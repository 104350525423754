import { isUndefined, isEmpty } from 'lodash'

import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { getTransitionsFromStep } from 'pages/orchestration/workflows/edit/utils/steps/transitionsFromStep'

interface IGetNestedTransitionsAmount {
  step: ICanvasStep
  steps: Array<ICanvasStep>
}

export const getNestedTransitionsAmount = (params: IGetNestedTransitionsAmount): number => {
  const { step, steps } = params
  const isGateway = !isUndefined(step?.gateway)
  const childSteps = getTransitionsFromStep({ step, steps })
  const transitionsHeights: Array<number> = []
  const isJoinGateway = childSteps[0]?.gateway?.mode === WorkflowActivityGatewayMode.JOIN

  if (isEmpty(childSteps) || isJoinGateway) {
    return 1
  }

  if (isGateway) {
    childSteps?.forEach?.(childStep => {
      if (childStep?.ID) {
        transitionsHeights.push(
          getNestedTransitionsAmount({
            step: childStep,
            steps,
          }),
        )
      } else {
        transitionsHeights.push(1)
      }
    })

    return transitionsHeights.reduce((acc, value) => acc + value, 0)
  }

  return getNestedTransitionsAmount({
    step: childSteps[0],
    steps,
  })
}
