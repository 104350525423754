import React from 'react'
import { Box, Typography, TextField, Grid, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material'
import { useCopyToClipboard } from 'react-use'
import { TooltipV2 } from 'components/ui-kit/tooltip/TooltipV2'
import { paramOptions } from './constants'
import { Label } from 'components/ui-kit/form/common/label/Label'

interface Props {
  onSubmit: (url: string) => void
}

export const CustomURLBuilder: React.FC<Props> = () => {
  const [, copyToClipboard] = useCopyToClipboard()
  const [baseUrl, setBaseUrl] = React.useState<string>('')
  const [urlParams, setUrlParams] = React.useState<URLSearchParams>(new URLSearchParams())

  const handleClearUrlParams = () => {
    setUrlParams(new URLSearchParams())
  }

  const queryString = urlParams.toString()
  const builtUrl = queryString ? `${baseUrl}?${queryString}` : baseUrl

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          sx={{
            wordBreak: 'break-all',
            cursor: 'pointer',
          }}
        >
          <Typography variant="label" onClick={() => copyToClipboard(builtUrl)}>
            Click to copy:
          </Typography>

          <TooltipV2 arrow={true} title="Click to copy to clipboard">
            <Typography variant="body" onClick={() => copyToClipboard(builtUrl)} component="code">
              {builtUrl}
            </Typography>
          </TooltipV2>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Button onClick={() => handleClearUrlParams()} variant="contained">
          Clear Params
        </Button>
      </Grid>

      <Grid item xs={8}>
        <TextField
          name="baseUrl"
          label="Base URL"
          variant="outlined"
          margin="normal"
          value={baseUrl}
          onChange={event => {
            const { value } = event.target
            setBaseUrl(value)
          }}
          fullWidth
        />
      </Grid>

      <Grid item xs={4} />

      {/* Query Param Toggles */}

      {paramOptions.map(paramOption => {
        const isSelect = Array.isArray(paramOption.values)

        return (
          <Grid item md={4} xs={6} key={paramOption.name}>
            <Box display="flex" flexDirection="column">
              <Label info={paramOption.description}>{paramOption.name}</Label>
              <FormControl variant="outlined" fullWidth margin="none">
                <InputLabel>{paramOption.name}</InputLabel>
                {isSelect ? (
                  <Select
                    name={paramOption.name}
                    value={urlParams.get(paramOption.name) || ''}
                    onChange={event => {
                      const { name, value } = event.target
                      setUrlParams(prevParams => {
                        const updatedParams = new URLSearchParams(prevParams)

                        if (value) {
                          updatedParams.set(name!, value as string)
                        } else {
                          updatedParams.delete(name!)
                        }

                        return updatedParams
                      })
                    }}
                    label={paramOption.name}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {(paramOption?.values || [])?.map(value => {
                      if (typeof value === 'string') {
                        return (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        )
                      } else {
                        return (
                          <MenuItem key={value.value} value={value.value}>
                            {value.label}
                          </MenuItem>
                        )
                      }
                    })}
                  </Select>
                ) : (
                  <TextField
                    name={paramOption.name}
                    variant="outlined"
                    margin="none"
                    value={urlParams.get(paramOption.name) || ''}
                    onChange={event => {
                      const { value } = event.target

                      setUrlParams(prevParams => {
                        const updatedParams = new URLSearchParams(prevParams)

                        if (value) {
                          updatedParams.set(paramOption.name!, value as string)
                        } else {
                          updatedParams.delete(paramOption.name!)
                        }

                        return updatedParams
                      })
                    }}
                    fullWidth
                  />
                )}
              </FormControl>
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}
