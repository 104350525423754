import { Box } from '@mui/material'
import { Table } from 'components/ui-layouts/table/Table'
import { FieldArrayRenderProps } from 'formik'
import { StackGroupWithId } from '../utils/formValues'
import { Text } from 'components/ui-kit/typography/Text'
import { InlineEdit } from 'components/ui-kit/inlineEdit/InlineEdit'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { GroupNumber } from './GroupNumber'

type TileProps = {
  group: StackGroupWithId
  index: number
  helpers: FieldArrayRenderProps
  languages: LanguageWithStatusDTO[]
}

export const TranslationTile = ({ helpers, index, group, languages }: TileProps) => (
  <Box display="flex" mb={7}>
    <GroupNumber index={index} />
    <Box flexGrow={1}>
      <Box display="flex" ml={2} mb={3} mt={0.75}>
        <Text width={250} weight={600} size={12}>
          Group Name
        </Text>
        <Text weight={700} size={16}>
          {group.name || 'None Provided'}
        </Text>
      </Box>
      <Table
        items={languages}
        cellSettings={{
          language: {
            label: 'Language',
            width: 250,
            cellRenderer: ({ language: { englishName, code } }) => (
              <Text weight={600} color="grey">
                {`${englishName} (${code})`}
              </Text>
            ),
          },
          translation: {
            label: 'Group Name Translation',
            cellRenderer: ({ language: { code } }) => (
              <InlineEdit
                placeholder="Custom"
                value={group?.translations?.[code] || ''}
                onChange={({ target }) => {
                  const newGroup = { ...group, translations: { ...group.translations, [code]: target.value } }
                  helpers.replace(index, newGroup)
                }}
              />
            ),
          },
        }}
      />
    </Box>
  </Box>
)
