import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { useAuth } from '../../../utils/hooks'
import { updatePrivacyProtocolConfig } from '../fetchers/updatePrivacyProtocolConfig'

const useCustomMutation = createUseMutation({
  mutationFn: updatePrivacyProtocolConfig,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdatePrivacyProtocolConfig = (config?: Config) => {
  const { userData } = useAuth()
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    organizationCode: userData.organizationCode,
  })
}
