import { useParams } from 'react-router-dom'
import { TeamsUpsert } from './TeamsUpsert'
import { getURLParams } from 'utils/routing/routes/utils'
import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { useUsers } from 'api/users/queries/useUsers'
import { useTeam } from 'api/teams/queries/useTeam'
import { Spinner } from '@ketch-com/deck'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {}

export const TeamsUpsertContainer: React.FC<Props> = () => {
  const { id } = useParams<getURLParams>()
  const isCreate = !id || id === NEW_ITEM_CODE

  const { data: userList, isLoading: isUserListLoading } = useUsers({ params: { active: true } })
  const { data: loadedTeam, isLoading: isTeamLoading } = useTeam({ params: { id }, enabled: !isCreate })

  const isLoadingRequiredData = isUserListLoading || isTeamLoading

  const breadcrumbs = [
    { title: 'Settings', link: RoutesManager.settings.root.getURL() },
    { title: 'Teams', link: RoutesManager.settings.teams.root.getURL() },
    { title: isLoadingRequiredData ? 'Loading...' : isCreate ? 'Create' : loadedTeam?.name },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      {isLoadingRequiredData ? (
        <Spinner size="32px" thickness={2.5} />
      ) : (
        <TeamsUpsert team={loadedTeam} users={userList} />
      )}
    </>
  )
}
