import { createUseQuery, CustomQueryConfig } from '../../common/query'
import { ApiQueryKeys } from '../../common/queryKeys'
import { fetchProcessingActivityChat } from '../fetchers/fetchProcessingActivityChat'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.processingActivityChat,
  queryFn: fetchProcessingActivityChat,
  select: res => res?.data || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useProcessingActivityChat = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
