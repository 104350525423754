import React, { useState } from 'react'
import { Box, Chip } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'

import { ReactComponent as EditIcon } from 'assets/icons/edit_pencil_light_grey.svg'
import { SearchAsYouType } from './SearchAsYouType'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import { LabelDefinitionDTO as LabelDTO } from '@ketch-com/figurehead'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      suggestedLabelsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      editIcon: {
        marginLeft: spacing(0.75),
        color: palette.lightGrey.main,
        cursor: 'pointer',
        borderRadius: spacing(1),
        padding: spacing(1, 1),
        '&:hover': {
          color: palette.fadedDarkGrey.main,
          backgroundColor: palette.fadedGrey.main,
        },
      },
    }),

  { name: 'ReviewLabelsChip' },
)

type Props = {
  index: number
  values: ManageLabelModalLabel[]
  asset: AssetSummaryDTO
  labels: LabelDTO[]
}

export const SuggestedLabelsCellRenderer: React.FC<Props> = ({ asset, values, index, labels }) => {
  const classes = useStyles()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  return (
    <Box className={classes.suggestedLabelsContainer} display="flex" alignItems="center">
      {labels?.map(label => (
        <Chip
          key={label.code}
          label={label.name}
          clickable={false}
          sx={{
            mx: 0.5,
            my: 0.5,
            cursor: 'pointer',
            backgroundColor: `rgba(255, 186, 121, 0.45)`,
            '&:hover': {
              color: 'red',
              backgroundColor: `rgba(255, 186, 121, 0.8)`,
            },
          }}
        />
      ))}
      {!isEditMode && (
        <Box
          onClick={() => setIsEditMode(true)}
          className={classes.editIcon}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <EditIcon />
        </Box>
      )}
      {isEditMode && <SearchAsYouType asset={asset} index={index} values={values} />}
    </Box>
  )
}
