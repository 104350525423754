import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { showToast } from 'components/modals/AlertComponent'
import { useApiKey } from 'api/apiKeys/queries/useApiKey'
import { ApiKeyView } from 'pages/developers/apiKeys/view/ApiKeyView'

export const ApiKeyViewContainer: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()

  const handleRedirectToApiKeysList = () => {
    navigate(RoutesManager.developers.apiKeys.root.getURL())
  }

  const { data: apiKey, isLoading: isApiKeyLoading } = useApiKey({
    params: {
      apiKeyId: id!,
      includeMetadata: true,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch API key', type: 'error' })
      handleRedirectToApiKeysList()
    },
  })

  return <ApiKeyView isReady={!isApiKeyLoading} apiKey={apiKey} />
}
