import React, { useState } from 'react'
import { Formik } from 'formik'
import Grid from '@mui/material/Grid'

import { Box, Typography } from '@mui/material'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'

import { getGatewayInitialValues } from 'pages/orchestration/workflows/edit/utils/forms/gateways/gatewayInitialValues'
import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { JoinGatewayConfirmDeleteModal } from './JoinGatewayConfirmDeleteModal'
import { FormInput } from 'components/form/FormInput'

interface IJoinGatewayFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
  connectedNestStartStep?: ICanvasStep
}

export const JoinGatewayForm: React.FC<IJoinGatewayFormProps> = ({
  step,
  steps,
  handleClose,
  connectedNestStartStep,
  handleRemove,
}) => {
  const nestStartIsSplit = connectedNestStartStep?.gateway?.mode === WorkflowActivityGatewayMode.SPLIT
  const nestStartIsSingleDecision =
    connectedNestStartStep?.gateway?.mode === WorkflowActivityGatewayMode.DECISION_SINGLE
  const [showModal, setShowModal] = useState(false)

  return (
    <SidebarConfigsWrapper handleClose={handleClose}>
      {showModal ? (
        <JoinGatewayConfirmDeleteModal onSubmit={() => handleRemove({ step })} onCancel={() => setShowModal(false)} />
      ) : null}
      <Formik
        initialValues={getGatewayInitialValues({ step: connectedNestStartStep || step, steps })}
        onSubmit={() => {}}
      >
        {() => (
          <>
            <SidebarConfigsHeader
              isValid={true}
              step={step}
              steps={steps}
              handleClose={handleClose}
              handleRemove={() => setShowModal(true)}
              removable={nestStartIsSingleDecision}
              hoverContent={
                nestStartIsSingleDecision
                  ? undefined
                  : `This tile is connected to the ${
                      nestStartIsSplit ? 'Split' : 'Multiple Decision Gateway'
                    } and cannot be removed.`
              }
            />
            <SidebarConfigsBody>
              <Box px={3} pb={5}>
                <Box pb={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormInput
                        formPropertyName="description"
                        fullWidth
                        label="Description Label"
                        placeholder="No Description Provided"
                        disabled
                        hideOptionalLabel
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <FormInput
                      formPropertyName="code"
                      fullWidth
                      label="Code"
                      placeholder="No Code Provided"
                      disabled
                      hideOptionalLabel
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box px={3}>
                <Box mb={3}>
                  <Typography variant="h3">Connections</Typography>
                </Box>

                {connectedNestStartStep?.gateway?.transitions.map(joinedStep => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      p: 2,
                      borderBottom: theme => `1px solid ${theme.palette.iron.main}`,
                    }}
                    key={joinedStep.ID}
                  >
                    <Typography variant="body">{joinedStep.name}</Typography>
                  </Box>
                ))}
              </Box>
            </SidebarConfigsBody>
          </>
        )}
      </Formik>
    </SidebarConfigsWrapper>
  )
}
