import React from 'react'

import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { ThemeStatus } from 'interfaces/themes-v2/themeStatus'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { Group } from 'components/ui-layouts/group/Group'
import { Badge } from 'components/ui-kit/badge/Badge'
import { Status } from 'components/ui-kit/status/Status'
import { Text } from 'components/ui-kit/typography/Text'

type Props = {
  theme: ThemeDTO
}

export const ThemeViewDetails: React.FC<Props> = ({ theme }) => {
  const { queries } = useQueryParams<{ version?: string }>()
  const { version } = queries

  return (
    <Group>
      {theme?.status === ThemeStatus.PUBLISHED ? (
        <Status size="big" variant="active">
          Deployed
        </Status>
      ) : (
        <Status size="big" variant="inactive">
          Undeployed
        </Status>
      )}

      {(!theme.latest || !!version) && theme.version && <Badge variant="outline">v{theme.version}</Badge>}

      {theme.code && (
        <div>
          <Text size={10} color="grey" weight={500} transform="uppercase">
            Code
          </Text>{' '}
          <Text size={10} color="grey">
            {theme.code}
          </Text>
        </div>
      )}
    </Group>
  )
}
