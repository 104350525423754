import { APIListRequestParams } from 'api/common/utils'
import { GetDataMapPurposeResponseBodyDTO } from '@ketch-com/figurehead'

import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  purposeCode?: string
}>

export const fetchDataMapPurpose = ({ purposeCode }: Params) =>
  API.get<GetDataMapPurposeResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/data-map/purposes/${purposeCode}`,
      params: {
        filters: {},
      },
    }),
  )
