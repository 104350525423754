import { FC, useMemo } from 'react'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Box, Skeleton } from '@mui/material'
import { ContentGroup, DataGrid, EmptyState, TableCell, theme } from '@ketch-com/deck'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { ApplicationDTO, ApplicationIdentitySpacesDTO } from 'interfaces/applications/application'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { APPLICATION_IDENTITY_SPACE_TYPE_NAME } from 'interfaces/applications/applicationIdentitySpaceType'
import { APPLICATION_IDENTITY_SPACE_FORMAT_NAME } from 'interfaces/applications/ApplicationIdentitySpaceFormat'
import { APPLICATION_IDENTITY_SPACE_ENCODING_NAME } from 'interfaces/applications/ApplicationIdentitySpaceEncoding'

type ApplicationIdentitySpacesData = {
  identitySpace?: IdentitySpaceDTO
} & ApplicationIdentitySpacesDTO

type Props = {
  application: ApplicationDTO
}

const managedCookieColumns: GridColDef<{ managedIdentity: boolean }>[] = [
  {
    align: 'left',
    field: 'managedIdentity',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Status',
    sortable: false,
    renderCell: ({ row }) => <TableCell title={row.managedIdentity ? 'Enabled' : 'Disabled'} />,
  },
]

const customIdentifiersColumns: GridColDef<ApplicationIdentitySpacesData>[] = [
  {
    align: 'left',
    field: 'ID',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Identifier',
    sortable: false,
    renderCell: ({ row }) =>
      !!row.identitySpace ? (
        <TableCell title={row.identitySpace.name} subTitle={row.identitySpace.code} />
      ) : (
        <TableCell>
          <Skeleton variant="text" animation="wave" width={150} height={14} />
        </TableCell>
      ),
  },
  {
    align: 'left',
    field: 'type',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Location',
    sortable: false,
    renderCell: ({ row }) => <TableCell title={row.type ? APPLICATION_IDENTITY_SPACE_TYPE_NAME[row.type] : 'None'} />,
  },
  {
    align: 'left',
    field: 'variable',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    renderCell: ({ row }) => <TableCell title={row.variable} />,
  },
  {
    align: 'left',
    field: 'format',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Format',
    sortable: false,
    renderCell: ({ row }) => <TableCell title={APPLICATION_IDENTITY_SPACE_FORMAT_NAME[row.format]} />,
  },
  {
    align: 'left',
    field: 'key',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Key',
    sortable: false,
    renderCell: ({ row }) => <TableCell title={row.key || 'Not applicable'} />,
  },
  {
    align: 'left',
    field: 'encoding',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Encoding',
    sortable: false,
    renderCell: ({ row }) => <TableCell title={APPLICATION_IDENTITY_SPACE_ENCODING_NAME[row.encoding]} />,
  },
]

const proxySupportColumns: GridColDef<{ proxy: string }>[] = [
  {
    align: 'left',
    field: 'proxy',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Proxy URL',
    sortable: false,
    renderCell: ({ row }) => <TableCell title={row.proxy} />,
  },
]

const customJurisdictionColumns: GridColDef<{ defaultPolicyScopeVariable: string }>[] = [
  {
    align: 'left',
    field: 'defaultPolicyScopeVariable',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Variable',
    sortable: false,
    renderCell: ({ row }) => <TableCell title={row.defaultPolicyScopeVariable} />,
  },
]

export const ApplicationDataLayer: FC<Props> = ({ application }) => {
  const { data: identitySpaces } = useIdentitySpaces({
    enabled: !!application?.identitySpaces?.length,
  })

  const { defaultPolicyScopeVariable, proxy, managedIdentity } = application

  const customIdentifiersData: ApplicationIdentitySpacesData[] = useMemo(
    () =>
      (application.identitySpaces || []).map(({ identitySpaceID, ...rest }) => ({
        ...rest,
        identitySpaceID,
        identitySpace: identitySpaces?.find(identitySpace => identitySpace.ID === identitySpaceID),
      })),
    [application, identitySpaces],
  )

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      {!!customIdentifiersData.length || !!proxy || !!defaultPolicyScopeVariable || !!managedIdentity ? (
        <>
          {!!managedIdentity && (
            <Box display="flex" flexDirection="column" gap={3}>
              <ContentGroup title="Ketch Managed Cookie" titleVariant="h3" variant="inner-page-header" />
              <DataGrid
                disableChildrenSorting
                disableColumnMenu
                disableColumnPinning
                disableColumnReorder
                disableColumnResize
                disableRowHoverStates
                disableRowSelectionOnClick
                hideFooter
                rows={[{ managedIdentity }]}
                columns={managedCookieColumns}
                getRowId={row => row.managedIdentity}
                getRowHeight={() => 'auto'}
                sx={{
                  '& .MuiDataGrid-virtualScroller': {
                    overflow: 'hidden',
                  },
                }}
              />
            </Box>
          )}
          {!!customIdentifiersData.length && (
            <Box display="flex" flexDirection="column" gap={3}>
              <ContentGroup
                title="Custom Identifiers"
                subTitle="Places where Ketch can pickup important data subject identifiers."
                titleVariant="h3"
                variant="inner-page-header"
              />
              <DataGrid
                disableChildrenSorting
                disableColumnMenu
                disableColumnPinning
                disableColumnReorder
                disableColumnResize
                disableRowHoverStates
                disableRowSelectionOnClick
                hideFooter
                rows={customIdentifiersData}
                columns={customIdentifiersColumns}
                getRowId={row => row.ID}
                getRowHeight={() => 'auto'}
                sx={{
                  '& .MuiDataGrid-virtualScroller': {
                    overflow: 'hidden',
                  },
                }}
              />
            </Box>
          )}
          {!!defaultPolicyScopeVariable && (
            <Box display="flex" flexDirection="column" gap={3}>
              <ContentGroup
                title="Custom Jurisdiction"
                subTitle="You can provide a variable in the property for Ketch to use in selecting the jurisdiction."
                titleVariant="h3"
                variant="inner-page-header"
              />
              <DataGrid
                disableChildrenSorting
                disableColumnMenu
                disableColumnPinning
                disableColumnReorder
                disableColumnResize
                disableRowHoverStates
                disableRowSelectionOnClick
                hideFooter
                rows={[{ defaultPolicyScopeVariable }]}
                columns={customJurisdictionColumns}
                getRowId={row => row.defaultPolicyScopeVariable}
                getRowHeight={() => 'auto'}
                sx={{
                  '& .MuiDataGrid-virtualScroller': {
                    overflow: 'hidden',
                  },
                }}
              />
            </Box>
          )}
          {!!proxy && (
            <Box display="flex" flexDirection="column" gap={3}>
              <ContentGroup
                title="Proxy Support"
                subTitle="Proxy support lets you gather identities from a proxy when the domain for privacy choices differs from the property domain in the Ketch app."
                titleVariant="h3"
                variant="inner-page-header"
              />
              <DataGrid
                disableChildrenSorting
                disableColumnMenu
                disableColumnPinning
                disableColumnReorder
                disableColumnResize
                disableRowHoverStates
                disableRowSelectionOnClick
                hideFooter
                rows={[{ proxy }]}
                columns={proxySupportColumns}
                getRowId={row => row.proxy}
                getRowHeight={() => 'auto'}
                sx={{
                  '& .MuiDataGrid-virtualScroller': {
                    overflow: 'hidden',
                  },
                }}
              />
            </Box>
          )}
        </>
      ) : (
        <EmptyState
          title="No variables specified"
          subTitle="Edit Property and configure the data layer section."
          iconName="OCode"
          sx={{
            padding: '48px 16px',
            backgroundColor: theme.palette.Black.o8,
            borderRadius: '11px',
          }}
        />
      )}
    </Box>
  )
}
