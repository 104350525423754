import React, { memo } from 'react'
import { Group } from 'react-konva'
import { find, isNil } from 'lodash'

import { ICanvasStep, ICoordinates } from 'pages/orchestration/workflows/edit/interfaces'
import { CANVAS_TRANSITION_CONNECTION_TYPES } from 'pages/orchestration/workflows/edit/interfaces/enums'
import { getAbsoluteCoordinates } from 'pages/orchestration/workflows/edit/utils/positioning/absoluteCoordinates'
import { getGridPositionForStep } from 'pages/orchestration/workflows/edit/utils/positioning/getGridPositionForStep'
import { TransitionPlaceholder } from 'pages/orchestration/workflows/edit/konvaEntities/transitions/TransitionPlaceholder'
import { TransitionConnection } from 'pages/orchestration/workflows/edit/konvaEntities/transitions/TransitionConnection'
import { CanvasImage } from 'pages/orchestration/workflows/edit/konvaEntities/common/CanvasImage'
import { StepByType } from 'pages/orchestration/workflows/edit/konvaLayers/steps/StepByType'
import * as CanvasConstants from '../utils/constants'

import startFlowActivity from 'assets/icons/workflow/activities/startFlowActivity.svg'
import startArrowImg from 'assets/icons/workflow/startArrow.svg'

interface IStartFlowProps {
  gridPosition: ICoordinates
  step: ICanvasStep
  steps: Array<ICanvasStep>
}

export const StartFlow: React.FC<IStartFlowProps> = memo(props => {
  const { gridPosition, step, steps } = props
  const { start } = step || {}

  const connectedStep = find(steps, { ID: start?.transition }) as ICanvasStep
  const hasTransition = !isNil(start?.transition) && !isNil(connectedStep)

  const stepPosition = getAbsoluteCoordinates(gridPosition)
  const nextStepGridPosition = getGridPositionForStep({
    parentGridPosition: gridPosition,
  })

  return (
    <Group>
      <Group x={stepPosition.x} y={stepPosition.y}>
        <CanvasImage
          useShadow
          svgURL={startFlowActivity}
          position={{
            x: CanvasConstants.GRID_IMG_GAP,
            y: CanvasConstants.GRID_IMG_GAP,
          }}
          width={CanvasConstants.GRID_IMG_SIZE}
          height={CanvasConstants.GRID_IMG_SIZE}
        />

        <CanvasImage
          svgURL={startArrowImg}
          position={{
            x: CanvasConstants.GRID_CELL_WIDTH + CanvasConstants.GRID_IMG_GAP,
            y: CanvasConstants.GRID_CELL_HEIGHT / 2 - 6,
          }}
          width={6}
          height={10}
        />
      </Group>

      {/* NOTE: 0.15 is required for additional arrow spacing */}
      <TransitionConnection
        type={hasTransition ? CANVAS_TRANSITION_CONNECTION_TYPES.SOLID : CANVAS_TRANSITION_CONNECTION_TYPES.DASHED}
        step={step}
        nextStep={connectedStep}
        gridPositionStart={{
          x: gridPosition.x + 0.15,
          y: gridPosition.y,
        }}
        gridPositionEnd={nextStepGridPosition}
      />

      {!hasTransition ? (
        <TransitionPlaceholder gridPosition={nextStepGridPosition} step={step} steps={steps} transitionIndex={0} />
      ) : (
        <StepByType steps={steps} step={connectedStep} gridPosition={nextStepGridPosition} />
      )}
    </Group>
  )
})
