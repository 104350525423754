import { Banner, Button, theme, Widget } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// THIS IS HARD CODED AND ONLY FOR DEMO PURPOSES
// THIS COMPONENT SHOULD BE DELETE AND REPLACED WITH THE ACTUAL ASSESMENT WIDGET
// ASAP

export const DemoAssessmentInDataSystemWidget: React.FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <Widget
        expandable={false}
        title="Assessment"
        content={
          <Box display="flex" alignItems="center" width="100%">
            <Box display="flex" flexDirection="column" gap={3}>
              <Banner
                title="Impact Assessment Recommended"
                variant="outlined"
                isInfoBox
                severity="info"
                actionTitle="Change to"
                action={
                  <>
                    <Button color="secondary" onClick={() => {}}>
                      Required
                    </Button>
                    <Button color="secondary" onClick={() => {}}>
                      Not Required
                    </Button>
                  </>
                }
              >
                <Typography>
                  An Impact Assessment or another type of assessment is recommended for this system.
                </Typography>
              </Banner>

              <Box display="flex" flexDirection="column" gap={0.75} pl={1.25}>
                <Typography variant="smallLabel" color={theme.palette.darkDuskFaded.main}>
                  Select Assessment
                </Typography>
                <Box display="flex" gap={1}>
                  <Button
                    color="primary"
                    onClick={() => {
                      navigate('/privacy-program/assessments/create')
                    }}
                  >
                    Select
                  </Button>
                  <Button color="tertiary" onClick={() => {}}>
                    Upload File
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        }
      />
    </>
  )
}
