import { API } from '../../../utils/api/api'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

type Params = {
  activityId: string
}

export const deleteProcessingActivityAssessment = ({ activityId }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/processing-activities/${activityId}/assessments`,
    }),
  )
