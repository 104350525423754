import { Box, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { RelatedPath, DsrConfigFormData } from 'pages/assetManager/dsrConfig/interfaces'
import { getRelatedDataset } from 'pages/assetManager/dsrConfig/utils'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { getAssetType } from 'pages/assetManager/utils'
import { ReactComponent as ConfiguredIcon } from 'assets/icons/green_check.svg'
import { theme } from '@ketch-com/deck'

type Props = {
  relatedPaths: RelatedPath[]
  assetsSummary: AssetSummaryDTO[]
}

export const RelatedDatasetName: React.FC<Props> = ({ relatedPaths, assetsSummary }) => {
  const { values } = useFormikContext<DsrConfigFormData>()
  const { actions } = values
  const {
    datasetName: relatedDataSetName,
    datasetId: relatedDataSetId,
    technology,
  } = getRelatedDataset(relatedPaths[0])
  const providerCode = assetsSummary?.[0]?.asset?.connection?.providerCode || ''
  const datasetType = getAssetType(providerCode, 2)
  const relatedDatasetIsConfigured = Object.values(actions[relatedDataSetId] || {}).some(action => action?.dsrConfigId)

  const relatedDataSetType = (
    <Box display="flex" alignItems="center" gap={0.5} mt={0.5}>
      <img src={getAssetCloudImgUrl(technology)} alt={technology} width={15} height={15} />
      <Box display="flex" gap={0.5} alignItems="center">
        <Typography fontSize={11} fontWeight={500} sx={{ textTransform: 'capitalize' }}>
          {datasetType}
        </Typography>
        {relatedDatasetIsConfigured ? (
          <Box display="flex" alignItems="center">
            <ConfiguredIcon width={24} height={20} />
            <Typography color={theme.palette.greenHaze.main} fontSize={12} fontWeight={600}>
              Configured
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
  return <NameAndCodeCellRenderer name={relatedDataSetName} code={relatedDataSetType} />
}
