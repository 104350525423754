import React from 'react'
import { DropListButton, DropListButtonProps, ListItemText } from '@ketch-com/deck'
import { useTranslation } from 'react-i18next'
import { COMMON } from 'i18n/types'
import { useDataSubjectTypes } from 'api/dataSubjectTypes/queries/useDataSubjectTypes'
import { ActionSheetItem as DeckMenuItem } from '@ketch-com/deck'

type TProps = {
  selected: boolean
  option: { value?: string; label?: string }
}
export const MenuItem = ({ selected, option, ...props }: TProps): React.ReactElement => {
  return (
    <DeckMenuItem selected={selected} {...props}>
      <ListItemText selected={selected}>{option?.label}</ListItemText>
    </DeckMenuItem>
  )
}

interface Props
  extends Omit<
    DropListButtonProps<{ label: string; value: string }, boolean, boolean, boolean>,
    'onChange' | 'options'
  > {
  onChange: (value?: string) => void
}
export const SubjectTypeDropList: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation([COMMON])
  const { data, isLoading } = useDataSubjectTypes()
  const options =
    data?.map(item => ({
      label: item.name || item.code,
      value: item.code,
    })) || []

  return (
    <DropListButton
      renderOption={(props, option, { selected }) => <MenuItem {...props} option={option} selected={selected} />}
      size="small"
      onChange={(_, item) => onChange(item?.value)}
      options={options}
      loading={isLoading}
      placeholder={isLoading ? t('Loading..', { ns: 'common' })! : t('All Subject Types')!}
    />
  )
}
