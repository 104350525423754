import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { Box } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Button, DateRangePicker } from '@ketch-com/deck'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getPermitStatisticsFilterState } from 'store/permitStatisticsFilterStateSlice/selectors'
import { updateDateRange, updateTrendPeriod } from 'store/permitStatisticsFilterStateSlice'
import { ChartTrendPeriod } from 'interfaces/rights/rightStats'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'
import moment from 'moment'

const PREFIX = 'TrendDateRangePicker'

const classes = {
  selectedDateRangeButton: `${PREFIX}-selectedDateRangeButton`,
  trendDatePickerButton: `${PREFIX}-trendDatePickerButton`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.selectedDateRangeButton}`]: {
    outline: `1px solid ${palette.sphere.main}`,
  },

  [`& .${classes.trendDatePickerButton}`]: {
    border: 'none',
  },
}))

const StyledDatePickerActionBar = styled(Box)(({ theme }) => ({
  gridRow: 3,
  minWidth: '650px',
  gridColumn: 2,
  margin: ' 0 8px 8px 8px',
  '& .StyledDatePickerActionBar-container': {
    borderTop: `1px solid ${theme.palette.iron.main}`,
    margin: '0 8px',
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 8,
  },
}))

type Props = {}

const currentDate = new Date()
const defaultSelected: DateRange<Dayjs> = [dayjs(currentDate).add(-6, 'day'), dayjs(currentDate)]

function getPreviousYearStartEnd() {
  var startOfLastYear = moment().subtract(1, 'year').startOf('year').toDate()
  var endOfLastYear = moment().subtract(1, 'year').endOf('year').toDate()
  return {
    startOfLastYear,
    endOfLastYear,
  }
}

export const TrendDateRangePicker: React.FC<Props> = () => {
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])
  const dispatch = useAppDispatch()
  const { startDate, endDate } = useAppSelector(getPermitStatisticsFilterState)

  const [range, setRange] = useState<DateRange<Dayjs>>(defaultSelected)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const startEndDifferenceInDays = (range[0] || dayjs(currentDate)).diff(range[1] || dayjs(currentDate), 'day')

  useEffect(() => {
    const syncReduxStateWithComponentState = async () => {
      if (startDate && endDate) {
        setRange([dayjs(startDate), dayjs(endDate)])
      } else {
        const payload = { startDate: range[0]?.toISOString() || '', endDate: range[1]?.toISOString() || '' }
        dispatch(updateDateRange(payload))
      }
    }

    syncReduxStateWithComponentState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const differenceInDaysLocalComponentRange = (range[0] || dayjs(currentDate).add(-7, 'day')).diff(
    range[1] || dayjs(currentDate),
    'day',
  )

  const { startOfLastYear, endOfLastYear } = getPreviousYearStartEnd()

  const onChangeHandler = (newValue: DateRange<Dayjs> | undefined) => {
    if (newValue) {
      if (dayjs(newValue[0]).isValid() && dayjs(newValue[1]).isValid()) {
        setRange(newValue)
      }
    }
  }

  const onOpenHandler = () => {
    setIsOpen(true)
  }

  const onCloseHandler = () => {
    setIsOpen(false)
  }

  return (
    <StyledBox>
      <DateRangePicker
        label=""
        open={isOpen}
        value={range}
        onOpen={onOpenHandler}
        onClose={onCloseHandler}
        disableFuture
        // disable dates older than 2 years
        minDate={dayjs(moment().subtract(1, 'year').startOf('year').toDate())}
        onChange={onChangeHandler}
        slots={{
          actionBar: () => {
            return (
              <StyledDatePickerActionBar>
                <Box className={'StyledDatePickerActionBar-container'}>
                  <Button
                    className={clsx({
                      [classes.selectedDateRangeButton]: differenceInDaysLocalComponentRange === -30,
                    })}
                    color="secondary"
                    onClick={e => {
                      e.preventDefault()
                      setRange([dayjs(currentDate).add(-30, 'day'), dayjs(currentDate)])
                    }}
                  >
                    {t('Last 30 Days')}
                  </Button>
                  <Button
                    className={clsx({
                      [classes.selectedDateRangeButton]: differenceInDaysLocalComponentRange === -60,
                    })}
                    color="secondary"
                    onClick={e => {
                      e.preventDefault()
                      setRange([dayjs(currentDate).add(-60, 'day'), dayjs(currentDate)])
                    }}
                  >
                    {t('Last 60 Days')}
                  </Button>
                  <Button
                    className={clsx({
                      [classes.selectedDateRangeButton]: differenceInDaysLocalComponentRange === -90,
                    })}
                    color="secondary"
                    onClick={e => {
                      e.preventDefault()
                      setRange([dayjs(currentDate).add(-90, 'day'), dayjs(currentDate)])
                    }}
                  >
                    {t('Last 90 Days')}
                  </Button>
                  <Button
                    className={clsx({
                      [classes.selectedDateRangeButton]: differenceInDaysLocalComponentRange === -90,
                    })}
                    color="secondary"
                    onClick={e => {
                      e.preventDefault()
                      setRange([dayjs(startOfLastYear), dayjs(endOfLastYear)])
                    }}
                  >
                    {t('Last Calendar Year')}
                  </Button>
                  <Button
                    disabled={!range[0] || !range[1]}
                    color="primary"
                    onClick={e => {
                      e.preventDefault()
                      // set redux store state to local component state to reflect user edits to date range
                      const payload = {
                        startDate: range[0]?.toISOString() || '',
                        endDate: range[1]?.toISOString() || '',
                      }
                      dispatch(updateDateRange(payload))
                      // if the date range is greater than 4 months automatically apply switch view to "monthly"
                      if (startEndDifferenceInDays > 120) {
                        dispatch(updateTrendPeriod(ChartTrendPeriod.MONTHLY_PAST_TWELVE))
                      }
                      setIsOpen(false)
                    }}
                  >
                    {t('Apply')}
                  </Button>
                </Box>
              </StyledDatePickerActionBar>
            )
          },
        }}
      />
    </StyledBox>
  )
}
