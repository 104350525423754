import { styled } from '@mui/styles'
import Box from '@mui/material/Box'
import { theme } from '@ketch-com/deck'
import { SxProps, Typography } from '@mui/material'

type Props = {
  title?: React.ReactNode
  hasBorderTop?: boolean
  hasBorderBottom?: boolean
  hidden?: boolean
  sx?: SxProps
  children: React.ReactNode
}

export const TitleBox = styled(Box)({
  paddingBottom: '24px',
})

export const SidebarFormGroup: React.FC<Props> = ({
  title,
  hasBorderTop = false,
  hasBorderBottom = false,
  hidden = false,
  sx,
  children,
}) => {
  return hidden ? null : (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={3}
      sx={{
        paddingTop: '32px',
        paddingBottom: '32px',
        borderTop: hasBorderTop ? `1px solid ${theme.palette.iron.main}` : '',
        borderBottom: hasBorderBottom ? `1px solid ${theme.palette.iron.main}` : '',
        ...sx,
      }}
    >
      {title && (
        <Typography variant={'h4'} color={theme.palette.darkDuskFaded.main}>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  )
}
