import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

import { Button, theme } from '@ketch-com/deck'
import { ReactComponent as DetermineYourInfrastructureIcon } from 'assets/icons/determine_your_infrastructure.svg'
import { ReactComponent as GetCredentialsAndDeployIcon } from 'assets/icons/get_credentials_and_deploy.svg'
import { transponderDocumentationLinks } from '../../constants'

type Props = {}

export const AppliancesInstallationGuide: React.FC<Props> = () => {
  const stepTagStyles = {
    borderRadius: '50%',
    bottom: -8,
    left: '44%',
    backgroundColor: theme.palette.darkDusk.main,
    color: theme.palette.white.main,
    height: 30,
    position: 'absolute',
    width: 30,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
  }

  return (
    <Grid container spacing={3}>
      {/* ONE */}

      <Grid item xs={4}>
        <Box pt={7} display="flex" flexDirection="column" alignItems="center">
          <Box mb={2} position="relative">
            <DetermineYourInfrastructureIcon />
            <Box sx={stepTagStyles}>1</Box>
          </Box>

          <Box mx={2} mb={2} textAlign="center">
            <Typography variant="h3">Determine Your Infrastructure</Typography>
          </Box>
          <Box mx={2} mb={2} textAlign="center" display="flex" alignItems="center" justifyContent="center">
            <Typography>Choose the infrastructure setup you’ll use and read the docs.</Typography>
          </Box>
          <Button
            onClick={() => {
              window.open(
                transponderDocumentationLinks.DETERMINING_YOUR_INFRASTRUCTURE,
                '_blank',
                'noopener,noreferrer',
              )
            }}
            color="secondary"
          >
            Read Documentation
          </Button>
        </Box>
      </Grid>

      {/* TWO */}

      <Grid item xs={4}>
        <Box pt={7} display="flex" flexDirection="column" alignItems="center">
          <Box mb={2} position="relative">
            <GetCredentialsAndDeployIcon />
            <Box sx={stepTagStyles}>2</Box>
          </Box>

          <Box mb={2} mx={8} textAlign="center">
            <Typography variant="h3">Get Credentials and Deploy</Typography>
          </Box>
          <Typography>Contact our customer success</Typography>
          <Typography>team at support@ketch.com</Typography>
          <Typography>to obtain credentials.</Typography>
        </Box>
      </Grid>

      {/* THREE */}

      <Grid item xs={4}>
        <Box py={7} display="flex" flexDirection="column" alignItems="center">
          <Box mb={2} position="relative">
            <DetermineYourInfrastructureIcon />
            <Box sx={stepTagStyles}>3</Box>
          </Box>

          <Box mb={2} mx={8} textAlign="center">
            <Typography variant="h3">Connect and Approve</Typography>
          </Box>

          <Typography>Wait for Transponder to start</Typography>
          <Typography>communicating with Ketch Platform</Typography>
          <Box mb={2}>
            <Typography>and approve it for further usage.</Typography>
          </Box>

          <Button
            onClick={() => {
              window.open(
                transponderDocumentationLinks.CONNECT_THE_TRANSPONDER_TO_KETCH,
                '_blank',
                'noopener,noreferrer',
              )
            }}
            color="secondary"
          >
            Read Documentation
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
