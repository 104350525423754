import React, { useEffect } from 'react'
import { useField } from 'formik'
import { useDropzone } from 'react-dropzone'
import { DropzonePreview, DropzoneRoot } from './components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Button } from '@ketch-com/deck'
import { FileWithPreview } from './interfaces'
import { SxProps, Theme } from '@mui/material'
import { showToast } from '../toastr/Toastr'

type SupportedFileTypes = {
  [key: string]: string[]
}

function flattenFileTypes(fileTypes: SupportedFileTypes | undefined): string {
  if (!fileTypes) return ''
  const flattened = Object.values(fileTypes).flat().join(', ')
  return flattened
}

type Props = {
  name: string
  accept?: SupportedFileTypes
  maxFiles?: number
  maxSize?: number
  containerSx?: SxProps<Theme>
}

export const Dropzone: React.FC<Props> = ({ name, accept, maxFiles, maxSize, containerSx }) => {
  const [field, meta, helpers] = useField<FileWithPreview[]>(name)
  const { setValue } = helpers

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    onDrop: acceptedFiles => {
      setValue([
        ...field.value,
        ...acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) })),
      ])
    },
    onDropRejected: fileRejections => {
      const collectedErrorMessages = new Set<string>()
      fileRejections.forEach(fileRejection => {
        const { errors } = fileRejection
        errors.forEach(error => {
          collectedErrorMessages.add(error.message)
        })
      })
      showToast({
        content: Array.from(collectedErrorMessages).join(', '),
        type: 'error',
      })
    },
  })

  // clean up preview object urls when unmounting
  useEffect(() => {
    return () => {
      field.value.forEach((file: { preview?: string }) => {
        if (file.preview) {
          URL.revokeObjectURL(file.preview)
        }
      })
    }
  }, [field.value])

  return (
    <Box display="flex" flexDirection="column" sx={containerSx}>
      {field.value.length < maxFiles! && (
        <DropzoneRoot
          {...getRootProps()}
          error={!!meta.error && meta.touched}
          isDragActive={isDragActive}
          hasFiles={!!field.value?.length}
        >
          <input {...getInputProps()} />
          <Typography variant="h4" color="text.primary" mb={1.5}>
            Upload Files
          </Typography>

          <Typography variant="smallBody" mb={3}>
            Supported File type: {flattenFileTypes(accept)}
          </Typography>

          <Button fullWidth={false} color="secondary">
            Browse...
          </Button>
        </DropzoneRoot>
      )}

      {meta.error && meta.touched && (
        <Box display="flex" alignItems="center">
          <Typography color="error">
            <>{meta.error}</>
          </Typography>
        </Box>
      )}

      <DropzonePreview name={name} />
    </Box>
  )
}
