import { DataSystemV2DTO } from '@ketch-com/figurehead'

export const systems = [
  {
    ID: '2pO389KideS8kmITPKJtYA',
    code: 'amazon-s3',
    version: '1.116.7',
    name: 'Amazon S3',
    shortDescription: 'Connect data from an S3 bucket into the Ketch Asset Manager.',
    primaryCategory: 2,
    supportedLanguages: ['en'],
    logo: {
      title: 'S3 Logo',
      URL: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/amazon-s3/1.116.7/bf3a16681cf66282c50d4d46c5521b6aab479f0181831e7102f6dc5fe9fe0159/logo.svg',
    },
    appInstanceIDs: ['2kOL1o9Q7tbbHeBXKTOVLN'],
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
  },
  {
    ID: '2pO38AGrTrUpL2i9unCHcg',
    code: 'amplitude',
    version: '1.116.7',
    name: 'Amplitude',
    shortDescription: 'Connect amplitude to your privacy program using this app.',
    primaryCategory: 1,
    supportedLanguages: ['en'],
    logo: {
      title: 'Amplitude Logo',
      URL: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/amplitude/1.116.7/272738b81a88e18bcb8ff27cf87182adfc27254a2dae9c279b943e2d20e5762f/logo.svg',
    },
    appInstanceIDs: ['2p9OIpKjoPTJbl3qJKRcSO', '2p8p5YBFcTB9EeSKVF9uhS', '2ojpZrNe2JEAKIvZLsEFZp'],
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
  },
  {
    ID: '2pO38b7eeCoU0nbk3EWnGZ',
    code: 'facebookAds',
    version: '1.116.7',
    name: 'Facebook Ads',
    shortDescription: 'Connect facebook ads to your privacy program.',
    primaryCategory: 1,
    supportedLanguages: ['en'],
    logo: {
      title: 'Facebook Ads',
      URL: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/facebookAds/1.116.7/070b6a0b83b2dcf836e31d9c4a978f92c9bc506f6ce46312e3988886fc715a60/logo.svg',
    },
    appInstanceIDs: [
      '2pHISHmOiYcef7jpkCD6N4',
      '2pE50dVXG9sLcvVT86S01B',
      '2ouMQe9AL096Eqr6MJUkCv',
      '2or384pv3efKYCmFkBGHvn',
      '2okN5tPLrCKChAJCUiUEEr',
      '2ojropKVCrM1uOHC0DL5cV',
    ],
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
  },
  {
    ID: '2pO38h348o8a5OblTpPjGW',
    code: 'google_marketing',
    version: '1.116.7',
    name: 'Google Marketing',
    shortDescription: 'Connect google marketing to your privacy program',
    primaryCategory: 1,
    supportedLanguages: ['en'],
    logo: {
      title: 'Google Marketing Logo',
      URL: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/google_marketing/1.116.7/f8040033f29d4fb6073a9f97dc3c01742253552e59829fea19464dbccd3e45ce/logo.svg',
    },
    appInstanceIDs: ['2oMjUaYWSdlXnFYSVPbE6G', '2oMO7cTs5XT1Dp30cGunHe'],
    hasCredentialsIssue: true,
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
  },
  {
    ID: '2pR0WKDHvacKoD29VEXBJB',
    code: 'googletag',
    version: '1.116.11',
    name: 'Google Tag Manager',
    shortDescription: 'Automate the privacy-based conditional firing rules for all tags within GTM.',
    primaryCategory: 1,
    supportedLanguages: ['en'],
    logo: {
      title: 'Logo',
      URL: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/googletag/1.116.11/38db05ad9e2cb512dc616fe56214c37398052510325b595b10f51cf5cc99c9cf/logo.svg',
    },
    appInstanceIDs: ['2pRIX4887RvQUjD3rOAUb1', '2pNIRCAmi9DZINGZohHvlo', '2pH2bangWKNCelXFZ6HgYU'],
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
  },
  {
    ID: '2pO38n5SldmB7dnUeJkuLs',
    code: 'hubspot',
    version: '1.116.7',
    name: 'Hubspot',
    shortDescription: 'Connect Hubspot to your privacy program.',
    primaryCategory: 1,
    supportedLanguages: ['en'],
    logo: {
      title: 'Logo',
      URL: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/hubspot/1.116.7/4110ba25f28a48bb93cc65baaf5e2ed4b9f2882b9a8d034a477ef440710fd8d6/logo.svg',
    },
    appInstanceIDs: ['2okNEDb2PMVmkAdNltSQ1J', '2ojsbrgu0ZR3VYnXj75NUd'],
    hasCredentialsIssue: true,
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
  },
  {
    ID: '2pO38o0nDvGDAqo68GaXae',
    code: 'intercom',
    version: '1.116.7',
    name: 'Intercom',
    shortDescription: 'Connect Intercom to your privacy program.',
    primaryCategory: 1,
    supportedLanguages: ['en'],
    logo: {
      title: 'Logo',
      URL: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/intercom/1.116.7/a876f0be1eaf328a3e5272dacd9e758d979e3b830b51dc4c46de7b360124f612/logo.svg',
    },
    appInstanceIDs: ['2p3HtFZEoICv5LnSGDdt6p'],
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
  },
  {
    ID: '2pO3929ANShIhIpUhvNGOv',
    code: 'linkedin',
    version: '1.116.7',
    name: 'LinkedIn',
    shortDescription: 'Connect LinkedIn to your privacy program.\n',
    primaryCategory: 1,
    supportedLanguages: ['en'],
    logo: {
      title: 'Logo',
      URL: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/linkedin/1.116.7/3438e6ee6339f26ed2c8dca631e94fd080fa0ae78cdd60a5dd446b6fa2634804/logo.svg',
    },
    appInstanceIDs: ['2os2HM77DEViQpDmjK5ZR1', '2nfLmqCnYhkO70mb8FFWuj'],
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
  },
  {
    ID: '2pO39AjOJsDUHRcqlaN5fn',
    code: 'marketo',
    version: '1.116.7',
    name: 'Marketo',
    shortDescription: 'Connect Marketo to your privacy program.',
    primaryCategory: 1,
    supportedLanguages: ['en'],
    logo: {
      title: 'Marketo Logo',
      URL: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/marketo/1.116.7/cc894a7df917e1a24c3eb44db101705ef4fcc33ab35aeb31c206ffd82a661ba2/logo.svg',
    },
    appInstanceIDs: ['2ofdhhorPIWL33N5LQcLls', '2oU8uPfvNmr3iTJXrGBAGU', '2oNIDB0Vlc6mFcHOHKH2T5'],
    hasCredentialsIssue: true,
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
  },
  {
    ID: '2pO39EGgS8D26sUHfbLLdH',
    code: 'microsoft365',
    version: '1.116.7',
    name: 'Microsoft Suite',
    shortDescription: 'Connect Microsoft Suite to your privacy program.',
    primaryCategory: 1,
    supportedLanguages: ['en'],
    logo: {
      title: 'Microsoft Suite',
      URL: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/microsoft365/1.116.7/97d8d5dcd28b8a4ee2c5caed867b10188fab68046361c5eb7677a817a86d810c/logo.svg',
    },
    appInstanceIDs: ['2ovsrblNl3vkFjCpEU3n3v', '2ouLMc3HLHtbITRq764EQO'],
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
  },
  {
    ID: '2pO39PjKk50DL6W2vAIaRb',
    code: 'optimizely',
    version: '1.116.7',
    name: 'Optimizely',
    shortDescription: 'Connect optimizely to your privacy program.',
    primaryCategory: 1,
    supportedLanguages: ['en'],
    logo: {
      title: 'Logo',
      URL: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/optimizely/1.116.7/bfd0c7d979fdeeeb08549dd1601d77e2ca01f1fe8a84f462a5bab6083f4b5289/logo.svg',
    },
    appInstanceIDs: ['2ojqfPeT0fFZje9nLtdZa0', '2ojpe0HeMPip4Ni2pktTqH'],
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
  },
  {
    ID: '2pO39f8AYiuPRIFhaNZ1Yi',
    code: 'salesforce',
    version: '1.116.7',
    name: 'SalesForce Sales Cloud CRM',
    shortDescription: 'Connect SalesForce SalesCloud CRMSA to your privacy program.',
    primaryCategory: 1,
    supportedLanguages: ['en'],
    logo: {
      title: 'Logo',
      URL: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/salesforce/1.116.7/3cf821829c46c38f711c58f3ff9bd55a64f5ffa2df37abc186a5a277910cb38f/logo.svg',
    },
    appInstanceIDs: ['2ounSE8foSIuS2E1pJFBVJ', '2l1UMbSNFrT9VEgZGjDKUA'],
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
  },
  {
    ID: '2pO39g2CDSVdMNAlnqKMSO',
    code: 'segment',
    version: '1.116.7',
    name: 'Segment',
    shortDescription: 'Connect segment to your privacy program.',
    primaryCategory: 1,
    supportedLanguages: ['en'],
    logo: {
      title: 'Logo',
      URL: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/segment/1.116.7/40b3fb60e63585e86ebb4bc0e16efbb3ab6e104676560f522a79508378e99934/logo.svg',
    },
    appInstanceIDs: [
      '2pAFOjArmZYvch8evUU2Em',
      '2pACSUaJ2oJK1I1pFeGOV7',
      '2pA85KNjZovBJSVIS43JMH',
      '2pA78BYnZoMAp00graBsAE',
      '2p3d6UnLq7FRfALGNdE6Rm',
      '2p0TuQdCodPLvP6jKqRhOW',
      '2ok0CQRC67pP1Kka603I3k',
    ],
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
  },
  {
    ID: '2pO39puJvIkTTlPEWpmMV2',
    code: 'snowflake',
    version: '1.116.7',
    name: 'Snowflake',
    shortDescription: 'Connect data from your Snowflake database into the Ketch asset manager.',
    primaryCategory: 2,
    supportedLanguages: ['en'],
    logo: {
      title: 'Snowflake Logo',
      URL: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/snowflake/1.116.7/53f5298918795ca2332e8e91428d1aed9e5deee03c017bea70c53290aa5c0dac/logo.svg',
    },
    appInstanceIDs: ['2obZfBbYXsK9Q1T74DJmHq', '2nj5Is3CuPrN7UqgXYt4jY', '2nhJJvnmBAAt3iJpdViOUv'],
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
  },
]

export const connections = [
  {
    ID: '2pRS416IYukm8LCagh50Be',
    code: 'sdasd',
    name: 'sdasd',
    description: 'Our main account at Intercom for all CX things.',
    appID: '2pO38b7eeCoU0nbk3EWnGZ',
    orgCode: 'vara_labs',
    version: '1.116.7',
    identitySpaceMappings: [
      { destinationIdentitySpace: 'mobileAdID' },
      { sourceIdentitySpace: 'email', destinationIdentitySpace: 'email' },
    ],
    createdAt: 1684950637,
    updatedAt: 1684950637,
    fields: [{ name: 'adAccountID', value: 'asASasas' }],
    hasCredentialIssues: false, // NEW
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
    workflowCount: 24,
  },
  {
    ID: '2pHISHmOiYcef7jpkCD6N4',
    code: 'facebook_ads_good_config_uat',
    name: 'Facebook Ads Good Config UAT',
    description: 'Account that is used for EU sites.',
    appID: '2pO38b7eeCoU0nbk3EWnGZ',
    orgCode: 'vara_labs',
    version: '1.116.7',
    identitySpaceMappings: [
      { destinationIdentitySpace: 'mobileAdID' },
      { sourceIdentitySpace: 'email', destinationIdentitySpace: 'email' },
    ],
    createdAt: 1683187331,
    updatedAt: 1684355711,
    fields: [{ name: 'adAccountID', value: '523412659026851' }],
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
    workflowCount: 24,
  },
  {
    ID: '2pE50dVXG9sLcvVT86S01B',
    code: 'sdasdasdsad',
    name: 'sdasdasdsad',
    appID: '2pO38b7eeCoU0nbk3EWnGZ',
    orgCode: 'vara_labs',
    version: '1.116.7',
    identitySpaceMappings: [
      { destinationIdentitySpace: 'mobileAdID' },
      { sourceIdentitySpace: 'email', destinationIdentitySpace: 'email' },
    ],
    createdAt: 1682626631,
    updatedAt: 1684355711,
    fields: [{ name: 'adAccountID', value: '123213213123' }],
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
    workflowCount: 24,
  },
  {
    ID: '2ouMQe9AL096Eqr6MJUkCv',
    code: 'na_integrations_account',
    name: 'na integrations account',
    appID: '2pO38b7eeCoU0nbk3EWnGZ',
    orgCode: 'vara_labs',
    version: '1.116.7',
    identitySpaceMappings: [
      { destinationIdentitySpace: 'mobileAdID' },
      { sourceIdentitySpace: 'email', destinationIdentitySpace: 'email' },
    ],
    createdAt: 1679903373,
    updatedAt: 1684355711,
    fields: [{ name: 'adAccountID', value: '74723104' }],
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
    workflowCount: 24,
  },
  {
    ID: '2or384pv3efKYCmFkBGHvn',
    code: 'na_integ1',
    name: 'na integ1',
    appID: '2pO38b7eeCoU0nbk3EWnGZ',
    orgCode: 'vara_labs',
    version: '1.116.7',
    identitySpaceMappings: [
      { destinationIdentitySpace: 'mobileAdID' },
      { sourceIdentitySpace: 'email', destinationIdentitySpace: 'email' },
    ],
    createdAt: 1679325193,
    updatedAt: 1684355711,
    fields: [{ name: 'adAccountID', value: '894391648483982' }],
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
    workflowCount: 24,
  },
  {
    ID: '2okN5tPLrCKChAJCUiUEEr',
    code: 'new_fb_ads',
    name: 'new_fb_ads',
    description: 'Our main account at Intercom for all CX things.',
    appID: '2pO38b7eeCoU0nbk3EWnGZ',
    orgCode: 'vara_labs',
    version: '1.116.7',
    identitySpaceMappings: [
      { destinationIdentitySpace: 'mobileAdID' },
      { sourceIdentitySpace: 'email', destinationIdentitySpace: 'email' },
    ],
    createdAt: 1678170643,
    updatedAt: 1684355711,
    fields: [{ name: 'adAccountID', value: '1018072009155435' }],
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
    workflowCount: 24,
  },
  {
    ID: '2ojropKVCrM1uOHC0DL5cV',
    code: 'facebook_ads_config_uat',
    name: 'facebook-ads config uat',
    appID: '2pO38b7eeCoU0nbk3EWnGZ',
    orgCode: 'vara_labs',
    version: '1.116.7',
    identitySpaceMappings: [
      { destinationIdentitySpace: 'mobileAdID' },
      { sourceIdentitySpace: 'email', destinationIdentitySpace: 'email' },
    ],
    purposes: { behavioral_advertising: true, data_broking: true },
    createdAt: 1678089218,
    updatedAt: 1684355711,
    fields: [{ name: 'adAccountID', value: '240994414453051' }],
    capabilities: ['consent', 'dsr', 'preferences', 'dataDiscovery'], // NEW
    workflowCount: 24,
  },
]

export const mockDiscoveredSystems: DataSystemV2DTO[] = [
  {
    code: 'adobeanalytics',
    documentURLs: {
      '': '',
    },
    logoUrl: 'https://cdn.uat.ketchjs.com/locker/v1/assets/logos/adobeanalytics.svg',
    name: 'Adobe Analytics',
    systemType: 'internal',
    updatedAt: 1234,
    updatedBy: '',
  },
  {
    code: 'facebookads',
    documentURLs: {
      '': '',
    },
    logoUrl: 'https://cdn.uat.ketchjs.com/locker/v1/assets/logos/facebook.svg',
    name: 'Facebook Ads',
    systemType: 'internal',
    updatedAt: 1234,
    updatedBy: '',
  },
  {
    code: 'shopify',
    documentURLs: {
      '': '',
    },
    logoUrl: 'https://cdn.uat.ketchjs.com/locker/v1/assets/logos/shopify.svg',
    name: 'Shopify',
    systemType: 'internal',
    updatedAt: 1234,
    updatedBy: '',
  },
  {
    code: 'googleads',
    documentURLs: {
      '': '',
    },
    logoUrl: 'https://cdn.uat.ketchjs.com/locker/v1/assets/logos/google-ads.svg',
    name: 'Google Ads',
    systemType: 'internal',
    updatedAt: 1234,
    updatedBy: '',
  },
]
