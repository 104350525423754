import { API } from 'utils/api/api'
import { APIRequestParams } from 'api/common/utils'
import { formatRequestString } from 'utils/helpers/api/queryString'

// types
import { FetchDataReviewFilesResponseBodyDTO } from '@ketch-com/figurehead'
type Params = APIRequestParams<{
  rightInvocationId: string
  workflowExecutionId: string
}>

export const fetchDataReviewFiles = ({ rightInvocationId, workflowExecutionId }: Params) =>
  API.get<FetchDataReviewFilesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/data-review/${rightInvocationId}/${workflowExecutionId}/files`,
    }),
  )
