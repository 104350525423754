import React, { ReactNode } from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import { formatCodeFromName } from 'utils/formatters'

type Props = {
  title: string
  actions?: ReactNode | ReactNode[]
  filters?: ReactNode | ReactNode[]
  shouldRemoveMarginBottom?: boolean
  paddingBottom?: number
  marginBottom?: number
  borderBottom?: string
  filtersBorderBottom?: string
  filtersFlex?: number
  filtersMargin?: string
  titleDataIntercomTarget?: string
  bigTitle?: boolean
}

type StyleProps = {
  paddingBottom?: number
  marginBottom?: number
  borderBottom?: string
  filtersBorderBottom?: string
  filtersFlex?: number
  filtersMargin?: string
}

const useStyles = makeStyles<Theme, StyleProps>(
  theme =>
    createStyles({
      root: {
        minHeight: '100%',
        margin: '0 0 22px 0',
        marginBottom: ({ marginBottom }) => marginBottom,
        borderBottom: ({ borderBottom }) => (borderBottom ? borderBottom : `1px solid ${theme.palette.iron.main}`),
        paddingBottom: ({ paddingBottom }) => paddingBottom,
      },
      filters: {
        borderBottom: ({ filtersBorderBottom }) => filtersBorderBottom || '',
        flex: ({ filtersFlex }) => filtersFlex || '',
        margin: ({ filtersMargin }) => filtersMargin || '',
      },
      actions: {},
      contentWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      },
      removeMarginBottom: {
        marginBottom: 0,
      },
    }),
  { name: 'ViewListLayoutHeader' },
)

export const ViewListLayoutHeader: React.FC<Props> = ({
  title,
  actions,
  filters,
  shouldRemoveMarginBottom = false,
  borderBottom,
  marginBottom = 22,
  filtersBorderBottom,
  filtersFlex,
  filtersMargin,
  paddingBottom = 22,
  titleDataIntercomTarget,
  bigTitle,
}) => {
  const classes = useStyles({
    borderBottom,
    filtersBorderBottom,
    filtersFlex,
    filtersMargin,
    paddingBottom,
    marginBottom,
  })

  const dataIntercomTarget = titleDataIntercomTarget
    ? titleDataIntercomTarget
    : `${formatCodeFromName(title)}_list_title`

  return (
    <div
      className={clsx(classes.root, {
        [classes.removeMarginBottom]: shouldRemoveMarginBottom,
      })}
    >
      {filters && (
        <div>
          <Typography
            id="viewListLayoutHeaderTitle"
            variant={bigTitle ? 'h1' : 'h2'}
            data-intercom-target={dataIntercomTarget}
            component="span"
          >
            {title}
          </Typography>
        </div>
      )}
      <div className={classes.contentWrapper}>
        {!filters && (
          <Typography
            id="viewListLayoutHeaderTitle"
            variant="h2"
            data-intercom-target={dataIntercomTarget}
            component="span"
          >
            {title}
          </Typography>
        )}
        <div className={classes.filters}>{filters}</div>
        <div className={classes.actions}>{actions}</div>
      </div>
    </div>
  )
}
