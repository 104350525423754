import React, { useContext, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useFormikContext } from 'formik'

import { fieldNames } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRadioGroup } from 'components/ui-kit/form/radioGroup/FormRadioGroup'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { Tabs } from 'components/ui-kit/tabs/Tabs'
import { VerificationOption } from 'pages/consentAndRights/experiences/upsert/components/UpsertPreference/components'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { CanonicalRights } from './CanonicalRights'

const PREFIX = 'PreferenceRightsRequestFields'

const classes = {
  tabs: `${PREFIX}-tabs`,
  addIcon: `${PREFIX}-addIcon`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.tabs}`]: {
    borderBottom: `1px solid ${palette.iron.main}`,
    width: '100%',
  },

  [`& .${classes.addIcon}`]: {
    fill: palette.royalBlue.main,
    fontSize: 20,
  },
}))

type Props = {
  organizationLanguages: LanguageWithStatusDTO[]
}

export const RightsRequest: React.FC<Props> = ({ organizationLanguages }) => {
  const { setFieldValue, setFieldTouched, values, validateForm } = useFormikContext<PreferenceExperienceFormValues>()
  const { activeSidebarTab, setActiveSidebarTab } = useContext(ExperienceUpsertContext)

  const { rights: rightsFormikValues } = values

  const resetTranslationInputs = () => {
    organizationLanguages?.forEach?.(({ language }) => {
      setFieldValue(fieldNames.preference.rights.requestDetailsTitle.translation(language.code), '')
    })
  }

  /* set active tab to 'title' on first mount */
  useEffect(() => setActiveSidebarTab('title'), [setActiveSidebarTab])

  return (
    <StyledBox display="flex" flexDirection="column" mb={3}>
      <Typography variant="body" color="darkDusk.main" mb={3}>
        Define your rights section title and rights ordering for this experience.
      </Typography>

      <Box display="flex" alignItems="center" mb={1}>
        <Tabs
          className={classes.tabs}
          tabSpacingOverride={2}
          config={[
            {
              key: 1,
              title: <Box px={1}>Title</Box>,
              onClick: () => setActiveSidebarTab('title'),
              active: activeSidebarTab === 'title',
            },
            {
              key: 2,
              title: <Box px={1}>Arrangement</Box>,
              onClick: () => setActiveSidebarTab('arrangement'),
              active: activeSidebarTab === 'arrangement',
            },
          ]}
        />
      </Box>

      {/* Title Tab */}
      {activeSidebarTab === 'title' && (
        <Box my={2}>
          <VerificationOption
            shouldHide={false}
            title="Title"
            value={!!rightsFormikValues.showRequestDetailsTitle}
            onChange={e => {
              setFieldValue(fieldNames.preference.rights.showRequestDetailsTitle.root, e.target.checked)
              setFieldTouched(fieldNames.preference.rights.showRequestDetailsTitle.root, true)

              if (!!e.target.checked) {
                setFieldValue(fieldNames.preference.rights.requestTitleType.root, 'default')
                setFieldValue(fieldNames.preference.rights.requestDetailsTitle.root, '')
              } else {
                /*  Unset the translations when "title" is toggled off */
                resetTranslationInputs()
                setFieldValue(fieldNames.preference.rights.requestTitleType.root, '')
                setFieldValue(fieldNames.preference.rights.requestDetailsTitle.root, '')
              }

              setTimeout(() => {
                validateForm()
              }, 500)
            }}
            sx={{
              mb: 2,
            }}
            requisiteForm={
              <Box display="flex" flexDirection="column" mb={2}>
                <FormRadioGroup
                  containerSx={{
                    mb: 1,
                  }}
                  name={fieldNames.preference.rights.requestTitleType.root}
                  layout="row"
                  valueKey="value"
                  renderLabel={({ name }) => name}
                  options={[
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Custom',
                      value: 'custom',
                    },
                  ]}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value === 'default') {
                      setFieldValue(fieldNames.preference.rights.requestDetailsTitle.root, '')
                      resetTranslationInputs()
                      setTimeout(() => {
                        validateForm()
                      }, 500)
                    }
                  }}
                />
              </Box>
            }
          />

          {rightsFormikValues.showRequestDetailsTitle && (
            <FormInput
              label="Title Text"
              disabled={rightsFormikValues.requestTitleType === 'default'}
              required={rightsFormikValues.requestTitleType === 'custom'}
              hint="Add your custom text and provide translations."
              placeholder="Request"
              fullWidth
              name={fieldNames.preference.rights.requestDetailsTitle.root}
              sx={{
                mb: 3,
              }}
            />
          )}
        </Box>
      )}

      {/* Arrangement tab */}
      {activeSidebarTab === 'arrangement' && <CanonicalRights />}
    </StyledBox>
  )
}
