import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FILTER_STATES, LIST_DISPLAY_MODES } from 'pages/assetManager/assets/list/components/MegaFilter/constants'

interface MegaFilterState {
  searchQuery: string
  filterState: FILTER_STATES
  typeState: string[]
  canonicalTypeState: number[]
  connectionTypeState: string[]
  purposesState: string[]
  displayMode: LIST_DISPLAY_MODES
}

const initialState = {
  searchQuery: '',
  filterState: FILTER_STATES.COLLAPSED,
  typeState: [],
  canonicalTypeState: [],
  connectionTypeState: [],
  purposesState: [],
  displayMode: LIST_DISPLAY_MODES.TILES,
} as MegaFilterState

export const megaFilterSlice = createSlice({
  name: 'megaFilterSlice',
  initialState,
  reducers: {
    addTypeFilter: (state, action: PayloadAction<string>) => {
      // This is temporary to only allow one type at a time. TODO:BAC - Replace with "state.typeState.push(action.payload)" when BE ready
      state.typeState = [action.payload]
    },
    removeTypeFilter: (state, action: PayloadAction<string>) => {
      const newTypeFilters = state.typeState.filter(id => id !== action.payload)
      state.typeState = newTypeFilters
    },

    addConnectionTypeFilter: (state, action: PayloadAction<string>) => {
      state.connectionTypeState = [action.payload]
    },
    removeConnectionTypeFilter: (state, action: PayloadAction<string>) => {
      const newConnectionTypeFilters = state.connectionTypeState.filter(id => id !== action.payload)
      state.connectionTypeState = newConnectionTypeFilters
    },

    addPurposesFilter: (state, action: PayloadAction<string>) => {
      state.purposesState.push(action.payload)
    },
    removePurposesFilter: (state, action: PayloadAction<string>) => {
      const newTypeFilters = state.purposesState.filter(id => id !== action.payload)
      state.purposesState = newTypeFilters
    },
    setFilterState: (state, action: PayloadAction<FILTER_STATES>) => {
      state.filterState = action.payload
    },
    clearFilterState: state => {
      state.typeState = []
    },

    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload
    },
    clearSearchQuery: state => {
      state.searchQuery = ''
    },
    setDisplayMode: (state, action: PayloadAction<LIST_DISPLAY_MODES>) => {
      state.displayMode = action.payload
    },
  },
})

export const {
  addConnectionTypeFilter,
  addPurposesFilter,
  addTypeFilter,
  clearFilterState,
  clearSearchQuery,
  removePurposesFilter,
  removeTypeFilter,
  setDisplayMode,
  setFilterState,
  setSearchQuery,
  removeConnectionTypeFilter,
} = megaFilterSlice.actions

export default megaFilterSlice.reducer
