import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { ClassificationV3DTO } from '@ketch-com/figurehead'
import { fetchDataCategories } from '../fetchers/fetchDataCategories'

export const useDataCategoriesInfinite = ({
  query,
  isSystemClassification,
  limit = 50,
}: {
  query?: string
  isSystemClassification?: string
  limit?: number
}) => {
  const resp = useInfiniteQuery({
    queryKey: [ApiQueryKeys.dataCategories, { query }],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchDataCategoriesResult = await fetchDataCategories({
        query,
        isSystemClassification,
        start: (pageParam - 1) * limit,
        limit,
      })

      return {
        categories: fetchDataCategoriesResult?.data?.classifications ?? [],
        totalResults: fetchDataCategoriesResult?.data?.totalResults ?? 0,
      }
    },
    getNextPageParam: ({ totalResults }, allPages) => {
      let totalItemsFetched = 0
      allPages?.forEach?.(pageOfResults => {
        totalItemsFetched += pageOfResults?.categories?.length || 0
      })
      const nextPageParam = Math.ceil(totalItemsFetched / limit) + 1

      return totalItemsFetched === totalResults ? undefined : nextPageParam
    },
  })

  let dataCategoriesInfinite: ClassificationV3DTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    const newDataCategories = page?.categories
    dataCategoriesInfinite = [...dataCategoriesInfinite, ...newDataCategories]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: dataCategoriesInfinite,
    totalResults,
  }
}
