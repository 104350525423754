import { DmlHookDTO } from '@ketch-com/figurehead'

export type CreateHookFormValues = {
  name: string
  type: string
  technology: string
  operation: string
  code: string
}

type Args = {
  hook?: DmlHookDTO
}

export const getInitialValues = ({ hook }: Args): CreateHookFormValues => {
  const isCreateMode = Object.keys(hook || {}).length === 0
  if (!isCreateMode) {
    return {
      name: hook?.name || '',
      type: hook?.type ? String(hook?.type) : '1',
      technology: hook?.technology?.toLowerCase() || '',
      operation: hook?.operation ? String(hook?.operation) : '1',
      code: hook?.dmlCode || '',
    }
  }

  return {
    name: '',
    type: '',
    technology: 'oracle',
    operation: '1',
    code: '',
  }
}
