import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export type TempAssignmentType = {
  ID?: string
  code?: string
  name?: string
  description?: string
  activityCode?: WorkflowActivityCode
  workflowDefinitionCode?: string
  workflowDefinitionName?: string
  icon: any
}

type TempResponseType = {
  activities: TempAssignmentType[]
}

export const fetchWorkflowAssignments = ({ id, orgCode }: { id?: string; orgCode?: string }) =>
  API.get<TempResponseType>(
    formatRequestString({
      entityUrl: `/api/organizations/${orgCode || ''}/workflows/steps?assignee=${id ? decodeURIComponent(id) : '-1'}`,
      params: {
        includeDetails: true,
      },
    }),
  )
