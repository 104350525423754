import React from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button } from '@ketch-com/deck'
import { ReactComponent as AppCrashCartoon } from 'assets/icons/app_crash_view_cartoon.svg'

type Props = {
  hideButton?: boolean
}
/**
 * This component is rendered in the event of a runtime error.
 */
export const AppCrashView: React.FC<Props> = ({ hideButton = false }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme => theme.palette.white.main,
          padding: theme => theme.spacing(3, 0, 5, 0),
          borderRadius: theme => theme.spacing(1),
          width: '100%',
          maxWidth: '1280px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <AppCrashCartoon />

        <Typography my={2} variant="h3">
          Ah, an error occurred
        </Typography>

        <Typography fontSize={12} color="darkDuskFaded.main">
          We’re very sorry but our app experienced an error.
        </Typography>

        <Typography fontSize={12} color="darkDuskFaded.main" mb={2}>
          If you see this screen again&mdash;please contact us at{' '}
          <a href="mailto:recipient@example.com">support@ketch.com</a>
        </Typography>

        {hideButton ? null : (
          <Link to="/">
            <Button size="large" color="primary">
              Back to Start
            </Button>
          </Link>
        )}
      </Box>
    </Box>
  )
}
