import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles, createStyles } from '@mui/styles'
import clsx from 'clsx'

import { Text } from 'components/ui-kit/typography/Text'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { getPluralAssetContent } from 'pages/assetManager/assets/list/components/AssetListItem/utils'
import { CategoryLabels } from 'pages/assetManager/components'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { useAssetV2 } from 'api/assets/queries/useAssetV2'

const useStylesDatabaseListItem = makeStyles(
  ({ palette, spacing, shadows }) =>
    createStyles({
      container: {
        border: `1px solid ${palette.iron.main}`,
        borderRadius: spacing(2),
        minHeight: 116,
        padding: spacing(2),
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          boxShadow: shadows[1],
        },
        '&.selected': {
          borderColor: '#5774FF',
        },
      },
      numberOfConfiguredItemsContainer: {
        backgroundColor: '#CCD5FF',
        borderRadius: spacing(0.5),
        padding: spacing(0, 0.75),
        color: '#1635CA',
      },
      assetCloudProviderImage: {
        height: 18,
        width: 18,
      },
    }),
  { name: 'DatabaseListItem' },
)

type DatabaseListItemProps = {
  databaseCode?: string
  isLastItem?: boolean
  isSelected?: boolean
  numConfiguredWorkflows?: number
  setActiveDatabase: (databaseResource: string) => void
  searchString?: string
}

export const DatabaseListItem: React.FC<DatabaseListItemProps> = ({
  databaseCode = '',
  isLastItem = false,
  numConfiguredWorkflows = 0,
  isSelected = false,
  setActiveDatabase,
  searchString,
}) => {
  const classes = useStylesDatabaseListItem()
  const { data: database, isLoading: isLoadingDatabase } = useAssetV2({
    enabled: !!databaseCode,
    params: {
      assetCode: databaseCode,
    },
  })

  const hasRelationshipCounts = Object.entries(database?.hierarchyCounts || {}).length > 0
  const labels = database?.asset?.assignedLabels || [
    {
      code: 'data_category',
      value: 'Sensitive',
      isSystemLabel: true,
      name: '',
    },
  ]

  if (isLoadingDatabase) {
    return (
      <Box
        pr={2}
        onClick={() => {
          setActiveDatabase(databaseCode)
        }}
      >
        <Box
          className={clsx(classes.container, {
            selected: isSelected,
          })}
          mb={isLastItem ? 0 : 2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner padding={20} />
        </Box>
      </Box>
    )
  }

  let displayValue = 'block'
  if (searchString) {
    const searchStringLowerCase = searchString.toLowerCase()
    const databaseName = database?.asset?.resource?.name?.toLowerCase() || ''

    if (databaseName.includes(searchStringLowerCase)) {
      displayValue = 'block'
    } else {
      displayValue = 'none'
    }
  }

  return (
    <Box
      style={{
        borderRight: isSelected ? `2px solid #5774FF` : `2px solid transparent`,
        display: displayValue,
      }}
      pr={2}
      onClick={() => {
        setActiveDatabase(databaseCode)
      }}
    >
      <Box
        className={clsx(classes.container, {
          selected: isSelected,
        })}
        mb={isLastItem ? 0 : 2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {/* Row 1 */}

        <Box display="flex" alignItems="center">
          <Text size={14} weight={700}>
            {database?.asset?.resource?.name || 'Database Name'}
          </Text>
        </Box>

        {/* Row 2 */}

        <Box display="flex" alignItems="center" justifyContent="space-between">
          {/* Left */}
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <CategoryLabels labels={labels} />
            </Box>

            {hasRelationshipCounts &&
              Object.entries(database?.hierarchyCounts || {})
                .filter(r => r?.[0]?.toLowerCase().includes('dataset'))
                .map(r => (
                  <Text weight={500} key={r[0]} transform="capitalize" size={12}>
                    {r?.[1]} {getPluralAssetContent({ key: r?.[0], value: r?.[1] })}
                  </Text>
                ))}
          </Box>

          <Box display="flex" alignItems="center" justifyContent="center">
            <Box mr={0.5} display="flex" alignItems="center" justifyContent="center">
              <img
                src={getAssetCloudImgUrl(database?.asset?.connection?.technology || 'snowflake')}
                className={classes.assetCloudProviderImage}
                alt=""
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
