import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { checkIsOrganizationCodeAvailable } from 'api/organizations/fetchers/checkIsOrganizationCodeAvailable'

const useCustomMutation = createUseMutation({
  mutationFn: checkIsOrganizationCodeAvailable,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCheckIsOrganizationCodeAvailable = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
