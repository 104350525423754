import React from 'react'

import { PopUp, Button } from '@ketch-com/deck'
import { makeStyles, createStyles } from '@mui/styles'

type Props = {
  regionNames: string[]
  onCancel: () => void
}

const useStyles = makeStyles(
  () =>
    createStyles({
      dialogContent: {
        columnCount: 3,
      },
    }),
  { name: 'ViewAssociatedRegions' },
)

export const ViewAssociatedRegions: React.FC<Props> = ({ regionNames = [], onCancel }) => {
  const classes = useStyles()

  return (
    <PopUp
      isLoading={!regionNames.length}
      variant="dialog"
      title={`Associated Regions ${regionNames.length}`}
      popUpWidth="550px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="primary" size="large" onClick={() => onCancel()}>
            Close
          </Button>
        </>
      }
    >
      <div className={classes.dialogContent}>
        {regionNames.map(name => (
          <div key={name}>{name}</div>
        ))}
      </div>
    </PopUp>
  )
}
