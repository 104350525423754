import React, { useMemo } from 'react'

import { ObjectLiteral } from 'interfaces'
import { RegulationDTO } from 'interfaces/regulations/regulation'
import { RightDTO } from 'interfaces/rights/right'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { getPossibleRightsFulfillment } from 'utils/helpers/rightsRegulationsHelpers'
import { Chip } from '@ketch-com/deck'
import { Tooltip, Typography } from '@mui/material'

type Props = {
  policyScope: PolicyScopeDTO
  regulations: RegulationDTO[]
  rights: RightDTO[]
}

export const PolicyScopeRights: React.FC<Props> = ({ policyScope, regulations, rights }) => {
  const { rightsFulfillment, regulationCodes } = policyScope

  const rightNamesByCodes = useMemo(
    () =>
      rights.reduce<ObjectLiteral<string>>(
        (acc, { name, code }) => ({
          ...acc,
          [code]: name,
        }),
        {},
      ),
    [rights],
  )

  const possibleRights = useMemo(
    () => getPossibleRightsFulfillment({ regulations, regulationCodes }),
    [regulations, regulationCodes],
  )

  const rightsCodes = rightsFulfillment?.map(({ rightCode }) => rightCode) || []

  return rightsCodes.length ? (
    <Tooltip
      placement="right"
      title={
        <>
          {rightsCodes.map(code => (
            <div key={code}>{rightNamesByCodes[code]}</div>
          ))}
        </>
      }
    >
      <span>
        <Chip size="small" clickable label={`${rightsCodes.length} / ${possibleRights.length}`} />
      </span>
    </Tooltip>
  ) : (
    <Typography variant="body">None</Typography>
  )
}
