import React, { useContext } from 'react'
import { isEmpty } from 'lodash'

import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { DeploymentPlanPreviewContext } from '../context/DeploymentPlanPreviewContext'
import { PreferenceScreenPreview } from 'pages/consentAndRights/experiences/preview/components/preferencePreview/PreferenceScreenPreview'
import { ensureHydratedThemeColors } from 'utils/helpers/ensureHydratedThemeColors'

type Props = {}

export const DeploymentPlanPreferencePreview: React.FC<Props> = () => {
  const { activeExperienceType, activePolicyScopeCode, data, formTemplates, isEntitled, mapping, mode } =
    useContext(DeploymentPlanPreviewContext)

  const { configuration } = mapping[activeExperienceType]!

  const config = configuration?.experiences?.preference
  const purposes = configuration?.purposes || []
  const rights = configuration?.rights || []
  if (!config) {
    return null
  }

  const isRightsAllowed = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)
  const isConsentAndDisclosureAllowed = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)

  const showOverviewTab = !isEmpty(config.overview)
  const showConsentsTab = !isEmpty(config.consents) && !!purposes.length && isConsentAndDisclosureAllowed
  const showRightsTab = !isEmpty(config.rights) && !!rights.length && isRightsAllowed

  return (
    <PreferenceScreenPreview
      preference={config}
      key={activePolicyScopeCode}
      mode={mode}
      theme={ensureHydratedThemeColors(configuration?.theme)}
      purposes={purposes}
      data={data}
      rights={rights}
      consent={{}}
      jitPurposes={[]}
      title={config.title}
      {...(showOverviewTab && {
        overviewTab: {
          tabName: config.overview!.tabName,
          bodyTitle: config.overview!.bodyTitle,
          bodyDescription: config.overview!.bodyDescription,
        },
      })}
      {...(showConsentsTab && {
        consentsTab: {
          tabName: config.consents!.tabName,
          bodyTitle: config.consents!.bodyTitle,
          bodyDescription: config.consents!.bodyDescription,
          buttonText: config.consents!.buttonText,
        },
      })}
      {...(showRightsTab && {
        rightsTab: {
          tabName: config.rights!.tabName,
          bodyTitle: config.rights!.bodyTitle,
          bodyDescription: config.rights!.bodyDescription,
          buttonText: config.rights!.buttonText,
        },
        rights,
        formTemplates,
        canonicalRightFormTemplates: config.rights?.canonicalRightFormTemplates,
        customRightFormTemplates: config.rights?.customRightFormTemplates,
      })}
    />
  )
}
