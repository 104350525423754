import { APIListRequestParams } from 'api/common/utils'
import { GetGatewayStepsDetailsResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  workflowExecutionId?: string | null | undefined
  stepId?: string | null | undefined
}>

export const fetchGatewayContext = ({ workflowExecutionId, stepId }: Params) =>
  API.get<GetGatewayStepsDetailsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/executions/${workflowExecutionId}/steps/${stepId}/decision-gateway`,
      params: {},
    }),
  )
