import React from 'react'
import { decomposeColor } from '@mui/material'

import { makeStyles, createStyles } from '@mui/styles'

import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      root: {
        display: 'inline-flex',
        width: 150,
      },
      colorChannel: {
        flex: 1,
        fontSize: typography.pxToRem(14),
        whiteSpace: 'nowrap',
      },
      colorChannelLetter: {
        color: palette.darkDuskFaded.main,
      },
    }),
  { name: 'ColorRGBView' },
)

type Props = {
  /** Hex color which will be decomposed into R G B */
  color: string
}

/**
 * Convert HEX color to RGB and render it as separate R G B values
 */
export const ColorRGB: React.FC<Props> = ({ color }) => {
  const classes = useStyles()

  try {
    const { values } = decomposeColor(color)
    const [r, g, b] = values

    return (
      <div className={classes.root}>
        <div className={classes.colorChannel}>
          <span className={classes.colorChannelLetter}>R&nbsp;</span>
          {r}
        </div>
        <div className={classes.colorChannel}>
          <span className={classes.colorChannelLetter}>G&nbsp;</span>
          {g}
        </div>
        <div className={classes.colorChannel}>
          <span className={classes.colorChannelLetter}>B&nbsp;</span>
          {b}
        </div>
      </div>
    )
  } catch (e) {
    return <EmptyValueRenderer />
  }
}
