export const updates = {
  root: {
    pattern: '/updates',
    getURL: () => '/updates',
  },

  tasks: {
    root: {
      pattern: '/tasks',
      getURL: () => '/updates/tasks',
    },

    list: {
      pattern: '/updates/tasks',
      getURL: () => '/updates/tasks',
    },
  },

  notifications: {
    root: {
      pattern: '/notifications',
      getURL: () => '/updates/notifications',
    },

    list: {
      pattern: '/updates/notifications',
      getURL: () => '/updates/notifications',
    },
  },
}
