import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { PurposeResponse } from '@ketch-com/supercargo/dist/purpose_gen.schemas'

export const fetchPurposesV2 = () =>
  API.get<PurposeResponse>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/purposes`,
      params: {
        filters: {},
      },
    }),
  )
