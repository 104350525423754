import { Icon } from '@ketch-com/deck'
import { SidebarRouteItem, SidebarSubRouteSection } from './types'
import { RoutesManager } from './routesManager'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useTranslation } from 'react-i18next'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { SidebarSection } from 'components/appLayout/appNavigation/components/navigationSidebar/utils'

export const useSidebarRoutesConfig = () => {
  // New
  const { t } = useTranslation(['common'])
  const isLocalizedUrl = window.location.pathname.endsWith('/zh')

  const routes: SidebarRouteItem[] = [
    {
      sectionId: SidebarSection.Home,
      title: 'Home',
      path: RoutesManager.home.root.getURL(),
      unselectedIcon: <Icon name={'ONavHome'} width={24} height={24} />,
      selectedIcon: <Icon name={'FNavHome'} width={24} height={24} />,
    },
    {
      sectionId: SidebarSection.PrivacyProgram,
      title: 'Privacy Program',
      description: 'Manage privacy essentials, mitigate risk, and define your general stance on data processing.',
      path: RoutesManager.policyCenter.root.getURL(),
      unselectedIcon: <Icon name={'ONavPrivacyProgram'} width={24} height={24} />,
      selectedIcon: <Icon name={'FNavPrivacyProgram'} width={24} height={24} />,
      permissions: [],
      entitlements: [],
      subRoutes: {
        [SidebarSubRouteSection.Risk]: [
          {
            title: 'Assessments',
            path: RoutesManager.policyCenter.assessments.root.getURL(),
            permissions: [PERMISSIONS.ASSESSMENT_READ],
            entitlements: [ENTITLEMENTS.ASSESSMENTS],
            subRoutes: [
              {
                title: 'Templates',
                path: RoutesManager.policyCenter.assessments.templates.root.getURL(),
                permissions: [PERMISSIONS.ASSESSMENT_READ],
                entitlements: [ENTITLEMENTS.ASSESSMENTS],
              },
              {
                title: 'Questions',
                path: RoutesManager.policyCenter.assessments.questions.root.getURL(),
                permissions: [PERMISSIONS.ASSESSMENT_READ],
                entitlements: [ENTITLEMENTS.ASSESSMENTS],
              },
            ],
          },

          {
            title: 'Processing Activities',
            path: RoutesManager.policyCenter.processingActivities.root.getURL(),
            permissions: [PERMISSIONS.PROCESSING_ACTIVITY_READ],
            entitlements: [ENTITLEMENTS.PROCESSING_ACTIVITIES],
          },
        ],
        [SidebarSubRouteSection.Foundation]: [
          {
            title: 'Jurisdictions',
            path: RoutesManager.policyCenter.policyScopes.root.getURL(),
            permissions: [PERMISSIONS.JURISDICTION_READ],
            entitlements: [ENTITLEMENTS.JURISDICTIONS],
          },
          {
            title: 'Purposes',
            path: RoutesManager.policyCenter.purposes.root.getURL(),
            permissions: [PERMISSIONS.PURPOSE_READ],
            entitlements: [ENTITLEMENTS.PURPOSES],
          },
          {
            title: 'Documents',
            path: RoutesManager.policyCenter.policyDocuments.root.getURL(),
            permissions: [PERMISSIONS.POLDOC_READ],
            entitlements: [ENTITLEMENTS.POLICY_DOCUMENTS],
          },
          {
            title: 'Controllers',
            path: RoutesManager.policyCenter.controllers.root.getURL(),
            permissions: [PERMISSIONS.CONTROLLER_READ],
            entitlements: [ENTITLEMENTS.CONTROLLERS],
          },
        ],
      },
    },
    {
      sectionId: SidebarSection.ConsentRights,
      title: 'Consent & Rights',
      description: 'Configure privacy experiences, create and manage properties to capture consent and automate DSARs.',
      path: RoutesManager.deployment.root.getURL(),
      unselectedIcon: <Icon name={'ONavConsent'} width={24} height={24} />,
      selectedIcon: <Icon name={'FNavConsent'} width={24} height={24} />,
      permissions: [],
      entitlements: [],
      subRoutes: {
        [SidebarSubRouteSection.Capture]: [
          {
            title: 'Experiences',
            path: RoutesManager.deployment.experiencesHome.root.getURL(),
            description: 'Design and customize consumer-facing privacy experiences.',
            permissions: [PERMISSIONS.EXP_READ],
            entitlements: [
              ENTITLEMENTS.CONSENT_MANAGEMENT,
              ENTITLEMENTS.RIGHTS_MANAGEMENT,
              ENTITLEMENTS.MARKETING_PREFERENCES,
            ],
            subRoutes: [
              {
                title: 'Subscriptions',
                path: RoutesManager.policyCenter.subscriptions.root.getURL(),
                description: '',
                permissions: [PERMISSIONS.SUBSCRIPTIONS_READ],
                entitlements: [ENTITLEMENTS.SUBSCRIPTIONS],
              },
              {
                title: 'Forms',
                path: RoutesManager.deployment.forms.templates.root.getURL(),
                description: '',
                permissions: [PERMISSIONS.EXP_WRITE],
                entitlements: [ENTITLEMENTS.RIGHTS_MANAGEMENT],
              },
              {
                title: 'Purpose Stacks',
                path: RoutesManager.deployment.purposeStacks.root.getURL(),
                description: '',
                permissions: [PERMISSIONS.EXP_READ],
                entitlements: [ENTITLEMENTS.CONSENT_MANAGEMENT, ENTITLEMENTS.MARKETING_PREFERENCES],
              },
            ],
          },
          {
            title: 'Properties',
            path: RoutesManager.deployment.propertiesHome.root.getURL(),
            permissions: [PERMISSIONS.PROPERTY_READ],
            entitlements: [
              ENTITLEMENTS.CONSENT_MANAGEMENT,
              ENTITLEMENTS.RIGHTS_MANAGEMENT,
              ENTITLEMENTS.MARKETING_PREFERENCES,
            ],
            subRoutes: [
              {
                title: 'Themes',
                path: RoutesManager.deployment.themesV3.root.getURL(),
                description: '',
                permissions: [PERMISSIONS.THEME_READ],
                entitlements: [
                  ENTITLEMENTS.CONSENT_MANAGEMENT,
                  ENTITLEMENTS.RIGHTS_MANAGEMENT,
                  ENTITLEMENTS.MARKETING_PREFERENCES,
                ],
              },
              {
                title: 'Deployment',
                path: RoutesManager.deployment.deploymentPlansV2.root.getURL(),
                description:
                  'Enable privacy experiences on your properties, such as websites and mobile apps via deployment bundle.',
                permissions: [PERMISSIONS.DEPLOY_READ],
                entitlements: [
                  ENTITLEMENTS.CONSENT_MANAGEMENT,
                  ENTITLEMENTS.RIGHTS_MANAGEMENT,
                  ENTITLEMENTS.MARKETING_PREFERENCES,
                ],
              },
            ],
          },
          {
            title: 'Trackers',
            path: RoutesManager.policyCenter.trackers.list.approvedTrackers.root.getURL(),
            description: '',
            permissions: [PERMISSIONS.COOKIE_READ],
            entitlements: [ENTITLEMENTS.TRACKERS],
          },
        ],
        [SidebarSubRouteSection.Orchestration]: [
          {
            title: 'Rights',
            path: RoutesManager.deployment.rightsHome.root.getURL(),
            permissions: [PERMISSIONS.RTINVQ_READ],
            entitlements: [ENTITLEMENTS.RIGHTS_MANAGEMENT, ENTITLEMENTS.WORKFLOWS],
            subRoutes: [
              {
                title: 'Workflows',
                path: RoutesManager.orchestration.workflows.root.getURL(),
                description: '',
                permissions: [PERMISSIONS.WORKFLOW_READ],
                entitlements: [ENTITLEMENTS.RIGHTS_MANAGEMENT, ENTITLEMENTS.WORKFLOWS],
              },
              {
                title: 'Events',
                path: RoutesManager.orchestration.events.root.getURL(),
                description: '',
                permissions: [PERMISSIONS.WORKFLOW_READ],
                entitlements: [ENTITLEMENTS.RIGHTS_MANAGEMENT, ENTITLEMENTS.WORKFLOWS],
              },
            ],
          },
          {
            title: 'Consent',
            path: RoutesManager.deployment.consentHome.root.getURL(),
            permissions: [PERMISSIONS.PERMIT_ORCH_READ],
            entitlements: [
              ENTITLEMENTS.ORCHESTRATION_CONSENT,
              ENTITLEMENTS.ORCHESTRATION_PROTOCOLS,
              ENTITLEMENTS.CONSENT_MANAGEMENT,
            ],
            subRoutes: [
              {
                title: 'Privacy Protocols',
                path: RoutesManager.orchestration.consent.privacyProtocols.root.getURL(),
                description: '',
                permissions: RoutesManager.orchestration.consent.privacyProtocols.root.permissions,
                entitlements: [ENTITLEMENTS.ORCHESTRATION_PROTOCOLS],
              },
              {
                title: 'Tags',
                path: RoutesManager.orchestration.webTags.root.getURL(),
                description: '',
                permissions: [PERMISSIONS.WEB_TAGS_READ],
                entitlements: [ENTITLEMENTS.ORCHESTRATION_CONSENT, ENTITLEMENTS.CONSENT_MANAGEMENT],
              },
            ],
          },
        ],
      },
    },
    {
      sectionId: SidebarSection.DataMap,
      title: 'Data Map',
      description:
        'Connect and configure vendor and partner Systems for an end-to-end automation. Discover and label your data.',
      path: RoutesManager.systems.root.getURL(),
      unselectedIcon: <Icon name={'ONavDatamap'} width={24} height={24} />,
      selectedIcon: <Icon name={'FNavDatamap'} width={24} height={24} />,
      permissions: [],
      entitlements: [],
      subRoutes: [
        {
          title: t('Systems'),
          path: RoutesManager.systems.systemsHome.root.getURL(),
          permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
          entitlements: [
            ENTITLEMENTS.DATA_MAPPING,
            ENTITLEMENTS.CONSENT_MANAGEMENT,
            ENTITLEMENTS.RIGHTS_MANAGEMENT,
            ENTITLEMENTS.DATA_DISCOVERY,
            ENTITLEMENTS.MARKETING_PREFERENCES,
          ],
          subRoutes: [
            {
              title: 'Connections',
              path: RoutesManager.systems.connections.root.getURL(),
              description: '',
              permissions: [PERMISSIONS.DATAMAP_READ],
              entitlements: [
                ENTITLEMENTS.DATA_MAPPING,
                ENTITLEMENTS.CONSENT_MANAGEMENT,
                ENTITLEMENTS.RIGHTS_MANAGEMENT,
                ENTITLEMENTS.DATA_DISCOVERY,
                ENTITLEMENTS.MARKETING_PREFERENCES,
              ],
            },
          ],
        },

        {
          title: 'Assets',
          path: RoutesManager.assetManager.assets.root.getURL(),
          permissions: [PERMISSIONS.ASSET_READ],
          entitlements: [ENTITLEMENTS.DATA_DISCOVERY],
        },
        {
          title: 'Relationships',
          path: RoutesManager.assetManager.relationshipMapping.list.getURL(),
          permissions: [PERMISSIONS.ASSET_RELATIONSHIP_READ],
          entitlements: [ENTITLEMENTS.DATA_DISCOVERY],
        },
        {
          title: 'Labels',
          path: RoutesManager.systems.labels.root.getURL(),
          permissions: [PERMISSIONS.LABEL_READ],
          entitlements: [ENTITLEMENTS.DATA_MAPPING, ENTITLEMENTS.DATA_DISCOVERY],
          subRoutes: [
            {
              title: 'Classifications',
              path: RoutesManager.systems.labels.classifications.getURL(),
              description: '',
              permissions: [PERMISSIONS.DATAMAP_READ],
              entitlements: [ENTITLEMENTS.DATA_MAPPING, ENTITLEMENTS.DATA_DISCOVERY],
            },
            {
              title: 'Data Categories',
              path: RoutesManager.systems.labels.dataCategories.getURL(),
              description: '',
              permissions: [PERMISSIONS.DATAMAP_READ],
              entitlements: [ENTITLEMENTS.DATA_MAPPING, ENTITLEMENTS.DATA_DISCOVERY],
            },
          ],
        },
      ],
    },
    {
      sectionId: SidebarSection.Reports,
      title: 'Reports',
      description:
        'Get instant insight into your data-verse, how DSAR requests are addressed and what’s your current consent statistics.',
      path: RoutesManager.insights.root.getURL(),
      unselectedIcon: <Icon name={'ONavReports'} width={24} height={24} />,
      selectedIcon: <Icon name={'FNavReports'} width={24} height={24} />,
      permissions: [],
      entitlements: [],
      subRoutes: [
        {
          title: 'ROPA',
          path: RoutesManager.insights.dataMap.root.getURL(),
          permissions: [PERMISSIONS.DATAMAP_READ],
          entitlements: [ENTITLEMENTS.STATS_DATAMAP],
        },
        {
          title: 'Rights',
          path: isLocalizedUrl
            ? RoutesManager.insights.rights.chineseTranslation.getURL({ code: 'zh' })
            : RoutesManager.insights.rights.root.getURL(),
          permissions: [PERMISSIONS.RIGHT_INSIGHTS_READ],
          entitlements: [ENTITLEMENTS.STATS_RIGHTS],
        },
        {
          title: 'Consent',
          path: isLocalizedUrl
            ? RoutesManager.insights.permits.chineseTranslation.getURL({ code: 'zh' })
            : RoutesManager.insights.permits.root.getURL(),
          permissions: [PERMISSIONS.PERMIT_INSIGHTS_READ],
          entitlements: [ENTITLEMENTS.STATS_CONSENT],
        },
        {
          title: 'Audit Log',
          path: RoutesManager.insights.auditLogs.root.getURL(),
          permissions: [PERMISSIONS.PERMIT_INSIGHTS_READ, PERMISSIONS.RIGHT_INSIGHTS_READ],
          requireAllPermissions: false, // "false" here allows access to the route if any one of the permissions are met.  The default, "true", requires all permissions to be met.
          entitlements: [ENTITLEMENTS.STATS_CONSENT, ENTITLEMENTS.STATS_RIGHTS],
        },
      ],
    },
  ]

  return routes
}
