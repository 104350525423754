import { IdentityFrom } from 'pages/orchestration/workflows/edit/interfaces/IImportContextParams'
import * as Yup from 'yup'

interface ImportContextValidationParams {
  usedCodes?: string[]
  multipleIdentitiesFlagEnabled: boolean
}

export const getImportContextValidationSchema = (params: ImportContextValidationParams) => {
  if (params.multipleIdentitiesFlagEnabled) {
    return Yup.object().shape({
      code: Yup.string()
        .notOneOf(params?.usedCodes || [], 'This code is already in use')
        .required('Required'),
      description: Yup.string().required('Required'),
      databaseResourceId: Yup.string().required('Required'),
      identityType: Yup.number().required('Required'),
      identitiesFrom: Yup.array().of(
        Yup.object()
          .shape({
            code: Yup.string()
              .required('Code is required')
              .test('is-non-empty', 'Code cannot be empty', value => !!(value && value.trim().length > 0)),
            name: Yup.string()
              .required('Name is required')
              .test('is-non-empty', 'Name cannot be empty', value => !!(value && value.trim().length > 0)),
          })
          .required('Must select an identity'),
      ),
      identityToCode: Yup.string().required('Required'),
      query: Yup.string()
        .test('', 'Required', value => Boolean(value))
        .test('', 'Must include all identities', (value, ctx) => {
          const identitiesFrom = ctx?.parent?.identitiesFrom || []
          return (
            identitiesFrom.every((identity: IdentityFrom) => value?.indexOf(`{{${identity.code}}}`) !== -1) &&
            value?.indexOf(`{{${ctx?.parent?.identityToCode}}}`) !== -1
          )
        })
        .test(
          '',
          'Must not include INSERT or UPDATE',
          value => value?.toLowerCase()?.indexOf('insert') === -1 && value?.toLowerCase()?.indexOf('update') === -1,
        ),
      assignee: Yup.string().required('Required'),
    })
  }

  return Yup.object().shape({
    code: Yup.string()
      .notOneOf(params?.usedCodes || [], 'This code is already in use')
      .required('Required'),
    description: Yup.string().required('Required'),
    databaseResourceId: Yup.string().required('Required'),
    identityType: Yup.number().required('Required'),
    identityFromCode: Yup.string().required('Required'),
    identityToCode: Yup.string().required('Required'),
    query: Yup.string()
      .test('', 'Required', value => Boolean(value))
      .test('', 'Must include all identities', (value, ctx) => {
        return (
          value?.indexOf(`{{${ctx?.parent?.identityFromCode}}}`) !== -1 &&
          value?.indexOf(`{{${ctx?.parent?.identityToCode}}}`) !== -1
        )
      })
      .test(
        '',
        'Must not include INSERT or UPDATE',
        value => value?.toLowerCase()?.indexOf('insert') === -1 && value?.toLowerCase()?.indexOf('update') === -1,
      ),
    assignee: Yup.string().required('Required'),
  })
}
