import { IconDictionary, CustomIconName } from '@ketch-com/deck'
import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'

export const getWorkflowStepIconNameFromActivityCode = (activityCode?: WorkflowActivityCode): CustomIconName => {
  switch (activityCode) {
    case WorkflowActivityCode.SET_PERMITS:
      return IconDictionary.WPermits
    case WorkflowActivityCode.API_CALL:
    case WorkflowActivityCode.API_CALL_V2:
      return IconDictionary.WApi
    case WorkflowActivityCode.EMAIL:
      return IconDictionary.WEmail
    case WorkflowActivityCode.TASK:
      return IconDictionary.WTask
    case WorkflowActivityCode.APPROVAL:
      return IconDictionary.WApprove
    case WorkflowActivityCode.DATA_SUBJECT_EMAIL:
      return IconDictionary.WEmailData
    case WorkflowActivityCode.IDENTITY_VERIFICATION:
      return IconDictionary.WId
    case WorkflowActivityCode.START:
      return IconDictionary.WStart
    case WorkflowActivityCode.END:
    case WorkflowActivityCode.END_ALT:
      return IconDictionary.WEnd
    case WorkflowActivityCode.DECISION_BOOLEAN:
      return IconDictionary.WGDecisionBool
    case WorkflowActivityCode.DECISION_MULTI:
      return IconDictionary.WGDecisionMulti
    case WorkflowActivityCode.IMPORT_CONTEXT:
      return IconDictionary.WImportContext
    case WorkflowActivityCode.INTERNAL_SYSTEM_DSR:
      return IconDictionary.WExecuteDsr
    default:
      return IconDictionary.WTask
  }
}
