import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { APIListRequestParams } from 'api/common/utils'
import { FormFieldCategoryDTO } from '@ketch-com/figurehead'
import { Ordering } from 'api/common/utils'
import { fetchFormFields } from '../fetchers/fetchFormFields'
import { FormFieldDTO } from '@ketch-com/figurehead'

type Params = APIListRequestParams<{
  start?: number
  category?: FormFieldCategoryDTO
  query?: string
  includeMetadata?: boolean
  includeDeleted?: boolean
  ordering?: Ordering<'created_at'> | Ordering<'title'>
  includeAuthenticators?: boolean
}>

type Options = {
  enabled?: boolean
  itemsPerPage?: number
}

export const useFormFieldsInfinite = ({ options, params }: { options?: Options; params: Params }) => {
  const { itemsPerPage: limit = 20 } = options || {}

  const resp = useInfiniteQuery({
    enabled: !!options?.enabled,
    queryKey: [
      ApiQueryKeys.formFieldsPaginated,
      {
        includeMetadata: true,
        limit,
        start: 0,
        ...params,
      },
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchDatasetsResp = await fetchFormFields({
        limit,
        start: (pageParam - 1) * limit,
        ...params,
      })

      const { data } = fetchDatasetsResp

      data.formFields = data.formFields ?? []
      data.totalResults = data.totalResults ?? 0

      return data
    },
    getNextPageParam: ({ totalResults }, allPages) => {
      let totalAssetsFetchedSoFar = 0
      allPages?.forEach?.(p => {
        totalAssetsFetchedSoFar += p?.formFields?.length || 0
      })
      const nextPageParam = Math.ceil(totalAssetsFetchedSoFar / limit) + 1
      const getNextPageParamReturnValue = totalAssetsFetchedSoFar === totalResults ? undefined : nextPageParam
      return getNextPageParamReturnValue
    },
  })

  let infiniteFormFields: FormFieldDTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    infiniteFormFields = [...infiniteFormFields, ...(page?.formFields || [])]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: infiniteFormFields,
    totalResults,
  }
}
