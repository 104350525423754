import React from 'react'
import { useSetState } from 'react-use'
import { useNavigate } from 'react-router-dom'

import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { SENSITIVE_DEPLOYMENT_PLANS_STATUSES } from 'interfaces/deploymentPlans-v2/deploymentPlanStatus'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { RoutesManager } from 'utils/routing/routesManager'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { Group } from 'components/ui-layouts/group/Group'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Text } from 'components/ui-kit/typography/Text'
import { Button } from 'components/ui-kit/button/Button'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'
import { DeploymentPlanDeleteModal } from 'pages/consentAndRights/deploymentPlans-v2/modals/DeploymentPlanDeleteModal'
import { FirstNameLastNameRenderer } from 'components/renderers/FirstNameLastNameRenderer'
import { Typography } from '@mui/material'

type Props = {
  deploymentPlan: DeploymentPlanDTO
}

export const DeploymentPlanViewActions: React.FC<Props> = ({ deploymentPlan }) => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()

  const [{ isDeleteOpen, isEditConfirmationOpen }, setState] = useSetState({
    isExportCodeOpen: false,
    isDeleteOpen: false,
    isEditConfirmationOpen: false,
  })

  const { code, metadata, status, audit } = deploymentPlan
  const isPermittedToManageDeploymentPlans = isPermitted(PERMISSIONS.DEPLOY_WRITE)
  const isPermittedToDeploy = isPermitted(PERMISSIONS.DEPLOY_ADMIN)
  const isInSensitiveStatus = SENSITIVE_DEPLOYMENT_PLANS_STATUSES.includes(status)

  // Deployment plan preview descoped for experience server rework, see below
  // const isPreviewActive = (policyScopeExperiences || []).some(({ experienceID }) => !!experienceID)

  const handleRedirectToEditDeploymentPlan = () => {
    navigate(RoutesManager.deployment.deploymentPlansV2.upsert.root.getURL({ code }))
  }

  return (
    <>
      <Group>
        <Group gap={3}>
          <Text size={12} color="grey" weight={500}>
            Last Updated
          </Text>
          <Typography variant="smallBody">
            <FormattedDateRenderer date={metadata?.updatedAt} />
            <FirstNameLastNameRenderer
              firstName={audit?.updatedBy?.firstName}
              lastName={audit?.updatedBy?.lastName}
              renderEmpty={false}
              prefix="by"
              textVariant="smallBody"
            />
          </Typography>
        </Group>

        {/* Deployment plan preview descoped for experience server rework */}
        {/* <Button
          disabled={!isPreviewActive}
          tooltip={!isPreviewActive ? 'Add experiences to the plan to preview' : null}
          variant="tertiary"
          size="big"
          onClick={() => {
            navigate(RoutesManager.deployment.deploymentPlansV2.preview.root.getURL({ code }))
          }}
        >
          Preview
        </Button> */}

        {isPermittedToManageDeploymentPlans && (
          <>
            <Button
              disabled={isInSensitiveStatus}
              tooltip={isInSensitiveStatus ? 'Deployed plans cannot be deleted' : null}
              variant="tertiary"
              size="big"
              onClick={() => {
                setState({ isDeleteOpen: true })
              }}
            >
              Delete
            </Button>

            <Button
              variant="secondary"
              size="big"
              onClick={() => {
                if (isInSensitiveStatus) {
                  setState({ isEditConfirmationOpen: true })
                } else {
                  handleRedirectToEditDeploymentPlan()
                }
              }}
            >
              Edit
            </Button>

            <Button
              disabled={!isPermittedToDeploy}
              tooltip={
                !isPermittedToDeploy
                  ? 'You do not have sufficient permissions to perform this action, please contact your admin'
                  : null
              }
              size="big"
              onClick={() => {
                navigate(RoutesManager.deployment.deploymentPlansV2.deploy.root.getURL({ code }))
              }}
            >
              Deploy
            </Button>
          </>
        )}
      </Group>

      {isDeleteOpen && (
        <DeploymentPlanDeleteModal
          deploymentPlan={deploymentPlan}
          onDelete={() => {
            navigate(RoutesManager.deployment.deploymentPlansV2.root.getURL())
          }}
          onClose={() => {
            setState({ isDeleteOpen: false })
          }}
        />
      )}

      {isEditConfirmationOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.DEPLOYMENT_PLAN}
          onSubmit={handleRedirectToEditDeploymentPlan}
          onCancel={() => {
            setState({ isEditConfirmationOpen: false })
          }}
        />
      )}
    </>
  )
}
