import { WorkflowExecutionDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  workflowExecutionId: string
}

type Response = { workflowExecution: WorkflowExecutionDTO }

export const fetchWorkflowExecution = ({ organizationCode, workflowExecutionId }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/executions/${workflowExecutionId}`,
    }),
  )
