import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { updateProcessingActivityAssessment } from '../fetchers/updateProcessingActivityAssessment'

const useCustomMutation = createUseMutation({
  mutationFn: updateProcessingActivityAssessment,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateProcessingActivityAssessment = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
