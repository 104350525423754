import { API } from 'utils/api/api'
import { APIListResponse, APIRequestParams } from 'api/common/utils'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  organizationCode: string
  userId: string
}>

type Response = APIListResponse<{
  permissionCodes: string[]
}>

export const fetchPermissions = ({ organizationCode, userId }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/members/${userId}/permissions`,
    }),
  )
