import { makeStyles, createStyles } from '@mui/styles'

export const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      databaseColumn: {
        maxWidth: 286,
        borderRight: `1px solid ${palette.iron.main}`,
        paddingBottom: spacing(2),
        overflowY: 'scroll',
        maxHeight: '100%',
      },
      plusButton: {
        borderRadius: '50%',
        height: spacing(3.5),
        width: spacing(3.5),
        padding: spacing(2),
        marginLeft: spacing(2),
        minWidth: 'unset',
      },
      tableContainer: {
        overflowY: 'scroll',
        maxHeight: '100%',
      },
    }),
  { name: 'DsrTransponderForm' },
)
