import React, { useState } from 'react'

import { Group } from 'components/ui-layouts/group/Group'
import { Button } from 'components/ui-kit/button/Button'
import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'
import { DeletePolicyModal } from 'pages/assetManager/components/PoliciesTablePaginated/components/DeletePolicyModal'
import { useNavigate } from 'react-router-dom'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  policy: AssetPolicyDTO
}

export const PolicyViewActions: React.FC<Props> = ({ policy }) => {
  const [isDeletePolicyModalOpen, setIsDeletePolicyModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWritePolicy = getIsPermitted([PERMISSIONS.ASSET_POLICY_WRITE])

  return (
    <>
      <Group>
        <Group gap={8}>
          <Text size={12} color="grey" weight={500}>
            Last Updated
          </Text>
          <Text size={12} weight={500}>
            <FormattedDateRenderer date={policy?.metadata?.updatedAt} />
          </Text>
        </Group>

        <Button
          onClick={() => setIsDeletePolicyModalOpen(true)}
          variant="tertiary"
          size="big"
          disabled={!isPermittedToWritePolicy}
          tooltip={isPermittedToWritePolicy ? null : 'You do not have policy edit permissions.'}
        >
          Delete
        </Button>

        <Button
          onClick={() => {
            navigate(RoutesManager.policyCenter.policies.upsert.getURL({ id: policy.id }))
          }}
          variant="secondary"
          size="big"
          disabled={!isPermittedToWritePolicy}
          tooltip={isPermittedToWritePolicy ? null : 'You do not have policy edit permissions.'}
        >
          Edit
        </Button>
      </Group>

      {isDeletePolicyModalOpen && (
        <DeletePolicyModal
          policyId={policy?.id || ''}
          onCancel={() => setIsDeletePolicyModalOpen(false)}
          onSubmit={async () => {
            setIsDeletePolicyModalOpen(false)
            navigate(RoutesManager.policyCenter.policies.list.getURL())
          }}
        />
      )}
    </>
  )
}
