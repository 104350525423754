import React, { createContext } from 'react'
import { UsePolicyScopeViewUtils, usePolicyScopeViewUtils } from '../hooks/usePolicyScopeViewUtils'

export const PolicyScopeViewContext = createContext({} as UsePolicyScopeViewUtils)

type Props = {
  children?: React.ReactNode
}

export const PolicyScopeViewContextProvider: React.FC<Props> = ({ children }) => {
  // Use the hook
  const hookValues = usePolicyScopeViewUtils()

  return (
    <PolicyScopeViewContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </PolicyScopeViewContext.Provider>
  )
}

export const withPolicyScopeViewContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <PolicyScopeViewContextProvider>
        <Component {...props} />
      </PolicyScopeViewContextProvider>
    )
  }
}
