import React, { useState, useEffect } from 'react'
import { useDebounce, useKeyPress } from 'react-use'
import { useNavigate } from 'react-router-dom'

import { FILTER_STATES } from '../components/MegaFilter/constants'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setFilterState, setSearchQuery, clearSearchQuery } from 'store/megaFilterStateSlice'
import { getMegaFilterState } from 'store/megaFilterStateSlice/selectors'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { useQueryParams } from 'utils/hooks/useQueryParams'

type Args = {
  assets: AssetSummaryDTO[]
}

export const useAssetsListViewUtils = ({ assets }: Args) => {
  const { queries, removeQueryParam } = useQueryParams<{ targetId: string }>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { filterState, searchQuery, displayMode } = useAppSelector(getMegaFilterState)
  const [searchString, setSearchString] = useState<string>(() => {
    return searchQuery
  })
  const [selectedAssets, setSelectedAssets] = useState<string[]>([])
  /* key press listener for bulk asset select/update */
  const predicate: any = (event: any) => {
    if (event.key.toLowerCase() === 'escape') {
      return true
    }
  }
  const [isEscapePressed] = useKeyPress(predicate)

  /* clear selection set on "Escape" press */
  useEffect(() => {
    if (isEscapePressed && selectedAssets.length > 0) {
      setSelectedAssets([])
      dispatch(setFilterState(FILTER_STATES.OPEN))
    }
  }, [isEscapePressed, selectedAssets.length, dispatch])

  const handleAssetOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const { currentTarget, metaKey } = event
    const { id } = currentTarget
    /* "metaKey" is "cmd" on mac, "windows key" on windows */
    if (metaKey) {
      setSelectedAssets(prevSelectedAssets => {
        const payload = new Set([...prevSelectedAssets, id])
        return [...payload]
      })
    } else {
      const payload = [id]
      setSelectedAssets(payload)
    }
  }

  const handleOnClear = (e: React.MouseEvent) => {
    e.preventDefault()
    setSelectedAssets([])
    dispatch(setFilterState(FILTER_STATES.OPEN))
  }

  const handleSelectAll = (e: React.MouseEvent) => {
    e.preventDefault()
    setSelectedAssets(assets.map(assetSummary => assetSummary?.asset?.resource?.id || ''))
  }

  /* debounce search query input */
  useDebounce(
    () => {
      if (searchString) {
        dispatch(setSearchQuery(searchString))
      } else {
        dispatch(clearSearchQuery())
      }
    },
    500,
    [searchString],
  )
  /* synchronize filter state based on if there are selected assets */
  useEffect(() => {
    if (selectedAssets.length > 0 && filterState !== FILTER_STATES.SELECTING)
      dispatch(setFilterState(FILTER_STATES.SELECTING))
  }, [selectedAssets, filterState, dispatch])

  React.useEffect(() => {
    const assetId = queries?.targetId || ''
    const targetElement = document.getElementById(assetId || '') as HTMLElement
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'auto' })
      removeQueryParam('targetId')
    } else {
      removeQueryParam('targetId')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    navigate,
    searchString,
    setSearchString,
    displayMode,
    filterState,
    selectedAssets,
    setSelectedAssets,
    handleAssetOnClick,
    handleOnClear,
    handleSelectAll,
  }
}
