import { useState } from 'react'
import { debounce } from 'lodash'

import { usePurposes } from 'api/purposes/queries/usePurposes'

export const usePurposesSearchOptions = () => {
  const [queryValue, setQueryValue] = useState('')

  const {
    data: purposes,
    isLoading: isLoadingResources,
    isFetching: isFetchingResources,
  } = usePurposes({
    enabled: !!queryValue,
    params: {
      query: queryValue,
    },
  })

  return {
    purposeQueryValue: queryValue,
    setSearchPurposeValue: debounce(searchValue => {
      setQueryValue(searchValue)
    }, 500),
    isLoadingPurposes: isLoadingResources || isFetchingResources,
    purposes,
    queryValue,
  }
}
