import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  dataSubjectTypeCode: string
  includeMetadata?: boolean
}>

type Response = { dataSubjectType: DataSubjectTypeDTO }

export const fetchDataSubjectType = ({ dataSubjectTypeCode, includeMetadata }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/data-subject-types/${dataSubjectTypeCode}`,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
  )
