import { createStyles, makeStyles } from '@mui/styles'

export const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      icon: {
        height: 24,
        width: 24,
        marginRight: spacing(1.5),
      },
      iconMedium: {
        height: 18,
        width: 18,
      },
      iconSmall: {
        height: 15,
        width: 15,
        marginTop: spacing(0.125),
      },
      dropdown: {
        backgroundColor: palette.white.main,
        border: 0,
        fontSize: 20,
        fontWeight: 700,
        padding: spacing(0, 0.5),
      },
      subtitle: {
        paddingLeft: spacing(0.75),
        color: palette.darkDuskFaded.main,
        display: 'block',
      },
      connectButton: {
        backgroundColor: palette.white.main,
      },
      chip: {
        marginRight: spacing(1),
        marginBottom: spacing(1),
      },
    }),
  { name: 'DataRisks' },
)
