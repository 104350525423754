import { StepDetailsIdentitySpaceDTO } from '@ketch-com/figurehead'
import * as Yup from 'yup'

export const identitySpaceFieldValidator = Yup.array().of(
  Yup.object().shape({
    value: Yup.string().test({
      name: 'identitySpace',
      test: (value, context: any) => {
        const inputConfig = context?.from?.[0]?.value as StepDetailsIdentitySpaceDTO

        if (inputConfig?.isRequired && !value) return context?.createError({ message: 'Required' })

        return true
      },
    }),
  }),
)

export const getIdentitySpacesValidationSchema = () => {
  return Yup.object().shape({
    identitySpaces: identitySpaceFieldValidator,
  })
}
