import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchOrganization } from 'api/organizations/fetchers/fetchOrganization'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.organization,
  queryFn: fetchOrganization,
  select: res => res?.data?.organization || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useOrganization = (config: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
