import { PurposeDTO } from 'interfaces/purposes/purpose'
import { ICanvasStep, ISetPermitsActivityParams } from 'pages/orchestration/workflows/edit/interfaces'
import { isSetPermitsActivityParamsTypeGuard } from 'pages/orchestration/workflows/edit/interfaces/typeGuards'

interface IGetSetPermitsInitialValuesParams {
  step: ICanvasStep
  purposes: PurposeDTO[]
}

export const getSetPermitsInitialValues = (params: IGetSetPermitsInitialValuesParams): ISetPermitsActivityParams => {
  const { step, purposes } = params

  if (isSetPermitsActivityParamsTypeGuard(step.activity?.params)) {
    const description = step.description || ''
    const code = step.code || ''

    return {
      description,
      code,
      permitDetails: purposes.map(purpose => {
        // Note: Here, we must go through a messy mapping process because
        // the tile form state must use purpose.ID as a unique identifier, but the
        // BE expects the payload to use purpose.code in activity.params.permitDetails.
        const targetIndex = step?.activity?.params?.permitDetails?.findIndex(permitDetail => {
          return Object.keys(permitDetail)?.[0] === purpose.code
        })
        const targetPermitDetail =
          typeof targetIndex === 'number' ? step?.activity?.params?.permitDetails?.[targetIndex] : undefined
        const permitDetailEntries = Object.entries(targetPermitDetail || {})
        const purposeId = purposes.find(purpose => purpose.code === permitDetailEntries?.[0]?.[0])?.ID
        const targetPermitDetailPayload = Object.fromEntries([[purposeId, permitDetailEntries?.[0]?.[1]]])
        return targetPermitDetail
          ? targetPermitDetailPayload
          : {
              [purpose?.ID as string]: '1',
            }
      }),
    }
  }

  return {
    description: '',
    code: '',
    permitDetails: purposes.map(purpose => {
      return {
        [purpose?.ID as string]: '1',
      }
    }),
  }
}
