import React from 'react'

import { useThemesPaginated } from 'api/themes-v3/queries/useThemesPaginated'
import { ThemesList } from 'pages/consentAndRights/themes-v3/list/ThemesList'

export const ThemesV3ListContainer: React.FC = () => {
  const {
    data: themes,
    isLoading,
    isFetching,
    pagination,
    refetch: refetchThemes,
  } = useThemesPaginated({
    params: {
      includeMetadata: true,
    },
  })

  return (
    <ThemesList
      isLoading={isLoading}
      isFetching={isFetching}
      themes={themes}
      pagination={pagination}
      refetchThemes={refetchThemes}
    />
  )
}
