import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans/deploymentPlan'
import { useDeleteDeploymentPlan } from 'api/deploymentPlans/mutations/useDeleteDeploymentPlan'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { Text } from 'components/ui-kit/typography/Text'
import { showToast } from 'components/ui-kit/toastr/Toastr'

type Props = {
  deploymentPlan: DeploymentPlanDTO
  onClose: () => void
  onDelete?: () => void
}

export const DeploymentPlanDeleteModal: React.FC<Props> = ({ deploymentPlan, onClose, onDelete = onClose }) => {
  const queryClient = useQueryClient()

  const { name, code } = deploymentPlan
  const [isBusy, setIsBusy] = useState(false)

  const { mutate: handleDeleteDeploymentPlan } = useDeleteDeploymentPlan({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onDelete?.()
      showToast({ content: 'Deployment plan deleted', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to delete deployment plan', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <Dialog
      isSaving={isBusy}
      title="Delete Deployment Plan"
      submitBtnLabel="Delete"
      cancelBtnLabel="Close"
      onSubmitBtnClick={() => {
        handleDeleteDeploymentPlan({
          params: {
            deploymentPlanCode: code!,
          },
        })
      }}
      onCancelBtnClick={onClose}
    >
      <Text size={14}>
        Are you sure you want to delete <strong>{name || code}</strong> deployment plan?
      </Text>
    </Dialog>
  )
}
