import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { FindSubscriptionTopicsResponseBodyDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces/common'

type Params = APIListRequestParams<{
  query?: string | null | undefined
}>

type Response = APIListResponse<FindSubscriptionTopicsResponseBodyDTO>

export const fetchSubscriptionTopics = ({ includeMetadata, start, limit, query }: Params) =>
  API.get<MaybeNull<Response>>(
    formatRequestString({
      entityUrl: `/api/subscriptions/topics`,
      params: {
        filters: {
          start,
          limit,
          ...(query && {
            query,
          }),
          ...(includeMetadata && {
            includeMetadata,
          }),
        },
      },
    }),
  )
