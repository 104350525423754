import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { checkIfSubscriptionTopicCodeAvailable } from 'api/subscriptions/fetchers/checkIfSubscriptionTopicCodeAvailable'

const useCustomMutation = createUseMutation({
  mutationFn: checkIfSubscriptionTopicCodeAvailable,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCheckIfSubscriptionCodeAvailable = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
