import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { TableCell } from '@ketch-com/deck'
import { CustomRightNameDTO } from '@ketch-com/figurehead'
import { JurisdictionCell } from '../components/JurisdictionCell'
import { CustomRightNameListDropdown } from '../list/CustomRightNameListDropdown'

export const getCustomRightNameListColumns = ({
  setCurrentRowItem,
}: {
  setCurrentRowItem: React.Dispatch<any>
}): GridColDef<CustomRightNameDTO>[] => [
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Custom Name',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<CustomRightNameDTO>) => {
      return <TableCell title={row.name} />
    },
  },
  {
    align: 'left',
    field: 'jurisdiction',
    flex: 0.4,
    headerAlign: 'left',
    headerName: 'Jurisdiction',
    sortable: false,
    renderCell: ({ row: { jurisdictionRights } }: GridRenderCellParams<CustomRightNameDTO>) => {
      return (
        <TableCell>
          <JurisdictionCell jurisdictionRights={jurisdictionRights} />
        </TableCell>
      )
    },
  },
  {
    align: 'right',
    field: 'dropDown',
    flex: 1,
    headerAlign: 'left',
    headerName: ' ',
    sortable: false,
    maxWidth: 100,
    renderCell: ({ row: { id } }: GridRenderCellParams<CustomRightNameDTO>) => {
      return (
        <TableCell>
          <CustomRightNameListDropdown id={id!} setCurrentRowItem={setCurrentRowItem} />
        </TableCell>
      )
    },
  },
]
