import { APIListResponse } from 'api/common/utils'
import { ApplianceDTO } from 'interfaces/appliances'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Response = APIListResponse<{ appliances: ApplianceDTO[] }>

export const fetchAppliances = () =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/appliances`,
    }),
  )
