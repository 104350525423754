import { useContext, useEffect, useMemo, useState } from 'react'
import { DataMapVisualization } from 'pages/insights/dataMap/components/DataMapVisualization'
import { DataMapContext, withDataMapContext } from '../context/DataMapContext'
import { Outlet, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { Tab, TabGroup } from '@ketch-com/deck'
import { Box } from '@mui/material'

const DataMapWithContext: React.FC = () => {
  const navigate = useNavigate()

  const { location, visualizationData } = useContext(DataMapContext)
  const tabs = useMemo(
    () => [
      {
        label: 'Purposes',
        link: {
          pathname: RoutesManager.insights.dataMap.purposes.root.getURL(),
          search: location.search,
        },
      },
      {
        label: 'Processing Activities',
        link: {
          pathname: RoutesManager.insights.dataMap.processingActivities.root.getURL(),
          search: location.search,
        },
      },
    ],
    [location.search],
  )

  const [currentTab, setCurrentTab] = useState(0)
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  useEffect(() => {
    navigate(tabs[currentTab].link)
  }, [tabs, currentTab, navigate])

  return (
    <>
      <DataMapVisualization visualizationData={visualizationData} />
      <Box display="flex" flexDirection="column" gap={3} width="1280px">
        <TabGroup value={currentTab} onChange={handleChange} showBottomDivider>
          {tabs.map(elem => (
            <Tab {...elem} />
          ))}
        </TabGroup>
        <Outlet />
      </Box>
    </>
  )
}

export const DataMap = withDataMapContext(DataMapWithContext)
