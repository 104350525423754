import React from 'react'

import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { Box } from '@mui/material'

interface ISidebarSearchProps {
  search: string
  setSearch: (value: string) => void
}

export const SidebarSearch: React.FC<ISidebarSearchProps> = ({ search, setSearch }) => {
  return (
    <Box
      sx={{
        paddingBottom: '18px',
        borderBottom: theme => `1px solid ${theme.palette.iron.main}`,
      }}
    >
      <SearchTextInput minWidth="auto" size="medium" value={search!} onChange={value => setSearch(value)} />
    </Box>
  )
}
