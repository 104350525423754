import React, { createContext } from 'react'
import { ExperienceViewUtils, useExperienceViewUtils } from '../utils/useExperienceViewUtils'

export const ExperienceViewContext = createContext({} as ExperienceViewUtils)

type Props = {
  children?: React.ReactNode | undefined
}

export const ExperienceViewContextProvider: React.FC<Props> = ({ children }) => {
  // Use utils hook
  const hookValues = useExperienceViewUtils()

  return <ExperienceViewContext.Provider value={{ ...hookValues }}>{children}</ExperienceViewContext.Provider>
}

export const withExperienceViewContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <ExperienceViewContextProvider>
        <Component {...props} />
      </ExperienceViewContextProvider>
    )
  }
}
