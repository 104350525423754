import { APIRequestParams } from 'api/common/utils'
import { OrganizationDTO, OrganizationFormDTO } from 'interfaces/organizations/organization'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  currentOrganizationCode: string
  includeMetadata?: boolean
  formData: OrganizationFormDTO
}>

type Response = { organization: OrganizationDTO }

// TODO - remove. create of organization should only be done through provisioning flow or ketch admin
export const createOrganization = ({ currentOrganizationCode, includeMetadata, formData }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${currentOrganizationCode}/organizations`,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
    formData,
  )
