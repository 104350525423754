import { POLICY_EFFECT_URL_ENUM } from '../constants'

export const getMapEffectUrlToString = (value: string) => {
  switch (value) {
    case POLICY_EFFECT_URL_ENUM.MASK:
      return 'mask'
    case POLICY_EFFECT_URL_ENUM.HASH:
      return 'hash'
    case POLICY_EFFECT_URL_ENUM.DENY:
      return 'deny'
    default:
      return ''
  }
}
