import React from 'react'

import { EditFormField, CreateFormField } from './components'
import { useIsEditMode } from 'utils/hooks'
import { useRedirectIfNotEntitledToExperienceServerRights } from 'pages/consentAndRights/forms/hooks'

export const FormFieldUpsertContainer: React.FC = () => {
  const isEditMode = useIsEditMode()

  useRedirectIfNotEntitledToExperienceServerRights()

  return isEditMode ? <EditFormField /> : <CreateFormField />
}
