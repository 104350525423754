import React from 'react'
import { Box } from '@mui/material'

import { Error } from 'components/ui-kit/form/common/error/Error'
import { Input } from 'components/ui-kit/input/Input'
import { Checkbox } from 'components/ui-kit/checkbox/Checkbox'
import { DropdownV2 } from 'components/ui-kit/dropdown/DropdownV2'
import { useAttributeTableActionCellRendererUtils } from './hooks'
import { getShouldShowBooleanInput, getShouldShowCustomValueInput } from './utils'
import { AssetDTO } from '@ketch-com/figurehead'

type Props = {
  activeDatabase: string
  attributeCode: string
  datasetCode: string
  attribute: AssetDTO
}

export const AttributeTableActionCellRenderer: React.FC<Props> = ({
  attributeCode,
  datasetCode,
  activeDatabase,
  attribute,
}) => {
  const {
    attributeDataType,
    errors,
    handleCustomValueOnChange,
    handleEffectSelectOnChange,
    policyStatements,
    targetEffectValue,
    obligationOptions,
    isAccessFlow,
    hasEffectAssigned,
    handleEffectOnToggle,
  } = useAttributeTableActionCellRendererUtils({
    attributeCode,
    datasetCode,
    activeDatabase,
    attribute,
  })

  return (
    <Box py={0.5} display="flex" flexDirection="column">
      {/* Effect Select Input */}

      <Box display="flex" alignItems="center" justifyContent="flexStart">
        {isAccessFlow ? (
          <Checkbox value={hasEffectAssigned} onChange={handleEffectOnToggle} />
        ) : (
          <DropdownV2
            size="small"
            placeholder="Select"
            value={targetEffectValue}
            onChange={handleEffectSelectOnChange}
            items={obligationOptions.map(o => {
              return {
                id: o.id,
                title: o.name,
              }
            })}
          />
        )}
      </Box>

      {/* Custom Value Update Input */}

      {getShouldShowCustomValueInput({ attributeDataType, targetEffectValue }) && (
        <Box mt={1.25}>
          <Input
            size="small"
            value={policyStatements?.[activeDatabase]?.statements?.[attributeCode]?.value || ''}
            onChange={handleCustomValueOnChange}
            name={`policyStatements.[${activeDatabase}].statements.[${attributeCode}].value`}
            placeholder="Custom value"
            valid={errors?.isValid}
          />
        </Box>
      )}

      {/* Boolean Input */}

      {getShouldShowBooleanInput({ attributeDataType, targetEffectValue }) && (
        <Box mt={1.25}>
          <DropdownV2
            size="small"
            value={targetEffectValue}
            onChange={handleEffectSelectOnChange}
            items={[
              {
                id: 'false',
                title: 'False',
              },
              {
                id: 'true',
                title: 'True',
              },
            ]}
          />
        </Box>
      )}

      {/* Error Message */}

      {errors?.message && (
        <Box mt={0.75}>
          <Error>{errors?.message}</Error>
        </Box>
      )}
    </Box>
  )
}
