import * as Yup from 'yup'

export const getDsrIdentityLookupFormValidation = () => {
  return Yup.object().shape({
    isPrimaryDataset: Yup.boolean(),
    relatedDatasetHasIdentitySpace: Yup.boolean(),
    primaryAttributeIdentitySpaceCode: Yup.string().test({
      name: 'identity',
      params: {},
      message: 'Required',
      test: (value, context) => Boolean(context.parent.isPrimaryDataset && value),
    }),
    primaryAttributeId: Yup.string().test({
      name: 'attribute',
      params: {},
      message: 'Required',
      test: (value, context) => Boolean(context.parent.isPrimaryDataset && value),
    }),
    datasetId: Yup.string(),
  })
}
