import React, { useContext } from 'react'
import { Box } from '@mui/material'
import { Tracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import pluralize from 'pluralize'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { Chip, PopUp, Button, Icon, IconDictionary } from '@ketch-com/deck'
import { ApprovedTrackerPropertiesContext } from 'pages/policyCenter/trackers/context'
import { ApprovedTrackerProperties } from 'pages/policyCenter/trackers/components'

type Props = {
  tracker: Tracker
}

export const Properties: React.FC<Props> = ({ tracker = {} as Tracker }, ref?: React.ForwardedRef<HTMLSpanElement>) => {
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const { trackerKeys, setTrackerKeys, isTrackerPropertiesLoading, trackerProperties } = useContext(
    ApprovedTrackerPropertiesContext,
  )

  return (
    <Box>
      <Chip
        size="small"
        disabled={!isPermittedToWriteCookie || !!trackerKeys?.encodedTrackerKey}
        label={`${tracker.numProperties} ${pluralize('Property', tracker.numProperties)}`}
        clickable={isPermittedToWriteCookie}
        deleteIcon={<Icon name={IconDictionary.OArrowCRight} />}
        onDelete={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          setTrackerKeys({ trackerKey: tracker?.key, encodedTrackerKey: tracker?.encodedKey })
        }}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          setTrackerKeys({ trackerKey: tracker?.key, encodedTrackerKey: tracker?.encodedKey })
        }}
      />

      {trackerKeys?.encodedTrackerKey ? (
        <PopUp
          isLoading={isTrackerPropertiesLoading}
          popUpWidth="820px"
          variant="modal"
          onClose={e => {
            setTrackerKeys(null)
          }}
          showCloseButton
          title={tracker?.key}
          subTitle={`This tracker is found on ${trackerProperties?.length} ${pluralize(
            'property',
            trackerProperties?.length,
          )}`}
          popUpActionChildren={
            <Button color="secondary" onClick={e => setTrackerKeys(null)}>
              Close
            </Button>
          }
        >
          <ApprovedTrackerProperties trackerKeys={trackerKeys} properties={trackerProperties} />
        </PopUp>
      ) : null}
    </Box>
  )
}
