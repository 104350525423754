import Box from '@mui/material/Box'
import { SxProps, Typography } from '@mui/material'
import { Icon } from '@ketch-com/deck'
import { useContext } from 'react'
import { BuilderContext } from '../../../Builder'
import { SectionEmptyState } from '../../SectionEmptyState'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from '../../../../../../../themes-v3/upsert/utils/useThemeValidationSchema'
import { BannerContainerLayout, BannerContainerPosition } from '@ketch-sdk/ketch-types'
import { BUILDER_CONSTANTS } from '../../../utils/enums'
import { clamp } from 'lodash'

const positionWidth = {
  [BannerContainerPosition.Bottom]: '920px',
  [BannerContainerPosition.Top]: '920px',
  [BannerContainerPosition.LeftCorner]: '248px',
  [BannerContainerPosition.RightCorner]: '248px',
  [BannerContainerPosition.BottomMiddle]: '480px',
  [BannerContainerPosition.Center]: '480px',
}

export const BannerHeader: React.FC = () => {
  const { themeConfig, experienceLayoutConfig, experienceEnglishConfig } = useContext(BuilderContext)

  const layout = getIn(themeConfig, themeUpsertFormKeys.bannerContainerLayout) as BannerContainerLayout
  const position = getIn(themeConfig, themeUpsertFormKeys.bannerContainerPosition) as BannerContainerPosition

  const headerContainerSx: SxProps = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: '30px',
  }

  return experienceLayoutConfig.banner?.header?.visible ? (
    <Box sx={headerContainerSx}>
      {layout === BannerContainerLayout.Centered && (
        <Box
          mr={layout === BannerContainerLayout.Centered ? '0px' : '30px'}
          width={2}
          height={20}
          visibility={'hidden'}
          flex={'0 1 auto'}
        />
      )}
      <Box flex={'0 1 auto'} sx={{ wordWrap: 'break-word' }} maxWidth={positionWidth[position]}>
        <Typography
          variant={'h3'}
          color={getIn(themeConfig, themeUpsertFormKeys.bannerHeaderTitleColor)}
          textAlign={layout === BannerContainerLayout.Centered ? 'center' : 'left'}
        >
          {experienceEnglishConfig.banner?.header?.title}
        </Typography>
      </Box>
      {experienceLayoutConfig.banner?.header?.closeButtonVisible && (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          width={30}
          height={30}
          minWidth={30}
          minHeight={30}
          flex={'0 1 auto'}
          sx={{
            backgroundColor: getIn(themeConfig, themeUpsertFormKeys.bannerHeaderReturnButtonFillColor),
            borderRadius: getIn(themeConfig, themeUpsertFormKeys.bannerHeaderReturnButtonCornerRadius) + 'px',
          }}
        >
          <Icon
            name={'OCross'}
            iconColor={getIn(themeConfig, themeUpsertFormKeys.bannerHeaderReturnButtonIconColor)}
            sx={{
              borderRadius: clamp(
                getIn(themeConfig, themeUpsertFormKeys.bannerHeaderReturnButtonCornerRadius),
                BUILDER_CONSTANTS.MIN_BORER_RADIUS,
                BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
              ),
            }}
          />
        </Box>
      )}
    </Box>
  ) : (
    <SectionEmptyState section={'Header'} sx={{ mt: '-6px', mb: '2px' }} />
  )
}
