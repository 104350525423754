export enum ContextVariableCategoryEnum {
  DATA_SUBJECT_VARIABLE = '1',
  OUTCOME_VARIABLE = '2',
  FORM_FIELD_VARIABLE = '3',
}

export enum ContextVariableCategory {
  DATA_SUBJECT_VARIABLE = 1,
  OUTCOME_VARIABLE = 2,
}

export const CONTEXT_VARIABLE_NAMES: {
  [key: number]: string
} = {
  [ContextVariableCategoryEnum.DATA_SUBJECT_VARIABLE]: 'Data Subject Variable',
  [ContextVariableCategoryEnum.OUTCOME_VARIABLE]: 'Outcome Variable',
}

export const CONTEXT_VARIABLE_NAME_TO_ENUM: {
  [key: string]: string
} = {
  data_subject_variable: ContextVariableCategoryEnum.DATA_SUBJECT_VARIABLE,
  outcome_variable: ContextVariableCategoryEnum.OUTCOME_VARIABLE,
}

export const CONTEXT_VARIABLE_OPTIONS = [
  {
    id: CONTEXT_VARIABLE_NAMES[ContextVariableCategoryEnum.DATA_SUBJECT_VARIABLE],
    name: CONTEXT_VARIABLE_NAMES[ContextVariableCategoryEnum.DATA_SUBJECT_VARIABLE],
  },
  {
    id: CONTEXT_VARIABLE_NAMES[ContextVariableCategoryEnum.OUTCOME_VARIABLE],
    name: CONTEXT_VARIABLE_NAMES[ContextVariableCategoryEnum.OUTCOME_VARIABLE],
  },
]
