import Box from '@mui/material/Box'

import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { Chip } from 'components/ui-kit/chip/Chip'
import { Dropdown } from 'components/ui-kit/dropdown/Dropdown'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { InlineEditLayout } from 'pages/assetManager/assets/view/hooks/useAssetDetailContentRows/components'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { useIdentitySpace } from 'api/identitySpaces/queries/useIdentitySpace'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { UseInlineEditUtilsReturnType } from '../hooks'

type Props = {
  assetSummary?: AssetSummaryDTO
  inlineEditUtils: UseInlineEditUtilsReturnType
}

export const IdentitySpace: React.FC<Props> = ({ assetSummary, inlineEditUtils }) => {
  const hasIdentitySpace = !!assetSummary?.asset?.identitySpace?.code

  const { data: identitySpaces, isLoading: isLoadingIdentitySpaces } = useIdentitySpaces()
  const { data: identitySpace, isLoading: isLoadingIdentitySpace } = useIdentitySpace({
    enabled: hasIdentitySpace,
    params: {
      identitySpaceCode: assetSummary?.asset?.identitySpace?.code || '',
      includeMetadata: false,
    },
  })

  const dropdownValueIdentitySpace = identitySpaces.find(({ code }) => code === values?.identitySpace)

  const {
    errors,
    handleSubmit,
    isUpdatingDatabase,
    values,
    resetForm,
    setFieldValue,
    initialValues,
    editRow,
    setEditRow,
  } = inlineEditUtils

  const isLoadingAggregate = isLoadingIdentitySpace || isLoadingIdentitySpaces

  return (
    <InlineEditLayout
      editModeKey="IdentitySpace"
      setEditRow={setEditRow}
      key={assetSummary?.asset?.identitySpace?.code || 'unset-identity-space'}
      isLoadingRequiredFormData={isLoadingAggregate}
      pending={isUpdatingDatabase}
      onSubmit={handleSubmit}
      error={errors?.dataCategory ? <Error>{errors?.description}</Error> : undefined}
      resetForm={resetForm}
      resetInputOnCancel={() => {
        setFieldValue('identitySpace', initialValues.identitySpace)
      }}
      isEditMode={editRow === 'IdentitySpace'}
      displayModeContent={
        !!assetSummary?.asset?.identitySpace?.code ? (
          isLoadingAggregate ? (
            <Box display="flex" alignItems="center">
              <Spinner size={16} />
            </Box>
          ) : (
            <Chip>{identitySpace?.name || identitySpace?.code}</Chip>
          )
        ) : (
          <EmptyValueRenderer color="darkDuskFaded" />
        )
      }
      editModeContent={
        <Dropdown
          disabled={isUpdatingDatabase}
          placeholder="Select Identifier"
          value={values?.identitySpace}
          items={identitySpaces.map(idSpace => ({
            active: values?.identitySpace === idSpace.code,
            content: idSpace.name,
            value: idSpace.ID,
            onClick: () => setFieldValue('identitySpace', idSpace.code),
          }))}
        >
          {dropdownValueIdentitySpace?.name}
        </Dropdown>
      }
    />
  )
}
