import React from 'react'

import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { getApplicationEntityName } from 'utils/messages/entityName'
import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'
import { Banner, BannerProps } from 'components/ui-kit/banner/Banner'

type Props = Omit<BannerProps, 'children'> & {
  actions?: any
  entityType: ApplicationEntity
}

export const getBannerText = (entityType: ApplicationEntity) => {
  switch (entityType) {
    case ApplicationEntity.PERMIT_PROPAGATION:
      return 'Purposes for this property have not been configured yet.'

    case ApplicationEntity.WEBTAG:
      return 'Purposes for this tag have not been configured yet.'

    case ApplicationEntity.DPIA:
      return 'Ketch recommends you conduct a DPIA given the canonical purpose chosen for this purpose.'

    case ApplicationEntity.POLICY_ON_HOLD:
      return "This policy is on hold because it's not assigned to any workflow."

    default: {
      const entityName = getApplicationEntityName({ entityType })

      return `${entityName.singular} configurations need to be updated`
    }
  }
}

export const IssuesMessage: React.FC<Props> = ({ actions, entityType, variant = 'failure', ...rest }) => (
  <ContentBoundaries width={1280}>
    <Banner actions={actions} {...rest} variant={variant}>
      {getBannerText(entityType)}
    </Banner>
  </ContentBoundaries>
)
