import { useCallback } from 'react'
import { FormTemplateSectionDTO, FormTemplateSectionTranslationDTO } from '@ketch-com/figurehead'
import { LanguageDTO } from 'interfaces/languages/language'

export type GenerateFormTemplateSectionDTOArgs = {
  enabledLanguages?: LanguageDTO[]
}

export const useGenerateEmptyFormTemplateSection = () => {
  const generateEmptyFormTemplateSectionDTO = useCallback(
    ({ enabledLanguages }: GenerateFormTemplateSectionDTOArgs): FormTemplateSectionDTO => {
      const translations = (enabledLanguages || [])?.reduce(
        (acc, curr) => {
          acc[curr.code] = {
            description: '',
            title: '',
          }
          return acc
        },
        {} as Record<string, FormTemplateSectionTranslationDTO>,
      )

      const payload = {
        description: '',
        formFields: [],
        title: '',
        translations,
      }

      return payload
    },
    [],
  )

  return generateEmptyFormTemplateSectionDTO
}
