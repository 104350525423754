import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { updateOrganization } from 'api/organizations/fetchers/updateOrganization'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomMutation = createUseMutation({
  mutationFn: updateOrganization,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateOrganization = (config?: Config) => {
  const { userData } = useAuth()
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    currentOrganizationCode: userData.organizationCode,
  })
}
