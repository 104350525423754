import { PutAssetRequestBodyDTO } from '@ketch-com/figurehead'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'

import { getLabelDtoArrayFromManageLabelModalLabelArray } from 'pages/assetManager/utils'
import { getSchedulePayload } from './getSchedulePayload'

type Args = { values: EditDatabaseFormValues; identitySpaces: IdentitySpaceDTO[] }

export const mapValuesToPayload = ({ values, identitySpaces }: Args): PutAssetRequestBodyDTO => {
  let identitySpacePayload: IdentitySpaceDTO | undefined
  if (values.identitySpace) {
    identitySpacePayload = identitySpaces.find(identitySpace => identitySpace.code === values.identitySpace)
  }

  const preHookPayload = (values?.hooks || [])?.filter?.(hook => hook.type === 1)
  const postHookPayload = (values?.hooks || [])?.filter?.(hook => hook.type === 2)
  const schedulePayload = getSchedulePayload(values?.schedule)

  return {
    assetSummary: {
      asset: {
        description: values.description,
        owner: values.dataOwner,
        assignedLabels: getLabelDtoArrayFromManageLabelModalLabelArray(values.labels),
        inferredLabels: getLabelDtoArrayFromManageLabelModalLabelArray(values.inferredLabels),
        ...(values.dataCategory && {
          dataCategory: [
            {
              isSystemLabel: true,
              code: 'data_category',
              value: values.dataCategory,
              name: 'DataCategory',
            },
          ],
        }),
        ...(preHookPayload && {
          dmlPreHooks: [...preHookPayload],
        }),
        ...(postHookPayload && {
          dmlPostHooks: [...postHookPayload],
        }),
        purposes: [...values.purposes],
        ...(Object.keys(schedulePayload).length > 0 && {
          schedule: {
            ...schedulePayload,
          },
        }),
        ...(!!identitySpacePayload && {
          identitySpace: {
            code: identitySpacePayload?.code,
            name: identitySpacePayload?.name,
          },
        }),
      },
    },
  }
}
