import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { createStyles, makeStyles } from '@mui/styles'
import pluralize from 'pluralize'

import { MaybeNull } from 'interfaces'
import { AssetPolicyDTO, AssetSummaryDTO } from '@ketch-com/figurehead'
import { AssetManagerWidgetLayout } from 'pages/assetManager/components'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ReactComponent as PurposesEmptyStateIcon } from 'assets/icons/policies-widget-empty-state-icon.svg'
import { useResourcePoliciesPaginatedV2 } from 'api/policies/queries/useResourcePoliciesPaginatedV2'
import { PolicyChip, PolicyOverviewModal } from './components'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      searchInput: {
        border: 'none',
        borderBottom: `1px solid ${palette.iron.main}`,
        borderRadius: 0,
      },
      emptyState: {
        backgroundColor: palette.whiteFaded.main,
        padding: spacing(4, 0),
        minHeight: 0,
      },
    }),
  { name: 'PoliciesWidget' },
)

// TODO:BAC - remove this once ready for production
export const dummyPolicies = [
  { id: '10-93453-4052-3', name: 'Policy 1' },
  { id: '23-348290-23442', name: 'Policy 2' },
]

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

// TODO:BAC - This component is mostly complete, but, still contains dev dummy data references because the BE is not ready.
// currently, this component is hard-coded never to display, so, it will never be rendered.
export const PoliciesWidget: React.FC<Props> = ({ assetSummary }) => {
  const classes = useStyles()
  const [targetPolicyId, setTargetPolicyId] = useState<string>('')
  const { data: resourcePolicies, isLoading: isLoadingResourcePolicies } = useResourcePoliciesPaginatedV2({
    enabled: !!assetSummary?.asset?.resource?.id,
    itemsPerPage: 1000,
    params: {
      resourceCode: assetSummary?.asset?.resource?.id || '',
    },
  })

  const policiesCount = resourcePolicies?.length ?? 0

  const handlePolicyOnClick: React.MouseEventHandler<HTMLDivElement> = e => {
    setTargetPolicyId((e.target as Element)?.id || '')
  }

  // const targetPolicy = dummyPolicies.find(policy => policy.id === targetPolicyId)
  const targetPolicy = resourcePolicies?.find?.(policy => policy?.id === targetPolicyId)

  return (
    <AssetManagerWidgetLayout
      onEdit={() => {}} // TODO:BAC - implement toggle visible. This Policies widget has been de-scoped.  Not currently rendered to UI.
      isReady={!isLoadingResourcePolicies}
      title="Policies"
      subTitle={`${policiesCount} active ${pluralize('policy', policiesCount || 0)} assigned.`}
    >
      <Box display="flex" alignItems="center" gap={1}>
        {/* {dummyPolicies.map(policy => {
          return <PolicyChip key={policy?.name} onClick={handlePolicyOnClick} policy={policy as AssetPolicyDTO} />
        })} */}

        {policiesCount > 0 ? (
          resourcePolicies.map(policy => {
            return <PolicyChip key={policy?.name} onClick={handlePolicyOnClick} policy={policy} />
          })
        ) : (
          <EmptyState
            className={classes.emptyState}
            title="No policies assigned"
            description="This asset has no policies assigned."
            titleTextVariant="faded"
            descriptionTextVariant="faded"
            variant="page"
            icon={<PurposesEmptyStateIcon />}
          />
        )}
      </Box>

      {targetPolicyId ? (
        <PolicyOverviewModal policy={targetPolicy as AssetPolicyDTO} onCancel={() => setTargetPolicyId('')} />
      ) : null}
    </AssetManagerWidgetLayout>
  )
}
