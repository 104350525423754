import { Box, styled } from '@mui/material'
import { PurposesFooter } from './components/PurposesFooter'
import { PurposesHeader } from './components/PurposesHeader'
import { PurposesList } from './components/PurposesList'
import { PurposesListHeader } from './components/PurposesListHeader'
import { SelectableSection } from '../../../../SelectableSection'
import { useContext } from 'react'
import { BuilderContext } from '../../../../../Builder'
import { PreferenceExperienceBuilderSection, PreferenceThemeBuilderSection } from '../../../../../utils/enums'

const PurposesTabBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

export const PurposesTab: React.FC = () => {
  const { isTheme } = useContext(BuilderContext)

  return (
    <PurposesTabBox>
      <Box>
        {/* Header section (theme only) */}
        <SelectableSection section={isTheme ? PreferenceThemeBuilderSection.PurposesTabHeader : undefined}>
          <PurposesHeader />
        </SelectableSection>
        {/* List header section (theme only) */}
        <SelectableSection section={isTheme ? PreferenceThemeBuilderSection.PurposesTabListHeader : undefined}>
          <PurposesListHeader />
        </SelectableSection>
        {/* List section */}
        <SelectableSection
          section={
            isTheme ? PreferenceThemeBuilderSection.PurposesTabList : PreferenceExperienceBuilderSection.PurposesTabList
          }
        >
          <PurposesList />
        </SelectableSection>
      </Box>
      {/* Footer section (theme only) */}
      <SelectableSection section={isTheme ? PreferenceThemeBuilderSection.PurposesTabFooter : undefined}>
        <PurposesFooter />
      </SelectableSection>
    </PurposesTabBox>
  )
}
