import { APIRequestParams } from 'api/common/utils'
import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  organizationCode: string
  themeCode: string
  version: string
}>

type Response = { theme: ThemeDTO }

export const fetchThemeVersion = ({ organizationCode, themeCode, version, includeMetadata }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/themes/${themeCode}/versions/${version}`,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
  )
