import { StepDetailsIdentitySpacesListItemDTO } from '@ketch-com/figurehead'
import { styled, Box, Typography } from '@mui/material'
import { AppealBadge } from '../../components'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

export const IdentityItemWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  wordBreak: 'break-all',
}))

type Props = {
  identities?: StepDetailsIdentitySpacesListItemDTO[]
}
export const IdentitySpacesContentV2: React.FC<Props> = ({ identities = [] }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {identities.map((id, index) => {
        return (
          <IdentityItemWrapper key={id.identitySpaceCode}>
            <Box display="flex" alignItems="center" flexWrap="wrap" gap={0.75} sx={{ overflowWrap: 'break-all' }}>
              <Typography
                component="div"
                variant="smallLabel"
                color="darkDuskFaded.main"
                sx={{ overflowWrap: 'break-all', width: '100%', textTransform: 'capitalize' }}
              >
                {id.identitySpaceCode}
              </Typography>
              {id.isAppealValue && <AppealBadge />}
            </Box>
            {!!id.identityValue ? (
              <Typography sx={{ overflowWrap: 'break-all', width: '100%' }}>{id.identityValue}</Typography>
            ) : (
              <EmptyValueRenderer />
            )}
          </IdentityItemWrapper>
        )
      })}
    </Box>
  )
}
