import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { PolicyScopeTemplateDTO } from 'interfaces/policyScopes/policyScope'
import { APIListRequestParams, APIListResponse } from 'api/common/utils'

type Params = APIListRequestParams<{
  includeIssues?: boolean
}>

type Response = APIListResponse<{ policyScopes?: PolicyScopeTemplateDTO[] }>

export const fetchPolicyScopeTemplates = ({ includeMetadata, includeIssues, start, limit }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: '/api/policy-scopes/templates',
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
        },
      },
    }),
  )
