import React from 'react'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces'
import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'
import { usePolicyScopeTemplates } from 'api/policyScopes/queries/usePolicyScopeTemplates'
import { useRegulations } from 'api/regulations/queries/useRegulations'
import { PolicyScopeLibraryView } from 'pages/policyCenter/policyScopes/library/PolicyScopeLibraryView'

export const PolicyScopeLibraryContainer: React.FC = () => {
  const navigate = useNavigate()

  const {
    data: policyScopeTemplates,
    isLoading: isPolScoLoading,
    isFetching: isPolScoFetching,
  } = usePolicyScopeTemplates()
  const { data: regulations, isLoading: isRegulationsLoading, isFetching: isRegulationsFetching } = useRegulations()

  const handleSubmit = async (values: { templateCode: MaybeNull<string> }) => {
    const { templateCode } = values

    if (templateCode) {
      navigate(
        RoutesManager.policyCenter.policyScopes.upsert.root.getURL({
          code: NEW_ITEM_CODE,
          templateCode,
        }),
      )
    } else {
      navigate(RoutesManager.policyCenter.policyScopes.upsert.root.getURL({ code: NEW_ITEM_CODE }))
    }
  }

  return (
    <PolicyScopeLibraryView
      templates={policyScopeTemplates}
      regulations={regulations}
      isLoading={isPolScoLoading || isRegulationsLoading}
      isFetching={isPolScoFetching || isRegulationsFetching}
      onSubmit={handleSubmit}
    />
  )
}
