import { isUndefined } from 'lodash'

import {
  WorkflowActivityConnectorCode,
  WorkflowActivityGatewayMode,
} from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep, IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { omitDisconnectedSteps } from 'pages/orchestration/workflows/edit/utils/steps/omitDisconnectedSteps'
import { getUuidWithoutHyphensAndStartingWithALetter } from '../getUuidWithoutHyphensAndStartingWithALetter'
import { createConnectedNest } from './createConnectedNest'
import { isNewStepConnectedNestStart } from './isNewStepConnectedNestStart'

export interface ICreateStepParams {
  workflow?: IWorkflowConfig
  parentStep: ICanvasStep
  stepToCreate: ICanvasStep
  transitionIndex: number
  standardJoinActivity: ICanvasStep
}

export interface ICreateStep {
  newSteps: Array<ICanvasStep>
  newStep: ICanvasStep
}

export const createStep = (params: ICreateStepParams): ICreateStep => {
  const { workflow, transitionIndex, parentStep, stepToCreate, standardJoinActivity } = params

  // End Flow steps must have unique `code` property since we are to allow multiple in a single workflow
  const isNewStepFinish = !isUndefined(stepToCreate.finish)
  if (isNewStepFinish) {
    stepToCreate.code = getUuidWithoutHyphensAndStartingWithALetter()
  }

  let newStep: ICanvasStep = {
    ...stepToCreate,
    valid: isNewStepFinish,
    ID: getUuidWithoutHyphensAndStartingWithALetter(),
  }

  const updatedSteps: Array<ICanvasStep> = []

  workflow!.steps?.forEach?.(workflowStep => {
    const isStart = !isUndefined(workflowStep.start)
    const isActivity = !isUndefined(workflowStep.activity)
    const isWorkflow = !isUndefined(workflowStep.workflow)
    const isPlaceholder = !isUndefined(workflowStep.placeholder)
    const isGateway = !isUndefined(workflowStep.gateway)
    const isNonJoinGateway = isGateway && workflowStep?.gateway?.mode !== WorkflowActivityGatewayMode.JOIN
    const isEnd =
      !isUndefined(workflowStep.finish) ||
      workflowStep.workflow?.temporalFunctionName === WorkflowActivityConnectorCode.END

    if (workflowStep.ID !== parentStep.ID) {
      return updatedSteps.push(workflowStep)
    }

    if (isStart) {
      updatedSteps.push({
        ...workflowStep,
        start: {
          transition: newStep.ID,
        },
      })
    }

    if (isActivity) {
      updatedSteps.push({
        ...workflowStep,
        activity: {
          ...workflowStep.activity,
          transition: newStep.ID,
        } as ICanvasStep['activity'],
      })
    }

    if (isWorkflow) {
      updatedSteps.push({
        ...workflowStep,
        workflow: {
          ...workflowStep.workflow,
          transition: newStep.ID,
        } as ICanvasStep['workflow'],
      })
    }

    if (isPlaceholder) {
      updatedSteps.push({
        ...workflowStep,
        placeholder: {
          ...workflowStep.placeholder,
          transition: newStep.ID,
        },
      })
    }

    if (isGateway) {
      if (isNonJoinGateway) {
        updatedSteps.push({
          ...workflowStep,
          gateway: {
            ...workflowStep.gateway,
            transitions: workflowStep!.gateway!.transitions.map((transitionChild, index) => ({
              ...transitionChild,
              ID: transitionIndex === index ? newStep.ID : transitionChild?.ID,
            })),
          } as ICanvasStep['gateway'],
        })
      } else {
        updatedSteps.push({
          ...workflowStep,
          gateway: {
            ...workflowStep.gateway,
            transitions: [{ ID: newStep.ID }],
          } as ICanvasStep['gateway'],
        })
      }
    }

    if (isEnd) {
      updatedSteps.push({
        ...workflowStep,
        finish: {
          transition: newStep.ID,
        },
      })
    }
  })

  const isNewStepConnectedNest = isNewStepConnectedNestStart({ step: newStep, steps: workflow?.steps || [] })

  if (isNewStepConnectedNest) {
    const { gatewayStep, placeholderA, placeholderB, joinStep } = createConnectedNest({
      stepToCreate,
      mode: stepToCreate.gateway?.mode!,
      standardJoinActivity,
      steps: workflow?.steps || [],
    })
    newStep = { ...newStep, ...gatewayStep }
    updatedSteps.push(placeholderA)
    updatedSteps.push(placeholderB)
    if (joinStep) updatedSteps.push(joinStep)
  }

  const createStepPayload = {
    newSteps: omitDisconnectedSteps({ steps: [...updatedSteps, newStep] }),
    newStep,
  }

  return createStepPayload
}
