import React from 'react'
import Box from '@mui/material/Box'
import { useDeleteFormField } from 'api/formFields/mutations/useDeleteFormField'
import { showToast } from 'components/modals/AlertComponent'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { FormFieldDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { ApiQueryKeys } from 'api/common/queryKeys'
import Typography from '@mui/material/Typography'
import { FormFieldModalUsedInSection } from './FormFieldModalUsedInSection'
import { useFormFieldsRelationshipData } from '../hooks'
import { Button, PopUp } from '@ketch-com/deck'

type Props = {
  onCancel: () => void
  formField?: FormFieldDTO
}

export const DeleteFormFieldModalV3: React.FC<Props> = ({ onCancel, formField }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { isAllowedToDelete } = useFormFieldsRelationshipData(formField)

  const { mutateAsync: handleDeleteFormField, isLoading: isDeletingFormField } = useDeleteFormField({
    onSuccess: () => {
      showToast({ content: 'Form field deleted', type: 'success' })
      queryClient.invalidateQueries(ApiQueryKeys.formFieldsPaginated)
      navigate(RoutesManager.deployment.forms.fields.root.getURL())
      onCancel()
    },
  })

  const handleSubmit = isAllowedToDelete
    ? () => {
        handleDeleteFormField({
          params: {
            formFieldId: formField?.id || '',
          },
        })
      }
    : undefined

  return (
    <PopUp
      title="Delete Form Field"
      variant={isAllowedToDelete ? 'dialog' : 'modal'}
      popUpWidth={isAllowedToDelete ? '550px' : '684px'}
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isDeletingFormField} onClick={onCancel}>
            Close
          </Button>

          <Button color="primary" size="large" pending={isDeletingFormField} onClick={handleSubmit}>
            Delete
          </Button>
        </>
      }
    >
      <Box display="flex" alignItems="center">
        {isAllowedToDelete ? (
          <Typography variant="body">
            Are you sure want to delete <Typography variant="label">{formField?.name}</Typography> form field?
          </Typography>
        ) : (
          <Typography variant="body">
            To delete the <Typography variant="label">{formField?.name}</Typography> Form Field, you must remove it from
            any deployed forms, experiences, enabled workflows, and authenticators.
          </Typography>
        )}
      </Box>
      {/* Relationship Table (if not allowed to delete) */}
      {isAllowedToDelete ? null : <FormFieldModalUsedInSection formField={formField} showOnlyActiveRelationships />}
    </PopUp>
  )
}
