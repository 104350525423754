import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useApp } from 'api/apps/queries/useApp'
import { useCanonicalPurposes } from 'api/purposes/queries/useCanonicalPurposes'
import { useUpdateAppsInstancesPurposes } from 'api/apps/mutations/useUpdateAppsInstancesPurposes'
import { RoutesManager } from 'utils/routing/routesManager'
import { PermitPropagationFormValues, mapValuesToPayload } from 'pages/consentAndRights/consent/systems/edit/utils'
import { PermitPropagationEdit } from 'pages/consentAndRights/consent/systems/edit/PermitPropagationEdit'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { PurposeDataRole } from 'interfaces/purposes/purposeDataRole'

export const PermitPropagationEditContainer: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()

  const { data: appDescriptor, isLoading: isAppLoading } = useApp({
    params: {
      appId: id!,
    },
    onError: () => {
      navigate(RoutesManager.orchestration.consent.systems.root.getURL())
    },
  })

  const { data: canonicalPurposes, isLoading: isCanonicalPurposesLoading } = useCanonicalPurposes({
    params: { includeDataSubjectTypes: true, filterDataRole: PurposeDataRole.CONTROLLER },
  })

  const { mutateAsync: handleUpdateAppsInstancesPurposes } = useUpdateAppsInstancesPurposes()

  const onSubmit = async (values: PermitPropagationFormValues) => {
    try {
      const formData = mapValuesToPayload(values)

      await handleUpdateAppsInstancesPurposes({
        params: {
          formData,
        },
      })

      showToast({ content: 'Permit updated', type: 'success' })
      await queryClient.refetchQueries([ApiQueryKeys.app, { appId: id }])
      navigate(RoutesManager.orchestration.consent.systems.view.root.getURL({ id }))
    } catch (e) {
      showToast({ content: 'Failed to update permit', type: 'error' })
    }
  }

  return (
    <PermitPropagationEdit
      isReady={!isAppLoading && !isCanonicalPurposesLoading}
      onSubmit={onSubmit}
      appDescriptor={appDescriptor}
      canonicalPurposes={canonicalPurposes}
    />
  )
}
