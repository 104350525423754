import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from '../../common/paginatedQuery'
import { ApiQueryKeys } from '../../common/queryKeys'
import { fetchProcessingActivityFilters } from '../fetchers/fetchProcessingActivityFilters'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.processingActivityFilters,
  queryFn: fetchProcessingActivityFilters,
  select: res => res?.data || {},
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useProcessingActivityFilters = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
