import React from 'react'
import { useSetState } from 'react-use'

import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { Button } from '@ketch-com/deck'
import { WorkflowExecutionStepApproveModalContainer } from '../approveStep/WorkflowExecutionStepApproveModalContainer'

type Props = {
  workflowExecutionStep: WorkflowExecutionStepDTO
}

export const WorkflowExecutionApprovalStepActions: React.FC<Props> = ({ workflowExecutionStep }) => {
  const [{ isApprovalOpen }, setState] = useSetState({
    isApprovalOpen: false,
  })

  return (
    <>
      <Button
        color="tertiary"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()

          setState({ isApprovalOpen: true })
        }}
      >
        View
      </Button>

      {isApprovalOpen && (
        <WorkflowExecutionStepApproveModalContainer
          workflowExecutionStep={workflowExecutionStep}
          onClose={() => {
            setState({ isApprovalOpen: false })
          }}
        />
      )}
    </>
  )
}
