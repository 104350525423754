import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIListRequestParams } from '../../common/utils'
import { GVLResponse } from '@ketch-com/supercargo/dist/gvl_gen.schemas'

type Params = APIListRequestParams<{
  region?: string
}>

export const fetchGlobalVendorList = ({ region }: Params) =>
  API.get<GVLResponse>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/gvl/${region}`,
      params: {
        filters: {},
      },
    }),
  )
