import * as Yup from 'yup'

interface IValidationParams {
  usedCodes?: Array<string>
}

export const getApiCallValidationSchema = (params: IValidationParams) =>
  Yup.object().shape({
    code: Yup.string()
      .notOneOf(params?.usedCodes || [], 'This code is already in use')
      .required('This field is required'),
    description: Yup.string().required('Description is required'),
    webhookId: Yup.string().required('Required'),
    // TODO readd after support
    // requestTimeout: Yup.number()
    //   .typeError('Request Timeout must be a number')
    //   .required('Please enter a number of minutes')
    //   .test('Please enter a value between 1 and 4000', 'Please enter a value between 1 and 4000', value => {
    //     return (value || 0) > 0 && (value || 0) <= 4000
    //   }),
    assignee: Yup.string().required('Assignee is required'),
  })
