import React, { CSSProperties, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces'
import { AppDescriptorDTO, AppFormDTO, AppInstanceDTO, AppInstanceFieldDTO } from 'interfaces/apps'

import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { RoutesManager } from 'utils/routing/routesManager'
import { Button, ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import {
  AppFormSectionBasicDetails,
  AppFormSectionFields,
  AppFormSectionIdentitySpaces,
} from 'pages/dataSystems/ConnectionNew/AddConnectionForm/components'

import { getAppInstanceFormValues } from './utils'
import { GoogleSignInButton } from 'components/ui-kit/button/GoogleSignInButton'
import { Link } from 'components/ui-kit/link/Link'
import { Box, Typography, styled } from '@mui/material'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormikProvider, useFormik } from 'formik'
import { useGetEditConnectionFormValidationSchema } from './hooks/useValidationSchema'

const DisclaimerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: '0 0 1rem 0',
}))

type Props = {
  isReady: boolean
  isEditMode: boolean
  appDescriptor: MaybeNull<AppDescriptorDTO>
  appInstance: MaybeNull<AppInstanceDTO>
  appInstanceFields: AppInstanceFieldDTO[]
  identitySpaces: IdentitySpaceDTO[]
  onSubmit: (values: AppFormDTO) => Promise<void>
  className?: string
  style?: CSSProperties
}

export const AddConnectionFormEdit: React.FC<Props> = ({
  isReady,
  isEditMode,
  appDescriptor,
  appInstance,
  appInstanceFields,
  identitySpaces,
  onSubmit,
}) => {
  const navigate = useNavigate()

  const app = appDescriptor?.app
  const marketplaceEntity = appDescriptor?.marketplaceEntity
  const appName = app?.name || ''
  const requiresGoogleOAuth = ['Google Analytics', 'Google Marketing'].includes(appName)

  const validationSchema = useGetEditConnectionFormValidationSchema({ isEditMode, appDescriptor })
  const initialValues = useMemo(
    () => getAppInstanceFormValues({ app, appInstance, appInstanceFields }),
    [app, appInstance, appInstanceFields],
  )

  const breadcrumbs = [
    { title: 'Systems', link: RoutesManager.systems.root.getURL() },
    { title: appName || '', link: RoutesManager.systems.id.root.getURL({ id: app?.ID }) },
    { title: 'Edit Connection' },
  ]

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })

  const { isSubmitting, values, isValid, submitForm } = formikProps

  const hasManagedItems = !!values?.identitySpaces?.filter(({ managed }) => !!managed)?.length
  const hasUserInput = !!values?.identitySpaces?.filter(({ userIdentitySpaceCode }) => !!userIdentitySpaceCode)?.length
  const doesNotRequireIdentityInput = !!(app && !app.identitySpaces?.length)
  const isIdentitySpacesListValid = hasManagedItems || hasUserInput || doesNotRequireIdentityInput
  const isSaveDisabled = isSubmitting || !isValid || !isIdentitySpacesListValid

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={UpsertLayoutMode.edit}
          renderFormTitle={() => (
            <ContentGroup title={`Edit Connection ${appName || ''} `} titleVariant="h2" isDivider />
          )}
          onAccept={submitForm}
          acceptButtonProps={{
            pending: isSubmitting,
            disabled: isSaveDisabled,
          }}
          acceptActionButtonText="Continue"
          onCancel={() => {
            navigate(RoutesManager.systems.id.overview.root.getURL({ id: app?.ID }))
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
          // if this condition is true, this will render instead the normal accept button
          customAcceptButton={
            requiresGoogleOAuth && <GoogleSignInButton onClick={() => submitForm()} disabled={isSaveDisabled} />
          }
        >
          {isReady ? (
            <>
              <AppFormSectionBasicDetails isEditMode={isEditMode} marketplaceEntity={marketplaceEntity} />

              <AppFormSectionFields isEditMode={isEditMode} app={app} />

              {!doesNotRequireIdentityInput && (
                <AppFormSectionIdentitySpaces app={app} identitySpaces={identitySpaces} />
              )}
              {marketplaceEntity?.docUrl && (
                <DisclaimerContainer>
                  <Typography>
                    By completing the installation, you acknowledge and accept that it operates as described in the{' '}
                    <Link outer to={marketplaceEntity?.docUrl || ''} target="_blank" variant="blue">
                      <Button variant="link" color="secondary">
                        <Typography>Documentation</Typography>
                      </Button>
                    </Link>
                    .<br />
                    You may uninstall this app at any time as described in the{' '}
                    <Link outer to={marketplaceEntity?.docUrl || ''} target="_blank" variant="blue">
                      <Button variant="link" color="secondary">
                        <Typography>Documentation</Typography>
                      </Button>
                    </Link>
                    .
                  </Typography>
                </DisclaimerContainer>
              )}
            </>
          ) : (
            <Box
              sx={{
                margin: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner size="32px" thickness={2.5} />
            </Box>
          )}
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
