import React, { useMemo } from 'react'
import { styled } from '@mui/material/styles'

import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { DeploymentPlanFormSpecialValues } from 'pages/consentAndRights/deploymentPlans-v2/upsert/utils'
import { FormDropList } from 'components/ui-kit/form/dropList/FormDropList'
import { Text } from 'components/ui-kit/typography/Text'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'

const PREFIX = 'DeploymentPlanExperienceAssignment'

const classes = {
  noExperienceText: `${PREFIX}-noExperienceText`,
}

const Root = styled('div')(({ theme: { palette } }) => ({
  [`& .${classes.noExperienceText}`]: {
    color: palette.sphere.main,
  },
}))

type Props = {
  experienceType: ExperienceType
  policyScopeCode: string
  experiences: ExperienceV2DTO[]
}

export const DeploymentPlanExperienceAssignment: React.FC<Props> = ({
  policyScopeCode,
  experienceType,
  experiences,
}) => {
  const name = `policyScopeExperiences.${policyScopeCode}.${experienceType}.experienceID`

  const items = useMemo(
    () => [
      {
        id: DeploymentPlanFormSpecialValues.NO_EXPERIENCE,
        name: DeploymentPlanFormSpecialValues.NO_EXPERIENCE,
      },
      ...experiences.filter(({ type, summary }) => type === experienceType && !!summary),
    ],
    [experiences, experienceType],
  )

  return (
    <Root>
      <FormDropList
        valueKey="id"
        name={name}
        items={items}
        placeholder="Select Experience"
        renderValue={({ id, name }) => {
          return id === DeploymentPlanFormSpecialValues.NO_EXPERIENCE ? (
            <Text className={classes.noExperienceText} size={14} weight={600} lineHeight={1.43}>
              {name}
            </Text>
          ) : (
            <NameAndCodeCellRenderer name={name} />
          )
        }}
        renderItem={({ name }) => <NameAndCodeCellRenderer name={name} />}
      />
    </Root>
  )
}
