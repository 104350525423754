export const ASSETS_TECHNOLOGY = {
  AZURE_SQL_DB: 'Azure SQL Database',
  MARIA_DB: 'MariaDB',
  MONGO_DB: 'MongoDB',
  MY_SQL: 'Mysql',
  ORACLE: 'Oracle',
  REDSHIFT: 'Redshift',
  SNOWFLAKE: 'Snowflake',
  SQL_SERVER: 'SQL Server',
}

export const ASSETS_PROVIDER_CODE = {
  AZURE_SQL_DB: 'azuresqldb',
  DOCUMENT_DB: 'documentdb',
  MARIA_DB: 'mariadb',
  MONGO_DB: 'mongodb',
  MY_SQL: 'mysql',
  ORACLE: 'oracle',
  POSTGRESQL: 'postgresql',
  REDSHIFT: 'redshift',
  SNOWFLAKE: 'snowflake',
  SQL_SERVER: 'sqlserver',
}

export const ASSETS_TYPES = {
  DATABASE: 'Database',
  DATASET: 'Dataset',
  ATTRIBUTE: 'Attribute',
  SCHEMA: 'Schema',
}
