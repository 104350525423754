import { APIListRequestParams } from 'api/common/utils'
import { FindPoliciesResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{}>

export const fetchPoliciesPaginatedV2 = ({ start, limit }: Params) =>
  API.get<FindPoliciesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/policies`,
      params: {
        filters: {
          start,
          limit,
        },
      },
    }),
  )
