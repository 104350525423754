import Box from '@mui/material/Box'

import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { DataOwnerChip } from 'pages/assetManager/components'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { InlineEditLayout } from 'pages/assetManager/assets/view/hooks/useAssetDetailContentRows/components'
import { PERSONAL_DATA_CATEGORY_OPTIONS } from 'pages/assetManager/assets/view/hooks/useAssetDetailContentRows/constants'
import { RadioGroup } from 'components/ui-kit/radioGroup/RadioGroup'
import { UseInlineEditUtilsReturnType } from '../hooks'

type Props = {
  assetSummary?: AssetSummaryDTO
  inlineEditUtils: UseInlineEditUtilsReturnType
}

export const PersonalDataTypes: React.FC<Props> = ({ assetSummary, inlineEditUtils }) => {
  const {
    errors,
    handleSubmit,
    isUpdatingDatabase,
    values,
    resetForm,
    setFieldValue,
    initialValues,
    handleChange,
    setEditRow,
    editRow,
  } = inlineEditUtils

  const hasPersonalDataTypes = (assetSummary?.asset?.dataCategory?.length || 0) > 0

  return (
    <InlineEditLayout
      editModeKey="PersonalDataTypes"
      setEditRow={setEditRow}
      pending={isUpdatingDatabase}
      onSubmit={handleSubmit}
      error={errors?.dataCategory ? <Error>{errors?.description}</Error> : undefined}
      resetForm={resetForm}
      resetInputOnCancel={() => {
        setFieldValue('dataCategory', initialValues.dataCategory)
      }}
      isEditMode={editRow === 'PersonalDataTypes'}
      displayModeContent={
        <Box display="flex" alignItems="center" gap={1}>
          {hasPersonalDataTypes ? (
            assetSummary?.asset?.dataCategory?.map(dataCategory => {
              return (
                <DataOwnerChip
                  key={`${dataCategory?.name}-${dataCategory?.value}`}
                  variant="grey"
                  text={dataCategory.value}
                />
              )
            })
          ) : (
            <EmptyValueRenderer color="darkDuskFaded" />
          )}
        </Box>
      }
      editModeContent={
        <RadioGroup
          value={values.dataCategory}
          onChange={handleChange}
          name="dataCategory"
          layout="row"
          options={PERSONAL_DATA_CATEGORY_OPTIONS}
        />
      }
    />
  )
}
