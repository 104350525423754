import { ChartTrendPeriod } from 'interfaces/rights/rightStats'

type Args = {
  startEndDifferenceInDays?: number
  trendPeriod: ChartTrendPeriod
}

export const getXAxisInterval = ({ startEndDifferenceInDays = 7, trendPeriod }: Args) => {
  const dayDifferenceDividedByThree = Math.floor((startEndDifferenceInDays - 1) / 2)

  if (trendPeriod === ChartTrendPeriod.MONTHLY_PAST_TWELVE || trendPeriod === ChartTrendPeriod.MONTHLY_PAST_CAL_YEAR)
    return 3

  if (startEndDifferenceInDays < 10) return 0

  return dayDifferenceDividedByThree
}
