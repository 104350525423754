import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  authenticatorId?: string
}

export const deleteAuthenticator = ({ authenticatorId }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/authenticators/${authenticatorId}`,
    }),
  )
