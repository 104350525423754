import { Formik } from 'formik'
// api
import { useUpsertStack } from 'api/stacks/mutations/useUpsertStack'
// components
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { TranslationsForm } from './components/TranslationsForm'
// types
import { StackDTO } from '@ketch-com/figurehead'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
type Props = {
  languages: LanguageWithStatusDTO[]
  purposeStack: StackDTO | null
}

export const PurposeStackTabTranslations: React.FC<Props> = ({ languages, purposeStack }) => {
  const { mutateAsync: upsertStack } = useUpsertStack()
  const handleSubmit = async (formData: StackDTO) => {
    await upsertStack({
      params: { code: formData.id, formData },
    })
    showToast({ content: 'Translations updated', type: 'success' })
  }
  if (!purposeStack) return null
  return (
    <Formik initialValues={purposeStack || {}} onSubmit={handleSubmit}>
      {({ submitForm }) => <TranslationsForm languages={languages} onSubmit={submitForm} display="view" />}
    </Formik>
  )
}
