import React from 'react'
import qs from 'query-string'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import { useQueryClient } from 'react-query'
import { ControlPointDuplicate, DeleteOutline, EditOutlined } from '@mui/icons-material'

import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { SENSITIVE_DEPLOYMENT_PLANS_STATUSES } from 'interfaces/deploymentPlans-v2/deploymentPlanStatus'

import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { ApiQueryKeys } from 'api/common/queryKeys'

import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'
import { DeploymentPlanDeleteModal } from 'pages/consentAndRights/deploymentPlans-v2/modals/DeploymentPlanDeleteModal'

type Props = {
  deploymentPlan: DeploymentPlanDTO
}

export const DeploymentPlansListDropdown: React.FC<Props> = ({ deploymentPlan }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { isPermitted } = useIsPermitted()
  const isPermittedToManageDeploymentPlans = isPermitted(PERMISSIONS.DEPLOY_WRITE)

  const { isEntitled } = useIsEntitled()
  const isEntitledToCreateDeploymentPlans = isEntitled(ENTITLEMENTS.QTY_DEPLOYMENT_PLAN)

  const { code, status } = deploymentPlan
  const isInSensitiveStatus = SENSITIVE_DEPLOYMENT_PLANS_STATUSES.includes(status)

  const [{ isDeleteOpen, isEditConfirmationOpen }, setState] = useSetState({
    isDeleteOpen: false,
    isEditConfirmationOpen: false,
  })

  const handleRedirectToEdit = ({ duplicate = false }: { duplicate?: boolean } = {}) => {
    navigate({
      pathname: RoutesManager.deployment.deploymentPlansV2.upsert.root.getURL({ code }),
      ...(duplicate && {
        search: qs.stringify({ duplicate: true }),
      }),
    })
  }

  return (
    <>
      <ButtonOptions
        items={[
          {
            content: 'Edit',
            icon: <EditOutlined />,
            hidden: !isPermittedToManageDeploymentPlans,
            onClick: () => {
              if (isInSensitiveStatus) {
                setState({ isEditConfirmationOpen: true })
              } else {
                handleRedirectToEdit()
              }
            },
          },
          {
            content: 'Duplicate',
            icon: <ControlPointDuplicate />,
            hidden: !isPermittedToManageDeploymentPlans || !isEntitledToCreateDeploymentPlans,
            onClick: () => {
              handleRedirectToEdit({ duplicate: true })
            },
          },
          {
            content: 'Delete',
            icon: <DeleteOutline />,
            hidden: !isPermittedToManageDeploymentPlans || isInSensitiveStatus,
            onClick: () => {
              setState({ isDeleteOpen: true })
            },
          },
        ]}
      />

      {isEditConfirmationOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.DEPLOYMENT_PLAN}
          onSubmit={handleRedirectToEdit}
          onCancel={() => {
            setState({ isEditConfirmationOpen: false })
          }}
        />
      )}

      {isDeleteOpen && (
        <DeploymentPlanDeleteModal
          deploymentPlan={deploymentPlan}
          onDelete={() => queryClient.refetchQueries(ApiQueryKeys.deploymentPlansPaginated)}
          onClose={() => {
            setState({ isDeleteOpen: false })
          }}
        />
      )}
    </>
  )
}
