import * as Yup from 'yup'

import { EventV2DTO } from '@ketch-com/figurehead'
import { DataRoleType, RightType } from 'interfaces/events'

export const getInitialValues = (): EventV2DTO => ({
  dataRole: DataRoleType.DATA_ROLE_CONTROLLER,
  dataSubjectTypeCode: '',
  rightCode: '',
  rightType: RightType.RIGHT_TYPE_CUSTOM,
  workflowCode: '',
})

export const getValidationSchema = () =>
  Yup.object().shape({
    dataRole: Yup.number().required('Required'),
    dataSubjectTypeCode: Yup.string().test('Is required', 'Required', (value, ctx) =>
      ctx?.parent?.dataRole === DataRoleType.DATA_ROLE_CONTROLLER ? !!value : true,
    ),
    rightCode: Yup.string().required('Required'),
    workflowCode: Yup.string(),
  })

export const groupDataSubjectTypeCodesByRightCode = (events: EventV2DTO[]): Record<string, string[]> => {
  const resultMap: Record<string, string[]> = {}

  events?.forEach?.(event => {
    if (event.rightCode && event.dataSubjectTypeCode) {
      if (resultMap[event.rightCode]) {
        if (!resultMap[event.rightCode].includes(event.dataSubjectTypeCode)) {
          resultMap[event.rightCode].push(event.dataSubjectTypeCode)
        }
      } else {
        resultMap[event.rightCode] = [event.dataSubjectTypeCode]
      }
    }
  })

  return resultMap
}
