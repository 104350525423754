import { formatRequestString } from 'utils/helpers/api/queryString'
import { API } from 'utils/api/api'
import {
  PatchPrivacyProtocolConfigBody,
  PrivacyProtocolConfigurationPatchRequestBody,
} from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'

type Params = {
  id: string
  formData: PatchPrivacyProtocolConfigBody
}

export const updatePrivacyProtocolConfigStatus = ({ id, formData }: Params) => {
  const url = formatRequestString({
    entityUrl: `/api/privacy-configuration/privacy-protocols/${id}/config`,
  })

  return API.patch<PrivacyProtocolConfigurationPatchRequestBody>(url, formData)
}
