import { GetChatTokenResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchChatToken = async () =>
  API.get<GetChatTokenResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/chat/token`,
    }),
  )
