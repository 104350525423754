import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { Pagination } from 'api/common/paginatedQuery'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ViewListLayout } from 'components/ui-layouts/viewListLayout/ViewListLayout'
import { ViewListLayoutHeader } from 'components/ui-layouts/viewListLayout/ViewListLayoutHeader'
import { ViewListLayoutContent } from 'components/ui-layouts/viewListLayout/ViewListLayoutContent'
import { Button } from 'components/ui-kit/button/Button'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { ThemesListDropdown } from 'pages/consentAndRights/themes-v3/list/ThemesListDropdown'

import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateThemes.svg'
import { FormMode } from 'interfaces/formModes/formMode'
import { ThemeStatus } from 'interfaces/themes-v3/themeStatus'
import { Status, StatusState, StatusVariant } from '@ketch-com/deck'
import { ColorPicker } from '../upsert/components/ColorPicker'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isLoading: boolean
  isFetching: boolean
  themes: ThemeV3DTO[]
  pagination: Pagination
  refetchThemes: () => void
}

export const ThemesList: React.FC<Props> = ({ isLoading, isFetching, themes, pagination, refetchThemes }) => {
  const navigate = useNavigate()

  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()

  const isEntitledToCreateThemes = isEntitled(ENTITLEMENTS.QTY_THEME)
  const isPermittedToCreateThemes = isPermitted([PERMISSIONS.THEME_WRITE])
  const canCreateThemes = isEntitledToCreateThemes && isPermittedToCreateThemes

  // TODO:JB - Until migration, only show new themes in this list
  const tableThemes = themes.filter(theme => !!theme.summary)

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Properties', link: RoutesManager.deployment.propertiesHome.root.getURL() },
    { title: 'Themes', link: RoutesManager.deployment.themesV3.root.getURL() },
  ]

  const handleUpsertThemeClick = (id?: string) => {
    navigate(RoutesManager.deployment.themesV3.upsert.root.getURL({ id, formMode: FormMode.CREATE }))
  }

  return (
    <ViewListLayout>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ViewListLayoutHeader
        title={''}
        marginBottom={24}
        paddingBottom={0}
        borderBottom={'none'}
        actions={
          isPermittedToCreateThemes && (
            <Button
              onClick={() => handleUpsertThemeClick()}
              tooltip={
                !isEntitledToCreateThemes
                  ? 'No themes entitlements remaining, please delete one or contact your admin for more'
                  : null
              }
              disabled={!isEntitledToCreateThemes}
            >
              Create Theme
            </Button>
          )
        }
      />

      <ViewListLayoutContent>
        {!isFetching && !tableThemes.length ? (
          <EmptyState
            variant="list"
            icon={<EmptyStateIcon />}
            title="There are no themes for this organization"
            description={canCreateThemes ? 'Create your first one to populate the table.' : null}
            actions={[
              {
                title: 'Create Theme',
                hidden: !canCreateThemes,
                size: 'big',
                onClick: () => handleUpsertThemeClick(),
              },
            ]}
          />
        ) : (
          <TablePaginated
            pending={isLoading}
            items={tableThemes}
            pagination={pagination}
            onRowClick={({ id }) => {
              navigate(RoutesManager.deployment.themesV3.view.preview.root.getURL({ id }))
            }}
            cellSettings={{
              name: {
                label: 'Theme',
                width: 350,
                cellRenderer: ({ name }) => <NameAndCodeCellRenderer name={name} isNameBold />,
              },
              colorScheme: {
                label: 'Color Scheme',
                width: 375,
                cellRenderer: theme => {
                  return (
                    <ColorPicker
                      colors={theme.summary?.colors ?? []}
                      backgroundColors={['#FFFFFF', '#EBEDED']}
                      listBreakpoint={7}
                      disableHover
                      noDropdown
                      noMargin
                    />
                  )
                },
              },
              // TODO:JB - Fonts descoped
              // typeface: {
              //   label: 'Typeface',
              //   width: 200,
              //   cellRenderer: () => <EmptyValueRenderer />,
              // },
              status: {
                label: 'Status',
                width: 299,
                cellRenderer: ({ status }) => {
                  const isDeployed = status === ThemeStatus.Deployed
                  return isDeployed ? (
                    <Status variant={StatusVariant.ghost} status={StatusState.success} icon={'Dot'} label="Deployed" />
                  ) : (
                    <Status variant={StatusVariant.ghost} status={StatusState.draft} icon={'Dot'} label="Undeployed" />
                  )
                },
              },
              updatedAt: {
                label: 'Last Updated',
                width: 210,
                cellRenderer: ({ metadata }) => <FormattedDateRenderer date={metadata?.updatedAt} />,
              },
              dropDown: {
                width: 60,
                cellRenderer: theme => <ThemesListDropdown theme={theme} refetchThemes={refetchThemes} />,
              },
            }}
          />
        )}
      </ViewListLayoutContent>
    </ViewListLayout>
  )
}
