import React, { Fragment, useCallback, useContext, useState } from 'react'
import { Circle, Group, Line, Rect, Shape } from 'react-konva'
import { isNil, isUndefined } from 'lodash'

import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep, ICoordinates } from 'pages/orchestration/workflows/edit/interfaces'
import { CANVAS_TRANSITION_CONNECTION_TYPES } from 'pages/orchestration/workflows/edit/interfaces/enums'
import { CanvasContext } from 'pages/orchestration/workflows/edit/contexts/CanvasContext'
import { getAbsoluteCoordinates } from 'pages/orchestration/workflows/edit/utils/positioning/absoluteCoordinates'
import * as CanvasConstants from 'pages/orchestration/workflows/edit/utils/constants'
import { CanvasStepType, CopyPasteType } from 'pages/orchestration/workflows/edit/utils/constants'
import { JoinGatewayInfo } from '../../utils/steps/getJoinTileRenderDetails'
import { WorkflowCanvasContext } from '../../components/WorkflowCanvas/context/WorkflowCanvasContext'
import { getStepType } from '../../utils/steps/getStepType'

const STROKE_WIDTH = 2
const CELL_WIDTH = CanvasConstants.GRID_CELL_WIDTH
const LINE_TOP_OFFSET = CELL_WIDTH / 2 - STROKE_WIDTH / 2

const linePropsByType = {
  [CANVAS_TRANSITION_CONNECTION_TYPES.SOLID]: {
    stroke: CanvasConstants.CANVAS_LINE_COLOR,
    strokeWidth: STROKE_WIDTH,
  },
  [CANVAS_TRANSITION_CONNECTION_TYPES.DASHED]: {
    dash: [8, 2],
    stroke: '#E1E2E6',
    strokeWidth: STROKE_WIDTH,
  },
  [CANVAS_TRANSITION_CONNECTION_TYPES.COPY_HOVERED]: {
    stroke: CanvasConstants.CANVAS_LINE_COLOR,
    strokeWidth: CanvasConstants.CANVAS_COPY_HOVERED_STROKE_WIDTH,
  },
  [CANVAS_TRANSITION_CONNECTION_TYPES.COPY_NOT_HOVERED]: {
    stroke: CanvasConstants.CANVAS_LINE_COLOR,
    strokeWidth: STROKE_WIDTH,
    opacity: CanvasConstants.CANVAS_COPY_NOT_HOVERED_OPACITY,
  },
}

interface ITransitionConnectionProps {
  gridPositionStart: ICoordinates
  gridPositionEnd: ICoordinates
  type?: CANVAS_TRANSITION_CONNECTION_TYPES
  step: ICanvasStep
  nextStep?: ICanvasStep
  joinStepDetails?: JoinGatewayInfo
  connectedNestId?: string
}

export const TransitionConnection: React.FC<ITransitionConnectionProps> = ({
  type = CANVAS_TRANSITION_CONNECTION_TYPES.SOLID,
  step,
  nextStep,
  gridPositionStart,
  gridPositionEnd,
  joinStepDetails,
  connectedNestId,
}) => {
  const correctGridPositionEnd = joinStepDetails ? joinStepDetails.lineEndPosition : gridPositionEnd
  const cellsYDiff = correctGridPositionEnd.y - gridPositionStart.y

  const { handleCreatePlaceholder } = useContext(CanvasContext)
  const { hoveredCopySteps, hoveredCopyPasteType } = useContext(WorkflowCanvasContext)
  const absolutePositionStart = getAbsoluteCoordinates(gridPositionStart)
  const absolutePositionEnd = getAbsoluteCoordinates(correctGridPositionEnd)
  const isStepPlaceholder = !isUndefined(step?.placeholder)
  const isNextStepPlaceholder = !isUndefined(nextStep?.placeholder)
  const isNextStepDecisionBoolean = nextStep?.gateway?.mode === WorkflowActivityGatewayMode.DECISION_BOOLEAN
  const isNextStepJoinGateway = nextStep?.gateway?.mode === WorkflowActivityGatewayMode.JOIN
  const isStepNonJoinGateway = !isUndefined(step?.gateway) && step.gateway?.mode !== WorkflowActivityGatewayMode.JOIN

  const isHoverEnabled = !isNil(nextStep) && !isNextStepPlaceholder && !isStepPlaceholder && !isNextStepDecisionBoolean
  const [isHover, setIsHover] = useState(false)

  const handlePlusIconClick = useCallback(() => {
    if (isHoverEnabled) {
      setIsHover(false)

      if (handleCreatePlaceholder) {
        handleCreatePlaceholder({ parentStep: step, childStep: nextStep! })
      }
    }
  }, [isHoverEnabled, handleCreatePlaceholder, step, nextStep])

  // Line start is the center of the right side of the parent cell
  const lineAbsoluteStart = {
    x: absolutePositionStart.x + CELL_WIDTH,
    y: absolutePositionStart.y + LINE_TOP_OFFSET,
  }

  // End the line at the center of the left side of the child cell
  const lineAbsoluteEnd = {
    x: absolutePositionEnd.x,
    y: absolutePositionEnd.y + LINE_TOP_OFFSET,
  }

  const isHorizontalLine = lineAbsoluteEnd.y === lineAbsoluteStart.y

  if (lineAbsoluteStart.x === lineAbsoluteEnd.x && lineAbsoluteStart.y === lineAbsoluteEnd.y) {
    console.warn("You can't draw a line where start position equals end position")

    return null
  }

  const PlusButton = () => {
    const rectWidth = lineAbsoluteEnd.x - lineAbsoluteStart.x
    const rectHeight = 20

    // Default position for one-cell horizontal lines
    const rectPosition: ICoordinates = {
      x: lineAbsoluteStart.x,
      y: lineAbsoluteStart.y - rectHeight / 2,
    }
    const circlePosition: ICoordinates = {
      x: lineAbsoluteStart.x + CELL_WIDTH / 2,
      y: lineAbsoluteStart.y,
    }

    // Splits and decisions spawn curved lines, need button moved onto the curved line
    if (!isHorizontalLine && isStepNonJoinGateway) {
      rectPosition.y = lineAbsoluteEnd.y - CELL_WIDTH / 4.5
      circlePosition.y = lineAbsoluteEnd.y - CELL_WIDTH / 10

      circlePosition.x += CELL_WIDTH / 5
    }

    // Paths leading to a join spawn curved lines, need button moved onto the curved line
    // But only when the line is one cell wide. Longer curved lines with the horizontal bit first are fine with the default
    if (!isHorizontalLine && isNextStepJoinGateway && lineAbsoluteEnd.x - lineAbsoluteStart.x <= CELL_WIDTH) {
      circlePosition.x = lineAbsoluteEnd.x - CELL_WIDTH * 0.7

      if (lineAbsoluteEnd.y > lineAbsoluteStart.y) {
        rectPosition.y += CELL_WIDTH / 9
        circlePosition.y += CELL_WIDTH / 9
      } else {
        rectPosition.y -= CELL_WIDTH / 9
        circlePosition.y -= CELL_WIDTH / 9
      }
    }

    return (
      <>
        {isHover && !isNextStepPlaceholder && (
          <Fragment>
            <Circle
              radius={9}
              stroke="#E1E2E6"
              strokeWidth={2}
              fill="#5774FF"
              x={circlePosition.x}
              y={circlePosition.y}
            />
            <Line
              points={[circlePosition.x, circlePosition.y - 4, circlePosition.x, circlePosition.y + 4]}
              strokeWidth={2}
              stroke="#fff"
            />
            <Line
              points={[circlePosition.x - 4, circlePosition.y, circlePosition.x + 4, circlePosition.y]}
              strokeWidth={2}
              stroke="#fff"
            />
          </Fragment>
        )}

        <Rect
          x={rectPosition.x}
          y={rectPosition.y}
          width={rectWidth}
          height={rectHeight}
          onClick={handlePlusIconClick}
          onMouseOver={() => isHoverEnabled && setIsHover(() => true)}
          onMouseOut={() => isHoverEnabled && setIsHover(() => false)}
        />
      </>
    )
  }

  const connectedStepIsCopyHoveredGateway =
    hoveredCopySteps.includes(step) && !!nextStep && hoveredCopySteps.includes(nextStep)

  const connectedStepIsCopyHoveredPath =
    connectedStepIsCopyHoveredGateway ||
    // Include gateway --> first step of path connection
    (hoveredCopyPasteType.current === CopyPasteType.path &&
      getStepType(step) === CanvasStepType.Gateway &&
      !!nextStep &&
      hoveredCopySteps.includes(nextStep)) ||
    // Include last step of path --> join connection
    (hoveredCopyPasteType.current === CopyPasteType.path &&
      hoveredCopySteps.includes(step) &&
      !!nextStep &&
      getStepType(nextStep) === CanvasStepType.Gateway &&
      nextStep.gateway?.mode === WorkflowActivityGatewayMode.JOIN)

  const hasCopyHoveredSteps = !!hoveredCopySteps.length

  // Determine line props
  const copyHoverLineProps =
    linePropsByType[
      connectedStepIsCopyHoveredGateway || connectedStepIsCopyHoveredPath
        ? CANVAS_TRANSITION_CONNECTION_TYPES.COPY_HOVERED
        : CANVAS_TRANSITION_CONNECTION_TYPES.COPY_NOT_HOVERED
    ]

  const regularLineProps =
    linePropsByType[
      connectedNestId
        ? CANVAS_TRANSITION_CONNECTION_TYPES.SOLID
        : isNextStepPlaceholder
        ? CANVAS_TRANSITION_CONNECTION_TYPES.DASHED
        : type
    ]

  const lineProps = hasCopyHoveredSteps ? copyHoverLineProps : regularLineProps

  // Horizontal Line
  if (isHorizontalLine) {
    return (
      <Group>
        <Line
          points={[lineAbsoluteStart.x, lineAbsoluteStart.y, lineAbsoluteEnd.x, lineAbsoluteEnd.y]}
          {...lineProps}
        />
        <PlusButton />
      </Group>
    )
  }

  // Curved Line
  return (
    <Group>
      <Shape
        {...lineProps}
        listening={false}
        sceneFunc={(context, shape) => {
          context.beginPath()

          // Set start position
          context.moveTo(lineAbsoluteStart.x, lineAbsoluteStart.y)

          // Draw longer horizontal line if needed
          if (lineAbsoluteEnd.x - lineAbsoluteStart.x >= CELL_WIDTH * 2) {
            context.lineTo(lineAbsoluteEnd.x - CELL_WIDTH, lineAbsoluteStart.y)

            // Curved line out of starting X position
            if (lineAbsoluteStart.y < lineAbsoluteEnd.y) {
              context.quadraticCurveTo(
                lineAbsoluteEnd.x - CELL_WIDTH / 2,
                lineAbsoluteStart.y,
                lineAbsoluteEnd.x - CELL_WIDTH / 2,
                lineAbsoluteStart.y + CELL_WIDTH / 2,
              )
            } else {
              context.quadraticCurveTo(
                lineAbsoluteEnd.x - CELL_WIDTH / 2,
                lineAbsoluteStart.y,
                lineAbsoluteEnd.x - CELL_WIDTH / 2,
                lineAbsoluteStart.y - CELL_WIDTH / 2,
              )
            }

            // Vertical line to end Y position
            if (lineAbsoluteStart.y < lineAbsoluteEnd.y) {
              context.lineTo(lineAbsoluteEnd.x - CELL_WIDTH / 2, lineAbsoluteEnd.y - CELL_WIDTH / 2)
            } else {
              context.lineTo(lineAbsoluteEnd.x - CELL_WIDTH / 2, lineAbsoluteEnd.y + CELL_WIDTH / 2)
            }

            // Curved line to end X position
            context.quadraticCurveTo(
              lineAbsoluteEnd.x - CELL_WIDTH / 2,
              lineAbsoluteEnd.y,
              lineAbsoluteEnd.x,
              lineAbsoluteEnd.y,
            )
          } else {
            // Curved line out of starting X position
            if (lineAbsoluteStart.y < lineAbsoluteEnd.y) {
              context.quadraticCurveTo(
                lineAbsoluteStart.x + CELL_WIDTH / 2,
                lineAbsoluteStart.y,
                lineAbsoluteStart.x + CELL_WIDTH / 2,
                lineAbsoluteStart.y + CELL_WIDTH / 2,
              )
            } else {
              context.quadraticCurveTo(
                lineAbsoluteStart.x + CELL_WIDTH / 2,
                lineAbsoluteStart.y,
                lineAbsoluteStart.x + CELL_WIDTH / 2,
                lineAbsoluteStart.y - CELL_WIDTH / 2,
              )
            }

            // Vertical line to end Y position
            if (lineAbsoluteStart.y < lineAbsoluteEnd.y) {
              context.lineTo(lineAbsoluteEnd.x - CELL_WIDTH / 2, lineAbsoluteEnd.y - CELL_WIDTH / 2)
            } else {
              context.lineTo(lineAbsoluteEnd.x - CELL_WIDTH / 2, lineAbsoluteEnd.y + CELL_WIDTH / 2)
            }

            // Curved line to end X position
            context.quadraticCurveTo(
              lineAbsoluteStart.x + CELL_WIDTH / 2,
              lineAbsoluteStart.y + CELL_WIDTH * cellsYDiff,
              lineAbsoluteStart.x + CELL_WIDTH,
              lineAbsoluteStart.y + CELL_WIDTH * cellsYDiff,
            )
          }

          context.fillStrokeShape(shape)
        }}
      />
      <PlusButton />
    </Group>
  )
}
