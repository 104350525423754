import { Box } from '@mui/material'
import { Button } from '@ketch-com/deck'
import { NavigationBreadCrumbsProps } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { UpsertLayout } from 'components/ui-layouts/upsertLayout/UpsertLayout'
import { UpsertLayoutFooter } from 'components/ui-layouts/upsertLayout/UpsertLayoutFooter'
import { StackDTO } from '@ketch-com/figurehead'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { BasicDetailsForm } from './components/BasicDetailsForm'
import { PurposesForm } from './components/PurposesForm'
import { TranslationsForm } from './components/TranslationsForm'
import { getPurposeStacksInitialValues, StackForm, usePurposeStacksValidationSchema } from './utils/formValues'
import { JurisdictionsInOtherStacks } from './utils/types'

type Props = {
  breadcrumbs: NavigationBreadCrumbsProps['items']
  stack: StackDTO
  purposes: PurposeDTO[]
  jurisdictions: any[]
  languages: LanguageWithStatusDTO[]
  onSubmit: (formData: StackForm) => void
  jurisdictionsInOtherStacks: JurisdictionsInOtherStacks
}

export const PurposeStacksEditForm: React.FC<Props> = ({
  breadcrumbs,
  stack,
  purposes,
  jurisdictions,
  languages,
  onSubmit,
  jurisdictionsInOtherStacks,
}) => {
  const validationSchema = usePurposeStacksValidationSchema()
  const initialValues = useMemo(() => getPurposeStacksInitialValues(stack), [stack])
  const navigate = useNavigate()

  return (
    <UpsertLayout
      breadcrumbs={breadcrumbs}
      title="Edit Stack"
      isReady={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm, errors, touched }) => (
        <Box>
          <BasicDetailsForm
            jurisdictions={jurisdictions}
            showJurisdictionError={Boolean(errors.jurisdictions && touched.jurisdictions)}
            jurisdictionsInOtherStacks={jurisdictionsInOtherStacks}
          />
          <PurposesForm purposes={purposes} />
          {!!languages.length && <TranslationsForm languages={languages} />}
          <UpsertLayoutFooter>
            <Box display="flex" gap={1.5}>
              <Button
                pending={isSubmitting}
                color="secondary"
                size="large"
                onClick={() => {
                  navigate(RoutesManager.deployment.purposeStacks.root.getURL())
                }}
              >
                Cancel
              </Button>
              <Button size="large" onClick={submitForm} pending={isSubmitting}>
                Save
              </Button>
            </Box>
          </UpsertLayoutFooter>
        </Box>
      )}
    </UpsertLayout>
  )
}
