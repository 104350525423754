import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { isNil } from 'lodash'
import { Grid, ButtonBase, CircularProgress } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import AutosizeInput, { AutosizeInputProps } from 'react-input-autosize'

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      root: {
        fontFamily: typography.fontFamily,
        textAlign: 'left',
        cursor: 'pointer',
        borderRadius: 5,
        transition: 'backgroundColor 0.3s linear',

        '&:not($editing):not($filled)': {
          backgroundColor: palette.fadedGrey.main,
          '&.noBackground': {
            backgroundColor: 'transparent',
          },
        },

        '&:hover, &:focus': {
          outline: 0,

          '&:not($editing):not($invalid)': {
            backgroundColor: palette.fadedGrey.main,
          },

          '&:not($invalid) $icon': {
            color: palette.royalBlue.main,
          },
        },
      },

      disabled: {
        pointerEvents: 'none',
      },

      border: {
        border: `1px solid ${palette.iron.main}`,
      },

      inline: {
        display: 'inline-flex',
        width: 'auto',
      },

      editing: {
        cursor: 'auto',

        '&$x-large': {
          paddingBottom: 4,
        },

        '&$large': {
          paddingBottom: 7,
        },

        '&$medium': {
          paddingBottom: 9,
        },

        '&$normal': {
          paddingBottom: 5,
        },

        '&$compact': {
          paddingBottom: 5,
        },
      },

      invalid: {
        '&:not($editing):not($filled)': {
          backgroundColor: palette.bleachWhite.main,
        },
      },

      filled: {},

      'x-large': {
        padding: '6px 10px 5px',
        fontSize: typography.pxToRem(28),
        fontWeight: 800,
        lineHeight: 1.21,
      },

      large: {
        padding: '8px 10px',
        fontSize: typography.pxToRem(20),
        fontWeight: 700,
        lineHeight: 1.4,
      },

      medium: {
        padding: '10px 10px 9px 10px',
        fontSize: typography.pxToRem(16),
        fontWeight: 700,
        lineHeight: 1.12,
      },

      normal: {
        padding: '6px 10px',
        fontSize: typography.pxToRem(14),
        fontWeight: 600,
        lineHeight: 1.43,
      },

      compact: {
        padding: '5px 10px',
        fontSize: typography.pxToRem(12),
        fontWeight: 600,
        lineHeight: 1.5,
      },

      icon: {
        marginLeft: 10,
        color: palette.darkDusk.main,
        width: 16,
        height: 16,
        lineHeight: 1.43,

        '$x-large &': {
          width: 24,
          height: 24,
        },

        '$root:hover &, $root:focus &': {
          color: palette.royalBlue.main,
        },

        '$invalid:hover &': {
          color: palette.darkDusk.main,
        },

        '$disabled &, $filled &': {
          color: palette.darkDuskFaded.main,
        },
      },

      progress: {
        margin: '0 7px 0 11px',

        '$x-large &': {
          margin: '0 11px 0 13px',
        },
      },

      text: {
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: palette.darkDuskFaded.main,

        '$filled &': {
          color: palette.darkDusk.main,
        },

        '$disabled &': {
          color: palette.darkDuskFaded.main,
        },
      },

      inputBase: {
        borderBottom: `1px solid ${palette.sphere.main}`,

        '$invalid &': {
          borderBottom: `1px solid ${palette.chileanFire.main}`,
        },
      },

      input: {
        fontFamily: typography.fontFamily,
        border: 0,
        padding: 0,
        height: 'auto',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        letterSpacing: 'inherit',
        color: palette.darkDusk.main,

        '&::placeholder': {
          color: palette.darkDuskFaded.main,
        },

        '&:focus': {
          outline: 0,
        },

        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          margin: 0,
          WebkitAppearance: 'none',
        },

        '&[type=number]': {
          MozAppearance: 'textfield',
        },
      },
      maxWidth: ({ maxWidth, fullWidth }: { maxWidth?: number; fullWidth?: boolean }) => ({
        ...(fullWidth && {
          minWidth: '100%',
        }),
        maxWidth: fullWidth || isNil(maxWidth) ? '100%' : maxWidth,
      }),
    }),
  { name: 'InlineEdit' },
)

export interface Props extends Omit<AutosizeInputProps, 'size' | 'ref'> {
  /** Html name attribute */
  name?: string
  /** Is control loading indicator displayed */
  pending?: boolean
  /** Control loading text. If not specified fallbacks to component value. If value is empty fallbacks to placeholder. */
  pendingText?: string
  /** Is control disabled */
  disabled?: boolean
  /** Control value placeholder */
  placeholder: string
  /** Control additional className */
  className?: string
  /** Is control expands for 100% of its container width */
  fullWidth?: boolean
  /** Valid state */
  valid?: boolean
  /** Control maximum width in pixels. Ignored if fullWidth = true */
  maxWidth?: number
  /** Control value */
  value?: string | number
  /** Control size */
  size?: 'x-large' | 'large' | 'medium' | 'normal' | 'compact'
  /** Optional border when data is entered */
  border?: boolean
  /** Control focus method */
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  /** Control blur method */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  /** Control value change method */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export type InlineEditProps = Props

export const InlineEdit: React.FC<Props> = ({
  value = '',
  onFocus,
  onBlur,
  onChange,
  size = 'normal',
  placeholder,
  className,
  fullWidth = false,
  valid = true,
  maxWidth,
  disabled = false,
  pending = false,
  pendingText = '',
  name,
  placeholderIsMinWidth = true,
  border = false,
  ...rest
}) => {
  const classes = useStyles({ maxWidth, fullWidth })
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (disabled || pending) {
      setIsEditing(false)
    }
  }, [disabled, pending])

  return (
    <Grid
      container
      component={ButtonBase}
      disabled={disabled || pending}
      disableRipple
      disableTouchRipple
      alignItems="center"
      className={clsx(
        classes.root,
        classes[size],
        classes.maxWidth,
        {
          [classes.filled]: !!value,
          [classes.editing]: isEditing,
          [classes.inline]: !fullWidth,
          [classes.invalid]: !valid,
          [classes.disabled]: disabled || pending,
          [classes.border]: border && !isEditing,
        },
        className,
      )}
      {...(!disabled &&
        !pending && {
          onClick: () => {
            setIsEditing(true)
          },
        })}
    >
      {isEditing ? (
        <AutosizeInput
          {...rest}
          autoFocus
          name={name}
          className={clsx(classes.inputBase, classes.maxWidth)}
          inputClassName={clsx(classes.input, classes.maxWidth)}
          placeholder={placeholder}
          placeholderIsMinWidth={placeholderIsMinWidth}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onBlur?.(e as unknown as React.FocusEvent<HTMLInputElement>)
              setIsEditing(false)
            }
          }}
          onBlur={e => {
            onBlur?.(e)
            setIsEditing(false)
          }}
        />
      ) : (
        <>
          <span className={classes.text}>{pending && pendingText ? pendingText : value || placeholder}</span>
          {pending && (
            <div className={classes.progress}>
              <CircularProgress
                style={
                  size === 'x-large'
                    ? {
                        width: 18,
                        height: 18,
                      }
                    : {
                        width: 14,
                        height: 14,
                      }
                }
              />
            </div>
          )}
          {!disabled && !pending && <EditIcon className={classes.icon} />}
        </>
      )}
    </Grid>
  )
}
