export const sortAlphabetically = (items: Record<string, any>[], key: string) =>
  items.sort((a, b) => {
    const nameA = a[key].toUpperCase()
    const nameB = b[key].toUpperCase()
    if (nameA <= nameB) {
      return -1
    } else {
      return 1
    }
  })
