import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { ResponseType } from 'axios'

type Params = {
  fileUrl: string
  responseType?: ResponseType | undefined
}

export const downloadFile = async ({ fileUrl, responseType = 'blob' }: Params) => {
  return API.get(
    formatRequestString({
      entityUrl: fileUrl,
    }),
    { responseType },
  )
}
