import React from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { useManageInferredLabelsInputUtils } from './hooks'
import { Text } from 'components/ui-kit/typography/Text'
import { Badge } from 'components/ui-kit/badge/Badge'
import { BadgeKeyValue } from 'components/ui-kit/badge/BadgeKeyValue'
import { Box } from '@mui/material'

type Props = {
  assetCode: string
}

const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      firstBadge: {
        marginRight: spacing(2),
      },
      withMarginBottom: {
        marginBottom: spacing(1),
      },
      withMarginRight: {
        marginRight: spacing(1),
      },
      capitalCase: {
        textTransform: 'capitalize',
      },
      withPointer: {
        cursor: 'pointer',
      },
      popoverContainer: {
        flexWrap: 'wrap',
        display: 'flex',
        maxWidth: '725px',
        padding: spacing(2),
        paddingBottom: spacing(1),
      },
    }),
  { name: 'ManageInferredLabelsInput' },
)

export const ManageInferredLabelsInput: React.FC<Props> = ({ assetCode }) => {
  const { labels, handleRemoveTag } = useManageInferredLabelsInputUtils({ assetCode })
  const classes = useStyles()

  if (labels?.length === 0) return null

  return (
    <FormRow>
      <>
        <Box display="flex" flexDirection="row" alignItems="center" mb={0.5}>
          <Text size={14} weight={600}>
            Automated Classifications
          </Text>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
          <Text size={14} color="grey">
            Remove automated classifications by clicking the X
          </Text>
        </Box>

        {labels?.length > 0 &&
          labels.map((l, index) => {
            if (l?.code && l?.value)
              return (
                <BadgeKeyValue
                  key={l.value}
                  badgeKey={l?.name as string}
                  badgeValue={l?.value}
                  onRemoveBadge={() => {
                    const uniqueIdentifier = `${l?.code || ''}${l?.value || ''}${l?.name || ''}`
                    handleRemoveTag(uniqueIdentifier)
                  }}
                  className={clsx(classes.capitalCase, classes.withMarginBottom, {
                    [classes.firstBadge]: index !== labels.length - 1,
                  })}
                />
              )
            if (l?.code)
              return (
                <Badge
                  key={l.code}
                  className={clsx(classes.capitalCase, classes.withMarginBottom, {
                    [classes.firstBadge]: index !== labels.length - 1,
                  })}
                  onRemoveBadge={() => {
                    const uniqueIdentifier = `${l?.code || ''}${l?.value || ''}${l?.name || ''}`
                    handleRemoveTag(uniqueIdentifier)
                  }}
                >
                  {l.code}
                </Badge>
              )

            if (l?.value)
              return (
                <Badge
                  key={l.value}
                  onRemoveBadge={() => {
                    const uniqueIdentifier = `${l?.code || ''}${l?.value || ''}${l?.name || ''}`
                    handleRemoveTag(uniqueIdentifier)
                  }}
                  className={clsx(classes.capitalCase, classes.withMarginBottom, {
                    [classes.firstBadge]: index !== labels.length - 1,
                  })}
                >
                  {l.value}
                </Badge>
              )
            return null
          })}
      </>
    </FormRow>
  )
}
