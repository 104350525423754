import React from 'react'
import Grid from '@mui/material/Grid'

import { makeStyles, createStyles } from '@mui/styles'

import { MaybeNull } from 'interfaces/common'
import { UserShortDTO } from 'interfaces/users/users'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionStepDTO, WorkflowExecutionDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { useAuth } from 'utils/hooks/useAuth'
import { joinNonEmpty } from 'utils/helpers/joinNonEmpty'
import { Modal } from 'components/ui-kit/modal/Modal'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { Text } from 'components/ui-kit/typography/Text'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { Group } from 'components/ui-layouts/group/Group'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { RightInvocationIdentitySpaces } from 'pages/orchestration/rightsQueue/viewV2/components/RightInvocationIdentitySpaces'
import { WorkflowExecutionAssignee } from 'pages/orchestration/rightsQueue/components/WorkflowExecutionAssignee'
import { Box } from '@mui/material'
import { WORKFLOW_TYPE_ENUM } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/DsrTransponderForm/constants'
import { WorkflowExecutionStepStatusRenderer } from '../WorkflowExecutionStepStatusRenderer'
import { WorkflowExecutionStepAttachments } from '../WorkflowExecutionStepAttachments'

const useStyles = makeStyles(
  theme =>
    createStyles({
      sectionTitle: {
        display: 'block',
        marginBottom: 12,
        color: theme.palette.fadedDarkGrey.main,
      },
    }),
  { name: 'WorkflowExecutionStepViewModal' },
)

type Props = {
  isReady: boolean
  users: UserShortDTO[]
  rightInvocation: RightInvocationDTO
  workflowExecution: MaybeNull<WorkflowExecutionDTO>
  workflowExecutionStep: WorkflowExecutionStepDTO
  workflowExecutionStepDetails: MaybeNull<WorkflowExecutionStepDetailsDTO>
  filesInfo: FileInfoDTO[]
  dsrFilesInfo: FileInfoDTO[]
  onClose: () => void
}

export const WorkflowExecutionStepViewModal: React.FC<Props> = ({
  isReady,
  users,
  filesInfo,
  dsrFilesInfo,
  rightInvocation,
  workflowExecution,
  workflowExecutionStep,
  workflowExecutionStepDetails,
  onClose,
}) => {
  const classes = useStyles()
  const { userData } = useAuth()

  const { user, identities } = rightInvocation
  const { status, assignee, stepDescription } = workflowExecutionStep

  const isAssignedToMe = assignee === userData.userId
  const resolutionDetails =
    workflowExecutionStepDetails?.results?.[workflowExecutionStep.stepID]?.resolutionDetails || ''
  const stepDetailsText = workflowExecutionStepDetails?.activity?.params?.details
  const isAccessFlow = workflowExecution?.results?.['canonicalRightCode'] === WORKFLOW_TYPE_ENUM.GET

  return (
    <Modal
      title={
        <Group gap={16} alignItems="center">
          Task&nbsp;
          <WorkflowExecutionStepStatusRenderer status={status} />
        </Group>
      }
      isLoading={!isReady}
      contentWidth={840}
      submitBtnLabel="Close"
      onSubmitBtnClick={onClose}
      onCloseButtonClick={onClose}
    >
      <Box mt={2.25}>
        {isAssignedToMe ? (
          <>
            <FormRow>
              <Text className={classes.sectionTitle} size={16} weight={800}>
                Task
              </Text>

              <Label>Details</Label>
              <TextCellRenderer value={stepDetailsText} />
            </FormRow>

            <FormRow>
              <Text className={classes.sectionTitle} size={16} weight={800}>
                Data Subject
              </Text>
              <Grid container>
                <Grid item xs={4}>
                  <FormRow>
                    <Label>Name</Label>
                    <TextCellRenderer value={joinNonEmpty([user?.first, user?.last])} />
                  </FormRow>

                  <FormRow>
                    <Label>Email</Label>
                    <TextCellRenderer value={user?.email} />
                  </FormRow>
                </Grid>
                <Grid item xs={4}>
                  <Label>Identifiers</Label>
                  {identities?.length ? (
                    <RightInvocationIdentitySpaces max={6} identities={identities} />
                  ) : (
                    <EmptyValueRenderer />
                  )}
                </Grid>
              </Grid>
            </FormRow>

            <FormRow>
              <Text className={classes.sectionTitle} size={16} weight={800}>
                Resolution
              </Text>
            </FormRow>

            <FormRow>
              <Label>Notes</Label>
              <TextCellRenderer value={resolutionDetails} />
            </FormRow>

            <FormRow>
              <Label>Attachments</Label>
              {filesInfo?.length ? (
                <WorkflowExecutionStepAttachments attachments={filesInfo} />
              ) : (
                <EmptyValueRenderer />
              )}
            </FormRow>

            {isAccessFlow ? (
              <FormRow>
                <Label>Attachments for Data Subject</Label>
                {dsrFilesInfo?.length ? (
                  <WorkflowExecutionStepAttachments attachments={dsrFilesInfo} isDownloadAllowed={true} />
                ) : (
                  <EmptyValueRenderer />
                )}
              </FormRow>
            ) : null}
          </>
        ) : (
          <>
            <FormRow>
              <Label>Description</Label>
              <TextCellRenderer value={stepDescription} />
            </FormRow>

            <FormRow>
              <Label>Task Assignee</Label>
              {(() => {
                const stepAssignee = users.find(({ ID }) => ID === assignee)

                return stepAssignee ? <WorkflowExecutionAssignee assignee={stepAssignee} /> : <EmptyValueRenderer />
              })()}
            </FormRow>

            <FormRow>
              <Label>Notes</Label>
              <TextCellRenderer value={resolutionDetails} />
            </FormRow>

            <FormRow>
              <Label>Attachments</Label>
              {filesInfo?.length ? (
                <WorkflowExecutionStepAttachments attachments={filesInfo} />
              ) : (
                <EmptyValueRenderer />
              )}
            </FormRow>

            {isAccessFlow ? (
              <FormRow>
                <Label>Attachments for Data Subject</Label>
                {dsrFilesInfo?.length ? (
                  <WorkflowExecutionStepAttachments attachments={dsrFilesInfo} isDownloadAllowed={true} />
                ) : (
                  <EmptyValueRenderer />
                )}
              </FormRow>
            ) : null}
          </>
        )}
      </Box>
    </Modal>
  )
}
