import React, { useMemo } from 'react'

import { UserDTO } from 'interfaces/users/users'
import { Button, DataGrid, Icon, TableCell } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { getWorkflowStepIconNameFromActivityCode } from 'pages/orchestration/utils'
import { useWorkflowAssignments } from 'api/users/queries/useWorkflowAssignments'
import { TempAssignmentType } from 'api/users/fetchers/fetchWorkflowAssignments'

type Props = {
  user: UserDTO
}

export const UsersViewAssignedActivites: React.FC<Props> = ({ user }) => {
  const { data, isLoading } = useWorkflowAssignments({ params: { id: user.ID } })
  const navigate = useNavigate()

  // DataGrid requires lowercase id
  const parsedData = useMemo(() => data?.map(x => ({ ...x, id: x.ID })), [data])

  return (
    <Box>
      <Typography variant="h3" my={3}>
        Assigned Activities
      </Typography>
      <DataGrid
        loading={isLoading}
        rows={parsedData || []}
        disableChildrenSorting
        disableMultipleColumnsSorting
        disableRowHoverStates
        disableColumnResize
        disableColumnFilter
        disableColumnReorder
        disableColumnPinning
        disableAggregation
        disableRowGrouping
        hideFooterRowCount
        disableColumnMenu
        noRowsOverlayProps={{
          subTitle: 'No Workflow Activities are assigned to this user.',
          buttonTitle: '',
        }}
        columns={[
          {
            align: 'left',
            field: 'name',
            headerAlign: 'left',
            headerName: 'Name',
            flex: 1,
            renderCell: ({ row }: GridRenderCellParams<TempAssignmentType>) => {
              const iconName = getWorkflowStepIconNameFromActivityCode(row.activityCode)

              return (
                <Box display="flex" gap={1} alignItems="center">
                  <Icon name={iconName} />
                  <TableCell title={row.name} subTitle={row.description} />
                </Box>
              )
            },
          },
          {
            align: 'left',
            field: 'workflow',
            headerAlign: 'left',
            headerName: 'Workflow',
            flex: 1,
            renderCell: ({ row }: GridRenderCellParams<TempAssignmentType>) => {
              return (
                <TableCell>
                  <NameAndCodeCellRenderer name={row.workflowDefinitionName} code={row.workflowDefinitionCode} />
                </TableCell>
              )
            },
          },
          {
            align: 'left',
            field: 'view',
            headerAlign: 'left',
            headerName: '',
            flex: 1,
            renderCell: ({ row }: GridRenderCellParams<TempAssignmentType>) => {
              return (
                <TableCell>
                  <Button
                    color="tertiary"
                    onClick={() =>
                      navigate(RoutesManager.orchestration.workflows.upsert.root.getURL({ code: row.code }))
                    }
                  >
                    <Box component="span" mr={0.5}>
                      View
                    </Box>
                    <Icon width={20} height={20} name="OShare" />
                  </Button>
                </TableCell>
              )
            },
          },
        ]}
      />
    </Box>
  )
}
