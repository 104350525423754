import { useState } from 'react'
import { useDebounce } from 'react-use'

export const useProcessingActivitiesUpsertSearch = <T>() => {
  const [value, setValue] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<T[]>([])
  const [searchQueryValue, setSearchQueryValue] = useState('')

  useDebounce(
    () => {
      if (inputValue) {
        setSearchQueryValue(inputValue)
      } else {
        setSearchQueryValue('')
      }
    },
    500,
    [inputValue],
  )

  return {
    value,
    setValue,
    inputValue,
    setInputValue,
    options,
    setOptions,
    searchQueryValue,
    setSearchQueryValue,
  }
}
