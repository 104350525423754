import { Box, Typography, styled } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { AppNavigationContext } from '../../context/AppNavigationContext'
import { Icon, ListItem, TextInput, theme } from '@ketch-com/deck'
import { createOverlayOptions, filterAndSortCreateOptions } from './utils'
import { useIsPermitted } from 'utils/hooks'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'

const CreateOverlayBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '12px',
  background: theme.palette.Black.o48,
  zIndex: 2147483647,
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  paddingTop: '96px',
  transition: 'opacity 0.25s ease-in-out',
}))

const TextInputBox = styled(Box)(({ theme }) => ({
  border: `4px solid ${theme.palette.White.o24}`,
  height: 'fit-content',
  borderRadius: 100,
}))

const OverlayTextInput = styled(TextInput)(({ theme }) => ({
  width: '540px',
  height: '56px ',
  '& .MuiInputBase-root': {
    height: '56px',
    borderRadius: 100,
    paddingLeft: '16px !important',
    paddingRight: '16px',
  },
  '& .MuiInputBase-input': {
    fontSize: '16px',
    fontWeight: 600,
  },
  borderRadius: 100,
  boxShadow: '0px 14px 32px -2px rgba(7, 26, 36, 0.24)',
}))

const ResultsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '540px',
  flexDirection: 'column',
  padding: '16px 8px 8px 8px',
  background: theme.palette.Common.Container,
  borderRadius: 16,
  boxShadow: '0px 14px 32px -2px rgba(7, 26, 36, 0.24)',
}))

const ListBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 0px',
  gap: '4px',
})

export const CreateOverlay: React.FC = () => {
  const { setShowCreateOverlay } = useContext(AppNavigationContext)
  const { isPermitted } = useIsPermitted()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')

  const [show, setShow] = useState(false)

  // Show the most recently searched 5 items if nothing has been searched for
  const showRecent = !search

  // Only show items the user can access
  const allowedItems = createOverlayOptions.filter(option => isPermitted(option.permissions))

  // Show 5 commonly used items or filter based on search
  const displayedItems = useMemo(() => {
    return showRecent ? allowedItems.slice(0, 5) : filterAndSortCreateOptions(allowedItems, search)
  }, [showRecent, allowedItems, search])

  // Debouce search input
  const setDebouncedSearch = useMemo(
    () =>
      debounce((v: string) => {
        setSearch(v)
      }, 100),
    [setSearch],
  )

  // Don't close the overlay when user clicks on the input
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
  }

  const handleItemClick = (path: string) => {
    navigate(path)
    setShowCreateOverlay(false)
  }

  // For transition
  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 1)
  }, [])

  return (
    <CreateOverlayBox sx={{ opacity: show ? 1 : 0 }} onClick={() => setShowCreateOverlay(false)}>
      {/* Search */}
      <TextInputBox>
        <OverlayTextInput
          onClick={handleOverlayClick}
          placeholder="Create or add..."
          inputWidth="540px"
          startIcon={<Icon name="OMag" />}
          onChange={e => setDebouncedSearch(e.target.value)}
          autoFocus
        />
      </TextInputBox>

      {/* Results */}
      <ResultsBox onClick={handleOverlayClick}>
        {/* Heading */}
        <Typography variant="smallLabel" color={theme.palette.Text.Secondary} pl="8px" py="4px">
          {showRecent ? 'Common' : `Matching Results ${displayedItems.length}`}
        </Typography>

        {/* List */}
        <ListBox>
          {displayedItems.map(({ value, label, icon, path }) => (
            <ListItem
              key={value}
              title={label}
              startIcon={<Icon name={icon} iconColor={theme.palette.Action.Primary} />}
              endIcon={<Icon name="OArrowRight" iconColor={theme.palette.Black.o32} />}
              onClick={() => handleItemClick(path)}
              sx={{
                '& .MuiTypography-root': {
                  fontSize: '16px',
                  fontWeight: '600 !important',
                  paddingLeft: '8px',
                },
              }}
            />
          ))}
        </ListBox>
      </ResultsBox>
    </CreateOverlayBox>
  )
}
