import React from 'react'
import pluralize from 'pluralize'
import { Box, Typography, Tooltip } from '@mui/material'
import { Tracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { theme, IconDictionary, Icon } from '@ketch-com/deck'

type Props = {
  tracker: Tracker
}

export const Disclosure: React.FC<Props> = ({ tracker = {} as Tracker }, ref?: React.ForwardedRef<HTMLSpanElement>) => {
  const { numDisclosed, numProperties } = tracker
  const isTrackerDisclosedOnSomeProperties = !!numDisclosed && numDisclosed < numProperties
  const isTrackerHiddenOnAllProperties = !numDisclosed && !!numProperties

  if (isTrackerHiddenOnAllProperties)
    return (
      <Box display="flex" alignItems="center" gap={0.5}>
        <Icon name={IconDictionary.OEyeHide} iconColor={theme.palette.darkDuskFaded.main} />
        <Typography variant="label" color="darkDuskFaded.main">
          Hidden
        </Typography>
      </Box>
    )
  if (isTrackerDisclosedOnSomeProperties)
    return (
      <Box display="flex" alignItems="center" gap={0.5}>
        <Icon name={IconDictionary.OEyeHide} iconColor={theme.palette.darkDuskFaded.main} />
        <Typography variant="label" color="darkDuskFaded.main">
          Hidden On Some
        </Typography>

        <Tooltip
          title={
            <Box display="flex" flexDirection="column" gap={1} width={180} p={0.5}>
              <Typography variant="label" color="white.main">
                This tracker hidden from your privacy experiences on {numProperties - numDisclosed}{' '}
                {pluralize('property', numProperties - numDisclosed)}.
              </Typography>

              <Typography variant="label" color="white.main">
                You can edit this setting on the tracker details page.
              </Typography>
            </Box>
          }
          placement="bottom"
        >
          <Box mt={0.5}>
            <Icon name={IconDictionary.FUnknown} iconColor={theme.palette.darkDuskFaded.main} width={16} height={16} />
          </Box>
        </Tooltip>
      </Box>
    )

  return (
    <Box display="flex" alignItems="center" gap={0.5}>
      <Icon name={IconDictionary.OEyeShow} iconColor={theme.palette.greenHaze.main} />
      <Typography variant="label" color="greenHaze.main">
        Disclosed
      </Typography>
    </Box>
  )
}
