import React, { ReactNode, useEffect, useState } from 'react'
import { useField } from 'formik'
import { FileRejection } from 'react-dropzone'
import { InputLabel, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { DropZone } from '@ketch-com/deck'
import { SxProps, Theme } from '@mui/material'
import { showToast } from '../../modals/AlertComponent'
import { FileDataType, mapFileToDropZoneUploadedFileData } from './utils'

export interface FileWithPreview extends File {
  preview?: string
}

export interface FormValues {
  files: FileWithPreview[]
}

type SupportedFileTypes = {
  [key: string]: string[]
}

type Props = {
  formPropertyName: string
  accept?: SupportedFileTypes
  maxFiles?: number
  maxSize?: number
  containerSx?: SxProps<Theme>
  disableDownload?: boolean
  acceptedFilesNames?: string[]
  required?: boolean
  label?: ReactNode
  title?: string
  subtitle?: string
  hideDropzoneOnUpload?: boolean
  showUploadDates?: boolean
  showUploadSizes?: boolean
}

// replaces functionality from -> { Dropzone } from 'components/ui-kit/dropzoneV2'
export const FormDropZone: React.FC<Props> = ({
  formPropertyName,
  accept,
  maxFiles,
  maxSize,
  containerSx,
  disableDownload = true,
  acceptedFilesNames,
  required,
  label,
  title = 'Upload File',
  subtitle = 'You can drop a file here for quick upload or use the button below to locate it in your file manager.',
  hideDropzoneOnUpload,
  showUploadDates,
  showUploadSizes,
}) => {
  const [fileDataAtUpload, setFileDataAtUpload] = useState<FileDataType | undefined>(undefined)
  const [field, meta, helpers] = useField<FileWithPreview[]>({ name: formPropertyName })
  const { value } = field
  const { error, touched } = meta
  const showError = error && touched
  const { setValue } = helpers

  // clean up preview object urls when unmounting
  useEffect(() => {
    return () => {
      field.value.forEach((file: { preview?: string }) => {
        if (file.preview) {
          URL.revokeObjectURL(file.preview)
        }
      })
    }
  }, [field.value])

  const onDropAccepted = async (acceptedFiles: File[]) => {
    setValue([
      ...field.value,
      ...acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) })),
    ])
    const filesUploadDate = acceptedFiles.map(file => ({ [file.name]: { date: new Date(), size: file.size } }))
    setFileDataAtUpload(uploadData => Object.assign({ ...uploadData }, ...filesUploadDate))
  }

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const collectedErrorMessages = new Set<string>()
    fileRejections.forEach(fileRejection => {
      const { errors } = fileRejection
      errors.forEach(error => {
        collectedErrorMessages.add(error.message)
      })
    })
    showToast({
      content: Array.from(collectedErrorMessages).join(', '),
      type: 'error',
    })
  }

  const onDelete = async ({ value, attachment }: { attachment: FileWithPreview; value: FileWithPreview[] }) => {
    helpers.setValue(field.value.filter(f => f.name !== attachment.name))

    const currentUploadedFileData = { ...fileDataAtUpload }
    if (currentUploadedFileData[attachment.name]) {
      delete currentUploadedFileData[attachment.name]
    }

    setFileDataAtUpload(currentUploadedFileData)
  }

  const uploadedFileData = mapFileToDropZoneUploadedFileData(value)

  return (
    <Box display="flex" flexDirection="column" sx={containerSx}>
      <InputLabel>
        {required ? (
          <Typography variant="label" color="darkDusk.main">
            {label}
          </Typography>
        ) : (
          <>
            <Typography variant="label" color="darkDusk.main">
              {label}
            </Typography>{' '}
            <Typography variant="label" color="darkGrey.main">
              {'(Optional)'}
            </Typography>
          </>
        )}
      </InputLabel>
      <DropZone
        hideDropzoneOnUpload={hideDropzoneOnUpload}
        disableDownload={disableDownload}
        validationError={showError && meta.error ? meta.error : ''}
        accept={accept}
        value={value}
        title={title}
        subtitle={subtitle}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
        maxSize={maxSize}
        maxFiles={maxFiles}
        acceptedFilesNames={acceptedFilesNames || []}
        onDelete={onDelete}
        uploadFilesData={uploadedFileData}
        showUploadDates={showUploadDates}
        showUploadSizes={showUploadSizes}
      />
    </Box>
  )
}
