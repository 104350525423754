export const sortByName = (aName = '', bName = ''): number => {
  const comparisonA = aName?.toUpperCase() || ''
  const comparisonB = bName?.toUpperCase() || ''
  let comparison = 0

  if (comparisonA > comparisonB) {
    comparison = 1
  } else if (comparisonA < comparisonB) {
    comparison = -1
  }

  return comparison
}
