import { Card, CustomIconName, Icon } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { formatCount } from 'pages/insights/utils'
import React from 'react'

interface Props {
  title: number
  subtitle: string
  icon: CustomIconName
  textColor: string
  backgroundColor: string
}

export const RightsOverviewCard: React.FC<Props> = ({ title, subtitle, icon, textColor, backgroundColor }) => {
  return (
    <Card
      cardVariant="stretchWidth"
      maxCardWidth="192px"
      primaryIcon={<Icon name="FClock" />}
      backgroundColor={backgroundColor}
      customContentCard={
        <Box display="flex" justifyContent="center" flexDirection="column" width="100%" gap={'8px'}>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Typography variant="h2" color={textColor}>
              {formatCount(title)}
            </Typography>
            <Icon name={icon} iconColor={textColor} width={32} height={28} />
          </Box>
          <Typography variant="label" color={textColor}>
            {subtitle}
          </Typography>
        </Box>
      }
    />
  )
}
