import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { createStyles, makeStyles } from '@mui/styles'

import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { ViewModeLayout } from 'components/ui-layouts/viewLayout/ViewModeLayout'
import { useAssetDetailContentRows } from '../hooks'
import { AssetDetailViewRelatedAssets } from '.'
import { useGetAssetTechnologySpecificHumanReadableName } from 'pages/assetManager/hooks'
import { ScenarioList } from 'pages/assetManager/scenario/list/ScenarioList'
import { relativePath } from 'utils/routing/relativePath'
import { GetDataPreviewResponseBody } from 'pages/assetManager/interfaces'

const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      viewModeLayoutContainer: {
        padding: spacing(0, 2),
      },
    }),
  { name: 'AssetDetailViewRoutes' },
)

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
  dataPreview?: GetDataPreviewResponseBody
}

export const AssetDetailViewRoutes: React.FC<Props> = ({ assetSummary, dataPreview }) => {
  const location = useLocation()
  const classes = useStyles()
  const contentRows = useAssetDetailContentRows(assetSummary || undefined, dataPreview)
  const getAssetTechnologySpecificHumanReadableName = useGetAssetTechnologySpecificHumanReadableName()

  return (
    <Routes location={location}>
      {/* default route */}

      <Route
        path="/"
        element={
          <ViewModeLayout
            containerClassName={classes.viewModeLayoutContainer}
            title="Overview"
            subtitle={`Basic information about this ${getAssetTechnologySpecificHumanReadableName(assetSummary)}.`}
            isLoading={false}
            contentRows={contentRows}
          />
        }
      />

      {/* .../view/overview */}

      <Route
        path={relativePath(
          RoutesManager.assetManager.assets.detail.overview.pattern,
          RoutesManager.assetManager.assets.detail.root.pattern,
        )}
        element={
          <ViewModeLayout
            containerClassName={classes.viewModeLayoutContainer}
            title="Overview"
            subtitle={`Basic information about this ${getAssetTechnologySpecificHumanReadableName(assetSummary)}.`}
            isLoading={false}
            contentRows={contentRows}
          />
        }
      />

      {/* .../view/dsr_config */}

      <Route
        path={relativePath(
          RoutesManager.assetManager.assets.detail.dsrConfiguration.pattern,
          RoutesManager.assetManager.assets.detail.root.pattern,
        )}
        element={<ScenarioList assetSummary={assetSummary} />}
      />

      {/* .../view/:resourceTypeCode */}

      <Route
        path={relativePath(
          RoutesManager.assetManager.assets.detail.tab.pattern,
          RoutesManager.assetManager.assets.detail.root.pattern,
        )}
        element={<AssetDetailViewRelatedAssets assetSummary={assetSummary} />}
      />
    </Routes>
  )
}
