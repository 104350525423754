import { Icon, theme } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { AppNavigationContext } from 'components/appLayout/appNavigation/context/AppNavigationContext'
import { useContext } from 'react'

export interface WidgetPreviewProps {
  label: string
  secondaryLabel?: string
}

export const WidgetPreview: React.FC<WidgetPreviewProps> = ({ label, secondaryLabel }) => {
  const { setShowNotEntitledModal } = useContext(AppNavigationContext)

  return (
    <Box
      display="flex"
      borderRadius={3}
      paddingX={2.5}
      paddingY={2}
      onClick={() => setShowNotEntitledModal(true)}
      sx={{
        backgroundColor: theme.palette.Common.Container,
        cursor: 'pointer',
      }}
      justifyContent="space-between"
    >
      <Box flexDirection="column" gap={0.5}>
        <Typography variant="h4" color={theme.palette.Text.Primary}>
          {label}
        </Typography>
        <Typography variant="body" color={theme.palette.Text.Secondary}>
          {secondaryLabel ? secondaryLabel : `No ${label.toLocaleLowerCase()} assigned.`}
        </Typography>
      </Box>
      <Icon name="FLock" sx={{ backgroundColor: theme.palette.Common.AppBackground, borderRadius: '5px' }} />
    </Box>
  )
}
