import { groupBy, find } from 'lodash'

import { MaybeNull } from 'interfaces/common'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans/deploymentPlan'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { ExperienceType } from 'interfaces/experiences/experienceType'
import {
  DeploymentPlanFormValues,
  DeploymentPlanFormSpecialValues,
} from 'pages/consentAndRights/deploymentPlans/upsert/utils'

export const getInitialValues = ({
  policyScopes,
  deploymentPlan,
}: {
  policyScopes: PolicyScopeDTO[]
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
}): DeploymentPlanFormValues => {
  const existing = groupBy(deploymentPlan?.policyScopeExperiences, 'policyScopeCode')

  return {
    code: deploymentPlan?.code || '',
    name: deploymentPlan?.name || '',
    privacyPolicyCode: deploymentPlan?.privacyPolicyCode || DeploymentPlanFormSpecialValues.NO_DOCUMENT,
    termsOfServiceCode: deploymentPlan?.termsOfServiceCode || DeploymentPlanFormSpecialValues.NO_DOCUMENT,
    themeID: deploymentPlan?.themeID || '',
    policyScopeExperiences: (policyScopes || []).reduce((acc: {}, { code }: PolicyScopeDTO) => {
      const consent = find(existing[code], ({ experienceType }) => experienceType === ExperienceType.CONSENT)
      const preference = find(existing[code], ({ experienceType }) => experienceType === ExperienceType.PREFERENCE)

      return {
        ...acc,
        [code]: {
          [ExperienceType.CONSENT]: {
            experienceID: consent ? consent.experienceID || DeploymentPlanFormSpecialValues.NO_EXPERIENCE : '',
            experienceDefault: consent?.experienceDefault || '',
          },
          [ExperienceType.PREFERENCE]: {
            experienceID: preference ? preference.experienceID || DeploymentPlanFormSpecialValues.NO_EXPERIENCE : '',
          },
        },
      }
    }, {}),
  }
}
