import { SxProps, Theme } from '@mui/material'
import { MaybeNull } from 'interfaces'

type GetSXPropsArgs = {
  isDropdownOpen?: boolean
  isPending?: boolean
  value: MaybeNull<any>
  isValid?: boolean
  variant?: 'text' | 'contained' | 'outlined'
  sxOverrides?: SxProps<Theme>
}

export const getDropdownV2SxProps = ({
  isDropdownOpen,
  value,
  variant,
  isValid,
  sxOverrides,
}: GetSXPropsArgs): SxProps<Theme> => {
  return {
    letterSpacing: '-0.01em',
    textTransform: 'none',
    fontWeight: 600,
    fontFamily: ({ typography }) => typography.fontFamily,
    fontSize: ({ typography }) => typography.pxToRem(14),
    borderRadius: 1.25,
    py: 0.75,
    color: ({ palette }) => (value ? palette.darkDusk.main : palette.darkDuskFaded.main),
    backgroundColor: ({ palette }) => (variant === 'contained' || isDropdownOpen ? palette.fadedGrey.main : undefined),
    border: ({ palette }) => (!isValid ? `1px solid ${palette.chileanFire.main}` : `1px solid ${palette.iron.main}`),
    '&:hover': {
      backgroundColor: ({ palette }) => palette.fadedGrey.main,
      color: ({ palette }) => palette.royalBlue.main,
      backgroundImage: 'none',
    },
    '&.MuiButton-sizeSmall': {
      minWidth: '60px',
      py: 0.125,
    },
    '&:focus': {
      backgroundImage: 'none',
    },
    '&:active': {
      backgroundImage: 'none',
    },
    'svg > path': {
      fill: ({ palette }) => (value ? palette.darkDusk.main : palette.darkDuskFaded.main),
    },
    ...sxOverrides,
  }
}
