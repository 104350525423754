import * as Yup from 'yup'

interface IValidationParams {
  usedCodes?: Array<string>
}

export const getInternalSystemDSRFormValidationSchema = (params: IValidationParams) =>
  Yup.object().shape({
    code: Yup.string()
      .notOneOf(params?.usedCodes || [], 'This code is already in use')
      .required('This field is required'),
    description: Yup.string().required('Description is required'),
  })
