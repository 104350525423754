import React from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { Box, Chip } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { RoutesManager } from 'utils/routing/routesManager'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { getRiskScoreDetails } from 'pages/insights/utils'
import { undisclosedSystemsMockData } from 'pages/insights/dataRisks/__mocks__/thirdPartyRisk'
import { getMockAccessIndex } from 'pages/insights/dataRisks/__mocks__/utils'
// Mock API for Pagination replace with actual API when available
import { usePermitsStatsPerIdentitySpace } from 'api/permitStatistics/queries/usePermitsStatsPerIdentitySpace'

const PREFIX = 'UndisclosedSystems'

const classes = {
  rowClassName: `${PREFIX}-rowClassName`,
  image: `${PREFIX}-image`,
}

const Root = styled('div')(({ theme: { spacing } }) => ({
  [`& .${classes.rowClassName}`]: {
    height: 52,
    overflow: 'hidden',
  },

  [`& .${classes.image}`]: {
    width: 16,
    height: 16,
    marginRight: spacing(1),
  },
}))

type Props = {}

//TODO: Real Data and Pagination
export const UndisclosedSystems: React.FC<Props> = () => {
  const navigate = useNavigate()

  const { undisclosedSystems } = undisclosedSystemsMockData // Replace with real data
  const { data, isLoading, pagination } = usePermitsStatsPerIdentitySpace({
    params: {
      endDate: '2022-07-31',
      startDate: '2022-07-24',
    },
  }) // Replace hook appropriate api call when available

  const rows = undisclosedSystems.sort((a, b) => (a.riskScore < b.riskScore ? 1 : -1))
  return (
    <Root>
      <TablePaginated
        variant="page"
        isSmallHeaderText
        rowClassName={classes.rowClassName}
        pagination={pagination}
        pending={isLoading}
        items={data}
        onRowClick={(_, index) => {
          const asset = rows[getMockAccessIndex(index, pagination)]
          const targetPath = RoutesManager.insights.dataRisks.thirdPartyRisk.undisclosedSystems.root.getURL({
            code: asset.id,
          })
          navigate(targetPath)
        }}
        cellSettings={{
          name: {
            label: 'Name',
            width: 373,
            cellRenderer: (_, index) => {
              const displayName = rows?.[getMockAccessIndex(index, pagination)]?.name
              const logo = rows?.[getMockAccessIndex(index, pagination)]?.logo
              return (
                <Box display="flex" alignItems="center">
                  <img className={classes.image} src={logo} alt="" />
                  <Text size={14} lineHeight={2.5}>
                    {displayName}
                  </Text>
                </Box>
              )
            },
          },
          summary: {
            width: 311,
            label: 'Summary',
            cellRenderer: (_, index) => {
              const summary = rows?.[getMockAccessIndex(index, pagination)]?.summary
              return (
                <Text size={14} component="div" lineHeight="20px">
                  {summary}
                </Text>
              )
            },
          },
          riskScore: {
            width: 110,
            label: 'Risk Score',
            cellRenderer: (_, index) => {
              const dataRiskScore = rows?.[getMockAccessIndex(index, pagination)]?.riskScore
              const riskScoreDetails = getRiskScoreDetails(dataRiskScore)
              return (
                <Chip
                  label={`${dataRiskScore} ${riskScoreDetails.category}`}
                  sx={{
                    color: riskScoreDetails.textColor,
                    backgroundColor: riskScoreDetails.bgColor,
                    fontWeight: '600',
                    fontSize: '12px',
                  }}
                  size="small"
                />
              )
            },
          },
        }}
      />
    </Root>
  )
}
