import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { FormikErrors, useFormikContext } from 'formik'
import Typography from '@mui/material/Typography'
import { OptionalSearchChipList } from '../../components/OptionalSearchChipList'
import { Button, theme } from '@ketch-com/deck'
import { isArray, isObject } from 'lodash'
import { PrivacyProtocolUpsertContext } from '../context/PrivacyProtocolUpsertContext'
import { PrivacyProtocolConfigurationPutRequestBody } from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'
import { protocolDocumentationLink, protocolId } from '../../constants'
import { FormError } from 'components/form/FormError'

/**
 * Helper function to determine if there is an error in the formik context errors.purposeMapping object. We have to do this because
 * if the key is numeric (e.g. '1'), the errors object will be an array instead of a map.
 * @param errors
 * @param key
 */
const hasError = (errors: FormikErrors<PrivacyProtocolConfigurationPutRequestBody>, key: string) => {
  const errorsArrayHasError =
    isObject(errors.purposeMapping) &&
    isArray(errors.purposeMapping) &&
    Number(key) &&
    errors.purposeMapping[key] !== undefined
  const errorsObjectHasError =
    isObject(errors.purposeMapping) && !isArray(errors.purposeMapping) && errors.purposeMapping[key] !== undefined
  return errorsArrayHasError || errorsObjectHasError
}

const errorStateStyles = {
  border: `1px solid ${theme.palette.chileanFire.main}`,
  borderRadius: '11px',
  m: -1,
  p: -1,
}

/**
 * Form section for mapping customer purposes to protocol purposes
 */
export const PurposeMappingFormSection: React.FC = () => {
  const {
    setFieldValue,
    errors,
    values: { purposeMapping },
  } = useFormikContext<PrivacyProtocolConfigurationPutRequestBody>()

  const { protocol, purposes: customerPurposes } = useContext(PrivacyProtocolUpsertContext)

  const alwaysOn = protocol.purposes.length === 1

  const subTitle =
    protocol.id === protocolId.google ? (
      <>
        Map Google Consent Types to your purposes.{' '}
        <Button
          variant="link"
          color="secondary"
          size="large"
          sx={{ mt: '-2px' }}
          onClick={() => window.open(protocolDocumentationLink[protocolId.google], '_blank', 'noopener,noreferrer')}
        >
          Read documentation
        </Button>{' '}
        to learn more about how Google leverages consent state for each consent type.
      </>
    ) : (
      'Map protocol purposes to your purposes'
    )

  return (
    <Box pb={6}>
      <Box
        sx={{
          paddingTop: 2,
          paddingBottom: 3,
        }}
      >
        <Typography variant="h3">Purpose Mapping</Typography>
        <Typography variant="body" color={theme.palette.darkDuskFaded.main}>
          {subTitle}
        </Typography>
      </Box>
      <Box
        sx={{
          '& > * + *': {
            marginTop: 3,
          },
        }}
      >
        {/* Render a mapping box for each protocol purpose */}
        <Box
          display="flex"
          flexDirection="column"
          gap="12px"
          border={errors.purposeMapping?.toString() === 'must not be empty' ? errorStateStyles : ''}
        >
          {protocol.purposes &&
            protocol.purposes.map(pp => {
              return (
                <OptionalSearchChipList
                  key={pp.code}
                  listTitle={
                    <Typography variant="label" mb="4px">
                      Related Purpose(s)
                    </Typography>
                  }
                  isEnabled={isArray(purposeMapping[pp.code])}
                  inputPrompt="Start typing a purpose name"
                  options={
                    customerPurposes
                      ? customerPurposes.map(cp => ({
                          id: cp.code || '',
                          label: cp.name || '',
                          description: cp.code || '',
                        }))
                      : []
                  }
                  selectedOptions={purposeMapping[pp.code]}
                  enableTitle={(enabled: boolean) => (
                    <Typography variant="h4" color={enabled ? 'greenHaze.main' : ''}>
                      {pp.name}
                    </Typography>
                  )}
                  showError={hasError(errors, pp.code)}
                  errorMsg="At least one purpose is required"
                  containerSx={(palette: any, enabled: boolean) =>
                    enabled
                      ? {
                          padding: '18px 18px 32px 18px',
                          borderRadius: '11px',
                        }
                      : {
                          padding: '18px 18px 15px 18px',
                          borderBottom: `1px solid ${theme.palette.iron.main}`,
                          '&:last-child': {
                            borderBottom: 'none',
                          },
                        }
                  }
                  onSelect={selectedId =>
                    setFieldValue(
                      `purposeMapping.${pp.code}`,
                      purposeMapping[pp.code] ? [...purposeMapping[pp.code], selectedId] : [selectedId],
                      false,
                    )
                  }
                  onDeselect={deselectedId => {
                    setFieldValue(
                      `purposeMapping.${pp.code}`,
                      purposeMapping[pp.code] ? purposeMapping[pp.code].filter(cp => cp !== deselectedId) : [],
                      false,
                    )
                  }}
                  onEnable={checked => setFieldValue(`purposeMapping.${pp.code}`, checked ? [] : undefined, false)}
                  alwaysOn={alwaysOn}
                />
              )
            })}
        </Box>
        {errors.purposeMapping?.toString() === 'must not be empty' && (
          <FormError msg="Must map at least one protocol purpose" />
        )}
      </Box>
    </Box>
  )
}
