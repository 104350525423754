import React, { useMemo } from 'react'
import { getUserFormSectionRolesOptions } from 'pages/settings/users/upsert/utils'
import { UserRoleDTO } from 'interfaces/users/userRole'
import { useIsCurrentUserOrgAdmin } from 'utils/hooks/useIsCurrentUserOrgAdmin'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { FormRow } from '@ketch-com/deck'
import { EditRolePermissionInfoRow } from './EditRolePermissionInfoRow'
import { EditUserRolePermissionsRow, UserFormValues } from '../utils/interfaces'
import { useFormikContext } from 'formik'
import { ROLE_GROUPS } from 'utils/constants/organizationEntitlements'

type Props = {
  isEditMode?: boolean
  roles: UserRoleDTO[]
}

export const UserFormSectionRoles: React.FC<Props> = ({ roles }) => {
  const { isEntitled } = useIsEntitled()
  const { values } = useFormikContext<UserFormValues>()
  const userFormSectionRolesOptions = useMemo(
    () => getUserFormSectionRolesOptions(roles, isEntitled),
    [roles, isEntitled],
  )

  const isCurrentUserOrgAdmin = useIsCurrentUserOrgAdmin()
  const isDocumentationOnlySelected = values.roles[ROLE_GROUPS.Organization]?.includes('document')

  const rows: EditUserRolePermissionsRow[] = (
    isCurrentUserOrgAdmin
      ? Object.entries(userFormSectionRolesOptions).map(([key, value]) => ({ id: key, ...value }))
      : Object.entries(userFormSectionRolesOptions).map(([key, value]) => ({ id: key, ...value }))
  ).sort((a, b) => a.roleGroupPriority - b.roleGroupPriority)

  return (
    <FormRow title="Roles & Permissions" hasTopBorder sx={{ '& .DeckFormRowTitleSection': { paddingBottom: '0px' } }}>
      {rows.map((row, index) => (
        <EditRolePermissionInfoRow
          data={row}
          disabled={!isCurrentUserOrgAdmin || (index !== 0 && isDocumentationOnlySelected)}
        />
      ))}
    </FormRow>
  )
}
