import { APIListRequestParams } from 'api/common/utils'
import { GetConsentStatusResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  identitySpaceCode?: string
  identityValue?: string
}>

type Response = GetConsentStatusResponseBodyDTO

export const fetchConsent = ({ identitySpaceCode, identityValue }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/consent/${identitySpaceCode}/${identityValue}`,
      params: {
        filters: {},
      },
    }),
  )
