import React, { useEffect } from 'react'

import { useAppSelector } from 'store/hooks'
import { getSelectedDatabases } from 'store/dsrTransponderFormSlice/selectors'
import { DatabaseListItem } from './DatabaseListItem'

type Props = {
  setActiveDatabase: (databaseResource: string) => void
  activeDatabase: string
  searchString: string
}

export const DatabaseList: React.FC<Props> = ({ activeDatabase, setActiveDatabase, searchString }) => {
  const selectedDatabases = useAppSelector(getSelectedDatabases)

  useEffect(() => {
    if (selectedDatabases.indexOf(activeDatabase) === -1) {
      setActiveDatabase('')
    }
  }, [selectedDatabases, setActiveDatabase, activeDatabase])

  return (
    <>
      {(selectedDatabases || []).map((databaseCode: string, index: number) => {
        const isLastItem = index === selectedDatabases.length - 1
        const isSelected = databaseCode === activeDatabase

        return (
          <DatabaseListItem
            setActiveDatabase={setActiveDatabase}
            key={databaseCode}
            databaseCode={databaseCode}
            isLastItem={isLastItem}
            isSelected={isSelected}
            searchString={searchString}
          />
        )
      })}
    </>
  )
}
