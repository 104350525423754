import { useContext, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Button, DropListButton, ListItemText, ActionSheetItem } from '@ketch-com/deck'
import { WebhooksContextTimelineDTO } from '@ketch-com/figurehead'
import { useState } from 'react'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { IdentitySpaceUpsertContainer } from 'pages/settings/identitySpaces/upsert/IdentitySpaceUpsertContainer'
import { ApiCallContext } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/context/ApiCallContext'

type Props = {
  timelineItem: WebhooksContextTimelineDTO
  identitySpaces: IdentitySpaceDTO[]
}

export const UnknownIdentity: React.FC<Props> = ({ timelineItem, identitySpaces }) => {
  const { submitTimelineChange } = useContext(ApiCallContext)
  const { identities = {} } = timelineItem
  const { isActionEnabled } = identities
  const [identityKey, identityValue] = Object.entries(identities?.data || { '': '' })?.[0]

  const [showForm, setShowForm] = useState(false)
  const [selectedSpace, setSelectedSpace] = useState<string>('')
  const [showModal, setShowModal] = useState(false)

  const submit = () => {
    submitTimelineChange({
      timelineItem,
      timelineItemToReplace: {
        ...timelineItem,
        ...{ identities: { ...timelineItem.identities, isActionEnabled: false } },
      },
      newTimelineItem: {
        ...timelineItem,
        ...{ identities: { data: { [selectedSpace]: identityValue }, isActionEnabled: false } },
      },
    })
  }

  useEffect(() => {
    if (!isActionEnabled) setShowForm(false)
  }, [isActionEnabled])

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography>
          Identifier received:&nbsp;<Typography variant="label">{identityKey}</Typography>
        </Typography>
      </Box>
      <Box mt={0.75} mb={1.5}>
        <Typography variant="label">
          Issue:<Typography>&nbsp;Identifier is not found in your organization.</Typography>
        </Typography>
      </Box>
      {isActionEnabled && !showForm ? (
        <Box display="flex" gap={1.5}>
          <Button color="tertiary" onClick={() => setShowForm(true)}>
            Change Identifier
          </Button>
          <Button color="secondary" onClick={() => setShowModal(true)}>
            Create New Identifier
          </Button>
        </Box>
      ) : null}
      {showForm && (
        <Box mt={1.5} width={500}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={10}>
              <DropListButton
                // see https://github.com/mui/material-ui/issues/33661 for more details
                disableClearable={!!(selectedSpace !== null)}
                fullWidth
                label="Change To"
                isOptionEqualToValue={(option, value) => option.ID === value.ID}
                getOptionLabel={option => option.name}
                renderOption={(props, option, { selected }) => (
                  <ActionSheetItem {...props} key={option.ID} selected={selected}>
                    <ListItemText selected={selected}>{option.name}</ListItemText>
                  </ActionSheetItem>
                )}
                options={identitySpaces}
                onChange={(_, value) => value && setSelectedSpace(value.code)}
                value={identitySpaces.find(elem => selectedSpace === elem.code) || null}
                placeholder="Select"
                size="small"
              />
            </Grid>
            <Grid item xs={10} display="flex" justifyContent="flex-start" gap={1}>
              <Button size="medium" color="tertiary" onClick={() => setShowForm(false)} aria-label="close">
                Cancel
              </Button>
              <Button size="medium" color="primary" onClick={submit} aria-label="update Identifier">
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      {showModal ? (
        <IdentitySpaceUpsertContainer
          isModalForm={true}
          onClose={() => setShowModal(false)}
          onSuccess={code => {
            setShowForm(true)
            setSelectedSpace(code)
          }}
        />
      ) : null}
    </Box>
  )
}
