import { CreateFormFieldRequestBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  formData: CreateFormFieldRequestBodyDTO
}

type Response = {}

export const createFormField = ({ formData }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/form-fields`,
    }),
    formData,
  )
