import React from 'react'
import { Box, Typography } from '@mui/material'
import { TrackerProperty, TrackerOnNewProperty } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { Spinner, DataGrid, TableCell, Icon, IconDictionary, theme } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import moment from 'moment'
import { useNewPropertyTrackerPropertiesInfinite } from 'api/trackers/queries/useNewPropertyTrackerPropertiesInfinite'
import {
  TrackerOnNewPropertiesDisclosure,
  TrackerOnNewPropertyConfirm,
} from 'pages/policyCenter/trackers/list/needsReviewTrackers/trackersOnNewProperties/components'

type Props = {
  tracker: TrackerOnNewProperty
}

export const TrackerOnNewProperties: React.FC<Props> = ({ tracker = {} as TrackerOnNewProperty }) => {
  const {
    trackerProperties,
    isLoading: isTrackerPropertiesLoading,
    isFetching: isFetchingTrackerProperties,
    isFetchingNextPage: isFetchingNextTrackerProperties,
    hasNextPage: hasNextTrackerProperties,
    fetchNextPage: fetchNextTrackerProperties,
  } = useNewPropertyTrackerPropertiesInfinite({
    enabled: Boolean(tracker?.key),
    limit: 20,
    encodedTrackerKey: tracker?.encodedKey || '',
  })

  if (isTrackerPropertiesLoading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" py={4}>
        <Spinner size={35} thickness={3} />
      </Box>
    )

  return (
    <DataGrid
      rowHeight={60}
      sx={{
        backgroundColor: 'unset',
        '& .MuiDataGrid-row': {
          backgroundColor: theme.palette.superLightGrey.main,
        },
        '& .MuiDataGrid-row:hover': {
          backgroundColor: theme.palette.superLightGrey.main,
        },
        '& .MuiDataGrid-cell': {
          backgroundColor: theme.palette.superLightGrey.main,
        },
        '& .MuiDataGrid-cell:hover': {
          backgroundColor: theme.palette.superLightGrey.main,
        },
        '& .MuiDataGrid-virtualScrollerContent': {
          backgroundColor: theme.palette.superLightGrey.main,
        },
      }}
      rows={trackerProperties?.map(tracker => tracker) || []}
      columns={[
        {
          width: 420,
          field: 'name',
          headerName: 'Property',
          renderCell: (params: GridRenderCellParams<TrackerProperty>) => {
            return (
              <TableCell>
                <Box display="flex" gap={1.5} alignItems="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={32}
                    height={32}
                    borderRadius={1}
                    sx={{ backgroundColor: ({ palette }) => palette.fadedGrey.main }}
                  >
                    <Icon
                      name={IconDictionary.OGlobe}
                      iconColor={theme.palette.darkDuskFaded.main}
                      width={20}
                      height={20}
                    />
                  </Box>

                  <Box display="flex" flexDirection="column" gap={0.25}>
                    <Typography variant="label">{params?.row?.name}</Typography>
                    <Typography variant="smallBody" color="darkDuskFaded.main">
                      {params?.row?.type}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            )
          },
          sortable: false,
        },
        {
          width: 420,
          field: 'firstSeen',
          headerName: 'First Seen',
          renderCell: (params: GridRenderCellParams<TrackerProperty>) => {
            return (
              <TableCell>
                <Typography variant="body">{moment(params?.row?.firstSeen).format('MMM D YYYY, h:mm A')}</Typography>
              </TableCell>
            )
          },
          sortable: false,
        },
        {
          width: 200,
          field: 'isDisclosed',
          headerName: 'Disclosure Settings',
          renderCell: (params: GridRenderCellParams<TrackerProperty>) => {
            return (
              <TableCell>
                <TrackerOnNewPropertiesDisclosure tracker={tracker} trackerProperty={params?.row} />
              </TableCell>
            )
          },
          sortable: false,
        },
        {
          width: 120,
          field: 'key',
          headerName: '',
          renderCell: (params: GridRenderCellParams<TrackerProperty>) => {
            return (
              <TableCell>
                <TrackerOnNewPropertyConfirm tracker={tracker} trackerProperty={params?.row} />
              </TableCell>
            )
          },
          sortable: false,
        },
      ]}
      checkboxSelection={false}
      hideFooter={false}
      hideFooterPagination={false}
      hideFooterRowCount
      disableBorder
      disableColumnMenu
      disableColumnPinning
      disableColumnReorder
      disableColumnResize
      disableChildrenSorting
      disableRowSelectionOnClick
      loading={false}
      disableRowHoverStates
      columnMenuProps={
        {
          slots: {
            columnMenuFilterItem: null,
            columnMenuAggregationItem: null,
            columnMenuGroupingItem: null,
          },
        } as any
      }
      onRowsScrollEnd={() => {
        if (hasNextTrackerProperties && !isFetchingTrackerProperties && !isFetchingNextTrackerProperties)
          fetchNextTrackerProperties()
      }}
      paginationLoading={isFetchingNextTrackerProperties}
      isPaginationLoading={isFetchingNextTrackerProperties}
    />
  )
}
