import React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/styles'
import { SidebarHeader } from './components/SidebarHeader'
import { SidebarForm } from './components/SidebarForm'
import { getIn, useFormikContext } from 'formik'
import { experienceUpsertFormKeys } from '../../utils/useExperienceValidationSchema'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'

const SidebarBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.white.main,
  boxShadow: '0px 6px 16px -2px rgba(7, 26, 36, 0.16);',
  borderRadius: '11px',
  margin: '12px',
  width: 420,
  minWidth: 420,
  zIndex: 5,
}))

const SidebarHeaderBox = styled(Box)(({ theme }) => ({
  padding: '20px 14px 0px 24px',
}))

const SidebarFormBox = styled(Box)(({ theme }) => ({
  // Needed for scrolling
  position: 'absolute',
  overflowY: 'auto',
  bottom: 12,

  width: 420,
  minWidth: 420,
  padding: '24px 14px 0px 24px',

  // Custom scrollbar
  '&::-webkit-scrollbar': {
    width: 10,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.fadedGrey.main,
    borderRadius: '100px',
  },
}))

export const UpsertExperienceSidebar: React.FC = () => {
  const { errors } = useFormikContext<ExperienceV2DTO>()
  const hasExpandedHeader = Boolean(getIn(errors, experienceUpsertFormKeys.experienceName))

  return (
    // Stop clicks from propagating so sidebar doesn't close
    <SidebarBox onClick={e => e.stopPropagation()}>
      <SidebarHeaderBox>
        <SidebarHeader />
      </SidebarHeaderBox>
      <SidebarFormBox
        sx={{
          top: hasExpandedHeader ? 133 : 115,
        }}
      >
        <SidebarForm />
      </SidebarFormBox>
    </SidebarBox>
  )
}
