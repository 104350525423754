import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { SetStepDetailsRequestBodyDTO } from '@ketch-com/figurehead'

type Params = {
  formData: SetStepDetailsRequestBodyDTO
  stepId: string | undefined
  workflowExecutionId: string | undefined
}

export const updateWorkflowExecutionStep = ({ formData, stepId, workflowExecutionId }: Params) =>
  API.put<{}>(
    formatRequestString({
      entityUrl: `/api/executions/${workflowExecutionId}/steps/${stepId}/details`,
    }),
    formData,
  )
