import { APIListRequestParams } from 'api/common/utils'
import { FetchLabelDefinitionsResponseBodyDTO } from '@ketch-com/figurehead'

import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  includeIssues?: boolean
  query?: string | null | undefined
}>

export const fetchLabelsV3 = ({ query }: Params) =>
  API.get<FetchLabelDefinitionsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v3/labels`,
      params: {
        filters: {
          ...(query && {
            query,
          }),
        },
      },
    }),
  )
