import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { fetchRelationshipsPaginatedV3 } from '../fetchers/fetchRelationshipsPaginatedV3'
import { APIListRequestParams } from 'api/common/utils'

type Options = {
  enabled?: boolean
}

type Params = APIListRequestParams<{
  type?: number
}>

type UseRelationshipsInfiniteArgs = {
  params: Params
  options?: Options
}

export const useRelationshipsInfiniteV3 = ({
  options: { enabled = true } = {},
  params,
}: UseRelationshipsInfiniteArgs) => {
  const resp = useInfiniteQuery({
    enabled: !!enabled,
    queryKey: [
      ApiQueryKeys.relationshipsInfiniteV3,
      {
        ...params,
      },
    ],
    queryFn: async ({ pageParam = {} }) => {
      const fetchRelationshipsResp = await fetchRelationshipsPaginatedV3({
        nextToken: pageParam?.nextToken,
        type: params?.type,
        limit: params?.limit || 50,
      })

      return fetchRelationshipsResp
    },
    getNextPageParam: lastPage => {
      const nextToken = lastPage?.data?.next?.nextToken
      return nextToken ? { nextToken } : undefined
    },
  })

  return {
    ...resp,
    relationshipsData: resp?.data?.pages?.map(page => page?.data?.relationships || []).flat() || [],
    totalResults: resp?.data?.pages?.[0]?.data?.totalResults || 0,
  }
}
