import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchDataSubjectType } from 'api/dataSubjectTypes/fetchers/fetchDataSubjectType'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.dataSubjectType,
  queryFn: fetchDataSubjectType,
  select: res => res?.data?.dataSubjectType || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useDataSubjectType = (config: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
