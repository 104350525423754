import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { ResourceTypeDetails } from '@ketch-com/windlass/dist/schemas'
import { APIRequestParams } from 'api/common/utils'

type Params = APIRequestParams<{
  providerCode?: string
  capability?: string
}>

export const fetchResourceTypeDetails = ({ providerCode, capability }: Params) => {
  return API.get<ResourceTypeDetails>(
    formatRequestString({
      entityUrl: `/api/asset-manager/resource-type/${providerCode}/${capability}`,
    }),
  )
}
