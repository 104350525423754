import React, { useState } from 'react'
import { TrackerOnNewProperty } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { Button, PopUp } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { Box, Typography } from '@mui/material'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useConfirmTrackerOnNewProperties } from 'api/trackers/mutations/useConfirmTrackerOnNewProperties'
import pluralize from 'pluralize'

type Props = {
  tracker: TrackerOnNewProperty
}

export const TrackerOnNewPropertiesConfirm: React.FC<Props> = ({ tracker }) => {
  const queryClient = useQueryClient()
  const [shouldConfirm, setShouldConfirm] = useState(false)
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])

  const { mutateAsync: confirmTrackerOnNewProperties, isLoading: isConfirmingTrackerOnNewProperties } =
    useConfirmTrackerOnNewProperties({
      onSuccess: async () => {
        await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.trackersOnNewPropertiesInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.trackerStatistics])
        showToast({ content: 'Updated tracker', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to update tracker', type: 'error' })
      },
    })

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {isPermittedToWriteCookie ? (
        <Box display="flex" alignItems="center" gap={1}>
          <Button
            disabled={isConfirmingTrackerOnNewProperties}
            color="secondary"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              setShouldConfirm(true)
            }}
          >
            Confirm {tracker?.numProperties}
          </Button>
        </Box>
      ) : null}

      {shouldConfirm ? (
        <PopUp
          title={`Confirm ${tracker?.key}`}
          popUpWidth="550px"
          variant="dialog"
          onClose={() => setShouldConfirm(false)}
          popUpActionChildren={
            <>
              <Button color="secondary" size="large" onClick={() => setShouldConfirm(false)}>
                Cancel
              </Button>
              <Button
                color="primary"
                size="large"
                onClick={() => confirmTrackerOnNewProperties({ params: { encodedTrackerKey: tracker?.encodedKey } })}
              >
                Confirm {tracker?.numProperties}
              </Button>
            </>
          }
        >
          <Box>
            <Typography variant="body">{`Are you sure you want to confirm ${tracker?.key} on ${tracker?.numProperties} ${pluralize(
              'property',
              tracker?.numProperties,
            )}?`}</Typography>
          </Box>
        </PopUp>
      ) : null}
    </Box>
  )
}
