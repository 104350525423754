import { useState } from 'react'
import { useDebounce } from 'react-use'
import { useSubscriptionTopicsPaginated } from 'api/subscriptions/queries/useSubscriptionTopicsPaginated'
import { MaybeNull } from 'interfaces/common'
import { SubscriptionTopicDTO } from '@ketch-com/figurehead'

export const useSubscriptionTopics = () => {
  const [subscriptionsTopicQuery, setSubscriptionsTopicQuery] = useState('')
  const [subscriptionsTopicInputValue, setSubscriptionsTopicInputValue] = useState('')
  const [selectedSubscriptionsTopic, setSelectedSubscriptionsTopic] = useState<MaybeNull<SubscriptionTopicDTO>>(null)

  const { data: subscriptionTopics, isLoading: isSubscriptionTopicsLoading } = useSubscriptionTopicsPaginated({
    params: {
      includeMetadata: true,
      query: subscriptionsTopicQuery,
      limit: 1000,
    },
  })

  useDebounce(
    () => {
      if (subscriptionsTopicInputValue) {
        setSubscriptionsTopicQuery(subscriptionsTopicInputValue)
      } else {
        setSubscriptionsTopicQuery('')
      }
    },
    500,
    [subscriptionsTopicInputValue],
  )

  return {
    subscriptionTopics,
    isSubscriptionTopicsLoading,
    setSubscriptionsTopicInputValue,
    selectedSubscriptionsTopic,
    setSelectedSubscriptionsTopic,
  }
}
