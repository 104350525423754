import { Chip } from '@ketch-com/deck'
import Typography from '@mui/material/Typography'
import { useContextVariable } from 'api/contextVariables/queries/useContextVariable'
import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'
import React from 'react'

type Props = { code?: string; value?: string }

export const ContextVariableChip: React.FC<Props> = ({ code, value }) => {
  const { data: contextVariable, isLoading } = useContextVariable({
    enabled: !!code,
    params: { code: code! },
  })

  if (isLoading) return null

  const { name } = contextVariable

  return (
    <Tooltip content={value?.toString()}>
      <Chip
        label={
          <Typography variant="body" color="darkDusk.main" sx={{ overflowWrap: 'break-word' }}>
            {name}: {value?.toString()}
          </Typography>
        }
        sx={{ maxWidth: 300 }}
      />
    </Tooltip>
  )
}
