import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import {
  ApplianceWorkItems,
  CreateApplianceRequest,
  GetApplianceWorkParams,
} from '@ketch-com/windlass/dist/appliance/appliances'

interface FetchApplianceWorksParams {
  applianceId: CreateApplianceRequest['name']
  params?: { filters: GetApplianceWorkParams }
}

export const fetchApplianceWorks = ({ applianceId, params }: FetchApplianceWorksParams) =>
  API.get<ApplianceWorkItems>(
    formatRequestString({
      entityUrl: `/api/asset-manager/appliances/${applianceId}/works`,
      params,
    }),
  )
