import React, { CSSProperties } from 'react'
import { Box } from '@mui/material'
import { SystemsList } from './SystemsList'
import { OverviewHeader } from './OverviewHeader'
import { withSystemsOverviewContext } from './Context/SystemsOverviewContext'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'
import { SystemListFilters } from 'pages/dataSystems/components/Filters/SystemListFilters'
import { SystemListFilterProvider } from 'pages/dataSystems/components/Filters/SystemListFilterV2/SystemListFilterV2Context'

interface Props {
  className?: string
  style?: CSSProperties
}

const OverviewWithContext: React.FC<Props> = ({ className, style }) => {
  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.systems.root.getURL() },
    { title: 'Systems', link: RoutesManager.systems.systemsHome.root.getURL() },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box className={className} style={style}>
        <OverviewHeader />
        <SystemListFilterProvider>
          <Box display="flex" mb={2.5}>
            <SystemListFilters />
          </Box>

          <SystemsList />
        </SystemListFilterProvider>
      </Box>
    </>
  )
}

export const Overview = withSystemsOverviewContext(OverviewWithContext)
