import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'

export const getWorkflowExecutionStepStatusText = ({ status }: { status: WorkflowExecutionStepStatus }) => {
  switch (status) {
    case WorkflowExecutionStepStatus.PENDING:
      return 'Pending Review'
    case WorkflowExecutionStepStatus.FAIL:
      return 'Failed'
    // Neither product nor design are able to provide a view mapping for this status
    // Including the text mapping here anyway in case the backend returns the status unexpectedly
    case WorkflowExecutionStepStatus.INVALID_CREDENTIALS:
      return 'Invalid Credentials'
    case WorkflowExecutionStepStatus.IN_PROGRESS:
      return 'In Progress'
    case WorkflowExecutionStepStatus.SUCCESS:
      return 'Completed'
    case WorkflowExecutionStepStatus.NOT_STARTED:
      return 'Not Started'
    case WorkflowExecutionStepStatus.SKIPPED:
      return 'Skipped'
    case WorkflowExecutionStepStatus.TERMINATED:
      return 'Terminated'
    case WorkflowExecutionStepStatus.MIXED:
      return 'Mixed'
    default:
      return 'In Progress'
  }
}
