import clsx from 'clsx'
import { styled } from '@mui/material/styles'

import { ThemeModalPosition } from 'interfaces/themes-v2/themeModalPosition'

const PREFIX = 'ModalPositionVisualization'

const classes = {
  root: `${PREFIX}-root`,
  position: `${PREFIX}-position`,
  center: `${PREFIX}-center`,
  leftFullHeight: `${PREFIX}-leftFullHeight`,
  rightFullHeight: `${PREFIX}-rightFullHeight`,
}

const Root = styled('div')(({ theme: { palette } }) => ({
  [`&.${classes.root}`]: {
    position: 'relative',
    width: 36,
    height: 26,
    border: `2px solid ${palette.darkDusk.main}`,
  },

  [`& .${classes.position}`]: {
    position: 'absolute',
    backgroundColor: palette.white.main,
  },

  [`& .${classes.center}`]: {
    width: 16,
    height: 14,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: `2px solid ${palette.darkDusk.main}`,
  },

  [`& .${classes.leftFullHeight}`]: {
    top: 0,
    left: 0,
    bottom: 0,
    width: 12,
    borderRight: `2px solid ${palette.darkDusk.main}`,
  },

  [`& .${classes.rightFullHeight}`]: {
    top: 0,
    right: 0,
    bottom: 0,
    width: 12,
    borderLeft: `2px solid ${palette.darkDusk.main}`,
  },
}))

type Props = {
  position: ThemeModalPosition
}

export const ModalPositionVisualization = ({ position }: Props) => {
  return (
    <Root className={classes.root}>
      <div
        className={clsx(classes.position, {
          [classes.center]: position === ThemeModalPosition.CENTER,
          [classes.leftFullHeight]: position === ThemeModalPosition.LEFT_FULL_HEIGHT,
          [classes.rightFullHeight]: position === ThemeModalPosition.RIGHT_FULL_HEIGHT,
        })}
      />
    </Root>
  )
}
