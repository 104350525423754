import { Icon, InfoRow, theme } from '@ketch-com/deck'
import { Box, styled, Tooltip, Typography } from '@mui/material'
import { EditUserRolePermissionsRow } from '../utils/interfaces'
import { FormSegmentButtons } from 'components/form/FormSegmentButtons'
import { NO_PERMISSION } from '../../constants'

interface Props {
  data: EditUserRolePermissionsRow
  disabled?: boolean
}

const ContentBox = styled(Box)({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
})

const TooltipsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',
})

const TooltipBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

export const EditRolePermissionInfoRow: React.FC<Props> = ({ data, disabled = false }) => {
  const { roleName, roleDescription, tooltips, options, group } = data

  return (
    <InfoRow
      title={roleName}
      titleAddendum={roleDescription}
      marginTop="24px"
      paddingTop="8px"
      sx={{
        '&.InfoRow-container': {
          ':first-child': {
            borderTop: `2px solid ${theme.palette.Black.o100}`,
            marginTop: '24px',
            '.InfoRow-helpTextTitle': { pt: '8px', fontWeight: '600 !important', fontSize: '16px !important' },
          },
          ':nth-child(2)': { borderTop: `2px solid ${theme.palette.Black.o100}` },
        },
        '.InfoRow-helpTextTitle': {
          color: `${theme.palette.Black.o100} !important`,
          fontWeight: '600 !important',
          pt: roleDescription ? '0px' : '10px',
        },
        '.InfoRow-helpTextAddendum': { color: `${theme.palette.Black.o100} !important`, fontSize: '14px !important' },
      }}
    >
      <ContentBox>
        <FormSegmentButtons
          formPropertyName={`roles.${group}`}
          options={options}
          disabled={disabled}
          defaultValue={NO_PERMISSION}
          disabledTooltip="Contact your organization admin to change permissions"
          labelKey="name"
          valueKey="id"
        />
        <Tooltip
          placement="bottom"
          title={
            <TooltipsBox>
              {tooltips.map(tooltip => (
                <TooltipBox>
                  <Typography variant="smallLabel">{tooltip?.permLevelTitle}</Typography>
                  <Typography variant="smallBody">{tooltip?.permLevelDescription}</Typography>
                </TooltipBox>
              ))}
            </TooltipsBox>
          }
        >
          <Box display="flex">
            <Icon name="FUnknown" width={16} height={16} iconColor={theme.palette.Black.o48} />
          </Box>
        </Tooltip>
      </ContentBox>
    </InfoRow>
  )
}
