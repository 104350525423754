import { TranslatorFunction } from 'i18n/types'
import { PerJurisdictionPermitStatsDTO } from '@ketch-com/figurehead'
import { ColorNames } from '@ketch-com/deck'
import { getConsentRequiredColor, getConsentRequiredText } from '../../AllowanceBreakdown/utils'

// function to sum the properties in an array of objects

const colors = [
  '#15005A',
  '#1463BB',
  '#3EB3CD',
  '#D1E0ED',
  '#B1BFFF',
  '#d2baff',
  '#EF57FF',
  '#9C64FF',
  '#690000',
  '#FF9461',
  '#FFE178',
  '#FFE0BF',
  '#FFBA79',
  '#FDB815',
]

export type ReshapedPerJurisdictionDataForPieChart = {
  code: string
  color: string
  consentRequiredText: string
  name: string
  percent: number
  totalRequests: number
  consentRequiredColor: ColorNames
}

export const getTransformedPerJurisdictionData = (
  jurisdictions: PerJurisdictionPermitStatsDTO[],
  jurisdictionTotalRequests: number,
  translate: TranslatorFunction,
): ReshapedPerJurisdictionDataForPieChart[] => {
  const reshapedData = []

  for (let i = 0; i < jurisdictions.length; i++) {
    const el = jurisdictions[i]

    const consentRequiredText = getConsentRequiredText({
      consentRequiredCount: el?.consentRequiredCount || 0,
      totalCount: el?.purposeCount || 0,
      entity: 'purposes',
      translate,
    })

    const consentRequiredColor = getConsentRequiredColor({
      consentRequiredCount: el?.consentRequiredCount || 0,
      totalCount: el?.purposeCount || 0,
    })

    const payload = {
      code: el?.code || '',
      color: colors[i > colors.length ? i % colors.length : i],
      consentRequiredColor,
      consentRequiredText,
      name: el?.name || '',
      percent: ((el?.requestCount || 0) / (jurisdictionTotalRequests || 1)) * 100,
      totalRequests: el?.requestCount || 0,
    }
    reshapedData.push(payload)
  }

  return reshapedData.sort((a, b) => b.percent - a.percent)
}
