import { APIListRequestParams } from '../../common/utils'
import { API } from '../../../utils/api/api'
import { FetchAssetProcessingActivitiesResponseBodyDTO } from '@ketch-com/figurehead'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  assetCode?: string
  resourceTypeCode?: string
}>

export const fetchAssetProcessingActivities = ({ assetCode, resourceTypeCode }: Params) => {
  return API.get<FetchAssetProcessingActivitiesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v3/assets/${assetCode}/processing-activities`,
      params: {
        filters: {
          resourceTypeCode,
        },
      },
    }),
  )
}
