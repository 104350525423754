import { useContext, useMemo } from 'react'
import { useFormik } from 'formik'

import { getIdentityVerificationAvailablePathsStringValue, getValidationSchema } from '../utils'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
import { ResolveIdentityVerificationFormValues } from '../interfaces'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'
import { showToast } from 'components/modals/AlertComponent'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { useCompleteWorkflowExecutionStepV2 } from 'api/workflowExecutions/mutations/useCompleteWorkflowExecutionStepV2'

export const useIdentityVerificationResolveFormUtils = () => {
  const navigate = useNavigate()
  const { rightInvocation, stepId, refetchStepDetails, stepDetails, workflowExecutionId } = useContext(
    RightInvocationWorkflowStepDetailViewContext,
  )

  const validationSchema = useMemo(() => getValidationSchema(), [])

  const initialValues: ResolveIdentityVerificationFormValues = useMemo(
    () => ({
      availablePaths: getIdentityVerificationAvailablePathsStringValue(stepDetails),
    }),
    [stepDetails],
  )

  const { mutateAsync: handleCompleteWorkflowExecutionStepV2 } = useCompleteWorkflowExecutionStepV2({
    onSuccess: async () => {
      await refetchStepDetails()
      showToast({ content: 'Resolved step', type: 'success' })
      navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocation?.id || '' }))
    },
  })

  const resolveFormik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      logObjectDetails(
        {
          values,
        },
        'SUBMITTING IDV RESOLVE',
        'yellow',
        false, // ensures will only log in debug mode, ?debug=log
      )

      const availablePaths = getIdentityVerificationAvailablePathsStringValue(stepDetails)

      await handleCompleteWorkflowExecutionStepV2({
        params: {
          workflowExecutionId: workflowExecutionId!,
          stepId: stepId!,
          availablePaths,
        },
      })
    },
  })

  return { resolveFormik }
}
