import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useDeleteInvite } from 'api/users/mutations/useDeleteInvite'
import { showToast } from 'components/modals/AlertComponent'
import { Button, PopUp } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  inviteeEmail: string
  onSubmit?: () => Promise<void>
  onCancel: () => void
}

export const DeleteInviteModal: React.FC<Props> = props => {
  const queryClient = useQueryClient()

  const { inviteeEmail, onSubmit, onCancel } = props
  const [isBusy, setIsBusy] = useState(false)

  const { mutate: handleDeleteInvite } = useDeleteInvite({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'User deleted', type: 'success' })

      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to delete user', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <PopUp
      title="Delete Invited User"
      variant="dialog"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Close
          </Button>
          <Button
            pending={isBusy}
            color="primary"
            size="large"
            onClick={() => {
              handleDeleteInvite({
                params: {
                  inviteeEmail,
                },
              })
            }}
          >
            Delete
          </Button>
        </>
      }
    >
      <Typography variant="body">
        Are you sure you want to delete <strong>{inviteeEmail}</strong>?
      </Typography>
    </PopUp>
  )
}
