// layout utils
import { ProtectedRoute } from 'components/ui-routing/ProtectedRoute'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { relativePath } from 'utils/routing/relativePath'

import { PolicyScopesListContainer } from './policyScopes/list/PolicyScopesListContainer'
import { PolicyScopeLibraryContainer } from './policyScopes/library/PolicyScopeLibraryContainer'
import { PolicyScopeView } from './policyScopes/view/PolicyScopeView'
import { PolicyScopeUpsertContainer as PolicyScopeUpsertContainerV2 } from './policyScopes/upsert/PolicyScopeUpsertContainer'
import { PolicyUpsertViewContainer } from './policies/upsert/PolicyUpsertViewContainer'
import { PolicyViewContainer } from './policies/view/PolicyViewContainer'
import { PolicyViewContainer as PolicyViewContainerV2 } from './policies/viewV2/PolicyViewContainer'

import { CookiesListContainer } from './cookies/list/CookiesListContainer'
import { CookiesLibraryContainer } from './cookies/library/CookiesLibraryContainer'
import { CookieViewContainer } from './cookies/view/CookieViewContainer'
import { CookieUpsertContainer } from './cookies/upsert/CookieUpsertContainer'
import { EditControllerContainer } from './controllers/edit/EditControllerContainer'
import { ControllersListContainer } from './controllers/list/ControllersListContainer'
import { PoliciesListContainer } from './policies/list/PoliciesListContainer'
import { PoliciesListContainer as PoliciesListContainerV2 } from './policies/listV2/PoliciesListContainer'

// release manager utils
import { isRelease } from 'utils/releases'
import { BusinessFunctionList } from './businessFunctions/list/BusinessFunctionList'
import { BusinessFunctionUpsert } from './businessFunctions/upsert/BusinessFunctionUpsert'
import { BusinessFunctionOverview } from './businessFunctions/view/BusinessFunctionOverview'

// utils
import { Release } from 'utils/releases/RELEASES'
import { lazyLoadRoute } from 'utils/routing/lazyLoadRoute'
import { ProcessingActivities } from './processingActivities/home/ProcessingActivities'
import { UpsertProcessingActivities } from './processingActivities/upsert'
import { ProcessingActivity } from './processingActivities/view/ProcessingActivity'
import { OverviewTab } from './processingActivities/view/Overview/OverviewTab'
import { DataSourcesTab } from './processingActivities/view/DataSources/DataSourcesTab'
import { DataFlowTab } from './processingActivities/view/DataFlow/DataFlowTab'
import { VersionsTab } from './processingActivities/view/Versions/VersionsTab'
import { PrivacyProgramOverview } from './overview/PrivacyProgramOverview'
import { RoutesManager } from 'utils/routing/routesManager'

export const privacyProgramRoutesChildren = [
  {
    element: <ProtectedRoute component={PrivacyProgramOverview} permissions={[]} requireAllPermissions={false} />,
    path: '',
  },
  {
    element: <ProtectedRoute component={PolicyScopesListContainer} permissions={PERMISSIONS.JURISDICTION_READ} />,
    path: `${relativePath(RoutesManager.policyCenter.policyScopes.root.pattern)}/*`,
  },
  {
    element: <ProtectedRoute component={PolicyScopeLibraryContainer} permissions={PERMISSIONS.JURISDICTION_READ} />,
    path: relativePath(RoutesManager.policyCenter.policyScopes.library.root.pattern),
  },
  {
    lazy: async () => {
      const { PurposesListContainer } = await import('./purposes/PurposesPages')
      return lazyLoadRoute(PurposesListContainer, PERMISSIONS.PURPOSE_READ)
    },
    path: relativePath(RoutesManager.policyCenter.purposes.root.pattern),
  },
  {
    lazy: async () => {
      const { PurposeLibraryContainer } = await import('./purposes/PurposesPages')
      return lazyLoadRoute(PurposeLibraryContainer, PERMISSIONS.PURPOSE_READ)
    },
    path: relativePath(RoutesManager.policyCenter.purposes.library.root.pattern),
  },
  {
    element: (
      <ProtectedRoute
        component={isRelease(Release.PolicyUI) ? PoliciesListContainerV2 : PoliciesListContainer}
        permissions={PERMISSIONS.ASSET_POLICY_READ}
      />
    ),
    path: relativePath(RoutesManager.policyCenter.policies.list.pattern),
  },
  {
    element: <ProtectedRoute component={BusinessFunctionList} permissions={PERMISSIONS.BUSINESS_FUNCTIONS_READ} />,
    path: relativePath(RoutesManager.policyCenter.businessFunctions.root.pattern),
  },

  // Jurisdictions
  {
    element: <ProtectedRoute component={PolicyScopeView} permissions={PERMISSIONS.JURISDICTION_READ} />,
    path: `${relativePath(RoutesManager.policyCenter.policyScopes.view.root.pattern)}/*`,
  },
  {
    element: <ProtectedRoute component={PolicyScopeUpsertContainerV2} permissions={PERMISSIONS.JURISDICTION_WRITE} />,
    path: relativePath(RoutesManager.policyCenter.policyScopes.upsert.root.path),
  },
  // Purposes
  {
    lazy: async () => {
      const { PurposeViewContainer } = await import('./purposes/PurposesPages')
      return lazyLoadRoute(PurposeViewContainer, PERMISSIONS.PURPOSE_READ)
    },
    path: relativePath(RoutesManager.policyCenter.purposes.view.root.pattern),
    children: [
      // overview
      {
        lazy: async () => {
          const { PurposeViewContainer } = await import('./purposes/PurposesPages')
          return lazyLoadRoute(PurposeViewContainer, PERMISSIONS.PURPOSE_READ)
        },
        path: relativePath(RoutesManager.policyCenter.purposes.view.overview.root.pattern),
      },
      // display
      {
        lazy: async () => {
          const { PurposeViewContainer } = await import('./purposes/PurposesPages')
          return lazyLoadRoute(PurposeViewContainer, PERMISSIONS.PURPOSE_READ)
        },
        path: relativePath(RoutesManager.policyCenter.purposes.view.display.root.pattern),
      },
      // legal basis
      {
        lazy: async () => {
          const { PurposeViewContainer } = await import('./purposes/PurposesPages')
          return lazyLoadRoute(PurposeViewContainer, PERMISSIONS.PURPOSE_READ)
        },
        path: relativePath(RoutesManager.policyCenter.purposes.view.legalBases.root.pattern),
      },
      // trackers
      {
        lazy: async () => {
          const { PurposeViewContainer } = await import('./purposes/PurposesPages')
          return lazyLoadRoute(PurposeViewContainer, PERMISSIONS.PURPOSE_READ)
        },
        path: relativePath(RoutesManager.policyCenter.purposes.view.trackers.root.pattern),
      },
      // cookies
      {
        lazy: async () => {
          const { PurposeViewContainer } = await import('./purposes/PurposesPages')
          return lazyLoadRoute(PurposeViewContainer, PERMISSIONS.PURPOSE_READ)
        },
        path: relativePath(RoutesManager.policyCenter.purposes.view.cookies.root.pattern),
      },
      // data systems
      {
        lazy: async () => {
          const { PurposeViewContainer } = await import('./purposes/PurposesPages')
          return lazyLoadRoute(PurposeViewContainer, PERMISSIONS.PURPOSE_READ)
        },
        path: relativePath(RoutesManager.policyCenter.purposes.view.dataSystems.root.pattern),
      },
      // documents
      {
        lazy: async () => {
          const { PurposeViewContainer } = await import('./purposes/PurposesPages')
          return lazyLoadRoute(PurposeViewContainer, PERMISSIONS.PURPOSE_READ)
        },
        path: relativePath(RoutesManager.policyCenter.purposes.view.documents.root.pattern),
      },
      // business functions
      {
        lazy: async () => {
          const { PurposeViewContainer } = await import('./purposes/PurposesPages')
          return lazyLoadRoute(PurposeViewContainer, PERMISSIONS.PURPOSE_READ)
        },
        path: relativePath(RoutesManager.policyCenter.purposes.view.businessFunctions.root.pattern),
      },
      // processing activities
      {
        lazy: async () => {
          const { PurposeViewContainer } = await import('./purposes/PurposesPages')
          return lazyLoadRoute(PurposeViewContainer, PERMISSIONS.PURPOSE_READ)
        },
        path: relativePath(RoutesManager.policyCenter.purposes.view.processingActivities.root.pattern),
      },
      // versions
      {
        lazy: async () => {
          const { PurposeViewContainer } = await import('./purposes/PurposesPages')
          return lazyLoadRoute(PurposeViewContainer, PERMISSIONS.PURPOSE_READ)
        },
        path: relativePath(RoutesManager.policyCenter.purposes.view.versions.root.pattern),
      },
    ],
  },
  {
    lazy: async () => {
      const { PurposeUpsertContainer } = await import('./purposes/PurposesPages')
      return lazyLoadRoute(PurposeUpsertContainer, PERMISSIONS.PURPOSE_WRITE)
    },
    path: relativePath(RoutesManager.policyCenter.purposes.upsert.root.path),
  },
  // Policy Documents
  {
    path: relativePath(RoutesManager.policyCenter.policyDocuments.root.pattern),
    lazy: async () => {
      const { PolicyDocuments } = await import('./policyDocuments')
      return lazyLoadRoute(PolicyDocuments, PERMISSIONS.POLDOC_READ)
    },
  },
  {
    path: `${relativePath(RoutesManager.policyCenter.policyDocuments.view.root.pattern)}/*`,
    lazy: async () => {
      const { PolicyDocumentViewContainer } = await import('./policyDocuments')
      return lazyLoadRoute(PolicyDocumentViewContainer, PERMISSIONS.POLDOC_READ)
    },
  },
  {
    path: relativePath(RoutesManager.policyCenter.policyDocuments.upsert.root.pattern),
    lazy: async () => {
      const { PolicyDocumentUpsert } = await import('./policyDocuments')
      return lazyLoadRoute(PolicyDocumentUpsert, PERMISSIONS.POLDOC_WRITE)
    },
  },
  // Cookies
  {
    element: <ProtectedRoute component={CookieViewContainer} permissions={PERMISSIONS.COOKIE_READ} />,
    path: `${relativePath(RoutesManager.policyCenter.cookies.view.root.pattern)}/*`,
  },
  {
    element: <ProtectedRoute component={CookieUpsertContainer} permissions={PERMISSIONS.COOKIE_WRITE} />,
    path: relativePath(RoutesManager.policyCenter.cookies.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={CookiesListContainer} permissions={PERMISSIONS.COOKIE_READ} />,
    path: relativePath(RoutesManager.policyCenter.cookies.root.pattern),
  },
  {
    element: <ProtectedRoute component={CookiesLibraryContainer} permissions={PERMISSIONS.COOKIE_READ} />,
    path: relativePath(RoutesManager.policyCenter.cookies.library.root.pattern),
  },
  // Controllers
  {
    element: <ProtectedRoute component={ControllersListContainer} permissions={PERMISSIONS.CONTROLLER_READ} />,
    path: relativePath(RoutesManager.policyCenter.controllers.root.pattern),
  },
  {
    element: <ProtectedRoute component={EditControllerContainer} permissions={PERMISSIONS.CONTROLLER_WRITE} />,
    path: relativePath(RoutesManager.policyCenter.controllers.upsert.root.pattern),
  },
  {
    element: (
      <ProtectedRoute
        component={isRelease(Release.PolicyUI) ? PolicyViewContainerV2 : PolicyViewContainer}
        permissions={PERMISSIONS.ASSET_POLICY_READ}
      />
    ),
    path: relativePath(RoutesManager.policyCenter.policies.detail.pattern),
  },
  {
    element: <ProtectedRoute component={PolicyUpsertViewContainer} permissions={PERMISSIONS.ASSET_POLICY_WRITE} />,
    path: relativePath(RoutesManager.policyCenter.policies.upsert.pattern),
  },
  {
    element: <ProtectedRoute component={BusinessFunctionUpsert} permissions={PERMISSIONS.BUSINESS_FUNCTIONS_WRITE} />,
    path: relativePath(RoutesManager.policyCenter.businessFunctions.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={BusinessFunctionOverview} permissions={PERMISSIONS.BUSINESS_FUNCTIONS_READ} />,
    path: relativePath(RoutesManager.policyCenter.businessFunctions.view.root.pattern),
  },
  // Assessments
  {
    path: `${RoutesManager.policyCenter.assessments.root.pattern}`,
    lazy: async () => {
      const { AssessmentsRootPage } = await import('./assessments/AssessmentsPages')
      return lazyLoadRoute(AssessmentsRootPage, PERMISSIONS.ASSESSMENT_READ)
    },
    children: [
      {
        path: relativePath(RoutesManager.policyCenter.assessments.list.pattern),
        lazy: async () => {
          const { AssessmentsList } = await import('./assessments/AssessmentsPages')
          return lazyLoadRoute(AssessmentsList, PERMISSIONS.ASSESSMENT_READ)
        },
      },
      {
        path: relativePath(RoutesManager.policyCenter.assessments.templates.list.pattern),
        lazy: async () => {
          const { TemplatesList } = await import('./assessments/AssessmentsPages')
          return lazyLoadRoute(TemplatesList, PERMISSIONS.ASSESSMENT_READ)
        },
      },
      {
        path: relativePath(RoutesManager.policyCenter.assessments.questions.root.pattern),
        lazy: async () => {
          const { QuestionsList } = await import('./assessments/AssessmentsPages')
          return lazyLoadRoute(QuestionsList, PERMISSIONS.ASSESSMENT_READ)
        },
      },
    ],
  },
  {
    path: RoutesManager.policyCenter.assessments.create.pattern,
    lazy: async () => {
      const { CreateAssessment } = await import('./assessments/AssessmentsPages')
      return lazyLoadRoute(CreateAssessment, PERMISSIONS.ASSESSMENT_WRITE)
    },
  },
  {
    path: RoutesManager.policyCenter.assessments.view.pattern,
    lazy: async () => {
      const { AssessmentViewPage } = await import('./assessments/AssessmentsPages')
      return lazyLoadRoute(AssessmentViewPage, PERMISSIONS.ASSESSMENT_READ)
    },
  },
  {
    path: RoutesManager.policyCenter.assessments.show.pattern,
    lazy: async () => {
      const { AssessmentShowPage } = await import('./assessments/AssessmentsPages')
      return lazyLoadRoute(AssessmentShowPage, PERMISSIONS.ASSESSMENT_READ)
    },
  },
  {
    path: RoutesManager.policyCenter.assessments.edit.approved.pattern,
    lazy: async () => {
      const { AssessmentApprovedPage } = await import('./assessments/AssessmentsPages')
      return lazyLoadRoute(AssessmentApprovedPage, PERMISSIONS.ASSESSMENT_APPROVE)
    },
  },
  {
    path: RoutesManager.policyCenter.assessments.edit.pattern,
    lazy: async () => {
      const { AssessmentEditPage } = await import('./assessments/AssessmentsPages')
      return lazyLoadRoute(AssessmentEditPage, PERMISSIONS.ASSESSMENT_RESPOND)
    },
  },
  {
    path: RoutesManager.policyCenter.assessments.questions.view.pattern,
    lazy: async () => {
      const { QuestionViewContainer } = await import('./assessments/AssessmentsPages')
      return lazyLoadRoute(QuestionViewContainer, PERMISSIONS.ASSESSMENT_READ)
    },
  },
  {
    path: RoutesManager.policyCenter.assessments.questions.published.pattern,
    lazy: async () => {
      const { QuestionPublishedContainer } = await import('./assessments/AssessmentsPages')
      return lazyLoadRoute(QuestionPublishedContainer, PERMISSIONS.ASSESSMENT_READ)
    },
  },
  {
    path: RoutesManager.policyCenter.assessments.questions.upsert.pattern,
    lazy: async () => {
      const { QuestionUpsertContainer } = await import('./assessments/AssessmentsPages')
      return lazyLoadRoute(QuestionUpsertContainer, PERMISSIONS.ASSESSMENT_WRITE)
    },
  },
  {
    path: RoutesManager.policyCenter.assessments.templates.view.pattern,
    lazy: async () => {
      const { TemplateViewContainer } = await import('./assessments/AssessmentsPages')
      return lazyLoadRoute(TemplateViewContainer, PERMISSIONS.ASSESSMENT_READ)
    },
  },
  {
    path: RoutesManager.policyCenter.assessments.templates.published.pattern,
    lazy: async () => {
      const { TemplatePublishedContainer } = await import('./assessments/AssessmentsPages')
      return lazyLoadRoute(TemplatePublishedContainer, PERMISSIONS.ASSESSMENT_READ)
    },
  },
  {
    path: RoutesManager.policyCenter.assessments.templates.show.pattern,
    lazy: async () => {
      const { TemplateShowPage } = await import('./assessments/AssessmentsPages')
      return lazyLoadRoute(TemplateShowPage, PERMISSIONS.ASSESSMENT_READ)
    },
  },
  {
    path: RoutesManager.policyCenter.assessments.templates.upsert.pattern,
    lazy: async () => {
      const { TemplateUpsertContainer } = await import('./assessments/AssessmentsPages')
      return lazyLoadRoute(TemplateUpsertContainer, PERMISSIONS.ASSESSMENT_WRITE)
    },
  },
  // Processing Activities
  {
    element: <ProtectedRoute component={ProcessingActivities} permissions={PERMISSIONS.PROCESSING_ACTIVITY_READ} />,
    path: relativePath(RoutesManager.policyCenter.processingActivities.root.pattern),
  },
  {
    element: <ProtectedRoute component={ProcessingActivity} permissions={PERMISSIONS.PROCESSING_ACTIVITY_READ} />,
    path: relativePath(RoutesManager.policyCenter.processingActivities.view.root.pattern),
    children: [
      {
        element: <ProtectedRoute component={OverviewTab} permissions={PERMISSIONS.PROCESSING_ACTIVITY_READ} />,
        path: relativePath(RoutesManager.policyCenter.processingActivities.view.overview.root.pattern),
      },
      {
        element: <ProtectedRoute component={DataSourcesTab} permissions={PERMISSIONS.PROCESSING_ACTIVITY_READ} />,
        path: relativePath(RoutesManager.policyCenter.processingActivities.view.dataSources.root.pattern),
      },
      {
        element: <ProtectedRoute component={DataFlowTab} permissions={PERMISSIONS.PROCESSING_ACTIVITY_READ} />,
        path: relativePath(RoutesManager.policyCenter.processingActivities.view.dataFlow.root.pattern),
      },
      {
        element: <ProtectedRoute component={VersionsTab} permissions={PERMISSIONS.PROCESSING_ACTIVITY_READ} />,
        path: relativePath(RoutesManager.policyCenter.processingActivities.view.versions.root.pattern),
      },
    ],
  },
  {
    element: (
      <ProtectedRoute component={UpsertProcessingActivities} permissions={PERMISSIONS.PROCESSING_ACTIVITY_WRITE} />
    ),
    path: RoutesManager.policyCenter.processingActivities.upsert.pattern,
  },
]
