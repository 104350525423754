import React from 'react'

import { UserDTO } from 'interfaces/users/users'
import { UserStatus } from 'interfaces/users/userStatus'
import { Box } from '@mui/material'
import { Status, StatusState, StatusVariant } from '@ketch-com/deck'

type Props = {
  user: UserDTO
}

export const UsersViewHeaderDetails: React.FC<Props> = ({ user }) => {
  return (
    <Box>
      {user.status === UserStatus.ACTIVE ? (
        <Status status={StatusState.success} variant={StatusVariant.outlined} label="Active" icon="Dot" />
      ) : (
        <Status status={StatusState.warning} variant={StatusVariant.outlined} label="Invited" icon="Dot" />
      )}
    </Box>
  )
}
