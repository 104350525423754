import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, DataGrid, EmptyState, Spinner, TableCell, IconDictionary, Icon, theme } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { ApprovedTrackerPropertiesContextProvider } from 'pages/policyCenter/trackers/context'
import { ReactComponent as EmptyTrackersIcon } from 'assets/icons/emptyState/EmptyStateTrackers.svg'
import { GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium'
import { Provenance, Tracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import {
  Purposes,
  Disclosure,
  Categories,
  Properties,
} from 'pages/policyCenter/trackers/list/approvedTrackers/components'
import { ApplicationDTO } from 'interfaces/applications/application'
import { useApprovedTrackersInfinite } from 'api/trackers/queries/useApprovedTrackersInfinite'

type Props = {
  application: ApplicationDTO
}

export const ApplicationTrackers: FC<Props> = ({ application }) => {
  const navigate = useNavigate()

  const {
    trackers: acceptedTrackers,
    isLoading: isAcceptedTrackersLoading,
    isFetchingNextPage: isFetchingNextAcceptedTrackers,
    hasNextPage: hasNextAcceptedTrackers,
    fetchNextPage: fetchNextAcceptedTrackers,
    isFetching: isFetchingAcceptedTrackers,
  } = useApprovedTrackersInfinite({
    propertyIDs: [application?.ID],
  })

  const rows = acceptedTrackers?.map(tracker => tracker) || []
  const cols = [
    {
      width: 340,
      field: 'name',
      headerName: 'Tracker',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        const provenance = params?.row?.provenance
        return (
          <TableCell>
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={36}
                height={36}
                borderRadius={1}
                sx={{ backgroundColor: ({ palette }) => palette.fadedGrey.main }}
              >
                {provenance === Provenance?.first ? (
                  <Icon name={IconDictionary.FCookie} iconColor={theme.palette.darkDuskFaded.main} />
                ) : (
                  <Icon name={IconDictionary.OCookie} iconColor={theme.palette.darkDuskFaded.main} />
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="label">{params?.row?.key}</Typography>
                {params?.row?.dataSystem ? (
                  <Box display="flex" alignItems="center" gap={1}>
                    {params?.row?.dataSystem?.logoURL ? (
                      <img
                        src={params?.row?.dataSystem?.logoURL}
                        alt={params?.row?.dataSystem?.name}
                        width={12}
                        height={12}
                      />
                    ) : null}
                    {params?.row?.dataSystem?.name ? (
                      <Typography variant="footnote" color="darkDusk.main">
                        {params?.row?.dataSystem?.name}
                      </Typography>
                    ) : null}
                  </Box>
                ) : (
                  <Typography variant="footnote" color="darkDuskFaded.main">
                    Unknown System
                  </Typography>
                )}
              </Box>
            </Box>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 160,
      field: 'categories',
      headerName: 'Categories',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            {params?.row?.categories?.length ? (
              <Categories tracker={params?.row} />
            ) : (
              <Typography variant="body" color="darkDuskFaded.main">
                Unknown
              </Typography>
            )}
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 160,
      field: 'provenance',
      headerName: 'Provenance',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        const provenance = params?.row?.provenance || ''
        return (
          <TableCell>
            <Typography variant="body">{provenance === Provenance?.first ? '1st party' : '3rd party'}</Typography>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 160,
      field: 'purposes',
      headerName: 'Purposes',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            {params?.row?.purposes?.length ? (
              <Purposes tracker={params?.row} />
            ) : (
              <Typography variant="body" color="darkDuskFaded.main">
                None
              </Typography>
            )}
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 160,
      field: 'id',
      headerName: 'Found on',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            <ApprovedTrackerPropertiesContextProvider>
              <Properties tracker={params?.row} />
            </ApprovedTrackerPropertiesContextProvider>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 200,
      field: 'properties',
      headerName: 'Disclosure',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            <Disclosure tracker={params?.row} />
          </TableCell>
        )
      },
      sortable: false,
    },
  ]

  if (isAcceptedTrackersLoading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Spinner size={35} thickness={3} />
      </Box>
    )

  let Component = (
    <EmptyState
      size="medium"
      customIcon={<EmptyTrackersIcon />}
      title="No trackers were found on this property"
      subTitle=""
    />
  )

  if (acceptedTrackers?.length)
    Component = (
      <Box style={{ height: '69vh' }}>
        <DataGrid
          getRowId={(row: Tracker) => row?.key}
          sx={{
            padding: 'unset',
            cursor: 'pointer',
            '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
              backgroundImage: 'unset',
              cursor: 'auto',
            },
          }}
          rows={rows}
          columns={cols}
          checkboxSelection={false}
          hideFooter={false}
          hideFooterPagination={false}
          hideFooterRowCount
          disableColumnMenu
          disableColumnPinning
          disableColumnReorder
          disableColumnResize
          disableChildrenSorting
          disableRowSelectionOnClick
          loading={false}
          columnMenuProps={
            {
              slots: {
                columnMenuFilterItem: null,
                columnMenuAggregationItem: null,
                columnMenuGroupingItem: null,
              },
            } as any
          }
          onRowClick={(params: GridRowParams<Tracker>) => {
            const { row } = params
            if (row?.encodedKey) {
              navigate(RoutesManager.policyCenter.trackers.details.root.getURL({ encodedTrackerKey: row?.encodedKey }))
            }
          }}
          onRowsScrollEnd={() => {
            if (hasNextAcceptedTrackers && !isFetchingAcceptedTrackers && !isFetchingNextAcceptedTrackers)
              fetchNextAcceptedTrackers()
          }}
          paginationLoading={isFetchingNextAcceptedTrackers}
          isPaginationLoading={isFetchingNextAcceptedTrackers}
        />
      </Box>
    )

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <ContentGroup
        title="Trackers"
        subTitle="Trackers that are associated with this property."
        titleVariant="h3"
        variant="inner-page-header"
      />

      {Component}
    </Box>
  )
}
