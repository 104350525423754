import Box from '@mui/material/Box'
import { Routes, Route, useLocation } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ViewLayoutHeaderLeftMenu, ViewLayoutLeftMenu } from 'components/ui-layouts/viewLayoutLeftMenu'
import { ReactComponent as EmptyStateDatabaseIcon } from 'assets/icons/databases.svg'
import { ReactComponent as UnutilizedDataIcon } from 'assets/icons/unutilizedData.svg'
import { UnutilizedDataDetails } from './components/UnutilizedDataDetails'
import { UnutilizedDataMenu } from './components/UnutilizedDataMenu'
import { Button } from '@ketch-com/deck'
import { assets } from '../__mocks__/unutilizedDataAssets' //TODO:JA use real data
import { DetailsEmptyState } from '../components/layout/DetailsEmptyState'
import { DataRiskHeaderNav } from '../components/layout/DataRiskHeaderNav'
import { useStyles } from '../components/layout/styles'
import { useScrollToTopOnLoad } from 'utils/hooks/useScrollToTopOnLoad'
import { showClipboardToast } from 'pages/insights/utils'
import { relativePath } from 'utils/routing/relativePath'

type Props = {}

export const UnutilizedDataContainer: React.FC<Props> = () => {
  const location = useLocation()
  const classes = useStyles()

  useScrollToTopOnLoad()

  const breadcrumbs = [
    { title: 'Reports', link: RoutesManager.insights.root.getURL() },
    { title: 'Data Risks', link: RoutesManager.insights.dataRisks.root.getURL() },
    { title: 'Unutilized Data', link: RoutesManager.insights.dataRisks.unutilizedData.root.getURL() },
  ]

  return (
    <ViewLayoutLeftMenu breadcrumbs={breadcrumbs}>
      <ViewLayoutHeaderLeftMenu
        title="Unutilized Data"
        titleComponent={<DataRiskHeaderNav />}
        details="Data assets that contain personal or sensitive data which are not actively used."
        detailsClassName={classes.subtitle}
        backTo={RoutesManager.insights.dataRisks.root.getURL()}
        icon={<UnutilizedDataIcon />}
        actions={
          <Button color="tertiary" size="large" clipboardText={window.location.href} onClick={showClipboardToast}>
            Share
          </Button>
        }
      />
      <Box display="flex" gap={1.5}>
        <UnutilizedDataMenu unutilizedDataAssets={assets} />

        <Routes location={location}>
          <Route
            path={relativePath(
              RoutesManager.insights.dataRisks.unutilizedData.view.root.path,
              RoutesManager.insights.dataRisks.unutilizedData.root.path,
            )}
            element={<UnutilizedDataDetails unutilizedDataAssets={assets} />}
          />
          <Route
            path={relativePath(
              RoutesManager.insights.dataRisks.unutilizedData.root.path,
              RoutesManager.insights.dataRisks.unutilizedData.root.path,
            )}
            element={
              <DetailsEmptyState
                title="Select a data asset"
                description="Check the panel on the left to look up and select the data set to see the insights."
                icon={<EmptyStateDatabaseIcon />}
              />
            }
          />
        </Routes>
      </Box>
    </ViewLayoutLeftMenu>
  )
}
