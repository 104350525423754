import { Button, Icon } from '@ketch-com/deck'
import { Tooltip, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { REFER_A_FRIEND_LINK } from 'utils/constants/referAFriend'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
/**
 * Refer a friend header button
 */
export const HeaderReferAFriendLink: React.FC = () => {
  const { isOrgOnFreePlan } = useContext(PlanAndBillingContext)
  const handleButtonClick = () => {
    window.open(REFER_A_FRIEND_LINK, '_blank', 'noreferrer')
  }
  if (isOrgOnFreePlan) return null
  return (
    <Tooltip title={<Typography variant="label">Refer a Friend!</Typography>}>
      <span>
        <Button
          id="settings-button"
          variant={'iconCustomRounded'}
          color={'white'}
          onClick={handleButtonClick}
          sx={{
            width: '28px',
            height: '28px',
            minWidth: '28px',
          }}
        >
          <Icon name="OGift" />
        </Button>
      </span>
    </Tooltip>
  )
}
