import { ContextVariableDTO } from '@ketch-com/figurehead'
import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep, ITaskActivityParams } from 'pages/orchestration/workflows/edit/interfaces'
import { getStepsToStartCount } from 'pages/orchestration/workflows/edit/utils/steps'

export const getOutcomeVariablesAvailableOnUpstreamTaskSteps = ({
  step,
  steps,
  outcomeVariables,
  precedingApiCallTiles,
}: {
  step: ICanvasStep
  steps: ICanvasStep[]
  outcomeVariables: ContextVariableDTO[]
  precedingApiCallTiles: ICanvasStep[]
}) => {
  let viableOutcomeVariablesPayload: ContextVariableDTO[] = []

  // if a decision gateway has preceding api call tiles, then ALL outcome variables are to be made available as options paths.
  if (precedingApiCallTiles?.length > 0) return outcomeVariables
  const currentStepStepsToStart = getStepsToStartCount({ step, steps })

  const upstreamTaskSteps =
    steps?.filter?.(step => {
      const stepCountFromStepToStart = getStepsToStartCount({ step, steps })
      return (
        (step?.activityCode === WorkflowActivityCode.TASK || step?.activity?.code === WorkflowActivityCode.TASK) &&
        stepCountFromStepToStart <= currentStepStepsToStart
      )
    }) || []

  const viableOutcomeVariableCodes =
    upstreamTaskSteps
      ?.map(step => {
        return (step?.activity?.params as ITaskActivityParams)?.outcomeVariables?.map?.(o => o.code)
      })
      ?.flat() || []

  viableOutcomeVariablesPayload = outcomeVariables?.filter?.(o => viableOutcomeVariableCodes?.includes?.(o.code!)) || []

  return viableOutcomeVariablesPayload
}
