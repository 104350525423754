import { Button, ContentGroup, Icon } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { useSaveDataSystemGlobalCustomAttribute } from 'api/dataSystems/mutations/useSaveDataSystemGlobalCustomAttribute'
import { useDataSystemGlobalCustomAttributes } from 'api/dataSystems/queries/useDataSystemGlobalCustomAttributes'
import { useState, useEffect, useCallback } from 'react'
import { useFieldUpdates } from '../hooks/useFieldUpdates'
import { useOptionUpdates } from '../hooks/useOptionUpdate'
import { CustomFieldsState } from '../../types'
import { FieldDeleteData, ConfirmDeleteCustomAttributeModal } from './ConfirmDeleteCustomAttributeModal'
import { CustomFieldBuilder } from './CustomFieldBuilder'
import { initialCustomFieldData } from '../../constants'
import { EditCustomFieldsSectionActions } from './EditCustomFieldsSectionActions'

interface EditCustomFieldsSectionProps {
  handleExitSettings: () => void
}

export const EditCustomFieldsSection: React.FC<EditCustomFieldsSectionProps> = ({ handleExitSettings }) => {
  const [customFields, setCustomFields] = useState<CustomFieldsState[]>([])
  const [fieldDeleteData, setDeleteData] = useState<FieldDeleteData[]>([])
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false)
  const [addFirstOption, setAddFirstOption] = useState<Record<number, boolean>>({})
  const [isSaving, setIsSaving] = useState(false)

  const { mutateAsync: saveDataSystemGlobalCustomAttribute } = useSaveDataSystemGlobalCustomAttribute({
    onSuccess: data => {
      setCustomFields(prev => {
        const newCustomFields = [...prev.filter(field => field.id)]
        data?.data?.configurations?.forEach(configuration => newCustomFields.push(configuration))
        newCustomFields.sort((a, b) => a.index - b.index)
        return newCustomFields
      })
    },
  })

  const handleOptionUpdates = useOptionUpdates(customFields)
  const handleFieldsUpdates = useFieldUpdates()

  const { data: globalCustomAttributes, refetch, isLoading } = useDataSystemGlobalCustomAttributes()

  useEffect(() => {
    if (globalCustomAttributes?.configurations && globalCustomAttributes.configurations.length) {
      const oganizationCustomFields = [...globalCustomAttributes.configurations].sort((a, b) => a.index - b.index)
      setCustomFields(oganizationCustomFields)
    }
  }, [globalCustomAttributes])

  const addCustomField = () => {
    const idx = customFields.length + 1
    setCustomFields(prev => {
      const newCustomFields = [...prev]
      newCustomFields.push({ ...initialCustomFieldData, index: idx })
      return newCustomFields
    })
  }

  const handleAddNewOption = useCallback((index: number) => {
    setAddFirstOption(prev => ({
      ...prev,
      [index]: !prev[index],
    }))
  }, [])

  const handleGlobalCustomAttributeUpdates = async (customFields: CustomFieldsState[]) => {
    await handleOptionUpdates()
    await handleFieldsUpdates(customFields)
    refetch()
  }

  const handleCustomFieldDelete = (index: number, id?: string) => {
    const fieldToDelete = customFields[index - 1]
    if (fieldToDelete.id) {
      setDeleteData(prev => [...prev, { id: fieldToDelete.id, index: fieldToDelete.index, name: fieldToDelete.label }])
    }

    setCustomFields(prev => {
      return prev
        .filter((prevVal, i) => {
          if (id) {
            return prevVal.id !== id
          }
          return i !== index - 1
        })
        .map((field, i) => {
          if (field.index === i + 1) {
            return field
          }

          return {
            ...field,
            index: i + 1,
            isEdited: !!field.id,
          }
        })
    })
  }

  const handleSaveOnClick = () => {
    if (fieldDeleteData.length) {
      setDisplayDeleteModal(true)
    } else {
      handleSave()
    }
  }
  const handleSave = async () => {
    setIsSaving(true)
    setDisplayDeleteModal(false)
    const indexAdjustedFields = [...customFields]
    indexAdjustedFields.forEach((field, idx) => {
      if (field.index === idx + 1) return
      field.index = idx + 1
      if (field.id) {
        field.isEdited = true
      }
    })
    const unsavedFields = indexAdjustedFields.filter(field => !field.id)
    try {
      if (unsavedFields.length) {
        await saveDataSystemGlobalCustomAttribute({ params: { configurations: unsavedFields } })
      }
      await handleGlobalCustomAttributeUpdates(indexAdjustedFields)
    } finally {
      setIsSaving(false)
      handleExitSettings()
    }
  }

  const handleCancel = async () => {
    setDeleteData([])
    await refetch()
    let organizationCustomFields: CustomFieldsState[] = []
    let updateIndices = false
    if (globalCustomAttributes?.configurations) {
      organizationCustomFields = [...globalCustomAttributes.configurations].sort((a, b) => a.index - b.index)
      organizationCustomFields.forEach((field, idx) => {
        if (field.index !== idx + 1) {
          updateIndices = true
          field.index = idx + 1
          field.isEdited = true
        }
      })
    }
    if (updateIndices) {
      await handleGlobalCustomAttributeUpdates(organizationCustomFields)
    } else {
      setCustomFields(organizationCustomFields)
    }
  }

  const pending = isSaving || isLoading

  return (
    <ContentGroup
      title="Custom Fields"
      titleVariant="h3"
      variant="inner-page-header"
      actionRightBlockComponent={
        <EditCustomFieldsSectionActions pending={pending} handleCancel={handleCancel} handleSave={handleSaveOnClick} />
      }
    >
      <Box display="flex" flexDirection="column" gap={4} paddingBottom={2} paddingX={2} overflow="auto">
        {customFields.map((field, idx) => (
          <CustomFieldBuilder
            key={field.id || idx}
            handleDeleteField={handleCustomFieldDelete}
            handleUpdateFields={setCustomFields}
            handleAddFirstOption={handleAddNewOption}
            addOption={addFirstOption[field.index]}
            type={field.type}
            index={field.index}
            id={field.id}
            label={field.label}
            options={field.options}
          />
        ))}
        <Button color="secondary" startIcon={<Icon name="OPlus" />} onClick={addCustomField}>
          Add Field
        </Button>
      </Box>
      {displayDeleteModal && (
        <ConfirmDeleteCustomAttributeModal
          handleOnClose={() => {
            setDisplayDeleteModal(false)
          }}
          handleSave={handleSave}
          fieldData={fieldDeleteData}
        />
      )}
    </ContentGroup>
  )
}
