import { FC } from 'react'
import { Button, PopUp } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { Starter } from 'components/planAndBilling/components/planDetails/Starter'
import { Plus } from 'components/planAndBilling/components/planDetails/Plus'
import { Pro } from 'components/planAndBilling/components/planDetails/Pro'

type PlanDetailsModalProps = {
  isOrgOnFreePlan: boolean
  isOrgOnStarterPlan: boolean
  isOpen: boolean
  onClose: () => void
}

export const PlanDetailsModal: FC<PlanDetailsModalProps> = ({
  isOrgOnFreePlan,
  isOrgOnStarterPlan,
  isOpen,
  onClose,
}) => {
  return (
    <PopUp
      title="Select Plan to Upgrade"
      subTitle="Choose plan that fits your needs best."
      variant="modal"
      isOpen={isOpen}
      onClose={onClose}
      popUpWidth="658px"
      popUpActionChildren={
        <>
          <Button color="tertiary" size="large" onClick={onClose}>
            Close
          </Button>
        </>
      }
    >
      <Box display="flex" gap={2}>
        {isOrgOnFreePlan ? <Starter /> : null}
        <Plus />
        {isOrgOnStarterPlan ? <Pro /> : null}
      </Box>
    </PopUp>
  )
}
