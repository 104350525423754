import { CustomIconName, Icon, theme } from '@ketch-com/deck'
import { Box, Button } from '@mui/material'
import { memo, ReactNode } from 'react'

type TranspondersCreateInfoButtonParams = {
  iconName: CustomIconName
  children: ReactNode
  handleOnClick: () => void
}

export const TranspondersCreateInfoButton: React.FC<TranspondersCreateInfoButtonParams> = memo(
  ({ iconName, children, handleOnClick }) => {
    return (
      <Button
        fullWidth
        sx={{
          backgroundColor: theme.palette.Action.Disabled,
          height: '32px',
          ':hover': {
            backgroundColor: theme.palette.Action.Disabled,
            backgroundImage: `linear-gradient(rgba(7, 26, 36, 0.1), rgba(7, 26, 36, 0.1))`,
          },
        }}
        onClick={handleOnClick}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center" gap={1}>
            <Icon name={iconName} iconColor={theme.palette.primary.main} /> {children}
          </Box>
          <Icon name="OArrowRight" iconColor={theme.palette.primary.main} />
        </Box>
      </Button>
    )
  },
)
