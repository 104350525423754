import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { RightDTO } from 'interfaces/rights/right'
import { APIListRequestParams, APIListResponse } from 'api/common/utils'

type Params = APIListRequestParams<{
  includeIssues?: boolean
  onlyOrgJurisdictionRights?: boolean
}>

type Response = APIListResponse<{ rights?: RightDTO[] }>

export const fetchRights = ({ includeMetadata, includeIssues, start, limit, onlyOrgJurisdictionRights }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/rights`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
          onlyOrgJurisdictionRights,
        },
      },
    }),
  )
