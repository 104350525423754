import { Icon, TextInput } from '@ketch-com/deck'
import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'

interface DeckSearchInputProps {
  value: string
  onClose?: () => void
  onChange?: (value: string) => void
  debounceDelay?: number
}

export const CookieListFilterSearchInput = ({
  value,
  onClose,
  onChange,
  debounceDelay = 1500,
}: DeckSearchInputProps) => {
  const [currentValue, setCurrentValue] = useState<string>('')

  const debounceFn = useMemo(
    () =>
      debounce((v: string) => {
        if (onChange) {
          onChange(v)
        }
      }, debounceDelay),
    [debounceDelay, onChange],
  )
  useEffect(() => {
    setCurrentValue(value)
  }, [value])
  const onChangeHandler = (value: string) => {
    setCurrentValue(value)
    debounceFn(value)
  }

  return (
    <TextInput
      autoFocus
      value={currentValue}
      startIcon={<Icon name="OMag" />}
      endIcon={currentValue && <Icon sx={{ cursor: 'pointer' }} name="OCross" onClick={() => onClose && onClose()} />}
      size="small"
      placeholder="Search..."
      onChange={event => onChangeHandler(event.target.value)}
    />
  )
}
