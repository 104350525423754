import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Avatar, AvatarSize, Icon, TableCell, theme } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { Box, Typography } from '@mui/material'
import { AppsInstancesPurposeDTO } from 'interfaces/apps'
import pluralize from 'pluralize'
import { PermitPropagationCanonicalPurposesRenderer } from './components/PermitPropagationCanonicalPurposesRenderer'
import { CanonicalPurposeDTO } from 'interfaces/purposes/canonicalPurpose'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { excludeFalsy } from 'utils/helpers/excludeFalsy'
import { IssuesCellRenderer } from 'components/renderers/IssuesCellRenderer'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'

export const usePermitPropagationListColumns = ({
  canonicalPurposes,
}: {
  canonicalPurposes: CanonicalPurposeDTO[]
}): GridColDef<AppsInstancesPurposeDTO>[] => [
  {
    align: 'left',
    field: 'issue',
    flex: 1,
    headerAlign: 'left',
    headerName: ' ',
    sortable: false,
    maxWidth: 50,
    renderCell: ({ row: appsInstancesPurpose }: GridRenderCellParams<AppsInstancesPurposeDTO>) => {
      const { purposes } = appsInstancesPurpose
      const isConfigured = Object.values(purposes || {}).some(Boolean)
      return (
        <TableCell>
          <IssuesCellRenderer hasIssues={!isConfigured} entityType={ApplicationEntity.CONSENT_PERMIT_PROPAGATION} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'app',
    flex: 1,
    headerAlign: 'left',
    headerName: 'App',
    sortable: false,
    renderCell: ({ row: appsInstancesPurpose }: GridRenderCellParams<AppsInstancesPurposeDTO>) => {
      const { app, marketplaceEntity } = appsInstancesPurpose
      return (
        <TableCell>
          <Box display="flex" alignItems="center" gap={1}>
            <Avatar
              src={marketplaceEntity.logo.URL}
              alt=""
              isLogo
              size={AvatarSize.large}
              backgroundColor={theme.palette.tertiary.main}
              variant="rounded"
            />
            <Typography variant="label">{app.name}</Typography>
          </Box>
        </TableCell>
      )
    },
  },

  {
    align: 'left',
    field: 'connections',
    flex: 0.6,
    headerAlign: 'left',
    headerName: 'Connections',
    sortable: false,
    renderCell: ({ row: { appInstances } }: GridRenderCellParams<AppsInstancesPurposeDTO>) => {
      const hasCredentialsIssue = appInstances.some(app => app.hasCredentialsIssue)

      return (
        <TableCell>
          <Box display="flex" alignItems="center">
            {appInstances.length ? (
              hasCredentialsIssue ? (
                <Icon name={'OConnectionIssue'} iconColor={theme.palette.chileanFire.main} />
              ) : (
                <Icon name={'OConnectionTrue'} iconColor={theme.palette.greenHaze.main} />
              )
            ) : (
              <Icon name={'OConnectionFalse'} iconColor={theme.palette.darkDusk.main} />
            )}
            <Typography
              variant="smallLabel"
              color={
                hasCredentialsIssue ? 'chileanFire.main' : !appInstances.length ? 'darkGrey.main' : 'greenHaze.main'
              }
              pl={0.5}
            >
              {appInstances.length ?? 0} {pluralize('Connection', appInstances.length)}
            </Typography>
          </Box>
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'canonicalPurposes',
    flex: 0.6,
    headerAlign: 'left',
    headerName: 'Canonical Purposes',
    sortable: false,
    renderCell: ({ row: appsInstancesPurpose }: GridRenderCellParams<AppsInstancesPurposeDTO>) => {
      return (
        <TableCell>
          <PermitPropagationCanonicalPurposesRenderer
            appsInstancesPurpose={appsInstancesPurpose}
            canonicalPurposes={canonicalPurposes}
          />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'lastUpdated',
    flex: 0.8,
    headerAlign: 'left',
    headerName: 'Last Updated',
    sortable: false,

    renderCell: ({ row: { appInstances } }: GridRenderCellParams<AppsInstancesPurposeDTO>) => {
      const dates = appInstances.map(({ updatedAt }) => updatedAt).filter(excludeFalsy)

      return (
        <TableCell display="flex" alignItems="flex-end">
          {dates.length ? <FormattedDateRenderer date={Math.max(...dates)} /> : <EmptyValueRenderer />}
        </TableCell>
      )
    },
  },
]
