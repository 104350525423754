import React, { memo, useContext } from 'react'
import { Group } from 'react-konva'

import { CanvasContext } from '../contexts/CanvasContext'
import { ICanvasStep, ICoordinates } from 'pages/orchestration/workflows/edit/interfaces'
import { getAbsoluteCoordinates } from 'pages/orchestration/workflows/edit/utils/positioning/absoluteCoordinates'
import { CanvasImage } from 'pages/orchestration/workflows/edit/konvaEntities/common/CanvasImage'
import * as CanvasConstants from '../utils/constants'
import { WarningIcon } from 'pages/orchestration/workflows/edit/konvaEntities/common/WarningIcon'
import EndFlowSVG from 'assets/icons/workflow/activities/endFlowActivity.svg'
import { WorkflowActivityConnectorCode } from 'interfaces/workflowActivities/workflowActivity'

interface IStartFlowProps {
  gridPosition: ICoordinates
  step: ICanvasStep
  steps: Array<ICanvasStep>
}

export const EndFlow: React.FC<IStartFlowProps> = memo(props => {
  const { step, gridPosition } = props
  const { valid } = step
  const stepPosition = getAbsoluteCoordinates(gridPosition)
  const canvasContext = useContext(CanvasContext)
  const isEndFlowV2 = step.workflow?.temporalFunctionName === WorkflowActivityConnectorCode.END
  const iconURL = isEndFlowV2 ? step?.iconURL : EndFlowSVG

  return (
    <Group>
      <Group
        x={stepPosition.x}
        y={stepPosition.y}
        onClick={event => {
          if (canvasContext.handleChangeActiveStep) {
            canvasContext.handleChangeActiveStep({ step })
          }

          event.cancelBubble = true
        }}
      >
        <CanvasImage
          useShadow
          svgURL={iconURL!}
          position={{
            x: CanvasConstants.GRID_IMG_GAP,
            y: CanvasConstants.GRID_IMG_GAP,
          }}
          width={CanvasConstants.GRID_IMG_SIZE}
          height={CanvasConstants.GRID_IMG_SIZE}
        />
        {!valid && <WarningIcon />}
      </Group>
    </Group>
  )
})
