import { Logger } from 'utils/Logger'

export const CacheKeys = {
  orgId: 'ketchOrgId',
  sessionId: 'sessionId',
  userEmail: 'userEmail',
  userId: 'userId',
}

type RetrieveArgs = {
  parseJson?: boolean
  setToIfNull?: unknown
}

export function save(key: string, value: string) {
  return localStorage.setItem(key, value)
}

export function retrieve(key: string, params: RetrieveArgs | undefined = {}) {
  const value = localStorage.getItem(key)
  if (!value && params.setToIfNull) {
    save(key, JSON.stringify(params.setToIfNull))
    return params.setToIfNull
  } else if (!params.parseJson) {
    return value
  } else {
    try {
      return JSON.parse(value || 'null')
    } catch (e) {
      Logger.warn('Read Cache: Parse Json', e)
      if (e instanceof SyntaxError) {
        return undefined
      } else return e
    }
  }
}

export function clear() {
  localStorage.clear()
}

export const saveOrgId = (orgId: string): void => {
  localStorage.setItem(CacheKeys.orgId, orgId)
}
