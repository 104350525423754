import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'

export const PreferenceThemeRequestsTabSubmittedHeaderForm: React.FC = () => {
  return (
    <SidebarFormGroup sx={{ pt: 0, gap: '16px' }}>
      <FormColorInput
        name={themeUpsertFormKeys.preferenceRequestsTabSubmittedHeaderTitleColor}
        label={'Title Color'}
        fullWidth
        required
      />
    </SidebarFormGroup>
  )
}
