import { FormSectionSwitch } from '../FormSectionSwitch'
import { ExperienceBuilderSectionLabels } from '../../../builder/utils/labels'
import { ModalExperienceBuilderSection } from '../../../builder/utils/enums'
import Box from '@mui/material/Box'
import { SidebarFormGroup } from '../SidebarFormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Typography } from '@mui/material'
import { FormTextInput } from '../FormTextInput'
import { FormCheckbox } from '../FormCheckbox'
import { experienceUpsertFormKeys } from '../../../../utils/useExperienceValidationSchema'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { getIn, useFormikContext } from 'formik'
import { theme } from '@ketch-com/deck'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { SyntheticEvent, useContext } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { getFormNameForLocale } from 'pages/consentAndRights/experiences-v2/upsert/utils/utils'
import { defaultExperienceV2DTO } from 'pages/consentAndRights/experiences-v2/upsert/utils/defaults'

export const ModalExperienceListHeaderForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ExperienceV2DTO>()
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length
  const showForm = getIn(values, experienceUpsertFormKeys.modalListHeaderVisible)
  const showCustomTextForm = !getIn(values, experienceUpsertFormKeys.modalListHeaderUseDefaultText)

  const handleDefaultChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked && !isLanguagesLoading) {
      // Reset to english default
      const englishFieldToReset = experienceUpsertFormKeys.modalListHeaderText
      const defaultEnglishValue = getIn(defaultExperienceV2DTO, englishFieldToReset)
      setFieldValue(englishFieldToReset, defaultEnglishValue)

      // Reset to default for each locale
      languages.forEach(({ language: { code } }) => {
        const localeFieldToReset = getFormNameForLocale(englishFieldToReset, code)
        const defaultLocaleValue = getIn(defaultExperienceV2DTO, localeFieldToReset)
        setFieldValue(localeFieldToReset, defaultLocaleValue)
      })
    }
  }

  return (
    <>
      <FormSectionSwitch
        name={experienceUpsertFormKeys.modalListHeaderVisible}
        title={ExperienceBuilderSectionLabels[ModalExperienceBuilderSection.ListHeader]}
      />
      {showForm && (
        <Box p={'16px 18px'}>
          <SidebarFormGroup sx={{ paddingTop: 0 }} hasBorderBottom={showCustomTextForm && hasLanguages}>
            <Box>
              <FormControlLabel
                control={
                  <FormCheckbox
                    name={experienceUpsertFormKeys.modalListHeaderUseDefaultText}
                    afterChange={handleDefaultChange}
                  />
                }
                label={<Typography variant={'label'}>Use Default Text</Typography>}
              />
              <Typography component={'p'} variant={'body'} color={theme.palette.darkDuskFaded.main}>
                The default text “Purposes” has translations for all available languages.
              </Typography>
            </Box>
            {showCustomTextForm && (
              <FormTextInput
                label={'Title Text'}
                name={experienceUpsertFormKeys.modalListHeaderText}
                fullWidth
                charLimit={30}
              />
            )}
          </SidebarFormGroup>
          {showCustomTextForm && hasLanguages && (
            <SidebarFormTranslations
              title={'List Title Text Translations'}
              name={experienceUpsertFormKeys.modalListHeaderText}
              variant={'text'}
              charLimit={30}
            />
          )}
        </Box>
      )}
    </>
  )
}
