import React, { Fragment, memo, useContext } from 'react'
import { Group } from 'react-konva'
import { find, isNil } from 'lodash'

import { ICanvasStep, ICoordinates } from 'pages/orchestration/workflows/edit/interfaces'
import { CANVAS_TRANSITION_CONNECTION_TYPES } from 'pages/orchestration/workflows/edit/interfaces/enums'
import * as CanvasConstants from 'pages/orchestration/workflows/edit/utils/constants'
import { CanvasContext } from 'pages/orchestration/workflows/edit/contexts/CanvasContext'
import { getAbsoluteCoordinates } from 'pages/orchestration/workflows/edit/utils/positioning/absoluteCoordinates'
import { getGridPositionForStep } from 'pages/orchestration/workflows/edit/utils/positioning/getGridPositionForStep'
import { TransitionPlaceholder } from 'pages/orchestration/workflows/edit/konvaEntities/transitions/TransitionPlaceholder'
import { TransitionConnection } from 'pages/orchestration/workflows/edit/konvaEntities/transitions/TransitionConnection'
import { WarningIcon } from 'pages/orchestration/workflows/edit/konvaEntities/common/WarningIcon'
import { CanvasStepImage } from 'pages/orchestration/workflows/edit/konvaEntities/common/CanvasStepImage'
import { ConnectionCircle } from 'pages/orchestration/workflows/edit/konvaEntities/common/ConnectionCircle'
import { CanvasNameText } from 'pages/orchestration/workflows/edit/konvaEntities/common/CanvasNameText'
import { CanvasDescriptionText } from 'pages/orchestration/workflows/edit/konvaEntities/common/CanvasDescriptionText'
import { StepByType } from 'pages/orchestration/workflows/edit/konvaLayers/steps/StepByType'
import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { getJoinTileRenderDetails } from '../utils/steps/getJoinTileRenderDetails'

interface IWorkflowProps {
  gridPosition: ICoordinates
  step: ICanvasStep
  steps: Array<ICanvasStep>
  connectedNestId?: string
  connectedNestStartPosition?: number
}

export const Workflow: React.FC<IWorkflowProps> = memo(
  ({ gridPosition, step, steps, connectedNestId, connectedNestStartPosition }) => {
    const { name = '', description = '', valid, workflow } = step
    const canvasContext = useContext(CanvasContext)

    const connectedStep = find(steps, { ID: workflow?.transition })
    const hasTransition = !isNil(workflow?.transition) && !isNil(connectedStep)

    const nextStepGridPosition = getGridPositionForStep({
      parentGridPosition: gridPosition,
    })
    const stepAbsolutePosition = getAbsoluteCoordinates(gridPosition)

    const nextStepIsJoinGateway = connectedStep?.gateway?.mode === WorkflowActivityGatewayMode.JOIN
    const joinStepDetails = nextStepIsJoinGateway
      ? getJoinTileRenderDetails({
          steps,
          joinStep: connectedStep,
          gridPosition,
          previousStep: step,
          connectedNestStartPosition,
        })
      : undefined

    return (
      <Group>
        <Group
          x={stepAbsolutePosition.x}
          y={stepAbsolutePosition.y}
          width={CanvasConstants.GRID_CELL_WIDTH}
          height={CanvasConstants.GRID_CELL_HEIGHT}
          onClick={event => {
            if (canvasContext.handleChangeActiveStep) {
              canvasContext.handleChangeActiveStep({ step })
            }

            event.cancelBubble = true
          }}
        >
          <CanvasStepImage
            useShadow
            step={step}
            position={{
              x: CanvasConstants.GRID_IMG_GAP,
              y: CanvasConstants.GRID_IMG_GAP,
            }}
          />

          <CanvasNameText text={name} />

          <CanvasDescriptionText text={description} />

          {!valid && <WarningIcon />}

          <ConnectionCircle />
        </Group>

        <TransitionConnection
          type={hasTransition ? CANVAS_TRANSITION_CONNECTION_TYPES.SOLID : CANVAS_TRANSITION_CONNECTION_TYPES.DASHED}
          step={step}
          nextStep={connectedStep}
          gridPositionStart={gridPosition}
          gridPositionEnd={nextStepGridPosition}
          joinStepDetails={joinStepDetails}
          connectedNestId={connectedNestId}
        />

        {!hasTransition ? (
          <TransitionPlaceholder
            gridPosition={nextStepGridPosition}
            step={step}
            steps={steps}
            transitionIndex={0}
            connectedNestStartPosition={connectedNestStartPosition}
          />
        ) : (
          <Fragment>
            <StepByType
              steps={steps}
              step={connectedStep!}
              gridPosition={nextStepGridPosition}
              joinStepDetails={joinStepDetails}
              connectedNestId={connectedNestId}
              connectedNestStartPosition={connectedNestStartPosition}
            />
          </Fragment>
        )}
      </Group>
    )
  },
)
