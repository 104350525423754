import * as Yup from 'yup'

import { FileInfoDTO } from 'interfaces/files/fileInfo'

export type EndStepFormValues = {
  finalizeRequestAs: string
  coverLetter: FileInfoDTO[] | undefined
  subjectCommunicationMessage: string | undefined
  reasonForFinalization: string | undefined
}

export const getValidationSchema = ({
  isCoverLetterRequired,
  isMessageRequired,
}: {
  isCoverLetterRequired: boolean
  isMessageRequired: boolean
}) =>
  Yup.object().shape({
    finalizeRequestAs: Yup.string().required('Required'),
    subjectCommunicationMessage: isMessageRequired ? Yup.string().required('Required') : Yup.string(),
    coverLetter: isCoverLetterRequired ? Yup.array().min(1, 'Required').required() : Yup.array(),
    reasonForFinalization: Yup.string(),
  })
