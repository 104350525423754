import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useField, FieldValidator } from 'formik'

import { InlineEdit, InlineEditProps } from '@ketch-com/deck'
import { debounce } from 'lodash'

type Props = Omit<InlineEditProps, 'value' | 'valid' | 'onBlur' | 'name'> & {
  /** Formik field name */
  formPropertyName: string
  /** Display Asterisk as required field */
  required?: boolean
  /** Text to be displayed above Component */
  label?: string
  /** Custom validation method */
  validate?: FieldValidator

  /** Additional onBlur logic */
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void

  shouldOverrideOnChange?: boolean
  shouldUpdateDebounced?: boolean
  debounceWaitTime?: number
}

export const FormInlineEdit: React.FC<Props> = ({
  label,
  formPropertyName,
  validate,
  required = false,
  shouldOverrideOnChange = false,
  shouldUpdateDebounced = false,
  className,
  onChange,
  onBlur,
  debounceWaitTime = 500,
  ...rest
}) => {
  const [textValue, setTextValue] = useState<string>()
  const [field, meta, helpers] = useField({ name: formPropertyName, validate: validate })
  const { error, touched } = meta
  const showError = error && touched

  useEffect(() => {
    setTextValue(field.value)
  }, [field.value])

  const debounceFn = useMemo(
    () =>
      debounce((v: string) => {
        helpers.setValue(v)
        helpers.setTouched(true)
      }, debounceWaitTime),
    [helpers, debounceWaitTime],
  )

  const debounceOnChangeHandler = useMemo(
    () =>
      debounce((e: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e)
      }, debounceWaitTime),
    [onChange, debounceWaitTime],
  )

  const onChangeInner = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (!shouldOverrideOnChange) {
      // Formik onChange
      if (shouldUpdateDebounced) {
        setTextValue(e.target.value)
        debounceFn(e.target.value)
      } else {
        helpers.setValue(e.target.value)
        helpers.setTouched(true)
        field.onChange(e)
      }
    }
    if (onChange) {
      if (shouldUpdateDebounced) {
        debounceOnChangeHandler(e)
      } else {
        onChange(e)
      }
    }
  }

  return (
    <InlineEdit
      {...rest}
      id={formPropertyName}
      required={required}
      validationText={showError ? error : undefined}
      value={shouldUpdateDebounced ? textValue : field.value}
      onChange={onChangeInner}
      onBlur={e => {
        e.preventDefault()
        field.onBlur(e)
        onBlur?.(e)
      }}
    />
  )
}
