import { useContext } from 'react'
import { BuilderContext } from '../../../Builder'
import { RichTextRenderer } from '../../RichTextRenderer'
import { getIn } from 'formik'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { Box, styled } from '@mui/material'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { ProgressiveExperienceType, ProgressiveExperienceConsentLayout } from '@ketch-sdk/ketch-types'

const ProgressiveDescriptionBox = styled(Box)({
  minHeight: '28px',
})

export const ProgressiveDescription: React.FC = () => {
  const { experienceConfig, themeConfig } = useContext(BuilderContext)

  const description = getIn(experienceConfig, experienceUpsertFormKeys.progressiveExperienceDescription)

  // Experience config
  const type = getIn(experienceConfig, experienceUpsertFormKeys.progressiveExperienceType) as ProgressiveExperienceType
  const consentLayout = getIn(
    experienceConfig,
    experienceUpsertFormKeys.progressiveExperienceConsentLayout,
  ) as ProgressiveExperienceConsentLayout

  // Theme config - We are using the banner theme for demo
  const textColor = getIn(themeConfig, themeUpsertFormKeys.bannerDescriptionTextColor)
  const linkColor = getIn(themeConfig, themeUpsertFormKeys.bannerDescriptionLinkColor)
  const underlineLinks = getIn(themeConfig, themeUpsertFormKeys.bannerDescriptionUnderlineLinks)

  const isSwitchButtonLayout =
    type === ProgressiveExperienceType.Consent && consentLayout === ProgressiveExperienceConsentLayout.ToggleSwitch

  return (
    <ProgressiveDescriptionBox padding={isSwitchButtonLayout ? '4px 16px 8px 16px' : '4px 16px 4px 16px'}>
      <RichTextRenderer
        text={description}
        center={false}
        fontSize={14}
        textColor={textColor}
        linkColor={linkColor}
        underlineLinks={underlineLinks}
      />
    </ProgressiveDescriptionBox>
  )
}
