import React from 'react'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { Button, DataGrid, TableCell, Banner, ContentGroup } from '@ketch-com/deck'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'

// utils
import { RoutesManager } from 'utils/routing/routesManager'

// types
import { StackDTO, PurposeDTO } from '@ketch-com/figurehead'
import { LegalBaseDTO } from 'interfaces/legalBases/legalBase'
import Typography from '@mui/material/Typography'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

type Props = {
  isReady: boolean
  legalBases: LegalBaseDTO[]
  policyScopeCode: string
  stack: StackDTO
}

export const StackTable: React.FC<Props> = ({ isReady, legalBases, policyScopeCode, stack }) => {
  const navigate = useNavigate()

  type RowType = PurposeDTO

  const gridColumns = [
    {
      field: 'Purposes',
      headerName: 'Purposes',
      width: 300,
      sortable: false,
      renderCell: ({ row: { name, code } }: GridRenderCellParams<RowType>) => (
        <TableCell>
          <NameAndCodeCellRenderer name={name} code={code} />
        </TableCell>
      ),
    },

    {
      field: 'Description',
      headerName: 'Description',
      flex: 1,
      sortable: false,
      renderCell: ({ row: { description } }: GridRenderCellParams<RowType>) => (
        <TableCell>
          <Typography>{description || 'None'}</Typography>
        </TableCell>
      ),
    },

    {
      field: 'Legal Basis',
      headerName: 'Legal Basis',
      width: 200,
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const purposeLegalBasesAssignment = get(row, 'legalBases')
        const currentScopeLegalBasisCode = get(purposeLegalBasesAssignment, policyScopeCode)
        const legalBasesItem = legalBases.find(({ code }) => code === currentScopeLegalBasisCode)
        return (
          <TableCell>
            <Typography>{legalBasesItem?.name || 'None'}</Typography>
          </TableCell>
        )
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      sortable: false,
      renderCell: ({ row: { code } }: GridRenderCellParams<RowType>) => (
        <Button
          color="tertiary"
          onClick={() => navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code }))}
        >
          View
        </Button>
      ),
    },
  ]

  return (
    <Box>
      <Box mb={3}>
        <Banner
          title="Purposes can be grouped on Experiences to be shown as a stack."
          severity="info"
          action={
            <Button variant="link" onClick={() => navigate(RoutesManager.deployment.purposeStacks.root.getURL())}>
              Try Purpose Stacks
            </Button>
          }
        />
      </Box>

      {!!stack?.groups?.length &&
        stack.groups?.map(group => (
          <Box key={group?.name} mb={5}>
            <ContentGroup
              titleVariant="h3"
              title={group?.name || ''}
              subTitle="These purposes will be rendered together as a group within the Experience Server"
            >
              <DataGrid
                loading={!isReady}
                rowCount={(group?.purposes || []).length}
                rows={group?.purposes || []}
                columns={gridColumns}
                autosizeOnMount
                disableColumnMenu
                disableColumnPinning
                disableColumnReorder
                disableChildrenSorting
                disableColumnResize
                hideFooter
                disableRowSelectionOnClick
                getRowId={({ id }) => id}
                sx={{
                  padding: 0,
                  '& .MuiDataGrid-columnHeader[role="columnheader"]:hover': {
                    backgroundImage: 'unset',
                  },
                }}
                getRowHeight={() => 'auto'}
              />
            </ContentGroup>
          </Box>
        ))}
    </Box>
  )
}
