import { Button, Icon, PopUp, Radio, theme } from '@ketch-com/deck'
import { Box, FormControlLabel, RadioGroup, styled, Tooltip, Typography } from '@mui/material'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useMemo, useState } from 'react'
import { useBulkEnableWebTags } from 'api/webtags/mutations/useBulkEnableWebTags'
import { useBulkDisableWebTags } from 'api/webtags/mutations/useBulkDisableWebTags'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useQueryClient } from 'react-query'
import { WebTagDTO } from 'interfaces/webtags/WebTags'

type Props = {
  tagIds: string[]
  webTags: WebTagDTO[]
  onClose: () => void
}

enum StatusValue {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

const statusLabels = {
  [StatusValue.Enabled]: 'Enabled',
  [StatusValue.Disabled]: 'Disabled',
}

const OptionBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

/**
 * Modal to handle updating the orchestration status (enabled or disabled) for multiple web tags at once
 */
export const BulkUpdateTagStatusModal: React.FC<Props> = ({ tagIds, webTags, onClose }) => {
  const queryClient = useQueryClient()
  const [statusValue, setStatusValue] = useState<StatusValue>(StatusValue.Enabled)

  // Filter all tags for the ones used in this modal
  const tags = useMemo(() => webTags.filter(webTag => tagIds.includes(webTag.ID), [tagIds]), [tagIds, webTags])
  const noPurposeTags = useMemo(
    () =>
      tags
        .filter(tag => !tag?.advancedConsentModeEnabled && (!tag?.purposes || !tag.purposes.length))
        .map(tag => <li style={{ marginLeft: '8px', fontWeight: 600 }}>{tag.name}</li>),
    [tags],
  )
  const advancedConsentModeTags = useMemo(
    () =>
      tags
        .filter(tag => tag.advancedConsentModeEnabled)
        .map(tag => <li style={{ marginLeft: '8px', fontWeight: 600 }}>{tag.name}</li>),
    [tags],
  )
  // Should disable save because tags without purposes mapped are selected and action is enable
  const disableDueToNoPurposes = !!noPurposeTags.length && statusValue === StatusValue.Enabled
  // Should disable save because tags with Built-in consent checks enabled are selected and action is disable
  const disableDueToAdvConsent = !!advancedConsentModeTags.length && statusValue === StatusValue.Disabled
  const disableSave = disableDueToNoPurposes || disableDueToAdvConsent
  // What to show in the disabled tooltip
  const disableSaveMessage = (
    <>
      {`The selected tags cannot be ${
        statusValue === StatusValue.Enabled ? 'enabled' : 'disabled'
      } because the following tags have ${
        disableDueToNoPurposes ? 'no purposes mapped' : 'built-in consent checks enabled'
      }:`}
      {disableDueToNoPurposes ? noPurposeTags : advancedConsentModeTags}
    </>
  )

  const { mutateAsync: bulkEnableTags, isLoading: isBulkEnabling } = useBulkEnableWebTags({
    onSuccess: () => {
      showToast({ content: 'Tags enabled', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Tag enable failed', type: 'error' })
    },
  })

  const { mutateAsync: bulkDisableTags, isLoading: isBulkDisabling } = useBulkDisableWebTags({
    onSuccess: () => {
      showToast({ content: 'Tags disabled', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Tag disable failed', type: 'error' })
    },
  })

  const isLoading = isBulkEnabling || isBulkDisabling

  // Update the status
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusValue(e.target.value as StatusValue)
  }

  // Format request body and make update status call, then exit
  const handleSave = async () => {
    const bulkFunction = statusValue === StatusValue.Enabled ? bulkEnableTags : bulkDisableTags
    const tags = webTags
      .filter(tag => tagIds.includes(tag.ID))
      .map(tag => ({ ID: tag.ID, appCode: tag.appCode, appInstanceID: tag.appInstanceID }))
    await bulkFunction({ params: { formData: { tags } } })

    // Refresh tags list and close
    await queryClient.invalidateQueries(ApiQueryKeys.webTagsPaginated)
    onClose()
  }

  return (
    <PopUp
      title="Change Status"
      popUpWidth="560px"
      variant="modal"
      onClose={onClose}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Tooltip title={disableSave ? disableSaveMessage : ''} placement="bottom">
            <span>
              <Button color="primary" size="large" pending={isLoading} onClick={handleSave} disabled={disableSave}>
                Save
              </Button>
            </span>
          </Tooltip>
        </>
      }
    >
      <Box display={'flex'} flexDirection={'column'} gap="4px" mb="24px">
        <Box display={'flex'} alignItems="center">
          <Typography variant="label">Set Orchestration Status As</Typography>
          <Tooltip
            title={
              <Box>
                <Typography variant="label">
                  Tags must have assigned purposes or use built-in consent checks to be enabled.{' '}
                </Typography>
                <Typography variant="body">Otherwise, they will remain disabled.</Typography>
              </Box>
            }
          >
            <span>
              <Button
                variant="iconCustomRounded"
                size="small"
                sx={{ width: '24px !important', height: '24px', minWidth: '24px', ':hover': { background: 'none' } }}
              >
                <Icon name="FUnknown" iconColor={theme.palette.Black.o48} width={16} height={16} />
              </Button>
            </span>
          </Tooltip>
        </Box>
        <RadioGroup value={statusValue} onChange={handleChange} row>
          {Object.values(StatusValue).map((status, index) => (
            <OptionBox key={status}>
              <FormControlLabel
                value={status}
                control={<Radio />}
                label={<Typography>{statusLabels[status]}</Typography>}
                sx={{ mr: index === 0 ? '96px' : 0, ml: '0px' }}
              />
            </OptionBox>
          ))}
        </RadioGroup>
      </Box>
    </PopUp>
  )
}
