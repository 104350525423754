import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { TableCell } from '@ketch-com/deck'

type ViewContextObjectType = {
  label: string
  value: JSX.Element
}

export const viewContextVariablesModalColumns: GridColDef<ViewContextObjectType>[] = [
  {
    align: 'left',
    field: 'labels',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Label',
    sortable: false,
    renderCell: ({ row: { label } }: GridRenderCellParams<ViewContextObjectType>) => {
      return <TableCell title={label} />
    },
  },
  {
    align: 'left',
    field: 'values',
    headerAlign: 'left',
    headerName: 'Value',
    flex: 1,
    sortable: false,
    renderCell: ({ row: { value } }: GridRenderCellParams<ViewContextObjectType>) => {
      return <TableCell>{value}</TableCell>
    },
  },
]
