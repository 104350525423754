import React from 'react'
import { useFormikContext } from 'formik'

// api
import { useDataSubjectTypes } from 'api/dataSubjectTypes/queries/useDataSubjectTypes'

// components
import Box from '@mui/material/Box'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { FormRadioGroup } from 'components/ui-kit/form/radioGroup/FormRadioGroup'
import Grid from '@mui/material/Grid'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { Text } from 'components/ui-kit/typography/Text'
import { WorkflowOptionsCellRenderer } from 'pages/orchestration/events/listV2/components/WorkflowOptionsCellRenderer'

// Types
import { RightDTO } from 'interfaces/rights/right'
import { DATA_ROLE_OPTIONS, DataRoleType } from 'interfaces/events'
import { EventV2DTO } from '@ketch-com/figurehead'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { groupDataSubjectTypeCodesByRightCode } from './utils'

type Props = {
  rights: RightDTO[]
  events: EventV2DTO[]
}

export const CreateEventForm: React.FC<Props> = ({ rights, events }) => {
  const { values, isSubmitting, setFieldValue } = useFormikContext<EventV2DTO>()
  const { data: dataSubjectTypes } = useDataSubjectTypes()

  const groupedDataSubjectTypeCodesByRightCode = groupDataSubjectTypeCodesByRightCode(events)

  const dataSubjectTypeCount = dataSubjectTypes.length

  const dataSubjectOptions = dataSubjectTypes
    // filter out any data subject types that are already assigned to an event for the selected rightCode
    .filter(dataSubjectType => {
      if (!values.rightCode) return true
      return !groupedDataSubjectTypeCodesByRightCode[values.rightCode]?.includes(dataSubjectType?.code || '')
    })
    .map(dataSubjectType => {
      return {
        id: dataSubjectType?.code,
        name: dataSubjectType?.name,
        code: dataSubjectType?.code,
      }
    })

  return (
    <Box>
      <FormSection>
        <FormRow>
          <Grid container spacing={4}>
            <Grid item xs={10}>
              <FormRadioGroup
                name="dataRole"
                label="Data Role"
                layout="row"
                valueKey="id"
                renderLabel={({ name }) => name}
                options={DATA_ROLE_OPTIONS}
              />
            </Grid>
          </Grid>
        </FormRow>

        <FormRow>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <FormDropdown
                fullWidth
                label="Right"
                placeholder="Select Right"
                name="rightCode"
                onChange={() => {
                  // clear data subject type when right is changed to prevent invalid data
                  setFieldValue('dataSubjectTypeCode', '')
                }}
                valueKey="code"
                disabled={isSubmitting || !rights.length}
                items={rights.filter(right => {
                  // filter out any rights that already have all data subject types assigned to an event
                  const dataSubjectTypeCodes = groupedDataSubjectTypeCodesByRightCode[right.code]
                  return !(dataSubjectTypeCodes?.length === dataSubjectTypeCount)
                })}
              />
            </Grid>
            <Grid item xs={4}>
              {values.dataRole !== DataRoleType.DATA_ROLE_PROCESSOR && (
                <FormDropdown
                  fullWidth
                  label="Subject Type"
                  placeholder="Select Subject Type"
                  name="dataSubjectTypeCode"
                  valueKey="id"
                  disabled={isSubmitting || !dataSubjectOptions.length}
                  items={dataSubjectOptions}
                  renderItem={({ name, code }) => (
                    <NameAndCodeCellRenderer name={name} code={code} shouldCapitalizeName />
                  )}
                />
              )}
            </Grid>
          </Grid>
        </FormRow>

        <FormRow>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Box display="flex" alignItems="center">
                <Label>Workflow Assignment</Label>
                <Text color="lightGrey" size={14} weight={600}>
                  &nbsp; (Optional)
                </Text>
              </Box>
              <WorkflowOptionsCellRenderer hasMaxWidth={true} canonicalRightCode={values.canonicalRightCode} />
            </Grid>
          </Grid>
        </FormRow>
      </FormSection>
    </Box>
  )
}
