import React from 'react'
import Box from '@mui/material/Box'
import { FormTemplateSectionDTO } from '@ketch-com/figurehead'

import Typography from '@mui/material/Typography'
import { FormFieldPreviewGrid } from 'pages/consentAndRights/forms/templates/upsert/components/TemplateSections/components/DraggableFormTemplateSectionItem/components'
import { SectionDescription } from '../../components'

type Props = {
  formTemplateSection: FormTemplateSectionDTO
}

export const FormTemplateSection: React.FC<Props> = ({ formTemplateSection }) => {
  const isDescriptionEmpty =
    !formTemplateSection?.description?.length || formTemplateSection.description === '<p><br></p>'
  return (
    <Box display="flex" mb={4}>
      <Box display="flex" alignItems="center" flex={1}>
        <Box display="flex" flex={1} width="100%" flexDirection="column">
          {/* Section Title */}
          {formTemplateSection?.title && (
            <Typography variant="h4" color="darkDusk.main" mb={!isDescriptionEmpty ? 0.5 : 2.5}>
              {formTemplateSection?.title}
            </Typography>
          )}
          {/* Section Description */}

          {!isDescriptionEmpty && <SectionDescription description={formTemplateSection?.description} />}
          {/* Section Form Fields */}
          <FormFieldPreviewGrid formFields={formTemplateSection?.formFields} />
        </Box>
      </Box>
    </Box>
  )
}
