import { AssetPolicyDTO } from '@ketch-com/figurehead'

const getRandomEntryFromArray = (array: number[]) => {
  return array[Math.floor(Math.random() * array.length)]
}

export const getSerializedPolicy = (policy: any): AssetPolicyDTO => {
  return {
    ...policy,
    status: getRandomEntryFromArray([0, 1, 2, 3]),
    assetsAffected: Math.floor(Math.random() * (1000 - 10 + 1)) + 10, // random num between 10 and 1000
    category: getRandomEntryFromArray([0, 1, 2, 3]),
  }
}
