import { Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'

import { FILTER_STATES } from '../constants'
import { getDimensions, getPadding } from '../utils'

type UseStylesProps = {
  filterState: FILTER_STATES
}

export const useMegaFilterStyles = makeStyles<Theme, UseStylesProps>(
  ({ palette, spacing }) =>
    createStyles({
      container: {
        position: 'sticky',
        transition: 'all 0.3s ease-in-out',
        width: ({ filterState }) => getDimensions(filterState).width,
        maxWidth: ({ filterState }) => getDimensions(filterState).width,
        // minHeight: ({ filterState }) => getDimensions(filterState).height,
        background: palette.white.main,
        borderRadius: spacing(2),
        padding: ({ filterState }) => getPadding(filterState),
        marginRight: spacing(2),
      },
      openButton: {
        height: 32,
        width: 32,
        borderRadius: '50%',
        backgroundColor: ({ filterState }) =>
          filterState === FILTER_STATES.OPEN ? palette.white.main : palette.bone.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.3s ease-in-out',
        transform: ({ filterState }) => (filterState === FILTER_STATES.OPEN ? 'rotate(0deg)' : 'rotate(180deg)'),
        border: ({ filterState }) => (filterState === FILTER_STATES.OPEN ? '' : 'rotate(180deg)'),
        boxShadow: ({ filterState }) =>
          filterState === FILTER_STATES.OPEN
            ? '0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.2)'
            : 'none',
      },
      filterOptionsContainer: {
        backgroundColor: palette.white.main,
        paddingLeft: spacing(2),
        paddingTop: spacing(2.5),
      },

      filterOptionNameCell: {
        paddingLeft: 0,
      },

      filterTableContainer: {
        transition: 'all 0.3s ease-in-out',
        display: ({ filterState }) => (filterState === FILTER_STATES.OPEN ? 'block' : 'none'),
      },
      typeCheckboxContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: spacing(2),
      },

      /* SELECTING styles */
      bulkSelectActionText: {
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        },
      },

      bulkActionButtons: {
        marginBottom: spacing(2),
      },
    }),
  { name: 'AssetsListView' },
)
