import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormMode } from 'interfaces/formModes/formMode'
import { ProcessingActivityDTO } from '@ketch-com/figurehead'

export const ProcessingActivityOptionsButton = ({
  disabled,
  processingActivity,
  handleDelete,
}: {
  disabled: boolean
  processingActivity: ProcessingActivityDTO
  handleDelete: () => void
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button size="large" onClick={handleClickListItem} disabled={disabled} variant="icon" color="tertiary">
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
        <ActionSheetItem
          onClick={() => {
            handleClose()
            navigate(
              RoutesManager.policyCenter.processingActivities.upsert.getURL({
                id: processingActivity.id,
                formMode: FormMode.EDIT,
              }),
            )
          }}
        >
          Edit
        </ActionSheetItem>

        <ActionSheetItem divider />

        <ActionSheetItem
          onClick={() => {
            handleClose()
            handleDelete()
          }}
        >
          Delete
        </ActionSheetItem>
      </ActionSheetWrapper>
    </>
  )
}
