import React from 'react'
import { Status, StatusState, StatusVariant } from '@ketch-com/deck'
import { StatusVariantV3Enum } from 'components/ui-kit/status/interfaces'

export const getStatusVariant = (variant: StatusVariantV3Enum): StatusState => {
  switch (variant) {
    case StatusVariantV3Enum.draft:
      return StatusState.finished
    case StatusVariantV3Enum.new:
      return StatusState.new
    case StatusVariantV3Enum.inProgress:
      return StatusState.warning
    case StatusVariantV3Enum.activeEnabled:
      return StatusState.success
    case StatusVariantV3Enum.errorCanceled:
      return StatusState.error
    case StatusVariantV3Enum.finishedDone:
      return StatusState.inProgress
    case StatusVariantV3Enum.inactiveArchive:
      return StatusState.draft
    default:
      return StatusState.finished
  }
}

export const WorkflowExecutionStepStatusRendererV2: React.FC<{
  variant: StatusVariantV3Enum
  statusText: string
}> = ({ variant, statusText }) => {
  return <Status label={statusText} status={getStatusVariant(variant)} variant={StatusVariant.ghost} icon="Dot" />
}
