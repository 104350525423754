import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { ProcessingActivityDataSystemProcessingStageDTO, ProcessingActivityTypeDTO } from '@ketch-com/figurehead'
import { ProcessingActivityContext } from '../../context/ProcessingActivityContext'
import Typography from '@mui/material/Typography'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { TabSegmented, TabSegmentedGroup } from '@ketch-com/deck'

const segmentsTabOptions = [
  {
    id: ProcessingActivityDataSystemProcessingStageDTO.UnspecifiedProcessingActivityDataSystemProcessingStage,
    name: 'All',
  },
  {
    id: ProcessingActivityDataSystemProcessingStageDTO.InputProcessingActivityDataSystemProcessingStage,
    name: 'Inputs',
  },
  {
    id: ProcessingActivityDataSystemProcessingStageDTO.OutputProcessingActivityDataSystemProcessingStage,
    name: 'Outputs',
  },
]

const processingSegmentTab = {
  id: ProcessingActivityDataSystemProcessingStageDTO.ProcessProcessingActivityDataSystemProcessingStage,
  name: 'Processors',
}

export const DataSourcesFilters: React.FC = () => {
  const { processingActivity, searchString, setSearchString, activityStage, setActivityStage } =
    useContext(ProcessingActivityContext)
  const isModelType = processingActivity.type === ProcessingActivityTypeDTO.ModelProcessingActivityType

  const segmentButtons =
    processingActivity.type === ProcessingActivityTypeDTO.ModelProcessingActivityType
      ? segmentsTabOptions
      : [...segmentsTabOptions, processingSegmentTab]

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <SearchTextInput
        size="small"
        placeholder="Search"
        onChange={nextSearchString => setSearchString(nextSearchString)}
        onClear={() => setSearchString('')}
        value={searchString}
        showClearButton
      />

      {processingActivity?.dataSystems?.length && (
        <Typography variant="label">{processingActivity?.dataSystems?.length} total</Typography>
      )}

      {isModelType && (
        <Box ml="auto">
          <TabSegmentedGroup
            size="small"
            value={activityStage}
            exclusive
            onChange={(_, value) => {
              setActivityStage(value)
            }}
          >
            {segmentButtons.map(segmentButton => {
              return (
                <TabSegmented value={segmentButton.id} key={segmentButton.id}>
                  {segmentButton.name}
                </TabSegmented>
              )
            })}
          </TabSegmentedGroup>
        </Box>
      )}
    </Box>
  )
}
