import { ExperienceActionButtonDestination } from 'interfaces/experiences/experienceActionButtonDestination'
import { ExperienceBannerPrimaryButtonAction } from 'interfaces/experiences/experienceBannerPrimaryButtonAction'
import { ExperienceSwitchRenderLogic } from 'interfaces/experiences/experienceSwitchRenderLogic'
import { deserialize } from '../../common'

export const getDefaultValues = ({ organizationName }: { organizationName: string }) => ({
  banner: {
    title: 'Your Privacy',
    footerDescription: deserialize(
      `Welcome to ${organizationName}! We’re glad you’re here and want you to know that we respect your privacy and your right to control how we collect, use, and share your personal data. Please read our [Privacy Policy](privacyPolicy) to learn about our privacy practices.`,
    ),
    buttonText: 'I understand',
    secondaryButtonText: '',
    secondaryButtonDestination: ExperienceActionButtonDestination.PREFERENCES,
    primaryButtonAction: ExperienceBannerPrimaryButtonAction.SAVE_CURRENT_STATE,
    showCloseIcon: true,
    extensions: {},
  },
  modal: {
    title: 'Your Privacy',
    bodyTitle: '',
    bodyDescription: deserialize(
      `Welcome to ${organizationName}! We’re glad you're here and want you to know that we respect your privacy and your right to control how we collect, use, and share your personal data. Listed below are the purposes for which we process your data--please indicate whether you consent to such processing. For more information on our privacy practices, including legal bases and our use of tracking technologies like cookies, please read our [Privacy Policy](privacyPolicy).`,
    ),
    buttonText: 'Save choices',
    showCloseIcon: true,
    consentTitle: '',
    hideConsentTitle: false,
    hideLegalBases: false,
    switchOnText: '',
    switchOffText: '',
    switchTextRenderLogic: ExperienceSwitchRenderLogic.RENDER_NEVER,
    extensions: {},
  },
  jit: {
    title: 'Your Privacy',
    bodyDescription: deserialize(
      'Please indicate whether you consent to our collection and use of your data in order to perform the operation(s) you’ve requested.',
    ),
    moreInfoText: '',
    moreInfoDestination: ExperienceActionButtonDestination.MODAL,
    declineButtonText: 'Cancel',
    acceptButtonText: 'Save choices',
    extensions: {},
    showCloseIcon: true,
  },
})
