import React from 'react'
import { Box } from '@mui/material'

import { Text } from 'components/ui-kit/typography/Text'
import { Spinner } from 'components/ui-kit/spinner/Spinner'

type Props = {
  isFetchingNextPage?: boolean
}

export const InfiniteScrollLoadingIndicator: React.FC<Props> = ({ isFetchingNextPage }) => {
  if (!isFetchingNextPage) return null
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      mb={2}
      py={1}
      borderRadius={1}
      bgcolor="fadedGrey.main"
    >
      <Box mr={3}>
        <Spinner size={18} />
      </Box>
      <Text size={11} weight={500} color="grey">
        Loading...
      </Text>
    </Box>
  )
}
