import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { RegisterDataDTO } from 'interfaces/auth/authData'

type Params = {
  formData: {
    email: string
    orgCode: string
    orgName: string
    plan: string
    recaptchaToken: string
    fullName: string
    website: string
    privacyPolicy: string
    hubspotutk: string
    marketingConsent: boolean
  }
}

export const register = ({ formData }: Params) =>
  API.post<RegisterDataDTO>(
    formatRequestString({
      entityUrl: '/api/provision/new-signup',
    }),
    formData,
  )
