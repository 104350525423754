import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { COMMON, RIGHTS_STATISTICS } from 'i18n/types'
import { ObjectLiteral } from 'interfaces'
import Typography from '@mui/material/Typography'
import { ChartTrendPeriod } from 'interfaces/rights/rightStats'
import { useQueryParams } from 'utils/hooks'
import moment from 'moment'

export const ChartTooltipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.darkDusk.main,
  borderRadius: 5,
  padding: '8px 10px',
  minWidth: 175,
}))

type TooltipProps = {
  active?: boolean
  payload: any
  label: string
}

export const RightsTrendQueueFlowChartTooltip: React.FC<TooltipProps> = ({ active, label, payload }) => {
  const theme = useTheme()
  const { t } = useTranslation([RIGHTS_STATISTICS, COMMON])
  const { queries } = useQueryParams<{
    trendPeriod?: ChartTrendPeriod
  }>()

  const labelOverride = !![ChartTrendPeriod.MONTHLY_PAST_CAL_YEAR, ChartTrendPeriod.MONTHLY_PAST_TWELVE].includes(
    queries?.trendPeriod!,
  )
    ? moment(payload?.[0]?.payload?.date).format('MMMM')
    : label

  if (active && payload && payload.length) {
    const invokedValue = payload.find((item: ObjectLiteral) => item.dataKey === 'invoked')?.value || 0
    const fulfilledValue = Math.abs(payload.find((item: ObjectLiteral) => item.dataKey === 'fulfilled')?.value || 0)

    // TODO:BAC - avgFulfillTime and medialFulfillTime have been de-scoped
    // const avgFulfillTime =
    //   (payload.find((item: ObjectLiteral) => item.dataKey === 'fulfilled')?.payload as RightStatsPeriodDataPointDTO)
    //     ?.avgFulfillTime || 0
    // const medialFulfillTime =
    //   (payload.find((item: ObjectLiteral) => item.dataKey === 'fulfilled')?.payload as RightStatsPeriodDataPointDTO)
    //     ?.medialFulfillTime || 0

    return (
      <ChartTooltipContainer>
        <Typography color={theme.palette.white.main} variant="smallBodyAlt" mb={0.25}>
          {/* "label" is Month or Day, depending on trend period in query */}
          {labelOverride}
        </Typography>
        <Typography color={theme.palette.white.main} variant="smallBodyAlt" mb={2}>
          {t('Queue Net Flow')} {invokedValue - fulfilledValue}
        </Typography>

        <Typography color={theme.palette.white.main} variant="smallBodyAlt" mb={0.25}>
          {t('Invoked')}: {invokedValue}
        </Typography>
        <Typography color={theme.palette.white.main} variant="smallBodyAlt">
          {t('Fulfilled')}: {fulfilledValue}
        </Typography>

        {/* TODO:BAC - de-scoped */}

        {/* <Typography color={theme.palette.white.main} variant="smallLabel" mb={0.25}>
          Fulfillment Time
        </Typography>
        <Typography color={theme.palette.white.main} variant="smallBodyAlt" mb={0.25}>
          Average: {avgFulfillTime} {`${pluralize('day', Math.round(avgFulfillTime))}`}
        </Typography>
        <Typography color={theme.palette.white.main} variant="smallBodyAlt">
          Median: {medialFulfillTime} {`${pluralize('day', Math.round(medialFulfillTime))}`}
        </Typography> */}
      </ChartTooltipContainer>
    )
  }

  return null
}
