import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { RoutesManager } from 'utils/routing/routesManager'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Button, DataGrid, TableCell } from '@ketch-com/deck'
import { IdentitySpacesRenderer } from 'pages/settings/identitySpaces/components/IdentitySpaceRenderer'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

type Props = {
  identitySpaces: IdentitySpaceDTO[]
}

export const IdentitySpaceViewMappingTable: React.FC<Props> = ({ identitySpaces }) => {
  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      {
        field: 'identitySpace',
        headerName: 'Identifier',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <NameAndCodeCellRenderer name={params?.row?.name} code={params?.row?.code} />
          </TableCell>
        ),
        flex: 2,
        sortable: false,
      },
      {
        field: 'pushesFrom',
        headerName: 'Pushes To',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <IdentitySpacesRenderer identitySpaceMaps={params?.row?.destinationIdentitySpaceMaps || []} />
          </TableCell>
        ),
        flex: 2,
        sortable: false,
      },
      {
        field: 'created',
        headerName: 'Created',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <FormattedDateRenderer date={params?.row?.metadata?.createdAt} />
          </TableCell>
        ),
        flex: 2,
        sortable: false,
      },
      {
        field: 'action',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <Button
              onClick={() =>
                navigate(RoutesManager.settings.identitySpaces.view.root.getURL({ code: params?.row?.code }))
              }
              color="tertiary"
            >
              View
            </Button>
          </TableCell>
        ),
        width: 100,
        sortable: false,
        renderHeader: () => null,
      },
    ],
    [navigate],
  )

  return (
    <DataGrid
      rows={identitySpaces}
      columns={columns}
      disableColumnMenu
      disableColumnPinning
      disableColumnReorder
      disableChildrenSorting
      isPaginationLoading
      disableRowSelectionOnClick
      disableRowHoverStates
      disableColumnResize
      hideFooter
      getRowId={row => row.ID}
      sx={{
        height: 'auto',
      }}
    />
  )
}
