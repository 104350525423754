import { theme } from '@ketch-com/deck'

type LabelProps = { value?: string | number; x?: string | number; y?: string | number }

export const Label = ({ x = 0, y = 0, value }: LabelProps) => {
  return (
    <g>
      <text
        x={Number(x) + 10}
        y={Number(y) + 27}
        fill={theme.palette.darkDusk.main}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="11px"
      >
        {String(value)?.split(' ')?.[0]}
      </text>
    </g>
  )
}
