import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  labelCode: string
  isV2FeatureFlagEnabled?: boolean
}

export const deleteLabelV2 = ({ labelCode, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/labels/${labelCode}`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/labels/${labelCode}`
  return API.delete<{}>(
    formatRequestString({
      entityUrl,
    }),
  )
}
