import { TableCell } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { ApiKeyFormValues } from 'interfaces/apiKeys'

export type DataGridRowsType = {
  displayName: string
  description: string
  group: string
  options: {
    name: string
    id: string
  }[]
}

export const getApiKeyFormSectionColumns = ({
  values,
  isCurrentUserOrgAdmin,
}: {
  values: ApiKeyFormValues
  isCurrentUserOrgAdmin: boolean
}) => [
  {
    field: 'name',
    headerName: 'Section',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<DataGridRowsType>) => (
      <TableCell title={row.displayName} subTitle={row.description} />
    ),
  },
  {
    field: 'value',
    headerName: 'Value',
    flex: 1,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<DataGridRowsType>) => {
      const { options, displayName, group } = row
      const droplistOptions = values.roles[displayName] === 'none' ? [{ name: 'None', id: 'none' }] : options
      return (
        <TableCell>
          <FormDroplistButton
            required
            disableClearable
            formPropertyName={`roles.${group}`}
            fullWidth
            valueKey="id"
            placeholder="Select Permission"
            disabled={values.roles[displayName] === 'none' || !isCurrentUserOrgAdmin}
            items={droplistOptions}
            labelTooltipText={
              !isCurrentUserOrgAdmin
                ? 'Contact organization admin to change'
                : values.roles[displayName] === 'none'
                ? 'Your subscription plan is not entitled to this'
                : null
            }
          />
        </TableCell>
      )
    },
  },
]
