import React from 'react'
import { useFormikContext } from 'formik'

import { AppDTO, AppFormDTO } from 'interfaces/apps'
import { getFormInput } from '../utils'
import { FormRow } from '@ketch-com/deck'

type Props = {
  app?: AppDTO
  isEditMode: boolean
}

export const AppFormSectionFields: React.FC<Props> = ({ isEditMode /*, app */ }) => {
  const { values } = useFormikContext<AppFormDTO>()

  return (
    <FormRow title="Configuration">
      {values.fields?.map((field, index) => {
        const { editable, showValueOnEdit } = field
        if (!editable && !showValueOnEdit && isEditMode) {
          return null
        }
        if ((field?.conditionForDisplay?.length || 0) > 0) {
          const shouldDisplayArray = (field?.conditionForDisplay || []).map(({ name, value }) => {
            const valueOfInterest = (values?.fields || []).find(v => v.name === name)?.value
            return valueOfInterest === value
          })
          /* if all display conditions are not met, return null */
          if (shouldDisplayArray.some(s => !s)) return null
        }
        return getFormInput({ field, index, isEditMode })
      })}
    </FormRow>
  )
}
