import React from 'react'
import { useSetState } from 'react-use'

import { RightInvocationDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionStepDTO, WorkflowExecutionDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { useAuth } from 'utils/hooks/useAuth'
import { Button } from '@ketch-com/deck'
import { MaybeNull } from 'interfaces'
import { WorkflowExecutionStepCompleteModalContainer } from '../completeStep/WorkflowExecutionStepCompleteModalContainer'
import { WorkflowExecutionStepReassignModalContainer } from '../reassignStep/WorkflowExecutionStepReassignModalContainer'
import { WorkflowExecutionStepViewModalContainer } from '../viewStep/WorkflowExecutionStepViewModalContainer'

type Props = {
  rightInvocation: RightInvocationDTO
  workflowExecutionStep: WorkflowExecutionStepDTO
  isAssigneeRequired: boolean
  workflowExecution: MaybeNull<WorkflowExecutionDTO>
}

export const WorkflowExecutionTaskStepActions: React.FC<Props> = ({
  rightInvocation,
  workflowExecutionStep,
  isAssigneeRequired,
  workflowExecution,
}) => {
  const [{ isCompleteOpen, isReassignOpen, isViewOpen }, setState] = useSetState({
    isCompleteOpen: false,
    isReassignOpen: false,
    isViewOpen: false,
  })

  const { userData } = useAuth()

  const { assignee, status } = workflowExecutionStep

  const isInProgress = status === WorkflowExecutionStepStatus.IN_PROGRESS
  const isAssignedToMe = assignee === userData.userId
  // http://localhost:3000/orchestration/rights-queue/view/2jqL9KSsAZgO8uuZDE83pD/workflow
  if (isInProgress) {
    return (
      <>
        <Button
          color="tertiary"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()

            if (isAssignedToMe || !isAssigneeRequired) {
              setState({ isCompleteOpen: true })
            } else {
              setState({ isReassignOpen: true })
            }
          }}
        >
          View
        </Button>

        {isCompleteOpen && (
          <WorkflowExecutionStepCompleteModalContainer
            rightInvocation={rightInvocation}
            workflowExecutionStep={workflowExecutionStep}
            workflowExecution={workflowExecution}
            onClose={() => {
              setState({ isCompleteOpen: false })
            }}
          />
        )}

        {isReassignOpen && (
          <WorkflowExecutionStepReassignModalContainer
            workflowExecutionStep={workflowExecutionStep}
            onClose={() => {
              setState({ isReassignOpen: false })
            }}
          />
        )}
      </>
    )
  }

  return (
    <>
      <Button
        color="tertiary"
        onClick={() => {
          setState({ isViewOpen: true })
        }}
      >
        View
      </Button>

      {isViewOpen && (
        <WorkflowExecutionStepViewModalContainer
          rightInvocation={rightInvocation}
          workflowExecution={workflowExecution}
          workflowExecutionStep={workflowExecutionStep}
          onClose={() => {
            setState({ isViewOpen: false })
          }}
        />
      )}
    </>
  )
}
