import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormTemplateDTO } from '@ketch-com/figurehead'
import { FormTemplateUsedInExperienceTable } from '.'
import { Button, PopUp } from '@ketch-com/deck'

type Props = {
  onCancel: () => void
  onConfirm: () => void
  formTemplate?: FormTemplateDTO
  isSubmitting?: boolean
}

export const FormTemplateSaveChangesModal: React.FC<Props> = ({ onCancel, formTemplate, onConfirm, isSubmitting }) => {
  return (
    <PopUp
      variant="dialog"
      title="Save Changes"
      popUpWidth="550px"
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isSubmitting} onClick={onCancel}>
            Cancel
          </Button>
          <Button color="primary" size="large" pending={isSubmitting} onClick={onConfirm}>
            Save
          </Button>
        </>
      }
      onClose={onCancel}
    >
      <Typography variant="body" mb={3} component={Box}>
        This form is used in currently deployed experiences. Any changes will take effect immediately, are you sure you
        want to save?
      </Typography>

      <FormTemplateUsedInExperienceTable formTemplate={formTemplate} />
    </PopUp>
  )
}
