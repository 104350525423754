import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'

type GetFilterOptionsArgs = { options: ManageLabelModalLabel[]; newLabel: string; labels: ManageLabelModalLabel[] }

type SearchFilterArgs = { element: ManageLabelModalLabel; newLabel: string }

type FilterFunctionArgs = {
  element: ManageLabelModalLabel
  labels: ManageLabelModalLabel[]
}

const searchFilter = ({ element, newLabel }: SearchFilterArgs) => {
  const tempString = Object.values(element).join('').replace(' ', '').toLowerCase()
  return tempString.includes(newLabel)
}

const unusedAllowedTypesFilter = ({ element, labels }: FilterFunctionArgs) => {
  const tempString = Object.values(element).join('').replace(' ', '').toLowerCase()
  const tempArray = labels.map(l => Object.values(l).join('').replace(' ', '').toLowerCase())
  return tempArray.indexOf(tempString) === -1
}

const alreadySelectedFilter = ({ element, labels }: FilterFunctionArgs) => {
  const labelCodesArray = labels.map(l => `${l?.code || ''}${l?.name || ''}${l?.value || ''}`)
  const isValid = labelCodesArray.indexOf(`${element?.code || ''}${element?.name || ''}${element?.value || ''}`) === -1
  return isValid
}

const multipleUseFilter = ({ element, labels }: FilterFunctionArgs) => {
  const labelCodesArray = labels.map(l => l.code)
  const hasAnotherPermutationBeenUsed = labelCodesArray.indexOf(element.code) !== -1
  if (hasAnotherPermutationBeenUsed) return (element?.usageLimit || 0) === 1
  return true
}

export const getFilterOptions = ({ options, newLabel, labels }: GetFilterOptionsArgs): ManageLabelModalLabel[] =>
  options.filter(
    o =>
      searchFilter({ element: o, newLabel }) &&
      unusedAllowedTypesFilter({ element: o, labels }) &&
      alreadySelectedFilter({ element: o, labels }) &&
      multipleUseFilter({ element: o, labels }),
  )
