import { PutProcessingActivityDataFlowRequestBodyDTO } from '@ketch-com/figurehead'
import { API } from '../../../utils/api/api'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

type Params = {
  formData: PutProcessingActivityDataFlowRequestBodyDTO
  activityId: string
}

type Response = {}

export const updateProcessingActivityDataFlow = ({ formData, activityId }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/processing-activities/${activityId}/data-flow`,
    }),
    formData,
  )
