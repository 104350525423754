import { APIListRequestParams } from 'api/common/utils'
import { FindConnectionsV2ResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  q?: string
  installedDataSystemId?: string
  capabilities?: string[]
  connectionStatuses?: string[]
  appInstanceId?: string
  start?: number
  limit?: number
}>

export const fetchConnections = ({
  q,
  installedDataSystemId,
  capabilities,
  connectionStatuses,
  appInstanceId,
  start,
  limit,
}: Params) => {
  return API.get<FindConnectionsV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: '/api/v2/connections',
      params: {
        filters: {
          start,
          limit,
          query: q,
          installedDataSystemId,
          appInstanceId,
          capabilities,
          connectionStatuses,
        },
      },
    }),
  )
}
