import React from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { Banner, Button, ContentGroup, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { BasicDetailsUpdateFormSection } from '../components'
import { RoutesManager } from 'utils/routing/routesManager'
import { CustomRightNameDTO } from '@ketch-com/figurehead'
import { CustomRightNameJurisdictionModal } from '../components'
import { getCustomRightNameFormValues } from '../utils'
import { CustomRightNameSaveAlertModal } from '../components/modals/CustomRightNameSaveAlertModal'
import { FormikProvider, useFormik } from 'formik'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { CustomRightNameUpsertCreate } from './CustomRightNameUpsertCreate'
import { getUpsertBreadCrumbs, validationSchema } from './utils'

type Props = {
  isReady: boolean
  isEditMode: boolean
  customRightName: CustomRightNameDTO
  onSubmitUpdate: (values: CustomRightNameDTO) => Promise<void>
  onSubmitCreate: (values: CustomRightNameDTO) => Promise<void>
  id: string
  setSaveWarningModal: any
  saveWarningModal: any
}

export const CustomRightNameUpsertView: React.FC<Props> = ({
  isReady,
  isEditMode,
  customRightName,
  onSubmitUpdate,
  onSubmitCreate,
  saveWarningModal,
  setSaveWarningModal,
}) => {
  const navigate = useNavigate()
  const [isCrnJurisdictionModalOpen, setCrnJurisdictionModalOpen] = React.useState(false)
  const shouldWarn =
    isEditMode && customRightName?.jurisdictionRights && customRightName?.jurisdictionRights?.length > 0

  const breadcrumbs = getUpsertBreadCrumbs(isEditMode, customRightName?.name)

  const initialValues = getCustomRightNameFormValues(customRightName)

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmitUpdate,
  })

  const { submitForm, isSubmitting, values, setFieldValue, validateForm, setFieldTouched } = formikProps

  return (
    <>
      {shouldWarn && (
        <Box width={1280} mb={2}>
          <Banner
            severity="warning"
            title={`This Custom Right Name is used in ${customRightName.jurisdictionRights
              ?.length} active Jurisdiction${
              customRightName.jurisdictionRights?.length! > 1 ? 's' : ''
            }. All changes will take effect immediately after saving.`}
            action={
              <Button variant="link" onClick={() => setCrnJurisdictionModalOpen(true)}>
                View List
              </Button>
            }
          />
        </Box>
      )}
      {isEditMode ? (
        <>
          <NavigationBreadCrumbs type="light" items={breadcrumbs} />
          <FormikProvider value={formikProps}>
            <UpsertLayout
              renderFormTitle={() => <ContentGroup title="Edit Custom Right" titleVariant="h2" isDivider />}
              mode={UpsertLayoutMode.edit}
              onAccept={async () => {
                const errors = await validateForm()
                if (Object.keys(errors).length) {
                  Object.keys(errors).forEach(errorKey => {
                    setFieldTouched(errorKey, true)
                  })
                  return
                }

                submitForm()
              }}
              onCancel={() => navigate(RoutesManager.settings.customRightName.root.getURL())}
              acceptButtonProps={{
                pending: isSubmitting,
              }}
              cancelButtonProps={{
                pending: isSubmitting,
              }}
              acceptActionButtonText="Save"
              cancelActionButtonText="Cancel"
            >
              <form autoComplete="off">
                <BasicDetailsUpdateFormSection
                  values={values}
                  onSubmitUpdate={onSubmitUpdate}
                  setFieldValue={setFieldValue}
                  initialValues={initialValues}
                  isLoading={!isReady}
                />

                {saveWarningModal && (
                  <CustomRightNameSaveAlertModal
                    isSubmitting={isSubmitting}
                    onClose={() => {
                      setSaveWarningModal(false)
                    }}
                    data={customRightName}
                    action={() => onSubmitUpdate(values)}
                  />
                )}
              </form>
            </UpsertLayout>
          </FormikProvider>
        </>
      ) : (
        <CustomRightNameUpsertCreate
          customRightName={customRightName}
          isReady={isReady}
          onSubmitCreate={onSubmitCreate}
        />
      )}

      {isCrnJurisdictionModalOpen && (
        <CustomRightNameJurisdictionModal
          onClose={() => {
            setCrnJurisdictionModalOpen(false)
          }}
          data={customRightName}
        />
      )}
    </>
  )
}
