import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { MaybeNull } from 'interfaces/common'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { PolicyScopeDTO } from 'interfaces/policyScopes-v2/policyScope'
import { PolicyDocumentDTO } from 'interfaces/policyDocuments/policyDocument'
import { RoutesManager } from 'utils/routing/routesManager'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { UpsertLayout } from 'components/ui-layouts/upsertLayout/UpsertLayout'
import { UpsertLayoutFooter } from 'components/ui-layouts/upsertLayout/UpsertLayoutFooter'
import { useValidationSchema, DeploymentPlanFormValues } from 'pages/consentAndRights/deploymentPlans-v2/upsert/utils'
import { getInitialValues } from 'pages/consentAndRights/deploymentPlans-v2/upsert/edit/utils'
import { Group } from 'components/ui-layouts/group/Group'
import { Button } from '@ketch-com/deck'
import { DeploymentPlanBasicDetails } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/DeploymentPlanBasicDetails'
import { DeploymentPlanExperiences } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/deploymentPlanExperiences/DeploymentPlanExperiences'
import { DeploymentPlanDocuments } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/deploymentPlanDocuments/DeploymentPlanDocuments'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'

type Props = {
  isReady: boolean
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
  policyScopes: PolicyScopeDTO[]
  experiences: ExperienceV2DTO[]
  policyDocuments: PolicyDocumentDTO[]
  themes: ThemeV3DTO[]
  onSubmit: (values: DeploymentPlanFormValues) => Promise<void>
}

export const DeploymentPlanEdit: React.FC<Props> = ({
  isReady,
  deploymentPlan,
  policyScopes,
  experiences,
  policyDocuments,
  themes,
  onSubmit,
}) => {
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()

  const name = deploymentPlan?.name
  const code = deploymentPlan?.code
  const isEntitledToContentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Deployment Plans', link: RoutesManager.deployment.deploymentPlansV2.root.getURL() },
    {
      title: name || code,
      link: RoutesManager.deployment.deploymentPlansV2.view.root.getURL({ code }),
    },
    { title: 'Edit Plan' },
  ]

  const initialValues = useMemo(
    () => getInitialValues({ deploymentPlan, policyScopes }),
    [deploymentPlan, policyScopes],
  )

  const validationSchema = useValidationSchema({ isEditMode: true, isEntitledToContentAndDisclosure })

  return (
    <UpsertLayout
      breadcrumbs={breadcrumbs}
      isReady={isReady}
      title="Edit Plan"
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm }) => (
        <>
          <div>
            {/* Filters themes with the summary field as a way to only show V2 themes */}
            <DeploymentPlanBasicDetails isEditMode themes={themes.filter(theme => !!theme.summary)} />
            <DeploymentPlanExperiences policyScopes={policyScopes} experiences={experiences} />
            {!isRelease(Release.PropertyThemeDocuments) && (
              <DeploymentPlanDocuments policyDocuments={policyDocuments} editMode={true} />
            )}
          </div>

          <UpsertLayoutFooter>
            <Group>
              <Button
                pending={isSubmitting}
                color="secondary"
                size="large"
                onClick={() => {
                  navigate(RoutesManager.deployment.deploymentPlansV2.view.root.getURL({ code }))
                }}
              >
                Cancel
              </Button>
              <Button size="large" onClick={submitForm} pending={isSubmitting}>
                Save
              </Button>
            </Group>
          </UpsertLayoutFooter>
        </>
      )}
    </UpsertLayout>
  )
}
