import { FormEditor } from 'components/ui-kit/form/editor'
import { experienceUpsertFormKeys } from '../../../../utils/useExperienceValidationSchema'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { renderDocumentLinkerModalExcludeModal } from 'components/ui-kit/form/editor/components/DocumentLinkerModal'
import { useContext } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'

export const ModalExperienceDescriptionForm: React.FC = () => {
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length
  return (
    <>
      <SidebarFormGroup hasBorderBottom={hasLanguages} sx={{ pt: 0 }}>
        <FormEditor
          label={'Description Text'}
          required={false}
          id={experienceUpsertFormKeys.bannerDescription.replaceAll('.', '_')}
          name={experienceUpsertFormKeys.modalDescriptionText}
          linkChangeModal={renderDocumentLinkerModalExcludeModal}
          charLimit={3000}
        />
      </SidebarFormGroup>
      <SidebarFormTranslations
        title={'Description Text Translations'}
        name={experienceUpsertFormKeys.modalDescriptionText}
        variant={'richText'}
        excludeModalLinks
        charLimit={3000}
      />
    </>
  )
}
