import React from 'react'
import { useFormikContext } from 'formik'

import { AppDTO, AppFormDTO } from 'interfaces/apps'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { UserIdentitySpacesCellRenderer } from './UserIdentitySpacesCellRenderer'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { DataGrid, FormRow, TableCell } from '@ketch-com/deck'

type Props = {
  app?: AppDTO
  identitySpaces: IdentitySpaceDTO[]
}

type IndentitySpaceType = {
  name: string
  code: string
  managed?: boolean
  filters?: {
    type: string
    format: string
  }
  rowIndex: number
  userIdentitySpaceCode: string
}
export const AppFormSectionIdentitySpaces: React.FC<Props> = ({ identitySpaces }) => {
  const { values } = useFormikContext<AppFormDTO>()

  const indentitySpaces: IndentitySpaceType[] = values.identitySpaces.map((elem, index) => ({
    ...elem,
    rowIndex: index,
  }))

  const columns = dataGridColumns(identitySpaces)
  return (
    <FormRow title="Identifier Mapping">
      <DataGrid
        autosizeOnMount
        columns={columns}
        disableChildrenSorting
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        getRowId={row => row.code}
        disableRowHoverStates
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        hideFooter
        rows={indentitySpaces}
        noRowsOverlayProps={{
          buttonTitle: '',
        }}
      />
    </FormRow>
  )
}

const dataGridColumns = (identitySpaces: IdentitySpaceDTO[]): GridColDef<IndentitySpaceType>[] => [
  {
    align: 'left',
    field: 'name',
    headerAlign: 'left',
    headerName: 'App Identifier',
    flex: 1,
    sortable: false,
    renderCell: ({ row: { name, code } }: GridRenderCellParams<IndentitySpaceType>) => {
      return <TableCell title={name} subTitle={code} />
    },
  },
  {
    align: 'left',
    field: 'userIdentitySpace',
    headerAlign: 'left',
    headerName: 'Ketch Identifier',
    flex: 1,
    sortable: false,
    renderCell: ({ row: { managed, filters, rowIndex } }: GridRenderCellParams<IndentitySpaceType>) => {
      return (
        <TableCell>
          <UserIdentitySpacesCellRenderer
            managed={managed}
            filters={filters}
            rowIndex={rowIndex}
            identitySpaces={identitySpaces}
          />
        </TableCell>
      )
    },
  },
]
