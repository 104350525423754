import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { InlineEditLayout } from 'pages/assetManager/assets/view/hooks/useAssetDetailContentRows/components'
import { UseInlineEditUtilsReturnType } from '../../hooks'
import { AutoDiscoveryDisplay, EditAutoDiscoveryFormSection } from './components'

type Props = {
  inlineEditUtils: UseInlineEditUtilsReturnType
  assetSummary?: AssetSummaryDTO
}

export const AutoDiscovery: React.FC<Props> = ({ assetSummary, inlineEditUtils }) => {
  const {
    errors,
    handleSubmit,
    isUpdatingDatabase,
    values,
    resetForm,
    setFieldValue,
    initialValues,
    handleChange,
    touched,
    setTouched,
    setEditRow,
    editRow,
  } = inlineEditUtils

  const error = !!Object.keys(errors).length ? <>Please complete all required values.</> : ''

  return (
    <InlineEditLayout
      editModeKey="AutoDiscovery"
      setEditRow={setEditRow}
      isEditMode={editRow === 'AutoDiscovery'}
      pending={isUpdatingDatabase}
      onSubmit={handleSubmit}
      error={error}
      resetForm={resetForm}
      resetInputOnCancel={() => {
        setFieldValue('schedule.day', initialValues.schedule.day)
        setFieldValue('schedule.frequency', initialValues.schedule.frequency)
        setFieldValue('schedule.isCustomizeStartingTimeChecked', initialValues.schedule.isCustomizeStartingTimeChecked)
        setFieldValue('schedule.time', initialValues.schedule.time)
        setFieldValue('schedule.period', initialValues.schedule.period)
      }}
      displayModeContent={<AutoDiscoveryDisplay assetSummary={assetSummary} />}
      editModeContent={
        <EditAutoDiscoveryFormSection
          assetSummary={assetSummary}
          {...{
            errors,
            handleSubmit,
            isUpdatingDatabase,
            values,
            handleChange,
            setFieldValue,
            touched,
            setTouched,
          }}
        />
      }
    />
  )
}
