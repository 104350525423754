import React, { ComponentType, createContext, useContext } from 'react'
import { withCheckPermission } from 'utils/helpers/auth/withCheckPermissionHOC'

interface ProtectedRouteProps {
  component: ComponentType
  permissions?: string | string[]
  requireAllPermissions?: boolean
}

// Create the PermissionsContext
const PermissionsContext = createContext<{
  permissions?: string | string[]
  requireAllPermissions?: boolean
}>({})

// Export a hook for easier access to the context
export const usePermissionsFromProtectedRoute = () => {
  return useContext(PermissionsContext)
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, permissions, requireAllPermissions }) => {
  let ComponentWithCheckPermission = withCheckPermission(component as any)

  return (
    <PermissionsContext.Provider value={{ permissions, requireAllPermissions }}>
      <ComponentWithCheckPermission />
    </PermissionsContext.Provider>
  )
}
