import React, { useState } from 'react'
import { isNumber } from 'lodash'
import Box from '@mui/material/Box'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { Button, Icon } from '@ketch-com/deck'

export interface Props {
  /** Value to be rendered */
  value?: React.ReactNode
}

export const SensitiveTextCellRenderer: React.FC<Props> = ({ value }) => {
  const [isHidden, setIsHidden] = useState(true)

  const handleToggleHidden = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setIsHidden(h => !h)
  }

  return (
    <>
      {value || isNumber(value) ? (
        <Box flex={1} display="flex" justifyContent="space-between" alignItems="center">
          <Button variant="iconCustomRounded" color="tertiary" onClick={e => handleToggleHidden(e)} sx={{ mr: 1 }}>
            <Icon name={isHidden ? 'OEyeShow' : 'OEyeHide'} />
          </Button>

          {isHidden
            ? Array.from({ length: 6 }, (_, i) => (
                <FiberManualRecordIcon
                  sx={{
                    fontSize: theme => theme.typography.pxToRem(12),
                  }}
                />
              ))
            : value}
        </Box>
      ) : (
        <EmptyValueRenderer />
      )}
    </>
  )
}
