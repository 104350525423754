import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EventV2DTO } from '@ketch-com/figurehead'
import { WorkflowDTO } from 'interfaces/workflows'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { Pagination } from 'api/common/paginatedQuery'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ReactComponent as EmptyStateWorkflowsIcon } from 'assets/icons/emptyState/EmptyStateWorkflows.svg'
import { DataGrid, EmptyState, ListLayout, TooltipButton } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { useWorkflowsListDataGridColumns } from './workflowsListUtils'
import { GridColDef, GridRowParams } from '@mui/x-data-grid-premium'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { debounce } from 'lodash'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

const DEBOUNCE_WAIT_TIME = 400
type Props = {
  isLoading: boolean
  isFetching: boolean
  workflows: WorkflowDTO[]
  events: EventV2DTO[]
  pagination: Pagination
  onHandleSearch: (value: string) => void
  searchValue: string
}

export const WorkflowsList: React.FC<Props> = ({
  isLoading,
  isFetching,
  workflows,
  searchValue,
  onHandleSearch,
  events,
  pagination,
}) => {
  const navigate = useNavigate()
  const [innerSearchValue, setInnerSearchValue] = useState('')
  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()
  const isEntitledToCreateWorkflow = isEntitled(ENTITLEMENTS.QTY_WORKFLOW)
  const isPermittedToCreateWorkflow = isPermitted([PERMISSIONS.WORKFLOW_WRITE])
  const canCreateWorkflow = isEntitledToCreateWorkflow && isPermittedToCreateWorkflow

  const handleUpsertWorkflowClick = (code?: string) => {
    navigate(RoutesManager.orchestration.workflows.upsert.root.getURL({ code: code || NEW_ITEM_ID }))
  }

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Workflows', link: RoutesManager.orchestration.workflows.root.getURL() },
  ]

  useEffect(() => {
    if (innerSearchValue !== searchValue) {
      setInnerSearchValue(innerSearchValue)
    }
  }, [searchValue, innerSearchValue])

  const workflowsColumns: GridColDef<any>[] = useWorkflowsListDataGridColumns(isEntitledToCreateWorkflow)

  const hasWorkflowsWithIssues = workflows.some(
    workflow => workflow.hasOwnProperty('hasIssues') && !!workflow.hasIssues,
  )
  const dataGridColumns = !hasWorkflowsWithIssues ? workflowsColumns.slice(1) : workflowsColumns

  const debounceFn = useMemo(() => debounce((v: string) => onHandleSearch(v), DEBOUNCE_WAIT_TIME), [onHandleSearch])

  const handleInnerSearchInputChange = (v: string) => {
    setInnerSearchValue(v)
    debounceFn(v)
  }

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ListLayout
        headerTitle={''}
        customHeaderContentComponent={
          <Box justifyContent="space-between" alignItems="center" display="flex" mt={-4}>
            <SearchTextInput onChange={handleInnerSearchInputChange} value={innerSearchValue} showClearButton />
            {isPermittedToCreateWorkflow && (
              <TooltipButton
                title={
                  !isEntitledToCreateWorkflow
                    ? 'No workflow entitlements remaining, please delete one or contact your admin for more'
                    : undefined
                }
                disabled={!isEntitledToCreateWorkflow}
                onClick={() => handleUpsertWorkflowClick()}
              >
                Create Workflow
              </TooltipButton>
            )}
          </Box>
        }
      >
        <Box>
          {!isFetching && !workflows.length && !searchValue ? (
            <Box
              mt={2.5}
              bgcolor="white.main"
              borderRadius="11px"
              py={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <EmptyState
                customIcon={<EmptyStateWorkflowsIcon />}
                title="There are no workflows for this organization"
                subTitle={canCreateWorkflow ? 'Create your first one to populate the table.' : undefined}
                primaryButtonTitle={canCreateWorkflow ? 'Create Flow' : undefined}
                primaryButtonProps={{
                  onClick: () => handleUpsertWorkflowClick(),
                }}
              />
            </Box>
          ) : (
            <DataGrid
              sx={{
                border: 'unset',
                cursor: 'pointer',
                '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                  backgroundImage: 'unset',
                  cursor: 'auto',
                },
                '& .MuiDataGrid-virtualScroller': {
                  height: !isLoading ? '100%' : '300px',
                },
              }}
              autosizeOnMount
              autosizeOptions={{
                includeHeaders: true,
                includeOutliers: false,
                columns: dataGridColumns.map(column => column.field),
                expand: true,
              }}
              getRowHeight={() => 'auto'}
              columns={dataGridColumns}
              disableChildrenSorting
              disableAggregation
              disableRowGrouping
              disableColumnFilter
              disableMultipleColumnsFiltering
              disableColumnMenu
              disableColumnResize
              disableColumnReorder
              getRowId={row => row.code}
              rows={workflows}
              loading={isLoading}
              onRowClick={({ row }: GridRowParams<WorkflowDTO>) => {
                navigate(RoutesManager.orchestration.workflows.view.root.getURL({ code: row.code }))
              }}
              pagination
              pageSize={25}
              slotProps={{
                pagination: {
                  showLastButton: true,
                  showFirstButton: true,
                  size: 'medium',
                },
              }}
              sortingMode="client"
              initialState={{
                sorting: {
                  sortModel: [{ field: 'updatedAt', sort: 'desc' }],
                },
              }}
              noRowsOverlayProps={{
                buttonTitle: 'Reset to Defaults',
                buttonProps: {
                  onClick: () => {
                    handleInnerSearchInputChange('')
                  },
                },
              }}
            />
          )}
        </Box>
      </ListLayout>
    </>
  )
}
