import { CustomRightNameDTO, PutCustomRightNameRequestBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  id: string
  formData: CustomRightNameDTO
}

export const updateCustomRightName = ({ id, formData }: Params) =>
  API.put<PutCustomRightNameRequestBodyDTO>(
    formatRequestString({
      entityUrl: `/api/custom-right-names/${id}`,
    }),
    { customRightName: formData },
  )
