import { useEnabledOrganizationLanguages } from '.'
import { v4 as uuidv4 } from 'uuid'
import { useCallback } from 'react'
import { DropdownOptionDTO } from '../interfaces'

export const useGetEmptyDropdownItem = (): {
  getDummyDropdownItem: () => DropdownOptionDTO
} => {
  const { enabledLanguages } = useEnabledOrganizationLanguages()

  const getDummyDropdownItem = useCallback(
    () =>
      ({
        id: uuidv4(),
        label: '',
        value: '',
        translations: enabledLanguages.map(language => ({
          code: language.code,
          title: language.englishName,
          fieldLabelTranslation: '',
        })),
      }) as DropdownOptionDTO,
    [enabledLanguages],
  )

  return { getDummyDropdownItem }
}
