import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO, DmlHookDTO, PutAssetRequestBodyDTO } from '@ketch-com/figurehead'

type Args = { assetSummary: MaybeNull<AssetSummaryDTO>; hooks: DmlHookDTO[] }

export const mapValuesToPayload = ({ assetSummary, hooks }: Args): PutAssetRequestBodyDTO => {
  const preHookPayload = (hooks || [])?.filter?.(hook => hook.type === 1)
  const postHookPayload = (hooks || [])?.filter?.(hook => hook.type === 2)

  return {
    assetSummary: {
      ...assetSummary,
      asset: {
        ...assetSummary?.asset,
        ...(preHookPayload && {
          dmlPreHooks: [...preHookPayload],
        }),
        ...(postHookPayload && {
          dmlPostHooks: [...postHookPayload],
        }),
      },
    },
  }
}
