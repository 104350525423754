import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchPermitStatsOverall } from '../fetchers/fetchPermitStatsOverall'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.permitStatsOverall,
  queryFn: fetchPermitStatsOverall,
  select: res => res?.data?.overall || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const usePermitStatsOverall = (config?: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      start: 0,
      limit: 1000,
      ...config?.params,
    },
  })
}
