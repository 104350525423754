import { FileInfoDTO } from 'interfaces/files/fileInfo'

export interface FileDataType {
  [fileName: string]: {
    date?: Date
    size?: string | number
  }
}

export const mapFileInfoDTOToDropZoneUploadedFileData = (data: FileInfoDTO[]) => {
  return data.reduce((acc, current) => {
    return {
      ...acc,
      [current.name]: {
        size: current.size,
      },
    }
  }, {})
}

export const mapFileToDropZoneUploadedFileData = (data: File[]) => {
  return data.reduce((acc, current) => {
    return {
      ...acc,
      [current.name]: {
        size: current.size,
      },
    }
  }, {})
}
