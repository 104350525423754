import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { PutDataReviewStatusRequestBodyDTO, PutDataReviewStatusResponseBodyDTO } from '@ketch-com/figurehead'

type Params = {
  executionId?: string
  formData: PutDataReviewStatusRequestBodyDTO
  requestId: string
  stepId: string
}

export const updateDataReviewStatus = ({ executionId, formData, requestId, stepId }: Params) =>
  API.put<PutDataReviewStatusResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/data-review/${requestId}/${executionId}/${stepId}/status`,
    }),
    formData,
  )
