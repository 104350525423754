import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchVerifiers } from '../fetchers/fetchVerifiers'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.verifiersPaginated,
  queryFn: fetchVerifiers,
  select: res => res?.data || null,
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useVerifiers = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    itemsPerPage: config?.itemsPerPage || 20,
    params: {
      ...config?.params,
    },
  })
}
