import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import {
  CreateApplianceRequest,
  CreateApplianceResponse,
} from '@ketch-com/windlass/dist/schemas/appliance_appliances_gen.schemas'

export const createTransponder = (data: CreateApplianceRequest) => {
  const entityUrl = '/api/asset-manager/appliances'
  return API.post<CreateApplianceResponse>(
    formatRequestString({
      entityUrl,
    }),
    data,
  )
}
