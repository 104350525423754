import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface NavigationState {
  /* The last ten URLs visited. Not persisted across page loads, but could be. */
  history: string[]
  goBack: string
}

const initialState = {
  history: [],
  goBack: '',
} as NavigationState

export const navigationSlice = createSlice({
  name: 'navigationSlice',
  initialState,
  reducers: {
    addRouteToHistory: (state, action: PayloadAction<string>) => {
      state.history = [...state?.history?.slice?.(-9), action.payload]
    },
    setNavigationSliceGoBack: (state, action: PayloadAction<string>) => {
      state.goBack = action.payload
    },
  },
})

export const { addRouteToHistory, setNavigationSliceGoBack } = navigationSlice.actions

export default navigationSlice.reducer
