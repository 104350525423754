import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'

interface IParams {
  entityType: ApplicationEntity
}

export const getApplicationEntityName = (params?: IParams) => {
  switch (params?.entityType) {
    case ApplicationEntity.APPLICATION:
    case ApplicationEntity.APPLICATIONS:
      return { plural: 'Properties', singular: 'Property' }

    case ApplicationEntity.DEPLOYMENT_PLAN:
    case ApplicationEntity.DEPLOYMENT_PLANS:
      return { plural: 'Deployment Plans', singular: 'Deployment Plan' }

    case ApplicationEntity.EXPERIENCE:
    case ApplicationEntity.EXPERIENCES:
      return { plural: 'Experiences', singular: 'Experience' }

    case ApplicationEntity.IDENTITY_SPACE:
    case ApplicationEntity.IDENTITY_SPACES:
      return { plural: 'Identifiers', singular: 'Identifier' }

    case ApplicationEntity.ORGANIZATION:
    case ApplicationEntity.ORGANIZATIONS:
      return { plural: 'Organizations', singular: 'Organization' }

    case ApplicationEntity.POLICY_DOCUMENT:
    case ApplicationEntity.POLICY_DOCUMENTS:
      return { plural: 'Policy Documents', singular: 'Policy Document' }

    case ApplicationEntity.COOKIE:
    case ApplicationEntity.COOKIES:
      return { plural: 'Cookies', singular: 'Cookie' }

    case ApplicationEntity.POLICY_SCOPE:
    case ApplicationEntity.POLICY_SCOPES:
      return { plural: 'Jurisdictions', singular: 'Jurisdiction' }

    case ApplicationEntity.PURPOSE:
    case ApplicationEntity.PURPOSES:
      return { plural: 'Purposes', singular: 'Purpose' }

    case ApplicationEntity.RIGHTS_QUEUE:
    case ApplicationEntity.RIGHTS_QUEUES:
    case ApplicationEntity.RIGHTS_INVOCATION:
    case ApplicationEntity.RIGHTS_INVOCATIONS:
      return { plural: 'Right Invocations', singular: 'Right Invocation' }

    case ApplicationEntity.USER:
    case ApplicationEntity.USERS:
      return { plural: 'Users', singular: 'User' }

    case ApplicationEntity.EVENT:
    case ApplicationEntity.EVENTS:
      return { plural: 'Events', singular: 'Event' }

    case ApplicationEntity.WORKFLOW:
    case ApplicationEntity.WORKFLOWS:
      return { plural: 'Workflows', singular: 'Workflow' }

    case ApplicationEntity.WEBTAG:
    case ApplicationEntity.WEBTAGS:
      return { plural: 'Tags', singular: 'Tag' }

    case ApplicationEntity.UNKNOWN_ENTITY_TYPE:
    default:
      return { plural: '', singular: '' }
  }
}
