import { useMemo } from 'react'
import * as reducers from 'store/rightsQueueFilterSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getRightsQueueFilterState } from 'store/rightsQueueFilterSlice/selectors'

import { RightsQueueMegaFilterButtonPanelIds } from 'pages/orchestration/rightsQueue/listV2/components/RightsQueueMegaFilterButton/constants'

/**
 * Custom hook for managing redux state as filters
 * @returns An object containing functions for manipulating Rights Queue search parameters stored in redux state
 */
export const useFiltersV3 = () => {
  const dispatch = useAppDispatch()
  // Destructure values from useSearchParams
  const { searchParams: reduxSearchParams } = useAppSelector(getRightsQueueFilterState)

  // Use memoization to store search param array
  const searchParamArray = useMemo(() => Object.entries(reduxSearchParams), [reduxSearchParams])

  /**
   * Checks if a given string is a valid key of the RightsQueueMegaFilterButtonPanelIds enum.
   *
   * @param key - The string to check.
   * @returns True if the string is a valid key of the RightsQueueMegaFilterButtonPanelIds enum, otherwise false.
   */
  const isValidRightsQueueMegaFilterButtonPanelId = (key: string): boolean => {
    return Object.values(RightsQueueMegaFilterButtonPanelIds).includes(key as RightsQueueMegaFilterButtonPanelIds)
  }

  /**
   * Adds a single search parameter to the redux state
   * @param key - The key of the search parameter
   * @param value - The value of the search parameter
   */
  const addSingleSearchParam = ({ key, value }: { key: string; value: string }) => {
    dispatch(
      reducers.setSearchParams({
        [key]: value,
      }),
    )
  }

  /**
   * Removes a single search parameter from the redux state
   * @param key - The key of the search parameter to be removed
   */
  const removeSingleSearchParam = (key: string) => {
    dispatch(reducers.unsetMultipleSearchParams([key]))
  }

  /**
   * Removes multiple search parameters from the redux state
   * @param keys - An array of keys of the search parameters to be removed
   */
  const removeMultipleSearchParams = (keys: string[]) => {
    dispatch(reducers.unsetMultipleSearchParams(keys))
  }

  /**
   * Removes a specific value from a comma-separated list of values associated with a search parameter in the redux state
   * @param key - The key of the search parameter
   * @param value - The value to be removed from the comma-separated list of values associated with the search parameter
   */
  const removeQueryParamFromList = ({ key, value }: { key: string; value: string }) => {
    dispatch(
      reducers.removeSearchParamFromList({
        key,
        value,
      }),
    )
  }

  /**
   * Clears all values associated with a search parameter from the redux state
   * @param key - The key of the search parameter
   */
  const clearAllEntriesForKey = (key: string) => {
    dispatch(reducers.unsetMultipleSearchParams([key]))
  }

  /**
   * Clears all search parameters from the redux state
   */
  const clearAllSearchParams = () => {
    dispatch(reducers.resetAllSearchParams())
  }

  /**
   * Gets the value of a query parameter by key from a URLSearchParams object.
   *
   * @param {Object} params - An object with a `key` property that specifies the name of the query parameter to retrieve.
   * @param {string} params.key - The name of the query parameter to retrieve.
   * @returns {string[]} An array of strings representing the values of the query parameter, or an empty array if the parameter is not found.
   */
  const getParamValueArrayByKey = (key: string): string[] => {
    const value = reduxSearchParams[key]
    return value ? value.split(',') : []
  }

  /**
   * Gets the value of a query parameter by key from a URLSearchParams object.
   *
   * @param {Object} params - An object with a `key` property that specifies the name of the query parameter to retrieve.
   * @param {string} params.key - The name of the query parameter to retrieve.
   * @returns {string} A comma-separated string representing the values of the query parameter, or an empty string if the parameter is not found.
   */
  const getParamValueByKey = (key: string): string => {
    const value = reduxSearchParams[key]
    return value || ''
  }

  /**
   * Replaces the query parameters in a URLSearchParams object with new values.
   *
   * @param {Object.<string, string>} newParams - An object that maps query parameter names to their new values.
   */
  const replaceParams = (newParams: { [key: string]: string }): void => {
    dispatch(reducers.setSearchParams(newParams))
  }

  /**
   * Calculates the number of filters applied to the current search query.
   * @param {string} panelId - The id of the panel to get the values for.
   * @returns {string[]} An array of strings representing the values of the query parameter, or an empty array if the parameter is not found.
   */
  const getPanelParamValuesArrayByPanelId = (panelId: string): string[] => {
    return reduxSearchParams?.[panelId]?.split(',').filter(Boolean) || []
  }

  /**
   * Calculates the number of filters applied to the current search query.
   *
   * @returns {number} The number of filters applied to the search query.
   */
  const filterCount = useMemo((): number => {
    return Object.keys(reduxSearchParams).reduce((acc, key) => {
      if (isValidRightsQueueMegaFilterButtonPanelId(key)) {
        const values = reduxSearchParams[key].split(',').filter(Boolean)
        return acc + values.length
      }
      return acc
    }, 0)
  }, [reduxSearchParams])

  // Return only necessary values as an object
  return {
    addSingleSearchParam,
    clearAllEntriesForKey,
    clearAllSearchParams,
    filterCount,
    getPanelParamValuesArrayByPanelId,
    getParamValueArrayByKey,
    getParamValueByKey,
    isValidRightsQueueMegaFilterButtonPanelId,
    reduxSearchParams,
    removeMultipleSearchParams,
    removeQueryParamFromList,
    removeSingleSearchParam,
    replaceParams,
    searchParamArray,
  }
}
