import { SxProps, Theme } from '@mui/material'

export const getTrendDateRangePickerSxProps = (): SxProps<Theme> => {
  return {
    letterSpacing: '-0.01em',
    textTransform: 'none',
    fontWeight: 600,
    fontFamily: ({ typography }) => typography.fontFamily,
    fontSize: ({ typography }) => typography.pxToRem(14),
    borderRadius: 1.25,
    py: 0.8,
    color: ({ palette }) => palette.darkDusk.main,
    backgroundColor: ({ palette }) => palette.fadedGrey.main,
    '&:hover': {
      backgroundColor: ({ palette }) => palette.fadedGrey.main,
      color: ({ palette }) => palette.royalBlue.main,
    },
    '&.MuiButton-sizeSmall': {
      minWidth: '60px',
      minHeight: 32,
    },
    '&.MuiButton-sizeLarge': {
      minWidth: '60px',
      minHeight: 44,
    },
  }
}
