import { MaybeNull, ObjectLiteral } from 'interfaces'
import { ApiKeyFormValues } from 'interfaces/apiKeys'
import { ApiKeyRoleDTO } from 'interfaces/apiKeys/ApiKeyRoleDTO'
import { ApiKeyDTO } from 'interfaces/apiKeys'
import { OrganizationDTO } from 'interfaces/organizations/organization'
import { ENTITLEMENT_CODES, ROLE_GROUPS } from 'utils/constants/organizationEntitlements'
import { NONE, NO_PERMISSION } from '../../constants'
import { roleGroupNames } from 'pages/settings/users/view/utils/getUserRolesDisplayData'

// Initial form values are a map of group name to role code
const getExistingApiKeyRoles = (userRoles: ApiKeyRoleDTO[]): ObjectLiteral<string> => {
  let start: ObjectLiteral<string> = {}
  userRoles
    .filter(r => Object.values(ROLE_GROUPS).includes(r.group))
    .forEach(r => {
      start[r.group] = r.code
    })
  return start
}

type GetGroupRoleValueArgs = { organization: MaybeNull<OrganizationDTO>; group: string }

const getGroupRoleValue = ({ organization, group }: GetGroupRoleValueArgs): string => {
  switch (group) {
    case ROLE_GROUPS.DataMapAssets: {
      const temp = organization?.entitlements?.[ENTITLEMENT_CODES.ASSET_MANAGER]
      if (temp === 0) return NONE
      if (temp === -1) return NO_PERMISSION
      return NONE
    }
    case ROLE_GROUPS.ConsentAndRightsCapture: {
      return Object.entries(organization?.entitlements || {})
        .filter(c => c?.[0].includes(ENTITLEMENT_CODES.EXPERIENCE_SERVER))
        .some(c => c?.[1] === -1)
        ? NO_PERMISSION
        : NONE
    }
    case ROLE_GROUPS.ConsentAndRightsConfig: {
      return Object.entries(organization?.entitlements || {})
        .filter(c => c?.[0].includes('orch_'))
        .some(c => c?.[1] === -1) ||
        (organization?.entitlements?.[ENTITLEMENT_CODES.EXPERIENCE_SERVER_RIGHTS] || -2) === -1
        ? NO_PERMISSION
        : NONE
    }
    case ROLE_GROUPS.ConsentAndRightsQueue: {
      return (organization?.entitlements?.[ENTITLEMENT_CODES.EXPERIENCE_SERVER_RIGHTS] || -2) === -1 ||
        (organization?.entitlements?.[ENTITLEMENT_CODES.ORCHESTRATION_RIGHTS] || -2) === -1
        ? NO_PERMISSION
        : NONE
    }
    case ROLE_GROUPS.Organization: {
      return ''
    }
    default: {
      return NO_PERMISSION
    }
  }
}

type Args = {
  apiKey: MaybeNull<ApiKeyDTO>
  isEditMode: boolean
  organization: MaybeNull<OrganizationDTO>
}

export const getApiKeyFormInitialValues = ({ apiKey, isEditMode, organization }: Args): ApiKeyFormValues => {
  if (isEditMode && apiKey) {
    return {
      name: apiKey.name,
      description: apiKey.description,
      status: apiKey.status,
      roles: getExistingApiKeyRoles(apiKey.roles),
    }
  } else {
    return {
      name: '',
      description: '',
      status: 'ENABLED',
      roles: Object.values(roleGroupNames).reduce<ObjectLiteral>(
        (acc, group) => ({
          ...acc,
          [group]: getGroupRoleValue({ organization, group }),
        }),
        {},
      ),
    }
  }
}
