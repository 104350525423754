import { CookieCategoryOptions } from 'interfaces/cookies/cookieCategory'

import { useStyles } from './useStyles'
import { COOKIE_FILTER_STATUS_OPTIONS } from '../utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setQuery, setCategory, setStatus } from 'store/cookiesListFilterSlice'
import { getCookiesListFilterState } from 'store/cookiesListFilterSlice/selectors'

export const useCookiesListFiltersUtils = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const {
    status: statusFilter,
    category: categoryFilter,
    query: searchText,
  } = useAppSelector(getCookiesListFilterState)

  const resetStatusItemsFilter = () => dispatch(setStatus(null))
  const resetCategoryItemsFilter = () => dispatch(setCategory(null))

  const statusItems = COOKIE_FILTER_STATUS_OPTIONS.map(({ value, name }) => ({
    content: name,
    active: statusFilter === value,
    onClick: () => dispatch(setStatus(value)),
  }))

  const categoryItems = CookieCategoryOptions.map(({ name, id }) => ({
    content: name,
    active: id === categoryFilter,
    onClick: () => dispatch(setCategory(id || null)),
  }))

  const searchInputHandleOnChange = (nextSearchString: string) => dispatch(setQuery(nextSearchString))

  const searchInputHandleOnClear = () => dispatch(setQuery(''))

  return {
    categoryFilter,
    categoryItems,
    classes,
    searchInputHandleOnChange,
    searchInputHandleOnClear,
    searchText,
    statusFilter,
    statusItems,
    resetStatusItemsFilter,
    resetCategoryItemsFilter,
  }
}
