import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchJurisdictionsRights } from 'api/rights/fetchers/fetchJurisdictionsRights'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.jurisdictionsRights,
  queryFn: fetchJurisdictionsRights,
  select: res => res?.data?.rights || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useJurisdictionsRights = (config?: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config?.params,
      start: 0,
      limit: 1000,
    },
  })
