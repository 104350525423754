import React from 'react'
import { Button, PopUp } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  onSubmit?: () => void
  onCancel: () => void
  isDeleting: boolean
}

export const ConfirmDeleteMessageModal: React.FC<Props> = ({ onSubmit, onCancel, isDeleting }) => {
  return (
    <>
      <PopUp
        title="Delete Message"
        popUpWidth="550px"
        variant="dialog"
        popUpActionChildren={
          <>
            <Button color="secondary" size="large" onClick={onCancel} pending={isDeleting}>
              Close
            </Button>
            <Button color="primary" size="large" onClick={onSubmit} pending={isDeleting}>
              Delete
            </Button>
          </>
        }
      >
        <Typography>Are you sure you want to permanently delete this message?</Typography>
      </PopUp>
    </>
  )
}
