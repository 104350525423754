import pluralize from 'pluralize'
import { RoutesManager } from 'utils/routing/routesManager'
import React, { useContext } from 'react'
import { DataSystemWidgetsContext } from '../context/DataSystemWidgetsContext'
import { ScrollContainer, SubTitle } from './index'
import { Typography } from '@mui/material'
import { Chip, Icon, Widget, theme } from '@ketch-com/deck'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

export const PurposesWidget: React.FC = () => {
  const { processingActivities, navigate, isPermitted } = useContext(DataSystemWidgetsContext)
  const isPermittedToReadPurposes = isPermitted(PERMISSIONS.PURPOSE_READ)

  if (!processingActivities?.purposes?.length) {
    return (
      <Widget
        expandable={Boolean(processingActivities?.purposes?.length)}
        title="Purposes"
        subTitle={
          processingActivities?.purposes?.length
            ? `${processingActivities?.purposes?.length} ${pluralize(
                'Purpose',
                processingActivities?.purposes?.length,
              )} Assigned.`
            : ''
        }
        emptyStateProps={{
          title: 'No Purposes Assigned',
          subTitle: 'This system is not associated with any purpose.',
          iconName: 'OScales',
          colorTheme: theme.palette.darkDuskFaded.main,
        }}
      />
    )
  }

  return (
    <Widget
      expandable={Boolean(processingActivities?.purposes?.length)}
      title="Purposes"
      subTitle={
        processingActivities?.purposes?.length
          ? `${processingActivities?.purposes?.length} ${pluralize(
              'Purpose',
              processingActivities?.purposes?.length,
            )} Assigned.`
          : ''
      }
      content={
        <ScrollContainer>
          {processingActivities?.purposes?.map(purpose => (
            <Chip
              clickable={isPermittedToReadPurposes}
              deleteIcon={<Icon name="OArrowCRight" />}
              label={purpose?.name}
              onClick={
                isPermittedToReadPurposes
                  ? () => navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code: purpose?.code }))
                  : undefined
              }
              onDelete={
                isPermittedToReadPurposes
                  ? () => navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code: purpose?.code }))
                  : undefined
              }
            />
          ))}
        </ScrollContainer>
      }
      footerComponent={
        <SubTitle>
          <Icon name="Link" width={16} height={16} iconColor={theme.palette.darkDuskFaded.main} />

          <Typography variant="smallLabel" color="darkDuskFaded.main">
            Data Map
          </Typography>
        </SubTitle>
      }
    />
  )
}
