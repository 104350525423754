import { useAssetsInfiniteV2 } from 'api/assets/queries/useAssetsInfiniteV2'
import React, { useState } from 'react'
import { Box } from '@mui/material'
import { Waypoint } from 'react-waypoint'

import { Text } from 'components/ui-kit/typography/Text'
import { Table } from 'components/ui-layouts/table/Table'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { AssetTypeIcon } from 'pages/assetManager/components'
import { getAssetTypeDisplayNameByEnum } from 'pages/assetManager/assets/list/components/AssetListItem/utils'
import { InfiniteScrollLoadingIndicator } from 'components/ui-layouts/table/components/infiniteScrollLoadingIndicator'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import numeral from 'numeral'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { generateDescendingArray } from 'pages/insights/activityAndAccess/__mocks__/generateDescendingArray'
import { DetailsClasses } from 'pages/insights/activityAndAccess/components/detailsStyles'

const descendingArray = generateDescendingArray(99)

type Props = {
  teamMemberName?: string
}

export const AccessUtilizationTable: React.FC<Props> = ({ teamMemberName }) => {
  const [searchString, setSearchString] = useState<string>('')

  const {
    data: assets,
    isLoading,
    isFetchingNextPage,
    totalResults,
    hasNextPage,
    fetchNextPage,
  } = useAssetsInfiniteV2({
    params: {
      limit: 10,
      query: searchString,
    },
  })

  return (
    <>
      <Text size={20} weight={700} mt={4} mb={1}>
        Most Frequent Users
      </Text>
      <Text size={14} color="grey" mb={3}>
        All data assets used by {teamMemberName} during the selected period.
      </Text>
      <Box display="flex" alignItems="center" mb={3}>
        <SearchInput
          size="small"
          onChange={nextSearchString => setSearchString(nextSearchString)}
          onClear={() => setSearchString('')}
          value={searchString}
        />

        <Text ml={3} weight={600}>
          {numeral(totalResults).format('0,0')} assets
        </Text>
      </Box>
      <Table
        pending={isLoading}
        isSmallHeaderText
        items={assets}
        rowIdKey={rowData => {
          return rowData?.asset?.resource?.id as string
        }}
        cellSettings={{
          name: {
            width: 380,
            label: 'Name',
            cellRenderer: assetSummary => <TextCellRenderer value={assetSummary?.asset?.resource?.name} />,
          },
          type: {
            width: 110,
            label: 'Type',
            cellRenderer: (assetSummary, index) => {
              return (
                <Box display="flex" alignItems="center">
                  <AssetTypeIcon
                    assetType={assetSummary?.asset?.resource?.resourceType?.canonicalResourceType || 0}
                    className={DetailsClasses.dataSetIcon}
                  />
                  <Text size={11} weight={600} color="grey">
                    {getAssetTypeDisplayNameByEnum(
                      assetSummary?.asset?.resource?.resourceType?.canonicalResourceType || 0,
                    )}
                  </Text>
                  {(assets?.length || 0) - 5 === index ? (
                    <Waypoint
                      onEnter={() => {
                        if (!isFetchingNextPage && hasNextPage) fetchNextPage()
                      }}
                    />
                  ) : null}
                </Box>
              )
            },
          },
          technologies: {
            width: 110,
            label: 'Technologies',
            cellRenderer: assetSummary => {
              return (
                <Box display="flex" alignItems="center">
                  <img
                    className={DetailsClasses.tableAssetTypeIcon}
                    src={getAssetCloudImgUrl(assetSummary?.asset?.connection?.technology || '')}
                    alt={getAssetCloudImgUrl(assetSummary?.asset?.connection?.technology || '')}
                  />
                </Box>
              )
            },
          },
          queriesCount: {
            width: 100,
            label: 'Queries',
            className: DetailsClasses.queriesCountCell,
            cellRenderer: (assetSummary, index) => {
              const totalQueries = Math.floor((8000 * descendingArray[index]) / 100)
              return (
                <Box display="flex" alignItems="center">
                  <Text size={14} lineHeight={2.5}>
                    {numeral(totalQueries).format('0,0')}
                  </Text>
                </Box>
              )
            },
          },
          queriesProgress: {
            className: DetailsClasses.queryProgressCell,
            cellRenderer: (assetSummary, index) => {
              const percent = descendingArray[index]
              // TODO:BAC - percent should be calculated using totalRequests
              return (
                <Box
                  sx={{
                    width: `${(percent / 100) * 115}px`,
                    height: 10,
                    ml: 2,
                    borderRadius: 5,
                    backgroundColor: 'darkDusk.main',
                  }}
                />
              )
            },
          },
        }}
      />
      <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
    </>
  )
}
