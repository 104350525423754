import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { DataGridProProps } from '@mui/x-data-grid-pro'
import { Avatar, AvatarSize, Icon, TableCell } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import {
  DataGridAssetObjectType,
  DataGridObjectType,
  isDataGridObjectType,
} from 'pages/insights/dataMap/view/processingActivities/components/dataSourcesListUtils'
import { nosql } from '../../../constants'

export interface DataGridAssetObjectWithDataSystemAppCodeType extends DataGridAssetObjectType {
  dataSystemAppCode: string
}

export const groupingColDef: DataGridProProps['groupingColDef'] = {
  headerName: 'System',
  sortable: false,
  resizable: false,
  headerAlign: 'left',
  align: 'left',
  flex: 1,
  renderCell: (params: GridRenderCellParams<DataGridObjectType | DataGridAssetObjectWithDataSystemAppCodeType>) => {
    const { row, rowNode } = params
    const isDataGridObj = isDataGridObjectType(row)
    return (
      <TableCell sx={{ padding: '8px 0 8px 15px' }}>
        {rowNode.type === 'group' && <Icon name={rowNode.childrenExpanded ? 'OArrowCDown' : 'OArrowCRight'} />}
        {isDataGridObj ? (
          <>
            <Avatar
              size={rowNode.depth === 1 ? AvatarSize.small : AvatarSize.xlarge}
              isLogo
              variant="rounded"
              sx={{
                backgroundColor: theme => theme.palette.tertiary.main,
              }}
              src={row.dataSystem?.logoUrl}
            />

            <Typography variant="label">{row.dataSystem?.name}</Typography>
          </>
        ) : (
          <Box display="flex" flexDirection="column" gap={0.25} py={1.5} ml={4}>
            <Box alignSelf="flex-start">
              <Typography variant="label">{row.asset?.asset?.resource?.name || ''}</Typography>
            </Box>
            <Box alignSelf="flex-start" display="flex" alignItems="center" gap={0.5}>
              <Avatar
                size={rowNode.depth === 1 ? AvatarSize.small : AvatarSize.xlarge}
                isLogo
                variant="rounded"
                sx={{
                  backgroundColor: theme => theme.palette.tertiary.main,
                }}
                src={row.logoUrl}
              />

              <Typography variant="footnote" textTransform="capitalize" color={'darkDusk.main'}>
                {nosql[row?.dataSystemAppCode || ''] || 'Table'}
              </Typography>
            </Box>
          </Box>
        )}
      </TableCell>
    )
  },
}
