export enum ApplicationEntity {
  UNKNOWN_ENTITY_TYPE = 'unknown_entity_type',

  ORGANIZATION = 'organization',
  ORGANIZATIONS = 'organizations',

  USER = 'users',
  USERS = 'users',

  POLICY_SCOPE = 'policy_scope',
  POLICY_SCOPES = 'policy_scopes',

  PURPOSE = 'purpose',
  PURPOSES = 'purposes',

  POLICY_DOCUMENT = 'policy_document',
  POLICY_DOCUMENTS = 'policy_documents',

  COOKIE = 'cookie',
  COOKIES = 'cookies',

  EXPERIENCE = 'experience',
  EXPERIENCES = 'experiences',

  DEPLOYMENT_PLAN = 'deployment_plan',
  DEPLOYMENT_PLANS = 'deployment_plans',

  APPLICATION = 'application',
  APPLICATIONS = 'applications',

  IDENTITY_SPACE = 'identity_space',
  IDENTITY_SPACES = 'identity_spaces',

  WORKFLOW = 'workflow',
  WORKFLOWS = 'workflows',

  WEBTAG = 'webtag',
  WEBTAGS = 'webtags',

  RIGHTS_QUEUE = 'queue',
  RIGHTS_QUEUES = 'queues',

  RIGHTS_INVOCATION = 'right_invocation',
  RIGHTS_INVOCATIONS = 'right_invocations',

  PERMIT_PROPAGATION = 'permit_propagation',
  PERMIT_PROPAGATIONS = 'permit_propagations',
  CONSENT_PERMIT_PROPAGATION = 'consent_permit_propagation',

  EVENT = 'event',
  EVENTS = 'events',

  THEME = 'theme',
  THEMES = 'themes',

  LABEL = 'label',
  LABELS = 'labels',
  DPIA = 'dpia',

  POLICY_ON_HOLD = 'policy_on_hold',
}
