import { FC } from 'react'
import { ContentGroup } from '@ketch-com/deck'
import { DataSourcesFilters } from './DataSourcesFilters'
import { DataSourcesList } from './DataSourcesList'

export const DataSystemsTab: FC = () => {
  return (
    <>
      <ContentGroup
        title="Data Sources"
        titleVariant="h3"
        variant="inner-page-header"
        isDivider={true}
        subTitle="The systems and data assets involved in this processing."
        actionBottomBlockComponent={<DataSourcesFilters />}
      />
      {/* Systems list */}
      <DataSourcesList />
    </>
  )
}
