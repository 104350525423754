import { Client, Conversation } from '@twilio/conversations'
import { showToast } from 'components/modals/AlertComponent'
import { FormikHelpers, useFormik } from 'formik'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { useState, ChangeEvent, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { formatBytes } from 'utils/formatters/formatBytes'
import { useAuth } from 'utils/hooks/useAuth'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { getComposeMessageValidationSchema } from '../../../utils'
import { acceptableFileTypes, MAX_FILE_SIZE } from '../../Message/constants'

type UseComposeMessageInputUtilsArgs = {
  twilioChatClient?: Client
  twilioConversation?: Conversation
  hasTwilioClientCatastrophicallyFailed: boolean
  rightInvocation?: RightInvocationDTO
}

export const useComposeMessageInputUtils = ({
  twilioConversation,
  hasTwilioClientCatastrophicallyFailed,
  twilioChatClient,
  rightInvocation,
}: UseComposeMessageInputUtilsArgs) => {
  const { isPermitted } = useIsPermitted()
  const { userData: currentUserData } = useAuth()
  const [files, setFiles] = useState<File[]>([])
  const [filesInputKey, setFilesInputKey] = useState<string>('input-key')
  const { id: rightInvocationRequestId } = useParams<{ id?: string }>()
  const isThreadClosed = rightInvocation?.status === 'fulfilled' || rightInvocation?.status === 'rejected'

  const handleSendMessage: (
    values: {
      message: string
    },
    formikHelpers: FormikHelpers<{
      message: string
    }>,
  ) => void | Promise<any> = async (values: any, { resetForm }) => {
    if (isThreadClosed) {
      showToast({ content: 'Thread is closed', type: 'error' })
      return
    }
    try {
      if (!twilioConversation) throw new Error('Twilio client or conversation is not defined')

      const newMessageBuilder = twilioConversation
        .prepareMessage()
        .setBody(values.message?.trim())
        .setAttributes({
          firstName: currentUserData.firstName || '',
          isDataSubject: false, // hardcoded to `false` because message originates from figurehead
          lastName: currentUserData.lastName || '',
          orgCode: currentUserData.organizationCode,
          requestID: rightInvocationRequestId || '',
        })

      for (const [, file] of files.entries()) {
        const fileData = new FormData()
        fileData.set(file.name, file, file.name)
        newMessageBuilder.addMedia(fileData)
      }

      await newMessageBuilder.build().send()

      resetForm()
      setFiles([])
      showToast({ content: 'Message sent', type: 'success' })
    } catch (error) {
      showToast({ content: 'Message failed to send', type: 'error' })
      console.info('Error message', '\n', (error as Error)?.message)
      console.info('Error name', '\n', (error as Error)?.name, '\n', '\n')
      console.info('Error stack', '\n', (error as Error)?.stack, '\n')
    }
  }

  const onFilesChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files: assets } = event.target
    if (!assets?.length) {
      return
    }
    const newFiles = Array.from(assets).filter(file => {
      const { type, size } = file
      const isAcceptableType = Object.keys(acceptableFileTypes).includes(type)
      const isAcceptableSize = size <= MAX_FILE_SIZE
      if (!isAcceptableType) {
        showToast({ content: 'File type is not supported', type: 'error' })
      }
      if (!isAcceptableSize) {
        showToast({ content: `File size is too big. Max size is ${formatBytes(MAX_FILE_SIZE)}`, type: 'error' })
      }
      return isAcceptableType && isAcceptableSize
    })

    setFiles([...files, ...newFiles])
  }

  const validationSchema = useMemo(getComposeMessageValidationSchema, [])
  const { values, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: handleSendMessage,
    validationSchema,
  })

  useEffect(() => {
    setFiles([])
    setFilesInputKey(Date.now().toString())
  }, [twilioConversation])

  useEffect(() => {
    if (!files.length) {
      setFilesInputKey(Date.now().toString())
    }
  }, [files])

  const isSendEnabled = (values.message.length > 0 || files.length > 0) && !hasTwilioClientCatastrophicallyFailed
  const isPermittedToWrite = isPermitted(PERMISSIONS.SUBJECT_CHAT_WRITE)
  const acceptedFileTypesArray = Object.values(acceptableFileTypes)
    ?.flat()
    ?.map(el => el.replaceAll('.', ''))

  return {
    acceptedFileTypesArray,
    files,
    filesInputKey,
    handleChange,
    handleSubmit,
    isPermittedToWrite,
    isSendEnabled,
    isSubmitting,
    onFilesChange,
    setFiles,
    values,
  }
}
