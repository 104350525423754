import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  customSqlId: string
}>

export const deleteCustomSql = ({ customSqlId }: Params) => {
  return API.delete(
    formatRequestString({
      entityUrl: `/api/asset-manager/dsr-sql/${customSqlId}`,
    }),
  )
}
