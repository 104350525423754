import { AvatarSize, Chip, TableCell } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { useUser } from 'api/users/queries/useUser'
import { UserAvatar } from 'components/avatar/UserAvatar'
import { UserDTO } from 'interfaces/users/users'
import { getFullNameUserShortDTO } from 'utils/helpers/getFullNameUserShortDTO'
import { fromAssignee } from 'utils/helpers/teamStringParser'

export const AssigneeCellRenderer = ({ assigneeId }: { assigneeId: string }) => {
  const [, teamAssignee] = fromAssignee(assigneeId)

  const { data: assignee, isLoading: isAssigneeLoading } = useUser({
    params: { userId: teamAssignee },
  })

  let renderedUser = { ...assignee } as UserDTO
  if (!assignee?.firstName && !assignee?.lastName) {
    renderedUser.firstName = assignee?.email || ''
  }

  return (
    <TableCell>
      {assignee ? (
        <Box display="flex" alignItems="center" gap={0.5}>
          <UserAvatar size={AvatarSize.medium} user={renderedUser} />
          <Box
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: '200px',
            }}
          >
            <Typography>{getFullNameUserShortDTO(renderedUser)}</Typography>
          </Box>
        </Box>
      ) : (
        <Chip label={isAssigneeLoading ? 'Loading...' : 'Automated'} />
      )}
    </TableCell>
  )
}
