import { ProtectedRoute } from 'components/ui-routing/ProtectedRoute'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { relativePath } from 'utils/routing/relativePath'
import { DeveloperSandboxContainer } from './DeveloperSandboxContainer'
import { KetchTagDebug } from './ketchTagDebug/KetchTagDebug'
import {
  ButtonExamples,
  ModalImplementationExample,
  AutoCompleteSingleSelectExample,
  AutoCompleteMultiSelectExample,
  AutocompleteCompareContrast,
  SegmentTabsExample,
  TabsExample,
  DataGridPaginationExample,
  DataGridInfinityScrollExample,
  DataGridInfinityBrowserScrollExample,
  DataGridInfinityWaypointScrollExample,
  DataGridPartOfLayoutExample,
  DatePickerExample,
  DateRangePickerExample,
  InlineEditExample,
  StatusExample,
  AvatarExample,
  AutocompleteInlineEditExample,
  InfoRowExample,
  DropListButtonAsyncDataExample,
  DropListButtonExample,
  ProgressExample,
  DropzoneExample,
  DataGridCustomFiltersExample,
  ContentGroupPartOfLayoutExample,
  DataGridExpandableExample,
  BannerExample,
  CreateLayoutFormExample,
  EditLayoutFormExample,
  SettingsLayoutExample,
  ViewLayoutExample,
  DataGridFiltersExample,
  CardExamples,
  LabelChipExample,
  TextInputColorExample,
  DropZoneExampleDeck,
  MegaFilterExample,
  ChipsAutocompleteExample,
} from './implementationExamples'

export const developmentUtilsRoutesChildren = [
  {
    element: <ProtectedRoute component={DeveloperSandboxContainer} permissions={PERMISSIONS.DEMO} />,
    path: `${relativePath(RoutesManager.developmentUtils.sandbox.pattern)}`,
    children: [
      {
        element: <ProtectedRoute component={AutoCompleteSingleSelectExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.autoCompleteMenuSingleSelectExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={AutoCompleteMultiSelectExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.autoCompleteMenuMultiSelectExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={ModalImplementationExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.modalImplementationExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={ButtonExamples} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.buttons.pattern)}`,
      },
      {
        element: <ProtectedRoute component={AutocompleteCompareContrast} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.autocompleteCompareContrast.pattern)}`,
      },
      {
        element: <ProtectedRoute component={SegmentTabsExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.segmentTabsExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={TabsExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.tabsExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={DatePickerExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.datePickerExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={DateRangePickerExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.dateRangePickerExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={InlineEditExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.inlineEdit.pattern)}`,
      },
      {
        element: <ProtectedRoute component={AutocompleteInlineEditExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.autocompleteInlineEdit.pattern)}`,
      },
      {
        element: <ProtectedRoute component={StatusExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.status.pattern)}`,
      },
      {
        element: <ProtectedRoute component={AvatarExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.avatar.pattern)}`,
      },
      {
        element: <ProtectedRoute component={InfoRowExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.infoRow.pattern)}`,
      },
      {
        element: <ProtectedRoute component={DropListButtonAsyncDataExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.dropListButton.async.pattern)}`,
      },
      {
        element: <ProtectedRoute component={DropListButtonExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.dropListButton.pattern)}`,
      },
      {
        element: <ProtectedRoute component={ProgressExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.progress.pattern)}`,
      },
      {
        element: <ProtectedRoute component={DropzoneExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.dropzone.pattern)}`,
      },
      {
        element: <ProtectedRoute component={DataGridPaginationExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.dataGridPaginationExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={DataGridInfinityScrollExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.dataGridInfinityScrollExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={DataGridInfinityBrowserScrollExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.dataGridInfinityBrowserScrollExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={DataGridInfinityWaypointScrollExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.dataGridInfinityWaypointScrollExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={DataGridPartOfLayoutExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.dataGridPartOfLayoutExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={DataGridCustomFiltersExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.dataGridCustomFilters.pattern)}`,
      },
      {
        element: <ProtectedRoute component={ContentGroupPartOfLayoutExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.contentGroupPartOfLayoutExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={DataGridExpandableExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.dataGridExpandableExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={BannerExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.bannerExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={CreateLayoutFormExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.createLayoutFormExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={EditLayoutFormExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.editLayoutFormExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={SettingsLayoutExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.settingsLayoutExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={ViewLayoutExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.viewLayoutExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={DataGridFiltersExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.dataGridFilters.pattern)}`,
      },
      {
        element: <ProtectedRoute component={CardExamples} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.cardExamples.pattern)}`,
      },
      {
        element: <ProtectedRoute component={LabelChipExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.labelChipExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={TextInputColorExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.textInputColor.pattern)}`,
      },
      {
        element: <ProtectedRoute component={DropZoneExampleDeck} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.dropZoneExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={MegaFilterExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.megaFitlerExample.pattern)}`,
      },
      {
        element: <ProtectedRoute component={ChipsAutocompleteExample} permissions={PERMISSIONS.DEMO} />,
        path: `${relativePath(RoutesManager.developmentUtils.sandbox.chipsAutocompleteExample.pattern)}`,
      },
    ],
  },

  {
    element: <ProtectedRoute component={KetchTagDebug} permissions={PERMISSIONS.DEMO} />,
    path: `${relativePath(RoutesManager.developmentUtils.ketchTagDebugUrlBuilder.pattern)}`,
  },
]
