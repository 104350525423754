import { Box, Typography, styled } from '@mui/material'
import { getIn } from 'formik'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { useContext } from 'react'
import { RichTextRenderer } from '../../../../../../RichTextRenderer'
import { SectionEmptyState } from '../../../../../../SectionEmptyState'
import { SelectableSection } from '../../../../../../SelectableSection'
import { PreferenceExperienceBuilderSection } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'

const HeaderBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '11px',
  marginBottom: '24px',
})

export const RequestsHomeHeader: React.FC = () => {
  const { themeConfig, experienceConfig, isTheme } = useContext(BuilderContext)

  // Theme config
  const titleColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabHomeHeaderTitleColor)
  const descriptionColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabHomeHeaderDescriptionColor)
  const linkColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabHomeHeaderLinkColor)
  const linkUnderline = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabHomeHeaderLinkUnderline)

  // Experience config
  const titleVisible = getIn(experienceConfig, experienceUpsertFormKeys.preferenceRequestsTabHomeHeaderTitleVisible)
  const title = getIn(experienceConfig, experienceUpsertFormKeys.preferenceRequestsTabHomeHeaderTitle)
  const description = getIn(experienceConfig, experienceUpsertFormKeys.preferenceRequestsTabHomeHeaderDescription)

  return (
    <HeaderBox>
      <SelectableSection
        section={isTheme ? undefined : PreferenceExperienceBuilderSection.RequestsHomeHeaderTitle}
        overlayProps={{ margin: '12px 0px' }}
      >
        {titleVisible ? (
          <Box minHeight={'41px'}>
            <Typography color={titleColor} fontSize={'28px'} fontWeight={600}>
              {title}
            </Typography>
          </Box>
        ) : (
          <SectionEmptyState section="Page Title" hidden={isTheme} />
        )}
      </SelectableSection>
      <SelectableSection
        section={isTheme ? undefined : PreferenceExperienceBuilderSection.RequestsHomeHeaderDescription}
        overlayProps={{ margin: '4px 0px' }}
      >
        <RichTextRenderer
          text={description}
          textColor={descriptionColor}
          linkColor={linkColor}
          underlineLinks={linkUnderline}
        />
      </SelectableSection>
    </HeaderBox>
  )
}
