import React, { useCallback, useContext } from 'react'
import Box from '@mui/material/Box'
import { FormTemplateDTO, FormTemplateSectionDTO } from '@ketch-com/figurehead'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { styled } from '@mui/material/styles'
import { FieldArray, useFormikContext } from 'formik'
import { Banner, Button, Icon } from '@ketch-com/deck'
import { DraggableFormTemplateSectionItem } from './components'
import { FormTemplateUpsertContext } from 'pages/consentAndRights/forms/templates/upsert/context/FormTemplateUpsertContextProvider'

import { Theme } from '@mui/material'
import pluralize from 'pluralize'
import { FormTemplateUsedInExperiencesListModal } from 'pages/consentAndRights/forms/components/FormTemplateUsedInExperiencesListModal'
import { getFormTemplateDeployedExperiences } from '../../utils'

const Container = styled(Box)(({ theme }: { theme?: Theme }) => ({
  borderRadius: '11px',
  padding: theme?.spacing(2, 2, 5, 2),
  width: '100%',
  backgroundColor: theme?.palette.white.main,
}))

export const reorderSections = (list: FormTemplateSectionDTO[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

type Props = {}

export const TemplateSections: React.FC<Props> = props => {
  const { values, setFieldValue } = useFormikContext<FormTemplateDTO>()
  const {
    setActiveSectionIndex,
    activeSectionIndex,
    generateEmptyFormTemplateSection,
    enabledLanguages,
    formTemplate,
    setIsFormTemplateUsedInExperiencesModalOpen,
    isFormTemplateUsedInExperiencesModalOpen,
  } = useContext(FormTemplateUpsertContext)

  const onDragEnd = useCallback(
    (result: DropResult) => {
      // handle item dropped outside the list area
      if (!result.destination) return
      const startIndex = result.source.index
      const endIndex = result.destination.index
      const newItems = reorderSections(values?.sections || [], startIndex, endIndex)

      if (typeof activeSectionIndex === 'number') {
        if (activeSectionIndex === startIndex) {
          setActiveSectionIndex(endIndex)
        } else if (activeSectionIndex > startIndex && activeSectionIndex <= endIndex) {
          setActiveSectionIndex(activeSectionIndex - 1)
        } else if (activeSectionIndex < startIndex && activeSectionIndex >= endIndex) {
          setActiveSectionIndex(activeSectionIndex + 1)
        }
      }
      setFieldValue('sections', newItems)
    },
    [setFieldValue, values?.sections, activeSectionIndex, setActiveSectionIndex],
  )

  return (
    <Box minWidth={856} maxWidth={856} width={856}>
      {(getFormTemplateDeployedExperiences(formTemplate)?.length || 0) > 0 && (
        <Box display="flex" flexDirection="column" mb={1.5}>
          <Banner
            sx={{
              width: '100%',
            }}
            title={`This form is used in ${
              getFormTemplateDeployedExperiences(formTemplate).length
            } deployed ${pluralize(
              'Experience',
              getFormTemplateDeployedExperiences(formTemplate).length,
            )}. All changes will take
            effect immediately after saving.`}
            action={
              <Button variant="link" onClick={() => setIsFormTemplateUsedInExperiencesModalOpen(true)}>
                View List
              </Button>
            }
            severity="warning"
          />
          {isFormTemplateUsedInExperiencesModalOpen && (
            <FormTemplateUsedInExperiencesListModal
              formTemplate={formTemplate}
              onClose={() => setIsFormTemplateUsedInExperiencesModalOpen(false)}
            />
          )}
        </Box>
      )}

      <Container>
        {/* Template Sections */}

        <FieldArray
          name="sections"
          render={({ push, remove }) => (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {provided => {
                  const { innerRef, droppableProps } = provided
                  return (
                    <Box {...droppableProps} ref={innerRef}>
                      {(values?.sections || []).map((section, sectionIndex) => {
                        const isDragDisabled = (values?.sections || [])?.length === 1

                        return (
                          <Draggable
                            key={`section-${sectionIndex}`}
                            draggableId={`section-${sectionIndex}`}
                            index={sectionIndex}
                            isDragDisabled={isDragDisabled}
                          >
                            {(provided, snapshot) => (
                              <DraggableFormTemplateSectionItem
                                key={`section-${sectionIndex}`}
                                sectionIndex={sectionIndex}
                                provided={provided}
                                snapshot={snapshot}
                                formTemplateSection={section}
                              />
                            )}
                          </Draggable>
                        )
                      })}

                      {provided.placeholder}

                      {/* Add Section Button */}

                      <Box display="flex" alignItems="center" px={3} flex={1}>
                        <Button
                          sx={{
                            width: '100%',
                          }}
                          size="large"
                          color="secondary"
                          onClick={() =>
                            push(
                              generateEmptyFormTemplateSection({
                                enabledLanguages,
                              }),
                            )
                          }
                          startIcon={<Icon name="OPlus" />}
                        >
                          Add Section
                        </Button>
                      </Box>
                    </Box>
                  )
                }}
              </Droppable>
            </DragDropContext>
          )}
        />
      </Container>
    </Box>
  )
}
