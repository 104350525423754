import React, { useContext } from 'react'

import { EditFormTemplate, CreateFormTemplate } from './components'
import { useRedirectIfNotEntitledToExperienceServerRights } from 'pages/consentAndRights/forms/hooks'
import { useIsEditMode } from 'utils/hooks'
import { FormTemplateUpsertContext, withFormTemplateUpsertContext } from './context/FormTemplateUpsertContextProvider'
import { Spinner } from '@ketch-com/deck'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Box } from '@mui/material'

const FormTemplateUpsertContainerWithoutContext: React.FC = () => {
  const { isReady, breadcrumbs } = useContext(FormTemplateUpsertContext)
  const isEditMode = useIsEditMode()

  useRedirectIfNotEntitledToExperienceServerRights()

  if (!isReady)
    return (
      <Box display="flex" justifyContent="center" flexDirection="column" flex={1}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs.filter(b => !!b?.title)} />

      {isEditMode ? <EditFormTemplate /> : <CreateFormTemplate />}
    </>
  )
}

export const FormTemplateUpsertContainer = withFormTemplateUpsertContext(FormTemplateUpsertContainerWithoutContext)
