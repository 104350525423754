import * as Yup from 'yup'

export const getValidationSchema = () =>
  Yup.object().shape({
    testMode: Yup.boolean(),
    headers: Yup.array().when('testMode', {
      is: false,
      then: Yup.array()
        .of(
          Yup.object().shape({
            key: Yup.string().required('Required'),
            value: Yup.string().required('Required'),
          }),
        )
        .min(1, 'Must have at least 1 header'),
      otherwise: Yup.array(), // No validation when testMode is true
    }),
    name: Yup.string().required('Required'),
    events: Yup.object()
      .shape({})
      .test({
        name: 'atLeastOneEventIsRequired',
        test: (parent, { createError }) => {
          const isValid = Object.keys(parent).some(event => parent[event])
          if (isValid) return true
          return createError({
            path: 'events',
            message: 'Required',
          })
        },
      }),
    url: Yup.string().when('testMode', {
      is: false,
      then: Yup.string().url().required('Required'),
    }),
  })
