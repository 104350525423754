import { useContext, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

// api
import { useFilesInfoList } from 'api/files/queries/useFilesInfoList'

// components
import { RoutesManager } from 'utils/routing/routesManager'
import { showToast } from 'components/modals/AlertComponent'

// utils
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'

// types
import { RightInvocationStatusDTO } from '@ketch-com/figurehead'
import { getTaskViewValidationSchema, useInitialTaskViewValues } from '../utils'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useCompleteWorkflowExecutionStep } from 'api/workflowExecutions/mutations/useCompleteWorkflowExecutionStep'
import { useQueryClient } from 'react-query'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'

export const useTaskViewUtils = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { id: rightInvocationId } = useParams<{ id?: string }>()
  const [searchParams, setSearchParams] = useSearchParams()

  const {
    isReady: isRightsQueueWorkflowTaskDetailViewContainerUtilsReady,
    rightInvocation,
    stepId,
    workflowExecutionId,
    workflowStep,
    stepDetails,
    isRightsQueueAdmin,
    isTaskComplete,
    isAssignedToMe,
    workflowExecution,
  } = useContext(RightInvocationWorkflowStepDetailViewContext)

  // TODO:GP remove below calls once BE is updated
  // NOTE: this will require replacing all instances of FileInfoDTO with the new generated type
  const { data: resolutionAttachments, isLoading: isLoadingResolutionAttachments } = useFilesInfoList({
    params: {
      version: 'resolution_attachments',
      folder: `${rightInvocation?.id}_${stepId}`,
    },
  })

  const { data: dsrResolutionAttachments, isLoading: isLoadingDSRResolutionAttachments } = useFilesInfoList({
    params: {
      version: rightInvocation?.id || '',
      folder: stepId!,
    },
  })

  const { mutateAsync: handleCompleteWorkflowExecutionStep, isLoading: isCompleteWorkflowExecutionStepLoading } =
    useCompleteWorkflowExecutionStep({
      onSuccess: async () => {
        await queryClient.refetchQueries([ApiQueryKeys.workflowExecutionStepDetailsV2, { workflowExecutionId, stepId }])
        await queryClient.refetchQueries([ApiQueryKeys.workflowExecution, { workflowExecutionId }])
        await queryClient.refetchQueries([ApiQueryKeys.workflowExecutionV2, { workflowExecutionId }])
        showToast({ content: 'Task was successfully resolved', type: 'success' })
        navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocation?.id }))
      },
      onError: () => {
        showToast({ content: 'Failed to approve activity', type: 'error' })
      },
    })

  /* Check for resolution requirements  */

  const isMissingRequiredResolutionNotes =
    workflowStep?.activity?.params?.isResolutionNotesRequired && !stepDetails?.manualInterventionData?.resolutionDetails

  const isMissingRequiredDSRVariables =
    (stepDetails?.dataSubjectContext
      ?.filter(dsr => dsr?.isRequired)
      ?.filter(dsr => dsr?.isConfiguredForStep)
      ?.filter(dsr => (dsr?.value === false ? false : !dsr?.value))
      .filter(Boolean)?.length || 0) > 0

  const isMissingRequiredOutcomeVariables =
    (stepDetails?.outcomeContext
      ?.filter(dsr => dsr?.isRequired)
      .filter(dsr => (dsr?.value === false ? false : !dsr?.value))?.length || 0) > 0

  const isMissingRequiredResolutionAttachments =
    !stepDetails?.manualInterventionData?.resolutionAttachments?.length &&
    !!workflowStep?.activity?.params?.isResolutionAttachmentsRequired

  const isMissingRequiredAttachmentsForDataSubject =
    !stepDetails?.manualInterventionData?.dsrResolutionAttachments?.length &&
    !!workflowStep?.activity?.params?.isDsrResolutionAttachmentsRequired

  const isMissingRequiredIdentitySpaces =
    !!workflowStep?.activity?.params?.hasIdentitySpaces &&
    (stepDetails?.stepIdentitySpaces || [])
      .filter(({ isRequired }) => !!isRequired)
      .filter(({ value }) => (value || '').trim().length < 1).length > 0

  const shouldPreventResolve =
    isMissingRequiredResolutionNotes ||
    isMissingRequiredDSRVariables ||
    isMissingRequiredOutcomeVariables ||
    isMissingRequiredResolutionAttachments ||
    isMissingRequiredAttachmentsForDataSubject ||
    isMissingRequiredIdentitySpaces

  logObjectDetails(
    {
      isMissingRequiredResolutionNotes,
      isMissingRequiredDSRVariables,
      isMissingRequiredOutcomeVariables,
      isMissingRequiredResolutionAttachments,
      isMissingRequiredAttachmentsForDataSubject,
      isMissingRequiredIdentitySpaces,
      shouldPreventResolve,
    },
    'useTaskViewUtils.ts',
    'white',
    false, // false ensures will log only in debug mode (i.e. ?debug=log)
  )

  const resolve = async () => {
    if (shouldPreventResolve) {
      const debugValue = searchParams.get('debug')
      const newSearchParams = new URLSearchParams()
      if (debugValue) newSearchParams.set('debug', debugValue)
      newSearchParams.set('hasAttemptedToResolve', 'true')
      setSearchParams(newSearchParams.toString())
      return
    }

    setSearchParams({})

    await handleCompleteWorkflowExecutionStep({
      params: {
        workflowExecutionId: workflowExecutionId!,
        stepId: stepId!,
        formData: {
          results: {
            decisionBoolean: stepDetails?.manualInterventionData?.decisionBoolean,
            resolutionDetails: stepDetails?.manualInterventionData?.resolutionDetails,
            dsrResolutionAttachments: stepDetails?.manualInterventionData?.dsrResolutionAttachments
              ?.map(f => f.infoUrl)
              .filter(s => s !== undefined) as string[],
            resolutionAttachments: stepDetails?.manualInterventionData?.resolutionAttachments
              ?.map(f => f.infoUrl)
              .filter(s => s !== undefined) as string[],
          },
        },
      },
    })
  }

  // component utils
  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    { title: 'Rights Queue', link: RoutesManager.orchestration.rightsQueue.root.getURL() },
    {
      title: rightInvocation?.policyScopeName,
      link: RoutesManager.orchestration.rightsQueue.view.root.getURL({ id: rightInvocationId }),
    },
    {
      title: 'Task',
      link: RoutesManager.orchestration.rightsQueue.stepDetails.root.getURL({
        id: rightInvocationId,
        stepId,
        workflowExecutionId,
      }),
    },
  ]
  const isWorkflowComplete = rightInvocation?.status === RightInvocationStatusDTO.FulfilledRightInvocationStatus
  const isWorkflowExecutionTerminated = workflowExecution?.status === WorkflowExecutionStepStatus.TERMINATED
  const canReassign = isRightsQueueAdmin && !isTaskComplete && !isWorkflowExecutionTerminated
  const canEdit = isWorkflowExecutionTerminated
    ? false
    : isTaskComplete
    ? isRightsQueueAdmin || isAssignedToMe
    : isAssignedToMe

  const [isEditMode, setEditMode] = useState(false)

  // form utils
  const validationSchema = useMemo(
    () =>
      getTaskViewValidationSchema({
        workflowStep: workflowStep || undefined,
      }),
    [workflowStep],
  )
  const initialValues = useInitialTaskViewValues(stepDetails || undefined)

  const hasRequiredOutcomeVariables = useMemo(() => {
    const { outcomeContext } = stepDetails

    return !!outcomeContext?.filter(o => o.isRequired && o.value === undefined)?.length
  }, [stepDetails])

  const isReady =
    isRightsQueueWorkflowTaskDetailViewContainerUtilsReady &&
    !isLoadingResolutionAttachments &&
    !isLoadingDSRResolutionAttachments

  const payload = {
    breadcrumbs,
    canEdit,
    canReassign,
    dsrResolutionAttachments,
    hasRequiredOutcomeVariables,
    initialValues,
    isCompleteWorkflowExecutionStepLoading,
    isEditMode,
    isReady,
    isWorkflowComplete,
    isWorkflowExecutionTerminated,
    resolutionAttachments,
    resolve,
    rightInvocationId,
    setEditMode,
    stepDetails,
    validationSchema,
    workflowStep,
    workflowExecution,
  }

  logObjectDetails(
    {
      payload,
    },
    'useTaskViewUtils.ts',
    'white',
    false, // false ensures will log only in debug mode (i.e. ?debug=log)
  )

  return payload
}

export type UseTaskViewUtilsReturnType = ReturnType<typeof useTaskViewUtils>
