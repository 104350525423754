import { faker } from '@faker-js/faker'
import { getTeamMemberSpecificData } from './ratioWidgetDummyData'
import { generateTeamMemberQueriesGraphData } from './teamMemberQueriesGraphData'

export type TeamMemberDTO = { name: string; count: number; id: string }

export const generateDummyTeamMembers = (number: number): TeamMemberDTO[] => {
  const names = []
  for (let i = 0; i < number; i++) {
    names.push({
      id: faker.datatype.uuid(),
      name: `${faker.name.firstName()} ${faker.name.lastName()}`,
      count: faker.datatype.number({ min: 100, max: 63000 }),
      userActivityGraphStaticData: generateTeamMemberQueriesGraphData(25),
      dataUsageGraphStaticData: generateTeamMemberQueriesGraphData(25),
      accessUtilizationGraphStaticData: getTeamMemberSpecificData(),
    })
  }
  return names
}
// prefer static list for coherence between pages. Generated with generateDummyTeamMembers()
export const teamMembers = [
  {
    id: '4f2a47ea-a0d8-422a-be3b-b3c09c64e8e3',
    name: 'Amya Sauer',
    count: 49410,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 70302,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 18533,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 31338,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 39599,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 73513,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 980,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 21214,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 50227,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 70066,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 2027,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 2968,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 38101,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 85567,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 68412,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 24809,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 28614,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 32718,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 6865,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 39157,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 54143,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 80527,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 13079,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 80045,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 63516,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 32492,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 38447,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 19649,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 35458,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 73011,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 36071,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 79169,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 21553,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 86779,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 63637,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 15548,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 3543,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 2040,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 34254,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 84117,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 33994,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 25307,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 36421,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 26379,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 64517,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 25958,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 23606,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 53484,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 62217,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 1915,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 78309,
      },
    ],
  },
  {
    id: '296d7f43-0b4f-4b2d-bac2-422a7acfa297',
    name: 'Izabella Fritsch',
    count: 19952,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 86078,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 50408,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 56504,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 52475,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 85314,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 3646,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 89704,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 69002,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 61587,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 42916,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 44982,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 62069,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 20041,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 75989,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 69642,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 15175,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 26633,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 46838,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 31995,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 7323,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 6568,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 22750,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 22615,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 42725,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 40815,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 25749,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 1453,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 70250,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 12557,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 37880,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 27473,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 33439,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 88097,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 28340,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 42446,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 62232,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 17871,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 80824,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 27309,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 12972,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 59456,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 30657,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 68728,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 22808,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 51223,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 83300,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 36155,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 54226,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 21542,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 42096,
      },
    ],
  },
  {
    id: '1acecdfa-5238-42e7-b756-32b9572c1585',
    name: 'Pink Maggio',
    count: 14992,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 27258,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 8045,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 12775,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 82618,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 85745,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 54634,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 86387,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 8624,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 15196,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 50351,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 8131,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 72125,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 71311,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 4851,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 14583,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 52349,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 54079,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 76267,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 26755,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 54822,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 16061,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 81295,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 75436,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 22028,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 17509,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 29374,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 17805,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 27115,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 23146,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 31662,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 1192,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 87014,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 67101,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 29700,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 63414,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 17901,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 39974,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 79217,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 74148,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 24405,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 40749,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 15076,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 68107,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 81894,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 30810,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 44806,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 72414,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 41923,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 27423,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 1188,
      },
    ],
  },
  {
    id: '0302d5c4-bf25-4e0b-ba3e-1f51e2c9e501',
    name: 'Brandy Dooley',
    count: 57737,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 39006,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 39968,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 58063,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 53496,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 11909,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 23270,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 87861,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 66098,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 38634,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 6609,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 30833,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 23039,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 47443,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 49114,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 80518,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 85414,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 58392,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 24652,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 24579,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 30531,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 52033,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 48411,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 59518,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 85974,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 86118,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 3029,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 72486,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 61336,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 65159,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 52451,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 11440,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 29843,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 40019,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 27149,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 36424,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 7923,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 80404,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 3505,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 17506,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 68028,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 84978,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 38412,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 44591,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 78633,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 32548,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 26135,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 58899,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 54526,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 43548,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 44944,
      },
    ],
  },
  {
    id: '9c14a6b9-9482-44da-a197-bd7e4ddd78bf',
    name: 'Vivien Abernathy',
    count: 53668,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 20808,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 76052,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 86509,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 1858,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 74724,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 4211,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 21123,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 53184,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 53053,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 78088,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 47490,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 17326,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 29774,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 76964,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 34770,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 68529,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 72327,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 25080,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 60373,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 29245,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 78468,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 41342,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 72222,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 60360,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 62966,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 20077,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 67417,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 73889,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 57994,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 70234,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 53714,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 76410,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 43828,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 84653,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 62892,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 15043,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 84120,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 43596,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 63097,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 80919,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 18053,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 14553,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 48239,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 51981,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 38597,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 16121,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 84722,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 56877,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 25787,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 45656,
      },
    ],
  },
  {
    id: '955ea85b-1244-4072-959a-9ac6e5093d21',
    name: 'Shanel Schimmel',
    count: 17650,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 14705,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 47214,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 17939,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 12352,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 89293,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 72974,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 53615,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 65037,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 81939,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 52919,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 6560,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 5576,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 15006,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 31180,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 16164,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 68666,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 61213,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 9567,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 26658,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 83064,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 61244,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 66877,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 30325,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 43722,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 19920,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 10276,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 63394,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 41049,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 39320,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 49394,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 65033,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 65835,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 71975,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 56275,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 36062,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 46585,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 38151,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 3136,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 71010,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 6178,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 14200,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 8215,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 44336,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 84247,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 68277,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 31052,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 69034,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 75508,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 41515,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 2166,
      },
    ],
  },
  {
    id: 'c8db5b57-848d-4532-9479-6af42200f54a',
    name: 'Angie Weissnat',
    count: 10990,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 39915,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 52113,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 65765,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 89912,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 85002,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 87390,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 41622,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 73311,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 60151,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 37391,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 54768,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 46875,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 61565,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 40531,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 68576,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 58161,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 48924,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 72375,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 25060,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 10923,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 77693,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 74555,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 79882,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 68138,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 47955,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 60434,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 80889,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 55326,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 64427,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 28899,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 53180,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 57614,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 12098,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 79865,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 34213,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 69826,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 46611,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 22569,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 80155,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 85337,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 20386,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 87336,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 12053,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 12861,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 53048,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 73354,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 87934,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 71970,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 10566,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 76966,
      },
    ],
  },
  {
    id: '69750a0d-8f1a-42b5-be63-c90a2311fb31',
    name: 'Tessie Roob',
    count: 13860,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 78888,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 60552,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 22952,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 48047,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 4997,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 85768,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 40012,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 60295,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 33605,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 27444,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 8250,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 86791,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 33149,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 76480,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 70508,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 78576,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 9882,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 89887,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 4086,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 13020,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 14519,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 8982,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 55405,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 88877,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 41777,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 21529,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 79506,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 26636,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 56959,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 68245,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 11140,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 85095,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 80465,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 60398,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 6926,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 12805,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 80206,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 79626,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 69074,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 21842,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 55607,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 57581,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 11386,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 24020,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 77680,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 82310,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 57682,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 62634,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 70842,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 2244,
      },
    ],
  },
  {
    id: 'd7684fc2-9399-4d2d-acec-a4840a0012b8',
    name: 'Jeremy Casper',
    count: 52333,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 25450,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 67946,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 28686,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 58625,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 59070,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 59547,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 32035,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 16255,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 55664,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 66291,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 68738,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 85747,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 51221,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 4734,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 37258,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 39494,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 3595,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 54676,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 9134,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 37700,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 6134,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 81772,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 58114,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 57969,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 12564,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 38376,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 5493,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 73641,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 26879,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 60160,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 8970,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 37606,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 44211,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 2703,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 88300,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 77982,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 55980,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 68087,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 78327,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 45897,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 16783,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 52324,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 15156,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 66002,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 14960,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 34493,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 58644,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 64132,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 39399,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 89597,
      },
    ],
  },
  {
    id: 'dfe07f0e-cde3-4666-a5d8-c65a4632cac4',
    name: "Zoila O'Connell",
    count: 43444,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 21941,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 34304,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 80473,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 83532,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 79243,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 3015,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 28367,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 17201,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 60235,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 53765,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 7643,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 26030,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 36982,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 74192,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 56805,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 84041,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 77704,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 8277,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 31480,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 37968,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 29428,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 81701,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 44454,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 17411,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 1517,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 26869,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 84424,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 30244,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 75356,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 30495,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 29951,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 81384,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 4365,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 76665,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 28483,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 11887,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 20785,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 24487,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 15390,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 62987,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 15201,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 10243,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 89604,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 27248,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 63245,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 44750,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 27366,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 54784,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 68298,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 75593,
      },
    ],
  },
  {
    id: 'e93c051e-f002-4365-ab75-c6868d708169',
    name: 'Cleta Beer',
    count: 22316,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 87030,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 19980,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 57763,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 86125,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 43171,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 79705,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 47493,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 30387,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 63118,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 77719,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 11643,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 65613,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 87620,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 26654,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 88193,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 28543,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 28603,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 48128,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 52815,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 78498,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 57913,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 61123,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 27489,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 7927,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 78850,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 4704,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 23919,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 33029,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 51669,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 43806,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 25468,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 46056,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 16531,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 46354,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 74628,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 15316,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 63245,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 62603,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 11818,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 23173,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 89590,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 77934,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 75468,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 62935,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 60822,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 20890,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 63390,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 78790,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 46386,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 89872,
      },
    ],
  },
  {
    id: 'd1a05dc9-9ec9-4b4d-b15d-11b15778ebf4',
    name: 'Eula VonRueden',
    count: 60840,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 41387,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 5804,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 57458,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 40899,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 11332,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 51269,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 60873,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 73585,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 67960,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 23965,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 58100,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 28365,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 73798,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 31895,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 24165,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 12925,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 60379,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 84326,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 47696,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 62060,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 17964,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 16410,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 27080,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 64490,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 52251,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 69729,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 5089,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 60774,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 72106,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 20069,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 9188,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 28182,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 9683,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 77905,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 26827,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 38179,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 3303,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 58376,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 53967,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 72577,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 63933,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 62508,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 35546,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 34178,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 29925,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 11616,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 56259,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 68870,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 9487,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 37754,
      },
    ],
  },
  {
    id: 'e6b0ac62-595e-4320-add9-8f6a4ca54517',
    name: 'Madelyn Cremin',
    count: 45849,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 88453,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 66635,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 47621,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 55835,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 7981,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 6916,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 54370,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 58569,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 55280,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 40664,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 14101,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 84717,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 82374,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 17578,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 47874,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 63074,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 39134,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 6769,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 77103,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 78188,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 64257,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 13553,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 61930,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 62196,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 88239,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 54184,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 89600,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 36219,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 19482,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 47169,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 44167,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 79512,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 63321,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 87916,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 35233,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 32888,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 73450,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 66760,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 45406,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 72262,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 35778,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 8774,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 54533,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 24615,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 8760,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 9681,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 18239,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 26745,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 25756,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 78148,
      },
    ],
  },
  {
    id: '86362d30-c5ff-4249-b19e-ffd5a931af2b',
    name: 'Caroline Paucek',
    count: 20158,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 40647,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 23065,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 30129,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 43388,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 88309,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 70822,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 28718,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 24777,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 58874,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 9101,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 5286,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 23749,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 9648,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 31777,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 60504,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 21615,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 3640,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 41121,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 33995,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 29091,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 36130,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 14697,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 63358,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 63284,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 38001,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 33446,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 52250,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 5389,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 42497,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 81533,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 35083,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 3573,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 78513,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 27557,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 36247,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 85205,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 28647,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 7048,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 86943,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 71968,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 14555,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 29891,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 88624,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 22248,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 70778,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 11030,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 2778,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 81679,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 25740,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 29961,
      },
    ],
  },
  {
    id: '0d1cf121-62ec-418d-84f7-20574ea89574',
    name: 'Cleve Gutmann',
    count: 20036,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 37977,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 39673,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 1721,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 68779,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 48722,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 40226,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 22132,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 83237,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 79609,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 87247,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 84612,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 47128,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 56399,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 39576,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 13679,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 1795,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 33405,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 22436,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 75018,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 19768,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 73213,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 43649,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 4167,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 87974,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 41752,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 11105,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 24463,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 33681,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 38969,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 64325,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 33578,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 49615,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 50174,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 7543,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 86376,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 11081,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 35667,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 46462,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 19359,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 34177,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 41834,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 44730,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 15657,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 11081,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 38024,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 1696,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 20821,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 16362,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 74205,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 63647,
      },
    ],
  },
  {
    id: '179269e6-6411-4b3f-b5ff-af0f8dd2c0a7',
    name: 'Vincent Dibbert',
    count: 44189,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 51841,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 6287,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 11875,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 31040,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 5341,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 64540,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 52378,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 25290,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 69516,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 16866,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 47324,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 2576,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 61390,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 48149,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 66809,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 65130,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 69714,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 7451,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 34518,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 11587,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 57521,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 4612,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 37890,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 82359,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 17608,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 13207,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 9658,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 4875,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 63426,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 66353,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 5880,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 48735,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 17168,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 84376,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 28147,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 76826,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 23649,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 3023,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 36390,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 86193,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 17696,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 41150,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 33050,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 35913,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 7749,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 43691,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 72615,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 52268,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 78763,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 46852,
      },
    ],
  },
  {
    id: 'bf0c243d-69d5-4046-a5a0-866fca87afad',
    name: 'Verner Becker',
    count: 18042,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 61190,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 83602,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 62662,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 49393,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 40257,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 63022,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 81345,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 50575,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 5809,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 23407,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 57462,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 49151,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 66392,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 84407,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 86278,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 2610,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 17233,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 32843,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 31106,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 18319,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 34181,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 49717,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 56548,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 82191,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 31998,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 37769,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 52288,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 47751,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 60419,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 48888,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 57858,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 47568,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 1892,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 31584,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 51544,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 70781,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 22262,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 2973,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 56827,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 74038,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 26778,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 74884,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 45108,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 88528,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 4264,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 34039,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 68204,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 85849,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 17595,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 26094,
      },
    ],
  },
  {
    id: '5b53e49a-4f7b-4b09-aa84-390212452e31',
    name: 'Rebeka Zemlak',
    count: 10061,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 76016,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 20715,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 34880,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 72227,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 25423,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 56213,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 6406,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 86587,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 83431,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 7351,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 1429,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 37728,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 15859,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 3801,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 40872,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 65314,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 2331,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 75197,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 57542,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 44456,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 60196,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 70775,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 9145,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 4782,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 61602,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 76363,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 9380,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 7585,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 87812,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 14682,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 61876,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 26286,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 6156,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 28182,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 7204,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 78250,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 60553,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 26933,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 4572,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 79337,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 46396,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 80382,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 71156,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 7503,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 44028,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 65970,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 33675,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 70904,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 85277,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 15289,
      },
    ],
  },
  {
    id: 'afeff964-4280-4476-8cc4-d45d23567151',
    name: 'Tremaine Hettinger',
    count: 6941,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 13335,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 22363,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 63722,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 61034,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 67878,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 79369,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 65908,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 32946,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 20740,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 83742,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 36168,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 76781,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 9237,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 8727,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 70649,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 14967,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 43120,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 8982,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 40419,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 21119,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 32418,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 49899,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 20144,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 31775,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 14921,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 58747,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 21629,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 83520,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 63490,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 64107,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 73487,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 89119,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 37705,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 65758,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 42108,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 12785,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 86994,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 23124,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 61350,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 40606,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 42749,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 5405,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 27690,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 83699,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 61850,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 85878,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 58276,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 19890,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 21017,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 50550,
      },
    ],
  },
  {
    id: '055ba589-274c-4260-a58a-bee109e3b4b2',
    name: 'Jackeline Upton',
    count: 49247,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 71077,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 30544,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 73997,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 29662,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 33042,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 73449,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 76445,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 50931,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 17505,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 87880,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 58761,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 19647,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 70500,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 74787,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 74386,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 42407,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 12267,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 40594,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 19990,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 40643,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 68533,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 78871,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 55568,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 84971,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 63361,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 65380,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 70234,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 14273,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 42130,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 89548,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 57027,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 47593,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 52231,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 56891,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 58876,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 56333,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 37052,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 19226,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 38801,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 86407,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 79184,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 11495,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 28390,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 88716,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 22462,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 65278,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 42825,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 22127,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 49193,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 46496,
      },
    ],
  },
  {
    id: 'c0ad865b-6238-4050-bf49-85ef4ccf8c9a',
    name: 'Donnie Senger',
    count: 29949,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 71260,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 86009,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 8679,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 19095,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 89037,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 20616,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 17676,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 45820,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 54615,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 81631,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 10610,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 50734,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 47841,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 39565,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 30625,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 51118,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 64161,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 52558,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 27106,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 50752,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 4901,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 12938,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 37468,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 2444,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 3654,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 74214,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 44823,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 74908,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 10559,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 38245,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 44454,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 36122,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 27745,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 9997,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 39520,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 54089,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 56800,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 82619,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 24697,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 54086,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 26985,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 58336,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 50782,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 53268,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 16110,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 25914,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 55769,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 14614,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 88389,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 32606,
      },
    ],
  },
  {
    id: '764e4a10-cc43-4402-9d6d-86dfb7633154',
    name: 'Yesenia Paucek',
    count: 31305,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 54348,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 71473,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 48371,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 53721,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 40562,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 15482,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 49484,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 78070,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 69092,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 13962,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 21523,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 72666,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 50953,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 41972,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 78361,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 41352,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 57565,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 29569,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 62205,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 16323,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 13766,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 67868,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 17970,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 41401,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 22206,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 36396,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 30782,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 59921,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 5439,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 14631,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 60768,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 62694,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 8292,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 51801,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 14407,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 5756,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 5642,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 86536,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 61224,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 86686,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 50868,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 29904,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 10731,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 37862,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 61023,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 4323,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 34907,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 36682,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 24197,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 37534,
      },
    ],
  },
  {
    id: '3895c985-0ab8-4445-9ff3-8c96ec126c64',
    name: 'Maverick Bashirian',
    count: 5555,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 42786,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 25599,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 18754,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 58667,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 23775,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 35273,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 62057,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 20949,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 77293,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 54904,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 12947,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 78214,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 84562,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 69324,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 50666,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 57412,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 86582,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 9863,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 3489,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 60668,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 72721,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 65116,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 25840,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 8915,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 18002,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 9924,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 80712,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 36213,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 65725,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 42660,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 28048,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 22876,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 85017,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 8775,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 41906,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 25812,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 2434,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 44048,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 1424,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 29333,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 40361,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 38034,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 89258,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 81878,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 56765,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 87489,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 35322,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 40293,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 58674,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 67885,
      },
    ],
  },
  {
    id: '5e76c840-d462-4803-876c-3eb95014494f',
    name: 'Damaris Brakus',
    count: 41115,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 82238,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 16453,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 68623,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 67269,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 62753,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 50004,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 53191,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 42697,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 55719,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 9584,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 69423,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 7255,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 32306,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 34456,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 37346,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 50744,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 37192,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 56244,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 994,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 4021,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 48543,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 10402,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 61243,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 77218,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 8581,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 8177,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 58123,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 61177,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 31386,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 86324,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 64561,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 81076,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 58023,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 75981,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 87429,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 45084,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 7290,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 80702,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 22512,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 65789,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 41423,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 19461,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 21480,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 70888,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 67119,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 969,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 4025,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 19971,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 67230,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 35366,
      },
    ],
  },
  {
    id: 'ef365b36-7bb5-406a-a700-a4d6df9c87d5',
    name: 'Abigail Hoeger',
    count: 33170,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 60419,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 23539,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 11456,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 21481,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 61967,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 14939,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 28632,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 17392,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 88129,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 55399,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 10376,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 57019,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 52571,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 73683,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 2887,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 26184,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 1635,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 16204,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 76243,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 38591,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 87710,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 78287,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 8062,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 80184,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 27562,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 35774,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 43668,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 5156,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 26078,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 3388,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 81920,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 54670,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 81509,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 52868,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 81163,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 20948,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 24769,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 85751,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 5338,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 24693,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 72313,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 44388,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 26304,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 17387,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 10146,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 71436,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 89538,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 16873,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 81377,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 30382,
      },
    ],
  },
  {
    id: 'af81f41c-091e-4ca5-ae19-56da2375b132',
    name: 'Margarette Douglas',
    count: 34251,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 49070,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 38592,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 11486,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 89003,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 49128,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 62819,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 57190,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 76536,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 49399,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 62534,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 85288,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 54631,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 42934,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 48482,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 84599,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 45540,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 27723,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 24185,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 23620,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 38194,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 7286,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 2766,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 10320,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 29198,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 6834,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 36290,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 46112,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 49180,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 25569,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 15048,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 52696,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 25101,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 89367,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 5192,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 69982,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 14298,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 64372,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 23447,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 61854,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 42272,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 84218,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 30864,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 83985,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 45613,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 84086,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 20516,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 26410,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 4238,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 29988,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 12967,
      },
    ],
  },
  {
    id: '6bf2a913-b758-4220-99d3-69e0706bd849',
    name: 'Royce Schoen',
    count: 55917,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 77891,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 32657,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 44398,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 12912,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 39096,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 41952,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 14635,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 85765,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 84707,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 18248,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 40419,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 56244,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 63422,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 61470,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 71308,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 18904,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 38664,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 78682,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 40584,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 78368,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 42732,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 40848,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 16512,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 7472,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 24852,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 6628,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 60795,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 58995,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 62513,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 55645,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 46188,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 38711,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 49865,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 33676,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 61892,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 47295,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 89087,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 86952,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 21682,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 81116,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 38669,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 54361,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 7499,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 26618,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 85602,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 17754,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 54470,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 66611,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 2607,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 35451,
      },
    ],
  },
  {
    id: 'd25e80b9-f241-431b-b062-477101924f79',
    name: 'Trenton Harris',
    count: 38759,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 60082,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 32021,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 82727,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 43419,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 37099,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 16095,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 80833,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 77543,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 75228,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 89794,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 67275,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 3903,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 64474,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 35442,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 54009,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 85614,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 33121,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 3683,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 8672,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 56784,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 6342,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 66288,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 38016,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 68538,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 28143,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 80217,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 3112,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 31988,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 64761,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 15297,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 28377,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 86537,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 21152,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 55339,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 10291,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 19254,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 54529,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 52503,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 27480,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 36651,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 88652,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 36799,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 54212,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 34746,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 75200,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 86431,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 51587,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 12667,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 62884,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 42225,
      },
    ],
  },
  {
    id: '4e013159-5443-4b22-855e-3beef80ec271',
    name: 'Ernesto Kiehn',
    count: 27142,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 82508,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 52650,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 28143,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 72573,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 37482,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 83990,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 37828,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 53080,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 78590,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 54605,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 74869,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 76271,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 36762,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 29508,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 52846,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 71619,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 16219,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 11159,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 56075,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 53720,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 22955,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 62559,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 26920,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 57197,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 1846,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 56412,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 71772,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 11064,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 75704,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 42242,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 62584,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 14448,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 77375,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 81997,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 31275,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 66692,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 82573,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 61068,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 88140,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 57254,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 86969,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 51290,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 57170,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 62683,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 48005,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 71912,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 43548,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 47287,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 19738,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 70972,
      },
    ],
  },
  {
    id: '507831e5-cda6-4625-88e0-a27a6fbd3d40',
    name: 'Walton Hilpert',
    count: 58957,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 2928,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 17168,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 4025,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 84094,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 15446,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 15777,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 68099,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 56976,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 24150,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 27597,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 32066,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 53245,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 16461,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 85286,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 33001,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 37404,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 81438,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 41791,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 41441,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 42931,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 55991,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 36982,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 80147,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 87046,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 77984,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 81813,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 49603,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 25035,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 47110,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 65359,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 42076,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 5396,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 33890,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 44666,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 37894,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 9116,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 73071,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 18955,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 68120,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 5088,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 85057,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 41621,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 42611,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 3407,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 68535,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 53480,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 54434,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 21097,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 41936,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 8608,
      },
    ],
  },
  {
    id: '19734e4f-3be5-47e1-82d2-ab2f8433c4e7',
    name: 'Freddie Reichel',
    count: 17686,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 43464,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 5275,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 47950,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 27598,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 63603,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 46034,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 51562,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 38532,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 64997,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 78680,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 88953,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 24974,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 65478,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 53599,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 11186,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 39349,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 27495,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 85130,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 87136,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 27115,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 49610,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 41349,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 5277,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 42490,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 87717,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 24829,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 8333,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 18406,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 58094,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 46257,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 24806,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 71411,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 35403,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 7521,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 40697,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 83138,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 51634,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 71579,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 42492,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 82066,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 47031,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 37834,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 87495,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 56625,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 16065,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 62485,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 18508,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 37697,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 34116,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 26606,
      },
    ],
  },
  {
    id: '15d49d09-de18-4575-9e91-04bd2d23f3a8',
    name: 'Hassan Fahey',
    count: 58214,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 46577,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 32711,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 13262,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 38649,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 76989,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 38991,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 59442,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 85694,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 70630,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 27489,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 87318,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 33377,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 23851,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 22477,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 30304,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 72891,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 70078,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 18284,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 53815,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 7202,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 39469,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 61243,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 59135,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 54726,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 59863,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 47889,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 25858,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 22921,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 40266,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 60395,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 33350,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 55672,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 25318,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 85681,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 73666,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 30284,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 46200,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 87155,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 33174,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 26380,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 38348,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 63796,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 85276,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 10939,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 83421,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 52821,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 15566,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 60666,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 16323,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 28359,
      },
    ],
  },
  {
    id: 'c26ce8bd-512c-4c97-b127-42a074ff4278',
    name: 'Jaylen McClure',
    count: 44946,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 11213,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 65298,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 38863,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 51196,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 911,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 55851,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 28563,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 22466,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 30523,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 71949,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 14537,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 29293,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 35958,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 46777,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 61403,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 72009,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 68027,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 36845,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 2896,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 39363,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 2270,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 24904,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 15925,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 15102,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 39475,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 77417,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 8312,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 88431,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 44081,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 39277,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 87580,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 41537,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 86048,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 49970,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 5189,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 81575,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 15040,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 83253,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 4849,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 44298,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 52303,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 73402,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 66651,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 11741,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 11627,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 6746,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 65665,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 84983,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 61583,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 79645,
      },
    ],
  },
  {
    id: 'd5c3f50f-8fe1-4bc5-bf7c-0ca5fde3b987',
    name: 'Hermann Kris',
    count: 31755,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 68963,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 12010,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 76150,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 9899,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 86843,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 89627,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 59309,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 65275,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 89227,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 54964,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 81170,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 63490,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 34679,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 45276,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 26137,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 44102,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 4435,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 51427,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 1575,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 52728,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 80406,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 1653,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 78431,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 10828,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 75386,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 26848,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 86727,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 31838,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 58230,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 70843,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 49984,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 84977,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 65591,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 29494,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 57749,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 12241,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 66117,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 29918,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 7183,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 28720,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 8553,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 37568,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 28289,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 85500,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 6308,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 18661,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 46515,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 40746,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 28106,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 71867,
      },
    ],
  },
  {
    id: '1f80409c-7d38-4190-885d-bd5b3fad7720',
    name: 'Jessica Block',
    count: 51428,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 79681,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 87380,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 56835,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 68596,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 49077,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 79420,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 71913,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 43839,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 33471,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 80777,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 89083,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 64994,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 88179,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 47556,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 57705,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 27021,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 37568,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 78076,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 7871,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 28441,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 36052,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 48509,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 65646,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 22118,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 78131,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 37275,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 78275,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 46571,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 41246,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 33847,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 57469,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 29827,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 71957,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 76586,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 57394,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 83062,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 45757,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 35998,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 13872,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 34036,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 13985,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 58825,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 40021,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 27669,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 21734,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 81755,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 77345,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 71791,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 48523,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 53744,
      },
    ],
  },
  {
    id: '8ff3b7fe-fd74-4e15-a257-b3b5c8770470',
    name: 'Elissa Gleichner',
    count: 6151,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 48437,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 60607,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 40395,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 70628,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 66114,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 78143,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 56937,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 74708,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 50364,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 2109,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 75415,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 73558,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 65272,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 56631,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 27227,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 32127,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 34868,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 64994,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 46414,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 29351,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 78378,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 32464,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 33690,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 37874,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 52051,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 47481,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 12186,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 40572,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 85963,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 905,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 4629,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 25915,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 38096,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 58511,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 58522,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 26161,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 54034,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 53315,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 5068,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 69327,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 13268,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 44901,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 31719,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 72201,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 50611,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 4669,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 46315,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 19686,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 3062,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 68342,
      },
    ],
  },
  {
    id: '00fccf5a-878c-43d0-bc94-3cf96758b1b9',
    name: 'Josh Larson',
    count: 45207,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 15306,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 15715,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 27841,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 79692,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 8270,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 88902,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 24453,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 88087,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 9972,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 61392,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 57777,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 11737,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 30384,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 2666,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 49526,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 43964,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 50358,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 7731,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 85419,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 86804,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 64795,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 55202,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 18874,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 13795,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 37509,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 42662,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 53868,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 11211,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 81235,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 56252,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 83225,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 80362,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 23100,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 36809,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 58360,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 35498,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 72307,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 37515,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 67448,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 75314,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 62390,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 78372,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 67403,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 69359,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 41340,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 47082,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 69573,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 20639,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 33506,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 52207,
      },
    ],
  },
  {
    id: 'bb688138-a37d-4273-b6e5-623562f43ceb',
    name: 'Wilford Kuphal',
    count: 56700,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 2271,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 88463,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 34220,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 2518,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 75441,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 29668,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 56632,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 49029,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 12738,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 74797,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 66069,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 61321,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 80155,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 65875,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 6578,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 9868,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 43797,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 47854,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 50091,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 86463,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 38103,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 61153,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 76313,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 20353,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 72481,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 74746,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 30356,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 87322,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 20204,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 81077,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 56306,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 29959,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 81055,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 87397,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 63816,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 47656,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 34007,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 20967,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 31884,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 67285,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 43825,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 78015,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 19556,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 47912,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 31552,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 89364,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 32690,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 47280,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 26164,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 78100,
      },
    ],
  },
  {
    id: 'f5bb9cf1-e8ba-4f83-9b34-2f6690cd8a94',
    name: 'Chaim Wolf',
    count: 28052,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 49431,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 68845,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 67413,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 48286,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 79119,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 16897,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 57614,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 38492,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 31242,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 22408,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 25228,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 4931,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 5907,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 33920,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 72316,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 59771,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 57509,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 44193,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 82487,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 54598,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 19752,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 52891,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 34911,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 21800,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 4286,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 50183,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 88833,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 78682,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 89353,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 18361,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 35542,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 39141,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 54599,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 15456,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 6444,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 64731,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 74200,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 25420,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 8893,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 24444,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 63631,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 10971,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 32906,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 66474,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 89498,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 63681,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 58524,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 5433,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 58492,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 52760,
      },
    ],
  },
  {
    id: '5ff0cfbe-f8f3-473e-8a22-52e602410d35',
    name: 'Sterling Casper',
    count: 10026,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 41127,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 58057,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 88063,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 14629,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 71557,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 63994,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 43250,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 6433,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 49414,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 75454,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 79292,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 39476,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 20818,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 46319,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 73335,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 66611,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 32087,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 25177,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 80189,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 42298,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 81942,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 30681,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 49429,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 82305,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 69296,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 11383,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 66926,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 49212,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 70399,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 49595,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 66542,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 66868,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 86993,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 23694,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 41944,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 20052,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 20054,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 57928,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 56173,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 43715,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 86571,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 19880,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 14943,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 74467,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 12957,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 1236,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 88565,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 87115,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 26799,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 2137,
      },
    ],
  },
  {
    id: 'a7b44067-86b1-40f6-a5d0-97584c2d2ebd',
    name: 'Tony Terry',
    count: 57879,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 54471,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 62999,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 51549,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 23562,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 38842,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 76835,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 37407,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 29517,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 76558,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 38606,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 61214,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 49010,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 17532,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 89467,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 36098,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 64552,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 38478,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 31911,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 1085,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 51300,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 86093,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 27786,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 2284,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 51113,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 10886,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 17808,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 13512,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 22958,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 79667,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 27736,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 10641,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 3084,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 40279,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 36298,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 72460,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 1259,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 47895,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 70792,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 81496,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 4423,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 2924,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 7238,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 46808,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 20630,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 1770,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 87023,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 55108,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 12910,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 20060,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 12118,
      },
    ],
  },
  {
    id: '61faac2b-8eb4-4de9-8be0-0cb23e35eef4',
    name: 'Westley Robel',
    count: 34634,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 31006,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 11493,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 58006,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 32940,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 85028,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 42983,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 31569,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 83126,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 74963,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 55910,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 40640,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 11622,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 22187,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 4081,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 69229,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 77894,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 81216,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 53721,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 42424,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 71022,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 21148,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 74138,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 5775,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 70554,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 46850,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 72803,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 6422,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 50734,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 23222,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 65229,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 28451,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 85813,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 70103,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 12708,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 79806,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 22103,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 34526,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 37495,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 55708,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 30293,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 85494,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 49342,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 58415,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 21363,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 15360,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 9814,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 16349,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 11832,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 60785,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 57731,
      },
    ],
  },
  {
    id: '68c13aa1-52fb-4d3f-aa52-151b5a694d3f',
    name: 'Finn Moen',
    count: 12035,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 82160,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 4113,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 89216,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 60322,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 38319,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 37914,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 24513,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 87095,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 22970,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 923,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 69842,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 41332,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 29790,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 63371,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 6501,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 17413,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 33311,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 26916,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 73200,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 64206,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 8771,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 46329,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 62919,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 78904,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 74166,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 60305,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 81054,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 75611,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 22725,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 50679,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 49738,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 29692,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 79497,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 40717,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 44262,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 86765,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 15809,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 38895,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 37871,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 13656,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 66913,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 1633,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 74525,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 31804,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 7613,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 78209,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 33068,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 23452,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 79838,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 85699,
      },
    ],
  },
  {
    id: '665ffccf-54b8-41c0-af26-bfdab6453538',
    name: 'Abraham Rippin',
    count: 43047,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 49272,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 44219,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 6047,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 39126,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 84403,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 48357,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 21103,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 55477,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 27656,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 29309,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 69266,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 30722,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 64296,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 74322,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 50245,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 14962,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 34806,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 27871,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 72814,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 1644,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 13569,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 26113,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 27711,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 65364,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 83880,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 41997,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 34171,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 72490,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 52715,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 42611,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 12023,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 4395,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 70652,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 46367,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 11184,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 7640,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 22166,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 77482,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 42375,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 26603,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 57777,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 50895,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 35306,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 37273,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 24542,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 1039,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 55968,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 59100,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 30437,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 65319,
      },
    ],
  },
  {
    id: '6f0421f5-5905-47b4-9c7e-3af329746680',
    name: 'Rogers Kutch',
    count: 43865,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 40056,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 3984,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 22709,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 27484,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 19505,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 84369,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 30290,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 52691,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 6273,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 12604,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 35852,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 15678,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 1463,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 49605,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 953,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 80958,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 82055,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 3562,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 82200,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 64812,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 32055,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 88147,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 56370,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 16871,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 77685,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 2842,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 85865,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 25944,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 45576,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 13087,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 47935,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 33337,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 31886,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 15802,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 31990,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 13942,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 78226,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 68272,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 27038,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 83653,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 61604,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 77368,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 8974,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 22376,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 22600,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 47743,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 78299,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 58584,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 16909,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 58126,
      },
    ],
  },
  {
    id: '16be2d49-1f9f-49ee-ac73-d002fd8eab8a',
    name: 'Destini Lind',
    count: 18025,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 85666,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 56009,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 45560,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 61143,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 64265,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 29127,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 53082,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 45745,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 49405,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 35355,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 66439,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 14936,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 76991,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 17492,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 4615,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 39444,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 20234,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 47833,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 89744,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 84789,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 56761,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 15966,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 44829,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 73413,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 48025,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 39002,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 33444,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 1573,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 86416,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 67389,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 37005,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 34978,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 21110,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 20053,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 34509,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 31565,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 45541,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 75974,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 54251,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 4918,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 44722,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 50196,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 6051,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 23043,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 27360,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 65559,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 21314,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 6567,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 46250,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 32591,
      },
    ],
  },
  {
    id: '57dd0598-4316-4cdb-b6be-03859b634357',
    name: 'Sandy Koch',
    count: 37060,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 63919,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 62666,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 46077,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 6018,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 42341,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 42611,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 13769,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 10235,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 51760,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 54697,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 39859,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 66790,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 3623,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 55771,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 49372,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 53342,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 78380,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 12048,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 6368,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 33394,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 19720,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 68709,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 6272,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 75935,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 5615,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 72324,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 1812,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 63608,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 39264,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 85157,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 47745,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 35213,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 87491,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 56095,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 56332,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 67562,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 2755,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 4316,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 45050,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 89489,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 77526,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 21028,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 12199,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 46833,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 88038,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 75512,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 31695,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 65959,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 5207,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 55360,
      },
    ],
  },
  {
    id: '5952cfac-c974-408e-b25d-10dcf6d075af',
    name: 'Darion Reichert',
    count: 47120,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 86964,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 38797,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 45069,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 15133,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 77463,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 82895,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 63457,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 41311,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 69388,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 20867,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 2472,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 6889,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 58987,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 49016,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 44206,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 16982,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 29407,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 49997,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 75644,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 42580,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 38125,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 11740,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 25405,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 4068,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 68100,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 64484,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 10920,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 21316,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 82029,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 84975,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 39915,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 19350,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 45162,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 27856,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 7643,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 11569,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 38877,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 6774,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 86213,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 46475,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 74524,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 5461,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 20347,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 16200,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 28576,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 89061,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 19995,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 81966,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 24797,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 41326,
      },
    ],
  },
  {
    id: '5006deaa-d6fc-46d0-8b4e-dfd3ff8b76b3',
    name: 'Sabina Welch',
    count: 18048,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 75177,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 30348,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 12174,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 45908,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 49356,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 40315,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 27353,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 74102,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 37991,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 7642,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 31782,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 58368,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 15223,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 16491,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 2779,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 9004,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 31946,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 17029,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 2004,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 43783,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 79957,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 60632,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 45215,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 17327,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 6033,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 77136,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 69042,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 16589,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 71722,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 60751,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 41630,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 14835,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 37033,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 62253,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 60491,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 40883,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 78341,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 5879,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 36905,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 78589,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 71686,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 66121,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 47253,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 84174,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 16744,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 32474,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 20875,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 72559,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 48940,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 59605,
      },
    ],
  },
  {
    id: 'f97e0822-0213-407c-b0cf-63d9ef00c691',
    name: 'Cary Rippin',
    count: 4779,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 87322,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 1710,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 36717,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 80273,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 54002,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 36213,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 42143,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 55959,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 28387,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 71203,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 33993,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 4462,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 33979,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 40916,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 61470,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 60892,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 63990,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 55083,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 4357,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 59061,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 42369,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 70155,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 71252,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 21509,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 66251,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 18827,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 49638,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 36697,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 32818,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 17590,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 53207,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 84798,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 17610,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 50647,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 26693,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 63651,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 17346,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 9889,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 61491,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 17979,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 75312,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 57268,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 68064,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 81199,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 37244,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 11236,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 17592,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 28655,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 89134,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 83593,
      },
    ],
  },
  {
    id: '80e704ac-bd49-402f-843c-f4ed0819b2c5',
    name: 'Julie Jones',
    count: 20760,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 29966,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 81610,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 84333,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 8788,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 43161,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 69131,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 53550,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 23961,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 89620,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 81836,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 56957,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 86368,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 3128,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 83588,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 61741,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 85236,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 75529,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 9616,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 33441,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 47577,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 5889,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 61462,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 10267,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 74196,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 70516,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 1747,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 61393,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 58358,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 3694,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 34276,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 16992,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 65692,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 3076,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 85512,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 66486,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 34761,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 51843,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 31785,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 82257,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 10090,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 76591,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 45226,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 19101,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 29836,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 35039,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 24469,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 19976,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 73363,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 82982,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 3944,
      },
    ],
  },
  {
    id: 'c291b34a-ddee-4cb6-9ddd-47c558197d94',
    name: 'Penelope Cormier',
    count: 9026,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 71116,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 23016,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 74977,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 53875,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 75589,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 16172,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 89939,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 16578,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 4393,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 55341,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 29794,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 6053,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 52272,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 62490,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 16731,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 15134,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 37404,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 74624,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 54176,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 43736,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 7318,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 79267,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 53171,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 84999,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 15715,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 35010,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 42701,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 83271,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 67048,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 79715,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 59626,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 8309,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 60624,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 77660,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 83002,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 74419,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 80077,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 28153,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 3719,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 29294,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 49296,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 32819,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 2060,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 66137,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 82470,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 53754,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 83196,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 28487,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 73557,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 83783,
      },
    ],
  },
  {
    id: '376ab3ac-9816-442d-ab47-b22dd5e91943',
    name: 'Gerry Boyle',
    count: 59463,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 70011,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 32934,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 23647,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 24467,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 26287,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 60940,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 5876,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 41915,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 44876,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 11671,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 48565,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 18134,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 22573,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 54024,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 69583,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 8252,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 16794,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 69140,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 20693,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 9389,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 59250,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 34128,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 30289,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 45211,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 27378,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 8136,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 3602,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 30581,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 88986,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 57854,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 60981,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 16034,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 55710,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 74613,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 9978,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 15749,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 56454,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 72562,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 82656,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 83614,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 46309,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 77173,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 40999,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 69124,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 9428,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 59089,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 76516,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 8887,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 28935,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 74043,
      },
    ],
  },
  {
    id: 'fd75a4ca-711e-4a5e-a511-8b347392c73b',
    name: 'Leo McKenzie',
    count: 17214,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 43690,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 23260,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 25576,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 47848,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 7090,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 56641,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 25370,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 78031,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 62237,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 31807,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 11979,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 50637,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 47296,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 73750,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 47302,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 48885,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 88712,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 13215,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 35782,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 42368,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 53037,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 41848,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 68686,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 24236,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 44139,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 67997,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 25067,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 5932,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 71864,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 41386,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 72729,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 8484,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 86578,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 4041,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 66336,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 43633,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 83366,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 84746,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 76575,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 68754,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 88752,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 44604,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 35291,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 11332,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 23767,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 43599,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 67718,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 3648,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 16525,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 71253,
      },
    ],
  },
  {
    id: '2a40f183-1e14-46f1-ad79-95464bfb459b',
    name: 'Kyra Quitzon',
    count: 46858,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 63692,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 64959,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 22431,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 20132,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 7421,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 42549,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 35492,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 62213,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 57590,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 35570,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 2800,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 86456,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 12140,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 22724,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 58060,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 80225,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 83420,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 74690,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 27813,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 74711,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 55926,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 16322,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 16995,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 29830,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 42779,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 20113,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 77070,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 82228,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 60898,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 15877,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 47815,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 51820,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 72693,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 56385,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 55164,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 5437,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 42765,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 31796,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 85934,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 16578,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 44949,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 62424,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 29898,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 6087,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 20691,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 38584,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 65671,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 32413,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 7591,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 84406,
      },
    ],
  },
  {
    id: '49e9fbdf-8e1d-4ee0-ac06-3bb6bf1bc3db',
    name: 'Noemi Homenick',
    count: 39384,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 12700,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 8386,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 38389,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 7210,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 63030,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 11320,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 46982,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 68081,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 8139,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 19763,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 58750,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 27632,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 58238,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 7365,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 57926,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 83321,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 38671,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 76584,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 85725,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 60972,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 33059,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 1076,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 3533,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 24635,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 31793,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 52674,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 29231,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 14008,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 4754,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 41018,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 28781,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 70895,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 21975,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 38304,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 89500,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 85289,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 53785,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 89722,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 82424,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 31245,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 71293,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 33204,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 65490,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 50666,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 79695,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 19735,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 5779,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 50221,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 76256,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 12815,
      },
    ],
  },
  {
    id: '3ff8f294-5c42-4c47-ad94-0cef056a10c9',
    name: 'Karson Harris',
    count: 16754,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 70758,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 50264,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 9502,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 31329,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 80517,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 50191,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 86936,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 39487,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 78684,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 6846,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 77367,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 42732,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 25069,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 23785,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 55901,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 34036,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 28423,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 29120,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 15779,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 19754,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 68868,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 4474,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 38036,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 59059,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 18207,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 14317,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 19048,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 7668,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 8081,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 17673,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 31123,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 81095,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 84096,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 72698,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 65204,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 47683,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 62768,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 13923,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 54378,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 74981,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 38242,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 50704,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 5414,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 81542,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 75219,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 57981,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 28791,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 61845,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 55585,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 78995,
      },
    ],
  },
  {
    id: '381894db-2376-4721-bd15-c7dd1c332112',
    name: 'Eusebio Kassulke',
    count: 33667,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 82405,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 25630,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 35261,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 47965,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 35266,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 12080,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 37869,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 34401,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 85670,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 20550,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 62810,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 60189,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 55041,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 61324,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 68256,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 7142,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 54751,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 30353,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 25119,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 17889,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 71230,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 6612,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 60990,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 27505,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 17752,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 48849,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 27499,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 18335,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 46998,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 43369,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 3734,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 77249,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 38263,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 63574,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 57475,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 63332,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 69038,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 46802,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 53837,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 70179,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 8831,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 47711,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 52202,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 14199,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 20819,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 10075,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 26113,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 41823,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 55432,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 32894,
      },
    ],
  },
  {
    id: 'c4dfa15d-ea59-484c-ad6b-8bdfd285c6ca',
    name: 'Imelda Romaguera',
    count: 13682,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 38498,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 34993,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 4588,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 75457,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 38618,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 70724,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 87103,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 9317,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 63697,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 76122,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 58781,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 35055,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 3175,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 29714,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 60924,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 49116,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 45795,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 74156,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 66057,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 43044,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 25711,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 5982,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 74113,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 87610,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 16471,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 40345,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 34156,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 25815,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 78064,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 36703,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 65030,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 24033,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 82951,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 13634,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 60579,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 29311,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 28446,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 14758,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 23365,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 75868,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 40924,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 87911,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 67962,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 9200,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 80621,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 46435,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 3974,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 84781,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 8181,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 17408,
      },
    ],
  },
  {
    id: '77faa22d-cf2f-4e38-ad6b-0ec7e2dc5c16',
    name: 'Trevor Gleason',
    count: 22066,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 51713,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 46175,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 19875,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 40400,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 67061,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 4006,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 41620,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 11810,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 36536,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 73233,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 17453,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 37996,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 70108,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 56680,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 53504,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 39163,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 40995,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 82840,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 63002,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 42239,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 9293,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 12818,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 84244,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 67477,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 67397,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 41653,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 4669,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 88923,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 12061,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 22062,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 5431,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 51671,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 19921,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 22516,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 82662,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 30227,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 30149,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 16388,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 87141,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 87196,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 67801,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 3138,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 36024,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 76949,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 14153,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 54355,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 23610,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 63461,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 35041,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 67393,
      },
    ],
  },
  {
    id: '18a6863e-3a30-4b57-8532-e252840cba37',
    name: 'Taya Larkin',
    count: 16269,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 79200,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 18064,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 39882,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 28446,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 28950,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 9318,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 2799,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 4225,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 23586,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 7477,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 87037,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 45421,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 69651,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 30207,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 49882,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 14143,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 66605,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 40914,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 76669,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 45404,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 2686,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 53436,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 84868,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 8366,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 71139,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 61609,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 9404,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 53088,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 40359,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 81140,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 48187,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 11578,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 80454,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 37329,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 66662,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 16857,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 33596,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 26274,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 81985,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 32743,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 26301,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 14083,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 80970,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 42050,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 89305,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 68606,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 77477,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 79579,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 28911,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 47145,
      },
    ],
  },
  {
    id: 'a2087d75-dc55-48ad-a843-e0e7b5e17c45',
    name: 'Carmen Abshire',
    count: 54660,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 38091,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 63530,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 27836,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 77664,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 28350,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 6721,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 68036,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 34937,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 6916,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 67218,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 43430,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 4060,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 16141,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 10257,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 71581,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 24241,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 85010,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 47659,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 55841,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 85307,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 5348,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 33666,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 959,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 16138,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 50442,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 76930,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 65589,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 69921,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 10514,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 15705,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 78721,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 64662,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 24158,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 37676,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 46905,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 10458,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 73995,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 20137,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 84665,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 71049,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 89532,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 83351,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 7812,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 33203,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 14648,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 17385,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 27380,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 20593,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 20372,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 44700,
      },
    ],
  },
  {
    id: 'd6aa4222-cdb0-4ae3-a0e0-1cee55914785',
    name: 'Aron Toy',
    count: 24244,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 26183,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 59007,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 34785,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 22291,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 42966,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 19386,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 78698,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 87051,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 62416,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 19799,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 86209,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 65899,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 44422,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 16203,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 8366,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 34967,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 67535,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 34488,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 35021,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 86701,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 56228,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 41224,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 62377,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 79041,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 76032,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 8165,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 6092,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 35951,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 60848,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 31859,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 52722,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 49236,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 26835,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 2245,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 67041,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 78793,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 45375,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 11477,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 42558,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 58939,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 27209,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 59682,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 57570,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 79812,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 86224,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 84390,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 20823,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 12343,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 22275,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 63605,
      },
    ],
  },
  {
    id: '7864165e-b853-4adc-bd5b-b5f5b9070130',
    name: 'Jeanne Breitenberg',
    count: 36479,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 10748,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 23081,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 74194,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 64749,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 21091,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 14666,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 35120,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 16875,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 64981,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 11855,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 28148,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 68694,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 56438,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 58771,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 29980,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 73389,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 78216,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 23384,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 43006,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 83749,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 87886,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 61557,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 64766,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 37684,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 58106,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 51972,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 51342,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 58878,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 40262,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 26909,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 43402,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 87031,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 51549,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 43863,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 8202,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 32999,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 24375,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 75629,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 67512,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 39511,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 39981,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 13553,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 37228,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 26560,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 78852,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 60996,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 26290,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 32102,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 61833,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 34085,
      },
    ],
  },
  {
    id: '998b58c2-8d69-469c-8fee-a956bcee01d8',
    name: 'Dexter Swift',
    count: 36628,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 12520,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 78527,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 42869,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 80715,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 78307,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 54310,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 20278,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 20881,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 3596,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 80609,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 23508,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 82515,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 70999,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 49861,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 42291,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 21672,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 34496,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 38227,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 24785,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 42019,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 63104,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 56156,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 47131,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 27935,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 89487,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 20130,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 46307,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 36516,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 49426,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 84218,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 74706,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 9564,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 71104,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 87115,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 16761,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 31742,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 69908,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 65612,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 5518,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 58912,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 79495,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 85307,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 14386,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 84066,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 14190,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 55970,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 6798,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 26851,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 51628,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 55412,
      },
    ],
  },
  {
    id: 'e97f06e3-57dd-49a7-928c-3c91d483236f',
    name: 'Diamond Mraz',
    count: 23094,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 45763,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 23837,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 11208,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 8776,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 22566,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 14241,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 27524,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 46556,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 89930,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 24586,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 81850,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 6504,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 7760,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 20151,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 89844,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 73073,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 51359,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 4380,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 23508,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 11159,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 72005,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 19535,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 45621,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 56577,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 58875,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 20367,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 84773,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 7397,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 26486,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 5598,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 85085,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 6791,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 36751,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 28731,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 41679,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 60475,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 73949,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 33952,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 48411,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 61718,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 42632,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 53381,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 6792,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 11871,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 50936,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 73414,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 16305,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 56637,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 43787,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 80674,
      },
    ],
  },
  {
    id: 'ffc862f1-7b4b-4973-bad2-259083f4fa8f',
    name: 'Marlin Huels',
    count: 34032,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 65297,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 17513,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 85562,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 18163,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 60585,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 5043,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 70881,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 65621,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 47434,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 58831,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 35540,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 3134,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 69388,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 77663,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 84858,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 32680,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 35170,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 46714,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 48279,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 23241,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 9483,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 52395,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 85944,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 84090,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 41724,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 9361,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 52478,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 74868,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 18130,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 25756,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 2492,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 21753,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 13610,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 14885,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 67593,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 17025,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 44830,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 35837,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 19552,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 41022,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 27935,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 8112,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 5430,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 88368,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 33031,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 58994,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 25202,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 1121,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 81266,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 2556,
      },
    ],
  },
  {
    id: 'ec8e8969-b5c3-4a15-8e93-5655e685e1a5',
    name: 'Arianna Barton',
    count: 8031,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 3276,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 14178,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 20345,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 4146,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 67094,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 84545,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 39597,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 33489,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 68490,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 88379,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 16090,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 81736,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 34799,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 3944,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 67275,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 2747,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 50467,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 30080,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 32425,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 26137,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 25132,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 14364,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 85395,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 65796,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 84150,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 37865,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 14715,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 65288,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 87322,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 59504,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 69915,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 57844,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 67344,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 61590,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 85421,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 63095,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 49264,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 37877,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 84469,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 7455,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 5535,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 50580,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 28998,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 48056,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 58029,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 24467,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 16091,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 20527,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 83205,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 58633,
      },
    ],
  },
  {
    id: 'f7cdf19b-124d-48e0-9d16-073699d6d791',
    name: 'Damon Nolan',
    count: 48425,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 41365,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 87794,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 4390,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 22261,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 16943,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 54074,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 53826,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 85941,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 6098,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 72103,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 44023,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 88348,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 4538,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 60539,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 53041,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 24228,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 13120,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 67126,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 47211,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 24538,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 50936,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 86641,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 3943,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 73227,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 13101,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 77633,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 29338,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 57740,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 44095,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 84412,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 74346,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 76865,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 65297,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 61196,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 29148,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 22515,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 66495,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 2091,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 86979,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 87471,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 67663,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 16583,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 75818,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 65079,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 49719,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 86069,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 55874,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 54747,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 9307,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 41922,
      },
    ],
  },
  {
    id: '192fff4a-c762-4652-a2bb-8e94027e2b9c',
    name: 'Johnny Schiller',
    count: 39204,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 11544,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 71563,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 24474,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 88463,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 44598,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 66783,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 52674,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 8691,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 20792,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 79055,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 50938,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 73878,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 83893,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 24484,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 32460,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 36112,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 51121,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 83560,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 20790,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 19139,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 39697,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 89616,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 65182,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 3200,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 45313,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 80344,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 81538,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 76293,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 79997,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 65695,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 53184,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 81205,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 34815,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 46550,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 63725,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 46679,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 49434,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 30941,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 43167,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 13617,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 74537,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 66790,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 86548,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 84405,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 83664,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 24430,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 66954,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 87266,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 88492,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 36590,
      },
    ],
  },
  {
    id: 'd42d47a3-0770-4ab4-8216-f9cd8670b20e',
    name: 'Mollie Mills',
    count: 24283,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 55078,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 42779,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 25423,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 86864,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 59546,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 75405,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 57772,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 66147,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 82256,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 74626,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 84336,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 36045,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 83973,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 4571,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 35884,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 88608,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 20350,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 18482,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 64223,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 41088,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 22958,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 38579,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 7466,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 24540,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 45332,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 47158,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 14577,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 64950,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 77095,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 84814,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 45222,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 33967,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 71258,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 62250,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 16991,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 50257,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 74328,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 35704,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 2789,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 77783,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 72388,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 79682,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 44450,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 44709,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 52803,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 22699,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 77274,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 42699,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 76646,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 77809,
      },
    ],
  },
  {
    id: 'b35e2cf7-f881-4fda-9b09-b017e120f035',
    name: 'Isai Collier',
    count: 51562,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 25707,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 6751,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 52412,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 18401,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 79296,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 54403,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 64460,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 1501,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 72628,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 50451,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 78916,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 41667,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 48066,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 53581,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 40434,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 26335,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 29776,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 20862,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 2347,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 12460,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 69461,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 46855,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 28773,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 59327,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 36537,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 77698,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 32604,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 37772,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 3622,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 17094,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 31343,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 57277,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 87404,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 29724,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 69144,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 73985,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 39095,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 5132,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 54184,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 32536,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 18236,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 44477,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 37814,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 74826,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 48453,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 65135,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 71569,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 43491,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 65881,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 48966,
      },
    ],
  },
  {
    id: '757ea8e9-d51f-4ee3-9651-01f2b1a5507c',
    name: 'Caleb Baumbach',
    count: 62826,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 19015,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 34706,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 5959,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 64264,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 51631,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 38244,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 24546,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 52545,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 74190,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 6458,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 46052,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 49155,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 54096,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 57027,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 66838,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 29807,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 19753,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 85401,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 19297,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 27827,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 82607,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 28187,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 5235,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 46693,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 78228,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 53472,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 23335,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 70932,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 19866,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 54021,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 89189,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 69811,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 39877,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 48813,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 75583,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 18880,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 3541,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 83935,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 34199,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 79921,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 41753,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 55377,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 21621,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 3894,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 87133,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 8303,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 15594,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 56981,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 57865,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 21909,
      },
    ],
  },
  {
    id: '32a017a0-9fac-41cf-8a96-51bc77143ade',
    name: 'Dena Wyman',
    count: 16937,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 32612,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 64759,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 7735,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 15382,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 26923,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 75871,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 86675,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 85985,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 3626,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 77994,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 43886,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 40798,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 29985,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 59348,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 83302,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 64579,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 72744,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 9518,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 17204,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 16204,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 4905,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 77448,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 75680,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 37275,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 54922,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 46592,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 17197,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 25145,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 59724,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 85292,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 87322,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 86014,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 6858,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 58309,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 68826,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 43142,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 30575,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 87570,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 5228,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 79344,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 27657,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 76165,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 7082,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 85000,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 9077,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 48831,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 7970,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 44332,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 29744,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 69676,
      },
    ],
  },
  {
    id: 'fbf2d617-a4e6-4e48-9603-141049c09053',
    name: 'Terrence Runolfsdottir',
    count: 30986,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 38112,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 63442,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 57963,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 5555,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 11153,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 74571,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 58925,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 59912,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 12939,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 62810,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 41024,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 42730,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 58859,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 34513,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 62406,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 54709,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 27543,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 8646,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 25739,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 87063,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 79022,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 61099,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 48162,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 89185,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 1542,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 18549,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 46727,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 53494,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 74658,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 13176,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 36643,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 38567,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 15040,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 56399,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 8672,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 1687,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 42359,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 70223,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 5232,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 56763,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 58337,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 36162,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 30963,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 28586,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 58165,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 60334,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 42392,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 41718,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 37993,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 81725,
      },
    ],
  },
  {
    id: '96bc027f-6345-4ec5-8c43-4ab072e6b80f',
    name: 'Jared Brown',
    count: 12276,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 35490,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 19478,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 25811,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 39408,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 64291,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 52709,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 68816,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 74526,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 82920,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 81071,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 30068,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 47636,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 40765,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 2461,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 29100,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 54876,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 32660,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 10921,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 39647,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 14546,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 6614,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 63825,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 63961,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 42334,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 83319,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 8621,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 40915,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 51922,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 9023,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 45442,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 51392,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 45185,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 17105,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 80860,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 50125,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 13840,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 75882,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 55527,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 34028,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 67598,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 83830,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 40927,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 50974,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 84515,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 39086,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 46175,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 6271,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 55074,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 15064,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 77978,
      },
    ],
  },
  {
    id: '3965f096-76bc-412e-b192-353d6d22eced',
    name: "Makayla O'Reilly",
    count: 49760,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 37906,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 5948,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 62562,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 48534,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 25383,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 46189,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 8413,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 75448,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 77102,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 79581,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 50460,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 85950,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 25344,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 23124,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 21718,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 49691,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 13537,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 51133,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 81360,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 45896,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 63824,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 78540,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 84007,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 41748,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 27736,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 14222,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 69947,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 55640,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 8404,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 3787,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 18915,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 77550,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 17342,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 59211,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 32845,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 60792,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 62990,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 49900,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 23225,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 40837,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 30942,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 36799,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 54730,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 36442,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 20050,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 84879,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 53994,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 38670,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 11712,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 47879,
      },
    ],
  },
  {
    id: 'b44535b7-3c59-46f0-a4f9-8f8c487e1bb7',
    name: 'Izabella Kemmer',
    count: 10708,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 69942,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 6012,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 6734,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 89022,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 964,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 65750,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 28803,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 4419,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 32668,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 30112,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 72658,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 60728,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 13374,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 32338,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 44834,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 70116,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 34733,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 30562,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 60564,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 23921,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 38123,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 86832,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 24609,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 38262,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 10956,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 63012,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 45306,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 49730,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 80842,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 4596,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 60437,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 52549,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 6164,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 56135,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 36161,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 42139,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 59543,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 44400,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 40693,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 37090,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 23152,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 18421,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 16911,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 24988,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 10855,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 31121,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 5014,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 63890,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 81112,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 53916,
      },
    ],
  },
  {
    id: 'aa4cf285-d85e-4517-a1e8-594d36e0edf9',
    name: 'Immanuel Hilpert',
    count: 52800,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 64847,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 46773,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 31785,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 79566,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 79071,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 12724,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 78684,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 46440,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 62862,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 12583,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 28626,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 1514,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 18294,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 72498,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 47236,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 15867,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 29175,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 35026,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 54647,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 68970,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 4877,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 58016,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 60827,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 25204,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 73914,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 64675,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 63414,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 11804,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 7214,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 48382,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 80747,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 9875,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 80614,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 10585,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 8550,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 18079,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 58124,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 4025,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 58195,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 56673,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 80339,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 87793,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 24071,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 3857,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 10215,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 18495,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 55058,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 65376,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 79405,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 53891,
      },
    ],
  },
  {
    id: '9e066cad-a1e0-48f1-b6e2-89c34c6539ed',
    name: 'Vernon Roob',
    count: 12892,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 79225,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 5996,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 7156,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 68872,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 13884,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 4629,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 29014,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 24035,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 36690,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 66993,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 43756,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 60829,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 85843,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 81511,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 17425,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 43139,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 8795,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 66789,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 73693,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 11579,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 25690,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 28523,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 1872,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 82758,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 88747,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 27813,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 58001,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 6868,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 77707,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 63984,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 40626,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 16964,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 17945,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 16038,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 56699,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 60903,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 15021,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 85729,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 54477,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 33852,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 12982,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 65756,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 74533,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 49422,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 32739,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 4632,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 35662,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 7582,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 20214,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 7452,
      },
    ],
  },
  {
    id: '4339e9b2-d29f-40c8-8463-f2a1b5af420f',
    name: 'Darron Muller',
    count: 10917,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 55435,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 56773,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 61649,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 72791,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 29558,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 14221,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 74885,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 83804,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 23913,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 33302,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 16180,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 8579,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 5168,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 5148,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 60711,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 58636,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 43862,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 69107,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 69010,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 78775,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 9002,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 22914,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 16129,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 72069,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 85324,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 17358,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 83638,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 86994,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 19301,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 20921,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 30628,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 24617,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 36458,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 28820,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 32710,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 25073,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 46629,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 50885,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 74007,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 17981,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 82007,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 36866,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 25612,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 59557,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 23811,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 36380,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 28653,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 52513,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 64042,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 35253,
      },
    ],
  },
  {
    id: '97eedeee-aa73-4b9f-8d96-447cc004fc19',
    name: 'Hailey Christiansen',
    count: 62868,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 10082,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 54509,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 60892,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 13732,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 35541,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 49701,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 88619,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 35726,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 77047,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 31411,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 73804,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 76007,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 58137,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 50742,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 59864,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 27196,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 34956,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 22287,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 62070,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 56903,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 7580,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 87362,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 71919,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 49316,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 32428,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 86496,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 54599,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 2198,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 58248,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 84858,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 35145,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 24601,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 30922,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 69253,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 20374,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 56707,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 86071,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 43560,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 20914,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 85527,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 67470,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 30557,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 74932,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 3627,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 36767,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 32709,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 74014,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 30521,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 57357,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 88481,
      },
    ],
  },
  {
    id: '6acae511-f720-4140-9a55-3be617eebe52',
    name: 'London Hackett',
    count: 46919,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 66395,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 85153,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 78999,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 69299,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 81831,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 62647,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 33886,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 22581,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 59674,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 74315,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 65091,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 14998,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 56579,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 23542,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 86418,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 37569,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 32841,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 74791,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 26795,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 9070,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 13892,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 27017,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 20163,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 5962,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 72281,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 56081,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 89697,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 52347,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 83866,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 28761,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 25391,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 87278,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 1250,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 64220,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 76860,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 33757,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 79772,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 2625,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 77076,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 42968,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 45733,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 24961,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 29419,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 49249,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 88238,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 67288,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 47390,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 64980,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 46067,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 84608,
      },
    ],
  },
  {
    id: '32c2d7c2-64b3-4d94-9a26-21dd55adea78',
    name: 'Nickolas Becker',
    count: 26678,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 21907,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 74639,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 5331,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 38505,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 45126,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 43646,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 16459,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 46780,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 38899,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 82867,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 31070,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 68091,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 59745,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 84663,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 19131,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 75636,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 77942,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 26463,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 82296,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 9966,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 7246,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 80841,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 45090,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 87485,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 59833,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 42001,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 24210,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 62161,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 88971,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 43630,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 66019,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 85068,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 89464,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 27358,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 42899,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 64956,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 81894,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 53971,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 89992,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 17031,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 39101,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 28611,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 83518,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 2089,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 2979,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 70015,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 64523,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 12732,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 64324,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 10884,
      },
    ],
  },
  {
    id: 'c6033cd1-9905-41f4-92fd-a598828300f8',
    name: 'Esta Berge',
    count: 1874,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 39424,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 63560,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 17396,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 60529,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 62133,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 77053,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 37307,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 41541,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 39053,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 48337,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 16404,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 12834,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 45747,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 76751,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 9730,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 19683,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 54814,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 50606,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 78895,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 23534,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 2434,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 21664,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 59041,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 84730,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 77423,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 16562,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 61203,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 4065,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 78327,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 82647,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 46199,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 45678,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 45309,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 32773,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 5734,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 49099,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 73548,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 75573,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 30423,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 10591,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 43345,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 77071,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 58024,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 20458,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 15085,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 32426,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 58177,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 32809,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 32070,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 28858,
      },
    ],
  },
  {
    id: '7a16fb5f-54db-4cbf-b791-a3d22a0bcec8',
    name: 'Erik Ebert',
    count: 4337,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 46926,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 6038,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 77965,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 20204,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 71533,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 73776,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 29636,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 87640,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 35440,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 16001,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 53275,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 16348,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 81490,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 14447,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 2260,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 77202,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 85245,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 75779,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 61389,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 14469,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 8914,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 12913,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 8531,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 63489,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 60119,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 88678,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 6906,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 56986,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 7180,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 50695,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 36013,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 6097,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 89472,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 75054,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 60843,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 26462,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 2600,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 75792,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 63186,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 73331,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 89173,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 20723,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 42381,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 57185,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 33212,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 72082,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 73094,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 68747,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 34791,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 72074,
      },
    ],
  },
  {
    id: 'fad14e37-dc75-4c6c-9e48-7ce570473430',
    name: 'Verna Haag',
    count: 48252,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 44244,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 20603,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 55290,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 7744,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 57480,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 32817,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 84239,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 83688,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 24645,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 89106,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 60564,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 24140,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 42400,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 56820,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 55528,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 11578,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 54403,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 58108,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 30549,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 53041,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 23727,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 50808,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 38074,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 49919,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 32868,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 36637,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 36175,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 63179,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 21270,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 15627,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 47466,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 68222,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 38089,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 89538,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 33221,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 52454,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 17643,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 76872,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 31890,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 72311,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 47340,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 50586,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 14143,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 20893,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 28668,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 39879,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 87613,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 64790,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 29535,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 34751,
      },
    ],
  },
  {
    id: '2324edf8-4ee9-43a1-92ac-902b7290f2f8',
    name: 'Valentine Dach',
    count: 26252,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 89435,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 14456,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 24486,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 21574,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 86094,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 7340,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 64717,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 74899,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 29367,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 72313,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 32501,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 27971,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 62232,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 18713,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 32530,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 81824,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 51278,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 11239,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 4285,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 64011,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 51645,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 83751,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 67080,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 41245,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 35404,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 58736,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 45297,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 67891,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 14672,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 23257,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 77039,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 62999,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 68090,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 39731,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 18222,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 9939,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 5487,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 40787,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 13051,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 64786,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 37664,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 66705,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 59433,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 81272,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 77737,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 50843,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 13505,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 76119,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 63931,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 18538,
      },
    ],
  },
  {
    id: '8ef7a221-6147-4529-b6b3-91d8a2ec0072',
    name: "Roxane D'Amore",
    count: 29864,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 27873,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 16420,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 46788,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 24960,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 82286,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 34096,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 14403,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 10298,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 75269,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 81190,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 7920,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 81652,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 66313,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 6240,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 88660,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 33402,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 35286,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 35411,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 33293,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 14979,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 56379,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 2384,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 85894,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 77183,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 11027,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 85392,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 89787,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 17556,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 54750,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 30804,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 63922,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 45600,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 28992,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 89463,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 83588,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 5783,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 42170,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 35457,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 64591,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 33705,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 23351,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 75342,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 3827,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 69833,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 38886,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 84416,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 54417,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 15013,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 70990,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 23056,
      },
    ],
  },
  {
    id: '55c5a7a3-a9c3-4b40-9290-27540d93cc0d',
    name: 'Misael Hills',
    count: 18145,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 82829,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 86109,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 46775,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 51377,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 11252,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 80260,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 55425,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 56898,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 63245,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 47937,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 39929,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 73734,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 27491,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 45639,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 82017,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 40885,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 77823,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 10482,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 9426,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 1895,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 29099,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 13614,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 74386,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 21077,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 38521,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 40647,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 52546,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 60079,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 49258,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 29470,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 65615,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 79195,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 74213,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 64395,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 80260,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 56660,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 39968,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 43536,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 78969,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 20655,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 22993,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 23685,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 82824,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 71941,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 41884,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 5994,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 64442,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 21626,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 1272,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 71903,
      },
    ],
  },
  {
    id: '836e900b-1c93-44dd-9584-bc34fc76c251',
    name: 'Justen Weber',
    count: 25651,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 38871,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 65597,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 35389,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 79638,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 51231,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 28303,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 30355,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 56965,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 34547,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 36269,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 17788,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 36265,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 28002,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 61291,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 34098,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 34491,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 28520,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 39263,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 4195,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 59189,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 6100,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 32365,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 79022,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 78333,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 68126,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 41122,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 31462,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 69941,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 51233,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 53954,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 60587,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 22869,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 57764,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 74721,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 28174,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 79250,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 60545,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 87081,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 45571,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 57904,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 11554,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 77598,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 61081,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 50694,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 25321,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 73661,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 64408,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 19704,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 19696,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 73173,
      },
    ],
  },
  {
    id: '5a490d15-2d9c-4ddc-b469-497ceba41426',
    name: 'Vivienne Roberts',
    count: 28156,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 20490,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 62312,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 77519,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 74615,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 75338,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 83643,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 67111,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 68124,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 82318,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 52615,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 4809,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 59999,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 83225,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 33424,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 27026,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 49415,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 49776,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 51067,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 86467,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 28467,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 77450,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 23817,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 5913,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 84370,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 77802,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 80782,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 46964,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 56393,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 51192,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 84118,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 1776,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 60544,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 11503,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 9643,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 84502,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 66662,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 37053,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 68169,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 36174,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 58644,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 26059,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 15334,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 9712,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 31286,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 46645,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 84626,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 31892,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 34674,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 69450,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 26043,
      },
    ],
  },
  {
    id: '184210a9-8e6f-4522-9dd2-817bb614fad4',
    name: 'Carleton Quitzon',
    count: 48635,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 71878,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 42579,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 3282,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 8820,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 72715,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 48517,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 46760,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 51951,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 86204,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 67592,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 75854,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 64186,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 34020,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 45285,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 46696,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 8044,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 37663,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 7468,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 2194,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 74306,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 60465,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 45057,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 9133,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 30893,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 24493,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 1073,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 65577,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 28564,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 41518,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 77344,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 5212,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 41212,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 70869,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 50030,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 82026,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 85646,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 88147,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 7257,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 41584,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 57820,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 21721,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 19705,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 44181,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 79156,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 26981,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 13416,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 74547,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 25965,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 25510,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 46742,
      },
    ],
  },
  {
    id: '44976130-3096-46b8-b8a1-c2de6605be63',
    name: 'Joanny Johnson',
    count: 57429,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 9815,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 3550,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 53158,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 85578,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 67376,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 8758,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 77572,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 22567,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 25651,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 34208,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 49653,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 5408,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 80454,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 82257,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 49654,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 46129,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 13087,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 47844,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 13045,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 1272,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 63975,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 80028,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 63329,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 37629,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 6150,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 54042,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 56964,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 32940,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 87709,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 69075,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 50183,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 11729,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 56319,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 43179,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 79688,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 46592,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 58576,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 41383,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 46728,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 66805,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 48899,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 17684,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 89670,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 70219,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 7934,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 83305,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 27400,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 54280,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 25086,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 67102,
      },
    ],
  },
  {
    id: '1b6ad2f5-bb55-4e20-b293-b37f69655f9a',
    name: 'Antonette Lockman',
    count: 26478,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 58846,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 38625,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 52665,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 40361,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 57474,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 38399,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 29353,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 62276,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 28931,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 60876,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 77552,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 89116,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 72722,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 23677,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 19005,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 84670,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 65325,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 37105,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 31407,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 29868,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 24265,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 81849,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 3168,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 68930,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 26279,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 27472,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 58073,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 38788,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 88333,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 22172,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 56477,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 80004,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 86254,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 31102,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 75385,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 1725,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 33265,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 88102,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 982,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 53726,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 69738,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 45137,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 56714,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 38378,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 56807,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 66038,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 19724,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 57756,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 80279,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 10755,
      },
    ],
  },
  {
    id: '1930edf8-b7f4-4218-975a-fdd7467bd6f6',
    name: 'Kadin Lemke',
    count: 3178,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 53933,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 32228,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 47033,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 67276,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 84541,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 19198,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 49586,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 47003,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 69679,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 57039,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 68113,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 37863,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 21738,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 86880,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 56296,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 53527,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 78258,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 87033,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 76279,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 29034,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 37016,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 11144,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 22358,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 3787,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 15662,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 9562,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 75017,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 46214,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 27427,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 22254,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 10738,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 76848,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 28517,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 58497,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 1882,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 22653,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 88148,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 80952,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 28609,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 6296,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 69564,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 35674,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 58179,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 9286,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 71700,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 15731,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 56783,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 9797,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 24886,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 54760,
      },
    ],
  },
  {
    id: '9e7b1b00-d7ef-4342-8796-1418cdcfe18d',
    name: 'Augusta Mohr',
    count: 39502,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 24120,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 26197,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 55363,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 31685,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 7731,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 67454,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 69997,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 78257,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 50674,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 24076,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 63258,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 28248,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 39277,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 1624,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 73674,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 18599,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 19590,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 43754,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 15524,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 37399,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 78412,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 73636,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 88542,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 50410,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 18209,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 72558,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 78042,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 3161,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 77987,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 63907,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 81610,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 76738,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 49851,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 42797,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 26287,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 78411,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 67585,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 27149,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 20966,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 33488,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 82278,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 58733,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 18368,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 38105,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 36738,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 82113,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 53207,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 76757,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 60533,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 54169,
      },
    ],
  },
  {
    id: '7d7d504b-f1b9-41cc-b271-61b7e6ce521d',
    name: 'Lillian McGlynn',
    count: 56346,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 33549,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 11963,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 24125,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 51656,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 52967,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 57833,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 51181,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 39945,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 18858,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 84613,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 68410,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 86624,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 39624,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 76721,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 49581,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 14635,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 40266,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 16483,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 63140,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 35743,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 48254,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 12209,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 16772,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 16119,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 54090,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 16923,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 27429,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 21779,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 47448,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 14568,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 4150,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 70689,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 43189,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 51390,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 79813,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 1226,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 84737,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 79975,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 31148,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 75706,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 10673,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 20714,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 40272,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 21308,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 49523,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 40964,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 20184,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 68650,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 55811,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 74486,
      },
    ],
  },
  {
    id: '4073e1af-37ad-4425-9939-f04f7b2cf4e4',
    name: 'Bulah Ziemann',
    count: 32731,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 45071,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 78701,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 2993,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 2617,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 20028,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 73500,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 44150,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 31673,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 70320,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 50955,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 84977,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 69662,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 39145,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 26805,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 17332,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 71151,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 69333,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 40503,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 57357,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 62858,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 28231,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 2262,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 6799,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 40349,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 19470,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 9225,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 16507,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 78510,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 82707,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 27753,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 24565,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 35116,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 21792,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 8068,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 66919,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 27712,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 67364,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 30947,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 63942,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 88215,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 15858,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 1974,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 1111,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 31368,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 80401,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 82950,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 10917,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 83964,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 34722,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 54280,
      },
    ],
  },
  {
    id: 'fb20371f-eb01-4751-b484-28e10b550d4c',
    name: 'Alvera Corwin',
    count: 55981,
    userActivityGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 29199,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 68091,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 33154,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 62228,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 78567,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 69518,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 52434,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 7267,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 65669,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 35400,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 83964,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 58693,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 88927,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 25958,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 4294,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 36201,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 18426,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 48974,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 42894,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 41200,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 54889,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 73443,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 15450,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 89483,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 78231,
      },
    ],
    dataUsageGraphStaticData: [
      {
        name: 'Jun 25',
        date: 'Jun 25, 2022',
        queryCount: 84113,
      },
      {
        name: 'Jun 26',
        date: 'Jun 26, 2022',
        queryCount: 43350,
      },
      {
        name: 'Jun 27',
        date: 'Jun 27, 2022',
        queryCount: 56849,
      },
      {
        name: 'Jun 28',
        date: 'Jun 28, 2022',
        queryCount: 1187,
      },
      {
        name: 'Jun 29',
        date: 'Jun 29, 2022',
        queryCount: 23131,
      },
      {
        name: 'Jun 30',
        date: 'Jun 30, 2022',
        queryCount: 53010,
      },
      {
        name: 'Jul 1',
        date: 'Jul 1, 2022',
        queryCount: 87638,
      },
      {
        name: 'Jul 2',
        date: 'Jul 2, 2022',
        queryCount: 56119,
      },
      {
        name: 'Jul 3',
        date: 'Jul 3, 2022',
        queryCount: 77599,
      },
      {
        name: 'Jul 4',
        date: 'Jul 4, 2022',
        queryCount: 74207,
      },
      {
        name: 'Jul 5',
        date: 'Jul 5, 2022',
        queryCount: 84316,
      },
      {
        name: 'Jul 6',
        date: 'Jul 6, 2022',
        queryCount: 19626,
      },
      {
        name: 'Jul 7',
        date: 'Jul 7, 2022',
        queryCount: 45867,
      },
      {
        name: 'Jul 8',
        date: 'Jul 8, 2022',
        queryCount: 54533,
      },
      {
        name: 'Jul 9',
        date: 'Jul 9, 2022',
        queryCount: 82492,
      },
      {
        name: 'Jul 10',
        date: 'Jul 10, 2022',
        queryCount: 20844,
      },
      {
        name: 'Jul 11',
        date: 'Jul 11, 2022',
        queryCount: 21172,
      },
      {
        name: 'Jul 12',
        date: 'Jul 12, 2022',
        queryCount: 11033,
      },
      {
        name: 'Jul 13',
        date: 'Jul 13, 2022',
        queryCount: 80554,
      },
      {
        name: 'Jul 14',
        date: 'Jul 14, 2022',
        queryCount: 44828,
      },
      {
        name: 'Jul 15',
        date: 'Jul 15, 2022',
        queryCount: 52761,
      },
      {
        name: 'Jul 16',
        date: 'Jul 16, 2022',
        queryCount: 50973,
      },
      {
        name: 'Jul 17',
        date: 'Jul 17, 2022',
        queryCount: 80352,
      },
      {
        name: 'Jul 18',
        date: 'Jul 18, 2022',
        queryCount: 44479,
      },
      {
        name: 'Jul 19',
        date: 'Jul 19, 2022',
        queryCount: 12458,
      },
    ],
  },
]

export const teamMembersAlt = [
  {
    id: '3a0f01f5-9284-4fda-a027-32b8dca0537f',
    name: 'Rachel Blick',
    count: 57128,
    accessUtilizationGraphStaticData: [
      {
        x: 55,
        y: 37,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '779ab509-cb16-4e7e-b562-ed47cb2aad2d',
    name: 'Cleve Frami',
    count: 26370,
    accessUtilizationGraphStaticData: [
      {
        x: 59,
        y: 24,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '943e9089-30a3-471b-a4ce-d61db8af5fa0',
    name: 'Flavie McClure',
    count: 2779,
    accessUtilizationGraphStaticData: [
      {
        x: 69,
        y: 50,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '3490111f-9c76-4d28-9333-7f0655afd4c7',
    name: 'Cleora Prohaska',
    count: 59521,
    accessUtilizationGraphStaticData: [
      {
        x: 57,
        y: 97,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '17a93eb8-0d79-45d9-b8ad-f0cdb2c4f089',
    name: 'Marco Kemmer',
    count: 24760,
    accessUtilizationGraphStaticData: [
      {
        x: 51,
        y: 17,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: 'f1776a93-86bd-4c61-b9b7-ae1d1a8c399e',
    name: 'Claudia Langosh',
    count: 40258,
    accessUtilizationGraphStaticData: [
      {
        x: 52,
        y: 39,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: 'e6c2889b-72fe-4484-87c5-27e3c2e341f8',
    name: 'Lyda Oberbrunner',
    count: 57374,
    accessUtilizationGraphStaticData: [
      {
        x: 54,
        y: 49,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '69f3ebf7-a12e-4c19-ace7-09562bb7f978',
    name: 'Aniya Huel',
    count: 45610,
    accessUtilizationGraphStaticData: [
      {
        x: 54,
        y: 82,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: 'fa318089-9a73-4fa6-a18c-488e25e706e0',
    name: 'Baby Kris',
    count: 44214,
    accessUtilizationGraphStaticData: [
      {
        x: 59,
        y: 85,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: 'bf0d5d1f-4396-4c7c-80b0-5de7b5d908ab',
    name: 'Axel Jerde',
    count: 24981,
    accessUtilizationGraphStaticData: [
      {
        x: 56,
        y: 27,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '97adbfee-3d3d-4bcb-92f1-395df51151a5',
    name: 'Aaliyah Heathcote',
    count: 41546,
    accessUtilizationGraphStaticData: [
      {
        x: 68,
        y: 49,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '79ae7b3e-d240-4e38-94f0-cdb57a13f6a5',
    name: 'Guillermo Donnelly',
    count: 31483,
    accessUtilizationGraphStaticData: [
      {
        x: 50,
        y: 87,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '6de92721-7ef3-417b-b8be-263dbf36f0d9',
    name: 'Hettie Prosacco',
    count: 60096,
    accessUtilizationGraphStaticData: [
      {
        x: 70,
        y: 24,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '255b969f-6d07-4a4b-866a-7c08dd9ea1e5',
    name: 'Tremaine Luettgen',
    count: 49482,
    accessUtilizationGraphStaticData: [
      {
        x: 62,
        y: 72,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '4b94fb22-e97c-42e3-ba16-9fd3b04217e1',
    name: 'Terrell Altenwerth',
    count: 48776,
    accessUtilizationGraphStaticData: [
      {
        x: 59,
        y: 33,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '4cc4d609-0f7b-40c1-bfaf-0c7f49f55514',
    name: 'Alexane Schinner',
    count: 41403,
    accessUtilizationGraphStaticData: [
      {
        x: 61,
        y: 57,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '0f713df2-0f49-4686-921f-87d7ce1bff63',
    name: 'Graciela Schulist',
    count: 61991,
    accessUtilizationGraphStaticData: [
      {
        x: 52,
        y: 30,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: 'ea6eae48-d0e7-40de-8d7b-df33a1d0cbc0',
    name: 'Rodrigo Gleason',
    count: 20070,
    accessUtilizationGraphStaticData: [
      {
        x: 52,
        y: 26,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '12097265-7da2-41be-96a8-65c2b02f015c',
    name: 'Raina Swift',
    count: 3821,
    accessUtilizationGraphStaticData: [
      {
        x: 61,
        y: 40,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '7e41353f-1574-4805-a853-fc7291def548',
    name: 'Jeremie Fahey',
    count: 36297,
    accessUtilizationGraphStaticData: [
      {
        x: 64,
        y: 95,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '82ec65c8-1893-48e7-9659-9617016ca3b8',
    name: 'Gust Walker',
    count: 39699,
    accessUtilizationGraphStaticData: [
      {
        x: 65,
        y: 87,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '9081a800-b537-4954-a433-2a7c9c6c4bd5',
    name: 'Hobart Metz',
    count: 9511,
    accessUtilizationGraphStaticData: [
      {
        x: 64,
        y: 35,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '75587263-b2a3-4911-9106-1bdfab1960eb',
    name: "Eileen O'Conner",
    count: 7622,
    accessUtilizationGraphStaticData: [
      {
        x: 65,
        y: 12,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: 'a95fa60c-6c0e-4d2f-ab61-0535d52711ef',
    name: 'Providenci Schoen',
    count: 42904,
    accessUtilizationGraphStaticData: [
      {
        x: 69,
        y: 65,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '9e77a56b-bf68-431e-b668-67a640728662',
    name: 'Raoul Anderson',
    count: 44575,
    accessUtilizationGraphStaticData: [
      {
        x: 64,
        y: 49,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '056859e1-9426-4c59-9c9f-5a2dee31a53e',
    name: 'Mandy Lockman',
    count: 57117,
    accessUtilizationGraphStaticData: [
      {
        x: 57,
        y: 23,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: 'b6aba5da-39b2-4fdd-8093-4200b64d6572',
    name: 'Malinda Grant',
    count: 23521,
    accessUtilizationGraphStaticData: [
      {
        x: 67,
        y: 48,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '0d473e46-bf26-4964-b8bb-3d0538b75810',
    name: 'Rory Hills',
    count: 29744,
    accessUtilizationGraphStaticData: [
      {
        x: 61,
        y: 56,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '41fbd4e9-2d24-49b0-a203-b8dc3b58add5',
    name: 'Wiley Kiehn',
    count: 54684,
    accessUtilizationGraphStaticData: [
      {
        x: 68,
        y: 67,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: 'beefcea2-b3f2-4c61-af23-ae5b2b3dd4d3',
    name: 'Nella Marvin',
    count: 47880,
    accessUtilizationGraphStaticData: [
      {
        x: 57,
        y: 22,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '7468c52b-5c48-45c2-bea5-e2848a06a195',
    name: 'Fiona Dooley',
    count: 48643,
    accessUtilizationGraphStaticData: [
      {
        x: 52,
        y: 41,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '5b9425a1-a976-4d8a-9301-31144e6952ca',
    name: 'Vesta Ullrich',
    count: 8507,
    accessUtilizationGraphStaticData: [
      {
        x: 54,
        y: 60,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '6923746d-0ccb-4584-82d4-8aa75d31dcc1',
    name: 'Bradford Lakin',
    count: 14269,
    accessUtilizationGraphStaticData: [
      {
        x: 62,
        y: 61,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '40a9253e-9877-4f64-a932-2b92b877fd64',
    name: 'Valerie Tremblay',
    count: 43993,
    accessUtilizationGraphStaticData: [
      {
        x: 67,
        y: 77,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '5e20b621-ae8e-42e0-b81f-d1c2e98d04b0',
    name: 'Elinore Doyle',
    count: 47947,
    accessUtilizationGraphStaticData: [
      {
        x: 65,
        y: 59,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '23dfe652-a690-43e4-a00e-c8c94f05abd0',
    name: 'Rosina Keeling',
    count: 25405,
    accessUtilizationGraphStaticData: [
      {
        x: 55,
        y: 11,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '01170a15-6803-4e51-8381-03f6a950e80c',
    name: 'Maximo Thiel',
    count: 12099,
    accessUtilizationGraphStaticData: [
      {
        x: 56,
        y: 28,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: 'e60036ec-38db-4e2d-a294-7ddab1b5b722',
    name: 'Kaden Cummerata',
    count: 48320,
    accessUtilizationGraphStaticData: [
      {
        x: 70,
        y: 18,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '67511690-bf98-4c93-8e6a-112e012374a1',
    name: 'Rebecca Ledner',
    count: 23033,
    accessUtilizationGraphStaticData: [
      {
        x: 58,
        y: 48,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '4e33e248-73fe-4e02-bb6d-2936af021716',
    name: 'Fabiola Lockman',
    count: 52636,
    accessUtilizationGraphStaticData: [
      {
        x: 68,
        y: 50,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: 'b128a45e-05f5-43e1-b914-78c8003f988a',
    name: 'Marcelle Blick',
    count: 1590,
    accessUtilizationGraphStaticData: [
      {
        x: 52,
        y: 19,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: 'b56678c5-bcab-4374-a386-6beee46a97c7',
    name: 'Deonte Bosco',
    count: 7568,
    accessUtilizationGraphStaticData: [
      {
        x: 57,
        y: 76,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: 'd3c099a2-6ec3-4af3-9ae2-9b1007b72759',
    name: 'Malinda Schulist',
    count: 22654,
    accessUtilizationGraphStaticData: [
      {
        x: 51,
        y: 24,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '19f5dedc-d6b7-4fa0-bc44-77b2f7fb953e',
    name: 'Trinity Wehner',
    count: 52109,
    accessUtilizationGraphStaticData: [
      {
        x: 64,
        y: 96,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '041f265a-b365-4fe4-b825-2180f1ee0566',
    name: 'Emmett Schmitt',
    count: 51218,
    accessUtilizationGraphStaticData: [
      {
        x: 53,
        y: 24,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: 'ca83eacb-d1c1-463f-ba90-48b19c1d49e4',
    name: 'Blair Padberg',
    count: 59401,
    accessUtilizationGraphStaticData: [
      {
        x: 54,
        y: 81,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: 'f7d87bb3-6cc3-496f-b798-f4547fe32281',
    name: 'Justice Emmerich',
    count: 51309,
    accessUtilizationGraphStaticData: [
      {
        x: 56,
        y: 66,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '29c00931-9b56-4cdb-b806-03f93e11f537',
    name: 'Hulda Dickinson',
    count: 51390,
    accessUtilizationGraphStaticData: [
      {
        x: 58,
        y: 78,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '9c598cb0-a1af-49bc-bc96-7d6924a47d02',
    name: 'Dolores Green',
    count: 32260,
    accessUtilizationGraphStaticData: [
      {
        x: 65,
        y: 24,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
  {
    id: '32d8934c-e054-42aa-b142-aa99a46dfbd7',
    name: 'Beverly Schuster',
    count: 54533,
    accessUtilizationGraphStaticData: [
      {
        x: 51,
        y: 17,
        z: 50,
        name: 'Team Member',
      },
    ],
  },
]
