import { Chip, CustomIconName, Icon, theme } from '@ketch-com/deck'
import { Box, styled, Typography } from '@mui/material'
import { FilterContextProps } from '../context/AssetsTabFilterContext'

const CloseButtonBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 28,
  height: 42,
  backgroundColor: theme.palette.Black.o8,
  borderRadius: 5,
  cursor: 'pointer',
})

const ChipContentBox = styled(Box)({
  display: 'flex',
  height: 60,
  paddingY: 8,
  paddingX: 4,
  gap: 8,
  alignItems: 'center',
})

interface DrillDownTrackerProps {
  onClose?: () => void
  drillDownLineage: FilterContextProps['drillDownLineage']
  traverseLineage: FilterContextProps['traverseLineage']
  handleClearDrilldown: FilterContextProps['handleClearDrilldown']
}

export const DrillDownTracker: React.FC<DrillDownTrackerProps> = ({
  onClose,
  drillDownLineage,
  traverseLineage,
  handleClearDrilldown,
}) => {
  return (
    <Box display="flex" marginY={1} gap={1} alignItems="center">
      <CloseButtonBox
        onClick={() => {
          handleClearDrilldown()
          if (onClose) {
            onClose()
          }
        }}
      >
        <Icon name="OCross" />
      </CloseButtonBox>
      {drillDownLineage.map((item, idx) => (
        <Box key={item.displayDetails.resourceType}>
          <Chip
            sx={{
              '&.MuiChip-root': {
                backgroundColor: 'rgba(0,0,0,0)',
                backgroundImage: 'unset',
              },
              height: '42px',
              paddingY: 1,
            }}
            label={
              <ChipContentBox>
                <Icon name={item.displayDetails.iconName as CustomIconName} />
                <Box display="flex" flexDirection="column">
                  <Typography variant="label">{item.displayDetails.name}</Typography>
                  <Box>
                    <Typography variant="fadedLabel">{item.displayDetails.resourceType}</Typography>

                    <Typography variant="footnote">{` | ${item.displayDetails.childResourceType} ${item.displayDetails.childResourceCount}`}</Typography>
                  </Box>
                </Box>
              </ChipContentBox>
            }
            onClick={() => {
              if (idx === 0) {
                handleClearDrilldown()
                if (onClose) {
                  onClose()
                }
              } else {
                traverseLineage(idx)
              }
            }}
          />
          {idx !== drillDownLineage.length - 1 && <Icon name="OArrowCRight" iconColor={theme.palette.Common.Divider} />}
        </Box>
      ))}
    </Box>
  )
}
