import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { GridRowParams } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { Pagination } from 'api/common/paginatedQuery'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import {
  FindSubscriptionTopicsResponseBodyDTO,
  SubscriptionTopicDTO,
  SubscriptionContactMethodDTO,
} from '@ketch-com/figurehead'
import { LegalBaseDTO } from 'interfaces/legalBases/legalBase'
import { DataGrid, EmptyState } from '@ketch-com/deck'
import { FormMode } from 'interfaces/formModes/formMode'
import { MaybeNull } from 'interfaces/common'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateCommon.svg'
import { subscriptionTopicsColumns } from './subscriptionTopicsUtils'
import { INSUFFICIENT_PERMISSIONS_TOOLTIP } from 'utils/constants/INSUFFICIENT_PERMISSIONS_TOOLTIP'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isLoading: boolean
  subscriptionTopics: MaybeNull<FindSubscriptionTopicsResponseBodyDTO>
  pagination: Pagination
  legalBases: LegalBaseDTO[]
  subscriptionContactMethods: SubscriptionContactMethodDTO[]
}

const PREFIX = 'SubscriptionTopics'

const classes = {
  translationsChip: `${PREFIX}-translationsChip`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.translationsChip}`]: {
    backgroundColor: palette.marine.main,
  },
}))

export const SubscriptionTopics: React.FC<Props> = ({
  isLoading,
  subscriptionTopics,
  pagination,
  legalBases,
  subscriptionContactMethods,
}) => {
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()
  const isEntitledToCreateSubscriptions = isEntitled(ENTITLEMENTS.SUBSCRIPTIONS)
  const isPermittedToCreateSubscriptions = isPermitted(PERMISSIONS.SUBSCRIPTIONS_WRITE)
  const canCreateSubscription = isEntitledToCreateSubscriptions && isPermittedToCreateSubscriptions
  const topics = subscriptionTopics?.topics || ([] as SubscriptionTopicDTO[])

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Experiences', link: RoutesManager.deployment.experiencesV2.root.getURL() },
    { title: 'Subscriptions', link: RoutesManager.policyCenter.subscriptions.root.getURL() },
  ]

  const handleRedirectToCreateSubscription = () => {
    navigate(
      RoutesManager.policyCenter.subscriptions.upsert.subscriptionTopic.root.getURL({
        code: 'new',
        formMode: FormMode.CREATE,
      }),
    )
  }

  const columns = subscriptionTopicsColumns(legalBases, subscriptionContactMethods)

  const totalPages = pagination.totalPages
  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  return (
    <StyledBox>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      {!isLoading && topics.length === 0 ? (
        <Box
          sx={{
            padding: '34px 0',
            borderRadius: '11px',
            height: '100%',
            backgroundColor: 'white.main',
          }}
        >
          <EmptyState
            title="There are no Subscriptions for this organization"
            subTitle="Create your first Subscription to populate the table."
            customIcon={<EmptyStateIcon />}
            primaryButtonTitle="Create Subscription"
            primaryButtonTooltipTitle={!canCreateSubscription ? INSUFFICIENT_PERMISSIONS_TOOLTIP : undefined}
            primaryButtonProps={{
              size: 'large',
              onClick: handleRedirectToCreateSubscription,
              disabled: !canCreateSubscription,
            }}
          />
        </Box>
      ) : (
        <DataGrid
          sx={{
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-columnHeader': {
              '&:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                backgroundImage: 'unset',
              },
            },
            border: 'unset',
          }}
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: false,
            columns: columns.map(column => column.field),
            expand: true,
          }}
          onRowClick={({ row }: GridRowParams<SubscriptionTopicDTO>) => {
            navigate(RoutesManager.policyCenter.subscriptions.view.subscriptionTopic.root.getURL({ code: row.code }))
          }}
          getRowHeight={() => 'auto'}
          columns={columns}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          getRowId={row => row.code}
          loading={isLoading}
          rows={topics}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[rowsPerPage]}
          rowCount={pagination.totalResults}
          hideFooter={pagination.totalPages < 2}
        />
      )}
    </StyledBox>
  )
}
