import { useMemo } from 'react'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { WorkflowActivityCategory } from 'interfaces/workflowActivities/workflowActivity'
import { workflowExecutionStepStatusVariantMap } from '../../../interfaces'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'

export const getBackgroundColorFromCategory = (category?: string) => {
  const isStandard = category === WorkflowActivityCategory.STANDARD || category === WorkflowActivityCategory.DSR
  const isGateway = category === WorkflowActivityCategory.GATEWAY
  const isAppLike = [
    WorkflowActivityCategory.APP,
    WorkflowActivityCategory.APP_LEVEL_2,
    WorkflowActivityCategory.CONNECTOR,
  ].includes(category! as WorkflowActivityCategory)
  const backgroundColor = isStandard
    ? 'heliotrope.main'
    : isGateway
    ? 'darkDusk.main'
    : isAppLike
    ? 'bone.main'
    : 'bone.main'

  return backgroundColor
}

export const useCurrentActivityCellRendererUtils = ({ rightInvocation }: { rightInvocation: RightInvocationDTO }) => {
  const workflowExecution = rightInvocation?.appealWorkflowExecution ?? rightInvocation?.workflowExecution

  const pendingSteps = (workflowExecution?.steps || [])?.filter?.(
    ({ status }) =>
      (status || 99) === WorkflowExecutionStepStatus.IN_PROGRESS ||
      (status || 99) === WorkflowExecutionStepStatus.PENDING,
  )
  const hasPendingSteps = pendingSteps?.length > 0

  // last element in steps array is the current activity.
  // This is a naive solution because the possibility exists that
  // two or more elements are pending at the same time due to the "split" gateway.
  // See here for more info: https://ketch-com.atlassian.net/browse/KD-7005?focusedCommentId=17938
  // A more robust solution would require BE to calculate and send additional data.
  const currentActivityWorkflowExecutionStep = hasPendingSteps
    ? pendingSteps?.[pendingSteps.length - 1]
    : workflowExecution?.steps?.[workflowExecution.steps.length - 1]

  const currentFailedSteps =
    workflowExecution?.steps?.filter(({ status }) => status === WorkflowExecutionStepStatus.FAIL) || []

  // Displays of multiple current steps should also include failed steps
  const currentActivityWorkflowExecutionSteps = (pendingSteps.length > 1 ? pendingSteps : []).concat(
    ...currentFailedSteps,
  )

  const currentInProgressSteps = currentActivityWorkflowExecutionSteps.filter(
    ({ status }) => status === WorkflowExecutionStepStatus.IN_PROGRESS,
  ).length

  const currentTimers = currentActivityWorkflowExecutionSteps
    .filter(({ dueAt, status }) => dueAt && status !== WorkflowExecutionStepStatus.SUCCESS)
    .map(({ stepName, dueAt }) => ({ stepName, dueAt }))

  const dueAt = currentActivityWorkflowExecutionStep?.dueAt
  const iconURL = currentActivityWorkflowExecutionStep?.icon?.url
  const stepName = currentActivityWorkflowExecutionStep?.stepName
  const currentActivityStepCode = currentActivityWorkflowExecutionStep?.activityStepCode
  const currentActivityStatus = currentActivityWorkflowExecutionStep?.status
  const assignedUser = currentActivityWorkflowExecutionStep?.currentDesigneeInfo
  const category = currentActivityWorkflowExecutionStep?.activityCategory
  const backgroundColor = useMemo(() => getBackgroundColorFromCategory(category), [category])

  const stepVariant =
    workflowExecutionStepStatusVariantMap?.[currentActivityStatus as keyof typeof workflowExecutionStepStatusVariantMap]

  const shouldShowStatus =
    currentActivityStatus === WorkflowExecutionStepStatus.FAIL ||
    currentActivityStatus === WorkflowExecutionStepStatus.INVALID_CREDENTIALS

  const isWorkflowFinalized = rightInvocation.finalization?.finalizedAt

  return {
    assignedUser,
    backgroundColor,
    currentActivityStatus,
    currentActivityStepCode,
    iconURL,
    stepName,
    stepVariant,
    shouldShowStatus,
    category,
    dueAt,
    currentActivityWorkflowExecutionSteps,
    currentFailedSteps: currentFailedSteps?.length, // Only the count is currently needed - return the whole array if more data becomes needed
    currentInProgressSteps, // Only the count is currently needed - return the whole array if more data becomes needed
    currentTimers,
    workflowExecution,
    isWorkflowFinalized,
  }
}
