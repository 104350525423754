import { Routes, Route } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { PurposeStackTabTranslations } from './PurposeStackTabTranslations'
import { PurposeStackTabOverview } from './PurposeStackTabOverview'

// type
import { StackDTO } from '@ketch-com/figurehead'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
type Props = {
  isLoading: boolean
  languages: LanguageWithStatusDTO[]
  purposeStack: StackDTO | null
}

export const PurposeStackViewRoutes: React.FC<Props> = ({ isLoading, languages, purposeStack }) => {
  return (
    <Routes>
      <Route path="/" element={<PurposeStackTabOverview purposeStack={purposeStack} isLoading={isLoading} />} />
      <Route
        path={RoutesManager.deployment.purposeStacks.view.overview.root.pattern}
        element={<PurposeStackTabOverview purposeStack={purposeStack} isLoading={isLoading} />}
      />
      <Route
        path={RoutesManager.deployment.purposeStacks.view.translations.root.pattern}
        element={<PurposeStackTabTranslations purposeStack={purposeStack} languages={languages} />}
      />
      <Route path={RoutesManager.deployment.purposeStacks.view.overview.root.pattern} />
    </Routes>
  )
}
