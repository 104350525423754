import { ReactComponent as GoogleIcon } from 'assets/icons/btn_google_light_normal.svg'
import { styled } from '@mui/styles'

const PREFIX = 'GoogleSignInButton'

const classes = {
  button: `${PREFIX}-button`,
}

type Props = {
  onClick: () => void
  disabled: boolean
}

// Google button style guidelines: https://developers.google.com/identity/branding-guidelines#font
// Additional CSS adapted from https://github.com/anthonyjgrove/react-google-login
const StyledButton = styled('button')(() => ({
  [`&.${classes.button}`]: {
    backgroundColor: '#fff',
    display: 'inline-flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, .54)',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, .24), 0 0 2px 0 rgba(0, 0, 0, .24)',
    padding: '0 12px 0 0',
    borderRadius: 2,
    border: '1px solid transparent',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Roboto, sans-serif',
    '&:hover:not([disabled])': {
      cursor: 'pointer',
      opacity: 0.8,
    },
    '&:active:not([disabled])': {
      cursor: 'pointer',
      backgroundColor: '#eee',
      color: 'rgba(0, 0, 0, .54)',
      opacity: 1,
    },
    '&:disabled': {
      opacity: 0.6,
    },
  },
}))

export const GoogleSignInButton: React.FC<Props> = ({ onClick, disabled }) => {
  return (
    <StyledButton className={classes.button} disabled={disabled} onClick={onClick} type="button">
      <GoogleIcon />
      <span>Sign In With Google</span>
    </StyledButton>
  )
}
