import { useState } from 'react'
import { useDebounce } from 'react-use'
import { useSearchParams } from 'react-router-dom'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { ASSET_TYPE, RELATIONSHIP_TYPE } from 'pages/assetManager/relationshipMapping/constants'
import { MaybeNull } from 'interfaces'
import { GetRelationship } from 'pages/assetManager/interfaces'

export const useRelationshipMapping = () => {
  const [relationshipSearch, setRelationshipSearch] = useState('')
  const [searchString, setSearchString] = useState(relationshipSearch || '')
  const [assetType, setAssetType] = useState<ASSET_TYPE>(ASSET_TYPE.DATABASE)
  const [relationshipType, setRelationshipType] = useState<RELATIONSHIP_TYPE>(RELATIONSHIP_TYPE.CREATED)
  const { setQueryParam, removeQueryParam } = useQueryParams<{ query?: string }>()
  const [isCreateRelationshipMappingModalOpen, setIsCreateRelationshipMappingModalOpen] = useState(false)
  const [deleteRelationship, setDeleteRelationship] = useState<MaybeNull<GetRelationship>>(null)
  const [isHowItWorksModalOpen, setIsHowItWorksModalOpen] = useState(false)
  const [searchParams] = useSearchParams()

  useDebounce(
    () => {
      if (relationshipSearch && relationshipSearch !== searchParams.get('query')) {
        setQueryParam('query', relationshipSearch)
      }

      if (!relationshipSearch) {
        removeQueryParam('query')
      }
    },
    500,
    [relationshipSearch],
  )

  return {
    isCreateRelationshipMappingModalOpen,
    setIsCreateRelationshipMappingModalOpen,
    deleteRelationship,
    setDeleteRelationship,
    searchString,
    setSearchString,
    relationshipSearch,
    setRelationshipSearch,
    assetType,
    setAssetType,
    relationshipType,
    setRelationshipType,
    isHowItWorksModalOpen,
    setIsHowItWorksModalOpen,
  }
}
