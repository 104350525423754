import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, DataGrid, EmptyState, ListLayout } from '@ketch-com/deck'
import Box from '@mui/material/Box'

// utils
import { Pagination } from 'api/common/paginatedQuery'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

// types
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useDataSubjectTypesListColumns } from './dataSubjectTypesListGridUtils'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
type Props = {
  isLoading: boolean
  isFetching: boolean
  dataSubjectTypes: DataSubjectTypeDTO[]
  pagination: Pagination
}

export const DataSubjectTypesList: React.FC<Props> = ({ isLoading, isFetching, pagination, dataSubjectTypes }) => {
  const navigate = useNavigate()

  const breadcrumbs = [
    {
      title: 'Settings',
      link: RoutesManager.settings.root.getURL(),
    },
    { title: 'Subject Types', link: RoutesManager.settings.dataSubjectTypes.root.getURL() },
  ]

  const { isPermitted } = useIsPermitted()
  const isPermittedToCreateDataSubjectTypes = isPermitted(PERMISSIONS.USER_ADMIN)

  const canCreateDataSubjectTypes = isPermittedToCreateDataSubjectTypes

  const handleCreateDataSubjectClick = () => {
    navigate(RoutesManager.settings.dataSubjectTypes.upsert.root.getURL({ id: NEW_ITEM_ID }))
  }

  const totalPages = pagination.totalPages
  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  const columns = useDataSubjectTypesListColumns({
    dataSubjectTypes,
  })
  const isGridLoading = isLoading || isFetching

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ListLayout
        sx={{
          '&.DeckLayout-wrapper .DeckLayout-content': {
            minHeight: 'auto',
          },
        }}
        headerTitle="Subject Types"
        customHeaderRightActionComponent={
          isPermittedToCreateDataSubjectTypes && <Button onClick={handleCreateDataSubjectClick}>Create Type</Button>
        }
        headerProps={{
          isDivider: true,
        }}
      >
        {!isFetching && !dataSubjectTypes.length ? (
          <Box
            sx={{
              backgroundColor: 'bone.main',
              padding: 5,
              borderRadius: '11px',
            }}
          >
            <EmptyState
              title="There are no Data Subject Types for this organization"
              subTitle={canCreateDataSubjectTypes ? 'Create your first one to populate the table.' : undefined}
              iconName="OProfile"
              primaryButtonTitle={canCreateDataSubjectTypes ? 'Create Data Subject Profile' : undefined}
              primaryButtonProps={{
                onClick: () => handleCreateDataSubjectClick(),
              }}
            />
          </Box>
        ) : (
          <Box flexDirection="column" display="flex">
            <DataGrid
              sx={{
                ...(isLoading && {
                  '& .MuiDataGrid-virtualScroller': {
                    height: '300px',
                  },
                }),
              }}
              autosizeOnMount
              autosizeOptions={{
                expand: true,
                includeHeaders: true,
                includeOutliers: false,
              }}
              getRowHeight={() => 'auto'}
              columns={columns}
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              disableRowSelectionOnClick
              disableRowHoverStates
              disableMultipleRowSelection
              getRowId={row => row.code}
              rows={dataSubjectTypes || []}
              loading={isGridLoading}
              noRowsOverlayProps={{
                buttonTitle: '',
              }}
              pagination
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={onPaginationModelChange}
              pageSizeOptions={[rowsPerPage]}
              rowCount={pagination.totalResults}
              hideFooter={pagination.totalPages < 2}
            />
          </Box>
        )}
      </ListLayout>
    </>
  )
}
