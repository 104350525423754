import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CookieCategory } from 'interfaces/cookies/cookieCategory'

interface CookiesListFilterState {
  query: string
  category: CookieCategory | null
  status: number | null
}

const initialState = {
  query: '',
  category: null,
  status: null,
} as CookiesListFilterState

export const cookiesListFilterSlice = createSlice({
  name: 'cookiesListFilterSlice',
  initialState,
  reducers: {
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },

    setCategory: (state, action: PayloadAction<number | null>) => {
      state.category = action.payload
    },

    setStatus: (state, action: PayloadAction<number | null>) => {
      state.status = action.payload
    },
  },
})

export const { setQuery, setCategory, setStatus } = cookiesListFilterSlice.actions

export default cookiesListFilterSlice.reducer
