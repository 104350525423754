import React, { useContext } from 'react'

import Typography from '@mui/material/Typography'
import { FormFieldDetailViewContext } from '../context/FormFieldDetailViewContext'
import { FormFieldTypeDTO } from '@ketch-com/figurehead'
import { LanguageCellRenderer } from '.'
import { DataGrid, InfoRow, TableCell } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { TranslationDTO } from '../../upsert/interfaces'
import { FormInlineEdit } from 'components/form/FormInlineEdit'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type Props = {}

type DataGridTranslationType = TranslationDTO & {
  rowIndex: number
}

export const FormFieldViewTranslations: React.FC<Props> = () => {
  const { formField, enabledLanguages, formikProps, organizationLanguages } = useContext(FormFieldDetailViewContext)
  const { values } = formikProps
  const isCheckbox = formField?.type === FormFieldTypeDTO.CheckboxFormFieldType

  if (isCheckbox) return null

  const translationRows: DataGridTranslationType[] =
    values?.translations.map((translation, index) => ({ ...translation, rowIndex: index })) || []

  return (
    <>
      <InfoRow title="Display Name">
        <Typography variant="body">{formField?.title}</Typography>
      </InfoRow>

      {translationRows.length && (
        <DataGrid
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: false,
            expand: true,
          }}
          getRowHeight={() => 'auto'}
          columns={[
            {
              align: 'left',
              field: 'language',
              flex: 1,
              headerAlign: 'left',
              headerName: 'Language',
              sortable: false,
              renderCell: ({ row: translationEntry }: GridRenderCellParams<DataGridTranslationType>) => {
                return (
                  <TableCell>
                    <LanguageCellRenderer
                      enabledLanguages={enabledLanguages}
                      organizationLanguages={organizationLanguages}
                      translationEntry={translationEntry}
                    />
                  </TableCell>
                )
              },
            },
            {
              align: 'left',
              field: 'displayNameTranslation',
              flex: 1,
              headerAlign: 'left',
              headerName: 'Display Name Translation',
              sortable: false,
              renderCell: ({ row: { rowIndex } }: GridRenderCellParams<DataGridTranslationType>) => {
                const currentValue = formikProps?.values?.translations[rowIndex].fieldTitleTranslation
                const initialValue = formikProps?.initialValues?.translations[rowIndex].fieldTitleTranslation
                return (
                  <TableCell onBlur={e => e.stopPropagation()} onChange={e => e.stopPropagation()}>
                    <FormInlineEdit
                      onKeyDown={e => {
                        // if we don't do this, DataGrid will not let us type "space" inside the inline edit
                        if (e.code === 'Space') {
                          e.stopPropagation()
                        }
                      }}
                      onBlur={() => {
                        // do not submit if current value equals to initial value
                        if (currentValue === initialValue) return
                        formikProps.submitForm()
                      }}
                      shouldUpdateDebounced
                      formPropertyName={`translations[${rowIndex}].fieldTitleTranslation`}
                      placeholder="Add Translation"
                    />
                  </TableCell>
                )
              },
            },
          ]}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowSelectionOnClick
          disableRowHoverStates
          disableMultipleRowSelection
          getRowId={row => row.code}
          rows={translationRows}
          hideFooter
        />
      )}

      {!isCheckbox && (
        <InfoRow title="Hint Text">
          {formField?.hint ? <Typography variant="body">{formField?.hint}</Typography> : <EmptyValueRenderer />}
        </InfoRow>
      )}

      {translationRows.length && (
        <DataGrid
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: false,
            expand: true,
          }}
          getRowHeight={() => 'auto'}
          columns={[
            {
              align: 'left',
              field: 'language',
              flex: 1,
              headerAlign: 'left',
              headerName: 'Language',
              sortable: false,
              renderCell: ({ row: translationEntry }: GridRenderCellParams<DataGridTranslationType>) => {
                const englishName = enabledLanguages?.find(lang => lang.code === translationEntry?.code)?.englishName
                return <TableCell title={englishName} />
              },
            },
            {
              align: 'left',
              field: 'hintTextTranslation',
              flex: 1,
              headerAlign: 'left',
              headerName: 'Hint Text Translation',
              sortable: false,
              renderCell: ({ row: { rowIndex } }: GridRenderCellParams<DataGridTranslationType>) => {
                const initialValue = formikProps?.initialValues?.translations[rowIndex].uiHintTranslation
                const currentValue = formikProps?.values?.translations[rowIndex].uiHintTranslation
                return (
                  <TableCell onBlur={e => e.stopPropagation()} onChange={e => e.stopPropagation()}>
                    <FormInlineEdit
                      onKeyDown={e => {
                        // if we don't do this, DataGrid will not let us type "space" inside the inline edit
                        if (e.code === 'Space') {
                          e.stopPropagation()
                        }
                      }}
                      onBlur={() => {
                        // do not submit if current value equals to initial value
                        if (currentValue === initialValue) return
                        formikProps.submitForm()
                      }}
                      shouldUpdateDebounced
                      formPropertyName={`translations[${rowIndex}].uiHintTranslation`}
                      placeholder="Add Translation"
                    />
                  </TableCell>
                )
              },
            },
          ]}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowSelectionOnClick
          disableRowHoverStates
          disableMultipleRowSelection
          getRowId={row => row.code}
          rows={translationRows}
          hideFooter
        />
      )}
    </>
  )
}
