import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { updateAssignedDataCategoriesConfirmationStatus } from '../fetchers/updateAssignedDataCategoriesConfirmationStatus'

const useCustomMutation = createUseMutation({
  mutationFn: updateAssignedDataCategoriesConfirmationStatus,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateAssignedDataCategoriesConfirmationStatus = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
