import { mapFeatures } from 'pages/policyCenter/policyScopes/components/map/mapFeatures'
import combine from '@turf/combine'
import { AllGeoJSON, Feature, featureCollection, Point, Properties } from '@turf/helpers'
import { DataMapJurisdictionDTO, FindMapDataResponseBodyDTO } from '@ketch-com/figurehead'
import centroid from '@turf/centroid'
import { theme } from '@ketch-com/deck'
import { hexToRGBArray } from 'pages/insights/dataMap/components/DataMapVisualization/utils/colors'

interface RegionJurisdictionMap {
  [key: string]: { jurisdictionCode: string; jurisdictionName: string; jurisdictionRegion: string }
}
interface CodeJurisdictionMap {
  [key: string]: DataMapJurisdictionDTO
}
interface CodeResidenceCentroidMap {
  [key: string]: Feature<Point, Properties>
}

interface DataTransfers {
  from: {
    name: string
    coordinates: number[]
  }
  to: {
    name: string
    coordinates: number[]
  }
}
interface JurisdictionDataTransfers {
  [key: string]: {
    dataTransfers: DataTransfers[]
  }
}

export const dataMapFillColors = [
  hexToRGBArray(theme.palette.dataMapFill0.main),
  hexToRGBArray(theme.palette.dataMapFill1.main),
  hexToRGBArray(theme.palette.dataMapFill2.main),
  hexToRGBArray(theme.palette.dataMapFill3.main),
  hexToRGBArray(theme.palette.dataMapFill4.main),
  hexToRGBArray(theme.palette.dataMapFill5.main),
  hexToRGBArray(theme.palette.dataMapFill6.main),
  hexToRGBArray(theme.palette.dataMapFill7.main),
  hexToRGBArray(theme.palette.dataMapFill8.main),
  hexToRGBArray(theme.palette.dataMapFill9.main),
  hexToRGBArray(theme.palette.dataMapFill10.main),
  hexToRGBArray(theme.palette.dataMapFill11.main),
  hexToRGBArray(theme.palette.dataMapFill12.main),
  hexToRGBArray(theme.palette.dataMapFill13.main),
  hexToRGBArray(theme.palette.dataMapFill14.main),
]

export const dataMapStrokeColors = [
  hexToRGBArray(theme.palette.dataMapStroke0.main),
  hexToRGBArray(theme.palette.dataMapStroke1.main),
  hexToRGBArray(theme.palette.dataMapStroke2.main),
  hexToRGBArray(theme.palette.dataMapStroke3.main),
  hexToRGBArray(theme.palette.dataMapStroke4.main),
  hexToRGBArray(theme.palette.dataMapStroke5.main),
  hexToRGBArray(theme.palette.dataMapStroke6.main),
  hexToRGBArray(theme.palette.dataMapStroke7.main),
  hexToRGBArray(theme.palette.dataMapStroke8.main),
  hexToRGBArray(theme.palette.dataMapStroke9.main),
  hexToRGBArray(theme.palette.dataMapStroke10.main),
  hexToRGBArray(theme.palette.dataMapStroke11.main),
  hexToRGBArray(theme.palette.dataMapStroke12.main),
  hexToRGBArray(theme.palette.dataMapStroke13.main),
  hexToRGBArray(theme.palette.dataMapStroke14.main),
]

export function getJurisdictionData(visualizationData: FindMapDataResponseBodyDTO) {
  const { jurisdictions, dataResidencies } = visualizationData
  const regionToJurisdictionMap: RegionJurisdictionMap = {}
  const codeToJurisdictionMap: CodeJurisdictionMap = {}
  const codeToResidenceCentroidMap: CodeResidenceCentroidMap = {}
  const jurisdictionMappedFeatures: any = {}
  const jurisdictionDataTransfers: JurisdictionDataTransfers = {}
  const sanitizedData = jurisdictions?.filter((data, index) => data.code && data.name && data?.regions?.length)

  sanitizedData?.forEach?.((data, index) => {
    const { regions, code, name } = data
    regions?.forEach?.(region => {
      regionToJurisdictionMap[region.code!] = {
        jurisdictionCode: code!,
        jurisdictionName: name!,
        jurisdictionRegion: region.code!,
      }
    })
    codeToJurisdictionMap[code!] = data
  })

  mapFeatures?.forEach?.(feature => {
    const { jurisdictionCode } = regionToJurisdictionMap[feature?.id] || {
      jurisdictionCode: 'default',
    }
    // Skip USA from features list as we have states for USA
    if (feature.properties.name !== 'USA') {
      if (jurisdictionMappedFeatures?.[jurisdictionCode]?.length) {
        jurisdictionMappedFeatures?.[jurisdictionCode].push(feature)
      } else {
        jurisdictionMappedFeatures[jurisdictionCode] = [feature]
      }
    }
    dataResidencies?.forEach?.(residence => {
      if (feature?.id === residence?.code) codeToResidenceCentroidMap[residence.code] = centroid(feature as AllGeoJSON)
    })
  })

  const jurisdictionFeatureCollection = Object.keys(jurisdictionMappedFeatures).map((code, index) => {
    const features = jurisdictionMappedFeatures[code]
    const { name } = codeToJurisdictionMap[code] || { name: 'Global Standard' }
    const combinedFeatureCollection = combine(featureCollection(features))
    combinedFeatureCollection.features[0].properties = {
      id: code,
      jurisdictionName: name,
      jurisdictionFillColor:
        code === 'default' ? hexToRGBArray(theme.palette.dataMapFillDefault.main) : dataMapFillColors[index],
      jurisdictionStrokeColor:
        code === 'default' ? hexToRGBArray(theme.palette.dataMapStrokeDefault.main) : dataMapStrokeColors[index],
    } as any

    if (code !== 'default') {
      const jurisdictionCenter = centroid(features[0])
      jurisdictionDataTransfers[code] = {
        dataTransfers: [],
      }
      dataResidencies?.forEach?.(residence => {
        if (residence?.code && residence?.name && codeToResidenceCentroidMap?.[residence.code]) {
          const fromCoordinates = [
            jurisdictionCenter?.geometry?.coordinates?.[0],
            jurisdictionCenter?.geometry?.coordinates?.[1],
          ]
          const toCoordinates = [
            codeToResidenceCentroidMap?.[residence.code]?.geometry?.coordinates?.[0],
            codeToResidenceCentroidMap?.[residence.code]?.geometry?.coordinates?.[1],
          ]
          const fromResidence = {
            name: '',
            coordinates: fromCoordinates,
          }
          const toResidence = {
            name: residence.name as string,
            coordinates: toCoordinates,
          }

          if (fromCoordinates.join() !== toCoordinates.join()) {
            jurisdictionDataTransfers[code].dataTransfers.push({
              from: fromResidence,
              to: toResidence,
            })
          }
        }
      })
    }
    return combinedFeatureCollection
  })

  const jurisdictionGeoData = jurisdictionFeatureCollection.reduce((collection, currentCollection) => {
    collection.features.push(currentCollection.features[0])
    return jurisdictionFeatureCollection[0]
  })

  return {
    jurisdictionGeoData,
    jurisdictionDataTransfers,
  }
}
