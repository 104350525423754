import * as Yup from 'yup'
import { MaybeNull } from 'interfaces/common'
import { ApplicationDTO, ApplicationEnvironmentDTO } from 'interfaces/applications/application'

export type ApplicationDeploymentEnvironment = {
  id: ApplicationEnvironmentDTO['ID']
  code: ApplicationEnvironmentDTO['code']
  pattern?: ApplicationEnvironmentDTO['pattern']
  defaultDeploymentID: string
  deploymentID: string
  selected: boolean
}

export type ApplicationDeployFormValues = {
  environments: ApplicationDeploymentEnvironment[]
}

export function getInitialValues(application: MaybeNull<ApplicationDTO>): ApplicationDeployFormValues {
  return {
    environments: (application?.environments || []).map(environment => {
      const { code, pattern, deploymentID } = environment
      const deploymentIDValue = deploymentID?.value || ''

      return {
        id: environment.ID,
        code,
        pattern,
        defaultDeploymentID: deploymentIDValue,
        deploymentID: deploymentIDValue,
        selected: !!deploymentIDValue,
      }
    }),
  }
}

export const validationSchema = Yup.object().shape({
  environments: Yup.array().of(
    Yup.object().shape({
      deploymentID: Yup.string().when('selected', {
        is: true,
        then: Yup.string().required('Required'),
      }),
    }),
  ),
})

export const getSelectionMetadata = ({ environments }: ApplicationDeployFormValues) => {
  const selectedCount = environments.map(({ selected }) => selected).filter(Boolean).length
  const totalCount = environments.length
  const value =
    selectedCount > 0 && selectedCount < totalCount ? ('indeterminate' as const) : selectedCount === totalCount

  return {
    selectedCount,
    totalCount,
    value,
  }
}

export const isDeploymentOverwritten = ({
  values,
  application,
}: {
  values: ApplicationDeployFormValues
  application: ApplicationDTO
}) =>
  (application.environments || []).some(environment => {
    const updatedEnvs = values.environments.map(env => ({
      ...env,
      ...(!env.selected && {
        deploymentID: '',
      }),
    }))

    const updatedEnv = updatedEnvs.find(updatedEnvironment => updatedEnvironment.code === environment.code)
    const initialDeploymentID = environment.deploymentID?.value
    const wasInitiallyDeployed = !!initialDeploymentID

    return wasInitiallyDeployed && initialDeploymentID !== updatedEnv?.deploymentID
  })
