import { ApiQueryKeys } from 'api/common/queryKeys'
import { CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { useAuth } from 'utils/hooks/useAuth'
import { createUsePaginatedQueryPreservedPage } from 'api/common/createUsePaginatedQueryPreservedPage'
import { fetchWebTagsV2 } from '../fetchers/fetchWebTagsV2'

const useCustomPaginatedQuery = createUsePaginatedQueryPreservedPage({
  queryKey: ApiQueryKeys.webTagsPaginated,
  queryFn: fetchWebTagsV2,
  select: res => res?.data,
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery, 'organizationCode'>

export const useWebTagsPaginatedPreservePaginationV2 = (config: QueryConfig) => {
  const { userData } = useAuth()

  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config!.params,
      organizationCode: userData.organizationCode!,
    },
  })
}
