import { useUserMe } from 'api/users/queries/useUserMe'
import { useMemo } from 'react'

export const useIsExperienceServerAdmin = () => {
  const { data: user, isLoading: isUserLoading } = useUserMe()

  const isUserExperienceServerAdmin = useMemo(
    () => !!user?.roles.find(role => role.code === 'experience_server_admin'),
    [user?.roles],
  )

  return { isUserExperienceServerAdmin, isUserLoading }
}
