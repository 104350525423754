import React from 'react'
import { useNavigate } from 'react-router-dom'

import { FormMode } from 'interfaces/formModes/formMode'
import { ExperienceDTO } from 'interfaces/experiences/experience'
import { ExperienceType, EXPERIENCE_TYPE_NAMES } from 'interfaces/experiences/experienceType'
import { ExperienceUrlTypeParam } from 'interfaces/experiences/experienceUrlTypeParam'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { Pagination } from 'api/common/paginatedQuery'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { SENSITIVE_EXPERIENCE_STATUSES } from 'interfaces/experiences/experienceStatus'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ButtonActions } from 'components/ui-kit/buttonActions/ButtonActions'
import { Button } from 'components/ui-kit/button/Button'
import { Badge } from 'components/ui-kit/badge/Badge'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { IssuesCellRenderer } from 'components/ui-layouts/table/cellRenderers/IssuesCellRenderer'
import { Status } from 'components/ui-kit/status/Status'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { ViewListLayout } from 'components/ui-layouts/viewListLayout/ViewListLayout'
import { ViewListLayoutContent } from 'components/ui-layouts/viewListLayout/ViewListLayoutContent'
import { ViewListLayoutHeader } from 'components/ui-layouts/viewListLayout/ViewListLayoutHeader'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { ExperiencesListDropdown } from 'pages/consentAndRights/experiences/list/ExperiencesListDropdown'

import { ReactComponent as EmptyStateExperiencesIcon } from 'assets/icons/emptyState/EmptyStateExperiences.svg'
import { TimesUsedCellRenderer } from 'components/ui-layouts/table/cellRenderers/TimesUsedCellRenderer'
import { AssociatedFormTemplatesCountTooltipTitle } from '../components/AssociatedFormTemplatesCountTooltipTitle'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isLoading: boolean
  isFetching: boolean
  pagination: Pagination
  experiences: ExperienceDTO[]
}

export const ExperiencesList: React.FC<Props> = ({ experiences, isLoading, isFetching, pagination }) => {
  const navigate = useNavigate()

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Experiences', link: RoutesManager.deployment.experiencesV2.root.getURL() },
  ]

  const { isPermitted } = useIsPermitted()
  const isPermittedToCreateExperiences = isPermitted(PERMISSIONS.EXP_WRITE)

  const { isEntitled } = useIsEntitled()
  const isEntitledToCreateExperience = isEntitled(ENTITLEMENTS.EXPERIENCES)
  const isEntitledToConsentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)
  const isEntitledToRights = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)

  const canCreateExperiences = isPermittedToCreateExperiences && isEntitledToCreateExperience

  const handleRedirectToCreateConsent = () => {
    navigate(
      RoutesManager.deployment.experiences.upsert.root.getURL({
        formMode: FormMode.CREATE,
        experienceType: ExperienceUrlTypeParam.CONSENT,
      }),
    )
  }

  const handleRedirectToCreatePreference = () => {
    navigate(
      RoutesManager.deployment.experiences.upsert.root.getURL({
        formMode: FormMode.CREATE,
        experienceType: ExperienceUrlTypeParam.PREFERENCE,
      }),
    )
  }

  return (
    <ViewListLayout>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ViewListLayoutHeader
        title="Experiences"
        actions={
          isPermittedToCreateExperiences && (
            <>
              {!isEntitledToCreateExperience || !(isEntitledToConsentAndDisclosure || isEntitledToRights) ? (
                <>
                  <Button
                    disabled={
                      !isEntitledToCreateExperience || !(isEntitledToConsentAndDisclosure || isEntitledToRights)
                    }
                    tooltip={
                      !isEntitledToCreateExperience
                        ? 'No experience entitlements remaining, please delete one or contact your admin for more.'
                        : null
                    }
                    onClick={() => {
                      if (isEntitledToConsentAndDisclosure) {
                        handleRedirectToCreateConsent()
                      } else {
                        handleRedirectToCreatePreference()
                      }
                    }}
                  >
                    Create Experience
                  </Button>
                </>
              ) : (
                <>
                  <ButtonActions
                    items={[
                      {
                        content: 'Consent & Disclosure',
                        onClick: () => {
                          handleRedirectToCreateConsent()
                        },
                        hidden: !isEntitledToConsentAndDisclosure,
                      },
                      {
                        content: 'Preference Management',
                        onClick: () => {
                          handleRedirectToCreatePreference()
                        },
                      },
                    ]}
                  >
                    Create Experience
                  </ButtonActions>
                </>
              )}
            </>
          )
        }
      />

      <ViewListLayoutContent>
        {!isFetching && !experiences.length ? (
          <EmptyState
            variant="list"
            icon={<EmptyStateExperiencesIcon />}
            title="There are no experiences for this organization"
            description={canCreateExperiences ? 'Create your first one to populate the table.' : null}
            actions={[
              {
                title: 'Create Preference Management',
                hidden: !canCreateExperiences && !isEntitledToRights && !isEntitledToConsentAndDisclosure,
                size: 'big',
                variant: 'secondary',
                onClick: handleRedirectToCreatePreference,
              },
              {
                title: 'Create Consent & Disclosure',
                hidden: !canCreateExperiences || !isEntitledToConsentAndDisclosure,
                size: 'big',
                onClick: handleRedirectToCreateConsent,
              },
            ]}
          />
        ) : (
          <TablePaginated
            rowIdKey="code"
            pending={isLoading}
            items={experiences}
            onRowClick={({ code }) => {
              navigate(RoutesManager.deployment.experiences.view.root.getURL({ code }))
            }}
            pagination={pagination}
            cellSettings={{
              issues: {
                width: 35,
                cellRenderer: ({ hasIssues }) => (
                  <IssuesCellRenderer hasIssues={hasIssues} entityType={ApplicationEntity.EXPERIENCE} />
                ),
              },
              experience: {
                label: 'Experience',
                cellRenderer: ({ name, code }) => <NameAndCodeCellRenderer name={name} code={code} />,
                width: 290,
              },
              type: {
                label: 'Type',
                width: 260,
                cellRenderer: ({ consent }) => (
                  <Badge variant="dark-blue">
                    {consent
                      ? EXPERIENCE_TYPE_NAMES[ExperienceType.CONSENT]
                      : EXPERIENCE_TYPE_NAMES[ExperienceType.PREFERENCE]}
                  </Badge>
                ),
              },
              status: {
                label: 'Status',
                width: 195,
                cellRenderer: ({ status }) =>
                  SENSITIVE_EXPERIENCE_STATUSES.includes(status) ? (
                    <Status variant="active">Deployed</Status>
                  ) : (
                    <Status variant="inactive">Undeployed</Status>
                  ),
              },
              forms: {
                label: 'Forms',
                width: 125,
                cellRenderer: experience => {
                  return (
                    <TimesUsedCellRenderer
                      timesUsed={experience?.formTemplates?.length || 0}
                      tooltipTitle={<AssociatedFormTemplatesCountTooltipTitle experience={experience} />}
                      emptyValueDisplayText="NA"
                    />
                  )
                },
              },
              lastUpdated: {
                label: 'Last Updated',
                width: 300,
                cellRenderer: ({ metadata }) => <FormattedDateRenderer date={metadata?.updatedAt} />,
              },
              dropDown: {
                width: 60,
                cellRenderer: experience => <ExperiencesListDropdown experience={experience} />,
              },
            }}
          />
        )}
      </ViewListLayoutContent>
    </ViewListLayout>
  )
}
