import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { useUpsertStack } from 'api/stacks/mutations/useUpsertStack'
import { useStack } from 'api/stacks/queries/useStack'
import { useStacks } from 'api/stacks/queries/useStacks'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { PurposeStacksCreateForm } from './PurposeStacksCreateForm'
import { PurposeStacksEditForm } from './PurposeStacksEditForm'
import { getSubmitValues, StackForm } from './utils/formValues'
import { JurisdictionsInOtherStacks } from './utils/types'
import { PurposeDataRole } from 'interfaces/purposes/purposeDataRole'

export const PurposeStacksUpsertContainer: React.FC = () => {
  const { code } = useParams<{ code: string }>()
  const isCreateMode = !code || code === 'new'
  const navigate = useNavigate()

  const { data: jurisdictions } = usePolicyScopes()
  const purposes = usePurposes().data?.filter(purpose => purpose.dataRole === PurposeDataRole.CONTROLLER)
  const { data: stack } = useStack({ params: { code: code! }, enabled: !isCreateMode })
  const { data: languages } = useOrganizationLanguages()
  const filteredLanguages = languages.filter(l => l.enabled)
  const { data: allStacks } = useStacks()
  const { mutateAsync: upsertStack } = useUpsertStack()

  const jurisdictionsInOtherStacks = useMemo(() => {
    const result = {} as JurisdictionsInOtherStacks

    allStacks?.forEach?.(({ name, jurisdictions }) => {
      if (stack?.name !== name) {
        jurisdictions?.forEach?.(({ code }) => (result[code || ''] = name))
      }
    })

    return result
  }, [allStacks, stack])

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Purpose Stacks', link: RoutesManager.deployment.purposeStacks.root.getURL() },
    { title: `${isCreateMode ? 'Create' : 'Edit'} Stack` },
  ]

  if ((!stack && !isCreateMode) || !jurisdictions || !purposes || !languages) {
    return <Spinner />
  }

  const submit = async (formData: StackForm) => {
    const response = await upsertStack({
      params: { code: code !== 'new' ? code : undefined, formData: getSubmitValues(formData, jurisdictions) },
    })

    const savedStackCode = response?.data?.stack?.id || code
    navigate(RoutesManager.deployment.purposeStacks.view.root.getURL({ code: savedStackCode }))
  }

  return isCreateMode ? (
    <PurposeStacksCreateForm
      breadcrumbs={breadcrumbs}
      purposes={purposes}
      jurisdictions={jurisdictions}
      languages={filteredLanguages}
      onSubmit={submit}
      jurisdictionsInOtherStacks={jurisdictionsInOtherStacks}
    />
  ) : (
    <PurposeStacksEditForm
      breadcrumbs={breadcrumbs}
      stack={stack!}
      purposes={purposes}
      jurisdictions={jurisdictions}
      languages={filteredLanguages}
      onSubmit={submit}
      jurisdictionsInOtherStacks={jurisdictionsInOtherStacks}
    />
  )
}
