import React, { useMemo } from 'react'
import { Formik } from 'formik'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import { Modal } from 'components/ui-kit/modal/Modal'
import { getInitialValues, getValidationSchema } from './utils'
import { CreateHookFormValues } from './utils/getInitialValues'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRadioGroup } from 'components/ui-kit/form/radioGroup/FormRadioGroup'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { FormTextArea } from 'components/ui-kit/form/textArea/FormTextArea'
import { useDmlHook } from 'api/dmlHooks/queries/useDmlHook'
import { Text } from 'components/ui-kit/typography/Text'

type Props = {
  isUpdatingAssetLabels?: boolean
  onSubmit?: (values: CreateHookFormValues) => void
  onCancel: () => void
  hookToEditId?: string
}

export const CreateHookModal: React.FC<Props> = ({ onSubmit = () => {}, onCancel, hookToEditId }) => {
  const { data: hookToEdit, isLoading: isLoadingHookToEdit } = useDmlHook({
    enabled: !!hookToEditId,
    params: {
      hookId: hookToEditId || '',
    },
  })
  const initialValues = useMemo(() => getInitialValues({ hook: hookToEdit }), [hookToEdit])
  const validationSchema = getValidationSchema()
  const technologyOptions = [
    {
      id: 'oracle',
      name: 'Oracle',
    },
    {
      id: 'mysql',
      name: 'MySQL',
    },
    {
      id: 'mariadb',
      name: 'MariaDB',
    },
  ]

  const operationOptions = [
    {
      id: '1',
      name: 'Stored Procedure',
    },
  ]

  const typeOptions = [
    { id: '1', name: 'PRE', disabled: !!hookToEditId },
    { id: '2', name: 'POST', disabled: !!hookToEditId },
  ]

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, handleSubmit }) => {
        return (
          <Modal
            cancelBtnLabel="Cancel"
            contentWidth={654}
            isLoading={isLoadingHookToEdit}
            isRemoveMaxHeight
            isSaving={isSubmitting}
            isValid={isValid}
            onCancelBtnClick={onCancel}
            onSubmitBtnClick={handleSubmit}
            submitBtnLabel={hookToEditId ? 'Update' : 'Create'}
            title={hookToEditId ? 'Edit Hook' : 'Create a Hook'}
            subtitle={
              hookToEditId
                ? 'Please note any edits to this Hook will apply immediately to any future DSR workflow executions involving assets assigned to this Hook.'
                : undefined
            }
          >
            <Box pt={3}>
              <Grid container spacing={3}>
                {/* Name Row */}

                <Grid item xs={6}>
                  <FormInput fullWidth required name="name" label="Name" placeholder="Example: Validation Hook" />
                </Grid>
                <Grid item xs={6} />

                {/* Type Row */}

                <Grid item xs={6}>
                  <FormRadioGroup
                    name="type"
                    label="Type"
                    layout="row"
                    valueKey="id"
                    renderLabel={({ name }) => name}
                    options={typeOptions}
                  />
                </Grid>
                <Grid item xs={6} />

                {/* Tech and Operation Row */}

                <Grid item xs={5.5}>
                  <FormDropdown
                    label="Technology"
                    valueKey="id"
                    disabled={!!hookToEditId}
                    required
                    fullWidth
                    name="technology"
                    placeholder="Select Technology"
                    items={technologyOptions}
                  />
                </Grid>

                <Grid item xs={5.5}>
                  <FormDropdown
                    label="Operation"
                    valueKey="id"
                    disabled={!!hookToEditId}
                    required
                    fullWidth
                    name="operation"
                    placeholder="Select Store Procedure"
                    items={operationOptions}
                  />
                </Grid>
                <Grid item xs={1} />

                {/* Code Row */}

                <Grid item xs={12}>
                  <FormTextArea
                    required
                    rows={5}
                    fullWidth
                    helperText={
                      <Box display="flex" alignItems="center" mb={1}>
                        <Text size={14}>
                          Please see{' '}
                          <a
                            href="https://docs.ketch.com"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none' }}
                          >
                            docs.ketch.com
                          </a>{' '}
                          for examples and expected syntax.
                        </Text>
                      </Box>
                    }
                    label="Code"
                    name="code"
                    placeholder="Paste your code here..."
                  />
                </Grid>
              </Grid>
            </Box>
          </Modal>
        )
      }}
    </Formik>
  )
}
