import React, { useState } from 'react'
import { Box, Typography, Tooltip } from '@mui/material'
import pluralize from 'pluralize'
import { NotFoundTracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { IconDictionary, theme, Accordion, AccordionDetails, AccordionSummary, Chip } from '@ketch-com/deck'
import {
  NotFoundTrackerProperties,
  NotFoundTrackerDelete,
} from 'pages/policyCenter/trackers/list/needsReviewTrackers/notFoundTrackers/components'
import { ProvenanceType } from 'pages/policyCenter/trackers/interfaces'
import { ProvenanceVariant } from 'pages/policyCenter/trackers/components'

type Props = {
  tracker: NotFoundTracker
}

export const NotFoundTrackerDetails: React.FC<Props> = ({ tracker = {} as NotFoundTracker }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Box my={1}>
      <Accordion
        key={tracker?.key}
        color={theme.palette.Black.o4}
        expanded={isExpanded}
        sx={{ border: `1px solid ${theme.palette.Black.o16}` }}
      >
        <AccordionSummary
          icon={IconDictionary.OArrowCRight}
          onClick={() => {
            setIsExpanded(isExpanded => !isExpanded)
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={36}
                height={36}
                borderRadius={1}
                sx={{ backgroundColor: ({ palette }) => palette.fadedGrey.main }}
              >
                <ProvenanceVariant provenance={tracker?.provenance} type={ProvenanceType.Icon} />
              </Box>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography variant="label">{tracker?.key}</Typography>
                {tracker?.instances?.length ? (
                  <Box>
                    <Tooltip
                      title={
                        <Box>
                          {tracker?.instances?.map((instances, index) => {
                            return (
                              <Box key={index} my={1}>
                                <Typography variant="label" color="white.main">
                                  {instances}
                                </Typography>
                              </Box>
                            )
                          })}
                        </Box>
                      }
                      placement="bottom"
                    >
                      <Typography variant="smallBody" color="darkDuskFaded.main">
                        {tracker?.instances?.length || 0} {pluralize('instance', tracker?.instances?.length)}
                      </Typography>
                    </Tooltip>
                  </Box>
                ) : null}
                {tracker?.dataSystem?.name || tracker?.dataSystem?.logoURL ? (
                  <Box display="flex" alignItems="center" gap={0.5}>
                    {tracker?.dataSystem?.logoURL ? (
                      <img src={tracker?.dataSystem?.logoURL} alt={tracker?.dataSystem?.name} width={12} height={12} />
                    ) : null}
                    {tracker?.dataSystem?.name ? (
                      <Typography variant="footnote" color="darkDusk.main">
                        {tracker?.dataSystem?.name}
                      </Typography>
                    ) : null}
                  </Box>
                ) : (
                  <Typography variant="footnote" color="darkDuskFaded.main">
                    Unknown System
                  </Typography>
                )}
              </Box>
            </Box>
            <Box display="flex" alignItems="center" gap={7}>
              <Chip
                size="small"
                label={`${tracker?.numProperties || 0} ${pluralize('property', tracker?.numProperties || 0)}`}
              />
              <NotFoundTrackerDelete tracker={tracker} />
            </Box>
          </Box>
        </AccordionSummary>

        <AccordionDetails>{isExpanded ? <NotFoundTrackerProperties tracker={tracker} /> : null}</AccordionDetails>
      </Accordion>
    </Box>
  )
}
