import React, { useContext } from 'react'
import { BuilderContext } from '../../../Builder'
import { RichTextRenderer } from '../../RichTextRenderer'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { Box } from '@mui/material'
import { ModalContainerPosition } from '@ketch-sdk/ketch-types'

const positionWidth: { [position: string]: string } = {
  [ModalContainerPosition.Center]: '632px',
  [ModalContainerPosition.Left]: '372px',
  [ModalContainerPosition.Right]: '372px',
}

export const ModalDescription: React.FC = () => {
  const { themeConfig, experienceConfig } = useContext(BuilderContext)

  // Theme config
  const textColor = getIn(themeConfig, themeUpsertFormKeys.modalDescriptionTextColor)
  const linkColor = getIn(themeConfig, themeUpsertFormKeys.modalDescriptionLinkColor)
  const underlineLinks = getIn(themeConfig, themeUpsertFormKeys.modalDescriptionUnderlineLinks)
  const position = getIn(themeConfig, themeUpsertFormKeys.modalContainerPosition)

  // Experience config
  const text = getIn(experienceConfig, experienceUpsertFormKeys.modalDescriptionText)

  return (
    <Box
      sx={{
        width: positionWidth[position],
        wordWrap: 'break-word',
      }}
    >
      <RichTextRenderer text={text} textColor={textColor} linkColor={linkColor} underlineLinks={underlineLinks} />
    </Box>
  )
}
