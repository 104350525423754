import { Accordion, AccordionDetails, AccordionSummary, AvatarSize, Button, DataGrid, theme } from '@ketch-com/deck'
import { Box, CircularProgress, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'
import { User } from 'interfaces/services/harbormaster/teams_gen.schemas'
import { useUser } from 'api/users/queries/useUser'
import { showToast } from 'components/modals/AlertComponent'
import { useIsEntitled, useIsPermitted } from 'utils/hooks'
import { getUserRoleData, UserRolesDisplayData } from 'pages/settings/users/view/utils/getUserRolesDisplayData'
import { UserAvatar } from 'components/avatar/UserAvatar'
import { getFullNameUserShortDTO } from 'utils/helpers/getFullNameUserShortDTO'
import { UserShortDTO } from 'interfaces/users/users'
import { UserDTO } from '@ketch-com/figurehead'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

type Props = {
  member: User
}

const columns: GridColDef[] = [
  {
    field: 'Section',
    headerName: 'Section',
    width: 420,
    renderCell: (params: GridRenderCellParams<UserRolesDisplayData>) => {
      return (
        <Box display="flex" flexDirection="column">
          <Typography variant="label">{params.row.section}</Typography>
          <Typography variant="smallLabel" color={theme.palette.Text.Secondary}>
            {params.row.description}
          </Typography>
        </Box>
      )
    },
  },
  {
    field: 'Permission',
    headerName: 'Permission',
    width: 150,
    renderCell: (params: GridRenderCellParams<UserRolesDisplayData>) => (
      <Typography variant="body">{params.row.permission}</Typography>
    ),
  },
]

const CustomLoader = () => {
  return (
    <Box pt={0.5} justifyContent="center" display="flex">
      <CircularProgress size={30} />
    </Box>
  )
}

export const TeamMemberListItem: React.FC<Props> = ({ member }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()

  const { isPermitted } = useIsPermitted()
  const isUserAdmin = isPermitted(PERMISSIONS.USER_ADMIN)

  const handleViewClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    navigate(RoutesManager.settings.users.view.root.getURL({ id: member.id }))
  }

  const { data, isLoading } = useUser({
    params: { userId: member.id || '' },
    enabled: !!member.id && isOpen,
    onError: () => showToast({ type: 'error', content: 'Error occured while loading user permissions' }),
  })

  const rows = useMemo(
    () => getUserRoleData(data?.roles || [], isEntitled).sort((a, b) => a.priority - b.priority),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  )

  return (
    <Accordion
      expanded={isOpen}
      sx={({ palette }) => ({
        ':not(:first-of-type)': {
          mt: 0,
          borderTop: `1px solid ${palette.Common.Divider}`,
          '&.MuiAccordion-root': {
            borderRadius: isOpen ? '11px' : 0,
          },
        },
      })}
    >
      <AccordionSummary
        size="small"
        disabled={!isUserAdmin}
        onClick={toggleOpen}
        sx={({ palette }) => ({
          backgroundColor: isOpen ? palette.Black.o8 : undefined,
          '&.Mui-expanded': {
            borderRadius: '11px',
          },
        })}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <UserAvatar user={{ ...member, ID: member.id } as UserDTO} size={AvatarSize.medium} />
          <Box display="flex" flexDirection="column">
            <Typography variant="label">{getFullNameUserShortDTO(member as UserShortDTO)}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Button color="tertiary" onClick={handleViewClick}>
            View
          </Button>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          '&.DeckAccordion-medium': {
            marginTop: 0,
            paddingTop: 0,
          },
          borderRadius: 0,
          borderTop: 0,
          borderRight: 0,
          borderBottom: 0,
          minHeight: 150,

          '& .MuiDataGrid-row:before': {
            content: 'unset',
          },

          '& .MuiDataGrid-cell': {
            // Not using theme color here intentionally due to Deck issue
            // TODO: Investigate deck component not injecting `palette` to sx
            borderTop: `1px solid rgb(218, 221, 222)`,
            borderRight: 0,
            borderBottom: 0,
            borderLeft: 0,
            borderImage: 0,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          sx={{
            borderRadius: 0,
            borderTop: 0,
            borderRight: 0,
            minHeight: 150,
            px: 0,
            pb: 0,
          }}
          columns={columns}
          rows={rows}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowHoverStates
          disableRowSelectionOnClick
          disableMultipleRowSelection
          hideFooter
          slots={{
            // TODO:JA why is this not working?
            // Should this be a deck default?
            loadingOverlay: CustomLoader,
          }}
        />
      </AccordionDetails>
    </Accordion>
  )
}
