import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useApp } from 'api/apps/queries/useApp'
import { useCanonicalPurposes } from 'api/purposes/queries/useCanonicalPurposes'
import { RoutesManager } from 'utils/routing/routesManager'
import { PermitPropagationView } from 'pages/consentAndRights/consent/systems/view/PermitPropagationView'
import { PurposeDataRole } from 'interfaces/purposes/purposeDataRole'

export const PermitPropagationViewContainer: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()

  const { data: appDescriptor, isLoading: isAppLoading } = useApp({
    params: {
      appId: id!,
    },
    onError: () => {
      navigate(RoutesManager.orchestration.consent.systems.root.getURL())
    },
  })

  const { data: canonicalPurposes, isLoading: isCanonicalPurposesLoading } = useCanonicalPurposes({
    params: { includeDataSubjectTypes: true, filterDataRole: PurposeDataRole.CONTROLLER },
  })

  const isReady = !isAppLoading && !isCanonicalPurposesLoading

  return <PermitPropagationView isReady={isReady} appDescriptor={appDescriptor} canonicalPurposes={canonicalPurposes} />
}
