import { FormCheckbox } from '../FormCheckbox'
import { experienceUpsertFormKeys } from '../../../../utils/useExperienceValidationSchema'
import { Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SidebarFormGroup } from '../SidebarFormGroup'

export const BannerExperienceFooterForm: React.FC = () => {
  return (
    <SidebarFormGroup sx={{ p: 0, m: 0, gap: 0 }}>
      {/* TODO:JB - Move to theme */}
      {/* <FormControlLabel
        control={<FormCheckbox name={experienceUpsertFormKeys.bannerFooterKetchBadgeVisible} />}
        label={<Typography variant={'label'}>Show “Powered by Ketch” Badge</Typography>}
      /> */}
      <FormControlLabel
        control={<FormCheckbox name={experienceUpsertFormKeys.bannerFooterGpcBadgeVisible} />}
        label={<Typography variant={'label'}>GPC Signal Indicator</Typography>}
      />
    </SidebarFormGroup>
  )
}
