import React from 'react'
import clsx from 'clsx'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useFormikContext } from 'formik'
import { Box, Button, InputAdornment, Popover } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { Label } from 'components/ui-kit/form/common/label/Label'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { LoadingOptionsSpinner } from 'pages/policyCenter/policies/upsert/CreatePolicyForm/components'
import { EndIcon } from 'components/ui-kit/dropdown/DropdownV2/components'
import { getAutocompleteInputCellRendererSxProps } from '../utils'
import { useAutocompleteStyles, useRelatedResourceSearchOptions } from '../hooks'
import { CreateRelationshipInitialValues } from '../utils/getInitialValues'
import { KeyOptionRenderer } from '.'
import { ResourceDTO } from '@ketch-com/figurehead'
import { ASSET_TYPE_ENUM } from 'interfaces/assets'

type Props = {
  label?: string
  placeholder?: string
  rowIndex: number
  replace: (index: number, value: any) => void
}

const getHelperText = (errorObject: any): string => {
  if (errorObject?.foreignKey) return errorObject?.foreignKey as string
  return 'no-error'
}

const getIsError = (errorObject: any, touchedObject: any, rowIndex: number): boolean => {
  if (errorObject?.foreignKey && touchedObject?.[`relations[${rowIndex}].foreignKey`]) return true
  return false
}

const getIsTouched = (touchedObject: any, rowIndex: number): boolean => {
  if (touchedObject?.[`relations[${rowIndex}].foreignKey`]) return true
  return false
}

export const ForeignKeyInputCellRenderer: React.FC<Props> = ({ label, placeholder, rowIndex, replace }) => {
  const autocompleteClasses = useAutocompleteStyles()
  const { errors, touched, setTouched, values } = useFormikContext<CreateRelationshipInitialValues>()
  const { resourceOptions, isLoadingResources, setSearchValue } = useRelatedResourceSearchOptions({
    isEnabled: !!values?.datasetCode,
    assetCode: values?.datasetCode,
    resourceTypeCode: values?.attributeResourceTypeCode,
  })

  const value = values.referenceDatasetCode
  const isTouched = getIsTouched(touched, rowIndex)
  const isValid = !getIsError(errors?.relations?.[rowIndex], touched, rowIndex)
  const errorMessage = getHelperText(errors?.relations?.[rowIndex]) || 'no-error'

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setTimeout(() => {
      document!.getElementById(autocompleteId)?.focus()
    }, 250)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? 'simple-popover' : undefined
  const autocompleteId = `primaryKeyInputAutocompleteId-${rowIndex}`

  const currentForeignKeyValues = (values?.relations || []).map(el => el.foreignKey)
  const currentValue = values?.relations?.[rowIndex]?.foreignKey

  // filter out any previously used options EXCEPT the current value
  const filteredOptions = resourceOptions.filter(resource => {
    const filterConditionsArray = currentForeignKeyValues.filter(v => v !== currentValue)
    return !filterConditionsArray.some(v => v === resource.id)
  })

  return (
    <Box pt={(rowIndex || 0) === 0 ? 0 : 2}>
      <Button
        size="medium"
        variant="contained"
        disabled={!values.datasetCode || !values.referenceDatasetCode}
        disableRipple
        disableFocusRipple
        disableElevation
        onClick={handleClick}
        endIcon={<EndIcon isPending={false} isDropdownOpen={false} />}
        sx={getAutocompleteInputCellRendererSxProps({ value, isValid, isTouched })}
      >
        {resourceOptions.find(resource => resource.id === values.relations?.[rowIndex]?.foreignKey)?.name ||
          'Select...'}
      </Button>
      <Error
        className={clsx({
          [autocompleteClasses.hidden]: !isTouched || isValid,
        })}
      >
        {errorMessage}
      </Error>

      <Popover
        id={popoverId}
        open={open}
        PaperProps={{
          sx: {
            minWidth: '400px',
            p: 1.25,
            borderRadius: 1.5,
          },
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <>
          {label && <Label>{label}</Label>}
          <Autocomplete
            options={filteredOptions}
            id={autocompleteId}
            fullWidth
            openOnFocus
            popupIcon={null}
            autoComplete={false}
            multiple={false}
            classes={{ inputRoot: autocompleteClasses.inputRoot }}
            loading={isLoadingResources}
            loadingText={<LoadingOptionsSpinner />}
            onChange={(_, v) => {
              if (v) {
                const payload = {
                  primaryKey: values.relations[rowIndex].primaryKey,
                  foreignKey: v.id,
                }
                replace(rowIndex, payload)
              }
              handleClose()
            }}
            getOptionLabel={(option: ResourceDTO) => option.name as string}
            renderOption={(props, option) => {
              return (
                <li {...{ ...props, key: option.id }}>
                  <KeyOptionRenderer option={option} />
                </li>
              )
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            onInputChange={(_, newInputValue) =>
              setSearchValue(newInputValue, ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_ATTRIBUTE)
            }
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                placeholder={placeholder}
                helperText={getHelperText(errors?.relations?.[rowIndex])}
                error={getIsError(errors?.relations?.[rowIndex], touched, rowIndex)}
                onBlur={() => setTouched({ ...touched, [`relations[${rowIndex}].foreignKey`]: true })}
                inputProps={{
                  ...params.inputProps,
                  form: {
                    autoComplete: 'off',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  sx: {
                    opacity: 0,
                    '&.Mui-error': {
                      color: ({ palette }) => palette.chileanFire.main,
                      opacity: 1,
                    },
                  },
                }}
              />
            )}
          />
        </>
      </Popover>
    </Box>
  )
}
