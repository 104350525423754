import { FC, useContext } from 'react'

import { RoutesManager } from 'utils/routing/routesManager'
import { PreviewLayout } from 'components/ui-layouts/previewLayout/PreviewLayout'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { ExperiencePreviewContext, withExperiencePreviewContext } from './context/ExperiencePreviewContext'
import { ConsentExperiencePreview } from './components/ConsentExperiencePreview'
import { PreferenceExperiencePreview } from './components/PreferenceExperiencePreview'
import { PreviewLayoutToolbar } from './components/PreviewLayoutToolbar'

type Props = {}

export const ExperiencePreviewWithContext: FC<Props> = () => {
  const { code, exitTo, isConsentExperience, isExistingEntityPreview, isReady, isPreviewReady, name } =
    useContext(ExperiencePreviewContext)

  if (!isReady) return <Spinner />

  return (
    <PreviewLayout
      isReady={isPreviewReady}
      breadcrumbs={[
        { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
        { title: 'Experiences', link: RoutesManager.deployment.experiences.root.getURL() },
        { title: isExistingEntityPreview ? name || code : 'Create New Experience', link: exitTo },
        { title: 'Preview' },
      ]}
      name={name}
      code={code}
      exitTo={exitTo}
      toolbar={<PreviewLayoutToolbar />}
    >
      {isConsentExperience ? <ConsentExperiencePreview /> : <PreferenceExperiencePreview />}
    </PreviewLayout>
  )
}

export const ExperiencePreview = withExperiencePreviewContext(ExperiencePreviewWithContext)
