import React from 'react'
import { Formik } from 'formik'
import { CreateRelationdhipFormData } from 'pages/assetManager/relationshipMapping/interfaces'
import {
  getCreateRelationshipMappingInitialValues,
  getCreateRelationshipMappingValidation,
} from 'pages/assetManager/relationshipMapping/components/CreatedRelationships/components/CreateRelationshipMappingModal/utils'
import { CreateRelationshipForm } from 'pages/assetManager/relationshipMapping/components/CreatedRelationships/components/CreateRelationshipMappingModal/components'
import { Button, PopUp } from '@ketch-com/deck'

type Props = {
  onSubmit: (data: CreateRelationdhipFormData) => void
  onCancel: () => void
}

export const CreateRelationshipMappingModal: React.FC<Props> = ({ onSubmit = () => {}, onCancel = () => {} }) => {
  const initialValues: CreateRelationdhipFormData = getCreateRelationshipMappingInitialValues()
  const validationSchema = getCreateRelationshipMappingValidation()

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isValid, values, isSubmitting, handleSubmit }) => {
        return (
          <PopUp
            subTitle="Define which dataset column will act like a Foreign Key and map them to a Primary Key column."
            title="Create a Relation"
            onClose={onCancel}
            variant="modal"
            popUpWidth="840px"
            popUpActionChildren={
              <>
                <Button color="secondary" size="large" onClick={onCancel}>
                  Cancel
                </Button>
                <Button color="primary" size="large" pending={isSubmitting} onClick={() => handleSubmit()}>
                  Create
                </Button>
              </>
            }
          >
            <CreateRelationshipForm />
          </PopUp>
        )
      }}
    </Formik>
  )
}
