import { AppNavigationSize, SidebarMode } from '../types'

export const getNextSidebarModeOnClick = (current: SidebarMode, appNavigationSize: AppNavigationSize): SidebarMode => {
  switch (current) {
    case SidebarMode.Closed:
      // From closed mode, set to the largest mode screen allows otherwise overlay
      switch (appNavigationSize) {
        case AppNavigationSize.Compact:
          return SidebarMode.Compact
        case AppNavigationSize.FullSize:
          return SidebarMode.Open
        default:
          return SidebarMode.Overlay
      }

    case SidebarMode.Compact:
      // From compact mode, set to open if screen allows otherwise overlay
      switch (appNavigationSize) {
        case AppNavigationSize.FullSize:
          return SidebarMode.Open
        default:
          return SidebarMode.Overlay
      }

    case SidebarMode.Open:
      // From open mode, always set to compact
      return SidebarMode.Compact

    case SidebarMode.Overlay:
      switch (appNavigationSize) {
        case AppNavigationSize.Hidden:
          return SidebarMode.Closed

        case AppNavigationSize.Compact:
          return SidebarMode.Compact

        case AppNavigationSize.FullSize:
          return SidebarMode.Open
      }
  }
}

export const getNextSidebarModeOnResize = (appNavigationSize: AppNavigationSize): SidebarMode => {
  // Determine sidebar mode hover state based on the window size (AppNavigationSize)
  switch (appNavigationSize) {
    case AppNavigationSize.Hidden:
      return SidebarMode.Closed

    case AppNavigationSize.Compact:
      return SidebarMode.Compact

    case AppNavigationSize.FullSize:
      return SidebarMode.Open
  }
}

export const getNextSidebarModeOnHover = (
  current: SidebarMode,
  isSidebarHovered: boolean,
  appNavigationSize: AppNavigationSize,
): SidebarMode => {
  // Only change state on hover if in compact or overlay mode
  if (!(current === SidebarMode.Compact || current === SidebarMode.Overlay)) return current

  if (isSidebarHovered) return SidebarMode.Overlay

  switch (appNavigationSize) {
    case AppNavigationSize.Hidden:
      return SidebarMode.Closed

    case AppNavigationSize.Compact:
      return SidebarMode.Compact

    case AppNavigationSize.FullSize:
      return SidebarMode.Compact
  }

  // If new state is hovered switch to overlay, if not switch to compact
}
