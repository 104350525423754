import React, { useContext } from 'react'
import { useSetState } from 'react-use'

import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Group } from 'components/ui-layouts/group/Group'
import { Text } from 'components/ui-kit/typography/Text'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'
import { FormMode } from 'interfaces/formModes/formMode'
import { Button } from '@ketch-com/deck'
import { ThemeViewContext } from '../context/ThemeViewContext'
import { DeleteThemeModalV3 } from 'components/modals/themes/DeleteThemeModalV3'
import { ThemeStatus } from 'interfaces/themes-v3/themeStatus'
import { Tooltip } from '@mui/material'

export const ThemeViewActions: React.FC = () => {
  const { theme, navigate, isPermitted } = useContext(ThemeViewContext)

  const [{ isEditConfirmationOpen, isDeleteOpen }, setState] = useSetState({
    isEditConfirmationOpen: false,
    isDeleteOpen: false,
  })

  const isPermittedToManageThemes = isPermitted(PERMISSIONS.THEME_WRITE)
  const isDeployed = theme?.status === ThemeStatus.Deployed

  const handleRedirectToUpsertTheme = () => {
    navigate(RoutesManager.deployment.themesV3.upsert.root.getURL({ id: theme?.id, formMode: FormMode.EDIT }))
  }

  return (
    <>
      <Group>
        <Group gap={8}>
          <Text size={12} color="grey" weight={500}>
            Last Updated
          </Text>
          <Text size={12} weight={500}>
            <FormattedDateRenderer date={theme?.metadata?.updatedAt} />
          </Text>
        </Group>

        {isPermittedToManageThemes && (
          <>
            <Tooltip placement="bottom" title={isDeployed ? 'A deployed Theme cannot be deleted' : null} arrow>
              <span>
                <Button
                  disabled={isDeployed}
                  size="large"
                  color="tertiary"
                  onClick={() => {
                    setState({ isDeleteOpen: true })
                  }}
                >
                  Delete
                </Button>
              </span>
            </Tooltip>

            <Button
              onClick={() => {
                if (isDeployed) {
                  setState({ isEditConfirmationOpen: true })
                } else {
                  handleRedirectToUpsertTheme()
                }
              }}
              size="large"
              color="secondary"
            >
              Edit
            </Button>
          </>
        )}
      </Group>

      {isDeleteOpen && (
        <DeleteThemeModalV3
          themeId={theme?.id || ''}
          onSubmit={() => navigate(RoutesManager.deployment.themesV3.root.getURL())}
          onCancel={() => setState({ isDeleteOpen: false })}
        />
      )}

      {isEditConfirmationOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.THEME}
          onSubmit={handleRedirectToUpsertTheme}
          onCancel={() => {
            setState({ isEditConfirmationOpen: false })
          }}
        />
      )}
    </>
  )
}
