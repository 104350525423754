import { Rect, Text, Image } from 'react-konva'
import React, { useState } from 'react'
import pasteSvg from 'assets/icons/o_focus_copy.svg'
import useImage from 'use-image'

type Props = {
  x: number
  y: number
  width?: number
  height?: number
  onClick?: () => void
}

export const PasteButton: React.FC<Props> = ({ x, y, width = 78, height = 32, onClick }) => {
  const [pasteIcon] = useImage(pasteSvg)
  const [hovered, setHovered] = useState(false)

  const handleMouseOver = () => setHovered(true)
  const handleMouseOut = () => setHovered(false)

  return (
    <>
      <Rect
        x={x}
        y={y}
        width={width}
        height={height}
        cornerRadius={100}
        fill={hovered ? 'lightgrey' : 'white'}
        strokeWidth={1}
        shadowColor="rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.05)"
        shadowBlur={20}
        shadowOffsetX={0}
        shadowOffsetY={10}
        shadowOpacity={0.8}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={onClick}
      />

      {/* Centered image and text */}
      <Image
        image={pasteIcon}
        x={x + 12}
        y={y + 8}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={onClick}
      />
      <Text
        x={x + 8}
        y={y}
        width={width}
        height={height}
        text="Paste"
        fontFamily="Inter"
        fontSize={12}
        fontStyle="bold"
        fill="black"
        align="center"
        verticalAlign="middle"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={onClick}
      />
    </>
  )
}
