import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { deleteDmlHook } from '../fetchers/deleteDmlHook'

const useCustomMutation = createUseMutation({
  mutationFn: deleteDmlHook,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeleteDmlHook = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
