import { useInfiniteQuery } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchCustomSqls } from '../fetchers/fetchCustomSqls'

type Params = {
  databaseResourceId?: string
  scenarioId?: string
  enabled?: boolean
  limit?: number
}

export const useCustomSqlsInfinite = (params: Params) => {
  const { databaseResourceId = ' ', scenarioId = '', enabled = true, limit = 20 } = params
  const resp = useInfiniteQuery({
    enabled,
    queryKey: [ApiQueryKeys.customSql, { databaseResourceId, scenarioId }],
    queryFn: async ({ pageParam = {} }) => {
      const fetchResult = await fetchCustomSqls({
        limit,
        nextToken: pageParam.nextToken,
        ...params,
      })
      return fetchResult
    },
    getNextPageParam: lastPage => {
      const nextToken = lastPage?.data?.nextToken
      return nextToken ? { nextToken } : undefined
    },
  })

  return {
    ...resp,
    customSqls: resp?.data?.pages?.map(page => page?.data?.dsrConfigSql || []).flat() || [],
    totalResults: resp?.data?.pages?.[0]?.data?.totalResults || 0,
  }
}
