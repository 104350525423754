import React from 'react'

import { useThemesPaginated } from 'api/themes-v2/queries/useThemesPaginated'
import { ThemesList } from 'pages/consentAndRights/themes/list/ThemesList'

export const ThemesListContainer: React.FC = () => {
  const {
    data: themes,
    isLoading,
    isFetching,
    pagination,
  } = useThemesPaginated({
    params: {
      includeMetadata: true,
    },
  })

  return <ThemesList isLoading={isLoading} isFetching={isFetching} themes={themes} pagination={pagination} />
}
