import React, { useContext } from 'react'
import { RoutesManager } from 'utils/routing/routesManager'
import {
  InternalSystemDsrDetails,
  InternalSystemDsrHeaderDetails,
  InternalSystemDsrHeaderActions,
} from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/InternalSystemDsr/components'
import { StepDetailsColumn, WorkflowStepHeaderIcon } from 'pages/orchestration/rightsQueue/stepDetails/components'
import {
  withInternalSystemDsrContext,
  InternalSystemDsrContext,
} from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/InternalSystemDsr/context'
import { Spinner, ViewLayout } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {}

export const InternalSystemDsrWithContext: React.FC<Props> = () => {
  const { breadcrumbs, isReady, rightInvocationId, workflowStep } = useContext(InternalSystemDsrContext)
  const navigate = useNavigate()

  if (!isReady) {
    return (
      <>
        <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )
  }

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs?.filter(b => !!b?.title)} />
      <ViewLayout
        contentGroupProps={{
          title: 'Execute Internal DSR',
          showBackButton: true,
          propsBackButton: {
            onClick: () =>
              navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocationId })),
          },
          imageBlockComponent: <WorkflowStepHeaderIcon />,
          infoBlockComponent: <InternalSystemDsrHeaderDetails workflowStep={workflowStep} />,
          actionRightBlockComponent: <InternalSystemDsrHeaderActions />,
        }}
        widgetContentComponent={<StepDetailsColumn />}
      >
        <InternalSystemDsrDetails />
      </ViewLayout>
    </>
  )
}

export const InternalSystemDsr = withInternalSystemDsrContext(InternalSystemDsrWithContext)
