import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { ListAssetItemDTO } from '@ketch-com/figurehead'
import { useState } from 'react'
import { AssetsTabRowEditModalProps } from './AssetsTabRowEditModal'
import { ClassificationList } from '../hooks/useSplitClassificationsByStatus'

interface AssetsTabTableRowEditMenuProps {
  row: ListAssetItemDTO
  triggerModal: (value: Omit<AssetsTabRowEditModalProps, 'refetchInstalledDatasystemAssets'> | undefined) => void
}

interface MenuOption {
  label: string
  value: keyof ListAssetItemDTO
}

// const dataOwner: MenuOption = { label: 'Data Owner', value: 'resource' }
const dataSensitivity: MenuOption = { label: 'Edit Data Sensitivity', value: 'dataSensitivities' }
const dataCategory: MenuOption = { label: 'Edit Data Categories', value: 'dataCategories' }
const dataType: MenuOption = { label: 'Edit Data Types', value: 'dataTypes' }

const menuOptionsByCanonnicalType: Record<string, MenuOption[]> = {
  CANONICAL_RESOURCE_TYPE_DATABASE: [
    // dataOwner,
    dataSensitivity,
    dataCategory,
  ],
  CANONICAL_RESOURCE_TYPE_SCHEMA: [dataSensitivity, dataCategory],
  CANONICAL_RESOURCE_TYPE_DATASET: [dataSensitivity, dataCategory],
  CANONICAL_RESOURCE_TYPE_OBJECT: [dataSensitivity, dataCategory],
  CANONICAL_RESOURCE_TYPE_FOLDER: [dataSensitivity, dataCategory],
  CANONICAL_RESOURCE_TYPE_FILE: [dataSensitivity, dataCategory, dataType],
  CANONICAL_RESOURCE_TYPE_ATTRIBUTE: [dataSensitivity, dataCategory, dataType],
}

export const AssetsTabTableRowEditMenu: React.FC<AssetsTabTableRowEditMenuProps> = ({ row, triggerModal }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const menuItems = menuOptionsByCanonnicalType[row.resource!.canonicalResourceType!]

  return (
    <>
      <Button variant="icon" color="ghost" onClick={handleButtonClick}>
        <Icon name="OMore" />{' '}
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {menuItems.map(item => (
          <ActionSheetItem
            key={item.value}
            onClick={() => {
              if (row[item.value]) {
                triggerModal({
                  canonicalResourceType: row.resource!.canonicalResourceType!,
                  resourceName: row.resource!.name || '',
                  classificationName: item.label,
                  classificationType: item.value,
                  classificationList: row[item.value] as ClassificationList,
                  onClose: () => {
                    triggerModal(undefined)
                  },
                })
                handleClose()
              }
            }}
          >
            {item.label}
          </ActionSheetItem>
        ))}
      </ActionSheetWrapper>
    </>
  )
}
