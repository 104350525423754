import { CircularProgress, DropListButton, TabSegmented, TabSegmentedGroup } from '@ketch-com/deck'
import { Box, Typography, styled } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { ConsentExperienceType } from 'interfaces/experiences-v2/consentExperienceType'
import { ConsentExperienceTypeLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { PreviewDisplayMode } from '../../../utils/enums'
import { previewDisplayModeLabels } from '../../../utils/labels'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { PolicyScopeDTO } from 'interfaces/policyScopes-v2/policyScope'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { ExperiencePreviewContext } from '../context/ExperiencePreviewContext'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'

type Props = {
  variant: ExperienceType
  isLoading: boolean
}

const PreviewHeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: '12px 16px',
  boxShadow: '0px 4px 10px 0px rgba(7, 26, 36, 0.10);',
  zIndex: 3,
})

const PreviewHeaderLeftBox = styled(Box)({
  display: 'flex',
  gap: '32px',
  alignItems: 'flex-end',
})

const PreviewHeaderRightBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
})

export const ExperiencePreviewHeader: React.FC<Props> = ({ variant, isLoading }) => {
  const {
    language,
    isLanguagesLoading,
    consentExperienceType,
    setConsentExperienceType,
    displayMode,
    setDisplayMode,
    languageOptions,
    setLanguage,
    jurisdictionOptions,
    jurisdiction,
    setJurisdiction,
    themeOptions,
    theme,
    setTheme,
    setIsPreviewReady,
  } = useContext(ExperiencePreviewContext)
  const isConsent = variant === ExperienceType.Consent
  const queryClient = useQueryClient()

  useEffect(() => {
    if (languageOptions?.length) {
      setLanguage(languageOptions[0])
    }
  }, [languageOptions, setLanguage])

  useEffect(() => {
    if (jurisdictionOptions?.length) {
      setJurisdiction(jurisdictionOptions[0])
    }
  }, [jurisdictionOptions, setJurisdiction])

  useEffect(() => {
    if (themeOptions?.length) {
      setTheme(themeOptions[0])
    }
  }, [themeOptions, setTheme])

  return (
    <PreviewHeaderBox>
      <PreviewHeaderLeftBox>
        {/* Experience type picker */}
        {isConsent && (
          <TabSegmentedGroup
            value={consentExperienceType}
            onChange={(event: React.MouseEvent<HTMLElement>, newType: ConsentExperienceType) => {
              setConsentExperienceType(newType)
            }}
            exclusive
            size={'small'}
            sx={{ height: 'fit-content' }}
          >
            {Object.values(ConsentExperienceType).map(expType => (
              <TabSegmented value={expType} key={expType}>
                <Typography
                  // Different text variant for selected
                  variant={consentExperienceType === expType ? 'smallLabel' : 'smallBody'}
                >
                  {ConsentExperienceTypeLabels[expType]}
                </Typography>
              </TabSegmented>
            ))}
          </TabSegmentedGroup>
        )}

        {/* Display mode picker */}
        <TabSegmentedGroup
          value={displayMode}
          onChange={(event: React.MouseEvent<HTMLElement>, newDisplayMode: PreviewDisplayMode) => {
            setIsPreviewReady(false)
            setDisplayMode(newDisplayMode)
          }}
          exclusive
          size={'small'}
          sx={{ height: 'fit-content' }}
        >
          {Object.values(PreviewDisplayMode).map(dp => (
            <TabSegmented value={dp} key={dp} sx={{ px: '20px' }}>
              <Typography
                // Different text variant for selected
                variant={dp === displayMode ? 'smallLabel' : 'smallBody'}
              >
                {previewDisplayModeLabels[dp]}
              </Typography>
            </TabSegmented>
          ))}
        </TabSegmentedGroup>

        {/* Preference center page picker */}
        {/* Descoped */}
        {/* {!isConsent && (
        <DropListButton
          label="Page"
          size="small"
          width="200px"
          options={Object.values(PreferenceCenterPage)}
          onChange={(event, newValue) => setPreferenceCenterPage(newValue as PreferenceCenterPage)}
          value={preferenceCenterPage}
          getOptionLabel={p => previewPreferencePageLabels[p]}
        />
      )} */}

        {/* Language picker */}
        {!isLanguagesLoading && (
          <DropListButton
            label="Language"
            size="small"
            width="200px"
            options={languageOptions ?? []}
            onChange={async (event, newValue) => {
              setIsPreviewReady(false)
              setLanguage(newValue as LanguageWithStatusDTO)
              await queryClient.refetchQueries(ApiQueryKeys.previewConfig)
            }}
            value={language ?? { language: { englishName: 'English', code: '', nativeName: '' }, enabled: true }}
            getOptionLabel={l => l.language.englishName}
          />
        )}

        {/* Jurisdiction picker */}
        <DropListButton
          label="Jurisdiction"
          size="small"
          width="200px"
          options={jurisdictionOptions ?? []}
          onChange={(event, newValue) => {
            setIsPreviewReady(false)
            setJurisdiction(newValue as PolicyScopeDTO)
          }}
          value={jurisdiction ?? { code: '', name: 'Global Standard', description: '' }}
          getOptionLabel={j => j.name}
        />

        {/* Theme picker */}
        <DropListButton
          label="Theme"
          size="small"
          width="200px"
          options={themeOptions ?? []}
          onChange={(event, newValue) => {
            setIsPreviewReady(false)
            setTheme(newValue as ThemeV3DTO)
          }}
          value={theme ?? { name: 'Default' }}
          getOptionLabel={t => t.name || 'Unamed Theme'}
        />
      </PreviewHeaderLeftBox>
      <PreviewHeaderRightBox>
        {isLoading && <CircularProgress label={<Typography variant={'label'}>Preview Loading</Typography>} />}
      </PreviewHeaderRightBox>
    </PreviewHeaderBox>
  )
}
