import {
  ProcessingActivityAssessmentRequirementDTO,
  ProcessingActivityProcessingFrequencyDTO,
  ProcessingActivityTypeDTO,
} from '@ketch-com/figurehead'

export const ProcessingActivityAssessmentRequirementLabel = {
  [ProcessingActivityAssessmentRequirementDTO.RequiredProcessingActivityAssessmentRequirement]: 'Required',
  [ProcessingActivityAssessmentRequirementDTO.RecommendedProcessingActivityAssessmentRequirement]: 'Recommended',
  [ProcessingActivityAssessmentRequirementDTO.MaybeRequiredProcessingActivityAssessmentRequirement]: 'May Be Required',
  [ProcessingActivityAssessmentRequirementDTO.NotRequiredProcessingActivityAssessmentRequirement]: 'None',
  [ProcessingActivityAssessmentRequirementDTO.UnspecifiedProcessingActivityAssessmentRequirement]: 'Unknown',
}

export const ProcessingActivityTypeLabel = {
  [ProcessingActivityTypeDTO.StandardProcessingActivityType]: 'Standard',
  [ProcessingActivityTypeDTO.ModelProcessingActivityType]: 'AI / Model',
  [ProcessingActivityTypeDTO.UnspecifiedProcessingActivityType]: 'Unknown',
}

export const ProcessingActivityFrequencyLabel = {
  [ProcessingActivityProcessingFrequencyDTO.PeriodicProcessingActivityProcessingFrequency]: 'Periodic',
  [ProcessingActivityProcessingFrequencyDTO.OngoingProcessingActivityProcessingFrequency]: 'Ongoing',
  [ProcessingActivityProcessingFrequencyDTO.UnspecifiedProcessingActivityProcessingFrequency]: 'Unknown',
}

export const personalDataTypes = ['Not Defined', 'Personal', 'Sensitive', 'Non-Personal']
