import { WorkflowActivityConnectorCode } from 'interfaces/workflowActivities/workflowActivity'

export const getWorkflowActivityParamsByType = (code: WorkflowActivityConnectorCode) => {
  switch (code) {
    case WorkflowActivityConnectorCode.ADOBE:
    case WorkflowActivityConnectorCode.SALESFORCE:
    case WorkflowActivityConnectorCode.MICROSOFT_EXCHANGE_SERVER:
    case WorkflowActivityConnectorCode.GOOGLE_MARKETING:
    case WorkflowActivityConnectorCode.HUBSPOT:
    case WorkflowActivityConnectorCode.MARKETO:
    case WorkflowActivityConnectorCode.APPNEXUS:
    case WorkflowActivityConnectorCode.COMSCORE:
    case WorkflowActivityConnectorCode.ORACLE_DATA_CLOUD:
    case WorkflowActivityConnectorCode.DATAXU:
      return {
        type: '',
        connection: '',
      }
  }
}
