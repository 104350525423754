import React from 'react'
import { compact } from 'lodash'
import { Avatar, AvatarSize } from '@ketch-com/deck'
import { Box, Tooltip, Typography } from '@mui/material'
import { UserShortDTO } from 'interfaces/users/users'
import { UserAvatar } from 'components/avatar/UserAvatar'

type Props = {
  assignee?: UserShortDTO
  hideAvatar?: boolean
  variant?: 'inline' | 'tooltip'
  avatarSize?: AvatarSize
}

export const WorkflowExecutionAssignee: React.FC<Props> = ({
  assignee,
  hideAvatar,
  variant = 'inline',
  avatarSize,
}) => {
  const words = compact([assignee?.firstName, assignee?.lastName])
  const fullName = words.join(' ')

  const abbreviation = words
    .map(word => word[0])
    .join('')
    .toUpperCase()

  if (variant === 'tooltip') {
    return (
      <Avatar textColor="white" backgroundColor="marine.main" size={avatarSize || AvatarSize.large}>
        <Tooltip title={fullName}>
          <Box>{abbreviation}</Box>
        </Tooltip>
      </Avatar>
    )
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {!hideAvatar && <UserAvatar user={assignee} size={avatarSize || AvatarSize.large} />}
      <Typography variant="label">{fullName}</Typography>
    </Box>
  )
}
