import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchThemes } from 'api/themes-v3/fetchers/fetchThemes'
import { CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'

import { createUsePaginatedQueryPreservedPage } from 'api/common/createUsePaginatedQueryPreservedPage'

const useCustomPaginatedQuery = createUsePaginatedQueryPreservedPage({
  queryKey: ApiQueryKeys.themesPaginated,
  queryFn: fetchThemes,
  select: res => res?.data?.themes || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useThemesPaginated = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
