import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { Chip } from '@ketch-com/deck'

import { Filter } from '../interfaces'
import { CustomMegaFilterContext } from '../context/MegaFilterContext'

type Props = {
  data: Filter
  isLoading: boolean
}

export const MegaFilterChipList: React.FC<Props> = ({ data: filter }) => {
  const { addParamValue, removeQueryParamFromList, hasValue } = useContext(CustomMegaFilterContext)

  const handleFilterChipClick = (key: string, value: string) => {
    if (hasValue(key, value)) {
      removeQueryParamFromList({ key, value })
    } else {
      addParamValue(key, value)
    }
  }

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" gap={1} sx={{ margin: '0 12px' }}>
      {filter.items.map(item => (
        <Chip
          clickable
          selectable
          isSelected={hasValue(filter.key, item.id)}
          label={item.label}
          onClick={() => handleFilterChipClick(filter.key, item.id)}
        />
      ))}
    </Box>
  )
}
