import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  createStyles({
    toolbar: {
      marginBottom: 24,
      padding: '12px 10px',
    },
  }),
  { name: 'UpsertInlineLayoutToolbar' },
)

type Props = {
  children?: React.ReactNode
}

export const UpsertInlineLayoutToolbar: React.FC<Props> = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.toolbar}>{children}</div>
}
