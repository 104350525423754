import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { ThemeStatus } from 'interfaces/themes-v2/themeStatus'
import { Pagination } from 'api/common/paginatedQuery'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ViewListLayout } from 'components/ui-layouts/viewListLayout/ViewListLayout'
import { ViewListLayoutHeader } from 'components/ui-layouts/viewListLayout/ViewListLayoutHeader'
import { ViewListLayoutContent } from 'components/ui-layouts/viewListLayout/ViewListLayoutContent'
import { Button } from 'components/ui-kit/button/Button'
import { Status } from 'components/ui-kit/status/Status'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { ThemesListDropdown } from 'pages/consentAndRights/themes/list/ThemesListDropdown'
import { ThemeColorScheme } from 'pages/consentAndRights/themes/list/ThemeColorScheme'

import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateThemes.svg'

type Props = {
  isLoading: boolean
  isFetching: boolean
  themes: ThemeDTO[]
  pagination: Pagination
}

export const ThemesList: React.FC<Props> = ({ isLoading, isFetching, themes, pagination }) => {
  const navigate = useNavigate()

  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()

  const isEntitledToCreateThemes = isEntitled(ENTITLEMENTS.QTY_THEME)
  const isPermittedToCreateThemes = isPermitted([PERMISSIONS.THEME_WRITE])
  const canCreateThemes = isEntitledToCreateThemes && isPermittedToCreateThemes

  const handleUpsertThemeClick = (code?: string) => {
    navigate(RoutesManager.deployment.themes.upsert.root.getURL({ code: code || NEW_ITEM_CODE }))
  }

  return (
    <ViewListLayout>
      <ViewListLayoutHeader
        title="Themes"
        actions={
          isPermittedToCreateThemes && (
            <Button
              onClick={() => handleUpsertThemeClick()}
              tooltip={
                !isEntitledToCreateThemes
                  ? 'No themes entitlements remaining, please delete one or contact your admin for more'
                  : null
              }
              disabled={!isEntitledToCreateThemes}
            >
              Create Theme
            </Button>
          )
        }
      />

      <ViewListLayoutContent>
        {!isFetching && !themes.length ? (
          <EmptyState
            variant="list"
            icon={<EmptyStateIcon />}
            title="There are no themes for this organization"
            description={canCreateThemes ? 'Create your first one to populate the table.' : null}
            actions={[
              {
                title: 'Create Theme',
                hidden: !canCreateThemes,
                size: 'big',
                onClick: () => handleUpsertThemeClick(),
              },
            ]}
          />
        ) : (
          <TablePaginated
            pending={isLoading}
            items={themes}
            pagination={pagination}
            onRowClick={({ code }) => {
              navigate(RoutesManager.deployment.themes.view.root.getURL({ code }))
            }}
            cellSettings={{
              name: {
                label: 'Theme',
                width: 250,
                cellRenderer: ({ name, code }) => <NameAndCodeCellRenderer code={code} name={name} />,
              },
              colorScheme: {
                label: 'Color Scheme',
                cellRenderer: theme => <ThemeColorScheme theme={theme} />,
              },
              typeface: {
                label: 'Typeface',
                width: 200,
                cellRenderer: () => <EmptyValueRenderer />,
              },
              buttonCornerRadius: {
                label: 'Button Corner Radius',
                width: 180,
                cellRenderer: ({ buttonBorderRadius }) => <TextCellRenderer value={buttonBorderRadius || 0} />,
              },
              status: {
                label: 'Status',
                width: 170,
                cellRenderer: ({ status }) =>
                  status === ThemeStatus.PUBLISHED ? (
                    <Status variant="active">Deployed</Status>
                  ) : (
                    <Status variant="inactive">Undeployed</Status>
                  ),
              },
              updatedAt: {
                label: 'Last Updated',
                width: 210,
                cellRenderer: ({ metadata }) => <FormattedDateRenderer date={metadata?.updatedAt} />,
              },
              dropDown: {
                width: 60,
                cellRenderer: theme => <ThemesListDropdown theme={theme} />,
              },
            }}
          />
        )}
      </ViewListLayoutContent>
    </ViewListLayout>
  )
}
