import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Box, Typography } from '@mui/material'
import { DataGrid, theme, InlineEdit, TableCell, FormRow } from '@ketch-com/deck'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { extractValueInParentheses } from '../utils'
import { FormInput } from 'components/form/FormInput'

type Props = {
  values: any
  onSubmitUpdate?: any
  setFieldValue?: any
  initialValues?: any
  isLoading?: any
}

export const BasicDetailsUpdateFormSection: React.FC<Props> = ({
  values,
  onSubmitUpdate,
  setFieldValue,
  initialValues,
  isLoading,
}) => {
  const subTitle = 'Edit a name and provide translations.'
  const { data: orgLangs, isLoading: isOrgLangLoading } = useOrganizationLanguages()
  const columns = [
    {
      field: 'language',
      headerName: 'Language',
      sortable: false,
      width: 250,
      renderCell: (params: { row: { translation: string | undefined; id: number; language: string } }) => (
        <TableCell>
          <Typography>{params.row.language}</Typography>
        </TableCell>
      ),
    },
    {
      field: 'translation',
      headerName: 'Custom Right Name Translation',
      width: 950,
      sortable: false,
      renderCell: (params: { row: { translation: string | undefined; id: number; language: string } }) => (
        <TableCell>
          <InlineEdit
            textInputVariant="ghost"
            placeholder="Add Translation"
            value={params.row.translation}
            onKeyDown={handleKeyDown}
            onChange={e => handleTranslation(e, params.row.language)}
          />
        </TableCell>
      ),
    },
  ]

  const mappedTranslations = orgLangs.map(langObj => {
    const translation =
      values.translations &&
      values.translations.find((t: { languageCode: string }) => t.languageCode === langObj.language.code)
    return {
      language: langObj.language.englishName,
      name: translation ? translation.name : '',
    }
  })

  const generateRows = () => {
    if (values.translations) {
      return mappedTranslations.map((mappedTranslation, index) => ({
        id: index,
        language: mappedTranslation.language,
        translation: mappedTranslation.name,
        obj: mappedTranslation,
      }))
    }
    return orgLangs.map((orgLang, index) => ({
      id: index,
      language: orgLang.language.englishName,
      translation: 'Add Translation',
      enabled: orgLang.enabled,
      obj: orgLang,
    }))
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === ' ' || e.code === 'Space') {
      e.stopPropagation()
    }
  }

  const onSubmit = (values: any) => {
    onSubmitUpdate(values)
  }

  const handleTranslation = (e: React.ChangeEvent<HTMLInputElement>, language: string) => {
    const languageCode = extractValueInParentheses(language)
    const name = e.target.value
    if (values.translations === undefined) {
      setFieldValue('translations', [{ languageCode, name }])
    } else {
      const updatedTranslation =
        values.translations?.length > 0
          ? values.translations?.find(
              (translation: { languageCode: string | null }) => translation.languageCode === languageCode,
            )
          : null
      if (updatedTranslation) {
        updatedTranslation.name = name
        setFieldValue('translations', [...values.translations])
      } else {
        setFieldValue('translations', [...values.translations, { languageCode, name }])
      }
    }
  }

  const rows = generateRows()

  const validationSchema = Yup.object({
    translation: Yup.string(),
    name: Yup.string().email('Invalid email address'),
  })

  return (
    <FormRow title="Details" subTitle={subTitle}>
      <Box width="50%">
        <FormInput
          fullWidth
          formPropertyName="name"
          label="Custom Name"
          required
          shouldUpdateDebounced
          placeholder="Add Right Name"
        />
      </Box>
      <Box mt={6}>
        <Typography variant="h4" color={theme.palette.darkDuskFaded.main} mb={3}>
          Translations
        </Typography>

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form>
            <DataGrid
              sx={{
                ...((isLoading || isOrgLangLoading) && {
                  '& .MuiDataGrid-virtualScroller': {
                    height: '300px',
                  },
                }),
              }}
              autosizeOnMount
              getRowHeight={() => 'auto'}
              columns={columns}
              disableAggregation
              disableColumnFilter
              disableColumnMenu
              disableColumnPinning
              disableColumnReorder
              disableColumnResize
              disableMultipleColumnsSorting
              disableMultipleRowSelection
              disableRowHoverStates
              disableRowSelectionOnClick
              hideFooter
              loading={isLoading || isOrgLangLoading}
              rows={rows.length ? rows : []}
              noRowsOverlayProps={{
                buttonTitle: '',
                subTitle: '',
              }}
            />
          </Form>
        </Formik>
      </Box>
    </FormRow>
  )
}
