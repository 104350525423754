import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import { FormSectionSwitch } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormSectionSwitch'
import { getIn, useFormikContext } from 'formik'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { Box, FormControlLabel, Typography } from '@mui/material'
import { FormFileUpload } from 'components/ui-kit/form/fileUpload/FormFileUpload'
import { themeFileAllowedTypes, themeFileUploadContext } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'
import { FormCheckbox } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormCheckbox'
import { SidebarFormRadioGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormRadioGroup'
import { ExitButtonPosition } from '@ketch-sdk/ketch-types'
import { ExitButtonPositionLabels } from 'pages/consentAndRights/themes-v3/upsert/utils/labels'
import {
  BuilderTextInputMode,
  FormTextInput,
} from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'

export const PreferenceThemeWelcomeTabExitButtonForm: React.FC = () => {
  const { values } = useFormikContext<ThemeV3DTO>()

  const showIconForm = getIn(values, themeUpsertFormKeys.preferenceWelcomeTabExitButtonIconVisible)
  const showDefaultIconForm = getIn(values, themeUpsertFormKeys.preferenceWelcomeTabExitButtonUseDefaultIcon)

  return (
    <>
      <SidebarFormGroup sx={{ pt: 0 }} hasBorderBottom>
        <SidebarFormRadioGroup
          name={themeUpsertFormKeys.preferenceWelcomeTabContainerExitButtonPosition}
          title={'Position'}
          options={Object.values(ExitButtonPosition).map(position => ({
            label: ExitButtonPositionLabels[position],
            value: position,
          }))}
          row
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferenceWelcomeTabExitButtonBackgroundColor}
          label={'Fill Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferenceWelcomeTabExitButtonTextColor}
          label={'Text Color'}
          fullWidth
          required
        />
        <FormTextInput
          name={themeUpsertFormKeys.preferenceWelcomeTabExitButtonCornerRadius}
          label="Exit Button Corner Radius"
          inputWidth="92px"
          mode={BuilderTextInputMode.CornerRadius}
        />
      </SidebarFormGroup>
      <SidebarFormGroup sx={{ gap: '16px' }}>
        <FormSectionSwitch title={'Icon'} name={themeUpsertFormKeys.preferenceWelcomeTabExitButtonIconVisible} />
        {showIconForm && (
          <Box px={'18px'}>
            {!showDefaultIconForm && (
              <SidebarFormGroup sx={{ pt: 0, pb: '16px' }} hasBorderBottom>
                <FormFileUpload
                  name={themeUpsertFormKeys.preferenceWelcomeTabExitButtonIconUrl}
                  label={'Exit Button Icon'}
                  belowButtonLabel={'Container size: 24x24'}
                  uploadContext={themeFileUploadContext}
                  accept={themeFileAllowedTypes}
                />
              </SidebarFormGroup>
            )}

            <SidebarFormGroup sx={{ py: showDefaultIconForm ? '0px' : '16px', gap: '8px' }}>
              <FormControlLabel
                control={<FormCheckbox name={themeUpsertFormKeys.preferenceWelcomeTabExitButtonUseDefaultIcon} />}
                label={<Typography variant={'label'}>Use Default Icon ("X")</Typography>}
                sx={{ px: '2px' }}
              />

              {showDefaultIconForm && (
                <FormColorInput
                  name={themeUpsertFormKeys.preferenceWelcomeTabExitButtonIconColor}
                  label={'Icon Color'}
                  fullWidth
                  required
                />
              )}
            </SidebarFormGroup>
          </Box>
        )}
      </SidebarFormGroup>
    </>
  )
}
