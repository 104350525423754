import React, { useCallback, useContext } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { FieldArray, useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Button, DataGrid, TableCell } from '@ketch-com/deck'
import { DropdownOptionDTO, FormFieldFormDTO } from 'pages/consentAndRights/forms/fields/upsert/interfaces'
import { DraggableDropdownItem } from '.'
import { FormFieldContext } from '../../../context/FormFieldUpsertContextProvider'
import Grid from '@mui/material/Grid'
import { TranslationsCollapse } from '.'
import { FormInput } from 'components/form/FormInput'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

import { TranslationDTO } from 'pages/settings/customRightName/components/LanguageCellRenderer'
import { FormInlineEdit } from 'components/form/FormInlineEdit'
/**
 * Reorders the dropdown options based on the drag and drop operation.
 *
 * @param {DropdownOptionDTO[]} list - The original list of dropdown options.
 * @param {number} startIndex - The index of the option being dragged.
 * @param {number} endIndex - The index where the option is dropped.
 * @returns {DropdownOptionDTO[]} - The reordered list of dropdown options.
 */
export const reorderDropdownOptions = (list: DropdownOptionDTO[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

type Props = {}

type DataGridTranslations = TranslationDTO & {
  rowIndex: number
}

export const DropdownInputConfig: React.FC<Props> = () => {
  const [isTranslationsExpanded, setIsTranslationsExpanded] = React.useState<boolean>(false)
  const [expandedItemTranslations, setExpandedItemsTranslations] = React.useState<string[]>([])
  const { values, setFieldValue, setFieldTouched, errors } = useFormikContext<FormFieldFormDTO>()
  const { getDummyDropdownItem } = useContext(FormFieldContext)

  /**
   * Handles the drag and drop event when an item is dropped.
   *
   * @param {DropResult} result - The result object containing the drag and drop details.
   * @returns {void}
   */
  const onDragEnd = useCallback(
    (result: DropResult) => {
      // handle item dropped outside the list area
      if (!result.destination) return
      const startIndex = result.source.index
      const endIndex = result.destination.index
      const newItems = reorderDropdownOptions(values?.dropdownOptions || [], startIndex, endIndex)
      setFieldValue('dropdownOptions', newItems)
    },
    [setFieldValue, values?.dropdownOptions],
  )

  const dataGridRows: DataGridTranslations[] =
    values?.translations.map((row, index) => ({ ...row, rowIndex: index })) || []

  return (
    <Box display="flex" flexDirection="column" p={2.25}>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: 'white.main',
          borderRadius: 2.75,
          mb: 3,
        }}
      >
        <Box p={2}>
          <Grid container spacing={3}>
            {/* Title Input */}

            <Grid item xs={6}>
              <FormInput
                required
                fullWidth
                formPropertyName="title"
                label="Display Name"
                placeholder="Add field label"
                shouldUpdateDebounced
              />
            </Grid>

            <Grid item xs={6} />

            {/* UI Hint Input */}

            <Grid item xs={6}>
              <FormInput
                fullWidth
                formPropertyName="uiHint"
                label="Hint Text"
                placeholder="Add hint text"
                onChange={e => {
                  if (errors.uiHint?.includes('characters')) setFieldTouched('uiHint', true)
                }}
                shouldUpdateDebounced
                alwaysShowError
              />
            </Grid>

            <Grid item xs={6} />
          </Grid>

          {/* Translations Section Header */}

          <TranslationsCollapse
            isExpanded={isTranslationsExpanded}
            setIsExpanded={setIsTranslationsExpanded}
            content={
              <>
                <DataGrid
                  autosizeOnMount
                  autosizeOptions={{
                    includeHeaders: true,
                    includeOutliers: false,
                    expand: true,
                  }}
                  getRowHeight={() => 'auto'}
                  columns={[
                    {
                      align: 'left',
                      field: 'language',
                      flex: 1,
                      headerAlign: 'left',
                      headerName: 'Language',
                      sortable: false,
                      renderCell: ({ row }: GridRenderCellParams<DataGridTranslations>) => {
                        return <TableCell title={row.title} />
                      },
                    },
                    {
                      align: 'left',
                      field: 'fieldTitleTranslation',
                      flex: 1,
                      headerAlign: 'left',
                      headerName: 'Display Name Translation',
                      sortable: false,
                      renderCell: ({ row: { rowIndex } }: GridRenderCellParams<DataGridTranslations>) => {
                        return (
                          <TableCell>
                            <FormInlineEdit
                              sx={{
                                input: {
                                  maxWidth: '250px !important',
                                },
                                '& .InlineEdit-content': {
                                  '&.InlineEdit-medium': {
                                    maxWidth: '250px',
                                    height: 'auto',
                                    minHeight: '32px',
                                  },
                                },
                              }}
                              formPropertyName={`translations[${rowIndex}].fieldTitleTranslation`}
                              placeholder="Add Translation"
                              shouldUpdateDebounced
                              // necessary so it doesn't interfere with the data grid accesibility keyboard actions
                              onKeyDown={e => e.stopPropagation()}
                            />
                          </TableCell>
                        )
                      },
                    },
                    {
                      align: 'left',
                      field: 'uiHintTranslation',
                      flex: 1,
                      headerAlign: 'left',
                      headerName: 'Hint Text Translation',
                      sortable: false,
                      renderCell: ({ row: { rowIndex } }: GridRenderCellParams<DataGridTranslations>) => {
                        return (
                          <TableCell>
                            <FormInlineEdit
                              sx={{
                                input: {
                                  maxWidth: '250px !important',
                                },
                                '& .InlineEdit-content': {
                                  '&.InlineEdit-medium': {
                                    maxWidth: '250px',
                                    height: 'auto',
                                    minHeight: '32px',
                                  },
                                },
                              }}
                              formPropertyName={`translations[${rowIndex}].uiHintTranslation`}
                              placeholder="Add Translation"
                              shouldUpdateDebounced
                              // necessary so it doesn't interfere with the data grid accesibility keyboard actions
                              onKeyDown={e => e.stopPropagation()}
                            />
                          </TableCell>
                        )
                      },
                    },
                  ]}
                  disableChildrenSorting
                  disableColumnMenu
                  disableColumnPinning
                  disableColumnResize
                  disableColumnReorder
                  disableRowSelectionOnClick
                  disableRowHoverStates
                  getRowId={row => row.code}
                  rows={dataGridRows}
                  hideFooter
                />
              </>
            }
          />
        </Box>
      </Box>

      {/* Items Section Header */}

      <Typography variant="h4" color="darkDuskFaded.main" mb={2}>
        Items
      </Typography>

      {/* Dropdown Items */}

      <FieldArray
        name="dropdownOptions"
        render={({ push, remove }) => (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => {
                const { innerRef, droppableProps } = provided
                return (
                  <Box {...droppableProps} ref={innerRef}>
                    {(values?.dropdownOptions || []).map((option, dropdownOptionIndex) => {
                      const isTranslationExpanded = expandedItemTranslations.includes(option.id)
                      const isDragDisabled = (values?.dropdownOptions || [])?.length === 1

                      return (
                        <Draggable
                          key={option.id}
                          draggableId={option?.id || ''}
                          index={dropdownOptionIndex}
                          isDragDisabled={isDragDisabled}
                        >
                          {(provided, snapshot) => (
                            <DraggableDropdownItem
                              key={option.id}
                              dropdownOptionIndex={dropdownOptionIndex}
                              isTranslationExpanded={isTranslationExpanded}
                              remove={remove}
                              setExpandedItemsTranslations={setExpandedItemsTranslations}
                              provided={provided}
                              snapshot={snapshot}
                              option={option}
                            />
                          )}
                        </Draggable>
                      )
                    })}

                    {provided.placeholder}

                    {/* Add Item Button */}

                    <Box display="flex" alignItems="center">
                      <Button color="secondary" onClick={() => push(getDummyDropdownItem())}>
                        Add Item
                      </Button>
                    </Box>
                  </Box>
                )
              }}
            </Droppable>
          </DragDropContext>
        )}
      />
    </Box>
  )
}
