import { isUndefined } from 'lodash'

import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { getSiblingsFromStep } from 'pages/orchestration/workflows/edit/utils/steps/siblingsFromStep'

interface ICanReachStartPath {
  step: ICanvasStep
  steps: Array<ICanvasStep>
}

export function getStepsToStartCount(params: ICanReachStartPath, count: number = 0): number {
  const { step, steps } = params
  const { parentStep } = getSiblingsFromStep({ step, steps })
  const isJoinGateway = step?.gateway?.mode === WorkflowActivityGatewayMode.JOIN

  if (isJoinGateway) {
    const joinResults: Array<number> = []
    const joinStepsToCheck: Array<ICanvasStep> = steps.filter(workflowStep => {
      if (workflowStep?.activity?.transition === step.ID) {
        return true
      }

      if (workflowStep?.workflow?.transition === step.ID) {
        return true
      }

      if (workflowStep?.placeholder?.transition === step.ID) {
        return true
      }

      if (workflowStep?.start?.transition === step.ID) {
        return true
      }

      return false
    })

    joinStepsToCheck?.forEach?.(stepToCheck =>
      joinResults.push(getStepsToStartCount({ step: stepToCheck, steps }, count + 1)),
    )

    return Math.min(...joinResults)
  }

  if (!isUndefined(step.start)) {
    return count
  }

  if (!parentStep) {
    return Infinity // if there's no path to the start, we return Infinity
  }

  return getStepsToStartCount({ step: parentStep, steps }, count + 1)
}
