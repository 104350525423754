import React from 'react'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { RoutesManager } from 'utils/routing/routesManager'
import { useApplications } from 'api/applications/queries/useApplications'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { ContentGroup, DataGrid, EmptyState, Spinner } from '@ketch-com/deck'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateApplications.svg'
import { cookieApplicationsTabGridColumns } from './utils/cookieApplicationsTabDataGridUtils'
import { Box } from '@mui/material'

type Props = {
  cookie: CookieDTO
}

export const CookieApplicationsTab: React.FC<Props> = ({ cookie }) => {
  const { data: applications, isFetching, isLoading } = useApplications()
  const isPending = isFetching || isLoading
  const mappedApplications =
    applications?.filter(
      application => !!cookie?.propertyCookies?.find(property => property.propertyCode === application.code),
    ) || []

  return (
    <>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
          { title: 'Cookies', link: RoutesManager.policyCenter.cookies.root.getURL() },
          {
            title: cookie?.name || cookie?.code,
            link: RoutesManager.policyCenter.cookies.view.root.getURL({ code: cookie?.code }),
          },
          { title: 'Properties' },
        ]}
      />
      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup title="Properties" titleVariant="h3" variant="inner-page-header" />
        {isPending ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '50px 0',
            }}
          >
            <Spinner size="32px" thickness={2.5} />
          </Box>
        ) : (
          <>
            {mappedApplications.length ? (
              <Box>
                <DataGrid
                  sx={{
                    paddingLeft: 'unset',
                    paddingRight: 'unset',
                    paddingTop: 'unset',
                  }}
                  autosizeOnMount
                  columns={cookieApplicationsTabGridColumns}
                  disableChildrenSorting
                  disableColumnMenu
                  disableColumnPinning
                  disableColumnReorder
                  disableColumnResize
                  getRowId={row => row.ID}
                  hideFooter
                  rows={mappedApplications}
                  getRowHeight={() => 'auto'}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: 'bone.main',
                  padding: '34px',
                  borderRadius: '11px',
                }}
              >
                <EmptyState customIcon={<EmptyStateIcon />} title="No properties mapped to this cookie" />
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  )
}
