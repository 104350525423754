import { FetchFormFieldsResponseBodyDTO, FormFieldCategoryDTO } from '@ketch-com/figurehead'
import { Ordering, formatOrdering } from 'api/common/utils'

import { API } from 'utils/api/api'
import { formatRequestStringV2 } from 'utils/helpers/api/formatRequestStringV2'

type Params = {
  start?: number
  category?: FormFieldCategoryDTO
  query?: string
  includeMetadata?: boolean
  includeDeleted?: boolean
  includeReservedFormFields?: boolean
  ordering?: Ordering<'created_at'> | Ordering<'title'>
}

export const fetchFormFields = ({ ordering, ...filters }: Params) => {
  return API.get<FetchFormFieldsResponseBodyDTO>(
    formatRequestStringV2({
      entityUrl: `/api/form-fields`,
      params: {
        filters: {
          ...filters,
          ordering: formatOrdering(ordering),
        },
      },
    }),
  )
}
