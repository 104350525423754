import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  code: string
}

export const checkIsOrganizationCodeAvailable = ({ code }: Params) =>
  API.get<{ available?: boolean }>(
    formatRequestString({
      entityUrl: `/api/provision/organization/${code}/available`,
    }),
  )
    .then(({ data }) => !!data?.available)
    .catch(() => false)
