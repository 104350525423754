import { MegaFilterOptionType } from '@ketch-com/deck'
import { Filter } from '../interfaces'

export function mergeObjectsByKey(
  arr: { key: string; value: string }[],
  filters: Filter[],
): Record<string, MegaFilterOptionType[]> {
  const result: Record<string, MegaFilterOptionType[]> = {}

  for (const item of arr) {
    const { key, value } = item
    const valueLabel = filters.find(filter => filter.key === key)?.items.find(item => item.id === value)?.label || ''
    if (!result[key]) {
      result[key] = [{ category: key, value: value, label: valueLabel }]
    } else {
      result[key].push({ category: key, value: value, label: valueLabel })
    }
  }

  return result
}
