import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'
import { isEmpty } from 'lodash'

import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'components/ui-kit/button/Button'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseEmptyState: {
        fontFamily: typography.fontFamily,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        boxSizing: 'border-box',
      },
      icon: {
        marginBottom: 20,
      },
      title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 800,
        marginBottom: 6,
        color: palette.darkDusk.main,
      },
      description: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: palette.darkDusk.main,
        fontSize: 12,
        fontWeight: 500,
        maxWidth: 450,
      },
      actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 24,
      },
      action: {
        '&:not(:last-child)': {
          marginRight: 8,
        },
      },

      // Types:
      typeList: {
        borderRadius: 11,
        minHeight: 260,
        padding: 50,
      },
      typePage: {
        borderRadius: 11,
        minHeight: 260,
        padding: 50,
      },
      typeModal: {
        minHeight: 200,
        padding: 30,
      },
      typePopover: {
        minHeight: 200,
        padding: 30,

        '& $title': {
          color: palette.iron.main,
        },

        '& $description': {
          color: palette.iron.main,
        },
      },

      // Backgrounds:
      bgWhite: {
        background: palette.white.main,

        '& > $icon': {
          '& svg': {
            fontSize: typography.pxToRem(64),
          },
        },
      },
      bgGrey: {
        background: palette.bone.main,

        '& > $icon': {
          '& svg': {
            fontSize: typography.pxToRem(50),
          },
        },
      },
      bgFadedGrey: {
        background: palette.fadedGrey.main,
      },
      textFaded: {
        color: palette.darkDuskFaded.main,
      },
    }),
  { name: 'EmptyState' },
)

type Action = {
  hidden?: boolean
  disabled?: boolean
  title: string
  tooltip?: string
  variant?: BUTTON_VARIANTS
  size?: BUTTON_SIZES
  onClick: () => void
}

export interface Props {
  /** Custom component className */
  className?: any
  /** Custom icon className */
  iconClassName?: any
  /** Component variant reflects dimensions based on context where it's used */
  variant?: 'list' | 'page' | 'modal' | 'popover'
  /** Variant for title text color */
  titleTextVariant?: 'faded'
  /** Variant for title description color */
  descriptionTextVariant?: 'faded'
  /** Component background reflects background color */
  bgVariant?: 'white' | 'grey' | 'fadedGrey'
  /** Component icon displayed above title */
  icon?: any
  /** Component title */
  title?: any
  /** Component description */
  description?: any
  /** Component actions (buttons) list */
  actions?: Action[]
  /** Spacer Height */
  spacerHeight?: number
}

/**
 * -
 */
export const EmptyState: React.FC<Props> = ({
  className,
  iconClassName,
  titleTextVariant = '',
  descriptionTextVariant = '',
  variant = 'list',
  bgVariant = 'white',
  icon,
  title,
  description,
  actions = [],
  spacerHeight,
}) => {
  const classes = useStyles()

  const componentClassName = clsx(className, classes.baseEmptyState, {
    [classes.typeList]: variant === 'list',
    [classes.typePage]: variant === 'page',
    [classes.typeModal]: variant === 'modal',
    [classes.typePopover]: variant === 'popover',

    [classes.bgWhite]: bgVariant === 'white',
    [classes.bgGrey]: bgVariant === 'grey',
    [classes.bgFadedGrey]: bgVariant === 'fadedGrey',
  })

  const titleClassName = clsx(classes.title, {
    [classes.textFaded]: titleTextVariant === 'faded',
  })

  const descriptionClassName = clsx(classes.description, {
    [classes.textFaded]: descriptionTextVariant === 'faded',
  })

  const visibleActions = actions.filter(({ hidden }) => !hidden)

  return (
    <div className={componentClassName}>
      {!!icon && <div className={clsx(classes.icon, iconClassName)}>{icon}</div>}
      {!!title && <div className={titleClassName}>{title}</div>}
      {!!description && <div className={descriptionClassName}>{description}</div>}

      {!isEmpty(visibleActions) && (
        <div className={classes.actions}>
          {visibleActions.map((action, index) => (
            <Button
              key={index}
              disabled={action.disabled}
              variant={action.variant}
              size={action.size}
              className={classes.action}
              onClick={action.onClick}
              tooltip={action?.tooltip}
            >
              {action.title}
            </Button>
          ))}
        </div>
      )}

      {spacerHeight ? <div style={{ height: spacerHeight }} /> : null}
    </div>
  )
}
