import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIRequestParams } from 'api/common/utils'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { RightStatsDTO } from 'interfaces/rights/rightStats'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { DataRoleType } from 'interfaces/events'

type Params = APIRequestParams<{
  organizationCode: string
  policyScope?: PolicyScopeDTO['code']
  dataSubjectType?: DataSubjectTypeDTO['code']
  dataRole?: DataRoleType
}>

type Response = {
  report: RightStatsDTO
}

export const fetchRightsStatsReport = ({ organizationCode, policyScope, dataSubjectType, dataRole }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/right-stats/report`,
      params: {
        filters: {
          policyScopeCode: policyScope,
          dataSubjectTypeCode: dataSubjectType,
          dataRole: dataRole,
        },
      },
    }),
  )
