import React, { MouseEvent, useState } from 'react'
import { Formik, Form } from 'formik'
import { MenuAutocomplete, ListItemText, ActionSheetItem, Icon, Button } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { ActionButtonsBar } from './components/ActionButtonsBar'

interface MyOption {
  myLabel: string
  myValue: string
}

// TODO:BAC - dummy options should be able to take on any shape (so long as consistent)
const dummyOptions: MyOption[] = [
  {
    myLabel: 'Apple',
    myValue: 'apple',
  },
  {
    myLabel: 'Banana',
    myValue: 'banana',
  },
  {
    myLabel: 'OrangeOrangeOrangeOrangeOrangeOrange',
    myValue: 'orange',
  },
]

export const AutoCompleteMultiSelectExample: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const [previousValues, setPreviousValues] = useState<MyOption[]>([])

  const initialFormikValue: {
    autocompleteField: MyOption[]
  } = {
    autocompleteField: previousValues,
  }

  const open = Boolean(anchorEl)

  const handleClose = () => {
    if (anchorEl) anchorEl.focus()
    setAnchorEl(undefined)
  }

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h3" mb={2}>
        Autocomplete Multi Select Example with Formik anchored to a button
      </Typography>

      <Formik initialValues={initialFormikValue} onSubmit={values => console.info('onSubmit, values = ', values)}>
        {formik => {
          const { values, setFieldValue, resetForm } = formik

          const handleClear = () => {
            resetForm()
          }

          const handleCancelAction = () => {
            setFieldValue('autocompleteField', previousValues)
            if (anchorEl) anchorEl.focus()
            setAnchorEl(undefined)
          }

          const handleOk = () => {
            setFieldValue('autocompleteField', values.autocompleteField)
            setPreviousValues(values.autocompleteField)
            if (anchorEl) anchorEl.focus()
            setAnchorEl(undefined)
          }

          return (
            <Form>
              <Button
                size="large"
                color="primary"
                disableRipple
                onClick={handleClick}
                endIcon={<Icon name="OArrowCDown" />}
              >
                Select
              </Button>
              <MenuAutocomplete
                sx={{
                  width: 300,
                }}
                multiple={true}
                value={values.autocompleteField}
                isAutocompleteOpen={open}
                anchorEl={anchorEl}
                handleOnClose={handleClose}
                wrapperPopperId={'wrapper-popper-id'}
                isOptionEqualToValue={(option, value) => option.myValue === value.myValue}
                getOptionLabel={elem => (typeof elem === 'string' ? elem : elem.myLabel)}
                noOptionsText={'Nothing found message text'}
                showSelectionCountSection={true}
                textInputVariant="outlined"
                handleOnChange={(newValue, reason) => {
                  setFieldValue('autocompleteField', newValue)
                }}
                renderOption={(props, option, { selected }) => (
                  <ActionSheetItem selected={selected} {...props}>
                    <ListItemText selected={selected}>{option.myLabel}</ListItemText>
                  </ActionSheetItem>
                )}
                options={dummyOptions}
                renderActionItems={() => (
                  <ActionButtonsBar
                    showClearButton={true}
                    handleClose={handleCancelAction}
                    handleApply={handleOk}
                    handleClear={handleClear}
                  />
                )}
              />

              <Box mt={2}>
                <Button color="tertiary" type="submit" size="large">
                  Submit Formik Form
                </Button>
              </Box>

              <pre>{JSON.stringify(formik, null, 2) || 'nothing to preview'}</pre>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}
