import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import {
  CircularProgress,
  CircularProgressSize,
  DataGrid,
  TabSegmentedGroup,
  TabSegmented,
  ColorNames,
  DropListButton,
  theme,
} from '@ketch-com/deck'

import { usePermitsStatsJurisdiction } from 'api/permitStatistics/queries/usePermitsStatsJurisdiction'
import { getPermitStatisticsFilterState } from 'store/permitStatisticsFilterStateSlice/selectors'
import { useAppSelector } from 'store/hooks'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'
import { useIsDemoOrg } from 'utils/hooks/useIsDemoOrg'
import { allowanceBreakdownPerExperienceColumns } from '../utils/allowanceBreakdownPerExperienceColumns'
import { useExperiences } from 'api/experiences/queries/useExperiences'
import { Cell, Pie, PieChart, Tooltip } from 'recharts'
import { useApplications } from 'api/applications/queries/useApplications'
import { DistributionPerJurisdictionTooltip } from '../../Distribution/components/DistributionPerJurisdiction/components'

const PREFIX = 'AllowanceBreakdownPerJurisdiction'

const classes = {
  table: `${PREFIX}-table`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.table}`]: {
    borderBottom: `1px solid ${palette.iron.main}`,
  },
}))

type Props = {}

export const AllowanceBreakdownPerExperience: React.FC<Props> = () => {
  const { startDate, endDate, identitySpace, jurisdiction, environment, property, purpose } =
    useAppSelector(getPermitStatisticsFilterState)

  const [segmentTab, setSegmentTab] = React.useState(0)
  const [propertyIndex, setPropertyIndex] = React.useState(0)

  const [isTimeoutReached, setIsTimeoutReached] = React.useState(false)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsTimeoutReached(true)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  const { data: applications } = useApplications()
  const propertyOptions = applications.map(property => ({
    label: property.name,
    value: property.code,
  }))
  console.log(applications)

  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])
  const { isDemoOrg } = useIsDemoOrg()
  const { pagination } = usePermitsStatsJurisdiction({
    itemsPerPage: 5,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  const { data: experiences } = useExperiences()
  console.log(experiences)

  const mockData = experiences.map((experience, index) => ({
    allowed: 5,
    // Add/subtract some ammount to make demo data more interesting
    allowanceRate: 50 + (index % 2) * -1 * index,
    allowedPermits: 5,
    code: experience.code,
    consentRequiredCount: 0,
    name: experience.name,
    purposeCount: 0,
    totalPermits: 10,
  }))

  const abData = [
    {
      code: 'one',
      color: '#15005A',
      consentRequiredColor: 'darkDusk' as ColorNames,
      consentRequiredText: 'Opt-In',
      name: 'Marketing - Purchase History - Button',
      percent: 60,
      totalRequests: 60,
    },
    {
      code: 'two',
      color: '#1463BB',
      consentRequiredColor: 'darkDusk' as ColorNames,
      consentRequiredText: 'Opt-In',
      name: 'Marketing - Purchase History - Switch',
      percent: 40,
      totalRequests: 40,
    },
  ]

  const totalPages = pagination.totalPages

  const rowsPerPage = React.useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = React.useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  const handleSegmentTabChange = (event: React.MouseEvent<HTMLElement>, newTab: number) => {
    if (newTab !== null) {
      setSegmentTab(newTab)
    }
  }

  const SegmentTabs = (
    <Box display="inline-flex" gap="32px" mb="12px">
      <TabSegmentedGroup
        value={segmentTab}
        exclusive
        onChange={handleSegmentTabChange}
        aria-label="Small sizes"
        size="small"
        sx={{
          mt: '8px',
          height: 'fit-content',
          '& .MuiButtonBase-root': {
            whiteSpace: 'nowrap',
          },
        }}
      >
        <TabSegmented value={0} key="1">
          All
        </TabSegmented>
        <TabSegmented value={1} key="2">
          A/B Tests
        </TabSegmented>
      </TabSegmentedGroup>
      {segmentTab === 1 && (
        <DropListButton
          options={propertyOptions}
          onChange={(_, newValue) => {
            const index = propertyOptions.findIndex(option => option.value === newValue?.value)
            setPropertyIndex(index)
          }}
          value={propertyOptions[propertyIndex]}
          size="small"
          label="Property"
        />
      )}
    </Box>
  )

  if (!isTimeoutReached) {
    return (
      <StyledBox
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: ({ palette }) => palette.white.main,
          borderRadius: 2,
          padding: 4,
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h3">Per Experience</Typography>
          <Typography variant="body" color="darkDuskFaded.main" sx={{ mb: 0.5 }}>
            See allowance rate distribution across all experiences.
          </Typography>
          {SegmentTabs}
        </Box>

        <Box flex={1} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={CircularProgressSize.xLarge} />
        </Box>
      </StyledBox>
    )
  }

  return (
    <StyledBox
      sx={{
        borderRadius: 2,
        display: 'flex',
        background: theme => theme.palette.white.main,
        flexDirection: 'column',
        width: isDemoOrg ? 421 : 632,
        p: 4,
      }}
    >
      <Typography variant="h3">Per Experience</Typography>
      <Typography variant="body" color="darkDuskFaded.main" sx={{ mb: 0.25 }}>
        See allowance rate distribution across all experiences.
      </Typography>
      {SegmentTabs}
      {segmentTab === 0 ? (
        <DataGrid
          getRowId={row => row?.code || row?.allowedPermits}
          sx={{
            padding: 0,
          }}
          autosizeOnMount
          disableRowSelectionOnClick
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: false,
            columns: allowanceBreakdownPerExperienceColumns(t).map((column, index) => column.field),
            expand: true,
          }}
          getRowHeight={() => 'auto'}
          columns={allowanceBreakdownPerExperienceColumns(t, isDemoOrg)}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowHoverStates
          rows={mockData}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[rowsPerPage]}
          rowCount={pagination.totalResults}
          hideFooterPagination={totalPages < 2}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            mb: 1,
            pt: 2,
          }}
        >
          <PieChart width={210} height={210} data={abData}>
            <Pie
              data={abData}
              animationDuration={500}
              startAngle={-120}
              endAngle={360}
              innerRadius={50}
              outerRadius={100}
              fill="#8884d8"
              paddingAngle={abData.length === 1 ? 0 : 4}
              dataKey="percent"
            >
              {abData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip
              content={props => {
                return (
                  <DistributionPerJurisdictionTooltip
                    active={props.active}
                    label={props.label}
                    payload={props.payload}
                  />
                )
              }}
            />
          </PieChart>
          <Box display="flex" width="100%">
            <ul style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              {abData.map(data => (
                <li style={{ color: data.color }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>
                    <Typography variant="label" color={theme.palette.Text.Primary}>
                      {data.name}
                    </Typography>
                    <Typography variant="smallBody" color={theme.palette.Text.Primary}>
                      {data.percent}% Opt-In
                    </Typography>
                  </Box>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      )}
    </StyledBox>
  )
}
