import { FormFieldDTO, FormFieldTypeDTO } from '@ketch-com/figurehead'
import React, { useContext } from 'react'
import Box from '@mui/material/Box'

import { FormDataInputValue } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'
import { Button } from '@ketch-com/deck'
import { ApiCallContext } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/context/ApiCallContext'
import { CheckboxInput, FormFieldListValueInput, TextValueInput } from '.'
type Props = {
  formField?: FormFieldDTO
  onCancel: () => void
  onSubmit: () => void
  formValue: FormDataInputValue
  setFormValue: (v: FormDataInputValue) => void
  isUnknown?: boolean
}
export const FormFieldInlineForm: React.FC<Props> = ({
  formField,
  onCancel,
  formValue,
  setFormValue,
  onSubmit,
  isUnknown,
}) => {
  const { isSubmittingWebhookContext } = useContext(ApiCallContext)
  const form =
    formField?.type === FormFieldTypeDTO.DropdownFormFieldType ? (
      <FormFieldListValueInput formField={formField} formValue={formValue} setFormValue={setFormValue} />
    ) : formField?.type === FormFieldTypeDTO.TextFormFieldType ? (
      <TextValueInput formValue={formValue} setFormValue={setFormValue} />
    ) : formField?.type === FormFieldTypeDTO.CheckboxFormFieldType ? (
      <CheckboxInput formValue={formValue} setFormValue={setFormValue} formField={formField} isUnknown={isUnknown} />
    ) : null

  const shouldDisableButton = !form || formValue === undefined

  return (
    <Box display="flex" flexDirection="column" gap={1.5}>
      {form && <Box>{form}</Box>}
      <Box display="flex" alignItems="center" gap={1}>
        <Button disabled={isSubmittingWebhookContext} color="secondary" aria-label="close" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={shouldDisableButton}
          pending={isSubmittingWebhookContext}
          aria-label="update form field"
          onClick={onSubmit}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}
