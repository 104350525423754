import { AssessmentDocumentDTO } from '@ketch-com/figurehead'
import { useTeams } from 'api/teams/queries/useTeams'
import { Team } from 'interfaces/services/harbormaster/teams_gen.schemas'
import { fromAssignee, toAssignee } from 'utils/helpers/teamStringParser'
import * as Yup from 'yup'

export type AssessmentCreateFormValues = {
  name: string
  template: string
  assignee: string
}

export const getInitialAssessmentCreateFormValues = (): AssessmentCreateFormValues => {
  return {
    name: '',
    template: '',
    assignee: '',
  }
}

export const useMapAssessmentCreateFormValuesToPayload = () => {
  const { data: teams } = useTeams({ params: { includeAssignee: true } })
  const teamsList: Team[] = teams?.teams || []

  return ({ name, template, assignee }: AssessmentCreateFormValues): AssessmentDocumentDTO => {
    const [team, teamAssignee] = fromAssignee(assignee)

    const builtAssignee =
      team && !teamAssignee
        ? toAssignee(team, teamsList.find(team => team.id === assignee)?.assigneeId || '')
        : undefined

    return {
      data: {
        type: 'Assessment',
        relationships: {
          template: {
            data: { attributes: { publishedID: template } },
          },
        },
        attributes: {
          name,
          initialAssignee: builtAssignee ? builtAssignee : assignee && assignee.length > 0 ? assignee : undefined,
        },
      },
    }
  }
}

export const getCreateAssessmentValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Required'),
    template: Yup.string().required('Required'),
    assignee: Yup.string(),
  })
