import React from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

import { ActionsCellRenderer } from './components/ActionsCellRenderer'
import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { Badge } from 'components/ui-kit/badge/Badge'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { INSUFFICIENT_PERMISSIONS_TOOLTIP } from 'utils/constants/INSUFFICIENT_PERMISSIONS_TOOLTIP'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { Pagination } from 'api/common/paginatedQuery'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { PoliciesListActions, PoliciesListFilters, TypeCellRenderer } from './components'
import { POLICY_STATUS_TITLE_MAP, POLICY_STATUS_VARIANT_MAP } from './tempInterfaces/PolicyStatus'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateCommon.svg'
import { RoutesManager } from 'utils/routing/routesManager'
import { Status, StatusVariant } from 'components/ui-kit/status/Status'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ViewListLayout } from 'components/ui-layouts/viewListLayout/ViewListLayout'
import { ViewListLayoutContent } from 'components/ui-layouts/viewListLayout/ViewListLayoutContent'
import { ViewListLayoutHeader } from 'components/ui-layouts/viewListLayout/ViewListLayoutHeader'

const PREFIX = 'PoliciesList'

const classes = {
  status: `${PREFIX}-status`,
}

const StyledViewListLayout = styled(ViewListLayout)(() => ({
  [`& .${classes.status}`]: {
    paddingLeft: '0px !important',
  },
}))

type Props = {
  isLoading: boolean
  policies: AssetPolicyDTO[]
  pagination: Pagination
}

export const PoliciesList: React.FC<Props> = ({ isLoading, policies, pagination }) => {
  const navigate = useNavigate()
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWritePolicy = getIsPermitted([PERMISSIONS.ASSET_POLICY_WRITE])

  return (
    <StyledViewListLayout isReady={!isLoading}>
      <ViewListLayoutHeader
        filters={<PoliciesListFilters policies={policies} />}
        title="Data Policies"
        actions={<PoliciesListActions />}
      />
      <ViewListLayoutContent>
        {!isLoading && policies.length === 0 ? (
          <EmptyState
            title="There are no policies for this organization"
            description="Create your first one to populate the table"
            actions={[
              {
                title: 'Create Policy',
                size: 'big',
                onClick: () => navigate(RoutesManager.policyCenter.policies.upsert.getURL({ id: 'new' })),
                disabled: !isPermittedToWritePolicy,
                tooltip: !isPermittedToWritePolicy ? INSUFFICIENT_PERMISSIONS_TOOLTIP : undefined,
              },
            ]}
            variant="list"
            icon={<EmptyStateIcon />}
          />
        ) : (
          <TablePaginated
            pending={isLoading}
            pagination={pagination}
            items={policies || []}
            isSmallHeaderText
            onRowClick={({ id }) => {
              navigate(RoutesManager.policyCenter.policies.detail.getURL({ id }))
            }}
            shouldRemoveTableRowExpandContentPadding
            cellSettings={{
              name: {
                width: 450,
                label: 'Policy',
                cellRenderer: ({ name, id }) => <NameAndCodeCellRenderer name={name} code={id} />,
              },
              status: {
                width: 165,
                label: 'Status',
                cellRenderer: ({ status }) => {
                  const statusVariant = POLICY_STATUS_VARIANT_MAP[status!] as StatusVariant
                  return (
                    <Status className={classes.status} size="regular" variant={statusVariant}>
                      {POLICY_STATUS_TITLE_MAP[status!]}
                    </Status>
                  )
                },
              },
              type: {
                width: 165,
                label: 'Type',
                cellRenderer: ({ category }) => <TypeCellRenderer category={category} />,
              },
              assetsAffected: {
                width: 165,
                label: 'Assets Affected',
                cellRenderer: ({ assetsAffected }) => <Badge variant="neutral">{assetsAffected || 0}</Badge>,
              },

              lastUpdated: {
                width: 220,
                label: 'Last Updated',
                cellRenderer: ({ metadata }) => <FormattedDateRenderer date={metadata?.createdAt} />,
              },
              actions: {
                label: '',
                cellRenderer: ({ id }) => <ActionsCellRenderer policyId={id || ''} />,
              },
            }}
          />
        )}
      </ViewListLayoutContent>
    </StyledViewListLayout>
  )
}
