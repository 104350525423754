import React, { CSSProperties, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { useSearchParams } from 'react-router-dom'

import { useQueryParams } from 'utils/hooks/useQueryParams'

import { Box, Typography } from '@mui/material'
import { CapabilitiesList } from 'interfaces/dataSystems/Capabilities'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { DropListButton, ListItemText, ActionSheetItem, SwitchToggle } from '@ketch-com/deck'

export enum ShowFilters {
  SEARCH = 'search',
  CAPABILITIES = 'capabilites',
  INTEGRATIONS = 'integrations',
}

interface Props {
  className?: string
  style?: CSSProperties
  show?: ShowFilters[]
}

export const Filters: React.FC<Props> = ({ show, className, style }) => {
  const { queries, setQueryParam, removeQueryParam } = useQueryParams<{
    q?: string
    capabilities?: string
    integrations?: string
  }>()

  const [searchString, setSearchString] = useState(queries?.q || '')
  const [selectedCapability, setSelectedCapability] = useState(queries?.capabilities || '')
  const [integrations, setIntegrations] = useState(queries?.integrations || '')

  const [searchParams] = useSearchParams()

  useEffect(() => {
    setSearchString('')
    setSelectedCapability('')
    setIntegrations('')
  }, [])

  //reset filters if queries are reset
  useDebounce(
    () => {
      if (!Object.keys(queries).length) {
        setSearchString('')
        setSelectedCapability('')
        setIntegrations('')
      }
    },
    600,
    [queries],
  )

  useDebounce(
    () => {
      if (searchString && searchString !== searchParams.get('q')) {
        setQueryParam('q', searchString)
      }
      if (!searchString) {
        removeQueryParam('q')
      }

      if (selectedCapability) {
        setQueryParam('capabilities', selectedCapability)
      }
      if (!selectedCapability) {
        removeQueryParam('capabilities')
      }

      if (integrations) {
        setQueryParam('integrations', integrations)
      }
      if (!integrations) {
        removeQueryParam('integrations')
      }
    },
    500,
    [searchString, selectedCapability, integrations],
  )

  const droplistItems = [
    ...CapabilitiesList.map(capability => {
      return {
        code: `${capability.code}`,
        name: capability.name,
      }
    }),
  ]
  const selectedCapabilityDropList =
    (selectedCapability && droplistItems.find(elem => selectedCapability === elem.code)) || null
  return (
    <Box className={className} style={style}>
      <Box
        sx={{
          display: 'flex',
          gap: 1.5,
          alignItems: 'center',
        }}
      >
        {show?.includes(ShowFilters.SEARCH) && (
          <SearchTextInput onChange={nextValue => setSearchString(nextValue)} value={searchString} showClearButton />
        )}

        {show?.includes(ShowFilters.CAPABILITIES) && (
          <DropListButton
            width="250px"
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={option => option.name}
            renderOption={(props, option, { selected }) => (
              <ActionSheetItem selected={selected} {...props}>
                <ListItemText selected={selected}>{option.name}</ListItemText>
              </ActionSheetItem>
            )}
            options={droplistItems}
            onChange={(_, value, reason) => {
              if (reason === 'clear') {
                setSelectedCapability('')
              } else {
                if (value) {
                  setSelectedCapability(value.code)
                }
              }
            }}
            value={selectedCapabilityDropList}
            placeholder="All Capabilities"
            size="small"
          />
        )}

        {show?.includes(ShowFilters.INTEGRATIONS) && (
          <>
            <SwitchToggle
              size="medium"
              checked={!!queries?.integrations}
              onChange={() =>
                queries?.integrations ? removeQueryParam('integrations') : setQueryParam('integrations', true)
              }
            />

            <Typography variant="body">Show with Integrations Only</Typography>
          </>
        )}
      </Box>
    </Box>
  )
}
