import { Box, styled } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useSidebarRoutesConfig } from 'utils/routing/useSidebarRoutesConfig'
import { SidebarSearch } from './components/SidebarSearch'
import { AppNavigationContext } from '../../context/AppNavigationContext'
import { AppNavigationSize, SidebarMode } from '../../types'
import { SidebarItem } from './components/sidebarItem/SidebarItem'
import { theme } from '@ketch-com/deck'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormMode } from 'interfaces/formModes/formMode'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { SidebarSearchResults } from './components/SidebarSearchResults'
import { useLocation } from 'react-router-dom'
import { debounce } from 'lodash'
import { SidebarReferAFriend } from './components/SidebarReferAFriend'

// Pages for which the sidebar should have a right border and should switch to compact mode
const fullscreenPages = [
  // Experiences
  RoutesManager.deployment.experiencesV2.upsert.root
    .getURL({
      id: 'dummy',
      formMode: FormMode.CREATE,
      experienceType: ExperienceType.Consent,
    })
    .split(`/${ExperienceType.Consent}/`)[0],
  RoutesManager.deployment.experiencesV2.upsert.root
    .getURL({ id: 'dummy', formMode: FormMode.EDIT, experienceType: ExperienceType.Consent })
    .split(`/${ExperienceType.Consent}/`)[0],
  RoutesManager.deployment.experiencesV2.upsert.root
    .getURL({ id: 'dummy', formMode: FormMode.DUPLICATE, experienceType: ExperienceType.Consent })
    .split(`/${ExperienceType.Consent}/`)[0],

  // Themes
  RoutesManager.deployment.themesV3.upsert.root.getURL({ formMode: FormMode.CREATE }),
  RoutesManager.deployment.themesV3.upsert.root.getURL({ formMode: FormMode.EDIT }),
  RoutesManager.deployment.themesV3.upsert.root.getURL({ formMode: FormMode.DUPLICATE }),

  // Forms
  RoutesManager.deployment.forms.templates.upsert.root.getURL({ id: '', formMode: FormMode.CREATE }),
  RoutesManager.deployment.forms.templates.upsert.root.getURL({ id: '', formMode: FormMode.EDIT }),

  // Workflow
  RoutesManager.orchestration.workflows.upsert.root.getURL({ code: '' }), // Covers both the new and edit case

  // Assessments
  RoutesManager.policyCenter.assessments.templates.upsert.getURL({
    formMode: FormMode.CREATE,
  }),
  RoutesManager.policyCenter.assessments.questions.upsert.getURL({
    formMode: FormMode.CREATE,
    code: 'new',
  }),
  RoutesManager.policyCenter.assessments.templates.upsert.getURL({
    formMode: FormMode.EDIT,
    code: '',
  }),
]

const SidebarBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minWidth: '52px', // Don't let compact sidebar shrink
  paddingLeft: '6px',
  background: theme.palette.Common.Container,
  position: 'absolute',
  height: 'calc(100% - 44px)',
  transition: 'width 0.3s ease, box-shadow 0.3s ease-in-out',

  // Hidden border so content does not shift when we add border
  borderRight: `1px solid ${theme.palette.Common.Container}`,
  boxSizing: 'border-box',

  // Larger zIndex than most elements but not max
  zIndex: 100,
}))

const SidebarScrollBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',

  // Larger zIndex than most elements but not max
  zIndex: 100,

  // Custom scrollbar
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: 10,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.fadedGrey.main,
    borderRadius: '100px',
  },
}))

const sidebarModeWidths = {
  [SidebarMode.Closed]: '0px',
  [SidebarMode.Compact]: '52px',
  [SidebarMode.Open]: '220px',
  [SidebarMode.Overlay]: '282px',
}

export const NavigationSidebar: React.FC = () => {
  const { sidebarMode, setIsSidebarHovered, setSidebarOpenSection, searchActive, appNavigationSize, setSidebarMode } =
    useContext(AppNavigationContext)
  const sidebarRoutes = useSidebarRoutesConfig()
  const { pathname } = useLocation()

  const showSearchResults = searchActive
  const isOverlay = sidebarMode === SidebarMode.Overlay
  const isHidden = sidebarMode === SidebarMode.Closed
  const renderSidebarFiller =
    (isOverlay && appNavigationSize !== AppNavigationSize.Hidden) || sidebarMode === SidebarMode.Compact

  const isRightBorderRoute = fullscreenPages.some(path => window.location.pathname.includes(path))

  // Wait 50ms before triggering onMouseEnter so a user that has just pinned the sidebar closed doesn't see
  // it jump when they drag their cursor away
  const debouncedHandleMouseEnter = debounce(() => setIsSidebarHovered(true), 50)

  const handleMouseLeave = () => {
    setIsSidebarHovered(false)
    debouncedHandleMouseEnter.cancel()
  }

  // Set the open sidebar section on page load
  useEffect(() => {
    const currentRoute = sidebarRoutes.find(route => pathname.includes(route.path))
    setSidebarOpenSection(currentRoute?.sectionId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  // Switch from full size to compact when a full screen page is opened
  useEffect(() => {
    if (fullscreenPages.some(path => pathname.includes(path)) && appNavigationSize === AppNavigationSize.FullSize)
      setSidebarMode(SidebarMode.Compact)
  }, [appNavigationSize, pathname, setSidebarMode])

  return (
    <>
      {renderSidebarFiller && <Box sx={{ width: '52px', background: 'red' }} />}
      <SidebarBox
        id="app-sidebar"
        onMouseEnter={debouncedHandleMouseEnter}
        onMouseLeave={handleMouseLeave}
        width={sidebarModeWidths[sidebarMode]}
        sx={{
          minWidth: sidebarModeWidths[sidebarMode],
          ...(sidebarMode === SidebarMode.Open ? { position: 'static', height: '100%' } : {}),
          ...(isHidden ? { display: 'none' } : {}),
          ...(isOverlay ? { boxShadow: '0px 14px 32px -2px rgba(7, 26, 36, 0.24)' } : {}),
          ...(isRightBorderRoute ? { borderRightColor: theme.palette.Black.o24 } : {}),
        }}
      >
        {/* Top of sidebar */}
        <SidebarScrollBox>
          <SidebarSearch />
          {showSearchResults ? (
            <SidebarSearchResults />
          ) : (
            sidebarRoutes.map((routeItem, index) => <SidebarItem key={index} item={routeItem} />)
          )}
        </SidebarScrollBox>

        {/* Bottom of sidebar */}
        <SidebarReferAFriend />
      </SidebarBox>
    </>
  )
}
