import React, { useContext, useMemo, useRef, useState } from 'react'
import { isUndefined } from 'lodash'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { StepIcon } from 'pages/orchestration/workflows/edit/components/stepIcon/StepIcon'
import { Button, Icon, InlineEdit, InlineEditSize, ActionSheetWrapper, ActionSheetItem } from '@ketch-com/deck'
import { Box, Tooltip } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getDsrFormState } from 'store/dsrTransponderFormSlice/selectors'
import { setCode, setDescription } from 'store/dsrTransponderFormSlice'
import { ListItemType } from 'components/ui-kit/list/List'
import { EditWorkflowContainerContext } from '../../EditWorkflowFormContainer/context/EditWorkflowContainerContext'
import { WorkflowCanvasContext } from '../../WorkflowCanvas/context/WorkflowCanvasContext'

interface ISidebarConfigsHeaderProps {
  isValid: boolean
  step: ICanvasStep
  handleSave?: () => void
  handleRemove: () => void
  handleClose: () => void
  handleToggleView?: () => void
  alternateViewType?: string
  copyable?: boolean
}

export const SidebarConfigsHeaderDsrTransponderForm: React.FC<ISidebarConfigsHeaderProps> = props => {
  const { step, handleSave, handleRemove, handleClose, handleToggleView, alternateViewType, copyable = '' } = props
  const { finish } = step
  const isEndFlow = !isUndefined(finish)
  const dispatch = useAppDispatch()
  const { code, description } = useAppSelector(getDsrFormState)
  const { handleCopyActivity } = useContext(EditWorkflowContainerContext)
  const { removeActiveSteps } = useContext(WorkflowCanvasContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const disabledCopyMessage = useRef('Copy not allowed for this workflow step')
  const disableCopy = useMemo(() => {
    // Disable copying of an invalid step
    if (isUndefined(step.valid) || !step.valid) {
      disabledCopyMessage.current = 'Please complete activity configuration before copying'
      return true
    }

    return false
  }, [step])

  const otherOptions: ListItemType[] = [
    {
      content: 'Remove',
      onClick: () => {
        handleRemove()
        handleClose()
      },
    },
    ...(copyable
      ? [
          {
            content: 'Copy',
            onClick: () => {
              handleCopyActivity(step)
              removeActiveSteps()
              handleClose()
            },
            disabled: disableCopy,
            tooltip: disabledCopyMessage.current,
          },
        ]
      : []),
  ]

  const otherOptionsMenuItems = [
    <ActionSheetItem key="remove" onClick={handleRemove}>
      Remove
    </ActionSheetItem>,
  ]
  if (copyable) {
    otherOptionsMenuItems.push(<ActionSheetItem divider key="divider" />)
    otherOptionsMenuItems.push(
      <Tooltip key="copy" title={disableCopy ? disabledCopyMessage.current : undefined}>
        <Box key="copy">
          <ActionSheetItem
            disabled={disableCopy}
            onClick={() => {
              handleCopyActivity(step)
              removeActiveSteps()
            }}
          >
            Copy
          </ActionSheetItem>
        </Box>
      </Tooltip>,
    )
  }

  return (
    <Box
      sx={{
        padding: ({ spacing }) => spacing(1.5, 1.5, 0, 1.5),
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          borderBottom: ({ palette }) => `1px solid ${palette.iron.main}`,
          paddingBottom: 1.5,
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',

          // Fix Safari issue
          minHeight: 65,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            variant="iconCustomRounded"
            sx={{
              mr: 2,
            }}
          >
            <Icon name="OCross" onClick={() => handleClose()} />
          </Button>
          <StepIcon
            step={step}
            sx={{
              marginRight: 1,
            }}
          />

          <Box display="flex" flexDirection="column">
            <InlineEdit
              size={InlineEditSize.large}
              textInputVariant="ghost"
              placeholder="Name"
              value={description}
              onChange={e => dispatch(setDescription(e.target.value))}
            />

            <InlineEdit
              textInputVariant="ghost"
              size={InlineEditSize.small}
              placeholder="Code"
              value={code}
              onChange={e => dispatch(setCode(e.target.value))}
            />
          </Box>
        </Box>

        <Box display="inline-flex" gap={1.5} alignItems="center">
          {/* TODO:BAC - remove "&& false" below when ready to implement toggle between YAML and GUI */}
          {otherOptions.length && (
            <>
              <Button size="large" variant="icon" onClick={handleClickListItem} color="tertiary">
                <Icon name="OMore" />
              </Button>
              <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleCloseMenu} transitionDuration={0}>
                {otherOptionsMenuItems}
              </ActionSheetWrapper>
            </>
          )}
          {step.activityCode === 'dsrTransponder' && false && (
            <Button color="tertiary" onClick={handleToggleView} size="large">
              {alternateViewType === 'yaml' ? 'Switch to Browse & Select' : 'Switch to YAML file'}
            </Button>
          )}

          {!isEndFlow && (
            <Button onClick={handleSave} size="large">
              Apply
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}
