import React, { CSSProperties, useState } from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useNavigate, useParams } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ConnectionV2DTO } from '@ketch-com/figurehead'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  AvatarSize,
  Banner,
  Button,
  Chip,
  ContentGroup,
  DataGrid,
  Icon,
  InfoRow,
  ActionSheetWrapper,
  ActionSheetItem,
  theme,
} from '@ketch-com/deck'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { useConnection } from 'api/dataSystems/queries/useConnection'
import { Capabilites } from '../Capabilities'
import { WorkflowsModal } from '../Modals'
import { getSystemIdentitySpaceV2DTOColumns } from './connectionsListItemUtils'

type Props = {
  connection: ConnectionV2DTO
  handleDelete: (connection: ConnectionV2DTO) => void
  className?: string
  style?: CSSProperties
  backgroundColor?: string
}

export const ConnectionsListItem: React.FC<Props> = ({
  connection,
  handleDelete,
  style,
  className,
  backgroundColor,
}) => {
  const { data: identitySpacesList, isLoading: isIdentitySpacesListLoading } = useIdentitySpaces()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false)

  const { id: installedDataSystemId } = useParams<{ id: string }>()

  const navigate = useNavigate()

  const { isPermitted } = useIsPermitted()
  const isDataSystemsWrite = isPermitted([PERMISSIONS.DATA_SYSTEMS_WRITE, PERMISSIONS.INTEGRATIONS_WRITE])
  const { workflowCount = 0, hasCredentialsIssue } = connection

  const isIntegrationWrite = isPermitted(PERMISSIONS.INTEGRATIONS_WRITE)

  const { data: connectionDetails } = useConnection({
    params: {
      id: connection.id,
    },
  })

  const hasWorkflows = workflowCount > 0
  const isSystemSpecific = installedDataSystemId !== undefined

  return (
    <>
      <Accordion
        color={backgroundColor || theme.palette.white.main}
        expanded={isOpen}
        onChange={() => setIsOpen(!isOpen)}
      >
        <AccordionSummary icon="OArrowCRight">
          <Box display="flex" alignItems="center" gap={1.5}>
            {connection.dataSystem?.logoUrl && !installedDataSystemId && (
              <Box>
                <Avatar
                  isLogo
                  src={connection.dataSystem?.logoUrl}
                  alt={connection.name}
                  size={AvatarSize.xlarge}
                  variant="rounded"
                  backgroundColor={theme.palette.tertiary.main}
                />
              </Box>
            )}

            <ContentGroup titleVariant="h4" title={connection.name} subTitle={connection.description} />
          </Box>
          <Box display="inline-flex" alignItems="center" width="440px" justifyContent="space-between">
            <Box>
              <Capabilites
                supportedCapabilites={connection?.dataSystem?.supportedCapabilities}
                hasCredentialsIssue={connection?.hasCredentialsIssue}
              />
            </Box>
            <Box display="inline-flex" gap={1.5} alignItems="center">
              {isSystemSpecific ? (
                <Chip
                  label={workflowCount}
                  onClick={event => {
                    event.stopPropagation()
                    if (hasWorkflows) {
                      setIsWorkflowModalOpen(true)
                    } else {
                      return null
                    }
                  }}
                  clickable={hasWorkflows}
                  icon={<Icon name="OWorkflow" />}
                />
              ) : (
                <Chip
                  label={hasWorkflows ? `Used in ${workflowCount} workflows` : 'Not Used in Workflows'}
                  onClick={event => {
                    event.stopPropagation()
                    if (hasWorkflows) {
                      setIsWorkflowModalOpen(true)
                    } else {
                      return null
                    }
                  }}
                  onDelete={
                    hasWorkflows
                      ? event => {
                          event.stopPropagation()
                          setIsWorkflowModalOpen(true)
                        }
                      : undefined
                  }
                  clickable={hasWorkflows}
                  deleteIcon={hasWorkflows ? <Icon name="OArrowCRight" /> : undefined}
                  icon={hasWorkflows ? <Icon name="OWorkflow" /> : undefined}
                />
              )}
              <Tooltip
                placement="bottom"
                title={isIntegrationWrite ? '' : 'You do not have permission to manage connections.'}
              >
                <span>
                  <Button
                    size="medium"
                    variant="iconSubtle"
                    onClick={handleClickListItem}
                    disabled={!isIntegrationWrite}
                  >
                    <Icon name="OMore" />
                  </Button>
                </span>
              </Tooltip>
              <ActionSheetWrapper
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transitionDuration={0}
                // need this so the accordion does not toggle open state on menu/action sheet close
                onClick={e => e.stopPropagation()}
              >
                <ActionSheetItem
                  disabled={!isDataSystemsWrite}
                  onClick={e => {
                    e.stopPropagation()
                    handleClose()
                    navigate(
                      RoutesManager.systems.connections.upsert.edit.getURL({
                        id: connection.installedDataSystemId,
                        instanceId: connection.id,
                      }),
                    )
                  }}
                >
                  Edit
                </ActionSheetItem>

                <ActionSheetItem divider />

                <ActionSheetItem
                  disabled={!isDataSystemsWrite}
                  onClick={e => {
                    e.stopPropagation()
                    handleClose()
                    handleDelete(connection)
                  }}
                >
                  Delete
                </ActionSheetItem>
              </ActionSheetWrapper>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            '&.MuiAccordionDetails-root': {
              paddingTop: 0,
            },
          }}
        >
          <Box display="flex" flexDirection="column" px={2} gap={0.75}>
            {hasCredentialsIssue && (
              <Box mb={3.5}>
                <Banner
                  severity="warning"
                  title="There is an issue with Credentials."
                  action={
                    <Tooltip
                      placement="bottom"
                      title={isIntegrationWrite ? '' : 'You do not have permission to manage connections.'}
                    >
                      <span>
                        <Button
                          variant="link"
                          disabled={!isIntegrationWrite}
                          onClick={() =>
                            navigate(
                              RoutesManager.systems.connections.upsert.edit.getURL({
                                id: connection.installedDataSystemId,
                                instanceId: connection.id,
                              }),
                            )
                          }
                        >
                          Update
                        </Button>
                      </span>
                    </Tooltip>
                  }
                />
              </Box>
            )}

            <Box display="flex" flexDirection="column" gap={4.5}>
              <Box display="flex" flexDirection="column" gap={3}>
                <Typography variant="h4">Basic Details</Typography>
                <InfoRow title="Code">{connection.code}</InfoRow>
              </Box>

              {!!connection.identitySpaceMappings?.length && !isIdentitySpacesListLoading && (
                <Box display="flex" flexDirection="column" gap={3}>
                  <Typography variant="h4">Identity</Typography>
                  <DataGrid
                    autosizeOnMount
                    autosizeOptions={{
                      includeHeaders: true,
                      includeOutliers: false,
                      expand: true,
                    }}
                    getRowHeight={() => 'auto'}
                    columns={getSystemIdentitySpaceV2DTOColumns({
                      connection,
                      identitySpacesList,
                    })}
                    disableChildrenSorting
                    disableColumnMenu
                    disableColumnPinning
                    disableColumnResize
                    disableColumnReorder
                    disableRowHoverStates
                    disableRowSelectionOnClick
                    getRowId={row => row.code}
                    rows={connection?.dataSystem?.identitySpaces || []}
                    hideFooter
                    noRowsOverlayProps={{
                      buttonTitle: '',
                      subTitle: '',
                    }}
                  />
                </Box>
              )}

              {connectionDetails?.dataSystem?.form?.length !== 0 && (
                <Box display="flex" flexDirection="column" gap={3}>
                  <Typography variant="h4">Configuration</Typography>

                  {connectionDetails?.dataSystem?.form?.map((item, index) => {
                    const { showOnView, showValueOnEdit, title, name } = item

                    const value = connectionDetails?.formFields?.find(field => field.name === name)?.value || ''

                    if (!showOnView || !showValueOnEdit) {
                      return null
                    }

                    return <InfoRow title={title || ''}>{value}</InfoRow>
                  })}
                </Box>
              )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {isWorkflowModalOpen && (
        <WorkflowsModal
          dataSystemName={connection.dataSystem?.name ?? ''}
          appInstanceId={connection?.appInstanceId ?? ''}
          onCancel={() => {
            setIsWorkflowModalOpen(false)
          }}
        />
      )}
    </>
  )
}
