import { useCallback } from 'react'
import { every, some, isArray } from 'lodash'

import { usePermissions } from 'api/permissions/queries/usePermissions'
import { useNavigate } from 'react-router-dom'

/**
 *
 * @returns isPermitted function that checks if the user has the required permissions
 */
export const useIsPermitted = () => {
  const navigate = useNavigate()
  // Fetch user permissions data using usePermissions hook
  const { data: userPermissions, isLoading: isPermissionsLoading } = usePermissions({
    staleTime: 60 * 1000 * 5, // 5 minutes
    onError: () => {
      navigate('/auth/error')
    },
  })

  const isPermitted = useCallback(
    (requiredPermissions?: string | string[], requireAll?: boolean) => {
      // Converting requiredPermissions to an array if it's not already an array
      const permissionsToCheck = isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions]

      // If user permissions data doesn't exist, return false
      if (!userPermissions.length) return false

      // If no requiredPermissions are passed, return true
      if (!permissionsToCheck.length) return true

      // Checking user permissions based on requiredPermissions and requireAll parameters
      if (requireAll) {
        return every(permissionsToCheck, permission => userPermissions.includes(permission!))
      } else {
        return some(permissionsToCheck, permission => userPermissions.includes(permission!))
      }
    },
    [userPermissions], // Only re-create function if userPermissions data changes
  )

  return {
    isPermitted,
    isPermissionsLoading,
  }
}
