import { formatRequestString } from 'utils/helpers/api/queryString'
import { API } from 'utils/api/api'
import {
  PrivacyProtocolConfigurationPutRequestBody,
  PutPrivacyProtocolConfigBody,
} from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'

type Params = {
  id?: string
  formData: PutPrivacyProtocolConfigBody
}

export const updatePrivacyProtocolConfig = ({ id, formData }: Params) => {
  const url = formatRequestString({
    entityUrl: `/api/privacy-configuration/privacy-protocols/${id}/config`,
  })

  return API.put<PrivacyProtocolConfigurationPutRequestBody>(url, formData)
}
