import { ApiQueryKeys } from 'api/common/queryKeys'
import { CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchDeploymentPlans } from 'api/deploymentPlans-v2/fetchers/fetchDeploymentPlans'
import { useAuth } from 'utils/hooks/useAuth'
import { createUsePaginatedQueryPreservedPage } from 'api/common/createUsePaginatedQueryPreservedPage'

const useCustomPaginatedQuery = createUsePaginatedQueryPreservedPage({
  queryKey: ApiQueryKeys.deploymentPlansPaginated,
  queryFn: fetchDeploymentPlans,
  select: res => res?.data?.deployments || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery, 'organizationCode'>

export const useDeploymentPlansPaginated = (config?: QueryConfig) => {
  const { userData } = useAuth()

  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
      organizationCode: userData.organizationCode!,
    },
  })
}
