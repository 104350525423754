import React from 'react'

import { DatabaseUpsertView } from './DatabaseUpsertView'
import { useAssetV2 } from 'api/assets/queries/useAssetV2'
import { useNavigate, useParams } from 'react-router-dom'
import { useLabelsV2OrV3BasedOnFeatureFlag } from 'api/labels/queries/useLabelsV2OrV3BasedOnFeatureFlag'
import { useUpdateAssetV2 } from 'api/assets/mutations/useUpdateAssetV2'

import { mapValuesToPayload } from './utils'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useAuth } from 'utils/hooks/useAuth'
import { RoutesManager } from 'utils/routing/routesManager'
import { getURLParams } from 'utils/routing/routes/utils'

export const DatabaseUpsertContainer: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { userData } = useAuth()

  const { code, resourceTypeCode = '' } = useParams<getURLParams>()

  const { data: database, isLoading: isLoadingAsset } = useAssetV2({
    params: {
      assetCode: code!,
      resourceTypeCode,
    },
  })

  const { data: labels, isLoading: isLoadingLabels } = useLabelsV2OrV3BasedOnFeatureFlag()

  const { mutate: handleUpdateAsset, isLoading: isUpdatingDatabase } = useUpdateAssetV2({
    onError: () => {
      showToast({ content: 'Failed to update database', type: 'error' })
    },
    onSuccess: async () => {
      await queryClient.refetchQueries([
        ApiQueryKeys.resource,
        {
          assetCode: database?.asset?.resource?.id,
          organizationCode: userData.organizationCode,
        },
      ])
      showToast({ content: 'Database updated', type: 'success' })
      navigate(
        RoutesManager.assetManager.assets.detail.root.getURL({
          code: database?.asset?.resource?.id || '',
          resourceTypeCode: database?.asset?.resource?.resourceType?.code || '',
        }),
      )
    },
  })

  const onSubmit = async (values: EditDatabaseFormValues) => {
    const formData = mapValuesToPayload({
      values,
    })

    handleUpdateAsset({
      params: {
        formData,
        assetCode: database?.asset?.resource?.id || '',
        resourceTypeCode: database?.asset?.resource?.resourceType?.code || '',
      },
    })
  }

  const isReady = !isLoadingAsset && !isLoadingLabels

  return (
    <DatabaseUpsertView
      isReady={isReady}
      onSubmit={onSubmit}
      database={database}
      labels={labels}
      isUpdatingDatabase={isUpdatingDatabase}
    />
  )
}
