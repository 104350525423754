import { createStyles, makeStyles } from '@mui/styles'

import { ReactNode } from 'react'
import { Text } from 'components/ui-kit/typography/Text'
import { InfoRow } from 'pages/policyCenter/policies/viewV2/components/OverviewTab/components'
import { Spinner } from 'components/ui-kit/spinner/Spinner'

export type ContentRow = {
  title: string
  info: ReactNode
}

type Props = {
  title: string
  subtitle: string
  // Empty rows are accepted for a cleaner dev experience when adding rows conditionally.
  contentRows: (ContentRow | false)[]
  isLoading: boolean
}

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    headerLine: {
      paddingBottom: spacing(3),
      borderBottom: `1px solid ${palette.iron.main}`,
    },
    header: {
      marginBottom: spacing(0.75),
    },
  }),
)

export const ViewModeLayoutLeftMenu: React.FC<Props> = ({ title, subtitle, contentRows, isLoading }) => {
  const classes = useStyles()

  const visibleRows = contentRows.filter(row => row) as ContentRow[]
  const lastRowIndex = visibleRows.length - 1

  return (
    <div>
      {isLoading ? (
        <Spinner padding={50} />
      ) : (
        <>
          <div className={classes.headerLine}>
            <Text component="div" className={classes.header} size={20} weight={700}>
              {title}
            </Text>
            <Text component="div" color="grey">
              {subtitle}
            </Text>
          </div>
          {visibleRows.map(({ title, info }, index) => (
            <InfoRow key={title} title={title} info={info} hasBorderBottom={index !== lastRowIndex} />
          ))}
        </>
      )}
    </div>
  )
}
