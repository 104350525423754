import { styled } from '@mui/material/styles'
import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as ExpandMoreIcon } from 'assets/icons/arrow_right.svg'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, expanded }) => ({
  // borderBottom: `1px solid ${theme.palette.iron.main}`,
  borderBottom: expanded ? 'none' : `1px solid ${theme.palette.iron.main}`,
  '&:before': {
    display: 'none',
  },
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <SvgIcon
        component={ExpandMoreIcon}
        inheritViewBox
        sx={{
          fontSize: 12,
          '& > path': {
            fill: ({ palette }) => palette.darkDusk.main,
          },
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '&.MuiButtonBase-root.MuiAccordionSummary-root': {
    padding: theme.spacing(0, 1),
  },
}))

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))
