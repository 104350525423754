import { APIListRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { AssessmentListDocumentDTO } from '@ketch-com/figurehead'

type Params = APIListRequestParams<{
  name?: string
  status?: string
  initialAssignee?: string
}>

export const fetchAssessments = ({ name, status, start, limit, initialAssignee }: Params) =>
  API.get<AssessmentListDocumentDTO>(
    formatRequestString({
      entityUrl: `/api/resources/assessments`,
      params: {
        filters: {
          'page[start]': start,
          'page[limit]': limit,
          ...(name && { 'filter[name]': name }),
          ...(status && { 'filter[status]': status }),
          ...(initialAssignee && { initialAssignee }),
        },
      },
    }),
  )
