import React, { useEffect } from 'react'
import { Box } from '@mui/material'

import { PermitsFilters } from 'pages/insights/permitsStatistics/components/PermitFilters/PermitsFilters'
import { PermitsSummary } from 'pages/insights/permitsStatistics/components/PermitsSummary'
import { AllowanceBreakdown } from 'pages/insights/permitsStatistics/components/AllowanceBreakdown'
import { Distribution } from 'pages/insights/permitsStatistics/components/Distribution'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

type Props = {}

export const PermitsStatistics: React.FC<Props> = () => {
  const { i18n } = useTranslation()
  const { language } = useParams<{ language: string }>()

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(`${language}`)
    } else {
      i18n.changeLanguage('en')
    }
  }, [i18n, language])

  return (
    <Box width="1280px" margin="0px auto">
      <PermitsFilters />

      <PermitsSummary />

      <AllowanceBreakdown />

      <Distribution />
    </Box>
  )
}
