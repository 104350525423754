import {
  ProcessingActivityAssessmentRequirementDTO,
  ProcessingActivityDataSystemConsentStatusDTO,
  ProcessingActivityProcessingFrequencyDTO,
  ProcessingActivityTypeDTO,
} from '@ketch-com/figurehead'

export const ProcessingActivityTypeLabel = {
  [ProcessingActivityTypeDTO.StandardProcessingActivityType]: 'Standard Processing',
  [ProcessingActivityTypeDTO.ModelProcessingActivityType]: 'AI / Model',
  [ProcessingActivityTypeDTO.UnspecifiedProcessingActivityType]: 'Unknown',
}

// These only display when there is no assessment object in the response
export const ProcessingActivityAssessmentRequirementLabel = {
  [ProcessingActivityAssessmentRequirementDTO.RequiredProcessingActivityAssessmentRequirement]:
    'Required (Not Assigned)',
  [ProcessingActivityAssessmentRequirementDTO.RecommendedProcessingActivityAssessmentRequirement]: 'Recommended',
  [ProcessingActivityAssessmentRequirementDTO.MaybeRequiredProcessingActivityAssessmentRequirement]: 'May Be Required',
  [ProcessingActivityAssessmentRequirementDTO.NotRequiredProcessingActivityAssessmentRequirement]: 'None',
  [ProcessingActivityAssessmentRequirementDTO.UnspecifiedProcessingActivityAssessmentRequirement]: 'Unknown',
}

export enum ProcessingActivitiesFormStep {
  BASIC_DETAILS = 'Basic Details',
  PROCESSING_DETAILS = 'Processing Details',
  DATA_SYSTEMS = 'Data Systems',
}

export const processingActivityType = [
  {
    type: ProcessingActivityTypeDTO.StandardProcessingActivityType,
    label: ProcessingActivityTypeLabel[ProcessingActivityTypeDTO.StandardProcessingActivityType],
  },
  {
    type: ProcessingActivityTypeDTO.ModelProcessingActivityType,
    label: ProcessingActivityTypeLabel[ProcessingActivityTypeDTO.ModelProcessingActivityType],
  },
]

export const processingFrequencyType = [
  {
    frequency: ProcessingActivityProcessingFrequencyDTO.OngoingProcessingActivityProcessingFrequency,
    label: 'Ongoing',
  },
  {
    frequency: ProcessingActivityProcessingFrequencyDTO.PeriodicProcessingActivityProcessingFrequency,
    label: 'Periodic',
  },
]

export const personalDataTypes = ['Not Defined', 'Personal', 'Sensitive', 'Non-Personal']

export const ProcessingActivityFrequencyLabel = {
  [ProcessingActivityProcessingFrequencyDTO.OngoingProcessingActivityProcessingFrequency]: 'Ongoing',
  [ProcessingActivityProcessingFrequencyDTO.PeriodicProcessingActivityProcessingFrequency]: 'Periodic',
  [ProcessingActivityProcessingFrequencyDTO.UnspecifiedProcessingActivityProcessingFrequency]: 'Unspecified',
}

export const ProcessingActivityConsentStatusLabel = {
  [ProcessingActivityDataSystemConsentStatusDTO.OrchestratedProcessingActivityDataSystemConsentStatus]: 'Orchestrated',
  [ProcessingActivityDataSystemConsentStatusDTO.NeedsConfirmationProcessingActivityDataSystemConsentStatus]:
    'Needs Confirmation',
  [ProcessingActivityDataSystemConsentStatusDTO.NoConnectionsProcessingActivityDataSystemConsentStatus]:
    'Needs Confirmation',
  [ProcessingActivityDataSystemConsentStatusDTO.UnspecifiedProcessingActivityDataSystemConsentStatus]: 'Unknown',
}

export const acceptableDataFlowFileTypes = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
}

export const acceptableAssessmentFileTypes = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'text/csv': ['.csv'],
}

export const acceptableModelBiasFileTypes = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'text/csv': ['.csv'],
}

// Probably naive choice of 26MB which we use in other parts of the app
export const MAX_FILE_SIZE = 26000000

export const nosql: { [key: string]: string } = {
  mongodb: 'collection',
  documentdb: 'collection',
}
