import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type UpdateRightInvocationAppealWorkflowDefinitionCodeFormData = {
  appealWorkflowDefinitionCode: string
}

type Params = {
  rightInvocationId: string
  formData: UpdateRightInvocationAppealWorkflowDefinitionCodeFormData
}

export const updateRightInvocationAppealWorkflowDefinitionCode = ({ formData, rightInvocationId }: Params) =>
  API.put<{}>(
    formatRequestString({
      entityUrl: `/api/v2/right-invocations/${rightInvocationId}/appeal-wd`,
    }),
    formData,
  )
