import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetFeatureFlags } from 'api/featureFlags/queries/useGetFeatureFlags'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

export const useFeatureFlag = () => {
  const navigate = useNavigate()
  const { data: featureFlags, isLoading: isLoadingFeatureFlags } = useGetFeatureFlags({
    staleTime: 60 * 1000 * 5, // 5 minutes
    onError: () => {
      navigate('/auth/error')
    },
  })

  const getFeatureFlagValue = useCallback((featureFlag: FEATURE_FLAGS) => featureFlags?.[featureFlag], [featureFlags])
  const isFeatureFlagEnabled = useCallback(
    (featureFlag: FEATURE_FLAGS, featureFlagValue: FEATURE_FLAGS_VALUES) =>
      featureFlags?.[featureFlag] === featureFlagValue,
    [featureFlags],
  )
  return {
    getFeatureFlagValue,
    isFeatureFlagEnabled,
    isLoadingFeatureFlags,
  }
}
