import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'
import { useField, FieldValidator } from 'formik'
import { isFunction } from 'lodash'

import { MaybeNull } from 'interfaces'
import { Hint } from 'components/ui-kit/form/common/hint/Hint'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { Input, Props as BaseInputProps } from 'components/ui-kit/input/Input'
import { Error } from 'components/ui-kit/form/common/error/Error'

const useStyles = makeStyles(
  createStyles({
    baseFormInput: {
      display: 'inline-block',
    },
    fullWidth: {
      display: 'block',
    },
    hidden: {
      opacity: 0,
    },
  }),
  { name: 'FormInput' },
)

type OmittedProps = Omit<BaseInputProps, 'name' | 'value' | 'valid'>

interface Props extends OmittedProps {
  /** Formik field name */
  name: string
  /** Text to be displayed below input */
  hint?: MaybeNull<string>
  /** Text to be displayed above input */
  label?: string | React.ReactNode
  /** Color of text to be displayed above input */
  labelColor?: 'black' | 'grey'
  /** Display Asterisk as required field */
  required?: boolean
  /** Adds margin at bottom of input such that when multiple inputs in a row and only one has error, they are aligned */
  hasErrorSpacer?: boolean
  /** Custom validation method */
  validate?: FieldValidator
  /** Toggle "Optional" display */
  shouldShowOptional?: boolean
  /** Add escape hatch for automatic Formik update */
  shouldOverrideOnChange?: boolean
  /** Prop to provide sx style overrides */
  sx?: SxProps<Theme>
  /** Override label "Optional" copy */
  optionalOverrideText?: string
  /** Override input width */
  width?: number
  /** Enable performance optimizations? */
  performant?: boolean
}

/**
 * @deprecated This component is deprecated and should not be used anymore.
 */
export const FormInput: React.FC<Props> = ({
  name,
  hint = '',
  label = '',
  labelColor = 'black',
  required = false,
  validate,
  onChange,
  fullWidth,
  hasErrorSpacer = false,
  shouldShowOptional = true,
  shouldOverrideOnChange = false,
  performant = false, // Performant mode is a test run for now, if it works well, it should become the default.
  optionalOverrideText,
  sx,
  width,
  ...baseInputProps
}) => {
  const classes = useStyles()
  const [field, meta] = useField({ name, validate })
  const [inputValue, setInputValue] = useState(field.value || '')
  const showError = meta.error && meta.touched

  const performantProps = performant ? { value: inputValue } : {}

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (performant) {
      setInputValue(e.target.value)
      setTimeout(() => onChangeInner(e), 0)
    } else {
      onChangeInner(e)
    }
  }

  const onChangeInner = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!shouldOverrideOnChange) {
      // Formik onChange
      field.onChange(e)
    }

    // Outer onChange
    if (isFunction(onChange)) {
      onChange(e)
    }
  }

  return (
    <Box sx={sx} id={name} className={clsx(classes.baseFormInput, { [classes.fullWidth]: fullWidth })}>
      {!!label && (
        <Label
          size={baseInputProps.size}
          id={baseInputProps.id}
          required={required}
          shouldShowOptional={shouldShowOptional}
          optionalOverrideText={optionalOverrideText}
          color={labelColor}
        >
          {label}
        </Label>
      )}

      <Box width={width}>
        <Input
          {...baseInputProps}
          {...field}
          valid={!showError}
          fullWidth={fullWidth}
          onChange={handleChange}
          {...performantProps}
        />
      </Box>
      {showError && <Error>{meta.error}</Error>}
      {hasErrorSpacer && !showError && <Error className={classes.hidden}>hidden</Error>}

      {hint && !showError && <Hint>{hint}</Hint>}
    </Box>
  )
}
