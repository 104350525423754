import { useContext, useState } from 'react'
import { useFormikContext } from 'formik'
import { Box, FormControlLabel, RadioGroup, Typography } from '@mui/material'

import { Assignee } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/IdentityVerification/components/IdvDetails/components/Assignee'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { styled } from '@mui/styles'
import {
  Banner,
  Chip,
  ContentGroup,
  DropListButton,
  Icon,
  InfoRow,
  ListItemText,
  ActionSheetItem,
  Radio,
  TextInput,
} from '@ketch-com/deck'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { ImportContextData } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ImportContext/context'
import { ImportContextFormData } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ImportContext/interfaces'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { FormError } from 'components/form/FormError'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'
import { StepDetailsImportContextExecutionDataDTO } from '@ketch-com/figurehead'
import { IdentityFrom } from 'pages/orchestration/workflows/edit/interfaces/IImportContextParams'
const Technology = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& img.technology': {
    height: 20,
    width: 20,
  },
}))

const Pre = styled('pre')(({ theme }) => ({
  margin: 0,
  padding: 12,
  whiteSpace: 'pre-wrap',
  border: `1px solid ${theme.palette.iron.main}`,
  borderRadius: 11,
  fontSize: 14,
  fontWeight: 400,
}))

//delete this when we have type generated from new API
interface MultipleIdentitySupportedTempStepDetailType extends StepDetailsImportContextExecutionDataDTO {
  identitiesFrom: IdentityFrom[]
}

export const ImportContextDetails: React.FC = () => {
  const navigate = useNavigate()
  const { isSubmitting, values, setFieldValue, submitForm, errors } = useFormikContext<ImportContextFormData>()
  const { stepDetails, canEdit } = useContext(ImportContextData)
  const { executionData = {}, manualInterventionStatus = 0, manualInterventionData = {} } = stepDetails
  const [isEditMode, setIsEditMode] = useState(false)

  const {
    databaseResourceId = '',
    databaseResourceName = '',
    databaseResourceTechnology = '',
    databaseResourceTypeCode = '',
    dataSubjectVariableType = 0,
    dataSubjectVariableEnum = [],
    identityType = 1,
    identityFromName = '',
    identityToCode = '',
    identityToName = '',
    query = '',
    outcome = {},
    identitiesFrom = [],
  } = (executionData?.importContext as MultipleIdentitySupportedTempStepDetailType) || {}
  const { result = [], errorMsg = '', timestamp = '' } = outcome

  const isDataSubjectVariableEnum = identityType === 2 && dataSubjectVariableType === 1
  const isDataSubjectVariableNumber = identityType === 2 && dataSubjectVariableType === 2
  const isDataSubjectVariableBoolean = identityType === 2 && dataSubjectVariableType === 3
  const isDataSubjectVariableString = identityType === 2 && dataSubjectVariableType === 4

  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isMultipleIdentitiesFlagEnabled = !isFeatureFlagEnabled(
    FEATURE_FLAGS.IMPORT_CONTEXT,
    FEATURE_FLAGS_VALUES.IMPORT_CONTEXT_SINGLE_IDENTITY,
  )

  let importedIdentityType = null
  switch (identityType) {
    case 1:
      importedIdentityType = 'Identifier'
      break
    case 2:
      importedIdentityType = 'Data Subject Variable'
      break
  }

  const manualInterventionInfoRowTitle = `Imported ${importedIdentityType} ${
    !!errors?.importContextValue ? '(Required)' : ''
  }`

  return (
    <Box display="flex" flexDirection="column" gap={6} mb={6}>
      {stepDetails?.status === WorkflowExecutionStepStatus.FAIL && errorMsg ? (
        <Banner title="An Error Occurred" isInfoBox severity="error">
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography>{errorMsg}</Typography>
            <Typography>
              Occurred time{' '}
              <Typography variant="label">
                <FormattedDateRenderer date={timestamp} />
              </Typography>
            </Typography>
          </Box>
        </Banner>
      ) : null}

      <Box display="flex" flexDirection="column" gap={4}>
        {/* Description */}
        <ContentGroup variant="inner-page-header" titleVariant="h3" title="Details" />

        <InfoRow title="Description">
          {stepDetails?.stepDescription ? stepDetails?.stepDescription : <EmptyValueRenderer />}
        </InfoRow>

        <Assignee />

        {stepDetails?.executionData?.importContext?.lastRetriedAt && (
          <InfoRow title="Last Retried At">
            <FormattedDateRenderer date={stepDetails?.executionData?.importContext?.lastRetriedAt} />
          </InfoRow>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup variant="inner-page-header" titleVariant="h3" title="Mapping" />
        <InfoRow title="Database">
          {databaseResourceId ? (
            <Box display="flex" alignItems="center">
              <Chip
                clickable
                size="medium"
                label={databaseResourceName}
                icon={
                  <Technology>
                    <img
                      className="technology"
                      src={getAssetCloudImgUrl(databaseResourceTechnology)}
                      alt={databaseResourceTechnology}
                    />
                  </Technology>
                }
                onClick={() =>
                  navigate(
                    RoutesManager.assetManager.assets.detail.overview.getURL({
                      code: databaseResourceId,
                      resourceTypeCode: databaseResourceTypeCode,
                    }),
                  )
                }
                deleteIcon={<Icon name="OArrowCRight" />}
                onDelete={() =>
                  navigate(
                    RoutesManager.assetManager.assets.detail.overview.getURL({
                      code: databaseResourceId,
                      resourceTypeCode: databaseResourceTypeCode,
                    }),
                  )
                }
              />
            </Box>
          ) : (
            <EmptyValueRenderer />
          )}
        </InfoRow>

        <InfoRow title="Context Type">{importedIdentityType}</InfoRow>

        <InfoRow title="Lookup Identifier">
          {isMultipleIdentitiesFlagEnabled ? (
            identitiesFrom.length ? (
              <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
                {identitiesFrom.map(identity => (
                  <Chip key={identity.code} label={identity.name} />
                ))}
              </Box>
            ) : (
              <EmptyValueRenderer />
            )
          ) : identityFromName ? (
            <Box display="flex" alignItems="center">
              <Chip label={identityFromName} />
            </Box>
          ) : (
            <EmptyValueRenderer />
          )}
        </InfoRow>

        <InfoRow title={`Imported ${importedIdentityType}`}>
          {identityToName ? (
            <Box display="flex" alignItems="center">
              <Chip label={identityToName} />
            </Box>
          ) : (
            <EmptyValueRenderer />
          )}
        </InfoRow>

        <InfoRow title="SQL Query">{query ? <Pre>{query}</Pre> : <EmptyValueRenderer />}</InfoRow>
      </Box>
      {!manualInterventionStatus && stepDetails?.status === WorkflowExecutionStepStatus.SUCCESS ? (
        <Box display="flex" flexDirection="column" gap={4}>
          <ContentGroup variant="inner-page-header" titleVariant="h3" title="Outcome" />
          <InfoRow title={`Imported ${importedIdentityType}`}>
            {identityToName ? (
              <Box display="flex" alignItems="center" gap={0.5}>
                <Chip
                  clickable
                  label={identityToName}
                  onClick={() =>
                    navigate(
                      RoutesManager.settings.identitySpaces.view.overview.root.getURL({
                        code: identityToCode,
                      }),
                    )
                  }
                  onDelete={() =>
                    navigate(
                      RoutesManager.settings.identitySpaces.view.overview.root.getURL({
                        code: identityToCode,
                      }),
                    )
                  }
                  deleteIcon={<Icon name="OArrowCRight" />}
                />
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="body">Value:</Typography>
                  <Typography variant="label">{result?.[0] || ''}</Typography>
                </Box>
              </Box>
            ) : (
              <EmptyValueRenderer />
            )}
          </InfoRow>
        </Box>
      ) : null}

      {manualInterventionStatus ? (
        <Box display="flex" flexDirection="column" gap={4}>
          <ContentGroup variant="inner-page-header" titleVariant="h3" title="Manual Intervention" />
          {stepDetails?.status === WorkflowExecutionStepStatus.SUCCESS ? (
            <Box>
              <InfoRow title={`Imported ${importedIdentityType}`}>
                {identityToName ? (
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <Chip
                      clickable
                      label={identityToName}
                      onClick={() =>
                        navigate(
                          RoutesManager.settings.identitySpaces.view.overview.root.getURL({
                            code: identityToCode,
                          }),
                        )
                      }
                      onDelete={() =>
                        navigate(
                          RoutesManager.settings.identitySpaces.view.overview.root.getURL({
                            code: identityToCode,
                          }),
                        )
                      }
                      deleteIcon={<Icon name="OArrowCRight" />}
                    />
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography variant="body">Value:</Typography>
                      <Typography variant="label">{manualInterventionData?.importContextValue || ''}</Typography>
                    </Box>
                  </Box>
                ) : (
                  <EmptyValueRenderer />
                )}
              </InfoRow>
            </Box>
          ) : null}

          {stepDetails?.status !== WorkflowExecutionStepStatus.SUCCESS && (
            <>
              <Typography variant="body" color="darkDuskFaded.main">
                To resolve this activity through Manual Intervention, please complete the fields below.
              </Typography>
              <InfoRow
                title={manualInterventionInfoRowTitle}
                isValidation={!!errors?.importContextValue}
                isEditable={canEdit}
                isEditing={isEditMode}
                isLoading={isSubmitting}
                onEditChange={() => {
                  setIsEditMode(true)
                }}
                onAcceptChange={async () => {
                  setIsEditMode(false)
                  await submitForm()
                }}
                onCancelChange={() => {
                  setIsEditMode(false)
                  setFieldValue('importContextValue', manualInterventionData?.importContextValue || '')
                }}
                isEmpty={!values?.importContextValue}
              >
                {isEditMode ? (
                  <Box>
                    {isDataSubjectVariableEnum && (
                      <DropListButton
                        sx={{
                          width: '300px',
                        }}
                        // this is hacky, but if we want a controlled component, which can also accept null as a value, and to also have the disableClearable prop
                        // we need to do smth like this, see https://github.com/mui/material-ui/issues/33661 for more details
                        disableClearable={values?.importContextValue !== null}
                        isOptionEqualToValue={(option, value) => option === value}
                        getOptionLabel={option => option}
                        renderOption={(props, option, { selected }) => (
                          <ActionSheetItem selected={selected} {...props}>
                            <ListItemText selected={selected}>{option}</ListItemText>
                          </ActionSheetItem>
                        )}
                        options={dataSubjectVariableEnum}
                        onChange={(_, value) => setFieldValue('importContextValue', value)}
                        value={values?.importContextValue}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                    {isDataSubjectVariableBoolean && (
                      <RadioGroup
                        value={values?.importContextValue}
                        row
                        onChange={(e, value) => setFieldValue('importContextValue', value)}
                      >
                        {[
                          { title: 'True', value: 'true' },
                          { title: 'False', value: 'false' },
                        ].map(option => (
                          <FormControlLabel key="value" value={option.value} control={<Radio />} label={option.title} />
                        ))}
                      </RadioGroup>
                    )}
                    {isDataSubjectVariableNumber && (
                      <Box display="flex" width="100%" flexDirection="column">
                        <TextInput
                          type="number"
                          sx={{
                            '& .MuiFormHelperText-root': {
                              color: theme => `${theme.palette.chileanFire.main} !important`,
                            },
                          }}
                          autoFocus
                          defaultValue={values?.importContextValue}
                          name="importContextValue"
                          fullWidth
                          onChange={e => {
                            if (e?.target?.value?.match(/[^0-9]/)) {
                              return e?.preventDefault()
                            }
                            setFieldValue('importContextValue', e.currentTarget.value)
                          }}
                          placeholder="Value"
                          required={!values?.importContextValue}
                          value={values?.importContextValue}
                          error={!!errors?.importContextValue}
                          variant="ghost"
                        />
                        {!!errors?.importContextValue && <FormError msg={errors?.importContextValue} />}
                      </Box>
                    )}
                    {(isDataSubjectVariableString || identityType === 1) && (
                      <Box display="flex" width="100%" flexDirection="column">
                        <TextInput
                          sx={{
                            '& .MuiFormHelperText-root': {
                              color: theme => `${theme.palette.chileanFire.main} !important`,
                            },
                          }}
                          autoFocus
                          defaultValue={values?.importContextValue}
                          name="importContextValue"
                          fullWidth
                          onChange={e => setFieldValue('importContextValue', e.currentTarget.value)}
                          placeholder="Value"
                          required={!values?.importContextValue}
                          value={values?.importContextValue}
                          error={!!errors?.importContextValue}
                          variant="ghost"
                        />
                        {!!errors?.importContextValue && <FormError msg={errors?.importContextValue} />}
                      </Box>
                    )}
                  </Box>
                ) : (
                  values?.importContextValue || <EmptyValueRenderer />
                )}
              </InfoRow>
            </>
          )}
        </Box>
      ) : null}
    </Box>
  )
}
