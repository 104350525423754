import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

// mui components
import Menu from '@mui/material/Menu'
import ListItemIcon from '@mui/material/ListItemIcon'
import ClearIcon from '@mui/icons-material/Clear'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import ScienceIcon from '@mui/icons-material/Science'
import FlagIcon from '@mui/icons-material/Flag'
import Typography from '@mui/material/Typography'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import PestControlIcon from '@mui/icons-material/PestControl'
import { Divider } from '@mui/material'

// utils
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { setRelease, RELEASES, clearRelease, getRelease } from 'utils/releases'
import { RoutesManager } from 'utils/routing/routesManager'

// types
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useAppNavigationUtils } from 'components/appLayout/appNavigation/utils'

export const ReleaseSelector = () => {
  const [, setSearchParams] = useSearchParams()
  const { isEntitled } = useIsEntitled()

  // Initialize app navigation utils
  useAppNavigationUtils()

  const isEntitledToDemo = isEntitled(ENTITLEMENTS.DEMO)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const currentRelease = getRelease()

  const handleMenuButtonOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  // if no releases or uses not entitled to demo, return null
  if (!RELEASES || !isEntitledToDemo) return null

  return (
    <>
      <span>
        <Box
          display="flex"
          alignItems="center"
          id="release-menu-button"
          onClick={handleMenuButtonOnClick}
          sx={{
            cursor: 'pointer',
            p: 1,
            ml: 0.5,
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.1)',
            },
          }}
        />
      </span>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          id="clear-release"
          key="clear-release"
          onClick={() => {
            clearRelease()
            handleCloseMenu()
          }}
        >
          <ListItemIcon>
            <ClearIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="body">Clear Feature Flags</Typography>
        </MenuItem>

        {RELEASES.map(release => {
          const isReleaseActive = currentRelease === release
          return (
            <MenuItem
              test-target={release}
              id={`release-${release}`}
              key={release}
              onClick={() => {
                setRelease(release)
                handleCloseMenu()
              }}
            >
              <ListItemIcon>
                <FlagIcon
                  fontSize="medium"
                  sx={{
                    color: isReleaseActive ? 'apple.main' : 'darkDuskFaded.main',
                  }}
                />
              </ListItemIcon>
              <Typography variant={isReleaseActive ? 'label' : 'body'}>{release}</Typography>
            </MenuItem>
          )
        })}

        <Divider />

        {/* Dev Actions */}

        <MenuItem
          key="clearSearchParams"
          id="clearSearchParams"
          onClick={() => {
            setSearchParams({})
            handleCloseMenu()
          }}
        >
          <ListItemIcon>
            <SearchOffIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="body">Clear Search Params</Typography>
        </MenuItem>

        <MenuItem
          key="setDebugParam"
          id="setDebugParam"
          onClick={() => {
            setSearchParams({
              debug: 'log',
            })
            handleCloseMenu()
          }}
        >
          <ListItemIcon>
            <PestControlIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="body">Set Debug</Typography>
        </MenuItem>

        <Divider />

        {/* Developer Routes */}

        <MenuItem
          key="sandbox"
          id="sandbox"
          onClick={() => {
            navigate(RoutesManager.developmentUtils.sandbox.getURL())
            handleCloseMenu()
          }}
        >
          <ListItemIcon>
            <ScienceIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="body">Sandbox</Typography>
        </MenuItem>

        <MenuItem
          key="ketchTagDebugger"
          id="ketchTagDebugger"
          onClick={() => {
            navigate(RoutesManager.developmentUtils.ketchTagDebugUrlBuilder.getURL())
            handleCloseMenu()
          }}
        >
          <ListItemIcon>
            <LocalOfferIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="body">Tag Debug URL Builder</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}
