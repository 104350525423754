import React from 'react'
import { Box } from '@mui/material'
import { ProcessingActivityAssetDTO, ProcessingActivityDataSystemDTO } from '@ketch-com/figurehead'
import { Button, PopUp, LabelChip, LabelChipVariant } from '@ketch-com/deck'

type Props = {
  dataCategoriesObject: ProcessingActivityDataSystemDTO | ProcessingActivityAssetDTO
  onCancel: () => void
}

export const PersonalDataTypesModal: React.FC<Props> = ({ dataCategoriesObject, onCancel }) => {
  // Determine name based on object type
  let name: string = ''
  if ('dataSystem' in dataCategoriesObject) {
    name = dataCategoriesObject.dataSystem?.name || ''
  } else if ('asset' in dataCategoriesObject) {
    name = dataCategoriesObject.asset?.asset?.resource?.name || ''
  }

  return (
    <PopUp
      isOpen={true}
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Close
          </Button>
        </>
      }
      variant="dialog"
      title={`${name} Data Categories`}
    >
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
        {dataCategoriesObject?.dataCategories?.map((dataCategory, index) => (
          <LabelChip isViewOnly key={index} variant={LabelChipVariant.category} title={dataCategory.code ?? ''} />
        ))}
      </Box>
    </PopUp>
  )
}
