import { FC } from 'react'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { getApplicationEntityName } from 'utils/messages/entityName'
import { Button, PopUp } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  entityType: ApplicationEntity
  onSubmit: () => void
  onCancel: () => void
}

export const getBodyByEntityType = ({ entityType }: Pick<Props, 'entityType'>) => {
  switch (entityType) {
    case ApplicationEntity.COOKIE:
      return 'This cookie is currently mapped. Any changes will take effect immediately, are you sure you want to continue?'

    case ApplicationEntity.APPLICATION:
      return 'This action will overwrite deployment plan assignments for selected environments, are you sure you want to continue?'

    case ApplicationEntity.EXPERIENCE:
      return 'This experience is currently deployed. Any changes will take effect immediately, are you sure you want to continue?'

    case ApplicationEntity.DEPLOYMENT_PLAN:
      return 'This plan is currently deployed. Any changes will take effect immediately, are you sure you want to continue?'

    case ApplicationEntity.POLICY_DOCUMENTS:
      return 'This document is currently deployed. Any changes will take effect immediately, are you sure you want to continue?'

    case ApplicationEntity.WORKFLOW:
      return 'This workflow is currently enabled. Any changes will take effect immediately, are you sure you want to continue?'

    case ApplicationEntity.PURPOSE:
      return 'This purpose is currently enabled. Any changes will take effect immediately, are you sure you want to continue?'

    case ApplicationEntity.THEME:
      return 'This theme is currently deployed. Any changes will take effect immediately, are you sure you want to continue?'

    default:
      return null
  }
}

export const EditConfirmationModal: FC<Props> = ({ entityType, onSubmit, onCancel }) => {
  const entityName = getApplicationEntityName({ entityType })

  return (
    <PopUp
      isOpen
      onClose={onCancel}
      variant="dialog"
      title={`Edit ${entityName.singular}`}
      popUpActionChildren={
        <>
          <Button
            color="secondary"
            size="large"
            onClick={e => {
              e.stopPropagation() // Stop click from triggering other handlers under the dialog
              onCancel()
            }}
          >
            Close
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={e => {
              e.stopPropagation() // Stop click from triggering other handlers under the dialog
              onSubmit()
            }}
          >
            Edit
          </Button>
        </>
      }
    >
      <Typography>{getBodyByEntityType({ entityType })}</Typography>
    </PopUp>
  )
}
