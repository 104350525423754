import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { FormTemplateDTO, FormTemplateExperienceDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { Link } from 'react-router-dom'
import { getFormTemplateDeployedExperiences } from '../upsert/utils'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { DataGrid, TableCell, Button } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

type DeleteFormTemplateModalBodyProps = {
  formTemplate?: FormTemplateDTO
  isFormTemplateAssociatedWithDeployedExperiences?: boolean
}

export const DeleteFormTemplateModalBody: React.FC<DeleteFormTemplateModalBodyProps> = ({
  formTemplate,
  isFormTemplateAssociatedWithDeployedExperiences,
}) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isExpV1 = isFeatureFlagEnabled(
    FEATURE_FLAGS.EXPERIENCE_SERVER_VERSION,
    FEATURE_FLAGS_VALUES.EXPERIENCE_SERVER_VERSION_1,
  )

  return isFormTemplateAssociatedWithDeployedExperiences ? (
    <>
      <Typography variant="body" mb={2} component={Box}>
        To delete the <Typography variant="label">{formTemplate?.name}</Typography> form, you must remove it from any
        deployed experiences or replace it with another form. This form is used in the following deployed experiences:
      </Typography>

      <DataGrid
        autosizeOnMount
        autosizeOptions={{
          includeHeaders: true,
          includeOutliers: false,
          expand: true,
        }}
        getRowHeight={() => 'auto'}
        columns={[
          {
            align: 'left',
            field: 'name',
            flex: 1,
            headerAlign: 'left',
            headerName: 'Experience',
            sortable: false,
            renderCell: ({ row }: GridRenderCellParams<FormTemplateExperienceDTO>) => {
              return <TableCell title={row.name} subTitle={row.code} />
            },
          },
          {
            align: 'right',
            field: 'actions',
            flex: 1,
            headerAlign: 'left',
            headerName: ' ',
            sortable: false,
            renderCell: ({ row: experience }: GridRenderCellParams<FormTemplateExperienceDTO>) => {
              const targetUrl = isExpV1
                ? RoutesManager.deployment.experiences.view.root.getURL({
                    code: experience.code,
                  })
                : RoutesManager.deployment.experiencesV2.view.preview.root.getURL({
                    id: experience?.id,
                  })

              return (
                <TableCell>
                  <Link to={targetUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Button color="tertiary" size="medium">
                      Open In New Tab
                    </Button>
                  </Link>
                </TableCell>
              )
            },
          },
        ]}
        disableChildrenSorting
        disableColumnMenu
        disableColumnPinning
        disableColumnResize
        disableColumnReorder
        disableRowSelectionOnClick
        disableRowHoverStates
        getRowId={row => row.code}
        rows={getFormTemplateDeployedExperiences(formTemplate)}
        hideFooter
      />
    </>
  ) : (
    <Typography variant="body" mb={2} component={Box}>
      Are you sure you want to delete the <Typography variant="label">{formTemplate?.name}</Typography> form?
    </Typography>
  )
}
