import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { RelationshipMapping } from 'pages/assetManager/relationshipMapping/RelationshipMapping'
import { useRelationshipMappingInfinite } from 'api/assets/queries/useRelationshipMappingInfinite'
import { MaybeNull } from 'interfaces'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useRelationshipMapping } from 'pages/assetManager/relationshipMapping/hooks'
import { RELATIONSHIP_TYPE } from 'pages/assetManager/relationshipMapping/constants'
import { useCreateRelationshipMapping } from 'api/assets/mutations/useCreateRelationshipMapping'
import { useDeleteRelationshipMapping } from 'api/assets/mutations/useDeleteRelationshipMapping'
import { CreateRelationdhipFormData } from 'pages/assetManager/relationshipMapping/interfaces'
import { GetRelationship } from 'pages/assetManager/interfaces'

export const RelationshipMappingContainer = () => {
  const queryClient = useQueryClient()
  const {
    relationshipSearch,
    setRelationshipSearch,
    assetType,
    setAssetType,
    relationshipType,
    setRelationshipType,
    isCreateRelationshipMappingModalOpen,
    setIsCreateRelationshipMappingModalOpen,
    deleteRelationship,
    setDeleteRelationship,
    searchString,
    setSearchString,
    isHowItWorksModalOpen,
    setIsHowItWorksModalOpen,
  } = useRelationshipMapping()

  useEffect(
    () => () => {
      queryClient.removeQueries({ queryKey: ApiQueryKeys.relationshipMapping, exact: true })
    },
    [relationshipType, queryClient],
  )
  const {
    relationships,
    isLoading: isLoadingRelationships,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useRelationshipMappingInfinite({
    params: {
      discovered: relationshipType === RELATIONSHIP_TYPE.DISCOVERED,
      limit: 20,
      query: relationshipSearch,
      assetType,
    },
  })

  const { mutate: createRelationshipMapping } = useCreateRelationshipMapping({
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(ApiQueryKeys.relationshipMapping)
      showToast({ content: 'Created Relationship', type: 'success' })
      setIsCreateRelationshipMappingModalOpen(false)
    },
    onError: () => {
      showToast({ content: 'Unable to create relationship', type: 'error' })
      setIsCreateRelationshipMappingModalOpen(false)
    },
  })

  const onCreateRelationshipMappingFormSubmit = (formData: CreateRelationdhipFormData) => {
    const { primaryColumnResourceId, foriegnColumnResourceId } = formData
    createRelationshipMapping({
      params: {
        data: {
          primaryKeyColumnId: [primaryColumnResourceId],
          foreignKeyColumnId: [foriegnColumnResourceId],
        },
      },
    })
  }

  const { mutate: deleteRelationshipMapping } = useDeleteRelationshipMapping({
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(ApiQueryKeys.relationshipMapping)
      showToast({ content: 'Deleted Relationship', type: 'success' })
      setDeleteRelationship(null)
    },
    onError: () => {
      showToast({ content: 'Unable to delete relationship', type: 'error' })
      setDeleteRelationship(null)
    },
  })

  const onDeleteRelationshipMappingSubmit = (relationship: MaybeNull<GetRelationship>) => {
    if (relationship) {
      deleteRelationshipMapping({
        params: {
          relationshipId: relationship?.id || '',
        },
      })
    }
  }

  const isReady = !isLoadingRelationships

  return (
    <RelationshipMapping
      isReady={isReady}
      relationships={relationships || []}
      searchString={searchString}
      setSearchString={setSearchString}
      relationshipSearch={relationshipSearch}
      setRelationshipSearch={setRelationshipSearch}
      relationshipType={relationshipType}
      setRelationshipType={setRelationshipType}
      assetType={assetType}
      setAssetType={setAssetType}
      isCreateRelationshipMappingModalOpen={isCreateRelationshipMappingModalOpen}
      setIsCreateRelationshipMappingModalOpen={setIsCreateRelationshipMappingModalOpen}
      deleteRelationship={deleteRelationship}
      setDeleteRelationship={setDeleteRelationship}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      onCreateRelationshipMappingFormSubmit={onCreateRelationshipMappingFormSubmit}
      onDeleteRelationshipMappingSubmit={onDeleteRelationshipMappingSubmit}
      isHowItWorksModalOpen={isHowItWorksModalOpen}
      setIsHowItWorksModalOpen={setIsHowItWorksModalOpen}
    />
  )
}
