import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { fetchDatabaseDatasetSummariesPaginatedV2 } from '../fetchers/fetchDatabaseDatasetSummariesPaginatedV2'
import { APIListRequestParams } from 'api/common/utils'
import { ListTableAssetSummariesForDatabaseResponseBodyDTO, AssetSummaryDTO } from '@ketch-com/figurehead'

type LastPage = ListTableAssetSummariesForDatabaseResponseBodyDTO

type Params = APIListRequestParams<{
  query?: string | null | undefined
  itemsPerPage?: number
  assetId?: string
  identityRelationType?: number
  isPersonal?: boolean
  scenarioId?: string
}>

type Options = {
  enabled?: boolean
}

export const useDatabaseDatasetSummariesInfiniteV2 = ({ options, params }: { options?: Options; params: Params }) => {
  const { itemsPerPage: limit = 20 } = params

  const resp = useInfiniteQuery({
    enabled: !!options?.enabled,
    queryKey: [
      ApiQueryKeys.databaseDatasetSummariesPaginatedV2,
      {
        includeMetadata: true,
        ...params,
      },
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchDatasetsResp = await fetchDatabaseDatasetSummariesPaginatedV2({
        limit,
        start: (pageParam - 1) * limit,
        assetId: params.assetId,
        ...params,
      })

      const { data } = fetchDatasetsResp

      data.hierarchySummaries = data.hierarchySummaries ?? []
      data.totalResults = data.totalResults ?? 0

      return data
    },
    getNextPageParam: ({ totalResults }: LastPage, allPages: LastPage[]) => {
      let totalAssetsFetchedSoFar = 0
      allPages?.forEach?.(p => {
        totalAssetsFetchedSoFar += p?.hierarchySummaries?.length || 0
      })
      const nextPageParam = Math.ceil(totalAssetsFetchedSoFar / limit) + 1
      const getNextPageParamReturnValue = totalAssetsFetchedSoFar === totalResults ? undefined : nextPageParam
      return getNextPageParamReturnValue
    },
  })

  let infiniteDatasets: AssetSummaryDTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    infiniteDatasets = [...infiniteDatasets, ...(page?.hierarchySummaries || [])]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: infiniteDatasets,
    totalResults,
  }
}
