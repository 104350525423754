import React from 'react'
import Box from '@mui/material/Box'

import { makeStyles, createStyles } from '@mui/styles'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { v4 as uuidv4 } from 'uuid'

type Props = {
  resources: any[]
  hasAttributeEffects: boolean
}

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      checkIcon: {
        fontSize: typography.pxToRem(20),
        color: palette.darkChrome.main,
      },
      closeIcon: {
        fontSize: typography.pxToRem(20),
        color: palette.lightGrey.main,
      },
    }),
  { name: 'AttributeEffectsCellRenderer' },
)

export const AttributeEffectsCellRenderer: React.FC<Props> = ({ resources, hasAttributeEffects }) => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column">
      {resources.map((r: any, index) => (
        <Box
          style={{ opacity: index === 0 ? 1 : 0 }}
          key={uuidv4()}
          mr={1.25}
          mb={index !== resources.length - 1 ? 3.25 : 1.75}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {hasAttributeEffects && <CheckIcon className={classes.closeIcon} />}
          {!hasAttributeEffects && <CloseIcon className={classes.closeIcon} />}
        </Box>
      ))}
    </Box>
  )
}
