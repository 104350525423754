import * as Yup from 'yup'

import { getMaxCharsErrorMessage, validationMessages } from 'utils/constants/forms/validationMessages'
import { MAX_CODE_LENGTH, MAX_HINT_LENGTH, MAX_TITLE_LENGTH } from '../../constants/validationCharLengths'

const { REQUIRED } = validationMessages

export const translationsValidation = Yup.object().shape({
  translations: Yup.array()
    .of(
      Yup.object().shape({
        code: Yup.string().required(REQUIRED).max(MAX_CODE_LENGTH, getMaxCharsErrorMessage(MAX_CODE_LENGTH)),
        title: Yup.string().required(REQUIRED).max(MAX_TITLE_LENGTH, getMaxCharsErrorMessage(MAX_TITLE_LENGTH)),
        fieldTitleTranslation: Yup.string().max(MAX_TITLE_LENGTH, getMaxCharsErrorMessage(MAX_TITLE_LENGTH)),
        uiHintTranslation: Yup.string().max(MAX_HINT_LENGTH, getMaxCharsErrorMessage(MAX_HINT_LENGTH)),
      }),
    )
    .required(REQUIRED),
})
