import React from 'react'

import { UserDTO } from 'interfaces/users/users'
import { Button, DataGrid, Icon, TableCell } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { TempAssignmentType } from 'api/users/fetchers/fetchWorkflowAssignments'
import { useInfiniteAssessments } from 'api/assessments/queries/useInfiniteAssessments'
import { AssessmentDTO } from '@ketch-com/figurehead'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'

type Props = {
  user: UserDTO
}

export const UsersViewAssessments: React.FC<Props> = ({ user }) => {
  const { data, isLoading } = useInfiniteAssessments({ initialAssignee: user.ID })
  const navigate = useNavigate()

  return (
    <Box>
      <Typography variant="h3" my={3}>
        Assessments
      </Typography>
      <DataGrid
        loading={isLoading}
        rows={data || []}
        disableColumnResize
        disableColumnFilter
        disableColumnReorder
        disableColumnPinning
        disableAggregation
        disableRowGrouping
        hideFooterRowCount
        disableColumnMenu
        disableRowHoverStates
        noRowsOverlayProps={{
          subTitle: 'No Workflow Activities are assigned to this user.',
          buttonTitle: '',
        }}
        columns={[
          {
            align: 'left',
            field: 'name',
            headerAlign: 'left',
            headerName: 'Name',
            flex: 1,
            renderCell: ({ row }: GridRenderCellParams<AssessmentDTO>) => {
              return (
                <TableCell>
                  <NameAndCodeCellRenderer
                    name={row.attributes?.name}
                    code={<FormattedDateRenderer date={row.meta?.createdAt} />}
                  />
                </TableCell>
              )
            },
          },
          {
            align: 'left',
            field: 'assignedQuestions',
            headerAlign: 'left',
            headerName: 'Assigned Questions',
            flex: 1,
            renderCell: ({ row }: GridRenderCellParams<AssessmentDTO>) => {
              return <TableCell>{/* <Chip label={pluralize(row.attributes.)} /> */}</TableCell>
            },
          },
          {
            align: 'left',
            field: 'view',
            headerAlign: 'left',
            headerName: '',
            flex: 1,
            renderCell: ({ row }: GridRenderCellParams<TempAssignmentType>) => {
              return (
                <TableCell>
                  <Button
                    color="tertiary"
                    onClick={() =>
                      navigate(RoutesManager.orchestration.workflows.upsert.root.getURL({ code: row.code }))
                    }
                  >
                    <Box component="span" mr={0.5}>
                      View
                    </Box>
                    <Icon width={20} height={20} name="OShare" />
                  </Button>
                </TableCell>
              )
            },
          },
        ]}
      />
    </Box>
  )
}
