import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from '../../common/paginatedQuery'
import { ApiQueryKeys } from '../../common/queryKeys'
import { fetchAssetProcessingActivities } from '../fetchers/fetchAssetProcessingActivities'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.assetProcessingActivities,
  queryFn: fetchAssetProcessingActivities,
  select: res => res?.data || {},
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useAssetProcessingActivities = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
