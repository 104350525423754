import { Fragment, useContext } from 'react'
import { Grid } from '@mui/material'
import { Spinner } from '@ketch-com/deck'
import Box from '@mui/material/Box'
import { DataMapContext } from '../../context/DataMapContext'
import { DataMapListTile } from './DataMapListTile'
import { InfiniteScrollLoadingIndicator } from 'components/InfiniteScrollLoadingIndicator'
import { RoutesManager } from 'utils/routing/routesManager'
import { Waypoint } from 'react-waypoint'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

export const PurposesTab: React.FC = () => {
  const { navigate, purposes, hasNextPage, isFetchingNextPage, fetchNextPage, isPurposesLoading } =
    useContext(DataMapContext)

  const breadcrumbs = [
    { title: 'Reports', link: RoutesManager.insights.root.getURL() },
    { title: 'ROPA', link: RoutesManager.insights.dataMap.root.getURL() },
    { title: 'Purposes' },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Grid>
        {isPurposesLoading ? (
          <Box
            sx={{
              margin: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner size="32px" thickness={2.5} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap={3}>
            {purposes &&
              (purposes || []).map((purpose, index) => {
                return (
                  <Fragment key={`${purpose.code}-${index}`}>
                    <DataMapListTile
                      title={purpose.name || ''}
                      subtitle={purpose.description || ''}
                      onClick={() =>
                        navigate(RoutesManager.insights.dataMap.view.overview.root.getURL({ code: purpose.code }))
                      }
                      details={[
                        {
                          title: purpose.dataSystemsCount?.toString() || '0',
                          body: 'Data Systems',
                        },
                        {
                          title: purpose.dataCategories?.length.toString() || '0',
                          body: 'Data Categories',
                        },
                        {
                          title: purpose.teamIDs?.length.toString() || '0',
                          body: 'Teams',
                        },
                        {
                          title: purpose.documents?.length.toString() || '0',
                          body: 'Documents',
                        },
                      ]}
                    />
                    {(purposes?.length || 0) - 2 === index ? (
                      <Waypoint
                        onEnter={() => {
                          if (!isFetchingNextPage && hasNextPage) fetchNextPage()
                        }}
                      />
                    ) : null}
                  </Fragment>
                )
              })}
            <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
          </Box>
        )}
      </Grid>
    </>
  )
}
