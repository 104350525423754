import { Chip, Icon, Status, StatusState, StatusVariant } from '@ketch-com/deck'
import { SubscriptionControlOrchestrationDTO } from '@ketch-com/figurehead'
import { Box } from '@mui/material'
import { GridValidRowModel } from '@mui/x-data-grid'
import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'

export interface SubscriptionControlOrchestrationRowType extends GridValidRowModel {
  id: string
  subscriptionControlOrchestration: SubscriptionControlOrchestrationDTO
}

export const SubscriptionControlOrchestrationsInfoRowContent = ({
  controlOrchestration,
}: {
  controlOrchestration: SubscriptionControlOrchestrationDTO
}) => {
  const navigate = useNavigate()
  const handleOnAppChipClickHandler = (appId?: string) => {
    const targetUrl = RoutesManager.systems.id.root.getURL({
      id: appId,
    })
    navigate(targetUrl)
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" width="100%">
      {controlOrchestration?.app ? (
        <Box display="flex" alignItems="center" width="100%" gap={3}>
          <Chip
            clickable
            label={
              controlOrchestration?.app?.appName
                ? controlOrchestration?.app?.appName.charAt(0).toUpperCase() +
                  controlOrchestration?.app?.appName.slice(1)
                : ''
            }
            icon={
              <img
                src={controlOrchestration?.app?.logoUrl}
                alt={controlOrchestration?.app?.appName}
                width={24}
                height={24}
              />
            }
            deleteIcon={<Icon name="OArrowCRight" />}
            onDelete={() => {
              handleOnAppChipClickHandler(controlOrchestration?.app?.appId)
            }}
            onClick={() => {
              handleOnAppChipClickHandler(controlOrchestration?.app?.appId)
            }}
          />
          <Status
            label={controlOrchestration?.app?.connectionName || ''}
            variant={StatusVariant.filled}
            status={StatusState.success}
            icon="OConnectionTrue"
          />
        </Box>
      ) : (
        <Box>
          <Chip
            clickable
            label={controlOrchestration?.webhook?.name}
            icon={<Icon name="FApi" />}
            deleteIcon={<Icon name="OArrowCRight" />}
            onDelete={() => {
              navigate(RoutesManager.developers.webhooks.root.getURL())
            }}
            onClick={() => {
              navigate(RoutesManager.developers.webhooks.root.getURL())
            }}
          />
        </Box>
      )}
    </Box>
  )
}
