import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useRights } from 'api/rights/queries/useRights'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { useInvokeRight } from 'api/rightInvocations/mutations/useInvokeRight'
import { useAuth } from 'utils/hooks/useAuth'
import { RoutesManager } from 'utils/routing/routesManager'
import { CreateRightInvocation } from 'pages/orchestration/rightsQueue/create/CreateRightInvocation'
import { showToast } from 'components/modals/AlertComponent'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { mapCreateRightInvocationValuesToPayload } from './utils'
import { RightInvocationFormValues } from 'interfaces/rightInvocations'
import { useDataSubjectTypes } from 'api/dataSubjectTypes/queries/useDataSubjectTypes'
import { useFormFields } from 'api/formFields/queries/useFormFields'

export const CreateRightInvocationContainer: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { userData } = useAuth()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { data: rights, isLoading: isRightsLoading } = useRights()
  const { data: identitySpaces, isLoading: isIdentitySpacesLoading } = useIdentitySpaces()
  const { data: policyScopes, isLoading: isPolicyScopesLoading } = usePolicyScopes()

  const { mutateAsync: handleInvokeRight } = useInvokeRight()

  const onSubmit = async (values: RightInvocationFormValues) => {
    /* make sure executeRecaptcha is initiated before calling */
    if (!executeRecaptcha) {
      return
    }

    let recaptchaToken = null
    try {
      recaptchaToken = await executeRecaptcha('invokeRight')
    } catch (error) {
      recaptchaToken = ''
    }

    try {
      const formData = mapCreateRightInvocationValuesToPayload({
        values,
        organizationCode: userData.organizationCode!,
        recaptchaToken,
      })

      await handleInvokeRight({
        params: {
          formData,
        },
      })

      await queryClient.refetchQueries(ApiQueryKeys.rightInvocationsPaginated)

      showToast({ content: 'Right was invoked', type: 'success' })
      navigate(RoutesManager.orchestration.rightsQueue.root.getURL())
    } catch (e) {
      showToast({ content: 'Failed to invoke right', type: 'error' })
    }
  }

  const { data: dataSubjectTypes, isLoading: isLoadingDataSubjectTypes } = useDataSubjectTypes({
    itemsPerPage: 1000,
    params: {},
  })

  const { isLoading: isLoadingFormFields } = useFormFields({
    itemsPerPage: 1000,
    params: {
      includeMetadata: true,
    },
  })

  const isReady =
    !isRightsLoading &&
    !isIdentitySpacesLoading &&
    !isPolicyScopesLoading &&
    !isLoadingDataSubjectTypes &&
    !isLoadingFormFields

  return (
    <CreateRightInvocation
      isReady={isReady}
      rights={rights}
      identitySpaces={identitySpaces}
      policyScopes={policyScopes}
      onSubmit={onSubmit}
      dataSubjectTypes={dataSubjectTypes}
    />
  )
}
