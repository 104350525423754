import { StepDetailsDTO } from '@ketch-com/figurehead'

function parseVariableValue(ctxVarValue?: string | number | boolean): any {
  if (typeof ctxVarValue === 'string') {
    return ctxVarValue
  } else if (typeof ctxVarValue === 'boolean') {
    return ctxVarValue ? 'true' : 'false'
  } else if (typeof ctxVarValue === 'number') {
    return ctxVarValue.toString()
  } else {
    return ''
  }
}

export const getDataSubjectVariablesInitialValues = (stepDetails?: StepDetailsDTO) => {
  const dataSubjectContextInitialValues = stepDetails?.dataSubjectContext?.map(dataSubjectVariable => {
    return {
      ...dataSubjectVariable,
      value: parseVariableValue(dataSubjectVariable.value),
    }
  })

  const initialValues = { dataSubjectContext: dataSubjectContextInitialValues }

  return initialValues
}
