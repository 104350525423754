import { LabelDefinitionDTO as LabelDTO } from '@ketch-com/figurehead'
import { MegaFilterLabelOption } from 'interfaces/labels/MegaFilterLabelOption'

export const getTransformLabels = (labelOptions: LabelDTO[]): MegaFilterLabelOption[] =>
  labelOptions
    .filter(label => !label?.isSystemLabel)
    .reduce((accumulator: MegaFilterLabelOption[], label: LabelDTO) => {
      if ((label?.allowedValues?.length || 0) > 0) {
        return [
          ...accumulator,
          ...(label?.allowedValues || []).map((r: string) => ({
            code: label?.code,
            value: r || '',
            name: label?.name,
            usageLimit: label?.usageLimit || 0,
            isSystemLabel: label?.isSystemLabel || false,
            isDerived: true,
          })),
        ] as MegaFilterLabelOption[]
      } else {
        return [
          ...accumulator,
          {
            code: label?.code,
            value: label?.allowedValues?.[0] || '',
            name: label?.name,
            usageLimit: label?.usageLimit || 0,
            isSystemLabel: label?.isSystemLabel || false,
            isDerived: false,
          },
        ] as MegaFilterLabelOption[]
      }
    }, [])
