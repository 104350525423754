import * as Yup from 'yup'

import { ConfigSelectorOptions } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/EndFlowFormV3/utils'
interface IValidationParams {
  usedCodes?: Array<string>
}

export const getEndValidationSchema = ({ usedCodes }: IValidationParams) => {
  const usedCodesSet = new Set(usedCodes || [])
  return Yup.object().shape({
    code: Yup.string()
      .notOneOf([...usedCodesSet], 'This code is already in use')
      .required('Code is required'),
    description: Yup.string().required('Description is required'),
    assignee: Yup.string().when('configSelector', {
      is: ConfigSelectorOptions.Manual,
      then: Yup.string().required('Assignee is required'),
    }),
    finalizeRequestAs: Yup.string().when('configSelector', {
      is: ConfigSelectorOptions.Automated,
      then: Yup.string().required('This field is required'),
      otherwise: Yup.string(),
    }),
    allowAppeal: Yup.boolean(),
    appealWorkflowDefinitionCode: Yup.string().when('allowAppeal', {
      is: true,
      then: Yup.string().required('Required'),
    }),
    configSelector: Yup.string().required('Configuration selector is required'),
    coverLetterFile: Yup.array().when(['configSelector', 'isCoverLetterRequired'], {
      is: (configSelector: ConfigSelectorOptions, isCoverLetterRequired: boolean) =>
        configSelector === ConfigSelectorOptions.Automated && isCoverLetterRequired,
      then: Yup.array().min(1, 'A cover letter is required'),
    }),
    subjectCommunicationMessage: Yup.string().when(['configSelector', 'isMessageRequired'], {
      is: (configSelector: ConfigSelectorOptions, isMessageRequired: boolean) =>
        configSelector === ConfigSelectorOptions.Automated && isMessageRequired,
      then: Yup.string().required('Subject communication message is required'),
    }),
    isCoverLetterRequired: Yup.boolean(),
    isMessageRequired: Yup.boolean(),
    manualMessage: Yup.string().when('configSelector', {
      is: ConfigSelectorOptions.Manual,
      then: Yup.string().required('This field is required'),
    }),
    manualCoverLetter: Yup.string().when('configSelector', {
      is: ConfigSelectorOptions.Manual,
      then: Yup.string().required('This field is required'),
    }),
    timeToCompleteDays: Yup.number()
      .integer()
      .moreThan(-1, 'Number of days must be a whole number less than 365, or 0 to not require a due date.')
      .lessThan(365, 'Number of days must be a whole number less than 365, or 0 to not require a due date.'),
  })
}
