import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import {
  BuilderTextInputMode,
  FormTextInput,
} from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'

export const PreferenceThemeRequestsTabFormHeaderForm: React.FC = () => {
  return (
    <>
      <SidebarFormGroup sx={{ pt: 0, gap: '16px' }} hasBorderBottom>
        <FormColorInput
          name={themeUpsertFormKeys.preferenceRequestsTabFormHeaderTitleColor}
          label={'Title Color'}
          fullWidth
          required
        />
      </SidebarFormGroup>
      <SidebarFormGroup title="Back Button" sx={{ gap: '16px' }}>
        <FormColorInput
          name={themeUpsertFormKeys.preferenceRequestsTabFormHeaderReturnButtonBackgroundColor}
          label={'Fill Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferenceRequestsTabFormHeaderReturnButtonIconColor}
          label={'Icon Color'}
          fullWidth
          required
        />
        <FormTextInput
          name={themeUpsertFormKeys.preferenceRequestsTabFormHeaderReturnButtonCornerRadius}
          label={'Corner Radius (px)'}
          mode={BuilderTextInputMode.CornerRadius}
          inputWidth="92px"
        />
      </SidebarFormGroup>
    </>
  )
}
