import Box from '@mui/material/Box'
import { Text } from 'components/ui-kit/typography/Text'
import { useParams } from 'react-router-dom'
import { DataRiskDetailLayout } from 'pages/insights/dataRisks/components/layout/DataRiskDetailLayout'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { GreyBox } from 'pages/insights/dataRisks/components/layout/GreyBox'
import { getRiskScoreDetails } from 'pages/insights/utils'
import { ThirdPartyRisk } from '../../__mocks__/thirdPartyRisk'
import { getDataSystemType } from 'pages/insights/dataMap/utils/getDataSystemType'
import { capitalize } from 'lodash'
import { ThirdPartyRiskLowerContent } from './ThirdPartyRiskLowerContent'
import { ReactComponent as ArrowsIcon } from 'assets/icons/data_risks_suggestions_arrows.svg'
import { ReactComponent as ConnectIcon } from 'assets/icons/data_risks_suggestions_connect.svg'
import { ReactComponent as AppIcon } from 'assets/icons/data_risks_main_app.svg'
import { ReactComponent as TogglesIcon } from 'assets/icons/data_risks_main_toggles.svg'
import { ReactComponent as NoIcon } from 'assets/icons/no.svg'
import { useStyles } from '../../components/layout/styles'

type Props = {
  privacyControlsRecords: ThirdPartyRisk[]
  emptyState: JSX.Element
}

export const PrivacyControlsDetails: React.FC<Props> = ({ privacyControlsRecords, emptyState }) => {
  const { code: recordId } = useParams<{ code?: string }>()
  const dataSystem = privacyControlsRecords.find(record => record.id === recordId)
  const riskScoreDetails = getRiskScoreDetails(dataSystem?.riskScore || 0)
  const classes = useStyles()

  if (!dataSystem) {
    return emptyState
  }

  const actions = (
    <ButtonOptions size="big" items={[{ content: <Text>Export</Text> }, { content: <Text>Compare</Text> }]} />
  )

  const whyContent = (
    <Box>
      <Text component="div" size={20} weight={700} mb={1.5} lineHeight="28px">
        DSR and consent controls are not configured
      </Text>
      <Text component="div" lineHeight="20px">
        {dataSystem?.name} contains personal data and classifications that are on the high risk side.
      </Text>
    </Box>
  )

  const suggestionsContent = [
    {
      icon: <ConnectIcon />,
      textContent: (
        <Text lineHeight="20px" component="div">
          Connect Intercom apps to automate DSR fulfillment and consent capture.&nbsp;
          <Text color="royalBlue" onClick={() => {}}>
            Read a quick guide
          </Text>
          &nbsp;on how to set it up.
        </Text>
      ),
    },
    {
      icon: <ArrowsIcon />,
      textContent: (
        <Text lineHeight="20px" component="div">
          Automation ensures that all consumer consent and rights signals are captured and enforced.&nbsp;
          <Text color="royalBlue" onClick={() => {}}>
            Check this video
          </Text>
          &nbsp;about implementation techniques.
        </Text>
      ),
    },
  ]

  const lowerContentRows = [<ThirdPartyRiskLowerContent record={dataSystem} key={1} />]

  const mainContent = (
    <GreyBox>
      <Box height={220} display="flex" justifyContent="space-evenly" mb={2.5}>
        <Box width={200} bgcolor="white.main" borderRadius={3} position="relative">
          <Box position="absolute" top={8} right={8}>
            <ButtonOptions items={[{ content: 'Connect' }]} className={classes.connectButton} />
          </Box>
          <Box display="flex" mt={1.5} flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            <AppIcon />
            <Text component="div" size={14} weight={600} sx={{ mt: 1.5, mb: 3.5 }}>
              DSR app
            </Text>
            <Box display="flex" gap={0.75}>
              <NoIcon />
              <Text size={12} weight={600} color="grey" component="div" sx={{ mt: 0.625 }} letterSpacing="0.5px">
                Not connected
              </Text>
            </Box>
          </Box>
        </Box>
        <Box width={200} bgcolor="white.main" borderRadius={3} position="relative">
          <Box position="absolute" top={8} right={8}>
            <ButtonOptions items={[{ content: 'Connect' }]} className={classes.connectButton} />
          </Box>
          <Box display="flex" mt={1.5} flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            <TogglesIcon />
            <Text component="div" size={14} weight={600} sx={{ mt: 1.5, mb: 3.5 }}>
              Consent app
            </Text>
            <Box display="flex" gap={0.75}>
              <NoIcon />
              <Text size={12} weight={600} color="grey" component="div" sx={{ mt: 0.625 }} letterSpacing="0.5px">
                Not connected
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </GreyBox>
  )

  return (
    <DataRiskDetailLayout
      heading={dataSystem?.name || ''}
      headingType={capitalize(getDataSystemType(dataSystem?.canonicalResourceType || 0))}
      actions={actions}
      mainContent={mainContent}
      riskScoreDetails={riskScoreDetails}
      riskScore={dataSystem?.riskScore}
      whyContent={whyContent}
      suggestionContent={suggestionsContent}
      lowerContentRows={lowerContentRows}
    />
  )
}
