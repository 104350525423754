import { Box, Typography, styled } from '@mui/material'
import { useContext } from 'react'
import { BuilderContext } from '../../../Builder'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { ImageContainer } from './ImageContainer'
import { BUILDER_CONSTANTS, BuilderImageVariant } from '../../../utils/enums'
import { ExitButtonPosition } from '@ketch-sdk/ketch-types'
import { Icon } from '@ketch-com/deck'
import { clamp } from 'lodash'

type Props = {
  variant: ExitButtonPosition
}

const BottomLeftExitButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '12px 16px',
  marginBottom: '30px',
}))

const TopRightExitButtonBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  padding: '6px 12px 6px 4px',
  minHeight: '36px',
})

export const PreferenceExitButton: React.FC<Props> = ({ variant }) => {
  const { themeConfig, experienceConfig } = useContext(BuilderContext)

  // Theme config
  const exitBoxBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabExitButtonBackgroundColor)
  const exitBoxTextColor = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabExitButtonTextColor)
  const showExitButtonIcon = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabExitButtonIconVisible)
  const exitButtonUseDefaultIcon = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabExitButtonUseDefaultIcon)
  const exitButtonIconUrl = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabExitButtonIconUrl)
  const exitButtonIconColor = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabExitButtonIconColor)

  const exitButtonCornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabExitButtonCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'

  // Experience config
  const exitButtonText = getIn(experienceConfig, experienceUpsertFormKeys.preferenceWelcomeTabFooterActionButtonText)

  const ExitButtonBox = variant === ExitButtonPosition.bottomLeft ? BottomLeftExitButtonBox : TopRightExitButtonBox

  return (
    <ExitButtonBox
      sx={{
        background: exitBoxBackgroundColor,
        paddingLeft: showExitButtonIcon ? '4px' : '12px',
        borderRadius: exitButtonCornerRadius,
      }}
    >
      {showExitButtonIcon &&
        (exitButtonUseDefaultIcon ? (
          <Icon name={'OCross'} iconColor={exitButtonIconColor} />
        ) : (
          <ImageContainer variant={BuilderImageVariant.Navigation} imageUrl={exitButtonIconUrl} />
        ))}
      <Typography
        fontSize={'14px'}
        fontWeight={600}
        color={exitBoxTextColor}
        maxWidth={variant === ExitButtonPosition.bottomLeft ? 204 : 125}
        sx={{ wordWrap: 'break-word' }}
      >
        {exitButtonText}
      </Typography>
    </ExitButtonBox>
  )
}
