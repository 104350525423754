import { Box, Typography } from '@mui/material'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { Table } from 'components/ui-layouts/table/Table'
import {
  RelatedDatasetName,
  RelatedDatasetInclude,
  RelatedDatasetPaths,
} from 'pages/assetManager/dsrConfig/upsert/components/RelatedDataSets/components'
import { RelatedPathsAndAssets, DsrConfigFormData } from 'pages/assetManager/dsrConfig/interfaces'
import { useFormikContext } from 'formik'
import { useMemo } from 'react'
import { getPrimaryDataset } from 'pages/assetManager/dsrConfig/utils'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { Switch } from 'components/ui-kit/switch/Switch'
import { ReactComponent as QuestionMarkGrayIcon } from 'assets/icons/about_question_mark_gray.svg'
import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { getAssetType } from 'pages/assetManager/utils'
import pluralize from 'pluralize'

type Props = {
  relatedPathsAndAssets: RelatedPathsAndAssets
  assetsSummary: AssetSummaryDTO[]
}

export const RelatedDataSets: React.FC<Props> = ({ relatedPathsAndAssets, assetsSummary }) => {
  const providerCode = assetsSummary[0]?.asset?.connection?.providerCode || ''
  const datasetType = getAssetType(providerCode, 2)
  const { values, resetForm } = useFormikContext<DsrConfigFormData>()
  const { relatedDatasets } = values
  const relatedPathsOfAsset = Object.values(relatedPathsAndAssets)
  const relatedTablesCount = relatedPathsOfAsset.length
  const relatedPathOfAsset = relatedPathsOfAsset?.[0]?.[0]
  const primaryDataSet = relatedPathOfAsset && getPrimaryDataset(relatedPathOfAsset)
  const primaryDataSetName = primaryDataSet && primaryDataSet.datasetName

  const { excludeAllRelatedDataSets, includeAllRecommendedDataSets } = useMemo(() => {
    let excludeAllRelatedDataSets = {} as DsrConfigFormData['relatedDatasets']
    let includeAllRecommendedDataSets = {} as DsrConfigFormData['relatedDatasets']
    const relatedDatasetKeys = Object.keys(relatedPathsAndAssets)

    relatedDatasetKeys?.forEach?.(datasetId => {
      const relatedPathsList = relatedPathsAndAssets[datasetId]
      const pathLength = relatedPathsList.length
      const hasMultiplePaths = pathLength > 1
      let datasetPathHasReverseLookup = false
      excludeAllRelatedDataSets[datasetId] = { included: false }
      includeAllRecommendedDataSets[datasetId] = { included: false }

      relatedPathsList?.forEach?.((relatedPath, pathIndex) => {
        const hasReverseLookup = relatedPath?.path?.some(
          ({ relationships }) => relationships?.some(relationship => relationship?.[0]?.key === 'FK'),
        )
        includeAllRecommendedDataSets[datasetId][relatedPath.hashID] = true
        excludeAllRelatedDataSets[datasetId][relatedPath.hashID] = false

        hasReverseLookup ? (datasetPathHasReverseLookup = true) : (datasetPathHasReverseLookup = false)

        if (!datasetPathHasReverseLookup) includeAllRecommendedDataSets[datasetId].included = true

        if (hasReverseLookup && hasMultiplePaths) {
          includeAllRecommendedDataSets[datasetId][relatedPath.hashID] = false
        }
      })
    })

    return { excludeAllRelatedDataSets, includeAllRecommendedDataSets }
  }, [relatedPathsAndAssets])

  const recommended = Object.keys(includeAllRecommendedDataSets).filter(
    datasetId => includeAllRecommendedDataSets[datasetId].included,
  )
  const includeRecommended =
    Boolean(recommended?.length) &&
    recommended.every(datasetId =>
      Object.keys(includeAllRecommendedDataSets[datasetId]).every(
        hashId =>
          includeAllRecommendedDataSets[datasetId][hashId] === relatedDatasets[datasetId][hashId] &&
          includeAllRecommendedDataSets[datasetId].included,
      ),
    )

  const hasRecommendation = Object.keys(includeAllRecommendedDataSets).some(
    datasetId => includeAllRecommendedDataSets[datasetId].included,
  )

  return (
    <>
      {relatedTablesCount ? (
        <FormSection
          title={`Related ${pluralize(datasetType, relatedTablesCount)}`}
          subTitle={`"${primaryDataSetName}" ${datasetType.toLowerCase()} relates to ${relatedTablesCount} others. Would you like to include them to this configuration?`}
        >
          <Box mb={3} display="flex" alignItems="center" gap={1}>
            {!hasRecommendation ? (
              <Tooltip
                position="bottom"
                content={
                  <Box display="flex" flexDirection="column" gap={1} p={1}>
                    <Typography variant="smallBody">
                      All related tables contain at least one FK to PK reverse lookup.
                    </Typography>
                  </Box>
                }
              >
                <Box position="relative" top={4}>
                  <InfoIcon />
                </Box>
              </Tooltip>
            ) : null}
            <Switch
              disabled={!hasRecommendation}
              value={includeRecommended}
              onChange={() => {
                resetForm({
                  values: {
                    identitySpace: values.identitySpace,
                    relatedDatasets: includeRecommended ? excludeAllRelatedDataSets : includeAllRecommendedDataSets,
                    actions: values.actions,
                  },
                })
              }}
            />
            <Typography fontSize={14} fontWeight={includeRecommended ? 'bold' : 'normal'}>
              Include Recommended
            </Typography>
            <Tooltip
              position="bottom"
              content={
                <Box display="flex" flexDirection="column" gap={1} p={1}>
                  <Typography variant="smallBody">
                    We recommend including all PK to FK relationships, but not relationships which include any FK to PK
                    reverse lookup since that can result in data related to other data subjects being incorrectly
                    removed.
                  </Typography>
                </Box>
              }
            >
              <Box position="relative" top={4}>
                <QuestionMarkGrayIcon />
              </Box>
            </Tooltip>
          </Box>
          <Table
            items={relatedPathsOfAsset}
            cellSettings={{
              name: {
                label: 'Name',
                width: 250,
                cellRenderer: relatedPaths => (
                  <RelatedDatasetName relatedPaths={relatedPaths} assetsSummary={assetsSummary} />
                ),
              },
              relationDetails: {
                labelNodeRenderer: () => <Box ml={3.25}>Relation Details</Box>,
                cellRenderer: relatedPaths => <RelatedDatasetPaths relatedPaths={relatedPaths} />,
              },
              requiresDisplay: {
                label: '',
                width: 180,
                cellRenderer: relatedPaths => <RelatedDatasetInclude relatedPaths={relatedPaths} />,
              },
            }}
          />
        </FormSection>
      ) : null}

      {!relatedTablesCount ? (
        <FormSection title={`Related ${pluralize(datasetType, relatedTablesCount)}`}>
          <Box bgcolor="lighterGrey.main" borderRadius={3} padding={1.5}>
            <Typography variant="label">{`No related ${pluralize(
              datasetType,
              relatedTablesCount,
            ).toLowerCase()} found.`}</Typography>
          </Box>
        </FormSection>
      ) : null}
    </>
  )
}
