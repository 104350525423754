import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { Status } from 'components/ui-kit/status/Status'
import { Text } from 'components/ui-kit/typography/Text'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'

const PREFIX = 'ApprovalInfo'

const classes = {
  approvalContainer: `${PREFIX}-approvalContainer`,
  approvalName: `${PREFIX}-approvalName`,
  approvalStatus: `${PREFIX}-approvalStatus`,
}

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  [`& .${classes.approvalContainer}`]: {
    marginBottom: spacing(2.5),
  },

  [`& .${classes.approvalName}`]: {
    marginRight: spacing(1),
  },

  [`& .${classes.approvalStatus}`]: {
    marginRight: spacing(1),
  },
}))

// function to generate a random date in the past
function getRandomDate(start: Date, end: Date) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
}

const getApprovalStatusVariant = (approvalStatusNumber: number) => {
  switch (approvalStatusNumber) {
    case 0:
      return 'new'
    case 1:
      return 'in-progress'
    case 2:
      return 'active' // Approved
    case 3:
      return 'inactive' // disabled
    default:
      return 'draft'
  }
}

const getApprovalStatusDisplay = (approvalStatusNumber: number) => {
  switch (approvalStatusNumber) {
    case 0:
      return 'Pending'
    case 1:
      return 'Waiting for approval'
    case 2:
      return 'Approved'
    case 3:
      return 'Disabled'
    default:
      return ''
  }
}
// TODO:BAC - remove dummy data for dynamic
const dummyApproval = [
  { name: 'Max Pain', approvalStatus: 0, updatedAt: getRandomDate(new Date(2020, 0, 1), new Date()) },
  { name: 'Dr. Doom', approvalStatus: 1, updatedAt: getRandomDate(new Date(2020, 0, 1), new Date()) },
  { name: 'Henry VIII', approvalStatus: 2, updatedAt: getRandomDate(new Date(2020, 0, 1), new Date()) },
]

type ApprovalInfoProps = {
  policy: AssetPolicyDTO & {
    // TODO:BAC - can either be an array of objects or a map.
    approval: { [key: string]: string }[]
  }
}
export const ApprovalInfo: React.FC<ApprovalInfoProps> = ({ policy }) => {
  return (
    <StyledBox>
      {dummyApproval.map(approval => (
        <Box display="flex" alignItems="center" key={approval.name} mb={2.5}>
          <Text className={classes.approvalName} size={14} weight={600}>
            {approval.name}
          </Text>

          <Status
            className={classes.approvalStatus}
            size="regular"
            variant={getApprovalStatusVariant(approval.approvalStatus)}
          >
            {getApprovalStatusDisplay(approval.approvalStatus)}
          </Status>
          <Text color="grey" size={12} weight={500}>
            <FormattedDateRenderer date={Math.round(approval.updatedAt.getTime() / 1000)} />
          </Text>
        </Box>
      ))}
    </StyledBox>
  )
}
