import { Chip, ChipsAutocompleteOptionType, Icon } from '@ketch-com/deck'
import { Box } from '@mui/material'

export type HandleMultipleIdentityChipsDeselectValue = (chipData: ChipsAutocompleteOptionType) => void

export const renderSelectedMultipleIdentityChips =
  (handleDeselectValue: HandleMultipleIdentityChipsDeselectValue) =>
  (data: ChipsAutocompleteOptionType, index: number) => {
    return (
      <Box key={`${index}-${data.value}`} width="auto">
        <Chip
          size="small"
          label={data.label ?? data.value}
          secondaryLabel={data.value}
          deleteIcon={<Icon name="OCross" />}
          onDelete={() => handleDeselectValue(data)}
        />
      </Box>
    )
  }
