import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'
import AddCircleIcon from '@mui/icons-material/AddCircle'

import { UpsertExperiencePreviewText } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewText'
import { UpsertExperiencePreviewButton } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewButton'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      text: {
        display: 'inline-flex',
        alignItems: 'center',
        color: '#9e9e9e',
      },
      icon: {
        marginRight: 6,
        fontSize: typography.pxToRem(18),
      },
      button: {
        backgroundColor: palette.whiteDisabled.main,
        border: 0,
      },
      small: {
        padding: '11px 15px',
      },
    }),
  { name: 'UpsertExperienceEmptyField' },
)

type Props = {
  children?: React.ReactNode
  size?: 'small' | 'medium' | 'large'
  button?: boolean
  required?: boolean
}

export const UpsertExperienceEmptyField: React.FC<Props> = ({
  size = 'medium',
  button = false,
  required = false,
  children,
}) => {
  const classes = useStyles()

  const text = (
    <UpsertExperiencePreviewText
      required={required}
      className={classes.text}
      size={size}
      bold={size === 'medium' || size === 'large'}
    >
      <AddCircleIcon className={classes.icon} /> Add {children}
    </UpsertExperiencePreviewText>
  )

  return button ? (
    <UpsertExperiencePreviewButton className={clsx(classes.button, { [classes.small]: size === 'small' })}>
      {text}
    </UpsertExperiencePreviewButton>
  ) : (
    text
  )
}
