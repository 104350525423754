import { RightInvocationDTO as RightInvocationDTOV2 } from '@ketch-com/figurehead'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import {
  FetchRightInvocationsFilterKeys,
  FetchRightInvocationsOrderByKeys,
} from 'interfaces/rightInvocationsV2/FetchRightInvocationsFilterKeys'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { StatusVariantV3Enum } from 'components/ui-kit/status/interfaces'

export const RightsQueueListTableColumnIds = [
  FetchRightInvocationsFilterKeys.statuses,
  FetchRightInvocationsOrderByKeys.invoked_at,
  FetchRightInvocationsOrderByKeys.status,
  'request-code',
  'data-role',
  'subject-type',
  'assignee',
  'progress',
  'invoke-date',
  'current-activity',
  'workflow',
  'subject-name',
  'messages',
] as const

export type RightsQueueListTableColumnId = (typeof RightsQueueListTableColumnIds)[number]

export interface RightsQueueListTableColumn {
  id: RightsQueueListTableColumnId
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
  disablePadding?: boolean
  numeric: boolean
  cellRenderer?: (rightInvocation: RightInvocationDTO) => React.ReactNode
}

export interface RightsQueueListTableColumn {
  id: RightsQueueListTableColumnId
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
  disablePadding?: boolean
  numeric: boolean
  cellRenderer?: (rightInvocation: RightInvocationDTO) => React.ReactNode
}

export interface RightsQueueListTableColumnV2 {
  id: RightsQueueListTableColumnId
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
  disablePadding?: boolean
  numeric: boolean
  cellRenderer?: (rightInvocation: RightInvocationDTOV2) => React.ReactNode
}

export type Order = 'asc' | 'desc'

export enum Ordering {
  Ascending = 'asc',
  Descending = 'desc',
  OrderBy = 'orderBy',
  Ordering = 'ordering',
}

export const workflowExecutionStepStatusVariantMap = {
  [WorkflowExecutionStepStatus.PENDING]: StatusVariantV3Enum.draft,
  [WorkflowExecutionStepStatus.IN_PROGRESS]: StatusVariantV3Enum.inProgress,
  [WorkflowExecutionStepStatus.SUCCESS]: StatusVariantV3Enum.finishedDone,
  [WorkflowExecutionStepStatus.FAIL]: StatusVariantV3Enum.errorCanceled,
  [WorkflowExecutionStepStatus.INVALID_CREDENTIALS]: StatusVariantV3Enum.errorCanceled,
} as const

export const WORKFLOW_EXECUTION_STEP_NAMES_V2 = {
  [WorkflowExecutionStepStatus.PENDING]: 'Pending',
  [WorkflowExecutionStepStatus.IN_PROGRESS]: 'In Progress',
  [WorkflowExecutionStepStatus.SUCCESS]: 'Completed',
  [WorkflowExecutionStepStatus.FAIL]: 'Fail',
  [WorkflowExecutionStepStatus.INVALID_CREDENTIALS]: 'Invalid Credentials',
}

export const MANUAL_ACTIVITY_CODE_LIST = [
  'standard.ManualTaskActivity',
  'standard.WaitForApprovalActivity',
  'standard.DataReviewWorkflow',
  'standard.ForwardEventWorkflow',
]
