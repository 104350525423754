import { isEmpty, find } from 'lodash'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { canReachStartPath } from './canReachStartPath'

interface IOmitDisconnectedSteps {
  steps: Array<ICanvasStep>
}

export const omitDisconnectedSteps = (params: IOmitDisconnectedSteps): Array<ICanvasStep> => {
  const { steps } = params
  const disconnectedSteps: Array<ICanvasStep> = []

  steps?.forEach?.(workflowStep => {
    if (!canReachStartPath({ step: workflowStep, steps })) {
      disconnectedSteps.push(workflowStep)
    }
  })

  return steps.filter(step => isEmpty(find(disconnectedSteps, { ID: step.ID })))
}
