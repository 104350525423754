import React from 'react'
import Box from '@mui/material/Box'

import { useAutocompleteStyles } from '../hooks'
import { Text } from 'components/ui-kit/typography/Text'
import { ResourceDTO } from '@ketch-com/figurehead'

type Props = {
  option: ResourceDTO
}

export const KeyOptionRenderer: React.FC<Props> = ({ option }) => {
  const autocompleteClasses = useAutocompleteStyles()

  return (
    <Box className={autocompleteClasses.attributeSelectOptionContainer}>
      <Box mb={0.5}>
        <Text size={14} weight={600}>
          {option?.name}
        </Text>
      </Box>
      <Box mb={0.25}>
        <Text size={11} weight={500} color="grey">
          Type: {option?.properties?.DATA_TYPE}
        </Text>
      </Box>
      <Text size={11} weight={500} color="grey">
        Dataset: {option?.hierarchies?.CANONICAL_RESOURCE_TYPE_DATASET?.name}
      </Text>
    </Box>
  )
}
