import { Box, Typography, styled } from '@mui/material'
import { getIn } from 'formik'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { useContext } from 'react'
import { RichTextRenderer } from '../../../../../RichTextRenderer'
import { SectionEmptyState } from '../../../../../SectionEmptyState'
import { SelectableSection } from '../../../../../SelectableSection'
import { PreferenceExperienceBuilderSection } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'

const HeaderBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '11px',
})

export const SubscriptionsHeader: React.FC = () => {
  const { themeConfig, experienceConfig, isTheme } = useContext(BuilderContext)

  // Theme config
  const titleColor = getIn(themeConfig, themeUpsertFormKeys.preferenceSubscriptionsTabHeaderTitleColor)
  const descriptionColor = getIn(themeConfig, themeUpsertFormKeys.preferenceSubscriptionsTabHeaderDescriptionColor)
  const linkColor = getIn(themeConfig, themeUpsertFormKeys.preferenceSubscriptionsTabHeaderLinkColor)
  const linkUnderline = getIn(themeConfig, themeUpsertFormKeys.preferenceSubscriptionsTabHeaderLinkUnderline)

  // Experience config
  const titleVisible = getIn(experienceConfig, experienceUpsertFormKeys.preferenceSubscriptionsTabHeaderTitleVisible)
  const title = getIn(experienceConfig, experienceUpsertFormKeys.preferenceSubscriptionsTabHeaderTitleText)
  const description = getIn(experienceConfig, experienceUpsertFormKeys.preferenceSubscriptionsTabHeaderDescriptionText)

  return (
    <HeaderBox maxWidth={556}>
      <SelectableSection
        section={isTheme ? undefined : PreferenceExperienceBuilderSection.SubscriptionsTabHeaderTitle}
        overlayProps={{ margin: '6px 0px' }}
      >
        {titleVisible ? (
          <Typography color={titleColor} fontSize={'28px'} fontWeight={600} sx={{ wordWrap: 'break-word' }}>
            {title}
          </Typography>
        ) : (
          <SectionEmptyState section="Page Title" hidden={isTheme} />
        )}
      </SelectableSection>
      <SelectableSection
        section={isTheme ? undefined : PreferenceExperienceBuilderSection.SubscriptionsTabHeaderDescription}
        overlayProps={{ margin: '10px 0px' }}
      >
        <RichTextRenderer
          text={description}
          textColor={descriptionColor}
          linkColor={linkColor}
          underlineLinks={linkUnderline}
        />
      </SelectableSection>
    </HeaderBox>
  )
}
