import { Text } from 'components/ui-kit/typography/Text'

type CustomRechartLabelProps = { xOffset?: number; yOffset?: number; textSize?: number; title: string }

// component inspired by StackOverflow post: https://stackoverflow.com/a/64068666/6642239
export const CustomRechartLabel: React.FC<CustomRechartLabelProps> = ({
  xOffset = 0,
  yOffset = 0,
  textSize = 14,
  title,
}) => {
  return (
    <g>
      <foreignObject x={xOffset} y={yOffset} width={100} height={100}>
        <Text weight={500} size={textSize || 12} color="darkDuskFaded">
          {title}
        </Text>
      </foreignObject>
    </g>
  )
}
