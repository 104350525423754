import { useQueries } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { GetDSRConfigsResponseBodyDTO } from '@ketch-com/figurehead'
import { fetchAssetsDsrConfigDynamic } from '../fetchers/fetchAssetsDsrConfigDynamic'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type Params = {
  assetIds: (string | undefined)[]
  scenarioId: string
  enabled?: boolean
}

export const useAssetsDsrConfigDynamic = ({ assetIds, scenarioId, enabled }: Params) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )

  const queries = assetIds.map((assetId = '') => ({
    queryKey: [ApiQueryKeys.assetDsrConfig, assetId, scenarioId],
    queryFn: () => fetchAssetsDsrConfigDynamic({ assetId, scenarioId, isV2FeatureFlagEnabled }),
    enabled,
  }))

  const results = useQueries(queries as any).map((r: any) => {
    return {
      data: (r?.data?.data || []) as GetDSRConfigsResponseBodyDTO[],
      isLoading: r?.isLoading || r?.isFetching || false,
    }
  })

  const assetsDsrConfig = results.flatMap(result => result.data)

  const payload = { assetsDsrConfig, isLoading: results.some(r => r?.isLoading) }

  return payload
}
