import React, { useState } from 'react'

import { FormTemplateSummaryDTO } from '@ketch-com/figurehead'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { setNavigationSliceGoBack } from 'store/navigationSlice'
import { useDispatch } from 'react-redux'
import { FormMode } from 'interfaces/formModes/formMode'
import { AreYouSureYouWantToDeleteModal } from 'components/modals/AreYouSureYouWantToDeleteModal'
import { useFormTemplate } from 'api/formTemplates/queries/useFormTemplate'
import { DeleteFormTemplateModalBody } from '../../components'
import { getFormTemplateDeployedExperiences } from '../../upsert/utils'
import { showToast } from 'components/modals/AlertComponent'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useQueryClient } from 'react-query'
import { useDeleteFormTemplate } from 'api/formTemplates/mutations/useDeleteFormTemplate'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { PreviewFrom } from '../../preview/interfaces'

type Props = {
  formTemplateSummary?: FormTemplateSummaryDTO
  isUserExperienceServerAdmin?: boolean
}

export const TemplatesDropdown: React.FC<Props> = ({ formTemplateSummary, isUserExperienceServerAdmin }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const queryClient = useQueryClient()
  const [isDeleteFormTemplateModalOpen, setIsDeleteFormTemplateModalOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { data: formTemplate, isLoading: isFormTemplateLoading } = useFormTemplate({
    enabled: isDeleteFormTemplateModalOpen,
    params: {
      formTemplateId: formTemplateSummary?.id || '',
    },
  })

  const { mutateAsync: handleDeleteFormTemplate, isLoading: isDeletingFormTemplate } = useDeleteFormTemplate({
    onSuccess: async () => {
      setIsDeleteFormTemplateModalOpen(false)
      await queryClient.invalidateQueries(ApiQueryKeys.formTemplatesPaginated)
      showToast({ content: 'Form deleted', type: 'success' })
    },
  })

  const isFormTemplateAssociatedWithDeployedExperiences = getFormTemplateDeployedExperiences(formTemplate).length > 0

  return (
    <>
      <Button size="medium" variant="iconSubtle" onClick={handleClickListItem} disabled={!isUserExperienceServerAdmin}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transitionDuration={0}
        onClick={e => e.stopPropagation()}
      >
        <ActionSheetItem
          onClick={e => {
            handleClose()
            navigate({
              pathname: RoutesManager.deployment.forms.templates.upsert.preview.root.getURL({
                formMode: FormMode.EDIT,
                id: formTemplateSummary?.id || '',
              }),
              search: createSearchParams({
                from: PreviewFrom.Detail,
              }).toString(),
            })
          }}
        >
          Preview
        </ActionSheetItem>
        {isUserExperienceServerAdmin && <ActionSheetItem divider />}
        {isUserExperienceServerAdmin && (
          <ActionSheetItem
            onClick={e => {
              handleClose()
              const targetUrl = RoutesManager.deployment.forms.templates.upsert.root.getURL({
                id: formTemplateSummary?.id || '',
                formMode: FormMode.EDIT,
              })
              dispatch(setNavigationSliceGoBack(location.pathname))
              navigate(targetUrl)
            }}
          >
            Edit
          </ActionSheetItem>
        )}
        {isUserExperienceServerAdmin && <ActionSheetItem divider />}
        {isUserExperienceServerAdmin && (
          <ActionSheetItem
            onClick={e => {
              handleClose()
              setIsDeleteFormTemplateModalOpen(true)
            }}
          >
            Delete
          </ActionSheetItem>
        )}
      </ActionSheetWrapper>

      {isDeleteFormTemplateModalOpen && (
        <AreYouSureYouWantToDeleteModal
          title="Delete Form"
          cancelBtnLabel="Close"
          isBusy={isDeletingFormTemplate}
          isLoading={isFormTemplateLoading}
          onCancel={() => setIsDeleteFormTemplateModalOpen(false)}
          onSubmit={
            isFormTemplateAssociatedWithDeployedExperiences
              ? undefined
              : () =>
                  handleDeleteFormTemplate({
                    params: {
                      formTemplateId: formTemplate?.id || '',
                    },
                  })
          }
          body={
            <DeleteFormTemplateModalBody
              formTemplate={formTemplate}
              isFormTemplateAssociatedWithDeployedExperiences={isFormTemplateAssociatedWithDeployedExperiences}
            />
          }
        />
      )}
    </>
  )
}
