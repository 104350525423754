import React, { useContext } from 'react'
import Box from '@mui/material/Box'

import { Group } from 'components/ui-layouts/group/Group'
import { InterpolateLinks } from 'pages/consentAndRights/experiences/components/InterpolateLinks'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { PreferenceField, serialize } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { UpsertExperienceEditableOverlay } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEditableOverlay'
import { UpsertExperienceEmptyField } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEmptyField'
import { UpsertExperiencePreviewButton } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewButton'
import { UpsertExperiencePreviewPlaceholder } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewPlaceholder'
import { UpsertExperiencePreviewText } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewText'
import { useUpsertPreferenceStyles } from 'pages/consentAndRights/experiences/upsert/components/UpsertPreference/hooks'
import { useFormikContext } from 'formik'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'

type Props = {}

export const SubscriptionsPreview: React.FC<Props> = () => {
  const classes = useUpsertPreferenceStyles()
  const { getOverlayProps } = useContext(ExperienceUpsertContext)

  const { values } = useFormikContext<PreferenceExperienceFormValues>()
  const { subscriptions } = values

  return (
    <>
      <Box className={classes.body}>
        <Box className={classes.textWrapper}>
          <UpsertExperienceEditableOverlay
            className={classes.bodyTitle}
            {...getOverlayProps(PreferenceField.SUBSCRIPTIONS_BODY_TITLE)}
          >
            {subscriptions?.bodyTitle ? (
              <UpsertExperiencePreviewText bold size="large">
                {subscriptions.bodyTitle}
              </UpsertExperiencePreviewText>
            ) : (
              <UpsertExperienceEmptyField size="large">Body Title</UpsertExperienceEmptyField>
            )}
          </UpsertExperienceEditableOverlay>

          <UpsertExperienceEditableOverlay
            className={classes.bodyDescription}
            {...getOverlayProps(PreferenceField.SUBSCRIPTIONS_BODY_DESCRIPTION)}
          >
            {(() => {
              const bodyDescriptionText = serialize(subscriptions.bodyDescription)

              return bodyDescriptionText ? (
                <UpsertExperiencePreviewText size="small">
                  <InterpolateLinks text={bodyDescriptionText} />
                </UpsertExperiencePreviewText>
              ) : (
                <UpsertExperienceEmptyField required size="small">
                  Body Description
                </UpsertExperienceEmptyField>
              )
            })()}
          </UpsertExperienceEditableOverlay>
        </Box>

        <UpsertExperienceEditableOverlay
          className={classes.bodyDescription}
          {...getOverlayProps(PreferenceField.SUBSCRIPTIONS_TOPICS)}
        >
          <UpsertExperiencePreviewPlaceholder className={classes.subscriptionsPlaceholder}>
            Subscriptions List
          </UpsertExperiencePreviewPlaceholder>
        </UpsertExperienceEditableOverlay>

        <UpsertExperienceEditableOverlay
          className={classes.bodyDescription}
          {...getOverlayProps(PreferenceField.SUBSCRIPTIONS_CONTROL)}
        >
          <UpsertExperiencePreviewPlaceholder className={classes.subscriptionsPlaceholder}>
            Unsubscribe From All
          </UpsertExperiencePreviewPlaceholder>
        </UpsertExperienceEditableOverlay>
      </Box>

      <Box className={classes.footer}>
        <Box className={classes.footerContent}>
          <Group gap={16}>
            <UpsertExperienceEditableOverlay {...getOverlayProps(PreferenceField.SUBSCRIPTIONS_PRIMARY_BUTTON)}>
              {subscriptions?.primaryButtonText ? (
                <UpsertExperiencePreviewButton className={classes.acceptButton}>
                  <UpsertExperiencePreviewText size="small" color="white" bold>
                    {subscriptions.primaryButtonText}
                  </UpsertExperiencePreviewText>
                </UpsertExperiencePreviewButton>
              ) : (
                <UpsertExperienceEmptyField required button size="small">
                  Save
                </UpsertExperienceEmptyField>
              )}
            </UpsertExperienceEditableOverlay>

            <UpsertExperienceEditableOverlay {...getOverlayProps(PreferenceField.SUBSCRIPTIONS_SECONDARY_BUTTON)}>
              {subscriptions?.secondaryButtonText ? (
                <UpsertExperiencePreviewButton className={classes.denyButton} inverted>
                  <UpsertExperiencePreviewText size="small" color="blue" bold>
                    {subscriptions.secondaryButtonText}
                  </UpsertExperiencePreviewText>
                </UpsertExperiencePreviewButton>
              ) : (
                <UpsertExperienceEmptyField required button size="small">
                  Exit Settings
                </UpsertExperienceEmptyField>
              )}
            </UpsertExperienceEditableOverlay>
          </Group>
        </Box>
      </Box>
    </>
  )
}
