import { cloneDeep, isUndefined } from 'lodash'

import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep, IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { getSiblingsFromStep } from './siblingsFromStep'
import { omitDisconnectedSteps } from './omitDisconnectedSteps'
import { isNewStepConnectedNestStart } from './isNewStepConnectedNestStart'
import { getNextJoinTile, getTileBeforeNextJoinTile } from './getNextJoinTile'
import { getStepTransitions } from './getStepTransitions'
import { isTileInConnectedNest } from './isTileInConnectedNest'

export interface IRemoveStepParams {
  workflow?: IWorkflowConfig
  step: ICanvasStep
  pathId?: string
}

export interface IRemoveStep {
  newSteps: Array<ICanvasStep>
}

const getParentSteps = (steps: ICanvasStep[], stepId: string) => {
  return steps.filter(step => getStepTransitions(step).includes(stepId))
}

const updateParentStepWithNewChild = ({
  parentStep,
  childStep,
  transitionIndex,
}: {
  parentStep: ICanvasStep
  childStep?: ICanvasStep
  transitionIndex?: number
}) => {
  // Figuring out which type of step is being handled is necessary because the workflow data structures are built to cause pain and suffering
  const isStart = !isUndefined(parentStep?.start)
  const isActivity = !isUndefined(parentStep?.activity)
  const isPlaceholder = !isUndefined(parentStep?.placeholder)
  const isWorkflow = !isUndefined(parentStep?.workflow)
  const isGateway = !isUndefined(parentStep?.gateway)

  // We must do a deep copy here to prevent weird readonly errors after copying
  const itemToReturn = cloneDeep(parentStep)

  if (isStart) {
    itemToReturn.start!.transition = childStep?.ID
  }

  if (isActivity) {
    itemToReturn.activity!.transition = childStep?.ID
  }

  if (isWorkflow) {
    itemToReturn.workflow!.transition = childStep?.ID
  }

  if (isPlaceholder) {
    itemToReturn.placeholder!.transition = childStep?.ID
  }

  if (isGateway) {
    if (childStep) {
      const existingTransition = itemToReturn.gateway!.transitions[transitionIndex!]
      itemToReturn.gateway!.transitions[transitionIndex!] = {
        ...existingTransition,
        ...childStep,
        name: existingTransition.name,
      }
    } else {
      itemToReturn.gateway!.transitions = []
    }
  }

  return itemToReturn
}

const doesNestPathToFollowContainConnectedNestStart = ({
  steps,
  step,
}: {
  steps: ICanvasStep[]
  step: ICanvasStep
}): boolean => {
  const result = isNewStepConnectedNestStart({
    steps,
    step,
  })

  if (result) return true

  //TODO:JA this could fail if there is a nested gateway on the second path of a decision boolean
  const nextStepId = getStepTransitions(step)[0] // Taking the first is safe since only gateways have more than one transition

  const nextStep = steps.find(({ ID }) => ID === nextStepId)

  if (!nextStep || nextStep.gateway?.mode === WorkflowActivityGatewayMode.JOIN) return false

  return doesNestPathToFollowContainConnectedNestStart({ steps, step: nextStep })
}

//TODO:JA Refactor needed - This can be done way more efficiently and require less props to do so.
const updateStepConnectionsIfNecessary = (
  workflowStep: ICanvasStep,
  isCurrentStepJoinGateway: boolean,
  isCurrentStepConnectedNestStart: boolean,
  parentStep: ICanvasStep,
  doesNestPathToFollowContainAConnectedNestStart: boolean,
  isStepInConnectedNest: boolean,
  nestPathToFollow?: ICanvasStep,
  stepsLeadingToJoin: ICanvasStep[] = [],
  childStep?: ICanvasStep,
  transitionIndex?: number,
  connectedNestEnd?: ICanvasStep,
  tileBeforeConnectedNestEnd?: ICanvasStep,
) => {
  const { ID } = workflowStep

  // Deep copy the object to return
  let itemToReturn = cloneDeep(workflowStep)

  // For join gateways, tack the child step onto the top path of the nest
  if (isCurrentStepJoinGateway) {
    const pathToAttachTo = stepsLeadingToJoin[0].ID
    const otherPathsToJoin = stepsLeadingToJoin
      .slice(1)
      .filter(s => !isUndefined(s))
      .map(({ ID }) => ID)

    if (pathToAttachTo === ID) {
      const tileAfterJoin = connectedNestEnd?.gateway?.transitions?.[0]

      itemToReturn = updateParentStepWithNewChild({
        childStep: tileAfterJoin,
        parentStep: workflowStep,
        transitionIndex,
      })
    } else if (otherPathsToJoin.includes(ID)) {
      itemToReturn = updateParentStepWithNewChild({
        childStep: undefined,
        parentStep: workflowStep,
        transitionIndex,
      })
    }

    // If deleting a nest start, keep the correct path if the user selected one
    // If not, connect the parent step to the tile after the join
  } else if (isCurrentStepConnectedNestStart || nestPathToFollow) {
    const tileAfterJoin = connectedNestEnd?.gateway?.transitions?.[0]

    if (nestPathToFollow) {
      if (ID === parentStep?.ID) {
        itemToReturn = updateParentStepWithNewChild({
          childStep: nestPathToFollow,
          parentStep,
          transitionIndex,
        })
      }

      // If deleting a nest start, also connect the associated join, or the tile after it if the join is to be removed
      if (tileBeforeConnectedNestEnd?.ID === ID) {
        const shouldConnectToJoinTile = doesNestPathToFollowContainAConnectedNestStart || isStepInConnectedNest

        itemToReturn = updateParentStepWithNewChild({
          childStep: shouldConnectToJoinTile ? connectedNestEnd : tileAfterJoin,
          parentStep: tileBeforeConnectedNestEnd!,
          transitionIndex,
        })
      }
    } else if (ID === parentStep?.ID) {
      itemToReturn = updateParentStepWithNewChild({
        childStep: tileAfterJoin,
        parentStep,
        transitionIndex,
      })
    }

    // All other tiles should link the parent and child steps
  } else if (ID === parentStep?.ID) {
    itemToReturn = updateParentStepWithNewChild({ childStep, parentStep, transitionIndex })
  }

  return itemToReturn
}

export const removeStep = ({ workflow, step, pathId }: IRemoveStepParams): IRemoveStep => {
  const steps = workflow?.steps || []
  const updatedSteps: Array<ICanvasStep> = []

  // Note that childStep is not valid for Decisions/Splits
  const { parentStep, parentTransitionIndex, childStep } = getSiblingsFromStep({ step, steps })
  if (!parentStep) return { newSteps: steps }

  const isCurrentStepJoinGateway = step?.gateway?.mode === WorkflowActivityGatewayMode.JOIN
  const isCurrentStepConnectedNestStart = isNewStepConnectedNestStart({
    step,
    steps,
    requireNestedGatewayValidation: true,
    requireAllPathsLeadingToJoinValidation: true,
  })
  const isStepInConnectedNest = isTileInConnectedNest({ step, steps })

  const nestPathToFollow = pathId ? steps.find(({ ID }) => ID === pathId) : undefined

  const doesNestPathToFollowContainAConnectedNestStart = nestPathToFollow
    ? doesNestPathToFollowContainConnectedNestStart({
        steps,
        step: nestPathToFollow,
      })
    : false

  const connectedNestEnd = isCurrentStepJoinGateway ? step : getNextJoinTile({ steps, step })

  const tileBeforeConnectedNestEnd = nestPathToFollow
    ? getTileBeforeNextJoinTile({ step: nestPathToFollow, steps })
    : undefined

  const stepsLeadingToJoin = isCurrentStepJoinGateway
    ? getParentSteps(workflow?.steps || [], step?.ID as string)
    : undefined

  if (!parentStep && !childStep) {
    return {
      newSteps: [],
    }
  }

  //TODO:JA Refactor needed - Lots of these props are needed only for specific tiles, deciding the tile type should be done first to
  steps.forEach(workflowStep => {
    const updatedStep = updateStepConnectionsIfNecessary(
      workflowStep,
      isCurrentStepJoinGateway,
      isCurrentStepConnectedNestStart,
      parentStep,
      doesNestPathToFollowContainAConnectedNestStart,
      isStepInConnectedNest,
      nestPathToFollow,
      stepsLeadingToJoin,
      childStep,
      parentTransitionIndex,
      connectedNestEnd,
      tileBeforeConnectedNestEnd,
    )

    const shouldRemoveJoinTile = isCurrentStepConnectedNestStart && !doesNestPathToFollowContainAConnectedNestStart

    if (
      workflowStep.ID !== step.ID &&
      // Remove the join tile, unless there is a nested gateway to attach to it
      (!shouldRemoveJoinTile || workflowStep.ID !== connectedNestEnd?.ID)
    ) {
      updatedSteps.push(updatedStep)
    }
  })

  const result = {
    newSteps: omitDisconnectedSteps({ steps: updatedSteps }),
  }

  return result
}
