import { useState } from 'react'
import { debounce } from 'lodash'

import { useDmlHooks } from 'api/dmlHooks/queries/useDmlHooks'
import { AssetSummaryDTO, DmlHookDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'

type Args = {
  isEnabled?: boolean
  assetSummary?: MaybeNull<AssetSummaryDTO>
}

export const useHookSearchOptions = ({ isEnabled = true, assetSummary }: Args) => {
  const [queryValue, setQueryValue] = useState('')
  const [assetType, setAssetType] = useState<number | undefined>()
  const [options, setOptions] = useState<DmlHookDTO[]>([])
  const [placeholderOptions, setPlaceholderOptions] = useState<DmlHookDTO[]>([])

  const { isLoading: isLoadingHooks, isFetching: isFetchingHooks } = useDmlHooks({
    enabled: !!assetType && !!queryValue && isEnabled,
    params: {
      query: queryValue,
      technology: assetSummary?.asset?.connection?.providerCode,
    },
    onSuccess: ({ data }) => {
      setOptions((data?.hooks || []) as DmlHookDTO[])
    },
  })

  const { isLoading: isLoadingPlaceholderHooks, isFetching: isFetchingPlaceholderHooks } = useDmlHooks({
    itemsPerPage: 50,
    params: {
      technology: assetSummary?.asset?.connection?.providerCode,
    },

    onSuccess: ({ data }) => {
      setPlaceholderOptions((data?.hooks || []) as DmlHookDTO[])
    },
  })

  // de-duplicate array after merging "placeholderOptions" and "options"
  const deDuplicatedHookOptions = [...new Map([...options, ...placeholderOptions].map(v => [v.id, v])).values()]

  return {
    queryValue,
    setSearchValue: debounce((searchValue, assetTypeValue: number) => {
      setQueryValue(searchValue)
      setAssetType(assetTypeValue)
    }, 500),
    hookOptions: deDuplicatedHookOptions,
    isLoadingHookOptions: isLoadingHooks || isFetchingHooks || isLoadingPlaceholderHooks || isFetchingPlaceholderHooks,
    setHookOptions: setOptions,
  }
}
