export enum SidebarMode {
  Closed = 'closed',
  Compact = 'compact',
  Open = 'open',
  Overlay = 'overlay',
}

export enum AppNavigationSize {
  FullSize = 'fullSize', // >= 1500px
  Compact = 'compact', // 1342px <= width <= 1500px
  Hidden = 'hidden', // < 1342px
}
