import React from 'react'

import { useControllersPaginated } from 'api/controllers/queries/useControllersPaginated'
import { ControllersList } from 'pages/policyCenter/controllers/list/ControllersList'

export const ControllersListContainer: React.FC = () => {
  const {
    data: controllers,
    isLoading,
    isFetching,
    pagination,
  } = useControllersPaginated({
    params: {
      includeMetadata: true,
    },
  })

  return (
    <ControllersList controllers={controllers} pagination={pagination} isLoading={isLoading} isFetching={isFetching} />
  )
}
