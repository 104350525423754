import Box from '@mui/material/Box'
import React from 'react'
import { useFormikContext } from 'formik'

import { Button, DropListButton, ListItemText, ActionSheetItem } from '@ketch-com/deck'
import { RightInvocationFormValues } from 'interfaces/rightInvocations'
import { FormFieldTypeDTO, FormFieldVariantDTO } from '@ketch-com/figurehead'
import { useFormFields } from 'api/formFields/queries/useFormFields'
import { Tooltip } from '@mui/material'

const standardFieldNames = ['firstName', 'lastName', 'email']

type Props = {
  push: (obj: any) => void
}

export const AddFieldRow: React.FC<Props> = ({ push }) => {
  const {
    values: { formData: formikFormFields },
  } = useFormikContext<RightInvocationFormValues>()
  const [selectedFieldCode, setSelectedFieldCode] = React.useState<string>('')
  const { data: formFields } = useFormFields({
    itemsPerPage: 1000,
    params: {
      includeMetadata: true,
    },
  })
  /* NOTE: because "description" is not returned from the API, we need to add it manually */
  const formFieldItemsWithDescription = [
    {
      id: 'description',
      code: 'description',
      title: 'Description',
      type: FormFieldTypeDTO.TextFormFieldType,
      variant: FormFieldVariantDTO.TextareaFormFieldVariant,
    },
    ...(formFields || []),
  ]

  const selectedField = formFieldItemsWithDescription.find(cv => cv.code === selectedFieldCode) || null
  const formFieldItems = formFieldItemsWithDescription
    .filter(cv => !formikFormFields?.find(pdv => pdv.id === cv.id) && !standardFieldNames.includes(cv?.code || ''))
    .map(cv => ({
      active: selectedFieldCode === cv.code,
      content: cv.name || cv.title,
      value: cv.code,
      onClick: () => {
        setSelectedFieldCode(cv?.code || '')
      },
    }))

  const droplistFields = formFieldItemsWithDescription.filter(
    cv => !formikFormFields?.find(pdv => pdv.id === cv.id) && !standardFieldNames.includes(cv?.code || ''),
  )

  return (
    <Tooltip title={formFieldItems.length ? '' : 'No more fields to add'}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1.5}
        sx={{
          backgroundColor: 'sphereFaded.main',
          borderRadius: 2.75, // 11px
          maxWidth: 410,
        }}
      >
        {/* Left */}

        <Box display="flex" alignItems="center">
          <DropListButton
            sx={{
              width: '300px',
            }}
            size="small"
            disableClearable={selectedFieldCode !== null}
            disabled={!formFieldItems.length}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            getOptionLabel={option => option.name || option.title || ''}
            renderOption={(props, option, { selected }) => (
              <ActionSheetItem key={option.code} selected={selected} {...props}>
                <ListItemText selected={selected}>{option.name || option.title}</ListItemText>
              </ActionSheetItem>
            )}
            options={droplistFields}
            onChange={(_, value) => value && setSelectedFieldCode(value?.code || '')}
            value={selectedField}
            placeholder="Select Field"
          />
        </Box>

        {/* Right */}

        <Button
          color="secondary"
          disabled={!selectedField}
          onClick={() => {
            if (selectedField) {
              push({
                ...selectedField,
                values: [],
                value: '',
              })
              setSelectedFieldCode('')
            }
          }}
        >
          Add
        </Button>
      </Box>
    </Tooltip>
  )
}
