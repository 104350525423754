import { createUseQuery, CustomQueryConfig } from '../../../common/query'
import { ExternalApiQueryKeys } from '../../../common/queryKeys'
import { fetchIpLocation } from '../fetchers/fetchIpLocation'

const useCustomQuery = createUseQuery({
  queryKey: ExternalApiQueryKeys.ipLocation,
  queryFn: fetchIpLocation,
  select: res => res?.data || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useIpLocation = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
