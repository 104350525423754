import { createUseQuery, CustomQueryConfig } from '../../common/query'
import { ApiQueryKeys } from '../../common/queryKeys'
import { fetchPurposesV2 } from '../fetchers/fetchPurposesV2'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.purposesV2,
  queryFn: fetchPurposesV2,
  select: res => res?.data?.purposes || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const usePurposesV2 = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
