import { Auth0ConfigDTO } from 'interfaces/auth/authContext'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIRequestParams } from '../../common/utils'

type Params = APIRequestParams<{
  isAdmin?: boolean
}>

const getDomain = () => window.location.hostname

export const fetchAuthConfig = ({ isAdmin }: Params) =>
  API.get<Auth0ConfigDTO>(
    formatRequestString({
      entityUrl: '/api/auth/config',
      params: {
        filters: {
          isAdmin,
          domain: getDomain(),
        },
      },
    }),
  )
