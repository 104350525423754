import { useCallback } from 'react'
import { FormFieldDTO, FormTemplateSectionDTO, FormTemplateSectionTranslationDTO } from '@ketch-com/figurehead'
import { LanguageDTO } from 'interfaces/languages/language'
import { getPreviewConfigurationExperienceFormFieldDTOfromFormFieldDTO } from '../components/TemplateSectionConfigSidePanel/components/FormTemplateSection/components/FormFields/utils'

export type GenerateFormTemplateSectionDTOArgs = {
  enabledLanguages?: LanguageDTO[]
  formFields?: FormFieldDTO[]
}

export const useGenerateDefaultFormTemplateSection = () => {
  const generateDefaultFormTemplateSectionDTO = useCallback(
    ({ enabledLanguages, formFields }: GenerateFormTemplateSectionDTOArgs): FormTemplateSectionDTO => {
      const email = formFields?.find?.(field => field.code === 'email')!
      const firstName = formFields?.find?.(field => field.code === 'firstName')!
      const lastName = formFields?.find?.(field => field.code === 'lastName')!

      const defaultFormFields = [
        getPreviewConfigurationExperienceFormFieldDTOfromFormFieldDTO(firstName),
        getPreviewConfigurationExperienceFormFieldDTOfromFormFieldDTO(lastName),
        getPreviewConfigurationExperienceFormFieldDTOfromFormFieldDTO(email),
      ]

      // Create a translations object with a key for each enabled language
      const translations = (enabledLanguages || [])?.reduce(
        (acc, curr) => {
          acc[curr.code] = {
            description: '',
            title: '',
          }
          return acc
        },
        {} as Record<string, FormTemplateSectionTranslationDTO>,
      )

      const payload = {
        description: '',
        formFields: defaultFormFields,
        title: '',
        translations,
      }

      return payload
    },
    [],
  )

  return generateDefaultFormTemplateSectionDTO
}
