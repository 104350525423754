import { Box, FormControlLabel, RadioGroup, Typography } from '@mui/material'
import { ContextVariableDTO } from '@ketch-com/figurehead'
import React from 'react'
import { FormDataInputValue } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'
import { Radio } from '@ketch-com/deck'

type Props = {
  contextVariable: ContextVariableDTO
  formValue: FormDataInputValue
  setFormValue: (v: FormDataInputValue) => void
}
export const ContextVariableListValueInput: React.FC<Props> = ({ contextVariable, formValue, setFormValue }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography mb={0.5} variant="smallLabel">
        New Value
      </Typography>
      <RadioGroup value={formValue} onChange={(e, value) => setFormValue(value)}>
        {contextVariable.values?.map((v, index) => {
          return <FormControlLabel key={index} value={v} control={<Radio />} label={v} />
        })}
      </RadioGroup>
    </Box>
  )
}
