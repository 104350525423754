import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { excludeDataReviewFile } from 'api/dataReview/fetchers/excludeDataReviewFile'

const useCustomMutation = createUseMutation({
  mutationFn: excludeDataReviewFile,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useExcludeDataReviewFile = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation)
}
