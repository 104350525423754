import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { useAssetParents } from '../../hooks'

type Args = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export type Breadcrumb = {
  title?: string
  link?: string
}

export const useAssetDetailBreadcrumbs = ({ assetSummary }: Args) => {
  const assetParents = useAssetParents({ assetSummary })

  const payload: Breadcrumb[] = [
    { title: 'Data Map', link: RoutesManager.assetManager.assets.root.getURL() },
    { title: 'Assets', link: RoutesManager.assetManager.assets.list.getURL() },
  ]

  for (const assetParent of assetParents) {
    const parentId = assetSummary?.asset?.resource?.hierarchies?.[assetParent?.resourceTypeCode]?.id || ''
    payload.push({
      title: assetParent.name || '{missing_name}',
      link: RoutesManager.assetManager.assets.detail.root.getURL({
        code: parentId,
        resourceTypeCode: assetParent?.resourceTypeCode || '',
      }),
    })
  }

  payload.push({ title: assetSummary?.asset?.resource?.name || assetSummary?.asset?.resource?.id || '' })

  return payload
}
