import { makeStyles, createStyles } from '@mui/styles'

export const useUpsertPreferenceStyles = makeStyles(
  theme =>
    createStyles({
      wrapper: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      preview: {
        width: '100%',
        height: '100%',
        maxWidth: 846,
        maxHeight: 922,
        borderRadius: 3,
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 5px 24px rgba(0, 0, 0, 0.65)',
      },
      header: {
        padding: '24px 72px 12px 72px',
        backgroundColor: theme.palette.darkDusk.main,
        color: theme.palette.white.main,
      },
      titleWrapper: {
        marginBottom: 44,
      },
      tabs: {
        display: 'flex',
        margin: 0,
        padding: '0 0 17px 0',
        borderBottom: '1px solid rgba(255, 255, 255, 0.35)',
        listStyle: 'none',
      },
      tab: {
        marginRight: 28,
        textAlign: 'center',

        '&:last-child': {
          marginRight: 0,
        },
      },
      active: {
        position: 'relative',

        '&::after': {
          bottom: -18,
          left: 0,
          height: 2,
          content: '""',
          position: 'absolute',
          width: '100%',
          backgroundColor: theme.palette.sphere.main,
        },
      },
      body: {
        flex: 1,
        overflowY: 'auto',
        padding: '16px 24px',
      },
      textWrapper: {
        padding: '20px 48px 16px 48px',
      },
      bodyTitle: {
        marginBottom: 17,
      },
      bodyDescription: {
        whiteSpace: 'pre-line',
        overflowWrap: 'break-word',
      },
      footer: {
        padding: '0 72px 32px 72px',
      },
      footerContent: {
        paddingTop: 24,
        borderTop: '1px solid rgba(1, 9, 14, 0.1)',
      },
      acceptButton: {
        border: `1px solid ${theme.palette.sphere.main} !important`,
        backgroundColor: `${theme.palette.sphere.main} !important`,
      },
      denyButton: {
        border: `1px solid ${theme.palette.sphere.main} !important`,
      },
      placeholder: {
        marginTop: 48,
        height: 156,
      },
      subscriptionsPlaceholder: {
        marginTop: 48,
        height: '156px !important',
      },
      translationsTitle: {
        display: 'block',
        marginBottom: 20,
      },
    }),
  { name: 'UpsertPreference' },
)
