import { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, List, Typography } from '@mui/material'
import { FindMapDataResponseBodyDTO } from '@ketch-com/figurehead'
import { Button, Chip, Icon, Tab, TabGroup } from '@ketch-com/deck'

const PREFIX = 'JurisdictionDetails'

const classes = {
  jurisdictionsDetailContainer: `${PREFIX}-jurisdictionsDetailContainer`,
  jurisdictionsDetail: `${PREFIX}-jurisdictionsDetail`,
  jurisdictionsHeader: `${PREFIX}-jurisdictionsHeader`,
  jurisdictionsTitle: `${PREFIX}-jurisdictionsTitle`,
  jurisdictionsName: `${PREFIX}-jurisdictionsName`,
  regulationNameBox: `${PREFIX}-regulationNameBox`,
  jurisdictionsInsights: `${PREFIX}-jurisdictionsInsights`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.jurisdictionsDetailContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  },

  [`& .${classes.jurisdictionsDetail}`]: {
    boxShadow: '-18px 0px 35px rgba(7, 26, 36, 0.1)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  [`& .${classes.jurisdictionsHeader}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 19px',
  },

  [`& .${classes.jurisdictionsTitle}`]: {
    color: 'rgba(7, 26, 36, 0.5)',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
    letterSpacing: '0.01em',
  },

  [`& .${classes.jurisdictionsName}`]: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#071A24',
    lineHeight: '28px',
    letterSpacing: '0.02em',
  },

  [`& .${classes.regulationNameBox}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '12px',
  },

  [`& .${classes.jurisdictionsInsights}`]: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '200px',
    flexGrow: '2',
    flexShrink: '2',
  },
}))

interface Props {
  visualizationData: FindMapDataResponseBodyDTO
  updateSelectedJurisdiction?: (jurisdiction: string) => void
  selectedJurisdiction?: string
}

interface DataResidencies {
  code?: string
  name?: string
}

enum JurisdictionInsight {
  RIGHTS = 'rights',
  REGIONS = 'regions',
  DATATRANSFERS = 'data transfers',
}

export const JurisdictionDetails: React.FC<Props> = ({
  visualizationData,
  updateSelectedJurisdiction,
  selectedJurisdiction,
}) => {
  const { jurisdictions, dataResidencies } = visualizationData

  const jurisdictionData = jurisdictions?.filter(data => data.code === selectedJurisdiction)[0]
  const residencies = dataResidencies as unknown as DataResidencies[]
  const jurisdictionName = jurisdictionData?.name
  const regulationNames = jurisdictionData?.regulationNames
  const rightsFulfillment = jurisdictionData?.rightsFulfilment
  const regions = jurisdictionData?.regions
  const hasRights = rightsFulfillment?.length
  const hasRegions = regions?.length
  const hasDataTransfers = residencies?.length
  const initialState = hasRights
    ? JurisdictionInsight.RIGHTS
    : hasRegions
    ? JurisdictionInsight.REGIONS
    : hasDataTransfers
    ? JurisdictionInsight.DATATRANSFERS
    : ''
  const [jurisdictionInsight, setJurisdictionInsight] = useState(initialState)
  const tabConfig = []
  let listItems

  const [selectedTabValue, setSelectedTabValue] = useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabValue(newValue)
    switch (newValue) {
      case 0:
        setJurisdictionInsight(JurisdictionInsight.RIGHTS)
        break
      case 1:
        setJurisdictionInsight(JurisdictionInsight.REGIONS)
        break
      case 2:
        setJurisdictionInsight(JurisdictionInsight.DATATRANSFERS)
        break
    }
  }

  if (hasRights) {
    tabConfig.push({
      key: 'rights',
      active: jurisdictionInsight === JurisdictionInsight.RIGHTS,
      label: 'Rights',
    })

    if (jurisdictionInsight === JurisdictionInsight.RIGHTS) {
      listItems = rightsFulfillment.map(elem => {
        return (
          <Box
            {...elem}
            sx={{
              padding: '9px 18px',
              borderBottom: theme => `1px solid ${theme.palette.iron.main}`,
            }}
          >
            <Typography>{elem?.rightName}</Typography>
          </Box>
        )
      })
    }
  }

  if (hasRegions) {
    tabConfig.push({
      key: 'regions',
      active: jurisdictionInsight === JurisdictionInsight.REGIONS,
      label: 'Regions',
    })

    if (jurisdictionInsight === JurisdictionInsight.REGIONS) {
      listItems = regions.map(elem => {
        return (
          <Box
            {...elem}
            sx={{
              padding: '9px 18px',
              borderBottom: theme => `1px solid ${theme.palette.iron.main}`,
            }}
          >
            <Typography>{elem?.name}</Typography>
          </Box>
        )
      })
    }
  }

  if (hasDataTransfers) {
    tabConfig.push({
      key: 'dataTransfers',
      active: jurisdictionInsight === JurisdictionInsight.DATATRANSFERS,
      label: 'Data Transfers',
    })

    if (jurisdictionInsight === JurisdictionInsight.DATATRANSFERS) {
      listItems = residencies.map(elem => {
        return (
          <Box
            {...elem}
            sx={{
              padding: '9px 18px',
              borderBottom: theme => `1px solid ${theme.palette.iron.main}`,
            }}
          >
            <Typography>{elem?.name}</Typography>
          </Box>
        )
      })
    }
  }

  const handleClose = () => {
    updateSelectedJurisdiction?.('')
  }

  return (
    <StyledBox className={classes.jurisdictionsDetailContainer}>
      <Button
        onClick={() => handleClose()}
        sx={{
          position: 'absolute',
          top: '20px',
          left: '-40px',
        }}
        variant="iconCustomRounded"
        color="tertiary"
      >
        <Icon name="OCross" />
      </Button>
      <Box className={classes.jurisdictionsDetail}>
        <Box className={classes.jurisdictionsHeader}>
          <Typography variant="smallLabel" color="darkGrey.main">
            Jurisdiction
          </Typography>
          <Typography variant="h3">{jurisdictionName}</Typography>

          {regulationNames?.length ? (
            <Box className={classes.regulationNameBox}>
              {regulationNames.map((regulationName, index) => (
                <Chip key={index} size="small" label={regulationName} />
              ))}
            </Box>
          ) : null}
        </Box>
        {hasRights || hasRegions || hasDataTransfers ? (
          <Box>
            <TabGroup sx={{ marginLeft: 1 }} value={selectedTabValue} onChange={handleTabChange} showBottomDivider>
              {tabConfig.map((elem, index) => (
                <Tab {...elem} />
              ))}
            </TabGroup>
            <List>{listItems}</List>
          </Box>
        ) : null}
      </Box>
    </StyledBox>
  )
}
