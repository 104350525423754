import { DeploymentPlanDTO, UpsertDeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  formData: UpsertDeploymentPlanDTO
}

type Response = { deployment: DeploymentPlanDTO }

export const createDeploymentPlan = ({ organizationCode, formData }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/deployments`,
    }),
    formData,
  )
