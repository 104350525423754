import { get, isEqual, isUndefined } from 'lodash'

import { ICanvasStep, IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { getTransitionsFromStep } from 'pages/orchestration/workflows/edit/utils/steps/transitionsFromStep'
import { omitDisconnectedSteps } from 'pages/orchestration/workflows/edit/utils/steps/omitDisconnectedSteps'
import { removeStep } from 'pages/orchestration/workflows/edit/utils/steps/removeStep'
import { createConnectedNest } from './createConnectedNest'
import { isNewStepConnectedNestStart } from './isNewStepConnectedNestStart'
import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { createPlaceholderStep } from './createPlaceholder'
import { getNextJoinTile } from './getNextJoinTile'
import { isTileInConnectedNest } from './isTileInConnectedNest'
import { getTransitionToStep } from './transitionsToStep'
import { getStepType } from './getStepType'
import { CanvasStepType } from '../constants'

export interface IReplaceStepParams {
  workflow?: IWorkflowConfig
  step: ICanvasStep
  stepToCreate: ICanvasStep
  standardJoinActivity: ICanvasStep
  pathId?: string
}

export interface IReplaceStep {
  newSteps: Array<ICanvasStep>
  newStep: ICanvasStep
}

export const replaceStep = ({
  workflow,
  step,
  stepToCreate,
  standardJoinActivity,
  pathId,
}: IReplaceStepParams): IReplaceStep => {
  const steps = workflow?.steps || []

  const isNewStepActivity = !isUndefined(stepToCreate?.activity)
  const isNewStepWorkflow = !isUndefined(stepToCreate?.workflow)
  const isNewStepGateway = !isUndefined(stepToCreate?.gateway)
  const isNewStepDecisionBoolean = stepToCreate?.gateway?.mode === WorkflowActivityGatewayMode.DECISION_BOOLEAN
  const isNewStepPlaceholder = !isNewStepActivity && !isNewStepWorkflow && !isNewStepGateway
  const isCurrentStepGateway = !isUndefined(step?.gateway)
  const isNewStepConnectedNest = isNewStepConnectedNestStart({ step: stepToCreate, steps })
  const isNewStepInConnectedNest = isTileInConnectedNest({ step, steps })

  const currentStepParents = getTransitionToStep({ step, steps })
  const parentIsDecisionBoolean = currentStepParents.some(
    parent =>
      getStepType(parent) === CanvasStepType.Gateway &&
      parent.gateway!.mode === WorkflowActivityGatewayMode.DECISION_BOOLEAN,
  )
  const currentStepTransitions = getTransitionsFromStep({ step, steps })
  const currentStepTransitionId = get(currentStepTransitions, `[0].ID`)

  let newStep: ICanvasStep = {}
  const updatedSteps: Array<ICanvasStep> = []

  // Found out the hard way through production issues why removeStep routes through the replaceStep process
  // In cases where there is a following tile, replace the tile with an empty object (stepToCreate)
  // If there is no following tile or a gateway needs the tile, remove the tile entirely
  if (isEqual(stepToCreate, {}) && (isCurrentStepGateway || !currentStepTransitionId) && !parentIsDecisionBoolean) {
    const { newSteps } = removeStep({ step, workflow, pathId })

    return {
      newSteps,
      newStep,
    }
  }

  steps.forEach(workflowStep => {
    const { ID } = workflowStep

    if (ID === step.ID) {
      newStep.ID = ID
      newStep.name = stepToCreate.name
      newStep.code = stepToCreate.code
      newStep.iconCode = stepToCreate.iconCode
      newStep.iconURL = stepToCreate.iconURL
      newStep.activityCode = stepToCreate.activityCode
      newStep.description = stepToCreate.description

      if (isNewStepPlaceholder) {
        newStep.valid = false
        newStep.placeholder = {
          transition: currentStepTransitionId,
        }
      }

      if (isNewStepActivity) {
        newStep.activity = {
          ...stepToCreate.activity,
          transition: currentStepTransitionId,
        } as ICanvasStep['activity']
      }

      if (isNewStepWorkflow) {
        newStep.workflow = {
          ...stepToCreate.workflow,
          transition: currentStepTransitionId,
        } as ICanvasStep['workflow']
      }

      if (isNewStepGateway) {
        const nextStep = steps.find(({ ID }) => ID === currentStepTransitionId)

        // Create the connected nest in its entirety and add it
        if (isNewStepConnectedNest) {
          const { gatewayStep, placeholderA, placeholderB, joinStep } = createConnectedNest({
            stepToCreate: newStep,
            mode: stepToCreate.gateway?.mode!,
            standardJoinActivity,
            nextStepTransitionId: currentStepTransitionId,
            steps: workflow?.steps || [],
          })
          newStep = { ...newStep, ...gatewayStep }
          updatedSteps.push(placeholderA)
          updatedSteps.push(placeholderB)
          if (joinStep) updatedSteps.push(joinStep)

          // Decision boolean should get placeholders added after when dropped before a join
        } else if (isNewStepDecisionBoolean && isNewStepInConnectedNest) {
          const isNextStepJoin = nextStep?.gateway?.mode === WorkflowActivityGatewayMode.JOIN
          const nextJoinTile = isNextStepJoin ? undefined : getNextJoinTile({ step, steps })

          const placeholderA = createPlaceholderStep(currentStepTransitionId)
          const placeholderB = createPlaceholderStep(isNextStepJoin ? currentStepTransitionId : nextJoinTile?.ID)

          newStep.gateway = {
            ...stepToCreate.gateway,
            transitions: [placeholderA, placeholderB],
          } as ICanvasStep['gateway']

          updatedSteps.push(placeholderA)
          updatedSteps.push(placeholderB)
          // Code path for most gateways - map the transitions accordingly
        } else {
          newStep.gateway = {
            ...stepToCreate.gateway,
            transitions: stepToCreate?.gateway?.transitions.map((transition, index) => ({
              ...transition,
              ID: !index ? currentStepTransitionId : transition.ID,
            })),
          } as ICanvasStep['gateway']
        }
      }

      updatedSteps.push(newStep)
    } else {
      updatedSteps.push(workflowStep)
    }
  })

  const newSteps = omitDisconnectedSteps({ steps: updatedSteps })

  return {
    newSteps,
    newStep,
  }
}
