import { policyEffectStrings } from 'pages/assetManager/constants'

export const getPolicyEffectDisplayTextColor = (effect: string) => {
  switch (effect) {
    case policyEffectStrings.MASK:
      return 'heliotrope'
    case policyEffectStrings.DENY:
      return 'red'
    case policyEffectStrings.HASH:
      return 'heliotrope'
    default:
      return 'black'
  }
}
