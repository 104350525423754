import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import Box from '@mui/material/Box'

import { SectionTitle } from 'components/ui-kit/typography/compositions/SectionTitle'
import { SubSectionTitle } from 'components/ui-kit/typography/compositions/SubSectionTitle'

import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'
import { Table } from 'components/ui-layouts/table/Table'
import Avatar from '@mui/material/Avatar'
import { HookChipCellRenderer } from '..'
import { KETCH_DML } from 'pages/developers/hooks/constants'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateCommon.svg'

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      sectionTitle: {
        marginBottom: spacing(0.5),
      },
      subSectionTitle: {
        color: palette.lightGrey.main,
        marginBottom: spacing(3.5),
      },
      hookChipCell: {
        padding: 0,
      },
      avatar: {
        backgroundColor: palette.fadedGrey.main,
        height: 24,
        width: 24,
      },
    }),
  { name: 'AssetHooksTab' },
)

export const AssetHooksTab: React.FC<Props> = ({ assetSummary }) => {
  const classes = useStyles()

  const assignedHooksCount =
    [...(assetSummary?.asset?.dmlPreHooks || []), ...(assetSummary?.asset?.dmlPostHooks || [])]?.length || 0

  const hooksTableData = [
    ...(assetSummary?.asset?.dmlPreHooks || []),
    {
      assignmentCount: 9999,
      dmlCode: 'dummy',
      id: 'dummy',
      name: KETCH_DML,
      operation: 99999,
      technology: 'dummy',
      type: 99,
    },
    ...(assetSummary?.asset?.dmlPostHooks || []),
  ]

  return (
    <>
      <Box display="flex" flexDirection="column">
        <SectionTitle className={classes.sectionTitle}>Hooks</SectionTitle>
        <SubSectionTitle className={classes.subSectionTitle}>{assignedHooksCount} hooks assigned</SubSectionTitle>
      </Box>

      {assignedHooksCount ? (
        <Table
          pending={false}
          items={!!assignedHooksCount ? hooksTableData : []}
          hideHeader
          cellSettings={{
            name: {
              width: 60,
              cellRenderer: (hook, rowIndex) => (
                <Avatar className={classes.avatar}>
                  <Text size={14}>{rowIndex + 1}</Text>
                </Avatar>
              ),
            },
            type: {
              className: classes.hookChipCell,
              cellRenderer: hook => <HookChipCellRenderer hook={hook} />,
            },
          }}
        />
      ) : (
        <EmptyState
          variant="page"
          bgVariant="grey"
          icon={<EmptyStateIcon />}
          title="No hooks have been assigned to this asset"
        />
      )}
    </>
  )
}
