import { useWebhook } from 'api/webhooks/queries/useWebhook'
import { useUsers } from 'api/users/queries/useUsers'
import { useWebhookContext } from 'api/executions/queries/useWebhookContext'
import { useUpdateWebhookContext } from 'api/executions/mutations/useUpdateWebhookContext'
import { showToast } from 'components/modals/AlertComponent'
import {
  SubmitTimelineChangeProps,
  WebhooksContextDetailsStatus,
  WebhooksContextTimelineItemStatus,
} from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'
import { useUpdateWorkflowExecution } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecution'
import { useContext } from 'react'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { GetWebhooksContextResponseBodyDTO, WebhooksContextTimelineDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { useCompleteWorkflowExecutionStepV2 } from 'api/workflowExecutions/mutations/useCompleteWorkflowExecutionStepV2'

/**
 * Count the unique keys within the specified 'key' field in a timeline.
 * This function will not double count recurring keys.
 *
 * @param {object} params - The parameters.
 * @param {'identities' | 'contextVariables' | 'formData' | 'outcomeVariables'} params.key - The key field to count the keys of.
 * @param {GetWebhooksContextResponseBodyDTO} params.webhookContext - The webhook context.
 * @returns {number} - The count of unique keys within the specified 'key' field in the timeline.
 */
const countReceivedParameters = ({
  key,
  webhookContext,
}: {
  key: 'identities' | 'contextVariables' | 'formData' | 'outcomeVariables'
  webhookContext: GetWebhooksContextResponseBodyDTO
}): number => {
  const countObj: Record<string, number> = {}
  const timeline = webhookContext?.timeline || []

  timeline?.forEach?.((timelineItem: WebhooksContextTimelineDTO) => {
    /* data is an object of key/value pairs for all form fields except checkboxes */
    if (timelineItem.status === 3 && timelineItem[key]?.data && !Array.isArray(timelineItem[key]?.data)) {
      const keys = Object.keys(timelineItem[key]?.data || {})
      for (const key of keys) {
        countObj[key] = (countObj[key] || 0) + 1
      }
    }
    /* special case to handle checkbox form fields because data is an array for checkboxes */
    if (timelineItem.status === 3 && timelineItem[key]?.data && Array.isArray(timelineItem[key]?.data)) {
      const keys = timelineItem?.[key]?.data?.map?.((item: any) => item?.code)
      for (const key of keys) {
        countObj[key] = (countObj[key] || 0) + 1
      }
    }
  })

  const payload = Object.keys(countObj).length

  return payload
}

export const useApiCallContainerUtils = () => {
  const queryClient = useQueryClient()
  const {
    workflowExecutionId,
    workflowStep,
    isReady: isRightsQueueWorkflowTaskDetailViewContainerUtilsReady,
    stepId,
    refetchWorkflowStep,
  } = useContext(RightInvocationWorkflowStepDetailViewContext)

  /* fetch data */

  const { data: webhookContext, isLoading: isWebhookContextLoading } = useWebhookContext({
    params: { workflowExecutionId, stepId },
    enabled: !!workflowExecutionId,
    onError: () => {
      showToast({ content: 'Failed to fetch API call details', type: 'error' })
    },
  })

  const { data: webhook, isLoading: isWebhookLoading } = useWebhook({
    params: { webhookId: webhookContext?.webhook?.id },
    enabled: !!webhookContext?.webhook?.id,
  })

  const { data: users, isLoading: isUsersLoading } = useUsers({
    params: {
      active: true,
    },
  })

  /* Derived state */

  // timeline is in reverse chronological order
  const mostRecentTimelineItem = webhookContext?.timeline?.[0]

  const error =
    mostRecentTimelineItem?.status === WebhooksContextTimelineItemStatus.ERROR ? mostRecentTimelineItem : undefined

  const isWebHookErrored = error?.webhookDetails?.status === WebhooksContextDetailsStatus.WEBHOOK
  const isCallbackErrored = error?.webhookDetails?.status === WebhooksContextDetailsStatus.CALLBACK

  const timelineIssues = (webhookContext?.timeline || []).reduce(
    (prev, curr) => {
      const { status, contextVariables, identities, formData, outcomeVariables } = curr || {}
      const { INVALID, UNKNOWN } = WebhooksContextTimelineItemStatus

      if (status === INVALID) {
        if (contextVariables?.isActionEnabled) prev.invalidDataSubjectVariables += 1
        if (outcomeVariables?.isActionEnabled) prev.invalidOutcomeVariables += 1
        if (formData?.isActionEnabled) prev.invalidFormData += 1
      }

      if (status === UNKNOWN) {
        if (contextVariables?.isActionEnabled) prev.unknownDataSubjectVariables += 1
        if (outcomeVariables?.isActionEnabled) prev.unknownOutcomeVariables += 1
        if (identities?.isActionEnabled) prev.unknownIdentities += 1
        if (formData?.isActionEnabled) prev.unknownFormData += 1
      }

      return prev
    },
    {
      invalidFormData: 0,
      unknownFormData: 0,

      invalidDataSubjectVariables: 0,
      unknownDataSubjectVariables: 0,

      invalidOutcomeVariables: 0,
      unknownOutcomeVariables: 0,

      unknownIdentities: 0,
    },
  )

  const timelineIssuesCount =
    timelineIssues.invalidDataSubjectVariables +
    timelineIssues.unknownDataSubjectVariables +
    timelineIssues.unknownIdentities +
    timelineIssues.invalidFormData +
    timelineIssues.unknownFormData +
    timelineIssues.invalidOutcomeVariables +
    timelineIssues.unknownOutcomeVariables

  const identitiesCount = countReceivedParameters({
    key: 'identities',
    webhookContext,
  })

  const outcomeVariablesCount = countReceivedParameters({
    key: 'outcomeVariables',
    webhookContext,
  })

  const dataSubjectVariablesCount = countReceivedParameters({
    key: 'contextVariables',
    webhookContext,
  })

  const formDataCount = countReceivedParameters({
    key: 'formData',
    webhookContext,
  })

  /* mutations */

  const { mutateAsync: resolveWorkflowStep } = useUpdateWorkflowExecution({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.workflowExecutionStepDetailsV2, { workflowExecutionId, stepId }])
      showToast({ content: 'Request to resolve workflow step recieved', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to resolve workflow step', type: 'error' })
    },
  })

  const { mutateAsync: completeWorkflowStep } = useCompleteWorkflowExecutionStepV2({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.workflowExecutionStepDetailsV2, { workflowExecutionId, stepId }])
      showToast({ content: 'Request to resolve workflow step recieved', type: 'success' })
    },
  })

  const { mutateAsync: submitWebhookContext, isLoading: isSubmittingWebhookContext } = useUpdateWebhookContext({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.workflowExecutionStepDetailsV2, { workflowExecutionId, stepId }])
      await queryClient.refetchQueries([ApiQueryKeys.webhookContext, { workflowExecutionId, stepId }])
      showToast({ content: 'Successfully updated API call', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update API call', type: 'error' })
    },
  })

  /* helper functions */

  const submitTimelineChange = async ({
    timelineItem,
    newTimelineItem,
    timelineItemToReplace,
  }: SubmitTimelineChangeProps) => {
    const timeline = (webhookContext?.timeline || []).map(timelineEntry => {
      if (Object.is(timelineItem, timelineEntry)) return timelineItemToReplace
      return timelineEntry
    })

    const formData = {
      timeline,
      ...newTimelineItem,
    }

    try {
      await submitWebhookContext({ params: { formData, workflowExecutionId, stepId } })
      return true
    } catch {
      showToast({ content: 'Failed to update Timeline Item', type: 'error' })
      return false
    }
  }

  const submitNotes = async (notes: string, onSubmitCallback?: () => void) => {
    const formData = { notes, timeline: webhookContext?.timeline || [] }
    await submitWebhookContext({ params: { formData, workflowExecutionId, stepId } })
    onSubmitCallback?.()
  }

  const resolve = async () => {
    if (timelineIssuesCount > 0) {
      showToast({
        content: 'Please resolve all issues before resolving this step',
        type: 'error',
      })
      return
    }

    const shouldComplete = workflowStep?.status === WorkflowExecutionStepStatus.FAIL && timelineIssuesCount === 0

    if (shouldComplete) {
      // completeWorkflowStep api is how to clear a workflow step out of ManualInterventionActivity state (which happens when
      // the step is in Fail (error) status)
      await completeWorkflowStep({
        params: {
          workflowExecutionId: workflowExecutionId || '',
          stepId: workflowStep.ID,
        },
      })
    } else {
      // resolveWorkflowStep api is how to interact with the running API Call child workflow (send Temporal signal)
      await resolveWorkflowStep({
        params: {
          formData: {
            temporalWorkflowID: `${workflowExecutionId}${workflowStep?.ID}`,
            signalName: 'event_forwarder_resolve',
            signalValue: {
              status: 'completed',
              // shouldResendToWebhook will be true if the current error allows retry
              shouldResendToWebhook: isWebHookErrored,
            },
          },
        },
      })
    }

    await refetchWorkflowStep()
  }

  const isReady =
    !!isRightsQueueWorkflowTaskDetailViewContainerUtilsReady &&
    !isWebhookLoading &&
    !isUsersLoading &&
    !isWebhookContextLoading

  const payload = {
    dataSubjectVariablesCount,
    error,
    formDataCount,
    identitiesCount,
    isCallbackErrored,
    isReady,
    isSubmittingWebhookContext,
    isWebHookErrored,
    outcomeVariablesCount,
    resolve,
    submitNotes,
    submitTimelineChange,
    timelineIssues,
    timelineIssuesCount,
    users,
    webhook,
    webhookContext,
  }

  return payload
}

export type UseApiCallContainerUtils = ReturnType<typeof useApiCallContainerUtils>
