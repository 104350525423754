import React, { useContext, useState } from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import {
  OrgPlanIDs,
  OrgPlanMaxUniquesText,
  OrgPlanCosts,
  OrgPlanNames,
  StripeRedirectURL,
  StripeInvokeAction,
  StripeInvokeActionResponse,
} from 'components/planAndBilling/common/constants/planAndBilling'
import { Button, Icon, theme, Status, StatusVariant, StatusState } from '@ketch-com/deck'
import { FreeDowngradeModal } from 'components/planAndBilling/components/planDetails/FreeDowngradeModal'
import { FreeDowngradeResolveBillingModal } from 'components/planAndBilling/components/planDetails/FreeDowngradeResolveBillingModal'
import { BillingStatus } from '@ketch-com/harbormaster/dist/organization/plans.schemas'

const freePlanOptions = [
  {
    title: OrgPlanMaxUniquesText.free,
    tooltip:
      'The number of unique visitors who will see the Ketch privacy experience on your website or mobile app each month.',
  },
  {
    title: 'Consent Management Platform (CMP)',
    tooltip:
      'Includes unlimited policy templates for privacy laws, customizable privacy experiences, website tracker scanning and blocking, and consent record storage.',
  },
  {
    title: '2 Integrations',
    tooltip:
      'Pass visitor consent signals to (2) business systems selected from our Integration Library (including Google Analytics, HubSpot, MailChimp, and more).',
  },
  {
    title: 'Email Support',
    tooltip:
      'Limited assistance from the Ketch Customer Experience Team to configure the platform and understand its core functionality.',
  },
]

export const Free = () => {
  const [shouldDisplayDowngradeModal, setShouldDisplayDowngradeModal] = useState(false)
  const [shouldDisplayDowngradeResolveBillingModal, setShouldDisplayDowngradeResolveBillingModal] = useState(false)
  const { orgPlan, createSubscription, isCreatingBillingPortal, isCreatingSubscription, createBillingPortal } =
    useContext(PlanAndBillingContext)
  const isFreePlanActive = orgPlan?.planProductID === OrgPlanIDs?.free
  const hasBillingIssues = orgPlan?.billingStatus !== BillingStatus.ok

  return (
    <>
      <Box
        flexBasis={0}
        flexGrow={1}
        pt={2}
        pb={4}
        sx={{
          border: `1px solid ${theme.palette.Common.Divider}`,
          borderRadius: 3,
        }}
      >
        <Box px={2}>
          <Box mb={2} display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="h3">{OrgPlanNames.free}</Typography>
            <Box display="flex" gap={0.5}>
              <Typography noWrap variant="h4">
                ${OrgPlanCosts.free}
              </Typography>
              <Typography variant="h4" color={theme.palette.Text.Secondary}>
                /Month
              </Typography>
            </Box>
          </Box>
          <br />
          <Box mb={2}>
            <Typography variant="body">Limited number of unique users per month.</Typography>
          </Box>

          {isFreePlanActive ? (
            <Box
              sx={{
                '& > div': {
                  display: 'flex',
                  padding: '9px 8px',
                  fontSize: '14px',
                  width: '100%',
                },
              }}
            >
              <Status
                icon="FCheckRound"
                label="You are on Free"
                variant={StatusVariant.outlined}
                status={StatusState.draft}
              />
            </Box>
          ) : (
            <Button
              disabled={isCreatingSubscription}
              fullWidth
              color="tertiary"
              size="large"
              onClick={() => {
                if (hasBillingIssues) {
                  setShouldDisplayDowngradeResolveBillingModal(true)
                } else {
                  setShouldDisplayDowngradeModal(true)
                }
              }}
            >
              Downgrade
            </Button>
          )}
        </Box>

        <Box
          mt={2.5}
          pt={0.5}
          px={2}
          display="flex"
          flexDirection="column"
          sx={{
            borderTop: `1px solid ${theme.palette.Common.Divider}`,
          }}
        >
          {freePlanOptions.map((planOption, index) => (
            <Box key={index} py="6px" display="flex" justifyContent="space-between" alignItems="center" gap={0.5}>
              <Typography variant={index === 0 ? 'label' : 'body'} pr={!planOption.tooltip ? 3 : 0}>
                {planOption.title}
              </Typography>
              {planOption.tooltip && (
                <Tooltip title={planOption.tooltip} placement="bottom">
                  <Box display="inline-flex">
                    <Icon name="OInfo" iconColor={theme.palette.Black.o32} />
                  </Box>
                </Tooltip>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <FreeDowngradeModal
        isOpen={shouldDisplayDowngradeModal}
        isLoading={isCreatingSubscription}
        onClose={() => setShouldDisplayDowngradeModal(false)}
        onSubmit={() => {
          createSubscription({
            params: {
              reqBody: {
                price: {
                  productCode: OrgPlanIDs.free,
                },
                successURL: (() => {
                  const url = new URL(StripeRedirectURL)
                  url.searchParams.set('action', StripeInvokeAction.PlanDowngrade)
                  url.searchParams.set('status', StripeInvokeActionResponse.Success)
                  return url.toString()
                })(),
                cancelURL: (() => {
                  const url = new URL(StripeRedirectURL)
                  url.searchParams.set('action', StripeInvokeAction.PlanDowngrade)
                  url.searchParams.set('status', StripeInvokeActionResponse.Cancel)
                  return url.toString()
                })(),
              },
            },
          })
        }}
      />
      <FreeDowngradeResolveBillingModal
        isOpen={shouldDisplayDowngradeResolveBillingModal}
        isLoading={isCreatingBillingPortal}
        onClose={() => setShouldDisplayDowngradeResolveBillingModal(false)}
        onSubmit={() => {
          createBillingPortal({
            params: {
              reqBody: {
                returnURL: (() => {
                  const url = new URL(StripeRedirectURL)
                  url.searchParams.set('action', StripeInvokeAction.BillingFailed)
                  return url.toString()
                })(),
              },
            },
          })
        }}
      />
    </>
  )
}
