import React from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { useWorkflowExecutionStepDetails } from 'api/workflowExecutions/queries/useWorkflowExecutionStepDetails'
import { useCompleteWorkflowExecutionStep } from 'api/workflowExecutions/mutations/useCompleteWorkflowExecutionStep'
import { useFilesInfo } from 'api/files/queries/useFilesInfo'
import { showToast } from 'components/modals/AlertComponent'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionAutomatedStepCompleteFormValues, mapValuesToPayload } from './utils'
import { WorkflowExecutionAutomatedStepCompleteModal } from './WorkflowExecutionAutomatedStepCompleteModal'

type Props = {
  rightInvocation: RightInvocationDTO
  workflowExecutionStep: WorkflowExecutionStepDTO
  onClose: () => void
}

export const WorkflowExecutionAutomatedStepCompleteModalContainer: React.FC<Props> = ({
  rightInvocation,
  workflowExecutionStep,
  onClose,
}) => {
  const queryClient = useQueryClient()

  const { stepID, workflowExecutionID } = workflowExecutionStep

  const { data: workflowExecutionStepDetails, isLoading: isWorkflowExecutionStepDetailsLoading } =
    useWorkflowExecutionStepDetails({
      params: {
        workflowExecutionId: workflowExecutionID,
        stepId: stepID,
      },
    })

  const resolutionAttachments = workflowExecutionStepDetails?.results?.[stepID]?.resolutionAttachments || []

  const { data: filesInfo, isLoading: isFilesInfoLoading } = useFilesInfo({
    enabled: !!resolutionAttachments.length,
    params: {
      infoUrls: resolutionAttachments,
    },
  })

  const { mutateAsync: handleCompleteWorkflowExecutionStep } = useCompleteWorkflowExecutionStep({
    onSuccess: async () => {
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      showToast({ content: 'Activity manually completed', type: 'success' })
      onClose()
    },
    onError: () => {
      showToast({ content: 'Failed to manually complete activity', type: 'error' })
    },
  })

  const onSubmit = async (values: WorkflowExecutionAutomatedStepCompleteFormValues) => {
    const formData = mapValuesToPayload(values)

    await handleCompleteWorkflowExecutionStep({
      params: {
        workflowExecutionId: workflowExecutionID,
        stepId: stepID,
        formData,
      },
    })

    await queryClient.refetchQueries([
      ApiQueryKeys.workflowExecution,
      {
        workflowExecutionId: workflowExecutionID,
      },
    ])

    await queryClient.refetchQueries([
      ApiQueryKeys.workflowExecutionStepDetails,
      {
        workflowExecutionId: workflowExecutionID,
        stepId: stepID,
      },
    ])
  }

  const isReady = !isWorkflowExecutionStepDetailsLoading && !isFilesInfoLoading

  return (
    <WorkflowExecutionAutomatedStepCompleteModal
      isReady={isReady}
      filesInfo={filesInfo}
      rightInvocation={rightInvocation}
      workflowExecutionStep={workflowExecutionStep}
      workflowExecutionStepDetails={workflowExecutionStepDetails}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  )
}
