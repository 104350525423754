import { useState } from 'react'
import { useDebounce } from 'react-use'
import { useQueryParams } from 'utils/hooks'

export const useDebouncedSearchString = () => {
  const { queries, setQueryParam, removeQueryParam } = useQueryParams<{ search?: string }>()
  const [searchString, setSearchString] = useState<string>(() => queries.search || '')

  useDebounce(
    () => {
      if (searchString) {
        setQueryParam('search', searchString.toLowerCase())
      } else {
        removeQueryParam('search')
      }
    },
    500,
    [searchString],
  )

  return { searchString, setSearchString, queryParamSearch: queries.search }
}
