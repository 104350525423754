import { Box, styled } from '@mui/material'
import React, { useContext } from 'react'
import { ImageEmptyState } from './ImageEmptyState'
import { BuilderImageVariant } from '../../../utils/enums'
import { BuilderContext } from '../../../Builder'

type Props = {
  variant: BuilderImageVariant
  imageUrl?: string
}

const HeaderImageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  height: '64px',
  width: '280px',
}))

const HeroBannerImageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '320px',
  width: '592px',
}))

const NavigationImageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '24px',
  width: '24px',
}))

const SummaryBannerImageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '320px',
  width: '592px',
  maxWidth: '592px',
}))

// Auto-scales to fit container dimensions while still allowing alignment within container
const BuilderImage = styled('img')({
  maxHeight: '100%',
  maxWidth: '100%',
  height: 'auto',
  width: 'auto',
  objectFit: 'contain',
})

const altText = {
  [BuilderImageVariant.Header]: 'header icon',
  [BuilderImageVariant.HeroBanner]: 'hero banner',
  [BuilderImageVariant.Navigation]: 'navigation icon',
  [BuilderImageVariant.SummaryBanner]: 'summary banner',
}

const imageBox = {
  [BuilderImageVariant.Header]: HeaderImageBox,
  [BuilderImageVariant.HeroBanner]: HeroBannerImageBox,
  [BuilderImageVariant.Navigation]: NavigationImageBox,
  [BuilderImageVariant.SummaryBanner]: SummaryBannerImageBox,
}

export const ImageContainer: React.FC<Props> = ({ variant, imageUrl }) => {
  const { isTheme } = useContext(BuilderContext)

  if (!imageUrl) return isTheme ? <ImageEmptyState variant={variant} /> : null

  const ImageBox = imageBox[variant]

  return (
    <ImageBox>
      <BuilderImage src={imageUrl} alt={altText[variant]} />
    </ImageBox>
  )
}
