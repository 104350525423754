import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { Spinner } from 'components/ui-kit/spinner/Spinner'

const useStyles = makeStyles(
  createStyles({
    root: {
      position: 'relative',
      minHeight: '100%',
      width: 1280,
      margin: '0 auto',
      flex: 1,
    },
    loading: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      minHeight: 250,
    },
  }),
  { name: 'ViewListLayout' },
)

type Props = {
  children?: React.ReactNode
  isReady?: boolean
}

export const ViewListLayout: React.FC<Props> = ({ children, isReady = true }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {isReady ? (
        children
      ) : (
        <Flex className={classes.loading} alignItems="center">
          <Spinner padding={50} />
        </Flex>
      )}
    </div>
  )
}
