import { ContentGroup, ListLayout } from '@ketch-com/deck'
import { Box, Divider, styled } from '@mui/material'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'
import { SidebarRouteItem } from 'utils/routing/types'
import { useSidebarRoutesConfig } from 'utils/routing/useSidebarRoutesConfig'
import { ConsentTrendChart } from './components/ConsentTrendChart/ConsentTrendChart'
import { OverviewCard } from 'components/OverviewCard/OverviewCard'
import { useNavigateGated } from 'utils/routing/hooks/useNavigateGated'

const CardsBox = styled(Box)({
  display: 'flex',
  gap: '24px',
})

export const ReportsOverview: React.FC = () => {
  const navigateGated = useNavigateGated()
  const breadcrumbs = [{ title: 'Reports' }]

  // Get reports routes
  const reportsRoutes = useSidebarRoutesConfig().find(route =>
    route.path.includes(RoutesManager.insights.root.getURL()),
  )?.subRoutes as SidebarRouteItem[]

  const handleCardClick = (item: SidebarRouteItem) => {
    navigateGated(
      item.path,
      item.permissions,
      item.entitlements,
      item.requireAllPermissions,
      item.requireAllEntitlements,
    )
  }

  const cards = reportsRoutes.map((item, index) => (
    <OverviewCard key={index} cardTitle={item.title} onClick={() => handleCardClick(item)} />
  ))

  return (
    <ListLayout headerTitle="Reports" headerProps={{ isDivider: true }} sx={{ px: '154px', mb: 2, pb: 2 }}>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      {/* Consent chart */}
      <ConsentTrendChart />
      <Divider sx={{ mt: 6, mb: 2 }} />
      {/* Cards */}
      {Boolean(cards.length) && (
        <ContentGroup title="Insights" titleVariant="h3" subTitle="Keep track of your recent stats and changes.">
          <CardsBox sx={{ mb: 6 }}>{cards}</CardsBox>
        </ContentGroup>
      )}
    </ListLayout>
  )
}
