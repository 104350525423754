import { useContext } from 'react'
import { SupportedDetailViewWorkflowStepCodes, SupportedDetailViewWorkflowStepNames } from '../interfaces'
import { RightInvocationWorkflowStepDetailViewContext } from '../context/RightInvocationWorkflowStepDetailViewContext'
import { WorkflowActivityCode, WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'

/**
 * Helper hook to derive the workflow details from the workflow step and workflow execution
 */
export const useDerivedWorkflowStepDetails = () => {
  const { workflowStep, workflowExecution, workflow } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const workflowExecutionStepCode = workflowExecution?.steps?.find(step => step.stepID === workflowStep?.ID)
    ?.activityStepCode
  const currentActivityWorkflowExecutionStep = workflowExecution?.steps?.find(step => step.stepID === workflowStep?.ID)
  const category = currentActivityWorkflowExecutionStep?.activityCategory
  const stepManualInterventionStatus = !!currentActivityWorkflowExecutionStep?.manualInterventionStatus

  const gatewayStepType = workflow?.steps?.find(step => step.ID === workflowStep?.ID)?.gateway?.mode

  const isDecisionGateway =
    workflowExecutionStepCode === WorkflowActivityCode.DECISION_SINGLE ||
    workflowExecutionStepCode === WorkflowActivityCode.DECISION_MULTI ||
    gatewayStepType === WorkflowActivityGatewayMode.DECISION_MULTI ||
    gatewayStepType === WorkflowActivityGatewayMode.DECISION_SINGLE
  const isApiCall = workflowStep?.name === SupportedDetailViewWorkflowStepNames.ApiCall
  const isTask = workflowStep?.name === SupportedDetailViewWorkflowStepNames.Task
  const isStepFailed = stepManualInterventionStatus || workflowStep?.status === WorkflowExecutionStepStatus.FAIL
  const isEndTile =
    workflowStep?.activityCode === SupportedDetailViewWorkflowStepCodes.End ||
    workflowStep?.activityCode === 'system.endFlow'
  const isIdv = workflowExecutionStepCode === WorkflowActivityCode.IDENTITY_VERIFICATION
  const isSetPermits = workflowExecutionStepCode === WorkflowActivityCode.SET_PERMITS
  const isEmail =
    workflowExecutionStepCode === WorkflowActivityCode.EMAIL ||
    workflowExecutionStepCode === WorkflowActivityCode.DATA_SUBJECT_EMAIL
  const isImportContext = workflowStep?.name === SupportedDetailViewWorkflowStepNames.ImportContext
  const isInternalSystemDSR = workflowExecutionStepCode === WorkflowActivityCode.INTERNAL_SYSTEM_DSR

  const payload = {
    workflowExecutionStepCode,
    currentActivityWorkflowExecutionStep,
    category,
    stepManualInterventionStatus,
    isDecisionGateway,
    isApiCall,
    isStepFailed,
    isEndTile,
    isTask,
    isIdv,
    isSetPermits,
    isEmail,
    isImportContext,
    isInternalSystemDSR,
  }

  return payload
}
