import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { IdentitySpaceTabOverview } from 'pages/settings/identitySpaces/view/tabs/IdentitySpaceTabOverview'
import { IdentitySpaceTabVersions } from 'pages/settings/identitySpaces/view/tabs/IdentitySpaceTabVersions'
import { relativePath } from 'utils/routing/relativePath'
import { FindVerifiersResponseBodyDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces/common'

type Props = {
  identitySpace: IdentitySpaceDTO
  pushesTo: IdentitySpaceDTO[]
  versions: IdentitySpaceDTO[]
  verifiersData: MaybeNull<FindVerifiersResponseBodyDTO>
}

export const IdentitySpaceViewRoutes: React.FC<Props> = ({ identitySpace, versions, pushesTo, verifiersData }) => {
  const location = useLocation()

  return (
    <Routes location={location}>
      <Route
        path="/"
        element={
          <IdentitySpaceTabOverview identitySpace={identitySpace} pushesTo={pushesTo} verifiersData={verifiersData} />
        }
      />

      <Route
        path={relativePath(RoutesManager.settings.identitySpaces.view.overview.root.pattern)}
        element={
          <IdentitySpaceTabOverview identitySpace={identitySpace} pushesTo={pushesTo} verifiersData={verifiersData} />
        }
      />

      <Route
        path={relativePath(RoutesManager.settings.identitySpaces.view.versions.root.pattern)}
        element={<IdentitySpaceTabVersions identitySpace={identitySpace} versions={versions} />}
      />
    </Routes>
  )
}
