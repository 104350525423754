export enum CookieDurations {
  SESSION = 1,
  PERSISTENT = 2,
}

export const CookieDurationsNames = {
  [CookieDurations.SESSION]: 'Session',
  [CookieDurations.PERSISTENT]: 'Persistent',
}

export const CookieDurationsOptions = [
  { id: CookieDurations.SESSION, name: CookieDurationsNames[CookieDurations.SESSION] },
  { id: CookieDurations.PERSISTENT, name: CookieDurationsNames[CookieDurations.PERSISTENT] },
]
