import { APIListRequestParams } from 'api/common/utils'
import { GetAssetPoliciesResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  resourceCode: string
}>

export const fetchResourcePoliciesPaginatedV2 = ({ resourceCode, start, limit }: Params) =>
  API.get<GetAssetPoliciesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/assets/${resourceCode}/policies`,
      params: {
        filters: {
          start,
          limit,
        },
      },
    }),
  )
