import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { getUserProfileFormValidation } from 'pages/settings/userProfile/edit/userDetails/utils/formValidation'
import { getUserProfileFormValues } from 'pages/settings/userProfile/edit/userDetails/utils/formValues'
import { joinNonEmpty } from 'utils/helpers/joinNonEmpty'
import { MaybeNull } from 'interfaces/common'
import { RoutesManager } from 'utils/routing/routesManager'
import { UserDTO, UserProfileFormDTO } from 'interfaces/users/users'
import { UserProfileFormSectionDetails } from 'pages/settings/userProfile/edit/userDetails/components/UserProfileFormSectionDetails'
import { NavigationBreadCrumbs } from '../../../../../components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'

type Props = {
  isReady: boolean
  user: MaybeNull<UserDTO>
  onSubmit: (values: UserProfileFormDTO) => Promise<void>
}

export const EditUserProfileDetailsView: React.FC<Props> = ({ isReady, user, onSubmit }) => {
  const navigate = useNavigate()
  const validationSchema = useMemo(() => getUserProfileFormValidation(), [])
  const initialValues = useMemo(() => getUserProfileFormValues({ user }), [user])
  const userName = joinNonEmpty([user?.firstName, user?.lastName])
  const breadcrumbs = [
    { title: 'Profile', link: RoutesManager.settings.profile.root.getURL() },
    {
      title: userName,
      link: RoutesManager.settings.profile.root.getURL(),
    },
    { title: 'Edit' },
  ]

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })

  const { isSubmitting, submitForm } = formikProps

  if (!isReady) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  return (
    <FormikProvider value={formikProps}>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <UpsertLayout
        mode={UpsertLayoutMode.edit}
        renderFormTitle={() => <ContentGroup title="Edit Profile" titleVariant="h2" isDivider />}
        onAccept={submitForm}
        onCancel={() => navigate(RoutesManager.settings.profile.root.getURL())}
        acceptButtonProps={{
          pending: isSubmitting,
        }}
        acceptActionButtonText="Save"
      >
        {isReady ? (
          <UserProfileFormSectionDetails />
        ) : (
          <Box
            sx={{
              margin: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner size="32px" thickness={2.5} />
          </Box>
        )}
      </UpsertLayout>
    </FormikProvider>
  )
}
