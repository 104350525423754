import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { WorkflowExecutionAutomatedStepReassignFormValues } from './utils'
import { showToast } from 'components/modals/AlertComponent'
import { useUpdateWorkflowExecutionStepAssignee } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStepAssignee'

export const useReassignStep = (
  workflowExecutionID: string,
  stepID: string,
  onClose = () => {},
  onUpdateWorkflowAssigneeCallback = () => {},
) => {
  const queryClient = useQueryClient()

  const { mutateAsync: handleUpdateWorkflowExecutionStepAssignee, isLoading: isUpdatingWorkflowExecutionStepAssignee } =
    useUpdateWorkflowExecutionStepAssignee({
      onSuccess: async () => {
        await queryClient.refetchQueries(ApiQueryKeys.entitlements)
        await queryClient.refetchQueries([
          ApiQueryKeys.workflowExecution,
          {
            workflowExecutionId: workflowExecutionID,
          },
        ])

        await queryClient.refetchQueries([
          ApiQueryKeys.workflowExecutionStepDetails,
          {
            workflowExecutionId: workflowExecutionID,
            stepId: stepID,
          },
        ])

        await queryClient.refetchQueries([
          ApiQueryKeys.workflowExecutionStepDetailsV2,
          {
            workflowExecutionId: workflowExecutionID,
            stepId: stepID,
          },
        ])
        showToast({ content: 'Activity reassigned', type: 'success' })
        onClose()
        onUpdateWorkflowAssigneeCallback()
      },
      onError: () => {
        showToast({ content: 'Failed to reassign activity', type: 'error' })
        onUpdateWorkflowAssigneeCallback()
      },
    })

  const onSubmit = async (values: WorkflowExecutionAutomatedStepReassignFormValues) => {
    await handleUpdateWorkflowExecutionStepAssignee({
      params: {
        workflowExecutionId: workflowExecutionID,
        stepId: stepID,
        assigneeId: values.assigneeId,
      },
    })
  }

  return {
    handleReassignWorkflowExecutionStepAssignee: onSubmit,
    isUpdatingWorkflowExecutionStepAssignee,
  }
}
