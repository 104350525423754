import { MaybeNull } from '../../../../../interfaces'
import { PolicyScopeTemplateDTO } from '../../../../../interfaces/policyScopes/policyScope'
import { PolicyScopeFormValues } from '../interfaces'
import { optOutRights } from '../constants'

export const getInitialValues = ({
  policyScopeTemplate,
}: {
  policyScopeTemplate?: MaybeNull<PolicyScopeTemplateDTO>
}): PolicyScopeFormValues => ({
  name: policyScopeTemplate?.name || '',
  code: '',
  description: policyScopeTemplate?.description || '',
  regionCodes: policyScopeTemplate?.regionCodes || [],
  regulationCodes: policyScopeTemplate?.regulationCodes || [],
  rightsFulfillment:
    policyScopeTemplate?.rightsFulfillment?.map(right => ({
      ...right,
      checked: !optOutRights.includes(right.rightCode),
    })) || [],
})
