import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { DeploymentPlanFormValues, mapValuesToPayload } from 'pages/consentAndRights/deploymentPlans-v2/upsert/utils'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useDeploymentPlan } from 'api/deploymentPlans-v2/queries/useDeploymentPlan'
import { useCreateDeploymentPlan } from 'api/deploymentPlans-v2/mutations/useCreateDeploymentPlan'
import { useUpdateDeploymentPlan } from 'api/deploymentPlans-v2/mutations/useUpdateDeploymentPlan'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { useExperiences } from 'api/experiences-v2/queries/useExperiences'
import { useThemes } from 'api/themes-v3/queries/useThemes'
import { usePolicyDocuments } from 'api/policyDocuments/queries/usePolicyDocuments'
import { DeploymentPlanCreate } from 'pages/consentAndRights/deploymentPlans-v2/upsert/create/DeploymentPlanCreate'
import { DeploymentPlanEdit } from 'pages/consentAndRights/deploymentPlans-v2/upsert/edit/DeploymentPlanEdit'
import { showToast } from 'components/ui-kit/toastr/Toastr'

export const DeploymentPlanUpsertContainer: React.FC = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()
  const { queries } = useQueryParams<{ duplicate?: string }>()
  const { isEntitled } = useIsEntitled()

  const duplicate = Boolean(queries.duplicate)
  const isEditMode = code !== NEW_ITEM_CODE
  const isEntitledToContentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)

  const { data: deploymentPlan, isLoading: isDeploymentPlanLoading } = useDeploymentPlan({
    enabled: duplicate || isEditMode,
    params: {
      deploymentPlanCode: code!,
    },
    onError: () => {
      navigate(RoutesManager.deployment.deploymentPlansV2.root.getURL())
    },
  })

  const { data: policyScopes, isLoading: isPolicyScopesLoading } = usePolicyScopes()
  const { data: experiences, isLoading: isExperiencesLoading } = useExperiences()
  const { data: policyDocuments, isLoading: isPolicyDocumentLoading } = usePolicyDocuments()
  const { data: themes, isLoading: isThemesLoading } = useThemes()
  const { mutateAsync: handleCreateDeploymentPlan } = useCreateDeploymentPlan()
  const { mutateAsync: handleUpdateDeploymentPlan } = useUpdateDeploymentPlan()

  const onSubmit = async (values: DeploymentPlanFormValues) => {
    try {
      const formData = mapValuesToPayload({
        values,
        isEntitledToContentAndDisclosure,
      })

      const { data } = await (duplicate || !isEditMode
        ? handleCreateDeploymentPlan({
            params: {
              formData,
            },
          })
        : handleUpdateDeploymentPlan({
            params: {
              deploymentPlanCode: code!,
              formData,
            },
          }))

      if (isEditMode) {
        queryClient.refetchQueries([ApiQueryKeys.deploymentPlan, { deploymentPlanCode: code }])
      }

      queryClient.refetchQueries(ApiQueryKeys.entitlements)

      showToast({ content: `Deployment plan ${isEditMode ? 'updated' : 'created'}`, type: 'success' })
      navigate(RoutesManager.deployment.deploymentPlansV2.view.root.getURL({ code: data.deployment.code }))
    } catch (e) {
      showToast({ content: `Failed to ${isEditMode ? 'update' : 'create'} deployment plan`, type: 'error' })
    }
  }

  const isReady =
    !isDeploymentPlanLoading &&
    !isPolicyScopesLoading &&
    !isExperiencesLoading &&
    !isPolicyDocumentLoading &&
    !isThemesLoading

  return duplicate || !isEditMode ? (
    <DeploymentPlanCreate
      duplicate={duplicate}
      isReady={isReady}
      deploymentPlan={deploymentPlan}
      policyScopes={policyScopes}
      experiences={experiences}
      themes={themes}
      policyDocuments={policyDocuments}
      onSubmit={onSubmit}
    />
  ) : (
    <DeploymentPlanEdit
      isReady={isReady}
      deploymentPlan={deploymentPlan}
      policyScopes={policyScopes}
      experiences={experiences}
      themes={themes}
      policyDocuments={policyDocuments}
      onSubmit={onSubmit}
    />
  )
}
