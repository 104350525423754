export enum ExperienceBannerPrimaryButtonAction {
  SAVE_CURRENT_STATE = 1,
  ACCEPT_ALL = 2,
}

export const EXPERIENCE_BANNER_PRIMARY_BUTTON_ACTION_NAMES = {
  [ExperienceBannerPrimaryButtonAction.SAVE_CURRENT_STATE]: 'Save Current State',
  [ExperienceBannerPrimaryButtonAction.ACCEPT_ALL]: 'Accept All',
}

export const EXPERIENCE_BANNER_PRIMARY_BUTTON_ACTION_OPTIONS = [
  {
    id: ExperienceBannerPrimaryButtonAction.SAVE_CURRENT_STATE,
    name: EXPERIENCE_BANNER_PRIMARY_BUTTON_ACTION_NAMES[ExperienceBannerPrimaryButtonAction.SAVE_CURRENT_STATE],
  },
  {
    id: ExperienceBannerPrimaryButtonAction.ACCEPT_ALL,
    name: EXPERIENCE_BANNER_PRIMARY_BUTTON_ACTION_NAMES[ExperienceBannerPrimaryButtonAction.ACCEPT_ALL],
  },
]
