import * as Yup from 'yup'

interface ISetPermitsValidationSchemaParams {
  usedCodes?: Array<string>
}

export const getSetPermitsValidationSchema = (params: ISetPermitsValidationSchemaParams) =>
  Yup.object().shape({
    code: Yup.string()
      .notOneOf(params?.usedCodes || [], 'This code is already in use')
      .required('This field is required'),
    description: Yup.string().required('Required'),
    permitDetails: Yup.array()
      .of(Yup.object().shape({}))
      .test('not-all-purposes-are-undefined', 'At least one purpose must be defined', function (value) {
        if (!value) return false

        return value.some(item => {
          return Object.values(item)[0] !== '1' && Object.values(item)[0] !== 1
        })
      })
      .required('Required'),
  })
