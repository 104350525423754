import * as Yup from 'yup'
import { useMemo } from 'react'

export const useVerifiersFormValidation = () => {
  return useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required('Required'),
        type: Yup.number().required('Required'),
        sharedSecret: Yup.string().when('type', {
          is: 1,
          then: Yup.string().required('Required'),
        }),
        publicKey: Yup.string().when('type', {
          is: 2,
          then: Yup.string().required('Required'),
        }),
        jwksUrl: Yup.string().when('type', {
          is: 3,
          then: Yup.string().required('Required'),
        }),
      }),
    [],
  )
}
