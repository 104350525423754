export const getUtcOffsetMessage = () => {
  const utcOffset = new Date().getTimezoneOffset() / 60
  const utcPlusMinus = utcOffset > 0 ? '-' : '+'
  const utcOffsetAbsolute = Math.abs(utcOffset)
  const hours = Math.floor(utcOffsetAbsolute)
  const minutes = Math.round((utcOffsetAbsolute - hours) * 60)
  const minDisplay = minutes === 0 ? '00' : `${minutes}`
  const hoursDisplay = hours > 9 ? `${hours}` : `0${hours}`

  const payload = `This scheduled time is set for your local timezone UTC ${utcPlusMinus}${hoursDisplay}:${minDisplay}`

  return payload
}
