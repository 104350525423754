import * as Yup from 'yup'

export type RightInvocationAssignFormValues = {
  workflowCode: string
}

export const initialValues: RightInvocationAssignFormValues = {
  workflowCode: '',
}

export const validationSchema = Yup.object().shape({
  workflowCode: Yup.string().required('Required'),
})
