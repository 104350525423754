import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { isGenericChildWorkflowParamsTypeGuard } from 'pages/orchestration/workflows/edit/interfaces/typeGuards'

interface IGetGenericChildWorkflowInitialValuesParams {
  step: ICanvasStep
}

interface IGetGenericChildWorkflowInitialValues {
  description: string
  code: string
  workflow: string
}

export const getGenericChildWorkflowInitialValues = (
  params: IGetGenericChildWorkflowInitialValuesParams,
): IGetGenericChildWorkflowInitialValues => {
  const { step } = params

  if (step.workflow?.params && isGenericChildWorkflowParamsTypeGuard(step.workflow.params)) {
    const description = step.description || ''
    const code = step.code || ''
    const workflow = step.workflow.params?.workflow || ''

    return {
      description,
      code,
      workflow,
    }
  }

  return {
    description: '',
    code: '',
    workflow: '',
  }
}
