import { API } from 'utils/api/api'
import { APIRequestParams } from 'api/common/utils'
import { formatRequestString } from 'utils/helpers/api/queryString'
import {
  CreateInstalledDataSystemV2RequestBodyDTO,
  CreateInstalledDataSystemV2ResponseBodyDTO,
} from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  formData: CreateInstalledDataSystemV2RequestBodyDTO
}>

export const createInstalledDataSystem = ({ formData }: Params) =>
  API.post<CreateInstalledDataSystemV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/installed-data-systems`,
    }),
    formData,
  )
