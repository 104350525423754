import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { deleteFormTemplate } from '../fetchers/deleteFormTemplate'

const useCustomMutation = createUseMutation({
  mutationFn: deleteFormTemplate,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeleteFormTemplate = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
