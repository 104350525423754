import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useFormikContext } from 'formik'
import { VENDOR_CHOICE_OPTIONS } from 'interfaces/privacyProtocols'
import { SearchChipList } from 'pages/consentAndRights/consent/privacyProtocols/components/SearchChipList'
import { PrivacyProtocolUpsertContext } from 'pages/consentAndRights/consent/privacyProtocols/upsert/context/PrivacyProtocolUpsertContext'
import { PrivacyProtocolConfigurationPutRequestBody } from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'
import { FormRow } from '@ketch-com/deck'
import { FormRadioGroup } from 'components/form/FormRadioGroup'

/**
 * Form section for selecting from a list of Google vendors, with the optional
 * choice of selecting all
 */
export const GoogleVendorsFormSection: React.FC = () => {
  const {
    googleVendorList: { vendors: allVendors },
  } = useContext(PrivacyProtocolUpsertContext)

  const {
    setFieldValue,
    errors,
    values: { selectAllGoogleVendors, googleVendors },
  } = useFormikContext<PrivacyProtocolConfigurationPutRequestBody>()

  return (
    <FormRow
      title="Google Additional Consent Vendors"
      subTitle="Google Additional Consent Vendors are third-party advertisers partnered with the publisher that are not yet registered on the IAB Global Vendor List. They display external content on the website and use marketing cookies to show relevant ads to users."
    >
      <Box>
        <FormRadioGroup
          hideOptionalLabel
          formPropertyName="selectAllGoogleVendors"
          row
          valueKey="id"
          renderLabel={({ id, name, description }) => (
            <Box>
              {/* Make selected option bold */}
              <Typography
                style={{
                  fontWeight:
                    (selectAllGoogleVendors && id === true) || (!selectAllGoogleVendors && id === false)
                      ? 'bold'
                      : 'normal',
                }}
              >
                {name}
              </Typography>
              <Typography display="block" variant="footnote" color="darkDuskFaded.main">
                {description}
              </Typography>
            </Box>
          )}
          options={VENDOR_CHOICE_OPTIONS}
          onChange={(event, value) => {
            setFieldValue('selectAllGoogleVendors', value === '1' || value === 'true', false)
          }}
        />
      </Box>

      {!selectAllGoogleVendors && (
        <SearchChipList
          options={
            allVendors?.length
              ? allVendors.map(v => ({
                  id: v.id || '',
                  label: v.name || '',
                }))
              : []
          }
          selectedOptions={googleVendors}
          inputPrompt="Start typing a vendor name"
          onSelect={selectedId =>
            setFieldValue('googleVendors', googleVendors ? [...googleVendors, selectedId] : [selectedId], false)
          }
          onDeselect={deselectedId =>
            setFieldValue(
              'googleVendors',
              googleVendors ? googleVendors.filter(vendorId => vendorId !== deselectedId) : [],
              false,
            )
          }
          showError={Boolean(errors.googleVendors)}
          errorMsg={'Must include at least one google vendor'}
        />
      )}
    </FormRow>
  )
}
