import { IAppLevel2Params, ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { isAppLevel2ActivityParamsTypeGuard } from '../../../interfaces/typeGuards'

interface IGetAppInitialValuesParams {
  step: ICanvasStep
}

export const getAppLvl2InitialValues = (params: IGetAppInitialValuesParams): IAppLevel2Params => {
  const { step } = params

  if (isAppLevel2ActivityParamsTypeGuard(step?.workflow?.params)) {
    const code = step.code || ''
    const description = step.description || ''
    const appInstanceID = step.workflow?.params?.appInstanceID || ''

    return {
      code,
      description,
      appInstanceID,
    }
  }

  return {
    description: '',
    code: '',
    appInstanceID: '',
  }
}
