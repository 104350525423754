import React from 'react'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'

import { formatCodeFromName } from 'utils/formatters'
import { FormFieldFormDTO } from '../interfaces'
import { useIsEditMode } from 'utils/hooks'
import { FormRow } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'

type Props = {}

export const BasicDetailsFormSection: React.FC<Props> = () => {
  const { setFieldValue, setFieldTouched, errors } = useFormikContext<FormFieldFormDTO>()

  const isEditMode = useIsEditMode()

  return (
    <FormRow title="Basic Details">
      <Grid container spacing={3}>
        {/* Title Input */}

        <Grid item xs={6}>
          <FormInput
            required
            fullWidth
            formPropertyName="name"
            label="Name"
            placeholder="Add field title"
            onChange={e => {
              if (errors.title?.includes('characters')) setFieldTouched('name', true)
              if (!isEditMode) {
                setFieldTouched('code', true)
                setFieldValue('code', formatCodeFromName(e.target.value))
              }
            }}
            shouldUpdateDebounced
          />
        </Grid>

        <Grid item xs={6} />

        {/* Code Input */}

        <Grid item xs={6}>
          <FormInput
            required
            fullWidth
            formPropertyName="code"
            label="Code"
            placeholder="Add a unique code"
            disabled={isEditMode}
            shouldUpdateDebounced
          />
        </Grid>

        <Grid item xs={6} />
      </Grid>
    </FormRow>
  )
}
