import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  id: string
}>

export const deleteTheme = ({ id }: Params) =>
  API.delete<null>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/themes/${id}`,
    }),
  )
