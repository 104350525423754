import { isEmpty, isUndefined } from 'lodash'

import { formModes } from 'utils/constants/formModes'
import { MaybeNull, ObjectLiteral } from 'interfaces'
import { WorkflowDTO } from 'interfaces/workflows'
import { IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import * as CanvasConstants from 'pages/orchestration/workflows/edit/utils/constants'
import { getStepsWithReplacedStepIdsForCollisionFreeDuplication } from '.'
import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'

interface ITransformWorkflowFromBeParams {
  workflow: MaybeNull<WorkflowDTO>
  workflowItemsIcons: ObjectLiteral
  mode: string
}

export const transformWorkflowFromBE = (params: ITransformWorkflowFromBeParams): IWorkflowConfig => {
  const { workflow, workflowItemsIcons, mode } = params
  const steps = workflow?.steps || []
  const isDuplicateMode = mode === formModes.DUPLICATE

  if (isEmpty(workflow) || isEmpty(steps)) {
    return CanvasConstants.WORKFLOW_INITIAL_STATE
  }

  const workflowForEdit = {
    // @ts-ignore
    name: workflow?.name || '',
    // @ts-ignore
    code: workflow?.code || '',
    ...workflow,
    steps: steps.map(step => {
      let iconURL

      if (!isUndefined(step.activity)) {
        iconURL = workflowItemsIcons[step.activity?.code]
      }

      if (!isUndefined(step.gateway)) {
        // Single decisions are no longer available in the sidebar and should use the decision multi icon
        const gatewayMode = step?.gateway?.mode
        const revisedGatewayMode =
          gatewayMode === WorkflowActivityGatewayMode.DECISION_SINGLE
            ? WorkflowActivityGatewayMode.DECISION_MULTI
            : gatewayMode

        iconURL = workflowItemsIcons[`gateway_${revisedGatewayMode}`]
      }

      if (!isUndefined(step.workflow)) {
        iconURL = workflowItemsIcons[step.workflow?.code]
      }

      return {
        ...step,
        iconURL,
        valid: true,
      }
    }),
  }

  const workflowForDuplicate: IWorkflowConfig = {
    code: '',
    name: '',
    steps: getStepsWithReplacedStepIdsForCollisionFreeDuplication(workflowForEdit.steps),
    canonicalRightCode: workflowForEdit.canonicalRightCode,
  }

  return isDuplicateMode ? workflowForDuplicate : workflowForEdit
}
