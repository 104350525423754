import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Button, TableCell } from '@ketch-com/deck'
import {
  AuthenticatorDTO,
  ExperienceShortDTO,
  FormTemplateDTO,
  WorkflowDefinitionStepSummaryDTO,
} from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { Link } from 'react-router-dom'
import { GatewayCellRenderer } from './GatewayCellRenderer'
import { FormMode } from 'interfaces/formModes/formMode'

export const formTemplateGridColumns: GridColDef<FormTemplateDTO>[] = [
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Forms',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<FormTemplateDTO>) => {
      return <TableCell title={row.name} />
    },
  },
  {
    align: 'right',
    field: 'actions',
    flex: 1,
    headerAlign: 'left',
    headerName: ' ',
    sortable: false,
    renderCell: ({ row: formTemplate }: GridRenderCellParams<FormTemplateDTO>) => {
      const targetUrl = RoutesManager.deployment.forms.templates.upsert.preview.root.getURL({
        formMode: FormMode.EDIT,
        id: formTemplate?.id || '',
      })
      return (
        <TableCell>
          <Link
            to={{ pathname: targetUrl, search: '?from=detail' }}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Button color="tertiary">Open In New Tab</Button>
          </Link>
        </TableCell>
      )
    },
  },
]

export const getExperienceShortGridColumns = (isExpV1: boolean): GridColDef<ExperienceShortDTO>[] => [
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Experiences',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ExperienceShortDTO>) => {
      return <TableCell title={row.name} subTitle={row.code} />
    },
  },
  {
    align: 'right',
    field: 'actions',
    flex: 1,
    headerAlign: 'left',
    headerName: ' ',
    sortable: false,
    renderCell: ({ row: experience }: GridRenderCellParams<ExperienceShortDTO>) => {
      const targetUrl = isExpV1
        ? RoutesManager.deployment.experiences.view.root.getURL({
            code: experience?.code || '',
          })
        : RoutesManager.deployment.experiencesV2.view.preview.root.getURL({ id: experience?.id })

      return (
        <TableCell>
          <Link to={targetUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Button color="tertiary">Open In New Tab</Button>
          </Link>
        </TableCell>
      )
    },
  },
]

export const workflowDefinitionStepSummaryGridColumns: GridColDef<WorkflowDefinitionStepSummaryDTO>[] = [
  {
    align: 'left',
    field: 'workflow',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Workflow',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<WorkflowDefinitionStepSummaryDTO>) => {
      return <TableCell title={row.workflowDefinitionName} />
    },
  },
  {
    align: 'left',
    field: 'gateway',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Decision Gateway',
    sortable: false,
    renderCell: ({ row: { name, icon } }: GridRenderCellParams<WorkflowDefinitionStepSummaryDTO>) => {
      return (
        <TableCell>
          <GatewayCellRenderer name={name} icon={icon} />
        </TableCell>
      )
    },
  },
  {
    align: 'right',
    field: 'actions',
    flex: 1,
    headerAlign: 'left',
    headerName: ' ',
    sortable: false,
    renderCell: ({ row: workflowActivity }: GridRenderCellParams<WorkflowDefinitionStepSummaryDTO>) => {
      const targetUrl = RoutesManager.orchestration.workflows.view.root.getURL({
        code: workflowActivity.workflowDefinitionCode,
      })
      return (
        <TableCell>
          <Link to={targetUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Button color="tertiary">Open In New Tab</Button>
          </Link>
        </TableCell>
      )
    },
  },
]

export const authenticatorGridColumns: GridColDef<AuthenticatorDTO>[] = [
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Authenticators',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<AuthenticatorDTO>) => {
      return <TableCell title={row.name} />
    },
  },
  {
    align: 'right',
    field: 'actions',
    flex: 1,
    headerAlign: 'left',
    headerName: ' ',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<AuthenticatorDTO>) => {
      const targetUrl = RoutesManager.developers.authenticators.list.getURL()
      return (
        <TableCell>
          <Link to={targetUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Button color="tertiary">Open In New Tab</Button>
          </Link>
        </TableCell>
      )
    },
  },
]
