import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useAssetV2 } from 'api/assets/queries/useAssetV2'
import { AssetDetailView } from './AssetDetailView'
import { useConnections } from 'api/assets/queries/useConnections'
import { getURLParams } from 'utils/routing/routes/utils'
import { useDataPreview } from 'api/assets/queries/useDataPreview'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useAssetProcessingActivities } from 'api/assets/queries/useAssetProcessingActivities'
import { AssetDeleted } from 'pages/assetManager/assets/view/components'

export const AssetDetailViewContainer: React.FC = () => {
  const queryClient = useQueryClient()
  const { code, resourceTypeCode = '' } = useParams<getURLParams>()
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isSamplesPreviewEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_DATA_PREVIEW_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_DATA_PREVIEW_VERSION_1,
  )
  const doesResourceSupportPreview =
    resourceTypeCode === 'azuresqldb.table' ||
    resourceTypeCode === 'snowflake.table' ||
    resourceTypeCode === 'redshift.table' ||
    resourceTypeCode === 'oracle.table' ||
    resourceTypeCode === 'postgresql.table' ||
    resourceTypeCode === 'cockroachDB.table' ||
    resourceTypeCode === 'mariadb.table' ||
    resourceTypeCode === 'mysql.table' ||
    resourceTypeCode === 'mssqlserver.table' ||
    resourceTypeCode === 'bigquery.table'
  const isDataPreviewSupported = isSamplesPreviewEnabled && doesResourceSupportPreview

  const { data: connections, isLoading: isLoadingConnections } = useConnections()
  const { data: assetSummary, isLoading: isLoadingAsset } = useAssetV2({
    params: {
      assetCode: code!,
      resourceTypeCode,
    },
  })
  // Processing activities for this asset - also used to get purposes
  const { data: processingActivities, isLoading: isLoadingProcessingActivity } = useAssetProcessingActivities({
    params: { assetCode: code, resourceTypeCode },
  })
  const applianceID = assetSummary.asset?.connection?.applianceID || ''
  const tableResourceID = assetSummary.asset?.resource?.id || ''
  const { data: dataPreview, isLoading: isLoadingDataPreview } = useDataPreview({
    enabled: !isLoadingAsset && isDataPreviewSupported,
    params: {
      applianceID,
      tableResourceID,
    },
  })

  useEffect(() => {
    return () => {
      if (isSamplesPreviewEnabled && !doesResourceSupportPreview) {
        queryClient.removeQueries({ queryKey: ['dataPreview', { applianceID, tableResourceID }], exact: true })
      }
    }
  })

  const isReady = !isLoadingAsset && !isLoadingConnections && !isLoadingDataPreview && !isLoadingProcessingActivity

  return (
    <>
      <AssetDeleted isReady={isReady} assetSummary={assetSummary} />
      <AssetDetailView
        isReady={isReady}
        assetSummary={assetSummary}
        connections={connections}
        processingActivities={processingActivities}
        dataPreview={dataPreview}
      />
    </>
  )
}
