import React, { createContext } from 'react'
import { useFormFieldsListUtils, UseFormFieldsListUtilsReturn } from '../hooks'

export const FormFieldsListContext = createContext({} as UseFormFieldsListUtilsReturn)

type Props = {
  children?: React.ReactNode
}

export const FormFieldsListContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useFormFieldsListUtils()

  return (
    <FormFieldsListContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </FormFieldsListContext.Provider>
  )
}
