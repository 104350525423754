import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'

import {
  AttributesCellRenderer,
  CheckAllDatasetsCellRenderer,
  DatasetCheckboxCellRenderer,
  DatasetTableActionCellRenderer,
} from '.'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { Pagination } from 'api/common/paginatedQuery'
import { Badge } from 'components/ui-kit/badge/Badge'
import { AssetDTO } from '@ketch-com/figurehead'

interface Props {
  pagination: Pagination
  activeDatabase: string
  isLoadingRelatedDatasets: boolean
  relatedDatasets: AssetDTO[]
  setConfigureDatasetModalCode: (code: string) => void
}

const useStyles = makeStyles(
  () =>
    createStyles({
      actionColumn: {
        alignItems: 'center',
      },
    }),
  { name: 'RelatedDatasetsTable' },
)

export const RelatedDatasetsTable: React.FC<Props> = ({
  activeDatabase,
  isLoadingRelatedDatasets,
  relatedDatasets,
  setConfigureDatasetModalCode,
  pagination,
}) => {
  const classes = useStyles()

  return (
    <TablePaginated
      pagination={pagination}
      isSmallHeaderText
      variant="page"
      pending={isLoadingRelatedDatasets}
      items={relatedDatasets || []}
      hasCheckAll
      cellSettings={{
        checkboxColumn: {
          width: 40,
          label: '',
          labelNodeRenderer: items => <CheckAllDatasetsCellRenderer activeDatabase={activeDatabase} items={items} />,
          cellRenderer: asset => (
            <DatasetCheckboxCellRenderer activeDatabase={activeDatabase} datasetCode={asset?.resource?.id as string} />
          ),
        },
        datasetName: {
          width: 260,
          label: 'Name',
          cellRenderer: asset => <TextCellRenderer value={asset?.resource?.name} />,
        },
        attributes: {
          width: 100,
          label: 'Attributes',
          cellRenderer: asset => <AttributesCellRenderer code={asset?.resource?.id || ''} />,
        },
        dataCategory: {
          label: 'Data Category',
          width: 120,
          cellRenderer: asset =>
            asset?.dataCategory ? (
              <Badge variant="neutral">{asset?.dataCategory?.[0]?.value}</Badge>
            ) : (
              <EmptyValueRenderer />
            ),
        },
        owner: {
          label: 'Owner',
          width: 180,
          cellRenderer: asset => (asset?.owner ? <TextCellRenderer value={asset?.owner} /> : <EmptyValueRenderer />),
        },
        action: {
          label: 'Action',
          className: classes.actionColumn,
          cellRenderer: asset => (
            <DatasetTableActionCellRenderer
              activeDatabase={activeDatabase}
              code={asset?.resource?.id || ''}
              setConfigureDatasetModalCode={setConfigureDatasetModalCode}
            />
          ),
        },
      }}
    />
  )
}
