import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { LanguageDTO } from 'interfaces/languages/language'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{}>

type Response = APIListResponse<{ languages: LanguageDTO[] }>

export const fetchLanguages = ({ includeMetadata, start, limit }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: '/api/languages',
      params: {
        filters: {
          includeMetadata,
          start,
          limit,
        },
      },
    }),
  )
