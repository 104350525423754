import React from 'react'

import { useCanonicalPurposes } from 'api/purposes/queries/useCanonicalPurposes'
import { useAppsInstancesPurposes } from 'api/apps/queries/useAppsInstancesPurposes'
import { PermitPropagationList } from 'pages/consentAndRights/consent/systems/list/PermitPropagationList'
import { PurposeDataRole } from 'interfaces/purposes/purposeDataRole'

export const PermitPropagationListContainer: React.FC = () => {
  const {
    data: appsInstancesPurposes,
    isLoading,
    isFetching,
  } = useAppsInstancesPurposes({
    params: {
      ordering: {
        hasIssues: true,
        name: 'asc',
      },
    },
  })
  const { data: canonicalPurposes, isLoading: isCanonicalPurposesLoading } = useCanonicalPurposes({
    params: { includeDataSubjectTypes: true, filterDataRole: PurposeDataRole.CONTROLLER },
  })

  return (
    <PermitPropagationList
      isReady={!isCanonicalPurposesLoading}
      isLoading={isLoading}
      isFetching={isFetching}
      appsInstancesPurposes={appsInstancesPurposes}
      canonicalPurposes={canonicalPurposes}
    />
  )
}
