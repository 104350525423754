import React from 'react'
import { FormSectionSwitch } from '../FormSectionSwitch'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { Box } from '@mui/material'
import { Tab, TabPanel, TabGroup } from '@ketch-com/deck'
import { FormTextInput } from '../FormTextInput'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ExperienceBuilderSectionLabels } from '../../../builder/utils/labels'
import { PreferenceExperienceBuilderSection } from '../../../builder/utils/enums'
import { FormEditor, renderDocumentLinkerModal } from 'components/ui-kit/form/editor'
import { useContext } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'

export const PreferenceExperienceWelcomeTabWelcomeMessageForm: React.FC = () => {
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length
  const { values } = useFormikContext<ExperienceV2DTO>()

  // Current tab of the button form
  const [tab, setTab] = React.useState(0)

  const showForm = getIn(values, experienceUpsertFormKeys.preferenceWelcomeTabWelcomeMsgVisible)

  return (
    <>
      <SidebarFormGroup sx={{ py: 0 }}>
        <FormSectionSwitch
          name={experienceUpsertFormKeys.preferenceWelcomeTabWelcomeMsgVisible}
          title={ExperienceBuilderSectionLabels[PreferenceExperienceBuilderSection.WelcomeTabWelcomeMessage]}
        />
      </SidebarFormGroup>
      {showForm && (
        <SidebarFormGroup sx={{ pt: '12px' }}>
          <Box px={2.25}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
                <Tab size={'medium'} label="Title" />
                <Tab size={'medium'} label="Sub-Title" />
              </TabGroup>
            </Box>

            {/* Title form */}
            <TabPanel value={tab} index={0} sx={{ px: 0, pt: '12px' }}>
              <SidebarFormGroup sx={{ paddingTop: '16px' }} hasBorderBottom={hasLanguages}>
                <FormTextInput
                  name={experienceUpsertFormKeys.preferenceWelcomeTabWelcomeMsgTitle}
                  label={'Title Text'}
                  fullWidth
                  charLimit={200}
                />
              </SidebarFormGroup>
              <SidebarFormTranslations
                title={'Title Text Translations'}
                name={experienceUpsertFormKeys.preferenceWelcomeTabWelcomeMsgTitle}
                variant={'text'}
                charLimit={200}
              />
            </TabPanel>

            {/* Subtitle form*/}
            <TabPanel value={tab} index={1} sx={{ px: 0, pt: '12px' }}>
              <SidebarFormGroup hasBorderBottom={hasLanguages} sx={{ paddingTop: '16px' }}>
                <FormEditor
                  label={'Sub-Title Text'}
                  required={false}
                  id={experienceUpsertFormKeys.preferenceWelcomeTabWelcomeMsgSubtitle.replaceAll('.', '_')}
                  name={experienceUpsertFormKeys.preferenceWelcomeTabWelcomeMsgSubtitle}
                  linkChangeModal={renderDocumentLinkerModal}
                  charLimit={6000}
                />
              </SidebarFormGroup>
              <SidebarFormTranslations
                title={'Label Text Translations'}
                name={experienceUpsertFormKeys.preferenceWelcomeTabWelcomeMsgSubtitle}
                variant={'richText'}
                charLimit={6000}
              />
            </TabPanel>
          </Box>
        </SidebarFormGroup>
      )}
    </>
  )
}
