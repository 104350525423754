import React, { Fragment } from 'react'
import Grid from '@mui/material/Grid'
import { Formik } from 'formik'
import { compact } from 'lodash'

import { IAppLevel2Params, ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { formatCodeFromName } from 'utils/formatters'
import { useApp } from 'api/apps/queries/useApp'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'

import { getAppLvl2InitialValues } from 'pages/orchestration/workflows/edit/utils/forms/apps/appLvl2InitialValues'
import { getAppLvl2Validation } from 'pages/orchestration/workflows/edit/utils/forms/apps/appLvl2Validation'
import { isAppLevel2ActivityParamsTypeGuard } from 'pages/orchestration/workflows/edit/interfaces/typeGuards'
import { Box, Typography } from '@mui/material'
import { FormInput } from 'components/form/FormInput'
import { FormDroplistButton } from 'components/form/FormDroplistButton'

interface IAppFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const AppLvl2Form: React.FC<IAppFormProps> = props => {
  const { step, steps, handleClose, handleRemove, handleSave } = props
  const appId = isAppLevel2ActivityParamsTypeGuard(step?.workflow?.params) ? step?.workflow?.params?.appID : ''
  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))

  const { data: appDescriptor, isLoading } = useApp({
    enabled: !!appId,
    params: {
      appId: appId!,
    },
  })

  const onSubmit = (values: IAppLevel2Params) => {
    handleSave({
      step: {
        ...step,
        valid: true,
        code: values.code,
        description: values.description,
        workflow: {
          ...step.workflow,
          params: {
            appID: appId,
            appInstanceID: values.appInstanceID,
          },
        } as ICanvasStep['workflow'],
      },
    })
  }

  return (
    <SidebarConfigsWrapper isLoading={isLoading} handleClose={handleClose}>
      <Formik
        initialValues={getAppLvl2InitialValues({ step })}
        validationSchema={getAppLvl2Validation({ usedCodes })}
        validateOnMount
        onSubmit={onSubmit}
      >
        {form => {
          const { isValid, submitForm, setFieldValue } = form

          return (
            <Fragment>
              <SidebarConfigsHeader
                isValid={isValid}
                step={step}
                steps={steps}
                handleSave={() => submitForm()}
                handleRemove={() => handleRemove({ step })}
                handleClose={handleClose}
              />

              <SidebarConfigsBody sx={{ px: 3 }}>
                <Box>
                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormInput
                          required
                          shouldUpdateDebounced
                          formPropertyName="description"
                          fullWidth
                          label="Description Label"
                          placeholder="Enter description"
                          onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom={5}>
                    <Grid container spacing={1}>
                      <Grid item xs={8}>
                        <FormInput
                          formPropertyName="code"
                          fullWidth
                          label="Code"
                          placeholder="Enter code"
                          required
                          shouldUpdateDebounced
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom={3}>
                    <Typography variant="h3">Connections</Typography>
                  </Box>

                  <Box marginBottom={5}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormDroplistButton
                          required
                          disableClearable
                          fullWidth
                          valueKey="ID"
                          getOptionLabel={option => option.name || option.ID}
                          formPropertyName="appInstanceID"
                          placeholder="Select Connection"
                          items={appDescriptor?.appInstances || []}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {!!appDescriptor?.app?.supportedRights?.length && (
                    <>
                      <Box marginBottom={3}>
                        <Typography variant="h3">Supported Rights</Typography>
                      </Box>

                      {appDescriptor.app.supportedRights.map((right, index) => (
                        <Box
                          key={index}
                          sx={{
                            padding: '16px 0',
                            borderBottom: theme => `1px solid ${theme.palette.iron.main}`,
                          }}
                        >
                          <Typography>{right}</Typography>
                        </Box>
                      ))}
                    </>
                  )}
                </Box>
              </SidebarConfigsBody>
            </Fragment>
          )
        }}
      </Formik>
    </SidebarConfigsWrapper>
  )
}
