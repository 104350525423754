import { useEffect, useState } from 'react'
import { InfoRow, TextInput } from '@ketch-com/deck'
import { Box, FormHelperText } from '@mui/material'

// components
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

export interface IdentitySpacesInfoRowType {
  canEdit: boolean
  defaultValue: string
  error?: string
  infoRowTitle: string
  isError?: boolean
  isLoading?: boolean
  isRequired?: boolean
  inputId?: string
  inputName?: string
  multiple?: boolean
  onCancel?: () => void
  onChange?: (value: string) => void
  onSubmit?: (value: string) => void
  placeholder: string
  showOptionalLabel?: boolean
  showRequiredLabel?: boolean
  showValidation?: boolean
  size?: 'medium' | 'small'
  value: string
}

/** @deprecated
 * Use components/InfoRow/TextAreaInfoRow.tsx
 */
/// same functionality of src/pages/orchestration/rightsQueue/stepDetails/components/InlineEdit.tsx
// wrapped in editable InfoRow
export const InfoRowEditableTextField = ({
  canEdit = true,
  defaultValue,
  error,
  infoRowTitle,
  isError,
  isLoading,
  isRequired = false,
  inputId,
  inputName,
  multiple = false,
  onCancel,
  onChange,
  onSubmit,
  placeholder,
  showOptionalLabel = false,
  showRequiredLabel = false,
  showValidation = false,
  size = 'small',
  value,
}: IdentitySpacesInfoRowType) => {
  const [isEditing, setIsEditing] = useState(false)
  const [innerValue, setInnerValue] = useState('')

  const internalInfoRowTitle = `${infoRowTitle} ${
    showRequiredLabel ? '(Required)' : showOptionalLabel ? '(Optional)' : ''
  }`

  useEffect(() => {
    setInnerValue(value)
  }, [value])

  // add edit mode listener for abstracted edit button
  useEffect(() => {
    if (!canEdit) {
      setIsEditing(false)
    }
  }, [canEdit])

  const handleSubmit = () => {
    if (innerValue && innerValue.length) {
      onSubmit?.(innerValue)
      setIsEditing(false)
    }
  }

  const handleOnCancel = () => {
    setIsEditing(false)
    setInnerValue(defaultValue)
    onCancel?.()
  }
  const handleOnChange = (value: string) => {
    setInnerValue(value)
    onChange?.(value)
  }

  const handleEditMode = () => {
    if (!canEdit) return
    setIsEditing(!isEditing)
  }

  const displayedValue = !!innerValue && innerValue.length ? innerValue : defaultValue

  return (
    <InfoRow
      title={internalInfoRowTitle}
      isValidation={showValidation}
      isEditable={canEdit}
      isEditing={isEditing}
      isLoading={isLoading}
      onEditChange={handleEditMode}
      onAcceptChange={handleSubmit}
      onCancelChange={handleOnCancel}
      isEmpty={!displayedValue}
    >
      {!isEditing ? (
        displayedValue || <EmptyValueRenderer />
      ) : (
        <Box display="flex" width="100%" flexDirection="column">
          <TextInput
            sx={{
              '& .MuiFormHelperText-root': {
                color: theme => `${theme.palette.chileanFire.main} !important`,
              },
            }}
            autoFocus
            id={inputId}
            defaultValue={defaultValue}
            error={isError}
            fullWidth
            multiline={multiple}
            name={inputName}
            onChange={e => handleOnChange(e.currentTarget.value)}
            placeholder={placeholder}
            required={isRequired}
            value={innerValue}
            variant="ghost"
            size={size}
          />
          {isError && <FormHelperText sx={{ color: 'chileanFire.main' }}>{error}</FormHelperText>}
        </Box>
      )}
    </InfoRow>
  )
}
