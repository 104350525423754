import { faker } from '@faker-js/faker'

export type Policy = {
  id?: string
  canonicalResourceType?: number
  name?: string
  summary?: string
  riskScore?: number
  riskCategory?: string
  personalDataTypes?: string[]
  dataCategories?: string[]
  purposes?: {
    name?: string
    code?: string
  }[]
}

export type Policies = {
  policies?: Policy[]
  totalResults?: number
}

const dataCategories = [
  'ZIP',
  'First Name',
  'School',
  'Last Name',
  'User ID',
  'SSN',
  'Job Title',
  'Salary',
  'Gender',
  'Email',
  'Middle Initial',
  'Employer',
  'Address',
  'Medical History',
  'Purchase History',
  'Snacking Trends',
  'Shoe Size',
  'Browsing Trends',
  'Search History',
]

const purposes = [
  { name: 'Candidate Recruiting', code: 'candidate_recruiting' },
  { name: 'Analytics', code: 'analytics' },
  { name: 'DSR Processing (Controller)', code: 'dsr_processing' },
  { name: 'Marketing', code: 'marketing' },
]

const names = [
  'EMPLOYEE_PERSONAL_DATA',
  'ss_mysql',
  'AWS SF North Medical',
  'Email Lists Subscribers',
  'VENDORS',
  'Archive',
  'Opt-out backup',
  'XTrytsn980-912',
  'Users',
  'STORE',
  'Archive.iiKKS.LP',
  'CALL CENTER',
  'CUSTOMER',
  'CATALOG_SALES',
  'STORE (backup)',
  'Air_Quality_data_ML',
  'Expired_CATALOG_SALES',
  'Sales_Legacy',
  'Pre-2019 Marketing Data',
  'INCIDENT_REPORTS',
]

const policySummaries = ['Additional policies are required to ensure security', 'No access or deletion policy assigned']
const securitySummaries = ['Security measures are insufficient', 'Additional measures are required to ensure security']

export const generatePolicies = (key: string): Policies => ({
  [key]: names.map(name => {
    const riskScore = faker.datatype.number({ min: 10, max: 100 })
    let riskCategory: string = 'Extreme'
    if (riskScore < 30) riskCategory = 'Acceptable'
    if (riskScore < 60) riskCategory = 'Tolerable'
    if (riskScore < 80) riskCategory = 'Critical'
    return {
      id: faker.datatype.uuid(),
      canonicalResourceType: faker.datatype.number({ min: 1, max: 3 }),
      name,
      summary: faker.helpers.arrayElement(key === 'policies' ? policySummaries : securitySummaries),
      riskScore,
      riskCategory,
      purposes: faker.helpers.arrayElements(purposes, 3),
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: faker.helpers.arrayElements(dataCategories, 16),
    }
  }),
  totalResults: names.length,
})

// Only generate once so that refreshing the page works
// console.info(generatePolicies('policies'))
// console.info(generatePolicies('securityControls'))

export const policiesMockData = {
  policies: [
    {
      id: 'da59eaa9-52cc-4ea1-b0c7-09749a872e72',
      canonicalResourceType: 2,
      name: 'EMPLOYEE_PERSONAL_DATA',
      summary: 'No access or deletion policy assigned',
      riskScore: 79,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'First Name',
        'Shoe Size',
        'Medical History',
        'Salary',
        'Search History',
        'ZIP',
        'Gender',
        'Email',
        'Employer',
        'Address',
        'Middle Initial',
        'Browsing Trends',
        'User ID',
        'Snacking Trends',
        'Purchase History',
        'School',
      ],
    },
    {
      id: '225b72de-bf36-4958-aa22-4988332183e4',
      canonicalResourceType: 2,
      name: 'ss_mysql',
      summary: 'No access or deletion policy assigned',
      riskScore: 58,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'School',
        'Middle Initial',
        'Browsing Trends',
        'ZIP',
        'Gender',
        'Snacking Trends',
        'Purchase History',
        'Job Title',
        'Search History',
        'User ID',
        'SSN',
        'Address',
        'Last Name',
        'Salary',
        'Email',
        'Medical History',
      ],
    },
    {
      id: 'd2b16bfc-1574-446f-8e5d-e81dba744118',
      canonicalResourceType: 1,
      name: 'AWS SF North Medical',
      summary: 'Additional policies are required to ensure security',
      riskScore: 10,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Email',
        'First Name',
        'Address',
        'Browsing Trends',
        'Shoe Size',
        'Snacking Trends',
        'Medical History',
        'Search History',
        'ZIP',
        'School',
        'Middle Initial',
        'User ID',
        'Job Title',
        'Employer',
        'Last Name',
        'Purchase History',
      ],
    },
    {
      id: 'b93d3660-d0e5-4591-8c9a-4b2a29a392a8',
      canonicalResourceType: 3,
      name: 'Email Lists Subscribers',
      summary: 'No access or deletion policy assigned',
      riskScore: 92,
      riskCategory: 'Extreme',
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'User ID',
        'Middle Initial',
        'Job Title',
        'First Name',
        'Address',
        'School',
        'ZIP',
        'Medical History',
        'Salary',
        'Browsing Trends',
        'Employer',
        'Search History',
        'SSN',
        'Last Name',
        'Purchase History',
        'Snacking Trends',
      ],
    },
    {
      id: 'babae317-b384-49d6-af6d-845ec78ca355',
      canonicalResourceType: 2,
      name: 'VENDORS',
      summary: 'Additional policies are required to ensure security',
      riskScore: 59,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'SSN',
        'Browsing Trends',
        'Employer',
        'Gender',
        'School',
        'Snacking Trends',
        'First Name',
        'Job Title',
        'Middle Initial',
        'Purchase History',
        'Last Name',
        'ZIP',
        'Email',
        'User ID',
        'Search History',
        'Medical History',
      ],
    },
    {
      id: 'c55a1d30-2830-4908-954b-7b63a61f63cb',
      canonicalResourceType: 2,
      name: 'Archive',
      summary: 'Additional policies are required to ensure security',
      riskScore: 49,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Job Title',
        'School',
        'ZIP',
        'Last Name',
        'Employer',
        'Medical History',
        'Browsing Trends',
        'Email',
        'Salary',
        'Purchase History',
        'Snacking Trends',
        'First Name',
        'Search History',
        'Middle Initial',
        'SSN',
        'Address',
      ],
    },
    {
      id: '92acee38-3d9d-450d-b752-8f63dea48257',
      canonicalResourceType: 2,
      name: 'Opt-out backup',
      summary: 'No access or deletion policy assigned',
      riskScore: 90,
      riskCategory: 'Extreme',
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Browsing Trends',
        'User ID',
        'First Name',
        'SSN',
        'Shoe Size',
        'Gender',
        'Address',
        'School',
        'Employer',
        'Search History',
        'Last Name',
        'Snacking Trends',
        'Purchase History',
        'Salary',
        'Medical History',
        'ZIP',
      ],
    },
    {
      id: '213b25ed-bea8-4f54-b08b-f94824d1a575',
      canonicalResourceType: 2,
      name: 'XTrytsn980-912',
      summary: 'Additional policies are required to ensure security',
      riskScore: 59,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Gender',
        'Salary',
        'Email',
        'User ID',
        'SSN',
        'Job Title',
        'Browsing Trends',
        'First Name',
        'School',
        'Search History',
        'Middle Initial',
        'Address',
        'Snacking Trends',
        'ZIP',
        'Employer',
        'Shoe Size',
      ],
    },
    {
      id: 'eaefbd40-d37e-4dc5-826e-a24dba021f71',
      canonicalResourceType: 3,
      name: 'Users',
      summary: 'Additional policies are required to ensure security',
      riskScore: 13,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'ZIP',
        'First Name',
        'Purchase History',
        'User ID',
        'Employer',
        'School',
        'Middle Initial',
        'Email',
        'Last Name',
        'Search History',
        'Snacking Trends',
        'Job Title',
        'Gender',
        'Medical History',
        'Shoe Size',
        'Salary',
      ],
    },
    {
      id: 'b925ffa1-c58b-4e50-af22-678e29a70de4',
      canonicalResourceType: 1,
      name: 'STORE',
      summary: 'Additional policies are required to ensure security',
      riskScore: 95,
      riskCategory: 'Extreme',
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'SSN',
        'Middle Initial',
        'School',
        'Search History',
        'Medical History',
        'Job Title',
        'Shoe Size',
        'ZIP',
        'Snacking Trends',
        'Salary',
        'Address',
        'Employer',
        'First Name',
        'Gender',
        'Purchase History',
      ],
    },
    {
      id: '72ae2cb8-821f-4593-9ae0-a17668de2c1e',
      canonicalResourceType: 3,
      name: 'Archive.iiKKS.LP',
      summary: 'No access or deletion policy assigned',
      riskScore: 86,
      riskCategory: 'Extreme',
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'SSN',
        'Browsing Trends',
        'Middle Initial',
        'Snacking Trends',
        'Salary',
        'ZIP',
        'Employer',
        'Last Name',
        'School',
        'First Name',
        'Purchase History',
        'Address',
        'Shoe Size',
        'Job Title',
        'Medical History',
        'Email',
      ],
    },
    {
      id: '5c226156-2cc0-4bfb-b127-d1298526e388',
      canonicalResourceType: 1,
      name: 'CALL CENTER',
      summary: 'No access or deletion policy assigned',
      riskScore: 78,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Address',
        'Browsing Trends',
        'Last Name',
        'Middle Initial',
        'School',
        'SSN',
        'Purchase History',
        'Shoe Size',
        'ZIP',
        'First Name',
        'Search History',
        'Employer',
        'Medical History',
        'Snacking Trends',
        'Job Title',
        'User ID',
      ],
    },
    {
      id: '11f9d815-0578-4b93-9f6c-0b0057a01744',
      canonicalResourceType: 3,
      name: 'CUSTOMER',
      summary: 'Additional policies are required to ensure security',
      riskScore: 38,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Search History',
        'Medical History',
        'First Name',
        'Purchase History',
        'Address',
        'Snacking Trends',
        'Gender',
        'SSN',
        'Middle Initial',
        'School',
        'Salary',
        'Last Name',
        'ZIP',
        'User ID',
        'Shoe Size',
        'Browsing Trends',
      ],
    },
    {
      id: '792b57f2-54af-4a36-8dbf-7c8a8769c630',
      canonicalResourceType: 1,
      name: 'CATALOG_SALES',
      summary: 'Additional policies are required to ensure security',
      riskScore: 86,
      riskCategory: 'Extreme',
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'First Name',
        'Employer',
        'School',
        'Browsing Trends',
        'Job Title',
        'SSN',
        'Purchase History',
        'Salary',
        'Medical History',
        'Middle Initial',
        'ZIP',
        'User ID',
        'Snacking Trends',
        'Last Name',
        'Gender',
        'Email',
      ],
    },
    {
      id: 'be73d99f-4876-4af1-9dbb-3325c4459d32',
      canonicalResourceType: 2,
      name: 'STORE (backup)',
      summary: 'Additional policies are required to ensure security',
      riskScore: 44,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'SSN',
        'Medical History',
        'Job Title',
        'Email',
        'Shoe Size',
        'Gender',
        'Address',
        'User ID',
        'Middle Initial',
        'Salary',
        'Last Name',
        'Purchase History',
        'Search History',
        'First Name',
        'ZIP',
        'Snacking Trends',
      ],
    },
    {
      id: '72a81adc-0231-41ff-9743-891c770b646a',
      canonicalResourceType: 2,
      name: 'Air_Quality_data_ML',
      summary: 'No access or deletion policy assigned',
      riskScore: 37,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Gender',
        'Search History',
        'Address',
        'Employer',
        'Email',
        'User ID',
        'Job Title',
        'Shoe Size',
        'Last Name',
        'Snacking Trends',
        'ZIP',
        'Salary',
        'First Name',
        'Browsing Trends',
        'Medical History',
        'SSN',
      ],
    },
    {
      id: 'a2e53e0b-1a27-4220-a078-7b46e6cb2d61',
      canonicalResourceType: 3,
      name: 'Expired_CATALOG_SALES',
      summary: 'Additional policies are required to ensure security',
      riskScore: 34,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'Shoe Size',
        'Salary',
        'Snacking Trends',
        'Purchase History',
        'SSN',
        'Gender',
        'Employer',
        'Email',
        'Job Title',
        'User ID',
        'Middle Initial',
        'First Name',
        'Medical History',
        'Search History',
        'ZIP',
      ],
    },
    {
      id: '174d284e-dcfd-4246-825f-c342789d025a',
      canonicalResourceType: 2,
      name: 'Sales_Legacy',
      summary: 'No access or deletion policy assigned',
      riskScore: 95,
      riskCategory: 'Extreme',
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Email',
        'Search History',
        'Address',
        'User ID',
        'ZIP',
        'Middle Initial',
        'Browsing Trends',
        'Snacking Trends',
        'Last Name',
        'Medical History',
        'First Name',
        'School',
        'Job Title',
        'Shoe Size',
        'SSN',
        'Purchase History',
      ],
    },
    {
      id: '572ffbb5-1e42-4318-a7e0-5b8ad933d806',
      canonicalResourceType: 2,
      name: 'Pre-2019 Marketing Data',
      summary: 'Additional policies are required to ensure security',
      riskScore: 41,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Snacking Trends',
        'Medical History',
        'Gender',
        'Job Title',
        'Middle Initial',
        'Salary',
        'Email',
        'Shoe Size',
        'First Name',
        'User ID',
        'Address',
        'School',
        'Browsing Trends',
        'Last Name',
        'SSN',
        'ZIP',
      ],
    },
    {
      id: '8b976f4b-bfc7-47e7-90ef-503198135bb9',
      canonicalResourceType: 2,
      name: 'INCIDENT_REPORTS',
      summary: 'Additional policies are required to ensure security',
      riskScore: 56,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Salary',
        'ZIP',
        'Address',
        'Email',
        'Middle Initial',
        'Employer',
        'Search History',
        'Purchase History',
        'User ID',
        'Snacking Trends',
        'First Name',
        'Gender',
        'Medical History',
        'Shoe Size',
        'SSN',
        'Job Title',
      ],
    },
  ],
  totalResults: 20,
}

export const securityControlsMockData = {
  securityControls: [
    {
      id: '26d3a8b8-0b53-4e9a-a4b3-0c807ddd51af',
      canonicalResourceType: 1,
      name: 'EMPLOYEE_PERSONAL_DATA',
      summary: 'Security measures are insufficient',
      riskScore: 47,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Search History',
        'Email',
        'Employer',
        'Middle Initial',
        'ZIP',
        'Job Title',
        'User ID',
        'Salary',
        'Shoe Size',
        'Address',
        'First Name',
        'School',
        'Medical History',
        'Snacking Trends',
        'Purchase History',
        'SSN',
      ],
    },
    {
      id: '3911d391-1dd4-4d27-9cc0-17dfc1c89380',
      canonicalResourceType: 1,
      name: 'ss_mysql',
      summary: 'Additional measures are required to ensure security',
      riskScore: 42,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'School',
        'Email',
        'First Name',
        'Snacking Trends',
        'SSN',
        'User ID',
        'Last Name',
        'Browsing Trends',
        'Middle Initial',
        'Medical History',
        'Shoe Size',
        'Search History',
        'Employer',
        'Address',
        'ZIP',
        'Salary',
      ],
    },
    {
      id: '8fa7a07b-1389-4622-90d8-d9c81c668343',
      canonicalResourceType: 1,
      name: 'AWS SF North Medical',
      summary: 'Security measures are insufficient',
      riskScore: 66,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Email',
        'Browsing Trends',
        'SSN',
        'Address',
        'Middle Initial',
        'Gender',
        'Job Title',
        'School',
        'Last Name',
        'Purchase History',
        'Medical History',
        'Shoe Size',
        'Snacking Trends',
        'User ID',
        'First Name',
        'Salary',
      ],
    },
    {
      id: '24f6c993-3f6b-496e-a698-36f630f6459b',
      canonicalResourceType: 2,
      name: 'Email Lists Subscribers',
      summary: 'Additional measures are required to ensure security',
      riskScore: 17,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'ZIP',
        'Salary',
        'Gender',
        'Middle Initial',
        'Email',
        'Shoe Size',
        'School',
        'First Name',
        'Address',
        'SSN',
        'Employer',
        'Browsing Trends',
        'Medical History',
        'Last Name',
        'User ID',
        'Job Title',
      ],
    },
    {
      id: '4e4fb93b-f347-46ec-818b-59788b4e6500',
      canonicalResourceType: 2,
      name: 'VENDORS',
      summary: 'Additional measures are required to ensure security',
      riskScore: 99,
      riskCategory: 'Extreme',
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'Purchase History',
        'Job Title',
        'Browsing Trends',
        'ZIP',
        'Employer',
        'Shoe Size',
        'Search History',
        'User ID',
        'Medical History',
        'Middle Initial',
        'School',
        'First Name',
        'Gender',
        'SSN',
        'Email',
      ],
    },
    {
      id: '190b9244-f1e7-448c-a1c7-c468360cf04b',
      canonicalResourceType: 2,
      name: 'Archive',
      summary: 'Security measures are insufficient',
      riskScore: 10,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Job Title',
        'Last Name',
        'Employer',
        'Gender',
        'Search History',
        'ZIP',
        'First Name',
        'Medical History',
        'Middle Initial',
        'Shoe Size',
        'Email',
        'User ID',
        'Purchase History',
        'Salary',
        'Address',
        'Snacking Trends',
      ],
    },
    {
      id: '13924dff-ea59-481e-aaa8-682b99d84f8f',
      canonicalResourceType: 3,
      name: 'Opt-out backup',
      summary: 'Additional measures are required to ensure security',
      riskScore: 89,
      riskCategory: 'Extreme',
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Salary',
        'Browsing Trends',
        'Shoe Size',
        'Search History',
        'Purchase History',
        'User ID',
        'Email',
        'SSN',
        'School',
        'Job Title',
        'Gender',
        'First Name',
        'Middle Initial',
        'Employer',
        'Medical History',
        'Last Name',
      ],
    },
    {
      id: 'fbe5ea37-5687-47bc-85c9-228315bf6d64',
      canonicalResourceType: 1,
      name: 'XTrytsn980-912',
      summary: 'Additional measures are required to ensure security',
      riskScore: 39,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'ZIP',
        'Middle Initial',
        'Salary',
        'Job Title',
        'Email',
        'Shoe Size',
        'Employer',
        'Snacking Trends',
        'Browsing Trends',
        'School',
        'Purchase History',
        'Search History',
        'SSN',
        'Medical History',
        'Address',
      ],
    },
    {
      id: '9782a75f-b09c-4102-9e80-74bab0478565',
      canonicalResourceType: 1,
      name: 'Users',
      summary: 'Additional measures are required to ensure security',
      riskScore: 55,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'School',
        'Search History',
        'Last Name',
        'SSN',
        'Medical History',
        'Browsing Trends',
        'Email',
        'User ID',
        'Employer',
        'Job Title',
        'Shoe Size',
        'ZIP',
        'Purchase History',
        'Middle Initial',
        'Address',
        'First Name',
      ],
    },
    {
      id: 'c2678519-0b6c-4d22-bf7f-39b033bec7bb',
      canonicalResourceType: 3,
      name: 'STORE',
      summary: 'Security measures are insufficient',
      riskScore: 16,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Employer',
        'Job Title',
        'Last Name',
        'Middle Initial',
        'User ID',
        'Search History',
        'Gender',
        'Purchase History',
        'School',
        'Salary',
        'Browsing Trends',
        'Shoe Size',
        'ZIP',
        'Snacking Trends',
        'Email',
        'Address',
      ],
    },
    {
      id: '2cbd6984-ceb1-4fbd-953d-3e6ab7298321',
      canonicalResourceType: 3,
      name: 'Archive.iiKKS.LP',
      summary: 'Additional measures are required to ensure security',
      riskScore: 21,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'First Name',
        'User ID',
        'Snacking Trends',
        'School',
        'Purchase History',
        'ZIP',
        'Shoe Size',
        'Browsing Trends',
        'Gender',
        'Employer',
        'Salary',
        'Job Title',
        'Address',
        'SSN',
        'Email',
        'Last Name',
      ],
    },
    {
      id: '4b3f4bc7-8bf9-4a36-aea5-63883b9cb1ab',
      canonicalResourceType: 3,
      name: 'CALL CENTER',
      summary: 'Additional measures are required to ensure security',
      riskScore: 17,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Medical History',
        'Gender',
        'User ID',
        'Shoe Size',
        'Job Title',
        'Address',
        'Salary',
        'First Name',
        'ZIP',
        'Middle Initial',
        'Search History',
        'Last Name',
        'School',
        'Email',
        'Employer',
        'SSN',
      ],
    },
    {
      id: '70afef1d-f5f3-4534-9ad5-2b2b3f5d7b5e',
      canonicalResourceType: 3,
      name: 'CUSTOMER',
      summary: 'Security measures are insufficient',
      riskScore: 63,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Purchase History',
        'Job Title',
        'School',
        'Shoe Size',
        'Search History',
        'Email',
        'Middle Initial',
        'Browsing Trends',
        'Salary',
        'Employer',
        'Address',
        'First Name',
        'Medical History',
        'Snacking Trends',
        'SSN',
        'Gender',
      ],
    },
    {
      id: '7a4e5816-a220-4c20-80d1-51fd7ecd5472',
      canonicalResourceType: 1,
      name: 'CATALOG_SALES',
      summary: 'Additional measures are required to ensure security',
      riskScore: 16,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'SSN',
        'Job Title',
        'Address',
        'Last Name',
        'School',
        'Browsing Trends',
        'User ID',
        'Salary',
        'Gender',
        'Employer',
        'ZIP',
        'Purchase History',
        'Email',
        'First Name',
        'Shoe Size',
        'Middle Initial',
      ],
    },
    {
      id: '259c892a-1e46-4a26-83e3-6bab17d2c197',
      canonicalResourceType: 3,
      name: 'STORE (backup)',
      summary: 'Additional measures are required to ensure security',
      riskScore: 96,
      riskCategory: 'Extreme',
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Gender',
        'First Name',
        'Purchase History',
        'Snacking Trends',
        'User ID',
        'School',
        'Employer',
        'Browsing Trends',
        'Last Name',
        'Medical History',
        'Salary',
        'Middle Initial',
        'Address',
        'Email',
        'Search History',
        'Shoe Size',
      ],
    },
    {
      id: 'cd8a034e-9034-4d23-a483-0e136290222f',
      canonicalResourceType: 3,
      name: 'Air_Quality_data_ML',
      summary: 'Additional measures are required to ensure security',
      riskScore: 100,
      riskCategory: 'Extreme',
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Employer',
        'Search History',
        'Medical History',
        'Browsing Trends',
        'Address',
        'Purchase History',
        'Job Title',
        'Snacking Trends',
        'First Name',
        'Shoe Size',
        'Middle Initial',
        'ZIP',
        'Email',
        'School',
        'Gender',
        'Last Name',
      ],
    },
    {
      id: 'e7a7c719-2daf-4539-910b-4a424bb3d181',
      canonicalResourceType: 3,
      name: 'Expired_CATALOG_SALES',
      summary: 'Additional measures are required to ensure security',
      riskScore: 11,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Employer',
        'Middle Initial',
        'First Name',
        'Last Name',
        'SSN',
        'Salary',
        'School',
        'Purchase History',
        'Gender',
        'ZIP',
        'Shoe Size',
        'Search History',
        'User ID',
        'Browsing Trends',
        'Email',
        'Medical History',
      ],
    },
    {
      id: 'b5a25cd0-d582-455d-bc16-7c1765741cec',
      canonicalResourceType: 2,
      name: 'Sales_Legacy',
      summary: 'Security measures are insufficient',
      riskScore: 96,
      riskCategory: 'Extreme',
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Browsing Trends',
        'Purchase History',
        'School',
        'Gender',
        'Employer',
        'Email',
        'Last Name',
        'Job Title',
        'ZIP',
        'Shoe Size',
        'Salary',
        'First Name',
        'Address',
        'Snacking Trends',
        'Search History',
        'Middle Initial',
      ],
    },
    {
      id: 'cba1c25e-8fe7-42ba-9712-52f6a1ade182',
      canonicalResourceType: 3,
      name: 'Pre-2019 Marketing Data',
      summary: 'Additional measures are required to ensure security',
      riskScore: 72,
      riskCategory: 'Critical',
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Employer',
        'User ID',
        'SSN',
        'Middle Initial',
        'First Name',
        'Gender',
        'Snacking Trends',
        'Search History',
        'Medical History',
        'Shoe Size',
        'Email',
        'Address',
        'Job Title',
        'Last Name',
        'Salary',
        'Purchase History',
      ],
    },
    {
      id: '8659de2a-6e1a-4763-a7ec-6afe3b952384',
      canonicalResourceType: 3,
      name: 'INCIDENT_REPORTS',
      summary: 'Additional measures are required to ensure security',
      riskScore: 95,
      riskCategory: 'Extreme',
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Medical History',
        'Address',
        'Email',
        'Job Title',
        'Shoe Size',
        'Salary',
        'Purchase History',
        'Employer',
        'Middle Initial',
        'School',
        'Gender',
        'Last Name',
        'Snacking Trends',
        'SSN',
        'Search History',
        'User ID',
      ],
    },
  ],
  totalResults: 20,
}
