import React from 'react'
import { Checkbox } from 'components/ui-kit/checkbox/Checkbox'
import { clearAttributeUpdateErrors, deselectDataset, selectDataset } from 'store/dsrTransponderFormSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getPolicyStatements } from 'store/dsrTransponderFormSlice/selectors'
import { Statement } from 'store/dsrTransponderFormSlice'
import { useFormikContext } from 'formik'
import { IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { getObligationEffectByCanonicalRightCode } from '../utils'

type Props = {
  datasetCode: string
  activeDatabase: string
}

export const DatasetCheckboxCellRenderer: React.FC<Props> = ({ datasetCode, activeDatabase }) => {
  const policyStatements = useAppSelector(getPolicyStatements)
  const isChecked = !!policyStatements?.[activeDatabase]?.statements?.[datasetCode]
  const dispatch = useAppDispatch()
  const { values: formikValues } = useFormikContext<IWorkflowConfig>()

  const handleCheckboxOnChange = () => {
    if (!isChecked) {
      const policyPayload = {
        ...policyStatements,
        [activeDatabase]: {
          ...policyStatements?.[activeDatabase],
          resource: activeDatabase,
          statements: {
            ...policyStatements?.[activeDatabase]?.statements,
            [`${datasetCode}`]: {
              effect: getObligationEffectByCanonicalRightCode(formikValues?.canonicalRightCode || ''),
              assetType: 'dataset',
            },
          },
        },
      }
      dispatch(selectDataset(policyPayload))
    } else {
      const copyPolicyStatements = { ...policyStatements }
      const statementEntries = Object.entries(copyPolicyStatements[activeDatabase].statements as Statement).filter(
        el => !(el[0] === datasetCode || el[1]?.parentAssetCode === datasetCode),
      )
      const updatedStatements = Object.fromEntries(statementEntries)
      const deselectPayload = {
        ...copyPolicyStatements,
        [activeDatabase]: { ...copyPolicyStatements[activeDatabase], statements: updatedStatements },
      }
      dispatch(deselectDataset(deselectPayload))
      dispatch(clearAttributeUpdateErrors())
    }
  }

  return <Checkbox value={isChecked} onChange={handleCheckboxOnChange} />
}
