import Box from '@mui/material/Box'

import { styled } from '@mui/material/styles'

import { Routes, Route, useLocation } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ViewLayoutHeaderLeftMenu, ViewLayoutLeftMenu } from 'components/ui-layouts/viewLayoutLeftMenu'
import { ReactComponent as AccessUtilizationIcon } from 'assets/icons/access-utilization-widget-icon.svg'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/search.svg'
import { AccessUtilizationActions, AccessUtilizationDetails, AccessUtilizationMenu } from './components'
import { teamMembersAlt } from 'pages/insights/activityAndAccess/__mocks__/generateDummyTeamMembers'
import { relativePath } from 'utils/routing/relativePath'

const PREFIX = 'AccessUtilizationContainer'

const classes = {
  menuContainer: `${PREFIX}-menuContainer`,
  contentContainer: `${PREFIX}-contentContainer`,
  emptyState: `${PREFIX}-emptyState`,
  emptyStateIcon: `${PREFIX}-emptyStateIcon`,
}

const StyledBox = styled(Box)(({ theme: { spacing, palette } }) => ({
  [`& .${classes.menuContainer}`]: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: spacing(1.375),
  },

  [`& .${classes.contentContainer}`]: {
    flex: 2.54,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: spacing(1.375),
  },

  [`& .${classes.emptyState}`]: {
    backgroundColor: palette.whiteFaded.main,
    paddingTop: spacing(24),
  },

  [`& .${classes.emptyStateIcon}`]: {
    opacity: 0.45,
  },
}))

type Props = {}

export const AccessUtilizationContainer: React.FC<Props> = () => {
  const location = useLocation()

  const breadcrumbs = [
    { title: 'Reports', link: RoutesManager.insights.root.getURL() },
    { title: 'Activity & Access', link: RoutesManager.insights.activityAndAccess.root.getURL() },
    { title: 'Access Utilization', link: RoutesManager.insights.activityAndAccess.accessUtilization.root.getURL() },
  ]

  return (
    <ViewLayoutLeftMenu breadcrumbs={breadcrumbs}>
      <ViewLayoutHeaderLeftMenu
        title="Access Utilization"
        details="Most active data users within the organization"
        backTo={RoutesManager.insights.activityAndAccess.root.getURL()}
        actions={<AccessUtilizationActions />}
        icon={<AccessUtilizationIcon />}
      />
      <StyledBox display="flex" gap={1.5}>
        {/* Left Menu  */}

        <Box className={classes.menuContainer}>
          <AccessUtilizationMenu teamMembers={teamMembersAlt} />
        </Box>

        {/* Content */}

        <Routes location={location}>
          <Route
            path={relativePath(
              RoutesManager.insights.activityAndAccess.accessUtilization.view.root.path,
              RoutesManager.insights.activityAndAccess.accessUtilization.root.path,
            )}
            element={<AccessUtilizationDetails />}
          />

          <Route
            path={relativePath(
              RoutesManager.insights.activityAndAccess.accessUtilization.root.path,
              RoutesManager.insights.activityAndAccess.accessUtilization.root.path,
            )}
            element={
              <Box className={classes.contentContainer}>
                <EmptyState
                  titleTextVariant="faded"
                  descriptionTextVariant="faded"
                  className={classes.emptyState}
                  iconClassName={classes.emptyStateIcon}
                  variant="page"
                  icon={<EmptyStateIcon />}
                  title="Select a team member"
                  description="Check the panel on the left to look up and select the user to see the insights."
                  spacerHeight={1200}
                />
              </Box>
            }
          />
          {/* <Route to={RoutesManager.insights.activityAndAccess.accessUtilization.root.getURL()} /> */}
        </Routes>
      </StyledBox>
    </ViewLayoutLeftMenu>
  )
}
