import { useSearchParams } from 'react-router-dom'
import { ObjectLiteral } from 'interfaces'

/**
 * Custom hook that provides easy access to query parameters in the URL using `react-router-dom`'s `useSearchParams`.
 * @template T An object type that represents the query parameters in the URL.
 */
export function useQueryParams<T extends ObjectLiteral>() {
  /**
   * The current query parameters as a `URLSearchParams` object.
   */
  const [searchParams, setSearchParams] = useSearchParams()

  /**
   * An object representing the current query parameters, derived from `searchParams`.
   */
  const queries = Object.fromEntries(searchParams.entries()) as T

  /**
   * Sets the value of a single query parameter in the URL.
   * @param key The key of the query parameter to set.
   * @param value The new value of the query parameter.
   */
  const setQueryParam = (key: keyof T, value: string | string[] | number | number[] | boolean) => {
    setSearchParams(prevSearchParams => {
      prevSearchParams.set(key as string, String(value))
      return prevSearchParams
    })
  }

  /**
   * Sets the values of multiple query parameters in the URL.
   * @param newParams An array of objects representing the new key-value pairs to set.
   */
  const setMultipleQueryParams = (
    newParams: {
      key: keyof T
      value: string | string[] | number | number[] | boolean
    }[],
  ) => {
    setSearchParams(prevSearchParams => {
      newParams?.forEach?.(({ key, value }) => {
        prevSearchParams.set(key as string, String(value))
      })
      return prevSearchParams
    })
  }

  /**
   * Removes a single query parameter from the URL.
   * @param key The key of the query parameter to remove.
   */
  const removeQueryParam = (key: keyof T) => {
    if (!(key in queries)) return
    setSearchParams(prevSearchParams => {
      prevSearchParams.delete(key as string)
      return prevSearchParams
    })
  }

  /**
   * Removes all query parameters from the URL.
   */
  const removeAllQueryParams = (): void => {
    setSearchParams({})
  }

  /**
   * Removes a single query parameter from the URL, setting its value to `undefined`.
   * @param key The key of the query parameter to remove.
   */
  const removeSingleQueryParam = (key: string) => {
    setSearchParams(key, undefined)
  }

  /**
   * Removes multiple query parameters from the URL, setting their values to `undefined`.
   * @param keys An array of keys of the query parameters to remove.
   */
  const removeMultipleQueryParams = (keys: string[]) => {
    keys?.forEach?.(key => setSearchParams(key, undefined))
  }

  return {
    /**
     * An object representing the current query parameters in the URL.
     */
    queries,
    setQueryParam,
    setMultipleQueryParams,
    removeQueryParam,
    removeAllQueryParams,
    removeSingleQueryParam,
    removeMultipleQueryParams,
  }
}
