import { API } from 'utils/api/api'
import { APIRequestParams } from 'api/common/utils'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { RightStatsPeriodDTO, ChartTrendPeriod, ChartTrendPeriodApiQueryParam } from 'interfaces/rights/rightStats'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { DataRoleType } from 'interfaces/events'

const getApiTrendPeriod = (trendPeriod: ChartTrendPeriod): ChartTrendPeriodApiQueryParam => {
  switch (trendPeriod) {
    case ChartTrendPeriod.DAILY:
      return ChartTrendPeriodApiQueryParam.DAILY
    case ChartTrendPeriod.WEEKLY:
      return ChartTrendPeriodApiQueryParam.WEEKLY
    case ChartTrendPeriod.MONTHLY_PAST_TWELVE:
      return ChartTrendPeriodApiQueryParam.MONTHLY
    case ChartTrendPeriod.MONTHLY_PAST_CAL_YEAR:
      return ChartTrendPeriodApiQueryParam.MONTHLY
  }
}

type Params = APIRequestParams<{
  organizationCode: string
  trendPeriod: ChartTrendPeriod
  policyScope?: PolicyScopeDTO['code']
  dataSubjectType?: DataSubjectTypeDTO['code']
  dataRole?: DataRoleType
  startDate?: string
  endDate?: string
}>

type Response = {
  report: RightStatsPeriodDTO
}

export const fetchRightsStatsPeriod = ({
  organizationCode,
  trendPeriod,
  policyScope,
  dataSubjectType,
  dataRole,
  startDate,
  endDate,
}: Params) => {
  const apiTrendPeriod = getApiTrendPeriod(trendPeriod)

  return API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/right-stats/time-series-${apiTrendPeriod}`,
      params: {
        filters: {
          policyScopeCode: policyScope,
          dataSubjectTypeCode: dataSubjectType,
          dataRole: dataRole,
          startDate,
          endDate,
          ordering: 'time_stamp desc',
        },
      },
    }),
  )
}
