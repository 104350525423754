import React, { Fragment } from 'react'
import Grid from '@mui/material/Grid'
import { Formik } from 'formik'
import { compact, isUndefined } from 'lodash'

import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { IApprovalActivityParams, ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { getStepsBeforeStep } from 'pages/orchestration/workflows/edit/utils/steps/getStepsBeforeStep'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'
import { useUsers } from 'api/users/queries/useUsers'

import { getApprovalInitialValues } from 'pages/orchestration/workflows/edit/utils/forms/activities/approval/approvalInitialValues'
import { getApprovalValidationSchema } from 'pages/orchestration/workflows/edit/utils/forms/activities/approval/approvalValidation'
import { formatCodeFromName } from 'utils/formatters'
import { Box, Typography } from '@mui/material'
import { FormInput } from 'components/form/FormInput'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { FormAssigneeOrTeamSelector } from 'components/form/FormAssigneeOrTeamSelector'

interface IApprovalFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const ApprovalForm: React.FC<IApprovalFormProps> = props => {
  const { step, steps, handleClose, handleRemove, handleSave } = props
  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))
  const prependingSteps = getStepsBeforeStep({ step, steps })

  const { isLoading } = useUsers({ params: { active: true } })

  const activitiesOptions = prependingSteps
    .filter(step => {
      const isActivity = !isUndefined(step.activity)
      const isWorkflow = !isUndefined(step.workflow)
      const isApproval = step?.activity?.code === WorkflowActivityCode.APPROVAL

      return (isActivity || isWorkflow) && !isApproval
    })
    .map(step => ({
      id: step.ID,
      name: step.code || step.ID,
    }))

  const onSubmit = (values: IApprovalActivityParams) => {
    handleSave({
      step: {
        ...step,
        valid: true,
        code: values.code,
        description: values.description,
        activity: {
          ...step.activity,
          params: {
            approver: values.approver,
            details: values.details,
            activity: values.activity,
            timeToCompleteDays: values.timeToCompleteDays,
          },
        } as ICanvasStep['activity'],
      },
    })
  }

  return (
    <SidebarConfigsWrapper isLoading={isLoading} handleClose={handleClose}>
      <Formik
        initialValues={getApprovalInitialValues({ step })}
        validationSchema={getApprovalValidationSchema({ usedCodes })}
        validateOnMount
        onSubmit={onSubmit}
      >
        {form => {
          const { isValid, setFieldValue } = form

          return (
            <Fragment>
              <SidebarConfigsHeader
                isValid={isValid}
                step={step}
                steps={steps}
                handleSave={() => form.submitForm()}
                handleRemove={() => handleRemove({ step })}
                handleClose={handleClose}
                copyable
              />

              <SidebarConfigsBody
                sx={{
                  px: 3,
                }}
              >
                <Box pb={5}>
                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormInput
                          required
                          formPropertyName="description"
                          fullWidth
                          label="Description Label"
                          placeholder="Enter description"
                          onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
                          shouldUpdateDebounced
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <FormInput
                        formPropertyName="code"
                        fullWidth
                        label="Code"
                        placeholder="Enter code"
                        required
                        shouldUpdateDebounced
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box pb={5}>
                  <Box marginBottom={3}>
                    <Typography variant="h3">Approval Details</Typography>
                  </Box>

                  <FormAssigneeOrTeamSelector label="Approver" name="approver" />

                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormDroplistButton
                          required
                          fullWidth
                          label="Activity / Workflow"
                          formPropertyName="activity"
                          placeholder="Select Activity / Workflow"
                          items={activitiesOptions}
                          valueKey="id"
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormInput
                          formPropertyName="details"
                          fullWidth
                          label="Approval Details"
                          placeholder="Enter approval details"
                          required
                          shouldUpdateDebounced
                          multiline
                          rows={3}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom={3}>
                    <FormInput
                      type="number"
                      formPropertyName="timeToCompleteDays"
                      label="Time To Complete (Days)"
                      placeholder="0"
                      inputWidth="80px"
                      shouldUpdateDebounced
                    />
                  </Box>
                </Box>
              </SidebarConfigsBody>
            </Fragment>
          )
        }}
      </Formik>
    </SidebarConfigsWrapper>
  )
}
