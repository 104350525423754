import { formatRequestString } from '../../../utils/helpers/api/queryString'
import { API } from '../../../utils/api/api'

type Params = {
  activityId: string
}

export const deleteProcessingActivity = ({ activityId }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/processing-activities/${activityId}`,
    }),
  )
