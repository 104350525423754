/**
 * Ensure that the input is an array. If the input is undefined, return an empty array.
 *
 * @param things - An array or undefined.
 * @returns The input array or an empty array if the input is undefined.
 * @template T The type of elements in the array.
 */
export function ensureArray<T>(things?: T[]): T[] {
  return things || []
}
