import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import {
  ConfirmTrackersWithNewPurposesResponseBody,
  ConfirmTrackersWithNewPurposesParams,
} from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type params = APIRequestParams<ConfirmTrackersWithNewPurposesParams>

export const confirmTrackersWithNewPurposes = ({ searchString }: params) =>
  API.put<ConfirmTrackersWithNewPurposesResponseBody>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/trackers/new-purposes`,
      params: {
        filters: {
          ...(searchString && {
            searchString,
          }),
        },
      },
    }),
  )
