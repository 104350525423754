import React, { useMemo } from 'react'
import { Formik } from 'formik'

import { UserShortDTO } from 'interfaces/users/users'
import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { Modal } from 'components/ui-kit/modal/Modal'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { Group } from 'components/ui-layouts/group/Group'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { WorkflowExecutionAssignee } from 'pages/orchestration/rightsQueue/components/WorkflowExecutionAssignee'
import { Box } from '@mui/material'
import { Button } from '@ketch-com/deck'
import { useAuth } from 'utils/hooks/useAuth'
import { MaybeNull } from 'interfaces'
import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { validationSchema, WorkflowExecutionStepReassignFormValues } from './utils'
import { WorkflowExecutionStepStatusRenderer } from '../WorkflowExecutionStepStatusRenderer'

type Props = {
  isReady: boolean
  users: UserShortDTO[]
  workflowExecutionStep: WorkflowExecutionStepDTO
  workflowExecutionStepDetails: MaybeNull<WorkflowExecutionStepDetailsDTO>
  onSubmit: (values: { assigneeId: string }) => Promise<void>
  onClose: () => void
}

export const WorkflowExecutionStepReassignModal: React.FC<Props> = ({
  isReady,
  users,
  workflowExecutionStep,
  workflowExecutionStepDetails,
  onSubmit,
  onClose,
}) => {
  const { userData } = useAuth()
  const { isPermitted } = useIsPermitted()
  const canReassign = isPermitted(PERMISSIONS.WORKFLOW_TASK_ASSIGN)
  const { assignee, status } = workflowExecutionStep
  const stepDetailsText = workflowExecutionStepDetails?.activity?.params?.details

  const initialValues: WorkflowExecutionStepReassignFormValues = useMemo(
    () => ({
      assigneeId: assignee || '',
    }),
    [assignee],
  )

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isValid, isSubmitting, setFieldValue }) => (
        <Modal
          isLoading={!isReady}
          isValid={isValid}
          isSaving={isSubmitting}
          contentWidth={840}
          title={
            <Group gap={16} alignItems="center">
              Task&nbsp;
              <WorkflowExecutionStepStatusRenderer status={status} />
            </Group>
          }
          cancelBtnLabel="Close"
          onCancelBtnClick={onClose}
          {...(canReassign
            ? { onSubmitBtnClick: submitForm, submitBtnLabel: 'Reassign' }
            : { cancelBtnVariant: 'primary' })}
        >
          <Box mt={2.25}>
            <FormRow>
              <Label>Details</Label>
              <TextCellRenderer value={stepDetailsText} />
            </FormRow>

            <FormRow>
              {canReassign ? (
                <Box display="flex" flexDirection="column">
                  <FormDropdown
                    label="Task Assignee"
                    placeholder="Select Assignee"
                    name="assigneeId"
                    valueKey="ID"
                    items={users}
                    renderItem={user => <WorkflowExecutionAssignee assignee={user} />}
                  />
                  <Button
                    sx={{
                      marginTop: 1,
                      maxWidth: '120px',
                    }}
                    color="secondary"
                    onClick={() => setFieldValue('assigneeId', userData?.userId)}
                  >
                    Assign to me
                  </Button>
                </Box>
              ) : (
                <>
                  <Label>Task Assignee</Label>
                  {(() => {
                    const stepAssignee = users.find(({ ID }) => ID === assignee)

                    return stepAssignee ? <WorkflowExecutionAssignee assignee={stepAssignee} /> : <EmptyValueRenderer />
                  })()}
                </>
              )}
            </FormRow>
          </Box>
        </Modal>
      )}
    </Formik>
  )
}
