export const getAssetsListContainerMaxHeight = () => {
  const element = document.getElementById('assetListContainer') as HTMLDivElement

  if (element) {
    const top = element.getBoundingClientRect().top
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    const maxHeight = viewHeight - top - 30
    return maxHeight
  } else {
    return 0
  }
}
