import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  createStyles({
    root: ({ width }: { width: number | string }) => ({
      margin: '0 auto',
      width,
    }),
  }),
  { name: 'ContentBoundaries' },
)

export interface Props {
  children?: React.ReactNode
  className?: string
  /** Custom component width */
  width?: number | string
}

export const ContentBoundaries: React.FC<Props> = ({ width = 1280, className, children }) => {
  const classes = useStyles({ width })

  return <div className={clsx(classes.root, className)}>{children}</div>
}
