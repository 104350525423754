import { useState } from 'react'
import { useDebounce } from 'react-use'

import { useQueryParams } from 'utils/hooks/useQueryParams'
import { usePurposesInfinite } from 'api/purposes/queries/usePurposesInfinite'
import { usePurposesSearchOptions } from '.'
import { AssetListFilters } from 'pages/assetManager/assets/list/interfaces'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { getMegaFilterState } from 'store/megaFilterStateSlice/selectors'

/*
    TODO:BAC - note, this component is a work in progress.  It's not rendered in the UI yet.
    It will be a helper component the the MegaFilter workflow.
*/
export const useMegaFilterPurposesExpandableRowUtils = () => {
  const { purposesState } = useAppSelector(getMegaFilterState)
  const dispatch = useAppDispatch()
  const { queries, setQueryParam } = useQueryParams<AssetListFilters>()
  const [searchString, setSearchString] = useState<string>('')
  const {
    data: infinitePurposes,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    hasNextPage,
  } = usePurposesInfinite({
    /* onSettled */
  })

  const {
    setSearchPurposeValue,
    // purposes: purposeSearchOptions,
    isLoadingPurposes: isLoadingSearchPurposes,
  } = usePurposesSearchOptions()

  const handleScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    const scrollHeight = e.currentTarget.scrollHeight
    const scrollTop = e.currentTarget.scrollTop
    const scrollDiff = scrollHeight - scrollTop
    const clientHeight = e.currentTarget.clientHeight
    const shouldFetchNextPage = scrollDiff - 10 <= clientHeight
    if (shouldFetchNextPage && hasNextPage) fetchNextPage()
  }

  useDebounce(
    () => {
      if (searchString) {
        setSearchPurposeValue(searchString)
      } else {
        setSearchPurposeValue('')
      }
    },
    500,
    [searchString],
  )

  return {
    infinitePurposes,
    handleScroll,
    setQueryParam,
    queries,
    isFetchingNextPage,
    isLoading,
    hasNextPage,
    setSearchString,
    searchString,
    isLoadingSearchPurposes,
    purposesState,
    dispatch,
  }
}
