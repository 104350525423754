import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { Text } from 'components/ui-kit/typography/Text'
import { AuthenticatorDTO } from '@ketch-com/figurehead'
import { Chip } from 'components/ui-kit/chip/Chip'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'

const PREFIX = 'WorkflowsCountCellRenderer'

const classes = {
  dataCategoriesCircle: `${PREFIX}-dataCategoriesCircle`,
  dataCategoriesPill: `${PREFIX}-dataCategoriesPill`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.dataCategoriesCircle}`]: {
    backgroundColor: palette.marine.main,
    borderRadius: '50%',
    padding: spacing(0.2, 0, 0, 0),
    height: spacing(3),
    width: spacing(3),
  },

  [`& .${classes.dataCategoriesPill}`]: {
    backgroundColor: palette.marine.main,
    padding: spacing(0, 0.75, 0.2, 0.75),
    height: spacing(3),
  },
}))

type Props = {
  authenticator: AuthenticatorDTO
}

export const WorkflowsCountCellRenderer: React.FC<Props> = ({ authenticator }) => {
  const workflowsCount = authenticator?.workflows?.length || 0

  if (workflowsCount === 0) return <EmptyValueRenderer color="darkDuskFaded" />

  if (String(workflowsCount).length > 2) {
    return (
      <Chip className={classes.dataCategoriesPill}>
        <Text size={11} weight={500} color="persianBlue" lineHeight="15px">
          {workflowsCount}
        </Text>
      </Chip>
    )
  }

  return (
    <StyledBox className={classes.dataCategoriesCircle} display="flex" alignItems="center" justifyContent="center">
      <Text size={11} weight={500} color="persianBlue">
        {workflowsCount}
      </Text>
    </StyledBox>
  )
}
