export const defaultInitialValues = {
  confirmPassword: '',
  email: '',
  familyName: '',
  givenName: '',
  orgCode: '',
  orgName: '',
  password: '',
  plan: '',
  fullName: '',
  website: '',
  privacyPolicyLink: '',
  marketingConsent: false,
}

export const orgNameHint =
  'The organization name is used to help you login going forward, so make it something you like that is easy to remember!'
