import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createVerifier } from '../fetchers/createVerifier'

const useCustomMutation = createUseMutation({
  mutationFn: createVerifier,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateVerifier = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
