import { Banner } from '@ketch-com/deck'
import { Box } from '@mui/material'
import pluralize from 'pluralize'
import React from 'react'

type Props = {
  daysToInvokeAppeal: number
}

export const RequestEligibleForAppealWarning: React.FC<Props> = ({ daysToInvokeAppeal }) => {
  return (
    <Box display="flex" alignItems="center">
      <Banner isInfoBox isCompact severity="info" variant="standard">
        This request is eligible for appeal. The Data Subject will have {daysToInvokeAppeal}{' '}
        {pluralize('day', daysToInvokeAppeal)} to invoke their right to appeal.
      </Banner>
    </Box>
  )
}
