/**
 * Truncates a string if it exceeds a specified number of characters, adding an ellipse at the end.
 * @param {string} str - The string to truncate.
 * @param {number} maxChars - The maximum number of characters to allow in the truncated string.
 * @returns {string} - The truncated string, with an ellipse added if necessary.
 */
export function truncateStringWithEllipse(str?: string, maxChars?: number): string {
  if (!str) return ''
  if (!maxChars) return str

  if (str.length <= maxChars) {
    return str
  } else {
    return str.slice(0, maxChars) + '...'
  }
}
