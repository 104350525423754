import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import clsx from 'clsx'
import { Tab as MUITab, Tabs as MUITabs } from '@mui/material'
import { Theme } from '@mui/material'

type UseStylesProps = {
  tabSpacingOverride?: number
}

const useStyles = makeStyles<Theme, UseStylesProps>(
  ({ typography, palette, spacing }) =>
    createStyles({
      baseTabs: {
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 0,

        '& .MuiTabs-indicator': {
          background: palette.darkDusk.main,
        },

        '& .MuiTab-textColorInherit': {
          opacity: 1,
        },
      },
      baseTab: {
        marginRight: ({ tabSpacingOverride }) => spacing(tabSpacingOverride || 1),
        textTransform: 'none',
        color: palette.darkDuskFaded.main,

        '&.Mui-selected': {
          color: palette.darkDusk.main,
        },

        '&.Mui-disabled': {
          color: palette.fadedDarkGrey.main,
          cursor: 'no-drop',
        },

        '&:not(.Mui-selected)': {
          '&:hover': {
            color: palette.royalBlue.main,
          },

          '&:active': {
            color: palette.persianBlue.main,
          },
        },

        '& .MuiTab-wrapper': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          fontFamily: typography.fontFamily,
          letterSpacing: '0.01em',
        },

        '&.MuiTab-labelIcon': {
          minHeight: 0,
        },

        '& .MuiTab-wrapper svg': {
          marginRight: 12,
          marginBottom: '0 !important',
        },
      },

      // Sizes:
      sizeRegular: {
        '& $baseTab': {
          minWidth: 0,
          padding: '6px 0 14px',
          marginRight: ({ tabSpacingOverride }) => spacing(tabSpacingOverride || 1),
        },

        '& $baseTab .MuiTab-wrapper': {
          fontSize: typography.pxToRem(14),
          fontWeight: 600,
          lineHeight: typography.pxToRem(20),
        },

        '& $baseTab .MuiTab-wrapper svg': {
          fontSize: typography.pxToRem(20),
        },
      },
      sizeSmall: {
        '& $baseTab': {
          minWidth: 0,
          padding: '6px 0 8px',
          marginRight: ({ tabSpacingOverride }) => spacing(tabSpacingOverride || 1),
        },

        '& $baseTab .MuiTab-wrapper': {
          fontSize: typography.pxToRem(12),
          fontWeight: 600,
          lineHeight: typography.pxToRem(18),
        },

        '& $baseTab .MuiTab-wrapper svg': {
          fontSize: typography.pxToRem(18),
        },
      },
    }),
  { name: 'Tabs' },
)

interface TabRootClasses {
  root?: string
  indicator?: string
  scrollable?: string
  scroller?: string
}

export interface TabsConfig {
  key: string | number
  title: React.ReactNode
  icon?: any
  active?: boolean
  disabled?: boolean
  link?: string
  onClick?: (event: React.MouseEvent) => void
}

export interface Props {
  /** Should tabs have scroll buttons */
  scrollButtons?: boolean
  /** Tabs size - reflects the size of the tab */
  size?: 'small' | 'regular'
  /** Tabs custom className */
  className?: string
  /** Tab custom className */
  tabClassName?: string
  /** Tabs list */
  config: TabsConfig[]
  /** Tab indicator props */
  tabIndicatorProps?: Partial<React.HTMLAttributes<HTMLDivElement>>
  /** Tabs root classes to override original styles */
  tabRootClasses?: TabRootClasses
  /** Hack to override tab spacing */
  tabSpacingOverride?: number
}

/**
 * -
 */
export const Tabs: React.FC<Props> = ({
  config,
  tabRootClasses,
  scrollButtons = false,
  size = 'regular',
  className,
  tabClassName,
  tabIndicatorProps = {},
  tabSpacingOverride,
}) => {
  const classes = useStyles({ tabSpacingOverride })

  const tabsClassName = clsx(
    classes.baseTabs,
    {
      [classes.sizeRegular]: size === 'regular',
      [classes.sizeSmall]: size === 'small',
    },
    className,
  )

  const activeTab = config.filter(tab => tab.active)[0] || config[0]

  return (
    <MUITabs
      TabIndicatorProps={tabIndicatorProps}
      scrollButtons={!!scrollButtons}
      value={activeTab}
      classes={tabRootClasses}
      className={tabsClassName}
    >
      {config.map(tab => (
        <MUITab
          className={clsx(classes.baseTab, tabClassName)}
          disableRipple
          disableTouchRipple
          key={tab.key}
          disabled={tab.disabled}
          icon={tab.icon}
          label={tab.title}
          value={tab}
          onClick={tab.onClick}
        />
      ))}
    </MUITabs>
  )
}
