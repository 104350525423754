import { APIRequestParams } from 'api/common/utils'
import { ControllerDTO } from 'interfaces/controllers/controller'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { API } from 'utils/api/api'

type Params = APIRequestParams<{
  controllerCode: string
  organizationCode: string
}>

type Response = { controller: ControllerDTO }

export const fetchController = ({ organizationCode, includeMetadata, controllerCode }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/controllers/${controllerCode}`,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
  )
