import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'
import CheckSharpIcon from '@mui/icons-material/CheckSharp'
import Box from '@mui/material/Box'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseFormStep: {
        fontFamily: typography.fontFamily,
        letterSpacing: '0.01em',
        display: 'inline-flex',
        alignItems: 'center',
        minWidth: 200,
      },
      baseStepIndex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        borderRadius: '50%',
        marginRight: 8,
        fontSize: typography.pxToRem(14),
        fontWeight: 600,
        color: palette.white.main,
        background: palette.darkDusk.main,
      },
      baseStepTitle: {
        flexGrow: 1,
        fontSize: typography.pxToRem(14),
        lineHeight: typography.pxToRem(20),
        fontWeight: 600,
        color: palette.darkDusk.main,
      },
      baseStepIcon: {
        marginLeft: 8,
      },
      // Types
      typeCompleted: {
        '& $baseStepIndex': {
          background: palette.apple.main,
        },

        '& $baseStepTitle': {
          color: palette.apple.main,
        },

        '& $baseStepIcon': {
          color: palette.apple.main,
        },
      },
      typeDisabled: {
        '& $baseStepIndex': {
          background: palette.darkDuskFaded.main,
        },

        '& $baseStepTitle': {
          color: palette.darkDuskFaded.main,
        },
      },
    }),
  { name: 'FormStep' },
)

export interface Props {
  /** Show step in "disabled" state */
  isDisabled?: boolean
  /** Show step in "completed" state */
  isCompleted?: boolean
  /** Custom step className */
  className?: string
  /** Step Number */
  index: string | number
  /** Step Title */
  title: string
  /** When user clicks on step */
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

/**
 * -
 */
export const FormStep: React.FC<Props> = ({
  isDisabled = false,
  isCompleted = false,
  className,
  index,
  title,
  onClick,
}) => {
  const classes = useStyles()

  return (
    <Box
      className={clsx(
        classes.baseFormStep,
        {
          [classes.typeCompleted]: isCompleted,
          [classes.typeDisabled]: isDisabled,
        },
        className,
      )}
      sx={{
        width: '100%',
        marginBottom: 1.75,
        padding: '0 13px 0 18px',
        '&:last-child': {
          marginBottom: 0,
        },
      }}
      onClick={onClick}
    >
      <Box className={classes.baseStepIndex}>{index}</Box>
      <Box className={classes.baseStepTitle}>{title}</Box>

      {isCompleted && <CheckSharpIcon className={classes.baseStepIcon} />}
    </Box>
  )
}
