import { OBLIGATION_ENUM } from '../../../constants'

export const getShouldShowCustomValueInput = ({
  targetEffectValue,
  attributeDataType,
}: {
  targetEffectValue: string
  attributeDataType: string
}) => {
  if (targetEffectValue !== OBLIGATION_ENUM.UPDATE) return false
  if (attributeDataType.toLowerCase() === 'boolean') return false
  return true
}
