import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { WorkflowExecutionStepStatusRenderer } from 'pages/orchestration/rightsQueue/viewV2/components/RightsQueueWorkflowTab/components/WorkflowExecutionStepStatusRenderer'

type Props = { workflowStep: WorkflowExecutionStepDetailsDTO | null }
export const ApiCallStepDetailsHeaderDetails: React.FC<Props> = ({ workflowStep }) => {
  return (
    <WorkflowExecutionStepStatusRenderer
      status={workflowStep?.status!}
      // dueAt={workflowStep?.dueAt} TODO:JA Uncomment when issue causing KD-10288 is resolved
      code="api_call"
    />
  )
}
