import { NotificationStatus } from 'interfaces/notificationsV2'
import { NotificationDTO } from '@ketch-com/figurehead'

export const splitNotificationsIntoReadAndUnread = (notifications: NotificationDTO[]) => {
  const unreadNotifications = (notifications || []).filter(
    notification => notification.status === NotificationStatus.UNREAD,
  )
  const readNotifications = (notifications || []).filter(
    notification => notification.status === NotificationStatus.READ,
  )

  return {
    unreadNotifications,
    readNotifications,
  }
}
