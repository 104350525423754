import React, { useContext, useState } from 'react'
import { ProcessingActivityContext } from '../../context/ProcessingActivityContext'
import Box from '@mui/material/Box'
import { Button, DataGrid, PopUp } from '@ketch-com/deck'
import { ProcessingActivityDataSystemConsentStatusDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks'

import { processingActivityDataSystemColumns } from './consentStatusModalUtils'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'

export const ConsentStatusModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const {
    navigate,
    processingActivity,
    handleConsentStatusUpdate,
    setShowEditApprovedDialog,
    isProcessingActivityApproved,
    isConsentStatusUpdating,
  } = useContext(ProcessingActivityContext)
  const { isPermitted } = useIsPermitted()

  // Filter string
  const [search, setSearch] = useState('')

  // Systems to display
  const systems =
    processingActivity?.dataSystems?.filter(
      system => system?.dataSystem?.name?.toLowerCase().includes(search.toLowerCase()),
    ) || []

  const handleViewSystemClick = (dataSystemId: string) => {
    navigate(RoutesManager.systems.id.root.getURL({ id: dataSystemId }))
  }

  const handleConfirmClick = async (id: string) => {
    if (isProcessingActivityApproved) {
      setShowEditApprovedDialog(true)
    } else {
      await handleConsentStatusUpdate({
        params: {
          activityId: processingActivity.id || '',
          dataSystemId: id,
          formData: {
            consentStatus:
              ProcessingActivityDataSystemConsentStatusDTO.OrchestratedProcessingActivityDataSystemConsentStatus,
          },
        },
      })
    }
  }

  const handleConnectClick = async (dataSystemId: string) => {
    navigate(RoutesManager.systems.id.root.getURL({ id: dataSystemId }))
  }

  const handleUndoClick = async (id: string) => {
    if (isProcessingActivityApproved) {
      setShowEditApprovedDialog(true)
    } else {
      await handleConsentStatusUpdate({
        params: {
          activityId: processingActivity.id || '',
          dataSystemId: id,
          formData: {
            consentStatus:
              ProcessingActivityDataSystemConsentStatusDTO.NeedsConfirmationProcessingActivityDataSystemConsentStatus,
          },
        },
      })
    }
  }

  const columns = processingActivityDataSystemColumns({
    handleConfirmClick,
    handleConnectClick,
    handleUndoClick,
    handleViewSystemClick,
    isConsentStatusUpdating,
    isPermitted: isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_WRITE),
  })

  return (
    <PopUp
      variant="modal"
      title="Consent Status"
      subTitle={`${processingActivity.dataSystems?.length || 'No'} data sources.`}
      popUpWidth="845px"
      onClose={onClose}
      popUpActionChildren={
        <Button color="secondary" size="large" onClick={onClose}>
          Cancel
        </Button>
      }
    >
      {/* Search bar */}

      <SearchTextInput onChange={newValue => setSearch(newValue)} size="small" value={search} placeholder="Search" />

      {/* Table */}

      <Box height="625px" mb={3}>
        <DataGrid
          disableRowHoverStates
          disableRowSelectionOnClick
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: false,
            columns: columns.map(column => column.field),
            expand: true,
          }}
          getRowHeight={() => 'auto'}
          columns={columns}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          getRowId={row => row.id}
          rows={systems}
          hideFooter
          noRowsOverlayProps={{
            subTitle:
              "Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try again.",
            buttonTitle: 'Reset Filters',
          }}
        />
      </Box>
    </PopUp>
  )
}
