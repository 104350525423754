import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { CreateDatabaseDsrConfigurationSqlReq, DatabaseDsrConfigurationSql } from '@ketch-com/windlass/dist/schemas'

type Params = APIRequestParams<{
  data: CreateDatabaseDsrConfigurationSqlReq
}>

export const upsertCustomSql = ({ data }: Params) => {
  if (!data?.id) delete data.id
  return API.post<DatabaseDsrConfigurationSql>(
    formatRequestString({
      entityUrl: `/api/asset-manager/dsr-sql`,
    }),
    data,
  )
}
