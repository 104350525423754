import { SyntheticEvent, useState, FC } from 'react'
import { ViewLayout, Icon, Button } from '@ketch-com/deck'
import { Box } from '@mui/material'

interface Props {}

const TAB_ITEMS = [
  {
    label: 'Section A',
  },
  {
    label: 'Section B',
  },
  {
    label: 'Section C',
  },
]

const RightActionBlock = () => (
  <>
    <Button size="medium" variant="icon" color="tertiary">
      <Icon name="OMore" />
    </Button>
    <Button size="medium" color="primary">
      Primary
    </Button>
  </>
)

const Widget = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      backgroundColor: theme => theme.palette.lightGrey.main,
    }}
  >
    Widget
  </Box>
)

export const ViewLayoutExample: FC<Props> = props => {
  const [value, setValue] = useState(0)
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        padding: '16px 16px 0 16px',
        gap: '24px',
        height: '700px',
        backgroundColor: theme => theme.palette.bone.main,
      }}
    >
      <ViewLayout
        tabsComponentProps={{ value, onChange: handleChange, tabItems: TAB_ITEMS }}
        contentGroupProps={{
          title: 'Element Name or Title',
          subTitle: 'subtitle',
          showBackButton: true,
          imageBlockComponent: <Icon name="ReportsUserActivity" width="48px" height="48px" />,
          actionRightBlockComponent: <RightActionBlock />,
        }}
        widgetContentComponent={<Widget />}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="400px"
          fontSize="16px"
          sx={{ backgroundColor: theme => theme.palette.lightGrey.main }}
        >
          Content
        </Box>
      </ViewLayout>
    </Box>
  )
}
