import { useMemo } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, TableCell } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { useRightsQueueListTableUtils } from '../../../pages/orchestration/rightsQueue/listV2/components/RightsQueueListTable/hooks/useRightsQueueListTableUtils'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { ThirdPartyRisksHighlight } from '../../../pages/insights/dataRisks/components/ThirdPartyRisks/components'

const useDataGridData = () => {
  const rightsQueueListTableUtils = useRightsQueueListTableUtils()
  const { rightsQueueListTableColumnConfig, paginationHelpers, rightInvocations, isLoading } = rightsQueueListTableUtils

  const gridColumns = rightsQueueListTableColumnConfig.map(colItem => ({
    field: colItem.id,
    headerName: colItem.label,
    renderCell: (params: GridRenderCellParams) => (
      <TableCell>{colItem?.cellRenderer?.(params.row.originalRowData)}</TableCell>
    ),
    width: colItem.id === 'subject-type' ? 250 : colItem.minWidth,
    sortable: false,
  }))

  const rows = rightInvocations.map(row => ({
    id: row.id,
    request_status: row.requestStatus,
    'subject-name': row.rightName,
    'request-code': row.rightCode,
    'current-activity': row.requestStatusText,
    progress: row.status,
    workflow: row.workflowExecutionID,
    invoked_at: formatDateTimeFromUnix(row.invokedAt),
    'subject-type': row.rightName,
    originalRowData: row,
  }))

  const rowCountState = rows?.length !== 0 ? paginationHelpers.totalResults : 0
  const rowsPerPage = useMemo(
    () =>
      paginationHelpers.totalResults ? Math.round(paginationHelpers.totalResults / paginationHelpers.totalPages) : 20,
    [paginationHelpers.totalResults, paginationHelpers.totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: paginationHelpers.page,
      pageSize: rowsPerPage,
    }),
    [paginationHelpers.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    paginationHelpers.handleSetTargetPage(paginationModel.page)
  }

  const isPending = paginationHelpers.isPending

  return {
    gridColumns,
    paginationModel,
    onPaginationModelChange,
    rowCountState,
    rows,
    isLoading,
    rowsPerPage,
    isPending,
  }
}

export const DataGridPartOfLayoutExample = () => {
  const { gridColumns, onPaginationModelChange, rowCountState, paginationModel, rows, rowsPerPage, isPending } =
    useDataGridData()

  return (
    <Box display="flex" justifyContent="space-between" alignItems="stretch" height={510} marginTop="spacing(2)">
      <ThirdPartyRisksHighlight />
      <DataGrid
        rowCount={rowCountState}
        loading={isPending}
        rows={rows}
        columns={gridColumns}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={[rowsPerPage]}
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableChildrenSorting
        pagination
      />
    </Box>
  )
}
