import { useEffect } from 'react'
import { setIsShowOnlySelected } from 'store/assetDetailViewSlice'
import { getAssetDetailViewState } from 'store/assetDetailViewSlice/selectors'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export const useResetBulkEditState = () => {
  const { selectedAssets } = useAppSelector(getAssetDetailViewState)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (selectedAssets?.length === 0) {
      dispatch(setIsShowOnlySelected(false))
    }
  }, [selectedAssets, dispatch])
}
