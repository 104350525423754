import { ContextVariableType } from 'pages/settings/contextVariables/constants'
import { StepDetailsTileWithEditModeInitialValues } from './getStepDetailsTileWithEditModeInitialValues'

export const mapStepDetailsTileWithEditModeValuesToPayload = ({
  values,
}: {
  values: StepDetailsTileWithEditModeInitialValues
}) => {
  const { dataSubjectVariables } = values

  return dataSubjectVariables?.reduce((result: { [key: string]: any }, obj) => {
    function parseVariableValue(stringValue: string): any {
      switch (obj?.type) {
        case ContextVariableType.String:
          return stringValue
        case ContextVariableType.Boolean:
          return stringValue === 'true'
        case ContextVariableType.Number:
          return parseFloat(stringValue)
        default:
          return stringValue
      }
    }

    result[obj?.code || ''] = parseVariableValue(obj?.value)
    return result
  }, {})
}
