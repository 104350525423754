import React from 'react'
import clsx from 'clsx'
import { Tabs, Tab } from '@mui/material'

import { makeStyles, createStyles } from '@mui/styles'
import { CustomIconName, Icon } from '@ketch-com/deck'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      root: {
        display: 'inline-flex',
        minHeight: 'auto',
        overflow: 'visible',
        borderRadius: 100,
        backgroundColor: palette.fadedGrey.main,
      },
      indicator: {
        display: 'none',
      },
      tab: {
        position: 'relative',
        top: -1,
        minWidth: 'auto',
        minHeight: 'auto',
        padding: '6px 20px',
        fontSize: typography.pxToRem(14),
        fontWeight: 400,
        lineHeight: 1.43,
        textTransform: 'none',
        borderRadius: 100,
        color: palette.darkDusk.main,

        '&:not($selected):hover': {
          color: palette.royalBlue.main,
        },

        '&:focus': {
          color: palette.lightBlue.main,
        },
      },
      selected: {
        fontWeight: 600,
        backgroundColor: palette.white.main,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
      },
      fixed: {
        overflow: 'visible !important',
      },
      textColorInherit: {
        opacity: 1,
      },
    }),
  { name: 'SegmentTabs' },
)

export type Props<T> = {
  /** Additional className */
  className?: string
  /** Active tab value */
  value: T
  /** Tabs list to render */
  tabs: {
    id: T
    icon: CustomIconName
    hidden?: boolean
  }[]
  /** Method to change active tab */
  onChange: (nextValue: T, event: React.ChangeEvent<{}>) => void
  /** Optional width (px) */
  width?: number
  /** Optional height (px) */
  height?: number
}

export function SegmentTabsWithIcons<T extends string | number = string>({
  className,
  tabs,
  value,
  onChange,
  width,
  height,
}: Props<T>) {
  const classes = useStyles()

  const filteredTabs = tabs.filter(({ hidden }) => !hidden)

  if (filteredTabs.length < 2) {
    return null
  }

  return (
    <Tabs
      className={clsx(classes.root, className)}
      classes={{
        indicator: classes.indicator,
        fixed: classes.fixed,
      }}
      value={value}
      onChange={(event, nextValue: T) => {
        onChange(nextValue, event)
      }}
      style={{
        width,
        height,
      }}
    >
      {filteredTabs.map(tab => (
        <Tab
          className={classes.tab}
          classes={{
            selected: classes.selected,
            textColorInherit: classes.textColorInherit,
          }}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          key={tab.id}
          label={<Icon name={tab.icon} />}
          value={tab.id}
          style={{
            width: width ? width / 2 : '',
            height,
          }}
        />
      ))}
    </Tabs>
  )
}
