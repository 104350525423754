import React from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'

import { showToast } from 'components/modals/AlertComponent'
import { useAuthenticator } from 'api/authenticators/queries/useAuthenticator'
import { useDeleteAuthenticator } from 'api/authenticators/mutations/useDeleteAuthenticator'
import { Button, PopUp, Spinner } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

type Props = {
  authenticatorToDeleteId: string
  onSubmit?: () => void
  onCancel: () => void
}

export const DeleteAuthenticatorModal: React.FC<Props> = ({ onSubmit, onCancel, authenticatorToDeleteId }) => {
  const queryClient = useQueryClient()

  const { data: authenticator, isLoading: isLoadingAuthenticator } = useAuthenticator({
    params: {
      authenticatorId: authenticatorToDeleteId || '',
    },
  })

  const { mutateAsync: handleDeleteAuthenticator, isLoading: isMutating } = useDeleteAuthenticator({
    onSuccess: async () => {
      onSubmit?.()
      showToast({ content: 'Authenticator deleted', type: 'success' })
      await queryClient.refetchQueries([ApiQueryKeys.authenticatorsPaginated])
    },
    onError: () => {
      showToast({ content: 'Failed to delete Authenticator', type: 'error' })
    },
  })

  return (
    <PopUp
      variant="dialog"
      title="Delete Authenticator"
      popUpWidth="550px"
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isMutating} onClick={onCancel}>
            Close
          </Button>
          <Button
            color="primary"
            size="large"
            pending={isMutating}
            onClick={() => {
              handleDeleteAuthenticator({
                params: {
                  authenticatorId: authenticatorToDeleteId!,
                },
              })
            }}
          >
            Delete
          </Button>
        </>
      }
      onClose={onCancel}
    >
      {isLoadingAuthenticator ? (
        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : (
        <>
          <Typography>
            Are you sure you want to delete <Typography variant="label">{authenticator?.name}</Typography>{' '}
            authenticator?
          </Typography>
          <Typography>
            Deleting this Authenticator will immediately remove it from any future DSR workflow executions involving
            this Authenticator.
          </Typography>
        </>
      )}
    </PopUp>
  )
}
