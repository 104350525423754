import { theme } from '@ketch-com/deck'

type IconProps = { x?: number; y?: number }

export const AverageIcon: React.FC<IconProps> = props => {
  const { x, y } = props
  return (
    <svg x={x} y={y} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill={theme.palette.darkDusk.main} fillOpacity="0.25" />
    </svg>
  )
}
