import { Box, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { useContext } from 'react'
import { BuilderContext } from '../../../Builder'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import {
  BuilderImageVariant,
  PreferenceExperienceBuilderSection,
  PreferenceThemeBuilderSection,
} from '../../../utils/enums'
import { ImageContainer } from './ImageContainer'
import { ExitButtonPosition } from '@ketch-sdk/ketch-types'
import { PreferenceExitButton } from './PreferenceExitButton'
import { SelectableSection } from '../../SelectableSection'

const PreferenceHeaderBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  columnGap: '48px',
  minHeight: '96px',
  wordWrap: 'break-word',
  padding: '16px 32px 16px 32px',
}))

export const PreferenceHeader: React.FC = () => {
  const { themeConfig, experienceConfig, isTheme } = useContext(BuilderContext)

  // Theme config
  const backgroundColor = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabHeaderBackgroundColor)
  const titleColor = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabHeaderTitleColor)
  const logoUrl = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabHeaderLogoUrl)
  const exitButtonPosition = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabContainerExitButtonPosition)
  const showExitButton = exitButtonPosition === ExitButtonPosition.topRight

  // Experience config
  const headerTitle = getIn(experienceConfig, experienceUpsertFormKeys.preferenceWelcomeTabHeaderTitle)
  const showImage = isTheme || logoUrl

  return (
    <PreferenceHeaderBox gridTemplateColumns="280px 1fr" sx={{ background: backgroundColor }}>
      {showImage ? (
        <ImageContainer variant={BuilderImageVariant.Header} imageUrl={logoUrl} />
      ) : (
        <Box display="flex" alignItems="center">
          <Typography
            fontSize="32px"
            fontWeight={700}
            color={titleColor}
            maxWidth={showExitButton ? '750px' : '855px'}
            sx={{ wordWrap: 'break-word' }}
          >
            {headerTitle}
          </Typography>
        </Box>
      )}
      <Box display={'flex'} alignItems={'center'} justifyContent={showImage ? 'space-between' : 'flex-end'}>
        {showImage && (
          <Typography
            fontSize="32px"
            fontWeight={700}
            color={titleColor}
            maxWidth={showExitButton ? '445px' : '536px'}
            sx={{ wordWrap: 'break-word' }}
          >
            {headerTitle}
          </Typography>
        )}
        {showExitButton && (
          <Box maxWidth={'275px'} justifySelf={'end'}>
            <SelectableSection
              section={
                isTheme
                  ? PreferenceThemeBuilderSection.WelcomeTabExitButton
                  : PreferenceExperienceBuilderSection.WelcomeTabExitButton
              }
            >
              <PreferenceExitButton variant={ExitButtonPosition.topRight} />
            </SelectableSection>
          </Box>
        )}
      </Box>
    </PreferenceHeaderBox>
  )
}
