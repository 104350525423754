import { Fragment, useContext } from 'react'
import { DataMapContext } from '../../context/DataMapContext'
import { DataMapListTile } from './DataMapListTile'
import {
  ProcessingActivityAssessmentRequirementLabel,
  ProcessingActivityFrequencyLabel,
  ProcessingActivityTypeLabel,
} from '../../constants'
import {
  ProcessingActivityAssessmentRequirementDTO,
  ProcessingActivityProcessingFrequencyDTO,
  ProcessingActivityTypeDTO,
} from '@ketch-com/figurehead'
import Box from '@mui/material/Box'
import { RoutesManager } from '../../../../../utils/routing/routesManager'
import { Waypoint } from 'react-waypoint'
import { Spinner } from '@ketch-com/deck'
import { InfiniteScrollLoadingIndicator } from 'components/InfiniteScrollLoadingIndicator'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

export const ProcessingActivitiesTab: React.FC = () => {
  const {
    navigate,
    processingActivities,
    isProcessingActivitiesLoading,
    hasNextPAPage,
    isFetchingNextPAPage,
    fetchNextPAPage,
  } = useContext(DataMapContext)

  const breadcrumbs = [
    { title: 'Reports', link: RoutesManager.insights.root.getURL() },
    { title: 'ROPA', link: RoutesManager.insights.dataMap.root.getURL() },
    { title: 'Processing Activities' },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box display="flex" flexDirection="column" gap={3}>
        {isProcessingActivitiesLoading ? (
          <Box
            sx={{
              margin: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner size="32px" thickness={2.5} />
          </Box>
        ) : (
          processingActivities &&
          (processingActivities || []).map((activity, index) => (
            <Fragment>
              <DataMapListTile
                title={activity.name || ''}
                subtitle={activity.description || ''}
                details={[
                  {
                    title:
                      ProcessingActivityTypeLabel[
                        activity.type || ProcessingActivityTypeDTO.UnspecifiedProcessingActivityType
                      ],
                    body: 'Type of Processing',
                  },
                  {
                    title: activity.dataSystems?.length.toString() || '0',
                    body: 'Data Systems',
                  },
                  {
                    title: activity.dataCategories?.length.toString() || '0',
                    body: 'Data Categories',
                  },
                  {
                    title:
                      ProcessingActivityFrequencyLabel[
                        activity.processingFrequency ||
                          ProcessingActivityProcessingFrequencyDTO.UnspecifiedProcessingActivityProcessingFrequency
                      ],
                    body: 'Processing Frequency',
                  },
                  {
                    title:
                      ProcessingActivityAssessmentRequirementLabel[
                        activity.assessmentRequirement ||
                          ProcessingActivityAssessmentRequirementDTO.UnspecifiedProcessingActivityAssessmentRequirement
                      ],
                    body: 'Privacy Assessment',
                  },
                ]}
                onClick={() =>
                  navigate(
                    RoutesManager.insights.dataMap.processingActivities.view.overview.root.getURL({ id: activity.id }),
                  )
                }
              />
              {(processingActivities?.length || 0) - 2 === index ? (
                <Waypoint
                  onEnter={() => {
                    if (!isFetchingNextPAPage && hasNextPAPage) fetchNextPAPage()
                  }}
                />
              ) : null}
            </Fragment>
          ))
        )}
        <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPAPage} />
      </Box>
    </>
  )
}
