import { SendbirdGroupChat } from '@sendbird/chat/groupChannel'

/**
 * Reconnects the Sendbird client as a different user.
 *
 * @param sb A Sendbird SDK instance
 * @param userId The ID of the user to reconnect as
 */
export const reconnectAs = async (sb: SendbirdGroupChat, userId: string) => {
  await sb.disconnect()
  await sb.connect(userId)
}
