import React from 'react'
import { useFormikContext, FieldArrayRenderProps } from 'formik'
import { Box } from '@mui/material'
import { showToast } from 'components/modals/AlertComponent'
import { SubscriptionControlFormData, OrchestrationData } from 'pages/policyCenter/subscriptions/interfaces'

import { useSystemConnections } from 'api/apps/queries/useSystemConnections'
import { Chip, Icon } from '@ketch-com/deck'
import { FormDroplistButton } from 'components/form/FormDroplistButton'

type Props = {
  orchestration: OrchestrationData
  fieldArrayHelpers: FieldArrayRenderProps
  fieldArrayIndex: number
}

export const SystemOrchestration: React.FC<Props> = ({ orchestration, fieldArrayHelpers, fieldArrayIndex }) => {
  const { isSubmitting, setFieldValue, values } = useFormikContext<SubscriptionControlFormData>()
  const { remove } = fieldArrayHelpers
  const { data: systemConnections, isLoading: isSystemConnectionsLoading } = useSystemConnections({
    params: { systemCode: orchestration?.appCode || '' },
    onSuccess: () => {},
    onError: () => {
      remove(fieldArrayIndex)
      showToast({ content: 'Failed to fetch system connections', type: 'error' })
    },
    enabled: Boolean(orchestration?.appCode),
  })

  const items = systemConnections?.map(connection => ({
    id: connection.id,
    name: connection.name,
    disabled: values?.orchestrations?.some(orchestration => orchestration.appInstanceId === connection?.id),
  }))

  return (
    <Box display="flex" alignItems="center" gap={7} pb={1.5}>
      <Box display="flex" alignItems="flex-end" height={51}>
        <Chip
          icon={<img src={orchestration?.appLogo?.url} alt={orchestration?.appLogo?.title} width={24} height={24} />}
          disabled={isSubmitting || isSystemConnectionsLoading}
          size="medium"
          label={orchestration.name}
          onDelete={() => remove(fieldArrayIndex)}
          deleteIcon={<Icon name="OCross" />}
        />
      </Box>
      <Box display="flex" alignItems="flex-end">
        <Box>
          <FormDroplistButton
            disableClearable
            required
            disabled={isSubmitting || isSystemConnectionsLoading}
            size="small"
            sx={{ width: 220 }}
            formPropertyName={`orchestrations.${fieldArrayIndex}.appInstanceId`}
            label="Select Connection"
            placeholder="Select Connection"
            items={items || []}
            onItemClick={option => {
              setFieldValue(`orchestrations.${fieldArrayIndex}.appInstanceId`, option.id)
              setFieldValue(`orchestrations.${fieldArrayIndex}.appInstanceName`, option.name)
            }}
            valueKey="id"
          />
        </Box>
      </Box>
    </Box>
  )
}
