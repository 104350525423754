import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { reassignWorkflowStep } from '../mutations/reassignWorkflowStep'
import { useAuth } from 'utils/hooks'

const useCustomMutation = createUseMutation({
  mutationFn: reassignWorkflowStep,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useReassignWorkflowStep = (config?: Config) => {
  const { userData } = useAuth()

  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, { orgCode: userData.organizationCode })
}
