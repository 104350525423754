import { get } from 'lodash'
import { Location } from 'history'
import qs from 'query-string'

import { formModes } from 'utils/constants/formModes'

type Params = {
  itemId: string
  location?: Location
}

export const getFormMode = ({ itemId, location }: Params) => {
  const queryParams = qs.parse(get(location || {}, 'search')!)

  switch (itemId) {
    case 'new':
      return formModes.CREATE_NEW
    default:
      return queryParams.duplicate === 'true' ? formModes.DUPLICATE : formModes.EDIT
  }
}
