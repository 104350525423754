import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { API } from 'utils/api/api'
import { prepareFileUpload } from 'api/files/fetchers/prepareFileUpload'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { AccessControlType } from 'components/ui-kit/form/imageUpload/hooks/index'

type Params = {
  file: File
  version?: string
  folder?: string
  bucket?: string
  acl?: string
  includeInDataSubjectFolder?: boolean
}

type MultipleFilesParams = Omit<Params, 'file'> & {
  files: File[]
}

export const uploadFile = async ({
  file,
  version = '',
  folder = '',
  bucket = '',
  acl = `${AccessControlType.private}`,
  includeInDataSubjectFolder = false,
}: Params) => {
  const formData = new FormData()
  const { data } = await prepareFileUpload()

  formData.append('file', file)
  formData.append('key', file.name)
  formData.append('bucket', bucket)
  formData.append('version', version)
  formData.append('folder', folder)
  formData.append('acl', acl)
  formData.append('csrf_token', data.csrf_token)

  return API.post<FileInfoDTO>(
    formatRequestString({
      entityUrl: `/api/v2/files`,
      params: {
        filters: {
          includeInDataSubjectFolder,
        },
      },
    }),
    formData,
    { withCredentials: true },
  )
}

export const uploadFiles = async ({
  files,
  version = '',
  folder = '',
  bucket = '',
  acl = `${AccessControlType.private}`,
  includeInDataSubjectFolder = false,
}: MultipleFilesParams) => {
  try {
    const { data } = await prepareFileUpload()
    const result = await Promise.all(
      files.map(async file => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('key', file.name)
        formData.append('bucket', bucket)
        formData.append('version', version)
        formData.append('folder', folder)
        formData.append('acl', acl)
        formData.append('csrf_token', data.csrf_token)
        try {
          return await API.post<FileInfoDTO>(
            formatRequestString({
              entityUrl: `/api/v2/files`,
              params: {
                filters: {
                  includeInDataSubjectFolder,
                },
              },
            }),
            formData,
            { withCredentials: true },
          )
        } catch (error) {
          return { error: error as string, file }
        }
      }),
    )
    return result
  } catch (e) {
    return { error: e as string }
  }
}
