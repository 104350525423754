import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchBusinessFunctions } from '../fetchers/fetchBusinessFunctions'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.businessFunctions,
  queryFn: fetchBusinessFunctions,
  select: res => res?.data?.businessFunctions || [],
})

type Config = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useBusinessFunctions = (config: Config) =>
  useCustomPaginatedQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
