import { APIRequestParams } from '../../common/utils'
import { API } from '../../../utils/api/api'
import { GetProcessingActivityVersionResponseBodyDTO } from '@ketch-com/figurehead'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

type Params = APIRequestParams<{
  activityId?: string
  versionId?: string
}>

export const fetchProcessingActivityVersion = ({ activityId, versionId, includeMetadata }: Params) => {
  return API.get<GetProcessingActivityVersionResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/processing-activities/${activityId}/versions/${versionId}`,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
  )
}
