export const onboarding = {
  signUp: {
    root: {
      pattern: '/sign-up/*',
      getURL: () => '/sign-up',
    },
    complete: {
      pattern: '/2',
      getURL: () => '/sign-up/2',
    },
    completed: {
      pattern: '/completed',
      getURL: () => '/sign-up/completed',
    },
  },
}
