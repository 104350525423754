export const mapFeatures = [
  {
    type: 'Feature',
    properties: {
      name: 'Afghanistan',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [61.210817, 35.650072],
          [62.230651, 35.270664],
          [62.984662, 35.404041],
          [63.193538, 35.857166],
          [63.982896, 36.007957],
          [64.546479, 36.312073],
          [64.746105, 37.111818],
          [65.588948, 37.305217],
          [65.745631, 37.661164],
          [66.217385, 37.39379],
          [66.518607, 37.362784],
          [67.075782, 37.356144],
          [67.83, 37.144994],
          [68.135562, 37.023115],
          [68.859446, 37.344336],
          [69.196273, 37.151144],
          [69.518785, 37.608997],
          [70.116578, 37.588223],
          [70.270574, 37.735165],
          [70.376304, 38.138396],
          [70.806821, 38.486282],
          [71.348131, 38.258905],
          [71.239404, 37.953265],
          [71.541918, 37.905774],
          [71.448693, 37.065645],
          [71.844638, 36.738171],
          [72.193041, 36.948288],
          [72.63689, 37.047558],
          [73.260056, 37.495257],
          [73.948696, 37.421566],
          [74.980002, 37.41999],
          [75.158028, 37.133031],
          [74.575893, 37.020841],
          [74.067552, 36.836176],
          [72.920025, 36.720007],
          [71.846292, 36.509942],
          [71.262348, 36.074388],
          [71.498768, 35.650563],
          [71.613076, 35.153203],
          [71.115019, 34.733126],
          [71.156773, 34.348911],
          [70.881803, 33.988856],
          [69.930543, 34.02012],
          [70.323594, 33.358533],
          [69.687147, 33.105499],
          [69.262522, 32.501944],
          [69.317764, 31.901412],
          [68.926677, 31.620189],
          [68.556932, 31.71331],
          [67.792689, 31.58293],
          [67.683394, 31.303154],
          [66.938891, 31.304911],
          [66.381458, 30.738899],
          [66.346473, 29.887943],
          [65.046862, 29.472181],
          [64.350419, 29.560031],
          [64.148002, 29.340819],
          [63.550261, 29.468331],
          [62.549857, 29.318572],
          [60.874248, 29.829239],
          [61.781222, 30.73585],
          [61.699314, 31.379506],
          [60.941945, 31.548075],
          [60.863655, 32.18292],
          [60.536078, 32.981269],
          [60.9637, 33.528832],
          [60.52843, 33.676446],
          [60.803193, 34.404102],
          [61.210817, 35.650072],
        ],
      ],
    },
    id: 'AF',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Angola',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [16.326528, -5.87747],
            [16.57318, -6.622645],
            [16.860191, -7.222298],
            [17.089996, -7.545689],
            [17.47297, -8.068551],
            [18.134222, -7.987678],
            [18.464176, -7.847014],
            [19.016752, -7.988246],
            [19.166613, -7.738184],
            [19.417502, -7.155429],
            [20.037723, -7.116361],
            [20.091622, -6.94309],
            [20.601823, -6.939318],
            [20.514748, -7.299606],
            [21.728111, -7.290872],
            [21.746456, -7.920085],
            [21.949131, -8.305901],
            [21.801801, -8.908707],
            [21.875182, -9.523708],
            [22.208753, -9.894796],
            [22.155268, -11.084801],
            [22.402798, -10.993075],
            [22.837345, -11.017622],
            [23.456791, -10.867863],
            [23.912215, -10.926826],
            [24.017894, -11.237298],
            [23.904154, -11.722282],
            [24.079905, -12.191297],
            [23.930922, -12.565848],
            [24.016137, -12.911046],
            [21.933886, -12.898437],
            [21.887843, -16.08031],
            [22.562478, -16.898451],
            [23.215048, -17.523116],
            [21.377176, -17.930636],
            [18.956187, -17.789095],
            [18.263309, -17.309951],
            [14.209707, -17.353101],
            [14.058501, -17.423381],
            [13.462362, -16.971212],
            [12.814081, -16.941343],
            [12.215461, -17.111668],
            [11.734199, -17.301889],
            [11.640096, -16.673142],
            [11.778537, -15.793816],
            [12.123581, -14.878316],
            [12.175619, -14.449144],
            [12.500095, -13.5477],
            [12.738479, -13.137906],
            [13.312914, -12.48363],
            [13.633721, -12.038645],
            [13.738728, -11.297863],
            [13.686379, -10.731076],
            [13.387328, -10.373578],
            [13.120988, -9.766897],
            [12.87537, -9.166934],
            [12.929061, -8.959091],
            [13.236433, -8.562629],
            [12.93304, -7.596539],
            [12.728298, -6.927122],
            [12.227347, -6.294448],
            [12.322432, -6.100092],
            [12.735171, -5.965682],
            [13.024869, -5.984389],
            [13.375597, -5.864241],
            [16.326528, -5.87747],
          ],
        ],
        [
          [
            [12.436688, -5.684304],
            [12.182337, -5.789931],
            [11.914963, -5.037987],
            [12.318608, -4.60623],
            [12.62076, -4.438023],
            [12.995517, -4.781103],
            [12.631612, -4.991271],
            [12.468004, -5.248362],
            [12.436688, -5.684304],
          ],
        ],
      ],
    },
    id: 'AO',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Albania',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [20.590247, 41.855404],
          [20.463175, 41.515089],
          [20.605182, 41.086226],
          [21.02004, 40.842727],
          [20.99999, 40.580004],
          [20.674997, 40.435],
          [20.615, 40.110007],
          [20.150016, 39.624998],
          [19.98, 39.694993],
          [19.960002, 39.915006],
          [19.406082, 40.250773],
          [19.319059, 40.72723],
          [19.40355, 41.409566],
          [19.540027, 41.719986],
          [19.371769, 41.877548],
          [19.304486, 42.195745],
          [19.738051, 42.688247],
          [19.801613, 42.500093],
          [20.0707, 42.58863],
          [20.283755, 42.32026],
          [20.52295, 42.21787],
          [20.590247, 41.855404],
        ],
      ],
    },
    id: 'AL',
  },
  {
    type: 'Feature',
    properties: {
      name: 'United Arab Emirates',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [51.579519, 24.245497],
          [51.757441, 24.294073],
          [51.794389, 24.019826],
          [52.577081, 24.177439],
          [53.404007, 24.151317],
          [54.008001, 24.121758],
          [54.693024, 24.797892],
          [55.439025, 25.439145],
          [56.070821, 26.055464],
          [56.261042, 25.714606],
          [56.396847, 24.924732],
          [55.886233, 24.920831],
          [55.804119, 24.269604],
          [55.981214, 24.130543],
          [55.528632, 23.933604],
          [55.525841, 23.524869],
          [55.234489, 23.110993],
          [55.208341, 22.70833],
          [55.006803, 22.496948],
          [52.000733, 23.001154],
          [51.617708, 24.014219],
          [51.579519, 24.245497],
        ],
      ],
    },
    id: 'AE',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Argentina',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-65.5, -55.2],
            [-66.45, -55.25],
            [-66.95992, -54.89681],
            [-67.56244, -54.87001],
            [-68.63335, -54.8695],
            [-68.63401, -52.63637],
            [-68.25, -53.1],
            [-67.75, -53.85],
            [-66.45, -54.45],
            [-65.05, -54.7],
            [-65.5, -55.2],
          ],
        ],
        [
          [
            [-64.964892, -22.075862],
            [-64.377021, -22.798091],
            [-63.986838, -21.993644],
            [-62.846468, -22.034985],
            [-62.685057, -22.249029],
            [-60.846565, -23.880713],
            [-60.028966, -24.032796],
            [-58.807128, -24.771459],
            [-57.777217, -25.16234],
            [-57.63366, -25.603657],
            [-58.618174, -27.123719],
            [-57.60976, -27.395899],
            [-56.486702, -27.548499],
            [-55.695846, -27.387837],
            [-54.788795, -26.621786],
            [-54.625291, -25.739255],
            [-54.13005, -25.547639],
            [-53.628349, -26.124865],
            [-53.648735, -26.923473],
            [-54.490725, -27.474757],
            [-55.162286, -27.881915],
            [-56.2909, -28.852761],
            [-57.625133, -30.216295],
            [-57.874937, -31.016556],
            [-58.14244, -32.044504],
            [-58.132648, -33.040567],
            [-58.349611, -33.263189],
            [-58.427074, -33.909454],
            [-58.495442, -34.43149],
            [-57.22583, -35.288027],
            [-57.362359, -35.97739],
            [-56.737487, -36.413126],
            [-56.788285, -36.901572],
            [-57.749157, -38.183871],
            [-59.231857, -38.72022],
            [-61.237445, -38.928425],
            [-62.335957, -38.827707],
            [-62.125763, -39.424105],
            [-62.330531, -40.172586],
            [-62.145994, -40.676897],
            [-62.745803, -41.028761],
            [-63.770495, -41.166789],
            [-64.73209, -40.802677],
            [-65.118035, -41.064315],
            [-64.978561, -42.058001],
            [-64.303408, -42.359016],
            [-63.755948, -42.043687],
            [-63.458059, -42.563138],
            [-64.378804, -42.873558],
            [-65.181804, -43.495381],
            [-65.328823, -44.501366],
            [-65.565269, -45.036786],
            [-66.509966, -45.039628],
            [-67.293794, -45.551896],
            [-67.580546, -46.301773],
            [-66.597066, -47.033925],
            [-65.641027, -47.236135],
            [-65.985088, -48.133289],
            [-67.166179, -48.697337],
            [-67.816088, -49.869669],
            [-68.728745, -50.264218],
            [-69.138539, -50.73251],
            [-68.815561, -51.771104],
            [-68.149995, -52.349983],
            [-68.571545, -52.299444],
            [-69.498362, -52.142761],
            [-71.914804, -52.009022],
            [-72.329404, -51.425956],
            [-72.309974, -50.67701],
            [-72.975747, -50.74145],
            [-73.328051, -50.378785],
            [-73.415436, -49.318436],
            [-72.648247, -48.878618],
            [-72.331161, -48.244238],
            [-72.447355, -47.738533],
            [-71.917258, -46.884838],
            [-71.552009, -45.560733],
            [-71.659316, -44.973689],
            [-71.222779, -44.784243],
            [-71.329801, -44.407522],
            [-71.793623, -44.207172],
            [-71.464056, -43.787611],
            [-71.915424, -43.408565],
            [-72.148898, -42.254888],
            [-71.746804, -42.051386],
            [-71.915734, -40.832339],
            [-71.680761, -39.808164],
            [-71.413517, -38.916022],
            [-70.814664, -38.552995],
            [-71.118625, -37.576827],
            [-71.121881, -36.658124],
            [-70.364769, -36.005089],
            [-70.388049, -35.169688],
            [-69.817309, -34.193571],
            [-69.814777, -33.273886],
            [-70.074399, -33.09121],
            [-70.535069, -31.36501],
            [-69.919008, -30.336339],
            [-70.01355, -29.367923],
            [-69.65613, -28.459141],
            [-69.001235, -27.521214],
            [-68.295542, -26.89934],
            [-68.5948, -26.506909],
            [-68.386001, -26.185016],
            [-68.417653, -24.518555],
            [-67.328443, -24.025303],
            [-66.985234, -22.986349],
            [-67.106674, -22.735925],
            [-66.273339, -21.83231],
            [-64.964892, -22.075862],
          ],
        ],
      ],
    },
    id: 'AR',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Armenia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [43.582746, 41.092143],
          [44.97248, 41.248129],
          [45.179496, 40.985354],
          [45.560351, 40.81229],
          [45.359175, 40.561504],
          [45.891907, 40.218476],
          [45.610012, 39.899994],
          [46.034534, 39.628021],
          [46.483499, 39.464155],
          [46.50572, 38.770605],
          [46.143623, 38.741201],
          [45.735379, 39.319719],
          [45.739978, 39.473999],
          [45.298145, 39.471751],
          [45.001987, 39.740004],
          [44.79399, 39.713003],
          [44.400009, 40.005],
          [43.656436, 40.253564],
          [43.752658, 40.740201],
          [43.582746, 41.092143],
        ],
      ],
    },
    id: 'AM',
  },
  {
    type: 'Feature',
    properties: {
      name: 'French Southern and Antarctic Lands',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [68.935, -48.625],
          [69.58, -48.94],
          [70.525, -49.065],
          [70.56, -49.255],
          [70.28, -49.71],
          [68.745, -49.775],
          [68.72, -49.2425],
          [68.8675, -48.83],
          [68.935, -48.625],
        ],
      ],
    },
    id: 'TF',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Australia',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [145.397978, -40.792549],
            [146.364121, -41.137695],
            [146.908584, -41.000546],
            [147.689259, -40.808258],
            [148.289068, -40.875438],
            [148.359865, -42.062445],
            [148.017301, -42.407024],
            [147.914052, -43.211522],
            [147.564564, -42.937689],
            [146.870343, -43.634597],
            [146.663327, -43.580854],
            [146.048378, -43.549745],
            [145.43193, -42.693776],
            [145.29509, -42.03361],
            [144.718071, -41.162552],
            [144.743755, -40.703975],
            [145.397978, -40.792549],
          ],
        ],
        [
          [
            [143.561811, -13.763656],
            [143.922099, -14.548311],
            [144.563714, -14.171176],
            [144.894908, -14.594458],
            [145.374724, -14.984976],
            [145.271991, -15.428205],
            [145.48526, -16.285672],
            [145.637033, -16.784918],
            [145.888904, -16.906926],
            [146.160309, -17.761655],
            [146.063674, -18.280073],
            [146.387478, -18.958274],
            [147.471082, -19.480723],
            [148.177602, -19.955939],
            [148.848414, -20.39121],
            [148.717465, -20.633469],
            [149.28942, -21.260511],
            [149.678337, -22.342512],
            [150.077382, -22.122784],
            [150.482939, -22.556142],
            [150.727265, -22.402405],
            [150.899554, -23.462237],
            [151.609175, -24.076256],
            [152.07354, -24.457887],
            [152.855197, -25.267501],
            [153.136162, -26.071173],
            [153.161949, -26.641319],
            [153.092909, -27.2603],
            [153.569469, -28.110067],
            [153.512108, -28.995077],
            [153.339095, -29.458202],
            [153.069241, -30.35024],
            [153.089602, -30.923642],
            [152.891578, -31.640446],
            [152.450002, -32.550003],
            [151.709117, -33.041342],
            [151.343972, -33.816023],
            [151.010555, -34.31036],
            [150.714139, -35.17346],
            [150.32822, -35.671879],
            [150.075212, -36.420206],
            [149.946124, -37.109052],
            [149.997284, -37.425261],
            [149.423882, -37.772681],
            [148.304622, -37.809061],
            [147.381733, -38.219217],
            [146.922123, -38.606532],
            [146.317922, -39.035757],
            [145.489652, -38.593768],
            [144.876976, -38.417448],
            [145.032212, -37.896188],
            [144.485682, -38.085324],
            [143.609974, -38.809465],
            [142.745427, -38.538268],
            [142.17833, -38.380034],
            [141.606582, -38.308514],
            [140.638579, -38.019333],
            [139.992158, -37.402936],
            [139.806588, -36.643603],
            [139.574148, -36.138362],
            [139.082808, -35.732754],
            [138.120748, -35.612296],
            [138.449462, -35.127261],
            [138.207564, -34.384723],
            [137.71917, -35.076825],
            [136.829406, -35.260535],
            [137.352371, -34.707339],
            [137.503886, -34.130268],
            [137.890116, -33.640479],
            [137.810328, -32.900007],
            [136.996837, -33.752771],
            [136.372069, -34.094766],
            [135.989043, -34.890118],
            [135.208213, -34.47867],
            [135.239218, -33.947953],
            [134.613417, -33.222778],
            [134.085904, -32.848072],
            [134.273903, -32.617234],
            [132.990777, -32.011224],
            [132.288081, -31.982647],
            [131.326331, -31.495803],
            [129.535794, -31.590423],
            [128.240938, -31.948489],
            [127.102867, -32.282267],
            [126.148714, -32.215966],
            [125.088623, -32.728751],
            [124.221648, -32.959487],
            [124.028947, -33.483847],
            [123.659667, -33.890179],
            [122.811036, -33.914467],
            [122.183064, -34.003402],
            [121.299191, -33.821036],
            [120.580268, -33.930177],
            [119.893695, -33.976065],
            [119.298899, -34.509366],
            [119.007341, -34.464149],
            [118.505718, -34.746819],
            [118.024972, -35.064733],
            [117.295507, -35.025459],
            [116.625109, -35.025097],
            [115.564347, -34.386428],
            [115.026809, -34.196517],
            [115.048616, -33.623425],
            [115.545123, -33.487258],
            [115.714674, -33.259572],
            [115.679379, -32.900369],
            [115.801645, -32.205062],
            [115.689611, -31.612437],
            [115.160909, -30.601594],
            [114.997043, -30.030725],
            [115.040038, -29.461095],
            [114.641974, -28.810231],
            [114.616498, -28.516399],
            [114.173579, -28.118077],
            [114.048884, -27.334765],
            [113.477498, -26.543134],
            [113.338953, -26.116545],
            [113.778358, -26.549025],
            [113.440962, -25.621278],
            [113.936901, -25.911235],
            [114.232852, -26.298446],
            [114.216161, -25.786281],
            [113.721255, -24.998939],
            [113.625344, -24.683971],
            [113.393523, -24.384764],
            [113.502044, -23.80635],
            [113.706993, -23.560215],
            [113.843418, -23.059987],
            [113.736552, -22.475475],
            [114.149756, -21.755881],
            [114.225307, -22.517488],
            [114.647762, -21.82952],
            [115.460167, -21.495173],
            [115.947373, -21.068688],
            [116.711615, -20.701682],
            [117.166316, -20.623599],
            [117.441545, -20.746899],
            [118.229559, -20.374208],
            [118.836085, -20.263311],
            [118.987807, -20.044203],
            [119.252494, -19.952942],
            [119.805225, -19.976506],
            [120.85622, -19.683708],
            [121.399856, -19.239756],
            [121.655138, -18.705318],
            [122.241665, -18.197649],
            [122.286624, -17.798603],
            [122.312772, -17.254967],
            [123.012574, -16.4052],
            [123.433789, -17.268558],
            [123.859345, -17.069035],
            [123.503242, -16.596506],
            [123.817073, -16.111316],
            [124.258287, -16.327944],
            [124.379726, -15.56706],
            [124.926153, -15.0751],
            [125.167275, -14.680396],
            [125.670087, -14.51007],
            [125.685796, -14.230656],
            [126.125149, -14.347341],
            [126.142823, -14.095987],
            [126.582589, -13.952791],
            [127.065867, -13.817968],
            [127.804633, -14.276906],
            [128.35969, -14.86917],
            [128.985543, -14.875991],
            [129.621473, -14.969784],
            [129.4096, -14.42067],
            [129.888641, -13.618703],
            [130.339466, -13.357376],
            [130.183506, -13.10752],
            [130.617795, -12.536392],
            [131.223495, -12.183649],
            [131.735091, -12.302453],
            [132.575298, -12.114041],
            [132.557212, -11.603012],
            [131.824698, -11.273782],
            [132.357224, -11.128519],
            [133.019561, -11.376411],
            [133.550846, -11.786515],
            [134.393068, -12.042365],
            [134.678632, -11.941183],
            [135.298491, -12.248606],
            [135.882693, -11.962267],
            [136.258381, -12.049342],
            [136.492475, -11.857209],
            [136.95162, -12.351959],
            [136.685125, -12.887223],
            [136.305407, -13.29123],
            [135.961758, -13.324509],
            [136.077617, -13.724278],
            [135.783836, -14.223989],
            [135.428664, -14.715432],
            [135.500184, -14.997741],
            [136.295175, -15.550265],
            [137.06536, -15.870762],
            [137.580471, -16.215082],
            [138.303217, -16.807604],
            [138.585164, -16.806622],
            [139.108543, -17.062679],
            [139.260575, -17.371601],
            [140.215245, -17.710805],
            [140.875463, -17.369069],
            [141.07111, -16.832047],
            [141.274095, -16.38887],
            [141.398222, -15.840532],
            [141.702183, -15.044921],
            [141.56338, -14.561333],
            [141.63552, -14.270395],
            [141.519869, -13.698078],
            [141.65092, -12.944688],
            [141.842691, -12.741548],
            [141.68699, -12.407614],
            [141.928629, -11.877466],
            [142.118488, -11.328042],
            [142.143706, -11.042737],
            [142.51526, -10.668186],
            [142.79731, -11.157355],
            [142.866763, -11.784707],
            [143.115947, -11.90563],
            [143.158632, -12.325656],
            [143.522124, -12.834358],
            [143.597158, -13.400422],
            [143.561811, -13.763656],
          ],
        ],
      ],
    },
    id: 'AU',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Austria',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [16.979667, 48.123497],
          [16.903754, 47.714866],
          [16.340584, 47.712902],
          [16.534268, 47.496171],
          [16.202298, 46.852386],
          [16.011664, 46.683611],
          [15.137092, 46.658703],
          [14.632472, 46.431817],
          [13.806475, 46.509306],
          [12.376485, 46.767559],
          [12.153088, 47.115393],
          [11.164828, 46.941579],
          [11.048556, 46.751359],
          [10.442701, 46.893546],
          [9.932448, 46.920728],
          [9.47997, 47.10281],
          [9.632932, 47.347601],
          [9.594226, 47.525058],
          [9.896068, 47.580197],
          [10.402084, 47.302488],
          [10.544504, 47.566399],
          [11.426414, 47.523766],
          [12.141357, 47.703083],
          [12.62076, 47.672388],
          [12.932627, 47.467646],
          [13.025851, 47.637584],
          [12.884103, 48.289146],
          [13.243357, 48.416115],
          [13.595946, 48.877172],
          [14.338898, 48.555305],
          [14.901447, 48.964402],
          [15.253416, 49.039074],
          [16.029647, 48.733899],
          [16.499283, 48.785808],
          [16.960288, 48.596982],
          [16.879983, 48.470013],
          [16.979667, 48.123497],
        ],
      ],
    },
    id: 'AT',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Azerbaijan',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [45.001987, 39.740004],
            [45.298145, 39.471751],
            [45.739978, 39.473999],
            [45.735379, 39.319719],
            [46.143623, 38.741201],
            [45.457722, 38.874139],
            [44.952688, 39.335765],
            [44.79399, 39.713003],
            [45.001987, 39.740004],
          ],
        ],
        [
          [
            [47.373315, 41.219732],
            [47.815666, 41.151416],
            [47.987283, 41.405819],
            [48.584353, 41.80887],
            [49.110264, 41.282287],
            [49.618915, 40.572924],
            [50.08483, 40.526157],
            [50.392821, 40.256561],
            [49.569202, 40.176101],
            [49.395259, 39.399482],
            [49.223228, 39.049219],
            [48.856532, 38.815486],
            [48.883249, 38.320245],
            [48.634375, 38.270378],
            [48.010744, 38.794015],
            [48.355529, 39.288765],
            [48.060095, 39.582235],
            [47.685079, 39.508364],
            [46.50572, 38.770605],
            [46.483499, 39.464155],
            [46.034534, 39.628021],
            [45.610012, 39.899994],
            [45.891907, 40.218476],
            [45.359175, 40.561504],
            [45.560351, 40.81229],
            [45.179496, 40.985354],
            [44.97248, 41.248129],
            [45.217426, 41.411452],
            [45.962601, 41.123873],
            [46.501637, 41.064445],
            [46.637908, 41.181673],
            [46.145432, 41.722802],
            [46.404951, 41.860675],
            [46.686071, 41.827137],
            [47.373315, 41.219732],
          ],
        ],
      ],
    },
    id: 'AZ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Burundi',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [29.339998, -4.499983],
          [29.276384, -3.293907],
          [29.024926, -2.839258],
          [29.632176, -2.917858],
          [29.938359, -2.348487],
          [30.469696, -2.413858],
          [30.527677, -2.807632],
          [30.743013, -3.034285],
          [30.752263, -3.35933],
          [30.50556, -3.568567],
          [30.116333, -4.090138],
          [29.753512, -4.452389],
          [29.339998, -4.499983],
        ],
      ],
    },
    id: 'BI',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Belgium',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [3.314971, 51.345781],
          [4.047071, 51.267259],
          [4.973991, 51.475024],
          [5.606976, 51.037298],
          [6.156658, 50.803721],
          [6.043073, 50.128052],
          [5.782417, 50.090328],
          [5.674052, 49.529484],
          [4.799222, 49.985373],
          [4.286023, 49.907497],
          [3.588184, 50.378992],
          [3.123252, 50.780363],
          [2.658422, 50.796848],
          [2.513573, 51.148506],
          [3.314971, 51.345781],
        ],
      ],
    },
    id: 'BE',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Benin',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [2.691702, 6.258817],
          [1.865241, 6.142158],
          [1.618951, 6.832038],
          [1.664478, 9.12859],
          [1.463043, 9.334624],
          [1.425061, 9.825395],
          [1.077795, 10.175607],
          [0.772336, 10.470808],
          [0.899563, 10.997339],
          [1.24347, 11.110511],
          [1.447178, 11.547719],
          [1.935986, 11.64115],
          [2.154474, 11.94015],
          [2.490164, 12.233052],
          [2.848643, 12.235636],
          [3.61118, 11.660167],
          [3.572216, 11.327939],
          [3.797112, 10.734746],
          [3.60007, 10.332186],
          [3.705438, 10.06321],
          [3.220352, 9.444153],
          [2.912308, 9.137608],
          [2.723793, 8.506845],
          [2.749063, 7.870734],
          [2.691702, 6.258817],
        ],
      ],
    },
    id: 'BJ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Burkina Faso',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-2.827496, 9.642461],
          [-3.511899, 9.900326],
          [-3.980449, 9.862344],
          [-4.330247, 9.610835],
          [-4.779884, 9.821985],
          [-4.954653, 10.152714],
          [-5.404342, 10.370737],
          [-5.470565, 10.95127],
          [-5.197843, 11.375146],
          [-5.220942, 11.713859],
          [-4.427166, 12.542646],
          [-4.280405, 13.228444],
          [-4.006391, 13.472485],
          [-3.522803, 13.337662],
          [-3.103707, 13.541267],
          [-2.967694, 13.79815],
          [-2.191825, 14.246418],
          [-2.001035, 14.559008],
          [-1.066363, 14.973815],
          [-0.515854, 15.116158],
          [-0.266257, 14.924309],
          [0.374892, 14.928908],
          [0.295646, 14.444235],
          [0.429928, 13.988733],
          [0.993046, 13.33575],
          [1.024103, 12.851826],
          [2.177108, 12.625018],
          [2.154474, 11.94015],
          [1.935986, 11.64115],
          [1.447178, 11.547719],
          [1.24347, 11.110511],
          [0.899563, 10.997339],
          [0.023803, 11.018682],
          [-0.438702, 11.098341],
          [-0.761576, 10.93693],
          [-1.203358, 11.009819],
          [-2.940409, 10.96269],
          [-2.963896, 10.395335],
          [-2.827496, 9.642461],
        ],
      ],
    },
    id: 'BF',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Bangladesh',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [92.672721, 22.041239],
          [92.652257, 21.324048],
          [92.303234, 21.475485],
          [92.368554, 20.670883],
          [92.082886, 21.192195],
          [92.025215, 21.70157],
          [91.834891, 22.182936],
          [91.417087, 22.765019],
          [90.496006, 22.805017],
          [90.586957, 22.392794],
          [90.272971, 21.836368],
          [89.847467, 22.039146],
          [89.70205, 21.857116],
          [89.418863, 21.966179],
          [89.031961, 22.055708],
          [88.876312, 22.879146],
          [88.52977, 23.631142],
          [88.69994, 24.233715],
          [88.084422, 24.501657],
          [88.306373, 24.866079],
          [88.931554, 25.238692],
          [88.209789, 25.768066],
          [88.563049, 26.446526],
          [89.355094, 26.014407],
          [89.832481, 25.965082],
          [89.920693, 25.26975],
          [90.872211, 25.132601],
          [91.799596, 25.147432],
          [92.376202, 24.976693],
          [91.915093, 24.130414],
          [91.46773, 24.072639],
          [91.158963, 23.503527],
          [91.706475, 22.985264],
          [91.869928, 23.624346],
          [92.146035, 23.627499],
          [92.672721, 22.041239],
        ],
      ],
    },
    id: 'BD',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Bulgaria',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [22.65715, 44.234923],
          [22.944832, 43.823785],
          [23.332302, 43.897011],
          [24.100679, 43.741051],
          [25.569272, 43.688445],
          [26.065159, 43.943494],
          [27.2424, 44.175986],
          [27.970107, 43.812468],
          [28.558081, 43.707462],
          [28.039095, 43.293172],
          [27.673898, 42.577892],
          [27.99672, 42.007359],
          [27.135739, 42.141485],
          [26.117042, 41.826905],
          [26.106138, 41.328899],
          [25.197201, 41.234486],
          [24.492645, 41.583896],
          [23.692074, 41.309081],
          [22.952377, 41.337994],
          [22.881374, 41.999297],
          [22.380526, 42.32026],
          [22.545012, 42.461362],
          [22.436595, 42.580321],
          [22.604801, 42.898519],
          [22.986019, 43.211161],
          [22.500157, 43.642814],
          [22.410446, 44.008063],
          [22.65715, 44.234923],
        ],
      ],
    },
    id: 'BG',
  },
  {
    type: 'Feature',
    properties: {
      name: 'The Bahamas',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-77.53466, 23.75975],
            [-77.78, 23.71],
            [-78.03405, 24.28615],
            [-78.40848, 24.57564],
            [-78.19087, 25.2103],
            [-77.89, 25.17],
            [-77.54, 24.34],
            [-77.53466, 23.75975],
          ],
        ],
        [
          [
            [-77.82, 26.58],
            [-78.91, 26.42],
            [-78.98, 26.79],
            [-78.51, 26.87],
            [-77.85, 26.84],
            [-77.82, 26.58],
          ],
        ],
        [
          [
            [-77, 26.59],
            [-77.17255, 25.87918],
            [-77.35641, 26.00735],
            [-77.34, 26.53],
            [-77.78802, 26.92516],
            [-77.79, 27.04],
            [-77, 26.59],
          ],
        ],
      ],
    },
    id: 'BS',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Bosnia and Herzegovina',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [19.005486, 44.860234],
          [19.36803, 44.863],
          [19.11761, 44.42307],
          [19.59976, 44.03847],
          [19.454, 43.5681],
          [19.21852, 43.52384],
          [19.03165, 43.43253],
          [18.70648, 43.20011],
          [18.56, 42.65],
          [17.674922, 43.028563],
          [17.297373, 43.446341],
          [16.916156, 43.667722],
          [16.456443, 44.04124],
          [16.23966, 44.351143],
          [15.750026, 44.818712],
          [15.959367, 45.233777],
          [16.318157, 45.004127],
          [16.534939, 45.211608],
          [17.002146, 45.233777],
          [17.861783, 45.06774],
          [18.553214, 45.08159],
          [19.005486, 44.860234],
        ],
      ],
    },
    id: 'BA',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Belarus',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [23.484128, 53.912498],
          [24.450684, 53.905702],
          [25.536354, 54.282423],
          [25.768433, 54.846963],
          [26.588279, 55.167176],
          [26.494331, 55.615107],
          [27.10246, 55.783314],
          [28.176709, 56.16913],
          [29.229513, 55.918344],
          [29.371572, 55.670091],
          [29.896294, 55.789463],
          [30.873909, 55.550976],
          [30.971836, 55.081548],
          [30.757534, 54.811771],
          [31.384472, 54.157056],
          [31.791424, 53.974639],
          [31.731273, 53.794029],
          [32.405599, 53.618045],
          [32.693643, 53.351421],
          [32.304519, 53.132726],
          [31.497644, 53.167427],
          [31.305201, 53.073996],
          [31.540018, 52.742052],
          [31.785998, 52.101678],
          [30.927549, 52.042353],
          [30.619454, 51.822806],
          [30.555117, 51.319503],
          [30.157364, 51.416138],
          [29.254938, 51.368234],
          [28.992835, 51.602044],
          [28.617613, 51.427714],
          [28.241615, 51.572227],
          [27.454066, 51.592303],
          [26.337959, 51.832289],
          [25.327788, 51.910656],
          [24.553106, 51.888461],
          [24.005078, 51.617444],
          [23.527071, 51.578454],
          [23.508002, 52.023647],
          [23.199494, 52.486977],
          [23.799199, 52.691099],
          [23.804935, 53.089731],
          [23.527536, 53.470122],
          [23.484128, 53.912498],
        ],
      ],
    },
    id: 'BY',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Belize',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-89.14308, 17.808319],
          [-89.150909, 17.955468],
          [-89.029857, 18.001511],
          [-88.848344, 17.883198],
          [-88.490123, 18.486831],
          [-88.300031, 18.499982],
          [-88.296336, 18.353273],
          [-88.106813, 18.348674],
          [-88.123479, 18.076675],
          [-88.285355, 17.644143],
          [-88.197867, 17.489475],
          [-88.302641, 17.131694],
          [-88.239518, 17.036066],
          [-88.355428, 16.530774],
          [-88.551825, 16.265467],
          [-88.732434, 16.233635],
          [-88.930613, 15.887273],
          [-89.229122, 15.886938],
          [-89.150806, 17.015577],
          [-89.14308, 17.808319],
        ],
      ],
    },
    id: 'BZ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Bolivia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-62.846468, -22.034985],
          [-63.986838, -21.993644],
          [-64.377021, -22.798091],
          [-64.964892, -22.075862],
          [-66.273339, -21.83231],
          [-67.106674, -22.735925],
          [-67.82818, -22.872919],
          [-68.219913, -21.494347],
          [-68.757167, -20.372658],
          [-68.442225, -19.405068],
          [-68.966818, -18.981683],
          [-69.100247, -18.260125],
          [-69.590424, -17.580012],
          [-68.959635, -16.500698],
          [-69.389764, -15.660129],
          [-69.160347, -15.323974],
          [-69.339535, -14.953195],
          [-68.948887, -14.453639],
          [-68.929224, -13.602684],
          [-68.88008, -12.899729],
          [-68.66508, -12.5613],
          [-69.529678, -10.951734],
          [-68.786158, -11.03638],
          [-68.271254, -11.014521],
          [-68.048192, -10.712059],
          [-67.173801, -10.306812],
          [-66.646908, -9.931331],
          [-65.338435, -9.761988],
          [-65.444837, -10.511451],
          [-65.321899, -10.895872],
          [-65.402281, -11.56627],
          [-64.316353, -12.461978],
          [-63.196499, -12.627033],
          [-62.80306, -13.000653],
          [-62.127081, -13.198781],
          [-61.713204, -13.489202],
          [-61.084121, -13.479384],
          [-60.503304, -13.775955],
          [-60.459198, -14.354007],
          [-60.264326, -14.645979],
          [-60.251149, -15.077219],
          [-60.542966, -15.09391],
          [-60.15839, -16.258284],
          [-58.24122, -16.299573],
          [-58.388058, -16.877109],
          [-58.280804, -17.27171],
          [-57.734558, -17.552468],
          [-57.498371, -18.174188],
          [-57.676009, -18.96184],
          [-57.949997, -19.400004],
          [-57.853802, -19.969995],
          [-58.166392, -20.176701],
          [-58.183471, -19.868399],
          [-59.115042, -19.356906],
          [-60.043565, -19.342747],
          [-61.786326, -19.633737],
          [-62.265961, -20.513735],
          [-62.291179, -21.051635],
          [-62.685057, -22.249029],
          [-62.846468, -22.034985],
        ],
      ],
    },
    id: 'BO',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Brazil',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-57.625133, -30.216295],
          [-56.2909, -28.852761],
          [-55.162286, -27.881915],
          [-54.490725, -27.474757],
          [-53.648735, -26.923473],
          [-53.628349, -26.124865],
          [-54.13005, -25.547639],
          [-54.625291, -25.739255],
          [-54.428946, -25.162185],
          [-54.293476, -24.5708],
          [-54.29296, -24.021014],
          [-54.652834, -23.839578],
          [-55.027902, -24.001274],
          [-55.400747, -23.956935],
          [-55.517639, -23.571998],
          [-55.610683, -22.655619],
          [-55.797958, -22.35693],
          [-56.473317, -22.0863],
          [-56.88151, -22.282154],
          [-57.937156, -22.090176],
          [-57.870674, -20.732688],
          [-58.166392, -20.176701],
          [-57.853802, -19.969995],
          [-57.949997, -19.400004],
          [-57.676009, -18.96184],
          [-57.498371, -18.174188],
          [-57.734558, -17.552468],
          [-58.280804, -17.27171],
          [-58.388058, -16.877109],
          [-58.24122, -16.299573],
          [-60.15839, -16.258284],
          [-60.542966, -15.09391],
          [-60.251149, -15.077219],
          [-60.264326, -14.645979],
          [-60.459198, -14.354007],
          [-60.503304, -13.775955],
          [-61.084121, -13.479384],
          [-61.713204, -13.489202],
          [-62.127081, -13.198781],
          [-62.80306, -13.000653],
          [-63.196499, -12.627033],
          [-64.316353, -12.461978],
          [-65.402281, -11.56627],
          [-65.321899, -10.895872],
          [-65.444837, -10.511451],
          [-65.338435, -9.761988],
          [-66.646908, -9.931331],
          [-67.173801, -10.306812],
          [-68.048192, -10.712059],
          [-68.271254, -11.014521],
          [-68.786158, -11.03638],
          [-69.529678, -10.951734],
          [-70.093752, -11.123972],
          [-70.548686, -11.009147],
          [-70.481894, -9.490118],
          [-71.302412, -10.079436],
          [-72.184891, -10.053598],
          [-72.563033, -9.520194],
          [-73.226713, -9.462213],
          [-73.015383, -9.032833],
          [-73.571059, -8.424447],
          [-73.987235, -7.52383],
          [-73.723401, -7.340999],
          [-73.724487, -6.918595],
          [-73.120027, -6.629931],
          [-73.219711, -6.089189],
          [-72.964507, -5.741251],
          [-72.891928, -5.274561],
          [-71.748406, -4.593983],
          [-70.928843, -4.401591],
          [-70.794769, -4.251265],
          [-69.893635, -4.298187],
          [-69.444102, -1.556287],
          [-69.420486, -1.122619],
          [-69.577065, -0.549992],
          [-70.020656, -0.185156],
          [-70.015566, 0.541414],
          [-69.452396, 0.706159],
          [-69.252434, 0.602651],
          [-69.218638, 0.985677],
          [-69.804597, 1.089081],
          [-69.816973, 1.714805],
          [-67.868565, 1.692455],
          [-67.53781, 2.037163],
          [-67.259998, 1.719999],
          [-67.065048, 1.130112],
          [-66.876326, 1.253361],
          [-66.325765, 0.724452],
          [-65.548267, 0.789254],
          [-65.354713, 1.095282],
          [-64.611012, 1.328731],
          [-64.199306, 1.492855],
          [-64.083085, 1.916369],
          [-63.368788, 2.2009],
          [-63.422867, 2.411068],
          [-64.269999, 2.497006],
          [-64.408828, 3.126786],
          [-64.368494, 3.79721],
          [-64.816064, 4.056445],
          [-64.628659, 4.148481],
          [-63.888343, 4.02053],
          [-63.093198, 3.770571],
          [-62.804533, 4.006965],
          [-62.08543, 4.162124],
          [-60.966893, 4.536468],
          [-60.601179, 4.918098],
          [-60.733574, 5.200277],
          [-60.213683, 5.244486],
          [-59.980959, 5.014061],
          [-60.111002, 4.574967],
          [-59.767406, 4.423503],
          [-59.53804, 3.958803],
          [-59.815413, 3.606499],
          [-59.974525, 2.755233],
          [-59.718546, 2.24963],
          [-59.646044, 1.786894],
          [-59.030862, 1.317698],
          [-58.540013, 1.268088],
          [-58.429477, 1.463942],
          [-58.11345, 1.507195],
          [-57.660971, 1.682585],
          [-57.335823, 1.948538],
          [-56.782704, 1.863711],
          [-56.539386, 1.899523],
          [-55.995698, 1.817667],
          [-55.9056, 2.021996],
          [-56.073342, 2.220795],
          [-55.973322, 2.510364],
          [-55.569755, 2.421506],
          [-55.097587, 2.523748],
          [-54.524754, 2.311849],
          [-54.088063, 2.105557],
          [-53.778521, 2.376703],
          [-53.554839, 2.334897],
          [-53.418465, 2.053389],
          [-52.939657, 2.124858],
          [-52.556425, 2.504705],
          [-52.249338, 3.241094],
          [-51.657797, 4.156232],
          [-51.317146, 4.203491],
          [-51.069771, 3.650398],
          [-50.508875, 1.901564],
          [-49.974076, 1.736483],
          [-49.947101, 1.04619],
          [-50.699251, 0.222984],
          [-50.388211, -0.078445],
          [-48.620567, -0.235489],
          [-48.584497, -1.237805],
          [-47.824956, -0.581618],
          [-46.566584, -0.941028],
          [-44.905703, -1.55174],
          [-44.417619, -2.13775],
          [-44.581589, -2.691308],
          [-43.418791, -2.38311],
          [-41.472657, -2.912018],
          [-39.978665, -2.873054],
          [-38.500383, -3.700652],
          [-37.223252, -4.820946],
          [-36.452937, -5.109404],
          [-35.597796, -5.149504],
          [-35.235389, -5.464937],
          [-34.89603, -6.738193],
          [-34.729993, -7.343221],
          [-35.128212, -8.996401],
          [-35.636967, -9.649282],
          [-37.046519, -11.040721],
          [-37.683612, -12.171195],
          [-38.423877, -13.038119],
          [-38.673887, -13.057652],
          [-38.953276, -13.79337],
          [-38.882298, -15.667054],
          [-39.161092, -17.208407],
          [-39.267339, -17.867746],
          [-39.583521, -18.262296],
          [-39.760823, -19.599113],
          [-40.774741, -20.904512],
          [-40.944756, -21.937317],
          [-41.754164, -22.370676],
          [-41.988284, -22.97007],
          [-43.074704, -22.967693],
          [-44.647812, -23.351959],
          [-45.352136, -23.796842],
          [-46.472093, -24.088969],
          [-47.648972, -24.885199],
          [-48.495458, -25.877025],
          [-48.641005, -26.623698],
          [-48.474736, -27.175912],
          [-48.66152, -28.186135],
          [-48.888457, -28.674115],
          [-49.587329, -29.224469],
          [-50.696874, -30.984465],
          [-51.576226, -31.777698],
          [-52.256081, -32.24537],
          [-52.7121, -33.196578],
          [-53.373662, -33.768378],
          [-53.650544, -33.202004],
          [-53.209589, -32.727666],
          [-53.787952, -32.047243],
          [-54.572452, -31.494511],
          [-55.60151, -30.853879],
          [-55.973245, -30.883076],
          [-56.976026, -30.109686],
          [-57.625133, -30.216295],
        ],
      ],
    },
    id: 'BR',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Brunei',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [114.204017, 4.525874],
          [114.599961, 4.900011],
          [115.45071, 5.44773],
          [115.4057, 4.955228],
          [115.347461, 4.316636],
          [114.869557, 4.348314],
          [114.659596, 4.007637],
          [114.204017, 4.525874],
        ],
      ],
    },
    id: 'BN',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Bhutan',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [91.696657, 27.771742],
          [92.103712, 27.452614],
          [92.033484, 26.83831],
          [91.217513, 26.808648],
          [90.373275, 26.875724],
          [89.744528, 26.719403],
          [88.835643, 27.098966],
          [88.814248, 27.299316],
          [89.47581, 28.042759],
          [90.015829, 28.296439],
          [90.730514, 28.064954],
          [91.258854, 28.040614],
          [91.696657, 27.771742],
        ],
      ],
    },
    id: 'BT',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Botswana',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [25.649163, -18.536026],
          [25.850391, -18.714413],
          [26.164791, -19.293086],
          [27.296505, -20.39152],
          [27.724747, -20.499059],
          [27.727228, -20.851802],
          [28.02137, -21.485975],
          [28.794656, -21.639454],
          [29.432188, -22.091313],
          [28.017236, -22.827754],
          [27.11941, -23.574323],
          [26.786407, -24.240691],
          [26.485753, -24.616327],
          [25.941652, -24.696373],
          [25.765849, -25.174845],
          [25.664666, -25.486816],
          [25.025171, -25.71967],
          [24.211267, -25.670216],
          [23.73357, -25.390129],
          [23.312097, -25.26869],
          [22.824271, -25.500459],
          [22.579532, -25.979448],
          [22.105969, -26.280256],
          [21.605896, -26.726534],
          [20.889609, -26.828543],
          [20.66647, -26.477453],
          [20.758609, -25.868136],
          [20.165726, -24.917962],
          [19.895768, -24.76779],
          [19.895458, -21.849157],
          [20.881134, -21.814327],
          [20.910641, -18.252219],
          [21.65504, -18.219146],
          [23.196858, -17.869038],
          [23.579006, -18.281261],
          [24.217365, -17.889347],
          [24.520705, -17.887125],
          [25.084443, -17.661816],
          [25.264226, -17.73654],
          [25.649163, -18.536026],
        ],
      ],
    },
    id: 'BW',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Central African Republic',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [15.27946, 7.421925],
          [16.106232, 7.497088],
          [16.290562, 7.754307],
          [16.456185, 7.734774],
          [16.705988, 7.508328],
          [17.96493, 7.890914],
          [18.389555, 8.281304],
          [18.911022, 8.630895],
          [18.81201, 8.982915],
          [19.094008, 9.074847],
          [20.059685, 9.012706],
          [21.000868, 9.475985],
          [21.723822, 10.567056],
          [22.231129, 10.971889],
          [22.864165, 11.142395],
          [22.977544, 10.714463],
          [23.554304, 10.089255],
          [23.55725, 9.681218],
          [23.394779, 9.265068],
          [23.459013, 8.954286],
          [23.805813, 8.666319],
          [24.567369, 8.229188],
          [25.114932, 7.825104],
          [25.124131, 7.500085],
          [25.796648, 6.979316],
          [26.213418, 6.546603],
          [26.465909, 5.946717],
          [27.213409, 5.550953],
          [27.374226, 5.233944],
          [27.044065, 5.127853],
          [26.402761, 5.150875],
          [25.650455, 5.256088],
          [25.278798, 5.170408],
          [25.128833, 4.927245],
          [24.805029, 4.897247],
          [24.410531, 5.108784],
          [23.297214, 4.609693],
          [22.84148, 4.710126],
          [22.704124, 4.633051],
          [22.405124, 4.02916],
          [21.659123, 4.224342],
          [20.927591, 4.322786],
          [20.290679, 4.691678],
          [19.467784, 5.031528],
          [18.932312, 4.709506],
          [18.542982, 4.201785],
          [18.453065, 3.504386],
          [17.8099, 3.560196],
          [17.133042, 3.728197],
          [16.537058, 3.198255],
          [16.012852, 2.26764],
          [15.907381, 2.557389],
          [15.862732, 3.013537],
          [15.405396, 3.335301],
          [15.03622, 3.851367],
          [14.950953, 4.210389],
          [14.478372, 4.732605],
          [14.558936, 5.030598],
          [14.459407, 5.451761],
          [14.53656, 6.226959],
          [14.776545, 6.408498],
          [15.27946, 7.421925],
        ],
      ],
    },
    id: 'CF',
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-78.019173, 62.59193],
            [-77.867218, 62.589161],
            [-77.837784, 62.556938],
            [-78.103333, 62.559158],
            [-78.107773, 62.582771],
            [-78.019173, 62.59193],
          ],
        ],
        [
          [
            [-69.182503, 59.128601],
            [-69.198608, 59.067211],
            [-69.132767, 59.055271],
            [-69.221123, 58.991661],
            [-69.223053, 58.95332],
            [-69.290283, 58.977211],
            [-69.35556, 58.949711],
            [-69.278343, 59.066669],
            [-69.357224, 59.139721],
            [-69.275284, 59.154991],
            [-69.182503, 59.128601],
          ],
        ],
        [
          [
            [-79.496384, 56.766941],
            [-79.486107, 56.658039],
            [-79.523621, 56.66721],
            [-79.567497, 56.615269],
            [-79.583893, 56.652771],
            [-79.549438, 56.68111],
            [-79.551941, 56.73193],
            [-79.589996, 56.76833],
            [-79.58168, 56.807209],
            [-79.496384, 56.766941],
          ],
        ],
        [
          [
            [-78.900284, 56.319439],
            [-79.061394, 56.124439],
            [-79.210831, 55.856098],
            [-79.188599, 55.942211],
            [-78.986656, 56.26749],
            [-78.983322, 56.389721],
            [-79.055267, 56.34444],
            [-79.100281, 56.221371],
            [-79.04834, 56.222759],
            [-79.061394, 56.18832],
            [-79.283333, 55.864441],
            [-79.133904, 56.126659],
            [-79.150558, 56.233051],
            [-79.243881, 56.1511],
            [-79.360283, 55.969711],
            [-79.493057, 55.858891],
            [-79.636124, 55.898609],
            [-79.785553, 55.788891],
            [-79.474716, 56.098331],
            [-79.513062, 56.134991],
            [-79.597229, 56.091381],
            [-79.877213, 55.83194],
            [-79.906113, 55.824711],
            [-79.947487, 55.889992],
            [-80.024719, 55.901379],
            [-79.74472, 56.15638],
            [-79.529167, 56.275551],
            [-79.475563, 56.38055],
            [-79.456673, 56.553322],
            [-79.419449, 56.4436],
            [-79.480003, 56.346378],
            [-79.491096, 56.264439],
            [-79.609734, 56.13166],
            [-79.427223, 56.203049],
            [-79.301102, 56.447208],
            [-79.269173, 56.624989],
            [-79.252792, 56.550831],
            [-79.133057, 56.54277],
            [-79.075562, 56.444149],
            [-78.928879, 56.425831],
            [-78.908051, 56.343048],
            [-78.951401, 56.316101],
            [-78.900284, 56.319439],
          ],
        ],
        [
          [
            [-79.555557, 56.4986],
            [-79.701111, 56.306381],
            [-79.986107, 56.199711],
            [-80.109734, 56.197769],
            [-80.044449, 56.310822],
            [-79.869164, 56.331669],
            [-79.877213, 56.379162],
            [-79.698883, 56.368881],
            [-79.614441, 56.43166],
            [-79.585281, 56.499161],
            [-79.543327, 56.527771],
            [-79.555557, 56.4986],
          ],
        ],
        [
          [
            [-78.660828, 56.414711],
            [-78.676392, 56.18111],
            [-78.933319, 56.115551],
            [-78.916946, 56.172489],
            [-78.825287, 56.262772],
            [-78.845551, 56.276661],
            [-78.809433, 56.338879],
            [-78.832497, 56.35083],
            [-78.731667, 56.44054],
            [-78.667221, 56.439709],
            [-78.660828, 56.414711],
          ],
        ],
        [
          [
            [-79.009354, 56.06361],
            [-78.957497, 56.083599],
            [-78.948883, 56.03722],
            [-79.002792, 56.012772],
            [-78.934158, 56.020271],
            [-79.002502, 55.979988],
            [-79.054443, 55.865551],
            [-79.135559, 55.789162],
            [-79.030838, 55.968601],
            [-79.009354, 56.06361],
          ],
        ],
        [
          [
            [-63.492229, 49.840832],
            [-62.944439, 49.732208],
            [-62.510281, 49.590832],
            [-62.39389, 49.492222],
            [-62.25111, 49.456661],
            [-62.20583, 49.411381],
            [-61.875561, 49.34499],
            [-61.661671, 49.14444],
            [-61.70055, 49.090271],
            [-61.81361, 49.06694],
            [-62.242771, 49.06221],
            [-62.456951, 49.125549],
            [-63.09, 49.228039],
            [-63.283329, 49.317768],
            [-63.5825, 49.387211],
            [-63.61972, 49.492771],
            [-63.841942, 49.63916],
            [-64.238602, 49.751659],
            [-64.362778, 49.82444],
            [-64.389999, 49.789719],
            [-64.513901, 49.863609],
            [-64.458618, 49.900829],
            [-64.203613, 49.950272],
            [-63.930828, 49.885269],
            [-63.492229, 49.840832],
          ],
        ],
        [
          [
            [-61.40778, 47.641109],
            [-61.38055, 47.62027],
            [-61.493061, 47.55249],
            [-61.54945, 47.553879],
            [-61.470551, 47.61694],
            [-61.54195, 47.61721],
            [-61.690109, 47.534672],
            [-61.711842, 47.518051],
            [-61.85556, 47.41721],
            [-61.777222, 47.43166],
            [-61.674999, 47.535],
            [-61.59444, 47.555271],
            [-61.701389, 47.49194],
            [-61.763889, 47.397491],
            [-61.829731, 47.401661],
            [-61.860001, 47.352489],
            [-61.933331, 47.333328],
            [-61.9575, 47.26194],
            [-61.908329, 47.27277],
            [-61.81945, 47.23333],
            [-61.783058, 47.260551],
            [-61.79945, 47.232769],
            [-61.85931, 47.217739],
            [-62.009998, 47.221371],
            [-61.928051, 47.407211],
            [-61.535839, 47.63166],
            [-61.40778, 47.641109],
          ],
        ],
        [
          [
            [-70.814438, 46.998329],
            [-70.904167, 46.913601],
            [-71.13028, 46.856098],
            [-70.896667, 47.013329],
            [-70.836403, 47.027771],
            [-70.814438, 46.998329],
          ],
        ],
        [
          [
            [-57.10424, 51.41267],
            [-57.208611, 51.415821],
            [-57.247219, 51.504169],
            [-57.60223, 51.428051],
            [-57.581669, 51.465271],
            [-57.67667, 51.429989],
            [-57.684441, 51.47694],
            [-57.748051, 51.47221],
            [-57.76445, 51.401661],
            [-57.800831, 51.433331],
            [-58.021389, 51.30888],
            [-58.227219, 51.271381],
            [-58.26112, 51.288052],
            [-58.249168, 51.332771],
            [-58.283058, 51.335819],
            [-58.29723, 51.2686],
            [-58.430279, 51.309719],
            [-58.580002, 51.24416],
            [-58.628052, 51.275551],
            [-58.61972, 51.231659],
            [-58.667221, 51.25639],
            [-58.68, 51.234161],
            [-58.59222, 51.184719],
            [-58.61861, 51.153049],
            [-58.83778, 51.0825],
            [-58.829731, 51.041111],
            [-58.889999, 50.992222],
            [-58.91333, 51],
            [-58.89806, 51.053879],
            [-59.00333, 51.015549],
            [-58.952499, 50.992489],
            [-58.958611, 50.92194],
            [-58.939442, 50.883331],
            [-58.975269, 50.857769],
            [-58.948051, 50.828331],
            [-59.029171, 50.775551],
            [-59.015282, 50.7486],
            [-59.087219, 50.775269],
            [-59.09333, 50.820831],
            [-59.055271, 50.870831],
            [-59.098881, 50.879429],
            [-59.118889, 50.8036],
            [-59.193611, 50.72971],
            [-59.256111, 50.75972],
            [-59.268608, 50.71249],
            [-59.350281, 50.662769],
            [-59.423889, 50.661381],
            [-59.404999, 50.63472],
            [-59.454441, 50.621929],
            [-59.58527, 50.478039],
            [-59.816109, 50.43388],
            [-59.881672, 50.371658],
            [-59.853889, 50.326099],
            [-59.814999, 50.349159],
            [-59.833889, 50.31916],
            [-59.948051, 50.255001],
            [-60.114449, 50.233051],
            [-60.134171, 50.20388],
            [-60.132771, 50.22971],
            [-60.17223, 50.231659],
            [-60.14999, 50.288601],
            [-60.243328, 50.232208],
            [-60.26722, 50.236111],
            [-60.23695, 50.268051],
            [-60.274719, 50.288891],
            [-60.291672, 50.24527],
            [-60.360001, 50.250832],
            [-60.35611, 50.22332],
            [-60.448051, 50.259441],
            [-60.583611, 50.208328],
            [-60.61972, 50.246941],
            [-60.666111, 50.220268],
            [-60.748341, 50.23444],
            [-60.788609, 50.201099],
            [-60.840549, 50.219151],
            [-60.792782, 50.261669],
            [-60.934441, 50.1936],
            [-61.02639, 50.225819],
            [-61.112782, 50.187771],
            [-61.15583, 50.202221],
            [-61.162498, 50.17749],
            [-61.297501, 50.199162],
            [-61.720829, 50.09193],
            [-61.783058, 50.106941],
            [-61.59306, 50.15527],
            [-61.577221, 50.1861],
            [-61.790279, 50.13361],
            [-61.849998, 50.21249],
            [-62.21056, 50.234161],
            [-62.397499, 50.294441],
            [-62.44611, 50.2575],
            [-62.73695, 50.282211],
            [-62.712502, 50.315269],
            [-62.805271, 50.288601],
            [-62.963058, 50.285549],
            [-62.994999, 50.311939],
            [-63.163891, 50.291382],
            [-63.159161, 50.254711],
            [-63.358608, 50.241661],
            [-63.394718, 50.213329],
            [-63.488609, 50.26194],
            [-63.597778, 50.250832],
            [-63.812771, 50.313049],
            [-64.162216, 50.262211],
            [-64.455002, 50.319439],
            [-64.872223, 50.267208],
            [-64.91362, 50.2925],
            [-65.189987, 50.286659],
            [-65.258057, 50.31889],
            [-65.713898, 50.257221],
            [-65.952499, 50.288891],
            [-66.056381, 50.216099],
            [-66.093338, 50.244438],
            [-66.089722, 50.19138],
            [-66.235817, 50.21888],
            [-66.369164, 50.193321],
            [-66.441673, 50.269161],
            [-66.543327, 50.20805],
            [-66.527222, 50.16972],
            [-66.42778, 50.164711],
            [-66.459442, 50.133049],
            [-66.638062, 50.181381],
            [-66.61972, 50.149158],
            [-66.741096, 50.042221],
            [-66.958618, 49.974709],
            [-66.963333, 49.914711],
            [-67.037514, 49.820831],
            [-67.073898, 49.848049],
            [-67.151398, 49.806938],
            [-67.179718, 49.74527],
            [-67.163063, 49.679722],
            [-67.198883, 49.651661],
            [-67.238892, 49.590549],
            [-67.233887, 49.470268],
            [-67.386124, 49.32222],
            [-67.569733, 49.32999],
            [-67.809723, 49.273609],
            [-67.957779, 49.295269],
            [-68.11972, 49.27166],
            [-68.156387, 49.20166],
            [-68.299438, 49.195541],
            [-68.19249, 49.164711],
            [-68.189713, 49.112209],
            [-68.325562, 49.09972],
            [-68.408051, 49.05555],
            [-68.444443, 49.059719],
            [-68.367767, 49.132771],
            [-68.443604, 49.191662],
            [-68.404167, 49.122761],
            [-68.606949, 49.0425],
            [-68.643341, 48.9561],
            [-68.696381, 48.939991],
            [-68.642227, 48.91666],
            [-68.787781, 48.907768],
            [-68.95639, 48.81583],
            [-68.96666, 48.782768],
            [-69.060822, 48.76749],
            [-69.142502, 48.59499],
            [-69.231377, 48.585548],
            [-69.29277, 48.457771],
            [-69.43277, 48.30777],
            [-69.57695, 48.23999],
            [-69.683884, 48.137772],
            [-69.810272, 48.155819],
            [-69.959442, 48.26944],
            [-70.151108, 48.274441],
            [-70.373322, 48.374161],
            [-70.684433, 48.3811],
            [-70.761398, 48.431938],
            [-70.98056, 48.462212],
            [-71.070572, 48.440109],
            [-70.969727, 48.447208],
            [-70.785553, 48.395828],
            [-70.887222, 48.358601],
            [-70.864166, 48.317211],
            [-70.768066, 48.350552],
            [-70.464172, 48.349159],
            [-70.272781, 48.298328],
            [-70.180557, 48.241379],
            [-69.936943, 48.221931],
            [-69.860001, 48.143879],
            [-69.730293, 48.10944],
            [-69.925827, 47.773048],
            [-70.139183, 47.659161],
            [-70.206123, 47.57666],
            [-70.225563, 47.49638],
            [-70.5, 47.43499],
            [-70.574173, 47.274441],
            [-70.721657, 47.101391],
            [-70.973618, 47.00333],
            [-71.197487, 46.852489],
            [-71.299156, 46.742222],
            [-71.143623, 46.844711],
            [-71.006119, 46.847771],
            [-70.541382, 47.002491],
            [-70.339172, 47.152489],
            [-70.201401, 47.312489],
            [-70.044159, 47.390831],
            [-70.017776, 47.487499],
            [-69.896393, 47.541111],
            [-69.545837, 47.8325],
            [-69.556381, 47.866661],
            [-69.450562, 47.97916],
            [-69.34111, 48.01416],
            [-68.823334, 48.36388],
            [-68.726936, 48.37249],
            [-68.541382, 48.451389],
            [-68.453613, 48.53249],
            [-68.058609, 48.691101],
            [-67.973618, 48.695541],
            [-67.553047, 48.85471],
            [-67.067497, 48.96693],
            [-66.639183, 49.120831],
            [-66.225014, 49.200829],
            [-65.722229, 49.227211],
            [-65.588058, 49.260551],
            [-64.94249, 49.214439],
            [-64.588058, 49.112209],
            [-64.23555, 48.910271],
            [-64.158051, 48.7561],
            [-64.298889, 48.823879],
            [-64.557503, 48.880268],
            [-64.473328, 48.832211],
            [-64.539436, 48.821381],
            [-64.401108, 48.81694],
            [-64.374161, 48.787769],
            [-64.428329, 48.774441],
            [-64.336937, 48.75555],
            [-64.160004, 48.622761],
            [-64.26973, 48.60471],
            [-64.279167, 48.554161],
            [-64.189163, 48.520828],
            [-64.309433, 48.463329],
            [-64.315552, 48.419159],
            [-64.719162, 48.338329],
            [-64.693604, 48.3111],
            [-64.771393, 48.196381],
            [-64.96611, 48.18499],
            [-64.945831, 48.143051],
            [-65.269997, 48.012772],
            [-65.455566, 48.000271],
            [-65.504181, 48.048882],
            [-65.810547, 48.116661],
            [-65.891678, 48.171101],
            [-65.893341, 48.21944],
            [-66.080002, 48.096378],
            [-66.293327, 48.113049],
            [-66.268066, 48.095829],
            [-66.29361, 48.063881],
            [-66.47084, 48.119438],
            [-66.538063, 48.05582],
            [-66.843697, 47.996651],
            [-66.943604, 47.963051],
            [-66.946953, 47.899158],
            [-67.047234, 47.93082],
            [-67.188599, 47.883881],
            [-67.322777, 47.896389],
            [-67.364166, 47.841381],
            [-67.604172, 47.9361],
            [-67.612213, 47.99749],
            [-68.116096, 48.001942],
            [-68.12027, 47.926659],
            [-68.372772, 47.924709],
            [-68.378052, 47.65416],
            [-68.38028, 47.558601],
            [-68.576111, 47.424709],
            [-69.039711, 47.29715],
            [-69.047478, 47.421989],
            [-69.230789, 47.4533],
            [-69.985458, 46.69133],
            [-70.014633, 46.57056],
            [-70.047089, 46.426079],
            [-70.191544, 46.334801],
            [-70.283974, 46.190208],
            [-70.229797, 46.137402],
            [-70.305328, 46.06662],
            [-70.280502, 46.05312],
            [-70.310783, 45.968739],
            [-70.247948, 45.94458],
            [-70.254448, 45.89896],
            [-70.416687, 45.79026],
            [-70.396873, 45.722],
            [-70.55275, 45.660622],
            [-70.72039, 45.512909],
            [-70.635406, 45.391918],
            [-70.797447, 45.425129],
            [-70.829613, 45.390678],
            [-70.813148, 45.35463],
            [-70.843361, 45.278091],
            [-70.87693, 45.225399],
            [-70.959862, 45.338821],
            [-71.08799, 45.301418],
            [-71.153572, 45.237919],
            [-71.297707, 45.293449],
            [-71.386864, 45.23489],
            [-71.447037, 45.236038],
            [-71.403, 45.202759],
            [-71.430893, 45.116951],
            [-71.503471, 45.059841],
            [-71.505836, 45.013309],
            [-71.902328, 45.00729],
            [-72.547668, 45.005329],
            [-73.188972, 45.00845],
            [-73.345154, 45.0061],
            [-74.021927, 44.990799],
            [-74.736481, 44.99287],
            [-74.506393, 45.071659],
            [-74.433609, 45.149441],
            [-74.324722, 45.201099],
            [-74.479172, 45.316669],
            [-74.396667, 45.564159],
            [-74.646118, 45.635551],
            [-74.875839, 45.64159],
            [-75.453339, 45.523609],
            [-75.847229, 45.37249],
            [-76.076683, 45.513889],
            [-76.184433, 45.521099],
            [-76.337784, 45.45583],
            [-76.607773, 45.55249],
            [-76.666397, 45.590549],
            [-76.710564, 45.712769],
            [-76.783333, 45.76194],
            [-76.804718, 45.864719],
            [-76.910553, 45.889992],
            [-76.920837, 45.806381],
            [-77.002792, 45.790279],
            [-77.206123, 45.877209],
            [-77.278877, 45.937771],
            [-77.321953, 46.026661],
            [-77.554993, 46.151932],
            [-77.680557, 46.183601],
            [-78.703613, 46.337212],
            [-78.75528, 46.400539],
            [-78.950562, 46.511669],
            [-79.155563, 46.812489],
            [-79.305557, 46.94054],
            [-79.443878, 47.11055],
            [-79.439438, 47.26527],
            [-79.576401, 47.441929],
            [-79.511322, 47.565571],
            [-79.519012, 48.605289],
            [-79.520683, 48.764679],
            [-79.537613, 50.958599],
            [-79.355827, 50.777489],
            [-79.348053, 50.73193],
            [-79.330002, 50.758331],
            [-79.383987, 50.836269],
            [-79.421944, 50.880859],
            [-79.537804, 50.982368],
            [-79.539436, 51.191662],
            [-79.547096, 51.460129],
            [-79.508347, 51.468601],
            [-79.551392, 51.553051],
            [-79.353882, 51.656101],
            [-79.239723, 51.634991],
            [-79.285278, 51.562489],
            [-79.274719, 51.530548],
            [-79.127487, 51.538052],
            [-79.024437, 51.476379],
            [-78.938316, 51.285831],
            [-78.951401, 51.215549],
            [-78.844727, 51.163601],
            [-78.920547, 51.231098],
            [-78.904449, 51.305271],
            [-78.823624, 51.34943],
            [-78.888344, 51.39666],
            [-78.763634, 51.486382],
            [-78.688599, 51.48444],
            [-78.820282, 51.51305],
            [-78.804001, 51.61021],
            [-78.943047, 51.658878],
            [-78.938316, 51.694439],
            [-79.033333, 51.776379],
            [-78.836937, 51.841381],
            [-78.894173, 51.93832],
            [-78.747772, 51.97332],
            [-78.695831, 52.008049],
            [-78.704178, 52.0536],
            [-78.638062, 52.057209],
            [-78.648064, 52.085819],
            [-78.579453, 52.111382],
            [-78.537514, 52.18082],
            [-78.556381, 52.23555],
            [-78.405838, 52.23777],
            [-78.451683, 52.271381],
            [-78.556664, 52.272221],
            [-78.497223, 52.358601],
            [-78.544159, 52.435822],
            [-78.506958, 52.460548],
            [-78.566391, 52.485271],
            [-78.545273, 52.514721],
            [-78.763344, 52.564442],
            [-78.69194, 52.5961],
            [-78.730003, 52.615002],
            [-78.725281, 52.648048],
            [-78.778877, 52.653599],
            [-78.754997, 52.695271],
            [-78.820847, 52.703609],
            [-78.771393, 52.709721],
            [-78.783333, 52.734718],
            [-78.850281, 52.764721],
            [-78.724716, 52.785549],
            [-78.728882, 52.823879],
            [-78.780838, 52.81805],
            [-78.68721, 52.87999],
            [-78.85611, 52.877769],
            [-78.899727, 52.922771],
            [-78.864723, 52.963612],
            [-78.786667, 52.96944],
            [-78.826111, 52.978039],
            [-78.845551, 53.01194],
            [-78.983063, 53.023609],
            [-78.918327, 53.052769],
            [-78.974716, 53.069439],
            [-78.915558, 53.165272],
            [-78.944443, 53.182499],
            [-78.905563, 53.191929],
            [-78.92749, 53.218601],
            [-78.888062, 53.224709],
            [-78.920273, 53.247768],
            [-78.895844, 53.26527],
            [-78.956673, 53.283611],
            [-78.941101, 53.379162],
            [-78.988052, 53.4436],
            [-79.063316, 53.43943],
            [-79.051392, 53.457211],
            [-79.110283, 53.502491],
            [-79.012787, 53.531109],
            [-79.041382, 53.50555],
            [-79.001404, 53.490829],
            [-78.959167, 53.51416],
            [-79.031113, 53.560822],
            [-78.915833, 53.560551],
            [-78.984734, 53.614719],
            [-79.063049, 53.596939],
            [-79.003342, 53.641659],
            [-79.04361, 53.6675],
            [-79.011124, 53.683601],
            [-79.151398, 53.70499],
            [-79.036392, 53.72971],
            [-79.067497, 53.764439],
            [-79.042503, 53.784721],
            [-79.06778, 53.80249],
            [-79.020554, 53.821941],
            [-79.046951, 53.835548],
            [-78.901398, 53.815269],
            [-79.0625, 53.856659],
            [-79.059998, 53.889721],
            [-79.101669, 53.909988],
            [-78.993332, 53.91193],
            [-79.136398, 53.956661],
            [-79.033073, 53.946381],
            [-79.093887, 53.963329],
            [-79.044724, 53.974991],
            [-79.097778, 53.97887],
            [-79.001099, 53.999989],
            [-78.964951, 53.997162],
            [-78.976669, 54.01833],
            [-79.077499, 54.037498],
            [-79.126938, 54.081108],
            [-79.286942, 54.082211],
            [-79.175278, 54.086651],
            [-79.16777, 54.117771],
            [-79.116386, 54.10305],
            [-79.130829, 54.124989],
            [-79.098053, 54.12888],
            [-79.138344, 54.144711],
            [-79.048889, 54.183331],
            [-79.205841, 54.154991],
            [-79.379707, 54.1936],
            [-79.283333, 54.248878],
            [-79.402222, 54.263611],
            [-79.438599, 54.276379],
            [-79.419159, 54.30138],
            [-79.461121, 54.304161],
            [-79.472778, 54.364441],
            [-79.506958, 54.370831],
            [-79.446663, 54.416939],
            [-79.501404, 54.421379],
            [-79.488052, 54.458599],
            [-79.548607, 54.504711],
            [-79.524719, 54.523048],
            [-79.602783, 54.555271],
            [-79.521393, 54.587212],
            [-79.576401, 54.60944],
            [-79.630547, 54.57111],
            [-79.596657, 54.63916],
            [-79.675552, 54.62582],
            [-79.662514, 54.66777],
            [-79.761124, 54.658329],
            [-79.636124, 54.698879],
            [-79.62999, 54.740551],
            [-79.619164, 54.711109],
            [-79.240829, 54.81221],
            [-78.976669, 54.843048],
            [-78.862778, 54.9086],
            [-78.335564, 55.03944],
            [-77.760277, 55.287769],
            [-77.65834, 55.37027],
            [-77.214722, 55.595268],
            [-77.086121, 55.705551],
            [-77.18306, 55.689709],
            [-76.840286, 55.928879],
            [-76.797501, 55.990269],
            [-76.658051, 56.060822],
            [-76.619453, 56.185822],
            [-76.53833, 56.297779],
            [-76.51889, 56.406101],
            [-76.505569, 56.803051],
            [-76.555832, 57.035],
            [-76.529716, 57.105831],
            [-76.653061, 57.401379],
            [-76.811394, 57.62471],
            [-76.806107, 57.669159],
            [-77.147232, 58.02277],
            [-77.487503, 58.165272],
            [-77.444153, 58.187771],
            [-77.609444, 58.269989],
            [-78.012512, 58.378601],
            [-78.013062, 58.413052],
            [-78.074173, 58.454441],
            [-78.120003, 58.446941],
            [-78.268623, 58.519161],
            [-78.397232, 58.620831],
            [-78.426102, 58.606098],
            [-78.327499, 58.53722],
            [-78.383904, 58.54166],
            [-78.47139, 58.61166],
            [-78.568619, 58.614441],
            [-78.549438, 58.660271],
            [-78.570007, 58.67305],
            [-78.467216, 58.70166],
            [-78.549438, 58.767769],
            [-78.495003, 58.77721],
            [-78.488052, 58.820831],
            [-78.549438, 58.91082],
            [-78.530289, 58.938881],
            [-78.570557, 58.96138],
            [-78.450844, 58.909988],
            [-78.43721, 58.952221],
            [-78.361656, 58.958599],
            [-78.363892, 58.912491],
            [-78.338333, 58.912769],
            [-78.245003, 58.99194],
            [-78.316963, 58.98555],
            [-78.269173, 59.016941],
            [-78.283333, 59.049999],
            [-78.205566, 59.050541],
            [-78.21611, 59.106941],
            [-78.178329, 59.08527],
            [-78.127487, 59.10833],
            [-78.153343, 59.15221],
            [-78.08667, 59.156651],
            [-78.092773, 59.215],
            [-77.828888, 59.281109],
            [-77.890839, 59.310822],
            [-77.778061, 59.32333],
            [-77.764717, 59.351662],
            [-77.800827, 59.368599],
            [-77.684998, 59.39333],
            [-77.779167, 59.426102],
            [-77.912514, 59.415272],
            [-77.848618, 59.446098],
            [-77.867493, 59.5],
            [-77.72139, 59.539719],
            [-77.712784, 59.620831],
            [-77.801102, 59.67527],
            [-77.741943, 59.691101],
            [-77.773903, 59.709721],
            [-77.670273, 59.674438],
            [-77.570282, 59.69582],
            [-77.473053, 59.634991],
            [-77.454178, 59.579159],
            [-77.313889, 59.564991],
            [-77.440552, 59.59972],
            [-77.452499, 59.663052],
            [-77.541672, 59.688881],
            [-77.491943, 59.71249],
            [-77.533073, 59.754711],
            [-77.472504, 59.742771],
            [-77.43277, 59.784161],
            [-77.29361, 59.79361],
            [-77.363617, 59.890831],
            [-77.42749, 59.914711],
            [-77.300278, 59.965],
            [-77.323059, 59.98888],
            [-77.236389, 59.994709],
            [-77.313889, 60.012501],
            [-77.206947, 60.04277],
            [-77.189987, 60.086109],
            [-77.059433, 60.062771],
            [-76.958054, 60.10305],
            [-76.858887, 60.101101],
            [-76.788597, 60.068329],
            [-76.833618, 60.115002],
            [-76.758904, 60.159161],
            [-76.924438, 60.11166],
            [-77.199997, 60.150829],
            [-77.105003, 60.108601],
            [-77.259171, 60.11805],
            [-77.232498, 60.053879],
            [-77.315826, 60.030548],
            [-77.358047, 60.060822],
            [-77.433319, 60.036381],
            [-77.630829, 60.065269],
            [-77.558037, 60.126659],
            [-77.406387, 60.130268],
            [-77.551392, 60.19471],
            [-77.470284, 60.213329],
            [-77.573624, 60.25972],
            [-77.543327, 60.285831],
            [-77.623322, 60.26833],
            [-77.602783, 60.32999],
            [-77.743607, 60.39333],
            [-77.740829, 60.423611],
            [-77.574448, 60.502491],
            [-77.553879, 60.542221],
            [-77.41333, 60.544159],
            [-77.549438, 60.571381],
            [-77.670273, 60.549721],
            [-77.833893, 60.639431],
            [-77.607773, 60.758331],
            [-77.745003, 60.76416],
            [-77.511673, 60.83638],
            [-77.890289, 60.75],
            [-77.858337, 60.791939],
            [-77.965843, 60.791382],
            [-77.896393, 60.828049],
            [-78.190552, 60.788891],
            [-78.159439, 60.86721],
            [-77.946663, 61.004711],
            [-77.811111, 61.052219],
            [-77.790283, 61.151379],
            [-77.694443, 61.20277],
            [-77.752228, 61.229431],
            [-77.719162, 61.26527],
            [-77.79277, 61.45388],
            [-77.700287, 61.426102],
            [-77.678879, 61.461109],
            [-77.560547, 61.468048],
            [-77.54834, 61.486111],
            [-77.670273, 61.519161],
            [-77.621109, 61.5611],
            [-77.474716, 61.54166],
            [-77.631943, 61.579441],
            [-77.589172, 61.604439],
            [-77.710564, 61.605831],
            [-77.785828, 61.700001],
            [-77.898903, 61.686378],
            [-78.006119, 61.733051],
            [-78.003067, 61.77972],
            [-78.08139, 61.865829],
            [-78.08139, 61.951099],
            [-78.137787, 62.009159],
            [-78.161667, 62.169159],
            [-78.155838, 62.278332],
            [-78.023903, 62.39333],
            [-77.70195, 62.470268],
            [-77.486938, 62.590271],
            [-77.418327, 62.58971],
            [-77.374161, 62.551929],
            [-77.04834, 62.519161],
            [-76.948883, 62.535831],
            [-76.143066, 62.379162],
            [-76.097778, 62.340549],
            [-75.949722, 62.350269],
            [-75.645844, 62.291939],
            [-75.592216, 62.264439],
            [-75.836937, 62.187771],
            [-75.894997, 62.1586],
            [-75.82695, 62.158878],
            [-75.486938, 62.296391],
            [-75.321953, 62.3111],
            [-75.152496, 62.284721],
            [-75.152786, 62.227489],
            [-75.066673, 62.26833],
            [-74.928329, 62.249161],
            [-74.765007, 62.14444],
            [-74.556381, 62.10471],
            [-74.751106, 62.191101],
            [-74.72583, 62.244999],
            [-74.410004, 62.251389],
            [-74.280563, 62.321659],
            [-74, 62.358051],
            [-73.868057, 62.458328],
            [-73.678879, 62.479988],
            [-73.5625, 62.377769],
            [-73.387512, 62.36916],
            [-73.211403, 62.312771],
            [-73.204178, 62.261379],
            [-73.063606, 62.182499],
            [-72.903877, 62.142769],
            [-72.94249, 62.11805],
            [-72.784439, 62.106941],
            [-72.815002, 62.137772],
            [-72.736938, 62.14333],
            [-72.626663, 62.115551],
            [-72.617493, 62.09193],
            [-72.661942, 62.05666],
            [-72.596123, 62.04916],
            [-72.618332, 62.033611],
            [-72.604446, 61.9711],
            [-72.678879, 61.949429],
            [-72.680832, 61.900269],
            [-72.794197, 61.834751],
            [-72.66806, 61.879162],
            [-72.669159, 61.847771],
            [-72.596123, 61.80555],
            [-72.636124, 61.90221],
            [-72.59584, 61.94276],
            [-72.205841, 61.863331],
            [-72.12999, 61.79916],
            [-72.200287, 61.789162],
            [-72.224716, 61.751659],
            [-72.145844, 61.75388],
            [-72.061661, 61.73555],
            [-71.99028, 61.67638],
            [-72.087784, 61.598881],
            [-72.236656, 61.619438],
            [-72.303329, 61.567211],
            [-72.210831, 61.598331],
            [-72.08371, 61.582458],
            [-71.980293, 61.599998],
            [-71.933609, 61.663601],
            [-71.958054, 61.70694],
            [-71.675278, 61.658878],
            [-71.571671, 61.605549],
            [-71.549438, 61.55888],
            [-71.855003, 61.530548],
            [-71.789436, 61.521931],
            [-71.795273, 61.492489],
            [-71.746948, 61.465832],
            [-71.887787, 61.43082],
            [-71.585281, 61.406651],
            [-71.689163, 61.36832],
            [-71.822777, 61.372761],
            [-71.792503, 61.357498],
            [-71.818619, 61.346661],
            [-71.664436, 61.32777],
            [-71.72583, 61.285831],
            [-71.598892, 61.254169],
            [-71.62999, 61.204708],
            [-71.569458, 61.214439],
            [-71.578339, 61.15527],
            [-71.512222, 61.161659],
            [-71.530838, 61.213612],
            [-71.473328, 61.223049],
            [-71.370003, 61.118599],
            [-71.361389, 61.14777],
            [-71.215843, 61.16777],
            [-71.193329, 61.115829],
            [-71.174713, 61.139992],
            [-71.011398, 61.121658],
            [-70.928329, 61.102489],
            [-70.936111, 61.073051],
            [-70.818069, 61.064442],
            [-70.767227, 61.048328],
            [-70.780563, 61.078331],
            [-70.751404, 61.082211],
            [-70.656387, 61.050541],
            [-70.676941, 61.02166],
            [-70.547501, 61.0075],
            [-70.566391, 61.040829],
            [-70.527222, 61.060822],
            [-70.426392, 61.023319],
            [-70.444992, 61.053879],
            [-70.410004, 61.05471],
            [-70.41362, 61.086651],
            [-70.351936, 61.061378],
            [-70.326401, 61.09388],
            [-70.246658, 61.067501],
            [-70.146118, 61.084721],
            [-70.107773, 61.059719],
            [-70.159729, 61.014439],
            [-70.09639, 61.009991],
            [-70.114441, 60.985271],
            [-70.085831, 60.95499],
            [-70.088058, 60.89777],
            [-70.147781, 60.87999],
            [-70.067497, 60.875271],
            [-70.061111, 60.84444],
            [-69.92749, 60.80777],
            [-69.849731, 60.846661],
            [-69.93306, 60.850552],
            [-69.756958, 60.91888],
            [-69.740547, 60.881378],
            [-69.658623, 60.876942],
            [-69.64389, 60.90332],
            [-69.689713, 60.961658],
            [-69.656662, 61.0536],
            [-69.599991, 61.08194],
            [-69.51445, 61.069439],
            [-69.426392, 60.923611],
            [-69.366096, 60.90694],
            [-69.391113, 60.871929],
            [-69.368607, 60.8111],
            [-69.71611, 60.68277],
            [-69.685822, 60.64444],
            [-69.696663, 60.598049],
            [-69.656952, 60.574711],
            [-69.826111, 60.525551],
            [-69.748894, 60.461658],
            [-69.776398, 60.415539],
            [-69.730003, 60.404991],
            [-69.722504, 60.364159],
            [-69.768066, 60.31221],
            [-69.606377, 60.232769],
            [-69.651108, 60.183331],
            [-69.594162, 60.18082],
            [-69.62471, 60.067501],
            [-69.7164, 60.04166],
            [-69.841949, 60.063049],
            [-69.841377, 60.023048],
            [-69.770279, 60.001942],
            [-69.794037, 59.98941],
            [-70.33168, 60.028332],
            [-70.315002, 60.005001],
            [-70.556953, 59.992771],
            [-71.030563, 60.061939],
            [-70.566963, 59.968601],
            [-70.227219, 59.98666],
            [-70.061394, 59.944988],
            [-69.726936, 59.963612],
            [-69.717499, 59.923882],
            [-69.759171, 59.902489],
            [-69.562767, 59.86805],
            [-69.612213, 59.784439],
            [-69.545837, 59.763611],
            [-69.654167, 59.697769],
            [-69.519447, 59.653049],
            [-69.571953, 59.590271],
            [-69.679443, 59.56361],
            [-69.748337, 59.509991],
            [-69.759453, 59.481098],
            [-69.697487, 59.480549],
            [-69.645279, 59.419159],
            [-69.66777, 59.386662],
            [-69.631378, 59.374989],
            [-69.758347, 59.320271],
            [-69.631104, 59.298882],
            [-69.412514, 59.355],
            [-69.362503, 59.339989],
            [-69.367218, 59.307499],
            [-69.249733, 59.323608],
            [-69.266403, 59.283329],
            [-69.235001, 59.239429],
            [-69.29277, 59.208328],
            [-69.371933, 59.240551],
            [-69.373894, 59.18943],
            [-69.419159, 59.19249],
            [-69.420837, 59.223049],
            [-69.470284, 59.213879],
            [-69.540283, 59.166382],
            [-69.533073, 59.110821],
            [-69.501709, 59.106331],
            [-69.463058, 59.129429],
            [-69.344162, 59.091099],
            [-69.439713, 59.02277],
            [-69.506958, 59.069721],
            [-69.454178, 58.89222],
            [-69.518341, 58.885551],
            [-69.547501, 58.808041],
            [-69.670837, 58.792221],
            [-69.71611, 58.864719],
            [-69.672234, 58.89138],
            [-69.671661, 58.93055],
            [-69.711937, 58.91888],
            [-69.709442, 58.972759],
            [-69.756668, 58.98999],
            [-69.78833, 58.96693],
            [-69.869164, 59.053051],
            [-69.861107, 58.984989],
            [-69.895554, 58.957771],
            [-69.80722, 58.931938],
            [-69.797501, 58.82888],
            [-69.898354, 58.873322],
            [-69.899986, 58.82777],
            [-70.25, 58.769711],
            [-70.036392, 58.733601],
            [-69.919724, 58.77388],
            [-69.925003, 58.69249],
            [-69.79834, 58.704708],
            [-69.876663, 58.643608],
            [-69.813049, 58.589161],
            [-69.700287, 58.697208],
            [-69.642776, 58.695541],
            [-69.608047, 58.754711],
            [-69.507507, 58.774712],
            [-69.348892, 58.871658],
            [-69.12999, 58.901661],
            [-68.889183, 58.876942],
            [-68.763062, 58.921101],
            [-68.390694, 58.81171],
            [-68.355827, 58.774441],
            [-68.380547, 58.697769],
            [-68.326683, 58.664711],
            [-68.348343, 58.600552],
            [-68.274437, 58.590549],
            [-68.288887, 58.524712],
            [-68.193604, 58.540829],
            [-68.223587, 58.397831],
            [-68.31723, 58.241661],
            [-68.344162, 58.127491],
            [-68.46666, 58.045551],
            [-68.964722, 57.95916],
            [-69.202499, 57.868599],
            [-69.369049, 57.765251],
            [-68.951401, 57.938599],
            [-68.404167, 58.039719],
            [-68.313606, 58.10305],
            [-68.303329, 58.195541],
            [-68.23056, 58.25555],
            [-68.168327, 58.414711],
            [-68.171387, 58.48999],
            [-68.125397, 58.526058],
            [-68.026672, 58.51305],
            [-68.003342, 58.576389],
            [-67.959167, 58.558041],
            [-67.958344, 58.51194],
            [-67.896118, 58.500549],
            [-67.923317, 58.403049],
            [-67.855827, 58.314991],
            [-67.897232, 58.25333],
            [-68.09584, 58.138599],
            [-68.128326, 58.073608],
            [-68.006668, 58.131939],
            [-67.954453, 58.211941],
            [-67.861656, 58.232208],
            [-67.801392, 58.296661],
            [-67.830566, 58.331379],
            [-67.787514, 58.464439],
            [-67.731949, 58.461941],
            [-67.667503, 58.42749],
            [-67.707497, 58.383049],
            [-67.693047, 58.354439],
            [-67.737213, 58.326939],
            [-67.730827, 58.283611],
            [-67.642776, 58.2486],
            [-67.703056, 58.16193],
            [-67.671661, 58.070831],
            [-67.735817, 57.966381],
            [-67.709732, 57.923321],
            [-67.708344, 57.982491],
            [-67.635559, 58.04277],
            [-67.65361, 58.122761],
            [-67.481377, 58.27388],
            [-67.378883, 58.276939],
            [-67.16861, 58.37804],
            [-67.090561, 58.350552],
            [-67.056381, 58.388889],
            [-67.077499, 58.4175],
            [-67.011398, 58.424709],
            [-66.944153, 58.501942],
            [-66.876938, 58.47916],
            [-66.898064, 58.445271],
            [-66.846657, 58.426659],
            [-66.804443, 58.493881],
            [-66.618607, 58.48777],
            [-66.669449, 58.53194],
            [-66.620827, 58.54694],
            [-66.621109, 58.60471],
            [-66.583618, 58.631939],
            [-66.599167, 58.673321],
            [-66.563606, 58.673321],
            [-66.551941, 58.71138],
            [-66.458893, 58.721661],
            [-66.489723, 58.75444],
            [-66.465012, 58.819988],
            [-66.349991, 58.843048],
            [-66.11055, 58.720539],
            [-66.072243, 58.650829],
            [-65.999161, 58.65443],
            [-65.985947, 58.622162],
            [-65.935822, 58.60471],
            [-66.021393, 58.495541],
            [-65.991096, 58.40694],
            [-66.089447, 58.365269],
            [-66.065552, 58.320271],
            [-66.041672, 58.36805],
            [-65.965012, 58.391941],
            [-65.920273, 58.44582],
            [-65.98056, 58.483051],
            [-65.904999, 58.52972],
            [-65.884171, 58.58083],
            [-65.828613, 58.572769],
            [-65.935547, 58.643051],
            [-65.948608, 58.682209],
            [-66.031387, 58.689159],
            [-66.044998, 58.755829],
            [-66.103882, 58.773609],
            [-66.022507, 58.86166],
            [-65.970001, 58.826099],
            [-65.797501, 58.847488],
            [-65.990829, 58.899719],
            [-65.85083, 58.946381],
            [-65.880829, 59.010551],
            [-65.678047, 58.926941],
            [-65.678329, 58.963879],
            [-65.793327, 59.005829],
            [-65.739723, 59.018051],
            [-65.777496, 59.029991],
            [-65.660278, 59.044159],
            [-65.5, 58.98333],
            [-65.58223, 59.049721],
            [-65.317497, 59.041382],
            [-65.54361, 59.101101],
            [-65.638062, 59.069439],
            [-65.715012, 59.148331],
            [-65.684433, 59.19305],
            [-65.737778, 59.209991],
            [-65.743057, 59.265831],
            [-65.676102, 59.261108],
            [-65.591377, 59.188042],
            [-65.610817, 59.253052],
            [-65.492493, 59.255001],
            [-65.565552, 59.30138],
            [-65.570847, 59.37804],
            [-65.357224, 59.27721],
            [-65.444992, 59.351391],
            [-65.430832, 59.403599],
            [-65.383057, 59.401661],
            [-65.49527, 59.43388],
            [-65.469727, 59.444149],
            [-65.542221, 59.48999],
            [-65.188599, 59.45916],
            [-65.126663, 59.407768],
            [-64.983322, 59.376381],
            [-65.22084, 59.488331],
            [-65.411667, 59.509441],
            [-65.54361, 59.736382],
            [-65.468338, 59.75972],
            [-65.495003, 59.789989],
            [-65.371933, 59.796391],
            [-65.381668, 59.824169],
            [-65.333893, 59.84721],
            [-65.152786, 59.779991],
            [-64.983887, 59.762772],
            [-65.211121, 59.833599],
            [-65.231949, 59.88583],
            [-65.133904, 59.868599],
            [-65.20639, 59.919991],
            [-65.148903, 59.92305],
            [-65.138611, 59.953049],
            [-65.066673, 59.934429],
            [-65.12471, 59.971661],
            [-65.063316, 59.97694],
            [-65.126099, 60.011108],
            [-65.12471, 60.0425],
            [-65.071671, 60.035271],
            [-65.026947, 60.067501],
            [-65.0625, 60.096939],
            [-64.988892, 60.11694],
            [-64.993057, 60.14888],
            [-64.943604, 60.191101],
            [-64.874161, 60.20583],
            [-64.955276, 60.255829],
            [-64.861389, 60.29694],
            [-64.835564, 60.363331],
            [-64.641678, 60.344711],
            [-64.534821, 60.302601],
            [-64.853882, 60.261669],
            [-64.851936, 60.22332],
            [-64.767227, 60.174999],
            [-64.637222, 60.178329],
            [-64.598892, 60.111111],
            [-64.734161, 60.103321],
            [-64.702499, 60.064991],
            [-64.796951, 60.039719],
            [-64.906387, 60.04805],
            [-64.847778, 59.964161],
            [-64.65361, 59.929989],
            [-64.661911, 59.88361],
            [-64.767776, 59.87999],
            [-64.744453, 59.84499],
            [-64.814163, 59.81889],
            [-64.773064, 59.698879],
            [-64.82695, 59.531109],
            [-64.700844, 59.44582],
            [-64.432503, 59.52943],
            [-64.340286, 59.49638],
            [-64.5, 59.433048],
            [-64.485001, 59.396389],
            [-64.538063, 59.3736],
            [-64.499161, 59.33971],
            [-64.53833, 59.285549],
            [-64.507507, 59.25],
            [-64.534729, 59.197491],
            [-64.493057, 59.158878],
            [-64.493057, 59.104439],
            [-64.365013, 59.07333],
            [-64.291382, 59.016659],
            [-64.415833, 58.981098],
            [-64.656387, 59.028599],
            [-64.720001, 59.069988],
            [-64.829453, 59.03249],
            [-64.864723, 58.990551],
            [-64.838898, 58.958599],
            [-64.878883, 58.92749],
            [-64.809433, 58.911098],
            [-64.718887, 58.945271],
            [-64.568069, 58.887211],
            [-64.300278, 58.882488],
            [-64.258621, 58.849998],
            [-64.257507, 58.77943],
            [-64.156113, 58.74527],
            [-63.999439, 58.81916],
            [-63.841942, 58.82972],
            [-63.766651, 58.874081],
            [-63.588329, 58.846378],
            [-63.480549, 58.7486],
            [-63.574169, 58.715832],
            [-64.051102, 58.676102],
            [-64.097504, 58.60722],
            [-64.084167, 58.55555],
            [-64.022507, 58.52055],
            [-63.886951, 58.563049],
            [-63.81945, 58.471371],
            [-64.156387, 58.348331],
            [-64.21167, 58.23555],
            [-64.398064, 58.18166],
            [-64.431953, 58.086109],
            [-64.208618, 58.033329],
            [-64.214447, 57.977489],
            [-64.057503, 57.76944],
            [-64.022507, 57.771381],
            [-64.006668, 57.812771],
            [-63.92667, 57.797218],
            [-63.891941, 57.73027],
            [-63.825562, 57.690269],
            [-63.765282, 57.732769],
            [-63.70055, 57.662769],
            [-63.653332, 57.731098],
            [-63.595829, 57.734161],
            [-63.60722, 57.663601],
            [-63.721378, 57.602489],
            [-63.765011, 57.573879],
            [-63.763889, 57.482491],
            [-63.709721, 57.37249],
            [-63.836941, 57.325829],
            [-63.87611, 57.242771],
            [-63.855282, 57.193878],
            [-63.81472, 57.27972],
            [-63.741112, 57.232769],
            [-63.7925, 57.165272],
            [-63.78751, 57.111938],
            [-63.873611, 57.06638],
            [-63.863331, 56.992771],
            [-63.917782, 56.930271],
            [-63.87138, 56.87804],
            [-64.00528, 56.850552],
            [-63.992229, 56.785831],
            [-64.083618, 56.765549],
            [-64.12944, 56.71027],
            [-63.945, 56.549721],
            [-63.942219, 56.479431],
            [-63.87138, 56.43943],
            [-63.97472, 56.412769],
            [-64.169724, 56.427769],
            [-64.114166, 56.39027],
            [-64.105827, 56.331379],
            [-64.136673, 56.3036],
            [-64.08667, 56.259159],
            [-63.97472, 56.259159],
            [-63.876949, 56.217209],
            [-64.010834, 56.159988],
            [-64.020279, 56.076389],
            [-63.847778, 56.12027],
            [-63.880001, 56.076939],
            [-63.79084, 56.039162],
            [-63.445, 56.02277],
            [-63.69416, 55.942211],
            [-63.715279, 55.859161],
            [-63.488331, 55.696659],
            [-63.448051, 55.59943],
            [-63.29834, 55.523319],
            [-63.144169, 55.526379],
            [-63.176392, 55.419441],
            [-63.095829, 55.349998],
            [-63.176571, 55.296539],
            [-63.519451, 55.183601],
            [-63.566109, 55.060268],
            [-63.435268, 54.973598],
            [-63.66917, 54.612209],
            [-63.976189, 54.626461],
            [-64.325562, 54.751942],
            [-64.404327, 54.75251],
            [-64.552223, 54.720539],
            [-64.811661, 54.749161],
            [-65.121933, 54.69471],
            [-65.443878, 54.745831],
            [-65.689987, 54.713329],
            [-65.835281, 54.831379],
            [-65.858337, 54.907768],
            [-65.957779, 54.92944],
            [-66.035004, 54.90971],
            [-66.23999, 54.99194],
            [-66.576401, 55.250549],
            [-66.681953, 55.25333],
            [-66.688049, 55.301102],
            [-66.77861, 55.338879],
            [-66.844162, 55.326099],
            [-66.688889, 55.204159],
            [-66.740829, 55.17749],
            [-66.765839, 55.105549],
            [-66.65976, 54.978031],
            [-66.732773, 54.94833],
            [-66.678329, 54.894161],
            [-66.605003, 54.790829],
            [-66.712219, 54.78194],
            [-66.698608, 54.742222],
            [-66.738602, 54.732769],
            [-66.946953, 54.801659],
            [-67.028061, 54.89777],
            [-67.297783, 55.008049],
            [-67.273903, 55.066669],
            [-67.428329, 55.07111],
            [-67.453056, 55.018879],
            [-67.362213, 54.87582],
            [-67.263344, 54.825272],
            [-67.30777, 54.783878],
            [-67.184998, 54.71944],
            [-67.213898, 54.66888],
            [-67.036392, 54.621658],
            [-67.219452, 54.607498],
            [-67.305557, 54.504169],
            [-67.492493, 54.58194],
            [-67.601387, 54.56638],
            [-67.633057, 54.479988],
            [-67.586121, 54.4175],
            [-67.59584, 54.365269],
            [-67.49472, 54.314442],
            [-67.531677, 54.248878],
            [-67.485001, 54.234161],
            [-67.637222, 54.174999],
            [-67.781952, 54.063881],
            [-67.738441, 54.000011],
            [-67.609161, 53.928051],
            [-67.588058, 53.85722],
            [-67.430557, 53.778332],
            [-67.491943, 53.603882],
            [-67.141953, 53.542221],
            [-67.128883, 53.464161],
            [-67.068619, 53.4286],
            [-67.06778, 53.384159],
            [-66.987503, 53.403599],
            [-66.910004, 53.340271],
            [-66.991669, 53.195271],
            [-66.948608, 53.109718],
            [-66.979446, 53.027771],
            [-67.078056, 52.94804],
            [-67.058327, 52.804989],
            [-67.092773, 52.72971],
            [-67.067497, 52.699429],
            [-66.891388, 52.673321],
            [-66.87471, 52.747768],
            [-66.813316, 52.66415],
            [-66.66806, 52.725819],
            [-66.648064, 52.94582],
            [-66.525284, 52.943321],
            [-66.476936, 53.034161],
            [-66.345551, 52.974159],
            [-66.26973, 52.877491],
            [-66.378883, 52.836109],
            [-66.328339, 52.736938],
            [-66.433884, 52.706379],
            [-66.43222, 52.611382],
            [-66.373611, 52.5425],
            [-66.446663, 52.530819],
            [-66.358047, 52.406651],
            [-66.458618, 52.3661],
            [-66.486656, 52.312489],
            [-66.438889, 52.200829],
            [-66.335564, 52.136379],
            [-66.303879, 52.222759],
            [-66.319733, 52.289162],
            [-66.275558, 52.311661],
            [-66.173889, 52.220539],
            [-66.068069, 52.216381],
            [-66.063889, 52.11832],
            [-65.916946, 52.050831],
            [-65.801666, 52.04277],
            [-65.799438, 52.095539],
            [-65.758621, 52.111382],
            [-65.56723, 52.046661],
            [-65.538597, 52.123878],
            [-65.453613, 52.20805],
            [-65.108612, 52.17083],
            [-64.853333, 52.021931],
            [-64.84584, 51.93082],
            [-64.587784, 51.82888],
            [-64.593887, 51.796101],
            [-64.718063, 51.747768],
            [-64.703056, 51.69276],
            [-64.604172, 51.642769],
            [-64.581123, 51.55777],
            [-64.44249, 51.66777],
            [-64.264183, 51.739719],
            [-64.357224, 51.850552],
            [-64.317497, 51.903599],
            [-64.330841, 51.965271],
            [-64.274437, 52.00333],
            [-64.293327, 52.046391],
            [-64.257782, 52.12804],
            [-64.115829, 52.151379],
            [-64.131378, 52.28138],
            [-64.08168, 52.37249],
            [-64.208893, 52.548882],
            [-64.175278, 52.60944],
            [-64.202217, 52.672218],
            [-64.156662, 52.738331],
            [-64.201401, 52.790829],
            [-64.172501, 52.85083],
            [-64.080841, 52.881378],
            [-63.959999, 52.867771],
            [-63.982769, 52.933601],
            [-63.919449, 52.97694],
            [-63.923611, 53.077221],
            [-63.693329, 53.113609],
            [-63.634731, 53.094151],
            [-63.62611, 53.042221],
            [-63.54195, 52.970829],
            [-63.595829, 52.770771],
            [-63.423061, 52.707211],
            [-63.377781, 52.650829],
            [-63.839722, 52.61805],
            [-63.971378, 52.570831],
            [-64.08168, 52.470539],
            [-64.027496, 52.433048],
            [-63.976391, 52.458599],
            [-63.930279, 52.41777],
            [-63.92445, 52.383881],
            [-64.013062, 52.387211],
            [-63.987499, 52.362499],
            [-63.941269, 52.264],
            [-63.65535, 52.124359],
            [-63.637779, 52.039989],
            [-63.824718, 52.070549],
            [-63.75111, 51.999722],
            [-57.367771, 51.999439],
            [-57.101109, 52],
            [-57.10424, 51.41267],
          ],
        ],
      ],
    },
    id: 'CA_QC',
    properties: {
      name: 'Quebec',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-55.88306, 53.486382],
            [-55.729439, 53.450272],
            [-55.79528, 53.458328],
            [-55.774441, 53.41444],
            [-55.817219, 53.41777],
            [-55.801392, 53.39249],
            [-55.971661, 53.44582],
            [-55.88306, 53.486382],
          ],
        ],
        [
          [
            [-55.466942, 50.784161],
            [-55.557499, 50.701389],
            [-55.653049, 50.727211],
            [-55.61916, 50.791382],
            [-55.589161, 50.772491],
            [-55.46278, 50.80582],
            [-55.466942, 50.784161],
          ],
        ],
        [
          [
            [-54.08112, 49.736382],
            [-53.98167, 49.649441],
            [-54.257778, 49.566669],
            [-54.293331, 49.580551],
            [-54.303329, 49.670269],
            [-54.25528, 49.672489],
            [-54.28278, 49.71666],
            [-54.215279, 49.720829],
            [-54.19194, 49.674999],
            [-54.148338, 49.74749],
            [-54.08112, 49.736382],
          ],
        ],
        [
          [
            [-54.52972, 49.633881],
            [-54.576672, 49.558601],
            [-54.727219, 49.528332],
            [-54.739441, 49.490269],
            [-54.871941, 49.487499],
            [-54.807499, 49.517208],
            [-54.884171, 49.546101],
            [-54.891941, 49.590549],
            [-54.793331, 49.602779],
            [-54.788342, 49.55777],
            [-54.743889, 49.544998],
            [-54.62722, 49.633049],
            [-54.620831, 49.602779],
            [-54.536121, 49.66415],
            [-54.52972, 49.633881],
          ],
        ],
        [
          [
            [-53.564159, 48.19054],
            [-53.509731, 48.19833],
            [-53.512501, 48.145271],
            [-53.553329, 48.106941],
            [-53.53083, 48.097771],
            [-53.634171, 48.075272],
            [-53.85611, 48.098049],
            [-53.935829, 48.182499],
            [-53.906391, 48.21027],
            [-53.850559, 48.161381],
            [-53.765282, 48.174709],
            [-53.68972, 48.147221],
            [-53.564159, 48.19054],
          ],
        ],
        [
          [
            [-55.927502, 47.676659],
            [-55.93, 47.639431],
            [-55.876389, 47.61166],
            [-55.901939, 47.602489],
            [-56.113621, 47.644711],
            [-55.927502, 47.676659],
          ],
        ],
        [
          [
            [-54.125, 47.640831],
            [-54.243328, 47.399441],
            [-54.364449, 47.406651],
            [-54.262218, 47.474709],
            [-54.205559, 47.546391],
            [-54.240002, 47.558041],
            [-54.172779, 47.621101],
            [-54.17194, 47.585548],
            [-54.135281, 47.668049],
            [-54.125, 47.640831],
          ],
        ],
        [
          [
            [-64.631828, 60.35746],
            [-64.790283, 60.391109],
            [-64.867493, 60.450272],
            [-64.85611, 60.473881],
            [-64.639999, 60.484718],
            [-64.525558, 60.413319],
            [-64.532227, 60.374161],
            [-64.47084, 60.405819],
            [-64.426941, 60.401379],
            [-64.427673, 60.372929],
            [-64.478333, 60.334431],
            [-64.423607, 60.28249],
            [-64.631828, 60.35746],
          ],
        ],
        [
          [
            [-64.534821, 60.302601],
            [-64.384171, 60.238331],
            [-64.613052, 60.28944],
            [-64.759453, 60.231098],
            [-64.554169, 60.262772],
            [-64.463333, 60.22694],
            [-64.562767, 60.17305],
            [-64.405563, 60.220268],
            [-64.426666, 60.19249],
            [-64.376938, 60.160549],
            [-64.486938, 60.115551],
            [-64.469162, 60.082771],
            [-64.627487, 60.070831],
            [-64.827499, 59.986382],
            [-64.603333, 60.051659],
            [-64.45639, 60.059429],
            [-64.373894, 60.1236],
            [-64.371933, 60.073051],
            [-64.416397, 60.06332],
            [-64.371109, 60.04361],
            [-64.389183, 60.01416],
            [-64.588608, 60.001942],
            [-64.398064, 59.971661],
            [-64.396957, 59.937771],
            [-64.499443, 59.891659],
            [-64.363327, 59.922489],
            [-64.330002, 59.984161],
            [-64.224442, 59.99527],
            [-64.325287, 60.015831],
            [-64.314713, 60.05138],
            [-64.173607, 60.028332],
            [-64.150284, 59.985271],
            [-64.294449, 59.948601],
            [-64.186394, 59.91972],
            [-64.259743, 59.872219],
            [-64.126099, 59.896099],
            [-64.177223, 59.785549],
            [-64.261948, 59.789162],
            [-64.254997, 59.75666],
            [-64.163887, 59.684158],
            [-63.956669, 59.689991],
            [-63.96611, 59.658878],
            [-63.923889, 59.653599],
            [-64.047501, 59.635269],
            [-64.122498, 59.519711],
            [-64.040833, 59.5536],
            [-63.997219, 59.626659],
            [-63.863621, 59.601662],
            [-63.98, 59.505268],
            [-63.87138, 59.56638],
            [-63.7225, 59.513889],
            [-63.799728, 59.41666],
            [-64.000557, 59.41444],
            [-64.06221, 59.382488],
            [-63.763889, 59.38805],
            [-63.755562, 59.358051],
            [-63.71167, 59.365269],
            [-63.805828, 59.240269],
            [-63.741112, 59.25639],
            [-63.64999, 59.362499],
            [-63.54306, 59.348049],
            [-63.534729, 59.264439],
            [-63.637779, 59.218601],
            [-63.605282, 59.209721],
            [-63.380001, 59.283878],
            [-63.45084, 59.240829],
            [-63.431671, 59.22443],
            [-63.462219, 59.1936],
            [-63.376659, 59.213329],
            [-63.366661, 59.186378],
            [-63.42556, 59.126381],
            [-63.551392, 59.084431],
            [-63.76778, 59.04916],
            [-63.934441, 59.081108],
            [-64.047234, 59.01944],
            [-63.898891, 59.05666],
            [-63.91222, 59.000549],
            [-63.868328, 58.95332],
            [-63.86916, 59.031658],
            [-63.527222, 59.030548],
            [-63.372768, 59.101101],
            [-63.125, 59.055271],
            [-63.175282, 59.026939],
            [-63.336109, 59.02499],
            [-63.224998, 59.001389],
            [-63.304169, 58.94249],
            [-63.173061, 58.97971],
            [-63.170559, 58.954708],
            [-63.226391, 58.939709],
            [-63.160549, 58.92638],
            [-63.325001, 58.855831],
            [-63.15778, 58.873322],
            [-63.182781, 58.833599],
            [-63.160278, 58.823051],
            [-63.071949, 58.902771],
            [-62.994999, 58.885551],
            [-63.053329, 58.859161],
            [-62.924171, 58.821381],
            [-62.90527, 58.789989],
            [-62.918331, 58.74527],
            [-63.04084, 58.707771],
            [-62.867771, 58.71138],
            [-62.843609, 58.659431],
            [-62.917221, 58.630821],
            [-62.915829, 58.600269],
            [-63.16917, 58.503052],
            [-63.439991, 58.51833],
            [-63.52417, 58.470829],
            [-63.385281, 58.488331],
            [-63.34333, 58.4561],
            [-63.589439, 58.300831],
            [-63.264721, 58.463051],
            [-63.089722, 58.458328],
            [-63.151939, 58.373878],
            [-63.050549, 58.450272],
            [-63.015011, 58.42527],
            [-62.80444, 58.492222],
            [-62.77528, 58.451099],
            [-62.745281, 58.49361],
            [-62.589439, 58.499722],
            [-62.556389, 58.478039],
            [-62.650551, 58.408329],
            [-62.61972, 58.376942],
            [-62.623051, 58.30444],
            [-62.858608, 58.262501],
            [-62.90527, 58.209991],
            [-62.832779, 58.251659],
            [-62.658611, 58.270271],
            [-62.59277, 58.2486],
            [-62.582779, 58.21693],
            [-62.661381, 58.17305],
            [-63.074718, 58.151379],
            [-63.012218, 58.135551],
            [-63.023891, 58.118881],
            [-63.21167, 58.062489],
            [-63.132221, 58.058331],
            [-63.15583, 58.026939],
            [-63.340839, 57.979988],
            [-63.12888, 57.997768],
            [-63.089161, 58.06221],
            [-62.838051, 58.145],
            [-62.846111, 58.118881],
            [-62.709438, 58.14249],
            [-62.652222, 58.118599],
            [-62.57362, 58.164711],
            [-62.554722, 58.121101],
            [-62.52306, 58.1661],
            [-62.452782, 58.17527],
            [-62.531391, 58.095268],
            [-62.50639, 58.055271],
            [-62.46674, 58.102371],
            [-62.368889, 58.11166],
            [-62.426392, 58.081379],
            [-62.38612, 58.07222],
            [-62.388889, 58.042221],
            [-62.33556, 58.067501],
            [-62.309719, 58.028599],
            [-62.386669, 57.993881],
            [-62.528339, 58.00555],
            [-62.672779, 57.929989],
            [-62.456951, 57.967491],
            [-62.423611, 57.957771],
            [-62.408611, 57.865551],
            [-62.34222, 57.975819],
            [-62.333611, 57.90638],
            [-62.276661, 57.950829],
            [-62.206108, 57.907768],
            [-62.127491, 57.968048],
            [-62.059719, 57.89777],
            [-62.13805, 57.835819],
            [-62.120831, 57.800831],
            [-61.99667, 57.772221],
            [-61.946949, 57.71471],
            [-61.965839, 57.687489],
            [-61.89333, 57.66972],
            [-61.88306, 57.637501],
            [-62.051392, 57.588879],
            [-62.13612, 57.528332],
            [-62.271938, 57.533051],
            [-62.33556, 57.456379],
            [-62.54528, 57.50111],
            [-62.36528, 57.41972],
            [-62.167221, 57.464439],
            [-61.891392, 57.41193],
            [-61.803051, 57.358891],
            [-61.91972, 57.365551],
            [-61.868328, 57.3386],
            [-61.863892, 57.285549],
            [-62.015839, 57.24305],
            [-61.89555, 57.211109],
            [-61.852501, 57.165272],
            [-61.77306, 57.19305],
            [-61.779171, 57.148331],
            [-61.636391, 57.232491],
            [-61.623329, 57.215271],
            [-61.696659, 57.153881],
            [-61.441669, 57.148609],
            [-61.358341, 57.08749],
            [-61.37944, 57.05138],
            [-61.348339, 57.025269],
            [-61.371941, 56.995541],
            [-61.346111, 56.951099],
            [-61.378052, 56.982208],
            [-61.487782, 56.981659],
            [-61.639999, 56.883881],
            [-61.661381, 56.809429],
            [-61.906391, 56.795269],
            [-61.889999, 56.69804],
            [-61.846111, 56.75639],
            [-61.702499, 56.73082],
            [-61.73444, 56.697208],
            [-61.825001, 56.706661],
            [-61.65889, 56.64777],
            [-61.688889, 56.61721],
            [-61.923061, 56.64555],
            [-61.895279, 56.68082],
            [-61.992771, 56.660271],
            [-62.49889, 56.77972],
            [-62.279442, 56.826389],
            [-62.04945, 56.8325],
            [-62.543892, 56.837212],
            [-62.58556, 56.79277],
            [-62.503059, 56.762211],
            [-61.916389, 56.62027],
            [-62.22472, 56.609161],
            [-61.945271, 56.57888],
            [-61.715, 56.57222],
            [-61.65527, 56.533878],
            [-61.66222, 56.5061],
            [-61.77306, 56.484718],
            [-62.046951, 56.504711],
            [-62.082218, 56.481659],
            [-61.949169, 56.46138],
            [-62.139172, 56.44471],
            [-61.98473, 56.415272],
            [-61.930279, 56.437489],
            [-61.79084, 56.39249],
            [-61.84277, 56.380821],
            [-61.772221, 56.336651],
            [-61.589161, 56.28722],
            [-61.811668, 56.305271],
            [-61.65889, 56.26749],
            [-61.778339, 56.25444],
            [-61.827782, 56.264721],
            [-61.753059, 56.277771],
            [-61.878609, 56.298611],
            [-62.076111, 56.285],
            [-61.919449, 56.212212],
            [-61.65527, 56.224159],
            [-61.56028, 56.194439],
            [-61.350842, 56.22221],
            [-61.33028, 56.176659],
            [-61.410549, 56.124439],
            [-61.342499, 56.14138],
            [-61.337502, 56.100269],
            [-61.452221, 56.056938],
            [-61.237499, 56.04277],
            [-61.50362, 56.006939],
            [-61.390839, 55.998329],
            [-61.376659, 55.97971],
            [-61.40472, 55.97221],
            [-61.460831, 55.99638],
            [-61.415001, 55.96027],
            [-61.117771, 55.966099],
            [-61.074451, 55.928329],
            [-61.07917, 55.893051],
            [-61.201672, 55.884159],
            [-61.10445, 55.845539],
            [-60.755562, 55.84943],
            [-60.733059, 55.80249],
            [-60.807781, 55.755268],
            [-60.945339, 55.751308],
            [-60.96666, 55.74638],
            [-60.9375, 55.727211],
            [-60.75639, 55.729431],
            [-60.647221, 55.822769],
            [-60.60194, 55.814709],
            [-60.62389, 55.783611],
            [-60.59277, 55.704159],
            [-60.661381, 55.66666],
            [-60.62888, 55.629162],
            [-60.679169, 55.563881],
            [-60.60223, 55.626659],
            [-60.503059, 55.803051],
            [-60.36195, 55.761669],
            [-60.34, 55.786381],
            [-60.33778, 55.7486],
            [-60.384171, 55.719711],
            [-60.37167, 55.678329],
            [-60.493889, 55.658039],
            [-60.531109, 55.59721],
            [-60.465839, 55.63166],
            [-60.393059, 55.601101],
            [-60.447781, 55.500271],
            [-60.340279, 55.57888],
            [-60.316391, 55.573879],
            [-60.339439, 55.533611],
            [-60.305561, 55.524158],
            [-60.45306, 55.444988],
            [-60.437771, 55.399441],
            [-60.478329, 55.347488],
            [-60.331669, 55.48666],
            [-60.30611, 55.486382],
            [-60.35722, 55.4286],
            [-60.285278, 55.438042],
            [-60.268608, 55.502491],
            [-60.201672, 55.4786],
            [-60.196659, 55.422218],
            [-60.354721, 55.395],
            [-60.399441, 55.289989],
            [-60.46666, 55.29472],
            [-60.533058, 55.204441],
            [-60.612499, 55.16666],
            [-60.553341, 55.181561],
            [-60.510761, 55.2076],
            [-60.46833, 55.237499],
            [-60.430279, 55.21693],
            [-60.396111, 55.253609],
            [-60.348049, 55.250549],
            [-60.46611, 55.189709],
            [-60.52306, 55.111382],
            [-60.670559, 55.04472],
            [-60.683331, 54.994999],
            [-60.482498, 55.105549],
            [-60.446659, 55.15638],
            [-60.385559, 55.150539],
            [-60.16, 55.285549],
            [-60.13805, 55.278049],
            [-60.194439, 55.192211],
            [-60.021389, 55.266109],
            [-60.18972, 55.096939],
            [-60.220551, 55.10638],
            [-60.288342, 55.053322],
            [-60.293892, 55.01944],
            [-60.099442, 55.136662],
            [-60.020279, 55.234989],
            [-59.91695, 55.233879],
            [-59.914719, 55.26416],
            [-59.779171, 55.32972],
            [-59.755001, 55.2761],
            [-59.711941, 55.269711],
            [-59.752781, 55.220829],
            [-59.732498, 55.197208],
            [-59.863892, 55.15416],
            [-59.96278, 55.161098],
            [-59.963619, 55.110279],
            [-59.921108, 55.139721],
            [-59.800831, 55.108891],
            [-59.729439, 55.14444],
            [-59.638889, 55.13055],
            [-59.535278, 55.16777],
            [-59.50584, 55.213329],
            [-59.501671, 55.141941],
            [-59.427219, 55.139992],
            [-59.519169, 55.054161],
            [-59.733059, 54.967491],
            [-59.715549, 54.946098],
            [-59.840549, 54.879162],
            [-59.860279, 54.845539],
            [-59.821388, 54.846939],
            [-59.827499, 54.82444],
            [-59.94416, 54.749722],
            [-59.791111, 54.785549],
            [-59.808048, 54.836109],
            [-59.75695, 54.895828],
            [-59.604172, 54.954441],
            [-59.433331, 55.06889],
            [-59.402222, 55.028049],
            [-59.286949, 55.18943],
            [-59.217499, 55.217209],
            [-59.21722, 55.164989],
            [-59.161381, 55.236938],
            [-59.127491, 55.20583],
            [-59.137218, 55.15443],
            [-59.252781, 55.102489],
            [-59.384171, 54.97332],
            [-59.250839, 55.035831],
            [-59.228882, 55.081108],
            [-59.181671, 55.097488],
            [-59.14917, 55.06805],
            [-59.12611, 55.123878],
            [-59.09, 55.083328],
            [-59.064159, 55.14666],
            [-59.023609, 55.156651],
            [-58.996109, 55.118599],
            [-58.96056, 55.134991],
            [-58.95583, 55.10083],
            [-59.043331, 54.994999],
            [-58.985561, 55.020828],
            [-58.894169, 54.95277],
            [-59.013618, 54.889721],
            [-58.883888, 54.916382],
            [-58.91666, 54.850552],
            [-58.779171, 54.84972],
            [-58.75639, 54.79805],
            [-58.73138, 54.843048],
            [-58.69194, 54.846378],
            [-58.685268, 54.81554],
            [-58.51722, 54.8036],
            [-58.565552, 54.766109],
            [-58.443611, 54.77388],
            [-58.40472, 54.738331],
            [-58.391941, 54.787498],
            [-58.196659, 54.795269],
            [-58.173328, 54.745541],
            [-57.998611, 54.714161],
            [-57.855, 54.737499],
            [-57.660278, 54.62804],
            [-57.545841, 54.661659],
            [-57.45055, 54.64999],
            [-57.414719, 54.5886],
            [-57.3475, 54.579441],
            [-57.431389, 54.55027],
            [-57.37722, 54.542221],
            [-57.385281, 54.50333],
            [-57.70528, 54.46693],
            [-57.421669, 54.453609],
            [-57.52528, 54.451099],
            [-57.523331, 54.41721],
            [-57.660831, 54.376942],
            [-58.146389, 54.365269],
            [-58.261951, 54.3111],
            [-58.095829, 54.325829],
            [-58.243061, 54.287498],
            [-58.233891, 54.254169],
            [-58.446388, 54.239159],
            [-58.72472, 54.145271],
            [-58.915279, 54.138599],
            [-59.37611, 54.04694],
            [-59.575562, 54.049438],
            [-59.509731, 53.995541],
            [-59.276112, 54.021099],
            [-58.687771, 54.12471],
            [-58.37944, 54.229988],
            [-58.610279, 54.04166],
            [-59.038609, 54.026939],
            [-58.919449, 54.01083],
            [-59.008339, 53.955269],
            [-59.11528, 53.946381],
            [-59.463329, 53.830551],
            [-59.82584, 53.84277],
            [-59.88055, 53.825272],
            [-59.80278, 53.806099],
            [-59.828892, 53.788601],
            [-60.13139, 53.77721],
            [-60.01778, 53.704159],
            [-60.097778, 53.683601],
            [-60.119999, 53.611382],
            [-60.064449, 53.560268],
            [-60.133888, 53.528332],
            [-60.39056, 53.665539],
            [-60.810829, 53.771931],
            [-60.875, 53.83083],
            [-60.856949, 53.79277],
            [-60.93306, 53.7286],
            [-60.91806, 53.709431],
            [-60.805561, 53.723049],
            [-60.53722, 53.678329],
            [-60.278881, 53.558601],
            [-60.048061, 53.49527],
            [-60.038059, 53.439709],
            [-60.13805, 53.453609],
            [-60.331951, 53.388599],
            [-60.363892, 53.351101],
            [-60.40583, 53.364159],
            [-60.39584, 53.331379],
            [-60.174999, 53.343048],
            [-60.39806, 53.265549],
            [-60.26112, 53.2686],
            [-60.16444, 53.309429],
            [-60.113892, 53.270271],
            [-60.09, 53.299999],
            [-60.147781, 53.32666],
            [-60.024719, 53.355],
            [-59.90889, 53.45583],
            [-59.87167, 53.4436],
            [-59.874168, 53.484989],
            [-59.785, 53.477211],
            [-59.893059, 53.528599],
            [-59.605831, 53.52972],
            [-59.36945, 53.648609],
            [-59.302219, 53.686378],
            [-59.279442, 53.662769],
            [-59.213619, 53.69138],
            [-59.074169, 53.683048],
            [-59.06834, 53.71777],
            [-59.027779, 53.711658],
            [-59.010559, 53.746658],
            [-59.066391, 53.794998],
            [-58.876389, 53.888329],
            [-58.841671, 53.943321],
            [-58.130001, 54.096939],
            [-57.78944, 54.0686],
            [-57.851669, 54.100269],
            [-58.159161, 54.13583],
            [-58.328892, 54.089432],
            [-58.418331, 54.139721],
            [-58.38361, 54.189709],
            [-58.17028, 54.23666],
            [-57.659161, 54.199429],
            [-57.604721, 54.18388],
            [-57.609169, 54.16193],
            [-57.428341, 54.182499],
            [-57.384171, 54.150539],
            [-57.396389, 54.128601],
            [-57.35973, 54.134159],
            [-57.370831, 54.10638],
            [-57.296391, 54.00555],
            [-57.142231, 53.934719],
            [-57.215, 53.929989],
            [-57.221378, 53.889992],
            [-57.076389, 53.823051],
            [-57.13361, 53.824169],
            [-57.099998, 53.800831],
            [-57.159721, 53.75861],
            [-57.119999, 53.7411],
            [-57.549999, 53.59193],
            [-57.444721, 53.618599],
            [-57.448608, 53.59222],
            [-57.376659, 53.607498],
            [-57.316669, 53.57972],
            [-57.344158, 53.54277],
            [-57.305561, 53.534439],
            [-57.30666, 53.49638],
            [-57.372219, 53.43082],
            [-57.289169, 53.43388],
            [-57.285278, 53.477489],
            [-57.13612, 53.54916],
            [-57.159161, 53.564159],
            [-57.136391, 53.60944],
            [-57.077499, 53.628601],
            [-57.014721, 53.71138],
            [-56.704441, 53.750549],
            [-56.600281, 53.710819],
            [-56.615841, 53.762501],
            [-56.464691, 53.782269],
            [-56.421669, 53.71693],
            [-56.725559, 53.67416],
            [-56.480549, 53.62138],
            [-56.333889, 53.629162],
            [-56.263062, 53.601391],
            [-56.33556, 53.603321],
            [-56.320278, 53.58527],
            [-56.027222, 53.575272],
            [-55.978882, 53.542221],
            [-56.00861, 53.5],
            [-56.063332, 53.540829],
            [-56.26334, 53.540279],
            [-56.031391, 53.459721],
            [-56.032219, 53.432209],
            [-55.965549, 53.409161],
            [-56.038342, 53.367489],
            [-55.91222, 53.395],
            [-55.85828, 53.365269],
            [-55.885559, 53.344151],
            [-55.808048, 53.340549],
            [-55.91111, 53.303879],
            [-55.812222, 53.290829],
            [-55.85973, 53.248051],
            [-55.745831, 53.249439],
            [-55.79084, 53.198879],
            [-55.841942, 53.205269],
            [-55.76334, 53.1786],
            [-55.754169, 53.134991],
            [-55.830002, 53.09721],
            [-55.944439, 53.112499],
            [-55.87944, 53.07999],
            [-55.911942, 53.028332],
            [-56.16695, 53.02943],
            [-56.084438, 53.01527],
            [-56.133331, 52.983051],
            [-56.11195, 52.96666],
            [-56.04084, 53.005829],
            [-55.889439, 52.969151],
            [-55.910831, 52.950272],
            [-55.834171, 52.92194],
            [-55.803329, 52.839432],
            [-55.987782, 52.806099],
            [-55.923328, 52.789989],
            [-55.996391, 52.773319],
            [-56.160549, 52.819439],
            [-56.16, 52.79388],
            [-56.053329, 52.762772],
            [-55.96056, 52.679161],
            [-55.76556, 52.665821],
            [-55.738609, 52.642769],
            [-55.794449, 52.593048],
            [-55.937222, 52.63916],
            [-56.122768, 52.6511],
            [-55.974998, 52.619709],
            [-56.047779, 52.576099],
            [-55.989441, 52.56694],
            [-55.999439, 52.548611],
            [-56.089722, 52.572491],
            [-56.237221, 52.541939],
            [-56.496868, 52.594151],
            [-56.245548, 52.52916],
            [-56.10722, 52.547489],
            [-55.971378, 52.50222],
            [-55.7514, 52.496101],
            [-55.735828, 52.469151],
            [-55.766399, 52.447769],
            [-55.64555, 52.437489],
            [-55.641941, 52.36388],
            [-55.799171, 52.384441],
            [-55.782501, 52.33416],
            [-56.195271, 52.435822],
            [-55.82362, 52.32333],
            [-55.849171, 52.298882],
            [-55.70805, 52.293331],
            [-55.79195, 52.237499],
            [-55.720829, 52.251389],
            [-55.618328, 52.215271],
            [-55.776661, 52.20916],
            [-55.688889, 52.18111],
            [-55.713329, 52.139992],
            [-55.68972, 52.101391],
            [-55.792782, 52.083881],
            [-55.763889, 52.047489],
            [-55.84277, 52.003052],
            [-55.959171, 52.019161],
            [-55.894451, 51.962212],
            [-55.915279, 51.932209],
            [-56.117771, 51.860279],
            [-56.209721, 51.789719],
            [-56.43306, 51.74638],
            [-56.493328, 51.688599],
            [-56.68195, 51.64222],
            [-56.695831, 51.584991],
            [-56.807499, 51.528332],
            [-56.81361, 51.491661],
            [-56.858059, 51.46888],
            [-56.933331, 51.491661],
            [-56.942768, 51.42749],
            [-57.10424, 51.41267],
            [-57.101109, 52],
            [-57.367771, 51.999439],
            [-63.75111, 51.999722],
            [-63.824718, 52.070549],
            [-63.637779, 52.039989],
            [-63.65535, 52.124359],
            [-63.941269, 52.264],
            [-63.987499, 52.362499],
            [-64.013062, 52.387211],
            [-63.92445, 52.383881],
            [-63.930279, 52.41777],
            [-63.976391, 52.458599],
            [-64.027496, 52.433048],
            [-64.08168, 52.470539],
            [-63.971378, 52.570831],
            [-63.839722, 52.61805],
            [-63.377781, 52.650829],
            [-63.423061, 52.707211],
            [-63.595829, 52.770771],
            [-63.54195, 52.970829],
            [-63.62611, 53.042221],
            [-63.634731, 53.094151],
            [-63.693329, 53.113609],
            [-63.923611, 53.077221],
            [-63.919449, 52.97694],
            [-63.982769, 52.933601],
            [-63.959999, 52.867771],
            [-64.080841, 52.881378],
            [-64.172501, 52.85083],
            [-64.201401, 52.790829],
            [-64.156662, 52.738331],
            [-64.202217, 52.672218],
            [-64.175278, 52.60944],
            [-64.208893, 52.548882],
            [-64.08168, 52.37249],
            [-64.131378, 52.28138],
            [-64.115829, 52.151379],
            [-64.257782, 52.12804],
            [-64.293327, 52.046391],
            [-64.274437, 52.00333],
            [-64.330841, 51.965271],
            [-64.317497, 51.903599],
            [-64.357224, 51.850552],
            [-64.264183, 51.739719],
            [-64.44249, 51.66777],
            [-64.581123, 51.55777],
            [-64.604172, 51.642769],
            [-64.703056, 51.69276],
            [-64.718063, 51.747768],
            [-64.593887, 51.796101],
            [-64.587784, 51.82888],
            [-64.84584, 51.93082],
            [-64.853333, 52.021931],
            [-65.108612, 52.17083],
            [-65.453613, 52.20805],
            [-65.538597, 52.123878],
            [-65.56723, 52.046661],
            [-65.758621, 52.111382],
            [-65.799438, 52.095539],
            [-65.801666, 52.04277],
            [-65.916946, 52.050831],
            [-66.063889, 52.11832],
            [-66.068069, 52.216381],
            [-66.173889, 52.220539],
            [-66.275558, 52.311661],
            [-66.319733, 52.289162],
            [-66.303879, 52.222759],
            [-66.335564, 52.136379],
            [-66.438889, 52.200829],
            [-66.486656, 52.312489],
            [-66.458618, 52.3661],
            [-66.358047, 52.406651],
            [-66.446663, 52.530819],
            [-66.373611, 52.5425],
            [-66.43222, 52.611382],
            [-66.433884, 52.706379],
            [-66.328339, 52.736938],
            [-66.378883, 52.836109],
            [-66.26973, 52.877491],
            [-66.345551, 52.974159],
            [-66.476936, 53.034161],
            [-66.525284, 52.943321],
            [-66.648064, 52.94582],
            [-66.66806, 52.725819],
            [-66.813316, 52.66415],
            [-66.87471, 52.747768],
            [-66.891388, 52.673321],
            [-67.067497, 52.699429],
            [-67.092773, 52.72971],
            [-67.058327, 52.804989],
            [-67.078056, 52.94804],
            [-66.979446, 53.027771],
            [-66.948608, 53.109718],
            [-66.991669, 53.195271],
            [-66.910004, 53.340271],
            [-66.987503, 53.403599],
            [-67.06778, 53.384159],
            [-67.068619, 53.4286],
            [-67.128883, 53.464161],
            [-67.141953, 53.542221],
            [-67.491943, 53.603882],
            [-67.430557, 53.778332],
            [-67.588058, 53.85722],
            [-67.609161, 53.928051],
            [-67.738441, 54.000011],
            [-67.781952, 54.063881],
            [-67.637222, 54.174999],
            [-67.485001, 54.234161],
            [-67.531677, 54.248878],
            [-67.49472, 54.314442],
            [-67.59584, 54.365269],
            [-67.586121, 54.4175],
            [-67.633057, 54.479988],
            [-67.601387, 54.56638],
            [-67.492493, 54.58194],
            [-67.305557, 54.504169],
            [-67.219452, 54.607498],
            [-67.036392, 54.621658],
            [-67.213898, 54.66888],
            [-67.184998, 54.71944],
            [-67.30777, 54.783878],
            [-67.263344, 54.825272],
            [-67.362213, 54.87582],
            [-67.453056, 55.018879],
            [-67.428329, 55.07111],
            [-67.273903, 55.066669],
            [-67.297783, 55.008049],
            [-67.028061, 54.89777],
            [-66.946953, 54.801659],
            [-66.738602, 54.732769],
            [-66.698608, 54.742222],
            [-66.712219, 54.78194],
            [-66.605003, 54.790829],
            [-66.678329, 54.894161],
            [-66.732773, 54.94833],
            [-66.65976, 54.978031],
            [-66.765839, 55.105549],
            [-66.740829, 55.17749],
            [-66.688889, 55.204159],
            [-66.844162, 55.326099],
            [-66.77861, 55.338879],
            [-66.688049, 55.301102],
            [-66.681953, 55.25333],
            [-66.576401, 55.250549],
            [-66.23999, 54.99194],
            [-66.035004, 54.90971],
            [-65.957779, 54.92944],
            [-65.858337, 54.907768],
            [-65.835281, 54.831379],
            [-65.689987, 54.713329],
            [-65.443878, 54.745831],
            [-65.121933, 54.69471],
            [-64.811661, 54.749161],
            [-64.552223, 54.720539],
            [-64.404327, 54.75251],
            [-64.325562, 54.751942],
            [-63.976189, 54.626461],
            [-63.66917, 54.612209],
            [-63.435268, 54.973598],
            [-63.566109, 55.060268],
            [-63.519451, 55.183601],
            [-63.176571, 55.296539],
            [-63.095829, 55.349998],
            [-63.176392, 55.419441],
            [-63.144169, 55.526379],
            [-63.29834, 55.523319],
            [-63.448051, 55.59943],
            [-63.488331, 55.696659],
            [-63.715279, 55.859161],
            [-63.69416, 55.942211],
            [-63.445, 56.02277],
            [-63.79084, 56.039162],
            [-63.880001, 56.076939],
            [-63.847778, 56.12027],
            [-64.020279, 56.076389],
            [-64.010834, 56.159988],
            [-63.876949, 56.217209],
            [-63.97472, 56.259159],
            [-64.08667, 56.259159],
            [-64.136673, 56.3036],
            [-64.105827, 56.331379],
            [-64.114166, 56.39027],
            [-64.169724, 56.427769],
            [-63.97472, 56.412769],
            [-63.87138, 56.43943],
            [-63.942219, 56.479431],
            [-63.945, 56.549721],
            [-64.12944, 56.71027],
            [-64.083618, 56.765549],
            [-63.992229, 56.785831],
            [-64.00528, 56.850552],
            [-63.87138, 56.87804],
            [-63.917782, 56.930271],
            [-63.863331, 56.992771],
            [-63.873611, 57.06638],
            [-63.78751, 57.111938],
            [-63.7925, 57.165272],
            [-63.741112, 57.232769],
            [-63.81472, 57.27972],
            [-63.855282, 57.193878],
            [-63.87611, 57.242771],
            [-63.836941, 57.325829],
            [-63.709721, 57.37249],
            [-63.763889, 57.482491],
            [-63.765011, 57.573879],
            [-63.721378, 57.602489],
            [-63.60722, 57.663601],
            [-63.595829, 57.734161],
            [-63.653332, 57.731098],
            [-63.70055, 57.662769],
            [-63.765282, 57.732769],
            [-63.825562, 57.690269],
            [-63.891941, 57.73027],
            [-63.92667, 57.797218],
            [-64.006668, 57.812771],
            [-64.022507, 57.771381],
            [-64.057503, 57.76944],
            [-64.214447, 57.977489],
            [-64.208618, 58.033329],
            [-64.431953, 58.086109],
            [-64.398064, 58.18166],
            [-64.21167, 58.23555],
            [-64.156387, 58.348331],
            [-63.81945, 58.471371],
            [-63.886951, 58.563049],
            [-64.022507, 58.52055],
            [-64.084167, 58.55555],
            [-64.097504, 58.60722],
            [-64.051102, 58.676102],
            [-63.574169, 58.715832],
            [-63.480549, 58.7486],
            [-63.588329, 58.846378],
            [-63.766651, 58.874081],
            [-63.841942, 58.82972],
            [-63.999439, 58.81916],
            [-64.156113, 58.74527],
            [-64.257507, 58.77943],
            [-64.258621, 58.849998],
            [-64.300278, 58.882488],
            [-64.568069, 58.887211],
            [-64.718887, 58.945271],
            [-64.809433, 58.911098],
            [-64.878883, 58.92749],
            [-64.838898, 58.958599],
            [-64.864723, 58.990551],
            [-64.829453, 59.03249],
            [-64.720001, 59.069988],
            [-64.656387, 59.028599],
            [-64.415833, 58.981098],
            [-64.291382, 59.016659],
            [-64.365013, 59.07333],
            [-64.493057, 59.104439],
            [-64.493057, 59.158878],
            [-64.534729, 59.197491],
            [-64.507507, 59.25],
            [-64.53833, 59.285549],
            [-64.499161, 59.33971],
            [-64.538063, 59.3736],
            [-64.485001, 59.396389],
            [-64.5, 59.433048],
            [-64.340286, 59.49638],
            [-64.432503, 59.52943],
            [-64.700844, 59.44582],
            [-64.82695, 59.531109],
            [-64.773064, 59.698879],
            [-64.814163, 59.81889],
            [-64.744453, 59.84499],
            [-64.767776, 59.87999],
            [-64.661911, 59.88361],
            [-64.65361, 59.929989],
            [-64.847778, 59.964161],
            [-64.906387, 60.04805],
            [-64.796951, 60.039719],
            [-64.702499, 60.064991],
            [-64.734161, 60.103321],
            [-64.598892, 60.111111],
            [-64.637222, 60.178329],
            [-64.767227, 60.174999],
            [-64.851936, 60.22332],
            [-64.853882, 60.261669],
            [-64.534821, 60.302601],
          ],
        ],
        [
          [
            [-64.047783, 59.84943],
            [-64.032784, 59.80444],
            [-64.075562, 59.78833],
            [-63.959721, 59.75639],
            [-63.997219, 59.723598],
            [-64.134171, 59.695541],
            [-64.16333, 59.703609],
            [-64.144447, 59.727211],
            [-64.204453, 59.73444],
            [-64.187767, 59.76833],
            [-64.129173, 59.757221],
            [-64.152786, 59.788052],
            [-64.121933, 59.84943],
            [-64.047783, 59.84943],
          ],
        ],
        [
          [
            [-61.884449, 57.86694],
            [-61.881939, 57.812771],
            [-61.947491, 57.78722],
            [-62.10944, 57.829441],
            [-61.994999, 57.909161],
            [-61.928341, 57.9086],
            [-61.939991, 57.872219],
            [-61.884449, 57.86694],
          ],
        ],
        [
          [
            [-61.654171, 57.771099],
            [-61.69611, 57.712212],
            [-61.76889, 57.71693],
            [-61.827782, 57.778881],
            [-61.89695, 57.758331],
            [-61.778881, 57.845268],
            [-61.698608, 57.83028],
            [-61.723881, 57.783329],
            [-61.676392, 57.795551],
            [-61.654171, 57.771099],
          ],
        ],
        [
          [
            [-61.926949, 57.452492],
            [-62.021938, 57.521099],
            [-61.974998, 57.581379],
            [-61.931671, 57.585548],
            [-61.946949, 57.54916],
            [-61.896111, 57.586109],
            [-61.781391, 57.54805],
            [-61.783058, 57.508331],
            [-61.926949, 57.452492],
          ],
        ],
        [
          [
            [-61.64389, 57.52277],
            [-61.69138, 57.495541],
            [-61.64695, 57.482769],
            [-61.674171, 57.463879],
            [-61.78973, 57.46027],
            [-61.826389, 57.43082],
            [-61.69416, 57.441662],
            [-61.614719, 57.418598],
            [-61.648338, 57.393608],
            [-61.90028, 57.437489],
            [-61.782261, 57.486259],
            [-61.737221, 57.536942],
            [-61.64389, 57.52277],
          ],
        ],
        [
          [
            [-61.355282, 56.91082],
            [-61.40472, 56.879711],
            [-61.361671, 56.848049],
            [-61.439442, 56.816101],
            [-61.448051, 56.84166],
            [-61.493061, 56.838039],
            [-61.503059, 56.804161],
            [-61.58556, 56.766392],
            [-61.38612, 56.775829],
            [-61.36861, 56.685822],
            [-61.404999, 56.662491],
            [-61.390839, 56.618881],
            [-61.445549, 56.62027],
            [-61.644451, 56.73444],
            [-61.631939, 56.859718],
            [-61.533619, 56.902489],
            [-61.52277, 56.941929],
            [-61.46777, 56.958889],
            [-61.42889, 56.930271],
            [-61.34277, 56.93388],
            [-61.355282, 56.91082],
          ],
        ],
        [
          [
            [-61.558048, 56.551929],
            [-61.325279, 56.512211],
            [-61.360561, 56.471931],
            [-61.17445, 56.47665],
            [-61.224171, 56.450829],
            [-61.148891, 56.441101],
            [-61.474442, 56.46666],
            [-61.50333, 56.4361],
            [-61.63055, 56.465271],
            [-61.630001, 56.490551],
            [-61.417782, 56.48333],
            [-61.633331, 56.50639],
            [-61.571671, 56.51833],
            [-61.60223, 56.552769],
            [-61.558048, 56.551929],
          ],
        ],
        [
          [
            [-61.546669, 56.390831],
            [-61.47472, 56.406651],
            [-61.411671, 56.372219],
            [-61.464161, 56.345539],
            [-61.416111, 56.32222],
            [-61.482769, 56.30999],
            [-61.68639, 56.352779],
            [-61.796108, 56.413052],
            [-61.546669, 56.390831],
          ],
        ],
        [
          [
            [-61.089161, 56.169991],
            [-61.045841, 56.153881],
            [-61.06583, 56.123051],
            [-60.944439, 56.09499],
            [-61.009171, 56.035271],
            [-60.934719, 56.011379],
            [-61.040562, 56.005268],
            [-61.220829, 56.050831],
            [-61.22694, 56.098049],
            [-61.14917, 56.091099],
            [-61.183331, 56.106659],
            [-61.089161, 56.169991],
          ],
        ],
        [
          [
            [-60.747501, 55.93166],
            [-60.75111, 55.90971],
            [-60.68639, 55.92194],
            [-60.75639, 55.880268],
            [-60.902222, 55.87138],
            [-60.815281, 55.88583],
            [-60.874439, 55.90443],
            [-60.865009, 55.95277],
            [-60.747501, 55.93166],
          ],
        ],
        [
          [
            [-57.94083, 54.91193],
            [-57.988609, 54.86721],
            [-57.983891, 54.802219],
            [-57.93861, 54.82777],
            [-57.884998, 54.802219],
            [-57.864719, 54.832211],
            [-57.841671, 54.812489],
            [-57.881939, 54.79055],
            [-58.139172, 54.757221],
            [-58.22472, 54.83527],
            [-58.215839, 54.87804],
            [-58.17028, 54.85416],
            [-57.94083, 54.91193],
          ],
        ],
        [
          [
            [-55.876949, 50.86277],
            [-55.906658, 50.89666],
            [-56.097221, 50.71693],
            [-56.091671, 50.759991],
            [-56.116112, 50.746101],
            [-56.133331, 50.773048],
            [-56.103889, 50.898331],
            [-56.15527, 50.885551],
            [-56.157219, 50.69083],
            [-56.09972, 50.679722],
            [-56.108059, 50.651932],
            [-56.206108, 50.595539],
            [-56.240841, 50.524158],
            [-56.321671, 50.52388],
            [-56.25861, 50.502781],
            [-56.40472, 50.374439],
            [-56.501671, 50.382771],
            [-56.423061, 50.352779],
            [-56.462502, 50.272221],
            [-56.566391, 50.157768],
            [-56.670559, 50.129162],
            [-56.63583, 50.100552],
            [-56.743061, 50.02277],
            [-56.77861, 49.93388],
            [-56.71833, 49.949162],
            [-56.759998, 49.83638],
            [-56.906391, 49.746941],
            [-56.781391, 49.727211],
            [-56.85751, 49.545551],
            [-56.735561, 49.666939],
            [-56.477779, 49.89222],
            [-56.4175, 49.84222],
            [-56.43195, 49.890549],
            [-56.36528, 49.896938],
            [-56.38805, 49.94305],
            [-56.33028, 50.02499],
            [-56.153881, 50.150539],
            [-56.116661, 50.153049],
            [-56.062771, 50.071659],
            [-56.188332, 49.923882],
            [-56.005001, 50.03138],
            [-56.028339, 49.970539],
            [-55.977779, 49.991379],
            [-55.962219, 50.035271],
            [-55.90527, 50.033878],
            [-55.746109, 49.92305],
            [-55.671391, 49.973049],
            [-55.594158, 49.96249],
            [-55.491699, 50.007309],
            [-55.459721, 49.9575],
            [-55.492229, 49.91721],
            [-55.891109, 49.75972],
            [-56.002781, 49.748051],
            [-56.007229, 49.716099],
            [-56.160278, 49.622761],
            [-56.17223, 49.579159],
            [-56.044449, 49.669991],
            [-55.89389, 49.714161],
            [-55.95834, 49.672489],
            [-55.833328, 49.686649],
            [-55.938332, 49.603611],
            [-55.88028, 49.584991],
            [-55.937222, 49.52972],
            [-56.080002, 49.486938],
            [-56.129169, 49.425549],
            [-55.827782, 49.524158],
            [-55.720829, 49.475819],
            [-55.779999, 49.44249],
            [-55.674171, 49.466099],
            [-55.698879, 49.42749],
            [-55.66444, 49.381939],
            [-55.52306, 49.48666],
            [-55.568611, 49.41888],
            [-55.566669, 49.36277],
            [-55.48917, 49.470829],
            [-55.36945, 49.50333],
            [-55.34972, 49.487209],
            [-55.431671, 49.422489],
            [-55.316391, 49.45166],
            [-55.44416, 49.355],
            [-55.391392, 49.374161],
            [-55.373051, 49.331108],
            [-55.32806, 49.406101],
            [-55.320839, 49.380821],
            [-55.29306, 49.4011],
            [-55.301941, 49.36055],
            [-55.338612, 49.35527],
            [-55.30444, 49.311661],
            [-55.300549, 49.348598],
            [-55.233608, 49.39444],
            [-55.311111, 49.529991],
            [-55.228611, 49.53833],
            [-55.19611, 49.5],
            [-55.193611, 49.53944],
            [-55.137218, 49.540829],
            [-55.124722, 49.465271],
            [-55.23222, 49.347771],
            [-55.221111, 49.26194],
            [-55.365841, 49.165272],
            [-55.31945, 49.149158],
            [-55.339722, 49.122219],
            [-55.307781, 49.099159],
            [-55.383331, 49.040829],
            [-55.182781, 49.12027],
            [-55.272221, 49.099998],
            [-55.279999, 49.160271],
            [-55.25333, 49.18388],
            [-55.288059, 49.18721],
            [-55.058609, 49.284439],
            [-55.07806, 49.356941],
            [-55.03751, 49.321941],
            [-55.007778, 49.33971],
            [-54.990002, 49.286942],
            [-55.041672, 49.224159],
            [-54.982769, 49.257221],
            [-54.959721, 49.299438],
            [-54.909439, 49.275829],
            [-54.87167, 49.293331],
            [-54.858891, 49.332211],
            [-54.890282, 49.35416],
            [-54.848049, 49.410271],
            [-54.824451, 49.269161],
            [-54.777222, 49.29916],
            [-54.76722, 49.352489],
            [-54.739719, 49.320549],
            [-54.541111, 49.526661],
            [-54.44722, 49.543049],
            [-54.530281, 49.444988],
            [-54.43195, 49.470829],
            [-54.488049, 49.371101],
            [-54.452221, 49.333328],
            [-54.48695, 49.25972],
            [-54.400829, 49.325272],
            [-54.407501, 49.37471],
            [-54.358059, 49.426941],
            [-54.24889, 49.397491],
            [-54.219719, 49.41721],
            [-54.228882, 49.361111],
            [-54.206108, 49.402771],
            [-54.166111, 49.37804],
            [-54.139999, 49.448601],
            [-54.041672, 49.48082],
            [-53.988049, 49.458599],
            [-54.033329, 49.42749],
            [-53.92778, 49.451099],
            [-53.455559, 49.251942],
            [-53.560001, 49.124989],
            [-53.619999, 49.117489],
            [-53.581951, 49.092491],
            [-53.593891, 49.035549],
            [-53.83527, 49.03249],
            [-53.725269, 49.00972],
            [-53.888889, 48.982769],
            [-53.813061, 48.92527],
            [-53.97472, 48.89138],
            [-53.883888, 48.886662],
            [-53.980831, 48.82888],
            [-54.178051, 48.800831],
            [-54.19944, 48.768051],
            [-54.172779, 48.761669],
            [-53.990841, 48.810822],
            [-53.998341, 48.76194],
            [-53.957779, 48.772221],
            [-53.98, 48.785831],
            [-53.93417, 48.83028],
            [-53.82056, 48.829441],
            [-53.808609, 48.805271],
            [-53.91333, 48.79805],
            [-53.900551, 48.759991],
            [-53.843609, 48.772491],
            [-53.89056, 48.733601],
            [-54.022499, 48.740829],
            [-53.937771, 48.722488],
            [-53.94833, 48.666382],
            [-53.89389, 48.646389],
            [-53.932499, 48.62471],
            [-53.790001, 48.679989],
            [-53.92445, 48.671379],
            [-53.831951, 48.749439],
            [-53.85194, 48.702221],
            [-53.7425, 48.711658],
            [-53.70306, 48.685261],
            [-53.722221, 48.654991],
            [-53.59972, 48.68499],
            [-53.658611, 48.641941],
            [-53.928341, 48.575829],
            [-53.952499, 48.53944],
            [-53.715279, 48.559719],
            [-53.78944, 48.543049],
            [-53.750839, 48.507771],
            [-53.902222, 48.47971],
            [-54.01112, 48.421661],
            [-54.09444, 48.425831],
            [-54.163891, 48.383049],
            [-54.127491, 48.353321],
            [-54.075562, 48.401932],
            [-53.992229, 48.402489],
            [-53.841671, 48.470829],
            [-53.81139, 48.465],
            [-53.884449, 48.383049],
            [-53.834171, 48.371658],
            [-53.849442, 48.392769],
            [-53.768608, 48.475269],
            [-53.647221, 48.541111],
            [-53.620831, 48.534439],
            [-53.659721, 48.51305],
            [-53.618061, 48.498878],
            [-53.725269, 48.426102],
            [-53.639721, 48.43943],
            [-53.586391, 48.525269],
            [-53.587219, 48.476101],
            [-53.547501, 48.48666],
            [-53.608891, 48.40749],
            [-53.473331, 48.5075],
            [-53.42445, 48.625549],
            [-53.338051, 48.612499],
            [-53.303329, 48.58194],
            [-53.318611, 48.549438],
            [-53.240002, 48.523319],
            [-53.153881, 48.628601],
            [-53.098881, 48.64222],
            [-53.072781, 48.700272],
            [-52.976391, 48.599159],
            [-53.05389, 48.44276],
            [-53.19416, 48.348598],
            [-53.35223, 48.385269],
            [-53.376389, 48.33028],
            [-53.37888, 48.278049],
            [-53.44833, 48.27943],
            [-53.66222, 48.163319],
            [-53.940552, 48.23082],
            [-53.944019, 48.16346],
            [-53.912498, 48.08416],
            [-53.66444, 48.064159],
            [-53.76334, 48.026379],
            [-53.91917, 48.022491],
            [-53.779171, 47.996658],
            [-53.605831, 48.046391],
            [-53.692768, 47.899441],
            [-53.788059, 47.900539],
            [-53.724442, 47.883331],
            [-53.721661, 47.84943],
            [-53.793331, 47.7686],
            [-53.874439, 47.845539],
            [-53.930279, 47.849159],
            [-53.85445, 47.794998],
            [-53.82917, 47.71944],
            [-53.85751, 47.710819],
            [-53.760281, 47.609989],
            [-53.695, 47.685822],
            [-53.665001, 47.67416],
            [-53.712502, 47.598049],
            [-53.639172, 47.573051],
            [-53.66666, 47.52166],
            [-53.617771, 47.556381],
            [-53.54528, 47.534439],
            [-53.559719, 47.576939],
            [-53.535561, 47.589432],
            [-53.561939, 47.61916],
            [-53.51445, 47.614441],
            [-53.461109, 47.80666],
            [-53.398609, 47.872761],
            [-53.355831, 47.865829],
            [-53.368061, 47.893879],
            [-53.29084, 47.999439],
            [-53.153881, 48.066101],
            [-53.053329, 48.049721],
            [-52.91972, 48.169159],
            [-52.832779, 48.096939],
            [-52.9175, 48.084721],
            [-52.953892, 48.007221],
            [-53.058048, 47.922489],
            [-53.053329, 47.867771],
            [-53.202221, 47.733601],
            [-53.162769, 47.71471],
            [-53.178341, 47.651379],
            [-53.26722, 47.606098],
            [-53.20583, 47.609989],
            [-53.248341, 47.57555],
            [-53.16972, 47.597488],
            [-53.261669, 47.546391],
            [-53.185829, 47.55444],
            [-53.20639, 47.46999],
            [-53.160549, 47.50111],
            [-53.152222, 47.471931],
            [-53.199718, 47.43832],
            [-53.14584, 47.44804],
            [-53.121941, 47.413319],
            [-52.89695, 47.558601],
            [-52.779442, 47.803051],
            [-52.70055, 47.749439],
            [-52.714451, 47.6661],
            [-52.65778, 47.65749],
            [-52.646111, 47.599159],
            [-52.68861, 47.551102],
            [-52.620281, 47.500271],
            [-52.688049, 47.461109],
            [-52.650551, 47.428329],
            [-52.743061, 47.309719],
            [-52.796951, 47.300541],
            [-52.759171, 47.293331],
            [-52.810001, 47.267208],
            [-52.865551, 47.098598],
            [-52.934441, 47.086941],
            [-52.848339, 47.067768],
            [-52.873611, 47.048328],
            [-52.852779, 47.022491],
            [-52.895279, 47.001389],
            [-52.884171, 46.941662],
            [-52.938332, 46.789162],
            [-53.023891, 46.73888],
            [-53.090839, 46.64333],
            [-53.207218, 46.630268],
            [-53.266941, 46.733879],
            [-53.387779, 46.681938],
            [-53.36195, 46.737499],
            [-53.561668, 46.61277],
            [-53.617771, 46.644161],
            [-53.644169, 46.70916],
            [-53.552502, 46.864441],
            [-53.481941, 46.897221],
            [-53.5425, 46.884159],
            [-53.64806, 46.796661],
            [-53.5975, 46.90638],
            [-53.512218, 46.955551],
            [-53.563061, 46.937489],
            [-53.566952, 46.977211],
            [-53.600559, 46.94138],
            [-53.60194, 46.991661],
            [-53.64167, 46.983879],
            [-53.577782, 47.08527],
            [-53.499168, 47.123878],
            [-53.523609, 47.16777],
            [-53.57333, 47.15443],
            [-53.559441, 47.20277],
            [-53.600559, 47.128601],
            [-53.639999, 47.101662],
            [-53.630829, 47.15443],
            [-53.654171, 47.14027],
            [-53.703609, 47.053051],
            [-53.765011, 47.034439],
            [-53.95084, 46.853882],
            [-54.053329, 46.794998],
            [-54.189991, 46.823608],
            [-54.178341, 46.94276],
            [-54.012218, 47.2286],
            [-53.931671, 47.22332],
            [-53.993889, 47.26527],
            [-53.997501, 47.310268],
            [-53.92194, 47.304161],
            [-53.875561, 47.35416],
            [-53.918331, 47.352779],
            [-53.913891, 47.374161],
            [-53.80389, 47.426941],
            [-53.906391, 47.424709],
            [-53.924171, 47.451389],
            [-53.885559, 47.576939],
            [-53.89695, 47.609989],
            [-53.930279, 47.606659],
            [-54.00639, 47.81916],
            [-54.063889, 47.797218],
            [-54.081669, 47.86916],
            [-54.11417, 47.824169],
            [-54.26334, 47.9011],
            [-54.19722, 47.86277],
            [-54.21278, 47.777771],
            [-54.285561, 47.692211],
            [-54.33778, 47.698879],
            [-54.320278, 47.651661],
            [-54.348511, 47.610649],
            [-54.460281, 47.494709],
            [-54.403332, 47.45055],
            [-54.448879, 47.42083],
            [-54.453609, 47.45583],
            [-54.51722, 47.369438],
            [-54.613331, 47.36277],
            [-54.418331, 47.603611],
            [-54.62138, 47.389992],
            [-54.671391, 47.37249],
            [-54.685551, 47.396389],
            [-54.71999, 47.352219],
            [-54.818611, 47.363609],
            [-54.78751, 47.41888],
            [-54.836941, 47.372761],
            [-54.83112, 47.421101],
            [-55.043892, 47.220829],
            [-55.012218, 47.172771],
            [-55.067501, 47.147221],
            [-55.084171, 47.201931],
            [-55.134171, 47.166939],
            [-55.08556, 47.16082],
            [-55.06945, 47.082211],
            [-55.104721, 47.078049],
            [-55.15472, 47.008049],
            [-55.16972, 47.065269],
            [-55.199169, 47.070549],
            [-55.189991, 46.996101],
            [-55.230282, 46.928329],
            [-55.365841, 46.904709],
            [-55.35445, 46.879711],
            [-55.384171, 46.865829],
            [-55.463619, 46.877491],
            [-55.46806, 46.928329],
            [-55.512218, 46.912491],
            [-55.52528, 46.939709],
            [-55.548611, 46.894161],
            [-55.57362, 46.910549],
            [-55.626949, 46.868881],
            [-55.740841, 46.849998],
            [-55.931389, 46.892769],
            [-55.983059, 46.952492],
            [-55.865551, 47.072769],
            [-55.487782, 47.137211],
            [-55.29834, 47.267208],
            [-55.266659, 47.39666],
            [-55.196388, 47.449429],
            [-54.951389, 47.505001],
            [-54.845829, 47.556938],
            [-54.841381, 47.583599],
            [-54.876949, 47.570271],
            [-54.850281, 47.60722],
            [-54.69722, 47.664711],
            [-54.769169, 47.678051],
            [-54.94944, 47.59972],
            [-55.079731, 47.589989],
            [-54.936111, 47.781658],
            [-55.11916, 47.576939],
            [-55.153881, 47.604439],
            [-55.130001, 47.66193],
            [-55.176392, 47.635269],
            [-55.31889, 47.668049],
            [-55.363892, 47.649158],
            [-55.35751, 47.726101],
            [-55.392502, 47.691101],
            [-55.427502, 47.711658],
            [-55.422501, 47.672771],
            [-55.467499, 47.61916],
            [-55.40028, 47.615551],
            [-55.432499, 47.581379],
            [-55.389721, 47.58638],
            [-55.406658, 47.49305],
            [-55.521111, 47.459721],
            [-55.58778, 47.398609],
            [-55.58778, 47.453049],
            [-55.664162, 47.436939],
            [-55.588612, 47.510281],
            [-55.62833, 47.541382],
            [-55.654171, 47.49527],
            [-55.70528, 47.502491],
            [-55.70528, 47.475269],
            [-55.763618, 47.454708],
            [-55.763062, 47.53833],
            [-55.80167, 47.45916],
            [-55.85778, 47.473598],
            [-55.92556, 47.439159],
            [-55.745831, 47.58527],
            [-56.104172, 47.463612],
            [-56.16917, 47.492489],
            [-55.805561, 47.605831],
            [-55.62833, 47.674709],
            [-55.83889, 47.61721],
            [-55.9175, 47.65749],
            [-55.830559, 47.732769],
            [-55.79945, 47.79916],
            [-55.821949, 47.834431],
            [-55.742229, 47.923321],
            [-55.773609, 47.95694],
            [-55.85445, 47.79472],
            [-55.987499, 47.7575],
            [-56.054722, 47.695541],
            [-56.10667, 47.693321],
            [-56.111111, 47.724159],
            [-56.053612, 47.77388],
            [-56.100559, 47.750271],
            [-56.15694, 47.832211],
            [-56.125, 47.757221],
            [-56.168331, 47.715832],
            [-56.141941, 47.684719],
            [-56.191109, 47.676941],
            [-56.161381, 47.634159],
            [-56.216389, 47.65527],
            [-56.232498, 47.625549],
            [-56.29945, 47.62249],
            [-56.309441, 47.7486],
            [-56.334171, 47.695271],
            [-56.310001, 47.639431],
            [-56.34528, 47.60833],
            [-56.682499, 47.623051],
            [-56.639999, 47.587212],
            [-56.67889, 47.57444],
            [-56.756672, 47.629429],
            [-56.751579, 47.589741],
            [-56.70639, 47.56554],
            [-56.779442, 47.526661],
            [-56.849171, 47.522491],
            [-56.85194, 47.54805],
            [-56.9925, 47.583881],
            [-57.12611, 47.563881],
            [-57.168331, 47.583599],
            [-57.141941, 47.598598],
            [-57.171391, 47.632488],
            [-57.184441, 47.598331],
            [-57.312771, 47.58083],
            [-57.334999, 47.639431],
            [-57.488892, 47.6236],
            [-57.53722, 47.64777],
            [-57.64389, 47.597488],
            [-57.738892, 47.653599],
            [-57.77055, 47.614159],
            [-57.916389, 47.648609],
            [-57.884171, 47.696659],
            [-57.952499, 47.661659],
            [-58.036388, 47.696098],
            [-58.074169, 47.666939],
            [-58.07917, 47.695271],
            [-58.353889, 47.643608],
            [-58.272221, 47.751659],
            [-58.338329, 47.745541],
            [-58.430561, 47.633049],
            [-58.535278, 47.666382],
            [-58.535, 47.631939],
            [-58.60611, 47.61166],
            [-58.642231, 47.62804],
            [-58.690552, 47.598881],
            [-59.118889, 47.55471],
            [-59.30444, 47.609989],
            [-59.28083, 47.751659],
            [-59.322231, 47.761108],
            [-59.400829, 47.906651],
            [-59.316391, 47.934158],
            [-59.218609, 48.02916],
            [-58.978882, 48.123878],
            [-58.58778, 48.43055],
            [-58.492771, 48.450272],
            [-58.591942, 48.39888],
            [-58.483608, 48.427769],
            [-58.418331, 48.48666],
            [-58.26556, 48.51749],
            [-58.519718, 48.500549],
            [-58.56945, 48.538601],
            [-58.682499, 48.554989],
            [-59.246948, 48.46666],
            [-59.228882, 48.52916],
            [-58.774719, 48.778881],
            [-58.958611, 48.61388],
            [-58.89584, 48.588879],
            [-58.89695, 48.551929],
            [-58.848339, 48.588879],
            [-58.848049, 48.634991],
            [-58.81778, 48.569988],
            [-58.709438, 48.570831],
            [-58.658051, 48.74305],
            [-58.543892, 48.860821],
            [-58.500839, 49.003609],
            [-58.35194, 49.150269],
            [-58.360001, 49.056938],
            [-58.17194, 49.061939],
            [-58.06028, 48.976101],
            [-57.961391, 48.956661],
            [-57.892231, 48.981659],
            [-58.00922, 48.980419],
            [-58.108059, 49.037769],
            [-58.144451, 49.121929],
            [-57.92556, 49.123051],
            [-57.849442, 49.181938],
            [-57.939442, 49.145],
            [-57.96999, 49.16415],
            [-57.924171, 49.18832],
            [-58.09111, 49.150829],
            [-58.10722, 49.183048],
            [-57.99472, 49.180271],
            [-57.928051, 49.240269],
            [-58.03944, 49.21249],
            [-58.20084, 49.239719],
            [-58.24194, 49.29166],
            [-58.223331, 49.39027],
            [-58.152222, 49.46888],
            [-57.998341, 49.559158],
            [-57.92028, 49.527771],
            [-57.918331, 49.465832],
            [-57.88055, 49.428051],
            [-57.906109, 49.477489],
            [-57.86306, 49.506939],
            [-57.746948, 49.453609],
            [-57.696388, 49.463612],
            [-57.788891, 49.500832],
            [-57.824718, 49.559719],
            [-57.877491, 49.528332],
            [-57.944721, 49.607498],
            [-57.94833, 49.67416],
            [-57.601109, 50.174999],
            [-57.526661, 50.364719],
            [-57.372501, 50.590832],
            [-57.150829, 50.6161],
            [-57.261951, 50.613331],
            [-57.277222, 50.630268],
            [-57.240841, 50.641109],
            [-57.39806, 50.69249],
            [-57.351109, 50.723598],
            [-57.230282, 50.72971],
            [-57.089439, 50.780548],
            [-56.977219, 50.8736],
            [-56.972771, 50.909161],
            [-56.927502, 50.915821],
            [-56.989719, 50.929161],
            [-56.966942, 50.968601],
            [-57.089161, 51.019989],
            [-57.004719, 51.016109],
            [-56.976662, 50.98555],
            [-56.902222, 51.013329],
            [-56.978882, 51.05027],
            [-56.880001, 51.06554],
            [-56.860828, 51.113609],
            [-56.784451, 51.137772],
            [-56.80666, 51.18943],
            [-56.746391, 51.204441],
            [-56.793892, 51.23999],
            [-56.688889, 51.336941],
            [-56.110561, 51.52388],
            [-55.890282, 51.629429],
            [-55.822781, 51.618599],
            [-55.884998, 51.562489],
            [-55.841942, 51.545269],
            [-55.877781, 51.492222],
            [-55.620281, 51.472759],
            [-55.73917, 51.55666],
            [-55.6175, 51.610279],
            [-55.653332, 51.590549],
            [-55.625, 51.56554],
            [-55.515839, 51.602219],
            [-55.404442, 51.571941],
            [-55.41917, 51.548328],
            [-55.467499, 51.56361],
            [-55.432499, 51.53722],
            [-55.45084, 51.499161],
            [-55.533058, 51.500832],
            [-55.452499, 51.460819],
            [-55.50806, 51.363331],
            [-55.562222, 51.38805],
            [-55.55917, 51.34277],
            [-55.609169, 51.344151],
            [-55.597778, 51.3036],
            [-55.759449, 51.346378],
            [-56.08556, 51.36832],
            [-56.09639, 51.318329],
            [-56.025002, 51.308601],
            [-56.010281, 51.270271],
            [-56.075279, 51.227211],
            [-56.013062, 51.213329],
            [-55.965, 51.153049],
            [-55.941669, 51.15527],
            [-55.959438, 51.197491],
            [-55.83778, 51.230549],
            [-55.709999, 51.173611],
            [-55.746948, 51.158039],
            [-55.719158, 51.123051],
            [-55.735561, 51.074169],
            [-55.810829, 51.056381],
            [-55.77639, 51.029991],
            [-55.836109, 50.968319],
            [-55.819172, 50.92527],
            [-55.859169, 50.94249],
            [-55.87389, 50.908329],
            [-55.844719, 50.90416],
            [-55.876949, 50.86277],
          ],
        ],
      ],
    },
    id: 'CA_NL',
    properties: {
      name: 'Newfoundland and Labrador',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-131.077103, 52.150089],
            [-130.948105, 52.093609],
            [-130.992493, 52.060822],
            [-131.070007, 52.07222],
            [-130.985504, 52.012211],
            [-131.041702, 52.022491],
            [-131.007004, 51.97887],
            [-131.0392, 51.96693],
            [-131.021698, 51.94083],
            [-131.110306, 51.999161],
            [-131.085007, 52.023319],
            [-131.120499, 52.05555],
            [-131.122696, 52.123779],
            [-131.077103, 52.150089],
          ],
        ],
        [
          [
            [-130.164993, 55.01416],
            [-130.149704, 54.947491],
            [-130.270294, 54.83083],
            [-130.3992, 54.764721],
            [-130.459396, 54.817501],
            [-130.213303, 55.012501],
            [-130.164993, 55.01416],
          ],
        ],
        [
          [
            [-130.458603, 54.80027],
            [-130.435806, 54.763329],
            [-130.463303, 54.708599],
            [-130.538605, 54.70388],
            [-130.610504, 54.753052],
            [-130.458603, 54.80027],
          ],
        ],
        [
          [
            [-130.746902, 54.615269],
            [-130.760803, 54.55471],
            [-130.964996, 54.45694],
            [-130.925598, 54.619438],
            [-130.746902, 54.615269],
          ],
        ],
        [
          [
            [-132.8806, 53.600269],
            [-133.007797, 53.67638],
            [-132.925293, 53.690269],
            [-132.979401, 53.710819],
            [-132.972794, 53.763889],
            [-133.0336, 53.752781],
            [-133.109207, 53.786942],
            [-133.067001, 53.820831],
            [-133.134705, 53.87027],
            [-133.139999, 53.908039],
            [-133.090805, 53.954159],
            [-133.102798, 53.994999],
            [-133.041107, 54.031658],
            [-133.081894, 54.102489],
            [-133.029205, 54.158878],
            [-133.0672, 54.173882],
            [-132.827499, 54.12249],
            [-132.658401, 54.14222],
            [-132.575302, 54.115551],
            [-132.566696, 54.02916],
            [-132.6642, 53.98333],
            [-132.681702, 53.95277],
            [-132.658905, 53.93943],
            [-132.409698, 54.098598],
            [-132.290604, 54.11055],
            [-132.228302, 54.06583],
            [-132.232803, 54.025269],
            [-132.162506, 54.005268],
            [-132.111099, 53.87833],
            [-132.225006, 53.78027],
            [-132.6633, 53.67944],
            [-132.595001, 53.672771],
            [-132.599197, 53.626659],
            [-132.541702, 53.66444],
            [-132.491898, 53.64222],
            [-132.350296, 53.668331],
            [-132.463593, 53.61277],
            [-132.468307, 53.570831],
            [-132.418106, 53.606098],
            [-132.279205, 53.623322],
            [-132.328293, 53.64333],
            [-132.315308, 53.666939],
            [-132.200302, 53.65221],
            [-132.155594, 53.699711],
            [-132.190002, 53.783051],
            [-132.079407, 53.889431],
            [-132.133606, 54.010551],
            [-132.177505, 54.04055],
            [-131.9711, 54.025269],
            [-131.727798, 54.106098],
            [-131.655899, 54.15971],
            [-131.705002, 53.96693],
            [-131.868896, 53.793331],
            [-131.865494, 53.721661],
            [-131.933304, 53.615269],
            [-131.911102, 53.351662],
            [-131.982193, 53.251659],
            [-132.210495, 53.230549],
            [-132.272797, 53.21027],
            [-132.237793, 53.188881],
            [-132.124405, 53.194149],
            [-132.186996, 53.160549],
            [-132.316406, 53.189709],
            [-132.3228, 53.159431],
            [-132.446701, 53.143608],
            [-132.459198, 53.18388],
            [-132.499405, 53.159161],
            [-132.536697, 53.178879],
            [-132.520798, 53.193321],
            [-132.577805, 53.20055],
            [-132.549698, 53.23138],
            [-132.703094, 53.257771],
            [-132.609207, 53.314991],
            [-132.537003, 53.32111],
            [-132.752808, 53.316101],
            [-132.697495, 53.376942],
            [-132.514206, 53.358601],
            [-132.410599, 53.29472],
            [-132.400299, 53.33083],
            [-132.520798, 53.412209],
            [-132.660004, 53.434719],
            [-132.660599, 53.489429],
            [-132.688293, 53.44833],
            [-132.752808, 53.4575],
            [-132.719101, 53.490269],
            [-132.743607, 53.515549],
            [-132.867493, 53.463612],
            [-132.823593, 53.516941],
            [-132.923599, 53.519161],
            [-132.889999, 53.55777],
            [-132.968307, 53.551659],
            [-132.994095, 53.587769],
            [-132.8806, 53.600269],
          ],
        ],
        [
          [
            [-130.336105, 54.067501],
            [-130.228897, 53.96471],
            [-130.344498, 53.83527],
            [-130.406403, 53.83416],
            [-130.4272, 53.871658],
            [-130.483597, 53.84388],
            [-130.497498, 53.8736],
            [-130.449997, 53.888599],
            [-130.407806, 53.963329],
            [-130.327499, 53.96693],
            [-130.349197, 53.997219],
            [-130.443893, 53.9786],
            [-130.482498, 53.893608],
            [-130.667007, 53.963879],
            [-130.698105, 53.91415],
            [-130.617996, 53.85722],
            [-130.642502, 53.832771],
            [-130.710297, 53.85638],
            [-130.720306, 53.934719],
            [-130.661697, 53.990829],
            [-130.411102, 54.10083],
            [-130.336105, 54.067501],
          ],
        ],
        [
          [
            [-129.479996, 53.249161],
            [-129.491394, 53.209431],
            [-129.572495, 53.20916],
            [-129.658905, 53.29694],
            [-129.635605, 53.311939],
            [-129.660294, 53.342491],
            [-129.711105, 53.33416],
            [-129.807205, 53.384159],
            [-129.815796, 53.466381],
            [-129.895004, 53.477489],
            [-129.8703, 53.545551],
            [-129.922501, 53.563049],
            [-129.883896, 53.57972],
            [-129.936401, 53.587212],
            [-129.911697, 53.611382],
            [-130.016098, 53.572769],
            [-130.056396, 53.603882],
            [-129.965393, 53.641781],
            [-129.874405, 53.638889],
            [-129.9496, 53.659248],
            [-130.083893, 53.641109],
            [-130.099197, 53.702221],
            [-130.209702, 53.72887],
            [-130.2108, 53.810822],
            [-130.286407, 53.83749],
            [-130.266403, 53.881378],
            [-130.186401, 53.9161],
            [-130.030899, 53.845268],
            [-129.523605, 53.496658],
            [-129.635605, 53.480549],
            [-129.491898, 53.470268],
            [-129.425293, 53.43082],
            [-129.453598, 53.296661],
            [-129.516403, 53.302219],
            [-129.479996, 53.249161],
          ],
        ],
        [
          [
            [-130.271896, 53.797779],
            [-130.2258, 53.77499],
            [-130.225006, 53.720268],
            [-130.1194, 53.686649],
            [-130.091095, 53.569439],
            [-130.329193, 53.61805],
            [-130.403397, 53.682499],
            [-130.287506, 53.748878],
            [-130.301697, 53.79388],
            [-130.271896, 53.797779],
          ],
        ],
        [
          [
            [-128.823593, 53.70055],
            [-128.872192, 53.661098],
            [-128.871597, 53.57222],
            [-128.986404, 53.58083],
            [-129.085495, 53.505001],
            [-129.086395, 53.446098],
            [-129.163101, 53.38221],
            [-129.153107, 53.638599],
            [-129.008102, 53.693321],
            [-128.823593, 53.70055],
          ],
        ],
        [
          [
            [-130.391098, 53.61694],
            [-129.920593, 53.42416],
            [-129.729202, 53.215271],
            [-129.742493, 53.178329],
            [-129.931702, 53.158039],
            [-130.014496, 53.246941],
            [-130.078903, 53.270271],
            [-130.094498, 53.325829],
            [-130.140594, 53.321941],
            [-130.203293, 53.37888],
            [-130.305801, 53.384159],
            [-130.412003, 53.49332],
            [-130.333893, 53.514999],
            [-130.458603, 53.514721],
            [-130.527206, 53.552219],
            [-130.508408, 53.631939],
            [-130.391098, 53.61694],
          ],
        ],
        [
          [
            [-128.985504, 53.523048],
            [-128.901093, 53.386662],
            [-128.918106, 53.331379],
            [-129.122192, 53.31805],
            [-129.134506, 53.383881],
            [-129.054993, 53.450829],
            [-129.037796, 53.520271],
            [-128.985504, 53.523048],
          ],
        ],
        [
          [
            [-129.168594, 53.296101],
            [-129.131897, 53.113609],
            [-129.274994, 53.084431],
            [-129.332504, 53.137501],
            [-129.338303, 53.18499],
            [-129.245499, 53.282211],
            [-129.273895, 53.328049],
            [-129.226898, 53.326099],
            [-129.168594, 53.296101],
          ],
        ],
        [
          [
            [-128.593597, 52.659161],
            [-128.597198, 52.609161],
            [-128.751694, 52.60083],
            [-128.727798, 52.662491],
            [-128.748001, 52.754169],
            [-128.644699, 52.90416],
            [-128.653397, 52.96666],
            [-128.679993, 52.957771],
            [-128.681107, 52.898609],
            [-128.742798, 52.91444],
            [-128.724197, 52.876659],
            [-128.780594, 52.73999],
            [-128.766098, 52.661659],
            [-128.801102, 52.693321],
            [-128.892502, 52.64888],
            [-129.080307, 52.772491],
            [-129.119995, 52.86916],
            [-129.016998, 52.84444],
            [-129.068298, 52.889992],
            [-128.983902, 52.934719],
            [-128.941406, 53],
            [-128.836395, 53.035549],
            [-129.011703, 53.139721],
            [-128.993896, 53.09721],
            [-128.862198, 53.034161],
            [-128.957199, 53.008331],
            [-129.069199, 52.903881],
            [-129.165802, 52.92305],
            [-129.188004, 53.024158],
            [-129.161697, 53.07111],
            [-129.115005, 53.064709],
            [-129.073303, 53.109161],
            [-129.058899, 53.23138],
            [-129.086105, 53.29361],
            [-128.891403, 53.287769],
            [-128.705307, 53.170551],
            [-128.605606, 53.145271],
            [-128.531693, 53.021099],
            [-128.517807, 52.92749],
            [-128.593597, 52.659161],
          ],
        ],
        [
          [
            [-131.074402, 52.219379],
            [-131.014999, 52.225552],
            [-131.024704, 52.178051],
            [-131.113907, 52.16777],
            [-131.136993, 52.209721],
            [-131.143906, 52.140831],
            [-131.189697, 52.121929],
            [-131.212204, 52.191929],
            [-131.277206, 52.240829],
            [-131.232193, 52.16193],
            [-131.236893, 52.126942],
            [-131.270798, 52.120831],
            [-131.349197, 52.233051],
            [-131.360306, 52.189159],
            [-131.505905, 52.321659],
            [-131.551102, 52.323879],
            [-131.532501, 52.351391],
            [-131.571106, 52.350269],
            [-131.580597, 52.390549],
            [-131.510605, 52.408878],
            [-131.613007, 52.422771],
            [-131.661697, 52.478321],
            [-131.775299, 52.511669],
            [-131.892807, 52.582771],
            [-131.933594, 52.653881],
            [-132.081894, 52.727489],
            [-132.040298, 52.760551],
            [-131.968597, 52.726101],
            [-132.002502, 52.776939],
            [-131.917496, 52.722488],
            [-131.951706, 52.781109],
            [-132.077194, 52.854439],
            [-132.055603, 52.79361],
            [-132.127502, 52.750832],
            [-132.236694, 52.824711],
            [-132.2258, 52.864441],
            [-132.283295, 52.87471],
            [-132.343002, 52.942211],
            [-132.2453, 52.95388],
            [-132.171097, 52.923611],
            [-132.199997, 52.960548],
            [-132.141098, 52.93277],
            [-132.157501, 52.964161],
            [-132.112793, 52.997768],
            [-132.339996, 53.037769],
            [-132.479706, 53.02721],
            [-132.552795, 53.08971],
            [-132.5289, 53.112209],
            [-132.562195, 53.145271],
            [-132.413605, 53.127491],
            [-132.070801, 53.153881],
            [-131.985001, 53.212212],
            [-131.794495, 53.251389],
            [-131.762207, 53.196659],
            [-131.784195, 53.164711],
            [-131.637497, 53.109718],
            [-131.594696, 53.035271],
            [-131.794693, 53.073051],
            [-131.998901, 53.05138],
            [-131.945801, 53.04166],
            [-131.917007, 52.984718],
            [-131.847794, 53.043049],
            [-131.644699, 53.00388],
            [-131.598099, 52.975269],
            [-131.628601, 52.94276],
            [-131.611694, 52.926102],
            [-131.6875, 52.86916],
            [-131.8022, 52.864719],
            [-131.8983, 52.941101],
            [-131.944702, 52.93166],
            [-131.891403, 52.909988],
            [-131.982498, 52.879711],
            [-131.886993, 52.88916],
            [-131.852493, 52.861938],
            [-131.880798, 52.833328],
            [-131.812195, 52.797218],
            [-131.6492, 52.800541],
            [-131.851105, 52.766109],
            [-131.806396, 52.748329],
            [-131.837799, 52.710819],
            [-131.726898, 52.695541],
            [-131.681396, 52.637501],
            [-131.705002, 52.621658],
            [-131.615295, 52.56889],
            [-131.660599, 52.538891],
            [-131.467499, 52.501389],
            [-131.483597, 52.479431],
            [-131.415604, 52.45805],
            [-131.447006, 52.390549],
            [-131.391403, 52.390831],
            [-131.397202, 52.35305],
            [-131.347504, 52.36805],
            [-131.353104, 52.418331],
            [-131.236404, 52.439159],
            [-131.301102, 52.38055],
            [-131.304199, 52.356659],
            [-131.247498, 52.365829],
            [-131.263107, 52.34277],
            [-131.358902, 52.343609],
            [-131.328293, 52.285],
            [-131.226898, 52.251942],
            [-131.248306, 52.302219],
            [-131.136398, 52.311378],
            [-131.089401, 52.249161],
            [-131.180298, 52.228321],
            [-131.074402, 52.219379],
          ],
        ],
        [
          [
            [-129.462799, 53.179989],
            [-129.313004, 53.057499],
            [-129.289505, 52.971931],
            [-129.4142, 53.010551],
            [-129.475296, 53.10194],
            [-129.544693, 53.12833],
            [-129.521698, 53.183601],
            [-129.462799, 53.179989],
          ],
        ],
        [
          [
            [-128.498901, 52.870831],
            [-128.450806, 52.805271],
            [-128.512497, 52.641109],
            [-128.539993, 52.70332],
            [-128.498901, 52.870831],
          ],
        ],
        [
          [
            [-128.170303, 52.817768],
            [-128.247192, 52.620541],
            [-128.2845, 52.598598],
            [-128.324997, 52.7761],
            [-128.225006, 52.782211],
            [-128.170303, 52.817768],
          ],
        ],
        [
          [
            [-129.231094, 52.816101],
            [-128.923294, 52.606659],
            [-128.943604, 52.469711],
            [-128.982803, 52.453049],
            [-129.008896, 52.525551],
            [-129.109695, 52.552769],
            [-129.118896, 52.60471],
            [-129.2108, 52.64888],
            [-129.224197, 52.67749],
            [-129.193893, 52.683048],
            [-129.270599, 52.719151],
            [-129.281403, 52.817211],
            [-129.231094, 52.816101],
          ],
        ],
        [
          [
            [-128.373306, 52.791111],
            [-128.274994, 52.48999],
            [-128.365799, 52.38221],
            [-128.441696, 52.36805],
            [-128.467804, 52.393051],
            [-128.394196, 52.465],
            [-128.423096, 52.474991],
            [-128.437805, 52.54361],
            [-128.450607, 52.626942],
            [-128.418304, 52.70805],
            [-128.441696, 52.746941],
            [-128.373306, 52.791111],
          ],
        ],
        [
          [
            [-131.468903, 52.730549],
            [-131.439194, 52.70166],
            [-131.458298, 52.630821],
            [-131.5905, 52.662491],
            [-131.593307, 52.63361],
            [-131.547806, 52.611382],
            [-131.585495, 52.58527],
            [-131.709702, 52.705269],
            [-131.574707, 52.72694],
            [-131.674698, 52.70499],
            [-131.549393, 52.688042],
            [-131.468903, 52.730549],
          ],
        ],
        [
          [
            [-128.535294, 52.647221],
            [-128.464996, 52.473049],
            [-128.486893, 52.43721],
            [-128.536407, 52.43499],
            [-128.6828, 52.514439],
            [-128.714706, 52.483879],
            [-128.813004, 52.519989],
            [-128.802505, 52.546101],
            [-128.697006, 52.520828],
            [-128.738907, 52.58749],
            [-128.577499, 52.593609],
            [-128.552505, 52.665272],
            [-128.535294, 52.647221],
          ],
        ],
        [
          [
            [-128.616104, 52.448601],
            [-128.617798, 52.32999],
            [-128.675598, 52.266392],
            [-128.726395, 52.316101],
            [-128.759201, 52.449429],
            [-128.674393, 52.482208],
            [-128.616104, 52.448601],
          ],
        ],
        [
          [
            [-127.596298, 52.151791],
            [-127.6539, 52.130821],
            [-127.717499, 51.98027],
            [-127.8797, 51.944988],
            [-127.9011, 51.985821],
            [-127.8797, 52.084991],
            [-127.7892, 52.221931],
            [-127.510002, 52.306938],
            [-127.400299, 52.395271],
            [-127.263901, 52.436649],
            [-127.234703, 52.416939],
            [-127.244499, 52.36916],
            [-127.207802, 52.344711],
            [-127.232201, 52.313049],
            [-127.596298, 52.151791],
          ],
        ],
        [
          [
            [-128.092499, 52.408329],
            [-128.056702, 52.32888],
            [-128.213593, 52.27972],
            [-128.1147, 52.418331],
            [-128.092499, 52.408329],
          ],
        ],
        [
          [
            [-127.961899, 52.28944],
            [-127.907501, 52.204708],
            [-127.924698, 52.17416],
            [-128.092804, 52.192211],
            [-128.011703, 52.23193],
            [-128.046402, 52.258049],
            [-128.169998, 52.249722],
            [-128.047806, 52.300541],
            [-127.961899, 52.28944],
          ],
        ],
        [
          [
            [-128.148102, 52.183331],
            [-128.151093, 52.081108],
            [-128.2061, 52.018879],
            [-128.253098, 52.019711],
            [-128.291702, 52.118881],
            [-128.2397, 52.184158],
            [-128.148102, 52.183331],
          ],
        ],
        [
          [
            [-128.031693, 52.163288],
            [-127.879204, 52.16666],
            [-127.962799, 52.074711],
            [-128.105804, 52.100269],
            [-128.1147, 52.149158],
            [-128.031693, 52.163288],
          ],
        ],
        [
          [
            [-127.951202, 52.046909],
            [-127.970596, 51.925831],
            [-128.032196, 51.840549],
            [-128.0186, 51.770271],
            [-128.131897, 51.743881],
            [-128.110504, 51.801929],
            [-128.132797, 51.85527],
            [-128.254501, 51.865829],
            [-128.199203, 51.913052],
            [-128.222198, 51.95332],
            [-128.145294, 52.038601],
            [-127.951202, 52.046909],
          ],
        ],
        [
          [
            [-127.998001, 51.711658],
            [-127.873596, 51.464439],
            [-127.914398, 51.41082],
            [-128.003403, 51.468601],
            [-128.0811, 51.474709],
            [-128.153107, 51.601662],
            [-128.136398, 51.661659],
            [-127.998001, 51.711658],
          ],
        ],
        [
          [
            [-127.121101, 49.852291],
            [-127.241402, 49.961941],
            [-127.178596, 50.009159],
            [-127.176697, 50.0611],
            [-127.091904, 50.09943],
            [-127.090302, 50.141941],
            [-127.172501, 50.09193],
            [-127.292503, 50.146389],
            [-127.277496, 50.055271],
            [-127.380501, 50.0261],
            [-127.470802, 50.08543],
            [-127.420799, 50.12804],
            [-127.485001, 50.12804],
            [-127.428596, 50.180271],
            [-127.596901, 50.115551],
            [-127.635597, 50.135551],
            [-127.609703, 50.180271],
            [-127.650803, 50.175549],
            [-127.788101, 50.081669],
            [-127.906998, 50.120831],
            [-127.745003, 50.24194],
            [-127.8442, 50.275551],
            [-127.804398, 50.321381],
            [-127.931396, 50.316101],
            [-127.9786, 50.342491],
            [-127.923897, 50.462769],
            [-127.584198, 50.486938],
            [-127.446999, 50.372761],
            [-127.57, 50.512211],
            [-127.490303, 50.56805],
            [-127.4142, 50.575829],
            [-127.419403, 50.596661],
            [-127.553001, 50.56805],
            [-127.596703, 50.596939],
            [-127.873299, 50.623878],
            [-127.786697, 50.582211],
            [-127.634499, 50.578049],
            [-127.595596, 50.536659],
            [-127.995102, 50.466389],
            [-128.058594, 50.486382],
            [-128.051407, 50.44669],
            [-128.224197, 50.531109],
            [-128.241394, 50.58749],
            [-128.3367, 50.63694],
            [-128.272797, 50.66972],
            [-128.375305, 50.6786],
            [-128.416702, 50.769161],
            [-128.064194, 50.872219],
            [-127.909698, 50.87138],
            [-127.507202, 50.772221],
            [-127.477097, 50.700069],
            [-127.408897, 50.746101],
            [-127.407204, 50.700001],
            [-127.200302, 50.61277],
            [-126.854698, 50.55444],
            [-126.852501, 50.523319],
            [-126.776398, 50.546101],
            [-126.592499, 50.47694],
            [-126.327797, 50.48082],
            [-126.069199, 50.438599],
            [-125.9478, 50.384991],
            [-125.725601, 50.352219],
            [-125.564201, 50.36388],
            [-125.431702, 50.310551],
            [-125.351898, 50.149158],
            [-125.368301, 50.125271],
            [-125.300598, 50.10527],
            [-125.168602, 49.912769],
            [-125, 49.796299],
            [-124.866402, 49.711109],
            [-124.883904, 49.666382],
            [-124.977203, 49.668598],
            [-124.789398, 49.464161],
            [-124.716698, 49.463051],
            [-124.535599, 49.373878],
            [-124.170502, 49.311939],
            [-124.110802, 49.275269],
            [-124.175598, 49.255829],
            [-123.957802, 49.228321],
            [-123.909698, 49.127491],
            [-123.810303, 49.115829],
            [-123.749397, 49.035271],
            [-123.757004, 48.986938],
            [-123.8311, 48.997219],
            [-123.582802, 48.83194],
            [-123.603302, 48.79583],
            [-123.565598, 48.789719],
            [-123.567497, 48.744438],
            [-123.648102, 48.750271],
            [-123.538101, 48.691662],
            [-123.509499, 48.58749],
            [-123.535004, 48.529991],
            [-123.4758, 48.568329],
            [-123.4767, 48.63166],
            [-123.439201, 48.647221],
            [-123.4692, 48.67944],
            [-123.4011, 48.681381],
            [-123.341698, 48.508888],
            [-123.2761, 48.451099],
            [-123.336403, 48.396938],
            [-123.443001, 48.443321],
            [-123.546097, 48.30777],
            [-123.583298, 48.301102],
            [-123.762497, 48.36166],
            [-123.924202, 48.3661],
            [-124.423599, 48.516941],
            [-124.438301, 48.532768],
            [-124.396103, 48.56583],
            [-124.489403, 48.53944],
            [-124.688599, 48.578331],
            [-124.8228, 48.652489],
            [-125.113899, 48.731098],
            [-125.184998, 48.800831],
            [-125.005997, 48.88903],
            [-125, 48.939468],
            [-124.842796, 49.015549],
            [-124.7808, 49.131378],
            [-124.8078, 49.240551],
            [-124.826103, 49.12249],
            [-124.906097, 49.001942],
            [-125.092201, 48.972488],
            [-125.150597, 48.996941],
            [-125.241898, 48.95166],
            [-125.331902, 48.96999],
            [-125.258301, 49.01833],
            [-125.3414, 49.03611],
            [-125.371902, 48.97916],
            [-125.483597, 48.915821],
            [-125.737801, 49.049999],
            [-125.8769, 49.088329],
            [-125.858299, 49.110821],
            [-125.738098, 49.105549],
            [-125.605797, 49.21027],
            [-125.753304, 49.147491],
            [-125.735001, 49.257221],
            [-125.802498, 49.255001],
            [-125.776901, 49.365829],
            [-125.846397, 49.25816],
            [-125.894402, 49.273609],
            [-125.970299, 49.224709],
            [-126.0233, 49.268051],
            [-125.945503, 49.316669],
            [-125.904701, 49.435822],
            [-126.001999, 49.321941],
            [-126.065002, 49.348331],
            [-126.052803, 49.446098],
            [-126.1903, 49.400829],
            [-126.258598, 49.445541],
            [-126.296997, 49.357769],
            [-126.403603, 49.411098],
            [-126.422501, 49.470268],
            [-126.480301, 49.44305],
            [-126.466103, 49.380268],
            [-126.542198, 49.374439],
            [-126.577797, 49.407768],
            [-126.555, 49.44471],
            [-126.566101, 49.584431],
            [-126.470299, 49.635551],
            [-126.134201, 49.64999],
            [-126.086899, 49.657211],
            [-126.103104, 49.679161],
            [-126.324997, 49.64888],
            [-126.438301, 49.66415],
            [-126.483101, 49.702492],
            [-126.408897, 49.770271],
            [-126.456398, 49.78833],
            [-126.4972, 49.712769],
            [-126.584999, 49.701099],
            [-126.645599, 49.892769],
            [-126.731697, 49.880268],
            [-126.829498, 49.96471],
            [-126.800797, 49.913879],
            [-126.845596, 49.88221],
            [-126.884201, 49.879162],
            [-126.914703, 49.965832],
            [-126.9394, 49.86277],
            [-127.121101, 49.852291],
          ],
        ],
        [
          [
            [-126.797798, 50.768879],
            [-126.8647, 50.78944],
            [-126.9058, 50.822769],
            [-126.881401, 50.83028],
            [-126.794197, 50.80471],
            [-126.779198, 50.831669],
            [-126.643303, 50.846939],
            [-126.563004, 50.799999],
            [-126.797798, 50.768879],
          ],
        ],
        [
          [
            [-126.251701, 50.818932],
            [-126.176697, 50.743881],
            [-126.243896, 50.717209],
            [-126.266403, 50.654709],
            [-126.6175, 50.664989],
            [-126.389702, 50.806381],
            [-126.251701, 50.818932],
          ],
        ],
        [
          [
            [-126.336403, 50.52166],
            [-126.623901, 50.533878],
            [-126.286102, 50.598331],
            [-126.224701, 50.55666],
            [-126.336403, 50.52166],
          ],
        ],
        [
          [
            [-125.370796, 50.45583],
            [-125.330597, 50.42527],
            [-125.385803, 50.369709],
            [-125.457802, 50.34943],
            [-125.536903, 50.379162],
            [-125.370796, 50.45583],
          ],
        ],
        [
          [
            [-125.273102, 50.43111],
            [-125.168098, 50.382771],
            [-125.051903, 50.22665],
            [-125.049202, 50.193321],
            [-125.088303, 50.197208],
            [-125.133904, 50.122761],
            [-125.154198, 50.133331],
            [-125.1269, 50.21944],
            [-125.2136, 50.316669],
            [-125.393097, 50.29583],
            [-125.388603, 50.343048],
            [-125.313301, 50.340832],
            [-125.320602, 50.385551],
            [-125.273102, 50.43111],
          ],
        ],
        [
          [
            [-124.898598, 50.29388],
            [-124.811401, 50.275551],
            [-124.820297, 50.20583],
            [-124.756699, 50.178329],
            [-124.806999, 50.11388],
            [-124.870796, 50.156651],
            [-124.834396, 50.192211],
            [-124.915604, 50.188881],
            [-124.963097, 50.236382],
            [-124.898598, 50.29388],
          ],
        ],
        [
          [
            [-125.345497, 50.263901],
            [-125.2547, 50.29361],
            [-125.167198, 50.213612],
            [-125.186699, 50.141659],
            [-125.233101, 50.11805],
            [-125.178001, 50.078609],
            [-125.167801, 49.98082],
            [-125.215599, 50.07888],
            [-125.323601, 50.14333],
            [-125.345497, 50.263901],
          ],
        ],
        [
          [
            [-124.983299, 50.225552],
            [-124.891098, 50.06805],
            [-124.987198, 50.012772],
            [-125, 50.064308],
            [-125.067001, 50.107498],
            [-124.982201, 50.176102],
            [-125.005798, 50.225819],
            [-124.983299, 50.225552],
          ],
        ],
        [
          [
            [-126.7687, 49.87862],
            [-126.681702, 49.849998],
            [-126.636101, 49.759441],
            [-126.613297, 49.648331],
            [-126.633102, 49.5961],
            [-126.807999, 49.619709],
            [-126.974197, 49.740269],
            [-126.855797, 49.776939],
            [-126.988602, 49.811378],
            [-126.970299, 49.832211],
            [-126.7687, 49.87862],
          ],
        ],
        [
          [
            [-124.122803, 49.49361],
            [-124.440002, 49.612499],
            [-124.4767, 49.67194],
            [-124.654198, 49.736111],
            [-124.6567, 49.79694],
            [-124.351402, 49.69804],
            [-124.122803, 49.49361],
          ],
        ],
        [
          [
            [-126.1269, 49.39027],
            [-126.0728, 49.343048],
            [-126.064697, 49.250832],
            [-126.239197, 49.289719],
            [-126.234703, 49.374161],
            [-126.1269, 49.39027],
          ],
        ],
        [
          [
            [-123.674698, 49.093048],
            [-123.521698, 48.96027],
            [-123.3125, 48.86805],
            [-123.378899, 48.856941],
            [-123.529999, 48.938042],
            [-123.662498, 49.035271],
            [-123.705299, 49.100269],
            [-123.674698, 49.093048],
          ],
        ],
        [
          [
            [-123.577202, 48.929161],
            [-123.433899, 48.84444],
            [-123.494499, 48.845268],
            [-123.371696, 48.7575],
            [-123.456398, 48.76527],
            [-123.431999, 48.7211],
            [-123.524696, 48.722488],
            [-123.551903, 48.75222],
            [-123.532501, 48.795269],
            [-123.571701, 48.829159],
            [-123.547798, 48.859989],
            [-123.592796, 48.898331],
            [-123.596703, 48.946941],
            [-123.577202, 48.929161],
          ],
        ],
        [
          [
            [-117.030998, 49],
            [-117.4375, 49],
            [-118.199203, 49],
            [-118.842499, 49],
            [-120.855797, 48.999969],
            [-122.7603, 48.999439],
            [-122.862503, 49.022221],
            [-122.859398, 49.077221],
            [-122.900002, 49.087212],
            [-123.0392, 49.0425],
            [-123.034302, 48.999439],
            [-123.145798, 49.058601],
            [-123.036102, 49.149719],
            [-123.115501, 49.108601],
            [-123.205002, 49.1236],
            [-123.2033, 49.194439],
            [-123.127197, 49.19582],
            [-123.248901, 49.273609],
            [-123.146698, 49.271381],
            [-123.147797, 49.308041],
            [-122.923599, 49.28833],
            [-122.852798, 49.4361],
            [-122.876404, 49.455551],
            [-122.901398, 49.36055],
            [-122.953102, 49.310268],
            [-123.269203, 49.331108],
            [-123.288101, 49.374989],
            [-123.233299, 49.426102],
            [-123.256699, 49.512772],
            [-123.159698, 49.699162],
            [-123.241898, 49.660549],
            [-123.254501, 49.588329],
            [-123.4925, 49.50972],
            [-123.4767, 49.419159],
            [-123.535599, 49.381378],
            [-123.749199, 49.461109],
            [-123.896103, 49.470539],
            [-124.0681, 49.633881],
            [-124.021103, 49.726379],
            [-123.940598, 49.742489],
            [-123.833099, 49.627491],
            [-123.769699, 49.504711],
            [-123.759201, 49.601101],
            [-123.614998, 49.63916],
            [-123.534203, 49.701389],
            [-123.7967, 49.638329],
            [-123.931396, 49.76416],
            [-123.872803, 49.877209],
            [-123.959702, 49.981659],
            [-123.744499, 50.086941],
            [-123.821404, 50.15221],
            [-123.985001, 50.216099],
            [-123.809196, 50.099998],
            [-123.999199, 50.00639],
            [-123.979698, 49.91666],
            [-123.911102, 49.869709],
            [-123.926399, 49.825829],
            [-124.004204, 49.810551],
            [-124.037804, 49.922489],
            [-124.071999, 49.873322],
            [-124.046097, 49.841099],
            [-124.084396, 49.79916],
            [-124.257797, 49.775829],
            [-124.2714, 49.742771],
            [-124.511902, 49.796101],
            [-124.532799, 49.84444],
            [-124.741898, 49.958328],
            [-124.829697, 50.061939],
            [-124.686401, 49.98444],
            [-124.704697, 50.030819],
            [-124.668602, 50.076389],
            [-124.700302, 50.10833],
            [-124.626099, 50.165272],
            [-124.601402, 50.23888],
            [-124.714996, 50.327492],
            [-124.651901, 50.38916],
            [-124.434196, 50.43166],
            [-124.347801, 50.502491],
            [-124.563004, 50.42083],
            [-124.671402, 50.425831],
            [-124.830002, 50.309429],
            [-124.959999, 50.33305],
            [-124.948898, 50.37582],
            [-124.994698, 50.430271],
            [-124.9953, 50.353882],
            [-125.0783, 50.322491],
            [-125.056702, 50.47694],
            [-125, 50.490829],
            [-124.858002, 50.590549],
            [-124.860298, 50.6786],
            [-124.822502, 50.723598],
            [-124.913597, 50.79361],
            [-124.786903, 50.884441],
            [-124.849998, 50.935261],
            [-124.912804, 50.841099],
            [-124.983299, 50.8036],
            [-124.897003, 50.72971],
            [-124.9217, 50.661381],
            [-124.903603, 50.619991],
            [-125.104202, 50.496941],
            [-125.1147, 50.436939],
            [-125.170303, 50.412491],
            [-125.246101, 50.462212],
            [-125.245003, 50.503609],
            [-125.315804, 50.465271],
            [-125.363297, 50.541382],
            [-125.4217, 50.465271],
            [-125.573303, 50.458889],
            [-125.527802, 50.540279],
            [-125.532204, 50.627209],
            [-125.4328, 50.713879],
            [-125.547203, 50.66193],
            [-125.573898, 50.544159],
            [-125.623901, 50.50639],
            [-125.613899, 50.465271],
            [-125.700302, 50.428051],
            [-125.795502, 50.496101],
            [-125.738602, 50.516109],
            [-125.815804, 50.534161],
            [-125.952202, 50.46888],
            [-126.275002, 50.5075],
            [-126.232803, 50.53611],
            [-126.175797, 50.519161],
            [-126.170303, 50.573879],
            [-125.9403, 50.629162],
            [-126.207802, 50.588879],
            [-126.273903, 50.632141],
            [-125.704201, 50.678329],
            [-125.6772, 50.73444],
            [-125.611099, 50.764721],
            [-125.641998, 50.853321],
            [-125.546402, 50.861382],
            [-125.5056, 50.933331],
            [-125.551697, 51.042221],
            [-125.633904, 51.096939],
            [-125.5811, 50.95388],
            [-125.542801, 50.932499],
            [-125.6744, 50.86694],
            [-125.680298, 50.81554],
            [-125.643097, 50.783878],
            [-125.703598, 50.764999],
            [-125.746902, 50.70805],
            [-125.936897, 50.681381],
            [-125.9953, 50.70805],
            [-126.210297, 50.665272],
            [-126.2089, 50.707771],
            [-126.024696, 50.793049],
            [-126.181396, 50.778332],
            [-126.1772, 50.86805],
            [-126.493301, 50.81638],
            [-126.552803, 50.834721],
            [-126.557503, 50.876659],
            [-126.494499, 50.918049],
            [-126.2258, 50.898609],
            [-126.170303, 50.936649],
            [-126.4636, 50.94804],
            [-126.516403, 51.070549],
            [-126.549202, 51.014439],
            [-126.524399, 50.937489],
            [-126.6614, 50.86805],
            [-126.854401, 50.93943],
            [-126.917198, 50.899158],
            [-127.176399, 50.929161],
            [-127.062798, 50.885269],
            [-126.917503, 50.876659],
            [-127.022499, 50.817501],
            [-127.2547, 50.913879],
            [-127.398598, 50.92638],
            [-127.441101, 50.947769],
            [-127.420799, 50.978321],
            [-127.535301, 51.000549],
            [-127.5028, 51.097488],
            [-127.381104, 51.059052],
            [-127.330803, 51.047779],
            [-127.236099, 51.041111],
            [-126.848602, 51.069721],
            [-126.765602, 51.056381],
            [-126.7117, 51.000832],
            [-126.704498, 51.039719],
            [-126.743103, 51.0825],
            [-126.652802, 51.14999],
            [-126.658302, 51.19276],
            [-126.806999, 51.089161],
            [-127.206703, 51.056381],
            [-127.388397, 51.067989],
            [-127.500603, 51.11805],
            [-127.649399, 51.09222],
            [-127.790001, 51.165539],
            [-127.761902, 51.249439],
            [-127.565598, 51.293049],
            [-127.306702, 51.280499],
            [-127.143303, 51.318329],
            [-127.115799, 51.391659],
            [-127.188004, 51.323608],
            [-127.280602, 51.301102],
            [-127.509697, 51.310268],
            [-127.465302, 51.358051],
            [-127.57, 51.328609],
            [-127.784203, 51.33305],
            [-127.778297, 51.361111],
            [-127.663902, 51.385551],
            [-127.551697, 51.468319],
            [-127.500603, 51.613609],
            [-127.233299, 51.662491],
            [-126.6633, 51.64888],
            [-126.605301, 51.713051],
            [-126.6633, 51.797218],
            [-126.639999, 51.709991],
            [-126.693001, 51.664711],
            [-127.053902, 51.697769],
            [-127.2742, 51.683331],
            [-127.3367, 51.712769],
            [-127.425797, 51.66666],
            [-127.433098, 51.75],
            [-127.3619, 51.77166],
            [-127.351097, 51.863609],
            [-127.392799, 51.77721],
            [-127.453102, 51.772491],
            [-127.474403, 51.70499],
            [-127.644203, 51.69804],
            [-127.462196, 51.6675],
            [-127.540298, 51.638889],
            [-127.5858, 51.570549],
            [-127.561096, 51.538601],
            [-127.640297, 51.458599],
            [-127.7547, 51.479988],
            [-127.656403, 51.508888],
            [-127.795502, 51.5261],
            [-127.787201, 51.560268],
            [-127.840599, 51.641731],
            [-127.878098, 51.673882],
            [-127.866096, 51.89999],
            [-127.789703, 51.941101],
            [-127.667801, 51.949162],
            [-127.651398, 52.051659],
            [-127.580299, 52.129162],
            [-127.480598, 52.1511],
            [-127.462502, 52.112499],
            [-127.584396, 52.093609],
            [-127.6203, 52.027771],
            [-127.547203, 52.07972],
            [-127.4114, 52.090549],
            [-127.453102, 52.179989],
            [-127.2453, 52.248878],
            [-127.1717, 52.312489],
            [-127.004997, 52.3036],
            [-126.938004, 52.246941],
            [-126.937202, 52.196098],
            [-126.751999, 52.078609],
            [-126.669403, 51.983601],
            [-126.669701, 52.041939],
            [-126.751701, 52.125271],
            [-126.900597, 52.205269],
            [-126.940804, 52.303879],
            [-126.867798, 52.35416],
            [-126.736702, 52.37804],
            [-126.792801, 52.39555],
            [-126.9814, 52.33527],
            [-127.082497, 52.334991],
            [-127.186401, 52.380821],
            [-127.2342, 52.517208],
            [-127.074203, 52.61639],
            [-126.989403, 52.631939],
            [-126.922203, 52.71888],
            [-126.966904, 52.828609],
            [-127.071098, 52.883881],
            [-126.980797, 52.72443],
            [-127.075699, 52.639721],
            [-127.257202, 52.54583],
            [-127.266701, 52.464161],
            [-127.452499, 52.40443],
            [-127.611702, 52.29472],
            [-127.746101, 52.276661],
            [-127.714203, 52.353611],
            [-127.736702, 52.349998],
            [-127.858299, 52.206661],
            [-127.928596, 52.30471],
            [-127.905296, 52.3461],
            [-127.841698, 52.365002],
            [-127.916397, 52.44582],
            [-127.869698, 52.5061],
            [-127.900002, 52.509991],
            [-127.930603, 52.434429],
            [-127.889999, 52.384991],
            [-127.963898, 52.321659],
            [-128.057205, 52.394711],
            [-128.068298, 52.447769],
            [-128.009506, 52.50861],
            [-127.9375, 52.499722],
            [-127.8797, 52.57972],
            [-128.099701, 52.503052],
            [-128.151093, 52.409988],
            [-128.304993, 52.26944],
            [-128.393906, 52.291382],
            [-128.224396, 52.459721],
            [-128.239197, 52.56694],
            [-128.113007, 52.748329],
            [-128.139206, 52.837212],
            [-128.128098, 52.88055],
            [-128.025299, 52.912209],
            [-128.139206, 52.924438],
            [-128.235306, 52.80582],
            [-128.441101, 52.822769],
            [-128.495499, 52.887501],
            [-128.537796, 53.073051],
            [-128.496597, 53.09721],
            [-128.554199, 53.118881],
            [-128.4814, 53.141941],
            [-128.571899, 53.142769],
            [-128.663605, 53.239429],
            [-128.685303, 53.18943],
            [-128.858902, 53.283611],
            [-128.878296, 53.338039],
            [-128.8461, 53.36832],
            [-128.883606, 53.369991],
            [-128.889206, 53.430271],
            [-128.829407, 53.477772],
            [-128.923294, 53.45388],
            [-128.972794, 53.553051],
            [-128.866592, 53.535],
            [-128.794998, 53.5686],
            [-128.754196, 53.493881],
            [-128.670807, 53.45055],
            [-128.692505, 53.485271],
            [-128.643906, 53.474991],
            [-128.4953, 53.388599],
            [-128.481903, 53.33971],
            [-128.474396, 53.389721],
            [-128.504196, 53.407768],
            [-128.373596, 53.442211],
            [-128.352493, 53.475819],
            [-128.131897, 53.448879],
            [-128.071106, 53.396389],
            [-128.099396, 53.33638],
            [-128.032196, 53.367489],
            [-127.952003, 53.326099],
            [-127.946098, 53.25222],
            [-127.867798, 53.239719],
            [-127.93, 53.284721],
            [-127.925003, 53.329159],
            [-128.050293, 53.395],
            [-128.114395, 53.488041],
            [-128.380295, 53.499439],
            [-128.389206, 53.462212],
            [-128.517502, 53.431381],
            [-128.756104, 53.560268],
            [-128.816696, 53.623322],
            [-128.764694, 53.71944],
            [-128.7939, 53.764999],
            [-128.770599, 53.79583],
            [-128.689697, 53.81221],
            [-128.643097, 53.863049],
            [-128.471405, 53.8325],
            [-128.688599, 53.889992],
            [-128.609207, 54.03138],
            [-128.685303, 54.000832],
            [-128.720001, 53.938881],
            [-128.9319, 53.774712],
            [-128.904694, 53.856659],
            [-128.943893, 53.80999],
            [-128.950806, 53.878601],
            [-129.003906, 53.89333],
            [-128.966904, 53.863049],
            [-128.964203, 53.782211],
            [-129.009201, 53.762211],
            [-129.0569, 53.811939],
            [-129.028595, 53.7486],
            [-129.232498, 53.62582],
            [-129.261993, 53.517769],
            [-129.2314, 53.500832],
            [-129.237503, 53.433601],
            [-129.310699, 53.379501],
            [-129.606094, 53.567768],
            [-129.734695, 53.67527],
            [-129.699707, 53.671101],
            [-129.713593, 53.699429],
            [-129.989395, 53.845829],
            [-129.962204, 53.88583],
            [-130.031693, 53.876381],
            [-130.101395, 53.946659],
            [-130.090805, 54.071659],
            [-130.046707, 54.15332],
            [-129.950302, 54.158039],
            [-129.839996, 54.027771],
            [-129.718903, 53.951389],
            [-129.737198, 54.001659],
            [-129.834198, 54.05471],
            [-129.929703, 54.183048],
            [-129.831894, 54.21944],
            [-129.614197, 54.178921],
            [-129.470001, 54.237209],
            [-129.616898, 54.194149],
            [-129.854996, 54.238041],
            [-130.122803, 54.15443],
            [-130.278595, 54.208889],
            [-130.279999, 54.24361],
            [-130.195602, 54.259991],
            [-130.2603, 54.33749],
            [-130.250305, 54.400829],
            [-130.275803, 54.34972],
            [-130.348907, 54.326939],
            [-130.481094, 54.364719],
            [-130.473602, 54.435822],
            [-130.381104, 54.45805],
            [-130.433594, 54.496658],
            [-130.429993, 54.562489],
            [-130.375793, 54.571659],
            [-130.439697, 54.612209],
            [-130.416702, 54.630268],
            [-130.063004, 54.339989],
            [-129.982803, 54.3111],
            [-129.959702, 54.326939],
            [-130.038605, 54.34193],
            [-130.089706, 54.402771],
            [-130.059204, 54.47916],
            [-129.978897, 54.516941],
            [-130.0672, 54.49416],
            [-130.082794, 54.434719],
            [-130.123306, 54.42749],
            [-130.351395, 54.61805],
            [-130.368607, 54.66777],
            [-130.171906, 54.703609],
            [-130.000595, 54.614719],
            [-129.880798, 54.614719],
            [-130.004501, 54.632771],
            [-130.079193, 54.691929],
            [-130.201706, 54.72694],
            [-130.155594, 54.815269],
            [-130.050293, 54.88694],
            [-130.173904, 54.846661],
            [-130.031998, 54.965271],
            [-129.954193, 54.95916],
            [-129.974396, 54.914989],
            [-129.824402, 54.989159],
            [-129.622498, 54.997768],
            [-129.998306, 55.018051],
            [-129.961395, 55.093319],
            [-129.860504, 55.170551],
            [-129.815598, 55.255001],
            [-129.749405, 55.28833],
            [-129.643295, 55.434158],
            [-129.523895, 55.439991],
            [-129.4711, 55.472759],
            [-129.636993, 55.456661],
            [-129.708298, 55.409988],
            [-129.811996, 55.629162],
            [-129.772797, 55.473881],
            [-129.839401, 55.387501],
            [-129.824402, 55.361938],
            [-129.790604, 55.398048],
            [-129.808594, 55.367771],
            [-129.782501, 55.353611],
            [-129.830795, 55.323051],
            [-129.813904, 55.289719],
            [-129.920807, 55.151932],
            [-130.068298, 54.996941],
            [-130.113602, 54.99638],
            [-130.160004, 55.086109],
            [-129.943893, 55.282211],
            [-130.007202, 55.355],
            [-130.0242, 55.46471],
            [-130.1017, 55.556381],
            [-130.074707, 55.672489],
            [-130.174698, 55.75444],
            [-130.015106, 55.90918],
            [-130.088593, 56.11805],
            [-130.4142, 56.12888],
            [-130.461899, 56.235271],
            [-130.627197, 56.25861],
            [-130.774399, 56.3661],
            [-131.054993, 56.398048],
            [-131.5578, 56.602219],
            [-131.824203, 56.596939],
            [-131.861404, 56.799721],
            [-132.103104, 56.866661],
            [-132.027496, 57.036381],
            [-132.336899, 57.088329],
            [-132.2267, 57.204708],
            [-133.136993, 58.13583],
            [-133.361099, 58.280548],
            [-133.429993, 58.45916],
            [-133.826904, 58.726101],
            [-134.245499, 58.856941],
            [-134.461395, 59.126659],
            [-134.566406, 59.13055],
            [-134.688004, 59.24332],
            [-134.951904, 59.279991],
            [-134.989197, 59.389992],
            [-135.091705, 59.426941],
            [-135.021103, 59.4711],
            [-135.014496, 59.567501],
            [-135.473602, 59.801929],
            [-136.343903, 59.602779],
            [-136.233902, 59.525829],
            [-136.463593, 59.469711],
            [-136.462799, 59.28944],
            [-136.583893, 59.163319],
            [-136.808899, 59.165272],
            [-137.4655, 58.906101],
            [-137.590805, 59.238602],
            [-138.615799, 59.774158],
            [-138.690308, 59.90694],
            [-138.938599, 59.997551],
            [-134.906601, 60],
            [-134.639297, 60.000198],
            [-134.151703, 59.999722],
            [-133.791199, 60],
            [-125.000801, 60.003868],
            [-123.816902, 59.994709],
            [-119.982903, 59.988522],
            [-120.000801, 54.005871],
            [-120.000801, 53.808041],
            [-119.904701, 53.778599],
            [-119.921097, 53.718319],
            [-119.810303, 53.707771],
            [-119.735298, 53.634991],
            [-119.7686, 53.603321],
            [-119.932198, 53.60722],
            [-119.882797, 53.560268],
            [-119.902496, 53.520271],
            [-119.842499, 53.518051],
            [-119.778099, 53.446941],
            [-119.738098, 53.395],
            [-119.606102, 53.37804],
            [-119.385803, 53.36166],
            [-119.348099, 53.275269],
            [-119.265602, 53.196659],
            [-119.045502, 53.137772],
            [-119.013603, 53.144711],
            [-119.012199, 53.223049],
            [-118.9786, 53.237209],
            [-118.759697, 53.12471],
            [-118.781097, 53.05582],
            [-118.669998, 53.034439],
            [-118.679703, 52.970539],
            [-118.625, 52.884159],
            [-118.491402, 52.89777],
            [-118.415604, 52.83638],
            [-118.428902, 52.78611],
            [-118.3461, 52.74332],
            [-118.305, 52.674709],
            [-118.349998, 52.61805],
            [-118.2817, 52.565269],
            [-118.286903, 52.527771],
            [-118.208298, 52.48082],
            [-118.259697, 52.442211],
            [-118.223099, 52.365551],
            [-118.145798, 52.39888],
            [-118.047501, 52.404991],
            [-118.0056, 52.48777],
            [-117.902802, 52.424999],
            [-117.7547, 52.403599],
            [-117.7314, 52.344151],
            [-117.829697, 52.27277],
            [-117.760002, 52.200829],
            [-117.6875, 52.196098],
            [-117.600304, 52.13055],
            [-117.363098, 52.138599],
            [-117.318901, 52.181938],
            [-117.269997, 52.044998],
            [-117.0392, 51.908039],
            [-116.919197, 51.70916],
            [-116.812798, 51.715271],
            [-116.815804, 51.750549],
            [-116.746101, 51.79916],
            [-116.658897, 51.796661],
            [-116.597504, 51.716381],
            [-116.595001, 51.65749],
            [-116.488098, 51.613049],
            [-116.466103, 51.56638],
            [-116.399696, 51.54472],
            [-116.3797, 51.488602],
            [-116.305, 51.461658],
            [-116.306099, 51.388329],
            [-116.262199, 51.316669],
            [-116.060501, 51.24638],
            [-116.0261, 51.21693],
            [-116.016098, 51.138329],
            [-115.957802, 51.103882],
            [-115.776703, 51.073608],
            [-115.759697, 51.035831],
            [-115.618599, 50.969151],
            [-115.571999, 50.90332],
            [-115.647202, 50.85471],
            [-115.527802, 50.791939],
            [-115.419197, 50.754169],
            [-115.395798, 50.71693],
            [-115.346703, 50.732208],
            [-115.311699, 50.706661],
            [-115.283096, 50.658878],
            [-115.320602, 50.638599],
            [-115.218903, 50.54694],
            [-115.1017, 50.588329],
            [-115.016998, 50.575001],
            [-114.8367, 50.39666],
            [-114.770302, 50.35833],
            [-114.793602, 50.334721],
            [-114.754997, 50.288891],
            [-114.724197, 50.125549],
            [-114.658897, 50.065269],
            [-114.643303, 49.977772],
            [-114.684998, 49.9575],
            [-114.6875, 49.913601],
            [-114.626099, 49.797218],
            [-114.636101, 49.727772],
            [-114.679703, 49.63221],
            [-114.738297, 49.608601],
            [-114.6894, 49.547218],
            [-114.576401, 49.556099],
            [-114.599998, 49.442211],
            [-114.576401, 49.389992],
            [-114.476097, 49.341381],
            [-114.429398, 49.265549],
            [-114.376701, 49.25639],
            [-114.372803, 49.204159],
            [-114.225304, 49.188599],
            [-114.154198, 49.148048],
            [-114.1567, 49.103611],
            [-114.035301, 49.031109],
            [-114.060303, 49],
            [-114.0625, 49],
            [-114.728302, 49],
            [-116.0625, 49],
            [-117.030998, 49],
          ],
        ],
      ],
    },
    id: 'CA_BC',
    properties: {
      name: 'British Columbia',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-109.978409, 78.671058],
            [-109.405296, 78.556931],
            [-109.326401, 78.518883],
            [-109.3339, 78.493874],
            [-109.254997, 78.478592],
            [-109.354401, 78.388893],
            [-109.3181, 78.358032],
            [-109.404999, 78.306374],
            [-109.824402, 78.293869],
            [-109.983902, 78.325546],
            [-109.978409, 78.671058],
          ],
        ],
        [
          [
            [-109.791702, 78.099152],
            [-109.580597, 78.058319],
            [-109.705597, 77.959991],
            [-109.987239, 77.924049],
            [-109.985789, 78.101103],
            [-109.791702, 78.099152],
          ],
        ],
        [
          [
            [-108.482697, 76.456818],
            [-108.607498, 76.410538],
            [-108.318604, 76.39415],
            [-108.077499, 76.279709],
            [-108.218903, 76.258881],
            [-108.093102, 76.214157],
            [-108.380302, 76.165268],
            [-108.4086, 76.147774],
            [-108.394203, 76.124977],
            [-108.456703, 76.099152],
            [-108.384201, 76.086647],
            [-108.496696, 76.039978],
            [-107.838303, 76.061371],
            [-107.6325, 75.991089],
            [-107.785301, 75.928864],
            [-107.750298, 75.905548],
            [-107.930298, 75.885536],
            [-108.031097, 75.822487],
            [-108.020302, 75.780823],
            [-107.873596, 75.798027],
            [-107.739403, 75.87915],
            [-107.369499, 75.911652],
            [-107.064201, 75.892761],
            [-107.043098, 75.883331],
            [-107.0858, 75.872482],
            [-107.098099, 75.823036],
            [-106.958603, 75.791931],
            [-107.0317, 75.771103],
            [-106.951103, 75.747482],
            [-106.963097, 75.702766],
            [-106.874702, 75.64415],
            [-106.9058, 75.686096],
            [-106.861397, 75.75499],
            [-106.880302, 75.765823],
            [-106.615799, 75.797211],
            [-106.888901, 75.834717],
            [-106.863602, 75.886932],
            [-106.896103, 75.941093],
            [-106.869698, 75.964157],
            [-106.606102, 76.057747],
            [-105.9702, 76.012421],
            [-105.668297, 75.955551],
            [-105.478897, 75.863037],
            [-105.445801, 75.830551],
            [-105.463898, 75.77916],
            [-105.419701, 75.743591],
            [-105.459999, 75.72998],
            [-105.388298, 75.656372],
            [-105.496399, 75.555252],
            [-105.751404, 75.4897],
            [-105.592796, 75.467484],
            [-105.714203, 75.416382],
            [-105.6464, 75.365257],
            [-105.866898, 75.275818],
            [-105.937798, 75.214432],
            [-105.8442, 75.183319],
            [-105.8853, 75.140266],
            [-106.070297, 75.106644],
            [-105.993301, 75.055817],
            [-106.725601, 75.002213],
            [-106.930603, 74.933594],
            [-107.197197, 74.910812],
            [-107.662498, 74.96666],
            [-107.7239, 75.016388],
            [-107.681396, 75.04248],
            [-107.695, 75.075272],
            [-107.7733, 75.0961],
            [-107.743896, 75.037201],
            [-108, 75.001938],
            [-107.948898, 74.958038],
            [-108.016403, 74.943588],
            [-107.941902, 74.930817],
            [-108.373001, 74.910538],
            [-108.807198, 74.983597],
            [-108.511398, 74.976379],
            [-108.651398, 75.053864],
            [-108.832497, 75.069992],
            [-108.949699, 75.040268],
            [-108.967796, 75.018883],
            [-108.937202, 75.003326],
            [-109.034698, 75.003052],
            [-108.996399, 74.984711],
            [-109.345802, 74.944702],
            [-109.398598, 74.896103],
            [-109.627296, 74.856888],
            [-109.872498, 74.869141],
            [-110.008101, 74.833102],
            [-110.00402, 75.532769],
            [-109.664703, 75.531097],
            [-108.899399, 75.476379],
            [-108.932503, 75.528587],
            [-109.030602, 75.552757],
            [-108.853897, 75.560257],
            [-108.895798, 75.586929],
            [-108.836403, 75.615807],
            [-108.936096, 75.674698],
            [-108.827003, 75.686653],
            [-109.391998, 75.769707],
            [-109.464401, 75.786652],
            [-109.413902, 75.799713],
            [-109.691399, 75.806931],
            [-109.626404, 75.832489],
            [-109.702499, 75.841927],
            [-109.663597, 75.870819],
            [-109.907799, 75.849991],
            [-110.001897, 75.875843],
            [-110.001757, 75.898089],
            [-109.672203, 75.943863],
            [-109.397499, 76.011383],
            [-109.433098, 76.029984],
            [-109.405602, 76.040817],
            [-109.290604, 76.034988],
            [-109.387199, 76.066093],
            [-109.313599, 76.109154],
            [-109.521698, 76.181091],
            [-109.720001, 76.194977],
            [-109.699997, 76.218872],
            [-109.882797, 76.233871],
            [-109.896698, 76.193588],
            [-109.999649, 76.224774],
            [-109.997979, 76.474992],
            [-109.746399, 76.505547],
            [-109.711403, 76.529984],
            [-109.847198, 76.532494],
            [-109.759201, 76.568047],
            [-109.776398, 76.593597],
            [-109.664398, 76.583878],
            [-109.654999, 76.61499],
            [-109.555298, 76.626373],
            [-109.562202, 76.658333],
            [-109.508301, 76.717758],
            [-109.350304, 76.74942],
            [-109.302803, 76.796944],
            [-108.9189, 76.809418],
            [-108.773598, 76.857758],
            [-108.6511, 76.813599],
            [-108.688301, 76.766098],
            [-108.527199, 76.760269],
            [-108.446404, 76.723602],
            [-108.441902, 76.696373],
            [-108.544701, 76.646378],
            [-108.727203, 76.638046],
            [-108.690598, 76.60054],
            [-108.5289, 76.553864],
            [-108.628304, 76.517761],
            [-108.530602, 76.515549],
            [-108.590302, 76.481934],
            [-108.482697, 76.456818],
          ],
        ],
        [
          [
            [-110.019917, 72.442051],
            [-109.952499, 72.459991],
            [-109.782799, 72.429428],
            [-110.019692, 72.493611],
            [-110.019536, 72.529154],
            [-109.888603, 72.484711],
            [-109.795303, 72.494141],
            [-109.9589, 72.612762],
            [-110.01912, 72.623398],
            [-110.018727, 72.7117],
            [-109.832802, 72.663307],
            [-109.816704, 72.676086],
            [-109.856903, 72.702766],
            [-109.770302, 72.722214],
            [-110.018556, 72.749766],
            [-110.017534, 72.974877],
            [-109.902199, 72.965271],
            [-109.853897, 72.91748],
            [-109.634499, 72.918053],
            [-109.7547, 72.878593],
            [-109.3797, 72.770538],
            [-109.400803, 72.750267],
            [-109.165802, 72.758614],
            [-109.111702, 72.739967],
            [-109.154701, 72.717209],
            [-108.937202, 72.667213],
            [-109.029999, 72.647217],
            [-109.043297, 72.56749],
            [-108.851097, 72.56749],
            [-108.888901, 72.597763],
            [-108.866898, 72.60582],
            [-108.621399, 72.549988],
            [-108.589401, 72.494431],
            [-108.637199, 72.444427],
            [-108.662804, 72.346367],
            [-108.5242, 72.199707],
            [-108.5364, 72.142212],
            [-108.404404, 72.146942],
            [-108.433296, 72.12886],
            [-108.398903, 72.113602],
            [-108.412201, 71.989967],
            [-108.2939, 71.976379],
            [-108.332802, 71.925812],
            [-108.186699, 71.955833],
            [-108.294998, 71.876083],
            [-108.241699, 71.835541],
            [-108.2808, 71.786926],
            [-108.165298, 71.754707],
            [-108.238098, 71.713882],
            [-108.066101, 71.717758],
            [-107.976097, 71.666382],
            [-107.982803, 71.633331],
            [-107.845299, 71.603867],
            [-107.724197, 71.623871],
            [-107.836403, 71.681664],
            [-107.804398, 71.726646],
            [-107.663902, 71.702477],
            [-107.535599, 71.779709],
            [-107.251999, 71.804428],
            [-107.470802, 71.870819],
            [-107.296997, 71.874153],
            [-107.257004, 71.900269],
            [-107.4133, 71.928307],
            [-107.422203, 71.974991],
            [-107.613899, 72.012497],
            [-107.653297, 72.066383],
            [-107.610001, 72.088593],
            [-107.660004, 72.113602],
            [-107.6353, 72.121918],
            [-107.7789, 72.136658],
            [-107.792198, 72.255257],
            [-107.864197, 72.298317],
            [-107.7136, 72.300812],
            [-107.843903, 72.354156],
            [-107.801903, 72.381927],
            [-107.877502, 72.424423],
            [-107.781403, 72.456383],
            [-107.980598, 72.511108],
            [-107.8769, 72.524429],
            [-107.876701, 72.567207],
            [-108.011902, 72.61998],
            [-107.954498, 72.63916],
            [-108.025299, 72.666092],
            [-108.005798, 72.702477],
            [-108.0439, 72.726379],
            [-108.079697, 72.748032],
            [-108.051102, 72.781372],
            [-108.145798, 72.87886],
            [-108.101402, 72.901093],
            [-108.169403, 72.956383],
            [-108.1353, 72.982483],
            [-108.232803, 73.033051],
            [-108.156097, 73.048599],
            [-108.300003, 73.135536],
            [-108.166397, 73.203873],
            [-107.864197, 73.188583],
            [-108.116402, 73.264427],
            [-108.1436, 73.30748],
            [-108.0131, 73.309143],
            [-108.071404, 73.353317],
            [-107.614403, 73.317207],
            [-107.069702, 73.173866],
            [-107.010803, 73.19136],
            [-107.123596, 73.240257],
            [-107.0839, 73.283333],
            [-106.861397, 73.310806],
            [-106.750801, 73.289146],
            [-106.6511, 73.222488],
            [-106.486702, 73.224991],
            [-106.463303, 73.179703],
            [-106.240303, 73.085823],
            [-106.047501, 73.044708],
            [-105.855598, 73.056931],
            [-105.824699, 73.045258],
            [-105.822197, 73.002487],
            [-105.608299, 72.944977],
            [-105.636703, 72.929153],
            [-105.525002, 72.875259],
            [-105.555, 72.86026],
            [-105.452797, 72.842758],
            [-105.363899, 72.785263],
            [-105.399399, 72.767212],
            [-105.321999, 72.741653],
            [-105.3778, 72.729431],
            [-105.510002, 72.776932],
            [-105.431702, 72.729156],
            [-105.457199, 72.702766],
            [-105.290802, 72.651657],
            [-105.3433, 72.617203],
            [-105.310799, 72.595261],
            [-105.318298, 72.55748],
            [-105.238098, 72.54776],
            [-105.2239, 72.512207],
            [-105.252197, 72.489967],
            [-105.195801, 72.482483],
            [-105.217796, 72.444977],
            [-105.301903, 72.455261],
            [-104.957497, 72.181374],
            [-105.037498, 72.118317],
            [-105.0186, 72.06694],
            [-104.954697, 72.057213],
            [-104.871902, 71.989967],
            [-104.790001, 71.84137],
            [-104.673302, 71.823318],
            [-104.355301, 71.574432],
            [-104.401901, 71.498322],
            [-104.319199, 71.462486],
            [-104.3442, 71.410812],
            [-104.277802, 71.351089],
            [-104.471397, 71.351089],
            [-104.496902, 71.322769],
            [-104.438904, 71.257767],
            [-104.448898, 71.218872],
            [-104.648598, 71.119141],
            [-104.414398, 70.999153],
            [-104.122803, 70.914703],
            [-103.994202, 70.752777],
            [-103.838898, 70.743317],
            [-103.556396, 70.600807],
            [-103.309196, 70.595261],
            [-103.121696, 70.501938],
            [-102.930603, 70.49054],
            [-102.932503, 70.5186],
            [-103.126099, 70.573883],
            [-103.1539, 70.665817],
            [-103.066704, 70.669983],
            [-102.848297, 70.593872],
            [-102.853897, 70.508331],
            [-102.377197, 70.412491],
            [-102.195, 70.324158],
            [-102.130798, 70.342758],
            [-101.893303, 70.25444],
            [-101.714699, 70.308868],
            [-101.5886, 70.271927],
            [-101.650597, 70.210823],
            [-101.551903, 70.109421],
            [-101.4133, 70.12442],
            [-101.375801, 70.177757],
            [-101.222198, 70.131927],
            [-101.135002, 70.159714],
            [-101.1119, 70.197197],
            [-100.973297, 70.155548],
            [-101.002502, 70.088882],
            [-100.926102, 70.021103],
            [-100.921898, 69.965271],
            [-100.961403, 69.928589],
            [-100.9039, 69.910538],
            [-100.870003, 69.814423],
            [-100.878098, 69.771652],
            [-100.948898, 69.733597],
            [-100.920303, 69.701103],
            [-100.957199, 69.662491],
            [-101.0644, 69.648613],
            [-101.155296, 69.688026],
            [-101.329697, 69.669983],
            [-101.473297, 69.832764],
            [-101.4161, 69.886932],
            [-101.445297, 69.909149],
            [-101.672501, 69.646103],
            [-101.766098, 69.721916],
            [-101.948601, 69.741364],
            [-102.029701, 69.801086],
            [-102.018303, 69.829163],
            [-102.050797, 69.85582],
            [-102.149696, 69.82666],
            [-102.168602, 69.887207],
            [-102.230301, 69.917213],
            [-102.257797, 69.889977],
            [-102.237198, 69.850807],
            [-102.340797, 69.835823],
            [-102.299202, 69.794434],
            [-102.349701, 69.756378],
            [-102.380302, 69.761383],
            [-102.364197, 69.795258],
            [-102.387802, 69.81192],
            [-102.402199, 69.77388],
            [-102.486702, 69.785812],
            [-102.576401, 69.737488],
            [-102.680603, 69.759987],
            [-102.592201, 69.693863],
            [-102.483597, 69.6922],
            [-102.507797, 69.564148],
            [-102.813004, 69.529709],
            [-103.186699, 69.629433],
            [-103.297501, 69.69693],
            [-103.486702, 69.684708],
            [-103.504204, 69.613037],
            [-103.216698, 69.551086],
            [-103.127502, 69.432213],
            [-103.095001, 69.452209],
            [-103.089203, 69.521927],
            [-103.0233, 69.493874],
            [-102.990799, 69.419434],
            [-103.033897, 69.359993],
            [-103.008301, 69.332207],
            [-103.023102, 69.271652],
            [-103.204498, 69.200821],
            [-103.208603, 69.122757],
            [-103.160599, 69.117752],
            [-102.840797, 69.383331],
            [-102.519203, 69.412201],
            [-102.460297, 69.482208],
            [-102.057503, 69.483597],
            [-101.931099, 69.407494],
            [-102.035797, 69.349991],
            [-102.160599, 69.354713],
            [-102.169998, 69.31749],
            [-102.1017, 69.334991],
            [-102.037201, 69.276382],
            [-102.0886, 69.231934],
            [-102.212799, 69.276382],
            [-102.217201, 69.225273],
            [-102.093597, 69.178589],
            [-102.036697, 69.231659],
            [-101.921898, 69.260269],
            [-101.751701, 69.160538],
            [-101.8675, 69.04248],
            [-101.808601, 68.998032],
            [-101.962799, 68.96666],
            [-102.062202, 68.996933],
            [-102.144997, 68.947662],
            [-102.392197, 68.953049],
            [-102.398399, 68.908867],
            [-102.535797, 68.864433],
            [-102.753098, 68.89415],
            [-102.699997, 68.839981],
            [-102.894699, 68.799988],
            [-103.005798, 68.795258],
            [-103.1642, 68.843048],
            [-103.320801, 68.829712],
            [-103.374199, 68.777481],
            [-103.5494, 68.82666],
            [-104.110298, 68.859421],
            [-104.240501, 68.896652],
            [-104.264999, 68.9422],
            [-104.303596, 68.930267],
            [-104.270798, 68.900543],
            [-104.457497, 68.936653],
            [-104.5028, 68.877762],
            [-104.583603, 68.859711],
            [-105.129204, 68.896378],
            [-105.2742, 68.948868],
            [-105.173302, 68.991928],
            [-105.0392, 68.990257],
            [-105.041397, 69.018883],
            [-104.936401, 69.030548],
            [-104.922997, 69.074707],
            [-105.083298, 69.106369],
            [-105.122803, 69.091087],
            [-105.035797, 69.05748],
            [-105.450798, 69.094994],
            [-105.616898, 69.160812],
            [-106.177803, 69.14415],
            [-106.406097, 69.180542],
            [-106.394402, 69.236923],
            [-106.267799, 69.292213],
            [-106.312202, 69.338318],
            [-106.310303, 69.40387],
            [-106.602203, 69.498871],
            [-106.733101, 69.44165],
            [-106.753304, 69.37915],
            [-106.985497, 69.3461],
            [-106.929199, 69.21666],
            [-107.037804, 69.185806],
            [-107.025803, 69.151382],
            [-107.1381, 69.150543],
            [-107.304199, 69.032494],
            [-107.955803, 68.931053],
            [-108.534401, 68.944702],
            [-108.563301, 68.933594],
            [-108.529701, 68.901382],
            [-108.551399, 68.879433],
            [-108.9375, 68.741089],
            [-109.400002, 68.693314],
            [-109.656403, 68.632751],
            [-110.220001, 68.62915],
            [-110.3778, 68.574158],
            [-110.4272, 68.608322],
            [-110.576698, 68.616653],
            [-110.950302, 68.551933],
            [-111.035004, 68.563873],
            [-110.860298, 68.587769],
            [-110.863602, 68.608597],
            [-111.379204, 68.584152],
            [-111.405296, 68.568047],
            [-111.209198, 68.549149],
            [-111.238098, 68.514427],
            [-111.600601, 68.543587],
            [-112.124397, 68.527771],
            [-112.612198, 68.502213],
            [-112.670303, 68.469147],
            [-113.251404, 68.452209],
            [-113.309402, 68.467209],
            [-113.291397, 68.489151],
            [-113.043602, 68.504173],
            [-113.357803, 68.59137],
            [-113.448303, 68.645264],
            [-113.4533, 68.700821],
            [-113.676903, 68.811096],
            [-113.617203, 68.845261],
            [-113.6772, 68.900818],
            [-113.569504, 68.957207],
            [-113.570801, 69.023323],
            [-113.639999, 69.032494],
            [-113.544998, 69.048027],
            [-113.657799, 69.091087],
            [-113.675598, 69.121643],
            [-113.638298, 69.138603],
            [-113.696098, 69.154709],
            [-113.6922, 69.189423],
            [-113.521103, 69.178589],
            [-114.311401, 69.284988],
            [-114.764198, 69.252213],
            [-114.991898, 69.280273],
            [-115.190804, 69.236923],
            [-115.499702, 69.256104],
            [-115.955803, 69.292213],
            [-116.525803, 69.407494],
            [-116.6297, 69.458878],
            [-116.561401, 69.488586],
            [-116.6092, 69.524429],
            [-116.581398, 69.560532],
            [-116.75, 69.574997],
            [-116.724701, 69.541656],
            [-116.910301, 69.590271],
            [-116.859908, 69.637114],
            [-116.513568, 69.634805],
            [-117.118097, 69.891887],
            [-117.085008, 69.999148],
            [-112.887108, 69.991098],
            [-112.862425, 69.840022],
            [-112.657705, 69.834517],
            [-112.614279, 69.896203],
            [-112.477477, 69.892389],
            [-112.48507, 69.993691],
            [-110.029392, 69.995491],
            [-110.019917, 72.442051],
          ],
        ],
        [
          [
            [-92.743797, 69.671951],
            [-92.920273, 69.669708],
            [-92.661667, 69.652771],
            [-92.349731, 69.701103],
            [-92.376389, 69.66748],
            [-92.357498, 69.658867],
            [-92.088333, 69.623032],
            [-92.300552, 69.641663],
            [-92.12471, 69.554977],
            [-91.803329, 69.483322],
            [-91.79834, 69.513893],
            [-91.712784, 69.535812],
            [-91.736938, 69.548317],
            [-91.590561, 69.578598],
            [-91.595001, 69.611099],
            [-91.475281, 69.664429],
            [-91.091949, 69.631653],
            [-91.219727, 69.617752],
            [-91.334442, 69.552757],
            [-91.553879, 69.505547],
            [-91.340843, 69.516663],
            [-91.192207, 69.562759],
            [-91.102783, 69.508881],
            [-90.88945, 69.534149],
            [-90.876938, 69.482483],
            [-90.758621, 69.482758],
            [-90.71611, 69.539429],
            [-90.493332, 69.504173],
            [-90.319458, 69.440536],
            [-90.555557, 69.422493],
            [-90.703613, 69.453598],
            [-90.58223, 69.416931],
            [-90.797234, 69.361366],
            [-90.809433, 69.253326],
            [-90.931381, 69.247482],
            [-90.896118, 69.2686],
            [-90.970001, 69.267212],
            [-90.920547, 69.299713],
            [-91.068893, 69.266098],
            [-91.100281, 69.281097],
            [-90.951683, 69.354713],
            [-91.194107, 69.288017],
            [-91.446663, 69.349716],
            [-90.664719, 69.083328],
            [-90.709167, 69.003883],
            [-90.604721, 68.978317],
            [-90.583893, 68.928864],
            [-90.629173, 68.915817],
            [-90.436394, 68.87442],
            [-90.481949, 68.856087],
            [-90.436111, 68.833054],
            [-90.593887, 68.805542],
            [-90.438889, 68.823318],
            [-90.473053, 68.799988],
            [-90.449158, 68.776093],
            [-90.507233, 68.779709],
            [-90.526672, 68.744431],
            [-90.479172, 68.705833],
            [-90.496948, 68.663879],
            [-90.563606, 68.624977],
            [-90.49527, 68.596649],
            [-90.538597, 68.565262],
            [-90.474442, 68.530823],
            [-90.606659, 68.446091],
            [-90.315002, 68.36998],
            [-90.477493, 68.33194],
            [-90.326401, 68.350273],
            [-90.280838, 68.243874],
            [-90.20723, 68.231087],
            [-90.118057, 68.262207],
            [-90.167503, 68.312477],
            [-90, 68.377037],
            [-89.904167, 68.451103],
            [-89.912216, 68.473312],
            [-89.800568, 68.539978],
            [-89.92778, 68.563599],
            [-89.949722, 68.607758],
            [-89.894173, 68.660812],
            [-89.80249, 68.712196],
            [-89.746948, 68.669708],
            [-89.766403, 68.621918],
            [-89.714172, 68.649147],
            [-89.729172, 68.699142],
            [-89.684723, 68.810257],
            [-89.737213, 68.831383],
            [-89.713058, 68.848602],
            [-89.756958, 68.939972],
            [-89.71666, 69.006104],
            [-89.48999, 69.110527],
            [-89.389999, 69.219147],
            [-89.114441, 69.275269],
            [-88.94249, 69.22998],
            [-88.87027, 69.148613],
            [-88.772507, 69.098877],
            [-88.208618, 68.911652],
            [-87.964447, 68.760536],
            [-87.916656, 68.656372],
            [-87.947769, 68.605263],
            [-87.789719, 68.391937],
            [-87.808884, 68.356934],
            [-87.787781, 68.327477],
            [-87.837509, 68.267212],
            [-87.935822, 68.195824],
            [-88.004997, 68.231369],
            [-88.16333, 68.231369],
            [-88.194153, 68.37442],
            [-88.392227, 68.287491],
            [-88.277496, 68.111649],
            [-88.381104, 68.025269],
            [-88.35556, 67.998871],
            [-88.370827, 67.959152],
            [-88.269173, 67.793587],
            [-88.162216, 67.736649],
            [-88.13945, 67.664429],
            [-87.955841, 67.607483],
            [-87.483887, 67.362488],
            [-87.448883, 67.261383],
            [-87.357773, 67.262207],
            [-87.505569, 67.185257],
            [-87.510277, 67.112198],
            [-87.42749, 67.170822],
            [-87.34111, 67.176376],
            [-87.304443, 67.099991],
            [-87.272507, 67.0961],
            [-87.229446, 67.22554],
            [-87.154167, 67.239967],
            [-87.106659, 67.179153],
            [-87.075844, 67.253601],
            [-86.963058, 67.250549],
            [-87.006958, 67.277206],
            [-86.994164, 67.302467],
            [-87.089996, 67.350807],
            [-86.974442, 67.316673],
            [-86.961403, 67.360527],
            [-86.874443, 67.404984],
            [-86.779167, 67.422211],
            [-86.647232, 67.358322],
            [-86.610817, 67.402481],
            [-86.592499, 67.345261],
            [-86.531952, 67.346649],
            [-86.503067, 67.377762],
            [-86.531952, 67.439972],
            [-86.473892, 67.468597],
            [-86.496948, 67.501663],
            [-86.451401, 67.597488],
            [-86.525284, 67.681374],
            [-86.364723, 67.822769],
            [-86.12944, 67.949997],
            [-86.127213, 68.013611],
            [-86.084732, 67.976929],
            [-85.892502, 68.051376],
            [-85.914436, 68.104156],
            [-85.858337, 68.137207],
            [-85.888062, 68.189697],
            [-85.784729, 68.216377],
            [-85.842499, 68.23027],
            [-85.805557, 68.294708],
            [-85.842773, 68.317207],
            [-85.712784, 68.397217],
            [-85.745003, 68.434708],
            [-85.66777, 68.448318],
            [-85.742493, 68.4711],
            [-85.7164, 68.501663],
            [-85.747498, 68.622482],
            [-85.645844, 68.737488],
            [-85.481949, 68.739433],
            [-85.464722, 68.75972],
            [-85.562767, 68.776657],
            [-85.514183, 68.783867],
            [-85.228882, 68.710274],
            [-85.262512, 68.751663],
            [-85.224167, 68.758331],
            [-84.775284, 68.737198],
            [-84.754181, 68.772217],
            [-84.834732, 68.820541],
            [-85.031677, 68.810806],
            [-85.192757, 68.862198],
            [-85.005569, 68.877472],
            [-85.069458, 68.906097],
            [-84.997498, 68.925812],
            [-85.116943, 68.933319],
            [-85.140556, 68.94664],
            [-85.115013, 68.958603],
            [-84.796387, 68.933594],
            [-84.986923, 68.999512],
            [-84.834732, 69.008041],
            [-84.818336, 69.032211],
            [-84.591667, 68.994431],
            [-84.528343, 69.017487],
            [-84.736107, 69.035263],
            [-84.769447, 69.083054],
            [-84.946663, 69.081383],
            [-84.963898, 69.1147],
            [-85.02417, 69.11499],
            [-84.968613, 69.136932],
            [-84.998611, 69.14444],
            [-85.123047, 69.11554],
            [-85.138901, 69.129433],
            [-85.101097, 69.154709],
            [-84.99472, 69.173027],
            [-85.172501, 69.169983],
            [-85.223618, 69.12886],
            [-85.315552, 69.148041],
            [-85.203339, 69.180542],
            [-85.383057, 69.205551],
            [-85.503067, 69.314423],
            [-85.345284, 69.306931],
            [-85.508057, 69.395264],
            [-85.383331, 69.415268],
            [-85.410278, 69.443314],
            [-85.339172, 69.438873],
            [-85.422783, 69.462486],
            [-85.491379, 69.436653],
            [-85.542503, 69.476379],
            [-85.479446, 69.562477],
            [-85.38028, 69.56694],
            [-85.544159, 69.637772],
            [-85.526672, 69.67276],
            [-85.438599, 69.701103],
            [-85.441383, 69.739151],
            [-85.514717, 69.768051],
            [-85.409157, 69.788589],
            [-85.389183, 69.780548],
            [-85.410828, 69.755257],
            [-85.379173, 69.753601],
            [-85.333069, 69.774147],
            [-85.344452, 69.813026],
            [-85.586403, 69.826927],
            [-85.561943, 69.859421],
            [-85.377213, 69.851089],
            [-85.064438, 69.769989],
            [-85.049438, 69.799713],
            [-84.871658, 69.816093],
            [-84.854172, 69.831383],
            [-84.882492, 69.855553],
            [-84.75, 69.832764],
            [-84.433609, 69.861099],
            [-83.733887, 69.70665],
            [-83.34639, 69.676086],
            [-83.253342, 69.705261],
            [-83.008057, 69.679153],
            [-82.669998, 69.67804],
            [-82.678329, 69.701103],
            [-82.25473, 69.636383],
            [-82.407501, 69.608597],
            [-82.600281, 69.64415],
            [-82.597778, 69.621643],
            [-82.652786, 69.618317],
            [-82.550552, 69.588043],
            [-82.743332, 69.577477],
            [-82.612503, 69.56694],
            [-82.500557, 69.492203],
            [-83.241096, 69.556641],
            [-83.293877, 69.534416],
            [-82.785278, 69.494141],
            [-82.237213, 69.400818],
            [-82.391678, 69.398613],
            [-82.14389, 69.300262],
            [-82.251106, 69.287773],
            [-82.226936, 69.26416],
            [-82.291382, 69.246643],
            [-82.258621, 69.233871],
            [-82.041107, 69.242752],
            [-82.011673, 69.273613],
            [-81.693878, 69.263046],
            [-81.497772, 69.197746],
            [-81.332497, 69.18248],
            [-81.275009, 69.086647],
            [-81.462784, 69.072769],
            [-81.570282, 68.992477],
            [-81.691673, 68.988586],
            [-81.71666, 68.949142],
            [-82.05722, 68.873871],
            [-81.67305, 68.904427],
            [-81.588058, 68.869713],
            [-81.569458, 68.827209],
            [-81.490547, 68.866089],
            [-81.382767, 68.866653],
            [-81.234444, 68.769989],
            [-81.267776, 68.74498],
            [-81.255836, 68.643051],
            [-81.633904, 68.508041],
            [-81.810547, 68.523323],
            [-81.79361, 68.50444],
            [-81.830292, 68.459717],
            [-81.97084, 68.421097],
            [-82.024437, 68.43692],
            [-82.077499, 68.503601],
            [-82.256393, 68.5336],
            [-82.173317, 68.485809],
            [-82.228058, 68.455261],
            [-82.608887, 68.517487],
            [-82.635277, 68.492203],
            [-82.491096, 68.453873],
            [-82.648064, 68.445251],
            [-82.644447, 68.417213],
            [-82.501678, 68.405823],
            [-82.363052, 68.35054],
            [-82.369453, 68.318329],
            [-82.507782, 68.313309],
            [-82.426102, 68.276657],
            [-82.264717, 68.284416],
            [-82.270279, 68.235527],
            [-82.345551, 68.170258],
            [-82.314438, 68.146652],
            [-82.179169, 68.111649],
            [-82.047501, 68.209717],
            [-81.988892, 68.20665],
            [-82.027222, 68.122757],
            [-82.120537, 68.105553],
            [-82.079453, 68.082489],
            [-82.173889, 68.002487],
            [-82.102783, 67.907211],
            [-81.936661, 67.823318],
            [-81.804443, 67.798317],
            [-81.828339, 67.774147],
            [-81.43306, 67.611366],
            [-81.243057, 67.474991],
            [-81.239166, 67.436096],
            [-81.304718, 67.386108],
            [-81.347778, 67.292763],
            [-81.38028, 67.170532],
            [-81.43222, 67.066673],
            [-81.495003, 67.053307],
            [-81.492493, 67.00972],
            [-81.52417, 66.99054],
            [-81.626099, 67.005257],
            [-81.711937, 66.969994],
            [-81.833618, 66.997757],
            [-81.988327, 66.959427],
            [-82.042503, 66.918053],
            [-82.018341, 66.89444],
            [-82.090561, 66.857208],
            [-82.077499, 66.828323],
            [-82.183884, 66.76416],
            [-82.156631, 66.709518],
            [-82.36972, 66.725807],
            [-82.376099, 66.696373],
            [-82.556381, 66.623871],
            [-82.596947, 66.560257],
            [-83.017227, 66.546944],
            [-83.015289, 66.506104],
            [-83.058609, 66.470261],
            [-83.368332, 66.348877],
            [-83.573624, 66.343597],
            [-83.567497, 66.367477],
            [-83.652786, 66.407761],
            [-83.63945, 66.437187],
            [-83.529167, 66.394707],
            [-83.672501, 66.520538],
            [-83.857498, 66.544144],
            [-83.900284, 66.5961],
            [-83.977493, 66.582207],
            [-84.01445, 66.692749],
            [-83.919724, 66.697746],
            [-83.900833, 66.644707],
            [-83.85611, 66.680817],
            [-83.955276, 66.747757],
            [-83.886124, 66.813873],
            [-83.907784, 66.876648],
            [-83.945541, 66.813599],
            [-84.098343, 66.747482],
            [-84.105003, 66.708328],
            [-84.260277, 66.716377],
            [-84.314438, 66.751099],
            [-84.268623, 66.785812],
            [-84.436394, 66.818329],
            [-84.239723, 66.833878],
            [-84.382492, 66.8647],
            [-84.420273, 66.901932],
            [-84.38945, 66.923866],
            [-84.584061, 66.978333],
            [-84.370827, 66.971367],
            [-84.926941, 67.059418],
            [-84.905273, 67.03804],
            [-84.935272, 67.029709],
            [-84.839722, 67.003883],
            [-84.871658, 66.984993],
            [-84.765556, 67.006378],
            [-84.639717, 66.978043],
            [-85.04834, 66.963318],
            [-85.212219, 66.913307],
            [-85.146957, 66.904709],
            [-85.228882, 66.873306],
            [-85.120003, 66.835541],
            [-84.940277, 66.863876],
            [-84.900833, 66.898041],
            [-84.993057, 66.923027],
            [-84.767776, 66.952209],
            [-84.602219, 66.935806],
            [-84.621109, 66.915268],
            [-84.56221, 66.901382],
            [-84.746384, 66.897491],
            [-84.50473, 66.823036],
            [-84.690552, 66.839981],
            [-84.319733, 66.774696],
            [-84.44249, 66.710823],
            [-84.324448, 66.676933],
            [-84.144447, 66.681374],
            [-84.146118, 66.642212],
            [-84.215012, 66.589432],
            [-83.916397, 66.44664],
            [-83.871109, 66.394989],
            [-83.864166, 66.312477],
            [-83.783333, 66.299149],
            [-83.678596, 66.201103],
            [-83.847778, 66.15332],
            [-83.786667, 66.197746],
            [-83.801102, 66.21666],
            [-83.964722, 66.195251],
            [-84.2164, 66.321663],
            [-84.347229, 66.276932],
            [-84.392227, 66.291367],
            [-84.426941, 66.363037],
            [-84.528877, 66.40416],
            [-84.636124, 66.328873],
            [-84.401398, 66.211113],
            [-84.37471, 66.1586],
            [-84.460564, 66.159149],
            [-84.565552, 66.204987],
            [-84.761124, 66.213318],
            [-84.869164, 66.266663],
            [-84.979721, 66.246643],
            [-85.078888, 66.277771],
            [-85.117767, 66.324707],
            [-85.178329, 66.262207],
            [-85.252228, 66.273041],
            [-85.306664, 66.314148],
            [-85.293327, 66.349152],
            [-85.338058, 66.39415],
            [-85.343338, 66.486923],
            [-85.479721, 66.581383],
            [-85.845284, 66.49942],
            [-86.128326, 66.498032],
            [-86.333618, 66.549988],
            [-86.406952, 66.514427],
            [-86.581123, 66.555817],
            [-86.580002, 66.520538],
            [-86.622772, 66.506653],
            [-86.780838, 66.520828],
            [-86.641678, 66.431931],
            [-86.811111, 66.441933],
            [-86.638344, 66.315262],
            [-86.332779, 66.282761],
            [-85.897781, 66.163307],
            [-86.014183, 66.100273],
            [-85.977493, 66.077766],
            [-85.980003, 66.028587],
            [-86.204727, 65.982758],
            [-86.239166, 65.936653],
            [-86.326401, 65.904984],
            [-86.421387, 65.902206],
            [-86.496948, 65.808029],
            [-86.455276, 65.786102],
            [-86.451111, 65.747208],
            [-86.554993, 65.664703],
            [-86.679169, 65.647217],
            [-86.829727, 65.555817],
            [-86.987213, 65.534149],
            [-86.975563, 65.515266],
            [-87.024437, 65.481659],
            [-87.110283, 65.458603],
            [-87.115013, 65.433594],
            [-87.059433, 65.414146],
            [-87.100563, 65.391937],
            [-87.372772, 65.322769],
            [-87.96666, 65.333054],
            [-88.212784, 65.402771],
            [-88.333618, 65.492477],
            [-88.559723, 65.586929],
            [-88.829727, 65.641373],
            [-88.411942, 65.630257],
            [-88.85083, 65.701393],
            [-88.93721, 65.685806],
            [-89.124443, 65.733322],
            [-89.174438, 65.778587],
            [-89.428329, 65.867203],
            [-89.516113, 65.858032],
            [-89.525284, 65.886932],
            [-89.671661, 65.939423],
            [-90, 65.874771],
            [-90.404449, 65.871094],
            [-90.432503, 65.882751],
            [-90.393066, 65.896103],
            [-90.225563, 65.900543],
            [-90.234734, 65.922493],
            [-90.596123, 65.896652],
            [-91.316391, 65.969994],
            [-91.496948, 65.945824],
            [-91.020554, 65.806091],
            [-91.123047, 65.904709],
            [-91.088058, 65.921097],
            [-90.545273, 65.883331],
            [-90, 65.796883],
            [-89.462509, 65.600807],
            [-89.40361, 65.506653],
            [-89.170837, 65.428307],
            [-89.054443, 65.328873],
            [-88.061661, 65.258881],
            [-87.67305, 65.291367],
            [-87.357773, 65.270828],
            [-87.051392, 65.236366],
            [-87.030289, 65.223038],
            [-87.051941, 65.199997],
            [-86.933319, 65.138046],
            [-86.997223, 65.096649],
            [-86.970284, 65.054428],
            [-87.110001, 64.999153],
            [-87.078339, 64.976646],
            [-87.096657, 64.950821],
            [-87.236389, 64.88472],
            [-87.303596, 64.806641],
            [-87.294449, 64.763046],
            [-87.451401, 64.707489],
            [-87.489441, 64.741089],
            [-87.525284, 64.714157],
            [-87.511398, 64.636658],
            [-87.579178, 64.568047],
            [-87.80722, 64.514427],
            [-87.888062, 64.338593],
            [-88.044724, 64.247482],
            [-88.05249, 64.227203],
            [-87.983887, 64.191093],
            [-88.113327, 64.136108],
            [-88.227493, 64.140266],
            [-88.736656, 63.968319],
            [-88.856949, 63.977489],
            [-88.898621, 64.012207],
            [-89.000839, 63.988041],
            [-88.995827, 64.008331],
            [-89.150833, 64.059418],
            [-89.260834, 64.160538],
            [-89.286392, 64.136658],
            [-89.182503, 64.036652],
            [-89.096947, 63.963879],
            [-89.023621, 63.950272],
            [-89.14389, 63.95055],
            [-89.273064, 64.014709],
            [-89.246658, 63.959721],
            [-89.363892, 64.037491],
            [-89.444717, 64.021652],
            [-89.551102, 64.077477],
            [-89.563316, 64.00943],
            [-89.48555, 63.942211],
            [-89.644173, 64.053864],
            [-89.712219, 64.079437],
            [-89.697487, 64.042213],
            [-89.720551, 64.033333],
            [-89.818069, 64.098602],
            [-89.809723, 64.12886],
            [-89.735817, 64.145264],
            [-89.773064, 64.240807],
            [-89.812767, 64.193863],
            [-89.883904, 64.200546],
            [-89.816391, 64.17276],
            [-89.823624, 64.14444],
            [-90.121422, 64.127861],
            [-89.914719, 64.106934],
            [-89.946114, 64.05748],
            [-89.848053, 64.021927],
            [-89.915283, 64.015823],
            [-89.913887, 63.99332],
            [-89.831123, 63.979988],
            [-89.813606, 63.93721],
            [-89.989441, 63.92194],
            [-89.946663, 63.965271],
            [-90, 63.984039],
            [-90.093887, 63.95055],
            [-90.182503, 64.008614],
            [-90.279167, 64.003601],
            [-90.090561, 63.92416],
            [-90.122223, 63.915272],
            [-90.10083, 63.88361],
            [-90, 63.773899],
            [-90.115829, 63.777489],
            [-90.05777, 63.744438],
            [-90.16861, 63.65694],
            [-90.148903, 63.629162],
            [-90.205276, 63.612209],
            [-90.281387, 63.613049],
            [-90.218887, 63.635551],
            [-90.289169, 63.64777],
            [-90.452217, 63.616661],
            [-90.486389, 63.63221],
            [-90.465012, 63.648048],
            [-90.539993, 63.655819],
            [-90.488052, 63.672489],
            [-90.623894, 63.704159],
            [-90.699158, 63.65749],
            [-90.590286, 63.676659],
            [-90.555557, 63.65527],
            [-90.551392, 63.601391],
            [-90.930557, 63.564159],
            [-91.063316, 63.62027],
            [-91.375816, 63.659161],
            [-91.393623, 63.685261],
            [-91.329453, 63.677769],
            [-91.411667, 63.707211],
            [-91.667221, 63.719711],
            [-91.654167, 63.750832],
            [-91.693878, 63.774712],
            [-91.751106, 63.733879],
            [-91.910553, 63.740551],
            [-91.986107, 63.776379],
            [-91.88945, 63.80138],
            [-91.984734, 63.81916],
            [-92.045547, 63.74527],
            [-92.136124, 63.745541],
            [-92.376389, 63.77721],
            [-92.506393, 63.83194],
            [-92.706947, 63.846661],
            [-92.761398, 63.890549],
            [-93.436661, 64.015266],
            [-93.612213, 64.093048],
            [-93.635834, 64.120247],
            [-93.61528, 64.157761],
            [-93.773064, 64.193588],
            [-93.66861, 64.126923],
            [-93.660004, 64.083054],
            [-93.548889, 64.024696],
            [-93.602219, 64.007492],
            [-93.547501, 64.003883],
            [-93.58168, 63.984989],
            [-93.76445, 63.988331],
            [-93.770554, 63.957771],
            [-93.699432, 63.95166],
            [-93.649727, 63.877209],
            [-93.339996, 63.808331],
            [-93.378883, 63.84277],
            [-93.21611, 63.843609],
            [-93.343338, 63.863049],
            [-93.448883, 63.925831],
            [-93.424438, 63.937771],
            [-93.451111, 63.959721],
            [-93.364723, 63.967491],
            [-93.226257, 63.908489],
            [-92.756958, 63.82555],
            [-92.622498, 63.77166],
            [-92.49527, 63.814709],
            [-92.395554, 63.736938],
            [-92.251106, 63.740551],
            [-92.103882, 63.70166],
            [-92.198608, 63.67749],
            [-92.206947, 63.651661],
            [-92.171944, 63.640549],
            [-92.284729, 63.62138],
            [-92.337784, 63.644711],
            [-92.38501, 63.592491],
            [-92.539436, 63.55582],
            [-92.471939, 63.523319],
            [-92.320557, 63.572769],
            [-92.230293, 63.534721],
            [-92.193878, 63.615269],
            [-91.776108, 63.715832],
            [-91.617493, 63.64888],
            [-91.60083, 63.580551],
            [-91.387222, 63.521099],
            [-91.388901, 63.485271],
            [-91.27417, 63.502491],
            [-91.065277, 63.449162],
            [-91.016403, 63.46693],
            [-90.786392, 63.39555],
            [-90.975014, 63.419441],
            [-90.688599, 63.355],
            [-90.681107, 63.331108],
            [-90.741669, 63.324169],
            [-90.740829, 63.29472],
            [-90.698608, 63.2761],
            [-90.723328, 63.249722],
            [-90.684433, 63.21888],
            [-90.730293, 63.178051],
            [-90.672501, 63.157768],
            [-90.690552, 63.11832],
            [-90.651947, 63.087769],
            [-90.689987, 63.076099],
            [-90.627487, 63.059429],
            [-90.703056, 63.035],
            [-90.720284, 63.011669],
            [-90.689713, 63.006939],
            [-90.756958, 62.99332],
            [-90.72084, 62.967209],
            [-90.783073, 62.961109],
            [-90.785004, 62.938042],
            [-91.04805, 62.942211],
            [-91.182503, 62.905819],
            [-91.213333, 62.85416],
            [-91.410828, 62.819721],
            [-91.356377, 62.788601],
            [-92.121933, 62.874161],
            [-92.09639, 62.798882],
            [-92.339996, 62.843609],
            [-92.455276, 62.814709],
            [-92.333618, 62.709991],
            [-92.219162, 62.684429],
            [-92.286942, 62.676941],
            [-91.948883, 62.651932],
            [-91.880547, 62.619438],
            [-91.948036, 62.53138],
            [-92.053329, 62.526661],
            [-92.174713, 62.54472],
            [-92.143341, 62.579159],
            [-92.16333, 62.60083],
            [-92.266113, 62.595268],
            [-92.245537, 62.564709],
            [-92.321671, 62.56916],
            [-92.325562, 62.540829],
            [-92.384743, 62.529991],
            [-92.483063, 62.552769],
            [-92.47583, 62.595268],
            [-92.519173, 62.571659],
            [-92.615829, 62.614159],
            [-92.539169, 62.53249],
            [-92.563889, 62.4986],
            [-92.498047, 62.508331],
            [-92.541382, 62.476379],
            [-92.529716, 62.44305],
            [-92.710007, 62.465832],
            [-92.730827, 62.4286],
            [-92.681953, 62.374439],
            [-92.822777, 62.349159],
            [-92.598053, 62.310822],
            [-92.601669, 62.265549],
            [-92.633904, 62.25388],
            [-92.470016, 62.14661],
            [-92.603882, 62.15638],
            [-92.624443, 62.210548],
            [-92.747498, 62.289989],
            [-93.144974, 62.337261],
            [-92.780289, 62.236938],
            [-92.790283, 62.17749],
            [-93.001404, 62.200272],
            [-92.981667, 62.171101],
            [-93.110001, 62.156651],
            [-93.126938, 62.132488],
            [-93.08139, 62.104439],
            [-93.019173, 62.126099],
            [-92.931381, 62.10416],
            [-92.991096, 62.067768],
            [-93.067497, 62.076939],
            [-93.078613, 62.035831],
            [-93.156113, 62.049721],
            [-93.162216, 61.97887],
            [-93.30278, 62.049438],
            [-93.329453, 62.047779],
            [-93.294724, 62.018879],
            [-93.411392, 62.03138],
            [-93.218338, 61.952492],
            [-93.388344, 61.94582],
            [-93.305267, 61.920551],
            [-93.279716, 61.894711],
            [-93.299728, 61.88583],
            [-93.619453, 61.948601],
            [-93.491669, 61.884159],
            [-93.600281, 61.879162],
            [-93.613617, 61.852489],
            [-93.362289, 61.821121],
            [-93.434998, 61.803879],
            [-93.248611, 61.784721],
            [-93.255569, 61.742489],
            [-93.468887, 61.764999],
            [-93.412781, 61.72332],
            [-93.313316, 61.725269],
            [-93.647781, 61.634159],
            [-93.780838, 61.551929],
            [-93.830841, 61.571659],
            [-93.874161, 61.513889],
            [-93.984734, 61.4561],
            [-93.938599, 61.408878],
            [-93.968613, 61.39666],
            [-93.868057, 61.38916],
            [-93.819168, 61.34721],
            [-93.940552, 61.294998],
            [-94.110817, 61.322769],
            [-93.998611, 61.227772],
            [-94.098892, 61.162209],
            [-94.039436, 61.15694],
            [-94.064438, 61.110279],
            [-93.980293, 61.0961],
            [-94.128601, 61.06554],
            [-94.233887, 60.904709],
            [-94.349167, 60.858601],
            [-94.415283, 60.762211],
            [-94.404999, 60.709721],
            [-94.484444, 60.64941],
            [-94.509453, 60.544159],
            [-94.575844, 60.520271],
            [-94.732773, 60.53944],
            [-94.652496, 60.492489],
            [-94.796951, 60.497219],
            [-94.68277, 60.47443],
            [-94.613892, 60.38055],
            [-94.706673, 60.260551],
            [-94.673607, 60.191101],
            [-94.720551, 60.138329],
            [-94.707497, 60.083599],
            [-94.766403, 60.061378],
            [-94.758621, 60.017769],
            [-94.800491, 59.999569],
            [-99.626953, 59.999729],
            [-100.039001, 59.999722],
            [-102.001701, 59.99992],
            [-101.984001, 64.21341],
            [-109.387397, 64.826919],
            [-110.651709, 65.499518],
            [-112.498556, 65.506191],
            [-120.658461, 68.001268],
            [-120.664292, 69.542782],
            [-120.66524, 69.551327],
            [-120.393303, 69.439697],
            [-119.982201, 69.344711],
            [-118.840599, 69.250549],
            [-118.644699, 69.212486],
            [-118.457802, 69.117477],
            [-117.870499, 68.985527],
            [-117.381897, 68.951927],
            [-117.1539, 68.885536],
            [-116.9664, 68.902206],
            [-117.0103, 68.936371],
            [-116.950798, 68.939972],
            [-116.847198, 68.883331],
            [-116.744499, 68.880539],
            [-116.500298, 68.857208],
            [-116.381401, 68.882477],
            [-116.2136, 68.830551],
            [-115.959999, 68.804703],
            [-116.116898, 68.861923],
            [-116.082497, 68.882751],
            [-116.325302, 68.961929],
            [-116.205002, 68.984993],
            [-115.8675, 68.92276],
            [-115.766998, 68.939148],
            [-115.935303, 69.006943],
            [-115.593102, 68.971649],
            [-115.094498, 68.85054],
            [-114.989998, 68.862762],
            [-114.791901, 68.799423],
            [-114.729401, 68.744431],
            [-114.448303, 68.689697],
            [-114.465302, 68.664429],
            [-114.404701, 68.61499],
            [-114.187798, 68.565536],
            [-114.170799, 68.528587],
            [-114.088898, 68.496368],
            [-114.057503, 68.433868],
            [-114.123901, 68.430267],
            [-114.095001, 68.402481],
            [-114.006699, 68.393883],
            [-113.974701, 68.435532],
            [-113.922798, 68.408867],
            [-113.912201, 68.38916],
            [-114.0467, 68.311653],
            [-114.0233, 68.241364],
            [-114.207199, 68.253883],
            [-114.32, 68.229156],
            [-114.441704, 68.263611],
            [-114.702499, 68.250267],
            [-114.959999, 68.285812],
            [-114.764702, 68.186096],
            [-114.8964, 68.146942],
            [-115.237198, 68.182213],
            [-115.172798, 68.128593],
            [-115.2267, 68.089706],
            [-115.240501, 68.036377],
            [-115.110497, 68.012207],
            [-115.542503, 67.921654],
            [-115.507202, 67.889427],
            [-115.253098, 67.883041],
            [-115.2761, 67.861649],
            [-115.113602, 67.798599],
            [-114.718903, 67.813309],
            [-114.608597, 67.771378],
            [-114.516701, 67.789429],
            [-114.297798, 67.718597],
            [-114.148399, 67.736923],
            [-113.845802, 67.69136],
            [-113.240799, 67.704437],
            [-113.050003, 67.666092],
            [-112.709396, 67.667763],
            [-112.370003, 67.681931],
            [-112.333603, 67.696373],
            [-112.368599, 67.728317],
            [-112.345299, 67.7397],
            [-112.011101, 67.742477],
            [-111.985497, 67.693039],
            [-111.8936, 67.679428],
            [-111.871101, 67.6922],
            [-111.929199, 67.718323],
            [-111.912804, 67.754173],
            [-111.538902, 67.712486],
            [-111.5728, 67.744431],
            [-111.5317, 67.765549],
            [-111.458603, 67.763046],
            [-111.474197, 67.735527],
            [-111.441101, 67.731934],
            [-111.322502, 67.806931],
            [-111.175797, 67.837486],
            [-111.143898, 67.828873],
            [-111.205597, 67.794144],
            [-111.1689, 67.784416],
            [-111.2006, 67.755547],
            [-111.085297, 67.786652],
            [-111.005798, 67.764999],
            [-110.840599, 67.800262],
            [-110.732498, 67.864433],
            [-110.329201, 67.945251],
            [-110.339996, 67.965553],
            [-110.139702, 67.916656],
            [-110.201698, 67.966087],
            [-110.116898, 68.008881],
            [-109.970802, 67.958038],
            [-110.003601, 67.866379],
            [-109.982498, 67.837196],
            [-109.912498, 67.845833],
            [-109.953102, 67.881927],
            [-109.890297, 67.879967],
            [-109.695801, 67.804153],
            [-109.744202, 67.775543],
            [-109.623001, 67.74054],
            [-109.735802, 67.742752],
            [-109.726097, 67.716927],
            [-109.522499, 67.687477],
            [-109.485497, 67.709991],
            [-109.506104, 67.733322],
            [-109.395302, 67.767487],
            [-109.3703, 67.729156],
            [-109.065804, 67.714157],
            [-108.919197, 67.535812],
            [-109.025299, 67.483322],
            [-109.002197, 67.443588],
            [-108.928596, 67.432747],
            [-108.929703, 67.405823],
            [-108.858902, 67.408867],
            [-108.812798, 67.348877],
            [-108.744499, 67.445534],
            [-108.736397, 67.603043],
            [-108.620499, 67.624687],
            [-108.578903, 67.605553],
            [-108.587196, 67.577209],
            [-108.511398, 67.487488],
            [-108.522797, 67.440811],
            [-108.4842, 67.349426],
            [-108.424698, 67.364151],
            [-108.440002, 67.427467],
            [-108.366898, 67.444977],
            [-108.015297, 67.293053],
            [-107.875, 67.140823],
            [-107.914398, 67.132477],
            [-107.880501, 67.048317],
            [-108.148598, 67.07666],
            [-108.232803, 67.01915],
            [-108.622803, 67.149986],
            [-108.516098, 67.034416],
            [-108.4561, 67.070541],
            [-108.330597, 66.986923],
            [-108.196999, 66.972214],
            [-108.1147, 66.928864],
            [-108.118103, 66.862198],
            [-108.232498, 66.845543],
            [-107.991096, 66.832764],
            [-107.939697, 66.766663],
            [-107.949402, 66.720543],
            [-107.882797, 66.663307],
            [-107.851402, 66.73027],
            [-107.888298, 66.755547],
            [-107.853302, 66.756378],
            [-107.691101, 66.642212],
            [-107.724197, 66.6297],
            [-107.647499, 66.574707],
            [-107.569504, 66.530548],
            [-107.5103, 66.542213],
            [-107.446404, 66.461647],
            [-107.222198, 66.349152],
            [-107.195801, 66.366379],
            [-107.235497, 66.407494],
            [-107.550797, 66.582764],
            [-107.649399, 66.693863],
            [-107.797203, 66.756104],
            [-107.6903, 66.767761],
            [-107.769402, 66.945534],
            [-107.764198, 67.001663],
            [-107.613297, 66.964996],
            [-107.650803, 67.076103],
            [-107.523903, 67.020264],
            [-107.484703, 66.918587],
            [-107.693604, 66.94664],
            [-107.629402, 66.888046],
            [-107.532501, 66.874153],
            [-107.5644, 66.833603],
            [-107.408897, 66.811096],
            [-107.486397, 66.877197],
            [-107.393898, 66.885269],
            [-107.435303, 66.972214],
            [-107.083603, 66.820541],
            [-107.2267, 66.961647],
            [-107.4039, 67.041656],
            [-107.250801, 67.07193],
            [-107.199699, 67.123871],
            [-107.4814, 67.180817],
            [-107.721901, 67.410812],
            [-107.635597, 67.402206],
            [-107.582802, 67.496368],
            [-108.007004, 67.707489],
            [-108.013901, 67.756104],
            [-107.941902, 67.846939],
            [-107.714699, 67.892487],
            [-107.6511, 67.940536],
            [-107.910301, 67.988586],
            [-107.890602, 68.081673],
            [-107.8517, 68.106087],
            [-107.693604, 68.071663],
            [-107.809402, 68.051651],
            [-107.739998, 68.03804],
            [-107.833298, 68.013046],
            [-107.778099, 67.986649],
            [-107.68, 68.045822],
            [-107.349403, 68.048599],
            [-107.154404, 68.126923],
            [-107.110298, 68.126923],
            [-107.118896, 68.084717],
            [-106.958603, 68.089432],
            [-106.952797, 68.1147],
            [-106.756401, 68.10498],
            [-106.753098, 68.157761],
            [-106.809402, 68.207207],
            [-106.779404, 68.223602],
            [-106.6847, 68.223038],
            [-106.703903, 68.194427],
            [-106.663597, 68.169434],
            [-106.625504, 68.184418],
            [-106.641098, 68.23082],
            [-106.614403, 68.248032],
            [-106.468597, 68.190536],
            [-106.444702, 68.151657],
            [-106.344704, 68.183319],
            [-106.495796, 68.22554],
            [-106.4244, 68.299988],
            [-106.466904, 68.324707],
            [-106.447502, 68.344711],
            [-106.2183, 68.392487],
            [-106.124199, 68.362198],
            [-106.055801, 68.398613],
            [-105.981697, 68.393883],
            [-105.966103, 68.421654],
            [-105.845802, 68.415268],
            [-105.821999, 68.449997],
            [-105.782204, 68.413879],
            [-105.730301, 68.419144],
            [-105.698303, 68.491928],
            [-105.7742, 68.548317],
            [-105.666397, 68.638603],
            [-105.790604, 68.609993],
            [-105.886703, 68.63472],
            [-106.029404, 68.619713],
            [-106.551102, 68.514999],
            [-106.419403, 68.510269],
            [-106.556702, 68.493317],
            [-106.505302, 68.471367],
            [-106.528603, 68.443039],
            [-106.631699, 68.461929],
            [-106.480598, 68.395538],
            [-106.5644, 68.342758],
            [-106.5261, 68.306374],
            [-106.543297, 68.291367],
            [-106.595001, 68.296654],
            [-106.644402, 68.347488],
            [-106.724403, 68.349716],
            [-106.713097, 68.371368],
            [-106.802803, 68.414429],
            [-106.796097, 68.370529],
            [-107.013603, 68.369431],
            [-107.131897, 68.272491],
            [-107.258904, 68.259987],
            [-107.338097, 68.315811],
            [-107.478897, 68.31694],
            [-107.5467, 68.347488],
            [-107.813301, 68.342484],
            [-107.8797, 68.258881],
            [-107.603104, 68.165268],
            [-107.800301, 68.183319],
            [-107.967796, 68.145538],
            [-108.1614, 68.17276],
            [-108.3703, 68.112762],
            [-108.436096, 68.141373],
            [-108.397499, 68.163597],
            [-108.362801, 68.156372],
            [-108.378304, 68.13443],
            [-108.3386, 68.145828],
            [-108.347504, 68.226929],
            [-108.392502, 68.218872],
            [-108.391998, 68.180267],
            [-108.455002, 68.190536],
            [-108.402199, 68.294144],
            [-108.484398, 68.305252],
            [-108.715599, 68.231369],
            [-108.819199, 68.266663],
            [-108.741699, 68.294983],
            [-108.741096, 68.342758],
            [-108.5811, 68.406372],
            [-108.345299, 68.601929],
            [-107.305603, 68.697197],
            [-106.820801, 68.811371],
            [-106.655296, 68.811371],
            [-106.214699, 68.94136],
            [-105.797997, 68.879433],
            [-105.475304, 68.720543],
            [-105.5439, 68.656647],
            [-105.407501, 68.51915],
            [-105.326103, 68.510818],
            [-105.321404, 68.474152],
            [-105.433899, 68.489151],
            [-105.538101, 68.445534],
            [-105.533096, 68.4086],
            [-105.338303, 68.412201],
            [-105.350304, 68.372482],
            [-105.294998, 68.339157],
            [-105.055603, 68.258041],
            [-104.998596, 68.274696],
            [-105.082497, 68.308029],
            [-104.939201, 68.309708],
            [-104.893898, 68.348877],
            [-104.839401, 68.288589],
            [-104.952003, 68.247482],
            [-104.900299, 68.225273],
            [-104.822197, 68.261658],
            [-104.609703, 68.241364],
            [-104.597198, 68.222214],
            [-104.670303, 68.138603],
            [-104.600601, 68.135818],
            [-104.592201, 68.083603],
            [-104.495499, 68.062477],
            [-104.5, 68.031937],
            [-104.303001, 68.051651],
            [-104.166702, 68.017487],
            [-103.555298, 68.057213],
            [-103.532799, 68.071663],
            [-103.553001, 68.109711],
            [-103.404198, 68.164146],
            [-103.3414, 68.116089],
            [-103.379204, 68.083328],
            [-103.366096, 68.005829],
            [-103.136902, 67.958038],
            [-103.093102, 67.923027],
            [-102.959396, 67.926933],
            [-102.800301, 67.820831],
            [-102.470802, 67.786926],
            [-102.311699, 67.717484],
            [-102.251099, 67.725273],
            [-102.227501, 67.68692],
            [-102.198601, 67.695824],
            [-102.224403, 67.733871],
            [-102.151398, 67.765549],
            [-102.039398, 67.742203],
            [-101.9786, 67.787773],
            [-101.800003, 67.704987],
            [-101.741898, 67.727768],
            [-101.671898, 67.69165],
            [-101.409698, 67.687759],
            [-101.446701, 67.732483],
            [-101.257797, 67.718597],
            [-101.233101, 67.731934],
            [-101.250298, 67.771103],
            [-101.166397, 67.754707],
            [-101.089401, 67.786102],
            [-101.0989, 67.737762],
            [-100.9039, 67.756943],
            [-100.915604, 67.817757],
            [-100.821999, 67.792213],
            [-100.699997, 67.846939],
            [-100.586403, 67.838593],
            [-100.587502, 67.79332],
            [-100.395599, 67.847488],
            [-99.820007, 67.795822],
            [-99.776672, 67.823883],
            [-99.632492, 67.789429],
            [-99.500557, 67.799713],
            [-99.478333, 67.771927],
            [-99.412216, 67.788307],
            [-99.210564, 67.70694],
            [-99.118881, 67.727768],
            [-98.930557, 67.696091],
            [-98.896118, 67.748322],
            [-98.761124, 67.743874],
            [-98.693604, 67.807747],
            [-98.597778, 67.77832],
            [-98.354446, 67.796097],
            [-98.449432, 67.865807],
            [-98.540283, 67.872208],
            [-98.724167, 67.953598],
            [-98.732773, 68.070267],
            [-98.585281, 68.091927],
            [-98.233322, 67.901932],
            [-98.088608, 67.756653],
            [-97.998611, 67.753052],
            [-97.92749, 67.695534],
            [-97.799438, 67.685532],
            [-97.738892, 67.642212],
            [-97.547501, 67.596367],
            [-97.283333, 67.662491],
            [-97.201401, 67.623871],
            [-97.179169, 67.674149],
            [-97.059158, 67.674988],
            [-97.144997, 67.715271],
            [-97.223618, 67.712486],
            [-97.115829, 67.782761],
            [-97.24028, 67.926086],
            [-97.291107, 67.884987],
            [-97.392227, 67.876373],
            [-97.68306, 68.0186],
            [-97.710831, 67.9711],
            [-97.833618, 67.976929],
            [-97.950287, 67.920258],
            [-98.029999, 67.941933],
            [-98.057503, 67.912491],
            [-98.051941, 67.830833],
            [-98.095284, 67.833328],
            [-98.581123, 68.139977],
            [-98.459732, 68.183594],
            [-98.424438, 68.152206],
            [-98.439438, 68.104156],
            [-98.367767, 68.080551],
            [-98.326111, 68.171371],
            [-98.501106, 68.208603],
            [-98.542221, 68.234154],
            [-98.490829, 68.246933],
            [-98.715286, 68.361649],
            [-98.637222, 68.37915],
            [-98.511398, 68.329712],
            [-98.537216, 68.362198],
            [-98.461403, 68.358871],
            [-98.539436, 68.400818],
            [-98.525284, 68.421097],
            [-98.199722, 68.300262],
            [-98.154449, 68.331673],
            [-98.072777, 68.321663],
            [-98.107498, 68.364433],
            [-97.909729, 68.340553],
            [-97.920273, 68.373032],
            [-97.894119, 68.386459],
            [-97.743057, 68.370529],
            [-97.781952, 68.406647],
            [-97.899986, 68.419708],
            [-97.903061, 68.449997],
            [-98.009743, 68.498032],
            [-97.994164, 68.541092],
            [-97.852493, 68.54248],
            [-97.692757, 68.516663],
            [-97.646118, 68.450821],
            [-97.537781, 68.41832],
            [-97.509171, 68.444427],
            [-97.619164, 68.458328],
            [-97.56778, 68.496368],
            [-97.282784, 68.474152],
            [-97.10556, 68.388321],
            [-97.161392, 68.374977],
            [-97.001953, 68.345261],
            [-96.99472, 68.289978],
            [-97.070007, 68.300262],
            [-97.080292, 68.259163],
            [-96.925003, 68.239151],
            [-96.680557, 68.280823],
            [-96.625267, 68.251663],
            [-96.694153, 68.216927],
            [-96.630829, 68.178864],
            [-96.609734, 68.243317],
            [-96.410553, 68.315536],
            [-96.474716, 68.271652],
            [-96.508904, 68.196373],
            [-96.638062, 68.13916],
            [-96.623047, 68.11554],
            [-96.808884, 68.049423],
            [-96.789169, 68.017212],
            [-96.708344, 68.008614],
            [-96.622223, 68.062187],
            [-96.540283, 68.030273],
            [-96.453056, 68.039429],
            [-96.480003, 68.090271],
            [-96.54834, 68.084152],
            [-96.463058, 68.14415],
            [-95.892227, 68.294144],
            [-96.078613, 68.149986],
            [-96.020279, 68.106087],
            [-96.215286, 67.823608],
            [-96.188889, 67.73027],
            [-96.220001, 67.693039],
            [-96.166656, 67.690262],
            [-96.185272, 67.626373],
            [-96.257782, 67.626923],
            [-96.335564, 67.69664],
            [-96.329727, 67.61026],
            [-96.460007, 67.548866],
            [-96.428879, 67.537201],
            [-96.464722, 67.478043],
            [-96.396118, 67.494431],
            [-96.33168, 67.431931],
            [-96.210007, 67.419144],
            [-96.097229, 67.464706],
            [-96.070282, 67.428307],
            [-96.226936, 67.316673],
            [-96.252228, 67.247482],
            [-96.136124, 67.256378],
            [-96.114441, 67.213318],
            [-95.899437, 67.284149],
            [-95.73999, 67.376648],
            [-95.675552, 67.328323],
            [-95.612778, 67.37915],
            [-95.565826, 67.376648],
            [-95.525009, 67.351089],
            [-95.553329, 67.313309],
            [-95.833328, 67.162773],
            [-95.650284, 67.225807],
            [-95.651398, 67.198868],
            [-95.487778, 67.203873],
            [-95.474167, 67.135536],
            [-95.436943, 67.154984],
            [-95.435547, 67.193863],
            [-95.37944, 67.154709],
            [-95.373894, 67.109993],
            [-95.421661, 67.063873],
            [-95.326111, 67.027206],
            [-95.350281, 66.963882],
            [-95.422501, 66.946373],
            [-95.623322, 66.951927],
            [-95.599442, 66.981087],
            [-95.622223, 66.987488],
            [-95.928047, 66.952477],
            [-95.944443, 66.996368],
            [-95.993881, 67.00972],
            [-95.946953, 67.07222],
            [-96.051666, 67.010818],
            [-96.025009, 66.973312],
            [-96.11055, 66.950821],
            [-96.155563, 66.961647],
            [-96.133904, 66.9897],
            [-96.279167, 66.999153],
            [-96.26889, 67.060532],
            [-96.377213, 67.084717],
            [-96.460564, 67.062187],
            [-96.397232, 66.993874],
            [-96.146393, 66.894707],
            [-96.100563, 66.828598],
            [-95.848053, 66.752777],
            [-95.777496, 66.654984],
            [-95.819733, 66.583878],
            [-95.629707, 66.675537],
            [-95.652222, 66.728592],
            [-95.681107, 66.729431],
            [-95.691383, 66.679428],
            [-95.766403, 66.683594],
            [-95.784729, 66.737198],
            [-95.993057, 66.842758],
            [-96.085564, 66.926933],
            [-96.026108, 66.950272],
            [-95.814438, 66.94136],
            [-95.724716, 66.900818],
            [-95.422234, 66.930542],
            [-95.323624, 66.889427],
            [-95.254547, 66.940369],
            [-95.219452, 66.973602],
            [-95.295547, 66.998871],
            [-95.291672, 67.034714],
            [-95.347504, 67.073883],
            [-95.322777, 67.106087],
            [-95.352219, 67.148331],
            [-95.266113, 67.212769],
            [-95.248894, 67.265266],
            [-95.16333, 67.287201],
            [-95.314713, 67.330551],
            [-95.375816, 67.439972],
            [-95.419159, 67.455261],
            [-95.33168, 67.505829],
            [-95.343613, 67.554977],
            [-95.4664, 67.637207],
            [-95.693047, 67.704437],
            [-95.704453, 67.738586],
            [-95.534439, 67.820831],
            [-95.527222, 67.872208],
            [-95.580292, 67.898613],
            [-95.412781, 68.021652],
            [-95.472778, 68.060532],
            [-95.233887, 68.082489],
            [-95.256958, 68.034988],
            [-95.101669, 68.086929],
            [-95.008904, 68.044434],
            [-94.722229, 68.054977],
            [-94.582497, 68.156097],
            [-94.199158, 68.267761],
            [-94.210831, 68.361366],
            [-94.123047, 68.416931],
            [-93.661392, 68.520828],
            [-93.544998, 68.557213],
            [-93.536392, 68.598602],
            [-93.375549, 68.633331],
            [-93.751953, 68.613602],
            [-93.673607, 68.720261],
            [-93.695267, 68.751938],
            [-93.639717, 68.764709],
            [-93.641113, 68.805817],
            [-93.566101, 68.849426],
            [-93.64389, 68.895538],
            [-93.642227, 68.963882],
            [-93.843887, 68.958878],
            [-93.871109, 68.9711],
            [-93.836937, 69.006378],
            [-93.909157, 68.998032],
            [-93.803879, 69.069153],
            [-93.93222, 69.02887],
            [-93.898064, 69.025818],
            [-93.947487, 68.993042],
            [-93.925552, 68.974701],
            [-94.055832, 68.901932],
            [-94.068069, 68.864151],
            [-94.040833, 68.859711],
            [-94.079727, 68.847488],
            [-94.034157, 68.833328],
            [-93.928596, 68.882477],
            [-93.933884, 68.855263],
            [-93.809998, 68.890549],
            [-94.095284, 68.758041],
            [-94.41333, 68.71582],
            [-94.632217, 68.75],
            [-94.545273, 68.88472],
            [-94.589996, 68.908333],
            [-94.562767, 68.924698],
            [-94.599167, 68.965553],
            [-94.501953, 68.968597],
            [-94.444992, 68.938026],
            [-94.348053, 69.024696],
            [-94.224167, 69.027771],
            [-94.151398, 69.057213],
            [-94.191673, 69.083603],
            [-94.16806, 69.103867],
            [-94.005836, 69.154427],
            [-94.235001, 69.119431],
            [-94.323059, 69.149986],
            [-94.281387, 69.226646],
            [-94.299156, 69.246094],
            [-94.259171, 69.271378],
            [-94.303329, 69.304977],
            [-94.025009, 69.359711],
            [-93.808609, 69.350807],
            [-93.736656, 69.399986],
            [-93.526398, 69.438309],
            [-93.883057, 69.25],
            [-93.811943, 69.264999],
            [-93.877487, 69.224152],
            [-93.8125, 69.214996],
            [-93.85611, 69.176933],
            [-93.837219, 69.164429],
            [-93.37944, 69.376373],
            [-93.498047, 69.349152],
            [-93.585831, 69.364151],
            [-93.439438, 69.475273],
            [-93.540833, 69.523323],
            [-93.839447, 69.4897],
            [-93.916656, 69.432747],
            [-94.279167, 69.440262],
            [-94.451683, 69.5186],
            [-94.577789, 69.649986],
            [-94.626938, 69.636383],
            [-94.628601, 69.683868],
            [-94.754997, 69.661652],
            [-94.730827, 69.602768],
            [-94.862778, 69.56694],
            [-95.009453, 69.621643],
            [-95.11528, 69.612488],
            [-95.40834, 69.681931],
            [-95.649986, 69.786377],
            [-95.738892, 69.786102],
            [-95.786942, 69.741364],
            [-95.828888, 69.765266],
            [-95.799438, 69.796097],
            [-95.960831, 69.778053],
            [-96.074448, 69.841927],
            [-96.198334, 69.859711],
            [-96.08223, 69.873596],
            [-96.119164, 69.895538],
            [-96.085007, 69.911377],
            [-96.116096, 69.921371],
            [-96.097778, 69.94664],
            [-96.246948, 69.958878],
            [-96.50029, 70.101379],
            [-96.568619, 70.2686],
            [-96.519173, 70.358322],
            [-96.434723, 70.409988],
            [-96.340286, 70.415268],
            [-96.324722, 70.49054],
            [-96.243057, 70.569717],
            [-96.188316, 70.529427],
            [-96.069733, 70.587769],
            [-95.797234, 70.529427],
            [-96.061394, 70.617203],
            [-96.04834, 70.646942],
            [-95.81778, 70.710274],
            [-96.093887, 70.659988],
            [-96.202789, 70.621643],
            [-96.383621, 70.675537],
            [-96.434723, 70.737198],
            [-96.611938, 70.794434],
            [-96.591667, 70.866928],
            [-96.513062, 70.949707],
            [-96.495827, 71.040268],
            [-96.370003, 71.089981],
            [-96.409439, 71.119431],
            [-96.41362, 71.084152],
            [-96.461403, 71.080551],
            [-96.560547, 71.126373],
            [-96.450562, 71.168053],
            [-96.376663, 71.149147],
            [-96.459442, 71.197197],
            [-96.429718, 71.258331],
            [-96.503891, 71.277206],
            [-96.30722, 71.295822],
            [-96.193329, 71.389977],
            [-96.046387, 71.418053],
            [-95.89389, 71.390823],
            [-95.67305, 71.287491],
            [-95.535278, 71.290817],
            [-95.519997, 71.323883],
            [-95.588608, 71.354431],
            [-95.433319, 71.370819],
            [-95.548607, 71.428307],
            [-95.376663, 71.50943],
            [-95.490829, 71.482208],
            [-95.799728, 71.504707],
            [-95.936661, 71.546654],
            [-95.876389, 71.582764],
            [-95.90834, 71.60054],
            [-95.724716, 71.625809],
            [-95.507782, 71.719711],
            [-95.263062, 71.723877],
            [-95.288063, 71.761932],
            [-95.226936, 71.82666],
            [-94.932037, 71.840569],
            [-94.734444, 71.823318],
            [-94.607773, 71.866379],
            [-94.756958, 71.837486],
            [-94.850273, 71.848213],
            [-94.933632, 71.850113],
            [-95.213333, 71.843048],
            [-95.256668, 71.866928],
            [-95.217499, 71.944977],
            [-94.563606, 71.996933],
            [-94.388611, 71.924149],
            [-94.5, 71.898041],
            [-94.449722, 71.88443],
            [-94.460564, 71.849426],
            [-94.5625, 71.849991],
            [-94.648354, 71.812477],
            [-94.594162, 71.743317],
            [-94.538597, 71.758331],
            [-94.488327, 71.824158],
            [-94.450562, 71.78804],
            [-94.401947, 71.814148],
            [-94.356659, 71.796371],
            [-94.390839, 71.774429],
            [-94.423889, 71.661377],
            [-94.194443, 71.791931],
            [-94.142776, 71.756104],
            [-94.054443, 71.787201],
            [-93.972504, 71.745819],
            [-93.71167, 71.761383],
            [-93.696663, 71.710823],
            [-93.811394, 71.645538],
            [-93.230827, 71.473602],
            [-93.142502, 71.374977],
            [-92.973892, 71.34082],
            [-92.995003, 71.315536],
            [-92.94194, 71.288879],
            [-92.930832, 71.214432],
            [-92.855827, 71.157494],
            [-92.889999, 71.065811],
            [-92.853058, 71.039429],
            [-92.908051, 70.903587],
            [-92.851936, 70.856644],
            [-92.944992, 70.835823],
            [-93.000557, 70.872757],
            [-93.04277, 70.863876],
            [-92.958618, 70.81749],
            [-92.676666, 70.771652],
            [-92.69194, 70.745819],
            [-92.657784, 70.735809],
            [-92.718887, 70.724152],
            [-92.641113, 70.715271],
            [-92.676102, 70.674698],
            [-92.487877, 70.668053],
            [-92.297501, 70.6297],
            [-92.341667, 70.59166],
            [-92.311394, 70.569992],
            [-92.276108, 70.572487],
            [-92.257233, 70.61998],
            [-92.159157, 70.584152],
            [-92.265007, 70.551933],
            [-92.18721, 70.504707],
            [-92.285553, 70.487762],
            [-92.116653, 70.470833],
            [-92.132492, 70.434143],
            [-91.996948, 70.390823],
            [-91.99527, 70.316673],
            [-92.085564, 70.298317],
            [-91.996658, 70.29776],
            [-91.952217, 70.255257],
            [-91.920273, 70.296371],
            [-91.855003, 70.303307],
            [-91.900284, 70.330833],
            [-91.890289, 70.354431],
            [-91.729721, 70.356934],
            [-91.687767, 70.315536],
            [-91.726387, 70.298317],
            [-91.693878, 70.197479],
            [-91.636124, 70.231659],
            [-91.511398, 70.152771],
            [-92.003891, 70.121368],
            [-92.226936, 70.185257],
            [-92.282501, 70.250816],
            [-92.362503, 70.240257],
            [-92.268341, 70.208878],
            [-92.276672, 70.184418],
            [-92.480827, 70.176651],
            [-92.388611, 70.144989],
            [-92.479446, 70.09137],
            [-92.58667, 70.076927],
            [-92.467499, 70.042763],
            [-92.43222, 70.075546],
            [-92.12999, 70.084991],
            [-91.939713, 70.020264],
            [-92.081123, 69.986366],
            [-92.267227, 69.901932],
            [-92.254997, 69.877762],
            [-92.554718, 69.808319],
            [-92.565277, 69.762207],
            [-92.643341, 69.791656],
            [-92.688599, 69.74498],
            [-92.867767, 69.702209],
            [-92.535004, 69.709427],
            [-92.743797, 69.671951],
          ],
        ],
        [
          [
            [-61.28278, 66.616928],
            [-61.447781, 66.538307],
            [-61.59861, 66.550262],
            [-61.72583, 66.643051],
            [-62.004719, 66.69664],
            [-62.016659, 66.658043],
            [-62.186661, 66.627197],
            [-62.09, 66.606644],
            [-61.98, 66.648041],
            [-61.788891, 66.608597],
            [-61.576389, 66.477203],
            [-61.98695, 66.410263],
            [-61.55727, 66.413681],
            [-61.462502, 66.369141],
            [-61.554722, 66.336113],
            [-61.783058, 66.334152],
            [-61.915001, 66.281372],
            [-62.080002, 66.329437],
            [-62.2075, 66.316673],
            [-62.23, 66.40416],
            [-62.60722, 66.442749],
            [-62.628052, 66.430542],
            [-62.60397, 66.40358],
            [-62.715549, 66.407578],
            [-62.604721, 66.387573],
            [-62.494999, 66.405548],
            [-62.453892, 66.353867],
            [-62.316109, 66.304977],
            [-62.66695, 66.214432],
            [-62.757229, 66.245247],
            [-62.779442, 66.307213],
            [-62.889999, 66.333328],
            [-62.824451, 66.289429],
            [-62.809441, 66.240807],
            [-62.715, 66.20166],
            [-62.513062, 66.219437],
            [-62.34333, 66.162491],
            [-62.108341, 66.143051],
            [-62.16444, 66.125526],
            [-62.153049, 66.100807],
            [-62.03751, 66.100807],
            [-62.079731, 66.082764],
            [-62.012779, 66.071381],
            [-62.108891, 66.049988],
            [-61.98167, 66.043053],
            [-61.960281, 66.011932],
            [-62.188889, 66.012207],
            [-62.307781, 65.973877],
            [-62.52528, 66.034149],
            [-62.778881, 66.033333],
            [-62.930561, 66.146942],
            [-63.061939, 66.120819],
            [-62.93639, 66.122757],
            [-62.875, 66.04776],
            [-62.793621, 66.010818],
            [-62.586109, 66.00943],
            [-62.418331, 65.970543],
            [-62.38583, 65.931374],
            [-62.453609, 65.921921],
            [-62.36195, 65.904984],
            [-62.376389, 65.868042],
            [-62.288059, 65.813309],
            [-62.619999, 65.80304],
            [-62.727219, 65.828598],
            [-62.86417, 65.911102],
            [-62.874722, 65.883041],
            [-62.812771, 65.857483],
            [-62.82362, 65.839706],
            [-62.56945, 65.757492],
            [-62.599442, 65.718872],
            [-62.848049, 65.763893],
            [-62.799171, 65.711929],
            [-62.589722, 65.668587],
            [-62.645279, 65.587196],
            [-62.67194, 65.597214],
            [-62.66, 65.638046],
            [-62.727779, 65.620819],
            [-62.730831, 65.588882],
            [-62.784729, 65.59137],
            [-62.85973, 65.63472],
            [-62.88612, 65.734421],
            [-62.959438, 65.753883],
            [-62.91666, 65.722214],
            [-62.89167, 65.638321],
            [-62.972221, 65.580833],
            [-63.0275, 65.635818],
            [-63.211941, 65.640549],
            [-63.43639, 65.845261],
            [-63.488049, 65.936096],
            [-63.529171, 65.876923],
            [-63.36945, 65.730553],
            [-63.384171, 65.711647],
            [-63.347778, 65.666656],
            [-63.728611, 65.675812],
            [-63.671669, 65.648331],
            [-63.375, 65.632202],
            [-63.32, 65.593323],
            [-63.35973, 65.536102],
            [-63.48222, 65.525269],
            [-63.583611, 65.5961],
            [-63.608891, 65.589981],
            [-63.56945, 65.549149],
            [-63.618328, 65.541931],
            [-63.536949, 65.517487],
            [-63.526951, 65.478867],
            [-63.446949, 65.488312],
            [-63.292782, 65.428864],
            [-63.65556, 65.470833],
            [-63.389439, 65.393051],
            [-63.523331, 65.38443],
            [-63.33556, 65.300537],
            [-63.448608, 65.292213],
            [-63.34277, 65.253326],
            [-63.45805, 65.239433],
            [-63.325001, 65.220833],
            [-63.317219, 65.191093],
            [-63.513889, 65.191093],
            [-63.43972, 65.171654],
            [-63.477779, 65.157494],
            [-63.34222, 65.140549],
            [-63.433331, 65.101929],
            [-63.39999, 65.078873],
            [-63.367771, 65.063026],
            [-63.465279, 65.05748],
            [-63.38805, 65.042763],
            [-63.46944, 65.032211],
            [-63.456669, 65.004173],
            [-63.527222, 64.971916],
            [-63.504169, 64.953049],
            [-63.546951, 64.887207],
            [-63.653881, 64.911652],
            [-63.62833, 64.929703],
            [-63.656658, 64.943588],
            [-63.601391, 64.961113],
            [-63.614719, 64.99942],
            [-63.732498, 64.962196],
            [-63.824169, 64.984711],
            [-63.825562, 65.012772],
            [-63.65527, 65.03804],
            [-63.870548, 65.040817],
            [-63.881672, 65.096939],
            [-63.798889, 65.18248],
            [-63.85944, 65.179153],
            [-63.965, 65.096367],
            [-64.074722, 65.120819],
            [-64.077789, 65.068047],
            [-64.121109, 65.043869],
            [-64.205276, 65.106934],
            [-64.267227, 65.094147],
            [-64.208893, 65.155258],
            [-64.081123, 65.199417],
            [-64.231377, 65.19693],
            [-64.323334, 65.148041],
            [-64.380829, 65.181091],
            [-64.391113, 65.248871],
            [-64.337784, 65.289146],
            [-64.39917, 65.270538],
            [-64.402786, 65.291092],
            [-64.255569, 65.386383],
            [-64.237213, 65.429977],
            [-64.291107, 65.422493],
            [-64.352219, 65.3461],
            [-64.46167, 65.294983],
            [-64.455566, 65.207207],
            [-64.535004, 65.097214],
            [-64.580002, 65.12886],
            [-64.637512, 65.119713],
            [-64.611389, 65.141937],
            [-64.643623, 65.15387],
            [-64.620827, 65.192467],
            [-64.730293, 65.158333],
            [-64.68277, 65.203049],
            [-64.718338, 65.220261],
            [-64.696663, 65.234154],
            [-64.647507, 65.247757],
            [-64.73111, 65.271103],
            [-64.801102, 65.23082],
            [-64.910828, 65.303589],
            [-64.684433, 65.341087],
            [-64.910553, 65.339981],
            [-64.59111, 65.429703],
            [-64.448608, 65.41832],
            [-64.412781, 65.478592],
            [-64.468887, 65.4422],
            [-64.648064, 65.464706],
            [-64.695267, 65.427757],
            [-64.863892, 65.424698],
            [-64.98111, 65.401932],
            [-64.989723, 65.368317],
            [-65.058884, 65.376648],
            [-65.144173, 65.427757],
            [-65.135834, 65.461647],
            [-65.16806, 65.483871],
            [-64.929718, 65.524696],
            [-64.863892, 65.579987],
            [-64.788887, 65.584152],
            [-64.836937, 65.60582],
            [-64.821404, 65.641663],
            [-64.710007, 65.652206],
            [-64.853333, 65.654709],
            [-64.993607, 65.548027],
            [-65.212219, 65.552467],
            [-65.196663, 65.599152],
            [-65.318336, 65.550537],
            [-65.338608, 65.575546],
            [-65.300827, 65.590271],
            [-65.299988, 65.630814],
            [-65.125816, 65.633331],
            [-65.099731, 65.671921],
            [-64.994453, 65.701393],
            [-64.791382, 65.723312],
            [-65.024719, 65.716927],
            [-65.168327, 65.656097],
            [-65.431381, 65.669144],
            [-65.45639, 65.685532],
            [-65.372772, 65.777206],
            [-65.497772, 65.737488],
            [-65.456123, 65.826927],
            [-65.343887, 65.916656],
            [-64.963898, 65.998596],
            [-64.735001, 65.969437],
            [-64.851387, 66.032494],
            [-64.779449, 66.092209],
            [-64.721657, 66.217484],
            [-64.356377, 66.349716],
            [-64.718887, 66.273323],
            [-64.839996, 66.193314],
            [-64.858887, 66.106087],
            [-65.226936, 66.01915],
            [-65.286392, 66.038307],
            [-65.293877, 66.010818],
            [-65.398064, 65.974701],
            [-65.916107, 65.951103],
            [-65.963058, 66.034416],
            [-65.911667, 66.09166],
            [-65.634171, 66.172493],
            [-65.47139, 66.312477],
            [-65.536392, 66.318047],
            [-65.471123, 66.342484],
            [-65.47583, 66.387772],
            [-65.553879, 66.327766],
            [-65.571671, 66.268333],
            [-65.696953, 66.180542],
            [-65.95195, 66.108871],
            [-66.139183, 66.131363],
            [-66.254318, 66.220047],
            [-66.150284, 66.284149],
            [-66.262512, 66.238586],
            [-66.419724, 66.275818],
            [-66.44194, 66.266098],
            [-66.37471, 66.207207],
            [-66.504997, 66.208878],
            [-66.545547, 66.27887],
            [-66.474442, 66.275269],
            [-66.515289, 66.318604],
            [-66.471123, 66.347763],
            [-66.576111, 66.359154],
            [-66.43721, 66.413307],
            [-66.617493, 66.368591],
            [-66.767776, 66.380539],
            [-66.715012, 66.385269],
            [-66.679169, 66.454987],
            [-66.731949, 66.428864],
            [-66.813606, 66.452209],
            [-66.786942, 66.505257],
            [-66.857773, 66.536652],
            [-66.724716, 66.584717],
            [-66.851936, 66.583328],
            [-67.023064, 66.643333],
            [-67.058884, 66.642761],
            [-67.043327, 66.625809],
            [-66.884171, 66.566093],
            [-67.044724, 66.521652],
            [-67.149437, 66.531937],
            [-67.079453, 66.511932],
            [-67.10556, 66.485809],
            [-67.198608, 66.494141],
            [-67.190277, 66.52916],
            [-67.329453, 66.565262],
            [-67.338333, 66.597763],
            [-67.450287, 66.572769],
            [-67.536392, 66.622757],
            [-67.554993, 66.610527],
            [-67.521667, 66.565811],
            [-67.639999, 66.596649],
            [-67.638611, 66.576103],
            [-67.740829, 66.571114],
            [-67.296387, 66.526093],
            [-67.260559, 66.475273],
            [-67.148621, 66.443863],
            [-67.138062, 66.382202],
            [-67.185272, 66.363602],
            [-67.236389, 66.400818],
            [-67.406387, 66.429703],
            [-67.293877, 66.371643],
            [-67.188599, 66.352203],
            [-67.126099, 66.305542],
            [-67.254181, 66.302757],
            [-67.282227, 66.275269],
            [-67.371658, 66.313599],
            [-67.39917, 66.29248],
            [-67.493881, 66.352768],
            [-67.492767, 66.382202],
            [-67.638611, 66.445534],
            [-67.8125, 66.463043],
            [-67.856949, 66.5186],
            [-67.883904, 66.4897],
            [-67.923607, 66.516098],
            [-67.992767, 66.503883],
            [-67.87027, 66.454712],
            [-67.858047, 66.411926],
            [-67.760559, 66.358032],
            [-67.701401, 66.284714],
            [-67.741096, 66.243042],
            [-67.29361, 66.11026],
            [-67.252792, 66.049149],
            [-67.162514, 66.035263],
            [-67.311943, 65.996933],
            [-67.171661, 65.926376],
            [-67.194443, 65.909714],
            [-67.349167, 65.913307],
            [-67.411118, 65.943863],
            [-67.453888, 65.897491],
            [-67.60611, 65.902206],
            [-67.742767, 65.960541],
            [-67.704178, 65.891663],
            [-67.914436, 65.899147],
            [-68.030563, 65.998032],
            [-68.025833, 66.065811],
            [-67.97084, 66.087486],
            [-68.230293, 66.146378],
            [-68.276947, 66.224426],
            [-68.346947, 66.212486],
            [-68.336121, 66.174988],
            [-68.389999, 66.183594],
            [-68.456123, 66.271378],
            [-68.516403, 66.298866],
            [-68.48056, 66.215553],
            [-68.835281, 66.184982],
            [-68.493057, 66.188873],
            [-68.244164, 66.071114],
            [-68.218887, 66.081383],
            [-68.259171, 66.109993],
            [-68.220001, 66.128593],
            [-68.027222, 66.074997],
            [-68.060272, 66.054428],
            [-68.064438, 65.984421],
            [-68.121658, 66.021927],
            [-68.200287, 66.020538],
            [-68.123894, 65.963043],
            [-68.346947, 66.037773],
            [-68.192207, 65.950272],
            [-68.315552, 65.959717],
            [-68.321671, 65.911926],
            [-68.169159, 65.904984],
            [-68.142776, 65.929153],
            [-68.148354, 65.873596],
            [-68.185822, 65.854156],
            [-68.080841, 65.834427],
            [-68.203613, 65.792763],
            [-68.023903, 65.775269],
            [-67.810822, 65.815536],
            [-67.821121, 65.768051],
            [-67.943329, 65.746933],
            [-67.976669, 65.705833],
            [-67.876099, 65.694138],
            [-67.867493, 65.634987],
            [-67.90889, 65.611099],
            [-67.984161, 65.633614],
            [-67.917503, 65.599991],
            [-68.058884, 65.568047],
            [-67.951683, 65.568329],
            [-68.025833, 65.481087],
            [-67.730293, 65.636383],
            [-67.485283, 65.643051],
            [-67.462593, 65.674271],
            [-67.328056, 65.662491],
            [-67.251678, 65.606934],
            [-67.339172, 65.576103],
            [-67.260834, 65.529709],
            [-67.362213, 65.546944],
            [-67.414436, 65.525818],
            [-67.332497, 65.515549],
            [-67.335564, 65.494141],
            [-67.451401, 65.493591],
            [-67.334442, 65.443863],
            [-67.291107, 65.470261],
            [-67.058037, 65.451393],
            [-67.112122, 65.435303],
            [-67.058327, 65.426651],
            [-67.118057, 65.361923],
            [-67.309158, 65.36998],
            [-67.409439, 65.333328],
            [-67.326683, 65.31694],
            [-67.269173, 65.352203],
            [-67.120003, 65.309982],
            [-67.16806, 65.264999],
            [-67.077789, 65.250267],
            [-67.168327, 65.224152],
            [-67.128601, 65.228317],
            [-67.111107, 65.19693],
            [-66.928879, 65.229713],
            [-66.959167, 65.106644],
            [-67.055557, 65.105263],
            [-67.107773, 65.058868],
            [-66.980827, 65.087769],
            [-66.951683, 65.0811],
            [-66.961403, 65.057213],
            [-66.900284, 65.063873],
            [-66.826401, 65.149147],
            [-66.726097, 65.180267],
            [-66.753342, 65.113312],
            [-66.827217, 65.094711],
            [-66.79361, 65.083878],
            [-66.806664, 65.055542],
            [-66.915283, 65.050812],
            [-66.840286, 65.033867],
            [-66.760834, 65.064697],
            [-66.780563, 65.003883],
            [-66.736389, 64.99971],
            [-66.743057, 64.963043],
            [-66.830292, 64.940811],
            [-66.726936, 64.913879],
            [-66.767227, 64.868591],
            [-66.698334, 64.763893],
            [-66.632492, 64.781372],
            [-66.689713, 64.822487],
            [-66.644447, 64.94693],
            [-66.68866, 65.038773],
            [-66.535278, 65.010818],
            [-66.539993, 64.953323],
            [-66.480293, 64.983871],
            [-66.478607, 64.929153],
            [-66.379173, 64.912201],
            [-66.341667, 64.938873],
            [-66.309433, 64.86499],
            [-66.267502, 64.901382],
            [-66.148354, 64.868874],
            [-66.19194, 64.77887],
            [-66.339722, 64.763611],
            [-66.20723, 64.752487],
            [-66.212219, 64.685532],
            [-66.151947, 64.689148],
            [-66.116943, 64.781372],
            [-66.020844, 64.849716],
            [-66.036667, 64.708038],
            [-66.006393, 64.701103],
            [-66.011948, 64.748596],
            [-65.956673, 64.761932],
            [-65.912514, 64.681931],
            [-65.803329, 64.633881],
            [-65.89917, 64.732758],
            [-65.855827, 64.755547],
            [-65.938316, 64.799988],
            [-65.885277, 64.827209],
            [-65.958618, 64.877762],
            [-65.862503, 64.859154],
            [-65.838608, 64.882477],
            [-65.772781, 64.853317],
            [-65.786942, 64.801651],
            [-65.733612, 64.844437],
            [-65.664169, 64.808319],
            [-65.742493, 64.741089],
            [-65.704727, 64.687477],
            [-65.708618, 64.736099],
            [-65.665283, 64.771378],
            [-65.555832, 64.723312],
            [-65.586403, 64.640266],
            [-65.746948, 64.59082],
            [-65.714172, 64.570541],
            [-65.725563, 64.498032],
            [-65.518066, 64.49971],
            [-65.505569, 64.468872],
            [-65.348343, 64.525543],
            [-65.404999, 64.44136],
            [-65.334167, 64.48027],
            [-65.276398, 64.460823],
            [-65.268341, 64.511932],
            [-65.217499, 64.535538],
            [-65.238052, 64.483322],
            [-65.11528, 64.510536],
            [-65.177223, 64.47554],
            [-65.077789, 64.475807],
            [-65.078888, 64.421097],
            [-65.260559, 64.384163],
            [-65.184998, 64.356934],
            [-65.194992, 64.310257],
            [-65.65834, 64.302757],
            [-65.434158, 64.299149],
            [-65.465286, 64.291656],
            [-65.439713, 64.2686],
            [-65.558609, 64.24498],
            [-65.339447, 64.246643],
            [-65.362213, 64.224701],
            [-65.250557, 64.208328],
            [-65.391678, 64.148613],
            [-65.265007, 64.178864],
            [-65.115829, 64.106369],
            [-65.193878, 64.072769],
            [-65.049438, 64.07222],
            [-65.221939, 64.028587],
            [-64.949158, 64.014999],
            [-64.919449, 64.003883],
            [-64.954453, 63.971371],
            [-64.82251, 64.024147],
            [-64.756119, 64.001938],
            [-64.66806, 64.033051],
            [-64.632767, 63.972759],
            [-64.888901, 63.895],
            [-64.889717, 63.865269],
            [-64.974716, 63.85194],
            [-64.984161, 63.813881],
            [-64.948883, 63.774441],
            [-64.755768, 63.744389],
            [-64.689438, 63.75444],
            [-64.508347, 63.650829],
            [-64.521942, 63.611938],
            [-64.589172, 63.608051],
            [-64.536667, 63.581108],
            [-64.558327, 63.55888],
            [-64.51973, 63.494999],
            [-64.547783, 63.468048],
            [-64.501106, 63.423611],
            [-64.553329, 63.393608],
            [-64.634743, 63.410271],
            [-64.51001, 63.374989],
            [-64.495537, 63.32777],
            [-64.526138, 63.303829],
            [-64.620003, 63.31332],
            [-64.481949, 63.282211],
            [-64.648621, 63.23777],
            [-64.767502, 63.323879],
            [-64.757507, 63.379711],
            [-64.797501, 63.395828],
            [-64.834442, 63.4986],
            [-64.935822, 63.572769],
            [-64.94249, 63.63221],
            [-65.070847, 63.67194],
            [-65.106377, 63.727211],
            [-65.208054, 63.775829],
            [-65.159439, 63.781109],
            [-65.298607, 63.812489],
            [-64.986938, 63.601662],
            [-65.091949, 63.559719],
            [-65.099442, 63.526379],
            [-65.04277, 63.459721],
            [-65.117027, 63.426929],
            [-65.045273, 63.43166],
            [-65.123322, 63.400269],
            [-64.965012, 63.369438],
            [-64.909157, 63.280548],
            [-64.921387, 63.235821],
            [-65.055557, 63.314442],
            [-65.015289, 63.251942],
            [-65.148621, 63.28611],
            [-65.087784, 63.23082],
            [-64.968613, 63.217209],
            [-65.079453, 63.21249],
            [-65.05777, 63.174709],
            [-64.920273, 63.184158],
            [-64.857498, 63.16444],
            [-64.898354, 63.140549],
            [-64.78389, 63.122219],
            [-64.760834, 63.090549],
            [-64.864441, 63.088879],
            [-64.766953, 63.06221],
            [-64.815277, 63.039162],
            [-64.771393, 62.98333],
            [-64.846657, 62.967209],
            [-64.730827, 62.921379],
            [-64.696114, 62.952221],
            [-64.626938, 62.89999],
            [-64.881943, 62.867489],
            [-65.080566, 62.938599],
            [-65.11972, 62.992222],
            [-65.154167, 62.972759],
            [-65.125, 62.92083],
            [-65.236107, 62.957771],
            [-65.27417, 63.001389],
            [-65.279449, 62.968601],
            [-65.186661, 62.924999],
            [-65.202499, 62.887772],
            [-64.984734, 62.714161],
            [-64.928329, 62.63221],
            [-64.983887, 62.642769],
            [-64.981667, 62.619438],
            [-65.144997, 62.56583],
            [-65.22084, 62.578331],
            [-65.294998, 62.669441],
            [-65.266953, 62.694439],
            [-65.327499, 62.691662],
            [-65.328056, 62.6661],
            [-65.353882, 62.684429],
            [-65.318336, 62.74332],
            [-65.244164, 62.77916],
            [-65.361938, 62.774712],
            [-65.363617, 62.81694],
            [-65.331947, 62.82222],
            [-65.253616, 62.8325],
            [-65.339722, 62.83749],
            [-65.331947, 62.925549],
            [-65.402786, 62.821659],
            [-65.454178, 62.84193],
            [-65.609444, 62.820831],
            [-65.664436, 62.904991],
            [-65.704727, 62.89027],
            [-65.750557, 62.922489],
            [-65.688049, 62.979988],
            [-65.728882, 63.052769],
            [-65.743881, 62.965832],
            [-65.825562, 62.927769],
            [-65.79361, 62.87027],
            [-65.878883, 62.924709],
            [-65.964172, 62.931381],
            [-65.833328, 63.03138],
            [-65.950562, 62.975819],
            [-66.01445, 62.97887],
            [-66.074722, 63.021381],
            [-66.055267, 63.04805],
            [-66.137222, 63.03722],
            [-66.162781, 63.089989],
            [-66.275833, 63.133881],
            [-66.291382, 63.1161],
            [-66.212219, 63.081379],
            [-66.196663, 63.031658],
            [-66.091377, 62.983601],
            [-66.097778, 62.952492],
            [-66.159157, 62.936649],
            [-66.293877, 62.992771],
            [-66.392776, 62.994999],
            [-66.518341, 63.065269],
            [-66.493607, 63.087769],
            [-66.569733, 63.15749],
            [-66.545273, 63.195541],
            [-66.689713, 63.281109],
            [-66.638344, 63.340271],
            [-66.650833, 63.374989],
            [-66.74028, 63.283611],
            [-66.602493, 63.205269],
            [-66.618607, 63.162491],
            [-66.563316, 63.073608],
            [-66.68277, 63.096661],
            [-66.540283, 62.99416],
            [-66.68222, 63.026379],
            [-66.768066, 63.087769],
            [-66.758621, 63.178051],
            [-66.807503, 63.273319],
            [-66.849167, 63.245541],
            [-66.806664, 63.160549],
            [-66.897713, 63.171459],
            [-67.023903, 63.24305],
            [-66.947769, 63.284161],
            [-67.009453, 63.326939],
            [-66.977783, 63.399719],
            [-67.017227, 63.397221],
            [-67.040833, 63.335548],
            [-67.017227, 63.281109],
            [-67.092499, 63.276661],
            [-67.171661, 63.31638],
            [-67.146118, 63.27943],
            [-67.179718, 63.275551],
            [-67.346657, 63.374161],
            [-67.403061, 63.462769],
            [-67.414436, 63.410271],
            [-67.494164, 63.4361],
            [-67.55278, 63.53611],
            [-67.621658, 63.548882],
            [-67.683319, 63.619438],
            [-67.670273, 63.65332],
            [-67.778061, 63.63694],
            [-67.794998, 63.688881],
            [-67.834732, 63.689159],
            [-67.833328, 63.725552],
            [-67.914719, 63.759441],
            [-67.799728, 63.632488],
            [-67.819458, 63.585819],
            [-67.786942, 63.528599],
            [-67.671944, 63.412491],
            [-67.678329, 63.373051],
            [-67.71666, 63.36388],
            [-67.820557, 63.400269],
            [-67.858047, 63.4575],
            [-67.950844, 63.50666],
            [-68.083618, 63.547218],
            [-68.126938, 63.603321],
            [-68.137222, 63.576389],
            [-68.247498, 63.597488],
            [-68.216949, 63.627209],
            [-68.272781, 63.691101],
            [-68.312767, 63.690269],
            [-68.271118, 63.63805],
            [-68.30278, 63.633049],
            [-68.528877, 63.74638],
            [-68.556381, 63.727489],
            [-68.747223, 63.77499],
            [-68.723618, 63.72971],
            [-68.99472, 63.75555],
            [-68.813049, 63.635551],
            [-68.85083, 63.599998],
            [-68.543877, 63.446098],
            [-68.446663, 63.439991],
            [-68.495827, 63.421379],
            [-68.270554, 63.285],
            [-68.284729, 63.26194],
            [-68.20639, 63.227211],
            [-68.234734, 63.197769],
            [-68.139183, 63.148609],
            [-67.917503, 63.137211],
            [-67.976387, 63.064159],
            [-67.919724, 63.07333],
            [-67.894173, 63.119991],
            [-67.785828, 63.087212],
            [-67.610283, 63.094151],
            [-67.615013, 63.06361],
            [-67.791382, 63.020828],
            [-67.735283, 62.98138],
            [-67.76973, 62.958328],
            [-67.713898, 62.961109],
            [-67.736938, 62.995541],
            [-67.686943, 63.026661],
            [-67.553047, 63.048611],
            [-67.499733, 63.007771],
            [-67.651398, 62.967491],
            [-67.665833, 62.91888],
            [-67.462784, 62.985271],
            [-67.395279, 62.963879],
            [-67.419724, 62.931381],
            [-67.362778, 62.943878],
            [-67.246384, 62.88361],
            [-67.370003, 62.85416],
            [-67.200844, 62.873051],
            [-67.179443, 62.83749],
            [-67.241943, 62.81221],
            [-67.114723, 62.820549],
            [-67.08667, 62.79361],
            [-67.118607, 62.75639],
            [-67.04834, 62.771381],
            [-67.012222, 62.73444],
            [-67.058327, 62.691929],
            [-66.912781, 62.694988],
            [-67.001106, 62.653881],
            [-66.804718, 62.678879],
            [-66.726936, 62.668598],
            [-66.773064, 62.632488],
            [-66.670273, 62.641941],
            [-66.712784, 62.621658],
            [-66.615013, 62.612209],
            [-66.61055, 62.582771],
            [-66.487778, 62.511379],
            [-66.509453, 62.421661],
            [-66.351669, 62.444988],
            [-66.394173, 62.419159],
            [-66.330566, 62.379162],
            [-66.476936, 62.335819],
            [-66.318619, 62.264721],
            [-66.35556, 62.33194],
            [-66.243607, 62.31916],
            [-66.21666, 62.35194],
            [-66.15834, 62.305271],
            [-66.146957, 62.252781],
            [-66.257233, 62.25972],
            [-66.058609, 62.224159],
            [-66.015007, 62.251389],
            [-65.93306, 62.204159],
            [-66.055557, 62.141941],
            [-66.038063, 62.064159],
            [-66.13945, 62.093048],
            [-66.153877, 62.125549],
            [-66.200562, 62.113049],
            [-66.098892, 62.035831],
            [-66.138344, 62.0075],
            [-66.068069, 61.995541],
            [-66.035828, 61.95277],
            [-65.992218, 61.962769],
            [-65.955002, 61.89027],
            [-66.394997, 61.870831],
            [-66.632767, 61.91888],
            [-66.665283, 61.94138],
            [-66.618057, 61.966099],
            [-66.707779, 61.967491],
            [-66.755569, 62.011108],
            [-66.937767, 62.044441],
            [-66.920273, 62.018879],
            [-66.960564, 62.014439],
            [-67.019997, 62.051659],
            [-67.104721, 62.032211],
            [-67.232773, 62.0961],
            [-67.32695, 62.07333],
            [-67.328339, 62.1311],
            [-67.389999, 62.1511],
            [-67.731659, 62.15836],
            [-67.828339, 62.172489],
            [-67.806664, 62.198879],
            [-67.838898, 62.20916],
            [-67.866386, 62.188881],
            [-68.54805, 62.2486],
            [-68.722229, 62.302219],
            [-68.711937, 62.325829],
            [-68.819458, 62.338329],
            [-68.853882, 62.381378],
            [-69.022507, 62.37471],
            [-69.298889, 62.498329],
            [-69.282227, 62.622219],
            [-69.376099, 62.533051],
            [-69.411392, 62.607498],
            [-69.457497, 62.622761],
            [-69.442207, 62.547489],
            [-69.597229, 62.662491],
            [-69.475563, 62.673882],
            [-69.478333, 62.696659],
            [-69.428047, 62.705551],
            [-69.565552, 62.718048],
            [-69.394997, 62.763329],
            [-69.570847, 62.761379],
            [-69.621109, 62.787769],
            [-69.682503, 62.739719],
            [-69.679443, 62.774712],
            [-69.83168, 62.821941],
            [-69.762512, 62.726101],
            [-69.896957, 62.75555],
            [-69.939987, 62.794159],
            [-70.041672, 62.750271],
            [-70.254997, 62.75639],
            [-70.499443, 62.864441],
            [-70.885559, 62.907211],
            [-70.768066, 62.925831],
            [-70.847778, 62.947208],
            [-71.042221, 62.95583],
            [-70.940552, 62.970268],
            [-70.9664, 62.999161],
            [-70.940277, 63.026939],
            [-70.997223, 63.023048],
            [-71.013062, 62.98999],
            [-71.152222, 62.985271],
            [-71.136673, 63.028599],
            [-71.027222, 63.02499],
            [-70.939163, 63.095268],
            [-70.823898, 63.128601],
            [-70.92749, 63.115002],
            [-70.949158, 63.139431],
            [-70.90889, 63.17083],
            [-70.99028, 63.148048],
            [-70.98999, 63.102489],
            [-71.029716, 63.071941],
            [-71.138611, 63.07333],
            [-71.155563, 63.098598],
            [-71.198608, 63.019989],
            [-71.243881, 63.00111],
            [-71.275833, 63.012211],
            [-71.248611, 63.02943],
            [-71.268623, 63.040279],
            [-71.40834, 63.055271],
            [-71.398064, 63.122761],
            [-71.465012, 63.103321],
            [-71.713898, 63.179161],
            [-71.65889, 63.19249],
            [-71.756393, 63.237209],
            [-71.646393, 63.259159],
            [-71.769997, 63.25639],
            [-71.738892, 63.267769],
            [-71.775284, 63.288891],
            [-71.752792, 63.312771],
            [-71.821091, 63.34341],
            [-71.730003, 63.34499],
            [-71.775009, 63.412209],
            [-71.805832, 63.382771],
            [-71.924438, 63.38916],
            [-71.993057, 63.426659],
            [-72.009171, 63.391109],
            [-72.145554, 63.446098],
            [-71.634743, 63.41972],
            [-71.502274, 63.47747],
            [-71.304169, 63.535271],
            [-71.288887, 63.588329],
            [-71.234734, 63.608051],
            [-71.407501, 63.567768],
            [-71.362503, 63.613331],
            [-71.407784, 63.635551],
            [-71.407784, 63.610279],
            [-71.460564, 63.600269],
            [-71.446953, 63.58083],
            [-71.57695, 63.581669],
            [-71.562767, 63.622219],
            [-71.485817, 63.629429],
            [-71.581123, 63.645271],
            [-71.534157, 63.71027],
            [-71.638062, 63.7211],
            [-71.667221, 63.697208],
            [-71.608337, 63.65443],
            [-71.640289, 63.646099],
            [-71.72139, 63.691929],
            [-71.684158, 63.746658],
            [-71.759743, 63.691662],
            [-71.773064, 63.74416],
            [-71.904449, 63.80999],
            [-71.970551, 63.79583],
            [-72.004181, 63.752781],
            [-71.843887, 63.75555],
            [-71.92305, 63.645271],
            [-71.971939, 63.656101],
            [-71.929169, 63.679722],
            [-71.947487, 63.722488],
            [-72.039169, 63.66444],
            [-72.070557, 63.73999],
            [-72.107498, 63.715832],
            [-72.204727, 63.774441],
            [-72.213898, 63.677769],
            [-72.317497, 63.67416],
            [-72.315552, 63.733051],
            [-72.357773, 63.761379],
            [-72.252792, 63.760281],
            [-72.271942, 63.797779],
            [-72.222778, 63.861382],
            [-72.092499, 63.904709],
            [-72.218338, 63.895],
            [-72.246384, 63.950272],
            [-72.276398, 63.90749],
            [-72.246658, 63.883331],
            [-72.3125, 63.896389],
            [-72.405563, 63.826389],
            [-72.372772, 63.778599],
            [-72.526398, 63.787769],
            [-72.456947, 63.81583],
            [-72.596947, 63.86832],
            [-72.660553, 63.850269],
            [-72.641113, 63.90443],
            [-72.665558, 63.922218],
            [-72.604172, 63.949429],
            [-72.592773, 64.022217],
            [-72.676102, 64.020264],
            [-72.664719, 64.080551],
            [-72.705841, 64.009163],
            [-72.658623, 63.98777],
            [-72.733322, 63.962769],
            [-72.758347, 64.004173],
            [-72.940277, 64.058594],
            [-72.870827, 64.093872],
            [-72.897232, 64.156937],
            [-73.08139, 64.183594],
            [-73.05249, 64.224991],
            [-73.130829, 64.266937],
            [-73.107224, 64.290817],
            [-73.210564, 64.291931],
            [-73.236107, 64.330551],
            [-73.310547, 64.310806],
            [-73.279167, 64.258614],
            [-73.384171, 64.272491],
            [-73.391388, 64.338593],
            [-73.333328, 64.378311],
            [-73.260559, 64.363312],
            [-73.351936, 64.401657],
            [-73.41777, 64.371094],
            [-73.471939, 64.414993],
            [-73.316391, 64.45665],
            [-73.419724, 64.477203],
            [-73.278061, 64.519989],
            [-73.16777, 64.57666],
            [-73.165558, 64.607483],
            [-73.275284, 64.595261],
            [-73.309433, 64.6586],
            [-73.347504, 64.634987],
            [-73.302223, 64.583878],
            [-73.307503, 64.535812],
            [-73.463333, 64.502487],
            [-73.486816, 64.571342],
            [-73.402222, 64.549423],
            [-73.457779, 64.58638],
            [-73.430557, 64.603317],
            [-73.571121, 64.585274],
            [-73.656662, 64.663597],
            [-73.689438, 64.583603],
            [-73.644173, 64.566093],
            [-73.753891, 64.535263],
            [-73.929443, 64.602203],
            [-73.786392, 64.476646],
            [-73.815277, 64.459152],
            [-73.882767, 64.494141],
            [-73.86972, 64.468323],
            [-73.906387, 64.455551],
            [-73.961121, 64.477203],
            [-73.972778, 64.430267],
            [-73.940552, 64.404427],
            [-73.985001, 64.387497],
            [-73.993332, 64.3311],
            [-74.089996, 64.329163],
            [-74.175003, 64.36026],
            [-74.102219, 64.367477],
            [-74.107498, 64.403053],
            [-74.077499, 64.411377],
            [-74.170273, 64.461647],
            [-74.04361, 64.443039],
            [-74.127487, 64.534416],
            [-73.953613, 64.516098],
            [-74.164436, 64.570541],
            [-73.999443, 64.58194],
            [-74.083618, 64.631088],
            [-74.060547, 64.733322],
            [-74.120537, 64.741928],
            [-74.119453, 64.676933],
            [-74.22139, 64.671097],
            [-74.208893, 64.614151],
            [-74.24028, 64.580276],
            [-74.298607, 64.625526],
            [-74.264183, 64.654984],
            [-74.316391, 64.681931],
            [-74.338333, 64.627197],
            [-74.417503, 64.659988],
            [-74.347229, 64.591927],
            [-74.381943, 64.569992],
            [-74.431107, 64.596367],
            [-74.415558, 64.623032],
            [-74.535278, 64.622208],
            [-74.57251, 64.664703],
            [-74.704178, 64.735527],
            [-74.565002, 64.845833],
            [-74.478882, 64.835823],
            [-74.577789, 64.86998],
            [-74.545837, 64.892212],
            [-74.639999, 64.903587],
            [-74.660828, 64.893883],
            [-74.63945, 64.861366],
            [-74.732773, 64.854713],
            [-74.706673, 64.800537],
            [-74.726387, 64.770828],
            [-74.811111, 64.804153],
            [-74.837219, 64.778587],
            [-74.985283, 64.795822],
            [-74.470001, 64.55748],
            [-74.638901, 64.538307],
            [-74.537216, 64.510536],
            [-74.616653, 64.498032],
            [-74.501106, 64.455833],
            [-74.667221, 64.40387],
            [-74.680557, 64.372208],
            [-74.974716, 64.416092],
            [-75.174713, 64.492752],
            [-75.18721, 64.473602],
            [-75.144173, 64.453049],
            [-75.215561, 64.428864],
            [-75.331833, 64.490387],
            [-75.536667, 64.536652],
            [-75.568336, 64.569443],
            [-75.843063, 64.607758],
            [-75.781387, 64.571381],
            [-75.837219, 64.561371],
            [-75.818893, 64.530823],
            [-75.631668, 64.453598],
            [-75.73555, 64.470261],
            [-75.708344, 64.437759],
            [-75.922501, 64.481087],
            [-75.801392, 64.436096],
            [-75.821953, 64.425537],
            [-75.726936, 64.367203],
            [-75.944443, 64.376083],
            [-75.982773, 64.404427],
            [-76.071671, 64.359993],
            [-76.121384, 64.387497],
            [-76.169998, 64.373596],
            [-76.140556, 64.364433],
            [-76.166656, 64.349991],
            [-76.375, 64.356934],
            [-76.323898, 64.315811],
            [-76.188316, 64.306931],
            [-76.300552, 64.27887],
            [-76.438049, 64.31749],
            [-76.483887, 64.306091],
            [-76.378052, 64.279427],
            [-76.484734, 64.266663],
            [-76.541382, 64.304703],
            [-76.658051, 64.276657],
            [-76.705841, 64.300812],
            [-76.738892, 64.276382],
            [-76.721657, 64.238312],
            [-76.660278, 64.218323],
            [-76.670547, 64.184143],
            [-76.841667, 64.202209],
            [-76.827499, 64.226929],
            [-77.122772, 64.295532],
            [-77.37999, 64.245247],
            [-77.37027, 64.280548],
            [-77.451111, 64.294434],
            [-77.386673, 64.306091],
            [-77.400284, 64.321663],
            [-77.542503, 64.311371],
            [-77.489441, 64.348328],
            [-77.652222, 64.388046],
            [-77.643341, 64.334717],
            [-77.672501, 64.318047],
            [-77.785004, 64.369431],
            [-77.877777, 64.373871],
            [-77.905563, 64.400543],
            [-77.842773, 64.425537],
            [-78.008347, 64.424423],
            [-77.973053, 64.463608],
            [-78.032227, 64.485809],
            [-78.06778, 64.498032],
            [-78.068619, 64.540817],
            [-78.183319, 64.572487],
            [-78.128601, 64.589157],
            [-78.194992, 64.648613],
            [-78.159157, 64.697197],
            [-78.299156, 64.704712],
            [-78.184433, 64.723602],
            [-78.187767, 64.753883],
            [-78.073624, 64.813599],
            [-78.066391, 64.85582],
            [-78.117218, 64.876083],
            [-78.149727, 64.952209],
            [-77.963898, 65.047493],
            [-77.628052, 65.143051],
            [-77.488327, 65.139427],
            [-77.328613, 65.178864],
            [-77.315552, 65.199417],
            [-77.513062, 65.318878],
            [-77.461121, 65.37915],
            [-77.287514, 65.367752],
            [-77.421661, 65.46138],
            [-77.238052, 65.469437],
            [-77.267227, 65.457489],
            [-77.08667, 65.407211],
            [-76.920837, 65.429428],
            [-76.66362, 65.410263],
            [-75.785553, 65.224426],
            [-75.733322, 65.152206],
            [-75.524628, 65.103271],
            [-75.428047, 65.048317],
            [-75.415558, 64.977478],
            [-75.507233, 64.938309],
            [-75.667503, 64.940811],
            [-75.605827, 64.913307],
            [-75.587509, 64.856644],
            [-75.454727, 64.838882],
            [-75.530838, 64.775543],
            [-75.443329, 64.765266],
            [-75.396667, 64.717209],
            [-75.298187, 64.725769],
            [-75.373894, 64.833054],
            [-75.343887, 64.919708],
            [-75.469727, 64.869431],
            [-75.567497, 64.883614],
            [-75.482773, 64.896378],
            [-75.473892, 64.935806],
            [-75.384743, 64.981934],
            [-75.269173, 64.950546],
            [-75.29306, 64.989151],
            [-75.243057, 65.007767],
            [-75.186943, 65.101646],
            [-75.259171, 65.102203],
            [-75.279999, 65.035263],
            [-75.360001, 65.003883],
            [-75.597504, 65.184143],
            [-75.714722, 65.186371],
            [-75.761948, 65.237762],
            [-75.903343, 65.25943],
            [-75.950287, 65.318329],
            [-75.696114, 65.303864],
            [-75.625549, 65.345543],
            [-75.566391, 65.274986],
            [-75.186661, 65.251938],
            [-75.083618, 65.286102],
            [-75.070847, 65.330276],
            [-75.108047, 65.393051],
            [-75, 65.379707],
            [-74.773064, 65.386108],
            [-74.714447, 65.431664],
            [-74.66861, 65.424149],
            [-74.704178, 65.40332],
            [-74.659439, 65.388321],
            [-74.690552, 65.374977],
            [-74.669449, 65.349716],
            [-74.524437, 65.333328],
            [-74.357773, 65.398613],
            [-74.299988, 65.472214],
            [-74.15889, 65.485809],
            [-74.18277, 65.525269],
            [-74.105827, 65.534988],
            [-73.994453, 65.503052],
            [-73.845284, 65.532211],
            [-73.736938, 65.514709],
            [-73.740829, 65.490807],
            [-73.663887, 65.45694],
            [-73.479721, 65.450821],
            [-73.569458, 65.577209],
            [-73.631378, 65.607483],
            [-73.611656, 65.646103],
            [-73.70195, 65.685806],
            [-73.652496, 65.718597],
            [-73.709732, 65.762497],
            [-74.058037, 65.875526],
            [-74.444992, 66.096939],
            [-74.46611, 66.151932],
            [-74.342216, 66.225273],
            [-73.906952, 66.353043],
            [-73.444153, 66.551086],
            [-73.351936, 66.649986],
            [-73.108612, 66.723312],
            [-72.878326, 66.925537],
            [-72.824173, 67.029427],
            [-72.684998, 67.076103],
            [-72.368607, 67.112488],
            [-72.276108, 67.166931],
            [-72.258347, 67.248032],
            [-72.192757, 67.273041],
            [-72.374443, 67.325821],
            [-72.35611, 67.357758],
            [-72.439713, 67.476646],
            [-72.506119, 67.496933],
            [-72.422234, 67.529984],
            [-72.473892, 67.566383],
            [-72.485817, 67.623032],
            [-72.595001, 67.633881],
            [-72.676392, 67.693863],
            [-72.583328, 67.750267],
            [-72.612503, 67.790268],
            [-72.763062, 67.876373],
            [-72.843887, 67.850807],
            [-72.90834, 67.903053],
            [-72.881378, 67.934418],
            [-72.942207, 67.925262],
            [-72.896118, 68.01416],
            [-72.98111, 68.13916],
            [-72.992218, 68.234421],
            [-73.035553, 68.235527],
            [-73.05249, 68.199142],
            [-73.221657, 68.22998],
            [-73.189438, 68.254707],
            [-73.215561, 68.272774],
            [-73.338898, 68.27916],
            [-73.319733, 68.25],
            [-73.361107, 68.243042],
            [-73.496109, 68.275543],
            [-73.30777, 68.320267],
            [-73.289436, 68.345833],
            [-73.31723, 68.362488],
            [-73.21167, 68.376923],
            [-73.253067, 68.390823],
            [-73.357498, 68.371368],
            [-73.349442, 68.3311],
            [-73.471687, 68.330109],
            [-73.632217, 68.246643],
            [-73.684998, 68.271378],
            [-73.591377, 68.302467],
            [-73.835564, 68.337196],
            [-73.948608, 68.411926],
            [-73.867218, 68.493042],
            [-73.72583, 68.525818],
            [-73.815277, 68.570541],
            [-73.719727, 68.614433],
            [-73.823898, 68.617203],
            [-73.706947, 68.653587],
            [-73.795273, 68.664429],
            [-73.753891, 68.683594],
            [-74.178879, 68.724152],
            [-73.888062, 68.561653],
            [-73.90361, 68.527771],
            [-73.99028, 68.492752],
            [-74.031387, 68.508614],
            [-74.006958, 68.532494],
            [-74.190826, 68.508881],
            [-74.366096, 68.539146],
            [-74.514183, 68.599991],
            [-74.541672, 68.666092],
            [-74.726097, 68.73027],
            [-74.719452, 68.770538],
            [-74.57695, 68.793053],
            [-74.551392, 68.830551],
            [-74.678329, 68.842758],
            [-74.749161, 68.816383],
            [-74.63501, 68.79332],
            [-74.778343, 68.765823],
            [-74.984444, 68.809418],
            [-74.691673, 68.8647],
            [-74.679443, 68.927757],
            [-75.041847, 68.902992],
            [-74.840843, 68.934708],
            [-75, 68.93074],
            [-74.880547, 68.967758],
            [-75.04277, 68.928307],
            [-74.930283, 68.997208],
            [-74.642776, 69.011658],
            [-74.660828, 69.044434],
            [-74.785828, 69.076393],
            [-75.043327, 69.013321],
            [-75.071404, 68.921097],
            [-75.139717, 68.88472],
            [-75.478882, 69.019707],
            [-75.578613, 68.984154],
            [-75.495003, 68.930267],
            [-75.603882, 68.8797],
            [-76.436394, 68.6772],
            [-76.660828, 68.699417],
            [-76.688049, 68.746933],
            [-76.521667, 68.870247],
            [-76.606659, 68.883041],
            [-76.655563, 68.924698],
            [-76.625549, 69.018333],
            [-76.348892, 69.054977],
            [-75.996933, 69.003036],
            [-75.775009, 69.088593],
            [-75.605827, 69.085274],
            [-75.569458, 69.158333],
            [-75.591667, 69.221649],
            [-75.95639, 69.366089],
            [-76.202499, 69.413879],
            [-76.389183, 69.395538],
            [-76.424713, 69.454437],
            [-76.637787, 69.546654],
            [-76.620003, 69.58638],
            [-76.477783, 69.652206],
            [-76.210831, 69.6147],
            [-76.227783, 69.641373],
            [-76.187767, 69.665268],
            [-76.376389, 69.671371],
            [-76.407227, 69.700546],
            [-76.634171, 69.683594],
            [-76.522781, 69.652481],
            [-76.561661, 69.620247],
            [-76.642502, 69.620247],
            [-76.707497, 69.559418],
            [-76.876099, 69.583054],
            [-76.821121, 69.626083],
            [-76.967499, 69.591927],
            [-77.191673, 69.639709],
            [-77.155563, 69.680542],
            [-76.820282, 69.699417],
            [-76.781387, 69.748596],
            [-76.887222, 69.751099],
            [-76.909157, 69.791656],
            [-76.757507, 69.815536],
            [-76.797501, 69.836113],
            [-77.058327, 69.813309],
            [-77.00473, 69.856369],
            [-77.150284, 69.816093],
            [-77.308884, 69.830833],
            [-77.243057, 69.881363],
            [-76.974716, 69.935806],
            [-77.188316, 69.906097],
            [-77.630547, 69.823036],
            [-77.508347, 69.82666],
            [-77.444443, 69.789429],
            [-77.509743, 69.796944],
            [-77.551392, 69.747757],
            [-77.615829, 69.741653],
            [-77.680557, 69.819153],
            [-77.672501, 70.180542],
            [-77.795273, 70.25499],
            [-78.054169, 70.218048],
            [-78.195831, 70.25499],
            [-78.131943, 70.218323],
            [-78.170273, 70.19136],
            [-78.254997, 70.228043],
            [-78.333328, 70.198318],
            [-78.401672, 70.212486],
            [-78.430832, 70.262207],
            [-78.539993, 70.306374],
            [-78.396118, 70.328049],
            [-78.425827, 70.347763],
            [-78.557503, 70.351379],
            [-78.578888, 70.309708],
            [-78.662514, 70.35054],
            [-78.635559, 70.373032],
            [-78.704727, 70.374687],
            [-78.743881, 70.449142],
            [-78.820007, 70.438873],
            [-78.889183, 70.479156],
            [-78.90834, 70.447479],
            [-78.873047, 70.407494],
            [-79.070557, 70.469711],
            [-79.046112, 70.483047],
            [-79.100281, 70.498322],
            [-79.037514, 70.516663],
            [-79.08168, 70.529984],
            [-78.87999, 70.546371],
            [-78.995827, 70.556374],
            [-78.87999, 70.594711],
            [-78.718887, 70.54776],
            [-78.840561, 70.634987],
            [-78.976936, 70.635818],
            [-79.009453, 70.679703],
            [-79.158051, 70.622208],
            [-79.001953, 70.613312],
            [-79.171944, 70.513893],
            [-79.142227, 70.501938],
            [-79.174156, 70.42804],
            [-79.335007, 70.438873],
            [-79.291107, 70.44693],
            [-79.297783, 70.489967],
            [-79.412781, 70.491653],
            [-79.395279, 70.437187],
            [-79.500557, 70.452209],
            [-79.588898, 70.399429],
            [-79.443329, 70.349426],
            [-79.357773, 70.369713],
            [-79.209442, 70.313309],
            [-79.108887, 70.304977],
            [-79.038887, 70.340553],
            [-78.921661, 70.300812],
            [-78.763062, 70.183594],
            [-78.743332, 70.073036],
            [-78.688599, 70.054977],
            [-78.664169, 70.004173],
            [-78.676392, 69.945251],
            [-78.795837, 69.876648],
            [-79.377777, 69.886108],
            [-79.682503, 69.848877],
            [-79.901947, 69.976379],
            [-80.009453, 69.941093],
            [-80.053047, 69.997208],
            [-80.232498, 70.007767],
            [-80.326111, 69.98027],
            [-80.577499, 70.048866],
            [-80.983612, 70.056931],
            [-81.069733, 70.097488],
            [-81.429993, 70.093597],
            [-81.728333, 70.132202],
            [-81.761948, 70.117477],
            [-81.709732, 70.074432],
            [-81.539719, 70.053307],
            [-81.441101, 69.999153],
            [-81.338333, 70.0336],
            [-81.188599, 69.991089],
            [-81.126389, 69.927757],
            [-80.791382, 69.790543],
            [-80.775558, 69.752213],
            [-80.967773, 69.714432],
            [-80.952217, 69.732758],
            [-81.103058, 69.768333],
            [-81.166946, 69.821381],
            [-81.599731, 69.943039],
            [-81.577499, 69.968872],
            [-81.76973, 69.97554],
            [-82.232773, 70.146378],
            [-82.558327, 70.184982],
            [-82.992767, 70.303589],
            [-83.04805, 70.306931],
            [-82.813316, 70.20665],
            [-82.103058, 70.065262],
            [-81.906387, 69.970833],
            [-81.710831, 69.934143],
            [-81.768066, 69.901382],
            [-81.744453, 69.871643],
            [-81.850281, 69.884987],
            [-81.854172, 69.855553],
            [-81.960281, 69.844147],
            [-82.019997, 69.873871],
            [-82.144997, 69.823883],
            [-82.118607, 69.810532],
            [-82.143341, 69.781372],
            [-82.29805, 69.801376],
            [-82.262787, 69.823883],
            [-82.321953, 69.830276],
            [-82.206673, 69.828598],
            [-82.303047, 69.856644],
            [-82.463623, 69.841087],
            [-83.066673, 70.010818],
            [-83.265839, 70.013893],
            [-83.345284, 69.977203],
            [-83.715286, 69.947746],
            [-84.728607, 70.010269],
            [-84.823334, 70.02832],
            [-84.870827, 70.071663],
            [-84.796951, 70.107758],
            [-84.982773, 70.064148],
            [-85.666397, 70.104713],
            [-85.869453, 70.085541],
            [-85.851669, 70.038307],
            [-85.618057, 70.070831],
            [-85.236107, 69.989151],
            [-85.726936, 69.99054],
            [-86.254997, 70.105553],
            [-86.551941, 70.234993],
            [-86.548889, 70.296654],
            [-86.574722, 70.311371],
            [-86.55249, 70.346939],
            [-86.591667, 70.358322],
            [-86.512787, 70.416382],
            [-86.294998, 70.472763],
            [-86.390839, 70.522217],
            [-86.339447, 70.486366],
            [-86.400558, 70.463318],
            [-86.678047, 70.373032],
            [-86.647232, 70.319443],
            [-86.876099, 70.326103],
            [-86.88945, 70.357758],
            [-86.739441, 70.382477],
            [-86.930557, 70.391937],
            [-86.870537, 70.413879],
            [-86.978058, 70.420822],
            [-86.921387, 70.45166],
            [-86.953339, 70.467209],
            [-87.132767, 70.439148],
            [-87.144173, 70.404427],
            [-87.246948, 70.383041],
            [-87.010559, 70.371643],
            [-86.972778, 70.351929],
            [-86.985817, 70.281662],
            [-87.756958, 70.332489],
            [-87.785553, 70.30304],
            [-87.613327, 70.281937],
            [-87.866386, 70.238876],
            [-88.01445, 70.295532],
            [-88.250557, 70.321381],
            [-88.206673, 70.352478],
            [-87.999443, 70.303864],
            [-87.889999, 70.32193],
            [-88.402222, 70.448318],
            [-88.693047, 70.455261],
            [-88.88945, 70.52887],
            [-89.003891, 70.624977],
            [-89.00528, 70.656937],
            [-89.338058, 70.795258],
            [-89.448334, 70.902481],
            [-89.222778, 70.935532],
            [-89.195267, 70.968323],
            [-89.559433, 71.090271],
            [-89.228333, 71.072769],
            [-89.203056, 71.03804],
            [-89.134453, 71.026932],
            [-88.634171, 71.052467],
            [-88.38028, 71.011932],
            [-88.318619, 70.953873],
            [-88.025284, 70.930542],
            [-87.353607, 70.945251],
            [-87.308609, 70.995529],
            [-87.246948, 71.009163],
            [-87.002792, 70.994141],
            [-87.385834, 71.041931],
            [-87.712784, 71.126083],
            [-87.848892, 71.184982],
            [-87.829453, 71.261932],
            [-87.911667, 71.266937],
            [-88.084442, 71.20665],
            [-89.662514, 71.307747],
            [-89.899727, 71.351379],
            [-90, 71.455101],
            [-89.951401, 71.520477],
            [-90.040428, 71.597748],
            [-89.795837, 71.75972],
            [-89.914436, 71.791656],
            [-89.883621, 71.813309],
            [-89.954453, 71.820541],
            [-89.955276, 71.851089],
            [-90, 71.864891],
            [-90.119453, 71.926376],
            [-90, 72.057114],
            [-89.738892, 72.124977],
            [-89.664719, 72.113312],
            [-89.574722, 72.163597],
            [-89.780563, 72.157494],
            [-89.897232, 72.188583],
            [-89.960281, 72.251663],
            [-89.928596, 72.265823],
            [-89.956673, 72.321663],
            [-89.827499, 72.396942],
            [-89.907501, 72.432213],
            [-89.781387, 72.426933],
            [-89.897957, 72.43544],
            [-89.79361, 72.462769],
            [-89.753891, 72.605553],
            [-89.572777, 72.616928],
            [-89.545273, 72.632477],
            [-89.556953, 72.663879],
            [-89.470551, 72.666092],
            [-89.574722, 72.698868],
            [-89.547501, 72.76944],
            [-89.574722, 72.785263],
            [-89.30278, 72.75],
            [-89.245537, 72.785263],
            [-89.414436, 72.825272],
            [-89.295837, 72.919434],
            [-89.361389, 72.991653],
            [-89.205276, 73.013611],
            [-89.308884, 73.048317],
            [-89.235817, 73.120819],
            [-89.125816, 73.146103],
            [-89.180557, 73.156372],
            [-89.087784, 73.207207],
            [-88.888062, 73.212769],
            [-89.046661, 73.246643],
            [-88.95639, 73.293053],
            [-88.618881, 73.280273],
            [-88.856949, 73.336113],
            [-88.074722, 73.627762],
            [-87.456673, 73.760269],
            [-86.54834, 73.85054],
            [-85.239441, 73.821663],
            [-84.837784, 73.741653],
            [-84.956123, 73.665268],
            [-85.573334, 73.508041],
            [-85.915283, 73.366089],
            [-86.11972, 73.246094],
            [-86.32251, 73.046371],
            [-86.65361, 72.868874],
            [-86.740547, 72.727768],
            [-86.68222, 72.683594],
            [-86.705276, 72.665543],
            [-86.686943, 72.644707],
            [-86.397232, 72.534988],
            [-86.240829, 72.420258],
            [-86.435822, 72.270264],
            [-86.459167, 72.201393],
            [-86.429443, 72.168587],
            [-86.420547, 72.012772],
            [-86.330841, 71.98027],
            [-86.332497, 71.947197],
            [-86.11055, 71.783051],
            [-85.747498, 71.64444],
            [-85.500839, 71.511108],
            [-84.934723, 71.41832],
            [-84.915009, 71.388321],
            [-84.951111, 71.34082],
            [-84.872223, 71.333328],
            [-84.832779, 71.27887],
            [-85.254181, 71.287491],
            [-85.273331, 71.277481],
            [-85.246948, 71.262497],
            [-85.172501, 71.272491],
            [-85.234444, 71.247208],
            [-85.472229, 71.231369],
            [-85.393066, 71.222214],
            [-85.399986, 71.193863],
            [-85.463898, 71.211113],
            [-85.514717, 71.176086],
            [-85.729172, 71.2061],
            [-85.93277, 71.178864],
            [-86.248894, 71.058594],
            [-86.820007, 70.988586],
            [-86.601936, 70.971649],
            [-86.270279, 71.002777],
            [-85.802223, 71.135818],
            [-85.411667, 71.174423],
            [-85.16333, 71.136658],
            [-85.088898, 71.151932],
            [-85.116943, 71.179153],
            [-84.875267, 71.17276],
            [-84.84584, 71.147774],
            [-84.875816, 71.069992],
            [-84.96611, 71.100273],
            [-85.127434, 71.087479],
            [-84.960281, 71.07222],
            [-85.005569, 71.030548],
            [-84.929718, 71.00444],
            [-84.975281, 70.945251],
            [-84.958618, 70.919434],
            [-84.79361, 70.926651],
            [-84.748047, 70.988037],
            [-84.829727, 71.073318],
            [-84.801392, 71.183319],
            [-84.665558, 71.217209],
            [-84.79306, 71.278053],
            [-84.740829, 71.377197],
            [-84.762512, 71.406647],
            [-84.526108, 71.468872],
            [-84.561394, 71.549988],
            [-84.65834, 71.583878],
            [-84.606659, 71.649429],
            [-84.782784, 71.678864],
            [-84.867218, 71.668053],
            [-84.840561, 71.641937],
            [-84.876099, 71.627762],
            [-85.279716, 71.668587],
            [-85.579727, 71.784988],
            [-85.43277, 71.806374],
            [-85.559723, 71.900543],
            [-86.022781, 71.980553],
            [-86.050827, 72.011108],
            [-85.9664, 71.97998],
            [-85.98111, 72.028587],
            [-85.72139, 72.004707],
            [-85.776398, 72.023613],
            [-85.764717, 72.043053],
            [-85.509453, 72.068047],
            [-85.495003, 72.089157],
            [-85.540558, 72.124153],
            [-85.396667, 72.137497],
            [-85.509171, 72.178864],
            [-85.496109, 72.210274],
            [-85.538063, 72.23526],
            [-85.41777, 72.220261],
            [-85.5, 72.248322],
            [-85.47084, 72.262497],
            [-84.919998, 72.232758],
            [-84.273903, 72.022491],
            [-84.184723, 71.930542],
            [-84.158623, 71.977203],
            [-84.189438, 72.008614],
            [-84.164719, 72.021103],
            [-84.346657, 72.069717],
            [-84.413887, 72.119713],
            [-84.378326, 72.127762],
            [-84.613327, 72.163597],
            [-84.743057, 72.223602],
            [-84.669724, 72.235809],
            [-84.933319, 72.284416],
            [-84.816101, 72.352768],
            [-84.521118, 72.35054],
            [-84.442207, 72.383041],
            [-84.864166, 72.366928],
            [-84.870827, 72.400818],
            [-84.786667, 72.408867],
            [-84.776947, 72.458328],
            [-85.127213, 72.351929],
            [-85.115829, 72.383041],
            [-85.33902, 72.406418],
            [-85.535278, 72.469711],
            [-85.617218, 72.545532],
            [-85.475563, 72.575272],
            [-85.623894, 72.586929],
            [-85.705276, 72.637772],
            [-85.68222, 72.680817],
            [-85.708344, 72.743042],
            [-85.650284, 72.864151],
            [-85.688049, 72.8936],
            [-85.570557, 72.966927],
            [-85.283073, 72.964432],
            [-83.955276, 72.748322],
            [-85.091667, 72.983047],
            [-85.059998, 72.996643],
            [-85.129173, 73.031372],
            [-85.178329, 73.006653],
            [-85.535278, 73.021927],
            [-85.406952, 73.136383],
            [-85.277222, 73.068878],
            [-85.142227, 73.047493],
            [-85.229172, 73.123032],
            [-85.148354, 73.141663],
            [-84.829727, 73.085541],
            [-83.867767, 73.029709],
            [-83.634453, 72.982483],
            [-83.784157, 73.040543],
            [-84.584442, 73.115807],
            [-85.170547, 73.210823],
            [-85.188599, 73.228867],
            [-85.134171, 73.305542],
            [-85.017227, 73.348328],
            [-84.786942, 73.388046],
            [-84.654999, 73.305542],
            [-84.35527, 73.223038],
            [-84.413063, 73.272217],
            [-84.586937, 73.323036],
            [-84.598343, 73.362488],
            [-84.656113, 73.390549],
            [-84.171387, 73.475273],
            [-83.751923, 73.42749],
            [-83.689438, 73.323608],
            [-83.600563, 73.297493],
            [-83.594162, 73.325272],
            [-83.650833, 73.367477],
            [-83.625, 73.415268],
            [-83.652496, 73.445251],
            [-84.006958, 73.50972],
            [-83.201111, 73.660812],
            [-83.045837, 73.645828],
            [-82.840843, 73.732758],
            [-81.57251, 73.719711],
            [-81.45723, 73.691093],
            [-81.449997, 73.65332],
            [-81.263062, 73.564148],
            [-81.180283, 73.415268],
            [-81.204727, 73.266663],
            [-80.59639, 73.148041],
            [-80.549438, 73.08194],
            [-80.59584, 73.045822],
            [-80.584732, 73.013893],
            [-80.647507, 72.99054],
            [-80.638344, 72.92276],
            [-80.487778, 72.828598],
            [-80.319458, 72.799149],
            [-80.247498, 72.730553],
            [-80.541382, 72.62886],
            [-80.531952, 72.573608],
            [-80.94249, 72.455261],
            [-81.099731, 72.353592],
            [-81.176102, 72.338593],
            [-81.199722, 72.289146],
            [-81.37944, 72.241653],
            [-81.229721, 72.258614],
            [-80.880547, 72.438309],
            [-80.516403, 72.503883],
            [-80.492767, 72.453049],
            [-80.514183, 72.3797],
            [-80.794159, 72.284714],
            [-80.864166, 72.225273],
            [-80.838898, 72.199707],
            [-80.969162, 72.199997],
            [-80.56723, 72.072769],
            [-80.646118, 72.063309],
            [-80.771393, 72.104156],
            [-81.025558, 72.091927],
            [-80.996109, 72.066673],
            [-81.08667, 72.046654],
            [-80.847229, 72.056641],
            [-80.79277, 72.027771],
            [-80.827789, 72.001663],
            [-80.821404, 71.956383],
            [-80.975014, 71.895828],
            [-80.950287, 71.881088],
            [-80.756119, 71.934143],
            [-80.746948, 71.982483],
            [-80.38501, 72.048317],
            [-80.347229, 72.088318],
            [-80.486656, 72.189423],
            [-80.241096, 72.197746],
            [-80.306381, 72.255257],
            [-80.260834, 72.294983],
            [-80.155838, 72.273613],
            [-79.991669, 72.176651],
            [-79.674438, 72.126648],
            [-79.979973, 72.217178],
            [-80.164436, 72.327477],
            [-80.064713, 72.383614],
            [-79.874443, 72.426933],
            [-79.851936, 72.462769],
            [-79.870827, 72.483047],
            [-79.836937, 72.498596],
            [-79.692207, 72.466927],
            [-79.769173, 72.412201],
            [-79.595551, 72.334717],
            [-79.638901, 72.289146],
            [-79.768623, 72.245247],
            [-79.75, 72.215553],
            [-79.565826, 72.275269],
            [-79.450562, 72.194977],
            [-79.467216, 72.24942],
            [-79.54361, 72.285812],
            [-79.329727, 72.397217],
            [-79.027786, 72.277481],
            [-78.948608, 72.273041],
            [-78.985817, 72.234154],
            [-78.943604, 72.193039],
            [-79.005569, 72.143883],
            [-79.036392, 72.069443],
            [-79.233063, 71.976379],
            [-79.026672, 71.970543],
            [-78.551392, 71.861099],
            [-78.508347, 71.876373],
            [-78.592499, 71.903053],
            [-78.607498, 71.938583],
            [-78.923607, 72.014999],
            [-78.876099, 72.06192],
            [-78.854446, 72.173027],
            [-78.476097, 72.087196],
            [-78.381943, 71.92804],
            [-78.184998, 71.81749],
            [-77.904449, 71.768051],
            [-78.139999, 71.830551],
            [-78.308884, 71.921097],
            [-78.305267, 71.94693],
            [-78.156952, 71.968323],
            [-77.96611, 71.837196],
            [-77.797234, 71.792763],
            [-77.759453, 71.75],
            [-77.698036, 71.81192],
            [-77.907227, 71.848038],
            [-78.103607, 71.944702],
            [-78.116386, 71.976929],
            [-78.32251, 71.985809],
            [-78.375549, 72.085823],
            [-78.436943, 72.117477],
            [-78.854721, 72.214432],
            [-78.86055, 72.23526],
            [-78.749161, 72.280273],
            [-78.819733, 72.314697],
            [-78.79277, 72.337769],
            [-78.604172, 72.359421],
            [-78.515839, 72.330551],
            [-78.529167, 72.235527],
            [-78.40834, 72.166382],
            [-78.384171, 72.175537],
            [-78.411118, 72.21666],
            [-78.472778, 72.242477],
            [-78.445831, 72.281097],
            [-78.468338, 72.314987],
            [-78.40834, 72.325821],
            [-77.665283, 72.204712],
            [-77.644447, 72.186653],
            [-77.665009, 72.16304],
            [-77.620537, 72.136108],
            [-77.657227, 72.094147],
            [-77.589722, 72.127472],
            [-77.622223, 72.164429],
            [-77.588898, 72.181374],
            [-77.289436, 72.183319],
            [-76.997223, 72.128036],
            [-77.278061, 72.19693],
            [-77.549988, 72.198029],
            [-78.389183, 72.373032],
            [-78.559158, 72.438026],
            [-78.556664, 72.50444],
            [-78.430557, 72.586647],
            [-77.613892, 72.751663],
            [-77.063049, 72.753326],
            [-76.753067, 72.720543],
            [-76.65361, 72.670822],
            [-76.681671, 72.642487],
            [-76.166946, 72.58638],
            [-76.162514, 72.526093],
            [-76.107773, 72.473038],
            [-76.036942, 72.489433],
            [-76.064713, 72.549988],
            [-75.931953, 72.583603],
            [-75.231667, 72.500549],
            [-75, 72.29837],
            [-74.947487, 72.24971],
            [-75.238327, 72.118317],
            [-75.607498, 72.143333],
            [-76.015007, 72.086647],
            [-76.410553, 71.859154],
            [-76.374443, 71.842484],
            [-76.238602, 71.931091],
            [-76.089722, 71.978867],
            [-76.01973, 72.046097],
            [-75.796661, 72.103592],
            [-75.528343, 72.120819],
            [-75.233322, 72.084152],
            [-75.221123, 72.064697],
            [-75.281677, 72.038589],
            [-75.606377, 71.989433],
            [-75.692207, 71.833328],
            [-75.80249, 71.750549],
            [-76.096123, 71.697479],
            [-75.88028, 71.702477],
            [-75.794998, 71.725807],
            [-75.654999, 71.826103],
            [-75.570282, 71.91748],
            [-75.558327, 71.976646],
            [-75.197769, 72.023323],
            [-75.035828, 72.125809],
            [-74.244164, 72.073036],
            [-74.117767, 71.969994],
            [-74.212219, 71.912773],
            [-74.166107, 71.874687],
            [-74.230003, 71.822769],
            [-74.439987, 71.801933],
            [-74.539436, 71.828873],
            [-74.575012, 71.818878],
            [-74.551392, 71.794434],
            [-74.713623, 71.73526],
            [-75.389999, 71.681091],
            [-75.04361, 71.699707],
            [-74.933609, 71.663879],
            [-75.406387, 71.512207],
            [-74.672783, 71.692467],
            [-74.631943, 71.646378],
            [-74.713058, 71.583878],
            [-75, 71.535583],
            [-75.152222, 71.466087],
            [-75.033333, 71.513046],
            [-74.838333, 71.521927],
            [-74.816101, 71.493317],
            [-74.925552, 71.454163],
            [-74.736938, 71.429703],
            [-74.699432, 71.390823],
            [-75.087219, 71.200272],
            [-75.065002, 71.180817],
            [-74.874161, 71.247757],
            [-74.637512, 71.380539],
            [-74.638062, 71.426651],
            [-74.73555, 71.472488],
            [-74.736107, 71.530548],
            [-74.620003, 71.558029],
            [-74.522781, 71.662201],
            [-74.381943, 71.6772],
            [-74.288597, 71.727478],
            [-74.109161, 71.735809],
            [-74.128883, 71.671654],
            [-74.254463, 71.606644],
            [-74.243057, 71.569717],
            [-74.156387, 71.532211],
            [-74.2164, 71.612762],
            [-74.09639, 71.652771],
            [-73.977783, 71.75972],
            [-73.704453, 71.774986],
            [-73.686111, 71.718872],
            [-73.604172, 71.772217],
            [-73.620003, 71.716087],
            [-73.791382, 71.661102],
            [-73.797783, 71.626648],
            [-73.890839, 71.609421],
            [-73.888901, 71.582489],
            [-73.98555, 71.534149],
            [-74.012222, 71.483597],
            [-73.988892, 71.46138],
            [-74.319168, 71.409416],
            [-74.02861, 71.437759],
            [-74.066963, 71.395538],
            [-74.063889, 71.336929],
            [-74.179169, 71.306374],
            [-74.091667, 71.285538],
            [-74.235001, 71.198318],
            [-73.996658, 71.324158],
            [-74.006958, 71.367203],
            [-73.968613, 71.419434],
            [-73.897781, 71.445534],
            [-73.867767, 71.525818],
            [-73.746948, 71.585274],
            [-73.621933, 71.583328],
            [-73.565002, 71.551933],
            [-73.619453, 71.515823],
            [-73.649437, 71.44136],
            [-73.583328, 71.388321],
            [-73.63501, 71.359421],
            [-73.51889, 71.37915],
            [-73.503067, 71.424698],
            [-73.446953, 71.440262],
            [-73.38028, 71.385269],
            [-73.464188, 71.372208],
            [-73.681953, 71.246094],
            [-73.735817, 71.093323],
            [-73.898354, 71.05748],
            [-73.674438, 71.088318],
            [-73.673607, 71.16304],
            [-73.623611, 71.22554],
            [-73.433884, 71.308594],
            [-73.436111, 71.340553],
            [-73.349731, 71.345261],
            [-73.049438, 71.268333],
            [-73.271942, 71.220543],
            [-73.235001, 71.162201],
            [-73.294449, 71.092484],
            [-73.450844, 71.035263],
            [-73.377487, 70.980553],
            [-73.399437, 71.041931],
            [-73.267059, 71.081779],
            [-73.196953, 71.13472],
            [-73.16861, 71.170532],
            [-73.180283, 71.211647],
            [-72.995003, 71.25],
            [-72.951401, 71.316093],
            [-72.993332, 71.401932],
            [-72.759171, 71.426933],
            [-72.838058, 71.506378],
            [-72.649727, 71.536926],
            [-72.557503, 71.660263],
            [-71.554169, 71.503883],
            [-71.444443, 71.468597],
            [-71.428329, 71.428589],
            [-71.301941, 71.388603],
            [-71.123322, 71.271652],
            [-71.340561, 71.170532],
            [-71.464447, 71.062477],
            [-71.910828, 71.107758],
            [-72.099731, 71.063873],
            [-72.101936, 71.016098],
            [-72.179718, 70.962196],
            [-72.297234, 70.947197],
            [-72.334167, 70.878593],
            [-72.771667, 70.815811],
            [-72.30278, 70.867203],
            [-72.16362, 70.83638],
            [-72.333069, 70.750816],
            [-72.316391, 70.723312],
            [-72.381943, 70.6772],
            [-72.623322, 70.628036],
            [-72.542503, 70.609993],
            [-72.326111, 70.667213],
            [-72.185547, 70.766388],
            [-71.626099, 70.863312],
            [-71.326111, 70.885536],
            [-71.185547, 70.851929],
            [-71.289169, 70.908867],
            [-71.208344, 71.00499],
            [-70.836121, 71.114433],
            [-70.613892, 71.062187],
            [-70.51445, 70.921097],
            [-70.726669, 70.829987],
            [-70.746948, 70.745529],
            [-71.100563, 70.654984],
            [-71.175278, 70.583603],
            [-71.592773, 70.606934],
            [-71.583618, 70.546371],
            [-71.737213, 70.483322],
            [-71.748894, 70.444427],
            [-71.960564, 70.420822],
            [-71.727219, 70.41748],
            [-71.755569, 70.329163],
            [-71.836937, 70.289978],
            [-71.733612, 70.313873],
            [-71.674713, 70.369713],
            [-71.655273, 70.472488],
            [-71.525284, 70.524696],
            [-71.506958, 70.576393],
            [-71.16777, 70.531937],
            [-71.273903, 70.336113],
            [-71.32251, 70.312759],
            [-71.285278, 70.251389],
            [-71.535828, 70.019707],
            [-71.33168, 70.128593],
            [-71.29834, 70.19664],
            [-71.210564, 70.262207],
            [-71.231949, 70.294983],
            [-71.060822, 70.520264],
            [-70.98999, 70.524429],
            [-71.053047, 70.55304],
            [-70.997223, 70.625809],
            [-70.844162, 70.641937],
            [-70.611938, 70.723602],
            [-69.892776, 70.883331],
            [-69.771118, 70.857483],
            [-69.87944, 70.768051],
            [-70.084732, 70.683594],
            [-70.245003, 70.638321],
            [-70.454727, 70.627762],
            [-70.478882, 70.606644],
            [-70.397507, 70.524147],
            [-70.477783, 70.47554],
            [-70.308037, 70.498871],
            [-70.39389, 70.611923],
            [-70.049438, 70.632751],
            [-70.027222, 70.589706],
            [-69.978607, 70.65332],
            [-69.775833, 70.682213],
            [-69.808609, 70.70166],
            [-69.771118, 70.712769],
            [-69.665558, 70.682213],
            [-69.620003, 70.758041],
            [-69.451401, 70.791931],
            [-69.226936, 70.779984],
            [-69.111656, 70.721916],
            [-68.284157, 70.540543],
            [-68.289719, 70.5],
            [-68.451401, 70.409416],
            [-68.458618, 70.369431],
            [-68.575844, 70.396103],
            [-68.54805, 70.447746],
            [-68.582497, 70.464432],
            [-68.661667, 70.431091],
            [-68.651108, 70.345833],
            [-68.682503, 70.329437],
            [-69.414436, 70.253601],
            [-69.66806, 70.198593],
            [-69.829453, 70.15416],
            [-69.91777, 70.085541],
            [-70.185547, 70.028053],
            [-69.901108, 70.048317],
            [-69.815552, 69.984711],
            [-70.148621, 69.962196],
            [-70.291382, 69.889427],
            [-70.450562, 69.857208],
            [-70.384171, 69.837196],
            [-70.076683, 69.958038],
            [-69.777222, 69.963608],
            [-69.735283, 69.99054],
            [-69.804718, 70.038879],
            [-69.79277, 70.080833],
            [-69.675827, 70.139709],
            [-69.219452, 70.188309],
            [-69.039436, 70.16748],
            [-69.014717, 70.183037],
            [-69.12944, 70.214706],
            [-68.68277, 70.203598],
            [-68.642502, 70.149429],
            [-68.753067, 70.105263],
            [-68.734161, 70.073036],
            [-68.783073, 70.044434],
            [-69.106377, 69.974426],
            [-69.426941, 69.819717],
            [-69.683884, 69.839981],
            [-69.805832, 69.819992],
            [-69.998047, 69.669983],
            [-69.982773, 69.61554],
            [-69.741943, 69.803589],
            [-69.395844, 69.777771],
            [-69.076683, 69.923866],
            [-68.933884, 69.951103],
            [-68.639717, 69.939972],
            [-68.669449, 69.981659],
            [-68.541672, 70.021378],
            [-68.565277, 70.049423],
            [-68.338333, 70.064148],
            [-68.20723, 70.109421],
            [-68.229446, 70.135269],
            [-68.333618, 70.13916],
            [-68.291107, 70.163307],
            [-68.345551, 70.163307],
            [-68.345284, 70.188026],
            [-68.298889, 70.182213],
            [-68.318619, 70.218597],
            [-68.160004, 70.282761],
            [-68.151108, 70.313873],
            [-68.055832, 70.322769],
            [-67.909439, 70.275543],
            [-67.794724, 70.258881],
            [-67.575562, 70.158333],
            [-67.242493, 69.958328],
            [-67.117767, 69.726379],
            [-67.406952, 69.714706],
            [-67.761124, 69.778587],
            [-67.996109, 69.774429],
            [-68.208054, 69.715553],
            [-68.329453, 69.627762],
            [-68.699997, 69.645828],
            [-69.220001, 69.535812],
            [-69.688889, 69.569443],
            [-70.035278, 69.537201],
            [-69.885834, 69.507217],
            [-69.66333, 69.548317],
            [-69.299988, 69.506104],
            [-68.668327, 69.59082],
            [-67.982224, 69.457489],
            [-67.792221, 69.484154],
            [-67.258347, 69.467484],
            [-66.795547, 69.341087],
            [-66.694992, 69.281662],
            [-66.646667, 69.203598],
            [-66.758621, 69.128036],
            [-66.996948, 69.177757],
            [-67.164719, 69.179428],
            [-67.207779, 69.143051],
            [-67.343063, 69.183319],
            [-67.676392, 69.169434],
            [-68.184479, 69.308006],
            [-68.375549, 69.294708],
            [-68.984444, 69.356934],
            [-69.257782, 69.270264],
            [-68.941101, 69.332489],
            [-68.445267, 69.270264],
            [-68.159439, 69.266098],
            [-68.077499, 69.217484],
            [-68.129707, 69.197746],
            [-68.967773, 69.2211],
            [-68.502502, 69.198593],
            [-68.70723, 69.138321],
            [-69.075562, 69.10582],
            [-68.926941, 69.098328],
            [-68.958893, 69.003052],
            [-69.028877, 68.971367],
            [-68.930557, 68.992752],
            [-68.872498, 69.084991],
            [-68.506393, 69.161652],
            [-68.178329, 69.146652],
            [-67.705841, 69.016388],
            [-68.029449, 68.971367],
            [-68.314163, 69.010536],
            [-68.556107, 68.964157],
            [-68.303047, 68.985809],
            [-68.04834, 68.943863],
            [-68.051941, 68.910263],
            [-68.089722, 68.901382],
            [-67.977493, 68.855553],
            [-68.485817, 68.890266],
            [-67.861389, 68.805542],
            [-67.771393, 68.781372],
            [-67.872772, 68.771652],
            [-68.565552, 68.847214],
            [-68.611656, 68.835823],
            [-68.48999, 68.791931],
            [-68.605827, 68.781097],
            [-69.280838, 68.875809],
            [-69.394447, 68.857758],
            [-69.152222, 68.828049],
            [-69.383904, 68.816383],
            [-68.957497, 68.790543],
            [-68.959732, 68.756653],
            [-68.997772, 68.751663],
            [-68.97084, 68.737762],
            [-68.868057, 68.766098],
            [-68.529167, 68.746643],
            [-68.049438, 68.681664],
            [-68.168327, 68.668869],
            [-68.087219, 68.654984],
            [-68.094452, 68.627762],
            [-68.209732, 68.655823],
            [-68.359444, 68.627197],
            [-68.657784, 68.656372],
            [-68.902496, 68.603592],
            [-68.768066, 68.594147],
            [-68.628601, 68.635536],
            [-68.483322, 68.596939],
            [-68.739723, 68.563873],
            [-68.460007, 68.562187],
            [-68.337784, 68.593323],
            [-68.136673, 68.57222],
            [-68.126938, 68.530823],
            [-67.922783, 68.550812],
            [-67.939438, 68.522774],
            [-67.904167, 68.455833],
            [-67.958618, 68.396103],
            [-67.898621, 68.421654],
            [-67.864441, 68.549423],
            [-67.780838, 68.529984],
            [-67.669861, 68.561256],
            [-67.492218, 68.527206],
            [-67.628052, 68.496368],
            [-67.612503, 68.379433],
            [-67.551102, 68.414429],
            [-67.553329, 68.488876],
            [-67.378883, 68.469994],
            [-67.316391, 68.496094],
            [-67.272232, 68.45166],
            [-67.232498, 68.480553],
            [-67.191673, 68.426376],
            [-67.324448, 68.407761],
            [-67.105003, 68.414146],
            [-67.112778, 68.478867],
            [-66.974716, 68.436096],
            [-66.803047, 68.467209],
            [-66.66362, 68.43248],
            [-66.801102, 68.424149],
            [-66.808609, 68.399986],
            [-66.904999, 68.416382],
            [-66.936394, 68.37442],
            [-67.011124, 68.353592],
            [-67.40834, 68.393333],
            [-67.781387, 68.337196],
            [-67.865829, 68.299713],
            [-67.86972, 68.259987],
            [-67.752502, 68.318878],
            [-67.536118, 68.34166],
            [-67.586937, 68.301651],
            [-67.576683, 68.2686],
            [-67.695267, 68.242477],
            [-67.570847, 68.253052],
            [-67.533073, 68.271378],
            [-67.540283, 68.299713],
            [-67.39473, 68.354156],
            [-67.325562, 68.344711],
            [-67.323334, 68.319443],
            [-67.243881, 68.358322],
            [-67.016113, 68.318604],
            [-67.235817, 68.294434],
            [-67.327789, 68.243591],
            [-67.327789, 68.181091],
            [-67.387787, 68.146378],
            [-67.598892, 68.164993],
            [-67.396957, 68.124687],
            [-67.304169, 68.1586],
            [-67.274719, 68.244141],
            [-67.011398, 68.294983],
            [-66.930573, 68.262482],
            [-66.765839, 68.238586],
            [-66.842216, 68.163307],
            [-66.823624, 68.126373],
            [-66.965561, 68.063873],
            [-66.946663, 68.013611],
            [-66.836121, 68.095543],
            [-66.694443, 68.143051],
            [-66.670837, 68.12886],
            [-66.678047, 68.04332],
            [-66.735001, 67.982208],
            [-66.647232, 68.015549],
            [-66.594727, 68.143051],
            [-66.309433, 68.127472],
            [-66.366386, 68.083328],
            [-66.460564, 68.070541],
            [-66.24028, 68.073318],
            [-66.184433, 68.013321],
            [-66.320847, 67.978867],
            [-66.405838, 67.898041],
            [-66.398903, 67.868591],
            [-66.746948, 67.875259],
            [-66.406952, 67.829163],
            [-66.386398, 67.772217],
            [-66.338608, 67.900269],
            [-66.276672, 67.954437],
            [-65.947769, 68.040817],
            [-65.960564, 68.14444],
            [-65.911942, 68.158867],
            [-65.857224, 68.106087],
            [-65.902786, 68.080551],
            [-65.853607, 68.073318],
            [-66.032227, 67.952477],
            [-65.941673, 67.880257],
            [-66.009743, 67.803307],
            [-66.008621, 67.625526],
            [-65.936943, 67.765549],
            [-65.953339, 67.821381],
            [-65.804169, 67.863602],
            [-65.763634, 67.909988],
            [-65.81778, 67.968048],
            [-65.514267, 67.992371],
            [-65.443604, 67.992203],
            [-65.464447, 67.920822],
            [-65.613892, 67.780823],
            [-65.426392, 67.696373],
            [-65.356659, 67.594711],
            [-65.318336, 67.601089],
            [-65.369164, 67.702766],
            [-65.554443, 67.78804],
            [-65.486389, 67.820831],
            [-65.406952, 67.922493],
            [-65.201401, 67.954437],
            [-65.141388, 67.990257],
            [-65.169159, 68.027206],
            [-65.110001, 68.015823],
            [-65.001106, 68.055542],
            [-64.713623, 67.981659],
            [-64.847229, 67.934982],
            [-65.066963, 67.931091],
            [-64.94249, 67.912491],
            [-65.048607, 67.844147],
            [-65.113052, 67.857758],
            [-65.139183, 67.790543],
            [-65.04805, 67.825821],
            [-65.008347, 67.785263],
            [-65.15834, 67.729713],
            [-65.200287, 67.643883],
            [-65.162781, 67.638893],
            [-65.129707, 67.71582],
            [-64.919724, 67.790817],
            [-64.810547, 67.777206],
            [-64.816101, 67.710541],
            [-64.862778, 67.69165],
            [-64.820557, 67.688309],
            [-64.768623, 67.709427],
            [-64.75, 67.822769],
            [-64.568069, 67.819992],
            [-64.459167, 67.789703],
            [-64.458618, 67.75972],
            [-64.363892, 67.75444],
            [-64.401398, 67.707764],
            [-64.578339, 67.69693],
            [-64.639183, 67.660538],
            [-64.379913, 67.698097],
            [-64.305557, 67.733597],
            [-64.204178, 67.664703],
            [-64.219452, 67.631653],
            [-64.285828, 67.618874],
            [-64.089996, 67.620819],
            [-64.068069, 67.599716],
            [-64.10556, 67.549423],
            [-64.039719, 67.5336],
            [-64.238602, 67.467484],
            [-64.440552, 67.474991],
            [-63.993328, 67.449142],
            [-63.939991, 67.364433],
            [-64.00029, 67.32666],
            [-63.908051, 67.310532],
            [-63.923889, 67.29332],
            [-64.797501, 67.356644],
            [-64.231949, 67.29332],
            [-64.35611, 67.250267],
            [-64.550278, 67.256104],
            [-64.779167, 67.218872],
            [-64.816101, 67.20694],
            [-64.783333, 67.190262],
            [-64.581947, 67.234154],
            [-63.96278, 67.272491],
            [-64.021393, 67.208878],
            [-64.162216, 67.214432],
            [-64.611938, 67.132477],
            [-64.689987, 67.083603],
            [-64.696953, 67.003326],
            [-64.628326, 67.012207],
            [-64.604172, 67.088318],
            [-64.506393, 67.129433],
            [-63.971378, 67.182213],
            [-63.889999, 67.234993],
            [-63.788342, 67.2397],
            [-63.751671, 67.212486],
            [-63.561939, 67.236923],
            [-63.45055, 67.222488],
            [-63.453892, 67.169434],
            [-63.529442, 67.104431],
            [-63.74445, 67.041092],
            [-63.806389, 66.995819],
            [-63.772499, 66.958878],
            [-63.750561, 67.017487],
            [-63.535278, 67.070831],
            [-63.402779, 67.14444],
            [-63.420841, 67.200272],
            [-63.3325, 67.281937],
            [-63.215279, 67.300262],
            [-63.293621, 67.246368],
            [-63.255001, 67.246643],
            [-63.172501, 67.27832],
            [-63.205002, 67.319153],
            [-63.110279, 67.329987],
            [-62.99778, 67.281372],
            [-62.970829, 67.221367],
            [-63.04472, 67.167213],
            [-63.284451, 67.105263],
            [-63.22028, 67.016937],
            [-63.240841, 66.961647],
            [-63.526138, 66.909271],
            [-63.565552, 66.892487],
            [-63.571949, 66.837486],
            [-63.821671, 66.815811],
            [-63.53944, 66.814697],
            [-63.478882, 66.835823],
            [-63.496948, 66.880814],
            [-63.441669, 66.908333],
            [-63.407219, 66.814423],
            [-63.45306, 66.711647],
            [-63.42028, 66.698868],
            [-63.320278, 66.814148],
            [-63.348339, 66.890823],
            [-62.846951, 66.961929],
            [-62.82056, 66.813599],
            [-62.899731, 66.636658],
            [-62.83527, 66.666382],
            [-62.831669, 66.713882],
            [-62.734169, 66.790817],
            [-62.77417, 66.840553],
            [-62.743061, 66.941933],
            [-62.56889, 66.944138],
            [-62.509731, 66.901932],
            [-62.500561, 66.842484],
            [-62.413059, 66.818604],
            [-62.313332, 66.726929],
            [-62.292782, 66.761658],
            [-62.424721, 66.847488],
            [-62.427219, 66.921097],
            [-62.26112, 66.948029],
            [-62.294449, 67.021378],
            [-62.262501, 67.045258],
            [-62.05444, 67.049149],
            [-62.005562, 67.035263],
            [-62.10223, 66.91304],
            [-62.01889, 66.901657],
            [-61.93861, 66.969437],
            [-61.838051, 66.968597],
            [-61.851391, 66.933037],
            [-61.749729, 66.948029],
            [-61.730831, 66.923866],
            [-61.77084, 66.897491],
            [-61.965549, 66.897491],
            [-62.00806, 66.867752],
            [-61.612782, 66.870819],
            [-61.652779, 66.829987],
            [-61.726662, 66.823608],
            [-61.4575, 66.776093],
            [-61.46278, 66.751663],
            [-61.28944, 66.669983],
            [-61.262501, 66.629433],
            [-61.28278, 66.616928],
          ],
        ],
        [
          [
            [-67.544159, 82.962196],
            [-67.196663, 82.936096],
            [-67.041107, 82.959717],
            [-66.939987, 82.950546],
            [-66.978058, 82.933037],
            [-66.95723, 82.926933],
            [-66.779449, 82.951393],
            [-66.330002, 82.933868],
            [-66.391388, 82.916092],
            [-66.34111, 82.905823],
            [-66.369164, 82.888321],
            [-66.876663, 82.807213],
            [-68.356659, 82.688026],
            [-68.642502, 82.628593],
            [-67.47084, 82.652206],
            [-66.900284, 82.719437],
            [-66.662514, 82.709717],
            [-66.636673, 82.720261],
            [-66.67749, 82.733322],
            [-66.638344, 82.748871],
            [-65.767776, 82.843048],
            [-65.467773, 82.833328],
            [-65.511124, 82.786652],
            [-65.164436, 82.763046],
            [-65.353058, 82.797211],
            [-65.102783, 82.848038],
            [-65.307503, 82.86554],
            [-64.835564, 82.906937],
            [-64.664169, 82.890266],
            [-64.923607, 82.8647],
            [-64.723053, 82.856369],
            [-64.737778, 82.82222],
            [-64.445267, 82.761932],
            [-64.103058, 82.831673],
            [-63.389172, 82.804977],
            [-63.382221, 82.767761],
            [-63.510281, 82.732483],
            [-63.850281, 82.71582],
            [-63.651661, 82.714996],
            [-63.540001, 82.694427],
            [-63.55722, 82.673866],
            [-63.254452, 82.650269],
            [-63.226101, 82.640266],
            [-63.25584, 82.627197],
            [-63.376389, 82.61998],
            [-63.3475, 82.60498],
            [-62.926109, 82.576103],
            [-63.053329, 82.54332],
            [-63.076672, 82.486099],
            [-63.116661, 82.474991],
            [-63.089439, 82.466377],
            [-63.369999, 82.438873],
            [-63.015839, 82.459717],
            [-62.902222, 82.510269],
            [-62.774441, 82.483871],
            [-62.727779, 82.488037],
            [-62.756672, 82.513893],
            [-62.506672, 82.526657],
            [-62.171669, 82.525543],
            [-62.322781, 82.511108],
            [-62.352779, 82.481087],
            [-62.228611, 82.506943],
            [-61.608101, 82.480377],
            [-61.13139, 82.377472],
            [-61.078609, 82.301086],
            [-61.193329, 82.223602],
            [-61.463619, 82.172493],
            [-62.023891, 82.135818],
            [-61.869999, 82.106644],
            [-62.254169, 82.019989],
            [-62.499439, 82.016937],
            [-62.507229, 81.983597],
            [-63.611671, 81.855263],
            [-63.76112, 81.811653],
            [-64.053047, 81.789978],
            [-64.325287, 81.824707],
            [-64.118057, 81.764427],
            [-64.372772, 81.720833],
            [-65.355827, 81.761108],
            [-65.349991, 81.733597],
            [-65.631378, 81.702477],
            [-66.038597, 81.692467],
            [-65.352493, 81.691933],
            [-65.789436, 81.632202],
            [-66.024719, 81.653053],
            [-66.172501, 81.618042],
            [-67.559723, 81.599152],
            [-68.156662, 81.561096],
            [-69.291382, 81.718872],
            [-68.370827, 81.553589],
            [-68.856949, 81.54776],
            [-68.579453, 81.514427],
            [-67.107773, 81.564987],
            [-66.608612, 81.512772],
            [-68.618057, 81.290543],
            [-69.02861, 81.258614],
            [-69.426941, 81.269989],
            [-69.463058, 81.253326],
            [-69.31221, 81.24054],
            [-69.541672, 81.212486],
            [-70.20639, 81.179703],
            [-69.638062, 81.177467],
            [-69.99527, 81.099426],
            [-69.430557, 81.187187],
            [-65.724716, 81.493874],
            [-64.566391, 81.545532],
            [-64.450562, 81.496933],
            [-64.436394, 81.479431],
            [-64.508621, 81.441933],
            [-64.735283, 81.374153],
            [-65.441383, 81.256378],
            [-66.010277, 81.220261],
            [-66.685822, 81.035812],
            [-67.208618, 80.941933],
            [-67.591377, 80.933037],
            [-67.530838, 80.897491],
            [-67.634453, 80.860809],
            [-68.247223, 80.755547],
            [-68.958618, 80.617477],
            [-68.893066, 80.590553],
            [-69.146667, 80.529709],
            [-69.317497, 80.412491],
            [-69.59639, 80.361099],
            [-70.284439, 80.351089],
            [-70.310822, 80.363037],
            [-70.220001, 80.416931],
            [-70.314163, 80.464432],
            [-70.8125, 80.562759],
            [-70.371109, 80.409149],
            [-70.458893, 80.381363],
            [-70.448334, 80.340271],
            [-69.960564, 80.256378],
            [-70.178596, 80.189148],
            [-70.74472, 80.205833],
            [-71.694443, 80.110809],
            [-71.953339, 80.180542],
            [-72.400284, 80.218597],
            [-71.895554, 80.114151],
            [-72.391678, 80.085541],
            [-72.32251, 80.077209],
            [-72.340561, 80.059143],
            [-71.489166, 80.068878],
            [-70.762512, 80.133331],
            [-70.507782, 80.099716],
            [-70.495003, 80.050812],
            [-70.646667, 80.031937],
            [-70.719452, 79.986366],
            [-71.416397, 79.930267],
            [-71.460564, 79.901382],
            [-71.415833, 79.886658],
            [-71.109734, 79.915268],
            [-70.910278, 79.885818],
            [-71.005836, 79.819717],
            [-71.136124, 79.784988],
            [-72.267227, 79.659149],
            [-72.93277, 79.706383],
            [-72.948608, 79.743591],
            [-73.062767, 79.799423],
            [-72.902496, 79.815262],
            [-72.991814, 79.819847],
            [-73.853333, 79.829163],
            [-73.746948, 79.854156],
            [-74.117493, 79.888893],
            [-74.84639, 79.847214],
            [-74.683884, 79.789978],
            [-74.309433, 79.803307],
            [-73.348892, 79.766098],
            [-73.384743, 79.748871],
            [-73.366653, 79.718048],
            [-73.174438, 79.651657],
            [-73.159729, 79.612198],
            [-73.184433, 79.591927],
            [-73.125816, 79.558319],
            [-73.353882, 79.505829],
            [-73.758057, 79.500267],
            [-73.965012, 79.554703],
            [-74.001106, 79.545822],
            [-73.949997, 79.493874],
            [-73.983322, 79.455551],
            [-74.198334, 79.436096],
            [-74.673607, 79.444138],
            [-74.964447, 79.513046],
            [-75.059433, 79.483871],
            [-74.883057, 79.408333],
            [-75.058327, 79.373871],
            [-75.531387, 79.392487],
            [-75.658623, 79.429153],
            [-75.70639, 79.406647],
            [-75.803329, 79.419434],
            [-75.817497, 79.448318],
            [-75.957497, 79.426086],
            [-76.261124, 79.497757],
            [-76.905838, 79.50972],
            [-77.142502, 79.547493],
            [-77.191383, 79.511108],
            [-77.134453, 79.490257],
            [-76.179169, 79.459717],
            [-76.138901, 79.441093],
            [-76.208054, 79.429703],
            [-76.157501, 79.391663],
            [-75.889999, 79.348038],
            [-76.86972, 79.349426],
            [-77.076401, 79.398331],
            [-77.139999, 79.464706],
            [-77.397507, 79.447197],
            [-77.163887, 79.324707],
            [-77.922501, 79.366379],
            [-78.051392, 79.354713],
            [-78.021393, 79.339981],
            [-77.322243, 79.267487],
            [-77.488052, 79.24498],
            [-76.754463, 79.279984],
            [-76.136124, 79.2686],
            [-75.938889, 79.23027],
            [-74.496948, 79.224991],
            [-74.82695, 79.174149],
            [-74.617493, 79.151382],
            [-74.664436, 79.134163],
            [-74.436661, 79.057747],
            [-74.725014, 79.022774],
            [-75.655563, 79.068329],
            [-75.885277, 79.097488],
            [-75.857773, 79.152206],
            [-76.098053, 79.199142],
            [-78.15889, 79.189972],
            [-78.228607, 79.160538],
            [-77.912216, 79.179428],
            [-76.706123, 79.153053],
            [-76.081947, 79.099716],
            [-76.170547, 79.075821],
            [-77.073334, 79.070831],
            [-77.495003, 79.017761],
            [-77.799988, 79.066673],
            [-78.351097, 79.08638],
            [-78.891388, 79.063309],
            [-77.829453, 79.048027],
            [-77.708344, 79.013046],
            [-77.954178, 78.958878],
            [-78.289993, 78.799149],
            [-78.248047, 78.770264],
            [-77.711937, 78.966087],
            [-76.754181, 79.027771],
            [-75.720284, 78.965553],
            [-75.825562, 78.926086],
            [-76.315552, 78.896103],
            [-76.446114, 78.863876],
            [-76.440277, 78.839432],
            [-76.204727, 78.881088],
            [-75.316101, 78.892212],
            [-74.911118, 78.850807],
            [-74.876938, 78.817757],
            [-74.760559, 78.823608],
            [-74.907227, 78.784149],
            [-74.761673, 78.773613],
            [-74.83168, 78.743317],
            [-74.719727, 78.707489],
            [-74.843338, 78.693039],
            [-74.869453, 78.668587],
            [-74.832779, 78.651932],
            [-74.871109, 78.638321],
            [-74.633331, 78.587769],
            [-74.789993, 78.59137],
            [-75.04805, 78.528053],
            [-75.479446, 78.509987],
            [-75.888344, 78.506104],
            [-76.43721, 78.548599],
            [-76.684433, 78.522217],
            [-76.261948, 78.513611],
            [-76.057503, 78.461929],
            [-75.443604, 78.430542],
            [-75.089996, 78.368874],
            [-75.031387, 78.331383],
            [-75.358612, 78.301651],
            [-75.479172, 78.222214],
            [-75.613617, 78.198029],
            [-76.157227, 78.24054],
            [-76.85527, 78.227478],
            [-76.90834, 78.195534],
            [-76.664436, 78.166092],
            [-75.58139, 78.115807],
            [-75.599167, 78.083328],
            [-75.922783, 77.95665],
            [-76.214722, 78.015266],
            [-76.49028, 77.967209],
            [-76.931107, 77.901382],
            [-77.210831, 77.949142],
            [-77.900558, 77.940811],
            [-78.260834, 77.995247],
            [-78.271118, 77.97554],
            [-78.183319, 77.963318],
            [-78.419724, 77.89888],
            [-77.958618, 77.8022],
            [-77.940552, 77.759987],
            [-78.051666, 77.729713],
            [-77.98111, 77.701393],
            [-78.08139, 77.673309],
            [-77.920273, 77.669983],
            [-77.723053, 77.599152],
            [-77.98056, 77.554703],
            [-77.948608, 77.501938],
            [-78.278343, 77.429153],
            [-78.23111, 77.397774],
            [-78.256668, 77.381927],
            [-78.777222, 77.307213],
            [-78.839722, 77.310257],
            [-78.726936, 77.367477],
            [-78.76445, 77.380814],
            [-79.20723, 77.288307],
            [-79.712219, 77.318329],
            [-80.042503, 77.272774],
            [-80.753342, 77.330551],
            [-81.277496, 77.424423],
            [-81.370537, 77.47554],
            [-81.573624, 77.512772],
            [-81.575012, 77.545258],
            [-81.672783, 77.570541],
            [-81.620003, 77.586113],
            [-81.839722, 77.625809],
            [-81.847504, 77.665817],
            [-81.928329, 77.685806],
            [-81.949432, 77.644989],
            [-81.911392, 77.609421],
            [-81.670547, 77.531662],
            [-81.670837, 77.496094],
            [-81.748047, 77.448029],
            [-81.728882, 77.429977],
            [-81.484734, 77.372208],
            [-81.203888, 77.370529],
            [-81.165833, 77.337196],
            [-81.828339, 77.285812],
            [-82.091949, 77.316383],
            [-82.194443, 77.299713],
            [-81.904449, 77.248596],
            [-81.882492, 77.214157],
            [-81.902222, 77.187187],
            [-81.786942, 77.157494],
            [-81.076401, 77.283051],
            [-80.116096, 77.201393],
            [-80.401672, 77.086647],
            [-80.373047, 77.071381],
            [-80.013344, 77.190536],
            [-79.72583, 77.239967],
            [-79.254997, 77.218597],
            [-79.041946, 77.161102],
            [-79.063049, 77.131088],
            [-79.004997, 77.096939],
            [-79.365547, 76.963318],
            [-79.386948, 76.927467],
            [-78.886124, 76.926933],
            [-78.964722, 76.84166],
            [-78.721939, 76.821381],
            [-78.698036, 76.839432],
            [-78.720001, 76.878593],
            [-78.56723, 76.887207],
            [-78.553879, 76.938583],
            [-78.398354, 76.963043],
            [-78.41362, 76.984993],
            [-78.384453, 76.99971],
            [-78.070282, 77.014427],
            [-77.886948, 76.947746],
            [-77.90834, 76.913597],
            [-77.835007, 76.886932],
            [-77.881104, 76.857758],
            [-77.715012, 76.821114],
            [-77.789436, 76.781662],
            [-77.763901, 76.729156],
            [-77.813049, 76.681931],
            [-77.769447, 76.658333],
            [-78.089722, 76.609421],
            [-78.186111, 76.559708],
            [-78.138901, 76.525818],
            [-78.346947, 76.500816],
            [-78.377487, 76.458038],
            [-78.614723, 76.489967],
            [-78.588333, 76.513046],
            [-78.613327, 76.54776],
            [-78.565552, 76.563873],
            [-78.790558, 76.571663],
            [-78.835251, 76.542572],
            [-78.994164, 76.424423],
            [-79.189713, 76.405823],
            [-79.178329, 76.380257],
            [-79.261124, 76.352203],
            [-79.272781, 76.304153],
            [-79.573624, 76.311653],
            [-80.087219, 76.223602],
            [-80.293327, 76.23526],
            [-80.672501, 76.158333],
            [-80.865829, 76.169144],
            [-81.075844, 76.12915],
            [-81.07695, 76.158043],
            [-80.953613, 76.191093],
            [-81.095551, 76.212196],
            [-80.783623, 76.374977],
            [-80.771667, 76.419144],
            [-80.991669, 76.483047],
            [-81.218613, 76.498596],
            [-81.178047, 76.530823],
            [-81.208054, 76.553589],
            [-81.492493, 76.469437],
            [-82.039719, 76.50943],
            [-82.083618, 76.52388],
            [-81.981949, 76.584717],
            [-82.056664, 76.609154],
            [-81.785828, 76.685532],
            [-82.081123, 76.631088],
            [-82.292503, 76.635536],
            [-82.44249, 76.684982],
            [-82.580292, 76.776382],
            [-82.749733, 76.818604],
            [-82.556107, 76.723038],
            [-82.5625, 76.688873],
            [-82.460564, 76.636108],
            [-82.081123, 76.561096],
            [-82.222229, 76.532761],
            [-82.127213, 76.44165],
            [-82.179718, 76.416931],
            [-82.799553, 76.387192],
            [-83.099731, 76.463882],
            [-83.061943, 76.497208],
            [-83.110001, 76.579987],
            [-83.388062, 76.671097],
            [-83.404999, 76.719994],
            [-83.355827, 76.752213],
            [-83.37999, 76.758881],
            [-83.523331, 76.700821],
            [-83.246384, 76.572769],
            [-83.239166, 76.517487],
            [-83.183884, 76.496643],
            [-83.239723, 76.463318],
            [-83.184433, 76.424988],
            [-83.223618, 76.410538],
            [-83.710564, 76.433037],
            [-83.756668, 76.468597],
            [-84.033623, 76.534714],
            [-84.08667, 76.624153],
            [-84.284439, 76.657761],
            [-84.330841, 76.647491],
            [-84.193047, 76.609993],
            [-84.249733, 76.536926],
            [-84.180832, 76.484993],
            [-84.236938, 76.443588],
            [-84.636948, 76.434418],
            [-84.78389, 76.469994],
            [-84.795273, 76.503883],
            [-84.950562, 76.577766],
            [-85.028343, 76.574997],
            [-85.051392, 76.51416],
            [-84.960281, 76.420532],
            [-84.397507, 76.330551],
            [-84.381943, 76.31192],
            [-84.429169, 76.30304],
            [-85.194717, 76.281097],
            [-85.262512, 76.310806],
            [-85.434433, 76.304977],
            [-85.73555, 76.355553],
            [-86.372223, 76.386383],
            [-86.412216, 76.407761],
            [-86.41861, 76.469147],
            [-86.211937, 76.535263],
            [-86.630341, 76.635132],
            [-86.301392, 76.524696],
            [-86.655563, 76.467209],
            [-86.632767, 76.441933],
            [-86.71666, 76.3461],
            [-87.130829, 76.384163],
            [-87.154999, 76.394989],
            [-87.118332, 76.420822],
            [-87.140556, 76.437187],
            [-87.42305, 76.45694],
            [-87.394173, 76.503052],
            [-87.50029, 76.535263],
            [-87.462784, 76.586929],
            [-87.536942, 76.617477],
            [-87.580002, 76.611366],
            [-87.566391, 76.575272],
            [-87.598618, 76.540817],
            [-87.493057, 76.503883],
            [-87.553596, 76.451103],
            [-87.429993, 76.417763],
            [-87.498894, 76.382202],
            [-87.416397, 76.348038],
            [-87.547234, 76.370819],
            [-87.591377, 76.341087],
            [-87.742767, 76.346367],
            [-87.816101, 76.411102],
            [-87.948334, 76.357758],
            [-88.351097, 76.384987],
            [-88.434433, 76.402206],
            [-88.36055, 76.431091],
            [-88.438316, 76.464157],
            [-88.355827, 76.481087],
            [-88.349442, 76.514427],
            [-88.52417, 76.62442],
            [-88.478333, 76.656097],
            [-88.559158, 76.727768],
            [-88.476669, 76.783333],
            [-88.51889, 76.816093],
            [-88.704453, 76.710274],
            [-88.584442, 76.635818],
            [-88.660553, 76.599991],
            [-88.556664, 76.589432],
            [-88.495537, 76.5522],
            [-88.515839, 76.52388],
            [-88.489441, 76.503326],
            [-88.611938, 76.452209],
            [-88.608047, 76.399986],
            [-88.677223, 76.401932],
            [-88.693329, 76.420822],
            [-88.658051, 76.475807],
            [-88.610283, 76.482758],
            [-88.68277, 76.525818],
            [-88.644173, 76.565262],
            [-88.733612, 76.593872],
            [-88.796387, 76.550262],
            [-88.775284, 76.524429],
            [-88.832779, 76.498871],
            [-88.781387, 76.466377],
            [-88.827133, 76.440308],
            [-88.921112, 76.405258],
            [-89.033333, 76.41832],
            [-89.045547, 76.447479],
            [-89.213333, 76.429703],
            [-89.430557, 76.497757],
            [-89.403061, 76.524696],
            [-89.441673, 76.565536],
            [-89.541382, 76.541656],
            [-89.676666, 76.567207],
            [-89.603607, 76.621918],
            [-89.411667, 76.680267],
            [-89.456947, 76.713318],
            [-89.388901, 76.729713],
            [-89.468063, 76.753601],
            [-89.533333, 76.853592],
            [-88.986938, 76.954437],
            [-88.995537, 76.984711],
            [-88.74028, 77.002777],
            [-88.464447, 77.065536],
            [-88.393623, 77.0961],
            [-88.545837, 77.100273],
            [-88.426392, 77.120819],
            [-87.690552, 77.135269],
            [-87.617493, 77.094994],
            [-87.349167, 77.106087],
            [-87.460564, 77.125526],
            [-87.356377, 77.175537],
            [-87.069168, 77.182747],
            [-86.804443, 77.127197],
            [-86.889999, 77.164429],
            [-86.614166, 77.180542],
            [-87.210564, 77.205261],
            [-87.166946, 77.233871],
            [-86.910553, 77.260269],
            [-87.012222, 77.274986],
            [-87.183884, 77.273613],
            [-87.248894, 77.303307],
            [-86.851936, 77.360527],
            [-87.391113, 77.330551],
            [-87.711937, 77.359993],
            [-87.698334, 77.390823],
            [-87.784729, 77.429703],
            [-87.642502, 77.48027],
            [-87.694443, 77.537201],
            [-88.214722, 77.650543],
            [-88.150284, 77.72554],
            [-88.162781, 77.758331],
            [-88.232773, 77.768883],
            [-88.103882, 77.813599],
            [-88.223053, 77.855553],
            [-88.01001, 77.819992],
            [-87.231377, 77.89888],
            [-86.422234, 77.830833],
            [-85.975281, 77.705833],
            [-85.833618, 77.579437],
            [-85.808037, 77.506943],
            [-85.718887, 77.472214],
            [-85.794449, 77.419708],
            [-85.503891, 77.474701],
            [-85.481667, 77.459717],
            [-85.535278, 77.44136],
            [-85.399727, 77.395828],
            [-84.973328, 77.377197],
            [-84.529167, 77.295822],
            [-84.463333, 77.300262],
            [-84.615013, 77.383041],
            [-84.550278, 77.401382],
            [-84.005836, 77.397491],
            [-83.464722, 77.348328],
            [-83.494164, 77.376648],
            [-83.448883, 77.38916],
            [-83.71167, 77.404709],
            [-83.835564, 77.455261],
            [-83.426102, 77.49971],
            [-83.180283, 77.592209],
            [-82.817497, 77.759987],
            [-82.78389, 77.782494],
            [-82.796661, 77.805542],
            [-82.674438, 77.836929],
            [-82.637787, 77.8936],
            [-82.547501, 77.915817],
            [-82.577789, 77.943314],
            [-82.513062, 77.956383],
            [-82.591667, 78.017487],
            [-82.377487, 78.035812],
            [-82.318619, 78.070831],
            [-82.549438, 78.07193],
            [-82.780289, 78.014999],
            [-82.785278, 77.969994],
            [-82.736107, 77.924149],
            [-82.949158, 77.874687],
            [-82.989723, 77.824707],
            [-83.111389, 77.789703],
            [-83.19249, 77.710541],
            [-83.427223, 77.600807],
            [-83.873047, 77.493317],
            [-84.858887, 77.542763],
            [-84.866386, 77.574158],
            [-84.44194, 77.7061],
            [-84.431107, 77.726379],
            [-84.489723, 77.746368],
            [-84.486389, 77.71138],
            [-84.53389, 77.684982],
            [-84.952499, 77.601379],
            [-85.298889, 77.660538],
            [-85.270279, 77.708328],
            [-85.348892, 77.728867],
            [-85.205002, 77.757217],
            [-85.18222, 77.795532],
            [-85.029167, 77.799988],
            [-84.955276, 77.835823],
            [-85.297783, 77.797211],
            [-85.400284, 77.813309],
            [-85.389717, 77.841927],
            [-85.207779, 77.883881],
            [-84.848892, 77.891937],
            [-84.89473, 77.833878],
            [-84.664436, 77.902206],
            [-84.313606, 77.891937],
            [-84.634453, 77.926933],
            [-85.474716, 77.868591],
            [-85.515289, 77.883614],
            [-85.486656, 77.909988],
            [-85.678879, 77.929428],
            [-85.065552, 78.056374],
            [-84.761673, 78.023613],
            [-84.547501, 78.071114],
            [-84.431686, 78.070763],
            [-84.288063, 78.075546],
            [-84.511253, 78.0858],
            [-84.587563, 78.077744],
            [-84.881943, 78.059143],
            [-85.094452, 78.097488],
            [-85.008621, 78.137497],
            [-84.995003, 78.16304],
            [-85.033623, 78.171654],
            [-84.631104, 78.199997],
            [-84.201111, 78.156937],
            [-84.026108, 78.176376],
            [-84.506668, 78.217758],
            [-84.951401, 78.195534],
            [-84.973892, 78.208603],
            [-84.956123, 78.232208],
            [-84.834442, 78.314987],
            [-84.572777, 78.35054],
            [-84.772232, 78.342209],
            [-84.866943, 78.369141],
            [-84.845551, 78.382202],
            [-84.765556, 78.477478],
            [-84.782501, 78.505829],
            [-84.619453, 78.588318],
            [-84.675278, 78.588318],
            [-84.978333, 78.414993],
            [-84.952789, 78.396942],
            [-84.961937, 78.343597],
            [-85.045837, 78.309143],
            [-85.024719, 78.284416],
            [-85.508057, 78.099152],
            [-86.148354, 78.054703],
            [-86.28833, 78.076393],
            [-86.230003, 78.136383],
            [-86.251404, 78.156647],
            [-86.113052, 78.170532],
            [-85.931107, 78.236649],
            [-85.878326, 78.288307],
            [-85.899727, 78.303589],
            [-85.835281, 78.332207],
            [-85.833069, 78.379967],
            [-86.05278, 78.297493],
            [-86.073624, 78.248596],
            [-86.285278, 78.193314],
            [-86.497772, 78.215553],
            [-86.736938, 78.118042],
            [-87.109444, 78.103043],
            [-87.529167, 78.132202],
            [-87.407501, 78.183037],
            [-87.087219, 78.2061],
            [-87.513344, 78.224991],
            [-87.491943, 78.285812],
            [-87.517227, 78.426376],
            [-87.140556, 78.550262],
            [-86.858337, 78.54776],
            [-86.877777, 78.573036],
            [-87.122498, 78.587196],
            [-86.976936, 78.648331],
            [-86.934433, 78.709991],
            [-86.638901, 78.799423],
            [-85.646667, 78.848602],
            [-85.064163, 78.919144],
            [-84.712784, 78.867752],
            [-83.746948, 78.836929],
            [-83.165833, 78.729103],
            [-82.82251, 78.695251],
            [-82.61055, 78.611366],
            [-82.308327, 78.568878],
            [-82.23555, 78.595833],
            [-82.594452, 78.703049],
            [-82.231949, 78.736366],
            [-82.781387, 78.731087],
            [-83.210564, 78.798866],
            [-83.25473, 78.834991],
            [-83.056953, 78.856087],
            [-82.429443, 78.833328],
            [-81.947487, 78.865807],
            [-81.712509, 78.839706],
            [-81.656952, 78.888321],
            [-81.755836, 78.918053],
            [-81.698608, 78.973877],
            [-81.486656, 79.052467],
            [-81.910278, 79.00499],
            [-82.110283, 78.913597],
            [-82.503067, 78.882751],
            [-82.924438, 78.934982],
            [-84.259171, 78.959427],
            [-84.766953, 79.037201],
            [-84.78389, 79.074432],
            [-84.52861, 79.143333],
            [-84.135559, 79.121918],
            [-83.896393, 79.03804],
            [-83.474716, 79.024147],
            [-83.358612, 79.050812],
            [-83.546112, 79.04248],
            [-83.995003, 79.133041],
            [-84.029167, 79.156937],
            [-83.939987, 79.185532],
            [-83.956673, 79.221916],
            [-84.158508, 79.183296],
            [-84.32695, 79.188583],
            [-84.335564, 79.252213],
            [-84.428879, 79.290268],
            [-84.484734, 79.406372],
            [-84.882492, 79.486099],
            [-84.969452, 79.537491],
            [-84.969452, 79.566383],
            [-85.047234, 79.587769],
            [-85.022232, 79.611099],
            [-85.050278, 79.621643],
            [-85.493332, 79.700546],
            [-85.900284, 79.700272],
            [-86.486389, 79.763611],
            [-86.48555, 79.816093],
            [-86.37999, 79.821381],
            [-86.454453, 79.847763],
            [-86.471939, 79.890549],
            [-86.436394, 79.942467],
            [-86.367218, 79.962769],
            [-85.585007, 79.938026],
            [-85.365829, 79.896378],
            [-85.254997, 79.920822],
            [-86.482773, 80.008614],
            [-86.579453, 80.043053],
            [-86.659439, 80.128036],
            [-86.548607, 80.205261],
            [-86.514717, 80.299149],
            [-86.468338, 80.308594],
            [-85.897507, 80.333054],
            [-85.290833, 80.2686],
            [-83.781952, 80.245819],
            [-82.153061, 79.858871],
            [-81.969727, 79.752777],
            [-81.978882, 79.718323],
            [-81.671112, 79.687477],
            [-81.687767, 79.645828],
            [-81.618607, 79.618317],
            [-81.785278, 79.61499],
            [-81.706673, 79.586647],
            [-81.424713, 79.636658],
            [-80.598053, 79.566093],
            [-80.641953, 79.588043],
            [-80.598053, 79.601379],
            [-79.904716, 79.646942],
            [-79.751404, 79.701393],
            [-80.43222, 79.677757],
            [-80.827499, 79.648331],
            [-81.227783, 79.709427],
            [-81.525009, 79.714432],
            [-81.509743, 79.750816],
            [-81.62999, 79.764427],
            [-81.56778, 79.814697],
            [-81.663063, 79.903053],
            [-81.400284, 79.937759],
            [-82.168327, 80.013611],
            [-83.203888, 80.318047],
            [-82.031387, 80.398331],
            [-80.367767, 80.462196],
            [-80.294159, 80.486649],
            [-80.357224, 80.501389],
            [-80.153343, 80.526382],
            [-78.063889, 80.564697],
            [-78.030838, 80.5961],
            [-78.416656, 80.608032],
            [-79.960281, 80.6147],
            [-78.585281, 80.772217],
            [-77.639183, 80.830276],
            [-76.511673, 80.854431],
            [-76.490829, 80.874977],
            [-76.621109, 80.900818],
            [-77.168877, 80.886932],
            [-77.548889, 80.913307],
            [-78.869453, 80.852203],
            [-78.934998, 80.875526],
            [-78.858337, 80.924149],
            [-78.928596, 80.99054],
            [-78.501106, 81.09166],
            [-78.393066, 81.142761],
            [-78.463623, 81.160812],
            [-78.159439, 81.193863],
            [-77.612503, 81.318878],
            [-76.955276, 81.393883],
            [-76.777786, 81.444427],
            [-78.228333, 81.291656],
            [-78.675278, 81.191933],
            [-78.754181, 81.135818],
            [-78.690826, 81.119431],
            [-78.817497, 81.106087],
            [-78.941383, 81.101089],
            [-79.501678, 81.193588],
            [-79.088837, 81.094757],
            [-79.25528, 81.058868],
            [-79.343887, 80.998322],
            [-79.165283, 80.96666],
            [-79.265289, 80.924149],
            [-79.41861, 80.916931],
            [-79.435822, 80.881088],
            [-79.618057, 80.819443],
            [-80.919449, 80.655548],
            [-82.353882, 80.556374],
            [-83.096657, 80.541656],
            [-83.165009, 80.551933],
            [-83.16806, 80.57666],
            [-83.078056, 80.644989],
            [-82.2164, 80.719147],
            [-81.758904, 80.813026],
            [-81.996109, 80.830276],
            [-82.56723, 80.740807],
            [-83.356949, 80.685532],
            [-83.541107, 80.704163],
            [-83.530563, 80.724701],
            [-83.569458, 80.739151],
            [-83.133057, 80.818878],
            [-83.256958, 80.838593],
            [-83.66777, 80.75],
            [-83.863892, 80.757492],
            [-83.775284, 80.739433],
            [-83.836403, 80.719711],
            [-83.720001, 80.636658],
            [-83.840561, 80.545532],
            [-85.066963, 80.505257],
            [-85.864166, 80.535538],
            [-85.564713, 80.619431],
            [-86.080841, 80.52832],
            [-86.739166, 80.597488],
            [-86.514717, 80.729431],
            [-85.77417, 80.948868],
            [-84.991379, 81.040268],
            [-84.769447, 81.027771],
            [-82.826401, 81.123032],
            [-82.364441, 81.179428],
            [-85.608887, 81.054153],
            [-86.309723, 80.940811],
            [-87.076401, 80.722763],
            [-87.215012, 80.638321],
            [-87.458054, 80.627762],
            [-88.406662, 80.716927],
            [-89.334732, 80.857483],
            [-89.462219, 80.919144],
            [-88.590286, 80.996368],
            [-87.446381, 80.976929],
            [-86.671944, 81.005257],
            [-86.061111, 81.082764],
            [-85.838898, 81.075272],
            [-85.914169, 81.100807],
            [-85.887512, 81.118874],
            [-84.745827, 81.289429],
            [-85.279167, 81.289978],
            [-86.077499, 81.207489],
            [-86.438049, 81.126083],
            [-87.37944, 81.070267],
            [-88.339996, 81.069717],
            [-89.629173, 81.009163],
            [-90, 81.032143],
            [-90.197769, 81.069717],
            [-90.351936, 81.16748],
            [-90, 81.242157],
            [-89.491096, 81.206383],
            [-88.944153, 81.244141],
            [-89.335281, 81.242752],
            [-89.952499, 81.329437],
            [-89.026108, 81.459427],
            [-88.823059, 81.453598],
            [-88.928329, 81.485527],
            [-88.715843, 81.513046],
            [-87.984161, 81.535812],
            [-87.914169, 81.509163],
            [-87.24472, 81.490257],
            [-88.306381, 81.581383],
            [-88.998047, 81.540543],
            [-90, 81.417747],
            [-90.443047, 81.366653],
            [-90.553329, 81.384987],
            [-90.487778, 81.398613],
            [-90.856659, 81.444138],
            [-89.586166, 81.621147],
            [-90.091667, 81.623306],
            [-90.033073, 81.646942],
            [-90.296951, 81.698593],
            [-90.360817, 81.685257],
            [-90.29277, 81.641373],
            [-90.718887, 81.666656],
            [-90.991096, 81.616089],
            [-91.006958, 81.598877],
            [-90.964722, 81.562477],
            [-91.071671, 81.537201],
            [-91.144173, 81.555542],
            [-91.446381, 81.524429],
            [-91.406387, 81.54776],
            [-91.444153, 81.583603],
            [-91.900284, 81.616928],
            [-91.950287, 81.637207],
            [-91.863892, 81.642487],
            [-91.956673, 81.6586],
            [-91.770844, 81.663307],
            [-91.809723, 81.674988],
            [-91.737213, 81.68692],
            [-91.726936, 81.726929],
            [-91.48555, 81.769989],
            [-91.034439, 81.763893],
            [-91.152496, 81.798027],
            [-91.051666, 81.828873],
            [-90.338058, 81.893051],
            [-90, 81.8992],
            [-89.700844, 81.915543],
            [-89.741096, 81.894989],
            [-89.62999, 81.856369],
            [-89.332962, 81.805687],
            [-89.349991, 81.823036],
            [-89.146667, 81.890549],
            [-89.397507, 81.909416],
            [-89.419159, 81.915543],
            [-89.397781, 81.930817],
            [-89.007233, 81.915543],
            [-88.986389, 81.944977],
            [-89.054443, 81.987488],
            [-89.021118, 81.998032],
            [-88.075562, 82.10498],
            [-87.352783, 82.067207],
            [-87.174438, 82.014709],
            [-87.309433, 81.967484],
            [-86.768341, 81.890266],
            [-86.734161, 81.902771],
            [-86.834732, 81.927757],
            [-87.13028, 81.968323],
            [-86.842773, 81.987198],
            [-87.009743, 82.030548],
            [-86.791946, 82.058029],
            [-86.169159, 82.041656],
            [-85.422501, 81.857483],
            [-85.371658, 81.859711],
            [-85.398064, 81.881088],
            [-85.731949, 81.983322],
            [-85.559433, 82.001663],
            [-85.188316, 81.992752],
            [-85.01889, 81.919434],
            [-84.793877, 81.881363],
            [-85.066101, 81.987488],
            [-84.889183, 81.990257],
            [-84.816673, 81.970833],
            [-84.811943, 81.927467],
            [-84.635277, 81.886108],
            [-84.605003, 81.889977],
            [-84.693604, 81.921097],
            [-84.729721, 81.977203],
            [-84.932503, 82.01944],
            [-86.637512, 82.12442],
            [-86.876099, 82.202209],
            [-85.662216, 82.2397],
            [-85.370827, 82.279984],
            [-85.48999, 82.319717],
            [-85.420273, 82.344994],
            [-85.537216, 82.345261],
            [-85.515289, 82.40332],
            [-85.911942, 82.435806],
            [-85.046951, 82.481934],
            [-84.641678, 82.465553],
            [-84.631378, 82.440262],
            [-84.943878, 82.425812],
            [-84.449997, 82.386108],
            [-84.344452, 82.352768],
            [-83.961403, 82.368591],
            [-83.516403, 82.31694],
            [-83.368607, 82.276382],
            [-83.344452, 82.227203],
            [-82.976669, 82.138321],
            [-82.968338, 82.098038],
            [-83.111938, 82.065262],
            [-82.284157, 82.066383],
            [-81.878052, 82.036377],
            [-82.020844, 82.082207],
            [-82.676941, 82.107758],
            [-82.731377, 82.149986],
            [-83.011398, 82.221649],
            [-83.028877, 82.276657],
            [-82.654449, 82.282211],
            [-81.91806, 82.154984],
            [-80.640289, 82.018333],
            [-80.571953, 81.989151],
            [-80.085007, 81.973602],
            [-79.564163, 81.825272],
            [-79.229172, 81.816093],
            [-79.670837, 81.927467],
            [-79.710281, 81.96138],
            [-79.921387, 81.984993],
            [-79.853333, 82.018883],
            [-80.791107, 82.079437],
            [-80.975563, 82.123596],
            [-80.868057, 82.154984],
            [-81.624443, 82.199997],
            [-82.679993, 82.370819],
            [-82.728882, 82.408333],
            [-82.498337, 82.506378],
            [-81.47583, 82.498596],
            [-81.9664, 82.52887],
            [-82.343887, 82.595261],
            [-82.39473, 82.617477],
            [-82.371933, 82.639709],
            [-81.972229, 82.666382],
            [-81.359734, 82.620819],
            [-80.891953, 82.532761],
            [-80.58168, 82.543053],
            [-81.138344, 82.703049],
            [-81.508621, 82.764709],
            [-81.585007, 82.800812],
            [-81.411392, 82.827766],
            [-80.381104, 82.788879],
            [-80.138901, 82.719994],
            [-80.182503, 82.694702],
            [-80.160278, 82.681374],
            [-79.861656, 82.64415],
            [-79.82251, 82.657761],
            [-79.983322, 82.689148],
            [-79.885834, 82.708603],
            [-79.203888, 82.666931],
            [-78.521118, 82.676933],
            [-79.623047, 82.727768],
            [-79.886948, 82.75943],
            [-79.996948, 82.803307],
            [-79.673889, 82.824707],
            [-80.15834, 82.835541],
            [-80.393066, 82.875526],
            [-80.429718, 82.89415],
            [-80.145279, 82.933868],
            [-79.458344, 82.974152],
            [-79.17749, 82.951927],
            [-79.218887, 82.933594],
            [-79.04277, 82.872482],
            [-78.671112, 82.945534],
            [-78.503342, 82.913307],
            [-78.55777, 82.860527],
            [-78.534439, 82.847763],
            [-78.406113, 82.873596],
            [-78.144173, 82.823318],
            [-78.106659, 82.83194],
            [-78.238892, 82.865257],
            [-78.016953, 82.857483],
            [-78.108337, 82.893333],
            [-77.813049, 82.924423],
            [-77.128326, 82.863312],
            [-76.927673, 82.790741],
            [-76.959167, 82.774147],
            [-76.766663, 82.750816],
            [-76.570557, 82.666656],
            [-75.892227, 82.591927],
            [-76.207497, 82.506378],
            [-76.261002, 82.466553],
            [-76.230827, 82.444702],
            [-75.671387, 82.586929],
            [-75.396118, 82.6147],
            [-76.235817, 82.712196],
            [-76.309158, 82.752777],
            [-75.976097, 82.784714],
            [-76.447487, 82.797493],
            [-76.752792, 82.894989],
            [-77.344727, 82.972488],
            [-77.381378, 82.994431],
            [-77.183884, 83.033867],
            [-77.135559, 83.011383],
            [-76.604172, 83.008614],
            [-76.02861, 83.054428],
            [-75.251404, 83.015823],
            [-74.95639, 83.045532],
            [-74.435822, 83.027206],
            [-74.018066, 82.95694],
            [-73.81778, 82.852768],
            [-72.633904, 82.694427],
            [-72.502502, 82.724426],
            [-73.148621, 82.794434],
            [-73.375549, 82.844437],
            [-73.354721, 82.856087],
            [-73.430557, 82.8936],
            [-73.650558, 82.925812],
            [-73.189713, 83.023323],
            [-72.902786, 83.048027],
            [-73.001106, 83.059418],
            [-72.650558, 83.096367],
            [-71.611656, 83.0961],
            [-71.775009, 83.032211],
            [-71.778343, 83.001663],
            [-71.56723, 82.941093],
            [-70.871384, 82.881088],
            [-71.497498, 83.007217],
            [-71.097229, 83.069992],
            [-71.120003, 83.092484],
            [-70.86055, 83.081383],
            [-70.887222, 83.098038],
            [-70.804443, 83.10498],
            [-69.665009, 83.108322],
            [-69.730827, 83.090271],
            [-69.664169, 83.070831],
            [-69.775284, 83.04776],
            [-69.451111, 83.035812],
            [-69.647507, 82.992203],
            [-69.015556, 83.040817],
            [-68.975281, 83.02832],
            [-68.980827, 82.982483],
            [-68.46666, 83.008041],
            [-68.154167, 82.991089],
            [-68.188599, 82.946091],
            [-68.145554, 82.934982],
            [-67.544159, 82.962196],
          ],
        ],
        [
          [
            [-96.742027, 76.982208],
            [-96.471939, 76.954987],
            [-96.369164, 76.990807],
            [-96.253342, 76.987198],
            [-96.387787, 77.030548],
            [-95.734734, 77.068604],
            [-95.41777, 77.056931],
            [-94.944443, 76.97554],
            [-94.526398, 76.969437],
            [-94.447487, 76.916656],
            [-94.238892, 76.889427],
            [-94.08168, 76.890266],
            [-93.943604, 76.933868],
            [-93.65834, 76.909988],
            [-93.584167, 76.856087],
            [-93.295837, 76.781097],
            [-93.263634, 76.744141],
            [-93.179718, 76.741089],
            [-93.174438, 76.674988],
            [-93.260277, 76.630257],
            [-93.306664, 76.546654],
            [-93.651947, 76.44165],
            [-93.54834, 76.386108],
            [-93.459732, 76.4086],
            [-93.576401, 76.426651],
            [-93.357224, 76.470543],
            [-93.123611, 76.573036],
            [-93.095284, 76.590553],
            [-93.108887, 76.616379],
            [-92.368607, 76.594437],
            [-91.938599, 76.66832],
            [-91.410553, 76.689148],
            [-90.986107, 76.649147],
            [-90.81778, 76.593597],
            [-90.582497, 76.565262],
            [-90.503067, 76.531372],
            [-90.528061, 76.49971],
            [-90.468063, 76.473038],
            [-90.779167, 76.461113],
            [-90.986938, 76.511383],
            [-91.090561, 76.478043],
            [-91.441101, 76.512772],
            [-91.566673, 76.498871],
            [-91.416397, 76.460274],
            [-90, 76.360207],
            [-89.305557, 76.299149],
            [-89.192207, 76.242203],
            [-89.349731, 76.183594],
            [-90, 76.173332],
            [-90.453613, 76.170532],
            [-90.110817, 76.124153],
            [-91.149727, 76.192467],
            [-91.613617, 76.262207],
            [-91.257782, 76.204437],
            [-91.385559, 76.142487],
            [-91.220284, 76.161652],
            [-90.679718, 76.117203],
            [-90.848892, 76.060806],
            [-90.54834, 76.09137],
            [-90.190552, 76.061096],
            [-90.301666, 76.032494],
            [-91.160828, 76.018051],
            [-90.937767, 76.000267],
            [-91.074448, 75.986649],
            [-90.938599, 75.951393],
            [-91.12944, 75.9086],
            [-91.12999, 75.839157],
            [-91.079453, 75.848038],
            [-91.058884, 75.896652],
            [-90.895844, 75.9272],
            [-90.793877, 75.994713],
            [-90.681107, 75.981369],
            [-90.718063, 75.955551],
            [-90.433319, 75.970261],
            [-90.526108, 75.930542],
            [-90.529999, 75.898331],
            [-90.50473, 75.895264],
            [-90.339447, 75.968323],
            [-90.118607, 75.931931],
            [-90.060272, 76.004707],
            [-90, 76.00959],
            [-89.6875, 75.899986],
            [-89.778877, 75.831673],
            [-89.775009, 75.787491],
            [-89.555557, 75.839432],
            [-89.620003, 75.853592],
            [-89.587509, 75.859154],
            [-89.164169, 75.774696],
            [-89.253891, 75.631088],
            [-89.658051, 75.61998],
            [-89.765289, 75.575546],
            [-89.542503, 75.570541],
            [-89.700844, 75.55304],
            [-89.217216, 75.584991],
            [-89.099731, 75.484154],
            [-88.980003, 75.471367],
            [-88.963898, 75.431931],
            [-88.779716, 75.43248],
            [-88.75, 75.474991],
            [-88.902222, 75.604713],
            [-88.771942, 75.62886],
            [-88.738892, 75.679428],
            [-88.203613, 75.531097],
            [-88.305832, 75.492203],
            [-88.263062, 75.476089],
            [-87.997498, 75.528053],
            [-87.748894, 75.578323],
            [-87.661392, 75.567207],
            [-87.624161, 75.516388],
            [-87.495537, 75.485809],
            [-87.606377, 75.449707],
            [-87.533623, 75.446091],
            [-87.416397, 75.48526],
            [-87.513344, 75.559418],
            [-87.251106, 75.621094],
            [-86.807503, 75.479156],
            [-86.583893, 75.474701],
            [-86.375549, 75.427467],
            [-86.596657, 75.361649],
            [-86.169998, 75.418587],
            [-85.527222, 75.401093],
            [-85.822777, 75.430817],
            [-86.143066, 75.508041],
            [-85.539719, 75.599426],
            [-85.56778, 75.576927],
            [-85.537781, 75.564423],
            [-85.232224, 75.558594],
            [-85.321671, 75.574432],
            [-85.292221, 75.5961],
            [-85.040558, 75.631653],
            [-85.011673, 75.644989],
            [-85.074173, 75.651932],
            [-85.039169, 75.657761],
            [-84.497498, 75.631653],
            [-84.651108, 75.6436],
            [-84.634743, 75.66304],
            [-84.66362, 75.686096],
            [-84.299156, 75.702766],
            [-84.194443, 75.762497],
            [-84.022232, 75.767212],
            [-83.920837, 75.803589],
            [-84.002502, 75.820831],
            [-83.740547, 75.824432],
            [-83.698036, 75.814423],
            [-83.736938, 75.795258],
            [-83.515289, 75.789703],
            [-83.433609, 75.750267],
            [-83.099442, 75.732208],
            [-82.664719, 75.811371],
            [-82.327217, 75.836929],
            [-81.118057, 75.775543],
            [-81.101097, 75.752487],
            [-81.22084, 75.704712],
            [-81.271118, 75.651382],
            [-80.316963, 75.630539],
            [-80.068336, 75.578873],
            [-80.082779, 75.54248],
            [-79.953613, 75.540268],
            [-80.132874, 75.502579],
            [-80.35527, 75.473877],
            [-80.373047, 75.463043],
            [-80.338608, 75.456383],
            [-79.929169, 75.479713],
            [-79.586403, 75.454712],
            [-79.682503, 75.430817],
            [-79.488602, 75.362488],
            [-79.611938, 75.298317],
            [-79.44249, 75.277206],
            [-79.62944, 75.174988],
            [-79.83168, 75.170532],
            [-79.944153, 75.136383],
            [-79.974167, 75.089981],
            [-80.440552, 75.03804],
            [-80.173889, 74.982483],
            [-80.266113, 74.94664],
            [-80.035553, 74.957764],
            [-79.975281, 74.99942],
            [-79.795273, 75.027481],
            [-79.613892, 75.019989],
            [-79.504997, 74.998322],
            [-79.551941, 74.981659],
            [-79.335831, 74.88916],
            [-79.930557, 74.813309],
            [-80.171387, 74.86026],
            [-80.142502, 74.898041],
            [-80.18222, 74.937187],
            [-80.210831, 74.931091],
            [-80.199997, 74.886108],
            [-80.253067, 74.870819],
            [-80.321671, 74.937759],
            [-80.416107, 74.888893],
            [-80.097778, 74.820267],
            [-80.108337, 74.7836],
            [-80.246948, 74.757492],
            [-80.13028, 74.715553],
            [-80.212219, 74.690536],
            [-80.17305, 74.655823],
            [-80.196663, 74.641373],
            [-80.146667, 74.626923],
            [-80.248047, 74.576103],
            [-81.219162, 74.571381],
            [-81.810822, 74.45694],
            [-82.456673, 74.517212],
            [-82.382217, 74.541092],
            [-82.435547, 74.570267],
            [-82.479446, 74.536102],
            [-82.61528, 74.511108],
            [-82.914169, 74.549149],
            [-83.079727, 74.630257],
            [-83.123894, 74.684982],
            [-83.107773, 74.748032],
            [-83.023064, 74.780548],
            [-83.081123, 74.78804],
            [-83.08168, 74.818047],
            [-83.248894, 74.823608],
            [-83.528061, 74.901657],
            [-83.560547, 74.887207],
            [-83.518623, 74.839432],
            [-83.319458, 74.767487],
            [-83.441383, 74.660812],
            [-83.454727, 74.591087],
            [-83.611656, 74.540817],
            [-84.899437, 74.503326],
            [-84.983063, 74.570541],
            [-84.99527, 74.643333],
            [-84.950844, 74.67276],
            [-84.990547, 74.698029],
            [-85.07251, 74.641098],
            [-85.041946, 74.617752],
            [-85.04361, 74.523323],
            [-85.259453, 74.49054],
            [-85.363617, 74.501938],
            [-85.37027, 74.552467],
            [-85.527222, 74.688873],
            [-85.551941, 74.660263],
            [-85.480293, 74.537201],
            [-85.580841, 74.498322],
            [-86.120827, 74.482208],
            [-86.082779, 74.555252],
            [-86.197487, 74.615257],
            [-86.240547, 74.596939],
            [-86.24472, 74.52388],
            [-86.423317, 74.478867],
            [-86.691101, 74.544144],
            [-86.785278, 74.616928],
            [-86.794449, 74.539978],
            [-86.604172, 74.468048],
            [-86.943604, 74.46138],
            [-87.008904, 74.508331],
            [-87.037781, 74.474152],
            [-87.111938, 74.468048],
            [-87.320847, 74.476646],
            [-87.373894, 74.51915],
            [-87.309433, 74.545822],
            [-87.574722, 74.461929],
            [-87.732224, 74.466377],
            [-87.768623, 74.514999],
            [-87.90361, 74.472214],
            [-88.093063, 74.48027],
            [-88.133331, 74.507767],
            [-88.21666, 74.482758],
            [-88.535278, 74.503601],
            [-88.571121, 74.549988],
            [-88.560822, 74.593048],
            [-88.434433, 74.696091],
            [-88.405838, 74.748322],
            [-88.431671, 74.762497],
            [-88.293327, 74.772491],
            [-88.487213, 74.791367],
            [-88.443604, 74.838593],
            [-88.547783, 74.907761],
            [-88.579453, 74.889977],
            [-88.547501, 74.843048],
            [-88.615829, 74.815536],
            [-88.618057, 74.846367],
            [-88.664719, 74.844994],
            [-88.749733, 74.777771],
            [-88.753342, 74.714157],
            [-88.835564, 74.661377],
            [-88.889717, 74.670532],
            [-88.909729, 74.777771],
            [-89.088608, 74.837196],
            [-89.011124, 74.780823],
            [-89.047501, 74.722488],
            [-89.271118, 74.754707],
            [-89.09584, 74.688026],
            [-89.125, 74.616928],
            [-89.185272, 74.587486],
            [-89.928596, 74.530823],
            [-90, 74.586166],
            [-90.106659, 74.549423],
            [-90.363052, 74.594711],
            [-90.317497, 74.626373],
            [-90.589722, 74.613312],
            [-90.731667, 74.664146],
            [-90.761398, 74.714157],
            [-90.721123, 74.732208],
            [-90.845001, 74.721916],
            [-90.895607, 74.681137],
            [-91.024719, 74.702766],
            [-90.758057, 74.8311],
            [-90.740829, 74.847488],
            [-90.772232, 74.884987],
            [-90.853882, 74.875259],
            [-91.101936, 74.751099],
            [-91.22583, 74.733597],
            [-91.106659, 74.625809],
            [-91.281387, 74.633614],
            [-91.301392, 74.656372],
            [-91.277222, 74.688026],
            [-91.314713, 74.716087],
            [-91.366653, 74.704163],
            [-91.33139, 74.672493],
            [-91.381943, 74.641098],
            [-91.676102, 74.671921],
            [-91.62027, 74.704987],
            [-91.708618, 74.727478],
            [-91.851097, 74.698868],
            [-91.875267, 74.711929],
            [-91.81221, 74.734421],
            [-92.045547, 74.789703],
            [-92.066391, 74.813309],
            [-92.013062, 74.851929],
            [-92.015289, 74.913597],
            [-92.075562, 74.933037],
            [-92.04805, 74.958328],
            [-92.223328, 75.029427],
            [-92.198036, 75.052757],
            [-92.228882, 75.071114],
            [-92.029999, 75.08638],
            [-92.01001, 75.101089],
            [-92.112778, 75.12886],
            [-92.051392, 75.146942],
            [-92.271393, 75.132751],
            [-92.503616, 75.218048],
            [-92.458618, 75.250816],
            [-92.448334, 75.318329],
            [-92.328613, 75.369431],
            [-92.458618, 75.368874],
            [-92.403061, 75.436096],
            [-92.210281, 75.551376],
            [-92.004997, 75.594994],
            [-92.080841, 75.617477],
            [-92.008347, 75.661377],
            [-92.174438, 75.744431],
            [-92.104721, 75.805252],
            [-92.108612, 75.858871],
            [-92.629173, 76.008881],
            [-92.637222, 76.115807],
            [-92.767227, 76.166092],
            [-92.793877, 76.207489],
            [-93.066963, 76.299149],
            [-93.061478, 76.344788],
            [-93.315277, 76.36026],
            [-93.617493, 76.291092],
            [-93.666397, 76.298599],
            [-93.624443, 76.305817],
            [-93.636124, 76.32666],
            [-93.678879, 76.32222],
            [-93.763901, 76.286377],
            [-93.637512, 76.258614],
            [-93.689987, 76.248871],
            [-94.625816, 76.275269],
            [-94.720551, 76.315536],
            [-95.033333, 76.220833],
            [-95.386948, 76.235809],
            [-95.366943, 76.301376],
            [-95.279167, 76.281372],
            [-94.983887, 76.332489],
            [-94.843613, 76.303864],
            [-94.814163, 76.329163],
            [-95.274719, 76.372208],
            [-95.388901, 76.351929],
            [-95.450562, 76.375809],
            [-95.378883, 76.395538],
            [-95.83139, 76.394989],
            [-95.995827, 76.43692],
            [-96.106949, 76.494431],
            [-96.065552, 76.521378],
            [-95.77861, 76.518883],
            [-95.587784, 76.603592],
            [-95.780563, 76.548866],
            [-96.016953, 76.549149],
            [-96.001953, 76.584152],
            [-96.158623, 76.583328],
            [-96.238052, 76.635269],
            [-96.403343, 76.639709],
            [-96.470284, 76.685532],
            [-96.946663, 76.723602],
            [-96.84111, 76.766388],
            [-96.904449, 76.790543],
            [-96.855003, 76.813026],
            [-96.684723, 76.774696],
            [-96.673317, 76.748322],
            [-96.305557, 76.753883],
            [-96.583328, 76.771927],
            [-96.511398, 76.804153],
            [-96.320557, 76.806641],
            [-96.813606, 76.868042],
            [-96.865829, 76.898041],
            [-96.857773, 76.924988],
            [-96.659157, 76.949142],
            [-96.827789, 76.968872],
            [-96.742027, 76.982208],
          ],
        ],
        [
          [
            [-86.410278, 64.582764],
            [-86.238892, 64.804153],
            [-86.171661, 64.823036],
            [-86.210564, 64.884163],
            [-86.153343, 64.927757],
            [-86.225014, 65.003601],
            [-86.138344, 65.094437],
            [-86.171661, 65.27887],
            [-86.093887, 65.361649],
            [-86.153343, 65.38472],
            [-86.097778, 65.52916],
            [-86.019997, 65.621918],
            [-86.013901, 65.709152],
            [-85.791382, 65.853317],
            [-85.542503, 65.933319],
            [-85.468887, 65.913879],
            [-85.520554, 65.842758],
            [-85.493332, 65.795258],
            [-85.370003, 65.834991],
            [-85.156387, 65.776657],
            [-85.148064, 65.733322],
            [-85.18721, 65.721367],
            [-85.051666, 65.606934],
            [-85.311943, 65.537773],
            [-85.015007, 65.41832],
            [-85.012512, 65.322769],
            [-84.930557, 65.258614],
            [-84.924713, 65.209717],
            [-84.792503, 65.226929],
            [-84.745827, 65.351379],
            [-84.561943, 65.481087],
            [-84.43222, 65.453323],
            [-84.444717, 65.413597],
            [-84.384171, 65.388321],
            [-84.151108, 65.338593],
            [-84.230827, 65.26944],
            [-84.141388, 65.219994],
            [-83.889999, 65.190262],
            [-83.913887, 65.174698],
            [-83.876099, 65.162773],
            [-83.540283, 65.164146],
            [-83.408623, 65.135536],
            [-83.334732, 65.069992],
            [-83.330841, 65.021378],
            [-83.208054, 64.945534],
            [-83.022232, 64.913879],
            [-82.865829, 64.873596],
            [-82.800278, 64.808868],
            [-82.709167, 64.776382],
            [-82.542503, 64.739433],
            [-82.382492, 64.767761],
            [-82.202217, 64.713608],
            [-82.215012, 64.684982],
            [-82.092499, 64.684982],
            [-81.763062, 64.501099],
            [-81.753067, 64.35582],
            [-81.777222, 64.311653],
            [-81.600281, 64.202766],
            [-81.602493, 64.129967],
            [-81.955841, 64.06192],
            [-81.986938, 63.99416],
            [-81.461937, 64.034988],
            [-81.43222, 64.043587],
            [-81.442757, 64.086929],
            [-81.388901, 64.097214],
            [-81.398621, 64.072769],
            [-81.287781, 64.080276],
            [-80.931671, 63.99194],
            [-80.909157, 64.023323],
            [-80.975563, 64.057747],
            [-80.934723, 64.129967],
            [-80.777496, 64.079437],
            [-80.698608, 64.011383],
            [-80.522507, 63.9786],
            [-80.551666, 63.925549],
            [-80.492218, 63.905548],
            [-80.699997, 63.911659],
            [-80.66362, 63.883331],
            [-80.206123, 63.808331],
            [-80.171661, 63.771099],
            [-80.454178, 63.727772],
            [-80.587784, 63.63583],
            [-80.922501, 63.521381],
            [-80.958054, 63.458328],
            [-81.076401, 63.451389],
            [-81.535278, 63.58028],
            [-81.653877, 63.571941],
            [-81.76889, 63.63805],
            [-81.909729, 63.631939],
            [-82.05249, 63.689159],
            [-82.224716, 63.686378],
            [-82.257233, 63.659161],
            [-82.285553, 63.678051],
            [-82.328888, 63.650269],
            [-82.472229, 63.680271],
            [-82.546951, 63.764721],
            [-82.376389, 63.817501],
            [-82.361389, 63.90527],
            [-82.548889, 63.969151],
            [-82.873322, 63.982769],
            [-83.086937, 63.95499],
            [-83.145279, 64.001099],
            [-82.96167, 64.145538],
            [-83.01973, 64.188873],
            [-83.14711, 64.166634],
            [-83.191101, 64.118317],
            [-83.328888, 64.136932],
            [-83.394997, 64.104156],
            [-83.46611, 64.123596],
            [-83.54834, 64.102478],
            [-83.68222, 64.007217],
            [-83.604172, 63.928051],
            [-83.646957, 63.86721],
            [-83.59584, 63.825829],
            [-83.637222, 63.766392],
            [-83.750839, 63.776661],
            [-84.099731, 63.60527],
            [-84.282784, 63.628601],
            [-84.400558, 63.548611],
            [-84.477493, 63.38361],
            [-84.586121, 63.308041],
            [-85.224167, 63.120831],
            [-85.493332, 63.119991],
            [-85.589172, 63.174709],
            [-85.638062, 63.244999],
            [-85.653877, 63.408878],
            [-85.607224, 63.494438],
            [-85.628052, 63.55666],
            [-85.59111, 63.617489],
            [-85.613327, 63.66972],
            [-85.714722, 63.706661],
            [-85.710831, 63.76749],
            [-85.813049, 63.703609],
            [-86.245003, 63.639431],
            [-86.596123, 63.668598],
            [-86.879173, 63.55555],
            [-87.118607, 63.55582],
            [-87.217216, 63.622219],
            [-87.186661, 63.72221],
            [-86.934433, 63.906101],
            [-86.189438, 64.101646],
            [-86.212219, 64.178589],
            [-86.308884, 64.226379],
            [-86.291672, 64.256653],
            [-86.354721, 64.289978],
            [-86.384171, 64.364433],
            [-86.344452, 64.401093],
            [-86.400284, 64.431931],
            [-86.376389, 64.550537],
            [-86.410278, 64.582764],
          ],
        ],
        [
          [
            [-94.341667, 81.109711],
            [-93.516663, 81.065262],
            [-93.123322, 81.115257],
            [-93.179169, 81.135536],
            [-93.09584, 81.165268],
            [-93.243057, 81.19165],
            [-93.222778, 81.210823],
            [-93.928879, 81.203873],
            [-94.381668, 81.250816],
            [-94.37027, 81.284714],
            [-94.245537, 81.319443],
            [-94.27861, 81.341927],
            [-94.153877, 81.359711],
            [-93.754997, 81.344711],
            [-93.568336, 81.302757],
            [-93.483063, 81.319717],
            [-93.560547, 81.367752],
            [-93.517502, 81.384987],
            [-92.148064, 81.236366],
            [-91.800552, 81.15332],
            [-91.842216, 81.123871],
            [-91.78389, 81.083603],
            [-91.908623, 81.070541],
            [-91.538887, 80.981659],
            [-91.517776, 80.932747],
            [-91.144173, 80.814987],
            [-91.154999, 80.780548],
            [-91.121658, 80.754707],
            [-90.664719, 80.684708],
            [-90.593063, 80.640823],
            [-90.766403, 80.565536],
            [-90, 80.534592],
            [-89.751106, 80.464432],
            [-89.825844, 80.5186],
            [-89.783623, 80.532211],
            [-89.546112, 80.547493],
            [-89.245003, 80.517212],
            [-89.059158, 80.46138],
            [-89.257233, 80.396942],
            [-89.075844, 80.393051],
            [-89.131943, 80.359421],
            [-89.114166, 80.333328],
            [-89.256668, 80.298599],
            [-89.251404, 80.27832],
            [-89.072777, 80.195534],
            [-88.534729, 80.098877],
            [-88.414719, 80.10498],
            [-88.483322, 80.135818],
            [-88.421944, 80.142487],
            [-88.145554, 80.093872],
            [-88.425003, 80.186096],
            [-88.27417, 80.171921],
            [-88.273064, 80.195534],
            [-88.506958, 80.218323],
            [-88.704178, 80.284149],
            [-88.644997, 80.321663],
            [-88.685272, 80.371643],
            [-88.648903, 80.3936],
            [-88.383904, 80.443588],
            [-87.683884, 80.410263],
            [-87.607498, 80.324158],
            [-87.618332, 80.272774],
            [-87.563889, 80.233322],
            [-87.562767, 80.179153],
            [-88.065552, 80.120819],
            [-87.891388, 80.055542],
            [-87.275558, 80.06694],
            [-87.036942, 79.962486],
            [-87.007233, 79.946091],
            [-87.031952, 79.937477],
            [-87.489441, 79.84137],
            [-87.338608, 79.84082],
            [-87.024719, 79.916092],
            [-86.957779, 79.903587],
            [-87.058327, 79.804703],
            [-87.055557, 79.731934],
            [-87.134171, 79.645264],
            [-87.441673, 79.573883],
            [-87.462509, 79.534714],
            [-87.44194, 79.526382],
            [-87.309998, 79.502213],
            [-87.161118, 79.571663],
            [-86.96666, 79.601646],
            [-86.823624, 79.587769],
            [-86.84639, 79.549988],
            [-86.789993, 79.538879],
            [-86.694992, 79.56749],
            [-86.813049, 79.611923],
            [-86.762222, 79.631653],
            [-86.334167, 79.645538],
            [-86.046112, 79.568878],
            [-86.039719, 79.545532],
            [-86.137512, 79.51915],
            [-86.028061, 79.474701],
            [-86.167503, 79.457764],
            [-86.070847, 79.434143],
            [-85.901108, 79.493591],
            [-85.893341, 79.554703],
            [-85.828339, 79.607483],
            [-85.681953, 79.613312],
            [-85.531113, 79.559418],
            [-85.54361, 79.538589],
            [-85.390839, 79.449142],
            [-85.039719, 79.350807],
            [-84.904167, 79.267761],
            [-85.297234, 79.187187],
            [-86.422501, 79.075546],
            [-86.550827, 79.048866],
            [-86.587784, 78.983597],
            [-86.702217, 78.955261],
            [-86.785004, 78.957207],
            [-86.983322, 79.056641],
            [-87.004463, 78.987198],
            [-86.952499, 78.906647],
            [-87.328056, 78.794708],
            [-87.615829, 78.645264],
            [-87.872772, 78.694977],
            [-87.953613, 78.74971],
            [-88.003342, 78.807213],
            [-87.946663, 78.909149],
            [-87.970001, 78.965553],
            [-87.794449, 79.006943],
            [-87.833618, 79.026093],
            [-87.741669, 79.059982],
            [-87.746109, 79.086113],
            [-87.899986, 79.011108],
            [-88.202499, 78.976379],
            [-88.188049, 78.885818],
            [-88.229721, 78.8022],
            [-88.13028, 78.684708],
            [-88.351936, 78.65332],
            [-88.02417, 78.656647],
            [-87.89473, 78.584717],
            [-87.90834, 78.548599],
            [-88.011948, 78.481369],
            [-88.234161, 78.453598],
            [-88.559433, 78.604156],
            [-88.804169, 78.609711],
            [-88.755569, 78.589981],
            [-88.773331, 78.573608],
            [-88.746109, 78.535812],
            [-88.535553, 78.41304],
            [-88.672501, 78.316093],
            [-88.751404, 78.19693],
            [-88.81778, 78.154427],
            [-89.115829, 78.200821],
            [-89.353882, 78.339706],
            [-89.658051, 78.431091],
            [-90, 78.609543],
            [-90.088333, 78.595833],
            [-90.10083, 78.549713],
            [-90, 78.466957],
            [-89.985001, 78.436096],
            [-89.748047, 78.380257],
            [-89.452499, 78.162491],
            [-89.618607, 78.16304],
            [-89.673889, 78.217209],
            [-89.911118, 78.218872],
            [-90, 78.284409],
            [-90.186111, 78.333328],
            [-90.744453, 78.323036],
            [-90.325287, 78.271652],
            [-90.375549, 78.240807],
            [-90.272781, 78.176933],
            [-90.465012, 78.135269],
            [-91.489723, 78.176933],
            [-91.857498, 78.2397],
            [-92.083328, 78.212486],
            [-92.190277, 78.278053],
            [-92.323898, 78.287201],
            [-92.181953, 78.319717],
            [-92.537216, 78.310532],
            [-92.609444, 78.330833],
            [-92.613617, 78.357208],
            [-92.919724, 78.418869],
            [-92.982498, 78.454437],
            [-92.978882, 78.483322],
            [-91.848343, 78.546097],
            [-91.660828, 78.526932],
            [-91.632492, 78.539429],
            [-91.657501, 78.563873],
            [-92.585564, 78.5961],
            [-92.806107, 78.633614],
            [-93.271118, 78.584152],
            [-93.4664, 78.645828],
            [-93.35611, 78.679428],
            [-93.555557, 78.680817],
            [-93.813606, 78.765823],
            [-93.589722, 78.7836],
            [-93.16333, 78.735527],
            [-93.054993, 78.739967],
            [-93.037781, 78.765823],
            [-93.416397, 78.824997],
            [-93.87471, 78.833603],
            [-94.282227, 78.968872],
            [-93.910278, 79.041931],
            [-93.608337, 79.051651],
            [-93.472778, 79.108871],
            [-93.460564, 79.142761],
            [-93.329727, 79.164429],
            [-92.86972, 79.139709],
            [-92.746948, 79.164146],
            [-92.309433, 79.145264],
            [-91.628098, 79.178452],
            [-90.564163, 79.215553],
            [-90.363274, 79.246811],
            [-92.180832, 79.203049],
            [-92.446663, 79.223038],
            [-92.694717, 79.257217],
            [-92.603882, 79.300812],
            [-91.932503, 79.297211],
            [-91.146667, 79.356369],
            [-91.129173, 79.390823],
            [-92.181107, 79.345833],
            [-92.571671, 79.370529],
            [-92.243881, 79.426651],
            [-92.259171, 79.44693],
            [-92.580841, 79.452209],
            [-92.876099, 79.407761],
            [-93.090561, 79.482208],
            [-93.144447, 79.463882],
            [-93.021393, 79.382751],
            [-93.374161, 79.336929],
            [-93.229721, 79.425537],
            [-93.338058, 79.447197],
            [-93.485283, 79.354156],
            [-93.665833, 79.360527],
            [-93.683884, 79.339432],
            [-93.628601, 79.31694],
            [-93.870003, 79.263893],
            [-94.212219, 79.276657],
            [-94.161942, 79.286377],
            [-94.167221, 79.31694],
            [-94.11972, 79.344711],
            [-93.838608, 79.392761],
            [-94.027222, 79.377472],
            [-94.386124, 79.421371],
            [-94.509171, 79.416382],
            [-94.5, 79.3797],
            [-94.389999, 79.368874],
            [-94.506668, 79.337196],
            [-95.087547, 79.270752],
            [-95.235283, 79.286102],
            [-95.191383, 79.309143],
            [-95.318619, 79.332207],
            [-95.163887, 79.344711],
            [-95.285004, 79.353043],
            [-95.308884, 79.390823],
            [-95.281952, 79.399719],
            [-95.444153, 79.372757],
            [-95.771667, 79.409714],
            [-95.748611, 79.424698],
            [-95.777222, 79.430267],
            [-95.671661, 79.439423],
            [-95.778343, 79.474701],
            [-95.70639, 79.501099],
            [-95.736389, 79.537491],
            [-94.699432, 79.612198],
            [-94.329453, 79.688309],
            [-94.357773, 79.726929],
            [-94.282784, 79.757492],
            [-94.318336, 79.778587],
            [-94.814438, 79.670258],
            [-95.853333, 79.646103],
            [-96.149437, 79.739151],
            [-96.238052, 79.736923],
            [-96.253067, 79.783333],
            [-96.335564, 79.815536],
            [-96.490547, 79.836113],
            [-96.391953, 79.863037],
            [-96.589447, 79.852478],
            [-96.609444, 79.888603],
            [-96.458618, 79.914429],
            [-96.391388, 79.913879],
            [-96.381668, 79.881653],
            [-96.138062, 79.906372],
            [-96.606659, 79.962196],
            [-96.679443, 80.014427],
            [-96.391678, 80.045822],
            [-96.676102, 80.041931],
            [-96.80278, 80.09082],
            [-96.711121, 80.144989],
            [-96.410004, 80.138893],
            [-95.847778, 80.053307],
            [-94.852783, 80.044144],
            [-94.416946, 79.978867],
            [-94.711533, 80.070663],
            [-94.803879, 80.092758],
            [-94.611938, 80.135536],
            [-94.120003, 80.183037],
            [-94.596947, 80.224152],
            [-94.816963, 80.159714],
            [-95.367767, 80.118317],
            [-95.695831, 80.178307],
            [-95.295837, 80.212769],
            [-95.243332, 80.243591],
            [-95.496948, 80.219437],
            [-95.645844, 80.2397],
            [-95.933609, 80.194427],
            [-96.566391, 80.272217],
            [-96.47084, 80.32222],
            [-96.681671, 80.342209],
            [-96.592216, 80.362762],
            [-96.232224, 80.334717],
            [-96.268066, 80.367477],
            [-96.047783, 80.389977],
            [-95.440826, 80.338318],
            [-95.468063, 80.382202],
            [-96.098343, 80.488037],
            [-95.933884, 80.508881],
            [-95.904716, 80.540543],
            [-96.027222, 80.574158],
            [-95.980003, 80.584717],
            [-94.995003, 80.603043],
            [-94.752792, 80.559982],
            [-93.990547, 80.554703],
            [-93.958344, 80.526093],
            [-93.786392, 80.525543],
            [-94.004997, 80.585274],
            [-94.543327, 80.599716],
            [-94.660278, 80.651382],
            [-94.662216, 80.675262],
            [-94.596947, 80.690536],
            [-94.199722, 80.693039],
            [-94.088333, 80.70166],
            [-94.108337, 80.718872],
            [-94.895554, 80.747757],
            [-95.037514, 80.771378],
            [-94.857773, 80.801376],
            [-94.888611, 80.814423],
            [-95.282501, 80.786102],
            [-95.534157, 80.818878],
            [-95.150833, 80.881088],
            [-95.484734, 80.899147],
            [-95.283623, 80.949997],
            [-95.241096, 81.006104],
            [-94.904999, 81.05748],
            [-94.572777, 81.038879],
            [-94.457779, 81.012207],
            [-94.508057, 80.979431],
            [-94.434158, 80.965553],
            [-94.298607, 81.008331],
            [-93.936394, 81.017761],
            [-94.070557, 81.041656],
            [-93.906387, 81.040543],
            [-94.14917, 81.056641],
            [-94.341667, 81.109711],
          ],
        ],
        [
          [
            [-101.086098, 73.325699],
            [-101.305, 73.361649],
            [-101.3172, 73.401657],
            [-101.580803, 73.450272],
            [-101.546097, 73.459991],
            [-101.621399, 73.490257],
            [-101.523903, 73.489433],
            [-101.427498, 73.5522],
            [-101.2808, 73.552467],
            [-101.217201, 73.604156],
            [-100.890297, 73.5961],
            [-100.672798, 73.464432],
            [-100.499702, 73.412491],
            [-100.415001, 73.418053],
            [-100.606102, 73.497208],
            [-100.5439, 73.556374],
            [-100.551697, 73.598877],
            [-100.9114, 73.622757],
            [-100.858002, 73.667213],
            [-101.046997, 73.673027],
            [-101.057198, 73.69664],
            [-101.014999, 73.712769],
            [-101.118301, 73.723312],
            [-100.981903, 73.763611],
            [-101.027199, 73.775269],
            [-101.013901, 73.797211],
            [-100.886398, 73.827477],
            [-100.754997, 73.812477],
            [-100.648399, 73.848328],
            [-100.417801, 73.845543],
            [-100.136902, 73.795532],
            [-100.026703, 73.729713],
            [-100.027199, 73.763046],
            [-99.955566, 73.813873],
            [-99.857773, 73.842758],
            [-99.843063, 73.878311],
            [-99.871384, 73.898331],
            [-99.892776, 73.891373],
            [-99.873611, 73.866928],
            [-99.948608, 73.87886],
            [-100.0494, 73.832764],
            [-100.249397, 73.833878],
            [-100.292198, 73.872208],
            [-100.142502, 73.931091],
            [-99.85611, 73.940811],
            [-99.800827, 73.931664],
            [-99.836121, 73.901093],
            [-99.785278, 73.867203],
            [-99.398064, 73.798866],
            [-99.206673, 73.725807],
            [-99.247772, 73.696091],
            [-99.189163, 73.685257],
            [-99.115013, 73.748596],
            [-98.789719, 73.734993],
            [-98.688316, 73.772018],
            [-98.134171, 73.809708],
            [-97.960281, 73.846939],
            [-97.900284, 73.896103],
            [-97.734444, 73.905548],
            [-97.825012, 73.858871],
            [-97.602493, 73.894989],
            [-97.47139, 73.857758],
            [-97.223618, 73.856369],
            [-96.962219, 73.738586],
            [-96.934723, 73.68692],
            [-96.968613, 73.633331],
            [-97.202499, 73.557213],
            [-97.433884, 73.581383],
            [-97.459732, 73.573883],
            [-97.432503, 73.531097],
            [-97.459732, 73.515823],
            [-97.638062, 73.538589],
            [-97.668327, 73.483322],
            [-97.623322, 73.456383],
            [-97.417221, 73.493317],
            [-97.18306, 73.464996],
            [-97.17305, 73.443588],
            [-97.248894, 73.429153],
            [-97.167503, 73.410812],
            [-97.171944, 73.352768],
            [-97.267227, 73.36499],
            [-97.841377, 73.273323],
            [-97.862503, 73.233871],
            [-98.240829, 73.075272],
            [-98.510277, 73.00943],
            [-98.44249, 72.981659],
            [-98.473618, 72.95665],
            [-98.42749, 72.943588],
            [-98.445831, 72.865807],
            [-98.227219, 72.987488],
            [-97.846947, 73.048599],
            [-97.283333, 72.963882],
            [-97.225014, 72.939972],
            [-97.253891, 72.931664],
            [-97.258057, 72.883614],
            [-97.415283, 72.86026],
            [-97.236389, 72.842484],
            [-97.161118, 72.780548],
            [-97.053047, 72.757492],
            [-97.029716, 72.71666],
            [-97.170547, 72.673599],
            [-97.196663, 72.604431],
            [-97.081009, 72.605927],
            [-96.915558, 72.678589],
            [-96.536667, 72.750267],
            [-96.517502, 72.714706],
            [-96.585007, 72.693863],
            [-96.455276, 72.640549],
            [-96.459732, 72.607758],
            [-96.405273, 72.559418],
            [-96.438599, 72.534714],
            [-96.37471, 72.534416],
            [-96.325562, 72.488312],
            [-96.29834, 72.415817],
            [-96.39917, 72.407211],
            [-96.446114, 72.369141],
            [-96.668327, 72.309708],
            [-96.871933, 72.321114],
            [-96.561111, 72.275543],
            [-96.614723, 72.245819],
            [-96.533333, 72.236923],
            [-96.485001, 72.129967],
            [-96.498337, 72.090271],
            [-96.866943, 72.041092],
            [-96.672501, 72.012772],
            [-96.488602, 72.034988],
            [-96.522507, 71.959152],
            [-96.76445, 71.914993],
            [-96.491669, 71.926086],
            [-96.570847, 71.819443],
            [-96.74472, 71.792213],
            [-96.738052, 71.824997],
            [-96.67778, 71.837486],
            [-96.775284, 71.843597],
            [-96.978058, 71.795258],
            [-97.084167, 71.700272],
            [-97.504997, 71.611649],
            [-97.988052, 71.661926],
            [-98.207497, 71.646103],
            [-98.359734, 71.728043],
            [-98.321404, 71.803307],
            [-98.209167, 71.88916],
            [-98.267227, 71.90416],
            [-98.291382, 71.894707],
            [-98.262787, 71.866653],
            [-98.338608, 71.85498],
            [-98.462509, 71.783867],
            [-98.493881, 71.713882],
            [-98.391113, 71.707489],
            [-98.410278, 71.688873],
            [-98.381378, 71.653587],
            [-98.18277, 71.583878],
            [-98.148621, 71.538879],
            [-98.037514, 71.526657],
            [-98.198036, 71.414703],
            [-98.46611, 71.313309],
            [-98.729721, 71.270538],
            [-98.829453, 71.293869],
            [-98.960281, 71.379967],
            [-99.238052, 71.344994],
            [-99.313606, 71.470543],
            [-99.410278, 71.53804],
            [-99.369164, 71.575546],
            [-99.574173, 71.619713],
            [-99.678329, 71.760536],
            [-99.788597, 71.786102],
            [-99.872498, 71.858032],
            [-100.101898, 71.88472],
            [-100.443604, 72.057213],
            [-100.423302, 72.096367],
            [-100.481697, 72.086113],
            [-100.513298, 72.108032],
            [-100.490799, 72.126923],
            [-100.634499, 72.185532],
            [-100.8853, 72.207764],
            [-100.951401, 72.171097],
            [-101.0214, 72.202209],
            [-101.055603, 72.236649],
            [-101.019203, 72.254707],
            [-101.083298, 72.258614],
            [-101.037498, 72.276382],
            [-101.220596, 72.332207],
            [-101.395599, 72.286926],
            [-101.402496, 72.244141],
            [-101.495003, 72.244431],
            [-101.469498, 72.265549],
            [-101.585602, 72.301376],
            [-101.776901, 72.299713],
            [-101.890297, 72.362488],
            [-101.879997, 72.399147],
            [-101.775803, 72.389427],
            [-101.916397, 72.406097],
            [-101.963303, 72.463318],
            [-101.911697, 72.472214],
            [-102.597801, 72.648331],
            [-102.741699, 72.724152],
            [-102.714996, 72.745819],
            [-102.7631, 72.790817],
            [-102.646698, 72.864433],
            [-102.576698, 72.979713],
            [-102.501099, 73.030548],
            [-102.2761, 73.082489],
            [-101.962196, 73.089157],
            [-101.9683, 73.049988],
            [-101.768898, 73.021103],
            [-101.7911, 72.98082],
            [-101.732803, 72.967209],
            [-101.817802, 72.96666],
            [-101.741096, 72.924149],
            [-101.618103, 72.886383],
            [-101.618896, 72.909988],
            [-101.5411, 72.921921],
            [-101.595299, 72.902206],
            [-101.509697, 72.871643],
            [-101.4506, 72.816383],
            [-101.466698, 72.800262],
            [-101.294403, 72.789978],
            [-101.296402, 72.74498],
            [-101.4133, 72.748322],
            [-101.366699, 72.725273],
            [-100.882202, 72.689697],
            [-100.812202, 72.715553],
            [-100.870796, 72.735809],
            [-100.828102, 72.758614],
            [-100.447998, 72.735527],
            [-100.331703, 72.780548],
            [-100.324402, 72.838882],
            [-100.416702, 72.869141],
            [-100.387199, 72.897217],
            [-100.442497, 72.921921],
            [-100.3881, 72.944977],
            [-100.498901, 72.950546],
            [-100.421898, 73.034988],
            [-100.309402, 73.028053],
            [-100.407799, 73.011383],
            [-100.381104, 73],
            [-100.419998, 72.974152],
            [-100.381401, 72.949142],
            [-100.292801, 72.931931],
            [-100.296997, 72.958603],
            [-100.2556, 72.953873],
            [-100.261101, 72.921097],
            [-100.328598, 72.904984],
            [-100.326103, 72.878036],
            [-100.077499, 72.886932],
            [-100.059402, 72.896942],
            [-100.086899, 72.920258],
            [-100.031403, 72.934982],
            [-100.176399, 72.987762],
            [-100.1064, 73.000816],
            [-100.140602, 73.048027],
            [-100.220596, 73.04248],
            [-100.1772, 73.083878],
            [-100.323898, 73.093597],
            [-100.232201, 73.13443],
            [-100.523903, 73.089706],
            [-100.518303, 73.120247],
            [-100.607803, 73.146378],
            [-100.472801, 73.193314],
            [-100.544701, 73.228317],
            [-100.361099, 73.290268],
            [-100.196701, 73.233322],
            [-100.107803, 73.241364],
            [-100.134697, 73.2211],
            [-100.037498, 73.183868],
            [-99.771667, 73.208038],
            [-100.077499, 73.251389],
            [-100.373901, 73.395828],
            [-100.4058, 73.361366],
            [-100.336098, 73.324997],
            [-100.583603, 73.2836],
            [-100.840599, 73.25972],
            [-101.086098, 73.325699],
          ],
        ],
        [
          [
            [-95.60054, 73.283913],
            [-95.590843, 73.304428],
            [-95.650833, 73.325272],
            [-95.60083, 73.381653],
            [-95.683884, 73.450272],
            [-95.626099, 73.500549],
            [-95.652496, 73.511383],
            [-95.632767, 73.527771],
            [-95.700287, 73.553864],
            [-95.631668, 73.588318],
            [-95.541107, 73.576103],
            [-95.723328, 73.639977],
            [-95.676102, 73.664993],
            [-95.716949, 73.689423],
            [-95.656387, 73.699417],
            [-95.681953, 73.711929],
            [-95.65889, 73.732483],
            [-95.299728, 73.771103],
            [-94.956673, 73.659149],
            [-94.618057, 73.651382],
            [-94.866096, 73.687187],
            [-95.075562, 73.773323],
            [-95.116096, 73.806641],
            [-94.982498, 73.845543],
            [-95.154167, 73.823608],
            [-95.304169, 73.880814],
            [-95.324722, 73.952477],
            [-95.24527, 74.010269],
            [-94.732224, 74.095261],
            [-94.17778, 74.133614],
            [-93.914169, 74.136108],
            [-93.765007, 74.075821],
            [-93.721939, 74.083054],
            [-93.758347, 74.096939],
            [-93.730827, 74.15416],
            [-93.515007, 74.173027],
            [-92.796387, 74.124977],
            [-92.748894, 74.113312],
            [-92.761124, 74.080551],
            [-92.711937, 74.080551],
            [-92.638062, 74.103043],
            [-92.334442, 74.031372],
            [-92.273621, 73.99054],
            [-92.327789, 73.951393],
            [-92.309433, 73.940811],
            [-92.132492, 73.946373],
            [-92.094162, 73.989151],
            [-91.925552, 74.012772],
            [-91.570847, 74.025818],
            [-91.065002, 74.006104],
            [-90.406662, 73.914703],
            [-90.31778, 73.925262],
            [-90.204178, 73.888321],
            [-90.275284, 73.838593],
            [-90.316963, 73.871368],
            [-90.376663, 73.85582],
            [-90.372498, 73.789703],
            [-90.765007, 73.562187],
            [-90.989723, 73.553307],
            [-90.930283, 73.483871],
            [-91.180557, 73.345833],
            [-91.262222, 73.263893],
            [-91.634453, 73.228867],
            [-91.367767, 73.200821],
            [-91.642776, 73.021103],
            [-91.646118, 72.97998],
            [-91.818893, 72.862198],
            [-92.066101, 72.752487],
            [-92.393341, 72.707207],
            [-93.222778, 72.780823],
            [-93.311394, 72.814697],
            [-93.603058, 72.774986],
            [-94.29805, 72.770264],
            [-94.334442, 72.738037],
            [-94.3125, 72.715553],
            [-94.142227, 72.734993],
            [-93.902496, 72.705833],
            [-93.775558, 72.734993],
            [-93.81723, 72.642212],
            [-93.568619, 72.570831],
            [-93.463333, 72.462196],
            [-93.628052, 72.341927],
            [-93.819733, 72.307213],
            [-94.014183, 72.163879],
            [-94.080841, 72.06694],
            [-94.228333, 72.039978],
            [-94.153061, 72.018883],
            [-94.022232, 72.059418],
            [-94.069733, 72.024147],
            [-94.029716, 71.99942],
            [-94.063049, 71.978317],
            [-94.449722, 72.023323],
            [-95.175827, 71.966927],
            [-95.10527, 71.99498],
            [-95.207779, 71.988876],
            [-95.196381, 72.052467],
            [-95.083618, 72.097763],
            [-95.196663, 72.106644],
            [-94.752228, 72.15332],
            [-95.171112, 72.13916],
            [-95.161667, 72.162773],
            [-95.214722, 72.205261],
            [-95.159729, 72.350807],
            [-95.236107, 72.428589],
            [-95.145279, 72.431374],
            [-95.196663, 72.450821],
            [-95.133331, 72.460274],
            [-95.200287, 72.524429],
            [-95.316101, 72.539703],
            [-95.346657, 72.587486],
            [-95.313316, 72.601089],
            [-95.364441, 72.643333],
            [-95.602219, 72.702209],
            [-95.599731, 72.73526],
            [-95.513062, 72.736923],
            [-95.570007, 72.765823],
            [-95.55722, 72.785538],
            [-95.666107, 72.801376],
            [-95.656387, 72.901093],
            [-95.622772, 72.927467],
            [-95.718613, 72.999153],
            [-95.655563, 73.019989],
            [-95.642227, 73.053307],
            [-95.688316, 73.080551],
            [-95.657501, 73.112198],
            [-95.5, 73.128593],
            [-95.579453, 73.159416],
            [-95.596947, 73.219437],
            [-95.557503, 73.23082],
            [-95.60054, 73.283913],
          ],
        ],
        [
          [
            [-99.45462, 75.974808],
            [-99.863327, 75.935532],
            [-99.905563, 75.9561],
            [-99.87471, 75.983871],
            [-100.035301, 76.006943],
            [-99.995003, 76.034149],
            [-100.077499, 76.038879],
            [-100.1119, 76.101379],
            [-100.220596, 76.132202],
            [-100.031097, 76.155548],
            [-99.714172, 76.114433],
            [-99.414436, 76.158333],
            [-99.716949, 76.139709],
            [-100.001099, 76.201927],
            [-100.438904, 76.212486],
            [-100.512802, 76.249153],
            [-100.464996, 76.274986],
            [-100.074699, 76.271927],
            [-100.203598, 76.298866],
            [-100.186401, 76.313873],
            [-100.029198, 76.27916],
            [-99.848618, 76.283867],
            [-100.0233, 76.295822],
            [-99.92305, 76.317757],
            [-100.153099, 76.349152],
            [-100.179398, 76.360809],
            [-100.147202, 76.380257],
            [-100.694397, 76.374977],
            [-100.982498, 76.50499],
            [-100.7117, 76.532211],
            [-100.723297, 76.556091],
            [-100.653099, 76.576393],
            [-100.318901, 76.635536],
            [-99.837784, 76.608597],
            [-99.684433, 76.633331],
            [-99.551392, 76.609154],
            [-99.676941, 76.59166],
            [-99.522781, 76.588318],
            [-99.291672, 76.500267],
            [-99.143623, 76.501663],
            [-99.25528, 76.453598],
            [-99.079727, 76.397217],
            [-99.122498, 76.461113],
            [-98.980827, 76.471649],
            [-98.925827, 76.436096],
            [-98.846123, 76.436096],
            [-98.889183, 76.446373],
            [-98.86528, 76.469147],
            [-99.050552, 76.536102],
            [-99.027222, 76.601089],
            [-98.814713, 76.613602],
            [-98.743057, 76.579987],
            [-98.725037, 76.611542],
            [-98.538887, 76.616379],
            [-98.509453, 76.630539],
            [-98.537216, 76.641937],
            [-98.483063, 76.649147],
            [-98.744453, 76.643883],
            [-98.852493, 76.671654],
            [-98.480293, 76.679153],
            [-98.403877, 76.661377],
            [-98.419724, 76.622482],
            [-98.151672, 76.585823],
            [-98.395554, 76.594437],
            [-98.357773, 76.565536],
            [-97.662781, 76.481087],
            [-97.695541, 76.460823],
            [-97.662514, 76.419434],
            [-97.727219, 76.395538],
            [-97.783623, 76.319717],
            [-97.509171, 76.188873],
            [-97.494453, 76.138893],
            [-97.588333, 76.080833],
            [-97.58139, 76.042213],
            [-97.656387, 75.972763],
            [-97.558037, 75.864433],
            [-97.664169, 75.79332],
            [-97.938599, 75.741364],
            [-97.386673, 75.682747],
            [-97.369164, 75.65332],
            [-97.411942, 75.61554],
            [-97.422501, 75.506378],
            [-97.378326, 75.434143],
            [-97.280838, 75.396942],
            [-97.469162, 75.42276],
            [-97.496109, 75.4422],
            [-97.487503, 75.496094],
            [-97.711937, 75.566673],
            [-97.762512, 75.568047],
            [-97.752792, 75.53804],
            [-97.841949, 75.550812],
            [-97.856949, 75.535538],
            [-97.789993, 75.518883],
            [-97.74527, 75.46666],
            [-97.835831, 75.460823],
            [-97.847504, 75.502213],
            [-97.932503, 75.512497],
            [-98.043327, 75.481369],
            [-97.949722, 75.461113],
            [-98.055557, 75.452477],
            [-97.774719, 75.423027],
            [-98.036942, 75.412201],
            [-97.876663, 75.36499],
            [-98.069168, 75.349716],
            [-98.107773, 75.371918],
            [-98.164719, 75.329163],
            [-98.080566, 75.329987],
            [-98.129173, 75.301933],
            [-98.04805, 75.284714],
            [-98.006119, 75.319717],
            [-97.894173, 75.329437],
            [-97.995827, 75.27887],
            [-97.913063, 75.26416],
            [-97.922501, 75.295258],
            [-97.887512, 75.300537],
            [-97.873894, 75.270264],
            [-97.743607, 75.224991],
            [-97.736938, 75.204437],
            [-97.800278, 75.193863],
            [-97.69249, 75.193863],
            [-97.654167, 75.179703],
            [-97.676941, 75.164146],
            [-97.567497, 75.147491],
            [-97.619453, 75.118591],
            [-97.862503, 75.111099],
            [-98.024719, 75.162491],
            [-98.025833, 75.210823],
            [-98.098618, 75.222763],
            [-98.07251, 75.199142],
            [-98.146667, 75.163879],
            [-98.090652, 75.137299],
            [-97.966949, 75.104156],
            [-97.938599, 75.064987],
            [-97.949997, 75.021103],
            [-97.982498, 75.015266],
            [-98.748337, 74.989967],
            [-98.84584, 75.00972],
            [-98.859161, 75.061653],
            [-98.960281, 75.051933],
            [-98.928047, 75.012207],
            [-98.956123, 75.003601],
            [-99.376663, 74.985527],
            [-99.406113, 74.993317],
            [-99.37471, 75.007767],
            [-99.387512, 75.037773],
            [-99.281387, 75.122482],
            [-99.496658, 75.026382],
            [-99.426392, 74.991364],
            [-99.538597, 74.974152],
            [-100.347801, 75.016937],
            [-100.393898, 75.037201],
            [-100.378098, 75.113312],
            [-100.430801, 75.147217],
            [-100.398102, 75.1586],
            [-100.538597, 75.204712],
            [-99.987778, 75.236099],
            [-100.393898, 75.260269],
            [-100.257202, 75.313309],
            [-100.523102, 75.293053],
            [-100.7789, 75.35054],
            [-100.4328, 75.375259],
            [-100.6978, 75.436371],
            [-100.011902, 75.46138],
            [-100.306999, 75.471649],
            [-99.951111, 75.492477],
            [-100.283096, 75.514999],
            [-100.155403, 75.52359],
            [-99.845001, 75.540817],
            [-100.039398, 75.554153],
            [-99.712509, 75.589157],
            [-99.66777, 75.611099],
            [-99.863052, 75.614433],
            [-99.809433, 75.635536],
            [-99.817497, 75.655258],
            [-99.033073, 75.6772],
            [-98.889999, 75.695251],
            [-98.971939, 75.710274],
            [-100.559196, 75.647217],
            [-101.365501, 75.574158],
            [-101.377197, 75.598877],
            [-101.495499, 75.601929],
            [-102.668297, 75.497208],
            [-102.671898, 75.52832],
            [-102.7789, 75.544434],
            [-102.728897, 75.572487],
            [-102.8778, 75.624687],
            [-102.632202, 75.6147],
            [-102.701401, 75.62886],
            [-102.666397, 75.648613],
            [-102.689201, 75.670532],
            [-102.531403, 75.691093],
            [-102.583099, 75.709991],
            [-102.538902, 75.721367],
            [-102.009201, 75.703049],
            [-102.236702, 75.744141],
            [-102.093597, 75.791092],
            [-102.366096, 75.789978],
            [-102.290298, 75.857208],
            [-101.864403, 75.902206],
            [-101.459702, 75.810806],
            [-101.459702, 75.761108],
            [-101.254997, 75.744713],
            [-100.911102, 75.811653],
            [-101.347801, 75.774986],
            [-101.323601, 75.80748],
            [-101.363899, 75.847763],
            [-101.537201, 75.861366],
            [-101.597801, 75.919708],
            [-101.302803, 76.013046],
            [-101.678299, 75.979713],
            [-101.8489, 76.024696],
            [-101.907204, 76.078598],
            [-101.888603, 76.119141],
            [-101.761101, 76.174149],
            [-101.385002, 76.248871],
            [-102.133102, 76.219994],
            [-102.165802, 76.238312],
            [-102.063904, 76.255257],
            [-102.059402, 76.27916],
            [-102.194397, 76.289429],
            [-102.041397, 76.318604],
            [-102.097198, 76.34166],
            [-102.064697, 76.358322],
            [-101.957497, 76.357483],
            [-102.050797, 76.398041],
            [-101.862198, 76.450272],
            [-101.315804, 76.414429],
            [-101.058296, 76.326927],
            [-101.140602, 76.240257],
            [-100.751099, 76.182213],
            [-100.625504, 76.127472],
            [-100.727203, 76.090553],
            [-100.417503, 76.116379],
            [-100.621101, 76.079712],
            [-100.315804, 76.051376],
            [-100.171898, 75.959717],
            [-100.071098, 75.945534],
            [-100.145302, 75.908043],
            [-99.983612, 75.919434],
            [-99.981949, 75.88472],
            [-99.930283, 75.873596],
            [-99.45462, 75.974808],
          ],
        ],
        [
          [
            [-99.57988, 69.04393],
            [-99.455002, 69.137772],
            [-99.341667, 69.123596],
            [-99.311394, 69.158867],
            [-99.006958, 69.136383],
            [-98.936394, 69.172493],
            [-98.720284, 69.193863],
            [-98.706947, 69.261383],
            [-98.653877, 69.294983],
            [-98.555557, 69.275269],
            [-98.393341, 69.308029],
            [-98.397232, 69.338318],
            [-98.611938, 69.444138],
            [-98.577217, 69.479431],
            [-98.419449, 69.471367],
            [-98.601669, 69.520828],
            [-98.556664, 69.580833],
            [-98.357773, 69.559143],
            [-98.219162, 69.473312],
            [-98.103333, 69.459717],
            [-98.092499, 69.424988],
            [-98.003891, 69.435806],
            [-98.263062, 69.578873],
            [-98.367767, 69.601089],
            [-98.281952, 69.751663],
            [-97.974167, 69.892212],
            [-97.458054, 69.76944],
            [-97.34111, 69.706383],
            [-97.489441, 69.673866],
            [-97.391388, 69.593323],
            [-97.303879, 69.698318],
            [-97.273903, 69.694702],
            [-96.879173, 69.491364],
            [-96.789719, 69.495247],
            [-96.51889, 69.418053],
            [-96.218338, 69.311096],
            [-96.176392, 69.269989],
            [-96.251404, 69.15387],
            [-96.210007, 69.123032],
            [-96.229721, 69.054703],
            [-96.118881, 69.025543],
            [-96.15834, 69.086929],
            [-96.134743, 69.125259],
            [-96.155838, 69.168587],
            [-96.047783, 69.228317],
            [-95.924438, 69.089432],
            [-95.978882, 69.043869],
            [-95.857773, 68.972214],
            [-95.833618, 68.872757],
            [-95.756958, 68.891373],
            [-95.626099, 68.82666],
            [-95.351387, 68.899429],
            [-95.206947, 68.850273],
            [-95.535278, 68.671921],
            [-95.565552, 68.674698],
            [-95.538887, 68.723312],
            [-95.593887, 68.752777],
            [-95.790558, 68.737198],
            [-95.798607, 68.693588],
            [-95.859734, 68.65332],
            [-95.852493, 68.608597],
            [-96.000839, 68.619431],
            [-96.291382, 68.473038],
            [-96.503616, 68.446091],
            [-96.928329, 68.521378],
            [-97.099167, 68.587769],
            [-97.143623, 68.584427],
            [-97.098892, 68.528587],
            [-97.181107, 68.511383],
            [-97.506119, 68.541931],
            [-97.761398, 68.653587],
            [-98.035553, 68.694702],
            [-98.120003, 68.670822],
            [-98.290558, 68.755547],
            [-98.244164, 68.82222],
            [-98.383331, 68.859993],
            [-98.425003, 68.84137],
            [-98.400284, 68.770828],
            [-98.519447, 68.747482],
            [-98.575012, 68.786652],
            [-98.859444, 68.829163],
            [-98.870003, 68.874153],
            [-98.811394, 68.915817],
            [-98.849442, 68.933594],
            [-98.98111, 68.950546],
            [-99.006958, 68.907761],
            [-99.118057, 68.921097],
            [-99.044159, 68.859711],
            [-99.188599, 68.824158],
            [-99.437767, 68.891098],
            [-99.460564, 68.950821],
            [-99.594162, 69.015823],
            [-99.57988, 69.04393],
          ],
        ],
        [
          [
            [-104.042198, 78.620682],
            [-103.826103, 78.671921],
            [-103.483597, 78.669144],
            [-103.518097, 78.710823],
            [-103.318901, 78.740257],
            [-103.468102, 78.787491],
            [-103.581398, 78.743591],
            [-103.616402, 78.767487],
            [-103.796097, 78.735809],
            [-103.695297, 78.793869],
            [-103.726898, 78.8022],
            [-103.886101, 78.804428],
            [-103.905296, 78.768883],
            [-103.991096, 78.758881],
            [-104.198898, 78.770264],
            [-104.215302, 78.793587],
            [-104.167198, 78.816383],
            [-103.821701, 78.898331],
            [-104.203598, 78.991653],
            [-104.265602, 78.988586],
            [-104.293098, 78.954987],
            [-104.455002, 78.9561],
            [-104.564201, 78.8647],
            [-104.785797, 78.806641],
            [-105.011902, 78.803589],
            [-105.012199, 78.844711],
            [-104.870796, 78.88916],
            [-104.681099, 79.016663],
            [-104.937202, 79.05304],
            [-105.395302, 79.011658],
            [-105.590797, 79.034416],
            [-105.628601, 79.161377],
            [-105.541702, 79.239967],
            [-105.420502, 79.284416],
            [-105.482803, 79.306374],
            [-105.440002, 79.329163],
            [-105.127197, 79.297493],
            [-104.007202, 79.367752],
            [-103.722504, 79.356934],
            [-102.8769, 79.233047],
            [-102.926697, 79.211113],
            [-102.891701, 79.166656],
            [-102.611702, 79.093048],
            [-102.665604, 78.982758],
            [-102.773598, 78.944138],
            [-102.583603, 78.948593],
            [-102.573097, 78.929703],
            [-102.626099, 78.900269],
            [-102.579498, 78.873032],
            [-102.391998, 78.931664],
            [-102.380501, 78.962769],
            [-102.417198, 79.009163],
            [-102.268097, 79.029709],
            [-101.9422, 79.084717],
            [-101.648903, 79.075821],
            [-101.231697, 78.959427],
            [-101.1408, 78.974152],
            [-100.986702, 78.937187],
            [-101.2006, 78.820831],
            [-101.186401, 78.802757],
            [-100.925598, 78.763321],
            [-100.351097, 78.828323],
            [-100.323303, 78.778053],
            [-99.896393, 78.695824],
            [-100.0625, 78.638893],
            [-100.016701, 78.616653],
            [-99.853333, 78.633041],
            [-99.748337, 78.597214],
            [-99.550278, 78.590271],
            [-99.529716, 78.578049],
            [-99.551941, 78.56192],
            [-99.651398, 78.544983],
            [-99.597778, 78.530548],
            [-99.670273, 78.479713],
            [-99.867767, 78.437477],
            [-99.748337, 78.383614],
            [-99.793877, 78.297211],
            [-99.529449, 78.282494],
            [-99.413887, 78.205261],
            [-98.945831, 78.061653],
            [-98.971657, 77.997757],
            [-99.099167, 77.960823],
            [-99.013344, 77.888321],
            [-99.179993, 77.84082],
            [-99.822243, 77.814148],
            [-99.906952, 77.778587],
            [-100.607498, 77.859993],
            [-100.604202, 77.890823],
            [-100.838303, 78.022774],
            [-100.747803, 78.077209],
            [-100.998596, 78.131653],
            [-101.035599, 78.196091],
            [-101.289398, 78.18248],
            [-101.474197, 78.234711],
            [-102.133102, 78.282761],
            [-102.618599, 78.241364],
            [-102.809402, 78.288307],
            [-102.807999, 78.31694],
            [-102.665802, 78.358597],
            [-102.777802, 78.376373],
            [-103.68, 78.31192],
            [-103.9058, 78.272774],
            [-103.898903, 78.245819],
            [-103.963097, 78.233597],
            [-104.467499, 78.265266],
            [-104.820602, 78.35582],
            [-104.991898, 78.437759],
            [-105.043098, 78.505829],
            [-104.831398, 78.569992],
            [-104.666702, 78.579712],
            [-103.523598, 78.496094],
            [-103.448898, 78.534714],
            [-103.494499, 78.566673],
            [-103.378098, 78.586113],
            [-103.431999, 78.598602],
            [-103.400002, 78.61554],
            [-104.042198, 78.620682],
          ],
        ],
        [
          [
            [-80.771667, 73.74971],
            [-80.373322, 73.761658],
            [-80.142227, 73.69664],
            [-80.023064, 73.727203],
            [-79.45195, 73.630539],
            [-78.166397, 73.668053],
            [-77.424438, 73.554703],
            [-77.19194, 73.501389],
            [-77.029449, 73.398041],
            [-77.048889, 73.361923],
            [-76.893341, 73.321114],
            [-76.708054, 73.317757],
            [-76.738602, 73.279984],
            [-76.579727, 73.219711],
            [-76.631668, 73.167213],
            [-76.495537, 73.116928],
            [-76.241096, 73.092209],
            [-76.318069, 73.062759],
            [-76.279449, 73.045822],
            [-76.323624, 73.020828],
            [-76.260277, 72.997208],
            [-76.333069, 72.963608],
            [-76.118607, 72.940262],
            [-76.059433, 72.900818],
            [-76.149986, 72.842209],
            [-76.5625, 72.812477],
            [-77.704178, 72.897217],
            [-78.236359, 72.893013],
            [-79.209167, 72.74498],
            [-79.594162, 72.753326],
            [-79.998611, 72.863037],
            [-80.181381, 73.043869],
            [-80.122772, 73.114433],
            [-80.178329, 73.158333],
            [-80.110001, 73.179703],
            [-80.151947, 73.222488],
            [-80.797501, 73.276932],
            [-80.876099, 73.327766],
            [-80.844162, 73.352478],
            [-80.892502, 73.374687],
            [-80.861938, 73.396378],
            [-80.884453, 73.439697],
            [-80.85556, 73.47554],
            [-80.695267, 73.477768],
            [-80.867218, 73.544708],
            [-80.902222, 73.602768],
            [-80.797501, 73.659988],
            [-80.80249, 73.714432],
            [-80.861717, 73.745178],
            [-80.771667, 73.74971],
          ],
        ],
        [
          [
            [-75, 68.11969],
            [-75.05249, 68.036652],
            [-75.164436, 67.954163],
            [-75.104446, 67.847488],
            [-75.044449, 67.812477],
            [-75.066391, 67.695534],
            [-75.025284, 67.619431],
            [-75.071953, 67.538879],
            [-75.388062, 67.354713],
            [-75.517227, 67.351646],
            [-75.59111, 67.306641],
            [-75.908051, 67.248596],
            [-76.242218, 67.262207],
            [-76.66362, 67.219994],
            [-77.044724, 67.260536],
            [-77.175003, 67.421097],
            [-77.246948, 67.451927],
            [-77.256393, 67.516388],
            [-77.22583, 67.543869],
            [-77.32251, 67.698029],
            [-77.220551, 67.861923],
            [-76.865547, 68.157761],
            [-76.606949, 68.279427],
            [-76.281952, 68.332764],
            [-76.235817, 68.303307],
            [-76.083328, 68.295258],
            [-75.917503, 68.338318],
            [-75.726097, 68.330276],
            [-75.134743, 68.234711],
            [-75, 68.11969],
          ],
        ],
        [
          [
            [-95.959473, 74.856369],
            [-96.127777, 74.915543],
            [-96.033333, 74.949417],
            [-96.050827, 74.972763],
            [-96.141678, 74.957207],
            [-96.056381, 75.010269],
            [-96.074173, 75.023613],
            [-96.142227, 75.017761],
            [-96.209732, 74.915543],
            [-96.268341, 74.90387],
            [-96.403061, 74.925812],
            [-96.37944, 74.943863],
            [-96.394173, 74.978317],
            [-96.328056, 74.974701],
            [-96.331123, 75.004707],
            [-96.614166, 74.984993],
            [-96.587509, 75.031662],
            [-96.604721, 75.063309],
            [-96.463058, 75.193314],
            [-96.380829, 75.175262],
            [-96.366386, 75.193314],
            [-96.399727, 75.204987],
            [-96.378601, 75.21666],
            [-96.04805, 75.238312],
            [-95.987778, 75.25],
            [-96.08139, 75.25],
            [-96.077789, 75.272491],
            [-95.903343, 75.289978],
            [-96.172501, 75.285538],
            [-95.944443, 75.378036],
            [-95.890556, 75.372208],
            [-95.919998, 75.346939],
            [-95.776947, 75.371918],
            [-96.033333, 75.401093],
            [-96.151108, 75.374977],
            [-96.178879, 75.38443],
            [-96.09584, 75.417763],
            [-95.958344, 75.436371],
            [-95.760834, 75.390823],
            [-95.684723, 75.428307],
            [-95.838058, 75.459152],
            [-95.749733, 75.513321],
            [-95.456673, 75.568329],
            [-95.371109, 75.552467],
            [-95.275284, 75.60582],
            [-95.178596, 75.584427],
            [-94.88266, 75.635788],
            [-94.363892, 75.59082],
            [-93.989723, 75.434982],
            [-93.676941, 75.356644],
            [-93.649727, 75.320831],
            [-93.801102, 75.303589],
            [-93.499161, 75.264709],
            [-93.578339, 75.197746],
            [-93.456673, 75.116379],
            [-93.509171, 75.091087],
            [-93.488892, 75.072487],
            [-93.642776, 75.038589],
            [-93.515289, 75.038307],
            [-93.42778, 74.955261],
            [-93.406387, 74.883614],
            [-93.438889, 74.757767],
            [-93.563606, 74.743042],
            [-93.463058, 74.729431],
            [-93.484734, 74.687759],
            [-93.741379, 74.635536],
            [-94.278061, 74.650818],
            [-94.643341, 74.623596],
            [-94.869453, 74.684982],
            [-94.91362, 74.662201],
            [-95.080002, 74.680817],
            [-95.06778, 74.736366],
            [-95.283333, 74.798027],
            [-95.443878, 74.769707],
            [-95.38028, 74.799713],
            [-95.457497, 74.798317],
            [-95.482773, 74.779427],
            [-95.450562, 74.741653],
            [-95.519173, 74.74054],
            [-95.547501, 74.756378],
            [-95.493057, 74.798866],
            [-95.518066, 74.811653],
            [-95.625816, 74.80748],
            [-95.725014, 74.843048],
            [-95.735001, 74.81749],
            [-95.907784, 74.819443],
            [-95.868881, 74.848038],
            [-95.959473, 74.856369],
          ],
        ],
        [
          [
            [-82.185822, 62.979988],
            [-81.911667, 62.952221],
            [-81.865547, 62.923321],
            [-81.90361, 62.852779],
            [-81.949722, 62.834991],
            [-81.938599, 62.709991],
            [-82.13945, 62.60638],
            [-82.369164, 62.547489],
            [-82.442207, 62.458599],
            [-83.001953, 62.204441],
            [-83.136398, 62.17305],
            [-83.198608, 62.22221],
            [-83.337784, 62.25222],
            [-83.703888, 62.141659],
            [-83.731667, 62.3036],
            [-83.945267, 62.427219],
            [-83.90834, 62.482769],
            [-83.704178, 62.569439],
            [-83.570007, 62.67527],
            [-83.527496, 62.82111],
            [-83.374161, 62.90694],
            [-83.21167, 62.913601],
            [-83.142502, 62.854439],
            [-83.041107, 62.837212],
            [-82.694443, 62.93943],
            [-82.420837, 62.924999],
            [-82.29277, 62.98333],
            [-82.185822, 62.979988],
          ],
        ],
        [
          [
            [-97.949432, 78.816383],
            [-97.488602, 78.796654],
            [-97.025284, 78.741928],
            [-96.886398, 78.69664],
            [-96.533623, 78.676933],
            [-96.315826, 78.618042],
            [-96.184433, 78.628593],
            [-96.152496, 78.611366],
            [-96.275009, 78.533333],
            [-95.964447, 78.483597],
            [-95.601936, 78.519707],
            [-94.894997, 78.395828],
            [-94.837509, 78.347214],
            [-95.39917, 78.231087],
            [-95.130829, 78.194138],
            [-95.089722, 78.154984],
            [-94.889999, 78.108871],
            [-94.929718, 78.08194],
            [-94.888062, 78.058868],
            [-95.112503, 77.951393],
            [-95.37999, 77.966377],
            [-96.285553, 77.859421],
            [-96.541107, 77.897217],
            [-96.735001, 77.866928],
            [-96.491669, 77.870247],
            [-96.570557, 77.851929],
            [-96.515556, 77.845543],
            [-96.71167, 77.839706],
            [-96.837784, 77.805252],
            [-96.817497, 77.791656],
            [-97.096947, 77.803307],
            [-97.081123, 77.843597],
            [-97.120003, 77.870247],
            [-96.995003, 77.921097],
            [-97.775558, 78.034988],
            [-97.651398, 78.051933],
            [-97.684433, 78.073883],
            [-97.647781, 78.09082],
            [-96.910278, 78.079163],
            [-96.85556, 78.104156],
            [-96.904449, 78.113037],
            [-96.870537, 78.133331],
            [-97.321121, 78.207489],
            [-97.926666, 78.217758],
            [-97.91362, 78.244141],
            [-97.763344, 78.244431],
            [-98.054718, 78.301651],
            [-98.010277, 78.333328],
            [-98.087509, 78.375809],
            [-98.036942, 78.397217],
            [-98.347778, 78.443039],
            [-98.410553, 78.490257],
            [-98.319458, 78.51915],
            [-98.345284, 78.534714],
            [-98.019173, 78.542763],
            [-98.32695, 78.650543],
            [-98.311394, 78.699707],
            [-98.371658, 78.719994],
            [-98.31778, 78.746643],
            [-98.364723, 78.768051],
            [-98.144447, 78.816673],
            [-97.949432, 78.816383],
          ],
        ],
        [
          [
            [-105.2258, 72.933037],
            [-105.356102, 72.947746],
            [-105.292503, 72.924698],
            [-105.324203, 72.906097],
            [-105.204201, 72.864151],
            [-105.383102, 72.866653],
            [-105.462502, 72.94136],
            [-105.903603, 73.130814],
            [-105.895798, 73.161102],
            [-106.101402, 73.189697],
            [-106.044403, 73.219437],
            [-106.193604, 73.284988],
            [-106.126099, 73.291367],
            [-106.466103, 73.401382],
            [-107.034203, 73.486366],
            [-106.645798, 73.699142],
            [-106.205597, 73.734154],
            [-105.148598, 73.754173],
            [-104.824203, 73.637207],
            [-104.580803, 73.600273],
            [-104.483101, 73.534416],
            [-104.5756, 73.453873],
            [-104.548599, 73.414703],
            [-104.586899, 73.37442],
            [-104.573303, 73.323036],
            [-104.760002, 73.203873],
            [-104.739403, 73.179977],
            [-104.975601, 73.085274],
            [-104.984703, 73.020538],
            [-105.2258, 72.933037],
          ],
        ],
        [
          [
            [-79.541321, 61.799789],
            [-79.60527, 61.76527],
            [-79.656952, 61.64249],
            [-79.761398, 61.576939],
            [-79.846123, 61.569988],
            [-79.959442, 61.68721],
            [-80.275284, 61.80666],
            [-80.292221, 61.873051],
            [-80.257507, 61.883881],
            [-80.295273, 61.983601],
            [-80.268623, 62.10722],
            [-80.180283, 62.217491],
            [-79.94722, 62.386108],
            [-79.714447, 62.39777],
            [-79.700562, 62.369991],
            [-79.561943, 62.41721],
            [-79.442757, 62.37999],
            [-79.359161, 62.296101],
            [-79.260834, 62.253609],
            [-79.261398, 62.163601],
            [-79.333618, 62.05471],
            [-79.315826, 61.98888],
            [-79.381104, 62.002781],
            [-79.541321, 61.799789],
          ],
        ],
        [
          [
            [-81.087784, 53.17944],
            [-80.763344, 52.931381],
            [-80.66777, 52.771931],
            [-80.712509, 52.689159],
            [-81.024437, 52.750549],
            [-81.251953, 52.8325],
            [-81.916656, 52.961658],
            [-82.063316, 53.026661],
            [-81.965286, 53.122219],
            [-81.848053, 53.18166],
            [-81.375, 53.224991],
            [-81.087784, 53.17944],
          ],
        ],
        [
          [
            [-83.921387, 66.00972],
            [-83.683319, 65.910538],
            [-83.699722, 65.851379],
            [-83.796951, 65.811371],
            [-83.525009, 65.737762],
            [-83.529716, 65.708328],
            [-83.360001, 65.727478],
            [-83.210831, 65.705833],
            [-83.263634, 65.688309],
            [-83.247498, 65.659714],
            [-83.288887, 65.632751],
            [-83.379173, 65.61554],
            [-83.468613, 65.654984],
            [-83.623611, 65.661377],
            [-83.58667, 65.703873],
            [-83.662514, 65.676376],
            [-83.62471, 65.646652],
            [-83.760559, 65.670258],
            [-83.842773, 65.649147],
            [-83.785828, 65.724701],
            [-83.684158, 65.744431],
            [-83.827499, 65.748322],
            [-83.785278, 65.788879],
            [-83.960007, 65.737762],
            [-84.143623, 65.76416],
            [-84.101097, 65.819717],
            [-84.130829, 65.909714],
            [-84.193329, 65.929153],
            [-84.208618, 65.977203],
            [-84.382767, 66.000549],
            [-84.468063, 66.064987],
            [-84.470284, 66.133331],
            [-84.096657, 66.056931],
            [-84.008347, 66.077209],
            [-84.000557, 66.02887],
            [-83.921387, 66.00972],
          ],
        ],
        [
          [
            [-93.174713, 77.704163],
            [-93.101936, 77.662491],
            [-93.37944, 77.630814],
            [-93.405563, 77.609154],
            [-93.37999, 77.583878],
            [-93.486389, 77.545532],
            [-93.502502, 77.503052],
            [-93.475281, 77.471367],
            [-93.570557, 77.437759],
            [-94.878326, 77.484993],
            [-95.864166, 77.462196],
            [-96.083328, 77.497757],
            [-96.328888, 77.60498],
            [-96.269173, 77.614151],
            [-96.289169, 77.650543],
            [-96.245003, 77.662491],
            [-96.241096, 77.694977],
            [-95.465286, 77.808029],
            [-95.363892, 77.737198],
            [-95.30278, 77.763893],
            [-95.243057, 77.742752],
            [-94.752228, 77.788589],
            [-94.343887, 77.753883],
            [-94.042503, 77.766098],
            [-93.931381, 77.732483],
            [-93.656952, 77.776657],
            [-93.174713, 77.704163],
          ],
        ],
        [
          [
            [-73.655472, 68.007713],
            [-73.411667, 67.974991],
            [-73.430283, 67.914146],
            [-73.405563, 67.856934],
            [-73.348618, 67.828049],
            [-73.449432, 67.762497],
            [-74.48111, 67.789429],
            [-74.684433, 67.881927],
            [-74.772232, 67.963318],
            [-74.778061, 68.006104],
            [-74.731949, 68.070831],
            [-74.456123, 68.067207],
            [-74.339447, 68.1772],
            [-74.212509, 68.130539],
            [-74.266403, 68.059418],
            [-73.974716, 68.041092],
            [-73.794159, 67.988586],
            [-73.655472, 68.007713],
          ],
        ],
        [
          [
            [-105.648903, 77.748596],
            [-105.027802, 77.546371],
            [-104.945, 77.484993],
            [-105.0103, 77.458603],
            [-105.0103, 77.408043],
            [-104.741096, 77.414429],
            [-104.706703, 77.377762],
            [-104.425003, 77.309708],
            [-104.472504, 77.284149],
            [-104.395599, 77.276382],
            [-104.3675, 77.224426],
            [-104.472504, 77.137497],
            [-104.740303, 77.108597],
            [-105.044403, 77.171371],
            [-105.226402, 77.161102],
            [-105.261101, 77.171654],
            [-105.248596, 77.205551],
            [-105.396103, 77.213043],
            [-105.323898, 77.23027],
            [-105.550301, 77.311653],
            [-105.573097, 77.329437],
            [-105.544701, 77.367477],
            [-105.657501, 77.380257],
            [-105.631897, 77.406937],
            [-105.689697, 77.41748],
            [-105.655602, 77.441933],
            [-105.764999, 77.463882],
            [-105.687202, 77.515549],
            [-105.856903, 77.54332],
            [-105.8181, 77.55748],
            [-105.834396, 77.61026],
            [-106.091698, 77.715271],
            [-105.913902, 77.762497],
            [-105.648903, 77.748596],
          ],
        ],
        [
          [
            [-78.212784, 63.496101],
            [-77.845001, 63.47221],
            [-77.81778, 63.454441],
            [-77.844452, 63.437771],
            [-77.80722, 63.425831],
            [-77.680557, 63.434429],
            [-77.636673, 63.402771],
            [-77.635559, 63.358891],
            [-77.495003, 63.265831],
            [-77.570282, 63.206661],
            [-77.560272, 63.18277],
            [-77.958344, 63.093048],
            [-78.5625, 63.395828],
            [-78.572777, 63.440269],
            [-78.212784, 63.496101],
          ],
        ],
        [
          [
            [-78.458618, 69.389977],
            [-78.279716, 69.370247],
            [-78.214447, 69.288307],
            [-78.356377, 69.196091],
            [-78.467216, 69.219711],
            [-78.476097, 69.161377],
            [-78.556381, 69.137497],
            [-78.556664, 69.083603],
            [-78.715012, 69.00972],
            [-78.725281, 68.968872],
            [-78.867493, 68.900543],
            [-79.216949, 68.829987],
            [-79.400284, 68.871918],
            [-79.388062, 68.906647],
            [-79.419159, 68.920258],
            [-79.309433, 68.973602],
            [-79.227219, 69.076393],
            [-78.960281, 69.102478],
            [-78.864166, 69.141098],
            [-78.858887, 69.189972],
            [-78.749161, 69.261108],
            [-78.619453, 69.261658],
            [-78.721939, 69.310532],
            [-78.71611, 69.340271],
            [-78.458618, 69.389977],
          ],
        ],
        [
          [
            [-79.768066, 69.752777],
            [-79.68721, 69.814697],
            [-79.476936, 69.803589],
            [-79.329453, 69.701393],
            [-79.631668, 69.608871],
            [-79.91806, 69.603592],
            [-80.059433, 69.643883],
            [-80.078888, 69.622208],
            [-79.935547, 69.527206],
            [-80.011948, 69.491653],
            [-80.229446, 69.531097],
            [-80.219162, 69.625259],
            [-80.325012, 69.554977],
            [-80.353607, 69.6147],
            [-80.492767, 69.664993],
            [-80.809433, 69.683037],
            [-80.725014, 69.749153],
            [-80.649727, 69.748596],
            [-80.462784, 69.712769],
            [-80.400833, 69.666092],
            [-80.419159, 69.699142],
            [-80.375267, 69.709717],
            [-80.448608, 69.723877],
            [-80.504463, 69.774986],
            [-80.388901, 69.799988],
            [-80.329727, 69.774147],
            [-80.18277, 69.792763],
            [-80.120537, 69.758614],
            [-80.146118, 69.720833],
            [-80.085007, 69.749153],
            [-79.964172, 69.720833],
            [-79.768066, 69.752777],
          ],
        ],
        [
          [
            [-90, 77.547783],
            [-89.719162, 77.458328],
            [-89.711121, 77.365257],
            [-89.636124, 77.339157],
            [-90, 77.213814],
            [-90.39473, 77.199142],
            [-90.419159, 77.208038],
            [-90.387512, 77.227203],
            [-91.142227, 77.360527],
            [-91.106377, 77.374687],
            [-91.208893, 77.414993],
            [-91.170273, 77.449707],
            [-91.205566, 77.518883],
            [-91.184433, 77.608597],
            [-90.906387, 77.653053],
            [-90.339172, 77.623871],
            [-90, 77.547783],
          ],
        ],
        [
          [
            [-97.65361, 74.099991],
            [-97.649437, 74.052467],
            [-97.763634, 73.988312],
            [-98.010559, 73.937477],
            [-98.144997, 73.873596],
            [-98.392776, 73.845261],
            [-98.652222, 73.834427],
            [-98.715286, 73.861366],
            [-98.754181, 73.837486],
            [-98.72084, 73.82222],
            [-98.74028, 73.813309],
            [-98.96167, 73.805252],
            [-99.166397, 73.832489],
            [-98.823624, 73.824432],
            [-98.847778, 73.849152],
            [-99.25, 73.852768],
            [-99.4375, 73.896942],
            [-99.281952, 73.938026],
            [-98.972778, 73.951103],
            [-99.013901, 73.984154],
            [-98.765289, 74.031097],
            [-98.491943, 74.034149],
            [-97.994453, 74.109421],
            [-97.65361, 74.099991],
          ],
        ],
        [
          [
            [-104.151802, 75.434563],
            [-103.803299, 75.36026],
            [-103.814201, 75.330833],
            [-103.733902, 75.274147],
            [-103.749702, 75.234711],
            [-103.583099, 75.164703],
            [-103.730301, 75.099991],
            [-104.261101, 75.018333],
            [-104.728104, 75.070267],
            [-104.930801, 75.132202],
            [-104.688004, 75.25972],
            [-104.770302, 75.282494],
            [-104.673599, 75.34166],
            [-104.428001, 75.420822],
            [-104.151802, 75.434563],
          ],
        ],
        [
          [
            [-86.434998, 68.162491],
            [-86.426392, 68.069153],
            [-86.368607, 67.954712],
            [-86.396118, 67.859711],
            [-86.571953, 67.728867],
            [-86.690552, 67.733871],
            [-86.858337, 67.796944],
            [-86.945831, 67.909416],
            [-86.940552, 67.934418],
            [-86.843338, 67.958603],
            [-86.836937, 68.001099],
            [-86.988327, 68.081673],
            [-86.897781, 68.136108],
            [-86.906387, 68.180267],
            [-86.711937, 68.299149],
            [-86.458618, 68.235527],
            [-86.402786, 68.194427],
            [-86.434998, 68.162491],
          ],
        ],
        [
          [
            [-70.587784, 62.774158],
            [-70.396957, 62.723049],
            [-70.422783, 62.709431],
            [-70.371933, 62.687771],
            [-70.413063, 62.650269],
            [-70.3125, 62.65416],
            [-70.211937, 62.57777],
            [-70.393066, 62.53027],
            [-70.723892, 62.55027],
            [-70.774719, 62.569721],
            [-70.74028, 62.592491],
            [-70.819733, 62.60471],
            [-70.854446, 62.713612],
            [-70.920273, 62.740551],
            [-70.771118, 62.76749],
            [-71.019173, 62.811939],
            [-71.15834, 62.797218],
            [-71.166397, 62.81805],
            [-71.032784, 62.822769],
            [-71.241379, 62.881378],
            [-70.788063, 62.836109],
            [-70.587784, 62.774158],
          ],
        ],
        [
          [
            [-104.134201, 76.669434],
            [-103.922501, 76.633614],
            [-104.053902, 76.563026],
            [-103.878899, 76.573608],
            [-103.859398, 76.607208],
            [-103.787498, 76.620529],
            [-103.559402, 76.563873],
            [-103.587799, 76.524986],
            [-103.248596, 76.499153],
            [-103.245003, 76.474991],
            [-103.004501, 76.429977],
            [-103.204697, 76.354713],
            [-103.507004, 76.310806],
            [-104.378899, 76.323318],
            [-104.404404, 76.334427],
            [-104.329697, 76.358032],
            [-104.351097, 76.383614],
            [-104.4608, 76.390266],
            [-104.391998, 76.421654],
            [-104.391098, 76.461113],
            [-104.448898, 76.491089],
            [-104.563904, 76.482208],
            [-104.589699, 76.508881],
            [-104.560799, 76.523041],
            [-104.658897, 76.545822],
            [-104.638603, 76.574707],
            [-104.6828, 76.597488],
            [-104.134201, 76.669434],
          ],
        ],
        [
          [
            [-84.910278, 66],
            [-84.860283, 65.968323],
            [-84.883331, 65.940811],
            [-84.752502, 65.878036],
            [-84.638062, 65.707207],
            [-84.58667, 65.6922],
            [-84.604446, 65.671921],
            [-84.576111, 65.62915],
            [-84.66777, 65.560532],
            [-84.784729, 65.556641],
            [-84.840843, 65.588043],
            [-84.878601, 65.669144],
            [-85.118057, 65.764709],
            [-85.143066, 65.913307],
            [-85.181953, 65.945534],
            [-85.17305, 65.994713],
            [-85.08168, 66.026657],
            [-84.910278, 66],
          ],
        ],
        [
          [
            [-64.659729, 61.588039],
            [-64.726097, 61.542221],
            [-64.683319, 61.531109],
            [-64.71666, 61.511669],
            [-64.675278, 61.50861],
            [-64.686943, 61.465832],
            [-64.801392, 61.412209],
            [-64.802223, 61.372761],
            [-64.875267, 61.322491],
            [-64.91333, 61.355],
            [-64.977493, 61.347771],
            [-64.980827, 61.376099],
            [-64.932503, 61.403599],
            [-65.060272, 61.407211],
            [-65.119453, 61.43277],
            [-65.090843, 61.454441],
            [-65.151672, 61.453049],
            [-65.195267, 61.499161],
            [-65.372498, 61.53722],
            [-65.487778, 61.59943],
            [-65.435822, 61.658039],
            [-65.036392, 61.6936],
            [-64.719162, 61.658039],
            [-64.791382, 61.617489],
            [-64.725563, 61.6311],
            [-64.734161, 61.610821],
            [-64.659157, 61.609989],
            [-64.659729, 61.588039],
          ],
        ],
        [
          [
            [-98.830002, 79.664429],
            [-98.936111, 79.719711],
            [-99.31723, 79.758614],
            [-99.30278, 79.787491],
            [-99.333618, 79.80304],
            [-99.296387, 79.839157],
            [-99.368881, 79.857758],
            [-99.614166, 79.893333],
            [-100.121101, 79.886658],
            [-100.177498, 79.909988],
            [-100.127502, 79.961929],
            [-100.193298, 80.033867],
            [-100.065598, 80.089981],
            [-99.759171, 80.149719],
            [-99.436661, 80.107208],
            [-99.136673, 80.133041],
            [-98.868881, 80.077766],
            [-98.871933, 80.044434],
            [-98.705841, 79.96582],
            [-98.779167, 79.902771],
            [-98.644173, 79.800262],
            [-98.830002, 79.664429],
          ],
        ],
        [
          [
            [-102.652199, 76.287773],
            [-102.5308, 76.223312],
            [-102.5839, 76.183594],
            [-102.529198, 76.153587],
            [-102.621399, 76.152481],
            [-102.650597, 76.11998],
            [-102.806396, 76.085541],
            [-103.342201, 76.036652],
            [-103.986099, 76.034988],
            [-103.888603, 76.049713],
            [-104.391098, 76.078323],
            [-104.482803, 76.142212],
            [-104.297997, 76.212486],
            [-103.876404, 76.248596],
            [-103.059998, 76.306374],
            [-102.652199, 76.287773],
          ],
        ],
        [
          [
            [-76.68161, 63.48135],
            [-76.541946, 63.46249],
            [-76.650284, 63.431381],
            [-76.631668, 63.412491],
            [-76.692207, 63.367771],
            [-76.973892, 63.403599],
            [-77.243332, 63.54361],
            [-77.42778, 63.589161],
            [-77.400833, 63.598331],
            [-77.439713, 63.618599],
            [-77.333893, 63.626942],
            [-77.45723, 63.64333],
            [-77.343338, 63.696098],
            [-77.116943, 63.65416],
            [-77.121933, 63.679722],
            [-77.061394, 63.672771],
            [-76.616096, 63.523319],
            [-76.68161, 63.48135],
          ],
        ],
        [
          [
            [-79.057503, 54.91777],
            [-79.776947, 54.778049],
            [-79.681953, 54.810268],
            [-79.728058, 54.812771],
            [-79.686943, 54.838329],
            [-79.467216, 54.888329],
            [-79.053879, 54.946659],
            [-79.015007, 54.932209],
            [-79.057503, 54.91777],
          ],
        ],
        [
          [
            [-64.325562, 63.637501],
            [-64.275284, 63.496101],
            [-64.306664, 63.483051],
            [-64.252502, 63.44054],
            [-64.286942, 63.430271],
            [-64.087509, 63.32972],
            [-64.061111, 63.27055],
            [-64.166656, 63.32333],
            [-64.181953, 63.29694],
            [-64.266953, 63.326389],
            [-64.421944, 63.471661],
            [-64.389183, 63.48444],
            [-64.490547, 63.620541],
            [-64.377213, 63.67749],
            [-64.325562, 63.637501],
          ],
        ],
        [
          [
            [-75, 68.672241],
            [-74.938316, 68.571663],
            [-74.768341, 68.473312],
            [-74.906662, 68.423027],
            [-74.776398, 68.410538],
            [-74.863892, 68.3797],
            [-74.816101, 68.369713],
            [-74.81221, 68.338043],
            [-74.855827, 68.34137],
            [-74.811394, 68.320541],
            [-75.002228, 68.333603],
            [-75.037514, 68.394707],
            [-75.241379, 68.436371],
            [-75.416397, 68.518051],
            [-75.398354, 68.610527],
            [-75.280563, 68.709717],
            [-75, 68.672241],
          ],
        ],
        [
          [
            [-78.141953, 69.742477],
            [-77.982773, 69.694702],
            [-77.946663, 69.646652],
            [-78.087784, 69.576927],
            [-78.518623, 69.480553],
            [-78.646118, 69.509987],
            [-78.817497, 69.452766],
            [-78.88028, 69.476929],
            [-78.575844, 69.636383],
            [-78.400284, 69.643333],
            [-78.37999, 69.698593],
            [-78.306953, 69.686653],
            [-78.315552, 69.659714],
            [-78.24527, 69.663597],
            [-78.228058, 69.677467],
            [-78.268623, 69.732208],
            [-78.141953, 69.742477],
          ],
        ],
        [
          [
            [-90, 76.837822],
            [-89.825844, 76.806091],
            [-89.673889, 76.737488],
            [-89.671661, 76.698593],
            [-89.837219, 76.66304],
            [-89.862778, 76.603867],
            [-89.794449, 76.533333],
            [-89.671387, 76.504173],
            [-89.808609, 76.482483],
            [-89.943329, 76.506378],
            [-89.934433, 76.476646],
            [-90, 76.470306],
            [-90.184433, 76.515266],
            [-90.600281, 76.734993],
            [-90.474716, 76.799713],
            [-90, 76.837822],
          ],
        ],
        [
          [
            [-77.113617, 69.44165],
            [-76.923607, 69.3936],
            [-76.7164, 69.422211],
            [-76.64473, 69.381363],
            [-76.646667, 69.336929],
            [-76.939987, 69.209152],
            [-76.944443, 69.185257],
            [-76.858612, 69.14888],
            [-76.922501, 69.119713],
            [-76.897232, 69.103867],
            [-76.958893, 69.142487],
            [-77.213623, 69.125809],
            [-77.301392, 69.164146],
            [-77.381943, 69.247482],
            [-77.335007, 69.333878],
            [-77.359734, 69.392761],
            [-77.113617, 69.44165],
          ],
        ],
        [
          [
            [-96.025436, 75.602837],
            [-95.911118, 75.554153],
            [-96.048889, 75.525269],
            [-96.170837, 75.458038],
            [-96.417221, 75.523323],
            [-96.360001, 75.568878],
            [-96.410278, 75.580276],
            [-96.549156, 75.519707],
            [-96.511948, 75.455833],
            [-96.703613, 75.423027],
            [-96.631104, 75.393051],
            [-96.851097, 75.350273],
            [-96.880547, 75.379967],
            [-96.979446, 75.386383],
            [-96.941383, 75.407761],
            [-97.053047, 75.492203],
            [-96.419159, 75.588593],
            [-96.424438, 75.635536],
            [-96.396393, 75.649986],
            [-96.025436, 75.602837],
          ],
        ],
        [
          [
            [-101.988602, 75.934418],
            [-102.394203, 75.880814],
            [-102.496902, 75.795532],
            [-102.611702, 75.767212],
            [-103.137802, 75.742752],
            [-103.382797, 75.765549],
            [-103.246902, 75.797211],
            [-103.297798, 75.809982],
            [-103.051399, 75.83194],
            [-103.146698, 75.854431],
            [-103.059402, 75.896378],
            [-102.696098, 75.94664],
            [-102.164703, 75.99054],
            [-101.988602, 75.934418],
          ],
        ],
        [
          [
            [-67.948608, 60.561378],
            [-67.798607, 60.4575],
            [-67.853333, 60.375271],
            [-68.177223, 60.24305],
            [-68.387222, 60.240829],
            [-68.377777, 60.310268],
            [-68.12944, 60.570549],
            [-67.948608, 60.561378],
          ],
        ],
        [
          [
            [-78.893066, 76.11554],
            [-78.79805, 76.08638],
            [-78.833893, 76.052467],
            [-79.176941, 75.946373],
            [-78.87944, 75.844147],
            [-79.319458, 75.873596],
            [-79.420273, 75.848038],
            [-79.341377, 75.824707],
            [-79.443878, 75.791931],
            [-79.592773, 75.817207],
            [-79.566963, 75.845833],
            [-79.598053, 75.861366],
            [-79.752228, 75.878593],
            [-79.596947, 75.902481],
            [-79.580002, 75.945251],
            [-79.307503, 76.013321],
            [-79.091377, 76.114433],
            [-78.893066, 76.11554],
          ],
        ],
        [
          [
            [-101.671898, 77.893333],
            [-101.191399, 77.830833],
            [-100.960602, 77.759163],
            [-100.926399, 77.743317],
            [-100.940598, 77.726929],
            [-101.118301, 77.714157],
            [-101.507004, 77.724991],
            [-101.798897, 77.676376],
            [-102.067802, 77.682213],
            [-102.444397, 77.731934],
            [-102.413597, 77.753326],
            [-102.504501, 77.779984],
            [-102.493896, 77.81192],
            [-102.529701, 77.834152],
            [-102.387802, 77.884163],
            [-101.671898, 77.893333],
          ],
        ],
        [
          [
            [-95.488892, 69.565536],
            [-95.359734, 69.509163],
            [-95.402496, 69.383331],
            [-95.515839, 69.330833],
            [-95.736938, 69.324432],
            [-95.713058, 69.344711],
            [-95.727783, 69.378593],
            [-95.648354, 69.40332],
            [-95.689987, 69.464706],
            [-95.669159, 69.507492],
            [-95.708054, 69.548866],
            [-95.815826, 69.562759],
            [-95.822777, 69.514427],
            [-95.781113, 69.463043],
            [-95.866943, 69.383041],
            [-95.862213, 69.348038],
            [-95.990829, 69.353317],
            [-95.993057, 69.388603],
            [-95.945831, 69.413879],
            [-96.009743, 69.483047],
            [-95.952499, 69.510536],
            [-95.919998, 69.595261],
            [-95.789436, 69.634163],
            [-95.612213, 69.614433],
            [-95.488892, 69.565536],
          ],
        ],
        [
          [
            [-71.794724, 71.05304],
            [-71.339722, 70.991364],
            [-71.415558, 70.907761],
            [-71.593613, 70.90387],
            [-71.914436, 70.848877],
            [-71.955566, 70.818329],
            [-72.112213, 70.811371],
            [-72.118057, 70.85054],
            [-72.19194, 70.877762],
            [-72.226387, 70.930542],
            [-72.05777, 70.933037],
            [-72.008621, 71.049713],
            [-71.851669, 71.07222],
            [-71.794724, 71.05304],
          ],
        ],
        [
          [
            [-102.353302, 76.073883],
            [-102.316399, 76.036926],
            [-102.365799, 76.005829],
            [-103.339401, 75.908043],
            [-103.699699, 75.887497],
            [-103.901901, 75.898613],
            [-103.971703, 75.938309],
            [-102.868301, 76.06694],
            [-102.353302, 76.073883],
          ],
        ],
        [
          [
            [-101.693901, 68.768051],
            [-101.682503, 68.661652],
            [-101.701698, 68.637772],
            [-101.824699, 68.649719],
            [-101.795303, 68.623596],
            [-101.8311, 68.56694],
            [-102.349403, 68.683037],
            [-102.092499, 68.748871],
            [-101.998299, 68.823036],
            [-101.693901, 68.768051],
          ],
        ],
        [
          [
            [-100.323601, 68.996094],
            [-100.238899, 68.924149],
            [-100.126404, 68.905548],
            [-100.205002, 68.846649],
            [-100.175598, 68.794708],
            [-100.2211, 68.799713],
            [-100.220802, 68.764427],
            [-100.299202, 68.773323],
            [-100.366898, 68.710541],
            [-100.4869, 68.753052],
            [-100.446701, 68.774429],
            [-100.468903, 68.797211],
            [-100.571999, 68.75444],
            [-100.628098, 68.766388],
            [-100.625801, 68.912491],
            [-100.573601, 68.936653],
            [-100.624199, 68.996094],
            [-100.543602, 69.036652],
            [-100.323601, 68.996094],
          ],
        ],
        [
          [
            [-100.297203, 76.721916],
            [-101.273903, 76.560806],
            [-101.688301, 76.58638],
            [-100.7164, 76.756378],
            [-100.297203, 76.721916],
          ],
        ],
        [
          [
            [-87.26561, 70.113564],
            [-87.102783, 70.120819],
            [-87.145279, 70.142761],
            [-87.091377, 70.150269],
            [-86.798889, 70.087196],
            [-86.639717, 70.116653],
            [-86.456673, 70.007492],
            [-86.662216, 69.967484],
            [-87.002228, 70.014999],
            [-87.104172, 69.987762],
            [-87.376938, 70.093323],
            [-87.26561, 70.113564],
          ],
        ],
        [
          [
            [-96.136398, 69.546097],
            [-96.096123, 69.468048],
            [-96.148064, 69.421921],
            [-96.125816, 69.393883],
            [-96.146667, 69.345833],
            [-96.269997, 69.370819],
            [-96.401108, 69.453049],
            [-96.548889, 69.474991],
            [-96.736656, 69.57666],
            [-96.373611, 69.560806],
            [-96.316391, 69.526382],
            [-96.184433, 69.567207],
            [-96.136398, 69.546097],
          ],
        ],
        [
          [
            [-79.297234, 52.09193],
            [-79.252792, 52.071381],
            [-79.322243, 52.031658],
            [-79.319733, 51.96999],
            [-79.376663, 51.9361],
            [-79.611656, 51.91444],
            [-79.652786, 51.981098],
            [-79.623322, 52.025269],
            [-79.53833, 52.023319],
            [-79.338333, 52.110279],
            [-79.297234, 52.09193],
          ],
        ],
        [
          [
            [-64.382767, 62.525829],
            [-64.363892, 62.495831],
            [-64.481667, 62.481659],
            [-64.394173, 62.46138],
            [-64.504997, 62.443321],
            [-64.477219, 62.408039],
            [-64.590561, 62.36721],
            [-64.926666, 62.418331],
            [-64.965843, 62.465832],
            [-64.79306, 62.491379],
            [-64.862213, 62.520828],
            [-64.815552, 62.559719],
            [-64.682503, 62.53249],
            [-64.549728, 62.536381],
            [-64.549728, 62.56221],
            [-64.466949, 62.544159],
            [-64.463333, 62.51416],
            [-64.382767, 62.525829],
          ],
        ],
        [
          [
            [-64.549438, 63.895271],
            [-64.604172, 63.838879],
            [-64.506119, 63.838039],
            [-64.608612, 63.783051],
            [-64.498337, 63.7411],
            [-64.426102, 63.777771],
            [-64.386124, 63.70166],
            [-64.436394, 63.673321],
            [-64.694717, 63.784439],
            [-64.802223, 63.764439],
            [-64.916397, 63.806381],
            [-64.910278, 63.837212],
            [-64.811661, 63.877209],
            [-64.748047, 63.828049],
            [-64.777222, 63.86832],
            [-64.732224, 63.86805],
            [-64.710564, 63.908878],
            [-64.636948, 63.914711],
            [-64.628601, 63.881378],
            [-64.549438, 63.895271],
          ],
        ],
        [
          [
            [-73.060547, 71.294708],
            [-73.092773, 71.308868],
            [-73.065552, 71.324997],
            [-73.272507, 71.361923],
            [-73.25473, 71.415268],
            [-73.374161, 71.485809],
            [-73.37999, 71.519707],
            [-73.189987, 71.565536],
            [-73.081123, 71.542213],
            [-73.179718, 71.450546],
            [-73.15834, 71.41832],
            [-72.985283, 71.521378],
            [-72.827499, 71.454987],
            [-73.031113, 71.406647],
            [-73.017227, 71.342758],
            [-72.971939, 71.31749],
            [-73.060547, 71.294708],
          ],
        ],
        [
          [
            [-85.924438, 79.053864],
            [-85.321404, 79.053864],
            [-85.167221, 79.020828],
            [-85.301392, 78.975273],
            [-86.388062, 78.883041],
            [-86.484444, 78.892761],
            [-86.346123, 78.939697],
            [-86.29277, 79.012207],
            [-85.924438, 79.053864],
          ],
        ],
        [
          [
            [-104.682503, 68.573883],
            [-104.440598, 68.476089],
            [-104.441399, 68.417213],
            [-104.545303, 68.396103],
            [-104.919197, 68.459991],
            [-105.081703, 68.546371],
            [-104.938599, 68.583328],
            [-104.682503, 68.573883],
          ],
        ],
        [
          [
            [-96.771942, 73.181664],
            [-96.72583, 73.159149],
            [-96.771118, 73.113602],
            [-96.650284, 73.139977],
            [-96.568619, 73.062187],
            [-96.65834, 72.954163],
            [-96.955276, 72.920532],
            [-97.089722, 72.981659],
            [-97.139717, 73.051933],
            [-97.141113, 73.085541],
            [-97.045547, 73.147491],
            [-96.771942, 73.181664],
          ],
        ],
        [
          [
            [-65.068069, 61.923882],
            [-65.016403, 61.851662],
            [-64.964722, 61.889992],
            [-64.953888, 61.820549],
            [-64.893341, 61.82999],
            [-64.828888, 61.75222],
            [-64.95195, 61.722488],
            [-65.210564, 61.81694],
            [-65.251953, 61.869709],
            [-65.24527, 61.914711],
            [-65.170273, 61.947769],
            [-65.068069, 61.923882],
          ],
        ],
        [
          [
            [-65.266403, 64.693314],
            [-65.308884, 64.660263],
            [-65.25, 64.663597],
            [-65.213898, 64.626083],
            [-65.428596, 64.591087],
            [-65.363052, 64.579987],
            [-65.397232, 64.556374],
            [-65.539436, 64.546944],
            [-65.460564, 64.521103],
            [-65.689713, 64.519989],
            [-65.660278, 64.573883],
            [-65.449158, 64.678864],
            [-65.414169, 64.733597],
            [-65.380547, 64.733871],
            [-65.401672, 64.646103],
            [-65.296951, 64.734154],
            [-65.266403, 64.693314],
          ],
        ],
        [
          [
            [-100.461098, 70.659988],
            [-100.1922, 70.577209],
            [-100.3881, 70.578873],
            [-100.296997, 70.540817],
            [-100.224403, 70.45665],
            [-100.676399, 70.563309],
            [-100.661697, 70.633041],
            [-100.702202, 70.682747],
            [-100.461098, 70.659988],
          ],
        ],
        [
          [
            [-78.795273, 68.438583],
            [-78.97084, 68.471367],
            [-78.943329, 68.511932],
            [-78.723892, 68.547493],
            [-78.66861, 68.583328],
            [-78.898064, 68.64888],
            [-78.720001, 68.657211],
            [-78.460564, 68.617203],
            [-78.482224, 68.553307],
            [-78.668877, 68.554153],
            [-78.724716, 68.521927],
            [-78.610817, 68.498032],
            [-78.795273, 68.438583],
          ],
        ],
        [
          [
            [-94.481949, 75.974426],
            [-94.420273, 75.868591],
            [-94.287781, 75.783867],
            [-94.353882, 75.753883],
            [-94.777496, 75.7686],
            [-94.825844, 75.800812],
            [-94.829453, 75.882751],
            [-94.906113, 75.930817],
            [-94.523903, 75.992477],
            [-94.481949, 75.974426],
          ],
        ],
        [
          [
            [-78.226669, 60.80888],
            [-78.397232, 60.743881],
            [-78.656387, 60.70277],
            [-78.698334, 60.721661],
            [-78.573624, 60.784161],
            [-78.223892, 60.83083],
            [-78.226669, 60.80888],
          ],
        ],
        [
          [
            [-73.669449, 64.4272],
            [-73.577217, 64.4422],
            [-73.611938, 64.475273],
            [-73.577499, 64.489433],
            [-73.681953, 64.507492],
            [-73.67778, 64.532211],
            [-73.509171, 64.552467],
            [-73.529167, 64.498596],
            [-73.488327, 64.443863],
            [-73.549988, 64.403053],
            [-73.547234, 64.367477],
            [-73.515839, 64.365257],
            [-73.579727, 64.335823],
            [-73.557503, 64.312759],
            [-73.655563, 64.320267],
            [-73.669449, 64.4272],
          ],
        ],
        [
          [
            [-101.053001, 69.50444],
            [-100.970299, 69.455551],
            [-101.063004, 69.4561],
            [-101.099197, 69.401932],
            [-101.194397, 69.401932],
            [-101.230301, 69.368591],
            [-101.279198, 69.382477],
            [-101.231697, 69.400818],
            [-101.245003, 69.424988],
            [-101.300301, 69.4422],
            [-101.228897, 69.434708],
            [-101.186401, 69.475273],
            [-101.387199, 69.537773],
            [-101.349701, 69.572487],
            [-101.261703, 69.581673],
            [-101.243103, 69.54248],
            [-101.1744, 69.565811],
            [-101.053001, 69.50444],
          ],
        ],
        [
          [
            [-74.480827, 62.740269],
            [-74.374161, 62.681381],
            [-74.015839, 62.664989],
            [-73.958054, 62.612499],
            [-74.169159, 62.602219],
            [-74.541382, 62.668331],
            [-74.651108, 62.71777],
            [-74.480827, 62.740269],
          ],
        ],
        [
          [
            [-74.340843, 68.462486],
            [-74.26973, 68.454712],
            [-74.074722, 68.330833],
            [-74.149727, 68.25],
            [-74.228882, 68.250816],
            [-74.278343, 68.294983],
            [-74.213333, 68.319443],
            [-74.388611, 68.398331],
            [-74.393066, 68.445251],
            [-74.340843, 68.462486],
          ],
        ],
        [
          [
            [-79.099442, 68.348602],
            [-78.933319, 68.35054],
            [-78.802223, 68.272491],
            [-79.020554, 68.169144],
            [-79.089722, 68.170258],
            [-79.176941, 68.209427],
            [-79.190552, 68.27832],
            [-79.191101, 68.319443],
            [-79.099442, 68.348602],
          ],
        ],
        [
          [
            [-67.86972, 69.700821],
            [-67.72139, 69.644707],
            [-67.83168, 69.601929],
            [-67.920273, 69.521927],
            [-68.309998, 69.59082],
            [-68.063889, 69.669983],
            [-67.827217, 69.655548],
            [-67.971123, 69.689697],
            [-67.86972, 69.700821],
          ],
        ],
        [
          [
            [-90.195267, 69.416931],
            [-90.154716, 69.350807],
            [-90.329453, 69.235809],
            [-90.51001, 69.334717],
            [-90.501106, 69.372482],
            [-90.351097, 69.424698],
            [-90.294159, 69.433594],
            [-90.241943, 69.389427],
            [-90.218887, 69.401932],
            [-90.24527, 69.4272],
            [-90.205276, 69.445824],
            [-90.195267, 69.416931],
          ],
        ],
        [
          [
            [-62.8946, 67.059113],
            [-62.81028, 67.056931],
            [-62.795559, 67.099991],
            [-62.61972, 67.135269],
            [-62.652222, 67.166092],
            [-62.631939, 67.176933],
            [-62.41806, 67.190811],
            [-62.375271, 67.165817],
            [-62.580002, 67.102203],
            [-62.651661, 67.046944],
            [-62.733608, 67.04332],
            [-62.752499, 67.010536],
            [-62.8946, 67.059113],
          ],
        ],
        [
          [
            [-97.368057, 74.622757],
            [-97.256958, 74.590553],
            [-97.387512, 74.506378],
            [-97.652786, 74.455833],
            [-97.792503, 74.485809],
            [-97.631378, 74.538307],
            [-97.616943, 74.574997],
            [-97.532227, 74.606369],
            [-97.368057, 74.622757],
          ],
        ],
        [
          [
            [-95.628601, 74.640823],
            [-95.40361, 74.603317],
            [-95.246384, 74.521652],
            [-95.48056, 74.5],
            [-95.845001, 74.563873],
            [-95.628601, 74.640823],
          ],
        ],
        [
          [
            [-66.32695, 68.147491],
            [-66.628601, 68.182213],
            [-66.662216, 68.208878],
            [-66.551941, 68.201927],
            [-66.5, 68.2397],
            [-66.348892, 68.228592],
            [-66.283333, 68.268333],
            [-66.198608, 68.267487],
            [-66.269173, 68.210541],
            [-66.256668, 68.163597],
            [-66.32695, 68.147491],
          ],
        ],
        [
          [
            [-90.575562, 69.198593],
            [-90.613327, 69.207764],
            [-90.620003, 69.251938],
            [-90.690826, 69.227768],
            [-90.646393, 69.259163],
            [-90.695831, 69.277771],
            [-90.655273, 69.304703],
            [-90.680557, 69.311371],
            [-90.651947, 69.350807],
            [-90.777222, 69.272491],
            [-90.775833, 69.329987],
            [-90.673317, 69.373871],
            [-90.559433, 69.347214],
            [-90.471939, 69.281097],
            [-90.495003, 69.241928],
            [-90.454453, 69.226379],
            [-90.575562, 69.198593],
          ],
        ],
        [
          [
            [-82.935547, 66.251389],
            [-82.996384, 66.198593],
            [-83.080292, 66.19664],
            [-83.287216, 66.256104],
            [-83.272232, 66.305252],
            [-83.29834, 66.313873],
            [-83.252228, 66.344994],
            [-83.142227, 66.309143],
            [-83.16806, 66.283867],
            [-83.054993, 66.25499],
            [-82.91333, 66.276093],
            [-82.935547, 66.251389],
          ],
        ],
        [
          [
            [-64.954727, 63.553879],
            [-64.881378, 63.486382],
            [-64.847778, 63.387501],
            [-64.942207, 63.43082],
            [-64.971657, 63.494709],
            [-65.053047, 63.548328],
            [-64.986938, 63.51527],
            [-65.007507, 63.556381],
            [-64.954727, 63.553879],
          ],
        ],
        [
          [
            [-100.095596, 69.117477],
            [-100.033302, 69.094711],
            [-99.978882, 69.013893],
            [-100.007004, 68.939423],
            [-100.161697, 68.96138],
            [-100.256699, 69.026657],
            [-100.231903, 69.087196],
            [-100.144203, 69.140823],
            [-100.095596, 69.117477],
          ],
        ],
        [
          [
            [-85.259743, 77.586647],
            [-85.011124, 77.573883],
            [-84.813889, 77.497208],
            [-85.168327, 77.45694],
            [-85.152786, 77.507767],
            [-85.53833, 77.539978],
            [-85.259743, 77.586647],
          ],
        ],
        [
          [
            [-77.625, 63.997768],
            [-77.753342, 63.925549],
            [-77.982498, 63.975552],
            [-77.943604, 64.011108],
            [-77.774437, 64.031662],
            [-77.55722, 64.028053],
            [-77.625, 63.997768],
          ],
        ],
        [
          [
            [-107.882797, 67.462486],
            [-107.892799, 67.383614],
            [-107.933899, 67.361923],
            [-107.910797, 67.310532],
            [-108.150803, 67.406097],
            [-108.125298, 67.422493],
            [-108.040802, 67.386932],
            [-108.032501, 67.407494],
            [-108.075302, 67.419708],
            [-108.061096, 67.4422],
            [-107.917801, 67.489433],
            [-107.882797, 67.462486],
          ],
        ],
        [
          [
            [-62.25861, 65.728592],
            [-62.297501, 65.708603],
            [-62.18639, 65.711929],
            [-62.167782, 65.702766],
            [-62.19944, 65.685532],
            [-62.12833, 65.674149],
            [-62.215, 65.609993],
            [-62.272221, 65.663307],
            [-62.331951, 65.611923],
            [-62.313061, 65.660538],
            [-62.46611, 65.663879],
            [-62.441669, 65.689148],
            [-62.48444, 65.726929],
            [-62.444439, 65.74498],
            [-62.37389, 65.720261],
            [-62.32, 65.74942],
            [-62.25861, 65.728592],
          ],
        ],
        [
          [
            [-79.929993, 59.8736],
            [-79.878883, 59.85471],
            [-79.928047, 59.811661],
            [-80.089722, 59.751942],
            [-80.09111, 59.808331],
            [-80.184158, 59.747768],
            [-80.103058, 59.84499],
            [-80.015007, 59.884991],
            [-79.929993, 59.8736],
          ],
        ],
        [
          [
            [-63.926392, 67.633331],
            [-63.76083, 67.515266],
            [-64.025284, 67.510536],
            [-64.034439, 67.558594],
            [-63.97694, 67.649429],
            [-63.926392, 67.633331],
          ],
        ],
        [
          [
            [-96.688316, 72.883331],
            [-96.721657, 72.829163],
            [-96.641388, 72.782211],
            [-96.741379, 72.72554],
            [-96.955276, 72.734154],
            [-97.011124, 72.775818],
            [-96.921387, 72.795258],
            [-96.921387, 72.835823],
            [-96.798607, 72.881363],
            [-96.688316, 72.883331],
          ],
        ],
        [
          [
            [-95.065277, 80.680542],
            [-94.970551, 80.635269],
            [-95.190277, 80.608871],
            [-96.14917, 80.664703],
            [-95.491379, 80.699997],
            [-95.065277, 80.680542],
          ],
        ],
        [
          [
            [-79.784157, 56.94083],
            [-79.717499, 56.81361],
            [-79.757507, 56.78194],
            [-79.79306, 56.789989],
            [-79.811111, 56.89222],
            [-79.897507, 56.884991],
            [-79.858612, 56.938599],
            [-79.784157, 56.94083],
          ],
        ],
        [
          [
            [-90.027458, 68.753304],
            [-90.003014, 68.807091],
            [-89.914436, 68.853043],
            [-89.896118, 68.829712],
            [-89.925552, 68.804153],
            [-89.85611, 68.822487],
            [-89.861656, 68.79776],
            [-89.781677, 68.766663],
            [-89.944443, 68.662201],
            [-89.959167, 68.6772],
            [-89.916397, 68.704987],
            [-89.967216, 68.694702],
            [-89.956947, 68.724991],
            [-90.027458, 68.753304],
          ],
        ],
        [
          [
            [-107.983299, 67.644707],
            [-107.922203, 67.540817],
            [-108.067497, 67.539703],
            [-108.029999, 67.498871],
            [-108.109398, 67.471367],
            [-108.142799, 67.541656],
            [-108.074203, 67.557747],
            [-108.120003, 67.575821],
            [-108.117798, 67.669983],
            [-107.983299, 67.644707],
          ],
        ],
        [
          [
            [-100.479698, 75.545822],
            [-100.319702, 75.59082],
            [-100.885597, 75.545822],
            [-101.039398, 75.567207],
            [-100.235001, 75.623032],
            [-100.157799, 75.584991],
            [-100.479698, 75.545822],
          ],
        ],
        [
          [
            [-63.456108, 67.264427],
            [-63.829731, 67.284149],
            [-63.585831, 67.353317],
            [-63.58889, 67.305252],
            [-63.485001, 67.341087],
            [-63.36916, 67.302467],
            [-63.456108, 67.264427],
          ],
        ],
        [
          [
            [-74.771942, 68.673027],
            [-74.648354, 68.652206],
            [-74.654167, 68.607758],
            [-74.518341, 68.558594],
            [-74.833893, 68.569717],
            [-74.890289, 68.624977],
            [-74.771942, 68.673027],
          ],
        ],
        [
          [
            [-91.535278, 69.726929],
            [-91.73555, 69.789146],
            [-91.523621, 69.824707],
            [-91.483322, 69.871918],
            [-91.419449, 69.879967],
            [-91.502228, 69.813873],
            [-91.454178, 69.784988],
            [-91.463333, 69.763611],
            [-91.535278, 69.726929],
          ],
        ],
        [
          [
            [-102.897499, 78.26915],
            [-102.798599, 78.250267],
            [-102.781403, 78.204987],
            [-103.041702, 78.122208],
            [-103.212799, 78.120529],
            [-103.282204, 78.157761],
            [-103.062798, 78.258041],
            [-102.897499, 78.26915],
          ],
        ],
        [
          [
            [-79.706673, 57.58083],
            [-79.705002, 57.50861],
            [-79.771118, 57.51416],
            [-79.805557, 57.418049],
            [-79.85556, 57.479988],
            [-79.810547, 57.48193],
            [-79.776672, 57.530819],
            [-79.82695, 57.538052],
            [-79.734161, 57.618881],
            [-79.706673, 57.58083],
          ],
        ],
        [
          [
            [-91.072243, 77.253326],
            [-90.815002, 77.240257],
            [-90.713623, 77.200821],
            [-90.868332, 77.138321],
            [-91.184723, 77.163597],
            [-91.299156, 77.217758],
            [-91.072243, 77.253326],
          ],
        ],
        [
          [
            [-64.171944, 63.63361],
            [-64.078339, 63.550541],
            [-64.10556, 63.532211],
            [-64.092499, 63.481659],
            [-64.209167, 63.575001],
            [-64.212784, 63.6236],
            [-64.171944, 63.63361],
          ],
        ],
        [
          [
            [-64.998894, 64.354431],
            [-64.989166, 64.317757],
            [-65.037514, 64.290268],
            [-64.884743, 64.287773],
            [-64.989166, 64.209152],
            [-65.065552, 64.227478],
            [-65.114723, 64.329437],
            [-64.998894, 64.354431],
          ],
        ],
        [
          [
            [-99.528877, 76.72554],
            [-99.433884, 76.694702],
            [-99.58168, 76.670258],
            [-99.65834, 76.680542],
            [-99.551941, 76.709717],
            [-100.128601, 76.721916],
            [-100.037804, 76.751389],
            [-99.528877, 76.72554],
          ],
        ],
        [
          [
            [-108.951103, 67.973312],
            [-108.884499, 67.948593],
            [-108.910599, 67.924149],
            [-108.866096, 67.900269],
            [-108.900597, 67.869713],
            [-109.198898, 67.972488],
            [-109.058601, 67.951927],
            [-109.074203, 67.974426],
            [-108.951103, 67.973312],
          ],
        ],
        [
          [
            [-72.924713, 71.649429],
            [-72.709167, 71.655258],
            [-72.660553, 71.598038],
            [-72.742493, 71.534149],
            [-72.949997, 71.547211],
            [-73.039993, 71.579987],
            [-72.924713, 71.649429],
          ],
        ],
        [
          [
            [-67.440552, 69.588318],
            [-67.310547, 69.549149],
            [-67.545273, 69.525818],
            [-67.492493, 69.495529],
            [-67.749733, 69.521103],
            [-67.550278, 69.565536],
            [-67.518341, 69.600273],
            [-67.440552, 69.588318],
          ],
        ],
        [
          [
            [-82.796112, 69.805252],
            [-82.460281, 69.761658],
            [-82.49472, 69.752487],
            [-82.453056, 69.720543],
            [-82.54277, 69.704163],
            [-82.720001, 69.733322],
            [-82.877777, 69.774986],
            [-82.796112, 69.805252],
          ],
        ],
        [
          [
            [-65.713623, 61.824169],
            [-65.678596, 61.746658],
            [-65.948036, 61.790279],
            [-65.809433, 61.863331],
            [-65.752228, 61.850552],
            [-65.794724, 61.84277],
            [-65.780563, 61.825829],
            [-65.713623, 61.824169],
          ],
        ],
        [
          [
            [-107.581703, 67.196373],
            [-107.319504, 67.098877],
            [-107.527802, 67.078049],
            [-107.629204, 67.183319],
            [-107.581703, 67.196373],
          ],
        ],
        [
          [
            [-66.820847, 66.388046],
            [-66.573624, 66.310806],
            [-66.641953, 66.279427],
            [-66.811661, 66.316673],
            [-66.847778, 66.342484],
            [-66.783073, 66.339157],
            [-66.958618, 66.406372],
            [-66.820847, 66.388046],
          ],
        ],
        [
          [
            [-93.08667, 61.829441],
            [-93.135834, 61.87249],
            [-93.218887, 61.879429],
            [-93.223618, 61.913052],
            [-93.06221, 61.93055],
            [-92.969452, 61.888329],
            [-93.001678, 61.84721],
            [-93.08667, 61.829441],
          ],
        ],
        [
          [
            [-79.95195, 53.348331],
            [-79.957497, 53.31221],
            [-79.911942, 53.29055],
            [-79.942757, 53.266941],
            [-80.018066, 53.265831],
            [-80.085281, 53.326939],
            [-80.061394, 53.355549],
            [-79.944717, 53.36805],
            [-79.95195, 53.348331],
          ],
        ],
        [
          [
            [-88.05278, 78.445534],
            [-88.05722, 78.407494],
            [-88.154449, 78.36554],
            [-88.166397, 78.308029],
            [-88.254997, 78.247208],
            [-88.360001, 78.237762],
            [-88.407501, 78.264427],
            [-88.404449, 78.298027],
            [-88.327217, 78.336647],
            [-88.199722, 78.354431],
            [-88.248047, 78.373306],
            [-88.235283, 78.426933],
            [-88.05278, 78.445534],
          ],
        ],
        [
          [
            [-107.645302, 73.570267],
            [-107.930603, 73.539429],
            [-108.084999, 73.585823],
            [-108.0075, 73.618591],
            [-107.681099, 73.621368],
            [-107.584702, 73.603043],
            [-107.645302, 73.570267],
          ],
        ],
        [
          [
            [-68.4189, 68.810257],
            [-67.661392, 68.701927],
            [-67.86972, 68.698593],
            [-68.451401, 68.783867],
            [-68.4189, 68.810257],
          ],
        ],
        [
          [
            [-64.334091, 63.852081],
            [-64.179993, 63.865551],
            [-64.179169, 63.8111],
            [-64.236938, 63.79583],
            [-64.180832, 63.785271],
            [-64.256958, 63.774158],
            [-64.398354, 63.845539],
            [-64.334091, 63.852081],
          ],
        ],
        [
          [
            [-78.939987, 72.435806],
            [-78.731667, 72.369431],
            [-78.950287, 72.334991],
            [-79.053047, 72.360809],
            [-79.066673, 72.420258],
            [-78.939987, 72.435806],
          ],
        ],
        [
          [
            [-83.576683, 69.780823],
            [-83.708618, 69.75943],
            [-83.655838, 69.732208],
            [-83.688599, 69.719437],
            [-83.917221, 69.778587],
            [-83.886948, 69.818604],
            [-83.832497, 69.825272],
            [-83.576683, 69.780823],
          ],
        ],
        [
          [
            [-64.884743, 62.594151],
            [-64.839447, 62.57777],
            [-64.860817, 62.561378],
            [-64.972778, 62.52972],
            [-65.141678, 62.54694],
            [-65.003067, 62.598881],
            [-64.884743, 62.594151],
          ],
        ],
        [
          [
            [-110.728302, 68.484421],
            [-111.097504, 68.477203],
            [-110.821701, 68.548027],
            [-110.745796, 68.526382],
            [-110.696098, 68.486366],
            [-110.728302, 68.484421],
          ],
        ],
        [
          [
            [-97.327499, 69.931664],
            [-97.347229, 69.896652],
            [-97.23056, 69.883331],
            [-97.269173, 69.852478],
            [-97.492767, 69.917763],
            [-97.453613, 69.963043],
            [-97.327499, 69.931664],
          ],
        ],
        [
          [
            [-73.730827, 64.386383],
            [-73.697769, 64.269989],
            [-73.780838, 64.283051],
            [-73.833618, 64.331673],
            [-73.781387, 64.405548],
            [-73.730827, 64.386383],
          ],
        ],
        [
          [
            [-83.347778, 65.832489],
            [-83.485283, 65.800812],
            [-83.586121, 65.854156],
            [-83.370003, 65.866653],
            [-83.285278, 65.838882],
            [-83.347778, 65.832489],
          ],
        ],
        [
          [
            [-109.447197, 68.092209],
            [-109.3153, 68.035812],
            [-109.314201, 67.985809],
            [-109.538902, 68.047493],
            [-109.470001, 68.045258],
            [-109.5047, 68.088882],
            [-109.447197, 68.092209],
          ],
        ],
        [
          [
            [-82.01001, 68.332764],
            [-82.07251, 68.30304],
            [-82.345551, 68.367752],
            [-82.234734, 68.354431],
            [-82.243057, 68.383331],
            [-82.216949, 68.384163],
            [-82.01001, 68.332764],
          ],
        ],
        [
          [
            [-79.55249, 72.451103],
            [-79.436394, 72.41832],
            [-79.440552, 72.370529],
            [-79.53389, 72.3461],
            [-79.609444, 72.366928],
            [-79.683319, 72.430542],
            [-79.55249, 72.451103],
          ],
        ],
        [
          [
            [-101.520302, 69.197479],
            [-101.495003, 69.160263],
            [-101.563599, 69.099991],
            [-101.698303, 69.095261],
            [-101.601402, 69.151657],
            [-101.710297, 69.169144],
            [-101.695297, 69.20694],
            [-101.520302, 69.197479],
          ],
        ],
        [
          [
            [-74.555557, 78.776093],
            [-74.16362, 78.716087],
            [-74.334167, 78.675262],
            [-74.614166, 78.702766],
            [-74.706673, 78.737488],
            [-74.632217, 78.777206],
            [-74.555557, 78.776093],
          ],
        ],
        [
          [
            [-94.515007, 78.278053],
            [-94.309158, 78.179153],
            [-94.506119, 78.17276],
            [-94.671112, 78.240807],
            [-94.694153, 78.258614],
            [-94.660828, 78.27916],
            [-94.515007, 78.278053],
          ],
        ],
        [
          [
            [-97.337784, 67.7211],
            [-97.327217, 67.681664],
            [-97.370537, 67.657761],
            [-97.502792, 67.62442],
            [-97.551392, 67.642761],
            [-97.529716, 67.676376],
            [-97.560547, 67.692749],
            [-97.337784, 67.7211],
          ],
        ],
        [
          [
            [-108.286102, 67.533051],
            [-108.301697, 67.491089],
            [-108.368301, 67.467209],
            [-108.496399, 67.524429],
            [-108.467499, 67.540543],
            [-108.491402, 67.563026],
            [-108.457802, 67.568047],
            [-108.370003, 67.543053],
            [-108.3358, 67.565811],
            [-108.286102, 67.533051],
          ],
        ],
        [
          [
            [-111.528603, 68.290543],
            [-111.605301, 68.281097],
            [-111.6717, 68.224701],
            [-111.7789, 68.241089],
            [-111.703903, 68.299423],
            [-111.513603, 68.311096],
            [-111.528603, 68.290543],
          ],
        ],
        [
          [
            [-79.843887, 56.85833],
            [-79.813606, 56.81694],
            [-79.876663, 56.747768],
            [-79.941101, 56.763611],
            [-79.945831, 56.833599],
            [-79.843887, 56.85833],
          ],
        ],
        [
          [
            [-73.213333, 71.698593],
            [-73.149986, 71.674423],
            [-73.282784, 71.637772],
            [-73.352493, 71.55748],
            [-73.450562, 71.584152],
            [-73.348343, 71.658333],
            [-73.213333, 71.698593],
          ],
        ],
        [
          [
            [-108.048599, 67.949142],
            [-108.0914, 67.880814],
            [-108.254204, 67.881927],
            [-108.197998, 67.950821],
            [-108.100601, 67.974991],
            [-108.048599, 67.949142],
          ],
        ],
        [
          [
            [-90, 73.986359],
            [-90.285004, 74.029709],
            [-90, 74.065613],
            [-89.901947, 74.037773],
            [-90, 73.986359],
          ],
        ],
        [
          [
            [-62.23138, 66.26944],
            [-62.14806, 66.207764],
            [-62.43, 66.229156],
            [-62.23138, 66.26944],
          ],
        ],
        [
          [
            [-104.071404, 77.161377],
            [-103.998901, 77.123871],
            [-104.196701, 77.077477],
            [-104.431702, 77.098877],
            [-104.300797, 77.155258],
            [-104.071404, 77.161377],
          ],
        ],
        [
          [
            [-77.676102, 77.8647],
            [-77.568619, 77.849716],
            [-77.579178, 77.818604],
            [-77.680557, 77.792763],
            [-77.875267, 77.774429],
            [-77.955002, 77.830276],
            [-77.676102, 77.8647],
          ],
        ],
        [
          [
            [-65.648354, 68.168587],
            [-65.502792, 68.151093],
            [-65.495537, 68.128311],
            [-65.686943, 68.095833],
            [-65.716949, 68.175812],
            [-65.648354, 68.168587],
          ],
        ],
        [
          [
            [-90.143578, 68.967819],
            [-89.914169, 69.006943],
            [-89.933609, 68.950546],
            [-89.906662, 68.922493],
            [-90.143578, 68.967819],
          ],
        ],
        [
          [
            [-95.328613, 71.842209],
            [-95.259743, 71.827477],
            [-95.310547, 71.737198],
            [-95.488327, 71.740807],
            [-95.361107, 71.790268],
            [-95.438889, 71.790268],
            [-95.450287, 71.818878],
            [-95.328613, 71.842209],
          ],
        ],
        [
          [
            [-95.372223, 77.238037],
            [-95.2164, 77.20166],
            [-95.206947, 77.177757],
            [-95.24527, 77.164146],
            [-95.639999, 77.237762],
            [-95.372223, 77.238037],
          ],
        ],
        [
          [
            [-94.854446, 76.136658],
            [-94.816101, 76.101089],
            [-94.928596, 76.051086],
            [-95.048889, 76.050812],
            [-95.147232, 76.116928],
            [-95.013062, 76.10582],
            [-94.854446, 76.136658],
          ],
        ],
        [
          [
            [-82.636398, 69.871094],
            [-82.517227, 69.854156],
            [-82.426102, 69.786926],
            [-82.564438, 69.800812],
            [-82.688599, 69.850807],
            [-82.636398, 69.871094],
          ],
        ],
        [
          [
            [-86.06723, 72.293869],
            [-85.863892, 72.297211],
            [-85.837219, 72.262772],
            [-85.90834, 72.217758],
            [-86.09639, 72.276382],
            [-86.06723, 72.293869],
          ],
        ],
        [
          [
            [-103.331902, 75.871918],
            [-103.590797, 75.8311],
            [-103.864403, 75.871368],
            [-103.331902, 75.871918],
          ],
        ],
        [
          [
            [-97.243057, 77.037491],
            [-97.093063, 77.00499],
            [-97.256393, 76.967484],
            [-97.473053, 76.980553],
            [-97.243057, 77.037491],
          ],
        ],
        [
          [
            [-96.455841, 75.817757],
            [-96.553596, 75.738586],
            [-96.717216, 75.7397],
            [-96.660828, 75.789429],
            [-96.455841, 75.817757],
          ],
        ],
        [
          [
            [-74.707779, 72.207207],
            [-74.796661, 72.168053],
            [-74.946953, 72.173309],
            [-74.85083, 72.217758],
            [-74.673889, 72.23027],
            [-74.707779, 72.207207],
          ],
        ],
        [
          [
            [-101.681702, 78.227478],
            [-101.6036, 78.187477],
            [-101.625801, 78.148041],
            [-101.881897, 78.162201],
            [-101.785301, 78.18692],
            [-101.753304, 78.227203],
            [-101.681702, 78.227478],
          ],
        ],
        [
          [
            [-84.097504, 76.506653],
            [-83.908051, 76.464996],
            [-83.986107, 76.423309],
            [-84.109444, 76.444427],
            [-84.103607, 76.484154],
            [-84.13945, 76.507217],
            [-84.097504, 76.506653],
          ],
        ],
        [
          [
            [-62.961109, 67.054703],
            [-62.91222, 67.014709],
            [-62.977779, 67.006653],
            [-63.138889, 67.059418],
            [-63.110561, 67.080276],
            [-62.961109, 67.054703],
          ],
        ],
        [
          [
            [-66.644447, 81.562187],
            [-66.896667, 81.587486],
            [-66.296661, 81.584717],
            [-66.644447, 81.562187],
          ],
        ],
        [
          [
            [-71.044159, 71.142761],
            [-71.112503, 71.089432],
            [-71.25473, 71.073883],
            [-71.200562, 71.125809],
            [-71.044159, 71.142761],
          ],
        ],
        [
          [
            [-78.419449, 82.899147],
            [-78.414719, 82.941933],
            [-78.361656, 82.958603],
            [-78.116943, 82.9422],
            [-78.365829, 82.883614],
            [-78.419449, 82.899147],
          ],
        ],
        [
          [
            [-91.761673, 81.548027],
            [-91.960564, 81.594994],
            [-91.58223, 81.578049],
            [-91.761673, 81.548027],
          ],
        ],
      ],
    },
    id: 'CA_NU',
    properties: {
      name: 'Nunavut',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-110.394402, 78.752213],
            [-109.978409, 78.671058],
            [-109.983902, 78.325546],
            [-110.411903, 78.277206],
            [-110.715599, 78.29248],
            [-111.141403, 78.386108],
            [-111.306396, 78.371094],
            [-111.2397, 78.323608],
            [-111.433601, 78.323036],
            [-111.401901, 78.283333],
            [-111.462502, 78.267487],
            [-111.82, 78.273613],
            [-111.918602, 78.332764],
            [-112.1586, 78.36998],
            [-113.142197, 78.268333],
            [-113.334198, 78.332764],
            [-113.226097, 78.356644],
            [-113.218903, 78.40387],
            [-112.6614, 78.494141],
            [-112.238098, 78.547211],
            [-111.752502, 78.550537],
            [-111.455597, 78.592758],
            [-111.378601, 78.617752],
            [-111.4347, 78.643333],
            [-111.1894, 78.688583],
            [-110.738098, 78.75444],
            [-110.394402, 78.752213],
          ],
        ],
        [
          [
            [-109.987239, 77.924049],
            [-110.2211, 77.893883],
            [-110.895798, 77.856087],
            [-110.886398, 77.820831],
            [-110.658897, 77.75972],
            [-110.105003, 77.774986],
            [-110.079498, 77.757492],
            [-110.113899, 77.692749],
            [-110.040604, 77.637497],
            [-110.162804, 77.604713],
            [-110.080299, 77.563599],
            [-110.224998, 77.505257],
            [-110.877197, 77.411377],
            [-111.276703, 77.424698],
            [-112.057198, 77.323318],
            [-112.413101, 77.356087],
            [-112.507797, 77.381363],
            [-112.601097, 77.455261],
            [-112.790802, 77.441093],
            [-112.957802, 77.469437],
            [-113.011703, 77.512772],
            [-113.197998, 77.52388],
            [-113.240303, 77.587196],
            [-113.089203, 77.619141],
            [-113.252197, 77.664703],
            [-113.206902, 77.688873],
            [-113.229401, 77.723602],
            [-113.1875, 77.739433],
            [-113.319702, 77.795532],
            [-113.209702, 77.908867],
            [-112.996101, 77.890549],
            [-112.914703, 77.927467],
            [-112.4142, 78.003052],
            [-111.932503, 78.018333],
            [-111.807999, 78.049423],
            [-111.730598, 78.024147],
            [-111.0933, 78.092484],
            [-110.861099, 78.061653],
            [-110.550003, 78.106087],
            [-109.985789, 78.101103],
            [-109.987239, 77.924049],
          ],
        ],
        [
          [
            [-110.008101, 74.833102],
            [-110.065598, 74.817757],
            [-110.345596, 74.846939],
            [-110.410797, 74.822487],
            [-110.318901, 74.798027],
            [-110.665298, 74.78804],
            [-110.596397, 74.707764],
            [-111.6772, 74.493317],
            [-112.541901, 74.409416],
            [-113.471703, 74.418907],
            [-113.696404, 74.446091],
            [-114.298302, 74.602768],
            [-114.443604, 74.664146],
            [-114.394402, 74.708328],
            [-113.709999, 74.829712],
            [-113.207199, 74.845261],
            [-113.322502, 74.879967],
            [-112.8675, 74.97554],
            [-112.014999, 75.002487],
            [-111.753304, 74.981659],
            [-111.551399, 75.011383],
            [-111.4814, 75.045822],
            [-111.5672, 75.060257],
            [-111.306396, 75.075272],
            [-111.290604, 75.091087],
            [-111.351402, 75.111649],
            [-111.266403, 75.103043],
            [-111.029198, 75.171097],
            [-111.068298, 75.188583],
            [-111.001999, 75.221367],
            [-110.920502, 75.223602],
            [-111.0681, 75.271927],
            [-111.230797, 75.26416],
            [-111.391701, 75.181091],
            [-111.608597, 75.165543],
            [-111.577202, 75.1436],
            [-111.695297, 75.145828],
            [-111.760803, 75.184708],
            [-111.900597, 75.131927],
            [-112.409698, 75.123596],
            [-112.470802, 75.146378],
            [-112.412003, 75.159012],
            [-112.294701, 75.198029],
            [-112.387199, 75.209427],
            [-112.339401, 75.223602],
            [-112.408897, 75.240807],
            [-112.461899, 75.218872],
            [-112.432503, 75.18692],
            [-112.592499, 75.181664],
            [-112.637199, 75.204987],
            [-112.594704, 75.25],
            [-112.679199, 75.277771],
            [-112.660599, 75.266388],
            [-112.713097, 75.256104],
            [-112.735802, 75.203323],
            [-112.688599, 75.171921],
            [-112.699997, 75.138321],
            [-112.806999, 75.115807],
            [-113.038902, 75.093597],
            [-113.134201, 75.14415],
            [-113.178001, 75.09166],
            [-113.254501, 75.076103],
            [-113.941399, 75.056931],
            [-113.973099, 75.096367],
            [-113.932503, 75.141373],
            [-113.647499, 75.184143],
            [-113.929398, 75.173599],
            [-113.805801, 75.32666],
            [-113.318901, 75.417763],
            [-113.470596, 75.431091],
            [-113.902199, 75.37442],
            [-113.992203, 75.448318],
            [-114.065002, 75.466087],
            [-114.093903, 75.410812],
            [-114.040604, 75.362198],
            [-114.170799, 75.226929],
            [-114.284698, 75.234993],
            [-114.512802, 75.314697],
            [-114.615303, 75.274986],
            [-114.443001, 75.257217],
            [-114.297203, 75.179153],
            [-114.394997, 75.090553],
            [-114.949203, 74.969994],
            [-115.195297, 74.989967],
            [-115.225304, 75.064148],
            [-115.172798, 75.107758],
            [-115.257004, 75.179977],
            [-115.279198, 75.140823],
            [-115.404198, 75.098877],
            [-115.674202, 75.138321],
            [-115.595299, 75.117203],
            [-115.611397, 75.098877],
            [-115.537498, 75.027206],
            [-115.690598, 74.964157],
            [-116.253899, 75.058319],
            [-116.041702, 75.122757],
            [-116.321098, 75.103317],
            [-116.241096, 75.168869],
            [-116.239197, 75.201393],
            [-116.301399, 75.203049],
            [-116.560501, 75.179153],
            [-116.694199, 75.116653],
            [-117.457802, 75.188873],
            [-117.683899, 75.253052],
            [-117.661903, 75.298027],
            [-117.555603, 75.310806],
            [-117.575302, 75.33638],
            [-117.455299, 75.400269],
            [-117.218903, 75.476379],
            [-115.991402, 75.485809],
            [-115.619698, 75.578873],
            [-115.2686, 75.595833],
            [-115.2883, 75.620529],
            [-115.267799, 75.635818],
            [-114.998001, 75.695824],
            [-115.714401, 75.642487],
            [-116.119202, 75.572769],
            [-116.863098, 75.564148],
            [-117.25, 75.586113],
            [-117.016701, 75.737198],
            [-116.816399, 75.796371],
            [-116.037201, 75.809708],
            [-115.749397, 75.85498],
            [-115.594498, 75.833328],
            [-114.980797, 75.853043],
            [-114.798599, 75.892212],
            [-116.106697, 75.864151],
            [-116.649696, 75.884987],
            [-116.724403, 75.906372],
            [-116.7267, 75.951393],
            [-116.461899, 75.974991],
            [-116.525803, 76.002213],
            [-116.499397, 76.026657],
            [-116.705597, 76.039146],
            [-116.641403, 76.113312],
            [-116.516098, 76.157761],
            [-116.163597, 76.197197],
            [-114.790802, 76.151077],
            [-114.680801, 76.164993],
            [-115.282204, 76.233871],
            [-115.827202, 76.239433],
            [-115.9217, 76.281097],
            [-115.850304, 76.324158],
            [-115.909401, 76.345543],
            [-115.499702, 76.454712],
            [-115.0214, 76.474701],
            [-114.899696, 76.516937],
            [-114.702003, 76.515266],
            [-114.751999, 76.487762],
            [-114.4617, 76.501663],
            [-114.118301, 76.435257],
            [-114.106102, 76.355553],
            [-114.177803, 76.344711],
            [-113.983299, 76.261383],
            [-114.0597, 76.217758],
            [-113.983299, 76.190262],
            [-113.323303, 76.262772],
            [-112.999199, 76.267487],
            [-112.892197, 76.253883],
            [-112.838898, 76.214996],
            [-112.425003, 76.167763],
            [-112.528099, 76.103867],
            [-112.4133, 76.04248],
            [-112.043297, 75.998871],
            [-111.727798, 75.921654],
            [-112.074997, 75.873871],
            [-112.225601, 75.811096],
            [-111.452003, 75.836647],
            [-111.474197, 75.810806],
            [-111.592201, 75.80748],
            [-111.439201, 75.776382],
            [-111.451698, 75.742203],
            [-111.355301, 75.724426],
            [-111.4039, 75.684708],
            [-111.3722, 75.645264],
            [-111.407204, 75.61499],
            [-111.3536, 75.572487],
            [-111.292801, 75.57193],
            [-111.318604, 75.545258],
            [-111.2714, 75.522491],
            [-110.495499, 75.569153],
            [-110.389503, 75.534149],
            [-110.00402, 75.532769],
            [-110.008101, 74.833102],
          ],
        ],
        [
          [
            [-110.001897, 75.875843],
            [-110.055603, 75.890549],
            [-110.001757, 75.898089],
            [-110.001897, 75.875843],
          ],
        ],
        [
          [
            [-109.999649, 76.224774],
            [-110.201698, 76.285538],
            [-110.372803, 76.294434],
            [-110.329498, 76.336647],
            [-110.377502, 76.355553],
            [-110.383904, 76.427467],
            [-109.997979, 76.474992],
            [-109.999649, 76.224774],
          ],
        ],
        [
          [
            [-117.431396, 69.99498],
            [-117.251404, 70.072769],
            [-117.010803, 70.116928],
            [-115.908302, 70.228867],
            [-114.543098, 70.313309],
            [-114.2183, 70.316093],
            [-113.683899, 70.263046],
            [-113.333298, 70.277206],
            [-112.564697, 70.198318],
            [-112.517197, 70.207489],
            [-112.577797, 70.216087],
            [-112.543297, 70.237488],
            [-112.391403, 70.225273],
            [-112.3414, 70.234993],
            [-112.378899, 70.256653],
            [-112.138298, 70.271378],
            [-112.283302, 70.299988],
            [-111.926399, 70.252213],
            [-111.442802, 70.290268],
            [-111.583603, 70.301086],
            [-111.489197, 70.324997],
            [-111.536903, 70.349426],
            [-111.980598, 70.370819],
            [-112.169197, 70.497208],
            [-112.408302, 70.507492],
            [-112.445297, 70.534149],
            [-112.528099, 70.51416],
            [-112.709702, 70.56749],
            [-112.848602, 70.567757],
            [-112.882202, 70.538589],
            [-113.091904, 70.608871],
            [-113.185799, 70.603592],
            [-113.206703, 70.64415],
            [-113.511101, 70.677757],
            [-113.591698, 70.646103],
            [-113.938301, 70.715271],
            [-114.171097, 70.664703],
            [-114.408302, 70.673599],
            [-114.641098, 70.622482],
            [-115.399399, 70.60498],
            [-115.5811, 70.573036],
            [-115.699699, 70.598602],
            [-116.055603, 70.57222],
            [-116.211899, 70.602768],
            [-116.171097, 70.626923],
            [-116.363899, 70.63916],
            [-116.9011, 70.597214],
            [-117.338097, 70.598877],
            [-117.376701, 70.625526],
            [-117.543602, 70.595261],
            [-117.724701, 70.641373],
            [-117.741699, 70.661377],
            [-117.709396, 70.693863],
            [-117.735802, 70.711647],
            [-118.112701, 70.811462],
            [-118.3097, 70.897217],
            [-118.415001, 70.998032],
            [-117.849701, 71.156937],
            [-116.842499, 71.247208],
            [-116.800598, 71.286102],
            [-116.2089, 71.364151],
            [-116.077797, 71.365807],
            [-116.148903, 71.341087],
            [-115.760803, 71.365807],
            [-115.7267, 71.381363],
            [-115.790604, 71.396378],
            [-116.220001, 71.424423],
            [-115.740799, 71.503601],
            [-115.750298, 71.481087],
            [-115.620796, 71.498596],
            [-115.378304, 71.449707],
            [-115.057503, 71.523041],
            [-115.442802, 71.488037],
            [-115.558601, 71.508331],
            [-115.455803, 71.532494],
            [-115.706398, 71.555817],
            [-117.377197, 71.382751],
            [-117.416702, 71.391373],
            [-117.378304, 71.454163],
            [-117.471397, 71.454712],
            [-117.4478, 71.473877],
            [-117.562798, 71.49971],
            [-117.633301, 71.461929],
            [-117.483299, 71.43248],
            [-117.539398, 71.372757],
            [-117.720299, 71.400818],
            [-117.776901, 71.368591],
            [-118.112198, 71.373596],
            [-118.2836, 71.404709],
            [-118.3153, 71.439697],
            [-118.290001, 71.481659],
            [-118.054398, 71.543053],
            [-117.766403, 71.522774],
            [-117.8358, 71.554703],
            [-117.6558, 71.57193],
            [-117.887199, 71.614151],
            [-117.957199, 71.598602],
            [-117.967499, 71.626373],
            [-117.700798, 71.671371],
            [-118.027802, 71.671654],
            [-118.172798, 71.628036],
            [-118.176697, 71.596367],
            [-118.411697, 71.57666],
            [-118.386101, 71.619713],
            [-118.566399, 71.662773],
            [-118.855598, 71.662491],
            [-118.9039, 71.6147],
            [-118.880501, 71.577209],
            [-119.050301, 71.626648],
            [-119.134499, 71.765266],
            [-119.087502, 71.908333],
            [-118.929199, 72.000549],
            [-118.724998, 72.06192],
            [-118.717499, 72.116928],
            [-118.5886, 72.176086],
            [-118.112801, 72.231934],
            [-118.145302, 72.326927],
            [-118.491096, 72.353043],
            [-118.5886, 72.416656],
            [-118.536903, 72.493874],
            [-118.191299, 72.628662],
            [-117.868896, 72.699997],
            [-117.3536, 72.916382],
            [-116.941399, 72.956383],
            [-116.574203, 73.054153],
            [-114.561699, 73.375526],
            [-114.226097, 73.318329],
            [-114.016701, 73.206383],
            [-113.953903, 73.125526],
            [-114.051399, 72.959991],
            [-114.017197, 72.925812],
            [-114.052803, 72.872208],
            [-113.969498, 72.813026],
            [-114.224197, 72.794983],
            [-114.351402, 72.747482],
            [-114.327797, 72.688873],
            [-114.248596, 72.688583],
            [-114.604698, 72.601646],
            [-114.5522, 72.585541],
            [-114.558296, 72.560806],
            [-114.351402, 72.55748],
            [-114.319199, 72.589706],
            [-114.062798, 72.640266],
            [-113.893097, 72.660263],
            [-113.806702, 72.63916],
            [-113.516098, 72.701927],
            [-113.729202, 72.61554],
            [-113.699699, 72.604156],
            [-113.442802, 72.672211],
            [-113.4133, 72.734711],
            [-113.597504, 72.754173],
            [-113.5989, 72.782761],
            [-113.302803, 72.948868],
            [-113.007004, 73.008881],
            [-112.514999, 72.951103],
            [-112.3619, 72.904709],
            [-112.061699, 72.889427],
            [-111.2258, 72.722763],
            [-111.202499, 72.670532],
            [-111.276901, 72.56749],
            [-111.489197, 72.50499],
            [-111.436699, 72.488586],
            [-111.560501, 72.429153],
            [-111.905602, 72.349716],
            [-111.812798, 72.323036],
            [-111.643097, 72.348038],
            [-111.651703, 72.319153],
            [-111.726097, 72.300537],
            [-111.713898, 72.274429],
            [-111.786102, 72.222763],
            [-111.763298, 72.198593],
            [-111.653297, 72.29332],
            [-111.4244, 72.337196],
            [-111.578102, 72.339157],
            [-111.609398, 72.367203],
            [-111.350304, 72.453323],
            [-111.2286, 72.465553],
            [-111.202499, 72.460274],
            [-111.243896, 72.434563],
            [-111.371696, 72.403587],
            [-111.2808, 72.397774],
            [-111.364403, 72.342484],
            [-111.241898, 72.365257],
            [-111.033096, 72.281937],
            [-111.0914, 72.401932],
            [-110.987801, 72.434418],
            [-110.993103, 72.476379],
            [-110.796997, 72.472763],
            [-110.827797, 72.525818],
            [-110.701401, 72.575546],
            [-110.478897, 72.5522],
            [-110.601898, 72.493317],
            [-110.350601, 72.42804],
            [-110.311897, 72.434708],
            [-110.530602, 72.502487],
            [-110.384201, 72.555542],
            [-110.050598, 72.433868],
            [-110.019917, 72.442051],
            [-110.029392, 69.995491],
            [-112.48507, 69.993691],
            [-112.477477, 69.892389],
            [-112.614279, 69.896203],
            [-112.657705, 69.834517],
            [-112.862425, 69.840022],
            [-112.887108, 69.991098],
            [-117.085008, 69.999148],
            [-117.118097, 69.891887],
            [-116.513568, 69.634805],
            [-116.859908, 69.637114],
            [-116.847504, 69.648613],
            [-117.247498, 69.756943],
            [-117.431396, 69.99498],
          ],
        ],
        [
          [
            [-110.019692, 72.493611],
            [-110.255798, 72.556931],
            [-110.104202, 72.557747],
            [-110.019536, 72.529154],
            [-110.019692, 72.493611],
          ],
        ],
        [
          [
            [-110.01912, 72.623398],
            [-110.2911, 72.671097],
            [-110.178299, 72.680817],
            [-110.212799, 72.711647],
            [-110.188301, 72.726089],
            [-110.078102, 72.727074],
            [-110.018727, 72.7117],
            [-110.01912, 72.623398],
          ],
        ],
        [
          [
            [-110.018556, 72.749766],
            [-110.073601, 72.755829],
            [-110.266098, 72.734993],
            [-110.171402, 72.774986],
            [-110.2117, 72.818329],
            [-110.535599, 72.847214],
            [-110.756699, 72.971367],
            [-110.708298, 73.002487],
            [-110.511398, 73.015266],
            [-110.017534, 72.974877],
            [-110.018556, 72.749766],
          ],
        ],
        [
          [
            [-102.001701, 59.99992],
            [-104.270699, 59.9995],
            [-104.7099, 59.999771],
            [-105.5411, 60],
            [-105.859001, 59.99968],
            [-106.044403, 59.999592],
            [-106.255997, 59.999771],
            [-107.113602, 59.994709],
            [-110.0009, 59.995281],
            [-119.982903, 59.988522],
            [-123.816902, 59.994709],
            [-123.8769, 60.04166],
            [-124.029198, 60.023048],
            [-123.974197, 60.098049],
            [-124.195297, 60.32888],
            [-124.206398, 60.457211],
            [-124.418297, 60.47916],
            [-124.459396, 60.569721],
            [-124.614197, 60.668598],
            [-124.619202, 60.709721],
            [-124.476898, 60.79055],
            [-124.578903, 60.953049],
            [-124.813004, 60.972488],
            [-124.8778, 60.91721],
            [-124.871696, 60.858051],
            [-125, 60.860519],
            [-125.184196, 60.848881],
            [-125.370003, 60.782558],
            [-125.711998, 60.827259],
            [-125.887199, 60.896938],
            [-125.957001, 60.877239],
            [-126.009201, 60.80341],
            [-126.107201, 60.817009],
            [-126.113602, 60.862808],
            [-126.202202, 60.863651],
            [-126.244698, 60.850868],
            [-126.217499, 60.819771],
            [-126.253601, 60.78867],
            [-126.394501, 60.77668],
            [-126.520302, 60.814411],
            [-126.672203, 60.743469],
            [-126.753304, 60.782211],
            [-126.826103, 60.755001],
            [-126.886902, 60.78249],
            [-126.8797, 60.82999],
            [-126.925301, 60.862209],
            [-126.898903, 60.941662],
            [-126.9394, 61.002491],
            [-126.935303, 61.059158],
            [-127.055801, 61.039989],
            [-127.101898, 61.070831],
            [-127.0131, 61.12804],
            [-127.031403, 61.150539],
            [-127.007004, 61.18499],
            [-127.059402, 61.363049],
            [-127.125, 61.4011],
            [-127.141701, 61.46666],
            [-127.2883, 61.519161],
            [-127.550301, 61.511379],
            [-127.972801, 61.688599],
            [-128.006104, 61.732491],
            [-128.080597, 61.849159],
            [-128.196701, 61.844151],
            [-128.279205, 61.948879],
            [-128.388107, 61.993881],
            [-128.375305, 62.02721],
            [-128.546997, 62.122219],
            [-128.677505, 62.125271],
            [-128.765305, 62.058331],
            [-128.984207, 62.136108],
            [-129.122803, 62.116661],
            [-129.2939, 62.156101],
            [-129.199203, 62.21693],
            [-129.282196, 62.267208],
            [-129.310501, 62.326389],
            [-129.215805, 62.377491],
            [-129.308304, 62.421379],
            [-129.172195, 62.481659],
            [-129.417801, 62.584991],
            [-129.567795, 62.57444],
            [-129.487793, 62.618881],
            [-129.523895, 62.64333],
            [-129.516998, 62.672489],
            [-129.625305, 62.70499],
            [-129.630798, 62.76083],
            [-129.773895, 62.874439],
            [-129.702499, 62.89222],
            [-129.726105, 62.91415],
            [-129.705795, 62.952221],
            [-129.598907, 63.051929],
            [-129.637207, 63.08028],
            [-129.847198, 63.086941],
            [-129.835297, 63.114719],
            [-129.900299, 63.197208],
            [-129.978607, 63.203609],
            [-130.044495, 63.269989],
            [-130.151093, 63.265831],
            [-130.138306, 63.310268],
            [-130.073593, 63.33696],
            [-129.919205, 63.372761],
            [-129.897202, 63.452221],
            [-129.819504, 63.48333],
            [-129.898102, 63.57222],
            [-129.957993, 63.58527],
            [-129.948593, 63.613609],
            [-130.093002, 63.63055],
            [-130.110794, 63.66444],
            [-130.082504, 63.68832],
            [-130.109695, 63.703609],
            [-130.251999, 63.66666],
            [-130.321106, 63.69582],
            [-130.282501, 63.73777],
            [-130.103897, 63.76416],
            [-130.119705, 63.808041],
            [-130.3703, 63.84388],
            [-130.522202, 63.93499],
            [-130.5811, 63.92416],
            [-130.7556, 63.986938],
            [-130.778595, 64.009163],
            [-130.751404, 64.046097],
            [-130.863907, 64.043053],
            [-130.853607, 64.072487],
            [-130.9478, 64.137207],
            [-130.867203, 64.187759],
            [-130.937195, 64.209991],
            [-130.979706, 64.265549],
            [-131.060501, 64.279427],
            [-131.001999, 64.330276],
            [-131.141693, 64.424423],
            [-131.388596, 64.467209],
            [-131.427505, 64.416382],
            [-131.593002, 64.368591],
            [-131.8181, 64.378311],
            [-131.803894, 64.438026],
            [-131.687805, 64.498596],
            [-131.702805, 64.535538],
            [-131.915802, 64.582489],
            [-132.046402, 64.695534],
            [-132.163605, 64.70166],
            [-132.368896, 64.77832],
            [-132.570602, 64.772491],
            [-132.622498, 64.826927],
            [-132.487503, 64.870819],
            [-132.507507, 64.964432],
            [-132.333298, 65.034714],
            [-132.381699, 65.084991],
            [-132.539993, 65.093872],
            [-132.556107, 65.118042],
            [-132.527496, 65.162773],
            [-132.576401, 65.190536],
            [-132.701904, 65.15387],
            [-132.787796, 65.187759],
            [-132.714203, 65.199417],
            [-132.780594, 65.229713],
            [-132.555298, 65.2836],
            [-132.521393, 65.363037],
            [-132.327805, 65.434708],
            [-132.273605, 65.546944],
            [-132.174194, 65.59137],
            [-132.286102, 65.71582],
            [-132.573303, 65.848602],
            [-132.525803, 65.897774],
            [-132.334396, 65.94664],
            [-132.342804, 65.981934],
            [-132.5103, 65.978867],
            [-132.542496, 66.021378],
            [-132.605804, 66.031662],
            [-132.869003, 65.910477],
            [-132.993896, 65.923027],
            [-132.908401, 65.996933],
            [-132.939697, 66.02887],
            [-133.147507, 66.024696],
            [-133.407501, 65.943863],
            [-133.629395, 65.974426],
            [-133.603302, 66.051651],
            [-133.704697, 66.07193],
            [-133.673096, 66.116928],
            [-133.563904, 66.155823],
            [-133.570007, 66.27832],
            [-133.824707, 66.316673],
            [-133.768097, 66.373032],
            [-133.750305, 66.447746],
            [-133.629395, 66.445251],
            [-133.690308, 66.531937],
            [-133.5867, 66.564697],
            [-133.771896, 66.64415],
            [-133.757202, 66.685257],
            [-133.840302, 66.727768],
            [-133.763901, 66.806931],
            [-133.908096, 66.908867],
            [-134.092499, 66.955261],
            [-134.046097, 66.984421],
            [-133.8311, 67.003326],
            [-136.164505, 67.003601],
            [-136.222198, 67.064148],
            [-136.198898, 67.143333],
            [-136.236404, 67.173866],
            [-136.134506, 67.226379],
            [-136.0914, 67.302467],
            [-136.142807, 67.359421],
            [-136.116394, 67.382751],
            [-136.205307, 67.406937],
            [-136.157806, 67.507767],
            [-136.241104, 67.620529],
            [-136.421097, 67.655258],
            [-136.445007, 67.715553],
            [-136.446106, 68.873032],
            [-136.419998, 68.90052],
            [-136.027802, 68.873032],
            [-135.799194, 68.822487],
            [-135.725296, 68.772491],
            [-135.519699, 68.745819],
            [-135.399399, 68.670258],
            [-135.286407, 68.684143],
            [-135.160004, 68.657211],
            [-135.506104, 68.832489],
            [-135.337997, 68.834991],
            [-135.623596, 68.886108],
            [-135.241699, 68.926933],
            [-135.172501, 68.886383],
            [-134.977493, 68.878311],
            [-134.845596, 68.929428],
            [-134.669495, 68.873306],
            [-134.626099, 68.808319],
            [-134.5, 68.756943],
            [-134.446396, 68.700272],
            [-134.219498, 68.692513],
            [-134.287506, 68.753601],
            [-134.406403, 68.801086],
            [-134.629196, 69.008881],
            [-134.537506, 69.093872],
            [-134.338898, 69.106369],
            [-134.1539, 69.258881],
            [-133.905594, 69.287491],
            [-133.859695, 69.337486],
            [-133.688904, 69.336929],
            [-133.661102, 69.348602],
            [-133.691101, 69.359154],
            [-133.670303, 69.386658],
            [-133.550598, 69.405823],
            [-133.217499, 69.396378],
            [-133.046707, 69.433319],
            [-133.052505, 69.466087],
            [-132.966095, 69.511658],
            [-132.951904, 69.569153],
            [-132.988602, 69.595261],
            [-132.893906, 69.65387],
            [-132.417496, 69.635536],
            [-132.333603, 69.68248],
            [-132.449402, 69.654427],
            [-132.423294, 69.683319],
            [-132.441101, 69.702477],
            [-132.622498, 69.679703],
            [-132.540604, 69.740807],
            [-132.259201, 69.772491],
            [-132.480804, 69.732208],
            [-132.303101, 69.733597],
            [-132.310303, 69.710541],
            [-132.133896, 69.686653],
            [-132.107193, 69.723602],
            [-131.845306, 69.765266],
            [-131.645004, 69.86499],
            [-131.418594, 69.901932],
            [-131.4478, 69.918587],
            [-131.410294, 69.957207],
            [-131.237198, 69.9272],
            [-131.201401, 69.883881],
            [-131.220596, 69.843048],
            [-131.184998, 69.824158],
            [-131.152496, 69.854156],
            [-131.185501, 69.863312],
            [-131.170303, 69.881927],
            [-131.080597, 69.884987],
            [-131.0047, 70.003883],
            [-130.930603, 70.021378],
            [-131.019501, 70.027771],
            [-130.892197, 70.099152],
            [-130.897507, 70.065811],
            [-130.713593, 70.125526],
            [-130.748306, 70.08194],
            [-130.720596, 70.076103],
            [-130.657501, 70.096939],
            [-130.656998, 70.134163],
            [-130.472198, 70.173866],
            [-130.522202, 70.158867],
            [-130.535599, 70.107483],
            [-130.477203, 70.110527],
            [-130.504196, 70.13443],
            [-130.411407, 70.131927],
            [-130.386703, 70.165543],
            [-130.324997, 70.134163],
            [-130.331696, 70.105263],
            [-130.285294, 70.101646],
            [-130.336105, 70.083054],
            [-130.279495, 70.082657],
            [-130.170807, 70.105263],
            [-130.195297, 70.062759],
            [-130.168594, 70.053307],
            [-130.104996, 70.109711],
            [-130.074707, 70.106369],
            [-130.082794, 70.073883],
            [-129.951096, 70.099426],
            [-129.990295, 70.068878],
            [-129.8936, 70.077766],
            [-129.854996, 70.10054],
            [-129.853302, 70.149147],
            [-129.7883, 70.177757],
            [-129.832504, 70.195534],
            [-129.730804, 70.218597],
            [-129.740005, 70.248032],
            [-129.673096, 70.266937],
            [-129.591904, 70.188583],
            [-129.629395, 70.160812],
            [-129.459198, 70.147491],
            [-129.502808, 70.115257],
            [-129.473297, 70.092758],
            [-129.404694, 70.123032],
            [-129.459396, 70.053307],
            [-129.553604, 70.042763],
            [-129.497498, 70.020538],
            [-130.521896, 69.782494],
            [-130.578293, 69.708038],
            [-130.780304, 69.676933],
            [-130.844101, 69.599716],
            [-130.928604, 69.563309],
            [-131.039703, 69.601646],
            [-131.039993, 69.640823],
            [-131.331696, 69.603317],
            [-131.332794, 69.574707],
            [-131.645599, 69.577209],
            [-131.667801, 69.571663],
            [-131.657196, 69.531662],
            [-131.755905, 69.56694],
            [-132.006393, 69.527206],
            [-132.080002, 69.48082],
            [-132.042496, 69.448868],
            [-132.098907, 69.451103],
            [-132.094101, 69.418053],
            [-132.141693, 69.412773],
            [-132.097504, 69.396057],
            [-132.094696, 69.362762],
            [-132.339401, 69.311096],
            [-132.315796, 69.247757],
            [-132.381393, 69.305252],
            [-132.435806, 69.298599],
            [-132.434204, 69.250267],
            [-132.483902, 69.236366],
            [-132.495499, 69.159416],
            [-132.513306, 69.263046],
            [-132.545593, 69.283333],
            [-132.717804, 69.266388],
            [-132.837997, 69.217209],
            [-132.8228, 69.1922],
            [-132.907196, 69.12442],
            [-132.829697, 69.106934],
            [-132.901901, 69.071663],
            [-132.888306, 68.979713],
            [-132.922806, 68.985809],
            [-132.946106, 69.037491],
            [-132.919205, 69.074997],
            [-133.016693, 69.044983],
            [-133.098602, 69.06694],
            [-133.200806, 69.032494],
            [-133.198898, 68.977203],
            [-133.251099, 68.963608],
            [-133.210495, 68.944138],
            [-133.233597, 68.9086],
            [-133.334702, 68.866089],
            [-133.399399, 68.881363],
            [-133.3694, 68.910538],
            [-133.466095, 68.888321],
            [-133.425598, 68.857483],
            [-133.484695, 68.850273],
            [-133.463898, 68.79332],
            [-133.054199, 68.69136],
            [-132.918304, 68.690262],
            [-132.933594, 68.709717],
            [-133.152496, 68.720833],
            [-133.266098, 68.77887],
            [-133.139694, 68.766937],
            [-133.251999, 68.797981],
            [-133.335495, 68.789703],
            [-133.380295, 68.844711],
            [-133.237793, 68.861099],
            [-133.121902, 68.805817],
            [-132.938004, 68.795822],
            [-132.962204, 68.8461],
            [-132.936707, 68.85498],
            [-132.707199, 68.786652],
            [-132.733307, 68.830833],
            [-132.491898, 68.801086],
            [-132.422699, 68.835831],
            [-132.397003, 68.8647],
            [-132.553299, 68.916092],
            [-132.492493, 68.877197],
            [-132.598602, 68.89415],
            [-132.665298, 68.841927],
            [-132.787201, 68.847488],
            [-132.705002, 68.880814],
            [-132.833298, 68.923599],
            [-132.675797, 68.926651],
            [-132.855804, 68.984421],
            [-132.867996, 69.026932],
            [-132.827194, 69.040268],
            [-132.865494, 69.068329],
            [-132.496094, 69.132202],
            [-132.425003, 69.233597],
            [-132.404205, 69.216377],
            [-132.423904, 69.150269],
            [-132.467499, 69.107208],
            [-132.384995, 69.13916],
            [-132.320602, 69.233871],
            [-132.291702, 69.203873],
            [-132.342194, 69.188873],
            [-132.289993, 69.1772],
            [-132.197006, 69.212196],
            [-132.237198, 69.161102],
            [-132.222504, 69.141663],
            [-132.163605, 69.24054],
            [-132.076096, 69.226089],
            [-131.953293, 69.260269],
            [-131.9095, 69.309708],
            [-131.876907, 69.310806],
            [-131.913895, 69.280823],
            [-131.865005, 69.283051],
            [-131.714401, 69.397774],
            [-131.849701, 69.369141],
            [-131.887497, 69.405258],
            [-131.970001, 69.406372],
            [-131.846603, 69.426086],
            [-131.645798, 69.421654],
            [-131.679993, 69.451927],
            [-131.652206, 69.471916],
            [-131.416107, 69.435532],
            [-131.486099, 69.402206],
            [-131.480804, 69.358597],
            [-131.525803, 69.329437],
            [-131.412796, 69.366653],
            [-131.405594, 69.41832],
            [-131.358307, 69.419983],
            [-131.370804, 69.454437],
            [-131.330795, 69.442467],
            [-131.321899, 69.493317],
            [-131.241898, 69.406372],
            [-131.274704, 69.49498],
            [-131.245804, 69.498322],
            [-131.212997, 69.387497],
            [-131.414505, 69.296944],
            [-131.160599, 69.388603],
            [-131.1642, 69.495529],
            [-131.247803, 69.583054],
            [-131.113602, 69.513321],
            [-131.128296, 69.361923],
            [-131.062195, 69.510269],
            [-131.196106, 69.589981],
            [-131.093597, 69.609421],
            [-131.104401, 69.571663],
            [-131.027802, 69.463882],
            [-131.116394, 69.322487],
            [-131.030899, 69.371918],
            [-130.962997, 69.472214],
            [-130.990295, 69.539429],
            [-130.946396, 69.539146],
            [-130.932495, 69.419144],
            [-131.047806, 69.336113],
            [-131.016998, 69.30748],
            [-130.935501, 69.388321],
            [-130.897797, 69.385818],
            [-130.906403, 69.328873],
            [-130.9991, 69.268333],
            [-130.989197, 69.236649],
            [-131.024704, 69.209717],
            [-131.014206, 69.136932],
            [-130.922806, 69.135269],
            [-130.956696, 69.161926],
            [-130.941696, 69.270538],
            [-130.794495, 69.346367],
            [-130.813004, 69.3797],
            [-130.660294, 69.429428],
            [-130.652802, 69.457764],
            [-130.748306, 69.453598],
            [-130.443893, 69.582489],
            [-130.452194, 69.613312],
            [-130.333893, 69.681374],
            [-130.365295, 69.694427],
            [-129.602203, 69.818878],
            [-129.099396, 69.858871],
            [-128.9319, 69.971649],
            [-128.900299, 69.971878],
            [-128.854706, 69.954712],
            [-128.898895, 69.962769],
            [-128.8983, 69.914429],
            [-128.9561, 69.889709],
            [-128.934402, 69.844147],
            [-129.169495, 69.824707],
            [-129.1297, 69.74942],
            [-129.149994, 69.695534],
            [-128.977493, 69.674698],
            [-128.921402, 69.687187],
            [-128.970795, 69.711113],
            [-128.961395, 69.732758],
            [-128.795807, 69.756943],
            [-128.587997, 69.872208],
            [-128.324203, 69.948318],
            [-128.308594, 70.008041],
            [-128.369705, 70.095833],
            [-128.343002, 70.116928],
            [-128.109207, 70.182213],
            [-127.514999, 70.221649],
            [-128.028397, 70.286377],
            [-128.112198, 70.359711],
            [-127.959999, 70.347488],
            [-127.915604, 70.396652],
            [-128.192795, 70.386932],
            [-128.135803, 70.523041],
            [-127.996696, 70.590553],
            [-127.463097, 70.409149],
            [-127.173599, 70.272217],
            [-126.893303, 70.008881],
            [-126.7742, 69.974426],
            [-126.795303, 69.943863],
            [-126.765602, 69.928307],
            [-126.811897, 69.905258],
            [-126.705803, 69.766098],
            [-126.255798, 69.526657],
            [-125.910599, 69.405548],
            [-125.6689, 69.389969],
            [-125.426399, 69.31218],
            [-125.365501, 69.342468],
            [-125.457802, 69.384407],
            [-125.456902, 69.410248],
            [-125.165298, 69.381638],
            [-125.1119, 69.415817],
            [-125.135803, 69.439133],
            [-125.089401, 69.449707],
            [-125.266403, 69.442177],
            [-125.295303, 69.47081],
            [-125.622498, 69.418587],
            [-125.578102, 69.471649],
            [-125.498596, 69.474693],
            [-125.511101, 69.491928],
            [-125.464699, 69.512207],
            [-125.313301, 69.497742],
            [-125.324203, 69.523857],
            [-125.118103, 69.485786],
            [-125.292801, 69.549133],
            [-125.225304, 69.586639],
            [-125.437202, 69.608871],
            [-125.372803, 69.6147],
            [-125.413597, 69.638321],
            [-125.365501, 69.690262],
            [-125.241096, 69.686371],
            [-125.016998, 69.740517],
            [-124.967499, 69.726929],
            [-124.925003, 69.644707],
            [-124.861702, 69.688583],
            [-124.871101, 69.713882],
            [-124.821999, 69.714996],
            [-124.897499, 69.750549],
            [-125.241402, 69.760246],
            [-125.276398, 69.808243],
            [-125.184402, 69.855797],
            [-125.165001, 69.795502],
            [-125.058601, 69.789688],
            [-125.0214, 69.864967],
            [-124.893303, 69.940262],
            [-124.629997, 69.979431],
            [-124.684196, 70.01915],
            [-124.764503, 69.970833],
            [-124.852501, 69.986099],
            [-124.795303, 70.008881],
            [-124.976402, 70.007217],
            [-125.121101, 69.967506],
            [-125.111397, 69.94194],
            [-125.206703, 69.933228],
            [-125.188301, 70.009033],
            [-125, 70.079987],
            [-124.986702, 70.041656],
            [-125.047997, 70.023041],
            [-125, 70.018646],
            [-124.863297, 70.027206],
            [-124.956902, 70.032211],
            [-124.834198, 70.068047],
            [-124.645302, 70.07193],
            [-124.584702, 70.011932],
            [-124.5336, 70.01944],
            [-124.556702, 70.036377],
            [-124.526398, 70.053589],
            [-124.436096, 70.036377],
            [-124.418098, 70.046371],
            [-124.449402, 70.080276],
            [-124.577797, 70.0811],
            [-124.519997, 70.103867],
            [-124.735802, 70.089432],
            [-124.6847, 70.108322],
            [-124.752502, 70.121643],
            [-124.680298, 70.149719],
            [-124.385803, 70.144707],
            [-124.446098, 70.108871],
            [-124.361397, 70.073608],
            [-124.376701, 70.023323],
            [-124.488899, 70.016098],
            [-124.417801, 69.989433],
            [-124.537804, 69.963882],
            [-124.438599, 69.952766],
            [-124.441902, 69.920258],
            [-124.5056, 69.903053],
            [-124.403297, 69.857758],
            [-124.501701, 69.784416],
            [-124.479401, 69.755547],
            [-124.500603, 69.725807],
            [-124.294998, 69.695251],
            [-124.201401, 69.729431],
            [-124.069702, 69.723602],
            [-124.035301, 69.678864],
            [-124.203598, 69.619713],
            [-124.240501, 69.550262],
            [-124.419998, 69.470261],
            [-124.414398, 69.412773],
            [-124.518898, 69.40416],
            [-124.446701, 69.367203],
            [-124.263603, 69.348602],
            [-124.095299, 69.35498],
            [-124.053001, 69.386932],
            [-124.006104, 69.344711],
            [-124.016403, 69.37915],
            [-123.984703, 69.404984],
            [-123.889503, 69.365257],
            [-123.817001, 69.388893],
            [-123.68, 69.353317],
            [-123.477798, 69.381088],
            [-123.380798, 69.410538],
            [-123.450798, 69.44693],
            [-123.400002, 69.490257],
            [-123.168602, 69.495247],
            [-123.126701, 69.571114],
            [-123.162201, 69.618591],
            [-123.096397, 69.670532],
            [-123.108902, 69.747757],
            [-123.152199, 69.768051],
            [-122.959198, 69.833603],
            [-122.807999, 69.793587],
            [-122.754997, 69.799149],
            [-122.741898, 69.830276],
            [-122.202797, 69.794983],
            [-121.930603, 69.814423],
            [-121.443001, 69.765549],
            [-120.881104, 69.638893],
            [-120.66524, 69.551327],
            [-120.664292, 69.542782],
            [-120.658461, 68.001268],
            [-112.498556, 65.506191],
            [-110.651709, 65.499518],
            [-109.387397, 64.826919],
            [-101.984001, 64.21341],
            [-102.001701, 59.99992],
          ],
        ],
        [
          [
            [-119.744797, 74.025513],
            [-119.750603, 74.09137],
            [-119.611397, 74.128593],
            [-119.465302, 74.2211],
            [-119.121101, 74.199707],
            [-119.090797, 74.150818],
            [-119.131104, 74.126083],
            [-119.0653, 74.103317],
            [-119.147202, 74.062187],
            [-119.187202, 73.994141],
            [-119.167503, 73.987198],
            [-119.073898, 74.015266],
            [-118.973099, 74.000267],
            [-118.790298, 74.107758],
            [-118.881104, 74.17804],
            [-118.673897, 74.219994],
            [-118.101898, 74.276382],
            [-117.438599, 74.229431],
            [-116.785004, 74.059708],
            [-115.894402, 73.718597],
            [-115.614197, 73.668053],
            [-115.366898, 73.545822],
            [-115.323097, 73.474426],
            [-115.834702, 73.339981],
            [-116.4561, 73.257492],
            [-117.244202, 73.054703],
            [-117.466103, 73.036377],
            [-118.366096, 72.824432],
            [-118.496101, 72.763611],
            [-118.753098, 72.736923],
            [-119.137497, 72.632477],
            [-119.183601, 72.595543],
            [-119.170799, 72.542763],
            [-119.309402, 72.438873],
            [-119.332497, 72.387207],
            [-119.311096, 72.352203],
            [-119.801399, 72.221367],
            [-120.029198, 72.219147],
            [-120.1847, 72.232758],
            [-120.139999, 72.267761],
            [-120.251099, 72.258614],
            [-120.258598, 72.234154],
            [-120.180298, 72.203873],
            [-120.144997, 72.1436],
            [-120.194199, 72.078323],
            [-120.449203, 71.94693],
            [-120.379204, 71.883881],
            [-120.426399, 71.744431],
            [-120.3769, 71.688026],
            [-120.543297, 71.516663],
            [-120.877197, 71.44136],
            [-121.429199, 71.378311],
            [-121.603104, 71.399429],
            [-121.531998, 71.417763],
            [-121.569702, 71.45166],
            [-121.743896, 71.453323],
            [-122.121696, 71.267212],
            [-122.605003, 71.174988],
            [-122.781097, 71.086113],
            [-123.095001, 71.079987],
            [-123.223297, 71.114151],
            [-123.403297, 71.211929],
            [-123.665802, 71.496368],
            [-123.801697, 71.535538],
            [-123.948898, 71.658333],
            [-125.236397, 71.94191],
            [-124.953903, 71.938026],
            [-124.939697, 71.95694],
            [-125, 71.970657],
            [-125.761398, 71.950821],
            [-125.997803, 71.973602],
            [-125.7789, 71.986366],
            [-125.715302, 72.090271],
            [-125.7883, 72.118317],
            [-125.566399, 72.178864],
            [-125.660004, 72.19136],
            [-125.6567, 72.251083],
            [-125.501701, 72.266373],
            [-125.478302, 72.299133],
            [-125.532204, 72.30191],
            [-125.391998, 72.396637],
            [-125.436699, 72.409126],
            [-125.343597, 72.414978],
            [-125.358597, 72.447189],
            [-125.300598, 72.483307],
            [-125, 72.560463],
            [-124.947998, 72.570541],
            [-124.968903, 72.60498],
            [-125.098099, 72.630814],
            [-124.940598, 72.702477],
            [-125.0186, 72.702759],
            [-124.971901, 72.755829],
            [-125.0261, 72.821091],
            [-125.114403, 72.861649],
            [-124.943001, 72.854156],
            [-124.4758, 72.9272],
            [-124.522499, 72.952766],
            [-124.4972, 72.977203],
            [-124.624702, 73.020538],
            [-124.726097, 73.006653],
            [-124.868896, 73.080551],
            [-124.785004, 73.138893],
            [-124.644699, 73.156372],
            [-124.668098, 73.192749],
            [-124.482498, 73.346649],
            [-124.4039, 73.371918],
            [-124.433098, 73.421371],
            [-124.068604, 73.535812],
            [-124.038902, 73.586929],
            [-124.078598, 73.622757],
            [-124.067802, 73.654709],
            [-123.8442, 73.696091],
            [-123.838303, 73.743874],
            [-123.774696, 73.764427],
            [-123.847801, 73.825272],
            [-124.217499, 73.872757],
            [-124.195801, 73.916382],
            [-124.352501, 73.976379],
            [-124.381104, 74.037773],
            [-124.462502, 74.069717],
            [-124.469704, 74.101089],
            [-124.426697, 74.109711],
            [-124.535301, 74.20665],
            [-124.516403, 74.25],
            [-124.693001, 74.26944],
            [-124.604202, 74.312477],
            [-124.762497, 74.315536],
            [-124.770798, 74.340271],
            [-123.253899, 74.444138],
            [-122.337502, 74.4711],
            [-121.5186, 74.548866],
            [-121.150803, 74.509987],
            [-121.002197, 74.467758],
            [-120.971703, 74.418053],
            [-120.217201, 74.282494],
            [-119.525002, 74.229713],
            [-119.6633, 74.223877],
            [-119.651398, 74.181664],
            [-119.824203, 74.094711],
            [-119.827202, 74.059143],
            [-119.744797, 74.025513],
          ],
        ],
        [
          [
            [-116.144997, 77.023323],
            [-115.7314, 76.949707],
            [-115.903297, 76.893883],
            [-116.365303, 76.926376],
            [-116.183601, 76.845833],
            [-116.000298, 76.811371],
            [-115.996696, 76.770828],
            [-115.896698, 76.69165],
            [-116.011101, 76.676376],
            [-116.093102, 76.619141],
            [-116.322502, 76.5811],
            [-117.040001, 76.537491],
            [-117.076401, 76.508881],
            [-116.983597, 76.454987],
            [-117.002502, 76.431931],
            [-116.940804, 76.386932],
            [-116.938599, 76.346367],
            [-117.319702, 76.257767],
            [-117.603104, 76.27388],
            [-117.657799, 76.29332],
            [-117.655296, 76.31749],
            [-117.813599, 76.318329],
            [-118.059998, 76.409149],
            [-118.0289, 76.424149],
            [-118.044998, 76.447197],
            [-117.990799, 76.462486],
            [-118.020599, 76.492752],
            [-117.904198, 76.52832],
            [-117.953903, 76.562759],
            [-117.908096, 76.578598],
            [-117.973297, 76.596367],
            [-117.900002, 76.636658],
            [-117.919197, 76.688026],
            [-117.734398, 76.77832],
            [-117.798897, 76.817757],
            [-117.8881, 76.818878],
            [-118.029701, 76.758614],
            [-118.337502, 76.768333],
            [-118.300301, 76.73526],
            [-118.312202, 76.705261],
            [-118.495003, 76.712196],
            [-118.458603, 76.6922],
            [-118.474701, 76.679703],
            [-118.3414, 76.6436],
            [-118.304199, 76.552467],
            [-118.4347, 76.548866],
            [-118.570801, 76.499153],
            [-118.841698, 76.554977],
            [-118.773598, 76.527206],
            [-118.9758, 76.498871],
            [-118.681999, 76.445251],
            [-118.567497, 76.336647],
            [-118.655602, 76.284149],
            [-118.9114, 76.265549],
            [-118.9422, 76.210541],
            [-118.887497, 76.173866],
            [-118.964203, 76.160812],
            [-118.964996, 76.126648],
            [-119.071404, 76.117477],
            [-119.075798, 76.083328],
            [-119.282501, 76.127472],
            [-119.301697, 76.19136],
            [-119.4561, 76.255547],
            [-119.499397, 76.361099],
            [-119.562798, 76.320831],
            [-119.713898, 76.331673],
            [-119.652802, 76.29776],
            [-119.658302, 76.268883],
            [-119.705002, 76.262207],
            [-119.565804, 76.175262],
            [-119.701103, 76.150818],
            [-119.552803, 76.117752],
            [-119.805, 76.108871],
            [-119.491402, 76.035538],
            [-119.481102, 75.970833],
            [-119.636902, 75.992203],
            [-119.7033, 75.942749],
            [-119.606903, 75.911102],
            [-119.710602, 75.921654],
            [-119.870003, 75.857483],
            [-120.069504, 75.838882],
            [-120.1492, 75.896378],
            [-120.288902, 75.816093],
            [-120.454498, 75.815811],
            [-120.4897, 75.849991],
            [-120.463097, 75.916382],
            [-120.384499, 75.967758],
            [-120.445297, 75.976646],
            [-120.4589, 76.011658],
            [-120.578102, 76.002777],
            [-120.563301, 75.985527],
            [-120.593903, 75.978043],
            [-120.696701, 76.013893],
            [-120.731102, 76.057747],
            [-120.648598, 76.099716],
            [-120.751099, 76.10582],
            [-120.659203, 76.142487],
            [-120.883598, 76.198318],
            [-121.012497, 76.13916],
            [-120.995003, 76.103592],
            [-121.022797, 76.059143],
            [-120.931999, 75.959991],
            [-120.980797, 75.94165],
            [-121.017197, 75.948029],
            [-120.999199, 75.994431],
            [-121.113899, 75.991653],
            [-121.259499, 75.964432],
            [-121.294998, 75.907494],
            [-121.423302, 75.933868],
            [-121.4953, 75.984421],
            [-121.864998, 76.042213],
            [-122.134201, 76.036377],
            [-122.132797, 75.974152],
            [-122.1978, 75.977478],
            [-122.416397, 75.928589],
            [-122.696404, 75.955551],
            [-122.728897, 75.973038],
            [-122.560501, 76.020538],
            [-122.698898, 76.018333],
            [-122.5439, 76.046944],
            [-122.4711, 76.11026],
            [-122.699997, 76.112198],
            [-122.489998, 76.141098],
            [-122.6203, 76.174423],
            [-122.842201, 76.148613],
            [-122.901901, 76.098038],
            [-123.037804, 76.084717],
            [-122.905602, 76.155258],
            [-122.9422, 76.182213],
            [-122.848602, 76.184708],
            [-122.828903, 76.233871],
            [-122.707497, 76.226089],
            [-122.636101, 76.264709],
            [-122.617798, 76.342209],
            [-121.983902, 76.444138],
            [-121.781998, 76.420258],
            [-121.533096, 76.437187],
            [-121.413902, 76.500549],
            [-121.411903, 76.553864],
            [-121.216904, 76.618591],
            [-121.1903, 76.680542],
            [-120.900299, 76.693314],
            [-120.812202, 76.737198],
            [-120.5811, 76.74942],
            [-120.401703, 76.797211],
            [-120.365303, 76.836113],
            [-120.418098, 76.875259],
            [-120.176697, 76.919708],
            [-120.188599, 76.942749],
            [-120.091103, 76.963608],
            [-120.0914, 77.003052],
            [-119.949997, 77.012497],
            [-119.8125, 77.096649],
            [-119.389198, 77.184418],
            [-119.295799, 77.276657],
            [-119.153297, 77.325821],
            [-117.867203, 77.388603],
            [-117.934998, 77.358597],
            [-117.801903, 77.367203],
            [-117.253601, 77.285538],
            [-117.023102, 77.290817],
            [-117.060799, 77.32666],
            [-117.181999, 77.340271],
            [-117.158897, 77.358871],
            [-116.795799, 77.31749],
            [-116.810799, 77.340553],
            [-116.651703, 77.388046],
            [-116.994698, 77.39444],
            [-117.150002, 77.457207],
            [-116.785301, 77.499153],
            [-116.769402, 77.516388],
            [-116.900299, 77.532211],
            [-116.416901, 77.556931],
            [-116.090599, 77.491089],
            [-116.065804, 77.47998],
            [-116.108299, 77.461113],
            [-115.753304, 77.419708],
            [-115.389198, 77.312187],
            [-115.8778, 77.215271],
            [-116.269203, 77.190262],
            [-116.290604, 77.14888],
            [-116.400002, 77.133881],
            [-116.295303, 77.113602],
            [-116.269203, 77.055817],
            [-116.144997, 77.023323],
          ],
        ],
        [
          [
            [-135.850098, 69.007401],
            [-135.648895, 68.991928],
            [-135.517807, 69.02388],
            [-135.690598, 69.012497],
            [-135.758606, 69.060257],
            [-135.934692, 69.095543],
            [-135.967194, 69.214706],
            [-135.914993, 69.257492],
            [-135.656693, 69.1436],
            [-135.411407, 69.097488],
            [-135.6306, 69.151382],
            [-135.810806, 69.242752],
            [-135.855804, 69.284714],
            [-135.803894, 69.316673],
            [-135.6595, 69.316673],
            [-135.585297, 69.223312],
            [-135.548904, 69.23526],
            [-135.610504, 69.319717],
            [-135.571899, 69.338593],
            [-135.170593, 69.257767],
            [-135.158401, 69.274429],
            [-135.240799, 69.308319],
            [-135.287201, 69.419144],
            [-135.150803, 69.476929],
            [-135.107803, 69.457764],
            [-134.790604, 69.498322],
            [-134.468597, 69.444427],
            [-134.407806, 69.474152],
            [-134.417801, 69.500549],
            [-134.507004, 69.515549],
            [-134.548599, 69.472763],
            [-134.596405, 69.514427],
            [-134.576401, 69.54776],
            [-134.468903, 69.542763],
            [-134.492798, 69.560532],
            [-134.401703, 69.638321],
            [-134.491104, 69.722214],
            [-134.312195, 69.7211],
            [-134.351395, 69.668869],
            [-134.203903, 69.668869],
            [-134.147003, 69.6297],
            [-134.242203, 69.568047],
            [-134.191101, 69.59082],
            [-134.127106, 69.542862],
            [-134.020004, 69.559418],
            [-134.001404, 69.546371],
            [-134.048096, 69.526932],
            [-133.918304, 69.508331],
            [-133.805603, 69.539429],
            [-133.827194, 69.559982],
            [-133.778397, 69.576393],
            [-133.748306, 69.542213],
            [-133.789703, 69.531097],
            [-133.778107, 69.487762],
            [-134.029694, 69.382477],
            [-134.097504, 69.328598],
            [-134.099197, 69.293869],
            [-134.211105, 69.276093],
            [-134.275604, 69.226089],
            [-134.280304, 69.180267],
            [-134.383606, 69.118317],
            [-134.5681, 69.106644],
            [-134.675797, 69.011383],
            [-134.666702, 68.9561],
            [-134.488602, 68.870247],
            [-134.417206, 68.784416],
            [-134.287506, 68.750816],
            [-134.227295, 68.696426],
            [-134.457504, 68.719147],
            [-134.467804, 68.762497],
            [-134.559402, 68.798866],
            [-134.606903, 68.862762],
            [-134.834198, 68.987488],
            [-134.914703, 68.96666],
            [-134.920303, 68.914703],
            [-134.961105, 68.892487],
            [-135.141998, 68.901382],
            [-135.201706, 68.932747],
            [-135.421402, 68.928864],
            [-135.4608, 68.942749],
            [-135.455307, 68.977768],
            [-135.534698, 68.944702],
            [-135.522202, 68.908043],
            [-135.807999, 68.895264],
            [-136.005295, 68.951927],
            [-135.9189, 68.958603],
            [-135.987503, 69.011932],
            [-135.968597, 69.044434],
            [-135.850098, 69.007401],
          ],
        ],
        [
          [
            [-117.623001, 76.114433],
            [-117.471397, 76.088882],
            [-117.489197, 76.046944],
            [-117.681099, 75.921097],
            [-117.838303, 75.859993],
            [-117.823898, 75.83194],
            [-117.952202, 75.771927],
            [-117.927498, 75.734421],
            [-118.263603, 75.616928],
            [-118.203903, 75.598877],
            [-118.604698, 75.496368],
            [-118.927498, 75.529427],
            [-118.873596, 75.547493],
            [-118.928001, 75.562759],
            [-119.196999, 75.562477],
            [-119.408096, 75.60582],
            [-118.619202, 75.915543],
            [-118.367798, 75.966377],
            [-118.152199, 75.971367],
            [-118.071404, 76.034149],
            [-117.933601, 76.047493],
            [-117.7789, 76.108597],
            [-117.623001, 76.114433],
          ],
        ],
        [
          [
            [-114.193001, 77.698029],
            [-114.634697, 77.757767],
            [-114.521698, 77.771103],
            [-115.116096, 77.958328],
            [-114.952202, 77.949417],
            [-114.6875, 78.024696],
            [-114.286903, 78.066093],
            [-114.350601, 78.032494],
            [-114.270599, 78.012772],
            [-114.311096, 78.004707],
            [-114.089203, 77.986366],
            [-113.958298, 77.914993],
            [-113.706398, 77.891663],
            [-113.677498, 77.846367],
            [-113.576103, 77.814148],
            [-113.7883, 77.745247],
            [-114.193001, 77.698029],
          ],
        ],
        [
          [
            [-114.054703, 76.703598],
            [-114.875801, 76.770828],
            [-114.6064, 76.865807],
            [-113.807503, 76.889427],
            [-113.498001, 76.833328],
            [-113.453903, 76.772774],
            [-113.652496, 76.704437],
            [-114.054703, 76.703598],
          ],
        ],
        [
          [
            [-120.867798, 75.913307],
            [-120.898399, 75.825821],
            [-121.032799, 75.737762],
            [-121.2883, 75.752777],
            [-121.029404, 75.811371],
            [-120.998299, 75.867752],
            [-121.047501, 75.899986],
            [-120.997803, 75.926376],
            [-120.8778, 75.936096],
            [-120.867798, 75.913307],
          ],
        ],
        [
          [
            [-112.237198, 70.362488],
            [-111.6717, 70.306641],
            [-112.090302, 70.295822],
            [-112.317802, 70.345543],
            [-112.237198, 70.362488],
          ],
        ],
        [
          [
            [-109.882797, 68.114151],
            [-109.932198, 68.079163],
            [-110.258598, 68.041931],
            [-109.9217, 68.134163],
            [-109.882797, 68.114151],
          ],
        ],
        [
          [
            [-128.120193, 70.597214],
            [-128.144196, 70.568047],
            [-128.2314, 70.598328],
            [-128.242203, 70.570267],
            [-128.341705, 70.542213],
            [-128.234207, 70.656097],
            [-128.094696, 70.620529],
            [-128.120193, 70.597214],
          ],
        ],
        [
          [
            [-117.183296, 70.537491],
            [-117.301697, 70.56192],
            [-117.199402, 70.59166],
            [-116.879402, 70.547493],
            [-117.183296, 70.537491],
          ],
        ],
        [
          [
            [-135.297501, 69.304977],
            [-135.565598, 69.390549],
            [-135.337799, 69.388603],
            [-135.339401, 69.351089],
            [-135.272202, 69.358322],
            [-135.297501, 69.304977],
          ],
        ],
        [
          [
            [-122.633301, 75.919708],
            [-122.324203, 75.899719],
            [-122.340797, 75.862762],
            [-122.398598, 75.859421],
            [-122.691704, 75.900269],
            [-122.633301, 75.919708],
          ],
        ],
        [
          [
            [-124.307503, 73.632202],
            [-124.113899, 73.564148],
            [-124.343597, 73.559982],
            [-124.358597, 73.630257],
            [-124.307503, 73.632202],
          ],
        ],
        [
          [
            [-113.778603, 77.104156],
            [-113.931396, 77.1297],
            [-113.699203, 77.144707],
            [-113.6642, 77.123032],
            [-113.778603, 77.104156],
          ],
        ],
      ],
    },
    id: 'CA_NT',
    properties: {
      name: 'Northwest Territories',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-64.482773, 47.91777],
            [-64.514717, 47.8325],
            [-64.605003, 47.748329],
            [-64.646667, 47.72887],
            [-64.690552, 47.753052],
            [-64.646118, 47.788601],
            [-64.702789, 47.823608],
            [-64.667503, 47.86694],
            [-64.609161, 47.88694],
            [-64.533333, 47.864159],
            [-64.482773, 47.91777],
          ],
        ],
        [
          [
            [-69.039711, 47.29715],
            [-68.576111, 47.424709],
            [-68.38028, 47.558601],
            [-68.378052, 47.65416],
            [-68.372772, 47.924709],
            [-68.12027, 47.926659],
            [-68.116096, 48.001942],
            [-67.612213, 47.99749],
            [-67.604172, 47.9361],
            [-67.364166, 47.841381],
            [-67.322777, 47.896389],
            [-67.188599, 47.883881],
            [-67.047234, 47.93082],
            [-66.946953, 47.899158],
            [-66.943604, 47.963051],
            [-66.843697, 47.996651],
            [-66.75, 47.979988],
            [-66.356659, 48.07333],
            [-66.339172, 48.013611],
            [-65.79361, 47.890831],
            [-65.628601, 47.66972],
            [-65.671387, 47.651661],
            [-65.634743, 47.620831],
            [-65.606659, 47.659988],
            [-65.476387, 47.68082],
            [-65.240829, 47.80666],
            [-65.044724, 47.84444],
            [-64.976936, 47.838039],
            [-65.071953, 47.776939],
            [-64.823624, 47.811378],
            [-64.786118, 47.772221],
            [-64.846947, 47.744438],
            [-64.791382, 47.742222],
            [-64.770279, 47.706661],
            [-64.733322, 47.76194],
            [-64.676666, 47.73555],
            [-64.8125, 47.674999],
            [-64.818619, 47.608051],
            [-64.89389, 47.556938],
            [-64.901108, 47.513329],
            [-64.87027, 47.51083],
            [-64.928329, 47.450829],
            [-64.866943, 47.484718],
            [-64.880829, 47.432499],
            [-64.910004, 47.35305],
            [-64.904167, 47.393051],
            [-64.941101, 47.373878],
            [-64.96611, 47.28944],
            [-65.370003, 47.086651],
            [-65.204727, 47.087769],
            [-65.212509, 47.044998],
            [-65.025284, 47.09193],
            [-64.979446, 47.05555],
            [-64.93277, 47.08416],
            [-64.798607, 47.07999],
            [-64.825844, 47.049999],
            [-64.803329, 46.987499],
            [-64.88028, 46.93055],
            [-64.904999, 46.851101],
            [-64.873047, 46.782211],
            [-64.788597, 46.724159],
            [-64.853333, 46.70499],
            [-64.854446, 46.672218],
            [-64.817497, 46.696381],
            [-64.752792, 46.663601],
            [-64.748611, 46.70277],
            [-64.711121, 46.679989],
            [-64.723618, 46.580551],
            [-64.673317, 46.500832],
            [-64.711403, 46.479988],
            [-64.611938, 46.40971],
            [-64.631943, 46.338039],
            [-64.701683, 46.32666],
            [-64.579178, 46.328049],
            [-64.551666, 46.363331],
            [-64.517227, 46.320831],
            [-64.568619, 46.287498],
            [-64.559433, 46.21999],
            [-64.518341, 46.242771],
            [-64.253891, 46.23444],
            [-64.154167, 46.19305],
            [-64.146118, 46.157211],
            [-64.116943, 46.181938],
            [-64.081947, 46.151379],
            [-64.057503, 46.180271],
            [-63.970829, 46.18055],
            [-63.77639, 46.121101],
            [-63.919449, 46.053051],
            [-64.068069, 46.059429],
            [-64.093887, 46.02166],
            [-64.042793, 45.991901],
            [-64.154999, 45.96888],
            [-64.272926, 45.835751],
            [-64.361389, 45.879162],
            [-64.339722, 45.859718],
            [-64.481949, 45.72332],
            [-64.538887, 45.723049],
            [-64.488052, 45.801102],
            [-64.534157, 45.898048],
            [-64.549728, 45.86805],
            [-64.576401, 45.885551],
            [-64.681381, 45.994709],
            [-64.690552, 46.050831],
            [-64.754463, 46.089161],
            [-64.699432, 46.035],
            [-64.70639, 45.994709],
            [-64.573624, 45.863331],
            [-64.672501, 45.750271],
            [-64.642227, 45.717209],
            [-64.693047, 45.716099],
            [-64.778343, 45.60722],
            [-64.904167, 45.627769],
            [-65.015007, 45.548882],
            [-65.326683, 45.4575],
            [-65.739723, 45.24527],
            [-65.799156, 45.25666],
            [-65.918877, 45.2061],
            [-65.999161, 45.2286],
            [-66.039169, 45.28833],
            [-66.126389, 45.304161],
            [-66.015289, 45.377769],
            [-66.002502, 45.461658],
            [-66.088333, 45.35527],
            [-66.193329, 45.333599],
            [-66.145279, 45.27916],
            [-66.05777, 45.2575],
            [-66.21666, 45.159161],
            [-66.245537, 45.208889],
            [-66.321953, 45.19804],
            [-66.253342, 45.191662],
            [-66.25, 45.161381],
            [-66.34584, 45.14444],
            [-66.440552, 45.07333],
            [-66.496384, 45.149719],
            [-66.648064, 45.083599],
            [-66.722229, 45.089161],
            [-66.75473, 45.05555],
            [-66.796951, 45.05888],
            [-66.790558, 45.102779],
            [-66.87999, 45.057209],
            [-66.907784, 45.111382],
            [-66.84584, 45.132488],
            [-66.949432, 45.187489],
            [-66.987213, 45.153881],
            [-67.027222, 45.168049],
            [-67.033333, 45.0886],
            [-67.122223, 45.1586],
            [-67.127213, 45.220268],
            [-67.182503, 45.244709],
            [-67.15123, 45.12191],
            [-67.166473, 45.156189],
            [-67.274658, 45.182709],
            [-67.346169, 45.122181],
            [-67.439987, 45.18951],
            [-67.478508, 45.280209],
            [-67.419113, 45.375778],
            [-67.504662, 45.485748],
            [-67.416641, 45.50349],
            [-67.439857, 45.592491],
            [-67.615692, 45.605129],
            [-67.718582, 45.68124],
            [-67.753502, 45.659229],
            [-67.803978, 45.678051],
            [-67.803596, 45.794449],
            [-67.759911, 45.82774],
            [-67.795113, 45.878422],
            [-67.756157, 45.916519],
            [-67.78083, 45.94701],
            [-67.79155, 47.060959],
            [-68.231323, 47.352112],
            [-68.335327, 47.35733],
            [-68.391769, 47.285061],
            [-68.515182, 47.296921],
            [-68.895393, 47.18222],
            [-69.037231, 47.257332],
            [-69.039711, 47.29715],
          ],
        ],
      ],
    },
    id: 'CA_NB',
    properties: {
      name: 'New Brunswick',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-60.910278, 45.546101],
            [-61.000278, 45.45583],
            [-61.021111, 45.46888],
            [-60.99889, 45.49361],
            [-61.072231, 45.502491],
            [-61.108341, 45.540829],
            [-61.01722, 45.575272],
            [-60.90028, 45.57555],
            [-60.878609, 45.55582],
            [-60.910278, 45.546101],
          ],
        ],
        [
          [
            [-66.764717, 44.801102],
            [-66.734734, 44.72916],
            [-66.761124, 44.676102],
            [-66.900284, 44.613331],
            [-66.858612, 44.74305],
            [-66.764717, 44.801102],
          ],
        ],
        [
          [
            [-60.59639, 46.196098],
            [-60.46806, 46.316669],
            [-60.416389, 46.328049],
            [-60.419998, 46.279991],
            [-60.64584, 46.106098],
            [-60.870281, 46.051659],
            [-60.84972, 46.07888],
            [-60.880001, 46.079159],
            [-60.97472, 45.988331],
            [-61.11306, 45.955269],
            [-61.125561, 45.923611],
            [-60.993328, 45.94138],
            [-60.875561, 46.036942],
            [-60.734169, 46.047489],
            [-60.80389, 45.9786],
            [-60.807499, 45.93111],
            [-61.066391, 45.893879],
            [-61.043892, 45.88472],
            [-61.08778, 45.847488],
            [-60.96806, 45.883331],
            [-60.917782, 45.847488],
            [-60.97028, 45.856659],
            [-60.940552, 45.821659],
            [-61.01083, 45.820271],
            [-61.118061, 45.763611],
            [-61.142502, 45.696381],
            [-61.070839, 45.689991],
            [-60.91444, 45.753609],
            [-60.797779, 45.707211],
            [-60.844158, 45.64138],
            [-60.76112, 45.69054],
            [-60.733608, 45.67416],
            [-60.74889, 45.74194],
            [-60.719719, 45.78833],
            [-60.39584, 45.996101],
            [-60.686111, 45.88694],
            [-60.785278, 45.946381],
            [-60.305, 46.210548],
            [-60.29528, 46.232208],
            [-60.372768, 46.22332],
            [-60.547501, 46.10416],
            [-60.656109, 46.067768],
            [-60.347778, 46.310551],
            [-60.285278, 46.321381],
            [-60.203331, 46.235271],
            [-60.28944, 46.154709],
            [-60.24028, 46.173611],
            [-60.199169, 46.143608],
            [-60.194439, 46.20277],
            [-60.131939, 46.2486],
            [-60.041672, 46.227772],
            [-60.076389, 46.199162],
            [-59.95055, 46.201389],
            [-59.94138, 46.151661],
            [-59.810551, 46.162769],
            [-59.88805, 46.088879],
            [-59.809719, 46.10638],
            [-59.959999, 46.02055],
            [-59.82362, 45.99749],
            [-59.818611, 45.929161],
            [-59.973331, 45.901379],
            [-59.99416, 45.8661],
            [-60.13028, 45.867771],
            [-60.160549, 45.835548],
            [-60.07362, 45.796391],
            [-60.17445, 45.763889],
            [-60.233891, 45.701099],
            [-60.673061, 45.564159],
            [-60.853889, 45.603882],
            [-60.871498, 45.639721],
            [-61.168331, 45.55138],
            [-61.14584, 45.579441],
            [-61.21722, 45.606941],
            [-61.285, 45.5425],
            [-61.45472, 45.705551],
            [-61.547501, 46.025551],
            [-61.482769, 46.066669],
            [-61.438889, 46.159431],
            [-61.283329, 46.23888],
            [-61.089439, 46.45805],
            [-61.033058, 46.561661],
            [-61.058048, 46.5961],
            [-61.034451, 46.628601],
            [-61.013889, 46.59166],
            [-60.892231, 46.77388],
            [-60.704441, 46.89138],
            [-60.640282, 47],
            [-60.591942, 47.033329],
            [-60.488609, 46.99416],
            [-60.39806, 47.023319],
            [-60.384998, 46.996101],
            [-60.498051, 46.89666],
            [-60.45084, 46.89777],
            [-60.445271, 46.86166],
            [-60.326111, 46.86832],
            [-60.302219, 46.83749],
            [-60.346951, 46.685551],
            [-60.396111, 46.665821],
            [-60.35445, 46.648609],
            [-60.384731, 46.635551],
            [-60.345001, 46.60638],
            [-60.589439, 46.255001],
            [-60.59639, 46.196098],
          ],
        ],
        [
          [
            [-64.042793, 45.991901],
            [-63.913059, 45.979988],
            [-63.80389, 45.88583],
            [-63.725769, 45.869961],
            [-63.749168, 45.835819],
            [-63.682499, 45.848598],
            [-63.664162, 45.815269],
            [-63.66222, 45.870541],
            [-63.482498, 45.877209],
            [-63.403881, 45.854439],
            [-63.482769, 45.858051],
            [-63.428612, 45.823608],
            [-63.51556, 45.807209],
            [-63.235001, 45.808601],
            [-63.380001, 45.766109],
            [-63.294449, 45.721931],
            [-63.186939, 45.772221],
            [-63.18972, 45.73444],
            [-63.065552, 45.75555],
            [-63.10778, 45.80888],
            [-62.672779, 45.767769],
            [-62.753891, 45.758049],
            [-62.63612, 45.714439],
            [-62.775002, 45.671101],
            [-62.791111, 45.638329],
            [-62.743061, 45.654991],
            [-62.743889, 45.611111],
            [-62.703892, 45.652489],
            [-62.650829, 45.62804],
            [-62.675282, 45.671379],
            [-62.60944, 45.684158],
            [-62.47361, 45.655548],
            [-62.509731, 45.6161],
            [-62.486389, 45.603611],
            [-62.356949, 45.67194],
            [-62.424721, 45.673321],
            [-62.250278, 45.708328],
            [-61.9175, 45.885551],
            [-61.88139, 45.686649],
            [-61.724998, 45.620831],
            [-61.6325, 45.637772],
            [-61.65556, 45.624161],
            [-61.618061, 45.61055],
            [-61.564999, 45.673882],
            [-61.471378, 45.682499],
            [-61.332779, 45.540829],
            [-61.22916, 45.501942],
            [-61.249729, 45.447491],
            [-61.48167, 45.367771],
            [-61.457218, 45.343609],
            [-61.257778, 45.33416],
            [-60.970551, 45.321659],
            [-60.97028, 45.269711],
            [-61.036388, 45.293049],
            [-61.02306, 45.25639],
            [-61.077782, 45.21944],
            [-61.139721, 45.210819],
            [-61.121941, 45.27166],
            [-61.171391, 45.255268],
            [-61.1675, 45.20388],
            [-61.204441, 45.246101],
            [-61.268059, 45.254711],
            [-61.360001, 45.209991],
            [-61.349442, 45.174438],
            [-61.458611, 45.144711],
            [-61.595829, 45.143051],
            [-61.69611, 45.177769],
            [-61.63805, 45.12027],
            [-61.672501, 45.114159],
            [-61.642231, 45.08416],
            [-61.819729, 45.104439],
            [-61.81361, 45.073879],
            [-61.85944, 45.075001],
            [-61.834999, 45.051102],
            [-61.88306, 45.024441],
            [-61.932499, 45.046391],
            [-62.051109, 45.00639],
            [-61.98138, 44.983051],
            [-62.240002, 44.95805],
            [-62.337219, 44.921101],
            [-62.338612, 44.888599],
            [-62.381111, 44.913601],
            [-62.457218, 44.82972],
            [-62.476391, 44.89555],
            [-62.515011, 44.85194],
            [-62.551392, 44.861382],
            [-62.535561, 44.798611],
            [-62.61195, 44.839161],
            [-62.65361, 44.791382],
            [-62.801109, 44.778599],
            [-62.825562, 44.744438],
            [-62.796108, 44.723881],
            [-62.840279, 44.711109],
            [-62.928612, 44.733879],
            [-62.99778, 44.703609],
            [-63.003059, 44.765831],
            [-63.055561, 44.77277],
            [-63.011951, 44.70805],
            [-63.054722, 44.673321],
            [-63.118889, 44.786659],
            [-63.14278, 44.688599],
            [-63.163891, 44.725552],
            [-63.212219, 44.673611],
            [-63.245831, 44.732491],
            [-63.240002, 44.676941],
            [-63.283058, 44.627209],
            [-63.40361, 44.641659],
            [-63.406391, 44.683331],
            [-63.451389, 44.672218],
            [-63.41222, 44.63221],
            [-63.439991, 44.590832],
            [-63.658329, 44.715],
            [-63.64056, 44.67305],
            [-63.562222, 44.63221],
            [-63.520279, 44.507771],
            [-63.570839, 44.461941],
            [-63.59972, 44.483601],
            [-63.631111, 44.435822],
            [-63.633888, 44.473881],
            [-63.713058, 44.454159],
            [-63.763618, 44.49749],
            [-63.79834, 44.46944],
            [-63.783329, 44.527489],
            [-63.8675, 44.489429],
            [-63.932499, 44.513329],
            [-63.937771, 44.621658],
            [-63.887218, 44.69054],
            [-64.059433, 44.638329],
            [-64.063889, 44.590549],
            [-64.009171, 44.513329],
            [-64.046951, 44.518879],
            [-64.083069, 44.46666],
            [-64.121658, 44.485271],
            [-64.121658, 44.544159],
            [-64.170273, 44.586109],
            [-64.224442, 44.533878],
            [-64.301941, 44.561378],
            [-64.324448, 44.47332],
            [-64.297501, 44.463879],
            [-64.355827, 44.44276],
            [-64.330841, 44.408878],
            [-64.256958, 44.408039],
            [-64.305267, 44.376099],
            [-64.211403, 44.361938],
            [-64.346123, 44.35722],
            [-64.335564, 44.32666],
            [-64.260559, 44.324169],
            [-64.289993, 44.301929],
            [-64.239441, 44.294159],
            [-64.258057, 44.269989],
            [-64.319458, 44.264721],
            [-64.430557, 44.338039],
            [-64.358612, 44.28833],
            [-64.363617, 44.25666],
            [-64.428047, 44.228321],
            [-64.494164, 44.14027],
            [-64.604172, 44.156651],
            [-64.616386, 44.133049],
            [-64.543327, 44.11639],
            [-64.556381, 44.077221],
            [-64.715561, 44.052219],
            [-64.663063, 44.02388],
            [-64.666397, 43.990269],
            [-64.845284, 43.92194],
            [-64.785553, 43.867771],
            [-64.820847, 43.864719],
            [-64.835007, 43.825001],
            [-64.907784, 43.87833],
            [-64.880547, 43.833881],
            [-64.906387, 43.800541],
            [-64.96611, 43.872761],
            [-64.928596, 43.802219],
            [-64.965286, 43.751659],
            [-65.040283, 43.82666],
            [-65.008347, 43.746941],
            [-65.025558, 43.70805],
            [-65.074448, 43.698879],
            [-65.083618, 43.752491],
            [-65.122498, 43.69305],
            [-65.12471, 43.670551],
            [-65.152786, 43.68721],
            [-65.138901, 43.74416],
            [-65.180557, 43.706661],
            [-65.237213, 43.798611],
            [-65.256668, 43.770828],
            [-65.223618, 43.7075],
            [-65.285004, 43.66972],
            [-65.32695, 43.697491],
            [-65.332497, 43.7686],
            [-65.337784, 43.73555],
            [-65.368332, 43.734989],
            [-65.325844, 43.674999],
            [-65.360001, 43.60527],
            [-65.338608, 43.549438],
            [-65.450287, 43.59972],
            [-65.404449, 43.502781],
            [-65.449158, 43.559719],
            [-65.486938, 43.5186],
            [-65.481377, 43.464439],
            [-65.568619, 43.570271],
            [-65.657501, 43.490269],
            [-65.72583, 43.502491],
            [-65.739166, 43.573051],
            [-65.755569, 43.54916],
            [-65.783333, 43.57111],
            [-65.775558, 43.68832],
            [-65.793327, 43.61166],
            [-65.818336, 43.630268],
            [-65.860817, 43.80444],
            [-65.87471, 43.763611],
            [-65.93277, 43.827221],
            [-65.962784, 43.774712],
            [-65.982224, 43.843319],
            [-66.004997, 43.840549],
            [-65.965843, 43.73082],
            [-66.020554, 43.691101],
            [-66.033623, 43.740269],
            [-66.062767, 43.716381],
            [-66.080841, 43.768051],
            [-66.123047, 43.740551],
            [-66.136948, 43.799999],
            [-66.108337, 43.841099],
            [-66.147232, 43.824169],
            [-66.16806, 43.863049],
            [-66.149727, 44.011108],
            [-66.21167, 44.095268],
            [-66.134453, 44.22971],
            [-66.118607, 44.338039],
            [-65.841377, 44.574169],
            [-65.943047, 44.57777],
            [-66.190552, 44.383331],
            [-66.191101, 44.423321],
            [-66.103058, 44.5],
            [-65.785004, 44.69054],
            [-65.753067, 44.605831],
            [-65.684998, 44.61694],
            [-65.428329, 44.776661],
            [-65.721657, 44.66777],
            [-65.75473, 44.67749],
            [-65.741096, 44.707211],
            [-64.861938, 45.139721],
            [-64.434433, 45.2575],
            [-64.393066, 45.303322],
            [-64.489166, 45.33527],
            [-64.327499, 45.303322],
            [-64.368881, 45.197491],
            [-64.353882, 45.165272],
            [-64.385834, 45.14555],
            [-64.359734, 45.097488],
            [-64.324387, 45.139339],
            [-64.244164, 45.123878],
            [-64.157227, 45.056938],
            [-64.156387, 44.978321],
            [-64.103882, 44.992222],
            [-64.098892, 45.057499],
            [-64.198608, 45.11721],
            [-64.152786, 45.19276],
            [-63.854721, 45.269161],
            [-63.78973, 45.311661],
            [-63.539169, 45.333328],
            [-63.470829, 45.29055],
            [-63.46666, 45.326389],
            [-63.360828, 45.360821],
            [-63.755562, 45.398048],
            [-63.883888, 45.350552],
            [-63.923611, 45.388329],
            [-64.069733, 45.410271],
            [-64.385559, 45.3661],
            [-64.563316, 45.411659],
            [-64.701401, 45.368599],
            [-64.698608, 45.326099],
            [-64.76001, 45.28944],
            [-64.763062, 45.326099],
            [-64.815826, 45.348598],
            [-64.93721, 45.326939],
            [-64.909157, 45.418049],
            [-64.458054, 45.674438],
            [-64.436661, 45.73999],
            [-64.375549, 45.7925],
            [-64.296661, 45.8186],
            [-64.335564, 45.740829],
            [-64.266663, 45.761108],
            [-64.30249, 45.772491],
            [-64.272926, 45.835751],
            [-64.154999, 45.96888],
            [-64.042793, 45.991901],
          ],
        ],
      ],
    },
    id: 'CA_NS',
    properties: {
      name: 'Nova Scotia',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-102.001701, 59.99992],
            [-102.0009, 59.918839],
            [-102.001503, 58.12854],
            [-102.001297, 58.034302],
            [-102.001297, 57.868439],
            [-102.0009, 57.49725],
            [-102.001297, 57.25964],
            [-102.001099, 57.013351],
            [-102.000999, 56.366421],
            [-102.001404, 56.221241],
            [-102.0009, 55.830151],
            [-101.967598, 55.76067],
            [-101.967201, 55.67194],
            [-101.967201, 55.467468],
            [-101.925797, 55.466381],
            [-101.925598, 55.117489],
            [-101.883598, 55.116661],
            [-101.883904, 54.76749],
            [-101.851402, 54.766392],
            [-101.851799, 54.53513],
            [-101.851898, 54.41721],
            [-101.811401, 54.41666],
            [-101.810799, 54.066101],
            [-101.7714, 54.06638],
            [-101.772102, 54.00256],
            [-101.742798, 53.369438],
            [-101.700798, 53.368881],
            [-101.672203, 52.67083],
            [-101.639999, 52.671101],
            [-101.639503, 52.32222],
            [-101.612503, 52.32222],
            [-101.613297, 51.96999],
            [-101.567001, 51.970829],
            [-101.544998, 50.950001],
            [-101.514702, 50.949429],
            [-101.514999, 50.595539],
            [-101.484703, 50.589989],
            [-101.483597, 50.24192],
            [-101.4533, 50.241661],
            [-101.453598, 49.888599],
            [-101.426102, 49.888599],
            [-101.425598, 49.5341],
            [-101.400299, 49.533051],
            [-101.400299, 49.177761],
            [-101.3675, 49.17749],
            [-101.367203, 49],
            [-101.5, 49],
            [-102.021797, 49],
            [-102.9375, 49],
            [-104.0625, 49],
            [-105.0625, 49],
            [-106.125, 49],
            [-107.1875, 49],
            [-108.25, 49],
            [-109.5, 49],
            [-110.001404, 49],
            [-110.000504, 54.00573],
            [-110.000702, 54.44952],
            [-110.000603, 54.624401],
            [-110.000801, 54.769001],
            [-110.000603, 56.276039],
            [-110.0009, 56.36282],
            [-110.000801, 58.968639],
            [-110.000504, 59.309391],
            [-110.0009, 59.995281],
            [-107.113602, 59.994709],
            [-106.255997, 59.999771],
            [-106.044403, 59.999592],
            [-105.859001, 59.99968],
            [-105.5411, 60],
            [-104.7099, 59.999771],
            [-104.270699, 59.9995],
            [-102.001701, 59.99992],
          ],
        ],
      ],
    },
    id: 'CA_SK',
    properties: {
      name: 'Saskatchewan',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-114.060303, 49],
            [-114.035301, 49.031109],
            [-114.1567, 49.103611],
            [-114.154198, 49.148048],
            [-114.225304, 49.188599],
            [-114.372803, 49.204159],
            [-114.376701, 49.25639],
            [-114.429398, 49.265549],
            [-114.476097, 49.341381],
            [-114.576401, 49.389992],
            [-114.599998, 49.442211],
            [-114.576401, 49.556099],
            [-114.6894, 49.547218],
            [-114.738297, 49.608601],
            [-114.679703, 49.63221],
            [-114.636101, 49.727772],
            [-114.626099, 49.797218],
            [-114.6875, 49.913601],
            [-114.684998, 49.9575],
            [-114.643303, 49.977772],
            [-114.658897, 50.065269],
            [-114.724197, 50.125549],
            [-114.754997, 50.288891],
            [-114.793602, 50.334721],
            [-114.770302, 50.35833],
            [-114.8367, 50.39666],
            [-115.016998, 50.575001],
            [-115.1017, 50.588329],
            [-115.218903, 50.54694],
            [-115.320602, 50.638599],
            [-115.283096, 50.658878],
            [-115.311699, 50.706661],
            [-115.346703, 50.732208],
            [-115.395798, 50.71693],
            [-115.419197, 50.754169],
            [-115.527802, 50.791939],
            [-115.647202, 50.85471],
            [-115.571999, 50.90332],
            [-115.618599, 50.969151],
            [-115.759697, 51.035831],
            [-115.776703, 51.073608],
            [-115.957802, 51.103882],
            [-116.016098, 51.138329],
            [-116.0261, 51.21693],
            [-116.060501, 51.24638],
            [-116.262199, 51.316669],
            [-116.306099, 51.388329],
            [-116.305, 51.461658],
            [-116.3797, 51.488602],
            [-116.399696, 51.54472],
            [-116.466103, 51.56638],
            [-116.488098, 51.613049],
            [-116.595001, 51.65749],
            [-116.597504, 51.716381],
            [-116.658897, 51.796661],
            [-116.746101, 51.79916],
            [-116.815804, 51.750549],
            [-116.812798, 51.715271],
            [-116.919197, 51.70916],
            [-117.0392, 51.908039],
            [-117.269997, 52.044998],
            [-117.318901, 52.181938],
            [-117.363098, 52.138599],
            [-117.600304, 52.13055],
            [-117.6875, 52.196098],
            [-117.760002, 52.200829],
            [-117.829697, 52.27277],
            [-117.7314, 52.344151],
            [-117.7547, 52.403599],
            [-117.902802, 52.424999],
            [-118.0056, 52.48777],
            [-118.047501, 52.404991],
            [-118.145798, 52.39888],
            [-118.223099, 52.365551],
            [-118.259697, 52.442211],
            [-118.208298, 52.48082],
            [-118.286903, 52.527771],
            [-118.2817, 52.565269],
            [-118.349998, 52.61805],
            [-118.305, 52.674709],
            [-118.3461, 52.74332],
            [-118.428902, 52.78611],
            [-118.415604, 52.83638],
            [-118.491402, 52.89777],
            [-118.625, 52.884159],
            [-118.679703, 52.970539],
            [-118.669998, 53.034439],
            [-118.781097, 53.05582],
            [-118.759697, 53.12471],
            [-118.9786, 53.237209],
            [-119.012199, 53.223049],
            [-119.013603, 53.144711],
            [-119.045502, 53.137772],
            [-119.265602, 53.196659],
            [-119.348099, 53.275269],
            [-119.385803, 53.36166],
            [-119.606102, 53.37804],
            [-119.738098, 53.395],
            [-119.778099, 53.446941],
            [-119.842499, 53.518051],
            [-119.902496, 53.520271],
            [-119.882797, 53.560268],
            [-119.932198, 53.60722],
            [-119.7686, 53.603321],
            [-119.735298, 53.634991],
            [-119.810303, 53.707771],
            [-119.921097, 53.718319],
            [-119.904701, 53.778599],
            [-120.000801, 53.808041],
            [-120.000801, 54.005871],
            [-119.982903, 59.988522],
            [-110.0009, 59.995281],
            [-110.000504, 59.309391],
            [-110.000801, 58.968639],
            [-110.0009, 56.36282],
            [-110.000603, 56.276039],
            [-110.000801, 54.769001],
            [-110.000603, 54.624401],
            [-110.000702, 54.44952],
            [-110.000504, 54.00573],
            [-110.001404, 49],
            [-110.75, 49],
            [-111.281898, 49],
            [-112.1875, 49],
            [-114.060303, 49],
          ],
        ],
      ],
    },
    id: 'CA_AB',
    properties: {
      name: 'Alberta',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-63.98917, 46.393608],
            [-64.13501, 46.416382],
            [-64.105827, 46.445541],
            [-64.113327, 46.534721],
            [-64.058037, 46.569439],
            [-64.071404, 46.63583],
            [-64.133331, 46.591381],
            [-64.223618, 46.629711],
            [-64.387512, 46.620831],
            [-64.416656, 46.68111],
            [-64.226936, 46.906101],
            [-63.99889, 47.061661],
            [-63.967499, 46.891659],
            [-64.091667, 46.778599],
            [-64.067497, 46.749161],
            [-63.97472, 46.742489],
            [-63.980831, 46.714161],
            [-63.882221, 46.64555],
            [-63.904171, 46.603882],
            [-63.8325, 46.61721],
            [-63.833328, 46.577492],
            [-63.9375, 46.47665],
            [-63.831669, 46.516392],
            [-63.811668, 46.49527],
            [-63.840839, 46.464439],
            [-63.709438, 46.437489],
            [-63.700279, 46.466381],
            [-63.740002, 46.496101],
            [-63.70055, 46.515549],
            [-63.723331, 46.54361],
            [-63.647221, 46.567211],
            [-63.490841, 46.524712],
            [-63.496948, 46.488331],
            [-63.45472, 46.461658],
            [-63.422501, 46.495831],
            [-63.4575, 46.509991],
            [-63.320278, 46.492771],
            [-63.238892, 46.399719],
            [-63.210831, 46.429161],
            [-62.587502, 46.42527],
            [-62.692501, 46.4561],
            [-62.477219, 46.477772],
            [-62.166389, 46.486111],
            [-61.970551, 46.45694],
            [-62.173328, 46.34943],
            [-62.285839, 46.377491],
            [-62.272221, 46.338329],
            [-62.35778, 46.355831],
            [-62.334721, 46.311939],
            [-62.400551, 46.306938],
            [-62.35973, 46.282768],
            [-62.37389, 46.262211],
            [-62.494999, 46.271381],
            [-62.437222, 46.251659],
            [-62.424721, 46.215549],
            [-62.58527, 46.232208],
            [-62.498051, 46.182499],
            [-62.589439, 46.206379],
            [-62.56834, 46.186649],
            [-62.603889, 46.177219],
            [-62.534168, 46.16777],
            [-62.542229, 46.12249],
            [-62.478329, 46.120831],
            [-62.513889, 46.14222],
            [-62.473049, 46.150269],
            [-62.446949, 46.090549],
            [-62.573891, 46.034721],
            [-62.459171, 46.00639],
            [-62.74667, 45.94833],
            [-62.882771, 45.995541],
            [-62.935001, 46.045551],
            [-62.896111, 46.064991],
            [-63.03083, 46.060822],
            [-62.922501, 46.092491],
            [-62.868061, 46.13472],
            [-62.885281, 46.155819],
            [-62.959171, 46.128601],
            [-62.94416, 46.189991],
            [-62.985001, 46.19582],
            [-62.983891, 46.173882],
            [-63.122219, 46.211658],
            [-62.96278, 46.319988],
            [-63.122768, 46.237209],
            [-63.195, 46.269989],
            [-63.172501, 46.224159],
            [-63.27306, 46.195271],
            [-63.219158, 46.181938],
            [-63.173061, 46.211109],
            [-63.14056, 46.186378],
            [-63.261669, 46.13805],
            [-63.48138, 46.21777],
            [-63.591942, 46.211941],
            [-63.698608, 46.249161],
            [-63.812771, 46.332211],
            [-63.785561, 46.339989],
            [-63.800831, 46.372761],
            [-63.714722, 46.35416],
            [-63.76556, 46.380268],
            [-63.744999, 46.394711],
            [-63.889999, 46.397491],
            [-63.929729, 46.424999],
            [-63.98917, 46.393608],
          ],
        ],
      ],
    },
    id: 'CA_PE',
    properties: {
      name: 'Prince Edward Island',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-139.121094, 69.52916],
            [-139.252197, 69.579712],
            [-139.332504, 69.566093],
            [-139.133102, 69.647774],
            [-138.867203, 69.588318],
            [-139.023102, 69.580833],
            [-139.121094, 69.52916],
          ],
        ],
        [
          [
            [-123.816902, 59.994709],
            [-125.000801, 60.003868],
            [-133.791199, 60],
            [-134.151703, 59.999722],
            [-134.639297, 60.000198],
            [-134.906601, 60],
            [-138.938599, 59.997551],
            [-139.188904, 60.088879],
            [-139.0681, 60.352219],
            [-139.676697, 60.340549],
            [-139.979401, 60.187771],
            [-140.450806, 60.309719],
            [-140.521393, 60.22221],
            [-140.995499, 60.307209],
            [-140.996857, 61.894802],
            [-141.000046, 65.840279],
            [-141.00206, 68.428207],
            [-141.003006, 69.642357],
            [-140.396103, 69.5961],
            [-139.888306, 69.616653],
            [-139.272797, 69.508614],
            [-139.143097, 69.510818],
            [-138.957504, 69.407494],
            [-138.751404, 69.346367],
            [-138.600601, 69.236649],
            [-138.5186, 69.25444],
            [-138.449997, 69.229156],
            [-138.395599, 69.293053],
            [-138.446106, 69.288589],
            [-138.378296, 69.304703],
            [-138.329407, 69.234711],
            [-138.1772, 69.159988],
            [-137.378296, 69.001389],
            [-137.419205, 68.988876],
            [-137.255005, 68.948318],
            [-137.0103, 68.947479],
            [-136.745499, 68.875259],
            [-136.419998, 68.90052],
            [-136.446106, 68.873032],
            [-136.445007, 67.715553],
            [-136.421097, 67.655258],
            [-136.241104, 67.620529],
            [-136.157806, 67.507767],
            [-136.205307, 67.406937],
            [-136.116394, 67.382751],
            [-136.142807, 67.359421],
            [-136.0914, 67.302467],
            [-136.134506, 67.226379],
            [-136.236404, 67.173866],
            [-136.198898, 67.143333],
            [-136.222198, 67.064148],
            [-136.164505, 67.003601],
            [-133.8311, 67.003326],
            [-134.046097, 66.984421],
            [-134.092499, 66.955261],
            [-133.908096, 66.908867],
            [-133.763901, 66.806931],
            [-133.840302, 66.727768],
            [-133.757202, 66.685257],
            [-133.771896, 66.64415],
            [-133.5867, 66.564697],
            [-133.690308, 66.531937],
            [-133.629395, 66.445251],
            [-133.750305, 66.447746],
            [-133.768097, 66.373032],
            [-133.824707, 66.316673],
            [-133.570007, 66.27832],
            [-133.563904, 66.155823],
            [-133.673096, 66.116928],
            [-133.704697, 66.07193],
            [-133.603302, 66.051651],
            [-133.629395, 65.974426],
            [-133.407501, 65.943863],
            [-133.147507, 66.024696],
            [-132.939697, 66.02887],
            [-132.908401, 65.996933],
            [-132.993896, 65.923027],
            [-132.869003, 65.910477],
            [-132.605804, 66.031662],
            [-132.542496, 66.021378],
            [-132.5103, 65.978867],
            [-132.342804, 65.981934],
            [-132.334396, 65.94664],
            [-132.525803, 65.897774],
            [-132.573303, 65.848602],
            [-132.286102, 65.71582],
            [-132.174194, 65.59137],
            [-132.273605, 65.546944],
            [-132.327805, 65.434708],
            [-132.521393, 65.363037],
            [-132.555298, 65.2836],
            [-132.780594, 65.229713],
            [-132.714203, 65.199417],
            [-132.787796, 65.187759],
            [-132.701904, 65.15387],
            [-132.576401, 65.190536],
            [-132.527496, 65.162773],
            [-132.556107, 65.118042],
            [-132.539993, 65.093872],
            [-132.381699, 65.084991],
            [-132.333298, 65.034714],
            [-132.507507, 64.964432],
            [-132.487503, 64.870819],
            [-132.622498, 64.826927],
            [-132.570602, 64.772491],
            [-132.368896, 64.77832],
            [-132.163605, 64.70166],
            [-132.046402, 64.695534],
            [-131.915802, 64.582489],
            [-131.702805, 64.535538],
            [-131.687805, 64.498596],
            [-131.803894, 64.438026],
            [-131.8181, 64.378311],
            [-131.593002, 64.368591],
            [-131.427505, 64.416382],
            [-131.388596, 64.467209],
            [-131.141693, 64.424423],
            [-131.001999, 64.330276],
            [-131.060501, 64.279427],
            [-130.979706, 64.265549],
            [-130.937195, 64.209991],
            [-130.867203, 64.187759],
            [-130.9478, 64.137207],
            [-130.853607, 64.072487],
            [-130.863907, 64.043053],
            [-130.751404, 64.046097],
            [-130.778595, 64.009163],
            [-130.7556, 63.986938],
            [-130.5811, 63.92416],
            [-130.522202, 63.93499],
            [-130.3703, 63.84388],
            [-130.119705, 63.808041],
            [-130.103897, 63.76416],
            [-130.282501, 63.73777],
            [-130.321106, 63.69582],
            [-130.251999, 63.66666],
            [-130.109695, 63.703609],
            [-130.082504, 63.68832],
            [-130.110794, 63.66444],
            [-130.093002, 63.63055],
            [-129.948593, 63.613609],
            [-129.957993, 63.58527],
            [-129.898102, 63.57222],
            [-129.819504, 63.48333],
            [-129.897202, 63.452221],
            [-129.919205, 63.372761],
            [-130.073593, 63.33696],
            [-130.138306, 63.310268],
            [-130.151093, 63.265831],
            [-130.044495, 63.269989],
            [-129.978607, 63.203609],
            [-129.900299, 63.197208],
            [-129.835297, 63.114719],
            [-129.847198, 63.086941],
            [-129.637207, 63.08028],
            [-129.598907, 63.051929],
            [-129.705795, 62.952221],
            [-129.726105, 62.91415],
            [-129.702499, 62.89222],
            [-129.773895, 62.874439],
            [-129.630798, 62.76083],
            [-129.625305, 62.70499],
            [-129.516998, 62.672489],
            [-129.523895, 62.64333],
            [-129.487793, 62.618881],
            [-129.567795, 62.57444],
            [-129.417801, 62.584991],
            [-129.172195, 62.481659],
            [-129.308304, 62.421379],
            [-129.215805, 62.377491],
            [-129.310501, 62.326389],
            [-129.282196, 62.267208],
            [-129.199203, 62.21693],
            [-129.2939, 62.156101],
            [-129.122803, 62.116661],
            [-128.984207, 62.136108],
            [-128.765305, 62.058331],
            [-128.677505, 62.125271],
            [-128.546997, 62.122219],
            [-128.375305, 62.02721],
            [-128.388107, 61.993881],
            [-128.279205, 61.948879],
            [-128.196701, 61.844151],
            [-128.080597, 61.849159],
            [-128.006104, 61.732491],
            [-127.972801, 61.688599],
            [-127.550301, 61.511379],
            [-127.2883, 61.519161],
            [-127.141701, 61.46666],
            [-127.125, 61.4011],
            [-127.059402, 61.363049],
            [-127.007004, 61.18499],
            [-127.031403, 61.150539],
            [-127.0131, 61.12804],
            [-127.101898, 61.070831],
            [-127.055801, 61.039989],
            [-126.935303, 61.059158],
            [-126.9394, 61.002491],
            [-126.898903, 60.941662],
            [-126.925301, 60.862209],
            [-126.8797, 60.82999],
            [-126.886902, 60.78249],
            [-126.826103, 60.755001],
            [-126.753304, 60.782211],
            [-126.672203, 60.743469],
            [-126.520302, 60.814411],
            [-126.394501, 60.77668],
            [-126.253601, 60.78867],
            [-126.217499, 60.819771],
            [-126.244698, 60.850868],
            [-126.202202, 60.863651],
            [-126.113602, 60.862808],
            [-126.107201, 60.817009],
            [-126.009201, 60.80341],
            [-125.957001, 60.877239],
            [-125.887199, 60.896938],
            [-125.711998, 60.827259],
            [-125.370003, 60.782558],
            [-125.184196, 60.848881],
            [-125, 60.860519],
            [-124.871696, 60.858051],
            [-124.8778, 60.91721],
            [-124.813004, 60.972488],
            [-124.578903, 60.953049],
            [-124.476898, 60.79055],
            [-124.619202, 60.709721],
            [-124.614197, 60.668598],
            [-124.459396, 60.569721],
            [-124.418297, 60.47916],
            [-124.206398, 60.457211],
            [-124.195297, 60.32888],
            [-123.974197, 60.098049],
            [-124.029198, 60.023048],
            [-123.8769, 60.04166],
            [-123.816902, 59.994709],
          ],
        ],
      ],
    },
    id: 'CA_YT',
    properties: {
      name: 'Yukon Territory',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-102.001701, 59.99992],
            [-100.039001, 59.999722],
            [-99.626953, 59.999729],
            [-94.800491, 59.999569],
            [-94.82251, 59.954159],
            [-94.768341, 59.78249],
            [-94.819168, 59.636379],
            [-94.761673, 59.570271],
            [-94.793877, 59.541939],
            [-94.727783, 59.446659],
            [-94.710831, 59.37138],
            [-94.736656, 59.34222],
            [-94.658623, 59.350552],
            [-94.775009, 59.293331],
            [-94.794724, 59.087212],
            [-94.934433, 59.084991],
            [-94.996109, 59.053879],
            [-94.804443, 59.061378],
            [-94.804718, 59.014721],
            [-94.679993, 58.971371],
            [-94.74527, 58.83305],
            [-94.675827, 58.87804],
            [-94.586403, 58.874989],
            [-94.482224, 58.8111],
            [-94.421661, 58.716381],
            [-94.32695, 58.721661],
            [-94.276947, 58.780548],
            [-94.203056, 58.804161],
            [-94.239723, 58.738602],
            [-94.202499, 58.67944],
            [-94.271667, 58.633881],
            [-94.306381, 58.563881],
            [-94.287216, 58.438042],
            [-94.328613, 58.39333],
            [-94.363327, 58.21888],
            [-94.228607, 58.396099],
            [-94.246109, 58.586651],
            [-94.140556, 58.73333],
            [-94.177223, 58.771381],
            [-93.501953, 58.771099],
            [-93.46666, 58.719711],
            [-93.400284, 58.700272],
            [-93.319168, 58.75861],
            [-93.152222, 58.73777],
            [-93.118057, 58.508888],
            [-92.931107, 58.211658],
            [-92.852493, 58.16415],
            [-92.868881, 58.143051],
            [-92.803329, 58.057209],
            [-92.749161, 57.860279],
            [-92.781113, 57.834721],
            [-92.728333, 57.820549],
            [-92.600281, 57.648609],
            [-92.554169, 57.539719],
            [-92.450844, 57.44249],
            [-92.418877, 57.33749],
            [-92.441101, 57.230549],
            [-92.576401, 57.056938],
            [-92.715843, 56.947491],
            [-92.876099, 56.90749],
            [-92.691101, 56.933601],
            [-92.443047, 57.04472],
            [-92.212219, 57.058041],
            [-92.4664, 56.932499],
            [-92.180283, 57.030819],
            [-91.001678, 57.261379],
            [-90.451111, 57.193878],
            [-90, 57.01683],
            [-89.179642, 56.86895],
            [-89.305557, 56.627209],
            [-90.843887, 55.66777],
            [-92.388641, 54.62793],
            [-92.476593, 54.56694],
            [-92.60231, 54.478489],
            [-92.698143, 54.410931],
            [-93.272087, 53.998859],
            [-93.652222, 53.72221],
            [-95.150284, 52.826389],
            [-95.154327, 49.576729],
            [-95.153877, 49.452019],
            [-95.151627, 49.371731],
            [-95.157516, 49],
            [-95.276421, 49],
            [-96.406616, 49],
            [-97.229103, 49],
            [-97.9375, 49],
            [-99, 49],
            [-99.53315, 49],
            [-100.1875, 49],
            [-101.367203, 49],
            [-101.3675, 49.17749],
            [-101.400299, 49.177761],
            [-101.400299, 49.533051],
            [-101.425598, 49.5341],
            [-101.426102, 49.888599],
            [-101.453598, 49.888599],
            [-101.4533, 50.241661],
            [-101.483597, 50.24192],
            [-101.484703, 50.589989],
            [-101.514999, 50.595539],
            [-101.514702, 50.949429],
            [-101.544998, 50.950001],
            [-101.567001, 51.970829],
            [-101.613297, 51.96999],
            [-101.612503, 52.32222],
            [-101.639503, 52.32222],
            [-101.639999, 52.671101],
            [-101.672203, 52.67083],
            [-101.700798, 53.368881],
            [-101.742798, 53.369438],
            [-101.772102, 54.00256],
            [-101.7714, 54.06638],
            [-101.810799, 54.066101],
            [-101.811401, 54.41666],
            [-101.851898, 54.41721],
            [-101.851799, 54.53513],
            [-101.851402, 54.766392],
            [-101.883904, 54.76749],
            [-101.883598, 55.116661],
            [-101.925598, 55.117489],
            [-101.925797, 55.466381],
            [-101.967201, 55.467468],
            [-101.967201, 55.67194],
            [-101.967598, 55.76067],
            [-102.0009, 55.830151],
            [-102.001404, 56.221241],
            [-102.000999, 56.366421],
            [-102.001099, 57.013351],
            [-102.001297, 57.25964],
            [-102.0009, 57.49725],
            [-102.001297, 57.868439],
            [-102.001297, 58.034302],
            [-102.001503, 58.12854],
            [-102.0009, 59.918839],
            [-102.001701, 59.99992],
          ],
        ],
      ],
    },
    id: 'CA_MB',
    properties: {
      name: 'Manitoba',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-79.547096, 51.460129],
            [-79.539436, 51.191662],
            [-79.537804, 50.982368],
            [-79.624969, 51.0355],
            [-79.678047, 51.05471],
            [-79.752228, 51.184429],
            [-79.680557, 51.29805],
            [-79.698036, 51.401379],
            [-79.547096, 51.460129],
          ],
        ],
        [
          [
            [-79.537613, 50.958599],
            [-79.520683, 48.764679],
            [-79.519012, 48.605289],
            [-79.511322, 47.565571],
            [-79.576401, 47.441929],
            [-79.439438, 47.26527],
            [-79.443878, 47.11055],
            [-79.305557, 46.94054],
            [-79.155563, 46.812489],
            [-78.950562, 46.511669],
            [-78.75528, 46.400539],
            [-78.703613, 46.337212],
            [-77.680557, 46.183601],
            [-77.554993, 46.151932],
            [-77.321953, 46.026661],
            [-77.278877, 45.937771],
            [-77.206123, 45.877209],
            [-77.002792, 45.790279],
            [-76.920837, 45.806381],
            [-76.910553, 45.889992],
            [-76.804718, 45.864719],
            [-76.783333, 45.76194],
            [-76.710564, 45.712769],
            [-76.666397, 45.590549],
            [-76.607773, 45.55249],
            [-76.337784, 45.45583],
            [-76.184433, 45.521099],
            [-76.076683, 45.513889],
            [-75.847229, 45.37249],
            [-75.453339, 45.523609],
            [-74.875839, 45.64159],
            [-74.646118, 45.635551],
            [-74.396667, 45.564159],
            [-74.479172, 45.316669],
            [-74.324722, 45.201099],
            [-74.433609, 45.149441],
            [-74.506393, 45.071659],
            [-74.736481, 44.99287],
            [-74.968819, 44.948578],
            [-75.329201, 44.81057],
            [-75.758972, 44.517475],
            [-75.759117, 44.517273],
            [-75.778252, 44.519852],
            [-75.976936, 44.38361],
            [-76.503067, 44.227489],
            [-76.688316, 44.21693],
            [-76.984161, 44.071381],
            [-77.070007, 44.09222],
            [-76.886673, 44.17749],
            [-77.046661, 44.129429],
            [-77.011398, 44.211109],
            [-77.55722, 44.12138],
            [-77.575562, 44.07888],
            [-77.314438, 44.15221],
            [-77.31778, 44.08971],
            [-77.076683, 44.17944],
            [-77.127777, 44.033611],
            [-77.015793, 44.047249],
            [-76.941109, 44.071049],
            [-76.846657, 44.11832],
            [-77.042221, 43.94833],
            [-76.848053, 43.943321],
            [-77.134453, 43.855549],
            [-77.232773, 43.87999],
            [-77.170273, 43.954159],
            [-77.275284, 43.910549],
            [-77.327789, 43.953609],
            [-77.25473, 43.96888],
            [-77.48555, 43.942211],
            [-77.580841, 44.002781],
            [-77.538887, 44.01944],
            [-77.703613, 44.041111],
            [-77.736938, 44.023609],
            [-77.678329, 44.000832],
            [-77.804718, 44.016941],
            [-79.092499, 43.826389],
            [-79.362503, 43.64333],
            [-79.467773, 43.646938],
            [-79.587784, 43.564709],
            [-79.775833, 43.319988],
            [-79.703613, 43.244438],
            [-79.345284, 43.188042],
            [-79.073982, 43.267471],
            [-79.065811, 43.101341],
            [-79.078613, 43.081669],
            [-79.031113, 43.05666],
            [-79.040833, 43.007771],
            [-78.92495, 42.91011],
            [-79.098892, 42.840271],
            [-79.454727, 42.877491],
            [-80.204178, 42.790279],
            [-80.450562, 42.612209],
            [-80.114441, 42.541382],
            [-80.521942, 42.573051],
            [-80.925003, 42.665539],
            [-81.333069, 42.651379],
            [-81.785278, 42.39777],
            [-81.856377, 42.25639],
            [-81.894173, 42.257221],
            [-81.857498, 42.320831],
            [-81.921112, 42.260281],
            [-82.071953, 42.253052],
            [-82.388062, 42.1236],
            [-82.479721, 42.03249],
            [-82.505836, 41.913319],
            [-82.627777, 42.035271],
            [-82.890556, 41.982208],
            [-83.123894, 42.069721],
            [-83.030457, 42.313221],
            [-82.493057, 42.312771],
            [-82.427223, 42.353882],
            [-82.418877, 42.498329],
            [-82.595284, 42.509991],
            [-82.638443, 42.54705],
            [-82.586403, 42.558601],
            [-82.518883, 42.633781],
            [-82.473328, 42.762859],
            [-82.472038, 42.898651],
            [-82.419701, 42.972679],
            [-82.175552, 43.071659],
            [-81.745537, 43.345829],
            [-81.697769, 43.48777],
            [-81.745003, 44.083881],
            [-81.615547, 44.219151],
            [-81.580566, 44.346661],
            [-81.405273, 44.437771],
            [-81.268066, 44.616661],
            [-81.349731, 44.973049],
            [-81.433884, 44.982491],
            [-81.591377, 45.15221],
            [-81.576111, 45.19276],
            [-81.720001, 45.215832],
            [-81.695267, 45.264721],
            [-81.283623, 45.249161],
            [-81.325562, 45.15749],
            [-81.265556, 45.10416],
            [-81.253616, 45.001659],
            [-81.110283, 44.96027],
            [-81.150284, 44.911381],
            [-80.948608, 44.963612],
            [-81.132767, 44.758049],
            [-80.997772, 44.809158],
            [-80.892776, 44.791111],
            [-80.942207, 44.595268],
            [-80.805557, 44.702492],
            [-80.649986, 44.726101],
            [-80.584442, 44.612209],
            [-80.089722, 44.468048],
            [-79.997498, 44.556938],
            [-79.987503, 44.68277],
            [-80.113327, 44.752491],
            [-80.103882, 44.819988],
            [-79.945831, 44.865551],
            [-79.877487, 44.76833],
            [-79.659729, 44.751659],
            [-79.721657, 44.78722],
            [-79.68306, 44.879162],
            [-79.736938, 44.865551],
            [-79.737778, 44.8036],
            [-79.783623, 44.817501],
            [-79.828613, 44.9361],
            [-79.914169, 44.94471],
            [-79.934998, 45.013329],
            [-80.099991, 45.123322],
            [-79.988602, 45.148331],
            [-80.12944, 45.237499],
            [-80.037514, 45.314159],
            [-80.059433, 45.382771],
            [-80.127777, 45.411659],
            [-80.19249, 45.375549],
            [-80.164719, 45.33971],
            [-80.233322, 45.341099],
            [-80.335831, 45.435551],
            [-80.349991, 45.382771],
            [-80.400284, 45.606659],
            [-80.501404, 45.569439],
            [-80.748337, 45.921379],
            [-81.043327, 45.9561],
            [-81.155273, 45.925831],
            [-81.168877, 46.017769],
            [-81.250557, 45.9575],
            [-81.497498, 45.974991],
            [-81.497772, 46.020271],
            [-81.670273, 45.946381],
            [-81.576111, 45.996941],
            [-81.64473, 46.014999],
            [-81.503891, 46.04166],
            [-81.703888, 46.0186],
            [-81.543327, 46.05999],
            [-81.621384, 46.123051],
            [-81.743332, 46.06805],
            [-81.761124, 46.01305],
            [-81.777222, 46.06332],
            [-81.714447, 46.10416],
            [-82.13501, 46.11694],
            [-82.369453, 46.165821],
            [-82.322243, 46.190269],
            [-82.674156, 46.16444],
            [-82.63028, 46.21777],
            [-82.986938, 46.172218],
            [-83.865547, 46.29583],
            [-83.883331, 46.334991],
            [-84.049728, 46.326099],
            [-84.119453, 46.375271],
            [-84.075012, 46.503609],
            [-84.127129, 46.529591],
            [-84.136177, 46.534111],
            [-84.17305, 46.55249],
            [-84.412132, 46.50367],
            [-84.433167, 46.499371],
            [-84.517227, 46.482208],
            [-84.590286, 46.56583],
            [-84.421944, 46.696098],
            [-84.465843, 46.758331],
            [-84.54834, 46.699429],
            [-84.544159, 46.825001],
            [-84.393066, 46.8325],
            [-84.351097, 46.882771],
            [-84.446663, 46.938881],
            [-84.576401, 46.943878],
            [-84.603607, 46.901932],
            [-84.777786, 46.981659],
            [-84.653877, 47.225819],
            [-84.578888, 47.288891],
            [-84.62471, 47.361111],
            [-85.027786, 47.611382],
            [-84.967499, 47.68082],
            [-84.991096, 47.71693],
            [-84.892502, 47.789719],
            [-84.931671, 47.85527],
            [-84.844452, 47.949162],
            [-85.557503, 47.925549],
            [-85.860283, 47.985271],
            [-86.131378, 48.241379],
            [-86.262787, 48.584431],
            [-86.434723, 48.77721],
            [-86.55249, 48.732208],
            [-86.703056, 48.810268],
            [-86.85556, 48.750832],
            [-86.970551, 48.809719],
            [-87.003067, 48.77499],
            [-87.289719, 48.76749],
            [-87.443604, 48.84388],
            [-87.547234, 48.833599],
            [-87.575844, 48.88583],
            [-87.649986, 48.863049],
            [-87.955841, 48.95055],
            [-88.035553, 49.022221],
            [-88.183319, 48.965832],
            [-88.261093, 48.99295],
            [-88.253891, 48.872219],
            [-88.10527, 48.787498],
            [-88.103333, 48.711658],
            [-88.204453, 48.606659],
            [-88.252792, 48.59499],
            [-88.229446, 48.636662],
            [-88.283623, 48.603882],
            [-88.332497, 48.657211],
            [-88.3125, 48.581669],
            [-88.391113, 48.540829],
            [-88.430283, 48.573608],
            [-88.482773, 48.470539],
            [-88.559723, 48.430271],
            [-88.590561, 48.490269],
            [-88.556381, 48.546101],
            [-88.350281, 48.678051],
            [-88.313889, 48.760551],
            [-88.360817, 48.83638],
            [-88.480293, 48.854439],
            [-88.547501, 48.764721],
            [-88.509453, 48.716381],
            [-88.551666, 48.698601],
            [-88.539993, 48.645271],
            [-88.624443, 48.62471],
            [-88.632767, 48.522491],
            [-88.693047, 48.507771],
            [-88.74028, 48.369709],
            [-88.938599, 48.314442],
            [-88.759453, 48.580551],
            [-89.138611, 48.48444],
            [-89.243057, 48.388329],
            [-89.198883, 48.32444],
            [-89.337784, 48.1161],
            [-89.530586, 48.00177],
            [-89.749222, 48.0266],
            [-89.900299, 47.992619],
            [-89.986931, 48.02367],
            [-90.026611, 48.086189],
            [-90.14518, 48.112881],
            [-90.556732, 48.092861],
            [-90.567352, 48.121811],
            [-90.743263, 48.088551],
            [-90.864388, 48.254311],
            [-91.027039, 48.19545],
            [-91.239326, 48.081409],
            [-91.571442, 48.043678],
            [-91.568657, 48.104561],
            [-91.703613, 48.114941],
            [-91.711807, 48.19688],
            [-91.788689, 48.206249],
            [-91.979401, 48.2505],
            [-92.035049, 48.35561],
            [-92.125816, 48.366859],
            [-92.275993, 48.352421],
            [-92.300133, 48.298409],
            [-92.276772, 48.244438],
            [-92.369957, 48.220871],
            [-92.473167, 48.35759],
            [-92.456192, 48.40226],
            [-92.497383, 48.44017],
            [-92.70649, 48.460461],
            [-92.698669, 48.49482],
            [-92.626228, 48.502918],
            [-92.64167, 48.54044],
            [-92.728851, 48.54031],
            [-92.946777, 48.628441],
            [-93.091301, 48.626659],
            [-93.304092, 48.63723],
            [-93.457619, 48.59277],
            [-93.465187, 48.54958],
            [-93.513977, 48.534328],
            [-93.780937, 48.511639],
            [-93.812523, 48.525459],
            [-93.843727, 48.624779],
            [-94.230637, 48.65202],
            [-94.292137, 48.707741],
            [-94.430443, 48.710819],
            [-94.570107, 48.713699],
            [-94.694221, 48.777641],
            [-94.681038, 48.877178],
            [-94.83181, 49.330811],
            [-95.151627, 49.371731],
            [-95.153877, 49.452019],
            [-95.154327, 49.576729],
            [-95.150284, 52.826389],
            [-93.652222, 53.72221],
            [-93.272087, 53.998859],
            [-92.698143, 54.410931],
            [-92.60231, 54.478489],
            [-92.476593, 54.56694],
            [-92.388641, 54.62793],
            [-90.843887, 55.66777],
            [-89.305557, 56.627209],
            [-89.179642, 56.86895],
            [-89.037781, 56.836109],
            [-88.87944, 56.858051],
            [-88.654716, 56.696381],
            [-88.48999, 56.64222],
            [-88.353607, 56.553322],
            [-87.975563, 56.437489],
            [-87.943047, 56.377491],
            [-87.723892, 56.20388],
            [-87.708054, 56.156101],
            [-87.583618, 56.076099],
            [-87.615829, 56.002781],
            [-87.548607, 56.049999],
            [-87.351936, 55.992771],
            [-87.362503, 55.946659],
            [-86.979446, 55.93166],
            [-86.958893, 55.905819],
            [-86.477783, 55.80888],
            [-86.277222, 55.72887],
            [-85.944443, 55.697769],
            [-85.577789, 55.562771],
            [-85.393341, 55.408878],
            [-85.128883, 55.346378],
            [-85.121933, 55.303322],
            [-85.214172, 55.273319],
            [-85.398064, 55.10083],
            [-85.383057, 55.063049],
            [-85.423889, 54.990551],
            [-85.194153, 55.24416],
            [-85.001953, 55.296661],
            [-84.599167, 55.241661],
            [-84.322777, 55.289989],
            [-84.092216, 55.27166],
            [-83.920273, 55.31916],
            [-83.730003, 55.259441],
            [-83.736938, 55.215],
            [-83.686943, 55.24416],
            [-83.601387, 55.224159],
            [-83.561943, 55.130821],
            [-83.583618, 55.22916],
            [-83.698608, 55.283051],
            [-83.179718, 55.197208],
            [-82.964722, 55.233601],
            [-82.895279, 55.171661],
            [-82.946663, 55.10638],
            [-82.884171, 55.15332],
            [-82.650284, 55.16972],
            [-82.555557, 55.139721],
            [-82.536667, 55.16444],
            [-82.319458, 55.064159],
            [-82.338608, 55.09943],
            [-82.308037, 55.121929],
            [-82.340561, 55.164711],
            [-82.245827, 55.102779],
            [-82.287216, 55.036659],
            [-82.254997, 54.975269],
            [-82.270844, 54.931381],
            [-82.195267, 54.844711],
            [-82.358887, 54.500271],
            [-82.441101, 54.36166],
            [-82.434158, 54.209431],
            [-82.362778, 54.143608],
            [-82.381378, 54.118881],
            [-82.321953, 54.123878],
            [-82.243881, 54.06805],
            [-82.12999, 53.79916],
            [-82.21666, 53.603882],
            [-82.114723, 53.273609],
            [-82.250557, 53.211941],
            [-82.301666, 52.961109],
            [-82.101669, 52.87999],
            [-81.973618, 52.780548],
            [-81.988892, 52.762211],
            [-81.921387, 52.682209],
            [-81.558037, 52.4561],
            [-81.553047, 52.352489],
            [-81.478058, 52.302219],
            [-81.561394, 52.31638],
            [-81.747498, 52.251389],
            [-81.82251, 52.25444],
            [-81.883621, 52.187489],
            [-81.758347, 52.239429],
            [-81.501678, 52.23333],
            [-81.352783, 52.101101],
            [-80.988327, 52.008049],
            [-80.899986, 51.895271],
            [-80.815277, 51.87999],
            [-80.599167, 51.713329],
            [-80.515007, 51.524441],
            [-80.436394, 51.464161],
            [-80.424438, 51.363609],
            [-80.487503, 51.336651],
            [-80.652496, 51.278332],
            [-80.75029, 51.17749],
            [-80.844727, 51.150269],
            [-81.015007, 51.028332],
            [-80.93277, 51.049721],
            [-80.943878, 51.0075],
            [-80.862213, 51.1161],
            [-80.694443, 51.156101],
            [-80.530563, 51.283611],
            [-80.392227, 51.3386],
            [-79.996384, 51.254711],
            [-79.741096, 51.1236],
            [-79.684998, 51.045551],
            [-79.537613, 50.958599],
          ],
        ],
        [
          [
            [-82.878601, 45.968601],
            [-82.809998, 45.992489],
            [-82.842216, 45.920269],
            [-82.757507, 45.844151],
            [-82.592216, 45.849998],
            [-82.539169, 45.785549],
            [-82.509171, 45.848598],
            [-82.586937, 45.902489],
            [-82.299438, 45.99194],
            [-82.167503, 45.837769],
            [-82.048607, 45.96471],
            [-81.92778, 45.978039],
            [-81.888344, 45.949429],
            [-81.90834, 45.891941],
            [-81.820282, 45.880268],
            [-81.847229, 45.785271],
            [-81.799988, 45.732208],
            [-81.683609, 45.904991],
            [-81.655838, 45.861111],
            [-81.70723, 45.798328],
            [-81.587219, 45.799999],
            [-81.719452, 45.606098],
            [-81.863327, 45.52055],
            [-81.99472, 45.559719],
            [-81.898064, 45.577492],
            [-81.761124, 45.701099],
            [-82.065826, 45.55888],
            [-82.689163, 45.793049],
            [-83.213333, 45.873878],
            [-83.186943, 45.96471],
            [-83.091377, 45.919441],
            [-83.054993, 45.960548],
            [-82.983887, 45.930271],
            [-82.878601, 45.968601],
          ],
        ],
      ],
    },
    id: 'CA_ON',
    properties: {
      name: 'Ontario',
    },
  },
  {
    type: 'Feature',
    properties: {
      name: 'Switzerland',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [9.594226, 47.525058],
          [9.632932, 47.347601],
          [9.47997, 47.10281],
          [9.932448, 46.920728],
          [10.442701, 46.893546],
          [10.363378, 46.483571],
          [9.922837, 46.314899],
          [9.182882, 46.440215],
          [8.966306, 46.036932],
          [8.489952, 46.005151],
          [8.31663, 46.163642],
          [7.755992, 45.82449],
          [7.273851, 45.776948],
          [6.843593, 45.991147],
          [6.5001, 46.429673],
          [6.022609, 46.27299],
          [6.037389, 46.725779],
          [6.768714, 47.287708],
          [6.736571, 47.541801],
          [7.192202, 47.449766],
          [7.466759, 47.620582],
          [8.317301, 47.61358],
          [8.522612, 47.830828],
          [9.594226, 47.525058],
        ],
      ],
    },
    id: 'CH',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Chile',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-68.63401, -52.63637],
            [-68.63335, -54.8695],
            [-67.56244, -54.87001],
            [-66.95992, -54.89681],
            [-67.29103, -55.30124],
            [-68.14863, -55.61183],
            [-68.639991, -55.580018],
            [-69.2321, -55.49906],
            [-69.95809, -55.19843],
            [-71.00568, -55.05383],
            [-72.2639, -54.49514],
            [-73.2852, -53.95752],
            [-74.66253, -52.83749],
            [-73.8381, -53.04743],
            [-72.43418, -53.7154],
            [-71.10773, -54.07433],
            [-70.59178, -53.61583],
            [-70.26748, -52.93123],
            [-69.34565, -52.5183],
            [-68.63401, -52.63637],
          ],
        ],
        [
          [
            [-68.219913, -21.494347],
            [-67.82818, -22.872919],
            [-67.106674, -22.735925],
            [-66.985234, -22.986349],
            [-67.328443, -24.025303],
            [-68.417653, -24.518555],
            [-68.386001, -26.185016],
            [-68.5948, -26.506909],
            [-68.295542, -26.89934],
            [-69.001235, -27.521214],
            [-69.65613, -28.459141],
            [-70.01355, -29.367923],
            [-69.919008, -30.336339],
            [-70.535069, -31.36501],
            [-70.074399, -33.09121],
            [-69.814777, -33.273886],
            [-69.817309, -34.193571],
            [-70.388049, -35.169688],
            [-70.364769, -36.005089],
            [-71.121881, -36.658124],
            [-71.118625, -37.576827],
            [-70.814664, -38.552995],
            [-71.413517, -38.916022],
            [-71.680761, -39.808164],
            [-71.915734, -40.832339],
            [-71.746804, -42.051386],
            [-72.148898, -42.254888],
            [-71.915424, -43.408565],
            [-71.464056, -43.787611],
            [-71.793623, -44.207172],
            [-71.329801, -44.407522],
            [-71.222779, -44.784243],
            [-71.659316, -44.973689],
            [-71.552009, -45.560733],
            [-71.917258, -46.884838],
            [-72.447355, -47.738533],
            [-72.331161, -48.244238],
            [-72.648247, -48.878618],
            [-73.415436, -49.318436],
            [-73.328051, -50.378785],
            [-72.975747, -50.74145],
            [-72.309974, -50.67701],
            [-72.329404, -51.425956],
            [-71.914804, -52.009022],
            [-69.498362, -52.142761],
            [-68.571545, -52.299444],
            [-69.461284, -52.291951],
            [-69.94278, -52.537931],
            [-70.845102, -52.899201],
            [-71.006332, -53.833252],
            [-71.429795, -53.856455],
            [-72.557943, -53.53141],
            [-73.702757, -52.835069],
            [-73.702757, -52.83507],
            [-74.946763, -52.262754],
            [-75.260026, -51.629355],
            [-74.976632, -51.043396],
            [-75.479754, -50.378372],
            [-75.608015, -48.673773],
            [-75.18277, -47.711919],
            [-74.126581, -46.939253],
            [-75.644395, -46.647643],
            [-74.692154, -45.763976],
            [-74.351709, -44.103044],
            [-73.240356, -44.454961],
            [-72.717804, -42.383356],
            [-73.3889, -42.117532],
            [-73.701336, -43.365776],
            [-74.331943, -43.224958],
            [-74.017957, -41.794813],
            [-73.677099, -39.942213],
            [-73.217593, -39.258689],
            [-73.505559, -38.282883],
            [-73.588061, -37.156285],
            [-73.166717, -37.12378],
            [-72.553137, -35.50884],
            [-71.861732, -33.909093],
            [-71.43845, -32.418899],
            [-71.668721, -30.920645],
            [-71.370083, -30.095682],
            [-71.489894, -28.861442],
            [-70.905124, -27.64038],
            [-70.724954, -25.705924],
            [-70.403966, -23.628997],
            [-70.091246, -21.393319],
            [-70.16442, -19.756468],
            [-70.372572, -18.347975],
            [-69.858444, -18.092694],
            [-69.590424, -17.580012],
            [-69.100247, -18.260125],
            [-68.966818, -18.981683],
            [-68.442225, -19.405068],
            [-68.757167, -20.372658],
            [-68.219913, -21.494347],
          ],
        ],
      ],
    },
    id: 'CL',
  },
  {
    type: 'Feature',
    properties: {
      name: 'China',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [110.339188, 18.678395],
            [109.47521, 18.197701],
            [108.655208, 18.507682],
            [108.626217, 19.367888],
            [109.119056, 19.821039],
            [110.211599, 20.101254],
            [110.786551, 20.077534],
            [111.010051, 19.69593],
            [110.570647, 19.255879],
            [110.339188, 18.678395],
          ],
        ],
        [
          [
            [127.657407, 49.76027],
            [129.397818, 49.4406],
            [130.582293, 48.729687],
            [130.987282, 47.790132],
            [132.506672, 47.78897],
            [133.373596, 48.183442],
            [135.026311, 48.47823],
            [134.500814, 47.57844],
            [134.112362, 47.212467],
            [133.769644, 46.116927],
            [133.097127, 45.144066],
            [131.883454, 45.321162],
            [131.025212, 44.967953],
            [131.288555, 44.11152],
            [131.144688, 42.92999],
            [130.633866, 42.903015],
            [130.640016, 42.395009],
            [129.994267, 42.985387],
            [129.596669, 42.424982],
            [128.052215, 41.994285],
            [128.208433, 41.466772],
            [127.343783, 41.503152],
            [126.869083, 41.816569],
            [126.182045, 41.107336],
            [125.079942, 40.569824],
            [124.265625, 39.928493],
            [122.86757, 39.637788],
            [122.131388, 39.170452],
            [121.054554, 38.897471],
            [121.585995, 39.360854],
            [121.376757, 39.750261],
            [122.168595, 40.422443],
            [121.640359, 40.94639],
            [120.768629, 40.593388],
            [119.639602, 39.898056],
            [119.023464, 39.252333],
            [118.042749, 39.204274],
            [117.532702, 38.737636],
            [118.059699, 38.061476],
            [118.87815, 37.897325],
            [118.911636, 37.448464],
            [119.702802, 37.156389],
            [120.823457, 37.870428],
            [121.711259, 37.481123],
            [122.357937, 37.454484],
            [122.519995, 36.930614],
            [121.104164, 36.651329],
            [120.637009, 36.11144],
            [119.664562, 35.609791],
            [119.151208, 34.909859],
            [120.227525, 34.360332],
            [120.620369, 33.376723],
            [121.229014, 32.460319],
            [121.908146, 31.692174],
            [121.891919, 30.949352],
            [121.264257, 30.676267],
            [121.503519, 30.142915],
            [122.092114, 29.83252],
            [121.938428, 29.018022],
            [121.684439, 28.225513],
            [121.125661, 28.135673],
            [120.395473, 27.053207],
            [119.585497, 25.740781],
            [118.656871, 24.547391],
            [117.281606, 23.624501],
            [115.890735, 22.782873],
            [114.763827, 22.668074],
            [114.152547, 22.22376],
            [113.80678, 22.54834],
            [113.241078, 22.051367],
            [111.843592, 21.550494],
            [110.785466, 21.397144],
            [110.444039, 20.341033],
            [109.889861, 20.282457],
            [109.627655, 21.008227],
            [109.864488, 21.395051],
            [108.522813, 21.715212],
            [108.05018, 21.55238],
            [107.04342, 21.811899],
            [106.567273, 22.218205],
            [106.725403, 22.794268],
            [105.811247, 22.976892],
            [105.329209, 23.352063],
            [104.476858, 22.81915],
            [103.504515, 22.703757],
            [102.706992, 22.708795],
            [102.170436, 22.464753],
            [101.652018, 22.318199],
            [101.80312, 21.174367],
            [101.270026, 21.201652],
            [101.180005, 21.436573],
            [101.150033, 21.849984],
            [100.416538, 21.558839],
            [99.983489, 21.742937],
            [99.240899, 22.118314],
            [99.531992, 22.949039],
            [98.898749, 23.142722],
            [98.660262, 24.063286],
            [97.60472, 23.897405],
            [97.724609, 25.083637],
            [98.671838, 25.918703],
            [98.712094, 26.743536],
            [98.68269, 27.508812],
            [98.246231, 27.747221],
            [97.911988, 28.335945],
            [97.327114, 28.261583],
            [96.248833, 28.411031],
            [96.586591, 28.83098],
            [96.117679, 29.452802],
            [95.404802, 29.031717],
            [94.56599, 29.277438],
            [93.413348, 28.640629],
            [92.503119, 27.896876],
            [91.696657, 27.771742],
            [91.258854, 28.040614],
            [90.730514, 28.064954],
            [90.015829, 28.296439],
            [89.47581, 28.042759],
            [88.814248, 27.299316],
            [88.730326, 28.086865],
            [88.120441, 27.876542],
            [86.954517, 27.974262],
            [85.82332, 28.203576],
            [85.011638, 28.642774],
            [84.23458, 28.839894],
            [83.898993, 29.320226],
            [83.337115, 29.463732],
            [82.327513, 30.115268],
            [81.525804, 30.422717],
            [81.111256, 30.183481],
            [79.721367, 30.882715],
            [78.738894, 31.515906],
            [78.458446, 32.618164],
            [79.176129, 32.48378],
            [79.208892, 32.994395],
            [78.811086, 33.506198],
            [78.912269, 34.321936],
            [77.837451, 35.49401],
            [76.192848, 35.898403],
            [75.896897, 36.666806],
            [75.158028, 37.133031],
            [74.980002, 37.41999],
            [74.829986, 37.990007],
            [74.864816, 38.378846],
            [74.257514, 38.606507],
            [73.928852, 38.505815],
            [73.675379, 39.431237],
            [73.960013, 39.660008],
            [73.822244, 39.893973],
            [74.776862, 40.366425],
            [75.467828, 40.562072],
            [76.526368, 40.427946],
            [76.904484, 41.066486],
            [78.187197, 41.185316],
            [78.543661, 41.582243],
            [80.11943, 42.123941],
            [80.25999, 42.349999],
            [80.18015, 42.920068],
            [80.866206, 43.180362],
            [79.966106, 44.917517],
            [81.947071, 45.317027],
            [82.458926, 45.53965],
            [83.180484, 47.330031],
            [85.16429, 47.000956],
            [85.720484, 47.452969],
            [85.768233, 48.455751],
            [86.598776, 48.549182],
            [87.35997, 49.214981],
            [87.751264, 49.297198],
            [88.013832, 48.599463],
            [88.854298, 48.069082],
            [90.280826, 47.693549],
            [90.970809, 46.888146],
            [90.585768, 45.719716],
            [90.94554, 45.286073],
            [92.133891, 45.115076],
            [93.480734, 44.975472],
            [94.688929, 44.352332],
            [95.306875, 44.241331],
            [95.762455, 43.319449],
            [96.349396, 42.725635],
            [97.451757, 42.74889],
            [99.515817, 42.524691],
            [100.845866, 42.663804],
            [101.83304, 42.514873],
            [103.312278, 41.907468],
            [104.522282, 41.908347],
            [104.964994, 41.59741],
            [106.129316, 42.134328],
            [107.744773, 42.481516],
            [109.243596, 42.519446],
            [110.412103, 42.871234],
            [111.129682, 43.406834],
            [111.829588, 43.743118],
            [111.667737, 44.073176],
            [111.348377, 44.457442],
            [111.873306, 45.102079],
            [112.436062, 45.011646],
            [113.463907, 44.808893],
            [114.460332, 45.339817],
            [115.985096, 45.727235],
            [116.717868, 46.388202],
            [117.421701, 46.672733],
            [118.874326, 46.805412],
            [119.66327, 46.69268],
            [119.772824, 47.048059],
            [118.866574, 47.74706],
            [118.064143, 48.06673],
            [117.295507, 47.697709],
            [116.308953, 47.85341],
            [115.742837, 47.726545],
            [115.485282, 48.135383],
            [116.191802, 49.134598],
            [116.678801, 49.888531],
            [117.879244, 49.510983],
            [119.288461, 50.142883],
            [119.279366, 50.582908],
            [120.18205, 51.643566],
            [120.738191, 51.964115],
            [120.725789, 52.516226],
            [120.177089, 52.753886],
            [121.003085, 53.251401],
            [122.245748, 53.431726],
            [123.571507, 53.458804],
            [125.068211, 53.161045],
            [125.946349, 52.792799],
            [126.564399, 51.784255],
            [126.939157, 51.353894],
            [127.287456, 50.739797],
            [127.657407, 49.76027],
          ],
        ],
      ],
    },
    id: 'CN',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Ivory Coast',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-2.856125, 4.994476],
          [-3.311084, 4.984296],
          [-4.00882, 5.179813],
          [-4.649917, 5.168264],
          [-5.834496, 4.993701],
          [-6.528769, 4.705088],
          [-7.518941, 4.338288],
          [-7.712159, 4.364566],
          [-7.635368, 5.188159],
          [-7.539715, 5.313345],
          [-7.570153, 5.707352],
          [-7.993693, 6.12619],
          [-8.311348, 6.193033],
          [-8.60288, 6.467564],
          [-8.385452, 6.911801],
          [-8.485446, 7.395208],
          [-8.439298, 7.686043],
          [-8.280703, 7.68718],
          [-8.221792, 8.123329],
          [-8.299049, 8.316444],
          [-8.203499, 8.455453],
          [-7.8321, 8.575704],
          [-8.079114, 9.376224],
          [-8.309616, 9.789532],
          [-8.229337, 10.12902],
          [-8.029944, 10.206535],
          [-7.89959, 10.297382],
          [-7.622759, 10.147236],
          [-6.850507, 10.138994],
          [-6.666461, 10.430811],
          [-6.493965, 10.411303],
          [-6.205223, 10.524061],
          [-6.050452, 10.096361],
          [-5.816926, 10.222555],
          [-5.404342, 10.370737],
          [-4.954653, 10.152714],
          [-4.779884, 9.821985],
          [-4.330247, 9.610835],
          [-3.980449, 9.862344],
          [-3.511899, 9.900326],
          [-2.827496, 9.642461],
          [-2.56219, 8.219628],
          [-2.983585, 7.379705],
          [-3.24437, 6.250472],
          [-2.810701, 5.389051],
          [-2.856125, 4.994476],
        ],
      ],
    },
    id: 'CI',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Cameroon',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [13.075822, 2.267097],
          [12.951334, 2.321616],
          [12.35938, 2.192812],
          [11.751665, 2.326758],
          [11.276449, 2.261051],
          [9.649158, 2.283866],
          [9.795196, 3.073404],
          [9.404367, 3.734527],
          [8.948116, 3.904129],
          [8.744924, 4.352215],
          [8.488816, 4.495617],
          [8.500288, 4.771983],
          [8.757533, 5.479666],
          [9.233163, 6.444491],
          [9.522706, 6.453482],
          [10.118277, 7.03877],
          [10.497375, 7.055358],
          [11.058788, 6.644427],
          [11.745774, 6.981383],
          [11.839309, 7.397042],
          [12.063946, 7.799808],
          [12.218872, 8.305824],
          [12.753672, 8.717763],
          [12.955468, 9.417772],
          [13.1676, 9.640626],
          [13.308676, 10.160362],
          [13.57295, 10.798566],
          [14.415379, 11.572369],
          [14.468192, 11.904752],
          [14.577178, 12.085361],
          [14.181336, 12.483657],
          [14.213531, 12.802035],
          [14.495787, 12.859396],
          [14.893386, 12.219048],
          [14.960152, 11.555574],
          [14.923565, 10.891325],
          [15.467873, 9.982337],
          [14.909354, 9.992129],
          [14.627201, 9.920919],
          [14.171466, 10.021378],
          [13.954218, 9.549495],
          [14.544467, 8.965861],
          [14.979996, 8.796104],
          [15.120866, 8.38215],
          [15.436092, 7.692812],
          [15.27946, 7.421925],
          [14.776545, 6.408498],
          [14.53656, 6.226959],
          [14.459407, 5.451761],
          [14.558936, 5.030598],
          [14.478372, 4.732605],
          [14.950953, 4.210389],
          [15.03622, 3.851367],
          [15.405396, 3.335301],
          [15.862732, 3.013537],
          [15.907381, 2.557389],
          [16.012852, 2.26764],
          [15.940919, 1.727673],
          [15.146342, 1.964015],
          [14.337813, 2.227875],
          [13.075822, 2.267097],
        ],
      ],
    },
    id: 'CM',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Democratic Republic of the Congo',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [30.83386, 3.509166],
          [30.773347, 2.339883],
          [31.174149, 2.204465],
          [30.85267, 1.849396],
          [30.468508, 1.583805],
          [30.086154, 1.062313],
          [29.875779, 0.59738],
          [29.819503, -0.20531],
          [29.587838, -0.587406],
          [29.579466, -1.341313],
          [29.291887, -1.620056],
          [29.254835, -2.21511],
          [29.117479, -2.292211],
          [29.024926, -2.839258],
          [29.276384, -3.293907],
          [29.339998, -4.499983],
          [29.519987, -5.419979],
          [29.419993, -5.939999],
          [29.620032, -6.520015],
          [30.199997, -7.079981],
          [30.740015, -8.340007],
          [30.346086, -8.238257],
          [29.002912, -8.407032],
          [28.734867, -8.526559],
          [28.449871, -9.164918],
          [28.673682, -9.605925],
          [28.49607, -10.789884],
          [28.372253, -11.793647],
          [28.642417, -11.971569],
          [29.341548, -12.360744],
          [29.616001, -12.178895],
          [29.699614, -13.257227],
          [28.934286, -13.248958],
          [28.523562, -12.698604],
          [28.155109, -12.272481],
          [27.388799, -12.132747],
          [27.16442, -11.608748],
          [26.553088, -11.92444],
          [25.75231, -11.784965],
          [25.418118, -11.330936],
          [24.78317, -11.238694],
          [24.314516, -11.262826],
          [24.257155, -10.951993],
          [23.912215, -10.926826],
          [23.456791, -10.867863],
          [22.837345, -11.017622],
          [22.402798, -10.993075],
          [22.155268, -11.084801],
          [22.208753, -9.894796],
          [21.875182, -9.523708],
          [21.801801, -8.908707],
          [21.949131, -8.305901],
          [21.746456, -7.920085],
          [21.728111, -7.290872],
          [20.514748, -7.299606],
          [20.601823, -6.939318],
          [20.091622, -6.94309],
          [20.037723, -7.116361],
          [19.417502, -7.155429],
          [19.166613, -7.738184],
          [19.016752, -7.988246],
          [18.464176, -7.847014],
          [18.134222, -7.987678],
          [17.47297, -8.068551],
          [17.089996, -7.545689],
          [16.860191, -7.222298],
          [16.57318, -6.622645],
          [16.326528, -5.87747],
          [13.375597, -5.864241],
          [13.024869, -5.984389],
          [12.735171, -5.965682],
          [12.322432, -6.100092],
          [12.182337, -5.789931],
          [12.436688, -5.684304],
          [12.468004, -5.248362],
          [12.631612, -4.991271],
          [12.995517, -4.781103],
          [13.25824, -4.882957],
          [13.600235, -4.500138],
          [14.144956, -4.510009],
          [14.209035, -4.793092],
          [14.582604, -4.970239],
          [15.170992, -4.343507],
          [15.75354, -3.855165],
          [16.00629, -3.535133],
          [15.972803, -2.712392],
          [16.407092, -1.740927],
          [16.865307, -1.225816],
          [17.523716, -0.74383],
          [17.638645, -0.424832],
          [17.663553, -0.058084],
          [17.82654, 0.288923],
          [17.774192, 0.855659],
          [17.898835, 1.741832],
          [18.094276, 2.365722],
          [18.393792, 2.900443],
          [18.453065, 3.504386],
          [18.542982, 4.201785],
          [18.932312, 4.709506],
          [19.467784, 5.031528],
          [20.290679, 4.691678],
          [20.927591, 4.322786],
          [21.659123, 4.224342],
          [22.405124, 4.02916],
          [22.704124, 4.633051],
          [22.84148, 4.710126],
          [23.297214, 4.609693],
          [24.410531, 5.108784],
          [24.805029, 4.897247],
          [25.128833, 4.927245],
          [25.278798, 5.170408],
          [25.650455, 5.256088],
          [26.402761, 5.150875],
          [27.044065, 5.127853],
          [27.374226, 5.233944],
          [27.979977, 4.408413],
          [28.428994, 4.287155],
          [28.696678, 4.455077],
          [29.159078, 4.389267],
          [29.715995, 4.600805],
          [29.9535, 4.173699],
          [30.83386, 3.509166],
        ],
      ],
    },
    id: 'CD',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Republic of the Congo',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [12.995517, -4.781103],
          [12.62076, -4.438023],
          [12.318608, -4.60623],
          [11.914963, -5.037987],
          [11.093773, -3.978827],
          [11.855122, -3.426871],
          [11.478039, -2.765619],
          [11.820964, -2.514161],
          [12.495703, -2.391688],
          [12.575284, -1.948511],
          [13.109619, -2.42874],
          [13.992407, -2.470805],
          [14.29921, -1.998276],
          [14.425456, -1.333407],
          [14.316418, -0.552627],
          [13.843321, 0.038758],
          [14.276266, 1.19693],
          [14.026669, 1.395677],
          [13.282631, 1.314184],
          [13.003114, 1.830896],
          [13.075822, 2.267097],
          [14.337813, 2.227875],
          [15.146342, 1.964015],
          [15.940919, 1.727673],
          [16.012852, 2.26764],
          [16.537058, 3.198255],
          [17.133042, 3.728197],
          [17.8099, 3.560196],
          [18.453065, 3.504386],
          [18.393792, 2.900443],
          [18.094276, 2.365722],
          [17.898835, 1.741832],
          [17.774192, 0.855659],
          [17.82654, 0.288923],
          [17.663553, -0.058084],
          [17.638645, -0.424832],
          [17.523716, -0.74383],
          [16.865307, -1.225816],
          [16.407092, -1.740927],
          [15.972803, -2.712392],
          [16.00629, -3.535133],
          [15.75354, -3.855165],
          [15.170992, -4.343507],
          [14.582604, -4.970239],
          [14.209035, -4.793092],
          [14.144956, -4.510009],
          [13.600235, -4.500138],
          [13.25824, -4.882957],
          [12.995517, -4.781103],
        ],
      ],
    },
    id: 'CG',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Colombia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-75.373223, -0.152032],
          [-75.801466, 0.084801],
          [-76.292314, 0.416047],
          [-76.57638, 0.256936],
          [-77.424984, 0.395687],
          [-77.668613, 0.825893],
          [-77.855061, 0.809925],
          [-78.855259, 1.380924],
          [-78.990935, 1.69137],
          [-78.617831, 1.766404],
          [-78.662118, 2.267355],
          [-78.42761, 2.629556],
          [-77.931543, 2.696606],
          [-77.510431, 3.325017],
          [-77.12769, 3.849636],
          [-77.496272, 4.087606],
          [-77.307601, 4.667984],
          [-77.533221, 5.582812],
          [-77.318815, 5.845354],
          [-77.476661, 6.691116],
          [-77.881571, 7.223771],
          [-77.753414, 7.70984],
          [-77.431108, 7.638061],
          [-77.242566, 7.935278],
          [-77.474723, 8.524286],
          [-77.353361, 8.670505],
          [-76.836674, 8.638749],
          [-76.086384, 9.336821],
          [-75.6746, 9.443248],
          [-75.664704, 9.774003],
          [-75.480426, 10.61899],
          [-74.906895, 11.083045],
          [-74.276753, 11.102036],
          [-74.197223, 11.310473],
          [-73.414764, 11.227015],
          [-72.627835, 11.731972],
          [-72.238195, 11.95555],
          [-71.75409, 12.437303],
          [-71.399822, 12.376041],
          [-71.137461, 12.112982],
          [-71.331584, 11.776284],
          [-71.973922, 11.608672],
          [-72.227575, 11.108702],
          [-72.614658, 10.821975],
          [-72.905286, 10.450344],
          [-73.027604, 9.73677],
          [-73.304952, 9.152],
          [-72.78873, 9.085027],
          [-72.660495, 8.625288],
          [-72.439862, 8.405275],
          [-72.360901, 8.002638],
          [-72.479679, 7.632506],
          [-72.444487, 7.423785],
          [-72.198352, 7.340431],
          [-71.960176, 6.991615],
          [-70.674234, 7.087785],
          [-70.093313, 6.960376],
          [-69.38948, 6.099861],
          [-68.985319, 6.206805],
          [-68.265052, 6.153268],
          [-67.695087, 6.267318],
          [-67.34144, 6.095468],
          [-67.521532, 5.55687],
          [-67.744697, 5.221129],
          [-67.823012, 4.503937],
          [-67.621836, 3.839482],
          [-67.337564, 3.542342],
          [-67.303173, 3.318454],
          [-67.809938, 2.820655],
          [-67.447092, 2.600281],
          [-67.181294, 2.250638],
          [-66.876326, 1.253361],
          [-67.065048, 1.130112],
          [-67.259998, 1.719999],
          [-67.53781, 2.037163],
          [-67.868565, 1.692455],
          [-69.816973, 1.714805],
          [-69.804597, 1.089081],
          [-69.218638, 0.985677],
          [-69.252434, 0.602651],
          [-69.452396, 0.706159],
          [-70.015566, 0.541414],
          [-70.020656, -0.185156],
          [-69.577065, -0.549992],
          [-69.420486, -1.122619],
          [-69.444102, -1.556287],
          [-69.893635, -4.298187],
          [-70.394044, -3.766591],
          [-70.692682, -3.742872],
          [-70.047709, -2.725156],
          [-70.813476, -2.256865],
          [-71.413646, -2.342802],
          [-71.774761, -2.16979],
          [-72.325787, -2.434218],
          [-73.070392, -2.308954],
          [-73.659504, -1.260491],
          [-74.122395, -1.002833],
          [-74.441601, -0.53082],
          [-75.106625, -0.057205],
          [-75.373223, -0.152032],
        ],
      ],
    },
    id: 'CO',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Costa Rica',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-82.965783, 8.225028],
          [-83.508437, 8.446927],
          [-83.711474, 8.656836],
          [-83.596313, 8.830443],
          [-83.632642, 9.051386],
          [-83.909886, 9.290803],
          [-84.303402, 9.487354],
          [-84.647644, 9.615537],
          [-84.713351, 9.908052],
          [-84.97566, 10.086723],
          [-84.911375, 9.795992],
          [-85.110923, 9.55704],
          [-85.339488, 9.834542],
          [-85.660787, 9.933347],
          [-85.797445, 10.134886],
          [-85.791709, 10.439337],
          [-85.659314, 10.754331],
          [-85.941725, 10.895278],
          [-85.71254, 11.088445],
          [-85.561852, 11.217119],
          [-84.903003, 10.952303],
          [-84.673069, 11.082657],
          [-84.355931, 10.999226],
          [-84.190179, 10.79345],
          [-83.895054, 10.726839],
          [-83.655612, 10.938764],
          [-83.40232, 10.395438],
          [-83.015677, 9.992982],
          [-82.546196, 9.566135],
          [-82.932891, 9.476812],
          [-82.927155, 9.07433],
          [-82.719183, 8.925709],
          [-82.868657, 8.807266],
          [-82.829771, 8.626295],
          [-82.913176, 8.423517],
          [-82.965783, 8.225028],
        ],
      ],
    },
    id: 'CR',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Cuba',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-82.268151, 23.188611],
          [-81.404457, 23.117271],
          [-80.618769, 23.10598],
          [-79.679524, 22.765303],
          [-79.281486, 22.399202],
          [-78.347434, 22.512166],
          [-77.993296, 22.277194],
          [-77.146422, 21.657851],
          [-76.523825, 21.20682],
          [-76.19462, 21.220565],
          [-75.598222, 21.016624],
          [-75.67106, 20.735091],
          [-74.933896, 20.693905],
          [-74.178025, 20.284628],
          [-74.296648, 20.050379],
          [-74.961595, 19.923435],
          [-75.63468, 19.873774],
          [-76.323656, 19.952891],
          [-77.755481, 19.855481],
          [-77.085108, 20.413354],
          [-77.492655, 20.673105],
          [-78.137292, 20.739949],
          [-78.482827, 21.028613],
          [-78.719867, 21.598114],
          [-79.285, 21.559175],
          [-80.217475, 21.827324],
          [-80.517535, 22.037079],
          [-81.820943, 22.192057],
          [-82.169992, 22.387109],
          [-81.795002, 22.636965],
          [-82.775898, 22.68815],
          [-83.494459, 22.168518],
          [-83.9088, 22.154565],
          [-84.052151, 21.910575],
          [-84.54703, 21.801228],
          [-84.974911, 21.896028],
          [-84.447062, 22.20495],
          [-84.230357, 22.565755],
          [-83.77824, 22.788118],
          [-83.267548, 22.983042],
          [-82.510436, 23.078747],
          [-82.268151, 23.188611],
        ],
      ],
    },
    id: 'CU',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Cyprus',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [33.973617, 35.058506],
          [34.004881, 34.978098],
          [32.979827, 34.571869],
          [32.490296, 34.701655],
          [32.256667, 35.103232],
          [32.73178, 35.140026],
          [32.919572, 35.087833],
          [33.190977, 35.173125],
          [33.383833, 35.162712],
          [33.455922, 35.101424],
          [33.475817, 35.000345],
          [33.525685, 35.038688],
          [33.675392, 35.017863],
          [33.86644, 35.093595],
          [33.973617, 35.058506],
        ],
      ],
    },
    id: 'CY',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Czech Republic',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [16.960288, 48.596982],
          [16.499283, 48.785808],
          [16.029647, 48.733899],
          [15.253416, 49.039074],
          [14.901447, 48.964402],
          [14.338898, 48.555305],
          [13.595946, 48.877172],
          [13.031329, 49.307068],
          [12.521024, 49.547415],
          [12.415191, 49.969121],
          [12.240111, 50.266338],
          [12.966837, 50.484076],
          [13.338132, 50.733234],
          [14.056228, 50.926918],
          [14.307013, 51.117268],
          [14.570718, 51.002339],
          [15.016996, 51.106674],
          [15.490972, 50.78473],
          [16.238627, 50.697733],
          [16.176253, 50.422607],
          [16.719476, 50.215747],
          [16.868769, 50.473974],
          [17.554567, 50.362146],
          [17.649445, 50.049038],
          [18.392914, 49.988629],
          [18.853144, 49.49623],
          [18.554971, 49.495015],
          [18.399994, 49.315001],
          [18.170498, 49.271515],
          [18.104973, 49.043983],
          [17.913512, 48.996493],
          [17.886485, 48.903475],
          [17.545007, 48.800019],
          [17.101985, 48.816969],
          [16.960288, 48.596982],
        ],
      ],
    },
    id: 'CZ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Germany',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [9.921906, 54.983104],
          [9.93958, 54.596642],
          [10.950112, 54.363607],
          [10.939467, 54.008693],
          [11.956252, 54.196486],
          [12.51844, 54.470371],
          [13.647467, 54.075511],
          [14.119686, 53.757029],
          [14.353315, 53.248171],
          [14.074521, 52.981263],
          [14.4376, 52.62485],
          [14.685026, 52.089947],
          [14.607098, 51.745188],
          [15.016996, 51.106674],
          [14.570718, 51.002339],
          [14.307013, 51.117268],
          [14.056228, 50.926918],
          [13.338132, 50.733234],
          [12.966837, 50.484076],
          [12.240111, 50.266338],
          [12.415191, 49.969121],
          [12.521024, 49.547415],
          [13.031329, 49.307068],
          [13.595946, 48.877172],
          [13.243357, 48.416115],
          [12.884103, 48.289146],
          [13.025851, 47.637584],
          [12.932627, 47.467646],
          [12.62076, 47.672388],
          [12.141357, 47.703083],
          [11.426414, 47.523766],
          [10.544504, 47.566399],
          [10.402084, 47.302488],
          [9.896068, 47.580197],
          [9.594226, 47.525058],
          [8.522612, 47.830828],
          [8.317301, 47.61358],
          [7.466759, 47.620582],
          [7.593676, 48.333019],
          [8.099279, 49.017784],
          [6.65823, 49.201958],
          [6.18632, 49.463803],
          [6.242751, 49.902226],
          [6.043073, 50.128052],
          [6.156658, 50.803721],
          [5.988658, 51.851616],
          [6.589397, 51.852029],
          [6.84287, 52.22844],
          [7.092053, 53.144043],
          [6.90514, 53.482162],
          [7.100425, 53.693932],
          [7.936239, 53.748296],
          [8.121706, 53.527792],
          [8.800734, 54.020786],
          [8.572118, 54.395646],
          [8.526229, 54.962744],
          [9.282049, 54.830865],
          [9.921906, 54.983104],
        ],
      ],
    },
    id: 'DE',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Djibouti',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [43.081226, 12.699639],
          [43.317852, 12.390148],
          [43.286381, 11.974928],
          [42.715874, 11.735641],
          [43.145305, 11.46204],
          [42.776852, 10.926879],
          [42.55493, 11.10511],
          [42.31414, 11.0342],
          [41.75557, 11.05091],
          [41.73959, 11.35511],
          [41.66176, 11.6312],
          [42, 12.1],
          [42.35156, 12.54223],
          [42.779642, 12.455416],
          [43.081226, 12.699639],
        ],
      ],
    },
    id: 'DJ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Denmark',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [12.690006, 55.609991],
            [12.089991, 54.800015],
            [11.043543, 55.364864],
            [10.903914, 55.779955],
            [12.370904, 56.111407],
            [12.690006, 55.609991],
          ],
        ],
        [
          [
            [10.912182, 56.458621],
            [10.667804, 56.081383],
            [10.369993, 56.190007],
            [9.649985, 55.469999],
            [9.921906, 54.983104],
            [9.282049, 54.830865],
            [8.526229, 54.962744],
            [8.120311, 55.517723],
            [8.089977, 56.540012],
            [8.256582, 56.809969],
            [8.543438, 57.110003],
            [9.424469, 57.172066],
            [9.775559, 57.447941],
            [10.580006, 57.730017],
            [10.546106, 57.215733],
            [10.25, 56.890016],
            [10.369993, 56.609982],
            [10.912182, 56.458621],
          ],
        ],
      ],
    },
    id: 'DK',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Dominican Republic',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-71.712361, 19.714456],
          [-71.587304, 19.884911],
          [-70.806706, 19.880286],
          [-70.214365, 19.622885],
          [-69.950815, 19.648],
          [-69.76925, 19.293267],
          [-69.222126, 19.313214],
          [-69.254346, 19.015196],
          [-68.809412, 18.979074],
          [-68.317943, 18.612198],
          [-68.689316, 18.205142],
          [-69.164946, 18.422648],
          [-69.623988, 18.380713],
          [-69.952934, 18.428307],
          [-70.133233, 18.245915],
          [-70.517137, 18.184291],
          [-70.669298, 18.426886],
          [-70.99995, 18.283329],
          [-71.40021, 17.598564],
          [-71.657662, 17.757573],
          [-71.708305, 18.044997],
          [-71.687738, 18.31666],
          [-71.945112, 18.6169],
          [-71.701303, 18.785417],
          [-71.624873, 19.169838],
          [-71.712361, 19.714456],
        ],
      ],
    },
    id: 'DO',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Algeria',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [11.999506, 23.471668],
          [8.572893, 21.565661],
          [5.677566, 19.601207],
          [4.267419, 19.155265],
          [3.158133, 19.057364],
          [3.146661, 19.693579],
          [2.683588, 19.85623],
          [2.060991, 20.142233],
          [1.823228, 20.610809],
          [-1.550055, 22.792666],
          [-4.923337, 24.974574],
          [-8.6844, 27.395744],
          [-8.665124, 27.589479],
          [-8.66559, 27.656426],
          [-8.674116, 28.841289],
          [-7.059228, 29.579228],
          [-6.060632, 29.7317],
          [-5.242129, 30.000443],
          [-4.859646, 30.501188],
          [-3.690441, 30.896952],
          [-3.647498, 31.637294],
          [-3.06898, 31.724498],
          [-2.616605, 32.094346],
          [-1.307899, 32.262889],
          [-1.124551, 32.651522],
          [-1.388049, 32.864015],
          [-1.733455, 33.919713],
          [-1.792986, 34.527919],
          [-2.169914, 35.168396],
          [-1.208603, 35.714849],
          [-0.127454, 35.888662],
          [0.503877, 36.301273],
          [1.466919, 36.605647],
          [3.161699, 36.783905],
          [4.815758, 36.865037],
          [5.32012, 36.716519],
          [6.26182, 37.110655],
          [7.330385, 37.118381],
          [7.737078, 36.885708],
          [8.420964, 36.946427],
          [8.217824, 36.433177],
          [8.376368, 35.479876],
          [8.140981, 34.655146],
          [7.524482, 34.097376],
          [7.612642, 33.344115],
          [8.430473, 32.748337],
          [8.439103, 32.506285],
          [9.055603, 32.102692],
          [9.48214, 30.307556],
          [9.805634, 29.424638],
          [9.859998, 28.95999],
          [9.683885, 28.144174],
          [9.756128, 27.688259],
          [9.629056, 27.140953],
          [9.716286, 26.512206],
          [9.319411, 26.094325],
          [9.910693, 25.365455],
          [9.948261, 24.936954],
          [10.303847, 24.379313],
          [10.771364, 24.562532],
          [11.560669, 24.097909],
          [11.999506, 23.471668],
        ],
      ],
    },
    id: 'DZ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Ecuador',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-80.302561, -3.404856],
          [-79.770293, -2.657512],
          [-79.986559, -2.220794],
          [-80.368784, -2.685159],
          [-80.967765, -2.246943],
          [-80.764806, -1.965048],
          [-80.933659, -1.057455],
          [-80.58337, -0.906663],
          [-80.399325, -0.283703],
          [-80.020898, 0.36034],
          [-80.09061, 0.768429],
          [-79.542762, 0.982938],
          [-78.855259, 1.380924],
          [-77.855061, 0.809925],
          [-77.668613, 0.825893],
          [-77.424984, 0.395687],
          [-76.57638, 0.256936],
          [-76.292314, 0.416047],
          [-75.801466, 0.084801],
          [-75.373223, -0.152032],
          [-75.233723, -0.911417],
          [-75.544996, -1.56161],
          [-76.635394, -2.608678],
          [-77.837905, -3.003021],
          [-78.450684, -3.873097],
          [-78.639897, -4.547784],
          [-79.205289, -4.959129],
          [-79.624979, -4.454198],
          [-80.028908, -4.346091],
          [-80.442242, -4.425724],
          [-80.469295, -4.059287],
          [-80.184015, -3.821162],
          [-80.302561, -3.404856],
        ],
      ],
    },
    id: 'EC',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Egypt',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [34.9226, 29.50133],
          [34.64174, 29.09942],
          [34.42655, 28.34399],
          [34.15451, 27.8233],
          [33.92136, 27.6487],
          [33.58811, 27.97136],
          [33.13676, 28.41765],
          [32.42323, 29.85108],
          [32.32046, 29.76043],
          [32.73482, 28.70523],
          [33.34876, 27.69989],
          [34.10455, 26.14227],
          [34.47387, 25.59856],
          [34.79507, 25.03375],
          [35.69241, 23.92671],
          [35.49372, 23.75237],
          [35.52598, 23.10244],
          [36.69069, 22.20485],
          [36.86623, 22],
          [32.9, 22],
          [29.02, 22],
          [25, 22],
          [25, 25.6825],
          [25, 29.238655],
          [24.70007, 30.04419],
          [24.95762, 30.6616],
          [24.80287, 31.08929],
          [25.16482, 31.56915],
          [26.49533, 31.58568],
          [27.45762, 31.32126],
          [28.45048, 31.02577],
          [28.91353, 30.87005],
          [29.68342, 31.18686],
          [30.09503, 31.4734],
          [30.97693, 31.55586],
          [31.68796, 31.4296],
          [31.96041, 30.9336],
          [32.19247, 31.26034],
          [32.99392, 31.02407],
          [33.7734, 30.96746],
          [34.26544, 31.21936],
          [34.9226, 29.50133],
        ],
      ],
    },
    id: 'EG',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Eritrea',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [42.35156, 12.54223],
          [42.00975, 12.86582],
          [41.59856, 13.45209],
          [41.155194, 13.77332],
          [40.8966, 14.11864],
          [40.026219, 14.519579],
          [39.34061, 14.53155],
          [39.0994, 14.74064],
          [38.51295, 14.50547],
          [37.90607, 14.95943],
          [37.59377, 14.2131],
          [36.42951, 14.42211],
          [36.323189, 14.822481],
          [36.75386, 16.291874],
          [36.85253, 16.95655],
          [37.16747, 17.26314],
          [37.904, 17.42754],
          [38.41009, 17.998307],
          [38.990623, 16.840626],
          [39.26611, 15.922723],
          [39.814294, 15.435647],
          [41.179275, 14.49108],
          [41.734952, 13.921037],
          [42.276831, 13.343992],
          [42.589576, 13.000421],
          [43.081226, 12.699639],
          [42.779642, 12.455416],
          [42.35156, 12.54223],
        ],
      ],
    },
    id: 'ER',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Spain',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-9.034818, 41.880571],
          [-8.984433, 42.592775],
          [-9.392884, 43.026625],
          [-7.97819, 43.748338],
          [-6.754492, 43.567909],
          [-5.411886, 43.57424],
          [-4.347843, 43.403449],
          [-3.517532, 43.455901],
          [-1.901351, 43.422802],
          [-1.502771, 43.034014],
          [0.338047, 42.579546],
          [0.701591, 42.795734],
          [1.826793, 42.343385],
          [2.985999, 42.473015],
          [3.039484, 41.89212],
          [2.091842, 41.226089],
          [0.810525, 41.014732],
          [0.721331, 40.678318],
          [0.106692, 40.123934],
          [-0.278711, 39.309978],
          [0.111291, 38.738514],
          [-0.467124, 38.292366],
          [-0.683389, 37.642354],
          [-1.438382, 37.443064],
          [-2.146453, 36.674144],
          [-3.415781, 36.6589],
          [-4.368901, 36.677839],
          [-4.995219, 36.324708],
          [-5.37716, 35.94685],
          [-5.866432, 36.029817],
          [-6.236694, 36.367677],
          [-6.520191, 36.942913],
          [-7.453726, 37.097788],
          [-7.537105, 37.428904],
          [-7.166508, 37.803894],
          [-7.029281, 38.075764],
          [-7.374092, 38.373059],
          [-7.098037, 39.030073],
          [-7.498632, 39.629571],
          [-7.066592, 39.711892],
          [-7.026413, 40.184524],
          [-6.86402, 40.330872],
          [-6.851127, 41.111083],
          [-6.389088, 41.381815],
          [-6.668606, 41.883387],
          [-7.251309, 41.918346],
          [-7.422513, 41.792075],
          [-8.013175, 41.790886],
          [-8.263857, 42.280469],
          [-8.671946, 42.134689],
          [-9.034818, 41.880571],
        ],
      ],
    },
    id: 'ES',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Estonia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [24.312863, 57.793424],
          [24.428928, 58.383413],
          [24.061198, 58.257375],
          [23.42656, 58.612753],
          [23.339795, 59.18724],
          [24.604214, 59.465854],
          [25.864189, 59.61109],
          [26.949136, 59.445803],
          [27.981114, 59.475388],
          [28.131699, 59.300825],
          [27.420166, 58.724581],
          [27.716686, 57.791899],
          [27.288185, 57.474528],
          [26.463532, 57.476389],
          [25.60281, 57.847529],
          [25.164594, 57.970157],
          [24.312863, 57.793424],
        ],
      ],
    },
    id: 'EE',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Ethiopia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [37.90607, 14.95943],
          [38.51295, 14.50547],
          [39.0994, 14.74064],
          [39.34061, 14.53155],
          [40.02625, 14.51959],
          [40.8966, 14.11864],
          [41.1552, 13.77333],
          [41.59856, 13.45209],
          [42.00975, 12.86582],
          [42.35156, 12.54223],
          [42, 12.1],
          [41.66176, 11.6312],
          [41.73959, 11.35511],
          [41.75557, 11.05091],
          [42.31414, 11.0342],
          [42.55493, 11.10511],
          [42.776852, 10.926879],
          [42.55876, 10.57258],
          [42.92812, 10.02194],
          [43.29699, 9.54048],
          [43.67875, 9.18358],
          [46.94834, 7.99688],
          [47.78942, 8.003],
          [44.9636, 5.00162],
          [43.66087, 4.95755],
          [42.76967, 4.25259],
          [42.12861, 4.23413],
          [41.855083, 3.918912],
          [41.1718, 3.91909],
          [40.76848, 4.25702],
          [39.85494, 3.83879],
          [39.559384, 3.42206],
          [38.89251, 3.50074],
          [38.67114, 3.61607],
          [38.43697, 3.58851],
          [38.120915, 3.598605],
          [36.855093, 4.447864],
          [36.159079, 4.447864],
          [35.817448, 4.776966],
          [35.817448, 5.338232],
          [35.298007, 5.506],
          [34.70702, 6.59422],
          [34.25032, 6.82607],
          [34.0751, 7.22595],
          [33.56829, 7.71334],
          [32.95418, 7.78497],
          [33.2948, 8.35458],
          [33.8255, 8.37916],
          [33.97498, 8.68456],
          [33.96162, 9.58358],
          [34.25745, 10.63009],
          [34.73115, 10.91017],
          [34.83163, 11.31896],
          [35.26049, 12.08286],
          [35.86363, 12.57828],
          [36.27022, 13.56333],
          [36.42951, 14.42211],
          [37.59377, 14.2131],
          [37.90607, 14.95943],
        ],
      ],
    },
    id: 'ET',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Finland',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [28.59193, 69.064777],
          [28.445944, 68.364613],
          [29.977426, 67.698297],
          [29.054589, 66.944286],
          [30.21765, 65.80598],
          [29.54443, 64.948672],
          [30.444685, 64.204453],
          [30.035872, 63.552814],
          [31.516092, 62.867687],
          [31.139991, 62.357693],
          [30.211107, 61.780028],
          [28.069998, 60.503517],
          [26.255173, 60.423961],
          [24.496624, 60.057316],
          [22.869695, 59.846373],
          [22.290764, 60.391921],
          [21.322244, 60.72017],
          [21.544866, 61.705329],
          [21.059211, 62.607393],
          [21.536029, 63.189735],
          [22.442744, 63.81781],
          [24.730512, 64.902344],
          [25.398068, 65.111427],
          [25.294043, 65.534346],
          [23.903379, 66.006927],
          [23.56588, 66.396051],
          [23.539473, 67.936009],
          [21.978535, 68.616846],
          [20.645593, 69.106247],
          [21.244936, 69.370443],
          [22.356238, 68.841741],
          [23.66205, 68.891247],
          [24.735679, 68.649557],
          [25.689213, 69.092114],
          [26.179622, 69.825299],
          [27.732292, 70.164193],
          [29.015573, 69.766491],
          [28.59193, 69.064777],
        ],
      ],
    },
    id: 'FI',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Fiji',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [178.3736, -17.33992],
            [178.71806, -17.62846],
            [178.55271, -18.15059],
            [177.93266, -18.28799],
            [177.38146, -18.16432],
            [177.28504, -17.72465],
            [177.67087, -17.38114],
            [178.12557, -17.50481],
            [178.3736, -17.33992],
          ],
        ],
        [
          [
            [179.364143, -16.801354],
            [178.725059, -17.012042],
            [178.596839, -16.63915],
            [179.096609, -16.433984],
            [179.413509, -16.379054],
            [180, -16.067133],
            [180, -16.555217],
            [179.364143, -16.801354],
          ],
        ],
        [
          [
            [-179.917369, -16.501783],
            [-180, -16.555217],
            [-180, -16.067133],
            [-179.79332, -16.020882],
            [-179.917369, -16.501783],
          ],
        ],
      ],
    },
    id: 'FJ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Falkland Islands',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-61.2, -51.85],
          [-60, -51.25],
          [-59.15, -51.5],
          [-58.55, -51.1],
          [-57.75, -51.55],
          [-58.05, -51.9],
          [-59.4, -52.2],
          [-59.85, -51.85],
          [-60.7, -52.3],
          [-61.2, -51.85],
        ],
      ],
    },
    id: 'FK',
  },
  {
    type: 'Feature',
    properties: {
      name: 'France',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [9.560016, 42.152492],
            [9.229752, 41.380007],
            [8.775723, 41.583612],
            [8.544213, 42.256517],
            [8.746009, 42.628122],
            [9.390001, 43.009985],
            [9.560016, 42.152492],
          ],
        ],
        [
          [
            [3.588184, 50.378992],
            [4.286023, 49.907497],
            [4.799222, 49.985373],
            [5.674052, 49.529484],
            [5.897759, 49.442667],
            [6.18632, 49.463803],
            [6.65823, 49.201958],
            [8.099279, 49.017784],
            [7.593676, 48.333019],
            [7.466759, 47.620582],
            [7.192202, 47.449766],
            [6.736571, 47.541801],
            [6.768714, 47.287708],
            [6.037389, 46.725779],
            [6.022609, 46.27299],
            [6.5001, 46.429673],
            [6.843593, 45.991147],
            [6.802355, 45.70858],
            [7.096652, 45.333099],
            [6.749955, 45.028518],
            [7.007562, 44.254767],
            [7.549596, 44.127901],
            [7.435185, 43.693845],
            [6.529245, 43.128892],
            [4.556963, 43.399651],
            [3.100411, 43.075201],
            [2.985999, 42.473015],
            [1.826793, 42.343385],
            [0.701591, 42.795734],
            [0.338047, 42.579546],
            [-1.502771, 43.034014],
            [-1.901351, 43.422802],
            [-1.384225, 44.02261],
            [-1.193798, 46.014918],
            [-2.225724, 47.064363],
            [-2.963276, 47.570327],
            [-4.491555, 47.954954],
            [-4.59235, 48.68416],
            [-3.295814, 48.901692],
            [-1.616511, 48.644421],
            [-1.933494, 49.776342],
            [-0.989469, 49.347376],
            [1.338761, 50.127173],
            [1.639001, 50.946606],
            [2.513573, 51.148506],
            [2.658422, 50.796848],
            [3.123252, 50.780363],
            [3.588184, 50.378992],
          ],
        ],
      ],
    },
    id: 'FR',
  },
  {
    type: 'Feature',
    properties: {
      name: 'French Guiana',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-52.556425, 2.504705],
          [-52.939657, 2.124858],
          [-53.418465, 2.053389],
          [-53.554839, 2.334897],
          [-53.778521, 2.376703],
          [-54.088063, 2.105557],
          [-54.524754, 2.311849],
          [-54.27123, 2.738748],
          [-54.184284, 3.194172],
          [-54.011504, 3.62257],
          [-54.399542, 4.212611],
          [-54.478633, 4.896756],
          [-53.958045, 5.756548],
          [-53.618453, 5.646529],
          [-52.882141, 5.409851],
          [-51.823343, 4.565768],
          [-51.657797, 4.156232],
          [-52.249338, 3.241094],
          [-52.556425, 2.504705],
        ],
      ],
    },
    id: 'GF',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Gabon',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [11.093773, -3.978827],
          [10.066135, -2.969483],
          [9.405245, -2.144313],
          [8.797996, -1.111301],
          [8.830087, -0.779074],
          [9.04842, -0.459351],
          [9.291351, 0.268666],
          [9.492889, 1.01012],
          [9.830284, 1.067894],
          [11.285079, 1.057662],
          [11.276449, 2.261051],
          [11.751665, 2.326758],
          [12.35938, 2.192812],
          [12.951334, 2.321616],
          [13.075822, 2.267097],
          [13.003114, 1.830896],
          [13.282631, 1.314184],
          [14.026669, 1.395677],
          [14.276266, 1.19693],
          [13.843321, 0.038758],
          [14.316418, -0.552627],
          [14.425456, -1.333407],
          [14.29921, -1.998276],
          [13.992407, -2.470805],
          [13.109619, -2.42874],
          [12.575284, -1.948511],
          [12.495703, -2.391688],
          [11.820964, -2.514161],
          [11.478039, -2.765619],
          [11.855122, -3.426871],
          [11.093773, -3.978827],
        ],
      ],
    },
    id: 'GA',
  },
  {
    type: 'Feature',
    properties: {
      name: 'United Kingdom',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-5.661949, 54.554603],
            [-6.197885, 53.867565],
            [-6.95373, 54.073702],
            [-7.572168, 54.059956],
            [-7.366031, 54.595841],
            [-7.572168, 55.131622],
            [-6.733847, 55.17286],
            [-5.661949, 54.554603],
          ],
        ],
        [
          [
            [-3.005005, 58.635],
            [-4.073828, 57.553025],
            [-3.055002, 57.690019],
            [-1.959281, 57.6848],
            [-2.219988, 56.870017],
            [-3.119003, 55.973793],
            [-2.085009, 55.909998],
            [-2.005676, 55.804903],
            [-1.114991, 54.624986],
            [-0.430485, 54.464376],
            [0.184981, 53.325014],
            [0.469977, 52.929999],
            [1.681531, 52.73952],
            [1.559988, 52.099998],
            [1.050562, 51.806761],
            [1.449865, 51.289428],
            [0.550334, 50.765739],
            [-0.787517, 50.774989],
            [-2.489998, 50.500019],
            [-2.956274, 50.69688],
            [-3.617448, 50.228356],
            [-4.542508, 50.341837],
            [-5.245023, 49.96],
            [-5.776567, 50.159678],
            [-4.30999, 51.210001],
            [-3.414851, 51.426009],
            [-3.422719, 51.426848],
            [-4.984367, 51.593466],
            [-5.267296, 51.9914],
            [-4.222347, 52.301356],
            [-4.770013, 52.840005],
            [-4.579999, 53.495004],
            [-3.093831, 53.404547],
            [-3.09208, 53.404441],
            [-2.945009, 53.985],
            [-3.614701, 54.600937],
            [-3.630005, 54.615013],
            [-4.844169, 54.790971],
            [-5.082527, 55.061601],
            [-4.719112, 55.508473],
            [-5.047981, 55.783986],
            [-5.586398, 55.311146],
            [-5.644999, 56.275015],
            [-6.149981, 56.78501],
            [-5.786825, 57.818848],
            [-5.009999, 58.630013],
            [-4.211495, 58.550845],
            [-3.005005, 58.635],
          ],
        ],
      ],
    },
    id: 'GB',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Georgia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [41.554084, 41.535656],
          [41.703171, 41.962943],
          [41.45347, 42.645123],
          [40.875469, 43.013628],
          [40.321394, 43.128634],
          [39.955009, 43.434998],
          [40.076965, 43.553104],
          [40.922185, 43.382159],
          [42.394395, 43.220308],
          [43.756017, 42.740828],
          [43.9312, 42.554974],
          [44.537623, 42.711993],
          [45.470279, 42.502781],
          [45.77641, 42.092444],
          [46.404951, 41.860675],
          [46.145432, 41.722802],
          [46.637908, 41.181673],
          [46.501637, 41.064445],
          [45.962601, 41.123873],
          [45.217426, 41.411452],
          [44.97248, 41.248129],
          [43.582746, 41.092143],
          [42.619549, 41.583173],
          [41.554084, 41.535656],
        ],
      ],
    },
    id: 'GE',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Ghana',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [1.060122, 5.928837],
          [-0.507638, 5.343473],
          [-1.063625, 5.000548],
          [-1.964707, 4.710462],
          [-2.856125, 4.994476],
          [-2.810701, 5.389051],
          [-3.24437, 6.250472],
          [-2.983585, 7.379705],
          [-2.56219, 8.219628],
          [-2.827496, 9.642461],
          [-2.963896, 10.395335],
          [-2.940409, 10.96269],
          [-1.203358, 11.009819],
          [-0.761576, 10.93693],
          [-0.438702, 11.098341],
          [0.023803, 11.018682],
          [-0.049785, 10.706918],
          [0.36758, 10.191213],
          [0.365901, 9.465004],
          [0.461192, 8.677223],
          [0.712029, 8.312465],
          [0.490957, 7.411744],
          [0.570384, 6.914359],
          [0.836931, 6.279979],
          [1.060122, 5.928837],
        ],
      ],
    },
    id: 'GH',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Guinea',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-8.439298, 7.686043],
          [-8.722124, 7.711674],
          [-8.926065, 7.309037],
          [-9.208786, 7.313921],
          [-9.403348, 7.526905],
          [-9.33728, 7.928534],
          [-9.755342, 8.541055],
          [-10.016567, 8.428504],
          [-10.230094, 8.406206],
          [-10.505477, 8.348896],
          [-10.494315, 8.715541],
          [-10.65477, 8.977178],
          [-10.622395, 9.26791],
          [-10.839152, 9.688246],
          [-11.117481, 10.045873],
          [-11.917277, 10.046984],
          [-12.150338, 9.858572],
          [-12.425929, 9.835834],
          [-12.596719, 9.620188],
          [-12.711958, 9.342712],
          [-13.24655, 8.903049],
          [-13.685154, 9.494744],
          [-14.074045, 9.886167],
          [-14.330076, 10.01572],
          [-14.579699, 10.214467],
          [-14.693232, 10.656301],
          [-14.839554, 10.876572],
          [-15.130311, 11.040412],
          [-14.685687, 11.527824],
          [-14.382192, 11.509272],
          [-14.121406, 11.677117],
          [-13.9008, 11.678719],
          [-13.743161, 11.811269],
          [-13.828272, 12.142644],
          [-13.718744, 12.247186],
          [-13.700476, 12.586183],
          [-13.217818, 12.575874],
          [-12.499051, 12.33209],
          [-12.278599, 12.35444],
          [-12.203565, 12.465648],
          [-11.658301, 12.386583],
          [-11.513943, 12.442988],
          [-11.456169, 12.076834],
          [-11.297574, 12.077971],
          [-11.036556, 12.211245],
          [-10.87083, 12.177887],
          [-10.593224, 11.923975],
          [-10.165214, 11.844084],
          [-9.890993, 12.060479],
          [-9.567912, 12.194243],
          [-9.327616, 12.334286],
          [-9.127474, 12.30806],
          [-8.905265, 12.088358],
          [-8.786099, 11.812561],
          [-8.376305, 11.393646],
          [-8.581305, 11.136246],
          [-8.620321, 10.810891],
          [-8.407311, 10.909257],
          [-8.282357, 10.792597],
          [-8.335377, 10.494812],
          [-8.029944, 10.206535],
          [-8.229337, 10.12902],
          [-8.309616, 9.789532],
          [-8.079114, 9.376224],
          [-7.8321, 8.575704],
          [-8.203499, 8.455453],
          [-8.299049, 8.316444],
          [-8.221792, 8.123329],
          [-8.280703, 7.68718],
          [-8.439298, 7.686043],
        ],
      ],
    },
    id: 'GN',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Gambia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-16.841525, 13.151394],
          [-16.713729, 13.594959],
          [-15.624596, 13.623587],
          [-15.39877, 13.860369],
          [-15.081735, 13.876492],
          [-14.687031, 13.630357],
          [-14.376714, 13.62568],
          [-14.046992, 13.794068],
          [-13.844963, 13.505042],
          [-14.277702, 13.280585],
          [-14.712197, 13.298207],
          [-15.141163, 13.509512],
          [-15.511813, 13.27857],
          [-15.691001, 13.270353],
          [-15.931296, 13.130284],
          [-16.841525, 13.151394],
        ],
      ],
    },
    id: 'GM',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Guinea Bissau',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-15.130311, 11.040412],
          [-15.66418, 11.458474],
          [-16.085214, 11.524594],
          [-16.314787, 11.806515],
          [-16.308947, 11.958702],
          [-16.613838, 12.170911],
          [-16.677452, 12.384852],
          [-16.147717, 12.547762],
          [-15.816574, 12.515567],
          [-15.548477, 12.62817],
          [-13.700476, 12.586183],
          [-13.718744, 12.247186],
          [-13.828272, 12.142644],
          [-13.743161, 11.811269],
          [-13.9008, 11.678719],
          [-14.121406, 11.677117],
          [-14.382192, 11.509272],
          [-14.685687, 11.527824],
          [-15.130311, 11.040412],
        ],
      ],
    },
    id: 'GW',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Equatorial Guinea',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [9.492889, 1.01012],
          [9.305613, 1.160911],
          [9.649158, 2.283866],
          [11.276449, 2.261051],
          [11.285079, 1.057662],
          [9.830284, 1.067894],
          [9.492889, 1.01012],
        ],
      ],
    },
    id: 'GQ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Greece',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [23.69998, 35.705004],
            [24.246665, 35.368022],
            [25.025015, 35.424996],
            [25.769208, 35.354018],
            [25.745023, 35.179998],
            [26.290003, 35.29999],
            [26.164998, 35.004995],
            [24.724982, 34.919988],
            [24.735007, 35.084991],
            [23.514978, 35.279992],
            [23.69998, 35.705004],
          ],
        ],
        [
          [
            [26.604196, 41.562115],
            [26.294602, 40.936261],
            [26.056942, 40.824123],
            [25.447677, 40.852545],
            [24.925848, 40.947062],
            [23.714811, 40.687129],
            [24.407999, 40.124993],
            [23.899968, 39.962006],
            [23.342999, 39.960998],
            [22.813988, 40.476005],
            [22.626299, 40.256561],
            [22.849748, 39.659311],
            [23.350027, 39.190011],
            [22.973099, 38.970903],
            [23.530016, 38.510001],
            [24.025025, 38.219993],
            [24.040011, 37.655015],
            [23.115003, 37.920011],
            [23.409972, 37.409991],
            [22.774972, 37.30501],
            [23.154225, 36.422506],
            [22.490028, 36.41],
            [21.670026, 36.844986],
            [21.295011, 37.644989],
            [21.120034, 38.310323],
            [20.730032, 38.769985],
            [20.217712, 39.340235],
            [20.150016, 39.624998],
            [20.615, 40.110007],
            [20.674997, 40.435],
            [20.99999, 40.580004],
            [21.02004, 40.842727],
            [21.674161, 40.931275],
            [22.055378, 41.149866],
            [22.597308, 41.130487],
            [22.76177, 41.3048],
            [22.952377, 41.337994],
            [23.692074, 41.309081],
            [24.492645, 41.583896],
            [25.197201, 41.234486],
            [26.106138, 41.328899],
            [26.117042, 41.826905],
            [26.604196, 41.562115],
          ],
        ],
      ],
    },
    id: 'GR',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Greenland',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-46.76379, 82.62796],
          [-43.40644, 83.22516],
          [-39.89753, 83.18018],
          [-38.62214, 83.54905],
          [-35.08787, 83.64513],
          [-27.10046, 83.51966],
          [-20.84539, 82.72669],
          [-22.69182, 82.34165],
          [-26.51753, 82.29765],
          [-31.9, 82.2],
          [-31.39646, 82.02154],
          [-27.85666, 82.13178],
          [-24.84448, 81.78697],
          [-22.90328, 82.09317],
          [-22.07175, 81.73449],
          [-23.16961, 81.15271],
          [-20.62363, 81.52462],
          [-15.76818, 81.91245],
          [-12.77018, 81.71885],
          [-12.20855, 81.29154],
          [-16.28533, 80.58004],
          [-16.85, 80.35],
          [-20.04624, 80.17708],
          [-17.73035, 80.12912],
          [-18.9, 79.4],
          [-19.70499, 78.75128],
          [-19.67353, 77.63859],
          [-18.47285, 76.98565],
          [-20.03503, 76.94434],
          [-21.67944, 76.62795],
          [-19.83407, 76.09808],
          [-19.59896, 75.24838],
          [-20.66818, 75.15585],
          [-19.37281, 74.29561],
          [-21.59422, 74.22382],
          [-20.43454, 73.81713],
          [-20.76234, 73.46436],
          [-22.17221, 73.30955],
          [-23.56593, 73.30663],
          [-22.31311, 72.62928],
          [-22.29954, 72.18409],
          [-24.27834, 72.59788],
          [-24.79296, 72.3302],
          [-23.44296, 72.08016],
          [-22.13281, 71.46898],
          [-21.75356, 70.66369],
          [-23.53603, 70.471],
          [-24.30702, 70.85649],
          [-25.54341, 71.43094],
          [-25.20135, 70.75226],
          [-26.36276, 70.22646],
          [-23.72742, 70.18401],
          [-22.34902, 70.12946],
          [-25.02927, 69.2588],
          [-27.74737, 68.47046],
          [-30.67371, 68.12503],
          [-31.77665, 68.12078],
          [-32.81105, 67.73547],
          [-34.20196, 66.67974],
          [-36.35284, 65.9789],
          [-37.04378, 65.93768],
          [-38.37505, 65.69213],
          [-39.81222, 65.45848],
          [-40.66899, 64.83997],
          [-40.68281, 64.13902],
          [-41.1887, 63.48246],
          [-42.81938, 62.68233],
          [-42.41666, 61.90093],
          [-42.86619, 61.07404],
          [-43.3784, 60.09772],
          [-44.7875, 60.03676],
          [-46.26364, 60.85328],
          [-48.26294, 60.85843],
          [-49.23308, 61.40681],
          [-49.90039, 62.38336],
          [-51.63325, 63.62691],
          [-52.14014, 64.27842],
          [-52.27659, 65.1767],
          [-53.66166, 66.09957],
          [-53.30161, 66.8365],
          [-53.96911, 67.18899],
          [-52.9804, 68.35759],
          [-51.47536, 68.72958],
          [-51.08041, 69.14781],
          [-50.87122, 69.9291],
          [-52.013585, 69.574925],
          [-52.55792, 69.42616],
          [-53.45629, 69.283625],
          [-54.68336, 69.61003],
          [-54.75001, 70.28932],
          [-54.35884, 70.821315],
          [-53.431315, 70.835755],
          [-51.39014, 70.56978],
          [-53.10937, 71.20485],
          [-54.00422, 71.54719],
          [-55, 71.406537],
          [-55.83468, 71.65444],
          [-54.71819, 72.58625],
          [-55.32634, 72.95861],
          [-56.12003, 73.64977],
          [-57.32363, 74.71026],
          [-58.59679, 75.09861],
          [-58.58516, 75.51727],
          [-61.26861, 76.10238],
          [-63.39165, 76.1752],
          [-66.06427, 76.13486],
          [-68.50438, 76.06141],
          [-69.66485, 76.37975],
          [-71.40257, 77.00857],
          [-68.77671, 77.32312],
          [-66.76397, 77.37595],
          [-71.04293, 77.63595],
          [-73.297, 78.04419],
          [-73.15938, 78.43271],
          [-69.37345, 78.91388],
          [-65.7107, 79.39436],
          [-65.3239, 79.75814],
          [-68.02298, 80.11721],
          [-67.15129, 80.51582],
          [-63.68925, 81.21396],
          [-62.23444, 81.3211],
          [-62.65116, 81.77042],
          [-60.28249, 82.03363],
          [-57.20744, 82.19074],
          [-54.13442, 82.19962],
          [-53.04328, 81.88833],
          [-50.39061, 82.43883],
          [-48.00386, 82.06481],
          [-46.59984, 81.985945],
          [-44.523, 81.6607],
          [-46.9007, 82.19979],
          [-46.76379, 82.62796],
        ],
      ],
    },
    id: 'GL',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Guatemala',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-90.095555, 13.735338],
          [-90.608624, 13.909771],
          [-91.23241, 13.927832],
          [-91.689747, 14.126218],
          [-92.22775, 14.538829],
          [-92.20323, 14.830103],
          [-92.087216, 15.064585],
          [-92.229249, 15.251447],
          [-91.74796, 16.066565],
          [-90.464473, 16.069562],
          [-90.438867, 16.41011],
          [-90.600847, 16.470778],
          [-90.711822, 16.687483],
          [-91.08167, 16.918477],
          [-91.453921, 17.252177],
          [-91.002269, 17.254658],
          [-91.00152, 17.817595],
          [-90.067934, 17.819326],
          [-89.14308, 17.808319],
          [-89.150806, 17.015577],
          [-89.229122, 15.886938],
          [-88.930613, 15.887273],
          [-88.604586, 15.70638],
          [-88.518364, 15.855389],
          [-88.225023, 15.727722],
          [-88.68068, 15.346247],
          [-89.154811, 15.066419],
          [-89.22522, 14.874286],
          [-89.145535, 14.678019],
          [-89.353326, 14.424133],
          [-89.587343, 14.362586],
          [-89.534219, 14.244816],
          [-89.721934, 14.134228],
          [-90.064678, 13.88197],
          [-90.095555, 13.735338],
        ],
      ],
    },
    id: 'GT',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Guyana',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-59.758285, 8.367035],
          [-59.101684, 7.999202],
          [-58.482962, 7.347691],
          [-58.454876, 6.832787],
          [-58.078103, 6.809094],
          [-57.542219, 6.321268],
          [-57.147436, 5.97315],
          [-57.307246, 5.073567],
          [-57.914289, 4.812626],
          [-57.86021, 4.576801],
          [-58.044694, 4.060864],
          [-57.601569, 3.334655],
          [-57.281433, 3.333492],
          [-57.150098, 2.768927],
          [-56.539386, 1.899523],
          [-56.782704, 1.863711],
          [-57.335823, 1.948538],
          [-57.660971, 1.682585],
          [-58.11345, 1.507195],
          [-58.429477, 1.463942],
          [-58.540013, 1.268088],
          [-59.030862, 1.317698],
          [-59.646044, 1.786894],
          [-59.718546, 2.24963],
          [-59.974525, 2.755233],
          [-59.815413, 3.606499],
          [-59.53804, 3.958803],
          [-59.767406, 4.423503],
          [-60.111002, 4.574967],
          [-59.980959, 5.014061],
          [-60.213683, 5.244486],
          [-60.733574, 5.200277],
          [-61.410303, 5.959068],
          [-61.139415, 6.234297],
          [-61.159336, 6.696077],
          [-60.543999, 6.856584],
          [-60.295668, 7.043911],
          [-60.637973, 7.415],
          [-60.550588, 7.779603],
          [-59.758285, 8.367035],
        ],
      ],
    },
    id: 'GY',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Honduras',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-87.316654, 12.984686],
          [-87.489409, 13.297535],
          [-87.793111, 13.38448],
          [-87.723503, 13.78505],
          [-87.859515, 13.893312],
          [-88.065343, 13.964626],
          [-88.503998, 13.845486],
          [-88.541231, 13.980155],
          [-88.843073, 14.140507],
          [-89.058512, 14.340029],
          [-89.353326, 14.424133],
          [-89.145535, 14.678019],
          [-89.22522, 14.874286],
          [-89.154811, 15.066419],
          [-88.68068, 15.346247],
          [-88.225023, 15.727722],
          [-88.121153, 15.688655],
          [-87.901813, 15.864458],
          [-87.61568, 15.878799],
          [-87.522921, 15.797279],
          [-87.367762, 15.84694],
          [-86.903191, 15.756713],
          [-86.440946, 15.782835],
          [-86.119234, 15.893449],
          [-86.001954, 16.005406],
          [-85.683317, 15.953652],
          [-85.444004, 15.885749],
          [-85.182444, 15.909158],
          [-84.983722, 15.995923],
          [-84.52698, 15.857224],
          [-84.368256, 15.835158],
          [-84.063055, 15.648244],
          [-83.773977, 15.424072],
          [-83.410381, 15.270903],
          [-83.147219, 14.995829],
          [-83.489989, 15.016267],
          [-83.628585, 14.880074],
          [-83.975721, 14.749436],
          [-84.228342, 14.748764],
          [-84.449336, 14.621614],
          [-84.649582, 14.666805],
          [-84.820037, 14.819587],
          [-84.924501, 14.790493],
          [-85.052787, 14.551541],
          [-85.148751, 14.560197],
          [-85.165365, 14.35437],
          [-85.514413, 14.079012],
          [-85.698665, 13.960078],
          [-85.801295, 13.836055],
          [-86.096264, 14.038187],
          [-86.312142, 13.771356],
          [-86.520708, 13.778487],
          [-86.755087, 13.754845],
          [-86.733822, 13.263093],
          [-86.880557, 13.254204],
          [-87.005769, 13.025794],
          [-87.316654, 12.984686],
        ],
      ],
    },
    id: 'HN',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Croatia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [18.829838, 45.908878],
          [19.072769, 45.521511],
          [19.390476, 45.236516],
          [19.005486, 44.860234],
          [18.553214, 45.08159],
          [17.861783, 45.06774],
          [17.002146, 45.233777],
          [16.534939, 45.211608],
          [16.318157, 45.004127],
          [15.959367, 45.233777],
          [15.750026, 44.818712],
          [16.23966, 44.351143],
          [16.456443, 44.04124],
          [16.916156, 43.667722],
          [17.297373, 43.446341],
          [17.674922, 43.028563],
          [18.56, 42.65],
          [18.450016, 42.479991],
          [17.50997, 42.849995],
          [16.930006, 43.209998],
          [16.015385, 43.507215],
          [15.174454, 44.243191],
          [15.37625, 44.317915],
          [14.920309, 44.738484],
          [14.901602, 45.07606],
          [14.258748, 45.233777],
          [13.952255, 44.802124],
          [13.656976, 45.136935],
          [13.679403, 45.484149],
          [13.71506, 45.500324],
          [14.411968, 45.466166],
          [14.595109, 45.634941],
          [14.935244, 45.471695],
          [15.327675, 45.452316],
          [15.323954, 45.731783],
          [15.67153, 45.834154],
          [15.768733, 46.238108],
          [16.564808, 46.503751],
          [16.882515, 46.380632],
          [17.630066, 45.951769],
          [18.456062, 45.759481],
          [18.829838, 45.908878],
        ],
      ],
    },
    id: 'HR',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Haiti',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-73.189791, 19.915684],
          [-72.579673, 19.871501],
          [-71.712361, 19.714456],
          [-71.624873, 19.169838],
          [-71.701303, 18.785417],
          [-71.945112, 18.6169],
          [-71.687738, 18.31666],
          [-71.708305, 18.044997],
          [-72.372476, 18.214961],
          [-72.844411, 18.145611],
          [-73.454555, 18.217906],
          [-73.922433, 18.030993],
          [-74.458034, 18.34255],
          [-74.369925, 18.664908],
          [-73.449542, 18.526053],
          [-72.694937, 18.445799],
          [-72.334882, 18.668422],
          [-72.79165, 19.101625],
          [-72.784105, 19.483591],
          [-73.415022, 19.639551],
          [-73.189791, 19.915684],
        ],
      ],
    },
    id: 'HT',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Hungary',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [16.202298, 46.852386],
          [16.534268, 47.496171],
          [16.340584, 47.712902],
          [16.903754, 47.714866],
          [16.979667, 48.123497],
          [17.488473, 47.867466],
          [17.857133, 47.758429],
          [18.696513, 47.880954],
          [18.777025, 48.081768],
          [19.174365, 48.111379],
          [19.661364, 48.266615],
          [19.769471, 48.202691],
          [20.239054, 48.327567],
          [20.473562, 48.56285],
          [20.801294, 48.623854],
          [21.872236, 48.319971],
          [22.085608, 48.422264],
          [22.64082, 48.15024],
          [22.710531, 47.882194],
          [22.099768, 47.672439],
          [21.626515, 46.994238],
          [21.021952, 46.316088],
          [20.220192, 46.127469],
          [19.596045, 46.17173],
          [18.829838, 45.908878],
          [18.456062, 45.759481],
          [17.630066, 45.951769],
          [16.882515, 46.380632],
          [16.564808, 46.503751],
          [16.370505, 46.841327],
          [16.202298, 46.852386],
        ],
      ],
    },
    id: 'HU',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Indonesia',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [120.715609, -10.239581],
            [120.295014, -10.25865],
            [118.967808, -9.557969],
            [119.90031, -9.36134],
            [120.425756, -9.665921],
            [120.775502, -9.969675],
            [120.715609, -10.239581],
          ],
        ],
        [
          [
            [124.43595, -10.140001],
            [123.579982, -10.359987],
            [123.459989, -10.239995],
            [123.550009, -9.900016],
            [123.980009, -9.290027],
            [124.968682, -8.89279],
            [125.07002, -9.089987],
            [125.08852, -9.393173],
            [124.43595, -10.140001],
          ],
        ],
        [
          [
            [117.900018, -8.095681],
            [118.260616, -8.362383],
            [118.87846, -8.280683],
            [119.126507, -8.705825],
            [117.970402, -8.906639],
            [117.277731, -9.040895],
            [116.740141, -9.032937],
            [117.083737, -8.457158],
            [117.632024, -8.449303],
            [117.900018, -8.095681],
          ],
        ],
        [
          [
            [122.903537, -8.094234],
            [122.756983, -8.649808],
            [121.254491, -8.933666],
            [119.924391, -8.810418],
            [119.920929, -8.444859],
            [120.715092, -8.236965],
            [121.341669, -8.53674],
            [122.007365, -8.46062],
            [122.903537, -8.094234],
          ],
        ],
        [
          [
            [108.623479, -6.777674],
            [110.539227, -6.877358],
            [110.759576, -6.465186],
            [112.614811, -6.946036],
            [112.978768, -7.594213],
            [114.478935, -7.776528],
            [115.705527, -8.370807],
            [114.564511, -8.751817],
            [113.464734, -8.348947],
            [112.559672, -8.376181],
            [111.522061, -8.302129],
            [110.58615, -8.122605],
            [109.427667, -7.740664],
            [108.693655, -7.6416],
            [108.277763, -7.766657],
            [106.454102, -7.3549],
            [106.280624, -6.9249],
            [105.365486, -6.851416],
            [106.051646, -5.895919],
            [107.265009, -5.954985],
            [108.072091, -6.345762],
            [108.486846, -6.421985],
            [108.623479, -6.777674],
          ],
        ],
        [
          [
            [134.724624, -6.214401],
            [134.210134, -6.895238],
            [134.112776, -6.142467],
            [134.290336, -5.783058],
            [134.499625, -5.445042],
            [134.727002, -5.737582],
            [134.724624, -6.214401],
          ],
        ],
        [
          [
            [127.249215, -3.459065],
            [126.874923, -3.790983],
            [126.183802, -3.607376],
            [125.989034, -3.177273],
            [127.000651, -3.129318],
            [127.249215, -3.459065],
          ],
        ],
        [
          [
            [130.471344, -3.093764],
            [130.834836, -3.858472],
            [129.990547, -3.446301],
            [129.155249, -3.362637],
            [128.590684, -3.428679],
            [127.898891, -3.393436],
            [128.135879, -2.84365],
            [129.370998, -2.802154],
            [130.471344, -3.093764],
          ],
        ],
        [
          [
            [134.143368, -1.151867],
            [134.422627, -2.769185],
            [135.457603, -3.367753],
            [136.293314, -2.307042],
            [137.440738, -1.703513],
            [138.329727, -1.702686],
            [139.184921, -2.051296],
            [139.926684, -2.409052],
            [141.00021, -2.600151],
            [141.017057, -5.859022],
            [141.033852, -9.117893],
            [140.143415, -8.297168],
            [139.127767, -8.096043],
            [138.881477, -8.380935],
            [137.614474, -8.411683],
            [138.039099, -7.597882],
            [138.668621, -7.320225],
            [138.407914, -6.232849],
            [137.92784, -5.393366],
            [135.98925, -4.546544],
            [135.164598, -4.462931],
            [133.66288, -3.538853],
            [133.367705, -4.024819],
            [132.983956, -4.112979],
            [132.756941, -3.746283],
            [132.753789, -3.311787],
            [131.989804, -2.820551],
            [133.066845, -2.460418],
            [133.780031, -2.479848],
            [133.696212, -2.214542],
            [132.232373, -2.212526],
            [131.836222, -1.617162],
            [130.94284, -1.432522],
            [130.519558, -0.93772],
            [131.867538, -0.695461],
            [132.380116, -0.369538],
            [133.985548, -0.78021],
            [134.143368, -1.151867],
          ],
        ],
        [
          [
            [125.240501, 1.419836],
            [124.437035, 0.427881],
            [123.685505, 0.235593],
            [122.723083, 0.431137],
            [121.056725, 0.381217],
            [120.183083, 0.237247],
            [120.04087, -0.519658],
            [120.935905, -1.408906],
            [121.475821, -0.955962],
            [123.340565, -0.615673],
            [123.258399, -1.076213],
            [122.822715, -0.930951],
            [122.38853, -1.516858],
            [121.508274, -1.904483],
            [122.454572, -3.186058],
            [122.271896, -3.5295],
            [123.170963, -4.683693],
            [123.162333, -5.340604],
            [122.628515, -5.634591],
            [122.236394, -5.282933],
            [122.719569, -4.464172],
            [121.738234, -4.851331],
            [121.489463, -4.574553],
            [121.619171, -4.188478],
            [120.898182, -3.602105],
            [120.972389, -2.627643],
            [120.305453, -2.931604],
            [120.390047, -4.097579],
            [120.430717, -5.528241],
            [119.796543, -5.6734],
            [119.366906, -5.379878],
            [119.653606, -4.459417],
            [119.498835, -3.494412],
            [119.078344, -3.487022],
            [118.767769, -2.801999],
            [119.180974, -2.147104],
            [119.323394, -1.353147],
            [119.825999, 0.154254],
            [120.035702, 0.566477],
            [120.885779, 1.309223],
            [121.666817, 1.013944],
            [122.927567, 0.875192],
            [124.077522, 0.917102],
            [125.065989, 1.643259],
            [125.240501, 1.419836],
          ],
        ],
        [
          [
            [128.688249, 1.132386],
            [128.635952, 0.258486],
            [128.12017, 0.356413],
            [127.968034, -0.252077],
            [128.379999, -0.780004],
            [128.100016, -0.899996],
            [127.696475, -0.266598],
            [127.39949, 1.011722],
            [127.600512, 1.810691],
            [127.932378, 2.174596],
            [128.004156, 1.628531],
            [128.594559, 1.540811],
            [128.688249, 1.132386],
          ],
        ],
        [
          [
            [117.875627, 1.827641],
            [118.996747, 0.902219],
            [117.811858, 0.784242],
            [117.478339, 0.102475],
            [117.521644, -0.803723],
            [116.560048, -1.487661],
            [116.533797, -2.483517],
            [116.148084, -4.012726],
            [116.000858, -3.657037],
            [114.864803, -4.106984],
            [114.468652, -3.495704],
            [113.755672, -3.43917],
            [113.256994, -3.118776],
            [112.068126, -3.478392],
            [111.703291, -2.994442],
            [111.04824, -3.049426],
            [110.223846, -2.934032],
            [110.070936, -1.592874],
            [109.571948, -1.314907],
            [109.091874, -0.459507],
            [108.952658, 0.415375],
            [109.069136, 1.341934],
            [109.66326, 2.006467],
            [109.830227, 1.338136],
            [110.514061, 0.773131],
            [111.159138, 0.976478],
            [111.797548, 0.904441],
            [112.380252, 1.410121],
            [112.859809, 1.49779],
            [113.80585, 1.217549],
            [114.621355, 1.430688],
            [115.134037, 2.821482],
            [115.519078, 3.169238],
            [115.865517, 4.306559],
            [117.015214, 4.306094],
            [117.882035, 4.137551],
            [117.313232, 3.234428],
            [118.04833, 2.28769],
            [117.875627, 1.827641],
          ],
        ],
        [
          [
            [105.817655, -5.852356],
            [104.710384, -5.873285],
            [103.868213, -5.037315],
            [102.584261, -4.220259],
            [102.156173, -3.614146],
            [101.399113, -2.799777],
            [100.902503, -2.050262],
            [100.141981, -0.650348],
            [99.26374, 0.183142],
            [98.970011, 1.042882],
            [98.601351, 1.823507],
            [97.699598, 2.453184],
            [97.176942, 3.308791],
            [96.424017, 3.86886],
            [95.380876, 4.970782],
            [95.293026, 5.479821],
            [95.936863, 5.439513],
            [97.484882, 5.246321],
            [98.369169, 4.26837],
            [99.142559, 3.59035],
            [99.693998, 3.174329],
            [100.641434, 2.099381],
            [101.658012, 2.083697],
            [102.498271, 1.3987],
            [103.07684, 0.561361],
            [103.838396, 0.104542],
            [103.437645, -0.711946],
            [104.010789, -1.059212],
            [104.369991, -1.084843],
            [104.53949, -1.782372],
            [104.887893, -2.340425],
            [105.622111, -2.428844],
            [106.108593, -3.061777],
            [105.857446, -4.305525],
            [105.817655, -5.852356],
          ],
        ],
      ],
    },
    id: 'ID',
  },
  {
    type: 'Feature',
    properties: {
      name: 'India',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [77.837451, 35.49401],
          [78.912269, 34.321936],
          [78.811086, 33.506198],
          [79.208892, 32.994395],
          [79.176129, 32.48378],
          [78.458446, 32.618164],
          [78.738894, 31.515906],
          [79.721367, 30.882715],
          [81.111256, 30.183481],
          [80.476721, 29.729865],
          [80.088425, 28.79447],
          [81.057203, 28.416095],
          [81.999987, 27.925479],
          [83.304249, 27.364506],
          [84.675018, 27.234901],
          [85.251779, 26.726198],
          [86.024393, 26.630985],
          [87.227472, 26.397898],
          [88.060238, 26.414615],
          [88.174804, 26.810405],
          [88.043133, 27.445819],
          [88.120441, 27.876542],
          [88.730326, 28.086865],
          [88.814248, 27.299316],
          [88.835643, 27.098966],
          [89.744528, 26.719403],
          [90.373275, 26.875724],
          [91.217513, 26.808648],
          [92.033484, 26.83831],
          [92.103712, 27.452614],
          [91.696657, 27.771742],
          [92.503119, 27.896876],
          [93.413348, 28.640629],
          [94.56599, 29.277438],
          [95.404802, 29.031717],
          [96.117679, 29.452802],
          [96.586591, 28.83098],
          [96.248833, 28.411031],
          [97.327114, 28.261583],
          [97.402561, 27.882536],
          [97.051989, 27.699059],
          [97.133999, 27.083774],
          [96.419366, 27.264589],
          [95.124768, 26.573572],
          [95.155153, 26.001307],
          [94.603249, 25.162495],
          [94.552658, 24.675238],
          [94.106742, 23.850741],
          [93.325188, 24.078556],
          [93.286327, 23.043658],
          [93.060294, 22.703111],
          [93.166128, 22.27846],
          [92.672721, 22.041239],
          [92.146035, 23.627499],
          [91.869928, 23.624346],
          [91.706475, 22.985264],
          [91.158963, 23.503527],
          [91.46773, 24.072639],
          [91.915093, 24.130414],
          [92.376202, 24.976693],
          [91.799596, 25.147432],
          [90.872211, 25.132601],
          [89.920693, 25.26975],
          [89.832481, 25.965082],
          [89.355094, 26.014407],
          [88.563049, 26.446526],
          [88.209789, 25.768066],
          [88.931554, 25.238692],
          [88.306373, 24.866079],
          [88.084422, 24.501657],
          [88.69994, 24.233715],
          [88.52977, 23.631142],
          [88.876312, 22.879146],
          [89.031961, 22.055708],
          [88.888766, 21.690588],
          [88.208497, 21.703172],
          [86.975704, 21.495562],
          [87.033169, 20.743308],
          [86.499351, 20.151638],
          [85.060266, 19.478579],
          [83.941006, 18.30201],
          [83.189217, 17.671221],
          [82.192792, 17.016636],
          [82.191242, 16.556664],
          [81.692719, 16.310219],
          [80.791999, 15.951972],
          [80.324896, 15.899185],
          [80.025069, 15.136415],
          [80.233274, 13.835771],
          [80.286294, 13.006261],
          [79.862547, 12.056215],
          [79.857999, 10.357275],
          [79.340512, 10.308854],
          [78.885345, 9.546136],
          [79.18972, 9.216544],
          [78.277941, 8.933047],
          [77.941165, 8.252959],
          [77.539898, 7.965535],
          [76.592979, 8.899276],
          [76.130061, 10.29963],
          [75.746467, 11.308251],
          [75.396101, 11.781245],
          [74.864816, 12.741936],
          [74.616717, 13.992583],
          [74.443859, 14.617222],
          [73.534199, 15.990652],
          [73.119909, 17.92857],
          [72.820909, 19.208234],
          [72.824475, 20.419503],
          [72.630533, 21.356009],
          [71.175273, 20.757441],
          [70.470459, 20.877331],
          [69.16413, 22.089298],
          [69.644928, 22.450775],
          [69.349597, 22.84318],
          [68.176645, 23.691965],
          [68.842599, 24.359134],
          [71.04324, 24.356524],
          [70.844699, 25.215102],
          [70.282873, 25.722229],
          [70.168927, 26.491872],
          [69.514393, 26.940966],
          [70.616496, 27.989196],
          [71.777666, 27.91318],
          [72.823752, 28.961592],
          [73.450638, 29.976413],
          [74.42138, 30.979815],
          [74.405929, 31.692639],
          [75.258642, 32.271105],
          [74.451559, 32.7649],
          [74.104294, 33.441473],
          [73.749948, 34.317699],
          [74.240203, 34.748887],
          [75.757061, 34.504923],
          [76.871722, 34.653544],
          [77.837451, 35.49401],
        ],
      ],
    },
    id: 'IN',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Ireland',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-6.197885, 53.867565],
          [-6.032985, 53.153164],
          [-6.788857, 52.260118],
          [-8.561617, 51.669301],
          [-9.977086, 51.820455],
          [-9.166283, 52.864629],
          [-9.688525, 53.881363],
          [-8.327987, 54.664519],
          [-7.572168, 55.131622],
          [-7.366031, 54.595841],
          [-7.572168, 54.059956],
          [-6.95373, 54.073702],
          [-6.197885, 53.867565],
        ],
      ],
    },
    id: 'IE',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Iran',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [53.921598, 37.198918],
          [54.800304, 37.392421],
          [55.511578, 37.964117],
          [56.180375, 37.935127],
          [56.619366, 38.121394],
          [57.330434, 38.029229],
          [58.436154, 37.522309],
          [59.234762, 37.412988],
          [60.377638, 36.527383],
          [61.123071, 36.491597],
          [61.210817, 35.650072],
          [60.803193, 34.404102],
          [60.52843, 33.676446],
          [60.9637, 33.528832],
          [60.536078, 32.981269],
          [60.863655, 32.18292],
          [60.941945, 31.548075],
          [61.699314, 31.379506],
          [61.781222, 30.73585],
          [60.874248, 29.829239],
          [61.369309, 29.303276],
          [61.771868, 28.699334],
          [62.72783, 28.259645],
          [62.755426, 27.378923],
          [63.233898, 27.217047],
          [63.316632, 26.756532],
          [61.874187, 26.239975],
          [61.497363, 25.078237],
          [59.616134, 25.380157],
          [58.525761, 25.609962],
          [57.397251, 25.739902],
          [56.970766, 26.966106],
          [56.492139, 27.143305],
          [55.72371, 26.964633],
          [54.71509, 26.480658],
          [53.493097, 26.812369],
          [52.483598, 27.580849],
          [51.520763, 27.86569],
          [50.852948, 28.814521],
          [50.115009, 30.147773],
          [49.57685, 29.985715],
          [48.941333, 30.31709],
          [48.567971, 29.926778],
          [48.014568, 30.452457],
          [48.004698, 30.985137],
          [47.685286, 30.984853],
          [47.849204, 31.709176],
          [47.334661, 32.469155],
          [46.109362, 33.017287],
          [45.416691, 33.967798],
          [45.64846, 34.748138],
          [46.151788, 35.093259],
          [46.07634, 35.677383],
          [45.420618, 35.977546],
          [44.77267, 37.17045],
          [44.225756, 37.971584],
          [44.421403, 38.281281],
          [44.109225, 39.428136],
          [44.79399, 39.713003],
          [44.952688, 39.335765],
          [45.457722, 38.874139],
          [46.143623, 38.741201],
          [46.50572, 38.770605],
          [47.685079, 39.508364],
          [48.060095, 39.582235],
          [48.355529, 39.288765],
          [48.010744, 38.794015],
          [48.634375, 38.270378],
          [48.883249, 38.320245],
          [49.199612, 37.582874],
          [50.147771, 37.374567],
          [50.842354, 36.872814],
          [52.264025, 36.700422],
          [53.82579, 36.965031],
          [53.921598, 37.198918],
        ],
      ],
    },
    id: 'IR',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Iraq',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [45.420618, 35.977546],
          [46.07634, 35.677383],
          [46.151788, 35.093259],
          [45.64846, 34.748138],
          [45.416691, 33.967798],
          [46.109362, 33.017287],
          [47.334661, 32.469155],
          [47.849204, 31.709176],
          [47.685286, 30.984853],
          [48.004698, 30.985137],
          [48.014568, 30.452457],
          [48.567971, 29.926778],
          [47.974519, 29.975819],
          [47.302622, 30.05907],
          [46.568713, 29.099025],
          [44.709499, 29.178891],
          [41.889981, 31.190009],
          [40.399994, 31.889992],
          [39.195468, 32.161009],
          [38.792341, 33.378686],
          [41.006159, 34.419372],
          [41.383965, 35.628317],
          [41.289707, 36.358815],
          [41.837064, 36.605854],
          [42.349591, 37.229873],
          [42.779126, 37.385264],
          [43.942259, 37.256228],
          [44.293452, 37.001514],
          [44.772699, 37.170445],
          [45.420618, 35.977546],
        ],
      ],
    },
    id: 'IQ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Iceland',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-14.508695, 66.455892],
          [-14.739637, 65.808748],
          [-13.609732, 65.126671],
          [-14.909834, 64.364082],
          [-17.794438, 63.678749],
          [-18.656246, 63.496383],
          [-19.972755, 63.643635],
          [-22.762972, 63.960179],
          [-21.778484, 64.402116],
          [-23.955044, 64.89113],
          [-22.184403, 65.084968],
          [-22.227423, 65.378594],
          [-24.326184, 65.611189],
          [-23.650515, 66.262519],
          [-22.134922, 66.410469],
          [-20.576284, 65.732112],
          [-19.056842, 66.276601],
          [-17.798624, 65.993853],
          [-16.167819, 66.526792],
          [-14.508695, 66.455892],
        ],
      ],
    },
    id: 'IS',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Israel',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [35.719918, 32.709192],
          [35.545665, 32.393992],
          [35.18393, 32.532511],
          [34.974641, 31.866582],
          [35.225892, 31.754341],
          [34.970507, 31.616778],
          [34.927408, 31.353435],
          [35.397561, 31.489086],
          [35.420918, 31.100066],
          [34.922603, 29.501326],
          [34.265433, 31.219361],
          [34.556372, 31.548824],
          [34.488107, 31.605539],
          [34.752587, 32.072926],
          [34.955417, 32.827376],
          [35.098457, 33.080539],
          [35.126053, 33.0909],
          [35.460709, 33.08904],
          [35.552797, 33.264275],
          [35.821101, 33.277426],
          [35.836397, 32.868123],
          [35.700798, 32.716014],
          [35.719918, 32.709192],
        ],
      ],
    },
    id: 'IL',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Italy',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [15.520376, 38.231155],
            [15.160243, 37.444046],
            [15.309898, 37.134219],
            [15.099988, 36.619987],
            [14.335229, 36.996631],
            [13.826733, 37.104531],
            [12.431004, 37.61295],
            [12.570944, 38.126381],
            [13.741156, 38.034966],
            [14.761249, 38.143874],
            [15.520376, 38.231155],
          ],
        ],
        [
          [
            [9.210012, 41.209991],
            [9.809975, 40.500009],
            [9.669519, 39.177376],
            [9.214818, 39.240473],
            [8.806936, 38.906618],
            [8.428302, 39.171847],
            [8.388253, 40.378311],
            [8.159998, 40.950007],
            [8.709991, 40.899984],
            [9.210012, 41.209991],
          ],
        ],
        [
          [
            [12.376485, 46.767559],
            [13.806475, 46.509306],
            [13.69811, 46.016778],
            [13.93763, 45.591016],
            [13.141606, 45.736692],
            [12.328581, 45.381778],
            [12.383875, 44.885374],
            [12.261453, 44.600482],
            [12.589237, 44.091366],
            [13.526906, 43.587727],
            [14.029821, 42.761008],
            [15.14257, 41.95514],
            [15.926191, 41.961315],
            [16.169897, 41.740295],
            [15.889346, 41.541082],
            [16.785002, 41.179606],
            [17.519169, 40.877143],
            [18.376687, 40.355625],
            [18.480247, 40.168866],
            [18.293385, 39.810774],
            [17.73838, 40.277671],
            [16.869596, 40.442235],
            [16.448743, 39.795401],
            [17.17149, 39.4247],
            [17.052841, 38.902871],
            [16.635088, 38.843572],
            [16.100961, 37.985899],
            [15.684087, 37.908849],
            [15.687963, 38.214593],
            [15.891981, 38.750942],
            [16.109332, 38.964547],
            [15.718814, 39.544072],
            [15.413613, 40.048357],
            [14.998496, 40.172949],
            [14.703268, 40.60455],
            [14.060672, 40.786348],
            [13.627985, 41.188287],
            [12.888082, 41.25309],
            [12.106683, 41.704535],
            [11.191906, 42.355425],
            [10.511948, 42.931463],
            [10.200029, 43.920007],
            [9.702488, 44.036279],
            [8.888946, 44.366336],
            [8.428561, 44.231228],
            [7.850767, 43.767148],
            [7.435185, 43.693845],
            [7.549596, 44.127901],
            [7.007562, 44.254767],
            [6.749955, 45.028518],
            [7.096652, 45.333099],
            [6.802355, 45.70858],
            [6.843593, 45.991147],
            [7.273851, 45.776948],
            [7.755992, 45.82449],
            [8.31663, 46.163642],
            [8.489952, 46.005151],
            [8.966306, 46.036932],
            [9.182882, 46.440215],
            [9.922837, 46.314899],
            [10.363378, 46.483571],
            [10.442701, 46.893546],
            [11.048556, 46.751359],
            [11.164828, 46.941579],
            [12.153088, 47.115393],
            [12.376485, 46.767559],
          ],
        ],
      ],
    },
    id: 'IT',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Jamaica',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-77.569601, 18.490525],
          [-76.896619, 18.400867],
          [-76.365359, 18.160701],
          [-76.199659, 17.886867],
          [-76.902561, 17.868238],
          [-77.206341, 17.701116],
          [-77.766023, 17.861597],
          [-78.337719, 18.225968],
          [-78.217727, 18.454533],
          [-77.797365, 18.524218],
          [-77.569601, 18.490525],
        ],
      ],
    },
    id: 'JM',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Jordan',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [35.545665, 32.393992],
          [35.719918, 32.709192],
          [36.834062, 32.312938],
          [38.792341, 33.378686],
          [39.195468, 32.161009],
          [39.004886, 32.010217],
          [37.002166, 31.508413],
          [37.998849, 30.5085],
          [37.66812, 30.338665],
          [37.503582, 30.003776],
          [36.740528, 29.865283],
          [36.501214, 29.505254],
          [36.068941, 29.197495],
          [34.956037, 29.356555],
          [34.922603, 29.501326],
          [35.420918, 31.100066],
          [35.397561, 31.489086],
          [35.545252, 31.782505],
          [35.545665, 32.393992],
        ],
      ],
    },
    id: 'JO',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Japan',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [134.638428, 34.149234],
            [134.766379, 33.806335],
            [134.203416, 33.201178],
            [133.79295, 33.521985],
            [133.280268, 33.28957],
            [133.014858, 32.704567],
            [132.363115, 32.989382],
            [132.371176, 33.463642],
            [132.924373, 34.060299],
            [133.492968, 33.944621],
            [133.904106, 34.364931],
            [134.638428, 34.149234],
          ],
        ],
        [
          [
            [140.976388, 37.142074],
            [140.59977, 36.343983],
            [140.774074, 35.842877],
            [140.253279, 35.138114],
            [138.975528, 34.6676],
            [137.217599, 34.606286],
            [135.792983, 33.464805],
            [135.120983, 33.849071],
            [135.079435, 34.596545],
            [133.340316, 34.375938],
            [132.156771, 33.904933],
            [130.986145, 33.885761],
            [132.000036, 33.149992],
            [131.33279, 31.450355],
            [130.686318, 31.029579],
            [130.20242, 31.418238],
            [130.447676, 32.319475],
            [129.814692, 32.61031],
            [129.408463, 33.296056],
            [130.353935, 33.604151],
            [130.878451, 34.232743],
            [131.884229, 34.749714],
            [132.617673, 35.433393],
            [134.608301, 35.731618],
            [135.677538, 35.527134],
            [136.723831, 37.304984],
            [137.390612, 36.827391],
            [138.857602, 37.827485],
            [139.426405, 38.215962],
            [140.05479, 39.438807],
            [139.883379, 40.563312],
            [140.305783, 41.195005],
            [141.368973, 41.37856],
            [141.914263, 39.991616],
            [141.884601, 39.180865],
            [140.959489, 38.174001],
            [140.976388, 37.142074],
          ],
        ],
        [
          [
            [143.910162, 44.1741],
            [144.613427, 43.960883],
            [145.320825, 44.384733],
            [145.543137, 43.262088],
            [144.059662, 42.988358],
            [143.18385, 41.995215],
            [141.611491, 42.678791],
            [141.067286, 41.584594],
            [139.955106, 41.569556],
            [139.817544, 42.563759],
            [140.312087, 43.333273],
            [141.380549, 43.388825],
            [141.671952, 44.772125],
            [141.967645, 45.551483],
            [143.14287, 44.510358],
            [143.910162, 44.1741],
          ],
        ],
      ],
    },
    id: 'JP',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Kazakhstan',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [70.962315, 42.266154],
          [70.388965, 42.081308],
          [69.070027, 41.384244],
          [68.632483, 40.668681],
          [68.259896, 40.662325],
          [67.985856, 41.135991],
          [66.714047, 41.168444],
          [66.510649, 41.987644],
          [66.023392, 41.994646],
          [66.098012, 42.99766],
          [64.900824, 43.728081],
          [63.185787, 43.650075],
          [62.0133, 43.504477],
          [61.05832, 44.405817],
          [60.239972, 44.784037],
          [58.689989, 45.500014],
          [58.503127, 45.586804],
          [55.928917, 44.995858],
          [55.968191, 41.308642],
          [55.455251, 41.259859],
          [54.755345, 42.043971],
          [54.079418, 42.324109],
          [52.944293, 42.116034],
          [52.50246, 41.783316],
          [52.446339, 42.027151],
          [52.692112, 42.443895],
          [52.501426, 42.792298],
          [51.342427, 43.132975],
          [50.891292, 44.031034],
          [50.339129, 44.284016],
          [50.305643, 44.609836],
          [51.278503, 44.514854],
          [51.316899, 45.245998],
          [52.16739, 45.408391],
          [53.040876, 45.259047],
          [53.220866, 46.234646],
          [53.042737, 46.853006],
          [52.042023, 46.804637],
          [51.191945, 47.048705],
          [50.034083, 46.60899],
          [49.10116, 46.39933],
          [48.593241, 46.561034],
          [48.694734, 47.075628],
          [48.057253, 47.743753],
          [47.315231, 47.715847],
          [46.466446, 48.394152],
          [47.043672, 49.152039],
          [46.751596, 49.356006],
          [47.54948, 50.454698],
          [48.577841, 49.87476],
          [48.702382, 50.605128],
          [50.766648, 51.692762],
          [52.328724, 51.718652],
          [54.532878, 51.02624],
          [55.716941, 50.621717],
          [56.777961, 51.043551],
          [58.363291, 51.063653],
          [59.642282, 50.545442],
          [59.932807, 50.842194],
          [61.337424, 50.79907],
          [61.588003, 51.272659],
          [59.967534, 51.96042],
          [60.927269, 52.447548],
          [60.739993, 52.719986],
          [61.699986, 52.979996],
          [60.978066, 53.664993],
          [61.436591, 54.006265],
          [65.178534, 54.354228],
          [65.666876, 54.601267],
          [68.1691, 54.970392],
          [69.068167, 55.38525],
          [70.865267, 55.169734],
          [71.180131, 54.133285],
          [72.22415, 54.376655],
          [73.508516, 54.035617],
          [73.425679, 53.48981],
          [74.384845, 53.546861],
          [76.8911, 54.490524],
          [76.525179, 54.177003],
          [77.800916, 53.404415],
          [80.03556, 50.864751],
          [80.568447, 51.388336],
          [81.945986, 50.812196],
          [83.383004, 51.069183],
          [83.935115, 50.889246],
          [84.416377, 50.3114],
          [85.11556, 50.117303],
          [85.54127, 49.692859],
          [86.829357, 49.826675],
          [87.35997, 49.214981],
          [86.598776, 48.549182],
          [85.768233, 48.455751],
          [85.720484, 47.452969],
          [85.16429, 47.000956],
          [83.180484, 47.330031],
          [82.458926, 45.53965],
          [81.947071, 45.317027],
          [79.966106, 44.917517],
          [80.866206, 43.180362],
          [80.18015, 42.920068],
          [80.25999, 42.349999],
          [79.643645, 42.496683],
          [79.142177, 42.856092],
          [77.658392, 42.960686],
          [76.000354, 42.988022],
          [75.636965, 42.8779],
          [74.212866, 43.298339],
          [73.645304, 43.091272],
          [73.489758, 42.500894],
          [71.844638, 42.845395],
          [71.186281, 42.704293],
          [70.962315, 42.266154],
        ],
      ],
    },
    id: 'KZ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Kenya',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [40.993, -0.85829],
          [41.58513, -1.68325],
          [40.88477, -2.08255],
          [40.63785, -2.49979],
          [40.26304, -2.57309],
          [40.12119, -3.27768],
          [39.80006, -3.68116],
          [39.60489, -4.34653],
          [39.20222, -4.67677],
          [37.7669, -3.67712],
          [37.69869, -3.09699],
          [34.07262, -1.05982],
          [33.903711, -0.95],
          [33.893569, 0.109814],
          [34.18, 0.515],
          [34.6721, 1.17694],
          [35.03599, 1.90584],
          [34.59607, 3.05374],
          [34.47913, 3.5556],
          [34.005, 4.249885],
          [34.620196, 4.847123],
          [35.298007, 5.506],
          [35.817448, 5.338232],
          [35.817448, 4.776966],
          [36.159079, 4.447864],
          [36.855093, 4.447864],
          [38.120915, 3.598605],
          [38.43697, 3.58851],
          [38.67114, 3.61607],
          [38.89251, 3.50074],
          [39.559384, 3.42206],
          [39.85494, 3.83879],
          [40.76848, 4.25702],
          [41.1718, 3.91909],
          [41.855083, 3.918912],
          [40.98105, 2.78452],
          [40.993, -0.85829],
        ],
      ],
    },
    id: 'KE',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Kyrgyzstan',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [70.962315, 42.266154],
          [71.186281, 42.704293],
          [71.844638, 42.845395],
          [73.489758, 42.500894],
          [73.645304, 43.091272],
          [74.212866, 43.298339],
          [75.636965, 42.8779],
          [76.000354, 42.988022],
          [77.658392, 42.960686],
          [79.142177, 42.856092],
          [79.643645, 42.496683],
          [80.25999, 42.349999],
          [80.11943, 42.123941],
          [78.543661, 41.582243],
          [78.187197, 41.185316],
          [76.904484, 41.066486],
          [76.526368, 40.427946],
          [75.467828, 40.562072],
          [74.776862, 40.366425],
          [73.822244, 39.893973],
          [73.960013, 39.660008],
          [73.675379, 39.431237],
          [71.784694, 39.279463],
          [70.549162, 39.604198],
          [69.464887, 39.526683],
          [69.55961, 40.103211],
          [70.648019, 39.935754],
          [71.014198, 40.244366],
          [71.774875, 40.145844],
          [73.055417, 40.866033],
          [71.870115, 41.3929],
          [71.157859, 41.143587],
          [70.420022, 41.519998],
          [71.259248, 42.167711],
          [70.962315, 42.266154],
        ],
      ],
    },
    id: 'KG',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Cambodia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [103.49728, 10.632555],
          [103.09069, 11.153661],
          [102.584932, 12.186595],
          [102.348099, 13.394247],
          [102.988422, 14.225721],
          [104.281418, 14.416743],
          [105.218777, 14.273212],
          [106.043946, 13.881091],
          [106.496373, 14.570584],
          [107.382727, 14.202441],
          [107.614548, 13.535531],
          [107.491403, 12.337206],
          [105.810524, 11.567615],
          [106.24967, 10.961812],
          [105.199915, 10.88931],
          [104.334335, 10.486544],
          [103.49728, 10.632555],
        ],
      ],
    },
    id: 'KH',
  },
  {
    // todo map
    type: 'Feature',
    properties: {
      name: 'Kosovo',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [20.76216, 42.05186],
          [20.71731, 41.84711],
          [20.59023, 41.85541],
          [20.52295, 42.21787],
          [20.28374, 42.32025],
          [20.0707, 42.58863],
          [20.25758, 42.81275],
          [20.49679, 42.88469],
          [20.63508, 43.21671],
          [20.81448, 43.27205],
          [20.95651, 43.13094],
          [21.143395, 43.068685],
          [21.27421, 42.90959],
          [21.43866, 42.86255],
          [21.63302, 42.67717],
          [21.77505, 42.6827],
          [21.66292, 42.43922],
          [21.54332, 42.32025],
          [21.576636, 42.245224],
          [21.3527, 42.2068],
          [20.76216, 42.05186],
        ],
      ],
    },
    id: 'OSA',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Kuwait',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [47.974519, 29.975819],
          [48.183189, 29.534477],
          [48.093943, 29.306299],
          [48.416094, 28.552004],
          [47.708851, 28.526063],
          [47.459822, 29.002519],
          [46.568713, 29.099025],
          [47.302622, 30.05907],
          [47.974519, 29.975819],
        ],
      ],
    },
    id: 'KW',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Laos',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [105.218777, 14.273212],
          [105.544338, 14.723934],
          [105.589039, 15.570316],
          [104.779321, 16.441865],
          [104.716947, 17.428859],
          [103.956477, 18.240954],
          [103.200192, 18.309632],
          [102.998706, 17.961695],
          [102.413005, 17.932782],
          [102.113592, 18.109102],
          [101.059548, 17.512497],
          [101.035931, 18.408928],
          [101.282015, 19.462585],
          [100.606294, 19.508344],
          [100.548881, 20.109238],
          [100.115988, 20.41785],
          [100.329101, 20.786122],
          [101.180005, 21.436573],
          [101.270026, 21.201652],
          [101.80312, 21.174367],
          [101.652018, 22.318199],
          [102.170436, 22.464753],
          [102.754896, 21.675137],
          [103.203861, 20.766562],
          [104.435, 20.758733],
          [104.822574, 19.886642],
          [104.183388, 19.624668],
          [103.896532, 19.265181],
          [105.094598, 18.666975],
          [105.925762, 17.485315],
          [106.556008, 16.604284],
          [107.312706, 15.908538],
          [107.564525, 15.202173],
          [107.382727, 14.202441],
          [106.496373, 14.570584],
          [106.043946, 13.881091],
          [105.218777, 14.273212],
        ],
      ],
    },
    id: 'LA',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Lebanon',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [35.821101, 33.277426],
          [35.552797, 33.264275],
          [35.460709, 33.08904],
          [35.126053, 33.0909],
          [35.482207, 33.90545],
          [35.979592, 34.610058],
          [35.998403, 34.644914],
          [36.448194, 34.593935],
          [36.61175, 34.201789],
          [36.06646, 33.824912],
          [35.821101, 33.277426],
        ],
      ],
    },
    id: 'LB',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Liberia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-7.712159, 4.364566],
          [-7.974107, 4.355755],
          [-9.004794, 4.832419],
          [-9.91342, 5.593561],
          [-10.765384, 6.140711],
          [-11.438779, 6.785917],
          [-11.199802, 7.105846],
          [-11.146704, 7.396706],
          [-10.695595, 7.939464],
          [-10.230094, 8.406206],
          [-10.016567, 8.428504],
          [-9.755342, 8.541055],
          [-9.33728, 7.928534],
          [-9.403348, 7.526905],
          [-9.208786, 7.313921],
          [-8.926065, 7.309037],
          [-8.722124, 7.711674],
          [-8.439298, 7.686043],
          [-8.485446, 7.395208],
          [-8.385452, 6.911801],
          [-8.60288, 6.467564],
          [-8.311348, 6.193033],
          [-7.993693, 6.12619],
          [-7.570153, 5.707352],
          [-7.539715, 5.313345],
          [-7.635368, 5.188159],
          [-7.712159, 4.364566],
        ],
      ],
    },
    id: 'LR',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Libya',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [14.8513, 22.86295],
          [14.143871, 22.491289],
          [13.581425, 23.040506],
          [11.999506, 23.471668],
          [11.560669, 24.097909],
          [10.771364, 24.562532],
          [10.303847, 24.379313],
          [9.948261, 24.936954],
          [9.910693, 25.365455],
          [9.319411, 26.094325],
          [9.716286, 26.512206],
          [9.629056, 27.140953],
          [9.756128, 27.688259],
          [9.683885, 28.144174],
          [9.859998, 28.95999],
          [9.805634, 29.424638],
          [9.48214, 30.307556],
          [9.970017, 30.539325],
          [10.056575, 30.961831],
          [9.950225, 31.37607],
          [10.636901, 31.761421],
          [10.94479, 32.081815],
          [11.432253, 32.368903],
          [11.488787, 33.136996],
          [12.66331, 32.79278],
          [13.08326, 32.87882],
          [13.91868, 32.71196],
          [15.24563, 32.26508],
          [15.71394, 31.37626],
          [16.61162, 31.18218],
          [18.02109, 30.76357],
          [19.08641, 30.26639],
          [19.57404, 30.52582],
          [20.05335, 30.98576],
          [19.82033, 31.75179],
          [20.13397, 32.2382],
          [20.85452, 32.7068],
          [21.54298, 32.8432],
          [22.89576, 32.63858],
          [23.2368, 32.19149],
          [23.60913, 32.18726],
          [23.9275, 32.01667],
          [24.92114, 31.89936],
          [25.16482, 31.56915],
          [24.80287, 31.08929],
          [24.95762, 30.6616],
          [24.70007, 30.04419],
          [25, 29.238655],
          [25, 25.6825],
          [25, 22],
          [25, 20.00304],
          [23.85, 20],
          [23.83766, 19.58047],
          [19.84926, 21.49509],
          [15.86085, 23.40972],
          [14.8513, 22.86295],
        ],
      ],
    },
    id: 'LY',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Sri Lanka',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [81.787959, 7.523055],
          [81.637322, 6.481775],
          [81.21802, 6.197141],
          [80.348357, 5.96837],
          [79.872469, 6.763463],
          [79.695167, 8.200843],
          [80.147801, 9.824078],
          [80.838818, 9.268427],
          [81.304319, 8.564206],
          [81.787959, 7.523055],
        ],
      ],
    },
    id: 'LK',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Lesotho',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [28.978263, -28.955597],
          [29.325166, -29.257387],
          [29.018415, -29.743766],
          [28.8484, -30.070051],
          [28.291069, -30.226217],
          [28.107205, -30.545732],
          [27.749397, -30.645106],
          [26.999262, -29.875954],
          [27.532511, -29.242711],
          [28.074338, -28.851469],
          [28.5417, -28.647502],
          [28.978263, -28.955597],
        ],
      ],
    },
    id: 'LS',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Lithuania',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [22.731099, 54.327537],
          [22.651052, 54.582741],
          [22.757764, 54.856574],
          [22.315724, 55.015299],
          [21.268449, 55.190482],
          [21.0558, 56.031076],
          [22.201157, 56.337802],
          [23.878264, 56.273671],
          [24.860684, 56.372528],
          [25.000934, 56.164531],
          [25.533047, 56.100297],
          [26.494331, 55.615107],
          [26.588279, 55.167176],
          [25.768433, 54.846963],
          [25.536354, 54.282423],
          [24.450684, 53.905702],
          [23.484128, 53.912498],
          [23.243987, 54.220567],
          [22.731099, 54.327537],
        ],
      ],
    },
    id: 'LT',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Luxembourg',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [6.043073, 50.128052],
          [6.242751, 49.902226],
          [6.18632, 49.463803],
          [5.897759, 49.442667],
          [5.674052, 49.529484],
          [5.782417, 50.090328],
          [6.043073, 50.128052],
        ],
      ],
    },
    id: 'LU',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Latvia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [21.0558, 56.031076],
          [21.090424, 56.783873],
          [21.581866, 57.411871],
          [22.524341, 57.753374],
          [23.318453, 57.006236],
          [24.12073, 57.025693],
          [24.312863, 57.793424],
          [25.164594, 57.970157],
          [25.60281, 57.847529],
          [26.463532, 57.476389],
          [27.288185, 57.474528],
          [27.770016, 57.244258],
          [27.855282, 56.759326],
          [28.176709, 56.16913],
          [27.10246, 55.783314],
          [26.494331, 55.615107],
          [25.533047, 56.100297],
          [25.000934, 56.164531],
          [24.860684, 56.372528],
          [23.878264, 56.273671],
          [22.201157, 56.337802],
          [21.0558, 56.031076],
        ],
      ],
    },
    id: 'LV',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Morocco',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-5.193863, 35.755182],
          [-4.591006, 35.330712],
          [-3.640057, 35.399855],
          [-2.604306, 35.179093],
          [-2.169914, 35.168396],
          [-1.792986, 34.527919],
          [-1.733455, 33.919713],
          [-1.388049, 32.864015],
          [-1.124551, 32.651522],
          [-1.307899, 32.262889],
          [-2.616605, 32.094346],
          [-3.06898, 31.724498],
          [-3.647498, 31.637294],
          [-3.690441, 30.896952],
          [-4.859646, 30.501188],
          [-5.242129, 30.000443],
          [-6.060632, 29.7317],
          [-7.059228, 29.579228],
          [-8.674116, 28.841289],
          [-8.66559, 27.656426],
          [-8.817809, 27.656426],
          [-8.817828, 27.656426],
          [-8.794884, 27.120696],
          [-9.413037, 27.088476],
          [-9.735343, 26.860945],
          [-10.189424, 26.860945],
          [-10.551263, 26.990808],
          [-11.392555, 26.883424],
          [-11.71822, 26.104092],
          [-12.030759, 26.030866],
          [-12.500963, 24.770116],
          [-13.89111, 23.691009],
          [-14.221168, 22.310163],
          [-14.630833, 21.86094],
          [-14.750955, 21.5006],
          [-17.002962, 21.420734],
          [-17.020428, 21.42231],
          [-16.973248, 21.885745],
          [-16.589137, 22.158234],
          [-16.261922, 22.67934],
          [-16.326414, 23.017768],
          [-15.982611, 23.723358],
          [-15.426004, 24.359134],
          [-15.089332, 24.520261],
          [-14.824645, 25.103533],
          [-14.800926, 25.636265],
          [-14.43994, 26.254418],
          [-13.773805, 26.618892],
          [-13.139942, 27.640148],
          [-13.121613, 27.654148],
          [-12.618837, 28.038186],
          [-11.688919, 28.148644],
          [-10.900957, 28.832142],
          [-10.399592, 29.098586],
          [-9.564811, 29.933574],
          [-9.814718, 31.177736],
          [-9.434793, 32.038096],
          [-9.300693, 32.564679],
          [-8.657476, 33.240245],
          [-7.654178, 33.697065],
          [-6.912544, 34.110476],
          [-6.244342, 35.145865],
          [-5.929994, 35.759988],
          [-5.193863, 35.755182],
        ],
      ],
    },
    id: 'MA',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Moldova',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [26.619337, 48.220726],
          [26.857824, 48.368211],
          [27.522537, 48.467119],
          [28.259547, 48.155562],
          [28.670891, 48.118149],
          [29.122698, 47.849095],
          [29.050868, 47.510227],
          [29.415135, 47.346645],
          [29.559674, 46.928583],
          [29.908852, 46.674361],
          [29.83821, 46.525326],
          [30.024659, 46.423937],
          [29.759972, 46.349988],
          [29.170654, 46.379262],
          [29.072107, 46.517678],
          [28.862972, 46.437889],
          [28.933717, 46.25883],
          [28.659987, 45.939987],
          [28.485269, 45.596907],
          [28.233554, 45.488283],
          [28.054443, 45.944586],
          [28.160018, 46.371563],
          [28.12803, 46.810476],
          [27.551166, 47.405117],
          [27.233873, 47.826771],
          [26.924176, 48.123264],
          [26.619337, 48.220726],
        ],
      ],
    },
    id: 'MD',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Madagascar',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [49.543519, -12.469833],
          [49.808981, -12.895285],
          [50.056511, -13.555761],
          [50.217431, -14.758789],
          [50.476537, -15.226512],
          [50.377111, -15.706069],
          [50.200275, -16.000263],
          [49.860606, -15.414253],
          [49.672607, -15.710204],
          [49.863344, -16.451037],
          [49.774564, -16.875042],
          [49.498612, -17.106036],
          [49.435619, -17.953064],
          [49.041792, -19.118781],
          [48.548541, -20.496888],
          [47.930749, -22.391501],
          [47.547723, -23.781959],
          [47.095761, -24.94163],
          [46.282478, -25.178463],
          [45.409508, -25.601434],
          [44.833574, -25.346101],
          [44.03972, -24.988345],
          [43.763768, -24.460677],
          [43.697778, -23.574116],
          [43.345654, -22.776904],
          [43.254187, -22.057413],
          [43.433298, -21.336475],
          [43.893683, -21.163307],
          [43.89637, -20.830459],
          [44.374325, -20.072366],
          [44.464397, -19.435454],
          [44.232422, -18.961995],
          [44.042976, -18.331387],
          [43.963084, -17.409945],
          [44.312469, -16.850496],
          [44.446517, -16.216219],
          [44.944937, -16.179374],
          [45.502732, -15.974373],
          [45.872994, -15.793454],
          [46.312243, -15.780018],
          [46.882183, -15.210182],
          [47.70513, -14.594303],
          [48.005215, -14.091233],
          [47.869047, -13.663869],
          [48.293828, -13.784068],
          [48.84506, -13.089175],
          [48.863509, -12.487868],
          [49.194651, -12.040557],
          [49.543519, -12.469833],
        ],
      ],
    },
    id: 'MG',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Mexico',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-97.140008, 25.869997],
          [-97.528072, 24.992144],
          [-97.702946, 24.272343],
          [-97.776042, 22.93258],
          [-97.872367, 22.444212],
          [-97.699044, 21.898689],
          [-97.38896, 21.411019],
          [-97.189333, 20.635433],
          [-96.525576, 19.890931],
          [-96.292127, 19.320371],
          [-95.900885, 18.828024],
          [-94.839063, 18.562717],
          [-94.42573, 18.144371],
          [-93.548651, 18.423837],
          [-92.786114, 18.524839],
          [-92.037348, 18.704569],
          [-91.407903, 18.876083],
          [-90.77187, 19.28412],
          [-90.53359, 19.867418],
          [-90.451476, 20.707522],
          [-90.278618, 20.999855],
          [-89.601321, 21.261726],
          [-88.543866, 21.493675],
          [-87.658417, 21.458846],
          [-87.05189, 21.543543],
          [-86.811982, 21.331515],
          [-86.845908, 20.849865],
          [-87.383291, 20.255405],
          [-87.621054, 19.646553],
          [-87.43675, 19.472403],
          [-87.58656, 19.04013],
          [-87.837191, 18.259816],
          [-88.090664, 18.516648],
          [-88.300031, 18.499982],
          [-88.490123, 18.486831],
          [-88.848344, 17.883198],
          [-89.029857, 18.001511],
          [-89.150909, 17.955468],
          [-89.14308, 17.808319],
          [-90.067934, 17.819326],
          [-91.00152, 17.817595],
          [-91.002269, 17.254658],
          [-91.453921, 17.252177],
          [-91.08167, 16.918477],
          [-90.711822, 16.687483],
          [-90.600847, 16.470778],
          [-90.438867, 16.41011],
          [-90.464473, 16.069562],
          [-91.74796, 16.066565],
          [-92.229249, 15.251447],
          [-92.087216, 15.064585],
          [-92.20323, 14.830103],
          [-92.22775, 14.538829],
          [-93.359464, 15.61543],
          [-93.875169, 15.940164],
          [-94.691656, 16.200975],
          [-95.250227, 16.128318],
          [-96.053382, 15.752088],
          [-96.557434, 15.653515],
          [-97.263592, 15.917065],
          [-98.01303, 16.107312],
          [-98.947676, 16.566043],
          [-99.697397, 16.706164],
          [-100.829499, 17.171071],
          [-101.666089, 17.649026],
          [-101.918528, 17.91609],
          [-102.478132, 17.975751],
          [-103.50099, 18.292295],
          [-103.917527, 18.748572],
          [-104.99201, 19.316134],
          [-105.493038, 19.946767],
          [-105.731396, 20.434102],
          [-105.397773, 20.531719],
          [-105.500661, 20.816895],
          [-105.270752, 21.076285],
          [-105.265817, 21.422104],
          [-105.603161, 21.871146],
          [-105.693414, 22.26908],
          [-106.028716, 22.773752],
          [-106.90998, 23.767774],
          [-107.915449, 24.548915],
          [-108.401905, 25.172314],
          [-109.260199, 25.580609],
          [-109.444089, 25.824884],
          [-109.291644, 26.442934],
          [-109.801458, 26.676176],
          [-110.391732, 27.162115],
          [-110.641019, 27.859876],
          [-111.178919, 27.941241],
          [-111.759607, 28.467953],
          [-112.228235, 28.954409],
          [-112.271824, 29.266844],
          [-112.809594, 30.021114],
          [-113.163811, 30.786881],
          [-113.148669, 31.170966],
          [-113.871881, 31.567608],
          [-114.205737, 31.524045],
          [-114.776451, 31.799532],
          [-114.9367, 31.393485],
          [-114.771232, 30.913617],
          [-114.673899, 30.162681],
          [-114.330974, 29.750432],
          [-113.588875, 29.061611],
          [-113.424053, 28.826174],
          [-113.271969, 28.754783],
          [-113.140039, 28.411289],
          [-112.962298, 28.42519],
          [-112.761587, 27.780217],
          [-112.457911, 27.525814],
          [-112.244952, 27.171727],
          [-111.616489, 26.662817],
          [-111.284675, 25.73259],
          [-110.987819, 25.294606],
          [-110.710007, 24.826004],
          [-110.655049, 24.298595],
          [-110.172856, 24.265548],
          [-109.771847, 23.811183],
          [-109.409104, 23.364672],
          [-109.433392, 23.185588],
          [-109.854219, 22.818272],
          [-110.031392, 22.823078],
          [-110.295071, 23.430973],
          [-110.949501, 24.000964],
          [-111.670568, 24.484423],
          [-112.182036, 24.738413],
          [-112.148989, 25.470125],
          [-112.300711, 26.012004],
          [-112.777297, 26.32196],
          [-113.464671, 26.768186],
          [-113.59673, 26.63946],
          [-113.848937, 26.900064],
          [-114.465747, 27.14209],
          [-115.055142, 27.722727],
          [-114.982253, 27.7982],
          [-114.570366, 27.741485],
          [-114.199329, 28.115003],
          [-114.162018, 28.566112],
          [-114.931842, 29.279479],
          [-115.518654, 29.556362],
          [-115.887365, 30.180794],
          [-116.25835, 30.836464],
          [-116.721526, 31.635744],
          [-117.12776, 32.53534],
          [-115.99135, 32.61239],
          [-114.72139, 32.72083],
          [-114.815, 32.52528],
          [-113.30498, 32.03914],
          [-111.02361, 31.33472],
          [-109.035, 31.34194],
          [-108.24194, 31.34222],
          [-108.24, 31.754854],
          [-106.50759, 31.75452],
          [-106.1429, 31.39995],
          [-105.63159, 31.08383],
          [-105.03737, 30.64402],
          [-104.70575, 30.12173],
          [-104.45697, 29.57196],
          [-103.94, 29.27],
          [-103.11, 28.97],
          [-102.48, 29.76],
          [-101.6624, 29.7793],
          [-100.9576, 29.38071],
          [-100.45584, 28.69612],
          [-100.11, 28.11],
          [-99.52, 27.54],
          [-99.3, 26.84],
          [-99.02, 26.37],
          [-98.24, 26.06],
          [-97.53, 25.84],
          [-97.140008, 25.869997],
        ],
      ],
    },
    id: 'MX',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Macedonia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [20.59023, 41.85541],
          [20.71731, 41.84711],
          [20.76216, 42.05186],
          [21.3527, 42.2068],
          [21.576636, 42.245224],
          [21.91708, 42.30364],
          [22.380526, 42.32026],
          [22.881374, 41.999297],
          [22.952377, 41.337994],
          [22.76177, 41.3048],
          [22.597308, 41.130487],
          [22.055378, 41.149866],
          [21.674161, 40.931275],
          [21.02004, 40.842727],
          [20.60518, 41.08622],
          [20.46315, 41.51509],
          [20.59023, 41.85541],
        ],
      ],
    },
    id: 'MK',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Mali',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-12.17075, 14.616834],
          [-11.834208, 14.799097],
          [-11.666078, 15.388208],
          [-11.349095, 15.411256],
          [-10.650791, 15.132746],
          [-10.086846, 15.330486],
          [-9.700255, 15.264107],
          [-9.550238, 15.486497],
          [-5.537744, 15.50169],
          [-5.315277, 16.201854],
          [-5.488523, 16.325102],
          [-5.971129, 20.640833],
          [-6.453787, 24.956591],
          [-4.923337, 24.974574],
          [-1.550055, 22.792666],
          [1.823228, 20.610809],
          [2.060991, 20.142233],
          [2.683588, 19.85623],
          [3.146661, 19.693579],
          [3.158133, 19.057364],
          [4.267419, 19.155265],
          [4.27021, 16.852227],
          [3.723422, 16.184284],
          [3.638259, 15.56812],
          [2.749993, 15.409525],
          [1.385528, 15.323561],
          [1.015783, 14.968182],
          [0.374892, 14.928908],
          [-0.266257, 14.924309],
          [-0.515854, 15.116158],
          [-1.066363, 14.973815],
          [-2.001035, 14.559008],
          [-2.191825, 14.246418],
          [-2.967694, 13.79815],
          [-3.103707, 13.541267],
          [-3.522803, 13.337662],
          [-4.006391, 13.472485],
          [-4.280405, 13.228444],
          [-4.427166, 12.542646],
          [-5.220942, 11.713859],
          [-5.197843, 11.375146],
          [-5.470565, 10.95127],
          [-5.404342, 10.370737],
          [-5.816926, 10.222555],
          [-6.050452, 10.096361],
          [-6.205223, 10.524061],
          [-6.493965, 10.411303],
          [-6.666461, 10.430811],
          [-6.850507, 10.138994],
          [-7.622759, 10.147236],
          [-7.89959, 10.297382],
          [-8.029944, 10.206535],
          [-8.335377, 10.494812],
          [-8.282357, 10.792597],
          [-8.407311, 10.909257],
          [-8.620321, 10.810891],
          [-8.581305, 11.136246],
          [-8.376305, 11.393646],
          [-8.786099, 11.812561],
          [-8.905265, 12.088358],
          [-9.127474, 12.30806],
          [-9.327616, 12.334286],
          [-9.567912, 12.194243],
          [-9.890993, 12.060479],
          [-10.165214, 11.844084],
          [-10.593224, 11.923975],
          [-10.87083, 12.177887],
          [-11.036556, 12.211245],
          [-11.297574, 12.077971],
          [-11.456169, 12.076834],
          [-11.513943, 12.442988],
          [-11.467899, 12.754519],
          [-11.553398, 13.141214],
          [-11.927716, 13.422075],
          [-12.124887, 13.994727],
          [-12.17075, 14.616834],
        ],
      ],
    },
    id: 'ML',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Myanmar',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [99.543309, 20.186598],
          [98.959676, 19.752981],
          [98.253724, 19.708203],
          [97.797783, 18.62708],
          [97.375896, 18.445438],
          [97.859123, 17.567946],
          [98.493761, 16.837836],
          [98.903348, 16.177824],
          [98.537376, 15.308497],
          [98.192074, 15.123703],
          [98.430819, 14.622028],
          [99.097755, 13.827503],
          [99.212012, 13.269294],
          [99.196354, 12.804748],
          [99.587286, 11.892763],
          [99.038121, 10.960546],
          [98.553551, 9.93296],
          [98.457174, 10.675266],
          [98.764546, 11.441292],
          [98.428339, 12.032987],
          [98.509574, 13.122378],
          [98.103604, 13.64046],
          [97.777732, 14.837286],
          [97.597072, 16.100568],
          [97.16454, 16.928734],
          [96.505769, 16.427241],
          [95.369352, 15.71439],
          [94.808405, 15.803454],
          [94.188804, 16.037936],
          [94.533486, 17.27724],
          [94.324817, 18.213514],
          [93.540988, 19.366493],
          [93.663255, 19.726962],
          [93.078278, 19.855145],
          [92.368554, 20.670883],
          [92.303234, 21.475485],
          [92.652257, 21.324048],
          [92.672721, 22.041239],
          [93.166128, 22.27846],
          [93.060294, 22.703111],
          [93.286327, 23.043658],
          [93.325188, 24.078556],
          [94.106742, 23.850741],
          [94.552658, 24.675238],
          [94.603249, 25.162495],
          [95.155153, 26.001307],
          [95.124768, 26.573572],
          [96.419366, 27.264589],
          [97.133999, 27.083774],
          [97.051989, 27.699059],
          [97.402561, 27.882536],
          [97.327114, 28.261583],
          [97.911988, 28.335945],
          [98.246231, 27.747221],
          [98.68269, 27.508812],
          [98.712094, 26.743536],
          [98.671838, 25.918703],
          [97.724609, 25.083637],
          [97.60472, 23.897405],
          [98.660262, 24.063286],
          [98.898749, 23.142722],
          [99.531992, 22.949039],
          [99.240899, 22.118314],
          [99.983489, 21.742937],
          [100.416538, 21.558839],
          [101.150033, 21.849984],
          [101.180005, 21.436573],
          [100.329101, 20.786122],
          [100.115988, 20.41785],
          [99.543309, 20.186598],
        ],
      ],
    },
    id: 'MM',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Montenegro',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [19.801613, 42.500093],
          [19.738051, 42.688247],
          [19.30449, 42.19574],
          [19.37177, 41.87755],
          [19.16246, 41.95502],
          [18.88214, 42.28151],
          [18.45, 42.48],
          [18.56, 42.65],
          [18.70648, 43.20011],
          [19.03165, 43.43253],
          [19.21852, 43.52384],
          [19.48389, 43.35229],
          [19.63, 43.21378],
          [19.95857, 43.10604],
          [20.3398, 42.89852],
          [20.25758, 42.81275],
          [20.0707, 42.58863],
          [19.801613, 42.500093],
        ],
      ],
    },
    id: 'ME',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Mongolia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [87.751264, 49.297198],
          [88.805567, 49.470521],
          [90.713667, 50.331812],
          [92.234712, 50.802171],
          [93.104219, 50.49529],
          [94.147566, 50.480537],
          [94.815949, 50.013433],
          [95.814028, 49.977467],
          [97.259728, 49.726061],
          [98.231762, 50.422401],
          [97.82574, 51.010995],
          [98.861491, 52.047366],
          [99.981732, 51.634006],
          [100.88948, 51.516856],
          [102.065223, 51.259921],
          [102.255909, 50.510561],
          [103.676545, 50.089966],
          [104.621552, 50.275329],
          [105.886591, 50.406019],
          [106.888804, 50.274296],
          [107.868176, 49.793705],
          [108.475167, 49.282548],
          [109.402449, 49.292961],
          [110.662011, 49.130128],
          [111.581231, 49.377968],
          [112.89774, 49.543565],
          [114.362456, 50.248303],
          [114.96211, 50.140247],
          [115.485695, 49.805177],
          [116.678801, 49.888531],
          [116.191802, 49.134598],
          [115.485282, 48.135383],
          [115.742837, 47.726545],
          [116.308953, 47.85341],
          [117.295507, 47.697709],
          [118.064143, 48.06673],
          [118.866574, 47.74706],
          [119.772824, 47.048059],
          [119.66327, 46.69268],
          [118.874326, 46.805412],
          [117.421701, 46.672733],
          [116.717868, 46.388202],
          [115.985096, 45.727235],
          [114.460332, 45.339817],
          [113.463907, 44.808893],
          [112.436062, 45.011646],
          [111.873306, 45.102079],
          [111.348377, 44.457442],
          [111.667737, 44.073176],
          [111.829588, 43.743118],
          [111.129682, 43.406834],
          [110.412103, 42.871234],
          [109.243596, 42.519446],
          [107.744773, 42.481516],
          [106.129316, 42.134328],
          [104.964994, 41.59741],
          [104.522282, 41.908347],
          [103.312278, 41.907468],
          [101.83304, 42.514873],
          [100.845866, 42.663804],
          [99.515817, 42.524691],
          [97.451757, 42.74889],
          [96.349396, 42.725635],
          [95.762455, 43.319449],
          [95.306875, 44.241331],
          [94.688929, 44.352332],
          [93.480734, 44.975472],
          [92.133891, 45.115076],
          [90.94554, 45.286073],
          [90.585768, 45.719716],
          [90.970809, 46.888146],
          [90.280826, 47.693549],
          [88.854298, 48.069082],
          [88.013832, 48.599463],
          [87.751264, 49.297198],
        ],
      ],
    },
    id: 'MN',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Mozambique',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [34.559989, -11.52002],
          [35.312398, -11.439146],
          [36.514082, -11.720938],
          [36.775151, -11.594537],
          [37.471284, -11.568751],
          [37.827645, -11.268769],
          [38.427557, -11.285202],
          [39.52103, -10.896854],
          [40.316589, -10.317096],
          [40.478387, -10.765441],
          [40.437253, -11.761711],
          [40.560811, -12.639177],
          [40.59962, -14.201975],
          [40.775475, -14.691764],
          [40.477251, -15.406294],
          [40.089264, -16.100774],
          [39.452559, -16.720891],
          [38.538351, -17.101023],
          [37.411133, -17.586368],
          [36.281279, -18.659688],
          [35.896497, -18.84226],
          [35.1984, -19.552811],
          [34.786383, -19.784012],
          [34.701893, -20.497043],
          [35.176127, -21.254361],
          [35.373428, -21.840837],
          [35.385848, -22.14],
          [35.562546, -22.09],
          [35.533935, -23.070788],
          [35.371774, -23.535359],
          [35.60747, -23.706563],
          [35.458746, -24.12261],
          [35.040735, -24.478351],
          [34.215824, -24.816314],
          [33.01321, -25.357573],
          [32.574632, -25.727318],
          [32.660363, -26.148584],
          [32.915955, -26.215867],
          [32.83012, -26.742192],
          [32.071665, -26.73382],
          [31.985779, -26.29178],
          [31.837778, -25.843332],
          [31.752408, -25.484284],
          [31.930589, -24.369417],
          [31.670398, -23.658969],
          [31.191409, -22.25151],
          [32.244988, -21.116489],
          [32.508693, -20.395292],
          [32.659743, -20.30429],
          [32.772708, -19.715592],
          [32.611994, -19.419383],
          [32.654886, -18.67209],
          [32.849861, -17.979057],
          [32.847639, -16.713398],
          [32.328239, -16.392074],
          [31.852041, -16.319417],
          [31.636498, -16.07199],
          [31.173064, -15.860944],
          [30.338955, -15.880839],
          [30.274256, -15.507787],
          [30.179481, -14.796099],
          [33.214025, -13.97186],
          [33.7897, -14.451831],
          [34.064825, -14.35995],
          [34.459633, -14.61301],
          [34.517666, -15.013709],
          [34.307291, -15.478641],
          [34.381292, -16.18356],
          [35.03381, -16.8013],
          [35.339063, -16.10744],
          [35.771905, -15.896859],
          [35.686845, -14.611046],
          [35.267956, -13.887834],
          [34.907151, -13.565425],
          [34.559989, -13.579998],
          [34.280006, -12.280025],
          [34.559989, -11.52002],
        ],
      ],
    },
    id: 'MZ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Mauritania',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-12.17075, 14.616834],
          [-12.830658, 15.303692],
          [-13.435738, 16.039383],
          [-14.099521, 16.304302],
          [-14.577348, 16.598264],
          [-15.135737, 16.587282],
          [-15.623666, 16.369337],
          [-16.12069, 16.455663],
          [-16.463098, 16.135036],
          [-16.549708, 16.673892],
          [-16.270552, 17.166963],
          [-16.146347, 18.108482],
          [-16.256883, 19.096716],
          [-16.377651, 19.593817],
          [-16.277838, 20.092521],
          [-16.536324, 20.567866],
          [-17.063423, 20.999752],
          [-16.845194, 21.333323],
          [-12.929102, 21.327071],
          [-13.118754, 22.77122],
          [-12.874222, 23.284832],
          [-11.937224, 23.374594],
          [-11.969419, 25.933353],
          [-8.687294, 25.881056],
          [-8.6844, 27.395744],
          [-4.923337, 24.974574],
          [-6.453787, 24.956591],
          [-5.971129, 20.640833],
          [-5.488523, 16.325102],
          [-5.315277, 16.201854],
          [-5.537744, 15.50169],
          [-9.550238, 15.486497],
          [-9.700255, 15.264107],
          [-10.086846, 15.330486],
          [-10.650791, 15.132746],
          [-11.349095, 15.411256],
          [-11.666078, 15.388208],
          [-11.834208, 14.799097],
          [-12.17075, 14.616834],
        ],
      ],
    },
    id: 'MR',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Malawi',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [34.559989, -11.52002],
          [34.280006, -12.280025],
          [34.559989, -13.579998],
          [34.907151, -13.565425],
          [35.267956, -13.887834],
          [35.686845, -14.611046],
          [35.771905, -15.896859],
          [35.339063, -16.10744],
          [35.03381, -16.8013],
          [34.381292, -16.18356],
          [34.307291, -15.478641],
          [34.517666, -15.013709],
          [34.459633, -14.61301],
          [34.064825, -14.35995],
          [33.7897, -14.451831],
          [33.214025, -13.97186],
          [32.688165, -13.712858],
          [32.991764, -12.783871],
          [33.306422, -12.435778],
          [33.114289, -11.607198],
          [33.31531, -10.79655],
          [33.485688, -10.525559],
          [33.231388, -9.676722],
          [32.759375, -9.230599],
          [33.739729, -9.417151],
          [33.940838, -9.693674],
          [34.280006, -10.16],
          [34.559989, -11.52002],
        ],
      ],
    },
    id: 'MW',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Malaysia',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [101.075516, 6.204867],
            [101.154219, 5.691384],
            [101.814282, 5.810808],
            [102.141187, 6.221636],
            [102.371147, 6.128205],
            [102.961705, 5.524495],
            [103.381215, 4.855001],
            [103.438575, 4.181606],
            [103.332122, 3.726698],
            [103.429429, 3.382869],
            [103.502448, 2.791019],
            [103.854674, 2.515454],
            [104.247932, 1.631141],
            [104.228811, 1.293048],
            [103.519707, 1.226334],
            [102.573615, 1.967115],
            [101.390638, 2.760814],
            [101.27354, 3.270292],
            [100.695435, 3.93914],
            [100.557408, 4.76728],
            [100.196706, 5.312493],
            [100.30626, 6.040562],
            [100.085757, 6.464489],
            [100.259596, 6.642825],
            [101.075516, 6.204867],
          ],
        ],
        [
          [
            [118.618321, 4.478202],
            [117.882035, 4.137551],
            [117.015214, 4.306094],
            [115.865517, 4.306559],
            [115.519078, 3.169238],
            [115.134037, 2.821482],
            [114.621355, 1.430688],
            [113.80585, 1.217549],
            [112.859809, 1.49779],
            [112.380252, 1.410121],
            [111.797548, 0.904441],
            [111.159138, 0.976478],
            [110.514061, 0.773131],
            [109.830227, 1.338136],
            [109.66326, 2.006467],
            [110.396135, 1.663775],
            [111.168853, 1.850637],
            [111.370081, 2.697303],
            [111.796928, 2.885897],
            [112.995615, 3.102395],
            [113.712935, 3.893509],
            [114.204017, 4.525874],
            [114.659596, 4.007637],
            [114.869557, 4.348314],
            [115.347461, 4.316636],
            [115.4057, 4.955228],
            [115.45071, 5.44773],
            [116.220741, 6.143191],
            [116.725103, 6.924771],
            [117.129626, 6.928053],
            [117.643393, 6.422166],
            [117.689075, 5.98749],
            [118.347691, 5.708696],
            [119.181904, 5.407836],
            [119.110694, 5.016128],
            [118.439727, 4.966519],
            [118.618321, 4.478202],
          ],
        ],
      ],
    },
    id: 'MY',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Namibia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [16.344977, -28.576705],
          [15.601818, -27.821247],
          [15.210472, -27.090956],
          [14.989711, -26.117372],
          [14.743214, -25.39292],
          [14.408144, -23.853014],
          [14.385717, -22.656653],
          [14.257714, -22.111208],
          [13.868642, -21.699037],
          [13.352498, -20.872834],
          [12.826845, -19.673166],
          [12.608564, -19.045349],
          [11.794919, -18.069129],
          [11.734199, -17.301889],
          [12.215461, -17.111668],
          [12.814081, -16.941343],
          [13.462362, -16.971212],
          [14.058501, -17.423381],
          [14.209707, -17.353101],
          [18.263309, -17.309951],
          [18.956187, -17.789095],
          [21.377176, -17.930636],
          [23.215048, -17.523116],
          [24.033862, -17.295843],
          [24.682349, -17.353411],
          [25.07695, -17.578823],
          [25.084443, -17.661816],
          [24.520705, -17.887125],
          [24.217365, -17.889347],
          [23.579006, -18.281261],
          [23.196858, -17.869038],
          [21.65504, -18.219146],
          [20.910641, -18.252219],
          [20.881134, -21.814327],
          [19.895458, -21.849157],
          [19.895768, -24.76779],
          [19.894734, -28.461105],
          [19.002127, -28.972443],
          [18.464899, -29.045462],
          [17.836152, -28.856378],
          [17.387497, -28.783514],
          [17.218929, -28.355943],
          [16.824017, -28.082162],
          [16.344977, -28.576705],
        ],
      ],
    },
    id: 'NA',
  },
  {
    type: 'Feature',
    properties: {
      name: 'New Caledonia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [165.77999, -21.080005],
          [166.599991, -21.700019],
          [167.120011, -22.159991],
          [166.740035, -22.399976],
          [166.189732, -22.129708],
          [165.474375, -21.679607],
          [164.829815, -21.14982],
          [164.167995, -20.444747],
          [164.029606, -20.105646],
          [164.459967, -20.120012],
          [165.020036, -20.459991],
          [165.460009, -20.800022],
          [165.77999, -21.080005],
        ],
      ],
    },
    id: 'NC',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Niger',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [2.154474, 11.94015],
          [2.177108, 12.625018],
          [1.024103, 12.851826],
          [0.993046, 13.33575],
          [0.429928, 13.988733],
          [0.295646, 14.444235],
          [0.374892, 14.928908],
          [1.015783, 14.968182],
          [1.385528, 15.323561],
          [2.749993, 15.409525],
          [3.638259, 15.56812],
          [3.723422, 16.184284],
          [4.27021, 16.852227],
          [4.267419, 19.155265],
          [5.677566, 19.601207],
          [8.572893, 21.565661],
          [11.999506, 23.471668],
          [13.581425, 23.040506],
          [14.143871, 22.491289],
          [14.8513, 22.86295],
          [15.096888, 21.308519],
          [15.471077, 21.048457],
          [15.487148, 20.730415],
          [15.903247, 20.387619],
          [15.685741, 19.95718],
          [15.300441, 17.92795],
          [15.247731, 16.627306],
          [13.972202, 15.684366],
          [13.540394, 14.367134],
          [13.956699, 13.996691],
          [13.954477, 13.353449],
          [14.595781, 13.330427],
          [14.495787, 12.859396],
          [14.213531, 12.802035],
          [14.181336, 12.483657],
          [13.995353, 12.461565],
          [13.318702, 13.556356],
          [13.083987, 13.596147],
          [12.302071, 13.037189],
          [11.527803, 13.32898],
          [10.989593, 13.387323],
          [10.701032, 13.246918],
          [10.114814, 13.277252],
          [9.524928, 12.851102],
          [9.014933, 12.826659],
          [7.804671, 13.343527],
          [7.330747, 13.098038],
          [6.820442, 13.115091],
          [6.445426, 13.492768],
          [5.443058, 13.865924],
          [4.368344, 13.747482],
          [4.107946, 13.531216],
          [3.967283, 12.956109],
          [3.680634, 12.552903],
          [3.61118, 11.660167],
          [2.848643, 12.235636],
          [2.490164, 12.233052],
          [2.154474, 11.94015],
        ],
      ],
    },
    id: 'NE',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Nigeria',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [8.500288, 4.771983],
          [7.462108, 4.412108],
          [7.082596, 4.464689],
          [6.698072, 4.240594],
          [5.898173, 4.262453],
          [5.362805, 4.887971],
          [5.033574, 5.611802],
          [4.325607, 6.270651],
          [3.57418, 6.2583],
          [2.691702, 6.258817],
          [2.749063, 7.870734],
          [2.723793, 8.506845],
          [2.912308, 9.137608],
          [3.220352, 9.444153],
          [3.705438, 10.06321],
          [3.60007, 10.332186],
          [3.797112, 10.734746],
          [3.572216, 11.327939],
          [3.61118, 11.660167],
          [3.680634, 12.552903],
          [3.967283, 12.956109],
          [4.107946, 13.531216],
          [4.368344, 13.747482],
          [5.443058, 13.865924],
          [6.445426, 13.492768],
          [6.820442, 13.115091],
          [7.330747, 13.098038],
          [7.804671, 13.343527],
          [9.014933, 12.826659],
          [9.524928, 12.851102],
          [10.114814, 13.277252],
          [10.701032, 13.246918],
          [10.989593, 13.387323],
          [11.527803, 13.32898],
          [12.302071, 13.037189],
          [13.083987, 13.596147],
          [13.318702, 13.556356],
          [13.995353, 12.461565],
          [14.181336, 12.483657],
          [14.577178, 12.085361],
          [14.468192, 11.904752],
          [14.415379, 11.572369],
          [13.57295, 10.798566],
          [13.308676, 10.160362],
          [13.1676, 9.640626],
          [12.955468, 9.417772],
          [12.753672, 8.717763],
          [12.218872, 8.305824],
          [12.063946, 7.799808],
          [11.839309, 7.397042],
          [11.745774, 6.981383],
          [11.058788, 6.644427],
          [10.497375, 7.055358],
          [10.118277, 7.03877],
          [9.522706, 6.453482],
          [9.233163, 6.444491],
          [8.757533, 5.479666],
          [8.500288, 4.771983],
        ],
      ],
    },
    id: 'NG',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Nicaragua',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-85.71254, 11.088445],
          [-86.058488, 11.403439],
          [-86.52585, 11.806877],
          [-86.745992, 12.143962],
          [-87.167516, 12.458258],
          [-87.668493, 12.90991],
          [-87.557467, 13.064552],
          [-87.392386, 12.914018],
          [-87.316654, 12.984686],
          [-87.005769, 13.025794],
          [-86.880557, 13.254204],
          [-86.733822, 13.263093],
          [-86.755087, 13.754845],
          [-86.520708, 13.778487],
          [-86.312142, 13.771356],
          [-86.096264, 14.038187],
          [-85.801295, 13.836055],
          [-85.698665, 13.960078],
          [-85.514413, 14.079012],
          [-85.165365, 14.35437],
          [-85.148751, 14.560197],
          [-85.052787, 14.551541],
          [-84.924501, 14.790493],
          [-84.820037, 14.819587],
          [-84.649582, 14.666805],
          [-84.449336, 14.621614],
          [-84.228342, 14.748764],
          [-83.975721, 14.749436],
          [-83.628585, 14.880074],
          [-83.489989, 15.016267],
          [-83.147219, 14.995829],
          [-83.233234, 14.899866],
          [-83.284162, 14.676624],
          [-83.182126, 14.310703],
          [-83.4125, 13.970078],
          [-83.519832, 13.567699],
          [-83.552207, 13.127054],
          [-83.498515, 12.869292],
          [-83.473323, 12.419087],
          [-83.626104, 12.32085],
          [-83.719613, 11.893124],
          [-83.650858, 11.629032],
          [-83.85547, 11.373311],
          [-83.808936, 11.103044],
          [-83.655612, 10.938764],
          [-83.895054, 10.726839],
          [-84.190179, 10.79345],
          [-84.355931, 10.999226],
          [-84.673069, 11.082657],
          [-84.903003, 10.952303],
          [-85.561852, 11.217119],
          [-85.71254, 11.088445],
        ],
      ],
    },
    id: 'NI',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Netherlands',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [6.074183, 53.510403],
          [6.90514, 53.482162],
          [7.092053, 53.144043],
          [6.84287, 52.22844],
          [6.589397, 51.852029],
          [5.988658, 51.851616],
          [6.156658, 50.803721],
          [5.606976, 51.037298],
          [4.973991, 51.475024],
          [4.047071, 51.267259],
          [3.314971, 51.345755],
          [3.830289, 51.620545],
          [4.705997, 53.091798],
          [6.074183, 53.510403],
        ],
      ],
    },
    id: 'NL',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Norway',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [28.165547, 71.185474],
            [31.293418, 70.453788],
            [30.005435, 70.186259],
            [31.101079, 69.55808],
            [29.399581, 69.156916],
            [28.59193, 69.064777],
            [29.015573, 69.766491],
            [27.732292, 70.164193],
            [26.179622, 69.825299],
            [25.689213, 69.092114],
            [24.735679, 68.649557],
            [23.66205, 68.891247],
            [22.356238, 68.841741],
            [21.244936, 69.370443],
            [20.645593, 69.106247],
            [20.025269, 69.065139],
            [19.87856, 68.407194],
            [17.993868, 68.567391],
            [17.729182, 68.010552],
            [16.768879, 68.013937],
            [16.108712, 67.302456],
            [15.108411, 66.193867],
            [13.55569, 64.787028],
            [13.919905, 64.445421],
            [13.571916, 64.049114],
            [12.579935, 64.066219],
            [11.930569, 63.128318],
            [11.992064, 61.800362],
            [12.631147, 61.293572],
            [12.300366, 60.117933],
            [11.468272, 59.432393],
            [11.027369, 58.856149],
            [10.356557, 59.469807],
            [8.382, 58.313288],
            [7.048748, 58.078884],
            [5.665835, 58.588155],
            [5.308234, 59.663232],
            [4.992078, 61.970998],
            [5.9129, 62.614473],
            [8.553411, 63.454008],
            [10.527709, 64.486038],
            [12.358347, 65.879726],
            [14.761146, 67.810642],
            [16.435927, 68.563205],
            [19.184028, 69.817444],
            [21.378416, 70.255169],
            [23.023742, 70.202072],
            [24.546543, 71.030497],
            [26.37005, 70.986262],
            [28.165547, 71.185474],
          ],
        ],
        [
          [
            [24.72412, 77.85385],
            [22.49032, 77.44493],
            [20.72601, 77.67704],
            [21.41611, 77.93504],
            [20.8119, 78.25463],
            [22.88426, 78.45494],
            [23.28134, 78.07954],
            [24.72412, 77.85385],
          ],
        ],
        [
          [
            [18.25183, 79.70175],
            [21.54383, 78.95611],
            [19.02737, 78.5626],
            [18.47172, 77.82669],
            [17.59441, 77.63796],
            [17.1182, 76.80941],
            [15.91315, 76.77045],
            [13.76259, 77.38035],
            [14.66956, 77.73565],
            [13.1706, 78.02493],
            [11.22231, 78.8693],
            [10.44453, 79.65239],
            [13.17077, 80.01046],
            [13.71852, 79.66039],
            [15.14282, 79.67431],
            [15.52255, 80.01608],
            [16.99085, 80.05086],
            [18.25183, 79.70175],
          ],
        ],
        [
          [
            [25.447625, 80.40734],
            [27.407506, 80.056406],
            [25.924651, 79.517834],
            [23.024466, 79.400012],
            [20.075188, 79.566823],
            [19.897266, 79.842362],
            [18.462264, 79.85988],
            [17.368015, 80.318896],
            [20.455992, 80.598156],
            [21.907945, 80.357679],
            [22.919253, 80.657144],
            [25.447625, 80.40734],
          ],
        ],
      ],
    },
    id: 'NO',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Nepal',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [88.120441, 27.876542],
          [88.043133, 27.445819],
          [88.174804, 26.810405],
          [88.060238, 26.414615],
          [87.227472, 26.397898],
          [86.024393, 26.630985],
          [85.251779, 26.726198],
          [84.675018, 27.234901],
          [83.304249, 27.364506],
          [81.999987, 27.925479],
          [81.057203, 28.416095],
          [80.088425, 28.79447],
          [80.476721, 29.729865],
          [81.111256, 30.183481],
          [81.525804, 30.422717],
          [82.327513, 30.115268],
          [83.337115, 29.463732],
          [83.898993, 29.320226],
          [84.23458, 28.839894],
          [85.011638, 28.642774],
          [85.82332, 28.203576],
          [86.954517, 27.974262],
          [88.120441, 27.876542],
        ],
      ],
    },
    id: 'NP',
  },
  {
    type: 'Feature',
    properties: {
      name: 'New Zealand',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [173.020375, -40.919052],
            [173.247234, -41.331999],
            [173.958405, -40.926701],
            [174.247587, -41.349155],
            [174.248517, -41.770008],
            [173.876447, -42.233184],
            [173.22274, -42.970038],
            [172.711246, -43.372288],
            [173.080113, -43.853344],
            [172.308584, -43.865694],
            [171.452925, -44.242519],
            [171.185138, -44.897104],
            [170.616697, -45.908929],
            [169.831422, -46.355775],
            [169.332331, -46.641235],
            [168.411354, -46.619945],
            [167.763745, -46.290197],
            [166.676886, -46.219917],
            [166.509144, -45.852705],
            [167.046424, -45.110941],
            [168.303763, -44.123973],
            [168.949409, -43.935819],
            [169.667815, -43.555326],
            [170.52492, -43.031688],
            [171.12509, -42.512754],
            [171.569714, -41.767424],
            [171.948709, -41.514417],
            [172.097227, -40.956104],
            [172.79858, -40.493962],
            [173.020375, -40.919052],
          ],
        ],
        [
          [
            [174.612009, -36.156397],
            [175.336616, -37.209098],
            [175.357596, -36.526194],
            [175.808887, -36.798942],
            [175.95849, -37.555382],
            [176.763195, -37.881253],
            [177.438813, -37.961248],
            [178.010354, -37.579825],
            [178.517094, -37.695373],
            [178.274731, -38.582813],
            [177.97046, -39.166343],
            [177.206993, -39.145776],
            [176.939981, -39.449736],
            [177.032946, -39.879943],
            [176.885824, -40.065978],
            [176.508017, -40.604808],
            [176.01244, -41.289624],
            [175.239567, -41.688308],
            [175.067898, -41.425895],
            [174.650973, -41.281821],
            [175.22763, -40.459236],
            [174.900157, -39.908933],
            [173.824047, -39.508854],
            [173.852262, -39.146602],
            [174.574802, -38.797683],
            [174.743474, -38.027808],
            [174.697017, -37.381129],
            [174.292028, -36.711092],
            [174.319004, -36.534824],
            [173.840997, -36.121981],
            [173.054171, -35.237125],
            [172.636005, -34.529107],
            [173.007042, -34.450662],
            [173.551298, -35.006183],
            [174.32939, -35.265496],
            [174.612009, -36.156397],
          ],
        ],
      ],
    },
    id: 'NZ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Oman',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [58.861141, 21.114035],
            [58.487986, 20.428986],
            [58.034318, 20.481437],
            [57.826373, 20.243002],
            [57.665762, 19.736005],
            [57.7887, 19.06757],
            [57.694391, 18.94471],
            [57.234264, 18.947991],
            [56.609651, 18.574267],
            [56.512189, 18.087113],
            [56.283521, 17.876067],
            [55.661492, 17.884128],
            [55.269939, 17.632309],
            [55.2749, 17.228354],
            [54.791002, 16.950697],
            [54.239253, 17.044981],
            [53.570508, 16.707663],
            [53.108573, 16.651051],
            [52.782184, 17.349742],
            [52.00001, 19.000003],
            [54.999982, 19.999994],
            [55.666659, 22.000001],
            [55.208341, 22.70833],
            [55.234489, 23.110993],
            [55.525841, 23.524869],
            [55.528632, 23.933604],
            [55.981214, 24.130543],
            [55.804119, 24.269604],
            [55.886233, 24.920831],
            [56.396847, 24.924732],
            [56.84514, 24.241673],
            [57.403453, 23.878594],
            [58.136948, 23.747931],
            [58.729211, 23.565668],
            [59.180502, 22.992395],
            [59.450098, 22.660271],
            [59.80806, 22.533612],
            [59.806148, 22.310525],
            [59.442191, 21.714541],
            [59.282408, 21.433886],
            [58.861141, 21.114035],
          ],
        ],
        [
          [
            [56.391421, 25.895991],
            [56.261042, 25.714606],
            [56.070821, 26.055464],
            [56.362017, 26.395934],
            [56.485679, 26.309118],
            [56.391421, 25.895991],
          ],
        ],
      ],
    },
    id: 'OM',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Pakistan',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [75.158028, 37.133031],
          [75.896897, 36.666806],
          [76.192848, 35.898403],
          [77.837451, 35.49401],
          [76.871722, 34.653544],
          [75.757061, 34.504923],
          [74.240203, 34.748887],
          [73.749948, 34.317699],
          [74.104294, 33.441473],
          [74.451559, 32.7649],
          [75.258642, 32.271105],
          [74.405929, 31.692639],
          [74.42138, 30.979815],
          [73.450638, 29.976413],
          [72.823752, 28.961592],
          [71.777666, 27.91318],
          [70.616496, 27.989196],
          [69.514393, 26.940966],
          [70.168927, 26.491872],
          [70.282873, 25.722229],
          [70.844699, 25.215102],
          [71.04324, 24.356524],
          [68.842599, 24.359134],
          [68.176645, 23.691965],
          [67.443667, 23.944844],
          [67.145442, 24.663611],
          [66.372828, 25.425141],
          [64.530408, 25.237039],
          [62.905701, 25.218409],
          [61.497363, 25.078237],
          [61.874187, 26.239975],
          [63.316632, 26.756532],
          [63.233898, 27.217047],
          [62.755426, 27.378923],
          [62.72783, 28.259645],
          [61.771868, 28.699334],
          [61.369309, 29.303276],
          [60.874248, 29.829239],
          [62.549857, 29.318572],
          [63.550261, 29.468331],
          [64.148002, 29.340819],
          [64.350419, 29.560031],
          [65.046862, 29.472181],
          [66.346473, 29.887943],
          [66.381458, 30.738899],
          [66.938891, 31.304911],
          [67.683394, 31.303154],
          [67.792689, 31.58293],
          [68.556932, 31.71331],
          [68.926677, 31.620189],
          [69.317764, 31.901412],
          [69.262522, 32.501944],
          [69.687147, 33.105499],
          [70.323594, 33.358533],
          [69.930543, 34.02012],
          [70.881803, 33.988856],
          [71.156773, 34.348911],
          [71.115019, 34.733126],
          [71.613076, 35.153203],
          [71.498768, 35.650563],
          [71.262348, 36.074388],
          [71.846292, 36.509942],
          [72.920025, 36.720007],
          [74.067552, 36.836176],
          [74.575893, 37.020841],
          [75.158028, 37.133031],
        ],
      ],
    },
    id: 'PK',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Panama',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-77.881571, 7.223771],
          [-78.214936, 7.512255],
          [-78.429161, 8.052041],
          [-78.182096, 8.319182],
          [-78.435465, 8.387705],
          [-78.622121, 8.718124],
          [-79.120307, 8.996092],
          [-79.557877, 8.932375],
          [-79.760578, 8.584515],
          [-80.164481, 8.333316],
          [-80.382659, 8.298409],
          [-80.480689, 8.090308],
          [-80.00369, 7.547524],
          [-80.276671, 7.419754],
          [-80.421158, 7.271572],
          [-80.886401, 7.220541],
          [-81.059543, 7.817921],
          [-81.189716, 7.647906],
          [-81.519515, 7.70661],
          [-81.721311, 8.108963],
          [-82.131441, 8.175393],
          [-82.390934, 8.292362],
          [-82.820081, 8.290864],
          [-82.850958, 8.073823],
          [-82.965783, 8.225028],
          [-82.913176, 8.423517],
          [-82.829771, 8.626295],
          [-82.868657, 8.807266],
          [-82.719183, 8.925709],
          [-82.927155, 9.07433],
          [-82.932891, 9.476812],
          [-82.546196, 9.566135],
          [-82.187123, 9.207449],
          [-82.207586, 8.995575],
          [-81.808567, 8.950617],
          [-81.714154, 9.031955],
          [-81.439287, 8.786234],
          [-80.947302, 8.858504],
          [-80.521901, 9.111072],
          [-79.9146, 9.312765],
          [-79.573303, 9.61161],
          [-79.021192, 9.552931],
          [-79.05845, 9.454565],
          [-78.500888, 9.420459],
          [-78.055928, 9.24773],
          [-77.729514, 8.946844],
          [-77.353361, 8.670505],
          [-77.474723, 8.524286],
          [-77.242566, 7.935278],
          [-77.431108, 7.638061],
          [-77.753414, 7.70984],
          [-77.881571, 7.223771],
        ],
      ],
    },
    id: 'PA',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Peru',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-69.590424, -17.580012],
          [-69.858444, -18.092694],
          [-70.372572, -18.347975],
          [-71.37525, -17.773799],
          [-71.462041, -17.363488],
          [-73.44453, -16.359363],
          [-75.237883, -15.265683],
          [-76.009205, -14.649286],
          [-76.423469, -13.823187],
          [-76.259242, -13.535039],
          [-77.106192, -12.222716],
          [-78.092153, -10.377712],
          [-79.036953, -8.386568],
          [-79.44592, -7.930833],
          [-79.760578, -7.194341],
          [-80.537482, -6.541668],
          [-81.249996, -6.136834],
          [-80.926347, -5.690557],
          [-81.410943, -4.736765],
          [-81.09967, -4.036394],
          [-80.302561, -3.404856],
          [-80.184015, -3.821162],
          [-80.469295, -4.059287],
          [-80.442242, -4.425724],
          [-80.028908, -4.346091],
          [-79.624979, -4.454198],
          [-79.205289, -4.959129],
          [-78.639897, -4.547784],
          [-78.450684, -3.873097],
          [-77.837905, -3.003021],
          [-76.635394, -2.608678],
          [-75.544996, -1.56161],
          [-75.233723, -0.911417],
          [-75.373223, -0.152032],
          [-75.106625, -0.057205],
          [-74.441601, -0.53082],
          [-74.122395, -1.002833],
          [-73.659504, -1.260491],
          [-73.070392, -2.308954],
          [-72.325787, -2.434218],
          [-71.774761, -2.16979],
          [-71.413646, -2.342802],
          [-70.813476, -2.256865],
          [-70.047709, -2.725156],
          [-70.692682, -3.742872],
          [-70.394044, -3.766591],
          [-69.893635, -4.298187],
          [-70.794769, -4.251265],
          [-70.928843, -4.401591],
          [-71.748406, -4.593983],
          [-72.891928, -5.274561],
          [-72.964507, -5.741251],
          [-73.219711, -6.089189],
          [-73.120027, -6.629931],
          [-73.724487, -6.918595],
          [-73.723401, -7.340999],
          [-73.987235, -7.52383],
          [-73.571059, -8.424447],
          [-73.015383, -9.032833],
          [-73.226713, -9.462213],
          [-72.563033, -9.520194],
          [-72.184891, -10.053598],
          [-71.302412, -10.079436],
          [-70.481894, -9.490118],
          [-70.548686, -11.009147],
          [-70.093752, -11.123972],
          [-69.529678, -10.951734],
          [-68.66508, -12.5613],
          [-68.88008, -12.899729],
          [-68.929224, -13.602684],
          [-68.948887, -14.453639],
          [-69.339535, -14.953195],
          [-69.160347, -15.323974],
          [-69.389764, -15.660129],
          [-68.959635, -16.500698],
          [-69.590424, -17.580012],
        ],
      ],
    },
    id: 'PE',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Philippines',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [126.376814, 8.414706],
            [126.478513, 7.750354],
            [126.537424, 7.189381],
            [126.196773, 6.274294],
            [125.831421, 7.293715],
            [125.363852, 6.786485],
            [125.683161, 6.049657],
            [125.396512, 5.581003],
            [124.219788, 6.161355],
            [123.93872, 6.885136],
            [124.243662, 7.36061],
            [123.610212, 7.833527],
            [123.296071, 7.418876],
            [122.825506, 7.457375],
            [122.085499, 6.899424],
            [121.919928, 7.192119],
            [122.312359, 8.034962],
            [122.942398, 8.316237],
            [123.487688, 8.69301],
            [123.841154, 8.240324],
            [124.60147, 8.514158],
            [124.764612, 8.960409],
            [125.471391, 8.986997],
            [125.412118, 9.760335],
            [126.222714, 9.286074],
            [126.306637, 8.782487],
            [126.376814, 8.414706],
          ],
        ],
        [
          [
            [123.982438, 10.278779],
            [123.623183, 9.950091],
            [123.309921, 9.318269],
            [122.995883, 9.022189],
            [122.380055, 9.713361],
            [122.586089, 9.981045],
            [122.837081, 10.261157],
            [122.947411, 10.881868],
            [123.49885, 10.940624],
            [123.337774, 10.267384],
            [124.077936, 11.232726],
            [123.982438, 10.278779],
          ],
        ],
        [
          [
            [118.504581, 9.316383],
            [117.174275, 8.3675],
            [117.664477, 9.066889],
            [118.386914, 9.6845],
            [118.987342, 10.376292],
            [119.511496, 11.369668],
            [119.689677, 10.554291],
            [119.029458, 10.003653],
            [118.504581, 9.316383],
          ],
        ],
        [
          [
            [121.883548, 11.891755],
            [122.483821, 11.582187],
            [123.120217, 11.58366],
            [123.100838, 11.165934],
            [122.637714, 10.741308],
            [122.00261, 10.441017],
            [121.967367, 10.905691],
            [122.03837, 11.415841],
            [121.883548, 11.891755],
          ],
        ],
        [
          [
            [125.502552, 12.162695],
            [125.783465, 11.046122],
            [125.011884, 11.311455],
            [125.032761, 10.975816],
            [125.277449, 10.358722],
            [124.801819, 10.134679],
            [124.760168, 10.837995],
            [124.459101, 10.88993],
            [124.302522, 11.495371],
            [124.891013, 11.415583],
            [124.87799, 11.79419],
            [124.266762, 12.557761],
            [125.227116, 12.535721],
            [125.502552, 12.162695],
          ],
        ],
        [
          [
            [121.527394, 13.06959],
            [121.26219, 12.20556],
            [120.833896, 12.704496],
            [120.323436, 13.466413],
            [121.180128, 13.429697],
            [121.527394, 13.06959],
          ],
        ],
        [
          [
            [121.321308, 18.504065],
            [121.937601, 18.218552],
            [122.246006, 18.47895],
            [122.336957, 18.224883],
            [122.174279, 17.810283],
            [122.515654, 17.093505],
            [122.252311, 16.262444],
            [121.662786, 15.931018],
            [121.50507, 15.124814],
            [121.728829, 14.328376],
            [122.258925, 14.218202],
            [122.701276, 14.336541],
            [123.950295, 13.782131],
            [123.855107, 13.237771],
            [124.181289, 12.997527],
            [124.077419, 12.536677],
            [123.298035, 13.027526],
            [122.928652, 13.55292],
            [122.671355, 13.185836],
            [122.03465, 13.784482],
            [121.126385, 13.636687],
            [120.628637, 13.857656],
            [120.679384, 14.271016],
            [120.991819, 14.525393],
            [120.693336, 14.756671],
            [120.564145, 14.396279],
            [120.070429, 14.970869],
            [119.920929, 15.406347],
            [119.883773, 16.363704],
            [120.286488, 16.034629],
            [120.390047, 17.599081],
            [120.715867, 18.505227],
            [121.321308, 18.504065],
          ],
        ],
      ],
    },
    id: 'PH',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Papua New Guinea',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [155.880026, -6.819997],
            [155.599991, -6.919991],
            [155.166994, -6.535931],
            [154.729192, -5.900828],
            [154.514114, -5.139118],
            [154.652504, -5.042431],
            [154.759991, -5.339984],
            [155.062918, -5.566792],
            [155.547746, -6.200655],
            [156.019965, -6.540014],
            [155.880026, -6.819997],
          ],
        ],
        [
          [
            [151.982796, -5.478063],
            [151.459107, -5.56028],
            [151.30139, -5.840728],
            [150.754447, -6.083763],
            [150.241197, -6.317754],
            [149.709963, -6.316513],
            [148.890065, -6.02604],
            [148.318937, -5.747142],
            [148.401826, -5.437756],
            [149.298412, -5.583742],
            [149.845562, -5.505503],
            [149.99625, -5.026101],
            [150.139756, -5.001348],
            [150.236908, -5.53222],
            [150.807467, -5.455842],
            [151.089672, -5.113693],
            [151.647881, -4.757074],
            [151.537862, -4.167807],
            [152.136792, -4.14879],
            [152.338743, -4.312966],
            [152.318693, -4.867661],
            [151.982796, -5.478063],
          ],
        ],
        [
          [
            [147.191874, -7.388024],
            [148.084636, -8.044108],
            [148.734105, -9.104664],
            [149.306835, -9.071436],
            [149.266631, -9.514406],
            [150.038728, -9.684318],
            [149.738798, -9.872937],
            [150.801628, -10.293687],
            [150.690575, -10.582713],
            [150.028393, -10.652476],
            [149.78231, -10.393267],
            [148.923138, -10.280923],
            [147.913018, -10.130441],
            [147.135443, -9.492444],
            [146.567881, -8.942555],
            [146.048481, -8.067414],
            [144.744168, -7.630128],
            [143.897088, -7.91533],
            [143.286376, -8.245491],
            [143.413913, -8.983069],
            [142.628431, -9.326821],
            [142.068259, -9.159596],
            [141.033852, -9.117893],
            [141.017057, -5.859022],
            [141.00021, -2.600151],
            [142.735247, -3.289153],
            [144.583971, -3.861418],
            [145.27318, -4.373738],
            [145.829786, -4.876498],
            [145.981922, -5.465609],
            [147.648073, -6.083659],
            [147.891108, -6.614015],
            [146.970905, -6.721657],
            [147.191874, -7.388024],
          ],
        ],
        [
          [
            [153.140038, -4.499983],
            [152.827292, -4.766427],
            [152.638673, -4.176127],
            [152.406026, -3.789743],
            [151.953237, -3.462062],
            [151.384279, -3.035422],
            [150.66205, -2.741486],
            [150.939965, -2.500002],
            [151.479984, -2.779985],
            [151.820015, -2.999972],
            [152.239989, -3.240009],
            [152.640017, -3.659983],
            [153.019994, -3.980015],
            [153.140038, -4.499983],
          ],
        ],
      ],
    },
    id: 'PG',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Poland',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [15.016996, 51.106674],
          [14.607098, 51.745188],
          [14.685026, 52.089947],
          [14.4376, 52.62485],
          [14.074521, 52.981263],
          [14.353315, 53.248171],
          [14.119686, 53.757029],
          [14.8029, 54.050706],
          [16.363477, 54.513159],
          [17.622832, 54.851536],
          [18.620859, 54.682606],
          [18.696255, 54.438719],
          [19.66064, 54.426084],
          [20.892245, 54.312525],
          [22.731099, 54.327537],
          [23.243987, 54.220567],
          [23.484128, 53.912498],
          [23.527536, 53.470122],
          [23.804935, 53.089731],
          [23.799199, 52.691099],
          [23.199494, 52.486977],
          [23.508002, 52.023647],
          [23.527071, 51.578454],
          [24.029986, 50.705407],
          [23.922757, 50.424881],
          [23.426508, 50.308506],
          [22.51845, 49.476774],
          [22.776419, 49.027395],
          [22.558138, 49.085738],
          [21.607808, 49.470107],
          [20.887955, 49.328772],
          [20.415839, 49.431453],
          [19.825023, 49.217125],
          [19.320713, 49.571574],
          [18.909575, 49.435846],
          [18.853144, 49.49623],
          [18.392914, 49.988629],
          [17.649445, 50.049038],
          [17.554567, 50.362146],
          [16.868769, 50.473974],
          [16.719476, 50.215747],
          [16.176253, 50.422607],
          [16.238627, 50.697733],
          [15.490972, 50.78473],
          [15.016996, 51.106674],
        ],
      ],
    },
    id: 'PL',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Puerto Rico',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-66.282434, 18.514762],
          [-65.771303, 18.426679],
          [-65.591004, 18.228035],
          [-65.847164, 17.975906],
          [-66.599934, 17.981823],
          [-67.184162, 17.946553],
          [-67.242428, 18.37446],
          [-67.100679, 18.520601],
          [-66.282434, 18.514762],
        ],
      ],
    },
    id: 'PR',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Portugal',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-9.034818, 41.880571],
          [-8.671946, 42.134689],
          [-8.263857, 42.280469],
          [-8.013175, 41.790886],
          [-7.422513, 41.792075],
          [-7.251309, 41.918346],
          [-6.668606, 41.883387],
          [-6.389088, 41.381815],
          [-6.851127, 41.111083],
          [-6.86402, 40.330872],
          [-7.026413, 40.184524],
          [-7.066592, 39.711892],
          [-7.498632, 39.629571],
          [-7.098037, 39.030073],
          [-7.374092, 38.373059],
          [-7.029281, 38.075764],
          [-7.166508, 37.803894],
          [-7.537105, 37.428904],
          [-7.453726, 37.097788],
          [-7.855613, 36.838269],
          [-8.382816, 36.97888],
          [-8.898857, 36.868809],
          [-8.746101, 37.651346],
          [-8.839998, 38.266243],
          [-9.287464, 38.358486],
          [-9.526571, 38.737429],
          [-9.446989, 39.392066],
          [-9.048305, 39.755093],
          [-8.977353, 40.159306],
          [-8.768684, 40.760639],
          [-8.790853, 41.184334],
          [-8.990789, 41.543459],
          [-9.034818, 41.880571],
        ],
      ],
    },
    id: 'PT',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Paraguay',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-62.685057, -22.249029],
          [-62.291179, -21.051635],
          [-62.265961, -20.513735],
          [-61.786326, -19.633737],
          [-60.043565, -19.342747],
          [-59.115042, -19.356906],
          [-58.183471, -19.868399],
          [-58.166392, -20.176701],
          [-57.870674, -20.732688],
          [-57.937156, -22.090176],
          [-56.88151, -22.282154],
          [-56.473317, -22.0863],
          [-55.797958, -22.35693],
          [-55.610683, -22.655619],
          [-55.517639, -23.571998],
          [-55.400747, -23.956935],
          [-55.027902, -24.001274],
          [-54.652834, -23.839578],
          [-54.29296, -24.021014],
          [-54.293476, -24.5708],
          [-54.428946, -25.162185],
          [-54.625291, -25.739255],
          [-54.788795, -26.621786],
          [-55.695846, -27.387837],
          [-56.486702, -27.548499],
          [-57.60976, -27.395899],
          [-58.618174, -27.123719],
          [-57.63366, -25.603657],
          [-57.777217, -25.16234],
          [-58.807128, -24.771459],
          [-60.028966, -24.032796],
          [-60.846565, -23.880713],
          [-62.685057, -22.249029],
        ],
      ],
    },
    id: 'PY',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Qatar',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [50.810108, 24.754743],
          [50.743911, 25.482424],
          [51.013352, 26.006992],
          [51.286462, 26.114582],
          [51.589079, 25.801113],
          [51.6067, 25.21567],
          [51.389608, 24.627386],
          [51.112415, 24.556331],
          [50.810108, 24.754743],
        ],
      ],
    },
    id: 'QA',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Romania',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [22.710531, 47.882194],
          [23.142236, 48.096341],
          [23.760958, 47.985598],
          [24.402056, 47.981878],
          [24.866317, 47.737526],
          [25.207743, 47.891056],
          [25.945941, 47.987149],
          [26.19745, 48.220881],
          [26.619337, 48.220726],
          [26.924176, 48.123264],
          [27.233873, 47.826771],
          [27.551166, 47.405117],
          [28.12803, 46.810476],
          [28.160018, 46.371563],
          [28.054443, 45.944586],
          [28.233554, 45.488283],
          [28.679779, 45.304031],
          [29.149725, 45.464925],
          [29.603289, 45.293308],
          [29.626543, 45.035391],
          [29.141612, 44.82021],
          [28.837858, 44.913874],
          [28.558081, 43.707462],
          [27.970107, 43.812468],
          [27.2424, 44.175986],
          [26.065159, 43.943494],
          [25.569272, 43.688445],
          [24.100679, 43.741051],
          [23.332302, 43.897011],
          [22.944832, 43.823785],
          [22.65715, 44.234923],
          [22.474008, 44.409228],
          [22.705726, 44.578003],
          [22.459022, 44.702517],
          [22.145088, 44.478422],
          [21.562023, 44.768947],
          [21.483526, 45.18117],
          [20.874313, 45.416375],
          [20.762175, 45.734573],
          [20.220192, 46.127469],
          [21.021952, 46.316088],
          [21.626515, 46.994238],
          [22.099768, 47.672439],
          [22.710531, 47.882194],
        ],
      ],
    },
    id: 'RO',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Russia',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [143.648007, 50.7476],
            [144.654148, 48.976391],
            [143.173928, 49.306551],
            [142.558668, 47.861575],
            [143.533492, 46.836728],
            [143.505277, 46.137908],
            [142.747701, 46.740765],
            [142.09203, 45.966755],
            [141.906925, 46.805929],
            [142.018443, 47.780133],
            [141.904445, 48.859189],
            [142.1358, 49.615163],
            [142.179983, 50.952342],
            [141.594076, 51.935435],
            [141.682546, 53.301966],
            [142.606934, 53.762145],
            [142.209749, 54.225476],
            [142.654786, 54.365881],
            [142.914616, 53.704578],
            [143.260848, 52.74076],
            [143.235268, 51.75666],
            [143.648007, 50.7476],
          ],
        ],
        [
          [
            [22.731099, 54.327537],
            [20.892245, 54.312525],
            [19.66064, 54.426084],
            [19.888481, 54.86616],
            [21.268449, 55.190482],
            [22.315724, 55.015299],
            [22.757764, 54.856574],
            [22.651052, 54.582741],
            [22.731099, 54.327537],
          ],
        ],
        [
          [
            [-175.01425, 66.58435],
            [-174.33983, 66.33556],
            [-174.57182, 67.06219],
            [-171.85731, 66.91308],
            [-169.89958, 65.97724],
            [-170.89107, 65.54139],
            [-172.53025, 65.43791],
            [-172.555, 64.46079],
            [-172.95533, 64.25269],
            [-173.89184, 64.2826],
            [-174.65392, 64.63125],
            [-175.98353, 64.92288],
            [-176.20716, 65.35667],
            [-177.22266, 65.52024],
            [-178.35993, 65.39052],
            [-178.90332, 65.74044],
            [-178.68611, 66.11211],
            [-179.88377, 65.87456],
            [-179.43268, 65.40411],
            [-180, 64.979709],
            [-180, 68.963636],
            [-177.55, 68.2],
            [-174.92825, 67.20589],
            [-175.01425, 66.58435],
          ],
        ],
        [
          [
            [180, 70.832199],
            [178.903425, 70.78114],
            [178.7253, 71.0988],
            [180, 71.515714],
            [180, 70.832199],
          ],
        ],
        [
          [
            [-178.69378, 70.89302],
            [-180, 70.832199],
            [-180, 71.515714],
            [-179.871875, 71.55762],
            [-179.02433, 71.55553],
            [-177.577945, 71.26948],
            [-177.663575, 71.13277],
            [-178.69378, 70.89302],
          ],
        ],
        [
          [
            [143.60385, 73.21244],
            [142.08763, 73.20544],
            [140.038155, 73.31692],
            [139.86312, 73.36983],
            [140.81171, 73.76506],
            [142.06207, 73.85758],
            [143.48283, 73.47525],
            [143.60385, 73.21244],
          ],
        ],
        [
          [
            [150.73167, 75.08406],
            [149.575925, 74.68892],
            [147.977465, 74.778355],
            [146.11919, 75.17298],
            [146.358485, 75.49682],
            [148.22223, 75.345845],
            [150.73167, 75.08406],
          ],
        ],
        [
          [
            [145.086285, 75.562625],
            [144.3, 74.82],
            [140.61381, 74.84768],
            [138.95544, 74.61148],
            [136.97439, 75.26167],
            [137.51176, 75.94917],
            [138.831075, 76.13676],
            [141.471615, 76.09289],
            [145.086285, 75.562625],
          ],
        ],
        [
          [
            [57.535693, 70.720464],
            [56.944979, 70.632743],
            [53.677375, 70.762658],
            [53.412017, 71.206662],
            [51.601895, 71.474759],
            [51.455754, 72.014881],
            [52.478275, 72.229442],
            [52.444169, 72.774731],
            [54.427614, 73.627548],
            [53.50829, 73.749814],
            [55.902459, 74.627486],
            [55.631933, 75.081412],
            [57.868644, 75.60939],
            [61.170044, 76.251883],
            [64.498368, 76.439055],
            [66.210977, 76.809782],
            [68.15706, 76.939697],
            [68.852211, 76.544811],
            [68.180573, 76.233642],
            [64.637326, 75.737755],
            [61.583508, 75.260885],
            [58.477082, 74.309056],
            [56.986786, 73.333044],
            [55.419336, 72.371268],
            [55.622838, 71.540595],
            [57.535693, 70.720464],
          ],
        ],
        [
          [
            [106.97013, 76.97419],
            [107.24, 76.48],
            [108.1538, 76.72335],
            [111.07726, 76.71],
            [113.33151, 76.22224],
            [114.13417, 75.84764],
            [113.88539, 75.32779],
            [112.77918, 75.03186],
            [110.15125, 74.47673],
            [109.4, 74.18],
            [110.64, 74.04],
            [112.11919, 73.78774],
            [113.01954, 73.97693],
            [113.52958, 73.33505],
            [113.96881, 73.59488],
            [115.56782, 73.75285],
            [118.77633, 73.58772],
            [119.02, 73.12],
            [123.20066, 72.97122],
            [123.25777, 73.73503],
            [125.38, 73.56],
            [126.97644, 73.56549],
            [128.59126, 73.03871],
            [129.05157, 72.39872],
            [128.46, 71.98],
            [129.71599, 71.19304],
            [131.28858, 70.78699],
            [132.2535, 71.8363],
            [133.85766, 71.38642],
            [135.56193, 71.65525],
            [137.49755, 71.34763],
            [138.23409, 71.62803],
            [139.86983, 71.48783],
            [139.14791, 72.41619],
            [140.46817, 72.84941],
            [149.5, 72.2],
            [150.35118, 71.60643],
            [152.9689, 70.84222],
            [157.00688, 71.03141],
            [158.99779, 70.86672],
            [159.83031, 70.45324],
            [159.70866, 69.72198],
            [160.94053, 69.43728],
            [162.27907, 69.64204],
            [164.05248, 69.66823],
            [165.94037, 69.47199],
            [167.83567, 69.58269],
            [169.57763, 68.6938],
            [170.81688, 69.01363],
            [170.0082, 69.65276],
            [170.45345, 70.09703],
            [173.64391, 69.81743],
            [175.72403, 69.87725],
            [178.6, 69.4],
            [180, 68.963636],
            [180, 64.979709],
            [179.99281, 64.97433],
            [178.7072, 64.53493],
            [177.41128, 64.60821],
            [178.313, 64.07593],
            [178.90825, 63.25197],
            [179.37034, 62.98262],
            [179.48636, 62.56894],
            [179.22825, 62.3041],
            [177.3643, 62.5219],
            [174.56929, 61.76915],
            [173.68013, 61.65261],
            [172.15, 60.95],
            [170.6985, 60.33618],
            [170.33085, 59.88177],
            [168.90046, 60.57355],
            [166.29498, 59.78855],
            [165.84, 60.16],
            [164.87674, 59.7316],
            [163.53929, 59.86871],
            [163.21711, 59.21101],
            [162.01733, 58.24328],
            [162.05297, 57.83912],
            [163.19191, 57.61503],
            [163.05794, 56.15924],
            [162.12958, 56.12219],
            [161.70146, 55.28568],
            [162.11749, 54.85514],
            [160.36877, 54.34433],
            [160.02173, 53.20257],
            [158.53094, 52.95868],
            [158.23118, 51.94269],
            [156.78979, 51.01105],
            [156.42, 51.7],
            [155.99182, 53.15895],
            [155.43366, 55.38103],
            [155.91442, 56.76792],
            [156.75815, 57.3647],
            [156.81035, 57.83204],
            [158.36433, 58.05575],
            [160.15064, 59.31477],
            [161.87204, 60.343],
            [163.66969, 61.1409],
            [164.47355, 62.55061],
            [163.25842, 62.46627],
            [162.65791, 61.6425],
            [160.12148, 60.54423],
            [159.30232, 61.77396],
            [156.72068, 61.43442],
            [154.21806, 59.75818],
            [155.04375, 59.14495],
            [152.81185, 58.88385],
            [151.26573, 58.78089],
            [151.33815, 59.50396],
            [149.78371, 59.65573],
            [148.54481, 59.16448],
            [145.48722, 59.33637],
            [142.19782, 59.03998],
            [138.95848, 57.08805],
            [135.12619, 54.72959],
            [136.70171, 54.60355],
            [137.19342, 53.97732],
            [138.1647, 53.75501],
            [138.80463, 54.25455],
            [139.90151, 54.18968],
            [141.34531, 53.08957],
            [141.37923, 52.23877],
            [140.59742, 51.23967],
            [140.51308, 50.04553],
            [140.06193, 48.44671],
            [138.55472, 46.99965],
            [138.21971, 46.30795],
            [136.86232, 45.1435],
            [135.51535, 43.989],
            [134.86939, 43.39821],
            [133.53687, 42.81147],
            [132.90627, 42.79849],
            [132.27807, 43.28456],
            [130.93587, 42.55274],
            [130.78, 42.22],
            [130.64, 42.395],
            [130.633866, 42.903015],
            [131.144688, 42.92999],
            [131.288555, 44.11152],
            [131.02519, 44.96796],
            [131.883454, 45.321162],
            [133.09712, 45.14409],
            [133.769644, 46.116927],
            [134.11235, 47.21248],
            [134.50081, 47.57845],
            [135.026311, 48.47823],
            [133.373596, 48.183442],
            [132.50669, 47.78896],
            [130.98726, 47.79013],
            [130.582293, 48.729687],
            [129.397818, 49.4406],
            [127.6574, 49.76027],
            [127.287456, 50.739797],
            [126.939157, 51.353894],
            [126.564399, 51.784255],
            [125.946349, 52.792799],
            [125.068211, 53.161045],
            [123.57147, 53.4588],
            [122.245748, 53.431726],
            [121.003085, 53.251401],
            [120.177089, 52.753886],
            [120.725789, 52.516226],
            [120.7382, 51.96411],
            [120.18208, 51.64355],
            [119.27939, 50.58292],
            [119.288461, 50.142883],
            [117.879244, 49.510983],
            [116.678801, 49.888531],
            [115.485695, 49.805177],
            [114.96211, 50.140247],
            [114.362456, 50.248303],
            [112.89774, 49.543565],
            [111.581231, 49.377968],
            [110.662011, 49.130128],
            [109.402449, 49.292961],
            [108.475167, 49.282548],
            [107.868176, 49.793705],
            [106.888804, 50.274296],
            [105.886591, 50.406019],
            [104.62158, 50.27532],
            [103.676545, 50.089966],
            [102.25589, 50.51056],
            [102.06521, 51.25991],
            [100.88948, 51.516856],
            [99.981732, 51.634006],
            [98.861491, 52.047366],
            [97.82574, 51.010995],
            [98.231762, 50.422401],
            [97.25976, 49.72605],
            [95.81402, 49.97746],
            [94.815949, 50.013433],
            [94.147566, 50.480537],
            [93.10421, 50.49529],
            [92.234712, 50.802171],
            [90.713667, 50.331812],
            [88.805567, 49.470521],
            [87.751264, 49.297198],
            [87.35997, 49.214981],
            [86.829357, 49.826675],
            [85.54127, 49.692859],
            [85.11556, 50.117303],
            [84.416377, 50.3114],
            [83.935115, 50.889246],
            [83.383004, 51.069183],
            [81.945986, 50.812196],
            [80.568447, 51.388336],
            [80.03556, 50.864751],
            [77.800916, 53.404415],
            [76.525179, 54.177003],
            [76.8911, 54.490524],
            [74.38482, 53.54685],
            [73.425679, 53.48981],
            [73.508516, 54.035617],
            [72.22415, 54.376655],
            [71.180131, 54.133285],
            [70.865267, 55.169734],
            [69.068167, 55.38525],
            [68.1691, 54.970392],
            [65.66687, 54.60125],
            [65.178534, 54.354228],
            [61.4366, 54.00625],
            [60.978066, 53.664993],
            [61.699986, 52.979996],
            [60.739993, 52.719986],
            [60.927269, 52.447548],
            [59.967534, 51.96042],
            [61.588003, 51.272659],
            [61.337424, 50.79907],
            [59.932807, 50.842194],
            [59.642282, 50.545442],
            [58.36332, 51.06364],
            [56.77798, 51.04355],
            [55.71694, 50.62171],
            [54.532878, 51.02624],
            [52.328724, 51.718652],
            [50.766648, 51.692762],
            [48.702382, 50.605128],
            [48.577841, 49.87476],
            [47.54948, 50.454698],
            [46.751596, 49.356006],
            [47.043672, 49.152039],
            [46.466446, 48.394152],
            [47.31524, 47.71585],
            [48.05725, 47.74377],
            [48.694734, 47.075628],
            [48.59325, 46.56104],
            [49.10116, 46.39933],
            [48.64541, 45.80629],
            [47.67591, 45.64149],
            [46.68201, 44.6092],
            [47.59094, 43.66016],
            [47.49252, 42.98658],
            [48.58437, 41.80888],
            [47.987283, 41.405819],
            [47.815666, 41.151416],
            [47.373315, 41.219732],
            [46.686071, 41.827137],
            [46.404951, 41.860675],
            [45.7764, 42.09244],
            [45.470279, 42.502781],
            [44.537623, 42.711993],
            [43.93121, 42.55496],
            [43.75599, 42.74083],
            [42.3944, 43.2203],
            [40.92219, 43.38215],
            [40.076965, 43.553104],
            [39.955009, 43.434998],
            [38.68, 44.28],
            [37.53912, 44.65721],
            [36.67546, 45.24469],
            [37.40317, 45.40451],
            [38.23295, 46.24087],
            [37.67372, 46.63657],
            [39.14767, 47.04475],
            [39.1212, 47.26336],
            [38.223538, 47.10219],
            [38.255112, 47.5464],
            [38.77057, 47.82562],
            [39.738278, 47.898937],
            [39.89562, 48.23241],
            [39.67465, 48.78382],
            [40.080789, 49.30743],
            [40.06904, 49.60105],
            [38.594988, 49.926462],
            [38.010631, 49.915662],
            [37.39346, 50.383953],
            [36.626168, 50.225591],
            [35.356116, 50.577197],
            [35.37791, 50.77394],
            [35.022183, 51.207572],
            [34.224816, 51.255993],
            [34.141978, 51.566413],
            [34.391731, 51.768882],
            [33.7527, 52.335075],
            [32.715761, 52.238465],
            [32.412058, 52.288695],
            [32.15944, 52.06125],
            [31.78597, 52.10168],
            [31.540018, 52.742052],
            [31.305201, 53.073996],
            [31.49764, 53.16743],
            [32.304519, 53.132726],
            [32.693643, 53.351421],
            [32.405599, 53.618045],
            [31.731273, 53.794029],
            [31.791424, 53.974639],
            [31.384472, 54.157056],
            [30.757534, 54.811771],
            [30.971836, 55.081548],
            [30.873909, 55.550976],
            [29.896294, 55.789463],
            [29.371572, 55.670091],
            [29.229513, 55.918344],
            [28.176709, 56.16913],
            [27.855282, 56.759326],
            [27.770016, 57.244258],
            [27.288185, 57.474528],
            [27.716686, 57.791899],
            [27.42015, 58.72457],
            [28.131699, 59.300825],
            [27.98112, 59.47537],
            [29.1177, 60.02805],
            [28.07, 60.50352],
            [30.211107, 61.780028],
            [31.139991, 62.357693],
            [31.516092, 62.867687],
            [30.035872, 63.552814],
            [30.444685, 64.204453],
            [29.54443, 64.948672],
            [30.21765, 65.80598],
            [29.054589, 66.944286],
            [29.977426, 67.698297],
            [28.445944, 68.364613],
            [28.59193, 69.064777],
            [29.39955, 69.15692],
            [31.10108, 69.55811],
            [32.13272, 69.90595],
            [33.77547, 69.30142],
            [36.51396, 69.06342],
            [40.29234, 67.9324],
            [41.05987, 67.45713],
            [41.12595, 66.79158],
            [40.01583, 66.26618],
            [38.38295, 65.99953],
            [33.91871, 66.75961],
            [33.18444, 66.63253],
            [34.81477, 65.90015],
            [34.878574, 65.436213],
            [34.94391, 64.41437],
            [36.23129, 64.10945],
            [37.01273, 63.84983],
            [37.14197, 64.33471],
            [36.539579, 64.76446],
            [37.17604, 65.14322],
            [39.59345, 64.52079],
            [40.4356, 64.76446],
            [39.7626, 65.49682],
            [42.09309, 66.47623],
            [43.01604, 66.41858],
            [43.94975, 66.06908],
            [44.53226, 66.75634],
            [43.69839, 67.35245],
            [44.18795, 67.95051],
            [43.45282, 68.57079],
            [46.25, 68.25],
            [46.82134, 67.68997],
            [45.55517, 67.56652],
            [45.56202, 67.01005],
            [46.34915, 66.66767],
            [47.89416, 66.88455],
            [48.13876, 67.52238],
            [50.22766, 67.99867],
            [53.71743, 68.85738],
            [54.47171, 68.80815],
            [53.48582, 68.20131],
            [54.72628, 68.09702],
            [55.44268, 68.43866],
            [57.31702, 68.46628],
            [58.802, 68.88082],
            [59.94142, 68.27844],
            [61.07784, 68.94069],
            [60.03, 69.52],
            [60.55, 69.85],
            [63.504, 69.54739],
            [64.888115, 69.234835],
            [68.51216, 68.09233],
            [69.18068, 68.61563],
            [68.16444, 69.14436],
            [68.13522, 69.35649],
            [66.93008, 69.45461],
            [67.25976, 69.92873],
            [66.72492, 70.70889],
            [66.69466, 71.02897],
            [68.54006, 71.9345],
            [69.19636, 72.84336],
            [69.94, 73.04],
            [72.58754, 72.77629],
            [72.79603, 72.22006],
            [71.84811, 71.40898],
            [72.47011, 71.09019],
            [72.79188, 70.39114],
            [72.5647, 69.02085],
            [73.66787, 68.4079],
            [73.2387, 67.7404],
            [71.28, 66.32],
            [72.42301, 66.17267],
            [72.82077, 66.53267],
            [73.92099, 66.78946],
            [74.18651, 67.28429],
            [75.052, 67.76047],
            [74.46926, 68.32899],
            [74.93584, 68.98918],
            [73.84236, 69.07146],
            [73.60187, 69.62763],
            [74.3998, 70.63175],
            [73.1011, 71.44717],
            [74.89082, 72.12119],
            [74.65926, 72.83227],
            [75.15801, 72.85497],
            [75.68351, 72.30056],
            [75.28898, 71.33556],
            [76.35911, 71.15287],
            [75.90313, 71.87401],
            [77.57665, 72.26717],
            [79.65202, 72.32011],
            [81.5, 71.75],
            [80.61071, 72.58285],
            [80.51109, 73.6482],
            [82.25, 73.85],
            [84.65526, 73.80591],
            [86.8223, 73.93688],
            [86.00956, 74.45967],
            [87.16682, 75.11643],
            [88.31571, 75.14393],
            [90.26, 75.64],
            [92.90058, 75.77333],
            [93.23421, 76.0472],
            [95.86, 76.14],
            [96.67821, 75.91548],
            [98.92254, 76.44689],
            [100.75967, 76.43028],
            [101.03532, 76.86189],
            [101.99084, 77.28754],
            [104.3516, 77.69792],
            [106.06664, 77.37389],
            [104.705, 77.1274],
            [106.97013, 76.97419],
          ],
        ],
        [
          [
            [105.07547, 78.30689],
            [99.43814, 77.921],
            [101.2649, 79.23399],
            [102.08635, 79.34641],
            [102.837815, 79.28129],
            [105.37243, 78.71334],
            [105.07547, 78.30689],
          ],
        ],
        [
          [
            [51.136187, 80.54728],
            [49.793685, 80.415428],
            [48.894411, 80.339567],
            [48.754937, 80.175468],
            [47.586119, 80.010181],
            [46.502826, 80.247247],
            [47.072455, 80.559424],
            [44.846958, 80.58981],
            [46.799139, 80.771918],
            [48.318477, 80.78401],
            [48.522806, 80.514569],
            [49.09719, 80.753986],
            [50.039768, 80.918885],
            [51.522933, 80.699726],
            [51.136187, 80.54728],
          ],
        ],
        [
          [
            [99.93976, 78.88094],
            [97.75794, 78.7562],
            [94.97259, 79.044745],
            [93.31288, 79.4265],
            [92.5454, 80.14379],
            [91.18107, 80.34146],
            [93.77766, 81.0246],
            [95.940895, 81.2504],
            [97.88385, 80.746975],
            [100.186655, 79.780135],
            [99.93976, 78.88094],
          ],
        ],
      ],
    },
    id: 'RU',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Rwanda',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [30.419105, -1.134659],
          [30.816135, -1.698914],
          [30.758309, -2.28725],
          [30.469696, -2.413858],
          [29.938359, -2.348487],
          [29.632176, -2.917858],
          [29.024926, -2.839258],
          [29.117479, -2.292211],
          [29.254835, -2.21511],
          [29.291887, -1.620056],
          [29.579466, -1.341313],
          [29.821519, -1.443322],
          [30.419105, -1.134659],
        ],
      ],
    },
    id: 'RW',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Western Sahara',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-8.794884, 27.120696],
          [-8.817828, 27.656426],
          [-8.66559, 27.656426],
          [-8.665124, 27.589479],
          [-8.6844, 27.395744],
          [-8.687294, 25.881056],
          [-11.969419, 25.933353],
          [-11.937224, 23.374594],
          [-12.874222, 23.284832],
          [-13.118754, 22.77122],
          [-12.929102, 21.327071],
          [-16.845194, 21.333323],
          [-17.063423, 20.999752],
          [-17.020428, 21.42231],
          [-17.002962, 21.420734],
          [-14.750955, 21.5006],
          [-14.630833, 21.86094],
          [-14.221168, 22.310163],
          [-13.89111, 23.691009],
          [-12.500963, 24.770116],
          [-12.030759, 26.030866],
          [-11.71822, 26.104092],
          [-11.392555, 26.883424],
          [-10.551263, 26.990808],
          [-10.189424, 26.860945],
          [-9.735343, 26.860945],
          [-9.413037, 27.088476],
          [-8.794884, 27.120696],
        ],
      ],
    },
    id: 'EH',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Saudi Arabia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [42.779332, 16.347891],
          [42.649573, 16.774635],
          [42.347989, 17.075806],
          [42.270888, 17.474722],
          [41.754382, 17.833046],
          [41.221391, 18.6716],
          [40.939341, 19.486485],
          [40.247652, 20.174635],
          [39.801685, 20.338862],
          [39.139399, 21.291905],
          [39.023696, 21.986875],
          [39.066329, 22.579656],
          [38.492772, 23.688451],
          [38.02386, 24.078686],
          [37.483635, 24.285495],
          [37.154818, 24.858483],
          [37.209491, 25.084542],
          [36.931627, 25.602959],
          [36.639604, 25.826228],
          [36.249137, 26.570136],
          [35.640182, 27.37652],
          [35.130187, 28.063352],
          [34.632336, 28.058546],
          [34.787779, 28.607427],
          [34.83222, 28.957483],
          [34.956037, 29.356555],
          [36.068941, 29.197495],
          [36.501214, 29.505254],
          [36.740528, 29.865283],
          [37.503582, 30.003776],
          [37.66812, 30.338665],
          [37.998849, 30.5085],
          [37.002166, 31.508413],
          [39.004886, 32.010217],
          [39.195468, 32.161009],
          [40.399994, 31.889992],
          [41.889981, 31.190009],
          [44.709499, 29.178891],
          [46.568713, 29.099025],
          [47.459822, 29.002519],
          [47.708851, 28.526063],
          [48.416094, 28.552004],
          [48.807595, 27.689628],
          [49.299554, 27.461218],
          [49.470914, 27.109999],
          [50.152422, 26.689663],
          [50.212935, 26.277027],
          [50.113303, 25.943972],
          [50.239859, 25.60805],
          [50.527387, 25.327808],
          [50.660557, 24.999896],
          [50.810108, 24.754743],
          [51.112415, 24.556331],
          [51.389608, 24.627386],
          [51.579519, 24.245497],
          [51.617708, 24.014219],
          [52.000733, 23.001154],
          [55.006803, 22.496948],
          [55.208341, 22.70833],
          [55.666659, 22.000001],
          [54.999982, 19.999994],
          [52.00001, 19.000003],
          [49.116672, 18.616668],
          [48.183344, 18.166669],
          [47.466695, 17.116682],
          [47.000005, 16.949999],
          [46.749994, 17.283338],
          [46.366659, 17.233315],
          [45.399999, 17.333335],
          [45.216651, 17.433329],
          [44.062613, 17.410359],
          [43.791519, 17.319977],
          [43.380794, 17.579987],
          [43.115798, 17.08844],
          [43.218375, 16.66689],
          [42.779332, 16.347891],
        ],
      ],
    },
    id: 'SA',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Sudan',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [33.963393, 9.464285],
          [33.824963, 9.484061],
          [33.842131, 9.981915],
          [33.721959, 10.325262],
          [33.206938, 10.720112],
          [33.086766, 11.441141],
          [33.206938, 12.179338],
          [32.743419, 12.248008],
          [32.67475, 12.024832],
          [32.073892, 11.97333],
          [32.314235, 11.681484],
          [32.400072, 11.080626],
          [31.850716, 10.531271],
          [31.352862, 9.810241],
          [30.837841, 9.707237],
          [29.996639, 10.290927],
          [29.618957, 10.084919],
          [29.515953, 9.793074],
          [29.000932, 9.604232],
          [28.966597, 9.398224],
          [27.97089, 9.398224],
          [27.833551, 9.604232],
          [27.112521, 9.638567],
          [26.752006, 9.466893],
          [26.477328, 9.55273],
          [25.962307, 10.136421],
          [25.790633, 10.411099],
          [25.069604, 10.27376],
          [24.794926, 9.810241],
          [24.537415, 8.917538],
          [24.194068, 8.728696],
          [23.88698, 8.61973],
          [23.805813, 8.666319],
          [23.459013, 8.954286],
          [23.394779, 9.265068],
          [23.55725, 9.681218],
          [23.554304, 10.089255],
          [22.977544, 10.714463],
          [22.864165, 11.142395],
          [22.87622, 11.38461],
          [22.50869, 11.67936],
          [22.49762, 12.26024],
          [22.28801, 12.64605],
          [21.93681, 12.58818],
          [22.03759, 12.95546],
          [22.29658, 13.37232],
          [22.18329, 13.78648],
          [22.51202, 14.09318],
          [22.30351, 14.32682],
          [22.56795, 14.94429],
          [23.02459, 15.68072],
          [23.88689, 15.61084],
          [23.83766, 19.58047],
          [23.85, 20],
          [25, 20.00304],
          [25, 22],
          [29.02, 22],
          [32.9, 22],
          [36.86623, 22],
          [37.18872, 21.01885],
          [36.96941, 20.83744],
          [37.1147, 19.80796],
          [37.48179, 18.61409],
          [37.86276, 18.36786],
          [38.41009, 17.998307],
          [37.904, 17.42754],
          [37.16747, 17.26314],
          [36.85253, 16.95655],
          [36.75389, 16.29186],
          [36.32322, 14.82249],
          [36.42951, 14.42211],
          [36.27022, 13.56333],
          [35.86363, 12.57828],
          [35.26049, 12.08286],
          [34.83163, 11.31896],
          [34.73115, 10.91017],
          [34.25745, 10.63009],
          [33.96162, 9.58358],
          [33.963393, 9.464285],
        ],
      ],
    },
    id: 'SD',
  },
  {
    type: 'Feature',
    properties: {
      name: 'South Sudan',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [33.963393, 9.464285],
          [33.97498, 8.68456],
          [33.8255, 8.37916],
          [33.2948, 8.35458],
          [32.95418, 7.78497],
          [33.56829, 7.71334],
          [34.0751, 7.22595],
          [34.25032, 6.82607],
          [34.70702, 6.59422],
          [35.298007, 5.506],
          [34.620196, 4.847123],
          [34.005, 4.249885],
          [33.39, 3.79],
          [32.68642, 3.79232],
          [31.88145, 3.55827],
          [31.24556, 3.7819],
          [30.83385, 3.50917],
          [29.95349, 4.1737],
          [29.715995, 4.600805],
          [29.159078, 4.389267],
          [28.696678, 4.455077],
          [28.428994, 4.287155],
          [27.979977, 4.408413],
          [27.374226, 5.233944],
          [27.213409, 5.550953],
          [26.465909, 5.946717],
          [26.213418, 6.546603],
          [25.796648, 6.979316],
          [25.124131, 7.500085],
          [25.114932, 7.825104],
          [24.567369, 8.229188],
          [23.88698, 8.61973],
          [24.194068, 8.728696],
          [24.537415, 8.917538],
          [24.794926, 9.810241],
          [25.069604, 10.27376],
          [25.790633, 10.411099],
          [25.962307, 10.136421],
          [26.477328, 9.55273],
          [26.752006, 9.466893],
          [27.112521, 9.638567],
          [27.833551, 9.604232],
          [27.97089, 9.398224],
          [28.966597, 9.398224],
          [29.000932, 9.604232],
          [29.515953, 9.793074],
          [29.618957, 10.084919],
          [29.996639, 10.290927],
          [30.837841, 9.707237],
          [31.352862, 9.810241],
          [31.850716, 10.531271],
          [32.400072, 11.080626],
          [32.314235, 11.681484],
          [32.073892, 11.97333],
          [32.67475, 12.024832],
          [32.743419, 12.248008],
          [33.206938, 12.179338],
          [33.086766, 11.441141],
          [33.206938, 10.720112],
          [33.721959, 10.325262],
          [33.842131, 9.981915],
          [33.824963, 9.484061],
          [33.963393, 9.464285],
        ],
      ],
    },
    id: 'SS',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Senegal',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-16.713729, 13.594959],
          [-17.126107, 14.373516],
          [-17.625043, 14.729541],
          [-17.185173, 14.919477],
          [-16.700706, 15.621527],
          [-16.463098, 16.135036],
          [-16.12069, 16.455663],
          [-15.623666, 16.369337],
          [-15.135737, 16.587282],
          [-14.577348, 16.598264],
          [-14.099521, 16.304302],
          [-13.435738, 16.039383],
          [-12.830658, 15.303692],
          [-12.17075, 14.616834],
          [-12.124887, 13.994727],
          [-11.927716, 13.422075],
          [-11.553398, 13.141214],
          [-11.467899, 12.754519],
          [-11.513943, 12.442988],
          [-11.658301, 12.386583],
          [-12.203565, 12.465648],
          [-12.278599, 12.35444],
          [-12.499051, 12.33209],
          [-13.217818, 12.575874],
          [-13.700476, 12.586183],
          [-15.548477, 12.62817],
          [-15.816574, 12.515567],
          [-16.147717, 12.547762],
          [-16.677452, 12.384852],
          [-16.841525, 13.151394],
          [-15.931296, 13.130284],
          [-15.691001, 13.270353],
          [-15.511813, 13.27857],
          [-15.141163, 13.509512],
          [-14.712197, 13.298207],
          [-14.277702, 13.280585],
          [-13.844963, 13.505042],
          [-14.046992, 13.794068],
          [-14.376714, 13.62568],
          [-14.687031, 13.630357],
          [-15.081735, 13.876492],
          [-15.39877, 13.860369],
          [-15.624596, 13.623587],
          [-16.713729, 13.594959],
        ],
      ],
    },
    id: 'SN',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Solomon Islands',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [162.119025, -10.482719],
            [162.398646, -10.826367],
            [161.700032, -10.820011],
            [161.319797, -10.204751],
            [161.917383, -10.446701],
            [162.119025, -10.482719],
          ],
        ],
        [
          [
            [160.852229, -9.872937],
            [160.462588, -9.89521],
            [159.849447, -9.794027],
            [159.640003, -9.63998],
            [159.702945, -9.24295],
            [160.362956, -9.400304],
            [160.688518, -9.610162],
            [160.852229, -9.872937],
          ],
        ],
        [
          [
            [161.679982, -9.599982],
            [161.529397, -9.784312],
            [160.788253, -8.917543],
            [160.579997, -8.320009],
            [160.920028, -8.320009],
            [161.280006, -9.120011],
            [161.679982, -9.599982],
          ],
        ],
        [
          [
            [159.875027, -8.33732],
            [159.917402, -8.53829],
            [159.133677, -8.114181],
            [158.586114, -7.754824],
            [158.21115, -7.421872],
            [158.359978, -7.320018],
            [158.820001, -7.560003],
            [159.640003, -8.020027],
            [159.875027, -8.33732],
          ],
        ],
        [
          [
            [157.538426, -7.34782],
            [157.33942, -7.404767],
            [156.90203, -7.176874],
            [156.491358, -6.765943],
            [156.542828, -6.599338],
            [157.14, -7.021638],
            [157.538426, -7.34782],
          ],
        ],
      ],
    },
    id: 'SB',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Sierra Leone',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-11.438779, 6.785917],
          [-11.708195, 6.860098],
          [-12.428099, 7.262942],
          [-12.949049, 7.798646],
          [-13.124025, 8.163946],
          [-13.24655, 8.903049],
          [-12.711958, 9.342712],
          [-12.596719, 9.620188],
          [-12.425929, 9.835834],
          [-12.150338, 9.858572],
          [-11.917277, 10.046984],
          [-11.117481, 10.045873],
          [-10.839152, 9.688246],
          [-10.622395, 9.26791],
          [-10.65477, 8.977178],
          [-10.494315, 8.715541],
          [-10.505477, 8.348896],
          [-10.230094, 8.406206],
          [-10.695595, 7.939464],
          [-11.146704, 7.396706],
          [-11.199802, 7.105846],
          [-11.438779, 6.785917],
        ],
      ],
    },
    id: 'SL',
  },
  {
    type: 'Feature',
    properties: {
      name: 'El Salvador',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-87.793111, 13.38448],
          [-87.904112, 13.149017],
          [-88.483302, 13.163951],
          [-88.843228, 13.259734],
          [-89.256743, 13.458533],
          [-89.812394, 13.520622],
          [-90.095555, 13.735338],
          [-90.064678, 13.88197],
          [-89.721934, 14.134228],
          [-89.534219, 14.244816],
          [-89.587343, 14.362586],
          [-89.353326, 14.424133],
          [-89.058512, 14.340029],
          [-88.843073, 14.140507],
          [-88.541231, 13.980155],
          [-88.503998, 13.845486],
          [-88.065343, 13.964626],
          [-87.859515, 13.893312],
          [-87.723503, 13.78505],
          [-87.793111, 13.38448],
        ],
      ],
    },
    id: 'SV',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Somalia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [48.93813, 9.451749],
          [48.486736, 8.837626],
          [47.78942, 8.003],
          [46.948328, 7.996877],
          [43.67875, 9.18358],
          [43.296975, 9.540477],
          [42.92812, 10.02194],
          [42.55876, 10.57258],
          [42.776852, 10.926879],
          [43.145305, 11.46204],
          [43.47066, 11.27771],
          [43.666668, 10.864169],
          [44.117804, 10.445538],
          [44.614259, 10.442205],
          [45.556941, 10.698029],
          [46.645401, 10.816549],
          [47.525658, 11.127228],
          [48.021596, 11.193064],
          [48.378784, 11.375482],
          [48.948206, 11.410622],
          [48.942005, 11.394266],
          [48.938491, 10.982327],
          [48.938233, 9.9735],
          [48.93813, 9.451749],
        ],
      ],
    },
    id: 'SO',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Somalia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [49.72862, 11.5789],
          [50.25878, 11.67957],
          [50.73202, 12.0219],
          [51.1112, 12.02464],
          [51.13387, 11.74815],
          [51.04153, 11.16651],
          [51.04531, 10.6409],
          [50.83418, 10.27972],
          [50.55239, 9.19874],
          [50.07092, 8.08173],
          [49.4527, 6.80466],
          [48.59455, 5.33911],
          [47.74079, 4.2194],
          [46.56476, 2.85529],
          [45.56399, 2.04576],
          [44.06815, 1.05283],
          [43.13597, 0.2922],
          [42.04157, -0.91916],
          [41.81095, -1.44647],
          [41.58513, -1.68325],
          [40.993, -0.85829],
          [40.98105, 2.78452],
          [41.855083, 3.918912],
          [42.12861, 4.23413],
          [42.76967, 4.25259],
          [43.66087, 4.95755],
          [44.9636, 5.00162],
          [47.78942, 8.003],
          [48.486736, 8.837626],
          [48.93813, 9.451749],
          [48.938233, 9.9735],
          [48.938491, 10.982327],
          [48.942005, 11.394266],
          [48.948205, 11.410617],
          [49.26776, 11.43033],
          [49.72862, 11.5789],
        ],
      ],
    },
    id: 'SO',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Serbia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [20.874313, 45.416375],
          [21.483526, 45.18117],
          [21.562023, 44.768947],
          [22.145088, 44.478422],
          [22.459022, 44.702517],
          [22.705726, 44.578003],
          [22.474008, 44.409228],
          [22.65715, 44.234923],
          [22.410446, 44.008063],
          [22.500157, 43.642814],
          [22.986019, 43.211161],
          [22.604801, 42.898519],
          [22.436595, 42.580321],
          [22.545012, 42.461362],
          [22.380526, 42.32026],
          [21.91708, 42.30364],
          [21.576636, 42.245224],
          [21.54332, 42.32025],
          [21.66292, 42.43922],
          [21.77505, 42.6827],
          [21.63302, 42.67717],
          [21.43866, 42.86255],
          [21.27421, 42.90959],
          [21.143395, 43.068685],
          [20.95651, 43.13094],
          [20.81448, 43.27205],
          [20.63508, 43.21671],
          [20.49679, 42.88469],
          [20.25758, 42.81275],
          [20.3398, 42.89852],
          [19.95857, 43.10604],
          [19.63, 43.21378],
          [19.48389, 43.35229],
          [19.21852, 43.52384],
          [19.454, 43.5681],
          [19.59976, 44.03847],
          [19.11761, 44.42307],
          [19.36803, 44.863],
          [19.00548, 44.86023],
          [19.390476, 45.236516],
          [19.072769, 45.521511],
          [18.82982, 45.90888],
          [19.596045, 46.17173],
          [20.220192, 46.127469],
          [20.762175, 45.734573],
          [20.874313, 45.416375],
        ],
      ],
    },
    id: 'RS',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Suriname',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-57.147436, 5.97315],
          [-55.949318, 5.772878],
          [-55.84178, 5.953125],
          [-55.03325, 6.025291],
          [-53.958045, 5.756548],
          [-54.478633, 4.896756],
          [-54.399542, 4.212611],
          [-54.006931, 3.620038],
          [-54.181726, 3.18978],
          [-54.269705, 2.732392],
          [-54.524754, 2.311849],
          [-55.097587, 2.523748],
          [-55.569755, 2.421506],
          [-55.973322, 2.510364],
          [-56.073342, 2.220795],
          [-55.9056, 2.021996],
          [-55.995698, 1.817667],
          [-56.539386, 1.899523],
          [-57.150098, 2.768927],
          [-57.281433, 3.333492],
          [-57.601569, 3.334655],
          [-58.044694, 4.060864],
          [-57.86021, 4.576801],
          [-57.914289, 4.812626],
          [-57.307246, 5.073567],
          [-57.147436, 5.97315],
        ],
      ],
    },
    id: 'SR',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Slovakia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [18.853144, 49.49623],
          [18.909575, 49.435846],
          [19.320713, 49.571574],
          [19.825023, 49.217125],
          [20.415839, 49.431453],
          [20.887955, 49.328772],
          [21.607808, 49.470107],
          [22.558138, 49.085738],
          [22.280842, 48.825392],
          [22.085608, 48.422264],
          [21.872236, 48.319971],
          [20.801294, 48.623854],
          [20.473562, 48.56285],
          [20.239054, 48.327567],
          [19.769471, 48.202691],
          [19.661364, 48.266615],
          [19.174365, 48.111379],
          [18.777025, 48.081768],
          [18.696513, 47.880954],
          [17.857133, 47.758429],
          [17.488473, 47.867466],
          [16.979667, 48.123497],
          [16.879983, 48.470013],
          [16.960288, 48.596982],
          [17.101985, 48.816969],
          [17.545007, 48.800019],
          [17.886485, 48.903475],
          [17.913512, 48.996493],
          [18.104973, 49.043983],
          [18.170498, 49.271515],
          [18.399994, 49.315001],
          [18.554971, 49.495015],
          [18.853144, 49.49623],
        ],
      ],
    },
    id: 'SK',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Slovenia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [13.806475, 46.509306],
          [14.632472, 46.431817],
          [15.137092, 46.658703],
          [16.011664, 46.683611],
          [16.202298, 46.852386],
          [16.370505, 46.841327],
          [16.564808, 46.503751],
          [15.768733, 46.238108],
          [15.67153, 45.834154],
          [15.323954, 45.731783],
          [15.327675, 45.452316],
          [14.935244, 45.471695],
          [14.595109, 45.634941],
          [14.411968, 45.466166],
          [13.71506, 45.500324],
          [13.93763, 45.591016],
          [13.69811, 46.016778],
          [13.806475, 46.509306],
        ],
      ],
    },
    id: 'SI',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Sweden',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [22.183173, 65.723741],
          [21.213517, 65.026005],
          [21.369631, 64.413588],
          [19.778876, 63.609554],
          [17.847779, 62.7494],
          [17.119555, 61.341166],
          [17.831346, 60.636583],
          [18.787722, 60.081914],
          [17.869225, 58.953766],
          [16.829185, 58.719827],
          [16.44771, 57.041118],
          [15.879786, 56.104302],
          [14.666681, 56.200885],
          [14.100721, 55.407781],
          [12.942911, 55.361737],
          [12.625101, 56.30708],
          [11.787942, 57.441817],
          [11.027369, 58.856149],
          [11.468272, 59.432393],
          [12.300366, 60.117933],
          [12.631147, 61.293572],
          [11.992064, 61.800362],
          [11.930569, 63.128318],
          [12.579935, 64.066219],
          [13.571916, 64.049114],
          [13.919905, 64.445421],
          [13.55569, 64.787028],
          [15.108411, 66.193867],
          [16.108712, 67.302456],
          [16.768879, 68.013937],
          [17.729182, 68.010552],
          [17.993868, 68.567391],
          [19.87856, 68.407194],
          [20.025269, 69.065139],
          [20.645593, 69.106247],
          [21.978535, 68.616846],
          [23.539473, 67.936009],
          [23.56588, 66.396051],
          [23.903379, 66.006927],
          [22.183173, 65.723741],
        ],
      ],
    },
    id: 'SE',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Swaziland',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [32.071665, -26.73382],
          [31.86806, -27.177927],
          [31.282773, -27.285879],
          [30.685962, -26.743845],
          [30.676609, -26.398078],
          [30.949667, -26.022649],
          [31.04408, -25.731452],
          [31.333158, -25.660191],
          [31.837778, -25.843332],
          [31.985779, -26.29178],
          [32.071665, -26.73382],
        ],
      ],
    },
    id: 'SZ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Syria',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [38.792341, 33.378686],
          [36.834062, 32.312938],
          [35.719918, 32.709192],
          [35.700798, 32.716014],
          [35.836397, 32.868123],
          [35.821101, 33.277426],
          [36.06646, 33.824912],
          [36.61175, 34.201789],
          [36.448194, 34.593935],
          [35.998403, 34.644914],
          [35.905023, 35.410009],
          [36.149763, 35.821535],
          [36.41755, 36.040617],
          [36.685389, 36.259699],
          [36.739494, 36.81752],
          [37.066761, 36.623036],
          [38.167727, 36.90121],
          [38.699891, 36.712927],
          [39.52258, 36.716054],
          [40.673259, 37.091276],
          [41.212089, 37.074352],
          [42.349591, 37.229873],
          [41.837064, 36.605854],
          [41.289707, 36.358815],
          [41.383965, 35.628317],
          [41.006159, 34.419372],
          [38.792341, 33.378686],
        ],
      ],
    },
    id: 'SY',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Chad',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [14.495787, 12.859396],
          [14.595781, 13.330427],
          [13.954477, 13.353449],
          [13.956699, 13.996691],
          [13.540394, 14.367134],
          [13.97217, 15.68437],
          [15.247731, 16.627306],
          [15.300441, 17.92795],
          [15.685741, 19.95718],
          [15.903247, 20.387619],
          [15.487148, 20.730415],
          [15.47106, 21.04845],
          [15.096888, 21.308519],
          [14.8513, 22.86295],
          [15.86085, 23.40972],
          [19.84926, 21.49509],
          [23.83766, 19.58047],
          [23.88689, 15.61084],
          [23.02459, 15.68072],
          [22.56795, 14.94429],
          [22.30351, 14.32682],
          [22.51202, 14.09318],
          [22.18329, 13.78648],
          [22.29658, 13.37232],
          [22.03759, 12.95546],
          [21.93681, 12.58818],
          [22.28801, 12.64605],
          [22.49762, 12.26024],
          [22.50869, 11.67936],
          [22.87622, 11.38461],
          [22.864165, 11.142395],
          [22.231129, 10.971889],
          [21.723822, 10.567056],
          [21.000868, 9.475985],
          [20.059685, 9.012706],
          [19.094008, 9.074847],
          [18.81201, 8.982915],
          [18.911022, 8.630895],
          [18.389555, 8.281304],
          [17.96493, 7.890914],
          [16.705988, 7.508328],
          [16.456185, 7.734774],
          [16.290562, 7.754307],
          [16.106232, 7.497088],
          [15.27946, 7.421925],
          [15.436092, 7.692812],
          [15.120866, 8.38215],
          [14.979996, 8.796104],
          [14.544467, 8.965861],
          [13.954218, 9.549495],
          [14.171466, 10.021378],
          [14.627201, 9.920919],
          [14.909354, 9.992129],
          [15.467873, 9.982337],
          [14.923565, 10.891325],
          [14.960152, 11.555574],
          [14.89336, 12.21905],
          [14.495787, 12.859396],
        ],
      ],
    },
    id: 'TD',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Togo',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [1.865241, 6.142158],
          [1.060122, 5.928837],
          [0.836931, 6.279979],
          [0.570384, 6.914359],
          [0.490957, 7.411744],
          [0.712029, 8.312465],
          [0.461192, 8.677223],
          [0.365901, 9.465004],
          [0.36758, 10.191213],
          [-0.049785, 10.706918],
          [0.023803, 11.018682],
          [0.899563, 10.997339],
          [0.772336, 10.470808],
          [1.077795, 10.175607],
          [1.425061, 9.825395],
          [1.463043, 9.334624],
          [1.664478, 9.12859],
          [1.618951, 6.832038],
          [1.865241, 6.142158],
        ],
      ],
    },
    id: 'TG',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Thailand',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [102.584932, 12.186595],
          [101.687158, 12.64574],
          [100.83181, 12.627085],
          [100.978467, 13.412722],
          [100.097797, 13.406856],
          [100.018733, 12.307001],
          [99.478921, 10.846367],
          [99.153772, 9.963061],
          [99.222399, 9.239255],
          [99.873832, 9.207862],
          [100.279647, 8.295153],
          [100.459274, 7.429573],
          [101.017328, 6.856869],
          [101.623079, 6.740622],
          [102.141187, 6.221636],
          [101.814282, 5.810808],
          [101.154219, 5.691384],
          [101.075516, 6.204867],
          [100.259596, 6.642825],
          [100.085757, 6.464489],
          [99.690691, 6.848213],
          [99.519642, 7.343454],
          [98.988253, 7.907993],
          [98.503786, 8.382305],
          [98.339662, 7.794512],
          [98.150009, 8.350007],
          [98.25915, 8.973923],
          [98.553551, 9.93296],
          [99.038121, 10.960546],
          [99.587286, 11.892763],
          [99.196354, 12.804748],
          [99.212012, 13.269294],
          [99.097755, 13.827503],
          [98.430819, 14.622028],
          [98.192074, 15.123703],
          [98.537376, 15.308497],
          [98.903348, 16.177824],
          [98.493761, 16.837836],
          [97.859123, 17.567946],
          [97.375896, 18.445438],
          [97.797783, 18.62708],
          [98.253724, 19.708203],
          [98.959676, 19.752981],
          [99.543309, 20.186598],
          [100.115988, 20.41785],
          [100.548881, 20.109238],
          [100.606294, 19.508344],
          [101.282015, 19.462585],
          [101.035931, 18.408928],
          [101.059548, 17.512497],
          [102.113592, 18.109102],
          [102.413005, 17.932782],
          [102.998706, 17.961695],
          [103.200192, 18.309632],
          [103.956477, 18.240954],
          [104.716947, 17.428859],
          [104.779321, 16.441865],
          [105.589039, 15.570316],
          [105.544338, 14.723934],
          [105.218777, 14.273212],
          [104.281418, 14.416743],
          [102.988422, 14.225721],
          [102.348099, 13.394247],
          [102.584932, 12.186595],
        ],
      ],
    },
    id: 'TH',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Tajikistan',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [71.014198, 40.244366],
          [70.648019, 39.935754],
          [69.55961, 40.103211],
          [69.464887, 39.526683],
          [70.549162, 39.604198],
          [71.784694, 39.279463],
          [73.675379, 39.431237],
          [73.928852, 38.505815],
          [74.257514, 38.606507],
          [74.864816, 38.378846],
          [74.829986, 37.990007],
          [74.980002, 37.41999],
          [73.948696, 37.421566],
          [73.260056, 37.495257],
          [72.63689, 37.047558],
          [72.193041, 36.948288],
          [71.844638, 36.738171],
          [71.448693, 37.065645],
          [71.541918, 37.905774],
          [71.239404, 37.953265],
          [71.348131, 38.258905],
          [70.806821, 38.486282],
          [70.376304, 38.138396],
          [70.270574, 37.735165],
          [70.116578, 37.588223],
          [69.518785, 37.608997],
          [69.196273, 37.151144],
          [68.859446, 37.344336],
          [68.135562, 37.023115],
          [67.83, 37.144994],
          [68.392033, 38.157025],
          [68.176025, 38.901553],
          [67.44222, 39.140144],
          [67.701429, 39.580478],
          [68.536416, 39.533453],
          [69.011633, 40.086158],
          [69.329495, 40.727824],
          [70.666622, 40.960213],
          [70.45816, 40.496495],
          [70.601407, 40.218527],
          [71.014198, 40.244366],
        ],
      ],
    },
    id: 'TJ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Turkmenistan',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [61.210817, 35.650072],
          [61.123071, 36.491597],
          [60.377638, 36.527383],
          [59.234762, 37.412988],
          [58.436154, 37.522309],
          [57.330434, 38.029229],
          [56.619366, 38.121394],
          [56.180375, 37.935127],
          [55.511578, 37.964117],
          [54.800304, 37.392421],
          [53.921598, 37.198918],
          [53.735511, 37.906136],
          [53.880929, 38.952093],
          [53.101028, 39.290574],
          [53.357808, 39.975286],
          [52.693973, 40.033629],
          [52.915251, 40.876523],
          [53.858139, 40.631034],
          [54.736845, 40.951015],
          [54.008311, 41.551211],
          [53.721713, 42.123191],
          [52.91675, 41.868117],
          [52.814689, 41.135371],
          [52.50246, 41.783316],
          [52.944293, 42.116034],
          [54.079418, 42.324109],
          [54.755345, 42.043971],
          [55.455251, 41.259859],
          [55.968191, 41.308642],
          [57.096391, 41.32231],
          [56.932215, 41.826026],
          [57.78653, 42.170553],
          [58.629011, 42.751551],
          [59.976422, 42.223082],
          [60.083341, 41.425146],
          [60.465953, 41.220327],
          [61.547179, 41.26637],
          [61.882714, 41.084857],
          [62.37426, 40.053886],
          [63.518015, 39.363257],
          [64.170223, 38.892407],
          [65.215999, 38.402695],
          [66.54615, 37.974685],
          [66.518607, 37.362784],
          [66.217385, 37.39379],
          [65.745631, 37.661164],
          [65.588948, 37.305217],
          [64.746105, 37.111818],
          [64.546479, 36.312073],
          [63.982896, 36.007957],
          [63.193538, 35.857166],
          [62.984662, 35.404041],
          [62.230651, 35.270664],
          [61.210817, 35.650072],
        ],
      ],
    },
    id: 'TM',
  },
  {
    type: 'Feature',
    properties: {
      name: 'East Timor',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [124.968682, -8.89279],
          [125.086246, -8.656887],
          [125.947072, -8.432095],
          [126.644704, -8.398247],
          [126.957243, -8.273345],
          [127.335928, -8.397317],
          [126.967992, -8.668256],
          [125.925885, -9.106007],
          [125.08852, -9.393173],
          [125.07002, -9.089987],
          [124.968682, -8.89279],
        ],
      ],
    },
    id: 'TL',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Trinidad and Tobago',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-61.68, 10.76],
          [-61.105, 10.89],
          [-60.895, 10.855],
          [-60.935, 10.11],
          [-61.77, 10],
          [-61.95, 10.09],
          [-61.66, 10.365],
          [-61.68, 10.76],
        ],
      ],
    },
    id: 'TT',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Tunisia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [9.48214, 30.307556],
          [9.055603, 32.102692],
          [8.439103, 32.506285],
          [8.430473, 32.748337],
          [7.612642, 33.344115],
          [7.524482, 34.097376],
          [8.140981, 34.655146],
          [8.376368, 35.479876],
          [8.217824, 36.433177],
          [8.420964, 36.946427],
          [9.509994, 37.349994],
          [10.210002, 37.230002],
          [10.18065, 36.724038],
          [11.028867, 37.092103],
          [11.100026, 36.899996],
          [10.600005, 36.41],
          [10.593287, 35.947444],
          [10.939519, 35.698984],
          [10.807847, 34.833507],
          [10.149593, 34.330773],
          [10.339659, 33.785742],
          [10.856836, 33.76874],
          [11.108501, 33.293343],
          [11.488787, 33.136996],
          [11.432253, 32.368903],
          [10.94479, 32.081815],
          [10.636901, 31.761421],
          [9.950225, 31.37607],
          [10.056575, 30.961831],
          [9.970017, 30.539325],
          [9.48214, 30.307556],
        ],
      ],
    },
    id: 'TN',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Turkey',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [36.913127, 41.335358],
            [38.347665, 40.948586],
            [39.512607, 41.102763],
            [40.373433, 41.013673],
            [41.554084, 41.535656],
            [42.619549, 41.583173],
            [43.582746, 41.092143],
            [43.752658, 40.740201],
            [43.656436, 40.253564],
            [44.400009, 40.005],
            [44.79399, 39.713003],
            [44.109225, 39.428136],
            [44.421403, 38.281281],
            [44.225756, 37.971584],
            [44.772699, 37.170445],
            [44.293452, 37.001514],
            [43.942259, 37.256228],
            [42.779126, 37.385264],
            [42.349591, 37.229873],
            [41.212089, 37.074352],
            [40.673259, 37.091276],
            [39.52258, 36.716054],
            [38.699891, 36.712927],
            [38.167727, 36.90121],
            [37.066761, 36.623036],
            [36.739494, 36.81752],
            [36.685389, 36.259699],
            [36.41755, 36.040617],
            [36.149763, 35.821535],
            [35.782085, 36.274995],
            [36.160822, 36.650606],
            [35.550936, 36.565443],
            [34.714553, 36.795532],
            [34.026895, 36.21996],
            [32.509158, 36.107564],
            [31.699595, 36.644275],
            [30.621625, 36.677865],
            [30.391096, 36.262981],
            [29.699976, 36.144357],
            [28.732903, 36.676831],
            [27.641187, 36.658822],
            [27.048768, 37.653361],
            [26.318218, 38.208133],
            [26.8047, 38.98576],
            [26.170785, 39.463612],
            [27.28002, 40.420014],
            [28.819978, 40.460011],
            [29.240004, 41.219991],
            [31.145934, 41.087622],
            [32.347979, 41.736264],
            [33.513283, 42.01896],
            [35.167704, 42.040225],
            [36.913127, 41.335358],
          ],
        ],
        [
          [
            [27.192377, 40.690566],
            [26.358009, 40.151994],
            [26.043351, 40.617754],
            [26.056942, 40.824123],
            [26.294602, 40.936261],
            [26.604196, 41.562115],
            [26.117042, 41.826905],
            [27.135739, 42.141485],
            [27.99672, 42.007359],
            [28.115525, 41.622886],
            [28.988443, 41.299934],
            [28.806438, 41.054962],
            [27.619017, 40.999823],
            [27.192377, 40.690566],
          ],
        ],
      ],
    },
    id: 'TR',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Taiwan',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [121.777818, 24.394274],
          [121.175632, 22.790857],
          [120.74708, 21.970571],
          [120.220083, 22.814861],
          [120.106189, 23.556263],
          [120.69468, 24.538451],
          [121.495044, 25.295459],
          [121.951244, 24.997596],
          [121.777818, 24.394274],
        ],
      ],
    },
    id: 'TW',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Tanzania',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [33.903711, -0.95],
          [34.07262, -1.05982],
          [37.69869, -3.09699],
          [37.7669, -3.67712],
          [39.20222, -4.67677],
          [38.74054, -5.90895],
          [38.79977, -6.47566],
          [39.44, -6.84],
          [39.47, -7.1],
          [39.19469, -7.7039],
          [39.25203, -8.00781],
          [39.18652, -8.48551],
          [39.53574, -9.11237],
          [39.9496, -10.0984],
          [40.31659, -10.3171],
          [39.521, -10.89688],
          [38.427557, -11.285202],
          [37.82764, -11.26879],
          [37.47129, -11.56876],
          [36.775151, -11.594537],
          [36.514082, -11.720938],
          [35.312398, -11.439146],
          [34.559989, -11.52002],
          [34.28, -10.16],
          [33.940838, -9.693674],
          [33.73972, -9.41715],
          [32.759375, -9.230599],
          [32.191865, -8.930359],
          [31.556348, -8.762049],
          [31.157751, -8.594579],
          [30.74, -8.34],
          [30.2, -7.08],
          [29.62, -6.52],
          [29.419993, -5.939999],
          [29.519987, -5.419979],
          [29.339998, -4.499983],
          [29.753512, -4.452389],
          [30.11632, -4.09012],
          [30.50554, -3.56858],
          [30.75224, -3.35931],
          [30.74301, -3.03431],
          [30.52766, -2.80762],
          [30.46967, -2.41383],
          [30.758309, -2.28725],
          [30.816135, -1.698914],
          [30.419105, -1.134659],
          [30.76986, -1.01455],
          [31.86617, -1.02736],
          [33.903711, -0.95],
        ],
      ],
    },
    id: 'TZ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Uganda',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [31.86617, -1.02736],
          [30.76986, -1.01455],
          [30.419105, -1.134659],
          [29.821519, -1.443322],
          [29.579466, -1.341313],
          [29.587838, -0.587406],
          [29.8195, -0.2053],
          [29.875779, 0.59738],
          [30.086154, 1.062313],
          [30.468508, 1.583805],
          [30.85267, 1.849396],
          [31.174149, 2.204465],
          [30.77332, 2.33989],
          [30.83385, 3.50917],
          [31.24556, 3.7819],
          [31.88145, 3.55827],
          [32.68642, 3.79232],
          [33.39, 3.79],
          [34.005, 4.249885],
          [34.47913, 3.5556],
          [34.59607, 3.05374],
          [35.03599, 1.90584],
          [34.6721, 1.17694],
          [34.18, 0.515],
          [33.893569, 0.109814],
          [33.903711, -0.95],
          [31.86617, -1.02736],
        ],
      ],
    },
    id: 'UG',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Ukraine',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [31.785998, 52.101678],
          [32.159412, 52.061267],
          [32.412058, 52.288695],
          [32.715761, 52.238465],
          [33.7527, 52.335075],
          [34.391731, 51.768882],
          [34.141978, 51.566413],
          [34.224816, 51.255993],
          [35.022183, 51.207572],
          [35.377924, 50.773955],
          [35.356116, 50.577197],
          [36.626168, 50.225591],
          [37.39346, 50.383953],
          [38.010631, 49.915662],
          [38.594988, 49.926462],
          [40.069058, 49.601055],
          [40.080789, 49.30743],
          [39.674664, 48.783818],
          [39.895632, 48.232405],
          [39.738278, 47.898937],
          [38.770585, 47.825608],
          [38.255112, 47.5464],
          [38.223538, 47.10219],
          [37.425137, 47.022221],
          [36.759855, 46.6987],
          [35.823685, 46.645964],
          [34.962342, 46.273197],
          [35.020788, 45.651219],
          [35.510009, 45.409993],
          [36.529998, 45.46999],
          [36.334713, 45.113216],
          [35.239999, 44.939996],
          [33.882511, 44.361479],
          [33.326421, 44.564877],
          [33.546924, 45.034771],
          [32.454174, 45.327466],
          [32.630804, 45.519186],
          [33.588162, 45.851569],
          [33.298567, 46.080598],
          [31.74414, 46.333348],
          [31.675307, 46.706245],
          [30.748749, 46.5831],
          [30.377609, 46.03241],
          [29.603289, 45.293308],
          [29.149725, 45.464925],
          [28.679779, 45.304031],
          [28.233554, 45.488283],
          [28.485269, 45.596907],
          [28.659987, 45.939987],
          [28.933717, 46.25883],
          [28.862972, 46.437889],
          [29.072107, 46.517678],
          [29.170654, 46.379262],
          [29.759972, 46.349988],
          [30.024659, 46.423937],
          [29.83821, 46.525326],
          [29.908852, 46.674361],
          [29.559674, 46.928583],
          [29.415135, 47.346645],
          [29.050868, 47.510227],
          [29.122698, 47.849095],
          [28.670891, 48.118149],
          [28.259547, 48.155562],
          [27.522537, 48.467119],
          [26.857824, 48.368211],
          [26.619337, 48.220726],
          [26.19745, 48.220881],
          [25.945941, 47.987149],
          [25.207743, 47.891056],
          [24.866317, 47.737526],
          [24.402056, 47.981878],
          [23.760958, 47.985598],
          [23.142236, 48.096341],
          [22.710531, 47.882194],
          [22.64082, 48.15024],
          [22.085608, 48.422264],
          [22.280842, 48.825392],
          [22.558138, 49.085738],
          [22.776419, 49.027395],
          [22.51845, 49.476774],
          [23.426508, 50.308506],
          [23.922757, 50.424881],
          [24.029986, 50.705407],
          [23.527071, 51.578454],
          [24.005078, 51.617444],
          [24.553106, 51.888461],
          [25.327788, 51.910656],
          [26.337959, 51.832289],
          [27.454066, 51.592303],
          [28.241615, 51.572227],
          [28.617613, 51.427714],
          [28.992835, 51.602044],
          [29.254938, 51.368234],
          [30.157364, 51.416138],
          [30.555117, 51.319503],
          [30.619454, 51.822806],
          [30.927549, 52.042353],
          [31.785998, 52.101678],
        ],
      ],
    },
    id: 'UA',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Uruguay',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-57.625133, -30.216295],
          [-56.976026, -30.109686],
          [-55.973245, -30.883076],
          [-55.60151, -30.853879],
          [-54.572452, -31.494511],
          [-53.787952, -32.047243],
          [-53.209589, -32.727666],
          [-53.650544, -33.202004],
          [-53.373662, -33.768378],
          [-53.806426, -34.396815],
          [-54.935866, -34.952647],
          [-55.67409, -34.752659],
          [-56.215297, -34.859836],
          [-57.139685, -34.430456],
          [-57.817861, -34.462547],
          [-58.427074, -33.909454],
          [-58.349611, -33.263189],
          [-58.132648, -33.040567],
          [-58.14244, -32.044504],
          [-57.874937, -31.016556],
          [-57.625133, -30.216295],
        ],
      ],
    },
    id: 'UY',
  },
  {
    type: 'Feature',
    properties: {
      name: 'USA',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-155.54211, 19.08348],
            [-155.68817, 18.91619],
            [-155.93665, 19.05939],
            [-155.90806, 19.33888],
            [-156.07347, 19.70294],
            [-156.02368, 19.81422],
            [-155.85008, 19.97729],
            [-155.91907, 20.17395],
            [-155.86108, 20.26721],
            [-155.78505, 20.2487],
            [-155.40214, 20.07975],
            [-155.22452, 19.99302],
            [-155.06226, 19.8591],
            [-154.80741, 19.50871],
            [-154.83147, 19.45328],
            [-155.22217, 19.23972],
            [-155.54211, 19.08348],
          ],
        ],
        [
          [
            [-156.07926, 20.64397],
            [-156.41445, 20.57241],
            [-156.58673, 20.783],
            [-156.70167, 20.8643],
            [-156.71055, 20.92676],
            [-156.61258, 21.01249],
            [-156.25711, 20.91745],
            [-155.99566, 20.76404],
            [-156.07926, 20.64397],
          ],
        ],
        [
          [
            [-156.75824, 21.17684],
            [-156.78933, 21.06873],
            [-157.32521, 21.09777],
            [-157.25027, 21.21958],
            [-156.75824, 21.17684],
          ],
        ],
        [
          [
            [-157.65283, 21.32217],
            [-157.70703, 21.26442],
            [-157.7786, 21.27729],
            [-158.12667, 21.31244],
            [-158.2538, 21.53919],
            [-158.29265, 21.57912],
            [-158.0252, 21.71696],
            [-157.94161, 21.65272],
            [-157.65283, 21.32217],
          ],
        ],
        [
          [
            [-159.34512, 21.982],
            [-159.46372, 21.88299],
            [-159.80051, 22.06533],
            [-159.74877, 22.1382],
            [-159.5962, 22.23618],
            [-159.36569, 22.21494],
            [-159.34512, 21.982],
          ],
        ],
        [
          [
            [-94.81758, 49.38905],
            [-94.64, 48.84],
            [-94.32914, 48.67074],
            [-93.63087, 48.60926],
            [-92.61, 48.45],
            [-91.64, 48.14],
            [-90.83, 48.27],
            [-89.6, 48.01],
            [-89.272917, 48.019808],
            [-88.378114, 48.302918],
            [-87.439793, 47.94],
            [-86.461991, 47.553338],
            [-85.652363, 47.220219],
            [-84.87608, 46.900083],
            [-84.779238, 46.637102],
            [-84.543749, 46.538684],
            [-84.6049, 46.4396],
            [-84.3367, 46.40877],
            [-84.14212, 46.512226],
            [-84.091851, 46.275419],
            [-83.890765, 46.116927],
            [-83.616131, 46.116927],
            [-83.469551, 45.994686],
            [-83.592851, 45.816894],
            [-82.550925, 45.347517],
            [-82.337763, 44.44],
            [-82.137642, 43.571088],
            [-82.43, 42.98],
            [-82.9, 42.43],
            [-83.12, 42.08],
            [-83.142, 41.975681],
            [-83.02981, 41.832796],
            [-82.690089, 41.675105],
            [-82.439278, 41.675105],
            [-81.277747, 42.209026],
            [-80.247448, 42.3662],
            [-78.939362, 42.863611],
            [-78.92, 42.965],
            [-79.01, 43.27],
            [-79.171674, 43.466339],
            [-78.72028, 43.625089],
            [-77.737885, 43.629056],
            [-76.820034, 43.628784],
            [-76.5, 44.018459],
            [-76.375, 44.09631],
            [-75.31821, 44.81645],
            [-74.867, 45.00048],
            [-73.34783, 45.00738],
            [-71.50506, 45.0082],
            [-71.405, 45.255],
            [-71.08482, 45.30524],
            [-70.66, 45.46],
            [-70.305, 45.915],
            [-69.99997, 46.69307],
            [-69.237216, 47.447781],
            [-68.905, 47.185],
            [-68.23444, 47.35486],
            [-67.79046, 47.06636],
            [-67.79134, 45.70281],
            [-67.13741, 45.13753],
            [-66.96466, 44.8097],
            [-68.03252, 44.3252],
            [-69.06, 43.98],
            [-70.11617, 43.68405],
            [-70.645476, 43.090238],
            [-70.81489, 42.8653],
            [-70.825, 42.335],
            [-70.495, 41.805],
            [-70.08, 41.78],
            [-70.185, 42.145],
            [-69.88497, 41.92283],
            [-69.96503, 41.63717],
            [-70.64, 41.475],
            [-71.12039, 41.49445],
            [-71.86, 41.32],
            [-72.295, 41.27],
            [-72.87643, 41.22065],
            [-73.71, 40.931102],
            [-72.24126, 41.11948],
            [-71.945, 40.93],
            [-73.345, 40.63],
            [-73.982, 40.628],
            [-73.952325, 40.75075],
            [-74.25671, 40.47351],
            [-73.96244, 40.42763],
            [-74.17838, 39.70926],
            [-74.90604, 38.93954],
            [-74.98041, 39.1964],
            [-75.20002, 39.24845],
            [-75.52805, 39.4985],
            [-75.32, 38.96],
            [-75.071835, 38.782032],
            [-75.05673, 38.40412],
            [-75.37747, 38.01551],
            [-75.94023, 37.21689],
            [-76.03127, 37.2566],
            [-75.72205, 37.93705],
            [-76.23287, 38.319215],
            [-76.35, 39.15],
            [-76.542725, 38.717615],
            [-76.32933, 38.08326],
            [-76.989998, 38.239992],
            [-76.30162, 37.917945],
            [-76.25874, 36.9664],
            [-75.9718, 36.89726],
            [-75.86804, 36.55125],
            [-75.72749, 35.55074],
            [-76.36318, 34.80854],
            [-77.397635, 34.51201],
            [-78.05496, 33.92547],
            [-78.55435, 33.86133],
            [-79.06067, 33.49395],
            [-79.20357, 33.15839],
            [-80.301325, 32.509355],
            [-80.86498, 32.0333],
            [-81.33629, 31.44049],
            [-81.49042, 30.72999],
            [-81.31371, 30.03552],
            [-80.98, 29.18],
            [-80.535585, 28.47213],
            [-80.53, 28.04],
            [-80.056539, 26.88],
            [-80.088015, 26.205765],
            [-80.13156, 25.816775],
            [-80.38103, 25.20616],
            [-80.68, 25.08],
            [-81.17213, 25.20126],
            [-81.33, 25.64],
            [-81.71, 25.87],
            [-82.24, 26.73],
            [-82.70515, 27.49504],
            [-82.85526, 27.88624],
            [-82.65, 28.55],
            [-82.93, 29.1],
            [-83.70959, 29.93656],
            [-84.1, 30.09],
            [-85.10882, 29.63615],
            [-85.28784, 29.68612],
            [-85.7731, 30.15261],
            [-86.4, 30.4],
            [-87.53036, 30.27433],
            [-88.41782, 30.3849],
            [-89.18049, 30.31598],
            [-89.593831, 30.159994],
            [-89.413735, 29.89419],
            [-89.43, 29.48864],
            [-89.21767, 29.29108],
            [-89.40823, 29.15961],
            [-89.77928, 29.30714],
            [-90.15463, 29.11743],
            [-90.880225, 29.148535],
            [-91.626785, 29.677],
            [-92.49906, 29.5523],
            [-93.22637, 29.78375],
            [-93.84842, 29.71363],
            [-94.69, 29.48],
            [-95.60026, 28.73863],
            [-96.59404, 28.30748],
            [-97.14, 27.83],
            [-97.37, 27.38],
            [-97.38, 26.69],
            [-97.33, 26.21],
            [-97.14, 25.87],
            [-97.53, 25.84],
            [-98.24, 26.06],
            [-99.02, 26.37],
            [-99.3, 26.84],
            [-99.52, 27.54],
            [-100.11, 28.11],
            [-100.45584, 28.69612],
            [-100.9576, 29.38071],
            [-101.6624, 29.7793],
            [-102.48, 29.76],
            [-103.11, 28.97],
            [-103.94, 29.27],
            [-104.45697, 29.57196],
            [-104.70575, 30.12173],
            [-105.03737, 30.64402],
            [-105.63159, 31.08383],
            [-106.1429, 31.39995],
            [-106.50759, 31.75452],
            [-108.24, 31.754854],
            [-108.24194, 31.34222],
            [-109.035, 31.34194],
            [-111.02361, 31.33472],
            [-113.30498, 32.03914],
            [-114.815, 32.52528],
            [-114.72139, 32.72083],
            [-115.99135, 32.61239],
            [-117.12776, 32.53534],
            [-117.295938, 33.046225],
            [-117.944, 33.621236],
            [-118.410602, 33.740909],
            [-118.519895, 34.027782],
            [-119.081, 34.078],
            [-119.438841, 34.348477],
            [-120.36778, 34.44711],
            [-120.62286, 34.60855],
            [-120.74433, 35.15686],
            [-121.71457, 36.16153],
            [-122.54747, 37.55176],
            [-122.51201, 37.78339],
            [-122.95319, 38.11371],
            [-123.7272, 38.95166],
            [-123.86517, 39.76699],
            [-124.39807, 40.3132],
            [-124.17886, 41.14202],
            [-124.2137, 41.99964],
            [-124.53284, 42.76599],
            [-124.14214, 43.70838],
            [-124.020535, 44.615895],
            [-123.89893, 45.52341],
            [-124.079635, 46.86475],
            [-124.39567, 47.72017],
            [-124.68721, 48.184433],
            [-124.566101, 48.379715],
            [-123.12, 48.04],
            [-122.58736, 47.096],
            [-122.34, 47.36],
            [-122.5, 48.18],
            [-122.84, 49],
            [-120, 49],
            [-117.03121, 49],
            [-116.04818, 49],
            [-113, 49],
            [-110.05, 49],
            [-107.05, 49],
            [-104.04826, 48.99986],
            [-100.65, 49],
            [-97.22872, 49.0007],
            [-95.15907, 49],
            [-95.15609, 49.38425],
            [-94.81758, 49.38905],
          ],
        ],
        [
          [
            [-153.006314, 57.115842],
            [-154.00509, 56.734677],
            [-154.516403, 56.992749],
            [-154.670993, 57.461196],
            [-153.76278, 57.816575],
            [-153.228729, 57.968968],
            [-152.564791, 57.901427],
            [-152.141147, 57.591059],
            [-153.006314, 57.115842],
          ],
        ],
        [
          [
            [-165.579164, 59.909987],
            [-166.19277, 59.754441],
            [-166.848337, 59.941406],
            [-167.455277, 60.213069],
            [-166.467792, 60.38417],
            [-165.67443, 60.293607],
            [-165.579164, 59.909987],
          ],
        ],
        [
          [
            [-171.731657, 63.782515],
            [-171.114434, 63.592191],
            [-170.491112, 63.694975],
            [-169.682505, 63.431116],
            [-168.689439, 63.297506],
            [-168.771941, 63.188598],
            [-169.52944, 62.976931],
            [-170.290556, 63.194438],
            [-170.671386, 63.375822],
            [-171.553063, 63.317789],
            [-171.791111, 63.405846],
            [-171.731657, 63.782515],
          ],
        ],
        [
          [
            [-155.06779, 71.147776],
            [-154.344165, 70.696409],
            [-153.900006, 70.889989],
            [-152.210006, 70.829992],
            [-152.270002, 70.600006],
            [-150.739992, 70.430017],
            [-149.720003, 70.53001],
            [-147.613362, 70.214035],
            [-145.68999, 70.12001],
            [-144.920011, 69.989992],
            [-143.589446, 70.152514],
            [-142.07251, 69.851938],
            [-140.985988, 69.711998],
            [-140.985988, 69.711998],
            [-140.992499, 66.000029],
            [-140.99777, 60.306397],
            [-140.012998, 60.276838],
            [-139.039, 60.000007],
            [-138.34089, 59.56211],
            [-137.4525, 58.905],
            [-136.47972, 59.46389],
            [-135.47583, 59.78778],
            [-134.945, 59.27056],
            [-134.27111, 58.86111],
            [-133.355549, 58.410285],
            [-132.73042, 57.69289],
            [-131.70781, 56.55212],
            [-130.00778, 55.91583],
            [-129.979994, 55.284998],
            [-130.53611, 54.802753],
            [-131.085818, 55.178906],
            [-131.967211, 55.497776],
            [-132.250011, 56.369996],
            [-133.539181, 57.178887],
            [-134.078063, 58.123068],
            [-135.038211, 58.187715],
            [-136.628062, 58.212209],
            [-137.800006, 58.499995],
            [-139.867787, 59.537762],
            [-140.825274, 59.727517],
            [-142.574444, 60.084447],
            [-143.958881, 59.99918],
            [-145.925557, 60.45861],
            [-147.114374, 60.884656],
            [-148.224306, 60.672989],
            [-148.018066, 59.978329],
            [-148.570823, 59.914173],
            [-149.727858, 59.705658],
            [-150.608243, 59.368211],
            [-151.716393, 59.155821],
            [-151.859433, 59.744984],
            [-151.409719, 60.725803],
            [-150.346941, 61.033588],
            [-150.621111, 61.284425],
            [-151.895839, 60.727198],
            [-152.57833, 60.061657],
            [-154.019172, 59.350279],
            [-153.287511, 58.864728],
            [-154.232492, 58.146374],
            [-155.307491, 57.727795],
            [-156.308335, 57.422774],
            [-156.556097, 56.979985],
            [-158.117217, 56.463608],
            [-158.433321, 55.994154],
            [-159.603327, 55.566686],
            [-160.28972, 55.643581],
            [-161.223048, 55.364735],
            [-162.237766, 55.024187],
            [-163.069447, 54.689737],
            [-164.785569, 54.404173],
            [-164.942226, 54.572225],
            [-163.84834, 55.039431],
            [-162.870001, 55.348043],
            [-161.804175, 55.894986],
            [-160.563605, 56.008055],
            [-160.07056, 56.418055],
            [-158.684443, 57.016675],
            [-158.461097, 57.216921],
            [-157.72277, 57.570001],
            [-157.550274, 58.328326],
            [-157.041675, 58.918885],
            [-158.194731, 58.615802],
            [-158.517218, 58.787781],
            [-159.058606, 58.424186],
            [-159.711667, 58.93139],
            [-159.981289, 58.572549],
            [-160.355271, 59.071123],
            [-161.355003, 58.670838],
            [-161.968894, 58.671665],
            [-162.054987, 59.266925],
            [-161.874171, 59.633621],
            [-162.518059, 59.989724],
            [-163.818341, 59.798056],
            [-164.662218, 60.267484],
            [-165.346388, 60.507496],
            [-165.350832, 61.073895],
            [-166.121379, 61.500019],
            [-165.734452, 62.074997],
            [-164.919179, 62.633076],
            [-164.562508, 63.146378],
            [-163.753332, 63.219449],
            [-163.067224, 63.059459],
            [-162.260555, 63.541936],
            [-161.53445, 63.455817],
            [-160.772507, 63.766108],
            [-160.958335, 64.222799],
            [-161.518068, 64.402788],
            [-160.777778, 64.788604],
            [-161.391926, 64.777235],
            [-162.45305, 64.559445],
            [-162.757786, 64.338605],
            [-163.546394, 64.55916],
            [-164.96083, 64.446945],
            [-166.425288, 64.686672],
            [-166.845004, 65.088896],
            [-168.11056, 65.669997],
            [-166.705271, 66.088318],
            [-164.47471, 66.57666],
            [-163.652512, 66.57666],
            [-163.788602, 66.077207],
            [-161.677774, 66.11612],
            [-162.489715, 66.735565],
            [-163.719717, 67.116395],
            [-164.430991, 67.616338],
            [-165.390287, 68.042772],
            [-166.764441, 68.358877],
            [-166.204707, 68.883031],
            [-164.430811, 68.915535],
            [-163.168614, 69.371115],
            [-162.930566, 69.858062],
            [-161.908897, 70.33333],
            [-160.934797, 70.44769],
            [-159.039176, 70.891642],
            [-158.119723, 70.824721],
            [-156.580825, 71.357764],
            [-155.06779, 71.147776],
          ],
        ],
      ],
    },
    id: 'US',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Uzbekistan',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [66.518607, 37.362784],
          [66.54615, 37.974685],
          [65.215999, 38.402695],
          [64.170223, 38.892407],
          [63.518015, 39.363257],
          [62.37426, 40.053886],
          [61.882714, 41.084857],
          [61.547179, 41.26637],
          [60.465953, 41.220327],
          [60.083341, 41.425146],
          [59.976422, 42.223082],
          [58.629011, 42.751551],
          [57.78653, 42.170553],
          [56.932215, 41.826026],
          [57.096391, 41.32231],
          [55.968191, 41.308642],
          [55.928917, 44.995858],
          [58.503127, 45.586804],
          [58.689989, 45.500014],
          [60.239972, 44.784037],
          [61.05832, 44.405817],
          [62.0133, 43.504477],
          [63.185787, 43.650075],
          [64.900824, 43.728081],
          [66.098012, 42.99766],
          [66.023392, 41.994646],
          [66.510649, 41.987644],
          [66.714047, 41.168444],
          [67.985856, 41.135991],
          [68.259896, 40.662325],
          [68.632483, 40.668681],
          [69.070027, 41.384244],
          [70.388965, 42.081308],
          [70.962315, 42.266154],
          [71.259248, 42.167711],
          [70.420022, 41.519998],
          [71.157859, 41.143587],
          [71.870115, 41.3929],
          [73.055417, 40.866033],
          [71.774875, 40.145844],
          [71.014198, 40.244366],
          [70.601407, 40.218527],
          [70.45816, 40.496495],
          [70.666622, 40.960213],
          [69.329495, 40.727824],
          [69.011633, 40.086158],
          [68.536416, 39.533453],
          [67.701429, 39.580478],
          [67.44222, 39.140144],
          [68.176025, 38.901553],
          [68.392033, 38.157025],
          [67.83, 37.144994],
          [67.075782, 37.356144],
          [66.518607, 37.362784],
        ],
      ],
    },
    id: 'UZ',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Venezuela',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-71.331584, 11.776284],
          [-71.360006, 11.539994],
          [-71.94705, 11.423282],
          [-71.620868, 10.96946],
          [-71.633064, 10.446494],
          [-72.074174, 9.865651],
          [-71.695644, 9.072263],
          [-71.264559, 9.137195],
          [-71.039999, 9.859993],
          [-71.350084, 10.211935],
          [-71.400623, 10.968969],
          [-70.155299, 11.375482],
          [-70.293843, 11.846822],
          [-69.943245, 12.162307],
          [-69.5843, 11.459611],
          [-68.882999, 11.443385],
          [-68.233271, 10.885744],
          [-68.194127, 10.554653],
          [-67.296249, 10.545868],
          [-66.227864, 10.648627],
          [-65.655238, 10.200799],
          [-64.890452, 10.077215],
          [-64.329479, 10.389599],
          [-64.318007, 10.641418],
          [-63.079322, 10.701724],
          [-61.880946, 10.715625],
          [-62.730119, 10.420269],
          [-62.388512, 9.948204],
          [-61.588767, 9.873067],
          [-60.830597, 9.38134],
          [-60.671252, 8.580174],
          [-60.150096, 8.602757],
          [-59.758285, 8.367035],
          [-60.550588, 7.779603],
          [-60.637973, 7.415],
          [-60.295668, 7.043911],
          [-60.543999, 6.856584],
          [-61.159336, 6.696077],
          [-61.139415, 6.234297],
          [-61.410303, 5.959068],
          [-60.733574, 5.200277],
          [-60.601179, 4.918098],
          [-60.966893, 4.536468],
          [-62.08543, 4.162124],
          [-62.804533, 4.006965],
          [-63.093198, 3.770571],
          [-63.888343, 4.02053],
          [-64.628659, 4.148481],
          [-64.816064, 4.056445],
          [-64.368494, 3.79721],
          [-64.408828, 3.126786],
          [-64.269999, 2.497006],
          [-63.422867, 2.411068],
          [-63.368788, 2.2009],
          [-64.083085, 1.916369],
          [-64.199306, 1.492855],
          [-64.611012, 1.328731],
          [-65.354713, 1.095282],
          [-65.548267, 0.789254],
          [-66.325765, 0.724452],
          [-66.876326, 1.253361],
          [-67.181294, 2.250638],
          [-67.447092, 2.600281],
          [-67.809938, 2.820655],
          [-67.303173, 3.318454],
          [-67.337564, 3.542342],
          [-67.621836, 3.839482],
          [-67.823012, 4.503937],
          [-67.744697, 5.221129],
          [-67.521532, 5.55687],
          [-67.34144, 6.095468],
          [-67.695087, 6.267318],
          [-68.265052, 6.153268],
          [-68.985319, 6.206805],
          [-69.38948, 6.099861],
          [-70.093313, 6.960376],
          [-70.674234, 7.087785],
          [-71.960176, 6.991615],
          [-72.198352, 7.340431],
          [-72.444487, 7.423785],
          [-72.479679, 7.632506],
          [-72.360901, 8.002638],
          [-72.439862, 8.405275],
          [-72.660495, 8.625288],
          [-72.78873, 9.085027],
          [-73.304952, 9.152],
          [-73.027604, 9.73677],
          [-72.905286, 10.450344],
          [-72.614658, 10.821975],
          [-72.227575, 11.108702],
          [-71.973922, 11.608672],
          [-71.331584, 11.776284],
        ],
      ],
    },
    id: 'VE',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Vietnam',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [108.05018, 21.55238],
          [106.715068, 20.696851],
          [105.881682, 19.75205],
          [105.662006, 19.058165],
          [106.426817, 18.004121],
          [107.361954, 16.697457],
          [108.269495, 16.079742],
          [108.877107, 15.276691],
          [109.33527, 13.426028],
          [109.200136, 11.666859],
          [108.36613, 11.008321],
          [107.220929, 10.364484],
          [106.405113, 9.53084],
          [105.158264, 8.59976],
          [104.795185, 9.241038],
          [105.076202, 9.918491],
          [104.334335, 10.486544],
          [105.199915, 10.88931],
          [106.24967, 10.961812],
          [105.810524, 11.567615],
          [107.491403, 12.337206],
          [107.614548, 13.535531],
          [107.382727, 14.202441],
          [107.564525, 15.202173],
          [107.312706, 15.908538],
          [106.556008, 16.604284],
          [105.925762, 17.485315],
          [105.094598, 18.666975],
          [103.896532, 19.265181],
          [104.183388, 19.624668],
          [104.822574, 19.886642],
          [104.435, 20.758733],
          [103.203861, 20.766562],
          [102.754896, 21.675137],
          [102.170436, 22.464753],
          [102.706992, 22.708795],
          [103.504515, 22.703757],
          [104.476858, 22.81915],
          [105.329209, 23.352063],
          [105.811247, 22.976892],
          [106.725403, 22.794268],
          [106.567273, 22.218205],
          [107.04342, 21.811899],
          [108.05018, 21.55238],
        ],
      ],
    },
    id: 'VN',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Vanuatu',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [167.844877, -16.466333],
            [167.515181, -16.59785],
            [167.180008, -16.159995],
            [167.216801, -15.891846],
            [167.844877, -16.466333],
          ],
        ],
        [
          [
            [167.107712, -14.93392],
            [167.270028, -15.740021],
            [167.001207, -15.614602],
            [166.793158, -15.668811],
            [166.649859, -15.392704],
            [166.629137, -14.626497],
            [167.107712, -14.93392],
          ],
        ],
      ],
    },
    id: 'VU',
  },
  {
    type: 'Feature',
    properties: {
      name: 'West Bank',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [35.545665, 32.393992],
          [35.545252, 31.782505],
          [35.397561, 31.489086],
          [34.927408, 31.353435],
          [34.970507, 31.616778],
          [35.225892, 31.754341],
          [34.974641, 31.866582],
          [35.18393, 32.532511],
          [35.545665, 32.393992],
        ],
      ],
    },
    id: 'PS',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Yemen',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [53.108573, 16.651051],
          [52.385206, 16.382411],
          [52.191729, 15.938433],
          [52.168165, 15.59742],
          [51.172515, 15.17525],
          [49.574576, 14.708767],
          [48.679231, 14.003202],
          [48.238947, 13.94809],
          [47.938914, 14.007233],
          [47.354454, 13.59222],
          [46.717076, 13.399699],
          [45.877593, 13.347764],
          [45.62505, 13.290946],
          [45.406459, 13.026905],
          [45.144356, 12.953938],
          [44.989533, 12.699587],
          [44.494576, 12.721653],
          [44.175113, 12.58595],
          [43.482959, 12.6368],
          [43.222871, 13.22095],
          [43.251448, 13.767584],
          [43.087944, 14.06263],
          [42.892245, 14.802249],
          [42.604873, 15.213335],
          [42.805015, 15.261963],
          [42.702438, 15.718886],
          [42.823671, 15.911742],
          [42.779332, 16.347891],
          [43.218375, 16.66689],
          [43.115798, 17.08844],
          [43.380794, 17.579987],
          [43.791519, 17.319977],
          [44.062613, 17.410359],
          [45.216651, 17.433329],
          [45.399999, 17.333335],
          [46.366659, 17.233315],
          [46.749994, 17.283338],
          [47.000005, 16.949999],
          [47.466695, 17.116682],
          [48.183344, 18.166669],
          [49.116672, 18.616668],
          [52.00001, 19.000003],
          [52.782184, 17.349742],
          [53.108573, 16.651051],
        ],
      ],
    },
    id: 'YE',
  },
  {
    type: 'Feature',
    properties: {
      name: 'South Africa',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [31.521001, -29.257387],
          [31.325561, -29.401978],
          [30.901763, -29.909957],
          [30.622813, -30.423776],
          [30.055716, -31.140269],
          [28.925553, -32.172041],
          [28.219756, -32.771953],
          [27.464608, -33.226964],
          [26.419452, -33.61495],
          [25.909664, -33.66704],
          [25.780628, -33.944646],
          [25.172862, -33.796851],
          [24.677853, -33.987176],
          [23.594043, -33.794474],
          [22.988189, -33.916431],
          [22.574157, -33.864083],
          [21.542799, -34.258839],
          [20.689053, -34.417175],
          [20.071261, -34.795137],
          [19.616405, -34.819166],
          [19.193278, -34.462599],
          [18.855315, -34.444306],
          [18.424643, -33.997873],
          [18.377411, -34.136521],
          [18.244499, -33.867752],
          [18.25008, -33.281431],
          [17.92519, -32.611291],
          [18.24791, -32.429131],
          [18.221762, -31.661633],
          [17.566918, -30.725721],
          [17.064416, -29.878641],
          [17.062918, -29.875954],
          [16.344977, -28.576705],
          [16.824017, -28.082162],
          [17.218929, -28.355943],
          [17.387497, -28.783514],
          [17.836152, -28.856378],
          [18.464899, -29.045462],
          [19.002127, -28.972443],
          [19.894734, -28.461105],
          [19.895768, -24.76779],
          [20.165726, -24.917962],
          [20.758609, -25.868136],
          [20.66647, -26.477453],
          [20.889609, -26.828543],
          [21.605896, -26.726534],
          [22.105969, -26.280256],
          [22.579532, -25.979448],
          [22.824271, -25.500459],
          [23.312097, -25.26869],
          [23.73357, -25.390129],
          [24.211267, -25.670216],
          [25.025171, -25.71967],
          [25.664666, -25.486816],
          [25.765849, -25.174845],
          [25.941652, -24.696373],
          [26.485753, -24.616327],
          [26.786407, -24.240691],
          [27.11941, -23.574323],
          [28.017236, -22.827754],
          [29.432188, -22.091313],
          [29.839037, -22.102216],
          [30.322883, -22.271612],
          [30.659865, -22.151567],
          [31.191409, -22.25151],
          [31.670398, -23.658969],
          [31.930589, -24.369417],
          [31.752408, -25.484284],
          [31.837778, -25.843332],
          [31.333158, -25.660191],
          [31.04408, -25.731452],
          [30.949667, -26.022649],
          [30.676609, -26.398078],
          [30.685962, -26.743845],
          [31.282773, -27.285879],
          [31.86806, -27.177927],
          [32.071665, -26.73382],
          [32.83012, -26.742192],
          [32.580265, -27.470158],
          [32.462133, -28.301011],
          [32.203389, -28.752405],
          [31.521001, -29.257387],
        ],
        [
          [28.978263, -28.955597],
          [28.5417, -28.647502],
          [28.074338, -28.851469],
          [27.532511, -29.242711],
          [26.999262, -29.875954],
          [27.749397, -30.645106],
          [28.107205, -30.545732],
          [28.291069, -30.226217],
          [28.8484, -30.070051],
          [29.018415, -29.743766],
          [29.325166, -29.257387],
          [28.978263, -28.955597],
        ],
      ],
    },
    id: 'ZA',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Zambia',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [32.759375, -9.230599],
          [33.231388, -9.676722],
          [33.485688, -10.525559],
          [33.31531, -10.79655],
          [33.114289, -11.607198],
          [33.306422, -12.435778],
          [32.991764, -12.783871],
          [32.688165, -13.712858],
          [33.214025, -13.97186],
          [30.179481, -14.796099],
          [30.274256, -15.507787],
          [29.516834, -15.644678],
          [28.947463, -16.043051],
          [28.825869, -16.389749],
          [28.467906, -16.4684],
          [27.598243, -17.290831],
          [27.044427, -17.938026],
          [26.706773, -17.961229],
          [26.381935, -17.846042],
          [25.264226, -17.73654],
          [25.084443, -17.661816],
          [25.07695, -17.578823],
          [24.682349, -17.353411],
          [24.033862, -17.295843],
          [23.215048, -17.523116],
          [22.562478, -16.898451],
          [21.887843, -16.08031],
          [21.933886, -12.898437],
          [24.016137, -12.911046],
          [23.930922, -12.565848],
          [24.079905, -12.191297],
          [23.904154, -11.722282],
          [24.017894, -11.237298],
          [23.912215, -10.926826],
          [24.257155, -10.951993],
          [24.314516, -11.262826],
          [24.78317, -11.238694],
          [25.418118, -11.330936],
          [25.75231, -11.784965],
          [26.553088, -11.92444],
          [27.16442, -11.608748],
          [27.388799, -12.132747],
          [28.155109, -12.272481],
          [28.523562, -12.698604],
          [28.934286, -13.248958],
          [29.699614, -13.257227],
          [29.616001, -12.178895],
          [29.341548, -12.360744],
          [28.642417, -11.971569],
          [28.372253, -11.793647],
          [28.49607, -10.789884],
          [28.673682, -9.605925],
          [28.449871, -9.164918],
          [28.734867, -8.526559],
          [29.002912, -8.407032],
          [30.346086, -8.238257],
          [30.740015, -8.340007],
          [31.157751, -8.594579],
          [31.556348, -8.762049],
          [32.191865, -8.930359],
          [32.759375, -9.230599],
        ],
      ],
    },
    id: 'ZM',
  },
  {
    type: 'Feature',
    properties: {
      name: 'Zimbabwe',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [31.191409, -22.25151],
          [30.659865, -22.151567],
          [30.322883, -22.271612],
          [29.839037, -22.102216],
          [29.432188, -22.091313],
          [28.794656, -21.639454],
          [28.02137, -21.485975],
          [27.727228, -20.851802],
          [27.724747, -20.499059],
          [27.296505, -20.39152],
          [26.164791, -19.293086],
          [25.850391, -18.714413],
          [25.649163, -18.536026],
          [25.264226, -17.73654],
          [26.381935, -17.846042],
          [26.706773, -17.961229],
          [27.044427, -17.938026],
          [27.598243, -17.290831],
          [28.467906, -16.4684],
          [28.825869, -16.389749],
          [28.947463, -16.043051],
          [29.516834, -15.644678],
          [30.274256, -15.507787],
          [30.338955, -15.880839],
          [31.173064, -15.860944],
          [31.636498, -16.07199],
          [31.852041, -16.319417],
          [32.328239, -16.392074],
          [32.847639, -16.713398],
          [32.849861, -17.979057],
          [32.654886, -18.67209],
          [32.611994, -19.419383],
          [32.772708, -19.715592],
          [32.659743, -20.30429],
          [32.508693, -20.395292],
          [32.244988, -21.116489],
          [31.191409, -22.25151],
        ],
      ],
    },
    id: 'ZW',
  },

  {
    type: 'Feature',
    properties: { name: 'South Korea' },
    id: 'KR',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [126.82336637100016, 33.559439826000116],
            [126.83521569100006, 33.544582424000126],
            [126.84994550900004, 33.534979559000149],
            [126.89820397200006, 33.51898834800015],
            [126.91285241000017, 33.506577867000132],
            [126.9226994150001, 33.476629950000145],
            [126.93238366000006, 33.464911200000145],
            [126.93702233200005, 33.469468492000104],
            [126.94336998800011, 33.473822333],
            [126.94605553500017, 33.478013414000131],
            [126.95362389400012, 33.46503327000012],
            [126.94898522200018, 33.460598049000069],
            [126.93930097700004, 33.458238023000106],
            [126.93238366000006, 33.451320705000015],
            [126.93287194100006, 33.445502020000063],
            [126.93921959700006, 33.436265367000189],
            [126.93921959700006, 33.430853583000143],
            [126.93580162900005, 33.425482489],
            [126.92725670700023, 33.416083075000145],
            [126.925547722, 33.413153387000037],
            [126.90750666100004, 33.39572509800017],
            [126.87353004000008, 33.373045514000111],
            [126.86299236000005, 33.354141206000023],
            [126.84795550900006, 33.332065315000094],
            [126.84723913600007, 33.323217852000184],
            [126.84058678500017, 33.310207424000069],
            [126.81592858200011, 33.307806708000115],
            [126.80640709700018, 33.303615627000099],
            [126.78014100500005, 33.305550290000113],
            [126.74179256500005, 33.280208472000041],
            [126.64072332900005, 33.264666184000149],
            [126.60691886200019, 33.240610458],
            [126.56552749000016, 33.232907836000138],
            [126.52120889500006, 33.236617790000068],
            [126.47056220500005, 33.228713895000126],
            [126.42845775900005, 33.239309302],
            [126.33342729600022, 33.238169044000145],
            [126.3037902140002, 33.226559374000132],
            [126.28614342500012, 33.200384833000086],
            [126.27003014400012, 33.197577216000028],
            [126.25391686300023, 33.204046942000119],
            [126.23115058600004, 33.234922996000151],
            [126.20082394300007, 33.243552024000124],
            [126.17139733200023, 33.269354559000121],
            [126.16114342500012, 33.300767320000048],
            [126.17896569100012, 33.341457424000126],
            [126.19581139400012, 33.366685289000188],
            [126.20622806100019, 33.375555731000063],
            [126.24097741000006, 33.392767645000063],
            [126.27066067500007, 33.43587354099999],
            [126.29794924799998, 33.441714895000146],
            [126.31056732700009, 33.46447646100016],
            [126.35736028600016, 33.477960841000041],
            [126.45938010300009, 33.502380557000109],
            [126.48493458800024, 33.520757551000159],
            [126.58243391300002, 33.52928356800011],
            [126.64879765800001, 33.553426278000089],
            [126.66469154300015, 33.549675810000011],
            [126.74550521600023, 33.560556374000114],
            [126.79236033000021, 33.563796905000103],
            [126.82336637100016, 33.559439826000116],
          ],
        ],
        [
          [
            [127.29566491000017, 34.061468817],
            [127.29810631600012, 34.057196356000091],
            [127.29468834700018, 34.047919012000037],
            [127.29639733200011, 34.037583726000136],
            [127.30909264400023, 34.020086981000176],
            [127.31495201900012, 34.015773830000072],
            [127.32211347700016, 34.014105536000059],
            [127.32471764400023, 34.010687567000062],
            [127.32349694100006, 34.005764065],
            [127.309336785, 34.011460679],
            [127.29566491000017, 34.014105536000059],
            [127.29322350400005, 34.017238674000126],
            [127.28980553500006, 34.02407461100016],
            [127.28760826900023, 34.032416083000115],
            [127.28386478000019, 34.034613348000121],
            [127.28077233200011, 34.037787177],
            [127.27833092500006, 34.044623114],
            [127.27979576900023, 34.048651434000178],
            [127.27930748800023, 34.053290106000091],
            [127.28003991000017, 34.057440497000059],
            [127.28321373800023, 34.058294989000146],
            [127.28687584700012, 34.057277736000074],
            [127.29151451900023, 34.05849844],
            [127.294200066, 34.061102606000091],
            [127.29566491000017, 34.061468817],
          ],
        ],
        [
          [
            [125.13738040500007, 34.051336981000148],
            [125.13428795700017, 34.04686107],
            [125.12989342500006, 34.047552802000112],
            [125.12769616000017, 34.048570054],
            [125.122813347, 34.051174221000096],
            [125.10328209700006, 34.066066799000126],
            [125.09213300900004, 34.071763414000131],
            [125.0896916020001, 34.079535223000121],
            [125.09457441500015, 34.09007396],
            [125.10222415500019, 34.09593333500014],
            [125.11329186300011, 34.092840887000065],
            [125.12208092500006, 34.083807684000035],
            [125.13640384200008, 34.059271552000112],
            [125.13738040500007, 34.051336981000148],
          ],
        ],
        [
          [
            [126.56788170700011, 34.168646552000027],
            [126.57374108200023, 34.168036200000145],
            [126.58204186300011, 34.170152085000055],
            [126.59327233200011, 34.169989325000088],
            [126.59896894599999, 34.168646552000027],
            [126.60767662900017, 34.165594794000114],
            [126.61597741000017, 34.156968492000104],
            [126.61353600400005, 34.15253327000012],
            [126.60938561300011, 34.155666408000016],
            [126.60580488400004, 34.15615469],
            [126.60401451900023, 34.15224844],
            [126.60027103000019, 34.153469143000038],
            [126.59115644600004, 34.157619533000158],
            [126.58179772200018, 34.157416083],
            [126.576426629, 34.153062242000104],
            [126.57300866, 34.14769114800005],
            [126.56185957099999, 34.141424872000115],
            [126.55193118600019, 34.133978583000115],
            [126.53687584700018, 34.126450914000159],
            [126.51775149800019, 34.127142645],
            [126.50709069100017, 34.138861395],
            [126.50635826900023, 34.153998114],
            [126.51514733200011, 34.168850002000156],
            [126.53288821700019, 34.179103908000073],
            [126.54810631600017, 34.179103908000073],
            [126.56788170700011, 34.168646552000027],
          ],
        ],
        [
          [
            [126.66537519600004, 34.119086005000085],
            [126.655528191, 34.116848049000097],
            [126.65040123800011, 34.119370835000112],
            [126.64861087300019, 34.124009507000139],
            [126.643809441, 34.127183335],
            [126.63868248800011, 34.128119208000086],
            [126.636485222, 34.14154694200009],
            [126.64283287900005, 34.164618231000034],
            [126.64503014400023, 34.177476304000052],
            [126.63843834700018, 34.18256256700009],
            [126.636485222, 34.187160549000041],
            [126.64478600400011, 34.193752346000096],
            [126.64527428500017, 34.197170315],
            [126.65381920700011, 34.197088934000121],
            [126.67074629, 34.189886786],
            [126.67310631600006, 34.180121161000145],
            [126.66358483200023, 34.173325914000131],
            [126.66187584700006, 34.165716864000089],
            [126.67204837300019, 34.153265692000062],
            [126.67603600400017, 34.151434637000179],
            [126.67945397200018, 34.146551825000117],
            [126.67628014400012, 34.14354075700011],
            [126.67188561300023, 34.142971096000153],
            [126.67042076900017, 34.137030341000028],
            [126.67042076900017, 34.126939195000048],
            [126.66537519600004, 34.119086005000085],
          ],
        ],
        [
          [
            [126.91993248800011, 34.166978257],
            [126.91358483200011, 34.15766022300015],
            [126.90186608200011, 34.152411200000145],
            [126.88697350400005, 34.15135325700011],
            [126.87598717500006, 34.156073309000121],
            [126.86695397200018, 34.162827867000047],
            [126.86036217500012, 34.162176825000088],
            [126.85694420700011, 34.157171942000062],
            [126.85320071700008, 34.15900299700003],
            [126.84913170700011, 34.174383856000176],
            [126.85531660200016, 34.184759833000086],
            [126.85694420700011, 34.194281317000062],
            [126.86085045700011, 34.201849677000112],
            [126.86793053500006, 34.207464911000116],
            [126.88217207100016, 34.213283596000068],
            [126.89926191500004, 34.209662177000112],
            [126.90935306100008, 34.197699286000088],
            [126.91895592500023, 34.190497137000065],
            [126.92497806100008, 34.184881903000147],
            [126.92237389400023, 34.172349351000051],
            [126.91993248800011, 34.166978257],
          ],
        ],
        [
          [
            [126.58887780000023, 34.179103908000073],
            [126.58383222699999, 34.175604559000092],
            [126.56861412900005, 34.17902252800009],
            [126.56072024800008, 34.185126044000086],
            [126.55876712300019, 34.189154364000061],
            [126.5545353520001, 34.190415757000082],
            [126.54981530000006, 34.191229559000178],
            [126.54883873800011, 34.194566148000106],
            [126.553965691, 34.206529039000046],
            [126.55689537900005, 34.211004950000088],
            [126.55730228000007, 34.214056708],
            [126.55534915500019, 34.216457424000126],
            [126.55616295700011, 34.218654690000122],
            [126.55982506600006, 34.220160223000093],
            [126.56218509200019, 34.222316799000097],
            [126.56153405000023, 34.225165106000034],
            [126.55640709700006, 34.232123114000117],
            [126.55974368600008, 34.234523830000157],
            [126.56763756600006, 34.232407945000048],
            [126.57536868600008, 34.228949286000145],
            [126.59620201900023, 34.226019598000121],
            [126.60694420700023, 34.221380927],
            [126.61215254000015, 34.21816640800013],
            [126.61622155000012, 34.213283596000068],
            [126.61703535200004, 34.204535223],
            [126.610118035, 34.199693101000108],
            [126.60661868600002, 34.188299872000144],
            [126.592051629, 34.187648830000015],
            [126.58399498800017, 34.186265367000132],
            [126.58887780000023, 34.179103908000073],
          ],
        ],
        [
          [
            [126.03321373800023, 34.314886786000059],
            [126.06763756600017, 34.308905341000141],
            [126.08204186300023, 34.31073639500012],
            [126.08871504, 34.307928778000175],
            [126.08773847700004, 34.296820380000142],
            [126.08936608200023, 34.289618231000119],
            [126.08464603000002, 34.283880927000141],
            [126.07496178500017, 34.282863674000069],
            [126.06934655000018, 34.278998114000061],
            [126.06495201900006, 34.280707098000065],
            [126.06080162900005, 34.286078192000119],
            [126.05738366000006, 34.285956122000144],
            [126.05567467500023, 34.280991929000109],
            [126.04932701900012, 34.28021881700009],
            [126.0286564460001, 34.28733958500014],
            [126.02165774800019, 34.295640367000104],
            [126.02100670700023, 34.301092841000141],
            [126.02523847700016, 34.305853583000058],
            [126.02572675900015, 34.310777085000112],
            [126.03321373800023, 34.314886786000059],
          ],
        ],
        [
          [
            [126.84351647200012, 34.350043036000116],
            [126.85075931100002, 34.348456122000087],
            [126.85499108200023, 34.348700262000037],
            [126.85922285200016, 34.349351304],
            [126.87037194100017, 34.348374742000104],
            [126.884532097, 34.345526434000149],
            [126.89332116000017, 34.341253973000065],
            [126.89136803500006, 34.333929755000057],
            [126.88559004000015, 34.328314520000148],
            [126.88404381600006, 34.324896552000141],
            [126.88306725400005, 34.317938544000143],
            [126.87680097699999, 34.312241929000081],
            [126.86817467500012, 34.313625393000152],
            [126.85889733200023, 34.312201239000089],
            [126.85385175900004, 34.308539130000113],
            [126.84961998800011, 34.304917710000083],
            [126.84058678500017, 34.303371486000046],
            [126.835703972, 34.309149481000091],
            [126.83366946700019, 34.316839911000031],
            [126.82829837300014, 34.322902736000017],
            [126.820567254, 34.325628973000065],
            [126.81031334700018, 34.324367580000072],
            [126.79835045700017, 34.320868231000176],
            [126.78874759200008, 34.32094961100016],
            [126.78492272200018, 34.322577216000084],
            [126.78109785200016, 34.324652411],
            [126.75896243600008, 34.332749742000132],
            [126.75757897200018, 34.336127020000148],
            [126.76783287900005, 34.33722565300009],
            [126.77784264400012, 34.339667059000035],
            [126.78223717500006, 34.341782945000134],
            [126.78589928500006, 34.343247789000188],
            [126.79379316500004, 34.342230536000116],
            [126.80290774800019, 34.338690497000144],
            [126.81397545700023, 34.342515367000161],
            [126.82748457100004, 34.351629950000145],
            [126.83765709700006, 34.353745835000083],
            [126.84351647200012, 34.350043036000116],
          ],
        ],
        [
          [
            [127.04590905000018, 34.357652085000055],
            [127.05372155000023, 34.354803778000033],
            [127.06739342500012, 34.358384507],
            [127.07740319100017, 34.359320380000085],
            [127.08204186300011, 34.355943101000051],
            [127.08545983200011, 34.351385809000121],
            [127.09156334700012, 34.344916083000115],
            [127.094981316, 34.342474677000084],
            [127.09693444100006, 34.337795315000065],
            [127.09083092500018, 34.336737372000115],
            [127.08236738400004, 34.340277411000088],
            [127.07243899800014, 34.342108466000141],
            [127.06527754, 34.33978913],
            [127.06788170700005, 34.337062893000123],
            [127.07398522200018, 34.334173895],
            [127.06674238400015, 34.328192450000174],
            [127.04908287900005, 34.316717841000056],
            [127.03882897200012, 34.312892971000124],
            [127.03785241000006, 34.321722723000093],
            [127.0428166020001, 34.327948309000035],
            [127.04916425900004, 34.33144765800013],
            [127.04558353000007, 34.335435289000102],
            [127.023692254, 34.339178778000033],
            [127.02662194100006, 34.343166408000016],
            [127.0242619150001, 34.348211981000148],
            [127.01758873800011, 34.35154857],
            [127.00082441499998, 34.352850653000175],
            [126.99431399800019, 34.354681708000058],
            [126.99146569100017, 34.356268622000087],
            [126.99382571700014, 34.358140367000047],
            [127.001231316, 34.359361070000077],
            [127.00700931100008, 34.362616278000118],
            [127.01628665500002, 34.371893622000172],
            [127.02621504000004, 34.378729559000178],
            [127.02540123800011, 34.38133372599999],
            [127.02857506600017, 34.380438544000086],
            [127.03565514400023, 34.374741929000109],
            [127.04216556100002, 34.366685289000159],
            [127.04590905000018, 34.357652085000055],
          ],
        ],
        [
          [
            [126.74561608200017, 34.324204820000105],
            [126.76392662900005, 34.309230861000074],
            [126.76587975400017, 34.303045966000113],
            [126.77149498800006, 34.29189687700007],
            [126.76807701900017, 34.289211330000015],
            [126.76319420700011, 34.289780992000161],
            [126.75831139400012, 34.290920315],
            [126.74935957099999, 34.294134833000058],
            [126.74170983200005, 34.298244533000016],
            [126.73747806100008, 34.297552802000084],
            [126.73650149800008, 34.291693427000112],
            [126.73170006600017, 34.28937409100017],
            [126.722911004, 34.292710679],
            [126.707774285, 34.295640367000104],
            [126.68897545700005, 34.301092841000141],
            [126.68881269600004, 34.307562567000062],
            [126.69809004000015, 34.313421942],
            [126.69564863400004, 34.32078685099999],
            [126.68702233200023, 34.322699286000059],
            [126.67660566500004, 34.320461330000072],
            [126.66358483200023, 34.323431708000086],
            [126.65186608200017, 34.329779364000117],
            [126.64486738400009, 34.337103583000115],
            [126.64193769600016, 34.347967841000113],
            [126.64096113399998, 34.360337632000139],
            [126.64177493600019, 34.372951565000122],
            [126.64576256600017, 34.385565497000115],
            [126.65365644600016, 34.395209052000084],
            [126.66439863400015, 34.399481512000179],
            [126.67017662900005, 34.40058014500012],
            [126.68775475400005, 34.402044989],
            [126.69922936300011, 34.398911851000108],
            [126.70997155000012, 34.392889716000113],
            [126.72185306100008, 34.381293036],
            [126.73113040500013, 34.362005927000055],
            [126.73389733200005, 34.346177476000108],
            [126.73707116000006, 34.33885325700011],
            [126.74252363400009, 34.332749742000132],
            [126.74561608200017, 34.324204820000105],
          ],
        ],
        [
          [
            [126.86019941500015, 34.398871161000116],
            [126.86109459700006, 34.39276764500012],
            [126.85377037900005, 34.391750393000066],
            [126.83326256600017, 34.396958726000079],
            [126.82471764400023, 34.395209052000084],
            [126.81967207100004, 34.389064846000096],
            [126.79330488400004, 34.378892320000048],
            [126.78402754000015, 34.368597723000121],
            [126.78109785200016, 34.357855536000116],
            [126.77572675899998, 34.35472239800005],
            [126.76921634200019, 34.360988674000097],
            [126.76937910200016, 34.37132396],
            [126.76726321700008, 34.380031643000095],
            [126.76246178500017, 34.382961330000015],
            [126.75757897200018, 34.383246161000145],
            [126.753184441, 34.38694896],
            [126.75684655000023, 34.392889716000113],
            [126.76148522200018, 34.398749091000141],
            [126.76840254, 34.411363023000135],
            [126.78248131600006, 34.426743882000082],
            [126.80225670700023, 34.435777085],
            [126.82300866000006, 34.439846096000124],
            [126.83545983200005, 34.438055731000148],
            [126.84017988400009, 34.432074286000145],
            [126.84099368600019, 34.425482489000061],
            [126.83578535199999, 34.421047268000095],
            [126.83659915500019, 34.418646552000055],
            [126.84587649800008, 34.413967190000122],
            [126.85352623800011, 34.406887111000074],
            [126.86019941500015, 34.398871161000116],
          ],
        ],
        [
          [
            [127.48682701900023, 34.463853257],
            [127.48707116000017, 34.461249091000084],
            [127.49170983200011, 34.462225653000147],
            [127.500743035, 34.469631252000156],
            [127.50993899800008, 34.470038153000147],
            [127.53191165500019, 34.450588283000158],
            [127.53638756600006, 34.44065989800005],
            [127.53638756600006, 34.434759833000115],
            [127.52881920700011, 34.42804596600017],
            [127.51059004000004, 34.418280341000028],
            [127.49463951900012, 34.414252020000063],
            [127.48894290500002, 34.416937567000147],
            [127.48853600400005, 34.425482489000061],
            [127.48178144599999, 34.431341864000032],
            [127.47169030000023, 34.434027411],
            [127.467051629, 34.439195054],
            [127.46973717500006, 34.443182684000149],
            [127.45883222699999, 34.448919989000146],
            [127.45215905000023, 34.453029690000093],
            [127.45573978000002, 34.460516669000143],
            [127.45508873800006, 34.466131903000147],
            [127.45337975400017, 34.470607815000122],
            [127.45826256600006, 34.47288646],
            [127.46534264400012, 34.470363674000069],
            [127.47282962300008, 34.466782945000105],
            [127.48682701900023, 34.463853257],
          ],
        ],
        [
          [
            [127.21021569100006, 34.423976955000015],
            [127.16895592500006, 34.417710679000081],
            [127.15300540500019, 34.419582424000126],
            [127.14128665500019, 34.424302476000136],
            [127.11736087300002, 34.437567450000174],
            [127.11736087300002, 34.445013739000146],
            [127.12281334700018, 34.450588283000158],
            [127.12378991000006, 34.455226955000072],
            [127.11988366000006, 34.459702867000047],
            [127.11052493600019, 34.46491120000006],
            [127.12435957100004, 34.473334052],
            [127.14031009200014, 34.478705145000148],
            [127.17302493600008, 34.48371002800009],
            [127.20427493600013, 34.486761786000088],
            [127.22055097700016, 34.485744533000016],
            [127.2342228520001, 34.480292059000178],
            [127.24512780000023, 34.464056708000143],
            [127.24187259200019, 34.445461330000128],
            [127.22868899800008, 34.430243231000148],
            [127.21021569100006, 34.423976955000015],
          ],
        ],
        [
          [
            [127.77930748800023, 34.488470770000092],
            [127.77116946700014, 34.48818594000015],
            [127.76905358200011, 34.489935614000146],
            [127.76579837300008, 34.493963934000121],
            [127.75652103000013, 34.49823639500012],
            [127.74195397200018, 34.502264716000084],
            [127.73218834700006, 34.508490302000055],
            [127.72771243600019, 34.516994533000101],
            [127.72282962300019, 34.523179429000052],
            [127.714610222, 34.526841539000102],
            [127.70753014400023, 34.533880927000084],
            [127.71290123800011, 34.542425848],
            [127.729746941, 34.549058335000083],
            [127.74805748800006, 34.551092841000084],
            [127.76107832100016, 34.543117580000128],
            [127.77621504000015, 34.518784898000078],
            [127.77442467500018, 34.511786200000088],
            [127.77491295700017, 34.508205471000124],
            [127.78296959700018, 34.506984768],
            [127.78956139400017, 34.503973700000088],
            [127.79395592500023, 34.49823639500012],
            [127.79175866000017, 34.492010809000149],
            [127.77930748800023, 34.488470770000092],
          ],
        ],
        [
          [
            [126.31560306100008, 34.538275458000058],
            [126.32227623800023, 34.533840236000103],
            [126.32683353000019, 34.53506094],
            [126.34945722700004, 34.54682038],
            [126.35035241000006, 34.538153387000094],
            [126.38005618600008, 34.498439846000096],
            [126.38160241, 34.48468659100017],
            [126.37891686300023, 34.469305731000034],
            [126.37354576900017, 34.454413153000175],
            [126.35385175900004, 34.419582424000126],
            [126.34587649800008, 34.409002997000172],
            [126.33594811300006, 34.403469143000066],
            [126.33423912900017, 34.406561591000141],
            [126.317149285, 34.419907945000048],
            [126.30925540500007, 34.423976955000015],
            [126.30925540500007, 34.417710679000081],
            [126.30518639400006, 34.401678778000175],
            [126.20997155000012, 34.358791408000101],
            [126.19849694100017, 34.355536200000145],
            [126.15642337300019, 34.352606512000037],
            [126.144786004, 34.355047919000171],
            [126.14258873800011, 34.363755601000051],
            [126.148203972, 34.371079820000048],
            [126.15560957099999, 34.37718333500014],
            [126.15845787900005, 34.382310289000074],
            [126.15308678500017, 34.388495184000035],
            [126.12427819100006, 34.403469143000066],
            [126.12427819100006, 34.382310289000074],
            [126.103282097, 34.402777411000116],
            [126.11573326900023, 34.436183986000103],
            [126.14421634200002, 34.463202216000056],
            [126.17212975400017, 34.46491120000006],
            [126.18116295700011, 34.486517645000148],
            [126.18702233200023, 34.494208075000145],
            [126.19629967500006, 34.502752997000087],
            [126.20704186300011, 34.507635809000149],
            [126.21656334700018, 34.508978583000143],
            [126.22380618600013, 34.513413804000109],
            [126.22657311300006, 34.52757396000014],
            [126.23829186300006, 34.515692450000088],
            [126.25416100400011, 34.514349677],
            [126.26832116, 34.522040106000119],
            [126.27442467500012, 34.5372582050001],
            [126.26677493600008, 34.549750067],
            [126.24105879000004, 34.566473700000031],
            [126.24773196700019, 34.574774481000119],
            [126.26392662900005, 34.575751044000086],
            [126.28044681100019, 34.568304755000113],
            [126.29476972699999, 34.558905341000084],
            [126.30543053500006, 34.554266669000143],
            [126.30787194100017, 34.551459052000112],
            [126.31080162900017, 34.545152085000083],
            [126.31560306100008, 34.538275458000058],
          ],
        ],
        [
          [
            [126.05762780000006, 34.53253815300009],
            [126.04672285199999, 34.53115469],
            [126.03516686300006, 34.533921617000161],
            [126.03158613400004, 34.53904857],
            [126.03736412900017, 34.540716864],
            [126.04346764400023, 34.540961005000142],
            [126.04851321700019, 34.545152085000083],
            [126.054453972, 34.548895575000117],
            [126.05347741000006, 34.552313544000114],
            [126.03126061300006, 34.556382554000081],
            [126.03663170700023, 34.561672268000152],
            [126.05046634200002, 34.563421942000147],
            [126.06495201900006, 34.563788153000147],
            [126.07634524800002, 34.570990302],
            [126.08130944100012, 34.580755927000141],
            [126.07642662900005, 34.590765692000119],
            [126.06641686300023, 34.603989976000051],
            [126.06006920700011, 34.620794989000061],
            [126.06763756600017, 34.62547435099999],
            [126.07984459700006, 34.617377020000063],
            [126.08708743600008, 34.611151434000092],
            [126.099375847, 34.605454820000105],
            [126.10010826900023, 34.596747137000037],
            [126.10238691500004, 34.594875393000066],
            [126.10425866000017, 34.589789130000142],
            [126.10336347699999, 34.579413153000061],
            [126.10027103000019, 34.568304755000113],
            [126.099375847, 34.558661200000145],
            [126.09376061300011, 34.553656317],
            [126.08570397200018, 34.54873281500015],
            [126.07732181100013, 34.542181708000058],
            [126.05762780000006, 34.53253815300009],
          ],
        ],
        [
          [
            [126.03809655000012, 34.569484768000123],
            [126.02857506600012, 34.565863348000093],
            [126.02051842500018, 34.571437893000095],
            [126.01661217500018, 34.606146552000055],
            [126.02019290500007, 34.625433661],
            [126.02857506600012, 34.634222723000065],
            [126.04135175900015, 34.611151434000092],
            [126.05201256600017, 34.598334052000055],
            [126.06039472700004, 34.584295966000113],
            [126.05762780000006, 34.572577216000028],
            [126.04859459700018, 34.570054429000109],
            [126.03809655000012, 34.569484768000123],
          ],
        ],
        [
          [
            [128.27125084700018, 34.632310289000102],
            [128.27580813900013, 34.631903387000094],
            [128.29460696700019, 34.639553127000127],
            [128.297129754, 34.637315171000139],
            [128.29566491000006, 34.631984768000066],
            [128.28606204499997, 34.622219143000123],
            [128.28174889400017, 34.619859117000161],
            [128.27531985800024, 34.619086005000057],
            [128.26587975400005, 34.620835679000052],
            [128.25806725400005, 34.619899807000152],
            [128.24740644600004, 34.615545966000084],
            [128.24219811300011, 34.611517645000035],
            [128.23625735800007, 34.61045970300016],
            [128.23528079500002, 34.614203192],
            [128.23878014400012, 34.620591539000102],
            [128.23780358200023, 34.625759182000124],
            [128.23340905000023, 34.62938060099999],
            [128.22966556100013, 34.630072333000115],
            [128.22527103000002, 34.632839260000182],
            [128.22567793100015, 34.637844143000123],
            [128.22836347700004, 34.642401434000149],
            [128.22877037900005, 34.647853908000016],
            [128.23275800900004, 34.651027736000074],
            [128.23951256600006, 34.652411200000145],
            [128.27116946700019, 34.638495184000149],
            [128.27125084700018, 34.632310289000102],
          ],
        ],
        [
          [
            [125.43392988400004, 34.695542710000083],
            [125.43726647200006, 34.688299872000144],
            [125.44581139400017, 34.68846263200011],
            [125.457286004, 34.690904039000046],
            [125.45923912900017, 34.686835028000175],
            [125.45045006600017, 34.679673570000048],
            [125.44581139400017, 34.67511627800009],
            [125.44320722700016, 34.669419664000102],
            [125.42741946700014, 34.654242255000113],
            [125.42432701900023, 34.647202867000047],
            [125.41773522200018, 34.63812897300015],
            [125.41260826900023, 34.632310289000102],
            [125.40853925899998, 34.628607489000061],
            [125.39877363400015, 34.631984768000066],
            [125.39234459700006, 34.646307684000149],
            [125.39340254, 34.659654039000159],
            [125.39893639400012, 34.671820380000057],
            [125.40560957100016, 34.682562567000062],
            [125.41285241000011, 34.691961981000091],
            [125.42123457100016, 34.697088934000121],
            [125.42945397200006, 34.698431708],
            [125.43392988400004, 34.695542710000083],
          ],
        ],
        [
          [
            [127.79786217500023, 34.579738674000069],
            [127.78663170700023, 34.579006252000127],
            [127.77540123800023, 34.58397044499999],
            [127.74000084700006, 34.613674221000011],
            [127.73585045700011, 34.62523021000014],
            [127.74187259200019, 34.643622137000179],
            [127.74626712300019, 34.648423570000077],
            [127.76498457100016, 34.660101630000085],
            [127.76832116000017, 34.666083075000088],
            [127.77588951900023, 34.691473700000117],
            [127.76075280000023, 34.704738674000126],
            [127.76254316500004, 34.712836005000057],
            [127.77344811300011, 34.716986395],
            [127.785899285, 34.71816640800013],
            [127.79664147200006, 34.714178778000147],
            [127.79932701900012, 34.704820054000109],
            [127.79420006600017, 34.693793036000088],
            [127.78207441499998, 34.68455638200011],
            [127.79249108200011, 34.682359117000104],
            [127.8016056650001, 34.670477606000148],
            [127.80876712300014, 34.655829169000143],
            [127.81723066500015, 34.630764065000065],
            [127.81812584700006, 34.616156317000147],
            [127.81511478000007, 34.601955471000124],
            [127.80787194100006, 34.588202216000113],
            [127.79786217500023, 34.579738674000069],
          ],
        ],
        [
          [
            [125.99903405000023, 34.677435614000061],
            [125.98389733200023, 34.660630601000051],
            [125.97689863400015, 34.656398830000128],
            [125.96998131600017, 34.655910549000126],
            [125.95972741000006, 34.656683661000059],
            [125.95964603000007, 34.659816799000126],
            [125.94516035199999, 34.666815497000115],
            [125.92945397200006, 34.672308661000145],
            [125.92505944100006, 34.67096588700015],
            [125.91602623800023, 34.6876488300001],
            [125.92286217500018, 34.695379950000117],
            [125.95240319100006, 34.705145575000145],
            [125.97055097700004, 34.725653387000094],
            [125.97673587300019, 34.728257554],
            [125.98047936300023, 34.726223049000069],
            [125.98902428500006, 34.713324286000059],
            [126.00098717500006, 34.704413153000118],
            [126.00407962300008, 34.700262762000179],
            [126.00538170700005, 34.69261302300005],
            [126.00318444100017, 34.684759833000058],
            [125.99903405000023, 34.677435614000061],
          ],
        ],
        [
          [
            [126.11784915500007, 34.769964911000145],
            [126.12720787900017, 34.766546942000119],
            [126.13314863400004, 34.761216539000159],
            [126.14193769600016, 34.757554429000109],
            [126.15381920700011, 34.75690338700015],
            [126.16358483200017, 34.754950262000122],
            [126.17025800900004, 34.749945380000085],
            [126.17383873800006, 34.744452216000141],
            [126.17310631600006, 34.73944733300003],
            [126.17408287900005, 34.732814846000124],
            [126.17750084700006, 34.724066473000065],
            [126.17790774800002, 34.713364976000051],
            [126.17212975400017, 34.703802802000055],
            [126.159434441, 34.702785549000097],
            [126.14771569100006, 34.712347723000093],
            [126.13843834700018, 34.723456122000115],
            [126.12769616000017, 34.72357819200009],
            [126.122325066, 34.719631252000099],
            [126.11882571700019, 34.718247789000102],
            [126.11133873800023, 34.717718817000119],
            [126.10425866000017, 34.721991278000147],
            [126.10254967500006, 34.730536200000174],
            [126.09343509200019, 34.7442894550001],
            [126.07374108200023, 34.761379299000126],
            [126.069590691, 34.769680080000015],
            [126.07927493600008, 34.767564195000105],
            [126.08716881600006, 34.767523505000113],
            [126.09457441500004, 34.770656643000095],
            [126.10523522200006, 34.771551825000174],
            [126.11784915500007, 34.769964911000145],
          ],
        ],
        [
          [
            [126.00717207100016, 34.780951239],
            [126.00757897200006, 34.759711005000113],
            [126.00294030000023, 34.761460679000109],
            [125.99154707100016, 34.764105536],
            [125.98650149800002, 34.766546942000119],
            [125.97787519600004, 34.757147528000118],
            [125.96420332099999, 34.757066148000135],
            [125.95167076900012, 34.759263414000131],
            [125.9461369150001, 34.75665924700003],
            [125.94410241000006, 34.743231512000037],
            [125.93873131600017, 34.731431382000054],
            [125.93002363400004, 34.722601630000028],
            [125.91814212300008, 34.71816640800013],
            [125.90642337300008, 34.719305731000148],
            [125.89779707100016, 34.726019598000036],
            [125.89258873800006, 34.736639716000141],
            [125.89087975400017, 34.749823309000092],
            [125.89389082100016, 34.762274481000034],
            [125.90186608200023, 34.771063544000086],
            [125.91285241000017, 34.776922919000029],
            [125.92505944100006, 34.780829169000029],
            [125.93864993600019, 34.776516018000123],
            [125.95655358200005, 34.779730536000088],
            [125.98926842500012, 34.790025132000025],
            [125.99594160200016, 34.790757554000137],
            [126.00074303500006, 34.790106512000179],
            [126.00440514400012, 34.787176825000145],
            [126.00717207100016, 34.780951239],
          ],
        ],
        [
          [
            [126.15821373800011, 34.764471747000115],
            [126.14625084700018, 34.761867580000015],
            [126.13461347700016, 34.767320054000052],
            [126.12159264400006, 34.776516018000123],
            [126.10482832100016, 34.781642971000124],
            [126.09253991000011, 34.787583726000079],
            [126.10238691500004, 34.794378973],
            [126.12728925900015, 34.797756252000013],
            [126.14185631600017, 34.802964585000112],
            [126.14942467500023, 34.808091539000131],
            [126.15902754000004, 34.804592190000122],
            [126.16627037900005, 34.796454169000114],
            [126.16871178500017, 34.792303778000175],
            [126.16553795700011, 34.789699611000074],
            [126.16211998800011, 34.783270575000145],
            [126.16423587300019, 34.76976146],
            [126.15821373800011, 34.764471747000115],
          ],
        ],
        [
          [
            [128.379012856, 34.822870451000185],
            [128.38582970300016, 34.820124841],
            [128.39536618600008, 34.823348162000102],
            [128.41076357000023, 34.826521822000032],
            [128.42822603300007, 34.827448422000074],
            [128.43799723000004, 34.819571664000179],
            [128.44063484600005, 34.806735409000098],
            [128.43533376200006, 34.798709496000114],
            [128.43897647600025, 34.793902942000145],
            [128.43463561100006, 34.78788231100016],
            [128.42457204700011, 34.791220274000139],
            [128.42059744500014, 34.78923582600008],
            [128.4208856920001, 34.786720069000111],
            [128.42910068400025, 34.785396720000122],
            [128.43733203100007, 34.779808956000082],
            [128.43329431000015, 34.773784158000055],
            [128.42308274400014, 34.763252549000057],
            [128.40653355100008, 34.763098136000153],
            [128.39928054900011, 34.770715731000095],
            [128.39536287600006, 34.778540189000083],
            [128.38698984800013, 34.778803800000148],
            [128.38895679200007, 34.785362821000078],
            [128.386205988, 34.791917414000082],
            [128.37330464300024, 34.793491198000183],
            [128.36870233100004, 34.796536845000119],
            [128.37827168600009, 34.802789858000139],
            [128.3800892300001, 34.81040434100008],
            [128.37041622000018, 34.816686261000186],
            [128.35239543000003, 34.821804177000061],
            [128.34097425000007, 34.818844074000097],
            [128.33419248600009, 34.819132496000165],
            [128.33204884400018, 34.825702467000113],
            [128.3400934, 34.832972862000091],
            [128.35183084900021, 34.835421578000123],
            [128.36724685800002, 34.830274022000182],
            [128.379012856, 34.822870451000185],
          ],
        ],
        [
          [
            [128.24146569100017, 34.799994208],
            [128.23845462300008, 34.796942450000088],
            [128.22901451900017, 34.799139716000084],
            [128.22315514400012, 34.801499742000047],
            [128.21216881600017, 34.807806708],
            [128.20582116000006, 34.815619208000086],
            [128.20704186300023, 34.821030992000132],
            [128.21387780000018, 34.8302269550001],
            [128.22730553500017, 34.838568427000084],
            [128.23991946700002, 34.836167710000112],
            [128.25489342500006, 34.822414455000015],
            [128.26050866000017, 34.814642645],
            [128.25652103000019, 34.809271552000055],
            [128.24577884200019, 34.809271552000055],
            [128.24073326900023, 34.807074286000059],
            [128.24146569100017, 34.799994208],
          ],
        ],
        [
          [
            [126.14226321700019, 34.879136460000083],
            [126.14722741000006, 34.876857815],
            [126.15202884200002, 34.871161200000031],
            [126.15308678500017, 34.864976304000052],
            [126.13965905000012, 34.85789622599999],
            [126.14454186300017, 34.842352606000091],
            [126.14210045700023, 34.83291250200007],
            [126.13502037900017, 34.829291083000115],
            [126.12517337300002, 34.829657294000029],
            [126.11890709700018, 34.823797919000086],
            [126.11915123800023, 34.812648830000072],
            [126.11589603000019, 34.803127346000068],
            [126.10523522200006, 34.798163153000118],
            [126.09620201900023, 34.798773505000085],
            [126.09400475400017, 34.804673570000105],
            [126.09441165500007, 34.808661200000088],
            [126.07943769600004, 34.822658596000153],
            [126.07105553500017, 34.832180080000128],
            [126.06413821700008, 34.84259674700003],
            [126.06714928500017, 34.850531317],
            [126.08179772200018, 34.856594143],
            [126.09376061300011, 34.857611395000063],
            [126.103282097, 34.853176174000097],
            [126.11255944100017, 34.853420315000122],
            [126.11524498800023, 34.859442450000031],
            [126.11329186300011, 34.871161200000031],
            [126.12281334700018, 34.87881094000015],
            [126.13591556100008, 34.878607489],
            [126.14226321700019, 34.879136460000083],
          ],
        ],
        [
          [
            [128.01343834700006, 34.86912669500002],
            [128.01872806100002, 34.86615631700009],
            [128.02458743600008, 34.877020575000174],
            [128.03630618600008, 34.892279364000146],
            [128.04590905000023, 34.893622137000037],
            [128.04883873800017, 34.88662344000015],
            [128.05396569100017, 34.882635809000149],
            [128.05892988400004, 34.880357164000188],
            [128.06120853000019, 34.868353583000086],
            [128.06690514400006, 34.85175202000012],
            [128.07276451900023, 34.845119533000158],
            [128.07683353000019, 34.842474677000055],
            [128.08236738400004, 34.835598049000154],
            [128.07178795700023, 34.833400783000158],
            [128.02654056100002, 34.843207098],
            [128.00180097700016, 34.836655992000104],
            [127.98731530000012, 34.838080145000092],
            [127.97242272200006, 34.845526434000149],
            [127.96363366000017, 34.859767971000068],
            [127.97046959700012, 34.87518952],
            [127.98414147200006, 34.889715887000037],
            [127.99284915500002, 34.90338776200015],
            [128.00196373800011, 34.913031317000119],
            [128.01783287900005, 34.916571356000119],
            [128.02295983200023, 34.90550364799999],
            [128.01596113400009, 34.889349677000112],
            [128.01246178500017, 34.878322658000073],
            [128.01343834700006, 34.86912669500002],
          ],
        ],
        [
          [
            [126.35962975400017, 34.858587958000143],
            [126.36166425900004, 34.837388414000046],
            [126.34205162900017, 34.822495835],
            [126.33130944100006, 34.825832424000012],
            [126.33765709700018, 34.831732489000146],
            [126.34083092500023, 34.837469794000029],
            [126.33822675900015, 34.841457424000012],
            [126.31031334700012, 34.854071356000176],
            [126.30616295700017, 34.855902411000059],
            [126.27662194100012, 34.851955471000068],
            [126.26351972700004, 34.846665757],
            [126.24870853000007, 34.844712632000054],
            [126.23682701900012, 34.848049221000068],
            [126.23072350400005, 34.85455963700015],
            [126.23609459700018, 34.859808661000059],
            [126.25196373800011, 34.860663153000147],
            [126.27230879, 34.866766669000143],
            [126.27588951900017, 34.870510158000073],
            [126.27914472700004, 34.87327708500014],
            [126.28012129, 34.877508856000148],
            [126.29265384200019, 34.880682684000121],
            [126.29712975400005, 34.886908270000092],
            [126.29761803500006, 34.894232489000089],
            [126.28980553500006, 34.903021552000055],
            [126.28646894600004, 34.912909247000144],
            [126.29737389400012, 34.917547919000086],
            [126.31470787900017, 34.914129950000174],
            [126.33253014400023, 34.90672435099999],
            [126.34522545700023, 34.896226304000109],
            [126.34392337300014, 34.890326239],
            [126.33521569100006, 34.889390367000104],
            [126.31999759200008, 34.881089585000112],
            [126.31812584700018, 34.876450914000102],
            [126.31869550900015, 34.870550848000065],
            [126.32007897200006, 34.865871486000131],
            [126.32650800900015, 34.864203192000119],
            [126.3406681650001, 34.864081122000144],
            [126.35962975400017, 34.858587958000143],
          ],
        ],
        [
          [
            [126.08912194100017, 34.911322333000115],
            [126.09148196700002, 34.896918036000059],
            [126.08838951900023, 34.8829613300001],
            [126.08073978000002, 34.870998440000065],
            [126.06910241000006, 34.862738348000065],
            [126.05469811300023, 34.859198309000092],
            [126.04639733200005, 34.86180247599999],
            [126.03988691499998, 34.866441148000106],
            [126.03142337300002, 34.868963934000035],
            [125.99878991000011, 34.862941799000126],
            [125.98650149800002, 34.862738348000065],
            [125.98650149800002, 34.868963934000035],
            [125.99634850400017, 34.876044012000094],
            [126.01433353000019, 34.899644273000135],
            [126.02800540500002, 34.909898179000052],
            [126.03443444100006, 34.90957265800013],
            [126.04395592500023, 34.907416083000143],
            [126.05225670700023, 34.907538153000118],
            [126.05600019600016, 34.913641669000086],
            [126.06031334700006, 34.926906643000123],
            [126.07007897200018, 34.928900458000058],
            [126.08122806100013, 34.922593492000132],
            [126.08912194100017, 34.911322333000115],
          ],
        ],
        [
          [
            [126.24642988399998, 34.916245835],
            [126.23755944100006, 34.91307200700011],
            [126.22535241000017, 34.913397528000147],
            [126.22038821700019, 34.922430731000148],
            [126.22242272200006, 34.931952216000141],
            [126.23023522200006, 34.93545156500015],
            [126.24732506600017, 34.93545156500015],
            [126.253184441, 34.93349844],
            [126.25342858200005, 34.929185289000188],
            [126.25017337300019, 34.918850002000099],
            [126.24642988399998, 34.916245835],
          ],
        ],
        [
          [
            [127.94988040500007, 34.824652411],
            [127.96192467500006, 34.806952216000084],
            [127.98129316499998, 34.809759833000143],
            [128.00586998800011, 34.81769440300009],
            [128.02906334700006, 34.821763414000074],
            [128.06275475400005, 34.817124742000132],
            [128.06959069100017, 34.815008856000119],
            [128.07471764400006, 34.803656317000147],
            [128.07178795700023, 34.793646552000055],
            [128.06625410200004, 34.783026434000121],
            [128.06332441500004, 34.769964911000145],
            [128.06609134200008, 34.749335028000118],
            [128.06527754000015, 34.73753489800005],
            [128.05958092500006, 34.732407945000134],
            [128.05160566500004, 34.728705145],
            [128.06006920700005, 34.708400783000101],
            [128.05591881600006, 34.698228257000054],
            [128.04053795700011, 34.695868231000091],
            [128.02588951900006, 34.703111070000105],
            [128.01010175900004, 34.708807684000092],
            [127.99154707100004, 34.701727606000034],
            [127.97527103000019, 34.699937242000047],
            [127.96534264400006, 34.719305731000148],
            [127.95411217500006, 34.759711005000113],
            [127.93767337300019, 34.767035223000121],
            [127.92457116000006, 34.759588934000064],
            [127.91570071700008, 34.742743231000063],
            [127.91236412900005, 34.721869208000086],
            [127.90626061300017, 34.713080145],
            [127.89210045700023, 34.712713934000092],
            [127.87712649800019, 34.716945705000015],
            [127.86801191500004, 34.721869208000086],
            [127.86182701900023, 34.730861721000096],
            [127.86036217500006, 34.73826732],
            [127.86011803500006, 34.747015692000062],
            [127.85792076900023, 34.759711005000113],
            [127.83033287900005, 34.833563544000029],
            [127.82667076900006, 34.851385809000092],
            [127.82935631600006, 34.868963934000035],
            [127.84083092500012, 34.886948960000083],
            [127.84848066500004, 34.891546942],
            [127.85629316500004, 34.892726955000128],
            [127.86231530000023, 34.896063544000143],
            [127.86703535200004, 34.918158270000148],
            [127.8728947270001, 34.924465236000103],
            [127.88892662900017, 34.93475983300003],
            [127.902110222, 34.940008856000091],
            [127.917246941, 34.936183986000074],
            [127.93051191500015, 34.926459052000112],
            [127.93824303500017, 34.913967190000122],
            [127.9368595710001, 34.900824286000059],
            [127.92807050900004, 34.888251044000171],
            [127.90560957100004, 34.868963934000035],
            [127.90967858200005, 34.862046617000132],
            [127.91431725400011, 34.844549872000087],
            [127.91993248800011, 34.835435289000188],
            [127.92847741, 34.830552476000108],
            [127.94988040500007, 34.824652411],
          ],
        ],
        [
          [
            [128.72388756600006, 34.954535223000121],
            [128.72999108200023, 34.945135809000092],
            [128.73438561300011, 34.93545156500015],
            [128.73414147200018, 34.922308661],
            [128.73015384200019, 34.910386460000055],
            [128.71436608200023, 34.876410223],
            [128.73462975400017, 34.88226959800015],
            [128.74431399800019, 34.887762762000179],
            [128.74756920700017, 34.885809637000037],
            [128.74854576900023, 34.868963934000035],
            [128.74740644599999, 34.857123114000089],
            [128.74293053500006, 34.844549872000087],
            [128.73414147200018, 34.837551174000012],
            [128.72055097700004, 34.842271226000108],
            [128.72608483200023, 34.828843492000104],
            [128.73902428500006, 34.806219794000143],
            [128.74170983200011, 34.790716864000146],
            [128.73406009200019, 34.788234768000038],
            [128.71656334700006, 34.793198960000083],
            [128.69776451900006, 34.801336981000176],
            [128.68637129000004, 34.808091539000131],
            [128.68327884200002, 34.794134833000058],
            [128.68018639400023, 34.786566473],
            [128.66285241000017, 34.769964911000145],
            [128.65723717500018, 34.756984768000152],
            [128.66911868600013, 34.749497789000159],
            [128.68555748800011, 34.743841864],
            [128.69385826900012, 34.736151434000149],
            [128.67823326900012, 34.724839585],
            [128.60580488400015, 34.706366278000147],
            [128.58399498800011, 34.705145575000145],
            [128.61817467500012, 34.72557200700011],
            [128.60401451900006, 34.731105861000131],
            [128.59864342500012, 34.739976304],
            [128.59945722700004, 34.751898505000113],
            [128.60450280000018, 34.766546942000119],
            [128.57349694100006, 34.753485419000143],
            [128.56430097700016, 34.758734442000147],
            [128.559825066, 34.770249742000161],
            [128.56039472700016, 34.781805731000091],
            [128.56666100400005, 34.787054755],
            [128.59156334700018, 34.79120514500012],
            [128.59864342500012, 34.802232164000159],
            [128.59913170700011, 34.817816473000065],
            [128.60450280000018, 34.835435289000188],
            [128.59083092500023, 34.842271226000108],
            [128.58057701900012, 34.831854559000121],
            [128.544688347, 34.818793036000145],
            [128.52881920700011, 34.808091539000131],
            [128.51742597700004, 34.822658596000153],
            [128.503184441, 34.835516669000171],
            [128.49154707100004, 34.849920966000141],
            [128.48845462300014, 34.868963934000035],
            [128.49488366000006, 34.880438544000171],
            [128.50806725400017, 34.891017971000124],
            [128.52328535200016, 34.899155992000047],
            [128.53630618600008, 34.903713283000101],
            [128.57374108200011, 34.910060940000122],
            [128.58399498800011, 34.909898179000052],
            [128.60043379000004, 34.904201565000093],
            [128.61353600400017, 34.897162177000112],
            [128.62696373800006, 34.891750393000066],
            [128.64551842500006, 34.890692450000117],
            [128.64551842500006, 34.896918036000059],
            [128.62696373800006, 34.906927802000055],
            [128.61638431100013, 34.92186107],
            [128.61166425900004, 34.93891022300015],
            [128.61060631600006, 34.954901434000064],
            [128.62378991, 34.953558661000059],
            [128.69385826900012, 34.986273505],
            [128.68189537900017, 35.004624742000047],
            [128.68751061300017, 35.022853908000016],
            [128.70289147200012, 35.032945054],
            [128.72055097700004, 35.02724844],
            [128.72494550899998, 35.018947658000044],
            [128.72527103000002, 35.009263414000159],
            [128.71876061300023, 34.972154039000046],
            [128.71924889400012, 34.966294664000102],
            [128.72388756600006, 34.954535223000121],
          ],
        ],
        [
          [
            [126.14380944100006, 35.05768463700015],
            [126.15251712300019, 35.054388739000117],
            [126.16529381600017, 35.055243231000034],
            [126.17416425900004, 35.053168036],
            [126.17839603000002, 35.048488674000097],
            [126.18091881600006, 35.044012762000122],
            [126.18189537900005, 35.038885809000121],
            [126.18165123800006, 35.029120184000149],
            [126.17741946700008, 35.025946356000091],
            [126.17237389400012, 35.023627020000148],
            [126.16700280000018, 35.02529531500015],
            [126.15805097700004, 35.033433335000083],
            [126.15398196700008, 35.035711981000063],
            [126.14861087300014, 35.033514716000141],
            [126.14380944100006, 35.033921617000075],
            [126.14234459700018, 35.038275458000058],
            [126.13851972700016, 35.039862372000087],
            [126.133555535, 35.039740302000112],
            [126.13217207100004, 35.041937567],
            [126.13941491000006, 35.046454169000143],
            [126.13656660200004, 35.047756252000156],
            [126.13209069100006, 35.047105210000112],
            [126.12777754000015, 35.048163153000147],
            [126.129649285, 35.052679755000113],
            [126.13738040500002, 35.056870835000055],
            [126.14380944100006, 35.05768463700015],
          ],
        ],
        [
          [
            [128.83179772200018, 34.993801174000126],
            [128.82667076900023, 34.992377020000063],
            [128.82422936300011, 34.99673086100016],
            [128.81934655000023, 35.014227606000119],
            [128.81177819100012, 35.020738023000106],
            [128.80909264400012, 35.025864976000108],
            [128.81031334700006, 35.032945054],
            [128.80689537900005, 35.041245835000055],
            [128.80160566500015, 35.049139716000056],
            [128.80437259200019, 35.056341864000061],
            [128.81495201900017, 35.061265367000132],
            [128.826426629, 35.062241929000109],
            [128.83643639400012, 35.059800523000078],
            [128.84327233200011, 35.047186591000084],
            [128.84278405000012, 35.021877346000153],
            [128.83838951900023, 35.001206773000135],
            [128.83179772200018, 34.993801174000126],
          ],
        ],
        [
          [
            [126.189219597, 35.102240302000055],
            [126.19857832100016, 35.097113348000121],
            [126.20704186300011, 35.099514065000093],
            [126.21664472700016, 35.103664455000015],
            [126.22282962300002, 35.101141669000114],
            [126.22494550900015, 35.096747137000122],
            [126.22982832100004, 35.09690989799999],
            [126.23601321700019, 35.098374742000047],
            [126.238536004, 35.091864325000145],
            [126.23161868600019, 35.086127020000092],
            [126.22242272200006, 35.085638739],
            [126.21851647200006, 35.08193594000015],
            [126.21851647200006, 35.076605536000088],
            [126.22950280000012, 35.067328192000119],
            [126.23878014400023, 35.06386953300013],
            [126.24919681100008, 35.063299872000144],
            [126.25513756600017, 35.056382554000052],
            [126.25814863400009, 35.047512111000017],
            [126.26319420700011, 35.041896877000013],
            [126.26498457099999, 35.034247137000179],
            [126.26107832099999, 35.024562893000123],
            [126.25717207099999, 35.021958726000136],
            [126.25456790500019, 35.025091864],
            [126.24293053500011, 35.02728913],
            [126.238047722, 35.03241608300003],
            [126.23406009200008, 35.03831614800005],
            [126.23422285200004, 35.04706452000012],
            [126.23609459700018, 35.051947333],
            [126.22925866000017, 35.053656317],
            [126.20362389400012, 35.057196356000176],
            [126.19792728000019, 35.061265367000132],
            [126.18913821700002, 35.061957098000065],
            [126.18482506600006, 35.06268952],
            [126.18051191500015, 35.065904039000074],
            [126.17481530000023, 35.068264065],
            [126.16944420700011, 35.073675848000065],
            [126.15870201900006, 35.094224351000108],
            [126.16480553500017, 35.102280992000047],
            [126.17750084700006, 35.105780341000028],
            [126.189219597, 35.102240302000055],
          ],
        ],
        [
          [
            [126.11426842500018, 35.059312242000161],
            [126.110606316, 35.051174221000068],
            [126.10523522200006, 35.050034898000135],
            [126.07862389400012, 35.055853583000086],
            [126.06910241000006, 35.054592190000065],
            [126.06544030000018, 35.056830145000063],
            [126.06299889400023, 35.059027411000145],
            [126.06039472700004, 35.060736395000148],
            [126.05600019600016, 35.061346747000115],
            [126.06381269600016, 35.093085028000147],
            [126.06910241000006, 35.10236237200003],
            [126.07601972700004, 35.105617580000072],
            [126.08790123800006, 35.108872789000102],
            [126.09888756600006, 35.11326732000002],
            [126.103770379, 35.119696356000119],
            [126.11133873800023, 35.133286851000079],
            [126.12940514400017, 35.142889716000141],
            [126.14991295700023, 35.146877346000039],
            [126.16521243600019, 35.143500067],
            [126.13477623800023, 35.128973700000145],
            [126.12777754000015, 35.120550848],
            [126.12696373800023, 35.110541083000115],
            [126.13135826900012, 35.100246486000017],
            [126.144786004, 35.082464911000116],
            [126.13404381600017, 35.078802802000084],
            [126.12281334700018, 35.069810289000046],
            [126.11426842500018, 35.059312242000161],
          ],
        ],
        [
          [
            [126.30730228000019, 35.616644598],
            [126.30941816500004, 35.61066315300009],
            [126.30567467500006, 35.603216864000032],
            [126.28728274800002, 35.590277411],
            [126.27491295700011, 35.579046942],
            [126.26710045700011, 35.57412344000015],
            [126.26384524800008, 35.571682033000016],
            [126.25806725400011, 35.570624091000141],
            [126.253672722, 35.5743675800001],
            [126.25049889400023, 35.580959377000156],
            [126.25245201900012, 35.587307033000101],
            [126.26295006600017, 35.591782945000048],
            [126.26148522200012, 35.593410549000069],
            [126.25652103000007, 35.59601471600017],
            [126.25896243600019, 35.598374742000132],
            [126.26449629000004, 35.600897528000147],
            [126.26823978000002, 35.605210679000052],
            [126.27426191500015, 35.606024481000148],
            [126.27784264400012, 35.607367255000057],
            [126.27613366, 35.612860419000171],
            [126.28044681100019, 35.612697658000016],
            [126.29175866000011, 35.611029364],
            [126.29932701900023, 35.616034247000144],
            [126.30298912900011, 35.619330145000092],
            [126.30730228000019, 35.616644598],
          ],
        ],
        [
          [
            [126.40479576900023, 36.479641018000095],
            [126.40369050900009, 36.473343568000033],
            [126.40268670300017, 36.468391678000117],
            [126.40541386500004, 36.458835528000108],
            [126.41293379000015, 36.44033437700007],
            [126.42644290500002, 36.423570054000052],
            [126.43213951900012, 36.407945054000081],
            [126.422618035, 36.41152578300013],
            [126.37818444100017, 36.409491278000118],
            [126.36378014400023, 36.41136302299999],
            [126.36105280800004, 36.426320981000131],
            [126.36587392800018, 36.431644584000153],
            [126.37355578800012, 36.43733670200011],
            [126.37761478000002, 36.446193752000127],
            [126.35377037900005, 36.434068101000136],
            [126.34262129000004, 36.438381252000127],
            [126.34571373800011, 36.453314520000092],
            [126.36378014400023, 36.473456122000115],
            [126.35694420700011, 36.479641018000095],
            [126.34327233200005, 36.466620184000092],
            [126.33864029100008, 36.475611029000063],
            [126.32814310100017, 36.471089495000129],
            [126.33360616900021, 36.495093329000085],
            [126.32976321700019, 36.531724351000136],
            [126.32515322400005, 36.553864215000132],
            [126.31481811200015, 36.581786863000119],
            [126.32346290300009, 36.590890510000108],
            [126.34118120200017, 36.60413360200009],
            [126.36024360000025, 36.609360123000087],
            [126.37354576900017, 36.592230536000116],
            [126.37533613400015, 36.586086330000157],
            [126.37099369800004, 36.578754296000014],
            [126.36336739900017, 36.573839777],
            [126.36426630900021, 36.566970229000148],
            [126.381114129, 36.536281643000066],
            [126.38379967500012, 36.526516018000123],
            [126.37940514400012, 36.51837799700003],
            [126.38456565000016, 36.511198198000059],
            [126.395909574, 36.505792186000164],
            [126.40479576900023, 36.493312893000123],
            [126.38379967500012, 36.493312893000123],
            [126.38379967500012, 36.487127997000144],
            [126.39063561300011, 36.486029364],
            [126.40479576900023, 36.479641018000095],
          ],
        ],
        [
          [
            [131.86252188600005, 37.243104377000023],
            [131.86170437700005, 37.241605610000093],
            [131.86014777700021, 37.24239861400018],
            [131.86104174400012, 37.244006186000135],
            [131.86252188600005, 37.243104377000023],
          ],
        ],
        [
          [
            [126.15919030000006, 37.231919664000188],
            [126.15503991000006, 37.225734768000123],
            [126.14234459700018, 37.215399481000119],
            [126.13331139400017, 37.212347723000121],
            [126.12159264400006, 37.210923570000048],
            [126.11166425900009, 37.214544989000032],
            [126.10515384200008, 37.223578192000119],
            [126.10254967500006, 37.228664455000128],
            [126.099864129, 37.228013414000188],
            [126.09278405000023, 37.224839585000112],
            [126.08863366, 37.225978908000158],
            [126.08871504, 37.229559637000037],
            [126.09180748800006, 37.245917059000149],
            [126.09742272200018, 37.259426174000126],
            [126.10474694100017, 37.267523505000057],
            [126.11280358200011, 37.263088283000073],
            [126.11980228000019, 37.253607489000061],
            [126.12696373800023, 37.248928127000156],
            [126.15162194100006, 37.238592841000141],
            [126.15919030000006, 37.231919664000188],
          ],
        ],
        [
          [
            [126.47820071700008, 37.24160390800013],
            [126.46949303500017, 37.233465887000037],
            [126.45818118600019, 37.231268622000059],
            [126.45045006600017, 37.235825914000188],
            [126.44223066500015, 37.23517487200003],
            [126.43572024800008, 37.238430080000072],
            [126.43344160199999, 37.25104401200015],
            [126.43531334700018, 37.26398346600017],
            [126.44353274800008, 37.273098049000154],
            [126.46119225400017, 37.280585028000033],
            [126.48406009200019, 37.278998114],
            [126.494395379, 37.273504950000145],
            [126.49830162900011, 37.261419989000061],
            [126.493907097, 37.257269598000121],
            [126.48316491000011, 37.254339911],
            [126.47901451900023, 37.247748114000117],
            [126.47820071700008, 37.24160390800013],
          ],
        ],
        [
          [
            [126.57374108200023, 37.278998114],
            [126.57789147200018, 37.271144924000012],
            [126.58961022200018, 37.265773830000157],
            [126.60865319100006, 37.259426174000126],
            [126.61394290500002, 37.25519440300009],
            [126.61638431100008, 37.250555731000176],
            [126.61882571700019, 37.242010809000149],
            [126.61353600400005, 37.232814846000096],
            [126.56251061300023, 37.200384833],
            [126.539317254, 37.19806549700003],
            [126.53956139400023, 37.203111070000048],
            [126.55046634200014, 37.214911200000031],
            [126.55193118600019, 37.221665757000054],
            [126.54737389400023, 37.227484442000119],
            [126.56039472700004, 37.239325262000179],
            [126.56080162900005, 37.251288153000118],
            [126.55543053500017, 37.266058661],
            [126.54802493600019, 37.276312567000119],
            [126.54078209700018, 37.282945054],
            [126.54297936300023, 37.284979559000121],
            [126.55941816500015, 37.278631903000175],
            [126.57349694100017, 37.288478908000101],
            [126.57667076900023, 37.287665106000091],
            [126.57374108200023, 37.278998114],
          ],
        ],
        [
          [
            [126.57644010500005, 37.502370480000181],
            [126.58057893500015, 37.495627118000087],
            [126.58306593700004, 37.490383828000134],
            [126.577625729, 37.488584848000144],
            [126.56918379, 37.483587958000143],
            [126.5657658210001, 37.479966539000188],
            [126.54829135400021, 37.476637221000132],
            [126.54055947100014, 37.47765700500004],
            [126.52993258500024, 37.473683142000041],
            [126.50657213500008, 37.46556815100017],
            [126.49398436600021, 37.456585083000121],
            [126.48701249900014, 37.446795614000067],
            [126.44341265299997, 37.420625381000107],
            [126.43359457200003, 37.422405821000083],
            [126.42443423800003, 37.421569434000062],
            [126.42046059600014, 37.422355526],
            [126.4162780300002, 37.434554709000125],
            [126.40836694700013, 37.438536943000102],
            [126.40472699200021, 37.435367928000105],
            [126.40142731800003, 37.434469826000125],
            [126.40028854700003, 37.43653028199999],
            [126.40199194300024, 37.440053295000084],
            [126.39949220300016, 37.444921322000155],
            [126.39341183300021, 37.445545420000101],
            [126.38938611300003, 37.441316253000124],
            [126.38320006100005, 37.437454989000017],
            [126.37573144899997, 37.440136735000138],
            [126.36497292700003, 37.442291305000154],
            [126.3713270960001, 37.447311164],
            [126.36911461900016, 37.452736484000113],
            [126.35817777300022, 37.46101202500013],
            [126.35551114100011, 37.466222800000153],
            [126.35934227900012, 37.473629767000077],
            [126.36529165600021, 37.475064397000111],
            [126.37356753399999, 37.47164295099999],
            [126.41888511500005, 37.496861378000162],
            [126.45132674600015, 37.499682004000036],
            [126.47337965000023, 37.49925683900004],
            [126.49370873300015, 37.507781052000112],
            [126.50066081700018, 37.530185259000049],
            [126.51483014900023, 37.534347200000141],
            [126.52637780000012, 37.525091864000146],
            [126.53199303500011, 37.52334219000015],
            [126.53777103000007, 37.52073802300005],
            [126.54346764400023, 37.518866278000175],
            [126.54908287900005, 37.518622137000037],
            [126.55511086400011, 37.517683956000141],
            [126.56330320700025, 37.514443293000156],
            [126.56723032800019, 37.511231283000129],
            [126.57114036500016, 37.506348497000076],
            [126.57644010500005, 37.502370480000181],
          ],
        ],
        [
          [
            [130.89673912900011, 37.463120835],
            [130.87769616000011, 37.455511786000145],
            [130.85222415500019, 37.459458726000136],
            [130.82748457100004, 37.471258856000091],
            [130.81104576900012, 37.487005927000055],
            [130.80583743600002, 37.507391669000057],
            [130.81430097700016, 37.522609768000038],
            [130.832286004, 37.532131252000013],
            [130.86784915500019, 37.536932684000092],
            [130.89161217500006, 37.543890692],
            [130.903575066, 37.545843817000147],
            [130.92066491000006, 37.535589911000116],
            [130.91944420700023, 37.510199286],
            [130.90837649800019, 37.481919664000131],
            [130.89673912900011, 37.463120835],
          ],
        ],
        [
          [
            [125.69361412900005, 37.653957424000041],
            [125.68783613400015, 37.65176015800013],
            [125.68458092500012, 37.657294012000065],
            [125.67920983200023, 37.669663804],
            [125.68043053500017, 37.678290106000091],
            [125.68824303500017, 37.679266669000143],
            [125.69922936300011, 37.678127346000124],
            [125.70622806100019, 37.672308661000088],
            [125.70313561300011, 37.66144440300009],
            [125.69361412900005, 37.653957424000041],
          ],
        ],
        [
          [
            [126.37012780000023, 37.658351955000015],
            [126.34986412900005, 37.657375393000123],
            [126.28199303500006, 37.699286200000031],
            [126.290782097, 37.705633856000148],
            [126.29493248800017, 37.713568427000112],
            [126.30062910200004, 37.732367255000085],
            [126.30372155000012, 37.737209377000156],
            [126.30860436300011, 37.742336330000072],
            [126.31421959700018, 37.746079820000105],
            [126.31999759200008, 37.747219143000123],
            [126.32374108200011, 37.745550848000121],
            [126.32618248800023, 37.742499091000028],
            [126.32764733200011, 37.738267320000105],
            [126.33073978000007, 37.71995677300005],
            [126.33187910200004, 37.716498114000146],
            [126.33668053500011, 37.709906317000062],
            [126.34302819100006, 37.704291083000086],
            [126.35670006600017, 37.695135809000092],
            [126.36915123800006, 37.687323309000092],
            [126.37875410200016, 37.678697007],
            [126.38070722700004, 37.669175523000021],
            [126.37012780000023, 37.658351955000015],
          ],
        ],
        [
          [
            [126.27654056100013, 37.811509507000054],
            [126.29525800899998, 37.802476304000052],
            [126.31495201900023, 37.80272044499999],
            [126.33326256600017, 37.800441799000041],
            [126.34253991000006, 37.792141018000123],
            [126.33594811300006, 37.774400132000054],
            [126.31820722700016, 37.762274481000148],
            [126.29623457100016, 37.761135158000016],
            [126.25456790500019, 37.768215236000074],
            [126.24537194100006, 37.765366929000137],
            [126.23414147200006, 37.760646877000127],
            [126.22445722700016, 37.760443427000084],
            [126.22046959700018, 37.771307684000149],
            [126.22266686300006, 37.774725653000175],
            [126.23414147200006, 37.802313544000086],
            [126.24642988399998, 37.811753648000106],
            [126.26050866000006, 37.814764716000113],
            [126.27654056100013, 37.811509507000054],
          ],
        ],
        [
          [
            [126.52906334700018, 37.62490469000015],
            [126.51783287900017, 37.603827216000028],
            [126.50782311300011, 37.596869208000115],
            [126.50017337300008, 37.59617747599999],
            [126.49415123800023, 37.598374742000161],
            [126.48365319100017, 37.60740794499999],
            [126.47608483200023, 37.611070054000052],
            [126.47071373800006, 37.60956452],
            [126.46656334700006, 37.606187242000161],
            [126.46273847700004, 37.604315497000115],
            [126.41016686300011, 37.602036851000136],
            [126.39063561300011, 37.609279690000065],
            [126.37012780000023, 37.63104889500012],
            [126.40088951900023, 37.637437242000047],
            [126.40821373800023, 37.641302802000055],
            [126.41667728000019, 37.651841539000102],
            [126.41553795700023, 37.656683661],
            [126.40992272200006, 37.659735419000086],
            [126.40479576900023, 37.665187893000123],
            [126.36915123800006, 37.719061591000141],
            [126.36695397200018, 37.727240302000055],
            [126.37061608200023, 37.737982489000089],
            [126.36963951900006, 37.785345770000035],
            [126.37378991, 37.796128648000106],
            [126.38347415500007, 37.799017645000148],
            [126.40707441500004, 37.820705471000124],
            [126.42530358200011, 37.824448960000055],
            [126.44125410199999, 37.81533437700007],
            [126.456309441, 37.801214911000145],
            [126.47144616, 37.790228583000086],
            [126.50359134200008, 37.777411200000145],
            [126.516368035, 37.767238674000012],
            [126.52149498800023, 37.751166083000115],
            [126.52035566499998, 37.74310944200009],
            [126.51514733200011, 37.729559637000122],
            [126.51400800900015, 37.723537502000013],
            [126.51628665500002, 37.71678294499999],
            [126.52556399800019, 37.703273830000015],
            [126.52767988399998, 37.695868231000119],
            [126.53093509200002, 37.659898179000052],
            [126.52767988399998, 37.651516018000095],
            [126.53321373800011, 37.646144924000126],
            [126.538828972, 37.635443427000027],
            [126.54200280000006, 37.63104889500012],
            [126.52906334700018, 37.62490469000015],
          ],
        ],
        [
          [
            [124.7142033210001, 37.80955638200011],
            [124.70679772200018, 37.806341864000061],
            [124.69898522200012, 37.80687083500014],
            [124.69239342500012, 37.80499909100017],
            [124.687266472, 37.800767320000048],
            [124.68458092500023, 37.799994208000115],
            [124.68458092500023, 37.802639065],
            [124.68474368600019, 37.803941148000106],
            [124.68148847700016, 37.808823960000083],
            [124.67351321700019, 37.817043361000074],
            [124.67652428500011, 37.824896552000055],
            [124.69336998800011, 37.835516669000114],
            [124.70411217500012, 37.844387111000131],
            [124.70915774800002, 37.847560940000037],
            [124.71648196700008, 37.846909898000078],
            [124.72445722699999, 37.843654690000122],
            [124.72584069100017, 37.837388414000159],
            [124.72136478000002, 37.828436591000141],
            [124.71827233200023, 37.818182684000121],
            [124.7142033210001, 37.80955638200011],
          ],
        ],
        [
          [
            [124.732676629, 37.980454820000077],
            [124.74366295700017, 37.969671942000062],
            [124.74203535200004, 37.955023505000142],
            [124.72820071700002, 37.947007554000024],
            [124.70875084700006, 37.95628489800005],
            [124.68327884200002, 37.954535223000065],
            [124.67798912900017, 37.952541408000016],
            [124.67839603000007, 37.942043361000131],
            [124.68816165500019, 37.938177802000141],
            [124.69988040500019, 37.936509507000139],
            [124.70533287900005, 37.932684637000122],
            [124.68490644600016, 37.919989325000145],
            [124.64405358200011, 37.924505927000112],
            [124.61361738400015, 37.945054429000052],
            [124.62403405000023, 37.980454820000077],
            [124.64942467500018, 37.973293361000017],
            [124.70492597700004, 37.988674221000153],
            [124.732676629, 37.980454820000077],
          ],
        ],
        [
          [
            [128.47445722700016, 38.426092841000028],
            [128.56275475400005, 38.288967190000065],
            [128.63160241000011, 38.144964911],
            [128.642832879, 38.129299221000011],
            [128.67872155000012, 38.094305731000119],
            [128.69247480600021, 38.064927476000108],
            [128.85914147200006, 37.876776434000149],
            [128.92383873800006, 37.802313544000086],
            [129.00131269600004, 37.734361070000105],
            [129.0122990240001, 37.727240302000055],
            [129.06421959700018, 37.678859768000152],
            [129.06690514400023, 37.658433335],
            [129.067149285, 37.63385651200015],
            [129.07333418100009, 37.613023179],
            [129.1087345710001, 37.597398179000109],
            [129.11898847699999, 37.580633856000091],
            [129.14820397200006, 37.505113023000078],
            [129.16122480600021, 37.487616278000118],
            [129.17684980600021, 37.480169989000146],
            [129.18995201900006, 37.469387111000131],
            [129.23544355600004, 37.398260809000092],
            [129.26278730600021, 37.370917059000121],
            [129.27222741000006, 37.355861721000011],
            [129.27588951900012, 37.339911200000088],
            [129.28280683700009, 37.324448960000083],
            [129.33106530000012, 37.282171942000062],
            [129.34644616000017, 37.247503973000093],
            [129.360118035, 37.17177969],
            [129.37891686300023, 37.13011302300005],
            [129.43018639400006, 37.073065497000144],
            [129.43409264400006, 37.061102606000119],
            [129.42823326900023, 37.049546617000189],
            [129.41895592500006, 37.03851959800015],
            [129.41309655000023, 37.027736721000124],
            [129.41268964900021, 37.01805247599999],
            [129.41993248800017, 36.986761786000116],
            [129.42872155000012, 36.897406317],
            [129.43799889400006, 36.856146552000055],
            [129.47120201900012, 36.771877346000153],
            [129.47388756600017, 36.730617580000015],
            [129.4634708990001, 36.692450262000037],
            [129.44092858200011, 36.657782294000143],
            [129.42261803500006, 36.616644598000093],
            [129.42286217500006, 36.614976304000081],
            [129.42798912900011, 36.575669664000159],
            [129.44166100400005, 36.534654039000046],
            [129.44711347700016, 36.493312893000123],
            [129.44092858200011, 36.407945054000081],
            [129.434825066, 36.389593817000119],
            [129.40390058700009, 36.359442450000088],
            [129.39185631600006, 36.342474677000112],
            [129.3865666020001, 36.322699286],
            [129.38233483200017, 36.201849677000084],
            [129.38575280000018, 36.185451565000065],
            [129.39185631600006, 36.176336981000176],
            [129.39852949300021, 36.17267487200003],
            [129.40390058700009, 36.167629299000097],
            [129.40967858200023, 36.133612372000144],
            [129.41708418100021, 36.1075707050001],
            [129.42514082100016, 36.103013414000046],
            [129.43051191500004, 36.097398179000052],
            [129.42676842500006, 36.081773179000052],
            [129.41871178500006, 36.072984117000189],
            [129.40829511800004, 36.068915106000034],
            [129.39844811300023, 36.066310940000122],
            [129.38178266800006, 36.055174622000109],
            [129.38085871700005, 36.04148684400009],
            [129.40193704300023, 36.025660084000108],
            [129.41708062100011, 36.011912686000059],
            [129.4330514750001, 35.996107732],
            [129.45333141, 35.993287368000054],
            [129.47901451900006, 36.009588934000149],
            [129.54257246200009, 36.065904039000131],
            [129.55479495800003, 36.082477915000098],
            [129.57129967500023, 36.071844794000143],
            [129.57496178500017, 36.054673570000134],
            [129.58204186300023, 36.036810614000061],
            [129.58725019600004, 36.016913153000175],
            [129.57164375300019, 35.997124364000101],
            [129.53558955300016, 35.939035632000113],
            [129.53283905500015, 35.913012911000109],
            [129.52213431000021, 35.844552213000085],
            [129.4962164990001, 35.785073935000057],
            [129.49765058700009, 35.747870184000035],
            [129.47112589500003, 35.683222298000103],
            [129.45157570400025, 35.651802283],
            [129.44305339200005, 35.636090011000178],
            [129.45137576100004, 35.618243323000129],
            [129.46058990800012, 35.609294223000163],
            [129.4672751410001, 35.599667569000147],
            [129.46348790100015, 35.577883359000012],
            [129.46135128400013, 35.552061385000101],
            [129.45460045700023, 35.513251044000029],
            [129.44442793100009, 35.496812242000132],
            [129.42798912900011, 35.478745835000112],
            [129.41285241000011, 35.472072658000158],
            [129.40626061300023, 35.489691473000093],
            [129.40040123800006, 35.512640692000062],
            [129.38746178500017, 35.529852606000091],
            [129.37354576900012, 35.532782294000114],
            [129.36524498800011, 35.513251044000029],
            [129.37891686300023, 35.510687567000119],
            [129.38705488400015, 35.50226471600017],
            [129.39096113400015, 35.489935614000117],
            [129.39185631600006, 35.475734768],
            [129.38746178500017, 35.462307033000016],
            [129.37720787900005, 35.459173895000148],
            [129.36573326900012, 35.458319403000061],
            [129.35775800900015, 35.451808986000131],
            [129.35922285200004, 35.444647528000118],
            [129.36050348000001, 35.42605606700009],
            [129.36458132900012, 35.406870057000063],
            [129.35526191400001, 35.39457178],
            [129.34678727000002, 35.382272538000038],
            [129.35512974900021, 35.37266731900003],
            [129.36181399000012, 35.367171831000078],
            [129.36044355600021, 35.353583075000174],
            [129.33987502900001, 35.348074232],
            [129.3205132100002, 35.338545472000121],
            [129.29271986900008, 35.316049089000117],
            [129.26920554700021, 35.316766528000144],
            [129.26412629100017, 35.302427614000166],
            [129.26240016100004, 35.283988745000059],
            [129.25648417200014, 35.274421522000111],
            [129.24973457400009, 35.266227746000183],
            [129.25538170700023, 35.25259023600016],
            [129.25570722700016, 35.246975002000156],
            [129.24439034400021, 35.228218040000016],
            [129.22847902700008, 35.200530553000121],
            [129.21069260600021, 35.183001370000127],
            [129.20108409400021, 35.166628086000131],
            [129.19434655000018, 35.155503648000106],
            [129.1788955530001, 35.15575745200006],
            [129.16747984700024, 35.156168307000044],
            [129.15341230600015, 35.154282945000105],
            [129.14307701900023, 35.15477122599999],
            [129.13220458099997, 35.150766444000013],
            [129.1202571490002, 35.149616723000022],
            [129.11929663500021, 35.135567198],
            [129.12728050800015, 35.12582503400013],
            [129.12860118099999, 35.114147970000133],
            [129.12347008100019, 35.100845473],
            [129.10107818500003, 35.092276153000014],
            [129.08296636300005, 35.105184781000148],
            [129.07199805400003, 35.112617369000148],
            [129.06384042000016, 35.120847092000119],
            [129.037627227, 35.097834228000082],
            [129.02568968200015, 35.079502249000129],
            [129.02186185400015, 35.059983107000093],
            [129.01375367600011, 35.055307289],
            [129.00895713300005, 35.065134478000161],
            [128.997026105, 35.075728095000144],
            [128.99801473400012, 35.066281188000104],
            [129.00040153000023, 35.045950018],
            [128.99276994400012, 35.045962659000068],
            [128.9894054590001, 35.052694024000132],
            [128.98511156000009, 35.054283780000148],
            [128.97486412900017, 35.048651434000149],
            [128.96512461700016, 35.043648796000028],
            [128.95844737900021, 35.050387047000029],
            [128.95036040000016, 35.072913013000104],
            [128.95370360600012, 35.093201670000141],
            [128.96134093800018, 35.118954637000016],
            [128.96277764300012, 35.133385991000111],
            [128.95800991400009, 35.136896883000148],
            [128.948944017, 35.125974671000094],
            [128.91841959300021, 35.081882053000029],
            [128.90459292600011, 35.081098650000129],
            [128.90363532100017, 35.101390025000129],
            [128.90458592900021, 35.120509920000089],
            [128.89792560800018, 35.12130533],
            [128.8892521490001, 35.105454820000105],
            [128.88218607000013, 35.081484777000028],
            [128.85740242600005, 35.08382307900014],
            [128.79155544200003, 35.082953208000035],
            [128.78777325400009, 35.101318593000187],
            [128.78107691500023, 35.111062146000066],
            [128.77198831899997, 35.083313239000134],
            [128.76197088500007, 35.079392388000187],
            [128.73868948900022, 35.087987622000114],
            [128.72779381600006, 35.104722398000078],
            [128.71583092500012, 35.105861721000011],
            [128.70045006600017, 35.096136786000059],
            [128.70045006600017, 35.139878648000135],
            [128.69629967500012, 35.143500067],
            [128.687754754, 35.140611070000077],
            [128.6805119150001, 35.131415106000091],
            [128.679942254, 35.115952867000161],
            [128.66407311300006, 35.126125393000123],
            [128.65170332100016, 35.140773830000128],
            [128.63803144600004, 35.149847723000121],
            [128.61793053500017, 35.143255927000055],
            [128.62232506600006, 35.157375393000095],
            [128.61402428500017, 35.177435614000146],
            [128.61793053500017, 35.184881903000033],
            [128.60792076900006, 35.21116771000014],
            [128.60206139400023, 35.213934637000094],
            [128.59058678500017, 35.20538971600017],
            [128.57813561300006, 35.189357815000093],
            [128.57789147200006, 35.178656317000062],
            [128.59058678500017, 35.156968492000161],
            [128.59766686300023, 35.130519924000012],
            [128.60181725400011, 35.121242580000157],
            [128.61036217500012, 35.109198309000035],
            [128.62704511800004, 35.090765692],
            [128.6294865240001, 35.08148834800015],
            [128.62476647200018, 35.068182684000035],
            [128.63266035200016, 35.067287502000127],
            [128.64503014400012, 35.062892971000153],
            [128.65211022200006, 35.061346747000115],
            [128.64332116000017, 35.056545315000122],
            [128.634532097, 35.054632880000057],
            [128.61426842500012, 35.054592190000065],
            [128.6036076180001, 35.056626695000105],
            [128.60059655000006, 35.061916408000073],
            [128.60010826900006, 35.06858958500014],
            [128.59677168100004, 35.075018622000144],
            [128.58716881600017, 35.083319403000033],
            [128.58033287900017, 35.087876695000077],
            [128.52637780000023, 35.103461005000057],
            [128.51124108200023, 35.104234117000189],
            [128.47811933700021, 35.099066473000093],
            [128.46908613400004, 35.095404364000117],
            [128.46973717500023, 35.088364976000051],
            [128.48129316499998, 35.075018622000144],
            [128.46273847700016, 35.071966864000061],
            [128.42847741, 35.057806708000115],
            [128.38917076900012, 35.049017645000063],
            [128.38404381600017, 35.03636302299999],
            [128.38209069100006, 35.022609768000066],
            [128.37142988400004, 35.013576565000065],
            [128.37142988400004, 35.006781317000147],
            [128.47120201900012, 35.050238348000093],
            [128.48812910199999, 35.051174221000068],
            [128.49154707100004, 35.033596096000124],
            [128.49756920700011, 35.020697333000115],
            [128.49960371200004, 35.009222723000065],
            [128.49154707100004, 34.995917059000149],
            [128.47877037900017, 34.990057684000121],
            [128.45923912900017, 34.987372137000037],
            [128.439707879, 34.988104559000149],
            [128.42676842500012, 34.992499091000141],
            [128.42001386800004, 34.982733466000113],
            [128.42465254, 34.978013414000188],
            [128.4336857430001, 34.973700262000094],
            [128.44044030000023, 34.96515534100017],
            [128.44141686300011, 34.959947007000082],
            [128.44174238400015, 34.952460028000118],
            [128.44044030000023, 34.931626695000134],
            [128.43295332100016, 34.890692450000117],
            [128.44214928500006, 34.883490302000084],
            [128.450938347, 34.885891018000038],
            [128.45785566500004, 34.893866278000175],
            [128.46094811300011, 34.903713283000101],
            [128.47445722700016, 34.890692450000117],
            [128.46054121200009, 34.87018463700015],
            [128.435313347, 34.843166408000016],
            [128.412929531, 34.837635083000023],
            [128.38288272000014, 34.839289670000156],
            [128.3833965260001, 34.85458744400016],
            [128.40267988400009, 34.854803778000118],
            [128.41089928500017, 34.856024481000034],
            [128.41586347700004, 34.862738348000065],
            [128.35377037900011, 34.868475653000147],
            [128.34083092500006, 34.872707424000069],
            [128.32650800900004, 34.889064846000096],
            [128.32447350400017, 34.897284247000172],
            [128.33472741, 34.902573960000055],
            [128.35775800900009, 34.909898179000052],
            [128.33505293100021, 34.947333075000088],
            [128.31869550900004, 34.934068101000079],
            [128.30225670700011, 34.908026434000092],
            [128.27906334700018, 34.906805731000176],
            [128.2579044930001, 34.930161851000079],
            [128.24683678500006, 34.935980536000116],
            [128.22413170700011, 34.937892971000096],
            [128.214121941, 34.932928778000033],
            [128.20858808700009, 34.920843817000119],
            [128.20655358200005, 34.90550364799999],
            [128.20704186300023, 34.890692450000117],
            [128.18588300900015, 34.893622137000037],
            [128.13941491, 34.891913153000033],
            [128.12110436300023, 34.900295315000093],
            [128.11898847700004, 34.905707098000121],
            [128.11874433700009, 34.920355536000145],
            [128.11768639400023, 34.924221096000153],
            [128.11304772200012, 34.925197658000016],
            [128.10189863399998, 34.923529364],
            [128.08749433700021, 34.926255601000079],
            [128.06397545700017, 34.927923895000092],
            [128.05567467500006, 34.931626695000134],
            [128.05103600400017, 34.939642645000092],
            [128.05152428500006, 34.947495835000055],
            [128.05404707100016, 34.955023505000113],
            [128.05567467500006, 34.961737372000144],
            [128.05404707100016, 35.035834052000112],
            [128.05567467500006, 35.047756252000156],
            [128.05860436300011, 35.053290106000176],
            [128.063731316, 35.060614325000174],
            [128.06812584700012, 35.070217190000065],
            [128.06934655000012, 35.082464911000116],
            [128.05372155000023, 35.067124742000161],
            [128.04086347700004, 35.049221096000039],
            [128.02499433700009, 35.035793361000046],
            [128.00098717500023, 35.034084377000042],
            [128.01929772200012, 34.998683986000017],
            [128.00684655000006, 34.991766669000114],
            [127.97974694100006, 34.992254950000088],
            [127.95386803500006, 34.978827216000113],
            [127.94336998800006, 35.002834377000156],
            [127.93702233200023, 35.01264069200009],
            [127.92579186300023, 35.01976146000014],
            [127.92286217500012, 34.98753489799999],
            [127.91211998800011, 34.962062893000095],
            [127.89120527400016, 34.94668203300013],
            [127.864512566, 34.944728908000101],
            [127.85385175899998, 34.948187567],
            [127.8443302740001, 34.950181382000139],
            [127.83643639400012, 34.949448960000112],
            [127.83033287900005, 34.944728908000101],
            [127.81544030000006, 34.954820054000081],
            [127.80502363399998, 34.957139390000023],
            [127.79843183700021, 34.958644924000069],
            [127.7579044930001, 34.958319403000147],
            [127.74439537900005, 34.960191148000106],
            [127.73902428500017, 34.965277411000145],
            [127.73658287900005, 34.97288646],
            [127.73072350400017, 34.982570705000128],
            [127.71924889400023, 34.990057684000121],
            [127.71656334700006, 34.982367255],
            [127.71827233200005, 34.970404364000061],
            [127.72046959700006, 34.96515534100017],
            [127.71322675900004, 34.946722723000121],
            [127.70923912900017, 34.940415757],
            [127.699961785, 34.931626695000134],
            [127.66211998800017, 34.906805731000176],
            [127.64112389400012, 34.899115302000055],
            [127.62606855600021, 34.909735419000086],
            [127.60718834700018, 34.941310940000093],
            [127.59156334700018, 34.946437893],
            [127.58065839900021, 34.933661200000145],
            [127.57528730600004, 34.913641669000086],
            [127.57642662900011, 34.896918036000059],
            [127.58570397200006, 34.879217841000141],
            [127.60116621200009, 34.856350002000156],
            [127.61890709700006, 34.836411851000079],
            [127.63477623800011, 34.82802969],
            [127.64600670700011, 34.816066799000069],
            [127.65650475400017, 34.81191640800013],
            [127.66895592500006, 34.818345445000048],
            [127.699961785, 34.842271226000108],
            [127.72144616000006, 34.84463125200007],
            [127.73975670700011, 34.84170156500015],
            [127.75684655000023, 34.840806382000054],
            [127.77564537900017, 34.849066473000121],
            [127.77450605600004, 34.829331773000106],
            [127.77125084700012, 34.815334377000127],
            [127.76132246200021, 34.787054755],
            [127.74984785200016, 34.737127997000144],
            [127.7415470710001, 34.72557200700011],
            [127.70639082100004, 34.71588776200015],
            [127.67855879000015, 34.727362372000115],
            [127.65626061300011, 34.731268622000087],
            [127.63795006600006, 34.698228257000054],
            [127.632090691, 34.701402085000112],
            [127.63062584700006, 34.701117255000085],
            [127.62924238400015, 34.699286200000088],
            [127.624278191, 34.698228257000054],
            [127.632090691, 34.685044664000188],
            [127.63795006600006, 34.67096588700015],
            [127.64527428500017, 34.62628815300009],
            [127.64071699300021, 34.617987372000115],
            [127.63038170700011, 34.622707424000126],
            [127.61402428500017, 34.636867580000128],
            [127.59009850400005, 34.639308986000074],
            [127.57227623800023, 34.647406317],
            [127.560801629, 34.662176825000088],
            [127.55591881600006, 34.68455638200011],
            [127.56202233200023, 34.705715236000017],
            [127.57504316500004, 34.72654857],
            [127.58448326900023, 34.746242580000128],
            [127.57984459700012, 34.763128973000121],
            [127.57178795700005, 34.77252838700015],
            [127.55591881600006, 34.800726630000113],
            [127.55176842500012, 34.80292389500012],
            [127.54615319100012, 34.803045966000084],
            [127.54029381600006, 34.803778387000122],
            [127.53541100400017, 34.808091539000131],
            [127.53492272200018, 34.813218492000132],
            [127.53663170700011, 34.819240627000127],
            [127.53956139400012, 34.823065497000144],
            [127.52442467500012, 34.849269924000097],
            [127.52182050900004, 34.859035549000126],
            [127.522715691, 34.864935614000061],
            [127.52711022200012, 34.874701239],
            [127.52808678500017, 34.879828192000119],
            [127.52588951900006, 34.885443427000112],
            [127.52125084700006, 34.889837958],
            [127.51661217500023, 34.891099351000108],
            [127.51441491000006, 34.886948960000083],
            [127.51099694100006, 34.884711005],
            [127.49390709700018, 34.849066473000121],
            [127.48731530000012, 34.841498114],
            [127.48389733200011, 34.839544989000146],
            [127.47877037900011, 34.839056708000058],
            [127.4446720710001, 34.829169012000037],
            [127.42481530000006, 34.82713450700011],
            [127.38160241000017, 34.82802969],
            [127.36532636800015, 34.824042059000035],
            [127.37850996200009, 34.815130927],
            [127.40430748800023, 34.805853583000143],
            [127.425547722, 34.800726630000113],
            [127.425547722, 34.794419664000188],
            [127.38306725400005, 34.783270575000145],
            [127.361582879, 34.773342190000065],
            [127.35792076900023, 34.759711005000113],
            [127.36646569100017, 34.753973700000145],
            [127.38526451900023, 34.760199286],
            [127.395274285, 34.75665924700003],
            [127.40235436300006, 34.747137762000037],
            [127.40023847700016, 34.743638414000046],
            [127.39486738400004, 34.742499091000113],
            [127.39210045700023, 34.739813544000029],
            [127.40601647200006, 34.70001862200003],
            [127.43490644600016, 34.678697007000082],
            [127.46615644600004, 34.662176825000088],
            [127.48755944100006, 34.636867580000128],
            [127.47730553500006, 34.638169664000046],
            [127.45972741000011, 34.638251044000029],
            [127.45224043100021, 34.636867580000128],
            [127.49520918100009, 34.623236395],
            [127.50075946700022, 34.612163867000035],
            [127.50358985, 34.591324773000011],
            [127.49444022100025, 34.583776608000122],
            [127.48414147200006, 34.576564846000011],
            [127.46461022200018, 34.577337958000115],
            [127.45224043100021, 34.574774481000119],
            [127.40674889400023, 34.598130601000108],
            [127.39210045700023, 34.602036851000108],
            [127.37916100400005, 34.600002346000068],
            [127.36304772200018, 34.5938988300001],
            [127.34937584700006, 34.58584219000015],
            [127.34359785200016, 34.578192450000031],
            [127.34595787900005, 34.575018622000144],
            [127.35792076900023, 34.554266669000143],
            [127.37194040400018, 34.551733359000153],
            [127.38040826900013, 34.54710467500017],
            [127.41841862600009, 34.555280369000101],
            [127.43921959700006, 34.540676174000012],
            [127.39178801300014, 34.504779601000067],
            [127.374940968, 34.485036569000059],
            [127.35661839400021, 34.491964730000078],
            [127.32780172800003, 34.473929461000139],
            [127.33082116000006, 34.456488348000093],
            [127.34359785200016, 34.445013739000146],
            [127.31592550399998, 34.444901020000046],
            [127.2750818010002, 34.489453300000108],
            [127.26099694100017, 34.513332424000126],
            [127.22348066500015, 34.54682038],
            [127.21200605600021, 34.544663804],
            [127.17237389400012, 34.52757396000014],
            [127.1763615240001, 34.517320054000109],
            [127.17920983200011, 34.513332424000126],
            [127.16293379, 34.517971096000068],
            [127.14722741, 34.520493882000082],
            [127.13379967500012, 34.526312567000119],
            [127.12452233200005, 34.540676174000012],
            [127.12330162900017, 34.555365302000112],
            [127.12964928500017, 34.563910223000121],
            [127.15186608200023, 34.574774481000119],
            [127.15902754, 34.580023505],
            [127.17920983200011, 34.602036851000108],
            [127.19166100400005, 34.623602606000119],
            [127.19882246200009, 34.631008205000015],
            [127.21338951900012, 34.636867580000128],
            [127.21241295700023, 34.620184637000094],
            [127.20753014400017, 34.603989976000051],
            [127.19288170700023, 34.574774481000119],
            [127.22217858200005, 34.576361395000148],
            [127.2466740240001, 34.583726304000052],
            [127.26132246200021, 34.600409247000087],
            [127.26099694100017, 34.629950262000065],
            [127.25513756600006, 34.64150625200007],
            [127.24740644600004, 34.648098049000154],
            [127.241465691, 34.655829169000143],
            [127.24057050900004, 34.67096588700015],
            [127.24594160200016, 34.678900458000115],
            [127.27475019600004, 34.705145575000145],
            [127.28516686300011, 34.700628973000093],
            [127.29200280000012, 34.694525458],
            [127.293711785, 34.686712958000115],
            [127.28842207100016, 34.677191473000036],
            [127.29444420700023, 34.664129950000031],
            [127.30298912900017, 34.655015367000047],
            [127.31446373800011, 34.651922919000143],
            [127.32992597700004, 34.656683661000059],
            [127.33643639400023, 34.663641669000143],
            [127.35010826900017, 34.687811591000141],
            [127.35792076900023, 34.698228257000054],
            [127.34400475400017, 34.715277411],
            [127.33863366000006, 34.733099677000084],
            [127.33155358200017, 34.741156317000033],
            [127.31259199300004, 34.728989976000136],
            [127.29078209700018, 34.721380927],
            [127.27271569100017, 34.733791408000016],
            [127.24805748800006, 34.766546942000119],
            [127.23389733200017, 34.748236395000063],
            [127.21371504000004, 34.705552476000051],
            [127.19971764400006, 34.691473700000117],
            [127.18531334700012, 34.686672268000123],
            [127.17310631600006, 34.687201239],
            [127.14804121200021, 34.691473700000117],
            [127.13624108200017, 34.688055731000091],
            [127.125254754, 34.679754950000031],
            [127.10718834700018, 34.660101630000085],
            [127.09913170700023, 34.654486395000063],
            [127.06251061300023, 34.636867580000128],
            [127.04273522200012, 34.624457098000121],
            [127.03003991000006, 34.618353583000115],
            [127.01783287900017, 34.615708726000051],
            [127.01026451900012, 34.612494208],
            [127.008555535, 34.604234117000189],
            [127.00904381600012, 34.59320709800015],
            [127.00717207100004, 34.581529039000046],
            [127.00058027400004, 34.57330963700015],
            [126.99130293100021, 34.564357815000122],
            [126.98666425900009, 34.553697007],
            [126.99415123800023, 34.540676174000012],
            [126.93897545700011, 34.495917059000149],
            [126.94076582099999, 34.454575914000131],
            [126.93140709700018, 34.447699286000116],
            [126.90479576900012, 34.458685614000089],
            [126.89600670700017, 34.449937242000104],
            [126.891856316, 34.440130927000084],
            [126.89234459700012, 34.429388739000061],
            [126.89795983200011, 34.417710679000081],
            [126.885020379, 34.421087958000086],
            [126.85702558700009, 34.437567450000174],
            [126.81861412900017, 34.446844794000029],
            [126.80982506600012, 34.451239325000117],
            [126.802012566, 34.470404364000061],
            [126.80616295700023, 34.521063544000143],
            [126.80233808700021, 34.540676174000012],
            [126.80787194100006, 34.553168036000116],
            [126.80665123800023, 34.568548895000063],
            [126.80005944100017, 34.582017320000048],
            [126.78866621200009, 34.589056708000115],
            [126.77784264400012, 34.586493231000091],
            [126.76693769600004, 34.578029690000065],
            [126.75855553500017, 34.567206122000172],
            [126.75521894600004, 34.55768463700015],
            [126.75928795700011, 34.54385000200007],
            [126.76563561300023, 34.531805731000148],
            [126.76596113400015, 34.523382880000113],
            [126.73511803500017, 34.51487864799999],
            [126.73910566500015, 34.502671617000104],
            [126.76148522200018, 34.479193427000141],
            [126.72315514400023, 34.460842190000093],
            [126.70875084700018, 34.449896552000112],
            [126.72364342500023, 34.445013739000146],
            [126.72706139400023, 34.442775783000158],
            [126.72803795700023, 34.437892971000096],
            [126.72689863399998, 34.433010158000044],
            [126.72364342500023, 34.430812893000123],
            [126.71753991000017, 34.431830145],
            [126.708262566, 34.436468817000119],
            [126.70337975400005, 34.437567450000174],
            [126.69019616000017, 34.435939846000153],
            [126.68751061300011, 34.431341864000032],
            [126.68734785200016, 34.423895575000031],
            [126.68222089900021, 34.414007880000113],
            [126.67440839900021, 34.409654039000131],
            [126.64478600400011, 34.403469143000066],
            [126.6336369150001, 34.395697333000058],
            [126.62964928500011, 34.389227606000148],
            [126.60531660200004, 34.321682033000101],
            [126.60328209700012, 34.311997789000131],
            [126.60328209700012, 34.300441799000012],
            [126.57886803500006, 34.304754950000117],
            [126.53280683700021, 34.292222398000106],
            [126.52125084700018, 34.29417552300005],
            [126.51986738400004, 34.305975653000033],
            [126.52930748800011, 34.330023505000057],
            [126.52743574300004, 34.341376044000029],
            [126.51832116000017, 34.34784577000012],
            [126.5057072270001, 34.349920966000141],
            [126.47958418100021, 34.34882233300003],
            [126.47958418100021, 34.355047919000171],
            [126.48487389400012, 34.360825914000131],
            [126.486582879, 34.364813544000114],
            [126.48853600400017, 34.380560614000061],
            [126.49195397200018, 34.38108958500014],
            [126.49642988400004, 34.37986888200011],
            [126.50074303500006, 34.382310289000074],
            [126.51490319100006, 34.40355052300005],
            [126.51758873800023, 34.413763739000089],
            [126.51376386800021, 34.423976955000015],
            [126.50879967500018, 34.427232164000159],
            [126.50147545700017, 34.429348049000069],
            [126.486582879, 34.430812893000123],
            [126.47584069100017, 34.433254299000069],
            [126.47437584700006, 34.438666083000115],
            [126.47730553500006, 34.443589585000083],
            [126.47958418100021, 34.445013739000146],
            [126.47999108200023, 34.457831122000172],
            [126.48373457100016, 34.470160223000121],
            [126.48194420700005, 34.47675202],
            [126.46591230600009, 34.472357489000117],
            [126.47030683700009, 34.496486721000124],
            [126.49024498800017, 34.50861237200003],
            [126.51685631600012, 34.512844143000152],
            [126.54175866000006, 34.513332424000126],
            [126.5372013680001, 34.521063544000143],
            [126.531016472, 34.527004299000069],
            [126.52320397200006, 34.53115469],
            [126.51376386800021, 34.533840236000103],
            [126.52955162900017, 34.54295482],
            [126.539317254, 34.553615627000013],
            [126.53964277400021, 34.562974351000051],
            [126.52743574300004, 34.568548895000063],
            [126.52295983200011, 34.567084052],
            [126.51392662900017, 34.557806708000143],
            [126.50757897200006, 34.554266669000143],
            [126.49927819100017, 34.554348049000126],
            [126.48633873800017, 34.560939846000039],
            [126.47958418100021, 34.561102606000176],
            [126.47348066500015, 34.556545315000122],
            [126.4693302740001, 34.549709377000013],
            [126.46591230600009, 34.540676174000012],
            [126.45704186300023, 34.53904857],
            [126.448496941, 34.539292710000112],
            [126.43873131600017, 34.540676174000012],
            [126.41732832100004, 34.540350653000175],
            [126.40560957100016, 34.538641669000171],
            [126.39087975400017, 34.533840236000103],
            [126.38900800899998, 34.549709377000013],
            [126.38046308700009, 34.558905341000084],
            [126.35670006600017, 34.568548895000063],
            [126.35702558700009, 34.571722723000121],
            [126.35206139400023, 34.579779364000061],
            [126.34603925900004, 34.584621486000131],
            [126.34302819100006, 34.578192450000031],
            [126.340098504, 34.574367580000015],
            [126.33301842500023, 34.570990302],
            [126.324961785, 34.56964752800009],
            [126.31861412900017, 34.57168203300013],
            [126.29786217500006, 34.588039455000128],
            [126.29525800899998, 34.589056708000115],
            [126.2918400400001, 34.599107164000188],
            [126.2935490240001, 34.601996161000116],
            [126.29761803500006, 34.603338934000092],
            [126.30152428500006, 34.608872789000131],
            [126.30551191500004, 34.604193427000112],
            [126.31552168100009, 34.604071356000034],
            [126.32276451900012, 34.608872789000131],
            [126.31861412900017, 34.619126695000048],
            [126.2918400400001, 34.629950262000065],
            [126.27108808700009, 34.64248281500015],
            [126.26937910200016, 34.671291408000073],
            [126.27808678500017, 34.703314520000063],
            [126.28858483200023, 34.72557200700011],
            [126.2896427740001, 34.733140367000161],
            [126.28882897200018, 34.742417710000112],
            [126.29029381600006, 34.750230210000112],
            [126.29843183700004, 34.753485419000143],
            [126.305918816, 34.752875067],
            [126.313731316, 34.751044012000122],
            [126.31959069100006, 34.747748114000089],
            [126.32618248800023, 34.735825914000046],
            [126.35670006600017, 34.698228257000054],
            [126.34717858200005, 34.687892971000124],
            [126.34424889400023, 34.677883205000157],
            [126.34595787900005, 34.667669989000117],
            [126.34921308700009, 34.656683661000059],
            [126.36988366, 34.608872789000131],
            [126.38233483200023, 34.602728583000143],
            [126.43189537900017, 34.59589264500012],
            [126.45313561300023, 34.583807684000035],
            [126.46461022200018, 34.584662177000112],
            [126.47274824300021, 34.602036851000108],
            [126.46151777400021, 34.600653387000037],
            [126.45557701900006, 34.603094794000143],
            [126.43499714700013, 34.606785903000016],
            [126.42529931200008, 34.61927176900015],
            [126.41256654200006, 34.629766068],
            [126.41610761800021, 34.652736721000068],
            [126.40290177500017, 34.6507478300001],
            [126.39651901099998, 34.636604222000116],
            [126.38613374100012, 34.635835405000094],
            [126.37985478700003, 34.654505542000052],
            [126.37677542400004, 34.679470152000093],
            [126.38437937199998, 34.693698786000184],
            [126.37861288900004, 34.711182163],
            [126.40596947500009, 34.713408013000148],
            [126.44788115700024, 34.705878563000127],
            [126.45333635200004, 34.697883824000158],
            [126.45069420700011, 34.684881903000033],
            [126.45093834700006, 34.680853583000058],
            [126.45053144600016, 34.676988023000078],
            [126.45289147200012, 34.67096588700015],
            [126.46330599400019, 34.665421393000045],
            [126.46936248599999, 34.658674345000108],
            [126.48239978400014, 34.651669889000104],
            [126.50034546499998, 34.64581857600011],
            [126.51119327000006, 34.637154827000117],
            [126.52512592100018, 34.62890229200012],
            [126.53571133000017, 34.62317762500011],
            [126.54004967500023, 34.627590236000103],
            [126.51911134500008, 34.650369807000075],
            [126.53155606200019, 34.652596588000122],
            [126.55003784200019, 34.643830033000043],
            [126.5738725790001, 34.633111353000047],
            [126.60270100300025, 34.625532481],
            [126.6236271490001, 34.629950262000065],
            [126.60254967500018, 34.632879950000174],
            [126.58545983200023, 34.64179108300003],
            [126.55543053500017, 34.663519598000093],
            [126.56275475400017, 34.67096588700015],
            [126.5411076180001, 34.683986721000124],
            [126.50839277400004, 34.718491929000052],
            [126.49024498800017, 34.72557200700011],
            [126.46534264400012, 34.729681708000058],
            [126.43576744100008, 34.728105394000025],
            [126.41537519599999, 34.738999742000104],
            [126.39017860200013, 34.733397455000116],
            [126.36705994600007, 34.739664412000039],
            [126.36401690800008, 34.749160108000027],
            [126.37831687300005, 34.763887706],
            [126.39232840700012, 34.768607452000182],
            [126.42212790700015, 34.764783571000166],
            [126.46014859900006, 34.77875539200015],
            [126.50586998800023, 34.751044012000122],
            [126.52125084700018, 34.753485419000143],
            [126.52515709700018, 34.751288153000147],
            [126.527598504, 34.749253648000135],
            [126.53028405000012, 34.74750397300015],
            [126.534922722, 34.746039130000085],
            [126.53052819100006, 34.765285549000126],
            [126.53248131600017, 34.782863674000154],
            [126.54444420700011, 34.795721747000087],
            [126.56893964900016, 34.800726630000113],
            [126.61475670700023, 34.789455471000124],
            [126.63819420700023, 34.788031317000062],
            [126.63111412900017, 34.800726630000113],
            [126.6465763680001, 34.807114976000051],
            [126.65487714900004, 34.808539130000113],
            [126.66529381600006, 34.808091539000131],
            [126.65040123800011, 34.822170315000065],
            [126.62769616000006, 34.825588283000073],
            [126.57577558700009, 34.821763414000074],
            [126.57968183700004, 34.831447658000016],
            [126.57837975400017, 34.8380394550001],
            [126.57577558700009, 34.844875393],
            [126.57577558700009, 34.855292059000092],
            [126.57943769600004, 34.861883856000176],
            [126.58472741000011, 34.866766669000143],
            [126.58912194100017, 34.872870184000035],
            [126.58944746200021, 34.883246161000116],
            [126.58139082100016, 34.898016669000114],
            [126.56763756600006, 34.901434637000122],
            [126.55274498800011, 34.895697333000143],
            [126.54175866000006, 34.883246161000116],
            [126.54053795700023, 34.874945380000057],
            [126.54712975400011, 34.858099677000055],
            [126.54859459700006, 34.849066473000121],
            [126.54688561300023, 34.847723700000145],
            [126.534922722, 34.82802969],
            [126.52369225400005, 34.793931382],
            [126.51327558700021, 34.782294012000094],
            [126.49341881600006, 34.780829169000029],
            [126.49341881600006, 34.783148505],
            [126.4871525400001, 34.787054755],
            [126.49122155000023, 34.792385158000158],
            [126.49659264400012, 34.802801825000145],
            [126.50074303500006, 34.808091539000131],
            [126.4871525400001, 34.81378815300009],
            [126.47803795700011, 34.810451565000065],
            [126.46924889400012, 34.80410390800013],
            [126.45606530000018, 34.800726630000113],
            [126.42945397200006, 34.805609442],
            [126.42099043100009, 34.802313544000143],
            [126.41931790800018, 34.791988179000086],
            [126.40560957100016, 34.792181708],
            [126.38404381600006, 34.786322333000058],
            [126.37354576900017, 34.790716864000146],
            [126.37012780000023, 34.801336981000176],
            [126.37728925900004, 34.811265367000189],
            [126.39771569100017, 34.82802969],
            [126.40512129000015, 34.837225653000175],
            [126.40927168100021, 34.844468492000104],
            [126.41098066500004, 34.851263739000117],
            [126.411387566, 34.859035549000126],
            [126.40870201900023, 34.87396881700009],
            [126.41211998800023, 34.881333726000079],
            [126.42505944100006, 34.890692450000117],
            [126.41260826900023, 34.898749091000141],
            [126.40170332100016, 34.915472723000065],
            [126.39576256600006, 34.935044664000046],
            [126.39771569100017, 34.951483466000056],
            [126.41163170700023, 34.961981512000094],
            [126.43051191499998, 34.972601630000057],
            [126.43637129000004, 34.981390692000119],
            [126.411387566, 34.986273505],
            [126.39999433700004, 34.984564520000092],
            [126.39332116000017, 34.979803778000175],
            [126.38795006600006, 34.97483958500014],
            [126.38038170700011, 34.972601630000057],
            [126.37061608200023, 34.968491929000024],
            [126.37208092500012, 34.958726304000052],
            [126.37696373800006, 34.947251695000105],
            [126.37728925900004, 34.937892971000096],
            [126.36329186300023, 34.927435614],
            [126.34522545700023, 34.926418361000131],
            [126.32772871200009, 34.93109772300015],
            [126.31519616000017, 34.937892971000096],
            [126.32471764400017, 34.943264065000122],
            [126.328868035, 34.944728908000101],
            [126.32007897200006, 34.949896552000027],
            [126.3125919930001, 34.95612213700015],
            [126.30640709700012, 34.963568427000141],
            [126.30152428500006, 34.972601630000057],
            [126.31299889400006, 34.977443752000127],
            [126.32422936300006, 34.976955471000124],
            [126.34921308700009, 34.972601630000057],
            [126.34620201900012, 34.994086005000085],
            [126.37411543100015, 35.016831773000106],
            [126.36670983200023, 35.037787177000055],
            [126.33822675900015, 35.070135809000178],
            [126.32064863400004, 35.083319403000033],
            [126.30152428500006, 35.088690497000087],
            [126.30941816500004, 35.066229559000178],
            [126.302500847, 35.05337148600016],
            [126.28834069100017, 35.052964585000055],
            [126.27418053500017, 35.068182684000035],
            [126.28353925899998, 35.078843492000161],
            [126.25782311300023, 35.106838283000073],
            [126.25424238400009, 35.123439846000124],
            [126.26864668100004, 35.137152411000059],
            [126.28907311300011, 35.139390367000047],
            [126.30722089900021, 35.131781317000033],
            [126.31519616000017, 35.115952867000161],
            [126.32789147200006, 35.122626044000029],
            [126.33497155000012, 35.134100653000175],
            [126.34302819100006, 35.156968492000161],
            [126.35010826900012, 35.145575262000122],
            [126.35141035199999, 35.13597239800005],
            [126.34872480600021, 35.117499091000028],
            [126.34424889400023, 35.108140367000161],
            [126.34302819100006, 35.10236237200003],
            [126.34498131600017, 35.096421617000189],
            [126.34896894600016, 35.096421617000189],
            [126.3536076180001, 35.097845770000063],
            [126.35670006600017, 35.096136786000059],
            [126.36068769600016, 35.082953192000033],
            [126.36166425900004, 35.077297268000123],
            [126.3653263680001, 35.074042059000178],
            [126.37728925900004, 35.068182684000035],
            [126.38697350400017, 35.06537506700009],
            [126.40381920700017, 35.064886786],
            [126.411387566, 35.061346747000115],
            [126.40560957100016, 35.049953518000152],
            [126.40723717500018, 35.038967190000093],
            [126.41439863400004, 35.02875397300015],
            [126.42505944100006, 35.01976146000014],
            [126.45028730600021, 35.06659577],
            [126.45289147200012, 35.082464911000116],
            [126.452403191, 35.100287177],
            [126.44752037900005, 35.106675523000106],
            [126.42872155000012, 35.110541083000115],
            [126.42074629000015, 35.11420319200009],
            [126.4141544930001, 35.119696356000119],
            [126.40390058700015, 35.142157294000114],
            [126.37110436300023, 35.173529364000146],
            [126.36353600400017, 35.187974351000108],
            [126.36573326900006, 35.192857164000159],
            [126.37037194100012, 35.196966864000117],
            [126.37509199300021, 35.202378648000078],
            [126.37728925900004, 35.211615302000141],
            [126.36670983200023, 35.217718817000119],
            [126.36158287900005, 35.225653387000179],
            [126.36353600400017, 35.23948802299999],
            [126.35067793100004, 35.235337632000054],
            [126.33741295700023, 35.233587958000058],
            [126.30892988400004, 35.233303127000013],
            [126.32007897200006, 35.244574286000031],
            [126.33838951900012, 35.248032945000105],
            [126.35914147200012, 35.248968817],
            [126.37728925900004, 35.25259023600016],
            [126.37134850400017, 35.254461981000119],
            [126.36817467500012, 35.256740627000099],
            [126.36451256600006, 35.258734442000119],
            [126.35670006600017, 35.25999583500014],
            [126.36158287900005, 35.269029039000046],
            [126.36744225400017, 35.274318752000127],
            [126.37387129, 35.278387762000179],
            [126.38038170700011, 35.284165757000054],
            [126.38379967500012, 35.291734117000189],
            [126.38217207100016, 35.298285223000093],
            [126.37899824300021, 35.304592190000122],
            [126.37728925900004, 35.31150950700011],
            [126.38070722700004, 35.327093817000033],
            [126.38941491000011, 35.342474677000055],
            [126.411387566, 35.369859117000104],
            [126.41724694100006, 35.364894924000069],
            [126.43189537900017, 35.349351304000052],
            [126.44955488400009, 35.34495677299999],
            [126.45850670700011, 35.345445054000081],
            [126.45606530000018, 35.352769273000078],
            [126.42310631600012, 35.383368231000091],
            [126.41822350400005, 35.394029039000131],
            [126.42554772200006, 35.417669989000146],
            [126.44271894600016, 35.446926174000069],
            [126.46981423500009, 35.498526407000114],
            [126.49266327900014, 35.525333007000071],
            [126.52528299100013, 35.534857697000135],
            [126.56244996899997, 35.541087444000098],
            [126.59423270099998, 35.542487712000039],
            [126.608894295, 35.552056091000011],
            [126.62297749100017, 35.56861206000012],
            [126.64288939900021, 35.572774993000124],
            [126.6607365240001, 35.556463934000035],
            [126.67904707100016, 35.538031317],
            [126.69247480600004, 35.534369208000143],
            [126.67945397200018, 35.571722723],
            [126.67017662900005, 35.588690497000172],
            [126.65503991000006, 35.595770575000031],
            [126.51315778100016, 35.576896518000169],
            [126.46373599600022, 35.601972857000121],
            [126.46674363400021, 35.628874691000036],
            [126.46755899100017, 35.639638447000053],
            [126.49097741000017, 35.649115302],
            [126.51141569500024, 35.66553251100008],
            [126.53033472200005, 35.683164305],
            [126.56034574700007, 35.697391795],
            [126.59716770100007, 35.713890194000143],
            [126.62513225000012, 35.745625835000155],
            [126.62938900800023, 35.775480579000046],
            [126.64475472000018, 35.782901804000076],
            [126.65611672100013, 35.788735721000151],
            [126.66215366800012, 35.792998345000157],
            [126.698022947, 35.795939459000138],
            [126.73312722800014, 35.792415051000106],
            [126.75538170700011, 35.778469143000152],
            [126.77133222700004, 35.775213934000092],
            [126.78199303500006, 35.760239976000079],
            [126.78614342500006, 35.768377997000087],
            [126.78956139400012, 35.779689846000068],
            [126.79102623800023, 35.791449286000145],
            [126.77766824800008, 35.800092446000022],
            [126.76846474400006, 35.805555327000135],
            [126.74934413200006, 35.813257440000044],
            [126.72762228500019, 35.825278498],
            [126.70997155000012, 35.836004950000031],
            [126.70069420700017, 35.839829820000048],
            [126.70337975400005, 35.848456122000144],
            [126.711680535, 35.857733466000113],
            [126.71998131600017, 35.863267320000134],
            [126.7290958990001, 35.864976304000137],
            [126.78492272200018, 35.862046617000104],
            [126.79712975400005, 35.862860419000029],
            [126.80616295700023, 35.866685289000046],
            [126.81226647200006, 35.875677802000055],
            [126.81454511800021, 35.883042710000055],
            [126.81934655000012, 35.888128973000065],
            [126.83309980600021, 35.89004140800013],
            [126.83749433700009, 35.893296617000161],
            [126.832286004, 35.900620835],
            [126.82162519600016, 35.908148505000142],
            [126.80982506600012, 35.911688544000029],
            [126.80665123800023, 35.909654039000188],
            [126.73316491000006, 35.885565497000172],
            [126.70850670700023, 35.884588934000092],
            [126.66009099600015, 35.887157801000015],
            [126.61228893200004, 35.890224220000064],
            [126.61183815100017, 35.907439668],
            [126.60880930300019, 35.931126764000126],
            [126.59957573500006, 35.937653630000156],
            [126.56973974000019, 35.945951984000104],
            [126.52573565800017, 35.940358909000068],
            [126.52141673600013, 35.970520003],
            [126.61204597100001, 35.978430089000184],
            [126.66133514700007, 35.983398648000147],
            [126.71119225400005, 35.985785223000065],
            [126.72730553500011, 35.993638414000074],
            [126.74447675900004, 35.98956940300009],
            [126.76547285200016, 35.998277085],
            [126.80233808700021, 36.020941473000121],
            [126.84913170700011, 36.036037502000127],
            [126.86890709700006, 36.047512111000103],
            [126.87069746200021, 36.06195709800015],
            [126.81210371200009, 36.047308661000145],
            [126.79151451900023, 36.042181708000115],
            [126.77133222700004, 36.030910549000126],
            [126.75440514400023, 36.017726955000072],
            [126.73568769600016, 36.010443427000084],
            [126.71338951900012, 36.007391669000171],
            [126.69247480600004, 35.99990469],
            [126.67725670700005, 36.005926825000117],
            [126.67212975400017, 36.012884833],
            [126.67050214900016, 36.022040106000176],
            [126.66529381600006, 36.034613348000065],
            [126.65699303500017, 36.045477606000148],
            [126.64014733200005, 36.063421942],
            [126.63111412900017, 36.075588283000101],
            [126.64503014400023, 36.084702867000161],
            [126.63851972700016, 36.090969143000123],
            [126.62484785200004, 36.095607815000065],
            [126.61232836700006, 36.098992442000068],
            [126.58944746200021, 36.130804755000113],
            [126.57186865200012, 36.136978742000068],
            [126.55857335600015, 36.130763698000081],
            [126.55228153900012, 36.130808778000088],
            [126.55176768800007, 36.141822647000097],
            [126.52917225600001, 36.153514132000154],
            [126.50964758000023, 36.151024779000139],
            [126.50357948600018, 36.13219012500015],
            [126.50025475400005, 36.125718492000189],
            [126.49341881600006, 36.130804755000113],
            [126.49740117200011, 36.160018682000029],
            [126.53135681300014, 36.17393972800015],
            [126.5613412380001, 36.18158844],
            [126.60328209700012, 36.171820380000113],
            [126.58847089900021, 36.191595770000148],
            [126.58008873800006, 36.199367580000128],
            [126.56893964900016, 36.205959377000042],
            [126.56226647200018, 36.20722077000012],
            [126.55429121200015, 36.203436591000113],
            [126.54859459700006, 36.205959377000042],
            [126.5411076180001, 36.212551174000097],
            [126.534922722, 36.219631252000156],
            [126.55591881600017, 36.26626211100016],
            [126.56796308700009, 36.286810614000032],
            [126.5826115240001, 36.294663804],
            [126.57154381600006, 36.305609442000062],
            [126.52906334700018, 36.316107489000117],
            [126.51376386800021, 36.322658596000011],
            [126.52881920700023, 36.333889065000037],
            [126.57333418100021, 36.342596747000087],
            [126.5826115240001, 36.353013414000188],
            [126.57618248800006, 36.366278387000122],
            [126.56039472700004, 36.369614976000051],
            [126.52743574300004, 36.369777736000017],
            [126.52059980600015, 36.373602606000034],
            [126.50879967500018, 36.385199286000145],
            [126.50074303500006, 36.390936591000113],
            [126.50074303500006, 36.39769114800005],
            [126.51376386800021, 36.39769114800005],
            [126.49877937599999, 36.420105859000145],
            [126.51689530000013, 36.428187147000173],
            [126.53753659200001, 36.433220901000098],
            [126.55677759000017, 36.455829834],
            [126.59795991900003, 36.465072646000053],
            [126.61744225400005, 36.473456122000115],
            [126.55909264400023, 36.473456122000115],
            [126.5450138680001, 36.466986395000035],
            [126.52344811300006, 36.441107489],
            [126.50757897200006, 36.438666083000058],
            [126.49765058700004, 36.448391018000038],
            [126.48853600400017, 36.46686432500006],
            [126.48275800899998, 36.485825914000046],
            [126.48340905000023, 36.497015692000062],
            [126.50424238400004, 36.516669012000122],
            [126.51392662900017, 36.528794664000102],
            [126.51075280000012, 36.534247137000065],
            [126.49157989400013, 36.528669687000061],
            [126.48292076900023, 36.535223700000031],
            [126.47507125300004, 36.539414438000122],
            [126.4703285210002, 36.540198793000101],
            [126.46568460600005, 36.549387396000085],
            [126.47005864200005, 36.557766204000032],
            [126.4692175070002, 36.566933745000043],
            [126.46418202800012, 36.584120884000143],
            [126.45809519600007, 36.591406820000188],
            [126.45765695400016, 36.595595665000118],
            [126.47145646900017, 36.596291460000131],
            [126.49145308100012, 36.603761955000081],
            [126.51231193700008, 36.603209348000078],
            [126.5138679730002, 36.615391621000143],
            [126.51986738400004, 36.626166083000086],
            [126.51171037600002, 36.63484833299999],
            [126.49985528800002, 36.63683581500014],
            [126.48379869000021, 36.649603414000055],
            [126.4939559960001, 36.661316129000156],
            [126.50803023500006, 36.670413284000048],
            [126.49851049800023, 36.684273433],
            [126.47453515599997, 36.687885090000137],
            [126.46968683200012, 36.704587037000167],
            [126.47734917200003, 36.716888123],
            [126.50074303500006, 36.719875393000095],
            [126.48731530000023, 36.732163804000052],
            [126.482676629, 36.738348700000117],
            [126.47958418100021, 36.747137762000179],
            [126.47120201900017, 36.74140045800003],
            [126.45704186300023, 36.72898997599999],
            [126.441172722, 36.723537502000127],
            [126.43799889400023, 36.716457424000069],
            [126.43856855600021, 36.709418036000031],
            [126.44563611300012, 36.700721628000068],
            [126.44688039700023, 36.683761865000136],
            [126.43951348800024, 36.664265281],
            [126.44390738399997, 36.638081079000145],
            [126.43328998600006, 36.615726512000052],
            [126.42423834400003, 36.606581370000114],
            [126.40572781000017, 36.618195758000084],
            [126.3863910370001, 36.618887706000137],
            [126.36714982900006, 36.629065495000177],
            [126.37196876700014, 36.683077732000115],
            [126.37790876900013, 36.702581756000157],
            [126.36642243100007, 36.706971539],
            [126.36988366, 36.732326565],
            [126.35914147200012, 36.742661851000108],
            [126.34302819100006, 36.740383205000128],
            [126.33399498800011, 36.727362372000144],
            [126.33155358200011, 36.711371161000059],
            [126.32431074300004, 36.699652411000059],
            [126.30152428500006, 36.699367580000128],
            [126.31088300900015, 36.683986721000096],
            [126.32911217500023, 36.660589911000031],
            [126.335703972, 36.644110419000029],
            [126.32471764400017, 36.63662344000015],
            [126.32618248800023, 36.628363348000065],
            [126.34302819100006, 36.610012111000017],
            [126.32772871200009, 36.607570705000072],
            [126.31478925900015, 36.601629950000145],
            [126.30396569100017, 36.593085028000033],
            [126.29525800899998, 36.582709052000141],
            [126.29542076900023, 36.59003327000012],
            [126.29428144600004, 36.597479559000121],
            [126.29200280000023, 36.604234117000047],
            [126.28858483200023, 36.610012111000017],
            [126.30233808700004, 36.634426174000069],
            [126.27295983200023, 36.67316315300009],
            [126.28174889400012, 36.69257233300003],
            [126.27418053500017, 36.719875393000095],
            [126.25570722700004, 36.711574611000017],
            [126.22925866000017, 36.684719143000123],
            [126.20972741000017, 36.678900458000086],
            [126.19825280000023, 36.67706940300009],
            [126.18799889400012, 36.673814195000048],
            [126.17774498800006, 36.673244533000073],
            [126.16561933700009, 36.678900458000086],
            [126.16268964900004, 36.68353913],
            [126.15853925900004, 36.692857164000046],
            [126.15699303500017, 36.702053127000013],
            [126.16187584700006, 36.706203518000152],
            [126.20313561300011, 36.70587799700003],
            [126.21363366000017, 36.711615302],
            [126.21338951900023, 36.726711330000015],
            [126.20264733200011, 36.742417710000083],
            [126.18726647200018, 36.748480536000059],
            [126.171153191, 36.752264716000084],
            [126.15821373800011, 36.760809637000122],
            [126.15308678500017, 36.748846747000087],
            [126.15015709700018, 36.723944403000147],
            [126.14454186300017, 36.712388414000131],
            [126.13282311300006, 36.703843492000189],
            [126.12891686300006, 36.711900132000139],
            [126.13013756600012, 36.736965236000131],
            [126.12818444100006, 36.761297919000114],
            [126.13095136800021, 36.768052476000136],
            [126.15658613400009, 36.79417552299999],
            [126.16163170700011, 36.803290106000176],
            [126.16561933700009, 36.816066799000126],
            [126.16879316500015, 36.806382554000081],
            [126.17310631600006, 36.797674872000087],
            [126.17847741, 36.789455471000096],
            [126.18539472700004, 36.781317450000174],
            [126.19068444100017, 36.785305080000128],
            [126.19727623800017, 36.784898179000137],
            [126.204844597, 36.782782294000029],
            [126.21338951900023, 36.781317450000174],
            [126.20289147200006, 36.793198960000112],
            [126.19223066500004, 36.808579820000077],
            [126.22071373800023, 36.801743882000139],
            [126.23243248800023, 36.804185289000159],
            [126.24000084700018, 36.816066799000126],
            [126.21444746200009, 36.817531643000095],
            [126.19874108200023, 36.823228257000082],
            [126.19686933700004, 36.835679429],
            [126.21338951900023, 36.857001044000143],
            [126.19076582100016, 36.87201569200009],
            [126.19223066500004, 36.885931708000058],
            [126.20948326900023, 36.897162177000055],
            [126.23389733200011, 36.904201565000122],
            [126.22754967500023, 36.87978750200007],
            [126.22999108200011, 36.873032945000048],
            [126.24374433700021, 36.870672919000086],
            [126.26856530000023, 36.871039130000028],
            [126.27523847700004, 36.874741929000052],
            [126.28174889400012, 36.883734442000062],
            [126.28353925899998, 36.906236070000077],
            [126.28101647200018, 36.936468817000062],
            [126.28565514400012, 36.961127020000063],
            [126.30892988400004, 36.966864325000031],
            [126.305918816, 36.953070380000113],
            [126.30665123800023, 36.931463934000121],
            [126.31104576900023, 36.909572658000101],
            [126.32349694100006, 36.883246161000088],
            [126.31739342500023, 36.873968817000119],
            [126.30762780000012, 36.865668036000145],
            [126.30152428500006, 36.857001044000143],
            [126.30184980600004, 36.847805080000072],
            [126.30811608200011, 36.833075262000094],
            [126.30892988400004, 36.82908763200011],
            [126.30347741000017, 36.824367580000015],
            [126.28549238400015, 36.81492747599999],
            [126.28174889400012, 36.812323309000092],
            [126.28272545700011, 36.803697007],
            [126.28598066500004, 36.794582424000012],
            [126.291270379, 36.78925202000012],
            [126.29843183700004, 36.791896877000042],
            [126.31527754000015, 36.804348049000126],
            [126.32789147200006, 36.804632880000085],
            [126.35670006600017, 36.788763739000146],
            [126.33602949300004, 36.824652411000145],
            [126.32870527400016, 36.846991278000175],
            [126.33936608200005, 36.857001044000143],
            [126.35547936300023, 36.840480861000074],
            [126.3653263680001, 36.835353908000158],
            [126.36988366, 36.846177476000051],
            [126.37411543100015, 36.849554755000085],
            [126.384532097, 36.852932033000101],
            [126.40455162900011, 36.857001044000143],
            [126.40455162900011, 36.863226630000113],
            [126.39657636800021, 36.86420319200009],
            [126.37728925900004, 36.870672919000086],
            [126.37728925900004, 36.876898505000057],
            [126.40137780000012, 36.881415106000091],
            [126.41553795700023, 36.899847723000121],
            [126.41920006600012, 36.922308661000145],
            [126.411387566, 36.938910223],
            [126.40267988400004, 36.941229559000149],
            [126.37647545700011, 36.941595770000092],
            [126.36353600400017, 36.945786851000108],
            [126.35385175900004, 36.955226955000043],
            [126.35067793100004, 36.964504299000069],
            [126.35547936300023, 36.96775950700011],
            [126.36988366, 36.959418036000059],
            [126.37647545700011, 36.97492096600017],
            [126.36939537900005, 36.984116929000137],
            [126.35401451900006, 36.987860419000171],
            [126.335703972, 36.986761786000116],
            [126.34310957100004, 36.994452216000141],
            [126.35303795700011, 36.999986070000077],
            [126.36378014400023, 37.001450914000131],
            [126.37354576900017, 36.997015692000147],
            [126.38306725400017, 36.991156317],
            [126.39144941500004, 36.989813544000029],
            [126.39527428500006, 36.992905992000104],
            [126.39087975400017, 37.000433661000059],
            [126.39087975400017, 37.007879950000031],
            [126.42774498800023, 37.008368231000034],
            [126.44540449300021, 37.003404039000159],
            [126.45289147200012, 36.990179755000142],
            [126.4478458990001, 36.982001044000029],
            [126.43824303500011, 36.973334052000141],
            [126.43238366000006, 36.96198151200015],
            [126.43873131600017, 36.945786851000108],
            [126.44752037900005, 36.952826239000089],
            [126.45883222700004, 36.957749742000047],
            [126.46859785200016, 36.957586981000176],
            [126.47274824300021, 36.949204820000105],
            [126.47388756600006, 36.94220612200003],
            [126.47852623800006, 36.935858466000113],
            [126.47958418100021, 36.92902252800009],
            [126.47779381600006, 36.926418361000103],
            [126.46876061300023, 36.917792059000178],
            [126.46591230600009, 36.911607164000102],
            [126.46591230600009, 36.866929429000052],
            [126.46924889400012, 36.853989976000051],
            [126.47657311300023, 36.844916083000058],
            [126.48389733200023, 36.841498114000146],
            [126.4871525400001, 36.846177476000051],
            [126.49122155000023, 36.87107982000002],
            [126.50953209700018, 36.918850002000127],
            [126.51376386800021, 36.942368882],
            [126.51913496200009, 36.947902736000017],
            [126.53126061300006, 36.94131094000015],
            [126.54859459700006, 36.925930080000015],
            [126.55567467500012, 36.915106512000094],
            [126.56080162900005, 36.90509674700003],
            [126.56836998800006, 36.898504950000145],
            [126.5826115240001, 36.898016669000143],
            [126.5826115240001, 36.904201565000122],
            [126.57545006600006, 36.911810614000061],
            [126.56674238400015, 36.932196356000148],
            [126.55909264400023, 36.942368882],
            [126.497813347, 36.994208075000088],
            [126.49341881600006, 36.997015692000147],
            [126.49683678500006, 37.007798570000048],
            [126.50538170700017, 37.006577867000047],
            [126.51563561300006, 36.99884674700003],
            [126.53158613399998, 36.982896226000108],
            [126.54004967500023, 36.976874091000113],
            [126.55030358200017, 36.97313060099999],
            [126.56275475400017, 36.973089911],
            [126.57211347700004, 36.977484442000062],
            [126.57300866, 36.98436107],
            [126.56617272200018, 36.990790106000091],
            [126.55201256600017, 36.99359772300015],
            [126.53296959700018, 37.000921942000147],
            [126.51225445700018, 37.01643034000007],
            [126.502920773, 37.039520921000033],
            [126.50074303500006, 37.054999091000028],
            [126.51905358200011, 37.055405992000047],
            [126.53500410199999, 37.049261786000059],
            [126.54932701900006, 37.0392113300001],
            [126.56934144700008, 37.027777516000143],
            [126.58845236900018, 37.017221133000035],
            [126.59629238700003, 37.010827787000053],
            [126.61146668700005, 37.002560097000142],
            [126.62609486699998, 36.996104465000158],
            [126.63609541000019, 36.98245312400006],
            [126.6313826790001, 36.968930852000042],
            [126.62167402400016, 36.959784247000172],
            [126.6236271490001, 36.952622789000131],
            [126.63355553500011, 36.93740469000015],
            [126.63477623800011, 36.928045966000113],
            [126.63111412900017, 36.911607164000102],
            [126.64210045700011, 36.917710679000109],
            [126.64649498800011, 36.92511627800009],
            [126.64918053500017, 36.933417059000149],
            [126.64537545700003, 36.95795322],
            [126.6494489610001, 36.966056461000065],
            [126.65579253700005, 36.974593633000083],
            [126.6650431530002, 36.98852457200006],
            [126.68154445400003, 36.994704069000093],
            [126.69703209700012, 36.994818427000055],
            [126.71434831600007, 36.993042063000175],
            [126.7335123940002, 36.987430872000161],
            [126.7577669460002, 36.982219859000011],
            [126.775157097, 36.973089911],
            [126.79623457100016, 36.955755927],
            [126.79769941500004, 36.934393622000144],
            [126.78199303500006, 36.891180731000063],
            [126.79395592500006, 36.897528387000179],
            [126.81275475400005, 36.91693756700009],
            [126.82300866000006, 36.918443101000136],
            [126.8301701180001, 36.910223700000031],
            [126.83432050900004, 36.895493882000054],
            [126.83651777400021, 36.866929429000052],
            [126.83545983200005, 36.860825914000159],
            [126.83082116000006, 36.847235419000114],
            [126.82984459700018, 36.839341539000046],
            [126.831797722, 36.831122137000037],
            [126.84066816500015, 36.819159247000115],
            [126.84913170700011, 36.788031317000119],
            [126.84628339900004, 36.779120184000178],
            [126.82984459700018, 36.775091864000032],
            [126.83285566500015, 36.76666901200015],
            [126.83676191500015, 36.760931708],
            [126.84253991000006, 36.757025458],
            [126.850840691, 36.753973700000088],
            [126.85670006600006, 36.776434637000094],
            [126.86654707100016, 36.789780992000104],
            [126.87159264400012, 36.804022528000118],
            [126.86329186300023, 36.82908763200011],
            [126.88355553500006, 36.824367580000015],
            [126.90479576900012, 36.82225169500002],
            [126.87045332100016, 36.852769273000135],
            [126.86019941500015, 36.869574286000145],
            [126.880625847, 36.876898505000057],
            [126.89901777400004, 36.879339911000088],
            [126.94247480600021, 36.89337799700003],
            [126.97445722699999, 36.912909247000115],
            [126.989512566, 36.918605861000103],
            [127.00033613400004, 36.928371486000131],
            [127.00717207100004, 36.952622789000131],
            [127.0052189460001, 36.956935940000122],
            [126.99594160200004, 36.964789130000113],
            [126.99415123800023, 36.966864325000031],
            [126.99952233200011, 36.970892645],
            [127.00489342500023, 36.971421617000161],
            [127.01002037900017, 36.971258856000119],
            [127.02393639400023, 36.97744375200007],
            [127.03280683700009, 36.978501695000134],
            [127.03939863400009, 36.982001044000029],
            [127.04200280000018, 36.99359772300015],
            [126.982188347, 36.980169989000146],
            [126.97315514400012, 36.969956773000106],
            [126.97632897200018, 36.939032294000171],
            [126.96908613400015, 36.932033596000096],
            [126.94581139400023, 36.925930080000015],
            [126.9126896490001, 36.929632880000142],
            [126.87338300899998, 36.947984117000189],
            [126.83790123800011, 36.975897528000033],
            [126.82440967100015, 37.002616176000075],
            [126.88428795700005, 37.007879950000031],
            [126.88428795700005, 37.014105536],
            [126.88013756600006, 37.015692450000031],
            [126.87069746200021, 37.02147044499999],
            [126.88192793100021, 37.022650458000115],
            [126.89258873800023, 37.022162177000141],
            [126.90235436300011, 37.019476630000057],
            [126.91098066499998, 37.014105536],
            [126.90674889400006, 37.033148505],
            [126.89307701900023, 37.050116278000147],
            [126.88314863400004, 37.069566148000078],
            [126.89047285200004, 37.095933335000055],
            [126.87916100400017, 37.100002346000039],
            [126.87045332100016, 37.090765692000062],
            [126.86508222700004, 37.074855861000131],
            [126.86329186300023, 37.058783270000063],
            [126.85865319100012, 37.041327216000084],
            [126.84913170700011, 37.03729889500012],
            [126.83969160199999, 37.044867255000085],
            [126.820567254, 37.041693427000112],
            [126.80128014400006, 37.037746486000046],
            [126.75521894600004, 37.048814195000077],
            [126.75552653200012, 37.060141064000149],
            [126.75119053500023, 37.070092373000179],
            [126.75481395200021, 37.083556184000045],
            [126.75285886400016, 37.100687967000098],
            [126.76445101400006, 37.117657804000046],
            [126.77826835000005, 37.132804006000029],
            [126.79418169300018, 37.1389203],
            [126.81120990000002, 37.144120531000155],
            [126.83651777400021, 37.144435940000122],
            [126.86353600400017, 37.150295315000093],
            [126.86915123800006, 37.156968492000132],
            [126.87069746200021, 37.175482489000146],
            [126.86451256600006, 37.179103908000016],
            [126.85059655000018, 37.173041083000115],
            [126.82984459700018, 37.158677476000136],
            [126.81690514400017, 37.160793361000131],
            [126.80738366000017, 37.165269273000106],
            [126.80225670700023, 37.17316315300009],
            [126.80233808700021, 37.185370184000149],
            [126.77857506600006, 37.17568594],
            [126.75928795700011, 37.164699611000131],
            [126.72364342500023, 37.133856512000179],
            [126.71444746200009, 37.127997137000037],
            [126.70289147200006, 37.124701239],
            [126.69214928500006, 37.124986070000134],
            [126.68563886800015, 37.13011302300005],
            [126.68604576900023, 37.139308986000017],
            [126.69214928500006, 37.148260809000149],
            [126.70687910200004, 37.164862372000115],
            [126.67774498800006, 37.159491278000147],
            [126.66846764400012, 37.161810614000117],
            [126.66529381600006, 37.175482489000146],
            [126.67066491, 37.185614325000117],
            [126.68287194100017, 37.191351630000085],
            [126.69678795700017, 37.195135809000092],
            [126.70687910200004, 37.199652411000059],
            [126.71900475400005, 37.21287669500002],
            [126.71583092500023, 37.216294664000102],
            [126.68905683700004, 37.213324286],
            [126.68018639400012, 37.217678127000099],
            [126.67318769600004, 37.228420315],
            [126.66651451900017, 37.241848049000097],
            [126.65845787900005, 37.254299221000011],
            [126.66293379, 37.255316473000065],
            [126.66578209700006, 37.256903387000094],
            [126.67212975400017, 37.26170482000002],
            [126.67945397200018, 37.25722890800013],
            [126.69052168100021, 37.257717190000122],
            [126.70997155000012, 37.26170482000002],
            [126.72584069100006, 37.258205471000011],
            [126.73910566500015, 37.251654364000117],
            [126.75220787900005, 37.248277085000026],
            [126.76758873800006, 37.254299221000011],
            [126.76636803500017, 37.243150132],
            [126.76758873800006, 37.232326565],
            [126.77247155000023, 37.223944403000033],
            [126.78199303500006, 37.220160223],
            [126.79249108200011, 37.223456122000144],
            [126.79737389400006, 37.232855536000088],
            [126.80233808700021, 37.254299221000011],
            [126.81918379000004, 37.274400132000054],
            [126.83301842500023, 37.272853908000016],
            [126.84864342500012, 37.265326239000061],
            [126.87069746200021, 37.26788971600017],
            [126.86597741, 37.276068427000084],
            [126.85987389400012, 37.28213125200007],
            [126.85214277400021, 37.286200262000037],
            [126.84278405000006, 37.288397528000118],
            [126.82889134800004, 37.287984005000098],
            [126.82254447400013, 37.295606773000102],
            [126.80760269700025, 37.297765931000143],
            [126.7955596300001, 37.294724614000032],
            [126.78357307900015, 37.295267564000099],
            [126.76464317200006, 37.298266746000095],
            [126.73331008900007, 37.306170435000084],
            [126.689792719, 37.332486420000023],
            [126.68748790400011, 37.34402775299999],
            [126.70686509900023, 37.366468577000106],
            [126.72004509100023, 37.377053382000057],
            [126.71716238899998, 37.383838776000076],
            [126.66475029200004, 37.389168229000134],
            [126.64034014200004, 37.364389566000128],
            [126.61508749300006, 37.379744927],
            [126.62538212000018, 37.397918345000093],
            [126.63862853900017, 37.413790562000102],
            [126.6303083940002, 37.425012798000139],
            [126.61240549000004, 37.432740637000151],
            [126.59598521600006, 37.439656589000109],
            [126.59829071400011, 37.45792753799999],
            [126.59501803700007, 37.472273455000121],
            [126.60476098200016, 37.485696540000092],
            [126.63344429000014, 37.492570852000156],
            [126.64202197400019, 37.496062212000126],
            [126.64416298100016, 37.504385171000152],
            [126.63067522600025, 37.507301053],
            [126.62702516200014, 37.498594657000027],
            [126.60645123400005, 37.496811747000137],
            [126.60110577300017, 37.506802104000101],
            [126.59890451700019, 37.525106533000113],
            [126.60379237200002, 37.549296673000086],
            [126.59890670400003, 37.558087735000143],
            [126.59110467000019, 37.572870008000123],
            [126.58225004499999, 37.584483457000104],
            [126.57193454900008, 37.598494570000142],
            [126.56051462500002, 37.607353315000111],
            [126.56028577100008, 37.620847372000171],
            [126.55616295700011, 37.641017971000011],
            [126.54542076900012, 37.672512111000046],
            [126.531016472, 37.754706122000115],
            [126.534922722, 37.768215236000074],
            [126.54818769600016, 37.770168361000046],
            [126.60328209700012, 37.754543361000131],
            [126.62086022200006, 37.757554429000052],
            [126.63550866000006, 37.763006903000175],
            [126.64852949300021, 37.765041408000016],
            [126.66187584700006, 37.757961330000157],
            [126.66684004, 37.746161200000174],
            [126.66879316500015, 37.727728583000143],
            [126.6673283210001, 37.708807684000035],
            [126.66187584700006, 37.695868231000119],
            [126.66146894600004, 37.672430731000063],
            [126.69214928500006, 37.652044989000061],
            [126.73104902400021, 37.639064846000068],
            [126.75521894600004, 37.637884833000143],
            [126.7364201180001, 37.653306382000082],
            [126.67880293100021, 37.686265367000047],
            [126.69556725400005, 37.721747137000037],
            [126.70053144599999, 37.742987372000115],
            [126.69629967500006, 37.757961330000157],
            [126.68685957100016, 37.77362702000012],
            [126.68604576900023, 37.782171942000147],
            [126.68604469676754, 37.782182704976961],
            [126.68490644600004, 37.793605861000103],
            [126.68946373800023, 37.813666083000058],
            [126.69996178500006, 37.829657294000143],
            [126.69247480600004, 37.837103583000143],
            [126.68262780000023, 37.836004950000088],
            [126.66749108200023, 37.827826239000089],
            [126.66745892690014, 37.827810161200105],
            [126.66051639800011, 37.913810934000182],
            [126.66578739500014, 37.937633769000158],
            [126.6778280030002, 37.944584249000158],
            [126.69632816600014, 37.946186219000182],
            [126.72211470600021, 37.953937683],
            [126.76190555800022, 37.979000753000108],
            [126.84732670200006, 38.082017721000099],
            [126.8647933350002, 38.096022034],
            [126.90334395400018, 38.119586487],
            [126.91982873600014, 38.134598491000176],
            [126.94013424700009, 38.174172571],
            [126.95372847500008, 38.200666810000129],
            [126.96606463600008, 38.213038016],
            [126.97191857900012, 38.218908590000083],
            [127.0267472740002, 38.254461976000144],
            [127.09046431500022, 38.28585540800016],
            [127.15748864800005, 38.307223613000176],
            [127.22198083500015, 38.312701315000155],
            [127.2538135180001, 38.310039978000148],
            [127.37711348500002, 38.318514913000158],
            [127.47193973800009, 38.295906474],
            [127.50206709900024, 38.29807688400011],
            [127.56082320200019, 38.312081197000012],
            [127.75373132300004, 38.32536204100002],
            [127.76194787700015, 38.320969543000118],
            [127.77471195500013, 38.306448466000077],
            [127.78701094600009, 38.29668162000003],
            [127.80075687700011, 38.293916932000073],
            [127.86685103400018, 38.304846497000042],
            [127.97175419200008, 38.300040589000119],
            [128.03986372900025, 38.304278056000086],
            [128.10849003100012, 38.323501689000111],
            [128.17236210200016, 38.355954489000126],
            [128.22558882700005, 38.399776103000036],
            [128.24873986800006, 38.425769348000088],
            [128.26806685400018, 38.453519593000053],
            [128.28108931500012, 38.484370423000101],
            [128.2854301360002, 38.519846293000157],
            [128.27695520100011, 38.554056091000135],
            [128.27478479000004, 38.57149688700018],
            [128.28108931500012, 38.584415996000089],
            [128.30837447200022, 38.601340027],
            [128.36492222296098, 38.624330758974921],
            [128.39478358107735, 38.578074133050009],
            [128.394786004, 38.578070380000057],
            [128.44141686300011, 38.505804755000085],
            [128.45069420700005, 38.474107164000102],
            [128.47445722700016, 38.426092841000028],
          ],
        ],
      ],
    },
  },

  {
    type: 'Feature',
    id: 'KP',
    properties: { name: 'North Korea' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [125.31275475400017, 37.741400458000086],
            [125.29574629, 37.727606512000179],
            [125.26937910199999, 37.714097398000106],
            [125.26099694100006, 37.707424221000153],
            [125.246836785, 37.699855861000103],
            [125.22632897200006, 37.696356512000094],
            [125.22055097699999, 37.703762111000103],
            [125.25017337300002, 37.731675523000135],
            [125.26433353000007, 37.737209377000156],
            [125.27458743600019, 37.733832098000121],
            [125.277110222, 37.740464585],
            [125.27947024800008, 37.74941640800013],
            [125.28484134200014, 37.749497789000102],
            [125.28744550900004, 37.750677802000141],
            [125.28874759200019, 37.753648179000052],
            [125.29273522200018, 37.755438544000029],
            [125.29932701900017, 37.75617096600017],
            [125.32878665500007, 37.766669012000037],
            [125.33773847700016, 37.768703518000066],
            [125.31275475400017, 37.741400458000086],
          ],
        ],
        [
          [
            [125.15626061300006, 37.786688544000114],
            [125.14771569100006, 37.785589911000059],
            [125.14283287900017, 37.794134833000086],
            [125.14136803500006, 37.799505927000141],
            [125.14649498800023, 37.802232164000102],
            [125.16618899800008, 37.81370677300005],
            [125.18384850400011, 37.818833726000079],
            [125.18848717500012, 37.819077867000104],
            [125.19141686300023, 37.815497137000037],
            [125.19288170700011, 37.811102606000148],
            [125.19027754000004, 37.809027411000116],
            [125.18506920700011, 37.808172919000029],
            [125.17172285199999, 37.79718659100017],
            [125.15626061300006, 37.786688544000114],
          ],
        ],
        [
          [
            [125.04460696700019, 37.824611721000124],
            [125.04175866000017, 37.817287502000042],
            [125.03956139400006, 37.81761302300005],
            [125.036387566, 37.817450262000179],
            [125.025645379, 37.808294989],
            [125.01587975400017, 37.80565013200011],
            [125.00505618600008, 37.805568752000127],
            [124.99773196700008, 37.808417059000178],
            [124.99781334700006, 37.813055731000091],
            [125.00261478000002, 37.815415757000054],
            [125.00684655000023, 37.819281317000062],
            [125.01197350400017, 37.821844794000171],
            [125.01905358200023, 37.820298570000134],
            [125.02540123800017, 37.817857164000188],
            [125.02849368600008, 37.818833726000079],
            [125.02947024800019, 37.822984117000104],
            [125.03012129000015, 37.825100002000013],
            [125.02784264400012, 37.830023505000085],
            [125.03044681100019, 37.836167710000055],
            [125.04078209700012, 37.834458726000051],
            [125.04460696700019, 37.824611721000124],
          ],
        ],
        [
          [
            [124.88347415500019, 38.528550523000106],
            [124.90040123800011, 38.505316473],
            [124.90398196700008, 38.494370835000112],
            [124.86109459700018, 38.503851630000142],
            [124.84083092500006, 38.510687567000147],
            [124.822032097, 38.522365627000156],
            [124.79948978000007, 38.508490302000055],
            [124.79029381600017, 38.517401434000092],
            [124.79444420700011, 38.533921617000161],
            [124.83220462300008, 38.553045966000141],
            [124.84424889400006, 38.555161851000051],
            [124.84937584700018, 38.54588450700011],
            [124.86573326900012, 38.541489976000108],
            [124.87720787900017, 38.536322333000115],
            [124.88347415500019, 38.528550523000106],
          ],
        ],
        [
          [
            [124.60661868600008, 39.428045966000141],
            [124.60206139400023, 39.427394924000012],
            [124.59603925900004, 39.430243231000063],
            [124.59310957099999, 39.439032294000114],
            [124.59286543100004, 39.446804104000122],
            [124.60173587300019, 39.449326890000023],
            [124.61255944100012, 39.457139390000023],
            [124.61443118600008, 39.449204820000048],
            [124.61508222700004, 39.447495835000055],
            [124.61784915500019, 39.444281317],
            [124.61906985800007, 39.433010158000016],
            [124.60661868600008, 39.428045966000141],
          ],
        ],
        [
          [
            [124.70484459700006, 39.50873444200009],
            [124.69776451900006, 39.502590236000103],
            [124.68458684400017, 39.503242592000063],
            [124.68014891200008, 39.507725708000109],
            [124.6837689000001, 39.516094421000119],
            [124.68197362300023, 39.521179623000037],
            [124.68767337300019, 39.527167059000121],
            [124.69361973900007, 39.53507889100014],
            [124.70470809100001, 39.537076370000037],
            [124.70777906000004, 39.530399124000027],
            [124.70774642500018, 39.519317028000117],
            [124.70484459700006, 39.50873444200009],
          ],
        ],
        [
          [
            [124.69125410199999, 39.569647528000175],
            [124.69809004000004, 39.562892971000153],
            [124.69564863400009, 39.55377838700015],
            [124.68702233200005, 39.544745184000064],
            [124.675547722, 39.538519598000093],
            [124.66163170700023, 39.538397528000118],
            [124.64478600400005, 39.547674872000172],
            [124.60352623800011, 39.538519598000093],
            [124.60035241000006, 39.536200262000122],
            [124.59587649800008, 39.53790924700003],
            [124.59742272200012, 39.544582424000097],
            [124.60466556100002, 39.549261786],
            [124.61426842500012, 39.553412177000141],
            [124.63086998800017, 39.565090236000131],
            [124.635020379, 39.563950914000102],
            [124.64877363400004, 39.565252997000087],
            [124.67383873800011, 39.570624091000141],
            [124.69125410199999, 39.569647528000175],
          ],
        ],
        [
          [
            [124.88318229400014, 39.527470239000152],
            [124.86166425900015, 39.507635809000035],
            [124.84265555800008, 39.505162498000047],
            [124.81856313900008, 39.495444054000174],
            [124.83692719500013, 39.520939030000036],
            [124.84981631800022, 39.516533791000043],
            [124.85697023600008, 39.527898939000082],
            [124.84277262900017, 39.538425779000065],
            [124.84500731600022, 39.543176235000189],
            [124.85658815600019, 39.544227939000066],
            [124.85704955600025, 39.548302525000125],
            [124.85973830100014, 39.553138689000079],
            [124.85440967000014, 39.556577033000067],
            [124.85447530500008, 39.573754186000045],
            [124.87052236500003, 39.580933634000147],
            [124.86970632800004, 39.59565681500014],
            [124.88176886300008, 39.603869488000115],
            [124.88047098200005, 39.61245477199999],
            [124.89919306000004, 39.614124353000037],
            [124.92674098600006, 39.604786429000129],
            [124.92222659900008, 39.598288862000132],
            [124.90967360100009, 39.593553532],
            [124.92025365200018, 39.572278154000131],
            [124.93360925600004, 39.550543045000055],
            [124.91209231600007, 39.54080307800011],
            [124.88318229400014, 39.527470239000152],
          ],
        ],
        [
          [
            [124.22739722100019, 39.794640480000126],
            [124.22601537000017, 39.791422867000094],
            [124.22318937200012, 39.791556723000056],
            [124.22120143400005, 39.793839602000119],
            [124.21609840900013, 39.794711222000117],
            [124.21166218600015, 39.795157408000094],
            [124.21153335300008, 39.795572646000082],
            [124.21131598900004, 39.799505011000107],
            [124.21258842700016, 39.804049196000122],
            [124.21465084700017, 39.807242025000065],
            [124.21927271100006, 39.810921583000138],
            [124.21757453200016, 39.814864264000121],
            [124.22027210100023, 39.815260301000123],
            [124.2231159830001, 39.816788335000169],
            [124.22099393599999, 39.818973972],
            [124.22342567800004, 39.819987174000161],
            [124.22706541500011, 39.821298303],
            [124.2300174870002, 39.821171938000035],
            [124.23189581799997, 39.819198563],
            [124.2344328190002, 39.817628324000125],
            [124.23586068300003, 39.813481668000051],
            [124.23988654900009, 39.812622620000113],
            [124.2395863630002, 39.809830802000036],
            [124.23956104000015, 39.80765715500003],
            [124.24278750000022, 39.807321395000073],
            [124.24275057200006, 39.8046328410001],
            [124.24162706000013, 39.801856081000111],
            [124.23783526200006, 39.800967382000053],
            [124.23525982000015, 39.798385811000131],
            [124.23039032100021, 39.798260617000082],
            [124.22739722100019, 39.794640480000126],
          ],
        ],
        [
          [
            [129.99571333800012, 42.961900736000089],
            [130.00821903500011, 42.960557149000024],
            [130.04728641800003, 42.961900736000089],
            [130.0943119720001, 42.976938578000087],
            [130.12149377400024, 42.981847840000015],
            [130.13358606000023, 42.972726950000137],
            [130.13048547400004, 42.959471945000033],
            [130.12252730300023, 42.943323060000139],
            [130.11157190000009, 42.929060364000165],
            [130.09875614500018, 42.921515605000124],
            [130.14040734900018, 42.907123719000097],
            [130.19384078000022, 42.908208923000089],
            [130.23900598200012, 42.902240296000073],
            [130.25647261600014, 42.866893616000155],
            [130.24220992100007, 42.782015076000036],
            [130.24303674300009, 42.743361105000119],
            [130.26267378700013, 42.708608704000156],
            [130.31714074800013, 42.66739674899999],
            [130.32478885900011, 42.65029185000013],
            [130.39300175000014, 42.60623769200005],
            [130.40395715400021, 42.604067281000155],
            [130.4127421470001, 42.604635722000026],
            [130.42049361200012, 42.604273987000042],
            [130.42772831200008, 42.599364727000093],
            [130.43000207500009, 42.59148407000005],
            [130.42638472500019, 42.572105408000184],
            [130.43124231000004, 42.561511739000096],
            [130.452533, 42.549341940000019],
            [130.4679325770002, 42.554897156000109],
            [130.47103316300004, 42.569754130000049],
            [130.45511682200012, 42.585153707000032],
            [130.4678292240001, 42.600398254000154],
            [130.48415897600003, 42.61001007099999],
            [130.50141890500018, 42.6107852170001],
            [130.51650842300003, 42.599364727000093],
            [130.51950565600012, 42.588590190000062],
            [130.52519006400016, 42.549238587000062],
            [130.53077111900009, 42.53048004200015],
            [130.54265669800012, 42.510532939000043],
            [130.54451704900012, 42.506295472000104],
            [130.54606734300003, 42.495288391000102],
            [130.54989139900024, 42.490379131000068],
            [130.55474898300011, 42.487614441000019],
            [130.55877974500018, 42.482705181000185],
            [130.57562626200016, 42.445730693000044],
            [130.58740848900007, 42.432010600000027],
            [130.60590865100019, 42.420615947000115],
            [130.62854292900013, 42.414802348000038],
            [130.64580285700012, 42.400875549000048],
            [130.6566549070001, 42.383253886000077],
            [130.66058231600007, 42.359198507000045],
            [130.66027225800016, 42.333928732000075],
            [130.66275272600004, 42.320570374000127],
            [130.67288130800009, 42.311139425000178],
            [130.69996178500006, 42.295111395000148],
            [130.69555680600016, 42.289001412000161],
            [130.66712883500011, 42.283092173000071],
            [130.63157671200017, 42.274397068000056],
            [130.61662071100002, 42.27231829],
            [130.60382311000004, 42.263260240000179],
            [130.59754438100018, 42.262780245000059],
            [130.59848066500004, 42.280951239000117],
            [130.59766686300011, 42.29193756700009],
            [130.59498131600006, 42.298163153000033],
            [130.58204186300011, 42.307928778000175],
            [130.56936639700021, 42.315259072000131],
            [130.54050135200012, 42.318370657000017],
            [130.51905254900012, 42.327602855000166],
            [130.51018950300013, 42.335779521000077],
            [130.4806838960001, 42.339752116000156],
            [130.46565258500019, 42.334752470000112],
            [130.46939604800005, 42.322074715000085],
            [130.45645952900011, 42.311768735],
            [130.44009806300019, 42.309128306000119],
            [130.4318388400001, 42.318124576000102],
            [130.41915868400022, 42.324398259000034],
            [130.40744972100018, 42.324577343000115],
            [130.40699303500006, 42.315741278000175],
            [130.40585371200009, 42.307114976000079],
            [130.39399883200022, 42.299952343000101],
            [130.38415748600002, 42.291194579000077],
            [130.38632246200021, 42.277655341000084],
            [130.38624108200023, 42.270656643],
            [130.39112389400012, 42.264105536000116],
            [130.39926191500004, 42.263169664000046],
            [130.40585371200009, 42.260809637000179],
            [130.40601647200006, 42.249579169000086],
            [130.40088951900023, 42.242499091000028],
            [130.39332116000011, 42.244086005000057],
            [130.37818188000008, 42.257854715000022],
            [130.36799186900006, 42.255686789000052],
            [130.36065517800003, 42.243663939000143],
            [130.36983713100008, 42.232010977000172],
            [130.35882866500017, 42.229272342000158],
            [130.35150542300008, 42.216082705000034],
            [130.33505739600011, 42.213999164000043],
            [130.33064466300013, 42.191508042000024],
            [130.31876991400011, 42.184730392000134],
            [130.30702563300022, 42.183153959],
            [130.30815993200011, 42.197594767000155],
            [130.31563515800005, 42.218883039000033],
            [130.30741757800016, 42.23629503800008],
            [130.29418223200017, 42.237044681],
            [130.25136340200018, 42.205290936000111],
            [130.22898561200023, 42.18245195499999],
            [130.2149357430001, 42.17462799700003],
            [130.20227391700021, 42.17355132400003],
            [130.20428617100018, 42.15964419400008],
            [130.1993107430001, 42.101304429],
            [130.19385826900023, 42.100978908000073],
            [130.18531334700006, 42.103908596000011],
            [130.1739201180001, 42.098781643000095],
            [130.16024824300021, 42.085109768000152],
            [130.16358483200017, 42.083482164000131],
            [130.16659589900004, 42.077093817],
            [130.15943198300008, 42.072837552000109],
            [130.15010820100011, 42.074103465000022],
            [130.13319825000022, 42.084718682000116],
            [130.12006878000003, 42.090081352000183],
            [130.09277164500011, 42.089810624000066],
            [130.07304050900009, 42.078458672000139],
            [130.06795501000013, 42.059413827000085],
            [130.06701470700014, 42.051898647000186],
            [130.05222157100016, 42.050364513000076],
            [130.04971177100006, 42.042287515000126],
            [130.044907661, 42.03597565800014],
            [130.03646983500008, 42.011623706000179],
            [130.03387925300004, 41.995513988000042],
            [130.02681671900021, 41.992699522000137],
            [130.01495011500006, 42.01440370300007],
            [130.0028839710001, 42.010911763000152],
            [130.00129707400006, 41.990466150000188],
            [129.98218834700006, 41.982652085000055],
            [129.98902428500017, 41.978176174000097],
            [129.99415123800006, 41.973049221000068],
            [130.00269616000011, 41.96222565300009],
            [129.99293053500006, 41.959784247000144],
            [129.98560631600006, 41.955267645],
            [129.97486412900005, 41.941636460000055],
            [129.97828209700006, 41.943793036000059],
            [129.97567793100004, 41.933986721000039],
            [129.97103925900004, 41.922064520000092],
            [129.9685978520001, 41.917466539000046],
            [129.96469160199999, 41.89874909100017],
            [129.95443769600016, 41.892035223000121],
            [129.94678708800004, 41.894935183000158],
            [129.93739352300005, 41.903897810000117],
            [129.91362039900005, 41.911195248000027],
            [129.896740032, 41.904262980000127],
            [129.89039147200018, 41.88300202000012],
            [129.8833927740001, 41.857407945000048],
            [129.88453209700006, 41.824164130000142],
            [129.86965230500019, 41.791456653000083],
            [129.85517127100016, 41.781791880000142],
            [129.85246022300012, 41.764923428000131],
            [129.84281272100006, 41.758783078000093],
            [129.826545998, 41.778446388000091],
            [129.81331498300003, 41.777733233000063],
            [129.77475988900008, 41.759417195000182],
            [129.76278730600021, 41.731024481000091],
            [129.74976647200006, 41.696275132000139],
            [129.74136054600015, 41.684017639000061],
            [129.71501861100003, 41.67533690000009],
            [129.69082256400006, 41.649715773000182],
            [129.68318499900025, 41.617720114000136],
            [129.66940887800004, 41.575959449000138],
            [129.64879790700016, 41.549402728000175],
            [129.66065514400023, 41.525946356000148],
            [129.65772304300012, 41.509238682],
            [129.65272983900016, 41.494124004000142],
            [129.65940316200013, 41.464647435000089],
            [129.68167775700024, 41.443084265000138],
            [129.70166987900009, 41.428665671000132],
            [129.71460278400016, 41.419646010000079],
            [129.73605572900024, 41.421286228],
            [129.74415079600007, 41.406962770000135],
            [129.75818397200007, 41.392594180000017],
            [129.769297722, 41.386175848],
            [129.77564537900005, 41.385484117000047],
            [129.79110761800004, 41.387762762000037],
            [129.79664147200018, 41.386175848],
            [129.80616295700005, 41.376044012000037],
            [129.80518639400012, 41.372788804],
            [129.79989668100021, 41.368597723000065],
            [129.79664147200018, 41.355454820000105],
            [129.79468834700006, 41.352280992000047],
            [129.78687584700006, 41.33006419499999],
            [129.78614342500012, 41.324774481000091],
            [129.77035566500015, 41.315130927000141],
            [129.75912519600016, 41.292873440000093],
            [129.71957441500004, 41.166937567000119],
            [129.71192467500006, 41.122626044000086],
            [129.72828209700012, 41.085150458000058],
            [129.72201582100016, 41.071844794000057],
            [129.71981855600004, 41.056219794000143],
            [129.72079511800021, 41.02366771],
            [129.72828209700012, 40.978705145],
            [129.73251386800021, 40.968939520000148],
            [129.75082441499998, 40.951727606000034],
            [129.76489138300022, 40.944414406000149],
            [129.75557436799997, 40.938969672000141],
            [129.74922940500019, 40.917010468000072],
            [129.75299654700021, 40.909939743000095],
            [129.7342720730002, 40.891101808000187],
            [129.73734311300021, 40.871455785000123],
            [129.72521883400023, 40.847817701000068],
            [129.71655794700021, 40.834585581000098],
            [129.70608701000018, 40.835661468000026],
            [129.69835671000013, 40.845606223000132],
            [129.68455962900023, 40.841829436000111],
            [129.66285241000006, 40.83877187700007],
            [129.64392208600023, 40.841618462000056],
            [129.62616679600004, 40.838312455000093],
            [129.60980304500012, 40.840929487000082],
            [129.59796755400023, 40.838541143000086],
            [129.57177252600016, 40.839716297000152],
            [129.55394109300008, 40.82893169],
            [129.55105095300021, 40.810117421000101],
            [129.53199733800014, 40.807277312000124],
            [129.51808142200017, 40.795467082000116],
            [129.50624007600007, 40.792074356000157],
            [129.47652919900017, 40.793877798000054],
            [129.4269541460001, 40.781295643000036],
            [129.38918690000017, 40.760759561000057],
            [129.35168657900024, 40.745343193000011],
            [129.33975647200006, 40.734430214000085],
            [129.32782972800013, 40.718128132000018],
            [129.3112103670002, 40.687471989000173],
            [129.30323326900017, 40.673651434000092],
            [129.296153191, 40.67625560099999],
            [129.28663170700011, 40.68357982],
            [129.27791677000008, 40.698037214000109],
            [129.27013756500017, 40.707980128000159],
            [129.25315679700006, 40.709131856000155],
            [129.23348208400014, 40.705713549],
            [129.21770310100013, 40.69635326300012],
            [129.21239276700013, 40.68595193600008],
            [129.2064264210002, 40.675546457000124],
            [129.20638642700024, 40.670088909000114],
            [129.20699478900022, 40.663631634000168],
            [129.20170902700013, 40.656207153000125],
            [129.20036229600007, 40.650753090000038],
            [129.19776451900006, 40.638006903000033],
            [129.19336998800011, 40.632635809000178],
            [129.19052168100004, 40.622503973000121],
            [129.1870497470002, 40.616535271000188],
            [129.17916533900021, 40.609599498000179],
            [129.17455523000015, 40.603660165000079],
            [129.17367597700016, 40.593451239000146],
            [129.17261803500011, 40.568915106000034],
            [129.16123496800017, 40.56554866300003],
            [129.1553115830001, 40.55663459000003],
            [129.15089908300004, 40.545896736000188],
            [129.11506552400024, 40.511164886000174],
            [129.07756443800022, 40.472565003000014],
            [129.03011562100002, 40.473970225000116],
            [128.97581217399997, 40.459933038000045],
            [128.89975513100003, 40.398164928000185],
            [128.86053200500007, 40.371848367000112],
            [128.83053350100008, 40.367387326000127],
            [128.81356723100023, 40.354979919000087],
            [128.78097427500009, 40.347025483000081],
            [128.76404237400007, 40.346530265000141],
            [128.75200487400016, 40.335095472000106],
            [128.72164470499999, 40.338947061000013],
            [128.68780752700005, 40.331196581000185],
            [128.66966869500007, 40.310770897000097],
            [128.65665543100025, 40.310762531000123],
            [128.65534028200014, 40.320176984000128],
            [128.6364886420001, 40.313216565000019],
            [128.61894776000022, 40.297330807000051],
            [128.61644650800011, 40.271442812000188],
            [128.62879174100024, 40.266008756000147],
            [128.65672182800009, 40.25714038800011],
            [128.6606298520002, 40.247721506000076],
            [128.66777968400018, 40.240308598000112],
            [128.66259900900016, 40.225413382000013],
            [128.66456139400006, 40.201239325000117],
            [128.65093108100021, 40.197147697000148],
            [128.64184730600019, 40.190692851000122],
            [128.63471320599999, 40.184238817],
            [128.62887528100023, 40.180265716000164],
            [128.62173228900022, 40.180755529000109],
            [128.60810291600015, 40.185701989],
            [128.58861121900023, 40.186672799000135],
            [128.57760718600005, 40.180211410000098],
            [128.54319885000004, 40.166767012000079],
            [128.53609346200003, 40.151375446000131],
            [128.52768092400007, 40.143423720000058],
            [128.51018915700016, 40.135455971000013],
            [128.47979942000021, 40.134748641000115],
            [128.46626632500013, 40.124373526000014],
            [128.44856299200003, 40.109500088000132],
            [128.43494602900003, 40.104001888000042],
            [128.41138756600017, 40.096380927],
            [128.39184390500023, 40.099577477000153],
            [128.36476484100004, 40.096890115000022],
            [128.33776517800018, 40.067047911000188],
            [128.32775258900003, 40.028216411000145],
            [128.30209394600016, 40.033433335000055],
            [128.28013205200014, 40.06547653600002],
            [128.26344363100011, 40.077104998],
            [128.23632157100005, 40.067904293000154],
            [128.22783723699999, 40.056206037],
            [128.23438561300023, 40.029364325000088],
            [128.22510826900006, 40.027573960000112],
            [128.20321699300021, 40.029608466000056],
            [128.19336998800011, 40.029364325000088],
            [128.18238366000017, 40.026109117000047],
            [128.16431725400005, 40.017971096000124],
            [128.15512129000015, 40.016302802000112],
            [128.14682050899998, 40.018296617000047],
            [128.14356530000023, 40.022853908000101],
            [128.13941491, 40.027329820000077],
            [128.00684655000006, 40.04515208500014],
            [128.00228925900015, 40.040472723000121],
            [128.00098717500023, 40.025946356000176],
            [127.99781334700018, 40.02041250200007],
            [127.98975670700023, 40.014837958000058],
            [127.97982832100004, 40.010565497000144],
            [127.9705509770001, 40.008856512000037],
            [127.96371504000015, 40.004095770000148],
            [127.94727623800006, 39.981634833000143],
            [127.94003339900016, 39.974758205000128],
            [127.9302677740001, 39.97190989799999],
            [127.90665933500011, 39.983942366000107],
            [127.8910383890001, 39.961225290000058],
            [127.89126291, 39.94383111700013],
            [127.88404381600012, 39.902085679000052],
            [127.87460371200009, 39.892808335],
            [127.78183027400004, 39.885402736000017],
            [127.79639733200011, 39.875637111000074],
            [127.79403730600021, 39.865668036000088],
            [127.76243847200024, 39.853534320000122],
            [127.72738250700016, 39.857188614000151],
            [127.69574959500019, 39.848200850000083],
            [127.67962851700011, 39.816438696000105],
            [127.66329100900023, 39.806800659000046],
            [127.64869940600013, 39.820897156000129],
            [127.62374852100018, 39.830935318000073],
            [127.60718834700018, 39.810248114],
            [127.59302819100006, 39.806219794000029],
            [127.5760197270001, 39.79629140800013],
            [127.56185957100016, 39.784002997000172],
            [127.54940839900016, 39.76105377800009],
            [127.52084394600016, 39.746975002000127],
            [127.51441491000006, 39.738592841000084],
            [127.51246178500011, 39.731105861000017],
            [127.50375410200004, 39.715521552000112],
            [127.50131269600016, 39.707220770000148],
            [127.50171959700018, 39.698472398000078],
            [127.50635826900012, 39.683783270000063],
            [127.50855553500011, 39.660060940000065],
            [127.51221764400023, 39.649481512000094],
            [127.51856530000006, 39.644842841000084],
            [127.52808678500017, 39.645819403000147],
            [127.55274498800006, 39.636786200000145],
            [127.57032311300011, 39.617865302000112],
            [127.57455488400004, 39.595038153000118],
            [127.53676992700017, 39.552012464000043],
            [127.52043931200015, 39.510465133000096],
            [127.53541100400017, 39.443752346000039],
            [127.56299889400012, 39.343736070000077],
            [127.56210371200021, 39.316229559000035],
            [127.55445397200018, 39.30597565300009],
            [127.54346764400012, 39.301947333000143],
            [127.53183027400021, 39.305121161],
            [127.52182050900004, 39.316229559000035],
            [127.52059980600021, 39.329413153000175],
            [127.52694746200015, 39.344224351000051],
            [127.54224694100012, 39.371486721000124],
            [127.507578972, 39.409247137000094],
            [127.50399824300004, 39.422837632000054],
            [127.49512780000012, 39.433417059000121],
            [127.48292076900023, 39.436997789000159],
            [127.46998131600006, 39.429754950000145],
            [127.44044030000023, 39.407863674000126],
            [127.43808027400021, 39.405178127000127],
            [127.43100019600016, 39.397284247000172],
            [127.425547722, 39.392523505000085],
            [127.37842858200011, 39.378851630000142],
            [127.37842858200011, 39.371486721000124],
            [127.38990319100006, 39.367010809000178],
            [127.41407311300017, 39.36180247599999],
            [127.425547722, 39.357123114000061],
            [127.43783613400015, 39.36424388200011],
            [127.45386803500006, 39.367987372000144],
            [127.46753991000017, 39.363836981000119],
            [127.47339928500006, 39.347560940000093],
            [127.46680748800017, 39.340155341000084],
            [127.43539472700004, 39.33478424700003],
            [127.425547722, 39.330471096000124],
            [127.42025800900004, 39.336371161000059],
            [127.41456139400023, 39.340643622000172],
            [127.4082951180001, 39.343247789000159],
            [127.40137780000012, 39.344142971000068],
            [127.39193769599999, 39.341864325000088],
            [127.39267011800021, 39.336411851000051],
            [127.39698326900012, 39.329657294000029],
            [127.39828535200004, 39.323675848],
            [127.38672936300011, 39.297186591000056],
            [127.37842858200011, 39.268988348000065],
            [127.37202988300012, 39.24042604300017],
            [127.3829044930001, 39.221991278000033],
            [127.39340254000015, 39.201727606000034],
            [127.40853925900015, 39.193304755000085],
            [127.42017662900005, 39.189195054000137],
            [127.44125410200016, 39.17084381700009],
            [127.45224043100021, 39.165961005000028],
            [127.47193444100017, 39.16828034100017],
            [127.47608483200011, 39.177069403000033],
            [127.47364342500006, 39.187160549000012],
            [127.47339928500006, 39.193304755000085],
            [127.49130293100009, 39.194891669000114],
            [127.51172936300017, 39.15643952],
            [127.53174889400023, 39.146144924000097],
            [127.60694420700023, 39.152044989000117],
            [127.63168379000004, 39.146144924000097],
            [127.64307701900017, 39.13857656500015],
            [127.65170332100016, 39.129299221000096],
            [127.66138756600006, 39.121486721000011],
            [127.67562910200004, 39.118150132000082],
            [127.74415123800006, 39.118597723000065],
            [127.75928795700005, 39.117010809000035],
            [127.7696232430001, 39.109198309000035],
            [127.79257246200009, 39.077541408000073],
            [127.82374108200017, 39.043036200000145],
            [127.83033287900005, 39.032863674000012],
            [127.8365177740001, 39.018500067000119],
            [127.85157311300011, 39.001410223000093],
            [127.88550866000017, 38.974188544000086],
            [127.88868248800023, 38.961371161000059],
            [127.89291425900015, 38.957464911000059],
            [127.90219160199999, 38.96393463700015],
            [127.90870201900012, 38.967189846000011],
            [127.91431725400011, 38.96417877800009],
            [127.91822350400011, 38.957749742000189],
            [127.92147871200015, 38.942328192000062],
            [127.92628014400023, 38.93447500200007],
            [127.93384850400017, 38.928656317000119],
            [127.943614129, 38.926418361000131],
            [127.96176191500004, 38.917914130000113],
            [127.99545332100004, 38.87531159100017],
            [128.01465905000006, 38.858140367000047],
            [128.09115644600016, 38.84373607],
            [128.210215691, 38.727728583000115],
            [128.22510826900006, 38.732326565000065],
            [128.27035566499998, 38.751654364000089],
            [128.28280683700021, 38.755072333],
            [128.31324303500017, 38.717230536000059],
            [128.32105553500017, 38.711411851000022],
            [128.338145379, 38.703558661000116],
            [128.34766686300011, 38.696722723],
            [128.35962975400005, 38.67865631700009],
            [128.35995512006153, 38.675361977006318],
            [128.35995527400004, 38.675360419000143],
            [128.36133873800023, 38.660711981000034],
            [128.36036217500018, 38.642767645000092],
            [128.36459394599999, 38.624741929000052],
            [128.36491946700019, 38.624335028000147],
            [128.36492222296098, 38.624330758974921],
            [128.30837447200022, 38.601340027],
            [128.28108931500012, 38.584415996000089],
            [128.27478479000004, 38.57149688700018],
            [128.27695520100011, 38.554056091000135],
            [128.2854301360002, 38.519846293000157],
            [128.28108931500012, 38.484370423000101],
            [128.26806685400018, 38.453519593000053],
            [128.24873986800006, 38.425769348000088],
            [128.22558882700005, 38.399776103000036],
            [128.17236210200016, 38.355954489000126],
            [128.10849003100012, 38.323501689000111],
            [128.03986372900025, 38.304278056000086],
            [127.97175419200008, 38.300040589000119],
            [127.86685103400018, 38.304846497000042],
            [127.80075687700011, 38.293916932000073],
            [127.78701094600009, 38.29668162000003],
            [127.77471195500013, 38.306448466000077],
            [127.76194787700015, 38.320969543000118],
            [127.75373132300004, 38.32536204100002],
            [127.56082320200019, 38.312081197000012],
            [127.50206709900024, 38.29807688400011],
            [127.47193973800009, 38.295906474],
            [127.37711348500002, 38.318514913000158],
            [127.2538135180001, 38.310039978000148],
            [127.22198083500015, 38.312701315000155],
            [127.15748864800005, 38.307223613000176],
            [127.09046431500022, 38.28585540800016],
            [127.0267472740002, 38.254461976000144],
            [126.97191857900012, 38.218908590000083],
            [126.96606463600008, 38.213038016],
            [126.95372847500008, 38.200666810000129],
            [126.94013424700009, 38.174172571],
            [126.91982873600014, 38.134598491000176],
            [126.90334395400018, 38.119586487],
            [126.8647933350002, 38.096022034],
            [126.84732670200006, 38.082017721000099],
            [126.76190555800022, 37.979000753000108],
            [126.72211470600021, 37.953937683],
            [126.69632816600014, 37.946186219000182],
            [126.6778280030002, 37.944584249000158],
            [126.66578739500014, 37.937633769000158],
            [126.66051639800011, 37.913810934000182],
            [126.66745892690014, 37.827810161200105],
            [126.66700280000023, 37.827582098000121],
            [126.65577233200023, 37.814520575000145],
            [126.651621941, 37.799261786],
            [126.64210045700011, 37.798000393000095],
            [126.62079930408746, 37.787715675819257],
            [126.62077884200008, 37.787705796000168],
            [126.59327233200011, 37.774400132000054],
            [126.57618248800006, 37.77753327000012],
            [126.43661543100009, 37.848374742000132],
            [126.42847741000006, 37.854193427000084],
            [126.40430748800023, 37.887152411000059],
            [126.39771569100017, 37.891750393000095],
            [126.37867272200018, 37.88959381700009],
            [126.35800214900004, 37.884344794000114],
            [126.27955162900005, 37.851263739000061],
            [126.25953209700006, 37.845445054000109],
            [126.2369083990001, 37.843329169000086],
            [126.19996178500006, 37.84422435099999],
            [126.17847741, 37.840521552000141],
            [126.15821373800011, 37.829657294000143],
            [126.14918053500017, 37.822170315000093],
            [126.14771569100006, 37.819037177000112],
            [126.14975019600016, 37.815252997000087],
            [126.15284264400023, 37.799709377000099],
            [126.15967858200017, 37.778876044000114],
            [126.16537519600016, 37.768255927000084],
            [126.16431725400017, 37.754624742000132],
            [126.16122480600021, 37.740668036000059],
            [126.15821373800011, 37.733465887000122],
            [126.11036217500006, 37.740912177],
            [126.09693444100017, 37.781398830000128],
            [126.08692467500012, 37.800360419000057],
            [126.07211347700004, 37.799261786],
            [126.06283613400009, 37.802232164000102],
            [126.05860436300023, 37.824693101000108],
            [126.05575605600021, 37.870591539000188],
            [126.02613366000017, 37.853664455000015],
            [126.01278730600004, 37.854315497000144],
            [126.00733483200011, 37.874335028000118],
            [126.00074303500006, 37.88556549700003],
            [125.98593183700009, 37.898382880000057],
            [125.96981855600021, 37.908677476000079],
            [125.95948326900012, 37.912176825000145],
            [125.94410241000006, 37.901922919000029],
            [125.95053144600016, 37.889349677000141],
            [125.96461022200012, 37.878648179000109],
            [125.97250410200016, 37.874335028000118],
            [125.97445722700004, 37.86416250200007],
            [125.98365319100012, 37.837103583000143],
            [125.98617597700004, 37.822821356000034],
            [125.97787519600004, 37.829331773000135],
            [125.96713300900004, 37.835435289000131],
            [125.95590254000004, 37.84031810099999],
            [125.94589277400021, 37.843329169000086],
            [125.93336022200006, 37.844631252000099],
            [125.927012566, 37.84398021000014],
            [125.92164147200006, 37.844875393000123],
            [125.91163170700005, 37.850775458000086],
            [125.89551842500012, 37.864813544000029],
            [125.84546959700018, 37.926499742000047],
            [125.84107506600017, 37.936346747000172],
            [125.83497155000012, 37.943548895],
            [125.81128991000017, 37.94757721600017],
            [125.80518639400012, 37.951564846000153],
            [125.802500847, 37.958929755000142],
            [125.80176842500006, 37.970200914000131],
            [125.80396569100017, 37.988959052],
            [125.79932701900023, 37.992905992000161],
            [125.78419030000023, 37.994086005000028],
            [125.77377363400015, 37.996079820000048],
            [125.75635826900012, 38.005072333000058],
            [125.74659264400023, 38.007757880000142],
            [125.70517011800015, 38.009344794000171],
            [125.68734785200016, 38.012274481000091],
            [125.64730879000004, 38.027289130000113],
            [125.62216230600004, 38.031927802000055],
            [125.59693444100017, 38.032212632],
            [125.57894941500015, 38.025132554000109],
            [125.57821699300021, 38.00901927300005],
            [125.59652754, 37.992254950000031],
            [125.62142988400004, 37.978989976000022],
            [125.64071699300004, 37.973618882000139],
            [125.67538496200015, 37.977606512000122],
            [125.68531334700006, 37.972113348000093],
            [125.67831464900004, 37.952541408000016],
            [125.68881269600004, 37.95604075700011],
            [125.69898522200018, 37.958197333000115],
            [125.70883222699999, 37.957424221000096],
            [125.71933027400004, 37.952541408000016],
            [125.70753014400012, 37.93976471600017],
            [125.69882246200021, 37.925238348000121],
            [125.71753991000017, 37.925279039000131],
            [125.72518964900021, 37.927476304000109],
            [125.73292076900012, 37.932684637000122],
            [125.73975670700023, 37.925238348000121],
            [125.727793816, 37.915920315],
            [125.71534264400012, 37.908270575000145],
            [125.70150800899998, 37.902492580000015],
            [125.68506920700011, 37.898586330000015],
            [125.66895592500012, 37.899155992000161],
            [125.6570744150001, 37.905829169000029],
            [125.64551842500023, 37.915472723],
            [125.63062584700018, 37.925238348000121],
            [125.62745201900023, 37.902167059000178],
            [125.63681074300004, 37.878648179000109],
            [125.64120527400016, 37.856390692000062],
            [125.62305748800023, 37.837103583000143],
            [125.63778730600015, 37.825344143000152],
            [125.64413496200009, 37.822821356000034],
            [125.64014733200011, 37.813421942000119],
            [125.633555535, 37.80955638200011],
            [125.61353600400005, 37.809149481000091],
            [125.60922285200016, 37.806301174000069],
            [125.611094597, 37.79975006700009],
            [125.61695397200006, 37.788723049000126],
            [125.62037194100017, 37.774115302000112],
            [125.62012780000012, 37.765326239000146],
            [125.61353600400005, 37.761379299000154],
            [125.60279381600006, 37.761786200000145],
            [125.59302819100012, 37.763617255000142],
            [125.58399498800011, 37.767523505000142],
            [125.57520592500012, 37.774400132000054],
            [125.57935631600017, 37.776678778000118],
            [125.58008873800011, 37.777655341000084],
            [125.58041425900004, 37.778794664000131],
            [125.58277428500017, 37.781887111000017],
            [125.57056725400011, 37.787095445000105],
            [125.55958092500023, 37.78774648600016],
            [125.55160566499998, 37.782700914000131],
            [125.54859459700018, 37.771307684000149],
            [125.54184004000015, 37.770453192000062],
            [125.52890058700015, 37.772528387000179],
            [125.52051842500006, 37.780178127000013],
            [125.52759850400005, 37.796128648000106],
            [125.50350996200021, 37.778794664000131],
            [125.49097741, 37.754055080000157],
            [125.47461998800011, 37.731187242000047],
            [125.438731316, 37.719794012000179],
            [125.40365644600004, 37.719061591000141],
            [125.38526451900006, 37.716376044000171],
            [125.37728925900004, 37.710150458000115],
            [125.37265058700009, 37.693548895000063],
            [125.36158287900017, 37.680080471000068],
            [125.34750410200004, 37.675604559000121],
            [125.33505293100021, 37.686265367000047],
            [125.34864342500023, 37.737982489000089],
            [125.36378014400023, 37.760891018000066],
            [125.39039147200018, 37.754543361000131],
            [125.44491621200009, 37.774400132000054],
            [125.42945397200006, 37.781561591000084],
            [125.3951115240001, 37.776434637000179],
            [125.37728925900004, 37.781887111000017],
            [125.389903191, 37.796128648000106],
            [125.41098066500004, 37.809393622000144],
            [125.43490644599999, 37.819037177000112],
            [125.45582116000006, 37.822821356000034],
            [125.50513756600006, 37.813177802000084],
            [125.52418053500006, 37.817816473],
            [125.52759850400005, 37.843329169000086],
            [125.51083418100021, 37.837713934000092],
            [125.49732506600006, 37.839667059000035],
            [125.48666425900004, 37.84707265800013],
            [125.47909589900004, 37.857611395000092],
            [125.50863691500004, 37.883734442000147],
            [125.51010175899998, 37.894273179000109],
            [125.48316491000006, 37.898586330000015],
            [125.46387780000006, 37.904120184000035],
            [125.45508873800011, 37.914618231000091],
            [125.44564863400004, 37.920396226000051],
            [125.42440839900021, 37.912176825000145],
            [125.42750084700012, 37.908351955000128],
            [125.43002363400004, 37.902004299000012],
            [125.43189537900017, 37.898586330000015],
            [125.40626061300011, 37.89862702],
            [125.39454186300023, 37.896673895000148],
            [125.38355553500017, 37.891750393000095],
            [125.40284264400012, 37.88556549700003],
            [125.42798912900011, 37.874497789000159],
            [125.44629967500023, 37.860012111000131],
            [125.44491621200009, 37.843329169000086],
            [125.42628014400006, 37.856024481000148],
            [125.39966881600006, 37.865464585000083],
            [125.37183678500017, 37.869126695000134],
            [125.34937584700018, 37.864406643000038],
            [125.35401451900012, 37.857001044000029],
            [125.36036217500023, 37.851060289000102],
            [125.36801191500015, 37.846625067000119],
            [125.37728925900004, 37.843329169000086],
            [125.34595787900017, 37.822577216000084],
            [125.32667076900023, 37.812974351000108],
            [125.30836022200018, 37.809149481000091],
            [125.30909264400012, 37.811102606000148],
            [125.30811608200023, 37.815497137000037],
            [125.30543053500006, 37.82021719000015],
            [125.30103600400017, 37.822821356000034],
            [125.239512566, 37.822821356000034],
            [125.25171959700018, 37.837836005000057],
            [125.29493248800006, 37.859849351000079],
            [125.30836022200018, 37.878078518000152],
            [125.30062910200016, 37.895209052],
            [125.25709069100006, 37.914618231000091],
            [125.26002037900017, 37.932684637000122],
            [125.24008222700016, 37.929592190000122],
            [125.22852623800023, 37.915228583000058],
            [125.21957441500004, 37.89671458500014],
            [125.20850670700011, 37.881170966000056],
            [125.19312584700006, 37.871771552000112],
            [125.17660566500015, 37.867621161000088],
            [125.15821373800023, 37.867580471000096],
            [125.10035241, 37.875067450000145],
            [125.08936608200017, 37.878078518000152],
            [125.06210371200009, 37.896144924000069],
            [125.051524285, 37.898586330000015],
            [125.02938886800021, 37.900091864000061],
            [125.01319420700011, 37.905991929],
            [125.00660241, 37.918443101000108],
            [125.01351972700004, 37.939520575000031],
            [124.98902428500017, 37.927435614000117],
            [124.97950280000006, 37.925238348000121],
            [124.983653191, 37.94232819200009],
            [124.99219811300023, 37.952948309000035],
            [125.00546308700009, 37.95844147300015],
            [125.02418053500006, 37.960028387000179],
            [125.03663170700017, 37.963609117000075],
            [125.06202233200011, 37.978583075000088],
            [125.07569420700023, 37.980454820000077],
            [125.08961022200012, 37.975816148000135],
            [125.11597741000011, 37.962713934000149],
            [125.13379967500006, 37.960028387000179],
            [125.14991295700023, 37.960760809000121],
            [125.163828972, 37.963771877000042],
            [125.17538496200004, 37.969916083],
            [125.18490644600016, 37.980454820000077],
            [125.17750084700006, 37.987941799000126],
            [125.16651451900012, 37.981268622000172],
            [125.15528405000012, 37.979966539000159],
            [125.14429772200006, 37.98346588700015],
            [125.123301629, 37.998439846000011],
            [125.11850019600004, 37.999457098000065],
            [125.11532636800004, 38.002386786000088],
            [125.10971113400015, 38.015285549000012],
            [125.10670006600006, 38.034613348000121],
            [125.11622155000023, 38.041937567000119],
            [125.15756269600016, 38.042547919000086],
            [125.20167076900017, 38.049383856000119],
            [125.21322675899998, 38.045721747000144],
            [125.21729576900023, 38.037176825000031],
            [125.21957441500004, 38.027818101000022],
            [125.22518964900021, 38.021429755000085],
            [125.24634850400005, 38.020086981000091],
            [125.25318444100006, 38.05394114800005],
            [125.273692254, 38.06240469],
            [125.26490319100006, 38.077948309000092],
            [125.24830162900017, 38.083807684000035],
            [125.20850670700011, 38.083482164000102],
            [125.19044030000018, 38.085760809000092],
            [125.15585371200009, 38.095526434000035],
            [125.14063561300011, 38.097805080000015],
            [125.12452233200023, 38.095119533000044],
            [125.07569420700023, 38.076076565000122],
            [125.02735436300011, 38.068996486000074],
            [125.01351972700004, 38.06240469],
            [124.93783613400004, 38.097805080000015],
            [124.90487714900004, 38.124823309000149],
            [124.88526451900012, 38.131659247000172],
            [124.87647545700023, 38.113959052000055],
            [124.86548912900017, 38.107082424000154],
            [124.84009850400005, 38.101996161000116],
            [124.79379316500015, 38.097805080000015],
            [124.67709394600016, 38.115383205000128],
            [124.66724694100006, 38.125718492000047],
            [124.67359459700018, 38.13719310099999],
            [124.69776451900006, 38.144964911],
            [124.74512780000012, 38.147935289000102],
            [124.76514733200011, 38.156073309000121],
            [124.77328535199999, 38.175970770000035],
            [124.78003991000006, 38.178615627000013],
            [124.82178795700005, 38.185939846000011],
            [124.82911217500006, 38.193793036],
            [124.84546959700012, 38.218410549000097],
            [124.85596764400006, 38.226955471000011],
            [124.879161004, 38.230658270000063],
            [124.89527428500017, 38.221502997000172],
            [124.92090905000023, 38.189357815000037],
            [124.93100019600004, 38.192938544000086],
            [124.99301191500015, 38.226955471000011],
            [124.96762129000004, 38.235337632000082],
            [124.91504967500012, 38.229071356000034],
            [124.88656660199999, 38.244574286000145],
            [124.87232506600017, 38.257635809000121],
            [124.87012780000012, 38.265448309000092],
            [124.86963951900012, 38.282131252000127],
            [124.87973066500015, 38.345200914000046],
            [124.88388105600021, 38.355292059000121],
            [124.927989129, 38.40908437700007],
            [124.93506920700005, 38.420599677],
            [124.94727623800023, 38.450506903000175],
            [124.95899498800023, 38.460882880000085],
            [124.96656334700018, 38.463364976000108],
            [124.97217858200005, 38.46214427299999],
            [124.97486412900017, 38.455145575000117],
            [124.97266686300017, 38.440375067000119],
            [124.98519941500015, 38.451361395000092],
            [125.00204511800004, 38.473618882000139],
            [125.01636803500006, 38.497951565],
            [125.02100670700005, 38.51487864799999],
            [125.01474043100021, 38.524969794000143],
            [124.99683678500017, 38.535874742000132],
            [124.99301191500015, 38.54588450700011],
            [124.99488366000006, 38.557684637000179],
            [124.99708092500012, 38.566148179000109],
            [124.99569746200021, 38.574042059000092],
            [124.98086569900025, 38.584157201],
            [124.98394855600023, 38.591941676000076],
            [124.99774737499999, 38.592521621000103],
            [125.01768591000021, 38.583523296000138],
            [125.02920047300009, 38.589495285000069],
            [125.03762506699999, 38.591271449000132],
            [125.04296633600009, 38.581682956000108],
            [125.05824114500015, 38.568467928000118],
            [125.07974441700006, 38.58398853000007],
            [125.10276941300017, 38.593523426000118],
            [125.1280086400001, 38.580281442000128],
            [125.13453209700012, 38.591253973],
            [125.134043816, 38.61001211100016],
            [125.13721764400006, 38.624741929000052],
            [125.12606855600009, 38.633246161],
            [125.11841881600006, 38.645168361000131],
            [125.11654707100016, 38.657212632000139],
            [125.12338300899998, 38.666327216000028],
            [125.13755966000022, 38.662332307000085],
            [125.14822853900014, 38.646128223000133],
            [125.1565998990001, 38.631726627000134],
            [125.17962270700005, 38.634655136000148],
            [125.18349645, 38.642431744000092],
            [125.17819758300007, 38.656826179000134],
            [125.17979650700013, 38.670002481000111],
            [125.1889903810002, 38.666979030000149],
            [125.19354523400003, 38.657378649000108],
            [125.20348540400019, 38.652553381000089],
            [125.22038123100018, 38.656092424000079],
            [125.23573354900017, 38.657837223000129],
            [125.25961350900016, 38.669738521000042],
            [125.26729528800016, 38.67031000600015],
            [125.27798275600017, 38.663077538000024],
            [125.30103600400017, 38.656805731000034],
            [125.31132433100012, 38.665016702000131],
            [125.31979146600005, 38.668584452000019],
            [125.33060514700006, 38.678747467000065],
            [125.34601622600022, 38.687079095000044],
            [125.36910835300003, 38.694760775000034],
            [125.39722741, 38.693670966000113],
            [125.40674889400012, 38.692857164000188],
            [125.42969811300011, 38.687486070000134],
            [125.44190514400006, 38.686224677000112],
            [125.44442793100009, 38.689520575000145],
            [125.44613691499998, 38.696722723],
            [125.45101972700016, 38.704006252000013],
            [125.46265709700018, 38.707261460000055],
            [125.50709069100017, 38.686224677000112],
            [125.5118107430001, 38.676703192000119],
            [125.51465905000023, 38.657863674000097],
            [125.520762566, 38.652655341000084],
            [125.53272545700023, 38.654120184000149],
            [125.53793379000015, 38.663641669000143],
            [125.54078209700018, 38.674261786],
            [125.54517662900017, 38.679388739000032],
            [125.55518639400023, 38.676662502000127],
            [125.56495201900012, 38.670111395000063],
            [125.5723576180001, 38.662298895000063],
            [125.57520592500012, 38.655747789000159],
            [125.58594811300023, 38.641913153000175],
            [125.61036217500006, 38.631089585],
            [125.63795006600012, 38.626410223000065],
            [125.65780683700021, 38.631008205000015],
            [125.64852949300004, 38.638495184000178],
            [125.63681074300004, 38.653998114000089],
            [125.63062584700018, 38.658880927000055],
            [125.61988366000017, 38.661444403000147],
            [125.59961998800006, 38.662258205000072],
            [125.58895918100015, 38.666327216000028],
            [125.543711785, 38.711127020000063],
            [125.52759850400005, 38.720933335],
            [125.51474043100021, 38.722805080000157],
            [125.49097741, 38.72288646000014],
            [125.47909589900004, 38.727728583000115],
            [125.47291100400011, 38.73432038],
            [125.44703209700018, 38.771551825000174],
            [125.44800866000017, 38.776190497000115],
            [125.44776451900012, 38.777248440000065],
            [125.438731316, 38.775580145000148],
            [125.43588300900015, 38.771307684000035],
            [125.43213951900023, 38.76308828300013],
            [125.42457116000017, 38.755926825000088],
            [125.41089928500006, 38.755072333],
            [125.42001386800021, 38.75018952000012],
            [125.42782636800021, 38.744126695000134],
            [125.43417402400004, 38.736761786000116],
            [125.41931328300009, 38.72926491900013],
            [125.3892440650001, 38.717449085000013],
            [125.37312844300013, 38.720541037000046],
            [125.363047722, 38.734564520000148],
            [125.356211785, 38.734564520000148],
            [125.35035241000017, 38.721258856000119],
            [125.341075066, 38.717474677],
            [125.31454511800015, 38.720933335],
            [125.31275475400017, 38.723863023000106],
            [125.310313347, 38.729803778000061],
            [125.30640709700006, 38.73460521000014],
            [125.30103600400017, 38.734564520000148],
            [125.29590905000023, 38.728461005000057],
            [125.29794355600009, 38.722113348000121],
            [125.30347741, 38.716742255000057],
            [125.30836022200018, 38.713527736000017],
            [125.30836022200018, 38.707261460000055],
            [125.29371178500017, 38.703192450000088],
            [125.27767988400009, 38.703314520000063],
            [125.26246178500011, 38.707912502000013],
            [125.24976647200006, 38.717230536000059],
            [125.24350019599999, 38.728338934000178],
            [125.24691816499998, 38.736721096000124],
            [125.26685631600017, 38.755072333],
            [125.25977623800011, 38.758286851000051],
            [125.25318444100006, 38.762518622000172],
            [125.25782311300006, 38.771918036],
            [125.25269616000017, 38.783596096000096],
            [125.24097741000017, 38.7931175800001],
            [125.22518964900021, 38.796087958],
            [125.23047936300011, 38.771918036],
            [125.2165567870002, 38.739716800000011],
            [125.18811979800003, 38.738607582000085],
            [125.17666381900008, 38.751228001000172],
            [125.1721114310001, 38.763229570000092],
            [125.15677039000016, 38.769267292000066],
            [125.14527391700008, 38.777087866],
            [125.13721764400006, 38.803534247000172],
            [125.14332116000017, 38.820379950000174],
            [125.14755293100009, 38.837144273000106],
            [125.15072675900015, 38.874579169000057],
            [125.15196621700014, 38.891528975000043],
            [125.16362715400012, 38.915460405000132],
            [125.17298086200017, 38.938799521000035],
            [125.19002722000019, 38.958519826000114],
            [125.20090124600009, 38.975262448000038],
            [125.19864306900004, 38.986056348000076],
            [125.21216881600006, 39.001532294000143],
            [125.23336837900004, 39.02988677000009],
            [125.25200202900007, 39.049638677000033],
            [125.26002037900017, 39.066351630000057],
            [125.27080255200011, 39.080606125000159],
            [125.2911076180001, 39.080471096000096],
            [125.31600996200021, 39.079535223],
            [125.33505293100021, 39.083441473],
            [125.28407024400011, 39.089556418000072],
            [125.28669428200013, 39.097046423],
            [125.29061152600016, 39.104530787000115],
            [125.29033477800024, 39.111281257000186],
            [125.28617909400023, 39.117048880000183],
            [125.28815316700005, 39.123542083000174],
            [125.28736412900005, 39.13182200700011],
            [125.28915449300021, 39.139064846000124],
            [125.29330488400015, 39.143540757],
            [125.29444420700011, 39.147365627000013],
            [125.28736412900005, 39.152329820000077],
            [125.29672285200016, 39.16152578300013],
            [125.30713951900023, 39.16543203300013],
            [125.33204186300011, 39.165961005000028],
            [125.34351647200006, 39.168890692000119],
            [125.34009850400005, 39.176011460000083],
            [125.33204186300011, 39.184963283000016],
            [125.32886803500006, 39.193304755000085],
            [125.34359785200004, 39.212876695000048],
            [125.36508222700016, 39.220892645],
            [125.38900800900004, 39.220282294000029],
            [125.41089928500006, 39.213812567000119],
            [125.40487714900021, 39.228949286000116],
            [125.39600670700011, 39.236558335000083],
            [125.39356530000023, 39.245794989000117],
            [125.43189537900017, 39.300726630000113],
            [125.44491621200009, 39.310614325000031],
            [125.42920983200011, 39.312241929000052],
            [125.416270379, 39.32025788],
            [125.41114342500006, 39.333482164000046],
            [125.41822350400005, 39.350978908000101],
            [125.41342207100016, 39.35443756700009],
            [125.40463300899998, 39.364650783000044],
            [125.41480553500006, 39.362005927000141],
            [125.41895592500006, 39.360541083000086],
            [125.42440839900021, 39.357123114000061],
            [125.44564863400004, 39.366929429],
            [125.45419355600021, 39.374335028000175],
            [125.45923912900017, 39.385077216000084],
            [125.43848717500023, 39.384995835000112],
            [125.37647545700011, 39.392523505000085],
            [125.35702558700021, 39.398749091000028],
            [125.35995527400021, 39.413153387000094],
            [125.38355553500017, 39.440334377000013],
            [125.37256920700011, 39.440985419000143],
            [125.36182701900012, 39.439927476000108],
            [125.35165449300004, 39.437404690000093],
            [125.34253991000017, 39.433539130000085],
            [125.34603925900015, 39.459621486000131],
            [125.36036217500023, 39.474676825000145],
            [125.37940514400023, 39.486273505000085],
            [125.39722741, 39.501776434000092],
            [125.40259850400017, 39.513739325000031],
            [125.40658613400015, 39.527492580000128],
            [125.41366621200021, 39.538723049000126],
            [125.44198652400004, 39.547552802],
            [125.45028730600021, 39.55792877800009],
            [125.4517521490001, 39.57127513200011],
            [125.44491621200009, 39.584377346000068],
            [125.42969811300011, 39.575384833000058],
            [125.32569420700005, 39.533189195000105],
            [125.30355879, 39.52728913],
            [125.29493248800006, 39.523016669000171],
            [125.28598066500015, 39.521185614],
            [125.277110222, 39.526027736000074],
            [125.250743035, 39.551214911000145],
            [125.24634850400005, 39.553615627000099],
            [125.23707116000017, 39.562892971000153],
            [125.21957441500004, 39.566473700000117],
            [125.21029707100016, 39.573065497000087],
            [125.22518964900021, 39.591213283000101],
            [125.15756269600016, 39.591213283000101],
            [125.16098066499998, 39.58519114799999],
            [125.16781660199999, 39.56976959800015],
            [125.17123457100004, 39.563869533000016],
            [125.12680097699999, 39.563869533000016],
            [125.11695397200018, 39.565863348000065],
            [125.11426842500012, 39.570949611000074],
            [125.11353600400017, 39.57758209800015],
            [125.10971113400015, 39.584377346000068],
            [125.10377037900005, 39.592474677],
            [125.10165449300021, 39.59870026200015],
            [125.09750410200016, 39.602728583000115],
            [125.08554121200015, 39.604193427],
            [125.070078972, 39.601507880000113],
            [125.06031334700018, 39.601141669000086],
            [125.04769941499998, 39.604193427],
            [125.04004967500018, 39.608547268000095],
            [125.01807701900023, 39.624009507000025],
            [125.01351972700004, 39.628729559000121],
            [125.01059004, 39.635321356000176],
            [125.0032658210001, 39.636867580000128],
            [124.99439537900005, 39.63690827000012],
            [124.98633873800011, 39.638983466000056],
            [124.95826256600012, 39.662014065],
            [124.94727623800023, 39.66299062700007],
            [124.93783613400004, 39.645819403000147],
            [124.92652428500006, 39.652980861000103],
            [124.91309655000023, 39.656236070000048],
            [124.901621941, 39.662054755],
            [124.8926701180001, 39.691961981000176],
            [124.88160241000006, 39.70416901200015],
            [124.86630293100009, 39.71385325700011],
            [124.84913170700023, 39.721502997000059],
            [124.83358808700004, 39.725978908000016],
            [124.81128991, 39.728338934000149],
            [124.80307050900004, 39.730454820000077],
            [124.79916425900004, 39.739813544000114],
            [124.77064966000009, 39.741811421000122],
            [124.760390129, 39.748007778000058],
            [124.7564396490001, 39.770656643000066],
            [124.74610436300011, 39.782945054000109],
            [124.74024498800023, 39.772162177000112],
            [124.739512566, 39.762111721000124],
            [124.74293053500006, 39.75299713700015],
            [124.74952233200011, 39.74506256700009],
            [124.75473066499998, 39.733710028000118],
            [124.75025475400005, 39.722357489000146],
            [124.75002107500009, 39.709892474000029],
            [124.74418696600017, 39.701307953000125],
            [124.74462405300019, 39.69135615600014],
            [124.75711508900022, 39.687905913000023],
            [124.75304515100018, 39.6724587450001],
            [124.740532312, 39.671791548000058],
            [124.72980457000003, 39.665292524000122],
            [124.73424385799999, 39.654996174000033],
            [124.74390709700012, 39.644354559000092],
            [124.73513749100007, 39.641587568000134],
            [124.71641220400025, 39.647127675000135],
            [124.69501666400018, 39.63753981400005],
            [124.67492862800023, 39.622069139000061],
            [124.64906281400025, 39.612120807000096],
            [124.64057472300013, 39.594603830000054],
            [124.63745219100022, 39.590483449000104],
            [124.62378991, 39.597967841000028],
            [124.62411543100021, 39.602199611000131],
            [124.62533613400015, 39.605169989000061],
            [124.62940514400012, 39.61163971600017],
            [124.62191816500004, 39.613104559000035],
            [124.61866295700011, 39.614732164000046],
            [124.61573326900017, 39.617865302000112],
            [124.61080301399997, 39.628650500000148],
            [124.62857395600005, 39.644747588000101],
            [124.63214883400011, 39.662945980000032],
            [124.64509531300004, 39.669466084000121],
            [124.64991295700017, 39.683661200000088],
            [124.64698326900023, 39.69757721600017],
            [124.63990319100017, 39.698065497000144],
            [124.63143964900021, 39.693915106000034],
            [124.62378991, 39.693548895],
            [124.61988366, 39.697943427],
            [124.61337324300021, 39.709784247000144],
            [124.60946699300021, 39.714667059000121],
            [124.60035241000006, 39.719305731000148],
            [124.59156334700006, 39.7208519550001],
            [124.58480879000004, 39.723578192000062],
            [124.582286004, 39.731756903000147],
            [124.58326256600017, 39.740545966000028],
            [124.58790123800011, 39.756293036],
            [124.58912194100006, 39.765855210000083],
            [124.58472741000006, 39.770941473],
            [124.56600996200009, 39.778876044000143],
            [124.56161543100009, 39.786688544000143],
            [124.56544030000012, 39.807114976000136],
            [124.56405683700021, 39.814276434000149],
            [124.55494225400005, 39.817084052000112],
            [124.51978600400017, 39.818060614],
            [124.43441816500004, 39.830755927000055],
            [124.43002363400015, 39.82933177299999],
            [124.4263615240001, 39.826483466000141],
            [124.42261803500017, 39.824204820000077],
            [124.41773522200018, 39.824530341000084],
            [124.41366621200021, 39.827826239000117],
            [124.40975996200021, 39.835923570000077],
            [124.40723717500012, 39.837591864000089],
            [124.40357506600017, 39.841498114000061],
            [124.39918053500017, 39.859808661000116],
            [124.3965763680001, 39.865545966000113],
            [124.38965905000006, 39.867865302000084],
            [124.36255944100006, 39.871730861000074],
            [124.348887566, 39.892808335],
            [124.34180748800017, 39.899969794000057],
            [124.3348087900001, 39.905585028000033],
            [124.32797285200016, 39.909735419000171],
            [124.32154381600006, 39.912665106000091],
            [124.3321232430001, 39.925116278000118],
            [124.34571373800023, 39.930243231000034],
            [124.35938561300011, 39.933539130000085],
            [124.36996504000015, 39.940008856000176],
            [124.37606855600021, 39.95408763200011],
            [124.383067254, 40.002671617000161],
            [124.40577233200017, 40.027777411000059],
            [124.43409264400012, 40.024562893],
            [124.46583092500023, 40.015285549000154],
            [124.49960371200015, 40.022528387000179],
            [124.47925866000006, 40.030747789000159],
            [124.43287194100017, 40.032782294000114],
            [124.41024824300021, 40.036200262000122],
            [124.39144941500015, 40.048285223000036],
            [124.38436933700021, 40.064886786000088],
            [124.38038170700023, 40.08266836100016],
            [124.36996504000015, 40.098293361000131],
            [124.3855648200001, 40.110472718000139],
            [124.52819177300015, 40.214135641000027],
            [124.53237756400009, 40.215995992000032],
            [124.54302290900014, 40.21764963800014],
            [124.54865564000019, 40.22090525300014],
            [124.55020593300011, 40.224729309000097],
            [124.55175622600021, 40.236201478000012],
            [124.55485681100018, 40.241420797000117],
            [124.57087650600013, 40.252427877000017],
            [124.61118412300019, 40.272168275000169],
            [124.62720381700004, 40.286120911000168],
            [124.64260339400013, 40.29428578699999],
            [124.69303959200013, 40.302812398000086],
            [124.71246993000003, 40.310305481000128],
            [124.72296024600016, 40.32668691],
            [124.73128015200021, 40.349734599000143],
            [124.74120202700013, 40.370353496000135],
            [124.75680830900015, 40.379190166000157],
            [124.77985599800004, 40.383479310000112],
            [124.8008366290002, 40.394072978000096],
            [124.84987756400014, 40.431590068000091],
            [124.88382898000023, 40.465541484000099],
            [124.89065026900019, 40.477892152000081],
            [124.89550785400004, 40.483628235000126],
            [124.90563643400017, 40.476496888],
            [124.91514489800025, 40.46585154200001],
            [124.91793542600013, 40.461097310000113],
            [124.92589359600018, 40.457893372000129],
            [124.93529870600005, 40.455567932000108],
            [124.95958663000025, 40.453655904000087],
            [124.98661340400011, 40.457376607000086],
            [125.00661218300004, 40.456136373000064],
            [125.02630090400018, 40.457376607000086],
            [125.04128706900022, 40.461097310000113],
            [125.04629968300011, 40.471070862000104],
            [125.03885827700017, 40.483473206],
            [125.02769616800006, 40.49587555000015],
            [125.01529382300018, 40.508277894000045],
            [125.01529382300018, 40.521972148000046],
            [125.02511234500005, 40.534374492000111],
            [125.05002038600011, 40.541815898000138],
            [125.07358484000022, 40.548017070000029],
            [125.08965620900008, 40.555510152000139],
            [125.11461592700007, 40.564140117000093],
            [125.1330644120001, 40.572873433000083],
            [125.15306319300012, 40.583983867000185],
            [125.18153690700015, 40.593957418000016],
            [125.21641849800014, 40.602639059000168],
            [125.24241174300019, 40.611320699000132],
            [125.25610599800021, 40.615041402000131],
            [125.2648393150001, 40.622482809000147],
            [125.27098881000015, 40.632456360000063],
            [125.27718998200024, 40.643618470000021],
            [125.29331302900013, 40.648579407000128],
            [125.30819584200017, 40.649819642000082],
            [125.32318200800015, 40.644858704000129],
            [125.3366695560002, 40.641138001000044],
            [125.35604821800015, 40.646047262000153],
            [125.3635929770002, 40.646719055000133],
            [125.37248132300013, 40.643928528000075],
            [125.38457360900011, 40.635091858000081],
            [125.39087813400013, 40.632456360000063],
            [125.41325402900011, 40.631784566000178],
            [125.41712976200009, 40.639277649000022],
            [125.41495935100025, 40.651938375000057],
            [125.41515982600006, 40.652684880000024],
            [125.41888676000022, 40.66656280600013],
            [125.42632816600016, 40.671162008000081],
            [125.44369144800018, 40.668733215000131],
            [125.45299320500013, 40.673384094000184],
            [125.45630049700011, 40.679688620000022],
            [125.4569206140001, 40.693692933000094],
            [125.45981449400009, 40.701341044000074],
            [125.46906457500009, 40.712916566000118],
            [125.47877974500003, 40.71875600200012],
            [125.49164717600004, 40.7209264130001],
            [125.53309167500018, 40.721804912000138],
            [125.54647587100013, 40.725939026000063],
            [125.55334883600008, 40.737101136000021],
            [125.55536421800005, 40.758753561000091],
            [125.5600667730001, 40.7783389290001],
            [125.57174564700009, 40.784901836000088],
            [125.5865251060001, 40.781852926000155],
            [125.60032271400021, 40.77270619800008],
            [125.60812585500017, 40.764179586000139],
            [125.60993453000006, 40.761285706000152],
            [125.66869063300012, 40.762164205000104],
            [125.68264327000011, 40.768882141000134],
            [125.67334151200018, 40.784075012000145],
            [125.64481612100022, 40.810585023000058],
            [125.68321171100015, 40.847895407000109],
            [125.70408969200011, 40.859194625000029],
            [125.70641442900015, 40.860452779000113],
            [125.73700687700008, 40.86520701100001],
            [125.74589522300019, 40.868152568000099],
            [125.75075280800004, 40.874767151000114],
            [125.75473189300018, 40.881691794000076],
            [125.76093306500016, 40.885670878000056],
            [125.77080326400008, 40.886239319000126],
            [125.77917484600013, 40.884637349000073],
            [125.82196293100006, 40.869754537000134],
            [125.83167810100011, 40.870839743000133],
            [125.87270918800007, 40.892698873000157],
            [125.88521488500024, 40.89750478200007],
            [125.90154463700017, 40.899313457000076],
            [125.9122933350001, 40.894145813000151],
            [125.92402388500003, 40.88406890900005],
            [125.93616784700018, 40.877402649000103],
            [125.94960371900007, 40.882570293000029],
            [125.96427982600014, 40.891303610000094],
            [125.9950789800001, 40.894145813000151],
            [126.00784305900007, 40.899313457000076],
            [126.01533614200011, 40.909131979000122],
            [126.01001346900014, 40.913886211000133],
            [125.9966292730002, 40.914919739000098],
            [125.98055790200024, 40.913627828000145],
            [125.99203007100007, 40.928355612000146],
            [126.0095483810002, 40.932696431000139],
            [126.02856530800003, 40.935280253000045],
            [126.0455151780001, 40.944685364000108],
            [126.06582401600022, 40.968611553000088],
            [126.0719735110001, 40.980600485000153],
            [126.06928633600015, 40.989385478000045],
            [126.0756425380001, 40.997085267000145],
            [126.08370406100008, 41.002511292000023],
            [126.09336755400003, 41.006231995000022],
            [126.10406457600018, 41.009177552000111],
            [126.10132572500007, 41.022096659000127],
            [126.11150598200012, 41.032431946000045],
            [126.12644047100017, 41.043232320000172],
            [126.13817102100009, 41.056978252000121],
            [126.12375329700015, 41.075530091000147],
            [126.1474211020001, 41.092273255000109],
            [126.26069584200016, 41.143122864000119],
            [126.27475183200019, 41.152579651000096],
            [126.2843636480001, 41.16410349500002],
            [126.30953007000014, 41.207821757000019],
            [126.32100223900014, 41.218880514000105],
            [126.34616866100018, 41.237690735000015],
            [126.35733077000012, 41.249421285],
            [126.38058516500021, 41.290814107000145],
            [126.39148889200013, 41.303423157000068],
            [126.4408915610002, 41.348381653000089],
            [126.47251753800012, 41.366520081000104],
            [126.50130131100005, 41.366106669000104],
            [126.4982007250002, 41.361765849000122],
            [126.49649540200014, 41.358045146],
            [126.49375655100019, 41.354789530000048],
            [126.48765873200014, 41.35179229800012],
            [126.48765873200014, 41.344402568000092],
            [126.52212691200012, 41.351947327000161],
            [126.52212691200012, 41.377630514000131],
            [126.50827762900022, 41.409153137000189],
            [126.50130131100005, 41.434371236000132],
            [126.50760583500008, 41.447238669000129],
            [126.54222904500014, 41.488993225000101],
            [126.55876550300016, 41.534158428000168],
            [126.57726566600016, 41.561805318000083],
            [126.58088301700016, 41.575241191000131],
            [126.57726566600016, 41.588315329000082],
            [126.56651696800023, 41.601932068000067],
            [126.56703373200011, 41.614153544000104],
            [126.60207035300013, 41.642058818000081],
            [126.61788334200011, 41.665933330000158],
            [126.63395471200019, 41.668620504],
            [126.66930139200011, 41.667121887000079],
            [126.68010176600004, 41.671901957],
            [126.71224450800005, 41.694174500000045],
            [126.72795414300012, 41.701254171000144],
            [126.71415653500017, 41.709909974000126],
            [126.69963545800013, 41.716421204000184],
            [126.68712976100014, 41.724017639000138],
            [126.67948164900022, 41.73598073300009],
            [126.69658654800011, 41.741225892000116],
            [126.71250288900004, 41.741225892000116],
            [126.72728234900015, 41.736910909000144],
            [126.74154504400022, 41.729185282000159],
            [126.77136234600013, 41.70448394800006],
            [126.78862227400012, 41.695905661000111],
            [126.79627038600009, 41.704949036000173],
            [126.80887943600013, 41.729831238000131],
            [126.8647933350002, 41.747272034000062],
            [126.87745406100024, 41.773549500000016],
            [126.88711755400007, 41.784918315000098],
            [126.90851159700017, 41.796157939000139],
            [126.92980228700017, 41.80104136199999],
            [126.93962080900022, 41.793729146000189],
            [126.9406543380002, 41.771766663000122],
            [126.94613204000021, 41.766185608000015],
            [126.9585860600001, 41.766805726000158],
            [126.98116866100023, 41.763317566000026],
            [126.99963111400021, 41.752049230000111],
            [127.03315515200015, 41.731588237000111],
            [127.04266361600006, 41.732647604000093],
            [127.04679773000024, 41.719237569000043],
            [127.06834680200021, 41.69358022100009],
            [127.07661503100016, 41.680790303000109],
            [127.05961348500014, 41.682237244000092],
            [127.04896814000014, 41.67774139400008],
            [127.04545414300017, 41.668000387000049],
            [127.04943322800008, 41.653427633000078],
            [127.05899336800016, 41.643299052000103],
            [127.07403121000007, 41.63570261600016],
            [127.10467533400018, 41.626168315000157],
            [127.10188480700012, 41.622292583000032],
            [127.09723392800007, 41.612448222000054],
            [127.11397709200011, 41.610355327000079],
            [127.16606693600025, 41.594955750000096],
            [127.17970951300018, 41.585163066000021],
            [127.15785038300012, 41.578600159000032],
            [127.13650801600014, 41.569246725000099],
            [127.11800785300002, 41.555759176000137],
            [127.10467533400018, 41.53741404300014],
            [127.11196171100008, 41.536638896000127],
            [127.12085005700018, 41.534778544000133],
            [127.12823978800017, 41.531574605000159],
            [127.13464766500013, 41.522014466000158],
            [127.14239912900021, 41.523926494000179],
            [127.15593835500013, 41.53054107700008],
            [127.16513676000002, 41.528525696000131],
            [127.17035607900013, 41.523926494000179],
            [127.17433516500003, 41.519275614000108],
            [127.17970951300018, 41.51695017499999],
            [127.18725427300004, 41.521497702000133],
            [127.20032841000008, 41.542064922000023],
            [127.20709802300021, 41.545940653000159],
            [127.22198083500015, 41.540773010000166],
            [127.24451176000017, 41.52800893199999],
            [127.25830936700012, 41.525476786000112],
            [127.26745609600019, 41.527337138000021],
            [127.2746907960001, 41.530437725000141],
            [127.28146040900012, 41.53105784100002],
            [127.28895349200016, 41.525476786000112],
            [127.28978031500017, 41.518552145000044],
            [127.28357914200009, 41.512195944],
            [127.2751558840001, 41.507338359000173],
            [127.26905806500022, 41.504961243000039],
            [127.28636967000014, 41.499018453000119],
            [127.30512821500022, 41.494677633000137],
            [127.34424727400008, 41.491318665000122],
            [127.35370406100006, 41.492713928000015],
            [127.37111901900008, 41.498088278000168],
            [127.37897383700013, 41.498811747000062],
            [127.38848230000011, 41.49602122],
            [127.39592370700021, 41.49137034100012],
            [127.40134973200011, 41.486822815000025],
            [127.40512211100011, 41.484497376000078],
            [127.42734297800015, 41.483102112],
            [127.44305261300022, 41.48801137300002],
            [127.45710860200015, 41.49472930900005],
            [127.47411014900015, 41.498811747000062],
            [127.49100834100014, 41.495401103000134],
            [127.50847497600003, 41.488166402000061],
            [127.52583825700012, 41.484342346000048],
            [127.54289147900025, 41.491318665000122],
            [127.54475183100024, 41.468891093000039],
            [127.55880782100016, 41.458245748000039],
            [127.59751346900018, 41.450339254000184],
            [127.63068973900019, 41.43287262000014],
            [127.63833785000017, 41.429875387000138],
            [127.64707116800005, 41.432975973000069],
            [127.65812992400006, 41.446566874000055],
            [127.66267745000019, 41.446980286000084],
            [127.69027266500009, 41.438970439000101],
            [127.78463383000022, 41.43690338200004],
            [127.81036869300013, 41.443569641000138],
            [127.82886885700023, 41.433957825000121],
            [127.84886763600016, 41.431322327000132],
            [127.8651457120001, 41.437420146000179],
            [127.87186364800013, 41.453749899000016],
            [127.88147546400015, 41.460881246000056],
            [127.90359297800015, 41.466255595000021],
            [127.94421065300017, 41.470854798000161],
            [127.97423466100022, 41.450339254000184],
            [127.98446659400022, 41.454008281000185],
            [127.99273482300018, 41.461139629000044],
            [128.00141646300014, 41.470854798000161],
            [128.01237186700021, 41.460106100000061],
            [128.01309533700012, 41.4422777300001],
            [128.01154504500019, 41.424346009000132],
            [128.01516239500009, 41.413287252000131],
            [128.02384403500005, 41.41147857700004],
            [128.0336625570001, 41.413752340000045],
            [128.05061242700017, 41.420728658000044],
            [128.04689172400018, 41.398766174000073],
            [128.06415165200016, 41.388430888000173],
            [128.08812951700011, 41.388895976000086],
            [128.10394250500011, 41.399592998000102],
            [128.11820520100017, 41.393443502000039],
            [128.10394250500011, 41.379129130000038],
            [128.14611047400015, 41.376338603000178],
            [128.18569462100018, 41.404450582000109],
            [128.28760054600011, 41.544700419000137],
            [128.3030001220001, 41.583406067000126],
            [128.28325972500019, 41.605058492000026],
            [128.28139937300003, 41.622990215000087],
            [128.26899703000007, 41.643376566000043],
            [128.25091027900012, 41.66011973100008],
            [128.22414188700023, 41.669447327000043],
            [128.18600467900009, 41.692908427000091],
            [128.15913293500009, 41.711822001000044],
            [128.15489546700022, 41.733758647000101],
            [128.14425012300015, 41.75471344099999],
            [128.0970178630001, 41.822616272000133],
            [128.09102339800009, 41.841736552000143],
            [128.05764042200011, 41.849849752000139],
            [128.04782190000006, 41.863983256],
            [128.03996708200012, 41.879589539000122],
            [128.02043339100018, 41.965062358000139],
            [128.02146691900018, 41.979815979000151],
            [128.03459273300015, 41.993742778000055],
            [128.05464318900019, 41.998316142000178],
            [128.10053186000007, 41.99909128899999],
            [128.26196903500013, 42.032861837000056],
            [128.36377160700019, 42.026479797000135],
            [128.38712935400022, 42.020692038000064],
            [128.4075932210001, 42.008341370000025],
            [128.45399865800019, 42.010330912000043],
            [128.48831180900012, 41.99751515800007],
            [128.50236779900015, 41.996094055000171],
            [128.54370894400009, 42.008057149000038],
            [128.55332076100009, 42.006636048000033],
            [128.56437951700011, 42.000796611000155],
            [128.57368127500004, 42.004310609000115],
            [128.58773726400014, 42.019994406000151],
            [128.59755578700012, 42.027125753000021],
            [128.60789107300005, 42.030174663000039],
            [128.68251184100009, 42.025730489000026],
            [128.70928023300016, 42.030174663000039],
            [128.72095910700014, 42.047589621000057],
            [128.81707727100016, 42.043868918000058],
            [128.88317189700015, 42.030408857000154],
            [128.90348026500013, 42.026273092000068],
            [128.94006718000017, 42.035368144000145],
            [128.95484663900018, 42.075236511000085],
            [128.96383833800022, 42.088517355000093],
            [128.98502567600011, 42.092909852000091],
            [129.00890018800024, 42.095080262000167],
            [129.02657352700018, 42.101875712000108],
            [129.05334192000024, 42.122856344000027],
            [129.06347050000002, 42.136085511000104],
            [129.06471073400022, 42.153758850000102],
            [129.08848189400018, 42.14319102000006],
            [129.10553511600014, 42.14210581500005],
            [129.11979781200003, 42.149366353000019],
            [129.14801314300016, 42.173705953000038],
            [129.17767541600014, 42.19344635],
            [129.19638228400012, 42.209181824000083],
            [129.20423710200023, 42.214323629000077],
            [129.21116174300013, 42.219956360000097],
            [129.21426232900015, 42.22569244400016],
            [129.20930139200013, 42.234684144000099],
            [129.18687382000022, 42.236027731000078],
            [129.18077600100017, 42.243107402000092],
            [129.18335982300007, 42.254553732000105],
            [129.19214481700024, 42.259850566000139],
            [129.20289351500017, 42.262744446000127],
            [129.21116174300013, 42.267007752],
            [129.2147790930002, 42.275637716000048],
            [129.21415897700015, 42.283699239000114],
            [129.21612268100009, 42.291063131000115],
            [129.22800826000011, 42.297729391000033],
            [129.22067020700004, 42.303672180000049],
            [129.21240197700016, 42.307754618000061],
            [129.20372033700019, 42.310157573000097],
            [129.19390181500015, 42.31077769000008],
            [129.19390181500015, 42.31824493400002],
            [129.2261479090001, 42.318141581000177],
            [129.23627648900006, 42.323877666000143],
            [129.24227095600017, 42.338734640000084],
            [129.24030725200024, 42.344031474000118],
            [129.23420943300019, 42.346563619],
            [129.22862837800008, 42.350206808000095],
            [129.22800826000011, 42.359198507000045],
            [129.23110884600024, 42.367647603000037],
            [129.23606978400019, 42.374365540000056],
            [129.24309777900012, 42.378706361000027],
            [129.29663456200015, 42.387232971000131],
            [129.30376591000018, 42.389584249000094],
            [129.30707320200011, 42.407619324000095],
            [129.31544478400016, 42.422321269000079],
            [129.32650354000018, 42.42676544199999],
            [129.33787235600008, 42.414414775000054],
            [129.35213505000004, 42.420615947000115],
            [129.34376346800016, 42.429220073000138],
            [129.33859582500023, 42.438366801000043],
            [129.34014611800009, 42.445472311000074],
            [129.35213505000004, 42.447952779000147],
            [129.36309045400012, 42.445007223000133],
            [129.37208215400014, 42.432553202000165],
            [129.38624149600011, 42.428109029000055],
            [129.39543990100017, 42.43020192500002],
            [129.40618859900022, 42.435963847],
            [129.41879764900014, 42.44105397599999],
            [129.4340938720002, 42.441105653000093],
            [129.48246301300006, 42.410719910000026],
            [129.48887089100018, 42.402555034000116],
            [129.51708622300023, 42.390953674000158],
            [129.52339074700015, 42.38338307700009],
            [129.52680139200018, 42.373073629000103],
            [129.53517297400006, 42.369559631000143],
            [129.54530155500012, 42.371316630000038],
            [129.55418990100023, 42.376871847000032],
            [129.55946089700015, 42.387000427000189],
            [129.5602877200001, 42.39679311200014],
            [129.56318160000009, 42.404105327000039],
            [129.58767622900021, 42.411365866000111],
            [129.59449751800017, 42.42221791700014],
            [129.59563440000002, 42.435602112000097],
            [129.59170699100005, 42.447952779000147],
            [129.63346154900009, 42.445420634000143],
            [129.67118534400012, 42.437927552000033],
            [129.70332808500015, 42.442371724000125],
            [129.72885624200009, 42.475858053000039],
            [129.73361047400019, 42.511799011],
            [129.73144006400017, 42.550918071000027],
            [129.73650435400017, 42.585153707000032],
            [129.7630660400001, 42.60623769200005],
            [129.7630660400001, 42.612387187000039],
            [129.74621952300012, 42.615513611000083],
            [129.74156864400007, 42.629001160000044],
            [129.75107710800003, 42.642927958000129],
            [129.77670861800019, 42.647191264000085],
            [129.77226444600009, 42.661660665000014],
            [129.77939579300013, 42.670393982000078],
            [129.78756066900021, 42.676853536000138],
            [129.78652714100016, 42.684734192000079],
            [129.7745382090001, 42.69333831800013],
            [129.76440962700022, 42.696852315000072],
            [129.75758833900002, 42.703647766000032],
            [129.75562463400016, 42.722277120000072],
            [129.75727828000012, 42.734705302000165],
            [129.76130904200019, 42.74604827900005],
            [129.77350468000012, 42.766951396000124],
            [129.78952437400008, 42.792479553000149],
            [129.79500207600009, 42.806483866000164],
            [129.79903283700006, 42.836662903000061],
            [129.83417281100012, 42.90748545400011],
            [129.84151086400018, 42.917794902000097],
            [129.84667850800011, 42.928233541000125],
            [129.84760868300023, 42.951281230000021],
            [129.85174279800017, 42.961900736000089],
            [129.86311161300009, 42.968980408000036],
            [129.89329065000007, 42.971538391000095],
            [129.9070365810002, 42.976137593000075],
            [129.89773482300009, 42.978747253000066],
            [129.89008671100018, 42.982803854000068],
            [129.88409224500018, 42.988514099000113],
            [129.87975142500002, 42.996033020000155],
            [129.89070682800016, 42.999417827000073],
            [129.9092069910001, 43.008435364000135],
            [129.92067915900012, 43.010269878000045],
            [129.93411503100006, 43.008409526000136],
            [129.94155643700017, 43.003629456000127],
            [129.9717354740001, 42.973734640000103],
            [129.98134729000017, 42.966732484000104],
            [129.99571333800012, 42.961900736000089],
          ],
        ],
      ],
    },
  },

  // US state features
  {
    type: 'Feature',
    id: 'US_AL',
    properties: { name: 'Alabama' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-87.359296, 35.00118],
          [-85.606675, 34.984749],
          [-85.431413, 34.124869],
          [-85.184951, 32.859696],
          [-85.069935, 32.580372],
          [-84.960397, 32.421541],
          [-85.004212, 32.322956],
          [-84.889196, 32.262709],
          [-85.058981, 32.13674],
          [-85.053504, 32.01077],
          [-85.141136, 31.840985],
          [-85.042551, 31.539753],
          [-85.113751, 31.27686],
          [-85.004212, 31.003013],
          [-85.497137, 30.997536],
          [-87.600282, 30.997536],
          [-87.633143, 30.86609],
          [-87.408589, 30.674397],
          [-87.446927, 30.510088],
          [-87.37025, 30.427934],
          [-87.518128, 30.280057],
          [-87.655051, 30.247195],
          [-87.90699, 30.411504],
          [-87.934375, 30.657966],
          [-88.011052, 30.685351],
          [-88.10416, 30.499135],
          [-88.137022, 30.318396],
          [-88.394438, 30.367688],
          [-88.471115, 31.895754],
          [-88.241084, 33.796253],
          [-88.098683, 34.891641],
          [-88.202745, 34.995703],
          [-87.359296, 35.00118],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_AK',
    properties: { name: 'Alaska' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-131.602021, 55.117982],
            [-131.569159, 55.28229],
            [-131.355558, 55.183705],
            [-131.38842, 55.01392],
            [-131.645836, 55.035827],
            [-131.602021, 55.117982],
          ],
        ],
        [
          [
            [-131.832052, 55.42469],
            [-131.645836, 55.304197],
            [-131.749898, 55.128935],
            [-131.832052, 55.189182],
            [-131.832052, 55.42469],
          ],
        ],
        [
          [
            [-132.976733, 56.437924],
            [-132.735747, 56.459832],
            [-132.631685, 56.421493],
            [-132.664547, 56.273616],
            [-132.878148, 56.240754],
            [-133.069841, 56.333862],
            [-132.976733, 56.437924],
          ],
        ],
        [
          [
            [-133.595627, 56.350293],
            [-133.162949, 56.317431],
            [-133.05341, 56.125739],
            [-132.620732, 55.912138],
            [-132.472854, 55.780691],
            [-132.4619, 55.671152],
            [-132.357838, 55.649245],
            [-132.341408, 55.506844],
            [-132.166146, 55.364444],
            [-132.144238, 55.238474],
            [-132.029222, 55.276813],
            [-131.97993, 55.178228],
            [-131.958022, 54.789365],
            [-132.029222, 54.701734],
            [-132.308546, 54.718165],
            [-132.385223, 54.915335],
            [-132.483808, 54.898904],
            [-132.686455, 55.046781],
            [-132.746701, 54.997489],
            [-132.916486, 55.046781],
            [-132.889102, 54.898904],
            [-132.73027, 54.937242],
            [-132.626209, 54.882473],
            [-132.675501, 54.679826],
            [-132.867194, 54.701734],
            [-133.157472, 54.95915],
            [-133.239626, 55.090597],
            [-133.223195, 55.22752],
            [-133.453227, 55.216566],
            [-133.453227, 55.320628],
            [-133.277964, 55.331582],
            [-133.102702, 55.42469],
            [-133.17938, 55.588998],
            [-133.387503, 55.62186],
            [-133.420365, 55.884753],
            [-133.497042, 56.0162],
            [-133.639442, 55.923092],
            [-133.694212, 56.070969],
            [-133.546335, 56.142169],
            [-133.666827, 56.311955],
            [-133.595627, 56.350293],
          ],
        ],
        [
          [
            [-133.738027, 55.556137],
            [-133.546335, 55.490413],
            [-133.414888, 55.572568],
            [-133.283441, 55.534229],
            [-133.420365, 55.386352],
            [-133.633966, 55.430167],
            [-133.738027, 55.556137],
          ],
        ],
        [
          [
            [-133.907813, 56.930849],
            [-134.050213, 57.029434],
            [-133.885905, 57.095157],
            [-133.343688, 57.002049],
            [-133.102702, 57.007526],
            [-132.932917, 56.82131],
            [-132.620732, 56.667956],
            [-132.653593, 56.55294],
            [-132.817901, 56.492694],
            [-133.042456, 56.520078],
            [-133.201287, 56.448878],
            [-133.420365, 56.492694],
            [-133.66135, 56.448878],
            [-133.710643, 56.684386],
            [-133.688735, 56.837741],
            [-133.869474, 56.843218],
            [-133.907813, 56.930849],
          ],
        ],
        [
          [
            [-134.115936, 56.48174],
            [-134.25286, 56.558417],
            [-134.400737, 56.722725],
            [-134.417168, 56.848695],
            [-134.296675, 56.908941],
            [-134.170706, 56.848695],
            [-134.143321, 56.952757],
            [-133.748981, 56.772017],
            [-133.710643, 56.596755],
            [-133.847566, 56.574848],
            [-133.935197, 56.377678],
            [-133.836612, 56.322908],
            [-133.957105, 56.092877],
            [-134.110459, 56.142169],
            [-134.132367, 55.999769],
            [-134.230952, 56.070969],
            [-134.291198, 56.350293],
            [-134.115936, 56.48174],
          ],
        ],
        [
          [
            [-134.636246, 56.28457],
            [-134.669107, 56.169554],
            [-134.806031, 56.235277],
            [-135.178463, 56.67891],
            [-135.413971, 56.810356],
            [-135.331817, 56.914418],
            [-135.424925, 57.166357],
            [-135.687818, 57.369004],
            [-135.419448, 57.566174],
            [-135.298955, 57.48402],
            [-135.063447, 57.418296],
            [-134.849846, 57.407343],
            [-134.844369, 57.248511],
            [-134.636246, 56.728202],
            [-134.636246, 56.28457],
          ],
        ],
        [
          [
            [-134.712923, 58.223407],
            [-134.373353, 58.14673],
            [-134.176183, 58.157683],
            [-134.187137, 58.081006],
            [-133.902336, 57.807159],
            [-134.099505, 57.850975],
            [-134.148798, 57.757867],
            [-133.935197, 57.615466],
            [-133.869474, 57.363527],
            [-134.083075, 57.297804],
            [-134.154275, 57.210173],
            [-134.499322, 57.029434],
            [-134.603384, 57.034911],
            [-134.6472, 57.226604],
            [-134.575999, 57.341619],
            [-134.608861, 57.511404],
            [-134.729354, 57.719528],
            [-134.707446, 57.829067],
            [-134.784123, 58.097437],
            [-134.91557, 58.212453],
            [-134.953908, 58.409623],
            [-134.712923, 58.223407],
          ],
        ],
        [
          [
            [-135.857603, 57.330665],
            [-135.715203, 57.330665],
            [-135.567326, 57.149926],
            [-135.633049, 57.023957],
            [-135.857603, 56.996572],
            [-135.824742, 57.193742],
            [-135.857603, 57.330665],
          ],
        ],
        [
          [
            [-136.279328, 58.206976],
            [-135.978096, 58.201499],
            [-135.780926, 58.28913],
            [-135.496125, 58.168637],
            [-135.64948, 58.037191],
            [-135.59471, 57.987898],
            [-135.45231, 58.135776],
            [-135.107263, 58.086483],
            [-134.91557, 57.976944],
            [-135.025108, 57.779775],
            [-134.937477, 57.763344],
            [-134.822462, 57.500451],
            [-135.085355, 57.462112],
            [-135.572802, 57.675713],
            [-135.556372, 57.456635],
            [-135.709726, 57.369004],
            [-135.890465, 57.407343],
            [-136.000004, 57.544266],
            [-136.208128, 57.637374],
            [-136.366959, 57.829067],
            [-136.569606, 57.916698],
            [-136.558652, 58.075529],
            [-136.421728, 58.130299],
            [-136.377913, 58.267222],
            [-136.279328, 58.206976],
          ],
        ],
        [
          [
            [-147.079854, 60.200582],
            [-147.501579, 59.948643],
            [-147.53444, 59.850058],
            [-147.874011, 59.784335],
            [-147.80281, 59.937689],
            [-147.435855, 60.09652],
            [-147.205824, 60.271782],
            [-147.079854, 60.200582],
          ],
        ],
        [
          [
            [-147.561825, 60.578491],
            [-147.616594, 60.370367],
            [-147.758995, 60.156767],
            [-147.956165, 60.227967],
            [-147.791856, 60.474429],
            [-147.561825, 60.578491],
          ],
        ],
        [
          [
            [-147.786379, 70.245291],
            [-147.682318, 70.201475],
            [-147.162008, 70.15766],
            [-146.888161, 70.185044],
            [-146.510252, 70.185044],
            [-146.099482, 70.146706],
            [-145.858496, 70.168614],
            [-145.622988, 70.08646],
            [-145.195787, 69.993352],
            [-144.620708, 69.971444],
            [-144.461877, 70.026213],
            [-144.078491, 70.059075],
            [-143.914183, 70.130275],
            [-143.497935, 70.141229],
            [-143.503412, 70.091936],
            [-143.25695, 70.119321],
            [-142.747594, 70.042644],
            [-142.402547, 69.916674],
            [-142.079408, 69.856428],
            [-142.008207, 69.801659],
            [-141.712453, 69.790705],
            [-141.433129, 69.697597],
            [-141.378359, 69.63735],
            [-141.208574, 69.686643],
            [-141.00045, 69.648304],
            [-141.00045, 60.304644],
            [-140.53491, 60.22249],
            [-140.474664, 60.310121],
            [-139.987216, 60.184151],
            [-139.696939, 60.342983],
            [-139.088998, 60.359413],
            [-139.198537, 60.091043],
            [-139.045183, 59.997935],
            [-138.700135, 59.910304],
            [-138.623458, 59.767904],
            [-137.604747, 59.242118],
            [-137.445916, 58.908024],
            [-137.265177, 59.001132],
            [-136.827022, 59.159963],
            [-136.580559, 59.16544],
            [-136.465544, 59.285933],
            [-136.476498, 59.466672],
            [-136.301236, 59.466672],
            [-136.25742, 59.625503],
            [-135.945234, 59.663842],
            [-135.479694, 59.800766],
            [-135.025108, 59.565257],
            [-135.068924, 59.422857],
            [-134.959385, 59.280456],
            [-134.701969, 59.247595],
            [-134.378829, 59.033994],
            [-134.400737, 58.973748],
            [-134.25286, 58.858732],
            [-133.842089, 58.727285],
            [-133.173903, 58.152206],
            [-133.075318, 57.998852],
            [-132.867194, 57.845498],
            [-132.560485, 57.505928],
            [-132.253777, 57.21565],
            [-132.368792, 57.095157],
            [-132.05113, 57.051341],
            [-132.127807, 56.876079],
            [-131.870391, 56.804879],
            [-131.837529, 56.602232],
            [-131.580113, 56.613186],
            [-131.087188, 56.405062],
            [-130.78048, 56.366724],
            [-130.621648, 56.268139],
            [-130.468294, 56.240754],
            [-130.424478, 56.142169],
            [-130.101339, 56.114785],
            [-130.002754, 55.994292],
            [-130.150631, 55.769737],
            [-130.128724, 55.583521],
            [-129.986323, 55.276813],
            [-130.095862, 55.200136],
            [-130.336847, 54.920812],
            [-130.687372, 54.718165],
            [-130.785957, 54.822227],
            [-130.917403, 54.789365],
            [-131.010511, 54.997489],
            [-130.983126, 55.08512],
            [-131.092665, 55.189182],
            [-130.862634, 55.298721],
            [-130.928357, 55.337059],
            [-131.158389, 55.200136],
            [-131.284358, 55.287767],
            [-131.426759, 55.238474],
            [-131.843006, 55.457552],
            [-131.700606, 55.698537],
            [-131.963499, 55.616383],
            [-131.974453, 55.49589],
            [-132.182576, 55.588998],
            [-132.226392, 55.704014],
            [-132.083991, 55.829984],
            [-132.127807, 55.955953],
            [-132.324977, 55.851892],
            [-132.522147, 56.076446],
            [-132.642639, 56.032631],
            [-132.719317, 56.218847],
            [-132.527624, 56.339339],
            [-132.341408, 56.339339],
            [-132.396177, 56.487217],
            [-132.297592, 56.67891],
            [-132.450946, 56.673433],
            [-132.768609, 56.837741],
            [-132.993164, 57.034911],
            [-133.51895, 57.177311],
            [-133.507996, 57.577128],
            [-133.677781, 57.62642],
            [-133.639442, 57.790728],
            [-133.814705, 57.834544],
            [-134.072121, 58.053622],
            [-134.143321, 58.168637],
            [-134.586953, 58.206976],
            [-135.074401, 58.502731],
            [-135.282525, 59.192825],
            [-135.38111, 59.033994],
            [-135.337294, 58.891593],
            [-135.140124, 58.617746],
            [-135.189417, 58.573931],
            [-135.05797, 58.349376],
            [-135.085355, 58.201499],
            [-135.277048, 58.234361],
            [-135.430402, 58.398669],
            [-135.633049, 58.426053],
            [-135.91785, 58.382238],
            [-135.912373, 58.617746],
            [-136.087635, 58.814916],
            [-136.246466, 58.75467],
            [-136.876314, 58.962794],
            [-136.931084, 58.902547],
            [-136.586036, 58.836824],
            [-136.317666, 58.672516],
            [-136.213604, 58.667039],
            [-136.180743, 58.535592],
            [-136.043819, 58.382238],
            [-136.388867, 58.294607],
            [-136.591513, 58.349376],
            [-136.59699, 58.212453],
            [-136.859883, 58.316515],
            [-136.947514, 58.393192],
            [-137.111823, 58.393192],
            [-137.566409, 58.590362],
            [-137.900502, 58.765624],
            [-137.933364, 58.869686],
            [-138.11958, 59.02304],
            [-138.634412, 59.132579],
            [-138.919213, 59.247595],
            [-139.417615, 59.379041],
            [-139.746231, 59.505011],
            [-139.718846, 59.641934],
            [-139.625738, 59.598119],
            [-139.5162, 59.68575],
            [-139.625738, 59.88292],
            [-139.488815, 59.992458],
            [-139.554538, 60.041751],
            [-139.801, 59.833627],
            [-140.315833, 59.696704],
            [-140.92925, 59.745996],
            [-141.444083, 59.871966],
            [-141.46599, 59.970551],
            [-141.706976, 59.948643],
            [-141.964392, 60.019843],
            [-142.539471, 60.085566],
            [-142.873564, 60.091043],
            [-143.623905, 60.036274],
            [-143.892275, 59.997935],
            [-144.231845, 60.140336],
            [-144.65357, 60.206059],
            [-144.785016, 60.29369],
            [-144.834309, 60.441568],
            [-145.124586, 60.430614],
            [-145.223171, 60.299167],
            [-145.738004, 60.474429],
            [-145.820158, 60.551106],
            [-146.351421, 60.408706],
            [-146.608837, 60.238921],
            [-146.718376, 60.397752],
            [-146.608837, 60.485383],
            [-146.455483, 60.463475],
            [-145.951604, 60.578491],
            [-146.017328, 60.666122],
            [-146.252836, 60.622307],
            [-146.345944, 60.737322],
            [-146.565022, 60.753753],
            [-146.784099, 61.044031],
            [-146.866253, 60.972831],
            [-147.172962, 60.934492],
            [-147.271547, 60.972831],
            [-147.375609, 60.879723],
            [-147.758995, 60.912584],
            [-147.775426, 60.808523],
            [-148.032842, 60.781138],
            [-148.153334, 60.819476],
            [-148.065703, 61.005692],
            [-148.175242, 61.000215],
            [-148.350504, 60.803046],
            [-148.109519, 60.737322],
            [-148.087611, 60.594922],
            [-147.939734, 60.441568],
            [-148.027365, 60.277259],
            [-148.219058, 60.332029],
            [-148.273827, 60.249875],
            [-148.087611, 60.217013],
            [-147.983549, 59.997935],
            [-148.251919, 59.95412],
            [-148.399797, 59.997935],
            [-148.635305, 59.937689],
            [-148.755798, 59.986981],
            [-149.067984, 59.981505],
            [-149.05703, 60.063659],
            [-149.204907, 60.008889],
            [-149.287061, 59.904827],
            [-149.418508, 59.997935],
            [-149.582816, 59.866489],
            [-149.511616, 59.806242],
            [-149.741647, 59.729565],
            [-149.949771, 59.718611],
            [-150.031925, 59.61455],
            [-150.25648, 59.521442],
            [-150.409834, 59.554303],
            [-150.579619, 59.444764],
            [-150.716543, 59.450241],
            [-151.001343, 59.225687],
            [-151.308052, 59.209256],
            [-151.406637, 59.280456],
            [-151.592853, 59.159963],
            [-151.976239, 59.253071],
            [-151.888608, 59.422857],
            [-151.636669, 59.483103],
            [-151.47236, 59.472149],
            [-151.423068, 59.537872],
            [-151.127313, 59.669319],
            [-151.116359, 59.778858],
            [-151.505222, 59.63098],
            [-151.828361, 59.718611],
            [-151.8667, 59.778858],
            [-151.702392, 60.030797],
            [-151.423068, 60.211536],
            [-151.379252, 60.359413],
            [-151.297098, 60.386798],
            [-151.264237, 60.545629],
            [-151.406637, 60.720892],
            [-151.06159, 60.786615],
            [-150.404357, 61.038554],
            [-150.245526, 60.939969],
            [-150.042879, 60.912584],
            [-149.741647, 61.016646],
            [-150.075741, 61.15357],
            [-150.207187, 61.257632],
            [-150.47008, 61.246678],
            [-150.656296, 61.29597],
            [-150.711066, 61.252155],
            [-151.023251, 61.180954],
            [-151.165652, 61.044031],
            [-151.477837, 61.011169],
            [-151.800977, 60.852338],
            [-151.833838, 60.748276],
            [-152.080301, 60.693507],
            [-152.13507, 60.578491],
            [-152.310332, 60.507291],
            [-152.392486, 60.304644],
            [-152.732057, 60.173197],
            [-152.567748, 60.069136],
            [-152.704672, 59.915781],
            [-153.022334, 59.888397],
            [-153.049719, 59.691227],
            [-153.345474, 59.620026],
            [-153.438582, 59.702181],
            [-153.586459, 59.548826],
            [-153.761721, 59.543349],
            [-153.72886, 59.433811],
            [-154.117723, 59.368087],
            [-154.1944, 59.066856],
            [-153.750768, 59.050425],
            [-153.400243, 58.968271],
            [-153.301658, 58.869686],
            [-153.444059, 58.710854],
            [-153.679567, 58.612269],
            [-153.898645, 58.606793],
            [-153.920553, 58.519161],
            [-154.062953, 58.4863],
            [-153.99723, 58.376761],
            [-154.145107, 58.212453],
            [-154.46277, 58.059098],
            [-154.643509, 58.059098],
            [-154.818771, 58.004329],
            [-154.988556, 58.015283],
            [-155.120003, 57.955037],
            [-155.081664, 57.872883],
            [-155.328126, 57.829067],
            [-155.377419, 57.708574],
            [-155.547204, 57.785251],
            [-155.73342, 57.549743],
            [-156.045606, 57.566174],
            [-156.023698, 57.440204],
            [-156.209914, 57.473066],
            [-156.34136, 57.418296],
            [-156.34136, 57.248511],
            [-156.549484, 56.985618],
            [-156.883577, 56.952757],
            [-157.157424, 56.832264],
            [-157.20124, 56.766541],
            [-157.376502, 56.859649],
            [-157.672257, 56.607709],
            [-157.754411, 56.67891],
            [-157.918719, 56.657002],
            [-157.957058, 56.514601],
            [-158.126843, 56.459832],
            [-158.32949, 56.48174],
            [-158.488321, 56.339339],
            [-158.208997, 56.295524],
            [-158.510229, 55.977861],
            [-159.375585, 55.873799],
            [-159.616571, 55.594475],
            [-159.676817, 55.654722],
            [-159.643955, 55.829984],
            [-159.813741, 55.857368],
            [-160.027341, 55.791645],
            [-160.060203, 55.720445],
            [-160.394296, 55.605429],
            [-160.536697, 55.473983],
            [-160.580512, 55.567091],
            [-160.668143, 55.457552],
            [-160.865313, 55.528752],
            [-161.232268, 55.358967],
            [-161.506115, 55.364444],
            [-161.467776, 55.49589],
            [-161.588269, 55.62186],
            [-161.697808, 55.517798],
            [-161.686854, 55.408259],
            [-162.053809, 55.074166],
            [-162.179779, 55.15632],
            [-162.218117, 55.03035],
            [-162.470057, 55.052258],
            [-162.508395, 55.249428],
            [-162.661749, 55.293244],
            [-162.716519, 55.222043],
            [-162.579595, 55.134412],
            [-162.645319, 54.997489],
            [-162.847965, 54.926289],
            [-163.00132, 55.079643],
            [-163.187536, 55.090597],
            [-163.220397, 55.03035],
            [-163.034181, 54.942719],
            [-163.373752, 54.800319],
            [-163.14372, 54.76198],
            [-163.138243, 54.696257],
            [-163.329936, 54.74555],
            [-163.587352, 54.614103],
            [-164.085754, 54.61958],
            [-164.332216, 54.531949],
            [-164.354124, 54.466226],
            [-164.638925, 54.389548],
            [-164.847049, 54.416933],
            [-164.918249, 54.603149],
            [-164.710125, 54.663395],
            [-164.551294, 54.88795],
            [-164.34317, 54.893427],
            [-163.894061, 55.041304],
            [-163.532583, 55.046781],
            [-163.39566, 54.904381],
            [-163.291598, 55.008443],
            [-163.313505, 55.128935],
            [-163.105382, 55.183705],
            [-162.880827, 55.183705],
            [-162.579595, 55.446598],
            [-162.245502, 55.682106],
            [-161.807347, 55.89023],
            [-161.292514, 55.983338],
            [-161.078914, 55.939523],
            [-160.87079, 55.999769],
            [-160.816021, 55.912138],
            [-160.931036, 55.813553],
            [-160.805067, 55.736876],
            [-160.766728, 55.857368],
            [-160.509312, 55.868322],
            [-160.438112, 55.791645],
            [-160.27928, 55.76426],
            [-160.273803, 55.857368],
            [-160.536697, 55.939523],
            [-160.558604, 55.994292],
            [-160.383342, 56.251708],
            [-160.147834, 56.399586],
            [-159.830171, 56.541986],
            [-159.326293, 56.667956],
            [-158.959338, 56.848695],
            [-158.784076, 56.782971],
            [-158.641675, 56.810356],
            [-158.701922, 56.925372],
            [-158.658106, 57.034911],
            [-158.378782, 57.264942],
            [-157.995396, 57.41282],
            [-157.688688, 57.609989],
            [-157.705118, 57.719528],
            [-157.458656, 58.497254],
            [-157.07527, 58.705377],
            [-157.119086, 58.869686],
            [-158.039212, 58.634177],
            [-158.32949, 58.661562],
            [-158.40069, 58.760147],
            [-158.564998, 58.803962],
            [-158.619768, 58.913501],
            [-158.767645, 58.864209],
            [-158.860753, 58.694424],
            [-158.701922, 58.480823],
            [-158.893615, 58.387715],
            [-159.0634, 58.420577],
            [-159.392016, 58.760147],
            [-159.616571, 58.929932],
            [-159.731586, 58.929932],
            [-159.808264, 58.803962],
            [-159.906848, 58.782055],
            [-160.054726, 58.886116],
            [-160.235465, 58.902547],
            [-160.317619, 59.072332],
            [-160.854359, 58.88064],
            [-161.33633, 58.743716],
            [-161.374669, 58.667039],
            [-161.752577, 58.552023],
            [-161.938793, 58.656085],
            [-161.769008, 58.776578],
            [-161.829255, 59.061379],
            [-161.955224, 59.36261],
            [-161.703285, 59.48858],
            [-161.911409, 59.740519],
            [-162.092148, 59.88292],
            [-162.234548, 60.091043],
            [-162.448149, 60.178674],
            [-162.502918, 59.997935],
            [-162.760334, 59.959597],
            [-163.171105, 59.844581],
            [-163.66403, 59.795289],
            [-163.9324, 59.806242],
            [-164.162431, 59.866489],
            [-164.189816, 60.02532],
            [-164.386986, 60.074613],
            [-164.699171, 60.29369],
            [-164.962064, 60.337506],
            [-165.268773, 60.578491],
            [-165.060649, 60.68803],
            [-165.016834, 60.890677],
            [-165.175665, 60.846861],
            [-165.197573, 60.972831],
            [-165.120896, 61.076893],
            [-165.323543, 61.170001],
            [-165.34545, 61.071416],
            [-165.591913, 61.109754],
            [-165.624774, 61.279539],
            [-165.816467, 61.301447],
            [-165.920529, 61.416463],
            [-165.915052, 61.558863],
            [-166.106745, 61.49314],
            [-166.139607, 61.630064],
            [-165.904098, 61.662925],
            [-166.095791, 61.81628],
            [-165.756221, 61.827233],
            [-165.756221, 62.013449],
            [-165.674067, 62.139419],
            [-165.044219, 62.539236],
            [-164.912772, 62.659728],
            [-164.819664, 62.637821],
            [-164.874433, 62.807606],
            [-164.633448, 63.097884],
            [-164.425324, 63.212899],
            [-164.036462, 63.262192],
            [-163.73523, 63.212899],
            [-163.313505, 63.037637],
            [-163.039658, 63.059545],
            [-162.661749, 63.22933],
            [-162.272887, 63.486746],
            [-162.075717, 63.514131],
            [-162.026424, 63.448408],
            [-161.555408, 63.448408],
            [-161.13916, 63.503177],
            [-160.766728, 63.771547],
            [-160.766728, 63.837271],
            [-160.952944, 64.08921],
            [-160.974852, 64.237087],
            [-161.26513, 64.395918],
            [-161.374669, 64.532842],
            [-161.078914, 64.494503],
            [-160.79959, 64.609519],
            [-160.783159, 64.719058],
            [-161.144637, 64.921705],
            [-161.413007, 64.762873],
            [-161.664946, 64.790258],
            [-161.900455, 64.702627],
            [-162.168825, 64.680719],
            [-162.234548, 64.620473],
            [-162.541257, 64.532842],
            [-162.634365, 64.384965],
            [-162.787719, 64.324718],
            [-162.858919, 64.49998],
            [-163.045135, 64.538319],
            [-163.176582, 64.401395],
            [-163.253259, 64.467119],
            [-163.598306, 64.565704],
            [-164.304832, 64.560227],
            [-164.80871, 64.450688],
            [-165.000403, 64.434257],
            [-165.411174, 64.49998],
            [-166.188899, 64.576658],
            [-166.391546, 64.636904],
            [-166.484654, 64.735489],
            [-166.413454, 64.872412],
            [-166.692778, 64.987428],
            [-166.638008, 65.113398],
            [-166.462746, 65.179121],
            [-166.517516, 65.337952],
            [-166.796839, 65.337952],
            [-167.026871, 65.381768],
            [-167.47598, 65.414629],
            [-167.711489, 65.496784],
            [-168.072967, 65.578938],
            [-168.105828, 65.682999],
            [-167.541703, 65.819923],
            [-166.829701, 66.049954],
            [-166.3313, 66.186878],
            [-166.046499, 66.110201],
            [-165.756221, 66.09377],
            [-165.690498, 66.203309],
            [-165.86576, 66.21974],
            [-165.88219, 66.312848],
            [-165.186619, 66.466202],
            [-164.403417, 66.581218],
            [-163.981692, 66.592172],
            [-163.751661, 66.553833],
            [-163.872153, 66.389525],
            [-163.828338, 66.274509],
            [-163.915969, 66.192355],
            [-163.768091, 66.060908],
            [-163.494244, 66.082816],
            [-163.149197, 66.060908],
            [-162.749381, 66.088293],
            [-162.634365, 66.039001],
            [-162.371472, 66.028047],
            [-162.14144, 66.077339],
            [-161.840208, 66.02257],
            [-161.549931, 66.241647],
            [-161.341807, 66.252601],
            [-161.199406, 66.208786],
            [-161.128206, 66.334755],
            [-161.528023, 66.395002],
            [-161.911409, 66.345709],
            [-161.87307, 66.510017],
            [-162.174302, 66.68528],
            [-162.502918, 66.740049],
            [-162.601503, 66.89888],
            [-162.344087, 66.937219],
            [-162.015471, 66.778388],
            [-162.075717, 66.652418],
            [-161.916886, 66.553833],
            [-161.571838, 66.438817],
            [-161.489684, 66.55931],
            [-161.884024, 66.718141],
            [-161.714239, 67.002942],
            [-161.851162, 67.052235],
            [-162.240025, 66.991988],
            [-162.639842, 67.008419],
            [-162.700088, 67.057712],
            [-162.902735, 67.008419],
            [-163.740707, 67.128912],
            [-163.757138, 67.254881],
            [-164.009077, 67.534205],
            [-164.211724, 67.638267],
            [-164.534863, 67.725898],
            [-165.192096, 67.966884],
            [-165.493328, 68.059992],
            [-165.794559, 68.081899],
            [-166.243668, 68.246208],
            [-166.681824, 68.339316],
            [-166.703731, 68.372177],
            [-166.375115, 68.42147],
            [-166.227238, 68.574824],
            [-166.216284, 68.881533],
            [-165.329019, 68.859625],
            [-164.255539, 68.930825],
            [-163.976215, 68.985595],
            [-163.532583, 69.138949],
            [-163.110859, 69.374457],
            [-163.023228, 69.609966],
            [-162.842489, 69.812613],
            [-162.470057, 69.982398],
            [-162.311225, 70.108367],
            [-161.851162, 70.311014],
            [-161.779962, 70.256245],
            [-161.396576, 70.239814],
            [-160.837928, 70.343876],
            [-160.487404, 70.453415],
            [-159.649432, 70.792985],
            [-159.33177, 70.809416],
            [-159.298908, 70.760123],
            [-158.975769, 70.798462],
            [-158.658106, 70.787508],
            [-158.033735, 70.831323],
            [-157.420318, 70.979201],
            [-156.812377, 71.285909],
            [-156.565915, 71.351633],
            [-156.522099, 71.296863],
            [-155.585543, 71.170894],
            [-155.508865, 71.083263],
            [-155.832005, 70.968247],
            [-155.979882, 70.96277],
            [-155.974405, 70.809416],
            [-155.503388, 70.858708],
            [-155.476004, 70.940862],
            [-155.262403, 71.017539],
            [-155.191203, 70.973724],
            [-155.032372, 71.148986],
            [-154.566832, 70.990155],
            [-154.643509, 70.869662],
            [-154.353231, 70.8368],
            [-154.183446, 70.7656],
            [-153.931507, 70.880616],
            [-153.487874, 70.886093],
            [-153.235935, 70.924431],
            [-152.589656, 70.886093],
            [-152.26104, 70.842277],
            [-152.419871, 70.606769],
            [-151.817408, 70.546523],
            [-151.773592, 70.486276],
            [-151.187559, 70.382214],
            [-151.182082, 70.431507],
            [-150.760358, 70.49723],
            [-150.355064, 70.491753],
            [-150.349588, 70.436984],
            [-150.114079, 70.431507],
            [-149.867617, 70.508184],
            [-149.462323, 70.519138],
            [-149.177522, 70.486276],
            [-148.78866, 70.404122],
            [-148.607921, 70.420553],
            [-148.350504, 70.305537],
            [-148.202627, 70.349353],
            [-147.961642, 70.316491],
            [-147.786379, 70.245291],
          ],
        ],
        [
          [
            [-152.94018, 58.026237],
            [-152.945657, 57.982421],
            [-153.290705, 58.048145],
            [-153.044242, 58.305561],
            [-152.819688, 58.327469],
            [-152.666333, 58.562977],
            [-152.496548, 58.354853],
            [-152.354148, 58.426053],
            [-152.080301, 58.311038],
            [-152.080301, 58.152206],
            [-152.480117, 58.130299],
            [-152.655379, 58.059098],
            [-152.94018, 58.026237],
          ],
        ],
        [
          [
            [-153.958891, 57.538789],
            [-153.67409, 57.670236],
            [-153.931507, 57.69762],
            [-153.936983, 57.812636],
            [-153.723383, 57.889313],
            [-153.570028, 57.834544],
            [-153.548121, 57.719528],
            [-153.46049, 57.796205],
            [-153.455013, 57.96599],
            [-153.268797, 57.889313],
            [-153.235935, 57.998852],
            [-153.071627, 57.933129],
            [-152.874457, 57.933129],
            [-152.721103, 57.993375],
            [-152.469163, 57.889313],
            [-152.469163, 57.599035],
            [-152.151501, 57.620943],
            [-152.359625, 57.42925],
            [-152.74301, 57.505928],
            [-152.60061, 57.379958],
            [-152.710149, 57.275896],
            [-152.907319, 57.325188],
            [-152.912796, 57.128019],
            [-153.214027, 57.073249],
            [-153.312612, 56.991095],
            [-153.498828, 57.067772],
            [-153.695998, 56.859649],
            [-153.849352, 56.837741],
            [-154.013661, 56.744633],
            [-154.073907, 56.969187],
            [-154.303938, 56.848695],
            [-154.314892, 56.919895],
            [-154.523016, 56.991095],
            [-154.539447, 57.193742],
            [-154.742094, 57.275896],
            [-154.627078, 57.511404],
            [-154.227261, 57.659282],
            [-153.980799, 57.648328],
            [-153.958891, 57.538789],
          ],
        ],
        [
          [
            [-154.53397, 56.602232],
            [-154.742094, 56.399586],
            [-154.807817, 56.432447],
            [-154.53397, 56.602232],
          ],
        ],
        [
          [
            [-155.634835, 55.923092],
            [-155.476004, 55.912138],
            [-155.530773, 55.704014],
            [-155.793666, 55.731399],
            [-155.837482, 55.802599],
            [-155.634835, 55.923092],
          ],
        ],
        [
          [
            [-159.890418, 55.28229],
            [-159.950664, 55.068689],
            [-160.257373, 54.893427],
            [-160.109495, 55.161797],
            [-160.005433, 55.134412],
            [-159.890418, 55.28229],
          ],
        ],
        [
          [
            [-160.520266, 55.358967],
            [-160.33405, 55.358967],
            [-160.339527, 55.249428],
            [-160.525743, 55.128935],
            [-160.690051, 55.211089],
            [-160.794113, 55.134412],
            [-160.854359, 55.320628],
            [-160.79959, 55.380875],
            [-160.520266, 55.358967],
          ],
        ],
        [
          [
            [-162.256456, 54.981058],
            [-162.234548, 54.893427],
            [-162.349564, 54.838658],
            [-162.437195, 54.931766],
            [-162.256456, 54.981058],
          ],
        ],
        [
          [
            [-162.415287, 63.634624],
            [-162.563165, 63.536039],
            [-162.612457, 63.62367],
            [-162.415287, 63.634624],
          ],
        ],
        [
          [
            [-162.80415, 54.488133],
            [-162.590549, 54.449795],
            [-162.612457, 54.367641],
            [-162.782242, 54.373118],
            [-162.80415, 54.488133],
          ],
        ],
        [
          [
            [-165.548097, 54.29644],
            [-165.476897, 54.181425],
            [-165.630251, 54.132132],
            [-165.685021, 54.252625],
            [-165.548097, 54.29644],
          ],
        ],
        [
          [
            [-165.73979, 54.15404],
            [-166.046499, 54.044501],
            [-166.112222, 54.121178],
            [-165.980775, 54.219763],
            [-165.73979, 54.15404],
          ],
        ],
        [
          [
            [-166.364161, 60.359413],
            [-166.13413, 60.397752],
            [-166.084837, 60.326552],
            [-165.88219, 60.342983],
            [-165.685021, 60.277259],
            [-165.646682, 59.992458],
            [-165.750744, 59.89935],
            [-166.00816, 59.844581],
            [-166.062929, 59.745996],
            [-166.440838, 59.855535],
            [-166.6161, 59.850058],
            [-166.994009, 59.992458],
            [-167.125456, 59.992458],
            [-167.344534, 60.074613],
            [-167.421211, 60.206059],
            [-167.311672, 60.238921],
            [-166.93924, 60.206059],
            [-166.763978, 60.310121],
            [-166.577762, 60.321075],
            [-166.495608, 60.392275],
            [-166.364161, 60.359413],
          ],
        ],
        [
          [
            [-166.375115, 54.01164],
            [-166.210807, 53.934962],
            [-166.5449, 53.748746],
            [-166.539423, 53.715885],
            [-166.117699, 53.852808],
            [-166.112222, 53.776131],
            [-166.282007, 53.683023],
            [-166.555854, 53.622777],
            [-166.583239, 53.529669],
            [-166.878994, 53.431084],
            [-167.13641, 53.425607],
            [-167.306195, 53.332499],
            [-167.623857, 53.250345],
            [-167.793643, 53.337976],
            [-167.459549, 53.442038],
            [-167.355487, 53.425607],
            [-167.103548, 53.513238],
            [-167.163794, 53.611823],
            [-167.021394, 53.715885],
            [-166.807793, 53.666592],
            [-166.785886, 53.732316],
            [-167.015917, 53.754223],
            [-167.141887, 53.825424],
            [-167.032348, 53.945916],
            [-166.643485, 54.017116],
            [-166.561331, 53.880193],
            [-166.375115, 54.01164],
          ],
        ],
        [
          [
            [-168.790446, 53.157237],
            [-168.40706, 53.34893],
            [-168.385152, 53.431084],
            [-168.237275, 53.524192],
            [-168.007243, 53.568007],
            [-167.886751, 53.518715],
            [-167.842935, 53.387268],
            [-168.270136, 53.244868],
            [-168.500168, 53.036744],
            [-168.686384, 52.965544],
            [-168.790446, 53.157237],
          ],
        ],
        [
          [
            [-169.74891, 52.894344],
            [-169.705095, 52.795759],
            [-169.962511, 52.790282],
            [-169.989896, 52.856005],
            [-169.74891, 52.894344],
          ],
        ],
        [
          [
            [-170.148727, 57.221127],
            [-170.28565, 57.128019],
            [-170.313035, 57.221127],
            [-170.148727, 57.221127],
          ],
        ],
        [
          [
            [-170.669036, 52.697174],
            [-170.603313, 52.604066],
            [-170.789529, 52.538343],
            [-170.816914, 52.636928],
            [-170.669036, 52.697174],
          ],
        ],
        [
          [
            [-171.742517, 63.716778],
            [-170.94836, 63.5689],
            [-170.488297, 63.69487],
            [-170.280174, 63.683916],
            [-170.093958, 63.612716],
            [-170.044665, 63.492223],
            [-169.644848, 63.4265],
            [-169.518879, 63.366254],
            [-168.99857, 63.338869],
            [-168.686384, 63.295053],
            [-168.856169, 63.147176],
            [-169.108108, 63.180038],
            [-169.376478, 63.152653],
            [-169.513402, 63.08693],
            [-169.639372, 62.939052],
            [-169.831064, 63.075976],
            [-170.055619, 63.169084],
            [-170.263743, 63.180038],
            [-170.362328, 63.2841],
            [-170.866206, 63.415546],
            [-171.101715, 63.421023],
            [-171.463193, 63.306007],
            [-171.73704, 63.366254],
            [-171.852055, 63.486746],
            [-171.742517, 63.716778],
          ],
        ],
        [
          [
            [-172.432611, 52.390465],
            [-172.41618, 52.275449],
            [-172.607873, 52.253542],
            [-172.569535, 52.352127],
            [-172.432611, 52.390465],
          ],
        ],
        [
          [
            [-173.626584, 52.14948],
            [-173.495138, 52.105664],
            [-173.122706, 52.111141],
            [-173.106275, 52.07828],
            [-173.549907, 52.028987],
            [-173.626584, 52.14948],
          ],
        ],
        [
          [
            [-174.322156, 52.280926],
            [-174.327632, 52.379511],
            [-174.185232, 52.41785],
            [-173.982585, 52.319265],
            [-174.059262, 52.226157],
            [-174.179755, 52.231634],
            [-174.141417, 52.127572],
            [-174.333109, 52.116618],
            [-174.738403, 52.007079],
            [-174.968435, 52.039941],
            [-174.902711, 52.116618],
            [-174.656249, 52.105664],
            [-174.322156, 52.280926],
          ],
        ],
        [
          [
            [-176.469116, 51.853725],
            [-176.288377, 51.870156],
            [-176.288377, 51.744186],
            [-176.518409, 51.760617],
            [-176.80321, 51.61274],
            [-176.912748, 51.80991],
            [-176.792256, 51.815386],
            [-176.775825, 51.963264],
            [-176.627947, 51.968741],
            [-176.627947, 51.859202],
            [-176.469116, 51.853725],
          ],
        ],
        [
          [
            [-177.153734, 51.946833],
            [-177.044195, 51.897541],
            [-177.120872, 51.727755],
            [-177.274226, 51.678463],
            [-177.279703, 51.782525],
            [-177.153734, 51.946833],
          ],
        ],
        [
          [
            [-178.123152, 51.919448],
            [-177.953367, 51.913971],
            [-177.800013, 51.793479],
            [-177.964321, 51.651078],
            [-178.123152, 51.919448],
          ],
        ],
        [
          [
            [173.107557, 52.992929],
            [173.293773, 52.927205],
            [173.304726, 52.823143],
            [172.90491, 52.762897],
            [172.642017, 52.927205],
            [172.642017, 53.003883],
            [173.107557, 52.992929],
          ],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_AZ',
    properties: { name: 'Arizona' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-109.042503, 37.000263],
          [-109.04798, 31.331629],
          [-111.074448, 31.331629],
          [-112.246513, 31.704061],
          [-114.815198, 32.492741],
          [-114.72209, 32.717295],
          [-114.524921, 32.755634],
          [-114.470151, 32.843265],
          [-114.524921, 33.029481],
          [-114.661844, 33.034958],
          [-114.727567, 33.40739],
          [-114.524921, 33.54979],
          [-114.497536, 33.697668],
          [-114.535874, 33.933176],
          [-114.415382, 34.108438],
          [-114.256551, 34.174162],
          [-114.136058, 34.305608],
          [-114.333228, 34.448009],
          [-114.470151, 34.710902],
          [-114.634459, 34.87521],
          [-114.634459, 35.00118],
          [-114.574213, 35.138103],
          [-114.596121, 35.324319],
          [-114.678275, 35.516012],
          [-114.738521, 36.102045],
          [-114.371566, 36.140383],
          [-114.251074, 36.01989],
          [-114.152489, 36.025367],
          [-114.048427, 36.195153],
          [-114.048427, 37.000263],
          [-110.499369, 37.00574],
          [-109.042503, 37.000263],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_AR',
    properties: { name: 'Arkansas' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-94.473842, 36.501861],
          [-90.152536, 36.496384],
          [-90.064905, 36.304691],
          [-90.218259, 36.184199],
          [-90.377091, 35.997983],
          [-89.730812, 35.997983],
          [-89.763673, 35.811767],
          [-89.911551, 35.756997],
          [-89.944412, 35.603643],
          [-90.130628, 35.439335],
          [-90.114197, 35.198349],
          [-90.212782, 35.023087],
          [-90.311367, 34.995703],
          [-90.251121, 34.908072],
          [-90.409952, 34.831394],
          [-90.481152, 34.661609],
          [-90.585214, 34.617794],
          [-90.568783, 34.420624],
          [-90.749522, 34.365854],
          [-90.744046, 34.300131],
          [-90.952169, 34.135823],
          [-90.891923, 34.026284],
          [-91.072662, 33.867453],
          [-91.231493, 33.560744],
          [-91.056231, 33.429298],
          [-91.143862, 33.347144],
          [-91.089093, 33.13902],
          [-91.16577, 33.002096],
          [-93.608485, 33.018527],
          [-94.041164, 33.018527],
          [-94.041164, 33.54979],
          [-94.183564, 33.593606],
          [-94.380734, 33.544313],
          [-94.484796, 33.637421],
          [-94.430026, 35.395519],
          [-94.616242, 36.501861],
          [-94.473842, 36.501861],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_CA',
    properties: { name: 'California' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-123.233256, 42.006186],
          [-122.378853, 42.011663],
          [-121.037003, 41.995232],
          [-120.001861, 41.995232],
          [-119.996384, 40.264519],
          [-120.001861, 38.999346],
          [-118.71478, 38.101128],
          [-117.498899, 37.21934],
          [-116.540435, 36.501861],
          [-115.85034, 35.970598],
          [-114.634459, 35.00118],
          [-114.634459, 34.87521],
          [-114.470151, 34.710902],
          [-114.333228, 34.448009],
          [-114.136058, 34.305608],
          [-114.256551, 34.174162],
          [-114.415382, 34.108438],
          [-114.535874, 33.933176],
          [-114.497536, 33.697668],
          [-114.524921, 33.54979],
          [-114.727567, 33.40739],
          [-114.661844, 33.034958],
          [-114.524921, 33.029481],
          [-114.470151, 32.843265],
          [-114.524921, 32.755634],
          [-114.72209, 32.717295],
          [-116.04751, 32.624187],
          [-117.126467, 32.536556],
          [-117.24696, 32.668003],
          [-117.252437, 32.876127],
          [-117.329114, 33.122589],
          [-117.471515, 33.297851],
          [-117.7837, 33.538836],
          [-118.183517, 33.763391],
          [-118.260194, 33.703145],
          [-118.413548, 33.741483],
          [-118.391641, 33.840068],
          [-118.566903, 34.042715],
          [-118.802411, 33.998899],
          [-119.218659, 34.146777],
          [-119.278905, 34.26727],
          [-119.558229, 34.415147],
          [-119.875891, 34.40967],
          [-120.138784, 34.475393],
          [-120.472878, 34.448009],
          [-120.64814, 34.579455],
          [-120.609801, 34.858779],
          [-120.670048, 34.902595],
          [-120.631709, 35.099764],
          [-120.894602, 35.247642],
          [-120.905556, 35.450289],
          [-121.004141, 35.461243],
          [-121.168449, 35.636505],
          [-121.283465, 35.674843],
          [-121.332757, 35.784382],
          [-121.716143, 36.195153],
          [-121.896882, 36.315645],
          [-121.935221, 36.638785],
          [-121.858544, 36.6114],
          [-121.787344, 36.803093],
          [-121.929744, 36.978355],
          [-122.105006, 36.956447],
          [-122.335038, 37.115279],
          [-122.417192, 37.241248],
          [-122.400761, 37.361741],
          [-122.515777, 37.520572],
          [-122.515777, 37.783465],
          [-122.329561, 37.783465],
          [-122.406238, 38.15042],
          [-122.488392, 38.112082],
          [-122.504823, 37.931343],
          [-122.701993, 37.893004],
          [-122.937501, 38.029928],
          [-122.97584, 38.265436],
          [-123.129194, 38.451652],
          [-123.331841, 38.566668],
          [-123.44138, 38.698114],
          [-123.737134, 38.95553],
          [-123.687842, 39.032208],
          [-123.824765, 39.366301],
          [-123.764519, 39.552517],
          [-123.85215, 39.831841],
          [-124.109566, 40.105688],
          [-124.361506, 40.259042],
          [-124.410798, 40.439781],
          [-124.158859, 40.877937],
          [-124.109566, 41.025814],
          [-124.158859, 41.14083],
          [-124.065751, 41.442061],
          [-124.147905, 41.715908],
          [-124.257444, 41.781632],
          [-124.213628, 42.000709],
          [-123.233256, 42.006186],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_CO',
    properties: { name: 'Colorado' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-107.919731, 41.003906],
          [-105.728954, 40.998429],
          [-104.053011, 41.003906],
          [-102.053927, 41.003906],
          [-102.053927, 40.001626],
          [-102.042974, 36.994786],
          [-103.001438, 37.000263],
          [-104.337812, 36.994786],
          [-106.868158, 36.994786],
          [-107.421329, 37.000263],
          [-109.042503, 37.000263],
          [-109.042503, 38.166851],
          [-109.058934, 38.27639],
          [-109.053457, 39.125316],
          [-109.04798, 40.998429],
          [-107.919731, 41.003906],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_CT',
    properties: { name: 'Connecticut' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-73.053528, 42.039048],
          [-71.799309, 42.022617],
          [-71.799309, 42.006186],
          [-71.799309, 41.414677],
          [-71.859555, 41.321569],
          [-71.947186, 41.338],
          [-72.385341, 41.261322],
          [-72.905651, 41.28323],
          [-73.130205, 41.146307],
          [-73.371191, 41.102491],
          [-73.655992, 40.987475],
          [-73.727192, 41.102491],
          [-73.48073, 41.21203],
          [-73.55193, 41.294184],
          [-73.486206, 42.050002],
          [-73.053528, 42.039048],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_DE',
    properties: { name: 'Delaware' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-75.414089, 39.804456],
          [-75.507197, 39.683964],
          [-75.611259, 39.61824],
          [-75.589352, 39.459409],
          [-75.441474, 39.311532],
          [-75.403136, 39.065069],
          [-75.189535, 38.807653],
          [-75.09095, 38.796699],
          [-75.047134, 38.451652],
          [-75.693413, 38.462606],
          [-75.786521, 39.722302],
          [-75.616736, 39.831841],
          [-75.414089, 39.804456],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_DC',
    properties: { name: 'District of Columbia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-77.035264, 38.993869],
          [-76.909294, 38.895284],
          [-77.040741, 38.791222],
          [-77.117418, 38.933623],
          [-77.035264, 38.993869],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_FL',
    properties: { name: 'Florida' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-85.497137, 30.997536],
          [-85.004212, 31.003013],
          [-84.867289, 30.712735],
          [-83.498053, 30.647012],
          [-82.216449, 30.570335],
          [-82.167157, 30.356734],
          [-82.046664, 30.362211],
          [-82.002849, 30.564858],
          [-82.041187, 30.751074],
          [-81.948079, 30.827751],
          [-81.718048, 30.745597],
          [-81.444201, 30.707258],
          [-81.383954, 30.27458],
          [-81.257985, 29.787132],
          [-80.967707, 29.14633],
          [-80.524075, 28.461713],
          [-80.589798, 28.41242],
          [-80.56789, 28.094758],
          [-80.381674, 27.738757],
          [-80.091397, 27.021277],
          [-80.03115, 26.796723],
          [-80.036627, 26.566691],
          [-80.146166, 25.739673],
          [-80.239274, 25.723243],
          [-80.337859, 25.465826],
          [-80.304997, 25.383672],
          [-80.49669, 25.197456],
          [-80.573367, 25.241272],
          [-80.759583, 25.164595],
          [-81.077246, 25.120779],
          [-81.170354, 25.224841],
          [-81.126538, 25.378195],
          [-81.351093, 25.821827],
          [-81.526355, 25.903982],
          [-81.679709, 25.843735],
          [-81.800202, 26.090198],
          [-81.833064, 26.292844],
          [-82.041187, 26.517399],
          [-82.09048, 26.665276],
          [-82.057618, 26.878877],
          [-82.172634, 26.917216],
          [-82.145249, 26.791246],
          [-82.249311, 26.758384],
          [-82.566974, 27.300601],
          [-82.692943, 27.437525],
          [-82.391711, 27.837342],
          [-82.588881, 27.815434],
          [-82.720328, 27.689464],
          [-82.851774, 27.886634],
          [-82.676512, 28.434328],
          [-82.643651, 28.888914],
          [-82.764143, 28.998453],
          [-82.802482, 29.14633],
          [-82.994175, 29.179192],
          [-83.218729, 29.420177],
          [-83.399469, 29.518762],
          [-83.410422, 29.66664],
          [-83.536392, 29.721409],
          [-83.640454, 29.885717],
          [-84.02384, 30.104795],
          [-84.357933, 30.055502],
          [-84.341502, 29.902148],
          [-84.451041, 29.929533],
          [-84.867289, 29.743317],
          [-85.310921, 29.699501],
          [-85.299967, 29.80904],
          [-85.404029, 29.940487],
          [-85.924338, 30.236241],
          [-86.29677, 30.362211],
          [-86.630863, 30.395073],
          [-86.910187, 30.373165],
          [-87.518128, 30.280057],
          [-87.37025, 30.427934],
          [-87.446927, 30.510088],
          [-87.408589, 30.674397],
          [-87.633143, 30.86609],
          [-87.600282, 30.997536],
          [-85.497137, 30.997536],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_GA',
    properties: { name: 'Georgia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-83.109191, 35.00118],
          [-83.322791, 34.787579],
          [-83.339222, 34.683517],
          [-83.005129, 34.469916],
          [-82.901067, 34.486347],
          [-82.747713, 34.26727],
          [-82.714851, 34.152254],
          [-82.55602, 33.94413],
          [-82.325988, 33.81816],
          [-82.194542, 33.631944],
          [-81.926172, 33.462159],
          [-81.937125, 33.347144],
          [-81.761863, 33.160928],
          [-81.493493, 33.007573],
          [-81.42777, 32.843265],
          [-81.416816, 32.629664],
          [-81.279893, 32.558464],
          [-81.121061, 32.290094],
          [-81.115584, 32.120309],
          [-80.885553, 32.032678],
          [-81.132015, 31.693108],
          [-81.175831, 31.517845],
          [-81.279893, 31.364491],
          [-81.290846, 31.20566],
          [-81.400385, 31.13446],
          [-81.444201, 30.707258],
          [-81.718048, 30.745597],
          [-81.948079, 30.827751],
          [-82.041187, 30.751074],
          [-82.002849, 30.564858],
          [-82.046664, 30.362211],
          [-82.167157, 30.356734],
          [-82.216449, 30.570335],
          [-83.498053, 30.647012],
          [-84.867289, 30.712735],
          [-85.004212, 31.003013],
          [-85.113751, 31.27686],
          [-85.042551, 31.539753],
          [-85.141136, 31.840985],
          [-85.053504, 32.01077],
          [-85.058981, 32.13674],
          [-84.889196, 32.262709],
          [-85.004212, 32.322956],
          [-84.960397, 32.421541],
          [-85.069935, 32.580372],
          [-85.184951, 32.859696],
          [-85.431413, 34.124869],
          [-85.606675, 34.984749],
          [-84.319594, 34.990226],
          [-83.618546, 34.984749],
          [-83.109191, 35.00118],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_HI',
    properties: { name: 'Hawaii' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-155.634835, 18.948267],
            [-155.881297, 19.035898],
            [-155.919636, 19.123529],
            [-155.886774, 19.348084],
            [-156.062036, 19.73147],
            [-155.925113, 19.857439],
            [-155.826528, 20.032702],
            [-155.897728, 20.147717],
            [-155.87582, 20.26821],
            [-155.596496, 20.12581],
            [-155.284311, 20.021748],
            [-155.092618, 19.868393],
            [-155.092618, 19.736947],
            [-154.807817, 19.523346],
            [-154.983079, 19.348084],
            [-155.295265, 19.26593],
            [-155.514342, 19.134483],
            [-155.634835, 18.948267],
          ],
        ],
        [
          [
            [-156.587823, 21.029505],
            [-156.472807, 20.892581],
            [-156.324929, 20.952827],
            [-156.00179, 20.793996],
            [-156.051082, 20.651596],
            [-156.379699, 20.580396],
            [-156.445422, 20.60778],
            [-156.461853, 20.783042],
            [-156.631638, 20.821381],
            [-156.697361, 20.919966],
            [-156.587823, 21.029505],
          ],
        ],
        [
          [
            [-156.982162, 21.210244],
            [-157.080747, 21.106182],
            [-157.310779, 21.106182],
            [-157.239579, 21.221198],
            [-156.982162, 21.210244],
          ],
        ],
        [
          [
            [-157.951581, 21.697691],
            [-157.842042, 21.462183],
            [-157.896811, 21.325259],
            [-158.110412, 21.303352],
            [-158.252813, 21.582676],
            [-158.126843, 21.588153],
            [-157.951581, 21.697691],
          ],
        ],
        [
          [
            [-159.468693, 22.228955],
            [-159.353678, 22.218001],
            [-159.298908, 22.113939],
            [-159.33177, 21.966061],
            [-159.446786, 21.872953],
            [-159.764448, 21.987969],
            [-159.726109, 22.152277],
            [-159.468693, 22.228955],
          ],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_ID',
    properties: { name: 'Idaho' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-116.04751, 49.000239],
          [-116.04751, 47.976051],
          [-115.724371, 47.696727],
          [-115.718894, 47.42288],
          [-115.527201, 47.302388],
          [-115.324554, 47.258572],
          [-115.302646, 47.187372],
          [-114.930214, 46.919002],
          [-114.886399, 46.809463],
          [-114.623506, 46.705401],
          [-114.612552, 46.639678],
          [-114.322274, 46.645155],
          [-114.464674, 46.272723],
          [-114.492059, 46.037214],
          [-114.387997, 45.88386],
          [-114.568736, 45.774321],
          [-114.497536, 45.670259],
          [-114.546828, 45.560721],
          [-114.333228, 45.456659],
          [-114.086765, 45.593582],
          [-113.98818, 45.703121],
          [-113.807441, 45.604536],
          [-113.834826, 45.522382],
          [-113.736241, 45.330689],
          [-113.571933, 45.128042],
          [-113.45144, 45.056842],
          [-113.456917, 44.865149],
          [-113.341901, 44.782995],
          [-113.133778, 44.772041],
          [-113.002331, 44.448902],
          [-112.887315, 44.394132],
          [-112.783254, 44.48724],
          [-112.471068, 44.481763],
          [-112.241036, 44.569394],
          [-112.104113, 44.520102],
          [-111.868605, 44.563917],
          [-111.819312, 44.509148],
          [-111.616665, 44.547487],
          [-111.386634, 44.75561],
          [-111.227803, 44.580348],
          [-111.047063, 44.476286],
          [-111.047063, 42.000709],
          [-112.164359, 41.995232],
          [-114.04295, 41.995232],
          [-117.027882, 42.000709],
          [-117.027882, 43.830007],
          [-116.896436, 44.158624],
          [-116.97859, 44.240778],
          [-117.170283, 44.257209],
          [-117.241483, 44.394132],
          [-117.038836, 44.750133],
          [-116.934774, 44.782995],
          [-116.830713, 44.930872],
          [-116.847143, 45.02398],
          [-116.732128, 45.144473],
          [-116.671881, 45.319735],
          [-116.463758, 45.61549],
          [-116.545912, 45.752413],
          [-116.78142, 45.823614],
          [-116.918344, 45.993399],
          [-116.92382, 46.168661],
          [-117.055267, 46.343923],
          [-117.038836, 46.426077],
          [-117.044313, 47.762451],
          [-117.033359, 49.000239],
          [-116.04751, 49.000239],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_IL',
    properties: { name: 'Illinois' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-90.639984, 42.510065],
          [-88.788778, 42.493634],
          [-87.802929, 42.493634],
          [-87.83579, 42.301941],
          [-87.682436, 42.077386],
          [-87.523605, 41.710431],
          [-87.529082, 39.34987],
          [-87.63862, 39.169131],
          [-87.512651, 38.95553],
          [-87.49622, 38.780268],
          [-87.62219, 38.637868],
          [-87.655051, 38.506421],
          [-87.83579, 38.292821],
          [-87.950806, 38.27639],
          [-87.923421, 38.15042],
          [-88.000098, 38.101128],
          [-88.060345, 37.865619],
          [-88.027483, 37.799896],
          [-88.15893, 37.657496],
          [-88.065822, 37.482234],
          [-88.476592, 37.389126],
          [-88.514931, 37.285064],
          [-88.421823, 37.153617],
          [-88.547792, 37.071463],
          [-88.914747, 37.224817],
          [-89.029763, 37.213863],
          [-89.183118, 37.038601],
          [-89.133825, 36.983832],
          [-89.292656, 36.994786],
          [-89.517211, 37.279587],
          [-89.435057, 37.34531],
          [-89.517211, 37.537003],
          [-89.517211, 37.690357],
          [-89.84035, 37.903958],
          [-89.949889, 37.88205],
          [-90.059428, 38.013497],
          [-90.355183, 38.216144],
          [-90.349706, 38.374975],
          [-90.179921, 38.632391],
          [-90.207305, 38.725499],
          [-90.10872, 38.845992],
          [-90.251121, 38.917192],
          [-90.470199, 38.961007],
          [-90.585214, 38.867899],
          [-90.661891, 38.928146],
          [-90.727615, 39.256762],
          [-91.061708, 39.470363],
          [-91.368417, 39.727779],
          [-91.494386, 40.034488],
          [-91.50534, 40.237135],
          [-91.417709, 40.379535],
          [-91.401278, 40.560274],
          [-91.121954, 40.669813],
          [-91.09457, 40.823167],
          [-90.963123, 40.921752],
          [-90.946692, 41.097014],
          [-91.111001, 41.239415],
          [-91.045277, 41.414677],
          [-90.656414, 41.463969],
          [-90.344229, 41.589939],
          [-90.311367, 41.743293],
          [-90.179921, 41.809016],
          [-90.141582, 42.000709],
          [-90.168967, 42.126679],
          [-90.393521, 42.225264],
          [-90.420906, 42.329326],
          [-90.639984, 42.510065],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_IN',
    properties: { name: 'Indiana' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-85.990061, 41.759724],
          [-84.807042, 41.759724],
          [-84.807042, 41.694001],
          [-84.801565, 40.500028],
          [-84.817996, 39.103408],
          [-84.894673, 39.059592],
          [-84.812519, 38.785745],
          [-84.987781, 38.780268],
          [-85.173997, 38.68716],
          [-85.431413, 38.730976],
          [-85.42046, 38.533806],
          [-85.590245, 38.451652],
          [-85.655968, 38.325682],
          [-85.83123, 38.27639],
          [-85.924338, 38.024451],
          [-86.039354, 37.958727],
          [-86.263908, 38.051835],
          [-86.302247, 38.166851],
          [-86.521325, 38.040881],
          [-86.504894, 37.931343],
          [-86.729448, 37.893004],
          [-86.795172, 37.991589],
          [-87.047111, 37.893004],
          [-87.129265, 37.788942],
          [-87.381204, 37.93682],
          [-87.512651, 37.903958],
          [-87.600282, 37.975158],
          [-87.682436, 37.903958],
          [-87.934375, 37.893004],
          [-88.027483, 37.799896],
          [-88.060345, 37.865619],
          [-88.000098, 38.101128],
          [-87.923421, 38.15042],
          [-87.950806, 38.27639],
          [-87.83579, 38.292821],
          [-87.655051, 38.506421],
          [-87.62219, 38.637868],
          [-87.49622, 38.780268],
          [-87.512651, 38.95553],
          [-87.63862, 39.169131],
          [-87.529082, 39.34987],
          [-87.523605, 41.710431],
          [-87.42502, 41.644708],
          [-87.118311, 41.644708],
          [-86.822556, 41.759724],
          [-85.990061, 41.759724],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_IA',
    properties: { name: 'Iowa' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-91.368417, 43.501391],
          [-91.215062, 43.501391],
          [-91.204109, 43.353514],
          [-91.056231, 43.254929],
          [-91.176724, 43.134436],
          [-91.143862, 42.909881],
          [-91.067185, 42.75105],
          [-90.711184, 42.636034],
          [-90.639984, 42.510065],
          [-90.420906, 42.329326],
          [-90.393521, 42.225264],
          [-90.168967, 42.126679],
          [-90.141582, 42.000709],
          [-90.179921, 41.809016],
          [-90.311367, 41.743293],
          [-90.344229, 41.589939],
          [-90.656414, 41.463969],
          [-91.045277, 41.414677],
          [-91.111001, 41.239415],
          [-90.946692, 41.097014],
          [-90.963123, 40.921752],
          [-91.09457, 40.823167],
          [-91.121954, 40.669813],
          [-91.401278, 40.560274],
          [-91.417709, 40.379535],
          [-91.527248, 40.412397],
          [-91.729895, 40.615043],
          [-91.833957, 40.609566],
          [-93.257961, 40.582182],
          [-94.632673, 40.571228],
          [-95.7664, 40.587659],
          [-95.881416, 40.719105],
          [-95.826646, 40.976521],
          [-95.925231, 41.201076],
          [-95.919754, 41.453015],
          [-96.095016, 41.540646],
          [-96.122401, 41.67757],
          [-96.062155, 41.798063],
          [-96.127878, 41.973325],
          [-96.264801, 42.039048],
          [-96.44554, 42.488157],
          [-96.631756, 42.707235],
          [-96.544125, 42.855112],
          [-96.511264, 43.052282],
          [-96.434587, 43.123482],
          [-96.560556, 43.222067],
          [-96.527695, 43.397329],
          [-96.582464, 43.479483],
          [-96.451017, 43.501391],
          [-91.368417, 43.501391],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_KS',
    properties: { name: 'Kansas' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-101.90605, 40.001626],
          [-95.306337, 40.001626],
          [-95.207752, 39.908518],
          [-94.884612, 39.831841],
          [-95.109167, 39.541563],
          [-94.983197, 39.442978],
          [-94.824366, 39.20747],
          [-94.610765, 39.158177],
          [-94.616242, 37.000263],
          [-100.087706, 37.000263],
          [-102.042974, 36.994786],
          [-102.053927, 40.001626],
          [-101.90605, 40.001626],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_KY',
    properties: { name: 'Kentucky' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-83.903347, 38.769315],
          [-83.678792, 38.632391],
          [-83.519961, 38.703591],
          [-83.142052, 38.626914],
          [-83.032514, 38.725499],
          [-82.890113, 38.758361],
          [-82.846298, 38.588575],
          [-82.731282, 38.561191],
          [-82.594358, 38.424267],
          [-82.621743, 38.123036],
          [-82.50125, 37.931343],
          [-82.342419, 37.783465],
          [-82.293127, 37.668449],
          [-82.101434, 37.553434],
          [-81.969987, 37.537003],
          [-82.353373, 37.268633],
          [-82.720328, 37.120755],
          [-82.720328, 37.044078],
          [-82.868205, 36.978355],
          [-82.879159, 36.890724],
          [-83.070852, 36.852385],
          [-83.136575, 36.742847],
          [-83.673316, 36.600446],
          [-83.689746, 36.584015],
          [-84.544149, 36.594969],
          [-85.289013, 36.627831],
          [-85.486183, 36.616877],
          [-86.592525, 36.655216],
          [-87.852221, 36.633308],
          [-88.071299, 36.677123],
          [-88.054868, 36.496384],
          [-89.298133, 36.507338],
          [-89.418626, 36.496384],
          [-89.363857, 36.622354],
          [-89.215979, 36.578538],
          [-89.133825, 36.983832],
          [-89.183118, 37.038601],
          [-89.029763, 37.213863],
          [-88.914747, 37.224817],
          [-88.547792, 37.071463],
          [-88.421823, 37.153617],
          [-88.514931, 37.285064],
          [-88.476592, 37.389126],
          [-88.065822, 37.482234],
          [-88.15893, 37.657496],
          [-88.027483, 37.799896],
          [-87.934375, 37.893004],
          [-87.682436, 37.903958],
          [-87.600282, 37.975158],
          [-87.512651, 37.903958],
          [-87.381204, 37.93682],
          [-87.129265, 37.788942],
          [-87.047111, 37.893004],
          [-86.795172, 37.991589],
          [-86.729448, 37.893004],
          [-86.504894, 37.931343],
          [-86.521325, 38.040881],
          [-86.302247, 38.166851],
          [-86.263908, 38.051835],
          [-86.039354, 37.958727],
          [-85.924338, 38.024451],
          [-85.83123, 38.27639],
          [-85.655968, 38.325682],
          [-85.590245, 38.451652],
          [-85.42046, 38.533806],
          [-85.431413, 38.730976],
          [-85.173997, 38.68716],
          [-84.987781, 38.780268],
          [-84.812519, 38.785745],
          [-84.894673, 39.059592],
          [-84.817996, 39.103408],
          [-84.43461, 39.103408],
          [-84.231963, 38.895284],
          [-84.215533, 38.807653],
          [-83.903347, 38.769315],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_LA',
    properties: { name: 'Louisiana' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-93.608485, 33.018527],
          [-91.16577, 33.002096],
          [-91.072662, 32.887081],
          [-91.143862, 32.843265],
          [-91.154816, 32.640618],
          [-91.006939, 32.514649],
          [-90.985031, 32.218894],
          [-91.105524, 31.988862],
          [-91.341032, 31.846462],
          [-91.401278, 31.621907],
          [-91.499863, 31.643815],
          [-91.516294, 31.27686],
          [-91.636787, 31.265906],
          [-91.565587, 31.068736],
          [-91.636787, 30.997536],
          [-89.747242, 30.997536],
          [-89.845827, 30.66892],
          [-89.681519, 30.449842],
          [-89.643181, 30.285534],
          [-89.522688, 30.181472],
          [-89.818443, 30.044549],
          [-89.84035, 29.945964],
          [-89.599365, 29.88024],
          [-89.495303, 30.039072],
          [-89.287179, 29.88024],
          [-89.30361, 29.754271],
          [-89.424103, 29.699501],
          [-89.648657, 29.748794],
          [-89.621273, 29.655686],
          [-89.69795, 29.513285],
          [-89.506257, 29.387316],
          [-89.199548, 29.348977],
          [-89.09001, 29.2011],
          [-89.002379, 29.179192],
          [-89.16121, 29.009407],
          [-89.336472, 29.042268],
          [-89.484349, 29.217531],
          [-89.851304, 29.310638],
          [-89.851304, 29.480424],
          [-90.032043, 29.425654],
          [-90.021089, 29.283254],
          [-90.103244, 29.151807],
          [-90.23469, 29.129899],
          [-90.333275, 29.277777],
          [-90.563307, 29.283254],
          [-90.645461, 29.129899],
          [-90.798815, 29.086084],
          [-90.963123, 29.179192],
          [-91.09457, 29.190146],
          [-91.220539, 29.436608],
          [-91.445094, 29.546147],
          [-91.532725, 29.529716],
          [-91.620356, 29.73784],
          [-91.883249, 29.710455],
          [-91.888726, 29.836425],
          [-92.146142, 29.715932],
          [-92.113281, 29.622824],
          [-92.31045, 29.535193],
          [-92.617159, 29.579009],
          [-92.97316, 29.715932],
          [-93.2251, 29.776178],
          [-93.767317, 29.726886],
          [-93.838517, 29.688547],
          [-93.926148, 29.787132],
          [-93.690639, 30.143133],
          [-93.767317, 30.334826],
          [-93.696116, 30.438888],
          [-93.728978, 30.575812],
          [-93.630393, 30.679874],
          [-93.526331, 30.93729],
          [-93.542762, 31.15089],
          [-93.816609, 31.556184],
          [-93.822086, 31.775262],
          [-94.041164, 31.994339],
          [-94.041164, 33.018527],
          [-93.608485, 33.018527],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_ME',
    properties: { name: 'Maine' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-70.703921, 43.057759],
          [-70.824413, 43.128959],
          [-70.807983, 43.227544],
          [-70.966814, 43.34256],
          [-71.032537, 44.657025],
          [-71.08183, 45.303304],
          [-70.649151, 45.440228],
          [-70.720352, 45.511428],
          [-70.556043, 45.664782],
          [-70.386258, 45.735983],
          [-70.41912, 45.796229],
          [-70.260289, 45.889337],
          [-70.309581, 46.064599],
          [-70.210996, 46.327492],
          [-70.057642, 46.415123],
          [-69.997395, 46.694447],
          [-69.225147, 47.461219],
          [-69.044408, 47.428357],
          [-69.033454, 47.242141],
          [-68.902007, 47.176418],
          [-68.578868, 47.285957],
          [-68.376221, 47.285957],
          [-68.233821, 47.357157],
          [-67.954497, 47.198326],
          [-67.790188, 47.066879],
          [-67.779235, 45.944106],
          [-67.801142, 45.675736],
          [-67.456095, 45.604536],
          [-67.505388, 45.48952],
          [-67.417757, 45.379982],
          [-67.488957, 45.281397],
          [-67.346556, 45.128042],
          [-67.16034, 45.160904],
          [-66.979601, 44.804903],
          [-67.187725, 44.646072],
          [-67.308218, 44.706318],
          [-67.406803, 44.596779],
          [-67.549203, 44.624164],
          [-67.565634, 44.531056],
          [-67.75185, 44.54201],
          [-68.047605, 44.328409],
          [-68.118805, 44.476286],
          [-68.222867, 44.48724],
          [-68.173574, 44.328409],
          [-68.403606, 44.251732],
          [-68.458375, 44.377701],
          [-68.567914, 44.311978],
          [-68.82533, 44.311978],
          [-68.830807, 44.459856],
          [-68.984161, 44.426994],
          [-68.956777, 44.322932],
          [-69.099177, 44.103854],
          [-69.071793, 44.043608],
          [-69.258008, 43.923115],
          [-69.444224, 43.966931],
          [-69.553763, 43.840961],
          [-69.707118, 43.82453],
          [-69.833087, 43.720469],
          [-69.986442, 43.742376],
          [-70.030257, 43.851915],
          [-70.254812, 43.676653],
          [-70.194565, 43.567114],
          [-70.358873, 43.528776],
          [-70.369827, 43.435668],
          [-70.556043, 43.320652],
          [-70.703921, 43.057759],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_MD',
    properties: { name: 'Maryland' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-75.994645, 37.95325],
            [-76.016553, 37.95325],
            [-76.043938, 37.95325],
            [-75.994645, 37.95325],
          ],
        ],
        [
          [
            [-79.477979, 39.722302],
            [-75.786521, 39.722302],
            [-75.693413, 38.462606],
            [-75.047134, 38.451652],
            [-75.244304, 38.029928],
            [-75.397659, 38.013497],
            [-75.671506, 37.95325],
            [-75.885106, 37.909435],
            [-75.879629, 38.073743],
            [-75.961783, 38.139466],
            [-75.846768, 38.210667],
            [-76.000122, 38.374975],
            [-76.049415, 38.303775],
            [-76.257538, 38.320205],
            [-76.328738, 38.500944],
            [-76.263015, 38.500944],
            [-76.257538, 38.736453],
            [-76.191815, 38.829561],
            [-76.279446, 39.147223],
            [-76.169907, 39.333439],
            [-76.000122, 39.366301],
            [-75.972737, 39.557994],
            [-76.098707, 39.536086],
            [-76.104184, 39.437501],
            [-76.367077, 39.311532],
            [-76.443754, 39.196516],
            [-76.460185, 38.906238],
            [-76.55877, 38.769315],
            [-76.514954, 38.539283],
            [-76.383508, 38.380452],
            [-76.399939, 38.259959],
            [-76.317785, 38.139466],
            [-76.3616, 38.057312],
            [-76.591632, 38.216144],
            [-76.920248, 38.292821],
            [-77.018833, 38.446175],
            [-77.205049, 38.358544],
            [-77.276249, 38.479037],
            [-77.128372, 38.632391],
            [-77.040741, 38.791222],
            [-76.909294, 38.895284],
            [-77.035264, 38.993869],
            [-77.117418, 38.933623],
            [-77.248864, 39.026731],
            [-77.456988, 39.076023],
            [-77.456988, 39.223901],
            [-77.566527, 39.306055],
            [-77.719881, 39.322485],
            [-77.834897, 39.601809],
            [-78.004682, 39.601809],
            [-78.174467, 39.694917],
            [-78.267575, 39.61824],
            [-78.431884, 39.623717],
            [-78.470222, 39.514178],
            [-78.765977, 39.585379],
            [-78.963147, 39.437501],
            [-79.094593, 39.470363],
            [-79.291763, 39.300578],
            [-79.488933, 39.20747],
            [-79.477979, 39.722302],
          ],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_MA',
    properties: { name: 'Massachusetts' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-70.917521, 42.887974],
          [-70.818936, 42.871543],
          [-70.780598, 42.696281],
          [-70.824413, 42.55388],
          [-70.983245, 42.422434],
          [-70.988722, 42.269079],
          [-70.769644, 42.247172],
          [-70.638197, 42.08834],
          [-70.660105, 41.962371],
          [-70.550566, 41.929509],
          [-70.539613, 41.814493],
          [-70.260289, 41.715908],
          [-69.937149, 41.809016],
          [-70.008349, 41.672093],
          [-70.484843, 41.5516],
          [-70.660105, 41.546123],
          [-70.764167, 41.639231],
          [-70.928475, 41.611847],
          [-70.933952, 41.540646],
          [-71.120168, 41.496831],
          [-71.196845, 41.67757],
          [-71.22423, 41.710431],
          [-71.328292, 41.781632],
          [-71.383061, 42.01714],
          [-71.530939, 42.01714],
          [-71.799309, 42.006186],
          [-71.799309, 42.022617],
          [-73.053528, 42.039048],
          [-73.486206, 42.050002],
          [-73.508114, 42.08834],
          [-73.267129, 42.745573],
          [-72.456542, 42.729142],
          [-71.29543, 42.696281],
          [-71.185891, 42.789389],
          [-70.917521, 42.887974],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_MI',
    properties: { name: 'Michigan' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-83.454238, 41.732339],
            [-84.807042, 41.694001],
            [-84.807042, 41.759724],
            [-85.990061, 41.759724],
            [-86.822556, 41.759724],
            [-86.619909, 41.891171],
            [-86.482986, 42.115725],
            [-86.357016, 42.252649],
            [-86.263908, 42.444341],
            [-86.209139, 42.718189],
            [-86.231047, 43.013943],
            [-86.526801, 43.594499],
            [-86.433693, 43.813577],
            [-86.499417, 44.07647],
            [-86.269385, 44.34484],
            [-86.220093, 44.569394],
            [-86.252954, 44.689887],
            [-86.088646, 44.73918],
            [-86.066738, 44.903488],
            [-85.809322, 44.947303],
            [-85.612152, 45.128042],
            [-85.628583, 44.766564],
            [-85.524521, 44.750133],
            [-85.393075, 44.930872],
            [-85.387598, 45.237581],
            [-85.305444, 45.314258],
            [-85.031597, 45.363551],
            [-85.119228, 45.577151],
            [-84.938489, 45.75789],
            [-84.713934, 45.768844],
            [-84.461995, 45.653829],
            [-84.215533, 45.637398],
            [-84.09504, 45.494997],
            [-83.908824, 45.484043],
            [-83.596638, 45.352597],
            [-83.4871, 45.358074],
            [-83.317314, 45.144473],
            [-83.454238, 45.029457],
            [-83.322791, 44.88158],
            [-83.273499, 44.711795],
            [-83.333745, 44.339363],
            [-83.536392, 44.246255],
            [-83.585684, 44.054562],
            [-83.82667, 43.988839],
            [-83.958116, 43.758807],
            [-83.908824, 43.671176],
            [-83.667839, 43.589022],
            [-83.481623, 43.714992],
            [-83.262545, 43.972408],
            [-82.917498, 44.070993],
            [-82.747713, 43.994316],
            [-82.643651, 43.851915],
            [-82.539589, 43.435668],
            [-82.523158, 43.227544],
            [-82.413619, 42.975605],
            [-82.517681, 42.614127],
            [-82.681989, 42.559357],
            [-82.687466, 42.690804],
            [-82.797005, 42.652465],
            [-82.922975, 42.351234],
            [-83.125621, 42.236218],
            [-83.185868, 42.006186],
            [-83.437807, 41.814493],
            [-83.454238, 41.732339],
          ],
        ],
        [
          [
            [-85.508091, 45.730506],
            [-85.49166, 45.610013],
            [-85.623106, 45.588105],
            [-85.568337, 45.75789],
            [-85.508091, 45.730506],
          ],
        ],
        [
          [
            [-87.589328, 45.095181],
            [-87.742682, 45.199243],
            [-87.649574, 45.341643],
            [-87.885083, 45.363551],
            [-87.791975, 45.500474],
            [-87.781021, 45.675736],
            [-87.989145, 45.796229],
            [-88.10416, 45.922199],
            [-88.531362, 46.020784],
            [-88.662808, 45.987922],
            [-89.09001, 46.135799],
            [-90.119674, 46.338446],
            [-90.229213, 46.508231],
            [-90.415429, 46.568478],
            [-90.026566, 46.672539],
            [-89.851304, 46.793032],
            [-89.413149, 46.842325],
            [-89.128348, 46.990202],
            [-88.996902, 46.995679],
            [-88.887363, 47.099741],
            [-88.575177, 47.247618],
            [-88.416346, 47.373588],
            [-88.180837, 47.455742],
            [-87.956283, 47.384542],
            [-88.350623, 47.077833],
            [-88.443731, 46.973771],
            [-88.438254, 46.787555],
            [-88.246561, 46.929956],
            [-87.901513, 46.908048],
            [-87.633143, 46.809463],
            [-87.392158, 46.535616],
            [-87.260711, 46.486323],
            [-87.008772, 46.530139],
            [-86.948526, 46.469893],
            [-86.696587, 46.437031],
            [-86.159846, 46.667063],
            [-85.880522, 46.68897],
            [-85.508091, 46.678016],
            [-85.256151, 46.754694],
            [-85.064458, 46.760171],
            [-85.02612, 46.480847],
            [-84.82895, 46.442508],
            [-84.63178, 46.486323],
            [-84.549626, 46.4206],
            [-84.418179, 46.502754],
            [-84.127902, 46.530139],
            [-84.122425, 46.179615],
            [-83.990978, 46.031737],
            [-83.793808, 45.993399],
            [-83.7719, 46.091984],
            [-83.580208, 46.091984],
            [-83.476146, 45.987922],
            [-83.563777, 45.911245],
            [-84.111471, 45.976968],
            [-84.374364, 45.933153],
            [-84.659165, 46.053645],
            [-84.741319, 45.944106],
            [-84.70298, 45.850998],
            [-84.82895, 45.872906],
            [-85.015166, 46.00983],
            [-85.338305, 46.091984],
            [-85.502614, 46.097461],
            [-85.661445, 45.966014],
            [-85.924338, 45.933153],
            [-86.209139, 45.960537],
            [-86.324155, 45.905768],
            [-86.351539, 45.796229],
            [-86.663725, 45.703121],
            [-86.647294, 45.834568],
            [-86.784218, 45.861952],
            [-86.838987, 45.725029],
            [-87.069019, 45.719552],
            [-87.17308, 45.659305],
            [-87.326435, 45.423797],
            [-87.611236, 45.122565],
            [-87.589328, 45.095181],
          ],
        ],
        [
          [
            [-88.805209, 47.976051],
            [-89.057148, 47.850082],
            [-89.188594, 47.833651],
            [-89.177641, 47.937713],
            [-88.547792, 48.173221],
            [-88.668285, 48.008913],
            [-88.805209, 47.976051],
          ],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_MN',
    properties: { name: 'Minnesota' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-92.014696, 46.705401],
          [-92.091373, 46.749217],
          [-92.29402, 46.667063],
          [-92.29402, 46.075553],
          [-92.354266, 46.015307],
          [-92.639067, 45.933153],
          [-92.869098, 45.719552],
          [-92.885529, 45.577151],
          [-92.770513, 45.566198],
          [-92.644544, 45.440228],
          [-92.75956, 45.286874],
          [-92.737652, 45.117088],
          [-92.808852, 44.750133],
          [-92.545959, 44.569394],
          [-92.337835, 44.552964],
          [-92.233773, 44.443425],
          [-91.927065, 44.333886],
          [-91.877772, 44.202439],
          [-91.592971, 44.032654],
          [-91.43414, 43.994316],
          [-91.242447, 43.775238],
          [-91.269832, 43.616407],
          [-91.215062, 43.501391],
          [-91.368417, 43.501391],
          [-96.451017, 43.501391],
          [-96.451017, 45.297827],
          [-96.681049, 45.412843],
          [-96.856311, 45.604536],
          [-96.582464, 45.818137],
          [-96.560556, 45.933153],
          [-96.598895, 46.332969],
          [-96.719387, 46.437031],
          [-96.801542, 46.656109],
          [-96.785111, 46.924479],
          [-96.823449, 46.968294],
          [-96.856311, 47.609096],
          [-97.053481, 47.948667],
          [-97.130158, 48.140359],
          [-97.16302, 48.545653],
          [-97.097296, 48.682577],
          [-97.228743, 49.000239],
          [-95.152983, 49.000239],
          [-95.152983, 49.383625],
          [-94.955813, 49.372671],
          [-94.824366, 49.295994],
          [-94.69292, 48.775685],
          [-94.588858, 48.715438],
          [-94.260241, 48.699007],
          [-94.221903, 48.649715],
          [-93.838517, 48.627807],
          [-93.794701, 48.518268],
          [-93.466085, 48.545653],
          [-93.466085, 48.589469],
          [-93.208669, 48.644238],
          [-92.984114, 48.62233],
          [-92.726698, 48.540176],
          [-92.655498, 48.436114],
          [-92.50762, 48.447068],
          [-92.370697, 48.222514],
          [-92.304974, 48.315622],
          [-92.053034, 48.359437],
          [-92.009219, 48.266329],
          [-91.713464, 48.200606],
          [-91.713464, 48.112975],
          [-91.565587, 48.041775],
          [-91.264355, 48.080113],
          [-91.083616, 48.178698],
          [-90.837154, 48.238944],
          [-90.749522, 48.091067],
          [-90.579737, 48.123929],
          [-90.377091, 48.091067],
          [-90.141582, 48.112975],
          [-89.873212, 47.987005],
          [-89.615796, 48.008913],
          [-89.637704, 47.954144],
          [-89.971797, 47.828174],
          [-90.437337, 47.729589],
          [-90.738569, 47.625527],
          [-91.171247, 47.368111],
          [-91.357463, 47.20928],
          [-91.642264, 47.028541],
          [-92.091373, 46.787555],
          [-92.014696, 46.705401],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_MS',
    properties: { name: 'Mississippi' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-88.471115, 34.995703],
          [-88.202745, 34.995703],
          [-88.098683, 34.891641],
          [-88.241084, 33.796253],
          [-88.471115, 31.895754],
          [-88.394438, 30.367688],
          [-88.503977, 30.323872],
          [-88.744962, 30.34578],
          [-88.843547, 30.411504],
          [-89.084533, 30.367688],
          [-89.418626, 30.252672],
          [-89.522688, 30.181472],
          [-89.643181, 30.285534],
          [-89.681519, 30.449842],
          [-89.845827, 30.66892],
          [-89.747242, 30.997536],
          [-91.636787, 30.997536],
          [-91.565587, 31.068736],
          [-91.636787, 31.265906],
          [-91.516294, 31.27686],
          [-91.499863, 31.643815],
          [-91.401278, 31.621907],
          [-91.341032, 31.846462],
          [-91.105524, 31.988862],
          [-90.985031, 32.218894],
          [-91.006939, 32.514649],
          [-91.154816, 32.640618],
          [-91.143862, 32.843265],
          [-91.072662, 32.887081],
          [-91.16577, 33.002096],
          [-91.089093, 33.13902],
          [-91.143862, 33.347144],
          [-91.056231, 33.429298],
          [-91.231493, 33.560744],
          [-91.072662, 33.867453],
          [-90.891923, 34.026284],
          [-90.952169, 34.135823],
          [-90.744046, 34.300131],
          [-90.749522, 34.365854],
          [-90.568783, 34.420624],
          [-90.585214, 34.617794],
          [-90.481152, 34.661609],
          [-90.409952, 34.831394],
          [-90.251121, 34.908072],
          [-90.311367, 34.995703],
          [-88.471115, 34.995703],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_MO',
    properties: { name: 'Missouri' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-91.833957, 40.609566],
          [-91.729895, 40.615043],
          [-91.527248, 40.412397],
          [-91.417709, 40.379535],
          [-91.50534, 40.237135],
          [-91.494386, 40.034488],
          [-91.368417, 39.727779],
          [-91.061708, 39.470363],
          [-90.727615, 39.256762],
          [-90.661891, 38.928146],
          [-90.585214, 38.867899],
          [-90.470199, 38.961007],
          [-90.251121, 38.917192],
          [-90.10872, 38.845992],
          [-90.207305, 38.725499],
          [-90.179921, 38.632391],
          [-90.349706, 38.374975],
          [-90.355183, 38.216144],
          [-90.059428, 38.013497],
          [-89.949889, 37.88205],
          [-89.84035, 37.903958],
          [-89.517211, 37.690357],
          [-89.517211, 37.537003],
          [-89.435057, 37.34531],
          [-89.517211, 37.279587],
          [-89.292656, 36.994786],
          [-89.133825, 36.983832],
          [-89.215979, 36.578538],
          [-89.363857, 36.622354],
          [-89.418626, 36.496384],
          [-89.484349, 36.496384],
          [-89.539119, 36.496384],
          [-89.533642, 36.249922],
          [-89.730812, 35.997983],
          [-90.377091, 35.997983],
          [-90.218259, 36.184199],
          [-90.064905, 36.304691],
          [-90.152536, 36.496384],
          [-94.473842, 36.501861],
          [-94.616242, 36.501861],
          [-94.616242, 37.000263],
          [-94.610765, 39.158177],
          [-94.824366, 39.20747],
          [-94.983197, 39.442978],
          [-95.109167, 39.541563],
          [-94.884612, 39.831841],
          [-95.207752, 39.908518],
          [-95.306337, 40.001626],
          [-95.552799, 40.264519],
          [-95.7664, 40.587659],
          [-94.632673, 40.571228],
          [-93.257961, 40.582182],
          [-91.833957, 40.609566],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_MT',
    properties: { name: 'Montana' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-104.047534, 49.000239],
          [-104.042057, 47.861036],
          [-104.047534, 45.944106],
          [-104.042057, 44.996596],
          [-104.058488, 44.996596],
          [-105.91517, 45.002073],
          [-109.080842, 45.002073],
          [-111.05254, 45.002073],
          [-111.047063, 44.476286],
          [-111.227803, 44.580348],
          [-111.386634, 44.75561],
          [-111.616665, 44.547487],
          [-111.819312, 44.509148],
          [-111.868605, 44.563917],
          [-112.104113, 44.520102],
          [-112.241036, 44.569394],
          [-112.471068, 44.481763],
          [-112.783254, 44.48724],
          [-112.887315, 44.394132],
          [-113.002331, 44.448902],
          [-113.133778, 44.772041],
          [-113.341901, 44.782995],
          [-113.456917, 44.865149],
          [-113.45144, 45.056842],
          [-113.571933, 45.128042],
          [-113.736241, 45.330689],
          [-113.834826, 45.522382],
          [-113.807441, 45.604536],
          [-113.98818, 45.703121],
          [-114.086765, 45.593582],
          [-114.333228, 45.456659],
          [-114.546828, 45.560721],
          [-114.497536, 45.670259],
          [-114.568736, 45.774321],
          [-114.387997, 45.88386],
          [-114.492059, 46.037214],
          [-114.464674, 46.272723],
          [-114.322274, 46.645155],
          [-114.612552, 46.639678],
          [-114.623506, 46.705401],
          [-114.886399, 46.809463],
          [-114.930214, 46.919002],
          [-115.302646, 47.187372],
          [-115.324554, 47.258572],
          [-115.527201, 47.302388],
          [-115.718894, 47.42288],
          [-115.724371, 47.696727],
          [-116.04751, 47.976051],
          [-116.04751, 49.000239],
          [-111.50165, 48.994762],
          [-109.453274, 49.000239],
          [-104.047534, 49.000239],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_NE',
    properties: { name: 'Nebraska' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-103.324578, 43.002989],
          [-101.626726, 42.997512],
          [-98.499393, 42.997512],
          [-98.466531, 42.94822],
          [-97.951699, 42.767481],
          [-97.831206, 42.866066],
          [-97.688806, 42.844158],
          [-97.217789, 42.844158],
          [-96.692003, 42.657942],
          [-96.626279, 42.515542],
          [-96.44554, 42.488157],
          [-96.264801, 42.039048],
          [-96.127878, 41.973325],
          [-96.062155, 41.798063],
          [-96.122401, 41.67757],
          [-96.095016, 41.540646],
          [-95.919754, 41.453015],
          [-95.925231, 41.201076],
          [-95.826646, 40.976521],
          [-95.881416, 40.719105],
          [-95.7664, 40.587659],
          [-95.552799, 40.264519],
          [-95.306337, 40.001626],
          [-101.90605, 40.001626],
          [-102.053927, 40.001626],
          [-102.053927, 41.003906],
          [-104.053011, 41.003906],
          [-104.053011, 43.002989],
          [-103.324578, 43.002989],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_NV',
    properties: { name: 'Nevada' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-117.027882, 42.000709],
          [-114.04295, 41.995232],
          [-114.048427, 37.000263],
          [-114.048427, 36.195153],
          [-114.152489, 36.025367],
          [-114.251074, 36.01989],
          [-114.371566, 36.140383],
          [-114.738521, 36.102045],
          [-114.678275, 35.516012],
          [-114.596121, 35.324319],
          [-114.574213, 35.138103],
          [-114.634459, 35.00118],
          [-115.85034, 35.970598],
          [-116.540435, 36.501861],
          [-117.498899, 37.21934],
          [-118.71478, 38.101128],
          [-120.001861, 38.999346],
          [-119.996384, 40.264519],
          [-120.001861, 41.995232],
          [-118.698349, 41.989755],
          [-117.027882, 42.000709],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_NH',
    properties: { name: 'New Hampshire' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-71.08183, 45.303304],
          [-71.032537, 44.657025],
          [-70.966814, 43.34256],
          [-70.807983, 43.227544],
          [-70.824413, 43.128959],
          [-70.703921, 43.057759],
          [-70.818936, 42.871543],
          [-70.917521, 42.887974],
          [-71.185891, 42.789389],
          [-71.29543, 42.696281],
          [-72.456542, 42.729142],
          [-72.544173, 42.80582],
          [-72.533219, 42.953697],
          [-72.445588, 43.008466],
          [-72.456542, 43.150867],
          [-72.379864, 43.572591],
          [-72.204602, 43.769761],
          [-72.116971, 43.994316],
          [-72.02934, 44.07647],
          [-72.034817, 44.322932],
          [-71.700724, 44.41604],
          [-71.536416, 44.585825],
          [-71.629524, 44.750133],
          [-71.4926, 44.914442],
          [-71.503554, 45.013027],
          [-71.361154, 45.270443],
          [-71.131122, 45.243058],
          [-71.08183, 45.303304],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_NJ',
    properties: { name: 'New Jersey' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-74.236547, 41.14083],
          [-73.902454, 40.998429],
          [-74.022947, 40.708151],
          [-74.187255, 40.642428],
          [-74.274886, 40.489074],
          [-74.001039, 40.412397],
          [-73.979131, 40.297381],
          [-74.099624, 39.760641],
          [-74.411809, 39.360824],
          [-74.614456, 39.245808],
          [-74.795195, 38.993869],
          [-74.888303, 39.158177],
          [-75.178581, 39.240331],
          [-75.534582, 39.459409],
          [-75.55649, 39.607286],
          [-75.561967, 39.629194],
          [-75.507197, 39.683964],
          [-75.414089, 39.804456],
          [-75.145719, 39.88661],
          [-75.129289, 39.963288],
          [-74.82258, 40.127596],
          [-74.773287, 40.215227],
          [-75.058088, 40.417874],
          [-75.069042, 40.543843],
          [-75.195012, 40.576705],
          [-75.205966, 40.691721],
          [-75.052611, 40.866983],
          [-75.134765, 40.971045],
          [-74.882826, 41.179168],
          [-74.828057, 41.288707],
          [-74.69661, 41.359907],
          [-74.236547, 41.14083],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_NM',
    properties: { name: 'New Mexico' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-107.421329, 37.000263],
          [-106.868158, 36.994786],
          [-104.337812, 36.994786],
          [-103.001438, 37.000263],
          [-103.001438, 36.501861],
          [-103.039777, 36.501861],
          [-103.045254, 34.01533],
          [-103.067161, 33.002096],
          [-103.067161, 31.999816],
          [-106.616219, 31.999816],
          [-106.643603, 31.901231],
          [-106.528588, 31.786216],
          [-108.210008, 31.786216],
          [-108.210008, 31.331629],
          [-109.04798, 31.331629],
          [-109.042503, 37.000263],
          [-107.421329, 37.000263],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_NY',
    properties: { name: 'New York' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-73.343806, 45.013027],
          [-73.332852, 44.804903],
          [-73.387622, 44.618687],
          [-73.294514, 44.437948],
          [-73.321898, 44.246255],
          [-73.436914, 44.043608],
          [-73.349283, 43.769761],
          [-73.404052, 43.687607],
          [-73.245221, 43.523299],
          [-73.278083, 42.833204],
          [-73.267129, 42.745573],
          [-73.508114, 42.08834],
          [-73.486206, 42.050002],
          [-73.55193, 41.294184],
          [-73.48073, 41.21203],
          [-73.727192, 41.102491],
          [-73.655992, 40.987475],
          [-73.22879, 40.905321],
          [-73.141159, 40.965568],
          [-72.774204, 40.965568],
          [-72.587988, 40.998429],
          [-72.28128, 41.157261],
          [-72.259372, 41.042245],
          [-72.100541, 40.992952],
          [-72.467496, 40.845075],
          [-73.239744, 40.625997],
          [-73.562884, 40.582182],
          [-73.776484, 40.593136],
          [-73.935316, 40.543843],
          [-74.022947, 40.708151],
          [-73.902454, 40.998429],
          [-74.236547, 41.14083],
          [-74.69661, 41.359907],
          [-74.740426, 41.431108],
          [-74.89378, 41.436584],
          [-75.074519, 41.60637],
          [-75.052611, 41.754247],
          [-75.173104, 41.869263],
          [-75.249781, 41.863786],
          [-75.35932, 42.000709],
          [-79.76278, 42.000709],
          [-79.76278, 42.252649],
          [-79.76278, 42.269079],
          [-79.149363, 42.55388],
          [-79.050778, 42.690804],
          [-78.853608, 42.783912],
          [-78.930285, 42.953697],
          [-79.012439, 42.986559],
          [-79.072686, 43.260406],
          [-78.486653, 43.375421],
          [-77.966344, 43.369944],
          [-77.75822, 43.34256],
          [-77.533665, 43.233021],
          [-77.391265, 43.276836],
          [-76.958587, 43.271359],
          [-76.695693, 43.34256],
          [-76.41637, 43.523299],
          [-76.235631, 43.528776],
          [-76.230154, 43.802623],
          [-76.137046, 43.961454],
          [-76.3616, 44.070993],
          [-76.312308, 44.196962],
          [-75.912491, 44.366748],
          [-75.764614, 44.514625],
          [-75.282643, 44.848718],
          [-74.828057, 45.018503],
          [-74.148916, 44.991119],
          [-73.343806, 45.013027],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_NC',
    properties: { name: 'North Carolina' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-80.978661, 36.562108],
          [-80.294043, 36.545677],
          [-79.510841, 36.5402],
          [-75.868676, 36.551154],
          [-75.75366, 36.151337],
          [-76.032984, 36.189676],
          [-76.071322, 36.140383],
          [-76.410893, 36.080137],
          [-76.460185, 36.025367],
          [-76.68474, 36.008937],
          [-76.673786, 35.937736],
          [-76.399939, 35.987029],
          [-76.3616, 35.943213],
          [-76.060368, 35.992506],
          [-75.961783, 35.899398],
          [-75.781044, 35.937736],
          [-75.715321, 35.696751],
          [-75.775568, 35.581735],
          [-75.89606, 35.570781],
          [-76.147999, 35.324319],
          [-76.482093, 35.313365],
          [-76.536862, 35.14358],
          [-76.394462, 34.973795],
          [-76.279446, 34.940933],
          [-76.493047, 34.661609],
          [-76.673786, 34.694471],
          [-76.991448, 34.667086],
          [-77.210526, 34.60684],
          [-77.555573, 34.415147],
          [-77.82942, 34.163208],
          [-77.971821, 33.845545],
          [-78.179944, 33.916745],
          [-78.541422, 33.851022],
          [-79.675149, 34.80401],
          [-80.797922, 34.820441],
          [-80.781491, 34.935456],
          [-80.934845, 35.105241],
          [-81.038907, 35.044995],
          [-81.044384, 35.149057],
          [-82.276696, 35.198349],
          [-82.550543, 35.160011],
          [-82.764143, 35.066903],
          [-83.109191, 35.00118],
          [-83.618546, 34.984749],
          [-84.319594, 34.990226],
          [-84.29221, 35.225734],
          [-84.09504, 35.247642],
          [-84.018363, 35.41195],
          [-83.7719, 35.559827],
          [-83.498053, 35.565304],
          [-83.251591, 35.718659],
          [-82.994175, 35.773428],
          [-82.775097, 35.997983],
          [-82.638174, 36.063706],
          [-82.610789, 35.965121],
          [-82.216449, 36.156814],
          [-82.03571, 36.118475],
          [-81.909741, 36.304691],
          [-81.723525, 36.353984],
          [-81.679709, 36.589492],
          [-80.978661, 36.562108],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_ND',
    properties: { name: 'North Dakota' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-97.228743, 49.000239],
          [-97.097296, 48.682577],
          [-97.16302, 48.545653],
          [-97.130158, 48.140359],
          [-97.053481, 47.948667],
          [-96.856311, 47.609096],
          [-96.823449, 46.968294],
          [-96.785111, 46.924479],
          [-96.801542, 46.656109],
          [-96.719387, 46.437031],
          [-96.598895, 46.332969],
          [-96.560556, 45.933153],
          [-104.047534, 45.944106],
          [-104.042057, 47.861036],
          [-104.047534, 49.000239],
          [-97.228743, 49.000239],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_OH',
    properties: { name: 'Ohio' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-80.518598, 41.978802],
          [-80.518598, 40.636951],
          [-80.666475, 40.582182],
          [-80.595275, 40.472643],
          [-80.600752, 40.319289],
          [-80.737675, 40.078303],
          [-80.830783, 39.711348],
          [-81.219646, 39.388209],
          [-81.345616, 39.344393],
          [-81.455155, 39.410117],
          [-81.57017, 39.267716],
          [-81.685186, 39.273193],
          [-81.811156, 39.0815],
          [-81.783771, 38.966484],
          [-81.887833, 38.873376],
          [-82.03571, 39.026731],
          [-82.221926, 38.785745],
          [-82.172634, 38.632391],
          [-82.293127, 38.577622],
          [-82.331465, 38.446175],
          [-82.594358, 38.424267],
          [-82.731282, 38.561191],
          [-82.846298, 38.588575],
          [-82.890113, 38.758361],
          [-83.032514, 38.725499],
          [-83.142052, 38.626914],
          [-83.519961, 38.703591],
          [-83.678792, 38.632391],
          [-83.903347, 38.769315],
          [-84.215533, 38.807653],
          [-84.231963, 38.895284],
          [-84.43461, 39.103408],
          [-84.817996, 39.103408],
          [-84.801565, 40.500028],
          [-84.807042, 41.694001],
          [-83.454238, 41.732339],
          [-83.065375, 41.595416],
          [-82.933929, 41.513262],
          [-82.835344, 41.589939],
          [-82.616266, 41.431108],
          [-82.479343, 41.381815],
          [-82.013803, 41.513262],
          [-81.739956, 41.485877],
          [-81.444201, 41.672093],
          [-81.011523, 41.852832],
          [-80.518598, 41.978802],
          [-80.518598, 41.978802],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_OK',
    properties: { name: 'Oklahoma' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-100.087706, 37.000263],
          [-94.616242, 37.000263],
          [-94.616242, 36.501861],
          [-94.430026, 35.395519],
          [-94.484796, 33.637421],
          [-94.868182, 33.74696],
          [-94.966767, 33.861976],
          [-95.224183, 33.960561],
          [-95.289906, 33.87293],
          [-95.547322, 33.878407],
          [-95.602092, 33.933176],
          [-95.8376, 33.834591],
          [-95.936185, 33.889361],
          [-96.149786, 33.840068],
          [-96.346956, 33.686714],
          [-96.423633, 33.774345],
          [-96.631756, 33.845545],
          [-96.850834, 33.845545],
          [-96.922034, 33.960561],
          [-97.173974, 33.736006],
          [-97.256128, 33.861976],
          [-97.371143, 33.823637],
          [-97.458774, 33.905791],
          [-97.694283, 33.982469],
          [-97.869545, 33.851022],
          [-97.946222, 33.987946],
          [-98.088623, 34.004376],
          [-98.170777, 34.113915],
          [-98.36247, 34.157731],
          [-98.488439, 34.064623],
          [-98.570593, 34.146777],
          [-98.767763, 34.135823],
          [-98.986841, 34.223454],
          [-99.189488, 34.2125],
          [-99.260688, 34.404193],
          [-99.57835, 34.415147],
          [-99.698843, 34.382285],
          [-99.923398, 34.573978],
          [-100.000075, 34.563024],
          [-100.000075, 36.501861],
          [-101.812942, 36.501861],
          [-103.001438, 36.501861],
          [-103.001438, 37.000263],
          [-102.042974, 36.994786],
          [-100.087706, 37.000263],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_OR',
    properties: { name: 'Oregon' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-123.211348, 46.174138],
          [-123.11824, 46.185092],
          [-122.904639, 46.08103],
          [-122.811531, 45.960537],
          [-122.762239, 45.659305],
          [-122.247407, 45.549767],
          [-121.809251, 45.708598],
          [-121.535404, 45.725029],
          [-121.217742, 45.670259],
          [-121.18488, 45.604536],
          [-120.637186, 45.746937],
          [-120.505739, 45.697644],
          [-120.209985, 45.725029],
          [-119.963522, 45.823614],
          [-119.525367, 45.911245],
          [-119.125551, 45.933153],
          [-118.988627, 45.998876],
          [-116.918344, 45.993399],
          [-116.78142, 45.823614],
          [-116.545912, 45.752413],
          [-116.463758, 45.61549],
          [-116.671881, 45.319735],
          [-116.732128, 45.144473],
          [-116.847143, 45.02398],
          [-116.830713, 44.930872],
          [-116.934774, 44.782995],
          [-117.038836, 44.750133],
          [-117.241483, 44.394132],
          [-117.170283, 44.257209],
          [-116.97859, 44.240778],
          [-116.896436, 44.158624],
          [-117.027882, 43.830007],
          [-117.027882, 42.000709],
          [-118.698349, 41.989755],
          [-120.001861, 41.995232],
          [-121.037003, 41.995232],
          [-122.378853, 42.011663],
          [-123.233256, 42.006186],
          [-124.213628, 42.000709],
          [-124.356029, 42.115725],
          [-124.432706, 42.438865],
          [-124.416275, 42.663419],
          [-124.553198, 42.838681],
          [-124.454613, 43.002989],
          [-124.383413, 43.271359],
          [-124.235536, 43.55616],
          [-124.169813, 43.8081],
          [-124.060274, 44.657025],
          [-124.076705, 44.772041],
          [-123.97812, 45.144473],
          [-123.939781, 45.659305],
          [-123.994551, 45.944106],
          [-123.945258, 46.113892],
          [-123.545441, 46.261769],
          [-123.370179, 46.146753],
          [-123.211348, 46.174138],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_PA',
    properties: { name: 'Pennsylvania' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-79.76278, 42.252649],
          [-79.76278, 42.000709],
          [-75.35932, 42.000709],
          [-75.249781, 41.863786],
          [-75.173104, 41.869263],
          [-75.052611, 41.754247],
          [-75.074519, 41.60637],
          [-74.89378, 41.436584],
          [-74.740426, 41.431108],
          [-74.69661, 41.359907],
          [-74.828057, 41.288707],
          [-74.882826, 41.179168],
          [-75.134765, 40.971045],
          [-75.052611, 40.866983],
          [-75.205966, 40.691721],
          [-75.195012, 40.576705],
          [-75.069042, 40.543843],
          [-75.058088, 40.417874],
          [-74.773287, 40.215227],
          [-74.82258, 40.127596],
          [-75.129289, 39.963288],
          [-75.145719, 39.88661],
          [-75.414089, 39.804456],
          [-75.616736, 39.831841],
          [-75.786521, 39.722302],
          [-79.477979, 39.722302],
          [-80.518598, 39.722302],
          [-80.518598, 40.636951],
          [-80.518598, 41.978802],
          [-80.518598, 41.978802],
          [-80.332382, 42.033571],
          [-79.76278, 42.269079],
          [-79.76278, 42.252649],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_RI',
    properties: { name: 'Rhode Island' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-71.196845, 41.67757],
            [-71.120168, 41.496831],
            [-71.317338, 41.474923],
            [-71.196845, 41.67757],
          ],
        ],
        [
          [
            [-71.530939, 42.01714],
            [-71.383061, 42.01714],
            [-71.328292, 41.781632],
            [-71.22423, 41.710431],
            [-71.344723, 41.726862],
            [-71.448785, 41.578985],
            [-71.481646, 41.370861],
            [-71.859555, 41.321569],
            [-71.799309, 41.414677],
            [-71.799309, 42.006186],
            [-71.530939, 42.01714],
          ],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_SC',
    properties: { name: 'South Carolina' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-82.764143, 35.066903],
          [-82.550543, 35.160011],
          [-82.276696, 35.198349],
          [-81.044384, 35.149057],
          [-81.038907, 35.044995],
          [-80.934845, 35.105241],
          [-80.781491, 34.935456],
          [-80.797922, 34.820441],
          [-79.675149, 34.80401],
          [-78.541422, 33.851022],
          [-78.716684, 33.80173],
          [-78.935762, 33.637421],
          [-79.149363, 33.380005],
          [-79.187701, 33.171881],
          [-79.357487, 33.007573],
          [-79.582041, 33.007573],
          [-79.631334, 32.887081],
          [-79.866842, 32.755634],
          [-79.998289, 32.613234],
          [-80.206412, 32.552987],
          [-80.430967, 32.399633],
          [-80.452875, 32.328433],
          [-80.660998, 32.246279],
          [-80.885553, 32.032678],
          [-81.115584, 32.120309],
          [-81.121061, 32.290094],
          [-81.279893, 32.558464],
          [-81.416816, 32.629664],
          [-81.42777, 32.843265],
          [-81.493493, 33.007573],
          [-81.761863, 33.160928],
          [-81.937125, 33.347144],
          [-81.926172, 33.462159],
          [-82.194542, 33.631944],
          [-82.325988, 33.81816],
          [-82.55602, 33.94413],
          [-82.714851, 34.152254],
          [-82.747713, 34.26727],
          [-82.901067, 34.486347],
          [-83.005129, 34.469916],
          [-83.339222, 34.683517],
          [-83.322791, 34.787579],
          [-83.109191, 35.00118],
          [-82.764143, 35.066903],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_SD',
    properties: { name: 'South Dakota' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-104.047534, 45.944106],
          [-96.560556, 45.933153],
          [-96.582464, 45.818137],
          [-96.856311, 45.604536],
          [-96.681049, 45.412843],
          [-96.451017, 45.297827],
          [-96.451017, 43.501391],
          [-96.582464, 43.479483],
          [-96.527695, 43.397329],
          [-96.560556, 43.222067],
          [-96.434587, 43.123482],
          [-96.511264, 43.052282],
          [-96.544125, 42.855112],
          [-96.631756, 42.707235],
          [-96.44554, 42.488157],
          [-96.626279, 42.515542],
          [-96.692003, 42.657942],
          [-97.217789, 42.844158],
          [-97.688806, 42.844158],
          [-97.831206, 42.866066],
          [-97.951699, 42.767481],
          [-98.466531, 42.94822],
          [-98.499393, 42.997512],
          [-101.626726, 42.997512],
          [-103.324578, 43.002989],
          [-104.053011, 43.002989],
          [-104.058488, 44.996596],
          [-104.042057, 44.996596],
          [-104.047534, 45.944106],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_TN',
    properties: { name: 'Tennessee' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-88.054868, 36.496384],
          [-88.071299, 36.677123],
          [-87.852221, 36.633308],
          [-86.592525, 36.655216],
          [-85.486183, 36.616877],
          [-85.289013, 36.627831],
          [-84.544149, 36.594969],
          [-83.689746, 36.584015],
          [-83.673316, 36.600446],
          [-81.679709, 36.589492],
          [-81.723525, 36.353984],
          [-81.909741, 36.304691],
          [-82.03571, 36.118475],
          [-82.216449, 36.156814],
          [-82.610789, 35.965121],
          [-82.638174, 36.063706],
          [-82.775097, 35.997983],
          [-82.994175, 35.773428],
          [-83.251591, 35.718659],
          [-83.498053, 35.565304],
          [-83.7719, 35.559827],
          [-84.018363, 35.41195],
          [-84.09504, 35.247642],
          [-84.29221, 35.225734],
          [-84.319594, 34.990226],
          [-85.606675, 34.984749],
          [-87.359296, 35.00118],
          [-88.202745, 34.995703],
          [-88.471115, 34.995703],
          [-90.311367, 34.995703],
          [-90.212782, 35.023087],
          [-90.114197, 35.198349],
          [-90.130628, 35.439335],
          [-89.944412, 35.603643],
          [-89.911551, 35.756997],
          [-89.763673, 35.811767],
          [-89.730812, 35.997983],
          [-89.533642, 36.249922],
          [-89.539119, 36.496384],
          [-89.484349, 36.496384],
          [-89.418626, 36.496384],
          [-89.298133, 36.507338],
          [-88.054868, 36.496384],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_TX',
    properties: { name: 'Texas' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-101.812942, 36.501861],
          [-100.000075, 36.501861],
          [-100.000075, 34.563024],
          [-99.923398, 34.573978],
          [-99.698843, 34.382285],
          [-99.57835, 34.415147],
          [-99.260688, 34.404193],
          [-99.189488, 34.2125],
          [-98.986841, 34.223454],
          [-98.767763, 34.135823],
          [-98.570593, 34.146777],
          [-98.488439, 34.064623],
          [-98.36247, 34.157731],
          [-98.170777, 34.113915],
          [-98.088623, 34.004376],
          [-97.946222, 33.987946],
          [-97.869545, 33.851022],
          [-97.694283, 33.982469],
          [-97.458774, 33.905791],
          [-97.371143, 33.823637],
          [-97.256128, 33.861976],
          [-97.173974, 33.736006],
          [-96.922034, 33.960561],
          [-96.850834, 33.845545],
          [-96.631756, 33.845545],
          [-96.423633, 33.774345],
          [-96.346956, 33.686714],
          [-96.149786, 33.840068],
          [-95.936185, 33.889361],
          [-95.8376, 33.834591],
          [-95.602092, 33.933176],
          [-95.547322, 33.878407],
          [-95.289906, 33.87293],
          [-95.224183, 33.960561],
          [-94.966767, 33.861976],
          [-94.868182, 33.74696],
          [-94.484796, 33.637421],
          [-94.380734, 33.544313],
          [-94.183564, 33.593606],
          [-94.041164, 33.54979],
          [-94.041164, 33.018527],
          [-94.041164, 31.994339],
          [-93.822086, 31.775262],
          [-93.816609, 31.556184],
          [-93.542762, 31.15089],
          [-93.526331, 30.93729],
          [-93.630393, 30.679874],
          [-93.728978, 30.575812],
          [-93.696116, 30.438888],
          [-93.767317, 30.334826],
          [-93.690639, 30.143133],
          [-93.926148, 29.787132],
          [-93.838517, 29.688547],
          [-94.002825, 29.68307],
          [-94.523134, 29.546147],
          [-94.70935, 29.622824],
          [-94.742212, 29.787132],
          [-94.873659, 29.672117],
          [-94.966767, 29.699501],
          [-95.016059, 29.557101],
          [-94.911997, 29.496854],
          [-94.895566, 29.310638],
          [-95.081782, 29.113469],
          [-95.383014, 28.867006],
          [-95.985477, 28.604113],
          [-96.045724, 28.647929],
          [-96.226463, 28.582205],
          [-96.23194, 28.642452],
          [-96.478402, 28.598636],
          [-96.593418, 28.724606],
          [-96.664618, 28.697221],
          [-96.401725, 28.439805],
          [-96.593418, 28.357651],
          [-96.774157, 28.406943],
          [-96.801542, 28.226204],
          [-97.026096, 28.039988],
          [-97.256128, 27.694941],
          [-97.404005, 27.333463],
          [-97.513544, 27.360848],
          [-97.540929, 27.229401],
          [-97.425913, 27.262263],
          [-97.480682, 26.99937],
          [-97.557359, 26.988416],
          [-97.562836, 26.840538],
          [-97.469728, 26.758384],
          [-97.442344, 26.457153],
          [-97.332805, 26.353091],
          [-97.30542, 26.161398],
          [-97.217789, 25.991613],
          [-97.524498, 25.887551],
          [-97.650467, 26.018997],
          [-97.885976, 26.06829],
          [-98.198161, 26.057336],
          [-98.466531, 26.221644],
          [-98.669178, 26.238075],
          [-98.822533, 26.369522],
          [-99.030656, 26.413337],
          [-99.173057, 26.539307],
          [-99.266165, 26.840538],
          [-99.446904, 27.021277],
          [-99.424996, 27.174632],
          [-99.50715, 27.33894],
          [-99.479765, 27.48134],
          [-99.605735, 27.640172],
          [-99.709797, 27.656603],
          [-99.879582, 27.799003],
          [-99.934351, 27.979742],
          [-100.082229, 28.14405],
          [-100.29583, 28.280974],
          [-100.399891, 28.582205],
          [-100.498476, 28.66436],
          [-100.629923, 28.905345],
          [-100.673738, 29.102515],
          [-100.799708, 29.244915],
          [-101.013309, 29.370885],
          [-101.062601, 29.458516],
          [-101.259771, 29.535193],
          [-101.413125, 29.754271],
          [-101.851281, 29.803563],
          [-102.114174, 29.792609],
          [-102.338728, 29.869286],
          [-102.388021, 29.765225],
          [-102.629006, 29.732363],
          [-102.809745, 29.524239],
          [-102.919284, 29.190146],
          [-102.97953, 29.184669],
          [-103.116454, 28.987499],
          [-103.280762, 28.982022],
          [-103.527224, 29.135376],
          [-104.146119, 29.381839],
          [-104.266611, 29.513285],
          [-104.507597, 29.639255],
          [-104.677382, 29.924056],
          [-104.688336, 30.181472],
          [-104.858121, 30.389596],
          [-104.896459, 30.570335],
          [-105.005998, 30.685351],
          [-105.394861, 30.855136],
          [-105.602985, 31.085167],
          [-105.77277, 31.167321],
          [-105.953509, 31.364491],
          [-106.205448, 31.468553],
          [-106.38071, 31.731446],
          [-106.528588, 31.786216],
          [-106.643603, 31.901231],
          [-106.616219, 31.999816],
          [-103.067161, 31.999816],
          [-103.067161, 33.002096],
          [-103.045254, 34.01533],
          [-103.039777, 36.501861],
          [-103.001438, 36.501861],
          [-101.812942, 36.501861],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_UT',
    properties: { name: 'Utah' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-112.164359, 41.995232],
          [-111.047063, 42.000709],
          [-111.047063, 40.998429],
          [-109.04798, 40.998429],
          [-109.053457, 39.125316],
          [-109.058934, 38.27639],
          [-109.042503, 38.166851],
          [-109.042503, 37.000263],
          [-110.499369, 37.00574],
          [-114.048427, 37.000263],
          [-114.04295, 41.995232],
          [-112.164359, 41.995232],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_VT',
    properties: { name: 'Vermont' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-71.503554, 45.013027],
          [-71.4926, 44.914442],
          [-71.629524, 44.750133],
          [-71.536416, 44.585825],
          [-71.700724, 44.41604],
          [-72.034817, 44.322932],
          [-72.02934, 44.07647],
          [-72.116971, 43.994316],
          [-72.204602, 43.769761],
          [-72.379864, 43.572591],
          [-72.456542, 43.150867],
          [-72.445588, 43.008466],
          [-72.533219, 42.953697],
          [-72.544173, 42.80582],
          [-72.456542, 42.729142],
          [-73.267129, 42.745573],
          [-73.278083, 42.833204],
          [-73.245221, 43.523299],
          [-73.404052, 43.687607],
          [-73.349283, 43.769761],
          [-73.436914, 44.043608],
          [-73.321898, 44.246255],
          [-73.294514, 44.437948],
          [-73.387622, 44.618687],
          [-73.332852, 44.804903],
          [-73.343806, 45.013027],
          [-72.308664, 45.002073],
          [-71.503554, 45.013027],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_VA',
    properties: { name: 'Virginia' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-78.349729, 39.464886],
            [-77.82942, 39.130793],
            [-77.719881, 39.322485],
            [-77.566527, 39.306055],
            [-77.456988, 39.223901],
            [-77.456988, 39.076023],
            [-77.248864, 39.026731],
            [-77.117418, 38.933623],
            [-77.040741, 38.791222],
            [-77.128372, 38.632391],
            [-77.248864, 38.588575],
            [-77.325542, 38.446175],
            [-77.281726, 38.342113],
            [-77.013356, 38.374975],
            [-76.964064, 38.216144],
            [-76.613539, 38.15042],
            [-76.514954, 38.024451],
            [-76.235631, 37.887527],
            [-76.3616, 37.608203],
            [-76.246584, 37.389126],
            [-76.383508, 37.285064],
            [-76.399939, 37.159094],
            [-76.273969, 37.082417],
            [-76.410893, 36.961924],
            [-76.619016, 37.120755],
            [-76.668309, 37.065986],
            [-76.48757, 36.95097],
            [-75.994645, 36.923586],
            [-75.868676, 36.551154],
            [-79.510841, 36.5402],
            [-80.294043, 36.545677],
            [-80.978661, 36.562108],
            [-81.679709, 36.589492],
            [-83.673316, 36.600446],
            [-83.136575, 36.742847],
            [-83.070852, 36.852385],
            [-82.879159, 36.890724],
            [-82.868205, 36.978355],
            [-82.720328, 37.044078],
            [-82.720328, 37.120755],
            [-82.353373, 37.268633],
            [-81.969987, 37.537003],
            [-81.986418, 37.454849],
            [-81.849494, 37.285064],
            [-81.679709, 37.20291],
            [-81.55374, 37.208387],
            [-81.362047, 37.339833],
            [-81.225123, 37.235771],
            [-80.967707, 37.290541],
            [-80.513121, 37.482234],
            [-80.474782, 37.421987],
            [-80.29952, 37.509618],
            [-80.294043, 37.690357],
            [-80.184505, 37.849189],
            [-79.998289, 37.997066],
            [-79.921611, 38.177805],
            [-79.724442, 38.364021],
            [-79.647764, 38.594052],
            [-79.477979, 38.457129],
            [-79.313671, 38.413313],
            [-79.209609, 38.495467],
            [-78.996008, 38.851469],
            [-78.870039, 38.763838],
            [-78.404499, 39.169131],
            [-78.349729, 39.464886],
          ],
        ],
        [
          [
            [-75.397659, 38.013497],
            [-75.244304, 38.029928],
            [-75.375751, 37.860142],
            [-75.512674, 37.799896],
            [-75.594828, 37.569865],
            [-75.802952, 37.197433],
            [-75.972737, 37.120755],
            [-76.027507, 37.257679],
            [-75.939876, 37.564388],
            [-75.671506, 37.95325],
            [-75.397659, 38.013497],
          ],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_WA',
    properties: { name: 'Washington' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-117.033359, 49.000239],
            [-117.044313, 47.762451],
            [-117.038836, 46.426077],
            [-117.055267, 46.343923],
            [-116.92382, 46.168661],
            [-116.918344, 45.993399],
            [-118.988627, 45.998876],
            [-119.125551, 45.933153],
            [-119.525367, 45.911245],
            [-119.963522, 45.823614],
            [-120.209985, 45.725029],
            [-120.505739, 45.697644],
            [-120.637186, 45.746937],
            [-121.18488, 45.604536],
            [-121.217742, 45.670259],
            [-121.535404, 45.725029],
            [-121.809251, 45.708598],
            [-122.247407, 45.549767],
            [-122.762239, 45.659305],
            [-122.811531, 45.960537],
            [-122.904639, 46.08103],
            [-123.11824, 46.185092],
            [-123.211348, 46.174138],
            [-123.370179, 46.146753],
            [-123.545441, 46.261769],
            [-123.72618, 46.300108],
            [-123.874058, 46.239861],
            [-124.065751, 46.327492],
            [-124.027412, 46.464416],
            [-123.895966, 46.535616],
            [-124.098612, 46.74374],
            [-124.235536, 47.285957],
            [-124.31769, 47.357157],
            [-124.427229, 47.740543],
            [-124.624399, 47.88842],
            [-124.706553, 48.184175],
            [-124.597014, 48.381345],
            [-124.394367, 48.288237],
            [-123.983597, 48.162267],
            [-123.704273, 48.167744],
            [-123.424949, 48.118452],
            [-123.162056, 48.167744],
            [-123.036086, 48.080113],
            [-122.800578, 48.08559],
            [-122.636269, 47.866512],
            [-122.515777, 47.882943],
            [-122.493869, 47.587189],
            [-122.422669, 47.318818],
            [-122.324084, 47.346203],
            [-122.422669, 47.576235],
            [-122.395284, 47.800789],
            [-122.230976, 48.030821],
            [-122.362422, 48.123929],
            [-122.373376, 48.288237],
            [-122.471961, 48.468976],
            [-122.422669, 48.600422],
            [-122.488392, 48.753777],
            [-122.647223, 48.775685],
            [-122.795101, 48.8907],
            [-122.756762, 49.000239],
            [-117.033359, 49.000239],
          ],
        ],
        [
          [
            [-122.718423, 48.310145],
            [-122.586977, 48.35396],
            [-122.608885, 48.151313],
            [-122.767716, 48.227991],
            [-122.718423, 48.310145],
          ],
        ],
        [
          [
            [-123.025132, 48.583992],
            [-122.915593, 48.715438],
            [-122.767716, 48.556607],
            [-122.811531, 48.419683],
            [-123.041563, 48.458022],
            [-123.025132, 48.583992],
          ],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_WV',
    properties: { name: 'West Virginia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-80.518598, 40.636951],
          [-80.518598, 39.722302],
          [-79.477979, 39.722302],
          [-79.488933, 39.20747],
          [-79.291763, 39.300578],
          [-79.094593, 39.470363],
          [-78.963147, 39.437501],
          [-78.765977, 39.585379],
          [-78.470222, 39.514178],
          [-78.431884, 39.623717],
          [-78.267575, 39.61824],
          [-78.174467, 39.694917],
          [-78.004682, 39.601809],
          [-77.834897, 39.601809],
          [-77.719881, 39.322485],
          [-77.82942, 39.130793],
          [-78.349729, 39.464886],
          [-78.404499, 39.169131],
          [-78.870039, 38.763838],
          [-78.996008, 38.851469],
          [-79.209609, 38.495467],
          [-79.313671, 38.413313],
          [-79.477979, 38.457129],
          [-79.647764, 38.594052],
          [-79.724442, 38.364021],
          [-79.921611, 38.177805],
          [-79.998289, 37.997066],
          [-80.184505, 37.849189],
          [-80.294043, 37.690357],
          [-80.29952, 37.509618],
          [-80.474782, 37.421987],
          [-80.513121, 37.482234],
          [-80.967707, 37.290541],
          [-81.225123, 37.235771],
          [-81.362047, 37.339833],
          [-81.55374, 37.208387],
          [-81.679709, 37.20291],
          [-81.849494, 37.285064],
          [-81.986418, 37.454849],
          [-81.969987, 37.537003],
          [-82.101434, 37.553434],
          [-82.293127, 37.668449],
          [-82.342419, 37.783465],
          [-82.50125, 37.931343],
          [-82.621743, 38.123036],
          [-82.594358, 38.424267],
          [-82.331465, 38.446175],
          [-82.293127, 38.577622],
          [-82.172634, 38.632391],
          [-82.221926, 38.785745],
          [-82.03571, 39.026731],
          [-81.887833, 38.873376],
          [-81.783771, 38.966484],
          [-81.811156, 39.0815],
          [-81.685186, 39.273193],
          [-81.57017, 39.267716],
          [-81.455155, 39.410117],
          [-81.345616, 39.344393],
          [-81.219646, 39.388209],
          [-80.830783, 39.711348],
          [-80.737675, 40.078303],
          [-80.600752, 40.319289],
          [-80.595275, 40.472643],
          [-80.666475, 40.582182],
          [-80.518598, 40.636951],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_WI',
    properties: { name: 'Wisconsin' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-90.415429, 46.568478],
          [-90.229213, 46.508231],
          [-90.119674, 46.338446],
          [-89.09001, 46.135799],
          [-88.662808, 45.987922],
          [-88.531362, 46.020784],
          [-88.10416, 45.922199],
          [-87.989145, 45.796229],
          [-87.781021, 45.675736],
          [-87.791975, 45.500474],
          [-87.885083, 45.363551],
          [-87.649574, 45.341643],
          [-87.742682, 45.199243],
          [-87.589328, 45.095181],
          [-87.627666, 44.974688],
          [-87.819359, 44.95278],
          [-87.983668, 44.722749],
          [-88.043914, 44.563917],
          [-87.928898, 44.536533],
          [-87.775544, 44.640595],
          [-87.611236, 44.837764],
          [-87.403112, 44.914442],
          [-87.238804, 45.166381],
          [-87.03068, 45.22115],
          [-87.047111, 45.089704],
          [-87.189511, 44.969211],
          [-87.468835, 44.552964],
          [-87.545512, 44.322932],
          [-87.540035, 44.158624],
          [-87.644097, 44.103854],
          [-87.737205, 43.8793],
          [-87.704344, 43.687607],
          [-87.791975, 43.561637],
          [-87.912467, 43.249452],
          [-87.885083, 43.002989],
          [-87.76459, 42.783912],
          [-87.802929, 42.493634],
          [-88.788778, 42.493634],
          [-90.639984, 42.510065],
          [-90.711184, 42.636034],
          [-91.067185, 42.75105],
          [-91.143862, 42.909881],
          [-91.176724, 43.134436],
          [-91.056231, 43.254929],
          [-91.204109, 43.353514],
          [-91.215062, 43.501391],
          [-91.269832, 43.616407],
          [-91.242447, 43.775238],
          [-91.43414, 43.994316],
          [-91.592971, 44.032654],
          [-91.877772, 44.202439],
          [-91.927065, 44.333886],
          [-92.233773, 44.443425],
          [-92.337835, 44.552964],
          [-92.545959, 44.569394],
          [-92.808852, 44.750133],
          [-92.737652, 45.117088],
          [-92.75956, 45.286874],
          [-92.644544, 45.440228],
          [-92.770513, 45.566198],
          [-92.885529, 45.577151],
          [-92.869098, 45.719552],
          [-92.639067, 45.933153],
          [-92.354266, 46.015307],
          [-92.29402, 46.075553],
          [-92.29402, 46.667063],
          [-92.091373, 46.749217],
          [-92.014696, 46.705401],
          [-91.790141, 46.694447],
          [-91.09457, 46.864232],
          [-90.837154, 46.95734],
          [-90.749522, 46.88614],
          [-90.886446, 46.754694],
          [-90.55783, 46.584908],
          [-90.415429, 46.568478],
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'US_WY',
    properties: { name: 'Wyoming' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-109.080842, 45.002073],
          [-105.91517, 45.002073],
          [-104.058488, 44.996596],
          [-104.053011, 43.002989],
          [-104.053011, 41.003906],
          [-105.728954, 40.998429],
          [-107.919731, 41.003906],
          [-109.04798, 40.998429],
          [-111.047063, 40.998429],
          [-111.047063, 42.000709],
          [-111.047063, 44.476286],
          [-111.05254, 45.002073],
          [-109.080842, 45.002073],
        ],
      ],
    },
  },
]
