import React from 'react'
import Box from '@mui/material/Box'
import { AssetPolicyAssetDTO } from '@ketch-com/figurehead'
import { v4 as uuidv4 } from 'uuid'

type Props = {
  policyName: string
  resources: AssetPolicyAssetDTO[]
}

export const PolicyNameRenderer: React.FC<Props> = ({ policyName, resources }) => (
  <Box display="flex" flexDirection="column">
    {(resources || [{}]).map((res, index) => (
      <Box
        key={uuidv4()}
        display="flex"
        flexDirection="row"
        alignItems="center"
        mb={1.75}
        style={{ opacity: index === 0 ? 1 : 0 }}
      >
        {policyName}
      </Box>
    ))}
  </Box>
)
