import React, { useContext, useState } from 'react'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormMode } from 'interfaces/formModes/formMode'
import { getStatusRequestBody } from '../../upsert/utils'
import { PrivacyProtocolContext } from '../context/PrivacyProtocolContext'
import { PrivacyProtocolConfigurationResponseBodyStatus } from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'
import { documentedProtocols, protocolDocumentationLink } from '../../constants'
import { Box } from '@mui/material'
import { Button } from '@ketch-com/deck'

/**
 * Buttons for toggling the status of a protocol and redirecting to its edit screen
 */
export const PrivacyProtocolViewActions: React.FC = () => {
  const {
    canWrite,
    navigate,
    protocol: initialProtocol,
    refetchProtocol,
    updateStatus,
  } = useContext(PrivacyProtocolContext)
  const [protocol] = useState(initialProtocol)

  const handleDocumentationClick = () => {
    window.open(protocolDocumentationLink[protocol.id], '_blank', 'noreferrer')
  }

  const handleEditClick = () => {
    navigate(
      RoutesManager.orchestration.consent.privacyProtocols.upsert.root.getURL({
        id: protocol.id,
        formMode: FormMode.EDIT,
      }),
    )
  }

  // Flip status of protocol
  const handleEnableDisableClick = async () => {
    const newStatus =
      protocol.config?.status === PrivacyProtocolConfigurationResponseBodyStatus.enabled
        ? PrivacyProtocolConfigurationResponseBodyStatus.disabled
        : PrivacyProtocolConfigurationResponseBodyStatus.enabled
    await updateStatus(protocol.id, { privacyProtocolConfig: getStatusRequestBody(newStatus) })
    await refetchProtocol()
  }

  return canWrite ? (
    <>
      <Box display="flex" gap={1.5}>
        {/* Include documentation button if this is a protocol with its own page */}
        {documentedProtocols.includes(protocol.id) && (
          <Button onClick={handleDocumentationClick} color="tertiary" size="large">
            Documentation
          </Button>
        )}

        <Button onClick={handleEditClick} color="secondary" size="large">
          Edit
        </Button>

        <Button color="primary" size="large" onClick={handleEnableDisableClick}>
          {protocol.config?.status === PrivacyProtocolConfigurationResponseBodyStatus.enabled ? 'Disable' : 'Enable'}
        </Button>
      </Box>
    </>
  ) : (
    <></>
  )
}
