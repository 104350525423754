import React from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { useWorkflowExecutionStepDetails } from 'api/workflowExecutions/queries/useWorkflowExecutionStepDetails'
import { useCompleteWorkflowExecutionStep } from 'api/workflowExecutions/mutations/useCompleteWorkflowExecutionStep'
import { useUpdateWorkflowExecutionStepAssignee } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStepAssignee'
import { useFilesInfo } from 'api/files/queries/useFilesInfo'
import { useUsers } from 'api/users/queries/useUsers'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { WorkflowExecutionStepApproveFormValues } from './utils'
import { WorkflowExecutionStepApproveModal } from './WorkflowExecutionStepApproveModal'

type Props = {
  workflowExecutionStep: WorkflowExecutionStepDTO
  onClose: () => void
}

export const WorkflowExecutionStepApproveModalContainer: React.FC<Props> = ({ workflowExecutionStep, onClose }) => {
  const queryClient = useQueryClient()

  const { stepID, workflowExecutionID } = workflowExecutionStep

  const { data: approveStepDetails, isLoading: isApproveStepDetailsLoading } = useWorkflowExecutionStepDetails({
    params: {
      workflowExecutionId: workflowExecutionID,
      stepId: stepID,
    },
  })

  const stepToApproveId = approveStepDetails?.activity?.params?.activity

  const { data: stepToApproveDetails, isLoading: isStepToApproveDetailsLoading } = useWorkflowExecutionStepDetails({
    enabled: !!stepToApproveId,
    params: {
      workflowExecutionId: workflowExecutionID,
      stepId: stepToApproveId!,
    },
  })

  const resolutionAttachments = stepToApproveDetails?.results?.[stepToApproveId!]?.resolutionAttachments || []

  const { data: filesInfo, isLoading: isFilesInfoLoading } = useFilesInfo({
    enabled: !!resolutionAttachments.length,
    params: {
      infoUrls: resolutionAttachments,
    },
  })

  const { data: users, isLoading: isUsersLoading } = useUsers({
    params: {
      active: true,
    },
  })

  const { mutateAsync: handleCompleteWorkflowExecutionStep } = useCompleteWorkflowExecutionStep({
    onSuccess: async (_, variables) => {
      const decision = variables?.formData?.results?.decisionBoolean

      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      showToast({ content: `Activity was ${decision === false ? 'disapproved' : 'approved'}`, type: 'success' })
      onClose()
    },
    onError: () => {
      showToast({ content: 'Failed to approve activity', type: 'error' })
    },
  })

  const { mutateAsync: handleUpdateWorkflowExecutionStepAssignee } = useUpdateWorkflowExecutionStepAssignee({
    onSuccess: async () => {
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      showToast({ content: 'Activity reassigned', type: 'success' })
      onClose()
    },
    onError: () => {
      showToast({ content: 'Failed to reassign activity', type: 'error' })
    },
  })

  // Step can be either completed (if assignee is current user) or reassigned if assignee is not current user
  const onSubmit = async (values?: WorkflowExecutionStepApproveFormValues, isReassign?: boolean) => {
    if (isReassign) {
      await handleUpdateWorkflowExecutionStepAssignee({
        params: {
          workflowExecutionId: workflowExecutionID,
          stepId: stepID,
          assigneeId: values?.assigneeId || '',
        },
      })
    } else {
      await handleCompleteWorkflowExecutionStep({
        params: {
          workflowExecutionId: workflowExecutionID,
          stepId: stepID,
          formData:
            values?.decisionBoolean !== undefined
              ? { results: { decisionBoolean: values?.decisionBoolean } }
              : undefined,
        },
      })
    }

    await queryClient.refetchQueries([
      ApiQueryKeys.workflowExecution,
      {
        workflowExecutionId: workflowExecutionID,
      },
    ])

    await queryClient.refetchQueries([
      ApiQueryKeys.workflowExecutionStepDetails,
      {
        workflowExecutionId: workflowExecutionID,
        stepId: stepID,
      },
    ])
  }

  const isReady =
    !isApproveStepDetailsLoading && !isStepToApproveDetailsLoading && !isFilesInfoLoading && !isUsersLoading

  return (
    <WorkflowExecutionStepApproveModal
      isReady={isReady}
      users={users}
      filesInfo={filesInfo}
      approveStepDetails={approveStepDetails}
      stepToApproveDetails={stepToApproveDetails}
      onSubmit={onSubmit}
      onClose={onClose}
      stepId={workflowExecutionStep.stepID}
    />
  )
}
