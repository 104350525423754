import { showToast } from 'components/ui-kit/toastr/Toastr'
import { makeExportCSVData } from '../utils/makeExportCSVData'
import { DataMapPurposeDTO } from '@ketch-com/figurehead'
import { useAllDataMapPurposes } from 'api/dataMap/queries/useAllDataMapPurposes'

export type DataMapPurposesPage = { purposes: DataMapPurposeDTO[]; totalResults: number; cursor?: string }

export type InfiniteDataMapResult = { pageParams: number[] | undefined[]; pages: DataMapPurposesPage[] }

const handleOnSettled = ({
  result,
  fetchNextPage,
  error,
}: {
  result: InfiniteDataMapResult
  fetchNextPage: () => {}
  error: unknown
}): void => {
  if (error) {
    showToast({ content: 'Failed to fetch data map purposes for export', type: 'error' })
    return
  }
  const { pages } = result
  const totalResults = pages?.[0]?.totalResults || 0
  let totalFetchedDataMapPurposesSoFar = 0
  pages?.forEach?.((p: DataMapPurposesPage) => {
    totalFetchedDataMapPurposesSoFar += p.purposes.length
  })
  if (totalFetchedDataMapPurposesSoFar < totalResults) {
    fetchNextPage()
  }
}

export const useExportRopaCSVData = (enabled?: boolean) => {
  const {
    fetchNextPage,
    hasNextPage,
    isLoading,
    data: dataMapPurposes,
  } = useAllDataMapPurposes({
    enabled,
    params: {
      limit: 5,
    },
    onSettled: (result: InfiniteDataMapResult, error: unknown): void =>
      handleOnSettled({ result, fetchNextPage, error }),
  })

  return {
    data: makeExportCSVData(dataMapPurposes),
    headers: [
      { label: 'Purpose', key: 'name' },
      { label: 'Description', key: 'description' },
      { label: 'Duration of Data Usage', key: 'duration' },
      { label: 'Documents', key: 'documents' },
      { label: 'Classifications', key: 'classifications' },
      { label: 'Business Functions', key: 'businessFunctions' },
      { label: 'Subject Types', key: 'dataSubjectTypes' },
      { label: 'Data Role', key: 'dataRole' },
      { label: 'Legal Bases', key: 'legalBases' },
      { label: 'Data Transfers From', key: 'dataTransfersFrom' },
      { label: 'Data Transfers To', key: 'dataTransfersTo' },
    ],
    isLoading,
    hasNextPage,
  }
}
