import React, { useContext, useEffect, useState } from 'react'
import { FormikProvider } from 'formik'

import { RoutesManager } from 'utils/routing/routesManager'
import { FormFieldDetailViewContext, withFormDetailViewContext } from './context/FormFieldDetailViewContext'
import { FormFieldViewActions, FormFieldViewDetails } from './components'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Spinner, ViewLayout } from '@ketch-com/deck'
import { Box } from '@mui/material'

type Props = {}

const FormFieldDetailViewWithoutContext: React.FC<Props> = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const { formField, breadcrumbs, formikProps, isReady, tabs } = useContext(FormFieldDetailViewContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    navigate(tabs[newValue].link)
  }

  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (pathname.includes(tab.link.pathname)) {
        setCurrentTab(index)
      }
    })
  }, [tabs, currentTab, pathname])

  if (!isReady) {
    return (
      <>
        <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )
  }

  return (
    <FormikProvider value={formikProps}>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      <ViewLayout
        contentGroupProps={{
          title: formField?.name || formField?.title,
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.deployment.forms.fields.root.getURL()),
          },

          actionRightBlockComponent: <FormFieldViewActions formField={formField} />,
          infoBlockComponent: <FormFieldViewDetails formField={formField} />,
        }}
        tabsComponentProps={{ value: currentTab, onChange: handleTabChange, tabItems: tabs }}
      >
        <Outlet />
      </ViewLayout>
    </FormikProvider>
  )
}

export const FormFieldDetailView = withFormDetailViewContext(FormFieldDetailViewWithoutContext)
