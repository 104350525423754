export enum ActionsExecutionStatusEnum {
  ALL = 1,
  MIXED = 2,
}

export const ACTIONS_EXECUTION_STATUS_MAP: {
  [key: number]: string
} = {
  0: 'unspecified',
  [ActionsExecutionStatusEnum.ALL]: 'All',
  [ActionsExecutionStatusEnum.MIXED]: 'Mixed',
}
