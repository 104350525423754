import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { CreateSubscriptionTopicRequestBodyDTO, CreateSubscriptionTopicResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  data: CreateSubscriptionTopicRequestBodyDTO
}>

export const createSubscriptionTopic = ({ data }: Params) => {
  return API.post<CreateSubscriptionTopicResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/subscriptions/topics`,
    }),
    data,
  )
}
