import React from 'react'
import clsx from 'clsx'
import { Box, Popover } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'

import { Button } from '@ketch-com/deck'
import { Text } from 'components/ui-kit/typography/Text'
import { AssetDTO } from '@ketch-com/figurehead'
import { useHandleViewResourceOnClick } from 'pages/assetManager/assets/list/hooks'
import { PopoverTitleValuePair } from '.'
import { AssetTypeAccessorEnum } from 'interfaces/assets/AssetTypeEnum'

const useStyles = makeStyles(
  ({ spacing, typography, palette, shadows }) =>
    createStyles({
      foreignKeyBadge: {
        backgroundColor: palette.fadedGrey.main,
        borderRadius: spacing(4),
        padding: spacing(0.75, 1.75),
        cursor: 'pointer',
        '&[should-highlight="true"]': {
          outline: `1px solid ${palette.fadedDarkGrey.main}`,
        },
      },
      withMarginBottom: {
        marginBottom: spacing(1),
      },
      infoIcon: {
        color: palette.lightGrey.main,
        fontSize: typography.pxToRem(12),
        marginLeft: spacing(0.5),
      },
      showInAssetManagerButton: {
        borderColor: palette.white.main,
        color: palette.white.main,
        background: palette.darkDusk.main,
        fontWeight: 600,
        fontSize: typography.pxToRem(12),
      },
    }),
  { name: 'RelationshipsCreatedTab' },
)

type Props = {
  foreignKeyLength: number
  handlePointerOnEnterEvent: any
  handlePointerOnLeave: any
  index: number
  foreignKey: AssetDTO
  relationshipId: string
}

export const ForeignKeyPill: React.FC<Props> = ({
  foreignKeyLength,
  handlePointerOnEnterEvent,
  handlePointerOnLeave,
  index,
  foreignKey,
  relationshipId,
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<any>(null)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.target)
  const handleClose = () => setAnchorEl(null)

  const isPopoverOpen = Boolean(anchorEl)
  const popoverId = isPopoverOpen ? `${foreignKey?.resource?.name}-popover-id` : undefined

  const handleViewOnClick = useHandleViewResourceOnClick(foreignKey)

  return (
    <Box
      display="flex"
      alignItems="center"
      className={clsx({
        [classes.withMarginBottom]: index !== foreignKeyLength - 1,
      })}
    >
      <Box
        alignItems="center"
        className={clsx(classes.foreignKeyBadge)}
        display="flex"
        id={`${relationshipId}-foreign-key-${index}`}
        onClick={handleClick}
        onMouseEnter={handlePointerOnEnterEvent}
        onMouseLeave={handlePointerOnLeave}
      >
        {foreignKey?.resource?.name}
      </Box>

      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            minWidth: '250px',
            p: 1.25,
            borderRadius: '5px',
            backgroundColor: 'darkDusk.main',
            color: 'white.main',
          },
        }}
      >
        <PopoverTitleValuePair
          title="DATA TYPE"
          value={foreignKey?.dataCategory?.[0]?.value}
          marginBottom={3}
          titleLetterSpacing="0.13em"
        />

        <Box mb={0.75}>
          <Text weight={700} size={10} color="whiteFaded" letterSpacing="0.13em">
            LOCATION
          </Text>
        </Box>

        {foreignKey?.resource?.hierarchies?.[AssetTypeAccessorEnum.CANONICAL_RESOURCE_TYPE_DATABASE] && (
          <PopoverTitleValuePair
            title="Database"
            value={foreignKey?.resource?.hierarchies?.[AssetTypeAccessorEnum.CANONICAL_RESOURCE_TYPE_DATABASE]?.name}
            marginBottom={3}
          />
        )}

        {foreignKey?.resource?.hierarchies?.[AssetTypeAccessorEnum.CANONICAL_RESOURCE_TYPE_DATASET] && (
          <PopoverTitleValuePair
            title="Dataset"
            value={foreignKey?.resource?.hierarchies?.[AssetTypeAccessorEnum.CANONICAL_RESOURCE_TYPE_DATASET]?.name}
            marginBottom={3}
          />
        )}

        <Box>
          <Button onClick={handleViewOnClick} className={classes.showInAssetManagerButton} color="secondary">
            Show in Assets
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}
