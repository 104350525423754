import { FC, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Typography } from '@mui/material'
import { Button, PopUp } from '@ketch-com/deck'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useDeleteApplication } from 'api/applications/mutations/useDeleteApplication'
import { ApplicationDTO } from 'interfaces/applications/application'
import { showToast } from 'components/modals/AlertComponent'

type Props = {
  application: ApplicationDTO
  onClose: () => void
  onDelete?: () => void
}

export const ApplicationDeleteModal: FC<Props> = ({ application, onClose, onDelete = onClose }) => {
  const queryClient = useQueryClient()

  const { name, code } = application
  const [isBusy, setIsBusy] = useState(false)

  const { mutate: handleDeleteApplication } = useDeleteApplication({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onDelete?.()
      showToast({ content: 'Property deleted', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to delete property', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <PopUp
      isOpen
      isLoading={isBusy}
      onClose={onClose}
      variant="dialog"
      title="Delete Property"
      popUpWidth="560px"
      popUpActionChildren={
        <>
          <Button
            color="secondary"
            size="large"
            onClick={e => {
              e.stopPropagation()
              onClose()
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={e => {
              e.stopPropagation()
              handleDeleteApplication({
                params: {
                  applicationCode: code!,
                },
              })
            }}
          >
            Delete
          </Button>
        </>
      }
    >
      <Typography>
        Are you sure you want to delete <strong>{name || code}</strong> application?
      </Typography>
    </PopUp>
  )
}
