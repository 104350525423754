import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { MaybeNull } from 'interfaces'
import { excludeFalsy } from 'utils/helpers/excludeFalsy'

export const getMappings = ({
  identitySpaces,
  identitySpace,
}: {
  identitySpaces: IdentitySpaceDTO[]
  identitySpace: MaybeNull<IdentitySpaceDTO>
}) => {
  const { sourceIdentitySpaceMaps = [], destinationIdentitySpaceMaps = [] } = identitySpace || {}

  const receivesFromCodes = (sourceIdentitySpaceMaps || []).map(
    ({ destinationIdentitySpaceCode }) => destinationIdentitySpaceCode,
  )

  const pushesToCodes = (destinationIdentitySpaceMaps || []).map(
    ({ sourceIdentitySpaceCode }) => sourceIdentitySpaceCode,
  )

  const codes = [...receivesFromCodes, ...pushesToCodes]
  let receivesFrom: IdentitySpaceDTO[] = []
  let pushesTo: IdentitySpaceDTO[] = []

  if (codes.length) {
    receivesFrom = receivesFromCodes
      .map(receivesFromCode => identitySpaces.find(({ code }) => code === receivesFromCode))
      .filter(excludeFalsy)

    pushesTo = pushesToCodes
      .map(pushesToCode => identitySpaces.find(({ code }) => code === pushesToCode))
      .filter(excludeFalsy)
  }

  return {
    receivesFrom,
    pushesTo,
  }
}
