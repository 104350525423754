import { Box, styled } from '@mui/material'
import { SubscriptionsHeader } from './components/SubscriptionsHeader'
import { SubscriptionsUnsubscribeAll } from './components/SubscriptionsUnsubscribeAll'
import { SubscriptionsList } from './components/SubscriptionsList'
import { SubscriptionsFooter } from './components/SubscriptionsFooter'
import { SelectableSection } from '../../../../SelectableSection'
import { PreferenceExperienceBuilderSection, PreferenceThemeBuilderSection } from '../../../../../utils/enums'
import { useContext } from 'react'
import { BuilderContext } from '../../../../../Builder'
import { getIn } from 'formik'
import { UnsubscribeFromAllPosition } from '@ketch-sdk/ketch-types'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'

const SubscriptionsTabBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
})

export const SubscriptionsTab: React.FC = () => {
  const { isTheme, experienceConfig } = useContext(BuilderContext)

  // Theme config
  const unsubscribeFromAllPosition = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllPosition,
  )
  const isBottomUnsubscribeAll = unsubscribeFromAllPosition === UnsubscribeFromAllPosition.Bottom

  return (
    <SubscriptionsTabBox>
      <Box mb={'112px'}>
        {/* Header section (theme only) */}
        <SelectableSection
          section={isTheme ? PreferenceThemeBuilderSection.SubscriptionsTabHeader : undefined}
          overlayProps={{ margin: '8px 2px 2px 2px' }}
        >
          <SubscriptionsHeader />
        </SelectableSection>

        {/* Unsubscribe all - Top position */}
        {!isBottomUnsubscribeAll && <SubscriptionsUnsubscribeAll />}

        {/* List section */}
        <SelectableSection
          section={
            isTheme
              ? PreferenceThemeBuilderSection.SubscriptionsTabList
              : PreferenceExperienceBuilderSection.SubscriptionsTabList
          }
        >
          <SubscriptionsList />
        </SelectableSection>

        {/* Unsubscribe all - Bottom position */}
        {isBottomUnsubscribeAll && <SubscriptionsUnsubscribeAll />}
      </Box>

      {/* Footer section (theme only) */}
      <SelectableSection section={isTheme ? PreferenceThemeBuilderSection.SubscriptionsTabFooter : undefined}>
        <SubscriptionsFooter />
      </SelectableSection>
    </SubscriptionsTabBox>
  )
}
