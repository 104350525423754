import { find, isUndefined } from 'lodash'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'

interface IGetTransitionsFromStep {
  step?: ICanvasStep
  steps: Array<ICanvasStep>
}

export const getTransitionsFromStep = (params: IGetTransitionsFromStep): Array<ICanvasStep> => {
  const { step, steps } = params

  if (!step) {
    return []
  }

  if (!isUndefined(step?.start)) {
    const nextStep = find(steps, { ID: step.start?.transition })

    return nextStep ? [nextStep] : []
  }

  if (!isUndefined(step?.activity)) {
    const nextStep = find(steps, { ID: step.activity?.transition })

    return nextStep ? [nextStep] : []
  }

  if (!isUndefined(step?.workflow)) {
    const nextStep = find(steps, { ID: step.workflow?.transition })

    return nextStep ? [nextStep] : []
  }

  if (!isUndefined(step?.placeholder)) {
    const nextStep = find(steps, { ID: step.placeholder?.transition })

    return nextStep ? [nextStep] : []
  }

  if (!isUndefined(step?.gateway)) {
    const nextSteps: Array<ICanvasStep> = []

    step.gateway.transitions
      ?.filter(s => !isUndefined(s))
      ?.forEach?.(({ ID }) => {
        const found = find(steps, { ID })

        if (ID && found) {
          return nextSteps.push(found)
        }

        nextSteps.push({
          ID: undefined,
          valid: true,
          name: '',
        })
      })

    return nextSteps
  }

  return []
}
