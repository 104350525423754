import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { subjectTypeCodeAvailable } from 'api/dataSubjectTypes/fetchers/subjectTypeCodeAvailable'

const useCustomMutation = createUseMutation({
  mutationFn: subjectTypeCodeAvailable,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useSubjectTypeCodeAvailable = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
