import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { ASSET_TYPE_NAME_TO_ENUM } from 'interfaces/assets/AssetTypeEnum'
import { PolicyFormValues } from 'interfaces/policies/PolicyFormValues'
import { getMapEffectStringToEffectUrl } from 'pages/assetManager/utils'

export const mapValuesToPayload = ({ values }: { values: PolicyFormValues }): AssetPolicyDTO => ({
  purpose: values.purpose?.id,
  name: values.name,
  description: values?.description || '',
  assets: (values?.accessAllowances || [])?.map(el => {
    if (el.criteria === 'named') {
      return {
        type: ASSET_TYPE_NAME_TO_ENUM[el.assetType],
        named: el.labelName?.named || '',
        labeled: {},
      }
    } else {
      return {
        type: ASSET_TYPE_NAME_TO_ENUM[el.assetType],
        named: '',
        labeled: { [el?.labelName?.code]: el?.labelName?.value || '' },
      }
    }
  }),
  effects: (values?.attributeEffects || [])?.map(el => {
    if (el.criteria === 'named') {
      return {
        effect: getMapEffectStringToEffectUrl(el.effect),
        named: el.labelName?.named || '',
        labeled: {},
      }
    } else {
      return {
        effect: getMapEffectStringToEffectUrl(el.effect),
        named: '',
        labeled: { [el.labelName.code]: el.labelName.value },
      }
    }
  }),
  rowFilters: (values?.filters || [])?.map(el => ({
    condition: el.condition,
    attributeName: el.name,
    value: el.value,
  })),
})
