import { useContext, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { countriesWithCodes, CountryWithCodeType } from './utils'
import { Checkbox, Icon, MegaFilterContext, TextInput } from '@ketch-com/deck'

export const CheckboxSection = ({
  sectionKey,
  isControlledComponent,
  handleControlledUpdate,
  handleControlledDelete,
}: {
  sectionKey: string
  isControlledComponent?: boolean
  handleControlledUpdate?: (option: CountryWithCodeType) => void
  handleControlledDelete?: (option: CountryWithCodeType) => void
}) => {
  const context = useContext(MegaFilterContext)

  const { handleAddSelectedValue, handleDeleteValue, getSelectedValuesArrayByCategory } = context

  const currentValues = getSelectedValuesArrayByCategory(sectionKey)
  const isChecked = (option: CountryWithCodeType) =>
    !!(currentValues && currentValues.find(selectedValue => selectedValue.value === option.value))

  const [searchText, setSearchText] = useState('')
  const renderedCountries = countriesWithCodes.filter(country =>
    country.value.toLowerCase().includes(searchText.toLowerCase()),
  )

  const handleCheckboxClick = (option: CountryWithCodeType) => {
    // if we are using MegaFilter as a controlled component, we have to update the data ourselves,
    // here we are doing that by using these props
    if (isControlledComponent) {
      if (!isChecked(option)) {
        handleControlledUpdate && handleControlledUpdate(option)
      } else {
        handleControlledDelete && handleControlledDelete(option)
      }
    } else {
      // if MegaFilter is not controlled, the data is updated inside the MegaFilter Context
      // the context exports a few methods for usual use cases, like adding a newly selected value,
      // unselecting a value (or deleting one from the selected data), etc.
      if (!isChecked(option)) {
        handleAddSelectedValue({
          value: option.value,
          category: sectionKey,
          secondaryLabel: option.code,
        })
      } else {
        handleDeleteValue({
          value: option.value,
          category: sectionKey,
          secondaryLabel: option.code,
        })
      }
    }
  }

  return (
    <Box display="flex" flexWrap="wrap" flexDirection="column" alignItems="center" width="100%">
      <Box padding="0 12px" width="100%">
        <TextInput
          sx={{ mb: 1 }}
          value={searchText}
          startIcon={<Icon name="OMag" />}
          size="small"
          fullWidth
          placeholder="Search..."
          onChange={event => setSearchText(event.target.value)}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        sx={{ bgcolor: 'background.paper', maxHeight: 200, overflow: 'scroll', width: '100%' }}
      >
        {renderedCountries.length ? (
          renderedCountries.map(value => (
            <Box key={value.code} padding="0px 3px" display="flex" alignItems="center" py={0.5}>
              <Checkbox
                checked={isChecked(value)}
                onClick={() => {
                  handleCheckboxClick(value)
                }}
              />
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                <Typography>{value.value}</Typography>
                <Typography variant="footnote">Code: {value.code}</Typography>
              </Box>
            </Box>
          ))
        ) : (
          <Box height={34} padding="0 12px">
            <Typography color="darkDuskFaded.main">No results found</Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}
