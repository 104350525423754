import { IDParams } from './utils'

export const skus = {
  root: {
    pattern: '/skus',
    getURL: () => `/skus`,
  },

  skus: {
    root: {
      pattern: '/skus',
      getURL: () => `/skus/skus`,
    },

    view: {
      root: {
        pattern: '/skus/view/:id',
        getURL: ({ id }: IDParams) => `/skus/skus/view/${id}`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ id }: IDParams) => `/skus/skus/view/${id}/overview`,
        },
      },

      parentSkus: {
        root: {
          pattern: '/parent-skus',
          getURL: ({ id }: IDParams) => `/skus/skus/view/${id}/parent-skus`,
        },
      },

      childSkus: {
        root: {
          pattern: '/child-skus',
          getURL: ({ id }: IDParams) => `/skus/skus/view/${id}/child-skus`,
        },
      },

      permissionSets: {
        root: {
          pattern: '/permission-sets',
          getURL: ({ id }: IDParams) => `/skus/skus/view/${id}/permission-sets`,
        },
      },
    },

    upsert: {
      root: {
        pattern: '/skus/edit/:id',
        getURL: ({ id }: IDParams) => `/skus/skus/edit/${id}`,
      },
    },
  },
}
