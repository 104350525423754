import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { MaybeNull } from 'interfaces/common'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { RoutesManager } from 'utils/routing/routesManager'
import { relativePath } from 'utils/routing/relativePath'
import { CookieViewDetails } from 'pages/policyCenter/cookies/view/components/CookieViewDetails'
import { CookieViewActions } from 'pages/policyCenter/cookies/view/components/CookieViewActions'
import { Banner, Button, Spinner, ViewLayout } from '@ketch-com/deck'
import { CookieVersionsMessage } from './components/CookieVersionsMessage'
import { getBannerText } from 'components/ui-layouts/issuesMessage/IssuesMessage'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { CookieViewRoutes } from './CookieViewRoutes'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'
import { DeleteCookieModal } from 'components/modals/cookies/DeleteCookieModal'
import { Box } from '@mui/material'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isReady: boolean
  cookie: MaybeNull<CookieDTO>
  versions: CookieDTO[]
}

export const CookieView: React.FC<Props> = ({ isReady, cookie, versions }) => {
  const navigate = useNavigate()

  const location = useLocation()
  const isInSensitiveStatus = !!cookie?.status
  const isLatest = !versions.length || cookie?.latest // TODO :: !versions.length - for old cookies.
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)

  const tabs = useMemo(
    () => [
      {
        label: 'Overview',
        link: relativePath(RoutesManager.policyCenter.cookies.view.overview.root.pattern),
        search: location.search,
      },
      {
        label: 'Properties',
        link: relativePath(RoutesManager.policyCenter.cookies.view.applications.root.pattern),
        search: location.search,
      },
      {
        label: 'Versions',
        link: relativePath(RoutesManager.policyCenter.cookies.view.versions.root.pattern),
        search: location.search,
      },
    ],
    [location.search],
  )

  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (location.pathname.includes(tab.link)) {
        setCurrentTab(index)
      }
    })
  }, [location.pathname, tabs])

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.cookies.root.getURL() },
    { title: 'Cookies', link: RoutesManager.policyCenter.cookies.root.getURL() },
    { title: cookie?.name || cookie?.code },
  ]

  const banners = (
    <>
      {cookie?.hasIssues && (
        <Banner
          sx={{
            width: '100%',
          }}
          title={getBannerText(ApplicationEntity.COOKIE)}
          action={
            <Button
              variant="link"
              onClick={() => {
                navigate(RoutesManager.policyCenter.cookies.upsert.root.getURL({ code: cookie?.code }))
              }}
            >
              Update
            </Button>
          }
          severity="error"
        />
      )}
      {!isLatest && cookie && <CookieVersionsMessage cookie={cookie} />}
    </>
  )

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs} />
      {!isReady || !cookie ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '50px 0',
            backgroundColor: 'white.main',
            borderRadius: '11px',
            width: '1280px',
            flex: 1,
          }}
        >
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gap="24px" alignItems="center" flex="1">
          {banners}
          <ViewLayout
            contentGroupProps={{
              title: cookie?.name || cookie?.code || '',
              showBackButton: true,
              propsBackButton: {
                onClick: () => navigate(RoutesManager.policyCenter.cookies.root.getURL()),
              },
              infoBlockComponent: <CookieViewDetails cookie={cookie} />,
              actionRightBlockComponent: (
                <CookieViewActions
                  cookie={cookie}
                  isLatestVersion={!!isLatest}
                  isInSensitiveStatus={isInSensitiveStatus}
                  onRemoveClick={() => setIsRemoveModalOpen(true)}
                  onEditClick={() => {
                    setIsEditModalOpen(true)
                  }}
                />
              ),
            }}
            tabsComponentProps={{
              tabItems: tabs,
              value: currentTab,
              onChange: (_, value) => {
                navigate(tabs[value].link)
              },
            }}
          >
            <>
              <CookieViewRoutes cookie={cookie} versions={versions} />
              {isEditModalOpen && (
                <EditConfirmationModal
                  entityType={ApplicationEntity.COOKIE}
                  onSubmit={() => {
                    navigate(RoutesManager.policyCenter.cookies.upsert.root.getURL({ code: cookie?.code }))
                  }}
                  onCancel={() => setIsEditModalOpen(false)}
                />
              )}

              {isRemoveModalOpen && (
                <DeleteCookieModal
                  isOpen={isRemoveModalOpen}
                  code={cookie.code}
                  onSubmit={() => navigate(RoutesManager.policyCenter.cookies.root.getURL())}
                  onCancel={() => setIsRemoveModalOpen(false)}
                />
              )}
            </>
          </ViewLayout>
        </Box>
      )}
    </>
  )
}
