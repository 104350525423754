import Box from '@mui/material/Box'
import { SearchChipList } from './SearchChipList'
import React, { ReactNode, useState } from 'react'
import { isFunction, isUndefined } from 'lodash'
import { Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system/styleFunctionSx'
import { SwitchToggle, theme } from '@ketch-com/deck'

export type SearchOption = {
  /** Unique id value of the option */
  id: string
  /** Display string for the option */
  label: string
  /** Optional description for the option */
  description?: string
}

type Props = {
  /** Initial value for the enable switch */
  value?: boolean
  /** Whether the switch is enabled initially */
  isEnabled?: boolean
  /** Options for the search field */
  options: SearchOption[]
  /** Selected options for the search field */
  selectedOptions?: string[]
  /** Title for the search field */
  listTitle?: ReactNode
  /** String to display within the search field */
  inputPrompt?: string
  /** Boolean indicating if there is an error */
  showError?: boolean
  /** Error message, displayed below the search field */
  errorMsg?: string
  /** Component to render beside the enable switch */
  enableTitle: (enabled: boolean) => ReactNode
  /** Function to call when an item from the search field is clicked */
  onSelect?: (selectedId: string) => void
  /** Function to call when an item from the chip list is deleted */
  onDeselect?: (deselectedId: string) => void
  /** Function to call when the enable switch is toggled */
  onEnable?: (checked: boolean) => void
  /** Styles for the search field */
  inputSx?: SystemStyleObject<Theme>
  /** Styles for the container */
  containerSx?: (palette: any, enabled: boolean) => SystemStyleObject<Theme>
  /** Boolean indicating if the switch should always be on - i.e. disabled:on state */
  alwaysOn?: boolean
}

/**
 * A two-state component that displays text when disabled, and an additional
 * search chip list when enabled.
 */
export const OptionalSearchChipList: React.FC<Props> = ({
  value,
  isEnabled,
  options,
  selectedOptions,
  listTitle,
  inputPrompt,
  showError,
  errorMsg,
  enableTitle,
  onSelect,
  onDeselect,
  onEnable,
  inputSx,
  containerSx,
  alwaysOn,
}) => {
  const [enabled, setEnabled] = useState(isEnabled || alwaysOn || false)

  const handleEnableChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (isFunction(onEnable)) {
      onEnable(checked)
    }
    setEnabled(checked)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="36px"
      bgcolor={enabled ? theme.palette.ironLight.main : ''}
      sx={palette => (containerSx ? containerSx(palette, enabled) : {})}
    >
      {/* Enable/disable and title */}
      <Box display="flex" flexDirection="row" gap="34px" p="0px" alignItems="center">
        {alwaysOn ? (
          <SwitchToggle checked={true} onChange={() => {}} size="medium" />
        ) : (
          <SwitchToggle checked={isUndefined(value) ? enabled : value} onChange={handleEnableChange} size="medium" />
        )}
        {enableTitle(enabled)}
      </Box>

      {enabled && (
        <Box p="0px 14px 0px 14px">
          <SearchChipList
            title={listTitle}
            inputPrompt={inputPrompt}
            options={options}
            selectedOptions={selectedOptions}
            onSelect={onSelect}
            onDeselect={onDeselect}
            inputSx={inputSx}
            showError={showError}
            errorMsg={errorMsg}
          />
        </Box>
      )}
    </Box>
  )
}
