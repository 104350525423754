import { useContext } from 'react'

// components
import Box from '@mui/material/Box'

// utils
import { RightInvocationWorkflowStepDetailViewContext } from '../../../../context/RightInvocationWorkflowStepDetailViewContext'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

// types
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { StepDetailsDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { SectionDescription } from 'pages/consentAndRights/forms/templates/components'
import { ContentGroup, InfoRow } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { InfoRowAssignUser } from '../../../InfoRowAssignUser'
import { RightsQueueFormInfoRowTextField } from '../../../FormInfoRowTextField'
import { InfoRowInlineUpload } from '../../../InfoRowInlineUpload'

type Props = {
  resolutionAttachments?: FileInfoDTO[] | undefined
  stepDetails: StepDetailsDTO | null
  isAssignedToMe: boolean
  showRequiredFields?: boolean
}

export const EmailDetails: React.FC<Props> = ({
  resolutionAttachments,
  stepDetails,
  isAssignedToMe,
  showRequiredFields,
}) => {
  // provider
  const { rightInvocation, stepId, workflowExecutionId, workflowStep, canEdit } = useContext(
    RightInvocationWorkflowStepDetailViewContext,
  )

  // hooks
  const { isPermitted } = useIsPermitted()

  // component utils
  const canReassign = isPermitted(PERMISSIONS.WORKFLOW_TASK_ASSIGN)
  const { assigneeInfo, executionData, assignee } = stepDetails as StepDetailsDTO
  const { hasResolutionNotes, hasResolutionAttachments } = executionData?.manualTask || {}
  const isNotComplete = workflowStep?.status !== WorkflowExecutionStepStatus.SUCCESS
  const supportsAppRetry = !!executionData?.app?.supportsAppRetry

  const stepError = supportsAppRetry
    ? executionData?.app?.lastError || ''
    : (stepDetails as unknown as WorkflowExecutionStepDetailsDTO)?.results?.[stepId as string]?.error || ''

  const showNotesRequired =
    showRequiredFields && hasResolutionNotes && !stepDetails?.manualInterventionData?.resolutionDetails

  const showAttachmentsRequired = showRequiredFields && hasResolutionAttachments && !resolutionAttachments?.length

  return (
    <Box display="flex" flexDirection="column" gap={6} mb={6}>
      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup variant="inner-page-header" titleVariant="h3" title="Error" />
        <InfoRow title="Error Details">{stepError ? stepError : <EmptyValueRenderer />}</InfoRow>
      </Box>
      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup variant="inner-page-header" titleVariant="h3" title="Details" />

        {/* Assignees */}

        <InfoRowAssignUser
          infoRowTitle="Assignee"
          assigneeInfo={assigneeInfo || null}
          assignee={assignee}
          canEdit={canReassign && isNotComplete}
          isComplete={!isNotComplete}
          workflowExecutionId={workflowExecutionId}
          workflowStepId={stepId}
        />
      </Box>

      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup variant="inner-page-header" titleVariant="h3" title="Email Details" />

        <InfoRow title="To">{executionData?.email?.to ? executionData?.email?.to : <EmptyValueRenderer />}</InfoRow>

        <InfoRow title="CC">{executionData?.email?.cc ? executionData?.email?.cc : <EmptyValueRenderer />}</InfoRow>

        <InfoRow title="Subject">
          {executionData?.email?.subject ? executionData?.email?.subject : <EmptyValueRenderer />}
        </InfoRow>

        <InfoRow title="Body">
          {executionData?.email?.body ? (
            <SectionDescription description={executionData?.email?.body} />
          ) : (
            <EmptyValueRenderer />
          )}
        </InfoRow>
      </Box>
      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup
          variant="inner-page-header"
          titleVariant="h3"
          title="Manual Intervention"
          subTitle="Optional space to provide Manual Intervention details."
        />

        <RightsQueueFormInfoRowTextField
          infoRowTitle="Notes"
          canEdit={canEdit}
          name="resolutionDetails"
          placeholder="Add Notes"
          defaultValue={stepDetails?.manualInterventionData?.resolutionDetails || ''}
          showRequiredLabel={showNotesRequired}
          isRequired={hasResolutionNotes}
          showValidation={showNotesRequired}
        />
        <InfoRowInlineUpload
          attachments={resolutionAttachments}
          canEdit={canEdit}
          disableInEditDownload
          infoRowTitle="Attachments"
          isRequired={hasResolutionAttachments}
          name="resolutionAttachments"
          showFileData={true}
          showRequiredLabel={showAttachmentsRequired}
          showUploadSizes
          uploadContext={{
            version: 'resolution_attachments',
            folder: `${rightInvocation?.id}_${stepId}`,
            bucket: '',
          }}
        />
      </Box>
    </Box>
  )
}
