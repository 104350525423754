import { useField } from 'formik'
import { FileWithPreview } from '../interfaces'
import { theme } from '@ketch-com/deck'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { AttachmentChipV2 } from 'components/ui-kit/chip/AttachmentChipV2'

export function DropzonePreview({ name }: { name: string }) {
  const [field, , helpers] = useField<FileWithPreview[]>(name)

  return (
    <>
      {field.value.length > 0 && (
        <>
          <Box display="flex" alignItems="center" gap={3} flexWrap="wrap">
            {field.value.map(file => {
              return (
                <AttachmentChipV2
                  size="medium"
                  iconColor={theme.palette.doomedGrey.main}
                  deleteIconName="OCross"
                  file={file}
                  key={file.name}
                  title={file.name}
                  label={<Typography>{file.name}</Typography>}
                  onDelete={() => helpers.setValue(field.value.filter(f => f.name !== file.name))}
                />
              )
            })}
          </Box>

          {/* NOTE: Below is hard-coded to false because it's a poof of concept for image upload previews.  Designs have not yet called for this.
            When/if this does become a requirement the below will need to be styled by design. */}
          {false && (
            <Box display="flex" alignItems="center" gap={3} flexWrap="wrap" mb={2}>
              {field.value.map(file => {
                return (
                  <Box key={file.name}>
                    <img
                      src={file.preview}
                      alt={file.name}
                      style={{
                        width: '100px',
                        height: '100px',
                      }}
                    />
                    <p>{file.name}</p>
                  </Box>
                )
              })}
            </Box>
          )}
        </>
      )}
    </>
  )
}
