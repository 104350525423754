import moment from 'moment'

type DateRange = {
  startDate?: string
  endDate?: string
}

export function getDatesInRange(dateRange: DateRange): string[] {
  const { startDate, endDate } = dateRange

  let dateArray: string[] = []
  let currentDate: moment.Moment = moment(startDate)
  const stopDate: moment.Moment = moment(endDate)

  while (currentDate < stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
    currentDate = moment(currentDate).add(1, 'days')
  }

  return dateArray
}
