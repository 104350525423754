import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchDmlHook } from '../fetchers/fetchDmlHook'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.dmlHook,
  queryFn: fetchDmlHook,
  select: res => res?.data?.hook || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useDmlHook = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
