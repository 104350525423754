import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  code: string
  formData: DataSubjectTypeDTO
}>

type Response = { dataSubjectType: DataSubjectTypeDTO }

export const updateDataSubjectType = ({ formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/data-subject-types/${formData?.code}`,
    }),
    { dataSubjectType: formData },
  )
