import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { ApplianceDTO, TransponderFormValues } from 'interfaces/appliances'

type Params = APIRequestParams<{
  applianceId: string
  formData: TransponderFormValues
}>

type Response = { appliance: ApplianceDTO }

export const updateTransponder = ({ applianceId, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/appliances/${applianceId}`,
    }),
    formData,
  )
