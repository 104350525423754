import { Icon, TextInput } from '@ketch-com/deck'
import { SxProps, Theme } from '@mui/material'

type SearchTextInputType = {
  value: string
  onChange: (s: string) => void
  size?: 'medium' | 'small'
  sx?: SxProps<Theme> | undefined
  fullWidth?: boolean
  showClearButton?: boolean
  placeholder?: string
  minWidth?: number | string
  onClear?: () => void
  variant?: 'ghost' | 'outlined'
}

export const SearchTextInput = ({
  value,
  onChange,
  size = 'small',
  sx,
  fullWidth = false,
  showClearButton = false,
  onClear,
  placeholder = 'Search...',
  variant = 'outlined',
  minWidth = '300',
}: SearchTextInputType) => {
  return (
    <TextInput
      sx={{
        minWidth,
        ...sx,
      }}
      fullWidth={fullWidth}
      variant={variant}
      value={value}
      startIcon={<Icon name="OMag" />}
      endIcon={
        showClearButton &&
        value && (
          <Icon
            sx={{ cursor: 'pointer' }}
            name="OCross"
            onClick={() => {
              onChange('')
              onClear?.()
            }}
          />
        )
      }
      size={size}
      placeholder={placeholder}
      onChange={event => onChange(event.target.value)}
    />
  )
}
