import React, { useState } from 'react'
import { isNull } from 'lodash'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useDeletePolicyScope } from 'api/policyScopes/mutations/useDeletePolicyScope'
import { Button, PopUp } from '@ketch-com/deck'
import { showToast } from 'components/modals/AlertComponent'
import { Typography } from '@mui/material'

type Props = {
  code: string
  onSubmit?: () => void
  onCancel: () => void
}

export const DeletePolicyScopeModal: React.FC<Props> = ({ code, onSubmit, onCancel }) => {
  const queryClient = useQueryClient()
  const [isPending, setIsPending] = useState(false)

  const { mutateAsync: handleDeletePolicyScope } = useDeletePolicyScope()

  const handleSubmit = async () => {
    try {
      setIsPending(true)

      await handleDeletePolicyScope({
        params: {
          code,
        },
      })

      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.()

      showToast({ content: 'Jurisdiction deleted!', type: 'success' })
    } catch (e) {
      showToast({ content: 'Failed to delete jurisdiction!', type: 'error' })

      setIsPending(false)
    }
  }

  return (
    <PopUp
      variant="dialog"
      title="Delete Jurisdiction"
      isLoading={isNull(code)}
      onClose={() => onCancel()}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel} pending={isPending}>
            Close
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={() => {
              handleSubmit()
            }}
            pending={isPending}
          >
            Delete
          </Button>
        </>
      }
    >
      <Typography>
        Are you sure you want to delete <Typography variant="label">{code}</Typography> jurisdiction?
      </Typography>
    </PopUp>
  )
}
