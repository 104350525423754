import { useState, useEffect, useContext, useRef } from 'react'
import Konva from 'konva'
import { isUndefined } from 'lodash'

import { MaybeNull } from 'interfaces'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { ICreateStepParams } from 'pages/orchestration/workflows/edit/utils/steps/createStep'
import { IReplaceStepParams } from 'pages/orchestration/workflows/edit/utils/steps/replaceStep'
import { IChangeActiveStep } from 'pages/orchestration/workflows/edit/contexts/CanvasContext'
import { useCanvas } from 'pages/orchestration/workflows/edit/hooks/useCanvas'
import { useSidebar } from 'pages/orchestration/workflows/edit/hooks/useSidebar'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import * as CanvasConstants from 'pages/orchestration/workflows/edit/utils/constants'
import { getIsDecisionBooleanAllowed } from '../utils'
import { useSearchParams } from 'react-router-dom'
import { useContextVariablesPaginated } from 'api/contextVariables/queries/useContextVariablesPaginated'
import { EditWorkflowContext } from '../../../contexts/EditWorkflowContext'
import { getAbsoluteCoordinates } from '../../../utils/positioning/absoluteCoordinates'
import { useCanvasNavigation } from '../../../hooks'
import { EditWorkflowContainerContext } from '../../EditWorkflowFormContainer/context/EditWorkflowContainerContext'
import { IPasteStepsParams } from '../../../utils/steps/pasteSteps'
import { CopyPasteType } from 'pages/orchestration/workflows/edit/utils/constants'

export const useWorkflowCanvasUtils = () => {
  const {
    values,
    handleCreatePlaceholder: handleCreateWorkflowPlaceholder,
    handleCreateStep: handleCreateWorkflowStep,
    handleRemoveStep: handleRemoveWorkflowStep,
    handleReplaceStep: handleReplaceWorkflowStep,
    handlePasteSteps: handlePasteWorkflowSteps,
    handleUpdateStep: handleUpdateWorkflowStep,
    handleUndo,
    handleRedo,
    canUndo,
    canRedo,
  } = useContext(EditWorkflowContainerContext)

  const [searchParams, setSearchParams] = useSearchParams()
  const [activeConnectedNestStartStep, setActiveConnectedNestStartStep] = useState<ICanvasStep>()
  const [activeDragStep, setActiveDragStep] = useState<MaybeNull<ICanvasStep>>(null)
  const [dropCoordinates, setDropCoordinates] = useState({})
  const [warningModalMessage, setWarningModalMessage] = useState('')
  const initialPosition = getAbsoluteCoordinates(CanvasConstants.CANVAS_START_VIEW_POSITION)
  const [hoveredCopySteps, setHoveredCopySteps] = useState<ICanvasStep[]>([])
  // Type of hovered copy/paste, needed to know how to display connection lines to
  const hoveredCopyPasteType = useRef<CopyPasteType>()

  const { data: contextVariables } = useContextVariablesPaginated({
    params: { limit: 2000 },
  })

  const { isReady, workflowActivities, activeStep, setActiveStep } = useContext(EditWorkflowContext)

  /**
   * This useEffect sets the activeStep when an "activityId" search param is present in the URL then clears the searchParams.
   */
  useEffect(() => {
    const setActiveStepFromSearchParams = async () => {
      const activityId = searchParams.get('activityId')
      if (!!activityId && isReady) {
        const step = values.steps?.find?.(step => step?.ID === activityId)
        const iconURL = Object.values(workflowActivities)
          .flat()
          .find(activity => activity?.activityCode === step?.activityCode)?.iconURL

        if (!!step) {
          setActiveStep({
            ...step,
            iconURL,
          })
          setSearchParams({})
        }
      }
    }

    setActiveStepFromSearchParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, workflowActivities])

  const contextValue = {
    activeStep,
    activeDragStep,
    dropCoordinates,
    handleCreateStep: (params: ICreateStepParams) => {
      const isAttemptingToCreateDecisionBoolean = params.stepToCreate.gateway?.code === 'decision_boolean'
      if (
        isAttemptingToCreateDecisionBoolean &&
        !getIsDecisionBooleanAllowed({
          parentStepActivityCode: params?.parentStep?.activityCode,
        })
      ) {
        showToast({
          content: `Decision Boolean cannot follow the ${params?.parentStep?.name} tile`,
          type: 'error',
        })
        return
      }

      const newStep = handleCreateWorkflowStep(params)

      const isFinish = !isUndefined(newStep?.finish)

      if (!isFinish) {
        setActiveStep(newStep)
      }
      setActiveDragStep(null)
    },
    handleUpdateStep: handleUpdateWorkflowStep,
    handleRemoveStep: handleRemoveWorkflowStep,
    handleReplaceStep: (params: IReplaceStepParams) => {
      const newStep = handleReplaceWorkflowStep(params)
      setActiveStep(newStep)
      setActiveDragStep(null)
    },
    handlePasteSteps: (params: IPasteStepsParams) => {
      const pastedSteps = handlePasteWorkflowSteps(params)
      setActiveStep(pastedSteps![0])
    },
    handleCreatePlaceholder: handleCreateWorkflowPlaceholder,
    handleChangeActiveStep: ({ step, connectedNestStartStep }: IChangeActiveStep) => {
      setActiveStep(step)
      setActiveConnectedNestStartStep(connectedNestStartStep)
    },
  }

  // Canvas Refs:
  const [stageWrapperRef, setStageWrapperRef] = useState<MaybeNull<HTMLDivElement>>(null)
  const [stageRef, setStageRef] = useState<MaybeNull<Konva.Stage>>(null)

  // Sidebar:
  const { isExpanded, sidebarWidth, handleToggleSidebar } = useSidebar()

  // Canvas:
  const {
    positionX,
    positionY,
    scale,
    canvasWidth,
    canvasHeight,
    canvasViewPortWidth,
    canvasViewPortHeight,
    updateCanvasSettings,
  } = useCanvas({
    initialX: -initialPosition.x,
    initialY: -initialPosition.y,
    initialScale: 1,
    stageWrapper: stageWrapperRef,
    isExpanded,
  })

  // Zoom and pan:
  const {
    isAtMaxZoom,
    isAtMinZoom,
    isAtInitialZoom,
    isAtInitialPosition,
    initialScale,
    handleZoomIn,
    handleZoomOut,
    handleWheel,
    handleZoom,
    handleSetInitialZoom,
    handleSetInitialPosition,
    handleDragPanning,
  } = useCanvasNavigation({
    stageRef,
    canvasWidth,
    canvasHeight,
    canvasViewPortWidth,
    canvasViewPortHeight,
    positionX,
    positionY,
    scale,
    initialScale: 1,
    onChangeZoom: params => {
      const { x, y, scale } = params

      updateCanvasSettings(prevSettings => ({
        ...prevSettings,
        positionX: x,
        positionY: y,
        scale,
      }))
    },
    onChangePosition: params => {
      const { x, y } = params

      updateCanvasSettings(prevSettings => ({
        ...prevSettings,
        positionX: x,
        positionY: y,
      }))
    },
  })

  return {
    activeStep,
    activeConnectedNestStartStep,
    canvasViewPortHeight,
    canvasViewPortWidth,
    contextValue,
    contextVariables,
    handleWheel,
    handleZoom,
    handleDragPanning,
    handleSetInitialZoom,
    handleSetInitialPosition,
    handleToggleSidebar,
    handleZoomIn,
    handleZoomOut,
    handleUndo,
    handleRedo,
    hoveredCopySteps,
    setHoveredCopySteps,
    hoveredCopyPasteType,
    canUndo,
    canRedo,
    initialScale,
    isAtMaxZoom,
    isAtMinZoom,
    isAtInitialZoom,
    isAtInitialPosition,
    isExpanded,
    positionX,
    positionY,
    scale,
    setActiveDragStep,
    setActiveStep,
    removeActiveSteps: () => {
      setActiveStep(null)
      setActiveConnectedNestStartStep(undefined)
    },
    setDropCoordinates,
    setStageRef,
    setStageWrapperRef,
    setWarningModalMessage,
    sidebarWidth,
    stageRef,
    updateCanvasSettings,
    warningModalMessage,
  }
}

export type WorkflowCanvasUtils = ReturnType<typeof useWorkflowCanvasUtils>
