import React from 'react'
import { useCookiesListFiltersUtils } from './hooks'
import { getStatusFilterDisplay, getCategoryFilterDisplay } from './utils'
import { CookieListFilterDropList } from './utils/CookieListFilterDropList'
import { Box } from '@mui/material'
import { CookieListFilterSearchInput } from './utils/CookieListFilterSearchInput'

export const CookiesListFilters: React.FC = () => {
  const utils = useCookiesListFiltersUtils()

  return (
    <Box display="inline-flex" gap="12px" alignItems="center">
      <CookieListFilterSearchInput
        value={utils.searchText}
        debounceDelay={500}
        onChange={utils.searchInputHandleOnChange}
        onClose={() => utils.searchInputHandleOnChange('')}
      />
      <CookieListFilterDropList
        placeholder="All Categories"
        selectedValue={getCategoryFilterDisplay(utils.categoryFilter) || ''}
        items={utils.categoryItems}
        onClear={utils.resetCategoryItemsFilter}
      />
      <CookieListFilterDropList
        placeholder="All Statuses"
        selectedValue={getStatusFilterDisplay(utils.statusFilter) || ''}
        items={utils.statusItems}
        onClear={utils.resetStatusItemsFilter}
      />
    </Box>
  )
}
