import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { useContext, useState } from 'react'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { useUpdateWorkflowExecution } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecution'
import { showToast } from 'components/modals/AlertComponent'
import { WorkflowActivityCode, WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { useGatewayContext } from 'api/executions/queries/useGatewayContext'

export const useDecisionGatewayUtils = () => {
  const { id, stepId } = useParams<any>()
  const [selectedPaths, setSelectedPaths] = useState<string[]>([])
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()

  const {
    workflowStep,
    isReady: isRightInvocationWorkflowStepDetailViewContextReady,
    workflowExecution,
    rightInvocation,
    isTaskComplete,
    workflowExecutionId,
    workflow,
  } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const workflowExecutionStepCode = workflowExecution?.steps?.find(step => step.stepID === workflowStep?.ID)
    ?.activityStepCode

  const isMulti = workflowExecutionStepCode === WorkflowActivityCode.DECISION_MULTI

  const gatewayCode = workflow?.steps?.find(step => step.ID === workflowStep?.ID)?.gateway?.mode
  const isDecisionGateway =
    gatewayCode === WorkflowActivityGatewayMode.DECISION_MULTI ||
    gatewayCode === WorkflowActivityGatewayMode.DECISION_SINGLE

  const isUseGatewayContextEnabled = !!workflowExecutionId && !!workflowStep && isDecisionGateway && !!stepId

  const {
    data: gatewayContext,
    isLoading: isLoadingGateway,
    error: gatewayError,
  } = useGatewayContext({
    enabled: isUseGatewayContextEnabled,
    params: { stepId, workflowExecutionId },
  })

  const title = 'Decision Gateway'

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    { title: 'Rights Queue', link: RoutesManager.orchestration.rightsQueue.root.getURL() },
    {
      title: 'Request',
      link: RoutesManager.orchestration.rightsQueue.view.root.getURL({ id }),
    },
    {
      title,
      link: RoutesManager.orchestration.rightsQueue.stepDetails.root.getURL({
        id,
        stepId,
        workflowExecutionId,
      }),
    },
  ]

  const { mutateAsync: resolveWorkflowStep } = useUpdateWorkflowExecution({
    onSuccess: async () => {
      showToast({ content: 'Decision was successfully resolved', type: 'success' })
      navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocation?.id }))
    },
    onError: () => {
      showToast({ content: 'Failed to resolve decision', type: 'error' })
    },
  })

  const resolve = async () => {
    if (!selectedPaths.length) {
      setSearchParams({ hasAttemptedToResolve: 'true' })
      return
    }

    setSearchParams({})

    await resolveWorkflowStep({
      params: {
        formData: {
          temporalRunID: `${workflowExecution?.temporalRunID}`,
          temporalWorkflowID: `${workflowExecution?.temporalWorkflowID}`,
          signalName: 'decision_gateway_resolve',
          signalValue: {
            selectedPaths,
            selectedBy: 'currentUser',
          },
        },
      },
    })
  }

  return {
    breadcrumbs,
    gatewayContext,
    gatewayError,
    id,
    isMulti,
    isReady: isRightInvocationWorkflowStepDetailViewContextReady && !isLoadingGateway,
    resolve,
    selectedPaths,
    setSelectedPaths,
    title,
    workflowStep,
    isTaskComplete,
  }
}

export type DecisionGatewayUtils = ReturnType<typeof useDecisionGatewayUtils>
