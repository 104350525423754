// API functions and types
import { ContextVariableDTO } from '@ketch-com/figurehead'
import { useCreateContextVariable } from 'api/contextVariables/mutations/useCreateContextVariable'
import { useUpdateContextVariable } from 'api/contextVariables/mutations/useUpdateContextVariable'
import { ApiQueryKeys } from 'api/common/queryKeys'

// Custom hooks and utilities
import { useQueryClient } from 'react-query'
import { useContextVariablesValidation } from 'pages/settings/contextVariables/hooks'

// UI components and styles
import { showToast } from 'components/modals/AlertComponent'
import { getContextVariablesFormInitialValues } from 'pages/settings/contextVariables/utils'

type Args = {
  contextVariable?: ContextVariableDTO
  onSubmit: (value?: string) => Promise<void>
}

export const useUpsertContextVariablesModalUtils = ({ onSubmit, contextVariable = {} }: Args) => {
  const queryClient = useQueryClient()
  const isEditMode = !!Object.keys(contextVariable).length
  const { code = '' } = contextVariable
  const initialValues = getContextVariablesFormInitialValues({ contextVariable, isEditMode })
  const validationSchema = useContextVariablesValidation({ contextVariable, isEditMode })
  const { mutate: handleCreateContextVariable, isLoading: isCreateContextVariableLoading } = useCreateContextVariable({
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.(variables?.formData?.contextVariable?.code)
      showToast({ content: 'Context variable created', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to create context variable', type: 'error' })
    },
  })

  const { mutate: handleUpdateContextVariable, isLoading: isUpdateContextVariableLoading } = useUpdateContextVariable({
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.(variables?.formData?.contextVariable?.code)
      showToast({ content: 'Context variable updated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update context variable', type: 'error' })
    },
  })

  const onFormikSubmit = async (formValues: ContextVariableDTO) => {
    if (formValues.type !== 1) {
      delete formValues.values
    }
    isEditMode
      ? handleUpdateContextVariable({
          params: {
            code,
            formData: {
              contextVariable: formValues,
            },
          },
        })
      : handleCreateContextVariable({
          params: {
            formData: {
              contextVariable: formValues,
            },
          },
        })
  }

  const isVariableInUseInWorkflows = (contextVariable?.activities?.length || 0) > 0

  return {
    initialValues,
    validationSchema,
    onFormikSubmit,
    isEditMode,
    isVariableInUseInWorkflows,
    isCreateContextVariableLoading,
    isUpdateContextVariableLoading,
  }
}
