import { UserDTO, UserFormDTO } from 'interfaces/users/users'
import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  organizationCode: string
  userId: string
  formData: UserFormDTO
}>

type Response = { user: UserDTO }

export const updateUser = ({ organizationCode, userId, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/members/${userId}`,
    }),
    formData,
  )
