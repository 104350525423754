import { useState, useEffect } from 'react'
import moment from 'moment'
import { usePermitStatsOverall } from 'api/permitStatistics/queries/usePermitsStatsOverall'
import { usePermitsStatsJurisdiction } from 'api/permitStatistics/queries/usePermitsStatsJurisdiction'
import { usePermitsStatsPerIdentitySpace } from 'api/permitStatistics/queries/usePermitsStatsPerIdentitySpace'
import { usePermitsStatsPerJurisdiction } from 'api/permitStatistics/queries/usePermitsStatsPerJurisdiction'
import { usePermitsStatsPurpose } from 'api/permitStatistics/queries/usePermitsStatsPurpose'
import { usePermitStatsTrend } from 'api/permitStatistics/queries/usePermitsStatsTrend'

import { useAppSelector } from 'store/hooks'
import { getPermitStatisticsFilterState } from 'store/permitStatisticsFilterStateSlice/selectors'

export const useIsLoadingAggregate = () => {
  const [isLoadingMaster, setIsLoadingMaster] = useState(false)
  const { identitySpace, jurisdiction, purpose, property, environment, startDate, endDate } =
    useAppSelector(getPermitStatisticsFilterState)

  const { isLoading: isLoadingPermitsStatsJurisdiction } = usePermitsStatsJurisdiction({
    enabled: !!startDate && !!endDate,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  const { isLoading: isLoadingPermitStatsOverall } = usePermitStatsOverall({
    enabled: !!startDate && !!endDate,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  const { isLoading: isLoadingPermitsStatsPerIdentitySpace } = usePermitsStatsPerIdentitySpace({
    enabled: !!startDate && !!endDate,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  const { isLoading: isLoadingPermitsStatsPerJurisdiction } = usePermitsStatsPerJurisdiction({
    enabled: !!startDate && !!endDate,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  const { isLoading: isLoadingPermitsStatsPurpose } = usePermitsStatsPurpose({
    enabled: !!startDate && !!endDate,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  const { isLoading: isLoadingPermitsStatsTrend } = usePermitStatsTrend({
    enabled: !!startDate && !!endDate,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  const isLoadingAggregate =
    isLoadingPermitStatsOverall ||
    isLoadingPermitsStatsJurisdiction ||
    isLoadingPermitsStatsPerIdentitySpace ||
    isLoadingPermitsStatsPerJurisdiction ||
    isLoadingPermitsStatsPurpose ||
    isLoadingPermitsStatsTrend

  useEffect(() => {
    if (!isLoadingAggregate) {
      setIsLoadingMaster(true)
      setTimeout(() => {
        setIsLoadingMaster(false)
      }, 1000)
    }
  }, [isLoadingAggregate])

  return isLoadingAggregate || isLoadingMaster
}
