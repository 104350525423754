import { Box, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { FormFieldTypeDTO, RightInvocationFormDataDTO, WebhooksContextSubjectDetailsDTO } from '@ketch-com/figurehead'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { useContext } from 'react'
import { RightInvocationWorkflowStepDetailViewContext } from '../../context'
import { AppealBadge } from '../../components'
import { theme } from '@ketch-com/deck'

export const SubjectDetailWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  wordBreak: 'break-all',
}))

type SubjectDetailProps = {
  label?: string
  value?: string
  isAppealValue?: boolean
}
const SubjectDetail: React.FC<SubjectDetailProps> = ({ label, value, isAppealValue }) => (
  <SubjectDetailWrapper key={value}>
    <Box display="flex" alignItems="center" flexWrap="wrap" gap={0.75} sx={{ overflowWrap: 'break-all' }}>
      <Typography component="div" variant="smallLabel" color="darkDuskFaded.main" sx={{ overflowWrap: 'break-all' }}>
        {label}
      </Typography>
      {isAppealValue && <AppealBadge />}
    </Box>
    {!!value ? <Typography sx={{ overflowWrap: 'break-all' }}>{value}</Typography> : <EmptyValueRenderer />}
  </SubjectDetailWrapper>
)

type SubjectDetailCheckboxProps = {
  field?: RightInvocationFormDataDTO
}

const SubjectDetailCheckbox: React.FC<SubjectDetailCheckboxProps> = ({ field }) => {
  const { name, values } = field as RightInvocationFormDataDTO

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="smallLabel" color="darkDuskFaded.main" sx={{ overflowWrap: 'break-word' }}>
        {name}
      </Typography>

      <Box
        component="ul"
        pl={2.5}
        my={0}
        sx={{
          listStyle: 'initial',
        }}
      >
        {!!values?.length ? (
          values?.map?.((val, index) => {
            return (
              <Box
                component="li"
                key={val}
                mb={index === values.length - 1 ? 0 : 1}
                sx={{
                  wordBreak: 'break-word',
                }}
              >
                <Typography
                  variant="body"
                  sx={{
                    wordBreak: 'break-word',
                  }}
                >
                  {field?.options?.find(option => option.value === val)?.label}
                </Typography>
              </Box>
            )
          })
        ) : (
          <EmptyValueRenderer />
        )}
      </Box>
    </Box>
  )
}

type SubjectDetailsContentProps = {
  subjectDetails: WebhooksContextSubjectDetailsDTO
}

export const SubjectDetailsContent: React.FC<SubjectDetailsContentProps> = ({ subjectDetails }) => {
  const { appealReason } = useContext(RightInvocationWorkflowStepDetailViewContext)
  const {
    addressLine1 = '',
    addressLine2 = '',
    dataSubjectTypeName = '',
    dataSubjectTypeRelationshipDetails = '',
    description = '',
    email = '',
    firstName = '',
    lastName = '',
    postalCode = '',
    phone = '',
    city = '',
    country = '',
    countryCode = '',
    stateRegionCode = '',
    formData = [],
  } = subjectDetails as WebhooksContextSubjectDetailsDTO
  const name = `${firstName} ${lastName}`

  // address string
  const addressArr = []
  addressLine1 && addressArr.push(addressLine1)
  addressLine2 && addressArr.push(addressLine2)
  const address = addressArr.join(', ')
  const countryAndCode = country || countryCode ? `${country} (${countryCode})` : ''

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      {/* Name */}
      {!!name && <SubjectDetail label="Name" value={name} />}
      {/* Address */}
      {!!address && <SubjectDetail label="Address" value={address} />}
      {/* City */}
      {!!city && <SubjectDetail label="City" value={city} />}
      {/* State/Region */}
      {!!stateRegionCode && <SubjectDetail label="State/Region" value={stateRegionCode} />}
      {/* Postal Code */}
      {!!postalCode && <SubjectDetail label="Postal Code" value={postalCode} />}
      {/* Country */}
      {!!countryAndCode && <SubjectDetail label="Country" value={countryAndCode} />}
      {/* Phone */}
      {!!phone && <SubjectDetail label="Phone" value={phone} />}
      {/* Email */}
      {!!email && <SubjectDetail label="Email" value={email} />}
      {/* Description */}
      {!!description && <SubjectDetail label="Request Details" value={description} />}
      {/* Data Subject Type */}
      {!!dataSubjectTypeName && <SubjectDetail label="Subject Type" value={dataSubjectTypeName} />}
      {/* Relationship to Company */}
      {!!dataSubjectTypeRelationshipDetails && (
        <SubjectDetail label="Relationship to Company" value={dataSubjectTypeRelationshipDetails} />
      )}
      {/* custom form fields */}
      {!!formData?.length &&
        formData?.map(field => {
          const isCheckbox = field?.type === FormFieldTypeDTO.CheckboxFormFieldType

          if (isCheckbox) {
            return <SubjectDetailCheckbox field={field} key={field.name} />
          }

          return (
            <SubjectDetail
              label={field?.name}
              value={field?.value}
              key={field.name}
              isAppealValue={field?.isAppealValue}
            />
          )
        })}

      {!!appealReason && (
        <Box
          bgcolor={theme.palette.fadedHeliotrope.main}
          borderRadius="11px"
          p="6px 10px"
          display="flex"
          flexDirection="column"
        >
          <Typography variant="smallLabel" color={theme.palette.Violet.Dark.o100} lineHeight="18px">
            Reason for Appeal
          </Typography>
          <Typography lineHeight="20px" color={theme.palette.Violet.Dark.o100}>
            {appealReason}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
