import React from 'react'
import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material'

interface ISidebarConfigsBodyProps {
  children?: React.ReactNode
  sx?: SxProps<Theme>
}

export const SidebarConfigsBody: React.FC<ISidebarConfigsBodyProps> = ({ sx, children }) => {
  return (
    <Box
      sx={{
        width: '100%',
        flexGrow: 1,
        overflowY: 'auto',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
