import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  rightInvocationId: string
}

export const softDeleteRightInvocation = ({ rightInvocationId }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/right-invocations/${rightInvocationId}`,
    }),
  )
