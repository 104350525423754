import React, { useState } from 'react'
import pluralize from 'pluralize'
import { Box, Typography, Tooltip } from '@mui/material'
import { Accordion, AccordionDetails, AccordionSummary, theme, IconDictionary, Icon, Chip } from '@ketch-com/deck'
import { RoutesManager } from 'utils/routing/routesManager'
import { AssignedTrackers } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { useNavigate } from 'react-router-dom'
import { ProvenanceType } from 'pages/policyCenter/trackers/interfaces'
import { ProvenanceVariant } from 'pages/policyCenter/trackers/components'

type Props = {
  categoryPurposeMapping: AssignedTrackers
}

export const CategoryPurposeMapping: React.FC<Props> = ({ categoryPurposeMapping }: Props) => {
  const navigate = useNavigate()
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Accordion
      key={categoryPurposeMapping?.key}
      color={theme.palette.Black.o4}
      expanded={isExpanded}
      sx={{ border: `1px solid ${theme.palette.Black.o16}` }}
    >
      <AccordionSummary
        icon={IconDictionary.OArrowCRight}
        onClick={() => {
          setIsExpanded(isExpanded => !isExpanded)
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box display="flex" alignItems="center" gap={1}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={36}
              height={36}
              borderRadius={1}
              sx={{ backgroundColor: ({ palette }) => palette.fadedGrey.main }}
            >
              <ProvenanceVariant provenance={categoryPurposeMapping?.provenance} type={ProvenanceType.Icon} />
            </Box>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="label">{categoryPurposeMapping?.key}</Typography>
              {categoryPurposeMapping?.dataSystem?.name || categoryPurposeMapping?.dataSystem?.logoURL ? (
                <Box display="flex" alignItems="center" gap={0.5}>
                  {categoryPurposeMapping?.dataSystem?.logoURL ? (
                    <img
                      src={categoryPurposeMapping?.dataSystem?.logoURL}
                      alt={categoryPurposeMapping?.dataSystem?.name}
                      width={12}
                      height={12}
                    />
                  ) : null}
                  {categoryPurposeMapping?.dataSystem?.name ? (
                    <Typography variant="footnote" color="darkDusk.main">
                      {categoryPurposeMapping?.dataSystem?.name}
                    </Typography>
                  ) : null}
                </Box>
              ) : (
                <Typography variant="footnote" color="darkDuskFaded.main">
                  Unknown System
                </Typography>
              )}
            </Box>
            {categoryPurposeMapping?.categories?.length ? (
              <Box>
                <Tooltip
                  title={
                    <Box>
                      {categoryPurposeMapping?.categories?.map((category, index) => {
                        return (
                          <Box key={index} my={1}>
                            <Typography variant="label" color="white.main">
                              {category?.name}
                            </Typography>
                          </Box>
                        )
                      })}
                    </Box>
                  }
                  placement="bottom"
                >
                  <Box>
                    <Chip
                      label={`${categoryPurposeMapping?.categories?.length} ${pluralize(
                        'Category',
                        categoryPurposeMapping?.categories?.length,
                      )}`}
                    />
                  </Box>
                </Tooltip>
              </Box>
            ) : null}
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Box display="flex" flexDirection="column" gap={1}>
              {categoryPurposeMapping?.addedPurposes?.length ? (
                <Typography variant="label" color="heliotrope.main">
                  {`${categoryPurposeMapping?.addedPurposes?.length} ${pluralize(
                    'purpose',
                    categoryPurposeMapping?.addedPurposes?.length,
                  )} assigned`}
                </Typography>
              ) : null}
              {categoryPurposeMapping?.removedPurposes?.length ? (
                <Typography variant="label" color="error.main">
                  {`${categoryPurposeMapping?.removedPurposes?.length} ${pluralize(
                    'purpose',
                    categoryPurposeMapping?.removedPurposes?.length,
                  )} removed`}
                </Typography>
              ) : null}
            </Box>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        {categoryPurposeMapping?.addedPurposes?.length ? (
          <Box mb={categoryPurposeMapping?.removedPurposes?.length ? 4 : 0}>
            <Typography variant="body">
              {`Assigned ${pluralize('Purpose', categoryPurposeMapping?.addedPurposes?.length)}`}
            </Typography>

            {categoryPurposeMapping?.addedPurposes?.map(purpose => {
              return (
                <Box key={purpose?.code} my={1}>
                  <Chip
                    label={purpose?.name}
                    clickable
                    onClick={() => {
                      navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code: purpose?.code }))
                    }}
                    deleteIcon={<Icon name={IconDictionary.OArrowCRight} />}
                    onDelete={() => {
                      navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code: purpose?.code }))
                    }}
                    selectable={false}
                  />
                </Box>
              )
            })}
          </Box>
        ) : null}

        {categoryPurposeMapping?.removedPurposes?.length ? (
          <Box>
            <Typography variant="body">
              {`Removed ${pluralize('Purpose', categoryPurposeMapping?.addedPurposes?.length)}`}
            </Typography>

            {categoryPurposeMapping?.removedPurposes?.map(purpose => {
              return (
                <Box key={purpose?.code} my={1}>
                  <Chip
                    label={purpose?.name}
                    clickable
                    onClick={() => {
                      navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code: purpose?.code }))
                    }}
                    deleteIcon={<Icon name={IconDictionary.OArrowCRight} />}
                    onDelete={() => {
                      navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code: purpose?.code }))
                    }}
                    selectable={false}
                  />
                </Box>
              )
            })}
          </Box>
        ) : null}
      </AccordionDetails>
    </Accordion>
  )
}
