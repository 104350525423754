import { Box, Typography } from '@mui/material'
import { formatLastUpdatedDate } from 'utils/helpers/dateTime'

type Props = {
  lastUpdated?: number
}

export const PurposeActions: React.FC<Props> = ({ lastUpdated }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography color="darkDuskFaded.main" variant="smallBody">
        Last Updated&nbsp;
      </Typography>
      <Typography color="darkDusk.main" variant="smallBody">
        {formatLastUpdatedDate(lastUpdated)}
      </Typography>
    </Box>
  )
}
