import { APIListRequestParams } from 'api/common/utils'
import { FindRelationshipsResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type FetchRelationshipsPaginatedV3Params = APIListRequestParams<{
  nextToken?: number
  type?: number
}>

export const fetchRelationshipsPaginatedV3 = ({ nextToken, limit, type }: FetchRelationshipsPaginatedV3Params) => {
  return API.get<FindRelationshipsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v3/relationships`,
      params: {
        filters: {
          type,
          limit,
          nextToken,
        },
      },
    }),
  )
}
