import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { useLocation, useNavigate, useMatch } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { Tabs } from 'components/ui-kit/tabs/Tabs'
const PREFIX = 'ThirdPartyRiskTabs'

const classes = {
  tab: `${PREFIX}-tab`,
}

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  [`& .${classes.tab}`]: {
    marginLeft: spacing(1.5),
  },
}))

type Props = {}

export const ThirdPartyRiskTabs: React.FC<Props> = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const replaceConditional = (path: string) => path.replace('?', '')

  const privacyControlsRouteDetails = useMatch(
    replaceConditional(RoutesManager.insights.dataRisks.thirdPartyRisk.unknownTransfers.root.path),
  )
  const unknownTransfersRouteDetails = useMatch(
    replaceConditional(RoutesManager.insights.dataRisks.thirdPartyRisk.unknownTransfers.root.path),
  )
  const undisclosedSystemsRouteDetails = useMatch(
    replaceConditional(RoutesManager.insights.dataRisks.thirdPartyRisk.undisclosedSystems.root.path),
  )
  const code =
    privacyControlsRouteDetails?.params?.code ||
    unknownTransfersRouteDetails?.params?.code ||
    undisclosedSystemsRouteDetails?.params?.code

  return (
    <StyledBox mb={1.5} sx={({ palette }) => ({ borderBottom: `1px solid ${palette.iron.main}` })}>
      <Tabs
        tabClassName={classes.tab}
        config={[
          {
            key: 1,
            title: 'Privacy Controls',
            onClick: () => navigate(RoutesManager.insights.dataRisks.thirdPartyRisk.privacyControls.root.getURL({})),
            active:
              location.pathname ===
                RoutesManager.insights.dataRisks.thirdPartyRisk.privacyControls.root.getURL({ code }) ||
              location.pathname === RoutesManager.insights.dataRisks.thirdPartyRisk.root.getURL(),
          },
          {
            key: 2,
            title: 'Unknown Transfers',
            onClick: () => navigate(RoutesManager.insights.dataRisks.thirdPartyRisk.unknownTransfers.root.getURL({})),
            active:
              location.pathname ===
              RoutesManager.insights.dataRisks.thirdPartyRisk.unknownTransfers.root.getURL({ code }),
          },
          {
            key: 3,
            title: 'Undisclosed Systems',
            onClick: () => navigate(RoutesManager.insights.dataRisks.thirdPartyRisk.undisclosedSystems.root.getURL({})),
            active:
              location.pathname ===
              RoutesManager.insights.dataRisks.thirdPartyRisk.undisclosedSystems.root.getURL({ code }),
          },
        ]}
      />
    </StyledBox>
  )
}
