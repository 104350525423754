import { flatMap } from 'lodash'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useQueries } from 'react-query'

import { fetchAssetParallelV2 } from '../fetchers/fetchAssetParallelV2'
import { useAuth } from 'utils/hooks/useAuth'
import { AssetDTO } from '@ketch-com/figurehead'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

export const useAssetsParallelV2 = (assetCodeArray: string[]) => {
  const {
    userData: { organizationCode },
  } = useAuth()

  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )

  const queries = assetCodeArray.map(assetCode => ({
    queryKey: [ApiQueryKeys.resource, { assetCode, organizationCode }],
    queryFn: () => fetchAssetParallelV2({ assetCode, isV2FeatureFlagEnabled }),
  }))

  const results = useQueries(queries as any).map((r: any) => {
    return {
      assetsParallel: r.data || [],
      isLoading: r?.isLoading || r?.isFetching || false,
    }
  })

  const assetsParallel = flatMap(results, result => result.assetsParallel?.data?.assetSummary?.asset as AssetDTO[])

  const payload = { assetsParallel, isLoading: results.some(r => r?.isLoading) }

  return payload
}
