import React from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { ApiKeyDTO } from 'interfaces/apiKeys'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { Pagination } from 'api/common/paginatedQuery'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ContentGroup, DataGrid, EmptyState, TooltipButton } from '@ketch-com/deck'
import { GridRowParams } from '@mui/x-data-grid'
import { getApiKeyListColumns } from './utils/apiKeyListUtils'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isLoading: boolean
  isFetching: boolean
  apiKeys: ApiKeyDTO[]
  pagination?: Pagination
}

export const ApiKeyList: React.FC<Props> = ({ isLoading, isFetching, /* pagination,  */ apiKeys }) => {
  const navigate = useNavigate()

  const { isEntitled } = useIsEntitled()
  const isEntitledToCreateApiKeys = isEntitled(ENTITLEMENTS.QTY_API_KEY)

  const { isPermitted } = useIsPermitted()
  const isPermittedToCreateApiKeys = isPermitted([PERMISSIONS.API_KEY_WRITE, PERMISSIONS.ORG_ADMIN])

  const canCreateApiKeys = isEntitledToCreateApiKeys && isPermittedToCreateApiKeys

  const handleCreateApiClick = () => {
    navigate(RoutesManager.developers.apiKeys.upsert.getURL({ id: NEW_ITEM_ID }))
  }

  const columns = getApiKeyListColumns()

  const breadcrumbs = [
    { title: 'Developers', link: RoutesManager.developers.root.getURL() },
    { title: 'API Keys', link: RoutesManager.developers.apiKeys.root.getURL() },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box display="flex" flexDirection="column" gap={3}>
        <ContentGroup
          isDivider
          title="API Keys"
          actionRightBlockComponent={
            isPermittedToCreateApiKeys ? (
              <TooltipButton
                disabled={!isEntitledToCreateApiKeys}
                title={
                  !isEntitledToCreateApiKeys
                    ? 'No user entitlements remaining, please delete one or contact your admin for more'
                    : undefined
                }
                onClick={handleCreateApiClick}
              >
                Create Key
              </TooltipButton>
            ) : undefined
          }
        />
        {!isFetching && !apiKeys.length ? (
          <EmptyState
            title="There are no API Keys for this organization"
            subTitle={canCreateApiKeys ? 'Create your first one to populate the table.' : undefined}
            primaryButtonTitle="Create Key"
            primaryButtonTooltipTitle={
              canCreateApiKeys
                ? undefined
                : 'You do not have sufficient permissions to perform this action, please contact your admin.'
            }
            primaryButtonProps={{
              size: 'large',
              disabled: !canCreateApiKeys,

              onClick: () => handleCreateApiClick(),
            }}
            iconName="OProfile"
          />
        ) : (
          <Box flexDirection="column" display="flex">
            <DataGrid
              sx={{
                cursor: 'pointer',
                '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                  backgroundImage: 'unset',
                  cursor: 'auto',
                },
                '&.MuiDataGrid-root': {
                  '& .MuiDataGrid-overlayWrapper': {
                    height: !apiKeys.length ? '300px' : 'auto',
                  },
                },
              }}
              autosizeOnMount
              autosizeOptions={{
                expand: true,
                includeHeaders: true,
                includeOutliers: false,
              }}
              getRowHeight={() => 'auto'}
              columns={columns}
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              disableRowSelectionOnClick
              getRowId={row => row.id}
              rows={apiKeys}
              loading={isLoading}
              hideFooter
              noRowsOverlayProps={{
                buttonTitle: '',
                subTitle: '',
              }}
              onRowClick={({ row: apiKey }: GridRowParams<ApiKeyDTO>) => {
                navigate(RoutesManager.developers.apiKeys.detail.getURL({ id: apiKey.id }))
              }}
            />
          </Box>
        )}
      </Box>
    </>
  )
}
