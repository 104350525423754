import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { RightDTO } from 'interfaces/rights/right'
import { APIListRequestParams, APIListResponse } from 'api/common/utils'

// TODO MP :: Fix canonical endpoint

type Params = APIListRequestParams<{ includeRights?: boolean }>

type Response = APIListResponse<{ canonicalRights: RightDTO[] }>

export const fetchCanonicalRights = ({ includeMetadata, start, limit, includeRights }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: '/api/canonical-rights',
      params: {
        filters: {
          includeMetadata,
          start,
          limit,
          includeRights,
        },
      },
    }),
  )
