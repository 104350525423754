import React, { useContext, useEffect, useRef } from 'react'
import { Box, styled } from '@mui/material'
import { ReleaseSelector } from 'components/releases/ReleaseSelector'
import { HeaderNotifications } from './components/headerNotifications/HeaderNotifications'
import { HeaderDevelopersLink } from './components/HeaderDevelopersLink'
import { HeaderSettingsLink } from './components/HeaderSettingsLink'
import { HeaderUserInfo } from './components/headerUserInfo/HeaderUserInfo'
import { HeaderSidebarControlButton } from './components/HeaderSidebarControlButton'
import { ReactComponent as HeaderKetchLogo } from 'assets/icons/navigation/nav_logo.svg'
import { NavigationBreadCrumbsPortal } from '../../breadcrumbs/NavigationBreadCrumbsPortal'
import { HeaderCreateButton } from './components/headerCreateButton/HeaderCreateButton'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { AppNavigationContext } from '../../context/AppNavigationContext'
import { HeaderReferAFriendLink } from './components/HeaderReferAFriendLink'

const HeaderOuterBox = styled(Box)(({ theme }) => ({
  height: '44px',
  minHeight: '44px',
  background: theme.palette.Black.o16,
}))

const HeaderInnerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '43px',
  background: 'white',
}))

const HeaderLeftBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'visible',
}))

const HeaderRightBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  paddingRight: '10px',
}))

export const NavigationHeader: React.FC = () => {
  const navigate = useNavigate()
  const { showCreateOverlay, setShowCreateOverlay } = useContext(AppNavigationContext)
  // Store showCreateOverlay state in a ref so we can use it in our useEffect closure
  const stateRef = useRef(showCreateOverlay)

  useEffect(() => {
    stateRef.current = showCreateOverlay
  }, [showCreateOverlay])

  // Open the create overlay when the user presses CMD/CTRL + C
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.ctrlKey && event.key === 'k') {
        setShowCreateOverlay(!stateRef.current)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
    // Ignoring this as we don't want to set the listener multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <HeaderOuterBox id="app-header">
      <HeaderInnerBox>
        <HeaderLeftBox>
          <HeaderSidebarControlButton />
          <HeaderKetchLogo
            id="ketch-logo-button"
            onClick={() => navigate(RoutesManager.home.root.getURL())}
            style={{ position: 'relative', top: '0.5px', cursor: 'pointer' }}
          />
          <NavigationBreadCrumbsPortal.Target />
        </HeaderLeftBox>

        <HeaderRightBox>
          <ReleaseSelector />
          <HeaderCreateButton />
          <HeaderNotifications />
          <HeaderDevelopersLink />
          <HeaderSettingsLink />
          <HeaderUserInfo />
          <HeaderReferAFriendLink />
        </HeaderRightBox>
      </HeaderInnerBox>
    </HeaderOuterBox>
  )
}
