import { Box } from '@mui/material'
import React from 'react'
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, LabelList } from 'recharts'
import { Text } from 'components/ui-kit/typography/Text'
import { theme } from '@ketch-com/deck'
import { Label } from './Label'
import { ChartTooltip } from './ChartTooltip'
import { PersonIcon } from './PersonIcon'
import { AverageIcon } from './AverageIcon'

type Props = { riskScore: number; name: string }

// Create some idempotent pretty numbers that look reasonable based on the risk score
// Also avoids overlapping the edge of the box, TODO: work with misha to handle overlap/edge cases
const getXYVarianceForDemo = (riskScore: number, name: string) => {
  const x = riskScore < 85 ? riskScore : 85
  const y = riskScore + 10 < 95 ? riskScore + 10 : 95
  return { x, y, name }
}

export const OverAccessChart: React.FC<Props> = ({ riskScore, name = '' }) => {
  const data = [getXYVarianceForDemo(riskScore, name), { x: 61, y: 40, name: 'Average' }]

  return (
    <Box width={240} height={240} position="relative">
      <Text
        size={11}
        lineHeight="15px"
        component="div"
        color="grey"
        sx={{ top: 16, left: 16, position: 'absolute', zIndex: 2 }}
      >
        100% <br /> Access
      </Text>
      <Text
        size={11}
        lineHeight="15px"
        component="div"
        color="grey"
        sx={{ bottom: 16, right: 16, position: 'absolute', zIndex: 2, textAlign: 'right' }}
      >
        100% <br /> Usage
      </Text>
      <Text
        size={11}
        lineHeight="15px"
        component="div"
        color="grey"
        sx={{ bottom: 16, left: 16, position: 'absolute', zIndex: 2 }}
      >
        0
      </Text>
      <ScatterChart width={240} height={240}>
        <CartesianGrid stroke={theme.palette.bone.main} fill={theme.palette.white.main} />

        <XAxis type="number" dataKey="x" axisLine={false} ticks={[0, 25, 50, 75, 100]} tickLine={false} hide={true} />
        <YAxis type="number" dataKey="y" axisLine={false} ticks={[0, 25, 50, 75, 100]} tickLine={false} hide={true} />

        <Tooltip
          cursor={false}
          content={props => {
            const subPayload = props?.payload?.[0]?.payload
            return (
              <ChartTooltip
                active={props.active || false}
                x={subPayload?.x || 0}
                y={subPayload?.y || 0}
                name={subPayload?.name || 'Average'}
              />
            )
          }}
        />

        <Scatter data={[data[0]]} shape={<PersonIcon />}>
          <LabelList dataKey="name" position="bottom" content={<Label />} />
        </Scatter>
        <Scatter data={[data[1]]} shape={<AverageIcon />}>
          <LabelList dataKey="name" position="bottom" content={<Label />} />
        </Scatter>
      </ScatterChart>
    </Box>
  )
}
