import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  start?: number
  canonicalResourceType?: number | null | undefined
  resourceTypeCode?: string | null | undefined
  query?: string | null | undefined
  hookId?: string | null | undefined
  isV2FeatureFlagEnabled?: boolean
}>

type Response = APIListResponse<{ assets: AssetSummaryDTO[]; totalResults?: number }>

export const fetchAssetsPaginatedV2 = ({
  start,
  limit,
  canonicalResourceType,
  resourceTypeCode,
  query,
  hookId,
  isV2FeatureFlagEnabled,
}: Params) => {
  let entityUrl = `/api/v3/assets`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/assets`

  return API.get<Response>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          start,
          limit,
          ...(!isV2FeatureFlagEnabled &&
            canonicalResourceType && {
              canonicalResourceType,
            }),
          ...(resourceTypeCode && {
            resourceTypeCode,
          }),
          ...(query && {
            query,
          }),
          ...(hookId && {
            hookID: hookId,
          }),
        },
      },
    }),
  )
}
