import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { GridRowParams } from '@mui/x-data-grid'
import { Pagination } from 'api/common/paginatedQuery'
import { FindSubscriptionControlsResponseBodyDTO, SubscriptionControlDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces/common'
import { RoutesManager } from 'utils/routing/routesManager'
import { DataGrid } from '@ketch-com/deck'
import { subscriptionControlColumns } from './subscriptionControlsUtils'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isLoading: boolean
  subscriptionControls: MaybeNull<FindSubscriptionControlsResponseBodyDTO>
  pagination: Pagination
}

export const SubscriptionControls: React.FC<Props> = ({ isLoading, subscriptionControls, pagination }) => {
  const navigate = useNavigate()
  const controls = subscriptionControls?.controls || []

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Experiences', link: RoutesManager.deployment.experiencesV2.root.getURL() },
    { title: 'Subscriptions', link: RoutesManager.policyCenter.subscriptions.root.getURL() },
    { title: 'Global Control', link: RoutesManager.policyCenter.subscriptions.list.subscriptionControls.root.getURL() },
  ]

  const columns = subscriptionControlColumns(controls[0]?.code || '')
  const totalPages = pagination.totalPages
  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }
  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <DataGrid
        sx={{
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
          '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
            backgroundImage: 'unset',
          },
          height: 'auto',
          border: 'unset',
        }}
        autosizeOnMount
        autosizeOptions={{
          includeHeaders: true,
          includeOutliers: false,
          columns: columns.map(column => column.field),
          expand: true,
        }}
        onRowClick={({ row }: GridRowParams<SubscriptionControlDTO>) => {
          navigate(RoutesManager.policyCenter.subscriptions.view.subscriptionControl.root.getURL({ code: row.code }))
        }}
        getRowHeight={() => 'auto'}
        columns={columns}
        disableChildrenSorting
        disableColumnMenu
        disableColumnPinning
        disableColumnResize
        disableColumnReorder
        getRowId={row => row.code}
        loading={isLoading}
        rows={controls}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={[rowsPerPage]}
        rowCount={pagination.totalResults}
        hideFooter={pagination.totalPages < 2}
      />
    </>
  )
}
