import { createContext } from 'react'
import { NotFoundTrackersUtils } from 'pages/policyCenter/trackers/interfaces'
import { useNotFoundTrackersUtils } from 'pages/policyCenter/trackers/hooks'

export const NotFoundTrackersContext = createContext({} as NotFoundTrackersUtils)

type Props = {
  children?: React.ReactNode
}

export const NotFoundTrackersContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useNotFoundTrackersUtils()

  return (
    <NotFoundTrackersContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </NotFoundTrackersContext.Provider>
  )
}

export const withNotFoundTrackersContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <NotFoundTrackersContextProvider>
        <Component {...props} />
      </NotFoundTrackersContextProvider>
    )
  }
}
