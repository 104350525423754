import * as Yup from 'yup'

export const getValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string(),
    dataCategory: Yup.string(),
    labels: Yup.array().of(
      Yup.object().shape({
        code: Yup.string().required('Required'),
        value: Yup.string(),
      }),
    ),
    hooks: Yup.array().of(Yup.object().shape({})),
  })
