import { useState } from 'react'
import DeckGL from '@deck.gl/react'
import Box from '@mui/material/Box'

import { IMapRegion } from '../../list/PolicyScopesList'
import { usePolicyScopesMapLayersMultiple } from './usePolicyScopesMapLayersMultiple'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import pluralize from 'pluralize'
import { groupBy } from 'lodash'
import Typography from '@mui/material/Typography'

interface Props {
  regions?: IMapRegion[]
  initialViewState?: any
}

export const PolicyScopesMapMultiple: React.FC<Props> = ({ regions, initialViewState }) => {
  const navigate = useNavigate()
  const [viewState, setViewState] = useState(initialViewState)
  const [hoverInfo, setHoverInfo] = useState<any>(null)
  const layers = usePolicyScopesMapLayersMultiple({ regions })

  const groupedRegions = groupBy(regions, region => region.policyScopeCode)
  const hoveredRegion = (regions || [])?.find(region => region.id === hoverInfo?.object?.id)
  const hoveredRegionPolicyScopeCode = hoveredRegion?.policyScopeCode || ''
  const hoveredRegionCount = groupedRegions?.[hoveredRegionPolicyScopeCode]?.length || 0
  const hoveredRegionPolicyScopeName = hoveredRegion?.policyScopeName || ''
  const hoverInfoName = hoverInfo?.object?.properties?.name || ''

  return (
    <DeckGL
      viewState={viewState}
      onViewStateChange={(e: any) => setViewState(e.viewState)}
      getCursor={() => 'auto'}
      controller={{
        dragPan: false,
        doubleClickZoom: false,
        scrollZoom: false,
      }}
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
      }}
      layers={layers}
      onHover={info => setHoverInfo(info)}
      onClick={info => {
        if (hoveredRegionPolicyScopeCode)
          navigate(RoutesManager.policyCenter.policyScopes.view.root.getURL({ code: hoveredRegionPolicyScopeCode }))
      }}
    >
      {hoverInfo?.object && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            pointerEvents: 'none',
            left: hoverInfo.x,
            top: hoverInfo.y,
            backgroundColor: ({ palette }) => palette.darkDusk.main,
            color: 'white.main',
            padding: ({ spacing }) => spacing(2),
            borderRadius: ({ spacing }) => spacing(0.75),
            fontSize: ({ spacing }) => spacing(1.25),
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography color="lightGrey" mb={1}>
            {hoverInfoName}
          </Typography>

          <Box display="flex" alignItems="center">
            <Typography
              variant="label"
              color="white"
              sx={{
                borderRight: ({ palette }) => `1px solid ${palette.lightGrey.main}`,
                pr: 1,
              }}
            >
              {hoveredRegionPolicyScopeName}
            </Typography>

            <Typography variant="label" color="white" sx={{ ml: 1 }}>
              {hoveredRegionCount} {pluralize('Region', hoveredRegionCount || 0)}
            </Typography>
          </Box>
        </Box>
      )}
    </DeckGL>
  )
}
