import { useState } from 'react'
import { debounce } from 'lodash'

import { useLabelsV2OrV3BasedOnFeatureFlag } from 'api/labels/queries/useLabelsV2OrV3BasedOnFeatureFlag'
import { getReducedLabelsOptions } from 'pages/assetManager/components/ManageLabelsInput/utils'
import { MegaFilterLabelOption } from 'interfaces/labels/MegaFilterLabelOption'

export const useLabelsSearchOptions = () => {
  const [queryValue, setQueryValue] = useState('')
  const [options, setOptions] = useState<MegaFilterLabelOption[]>([])

  const { isLoading: isLoadingResources, isFetching: isFetchingResources } = useLabelsV2OrV3BasedOnFeatureFlag({
    enabled: !!queryValue,
    params: {
      query: queryValue,
    },
    onSuccess: ({ data }) => {
      const reducedLabelOptions = getReducedLabelsOptions({ labelOptions: data?.labels || [] })
      setOptions(reducedLabelOptions)
    },
  })

  return {
    labelQueryValue: queryValue,
    setSearchLabelValue: debounce(searchValue => {
      setQueryValue(searchValue)
    }, 500),
    labelOptions: options,
    isLoadingLabels: isLoadingResources || isFetchingResources,
    setLabelOptions: setOptions,
    queryValue,
  }
}
