import React, { createContext } from 'react'
import { UseRightsQueueViewV2UtilsReturn, useRightsQueueViewV2Utils } from '../hooks'

export const RightsQueueViewContext = createContext({} as UseRightsQueueViewV2UtilsReturn)

type Props = {
  children?: React.ReactNode
}

export const RightsQueueViewContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useRightsQueueViewV2Utils()

  return (
    <RightsQueueViewContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </RightsQueueViewContext.Provider>
  )
}

export const withRightsQueueViewContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <RightsQueueViewContextProvider>
        <Component {...props} />
      </RightsQueueViewContextProvider>
    )
  }
}
