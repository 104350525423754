import { createContext } from 'react'
import { TrackersSettingsUtils } from 'pages/policyCenter/trackers/interfaces'
import { useTrackersSettingsUtils } from 'pages/policyCenter/trackers/hooks'

export const TrackersSettingsContext = createContext({} as TrackersSettingsUtils)

type Props = {
  children?: React.ReactNode
}

export const TrackersSettingsContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useTrackersSettingsUtils()

  return (
    <TrackersSettingsContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </TrackersSettingsContext.Provider>
  )
}

export const withTrackersSettingsContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <TrackersSettingsContextProvider>
        <Component {...props} />
      </TrackersSettingsContextProvider>
    )
  }
}
