import Box from '@mui/material/Box'
import { useContext } from 'react'
import { BuilderContext } from '../../Builder'
import { BannerHeader } from './components/BannerHeader'
import { BannerDescription } from './components/BannerDescription'
import { BannerButtons } from './components/BannerButtons'
import { BannerFooter } from './components/BannerFooter'
import { SxProps } from '@mui/material'
import { SelectableSection } from '../SelectableSection'
import { BUILDER_CONSTANTS, BannerExperienceBuilderSection, BannerThemeBuilderSection } from '../../utils/enums'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from '../../../../../../themes-v3/upsert/utils/useThemeValidationSchema'
import { BannerContainerLayout, BannerContainerPosition, BannerContainerSize } from '@ketch-sdk/ketch-types'
import { clamp } from 'lodash'

const positionWidth = {
  [BannerContainerPosition.Bottom]: '950px',
  [BannerContainerPosition.Top]: '950px',
  [BannerContainerPosition.LeftCorner]: '328px',
  [BannerContainerPosition.RightCorner]: '328px',
  [BannerContainerPosition.BottomMiddle]: '560px',
  [BannerContainerPosition.Center]: '560px',
}

export const BannerBuilder: React.FC = () => {
  const { themeConfig, isTheme } = useContext(BuilderContext)
  const layout = getIn(themeConfig, themeUpsertFormKeys.bannerContainerLayout) as BannerContainerLayout
  const position = getIn(themeConfig, themeUpsertFormKeys.bannerContainerPosition) as BannerContainerPosition
  const size = getIn(themeConfig, themeUpsertFormKeys.bannerContainerSize) as BannerContainerSize
  const isCorner = position === BannerContainerPosition.LeftCorner || position === BannerContainerPosition.RightCorner
  const containerBorderRadius = clamp(
    getIn(themeConfig, themeUpsertFormKeys.bannerContainerCornerRadius),
    BUILDER_CONSTANTS.MIN_BORER_RADIUS,
    BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
  )

  const containerSx: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    width: positionWidth[position],
    padding: size === BannerContainerSize.Standard ? '20px 20px 16px 20px' : '15px 20px 11px 20px',
    background: getIn(themeConfig, themeUpsertFormKeys.bannerContainerBackgroundColor),
    borderRadius: containerBorderRadius + 'px',
  }

  const contentSx: SxProps = {
    display: 'flex',
    flexDirection: layout === BannerContainerLayout.Horizontal && !isCorner ? 'row' : 'column',
    gap:
      layout === BannerContainerLayout.Horizontal && !isCorner
        ? 0
        : size === BannerContainerSize.Standard
        ? '20px'
        : '10px',
    justifyContent: layout === BannerContainerLayout.Centered ? 'center' : 'space-between',
    alignItems: isCorner
      ? ''
      : layout === BannerContainerLayout.Vertical || layout === BannerContainerLayout.Horizontal
      ? 'flex-start'
      : 'center',
    my: size === BannerContainerSize.Standard ? '16px' : '8px',
  }

  return (
    <SelectableSection
      section={isTheme ? BannerThemeBuilderSection.Container : undefined}
      overlayProps={{
        top: 8,
        right: 8,
        bottom: 8,
        left: 8,
      }}
      sectionBorderRadius={containerBorderRadius - 8}
    >
      {/* Container */}
      <Box sx={containerSx}>
        {/* Header*/}
        <SelectableSection
          section={isTheme ? BannerThemeBuilderSection.Header : BannerExperienceBuilderSection.Header}
          overlayProps={{ margin: '-2px -2px 6px -2px' }}
          sectionTopLeftBorderRadius={containerBorderRadius - 8} // -8 keeps ensures container and overlay border radius look the same
          sectionTopRightBorderRadius={containerBorderRadius - 8}
        >
          <BannerHeader />
        </SelectableSection>
        <Box sx={contentSx}>
          {/* Description */}
          <SelectableSection
            section={isTheme ? BannerThemeBuilderSection.Description : BannerExperienceBuilderSection.Description}
            overlayProps={{ margin: '5px 10px 5px -2px' }}
          >
            <BannerDescription />
          </SelectableSection>

          {/* Buttons */}
          <SelectableSection
            section={isTheme ? BannerThemeBuilderSection.ActionButtons : BannerExperienceBuilderSection.ActionButtons}
            overlayProps={{ margin: '5px 5px 5px 5px' }}
          >
            <BannerButtons />
          </SelectableSection>
        </Box>

        {/* Footer */}
        <SelectableSection
          section={isTheme ? BannerThemeBuilderSection.Footer : undefined} // BannerExperienceBuilderSection.Footer - TODO: GPC descoped
          overlayProps={{ margin: '5px -2px 2px -2px' }}
          sectionBottomLeftBorderRadius={containerBorderRadius - 8} // -8 keeps ensures container and overlay border radius look the same
          sectionBottomRightBorderRadius={containerBorderRadius - 8}
        >
          <BannerFooter />
        </SelectableSection>
      </Box>
    </SelectableSection>
  )
}
