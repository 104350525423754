import { APIListRequestParams } from 'api/common/utils'

// utils
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

// types
import { FindDataMapPurposesResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIListRequestParams

export const fetchPaginatedDataMapPurposes = ({ start, limit }: Params) =>
  API.get<FindDataMapPurposesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/data-map/purposes`,
      params: {
        filters: {
          start,
          limit,
        },
      },
    }),
  )
