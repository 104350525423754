import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, TableCell } from '@ketch-com/deck'
import { VerifierDTO } from '@ketch-com/figurehead'
import { VerifiersActionsCellRenderer } from '../components'
import { VERIFIER_TYPE } from '../constants'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

export const useVerifiersListColumns = (): GridColDef<VerifierDTO>[] => [
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    renderCell: ({ row: verifier }: GridRenderCellParams<VerifierDTO>) => {
      return <TableCell title={verifier.name} />
    },
  },
  {
    align: 'left',
    field: 'type',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Type',
    sortable: false,
    renderCell: ({ row: verifier }: GridRenderCellParams<VerifierDTO>) => {
      let renderedValue
      switch (verifier.type) {
        case 1:
          renderedValue = <Chip size="small" label={VERIFIER_TYPE.SHARED_SECRET} />
          break
        case 2:
          renderedValue = <Chip size="small" label={VERIFIER_TYPE.PUBLIC_KEY} />
          break
        case 3:
          renderedValue = <Chip size="small" label={VERIFIER_TYPE.JWKS_URL} />
          break
        default:
          renderedValue = <EmptyValueRenderer />
      }
      return <TableCell>{renderedValue}</TableCell>
    },
  },

  {
    align: 'right',
    field: 'dropDown',
    headerAlign: 'left',
    headerName: '',
    sortable: false,
    maxWidth: 80,
    flex: 1,
    renderCell: ({ row: verifier }: GridRenderCellParams<VerifierDTO>) => {
      return (
        <TableCell>
          <VerifiersActionsCellRenderer verifier={verifier} />
        </TableCell>
      )
    },
  },
]
