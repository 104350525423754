import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
}>

type Response = APIListResponse<{
  languages: LanguageWithStatusDTO[]
}>

export const fetchOrganizationLanguages = ({ organizationCode, includeMetadata, start, limit }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/languages`,
      params: {
        filters: {
          includeMetadata,
          start,
          limit,
        },
      },
    }),
  )
