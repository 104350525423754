import { useState } from 'react'
import { useUnapprovedTrackersInfinite } from 'api/trackers/queries/useUnapprovedTrackersInfinite'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { useUpsertUnapprovedTrackers } from 'api/trackers/mutations/useUpsertUnapprovedTrackers'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'

export const useUnapprovedTrackersUtils = () => {
  const [shouldBulkApproveAllTrackers, setShouldBulkApproveAllTrackers] = useState(false)
  const [selectedTrackers, setSelectedTrackers] = useState<GridRowSelectionModel>([])
  const [searchString, setSearchString] = useState('')
  const queryClient = useQueryClient()

  const {
    trackers: unapprovedTrackers,
    totalResults: numUnapprovedTrackers,
    isLoading: isUnapprovedTrackersLoading,
    isFetching: isFetchingUnapprovedTrackers,
    isFetchingNextPage: isFetchingNextUnapprovedTrackers,
    hasNextPage: hasNextUnapprovedTrackers,
    fetchNextPage: fetchNextUnapprovedTrackers,
  } = useUnapprovedTrackersInfinite({
    limit: 20,
    searchString,
  })

  const { isLoading: isLoadingPurposeList } = usePurposes({})

  const { mutateAsync: approveTrackers, isLoading: isApprovingTrackers } = useUpsertUnapprovedTrackers({
    onSuccess: async () => {
      setSearchString('')
      setSelectedTrackers([])
      setShouldBulkApproveAllTrackers(false)
      await queryClient.invalidateQueries([ApiQueryKeys.unapprovedTrackersInfinite])
      await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite])
      await queryClient.invalidateQueries([ApiQueryKeys.trackerStatistics])
      showToast({ content: 'Approved trackers', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to approve trackers', type: 'error' })
    },
  })

  const isReady = !isUnapprovedTrackersLoading && !isLoadingPurposeList

  return {
    isReady,
    unapprovedTrackers,
    numUnapprovedTrackers,
    isUnapprovedTrackersLoading,
    isFetchingUnapprovedTrackers,
    isFetchingNextUnapprovedTrackers,
    hasNextUnapprovedTrackers,
    fetchNextUnapprovedTrackers,
    selectedTrackers,
    setSelectedTrackers,
    approveTrackers,
    isApprovingTrackers,
    searchString,
    setSearchString,
    shouldBulkApproveAllTrackers,
    setShouldBulkApproveAllTrackers,
  }
}
