import React, { useMemo } from 'react'

import { MaybeNull } from 'interfaces'
import { ActionSheetItem, DropListButton, ListItemText } from '@ketch-com/deck'
import { AppCode } from '../utils/enums'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag, useIsPermitted } from 'utils/hooks'

type Props = {
  value?: string | null
  placeholder?: string
  pending?: boolean
  onChange: (value: MaybeNull<string>) => void
}

export const WebTagsSourceDropList: React.FC<Props> = props => {
  const { value, onChange, pending, placeholder = 'Source' } = props

  const { isPermitted: getIsPermitted } = useIsPermitted()
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isPropertyTagsRelease =
    getIsPermitted([PERMISSIONS.DEMO]) ||
    isFeatureFlagEnabled(
      FEATURE_FLAGS.PROPERTY_TAGS_TRACKER_BLOCKING,
      FEATURE_FLAGS_VALUES.PROPERTY_TAGS_TRACKER_BLOCKING_ENABLED,
    )

  const options = useMemo(
    () => [
      {
        label: 'All Sources',
        value: 'all',
      },
      {
        value: AppCode.GoogleTagManager,
        label: 'Google Tag Manager',
      },
      {
        value: AppCode.AdobeExperiencePlatform,
        label: 'Adobe Experience Platform',
      },
      ...(isPropertyTagsRelease
        ? [
            {
              value: AppCode.Property,
              label: 'Property',
            },
          ]
        : []),
    ],
    [isPropertyTagsRelease],
  )

  const currentValue = useMemo(() => {
    const _value = value || 'all'

    return options.find(elem => elem.value === _value)
  }, [value, options])

  return (
    <DropListButton
      disableClearable
      isOptionEqualToValue={(option, value) => option === value}
      renderOption={(props, option, { selected }) => {
        return (
          <ActionSheetItem selected={selected} {...props}>
            <ListItemText selected={selected}>{option.label}</ListItemText>
          </ActionSheetItem>
        )
      }}
      options={options}
      onChange={(_, value) => value && onChange(value.value === 'all' ? null : value.value)}
      value={currentValue}
      placeholder={value ? placeholder : 'All Sources'}
      size="small"
      loading={pending}
    />
  )
}
