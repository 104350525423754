import React from 'react'
import { FieldArray, useFormikContext } from 'formik'

import {
  IdentitySpaceFormValues,
  emptyIdentitySpaceMappingValue,
} from 'pages/settings/identitySpaces/upsert/utils/formValues'
import { IdentitySpaceMappingRow } from 'pages/settings/identitySpaces/upsert/components/IdentitySpaceMappingRow'
import { Button, theme } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { FormSwitch } from 'components/form/FormSwitch'
import { IdentitySpaceMappingDirection } from 'interfaces/identitySpaces/identitySpace'

type Props = {
  isEditMode: boolean
  shouldSelectFirstByDefault?: boolean
}

export const IdentitySpaceMappings: React.FC<Props> = ({ isEditMode, shouldSelectFirstByDefault = false }) => {
  const { values, setFieldValue } = useFormikContext<IdentitySpaceFormValues>()

  return (
    <Box display="flex" flexDirection="column" pb={2} pt={2} borderTop={`1px solid ${theme.palette.iron.main}`}>
      <Box display="flex" alignItems="center" pb={values.hasMappings ? 3 : 0} gap={3}>
        <FormSwitch
          formPropertyName="hasMappings"
          size="medium"
          onChange={(_, checked) => {
            if (shouldSelectFirstByDefault && checked) {
              setFieldValue(`mappings[0].direction`, IdentitySpaceMappingDirection.PUSH)
            }
          }}
        />
        <Typography variant="h4">Identity Mapping</Typography>
      </Box>
      {values.hasMappings && (
        <FieldArray name="mappings">
          {({ push, remove }) => (
            <>
              {values.mappings.map((mapping, index: number) => (
                <IdentitySpaceMappingRow
                  isEditMode={isEditMode}
                  key={index}
                  index={index}
                  remove={remove}
                  push={push}
                />
              ))}

              <Box mt={3}>
                <Button
                  color="secondary"
                  onClick={() => {
                    push(emptyIdentitySpaceMappingValue)
                  }}
                >
                  Add Row
                </Button>
              </Box>
            </>
          )}
        </FieldArray>
      )}
    </Box>
  )
}
