import { RetryExecutionStepBody, RetryExecutionStep200 } from '@ketch-com/bosun/dist/mediation_gen.schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  workflowExecutionId?: string | null | undefined
  stepId?: string | null | undefined
  payload: RetryExecutionStepBody
}

export const retryExecution = ({ workflowExecutionId, stepId, payload }: Params) =>
  API.post<RetryExecutionStep200>(
    formatRequestString({
      entityUrl: `/api/v0/workflows/executions/${workflowExecutionId}/steps/${stepId}/retry`,
    }),
    payload,
  )
