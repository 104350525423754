import { useContext, useMemo } from 'react'
import { useFilesInfoList } from 'api/files/queries/useFilesInfoList'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { WorkflowExecutionStepCompleteFormValues } from '../interfaces'
import { StepDetailsDTO } from '@ketch-com/figurehead'
// import { StepDetailsDTO } from '@ketch-com/figurehead'

export const useInitialTaskViewValues = (stepDetails?: StepDetailsDTO): WorkflowExecutionStepCompleteFormValues => {
  const { rightInvocation, stepId } = useContext(RightInvocationWorkflowStepDetailViewContext)

  // TODO:GP remove below calls once BE is updated
  // NOTE: this will require replacing all instances of FileInfoDTO with the new generated type
  const { data: resolutionAttachments } = useFilesInfoList({
    params: {
      version: 'resolution_attachments',
      folder: `${rightInvocation?.id}_${stepId}`,
    },
  })
  const { data: dsrResolutionAttachments } = useFilesInfoList({
    params: {
      version: rightInvocation?.id || '',
      folder: stepId!,
    },
  })

  const outcomeContextInitialValues = useMemo(() => {
    return stepDetails?.outcomeContext?.reduce(
      (schema: Record<string, any>, context) => {
        return {
          ...schema,
          [context.code as string]: {
            ...context,
            value: context.value || '',
          },
        }
      },
      {} as Record<string, any>,
    )
  }, [stepDetails?.outcomeContext])

  const initialValues: WorkflowExecutionStepCompleteFormValues = useMemo(() => {
    return {
      dsrResolutionAttachments,
      resolutionAttachments,
      resolutionDetails: stepDetails?.manualInterventionData?.resolutionDetails || '',
      outcomeContext: outcomeContextInitialValues,
    }
  }, [dsrResolutionAttachments, resolutionAttachments, outcomeContextInitialValues, stepDetails])

  return initialValues
}
