import React from 'react'

import { styled } from '@mui/material/styles'

import { MaybeNull } from 'interfaces'
import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { Box } from '@mui/material'
import { Badge } from 'components/ui-kit/badge/Badge'
import { Status } from 'components/ui-kit/status/Status'
import { Text } from 'components/ui-kit/typography/Text'

const PREFIX = 'PolicyViewHeaderDetails'

const classes = {
  badge: `${PREFIX}-badge`,
  status: `${PREFIX}-status`,
  codeTitle: `${PREFIX}-codeTitle`,
}

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  [`& .${classes.badge}`]: {
    marginRight: spacing(1),
  },

  [`& .${classes.status}`]: {
    marginRight: spacing(1),
  },

  [`& .${classes.codeTitle}`]: {
    letterSpacing: '0.1em',
    color: 'rgba(104, 104, 100, 1)',
    marginRight: spacing(0.5),
  },
}))

type Props = {
  policy: MaybeNull<AssetPolicyDTO>
}

export const PolicyViewHeaderDetails: React.FC<Props> = ({ policy }) => {
  return (
    <StyledBox display="flex" alignItems="center">
      <Badge className={classes.badge} variant="neutral">
        Badge
      </Badge>
      <Status className={classes.status} size="big" variant="active">
        Approved
      </Status>
      <Text className={classes.codeTitle} size={10} weight={700}>
        CODE
      </Text>
      <Text size={11} weight={500} color="grey">
        {policy?.id}
      </Text>
    </StyledBox>
  )
}
