import { styled } from '@mui/system'

import CheckIcon from '@mui/icons-material/Check'

export const CheckButton = styled(CheckIcon)(({ theme }) => ({
  backgroundColor: theme.palette.sphere.main,
  borderRadius: 5,
  color: theme.palette.white.main,
  cursor: 'pointer',
  height: 28,
  marginLeft: 5,
  padding: 2,
  width: 28,
}))
