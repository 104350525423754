import React from 'react'

import { Button } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  id?: string
  stepId?: string
  workflowExecutionId: string
}

export const WorkflowExecutionPageViewActions: React.FC<Props> = ({ id, stepId, workflowExecutionId }) => {
  const navigate = useNavigate()

  return (
    <Button
      color="tertiary"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()

        navigate(RoutesManager.orchestration.rightsQueue.stepDetails.root.getURL({ id, stepId, workflowExecutionId }))
      }}
    >
      View
    </Button>
  )
}
