import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { useNavigate, To } from 'react-router-dom'
import Box from '@mui/material/Box'
import qs from 'query-string'

import { makeStyles, createStyles } from '@mui/styles'

import { ButtonBack } from 'components/ui-kit/buttonBack/ButtonBack'
import { Text } from 'components/ui-kit/typography/Text'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      root: {
        alignItems: 'center',
        backgroundColor: palette.white.main,
        borderRadius: spacing(1.375),
        display: 'flex',
        marginBottom: spacing(1.5),
        minHeight: 84,
        padding: spacing(2.5),
      },
      buttonBack: {
        marginRight: spacing(1.5),
        height: 44,
        width: 44,
      },
      iconContainer: {
        marginRight: spacing(1.5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: 44,
      },
      title: {
        alignSelf: 'flex-start',
      },
      titleAndDetailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        minHeight: 44,
      },
      actions: {
        display: 'flex',
        alignItems: 'center',
      },
      details: {
        display: 'flex',
        alignItems: 'center',
      },
    }),
  { name: 'ViewLayoutHeaderLeftMenu' },
)

type Props = {
  title?: string
  titleComponent?: JSX.Element
  details?: string
  detailsClassName?: string
  backTo?: To
  backToScrollToTarget?: string
  actions?: ReactNode | ReactNode[]
  icon?: ReactNode | null | undefined
}

export const ViewLayoutHeaderLeftMenu: React.FC<Props> = ({
  backTo,
  title,
  titleComponent,
  actions,
  details,
  detailsClassName,
  icon = null,
  backToScrollToTarget,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <Box className={classes.root}>
      {/* Col 1 */}

      <Box display="flex" alignItems="center">
        <ButtonBack
          className={classes.buttonBack}
          onClick={() => {
            if (backTo && backToScrollToTarget) {
              navigate({
                pathname: backTo as string,
                search: qs.stringify({ targetId: backToScrollToTarget }),
              })
            } else if (backTo) {
              navigate(backTo)
            } else {
              navigate(-1)
            }
          }}
        />

        {!!icon && <Box className={classes.iconContainer}>{icon}</Box>}
      </Box>

      <Box className={classes.titleAndDetailsContainer}>
        <Text className={clsx({ [classes.title]: !!details })} size={20} lineHeight={1} weight={700} title={title}>
          {titleComponent || title}
        </Text>

        {!!details && (
          <Text size={14} lineHeight={1} title={details} className={detailsClassName}>
            {details}
          </Text>
        )}
      </Box>

      <Box className={classes.actions}>{actions}</Box>
    </Box>
  )
}
