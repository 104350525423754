import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'

import { useContext } from 'react'
import { BuilderContext } from '../../../Builder'
import { getIn } from 'formik'
import { Box, styled, Typography } from '@mui/material'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'

const ProgressiveHeaderBox = styled(Box)({
  minHeight: '36px',
  padding: '12px 16px 0px 16px',
})

export const ProgressiveHeader: React.FC = () => {
  const { experienceConfig, themeConfig } = useContext(BuilderContext)

  // Experience config
  const title = getIn(experienceConfig, experienceUpsertFormKeys.progressiveExperienceHeader)

  // Theme config - We are using the banner theme for demo
  const textColor = getIn(themeConfig, themeUpsertFormKeys.bannerHeaderTitleColor)

  return (
    <ProgressiveHeaderBox>
      <Typography fontSize={16} fontWeight={600} lineHeight="24px" color={textColor}>
        {title}
      </Typography>
    </ProgressiveHeaderBox>
  )
}
