import { useState } from 'react'

import { useDebounce } from 'react-use'
import { useExecutionsInfinite } from 'api/executions/queries/useExecutionsInfinite'
import { TabFilterType } from '../constants'
import { getStatusFilterEnum } from '../utils/getStatusFilterEnum'

type Args = {
  stepId?: string
  workflowExecutionId?: string
}

export const useTransponderDsrOverviewModalUtils = ({ stepId, workflowExecutionId }: Args) => {
  const [searchString, setSearchString] = useState('')
  const [searchStringDebounced, setSearchStringDebounced] = useState('')
  const [selectedTab, setSelectedTab] = useState('process')
  const [tabFilter, setTabFilter] = useState<TabFilterType>(TabFilterType.ALL)

  const {
    data: executions,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    fetchNextPage,
    isLoading,
  } = useExecutionsInfinite({
    params: {
      itemsPerPage: 20,
      stepId: stepId || '',
      executionId: workflowExecutionId || '',
      query: searchStringDebounced,
      status: getStatusFilterEnum(tabFilter),
    },
  })

  useDebounce(
    () => {
      if (searchString) {
        setSearchStringDebounced(searchString)
      } else {
        setSearchStringDebounced('')
      }
    },
    500,
    [searchString],
  )

  const fiveItemsFromBottomIndex = (executions?.length || 0) - 5

  return {
    executions,
    fiveItemsFromBottomIndex,
    searchString,
    setSearchString,
    selectedTab,
    setSelectedTab,
    tabFilter,
    setTabFilter,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    fetchNextPage,
  }
}
