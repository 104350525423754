import { InlineEditProps, InlineEdit } from '@ketch-com/deck'
import { debounce } from 'lodash'
import { useState, useEffect, useMemo, useCallback } from 'react'

export interface ControlledInlineEditProps extends Omit<InlineEditProps, 'onChange'> {
  onChange: (text: string) => void
}

export const ControlledInlineEdit: React.FC<ControlledInlineEditProps> = ({
  required = true,
  className,
  onChange,
  onBlur,
  value,
  ...rest
}) => {
  const [textValue, setTextValue] = useState<string>()

  useEffect(() => {
    if (textValue && value !== textValue) {
      setTextValue(value as string)
    }
    // We only want to trigger this on value change, not on textValueChange.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const debouncedOnChange = useMemo(
    () =>
      debounce((newValue: string) => {
        onChange(newValue)
        setTextValue(newValue)
      }, 500),
    [onChange],
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedOnChange(e.target.value)
  }

  const handleFocus = useCallback(() => {
    setTextValue('')
  }, [])

  return (
    <InlineEdit
      {...rest}
      required={required}
      value={textValue}
      onFocus={handleFocus}
      onChange={handleInputChange}
      onBlur={e => {
        e.preventDefault()
        onBlur?.(e)
      }}
    />
  )
}
