import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { JurisdictionRightDTO } from '@ketch-com/figurehead'
import { APIListRequestParams, APIListResponse } from 'api/common/utils'

type Response = APIListResponse<{ rights?: JurisdictionRightDTO[] }>

type Params = APIListRequestParams<{
  languageCode?: string
}>

export const fetchJurisdictionsRights = ({ includeMetadata, languageCode }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/jurisdictions/rights`,
      params: {
        filters: {
          ...(includeMetadata && { includeMetadata }),
          ...(languageCode && { languageCode }),
        },
      },
    }),
  )
