import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  cookieCode: string
  organizationCode: string
}

export const deleteCookie = ({ organizationCode, cookieCode }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/cookies/${cookieCode}`,
    }),
  )
