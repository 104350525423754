import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { updateWebhookContext } from '../fetchers/updateWebhookContext'

const useCustomMutation = createUseMutation({
  mutationFn: updateWebhookContext,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateWebhookContext = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
