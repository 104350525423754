import { ContextVariableCategoryDTO } from '@ketch-com/figurehead'

export const getVariableTypesString = (type?: ContextVariableCategoryDTO): string => {
  switch (type) {
    case ContextVariableCategoryDTO.DataSubjectContextVariableCategory:
      return 'Data Subject'
    case ContextVariableCategoryDTO.OutcomeContextVariableCategory:
      return 'Outcome'
    default:
      return ''
  }
}
