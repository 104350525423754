import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

export const useLocalization = () => {
  const { pathname } = useLocation()
  const { i18n } = useTranslation()

  // todo: DEMO CHINESE TRANSLATION CODE. REMOVE WHEN LOCALIZATION IS MORE ROBUST
  // reset language to english when navigating off a localized route
  useEffect(() => {
    if (!pathname?.endsWith('/zh')) {
      i18n.changeLanguage('en')
    }
  }, [pathname, i18n])
}
