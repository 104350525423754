import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'

const permittedParentStepActivityCodes = [WorkflowActivityCode.IDENTITY_VERIFICATION, WorkflowActivityCode.APPROVAL]

type Args = {
  parentStepActivityCode?: string
}

export const getIsDecisionBooleanAllowed = ({ parentStepActivityCode }: Args) => {
  const isAllowed = permittedParentStepActivityCodes.includes(parentStepActivityCode as WorkflowActivityCode)
  return isAllowed
}
