import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles, createStyles } from '@mui/styles'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

import { DataCategoryChip } from '../DataCategoryChip'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { getRenderTag } from './utils'
import { getRenderTags, getFilterOptions } from 'pages/assetManager/components/ManageLabelsInput/utils'
import { getUniqueIdentifier } from 'pages/assetManager/assets/view/components/AssetDetailViewRelatedAssets/components/DataCategoriesBulkAssignModal/utils'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import { Modal } from 'components/ui-kit/modal/Modal'
import { Text } from 'components/ui-kit/typography/Text'
import { useDataCategoriesEditModalUtils } from './hooks'
import SearchIcon from '@mui/icons-material/Search'

export const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      autocomplete: {
        maxWidth: 300,
      },
      modalClassName: {
        paddingTop: spacing(2),
      },
    }),
  { name: 'DataCategoriesEditModal' },
)

type Props = {
  isUpdatingAssetLabels?: boolean
  assetCode: string
  onSubmit?: (labels: ManageLabelModalLabel[]) => void
  onCancel: () => void
}

export const DataCategoriesEditModal: React.FC<Props> = ({
  onSubmit = () => {},
  assetCode,
  onCancel,
  isUpdatingAssetLabels = false,
}) => {
  const classes = useStyles()

  const {
    handleAutocompleteOnChange,
    handleLabelInputValueOnChange,
    handleRemoveTag,
    handleRemoveInferredLabel,
    isBusy,
    isLoading,
    isOptionsOpen,
    inferredLabels,
    labelOptions,
    labels,
    newLabel,
    setIsOptionsOpen,
    setNewLabel,
  } = useDataCategoriesEditModalUtils({ assetCode })

  return (
    <Modal
      isLoading={isLoading}
      isSaving={isBusy || isUpdatingAssetLabels}
      title="Classifications"
      subtitle="Search for a classification, or remove those already assigned."
      contentWidth={825}
      submitBtnLabel="Save"
      className={classes.modalClassName}
      cancelBtnLabel="Cancel"
      onSubmitBtnClick={() => {
        onSubmit([...labels, ...inferredLabels])
      }}
      onCancelBtnClick={onCancel}
    >
      {/* Autocomplete */}

      <Box
        display="flex"
        alignItems="center"
        sx={{
          my: 2.25,
          pb: 2.25,
          borderBottom: ({ palette }) => `1px solid ${palette.iron.main}`,
        }}
      >
        <Autocomplete
          id="labels"
          multiple
          fullWidth
          className={classes.autocomplete}
          disableClearable
          popupIcon={null}
          onChange={handleAutocompleteOnChange}
          open={isOptionsOpen}
          getOptionLabel={(option: ManageLabelModalLabel) => option.name}
          options={[...labelOptions]}
          /* value hardcoded to empty array so selected options do not display in the input */
          value={[]}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Escape') {
              setIsOptionsOpen(false)
              if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
            }
          }}
          onClose={() => {
            setNewLabel('')
          }}
          filterOptions={options => getFilterOptions({ options, newLabel: newLabel, labels: labels })}
          renderTags={(value: ManageLabelModalLabel[]) =>
            getRenderTags({ value, handleRemoveTag: handleRemoveTag, labels: labels })
          }
          renderOption={getRenderTag}
          renderInput={params => (
            <TextField
              {...params}
              onFocus={() => setIsOptionsOpen(true)}
              onBlur={() => setIsOptionsOpen(false)}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                ...params.inputProps,
                onChange: handleLabelInputValueOnChange,
                value: newLabel,
                placeholder: 'Classification name',
              }}
            />
          )}
        />
      </Box>

      <Box display="flex" alignItems="center" my={2}>
        <Text size={14} weight={600} mr={0.75}>
          Assigned Classifications
        </Text>

        <Text size={14} weight={600} color="darkDuskFaded">
          {labels?.length || 0}
        </Text>
      </Box>

      {/* Classifications */}

      <Box display="flex" alignItems="center" gap={2}>
        {labels?.length > 0 ? (
          labels.map(label => (
            <DataCategoryChip key={getUniqueIdentifier(label)} label={label} handleRemoveTag={handleRemoveTag} />
          ))
        ) : (
          <EmptyValueRenderer color="darkDuskFaded" />
        )}
      </Box>

      {/* Automated Classifications */}

      {!!inferredLabels?.length ? (
        <>
          <Box display="flex" alignItems="center" my={2}>
            <Text size={14} weight={600} mr={0.75}>
              Automated Classifications
            </Text>

            <Text size={14} weight={600} color="darkDuskFaded">
              {inferredLabels?.length || 0}
            </Text>
          </Box>

          <Box display="flex" alignItems="center" gap={2}>
            {inferredLabels?.length > 0 ? (
              inferredLabels.map(label => (
                <DataCategoryChip
                  key={getUniqueIdentifier(label)}
                  label={label}
                  handleRemoveTag={handleRemoveInferredLabel}
                />
              ))
            ) : (
              <EmptyValueRenderer color="darkDuskFaded" />
            )}
          </Box>
        </>
      ) : null}
    </Modal>
  )
}
