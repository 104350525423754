import { WorkflowActivityGatewayMode } from '../../../../../../interfaces/workflowActivities/workflowActivity'
import { getNextJoinTile } from './getNextJoinTile'
import { ICanvasStep } from '../../interfaces'
import { getStepsInPath } from './getStepsInPath'

type Props = {
  gateway: ICanvasStep
  steps: ICanvasStep[]
}

export const getStepsInGateway = ({ gateway, steps }: Props): ICanvasStep[] => {
  const isDecisionBoolean = gateway.gateway?.mode === WorkflowActivityGatewayMode.DECISION_BOOLEAN
  const isDecisionGatewaySingle = gateway.gateway?.mode === WorkflowActivityGatewayMode.DECISION_SINGLE

  // Initially add the gateway and join tile (if it exists)
  const nextJoin = getNextJoinTile({ step: gateway, steps })!
  const gatewaySteps: ICanvasStep[] = [gateway, ...(nextJoin ? [nextJoin] : [])]

  // Copy all paths
  gateway.gateway?.transitions.forEach(transition => {
    gatewaySteps.push(
      ...getStepsInPath({
        pathStartStepId: transition.ID?.toString() || '',
        steps,
        untilEnd: isDecisionBoolean || isDecisionGatewaySingle,
        visited: nextJoin.ID ? new Set<string>([nextJoin.ID.toString()]) : new Set<string>(),
      }),
    )
  })

  return gatewaySteps
}
