import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import { MaybeNull } from 'interfaces'
import { WorkflowDTO, WorkflowSummaryDTO } from 'interfaces/workflows'
import { EventV2DTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { WorkflowTabEvents } from 'pages/orchestration/workflows/view/tabs/WorkflowTabEvents'
import { WorkflowTabVersions } from 'pages/orchestration/workflows/view/tabs/WorkflowTabVersions'

type Props = {
  workflow: WorkflowDTO
  workflowSummary: MaybeNull<WorkflowSummaryDTO>
  events: EventV2DTO[]
  versions: WorkflowDTO[]
}

export const WorkflowViewRoutes: React.FC<Props> = ({ workflow, workflowSummary, events, versions }) => {
  const location = useLocation()

  return (
    <Routes location={location}>
      <Route
        path="/"
        element={<WorkflowTabEvents workflow={workflow} workflowSummary={workflowSummary} events={events} />}
      />
      <Route
        path={RoutesManager.orchestration.workflows.view.events.root.pattern}
        element={<WorkflowTabEvents workflow={workflow} workflowSummary={workflowSummary} events={events} />}
      />

      <Route
        path={RoutesManager.orchestration.workflows.view.versions.root.pattern}
        element={<WorkflowTabVersions workflow={workflow} versions={versions} />}
      />
    </Routes>
  )
}
