export enum HookTypeEnum {
  PRE = 1,
  POST = 2,
}

export enum AssetTypeAccessorEnum {
  PRE = 'PRE',
  POST = 'POST',
}

export const HOOK_TYPE_ENUM = {
  PRE: 1,
  POST: 2,
}

export const HOOK_TYPE_NAMES: {
  [key: number]: string
} = {
  0: 'unspecified',
  [HookTypeEnum.PRE]: 'PRE',
  [HookTypeEnum.POST]: 'POST',
}

export const HOOK_TYPE_NAME_TO_ENUM: {
  [key: string]: number
} = {
  database: HookTypeEnum.PRE,
  schema: HookTypeEnum.POST,
}

export const HOOK_TYPE_OPTIONS = [
  {
    id: HookTypeEnum.PRE,
    name: HOOK_TYPE_NAMES[HookTypeEnum.PRE],
  },
  {
    id: HookTypeEnum.POST,
    name: HOOK_TYPE_NAMES[HookTypeEnum.POST],
  },
]
