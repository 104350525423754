import React, { useContext } from 'react'
import Box from '@mui/material/Box'

import { Group } from 'components/ui-layouts/group/Group'
import { InterpolateLinks } from 'pages/consentAndRights/experiences/components/InterpolateLinks'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { PreferenceField, serialize } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { UpsertExperienceEditableOverlay } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEditableOverlay'
import { UpsertExperienceEmptyField } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEmptyField'
import { UpsertExperiencePreviewButton } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewButton'
import { UpsertExperiencePreviewPlaceholder } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewPlaceholder'
import { UpsertExperiencePreviewText } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewText'
import { useUpsertPreferenceStyles } from 'pages/consentAndRights/experiences/upsert/components/UpsertPreference/hooks'
import { useFormikContext } from 'formik'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'

type Props = {}

export const PreferencePreview: React.FC<Props> = () => {
  const classes = useUpsertPreferenceStyles()
  const { getOverlayProps } = useContext(ExperienceUpsertContext)

  const { values } = useFormikContext<PreferenceExperienceFormValues>()
  const { consents } = values

  return (
    <>
      <Box className={classes.body}>
        <Box className={classes.textWrapper}>
          <UpsertExperienceEditableOverlay
            className={classes.bodyTitle}
            {...getOverlayProps(PreferenceField.PREFERENCES_BODY_TITLE)}
          >
            {consents?.bodyTitle ? (
              <UpsertExperiencePreviewText bold size="large">
                {consents.bodyTitle}
              </UpsertExperiencePreviewText>
            ) : (
              <UpsertExperienceEmptyField size="large">Body Title</UpsertExperienceEmptyField>
            )}
          </UpsertExperienceEditableOverlay>

          <UpsertExperienceEditableOverlay
            className={classes.bodyDescription}
            {...getOverlayProps(PreferenceField.PREFERENCES_BODY_DESCRIPTION)}
          >
            {(() => {
              const bodyDescriptionText = serialize(consents.bodyDescription)

              return bodyDescriptionText ? (
                <UpsertExperiencePreviewText size="small">
                  <InterpolateLinks text={bodyDescriptionText} />
                </UpsertExperiencePreviewText>
              ) : (
                <UpsertExperienceEmptyField required size="small">
                  Body Description
                </UpsertExperienceEmptyField>
              )
            })()}
          </UpsertExperienceEditableOverlay>
        </Box>
        <UpsertExperienceEditableOverlay
          className={classes.bodyDescription}
          {...getOverlayProps(PreferenceField.PREFERENCE_PURPOSES)}
        >
          <UpsertExperiencePreviewPlaceholder className={classes.placeholder}>
            Purposes List
          </UpsertExperiencePreviewPlaceholder>
        </UpsertExperienceEditableOverlay>
      </Box>

      <Box className={classes.footer}>
        <Box className={classes.footerContent}>
          <Group gap={16}>
            <UpsertExperienceEditableOverlay {...getOverlayProps(PreferenceField.PREFERENCES_SUBMIT_BUTTON)}>
              {consents?.buttonText ? (
                <UpsertExperiencePreviewButton className={classes.acceptButton}>
                  <UpsertExperiencePreviewText size="small" color="white" bold>
                    {consents.buttonText}
                  </UpsertExperiencePreviewText>
                </UpsertExperiencePreviewButton>
              ) : (
                <UpsertExperienceEmptyField required button size="small">
                  Submit Button
                </UpsertExperienceEmptyField>
              )}
            </UpsertExperienceEditableOverlay>

            <UpsertExperiencePreviewButton className={classes.denyButton} inverted>
              <UpsertExperiencePreviewText bold size="small" color="blue">
                Exit Settings
              </UpsertExperiencePreviewText>
            </UpsertExperiencePreviewButton>
          </Group>
        </Box>
      </Box>
    </>
  )
}
