import React from 'react'
import Grid from '@mui/material/Grid'

import { BannerConfigDTO } from 'interfaces/experiences/experience'
import { EXPERIENCE_ACTION_BUTTON_DESTINATION_NAMES } from 'interfaces/experiences/experienceActionButtonDestination'
import { EXPERIENCE_BANNER_PRIMARY_BUTTON_ACTION_NAMES } from 'interfaces/experiences/experienceBannerPrimaryButtonAction'
import { InterpolateLinks } from 'pages/consentAndRights/experiences/components/InterpolateLinks'
import { Text } from 'components/ui-kit/typography/Text'
import { SubSectionTitle } from 'components/ui-kit/typography/compositions/SubSectionTitle'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'

type Props = {
  config?: BannerConfigDTO
}

export const BannerDetails: React.FC<Props> = ({ config }) => {
  const { title, footerDescription, secondaryButtonText, secondaryButtonDestination, buttonText, primaryButtonAction } =
    config || {}

  return (
    <>
      <FormRow>
        <SubSectionTitle>Main Title</SubSectionTitle>
        {title ? <Text size={14}>{title}</Text> : <EmptyValueRenderer />}
      </FormRow>

      <FormRow>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <SubSectionTitle>Description</SubSectionTitle>
            {footerDescription ? (
              <Text size={14}>
                <InterpolateLinks linkColor="light-blue" text={footerDescription} />
              </Text>
            ) : (
              <EmptyValueRenderer />
            )}
          </Grid>
        </Grid>
      </FormRow>

      <FormRow>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <SubSectionTitle>Primary Button Text</SubSectionTitle>
            {secondaryButtonText ? <Text size={14}>{secondaryButtonText}</Text> : <EmptyValueRenderer />}
          </Grid>

          <Grid item xs={3}>
            <SubSectionTitle>Primary Button Action</SubSectionTitle>
            {secondaryButtonDestination ? (
              <Text size={14}>{EXPERIENCE_ACTION_BUTTON_DESTINATION_NAMES[secondaryButtonDestination]}</Text>
            ) : (
              <EmptyValueRenderer />
            )}
          </Grid>

          <Grid item xs={3}>
            <SubSectionTitle>Secondary Button Text</SubSectionTitle>
            {buttonText ? <Text size={14}>{buttonText}</Text> : <EmptyValueRenderer />}
          </Grid>

          <Grid item xs={3}>
            <SubSectionTitle>Secondary Button Action</SubSectionTitle>
            {primaryButtonAction ? (
              <Text size={14}>{EXPERIENCE_BANNER_PRIMARY_BUTTON_ACTION_NAMES[primaryButtonAction]}</Text>
            ) : (
              <EmptyValueRenderer />
            )}
          </Grid>
        </Grid>
      </FormRow>
    </>
  )
}
