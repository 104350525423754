import { FindMapDataResponseBodyDTO } from '@ketch-com/figurehead'

import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchDataMapVisualization = () =>
  API.get<FindMapDataResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/data-map/map`,
      params: {
        filters: {},
      },
    }),
  )
