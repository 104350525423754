import React from 'react'
import { styled } from '@mui/material/styles'
import numeral from 'numeral'
import clsx from 'clsx'
import { max } from 'lodash'

// components
import {
  CircularProgress,
  CircularProgressSize,
  Status,
  StatusState,
  StatusVariant,
  LinearProgress,
  IconDictionary,
} from '@ketch-com/deck'
import { Typography, Box } from '@mui/material'

// types
import { MaybeNull } from 'interfaces'
import { RightStatsDTO } from 'interfaces/rights/rightStats'
import { useTranslation } from 'react-i18next'
import { COMMON, RIGHTS_STATISTICS } from 'i18n/types'

const PREFIX = 'RightsSummaryCurrentQueue'

const classes = {
  container: `${PREFIX}-container`,
  pendingContainer: `${PREFIX}-pendingContainer`,
  chartData: `${PREFIX}-chartData`,
  chartDescription: `${PREFIX}-chartDescription`,
  chartWrapper: `${PREFIX}-chartWrapper`,
  chartRow: `${PREFIX}-chartRow`,
  chartRowName: `${PREFIX}-chartRowName`,
  chartRowProgressContainer: `${PREFIX}-chartRowProgressContainer`,
  chartRowProgress: `${PREFIX}-chartRowProgress`,
  chartProgressLine: `${PREFIX}-chartProgressLine`,
  chartProgressCount: `${PREFIX}-chartProgressCount`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    boxSizing: 'border-box',
    background: theme.palette.white.main,
    borderRadius: 11,
    padding: 32,
    flex: 1,
    height: '100%',
  },

  [`&.${classes.pendingContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.chartData}`]: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.chartDescription}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 110,
    marginTop: 8,
  },

  [`& .${classes.chartWrapper}`]: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },

  [`& .${classes.chartRow}`]: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

  [`& .${classes.chartRowName}`]: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },

  [`& .${classes.chartRowProgressContainer}`]: {
    minWidth: 83,
    marginLeft: 12,
    height: 16,
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.chartRowProgress}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexDirection: 'column',
    borderRadius: 13,
  },

  [`& .${classes.chartProgressLine}`]: {
    width: '100%',
    height: 8,
  },
}))

type Props = {
  isReady: boolean
  rightsReport: MaybeNull<RightStatsDTO>
}

type TItem = {
  name: string
  icon: keyof typeof IconDictionary
  percent: number
  value?: number
  color?: 'primary' | 'inherit' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  status: StatusState
}

export const RightsSummaryCurrentQueue: React.FC<Props> = ({ isReady, rightsReport }) => {
  const { t } = useTranslation([RIGHTS_STATISTICS, COMMON])
  const maxValue = max([
    rightsReport?.dueOneDay,
    (rightsReport?.dueFourteenDays || 0) - (rightsReport?.dueOneDay || 0), // do not include dueToday
    rightsReport?.onTrack,
    rightsReport?.overdue,
  ])
  const data: Array<TItem> = [
    {
      name: `${t('Overdue')}`,
      icon: IconDictionary.FClock,
      percent: ((rightsReport?.overdue || 0) / (maxValue || 1)) * 100,
      value: rightsReport?.overdue,
      color: 'error',
      status: StatusState.error,
    },
    {
      name: `${t('Due Today')}`,
      icon: IconDictionary.OClock,
      percent: ((rightsReport?.dueOneDay || 0) / (maxValue || 1)) * 100,
      value: rightsReport?.dueOneDay,
      color: 'error',
      status: StatusState.error,
    },
    {
      name: `${t('Due Soon')}`,
      icon: IconDictionary.OClock,
      percent: (((rightsReport?.dueFourteenDays || 0) - (rightsReport?.dueOneDay || 0)) / (maxValue || 1)) * 100,
      value: (rightsReport?.dueFourteenDays || 0) - (rightsReport?.dueOneDay || 0), // do not include dueToday
      color: 'warning',
      status: StatusState.warning,
    },
    {
      name: `${t('On Track')}`,
      percent: ((rightsReport?.onTrack || 0) / (maxValue || 1)) * 100,
      icon: IconDictionary.FPointer,
      value: rightsReport?.onTrack,
      color: 'primary',
      status: StatusState.inProgress,
    },
  ]

  if (!isReady) {
    return (
      <Root className={clsx(classes.container, classes.pendingContainer)}>
        <CircularProgress size={CircularProgressSize.xLarge} />
      </Root>
    )
  }

  return (
    <Root className={classes.container}>
      <Typography variant="h3">{t('Current Queue')}</Typography>
      <Typography color="grey">{t('How urgent are the pending requests?')}</Typography>
      <div className={classes.chartData}>
        <Box className={classes.chartDescription} flexDirection="column">
          <Typography variant="pound" className={classes.chartRow} style={{ marginBottom: 16 }}>
            {rightsReport?.pending || 0}
          </Typography>
          <Typography variant="smallLabel">{t('Pending Requests')}</Typography>
        </Box>

        <div className={classes.chartWrapper}>
          {data.map((item, index) => (
            <div className={classes.chartRow} key={index}>
              <div className={classes.chartRowName}>
                <Status
                  label={`${numeral(item.value).format('0,0')} ${item.name}`}
                  status={item.status}
                  variant={StatusVariant.ghost}
                  icon={item.icon}
                />
              </div>
              <div className={classes.chartRowProgressContainer}>
                <LinearProgress value={item.percent} color={item.color} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Root>
  )
}
