import Editor, { loader } from '@monaco-editor/react'
import * as monaco from 'monaco-editor'
import './RawData.scss'

// utils
import { theme } from '@ketch-com/deck'

type Props = {
  language?: string | undefined
  onChange: (value: string | undefined, event: monaco.editor.IModelContentChangedEvent) => void
  onMount: (editor: monaco.editor.IEditor) => void
  value?: string | undefined
}

export const MonacoEditor = ({ language, onChange, onMount, value }: Props) => {
  const { palette } = theme

  // set default theme to one of `csv` or `json`
  const defineTheme = (monaco: any) => {
    language === 'json'
      ? monaco.editor.defineTheme('json', {
          base: 'vs',
          inherit: false,
          rules: [
            { token: 'string', foreground: palette.greenHaze.main },
            { token: 'string.key.json', foreground: palette.greenHaze.main },
            { token: 'string.value.json', foreground: palette.minsk.main },
            { token: 'punctuation.separator.continuation', foreground: palette.minsk.main },
          ],
          colors: {
            'editor.foreground': palette.minsk.main,
            'editor.background': palette.white.main,
            'editorLineNumber.activeForeground': palette.minsk.main,
            'editorLineNumber.foreground': palette.steel.main,
          },
        })
      : monaco.editor.defineTheme('plaintext', {
          base: 'vs',
          inherit: false,
          rules: [],
          colors: {
            'editor.foreground': palette.greenHaze.main,
            'editor.background': palette.white.main,
            'editorLineNumber.activeForeground': palette.minsk.main,
            'editorLineNumber.foreground': palette.steel.main,
          },
        })
  }

  // HACK: override monaco CDN with local files to prevent adding a new vendor
  // this must be initiated before the Editor component renders
  loader.config({ paths: { vs: '/monaco-editor/min/vs' } })

  return (
    <Editor
      beforeMount={defineTheme}
      defaultLanguage={language}
      onChange={onChange}
      options={{
        minimap: {
          enabled: false,
        },
      }}
      onMount={onMount}
      theme={language}
      value={value}
    />
  )
}
