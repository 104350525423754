import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { updateTrackerDataSystem } from '../fetchers/updateTrackerDataSystem'

const useCustomMutation = createUseMutation({
  mutationFn: updateTrackerDataSystem,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateTrackerDataSystem = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
