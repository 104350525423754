import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { updateProcessingActivityAssessmentRequirement } from '../fetchers/updateProcessingActivityAssessmentRequirement'

const useCustomMutation = createUseMutation({
  mutationFn: updateProcessingActivityAssessmentRequirement,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateProcessingActivityAssessmentRequirement = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation, {})
}
