import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, Status, StatusVariant, TableCell } from '@ketch-com/deck'
import { ApplianceDTO } from 'interfaces/appliances'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { AppliancesListDropdown } from '../components'
import { Box } from '@mui/material'
import { listStatusColorMapping } from '../../view/constants'
import { getStatusValue } from './getStatusValue'

export const transpondersListColumns = (hasTransponderWritePermission: boolean): GridColDef<ApplianceDTO>[] => [
  {
    align: 'left',
    field: 'approvalStatus',
    flex: 1,
    headerAlign: 'left',
    headerName: ' ',
    maxWidth: 50,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ApplianceDTO>) => {
      if (!row?.approvalStatus) {
        return (
          <TableCell>
            <Box
              sx={{
                borderRadius: '50%',
                height: 7,
                width: 7,
                display: 'inline-block',
                opacity: 1,
                backgroundColor: theme => theme.palette.chileanFire.main,
              }}
            />
          </TableCell>
        )
      }
    },
  },

  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ApplianceDTO>) => {
      return <TableCell title={row.name || row.applianceID} subTitle={row.applianceID} />
    },
  },
  {
    align: 'left',
    field: 'version',
    flex: 0.4,
    headerAlign: 'left',
    headerName: 'Version',
    sortable: false,
    renderCell: ({ row: { instances } }: GridRenderCellParams<ApplianceDTO>) => {
      const instance = instances?.[Object.keys(instances || {})?.[0]]
      const version = instance?.deployment_version || instance?.version
      return <TableCell>{version ? <Chip size="small" label={version} /> : <EmptyValueRenderer />}</TableCell>
    },
  },
  {
    align: 'left',
    field: 'status',
    headerAlign: 'left',
    headerName: 'Status',
    width: 152,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ApplianceDTO>) => {
      const statusString = getStatusValue(row.connectionStatus)

      return (
        <TableCell>
          <Status
            icon={statusString === 'Connected' ? 'OConnectionTrue' : 'OConnectionIssue'}
            variant={StatusVariant.ghost}
            status={listStatusColorMapping[statusString]}
            label={statusString}
          />
        </TableCell>
      )
    },
  },
  {
    align: 'center',
    field: 'dropDown',
    headerAlign: 'center',
    headerName: '',
    sortable: false,
    width: 64,
    renderCell: ({ row }: GridRenderCellParams<ApplianceDTO>) => {
      return <TableCell>{hasTransponderWritePermission && <AppliancesListDropdown appliance={row} />}</TableCell>
    },
  },
]
