import { FormikProps } from 'formik'

import { CookieFormDTO } from 'interfaces/cookies/cookie'

export enum CookieFormStep {
  DETAILS = 'Basic Details',
  PURPOSES = 'Purposes',
  APPLICATIONS = 'Properties',
}

export const getCookieFormSteps = () => [CookieFormStep.DETAILS, CookieFormStep.PURPOSES, CookieFormStep.APPLICATIONS]

export const isCookieFormStepValid = (step: CookieFormStep, formikProps: FormikProps<CookieFormDTO>): boolean => {
  const { errors, isValid } = formikProps

  switch (step) {
    case CookieFormStep.DETAILS:
      return (
        !errors.name &&
        !errors.code &&
        !errors.host &&
        !errors.duration &&
        !errors.serviceProvider &&
        !errors.provenance &&
        !errors.category &&
        !errors.description
      )

    case CookieFormStep.PURPOSES: {
      const isBasicStepValid = isCookieFormStepValid(CookieFormStep.DETAILS, formikProps)

      return isBasicStepValid && !errors.purposes
    }

    case CookieFormStep.APPLICATIONS: {
      return isValid
    }

    default:
      return true
  }
}
