import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { ListOrganizationFeaturesResponseBodyDTO } from '@ketch-com/figurehead'

export const fetchFeatureFlags = () =>
  API.get<ListOrganizationFeaturesResponseBodyDTO>(
    formatRequestString({
      entityUrl: '/api/features',
    }),
  )
