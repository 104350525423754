import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, TableCell } from '@ketch-com/deck'
import { AuthenticatorDTO } from '@ketch-com/figurehead'
import { AuthenticatorsActionsCellRenderer } from '../components'

// TODO:BAC - de-scoped for now
// workflows: {
//   label: 'Workflows',
//   width: 228,
//   cellRenderer: authenticator => <WorkflowsCountCellRenderer authenticator={authenticator} />,
// },

export const useAuthenticatorsListColumns = ({
  setAuthenticatorToDeleteId,
}: {
  setAuthenticatorToDeleteId: (value: React.SetStateAction<string>) => void
}): GridColDef<AuthenticatorDTO>[] => [
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<AuthenticatorDTO>) => {
      return <TableCell title={row.name} />
    },
  },
  {
    align: 'left',
    field: 'type',
    flex: 0.6,
    headerAlign: 'left',
    headerName: 'Type',
    sortable: false,
    renderCell: ({ row: { type } }: GridRenderCellParams<AuthenticatorDTO>) => {
      return (
        <TableCell>
          <Chip size="small" label={type === 1 ? 'OAuth 2.0' : 'Form Post'} />
        </TableCell>
      )
    },
  },
  {
    align: 'right',
    field: 'dropDown',
    headerAlign: 'left',
    headerName: '',
    sortable: false,
    maxWidth: 80,
    flex: 1,
    renderCell: ({ row: authenticator }: GridRenderCellParams<AuthenticatorDTO>) => {
      return (
        <TableCell>
          <AuthenticatorsActionsCellRenderer
            authenticator={authenticator}
            setAuthenticatorToDeleteId={setAuthenticatorToDeleteId}
          />
        </TableCell>
      )
    },
  },
]
