import { MaybeNull } from 'interfaces'
import { ASSET_TYPE_NAMES } from 'interfaces/assets'
import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { PolicyFormValues } from 'interfaces/policies/PolicyFormValues'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { getMapEffectUrlToString } from 'pages/assetManager/utils'
import { capitalizeFirstLetterOfString } from 'utils/text/stringUtils'

export const getInitialValues = ({
  policy,
  purposes,
}: {
  policy: MaybeNull<AssetPolicyDTO>
  purposes: MaybeNull<PurposeDTO[]>
}): PolicyFormValues => {
  if (policy) {
    return {
      name: policy?.name || '',
      description: policy?.description || '',
      accessAllowances: (policy?.assets || []).map(asset => {
        if (asset?.named) {
          return {
            assetType: ASSET_TYPE_NAMES[asset?.type || 0],
            criteria: 'named',
            labelName: { type: asset?.type || 0, named: asset.named, labeled: {} },
          }
        } else {
          /* if(asset.labeled) {... */
          return {
            assetType: ASSET_TYPE_NAMES[asset?.type || 0],
            criteria: 'labeled',
            labelName: {
              code: Object.keys(asset?.labeled || {})?.[0],
              value: Object.values(asset?.labeled || {})?.[0],
              name: capitalizeFirstLetterOfString(Object.keys(asset?.labeled || {})?.[0]),
            },
          }
        }
      }),
      attributeEffects: (policy?.effects || []).map(effect => {
        if (effect?.named) {
          return {
            criteria: 'named',
            effect: getMapEffectUrlToString(effect.effect || ''),
            labelName: { type: 99, named: effect.named, labeled: {} },
          }
        } else {
          return {
            criteria: 'labeled',
            effect: getMapEffectUrlToString(effect?.effect || ''),
            labelName: {
              code: Object.keys(effect?.labeled || {})?.[0],
              value: Object.values(effect?.labeled || {})?.[0],
              name: capitalizeFirstLetterOfString(Object.keys(effect?.labeled || {})?.[0]),
            },
          }
        }
      }),
      purpose: { id: policy?.purpose || '', name: purposes?.find(p => p.code === policy?.purpose)?.name || '' },
      filters: (policy?.rowFilters || []).map(filter => ({
        name: filter?.attributeName || '',
        condition: filter?.condition || '',
        value: filter?.value || '',
      })),
    }
  }

  return {
    name: '',
    description: '',
    accessAllowances: [{ assetType: '', criteria: '', labelName: {} }],
    attributeEffects: [],
    purpose: { id: '', name: '' },
    filters: [],
  }
}
