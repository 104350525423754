import { LanguageWithStatusDTO } from 'interfaces/languages/language'

// TODO:GP replace once BE is updated
export function filterLanguages(languages?: LanguageWithStatusDTO[]) {
  // Filter out languages that are not enabled
  let processedLanguages = languages?.filter(language => language?.enabled)

  // Check if English is already included
  const includesEnglish = processedLanguages?.some(({ language }) => language?.code === 'english')

  // If English is not included, add it as a default
  if (!includesEnglish) {
    processedLanguages?.unshift?.({
      enabled: true,
      language: {
        code: 'en',
        englishName: 'English (en)',
        nativeName: 'English',
      },
    })
  }

  return processedLanguages
}
