import React, { useState } from 'react'
import { Box } from '@mui/material'
import { DropZone } from '@ketch-com/deck'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { MAX_FILE_UPLOAD_SIZE_4_MB } from 'utils/constants/maxFileUploadSize'

interface Props {}

export const DropZoneExampleDeck: React.FC<Props> = ({ ...args }: any) => {
  const [value, setValue] = useState<FileInfoDTO[]>([])
  const [uploadSucces, setUploadSucces] = useState(false)
  const [acceptedFilesNames, setAcceptedFilesNames] = useState<string[]>([])
  const [uploadDates, setUploadDates] = useState<{ [fileName: string]: Date } | undefined>(undefined)
  const [validationError, setValidationError] = useState('')
  const [isUploading, setIsUploading] = useState(false)

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={1}>
        <DropZone
          {...args}
          value={value}
          subtitle={'subtitle'}
          maxSize={MAX_FILE_UPLOAD_SIZE_4_MB}
          title={'Title'}
          onDelete={({ attachment }) => {
            setValue(value => value.filter(i => i.name !== attachment.name))
            uploadDates && delete uploadDates[attachment.name]
            setUploadDates(uploadDates)
          }}
          handleDownloadOnClick={() => {}}
          onDropAccepted={(acceptedFiles: FileInfoDTO[]) => {
            setIsUploading(true)
            setValidationError('')
            setValue((files: FileInfoDTO[]) => [...files, ...acceptedFiles])
            const filesUploadDate = acceptedFiles.map(file => ({ [file.name]: new Date() }))
            setUploadDates(uploadDates => Object.assign({ ...uploadDates }, ...filesUploadDate))
            setAcceptedFilesNames(acceptedFiles.map(file => file.name))
            setUploadSucces(true)
            setTimeout(() => setUploadSucces(false), 2500)
            setTimeout(() => setIsUploading(false), 1000)
          }}
          onDropRejected={rejectedFiles => {
            setIsUploading(false)
            setValidationError(rejectedFiles?.[0]?.errors?.[0]?.message)
          }}
          handleDragEnter={() => {
            setIsUploading(true)
          }}
          isUploading={isUploading}
          uploadDates={uploadDates}
          uploadSucces={uploadSucces}
          acceptedFilesNames={acceptedFilesNames}
          validationError={validationError}
        />
      </Box>
    </Box>
  )
}
