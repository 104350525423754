import React from 'react'
import { SxProps, Theme } from '@mui/material'
import clsx from 'clsx'
import { InputBase } from '@mui/material'

import { makeStyles, createStyles } from '@mui/styles'

export const useInputStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseInput: {
        border: `1px solid ${palette.iron.main}`,
        borderRadius: 5,
        boxSizing: 'border-box',
        fontFamily: typography.fontFamily,
        letterSpacing: '0.01em',
        color: palette.darkDusk.main,
        background: palette.white.main,

        '& .MuiInputBase-input': {
          padding: 0,
          margin: 0,

          '&:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0px 1000px ${palette.white.main} inset`,
          },

          '&::-webkit-color-swatch-wrapper': {
            display: 'none',
          },
          '&::-moz-color-swatch': {
            display: 'none',
          },
        },

        '&.Mui-disabled': {
          border: `1px solid ${palette.iron.main} !important`,
          background: palette.fadedGrey.main,
        },

        '&:not(.Mui-disabled)': {
          '&:hover': {
            border: `1px solid ${palette.darkDuskFaded.main}`,
          },

          '&.Mui-focused': {
            border: `1px solid ${palette.sphere.main}`,
          },
        },
      },

      // Sizes:
      sizeSmall: {
        padding: '3px 14px 4px',
        fontSize: typography.pxToRem(14),
        height: 28,
      },
      sizeRegular: {
        padding: '11px 14px 12px',
        fontSize: typography.pxToRem(14),
        height: 44,
      },

      // States:
      invalid: {
        borderColor: `${palette.chileanFire.main} !important`,
      },
    }),
  { name: 'Input' },
)

export interface Props {
  /** ID of an element */
  id?: string
  /** Name of an element */
  name?: string
  /** Test ID of an element for selectors */
  testId?: string
  /** Custom element className */
  className?: string
  /** Input value (controlled) */
  value: string | number
  /** Input value change method */
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  /** onKeyPress method */
  onKeyPress?: (event: React.KeyboardEvent<HTMLImageElement>) => void
  /** Input value blur method */
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  /** Input value focus method */
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  /** Input placeholder */
  placeholder?: string
  /** Input type */
  type?: 'number' | 'password' | 'text' | 'color' | 'email'
  /** Input size */
  size?: 'regular' | 'small'
  /** Should be focused on mount */
  autofocus?: boolean
  /** Should be filled on mount */
  autocomplete?: string
  /** Valid state */
  valid?: boolean
  /** Disable state */
  disabled?: boolean
  /** Full width */
  fullWidth?: boolean
  /** end adornment icon */
  endAdornment?: React.ReactNode
  /** Prop to provide sx style overrides */
  sx?: SxProps<Theme>
  /** Minimum value for number inputs */
  min?: number
  /** Maximum value for number inputs */
  max?: number
  /** readonly prop */
  readonly?: boolean
}

/**
 * -
 */
export const Input: React.FC<Props> = ({
  id,
  name,
  testId,
  className,
  value,
  placeholder = '',
  type = 'text',
  size = 'regular',
  autofocus = false,
  autocomplete = '',
  valid = true,
  disabled = false,
  fullWidth = false,
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  endAdornment = undefined,
  readonly = false,
  sx,
  min,
  max,
}) => {
  const classes = useInputStyles()

  const inputClassName = clsx(
    classes.baseInput,
    {
      [classes.sizeSmall]: size === 'small',
      [classes.sizeRegular]: size === 'regular',
      [classes.invalid]: !valid,
    },
    className,
  )

  return (
    <InputBase
      id={id}
      name={name}
      fullWidth={fullWidth}
      data-test-id={`input-${testId}`}
      type={type}
      placeholder={placeholder}
      autoFocus={autofocus}
      autoComplete={autocomplete}
      disabled={disabled}
      className={inputClassName}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      inputProps={{
        'aria-label': name,
        min,
        max,
      }}
      endAdornment={endAdornment}
      sx={sx}
      readOnly={readonly}
    />
  )
}
