import makeStyles from '@mui/styles/makeStyles'

export const useAppNavigationStyles = makeStyles(
  ({ palette, spacing }) => ({
    root: {
      position: 'sticky',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 10,
      backgroundColor: palette.bone.main,
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      transition: 'height 0.3s ease',
    },
    companyLogo: {
      marginLeft: 20,
      marginRight: 36,
      cursor: 'pointer',
      transition: 'all 0.3s ease',

      '&:hover': {
        opacity: 0.8,
      },
    },
    companyLogoSvg: {},
    routesList: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      boxSizing: 'border-box',
      paddingTop: 4,
      paddingBottom: 4,
    },
    breadCrumbsList: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      boxSizing: 'border-box',
      paddingTop: 4,
      paddingBottom: 4,
    },

    // Sizes:
    sizeRegular: {
      '& $content': {
        height: 64,
      },

      '& $companyLogoSvg': {
        marginTop: 3,
        width: 32,
        height: 34,
      },
    },
    sizeCompact: {
      '& $content': {
        height: 40,
      },

      '& $companyLogoSvg': {
        marginTop: 2,
        width: 22,
        height: 24,
      },
    },

    // Types:
    typeSectionListView: {
      borderBottom: `1px solid ${palette.iron.main}`,
    },
    typeSectionView: {
      borderBottom: `1px solid ${palette.iron.main}`,
    },
    typeSectionEdit: {
      backgroundColor: palette.persianBlue.main,
    },
    typeSectionCreate: {
      backgroundColor: palette.darkDusk.main,
    },
    typeSectionPreview: {
      backgroundColor: palette.persianBlue.main,
    },

    headerIcons: {
      cursor: 'pointer',
      height: 32,
      width: 32,
      borderRadius: '50%',
    },
    developerIcon: {
      marginRight: spacing(0.5),
    },
    iconLight: {
      color: palette.white.main,
    },
    purpleCircle: {
      background: 'rgba(156, 100, 255, 0.25)',
    },
  }),
  { name: 'AppHeader' },
)
