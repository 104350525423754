import { Box, Typography, styled } from '@mui/material'
import React, { useContext } from 'react'
import { BuilderContext } from '../../../Builder'
import { getIn } from 'formik'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { ItemStyle } from '@ketch-sdk/ketch-types'
import { SectionEmptyState } from '../../SectionEmptyState'
import { clamp } from 'lodash'
import { BUILDER_CONSTANTS } from '../../../utils/enums'

const ListHeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '24px',
})

const TextBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
})

const ButtonsBox = styled(Box)({
  flex: '0 1 auto',
  display: 'flex',
  gap: '8px',
})

const ButtonBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '86px',
  height: '28px',
})

export const ModalListHeader: React.FC = () => {
  const { themeConfig, experienceConfig } = useContext(BuilderContext)

  // Theme
  const titleColor = getIn(themeConfig, themeUpsertFormKeys.modalListTitleColor)
  const acceptAllButtonStyle = getIn(themeConfig, themeUpsertFormKeys.modalAcceptAllButtonStyle)
  const acceptAllButtonBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.modalAcceptAllButtonBackgroundColor)
  const acceptAllButtonTextColor = getIn(themeConfig, themeUpsertFormKeys.modalAcceptAllButtonTextColor)
  const acceptAllButtonBorderRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.modalAcceptAllButtonCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'
  const rejectAllButtonStyle = getIn(themeConfig, themeUpsertFormKeys.modalRejectAllButtonStyle)
  const rejectAllButtonBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.modalRejectAllButtonBackgroundColor)
  const rejectAllButtonTextColor = getIn(themeConfig, themeUpsertFormKeys.modalRejectAllButtonTextColor)
  const rejectAllButtonBorderRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.modalRejectAllButtonCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'

  // Experience
  const title = getIn(experienceConfig, experienceUpsertFormKeys.modalListHeaderText)
  const showTitle = getIn(experienceConfig, experienceUpsertFormKeys.modalListHeaderVisible)

  return (
    <ListHeaderBox>
      <TextBox>
        {showTitle ? (
          <Typography variant="h4" color={titleColor} sx={{ wordWrap: 'break-word', maxWidth: '445px' }}>
            {title}
          </Typography>
        ) : (
          <SectionEmptyState section={'List Title'} mt={0.25} />
        )}
      </TextBox>
      <ButtonsBox>
        {/* Reject all button */}
        <ButtonBox
          sx={{
            background: rejectAllButtonStyle === ItemStyle.Filled ? rejectAllButtonBackgroundColor : 'transparent',
            border: `1.5px solid ${rejectAllButtonBackgroundColor}`,
            borderRadius: rejectAllButtonBorderRadius,
          }}
        >
          <Typography color={rejectAllButtonTextColor} fontSize={'13px'} fontWeight={600}>
            Reject All
          </Typography>
        </ButtonBox>

        {/* Accept all button */}
        <ButtonBox
          sx={{
            background: acceptAllButtonStyle === ItemStyle.Filled ? acceptAllButtonBackgroundColor : 'transparent',
            border: `1.5px solid ${acceptAllButtonBackgroundColor}`,
            borderRadius: acceptAllButtonBorderRadius,
          }}
        >
          <Typography color={acceptAllButtonTextColor} fontSize={'13px'} fontWeight={600}>
            Accept All
          </Typography>
        </ButtonBox>
      </ButtonsBox>
    </ListHeaderBox>
  )
}
