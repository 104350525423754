import { forwardRef, HTMLAttributes } from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'

import { makeStyles, createStyles } from '@mui/styles'

import { ReactComponent as RemoveBadgeIcon } from 'assets/icons/remove_icon_bold.svg'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseBadge: {
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 5,
        padding: '5px 8px',
        fontFamily: typography.fontFamily,
        fontSize: typography.pxToRem(11),
        lineHeight: typography.pxToRem(13),
        fontWeight: 600,
        letterSpacing: '0.01em',
      },
      // Types:
      typeLightPink: {
        background: palette.bloom.main,
        color: palette.darkViolet.main,
      },
      typeDarkBlue: {
        background: palette.marine.main,
        color: palette.persianBlue.main,
      },
      typeNeutral: {
        background: palette.fadedGrey.main,
        color: palette.darkChrome.main,
      },
      typeSuccess: {
        background: palette.greenHaze.main,
        color: palette.white.main,
      },
      typeOutline: {
        border: `1px solid ${palette.darkDuskFaded.main}`,
        color: palette.darkDuskFaded.main,
      },
      typeOutlineInProgress: {
        border: `1px solid ${palette.sphere.main}`,
        color: palette.sphere.main,
      },
      typeOutlineFailure: {
        border: `1px solid ${palette.red.main}`,
        color: palette.red.main,
      },
      typeOutlineSuccess: {
        border: `1px solid ${palette.greenHaze.main}`,
        color: palette.greenHaze.main,
      },
      typeOutlineFaded: {
        border: `1px solid ${palette.iron.main}`,
        color: palette.darkDuskFaded.main,
      },
      removeBadgeContainer: {
        cursor: 'pointer',
      },
    }),
  { name: 'Badge' },
)

interface Props extends HTMLAttributes<HTMLDivElement> {
  /** Status variant - reflects the colour of border and background */
  variant?:
    | 'light-pink'
    | 'dark-blue'
    | 'neutral'
    | 'success'
    | 'outline'
    | 'outline-failure'
    | 'outline-success'
    | 'outline-progress'
    | 'outline-faded'
  /** Status custom className */
  className?: string
  /** Optional function param when Badge is intended for use in form where badges are removable */
  onRemoveBadge?: () => void
}

/**
 * -
 */
export const Badge = forwardRef<HTMLDivElement, Props>(function Badge(
  { variant = 'neutral', className, children, onRemoveBadge },
  ref,
) {
  const classes = useStyles()

  const badgeClassName = clsx(
    classes.baseBadge,
    {
      [classes.typeLightPink]: variant === 'light-pink',
      [classes.typeDarkBlue]: variant === 'dark-blue',
      [classes.typeNeutral]: variant === 'neutral',
      [classes.typeSuccess]: variant === 'success',
      [classes.typeOutline]: variant === 'outline',
      [classes.typeOutlineInProgress]: variant === 'outline-progress',
      [classes.typeOutlineFailure]: variant === 'outline-failure',
      [classes.typeOutlineSuccess]: variant === 'outline-success',
      [classes.typeOutlineFaded]: variant === 'outline-faded',
    },
    className,
  )

  return (
    <div ref={ref} className={badgeClassName}>
      <Box display="flex" alignItems="center">
        {children}
        {!!onRemoveBadge && (
          <Box
            display="flex"
            alignItems="center"
            className={classes.removeBadgeContainer}
            ml={2}
            onClick={onRemoveBadge}
          >
            <RemoveBadgeIcon />
          </Box>
        )}
      </Box>
    </div>
  )
})
