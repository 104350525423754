import React from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces/common'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans/deploymentPlan'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { Banner } from 'components/ui-kit/banner/Banner'
import { IssuesMessage } from 'components/ui-layouts/issuesMessage/IssuesMessage'
import { ViewLayout } from 'components/ui-layouts/viewLayout/ViewLayout'
import { ViewLayoutHeader } from 'components/ui-layouts/viewLayout/ViewLayoutHeader'
import { ViewLayoutContent } from 'components/ui-layouts/viewLayout/ViewLayoutContent'
import { DeploymentPlanViewDetails } from 'pages/consentAndRights/deploymentPlans/view/components/DeploymentPlanViewDetails'
import { DeploymentPlanViewActions } from 'pages/consentAndRights/deploymentPlans/view/components/DeploymentPlanViewActions'
import { DeploymentPlanOverview } from 'pages/consentAndRights/deploymentPlans/view/tabs/DeploymentPlanOverview'
import { DeploymentPlanDocuments } from 'pages/consentAndRights/deploymentPlans/view/tabs/DeploymentPlanDocuments'
import { DeploymentPlanDeployments } from 'pages/consentAndRights/deploymentPlans/view/tabs/DeploymentPlanDeployments'
import { DeploymentPlanVersions } from 'pages/consentAndRights/deploymentPlans/view/tabs/DeploymentPlanVersions'

type Props = {
  isReady: boolean
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
  versions: DeploymentPlanDTO[]
}

export const DeploymentPlanView: React.FC<Props> = ({ isReady, deploymentPlan, versions }) => {
  const location = useLocation()
  const { removeQueryParam } = useQueryParams<{ version?: string }>()
  const navigate = useNavigate()

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Deployment Plans', link: RoutesManager.deployment.deploymentPlans.root.getURL() },
    { title: deploymentPlan?.name || deploymentPlan?.code },
  ]

  if (!isReady || !deploymentPlan) {
    return <ViewLayout isReady={false} breadcrumbs={breadcrumbs} />
  }

  const { name, code, hasIssues, latest } = deploymentPlan
  // TODO :: !versions.length - for old deploymentPlans.
  const isLatest = !versions.length || !!latest

  const tabs = [
    {
      title: 'Overview',
      link: {
        pathname: RoutesManager.deployment.deploymentPlans.view.overview.root.getURL({ code }),
        search: location.search,
      },
    },
    {
      title: 'Documents',
      link: {
        pathname: RoutesManager.deployment.deploymentPlans.view.documents.root.getURL({ code }),
        search: location.search,
      },
    },
    {
      title: 'Deployments',
      link: {
        pathname: RoutesManager.deployment.deploymentPlans.view.deployments.root.getURL({ code }),
        search: location.search,
      },
    },
    {
      title: 'Versions',
      link: {
        pathname: RoutesManager.deployment.deploymentPlans.view.versions.root.getURL({ code }),
        search: location.search,
      },
    },
  ]

  const issuesMessageActions = [
    {
      title: 'Update',
      onClick: () => {
        navigate(RoutesManager.deployment.deploymentPlans.upsert.root.getURL({ code }))
      },
    },
  ]

  return (
    <ViewLayout
      breadcrumbs={breadcrumbs}
      issuesMessage={
        !!hasIssues && <IssuesMessage actions={issuesMessageActions} entityType={ApplicationEntity.DEPLOYMENT_PLAN} />
      }
      versionsMessage={
        !isLatest && (
          <Banner
            variant="warning"
            actions={[
              {
                title: 'View Current',
                onClick: () => {
                  removeQueryParam('version')
                },
              },
            ]}
          >
            You are viewing an old version of this deployment plan. Return to the current version to manage or activate.
          </Banner>
        )
      }
    >
      <ViewLayoutHeader
        title={name || code}
        backTo={RoutesManager.deployment.deploymentPlans.root.getURL()}
        details={<DeploymentPlanViewDetails isLatest={isLatest} deploymentPlan={deploymentPlan} />}
        actions={isLatest && <DeploymentPlanViewActions deploymentPlan={deploymentPlan} />}
      />

      <ViewLayoutContent tabs={tabs}>
        <Routes location={location}>
          <Route path="/" element={<DeploymentPlanOverview deploymentPlan={deploymentPlan} />} />
          <Route
            path={RoutesManager.deployment.deploymentPlans.view.overview.root.pattern}
            element={<DeploymentPlanOverview deploymentPlan={deploymentPlan} />}
          />

          <Route
            path={RoutesManager.deployment.deploymentPlans.view.documents.root.pattern}
            element={<DeploymentPlanDocuments deploymentPlan={deploymentPlan} />}
          />

          <Route
            path={RoutesManager.deployment.deploymentPlans.view.deployments.root.pattern}
            element={<DeploymentPlanDeployments deploymentPlan={deploymentPlan} />}
          />

          <Route
            path={RoutesManager.deployment.deploymentPlans.view.versions.root.pattern}
            element={<DeploymentPlanVersions deploymentPlan={deploymentPlan} versions={versions} />}
          />
        </Routes>
      </ViewLayoutContent>
    </ViewLayout>
  )
}
