import { MaybeNull } from 'interfaces'
import {
  IdentitySpaceDTO,
  IdentitySpaceFormDTO,
  IdentitySpacesCatalogDTO,
  IdentitySpaceMappingDirection,
} from 'interfaces/identitySpaces/identitySpace'
import { excludeFalsy } from 'utils/helpers/excludeFalsy'

export const identitySpaceMappingDirectionsList = [{ value: IdentitySpaceMappingDirection.PUSH, title: 'Pushes To' }]

export const emptyIdentitySpaceMappingValue = {
  direction: IdentitySpaceMappingDirection.RECEIVE,
  identitySpaceCode: '',
}

export const getOptions = ({
  isEditMode,
  index,
  values,
}: {
  isEditMode: boolean
  index: number
  values: IdentitySpaceFormValues
}) => {
  const codesToExclude = [
    isEditMode && values?.code,
    ...values.mappings.reduce<string[]>(
      (acc, { identitySpaceCode }, idx) => (idx === index ? acc : [...acc, identitySpaceCode]),
      [],
    ),
  ].filter(excludeFalsy)

  return values.identitySpaces.filter(({ code }) => !codesToExclude.includes(code))
}

export type IdentitySpaceFormValues = {
  name: string
  code: string
  description?: string
  typeCode: string
  formatCode: string
  marketplaceAppID: string
  hasMappings: boolean
  identitySpaces: IdentitySpaceDTO[]
  formats?: IdentitySpacesCatalogDTO['formats']
  types?: IdentitySpacesCatalogDTO['types']
  mappings: {
    direction: IdentitySpaceMappingDirection
    identitySpaceCode: string
  }[]
  isAuthentication: boolean
  verifierID: string
  IsMarketingPreferences: boolean
}

export function getInitialValues({
  identitySpace,
  identitySpaces,
}: {
  identitySpace: MaybeNull<IdentitySpaceDTO>
  identitySpaces: IdentitySpaceDTO[]
}): IdentitySpaceFormValues {
  if (!identitySpace) {
    return {
      name: '',
      code: '',
      hasMappings: false,
      typeCode: '',
      formatCode: '',
      marketplaceAppID: '',
      mappings: [emptyIdentitySpaceMappingValue],
      identitySpaces,
      isAuthentication: false,
      verifierID: '',
      IsMarketingPreferences: false,
    }
  }

  const { destinationIdentitySpaceMaps } = identitySpace

  // TODO: Remove inversion
  const destinationMappings = (destinationIdentitySpaceMaps || []).map(({ sourceIdentitySpaceCode }) => ({
    direction: IdentitySpaceMappingDirection.PUSH,
    identitySpaceCode: sourceIdentitySpaceCode,
  }))

  const mappings = [...destinationMappings]

  return {
    name: identitySpace.name,
    code: identitySpace.code,
    description: identitySpace.description || '',
    typeCode: identitySpace.typeCode || '',
    formatCode: identitySpace.formatCode || '',
    marketplaceAppID: identitySpace.marketplaceAppID || '',
    hasMappings: !!destinationMappings.length,
    mappings: mappings.length ? mappings : [emptyIdentitySpaceMappingValue],
    identitySpaces,
    isAuthentication: identitySpace.isAuthentication || false,
    verifierID: identitySpace.verifierID || '',
    IsMarketingPreferences: identitySpace.IsMarketingPreferences || false,
  }
}

export const mapValuesToPayload = ({ values }: { values: IdentitySpaceFormValues }): IdentitySpaceFormDTO => {
  const { hasMappings, mappings, isAuthentication, verifierID, IsMarketingPreferences } = values

  return {
    name: values.name,
    code: values.code,
    formatCode: values.formatCode,
    marketplaceAppID: values.marketplaceAppID,
    typeCode: values.typeCode,
    version: 1,
    ...(hasMappings && {
      destinationIdentitySpaceMaps: mappings
        .filter(({ direction }) => direction === IdentitySpaceMappingDirection.PUSH)
        // TODO: Remove inversion
        .map(({ identitySpaceCode }) => ({
          sourceIdentitySpaceCode: identitySpaceCode,
        })),
    }),
    isAuthentication,
    verifierID,
    IsMarketingPreferences,
  }
}
