import React from 'react'
import { useFormikContext } from 'formik'

import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { PreferenceField, serialize } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { InterpolateLinks } from 'pages/consentAndRights/experiences/components/InterpolateLinks'
import { UpsertExperienceEditableOverlay } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEditableOverlay'
import { UpsertExperienceEmptyField } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEmptyField'
import { UpsertExperiencePreviewText } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewText'
import { useUpsertPreferenceStyles } from 'pages/consentAndRights/experiences/upsert/components/UpsertPreference/hooks'

type Props = {
  getOverlayProps: (name: PreferenceField) => {
    active: boolean
    onClick: () => void
  }
}

export const BodyDescription: React.FC<Props> = ({ getOverlayProps }) => {
  const classes = useUpsertPreferenceStyles()

  const { values } = useFormikContext<PreferenceExperienceFormValues>()
  const { rights } = values

  const bodyDescriptionText = serialize(rights.bodyDescription)

  return (
    <UpsertExperienceEditableOverlay
      className={classes.bodyDescription}
      {...getOverlayProps(PreferenceField.RIGHTS_BODY_DESCRIPTION)}
    >
      {bodyDescriptionText ? (
        <UpsertExperiencePreviewText size="small">
          <InterpolateLinks text={bodyDescriptionText} />
        </UpsertExperiencePreviewText>
      ) : (
        <UpsertExperienceEmptyField required size="small">
          Body Description
        </UpsertExperienceEmptyField>
      )}
    </UpsertExperienceEditableOverlay>
  )
}
