import { ObjectLiteral } from 'interfaces'

export enum WorkflowActivityCategory {
  STANDARD = 'standard',
  GATEWAY = 'gateway',
  CONNECTOR = 'connector',
  APP = 'app',
  APP_LEVEL_2 = 'applevel2',
  DSR = 'dsr',
}

export enum WorkflowActivityGatewayMode {
  SPLIT = 1,
  JOIN = 2,
  DECISION_SINGLE = 3,
  DECISION_MULTI = 4,
  DECISION_BOOLEAN = 5,
}

export enum WorkflowActivityCode {
  SET_PERMITS = 'standard.SetPermitActivity',
  API_CALL = 'standard.CallAPIActivity',
  API_CALL_V2 = 'standard.ForwardEventWorkflow',
  EMAIL = 'standard.SendEmailActivity',
  TASK = 'standard.ManualTaskActivity',
  APPROVAL = 'standard.WaitForApprovalActivity',
  DATA_SUBJECT_EMAIL = 'standard.SendDataSubjectEmailActivity',
  FULFILL_RIGHT = 'standard.MarkRightFulfilledActivity',
  DSR_TRANSPONDER = 'dsrTransponder',
  INTERNAL_SYSTEM_DSR = 'internalSystemDSR',
  IDENTITY_VERIFICATION = 'standard.IdentityVerificationActivity',
  START = 'system.startFlow',
  END = 'system.endFlow',
  END_ALT = 'standard.EndWorkflow',
  DECISION_BOOLEAN = 'decision_boolean',
  DECISION_SINGLE = 'decision_single',
  DECISION_MULTI = 'decision_multi',
  IMPORT_CONTEXT = 'importcontext',
}

export enum WorkflowActivityConnectorCode {
  ADOBE = 'adobe',
  SALESFORCE = 'salesforce',
  MICROSOFT_EXCHANGE_SERVER = 'microsoft',
  GOOGLE_MARKETING = 'google',
  HUBSPOT = 'hubspot',
  MARKETO = 'marketo',
  APPNEXUS = 'appnexus',
  COMSCORE = 'comscore',
  ORACLE_DATA_CLOUD = 'oracle',
  DATAXU = 'dataxu',
  KETCH_TRANSPONDER = 'standard.TransponderWorkflow',
  DSR_TRANSPONDER = 'dsrTransponder',
  INTERNAL_SYSTEM_DSR = 'internalSystemDSR',
  IMPORT_CONTEXT = 'importcontext',
  API_CALL_V2 = 'standard.ForwardEventWorkflow',
  DATA_REVIEW = 'standard.DataReviewWorkflow',
  END = 'standard.EndWorkflow',
}

export type WorkflowActivity = {
  category: WorkflowActivityCategory
  name: string
  code: WorkflowActivityCode | WorkflowActivityConnectorCode | string
  version: string
  icon: {
    ETag?: string
    URL: string
  }
  gateway?: {
    mode: WorkflowActivityGatewayMode
  }
  workflow?: {
    temporalFunctionName?: string
    config?: ObjectLiteral
    outputs?: ObjectLiteral[]
    params?: ObjectLiteral[]
    options: ObjectLiteral
  }
  activity?: {
    temporalFunctionName?: string
    params?: ObjectLiteral[]
    outputs?: ObjectLiteral[]
    options?: ObjectLiteral
    config?: ObjectLiteral
  }
}
