import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { getUuidWithoutHyphensAndStartingWithALetter } from '../../../../../../utils/getUuidWithoutHyphensAndStartingWithALetter'

interface IGetSplitGatewayInitialValuesParams {
  step: ICanvasStep
  steps: Array<ICanvasStep>
}

interface IGetCommonGatewayInitialValues {
  description: string
  code: string
  transitions: Array<{
    ID?: string | number
    name: string
    variable: string
    operator: string
    operand: string
  }>
}

export const getDecisionBooleanInitialValues = ({
  step,
  steps,
}: IGetSplitGatewayInitialValuesParams): IGetCommonGatewayInitialValues => {
  const description = step.description || ''
  const code = step.code || ''
  const transitions = step.gateway?.transitions || []
  const precedingStepIndex = steps.findIndex(s => s?.activity?.transition === step.ID)
  const precedingStep = steps[precedingStepIndex]

  const payload = {
    description,
    code,
    transitions: transitions.map((transition, index) => {
      const namePayload = transition.name ? transition.name : index === 0 ? 'Positive' : 'Negative'
      return {
        ID: transition?.ID || getUuidWithoutHyphensAndStartingWithALetter(),
        name: namePayload,
        // variable, operator, and operand hard coded per https://ketch-com.atlassian.net/browse/KD-4172
        variable: `${precedingStep?.ID}.decisionBoolean`,
        operator: '==',
        operand: index === 0 ? 'true' : 'false',
      }
    }),
  }
  return payload
}
