import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { OrganizationDTO } from 'interfaces/organizations/organization'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  userId: string
  includeMetadata?: boolean
}>

type Response = APIListResponse<{ organizations: OrganizationDTO[] }>

export const fetchUserOrganizations = ({ userId, includeMetadata, start, limit }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/users/${userId}/organizations`,
      params: {
        filters: {
          includeMetadata,
          start,
          limit,
        },
      },
    }),
  )
