import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createCustomRightName } from 'api/customRightNames/fetchers/createCustomRightName'

const useCustomMutation = createUseMutation({
  mutationFn: createCustomRightName,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateCustomRightName = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
