import { FileInfoDTO as DropZoneFileInfo } from 'interfaces/files/fileInfo'
import { FileInfoDTO } from '@ketch-com/figurehead'

export type UrlParams = {
  id: string
}

export type DataFlowUploadFormValues = {
  file: FileInfoDTO[]
}

export const getDataFlowFileFormInitialValues = (): DataFlowUploadFormValues => ({
  file: [],
})

/**
 * Converter function between the figurehead FileInfo type and the one that our
 * dropzone uses
 */
export const convertFileInfoTypes: (file: FileInfoDTO) => DropZoneFileInfo = (file: FileInfoDTO) => ({
  public_url: file.publicUrl || '',
  ID: file.id || '',
  content_type: file.contentType || '',
  download_url: file.downloadUrl || '',
  extension: file.contentType || '',
  info_url: file.infoUrl || '',
  name: file.name || '',
  size: file.size || '',
})
