import React, { useCallback } from 'react'
import { useFormikContext } from 'formik'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'

import { DraggableCanonicalRight } from './DraggableCanonicalRight'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export const reorderDropdownOptions = <T,>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

type Props = {}

export const CanonicalRights: React.FC<Props> = () => {
  const { values, setFieldValue } = useFormikContext<PreferenceExperienceFormValues>()

  const onDragEnd = useCallback(
    (result: DropResult) => {
      // handle item dropped outside the list area
      if (!result.destination) return

      const startIndex = result.source.index
      const endIndex = result.destination.index

      const newOrder = Array.from(values?.rights?.canonicalRightsOrder || [])
      const [removed] = newOrder.splice(startIndex, 1)
      newOrder.splice(endIndex, 0, removed)

      setFieldValue(`rights.canonicalRightsOrder`, newOrder)
    },
    [setFieldValue, values?.rights?.canonicalRightsOrder],
  )

  const canonicalRights = values?.rights?.canonicalRightsOrder

  return (
    <>
      <Box my={2}>
        <Typography variant="body">
          Please specify the order for rights to be displayed using the canonical right groups below.
        </Typography>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => {
            const { innerRef, droppableProps } = provided
            return (
              <Box {...droppableProps} ref={innerRef}>
                {canonicalRights?.map((canonicalRight, canonicalRightIndex) => {
                  const isDragDisabled = canonicalRights?.length === 1
                  return (
                    <Draggable
                      key={canonicalRight}
                      draggableId={canonicalRight}
                      index={canonicalRightIndex}
                      isDragDisabled={isDragDisabled}
                    >
                      {(provided, snapshot) => (
                        <DraggableCanonicalRight
                          key={canonicalRight}
                          provided={provided}
                          snapshot={snapshot}
                          option={canonicalRight}
                        />
                      )}
                    </Draggable>
                  )
                })}

                {provided.placeholder}
              </Box>
            )
          }}
        </Droppable>
      </DragDropContext>
    </>
  )
}
