import { Box, styled } from '@mui/material'
import { useContext } from 'react'
import { BUILDER_CONSTANTS, ModalExperienceBuilderSection, ModalThemeBuilderSection } from '../../utils/enums'
import { BuilderContext } from '../../Builder'
import { SelectableSection } from '../SelectableSection'
import { ModalHeader } from './components/ModalHeader'
import { ModalDescriptionTitle } from './components/ModalDescriptionTitle'
import { ModalDescription } from './components/ModalDescription'
import { ModalListHeader } from './components/ModalListHeader'
import { ModalPurposeList } from './components/ModalPurposeList'
import { ModalFooter } from './components/ModalFooter'
import { ModalContainerPosition } from '@ketch-sdk/ketch-types'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { clamp } from 'lodash'

const positionWidths = {
  [ModalContainerPosition.Center]: '680px',
  [ModalContainerPosition.Left]: '420px',
  [ModalContainerPosition.Right]: '420px',
}

const ModalBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '12px',
})

export const ModalBuilder: React.FC = () => {
  const { themeConfig, isTheme } = useContext(BuilderContext)
  const position = getIn(themeConfig, themeUpsertFormKeys.modalContainerPosition) as ModalContainerPosition
  const backgroundColor = getIn(themeConfig, themeUpsertFormKeys.modalContainerBackgroundColor)
  const containerBorderRadius = clamp(
    getIn(themeConfig, themeUpsertFormKeys.modalContainerCornerRadius),
    BUILDER_CONSTANTS.MIN_BORER_RADIUS,
    BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
  )

  return (
    <SelectableSection
      section={isTheme ? ModalThemeBuilderSection.Container : undefined}
      overlayProps={{ margin: '30px 18px 18px 18px' }}
      sectionBorderRadius={containerBorderRadius - 8}
    >
      <ModalBox
        sx={{
          width: positionWidths[position],
          background: backgroundColor,
          borderRadius: containerBorderRadius + 'px',
        }}
      >
        {/* Header */}
        <SelectableSection
          section={isTheme ? ModalThemeBuilderSection.Header : ModalExperienceBuilderSection.Header}
          overlayProps={{
            margin: '18px 18px 26px 18px',
          }}
          sectionTopLeftBorderRadius={containerBorderRadius - 8} // -8 keeps ensures container and overlay border radius look the same
          sectionTopRightBorderRadius={containerBorderRadius - 8}
        >
          <ModalHeader />
        </SelectableSection>

        <Box px={'24px'}>
          {/* Description Title */}
          <SelectableSection
            section={isTheme ? undefined : ModalExperienceBuilderSection.DescriptionTitle}
            overlayProps={isTheme ? {} : { margin: '10px -6px 10px -6px' }}
          >
            <ModalDescriptionTitle />
          </SelectableSection>

          {/* Description */}
          <SelectableSection
            section={isTheme ? ModalThemeBuilderSection.Description : ModalExperienceBuilderSection.Description}
            overlayProps={isTheme ? { margin: '-18px -6px 4px -6px' } : { margin: '10px -6px 8px -6px' }}
          >
            <ModalDescription />
          </SelectableSection>

          {/* Purpose List Header */}
          <SelectableSection
            section={isTheme ? ModalThemeBuilderSection.ListHeader : ModalExperienceBuilderSection.ListHeader}
            overlayProps={{ margin: '4px -6px' }}
          >
            <ModalListHeader />
          </SelectableSection>

          {/* Purpose List */}
          <SelectableSection
            section={isTheme ? ModalThemeBuilderSection.PurposeList : ModalExperienceBuilderSection.PurposeList}
            overlayProps={{ margin: '10px -6px' }}
          >
            <ModalPurposeList />
          </SelectableSection>
        </Box>

        {/* Footer */}
        <SelectableSection
          section={isTheme ? ModalThemeBuilderSection.Footer : undefined} // TODO: ModalExperienceBuilderSection.Footer - Descoped
          overlayProps={{ margin: '18px' }}
          sectionBottomLeftBorderRadius={containerBorderRadius - 8} // -8 keeps ensures container and overlay border radius look the same
          sectionBottomRightBorderRadius={containerBorderRadius - 8}
        >
          <ModalFooter />
        </SelectableSection>
      </ModalBox>
    </SelectableSection>
  )
}
