import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'
import CheckSharpIcon from '@mui/icons-material/CheckSharp'
import { TooltipV2 } from '../tooltip/TooltipV2'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseListItem: {
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center',

        '&:not($disabled)': {
          '&:not($active)': {
            cursor: 'pointer',

            '&:hover': {
              background: palette.fadedGrey.main,

              '& $baseContent': {
                color: palette.royalBlue.main,
              },
            },

            '&:active': {
              background: palette.white.main,

              '& $baseContent': {
                color: palette.persianBlue.main,
              },
            },
          },
        },

        '&:not($borderless)': {
          '&:not(:last-child)': {
            borderBottom: `1px solid ${palette.iron.main}`,

            '&$active': {
              borderBottom: `1px solid rgba(87, 116, 255, 0.3)`,
            },
          },
        },
      },
      baseIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        width: 15,
        height: 15,
        marginRight: 15,

        '& svg': {
          color: palette.darkDusk.main,
        },
      },
      baseContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexGrow: 1,
      },
      baseCheckedState: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        width: 15,
        height: 15,
        marginLeft: 15,

        '& svg': {
          color: palette.sphere.main,
        },
      },

      // Sizes:
      sizeRegular: {
        padding: '9px 18px',

        '& $baseIcon svg': {
          fontSize: typography.pxToRem(18),
        },

        '& $baseContent': {
          fontSize: typography.pxToRem(14),
        },

        '& $baseCheckedState svg': {
          fontSize: typography.pxToRem(18),
        },
      },

      // States:
      disabled: {
        '& $baseIcon svg': {
          color: palette.fadedDarkGrey.main,
        },

        '& $baseContent': {
          color: palette.fadedDarkGrey.main,
        },
      },
      active: {
        background: 'rgba(87, 116, 255, 0.1)',

        '& $baseContent': {
          color: palette.sphere.main,
          fontWeight: 600,
        },
      },
      borderless: {
        borderBottom: 'none',
      },
    }),
  { name: 'ListItem' },
)

export interface Props {
  /** Test ID of an element for selectors */
  testId?: string
  /** Component active state */
  active?: boolean
  /** Custom component className */
  className?: string
  /** Component Icon */
  icon?: any
  /** Component Content */
  content: React.ReactNode
  /** Component value change method */
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  /** Component size */
  size?: 'regular' | 'small'
  /** Disable state */
  disabled?: boolean
  /** Should component has bottom border state */
  borderless?: boolean
  /** Should list item be visible **/
  hidden?: boolean
  /** Unique identifier for Intercom product tours */
  intercomTarget?: string
  /** Hide active Icon */
  hideActiveIcon?: boolean
  /** Hover tooltip */
  tooltip?: string
  /** Mouse enter callback */
  onMouseEnter?: () => void
  /** Mouse leave callback */
  onMouseLeave?: () => void
}

/**
 * -
 */
export const ListItem: React.FC<Props> = ({
  testId,
  active = false,
  disabled = false,
  borderless = false,
  hidden = false,
  className,
  icon,
  content,
  onClick,
  intercomTarget,
  hideActiveIcon = false,
  tooltip,
  onMouseEnter,
  onMouseLeave,
}) => {
  const classes = useStyles()

  if (hidden) {
    return null
  }

  const componentClassName = clsx(
    classes.baseListItem,
    classes.sizeRegular,
    {
      [classes.active]: active,
      [classes.disabled]: disabled,
      [classes.borderless]: borderless,
    },
    className,
  )

  return (
    <TooltipV2 title={tooltip} placement={'bottom'}>
      <div
        className={componentClassName}
        {...(!disabled && !active && { onClick })}
        data-test-id={testId}
        data-intercom-target={intercomTarget}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {icon && <div className={classes.baseIcon}>{icon}</div>}
        <div className={classes.baseContent}>{content}</div>
        {!hideActiveIcon && <div className={classes.baseCheckedState}>{active && <CheckSharpIcon />}</div>}
      </div>
    </TooltipV2>
  )
}
