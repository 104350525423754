import React from 'react'
import { Box, Drawer } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import { Spinner } from '@ketch-com/deck'

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 540,
    boxSizing: 'border-box',
    top: 12,
    right: 12,
    bottom: 12,
    height: 'unset',
    borderRadius: 11,

    '& .MuiBackdrop-root': {
      display: 'none',
    },
  },
  backdrop: {
    background: `rgba(0,0,0,0)`, // Override default darkened background
  },
  pendingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidth: {
    width: '95vw',
    maxWidth: 1490,
    minWidth: 1260,
  },
})

interface ISidebarConfigsWrapperProps {
  children?: React.ReactNode
  isLoading?: boolean
  handleClose: () => void
  fullWidth?: boolean
}

export const SidebarConfigsWrapper: React.FC<ISidebarConfigsWrapperProps> = ({
  children,
  isLoading,
  handleClose,
  fullWidth = false,
}) => {
  const classes = useStyles()

  if (isLoading) {
    return (
      <Drawer
        slotProps={{
          backdrop: {
            className: classes.backdrop,
          },
        }}
        anchor="right"
        open
        onClose={() => handleClose()}
        classes={{
          paper: clsx(classes.paper, {
            [classes.fullWidth]: fullWidth,
          }),
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </Drawer>
    )
  }

  return (
    <Drawer
      BackdropProps={{ className: classes.backdrop }}
      anchor="right"
      open
      onClose={() => handleClose()}
      classes={{
        paper: clsx(classes.paper, {
          [classes.fullWidth]: fullWidth,
        }),
      }}
    >
      {children}
    </Drawer>
  )
}
