// import { faker } from '@faker-js/faker'

export const generateDescendingArray = (num: number): number[] => {
  const data = []
  for (let i = 0; i < num; i++) {
    const payload = 100 - i * 3
    data.push(payload > 0 ? payload : 0)
  }
  return data
}

export const generateDescendingArrayAlt = (num: number): number[] => {
  const data = []
  for (let i = 0; i < num; i++) {
    const payload = 100 - i * 2
    data.push(payload > 0 ? payload : 0)
  }
  return data
}

export const generateDescendingArrayDataUsage = (num: number): number[] => {
  const data = []
  for (let i = 0; i < num; i++) {
    const payload = 100 - i * 5
    data.push(payload > 0 ? payload : 0)
  }
  return data
}

export const staticDescendingArray = generateDescendingArray(99)
export const staticDescendingArrayAlt = generateDescendingArrayAlt(99)
