export enum PreviewDeviceMode {
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile',
}

export const PREVIEW_DEVICE_MODE_OPTIONS = [
  {
    id: PreviewDeviceMode.DESKTOP,
    name: PreviewDeviceMode.DESKTOP,
  },
  {
    id: PreviewDeviceMode.MOBILE,
    name: PreviewDeviceMode.MOBILE,
  },
]
