import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { Group, Line } from 'react-konva'
import { isEmpty, isEqual, isNil, isUndefined } from 'lodash'
import {
  WorkflowActivityConnectorCode,
  WorkflowActivityGatewayMode,
} from 'interfaces/workflowActivities/workflowActivity'
import { IAbsoluteCoordinates, ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { CANVAS_TRANSITION_CONNECTION_TYPES } from 'pages/orchestration/workflows/edit/interfaces/enums'
import { CanvasContext } from 'pages/orchestration/workflows/edit/contexts/CanvasContext'
import { CanvasImage } from 'pages/orchestration/workflows/edit/konvaEntities/common/CanvasImage'
import { getAbsoluteCoordinates } from 'pages/orchestration/workflows/edit/utils/positioning/absoluteCoordinates'
import { getCellBoundaries } from 'pages/orchestration/workflows/edit/utils/positioning/cellBoundaries'
import { getGridPositionForStep } from 'pages/orchestration/workflows/edit/utils/positioning/getGridPositionForStep'
import { TransitionConnection } from 'pages/orchestration/workflows/edit/konvaEntities/transitions/TransitionConnection'
import { StepByType } from 'pages/orchestration/workflows/edit/konvaLayers/steps/StepByType'
import { WarningIcon } from 'pages/orchestration/workflows/edit/konvaEntities/common/WarningIcon'

import emptyNest from 'assets/icons/workflow/emptyNest.svg'
import emptyConnectedNest from 'assets/icons/workflow/emptyConnectedNest.svg'
import disallowedEmptyNest from 'assets/icons/workflow/disallowedEmptyNest.svg'
import emptyNestActive from 'assets/icons/workflow/emptyNestActive.svg'
import { EditWorkflowContext } from '../../contexts/EditWorkflowContext'
import { getJoinTileRenderDetails } from '../../utils/steps/getJoinTileRenderDetails'
import { getSiblingsFromStep } from '../../utils/steps/siblingsFromStep'
import { PasteButton } from '../common/PasteButton'
import { DeleteButton } from '../common/DeleteButton'
import { EditWorkflowContainerContext } from '../../components/EditWorkflowFormContainer/context/EditWorkflowContainerContext'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { getCopyPasteType } from '../../utils/steps/getCopyPasteType'
import { CanvasStepType, CopyPasteType, GatewayModeName } from '../../utils/constants'
import { containsEndTile } from '../../utils/steps/containsEndTile'
import { getStepsInPath } from '../../utils/steps/getStepsInPath'
import { getStepType } from '../../utils/steps/getStepType'
import { getNextSteps } from '../../utils/steps/getNextSteps'
import { isTileInConnectedNest } from '../../utils/steps/isTileInConnectedNest'

interface ITransitionPlaceholder {
  gridPosition: IAbsoluteCoordinates
  step: ICanvasStep
  nextStep?: ICanvasStep
  steps: Array<ICanvasStep>
  transitionIndex: number
  isValid?: boolean
  connectedNestId?: string
  connectedNestStartPosition?: number
}

export const NEST_SIZE_DEFAULT = 54
export const NEST_SIZE_ACTIVE = 56
export const SIDE_GAP_DEFAULT = 8
export const SIDE_GAP_ACTIVE = 7

export const TransitionPlaceholder: React.FC<ITransitionPlaceholder> = ({
  gridPosition,
  step,
  steps,
  nextStep,
  transitionIndex,
  isValid = true,
  connectedNestId,
  connectedNestStartPosition,
}) => {
  const { activeDragStep, dropCoordinates, handleCreateStep, handleReplaceStep, handlePasteSteps, handleRemoveStep } =
    useContext(CanvasContext)

  const { standardJoinActivity } = useContext(EditWorkflowContext)

  const { copiedSteps, hasCopiedSteps, copiedStepsCanonicalRightCode, values } =
    useContext(EditWorkflowContainerContext)

  const isDragStepEndFlow =
    !isUndefined(activeDragStep?.finish) ||
    activeDragStep?.workflow?.temporalFunctionName === WorkflowActivityConnectorCode.END

  const isDragStepJoin = activeDragStep?.gateway?.mode === WorkflowActivityGatewayMode.JOIN
  const isStepPlaceholder = !isUndefined(step?.placeholder)
  const isDropAllowed = !isStepPlaceholder || !isDragStepJoin
  const hasTransition = nextStep && !isNil(nextStep)
  const previousDropCoordinates = useRef(dropCoordinates)
  const nextStepGridPosition = getGridPositionForStep({
    parentGridPosition: gridPosition,
  })
  const cellBoundaries = useMemo(() => {
    return getCellBoundaries({ gridPosition })
  }, [gridPosition])
  const stepAbsolutePosition = getAbsoluteCoordinates(gridPosition)

  const nextStepIsJoinGateway = nextStep?.gateway?.mode === WorkflowActivityGatewayMode.JOIN
  const joinStepDetails = nextStepIsJoinGateway
    ? getJoinTileRenderDetails({
        steps,
        joinStep: nextStep,
        gridPosition,
        previousStep: step,
        connectedNestStartPosition,
      })
    : undefined

  const { parentStep } = getSiblingsFromStep({ step, steps })
  const previousStepIsGateway = !isUndefined(parentStep?.gateway)
  const isPlaceholderConnectingGatewayToJoin = nextStepIsJoinGateway && previousStepIsGateway
  const isTryingToDragEndFlowIntoInvalidLocation = isDragStepEndFlow && !!nextStep

  // Only show paste button if there are steps copied from a workflow of the same canonical type
  const showPasteButton = hasCopiedSteps && copiedStepsCanonicalRightCode === values.canonicalRightCode

  // Drop new step on placeholder after existing step
  const handleDrop = useCallback(() => {
    if (!isDropAllowed) {
      return
    }

    if (isStepPlaceholder) {
      handleReplaceStep?.({ step, stepToCreate: activeDragStep!, standardJoinActivity })
    } else {
      handleCreateStep?.({ parentStep: step, transitionIndex, stepToCreate: activeDragStep!, standardJoinActivity })
    }
  }, [
    activeDragStep,
    handleCreateStep,
    handleReplaceStep,
    isStepPlaceholder,
    isDropAllowed,
    step,
    transitionIndex,
    standardJoinActivity,
  ])

  const showDeleteButton = useMemo(() => {
    const nextSteps = getNextSteps(step)
    const hasNextSteps = nextSteps.length > 0 && nextSteps.every(transition => !!transition)

    return (
      isStepPlaceholder &&
      !isPlaceholderConnectingGatewayToJoin &&
      !isTryingToDragEndFlowIntoInvalidLocation &&
      hasNextSteps
    )
  }, [isPlaceholderConnectingGatewayToJoin, isStepPlaceholder, isTryingToDragEndFlowIntoInvalidLocation, step])

  // This is a useRef instead of useState because we don't want to render whenever the message is set in isPasteAllowed.
  // Also it cannot just be a regular variable (i.e. let pasteNotAllowedMessage = 'Paste not allowed') because that doesn't
  // work within a useMemo.
  let pasteNotAllowedMessage = useRef('Paste not allowed')
  const isPasteAllowed = useMemo(() => {
    if (!copiedSteps.length) {
      pasteNotAllowedMessage.current = 'No copied steps'
      return false
    }

    // Calculate flags
    const copyPasteType = getCopyPasteType(copiedSteps)
    const isBooleanOrSingleGateway = copyPasteType === CopyPasteType.booleanOrSingleGateway
    const hasEndTile = containsEndTile(copiedSteps)
    const hasEndTileInFirstPath =
      isBooleanOrSingleGateway &&
      containsEndTile(
        getStepsInPath({
          pathStartStepId: copiedSteps[0].gateway!.transitions[0].ID?.toString() || '',
          steps: copiedSteps,
          untilEnd: true,
        }),
      )
    const isPlaceholderRightmost = !step.placeholder?.transition

    // In a placeholder that is not the last step, an end tile cannot be placed unless it is a decision boolean being pasted.
    // If decision boolean, an end tile cannot be in the first path which connects to right of the placeholder
    if (!isPlaceholderRightmost) {
      if ((isBooleanOrSingleGateway && hasEndTileInFirstPath) || (!isBooleanOrSingleGateway && hasEndTile)) {
        pasteNotAllowedMessage.current = 'An end tile cannot be pasted before other tiles'
        return false
      }
    }

    // Don't allow pasting a single gateway without join or decision boolean within a connected nest
    const isPlaceHolderInNest = isTileInConnectedNest({ step, steps })
    if (isPlaceHolderInNest && isBooleanOrSingleGateway) {
      pasteNotAllowedMessage.current = 'An single gateway or decision boolean cannot be pasted within a gateway.'
      return false
    }

    return true
  }, [copiedSteps, step, steps])

  // Drop new step on placeholder after existing step
  const handlePaste = useCallback(() => {
    if (!isPasteAllowed) {
      showToast({
        content: pasteNotAllowedMessage.current,
        type: 'error',
        autoHideDuration: 3000,
      })
      return
    }

    if (!hasCopiedSteps) return

    handlePasteSteps?.({ stepToReplace: step, copiedSteps: copiedSteps, allSteps: steps, transitionIndex })

    const firstStep = copiedSteps[0]
    const stepType = getStepType(firstStep)
    let stepName = firstStep.name
    if (stepType === CanvasStepType.Gateway) {
      stepName = firstStep.gateway!.mode ? GatewayModeName[firstStep.gateway!.mode] : 'Gateway'
    } else if (copiedSteps.length > 1) {
      stepName = 'Steps'
    }
    showToast({
      content: `
        ${stepName} pasted!`,
      type: 'success',
      autoHideDuration: 3000,
    })
  }, [isPasteAllowed, hasCopiedSteps, handlePasteSteps, step, copiedSteps, steps, transitionIndex])

  useEffect(() => {
    if (
      isEmpty(previousDropCoordinates) ||
      isEmpty(dropCoordinates) ||
      isEmpty(activeDragStep) ||
      isEqual(dropCoordinates, previousDropCoordinates) ||
      isTryingToDragEndFlowIntoInvalidLocation
    ) {
      return
    }

    const { xStart, yStart, xFinish, yFinish } = cellBoundaries
    const x = dropCoordinates?.x || 0
    const y = dropCoordinates?.y || 0
    const isDropOnXAxis = x >= xStart && x <= xFinish
    const isDropOnYAxis = y >= yStart && y <= yFinish

    if (isDropOnXAxis && isDropOnYAxis) {
      handleDrop()
    }
  }, [activeDragStep, cellBoundaries, dropCoordinates, handleDrop, isTryingToDragEndFlowIntoInvalidLocation])

  const backgroundSVG = useMemo(() => {
    if (isTryingToDragEndFlowIntoInvalidLocation) return disallowedEmptyNest

    if (activeDragStep && isDropAllowed) return emptyNestActive

    if (connectedNestId) return emptyConnectedNest

    return emptyNest
  }, [activeDragStep, isDropAllowed, connectedNestId, isTryingToDragEndFlowIntoInvalidLocation])

  return (
    <Group>
      <Group x={stepAbsolutePosition.x} y={stepAbsolutePosition.y}>
        <CanvasImage
          width={activeDragStep && isDropAllowed ? NEST_SIZE_ACTIVE : NEST_SIZE_DEFAULT}
          height={activeDragStep && isDropAllowed ? NEST_SIZE_ACTIVE : NEST_SIZE_DEFAULT}
          svgURL={backgroundSVG}
          position={{
            x: activeDragStep && isDropAllowed ? SIDE_GAP_ACTIVE : SIDE_GAP_DEFAULT,
            y: activeDragStep && isDropAllowed ? SIDE_GAP_ACTIVE : SIDE_GAP_DEFAULT,
          }}
        />

        {isTryingToDragEndFlowIntoInvalidLocation ? (
          <>
            <Line
              position={{
                x: SIDE_GAP_DEFAULT,
                y: SIDE_GAP_DEFAULT,
              }}
              points={[
                NEST_SIZE_DEFAULT / 2 - 7,
                NEST_SIZE_DEFAULT / 2 - 7,
                NEST_SIZE_DEFAULT / 2 + 7,
                NEST_SIZE_DEFAULT / 2 + 7,
              ]}
              strokeWidth={2}
              stroke="#858585"
            />
            <Line
              position={{
                x: SIDE_GAP_DEFAULT,
                y: SIDE_GAP_DEFAULT,
              }}
              points={[
                NEST_SIZE_DEFAULT / 2 + 7,
                NEST_SIZE_DEFAULT / 2 - 7,
                NEST_SIZE_DEFAULT / 2 - 7,
                NEST_SIZE_DEFAULT / 2 + 7,
              ]}
              strokeWidth={2}
              stroke="#858585"
            />
          </>
        ) : null}

        {!isValid && <WarningIcon />}

        {/* Delete button */}
        {showDeleteButton && (
          <DeleteButton
            onClick={() => handleRemoveStep?.({ step })}
            x={SIDE_GAP_DEFAULT + 61}
            y={SIDE_GAP_DEFAULT - 9}
          />
        )}

        {/* Paste button */}
        {showPasteButton && <PasteButton x={SIDE_GAP_DEFAULT - 12} y={SIDE_GAP_DEFAULT + 40} onClick={handlePaste} />}
      </Group>

      {hasTransition && (
        <Fragment>
          <TransitionConnection
            type={CANVAS_TRANSITION_CONNECTION_TYPES.DASHED}
            step={step}
            nextStep={nextStep!}
            gridPositionStart={gridPosition}
            gridPositionEnd={nextStepGridPosition}
            joinStepDetails={joinStepDetails}
            connectedNestId={connectedNestId}
          />
          <StepByType
            steps={steps}
            step={nextStep!}
            gridPosition={nextStepGridPosition}
            joinStepDetails={joinStepDetails}
            connectedNestId={connectedNestId}
            connectedNestStartPosition={connectedNestStartPosition}
          />
        </Fragment>
      )}
    </Group>
  )
}
