import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { deleteNotFoundTracker } from '../fetchers/deleteNotFoundTracker'

const useCustomMutation = createUseMutation({
  mutationFn: deleteNotFoundTracker,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeleteNotFoundTracker = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
