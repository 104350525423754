import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useFormikContext } from 'formik'

import { IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, FormRow, Radio, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { useNavigate, useParams } from 'react-router-dom'
import { WORKFLOW_TYPE_ENUM } from './sidebarConfigs/forms/activity/DsrTransponderForm/constants'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

const WORKFLOW_TYPE_OPTIONS = [
  {
    id: WORKFLOW_TYPE_ENUM.DELETE,
    name: 'Delete Data',
  },
  {
    id: WORKFLOW_TYPE_ENUM.GET,
    name: 'Access Data',
  },
  {
    id: WORKFLOW_TYPE_ENUM.RESTRICT,
    name: 'Restrict Processing',
  },
  {
    id: WORKFLOW_TYPE_ENUM.UPDATE,
    name: 'Update Data',
  },
]

type Props = {
  isEditMode: boolean
  setCanonicalRightCode: React.Dispatch<React.SetStateAction<string>>
}

export const WorkflowTypeSelection: React.FC<Props> = ({ isEditMode, setCanonicalRightCode }) => {
  const { setFieldValue } = useFormikContext<IWorkflowConfig>()
  const { code } = useParams<{ code: string }>()
  const navigate = useNavigate()
  const [type, setType] = useState('')

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    { title: 'Workflows', link: RoutesManager.orchestration.workflows.root.getURL() },
    {
      title: 'Create',
    },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <UpsertLayout
        showStepper={false}
        mode={UpsertLayoutMode.create}
        renderFormTitle={() => <ContentGroup title="Create New Workflow" titleVariant="h2" isDivider />}
        onAccept={() => {
          setFieldValue('canonicalRightCode', type)
        }}
        onCancel={() => {
          if (isEditMode) {
            navigate(RoutesManager.orchestration.workflows.view.root.getURL({ code }))
          } else {
            navigate(RoutesManager.orchestration.workflows.root.getURL())
          }
        }}
        acceptActionButtonText="Next"
        cancelActionButtonText="Cancel"
        acceptButtonProps={{
          disabled: type === '',
        }}
      >
        <FormRow
          title="Select Type"
          subTitle="A workflow type will define your options for its configuration and events associated with its execution."
        >
          <Box display="flex" alignItems="flex-start" flexDirection="column" gap={1}>
            {WORKFLOW_TYPE_OPTIONS.map(option => {
              return (
                <Box
                  key={option.id}
                  display="flex"
                  alignItems="center"
                  gap={0.5}
                  onClick={e => {
                    setType(option.id)
                    setCanonicalRightCode(option.id)
                  }}
                  sx={{
                    cursor: 'pointer',
                    ml: '-9px',
                  }}
                >
                  <Radio
                    checked={option.id === type}
                    onClick={e => {
                      e.stopPropagation()
                      setType(option.id)
                      setCanonicalRightCode(option.id)
                    }}
                  />
                  <Typography variant="label">{option.name}</Typography>
                </Box>
              )
            })}
          </Box>
        </FormRow>
      </UpsertLayout>
    </>
  )
}
