import { FC } from 'react'
import { useCopyToClipboard } from 'react-use'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { duotoneLight } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import { Box } from '@mui/material'
import { Button, PopUp, Icon } from '@ketch-com/deck'
import { useApplicationScript } from 'api/applications/queries/useApplicationScript'
import { ApplicationDTO } from 'interfaces/applications/application'

type Props = {
  application: ApplicationDTO
  onClose: () => void
}

export const ApplicationExportCodeModal: FC<Props> = ({ application, onClose }) => {
  const [, copyToClipboard] = useCopyToClipboard()

  const { data: applicationScript, isLoading: isLoadingApplicationScript } = useApplicationScript({
    params: {
      applicationCode: application.code,
    },
  })

  return (
    <PopUp
      isOpen
      isLoading={isLoadingApplicationScript}
      title="Export Code"
      subTitle="Copy and paste the code below onto every page of your website as high in the <head> as possible."
      variant="modal"
      onClose={onClose}
      popUpWidth="768px"
      popUpActionChildren={
        <>
          <Button
            color="tertiary"
            size="large"
            startIcon={<Icon name="OCopy" />}
            onClick={() => copyToClipboard(applicationScript)}
          >
            Copy Code
          </Button>
          <Button color="secondary" size="large" onClick={onClose}>
            Close
          </Button>
        </>
      }
    >
      <Box>
        <SyntaxHighlighter wrapLongLines language="javascript" style={duotoneLight}>
          {applicationScript}
        </SyntaxHighlighter>
      </Box>
    </PopUp>
  )
}
