import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'

import { UserShortDTO } from 'interfaces/users/users'
import { UsersListDropdown } from 'pages/settings/users/list/UsersListDropdown'
import { UserStatus } from 'interfaces/users/userStatus'

import { NEW_ITEM_ID } from 'utils/constants/misc'
import { Pagination } from 'api/common/paginatedQuery'
import { RoutesManager } from 'utils/routing/routesManager'

import {
  Button,
  DataGrid,
  EmptyState,
  IconDictionary,
  ListLayout,
  Status,
  StatusState,
  StatusVariant,
  TableCell,
} from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { useIsCurrentUserOrgAdmin } from 'utils/hooks'

type Props = {
  isLoading: boolean
  isFetching: boolean
  users: UserShortDTO[]
  pagination: Pagination
}

export const UsersList: React.FC<Props> = ({ isLoading, isFetching, pagination, users }) => {
  const navigate = useNavigate()

  const breadcrumbs = [
    {
      title: 'Settings',
      link: RoutesManager.settings.root.getURL(),
    },
    { title: 'Users', link: RoutesManager.settings.users.root.getURL() },
  ]

  const isPermittedToCreateUsers = useIsCurrentUserOrgAdmin()

  const canCreateUsers = isPermittedToCreateUsers

  const handleCreateUserClick = () => {
    navigate(RoutesManager.settings.users.upsert.root.getURL({ id: NEW_ITEM_ID }))
  }

  const columns = useMemo(() => {
    return [
      {
        field: 'firstName',
        headerName: 'First Name',
        renderCell: (params: GridRenderCellParams) => <TableCell>{params?.row?.firstName}</TableCell>,
        sortable: false,
        flex: 2,
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        renderCell: (params: GridRenderCellParams) => <TableCell>{params?.row?.lastName}</TableCell>,
        sortable: false,
        flex: 1,
      },
      {
        field: 'email',
        headerName: 'Email Address',
        renderCell: (params: GridRenderCellParams) => <TableCell>{params?.row?.email}</TableCell>,
        sortable: false,
        flex: 2,
      },
      {
        field: 'status',
        headerName: 'Status',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {params.row?.status === UserStatus.ACTIVE ? (
              <Status status={StatusState.success} icon="Dot" variant={StatusVariant.ghost} label="Active" />
            ) : (
              <Status status={StatusState.warning} icon="Dot" variant={StatusVariant.ghost} label="Invited" />
            )}
          </TableCell>
        ),
        sortable: false,
        width: 120,
      },
      {
        renderCell: (params: GridRenderCellParams) => <UsersListDropdown user={params.row} />,
        width: 60,
        sortable: false,
        field: 'action',
        renderHeader: () => null,
      },
    ]
  }, [])

  const totalPages = pagination.totalPages

  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ListLayout
        headerTitle="Users"
        customHeaderRightActionComponent={
          canCreateUsers && (
            <Button size="medium" onClick={handleCreateUserClick}>
              Invite User
            </Button>
          )
        }
        headerProps={{
          isDivider: true,
        }}
      >
        {!isFetching && !users.length ? (
          <EmptyState
            iconName={IconDictionary.OFilter}
            title="There are no users for this organization"
            subTitle={canCreateUsers ? 'Create your first one to populate the table.' : ''}
            primaryButtonTitle={canCreateUsers ? 'Create User Profile' : ''}
            primaryButtonProps={{
              onClick: () => handleCreateUserClick(),
              color: 'tertiary',
            }}
          />
        ) : (
          <Box flexDirection="column" display="flex">
            <DataGrid
              rows={users}
              columns={columns}
              disableChildrenSorting
              disableColumnMenu
              disableColumnResize
              disableColumnReorder
              disableRowSelectionOnClick
              disableAggregation
              hideFooterRowCount
              onRowClick={({ row }) => {
                if (row.status === UserStatus.ACTIVE) {
                  navigate(RoutesManager.settings.users.view.root.getURL({ id: row.ID.toString() }))
                }
              }}
              getRowId={row => row.ID}
              loading={isLoading || pagination.isPending}
              pagination
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={onPaginationModelChange}
              pageSizeOptions={[rowsPerPage]}
              rowCount={pagination.totalResults || rowsPerPage * totalPages}
              hideFooterPagination={totalPages < 2}
              hideFooter={totalPages < 2}
              getRowHeight={() => 'auto'}
              sx={{
                '& .MuiDataGrid-columnHeader[role="columnheader"]:hover': {
                  backgroundImage: 'unset',
                },
                minHeight: '500px',
                height: 'auto',
              }}
            />
          </Box>
        )}
      </ListLayout>
    </>
  )
}
