import { useContext } from 'react'

import { RoutesManager } from 'utils/routing/routesManager'
import { EndDetails, EndDetailsHeaderDetails, EndDetailsHeaderActions } from './components'
import { StepDetailsColumn, WorkflowStepHeaderIcon } from 'pages/orchestration/rightsQueue/stepDetails/components'
import { EndFlowContext, withFormEndFlowContext } from './context/EndFlowContext'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Box } from '@mui/material'
import { Spinner, ViewLayout } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'

const EndFlowWithContext: React.FC = () => {
  const { isReady, breadcrumbs, rightInvocationId } = useContext(EndFlowContext)
  const navigate = useNavigate()

  if (!isReady) {
    return (
      <>
        <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )
  }

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      <ViewLayout
        contentGroupProps={{
          title: 'Task',
          showBackButton: true,
          propsBackButton: {
            onClick: () =>
              navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocationId })),
          },
          imageBlockComponent: <WorkflowStepHeaderIcon />,
          infoBlockComponent: <EndDetailsHeaderDetails />,
          actionRightBlockComponent: <EndDetailsHeaderActions />,
        }}
        widgetContentComponent={<StepDetailsColumn />}
      >
        <EndDetails />
      </ViewLayout>
    </>
  )
}

export const EndFlow = withFormEndFlowContext(EndFlowWithContext)
