import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { FormikConfig, FormikProps, FormikProvider, useFormik } from 'formik'

import { ObjectLiteral } from 'interfaces'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import {
  NavigationBreadCrumbs,
  NavigationBreadCrumbsProps,
} from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      root: {
        position: 'relative',
        width: 1280,
        margin: '0 auto',
        borderRadius: 11,
        backgroundColor: palette.white.main,
        padding: '0 14px',
      },
      header: {
        fontSize: typography.pxToRem(28),
        fontWeight: 800,
        margin: '9px 0 22px 0',
        padding: '14px 0 22px 18px',
        borderBottom: `1px solid ${palette.iron.main}`,
      },
    }),
  { name: 'UpsertLayout' },
)

type Props<I> = Omit<FormikConfig<I>, 'children'> & {
  isReady?: boolean
  validateOnMount?: boolean
  title: string
  breadcrumbs: NavigationBreadCrumbsProps['items']
  children: (formikProps: FormikProps<I>) => React.ReactNode
}

export function UpsertLayout<I extends ObjectLiteral>({
  isReady = true,
  validateOnMount = true,
  enableReinitialize = true,
  title,
  breadcrumbs,
  children,
  ...rest
}: Props<I>) {
  const classes = useStyles()
  const formikProps = useFormik({ ...rest, validateOnMount, enableReinitialize })

  return (
    <FormikProvider value={formikProps}>
      <div className={classes.root}>
        {isReady ? (
          <>
            <NavigationBreadCrumbs type="light" items={breadcrumbs} />
            {title ? <div className={classes.header}>{title}</div> : null}
            <form autoComplete="off">{children(formikProps)}</form>
          </>
        ) : (
          <Spinner padding={50} />
        )}
      </div>
    </FormikProvider>
  )
}
