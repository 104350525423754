import * as Yup from 'yup'
import { useCheckIsExperienceCodeAvailable } from 'api/experiences/mutations/useCheckIsExperienceCodeAvailable'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { useMemo } from 'react'
import { Descendant } from 'slate'
import { getMaxCharsErrorMessage, validationMessages } from 'utils/constants/forms/validationMessages'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { serialize } from '../../common'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'

const MAX_TITLE_LENGTH = 55
const MAX_SUBMIT_BUTTON_TEXT_LENGTH = 30

type UsePreferenceValidationSchemaArgs = {
  isEditMode: boolean
  isEntitledToRights: boolean
  isEntitledToConsentAndDisclosure: boolean
  organizationLanguages: LanguageWithStatusDTO[]
  isEntitledToSubscriptions: boolean
}

export const usePreferenceValidationSchema = ({
  isEditMode,
  isEntitledToRights,
  isEntitledToConsentAndDisclosure,
  organizationLanguages,
  isEntitledToSubscriptions,
}: UsePreferenceValidationSchemaArgs) => {
  const { mutateAsync: handleCheckIsExperienceCodeAvailable } = useCheckIsExperienceCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsExperienceCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(() => {
    const enabledLanguages = organizationLanguages.filter(({ enabled }) => enabled)
    const slateValueRequiredSchema = Yup.array().test(
      'Is required',
      'Required',
      value => !!serialize(value as Descendant[]),
    )

    const preferenceShapeSchema = {
      title: Yup.string().required(validationMessages.REQUIRED),
      overview: Yup.object().shape({
        tabName: Yup.string().required(validationMessages.REQUIRED),
        bodyDescription: slateValueRequiredSchema,
      }),
      ...(isEntitledToConsentAndDisclosure && {
        consents: Yup.object().shape({
          tabName: Yup.string().required(validationMessages.REQUIRED),
          buttonText: Yup.string().required(validationMessages.REQUIRED),
          consentTitle: Yup.string().when('isCustomTextSwitchOn', {
            is: true,
            then: Yup.string().required(validationMessages.REQUIRED),
          }),
          switchTextRenderLogic: Yup.number().required('Required'),
          switchOnText: Yup.string().when('isCustomSwitchTextSwitchOn', {
            is: true,
            then: Yup.string().required(validationMessages.REQUIRED),
          }),
          switchOffText: Yup.string().when('isCustomSwitchTextSwitchOn', {
            is: true,
            then: Yup.string().required(validationMessages.REQUIRED),
          }),
        }),
      }),
      ...(isEntitledToRights && {
        rights: Yup.object().shape({
          tabName: Yup.string().required(validationMessages.REQUIRED),
          bodyDescription: slateValueRequiredSchema,
          bodyTitle: Yup.string().max(MAX_TITLE_LENGTH, getMaxCharsErrorMessage(MAX_TITLE_LENGTH)),
          buttonText: Yup.string()
            .max(MAX_SUBMIT_BUTTON_TEXT_LENGTH, getMaxCharsErrorMessage(MAX_SUBMIT_BUTTON_TEXT_LENGTH))
            .required(validationMessages.REQUIRED),
          personalDetailsTitle: Yup.string().when('personalDetailsTitleType', {
            is: 'custom',
            then: Yup.string()
              .required(validationMessages.REQUIRED)
              .max(MAX_TITLE_LENGTH, getMaxCharsErrorMessage(MAX_TITLE_LENGTH)),
            otherwise: Yup.string().notRequired(),
          }),
          requestDetailsTitle: Yup.string().when('requestTitleType', {
            is: 'custom',
            then: Yup.string()
              .required(validationMessages.REQUIRED)
              .max(MAX_TITLE_LENGTH, getMaxCharsErrorMessage(MAX_TITLE_LENGTH)),
            otherwise: Yup.string().notRequired(),
          }),
          hasSingleFormTemplate: Yup.boolean().required(),
          singleFormTemplateID: Yup.string().when('hasSingleFormTemplate', {
            is: true,
            then: Yup.string().required(validationMessages.SELECTION_REQUIRED),
            otherwise: Yup.string().notRequired(),
          }),
          canonicalRightFormTemplates: Yup.array().of(
            Yup.object().shape({
              formTemplateID: Yup.string().test(
                'Test for value uniqueness amongst dropdown options',
                validationMessages.VALUE_MUST_BE_UNIQUE,
                (value, context: any) => {
                  let isValid = true

                  const formValues = context?.from?.[2]?.value as PreferenceExperienceFormValues

                  if (!formValues?.rights?.hasSingleFormTemplate && !value) {
                    return context.createError({ message: 'Selection required.' })
                  }

                  return isValid
                },
              ),
            }),
          ),
          customRightFormTemplates: Yup.array().of(
            Yup.object().shape({
              formTemplateID: Yup.string().notRequired(),
            }),
          ),
        }),
      }),
      ...(isEntitledToSubscriptions && {
        subscriptions: Yup.object().shape({
          tabName: Yup.string().required(validationMessages.REQUIRED),
          primaryButtonText: Yup.string().required(validationMessages.REQUIRED),
          secondaryButtonText: Yup.string().required(validationMessages.REQUIRED),
          title: Yup.string().when('isCustomTextSwitchOn', {
            is: true,
            then: Yup.string().required(validationMessages.REQUIRED),
          }),
          topicItems: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().test({
                name: 'deletedSubscriptions',
                test: (value, { path, createError }) => {
                  if (value) return true
                  return createError({
                    path,
                    message: 'Please remove deleted subscriptions.',
                  })
                },
              }),
            }),
          ),
          controlItems: Yup.array().of(
            Yup.object().shape({
              itemDetail: Yup.object().shape({
                title: Yup.string().required(validationMessages.REQUIRED),
              }),
            }),
          ),
        }),
      }),
    }

    const translationsPreferenceShapeSchema = {
      title: Yup.string(),
      overview: Yup.object().shape({
        tabName: Yup.string(),
        bodyDescription: Yup.array(),
      }),
      ...(isEntitledToConsentAndDisclosure && {
        consents: Yup.object().shape({
          tabName: Yup.string(),
          buttonText: Yup.string(),
        }),
      }),
      ...(isEntitledToRights && {
        rights: Yup.object().shape({
          tabName: Yup.string(),
          bodyDescription: Yup.array(),
          buttonText: Yup.string(),
        }),
      }),
    }

    return Yup.object().shape({
      ...(!isEditMode && {
        code: codeValidationSchema,
      }),
      name: Yup.string().max(75, 'Name must not exceed 75 characters').required(validationMessages.REQUIRED),
      ...preferenceShapeSchema,
      ...(!!enabledLanguages.length && {
        translations: Yup.object().shape(
          enabledLanguages.reduce(
            (acc, { language }) => ({
              ...acc,
              [language.code]: Yup.object().shape(translationsPreferenceShapeSchema),
            }),
            {},
          ),
        ),
      }),
    })
  }, [
    codeValidationSchema,
    isEditMode,
    isEntitledToConsentAndDisclosure,
    isEntitledToRights,
    organizationLanguages,
    isEntitledToSubscriptions,
  ])
}
