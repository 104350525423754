import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { CookieOverviewTab } from 'pages/policyCenter/cookies/view/tabs/CookieOverviewTab'
import { CookieApplicationsTab } from 'pages/policyCenter/cookies/view/tabs/CookieApplicationsTab'
import { CookieVersionsTab } from 'pages/policyCenter/cookies/view/tabs/CookieVersionsTab'

type Props = {
  cookie: CookieDTO
  versions: CookieDTO[]
}

export const CookieViewRoutes: React.FC<Props> = ({ cookie, versions }) => {
  const location = useLocation()

  return (
    <Routes location={location}>
      <Route path="/" element={<CookieOverviewTab cookie={cookie} />} />
      <Route
        path={RoutesManager.policyCenter.cookies.view.overview.root.pattern}
        element={<CookieOverviewTab cookie={cookie} />}
      />

      <Route
        path={RoutesManager.policyCenter.cookies.view.applications.root.pattern}
        element={<CookieApplicationsTab cookie={cookie} />}
      />

      <Route
        path={RoutesManager.policyCenter.cookies.view.versions.root.pattern}
        element={<CookieVersionsTab cookie={cookie} versions={versions} />}
      />
    </Routes>
  )
}
