import * as Yup from 'yup'

import { validationRegExp } from 'utils/constants/forms/validationRegExp'
import { FormFieldFormDTO } from '../../interfaces'
import { countBy } from 'lodash'
import { getMaxCharsErrorMessage, validationMessages } from 'utils/constants/forms/validationMessages'
import { MAX_CHECKBOX_LENGTH, MAX_TITLE_LENGTH, MAX_VALUE_LENGTH } from '../../constants/validationCharLengths'

const { REQUIRED } = validationMessages

const dropdownOptionsTranslationsValidation = Yup.array()
  .of(
    Yup.object().shape({
      title: Yup.string().required(REQUIRED).max(MAX_TITLE_LENGTH, getMaxCharsErrorMessage(MAX_TITLE_LENGTH)),
      fieldLabelTranslation: Yup.string().max(MAX_TITLE_LENGTH, getMaxCharsErrorMessage(MAX_TITLE_LENGTH)),
      code: Yup.string().required(REQUIRED),
    }),
  )
  .required(REQUIRED)

const checkboxOptionsTranslationsValidation = Yup.array()
  .of(
    Yup.object().shape({
      title: Yup.string().required(REQUIRED).max(MAX_CHECKBOX_LENGTH, getMaxCharsErrorMessage(MAX_CHECKBOX_LENGTH)),
      fieldLabelTranslation: Yup.string().max(MAX_CHECKBOX_LENGTH, getMaxCharsErrorMessage(MAX_CHECKBOX_LENGTH)),
      code: Yup.string().required(REQUIRED),
    }),
  )
  .required(REQUIRED)

export const formFieldTypeValidation = Yup.object().shape({
  identitySpaceId: Yup.string().when('inputType', {
    is: 'text',
    then: Yup.string().notRequired(), // change this validation logic to require Identifier id when input type is text
    otherwise: Yup.string().notRequired(),
  }),
  inputType: Yup.string().required(REQUIRED),
  variant: Yup.string().when('inputType', {
    is: 'text',
    then: Yup.string().required(REQUIRED),
    otherwise: Yup.string().notRequired(),
  }),
  dropdownOptions: Yup.array().when('inputType', {
    is: 'dropdown',
    then: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(REQUIRED),
        label: Yup.string().required(REQUIRED).max(MAX_TITLE_LENGTH, getMaxCharsErrorMessage(MAX_TITLE_LENGTH)),
        value: Yup.string()
          .min(2, 'Value must be at least 2 characters')
          .max(MAX_VALUE_LENGTH, getMaxCharsErrorMessage(MAX_VALUE_LENGTH))
          .matches(
            validationRegExp.START_FROM_UPPER_OR_LOWER_CASE,
            'Value must start with an upper or lowercase letter',
          )
          .matches(
            validationRegExp.SPECIAL_SYMBOLS,
            'Value must only use uppercase and lowercase characters, numbers, underscores and colons',
          )
          .test(
            'Test for value uniqueness amongst dropdown options',
            'Value must be unique.',
            (value, context: any) => {
              let isValid = true
              /* don't check uniqueness if value is empty */
              if (!value) return true

              const allValues =
                (context?.from?.[1]?.value as FormFieldFormDTO)?.dropdownOptions
                  ?.map(option => option.value)
                  ?.filter(Boolean) || []

              const occurrenceMap = countBy(allValues)

              if (occurrenceMap[value] > 1) isValid = false

              return isValid
            },
          )
          .required('Please input a value'),
        translations: dropdownOptionsTranslationsValidation,
      }),
    ),
    otherwise: Yup.array().notRequired(),
  }),

  checkboxOptions: Yup.array().when('inputType', {
    is: 'checkbox',
    then: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(REQUIRED),
        label: Yup.string().required(REQUIRED).max(MAX_CHECKBOX_LENGTH, getMaxCharsErrorMessage(MAX_CHECKBOX_LENGTH)),
        value: Yup.string()
          .min(2, 'Value must be at least 2 characters')
          .max(MAX_CHECKBOX_LENGTH, getMaxCharsErrorMessage(MAX_CHECKBOX_LENGTH))
          .matches(
            validationRegExp.START_FROM_UPPER_OR_LOWER_CASE,
            'Value must start with an upper or lowercase letter',
          )
          .matches(
            validationRegExp.SPECIAL_SYMBOLS,
            'Value must only use uppercase and lowercase characters, numbers, underscores and colons',
          )
          .test(
            'Test for value uniqueness amongst checkbox options',
            'Value must be unique.',
            (value, context: any) => {
              let isValid = true
              /* don't check uniqueness if value is empty */
              if (!value) return true

              const allValues =
                (context?.from?.[1]?.value as FormFieldFormDTO)?.checkboxOptions
                  ?.map(option => option.value)
                  ?.filter(Boolean) || []

              const occurrenceMap = countBy(allValues)

              if (occurrenceMap[value] > 1) isValid = false

              return isValid
            },
          )
          .required('Please input a value'),
        translations: checkboxOptionsTranslationsValidation,
      }),
    ),
    otherwise: Yup.array().notRequired(),
  }),
})
