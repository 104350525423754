import { Icon } from '@ketch-com/deck'
import { RightMappingDetail } from '@ketch-sdk/ketch-types'
import { Box, Typography, styled } from '@mui/material'
import { getIn } from 'formik'
import { clamp } from 'lodash'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { BUILDER_CONSTANTS } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'
import { CanonicalRightCodeLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import React, { useContext } from 'react'

const RequestTypesBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '40px',
})

const RequestTypesTitleBox = styled(Box)({})

const RequestTypesListBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const RequestTypesListItemBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '56px',
  padding: '16px',
})

export const RequestsHomeTypes: React.FC = () => {
  const { themeConfig, experienceConfig } = useContext(BuilderContext)

  // Theme config
  const listTitleColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabHomeRightsListTitleColor)
  const listItemTitleColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabHomeRightsListItemTitleColor)
  const listItemArrowIconColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabHomeRightsListItemArrowIcon,
  )
  const listItemBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabHomeRightsListItemBackground,
  )
  const listItemCornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabHomeRightsListItemCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'

  // Experience config
  const title = getIn(experienceConfig, experienceUpsertFormKeys.preferenceRequestsTabHomeRightsTitle)
  const details = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsDetails,
  ) as RightMappingDetail[]

  return (
    <RequestTypesBox>
      <RequestTypesTitleBox>
        <Typography fontSize={'18px'} fontWeight={600} color={listTitleColor}>
          {title}
        </Typography>
      </RequestTypesTitleBox>
      <RequestTypesListBox>
        {details.map(({ canonicalRight }) => (
          <>
            <RequestTypesListItemBox sx={{ borderRadius: listItemCornerRadius, background: listItemBackgroundColor }}>
              <Typography fontSize={'16px'} fontWeight={700} color={listItemTitleColor}>
                {canonicalRight ? CanonicalRightCodeLabels[canonicalRight] : ''}
              </Typography>
              <Icon name={'OArrowCRight'} iconColor={listItemArrowIconColor} />
            </RequestTypesListItemBox>
          </>
        ))}
      </RequestTypesListBox>
    </RequestTypesBox>
  )
}
