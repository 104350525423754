import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchRightInvocationsV2 } from 'api/rightInvocationsV2/fetchers/fetchRightInvocationsV2'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.rightInvocationsPaginatedV2,
  queryFn: fetchRightInvocationsV2,
  select: res => res?.data?.rightInvocations || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useRightInvocationsPaginatedV2 = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
