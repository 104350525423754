import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { RelatedPath } from 'pages/assetManager/dsrConfig/interfaces'
import { getRelatedDataset } from 'pages/assetManager/dsrConfig/utils'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { getAssetType } from 'pages/assetManager/utils'

type Props = {
  relatedPaths: RelatedPath[]
  assetsSummary: AssetSummaryDTO[]
}

export const RelatedDatasetName: React.FC<Props> = ({ relatedPaths, assetsSummary }) => {
  const { datasetName: relatedDataSetName, technology } = getRelatedDataset(relatedPaths[0])
  const providerCode = assetsSummary?.[0]?.asset?.connection?.providerCode || ''
  const datasetType = getAssetType(providerCode, 2)

  const relatedDataSetType = (
    <Box display="flex" alignItems="center" gap={0.5} mt={0.5}>
      <img src={getAssetCloudImgUrl(technology)} alt={technology} width={15} height={15} />
      <Text size={11} weight={500} sx={{ textTransform: 'capitalize' }}>
        {datasetType}
      </Text>
    </Box>
  )
  return <NameAndCodeCellRenderer name={relatedDataSetName} code={relatedDataSetType} />
}
