import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { WorkflowExecutionAutomatedStepReassignFormValues } from 'pages/orchestration/rightsQueue/viewV2/components/RightsQueueWorkflowTab/components/reassignAutomatedStep/utils'
import { showToast } from 'components/modals/AlertComponent'
import { useUpdateWorkflowExecutionStepAssignee } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStepAssignee'

export const useReassignWorkflowStep = (workflowExecutionID: string, stepID: string, onClose = () => {}) => {
  const queryClient = useQueryClient()

  const { mutateAsync: handleUpdateWorkflowExecutionStepAssignee } = useUpdateWorkflowExecutionStepAssignee({
    onSuccess: async () => {
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      showToast({ content: 'Activity reassigned', type: 'success' })
      onClose()
    },
    onError: () => {
      showToast({ content: 'Failed to reassign activity', type: 'error' })
    },
  })

  const onSubmit = async (values: WorkflowExecutionAutomatedStepReassignFormValues) => {
    await handleUpdateWorkflowExecutionStepAssignee({
      params: {
        workflowExecutionId: workflowExecutionID,
        stepId: stepID,
        assigneeId: values.assigneeId,
      },
    })

    await queryClient.refetchQueries([
      ApiQueryKeys.workflowExecution,
      {
        workflowExecutionId: workflowExecutionID,
      },
    ])

    await queryClient.refetchQueries([
      ApiQueryKeys.workflowExecutionStepDetails,
      {
        workflowExecutionId: workflowExecutionID,
        stepId: stepID,
      },
    ])
  }

  return onSubmit
}
