import React, { createContext } from 'react'
import { DecisionGatewayUtils, useDecisionGatewayUtils } from '../hooks'

export const DecisionGatewayContext = createContext({} as DecisionGatewayUtils)

type Props = {
  children?: React.ReactNode
}

export const DecisionGatewayContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useDecisionGatewayUtils()

  return (
    <DecisionGatewayContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </DecisionGatewayContext.Provider>
  )
}

export const withDecisionGatewayContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <DecisionGatewayContextProvider>
        <Component {...props} />
      </DecisionGatewayContextProvider>
    )
  }
}
