import { ApplicationDTO } from 'interfaces/applications/application'

export const getDeploymentEnvironments = (environments?: ApplicationDTO['environments']) => {
  const totalCount = environments?.length ?? 0
  const selectedCount = !!environments ? environments.map(({ deployment }) => deployment).filter(Boolean).length : 0
  return {
    totalCount,
    selectedCount,
  }
}
