import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface PaginationState {
  pagination: { [key: string]: number }
  rootPathString: string
}

const initialState = {
  pagination: {},
  rootPathString: '',
} as PaginationState

export const paginationPreservationSlice = createSlice({
  name: 'paginationPreservationSlice',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<{ key: string; value: number }>) => {
      const newPaginationState = { ...state.pagination, [action.payload.key]: action.payload.value }
      state.pagination = newPaginationState
    },

    setRootPathString: (state, action: PayloadAction<string>) => {
      state.rootPathString = action.payload || ''
    },
  },
})

export const { setPage, setRootPathString } = paginationPreservationSlice.actions
export const RIGHTS_QUEUE_PAGINATION_REDUX_KEY = 'orchestration/rights-queue'

export default paginationPreservationSlice.reducer
