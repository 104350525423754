import type { HandleDeleteParams, HandleDownloadOnClickParams } from './DropZone.types'
import { showToast } from 'components/ui-kit/toastr/Toastr'

/**
 * Handles the deletion of a file.
 *
 * @param params - The deletion parameters.
 */
export const handleDelete = async ({
  attachment,
  value,
  handleDeleteFile,
  // setValue,
  onDelete,
}: HandleDeleteParams) => {
  try {
    await handleDeleteFile({
      params: {
        fileUrl: attachment.download_url,
      },
    })
    showToast({ content: `Removed file ${attachment.name}`, type: 'success' }) // Show success message.
    if (onDelete) onDelete(attachment) // Execute additional deletion logic, if provided.
  } catch (error) {
    showToast({ content: `Unable to remove file ${attachment.name}`, type: 'error' }) // Show error message.
  }
  // setValue(value.filter(v => v.ID !== attachment.ID)) // Remove the deleted file from current files.
}

/**
 * Handles the download of a file when clicked.
 *
 * @param params - The download parameters.
 */
export const handleDownloadOnClick = async ({ attachment, handleDownloadFile }: HandleDownloadOnClickParams) => {
  try {
    const { data } = await handleDownloadFile({
      params: {
        fileUrl: attachment.download_url, // Pass the file URL for download.
      },
    })
    let a: HTMLAnchorElement | null = document.createElement('a')
    a.href = URL.createObjectURL(data)
    a.download = attachment.name
    a.click()
    a = null
    showToast({ content: `Downloaded file ${attachment.name}`, type: 'success' }) // Show success message.
  } catch (error) {
    showToast({ content: `Unable to download file ${attachment.name}`, type: 'error' }) // Show error message.
  }
}
