import { useLanguages } from 'api/languages/queries/useLanguages'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { useMemo } from 'react'

export const useEnabledOrganizationLanguages = () => {
  const { data: languages, isLoading: isLoadingLanguages } = useLanguages()
  const { data: organizationLanguages, isLoading: isLoadingOrganizationLanguages } = useOrganizationLanguages()

  const isLoadingEnabledLanguages = isLoadingLanguages || isLoadingOrganizationLanguages

  const enabledLanguages = useMemo(() => {
    return (languages || []).filter(
      language =>
        organizationLanguages?.find(organizationLanguage => organizationLanguage.language.code === language.code),
    )
  }, [languages, organizationLanguages])

  return { enabledLanguages, isLoadingEnabledLanguages, languages, organizationLanguages }
}
