import React from 'react'

import { TableCell } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
// types
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { Box, Typography } from '@mui/material'
import { DataGrid } from '@ketch-com/deck'
import { FormInlineEdit } from 'components/form/FormInlineEdit'
type Props = {
  isEditMode?: boolean
  languages: LanguageWithStatusDTO[]
}

export const DataSubjectTypeLanguages: React.FC<Props> = ({ languages }) => (
  <Box display="flex" flexDirection="column" gap={2}>
    <Typography variant="h4">Translations</Typography>
    <DataGrid
      autosizeOnMount
      columns={[
        {
          field: 'name',
          headerName: 'Language',
          flex: 0.5,
          sortable: false,
          align: 'left',
          headerAlign: 'left',
          renderCell: ({ row }: GridRenderCellParams<LanguageWithStatusDTO>) => (
            <TableCell title={row.language.englishName} />
          ),
        },
        {
          field: 'translation',
          headerName: 'Translation',
          flex: 1,
          sortable: false,
          align: 'left',
          headerAlign: 'left',
          renderCell: ({ row: { language } }: GridRenderCellParams<LanguageWithStatusDTO>) => (
            <TableCell>
              <FormInlineEdit
                formPropertyName={`translations[${language.code}]`}
                placeholder="Custom"
                required
                type="string"
                shouldUpdateDebounced
                onKeyDown={e => e.stopPropagation()}
              />
            </TableCell>
          ),
        },
      ]}
      rows={languages || []}
      disableChildrenSorting
      disableColumnMenu
      disableColumnPinning
      disableColumnReorder
      disableColumnResize
      disableRowHoverStates
      disableRowSelectionOnClick
      disableMultipleRowSelection
      getRowHeight={() => 'auto'}
      getRowId={row => row.language.code}
      hideFooter
      noRowsOverlayProps={{
        title: 'No Languages Added',
        iconName: 'OGlobe',
        subTitle: '',
        buttonTitle: '',
      }}
    />
  </Box>
)
