import React from 'react'
import { Box, Grid } from '@mui/material'

import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { ManageInferredLabelsInput, ManageLabelsInput } from 'pages/assetManager/components'
import { useEditAttributeFormUtils } from '../hooks'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { MaybeNull } from 'interfaces/common'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { FormTextArea } from 'components/ui-kit/form/textArea/FormTextArea'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { PERSONAL_DATA_CATEGORY_OPTIONS } from 'pages/assetManager/constants'
import { ASSET_TYPE_ENUM } from 'interfaces/assets'

type Props = {
  assetSummary?: MaybeNull<AssetSummaryDTO>
}

export const EditAttributeForm: React.FC<Props> = ({ assetSummary }) => {
  const _ = useEditAttributeFormUtils()

  if (_.isLoading)
    return (
      <Box my={4}>
        <Spinner />
      </Box>
    )

  return (
    <>
      <FormSection title="Basic Details">
        <FormRow>
          <Grid container>
            <Grid item xs={6}>
              <FormInput
                required
                disabled
                fullWidth
                name="name"
                label="Name"
                placeholder="e.g. Overarching policy applicable to all customer data."
              />
            </Grid>
          </Grid>
        </FormRow>

        <FormRow>
          <Grid container spacing={4}>
            <Grid item xs={9}>
              <FormTextArea
                fullWidth
                name="description"
                label="Description"
                placeholder="Describe the asset contents..."
              />
            </Grid>
          </Grid>
        </FormRow>

        <FormRow>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormDropdown
                label="Data Sensitivity"
                required
                fullWidth
                name="dataCategory"
                placeholder="Select Data Type"
                items={PERSONAL_DATA_CATEGORY_OPTIONS}
              />
            </Grid>

            <Grid item xs={3}>
              <FormDropdown
                label="Data Owner"
                valueKey="name"
                required
                fullWidth
                name="dataOwner"
                placeholder="Select Data Owner"
                items={_.users.map(u => ({
                  id: u.ID,
                  name: u?.firstName && u?.lastName ? `${u.firstName} ${u.lastName}` : u.email,
                }))}
              />
            </Grid>
          </Grid>
        </FormRow>
      </FormSection>

      <FormSection title="Assign Classifications" subTitle="Select classifications from the list.">
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <ManageLabelsInput assetCode={assetSummary?.asset?.resource?.id || ''} />
          </Grid>

          <Grid item xs={3} />

          <Grid item xs={9}>
            <ManageInferredLabelsInput assetCode={assetSummary?.asset?.resource?.id || ''} />
          </Grid>
        </Grid>
      </FormSection>

      {assetSummary?.asset?.resource?.resourceType?.canonicalResourceType ===
        ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_ATTRIBUTE && (
        <FormSection
          title="Identifier"
          isOptional
          subTitle="Assign the Identifier to this attribute so that you can enable DSR automation in the workflow builder."
        >
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <FormDropdown
                label="Identifier"
                required
                fullWidth
                name="identitySpace"
                placeholder="None"
                items={[
                  {
                    id: '',
                    name: 'None',
                  },
                  ..._.identitySpaceOptions,
                ]}
              />
            </Grid>
          </Grid>
        </FormSection>
      )}
    </>
  )
}
