import React from 'react'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'

import { IdentitySpaceFormDTO, IdentitySpacesCatalogDTO } from 'interfaces/identitySpaces/identitySpace'
import { formatCodeFromName } from 'utils/formatters'
import { FormInput } from 'components/form/FormInput'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { Box } from '@mui/material'

type Props = {
  isEditMode: boolean
  identitySpacesCatalog: IdentitySpacesCatalogDTO
  isModalForm?: boolean
}

export const IdentitySpaceBasicDetails: React.FC<Props> = ({
  isEditMode,
  identitySpacesCatalog,
  isModalForm = false,
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<IdentitySpaceFormDTO>()

  // KD-1075 - When user selects "Email" - we should disable "Raw" option for typeCode dropdown
  const formatsFiltered =
    identitySpacesCatalog?.formats?.filter(format => format.code !== 'raw' || values.typeCode !== 'email') || []

  return (
    <Box display="flex" flexDirection="column" mb={6} mt={2}>
      <Grid container gap={4}>
        <Grid item xs={isModalForm ? 12 : 6}>
          <FormInput
            fullWidth
            required
            formPropertyName="name"
            label="Name"
            placeholder="Add a recognizable Identifier name"
            onChange={e => {
              if (!isEditMode) {
                setFieldTouched('code', true)
                setFieldValue('code', formatCodeFromName(e.target.value))
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={isModalForm ? 12 : 6}>
              <FormInput
                fullWidth
                required
                disabled={isEditMode}
                formPropertyName="code"
                label="Code"
                placeholder="Add a unique code to reference your Identifier"
              />
            </Grid>

            <Grid item xs={isModalForm ? 6 : 3} mt="3px">
              <FormDroplistButton
                disableClearable
                required
                fullWidth
                valueKey="code"
                placeholder="Select type"
                formPropertyName="typeCode"
                label="Type"
                items={identitySpacesCatalog?.types || []}
                onItemClick={nextValue => {
                  if (nextValue.code === 'email') {
                    setFieldValue('formatCode', formatsFiltered?.[0]?.code)
                  }
                }}
              />
            </Grid>

            <Grid item xs={isModalForm ? 6 : 3} mt="3px">
              <FormDroplistButton
                disableClearable
                required
                fullWidth
                valueKey="code"
                placeholder="Select format"
                formPropertyName="formatCode"
                label="Format"
                items={formatsFiltered}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
