import { ReactComponent as PersonAvatarSmall } from 'assets/icons/person-avatar-small.svg'
import { RoutesManager } from 'utils/routing/routesManager'
import { DataRiskDetailMenu } from '../../components/layout/DataRiskDetailMenu'
import { useStyles } from '../../components/layout/styles'
import { OverAccessData } from '../../__mocks__/overAccess'

type Props = {
  rows: OverAccessData[]
}

export const OverAccessMenu: React.FC<Props> = ({ rows }) => {
  const classes = useStyles()
  return (
    <DataRiskDetailMenu
      route={RoutesManager.insights.dataRisks.overAccess.view.root}
      items={rows.map(asset => ({
        id: asset.id || '',
        text: asset.name || '',
        score: asset.riskScore || 0,
        icon: <PersonAvatarSmall className={classes.icon} />,
      }))}
    />
  )
}
