import React from 'react'
import * as Yup from 'yup'
import { useFormik, FormikProvider } from 'formik'
import type { RangeStatic } from 'quill'
import ReactQuill from 'react-quill'
import { Modal } from 'components/ui-kit/modal/Modal'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRadioGroup } from 'components/ui-kit/form/radioGroup/FormRadioGroup'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { Box } from '@mui/material'
import { LINK_URL_TYPE_OPTIONS, LinkUrlType } from '../constants'

type Props = {
  isEditMode?: boolean
  quillRef: React.RefObject<ReactQuill>
  range: RangeStatic | null
  setRange: React.Dispatch<React.SetStateAction<RangeStatic | null>>
  excludedLinkUrlTypes?: LinkUrlType[]
}

export const DocumentLinkerModal: React.FC<Props> = ({
  isEditMode = false,
  range,
  setRange,
  quillRef,
  excludedLinkUrlTypes = [],
}) => {
  const onSubmit = (values: { linkUrl?: string; customUrl?: string }) => {
    const { linkUrl, customUrl } = values

    if (!quillRef.current || !range) return

    const quill = quillRef.current.getEditor()

    let linkValue: string | null = null

    switch (linkUrl) {
      case LinkUrlType.CUSTOM:
        if (customUrl) linkValue = customUrl
        break
      case LinkUrlType.PRIVACY_POLICY:
        if (!customUrl) linkValue = 'privacyPolicy'
        break
      case LinkUrlType.TERMS_OF_SERVICE:
        if (!customUrl) linkValue = 'termsOfService'
        break
      case LinkUrlType.TRIGGER_MODAL:
        if (!customUrl) linkValue = 'triggerModal'
        break
    }

    if (linkValue) {
      quill.setSelection(range.index, range.length)
      quill.format('link', linkValue)
      setRange(null)
    }
  }

  const formik = useFormik({
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      customUrl: Yup.string()
        .trim()
        .when('linkUrl', {
          is: LinkUrlType.CUSTOM,
          then: Yup.string().url('Invalid url').required('Required'),
        }),
    }),
    initialValues: {
      linkUrl: '',
      customUrl: '',
    },
    onSubmit,
  })

  const { values, submitForm } = formik

  return (
    <FormikProvider value={formik}>
      <Modal
        title={isEditMode ? 'Edit Link' : 'Link Document'}
        onCancelBtnClick={() => setRange(null)}
        submitBtnLabel="Confirm"
        onSubmitBtnClick={submitForm}
      >
        <Box mt={2.25}>
          <FormRow>
            <FormRadioGroup
              label="Link to"
              name="linkUrl"
              layout="column"
              options={LINK_URL_TYPE_OPTIONS.filter(option => !excludedLinkUrlTypes.includes(option.value))}
            />
          </FormRow>

          {values.linkUrl === LinkUrlType.CUSTOM && (
            <FormRow>
              <FormInput required fullWidth name="customUrl" placeholder="Specify link URL here" />
            </FormRow>
          )}
        </Box>
      </Modal>
    </FormikProvider>
  )
}

type RenderDocumentLinkerModalType = (
  quillRef: React.RefObject<ReactQuill>,
  range: RangeStatic | null,
  setRange: React.Dispatch<React.SetStateAction<RangeStatic | null>>,
) => React.ReactNode

export const renderDocumentLinkerModal: RenderDocumentLinkerModalType = (quillRef, range, setRange) => {
  if (range) {
    return <DocumentLinkerModal quillRef={quillRef} range={range} setRange={setRange} />
  }
  return null
}

// Linker modal that doesn't have the "Trigger Modal" option
export const renderDocumentLinkerModalExcludeModal: RenderDocumentLinkerModalType = (quillRef, range, setRange) => {
  if (range) {
    return (
      <DocumentLinkerModal
        quillRef={quillRef}
        range={range}
        setRange={setRange}
        excludedLinkUrlTypes={[LinkUrlType.TRIGGER_MODAL]}
      />
    )
  }
  return null
}
