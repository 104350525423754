import { createUseQuery } from 'api/common/query'
import { ApiQueryKeys } from '../../common/queryKeys'
import { useAuth } from '../../../utils/hooks'
import { fetchProcessingActivitiesSummary } from '../fetchers/fetchProcessingActivitiesSummary'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.processingActivitiesSummary,
  queryFn: fetchProcessingActivitiesSummary,
  select: res => res?.data || {},
})

export const useProcessingActivitiesSummary = () => {
  const { userData } = useAuth()

  return useCustomQuery({
    params: {
      organizationCode: userData.organizationCode,
    },
  })
}
