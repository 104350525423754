import {
  inherentRiskLevelOptions,
  PersonalDataTypeOptions,
  securityMeasureOptions,
  TypeListFilterOptions,
} from 'pages/dataSystems/DataSystem/types'
import { FiltersData } from './SystemListFilter'
import { toTitleCase } from '../utils/toTitleCase'

export const initialFiltersData: FiltersData = {
  // Properties set to an empty array are dynamically generated
  ownerUserIDs: [],
  personalDataType: Object.keys(PersonalDataTypeOptions).map(option => ({
    name: PersonalDataTypeOptions[option].title as string,
    isSelected: false,
    id: option,
  })),
  inherentRisk: Object.keys(inherentRiskLevelOptions).map(option => ({
    name: inherentRiskLevelOptions[option].title,
    isSelected: false,
    id: option,
  })),
  assignedCategories: [],
  residencyCountries: [],
  assignedPurposes: [],
  type: TypeListFilterOptions.map(option => ({
    name: option.title,
    isSelected: false,
    id: `${option.value}`,
  })),
  residencySecurityMeasures: Object.keys(securityMeasureOptions).map(option => ({
    name: toTitleCase(securityMeasureOptions[parseInt(option)].title as string),
    isSelected: false,
    id: option,
  })),
  hasCredentialIssue: [
    { name: 'Has Issues', isSelected: false, id: 'true' },
    // unsupported
    // {name: 'No Issues', isSelected: false, id: 'false'},
  ],
  incompleteProgress: [
    { name: 'Incomplete', isSelected: false, id: 'true' },
    // unsupported
    // {name: 'Complete', isSelected: false, id: 'false'},
  ],
}

export const initialCounts = {
  ownerUserIDs: 0,
  personalDataType: 0,
  type: 0,
  assignedCategories: 0,
  inherentRisk: 0,
  residencyCountries: 0,
  hasCredentialIssue: 0,
  incompleteProgress: 0,
  assignedPurposes: 0,
  residencySecurityMeasures: 0,
}

export const filterDisplayNameMap: Record<string, string> = {
  ownerUserIDs: 'Owners',
  personalDataType: 'Data Sensitivity',
  type: 'System Types',
  assignedCategories: 'Data Categories',
  inherentRisk: 'Inherent Risk',
  residencyCountries: 'Data Residencies',
  hasCredentialIssue: 'Issues',
  incompleteProgress: 'Progress',
  assignedPurposes: 'Assigned Purposes',
  residencySecurityMeasures: 'Security Measures',
}
