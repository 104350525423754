import React, { useState } from 'react'
import { Box, Container, Typography, Autocomplete, TextField } from '@mui/material'
import { Outlet, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

type MenuItemData = {
  label: string
  url: string
}

const menuItems: Array<MenuItemData> = [
  { label: 'Avatar', url: RoutesManager.developmentUtils.sandbox.avatar.getURL() },
  {
    label: 'Autocomplete Menu Single Select',
    url: RoutesManager.developmentUtils.sandbox.autoCompleteMenuSingleSelectExample.getURL(),
  },
  {
    label: 'Autocomplete Menu Multi Select',
    url: RoutesManager.developmentUtils.sandbox.autoCompleteMenuMultiSelectExample.getURL(),
  },
  {
    label: 'Autocomplete Compare Contrast',
    url: RoutesManager.developmentUtils.sandbox.autocompleteCompareContrast.getURL(),
  },
  { label: 'Autocomplete Inline Edit', url: RoutesManager.developmentUtils.sandbox.autocompleteInlineEdit.getURL() },
  { label: 'Buttons', url: RoutesManager.developmentUtils.sandbox.buttons.getURL() },
  { label: 'DataGrid Custom Filters', url: RoutesManager.developmentUtils.sandbox.dataGridCustomFilters.getURL() },
  { label: 'DataGrid Filters', url: RoutesManager.developmentUtils.sandbox.dataGridFilters.getURL() },
  { label: 'DataGrid Expandable', url: RoutesManager.developmentUtils.sandbox.dataGridExpandableExample.getURL() },
  { label: 'Date Picker', url: RoutesManager.developmentUtils.sandbox.datePickerExample.getURL() },
  { label: 'Date Range Picker', url: RoutesManager.developmentUtils.sandbox.dateRangePickerExample.getURL() },
  { label: 'Dropzone', url: RoutesManager.developmentUtils.sandbox.dropzone.getURL() },
  { label: 'Inline Edit', url: RoutesManager.developmentUtils.sandbox.inlineEdit.getURL() },
  {
    label: 'Info Row',
    url: RoutesManager.developmentUtils.sandbox.infoRow.getURL(),
  },
  { label: 'Modal', url: RoutesManager.developmentUtils.sandbox.modalImplementationExample.getURL() },
  { label: 'Segment Tabs', url: RoutesManager.developmentUtils.sandbox.segmentTabsExample.getURL() },
  { label: 'Status', url: RoutesManager.developmentUtils.sandbox.status.getURL() },
  { label: 'Tabs', url: RoutesManager.developmentUtils.sandbox.tabsExample.getURL() },
  { label: 'DropListAsyncButton', url: RoutesManager.developmentUtils.sandbox.dropListButton.async.getURL() },
  { label: 'DropListButton', url: RoutesManager.developmentUtils.sandbox.dropListButton.getURL() },
  { label: 'Progress', url: RoutesManager.developmentUtils.sandbox.progress.getURL() },
  {
    label: 'Data Grid Infinity Scroll',
    url: RoutesManager.developmentUtils.sandbox.dataGridInfinityScrollExample.getURL(),
  },
  {
    label: 'Data Grid Infinity Browser Scroll',
    url: RoutesManager.developmentUtils.sandbox.dataGridInfinityBrowserScrollExample.getURL(),
  },
  {
    label: 'Data Grid Infinity Waypoint Scroll',
    url: RoutesManager.developmentUtils.sandbox.dataGridInfinityWaypointScrollExample.getURL(),
  },
  { label: 'Data Grid Pagination', url: RoutesManager.developmentUtils.sandbox.dataGridPaginationExample.getURL() },
  {
    label: 'Data Grid Part of Layout Example',
    url: RoutesManager.developmentUtils.sandbox.dataGridPartOfLayoutExample.getURL(),
  },
  {
    label: 'Content Group Part of Layout Example',
    url: RoutesManager.developmentUtils.sandbox.contentGroupPartOfLayoutExample.getURL(),
  },
  {
    label: 'Banner',
    url: RoutesManager.developmentUtils.sandbox.bannerExample.getURL(),
  },
  {
    label: 'Create Layout Form Example',
    url: RoutesManager.developmentUtils.sandbox.createLayoutFormExample.getURL(),
  },
  {
    label: 'Edit Layout Form Example',
    url: RoutesManager.developmentUtils.sandbox.editLayoutFormExample.getURL(),
  },
  {
    label: 'Settings Layout Example',
    url: RoutesManager.developmentUtils.sandbox.settingsLayoutExample.getURL(),
  },
  {
    label: 'View Layout Example',
    url: RoutesManager.developmentUtils.sandbox.viewLayoutExample.getURL(),
  },
  {
    label: 'Card Example',
    url: RoutesManager.developmentUtils.sandbox.cardExamples.getURL(),
  },
  {
    label: 'Label Chip',
    url: RoutesManager.developmentUtils.sandbox.labelChipExample.getURL(),
  },
  {
    label: 'Text Input Color',
    url: RoutesManager.developmentUtils.sandbox.textInputColor.getURL(),
  },
  {
    label: 'Drop Zone',
    url: RoutesManager.developmentUtils.sandbox.dropZoneExample.getURL(),
  },
  { label: 'Mega Filter', url: RoutesManager.developmentUtils.sandbox.megaFitlerExample.getURL() },
  { label: 'Chips Autocomplete', url: RoutesManager.developmentUtils.sandbox.chipsAutocompleteExample.getURL() },
]

type Props = {}

export const DeveloperSandboxContainer: React.FC<Props> = () => {
  const navigate = useNavigate()
  const [route, setRoute] = useState<string>('')

  return (
    <Container maxWidth="xl">
      <Box display="flex" flexDirection="column" p={3} borderRadius={2} bgcolor="white.main">
        <Box display="flex" alignItems="center" gap={2} mb={3}>
          <Typography variant="h1">Developer Sandbox</Typography>
          <Autocomplete
            fullWidth
            sx={{
              maxWidth: 600,
            }}
            size="small"
            options={menuItems}
            getOptionLabel={option => option.label}
            value={menuItems.find(item => item.url === route)}
            onChange={(event, newValue) => {
              if (newValue) {
                setRoute(newValue.url)
                navigate(newValue.url)
              }
            }}
            renderInput={params => <TextField {...params} label="Select a component demo" />}
          />
        </Box>
        <Outlet />
      </Box>
    </Container>
  )
}
