import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormControlLabel, Typography } from '@mui/material'
import { FormColorInput } from '../FormColorInput'
import { FormCheckbox } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormCheckbox'

export const PreferenceThemeRequestsTabHomeHeaderForm: React.FC = () => {
  return (
    <SidebarFormGroup sx={{ pt: 0, gap: '16px' }}>
      <FormColorInput
        name={themeUpsertFormKeys.preferenceRequestsTabHomeHeaderTitleColor}
        label={'Page Title Color'}
        fullWidth
        required
      />
      <FormColorInput
        name={themeUpsertFormKeys.preferenceRequestsTabHomeHeaderDescriptionColor}
        label={'Description Color'}
        fullWidth
        required
      />
      <FormColorInput
        name={themeUpsertFormKeys.preferenceRequestsTabHomeHeaderLinkColor}
        label={'Link Color'}
        fullWidth
        required
      />
      <FormControlLabel
        control={<FormCheckbox name={themeUpsertFormKeys.preferenceRequestsTabHomeHeaderLinkUnderline} />}
        label={<Typography variant={'label'}>Underline Links</Typography>}
      />
    </SidebarFormGroup>
  )
}
