import React, { createContext } from 'react'
import { UseEndFlowUtils, useEndFlowUtils } from '../hooks'

export const EndFlowContext = createContext({} as UseEndFlowUtils)

type Props = {
  children?: React.ReactNode
}

export const EndFlowContextProvider: React.FC<Props> = ({ children }) => {
  // Use the hook
  const hookValues = useEndFlowUtils()

  return (
    <EndFlowContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </EndFlowContext.Provider>
  )
}

export const withFormEndFlowContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <EndFlowContextProvider>
        <Component {...props} />
      </EndFlowContextProvider>
    )
  }
}
