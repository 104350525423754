import { useEffect } from 'react'

import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useDataSubjectTypes } from 'api/dataSubjectTypes/queries/useDataSubjectTypes'
import { useIsPermitted } from 'utils/hooks'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { useRights } from 'api/rights/queries/useRights'
import { useUsers } from 'api/users/queries/useUsers'
import { useWorkflows } from 'api/workflows/queries/useWorkflows'
import { useFiltersV3 } from 'utils/hooks'
import { searchParamIgnoreArray } from 'utils/constants/searchParamIgnoreArray'
import { useRightsQueueListTableUtils } from '../components/RightsQueueListTable/hooks'

const WIDTH_LIMIT = 700

export const useRightsQueueContainerV2Utils = () => {
  const { isPermitted } = useIsPermitted()
  const rightsQueueListTableUtils = useRightsQueueListTableUtils()
  const { searchParamArray, removeQueryParamFromList, replaceParams, getParamValueByKey, removeMultipleSearchParams } =
    useFiltersV3()

  /* fetch data */
  const { isLoading: isLoadingUsers } = useUsers()
  const { data: workflows, isLoading: isLoadingWorkflows } = useWorkflows()
  const { data: jurisdictions, isLoading: isLoadingJurisdictions } = usePolicyScopes()
  const { data: users, isLoading: isUsersLoading } = useUsers({
    params: {
      active: true,
    },
  })
  const { data: rights, isLoading: isRightsLoading } = useRights()
  const { data: dataSubjectTypes, isLoading: isDataSubjectTypesLoading } = useDataSubjectTypes({
    params: {
      includeDeleted: true,
    },
  })

  /* derived values */
  const isPermittedToCreateRights = isPermitted(PERMISSIONS.RTINVQ_WRITE)
  const isReady =
    !isLoadingWorkflows &&
    !isLoadingJurisdictions &&
    !isLoadingUsers &&
    !isUsersLoading &&
    !isRightsLoading &&
    !isDataSubjectTypesLoading

  const filteredSearchParamArray = searchParamArray.filter(param => {
    const [key, value] = param
    if (searchParamIgnoreArray.includes(key) || !value) return false
    return true
  })

  /* This useEffect "keeps an eye" on the combined width of the filter chip elements and identifies a
    "splitIndex".  The split informs the UI which filters to display chips for and which get consolidated
    in the "more" chip that opens a popover containing the remaining filters when you click it.
    
    Because "state" ("splitIndex" search param) is being updated in this useEffect, the if/else logic on
    when to set the "splitIndex" is delicate.  Errors will result in infinite loops.  Be careful.
    */
  useEffect(() => {
    if (filteredSearchParamArray.length > 0) {
      const chips: HTMLDivElement[] = Array.from(document.querySelectorAll("[id='selected-filter-chip']"))
      let width = 0

      for (let index = 0; index < chips.length; index++) {
        const chip = chips[index]
        width += chip.offsetWidth

        if (width > WIDTH_LIMIT && !getParamValueByKey('splitIndex')) {
          replaceParams({ splitIndex: String(index) })
          break
        }

        if (
          width > WIDTH_LIMIT &&
          !!getParamValueByKey('splitIndex') &&
          index < parseInt(getParamValueByKey('splitIndex') || '0')
        ) {
          replaceParams({ splitIndex: String(index) })
          break
        }
      }
      if (width < WIDTH_LIMIT && !!getParamValueByKey('splitIndex')) {
        removeMultipleSearchParams(['splitIndex'])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSearchParamArray])

  const filterChips = filteredSearchParamArray
    .map(param => {
      const [key, value] = param
      return value.split(',').map(v => ({ [key]: v }))
    })
    .flat()
  /** An array of objects { key: value } of the few filter chips that will be displayed on the one line in the UI */
  const shown = filterChips.filter((param, index) =>
    !!parseInt(getParamValueByKey('splitIndex') || '0')
      ? parseInt(getParamValueByKey('splitIndex') || '0') >= index
      : true,
  )
  /** An array of objects { key: value } of the filters that will be contained in the "More" chip popover */
  const overflowFilters = filterChips.filter((param, index) =>
    !!parseInt(getParamValueByKey('splitIndex') || '0')
      ? parseInt(getParamValueByKey('splitIndex') || '0') < index
      : false,
  )

  return {
    dataSubjectTypes,
    filterChips,
    overflowFilters,
    isPermittedToCreateRights,
    isReady,
    jurisdictions,
    removeQueryParamFromList,
    rights,
    getParamValueByKey,
    rightsQueueListTableUtils,
    shown,
    users,
    workflows,
  }
}
