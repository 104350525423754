import { getRiskScoreDetails, RiskScoreInfo } from 'pages/insights/utils'
import { Line, XAxis, YAxis, ComposedChart, ResponsiveContainer } from 'recharts'

type LineChartRiskScoreInfo = {
  acceptable?: RiskScoreInfo
  tolerable?: RiskScoreInfo
  critical?: RiskScoreInfo
  extreme?: RiskScoreInfo
}

type RiskTrend = {
  month?: string
  riskScore?: number
}

type Props = {
  riskTrend: RiskTrend[] | undefined
}

export const LineChart: React.FC<Props> = ({ riskTrend }) => {
  if (!riskTrend) return null
  const lineChartRiskScoreInfo: LineChartRiskScoreInfo = {}
  riskTrend?.forEach?.(trend => {
    const riskScoreDetails = getRiskScoreDetails(trend.riskScore || 0)
    const category: keyof LineChartRiskScoreInfo =
      riskScoreDetails.category.toLocaleLowerCase() as keyof LineChartRiskScoreInfo
    lineChartRiskScoreInfo[category] = riskScoreDetails
  })
  const hasExtremeRisk = lineChartRiskScoreInfo['extreme']
  const hasCriticalRisk = lineChartRiskScoreInfo['critical']
  const hasTolerableRisk = lineChartRiskScoreInfo['tolerable']
  const hasAcceptableRisk = lineChartRiskScoreInfo['acceptable']
  const acceptableColor = lineChartRiskScoreInfo?.acceptable?.textColor || getRiskScoreDetails(20).textColor
  const tolerableColor = lineChartRiskScoreInfo?.tolerable?.textColor || getRiskScoreDetails(50).textColor
  const criticalColor = lineChartRiskScoreInfo?.critical?.textColor || getRiskScoreDetails(70).textColor
  const extremeColor = lineChartRiskScoreInfo?.extreme?.textColor || getRiskScoreDetails(90).textColor

  let gradientElement
  if (hasAcceptableRisk) gradientElement = <stop offset="100%" stopColor={acceptableColor} />
  if (hasTolerableRisk) {
    gradientElement = <stop offset="100%" stopColor={tolerableColor} />
    if (hasAcceptableRisk) {
      gradientElement = (
        <>
          <stop offset="80%" stopColor={tolerableColor} />
          <stop offset="100%" stopColor={acceptableColor} />
        </>
      )
    }
  }
  if (hasCriticalRisk) {
    gradientElement = <stop offset="100%" stopColor={criticalColor} />
    if (hasTolerableRisk) {
      gradientElement = (
        <>
          <stop offset="60%" stopColor={criticalColor} />
          <stop offset="80%" stopColor={tolerableColor} />
        </>
      )
    }
    if (hasAcceptableRisk) {
      gradientElement = (
        <>
          <stop offset="60%" stopColor={criticalColor} />
          <stop offset="80%" stopColor={tolerableColor} />
          <stop offset="100%" stopColor={acceptableColor} />
        </>
      )
    }
  }
  if (hasExtremeRisk) {
    gradientElement = <stop offset="100%" stopColor={extremeColor} />

    if (hasCriticalRisk) {
      gradientElement = (
        <>
          <stop offset="0%" stopColor={extremeColor} />
          <stop offset="60%" stopColor={criticalColor} />
        </>
      )
    }

    if (hasTolerableRisk) {
      gradientElement = (
        <>
          <stop offset="0%" stopColor={extremeColor} />
          <stop offset="60%" stopColor={criticalColor} />
          <stop offset="80%" stopColor={tolerableColor} />
        </>
      )
    }
    if (hasAcceptableRisk) {
      gradientElement = (
        <>
          <stop offset="0%" stopColor={extremeColor} />
          <stop offset="60%" stopColor={criticalColor} />
          <stop offset="80%" stopColor={tolerableColor} />
          <stop offset="100%" stopColor={acceptableColor} />
        </>
      )
    }
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={riskTrend}
        margin={{
          top: 10,
          right: 0,
          left: -20,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="linearGradientColors" x1="0%" y1="0%" x2="0%" y2="1">
            {gradientElement}
          </linearGradient>
        </defs>
        <XAxis dataKey="month" />
        <YAxis ticks={[25, 75, 100]} />
        <Line type="monotone" dataKey="riskScore" stroke="url(#linearGradientColors)" strokeWidth={2} dot={false} />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
