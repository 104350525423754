import React, { useState } from 'react'
import clsx from 'clsx'
import { CircularProgress, Popover, ButtonBase } from '@mui/material'

import { makeStyles, createStyles } from '@mui/styles'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { MaybeNull } from 'interfaces'
import { List, ListItemType as BaseListItemType } from 'components/ui-kit/list/List'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseDropList: {
        display: 'inline-flex',
        alignItems: 'center',
        fontFamily: typography.fontFamily,
        borderRadius: 5,
        letterSpacing: '0.01em',
        color: palette.darkDusk.main,
        textAlign: 'left',

        '& $progressIcon': {
          color: palette.darkDusk.main,
        },

        '&:not($disabled)': {
          '&:hover, &:focus': {
            background: palette.fadedGrey.main,
            color: palette.royalBlue.main,
            cursor: 'pointer',

            '& $chevronIcon': {
              color: palette.royalBlue.main,
            },

            '& $baseContent': {
              color: palette.royalBlue.main,
              fontWeight: 600,
            },
          },

          '&:active': {
            background: palette.fadedGrey.main,
            color: palette.persianBlue.main,
            cursor: 'pointer',

            '& $chevronIcon': {
              color: palette.persianBlue.main,
            },

            '& $baseContent': {
              color: palette.royalBlue.main,
              fontWeight: 600,
            },
          },
        },
      },
      baseContent: {
        flexGrow: 1,
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      baseIcon: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      baseIconLeft: {
        display: 'inline-flex',
        alignItems: 'center',
      },
      baseHint: {
        color: `${palette.darkDuskFaded.main} !important`,
      },
      fullWidth: {
        display: 'flex',
        width: '100%',
      },
      progressIcon: {},
      chevronIcon: {
        color: palette.darkDusk.main,
      },

      // Popover
      popoverRoot: {
        marginTop: 5,
        borderRadius: 5,
      },
      popover: {
        borderRadius: 5,
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.2)',
        minWidth: 170,
      },

      // Sizes:
      sizeSmall: {
        padding: '3px 10px',
        lineHeight: typography.pxToRem(14),
        fontSize: typography.pxToRem(12),
        fontWeight: 600,

        '& $baseIconLeft svg': {
          marginRight: 10,
          fontSize: typography.pxToRem(16),
          lineHeight: 1,
        },

        '& $baseHint': {
          fontSize: typography.pxToRem(11),
          fontWeight: 500,
        },

        '& $baseContent': {
          marginRight: 6,
        },

        '& $progressIcon': {
          marginRight: 3,
        },

        '& $chevronIcon': {
          marginTop: 2,
          fontSize: typography.pxToRem(24),
        },
      },
      sizeRegular: {
        padding: '7px 10px',
        lineHeight: typography.pxToRem(14),
        fontSize: typography.pxToRem(14),
        fontWeight: 600,

        '& $baseIconLeft svg': {
          marginRight: 10,
          fontSize: typography.pxToRem(16),
          lineHeight: 1,
        },

        '& $baseHint': {
          fontSize: typography.pxToRem(11),
          fontWeight: 500,
        },

        '& $baseContent': {
          marginRight: 8,
        },

        '& $progressIcon': {
          marginRight: 4,
        },

        '& $chevronIcon': {
          fontSize: typography.pxToRem(24),
        },
      },
      sizeBig: {
        padding: '10px',
        lineHeight: typography.pxToRem(16),
        fontSize: typography.pxToRem(16),
        fontWeight: 600,

        '& $baseIconLeft svg': {
          marginRight: 10,
          fontSize: typography.pxToRem(14),
          lineHeight: 1,
        },

        '& $baseHint': {
          fontSize: typography.pxToRem(11),
          fontWeight: 500,
        },

        '& $baseContent': {
          marginRight: 6,
        },

        '& $progressIcon': {
          marginRight: 3,
        },

        '& $chevronIcon': {
          marginTop: 2,
          fontSize: typography.pxToRem(24),
        },
      },
      sizeLarge: {
        padding: '12px 10px',
        lineHeight: typography.pxToRem(20),
        fontSize: typography.pxToRem(20),
        fontWeight: 600,

        '& $baseIconLeft svg': {
          marginRight: 10,
          fontSize: typography.pxToRem(18),
          lineHeight: 1,
        },

        '& $baseContent': {
          marginRight: 6,
        },

        '& $baseHint': {
          fontSize: typography.pxToRem(11),
          fontWeight: 500,
        },

        '& $progressIcon': {
          marginRight: 3,
        },

        '& $chevronIcon': {
          marginTop: 2,
          fontSize: typography.pxToRem(24),
        },
      },
      sizeXLarge: {
        padding: '8px 10px',
        lineHeight: typography.pxToRem(28),
        fontSize: typography.pxToRem(28),
        fontWeight: 600,

        '& $baseIconLeft svg': {
          marginRight: 10,
          fontSize: typography.pxToRem(24),
          lineHeight: 1,
        },

        '& $baseContent': {
          marginRight: 6,
        },

        '& $baseHint': {
          fontSize: typography.pxToRem(11),
          fontWeight: 500,
        },

        '& $progressIcon': {
          marginRight: 3,
        },

        '& $chevronIcon': {
          marginTop: 2,
          fontSize: typography.pxToRem(24),
        },
      },

      // States:
      emptyValue: {
        '& $baseContent': {
          color: palette.darkDuskFaded.main,
        },
      },
      invalid: {
        background: `${palette.bleachWhite.main} !important`,
        border: `1px solid ${palette.chileanFire.main} !important`,
      },
      disabled: {
        opacity: 0.3,
      },
      pending: {
        color: palette.fadedDarkGrey.main,

        '& $progressIcon': {
          color: palette.persianBlue.main,
        },

        '& $chevronIcon': {
          color: palette.persianBlue.main,
        },
      },
      open: {
        background: palette.fadedGrey.main,
        color: palette.persianBlue.main,
        cursor: 'pointer',

        '& $chevronIcon': {
          color: palette.persianBlue.main,
        },

        '& $baseContent': {
          fontWeight: 600,
          color: palette.royalBlue.main,
        },
      },
    }),
  { name: 'DropList' },
)

export interface Props {
  children?: React.ReactNode
  /** Checks if the component should be disabled */
  disabled?: boolean
  /** Checks if the component should be in loading state */
  pending?: boolean
  /** Component size - reflects the size of the dropdown */
  size?: 'x-large' | 'large' | 'big' | 'regular' | 'small'
  /** Component custom className */
  className?: string
  /** Component items list to be displayed in popover */
  items: BaseListItemType[]
  /** Component value to indicate non-empty state */
  value?: MaybeNull<any>
  /** Component placeholder */
  placeholder?: string
  /** Component icon */
  icon?: MaybeNull<any>
  /** Component value hint */
  hint?: MaybeNull<string>
  /** Component Valid state */
  valid?: boolean
  /** Component open handler */
  onOpen?: () => any
  /** Component close handler */
  onClose?: () => any
  /** Is control expands for 100% of its container width */
  fullWidth?: boolean
}

export type DropListProps = Props

/**
 * -
 */
export const DropList: React.FC<Props> = ({
  disabled = false,
  pending = false,
  size = 'regular',
  className,
  children,
  items,
  value,
  placeholder,
  icon,
  hint,
  valid = true,
  fullWidth = false,
  onOpen,
  onClose,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<MaybeNull<HTMLButtonElement>>(null)

  const isOpen = Boolean(anchorEl)
  const popoverId = isOpen ? 'dropList-actions-popover' : undefined

  const dropListClassName = clsx(
    classes.baseDropList,
    {
      [classes.sizeXLarge]: size === 'x-large',
      [classes.sizeLarge]: size === 'large',
      [classes.sizeBig]: size === 'big',
      [classes.sizeRegular]: size === 'regular',
      [classes.sizeSmall]: size === 'small',

      [classes.emptyValue]: !value,
      [classes.open]: isOpen,
      [classes.pending]: pending,
      [classes.disabled]: disabled,
      [classes.invalid]: !valid,
      [classes.fullWidth]: fullWidth,
    },
    className,
  )

  const listSize = size === 'small' ? 'small' : 'regular'

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
    onOpen?.()
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ButtonBase
        className={dropListClassName}
        disableRipple
        disableTouchRipple
        disabled={disabled || pending}
        {...(!disabled && !pending && { onClick: handleOpen })}
      >
        {icon && <div className={classes.baseIconLeft}>{icon}</div>}
        <div className={classes.baseContent}>
          {value ? children : placeholder} {hint && <div className={classes.baseHint}>{hint}</div>}
        </div>
        <div className={classes.baseIcon}>
          {pending && (
            <CircularProgress
              size={size === 'small' ? 14 : 16}
              classes={{
                root: classes.progressIcon,
              }}
            />
          )}

          {isOpen ? (
            <KeyboardArrowUpIcon className={classes.chevronIcon} />
          ) : (
            <KeyboardArrowDownIcon className={classes.chevronIcon} />
          )}
        </div>
      </ButtonBase>

      <Popover
        id={popoverId}
        open={isOpen}
        classes={{
          root: classes.popoverRoot,
          paper: classes.popover,
        }}
        anchorEl={anchorEl}
        onClose={() => {
          handleCloseMenu()
          onClose?.()
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List
          size={listSize}
          items={items.map(item => ({
            ...item,
            onClick: e => {
              item.onClick?.(e)
              handleCloseMenu()
            },
          }))}
        />
      </Popover>
    </>
  )
}
