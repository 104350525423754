import { usePermissionsFromProtectedRoute } from 'components/ui-routing/ProtectedRoute'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsPermitted } from 'utils/hooks'

export const withCheckPermission = (Component: React.FC) => {
  return (props: any) => {
    const navigate = useNavigate()
    const { isPermitted, isPermissionsLoading } = useIsPermitted()
    const { permissions, requireAllPermissions } = usePermissionsFromProtectedRoute()
    const hasPermission = isPermitted(permissions, requireAllPermissions)

    useEffect(() => {
      if (isPermissionsLoading || !permissions) return
      if (!hasPermission) navigate('/')
    }, [isPermissionsLoading, hasPermission, navigate, permissions])

    return <Component {...props} />
  }
}
