import { usePurposes } from 'api/purposes/queries/usePurposes'
import { useApplications } from 'api/applications/queries/useApplications'
import { useTrackerCategories } from 'api/trackers/queries/useTrackerCategories'
import { useTrackerSystems } from 'api/trackers/queries/useTrackerSystems'

export const useTrackersFilterUtils = () => {
  const { data: purposes, isLoading: isLoadingPurposes } = usePurposes({
    params: {
      limit: 1000,
    },
  })

  const { data: properties, isLoading: isLoadingProperties } = useApplications({
    params: {
      limit: 1000,
    },
  })

  const { data: trackerCategories, isLoading: isLoadingTrackerCategories } = useTrackerCategories({
    params: {
      limit: 1000,
    },
  })

  const { data: dataSystems, isLoading: isLoadingDataSystems } = useTrackerSystems({
    params: {},
  })

  const isReady = !isLoadingPurposes && !isLoadingProperties && !isLoadingTrackerCategories && !isLoadingDataSystems

  return {
    isReady,
    isLoadingProperties,
    properties,
    isLoadingTrackerCategories,
    trackerCategories,
    isLoadingPurposes,
    purposes,
    isLoadingDataSystems,
    dataSystems: dataSystems?.systems || [],
  }
}
