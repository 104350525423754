import React, { useContext } from 'react'
import { useFormikContext } from 'formik'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormCheckbox } from 'components/form/FormCheckbox'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import { AppNavigationContext } from 'components/appLayout/appNavigation/context/AppNavigationContext'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'

export const PreferenceThemeWelcomeTabKetchBadgeForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ThemeV3DTO>()
  const { isOrgOnFreePlan, isOrgOnStarterPlan } = useContext(PlanAndBillingContext)
  const { setShowNotEntitledModal } = useContext(AppNavigationContext)

  return (
    <SidebarFormGroup sx={{ p: 0, m: 0, gap: 0 }}>
      <FormControlLabel
        control={
          <FormCheckbox
            name={themeUpsertFormKeys.preferenceWelcomeTabFooterShowKetchBadge}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if ((isOrgOnFreePlan || isOrgOnStarterPlan) && values?.content?.preference?.ketchBadgeVisible) {
                setShowNotEntitledModal(true)
                return
              }
              setFieldValue('content.preference.ketchBadgeVisible', !values?.content?.preference?.ketchBadgeVisible)
            }}
          />
        }
        label={<Typography variant={'label'}>Show “Powered by Ketch” Badge</Typography>}
      />
    </SidebarFormGroup>
  )
}
