import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { updateProcessingActivityDataFlow } from '../fetchers/updateProcessingActivityDataFlow'

const useCustomMutation = createUseMutation({
  mutationFn: updateProcessingActivityDataFlow,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateProcessingActivityDataFlow = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
