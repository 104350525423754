import {
  GetNewPropertyTrackerPropertiesParams,
  GetTrackerPropertiesResponseBody,
} from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchNewPropertyTrackerProperties = ({
  nextToken,
  limit,
  searchString,
  encodedTrackerKey,
  isDisclosed,
}: GetNewPropertyTrackerPropertiesParams & { encodedTrackerKey: string }) => {
  return API.get<GetTrackerPropertiesResponseBody>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/tracker/${encodedTrackerKey}/new-property/properties`,
      params: {
        filters: {
          ...(nextToken && {
            nextToken,
          }),
          ...(limit && {
            limit,
          }),
          ...(searchString && {
            searchString,
          }),
          ...(isDisclosed && {
            isDisclosed,
          }),
        },
      },
    }),
  )
}
