import React, { Fragment, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Formik } from 'formik'
import { compact } from 'lodash'

import { ICanvasStep, IEmailActivityParams } from 'pages/orchestration/workflows/edit/interfaces'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'
import Box from '@mui/material/Box'

import { getDataSubjectEmailInitialValues } from 'pages/orchestration/workflows/edit/utils/forms/activities/dataSubjectEmail/dataSubjectEmailInitialValues'
import { getDataSubjectEmailValidationSchema } from 'pages/orchestration/workflows/edit/utils/forms/activities/dataSubjectEmail/dataSubjectEmailValidation'
import { formatCodeFromName } from 'utils/formatters'
import { FormInput } from 'components/form/FormInput'
import { Switch, Typography } from '@mui/material'
import { FormEmailsInput } from 'components/form/FormEmailsInput/FormEmailsInput'

interface IDataSubjectEmailFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const DataSubjectEmailForm: React.FC<IDataSubjectEmailFormProps> = props => {
  const { step, steps, handleClose, handleRemove, handleSave } = props

  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))
  const [isReady] = useState(true)

  const onSubmit = (values: IEmailActivityParams) => {
    handleSave({
      step: {
        ...step,
        valid: true,
        code: values.code,
        description: values.description,
        activity: {
          ...step.activity,
          params: {
            body: values.body,
            subject: values.subject,
            cc: values.cc || [],
            hidePrivacyPortalLink: values.hidePrivacyPortalLink,
          },
        } as ICanvasStep['activity'],
      },
    })
  }

  return (
    <SidebarConfigsWrapper isLoading={!isReady} handleClose={handleClose}>
      <Formik
        initialValues={getDataSubjectEmailInitialValues({ step })}
        validationSchema={getDataSubjectEmailValidationSchema({ usedCodes })}
        validateOnMount
        onSubmit={onSubmit}
      >
        {form => {
          const { isValid, submitForm, setFieldValue, values } = form

          return (
            <Fragment>
              <SidebarConfigsHeader
                isValid={isValid}
                step={step}
                steps={steps}
                handleSave={() => submitForm()}
                handleRemove={() => handleRemove({ step })}
                handleClose={handleClose}
                copyable
              />

              <SidebarConfigsBody sx={{ px: 3 }}>
                <Box pb={5}>
                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormInput
                          required
                          formPropertyName="description"
                          fullWidth
                          label="Description Label"
                          placeholder="Enter description"
                          onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
                          shouldUpdateDebounced
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <FormInput
                        formPropertyName="code"
                        fullWidth
                        label="Code"
                        placeholder="Enter code"
                        required
                        shouldUpdateDebounced
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Box marginBottom={3}>
                    <Typography variant="h3">Email Details</Typography>
                  </Box>

                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormEmailsInput disabled fullWidth formPropertyName="to" label="To" required />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormEmailsInput fullWidth formPropertyName="cc" label="Cc" required />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mb={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormInput
                          formPropertyName="subject"
                          fullWidth
                          label="Subject"
                          placeholder="Enter subject text"
                          required
                          shouldUpdateDebounced
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mb={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormInput
                          formPropertyName="body"
                          fullWidth
                          label="Body"
                          placeholder="Enter body text"
                          rows={8}
                          required
                          shouldUpdateDebounced
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box>
                          <Typography variant="label">Hide Privacy Portal Link</Typography>
                        </Box>

                        <Switch
                          checked={values?.hidePrivacyPortalLink || false}
                          onChange={e => setFieldValue('hidePrivacyPortalLink', e.target.checked)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </SidebarConfigsBody>
            </Fragment>
          )
        }}
      </Formik>
    </SidebarConfigsWrapper>
  )
}
