import React from 'react'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'
import { MaybeNull } from 'interfaces'
import { IdentitySpaceFormValues } from 'pages/settings/identitySpaces/upsert/utils/formValues'
import { FindVerifiersResponseBodyDTO } from '@ketch-com/figurehead'
import { Box, Typography } from '@mui/material'
import { FormSwitch } from 'components/form/FormSwitch'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { theme } from '@ketch-com/deck'

type Props = {
  isEditMode: boolean
  isModalForm?: boolean
  verifiersData: MaybeNull<FindVerifiersResponseBodyDTO>
}

export const AuthenticatedIdentity: React.FC<Props> = ({ isEditMode, isModalForm, verifiersData }) => {
  const { values, setFieldValue } = useFormikContext<IdentitySpaceFormValues>()
  const { verifiers = [] } = verifiersData || {}
  const items = verifiers.map(verifier => ({ id: verifier.id, name: verifier.name }))

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      pb={6}
      pt={2}
      mt={3}
      borderTop={`1px solid ${theme.palette.iron.main}`}
    >
      <Box display="flex" alignItems="center" gap={3}>
        <FormSwitch
          size="medium"
          formPropertyName="isAuthentication"
          onChange={e => {
            if (!e?.currentTarget?.checked) setFieldValue('verifierID', '')
          }}
        />
        <Typography variant="h4">Verified Identity</Typography>
      </Box>
      {values.isAuthentication && (
        <Grid container spacing={3}>
          <Grid item xs={isModalForm ? 12 : 3}>
            <FormDroplistButton
              required
              fullWidth
              valueKey="id"
              placeholder="Select Token Verifier"
              formPropertyName="verifierID"
              label="Token Verifier"
              items={items}
              disableClearable
            />
          </Grid>
        </Grid>
      )}
    </Box>
  )
}
