import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useController } from 'api/controllers/queries/useController'
import { useDeleteController } from 'api/controllers/mutations/useDeleteController'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { Text } from 'components/ui-kit/typography/Text'
import { Dialog } from 'components/ui-kit/dialog/Dialog'

type Props = {
  code: string
  onSubmit?: () => void
  onCancel: () => void
}

export const DeleteControllerModal: React.FC<Props> = props => {
  const queryClient = useQueryClient()
  const { code, onSubmit, onCancel } = props
  const [isBusy, setIsBusy] = useState(false)

  const { data: controller, isLoading } = useController({
    enabled: !!code,
    params: {
      controllerCode: code!,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch controller', type: 'error' })
      onCancel()
    },
  })

  const { mutate: handleDeleteController } = useDeleteController({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'Controller deleted', type: 'success' })

      await queryClient.refetchQueries(ApiQueryKeys.controllersPaginated)
      await onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to delete controller', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <Dialog
      isLoading={isLoading}
      isSaving={isBusy}
      title="Delete Controller"
      contentWidth={550}
      submitBtnLabel="Delete"
      cancelBtnLabel="Close"
      onSubmitBtnClick={() => {
        handleDeleteController({
          params: {
            controllerCode: code!,
          },
        })
      }}
      onCancelBtnClick={onCancel}
    >
      <Text size={14}>
        Are you sure you want to delete <strong>{controller?.name || controller?.code}</strong> controller?
      </Text>
    </Dialog>
  )
}
