import React from 'react'

import { useQueryParams } from 'utils/hooks/useQueryParams'
import { Banner, Button } from '@ketch-com/deck'

export const PolicyScopeViewVersions = () => {
  const { removeQueryParam } = useQueryParams<{ version?: string }>()

  return (
    <Banner
      severity="warning"
      action={
        <Button variant="link" onClick={() => removeQueryParam('version')}>
          View Current
        </Button>
      }
    >
      You are viewing an old version of this jurisdiction. Return to the current version to manage or activate.
    </Banner>
  )
}
