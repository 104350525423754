import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { Box } from '@mui/material'
import { Button } from '@ketch-com/deck'
import { FormLayout } from 'components/ui-layouts/formLayout/FormLayout'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { ButtonBack } from 'components/ui-kit/buttonBack/ButtonBack'
import { useRegisterUtils, useStyles, useValidationSchema } from './hooks'
import { PersonalInfo } from './screens/PersonalInfo'
import { OrganizationInfo } from './screens/OrganizationInfo'
import { RegistrationFormValues } from 'interfaces/register/register'
import { Successful } from 'pages/registration/register/Register/screens/Successful'
import { RegistrationLayout } from './components/RegistrationLayout'
import { useIpLocation } from '../../../../api/externalApis/ketchCdn/queries/useIpLocation'
import { isKetchFreeEnabled } from 'utils/helpers/isKetchFreeEnabled'

const US_AUS_COUNTRY_CODES = ['US', 'AU']

// NOTE: to view this, you must first logout and then go to http://localhost:3000/registration/register
export const Register: React.FC = () => {
  const classes = useStyles()
  const validationSchema = useValidationSchema()
  const _ = useRegisterUtils()
  const onSubmit = _.onSubmit

  const [stepNumber, setStepNumber] = useState(0)
  const [isChecked, setIsChecked] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [snapshot, setSnapshot] = useState(_.initialValues)

  // Determine if the user is in the EU
  const { data: ipLocation } = useIpLocation({})
  const isUSOrAU = !!ipLocation?.location?.countryCode && US_AUS_COUNTRY_CODES.includes(ipLocation.location.countryCode)

  const steps = [
    <PersonalInfo validationSchema={validationSchema[0]} />,
    <OrganizationInfo
      validationSchema={validationSchema[1]}
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      isUSOrAU={isUSOrAU}
    />,
  ]
  const step = steps[stepNumber]
  const totalSteps = steps.length
  const isLastStep = stepNumber === totalSteps - 1

  const next = (values: RegistrationFormValues) => {
    setSnapshot(values)
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1))
  }

  const previous = (values: RegistrationFormValues) => {
    setSnapshot(values)
    setStepNumber(Math.max(stepNumber - 1, 0))
  }

  const handleSubmit = async (values: RegistrationFormValues, bag: any) => {
    if (isLastStep) {
      return onSubmit(values, setIsSuccessful)
    } else {
      bag.setTouched({})
      next(values)
    }
  }

  if (!isKetchFreeEnabled()) {
    return <Navigate to={RoutesManager.home.root.getURL()} />
  }

  return (
    <Box
      sx={{
        bgcolor: 'white.main',
        height: '100vh',
        width: '100vw',
      }}
    >
      <FormLayout
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
        signup={true}
      >
        {formik => {
          if (isSuccessful) {
            return (
              <RegistrationLayout isSuccessful={isSuccessful}>
                <Successful />
              </RegistrationLayout>
            )
          }
          return (
            <Flex flexDirection="row" justifyContent="flex-start">
              <div className={classes.sideContainer}>
                <Box width="104px" mt={15} display="flex" flexDirection="row" justifyContent="flex-start">
                  {stepNumber > 0 && (
                    <ButtonBack onClick={() => previous(formik.values)} size="big" className={classes.backButton} />
                  )}
                </Box>
              </div>
              <RegistrationLayout>
                {step}

                {isLastStep ? (
                  <Button pending={formik.isSubmitting} type="submit" size="large" sx={{ width: '100%' }}>
                    Create Account
                  </Button>
                ) : (
                  <Button pending={formik.isSubmitting} type="submit" size="large" sx={{ width: '100%' }}>
                    Continue
                  </Button>
                )}
              </RegistrationLayout>
              <div className={classes.sideContainer}>
                <Box width="104px"></Box>
              </div>
            </Flex>
          )
        }}
      </FormLayout>
    </Box>
  )
}
