import React, { useState } from 'react'
import { Formik } from 'formik'
import { useQueryClient } from 'react-query'

// api
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useAssignWorkflow } from 'api/orchestrationEventsV2/mutations/useAssignWorkflow'
import { useUnassignWorkflow } from 'api/orchestrationEventsV2/mutations/useUnassignWorkflow'

// components
import Box from '@mui/material/Box'
import { CheckButton } from './CheckButton'
import { CloseButton } from './CloseButton'
import { DeleteButton } from './DeleteButton'
import { EditButton } from './EditButton'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { WorkflowOptionsCellRenderer } from './WorkflowOptionsCellRenderer'

// types
import { AssignEventWorkflowV2BodyDTO, EventV2DTO } from '@ketch-com/figurehead'

type Props = {
  orchestrationEvent: EventV2DTO
  workflowName?: string
  hideDelete?: boolean
}

export const InlineEdit: React.FC<Props> = ({ orchestrationEvent, workflowName, hideDelete = false }) => {
  const [isEditing, setIsEditing] = useState(false)
  const queryClient = useQueryClient()

  // api
  const { mutateAsync: handleAssignWorkflow } = useAssignWorkflow({
    onSuccess: async () => {
      setIsEditing(false)
      showToast({ content: 'Workflow Assignments updated', type: 'success' })
      queryClient.refetchQueries(ApiQueryKeys.eventsPaginated)
    },
    onError: () => {
      showToast({ content: 'Failed to update workflow Assignments', type: 'error' })
    },
  })
  const { mutateAsync: handleUnassignWorkflow } = useUnassignWorkflow({
    onSuccess: async () => {
      setIsEditing(false)
      showToast({ content: 'Workflow Assignments updated', type: 'success' })
      queryClient.refetchQueries(ApiQueryKeys.eventsPaginated)
    },
    onError: () => {
      showToast({ content: 'Failed to update workflow Assignments', type: 'error' })
    },
  })

  // event handlers
  const handleFormSubmit = async (formValues: AssignEventWorkflowV2BodyDTO) => {
    await handleAssignWorkflow({
      params: {
        formData: {
          ...formValues,
        },
      },
    })
  }

  const handleDelete = async (event: EventV2DTO) => {
    await handleUnassignWorkflow({
      params: {
        formData: {
          eventID: event?.id,
          rightType: event?.rightType,
        },
      },
    })
  }

  return (
    <>
      {!isEditing ? (
        <Box display="flex" alignItems="center" onClick={() => setIsEditing(true)}>
          <NameAndCodeCellRenderer
            size="normal"
            code={orchestrationEvent?.workflowCode}
            name={workflowName}
            maxWidth={350}
          />
          <EditButton />
        </Box>
      ) : (
        <Formik
          initialValues={{
            eventId: orchestrationEvent?.id,
            workflowCode: orchestrationEvent?.workflowCode,
            rightType: orchestrationEvent?.rightType,
          }}
          enableReinitialize
          validateOnMount
          onSubmit={handleFormSubmit}
        >
          {({ handleSubmit }) => (
            <Box display="flex" alignItems="center">
              <WorkflowOptionsCellRenderer
                canonicalRightCode={orchestrationEvent?.canonicalRightCode || ''}
                hasMaxWidth
              />
              {!hideDelete && <DeleteButton onClick={() => handleDelete(orchestrationEvent)} />}
              <CloseButton onClick={() => setIsEditing(false)} />
              <CheckButton onClick={() => handleSubmit()} />
            </Box>
          )}
        </Formik>
      )}
    </>
  )
}
