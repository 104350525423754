import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchInstalledDataSystems } from '../fetchers/fetchInstalledDataSystems'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.installedDataSystems,
  queryFn: fetchInstalledDataSystems,
  select: res => res?.data.installedDataSystems,
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useInstalledDataSystems = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
    itemsPerPage: config?.params?.limit || 50,
  })
}
