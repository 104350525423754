import { Button, Icon, theme } from '@ketch-com/deck'
import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { useTransponderCreate } from '../../context'
import { TranspondersCreateInfoButton } from './TranspondersCreateInfoButton'
import { ConfigItemDisplay } from 'pages/developers/transponders/components'
import { TransponderDocumentationSlugs } from 'pages/developers/transponders/hooks/useTransponderDocumentation'

export const TransponderCreateSuccess: React.FC = () => {
  const { applianceId, token, handleExitFlow, handleVisitDocs } = useTransponderCreate()

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      paddingX={6}
      paddingY={4}
      borderRadius={4}
      width={617}
      sx={{ backgroundColor: theme.palette.background.paper }}
    >
      <Box display="flex" alignItems="center">
        <Icon name="OutcomeSuccess" width={56} height={56} sx={{ marginRight: 1.25 }} />
        <Typography variant="h2" color={theme.palette.Success.PrimaryDark}>
          Transponder Created
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap={3}>
        <Divider />

        <Box>
          <Box display="flex" alignItems="center">
            <Typography variant="h3">Next Step </Typography>
            <Icon name="OArrowRight" width={26} height={26} />
            <Typography variant="h3"> Add Organization</Typography>
          </Box>
          <Typography variant="body">
            Within your transponder, click Add Organization and paste this key as the Transponder Key.
          </Typography>
        </Box>

        <ConfigItemDisplay alwaysVisible type="token" name="Transponder Token" value={token!} />
        <ConfigItemDisplay alwaysVisible type="token" name="Transponder Appliance Id" value={applianceId!} />

        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="smallLabel" color={theme.palette.Text.Secondary} marginBottom={0.5}>
            Explore More
          </Typography>

          <TranspondersCreateInfoButton
            iconName="OLock"
            handleOnClick={() => handleVisitDocs(TransponderDocumentationSlugs.secretProviderAWS)}
          >
            {' '}
            Secret Providers
          </TranspondersCreateInfoButton>
          <TranspondersCreateInfoButton
            iconName="OChoice3"
            handleOnClick={() => handleVisitDocs(TransponderDocumentationSlugs.loadBalancerAWSLoadBalancer)}
          >
            {' '}
            Load Balancers
          </TranspondersCreateInfoButton>

          <Box display="flex" gap={0.5}>
            <Icon
              name="FInfo"
              iconColor={theme.palette.Text.Secondary}
              width={16}
              height={16}
              sx={{ alignSelf: 'flex-start' }}
            />
            <Typography variant="footnote">
              Explore tutorials that cover using different secret providers and load balancers for the transponder.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        <Divider />
        <Box display="flex" gap={1.5}>
          <Button
            color="tertiary"
            size="large"
            onClick={() => {
              handleVisitDocs(TransponderDocumentationSlugs.connectingTransponders)
            }}
            sx={{ alignSelf: 'start', marginTop: 2 }}
          >
            Read Documentation
          </Button>
          <Button color="primary" size="large" onClick={handleExitFlow} sx={{ alignSelf: 'start', marginTop: 2 }}>
            Done
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
