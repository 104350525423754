import React from 'react'

import Box from '@mui/material/Box'
import { useInlineCtxVariableUtils } from './hooks'
import { StepDetailsVariableDTO } from '@ketch-com/figurehead'
import { StepDetailsTypeEnum } from 'interfaces/workflows/StepDetailsTypeEnum'
import { DropListButton, ListItemText, ActionSheetItem, Radio, TextInput } from '@ketch-com/deck'
import { FormControlLabel, RadioGroup } from '@mui/material'
import { FormError } from 'components/form/FormError'

type Props = {
  canEdit: boolean
  name: string
  isRequired: boolean | undefined
  inputId: string
  contextVariable?: StepDetailsVariableDTO
  isError?: boolean
  variableIndex: number
}

export const InlineEditCtxVariable: React.FC<Props> = ({
  canEdit = true,
  isRequired = false,
  name,
  contextVariable,
  inputId,
}) => {
  const { handleOnBlur, value, error, setValue } = useInlineCtxVariableUtils({
    name,
    inputId,
    canEdit,
    contextVariable,
  })

  const droplistOptions =
    contextVariable?.options?.map(item => ({
      content: item || '',
      onClick: () => setValue(item || ''),
    })) || []

  const droplistValue = contextVariable?.options
    ?.map(item => ({
      content: item || '',
      onClick: () => setValue(item || ''),
    }))
    .find(item => item.content === value)

  return (
    <Box display="flex" width="100%" justifyContent="space-between">
      {contextVariable?.type === StepDetailsTypeEnum.STRING && (
        <>
          <Box width="100%">
            <TextInput
              id={inputId}
              error={!!error}
              fullWidth
              value={value}
              helperText={error}
              onChange={e => setValue(e.currentTarget.value)}
              placeholder="Enter value..."
              required={isRequired}
              variant="ghost"
              onBlur={handleOnBlur}
              size="small"
            />
            {isRequired && error && <FormError msg={error} />}
          </Box>
        </>
      )}

      {contextVariable?.type === StepDetailsTypeEnum.INTEGER && (
        <>
          <Box width="100%">
            <TextInput
              id={inputId}
              error={!!error}
              fullWidth
              value={value}
              helperText={error}
              onChange={e => setValue(e.currentTarget.value)}
              placeholder="Enter value..."
              required={isRequired}
              variant="ghost"
              onBlur={handleOnBlur}
              size="small"
              type="number"
            />
            {isRequired && error && <FormError msg={error} />}
          </Box>
        </>
      )}

      {contextVariable?.type === StepDetailsTypeEnum.BOOLEAN && (
        <Box>
          <RadioGroup value={value} row onChange={e => setValue(e.currentTarget.value)}>
            {[
              { title: 'True', value: 'true' },
              { title: 'False', value: 'false' },
            ].map(option => (
              <FormControlLabel value={option.value} control={<Radio />} label={option.title} />
            ))}
          </RadioGroup>
          <Box display="flex" alignItems="center">
            {error && <FormError msg={error} />}
          </Box>
        </Box>
      )}

      {contextVariable?.type === StepDetailsTypeEnum.LIST && (
        <Box display="flex" flexDirection="column">
          <DropListButton
            disableClearable
            placeholder="Select"
            options={droplistOptions}
            getOptionLabel={option => option.content}
            value={droplistValue}
            onChange={(_, newValue) => {
              newValue?.onClick()
            }}
            renderOption={(props, option) => (
              <ActionSheetItem selected={option.content === value} {...props}>
                <ListItemText selected={option.content === value}>{option.content}</ListItemText>
              </ActionSheetItem>
            )}
          />
          <Box display="flex" alignItems="center">
            {error && <FormError msg={error} />}
          </Box>
        </Box>
      )}
    </Box>
  )
}
