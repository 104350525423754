import React from 'react'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { FormTextInput } from '../FormTextInput'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { useContext } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'

export const PreferenceExperienceWelcomeTabHeaderForm: React.FC = () => {
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length

  return (
    <>
      <SidebarFormGroup sx={{ paddingTop: 0 }} hasBorderTop={false} hasBorderBottom={hasLanguages}>
        <FormTextInput
          label="Header Title Text"
          name={experienceUpsertFormKeys.preferenceWelcomeTabHeaderTitle}
          fullWidth
          charLimit={30}
        />
      </SidebarFormGroup>
      <SidebarFormTranslations
        title={'Header Title Text Translations'}
        name={experienceUpsertFormKeys.preferenceWelcomeTabHeaderTitle}
        variant={'text'}
        charLimit={30}
      />
    </>
  )
}
