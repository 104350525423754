import { Button, Icon, Tab, TabPanel, TabGroup, theme } from '@ketch-com/deck'
import { Box, FormControlLabel, Typography } from '@mui/material'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import React, { SyntheticEvent, useContext } from 'react'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormMode } from 'interfaces/formModes/formMode'
import { FormSectionSwitch } from '../FormSectionSwitch'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { FormCheckbox } from '../FormCheckbox'
import { FormTextInput } from '../FormTextInput'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { getFormNameForLocale } from 'pages/consentAndRights/experiences-v2/upsert/utils/utils'
import { SubscriptionTopicsList } from '../SubscriptionTopicsList'
import { defaultExperienceV2DTO } from 'pages/consentAndRights/experiences-v2/upsert/utils/defaults'

export const PreferenceExperienceSubscriptionsTabListForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ExperienceV2DTO>()
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length

  // Current tab of the subscriptions list form
  const [tab, setTab] = React.useState(0)

  const showSwitchButtonsForm = getIn(
    values,
    experienceUpsertFormKeys.preferenceSubscriptionsTabListSwitchButtonLabelsVisible,
  )
  const showCustomSwitchButtonsForm = !getIn(
    values,
    experienceUpsertFormKeys.preferenceSubscriptionsTabListSwitchButtonLabelsUseDefault,
  )

  const handleCreateSubscriptionClick = () => {
    window.open(
      RoutesManager.policyCenter.subscriptions.upsert.subscriptionTopic.root.getURL({
        formMode: FormMode.CREATE,
        code: 'new',
      }),
      '_blank',
    )
  }

  const handleSwitchButtonDefaultChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked && !isLanguagesLoading) {
      // Reset both the on and off text
      ;[
        experienceUpsertFormKeys.preferenceSubscriptionsTabListSwitchLabelsOnText,
        experienceUpsertFormKeys.preferenceSubscriptionsTabListSwitchLabelsOffText,
      ].forEach(englishFieldToReset => {
        // Reset to english default
        const defaultEnglishValue = getIn(defaultExperienceV2DTO, englishFieldToReset)
        setFieldValue(englishFieldToReset, defaultEnglishValue)

        // Reset to default for each locale
        languages.forEach(({ language: { code } }) => {
          const localeFieldToReset = getFormNameForLocale(englishFieldToReset, code)
          const defaultLocaleValue = getIn(defaultExperienceV2DTO, localeFieldToReset)
          setFieldValue(localeFieldToReset, defaultLocaleValue)
        })
      })
    }
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
          <Tab size={'medium'} label="List of Subscriptions" />
          <Tab size={'medium'} label="Switch Buttons" />
        </TabGroup>
      </Box>

      {/* Subscriptions list */}
      <TabPanel value={tab} index={0} sx={{ px: 0, pt: 0 }}>
        <SidebarFormGroup hasBorderBottom>
          <SubscriptionTopicsList />
        </SidebarFormGroup>
        <SidebarFormGroup>
          <Box>
            <Typography>Haven't created a subscription yet? Click here:</Typography>
            <Button
              onClick={handleCreateSubscriptionClick}
              color={'tertiary'}
              endIcon={<Icon name={'OShare'} iconColor={theme.palette.sphere.main} />}
              sx={{ mt: 1 }}
            >
              Create Subscription
            </Button>
          </Box>
        </SidebarFormGroup>
      </TabPanel>

      {/* Switch Buttons */}
      <TabPanel value={tab} index={1} sx={{ px: 0, pt: '32px' }}>
        <FormSectionSwitch
          name={experienceUpsertFormKeys.preferenceSubscriptionsTabListSwitchButtonLabelsVisible}
          title={'Switch Button Labels'}
        />
        {showSwitchButtonsForm && (
          <Box p={'16px 18px'}>
            <FormControlLabel
              control={
                <FormCheckbox
                  name={experienceUpsertFormKeys.preferenceSubscriptionsTabListSwitchButtonLabelsUseDefault}
                  afterChange={handleSwitchButtonDefaultChange}
                />
              }
              label={<Typography variant={'label'}>Use Default Text</Typography>}
            />
            <Typography component={'p'} variant={'body'} color={theme.palette.darkDuskFaded.main}>
              The default text "On" and "Off" has translations for all available languages.
            </Typography>
            {showCustomSwitchButtonsForm && (
              <>
                <SidebarFormGroup hasBorderBottom={hasLanguages}>
                  <FormTextInput
                    label={'Switch Button ON Text Label'}
                    name={experienceUpsertFormKeys.preferenceSubscriptionsTabListSwitchLabelsOnText}
                    fullWidth
                    charLimit={30}
                  />
                  <FormTextInput
                    label={'Switch Button OFF Text Label'}
                    name={experienceUpsertFormKeys.preferenceSubscriptionsTabListSwitchLabelsOffText}
                    fullWidth
                    charLimit={30}
                  />
                </SidebarFormGroup>
              </>
            )}
            {showCustomSwitchButtonsForm && (
              <SidebarFormTranslations
                title={'Switch Button Label Translations'}
                switchButtonOnName={experienceUpsertFormKeys.preferenceSubscriptionsTabListSwitchLabelsOnText}
                switchButtonOffName={experienceUpsertFormKeys.preferenceSubscriptionsTabListSwitchLabelsOffText}
                variant={'switchButtons'}
                charLimit={30}
              />
            )}
          </Box>
        )}
      </TabPanel>
    </>
  )
}
