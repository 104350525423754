import { APIListRequestParams } from 'api/common/utils'
import { GetJurisdictionPurposesPreviewsResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  code?: string
  languageCode?: string
}>

export const fetchJurisdictionPurposesPreviews = ({ code, languageCode }: Params) =>
  API.get<GetJurisdictionPurposesPreviewsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/jurisdictions/${code}/purposes/previews`,
      params: {
        filters: {
          languageCode,
        },
      },
    }),
  )
