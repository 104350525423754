import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { Chip as DeckChip, Icon, TableCell, theme } from '@ketch-com/deck'
import { useCopyToClipboard } from 'react-use'
import { Tooltip, Typography } from '@mui/material'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { RightListStatusCellRendererV2 } from '../..'
import { CurrentActivityCellRenderer, WorkflowExecutionProgressV2 } from '../components'
import { joinNonEmpty } from 'utils/helpers/joinNonEmpty'
import { getTooltipContent } from 'utils/messages/getTooltipContent'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { showToast } from 'components/modals/AlertComponent'
import {
  FetchRightInvocationsFilterKeys,
  FetchRightInvocationsOrderByKeys,
} from 'interfaces/rightInvocationsV2/FetchRightInvocationsFilterKeys'

function truncateId(id?: string): string {
  if (!id) return ''

  // Split the string by '-'
  const parts = id.split('-')

  // Take the substring up to the first '-'
  const firstPart = parts[0]

  // Take the last 4 characters from the original id string
  const last4Chars = id.slice(-4)

  // Combine and return the two extracted parts
  return `${firstPart}-...${last4Chars}`
}

export const DataRendererWithTooltip = ({ rightInvocation }: { rightInvocation: RightInvocationDTO }) => {
  const [, copyToClipboard] = useCopyToClipboard()
  const { rightName, id, appeal } = rightInvocation
  const appealWorkflowExecutionID = appeal?.results?.appealWorkflowExecutionID
  return (
    <TableCell padding="8px 0">
      <Box display="flex" flexDirection="column" justifyContent="flex-start">
        <Typography variant="label">{rightName || 'Right Invocation'}</Typography>
        {id && (
          <Tooltip
            title={
              <Box
                display="flex"
                p={1}
                flexDirection="column"
                onClick={() => {
                  copyToClipboard(id)
                  showToast({ content: 'Copied to clipboard', type: 'success' })
                }}
              >
                <Typography variant="label" color="white.main" mb={1}>
                  {id}
                </Typography>

                <Typography variant="smallLabel" color="white.main">
                  Click to copy to clipboard
                </Typography>
              </Box>
            }
          >
            <Typography variant="footnote">{truncateId(id)}</Typography>
          </Tooltip>
        )}
      </Box>
      {appealWorkflowExecutionID && (
        <DeckChip
          label="Appeal"
          size="small"
          sx={{
            backgroundColor: 'fadedHeliotrope.main',
            color: 'darkViolet.main',
            ml: 1,
          }}
        />
      )}
    </TableCell>
  )
}

export const useRightsQueueListDataGridColumns = (): GridColDef<RightInvocationDTO>[] => {
  return [
    {
      align: 'left',
      field: FetchRightInvocationsFilterKeys.statuses,
      headerAlign: 'left',
      headerName: 'Request Status',
      sortable: true,
      minWidth: 130,
      renderCell: ({ row: rightInvocation }: GridRenderCellParams<RightInvocationDTO>) => (
        <TableCell>
          <Box display="flex" alignItems="center">
            <RightListStatusCellRendererV2 rightInvocation={rightInvocation} />
            {/* TODO:BAC - Combining Issues column here for now.  Not sure how will integrate in V2 */}
            {rightInvocation.hasIssues && (
              <Tooltip title={getTooltipContent(ApplicationEntity.RIGHTS_QUEUE)}>
                <Box>
                  <Icon iconColor={theme.palette.chileanFire.main} name="FImportant" fontSize="small" />
                </Box>
              </Tooltip>
            )}
          </Box>
        </TableCell>
      ),
    },
    {
      align: 'left',
      field: 'subjectName',
      flex: 1,
      minWidth: 150,
      headerAlign: 'left',
      headerName: 'Subject Name',
      sortable: false,
      renderCell: ({ row: rightInvocation }: GridRenderCellParams<RightInvocationDTO>) => {
        const { user } = rightInvocation
        const subjectName = joinNonEmpty([user?.first, user?.last])
        return <TableCell>{user ? <Typography>{subjectName}</Typography> : <EmptyValueRenderer />}</TableCell>
      },
    },
    {
      align: 'left',
      field: 'requestCode',
      headerAlign: 'left',
      flex: 1,
      headerName: 'Request',
      minWidth: 210,
      sortable: false,
      renderCell: ({ row: rightInvocation }: GridRenderCellParams<RightInvocationDTO>) => (
        <DataRendererWithTooltip rightInvocation={rightInvocation} />
      ),
    },
    {
      align: 'left',
      field: 'currentActivity',
      headerAlign: 'left',
      flex: 1,
      minWidth: 270,
      headerName: 'Current Activity',
      sortable: false,
      renderCell: ({ row: rightInvocation }: GridRenderCellParams<RightInvocationDTO>) => {
        return (
          <TableCell sx={{ padding: '8px 0' }}>
            <CurrentActivityCellRenderer rightInvocation={rightInvocation} />
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'progress',
      headerAlign: 'left',
      flex: 1,
      minWidth: 156,
      headerName: 'Progress',
      sortable: false,
      renderCell: ({ row: rightInvocation }: GridRenderCellParams<RightInvocationDTO>) => {
        const isAppealed = !!rightInvocation?.appeal?.isAppealed
        const workflowExecution = isAppealed
          ? rightInvocation?.appealWorkflowExecution
          : rightInvocation?.workflowExecution

        return workflowExecution ? (
          <TableCell>
            <WorkflowExecutionProgressV2 workflowExecution={workflowExecution} rightInvocation={rightInvocation} />
          </TableCell>
        ) : (
          <TableCell>
            <EmptyValueRenderer />
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'workflow',
      headerAlign: 'left',
      flex: 1,
      minWidth: 210,
      headerName: 'Workflow',
      sortable: false,
      renderCell: ({ row: rightInvocation }: GridRenderCellParams<RightInvocationDTO>) => {
        const isAppealed = !!rightInvocation?.appeal?.isAppealed
        const workflowExecution = isAppealed
          ? rightInvocation?.appealWorkflowExecution
          : rightInvocation?.workflowExecution

        return workflowExecution?.workflowDefinitionName ? (
          <TableCell>
            <Typography title={workflowExecution?.workflowDefinitionName} variant="body">
              {workflowExecution?.workflowDefinitionName}
            </Typography>
          </TableCell>
        ) : (
          <TableCell>
            <EmptyValueRenderer />
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: FetchRightInvocationsOrderByKeys.invoked_at,
      headerAlign: 'left',
      flex: 1,
      minWidth: 130,
      headerName: 'Invoked Date',
      sortable: true,
      renderCell: ({ row: rightInvocation }: GridRenderCellParams<RightInvocationDTO>) => {
        return (
          <TableCell>
            <FormattedDateRenderer date={rightInvocation?.invokedAt} format="MMM DD YYYY" />
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'subjectType',
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
      headerName: 'Subject Type',
      sortable: false,
      renderCell: ({ row: rightInvocation }: GridRenderCellParams<RightInvocationDTO>) => {
        const { user } = rightInvocation
        return (
          <TableCell>
            <Chip
              sx={{
                padding: '2px 14px',
                height: 33,
                justifyContent: 'flex-start',
                borderRadius: '100px',
                '& .MuiChip-label': {
                  padding: 0,
                },
              }}
              label={
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="mediumFootnote"
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {user?.dataSubjectTypeName}
                  </Typography>
                  <Typography
                    variant="smallFootnote"
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {user?.dataSubjectTypeCode}
                  </Typography>
                </Box>
              }
            />
          </TableCell>
        )
      },
    },
  ]
}
