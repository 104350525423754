import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'

import { fetchResourcePoliciesPaginatedV2 } from '../fetchers/fetchResourcePoliciesPaginatedV2'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.resourcePoliciesPaginated,
  queryFn: fetchResourcePoliciesPaginatedV2,
  select: res => res?.data?.policies || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useResourcePoliciesPaginatedV2 = (config: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    itemsPerPage: config?.itemsPerPage,
    params: {
      ...config!.params,
    },
  })
}
