import { FC } from 'react'
import { Box, Tooltip } from '@mui/material'
import { Chip } from '@ketch-com/deck'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { SubjectTypeBulletedListTooltipContent } from 'components/tooltip/SubjectTypeBulletedListTooltipContent'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type Props = {
  dataSubjectTypes?: DataSubjectTypeDTO[]
}

export const DataSubjectTypeBadge: FC<Props> = ({ dataSubjectTypes = [] }) => {
  if (!dataSubjectTypes.length) {
    return <EmptyValueRenderer />
  }

  return (
    <Tooltip
      title={
        dataSubjectTypes.length === 1 ? undefined : (
          <SubjectTypeBulletedListTooltipContent dataSubjectTypes={dataSubjectTypes} />
        )
      }
    >
      <Box>
        {dataSubjectTypes.length === 1 ? (
          <Chip label={dataSubjectTypes?.[0]?.name} secondaryLabel={dataSubjectTypes?.[0]?.code} size="small" />
        ) : (
          <Chip clickable label="Multiple" size="small" />
        )}
      </Box>
    </Tooltip>
  )
}
