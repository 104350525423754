import React from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

import { FormMode } from 'interfaces/formModes/formMode'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { FormTemplateDTO } from '@ketch-com/figurehead'
import { getFormTemplateDeployedExperiences } from '../../upsert/utils'
import { useDeleteFormTemplate } from 'api/formTemplates/mutations/useDeleteFormTemplate'
import { useQueryClient } from 'react-query'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { AreYouSureYouWantToDeleteModal } from 'components/modals/AreYouSureYouWantToDeleteModal'
import { DeleteFormTemplateModalBody } from '../../components'
import { useIsExperienceServerAdmin } from 'pages/consentAndRights/forms/hooks'
import { PreviewFrom } from '../../preview/interfaces'
import { Box, Typography } from '@mui/material'
import { Button } from '@ketch-com/deck'

type Props = {
  formTemplate?: FormTemplateDTO
}

export const FormTemplateActions: React.FC<Props> = ({ formTemplate }) => {
  const [isDeleteFormTemplateModalOpen, setIsDeleteFormTemplateModalOpen] = React.useState<boolean>(false)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { isUserExperienceServerAdmin } = useIsExperienceServerAdmin()

  const { mutateAsync: handleDeleteFormTemplate, isLoading: isDeletingFormTemplate } = useDeleteFormTemplate({
    onSuccess: async () => {
      await queryClient.invalidateQueries(ApiQueryKeys.formTemplatesPaginated)
      showToast({ content: 'Form deleted', type: 'success' })
      navigate(RoutesManager.deployment.forms.templates.root.getURL())
    },
  })

  const handleRedirectToEdit = () => {
    const targetUrl = RoutesManager.deployment.forms.templates.upsert.root.getURL({
      id: formTemplate?.id || '',
      formMode: FormMode.EDIT,
    })
    navigate(targetUrl)
  }

  const isFormTemplateAssociatedWithDeployedExperiences = getFormTemplateDeployedExperiences(formTemplate).length > 0

  return (
    <>
      <Box display="flex" gap={1.5}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="smallBody" color="darkDuskFaded.main">
            Last Connected
          </Typography>

          <Typography color="darkDusk.main" variant="smallBody">
            <FormattedDateRenderer date={formTemplate?.metadata?.updatedAt} />
          </Typography>
        </Box>

        {isUserExperienceServerAdmin && (
          <>
            <Button
              onClick={() => {
                setIsDeleteFormTemplateModalOpen(true)
              }}
              color="tertiary"
              pending={isDeletingFormTemplate}
              size="large"
            >
              Delete
            </Button>

            <Button
              onClick={() => {
                handleRedirectToEdit()
              }}
              color="secondary"
              size="large"
            >
              Edit
            </Button>
          </>
        )}

        <Button
          onClick={() => {
            navigate({
              pathname: RoutesManager.deployment.forms.templates.upsert.preview.root.getURL({
                formMode: FormMode.EDIT,
                id: formTemplate?.id || '',
              }),
              search: createSearchParams({
                from: PreviewFrom.Detail,
              }).toString(),
            })
          }}
          color="primary"
          size="large"
        >
          Preview
        </Button>
      </Box>

      {isDeleteFormTemplateModalOpen && (
        <AreYouSureYouWantToDeleteModal
          title="Delete Form"
          cancelBtnLabel="Close"
          isBusy={false}
          isLoading={false}
          onCancel={() => setIsDeleteFormTemplateModalOpen(false)}
          onSubmit={
            isFormTemplateAssociatedWithDeployedExperiences
              ? undefined
              : () =>
                  handleDeleteFormTemplate({
                    params: {
                      formTemplateId: formTemplate?.id || '',
                    },
                  })
          }
          body={
            <DeleteFormTemplateModalBody
              formTemplate={formTemplate}
              isFormTemplateAssociatedWithDeployedExperiences={isFormTemplateAssociatedWithDeployedExperiences}
            />
          }
        />
      )}
    </>
  )
}
