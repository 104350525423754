import { Box } from '@mui/material'

import { useMegaFilterStyles } from '../hooks'
import { Checkbox } from 'components/ui-kit/checkbox/Checkbox'

import { FILTER_STATES } from '../constants'
import { FilterConfigItem } from '../utils/getFiltersConfig'
import { useAppSelector } from 'store/hooks'
import { getMegaFilterState } from 'store/megaFilterStateSlice/selectors'
import { useAppDispatch } from 'store/hooks'
import { addTypeFilter, removeTypeFilter } from 'store/megaFilterStateSlice'

type Props = {
  rowData: FilterConfigItem
  filterState: FILTER_STATES
}

export const MegaFilterTypeExpandableRow: React.FC<Props> = ({ rowData, filterState }) => {
  const classes = useMegaFilterStyles({ filterState })
  const { typeState } = useAppSelector(getMegaFilterState)
  const dispatch = useAppDispatch()

  return (
    <Box className={classes.filterOptionsContainer}>
      <Box display="flex" flexDirection="column">
        {rowData.fixedCheckboxes.map(({ id, value }) => {
          const isChecked = typeState.includes(id)
          return (
            <Box className={classes.typeCheckboxContainer} key={value}>
              <Checkbox
                id={value}
                name={value}
                title={value}
                value={isChecked}
                onChange={() => (isChecked ? dispatch(removeTypeFilter(id)) : dispatch(addTypeFilter(id)))}
              />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
