import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetProcessingActivityChatResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  activityId: string
}>

export const fetchProcessingActivityChat = ({ activityId }: Params) => {
  return API.get<GetProcessingActivityChatResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/processing-activities/${activityId}/chat`,
      params: {
        filters: {},
      },
    }),
  )
}
