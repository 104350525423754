import { getURLParams } from './utils'
import { FormMode } from 'interfaces/formModes/formMode'

export const assetManager = {
  root: {
    pattern: '/data-map',
    getURL: () => '/data-map',
  },

  assets: {
    root: {
      pattern: '/data-map/assets',
      getURL: () => '/data-map/assets',
    },
    list: {
      pattern: '/data-map/assets/view',
      getURL: () => '/data-map/assets/view',
    },
    upsert: {
      pattern: `/data-map/assets/:code/resource-code/:resourceTypeCode/edit`,
      getURL: ({ code, resourceTypeCode }: getURLParams) =>
        `/data-map/assets/${code}/resource-code/${resourceTypeCode}/edit`,
    },
    detail: {
      root: {
        pattern: `/data-map/assets/:code/resource-code/:resourceTypeCode/view`,
        getURL: ({ code, resourceTypeCode }: getURLParams) =>
          `/data-map/assets/${code}/resource-code/${resourceTypeCode}/view`,
      },

      overview: {
        pattern: `/data-map/assets/:code/resource-code/:resourceTypeCode/view/overview`,
        getURL: ({ code, resourceTypeCode }: getURLParams) =>
          `/data-map/assets/${code}/resource-code/${resourceTypeCode}/view/overview`,
      },

      dsrConfiguration: {
        pattern: `/data-map/assets/:code/resource-code/:resourceTypeCode/view/dsr_configuration`,
        getURL: ({ code, resourceTypeCode }: getURLParams) =>
          `/data-map/assets/${code}/resource-code/${resourceTypeCode}/view/dsr_configuration`,
      },

      tab: {
        pattern: `/data-map/assets/:code/resource-code/:resourceTypeCode/view/:tab`,
        getURL: ({ code, tab, resourceTypeCode }: getURLParams) =>
          `/data-map/assets/${code}/resource-code/${resourceTypeCode}/view/${tab}`,
      },

      datasets: {
        pattern: `/data-map/assets/:code/resource-code/:resourceTypeCode/view/datasets`,
        getURL: ({ code, resourceTypeCode }: getURLParams) =>
          `/data-map/assets/${code}/resource-code/${resourceTypeCode}/view/datasets`,
      },

      attributes: {
        pattern: `/data-map/assets/:code/resource-code/:resourceTypeCode/view/attributes`,
        getURL: ({ code, resourceTypeCode }: getURLParams) =>
          `/data-map/assets/${code}/resource-code/${resourceTypeCode}/view/attributes`,
      },
      schemas: {
        pattern: `/data-map/assets/:code/resource-code/:resourceTypeCode/view/schemas`,
        getURL: ({ code, resourceTypeCode }: getURLParams) =>
          `/data-map/assets/${code}/resource-code/${resourceTypeCode}/view/schemas`,
      },

      policies: {
        pattern: `/data-map/assets/:code/view/policies`,
        getURL: ({ code }: getURLParams) => `/data-map/assets/${code}/view/policies`,
      },

      purposes: {
        pattern: `/data-map/assets/:code/view/purposes`,
        getURL: ({ code }: getURLParams) => `/data-map/assets/${code}/view/purposes`,
      },

      hooks: {
        pattern: `/data-map/assets/:code/view/hooks`,
        getURL: ({ code }: getURLParams) => `/data-map/assets/${code}/view/hooks`,
      },

      scenarioDetailsTab: {
        pattern: `/data-map/assets/:id/resource-code/:resourceTypeCode/scenario/:scenarioId/edit/:tab`,
        getURL: ({ id, scenarioId, tab, resourceTypeCode }: getURLParams) =>
          `/data-map/assets/${id}/resource-code/${resourceTypeCode}/scenario/${scenarioId}/edit/${tab}`,
      },

      dsrConfigIdentity: {
        pattern: `/data-map/assets/:id/resource-code/:resourceTypeCode/scenario/:scenarioId/source/:assetType/identity-lookup/:formMode`,
        getURL: ({ id, scenarioId, formMode, resourceTypeCode, assetType }: getURLParams & { formMode: FormMode }) =>
          `/data-map/assets/${id}/resource-code/${resourceTypeCode}/scenario/${scenarioId}/source/${assetType}/identity-lookup/${formMode}`,
      },

      dsrConfigUpsert: {
        pattern: `/data-map/assets/:id/resource-code/:resourceTypeCode/scenario/:scenarioId/source/:assetType/configuration/:formMode`,
        getURL: ({ id, scenarioId, formMode, resourceTypeCode, assetType }: getURLParams & { formMode: FormMode }) =>
          `/data-map/assets/${id}/resource-code/${resourceTypeCode}/scenario/${scenarioId}/source/${assetType}/configuration/${formMode}`,
      },
    },
  },

  classifications: {
    root: {
      pattern: '/data-map/classifications',
      getURL: () => '/data-map/classifications',
    },
  },

  relationships: {
    list: {
      pattern: '/data-map/relationships',
      getURL: () => '/data-map/relationships',

      created: {
        pattern: '/data-map/relationships/created',
        getURL: () => '/data-map/relationships/created',
      },

      discovered: {
        pattern: '/data-map/relationships/discovered',
        getURL: () => '/data-map/relationships/discovered',
      },
    },
  },

  relationshipMapping: {
    list: {
      pattern: '/data-map/relationship-mapping',
      getURL: () => '/data-map/relationship-mapping',
    },
  },
}
