import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { FormColorInput } from '../FormColorInput'
import {
  BuilderTextInputMode,
  FormTextInput,
} from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'
import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'

export const BannerThemeHeaderForm: React.FC = () => {
  return (
    <>
      <SidebarFormGroup hasBorderBottom sx={{ pt: 0 }}>
        <FormColorInput label={'Title Color'} required name={themeUpsertFormKeys.bannerHeaderTitleColor} fullWidth />
      </SidebarFormGroup>
      <SidebarFormGroup title={'"X" Button'}>
        <FormColorInput
          name={themeUpsertFormKeys.bannerHeaderReturnButtonFillColor}
          label={'"X" Button Fill Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.bannerHeaderReturnButtonIconColor}
          label={'"X" Icon Color'}
          fullWidth
          required
        />
        <FormTextInput
          name={themeUpsertFormKeys.bannerHeaderReturnButtonCornerRadius}
          label={'"X" Button Corner Radius (px)'}
          inputWidth="92px"
          mode={BuilderTextInputMode.CornerRadius}
        />
      </SidebarFormGroup>
    </>
  )
}
