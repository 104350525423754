import { PreferenceCenterPage } from '@ketch-sdk/ketch-types'

export enum PreviewDisplayMode {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export const previewExcludedPages = [
  PreferenceCenterPage.PrivacyPolicy,
  PreferenceCenterPage.RequestsForm,
  PreferenceCenterPage.RequestsSubmitted,
]
