import React, { Fragment, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { compact } from 'lodash'
import Grid from '@mui/material/Grid'

import { useAuth } from 'utils/hooks/useAuth'
import { ICanvasStep, IEmailActivityParams } from 'pages/orchestration/workflows/edit/interfaces'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'

import { getEmailInitialValues } from 'pages/orchestration/workflows/edit/utils/forms/activities/email/emailInitialValues'
import { getEmailValidationSchema } from 'pages/orchestration/workflows/edit/utils/forms/activities/email/emailValidation'
import { formatCodeFromName } from 'utils/formatters'
import { Box, Typography } from '@mui/material'
import { FormInput } from 'components/form/FormInput'
import { Chip, Icon } from '@ketch-com/deck'
import { FormEmailsInput } from 'components/form/FormEmailsInput/FormEmailsInput'

const responseEmailTextRegEx =
  /Please send any replies to this email to ([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(, )*)+$/g

// Allowed to See Request Link Button
const AllowedOrgs = ['axonic', 'vara_labs', 'prod_test', 'utc']

interface IEmailFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const EmailForm: React.FC<IEmailFormProps> = props => {
  const { step, steps, handleClose, handleRemove, handleSave } = props

  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))
  const { userData } = useAuth()
  const [bodyCursorPos, setBodyCursorPos] = useState([-1, -1])

  const viewRequestLinkButton = AllowedOrgs.includes(userData.organizationCode)

  const onSubmit = (values: IEmailActivityParams) => {
    handleSave({
      step: {
        ...step,
        valid: true,
        code: values.code,
        description: values.description,
        activity: {
          ...step.activity,
          params: {
            to: values.to || [],
            cc: values.cc || [],
            responseEmail: values.responseEmail || [],
            body: values.body,
            subject: values.subject,
          },
        } as ICanvasStep['activity'],
      },
    })
  }

  const updateBodyCursorPos = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const { selectionStart, selectionEnd } = e.target
    if (
      selectionStart !== null &&
      Number.isInteger(selectionStart) &&
      selectionEnd !== null &&
      Number.isInteger(selectionEnd)
    ) {
      setBodyCursorPos([selectionStart, selectionEnd])
    }
  }

  const appendBodyResponseEmailText = (form: FormikProps<IEmailActivityParams>): void => {
    let bodyText = form.values.body
    const match = bodyText.match(responseEmailTextRegEx)
    if (match) {
      bodyText = bodyText.replaceAll(match[0], '')
    }
    if (form.values.responseEmail && form.values.responseEmail.length > 0) {
      if (bodyText.length && bodyText[bodyText.length - 1] !== '\n') {
        bodyText = bodyText + '\n'
      }
      bodyText = bodyText + `Please send any replies to this email to ${form.values.responseEmail.join(', ')}`
    }
    form.setFieldValue('body', bodyText)
  }

  const appendBodyVariable = (form: FormikProps<IEmailActivityParams>, text: string): void => {
    const [start, end] = bodyCursorPos
    let bodyText = form.values.body

    if (start > -1 && end > -1 && end >= start) {
      bodyText = `${bodyText.slice(0, start)}${text}${bodyText.slice(end, bodyText.length)}`
      form.setFieldValue('body', bodyText)
      setBodyCursorPos(([start, end]) => [start + text.length, end + text.length])
    } else {
      form.setFieldValue('body', form.values.body + `${text}`)
    }
  }

  return (
    <SidebarConfigsWrapper handleClose={handleClose}>
      <Formik
        initialValues={getEmailInitialValues({ step })}
        validationSchema={getEmailValidationSchema({ usedCodes })}
        validateOnMount
        onSubmit={onSubmit}
      >
        {form => {
          const { isValid, submitForm, setFieldValue } = form

          return (
            <Fragment>
              <SidebarConfigsHeader
                isValid={isValid}
                step={step}
                steps={steps}
                handleSave={() => submitForm()}
                handleRemove={() => handleRemove({ step })}
                handleClose={handleClose}
                copyable
              />

              <SidebarConfigsBody sx={{ px: 3 }}>
                <Box mb={5}>
                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormInput
                          required
                          formPropertyName="description"
                          fullWidth
                          label="Description Label"
                          placeholder="Enter description"
                          onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
                          shouldUpdateDebounced
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <FormInput
                        formPropertyName="code"
                        fullWidth
                        label="Code"
                        placeholder="Enter code"
                        required
                        shouldUpdateDebounced
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Box marginBottom={3}>
                    <Typography variant="h3">Email Details</Typography>
                  </Box>

                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormEmailsInput fullWidth formPropertyName="to" label="To" required />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormEmailsInput fullWidth formPropertyName="cc" label="Cc" required />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormEmailsInput
                          info="An email address for all replies to be sent to"
                          fullWidth
                          formPropertyName="responseEmail"
                          label="Response Email"
                          placeholder={form.values.responseEmail?.length ? '' : 'Response Email'}
                          onChange={() => appendBodyResponseEmailText(form)}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormInput
                          formPropertyName="subject"
                          fullWidth
                          label="Subject"
                          placeholder="Enter subject text"
                          required
                          shouldUpdateDebounced
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormInput
                          onBlur={updateBodyCursorPos}
                          size="medium"
                          formPropertyName="body"
                          fullWidth
                          label="Body"
                          placeholder="Enter body text"
                          required
                          multiline
                          rows={5}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom={3}>
                    <Typography variant="label">Data Subject Variables</Typography>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Chip
                          clickable
                          label="First Name"
                          deleteIcon={<Icon name="OPlus" />}
                          onClick={() => appendBodyVariable(form, '{{first_name}}')}
                          onDelete={() => appendBodyVariable(form, '{{first_name}}')}
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          clickable
                          label="Last Name"
                          deleteIcon={<Icon name="OPlus" />}
                          onClick={() => appendBodyVariable(form, '{{last_name}}')}
                          onDelete={() => appendBodyVariable(form, '{{last_name}}')}
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          clickable
                          label="Email"
                          deleteIcon={<Icon name="OPlus" />}
                          onClick={() => appendBodyVariable(form, '{{email}}')}
                          onDelete={() => appendBodyVariable(form, '{{email}}')}
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          clickable
                          label="Request ID"
                          deleteIcon={<Icon name="OPlus" />}
                          onClick={() => appendBodyVariable(form, '{{request_id}}')}
                          onDelete={() => appendBodyVariable(form, '{{request_id}}')}
                        />
                      </Grid>
                      {viewRequestLinkButton && (
                        <Grid item>
                          <Chip
                            clickable
                            label="Request Link"
                            deleteIcon={<Icon name="OPlus" />}
                            onClick={() => appendBodyVariable(form, '{{request_link}}')}
                            onDelete={() => appendBodyVariable(form, '{{request_link}}')}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Box>
              </SidebarConfigsBody>
            </Fragment>
          )
        }}
      </Formik>
    </SidebarConfigsWrapper>
  )
}
