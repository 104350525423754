import React, { useState, useEffect, HTMLAttributes } from 'react'
import { useDebounce } from 'react-use'
import { uniqBy } from 'lodash'

// API
import { useDataSubjectTypes } from 'api/dataSubjectTypes/queries/useDataSubjectTypes'
import { useOrchestrationEvents } from 'api/orchestrationEventsV2/queries/useOrchestrationEvents'

// dux
import { getEventsListFilterState } from 'store/eventsListFilterSlice/selectors'
import {
  clearSearchQuery,
  setDataSubjectTypeCodeFilter,
  setRightCodeFilter,
  setRightTypeFilter,
  setSearchQuery,
} from 'store/eventsListFilterSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'

// types
import { DataRoleType } from 'interfaces/events'
import { RightType } from 'interfaces/events'
import { ActionSheetItem, DropListButton, Icon, ListItemText, TextInput } from '@ketch-com/deck'
import { Box } from '@mui/material'

const eventTypeOptions = [
  {
    value: RightType.RIGHT_TYPE_UNSPECIFIED,
    label: 'All',
  },
  {
    value: RightType.RIGHT_TYPE_CANONICAL,
    label: 'Canonical',
  },
  {
    value: RightType.RIGHT_TYPE_CUSTOM,
    label: 'Custom',
  },
]

type Props = {}

export const EventsListFiltersV2: React.FC<Props> = () => {
  // hooks
  const dispatch = useAppDispatch()
  const { dataRole, dataSubjectTypeCode, rightCode, rightType, searchQuery } = useAppSelector(getEventsListFilterState)
  const [searchString, setSearchString] = useState(() => searchQuery || '')

  // apis
  const { data: dataSubjectTypes } = useDataSubjectTypes()
  const { data: events } = useOrchestrationEvents({
    params: {
      dataRole,
      rightType,
      dataSubjectTypeCode,
    },
  })

  // Handles reset of data when switched from controller to processor view with dataSubjectTypeCode set as customer
  useEffect(() => {
    if (dataRole === DataRoleType.DATA_ROLE_PROCESSOR) {
      dispatch(setDataSubjectTypeCodeFilter(''))
    }
  }, [dataRole, dataSubjectTypeCode, dispatch])

  // utils
  const eventsList = events.map(({ rightCode: code, rightName }) => ({
    active: rightCode === code,
    label: rightName,
    value: code,
    onClick: () => {
      dispatch(setRightCodeFilter(code || ''))
    },
  }))

  const eventsAllOption = {
    active: rightCode === '',
    value: '',
    label: 'All Rights',
    onClick: () => dispatch(setRightCodeFilter('')),
  }

  const dataSubjectTypesList = dataSubjectTypes.map(({ code, name }) => {
    return {
      active: dataSubjectTypeCode === code,
      label: name,
      value: code,
      onClick: () => {
        dispatch(setDataSubjectTypeCodeFilter(code || ''))
      },
    }
  })

  const dataSubjectTypesAllOption = {
    active: dataSubjectTypeCode === '',
    label: 'All Subject Types',
    value: '',
    onClick: () => dispatch(setDataSubjectTypeCodeFilter('')),
  }

  useDebounce(
    () => {
      if (searchString) {
        dispatch(setSearchQuery(searchString))
      } else {
        dispatch(clearSearchQuery())
      }
    },
    500,
    [searchString],
  )

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: {
      active?: boolean
      label?: string
      value?: string | number
      onClick?: () => any
    },
    { selected }: { selected: boolean },
  ) => (
    <ActionSheetItem selected={selected} {...props}>
      <ListItemText selected={selected}>{option.label}</ListItemText>
    </ActionSheetItem>
  )

  return (
    <Box display="flex" gap={3}>
      {/* Search box */}
      <TextInput
        size="small"
        placeholder="Search"
        startIcon={<Icon name="OMag" />}
        onChange={e => setSearchString(e.target.value)}
        value={searchString}
        inputWidth="176px"
      />

      <Box display="flex" gap={1}>
        {/* Types list */}
        <DropListButton
          size="small"
          renderOption={renderOption}
          placeholder={'All Types'}
          options={eventTypeOptions}
          onChange={(_, option) => option?.value && dispatch(setRightTypeFilter(option.value as number))}
          disableClearable
        />

        {/* Rights list */}
        <DropListButton
          size="small"
          renderOption={renderOption}
          placeholder={rightCode === '' ? 'All Rights' : ''}
          options={uniqBy([eventsAllOption, ...eventsList], 'value')}
          onChange={(_, option) => option?.onClick()}
          disableClearable
        />

        {/* Subject types list */}
        {dataRole !== DataRoleType.DATA_ROLE_PROCESSOR && (
          <DropListButton
            size="small"
            renderOption={renderOption}
            placeholder={dataSubjectTypeCode === '' ? 'All Subject Types' : ''}
            options={[dataSubjectTypesAllOption, ...dataSubjectTypesList]}
            onChange={(_, option) => option?.onClick()}
            disableClearable
          />
        )}
      </Box>
    </Box>
  )
}
