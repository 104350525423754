import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetRelatedAssetsResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

type Params = APIListRequestParams<{
  assetId?: string
  lookupType?: number
  depth?: number
  isPersonal?: boolean
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchRelatedDataSets = ({
  assetId,
  start,
  limit,
  query,
  lookupType,
  depth,
  isPersonal,
  isV2FeatureFlagEnabled,
}: Params) => {
  let entityUrl = `/api/v3/assets/${assetId}/related`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/assets/${assetId}/related`

  return API.get<GetRelatedAssetsResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          ...(start && {
            start,
          }),
          ...(limit && {
            limit,
          }),
          ...(query && {
            query,
          }),
          ...(depth && {
            depth,
          }),
          ...(isPersonal && {
            isPersonal,
          }),
          lookupType,
        },
      },
    }),
  )
}
