import { Box } from '@mui/material'
import { ProgressIndicator } from '../view/components/ProgressIndicator'
import { Icon, theme } from '@ketch-com/deck'

export enum IconType {
  DELETE = 'delete',
  VIEW = 'view',
  PROGRESS = 'progress',
  EXPIRED = 'expired',
  APPROVED = 'approved',
  REVIEW = 'review',
  COMPLETE = 'complete',
}

type Props = {
  type: IconType
  onClick?: () => void
  percent?: number
  width?: number
  height?: number
}

const backgroundColors = {
  [IconType.DELETE]: 'fadedGrey.main',
  [IconType.VIEW]: 'fadedGrey.main',
  [IconType.PROGRESS]: undefined, // Depends on percentage, see below
  [IconType.EXPIRED]: 'fadedHeliotrope.main',
  [IconType.APPROVED]: 'fadedGrey.main',
  [IconType.REVIEW]: 'sphereFaded.main',
  [IconType.COMPLETE]: 'tara.main',
}

const getProgressBarColors = (percent: number) =>
  percent > 1
    ? percent > 70
      ? {
          bgcolor: theme.palette.tara.main,
          fgcolor: theme.palette.greenHaze.main,
          highlightColor: theme.palette.greenHazeFaded.main,
        }
      : {
          bgcolor: theme.palette.bleachWhite.main,
          fgcolor: theme.palette.chileanFire.main,
          highlightColor: theme.palette.chileanFireFaded.main,
        }
    : {
        bgcolor: theme.palette.fadedGrey.main,
        fgcolor: theme.palette.darkDusk.main,
        highlightColor: theme.palette.lightGrey.main,
      }

export const IconButton: React.FC<Props> = props => {
  // Destructure the props here so that we can pass the unaltered props to the ProgressIndicator
  const { type, onClick, percent = 0, width = 28, height = 28 } = props

  const progressBarStyle = getProgressBarColors(percent)

  return (
    <Box
      borderRadius="5px"
      height={width}
      width={height}
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={backgroundColors[type] || progressBarStyle.bgcolor}
      sx={{ cursor: !!onClick ? 'pointer' : undefined }}
      onClick={onClick}
    >
      {type === 'delete' ? (
        <Icon name="FBin" iconColor={theme.palette.darkDusk.main} />
      ) : type === 'approved' ? (
        <Icon name="FCheckRound" iconColor={theme.palette.darkDusk.main} />
      ) : type === 'review' ? (
        <Icon name="FEyeShow" iconColor={theme.palette.sphere.main} />
      ) : type === 'complete' ? (
        <Icon name="OCheckRound" iconColor={theme.palette.greenHaze.main} />
      ) : type === 'expired' ? (
        <Icon name="OImportant" iconColor={theme.palette.heliotrope.main} />
      ) : type === 'progress' ? (
        <ProgressIndicator {...props} style={progressBarStyle} />
      ) : (
        <Icon name="OArrowCRight" />
      )}
    </Box>
  )
}
