import React from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

type Props = {
  date?: string
}

export const DatePill: React.FC<Props> = ({ date }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mb: 2.5,
      }}
    >
      <Box
        sx={{
          border: ({ palette }) => `1px solid ${palette.iron.main}`,
          borderRadius: 12.5,
          px: 1.75,
          pt: 0.25,
          pb: 0.5,
        }}
      >
        <Typography variant="smallLabel">{date}</Typography>
      </Box>
    </Box>
  )
}
