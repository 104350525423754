import React, { SyntheticEvent, useContext, useMemo, useRef } from 'react'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ActionSheetItem, ChipsAutocomplete, ChipsAutocompleteOptionType, theme } from '@ketch-com/deck'
import { Box, FormControlLabel, ListItemText, Typography } from '@mui/material'
import { FormSectionSwitch } from '../FormSectionSwitch'
import { SidebarFormRadioGroup } from '../SidebarFormRadioGroup'
import { SwitchButtonDisplayLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { SwitchButtonDisplay } from '@ketch-sdk/ketch-types'
import { FormTextInput } from '../FormTextInput'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { getFormNameForLocale } from 'pages/consentAndRights/experiences-v2/upsert/utils/utils'
import { defaultProgressiveExperienceV2DTO } from 'pages/consentAndRights/experiences-v2/upsert/utils/defaults'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { FormCheckbox } from '../FormCheckbox'
import { usePurposes } from 'api/purposes/queries/usePurposes'

export const ProgressiveExperienceSwitchButtonForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ExperienceV2DTO>()
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)

  // Get purpose options
  const { data: purposes } = usePurposes()
  const purposesOptions = useMemo(
    () =>
      purposes?.map(purpose => ({
        label: purpose.name,
        value: purpose.code,
      })),
    [purposes],
  )

  // Get the current mapped purposes for each button
  const switchButtonPurposeCodes = getIn(
    values,
    experienceUpsertFormKeys.progressiveExperienceSwitchButtonsPurposeCodes,
  ) as string[]
  const switchButtonPurposeCodesValue: ChipsAutocompleteOptionType[] = useMemo(() => {
    return switchButtonPurposeCodes.map(code => ({
      label: purposes?.find(purpose => purpose.code === code)?.name,
      value: code,
    }))
  }, [switchButtonPurposeCodes, purposes])

  const hasLanguages = !isLanguagesLoading && !!languages.length
  const showSwitchButtonForm = getIn(values, experienceUpsertFormKeys.progressiveExperienceSwitchButtonsVisible)
  const showCustomSwitchButtonForm = !getIn(
    values,
    experienceUpsertFormKeys.progressiveExperienceSwitchButtonsUseDefaultText,
  )

  const handleDefaultChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked && !isLanguagesLoading) {
      // Reset both the on and off text
      ;[
        experienceUpsertFormKeys.progressiveExperienceSwitchButtonsOnText,
        experienceUpsertFormKeys.progressiveExperienceSwitchButtonsOffText,
      ].forEach(englishFieldToReset => {
        // Reset to english default
        const defaultEnglishValue = getIn(defaultProgressiveExperienceV2DTO, englishFieldToReset)
        setFieldValue(englishFieldToReset, defaultEnglishValue)

        // Reset to default for each locale
        languages.forEach(({ language: { code } }) => {
          const localeFieldToReset = getFormNameForLocale(englishFieldToReset, code)
          const defaultLocaleValue = getIn(defaultProgressiveExperienceV2DTO, localeFieldToReset)
          setFieldValue(localeFieldToReset, defaultLocaleValue)
        })
      })
    }
  }

  const ref = useRef()

  const renderAutocompleteOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: ChipsAutocompleteOptionType,
  ) => (
    <ActionSheetItem {...props} key={option.value}>
      <ListItemText>{option.label}</ListItemText>
    </ActionSheetItem>
  )

  return (
    <SidebarFormGroup sx={{ pt: 0 }}>
      <Box>
        <ChipsAutocomplete
          ref={ref.current}
          fullWidth
          renderOption={renderAutocompleteOption}
          isOptionEqualToValue={(elem, value) => elem.value === value.value}
          disableClearable
          label="Selected Purposes"
          placeholder="Select a value"
          chipSize="medium"
          size="medium"
          multiple
          options={purposesOptions}
          onChange={(_, newValue) => {
            setFieldValue(
              experienceUpsertFormKeys.progressiveExperienceSwitchButtonsPurposeCodes,
              newValue.map(value => value.value),
            )
          }}
          handleChipClick={v => {
            const currentValueWithoutPurpose = switchButtonPurposeCodes.filter(code => code !== v.value)
            setFieldValue(
              experienceUpsertFormKeys.progressiveExperienceSwitchButtonsPurposeCodes,
              currentValueWithoutPurpose,
            )
          }}
          value={switchButtonPurposeCodesValue}
        />
        <FormSectionSwitch
          name={experienceUpsertFormKeys.progressiveExperienceSwitchButtonsVisible}
          title={'Switch Button Labels'}
        />
        {showSwitchButtonForm && (
          <Box p={'16px 18px'}>
            <SidebarFormGroup hasBorderBottom sx={{ pt: 0, pb: 3 }}>
              <SidebarFormRadioGroup
                name={experienceUpsertFormKeys.progressiveExperienceSwitchButtonsDisplay}
                title={'Display'}
                options={Object.values(SwitchButtonDisplay).map(display => ({
                  label: SwitchButtonDisplayLabels[display],
                  value: display,
                }))}
              />
            </SidebarFormGroup>
            <SidebarFormGroup sx={{ pt: 3, pb: 4 }} hasBorderBottom={showCustomSwitchButtonForm && hasLanguages}>
              <Box>
                <FormControlLabel
                  control={
                    <FormCheckbox
                      name={experienceUpsertFormKeys.progressiveExperienceSwitchButtonsUseDefaultText}
                      afterChange={handleDefaultChange}
                    />
                  }
                  label={<Typography variant={'label'}>Use Default Text</Typography>}
                />
                <Typography component={'p'} variant="body" color={theme.palette.darkDuskFaded.main}>
                  The default text "Opted In" and "Opted Out" has translations for all available languages.
                </Typography>
              </Box>
              {showCustomSwitchButtonForm && (
                <>
                  <FormTextInput
                    label={'Switch Button ON Text'}
                    name={experienceUpsertFormKeys.progressiveExperienceSwitchButtonsOnText}
                    fullWidth
                    charLimit={30}
                  />
                  <FormTextInput
                    label={'Switch Button OFF Text'}
                    name={experienceUpsertFormKeys.progressiveExperienceSwitchButtonsOffText}
                    fullWidth
                    charLimit={30}
                  />
                </>
              )}
            </SidebarFormGroup>
            {showCustomSwitchButtonForm && (
              <SidebarFormTranslations
                title={'Switch Button Text Translations'}
                switchButtonOnName={experienceUpsertFormKeys.progressiveExperienceSwitchButtonsOnText}
                switchButtonOffName={experienceUpsertFormKeys.progressiveExperienceSwitchButtonsOffText}
                variant={'switchButtons'}
                charLimit={30}
              />
            )}
          </Box>
        )}
      </Box>
    </SidebarFormGroup>
  )
}
