import { createContext } from 'react'
import { ApprovedTrackerPropertiesUtils } from 'pages/policyCenter/trackers/interfaces'
import { useApprovedTrackerPropertiesUtils } from 'pages/policyCenter/trackers/hooks'

export const ApprovedTrackerPropertiesContext = createContext({} as ApprovedTrackerPropertiesUtils)

type Props = {
  children?: React.ReactNode
}

export const ApprovedTrackerPropertiesContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useApprovedTrackerPropertiesUtils()

  return (
    <ApprovedTrackerPropertiesContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </ApprovedTrackerPropertiesContext.Provider>
  )
}

export const withApprovedTrackerPropertiesContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <ApprovedTrackerPropertiesContextProvider>
        <Component {...props} />
      </ApprovedTrackerPropertiesContextProvider>
    )
  }
}
