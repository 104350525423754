import { FindAssetAssignedClassificationsV3ResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  installedDataSystemId?: string
  query?: string
  start?: number
  limit?: number
}>

export const fetchAssignedDataCategories = ({ installedDataSystemId, query, start, limit }: Params) => {
  return API.get<FindAssetAssignedClassificationsV3ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v3/assets/${installedDataSystemId}/classifications`,
      params: {
        filters: {
          query,
          start,
          limit,
        },
      },
    }),
  )
}
