import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchJurisdictionPurposesPreviews } from 'api/purposes/fetchers/fetchJurisdictionPurposesPreview'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.jurisdictionPurposesPreview,
  queryFn: fetchJurisdictionPurposesPreviews,
  select: res => res?.data || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useJurisdictionPurposesPreviews = (config?: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
