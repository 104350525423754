import React from 'react'
import { Box } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import Avatar from '@mui/material/Avatar'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { duotoneLight } from 'react-syntax-highlighter/dist/cjs/styles/prism'

import { Text } from 'components/ui-kit/typography/Text'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { AssignedAssetsListTableActionsCellRenderer, InfoRow } from './components'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { DmlHookDTO } from '@ketch-com/figurehead'
import { HOOK_OPERATION_TYPE_NAMES, HOOK_TYPE_NAMES } from '../../constants'
import { Tabs } from 'components/ui-kit/tabs/Tabs'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { AssetTypeIcon } from 'pages/assetManager/components'
import { Badge } from 'components/ui-kit/badge/Badge'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import numeral from 'numeral'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateCommon.svg'
import { useHookOverviewModalUtils } from './hooks'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      tabsContainer: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${palette.iron.main}`,
        mb: 1.5,
      },
      tab: {
        margin: spacing(0, 2),
      },
      tableAssetTypeIcon: {
        width: 22,
        height: 22,
        marginRight: spacing(1),
      },
      dataSetIcon: {
        marginRight: spacing(1),
      },
      labelsCountPill: {
        backgroundColor: palette.marine.main,
        borderRadius: '50%',
        padding: spacing(0.75, 0.75),
      },
    }),
  { name: 'HookOverviewModal' },
)

type Props = {
  hook?: DmlHookDTO
  onCancel?: () => void
  shouldHideAssetsAssignmentTab?: boolean
}

export const HookOverviewModal: React.FC<Props> = ({ onCancel, hook, shouldHideAssetsAssignmentTab = false }) => {
  const classes = useStyles()
  const {
    selectedTab,
    setSelectedTab,
    searchString,
    setSearchString,
    assetSummaries,
    pagination,
    isLoading,
    totalResults,
  } = useHookOverviewModalUtils({ hookId: hook?.id })

  let tabsConfig = [
    {
      key: 'overview',
      active: selectedTab === 'overview',
      title: 'Overview',
      onClick: () => setSelectedTab('overview'),
    },
  ]

  if (!shouldHideAssetsAssignmentTab) {
    tabsConfig.push({
      key: 'assetsAssignment',
      active: selectedTab === 'assetsAssignment',
      title: 'Assets Assignment',
      onClick: () => setSelectedTab('assetsAssignment'),
    })
  }

  return (
    <Dialog
      title={hook?.name}
      contentWidth={968}
      submitBtnLabel="Delete"
      cancelBtnLabel="Close"
      onCancelBtnClick={onCancel}
    >
      <Box>
        <Box className={classes.tabsContainer}>
          <Tabs tabClassName={classes.tab} config={tabsConfig} size="regular" />
        </Box>

        {selectedTab === 'overview' ? (
          <>
            <InfoRow title="Name" hasBorderBottom info={<Text size={14}>{hook?.name}</Text>} />

            <InfoRow
              title="Type"
              hasBorderBottom
              info={
                <Text
                  size={14}
                  weight={600}
                  sx={{
                    textTransform: 'uppercase',
                  }}
                >
                  {HOOK_TYPE_NAMES[hook?.type || 0]}
                </Text>
              }
            />

            <InfoRow
              title="Technology"
              hasBorderBottom
              info={
                <Box display="flex" alignItems="center" pb={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 56,
                    }}
                  >
                    <Avatar
                      alt={hook?.technology}
                      src={getAssetCloudImgUrl(hook?.technology || '')}
                      sx={{ width: 38, height: 38, mr: 1.375 }}
                    />
                    <Text size={14} sx={{ textTransform: 'capitalize' }}>
                      {hook?.technology}
                    </Text>
                  </Box>
                </Box>
              }
            />

            <InfoRow
              title="Operation"
              hasBorderBottom
              info={
                <Text size={14} weight={600}>
                  {HOOK_OPERATION_TYPE_NAMES[hook?.operation || 0]}
                </Text>
              }
            />

            <InfoRow
              title="Code"
              hasBorderBottom
              info={
                <SyntaxHighlighter language="sql" style={duotoneLight} wrapLongLines={true}>
                  {hook?.dmlCode || ''}
                </SyntaxHighlighter>
              }
            />
          </>
        ) : (
          <>
            <Box display="flex" alignItems="center" mt={2} mb={1}>
              <SearchInput
                size="small"
                onChange={nextSearchString => setSearchString(nextSearchString)}
                onClear={() => setSearchString('')}
                value={searchString}
              />
              <Text size={14} weight={600} ml={2}>
                Assigned to {numeral(totalResults).format('0,0')} assets
              </Text>
            </Box>

            {!isLoading && (assetSummaries || [])?.length === 0 ? (
              <EmptyState
                icon={<EmptyStateIcon />}
                variant="list"
                bgVariant="white"
                title="Query did not return any results or there are no assets this hook is assigned to."
              />
            ) : (
              <>
                <TablePaginated
                  pending={isLoading}
                  pagination={pagination}
                  isSmallHeaderText
                  items={assetSummaries || []}
                  rowIdKey={rowData => {
                    return rowData?.asset?.resource?.id as string
                  }}
                  cellSettings={{
                    name: {
                      width: 390,
                      label: 'Name',
                      cellRenderer: assetSummary => (
                        <NameAndCodeCellRenderer
                          name={assetSummary?.asset?.resource?.name}
                          code={assetSummary?.asset?.resource?.id || ''}
                        />
                      ),
                    },

                    technologies: {
                      width: 119,
                      label: 'Technologies',
                      cellRenderer: assetSummary => {
                        return (
                          <Box display="flex" alignItems="center">
                            <img
                              className={classes.tableAssetTypeIcon}
                              src={getAssetCloudImgUrl(assetSummary?.asset?.connection?.technology || '')}
                              alt={getAssetCloudImgUrl(assetSummary?.asset?.connection?.technology || '')}
                            />
                          </Box>
                        )
                      },
                    },

                    type: {
                      width: 120,
                      label: 'Type',
                      cellRenderer: assetSummary => {
                        return (
                          <Box display="flex" alignItems="center">
                            <AssetTypeIcon
                              assetType={assetSummary?.asset?.resource?.resourceType?.canonicalResourceType || 0}
                              className={classes.dataSetIcon}
                            />
                            <Text size={11} weight={600} color="grey">
                              {assetSummary?.asset?.resource?.resourceType?.name}
                            </Text>
                          </Box>
                        )
                      },
                    },

                    personalDataType: {
                      width: 140,
                      label: 'Data Sensitivity',
                      cellRenderer: assetSummary =>
                        assetSummary?.asset?.dataCategory?.[0]?.value ? (
                          <Badge variant="neutral">{assetSummary?.asset?.dataCategory?.[0]?.value}</Badge>
                        ) : (
                          <EmptyValueRenderer />
                        ),
                    },

                    labels: {
                      width: 80,
                      label: 'Classifications',
                      cellRenderer: ({ asset }) => {
                        const assignedLabels = asset?.assignedLabels || []
                        const inferredLabels = asset?.inferredLabels || []
                        const collectedLabels = [...assignedLabels, ...inferredLabels]
                        return (
                          <Box className={classes.labelsCountPill} display="flex" alignItems="center">
                            <Text size={11} weight={500} color="persianBlue">
                              {collectedLabels?.length || 0}
                            </Text>
                          </Box>
                        )
                      },
                    },
                    actions: {
                      cellRenderer: assetSummary => {
                        return <AssignedAssetsListTableActionsCellRenderer asset={assetSummary} />
                      },
                    },
                  }}
                />
              </>
            )}
          </>
        )}
      </Box>
    </Dialog>
  )
}

// TODO:BAC - this styling was de-scoped.  Was originally intended for the "Technology" InfoRow.
// Saving here as this will be what replaces it once it's clickable.

// <InfoRow
//   title="Technology"
//   hasBorderBottom
//   info={
//     <Box display="flex" alignItems="center" pb={2}>
//       <Box
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           backgroundColor: 'sphereFaded.main',
//           borderRadius: 100,
//           height: 56,
//           py: 1.25,
//           px: 1.75,
//         }}
//       >
// <Avatar
//   alt={hook?.technology}
//   src={getAssetCloudImgUrl(hook?.technology || '')}
//   sx={{ width: 38, height: 38, mr: 1.375 }}
// />
//         <Text size={14} color="royalBlue">
//           {hook?.technology}
//         </Text>
//         <KeyboardArrowRight
//           sx={{
//             color: 'royalBlue.main',
//           }}
//         />
//       </Box>
//     </Box>
//   }
// />
