import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { DeleteApiKeyModal } from 'components/modals/apiKeys/DeleteApiKeyModal'
import { ApiKeyDTO, ApiKeyStatus } from 'interfaces/apiKeys'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { ToggleApiKeyActivationStatusModal } from 'components/modals/apiKeys/ToggleApiKeyActivationStatusModal'

type Props = {
  apiKey: ApiKeyDTO
}

export const ApiKeyListDropdown: React.FC<Props> = ({ apiKey }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isBusy] = useState(false)
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleDeactivateApiKey = async () => {
    await queryClient.refetchQueries(ApiQueryKeys.apiKeysPaginated)
    navigate(RoutesManager.developers.apiKeys.root.getURL())
  }

  return (
    <Fragment>
      <Button
        size="medium"
        variant="iconSubtle"
        onClick={handleClickListItem}
        pending={isBusy}
        disabled={!isPermitted(PERMISSIONS.API_KEY_WRITE)}
      >
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transitionDuration={0}
        onClick={e => e.stopPropagation()}
      >
        <ActionSheetItem
          onClick={e => {
            handleClose()
            navigate(RoutesManager.developers.apiKeys.upsert.getURL({ id: apiKey.id }))
          }}
        >
          Edit
        </ActionSheetItem>
        <ActionSheetItem divider />
        <ActionSheetItem
          onClick={e => {
            handleClose()
            setIsDeleteModalOpen(true)
          }}
        >
          Delete
        </ActionSheetItem>
        <ActionSheetItem divider />
        <ActionSheetItem
          onClick={e => {
            handleClose()
            setIsDeactivateModalOpen(d => !d)
          }}
        >
          {apiKey?.status === ApiKeyStatus.ENABLED ? 'Deactivate' : 'Activate'}
        </ActionSheetItem>
      </ActionSheetWrapper>

      {isDeleteModalOpen && (
        <DeleteApiKeyModal
          apiKeyId={apiKey.id}
          onCancel={() => setIsDeleteModalOpen(false)}
          onSubmit={async () => {
            await queryClient.refetchQueries(ApiQueryKeys.apiKeysPaginated)

            setIsDeleteModalOpen(false)
          }}
        />
      )}
      {isDeactivateModalOpen && (
        <ToggleApiKeyActivationStatusModal
          apiKeyId={apiKey.id!}
          onCancel={() => setIsDeactivateModalOpen(false)}
          onSubmit={() => {
            setIsDeactivateModalOpen(false)
            return handleDeactivateApiKey()
          }}
        />
      )}
    </Fragment>
  )
}
