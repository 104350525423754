import { useState } from 'react'
import { useApprovedTrackersInfinite } from 'api/trackers/queries/useApprovedTrackersInfinite'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useTrackerStatistics } from 'api/trackers/queries/useTrackerStatistics'
import { useUpsertTrackerPurposes } from 'api/trackers/mutations/useUpsertTrackerPurposes'
import { useUpsertTrackerDisclosure } from 'api/trackers/mutations/useUpsertTrackerDisclosure'
import { useUpsertTrackerPropertyDisclosure } from 'api/trackers/mutations/useUpsertTrackerPropertyDisclosure'
import { GetTrackersParams } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { MaybeNull } from 'interfaces'
import { DrawerType } from 'pages/policyCenter/trackers/interfaces'

export const useApprovedTrackersUtils = () => {
  const [getApprovedTrackersParams, setGetApprovedTrackersParams] = useState<GetTrackersParams>({ searchString: '' })
  const [showDrawer, setShowDrawer] = useState<MaybeNull<DrawerType>>(null)
  const queryClient = useQueryClient()

  const {
    trackers: approvedTrackers,
    isFetching: isFetchingApprovedTrackers,
    isLoading: isLoadingApprovedTrackers,
    isFetchingNextPage: isFetchingNextApprovedTrackers,
    hasNextPage: hasNextApprovedTrackers,
    fetchNextPage: fetchNextApprovedTrackers,
  } = useApprovedTrackersInfinite(getApprovedTrackersParams)

  const { data: trackerStatistics, isLoading: isLoadingTrackerStatistics } = useTrackerStatistics({})

  const { mutateAsync: upsertApprovedTrackerPurposes, isLoading: isUpdatingApprovedTrackerPurposes } =
    useUpsertTrackerPurposes({
      onSuccess: async () => {
        await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite])
        showToast({ content: 'Updated purpose', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to update purpose', type: 'error' })
      },
    })

  const { mutateAsync: upsertApprovedTrackersDisclosure, isLoading: isUpdatingApprovedTrackersDisclosure } =
    useUpsertTrackerDisclosure({
      onSuccess: async () => {
        await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite])
        showToast({ content: 'Updated disclosure', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to update disclosure', type: 'error' })
      },
    })

  const {
    mutateAsync: upsertApprovedTrackersPropertyDisclosure,
    isLoading: isUpdatingApprovedTrackersPropertyDisclosure,
  } = useUpsertTrackerPropertyDisclosure({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite])
      await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackerPropertiesInfinite])
      showToast({ content: 'Updated disclosure', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update disclosure', type: 'error' })
    },
  })

  const isReady = !isLoadingApprovedTrackers && !isLoadingTrackerStatistics

  return {
    isReady,
    approvedTrackers,
    isFetchingApprovedTrackers,
    isLoadingApprovedTrackers,
    isFetchingNextApprovedTrackers,
    hasNextApprovedTrackers,
    fetchNextApprovedTrackers,
    upsertApprovedTrackerPurposes,
    isUpdatingApprovedTrackerPurposes,
    upsertApprovedTrackersDisclosure,
    isUpdatingApprovedTrackersDisclosure,
    upsertApprovedTrackersPropertyDisclosure,
    isUpdatingApprovedTrackersPropertyDisclosure,
    showDrawer,
    setShowDrawer,
    trackerStatistics,
    getApprovedTrackersParams,
    setGetApprovedTrackersParams,
  }
}
