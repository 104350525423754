import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchCanonicalRight } from 'api/rights/fetchers/fetchCanonicalRight'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.canonicalRight,
  queryFn: fetchCanonicalRight,
  select: res => res?.data?.canonicalRight || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useCanonicalRight = (config?: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
