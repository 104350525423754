import { IssueDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'

export const InvalidCredentialsIssueType = 'app_instance_credentials'

export const hasInvalidCredentialsIssue = (status: WorkflowExecutionStepStatus, issues?: IssueDTO[]): boolean => {
  if (!issues) return false
  return (
    issues.some(issue => issue.entityType === InvalidCredentialsIssueType) &&
    status === WorkflowExecutionStepStatus.FAIL
  )
}
