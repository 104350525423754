import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum HEADER_DISPLAY_MODE {
  STUCK = 'stuck',
  FREE = 'free',
}

interface AssetDetailViewState {
  displayMode: HEADER_DISPLAY_MODE
  selectedAssets: string[]
  isShowOnlySelected: boolean
  searchString: string
  searchQuery: string
  isAssignDataCategoriesModalOpen: boolean
  backToUrlHistory: string[]
}

const initialState = {
  searchQuery: '',
  displayMode: HEADER_DISPLAY_MODE.FREE,
  selectedAssets: [],
  isShowOnlySelected: false,
  searchString: '',
  isAssignDataCategoriesModalOpen: false,
  backToUrlHistory: [],
} as AssetDetailViewState

export const assetDetailView = createSlice({
  name: 'assetDetailView',
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload
    },
    clearSearchQuery: state => {
      state.searchQuery = ''
    },
    setDisplayMode: (state, action: PayloadAction<HEADER_DISPLAY_MODE>) => {
      state.displayMode = action.payload
    },
    selectAssetById: (state, action: PayloadAction<string>) => {
      state.selectedAssets.push(action.payload)
    },
    selectMultipleAssets: (state, action: PayloadAction<string[]>) => {
      state.selectedAssets = action.payload
    },
    setIsAssignDataCategoriesModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isAssignDataCategoriesModalOpen = action.payload
    },
    removeAssetById: (state, action: PayloadAction<string>) => {
      const newAssets = state.selectedAssets.filter(asset => asset !== action.payload)
      state.selectedAssets = newAssets
    },
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload
    },
    setIsShowOnlySelected: (state, action: PayloadAction<boolean>) => {
      state.isShowOnlySelected = action.payload
    },
    clearSelectedAssets: state => {
      state.selectedAssets = []
    },
    resetAssetDetailViewState: state => {
      state.searchQuery = ''
      state.displayMode = HEADER_DISPLAY_MODE.FREE
      state.selectedAssets = []
      state.isShowOnlySelected = false
      state.searchString = ''
      state.backToUrlHistory = []
    },
    pushEntryOntoBackToUrlHistory: (state, action: PayloadAction<string>) => {
      state.backToUrlHistory.push(action.payload)
    },
    popEntryFromBackToUrlHistory: state => {
      state.backToUrlHistory.pop()
    },
    setBackToUrlHistory: (state, action: PayloadAction<string[]>) => {
      state.backToUrlHistory = action.payload
    },
  },
})

export const {
  clearSearchQuery,
  clearSelectedAssets,
  removeAssetById,
  resetAssetDetailViewState,
  selectAssetById,
  setDisplayMode,
  selectMultipleAssets,
  setIsShowOnlySelected,
  setIsAssignDataCategoriesModalOpen,
  setSearchQuery,
  setSearchString,
  pushEntryOntoBackToUrlHistory,
  popEntryFromBackToUrlHistory,
  setBackToUrlHistory,
} = assetDetailView.actions

export default assetDetailView.reducer
