import { IAdobeConnectActivityParams, ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { isAdobeConnectActivityParamsTypeGuard } from 'pages/orchestration/workflows/edit/interfaces/typeGuards'

interface IGetAdobeInitialValuesParams {
  step: ICanvasStep
  // todo: wait for real data from BE to add type
  identitySpaces: any[]
}

// todo: wait for real data from BE to add type
export const getIdentitySpacesInitialValues = (identitySpaces: any[]) =>
  identitySpaces.reduce((acc, item) => {
    acc[item.id] = {
      permits: [''],
      condition: 'any', // todo: use const. waiting for BE
    }

    return acc
  }, {})

export const getAdobeInitialValues = (params: IGetAdobeInitialValuesParams): IAdobeConnectActivityParams => {
  const { step, identitySpaces } = params

  const identitySpacesInitialValues = getIdentitySpacesInitialValues(identitySpaces)

  if (step.workflow?.params && isAdobeConnectActivityParamsTypeGuard(step.workflow?.params)) {
    const description = step.description || ''
    const code = step.code || ''
    const connection = step.workflow?.params?.connection || ''
    const type = step.workflow?.params?.type || 'permit'
    // todo: add this conditionally only when have permits checkbox. and only when it is checked
    const identitySpaces = step.workflow?.params?.identitySpaces || identitySpacesInitialValues
    // todo: same with rights
    const rights = step.workflow?.params?.rights || []
    const rightsMap = rights.reduce((acc, value) => ({ ...acc, [value]: true }), {})

    return {
      description,
      code,
      connection,
      type,
      rights,
      rightsMap,
      identitySpaces,
    }
  }

  return {
    description: '',
    code: '',
    connection: '',
    type: '',
    rights: [],
    rightsMap: {},
    identitySpaces: identitySpacesInitialValues,
  }
}
