import { Checkbox, Chip, InfoRow, InfoRowProps, theme } from '@ketch-com/deck'
import { useEffect, useState } from 'react'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { ChipContentRadioEditOptions } from './ChipContentRadioEditInfoRow'
import { Box, Typography } from '@mui/material'

type EditChipContentCheckboxProps = {
  values?: string[]
  setValue: (selectedChips?: string[]) => void
  options: ChipContentRadioEditOptions
  allowDelete?: boolean
  isCustomField?: boolean
}

export interface ChipContentCheckboxInfoRowProps extends InfoRowProps, EditChipContentCheckboxProps {}

export default function ChipContentCheckboxEditInfoRow({
  allowDelete = true,
  options,
  setValue,
  values,
  isCustomField = false,
  ...rest
}: ChipContentCheckboxInfoRowProps) {
  const [localValue, setLocalValue] = useState<Record<string, boolean>>({})
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setLocalValue(() => {
      const optionObject = Object.entries(options).reduce(
        (acc, option) => {
          acc[option[0]] = values?.includes(option[1].value) || false
          return acc
        },
        {} as Record<string, boolean>,
      )
      return optionObject
    })
  }, [options, values])

  const isEmpty = Object.values(localValue).some(val => val === true)

  return (
    <InfoRow
      {...rest}
      isEditable
      isEditing={isEditing}
      onEditChange={() => {
        setIsEditing(true)
      }}
      onAcceptChange={() => {
        setIsEditing(false)
        setValue(
          Object.entries(localValue)
            .filter(value => value[1] === true)
            .map(val => val[0]),
        )
      }}
      onCancelChange={() => {
        setIsEditing(false)
      }}
      onDeleteChange={
        allowDelete
          ? () => {
              setIsEditing(false)
              setValue()
            }
          : undefined
      }
      isEmpty={isEmpty}
      titleAddendum={isCustomField ? 'Custom' : undefined}
    >
      {isEditing ? (
        <Box display="flex" flexDirection="column" gap={1}>
          {Object.values(options).map(option => (
            <Box display="flex" gap={1} alignItems="center">
              <Checkbox
                disabled={option.disabled}
                checked={localValue[option.value]}
                title={option.title as string}
                onChange={() => {
                  setLocalValue(prev => ({
                    ...prev,
                    [option.value]: !prev[option.value],
                  }))
                }}
              />
              <Typography color={theme.palette.Text.Primary} variant={localValue[option.value] ? 'label' : undefined}>
                {option.title}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : localValue ? (
        <Box display="flex" gap={0.5}>
          {Object.entries(localValue)
            .filter(value => value[1] === true)
            .map(val => (
              <Chip
                label={options[val[0]].title}
                onClick={e => {
                  e.stopPropagation()
                }}
              />
            ))}
        </Box>
      ) : (
        <EmptyValueRenderer />
      )}
    </InfoRow>
  )
}
