import { Button, PopUp } from '@ketch-com/deck'
import { memo, useEffect, useState } from 'react'
import { ConfigItemDisplay } from '../../components'
import { useCreateApplianceToken } from 'api/dataSystems/mutations/useCreateApplianceToken'
import { showToast } from 'components/modals/AlertComponent'
import { useVisitTransponderDocumentation } from '../../hooks/useTransponderDocumentation'

export interface TransponderKeyModalProps {
  handleClose: () => void
  applianceId: string
}

export const TransponderKeyModal: React.FC<TransponderKeyModalProps> = memo(({ handleClose, applianceId }) => {
  const handleVisitTransponderDocumentation = useVisitTransponderDocumentation()

  const [transponderToken, setTransponderToken] = useState('')

  const { mutateAsync: handleCreateApplianceToken, isError } = useCreateApplianceToken({
    onSuccess: ({ data }) => {
      setTransponderToken(data.token)
      showToast({ content: 'New transponder token generated!', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to generate transponder token', type: 'error' })
    },
  })

  useEffect(() => {
    if (applianceId) {
      handleCreateApplianceToken({ params: { applianceId } })
    }
  }, [applianceId, handleCreateApplianceToken])

  return (
    <PopUp
      variant="dialog"
      title="Transponder Key"
      subTitle="Within your transponder, click Add Organization and paste this key as the Transponder Key."
      onClose={handleClose}
      popUpActionChildren={
        <>
          <Button
            color="secondary"
            size="large"
            onClick={() => {
              handleVisitTransponderDocumentation()
            }}
          >
            Read Documentation
          </Button>
          <Button color="primary" size="large" onClick={handleClose}>
            Close
          </Button>
        </>
      }
    >
      <ConfigItemDisplay
        alwaysVisible
        onRegenerate={() => {
          handleCreateApplianceToken({ params: { applianceId } })
        }}
        type="token"
        value={transponderToken}
        error={isError}
        name="Transponder Key"
      />
      <ConfigItemDisplay alwaysVisible type="token" value={applianceId} name="Transponder Appliance Id" />
    </PopUp>
  )
})
