import { Box, Typography } from '@mui/material'
import { WebhooksContextTimelineDTO } from '@ketch-com/figurehead'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import {
  WebhooksContextDetailsStatus,
  WebhooksContextDetailsType,
  WebhooksContextTimelineItemStatus,
} from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'
import { getContextDetailsString } from '../../../../../../utils/getContextDetailsString'
import { TimelineModalProps } from '../../../../ApiCallStepDetailsTimeline'
import { ContextVariableChip } from 'pages/orchestration/rightsQueue/stepDetails/components'
import {
  UnknownContextVariable,
  InvalidContextVariable,
  InvalidFormData,
  InvalidOutcomeVariable,
  UnknownFormData,
  UnknownOutcomeVariable,
  UnknownIdentity,
  FormDataTimelineContent,
} from './components'
import { Chip, Icon } from '@ketch-com/deck'

type Props = {
  timelineItem: WebhooksContextTimelineDTO
  setModalDetails: (props: TimelineModalProps) => void
  identitySpaces: IdentitySpaceDTO[]
}

// see also: getContextDetailsString.ts when adding or removing entries here
export const TimelineItemContent: React.FC<Props> = ({ timelineItem, setModalDetails, identitySpaces }) => {
  const { status, identities, contextVariables, webhookDetails, dataCollected, outcomeVariables, formData } =
    timelineItem
  const navigate = useNavigate()

  /* UNKNOWN */

  if (status === WebhooksContextTimelineItemStatus.UNKNOWN) {
    if (identities) {
      return <UnknownIdentity timelineItem={timelineItem} identitySpaces={identitySpaces} />
    } else if (contextVariables) {
      return <UnknownContextVariable timelineItem={timelineItem} />
    } else if (outcomeVariables) {
      return <UnknownOutcomeVariable timelineItem={timelineItem} />
    } else if (formData) {
      return <UnknownFormData timelineItem={timelineItem} />
    }
  }

  /* INVALID */

  if (status === WebhooksContextTimelineItemStatus.INVALID) {
    if (contextVariables) return <InvalidContextVariable timelineItem={timelineItem} />
    if (outcomeVariables) return <InvalidOutcomeVariable timelineItem={timelineItem} />
    if (formData) return <InvalidFormData timelineItem={timelineItem} />
  }

  /* ERROR */

  if (status === WebhooksContextTimelineItemStatus.ERROR) {
    return (
      <Chip
        clickable
        label="View Error"
        onClick={() =>
          setModalDetails({
            title: getContextDetailsString(timelineItem),
            subtitle: 'Error Details',
            headers: timelineItem.webhookDetails?.data?.responseHeaders,
            body: timelineItem.webhookDetails?.data?.responseBody, // TODO:JA move error message to own field instead of using timelineItem.webhookDetails?.data?.responseBody
          })
        }
        onDelete={() =>
          setModalDetails({
            title: getContextDetailsString(timelineItem),
            subtitle: 'Error Details',
            headers: timelineItem.webhookDetails?.data?.responseHeaders,
            body: timelineItem.webhookDetails?.data?.responseBody, // TODO:JA move error message to own field instead of using timelineItem.webhookDetails?.data?.responseBody
          })
        }
        deleteIcon={<Icon name="OArrowCRight" />}
      />
    )
  }

  /* SUCCESS */

  if (status === WebhooksContextTimelineItemStatus.SUCCESS) {
    // "Callback" is the customer server responding to Ketch via Rest Gateway
    // with an AccessStatusEvent or DeleteStatusEvent
    if (webhookDetails?.status === WebhooksContextDetailsStatus.CALLBACK) {
      if (webhookDetails?.type === WebhooksContextDetailsType.REQUEST) {
        return (
          <Chip
            clickable
            label="View Details"
            onClick={() =>
              setModalDetails({
                title: getContextDetailsString(timelineItem),
                subtitle: 'Request Body',
                headers: timelineItem.webhookDetails?.data?.requestHeaders,
                body: timelineItem.webhookDetails?.data?.requestBody,
              })
            }
            onDelete={() =>
              setModalDetails({
                title: getContextDetailsString(timelineItem),
                subtitle: 'Request Body',
                headers: timelineItem.webhookDetails?.data?.requestHeaders,
                body: timelineItem.webhookDetails?.data?.requestBody,
              })
            }
            deleteIcon={<Icon name="OArrowCRight" />}
          />
        )
      }

      if (webhookDetails?.type === WebhooksContextDetailsType.RESPONSE) {
        return (
          <Chip
            clickable
            label="View Details"
            onClick={() =>
              setModalDetails({
                title: getContextDetailsString(timelineItem),
                subtitle: 'Response Status Code & Body',
                headers: timelineItem.webhookDetails?.data?.responseHeaders,
                body: timelineItem.webhookDetails?.data?.responseBody,
              })
            }
            onDelete={() =>
              setModalDetails({
                title: getContextDetailsString(timelineItem),
                subtitle: 'Response Status Code & Body',
                headers: timelineItem.webhookDetails?.data?.responseHeaders,
                body: timelineItem.webhookDetails?.data?.responseBody,
              })
            }
            deleteIcon={<Icon name="OArrowCRight" />}
          />
        )
      }
    }

    if (webhookDetails?.status === WebhooksContextDetailsStatus.WEBHOOK) {
      if (webhookDetails?.type === WebhooksContextDetailsType.REQUEST) {
        return (
          <Chip
            clickable
            label="View Details"
            onClick={() =>
              setModalDetails({
                title: getContextDetailsString(timelineItem),
                subtitle: 'Request Body',
                headers: timelineItem.webhookDetails?.data?.requestHeaders,
                body: timelineItem.webhookDetails?.data?.requestBody,
              })
            }
            onDelete={() =>
              setModalDetails({
                title: getContextDetailsString(timelineItem),
                subtitle: 'Request Body',
                headers: timelineItem.webhookDetails?.data?.requestHeaders,
                body: timelineItem.webhookDetails?.data?.requestBody,
              })
            }
            deleteIcon={<Icon name="OArrowCRight" />}
          />
        )
      }

      if (webhookDetails?.type === WebhooksContextDetailsType.RESPONSE) {
        return (
          <Chip
            clickable
            label="View Details"
            onClick={() =>
              setModalDetails({
                title: getContextDetailsString(timelineItem),
                subtitle: 'Response Status Code & Body',
                headers: timelineItem.webhookDetails?.data?.responseHeaders,
                body: timelineItem.webhookDetails?.data?.responseBody,
              })
            }
            onDelete={() =>
              setModalDetails({
                title: getContextDetailsString(timelineItem),
                subtitle: 'Response Status Code & Body',
                headers: timelineItem.webhookDetails?.data?.responseHeaders,
                body: timelineItem.webhookDetails?.data?.responseBody,
              })
            }
            deleteIcon={<Icon name="OArrowCRight" />}
          />
        )
      }
    }

    if (identities) {
      return (
        <Box display="flex" gap={1.5} flexDirection="column" alignItems="flex-start">
          {Object.entries(identities?.data || {}).map(([k, v], index) => {
            const identitySpace = identitySpaces.find(space => space.code === k)

            return (
              <Box key={index} display="flex" alignItems="center" gap={0.75}>
                <Chip
                  clickable
                  key={index}
                  label={identitySpace?.name || k}
                  onClick={() => navigate(RoutesManager.settings.identitySpaces.view.overview.root.getURL({ code: k }))}
                  onDelete={() =>
                    navigate(RoutesManager.settings.identitySpaces.view.overview.root.getURL({ code: k }))
                  }
                  deleteIcon={<Icon name="OArrowCRight" />}
                />
                <Typography variant="body">
                  Value: <Typography sx={{ overflowWrap: 'anywhere' }} variant="label">{`${v}`}</Typography>
                </Typography>
              </Box>
            )
          })}
        </Box>
      )
    }

    if (contextVariables) {
      return (
        <Box display="flex" gap={1.5} flexDirection="column" alignItems="flex-start">
          {Object.entries(contextVariables?.data || {}).map(([code, value], index) => {
            return (
              <Box key={index} display="flex" alignItems="center" gap={0.75}>
                <ContextVariableChip code={code} />
                <Typography variant="body">
                  Value: <Typography sx={{ overflowWrap: 'anywhere' }} variant="label">{`${value}`}</Typography>
                </Typography>
              </Box>
            )
          })}
        </Box>
      )
    }

    if (outcomeVariables) {
      return (
        <Box display="flex" gap={1.5} flexDirection="column" alignItems="flex-start">
          {Object.entries(outcomeVariables?.data || {}).map(([code, value], index) => {
            return (
              <Box key={index} display="flex" alignItems="center" gap={0.75}>
                <ContextVariableChip code={code} />
                <Typography variant="body">
                  Value: <Typography sx={{ overflowWrap: 'anywhere' }} variant="label">{`${value}`}</Typography>
                </Typography>
              </Box>
            )
          })}
        </Box>
      )
    }

    if (dataCollected) {
      return (
        <Chip
          clickable
          label="View Data"
          onClick={() =>
            setModalDetails({
              title: getContextDetailsString(timelineItem),
              headers: dataCollected.data,
            })
          }
          onDelete={() =>
            setModalDetails({
              title: getContextDetailsString(timelineItem),
              headers: dataCollected.data,
            })
          }
          deleteIcon={<Icon name="OArrowCRight" />}
        />
      )
    }

    if (formData) return <FormDataTimelineContent formData={formData} />
  }

  return null
}
