import { Button, TableCell } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'
import { CodeParams } from 'utils/routing/routes/utils'

interface RowData {
  code: string
}

interface CookieViewTabActionCellProps<T extends RowData> {
  row: T
  route?: {
    pattern: string
    getURL: ({ code }: CodeParams) => string
  }
  disabled?: boolean
  onClick?: () => void
}

export const CookieViewTabActionCell = <T extends RowData>({
  row,
  route,
  disabled,
  onClick,
}: CookieViewTabActionCellProps<T>) => {
  const navigate = useNavigate()
  const onClickHandler = (row: T) => {
    if (onClick) {
      onClick()
    } else {
      if (route) {
        navigate(route.getURL({ code: row.code }))
      }
    }
  }
  return (
    <TableCell>
      <Button disabled={disabled} color="tertiary" onClick={() => onClickHandler(row)}>
        View
      </Button>
    </TableCell>
  )
}
