import { ApiQueryKeys } from 'api/common/queryKeys'
import { useCompleteWorkflowExecutionStep } from 'api/workflowExecutions/mutations/useCompleteWorkflowExecutionStep'
import { useUpdateWorkflowExecution } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecution'
import { showToast } from 'components/modals/AlertComponent'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
import { Dispatch, SetStateAction, useContext } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

type Args = {
  setIsResolving: Dispatch<SetStateAction<boolean>>
  stepDetails: any
}

export const useResolve = ({ setIsResolving, stepDetails }: Args) => {
  const { id: rightInvocationId } = useParams<{ id?: string }>()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { stepId, workflowExecutionId, setHasResolveSucceeded } = useContext(
    RightInvocationWorkflowStepDetailViewContext,
  )

  const handleResolveOnSuccess = () => {
    setTimeout(async () => {
      setIsResolving(false)
      await queryClient.refetchQueries([
        [ApiQueryKeys.workflowExecution, { workflowExecutionId }],
        ApiQueryKeys.workflowExecutionStepDetailsV2,
        ApiQueryKeys.workflowExecution,
      ])
      showToast({ content: `${stepDetails?.stepName} was successfully resolved`, type: 'success' })
      setHasResolveSucceeded(true)
      navigate(
        RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({
          id: rightInvocationId || '',
        }),
      )
    }, 3000)
  }

  const { mutateAsync: handleUpdateWorkflowExecution } = useUpdateWorkflowExecution({
    onSuccess: handleResolveOnSuccess,
    onError: () => {
      setIsResolving(false)
      showToast({ content: 'Failed to resolve', type: 'error' })
    },
  })

  const { mutateAsync: handleCompleteWorkflowExecutionStep } = useCompleteWorkflowExecutionStep({
    onSuccess: handleResolveOnSuccess,
    onError: () => {
      setIsResolving(false)
      showToast({ content: 'Failed to approve activity', type: 'error' })
    },
  })

  const resolve = async (availablePaths?: string | undefined) => {
    const shouldUseRetryAppResolve = !!stepDetails?.executionData?.app?.supportsAppRetry
    // if an app supports retry, we need to use the signal to resolve the step
    // per https://ketch-com.atlassian.net/browse/KD-6364?focusedCommentId=17054
    shouldUseRetryAppResolve
      ? await handleUpdateWorkflowExecution({
          params: {
            formData: {
              temporalWorkflowID: `${workflowExecutionId}${stepDetails?.stepID}`,
              signalName: 'level_2_app_impel',
              signalValue: {
                command: 'resolve',
              },
            },
          },
        })
      : await handleCompleteWorkflowExecutionStep({
          params: {
            workflowExecutionId: workflowExecutionId!,
            stepId: stepId!,
            availablePaths,
          },
        })

    await queryClient.invalidateQueries('workflowExecution')
  }

  return {
    resolve,
  }
}
