import React from 'react'
import { Box, Typography } from '@mui/material'
import { Card, ContentGroup, DataGrid, EmptyState, Spinner } from '@ketch-com/deck'
import { useAuditLogConsent } from '../hooks'
import { auditLogsPermitsColumns } from './auditLogsDataGridUtils'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { getLastCollectedDate } from './utils'

type Props = {}

export const AuditLogsPermits: React.FC<Props> = () => {
  const consent = useAuditLogConsent()

  return (
    <Card
      sx={{
        maxWidth: '50%',
        minWidth: '50%',
        marginRight: theme => theme.spacing(2),
        padding: '32px',
      }}
      customContentCard={
        <Box
          sx={{
            height: 440,
            width: '100%',
          }}
        >
          {consent.isLoading ? (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner size="32px" thickness={2.5} />
            </Box>
          ) : (
            <>
              <ContentGroup
                title="Permits"
                subTitle="What’s the current state of permits for this identifier?"
                variant="inner-page-header"
                titleVariant="h3"
                actionRightBlockComponent={
                  <Box display="flex" flexDirection="column" alignItems="end">
                    <Typography color="darkGrey.main" variant="smallLabel">
                      Last Updated
                    </Typography>
                    <Typography variant="smallBody">
                      {formatDateTimeFromUnix(getLastCollectedDate(consent?.data))}
                    </Typography>
                  </Box>
                }
              />

              {!consent.data.length ? (
                <Box height="100%">
                  <EmptyState
                    title="No permits yet"
                    subTitle="Permits will be displayed here when they have been created"
                    iconName="OLock"
                  />
                </Box>
              ) : (
                <DataGrid
                  sx={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    height: 'auto',
                  }}
                  autosizeOnMount
                  columns={auditLogsPermitsColumns}
                  disableChildrenSorting
                  disableColumnMenu
                  disableColumnPinning
                  disableColumnResize
                  disableColumnReorder
                  getRowId={row => row.code}
                  loading={!!consent.isLoading}
                  rows={consent.data}
                  pagination
                  paginationMode="client"
                  pageSizeOptions={[5]}
                />
              )}
            </>
          )}
        </Box>
      }
    />
  )
}
