import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

import { ReshapedPerJurisdictionDataForPieChart } from '../../../utils/getTransformedPerJurisdictionData'
import { formatCount } from '../../../../PermitsSummary/components/PermitStatisticsOverall/utils'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'

const PREFIX = 'DistributionPerScopeTopThree'

const classes = {
  baseDot: `${PREFIX}-baseDot`,
  chartDescriptionRow: `${PREFIX}-chartDescriptionRow`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.baseDot}`]: {
    borderRadius: '50%',
    width: 10,
    height: 10,
  },

  [`& .${classes.chartDescriptionRow}`]: {
    display: 'block',
    marginBottom: 2,
  },
}))

type Props = { data: ReshapedPerJurisdictionDataForPieChart[] }

export const DistributionPerScopeTopThree: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])

  return (
    <StyledBox display="flex" flexDirection="column" flex={3.5}>
      <Typography
        variant="body"
        color="darkDuskFaded.main"
        sx={{
          mt: 3.25,
          display: 'block',
          marginBottom: 0.5,
        }}
      >
        {t('Top 3')}
      </Typography>
      <Box mt={1.5} display="flex" flexDirection="column" gap={4} flex={1}>
        {data.map((jurisdiction, index) => {
          if (index < 3) {
            return (
              <Box display="flex" key={`${jurisdiction?.code}_${index}`}>
                {/* Col 1 */}
                <Box pt={0.5}>
                  <Box className={classes.baseDot} sx={{ backgroundColor: jurisdiction?.color || 'black' }} />
                </Box>

                {/* Col 2 */}

                <Box pl={1} flex={1} display="flex" flexDirection="column">
                  <Typography variant="label" sx={{ mb: 0.5 }}>
                    {jurisdiction?.name || ''}
                  </Typography>
                  <Typography variant="smallBody">{formatCount(jurisdiction?.totalRequests || 0)}</Typography>
                  <Typography
                    variant="smallBody"
                    color={`${jurisdiction.consentRequiredColor}.main`}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {jurisdiction.consentRequiredText}
                  </Typography>
                </Box>
              </Box>
            )
          }

          return null
        })}
      </Box>
    </StyledBox>
  )
}
