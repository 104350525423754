import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { updateProcessingActivityModelBias } from '../fetchers/updateProcessingActivityModelBiasFile'

const useCustomMutation = createUseMutation({
  mutationFn: updateProcessingActivityModelBias,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateProcessingActivityModelBiasFile = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
