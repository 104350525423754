import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useExperience } from 'api/experiences/queries/useExperience'
import { useExperienceVersion } from 'api/experiences/queries/useExperienceVersion'
import { useExperienceVersions } from 'api/experiences/queries/useExperienceVersions'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { ExperienceView } from 'pages/consentAndRights/experiences/view/ExperienceView'
import { useResetExperienceBuilderReduxFormStateOnMount } from 'store/experienceBuilderFormStateFilterSlice/hooks'

export const ExperienceViewContainer: React.FC = () => {
  useResetExperienceBuilderReduxFormStateOnMount()
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()
  const { queries } = useQueryParams<{ version?: string }>()
  const { version } = queries

  const handleRedirectToExperiencesList = () => {
    navigate(RoutesManager.deployment.experiences.root.getURL())
  }

  const { data: experience, isLoading: isExperienceLoading } = useExperience({
    enabled: !version,
    params: {
      experienceCode: code!,
      includeMetadata: true,
      includeIssues: true,
    },
    onError: handleRedirectToExperiencesList,
  })

  const { data: experienceVersion, isLoading: isExperienceVersionLoading } = useExperienceVersion({
    enabled: !!version,
    params: {
      experienceCode: code!,
      version: version!,
    },
    onError: handleRedirectToExperiencesList,
  })

  const { data: versions, isLoading: isVersionsLoading } = useExperienceVersions({
    params: {
      experienceCode: code!,
      includeMetadata: true,
      ordering: {
        version: 'desc',
      },
    },
  })

  const { data: organizationLanguages, isLoading: isOrganizationLanguagesLoading } = useOrganizationLanguages()

  const isReady =
    !isVersionsLoading &&
    !isOrganizationLanguagesLoading &&
    (version ? !isExperienceVersionLoading : !isExperienceLoading)

  return (
    <ExperienceView
      isReady={isReady}
      experience={version ? experienceVersion : experience}
      versions={versions}
      organizationLanguages={organizationLanguages}
    />
  )
}
