import { GetTrackerStatisticsResponseBody } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchTrackerStatistics = () => {
  return API.get<GetTrackerStatisticsResponseBody>(
    formatRequestString({
      entityUrl: '/api/privacy-configuration/tracker/statistics',
      params: {
        filters: {},
      },
    }),
  )
}
