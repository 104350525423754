import { Box, Typography } from '@mui/material'
import { Avatar, AvatarSize, Button, Checkbox, Icon, Spinner, theme, Chip } from '@ketch-com/deck'
import { Button as MUIButton } from '@mui/material'
import { Drawer } from '../../../components/ui-kit/drawer/Drawer'
import React, { useContext, useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import { initialValues } from './utils/formValues'
import { Formik } from 'formik'
import { SystemsOverviewContext } from '../Home/components/Overview/Context/SystemsOverviewContext'

type Props = {
  /** Open/closed state of the drawer */
  open: boolean
  /** Function to call when the drawer is closed */
  onClose: () => void
}

/**
 * Drawer for viewing and adding discovered systems
 *
 * NOTE: Contains demo functionality that will be removed once backend is
 * complete
 *
 */
export const DiscoveredSystemsDrawer: React.FC<Props> = ({ open, onClose }) => {
  const { webProperties, uninstalledDiscoveredSystems, setDiscoveredSystems, onSubmit, isDemo, deleteDemoSystems } =
    useContext(SystemsOverviewContext)

  const [isDemoLoading, setIsDemoLoading] = useState(false)
  const [checked, setChecked] = useState(Array(uninstalledDiscoveredSystems.length).fill(false))
  const checkedSystems = uninstalledDiscoveredSystems.filter((system, index) => checked[index])

  // Handle when the all checkbox is clicked
  const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(Array(checked.length).fill(event.target.checked))
  }

  // Handle when one checkbox is clicked
  const handleOneChange = (index: number) => {
    const tempChecked = [...checked]
    tempChecked[index] = !checked[index]
    setChecked(tempChecked)
  }

  // Fake refresh for demo only
  const handleScanClick = () => {
    setIsDemoLoading(true)
    setTimeout(() => {
      setIsDemoLoading(false)
    }, 2000)
  }

  // Reset function for demo only
  const handleDemoDeleteClick = () => {
    deleteDemoSystems()
    setChecked(Array(4).fill(false))
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          height: '90%',
          width: '100%',
          maxWidth: '1300px',
          margin: 'auto', // Centered
          padding: '32px',
          borderRadius: '37px 37px 0px 0px',
        },
      }}
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ submitForm, setFieldValue }) => {
          const handleAddClick = () => {
            setFieldValue('selectedDataSystems', checkedSystems)
            setDiscoveredSystems(uninstalledDiscoveredSystems.filter((system, index) => !checked[index]))
            setChecked(Array(uninstalledDiscoveredSystems.length - checkedSystems.length).fill(false))
            submitForm()
          }

          return (
            <>
              {/* Header text */}
              <Box mb="24px">
                <Typography display="inline" variant="h2">
                  New Systems Found{' '}
                </Typography>
                <Typography display="inline" variant="h2" color={theme.palette.darkDuskFaded.main}>
                  {uninstalledDiscoveredSystems.length}
                </Typography>
              </Box>
              <Box mb="12px">
                <Typography display="inline" variant="label">
                  Last scanned yesterday.{' '}
                </Typography>
                <Button variant="link" color="secondary" onClick={handleScanClick}>
                  <Typography variant="label">Scan Again</Typography>
                </Button>
              </Box>
              <Typography variant="body">
                Please review these systems and ensure that they are authorized and pose no security risk to your
                organization.
              </Typography>

              {/* Select all box and add button */}
              <Box display="flex" pt="32px" pb="20px">
                <FormControlLabel
                  label={<Typography variant="label">Selected {checkedSystems.length}</Typography>}
                  control={
                    <Checkbox
                      checked={checked.every(isChecked => isChecked)}
                      indeterminate={checked.some(isChecked => !isChecked) && checked.some(isChecked => isChecked)}
                      onChange={handleAllChange}
                      size="medium"
                    />
                  }
                  disabled={!checked.length} // Disabled when no systems
                />
                {/* Disabled when no systems are checked */}
                <Button
                  onClick={handleAddClick}
                  sx={{ marginLeft: 'auto' }}
                  size="large"
                  disabled={!checkedSystems.length}
                >
                  <Box>
                    <Typography variant="label" color={theme.palette.white.main}>
                      Add Systems{' '}
                    </Typography>
                    <Typography variant="label" color={theme.palette.iron.main}>
                      {checkedSystems.length}
                    </Typography>
                  </Box>
                </Button>
              </Box>

              {isDemoLoading ? (
                <Box display="flex" gap={1} flexDirection="column" alignItems="center" justifyContent="center">
                  <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
                    <Spinner size="32px" thickness={2.5} />
                  </Box>
                  <Typography variant="label">Discovering new systems...</Typography>
                </Box>
              ) : (
                <>
                  {/* Systems list */}
                  {uninstalledDiscoveredSystems.map(({ code, name, logoUrl, systemType }, index) => (
                    <FormControlLabel
                      key={code}
                      sx={{ mr: 0 }}
                      label=""
                      control={
                        // List item
                        <Box
                          display="flex"
                          width="100%"
                          py="12px"
                          alignItems="center"
                          justifyContent="flex-start"
                          borderBottom={`1px solid ${theme.palette.iron.main}`}
                        >
                          {/* System info section */}
                          <Box display="flex" gap={2} width="59%" alignItems="center" justifyContent="flex-start">
                            <Checkbox checked={checked[index]} onChange={() => handleOneChange(index)} disableRipple />
                            <Avatar
                              isLogo
                              src={logoUrl}
                              alt={name}
                              variant="rounded"
                              backgroundColor={theme.palette.tertiary.main}
                              size={AvatarSize.xlarge}
                            />
                            <Typography variant="label">{name}</Typography>
                          </Box>

                          {/* Property section */}
                          {systemType === 'internal' && webProperties.length && (
                            <Box display="flex" gap={0.5} justifyContent="flex-start" alignItems="center">
                              <Typography variant="body" color={theme.palette.darkDuskFaded.main}>
                                Found on:
                              </Typography>
                              <Chip
                                label={webProperties[0].name}
                                clickable
                                icon={<Icon name="OGlobe" iconColor={theme.palette.sphere.main} />}
                              />
                            </Box>
                          )}

                          {/* More info section */}
                          <Box display="flex" gap={4} marginLeft="auto" alignItems="center">
                            {systemType !== 'internal' && (
                              <Box display="flex" gap={1} alignItems="center" marginLeft="auto">
                                <Icon name="OImportant" iconColor={theme.palette.chileanFire.main} />
                                <Typography variant="label" color={theme.palette.chileanFire.main}>
                                  Unknown System
                                </Typography>
                              </Box>
                            )}
                            <Icon name="OMore" />
                          </Box>
                        </Box>
                      }
                    />
                  ))}
                </>
              )}
            </>
          )
        }}
      </Formik>

      {isDemo && (
        <Box position="absolute" bottom={0} left={0}>
          <MUIButton
            onClick={handleDemoDeleteClick}
            sx={{ height: '40px', width: '5px', margin: '0px 0px 0px 5px', padding: 0 }}
          ></MUIButton>
        </Box>
      )}
    </Drawer>
  )
}
