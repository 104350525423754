const secondsPerHour = 3600
const secondsPerDay = 86400

export type StatusTimeRemaining = {
  unit: 'Hours' | 'Days'
  duration: number
}

export const getStatusTimeRemaining = (dueAt: number): StatusTimeRemaining => {
  const currentTimestampInSeconds = Date.parse(new Date().toISOString()) / 1000

  // Round to integer because JS math is insane and produces results like 4.000364398147212
  const currentTimestampInDays = Math.round(currentTimestampInSeconds / secondsPerDay)
  const dueAtInDays = Math.round(dueAt / secondsPerDay)

  // Round to integer because JS math is insane and produces results like 4.000364398147212
  const currentTimestampInHours = Math.round(currentTimestampInSeconds / secondsPerHour)
  const dueAtInHours = Math.round(dueAt / secondsPerHour)

  const differenceInHours = Math.round(dueAtInHours - currentTimestampInHours)

  if (differenceInHours <= 48 && differenceInHours >= -24) {
    return { unit: 'Hours', duration: differenceInHours }
  }

  return { unit: 'Days', duration: Math.round(dueAtInDays - currentTimestampInDays) }
}
