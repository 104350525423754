import { ContextVariableDTO } from '@ketch-com/figurehead'
import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import pluralize from 'pluralize'

// TODO:BAC  verify the logic in this function.
export const testForInvalidTaskRemoval = ({
  step,
  steps,
  contextVariables,
  setWarningModalMessage,
}: {
  step: ICanvasStep
  steps: ICanvasStep[]
  contextVariables: ContextVariableDTO[]
  setWarningModalMessage: (message: string) => void
}) => {
  /* Determine if step being removed is of type TASK */
  const isStepTask = step.activityCode === WorkflowActivityCode.TASK

  if (!isStepTask) return true

  const affectedDecisionGatewaySteps: ICanvasStep[] = []
  const affectedVariables: ContextVariableDTO[] = []

  const decisionGatewaySteps = steps.filter(step => step.activityCode === WorkflowActivityCode.DECISION_MULTI)

  for (const decisionGatewayStep of decisionGatewaySteps) {
    const decisionGatewayStepTransitions = decisionGatewayStep?.gateway?.transitions

    if (decisionGatewayStepTransitions) {
      for (const transition of decisionGatewayStepTransitions) {
        if (transition?.outcomeVariableStepID === step?.ID) {
          affectedDecisionGatewaySteps.push(decisionGatewayStep)
          const contextVariable = contextVariables?.find?.(variable => variable.code === transition?.variable)
          contextVariable && affectedVariables.push(contextVariable)
        }
      }
    }
  }

  /* Check to see if step is a TASK that's being referenced by a downstream decision gateway */
  if (affectedDecisionGatewaySteps.length > 0 && isStepTask) {
    const affectedVars = affectedVariables?.map?.(variable => variable?.name)?.join?.(', ')
    const affectedDecisionGatewayStepName = affectedDecisionGatewaySteps?.[0]?.description

    const warningMessage = `${pluralize(
      'Variable',
      affectedVariables.length,
    )} ${affectedVars} is defined in this Task and is required for decisioning in ${affectedDecisionGatewayStepName}. Before deleting this task, please remove dependencies from ${affectedDecisionGatewayStepName}.`

    // * if there are multiple variables or decision gateways please make sure the language updates accordingly
    setWarningModalMessage(warningMessage)
    return false
  }

  return true
}
