import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchDataMapDataSystem } from '../fetchers/fetchDataMapDataSystem'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.dataMapDataSystem,
  queryFn: fetchDataMapDataSystem,
  select: res => res?.data?.dataSystem || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useDataMapDataSystem = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
