import React, { createContext } from 'react'
import { UsePolicyScopeUpsertUtils, usePolicyScopeUpsertUtils } from '../hooks/usePolicyScopeUpsertUtils'

export const PolicyScopeUpsertContext = createContext({} as UsePolicyScopeUpsertUtils)

type Props = {
  children?: React.ReactNode
}

export const PolicyScopeUpsertContextProvider: React.FC<Props> = ({ children }) => {
  // Use the hook
  const hookValues = usePolicyScopeUpsertUtils()

  return (
    <PolicyScopeUpsertContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </PolicyScopeUpsertContext.Provider>
  )
}

export const withFormPolicyScopeUpsertContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <PolicyScopeUpsertContextProvider>
        <Component {...props} />
      </PolicyScopeUpsertContextProvider>
    )
  }
}
