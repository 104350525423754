import { useCallback } from 'react'
import { useUpdateDataSystemGlobalCustomAttribute } from 'api/dataSystems/mutations/useUpdateDataSystemGlobalCustomAttribute'
import { CustomFieldsState } from '../../types'

export const useFieldUpdates = () => {
  const { mutateAsync: updateDataSystemGlobalCustomAttribute } = useUpdateDataSystemGlobalCustomAttribute()

  const handleFieldUpdates = useCallback(
    async (customFields: CustomFieldsState[]) => {
      const attributesToUpdate = customFields.filter(field => field.isEdited)
      if (!attributesToUpdate.length) return

      const attributeUpdatePromises = attributesToUpdate.map(field => {
        const { isEdited, ...configuration } = field
        return updateDataSystemGlobalCustomAttribute({ params: { id: field.id!, reqBody: { configuration } } })
      })

      await Promise.all(attributeUpdatePromises)
    },
    [updateDataSystemGlobalCustomAttribute],
  )

  return handleFieldUpdates
}
