import { useMemo } from 'react'
import { Button, Icon, MegaFilter, MegaFilterOptionType } from '@ketch-com/deck'

import { useFiltersV3 } from 'utils/hooks'
import { StatusFilter, SubjectTypeFilter, JurisdictionFilter, RightFilter } from './components'
import { RightsQueueMegaFilterButtonPanelIds, statusFilters } from './constants'
import { useRights } from 'api/rights/queries/useRights'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { useDataSubjectTypes } from 'api/dataSubjectTypes/queries/useDataSubjectTypes'
import { RightsQueueListTableUtils } from '../RightsQueueListTable/hooks/useRightsQueueListTableUtils'
import { useAppDispatch } from 'store/hooks'
import { RIGHTS_QUEUE_PAGINATION_REDUX_KEY, setPage } from 'store/paginationPreservationSlice'

type Props = {
  rightsQueueListTableUtils: RightsQueueListTableUtils
  handlePaginationReset: () => void
}

export const RightsQueueMegaFilterButton = ({ rightsQueueListTableUtils, handlePaginationReset }: Props) => {
  const { filterCount, clearAllSearchParams, removeQueryParamFromList, getPanelParamValuesArrayByPanelId } =
    useFiltersV3()

  const {
    isFetching: isFetchingRightInvocations,
    paginationHelpers: { isNextPending, isPrevPending, totalResults },
  } = rightsQueueListTableUtils

  const { data: rights, isLoading: isRightsLoading } = useRights({
    enabled: true,
    params: {
      onlyOrgJurisdictionRights: true,
    },
  })

  const { data: jurisdictions, isLoading: isJurisdictionsLoading } = usePolicyScopes({
    enabled: true,
  })

  const {
    data: subjectTypes /* , TODO:BAC - isLoading, not sure how hydration is going to shake out, holding until BE is ready */,
  } = useDataSubjectTypes({
    enabled: true,
    params: {
      includeDeleted: false, // TODO:BAC - waiting on product to say if we should include deleted or not
    },
  })

  const megaFilterSections = useMemo(
    () => [
      { label: 'Status', value: RightsQueueMegaFilterButtonPanelIds.status },
      { label: 'Right', value: RightsQueueMegaFilterButtonPanelIds.rights },
      { label: 'Subject Type', value: RightsQueueMegaFilterButtonPanelIds.subjectTypes },
      { label: 'Jurisdiction', value: RightsQueueMegaFilterButtonPanelIds.jurisdictions },
    ],
    [],
  )

  const megaFilterSelectedValues = useMemo(() => {
    return megaFilterSections.reduce((accumulator, section) => {
      const panelParamValuesArray = getPanelParamValuesArrayByPanelId(section.value)
      let result: MegaFilterOptionType[] = []
      if (section.value === RightsQueueMegaFilterButtonPanelIds.status) {
        result = panelParamValuesArray.map(paramValue => {
          const res = statusFilters.find(({ value }) => value === paramValue)
          if (res) {
            return { ...res, category: section.value }
          }
          return {} as MegaFilterOptionType
        })
      } else if (section.value === RightsQueueMegaFilterButtonPanelIds.rights) {
        result = panelParamValuesArray.map(paramValue => {
          const res = rights.find(right => right.code === paramValue)
          if (res) {
            return { value: res.code, label: res.name, category: section.value }
          }
          return {} as MegaFilterOptionType
        })
      } else if (section.value === RightsQueueMegaFilterButtonPanelIds.jurisdictions) {
        result = panelParamValuesArray.map(paramValue => {
          const res = jurisdictions.find(jurisdiction => jurisdiction.code === paramValue)
          if (res) {
            return { value: res.code, label: res.name, category: section.value }
          }
          return {} as MegaFilterOptionType
        })
      } else if (section.value === RightsQueueMegaFilterButtonPanelIds.subjectTypes) {
        result = panelParamValuesArray.map(paramValue => {
          const res = subjectTypes.find(subjectType => subjectType.code === paramValue)
          if (res) {
            return { value: res.code || '', label: res.name, category: section.value, secondaryLabel: res.code }
          }
          return {} as MegaFilterOptionType
        })
      }
      return { ...accumulator, [section.value]: result }
    }, {})
  }, [rights, jurisdictions, subjectTypes, megaFilterSections, getPanelParamValuesArrayByPanelId])

  const filterButtonText = `Filter ${filterCount ? `(${filterCount})` : ''}`

  return (
    <MegaFilter
      sx={{
        '& .MuiButtonBase-root': {
          alignSelf: 'flex-start',
        },
        '& .MegaFilter-selectedOptions': {
          '& .MuiButtonBase-root': {
            alignSelf: 'center',
          },
        },
      }}
      buttonTitle={filterButtonText}
      sections={megaFilterSections}
      value={megaFilterSelectedValues}
      totalFilteredItemsNumber={totalResults}
      buttonProps={{
        size: 'medium',
        color: 'secondary',
        endIcon: <Icon name="OArrowCDown" />,
        pending: isFetchingRightInvocations && !isNextPending && !isPrevPending,
        sx: {
          '& .DeckBtnWrapChild': {
            whiteSpace: 'nowrap',
          },
        },
      }}
      onDeselectChipHandler={(option: MegaFilterOptionType) => {
        removeQueryParamFromList({ key: option.category, value: option.value })
        handlePaginationReset()
      }}
      renderActionItems={<RightsQueueActionButton clearAllSearchParams={clearAllSearchParams} />}
      selectedOptionsModalWidth={900}
    >
      <StatusFilter handlePaginationReset={handlePaginationReset} />
      <RightFilter rights={rights} isLoading={isRightsLoading} handlePaginationReset={handlePaginationReset} />
      <SubjectTypeFilter subjectTypes={subjectTypes} handlePaginationReset={handlePaginationReset} />
      <JurisdictionFilter
        jurisdictions={jurisdictions}
        isLoading={isJurisdictionsLoading}
        handlePaginationReset={handlePaginationReset}
      />
    </MegaFilter>
  )
}

const RightsQueueActionButton = ({ clearAllSearchParams }: { clearAllSearchParams: () => void }) => {
  const dispatch = useAppDispatch()

  return (
    <Button
      color="tertiary"
      size="medium"
      onClick={() => {
        clearAllSearchParams()
        dispatch(
          setPage({
            key: RIGHTS_QUEUE_PAGINATION_REDUX_KEY,
            value: 0,
          }),
        )
      }}
    >
      Clear all
    </Button>
  )
}
