import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        marginBottom: 48,

        '&:last-child': {
          marginBottom: 0,
        },
      },
    }),
  { name: 'FormGroup' },
)

type Props = {
  children?: React.ReactNode
}
export const FormGroup: React.FC<Props> = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.root}>{children}</div>
}
