import { GetTrackersResponseBody, GetTrackersParams } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchApprovedTrackers = ({
  nextToken,
  limit,
  propertyIDs,
  purposeIDs,
  categoryCodes,
  disclosureStatus,
  dataSystemCodes,
  searchString,
}: GetTrackersParams) => {
  return API.get<GetTrackersResponseBody>(
    formatRequestString({
      entityUrl: '/api/privacy-configuration/trackers',
      params: {
        filters: {
          ...(nextToken && {
            nextToken,
          }),
          ...(limit && {
            limit,
          }),
          ...(propertyIDs && {
            propertyIDs,
          }),
          ...(purposeIDs && {
            purposeIDs,
          }),
          ...(dataSystemCodes && {
            dataSystemCodes,
          }),
          ...(typeof disclosureStatus === 'boolean' && {
            disclosureStatus,
          }),
          ...(searchString && {
            searchString,
          }),
          ...(categoryCodes && {
            categoryCodes,
          }),
        },
      },
    }),
  )
}
