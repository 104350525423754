import { PutProcessingActivityDataSystemConsentStatusRequestBodyDTO } from '@ketch-com/figurehead'
import { API } from '../../../utils/api/api'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

type Params = {
  formData: PutProcessingActivityDataSystemConsentStatusRequestBodyDTO
  activityId: string
  dataSystemId: string
}

type Response = {}

export const updateProcessingActivityDataSystemConsentStatus = ({ formData, activityId, dataSystemId }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/processing-activities/${activityId}/data-systems/${dataSystemId}/consent-status`,
    }),
    formData,
  )
