import React from 'react'
import { Button } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Container, InnerContainer } from './components'
import Typography from '@mui/material/Typography'

type Props = {
  errorDescription?: string
  errorTitle?: string
  buttonText?: string
}
/**
 * This component is rendered if `error` search params are present in /callback route.
 */
export const CallbackError: React.FC<Props> = ({
  errorDescription = 'Unknown error',
  errorTitle = 'Sign-in Error',
  buttonText = 'Try Again',
}) => {
  const navigate = useNavigate()
  const { logout } = useAuth0()

  return (
    <Container>
      <InnerContainer>
        <Typography
          fontSize={20}
          color="darkDuskFaded.main"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {errorTitle}
        </Typography>

        <Typography fontSize={16} color="pinkCold.main" my={2}>
          {errorDescription}
        </Typography>

        {buttonText && (
          <Button
            onClick={() => {
              try {
                logout()
                navigate('/')
              } catch (error) {
                console.error(error)
                navigate('/')
              }
            }}
          >
            {buttonText}
          </Button>
        )}

        <Typography fontSize={12} color="darkDuskFaded.main" mt={2}>
          If the error persists, please contact support@ketch.com.
        </Typography>
      </InnerContainer>
    </Container>
  )
}
