import { createContext } from 'react'
import { ImportContextUtils } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ImportContext/interfaces'
import { useImportContextUtils } from '../hooks'

export const ImportContextData = createContext({} as ImportContextUtils)

type Props = {
  children?: React.ReactNode
}

export const ImportContextDataProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useImportContextUtils()

  return (
    <ImportContextData.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </ImportContextData.Provider>
  )
}

export const withImportContextDataContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <ImportContextDataProvider>
        <Component {...props} />
      </ImportContextDataProvider>
    )
  }
}
