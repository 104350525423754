import Box from '@mui/material/Box'
import { Chip, ContentGroup, Icon, InfoRow, LabelChip, LabelChipVariant } from '@ketch-com/deck'
import Typography from '@mui/material/Typography'
import { useContext } from 'react'
import { ProcessingActivityContext } from '../context/ProcessingActivityContext'
import { ProcessingActivityProcessingFrequencyDTO, ProcessingActivityStatusDTO } from '@ketch-com/figurehead'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { personalDataTypes, ProcessingActivityFrequencyLabel } from '../../constants'
import { styled } from '@mui/styles'
import { DataResidencyTile } from './components/DataResidencyTile'
import { RoutesManager } from 'utils/routing/routesManager'
import { isUndefined, toNumber } from 'lodash'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { TeamChip } from 'components/chip/TeamChip'

const InfoRowContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}))

export const OverviewTab = () => {
  const { navigate, processingActivity } = useContext(ProcessingActivityContext)

  const validResidencies =
    processingActivity.residencies?.filter(
      residency => residency.country || residency.security || residency.retentionPeriod,
    ) || []

  const handlePurposeClick = (code: string) => {
    navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code }))
  }

  return (
    <Box mb={6}>
      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup
          title="Overview"
          subTitle="Basic information about this processing activity."
          titleVariant="h3"
          variant="inner-page-header"
        />
        {/* Conditionally show approval details */}
        {processingActivity.status === ProcessingActivityStatusDTO.ApprovedProcessingActivityStatus && (
          <InfoRow title="Approved By">
            <InfoRowContent>
              <Typography variant="body">
                {processingActivity?.statusUpdatedByInfo?.firstName} {processingActivity?.statusUpdatedByInfo?.lastName}{' '}
                on {formatDateTimeFromUnix(processingActivity.statusUpdatedAt)}
              </Typography>
            </InfoRowContent>
          </InfoRow>
        )}

        {/* Description */}
        <InfoRow title="Description">
          <InfoRowContent>
            {processingActivity.description ? (
              <Typography variant="body">{processingActivity.description}</Typography>
            ) : (
              <EmptyValueRenderer />
            )}
          </InfoRowContent>
        </InfoRow>
        {/* Processing Frequency */}
        <InfoRow title="Processing Frequency">
          <InfoRowContent>
            {!isUndefined(processingActivity.processingFrequency) &&
            processingActivity.processingFrequency !==
              ProcessingActivityProcessingFrequencyDTO.UnspecifiedProcessingActivityProcessingFrequency ? (
              <Typography variant="body">
                {
                  ProcessingActivityFrequencyLabel[
                    processingActivity.processingFrequency ||
                      ProcessingActivityProcessingFrequencyDTO.UnspecifiedProcessingActivityProcessingFrequency
                  ]
                }
              </Typography>
            ) : (
              <EmptyValueRenderer />
            )}
          </InfoRowContent>
        </InfoRow>
        {/* Clickable chip per purpose, redirecting to view screen for that purpose */}
        <InfoRow title={'Purpose'}>
          <InfoRowContent>
            {processingActivity?.purposes?.length ? (
              processingActivity.purposes.map(purpose => (
                <Chip
                  key={purpose.code}
                  label={purpose.name}
                  clickable
                  deleteIcon={<Icon name="OArrowCRight" />}
                  onDelete={() => handlePurposeClick(purpose.code || '')}
                  onClick={() => handlePurposeClick(purpose.code || '')}
                />
              ))
            ) : (
              <EmptyValueRenderer />
            )}
          </InfoRowContent>
        </InfoRow>
        <InfoRow title={'Team'}>
          <InfoRowContent>
            {processingActivity?.teams?.length ? (
              processingActivity.teams.map(team => <TeamChip team={team} showSecondaryLabel={false} />)
            ) : (
              <EmptyValueRenderer />
            )}
          </InfoRowContent>
        </InfoRow>
        {/* Personal Data Types */}
        <InfoRow title="Data Sensitivity">
          <InfoRowContent>
            {processingActivity?.personalDataTypes?.length ? (
              processingActivity.personalDataTypes.map(dataType => (
                <Chip
                  key={dataType}
                  label={<Typography variant="body">{personalDataTypes[toNumber(dataType)]}</Typography>}
                />
              ))
            ) : (
              <EmptyValueRenderer />
            )}
          </InfoRowContent>
        </InfoRow>
        {/* Categories */}
        <InfoRow title="Data Categories">
          <InfoRowContent>
            {processingActivity?.dataCategories?.length ? (
              processingActivity.dataCategories.map(dataCategory => (
                <LabelChip
                  title={dataCategory.name || ''}
                  key={dataCategory.code}
                  variant={LabelChipVariant.category}
                  isViewOnly
                />
              ))
            ) : (
              <EmptyValueRenderer />
            )}
          </InfoRowContent>
        </InfoRow>
        {/* Data Residencies */}
        <InfoRow title="Data Residencies">
          <InfoRowContent>
            {validResidencies.length ? (
              // Used grid below so we can fill space with exactly two columns of the same size
              <Box width="100%" display="grid" gridTemplateColumns="1fr 1fr" columnGap={2} rowGap={2.5}>
                {validResidencies.map(residency => (
                  <DataResidencyTile key={residency.country} residency={residency} />
                ))}
              </Box>
            ) : (
              <EmptyValueRenderer />
            )}
          </InfoRowContent>
        </InfoRow>
        {/* Last updated */}
        <InfoRow title="Last Updated">
          <InfoRowContent>
            {processingActivity?.metadata?.updatedAt ? (
              <Typography variant="body">{formatDateTimeFromUnix(processingActivity?.metadata?.updatedAt)}</Typography>
            ) : (
              <EmptyValueRenderer />
            )}
          </InfoRowContent>
        </InfoRow>
      </Box>
    </Box>
  )
}
