import { MaybeNull } from 'interfaces/common'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { RegulationDTO } from 'interfaces/regulations/regulation'
import { getPossibleRightsFulfillment } from 'utils/helpers/rightsRegulationsHelpers'
import { PolicyScopeFormValues } from '../interfaces'

export const getEditPolicyScopeFormInitialValues = ({
  policyScope,
  regulations,
}: {
  policyScope: MaybeNull<PolicyScopeDTO>
  regulations: RegulationDTO[]
}): PolicyScopeFormValues => {
  const regulationCodes = policyScope?.regulationCodes || []

  const policyScopeRightsFulfillmentValues = (policyScope?.rightsFulfillment || []).map(
    ({ rightCode, fulfillment, allowAppeal, customRightNameID }) => ({
      checked: true,
      rightCode,
      fulfillment,
      allowAppeal,
      customRightNameID,
    }),
  )

  const otherPossibleRightsFulfillmentValues = getPossibleRightsFulfillment({ regulationCodes, regulations }).filter(
    ({ rightCode }) =>
      !policyScopeRightsFulfillmentValues.find(rightFulfillment => rightFulfillment.rightCode === rightCode),
  )

  const rightsFulfillment = [...policyScopeRightsFulfillmentValues, ...otherPossibleRightsFulfillmentValues].map(
    ({ rightCode, fulfillment, checked, allowAppeal, customRightNameID }) => ({
      checked,
      rightCode,
      fulfillment: `${fulfillment}`,
      allowAppeal,
      customRightNameID,
    }),
  )

  const payload = {
    name: policyScope?.name || '',
    code: policyScope?.code || '',
    description: policyScope?.description || '',
    regionCodes: policyScope?.regionCodes || [],
    regulationCodes,
    rightsFulfillment: rightsFulfillment.map(right => ({
      ...right,
      checked: !!policyScope?.rightsFulfillment?.find(({ rightCode }) => rightCode === right?.rightCode) || false,
    })),
    timeToInvokeAppealInDays: policyScope?.timeToInvokeAppealInDays || '30',
    timeToFulfillAppealInDays: policyScope?.timeToFulfillAppealInDays || '45',
  }

  return payload
}
