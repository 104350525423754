import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchPermitStatsPerIdentitySpace } from '../fetchers/fetchPermitStatsPerIdentitySpace'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.permitsStatsPerIdentitySpace,
  queryFn: fetchPermitStatsPerIdentitySpace,
  select: res => res?.data?.maxNumberOfRequests || 0,
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const usePermitsStatsPerIdentitySpaceMaxNumberOfRequests = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    itemsPerPage: config?.itemsPerPage || 5,
    params: {
      ...config?.params,
    },
  })
}
