import { Button, Card, Chip, CircularProgress, CircularProgressSize, Icon, theme } from '@ketch-com/deck'
import { Box, Tooltip, Typography, styled } from '@mui/material'
import { usePermitStatsOverall } from 'api/permitStatistics/queries/usePermitsStatsOverall'
import { AppNavigationContext } from 'components/appLayout/appNavigation/context/AppNavigationContext'
import { EntitledBlurWrapper } from 'components/entitledBlurWrapper/EntitledBlurWrapper'
import { addDays } from 'date-fns'
import { t } from 'i18next'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import moment from 'moment'
import { ConsentPercentBlock } from 'pages/insights/permitsStatistics/components/PermitsSummary/components/PermitStatisticsOverall/components'
import { formatCount } from 'pages/insights/permitsStatistics/components/PermitsSummary/components/PermitStatisticsOverall/utils/formatCount'
import { useContext } from 'react'
import { GatedNavigateStatus, useNavigateGated } from 'utils/routing/hooks/useNavigateGated'
import { RoutesManager } from 'utils/routing/routesManager'
import { SidebarRouteItem } from 'utils/routing/types'
import { useSidebarRoutesConfig } from 'utils/routing/useSidebarRoutesConfig'

const HomeOverviewCard = styled(Card)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '16px 20px',
  '& .MuiCardContent-root': {
    height: '100%',
  },
})

const CardContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '100%',
  height: '100%',
})

const CardHeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '16px',
})

const CardBodyBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: '16px 28px',
})

export const ConsentCard: React.FC = () => {
  const { setSidebarOpenSection } = useContext(AppNavigationContext)
  // Consent data
  const navigateGated = useNavigateGated()
  const reportsItems = useSidebarRoutesConfig().find(item => item.path === RoutesManager.insights.root.getURL())
    ?.subRoutes as SidebarRouteItem[]
  const consentRouteItem = reportsItems.find(item => item.path === RoutesManager.insights.permits.root.getURL())

  const currentDate = new Date()
  const { data: fetchedData, isLoading } = usePermitStatsOverall({
    params: {
      startDate: `${moment(addDays(currentDate, -30)).format('YYYY-MM-DD')}`,
      endDate: `${moment(currentDate).format('YYYY-MM-DD')}`,
    },
  })
  const data = [
    {
      consent: fetchedData?.consent || 0,
      noConsent: fetchedData.noConsent || 0,
      noConsentRequired: fetchedData.consentNotRequired || 0,
      name: 'Page A',
    },
  ]
  const total = (data?.[0]?.consent || 0) + (data?.[0]?.noConsent || 0) + (data?.[0]?.noConsentRequired || 0)
  const consentCount = data?.[0]?.consent || 0
  const noConsentCount = data?.[0]?.noConsent || 0
  const noConsentRequiredCount = data?.[0]?.noConsentRequired || 0
  const allowanceRate = total === 0 ? 100 : ((consentCount + noConsentRequiredCount) / total) * 100
  const consentPercentage = (data[0].consent / total) * 100
  const noConsentPercentage = (data[0].noConsent / total) * 100
  const noConsentRequiredPercentage = (data[0].noConsentRequired / total) * 100

  const handleClick = (item: SidebarRouteItem) => {
    const { status } = navigateGated(
      item.path,
      item.permissions,
      item.entitlements,
      item.requireAllPermissions,
      item.requireAllEntitlements,
    )
    if (status === GatedNavigateStatus.Success && item.sectionId) setSidebarOpenSection(item.sectionId)
  }

  return (
    <HomeOverviewCard
      customContentCard={
        isLoading ? (
          <CircularProgress size={CircularProgressSize.xLarge} />
        ) : (
          <CardContentBox>
            {/* Header */}
            <CardHeaderBox>
              <Box>
                <Typography variant="h3" mb="2px">
                  Consent
                </Typography>
                <Typography color={theme.palette.Text.Secondary}>How much personal data can be used?</Typography>
              </Box>
              <Button variant="icon" color="tertiary" onClick={() => consentRouteItem && handleClick(consentRouteItem)}>
                <Icon name="OArrowCRight" />
              </Button>
            </CardHeaderBox>

            {/* Body */}
            <EntitledBlurWrapper entitlements={consentRouteItem?.entitlements || ENTITLEMENTS.STATS_CONSENT}>
              <CardBodyBox id="card-content">
                <Box display="flex" width="100%">
                  <Box display="flex" flexDirection="column" width="100%" gap="20px">
                    {/* Allowance rate */}
                    <Box>
                      <Typography variant="h1">{allowanceRate.toFixed(1)}%</Typography>
                      <Box display="flex" alignItems="center" gap="8px">
                        <Typography variant="label" color={theme.palette.Text.Secondary}>
                          {t('Allowance Rate')}
                        </Typography>

                        <Tooltip
                          title={t(
                            'The percentage of permits that allow you to use the data for a given purpose across all legal bases.',
                          )}
                          placement="bottom"
                        >
                          <span style={{ marginTop: '4px' }}>
                            <Icon name="FUnknown" width={16} height={16} iconColor={theme.palette.Black.o48} />
                          </span>
                        </Tooltip>
                      </Box>
                    </Box>

                    {/* Allowed */}
                    <Box>
                      <Typography variant="h2">{formatCount(consentCount + noConsentRequiredCount)}</Typography>
                      <Typography variant="label" color={theme.palette.Text.Secondary}>
                        {t('Allowed')}
                      </Typography>
                    </Box>

                    {/* Total */}
                    <Box>
                      <Typography variant="h2">{formatCount(total)}</Typography>
                      <Typography variant="label" color={theme.palette.Text.Secondary}>
                        {t('Total')}
                      </Typography>
                    </Box>

                    <Chip
                      label={
                        <Typography variant="smallBody" display="flex" alignItems="center">
                          Last 30 Days
                        </Typography>
                      }
                      size="small"
                      sx={{ display: 'flex', alignItems: 'center', maxWidth: '92px' }}
                    />
                  </Box>

                  <Box display="flex" flexDirection="column" width="100%">
                    <Box sx={{ gap: 0.625, width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                      {/* No Consent */}

                      <ConsentPercentBlock
                        title={t('No Consent')}
                        percent={noConsentPercentage}
                        fillColor={theme.palette.Black.o16}
                        textColor="darkDusk"
                        count={noConsentCount}
                      />

                      {/* No Consent Required */}

                      <ConsentPercentBlock
                        title={t('Consent Not Required')}
                        textColor="white"
                        percent={noConsentRequiredPercentage}
                        fillColor={theme.palette.Infographics.BlueC.Secondary}
                        count={noConsentRequiredCount}
                      />

                      {/* Consent  */}

                      <ConsentPercentBlock
                        title={t('Consent')}
                        textColor="white"
                        percent={consentPercentage}
                        fillColor={theme.palette.Infographics.GreenA.Primary}
                        count={consentCount}
                      />
                    </Box>
                  </Box>
                </Box>
              </CardBodyBox>
            </EntitledBlurWrapper>
          </CardContentBox>
        )
      }
    />
  )
}
