import { ApplicationEntity } from '../../interfaces/applicationEntities/applicationEntity'
import { getApplicationEntityName } from './entityName'

export const getTooltipContent = (entityType: ApplicationEntity) => {
  switch (entityType) {
    case ApplicationEntity.PERMIT_PROPAGATION:
      return 'Purposes for this property have not been configured yet.'
    case ApplicationEntity.DPIA:
      return 'Ketch recommends you conduct a DPIA given the canonical purpose chosen for this purpose.'

    default: {
      const entityName = getApplicationEntityName({ entityType })

      return `${entityName.singular} configurations need to be updated`
    }
  }
}
