import React from 'react'
import { useQueryClient } from 'react-query'
import { Icon, IconDictionary, theme, Button } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { TrackerProperty } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useUpsertTrackerPropertyDisclosure } from 'api/trackers/mutations/useUpsertTrackerPropertyDisclosure'
import { TrackerKeys } from 'pages/policyCenter/trackers/interfaces'
import { MaybeNull } from 'interfaces'

type Props = {
  trackerKeys: MaybeNull<TrackerKeys>
  trackerProperty: TrackerProperty
}

export const TrackerPropertyDisclosure: React.FC<Props> = ({ trackerKeys, trackerProperty }: Props) => {
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const queryClient = useQueryClient()

  const { mutateAsync: upsertTrackerPropertyDisclosure, isLoading: isUpdatingAcceptedTrackerPropertyDisclosure } =
    useUpsertTrackerPropertyDisclosure({
      onSuccess: async () => {
        await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackerPropertiesInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.trackerDetails])
        showToast({ content: 'Updated disclosure', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to update disclosure', type: 'error' })
      },
    })

  return (
    <Button
      sx={{
        width: 32,
        height: 32,
        borderRadius: '100%',
        padding: 0.25,
      }}
      disabled={!isPermittedToWriteCookie || isUpdatingAcceptedTrackerPropertyDisclosure}
      onClick={() => {
        upsertTrackerPropertyDisclosure({
          params: {
            data: {
              isDisclosed: trackerProperty.isDisclosed ? false : true,
            },
            encodedTrackerKey: trackerKeys?.encodedTrackerKey || '',
            propertyId: trackerProperty.id || '',
          },
        })
      }}
      variant={trackerProperty.isDisclosed ? 'contained' : 'iconCustomRounded'}
      color={trackerProperty.isDisclosed ? 'primary' : 'tertiary'}
      children={
        trackerProperty.isDisclosed ? (
          <Icon name={IconDictionary.OEyeShow} iconColor={theme.palette.white.main} />
        ) : (
          <Icon name={IconDictionary.OEyeHide} />
        )
      }
    />
  )
}
