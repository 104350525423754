import { OrgAvailablePlans, OrgPlanProductIDs } from 'components/planAndBilling/common/types/planAndBilling'
import { RoutesManager } from 'utils/routing/routesManager'

export const OrgPlanIDs: Record<OrgAvailablePlans, OrgPlanProductIDs> = {
  free: 'plan_v5_plg_free',
  starter: 'plan_v5_plg_30k',
  plus: 'plan_v5_plg_150k',
  pro: 'plan_v5_platform_standard',
}

export const OrgPlanNames: Record<OrgAvailablePlans, string> = {
  free: 'Free',
  starter: 'Starter',
  plus: 'Plus',
  pro: 'Pro',
}

export const OrgPlanCosts: Record<Exclude<OrgAvailablePlans, 'pro'>, number> = {
  free: 0,
  starter: 150,
  plus: 333,
}

export const OrgPlanMaxUniquesText: Record<OrgAvailablePlans, string> = {
  free: '5k Unique Users',
  starter: '30k Unique Users',
  plus: '150k Unique Users',
  pro: 'Unlimited Unique Users',
}

export const OrgPlanMaxUniquesLimit: Record<Exclude<OrgAvailablePlans, 'pro'>, number> = {
  free: 5000,
  starter: 30000,
  plus: 150000,
}

export enum OrgPlanAndBillingBannerVariants {
  freePlanInGrace = 'freePlanInGrace',
  freePlanSuspended = 'freePlanSuspended',
  starterPlanInGrace = 'starterPlanInGrace',
  starterPlanSuspended = 'starterPlanSuspended',
  billingInGrace = 'billingInGrace',
  billingMissed = 'billingMissed',
}

export const StripeRedirectURL = `${window.location.origin}${RoutesManager.settings.planAndBilling.root.getURL()}`
export enum StripeInvokeAction {
  PlanUpgrade = 'plan_upgrade',
  PlanDowngrade = 'plan_downgrade',
  BillingFailed = 'billing_failed',
  BillingMissed = 'billing_missed',
  BillingManage = 'billing_manage',
}
export enum StripeInvokeActionResponse {
  Success = 'success',
  Cancel = 'cancel',
}
