import React from 'react'

import { Typography } from '@mui/material'
import { Button, PopUp } from '@ketch-com/deck'

type Props = {
  message?: string
  onClose: () => void
}

export const WarningModal: React.FC<Props> = ({ message, onClose }) => {
  return (
    <PopUp
      title="Warning"
      variant="dialog"
      isOpen={!!message}
      onClose={onClose}
      popUpWidth="550px"
      popUpActionChildren={
        <Button color="secondary" size="large" onClick={onClose}>
          Close
        </Button>
      }
    >
      <Typography>{message}</Typography>
    </PopUp>
  )
}
