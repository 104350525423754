import React from 'react'
import pluralize from 'pluralize'
import { Box, Typography, Tooltip } from '@mui/material'
import { Tracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { theme, IconDictionary, Icon } from '@ketch-com/deck'

export type BlockedTrackerProps = {
  tracker: Tracker
}

export const BlockedTracker: React.FC<BlockedTrackerProps> = ({ tracker = {} as Tracker }) => {
  const { numBlocked, numProperties } = tracker
  const isTrackerBlockedOnSomeProperties = !!numBlocked && numBlocked < numProperties
  const isTrackerBlockedOnAllProperties = numBlocked === numProperties

  if (isTrackerBlockedOnAllProperties)
    return (
      <Box display="flex" alignItems="center" gap={0.5}>
        <Icon name={IconDictionary.OEyeHide} iconColor={theme.palette.darkDuskFaded.main} />
        <Typography variant="label" color="darkDuskFaded.main">
          Blocked
        </Typography>
      </Box>
    )
  if (isTrackerBlockedOnSomeProperties)
    return (
      <Box display="flex" alignItems="center" gap={0.5}>
        <Icon name={IconDictionary.OEyeHide} iconColor={theme.palette.darkDuskFaded.main} />
        <Typography variant="label" color="darkDuskFaded.main">
          Blocked On Some
        </Typography>

        <Tooltip
          title={
            <Box display="flex" flexDirection="column" gap={1} width={180} p={0.5}>
              <Typography variant="label" color="white.main">
                This tracker is blocked on {numBlocked} {pluralize('property', numBlocked)}.
              </Typography>

              <Typography variant="label" color="white.main">
                You can edit this setting on the tracker details page.
              </Typography>
            </Box>
          }
          placement="bottom"
        >
          <Box mt={0.5}>
            <Icon name={IconDictionary.FUnknown} iconColor={theme.palette.darkDuskFaded.main} width={16} height={16} />
          </Box>
        </Tooltip>
      </Box>
    )

  return (
    <Box display="flex" alignItems="center" gap={0.5}>
      <Icon name={IconDictionary.OEyeShow} iconColor={theme.palette.greenHaze.main} />
      <Typography variant="label" color="greenHaze.main">
        Not Blocked
      </Typography>
    </Box>
  )
}
