import React, { Fragment, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import { FieldArray, Formik } from 'formik'
import { compact } from 'lodash'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import {
  getGatewayInitialValues,
  getTransitionItem,
} from 'pages/orchestration/workflows/edit/utils/forms/gateways/gatewayInitialValues'
import { getSplitGatewayFormValidationSchema } from 'pages/orchestration/workflows/edit/utils/forms/gateways/split/splitGatewayValidation'

import { Button } from '@ketch-com/deck'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'
import { formatCodeFromName } from 'utils/formatters'
import { DecisionGatewayConfirmDeleteModal } from '../DecisionGatewayForm/components/DecisionGatewayConfirmDeleteModal'
import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { DecisionPathTile } from '../DecisionGatewayForm/components'
import { FormInput } from 'components/form/FormInput'

interface ISplitGatewayFormProps {
  step: ICanvasStep
  steps: ICanvasStep[]
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep; pathId?: string }) => void
}

export const SplitGatewayForm: React.FC<ISplitGatewayFormProps> = props => {
  const { step, steps, handleClose, handleRemove, handleSave } = props
  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))
  const [isReady] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const stepIndex = useMemo(() => steps.findIndex(({ code }) => code === step.code), [steps, step])

  const onSubmit = (values: any) => {
    handleSave({
      step: {
        ...step,
        description: values.description,
        code: values.code,
        valid: true,
        gateway: {
          ...step.gateway,
          transitions: values.transitions,
        } as ICanvasStep['gateway'],
      },
    })
  }

  return (
    <SidebarConfigsWrapper isLoading={!isReady} handleClose={handleClose}>
      <Formik
        initialValues={getGatewayInitialValues({ step, steps })}
        validationSchema={getSplitGatewayFormValidationSchema({ usedCodes })}
        validateOnMount
        onSubmit={onSubmit}
      >
        {form => {
          const { isValid, submitForm, values, setFieldValue } = form

          return (
            <Fragment>
              <SidebarConfigsHeader
                isValid={isValid}
                step={step}
                steps={steps}
                handleSave={() => submitForm()}
                handleRemove={() => setShowModal(true)}
                handleClose={handleClose}
                copyable
              />

              <SidebarConfigsBody>
                <Box px={3}>
                  <Box pb={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormInput
                          required
                          formPropertyName="description"
                          fullWidth
                          label="Description Label"
                          placeholder="Enter description"
                          onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
                          shouldUpdateDebounced
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid container spacing={1} mb={6}>
                    <Grid item xs={8}>
                      <FormInput
                        formPropertyName="code"
                        fullWidth
                        label="Code"
                        placeholder="Enter code"
                        required
                        shouldUpdateDebounced
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <Typography title="Paths" mb={3} variant="h3">
                      Paths
                    </Typography>
                  </Box>
                  <Box>
                    <FieldArray
                      name="transitions"
                      render={({ push, remove, insert }) => (
                        <DragDropContext
                          onDragEnd={(dragData: DropResult) => {
                            const sourceIndex = dragData?.source?.index
                            const sourceObject = values?.transitions?.[sourceIndex]
                            const destinationIndex = dragData?.destination?.index

                            if (
                              sourceIndex === undefined ||
                              destinationIndex === undefined ||
                              sourceObject === undefined
                            ) {
                              return
                            }

                            remove(sourceIndex)
                            insert(destinationIndex, sourceObject)
                          }}
                        >
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <Box {...provided.droppableProps} ref={provided.innerRef}>
                                {values.transitions.map((_: any, index: number) => (
                                  <DecisionPathTile
                                    step={step}
                                    steps={steps}
                                    stepIndex={stepIndex}
                                    key={index}
                                    index={index}
                                    remove={remove}
                                    expandable={false}
                                    copyable
                                  />
                                ))}
                                {provided.placeholder}
                              </Box>
                            )}
                          </Droppable>

                          <Button
                            color="secondary"
                            onClick={() =>
                              push({
                                ...getTransitionItem(values, steps),
                                name: `Path ${values.transitions.length + 1}`,
                              })
                            }
                            sx={{ mt: 1, mb: 5 }}
                          >
                            Add Path
                          </Button>
                        </DragDropContext>
                      )}
                    />
                  </Box>
                </Box>
              </SidebarConfigsBody>
            </Fragment>
          )
        }}
      </Formik>
      {showModal ? (
        <DecisionGatewayConfirmDeleteModal
          onSubmit={pathId => handleRemove({ step, pathId })}
          onCancel={() => setShowModal(false)}
          step={step}
          stepType={WorkflowActivityGatewayMode.SPLIT}
        />
      ) : null}
    </SidebarConfigsWrapper>
  )
}
