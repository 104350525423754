import React from 'react'
import { Box, Typography } from '@mui/material'
import { Button, PopUp, Icon } from '@ketch-com/deck'

type Props = {
  onCancel: () => void
}

const howItWorksData = [
  {
    title: 'Relationship Mapping',
    description: 'All relations of attributes to each other are structured as Foreign to Primary.',
    icon: <Icon width={56} height={56} name="FaqRelationshipsA" />,
  },
  {
    title: 'Created',
    description: 'Shows all relations created on the Ketch Platform manually.',
    icon: <Icon name="FaqRelationshipsB" width={56} height={56} />,
  },
  {
    title: 'Discovered',
    description: 'Contains relations that were channeled here via a Transponder.',
    icon: <Icon name="FaqRelationshipsC" width={56} height={56} />,
  },
  {
    title: 'Data Type',
    description: 'Attributes can relate to each other only if they contain the same type of data.',
    icon: <Icon name="FaqRelationshipsD" width={56} height={56} />,
  },
]

export const HowItWorksModal: React.FC<Props> = ({ onCancel }) => {
  return (
    <PopUp
      popUpWidth="750px"
      onClose={onCancel}
      title="How it works?"
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Close
          </Button>
        </>
      }
    >
      <Box pl={4} pr={4}>
        {howItWorksData.map((item, index) => (
          <Box display="flex" gap={3} alignItems="center" my={5}>
            {item.icon}
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="label">{item.title}</Typography>
              <Typography variant="body">{item.description}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </PopUp>
  )
}
