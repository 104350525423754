import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchTheme } from 'api/themes/fetchers/fetchTheme'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.theme,
  queryFn: fetchTheme,
  select: res => res?.data?.theme || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery, 'organizationCode'>

export const useTheme = (config: Config) => {
  const { userData } = useAuth()

  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
      organizationCode: userData.organizationCode!,
    },
  })
}
