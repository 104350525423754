import {
  AssetSummaryDTO,
  ListDsrAttributeActionsSummaryBodyDTO,
  GetDSRConfigsResponseBodyDTO,
} from '@ketch-com/figurehead'
import { DsrConfigFormData, RelatedPathsAndAssets } from 'pages/assetManager/dsrConfig/interfaces'

type Args = {
  assetAttributesSummary: AssetSummaryDTO[]
  relatedPathsAndAssets: RelatedPathsAndAssets
  assetsDsrConfig: GetDSRConfigsResponseBodyDTO[]
  assetsHierarchies: ListDsrAttributeActionsSummaryBodyDTO[]
  isEditMode: boolean
}

export const getDsrConfigFormInitialValues = ({
  assetAttributesSummary,
  relatedPathsAndAssets,
  assetsDsrConfig,
  assetsHierarchies,
  isEditMode,
}: Args): DsrConfigFormData => {
  // Identifier initial and persisted values
  const configuration = assetsDsrConfig?.[0]?.datasetDSRConfiguration
  const { identityLookups = [] } = configuration?.[0] || {} //assetsDsrConfig?.[0] is primary dataset config
  const configuredIdentities = identityLookups.flatMap(identityLookup => {
    const { identitySpaceCode = '', identityAttributeId } = identityLookup
    return [identityAttributeId + identitySpaceCode]
  })
  const configuredDatasetIdentities = assetAttributesSummary
    .map(assetAttributeSummary => {
      const identitySpaceCode = assetAttributeSummary?.asset?.identitySpace?.code || ''
      const attributeId = assetAttributeSummary?.asset?.resource?.id || ''
      const key = attributeId + identitySpaceCode
      return configuredIdentities.includes(key) && key
    })
    .filter(key => key)

  const identitySpace =
    assetAttributesSummary?.reduce(
      (prev, curr) => {
        const identitySpaceCode = curr.asset?.identitySpace?.code || ''
        const identitySpaceName = curr.asset?.identitySpace?.name || ''
        const attributeId = curr.asset?.resource?.id || ''
        const attributeName = curr.asset?.resource?.name || ''
        const attributeHasIdentitySpace = configuredIdentities.includes(attributeId + identitySpaceCode)

        return {
          ...prev,
          [attributeId]: {
            checked:
              configuration && configuredDatasetIdentities.length ? (attributeHasIdentitySpace ? true : false) : true,
            identitySpaceCode,
            attributeId,
            attributeName,
            identitySpaceName,
          },
        }
      },
      {} as DsrConfigFormData['identitySpace'],
    ) || {}

  // Related data sets initial and persisted values

  const relatedDatasetKeys = Object.keys(relatedPathsAndAssets)
  const relatedDatasets = {} as DsrConfigFormData['relatedDatasets']
  relatedDatasetKeys?.forEach?.(datasetId => {
    let datasetHasNoReverseLookups = false
    const relatedDatasetConfig = assetsDsrConfig.filter(config => {
      if (!isEditMode && !config?.datasetDSRConfiguration) return true // create mode with no config and include related datasets
      const datasetDSRConfiguration = config?.datasetDSRConfiguration?.[0] || {}
      if (datasetDSRConfiguration.datasetId === datasetId)
        return datasetDSRConfiguration.identityLookups?.some(lookup => lookup.hashId)
      return false
    })?.[0]
    relatedDatasets[datasetId] = {
      included: relatedDatasetConfig ? true : false,
    }
    const relatedPathsList = relatedPathsAndAssets[datasetId]
    const relatedPathsLength = relatedPathsList.length
    const hasMultiplePaths = relatedPathsLength > 1

    relatedPathsList?.forEach?.((relatedPath, relatedIndex) => {
      const isEndOfPath = relatedIndex === relatedPathsLength - 1
      const hasReverseLookup = relatedPath?.path?.some(
        ({ relationships }) => relationships?.some(relationship => relationship?.[0]?.key === 'FK'),
      )
      const identityLookups = relatedDatasetConfig?.datasetDSRConfiguration?.[0]?.identityLookups
      const isRelatedPathSelected = Boolean(
        (identityLookups?.filter(lookup => lookup?.hashId === relatedPath?.hashID) || []).length,
      )
      relatedDatasets[datasetId][relatedPath.hashID] = true
      if (!isEditMode) {
        if (!hasReverseLookup && !datasetHasNoReverseLookups) datasetHasNoReverseLookups = true
        if (!datasetHasNoReverseLookups && isEndOfPath) relatedDatasets[datasetId].included = false
        if (hasMultiplePaths && hasReverseLookup) relatedDatasets[datasetId][relatedPath.hashID] = false
      }

      if (isEditMode && hasMultiplePaths && !isRelatedPathSelected) {
        relatedDatasets[datasetId][relatedPath.hashID] = false
      }
    })
  })

  // Dataset actions initial and persisted values
  let attributeActions = {} as DsrConfigFormData['actions']
  assetsHierarchies?.forEach?.(({ hierarchySummaries }, index) => {
    const datasetId = hierarchySummaries?.[0]?.asset?.resource?.hierarchies?.$parent?.id || ''
    const { id: dsrConfigId, attributeCommands = [] } =
      assetsDsrConfig.filter(config => config?.datasetDSRConfiguration?.[0]?.datasetId === datasetId)?.[0]
        ?.datasetDSRConfiguration?.[0] || {}

    attributeActions[datasetId] =
      hierarchySummaries?.reduce(
        (prev, curr) => {
          const { resource } = curr.asset || {}
          const { id: attributeId = '' } = resource || {}
          return {
            ...prev,
            [attributeId]: {
              command: -1,
              updateValue: '',
              dsrConfigId,
            },
          }
        },
        {} as {
          [key: string]: {
            command: number
            updateValue: string
            dsrConfigId?: string
          }
        },
      ) || {}

    if (attributeCommands.length) {
      attributeCommands?.forEach?.(attributeCommand => {
        const { id: attributeId = '', command = -1, updateValue = '' } = attributeCommand
        attributeActions[datasetId][attributeId] = {
          command,
          updateValue,
          dsrConfigId,
        }
      })
    }
  })

  return {
    identitySpace,
    relatedDatasets,
    actions: attributeActions,
  }
}
