import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { forceCompleteRightInvocation } from '../fetchers/forceCompleteRightInvocation'

const useCustomMutation = createUseMutation({
  mutationFn: forceCompleteRightInvocation,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useForceCompleteRightInvocation = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation)
}
