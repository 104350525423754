import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchJurisdictionRights } from 'api/rights/fetchers/fetchJurisdictionRights'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.jurisdictionRights,
  queryFn: fetchJurisdictionRights,
  select: res => res?.data?.rights || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useJurisdictionRights = (config: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
