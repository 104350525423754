import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { updateTeam } from '../mutations/updateTeam'

const useCustomMutation = createUseMutation({
  mutationFn: updateTeam,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateTeam = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
