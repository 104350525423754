import React from 'react'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import Box from '@mui/material/Box'

import { Button } from 'components/ui-kit/button/Button'
import { CreateHookModal } from './components/CreateHookModal'
import { DeleteHookModal, HooksActionsCellRenderer } from './components'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { HOOK_TYPE_FILTER_SELECT_OPTIONS } from './constants'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { Text } from 'components/ui-kit/typography/Text'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { useHooksListUtils } from './hooks'
import { HOOK_TYPE_NAMES } from '../constants'

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      headerContainer: {
        padding: spacing(2, 2, 3, 2),
        marginBottom: spacing(2),
        borderBottom: `1px solid ${palette.iron.main}`,
      },
      prePostBadge: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
        borderColor: palette.iron.main,
        padding: spacing(0.625, 0.825),
        borderRadius: spacing(0.825),
      },
      hookAssignmentCount: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: palette.marine.main,
        borderRadius: 12.5,
        padding: spacing(0.725, 1),
      },
      techIcon: {
        width: 22,
        height: 22,
        marginRight: spacing(1),
      },
    }),
  { name: 'HooksList' },
)

type Props = {}

export const HooksList: React.FC<Props> = () => {
  const classes = useStyles()
  const {
    handleCreateHookOnClick,
    handleSubmit,
    handleSubmitDeleteHook,
    hooks,
    hookToDeleteId,
    hookToEditId,
    hookTypeFilterSelect,
    isCreateHookModalOpen,
    isLoadingHooks,
    isPermittedToCreateHooks,
    isEntitledToCreateHooks,
    pagination,
    searchString,
    setHookToDeleteId,
    setHookToEditId,
    setHookTypeFilterSelect,
    setIsCreateHookModalOpen,
    setSearchString,
  } = useHooksListUtils()

  return (
    <>
      <Box flexDirection="column" display="flex" gap={3}>
        <Box className={classes.headerContainer}>
          {/* Row 1 */}

          <Box mb={1.75} display="flex" justifyContent="space-between">
            <Text size={20} weight={700} testId="developersHooksHeader">
              Hooks
            </Text>

            <Button
              disabled={!isPermittedToCreateHooks}
              tooltip={
                !isPermittedToCreateHooks || !isEntitledToCreateHooks
                  ? 'You do not have sufficient permissions to perform this action, please contact your admin.'
                  : null
              }
              onClick={handleCreateHookOnClick}
            >
              Create Hook
            </Button>
          </Box>

          {/* Row 2 */}

          <Box display="flex" alignItems="center" gap={1.5}>
            <SearchInput
              onChange={nextSearchString => setSearchString(nextSearchString)}
              onSearch={() => {}}
              onClear={() => setSearchString('')}
              value={searchString}
            />

            <SegmentTabs
              tabs={HOOK_TYPE_FILTER_SELECT_OPTIONS}
              value={hookTypeFilterSelect}
              onChange={v => setHookTypeFilterSelect(v)}
            />
          </Box>
        </Box>

        {!isLoadingHooks && !hooks.length ? (
          <EmptyState
            variant="list"
            icon={<PersonOutlineIcon />}
            title="There are no Hooks for this organization"
            description={isPermittedToCreateHooks ? 'Create your first one to populate the table.' : null}
            actions={[
              {
                title: 'Create Hook',
                size: 'big',
                disabled: !isPermittedToCreateHooks || !isEntitledToCreateHooks,
                tooltip:
                  isPermittedToCreateHooks && isEntitledToCreateHooks
                    ? undefined
                    : 'You do not have sufficient permissions to perform this action, please contact your admin.',
                onClick: () => handleCreateHookOnClick(),
              },
            ]}
          />
        ) : (
          <TablePaginated
            pagination={pagination}
            pending={isLoadingHooks}
            items={hooks}
            cellSettings={{
              name: {
                label: 'Name',
                width: 390,
                cellRenderer: hook => <TextCellRenderer value={hook?.name} />,
              },
              view: {
                width: 125,
                label: 'Type',
                cellRenderer: hook => {
                  return (
                    <Box className={classes.prePostBadge}>
                      <Text
                        lineHeight={0}
                        size={11}
                        weight={500}
                        color="darkChrome"
                        sx={{ textTransform: 'uppercase' }}
                      >
                        {HOOK_TYPE_NAMES[hook?.type || 0]}
                      </Text>
                    </Box>
                  )
                },
              },
              status: {
                label: 'Technology',
                width: 200,
                cellRenderer: hook => (
                  <Box display="flex" alignItems="center">
                    <img
                      className={classes.techIcon}
                      src={getAssetCloudImgUrl(hook?.technology || '')}
                      alt={getAssetCloudImgUrl(hook?.technology || '')}
                    />
                    <Text size={14}>{hook?.technology}</Text>
                  </Box>
                ),
              },
              createdDate: {
                label: 'Assignment',
                width: 210,
                cellRenderer: hook => {
                  return hook?.assignmentCount ? (
                    <Box className={classes.hookAssignmentCount}>
                      <Text size={11} weight={500} color="persianBlue">
                        {hook?.assignmentCount} {hook?.assignmentCount > 1 ? 'assets' : 'asset'}
                      </Text>
                    </Box>
                  ) : (
                    <EmptyValueRenderer />
                  )
                },
              },

              dropDown: {
                cellRenderer: hook => (
                  <HooksActionsCellRenderer
                    hook={hook}
                    setHookToDeleteId={setHookToDeleteId}
                    setHookToEditId={setHookToEditId}
                  />
                ),
              },
            }}
          />
        )}
      </Box>

      {/* Create Hook Modal */}

      {isCreateHookModalOpen || !!hookToEditId ? (
        <CreateHookModal
          hookToEditId={hookToEditId}
          onSubmit={handleSubmit}
          onCancel={() => {
            setIsCreateHookModalOpen(false)
            setHookToEditId('')
          }}
        />
      ) : null}

      {/* Delete Hook Modal */}

      {hookToDeleteId ? (
        <DeleteHookModal
          hookToDeleteId={hookToDeleteId}
          onSubmit={handleSubmitDeleteHook}
          onCancel={() => {
            setHookToDeleteId('')
          }}
        />
      ) : null}
    </>
  )
}
