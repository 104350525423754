import React from 'react'
import { TrackerProperty, NotFoundTracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { Button } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useDeleteNotFoundTrackerOnProperty } from 'api/trackers/mutations/useDeleteNotFoundTrackerOnProperty'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'

type Props = {
  trackerProperty: TrackerProperty
  tracker: NotFoundTracker
}

export const NotFoundTrackerPropertyDelete: React.FC<Props> = ({
  tracker,
  trackerProperty = {} as TrackerProperty,
}) => {
  const queryClient = useQueryClient()
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])

  const { mutateAsync: deleteNotFoundTrackerOnProperty, isLoading: isDeletingNotFoundTrackerOnProperty } =
    useDeleteNotFoundTrackerOnProperty({
      onSuccess: async () => {
        await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.notFoundTrackerPropertiesInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.notFoundTrackersInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.trackerStatistics])
        showToast({ content: 'Removed tracker', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to remove tracker', type: 'error' })
      },
    })

  return (
    <Button
      disabled={isDeletingNotFoundTrackerOnProperty || !isPermittedToWriteCookie}
      color="tertiary"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        deleteNotFoundTrackerOnProperty({
          params: { encodedTrackerKey: tracker?.encodedKey, propertyID: trackerProperty?.id },
        })
      }}
    >
      Remove
    </Button>
  )
}
