import React from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'

import { Text } from 'components/ui-kit/typography/Text'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { Chip } from 'components/ui-kit/chip/Chip'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      dataCategoriesCircle: {
        backgroundColor: palette.marine.main,
        borderRadius: '50%',
        padding: spacing(0.75, 0.75),
        height: spacing(3),
        width: spacing(3),
      },

      dataCategoriesPill: {
        backgroundColor: palette.marine.main,
        padding: spacing(0.75, 0.75),
        height: spacing(3),
      },
    }),
  { name: 'DataCategoriesCountCellRenderer' },
)

type Props = {
  assetSummary: AssetSummaryDTO
}

export const DataCategoriesCountCellRenderer: React.FC<Props> = ({ assetSummary }) => {
  const classes = useStyles()
  const assignedLabels = assetSummary?.asset?.assignedLabels || []
  const inferredLabels = assetSummary?.asset?.inferredLabels || []
  const collectedLabels = [...assignedLabels, ...inferredLabels]
  const dataCategoriesCount = collectedLabels?.length || 0

  if (dataCategoriesCount === 0) return <EmptyValueRenderer color="darkDuskFaded" />

  if (dataCategoriesCount > 9) {
    return (
      <Chip className={classes.dataCategoriesPill}>
        <Text size={11} weight={500} color="persianBlue">
          {dataCategoriesCount}
        </Text>
      </Chip>
    )
  }

  return (
    <Box className={classes.dataCategoriesCircle} display="flex" alignItems="center" justifyContent="center">
      <Text size={11} weight={500} color="persianBlue">
        {dataCategoriesCount}
      </Text>
    </Box>
  )
}
