import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { splitCustomSqls } from '../fetchers/splitCustomSqls'

const useCustomMutation = createUseMutation({
  mutationFn: splitCustomSqls,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useSplitCustomSqls = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation, {})
}
