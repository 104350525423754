import { useCallback, useEffect, useMemo, useContext } from 'react'
import { ContentGroup, Icon, ListItemProps, SettingsLayout, SettingsLayoutMenuItem } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useIsPermitted } from 'utils/hooks'
import { RoutesManager } from 'utils/routing/routesManager'
import { relativePath } from '../../utils/routing/relativePath'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'

export const HELP_CENTER_TITLE = 'Help Center'
export const HELP_CENTER_URL = 'https://docs.ketch.com/'

type SettingsRoutesItemType = {
  title: string
  path?: string
  permissions?: PERMISSIONS[]
  isSectionHeading?: boolean
  isHidden?: boolean
  listItemProps?: ListItemProps
  subItems?: Array<{
    title: string
    path: string
    permissions: PERMISSIONS[]
  }>
}

type RouteItemPath = SettingsLayoutMenuItem & {
  path?: string
  permissions?: Array<string>
}

export const Settings = () => {
  const { isPermitted } = useIsPermitted()
  const location = useLocation()
  const navigate = useNavigate()
  const { isOrgOnFreePlan, isOrgOnStarterPlan } = useContext(PlanAndBillingContext)
  const isOrgOnPlgPlan = isOrgOnFreePlan || isOrgOnStarterPlan

  const settingsRoutesV2: SettingsRoutesItemType[] = useMemo(() => {
    return [
      // General settings
      {
        title: 'General Settings',
        isSectionHeading: true,
      },
      {
        title: 'Account',
        path: relativePath(RoutesManager.settings.profile.root.pattern),
        permissions: [],
      },
      {
        title: 'Identifiers',
        path: relativePath(RoutesManager.settings.identitySpaces.root.pattern),
        permissions: [PERMISSIONS.IDSP_READ],
      },
      // Languages
      {
        title: 'Languages',
        path: relativePath(RoutesManager.deployment.languages.root.pattern),
        permissions: [PERMISSIONS.TRANSLATIONS_READ],
      },
      // Workspace settings
      {
        title: 'Workspace Settings',
        isSectionHeading: true,
      },
      {
        title: 'Organization',
        path: relativePath(RoutesManager.settings.organization.root.pattern),
        permissions: [PERMISSIONS.ORG_ADMIN],
      },
      {
        title: 'Plan & Billing',
        path: relativePath(RoutesManager.settings.planAndBilling.root.pattern),
        isHidden: !isOrgOnPlgPlan,
        permissions: [PERMISSIONS.ORG_ADMIN],
      },
      {
        title: 'Users',
        path: relativePath(RoutesManager.settings.users.root.pattern),
        permissions: [PERMISSIONS.USER_ADMIN],
      },
      {
        title: 'Teams',
        path: relativePath(RoutesManager.settings.teams.root.pattern),
        permissions: [PERMISSIONS.TEAM_READ],
      },
      // DSR Settings
      ...(isPermitted(
        [PERMISSIONS.CUSTOM_NAME_RIGHTS_READ, PERMISSIONS.CONTEXT_VARIABLE_READ, PERMISSIONS.SUBJECTTYPE_READ],
        false,
      )
        ? [
            {
              title: 'DSR Settings',
              isSectionHeading: true,
            },
          ]
        : []),
      {
        title: 'Right Names',
        path: relativePath(RoutesManager.settings.customRightName.root.pattern),
        permissions: [PERMISSIONS.CUSTOM_NAME_RIGHTS_READ],
      },
      {
        title: 'Variables',
        path: relativePath(RoutesManager.settings.contextVariables.root.pattern),
        permissions: [PERMISSIONS.CONTEXT_VARIABLE_READ],
      },
      {
        title: 'Subject Types',
        path: relativePath(RoutesManager.settings.dataSubjectTypes.root.pattern),
        permissions: [PERMISSIONS.SUBJECTTYPE_READ],
      },
      {
        title: '',
        isSectionHeading: true,
      },
      {
        title: HELP_CENTER_TITLE,
        listItemProps: {
          endIcon: <Icon name="OShare" />,
        },
      },
    ]
  }, [isPermitted, isOrgOnPlgPlan])

  const onRouteMenuItemClick = useCallback(
    (r: RouteItemPath) => {
      if (r.title === HELP_CENTER_TITLE) {
        window.open(HELP_CENTER_URL, '_blank')
      } else {
        !!r.path && navigate(r.path)
      }
    },
    [navigate],
  )

  const routes: RouteItemPath[] = useMemo(() => {
    const getRouteObject = (r: SettingsRoutesItemType): RouteItemPath => {
      const isActive = !!r.path && location.pathname.includes(r.path)

      return {
        ...r,
        listItemProps: {
          ...r.listItemProps,
          selected: isActive,
        },
        onClickHandler: () => {
          onRouteMenuItemClick(r)
        },
      }
    }

    // Get routes for new navigation
    return settingsRoutesV2
      .filter(r => !r.isHidden && (!r.permissions || isPermitted(r.permissions)))
      .map(r => getRouteObject(r))
  }, [isPermitted, location.pathname, onRouteMenuItemClick, settingsRoutesV2])

  useEffect(() => {
    if (location.pathname === RoutesManager.settings.root.pattern && routes[0].path) {
      navigate(routes[0].path)
    }
  }, [location.pathname, navigate, routes])

  return (
    <Box display="flex" flexDirection="column" gap={3} flex={1} height="calc(100% - 24px - 64px);">
      <ContentGroup title="Settings" isDivider titleVariant="h1" variant="top-header" />
      <SettingsLayout
        menuItems={routes}
        sx={{
          '&.SettingsLayout-container': {
            flex: 1,
            height: 'fit-content',
            marginBottom: '16px',
            borderRadius: '11px !important',
            '& .SettingsLayout-menu': {
              top: '8px',
            },
          },
          '& .SettingsLayout-content': {
            borderRadius: '11px !important',
          },
        }}
      >
        <Outlet />
      </SettingsLayout>
    </Box>
  )
}
