import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { ContentGroup, DataGrid, EmptyState, Icon } from '@ketch-com/deck'
import { useProcessingActivitiesPaginated } from 'api/processingActivities/queries/useProcessingActivitiesPaginated'
import { dataMapProcessingActivitiesColumns } from './dataMapPurposeProcessingActivitiesDataGridUtils'

type Props = {
  purposeCode: string
}

export const DataMapPurposeProcessingActivities: React.FC<Props> = ({ purposeCode }) => {
  const {
    data: processingActivities,
    pagination,
    isLoading,
  } = useProcessingActivitiesPaginated({
    params: {
      purposeCodes: [purposeCode],
    },
  })

  const totalPages = pagination.totalPages
  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )
  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }
  return (
    <>
      <Box mb={4}>
        <ContentGroup
          title="Processing Activities"
          titleVariant="h3"
          subTitle="All processing activities associated with this Purpose."
          variant="inner-page-header"
        />
      </Box>
      {!isLoading && !processingActivities.length ? (
        <Box
          sx={{
            padding: '50px 0',
            borderRadius: '11px',
            backgroundColor: 'bone.main',
          }}
        >
          <EmptyState
            customIcon={<Icon fontSize="large" name={'ORelated'} />}
            title="No processing activities"
            subTitle="This purpose is not associated with any processing activities"
          />
        </Box>
      ) : (
        <>
          {/* Table of processing activities */}
          <DataGrid
            sx={{
              padding: 'unset',
              height: isLoading ? '300px' : 'auto',
            }}
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              includeOutliers: false,
              columns: dataMapProcessingActivitiesColumns.map(column => column.field),
              expand: true,
            }}
            columns={dataMapProcessingActivitiesColumns}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnReorder
            disableColumnResize
            getRowId={row => row.id}
            loading={isLoading}
            rows={processingActivities}
            getRowHeight={() => 'auto'}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            pageSizeOptions={[rowsPerPage]}
            rowCount={pagination.totalResults}
            hideFooter={totalPages <= 1}
          />
        </>
      )}
    </>
  )
}
