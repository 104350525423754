import { useState } from 'react'
import useDebounce from 'react-use/lib/useDebounce'

import { useAssetsPaginatedV2 } from 'api/assets/queries/useAssetsPaginatedV2'
import { useAssetsPaginatedV2TotalResults } from 'api/assets/queries/useAssetsPaginatedV2TotalResults'

type Args = {
  hookId?: string
}

export const useHookOverviewModalUtils = ({ hookId }: Args) => {
  const [selectedTab, setSelectedTab] = useState('overview')
  const [searchString, setSearchString] = useState<string>('')
  const [queryValue, setQueryValue] = useState<string>('')

  useDebounce(
    () => {
      if (searchString) {
        setQueryValue(searchString)
      } else {
        setQueryValue('')
      }
    },
    500,
    [searchString],
  )

  const {
    data: assetSummaries,
    pagination,
    isLoading,
  } = useAssetsPaginatedV2({
    itemsPerPage: 10,
    params: {
      query: queryValue,
      hookId: hookId,
    },
  })

  const { data: totalResults } = useAssetsPaginatedV2TotalResults({
    itemsPerPage: 2,
    params: {
      hookId: hookId,
    },
  })

  return {
    selectedTab,
    setSelectedTab,
    searchString,
    setSearchString,
    assetSummaries,
    pagination,
    isLoading,
    totalResults,
  }
}
