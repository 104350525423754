import { useEffect, useState } from 'react'
/**
 *
 * @returns {boolean} - true after the 20 second timeout has passed.
 * downstream, the Client is deemed to have catastrophically failed
 * if 20 seconds have passed and there is still no twilio Client defined.
 */
export const useTwilioClientTimeoutTimer = ({
  isOver400DaysOld,
  isRightInvocationLoading,
  isRightInvocationCreatedAfterFeatureReleaseDate,
}: {
  isOver400DaysOld: boolean
  isRightInvocationLoading: boolean
  isRightInvocationCreatedAfterFeatureReleaseDate: boolean
}) => {
  const [hasTwilioClientInitTimedOut, setHasTwilioClientInitTimedOut] = useState(false)
  const [isIntervalSet, setIsIntervalSet] = useState(false)

  useEffect(() => {
    if (isRightInvocationLoading || hasTwilioClientInitTimedOut || isIntervalSet) return
    if (isOver400DaysOld || !isRightInvocationCreatedAfterFeatureReleaseDate) {
      setHasTwilioClientInitTimedOut(true)
      return
    }
    setIsIntervalSet(true)
    setTimeout(() => setHasTwilioClientInitTimedOut(true), 5000)
  }, [
    setHasTwilioClientInitTimedOut,
    isOver400DaysOld,
    isRightInvocationLoading,
    isIntervalSet,
    hasTwilioClientInitTimedOut,
    isRightInvocationCreatedAfterFeatureReleaseDate,
  ])

  return { hasTwilioClientInitTimedOut }
}
