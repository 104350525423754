import { useRef, useCallback } from 'react'

import { useAsyncDebounce } from 'utils/hooks/useAsyncDebounce'

export const useIsAvailableHandler = <Handler extends (value: string) => Promise<boolean>>({
  handler,
  wait,
}: {
  handler: Handler
  wait?: number
}) => {
  const latestRef = useRef<{
    value?: string
    available: boolean
  }>({
    value: undefined,
    available: true,
  })

  const debounced = useAsyncDebounce(async (value?: string) => {
    if (!value) {
      latestRef.current.available = true

      return true
    }

    try {
      const available = await handler(value)
      latestRef.current.available = available

      return available
    } catch (e) {
      latestRef.current.available = false
      return false
    }
  }, wait)

  return useCallback(
    async (value?: string) => {
      // Trigger async validation only for current field change
      if (value === latestRef.current.value) {
        return latestRef.current.available
      }

      latestRef.current.value = value || ''

      return debounced(value)
    },
    [debounced],
  )
}
