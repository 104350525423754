import { APIListRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  hasTaskId?: boolean
  hasNoTaskId?: boolean
}>

type Response = any

export const postAllNotificationsAsRead = ({ hasNoTaskId, hasTaskId }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/notifications/markAllRead`,
      params: {
        filters: {
          ...(hasTaskId && { hasTaskId }),
          ...(hasNoTaskId && { hasNoTaskId }),
        },
      },
    }),
    /* without this dummy body the BE fails with an error because the body is both 
    required AND cannot be blank. The foo: bar payload below is a workaround */
    {
      foo: 'bar',
    },
  )
