import { IconDictionary, Status, StatusState, StatusVariant } from '@ketch-com/deck'
import pluralize from 'pluralize'
import { StatusTimeRemaining, getStatusTimeRemaining } from './utils/getStatusTimeRemaining'
import { useMemo } from 'react'

const getStatusText = (timeRemaining: StatusTimeRemaining, includeTimerString: boolean) => {
  if (includeTimerString) {
    return `Timer: ${Math.abs(timeRemaining.duration)} ${
      timeRemaining.unit === 'Days'
        ? pluralize('day', timeRemaining.duration)
        : pluralize('hour', timeRemaining.duration)
    } ${timeRemaining.duration <= 0 ? 'overdue' : 'left'}`
  }

  if (timeRemaining.duration <= 0) {
    return 'Overdue'
  }

  return `${Math.abs(timeRemaining.duration)} ${
    timeRemaining.unit === 'Days' ? pluralize('day', timeRemaining.duration) : pluralize('hour', timeRemaining.duration)
  }`
}

const getStatusState = (timeRemaining: StatusTimeRemaining): StatusState => {
  if ((timeRemaining.unit === 'Hours' && timeRemaining.duration <= 24) || timeRemaining.duration <= 0) {
    return StatusState.error // Red
  }

  if (timeRemaining.unit === 'Hours') {
    return StatusState.warning // Yellow
  }

  return StatusState.inProgress // Blue
}

export const ActivityTimerChip = ({
  dueAt,
  includeTimerString = false,
}: {
  dueAt: number
  includeTimerString?: boolean
}) => {
  const timeRemaining = getStatusTimeRemaining(dueAt)

  const variant = useMemo(
    () => (timeRemaining.duration <= 0 && !includeTimerString ? StatusVariant.ghost : StatusVariant.filled),
    [timeRemaining.duration, includeTimerString],
  )

  const icon = useMemo(
    () =>
      includeTimerString ? undefined : timeRemaining.duration <= 0 ? IconDictionary.FClock : IconDictionary.OClock,
    [timeRemaining.duration, includeTimerString],
  )

  return (
    <Status
      label={getStatusText(timeRemaining, includeTimerString)}
      status={getStatusState(timeRemaining)}
      variant={variant}
      icon={icon}
    />
  )
}
