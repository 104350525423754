import { theme } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { UserChip } from 'components/chip/UserChip'
import { dataSystemProgressIndicatorStyles } from 'pages/dataSystems/DataSystem/constants'
import { useDataSystemsContext } from 'pages/dataSystems/DataSystem/context/DataSystemContext'
import { ProgressIndicator } from 'pages/policyCenter/assessments/view/components/ProgressIndicator'

export const SystemProfileStatusWidget: React.FC = () => {
  const { appDescriptor, userOwners } = useDataSystemsContext()

  const progressPercentage = Math.round((appDescriptor!.progress!.filled! / appDescriptor!.progress!.total!) * 100)
  const remainingFieldsCount = appDescriptor?.progress?.total! - appDescriptor?.progress?.filled!
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        border={`2px solid ${theme.palette.Highlight.PrimaryDark}`}
        borderRadius={2}
        paddingX={3}
        paddingTop={2}
        paddingBottom={3}
        sx={{
          backgroundColor: theme.palette.Common.Container,
        }}
      >
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="h4" color={theme.palette.Highlight.PrimaryDark}>
            Add More Information
          </Typography>
          <Typography variant="body" color={theme.palette.Highlight.PrimaryDark}>
            Complete System's profile with additional details.
          </Typography>
        </Box>

        <Box display="flex" paddingY={2} gap={1.5}>
          <ProgressIndicator percent={progressPercentage} width={48} style={dataSystemProgressIndicatorStyles} />
          <Box display="flex" flexDirection="column">
            <Typography variant="h2" color={theme.palette.Highlight.PrimaryDark}>{`${progressPercentage}%`}</Typography>
            <Typography variant="body" color={theme.palette.Highlight.PrimaryDark} marginBottom={1}>
              {remainingFieldsCount === 1
                ? `${remainingFieldsCount} field remains`
                : `${remainingFieldsCount} fields remain`}
            </Typography>
            {userOwners.length > 0 && <UserChip user={userOwners[0]} />}
          </Box>
        </Box>
      </Box>
    </>
  )
}
