import React from 'react'
import moment from 'moment'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'

import { NotificationDTO } from '@ketch-com/figurehead'
import Typography from '@mui/material/Typography'
import { TaskNotificationCardTextWithLinksReplaced } from './TaskNotificationCardTextWithLinksReplaced'
import { ActivityTimerChip } from 'pages/orchestration/rightsQueue/components/ActivityTimerChip'
import { Avatar, AvatarSize, Chip, Icon, theme } from '@ketch-com/deck'
import { UserAvatar } from 'components/avatar/UserAvatar'

const NewNotificationIcon = styled(Box)(({ theme }) => ({
  width: 6,
  height: 6,
  borderRadius: '50%',
  background: theme.palette.heliotrope.main,
  marginRight: 4,
}))

type Props = {
  isNew?: boolean
  isLast?: boolean
  isFirst?: boolean
  notification?: NotificationDTO
}

export const TaskNotificationCard: React.FC<Props> = ({ notification, isLast, isNew = false }) => {
  const isAutomated = notification?.task?.isAutomatedAssignedBy
  const imageURL = notification?.task?.assignedByMember?.imageURL
  const imageURLIncludesGravatar = imageURL?.includes?.('gravatar') // if true, then UI will not render because it doesn't match our design system
  const dueAt = notification?.task?.metadata?.dueAt
  const isViaTeam = !!notification?.task?.principalTeam

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderBottom: ({ palette }) => (!isLast ? `1px solid ${palette.iron.main}` : 'none'),
        py: '5px',
      }}
      position="relative"
    >
      {isNew ? (
        <Box>
          <NewNotificationIcon />
        </Box>
      ) : (
        <Box width="10px" />
      )}

      {isAutomated ? (
        <>
          <Box sx={{ mr: 1 }}>
            <Avatar
              icon="FAutomation"
              size={AvatarSize.large}
              variant="circular"
              backgroundColor={theme.palette.iron.main}
              textColor={theme.palette.darkDuskFaded.main}
            />
          </Box>
        </>
      ) : imageURL && !imageURLIncludesGravatar ? (
        <>
          <Box mr={1}>
            <Avatar
              variant="circular"
              alt={[notification?.task?.assignedByMember?.firstName, notification?.task?.assignedByMember?.lastName]
                .filter(Boolean)
                .join(' ')}
              src={notification?.task?.assignedByMember?.imageURL}
              size={AvatarSize.large}
            />
          </Box>
        </>
      ) : (
        <Box mr={1}>
          <UserAvatar user={notification?.task?.assignedByMember} size={AvatarSize.large} />
        </Box>
      )}

      <Box textAlign="left" mb={isViaTeam ? 1 : 0}>
        <TaskNotificationCardTextWithLinksReplaced notification={notification} isNew={isNew} />
        <Box lineHeight="normal">
          <Typography variant="smallBody" color="darkDuskFaded.main" sx={{ fontStyle: 'capitalize' }}>
            {moment((notification?.metadata?.createdAt || 0) * 1000).fromNow()}
          </Typography>
        </Box>
      </Box>

      <Box position="absolute" bottom={8} right={0} display="flex" gap={1} alignItems="center">
        {isViaTeam ? <Chip size="small" icon={<Icon name="OGroup" />} label="Team" /> : null}
        {dueAt ? <ActivityTimerChip dueAt={dueAt} /> : null}
      </Box>
    </Box>
  )
}
