import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { createRelationshipMapping } from '../fetchers/createRelationshipMapping'

const useCustomMutation = createUseMutation({
  mutationFn: createRelationshipMapping,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateRelationshipMapping = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
