import React, { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { DeleteIdentitySpaceModal } from 'components/modals/identitySpaces/DeleteIdentitySpaceModal'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'

type Props = {
  identitySpace: IdentitySpaceDTO
}

export const IdentitySpacesListDropdown: React.FC<Props> = ({ identitySpace }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const onSubmit = async () => {
    await queryClient.refetchQueries(ApiQueryKeys.identitySpacesPaginated)
    setIsDeleteModalOpen(false)
  }

  const menuItems = useMemo(
    () => [
      {
        id: 'edit',
        content: 'Edit',
        onClick: () =>
          navigate(
            RoutesManager.settings.identitySpaces.upsert.root.getURL({
              code: identitySpace.code,
            }),
          ),
        hidden: !isPermitted(PERMISSIONS.IDSP_WRITE) || identitySpace?.managed,
      },
      {
        id: 'delete',
        content: 'Delete',
        onClick: () => setIsDeleteModalOpen(true),
        hidden: !isPermitted(PERMISSIONS.IDSP_WRITE) || identitySpace?.managed,
      },
    ],
    [identitySpace.code, identitySpace?.managed, isPermitted, navigate],
  )

  return (
    <>
      <Button size="medium" variant="iconSubtle" onClick={handleClickListItem}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
        {menuItems.map((item, index) => {
          if (!item.hidden) {
            if (index === menuItems.length - 1) {
              return (
                <ActionSheetItem key={item.id} onClick={item.onClick}>
                  {item.content}
                </ActionSheetItem>
              )
            } else {
              return (
                <>
                  <ActionSheetItem key={item.id} onClick={item.onClick}>
                    {item.content}
                  </ActionSheetItem>
                  <ActionSheetItem divider />
                </>
              )
            }
          } else {
            return null
          }
        })}
      </ActionSheetWrapper>

      {isDeleteModalOpen && (
        <DeleteIdentitySpaceModal
          identitySpaceCode={identitySpace.code}
          onCancel={() => {
            setIsDeleteModalOpen(false)
          }}
          onSubmit={onSubmit}
        />
      )}
    </>
  )
}
