import { Button, PopUp } from '@ketch-com/deck'
import { Box, styled, Typography } from '@mui/material'
import { useDeleteDataSystemGlobalCustomAttribute } from 'api/dataSystems/mutations/useDeleteDataSystemGlobalCustomAttribute'
import pluralize from 'pluralize'

const TextBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

export interface FieldDeleteData {
  id?: string
  index: number
  name: string
}

export interface ConfirmDeleteCustomAttributeModalProps {
  handleOnClose: () => void
  fieldData: FieldDeleteData[]
  handleSave: () => Promise<void>
}

export const ConfirmDeleteCustomAttributeModal: React.FC<ConfirmDeleteCustomAttributeModalProps> = ({
  handleOnClose,
  handleSave,
  fieldData,
}) => {
  const { mutateAsync: deleteDataSystemGlobalCustomAttribute } = useDeleteDataSystemGlobalCustomAttribute({})

  const onDeleteClick = () => {
    fieldData.forEach(field => {
      deleteDataSystemGlobalCustomAttribute({ params: { id: field.id! } })
    })
    handleOnClose()
    handleSave()
  }

  const names = fieldData.map(field => field.name).join(', ')

  return (
    <PopUp
      variant="modal"
      title="Delete Field"
      onClose={handleOnClose}
      popUpActionChildren={
        <>
          <Button size="large" color="secondary" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button size="large" color="primary" onClick={onDeleteClick}>
            Delete
          </Button>
        </>
      }
    >
      <TextBox>
        <Typography>
          You want to permanently delete the <Typography variant="label">{names}</Typography>{' '}
          {`${pluralize('field', fieldData.length)}. All ${fieldData.length > 1 ? 'their' : 'its'} data
          will be lost. `}
        </Typography>

        <Typography>Please confirm this action.</Typography>
      </TextBox>
    </PopUp>
  )
}
