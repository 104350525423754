import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchTeamDefaultAssignee = ({ id, orgCode }: { id?: string; orgCode?: string }) =>
  API.get<{ assigneeId: string }>(
    formatRequestString({
      entityUrl: `/api/teams/${id ? decodeURIComponent(id) : '-1'}/assignee`,
      params: {
        includeDetails: true,
      },
    }),
  )
