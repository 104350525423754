import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { confirmTrackersWithNewPurposes } from '../fetchers/confirmTrackersWithNewPurposes'

const useCustomMutation = createUseMutation({
  mutationFn: confirmTrackersWithNewPurposes,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useConfirmTrackersWithNewPurposes = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
