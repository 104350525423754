import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useNavigate, useLocation } from 'react-router-dom'
import numeral from 'numeral'

import { Text } from 'components/ui-kit/typography/Text'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { ReactComponent as PersonAvatarSmall } from 'assets/icons/person-avatar-small.svg'
import { ReactComponent as PersonAvatarSmallActive } from 'assets/icons/person-avatar-small-active.svg'
import { TeamMemberDTO } from '../../__mocks__/generateDummyTeamMembers'
import { RoutesManager } from 'utils/routing/routesManager'
import { SegmentTabSortOption } from '../../components/SegmentTabSortOption'

const PREFIX = 'UserActivityMenu'

const classes = {
  container: `${PREFIX}-container`,
  searchInput: `${PREFIX}-searchInput`,
  personAvatarIcon: `${PREFIX}-personAvatarIcon`,
  chevronRightIcon: `${PREFIX}-chevronRightIcon`,
  teamMemberListItem: `${PREFIX}-teamMemberListItem`,
  tabClassName: `${PREFIX}-tabClassName`,
}

const StyledBox = styled(Box)(({ theme: { spacing, palette } }) => ({
  [`&.${classes.container}`]: {
    flex: 1,
    maxWidth: 358,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: spacing(1.375),
    backgroundColor: palette.white.main,
    maxHeight: 732,
    padding: spacing(2.5),
    overflowY: 'auto',
  },

  [`& .${classes.searchInput}`]: {
    border: 'none',
    borderBottom: `1px solid ${palette.iron.main}`,
    borderRadius: 0,
  },

  [`& .${classes.personAvatarIcon}`]: {
    height: 12,
    width: 12,
    marginRight: spacing(1.5),
  },

  [`& .${classes.chevronRightIcon}`]: {
    color: palette.fadedDarkGrey.main,
    height: 16,
    '&[data-active="active"]': {
      color: palette.darkDusk.main,
    },
  },

  [`& .${classes.teamMemberListItem}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(1.25, 0.75, 1.25, 1.25),
    borderRadius: spacing(1.375),
    '&:hover': {
      backgroundColor: palette.sphereFaded.main,
      cursor: 'pointer',
    },
    '&[data-active="active"]': {
      backgroundColor: palette.sphereFaded.main,
      cursor: 'unset',
    },
  },

  [`& .${classes.tabClassName}`]: {
    padding: spacing(0.75, 1.5),
    minHeight: 27,
  },
}))

export enum TabSortType {
  ASC = 'asc',
  DEC = 'dec',
}

type Props = { teamMembers: TeamMemberDTO[] }

export const UserActivityMenu: React.FC<Props> = ({ teamMembers }) => {
  const navigate = useNavigate()

  const [sort, setSort] = useState<TabSortType>(TabSortType.ASC)
  const [searchString, setSearchString] = useState('')
  const location = useLocation()
  const userId = location?.pathname?.split('/')?.[4] || ''

  const tabFilterOptions = [
    {
      id: TabSortType.ASC,
      name: <SegmentTabSortOption type={TabSortType.ASC} isActive={TabSortType.ASC === sort} />,
    },
    {
      id: TabSortType.DEC,
      name: <SegmentTabSortOption type={TabSortType.DEC} isActive={TabSortType.DEC === sort} />,
    },
  ]

  return (
    <StyledBox className={classes.container}>
      <Box display="flex" alignItems="center" mb={3}>
        <Box display="flex" alignItems="center">
          <SearchInput
            size="small"
            fullWidth
            className={classes.searchInput}
            onChange={nextSearchString => setSearchString(nextSearchString)}
            onClear={() => setSearchString('')}
            value={searchString}
          />
        </Box>

        <SegmentTabs
          tabClassName={classes.tabClassName}
          value={sort}
          tabs={tabFilterOptions}
          onChange={sort => setSort(sort)}
        />
      </Box>

      {teamMembers.map((teamMember, index) => {
        const isActive = teamMember.id === userId
        const dataActive = isActive ? 'active' : ''

        return (
          <Box
            key={teamMember.id}
            data-active={dataActive}
            onClick={() => {
              const targetPath = RoutesManager.insights.activityAndAccess.userActivity.view.root.getURL({
                code: teamMember.id,
              })
              navigate(targetPath)
            }}
            className={classes.teamMemberListItem}
          >
            {/* Name */}

            <Box display="flex" alignItems="center">
              {dataActive ? (
                <PersonAvatarSmallActive className={classes.personAvatarIcon} data-active={dataActive} />
              ) : (
                <PersonAvatarSmall className={classes.personAvatarIcon} data-active={dataActive} />
              )}

              <Text size={14} lineHeight={2.5} weight={isActive ? 600 : undefined}>
                {teamMember.name}
              </Text>
            </Box>

            {/* Asset Count */}

            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Text size={12} color={isActive ? 'black' : 'darkDuskFaded'} weight={500}>
                {numeral(teamMember.count).format('0,0')}
              </Text>
              <ChevronRightIcon className={classes.chevronRightIcon} data-active={dataActive} />
            </Box>
          </Box>
        )
      })}
    </StyledBox>
  )
}
