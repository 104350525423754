import { API } from 'utils/api/api'
import { PolicyDocumentDTO } from 'interfaces/policyDocuments/policyDocument'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIListRequestParams, APIListResponse } from 'api/common/utils'

type Params = APIListRequestParams<{
  organizationCode: string
  includeIssues?: boolean
  currentID?: string
  ordering?: string
  documentType?: string
}>

type Response = APIListResponse<{ policyDocuments: PolicyDocumentDTO[] }>

export const fetchPolicyDocuments = ({
  organizationCode,
  currentID,
  ordering,
  includeMetadata,
  includeIssues,
  start,
  limit,
  documentType,
  query,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/policy-documents`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
          currentID,
          ordering,
          documentType,
          query,
        },
      },
    }),
  )
