import React, { useContext } from 'react'
import { useField, useFormikContext } from 'formik'
import { useQueryClient } from 'react-query'

// api
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useUpdateWorkflowExecutionStep } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStep'

// components
import { showToast } from 'components/modals/AlertComponent'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
import { TaskContext } from './stepSpecificViews/Task/context'
import { InfoRowEditableTextField } from 'components/InfoRowEditableTextField.tsx/InfoRowEditableTextField'

// types
type Props = {
  canEdit: boolean
  defaultValue: string
  name: string
  isRequired?: boolean
  placeholder: string
  showOptionalLabel?: boolean
  showRequiredLabel?: boolean
  inputId?: string
  infoRowTitle: string
  showValidation?: boolean
}

export const RightsQueueFormInfoRowTextField: React.FC<Props> = ({
  canEdit = true,
  isRequired = false,
  name,
  placeholder,
  defaultValue,
  inputId,
  infoRowTitle,
  showOptionalLabel,
  showRequiredLabel,
  showValidation,
}) => {
  // context
  const { stepId, workflowExecutionId, refetchWorkflowStep, isTaskComplete } = useContext(
    RightInvocationWorkflowStepDetailViewContext,
  )

  const { isEditMode } = useContext(TaskContext)
  const shouldPreventEditOfCompletedTaskNotInEditMode = isTaskComplete && !isEditMode

  const { submitForm, isSubmitting, setSubmitting } = useFormikContext()

  const [, { error, value }, { setValue }] = useField<string>({ name })

  const queryClient = useQueryClient()

  // api
  const { mutateAsync: submitStepDetails } = useUpdateWorkflowExecutionStep({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.workflowExecutionStepDetailsV2, { workflowExecutionId, stepId }])
      await refetchWorkflowStep()
      showToast({ content: 'Successfully updated Task', type: 'success' })
      submitForm()
      setSubmitting(false)
    },
    onError: () => {
      showToast({ content: 'Failed to update Task', type: 'error' })
    },
  })

  // event handlers
  const handleSubmit = async () => {
    if (!!error || !value || value === defaultValue) return
    setSubmitting(true)
    await submitStepDetails({
      params: {
        formData: {
          manualInterventionData: { resolutionDetails: value || '' },
        },
        workflowExecutionId,
        stepId: stepId!,
      },
    })
  }

  const handleOnChange = (value: string) => {
    setValue(value)
  }
  return (
    <InfoRowEditableTextField
      canEdit={canEdit && !shouldPreventEditOfCompletedTaskNotInEditMode}
      defaultValue={defaultValue}
      error={error}
      infoRowTitle={infoRowTitle}
      isError={!!error}
      isLoading={isSubmitting}
      isRequired={isRequired}
      onChange={handleOnChange}
      onSubmit={handleSubmit}
      placeholder={placeholder}
      showOptionalLabel={showOptionalLabel}
      showRequiredLabel={showRequiredLabel}
      showValidation={showValidation}
      value={value}
    />
  )
}
