import { StatusState } from '@ketch-com/deck'
import { ApplianceWork } from '@ketch-com/windlass/dist/appliance/appliances'

export const listStatusColorMapping: Record<string, StatusState> = {
  Connected: StatusState.success,
  Disconnected: StatusState.draft,
  Pending: StatusState.inProgress,
}

export const workStatusColorMapping: Record<ApplianceWork['status'], StatusState> = {
  QUEUED: StatusState.new,
  'IN-PROGRESS': StatusState.inProgress,
  COMPLETED: StatusState.success,
  FAILED: StatusState.error,
}

export const workStatusTextMapping: Record<ApplianceWork['status'], string> = {
  QUEUED: 'Queued',
  'IN-PROGRESS': 'Running',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
}
