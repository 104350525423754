import React from 'react'

import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { InvalidCredentialsIssueType } from 'pages/orchestration/rightsQueue/utilsV2/hasInvalidCredentialsIssue'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { WorkflowExecutionStepStatusRenderer } from '../WorkflowExecutionStepStatusRenderer'

import { useConnections } from 'api/dataSystems/queries/useConnections'
import { Button, PopUp } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  workflowExecutionStep: WorkflowExecutionStepDTO
  onClose: () => void
}

export const WorkflowExecutionStepViewInvalidCredentialsModal: React.FC<Props> = ({
  workflowExecutionStep,
  onClose,
}) => {
  const { status, issues } = workflowExecutionStep
  const navigate = useNavigate()

  const { data: connections } = useConnections({
    params: {
      appInstanceId: issues?.find(issue => issue.entityType === InvalidCredentialsIssueType)?.entityID,
    },
  })

  const onSubmit = async () => {
    const appInstanceId = issues?.find(issue => issue.entityType === InvalidCredentialsIssueType)?.entityID

    if (appInstanceId) {
      const editAppInstanceURL = RoutesManager.systems.connections.upsert.root.getURL({
        id: connections?.[0]?.installedDataSystem?.id,
        instanceId: connections?.[0]?.id,
      })

      navigate(editAppInstanceURL)
    } else {
      onClose()
    }
  }

  return (
    <PopUp
      title="Automated Activity"
      popUpTitleStatusComponent={<WorkflowExecutionStepStatusRenderer issues={issues} status={status} />}
      variant="dialog"
      onClose={onClose}
      popUpActionChildren={
        <>
          <Button
            color="secondary"
            size="large"
            onClick={e => {
              e.stopPropagation() // Stop click from triggering other handlers under the dialog
              onClose()
            }}
          >
            Close
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={e => {
              e.stopPropagation() // Stop click from triggering other handlers under the dialog
              onSubmit()
            }}
          >
            Update Credentials
          </Button>
        </>
      }
    >
      <Typography>
        The connection for this activity has invalid credentials. You must update the credentials in the marketplace
        before this activity will start.
      </Typography>
    </PopUp>
  )
}
