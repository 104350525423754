import React, { useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import { AuthenticatorOverviewModal, DeleteAuthenticatorModal } from './components'
import { useAuthenticatorsListUtils } from './hooks/useAuthenticatorsListUtils'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, DataGrid, EmptyState, TooltipButton } from '@ketch-com/deck'
import { useAuthenticatorsListColumns } from './hooks/useAuthenticatorsListGridColumns'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { GridRowParams } from '@mui/x-data-grid'
import { AuthenticatorDTO } from '@ketch-com/figurehead'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {}

export const AuthenticatorsList: React.FC<Props> = () => {
  const [isAuthenticatorOverviewModalOpen, setIsAuthenticatorOverviewModalOpen] = useState<AuthenticatorDTO | null>()
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const {
    handleSubmitDeleteAuthenticator,
    authenticators,
    authenticatorToDeleteId,
    isLoadingAuthenticators,
    isPermittedToCreateAuthenticators,
    isEntitledToCreateAuthenticators,
    pagination,
    searchString,
    setAuthenticatorToDeleteId,
    setSearchString,
    searchQueryValue,
  } = useAuthenticatorsListUtils()

  const columns = useAuthenticatorsListColumns({
    setAuthenticatorToDeleteId,
  })

  const isPermittedToReadAuthenticators = isPermitted(PERMISSIONS.AUTHENTICATION_READ)

  const totalPages = pagination.totalPages
  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  const breadcrumbs = [
    { title: 'Developers', link: RoutesManager.developers.root.getURL() },
    { title: 'Authenticators', link: RoutesManager.developers.authenticators.root.getURL() },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box flexDirection="column" display="flex" gap={3}>
        <ContentGroup
          isDivider
          title="Authenticators"
          actionRightBlockComponent={
            <TooltipButton
              disabled={!isPermittedToCreateAuthenticators}
              title={
                !isPermittedToCreateAuthenticators || !isEntitledToCreateAuthenticators
                  ? 'You do not have sufficient permissions to perform this action, please contact your admin.'
                  : undefined
              }
              onClick={() => navigate(RoutesManager.developers.authenticators.upsert.getURL({ id: 'new' }))}
            >
              Create Authenticator
            </TooltipButton>
          }
          actionBottomBlockComponent={
            <Box display="flex" alignItems="center" gap={1.5}>
              <SearchTextInput
                value={searchString}
                onChange={nextSearchString => setSearchString(nextSearchString)}
                showClearButton
              />
            </Box>
          }
        />

        {!isLoadingAuthenticators && !authenticators.length && !searchQueryValue ? (
          <EmptyState
            title="There are no Authenticators for this organization"
            subTitle={isPermittedToCreateAuthenticators ? 'Create your first one to populate the table.' : undefined}
            primaryButtonTitle="Create Authenticator"
            primaryButtonTooltipTitle={
              isPermittedToCreateAuthenticators && isEntitledToCreateAuthenticators
                ? undefined
                : 'You do not have sufficient permissions to perform this action, please contact your admin.'
            }
            primaryButtonProps={{
              size: 'large',
              disabled: !isPermittedToCreateAuthenticators || !isEntitledToCreateAuthenticators,
              onClick: () => navigate(RoutesManager.developers.authenticators.upsert.getURL({ id: 'new' })),
            }}
            iconName="OProfile"
          />
        ) : (
          <DataGrid
            sx={{
              ...(isPermittedToReadAuthenticators && {
                cursor: 'pointer',
                '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                  backgroundImage: 'unset',
                  cursor: 'auto',
                },
              }),
              ...(isLoadingAuthenticators && {
                '& .MuiDataGrid-virtualScroller': {
                  height: '300px',
                },
              }),
            }}
            autosizeOnMount
            autosizeOptions={{
              expand: true,
              includeHeaders: true,
              includeOutliers: false,
            }}
            getRowHeight={() => 'auto'}
            columns={columns}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowSelectionOnClick
            disableRowHoverStates={!isPermittedToReadAuthenticators}
            getRowId={row => row.id}
            rows={authenticators || []}
            loading={isLoadingAuthenticators}
            noRowsOverlayProps={{
              buttonTitle: '',
            }}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            pageSizeOptions={[rowsPerPage]}
            rowCount={pagination.totalResults}
            hideFooter={pagination.totalPages < 2}
            onRowClick={({ row: authenticator }: GridRowParams<AuthenticatorDTO>) => {
              if (isPermittedToReadAuthenticators) {
                setIsAuthenticatorOverviewModalOpen(authenticator)
              }
            }}
          />
        )}
      </Box>

      {/* Delete Authenticator Modal */}
      {authenticatorToDeleteId ? (
        <DeleteAuthenticatorModal
          authenticatorToDeleteId={authenticatorToDeleteId}
          onSubmit={handleSubmitDeleteAuthenticator}
          onCancel={() => {
            setAuthenticatorToDeleteId('')
          }}
        />
      ) : null}

      {isAuthenticatorOverviewModalOpen ? (
        <AuthenticatorOverviewModal
          authenticator={isAuthenticatorOverviewModalOpen}
          onCancel={() => setIsAuthenticatorOverviewModalOpen(null)}
        />
      ) : null}
    </>
  )
}
