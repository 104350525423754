import { Field, useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { GetAssetHierarchiesResponseBodyDTO, AssetSummaryDTO } from '@ketch-com/figurehead'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { getAssetType } from 'pages/assetManager/utils'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { DsrIdentityLookupFormData, UrlParams } from 'pages/assetManager/dsrIdentityLookup/interfaces'

type Props = {
  assetsHierarchies: GetAssetHierarchiesResponseBodyDTO[]
  identitySpaces: IdentitySpaceDTO[]
  assetsSummary: AssetSummaryDTO[]
}

export const PrimaryDataset: React.FC<Props> = ({ assetsHierarchies, identitySpaces, assetsSummary }) => {
  const { setFieldValue, isSubmitting } = useFormikContext<DsrIdentityLookupFormData>()

  const providerCode = assetsSummary?.[0]?.asset?.connection?.providerCode || ''
  const datasetType = getAssetType(providerCode, 2)
  const attributeType = getAssetType(providerCode, 3)
  const { id: primaryDatasetId } = useParams<UrlParams>()
  const identitySpaceOptions = identitySpaces.map(space => ({ id: space.code, name: space.name }))
  const attributeOptions =
    assetsHierarchies?.[0]?.hierarchySummaries?.map(({ asset }) => ({
      id: asset?.resource?.id,
      name: asset?.resource?.name,
    })) || []

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="h4">This {datasetType}</Typography>

        <Typography variant="body">
          You can allocate an Identifier to a {attributeType.toLowerCase()} in this {datasetType.toLowerCase()} and
          proceed with the configuration.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={4} mt={2} ml={3.25}>
        <Field
          type="radio"
          disabled={isSubmitting}
          name="datasetId"
          value={primaryDatasetId}
          onClick={() => {
            setFieldValue('isPrimaryDataset', true)
            setFieldValue('relatedDatasetHasIdentitySpace', false)
          }}
        />
        <Box display="flex" flexDirection="column" gap={1} flexBasis={348}>
          <Typography variant="label">Identifier</Typography>
          <FormDropdown
            disabled={isSubmitting}
            fullWidth
            placeholder="Select Identity"
            valueKey="id"
            name="primaryAttributeIdentitySpaceCode"
            items={identitySpaceOptions}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={1} flexBasis={348}>
          <Typography variant="label">To {attributeType}</Typography>
          <FormDropdown
            disabled={isSubmitting}
            fullWidth
            placeholder="Select Column"
            valueKey="id"
            name="primaryAttributeId"
            items={attributeOptions}
          />
        </Box>
      </Box>
    </Box>
  )
}
