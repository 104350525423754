import { FormikProps } from 'formik'
import { IRegionFE } from 'interfaces'
import { RegionDTO } from 'interfaces/regions/region'
import { transformRegionsForFeTree } from 'pages/policyCenter/policyScopes/utils'
import { PolicyScopeFormValues } from '../interfaces'

export const getRegionsHierarchy = (regionGroups: RegionDTO[]): RegionDTO[] => {
  const regionsHierarchyTransformed: IRegionFE[] = []

  regionGroups?.forEach?.(region => transformRegionsForFeTree(region, regionsHierarchyTransformed))

  return regionsHierarchyTransformed
}

export enum PolicyScopeFormStep {
  DETAILS = 'Basic Details',
  REGULATIONS = 'Regulations',
  RIGHTS = 'Rights',
}

export const getFormSteps = () => [
  PolicyScopeFormStep.DETAILS,
  PolicyScopeFormStep.REGULATIONS,
  PolicyScopeFormStep.RIGHTS,
]

export const isStepValid = (step: PolicyScopeFormStep, formikProps: FormikProps<PolicyScopeFormValues>): boolean => {
  const { errors, isValid } = formikProps

  switch (step) {
    case PolicyScopeFormStep.DETAILS:
      return !errors.name && !errors.code

    case PolicyScopeFormStep.REGULATIONS: {
      return !errors.regulationCodes && !errors.regionCodes
    }

    case PolicyScopeFormStep.RIGHTS:
      return isValid

    default:
      return true
  }
}

export { useValidationSchema } from './useValidationSchema'
export { mapValuesToPayload } from './mapValuesToPayload'
export { getCreatePolicyScopeInitialValues } from './getCreatePolicyScopeInitialValues'
