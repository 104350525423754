import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { searchParamIgnoreArray } from 'utils/constants/searchParamIgnoreArray'

/**
 * Custom hook for managing URL query params as filters
 * @returns An object containing functions for manipulating URL query params as filters
 */
export const useFilters = () => {
  // Destructure values from useSearchParams
  const [searchParams, setSearchParams] = useSearchParams()

  // Use memoization to store search param array
  const searchParamArray = useMemo(() => Array.from(searchParams.entries()), [searchParams])

  /**
   * Adds a single search parameter to the URL query params
   * @param key - The key of the search parameter
   * @param value - The value of the search parameter
   */
  const addSingleSearchParam = ({ key, value }: { key: string; value: string }) => {
    setSearchParams({ key, value })
  }

  /**
   * Removes a single search parameter from the URL query params
   * @param key - The key of the search parameter to be removed
   */
  const removeSingleSearchParam = (key: string) => {
    if (searchParams.has(key)) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete(key)
      setSearchParams(newSearchParams)
    }
  }

  /**
   * Removes multiple search parameters from the URL query params
   * @param keys - An array of keys of the search parameters to be removed
   */
  const removeMultipleSearchParams = (keys: string[]) => {
    const newSearchParams = new URLSearchParams(searchParams)
    keys?.forEach?.(key => newSearchParams.delete(key))
    setSearchParams(newSearchParams)
  }

  /**
   * Removes a specific value from a comma-separated list of values associated with a search parameter in the URL query params
   * @param key - The key of the search parameter
   * @param value - The value to be removed from the comma-separated list of values associated with the search parameter
   */
  const removeQueryParamFromList = ({ key, value }: { key: string; value: string }) => {
    const paramValues = searchParams.get(key)?.split(',') || []
    const filteredParamValues = paramValues.filter(paramValue => paramValue !== value)
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set('page', '0')
    if (filteredParamValues.length === 0) {
      newSearchParams.delete(key)
    } else {
      newSearchParams.set(key, filteredParamValues.join(','))
    }
    setSearchParams(newSearchParams)
  }

  /**
   * Clears all values associated with a search parameter from the URL query params
   * @param key - The key of the search parameter
   */
  const clearAllEntriesForKey = (key: string) => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete(key)
    setSearchParams(newSearchParams)
  }

  /**
   * Clears all search parameters from the URL query params
   */
  const clearAllSearchParams = () => {
    const newSearchParams = new URLSearchParams()
    const orderByValue = searchParams.get('orderBy')
    const order = searchParams.get('ordering')
    if (orderByValue) newSearchParams.set('orderBy', orderByValue)
    if (order) newSearchParams.set('ordering', order)
    newSearchParams.set('page', '0')
    setSearchParams(newSearchParams)
  }

  /**
   * Gets the value of a query parameter by key from a URLSearchParams object.
   *
   * @param {Object} params - An object with a `key` property that specifies the name of the query parameter to retrieve.
   * @param {string} params.key - The name of the query parameter to retrieve.
   * @returns {string[]} An array of strings representing the values of the query parameter, or an empty array if the parameter is not found.
   */
  const getParamValueByKey = ({ key }: { key: string }): string[] => {
    return searchParams.get(key)?.split(',') || []
  }

  /**
   * Replaces the query parameters in a URLSearchParams object with new values.
   *
   * @param {Object.<string, string>} newParams - An object that maps query parameter names to their new values.
   */
  const replaceParams = (newParams: { [key: string]: string }): void => {
    const searchParamsCopy = new URLSearchParams(searchParams)
    let hasChanged = false

    // Use for...in loop to iterate over newParams object
    for (const key in newParams) {
      if (!searchParamsCopy.has(key) || searchParamsCopy.get(key) !== newParams[key]) {
        searchParamsCopy.set(key, newParams[key])
        hasChanged = true
      }
    }

    if (hasChanged) {
      setSearchParams(searchParamsCopy)
    }
  }

  /**
   * Calculates the number of filters applied to the current search query.
   *
   * @returns {number} The number of filters applied to the search query.
   */
  const filterCount = useMemo((): number => {
    return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
      if (!searchParamIgnoreArray.includes(key)) {
        const values = value.split(',')
        return acc + values.length
      }
      return acc
    }, 0)
  }, [searchParams])

  // Return only necessary values as an object
  return {
    addSingleSearchParam,
    clearAllEntriesForKey,
    clearAllSearchParams,
    filterCount,
    getParamValueByKey,
    removeQueryParamFromList,
    removeSingleSearchParam,
    removeMultipleSearchParams,
    replaceParams,
    searchParamArray,
    searchParams,
    setSearchParams,
  }
}
