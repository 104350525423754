import React from 'react'
import { InstalledDataSystemV2DTO } from '@ketch-com/figurehead'
import { ConnectionsList } from './ConnectionsList'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  appDescriptor?: InstalledDataSystemV2DTO
  backgroundColor?: string
}

export const Connections: React.FC<Props> = ({ appDescriptor, backgroundColor }) => {
  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.systems.root.getURL() },
    { title: 'Systems', link: RoutesManager.systems.systems.root.getURL() },
    ...(appDescriptor
      ? [
          {
            title: appDescriptor?.dataSystem?.name,
            link: RoutesManager.systems.id.root.getURL({ id: appDescriptor?.id }),
          },
        ]
      : []),
    { title: 'Connections' },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ConnectionsList listItemBackgroundColor={backgroundColor} />
    </>
  )
}
