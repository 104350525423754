import { MaybeNull } from 'interfaces'
import { AppDTO, AppFormDTO, AppInstanceDTO, AppInstanceFieldDTO } from 'interfaces/apps'

export const getAppInstanceFormValues = ({
  app,
  appInstance,
  appInstanceFields,
}: {
  app?: MaybeNull<AppDTO>
  appInstance: MaybeNull<AppInstanceDTO>
  appInstanceFields: AppInstanceFieldDTO[]
}): AppFormDTO => {
  const fields: AppFormDTO['fields'] = []

  app?.form?.forEach(formField => {
    fields.push({
      ...formField,
      value:
        /* temporarily hard coding to self hosted transponder.   */
        formField.name === 'transponderType'
          ? 'self'
          : appInstanceFields?.find(field => field.name === formField.name)?.value || '',
    })
  })

  return {
    name: appInstance?.name || '',
    code: appInstance?.code || '',
    description: appInstance?.description || '',
    fields,
    identitySpaces:
      app?.identitySpaces?.map(identitySpace => ({
        name: identitySpace.name,
        code: identitySpace.code,
        managed: !!identitySpace.managed,
        filters: identitySpace?.filters?.[0],
        userIdentitySpaceCode:
          appInstance?.identitySpaceMappings?.find(
            ({ destinationIdentitySpace }) => destinationIdentitySpace === identitySpace.code,
          )?.sourceIdentitySpace || !!identitySpace.managed
            ? identitySpace.code
            : '',
      })) || [],
  }
}
