import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

type Props = {
  title: string
  subtitle: React.ReactNode
  icon: React.ReactNode
  withDivider?: boolean
}

export const DsrMetadataChip: React.FC<Props> = ({ title, subtitle, icon, withDivider = true }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          height: 90,
          flex: 1,
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column" flex={1}>
          <Typography variant="body" color="darkDuskFaded.main">
            {title}
          </Typography>
          <Box display="flex" alignItems="center" maxWidth="80%">
            {subtitle}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          {icon}
        </Box>
      </Box>
      {withDivider && <Divider orientation="vertical" flexItem />}
    </>
  )
}
