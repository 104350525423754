import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { getInitialValues, getValidationSchema } from 'pages/assetManager/attributes/upsert/utils'
import { Group } from 'components/ui-layouts/group/Group'
import { Button } from '@ketch-com/deck'
import { UpsertLayout } from 'components/ui-layouts/upsertLayout/UpsertLayout'
import { UpsertLayoutFooter } from 'components/ui-layouts/upsertLayout/UpsertLayoutFooter'
import { MaybeNull } from 'interfaces/common'
import { EditAttributeFormValues } from 'interfaces/assets'
import { LabelDefinitionDTO as LabelDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { EditAttributeForm } from './components'
import { AssetSummaryDTO } from '@ketch-com/figurehead'

type Props = {
  isReady: boolean
  isUpdatingAttribute: boolean
  onSubmit: (values: EditAttributeFormValues) => Promise<void>
  attribute: MaybeNull<AssetSummaryDTO>
  labels: MaybeNull<LabelDTO[]>
}

export const AttributeUpsertView: React.FC<Props> = ({ isReady, onSubmit, attribute, isUpdatingAttribute }) => {
  const navigate = useNavigate()
  const initialValues = useMemo(() => getInitialValues({ attribute }), [attribute])
  const validationSchema = useMemo(getValidationSchema, [])

  return (
    <UpsertLayout
      isReady={isReady}
      title="Edit Attribute"
      breadcrumbs={[
        {
          title: 'Data Map',
          link: RoutesManager.assetManager.assets.root.getURL(),
        },
        { title: 'Assets', link: RoutesManager.assetManager.assets.list.getURL() },
        {
          title: attribute?.asset?.resource?.name || attribute?.asset?.resource?.id,
          link: RoutesManager.assetManager.assets.detail.root.getURL({ code: attribute?.asset?.resource?.id || '' }),
        },
        { title: 'Edit Attribute' },
      ]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm, isValid }) => {
        return (
          <>
            <EditAttributeForm assetSummary={attribute} />

            <UpsertLayoutFooter>
              <Group>
                <Button
                  pending={isSubmitting}
                  color="secondary"
                  size="large"
                  onClick={() => {
                    navigate(
                      RoutesManager.assetManager.assets.detail.root.getURL({
                        code: attribute?.asset?.resource?.id || '',
                        resourceTypeCode: attribute?.asset?.resource?.resourceType?.code || '',
                      }),
                    )
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!isValid}
                  pending={isSubmitting || isUpdatingAttribute}
                  size="large"
                  onClick={submitForm}
                >
                  Save
                </Button>
              </Group>
            </UpsertLayoutFooter>
          </>
        )
      }}
    </UpsertLayout>
  )
}
