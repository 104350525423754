import React, { createContext } from 'react'
import { PrivacyProtocolUpsertUtils, usePrivacyProtocolUpsertUtils } from '../utils/usePrivacyProtocolUpsertUtils'

export const PrivacyProtocolUpsertContext = createContext({} as PrivacyProtocolUpsertUtils)

type Props = {
  children?: React.ReactNode | undefined
}

export const PrivacyProtocolUpsertContextProvider: React.FC<Props> = ({ children }) => {
  // Use utils hook
  const hookValues = usePrivacyProtocolUpsertUtils()

  return (
    <PrivacyProtocolUpsertContext.Provider value={{ ...hookValues }}>{children}</PrivacyProtocolUpsertContext.Provider>
  )
}

export const withPrivacyProtocolUpsertContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <PrivacyProtocolUpsertContextProvider>
        <Component {...props} />
      </PrivacyProtocolUpsertContextProvider>
    )
  }
}
