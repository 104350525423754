import React from 'react'
import { SxProps, Theme } from '@mui/material'
import { useField, FieldValidator } from 'formik'
import Box from '@mui/material/Box'

import { Hint } from 'components/ui-kit/form/common/hint/Hint'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { Checkbox, Props as BaseCheckboxProps } from 'components/ui-kit/checkbox/Checkbox'

type OmittedProps = Omit<BaseCheckboxProps, 'name' | 'value'>

interface Props extends OmittedProps {
  /** Formik field name */
  name: string
  /** Text to be displayed below Component */
  hint?: string
  /** Text to be displayed above Component */
  label?: React.ReactNode
  /** Display Asterisk as required field */
  required?: boolean
  /** Custom validation method */
  validate?: FieldValidator
  /** Should label be displayed inline? (Default: false) */
  inlineLabel?: boolean
  /** Custom onChange method (should change formValues on top level) */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  /** Prop to provide sx style overrides */
  sx?: SxProps<Theme>
}

/**
 * -
 */
export const FormCheckbox: React.FC<Props> = ({
  name,
  hint,
  label,
  validate,
  onChange,
  className,
  inlineLabel = false,
  sx,
  ...checkboxProps
}) => {
  const [field, { error, touched }] = useField({ name, validate, type: 'checkbox' })
  const showError = error && touched

  return (
    <Box display="flex" flexDirection="column" className={className} sx={sx}>
      {!inlineLabel && label ? <Label>{label}</Label> : null}

      <Checkbox
        title={inlineLabel ? label : undefined}
        {...checkboxProps}
        {...field}
        onChange={onChange || field.onChange}
      />

      {showError && <Error>{error}</Error>}

      {!!hint && !showError && <Hint>{hint}</Hint>}
    </Box>
  )
}
