import React from 'react'
import pluralize from 'pluralize'
import { Box, Typography, Tooltip } from '@mui/material'
import { Chip } from '@ketch-com/deck'
import { TrackerWithNewPurposes } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type Props = {
  tracker: TrackerWithNewPurposes
}

export const Categories: React.FC<Props> = (
  { tracker = {} as TrackerWithNewPurposes },
  ref?: React.ForwardedRef<HTMLSpanElement>,
) => {
  const noOfCategories = tracker?.categories?.length || 0

  return (
    <Tooltip
      title={
        <Box>
          {tracker?.categories?.map((category, index) => {
            return (
              <Box key={index} my={1}>
                <Typography variant="label" color="white.main">
                  {category?.name}
                </Typography>
              </Box>
            )
          })}
        </Box>
      }
      placement="bottom"
    >
      <Box>
        <Chip size="small" label={`${noOfCategories} ${pluralize('Category', noOfCategories)}`} />
      </Box>
    </Tooltip>
  )
}
