import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { DeleteEventAndUnassignWorkflowV2ResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  eventId: string
  includeMetadata?: boolean
}>

export const deleteEvent = ({ eventId, includeMetadata }: Params) =>
  API.delete<DeleteEventAndUnassignWorkflowV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/events/${eventId}`,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
  )
