import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep } from '../../interfaces'
import { getUuidWithoutHyphensAndStartingWithALetter } from '../getUuidWithoutHyphensAndStartingWithALetter'
import { createPlaceholderStep } from './createPlaceholder'
import { getNextJoinTile } from './getNextJoinTile'
import { isTileInConnectedNest } from './isTileInConnectedNest'

type ConnectedNestReturnValue = {
  gatewayStep: ICanvasStep
  placeholderA: ICanvasStep
  placeholderB: ICanvasStep
  joinStep?: ICanvasStep
}

type Props = {
  stepToCreate: ICanvasStep
  standardJoinActivity: ICanvasStep
  mode: WorkflowActivityGatewayMode
  nextStepTransitionId?: string
  steps: ICanvasStep[]
}

export const createConnectedNest = ({
  stepToCreate,
  steps,
  standardJoinActivity,
  nextStepTransitionId,
  mode,
}: Props): ConnectedNestReturnValue => {
  const isTileAlreadyInNest = isTileInConnectedNest({ step: stepToCreate, steps })
  const nextJoinTile = getNextJoinTile({ step: stepToCreate, steps })

  const postJoinPlaceholder = createPlaceholderStep(undefined, '')

  const joinStep: ICanvasStep | undefined = isTileAlreadyInNest
    ? undefined
    : {
        ...standardJoinActivity,
        ID: getUuidWithoutHyphensAndStartingWithALetter(),
        code: getUuidWithoutHyphensAndStartingWithALetter(),
        gateway: {
          ...standardJoinActivity?.gateway,
          transitions: nextStepTransitionId ? [{ ID: nextStepTransitionId }] : [{ ID: postJoinPlaceholder.ID }],
        } as ICanvasStep['gateway'],
      }

  const placeholderA = createPlaceholderStep(joinStep?.ID || nextStepTransitionId || nextJoinTile?.ID, 'Path 1')
  const placeholderB = createPlaceholderStep(joinStep?.ID || nextJoinTile?.ID, 'Path 2')

  const gatewayStep = {
    ...stepToCreate,
    gateway: {
      ...stepToCreate?.gateway,
      mode,
      transitions: [placeholderA, placeholderB],
    },
  } as ICanvasStep

  return { gatewayStep, placeholderA, placeholderB, joinStep }
}
