import { PerIdentitySpacePermitStatsDTO } from '@ketch-com/figurehead'

export type ReshapedPerIdentitySpaceDTO = {
  name: string
  code: string
  percent: number
  totalRequests: number
}

export const getTransformedPerIdentitySpaceData = (
  identitySpaces: PerIdentitySpacePermitStatsDTO[],
  identitySpacesTotalRequests: number,
): ReshapedPerIdentitySpaceDTO[] => {
  const reshapedData = []

  for (let i = 0; i < identitySpaces.length; i++) {
    const el = identitySpaces[i]

    const payload = {
      name: el?.name || '',
      code: el?.code || '',
      percent: ((el?.requestCount || 0) / (identitySpacesTotalRequests || 1)) * 100,
      totalRequests: el?.requestCount || 0,
    }
    reshapedData.push(payload)
  }

  return reshapedData.sort((a, b) => b.percent - a.percent)
}
