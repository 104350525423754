import React, { useContext } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Button, TooltipButton } from '@ketch-com/deck'
import { FormTemplateUpsertContext } from 'pages/consentAndRights/forms/templates/upsert/context/FormTemplateUpsertContextProvider'
import { FormTemplateDTO } from '@ketch-com/figurehead'
import { useFormikContext } from 'formik'

type Props = {
  title: string
  isRequired?: boolean
  onRemove?: () => void
}

export const Header: React.FC<Props> = ({ title, isRequired, onRemove }) => {
  const { setActiveSectionIndex, activeSectionIndex, mandatoryFormFieldIds } = useContext(FormTemplateUpsertContext)
  const { values } = useFormikContext<FormTemplateDTO>()

  const doesActiveSectionContainRequiredFormFields =
    typeof activeSectionIndex === 'number'
      ? values?.sections?.[activeSectionIndex]?.formFields?.some(formField =>
          mandatoryFormFieldIds.includes(formField?.id || ''),
        )
      : false

  return (
    <Box display="flex" flexDirection="column" mb={2.25}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="iron.main"
        pb={2.25}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h3">{title}</Typography>

          {!isRequired && (
            <>
              &nbsp;
              <Typography variant="h3" color="fadedDarkGrey.main">
                (Optional)
              </Typography>
            </>
          )}
        </Box>

        <Box display="flex" alignItems="center">
          {!doesActiveSectionContainRequiredFormFields && onRemove && (
            <TooltipButton
              title={(values?.sections?.length || 0) < 2 ? 'Form requires at least one section' : ''}
              disabled={(values?.sections?.length || 0) < 2}
              color="tertiary"
              size="large"
              onClick={() => {
                onRemove?.()
                setActiveSectionIndex(undefined)
              }}
              sx={{ mr: 1.5 }}
            >
              Remove
            </TooltipButton>
          )}

          <Button color="secondary" size="large" onClick={() => setActiveSectionIndex(undefined)}>
            Close
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
