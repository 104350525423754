import { InlineEditCtxVariable } from 'pages/orchestration/rightsQueue/stepDetails/components/InlineEditCtxVariable/InlineEditCtxVariable'
import { FormikErrors } from 'formik'
import { OutcomeVariablesInitialValues, getOutcomeVariablesInitialValues } from '../OutcomeVariables/utils'
import { InfoRow } from '@ketch-com/deck'
import { useInlineCtxVariableUtils } from 'pages/orchestration/rightsQueue/stepDetails/components/InlineEditCtxVariable/hooks'
import { ContextVariableCategoryDTO, StepDetailsVariableDTO } from '@ketch-com/figurehead'
import { DataSubjectVariablesInitialValues, getDataSubjectVariablesInitialValues } from '../DataSubjectVariables/utils'
import { Box } from '@mui/material'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

export interface InfoRowOutcomeVariableType {
  canEdit: boolean
  name: string
  variableIndex: number
  variable: StepDetailsVariableDTO
  errors: FormikErrors<
    ReturnType<typeof getOutcomeVariablesInitialValues | typeof getDataSubjectVariablesInitialValues>
  >
  hasAttemptedToResolve: boolean
}
// used in OutcomeVariables and DataSubjectVariables
export const InfoRowCtxVariable = ({
  canEdit = true,
  name,
  variableIndex,
  variable,
  errors,
  hasAttemptedToResolve,
}: InfoRowOutcomeVariableType) => {
  const { isEditing, isSubmitting, handleEditMode, handleSubmit, value, setValue, setIsEditing, initialValues } =
    useInlineCtxVariableUtils({
      name,
      inputId: variable?.code || '',
      canEdit,
      contextVariable: variable,
    })

  let showRequired
  let isError
  if (variable?.category === ContextVariableCategoryDTO.OutcomeContextVariableCategory) {
    const errs = errors as FormikErrors<ReturnType<typeof getOutcomeVariablesInitialValues>>
    showRequired = variable.isRequired && hasAttemptedToResolve && !!errs?.outcomeContext?.[variableIndex]
    isError = hasAttemptedToResolve && !!errs?.outcomeContext?.[variableIndex]
  } else {
    const errs = errors as FormikErrors<ReturnType<typeof getDataSubjectVariablesInitialValues>>
    showRequired =
      variable.isRequired &&
      hasAttemptedToResolve &&
      !!(errors as FormikErrors<ReturnType<typeof getDataSubjectVariablesInitialValues>>)?.dataSubjectContext?.[
        variableIndex
      ]
    isError = hasAttemptedToResolve && !!errs?.dataSubjectContext?.[variableIndex]
  }

  const showOptional = !variable.isRequired
  const infoRowTitle = `${variable?.name || ''} ${
    variable.isRequired ? '(Required)' : showOptional ? '(Optional)' : ''
  }`

  return (
    <InfoRow
      title={infoRowTitle}
      isValidation={showRequired && isError}
      key={variable.code}
      isEditable={canEdit}
      isEditing={isEditing}
      isLoading={isSubmitting}
      onEditChange={handleEditMode}
      onAcceptChange={() => {
        setIsEditing(false)
        handleSubmit()
      }}
      onCancelChange={() => {
        const setValuePayload =
          variable?.category === ContextVariableCategoryDTO.OutcomeContextVariableCategory
            ? (initialValues as OutcomeVariablesInitialValues)?.outcomeContext?.[variableIndex]?.value
            : (initialValues as DataSubjectVariablesInitialValues)?.dataSubjectContext?.[variableIndex]?.value
        setValue(setValuePayload)
        setIsEditing(false)
      }}
      isEmpty={!value}
    >
      {!isEditing ? (
        <Box display="flex" justifyContent="space-between" width="100%">
          {value ? value : <EmptyValueRenderer />}
        </Box>
      ) : (
        <InlineEditCtxVariable
          contextVariable={variable}
          inputId={variable?.code || ''}
          canEdit={canEdit}
          isRequired={variable.isRequired}
          name={name}
          isError={isError}
          variableIndex={variableIndex}
        />
      )}
    </InfoRow>
  )
}
