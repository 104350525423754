/** @jsxRuntime classic */
/** @jsx jsx */
import { createElement, useRef, useEffect } from 'react'
import { h, render } from 'preact'
import {
  LanyardProvider,
  LanyardPreferenceExperience,
  LanyardPreferenceExperienceComponentProps,
  fullConfigBeta,
  consentsMock,
  subscriptionConfiguration,
  subscriptions,
  vendorConsentMock,
  showPreferenceOptionsMock,
} from '@ketch-com/lanyard'

import { Theme } from '@ketch-sdk/ketch-types'
import { ThemeDTO, ThemeFormDTO } from 'interfaces/themes-v2/theme'

const jsx = createElement

type Props = {
  wrapperClassName?: string
  theme: Theme | ThemeDTO | ThemeFormDTO
} & LanyardPreferenceExperienceComponentProps

export type PreferencePreviewProps = Props

export const PreferencePreview: React.FC<Props> = ({
  wrapperClassName,
  theme,
  rights,
  preference,
  formTemplates,
  canonicalRightFormTemplates = [],
  customRightFormTemplates = [],
  purposes = [],
  ...preferenceProps
}) => {
  const ref = useRef<HTMLDivElement>(null!)
  if (preferenceProps?.subscriptionsTab && fullConfigBeta?.experiences?.preference?.subscriptions)
    fullConfigBeta.experiences.preference.subscriptions = preferenceProps.subscriptionsTab

  useEffect(() => {
    const jsx = h
    const config = {
      ...fullConfigBeta,
      theme,
      ...(!!rights?.length && { rights }),
      ...(preference && {
        experiences: {
          preference,
        },
      }),
      ...(!!purposes?.length && { purposes }),
      ...(!!formTemplates?.length && { formTemplates }),
      ...(!!canonicalRightFormTemplates?.length && { canonicalRightFormTemplates }),
      ...(!!customRightFormTemplates?.length && { customRightFormTemplates }),
    }

    render(
      <LanyardProvider
        config={config}
        consent={consentsMock?.purposes}
        handleUpdateConsent={() => console.info('handleUpdateConsent')}
        setViewMode={() => console.info('setViewMode')}
        vendorConsent={vendorConsentMock}
        viewMode="experiences.consent.preference"
        showPreferenceOptions={showPreferenceOptionsMock}
        subscriptionConfiguration={subscriptionConfiguration}
        subscriptions={subscriptions}
      >
        <LanyardPreferenceExperience {...preferenceProps} title={preference?.title || ''} />
      </LanyardProvider>,
      ref.current,
    )
  })

  return <div className={wrapperClassName} ref={ref} />
}
