import React from 'react'
import Grid from '@mui/material/Grid'

import { SubscriptionsDTO } from 'interfaces/experiences/experience'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { InterpolateLinks } from 'pages/consentAndRights/experiences/components/InterpolateLinks'
import { Text } from 'components/ui-kit/typography/Text'
import { SubSectionTitle } from 'components/ui-kit/typography/compositions/SubSectionTitle'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'

type Props = {
  title?: string
  config?: SubscriptionsDTO
}

export const SubscriptionsDetails: React.FC<Props> = ({ title, config }) => {
  const { isEntitled } = useIsEntitled()
  const isEntitledToSubscriptions = isEntitled(ENTITLEMENTS.SUBSCRIPTIONS)

  if (!isEntitledToSubscriptions) {
    return null
  }

  const {
    tabName,
    bodyTitle,
    bodyDescription,
    primaryButtonText,
    secondaryButtonText,
    title: subscriptionListTitle,
    switchOffText,
    switchOnText,
  } = config || {}

  return (
    <>
      <FormRow>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <SubSectionTitle>Main Title</SubSectionTitle>
            {title ? <Text size={14}>{title}</Text> : <EmptyValueRenderer />}
          </Grid>

          <Grid item xs={3}>
            <SubSectionTitle>Tab Name</SubSectionTitle>
            {tabName ? <Text size={14}>{tabName}</Text> : <EmptyValueRenderer />}
          </Grid>

          <Grid item xs={3}>
            <SubSectionTitle>Body Title</SubSectionTitle>
            {bodyTitle ? <Text size={14}>{bodyTitle}</Text> : <EmptyValueRenderer />}
          </Grid>
        </Grid>
      </FormRow>

      <FormRow>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <SubSectionTitle>Body Description</SubSectionTitle>
            {bodyDescription ? (
              <Text size={14}>
                <InterpolateLinks linkColor="light-blue" text={bodyDescription} />
              </Text>
            ) : (
              <EmptyValueRenderer />
            )}
          </Grid>
        </Grid>
      </FormRow>

      <FormRow>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <SubSectionTitle>List of Subscription Title</SubSectionTitle>
            {subscriptionListTitle ? <Text size={14}>{subscriptionListTitle}</Text> : <EmptyValueRenderer />}
          </Grid>

          <Grid item xs={3}>
            <SubSectionTitle>Positive Switch </SubSectionTitle>
            {switchOnText ? <Text size={14}>{switchOnText}</Text> : <EmptyValueRenderer />}
          </Grid>

          <Grid item xs={3}>
            <SubSectionTitle>Negative Switch</SubSectionTitle>
            {switchOffText ? <Text size={14}>{switchOffText}</Text> : <EmptyValueRenderer />}
          </Grid>
        </Grid>
      </FormRow>

      <FormRow>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <SubSectionTitle>Cancel Button</SubSectionTitle>
            {secondaryButtonText ? <Text size={14}>{secondaryButtonText}</Text> : <EmptyValueRenderer />}
          </Grid>

          <Grid item xs={3}>
            <SubSectionTitle>Save Button</SubSectionTitle>
            {primaryButtonText ? <Text size={14}>{primaryButtonText}</Text> : <EmptyValueRenderer />}
          </Grid>
        </Grid>
      </FormRow>
    </>
  )
}
