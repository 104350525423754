import React from 'react'
import { FormFieldCategoryDTO, PreviewConfigurationExperienceFormFieldDTO } from '@ketch-com/figurehead'
import { countriesMinusEmbargoed } from 'utils/constants/countries'
import { ActionSheetItem, DropListButton, ListItemText, theme } from '@ketch-com/deck'

type Props = {
  field?: PreviewConfigurationExperienceFormFieldDTO
}

/**
 * DropdownField is a React component that wraps the generic Dropdown component.
 * Required to use the useState hook to temporarily persist the dropdown value.
 */
export const DropdownField: React.FC<Props> = ({ field }) => {
  const [dropdownValue, setDropdownValue] = React.useState('')

  const isDefaultCountryDropdown =
    field?.category === FormFieldCategoryDTO.DefaultFormFieldCategory && field.name === 'country'

  if (isDefaultCountryDropdown) {
    const currentValue = countriesMinusEmbargoed.find(country => country.id === dropdownValue)
    return (
      <DropListButton
        sx={{
          '& .MuiTextField-root': {
            '& .MuiAutocomplete-inputRoot': {
              borderRadius: 'unset',
              borderColor: `${theme.palette.doomedGrey.main}`,
              height: '44px',
              '&.MuiInputBase-root:not(.Mui-focused)': {
                backgroundColor: 'transparent',
              },
            },
          },
        }}
        fullWidth
        disableClearable={currentValue !== null}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => option.name}
        renderOption={(props, option, { selected }) => {
          return (
            <ActionSheetItem {...props} key={option.id} selected={selected}>
              <ListItemText selected={selected}>{option.name}</ListItemText>
            </ActionSheetItem>
          )
        }}
        options={countriesMinusEmbargoed}
        onChange={(_, value) => value && setDropdownValue(value.id)}
        value={currentValue}
        placeholder={field?.hint}
        size={'medium'}
      />
    )
  }

  const currentValue = field?.options?.find(option => option.value === dropdownValue)
  return (
    <DropListButton
      sx={{
        '& .MuiTextField-root': {
          '& .MuiAutocomplete-inputRoot': {
            borderRadius: 'unset',
            borderColor: `${theme.palette.doomedGrey.main}`,
            height: '44px',
            '&.MuiInputBase-root:not(.Mui-focused)': {
              backgroundColor: 'transparent',
            },
          },
        },
      }}
      fullWidth
      disableClearable={currentValue !== null}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={option => option.label || ''}
      renderOption={(props, option, { selected }) => {
        return (
          <ActionSheetItem {...props} key={option.value} selected={selected}>
            <ListItemText selected={selected}>{option.label}</ListItemText>
          </ActionSheetItem>
        )
      }}
      options={field?.options || []}
      onChange={(_, value) => value && setDropdownValue(value.value || '')}
      value={currentValue}
      placeholder={field?.hint}
      size={'medium'}
    />
  )
}
