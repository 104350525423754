import React from 'react'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'
import { CookieFormDTO } from 'interfaces/cookies/cookie'
import { CookieCategoryOptions } from 'interfaces/cookies/cookieCategory'
import { CookieProvenanceOptions } from 'interfaces/cookies/cookieProvenance'
import { CookieDurationsOptions } from 'interfaces/cookies/cookieDurations'
import { formatCodeFromName } from 'utils/formatters'
import { FormInput } from 'components/form/FormInput'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { FormRow } from '@ketch-com/deck'

type Props = {
  isKetchCookie: boolean
  isEditMode?: boolean
}

type CookiesBasicDetailsFormKeysType = {
  [key: string]: keyof CookieFormDTO
}

export const cookiesBasicDetailsFormKeys = {
  code: 'code',
  description: 'description',
  name: 'name',
  serviceProvider: 'serviceProvider',
  host: 'host',
  provenance: 'provenance',
  category: 'category',
  duration: 'duration',
} as CookiesBasicDetailsFormKeysType

export const CookieBasicDetailsFormSection: React.FC<Props> = ({ isEditMode, isKetchCookie }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext<CookieFormDTO>()

  return (
    <FormRow title="Basic Details">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormInput
            required
            fullWidth
            label="Name"
            id="name"
            formPropertyName={cookiesBasicDetailsFormKeys.name}
            placeholder="Example: Collect"
            onChange={e => {
              if (!isEditMode) {
                setFieldTouched(cookiesBasicDetailsFormKeys.code, true)
                setFieldValue(cookiesBasicDetailsFormKeys.code, formatCodeFromName(e.target.value))
              }
            }}
          />
        </Grid>

        <Grid item xs={6} />

        <Grid item xs={6}>
          <FormInput
            required
            fullWidth
            disabled={isEditMode || isKetchCookie}
            label="Code"
            formPropertyName={cookiesBasicDetailsFormKeys.code}
            placeholder="Example: Collect"
          />
        </Grid>
        <Grid item xs={6} />

        <Grid item xs={9}>
          <FormInput
            formPropertyName={cookiesBasicDetailsFormKeys.description}
            fullWidth
            label="Description"
            multiline
            placeholder="Example: Imagine there is a text depicting the nature of this cookie."
            rows={3}
          />
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={6}>
          <FormInput
            disabled={isKetchCookie}
            required
            fullWidth
            formPropertyName={cookiesBasicDetailsFormKeys.serviceProvider}
            label="Service Provider"
            placeholder="Example: Provider"
          />
        </Grid>

        <Grid item xs={6}>
          <FormInput
            required
            formPropertyName={cookiesBasicDetailsFormKeys.host}
            fullWidth
            label="Domain"
            placeholder="Example: ads/ga-audiences"
          />
        </Grid>

        <Grid item xs={4}>
          <FormDroplistButton
            fullWidth
            formPropertyName={cookiesBasicDetailsFormKeys.provenance}
            label="Provenance"
            items={CookieProvenanceOptions}
            valueKey="id"
          />
        </Grid>
        <Grid item xs={4}>
          <FormDroplistButton
            disabled={isKetchCookie}
            fullWidth
            formPropertyName={cookiesBasicDetailsFormKeys.category}
            label="Category"
            items={CookieCategoryOptions}
            valueKey="id"
          />
        </Grid>
        <Grid item xs={4}>
          <FormDroplistButton
            disabled={isKetchCookie}
            fullWidth
            formPropertyName={cookiesBasicDetailsFormKeys.duration}
            label="Duration"
            items={CookieDurationsOptions}
            valueKey="id"
          />
        </Grid>
      </Grid>
    </FormRow>
  )
}
