import React from 'react'
import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material'

type Props = {
  padding?: string | number
  size?: string | number | undefined
  sx?: SxProps<Theme>
}

export const Spinner: React.FC<Props> = ({ padding, size, sx }) => {
  return (
    <Box
      sx={{
        padding: padding ? padding : 0,
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
      }}
    >
      <CircularProgress size={size} sx={sx} />
    </Box>
  )
}
