import React from 'react'
import { Box, Typography, Link } from '@mui/material'
import Ketch from 'assets/icons/onboarding/ketch.png'
import { Button, Icon, IconDictionary } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { RoutesManager } from 'utils/routing/routesManager'
import { SignUpFormFields } from 'pages/onboarding/signUp/common/types/signUp'
import { useFormikContext } from 'formik'
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'

type SignUpFormProps = {
  isMobileLayout?: boolean
}

export const SignUpForm: React.FC<SignUpFormProps> = ({ isMobileLayout = false }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { setFieldTouched, errors, isSubmitting } = useFormikContext<SignUpFormFields>()

  return (
    <Box display="flex" justifyContent="center" px={2}>
      <Box flexBasis={420} display="flex" flexDirection="column" gap={6}>
        <Box width={194} height={66}>
          <img src={Ketch} alt="KetchLogo" style={{ width: '100%', height: 'auto' }} />
        </Box>

        <Box display="flex" flexDirection="column" gap={4}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="h1">Sign Up</Typography>
            <Typography variant="body">Creating an account is quick and easy.</Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={3}>
            <FormInput
              fullWidth
              required
              label="Full Name"
              formPropertyName="name"
              placeholder="Jenna Smith"
              debounceWaitTime={1000}
              disabled={isSubmitting}
            />
            <FormInput
              fullWidth
              required
              label="Work Email"
              formPropertyName="email"
              placeholder="name@company.com"
              debounceWaitTime={1000}
              disabled={isSubmitting}
            />

            {!isMobileLayout ? (
              <Box mt={1}>
                <Button
                  disabled={isSubmitting}
                  size="large"
                  fullWidth
                  color="primary"
                  onClick={() => {
                    setFieldTouched('name')
                    setFieldTouched('email')
                    let route = RoutesManager.onboarding.signUp.complete.getURL()
                    if (location.search) route += location.search
                    if (!errors.name && !errors.email) navigate(route)
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="label">Continue</Typography>
                    <Icon name={IconDictionary.OArrowRight} width={20} height={20} sx={{ marginTop: 0.25 }} />
                  </Box>
                </Button>
              </Box>
            ) : null}
          </Box>

          {!isMobileLayout ? (
            <Box display="flex" flexDirection="column">
              <Typography variant="label">Already have an account?</Typography>
              <Box>
                <Link component={RouterLink} to="/">
                  <Typography variant="labelLink" color="sphere.main">
                    Sign In
                  </Typography>
                </Link>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}
