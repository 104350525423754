import { ICanvasStep } from '../../interfaces'
import { isUndefined } from 'lodash'
import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { CopyPasteType } from '../constants'
import { getNextJoinTile } from './getNextJoinTile'

/**
 * Determine the type of paste based on the first step to be pasted
 */
export const getCopyPasteType = (stepsToPaste: ICanvasStep[]) => {
  const testStep = stepsToPaste[0]
  let copyPasteType = CopyPasteType.path
  if (stepsToPaste.length === 1) {
    copyPasteType = CopyPasteType.single
  } else if (!isUndefined(testStep?.gateway)) {
    const nextJoin = getNextJoinTile({ step: testStep, steps: stepsToPaste })
    copyPasteType =
      testStep.gateway.mode === WorkflowActivityGatewayMode.DECISION_BOOLEAN ||
      (testStep.gateway.mode === WorkflowActivityGatewayMode.DECISION_SINGLE && !nextJoin) // Note: We treat a single gateway with join as decision multi for pasting
        ? CopyPasteType.booleanOrSingleGateway
        : CopyPasteType.splitOrMultiGateway
  }
  return copyPasteType
}
