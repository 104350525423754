import React, { createContext } from 'react'
import {
  UseEditWorkflowFormContainerUtils,
  useEditWorkflowFormContainerUtils,
} from '../hooks/useEditWorkflowFormContainerUtils'

export const EditWorkflowContainerContext = createContext({} as UseEditWorkflowFormContainerUtils)

type Props = {
  children?: React.ReactNode
}

export const EditWorkflowContainerContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useEditWorkflowFormContainerUtils()

  return (
    <EditWorkflowContainerContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </EditWorkflowContainerContext.Provider>
  )
}
