import React from 'react'
import Box from '@mui/material/Box'

import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { FinalizeRequestAsOptions, finalizeRequestAsOptions } from '../utils'
import { IEndFlowActivityParams } from 'pages/orchestration/workflows/edit/interfaces'
import { v4 as uuidv4 } from 'uuid'
import { AccessControlType } from 'components/ui-kit/form/imageUpload/hooks'
import { useFormikContext } from 'formik'
import { WorkflowDTO } from 'interfaces/workflows'
import { MAX_FILE_UPLOAD_SIZE_4_MB } from 'utils/constants/maxFileUploadSize'
import { isTwilioConversationsEnabled } from 'utils/helpers/isTwilioConversationsEnabled'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { FormCheckbox } from 'components/form/FormCheckbox'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { Button } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { FormDropZoneWithUpload } from 'components/form/FormDropZone/FormDropZoneWithUpload'

type Props = {
  appealWorkflows: WorkflowDTO[]
}

export const AutomatedForm: React.FC<Props> = ({ appealWorkflows }) => {
  const { values, setFieldValue } = useFormikContext<IEndFlowActivityParams>()
  const navigate = useNavigate()
  const subjectCommunicationEnabled = isTwilioConversationsEnabled()

  return (
    <>
      {/* Completed or REjected radio input */}

      <Box display="flex" alignItems="center" mb={3}>
        <FormRadioGroup
          hideOptionalLabel
          row
          label="Finalize Request As"
          formPropertyName="finalizeRequestAs"
          renderLabel={({ name }) => name}
          options={finalizeRequestAsOptions}
          valueKey="id"
          onChange={e => {
            const newValue = e.target.value
            if (newValue === FinalizeRequestAsOptions.Rejected) {
            }

            if (newValue === FinalizeRequestAsOptions.Completed) {
              setFieldValue('allowAppeal', false)
              setFieldValue('appealWorkflowDefinitionCode', '')
            }
          }}
        />
      </Box>

      {values.finalizeRequestAs === 'rejected' && (
        <FormCheckbox
          name="allowAppeal"
          label="Allow Appeal"
          inlineLabel
          sx={{ mb: 2, mt: -1 }}
          onChange={(e, checked) => {
            setFieldValue('allowAppeal', checked)
            if (!checked) setFieldValue('appealWorkflowDefinitionCode', '')
          }}
        />
      )}

      {/* If allow appeal is checked, display Appeal Workflow dropdown */}

      {values.finalizeRequestAs === 'rejected' && values.allowAppeal && (
        <>
          <Box mb={3} maxWidth={374}>
            <FormDroplistButton
              fullWidth
              hideOptionalLabel
              disableClearable
              formPropertyName="appealWorkflowDefinitionCode"
              label="Appeal Workflow"
              placeholder="Select workflow"
              valueKey="code"
              labelTooltipText="Same-type workflows only. Nested appeal workflows won't run if included in selected workflow."
              items={appealWorkflows}
            />
          </Box>

          <Typography variant="body" display="block" component="div">
            Data Subjects in certain Jurisdictions can appeal rejected requests. Configure appeal time settings in
            Privacy Policy <span>&rarr;</span>{' '}
            <Button
              variant="link"
              color="secondary"
              onClick={() => navigate(RoutesManager.policyCenter.policyScopes.list.root.getURL())}
            >
              <Typography variant="label">Jurisdictions</Typography>
            </Button>
            .
          </Typography>
        </>
      )}

      {/* Subject Communication Section */}

      <Box mt={3}>
        <Typography variant="h4" color="darkDuskFaded.main">
          Subject Communication
        </Typography>

        {subjectCommunicationEnabled ? (
          <>
            <Box mt={2} mb={2}>
              <FormCheckbox name="isMessageRequired" label="Send message via Subject Communication" inlineLabel />
            </Box>

            {values.isMessageRequired && (
              <Box mb={2}>
                <FormInput
                  formPropertyName="subjectCommunicationMessage"
                  fullWidth
                  required
                  rows={7}
                  shouldUpdateDebounced
                  multiline
                />
              </Box>
            )}
          </>
        ) : null}

        <Box mb={1} mt={subjectCommunicationEnabled ? undefined : 2}>
          <FormCheckbox name="isCoverLetterRequired" label="Send Cover Letter" inlineLabel />
        </Box>

        {values.isCoverLetterRequired && (
          <Box mb={2}>
            <FormDropZoneWithUpload
              disableDownload
              accept={{
                'application/pdf': ['.pdf'],
              }}
              name="coverLetterFile"
              maxFiles={1}
              maxSize={MAX_FILE_UPLOAD_SIZE_4_MB}
              onDelete={file => {
                setFieldValue('coverLetterFile', values?.coverLetterFile?.filter(f => f !== file))
              }}
              subtitle=""
              uploadContext={{
                acl: `${AccessControlType.signed}`,
                bucket: '',
                folder: uuidv4(),
                version: 'cover_letter',
              }}
              validateOnTouch={false}
            />
          </Box>
        )}
      </Box>
    </>
  )
}
