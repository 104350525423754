import { Button } from '@ketch-com/deck'
import { Box } from '@mui/material'

export const ActionButtonsBar = ({
  handleClose,
  handleApply,
  handleClear = () => {},
  showClearButton = false,
}: {
  handleClear?: () => void
  handleClose: () => void
  handleApply: () => void
  showClearButton?: boolean
}) => {
  return (
    <Box
      sx={{
        marginTop: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {showClearButton && (
        <Button size="medium" color="tertiary" onClick={handleClear}>
          Clear
        </Button>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Button size="medium" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          size="medium"
          color="primary"
          onMouseDown={event => {
            event.preventDefault()
          }}
          onClick={handleApply}
        >
          Apply
        </Button>
      </Box>
    </Box>
  )
}
