import React, { createContext } from 'react'
import { ProcessingActivitiesUtils, useProcessingActivitiesUtils } from '../utils/useProcessingActivitiesUtils'

export const ProcessingActivitiesContext = createContext({} as ProcessingActivitiesUtils)

type Props = {
  children?: React.ReactNode | undefined
}

export const ProcessingActivitiesContextProvider: React.FC<Props> = ({ children }) => {
  // Use utils hook
  const hookValues = useProcessingActivitiesUtils()

  return (
    <ProcessingActivitiesContext.Provider value={{ ...hookValues }}>{children}</ProcessingActivitiesContext.Provider>
  )
}

export const withProcessingActivitiesContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <ProcessingActivitiesContextProvider>
        <Component {...props} />
      </ProcessingActivitiesContextProvider>
    )
  }
}
