import React, { useContext } from 'react'
import { UpsertInlineLayout } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayout'
import { ThemeUpsertContext, withThemeUpsertContext } from './context/ThemeUpsertContext'
import { UpsertThemeInlineLayoutHeader } from './components/UpsertThemeInlineLayoutHeader'
import { Builder } from '../../experiences-v2/upsert/components/builder/Builder'
import {
  BuilderMode,
  BuilderTarget,
  BuilderType,
  ExperienceBuilderSection,
  ThemeBuilderSection,
} from '../../experiences-v2/upsert/components/builder/utils/enums'
import { UpsertInlineLayoutPreview } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutPreview'
import { ThemeType } from './utils/enums'
import { RoutesManager } from '../../../../utils/routing/routesManager'
import { UpsertThemeSidebar } from './components/upsertThemeSidebar/UpsertThemeSidebar'
import { themeUpsertFormKeys } from './utils/useThemeValidationSchema'
import { getIn } from 'formik'
import { getDefaultExperienceConfigForThemeUpsert, getRgba } from './utils/utils'
import { UpsertExperienceExampleDataBanner } from 'pages/consentAndRights/experiences-v2/upsert/components/UpsertExperienceExampleDataBanner'
import { ThemeFormErrorSectionSwitcher } from './components/ThemeFormErrorSectionSwitcher'
import { Box, styled } from '@mui/material'
import { defaultExperienceV2DTO } from 'pages/consentAndRights/experiences-v2/upsert/utils/defaults'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'

const UpsertThemeBuilderBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  padding: '12px',
})

export const ThemeUpsertWithoutContext: React.FC = () => {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    themeType,
    themeBuilderSection,
    setThemeBuilderSection,
    isEditMode,
    isThemeLoading,
    preferencePage,
  } = useContext(ThemeUpsertContext)

  // Determine type of builder
  const builderType =
    themeType === ThemeType.Preference
      ? BuilderType.Preference
      : themeType === ThemeType.Banner
      ? BuilderType.Banner
      : BuilderType.Modal

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Themes', link: RoutesManager.deployment.themesV3.root.getURL() },
    { title: isEditMode ? 'Edit Theme' : 'Create New Theme' },
  ]

  return (
    <UpsertInlineLayout
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      breadcrumbs={breadcrumbs}
      marginBottom={'-24px'}
      isReady={!isEditMode || !isThemeLoading}
      fullWidth
      removePadding
      validateOnMount={false}
      validateOnChange={false}
    >
      {({ values, errors }) => {
        logObjectDetails(errors, 'ThemeUpsert.tsx')
        const showBackdrop =
          themeType === ThemeType.Preference
            ? false
            : getIn(
                values,
                themeType === ThemeType.Banner
                  ? themeUpsertFormKeys.bannerContainerBackdropVisible
                  : themeUpsertFormKeys.modalContainerBackdropVisible,
              )
        const background = getIn(
          values,
          themeType === ThemeType.Banner
            ? themeUpsertFormKeys.bannerContainerBackdropBackgroundColor
            : themeUpsertFormKeys.modalContainerBackdropBackgroundColor,
        )
        const opacity = getIn(
          values,
          themeType === ThemeType.Banner
            ? themeUpsertFormKeys.bannerContainerBackdropBackgroundOpacity
            : themeUpsertFormKeys.modalContainerBackdropBackgroundOpacity,
        )

        return (
          <>
            <ThemeFormErrorSectionSwitcher />
            <UpsertThemeInlineLayoutHeader />
            <UpsertInlineLayoutPreview
              sx={showBackdrop ? { background: getRgba(background, opacity) } : {}}
              onClick={() => {
                setThemeBuilderSection(undefined)
              }}
            >
              <UpsertThemeBuilderBox>
                <UpsertExperienceExampleDataBanner preferencePage={preferencePage} builderType={builderType} />
                <Builder
                  builderTarget={BuilderTarget.Theme}
                  builderType={builderType}
                  builderMode={BuilderMode.Edit}
                  themeConfig={values}
                  experienceConfig={getDefaultExperienceConfigForThemeUpsert(defaultExperienceV2DTO)}
                  activeSection={themeBuilderSection}
                  setActiveSection={(section: ExperienceBuilderSection | ThemeBuilderSection) => {
                    setThemeBuilderSection(section as ThemeBuilderSection)
                  }}
                  preferencePage={preferencePage}
                />
              </UpsertThemeBuilderBox>
              {themeBuilderSection && <UpsertThemeSidebar />}
            </UpsertInlineLayoutPreview>
          </>
        )
      }}
    </UpsertInlineLayout>
  )
}

export const ThemeV3Upsert = withThemeUpsertContext(ThemeUpsertWithoutContext)
