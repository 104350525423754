import { MaybeNull, MetaData } from 'interfaces/common'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { CookieProvenance } from 'interfaces/cookies/cookieProvenance'
import { CookieCategory } from 'interfaces/cookies/cookieCategory'
import { CookieDurations } from 'interfaces/cookies/cookieDurations'

export const COOKIE_KETCH_LIBRARY_KEY = 'ketch-library'

export interface CookieDTO {
  ID: string
  name: string
  code: string
  host: string
  status?: number
  used: boolean
  appCode: string
  propertyCookies: {
    cookieID: string
    propertyCode: string
    lastScanned?: number
    disclose?: boolean
  }[]
  duration: CookieDurations
  serviceProvider: string
  provenance: CookieProvenance
  category: CookieCategory
  description?: string
  hasIssues?: boolean
  latest?: boolean
  version?: number
  purposes?: PurposeDTO[]
  metadata?: MetaData
}

export interface CookieFormDTO {
  ID?: string
  name: string
  code: string
  host: string
  appCode: string
  duration: MaybeNull<CookieDurations>
  serviceProvider: string
  provenance: MaybeNull<CookieProvenance>
  category: MaybeNull<CookieCategory>
  description?: string
  propertyCookies?: {
    cookieID: string
    propertyCode: string
    lastScanned?: number
    disclose?: boolean
  }[]
  purposes?: PurposeDTO[]
}

export type CookiePage = { cookies: CookieDTO[]; totalResults: number; cursor?: string }

export type InfiniteCookieResult = { pageParams: number[] | undefined[]; pages: CookiePage[] }
