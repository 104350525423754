import { FieldArray, useFormikContext } from 'formik'

import { Box } from '@mui/material'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { TranslationTile } from './TranslationTile'

// types
import { StackDTO } from '@ketch-com/figurehead'
type Props = {
  languages: LanguageWithStatusDTO[]
  onSubmit: () => void
  display?: 'view' | 'edit'
}

export const TranslationsForm: React.FC<Props> = ({ languages, onSubmit, display = 'edit' }) => {
  const {
    values: { groups },
  } = useFormikContext<StackDTO>()

  return (
    <FormSection
      title="Group Name Translations"
      subTitle=""
      hasHeaderBorder={display === 'view'}
      shouldRemoveLeftPadding={display === 'view'}
    >
      <FieldArray
        name="groups"
        render={helpers => (
          <Box>
            {groups?.map((group, index) => (
              <TranslationTile
                group={group}
                helpers={helpers}
                index={index}
                key={group?.name}
                languages={languages}
                onSubmit={onSubmit}
                display={display}
              />
            ))}
          </Box>
        )}
      />
    </FormSection>
  )
}
