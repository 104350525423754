import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/modals/AlertComponent'
import { useCreateVerifier } from 'api/verifiers/mutations/useCreateVerifier'
import { useUpdateVerifier } from 'api/verifiers/mutations/useUpdateVerifier'
import { VerifierDTO } from '@ketch-com/figurehead'
import { Formik } from 'formik'
import { Box, Tooltip, Typography } from '@mui/material'
import { useVerifiersFormInitialValues, useVerifiersFormValidation } from 'pages/developers/verifiers/hooks'
import { VERIFIER_TYPE } from 'pages/developers/verifiers/constants'
import { Button, Chip, Icon, PopUp, theme } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type Props = {
  verifier?: VerifierDTO
  onSubmit: (value?: string) => void
  onCancel: () => void
}

const verifierTypeOptions = [
  { id: '1', verifierTypeName: VERIFIER_TYPE.SHARED_SECRET },
  { id: '2', verifierTypeName: VERIFIER_TYPE.PUBLIC_KEY },
  { id: '3', verifierTypeName: VERIFIER_TYPE.JWKS_URL },
]

export const VerifierUpsertModal: React.FC<Props> = ({ onSubmit, onCancel, verifier = {} }) => {
  const queryClient = useQueryClient()
  const isEditMode = !!Object.keys(verifier).length
  const initialValues = useVerifiersFormInitialValues({ verifier, isEditMode })
  const validationSchema = useVerifiersFormValidation()
  const { mutate: handleCreateVerifier } = useCreateVerifier({
    onSuccess: async (_, verifiers) => {
      showToast({ content: 'Verifier created', type: 'success' })
      await queryClient.refetchQueries(ApiQueryKeys.verifiersPaginated)
      onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to create verifier', type: 'error' })
    },
  })

  const { mutate: handleUpdateVerifier } = useUpdateVerifier({
    onSuccess: async (_, verifiers) => {
      showToast({ content: 'Verifier updated', type: 'success' })
      await queryClient.refetchQueries(ApiQueryKeys.verifiersPaginated)
      await onSubmit?.(verifiers.verifierId)
    },
    onError: () => {
      showToast({ content: 'Failed to update verifier', type: 'error' })
    },
  })

  let verifierType: string | null = null
  if (verifier.type === 1) {
    verifierType = VERIFIER_TYPE.SHARED_SECRET
  } else if (verifier.type === 2) {
    verifierType = VERIFIER_TYPE.PUBLIC_KEY
  } else if (verifier.type === 3) {
    verifierType = VERIFIER_TYPE.JWKS_URL
  }

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={formValues => {
        isEditMode
          ? handleUpdateVerifier({
              params: {
                formData: {
                  verifier: { ...formValues },
                },
              },
            })
          : handleCreateVerifier({
              params: {
                formData: {
                  verifier: { ...formValues },
                },
              },
            })
      }}
    >
      {({ isValid, values, isSubmitting, handleSubmit, setFieldValue, setFieldTouched }) => {
        const shouldShowSharedSecret = !isEditMode || values.type === 1
        const shouldShowPublicKey = !isEditMode || values.type === 2
        const shouldShowJwks = !isEditMode || values.type === 3

        return (
          <PopUp
            variant="modal"
            title={isEditMode ? 'Edit Token Verifier' : 'Create Token Verifier'}
            popUpWidth="774px"
            popUpActionChildren={
              <>
                <Button color="secondary" size="large" pending={isSubmitting} onClick={onCancel} disabled={!isValid}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  size="large"
                  pending={isSubmitting}
                  onClick={() => handleSubmit()}
                  disabled={!isValid}
                >
                  {isEditMode ? 'Save' : 'Create'}
                </Button>
              </>
            }
            onClose={onCancel}
          >
            <Box display="flex" flexDirection="column" gap={4} mb={4}>
              <Box>
                <FormInput
                  autoFocus
                  required
                  fullWidth
                  formPropertyName="name"
                  label="Name"
                  placeholder="Token Verifier Name"
                  shouldUpdateDebounced
                />
              </Box>
              <Box>
                <Box>
                  <Box>
                    <Box display="flex" alignItems="top" gap={1}>
                      <Typography variant="label">Type</Typography>
                      {isEditMode ? (
                        <Tooltip title="The type is permanent after creation.">
                          <Box display="flex" alignItems="center">
                            <Icon name="FUnknown" iconColor={theme.palette.darkDuskFaded.main} width={16} height={16} />
                          </Box>
                        </Tooltip>
                      ) : null}
                    </Box>
                    {isEditMode && (
                      <Box mt={0.5}>{verifierType ? <Chip label={verifierType} /> : <EmptyValueRenderer />}</Box>
                    )}
                  </Box>
                </Box>
                {shouldShowSharedSecret ? (
                  <Box mt={3}>
                    {!isEditMode ? (
                      <FormRadioGroup
                        sx={{ ml: 1.5 }}
                        isInt
                        formPropertyName="type"
                        row
                        hideOptionalLabel
                        valueKey="id"
                        renderLabel={({ verifierTypeName }) => verifierTypeName}
                        options={[verifierTypeOptions[0]]}
                        onChange={() => {
                          setFieldValue('publicKey', '')
                          setFieldValue('jwksUrl', '')
                          setFieldTouched('publicKey', false)
                          setFieldTouched('jwksUrl', false)
                        }}
                      />
                    ) : null}

                    {values.type === 1 && (
                      <Box ml={!isEditMode ? 4.5 : 0}>
                        <FormInput
                          autoFocus
                          required
                          fullWidth
                          formPropertyName="sharedSecret"
                          label={isEditMode ? VERIFIER_TYPE.SHARED_SECRET : ''}
                          shouldUpdateDebounced
                        />
                      </Box>
                    )}
                  </Box>
                ) : null}
                {shouldShowPublicKey ? (
                  <Box mt={3}>
                    {!isEditMode ? (
                      <FormRadioGroup
                        sx={{ ml: 1.5 }}
                        row
                        hideOptionalLabel
                        isInt
                        formPropertyName="type"
                        valueKey="id"
                        renderLabel={({ verifierTypeName }) => verifierTypeName}
                        options={[verifierTypeOptions[1]]}
                        onChange={() => {
                          setFieldValue('sharedSecret', '')
                          setFieldValue('jwksUrl', '')
                          setFieldTouched('sharedSecret', false)
                          setFieldTouched('jwksUrl', false)
                        }}
                      />
                    ) : null}

                    {values.type === 2 && (
                      <Box ml={!isEditMode ? 4.5 : 0}>
                        <FormInput
                          autoFocus
                          required
                          fullWidth
                          formPropertyName="publicKey"
                          label={isEditMode ? VERIFIER_TYPE.PUBLIC_KEY : ''}
                          shouldUpdateDebounced
                        />
                      </Box>
                    )}
                  </Box>
                ) : null}
                {shouldShowJwks ? (
                  <Box mt={3}>
                    {!isEditMode ? (
                      <FormRadioGroup
                        sx={{ ml: 1.5 }}
                        hideOptionalLabel
                        isInt
                        formPropertyName="type"
                        row
                        valueKey="id"
                        renderLabel={({ verifierTypeName }) => verifierTypeName}
                        options={[verifierTypeOptions[2]]}
                        onChange={() => {
                          setFieldValue('publicKey', '')
                          setFieldValue('sharedSecret', '')
                          setFieldTouched('publicKey', false)
                          setFieldTouched('sharedSecret', false)
                        }}
                      />
                    ) : null}

                    {values.type === 3 && (
                      <Box ml={!isEditMode ? 4.5 : 0}>
                        <FormInput
                          autoFocus
                          required
                          fullWidth
                          formPropertyName="jwksUrl"
                          label={isEditMode ? VERIFIER_TYPE.JWKS_URL : ''}
                          shouldUpdateDebounced
                        />
                      </Box>
                    )}
                  </Box>
                ) : null}
              </Box>

              {!isEditMode ? (
                <Box display="flex" alignItems="center" gap={1}>
                  <Icon name="FImportant" width={18} height={18} iconColor={theme.palette.chileanFire.main} />
                  <Typography variant="smallBody" color="chileanFire.main">
                    Once you create a Verifier, changing the type won't be possible.
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </PopUp>
        )
      }}
    </Formik>
  )
}
