import React from 'react'
import { ViewListLayout } from 'components/ui-layouts/viewListLayout/ViewListLayout'
import { ViewListLayoutHeader } from 'components/ui-layouts/viewListLayout/ViewListLayoutHeader'

import { ViewListLayoutContent } from 'components/ui-layouts/viewListLayout/ViewListLayoutContent'
import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { Pagination } from 'api/common/paginatedQuery'
import { PoliciesTablePaginated } from 'pages/assetManager/components/PoliciesTablePaginated'
import { PoliciesListActions } from './components'

type Props = {
  isLoading: boolean
  policies: AssetPolicyDTO[]
  pagination: Pagination
}

export const PoliciesList: React.FC<Props> = ({ isLoading, policies, pagination }) => (
  <ViewListLayout isReady={!isLoading}>
    <ViewListLayoutHeader title="Policies" actions={<PoliciesListActions />} />
    <ViewListLayoutContent>
      <PoliciesTablePaginated
        pagination={pagination}
        policies={policies}
        shouldShowSearchInput={false}
        isExpandable={false}
      />
    </ViewListLayoutContent>
  </ViewListLayout>
)
