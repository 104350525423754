import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces/common'
import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { ThemePreviewViewType, ThemePreviewViewTypeOptions } from 'interfaces/themes-v2/themePreview'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

import { RoutesManager } from 'utils/routing/routesManager'
import { formatCodeFromName } from 'utils/formatters'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import {
  getUpsertThemeInitialValues,
  useValidationSchema,
  ThemeFormValues,
} from 'pages/consentAndRights/themes/upsert/utils'
import { UpsertInlineLayout } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayout'
import { UpsertInlineLayoutHeader } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutHeader'
import { UpsertInlineLayoutToolbar } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutToolbar'
import { UpsertInlineLayoutPreview } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutPreview'
import { UpsertInlineLayoutPreviewContent } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutPreviewContent'
import { UpsertInlineLayoutPreviewSidebar } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutPreviewSidebar'
import { Group } from 'components/ui-layouts/group/Group'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { FormInlineEdit } from 'components/ui-kit/form/inlineEdit/FormInlineEdit'
import { Button } from '@ketch-com/deck'
import { ThemeUpsertPreviewContent } from 'pages/consentAndRights/themes/upsert/components/ThemeUpsertPreviewContent'
import { ThemeUpsertSidebar } from 'pages/consentAndRights/themes/upsert/components/ThemeUpsertSidebar'

type Props = {
  isReady: boolean
  isEditMode: boolean
  theme: MaybeNull<ThemeDTO>
  onSubmit: (values: ThemeFormValues) => Promise<void>
}

export const ThemesUpsert: React.FC<Props> = ({ isReady, isEditMode, theme, onSubmit }) => {
  const navigate = useNavigate()
  const [view, setView] = useState(ThemePreviewViewType.BANNER)
  const { isEntitled } = useIsEntitled()

  const name = theme?.name
  const code = theme?.code

  const breadcrumbs = useMemo(
    () =>
      isEditMode
        ? [
            { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
            { title: 'Themes', link: RoutesManager.deployment.themes.root.getURL() },
            {
              title: name || code,
              link: RoutesManager.deployment.themes.view.root.getURL({ code }),
            },
            { title: 'Edit' },
          ]
        : [
            { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
            { title: 'Themes', link: RoutesManager.deployment.themes.root.getURL() },
            { title: 'Create' },
          ],
    [isEditMode, name, code],
  )

  const isEntitledToUnbranded = isEntitled(ENTITLEMENTS.EXP_SERVER_UNBRANDED)
  const initialValues = useMemo(
    () => getUpsertThemeInitialValues({ theme, isEntitledToUnbranded }),
    [theme, isEntitledToUnbranded],
  )
  const validationSchema = useValidationSchema({ isEditMode })

  return (
    <UpsertInlineLayout
      isReady={isReady}
      breadcrumbs={breadcrumbs}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, submitForm, setFieldValue, setFieldTouched }) => {
        return (
          <>
            <UpsertInlineLayoutHeader>
              <Flex flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                <Flex flexDirection="column" justifyContent="center" alignItems="flex-start">
                  <FormInlineEdit
                    placeholder="Theme Name"
                    size="x-large"
                    name="name"
                    onChange={e => {
                      if (!isEditMode) {
                        setFieldTouched('code', true)
                        setFieldValue('code', formatCodeFromName(e.target.value))
                      }
                    }}
                  />

                  <FormInlineEdit disabled={isEditMode} placeholder="Theme Code" name="code" />
                </Flex>

                <Group>
                  <Button
                    pending={isSubmitting}
                    color="secondary"
                    size="large"
                    onClick={() => {
                      if (isEditMode) {
                        navigate(RoutesManager.deployment.themes.view.root.getURL({ code }))
                      } else {
                        navigate(RoutesManager.deployment.themes.root.getURL())
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <Button pending={isSubmitting} disabled={!isValid} size="large" onClick={submitForm}>
                    Save
                  </Button>
                </Group>
              </Flex>
            </UpsertInlineLayoutHeader>

            <UpsertInlineLayoutToolbar>
              <Flex justifyContent="flex-start" alignItems="flex-end">
                <Group gap={12}>
                  <SegmentTabs tabs={ThemePreviewViewTypeOptions} value={view} onChange={setView} />
                </Group>
              </Flex>
            </UpsertInlineLayoutToolbar>

            <UpsertInlineLayoutPreview>
              <UpsertInlineLayoutPreviewContent>
                <ThemeUpsertPreviewContent view={view} />
              </UpsertInlineLayoutPreviewContent>

              <UpsertInlineLayoutPreviewSidebar>
                <ThemeUpsertSidebar view={view} />
              </UpsertInlineLayoutPreviewSidebar>
            </UpsertInlineLayoutPreview>
          </>
        )
      }}
    </UpsertInlineLayout>
  )
}
