import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import { UpdateResourceLabelsDTO } from 'interfaces/labels/UpdateResourceLabelsDTO'

type Args = {
  labels: ManageLabelModalLabel[]
}

export const mapValuesToPayload = ({ labels }: Args): UpdateResourceLabelsDTO => ({
  labels: labels.map(l => ({
    code: l?.code,
    value: l?.value,
    isSystemLabel: l?.isSystemLabel || false,
    name: l?.name || '',
  })),
})
