import * as Yup from 'yup'
import { RoutesManager } from 'utils/routing/routesManager'

export const getUpsertBreadCrumbs = (isEditMode: boolean, customRightName?: string) => {
  const breadcrumbs = isEditMode
    ? [
        { title: 'Settings', link: RoutesManager.settings.root.getURL() },
        { title: 'Custom Right Name', link: RoutesManager.settings.customRightName.root.getURL() },
        { title: customRightName },
        { title: 'Edit' },
      ]
    : [
        { title: 'Settings', link: RoutesManager.settings.root.getURL() },
        { title: 'Custom Right Name', link: RoutesManager.settings.customRightName.root.getURL() },
        { title: 'Create Custom Right Name' },
      ]
  return breadcrumbs
}

export const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
})
