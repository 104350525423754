import React from 'react'
import { useFormikContext } from 'formik'
import { Box, Tooltip, Typography } from '@mui/material'
import { SubscriptionTopicFormData } from 'pages/policyCenter/subscriptions/interfaces'
import { legalBasisItems } from 'pages/policyCenter/subscriptions/constants'
import { FormOptionActionSheet } from 'components/form/FormOptionActionSheet'
import { FormRow, Icon, theme } from '@ketch-com/deck'

export const LegalBasis: React.FC = () => {
  const { isSubmitting } = useFormikContext<SubscriptionTopicFormData>()

  return (
    <FormRow title="Legal Basis" subTitle="Select default state of subscription." hasTopBorder>
      <Box display="flex" alignItems="flex-start" gap={1}>
        <Box>
          <FormOptionActionSheet
            buttonProps={{
              size: 'large',
              color: 'tertiary',
              disabled: isSubmitting,
            }}
            formPropertyName="legalBasis.code"
            items={legalBasisItems}
            required={true}
            valueKey="code"
            placeholder="Select"
            renderItem={item => item.name}
          />
        </Box>
        <Tooltip
          title={
            <Box display="flex" flexDirection="column" px={3}>
              <ul style={{ padding: 0 }}>
                <li>
                  <Typography variant="smallBody">
                    <strong>Opt Out</strong> - Subscribe by default
                  </Typography>
                </li>
                <li>
                  <Typography variant="smallBody">
                    <strong>Opt In</strong> - User must subscribe
                  </Typography>
                </li>
                <li>
                  <Typography variant="smallBody">
                    <strong>Disclosure</strong> - User cannot opt out
                  </Typography>
                </li>
              </ul>
            </Box>
          }
        >
          <Box display="flex" alignItems="center" height={44}>
            <Icon name="FUnknown" iconColor={theme.palette.darkDuskFaded.main} width={20} height={20} />
          </Box>
        </Tooltip>
      </Box>
    </FormRow>
  )
}
