import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/modals/AlertComponent'
import { useAppInstancesUsages } from 'api/apps/queries/useAppInstancesUsages'
import { useDeleteInstalledDataSystem } from 'api/dataSystems/mutations/useDeleteInstalledDataSystem'
import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'
import { Button, DataGrid, PopUp, Spinner, TableCell } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { WorkflowDTO } from 'interfaces/workflows'
import { useInstalledDataSystem } from 'api/dataSystems/queries/useInstalledDataSystem'

type Props = {
  appId: string
  onCancel: () => void
  onSubmit?: () => void
}

export const DeleteAppModal: React.FC<Props> = ({ appId, onCancel, onSubmit }) => {
  const queryClient = useQueryClient()

  const navigate = useNavigate()

  const [isBusy, setIsBusy] = useState(false)

  const { data: app } = useInstalledDataSystem({ params: { id: appId } })

  const { workflows, isLoading: isLoadingWorkflows } = useAppInstancesUsages(
    app?.connections?.length ? app.connections.map(i => i.appInstanceId!) : [],
  )

  const { mutate: handleDeleteInstalledDataSystem } = useDeleteInstalledDataSystem({
    onMutate: () => {},
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.installedDataSystems, ApiQueryKeys.installedDataSystemsStats])
      await queryClient.refetchQueries()
      setIsBusy(false)
      showToast({ content: 'Data system deleted', type: 'success' })
      if (appId) {
        navigate(RoutesManager.systems.systemsHome.root.getURL())
      }
    },
    onError: () => {
      showToast({ content: 'Failed to delete data system', type: 'error' })
      setIsBusy(false)
    },
  })

  const hasUsages = workflows.length

  return (
    <PopUp
      title="Confirm Delete"
      variant="dialog"
      popUpWidth="550px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isBusy} onClick={onCancel}>
            Close
          </Button>
          <Button
            color="primary"
            size="large"
            disabled={!!hasUsages}
            pending={isBusy}
            onClick={() => {
              setIsBusy(true)
              handleDeleteInstalledDataSystem({
                params: {
                  id: appId,
                },
              })
            }}
          >
            Delete
          </Button>
        </>
      }
    >
      {isLoadingWorkflows ? (
        <Box display="flex" alignItems="center" justifyContent="center" m={3}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : hasUsages ? (
        <>
          <Typography>
            This data system has live connections and cannot be deleted until it is no longer being used within the
            following contexts.
          </Typography>

          <DataGrid
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              includeOutliers: false,
              columns: columns.map(column => column.field),
              expand: true,
            }}
            getRowHeight={() => 'auto'}
            columns={columns}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowHoverStates
            disableRowSelectionOnClick
            getRowId={row => row.code}
            rows={workflows}
            hideFooter
            noRowsOverlayProps={{
              buttonTitle: '',
            }}
          />
        </>
      ) : (
        <Typography>
          Once you delete the system, all associated functionalities will be unavailable. Are you sure you want to
          continue?
        </Typography>
      )}
    </PopUp>
  )
}

const columns: GridColDef<WorkflowDTO>[] = [
  {
    align: 'left',
    field: 'productArea',
    headerAlign: 'left',
    headerName: 'Product Area',
    sortable: false,
    flex: 1,

    renderCell: ({ row }: GridRenderCellParams<WorkflowDTO>) => {
      return <TableCell title="Workflow"></TableCell>
    },
  },
  {
    align: 'left',
    field: 'itemName',
    headerAlign: 'left',
    headerName: 'Used By',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { name, code } }: GridRenderCellParams<WorkflowDTO>) => {
      return <TableCell title={name} subTitle={code}></TableCell>
    },
  },
]
