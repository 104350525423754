import { groupBy } from 'lodash'

import { MaybeNull } from 'interfaces/common'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { PolicyScopeDTO } from 'interfaces/policyScopes-v2/policyScope'
import { ExperienceType, LegacyExperienceType } from 'interfaces/experiences-v2/experienceType'
import {
  DeploymentPlanFormValues,
  DeploymentPlanFormSpecialValues,
} from 'pages/consentAndRights/deploymentPlans-v2/upsert/utils'

export const getInitialValues = ({
  policyScopes,
  deploymentPlan,
}: {
  policyScopes: PolicyScopeDTO[]
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
}): DeploymentPlanFormValues => {
  const existing = groupBy(deploymentPlan?.policyScopeExperiences, 'policyScopeCode')

  return {
    code: deploymentPlan?.code || '',
    name: deploymentPlan?.name || '',
    isOrchestrationOnly: deploymentPlan?.isOrchestrationOnly || false,
    privacyPolicyCode: deploymentPlan?.privacyPolicyCode || DeploymentPlanFormSpecialValues.NO_DOCUMENT,
    termsOfServiceCode: deploymentPlan?.termsOfServiceCode || DeploymentPlanFormSpecialValues.NO_DOCUMENT,
    themeID: deploymentPlan?.themeID || '',
    policyScopeExperiences: (policyScopes || []).reduce((acc: {}, { code }: PolicyScopeDTO) => {
      const consent = existing[code]?.find(exp => (exp.experienceType as any) === LegacyExperienceType.CONSENT)
      const preference = existing[code]?.find(exp => (exp.experienceType as any) === LegacyExperienceType.PREFERENCE)

      return {
        ...acc,
        [code]: {
          [ExperienceType.Consent]: {
            experienceID: consent ? consent.experienceID || DeploymentPlanFormSpecialValues.NO_EXPERIENCE : '',
            experienceDefault: consent?.experienceDefault || '',
          },
          [ExperienceType.Preference]: {
            experienceID: preference ? preference.experienceID || DeploymentPlanFormSpecialValues.NO_EXPERIENCE : '',
          },
        },
      }
    }, {}),
  }
}
