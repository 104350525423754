import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { UpsertLayout, FormRow, Complication } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import { Values, formStepConfigs, initialValues } from './utils'
import { InputStepExample } from './components/InputStepExample'
import { RadioStepExample } from './components/RadioStepExample'

interface Props {}

const getRenderedFormTitle = () => <Complication title="Create layout form example" mb="24px" />

export const CreateLayoutFormExample: React.FC<Props> = props => {
  const onSubmit = (values: Values) => {
    alert(JSON.stringify(values, null, 2))
  }
  const [endText, setEndText] = useState('')
  const [currentFormStep, setCurrentFormStep] = useState(0)
  const [currentValidationSchema, setCurrentValidationSchema] = useState(formStepConfigs[0].validationSchema)

  const isLastStep = formStepConfigs.length - 1 === currentFormStep

  useEffect(() => {
    let n = 0
    let current = {} as Yup.ObjectSchema<any>
    while (n <= currentFormStep) {
      if (n === 0) {
        current = formStepConfigs[0]?.validationSchema
      } else {
        current = current.concat(formStepConfigs?.[n]?.validationSchema)
      }
      n++
    }
    setCurrentValidationSchema(current)
  }, [currentFormStep])

  const formikInstance = useFormik<Values>({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: currentValidationSchema,
  })

  const { validateForm, values, errors } = formikInstance

  const onCancel = () => {
    setCurrentValidationSchema(formStepConfigs[0]?.validationSchema)
    setCurrentFormStep(0)
    setEndText('')
  }
  const onAccept = async () => {
    const errors = await validateForm()
    if (Object.keys(errors).length) {
      return
    }
    if (!isLastStep) {
      setCurrentFormStep(prev => prev + 1)
    } else {
      setEndText('Done')
    }
  }

  const formItems = [
    <FormRow title={formStepConfigs[0].title} subTitle="Subhead">
      <InputStepExample formFieldValue="firstValue" />
    </FormRow>,
    <FormRow title={formStepConfigs[1].title} subTitle="Subhead">
      <RadioStepExample formFieldValue="secondValue" />
    </FormRow>,
    <FormRow title={formStepConfigs[2].title}>
      <InputStepExample formFieldValue="thirdValue" />
    </FormRow>,
    <FormRow title={formStepConfigs[3].title} subTitle="Subhead">
      <InputStepExample formFieldValue="fourthValue" />
    </FormRow>,
    <FormRow title={formStepConfigs[4].title}>
      <InputStepExample formFieldValue="fifthValue" />
    </FormRow>,
  ]

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: theme => theme.palette.bone.main,
      }}
    >
      <FormikProvider value={formikInstance}>
        <UpsertLayout
          formSteps={formStepConfigs.map(elem => elem.title)}
          currentFormStep={currentFormStep}
          showStepper
          onAccept={onAccept}
          onCancel={onCancel}
          renderFormTitle={getRenderedFormTitle}
        >
          {formItems.filter((_, i) => currentFormStep >= i)}
        </UpsertLayout>
      </FormikProvider>
      {endText && <pre>{JSON.stringify(endText, null, 2)}</pre>}
      <pre>{JSON.stringify(values, null, 2) || 'nothing to preview'}</pre>
      <pre>{JSON.stringify(errors, null, 2) || 'nothing to preview'}</pre>
    </Box>
  )
}
