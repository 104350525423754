import { useMemo } from 'react'
import * as Yup from 'yup'

import { useCheckIsIdentitySpaceCodeAvailable } from 'api/identitySpaces/mutations/useCheckIsIdentitySpaceCodeAvailable'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { IdentitySpaceFormValues } from 'pages/settings/identitySpaces/upsert/utils/formValues'

export const useValidationSchema = ({ isEditMode }: { isEditMode?: boolean } = {}) => {
  const { mutateAsync: handleCheckIsIdentitySpaceCodeAvailable } = useCheckIsIdentitySpaceCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsIdentitySpaceCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(
    () =>
      Yup.object().shape({
        ...(!isEditMode && {
          code: codeValidationSchema,
        }),
        name: Yup.string().max(75, 'Name must not exceed 75 characters').required('Required'),
        typeCode: Yup.string().required('Type is required'),
        formatCode: Yup.string().required('Format is required'),
        mappings: Yup.array(
          Yup.object().shape({
            identitySpaceCode: Yup.string().test('Required if hasMappings', 'Required', function (
              this: any,
              value: string,
            ) {
              const ctx = this as Yup.TestContext & { from: [any, { value: IdentitySpaceFormValues }] }
              const { value: formValues } = ctx.from[1]

              return !formValues.hasMappings || !!value
            } as Yup.TestFunction),
          }),
        ),
        verifierID: Yup.string().when('isAuthentication', {
          is: true,
          then: Yup.string().required('Please select verifier'),
        }),
      }),
    [isEditMode, codeValidationSchema],
  )
}
