import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  id: string
}

export const deleteInstalledDataSystem = ({ id }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/v2/installed-data-systems/${id}`,
    }),
  )
