import { FetchNotificationsCountResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchUnreadNotificationsCount = ({
  hasNoTaskId,
  hasTaskId,
}: {
  hasNoTaskId?: boolean
  hasTaskId?: boolean
}) => {
  return API.get<FetchNotificationsCountResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/notifications/count`,
      params: {
        filters: {
          ...(hasTaskId && { hasTaskId }),
          ...(hasNoTaskId && { hasNoTaskId }),
        },
      },
    }),
  )
}
