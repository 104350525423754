export const getPluralAssetContent = ({ key, value }: { key: string; value: number }): string => {
  if (key.toLowerCase().includes('attribute')) {
    if (value >= 2) return 'Attributes'
    if (value < 2) return 'Attribute'
  }

  if (key.toLowerCase().includes('dataset')) {
    if (value >= 2) return 'Datasets'
    if (value < 2) return 'Dataset'
  }

  if (key.toLowerCase().includes('schema')) {
    if (value >= 2) return 'Schemas'
    if (value < 2) return 'Schema'
  }

  return ''
}
