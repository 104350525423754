import React from 'react'
import clsx from 'clsx'
import { Switch as MUISwitch, SxProps, Theme } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  baseSwitch: {
    boxSizing: 'border-box',
    padding: 0,

    '&:hover': {
      background: 'transparent',
    },

    '&$sizeSmall': {
      '& .MuiSwitch-switchBase': {
        '&$checked': {
          transform: 'translateX(12px)',
        },
      },
    },

    '&$sizeRegular': {
      '& .MuiSwitch-switchBase': {
        '&$checked': {
          transform: 'translateX(16px)',
        },
      },
    },

    '& .MuiSwitch-switchBase': {
      padding: 2,

      '&:hover': {
        '& + $track': {
          background: theme.palette.darkDuskFaded.main,
        },
      },

      '&:active': {
        '& $thumb': {
          background: theme.palette.white.main,
          opacity: 0.6,
        },
      },

      '& $thumb': {
        color: 'rgba(255, 255, 255, 0.6)',
        opacity: 1,
      },

      '&$checked': {
        color: theme.palette.white.main,
        opacity: 1,

        '& $thumb': {
          background: theme.palette.white.main,
          opacity: 1,
        },

        '&:hover': {
          '& + $track': {
            background: theme.palette.greenHaze.main,
          },
        },

        '&:active': {
          '& $thumb': {
            background: theme.palette.white.main,
            opacity: 0.6,
          },
        },

        '& + $track': {
          background: theme.palette.apple.main,
          opacity: 1,
        },
      },
    },
  },

  thumb: {
    width: 20,
    height: 20,
    background: theme.palette.white.main,
  },

  track: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.iron.main,
    opacity: 1,
    transition: theme.transitions.create(['background-color']),
  },

  // Sizes:
  sizeSmall: {
    width: 32,
    height: 20,

    '& $thumb': {
      width: 16,
      height: 16,
    },
  },
  sizeRegular: {
    width: 40,
    height: 24,

    '& $thumb': {
      width: 20,
      height: 20,
    },
  },

  disabled: {
    '& $track': {
      background: `${theme.palette.iron.main} !important`,
    },

    '& $thumb': {
      background: 'rgba(255, 255, 255, 0.6) !important',
    },
  },

  disabledOn: {
    '& $track': {
      background: `${theme.palette.greenHazeFaded.main} !important`,
    },

    '& $thumb': {
      background: 'rgba(255, 255, 255, 0.6) !important',
    },
  },

  checked: {},
  focusVisible: {},
}))

export interface Props {
  /** ID of an element */
  id?: string
  /** Name of an element */
  name?: string
  /** Test ID of an element for selectors */
  testId?: string
  /** Custom element className */
  className?: string
  /** Component value (controlled) */
  value: boolean
  /** Component value change method */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  /** Component size */
  size?: 'regular' | 'small'
  /** Disable state */
  disabled?: boolean
  /** Disabled in 'on' state */
  disabledOn?: boolean
  /** Stop click event bubbling */
  disableClickBubbling?: boolean
  sx?: SxProps<Theme>
}

/**
 * -
 */
export const Switch: React.FC<Props> = ({
  id,
  name,
  testId,
  className,
  value,
  size = 'regular',
  disabled = false,
  disabledOn = false,
  disableClickBubbling = false,
  onChange,
  sx,
}) => {
  const classes = useStyles()

  const componentClassName = clsx(className, classes.baseSwitch, {
    [classes.sizeSmall]: size === 'small',
    [classes.sizeRegular]: size === 'regular',
    [classes.disabled]: disabled && !disabledOn,
    [classes.disabledOn]: disabledOn,
  })

  return (
    <MUISwitch
      id={id}
      name={name}
      data-test-id={`Switch-${testId}`}
      disableRipple
      checked={value}
      disabled={disabled || disabledOn}
      classes={{
        root: componentClassName,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      onChange={onChange}
      onClick={disableClickBubbling ? e => e.stopPropagation() : undefined}
      sx={sx}
    />
  )
}
