import { useAuth0 } from '@auth0/auth0-react'
import { InitialLoadingScreen } from 'pages/auth/InitialLoadingScreen'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'utils/hooks/useAuth'
import { RoutesManager } from '../routesManager'
import { CallbackError } from 'pages/auth/signInError/CallbackError'

/**
 * Auth0 automatically redirects the user to the /callback page after they have logged in
 * and this component is used to redirect them to the correct page based on their permissions.
 */
export const Callback = () => {
  const { userData, isLoading: useAuthIsLoading, error: auth0Error } = useAuth()
  const { isLoading: useAuthZeroIsLoading } = useAuth0()

  const [searchParams] = useSearchParams()

  if (searchParams.get('error')) {
    return (
      <CallbackError
        errorTitle={searchParams.get('error')?.split?.('_')?.join?.(' ') || 'Unknown error'}
        errorDescription={searchParams.get('error_description') || 'Unknown error'}
      />
    )
  }

  if (useAuthIsLoading || useAuthZeroIsLoading) return <InitialLoadingScreen />

  // Handle login error
  if (!userData || auth0Error) return <Navigate to={RoutesManager.auth.error.root.getURL()} />

  // Otherwise redirect to home page
  return <Navigate to={RoutesManager.home.root.getURL()} />
}
