import React from 'react'

import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useRightsStatsReport } from 'api/rights/queries/useRightsStatsReport'
import { useTranslation } from 'react-i18next'
import { COMMON, RIGHTS_STATISTICS } from 'i18n/types'
import { DataRoleType } from 'interfaces/events'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { RightsSummaryCurrentQueue, RightsSummaryWeeklyQueue, RightsSummaryTotalFulfillment } from './components'

type Props = {}

export const RightsSummary: React.FC<Props> = () => {
  const { t } = useTranslation([RIGHTS_STATISTICS, COMMON])
  const { queries } = useQueryParams<{
    policyScope?: string
    dataSubjectType?: string
    dataRole?: DataRoleType
  }>()

  const {
    data: rightsReport,
    isLoading,
    isFetching,
  } = useRightsStatsReport({
    params: {
      policyScope: queries?.policyScope,
      dataSubjectType: queries?.dataSubjectType,
      dataRole: queries?.dataRole,
    },
  })

  const isReady = !isLoading && !isFetching

  return (
    <Box mt={3}>
      <Typography variant="h2">{t('Summary')}</Typography>

      <Box mt={3} display="flex">
        <Box display="flex" flexDirection="row" alignItems="center" flex={1} gap={2}>
          <RightsSummaryCurrentQueue isReady={isReady} rightsReport={rightsReport} />
          <RightsSummaryWeeklyQueue isReady={isReady} rightsReport={rightsReport} />
          <RightsSummaryTotalFulfillment isReady={isReady} rightsReport={rightsReport} />
        </Box>
      </Box>
    </Box>
  )
}
