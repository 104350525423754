import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { InlineEditLayout } from 'pages/assetManager/assets/view/hooks/useAssetDetailContentRows/components'
import { TextArea } from 'components/ui-kit/textArea/TextArea'
import { UseInlineEditUtilsReturnType } from '../hooks'

type Props = {
  assetSummary?: AssetSummaryDTO
  inlineEditUtils: UseInlineEditUtilsReturnType
}

export const Description: React.FC<Props> = ({ assetSummary, inlineEditUtils }) => {
  const {
    errors,
    handleSubmit,
    isUpdatingDatabase,
    values,
    resetForm,
    setFieldValue,
    initialValues,
    handleChange,
    setEditRow,
    editRow,
  } = inlineEditUtils

  return (
    <InlineEditLayout
      editModeKey="Description"
      setEditRow={setEditRow}
      isEditMode={editRow === 'Description'}
      pending={isUpdatingDatabase}
      onSubmit={handleSubmit}
      resetForm={resetForm}
      resetInputOnCancel={() => setFieldValue('description', initialValues.description)}
      error={errors?.dataCategory ? <Error>{errors?.description}</Error> : undefined}
      displayModeContent={
        assetSummary?.asset?.description ? (
          <Text size={14}>{assetSummary?.asset?.description}</Text>
        ) : (
          <EmptyValueRenderer color="darkDuskFaded" />
        )
      }
      editModeContent={
        <TextArea
          fullWidth
          value={values.description}
          onChange={handleChange}
          name="description"
          placeholder="Describe the asset contents..."
        />
      }
    />
  )
}
