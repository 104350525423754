import { FC, useContext } from 'react'
import { useMatches } from 'react-router-dom'
import { theme } from '@ketch-com/deck'
import { OrgPlanAndBillingBannerVariants } from 'components/planAndBilling/common/constants/planAndBilling'
import { Box } from '@mui/material'
import { RoutesManager } from 'utils/routing/routesManager'
import { PlanAndBillingBanners } from 'components/planAndBilling/components/banners/PlanAndBillingBanners'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'

const ignoreRoutes = [RoutesManager.settings.planAndBilling.root.getURL()]

export const PlanAndBillingGlobalBanner: FC = () => {
  const {
    isOrgOnFreePlan,
    isFreePlanInGrace,
    isFreePlanSuspended,
    isOrgOnStarterPlan,
    isStarterPlanInGrace,
    isStarterPlanSuspended,
    isBillingInGrace,
    isBillingMissed,
  } = useContext(PlanAndBillingContext)

  const matches = useMatches()

  const isPlanSuspended = isFreePlanSuspended || isStarterPlanSuspended
  const isOrgOnPlgPlan = isOrgOnFreePlan || isOrgOnStarterPlan
  const isHiddenBanner =
    ((isOrgOnFreePlan || isOrgOnStarterPlan) &&
      !isFreePlanInGrace &&
      !isFreePlanSuspended &&
      !isStarterPlanInGrace &&
      !isStarterPlanSuspended &&
      !isBillingInGrace &&
      !isBillingMissed) ||
    !isOrgOnPlgPlan ||
    matches.some(match => ignoreRoutes.some(ignoreRoute => ignoreRoute === match.pathname))

  if (isHiddenBanner) return null

  return (
    <Box mt={-3} mb={3} sx={{ width: '100%', backgroundColor: theme.palette.common.white }}>
      {isFreePlanInGrace && !isBillingMissed ? (
        <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.freePlanInGrace} isGlobal={true} />
      ) : null}
      {isFreePlanSuspended ? (
        <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.freePlanSuspended} isGlobal={true} />
      ) : null}
      {isStarterPlanInGrace && !isBillingMissed ? (
        <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.starterPlanInGrace} isGlobal={true} />
      ) : null}
      {isStarterPlanSuspended ? (
        <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.starterPlanSuspended} isGlobal={true} />
      ) : null}
      {isBillingInGrace && !isPlanSuspended ? (
        <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.billingInGrace} isGlobal={true} />
      ) : null}
      {isBillingMissed ? (
        <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.billingMissed} isGlobal={true} />
      ) : null}
    </Box>
  )
}
