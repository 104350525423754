import { Dispatch, SetStateAction } from 'react'
import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { Rights } from 'pages/assetManager/scenario/list/components'
import { ScenarioDTO } from '@ketch-com/figurehead'
import { RightType } from 'interfaces/events'
import { theme } from '@ketch-com/deck'
import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'

type Props = {
  scenarios: ScenarioDTO[]
  setRightType: Dispatch<SetStateAction<RightType>>
  assetSummary: MaybeNull<AssetSummaryDTO>
  rightType: RightType
}

const segmentsTabOptions = [
  {
    id: RightType.RIGHT_TYPE_UNSPECIFIED,
    name: 'All',
  },
  {
    id: RightType.RIGHT_TYPE_CANONICAL,
    name: 'Canonical',
  },
  {
    id: RightType.RIGHT_TYPE_CUSTOM,
    name: 'Custom',
  },
]

export const DatasetScenario: React.FC<Props> = ({ scenarios, setRightType, assetSummary, rightType }) => {
  const canonicalEvents: ScenarioDTO[] = []
  const customEvents: ScenarioDTO[] = []

  scenarios?.forEach?.(scenario => {
    if (scenario.rightType === RightType.RIGHT_TYPE_CANONICAL) canonicalEvents.push(scenario)
    if (scenario.rightType === RightType.RIGHT_TYPE_CUSTOM) customEvents.push(scenario)
  })

  return (
    <Box p={1.5} pt={0}>
      <Box mb={4}>
        <Text size={20} weight={700} display="block">
          DSR Configuration
        </Text>
        <Text size={14} color="grey">
          See how data assets are configured to support data subjects rights requests.
        </Text>
      </Box>
      <Box
        display="flex"
        justifyContent="left"
        alignItems="center"
        gap={4}
        mb={4}
        pb={2}
        borderBottom={`1px solid ${theme.palette.iron.main}`}
      >
        <SegmentTabs
          tabs={segmentsTabOptions}
          value={rightType}
          onChange={rightType => {
            setRightType(rightType)
          }}
        />
      </Box>
      {canonicalEvents.length ? (
        <Box>
          <Text color="grey" size={20} weight={700}>
            Canonical Events
          </Text>
          <Rights
            eventBasedScenario={canonicalEvents}
            assetSummary={assetSummary}
            isDatasetScenario={true}
            isCanonical={true}
          />
        </Box>
      ) : null}
      {customEvents.length ? (
        <Box>
          <Text color="grey" size={20} weight={700}>
            Custom Events
          </Text>
          <Rights eventBasedScenario={customEvents} assetSummary={assetSummary} isDatasetScenario={true} />
        </Box>
      ) : null}
    </Box>
  )
}
