import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  experienceCode: string
  organizationCode: string
}

export const deleteExperience = ({ organizationCode, experienceCode }: Params) =>
  API.delete<null>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/experiences/${experienceCode}`,
    }),
  )
