import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  conversationId: string
}>

export const addParticipantToConversationIfNotExist = ({ conversationId }: Params) =>
  API.post(
    formatRequestString({
      entityUrl: `/api/chat/conversations/${conversationId}/participants`,
    }),
    {},
  )
