import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import React from 'react'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { Box } from '@mui/material'
import { Tab, TabPanel, TabGroup } from '@ketch-com/deck'
import { FormTextInput } from '../FormTextInput'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { useContext } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'

export const PreferenceExperienceRequestsTabHomeDsrLinkForm: React.FC = () => {
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length

  // Current tab of the unsubscribe from all form
  const [tab, setTab] = React.useState(0)

  return (
    <SidebarFormGroup sx={{ pt: '12px' }}>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
            <Tab size={'medium'} label="Title" />
            <Tab size={'medium'} label="Description" />
          </TabGroup>
        </Box>

        {/* Title */}
        <TabPanel value={tab} index={0} sx={{ px: 0, pt: '12px' }}>
          <SidebarFormGroup hasBorderBottom={hasLanguages} sx={{ paddingTop: '16px' }}>
            <FormTextInput
              label={'Title Text'}
              name={experienceUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkTitle}
              fullWidth
              charLimit={30}
            />
          </SidebarFormGroup>
          <SidebarFormTranslations
            title={'Title Text Translations'}
            name={experienceUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkTitle}
            variant={'text'}
            charLimit={30}
          />
        </TabPanel>

        {/* Description */}
        <TabPanel value={tab} index={1} sx={{ px: 0, pt: '12px' }}>
          <SidebarFormGroup hasBorderBottom={hasLanguages} sx={{ paddingTop: '16px' }}>
            <FormTextInput
              label={'Description Text'}
              name={experienceUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkDescription}
              fullWidth
              charLimit={200}
            />
          </SidebarFormGroup>
          <SidebarFormTranslations
            title={'Description Text Translations'}
            name={experienceUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkDescription}
            variant={'text'}
            charLimit={200}
          />
        </TabPanel>
      </Box>
    </SidebarFormGroup>
  )
}
