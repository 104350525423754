import { DATA_TYPE_NAMES, UPDATE_VALUE_VALIDATION_SCHEMAS } from '../../../constants'

export const validateCustomValueInput = async ({
  switchCase,
  updateValue,
}: {
  switchCase: string
  updateValue: string
}) => {
  switch (switchCase) {
    case DATA_TYPE_NAMES.STRING.toLowerCase():
      await UPDATE_VALUE_VALIDATION_SCHEMAS.STRING.validate(updateValue)
      break
    case DATA_TYPE_NAMES.INTEGER.toLowerCase():
      await UPDATE_VALUE_VALIDATION_SCHEMAS.INTEGER.validate(updateValue)
      break
    case DATA_TYPE_NAMES.BOOLEAN.toLowerCase():
      await UPDATE_VALUE_VALIDATION_SCHEMAS.BOOLEAN.validate(updateValue)
      break
    case DATA_TYPE_NAMES.INT.toLowerCase():
      await UPDATE_VALUE_VALIDATION_SCHEMAS.INT.validate(updateValue)
      break
    case DATA_TYPE_NAMES.INT_8.toLowerCase():
      await UPDATE_VALUE_VALIDATION_SCHEMAS.INT_8.validate(updateValue)
      break
    case DATA_TYPE_NAMES.INT_32.toLowerCase():
      await UPDATE_VALUE_VALIDATION_SCHEMAS.INT_32.validate(updateValue)
      break
    default:
      await UPDATE_VALUE_VALIDATION_SCHEMAS.STRING.validate(updateValue)
      break
  }
}
