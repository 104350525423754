import { TextInput, TextInputProps } from '@ketch-com/deck'
import { Box, InputLabel, Typography } from '@mui/material'
import { FieldValidator, useField } from 'formik'
import { debounce } from 'lodash'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'

type FormTextInputProps<T> = {
  debounceWaitTime?: number
  formPropertyName: keyof T
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  shouldOverrideOnChange?: boolean
  shouldUpdateDebounced?: boolean
  validate?: FieldValidator
  hideOptionalLabel?: boolean
  labelColor?: string
  alwaysShowError?: boolean
  hint?: string
} & TextInputProps

export const FormInput = <T,>({
  debounceWaitTime = 500,
  formPropertyName,
  onBlur,
  onChange,
  required,
  shouldOverrideOnChange = false,
  shouldUpdateDebounced = false,
  validate,
  label,
  hideOptionalLabel,
  size,
  labelColor,
  disabled,
  alwaysShowError,
  hint,
  ...rest
}: FormTextInputProps<T>) => {
  const [textValue, setTextValue] = useState<string>('')
  const [field, meta, helpers] = useField({ name: formPropertyName as string, validate })
  const showError = meta.error && meta.touched

  useEffect(() => {
    setTextValue(field.value)
  }, [field.value])

  const debounceFn = useMemo(
    () =>
      debounce((v: string) => {
        helpers.setValue(v)
      }, debounceWaitTime),
    [helpers, debounceWaitTime],
  )

  const debounceOnChangeHandler = useMemo(
    () =>
      debounce((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onChange && onChange(e)
      }, debounceWaitTime),
    [onChange, debounceWaitTime],
  )

  const onChangeInner = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!shouldOverrideOnChange) {
      // Formik onChange
      if (shouldUpdateDebounced) {
        setTextValue(e.target.value)
        debounceFn(e.target.value)
      } else {
        field.onChange(e)
      }
    }
    if (onChange) {
      if (shouldUpdateDebounced) {
        debounceOnChangeHandler(e)
      } else {
        onChange(e)
      }
    }
  }

  return (
    <Box>
      {/* we show optional text if not required */}
      <InputLabel>
        {required ? (
          <Typography variant="label" color={labelColor || 'darkDusk.main'}>
            {label}
          </Typography>
        ) : (
          <>
            <Typography variant="label" color={labelColor || 'darkDusk.main'}>
              {label}
            </Typography>{' '}
            {!hideOptionalLabel && (
              <Typography variant="label" color="darkGrey.main">
                {'(Optional)'}
              </Typography>
            )}
          </>
        )}
      </InputLabel>
      <TextInput
        {...field}
        {...rest}
        onBlur={e => {
          field.onBlur(e)
          onBlur?.(e)
        }}
        disabled={disabled}
        size={size}
        value={shouldUpdateDebounced ? textValue : field.value}
        required={required}
        error={(!!alwaysShowError || (required && !disabled)) && !!showError}
        onChange={onChangeInner}
        errorMessage={showError ? meta.error : undefined}
        helperText={hint}
      />
    </Box>
  )
}
