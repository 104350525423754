import React from 'react'
import { useFormikContext } from 'formik'
import { Box, Typography } from '@mui/material'
import { DataGrid, theme, InlineEdit, TableCell, FormRow } from '@ketch-com/deck'
import { CustomRightNameDTO } from '@ketch-com/figurehead'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { extractValueInParentheses } from '../utils'
import { FormInput } from 'components/form/FormInput'

type Props = {
  values: any
  isLoading?: boolean
}

export const BasicDetailsFormSection: React.FC<Props> = ({ isLoading, values }) => {
  const { setFieldValue } = useFormikContext<CustomRightNameDTO>()
  const subTitle = 'Add a name and provide translations.'
  const { data: orgLangs, isLoading: isOrgLangLoading } = useOrganizationLanguages()

  const columns = [
    {
      sortable: false,
      field: 'language',
      headerName: 'Language',
      width: 250,
      renderCell: (params: { row: { translation: string | undefined; id: number; language: string } }) => (
        <TableCell columnGap={1.5}>
          <Typography>{params.row.language}</Typography>
        </TableCell>
      ),
    },
    {
      sortable: false,
      field: 'translation',
      headerName: 'Custom Right Name Translation',
      width: 950,
      renderCell: (params: { row: { translation: string | undefined; id: number; language: string } }) => (
        <TableCell columnGap={1.5}>
          <InlineEdit
            textInputVariant="ghost"
            placeholder="Add Translation"
            value={params.row.translation}
            onKeyDown={handleKeyDown}
            onChange={e => handleTranslation(e, params.row.id, params.row.language)}
            autoComplete="off"
          />
        </TableCell>
      ),
    },
  ]

  const mappedTranslations = orgLangs.map(langObj => {
    const translation =
      values.translations &&
      values.translations.find((t: { languageCode: string }) => t.languageCode === langObj.language.code)
    return {
      language: langObj.language.englishName,
      name: translation ? translation.name : '',
    }
  })

  const generateRows = () => {
    if (values.translations) {
      return mappedTranslations.map((mappedTranslation, index) => ({
        id: index,
        language: mappedTranslation.language,
        translation: mappedTranslation.name,
      }))
    }

    return orgLangs.map((obj, index) => ({
      id: index,
      language: obj.language.englishName,
      translation: '',
    }))
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === ' ' || e.code === 'Space') e.stopPropagation()
  }

  const handleTranslation = (e: React.ChangeEvent<HTMLInputElement>, index: number, language: string) => {
    const languageCode = extractValueInParentheses(language)
    const name = e.target.value
    if (values.translations === undefined) {
      setFieldValue('translations', [{ languageCode, name }])
    } else {
      const updatedTranslation =
        values.translations?.length > 0
          ? values.translations?.find(
              (translation: { languageCode: string | null }) => translation.languageCode === languageCode,
            )
          : null
      if (updatedTranslation) {
        updatedTranslation.name = name
        setFieldValue('translations', [...values.translations])
      } else {
        setFieldValue('translations', [...values.translations, { languageCode, name }])
      }
    }
  }

  const rows = generateRows()

  return (
    <FormRow title="Details" subTitle={subTitle}>
      <Box width="50%">
        <FormInput
          fullWidth
          formPropertyName="name"
          label="Custom Name"
          required
          shouldUpdateDebounced
          placeholder="Add Right Name"
        />
      </Box>
      <Box mt={6}>
        <Typography variant="h4" color={theme.palette.darkDuskFaded.main} mb={3}>
          Translations
        </Typography>
        <DataGrid
          sx={{
            ...((isLoading || isOrgLangLoading) && {
              '& .MuiDataGrid-virtualScroller': {
                height: '300px',
              },
            }),
          }}
          columns={columns}
          rows={rows.length ? rows : []}
          autosizeOnMount
          getRowHeight={() => 'auto'}
          disableColumnResize
          disableColumnFilter
          disableColumnReorder
          disableColumnPinning
          disableAggregation
          hideFooter
          disableColumnMenu
          disableRowHoverStates
          disableRowSelectionOnClick
          disableMultipleRowSelection
          loading={isLoading || isOrgLangLoading}
          noRowsOverlayProps={{
            buttonTitle: '',
            subTitle: '',
          }}
        />
      </Box>
    </FormRow>
  )
}
