import { IncludeDataReviewFileResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  executionId?: string
  fileName?: string
  requestId?: string
  stepId?: string
}

export const includeDataReviewFile = ({ executionId, fileName, requestId, stepId }: Params) =>
  API.put<IncludeDataReviewFileResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/data-review/${requestId}/${executionId}/${stepId}/files/${fileName}`,
    }),
    { formData: { fileName } },
  )
