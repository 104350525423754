import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchInstalledDataSystemsStats } from '../fetchers/fetchInstalledDataSystemsStats'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.installedDataSystemsStats,
  queryFn: fetchInstalledDataSystemsStats,
  select: res =>
    res?.data || {
      connectionCountByStatus: {
        CONNECTION_STATUS_ACTIVE: 0,
        CONNECTION_STATUS_PENDING: 0,
      },
      installedDataSystemsCount: 0,
    },
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useInstalledDataSystemsStats = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
