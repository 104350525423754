import React, { useContext } from 'react'
import { RoutesManager } from 'utils/routing/routesManager'
import { PrivacyProtocolViewOverview } from './components/PrivacyProtocolViewOverview'
import { PrivacyProtocolViewDetails } from './components/PrivacyProtocolViewDetails'
import { PrivacyProtocolViewActions } from './components/PrivacyProtocolViewActions'
import { ProtocolIcon } from '../components/ProtocolIcon'
import { PrivacyProtocolContext, withPrivacyProtocolContext } from './context/PrivacyProtocolContext'
import { Spinner, ViewLayout } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Box } from '@mui/material'

/**
 * View for the details of a privacy protocol configuration
 */
const PrivacyProtocolWithContext: React.FC = () => {
  const breadcrumbs = [{ title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() }]

  const { protocol, isReady } = useContext(PrivacyProtocolContext)
  const navigate = useNavigate()

  if (!isReady) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flex={1} flexDirection="column">
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }
  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      <ViewLayout
        contentGroupProps={{
          title: protocol.name,
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.orchestration.consent.privacyProtocols.root.getURL()),
          },
          infoBlockComponent: <PrivacyProtocolViewDetails />,
          actionRightBlockComponent: <PrivacyProtocolViewActions />,
          imageBlockComponent: <ProtocolIcon protocolId={protocol.id || ''} variant="large" />,
        }}
      >
        <PrivacyProtocolViewOverview />
      </ViewLayout>
    </>
  )
}

export const PrivacyProtocol = withPrivacyProtocolContext(PrivacyProtocolWithContext)
