import { Element, Text, Descendant } from 'slate'
import { LinkElement } from 'pages/consentAndRights/experiences/upsert/components/common/interpolatedTextArea/interfaces'

export const serialize = (nodes: Descendant[]) =>
  nodes
    .map(node => {
      const texts: string[] = []
      const inlineNodes: (LinkElement | Text)[] = Element.isElement(node) ? node.children : []

      inlineNodes?.forEach?.(inlineNode => {
        if (Element.isElement(inlineNode)) {
          if (inlineNode.type === 'link') {
            const linkText = inlineNode.children.map(({ text }) => text).join('')
            const linkUrl = inlineNode.url

            texts.push(`[${linkText}](${linkUrl})`)
          }
        } else {
          texts.push(inlineNode.text)
        }
      })

      return texts.join('')
    })
    .join('\n')
    .trim()
