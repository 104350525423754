import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import Box from '@mui/material/Box'
import { showToast } from 'components/modals/AlertComponent'
import { useDeleteContextVariable } from 'api/contextVariables/mutations/useDeleteContextVariable'
import { ContextVariableDTO } from '@ketch-com/figurehead'
import Typography from '@mui/material/Typography'
import { VariableUsedInWorkflowsTable } from 'pages/settings/contextVariables/components'
import { Button, PopUp } from '@ketch-com/deck'

type Props = {
  contextVariable: ContextVariableDTO
  onSubmit?: () => void
  onCancel: () => void
}

export const DeleteContextVariablesModal: React.FC<Props> = ({ contextVariable, onSubmit, onCancel }) => {
  const { code = '', name = '' } = contextVariable
  const queryClient = useQueryClient()
  const [isBusy, setIsBusy] = useState(false)
  const isVariableInUseInWorkflows = !!contextVariable?.activities?.length

  const { mutate: handleDeleteContextVariable } = useDeleteContextVariable({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'Context variable deleted', type: 'success' })
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to delete variable', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <PopUp
      title="Delete Variable"
      variant={isVariableInUseInWorkflows ? 'modal' : 'dialog'}
      popUpWidth="636px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel} pending={isBusy}>
            Close
          </Button>
          {!isVariableInUseInWorkflows && (
            <Button
              color="primary"
              size="large"
              onClick={() =>
                handleDeleteContextVariable({
                  params: {
                    code,
                  },
                })
              }
              pending={isBusy}
            >
              Confirm
            </Button>
          )}
        </>
      }
    >
      <Box display="flex" flexDirection="column">
        {isVariableInUseInWorkflows ? (
          <>
            <Typography variant="body" mb={1}>
              Variables used in workflows cannot be deleted. Please update and try again.
            </Typography>
            <VariableUsedInWorkflowsTable contextVariable={contextVariable} />
          </>
        ) : (
          <Typography variant="body">
            Are you sure you want to delete <strong>{name || code}</strong> context variable? This deletion is
            irreversible
          </Typography>
        )}
      </Box>
    </PopUp>
  )
}
