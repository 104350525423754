import { DropListButton, ListItemText, ActionSheetItem } from '@ketch-com/deck'

interface CookieListFilterDropListItemsType {
  content: string
  active: boolean
  onClick: () => void
}
interface CookieListFilterDropListType {
  selectedValue: string
  placeholder: string
  items: CookieListFilterDropListItemsType[]
  onClear?: () => void
}

export const CookieListFilterDropList = ({
  selectedValue,
  placeholder,
  items,
  onClear,
}: CookieListFilterDropListType) => {
  return (
    <DropListButton
      size="small"
      options={items}
      placeholder={placeholder}
      getOptionLabel={option => option.content as string}
      isOptionEqualToValue={(option, value) => option.content === value.content}
      onChange={(_, newValue) => {
        if (newValue) {
          const currentItem = items.find(item => item.content === newValue.content)
          if (currentItem) {
            currentItem.onClick && currentItem.onClick()
          }
        } else {
          if (onClear) {
            onClear()
          }
        }
      }}
      value={items.find(item => item.content === selectedValue) ?? null}
      renderOption={(props, option, state) => (
        <ActionSheetItem selected={option.content === selectedValue} {...props}>
          <ListItemText selected={option.content === selectedValue}>{option.content}</ListItemText>
        </ActionSheetItem>
      )}
    />
  )
}
