import React from 'react'
import { Box, Typography } from '@mui/material'
import moment from 'moment'

import { DistributionPerIdentitySpace, DistributionPerJurisdiction } from './components'
import { usePermitsStatsPerJurisdiction } from 'api/permitStatistics/queries/usePermitsStatsPerJurisdiction'
import { useAppSelector } from 'store/hooks'
import { getPermitStatisticsFilterState } from 'store/permitStatisticsFilterStateSlice/selectors'
import { usePermitsStatsPerIdentitySpace } from 'api/permitStatistics/queries/usePermitsStatsPerIdentitySpace'
import { usePermitsStatsPerJurisdictionTotalRequests } from 'api/permitStatistics/queries/usePermitsStatsPerJurisdictionTotalRequests'
import { usePermitsStatsPerIdentitySpaceMaxNumberOfRequests } from 'api/permitStatistics/queries/usePermitsStatsPerIdentitySpaceMaxNumberOfRequests'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'

type Props = {}

export const Distribution: React.FC<Props> = () => {
  const { startDate, endDate, identitySpace, jurisdiction, environment, property, purpose } =
    useAppSelector(getPermitStatisticsFilterState)
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])
  const { data: jurisdictions, isLoading: isLoadingJurisdictions } = usePermitsStatsPerJurisdiction({
    enabled: !!startDate && !!endDate,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  const { data: jurisdictionTotalRequests } = usePermitsStatsPerJurisdictionTotalRequests({
    enabled: !!startDate && !!endDate,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  const {
    data: identitySpaces,
    isLoading: isLoadingIdentitySpaces,
    pagination: identitySpacesPagination,
  } = usePermitsStatsPerIdentitySpace({
    enabled: !!startDate && !!endDate,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  const { data: identitySpacesMaxNumberOfRequests } = usePermitsStatsPerIdentitySpaceMaxNumberOfRequests({
    enabled: !!startDate && !!endDate,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  return (
    <>
      <Box display="flex" alignItems="center" mb={3}>
        <Typography variant="h2" sx={{ py: 1 }}>
          {t('User Distribution')}
        </Typography>
      </Box>

      <Box display="flex" alignItems="stretch" gap={2} sx={{ minHeight: 485 }}>
        <DistributionPerJurisdiction
          isLoading={isLoadingJurisdictions}
          jurisdictions={jurisdictions}
          jurisdictionTotalRequests={jurisdictionTotalRequests}
        />

        <DistributionPerIdentitySpace
          isLoading={isLoadingIdentitySpaces}
          identitySpaces={identitySpaces}
          identitySpacesMaxNumberOfRequests={identitySpacesMaxNumberOfRequests}
          pagination={identitySpacesPagination}
        />
      </Box>
    </>
  )
}
