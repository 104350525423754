import React from 'react'
import { useNavigate } from 'react-router-dom'

import { CanonicalPurposeDTO } from 'interfaces/purposes/canonicalPurpose'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

import { GridRowParams } from '@mui/x-data-grid'
import { AppsInstancesPurposeDTO } from 'interfaces/apps'
import { Box } from '@mui/material'
import { DataGrid, EmptyState } from '@ketch-com/deck'
import { usePermitPropagationListColumns } from './permitPropagationListUtils'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isReady: boolean
  isLoading: boolean
  isFetching: boolean
  appsInstancesPurposes: AppsInstancesPurposeDTO[]
  canonicalPurposes: CanonicalPurposeDTO[]
}

export const PermitPropagationList: React.FC<Props> = ({
  isReady,
  isFetching,
  isLoading,
  appsInstancesPurposes,
  canonicalPurposes,
}) => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()

  const isPermittedToInstallApps = isPermitted(PERMISSIONS.PERMIT_ORCH_WRITE)

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Consent', link: RoutesManager.deployment.consentHome.root.getURL() },
    { title: 'Systems', link: RoutesManager.orchestration.consent.systems.root.getURL() },
  ]

  const columns = usePermitPropagationListColumns({
    canonicalPurposes,
  })

  const hasAppInstancesNotConfigured = appsInstancesPurposes.some(({ purposes }) => {
    const isConfigured = Object.values(purposes || {}).some(Boolean)
    if (isConfigured) return false
    return true
  })

  const dataGridColumns = !hasAppInstancesNotConfigured ? columns.slice(1) : columns
  return (
    <Box>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      {!isFetching && !appsInstancesPurposes.length ? (
        <Box
          sx={{
            padding: '50px 0',
            borderRadius: '11px',
            backgroundColor: 'white.main',
          }}
        >
          <EmptyState
            iconName="OTask"
            title="There are no apps installed that propagate permits"
            subTitle={
              isPermittedToInstallApps
                ? 'Install a relevant app from the marketplace to enable this capability.'
                : undefined
            }
            primaryButtonTitle={isPermittedToInstallApps ? 'Install App' : undefined}
            primaryButtonProps={{
              onClick: () => {
                navigate(RoutesManager.systems.root.getURL())
              },
            }}
          />
        </Box>
      ) : (
        <DataGrid
          sx={{
            border: 'unset',
            cursor: 'pointer',
            '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
              backgroundImage: 'unset',
              cursor: 'auto',
            },
            ...(isLoading && {
              '& .MuiDataGrid-virtualScroller': {
                height: '300px',
              },
            }),
          }}
          autosizeOnMount
          autosizeOptions={{
            expand: true,
            includeHeaders: true,
            includeOutliers: false,
          }}
          getRowHeight={() => 'auto'}
          columns={dataGridColumns}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowSelectionOnClick
          getRowId={row => row.app.ID}
          rows={appsInstancesPurposes || []}
          hideFooter
          loading={isLoading}
          noRowsOverlayProps={{
            buttonTitle: '',
          }}
          onRowClick={({ row }: GridRowParams<AppsInstancesPurposeDTO>) => {
            navigate(RoutesManager.orchestration.consent.systems.view.root.getURL({ id: row.app.ID }))
          }}
        />
      )}
    </Box>
  )
}
