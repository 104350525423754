import { Formik } from 'formik'
import { Box, Tooltip, Typography } from '@mui/material'
import { useDataCategoriesInfinite } from 'api/dataSystemsCategories/queries/useDataCategoriesInfinite'
import { useParams } from 'react-router-dom'
import { showToast } from 'components/modals/AlertComponent'
import { useUpdateAssignedCategories } from 'api/dataSystemsCategories/mutations/useUpdateAssignedCategories'
import {
  AssignedCategoryV3DTO,
  ConfidenceLevelV3DTO,
  ConfirmationStatusV3DTO,
  LabelMethodV3DTO,
} from '@ketch-com/figurehead'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useQueryClient } from 'react-query'
import { useAssignedDataCategoriesInfinite } from 'api/dataSystemsCategories/queries/useAssignedDataCategoriesInfinite'
import {
  Button,
  Chip,
  PopUp,
  DropListButton,
  EmptyState,
  Icon,
  LabelChip,
  LabelChipColor,
  LabelChipVariant,
  ListItemText,
  ActionSheetItem,
  theme,
} from '@ketch-com/deck'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const AssignedCategoriesEditModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { id } = useParams<{ id: string }>()

  const { data: dataCategories } = useDataCategoriesInfinite({
    limit: 9000,
  })

  const dataCategoriesRemap: AssignedCategoryV3DTO[] = dataCategories.map(dataCategory => ({
    category: dataCategory,
    categoryId: dataCategory.id,
    installedDataSystemId: id,
  }))

  const { data: dataAssignedCategories } = useAssignedDataCategoriesInfinite({
    installedDataSystemId: id,
    limit: 2000,
  })

  const queryClient = useQueryClient()

  const { mutateAsync: handleUpdateAssignedCategories } = useUpdateAssignedCategories({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.assignedDataCategories, { installedDataSystemId: id }])

      showToast({ content: 'Assigned data categories updated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update assigned data categories', type: 'error' })
    },
  })

  const onSubmit = (values: { assignedCategories: AssignedCategoryV3DTO[] }) => {
    handleUpdateAssignedCategories({
      params: {
        installedDataSystemId: id,
        formData: { assignedCategories: values.assignedCategories },
      },
    })

    onClose()
  }

  const handleConfirmation = (assignedCategoryId: string | undefined, confirmationStatus: ConfirmationStatusV3DTO) => {
    const assignedCategoriesRemap = dataAssignedCategories?.assignedCategories?.map(assignedCategory => {
      if (assignedCategory.id === assignedCategoryId) {
        return {
          ...assignedCategory,
          confirmationStatus,
        }
      } else {
        return assignedCategory
      }
    })

    handleUpdateAssignedCategories({
      params: {
        installedDataSystemId: id,
        formData: { assignedCategories: assignedCategoriesRemap },
      },
    })
  }

  const initialValues = {
    assignedCategories: dataAssignedCategories?.assignedCategories ?? [],
  }

  const assignedCategories = dataAssignedCategories?.assignedCategories?.filter(
    assignedCategory => assignedCategory.confirmationStatus !== ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
  )

  return (
    <Formik enableReinitialize validateOnMount initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <PopUp
            title="Data Categories"
            subTitle={`Data Categories Assigned ${assignedCategories?.length}`}
            isOpen={isOpen}
            variant="modal"
            onClose={() => onClose()}
            popUpWidth="950px"
            popUpActionChildren={
              <>
                <Button
                  color="secondary"
                  size="large"
                  onClick={e => {
                    onClose()
                  }}
                >
                  Close
                </Button>
                <Button color="primary" size="large" onClick={() => handleSubmit()}>
                  Save
                </Button>
              </>
            }
          >
            <Box display="flex" flexDirection="column">
              <DropListButton
                filterSelectedOptions
                multiple
                disableClearable={values.assignedCategories !== null}
                isOptionEqualToValue={(option, value: AssignedCategoryV3DTO) =>
                  option?.category?.id === value?.category?.id
                }
                getOptionLabel={option => option?.category?.name ?? ''}
                renderOption={(props, option, { selected }) => (
                  <ActionSheetItem selected={selected} {...props}>
                    <ListItemText selected={selected}>{option?.category?.name || ''}</ListItemText>
                  </ActionSheetItem>
                )}
                options={dataCategoriesRemap}
                onChange={(e, newValue) => setFieldValue('assignedCategories', newValue)}
                value={values.assignedCategories}
                placeholder="Search..."
                size="medium"
                width="300px"
                renderTags={() => null}
              />

              <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                {!!values.assignedCategories?.length ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        marginLeft: 'auto',
                      }}
                    >
                      <Button variant="link" color="secondary" onClick={() => setFieldValue('assignedCategories', [])}>
                        Clear All
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                      }}
                    >
                      {values.assignedCategories.map(assignedCategory => {
                        if (
                          assignedCategory.confirmationStatus === ConfirmationStatusV3DTO.DismissedConfirmationStatusV3
                        ) {
                          return null
                        }

                        return (
                          <Box key={assignedCategory.id}>
                            {assignedCategory?.method &&
                            assignedCategory?.method !== LabelMethodV3DTO.ManualLabelMethodV3 ? (
                              <LabelChip
                                PopperProps={{
                                  disablePortal: false,
                                }}
                                title={assignedCategory?.category?.name || ''}
                                variant={LabelChipVariant.category}
                                color={
                                  assignedCategory?.confidence === ConfidenceLevelV3DTO.MediumConfidenceLevelV3
                                    ? LabelChipColor.fairly_confident
                                    : LabelChipColor.confident
                                }
                                isDone={
                                  assignedCategory?.confirmationStatus !==
                                    ConfirmationStatusV3DTO.NoneConfirmationStatusV3 || undefined
                                }
                                labelDetails={
                                  <Box display="flex" flexDirection="column" gap={1}>
                                    <Box>
                                      <Typography variant="body">{assignedCategory?.category?.description}</Typography>
                                    </Box>
                                    {!!assignedCategory?.categorySource?.categorySourceCode && (
                                      <Box>
                                        <Typography variant="label" color={theme.palette.darkGrey.main}>
                                          Source:
                                        </Typography>{' '}
                                        <Typography variant="label" color={theme.palette.white.main}>
                                          {assignedCategory?.categorySource?.categorySourceCode}
                                        </Typography>
                                        <br />
                                        <Typography variant="body">
                                          {assignedCategory?.categorySource?.categorySourceDescription}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                }
                                onConfirm={() =>
                                  handleConfirmation(
                                    assignedCategory?.id,
                                    ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
                                  )
                                }
                                onDismiss={() =>
                                  handleConfirmation(
                                    assignedCategory?.id,
                                    ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
                                  )
                                }
                              />
                            ) : (
                              <Tooltip
                                title={
                                  <Typography variant="body">{assignedCategory?.category?.description}</Typography>
                                }
                              >
                                <Box>
                                  <Chip
                                    label={assignedCategory?.category?.name ?? ''}
                                    onDelete={() => {
                                      setFieldValue(
                                        'assignedCategories',
                                        values.assignedCategories.filter(
                                          assignedCategorya =>
                                            assignedCategorya?.categoryId !== assignedCategory?.categoryId,
                                        ),
                                      )
                                    }}
                                    deleteIcon={<Icon name="OCross" />}
                                  />
                                </Box>
                              </Tooltip>
                            )}
                          </Box>
                        )
                      })}
                    </Box>
                  </>
                ) : (
                  <EmptyState title="No Data Categories" subTitle="Use the search above to add categories." />
                )}
              </Box>
            </Box>
          </PopUp>
        )
      }}
    </Formik>
  )
}
