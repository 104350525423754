import { isUndefined } from 'lodash'
import { ICanvasStep } from '../../interfaces'

export const getStepTransitions = (step: ICanvasStep) => {
  return [
    step?.placeholder?.transition ||
      step?.activity?.transition ||
      step?.workflow?.transition ||
      step?.start?.transition ||
      step?.gateway?.transitions?.filter(s => !isUndefined(s))?.map(({ ID }) => ID),
  ].flat() // Flatten array because gateways will produce string[] verusus the standard string
}
