import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import React, { useContext } from 'react'
import { PreferenceField, isFieldRequired, fieldNames } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { InterpolatedTextArea } from 'pages/consentAndRights/experiences/upsert/components/common/interpolatedTextArea/InterpolatedTextArea'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'

type Props = {}
export const PreferencesFields: React.FC<Props> = () => {
  const { sidebarActiveTab } = useContext(ExperienceUpsertContext)
  return (
    <>
      {sidebarActiveTab === PreferenceField.PREFERENCES_TAB_NAME && (
        <FormRow marginBottom={24}>
          <FormInput
            required={isFieldRequired({ field: PreferenceField.PREFERENCES_TAB_NAME })}
            fullWidth
            name={fieldNames.preference.consents.tabName.root}
            label="Display Text"
          />
        </FormRow>
      )}

      {sidebarActiveTab === PreferenceField.PREFERENCES_BODY_TITLE && (
        <FormRow marginBottom={24}>
          <FormInput
            required={isFieldRequired({ field: PreferenceField.PREFERENCES_BODY_TITLE })}
            fullWidth
            name={fieldNames.preference.consents.bodyTitle.root}
            label="Display Text"
          />
        </FormRow>
      )}

      {sidebarActiveTab === PreferenceField.PREFERENCES_BODY_DESCRIPTION && (
        <FormRow marginBottom={24}>
          <InterpolatedTextArea
            required={isFieldRequired({ field: PreferenceField.PREFERENCES_BODY_DESCRIPTION })}
            label="Display Text"
            name={fieldNames.preference.consents.bodyDescription.root}
          />
        </FormRow>
      )}

      {sidebarActiveTab === PreferenceField.PREFERENCES_SUBMIT_BUTTON && (
        <FormRow marginBottom={24}>
          <FormInput
            required={isFieldRequired({ field: PreferenceField.PREFERENCES_SUBMIT_BUTTON })}
            fullWidth
            name={fieldNames.preference.consents.buttonText.root}
            label="Display Text"
          />
        </FormRow>
      )}
    </>
  )
}
