import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'

import { Text } from 'components/ui-kit/typography/Text'
import { ReactComponent as DataUsageWidgetIcon } from 'assets/icons/data-usage-widget-icon.svg'
import { DateRangePicker } from '.'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import numeral from 'numeral'
import { Link } from 'components/ui-kit/link/Link'
import { RoutesManager } from 'utils/routing/routesManager'
import { useAssetsPaginatedV2 } from 'api/assets/queries/useAssetsPaginatedV2'
import { staticDescendingArray } from '../__mocks__/generateDescendingArray'
import { AssetTypeIcon } from 'pages/assetManager/components'

const PREFIX = 'DataUsageWidget'

const classes = {
  container: `${PREFIX}-container`,
  widgetIcon: `${PREFIX}-widgetIcon`,
  nameCell: `${PREFIX}-nameCell`,
  queriesCountCell: `${PREFIX}-queriesCountCell`,
  dataSetIcon: `${PREFIX}-dataSetIcon`,
  rowClassName: `${PREFIX}-rowClassName`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    background: palette.white.main,
    borderRadius: spacing(1),
    padding: spacing(3),
  },

  [`& .${classes.widgetIcon}`]: {
    height: 48,
    width: 48,
  },

  [`& .${classes.nameCell}`]: {
    alignItems: 'center',
  },

  [`& .${classes.queriesCountCell}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  [`& .${classes.dataSetIcon}`]: {
    height: 18,
    width: 18,
    marginRight: spacing(1.5),
  },

  [`& .${classes.rowClassName}`]: {
    minHeight: 52,
  },
}))

type Props = {}

export const DataUsageWidget: React.FC<Props> = props => {
  const navigate = useNavigate()

  const {
    data: assets,
    isLoading: isLoadingAssets,
    pagination: assetsPagination,
  } = useAssetsPaginatedV2({
    itemsPerPage: 5,
    params: {},
  })

  return (
    <StyledBox className={classes.container}>
      {/* Title Row */}

      <Link variant="buttonWrap" to={RoutesManager.insights.activityAndAccess.dataUsage.root.getURL()}>
        <Box display="flex" alignItems="center" gap={1.5} mb={3}>
          <DataUsageWidgetIcon className={classes.widgetIcon} />
          <Box display="flex" flexDirection="column" flex={1}>
            <Text size={20} weight={700} mb={1.25}>
              Data Usage
            </Text>
            <Text size={14} color="darkDuskFaded">
              Most actively used data assets within the organization.
            </Text>
          </Box>
        </Box>
      </Link>
      <Box display="flex" alignItems="center" mb={3}>
        <DateRangePicker />
      </Box>

      <TablePaginated
        variant="page"
        isSmallHeaderText
        pagination={assetsPagination}
        pending={isLoadingAssets}
        items={assets}
        rowClassName={classes.rowClassName}
        onRowClick={(assetSummary, index) => {
          const targetPath = RoutesManager.insights.activityAndAccess.dataUsage.view.root.getURL({
            code: assetSummary?.asset?.resource?.id,
          })
          navigate(`${targetPath}?index=${index}`)
        }}
        cellSettings={{
          name: {
            label: 'Name',
            width: 370,
            className: classes.nameCell,
            cellRenderer: ({ asset }) => {
              const displayName = asset?.resource?.name || 'Unknown'
              return (
                <Box display="flex" alignItems="center">
                  <AssetTypeIcon
                    assetType={asset?.resource?.resourceType?.canonicalResourceType || 0}
                    className={classes.dataSetIcon}
                  />
                  <Text size={14} lineHeight={2.5}>
                    {displayName}
                  </Text>
                </Box>
              )
            },
          },
          queriesCount: {
            width: 110,
            label: 'Queries',
            className: classes.queriesCountCell,
            cellRenderer: (assetSummary, index) => {
              const percent = staticDescendingArray[index + assetsPagination?.page * 5] / 100
              const totalRequests = 43958

              return (
                <Box display="flex" alignItems="center">
                  <Text size={14} lineHeight={2.5}>
                    {numeral(totalRequests * percent).format('0,0')}
                  </Text>
                </Box>
              )
            },
          },
          queriesProgress: {
            cellRenderer: (assetSummary, index) => {
              const percent = staticDescendingArray[index + assetsPagination?.page * 5]

              return (
                <Box display="flex" alignItems="center">
                  <Box
                    sx={{
                      width: `${(percent / 100) * 102}px`,
                      height: 8,
                      borderRadius: 5,
                      backgroundColor: 'heliotrope.main',
                    }}
                  />
                </Box>
              )
            },
          },
        }}
      />
    </StyledBox>
  )
}
