import React, { useState } from 'react'
import { NotFoundTracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { Button, PopUp } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { Box, Typography } from '@mui/material'
import { useDeleteNotFoundTracker } from 'api/trackers/mutations/useDeleteNotFoundTracker'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import pluralize from 'pluralize'

type Props = {
  tracker: NotFoundTracker
}

export const NotFoundTrackerDelete: React.FC<Props> = ({ tracker }) => {
  const queryClient = useQueryClient()
  const [shouldRemove, setShouldRemove] = useState(false)
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])

  const { mutateAsync: deleteNotFoundTracker, isLoading: isDeletingNotFoundTracker } = useDeleteNotFoundTracker({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite])
      await queryClient.invalidateQueries([ApiQueryKeys.notFoundTrackersInfinite])
      await queryClient.invalidateQueries([ApiQueryKeys.trackerStatistics])
      showToast({ content: 'Removed tracker', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to remove tracker', type: 'error' })
    },
  })

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {isPermittedToWriteCookie ? (
        <Button
          disabled={isDeletingNotFoundTracker}
          color="secondary"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            setShouldRemove(true)
          }}
        >
          Remove {tracker?.numProperties}
        </Button>
      ) : null}

      {shouldRemove ? (
        <PopUp
          title={`Remove ${tracker?.key}`}
          popUpWidth="550px"
          variant="dialog"
          onClose={() => setShouldRemove(false)}
          popUpActionChildren={
            <>
              <Button color="secondary" size="large" onClick={() => setShouldRemove(false)}>
                Cancel
              </Button>
              <Button
                color="primary"
                size="large"
                onClick={() => deleteNotFoundTracker({ params: { encodedTrackerKey: tracker?.encodedKey } })}
              >
                Remove {tracker?.numProperties}
              </Button>
            </>
          }
        >
          <Box>
            <Typography variant="body">{`Are you sure you want to remove ${tracker?.key} on ${tracker?.numProperties} ${pluralize(
              'property',
              tracker?.numProperties,
            )}?`}</Typography>
          </Box>
        </PopUp>
      ) : null}
    </Box>
  )
}
