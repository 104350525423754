import React, { useMemo } from 'react'
import { styled } from '@mui/material/styles'

import { ExperienceDTO } from 'interfaces/experiences/experience'
import { ExperienceType } from 'interfaces/experiences/experienceType'
import { DeploymentPlanFormSpecialValues } from 'pages/consentAndRights/deploymentPlans/upsert/utils'
import { FormDropList } from 'components/ui-kit/form/dropList/FormDropList'
import { Text } from 'components/ui-kit/typography/Text'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'

const PREFIX = 'DeploymentPlanExperienceAssignment'

const classes = {
  noExperienceText: `${PREFIX}-noExperienceText`,
}

const Root = styled('div')(({ theme: { palette } }) => ({
  [`& .${classes.noExperienceText}`]: {
    color: palette.sphere.main,
  },
}))

type Props = {
  experienceType: ExperienceType
  policyScopeCode: string
  experiences: ExperienceDTO[]
}

export const DeploymentPlanExperienceAssignment: React.FC<Props> = ({
  policyScopeCode,
  experienceType,
  experiences,
}) => {
  const name = `policyScopeExperiences.${policyScopeCode}.${experienceType}.experienceID`

  const items = useMemo(
    () => [
      {
        ID: DeploymentPlanFormSpecialValues.NO_EXPERIENCE,
        name: DeploymentPlanFormSpecialValues.NO_EXPERIENCE,
        code: DeploymentPlanFormSpecialValues.NO_EXPERIENCE,
      },
      ...experiences.filter(({ consent, preference }) =>
        experienceType === ExperienceType.CONSENT ? !!consent : !!preference,
      ),
    ],
    [experiences, experienceType],
  )

  return (
    <Root>
      <FormDropList
        valueKey="ID"
        name={name}
        items={items}
        placeholder="Select Experience"
        renderValue={({ ID, name, code }) =>
          ID === DeploymentPlanFormSpecialValues.NO_EXPERIENCE ? (
            <Text className={classes.noExperienceText} size={14} weight={600} lineHeight={1.43}>
              {name}
            </Text>
          ) : (
            <NameAndCodeCellRenderer name={name} code={code} />
          )
        }
        renderItem={({ ID, name, code }) => (
          <NameAndCodeCellRenderer
            name={name}
            {...(ID !== DeploymentPlanFormSpecialValues.NO_EXPERIENCE && { code })}
          />
        )}
      />
    </Root>
  )
}
