import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { ListLayout, EmptyState, Icon, IconDictionary } from '@ketch-com/deck'
import { GetTrackerStatisticsResponseBody } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { ReactComponent as DuckDuckGo } from 'assets/images/DuckDuckGo.svg'
import { NotFoundTrackers } from 'pages/policyCenter/trackers/list/needsReviewTrackers/notFoundTrackers'
import { TrackersOnNewProperties } from 'pages/policyCenter/trackers/list/needsReviewTrackers/trackersOnNewProperties'
import { TrackersWithNewPurposes } from 'pages/policyCenter/trackers/list/needsReviewTrackers/trackersWithNewPurposes/TrackersWithNewPurposes'

type Props = {
  trackerStatistics: GetTrackerStatisticsResponseBody
}

export const NeedsReviewTrackers: React.FC<Props> = ({ trackerStatistics }) => {
  const [currentTab, setCurrentTab] = useState(0)
  const { numTrackersOnNewProperties = 0, numTrackersWithNewPurposes = 0, numNotFoundTrackers = 0 } = trackerStatistics
  const needsReviewStatistics = numTrackersOnNewProperties + numTrackersWithNewPurposes + numNotFoundTrackers
  const tabs = [
    {
      label: { label: `Tracker Found on New Property - ${numTrackersOnNewProperties}` },
      component: <TrackersOnNewProperties trackerStatistics={trackerStatistics} />,
      order: numTrackersOnNewProperties,
    },
    {
      label: { label: `Tracker Purpose Updated - ${numTrackersWithNewPurposes}` },
      component: <TrackersWithNewPurposes trackerStatistics={trackerStatistics} />,
      order: numTrackersWithNewPurposes,
    },
    {
      label: { label: `Tracker Not Found - ${numNotFoundTrackers}` },
      component: <NotFoundTrackers trackerStatistics={trackerStatistics} />,
      order: numNotFoundTrackers,
    },
  ]
  const filteredTabs = tabs.filter(tab => tab.order > 0)
  const orderedTabs = filteredTabs.map(tab => tab.label)
  const orderedComponents = filteredTabs.map(tab => tab.component)

  useEffect(() => {
    if (
      Number(!!numTrackersOnNewProperties) + Number(!!numTrackersWithNewPurposes) + Number(!!numNotFoundTrackers) ===
      1
    )
      setCurrentTab(0)
  }, [numTrackersOnNewProperties, numTrackersWithNewPurposes, numNotFoundTrackers])

  if (!needsReviewStatistics)
    return (
      <ListLayout headerTitle="Needs Review">
        <Box my={5}>
          <EmptyState
            size="medium"
            customIcon={<Icon name={IconDictionary.OCheckRound2} width={64} height={64} />}
            title="All Caught Up"
            subTitle="No trackers to review at the moment."
          />
        </Box>
      </ListLayout>
    )

  return (
    <ListLayout
      headerTitle="Needs Review"
      tabsComponentProps={{
        tabItems: orderedTabs,
        value: currentTab,
        onChange: (event: React.SyntheticEvent, newValue: number) => {
          setCurrentTab(newValue)
        },
      }}
    >
      <Box>{orderedComponents[currentTab]}</Box>
      <Box display="flex" alignItems="center" gap={1} my={2} position="absolute" bottom={0} left={32}>
        <Typography variant="smallBody" color="darkDuskFaded.main">
          In partnership with
        </Typography>
        <DuckDuckGo />
      </Box>
    </ListLayout>
  )
}
