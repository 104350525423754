import { useState } from 'react'
import { Typography } from '@mui/material'
import pluralize from 'pluralize'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useFetchSql } from 'api/assets/queries/useFetchSql'
import { Tabs } from 'components/ui-kit/tabs/Tabs'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { getAssetType } from 'pages/assetManager/utils'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'
import { theme } from '@ketch-com/deck'

type Props = {
  rightCode: string
  assetSummary: MaybeNull<AssetSummaryDTO>
  scenarioId: string
  onCancel: () => void
}

enum SqlView {
  PRIMARY = 'primary',
  RELATED = 'Related',
}

const PREFIX = 'SQLPreviewModal'

const classes = {
  tabs: `${PREFIX}-tabs`,
  tab: `${PREFIX}-tab`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.tabs}`]: {
    width: '100%',
    borderBottom: `1px solid ${palette.iron.main}`,
    minHeight: '36px',
  },

  [`& .${classes.tab}`]: {
    minHeight: '36px',
  },
}))

export const SQLPreviewModal: React.FC<Props> = ({ rightCode, assetSummary, scenarioId, onCancel }) => {
  const [viewSql, setViewSql] = useState(SqlView.PRIMARY)
  const { id: datasetId, name: datasetName } = assetSummary?.asset?.resource || {}
  const providerCode = assetSummary?.asset?.connection?.providerCode || ''
  const datasetType = getAssetType(providerCode, 2)

  const { data: sqlData, isLoading: isSqlLoading } = useFetchSql({
    params: {
      start: 0,
      limit: 100,
      rightCode,
      datasetId,
      scenarioId,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch queries', type: 'error' })
      onCancel()
    },
    enabled: viewSql === SqlView.PRIMARY,
  })

  return (
    <Dialog
      isLoading={isSqlLoading}
      title={`${datasetName} SQL Queries`}
      contentWidth={774}
      cancelBtnLabel="Close"
      onCancelBtnClick={onCancel}
    >
      {!isSqlLoading ? (
        <StyledBox>
          <Tabs
            tabClassName={classes.tab}
            className={classes.tabs}
            config={[
              {
                key: SqlView.PRIMARY,
                active: viewSql === SqlView.PRIMARY,
                title: `${datasetType} SQL`,
                onClick: () => {
                  setViewSql(SqlView.PRIMARY)
                },
              },
              {
                key: SqlView.RELATED,
                active: viewSql === SqlView.RELATED,
                title: `Related ${pluralize(datasetType, sqlData?.relatedAssets?.length || 0)} SQL`,
                onClick: () => {
                  setViewSql(SqlView.RELATED)
                },
              },
            ]}
            size="regular"
          />
          {viewSql === SqlView.PRIMARY ? (
            <Box>
              {sqlData?.primaryAsset?.sqlString?.length ? (
                sqlData?.primaryAsset?.sqlString.map((sql: string, index) => (
                  <pre
                    key={index}
                    style={{
                      marginBottom: 12,
                      padding: 12,
                      whiteSpace: 'pre-wrap',
                      backgroundColor: theme.palette.fadedGrey.main,
                    }}
                  >
                    {sql}
                  </pre>
                ))
              ) : (
                <pre
                  style={{
                    padding: 12,
                    whiteSpace: 'pre-wrap',
                    backgroundColor: theme.palette.fadedGrey.main,
                  }}
                >
                  No SQL generated for this configuration.
                </pre>
              )}
            </Box>
          ) : null}
          {viewSql === SqlView.RELATED ? (
            sqlData?.relatedAssets?.length ? (
              sqlData?.relatedAssets?.map(asset => {
                return (
                  <Box key={asset.datasetId} my={2}>
                    <Typography variant="label" component="div">
                      {asset.datasetName}
                    </Typography>

                    {asset?.sqlString?.map((sql: string, index) => (
                      <pre
                        key={index}
                        style={{
                          marginBottom: 12,
                          padding: 12,
                          whiteSpace: 'pre-wrap',
                          backgroundColor: theme.palette.fadedGrey.main,
                        }}
                      >
                        {sql}
                      </pre>
                    ))}
                  </Box>
                )
              })
            ) : (
              <Box>
                <pre style={{ padding: 12, whiteSpace: 'pre-wrap', backgroundColor: theme.palette.fadedGrey.main }}>
                  No related SQL found.
                </pre>
              </Box>
            )
          ) : null}
        </StyledBox>
      ) : null}
    </Dialog>
  )
}
