import React from 'react'
import numeral from 'numeral'

import { MaybeNull } from 'interfaces/common'
import { Text } from 'components/ui-kit/typography/Text'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'

type Props = {
  value?: MaybeNull<number>
  format?: string
}

export const FormattedNumberRenderer: React.FC<Props> = ({ value, format = '0,0' }) => {
  if (!value) {
    return <EmptyValueRenderer />
  }

  return <Text size={14}>{numeral(value).format(format)}</Text>
}
