import React, { useState, useMemo } from 'react'
import { useFormik, FormikProvider } from 'formik'
import { Editor, Transforms, Element } from 'slate'

import {
  useSlateInstance,
  wrapLink,
  getInitialValues,
  validationSchema,
} from 'pages/consentAndRights/experiences/upsert/components/common/interpolatedTextArea/utils'
import { Modal } from 'components/ui-kit/modal/Modal'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRadioGroup } from 'components/ui-kit/form/radioGroup/FormRadioGroup'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { Box } from '@mui/material'
import { LinkUrlType, LINK_URL_TYPE_OPTIONS } from '../constants'
import { LinkElementFormValues } from '../interfaces'

type Props = {
  isEditMode?: boolean
  element?: Element
  onClose: () => void
}

export const DocumentLinkerModal: React.FC<Props> = ({ isEditMode = false, element, onClose }) => {
  const editor = useSlateInstance()

  // Save initial selection
  const [selection] = useState(
    () =>
      editor.selection ||
      (() => {
        const endPoint = Editor.end(editor, [])

        return { anchor: endPoint, focus: endPoint }
      })(),
  )

  // Calculated ones intentionally.
  const initialValues = useMemo(
    () => getInitialValues({ isEditMode, element, editor }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onSubmit = (values: LinkElementFormValues) => {
    const { linkText, linkUrl, customUrl } = values

    if (!editor.selection) {
      // Restore initial selection
      Transforms.select(editor, selection)
    }

    wrapLink({
      editor,
      url: linkUrl === LinkUrlType.CUSTOM ? customUrl : linkUrl,
      // If text wasn't changed we are not replacing it
      ...(initialValues.linkText !== linkText && { text: linkText }),
    })

    onClose()
  }

  const formik = useFormik({
    validateOnMount: true,
    validationSchema,
    initialValues,
    onSubmit,
  })

  const { values, submitForm } = formik

  return (
    <FormikProvider value={formik}>
      <Modal
        title={isEditMode ? 'Edit Link' : 'Link Document'}
        onCancelBtnClick={onClose}
        submitBtnLabel="Confirm"
        onSubmitBtnClick={submitForm}
      >
        <Box mt={2.25}>
          <FormRow>
            <FormInput autofocus required fullWidth name="linkText" label="Text to display" />
          </FormRow>

          <FormRow>
            <FormRadioGroup label="Link to" name="linkUrl" layout="column" options={LINK_URL_TYPE_OPTIONS} />
          </FormRow>

          {values.linkUrl === LinkUrlType.CUSTOM && (
            <FormRow>
              <FormInput required fullWidth name="customUrl" placeholder="Specify link URL here" />
            </FormRow>
          )}
        </Box>
      </Modal>
    </FormikProvider>
  )
}
