import { Icon } from '@ketch-com/deck'
import { Box, Typography, styled } from '@mui/material'
import React from 'react'

export enum CheckboxVariant {
  on,
  off,
  disabled,
}

const ContainerBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '10px',
})

const CheckBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '20px',
  height: '20px',
  padding: '2px',
  background: 'black',
  borderRadius: '3px',
})

export type BuilderCheckboxProps = {
  variant?: CheckboxVariant
  onBackgroundColor?: string
  onTextColor?: string
  offBackgroundColor?: string
  offTextColor?: string
  errorBorderColor?: string
  errorTextColor?: string
  label?: string | React.ReactNode
  errorLabel?: string | React.ReactNode
  hasError?: boolean
}

export const BuilderCheckbox: React.FC<BuilderCheckboxProps> = ({
  variant = CheckboxVariant.on,
  onBackgroundColor = 'black',
  onTextColor = 'black',
  offBackgroundColor = 'rgba(7, 26, 36, 0.56)', // Not a deck color
  offTextColor = 'black',
  errorBorderColor = '#F00000',
  errorTextColor = '#F00000',
  label = '',
  errorLabel = '',
  hasError = false,
}) => {
  const isOn = variant === CheckboxVariant.on
  const isDisabled = variant === CheckboxVariant.disabled
  const backgroundColor = isOn ? onBackgroundColor : offBackgroundColor

  return (
    <ContainerBox>
      <Box display="flex" alignItems="flex-start" gap="10px">
        <CheckBox
          sx={{
            background: backgroundColor,
            opacity: isDisabled ? '0.3' : '1.0',
            border: `2px solid ${hasError ? errorBorderColor : backgroundColor}`,
          }}
        >
          <Box width={24} height={24}>
            {isOn && <Icon name="OCheck" iconColor="white" width="24px" height="24px" />}
          </Box>
        </CheckBox>
        <Typography
          variant="label"
          fontWeight={isOn ? 600 : 400}
          color={hasError ? errorTextColor : isOn ? onTextColor : offTextColor}
          sx={{ opacity: isDisabled ? '0.3' : '1.0', pt: '1px' }}
        >
          {label}
        </Typography>
      </Box>
      {hasError && errorLabel && (
        <Typography fontSize={12} fontWeight={500} color={errorTextColor}>
          {errorLabel}
        </Typography>
      )}
    </ContainerBox>
  )
}
