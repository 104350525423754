const templateVarRegex = /{([^}]+)}/g

export const parseStringWithTemplateVars = (input: string): string[] => {
  const result: string[] = []
  let currentIndex = 0
  // Find all occurrences of template variables in the input string
  let match
  while ((match = templateVarRegex.exec(input)) !== null) {
    // Add the substring before the template variable to the result array
    result.push(input.substring(currentIndex, match.index))
    // Add the template variable to the result array
    result.push(match[0])
    // Update the current index to continue searching from the end of the template variable
    currentIndex = match.index + match[0].length
  }
  // Add the remaining substring after the last template variable to the result array
  result.push(input.substring(currentIndex))
  return result
}
