import { useApprovedTrackerPropertiesInfinite } from 'api/trackers/queries/useApprovedTrackerPropertiesInfinite'
import { useState } from 'react'
import { MaybeNull } from 'interfaces'
import { TrackerKeys } from 'pages/policyCenter/trackers/interfaces'

export const useApprovedTrackerPropertiesUtils = () => {
  const [trackerKeys, setTrackerKeys] = useState<MaybeNull<TrackerKeys>>(null)
  const {
    trackerProperties,
    isLoading: isTrackerPropertiesLoading,
    isFetching: isFetchingTrackerProperties,
    isFetchingNextPage: isFetchingNextTrackerProperties,
    hasNextPage: hasNextTrackerProperties,
    fetchNextPage: fetchNextTrackerProperties,
  } = useApprovedTrackerPropertiesInfinite({
    enabled: !!trackerKeys?.encodedTrackerKey,
    limit: 20,
    encodedTrackerKey: trackerKeys?.encodedTrackerKey || '',
  })

  const isReady = !isTrackerPropertiesLoading

  return {
    isReady,
    trackerProperties,
    isTrackerPropertiesLoading,
    isFetchingTrackerProperties,
    isFetchingNextTrackerProperties,
    hasNextTrackerProperties,
    fetchNextTrackerProperties,
    trackerKeys,
    setTrackerKeys,
  }
}
