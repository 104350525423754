import { theme } from '@ketch-com/deck'

export const dataSystemProgressIndicatorStyles = {
  bgcolor: 'transparent',
  fgcolor: theme.palette.Highlight.PrimaryDark,
  highlightColor: theme.palette.Highlight.Disabled,
}

export const SYSTEM_PROTOCOL_MAXIMUM =
  'You have reached the maximum number of connected systems/protocols allowed under your current plan. To upgrade your account, please contact us at support@ketch.com.'
