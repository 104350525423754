import React from 'react'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import {
  ProgressiveExperienceConsentLayout,
  ProgressiveExperiencePosition,
  ProgressiveExperienceType,
} from '@ketch-sdk/ketch-types'
import {
  ProgressiveExperienceConsentLayoutLabels,
  ProgressiveExperiencePositionLabels,
  ProgressiveExperienceTypeLabels,
} from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { SidebarFormRadioGroup } from '../SidebarFormRadioGroup'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { FormTextInput } from '../FormTextInput'
import { Link } from '@mui/material'
import { RoutesManager } from 'utils/routing/routesManager'

export const ProgressiveExperienceContainerForm: React.FC = () => {
  const { values } = useFormikContext<ExperienceV2DTO>()
  const showConsentLayout =
    getIn(values, experienceUpsertFormKeys.progressiveExperienceType) === ProgressiveExperienceType.Consent
  const showEmbedElementId =
    getIn(values, experienceUpsertFormKeys.progressiveExperiencePosition) === ProgressiveExperiencePosition.Embed

  return (
    <SidebarFormGroup sx={{ pt: 0, gap: '8px' }}>
      <SidebarFormRadioGroup
        name={experienceUpsertFormKeys.progressiveExperienceType}
        title={'Type'}
        options={Object.values(ProgressiveExperienceType).map(experienceType => ({
          label: ProgressiveExperienceTypeLabels[experienceType],
          value: experienceType,
        }))}
        row
      />
      {showConsentLayout && (
        <SidebarFormRadioGroup
          name={experienceUpsertFormKeys.progressiveExperienceConsentLayout}
          title={'Consent Layout'}
          options={Object.values(ProgressiveExperienceConsentLayout).map(consentLayout => ({
            label: ProgressiveExperienceConsentLayoutLabels[consentLayout],
            value: consentLayout,
          }))}
          row
        />
      )}
      <SidebarFormRadioGroup
        name={experienceUpsertFormKeys.progressiveExperiencePosition}
        title={'Position'}
        options={Object.values(ProgressiveExperiencePosition).map(position => ({
          label: ProgressiveExperiencePositionLabels[position],
          value: position,
        }))}
        row
      />
      {showEmbedElementId && (
        <FormTextInput
          name={experienceUpsertFormKeys.progressiveExperienceEmbedElementId}
          label={'Element ID'}
          fullWidth
          charLimit={50}
          placeholder="my-element-id"
          helperText={
            <>
              Ketch will automatically insert the experience into the element with this ID. See our{' '}
              <Link
                // Open in new tab to avoid losing experience changes
                onClick={() => window.open(RoutesManager.deployment.applications.root.getURL(), '_blank', 'noreferrer')}
                sx={{ cursor: 'pointer' }}
              >
                Rule Builder
              </Link>{' '}
              to define when the experience should be inserted.
            </>
          }
        />
      )}
    </SidebarFormGroup>
  )
}
