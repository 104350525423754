import { DsrFormPolicyStatements } from 'store/dsrTransponderFormSlice'

type Args = {
  activeDatabase: string
  policyStatements: DsrFormPolicyStatements
  attributeCode: string
  updateValue: string
}

export const getUpdateCustomValuePayload = ({
  activeDatabase,
  policyStatements,
  attributeCode,
  updateValue,
}: Args): DsrFormPolicyStatements => ({
  ...policyStatements,
  [activeDatabase]: {
    ...policyStatements?.[activeDatabase],
    resource: activeDatabase,
    statements: {
      ...policyStatements?.[activeDatabase]?.statements,
      [`${attributeCode}`]: {
        ...policyStatements?.[activeDatabase]?.statements?.[attributeCode],
        value: updateValue,
      },
    },
  },
})
