import {
  Avatar,
  AvatarSize,
  Button,
  Chip,
  DataGrid,
  Icon,
  PopUp,
  TabSegmented,
  TabSegmentedGroup,
  TableCell,
} from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { Team } from 'interfaces/services/harbormaster/teams_gen.schemas'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import React, { useEffect } from 'react'
import { TempAssignmentType } from 'api/users/fetchers/fetchWorkflowAssignments'
import pluralize from 'pluralize'
import { useDeleteTeam } from 'api/teams/queries/useDeleteTeam'
import { showToast } from 'components/modals/AlertComponent'
import { AssigneeOrTeamSelector } from 'components/AssigneeSelector/AssigneeOrTeamSelector'
import { useReassignWorkflowStep } from 'api/teams/queries/useReassignWorkflowStep'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { getWorkflowStepIconNameFromActivityCode } from 'pages/orchestration/utils/getWorkflowStepIconNameFromActivityCode'
import { useAuth } from 'utils/hooks'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import {
  InstalledDataSystemV2DTO,
  ProcessingActivityDTO,
  ProcessingActivityTypeDTO,
  TeamDTO,
} from '@ketch-com/figurehead'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { useEditProcessingActivity } from 'api/processingActivities/mutations/useEditProcessingActivity'
import { useUpdateInstalledDataSystem } from 'api/dataSystems/mutations/useUpdateInstalledDataSystem'
import { AssigneeFormatDelimter, TeamPrefix } from 'utils/helpers/teamStringParser'

export type ViewTabs = 'workflowActivities' | 'systems' | 'assessments' | 'processingActivities'
export type ViewModes = 'view' | 'delete' | 'none'

type Props = {
  team: Team
  mode: ViewModes
  onClose: () => void
  teamModalTab: ViewTabs
  setTeamModalTab: (tab: ViewTabs) => void
  teamAssignments?: TempAssignmentType[]
  processingActivities?: ProcessingActivityDTO[]
  assignedSystems?: InstalledDataSystemV2DTO[]
}

const useAssignmentsModalUtils = (
  onClose: () => void,
  team: Team,
  teamAssignments?: TempAssignmentType[],
  processingActivities?: ProcessingActivityDTO[],
  assignedSystems?: InstalledDataSystemV2DTO[],
) => {
  const queryClient = useQueryClient()
  const { userData } = useAuth()

  const { mutateAsync: deleteTeam, isLoading: isDeleting } = useDeleteTeam({
    onSuccess: () => {
      showToast({ type: 'success', content: 'Deleted team successfully' })
      queryClient.refetchQueries([ApiQueryKeys.teams])
      onClose()
    },
    onError: () => showToast({ type: 'error', content: 'Encountered error while attempting to delete team' }),
  })

  const { mutateAsync: reassignStep, isLoading: isReassigningStep } = useReassignWorkflowStep({
    onSuccess: () => {
      showToast({ type: 'success', content: 'Reassigned step successfully' })
      queryClient.refetchQueries([ApiQueryKeys.teamAssignments, { id: team.id, orgCode: userData.organizationCode }])
    },
    onError: () => showToast({ type: 'error', content: 'Encountered error while attempting to reassign step' }),
  })

  const { mutate: handleEditProcessingActivity, isLoading: isReassigningActivity } = useEditProcessingActivity({
    onSuccess: async ({ data: response }) => {
      const teamsToRefetch = [team, ...(response.processingActivity?.teams || [])]

      teamsToRefetch.map(t =>
        queryClient.refetchQueries([
          ApiQueryKeys.processingActivitiesPaginated,
          {
            teamIds: [t.id],
            includeMetadata: true,
          },
        ]),
      )

      showToast({ content: 'Reassigned processing activity', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Unable to reassign processing activity', type: 'error' })
    },
  })

  const { mutateAsync: handleEditDataSystem, isLoading: isReassigningSystem } = useUpdateInstalledDataSystem({
    onSuccess: async ({ data: response }) => {
      showToast({ content: `Reassigned Data System`, type: 'success' })
      const responseDataSystemTeams = (response.installedDataSystem?.ownerUserIds || []).filter(
        id => id.startsWith(TeamPrefix) && !id.includes(AssigneeFormatDelimter),
      )
      const teamsToRefetch = [team.id, ...responseDataSystemTeams]

      teamsToRefetch.map(teamId =>
        queryClient.refetchQueries([
          ApiQueryKeys.installedDataSystems,
          {
            ownerUserIds: teamId,
            limit: 300,
            start: 0,
          },
        ]),
      )
    },
    onError: () => {
      showToast({ content: `Unable to Reassign System`, type: 'error' })
    },
  })

  const reassignSystem = (team: TeamDTO, system: InstalledDataSystemV2DTO, newAssigneeId?: string) => {
    const isAssigningToSameTeam = team.id === newAssigneeId

    // New system owners = all its previous, plus the new ID (if not being unassigned), minus the current team's ID unless the same team was selected
    const newOwners = Array.from(
      new Set([...(system.ownerUserIds || []), ...(newAssigneeId ? [newAssigneeId] : [])]),
    ).filter(id => id !== team.id || isAssigningToSameTeam)

    handleEditDataSystem({
      params: {
        id: system.id!,
        formData: { installedDataSystem: { ...system, ownerUserIds: newOwners } },
      },
    })
  }

  const reassignProcessingActivity = (team: TeamDTO, processingActivity: ProcessingActivityDTO, newTeamId?: string) => {
    const isAssigningToSameTeam = team.id === newTeamId

    // New teams = all its previous, plus the new ID (if not being unassigned), minus the current team's ID unless the same team was selected
    const newTeams = Array.from(
      new Set([...(processingActivity.teams || []), ...(newTeamId ? [{ id: newTeamId }] : [])]),
    ).filter(({ id }) => id !== team.id || isAssigningToSameTeam)

    handleEditProcessingActivity({
      params: {
        id: processingActivity.id || '',
        formData: { processingActivity: { ...processingActivity, teams: newTeams } },
      },
    })
  }

  const assignmentsColumns: GridColDef<TempAssignmentType>[] = [
    {
      field: 'activities',
      headerName: 'Activities',
      width: 175,
      renderCell: ({ row: assignment }: GridRenderCellParams<TempAssignmentType>) => {
        const iconName = getWorkflowStepIconNameFromActivityCode(assignment.activityCode)

        return (
          <Box display="flex" gap={1} alignItems="center">
            <Icon name={iconName} />
            <TableCell title={assignment.name} subTitle={assignment.description} />
          </Box>
        )
      },
    },
    {
      field: 'workflow',
      headerName: 'Workflow',
      width: 225,
      renderCell: ({ row: assignment }: GridRenderCellParams<TempAssignmentType>) => {
        return <TableCell title={assignment.workflowDefinitionName} subTitle={assignment.workflowDefinitionCode} />
      },
    },
    {
      field: 'assignee',
      headerName: 'Assignee',
      sortable: false,
      width: 310,
      renderCell: ({ row: assignment }: GridRenderCellParams<TempAssignmentType>) => {
        return (
          <TableCell>
            <AssigneeOrTeamSelector
              selectedAssigneeId={team.id}
              onChange={newAssigneeId =>
                reassignStep({
                  params: {
                    assignee: newAssigneeId,
                    workflowCode: assignment.workflowDefinitionCode,
                    stepId: assignment.ID,
                  },
                })
              }
            />
          </TableCell>
        )
      },
    },
  ]

  const systemsColumns: GridColDef<InstalledDataSystemV2DTO>[] = [
    {
      field: 'system',
      headerName: 'System',
      width: 380,
      renderCell: ({ row: system }: GridRenderCellParams<InstalledDataSystemV2DTO>) => {
        return (
          <Box display="flex" alignItems="center">
            <Avatar
              isLogo
              src={system.dataSystem?.logoUrl}
              alt={system.dataSystem?.name}
              size={AvatarSize.xlarge}
              variant="rounded"
            />
            <TableCell title={system.dataSystem?.name} subTitle={system.description} />
          </Box>
        )
      },
    },
    {
      field: 'dataOwner',
      headerName: 'Data Owner',
      sortable: false,
      width: 300,
      renderCell: ({ row: system }: GridRenderCellParams<InstalledDataSystemV2DTO>) => {
        return (
          <TableCell>
            <AssigneeOrTeamSelector
              selectedAssigneeId={team.id}
              onChange={newAssigneeId => reassignSystem(team, system, newAssigneeId)}
            />
            <Button
              sx={{ ml: 1 }}
              color="secondary"
              pending={isReassigningSystem}
              onClick={() => reassignSystem(team, system, undefined)}
            >
              Unassign
            </Button>
          </TableCell>
        )
      },
    },
  ]

  const processingActivitiesColumns: GridColDef<ProcessingActivityDTO>[] = [
    {
      field: 'activities',
      headerName: 'Processing Activities',
      width: 200,
      renderCell: ({ row: processingActivity }: GridRenderCellParams<ProcessingActivityDTO>) => {
        return (
          <NameAndCodeCellRenderer
            name={processingActivity.name}
            code={
              processingActivity.metadata?.createdAt ? (
                <FormattedDateRenderer date={processingActivity.metadata?.createdAt} />
              ) : undefined
            }
          />
        )
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      renderCell: ({ row: processingActivity }: GridRenderCellParams<ProcessingActivityDTO>) => {
        const label =
          processingActivity.type === ProcessingActivityTypeDTO.ModelProcessingActivityType
            ? 'AI / Model'
            : ProcessingActivityTypeDTO.StandardProcessingActivityType
            ? 'Standard Processing'
            : 'Unspecified'
        return <Chip label={label} />
      },
    },
    {
      field: 'assignee',
      headerName: 'Assignee',
      sortable: false,
      width: 300,
      renderCell: ({ row: processingActivity }: GridRenderCellParams<ProcessingActivityDTO>) => {
        return (
          <TableCell>
            <AssigneeOrTeamSelector
              onlyShowTeams
              selectedAssigneeId={team.id}
              onChange={newTeamId => reassignProcessingActivity(team, processingActivity, newTeamId)}
            />
            <Button
              sx={{ ml: 1 }}
              color="secondary"
              pending={isReassigningActivity}
              onClick={() => reassignProcessingActivity(team, processingActivity, undefined)}
            >
              Unassign
            </Button>
          </TableCell>
        )
      },
    },
  ]

  return {
    assignmentsColumns,
    teamAssignments,
    assignedSystems,
    processingActivities,
    deleteTeam,
    isDeleting,
    isReassigning: isReassigningStep || isReassigningActivity || isReassigningSystem,
    systemsColumns,
    processingActivitiesColumns,
  }
}

const workflowActivityTextAddendum =
  'Note, re-assignments will only be applied to newly invoked workflows, all inflight assignments will remain assigned to their current assignee.'

export const TeamAssignmentsModal: React.FC<Props> = ({
  mode = 'delete',
  team,
  onClose,
  teamModalTab,
  setTeamModalTab,
  teamAssignments,
  processingActivities,
  assignedSystems,
}) => {
  const isDelete = mode === 'delete'
  const { assignmentsColumns, systemsColumns, processingActivitiesColumns, isDeleting, isReassigning, deleteTeam } =
    useAssignmentsModalUtils(onClose, team, teamAssignments, processingActivities, assignedSystems)

  const hasWorkflowActivities = teamAssignments?.length ? 1 : 0
  const hasAssignedSystems = assignedSystems?.length ? 1 : 0
  const hasProcessingActivities = processingActivities?.length ? 1 : 0

  const totalTabs = hasWorkflowActivities + hasAssignedSystems + hasProcessingActivities

  const isDeleteDisabled = totalTabs !== 0

  // Close the modal when no more steps are assigned if not a delete modal
  useEffect(() => {
    if (totalTabs === 0 && !isDelete) {
      onClose()
    }
  }, [totalTabs, isDelete, onClose])

  // If the user is on a tab that no longer has data, redirect to a tab that does
  useEffect(() => {
    const tabToDefaultTo = hasWorkflowActivities
      ? 'workflowActivities'
      : hasAssignedSystems
      ? 'systems'
      : 'processingActivities'

    if (
      (!hasProcessingActivities && teamModalTab === 'processingActivities') ||
      (!hasAssignedSystems && teamModalTab === 'systems') ||
      (!hasWorkflowActivities && teamModalTab === 'workflowActivities')
    ) {
      setTeamModalTab(tabToDefaultTo)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalTabs])

  const title = isDelete ? 'Delete Team' : 'Assignments'
  const subtitle = isDelete
    ? isDeleteDisabled
      ? `Before deleting this team, please re-assign the items below. ${
          teamModalTab === 'workflowActivities' ? workflowActivityTextAddendum : ''
        }`
      : `Are you sure you want to delete ${team.name || 'this team'}? This change cannot be undone.`
    : teamModalTab === 'workflowActivities'
    ? `This Team is assigned to ${
        (teamAssignments?.length || 0) < 2 ? 'an activity' : 'activities'
      } in the following ${pluralize('Workflow', teamAssignments?.length || 0)}. ${workflowActivityTextAddendum}`
    : teamModalTab === 'systems'
    ? `This Team owns the following ${pluralize('System', assignedSystems?.length || 0)}.`
    : `This Team owns the following ${pluralize('Processing Activity', processingActivities?.length || 0)}.`

  const cancelButtonText = isDelete ? 'Cancel' : 'Close'

  // Data grid requires lowercase `id` field :(
  const workflowActivityRows = teamAssignments?.map(activity => ({ ...activity, id: activity.ID })) || []

  return (
    <PopUp
      onClose={onClose}
      isLoading={isReassigning}
      title={title}
      popUpWidth="775px"
      popUpActionChildren={
        <>
          <Button size="large" color="secondary" onClick={onClose} disabled={isDeleting}>
            {cancelButtonText}
          </Button>
          {isDelete ? (
            <Button
              size="large"
              disabled={isDeleteDisabled}
              pending={isDeleting}
              onClick={() => deleteTeam({ params: { id: team.id } })}
            >
              Delete
            </Button>
          ) : null}
        </>
      }
    >
      <Typography variant="body">{subtitle}</Typography>

      {totalTabs > 1 ? (
        <Box>
          <TabSegmentedGroup
            value={teamModalTab}
            exclusive
            onChange={(_, value) => setTeamModalTab(value)}
            size="small"
          >
            {hasWorkflowActivities ? (
              <TabSegmented value={'workflowActivities' as ViewTabs} key="1">
                Assigned Activities
              </TabSegmented>
            ) : null}
            {hasAssignedSystems ? (
              <TabSegmented value={'systems' as ViewTabs} key="2">
                Owned Systems
              </TabSegmented>
            ) : null}
            {hasProcessingActivities ? (
              <TabSegmented value={'processingActivities' as ViewTabs} key="3">
                Processing Activities
              </TabSegmented>
            ) : null}
          </TabSegmentedGroup>
        </Box>
      ) : null}

      {hasWorkflowActivities && teamModalTab === 'workflowActivities' ? (
        <DataGrid
          columns={assignmentsColumns}
          rows={workflowActivityRows}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowHoverStates
          disableRowSelectionOnClick
          disableMultipleRowSelection
          hideFooter
        />
      ) : null}

      {hasAssignedSystems && teamModalTab === 'systems' ? (
        <DataGrid
          columns={systemsColumns}
          rows={assignedSystems || []}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowHoverStates
          disableRowSelectionOnClick
          disableMultipleRowSelection
          hideFooter
        />
      ) : null}

      {hasProcessingActivities && teamModalTab === 'processingActivities' ? (
        <DataGrid
          columns={processingActivitiesColumns}
          rows={processingActivities || []}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowHoverStates
          disableRowSelectionOnClick
          disableMultipleRowSelection
          hideFooter
        />
      ) : null}
    </PopUp>
  )
}
