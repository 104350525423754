import React from 'react'
import Typography from '@mui/material/Typography'
import { MessageAttributes } from '../../../interfaces'
import { Message } from '@twilio/conversations'
import { userNoLongerExistsColors } from 'components/ui-kit/avatar/avatarColorBucket'
import { useAuth } from 'utils/hooks'
import { Avatar, AvatarSize } from '@ketch-com/deck'
import { Box } from '@mui/material'

type Props = {
  message: Message
  bgColor?: string
  fgColor?: string
}

export const InternalMessageAuthor: React.FC<Props> = ({
  message,
  bgColor = userNoLongerExistsColors?.bg,
  fgColor = userNoLongerExistsColors?.fg,
}) => {
  const { userData } = useAuth()
  const authorFirstInitial = (message?.attributes as MessageAttributes)?.firstName?.charAt(0)
  const authorLastInitial = (message?.attributes as MessageAttributes)?.lastName?.charAt(0)
  const authorFirstName = (message?.attributes as MessageAttributes)?.firstName
  const authorLastName = (message?.attributes as MessageAttributes)?.lastName

  // handle case when it's an automated system message not sent by an identifiable user
  const initials =
    !authorFirstInitial && !authorLastInitial
      ? `${userData?.organizationName?.charAt(0) || ''}T`
      : `${authorFirstInitial}${authorLastInitial}`
  const authorFullName =
    !authorFirstInitial && !authorLastInitial
      ? `${userData?.organizationName} Team`
      : `${authorFirstName} ${authorLastName}`

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Avatar size={AvatarSize.small} backgroundColor={bgColor} textColor={fgColor}>
        {initials}
      </Avatar>
      <Typography variant="smallLabel">{authorFullName}</Typography>
    </Box>
  )
}
