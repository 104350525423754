import { useState } from 'react'
import { useDebounce } from 'react-use'

import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useRelatedAssetsPaginatedV2 } from 'api/assets/queries/useRelatedAssetsPaginatedV2'
import { useCreateDsrPolicy } from 'api/dsrPolicy/mutations/useCreateDsrPolicy'
import { ICanvasStep, IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { getClientSidePolicyRepresentation, getCreateDsrPolicyFormData, getRightsInvocationType } from '../utils'
import { useDsrPolicies } from 'api/dsrPolicy/queries/useDsrPolicies'
import { PolicyDTO } from '@ketch-com/figurehead'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { hydrateFormState } from 'store/dsrTransponderFormSlice'
import React from 'react'
import { getDsrFormState, getPolicyStatements, getSelectedDatabases } from 'store/dsrTransponderFormSlice/selectors'
import { useFormikContext } from 'formik'
import { useAssetsParallelV2 } from 'api/assets/queries/useAssetsParallelV2'
import { useAssetsPaginatedV2 } from 'api/assets/queries/useAssetsPaginatedV2'
import { ASSET_TYPE_ENUM } from 'interfaces/assets'

type Args = {
  databaseCode?: string
  step: ICanvasStep
  handleSave: (params: { step: ICanvasStep }) => void
}

export const useDsrTransponderFormUtils = ({ handleSave, step }: Args) => {
  const dispatch = useAppDispatch()

  // redux state
  const policyStatements = useAppSelector(getPolicyStatements)
  const selectedDatabases = useAppSelector(getSelectedDatabases)
  const { code, description } = useAppSelector(getDsrFormState)

  // local state
  const [isShowSelectedDatasets, setIsShowSelectedDatasets] = useState(false)
  const [activeDatabase, setActiveDatabase] = useState('')
  const [configureDatasetModalCode, setConfigureDatasetModalCode] = useState<string | undefined>(undefined)
  const [formViewType, setFormViewType] = useState('gui')
  const [isAddDatabasesModalOpen, setIsAddDatabasesModalOpen] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [attributesSearchString, setAttributesSearchString] = useState('')
  const [datasetSearchString, setDatasetSearchString] = useState('')
  const [hasMounted, setHasMounted] = useState(() => false)
  const [datasetQueryString, setDatasetQueryString] = useState('')
  const [attributesQueryString, setAttributesQueryString] = useState('')

  const dsrWorkflowPolicyIdsArray = [...(step?.workflow?.params?.policies || []).map(p => p?.policyID)]
  const { dsrPolicies, isLoading: isLoadingDsrPolicies } = useDsrPolicies(dsrWorkflowPolicyIdsArray)

  const { values: formikValues } = useFormikContext<IWorkflowConfig>()

  React.useEffect(() => {
    if (!isLoadingDsrPolicies && !!step && !hasMounted) {
      setHasMounted(true)
      const formInitialState = getClientSidePolicyRepresentation({
        policies: dsrPolicies,
        step,
        canonicalRightCode: formikValues?.canonicalRightCode || '',
      })
      dispatch(hydrateFormState(formInitialState))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingDsrPolicies, dispatch, step, hasMounted, formikValues?.canonicalRightCode])

  const { mutateAsync: handleCreateDsrPolicy } = useCreateDsrPolicy({
    onError: () => {
      showToast({ content: 'Failed to create dsr policies', type: 'error' })
    },
  })

  const onSubmit = async () => {
    const formData = getCreateDsrPolicyFormData({
      policyStatements,
      canonicalRightCode: formikValues?.canonicalRightCode || '',
    })

    await handleCreateDsrPolicy({
      params: {
        formData,
      },
      onSuccess: ({ data }) => {
        const paramsPayload = (data?.policies || []).reduce(
          (acc: any, policy: PolicyDTO) => ({
            ...acc,
            policies: [...(acc.policies || []), { policyID: policy.id, resourceCode: policy.resource }],
          }),
          {},
        )
        handleSave({
          step: {
            ...step,
            code,
            description,
            valid: true,
            workflow: {
              ...step.workflow,
              params: {
                ...paramsPayload,
                rightInvocationType: getRightsInvocationType(formikValues?.canonicalRightCode || ''),
              },
            } as ICanvasStep['workflow'],
          },
        })
      },
      onError: () => {
        showToast({ content: 'Failed to save workflow step', type: 'error' })
      },
    })
  }

  const { data: databasesResources, isLoading: isLoadingDatabaseResources } = useAssetsPaginatedV2({
    itemsPerPage: 1000,
    params: {
      canonicalResourceType: ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATABASE,
    },
  })

  const {
    pagination: relatedDatasetsPagination,
    data: relatedDatasets,
    isLoading: isLoadingRelatedDatasets,
  } = useRelatedAssetsPaginatedV2({
    enabled: !!activeDatabase,
    itemsPerPage: 50,
    params: {
      canonicalResourceType: ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATASET,
      assetCode: activeDatabase,
    },
  })

  const {
    pagination: relatedAttributesPagination,
    data: relatedAttributes,
    isLoading: isLoadingRelatedAttributes,
  } = useRelatedAssetsPaginatedV2({
    enabled: !!configureDatasetModalCode,
    itemsPerPage: 10,
    params: {
      canonicalResourceType: ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_ATTRIBUTE,
      assetCode: configureDatasetModalCode,
    },
  })

  const selectedDatasetObject = (relatedDatasets || []).find(d => d?.resource?.id === configureDatasetModalCode)

  useDebounce(
    () => {
      if (datasetSearchString) {
        setDatasetQueryString(datasetSearchString)
      } else {
        setDatasetQueryString('')
      }
    },
    500,
    [datasetSearchString],
  )

  useDebounce(
    () => {
      if (attributesSearchString) {
        setAttributesQueryString(attributesSearchString)
      } else {
        setAttributesQueryString('')
      }
    },
    500,
    [attributesSearchString],
  )

  const {
    pagination: relatedDatasetsSearchedPagination,
    data: relatedDatasetsSearched,
    isLoading: isLoadingRelatedDatasetsSearched,
  } = useRelatedAssetsPaginatedV2({
    enabled: !!datasetQueryString,
    itemsPerPage: 50,
    params: {
      canonicalResourceType: ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATASET,
      assetCode: activeDatabase,
      query: datasetQueryString,
    },
  })

  const {
    pagination: relatedAttributesSearchPagination,
    data: relatedAttributesSearch,
    isLoading: isLoadingRelatedAttributesSearch,
  } = useRelatedAssetsPaginatedV2({
    enabled: !!configureDatasetModalCode,
    itemsPerPage: 10,
    params: {
      canonicalResourceType: ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_ATTRIBUTE,
      assetCode: configureDatasetModalCode,
      query: attributesQueryString,
    },
  })

  const selectedDatasetsIds = Object.entries(policyStatements?.[activeDatabase]?.statements || {})
    .filter(([, value]) => {
      return value.assetType === 'dataset'
    })
    .map(([key]) => key)

  const { assetsParallel, isLoading: isLoadingAssetsParallel } = useAssetsParallelV2(selectedDatasetsIds)

  return {
    activeDatabase,
    attributesSearchString,
    configureDatasetModalCode,
    databasesResources,
    datasetSearchString,
    formViewType,
    isAddDatabasesModalOpen,
    isLoadingDatabaseResources,
    isLoadingDsrPolicies,
    isLoadingRelatedAttributes: attributesQueryString ? isLoadingRelatedAttributesSearch : isLoadingRelatedAttributes,
    isLoadingRelatedDatasets: isShowSelectedDatasets
      ? isLoadingAssetsParallel
      : datasetQueryString
      ? isLoadingRelatedDatasetsSearched
      : isLoadingRelatedDatasets,
    isShowSelectedDatasets,
    onSubmit,
    relatedAttributes: attributesQueryString ? relatedAttributesSearch : relatedAttributes,
    relatedAttributesPagination: attributesQueryString
      ? relatedAttributesSearchPagination
      : relatedAttributesPagination,
    relatedDatasets: isShowSelectedDatasets
      ? assetsParallel
      : datasetQueryString
      ? relatedDatasetsSearched
      : relatedDatasets,
    relatedDatasetsPagination: isShowSelectedDatasets
      ? { ...relatedDatasetsPagination, totalPages: 0 }
      : datasetQueryString
      ? relatedDatasetsSearchedPagination
      : relatedDatasetsPagination,
    searchString,
    selectedDatasetObject,
    selectedDatabases,
    setActiveDatabase,
    setConfigureDatasetModalCode,
    setDatasetSearchString,
    setFormViewType,
    setIsAddDatabasesModalOpen,
    setSearchString,
    setIsShowSelectedDatasets,
    setAttributesSearchString,
  }
}
