import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Typography } from '@mui/material'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/modals/AlertComponent'
import { useDeleteVerifier } from 'api/verifiers/mutations/useDeleteVerifier'
import { useVerifierUsage } from 'api/verifiers/queries/useVerifierUsage'
import { IdentitySpaceShortDTO, VerifierDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { Button, DataGrid, PopUp, Spinner, TableCell } from '@ketch-com/deck'
import { GridRowParams } from '@mui/x-data-grid'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

type Props = {
  verifier: VerifierDTO
  onSubmit?: () => void
  onCancel: () => void
}

export const VerifierDeleteModal: React.FC<Props> = ({ onSubmit, onCancel, verifier }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data: verifierUsageData, isLoading: isLoadingVerifierUsage } = useVerifierUsage({
    params: {
      verifierId: verifier.id,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch verifier usage', type: 'error' })
    },
  })

  const { mutateAsync: handleDeleteVerifier, isLoading: isMutating } = useDeleteVerifier({
    onSuccess: async () => {
      onSubmit?.()
      showToast({ content: 'Verifier deleted', type: 'success' })
      await queryClient.refetchQueries([ApiQueryKeys.verifiersPaginated])
    },
    onError: () => {
      showToast({ content: 'Failed to delete verifier', type: 'error' })
    },
  })

  const verifierHasAssociatedIdentities = !!verifierUsageData?.identitySpaces?.length
  return (
    <PopUp
      variant="dialog"
      title="Delete Token Verifier"
      popUpWidth="550px"
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isMutating} onClick={onCancel}>
            Close
          </Button>
          {!verifierHasAssociatedIdentities && (
            <Button
              color="primary"
              size="large"
              pending={isMutating}
              onClick={() => {
                handleDeleteVerifier({
                  params: {
                    verifierId: verifier.id,
                  },
                })
              }}
            >
              Delete
            </Button>
          )}
        </>
      }
      onClose={onCancel}
    >
      {isLoadingVerifierUsage ? (
        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : verifierHasAssociatedIdentities ? (
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="body">
            Token Verifier has associated Identifiers. To delete a token Verifier please update the association.
          </Typography>
          <DataGrid
            sx={{
              cursor: 'pointer',
              '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                backgroundImage: 'unset',
                cursor: 'auto',
              },
              '&.MuiDataGrid-root': {
                '& .MuiDataGrid-overlayWrapper': {
                  height: !verifierUsageData?.identitySpaces?.length ? '300px' : 'auto',
                },
              },
            }}
            autosizeOnMount
            autosizeOptions={{
              expand: true,
              includeHeaders: true,
              includeOutliers: false,
            }}
            getRowHeight={() => 'auto'}
            columns={[
              {
                align: 'left',
                field: 'identitySpace',
                flex: 1,
                headerAlign: 'left',
                headerName: 'Associated Identifiers',
                sortable: false,
                renderCell: ({ row }: GridRenderCellParams<IdentitySpaceShortDTO>) => {
                  return <TableCell title={row.name} subTitle={row.code} />
                },
              },
            ]}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowSelectionOnClick
            getRowId={row => row.id}
            rows={verifierUsageData?.identitySpaces || []}
            hideFooter
            noRowsOverlayProps={{
              buttonTitle: '',
              subTitle: '',
            }}
            onRowClick={({ row: { code } }: GridRowParams<IdentitySpaceShortDTO>) => {
              navigate(RoutesManager.settings.identitySpaces.view.root.getURL({ code }))
            }}
          />
        </Box>
      ) : (
        <Typography variant="body">
          Are you sure you want to delete <Typography variant="label">{verifier.name}</Typography>?
        </Typography>
      )}
    </PopUp>
  )
}
