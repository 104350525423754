// layout utils
import { ProtectedRoute } from 'components/ui-routing/ProtectedRoute'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { relativePath } from 'utils/routing/relativePath'
import { RoutesManager } from 'utils/routing/routesManager'

import { EventsListV2Container } from 'pages/orchestration/events/listV2/EventsListContainer'
import { CreateEventContainer } from 'pages/orchestration/events/create/CreateEventContainer'

import { WorkflowsListContainer } from 'pages/orchestration/workflows/list/WorkflowsListContainer'
import { WorkflowViewContainer } from 'pages/orchestration/workflows/view/WorkflowViewContainer'
import { EditWorkflowContainer } from 'pages/orchestration/workflows/edit/EditWorkflowContainer'

import { Redaction } from 'pages/orchestration/rightsQueue/redaction/Redaction'
import { RightsQueueV2 } from 'pages/orchestration/rightsQueue/listV2/RightsQueueV2'
import { RightsQueueViewV2 } from './rightsQueue/viewV2/RightsQueueViewV2'

import { CreateRightInvocationContainer } from 'pages/orchestration/rightsQueue/create/CreateRightInvocationContainer'

import { WorkflowStepDetails } from './rightsQueue/stepDetails/WorkflowStepDetailsContainer'

export const orchestrationRoutesChildren = [
  {
    element: <ProtectedRoute component={EventsListV2Container} permissions={PERMISSIONS.WORKFLOW_READ} />,
    path: '',
  },
  {
    element: <ProtectedRoute component={EventsListV2Container} permissions={PERMISSIONS.WORKFLOW_READ} />,
    path: relativePath(RoutesManager.orchestration.events.root.pattern),
  },
  {
    element: <ProtectedRoute component={CreateEventContainer} permissions={PERMISSIONS.WORKFLOW_WRITE} />,
    path: relativePath(RoutesManager.orchestration.events.create.root.pattern),
  },
  {
    element: <ProtectedRoute component={WorkflowsListContainer} permissions={PERMISSIONS.WORKFLOW_READ} />,
    path: relativePath(RoutesManager.orchestration.workflows.root.pattern),
  },
  //
  // Rights Queue
  //
  {
    element: <ProtectedRoute component={RightsQueueV2} permissions={PERMISSIONS.RTINVQ_READ} />,
    path: relativePath(RoutesManager.orchestration.rightsQueue.root.pattern),
  },
  {
    element: <ProtectedRoute component={WorkflowStepDetails} permissions={PERMISSIONS.RTINVQ_READ} />,
    path: relativePath(RoutesManager.orchestration.rightsQueue.stepDetails.root.pattern),
  },
  {
    element: <ProtectedRoute component={RightsQueueViewV2} permissions={PERMISSIONS.RTINVQ_READ} />,
    path: relativePath(RoutesManager.orchestration.rightsQueue.view.root.pattern),
    children: [
      // overview
      {
        element: <ProtectedRoute component={RightsQueueViewV2} permissions={PERMISSIONS.RTINVQ_READ} />,
        path: relativePath(RoutesManager.orchestration.rightsQueue.view.overview.root.pattern),
      },
      // workflow
      {
        element: <ProtectedRoute component={RightsQueueViewV2} permissions={PERMISSIONS.RTINVQ_READ} />,
        path: relativePath(RoutesManager.orchestration.rightsQueue.view.workflow.root.pattern),
      },
      // subject-communication
      {
        element: <ProtectedRoute component={RightsQueueViewV2} permissions={PERMISSIONS.RTINVQ_READ} />,
        path: relativePath(RoutesManager.orchestration.rightsQueue.view.subjectCommunication.root.pattern),
      },
    ],
  },
  {
    element: <ProtectedRoute component={CreateRightInvocationContainer} permissions={PERMISSIONS.RTINVQ_WRITE} />,
    path: relativePath(RoutesManager.orchestration.rightsQueue.create.root.pattern),
  },
  {
    element: <ProtectedRoute component={Redaction} permissions={PERMISSIONS.REDACTION_WRITE} />,
    path: relativePath(RoutesManager.orchestration.rightsQueue.reviewData.root.pattern),
  },
  {
    element: <ProtectedRoute component={WorkflowViewContainer} permissions={PERMISSIONS.WORKFLOW_READ} />,
    path: `${relativePath(RoutesManager.orchestration.workflows.view.root.pattern)}/*`,
  },
  {
    element: <ProtectedRoute component={EditWorkflowContainer} permissions={PERMISSIONS.WORKFLOW_WRITE} />,
    path: relativePath(RoutesManager.orchestration.workflows.upsert.root.pattern),
  },
]
