import { UpsertRightInvocationDTO } from 'interfaces/rightInvocations'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  formData: UpsertRightInvocationDTO
}

export const invokeRight = ({ organizationCode, formData }: Params) =>
  API.post<{}>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/rights/invoke`,
    }),
    formData,
  )
