import React, { createContext } from 'react'
import { ExperiencePreviewUtils, useExperiencePreviewUtils } from '../utils/useExperiencePreviewUtils'

export const ExperiencePreviewContext = createContext({} as ExperiencePreviewUtils)

type Props = {
  children?: React.ReactNode | undefined
}

export const ExperiencePreviewContextProvider: React.FC<Props> = ({ children }) => {
  // Use utils hook
  const hookValues = useExperiencePreviewUtils()

  return <ExperiencePreviewContext.Provider value={{ ...hookValues }}>{children}</ExperiencePreviewContext.Provider>
}

export const withExperiencePreviewContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <ExperiencePreviewContextProvider>
        <Component {...props} />
      </ExperiencePreviewContextProvider>
    )
  }
}
