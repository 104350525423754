import React, { useState } from 'react'
import { GetAssetsStatisticsResponseBodyDTO, GetAssetsDataSystemsResponseBodyDTO } from '@ketch-com/figurehead'
import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'
import { Text } from 'components/ui-kit/typography/Text'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { Box } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { AssetsStatistics, AssetsDataSystems } from './components'
import { useAppDispatch } from 'store/hooks'
import { setSearchQuery, setFilterState, clearFilterState } from 'store/megaFilterStateSlice'
import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'
import { FILTER_STATES } from 'pages/assetManager/assets/list/components/MegaFilter/constants'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ReactComponent as EmptyDataSystemsIcon } from 'assets/icons/empty_data_systems.svg'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isLoading: boolean
  assetStatistics: GetAssetsStatisticsResponseBodyDTO
  assetsDataSystems: GetAssetsDataSystemsResponseBodyDTO
}

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: spacing(2),
        paddingBottom: spacing(3),
        borderBottom: `1px solid ${palette.iron.main}`,
      },
      bodyContainer: {
        backgroundColor: palette.white.main,
        borderRadius: 12,
        padding: spacing(3, 2),
      },
      assetStatisticsContainer: {
        display: 'flex',
        gap: spacing(2),
      },
      assetStatisticsItem: {
        backgroundColor: palette.superLightGrey.main,
        borderRadius: 16,
        padding: spacing(3),
        flex: 1,
      },
      assetTypeIcon: {
        marginRight: spacing(1),
        minWidth: 32,
        minHeight: 32,
      },
      assetStatisticsItemHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: spacing(1),
      },
    }),
  { name: 'AssetsHomePage' },
)

export const AssetsHomePage: React.FC<Props> = ({ isLoading, assetStatistics, assetsDataSystems }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const [searchString, setSearchString] = useState<string>('')
  const { dataSystems = 0, databases = 0, sensitiveDataAssets = 0, datasets = 0 } = assetStatistics
  const isEmptyData =
    !isLoading && !dataSystems && !databases && !sensitiveDataAssets && !datasets && !assetsDataSystems.totalResults

  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.systems.root.getURL() },
    { title: 'Assets', link: RoutesManager.assets.root.getURL() },
  ]

  return (
    <ContentBoundaries>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box className={classes.bodyContainer}>
        {isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" py={3}>
            <Box ml={4}>
              <Spinner />
            </Box>
          </Box>
        )}

        {!isLoading && !isEmptyData ? (
          <>
            <Box className={classes.headerContainer}>
              <Text size={28} weight={800}>
                Assets
              </Text>

              <SearchInput
                size="small"
                startSearchFromChar={0}
                debounceTimeout={0}
                value={searchString}
                placeholder="Search"
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    dispatch(setFilterState(FILTER_STATES.COLLAPSED))
                    dispatch(clearFilterState())
                    dispatch(setSearchQuery(searchString))
                    navigate(RoutesManager.assetManager.assets.list.getURL())
                  }
                }}
                onChange={() => {}}
                onSearch={nextValue => {
                  setSearchString(nextValue)
                }}
                onClear={() => setSearchString('')}
              />
            </Box>
            <AssetsStatistics assetStatistics={assetStatistics} />
            <AssetsDataSystems assetsDataSystems={assetsDataSystems} />
          </>
        ) : null}

        {!isLoading && isEmptyData ? (
          <EmptyState
            icon={<EmptyDataSystemsIcon />}
            variant="list"
            bgVariant="white"
            title="No Data Systems yet"
            description="Try adding data systems and you'll see them populated here."
            actions={[
              {
                title: 'Add System',
                variant: 'primary',
                size: 'big',
                onClick: () => navigate(RoutesManager.systems.create.root.getURL()),
              },
            ]}
          />
        ) : null}
      </Box>
    </ContentBoundaries>
  )
}
