import React, { useMemo } from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'
import { useField, FieldValidator } from 'formik'
import { isFunction, isNil } from 'lodash'

import { ObjectLiteral } from 'interfaces'
import { DropList, DropListProps } from 'components/ui-kit/dropList/DropList'
import { Error } from 'components/ui-kit/form/common/error/Error'

const useStyles = makeStyles(
  createStyles({
    root: {
      display: 'inline-block',
    },
    fullWidth: {
      display: 'block',
    },
  }),
  { name: 'FormDropList' },
)

type Props<T> = Omit<DropListProps, 'items' | 'value' | 'onOpen' | 'onClose' | 'valid' | 'name'> & {
  /** Formik field name */
  name: string
  /** Custom validation method */
  validate?: FieldValidator
  /** Value key refers to item value */
  valueKey?: string
  /** Array of items to render in menu */
  items: T[]
  /** Selected item renderer. Defaults to renderItem prop */
  renderValue?: (selectedItem: T) => React.ReactNode
  /** Item renderer */
  renderItem?: (item: T) => React.ReactNode
  /** Custom on change method */
  onChange?: (item: T) => void
}

export function FormDropList<T extends ObjectLiteral>({
  name,
  validate,
  valueKey = 'id',
  items,
  renderItem = ({ name }) => name,
  renderValue = renderItem,
  fullWidth = false,
  onChange,
  ...rest
}: Props<T>) {
  const classes = useStyles()
  const [{ value }, { touched, error }, { setTouched, setValue }] = useField({ name, validate })
  const showError = error && touched

  const onChangeInner = (item: T) => {
    const nextValue = item[valueKey]

    setValue(nextValue)

    // Outer onChange
    if (isFunction(onChange)) {
      onChange(item)
    }
  }

  const itemsMap = useMemo(
    () =>
      items.reduce<ObjectLiteral<T>>(
        (acc, item) => ({
          ...acc,
          [item[valueKey]]: item,
        }),
        {},
      ),
    [items, valueKey],
  )

  const hasValue = value !== '' && !isNil(value) && itemsMap[value]

  const componentProps = {
    ...rest,
    fullWidth,
    valid: !showError,
    items: items.map(item => ({
      active: item[valueKey] === value,
      content: renderItem(item),
      onClick: () => onChangeInner(item),
    })),
    onClose: () => {
      setTouched(true)
    },
    value: hasValue,
    ...(hasValue && {
      children: renderValue(itemsMap[value]),
    }),
  }

  return (
    <div id={name} className={clsx(classes.root, { [classes.fullWidth]: fullWidth })}>
      <DropList {...componentProps} />

      {showError && <Error>{error}</Error>}
    </div>
  )
}
