import { FC, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { ActionSheetItem, FormRow, ListItemText } from '@ketch-com/deck'
import { PolicyDocumentDTO, PolicyDocumentType } from 'interfaces/policyDocuments/policyDocument'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { ApplicationFormSpecialValues } from 'interfaces/applications/ApplicationFormValues'

interface ApplicationDocumentsProps {
  isEditMode?: boolean
  policyDocuments: PolicyDocumentDTO[]
}

type DocumentItem = {
  code: string
  name: string
}

export const ApplicationDocuments: FC<ApplicationDocumentsProps> = ({ isEditMode = false, policyDocuments }) => {
  const privacyPolicyItems: DocumentItem[] = useMemo(() => {
    const filteredItems = policyDocuments
      .filter(document => document.policyDocumentType === PolicyDocumentType.PRIVACY_POLICY)
      .map(document => ({
        code: document.code,
        name: document.name,
      }))
    return [
      ...(isEditMode && filteredItems.length === 0
        ? [{ code: ApplicationFormSpecialValues.NO_DOCUMENT, name: ApplicationFormSpecialValues.NO_DOCUMENT }]
        : []),
      ...filteredItems,
    ]
  }, [policyDocuments, isEditMode])

  const termsServiceItems: DocumentItem[] = useMemo(() => {
    const filteredItems = policyDocuments
      .filter(document => document.policyDocumentType === PolicyDocumentType.TERMS_OF_SERVICE)
      .map(document => ({
        code: document.code,
        name: document.name,
      }))
    return [
      { code: ApplicationFormSpecialValues.NO_DOCUMENT, name: ApplicationFormSpecialValues.NO_DOCUMENT },
      ...filteredItems,
    ]
  }, [policyDocuments])

  return (
    <FormRow hasTopBorder title="Documents">
      <Box>
        <FormDroplistButton
          width="468px"
          required
          disableClearable
          label="Privacy Policy"
          formPropertyName="privacyPolicyCode"
          placeholder="Select Document"
          items={privacyPolicyItems}
          valueKey="code"
          renderOption={(props, option, { selected }) => (
            <ActionSheetItem selected={selected} {...props}>
              <ListItemText selected={selected}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body">{option.name}</Typography>
                  <Typography variant="footnote">{option.code}</Typography>
                </Box>
              </ListItemText>
            </ActionSheetItem>
          )}
        />
      </Box>
      <Box mt={4}>
        <FormDroplistButton
          width="468px"
          disableClearable
          label="Terms of Service"
          formPropertyName="termsOfServiceCode"
          placeholder="Select Document"
          items={termsServiceItems}
          valueKey="code"
          renderOption={(props, option, { selected }) => (
            <ActionSheetItem selected={selected} {...props}>
              <ListItemText selected={selected}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body">{option.name}</Typography>
                  <Typography variant="footnote">{option.code}</Typography>
                </Box>
              </ListItemText>
            </ActionSheetItem>
          )}
        />
      </Box>
    </FormRow>
  )
}
