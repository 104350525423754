import { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useDataReviewFiles } from 'api/dataReview/queries/useDataReviewFiles'
import { useRightInvocationV2 } from 'api/rightInvocationsV2/queries/useRightInvocationV2'
import { useWorkflowExecution } from 'api/workflowExecutions/queries/useWorkflowExecution'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useIncludeDataReviewFile } from 'api/dataReview/mutations/useIncludeDataReviewFile'
import { useExcludeDataReviewFile } from 'api/dataReview/mutations/useExcludeDataReviewFile'
import { useUploadDataReviewFiles } from 'api/dataReview/mutations/useUploadDataReviewFiles'
import { isJson } from '../utils/isJson'
import { showToast } from 'components/modals/AlertComponent'
import { useDownloadFile } from 'api/files/mutations/useDownloadFile'
import { useWorkflowActivities } from 'api/workflows/queries/useWorkflowActivities'
import { DataReviewStepDTO } from '@ketch-com/figurehead'
import { FormFile } from 'api/dataReview/fetchers/uploadDataReviewFiles'
import { RoutesManager } from 'utils/routing/routesManager'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { ensureArray } from 'utils/helpers/array'
import { ReviewDataURLParams } from 'utils/routing/routes/utils'

export const useRedactionUtils = () => {
  const { getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()
  const { rightInvocationId, stepId, executionId } = useParams<ReviewDataURLParams>()

  const [userToken, setUserToken] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)

  // get right invocation
  const { data: rightInvocation, isLoading: isRightInvocationLoading } = useRightInvocationV2({
    enabled: !!rightInvocationId,
    params: {
      rightInvocationId: rightInvocationId as string,
      includeMetadata: true,
      includeIssues: true,
    },
    onError: () => {
      navigate(RoutesManager.orchestration.rightsQueue.root.getURL())
    },
  })

  // get workflow execution
  const { data: workflowExecution, isLoading: isWorkflowExecutionLoading } = useWorkflowExecution({
    enabled: !!executionId,
    params: {
      workflowExecutionId: executionId as string,
    },
  })
  const workflowExecutionStep = workflowExecution?.steps?.find(step => step.stepID === stepId)

  // get data redaction steps
  const { data: dataReviewSteps, isLoading: isDataReviewFilesLoading } = useDataReviewFiles({
    enabled: !!executionId && !!rightInvocationId,
    params: {
      workflowExecutionId: executionId as string,
      rightInvocationId: rightInvocationId as string,
    },
  })

  // set user token for PDFTron requests
  useEffect(() => {
    const getUserToken = async () => {
      const accessToken = await getAccessTokenSilently()
      setUserToken(accessToken)
    }

    getUserToken()
  }, [getAccessTokenSilently])

  const queryClient = useQueryClient()
  //
  // state
  //
  const [annotationManager, setAnnotationManager] = useState<any>(null)
  const [steps, setSteps] = useState<DataReviewStepDTO[]>([])
  const [documentViewer, setDocumentViewer] = useState<any>(null)
  const [activeFile, setActiveFile] = useState<FormFile>()
  const [files, setFiles] = useState<Array<FormFile>>([])
  const [isDocumentLoaded, setIsDocumentLoaded] = useState<boolean>(false)
  const [isFileFetching, setIsFileFetching] = useState<boolean>(false)
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [invalidFormatFiles, setInvalidFormatFiles] = useState<string[]>([])

  //
  // api hooks
  //

  // download raw data files
  const { mutateAsync: handleDownloadFile, isLoading: isDownloadingFile } = useDownloadFile()

  // include file in data subject folder
  const { mutate: handleIncludeDataReviewFile } = useIncludeDataReviewFile({
    onMutate: async () => {
      setIsSaving(true)
    },
    onSuccess: async ({ data }) => {
      showToast({ content: 'File included', type: 'success' })
      setActiveFile({
        ...activeFile,
        ...data,
        isExcluded: false,
      })
      await queryClient.refetchQueries(ApiQueryKeys.dataReview)
      setIsSaving(false)
    },
    onError: () => {
      showToast({ content: 'Failed to include file', type: 'error' })
      setIsSaving(false)
    },
  })

  // exclude file from data subject folder
  const { mutate: handleExcludeDataReviewFile } = useExcludeDataReviewFile({
    onMutate: async () => {
      setIsSaving(true)
    },
    onSuccess: async ({ data }) => {
      showToast({ content: 'File Excluded', type: 'success' })
      setActiveFile({
        ...activeFile,
        ...data,
        isExcluded: true,
      })
      await queryClient.refetchQueries(ApiQueryKeys.dataReview)
      setIsSaving(false)
    },
    onError: () => {
      showToast({ content: 'Failed to exclude file', type: 'error' })
      setIsSaving(false)
    },
  })

  // save redacted files
  const { mutateAsync: handleUploadDataReviewFile } = useUploadDataReviewFiles({
    onMutate: () => {
      setIsSaving(true)
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(ApiQueryKeys.dataReview)
      setFiles([])
      setActiveFile({
        ...activeFile,
        redactions: [],
        rawData: '',
      })
      showToast({ content: 'Saved', type: 'success' })
      setIsSaving(false)
    },
    onError: () => {
      showToast({ content: 'Failed to save', type: 'error' })
      setIsSaving(false)
    },
  })

  // get workflow activity (icon)
  const { data: workflowActivities, isLoading: isWorkflowActivitiesLoading } = useWorkflowActivities()
  const workflowActivity = workflowActivities.find(({ code }) => code === workflowExecutionStep?.activityStepCode)

  //
  // data utils
  //

  const getBlobData = async (file: FormFile) => {
    let data
    if (!isRawData(file?.name)) {
      // get doc and annotations from PDFtron
      const doc = documentViewer?.getDocument()

      // loop over all files and apply redactions
      annotationManager.enableRedaction()
      await annotationManager.applyRedactions()

      const xfdfString = await annotationManager.exportAnnotations()
      // if redactions save the document with annotations in it
      if (!xfdfString) return
      const redactedFile = await doc.getFileData({
        xfdfString,
      })
      data = new Uint8Array(redactedFile)
    } else {
      data = new TextEncoder().encode(file?.rawData)
    }
    // convert to blob for BE
    const blob = new Blob([data], { type: file?.type })
    return blob
  }

  // update form files
  const updateFiles = async (newFile: FormFile) => {
    // get blob data
    if (newFile?.rawData || !!newFile?.redactions?.length) {
      const data = await getBlobData(newFile!)
      if (data) {
        newFile = {
          ...newFile,
          data,
        }
      }
    }

    const newFiles = [...files]
    const i = files.findIndex(f => f?.id === newFile?.id)
    // if exists update else add
    if (i >= 0) {
      newFiles[i] = newFile
    } else {
      newFiles.push(newFile)
    }
    setFiles(newFiles)
    return newFiles
  }

  // on active file change update ref
  useEffect(() => {
    if (!activeFile) return
    fileRef.current = activeFile
  }, [activeFile])

  // on steps change update state
  useEffect(() => {
    if (!dataReviewSteps.length) return
    // update steps state
    setSteps(dataReviewSteps)
  }, [dataReviewSteps])

  //
  // api utils
  //
  const handleSaveFiles = async () => {
    const newFiles = await updateFiles(activeFile!)
    await handleUploadDataReviewFile({
      params: {
        executionId: rightInvocation?.workflowExecutionID,
        files: [...newFiles?.filter(f => !!f?.data && !f?.isExcluded)],
        requestId: rightInvocation?.id,
      },
    })
  }

  //
  // document utils
  //
  const isRawData = (fileName?: string) => fileName?.includes('json') || fileName?.includes('csv')

  const handleDocumentLoad = () => {
    const redactions = fileRef?.current?.redactions

    if (!redactions?.length) return
    // redraw annotations
    annotationManager.addAnnotations(redactions)
    // need to draw the annotations otherwise they won't show up until the page is refreshed
    annotationManager.drawAnnotationsFromList(redactions)
  }

  //
  // annotation utils
  //
  const getRedactions = (): Array<any> =>
    annotationManager.getAnnotationsList().filter((a: any) => a.Subject === 'Redaction')

  //
  // event handlers
  const handleFileRedaction = (annotation: any, action: string) => {
    const redaction = annotation[0]
    if ((redaction.Subject === 'Redaction' && action === 'add') || action === 'delete') {
      // cant access react state inside event listeners so use file ref
      const file = fileRef.current
      setActiveFile({
        ...file,
        redactions: getRedactions(),
      })
    }
  }

  //
  // API
  //
  const fetchFile = async (file: FormFile) => {
    // handle document viewer data
    try {
      setIsFileFetching(true)
      await documentViewer?.loadDocument(file?.url, {
        customHeaders: {
          Authorization: `Bearer ${userToken}`,
        },
        filename: file?.name,
        fullAPI: true,
        licenseKey: (window as any).figureheadConfig.REACT_APP_PDFTRON_KEY,
        loadAsPDF: true,
      })
      setIsFileFetching(false)
      setIsDocumentLoaded(true)
    } catch (error) {
      // close prev doc
      await documentViewer?.closeDocument()
      showToast({
        content: `Unable to load document ${file?.name}`,
        type: 'error',
      })
      setIsFileFetching(false)
    }
  }

  const fetchRawFile = async (file: FormFile) => {
    // close prev doc
    await documentViewer?.closeDocument()
    try {
      setIsFileFetching(true)
      const { data } = await handleDownloadFile({
        params: {
          fileUrl: file?.url || '',
          responseType: isJson(file?.name) ? 'json' : 'text',
        },
      })
      setIsFileFetching(false)
      return data
    } catch (error) {
      showToast({
        content: `Unable to download file ${file?.name}`,
        type: 'error',
      })
      setIsFileFetching(false)
    }
  }

  const loadRawFile = async (file: FormFile) => {
    setIsFileLoading(true)
    // see if new file exists in state
    const newFile = files?.find(f => !!f && f?.id === file?.id) || file
    // if active file not set or existing file missing rawData fetch
    if (!activeFile || !newFile?.rawData) {
      const data = await fetchRawFile(file)
      setActiveFile({
        ...file,
        // format data
        rawData: isJson(file?.name) ? JSON.stringify(data, null, 2) : data,
        redactions: [],
      })
    } else {
      // else use local state
      setActiveFile(newFile)
    }
    setIsFileLoading(false)
  }

  const loadFile = async (file: FormFile) => {
    setIsFileLoading(true)
    // file names/urls may change on the fly
    // grab latest file details from server
    // load redactions & blob data from state
    const formFile = files?.find(f => f?.id === file?.id)
    const newFile = { ...file, data: formFile?.data || '', redactions: formFile?.redactions || [] }
    // MUST set file ref FIRST so PDFTron has time to render the redaction objects
    fileRef.current = newFile
    await fetchFile(newFile)
    setActiveFile(newFile)
    setIsFileLoading(false)
  }

  const handleFileChange = async (file: FormFile) => {
    if (file?.id === activeFile?.id || isFileFetching) return
    setIsDocumentLoaded(false)

    await updateFiles(activeFile!)

    if (isRawData(file.name)) {
      await loadRawFile(file)
    } else {
      await loadFile(file)
    }
  }

  //
  // layout utils
  //

  // refs
  const fileRef = useRef(activeFile)
  const scrollView = useRef(null)
  const viewer = useRef(null)

  // utils
  const handleExclude = (isExcluded: boolean) => {
    const params = {
      executionId: rightInvocation?.workflowExecutionID,
      fileName: activeFile?.name,
      requestId: rightInvocationId,
      stepId: activeFile?.stepId,
    }
    isExcluded ? handleIncludeDataReviewFile({ params }) : handleExcludeDataReviewFile({ params })
  }

  const handleInvalidFormatFiles = (fileName: string, isValid: boolean) => {
    if (isValid) {
      setInvalidFormatFiles(invalidFormatFiles.filter(name => name !== fileName))
    } else if (!invalidFormatFiles?.includes(fileName || '')) {
      setInvalidFormatFiles([...invalidFormatFiles, fileName])
    }
  }

  const isLoading = isFileFetching || isFileLoading || isDownloadingFile || isWorkflowActivitiesLoading
  const isComplete = workflowExecutionStep?.dataReviewStatus === WorkflowExecutionStepStatus.SUCCESS
  const rightCode = rightInvocation?.rightName
  const rightName = rightInvocation?.rightName
  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    { title: 'Rights Queue', link: RoutesManager.orchestration.rightsQueue.root.getURL() },
    {
      title: rightName || rightCode || rightInvocationId,
      link: RoutesManager.orchestration.rightsQueue.view.root.getURL({ id: rightInvocationId }),
    },
    { title: 'Review Data' },
  ]

  const isReady = !isDataReviewFilesLoading && !isRightInvocationLoading && !isWorkflowExecutionLoading && !!userToken

  const totalRedactionCount = ensureArray(files).reduce(
    (acc, data) => acc + ((data && data?.redactions?.length) || 0),
    0,
  )

  const payload = {
    activeFile,
    annotationManager,
    breadcrumbs,
    dataReviewSteps,
    documentViewer,
    files,
    handleDocumentLoad,
    handleExclude,
    handleFileChange,
    handleFileRedaction,
    handleInvalidFormatFiles,
    handleSaveFiles,
    rightInvocationId,
    invalidFormatFiles,
    isComplete,
    isDocumentLoaded,
    isFileFetching,
    isLoading,
    isRawData,
    isReady,
    isSaving,
    rightInvocation,
    scrollView,
    setActiveFile,
    setAnnotationManager,
    setDocumentViewer,
    setShowModal,
    showModal,
    stepId,
    steps,
    totalRedactionCount,
    viewer,
    workflowActivity,
    workflowExecution,
    workflowExecutionStep,
  }

  return payload
}

export type RedactionUtils = ReturnType<typeof useRedactionUtils>
