import React, { useState } from 'react'
import { VerifierDeleteModal, VerifierUpsertModal } from 'pages/developers/verifiers/components'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { VerifierDTO } from '@ketch-com/figurehead'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { Box } from '@mui/material'

type Props = {
  verifier: VerifierDTO
}

export const VerifiersActionsCellRenderer: React.FC<Props> = ({ verifier }) => {
  const { isPermitted } = useIsPermitted()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isBusy] = useState(false)
  const [isVerifierUpsertModalOpen, setIsVerifierUpsertModalOpen] = useState(false)
  const isPermittedToEditVerifiers = isPermitted(PERMISSIONS.AUTHENTICATION_WRITE)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Button
        size="medium"
        variant="iconSubtle"
        onClick={handleClickListItem}
        disabled={isBusy || !isPermittedToEditVerifiers}
      >
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transitionDuration={0}
        onClick={e => e.stopPropagation()}
      >
        <ActionSheetItem
          onClick={e => {
            handleClose()
            setIsVerifierUpsertModalOpen(true)
          }}
        >
          Edit
        </ActionSheetItem>
        <ActionSheetItem divider />

        <ActionSheetItem
          onClick={e => {
            handleClose()
            setIsDeleteModalOpen(true)
          }}
        >
          Delete
        </ActionSheetItem>
      </ActionSheetWrapper>

      {isDeleteModalOpen && (
        <VerifierDeleteModal
          verifier={verifier}
          onCancel={() => setIsDeleteModalOpen(false)}
          onSubmit={() => setIsDeleteModalOpen(false)}
        />
      )}

      {isVerifierUpsertModalOpen && (
        <VerifierUpsertModal
          verifier={verifier}
          onCancel={() => setIsVerifierUpsertModalOpen(false)}
          onSubmit={() => setIsVerifierUpsertModalOpen(false)}
        />
      )}
    </Box>
  )
}
