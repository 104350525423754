import { useContext, useState } from 'react'

import { Box } from '@mui/material'
import { TooltipButton } from '@ketch-com/deck'
import { EndDetailsHeaderTimestamp } from './EndDetailsHeaderTimestamp'
import { FinalizeRequestModalV2 } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/EndFlow/components'
import { EndFlowContext } from '../context/EndFlowContext'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'

type Props = {}

export const EndDetailsHeaderActions: React.FC<Props> = () => {
  const [isFinalizeRequestModalOpen, setIsFinalizeRequestModalOpen] = useState(false)
  const { isAssignedToMe, workflowStep, stepDetails, isWorkflowExecutionTerminated } = useContext(EndFlowContext)

  const isNotSuccess = workflowStep?.status !== WorkflowExecutionStepStatus.SUCCESS
  const isInProgress = workflowStep?.status === WorkflowExecutionStepStatus.IN_PROGRESS
  const isComplete = workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS

  const tooltipTitle = isAssignedToMe ? '' : 'Only the assignee can finalize this request.'

  return (
    <Box display="flex" gap={1.5} alignItems="center">
      <EndDetailsHeaderTimestamp
        startedAt={stepDetails?.startedAt}
        updatedAt={stepDetails?.updatedAt}
        completedAt={stepDetails?.completedAt}
        status={stepDetails?.status}
      />

      {!isWorkflowExecutionTerminated && (isInProgress || isNotSuccess) && !isComplete && (
        <TooltipButton
          title={tooltipTitle}
          size="large"
          color="primary"
          onClick={() => setIsFinalizeRequestModalOpen(true)}
          disabled={!isAssignedToMe || isComplete}
        >
          Finalize Request
        </TooltipButton>
      )}

      {isFinalizeRequestModalOpen && <FinalizeRequestModalV2 onClose={() => setIsFinalizeRequestModalOpen(false)} />}
    </Box>
  )
}
