import { ProcessingActivityContext } from '../../context/ProcessingActivityContext'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, EmptyState } from '@ketch-com/deck'
import {
  ProcessingActivityAssetDTO,
  ProcessingActivityDataSystemDTO,
  ProcessingActivityDataSystemProcessingStageDTO,
  ProcessingActivityTypeDTO,
} from '@ketch-com/figurehead'
import { PersonalDataTypesModal } from '../../../upsert/components'
import { isUndefined } from 'lodash'
import {
  DataGridObjectType,
  dataSourcesListColumns,
} from 'pages/insights/dataMap/view/processingActivities/components/dataSourcesListUtils'
import {
  GridRowId,
  GridRowClassNameParams,
  useGridApiRef,
  GridEventListener,
  GridGroupNode,
} from '@mui/x-data-grid-premium'
import { DataGridAssetObjectWithDataSystemAppCodeType, groupingColDef } from './dataSourcesListUtils'

export const DataSourcesList: React.FC = () => {
  const { processingActivity, searchString, activityStage, setSearchString, setActivityStage } =
    useContext(ProcessingActivityContext)
  const isModelType = processingActivity.type === ProcessingActivityTypeDTO.ModelProcessingActivityType
  const [dataCategoryModalObject, setDataCategoryModalObject] = useState<
    ProcessingActivityDataSystemDTO | ProcessingActivityAssetDTO
  >()
  const [showDataCategoriesModal, setShowDataCategoriesModal] = useState(false)

  const displayedDataSources = useMemo(
    () =>
      processingActivity?.dataSystems?.filter(system => {
        const searchMatch = system?.dataSystem?.name?.toLowerCase().includes(searchString)
        const stageMatch =
          system?.processingStages?.includes(activityStage) ||
          activityStage ===
            ProcessingActivityDataSystemProcessingStageDTO.UnspecifiedProcessingActivityDataSystemProcessingStage
        return searchMatch && stageMatch
      }),
    [activityStage, processingActivity?.dataSystems, searchString],
  )

  const handleDataCategoriesClick = useCallback(
    (object: ProcessingActivityDataSystemDTO | ProcessingActivityAssetDTO) => {
      setDataCategoryModalObject(object)
      setShowDataCategoriesModal(!showDataCategoriesModal)
    },
    [showDataCategoriesModal],
  )

  const handleResetFiltersClick = () => {
    setSearchString('')
    setActivityStage(
      ProcessingActivityDataSystemProcessingStageDTO.UnspecifiedProcessingActivityDataSystemProcessingStage,
    )
  }

  const expansionLookup = React.useRef<Record<GridRowId, boolean>>({})

  const apiRef = useGridApiRef()

  const onRowClick = React.useCallback<GridEventListener<'rowClick'>>(
    params => {
      const rowNode = apiRef.current.getRowNode(params.id)
      if (rowNode && rowNode.type === 'group') {
        apiRef.current.setRowChildrenExpansion(params.id, !rowNode.childrenExpanded)
      }
    },
    [apiRef],
  )

  const getRowClassName = (params: GridRowClassNameParams) => {
    //add class name to row with Header. Row with header has only id and path
    const rowHeaderClass = Object.keys(params.row).length === 2 ? 'DeckRowHeader' : ''
    return `DeckRowSpan-${params.row.path.length} ${rowHeaderClass}`
  }

  const rows = useMemo(() => {
    const result: (DataGridObjectType | DataGridAssetObjectWithDataSystemAppCodeType)[] = []
    if (displayedDataSources) {
      displayedDataSources.forEach(elem => {
        if (!elem.assets) {
          result.push({ ...elem, path: [elem.id || ''], handleDataCategoriesClick })
        } else {
          result.push({ ...elem, path: [elem.id || ''], handleDataCategoriesClick })
          elem.assets.forEach(asset => {
            result.push({
              ...asset,
              handleDataCategoriesClick,
              logoUrl: elem.dataSystem?.logoUrl,
              // pretty much the same as in DataMap's Processing activity tab,
              // but we need this extra info from the parent elem
              dataSystemAppCode: elem.dataSystem?.appCode,
              path: [elem.id || '', asset.id || ''],
            })
          })
        }
      })
    }
    return result
  }, [displayedDataSources, handleDataCategoriesClick])

  // same columns as in DataMap's Processing activity tab, except the last column
  const columns = useMemo(() => dataSourcesListColumns(isModelType).slice(0, -1), [isModelType])
  const getTreeDataPath = useCallback((row: any) => row.path, [])

  React.useEffect(() => {
    if (apiRef.current && apiRef.current.subscribeEvent) {
      apiRef.current.subscribeEvent('rowExpansionChange', node => {
        return (expansionLookup.current[node.id] = node.childrenExpanded || false)
      })
    }
  }, [apiRef])

  const isGroupExpandedByDefault = React.useCallback(
    (node: GridGroupNode) => {
      return !!expansionLookup.current[node.id]
    },
    [expansionLookup],
  )

  return rows && rows.length ? (
    <Box>
      <DataGrid
        sx={{
          padding: 'unset',
          // we update the padding added by DataGrid for "groupingColDef" passed as prop below
          '&.DeckExpandable:not(.DeckWithCheckbox)': {
            '& .MuiDataGrid-columnHeader:first-of-type': {
              '& .MuiDataGrid-columnHeaderDraggableContainer': {
                paddingLeft: '18px',
              },
            },
          },
        }}
        isGroupExpandedByDefault={isGroupExpandedByDefault}
        apiRef={apiRef}
        columns={columns}
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        disableRowHoverStates
        disableRowSelectionOnClick
        getRowClassName={getRowClassName}
        getRowHeight={() => 'auto'}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        hideFooter
        onRowClick={onRowClick}
        rows={rows}
        treeData
        slotProps={{
          baseIconButton: {
            onClick: () => {
              return false // let the rowClick handler deal with the click
            },
          },
        }}
      />

      {/* Modal */}
      {showDataCategoriesModal && !isUndefined(dataCategoryModalObject) && (
        <PersonalDataTypesModal
          dataCategoriesObject={dataCategoryModalObject}
          onCancel={() => setShowDataCategoriesModal(false)}
        />
      )}
    </Box>
  ) : (
    // No results indicator
    <Box>
      <EmptyState
        iconName="OFilter"
        title="No results found"
        subTitle="Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try again."
        primaryButtonTitle="Reset to Defaults"
        primaryButtonProps={{
          onClick: handleResetFiltersClick,
          color: 'tertiary',
        }}
      />
    </Box>
  )
}
