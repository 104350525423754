import React from 'react'

import { Badge } from 'components/ui-kit/badge/Badge'
import { BadgeKeyValue } from 'components/ui-kit/badge/BadgeKeyValue'

import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import { AutocompleteRenderOptionState } from '@mui/material'

export const getRenderTag = (
  props: React.HTMLAttributes<HTMLLIElement>,
  value: ManageLabelModalLabel,
  state: AutocompleteRenderOptionState,
): React.ReactNode | null => {
  const uniqueIdentifier = `${value?.code || ''}${value?.value || ''}${value?.name || ''}`
  if (value.value)
    return (
      <li {...props} key={uniqueIdentifier}>
        <BadgeKeyValue badgeKey={value.code} badgeValue={value.value} variant="neutral" />
      </li>
    )
  if (!value.value)
    return (
      <li {...props} key={uniqueIdentifier}>
        <Badge variant="neutral">{value.code}</Badge>
      </li>
    )
  return null
}
