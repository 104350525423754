import { Box, FormGroup, Typography, styled } from '@mui/material'
import React, { CSSProperties, useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment'
import {
  ActionSheetItem,
  Avatar,
  AvatarSize,
  Button,
  Icon,
  InlineEdit,
  ListItemText,
  Spinner,
  TextInput,
  theme,
} from '@ketch-com/deck'
import pluralize from 'pluralize'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { SystemsSelector } from './components/SystemsSelector'
import { useCreateInstalledDataSystem } from 'api/dataSystems/mutations/useCreateInstalledDataSystem'
import { showToast } from 'components/modals/AlertComponent'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useQueryClient } from 'react-query'
import {
  DataSystemV2DTO,
  InstalledDataSystemRelationshipTypeV2DTO,
  InstalledDataSystemV2DTO,
} from '@ketch-com/figurehead'
import { Waypoint } from 'react-waypoint'
import { useDataSystemsInfinite } from 'api/dataSystems/queries/useDataSystemsInfinite'
import { ReactComponent as AppIcon } from 'assets/icons/app_icon.svg'

import { useCreateDataSystem } from 'api/dataSystems/mutations/useCreateDataSystem'
import { useAuth } from 'utils/hooks'
import { useInstalledDataSystems } from 'api/dataSystems/queries/useInstalledDataSystems'
import { formatCodeFromName } from 'utils/formatters'
import { StyledOrchestrationAutocompletePaper } from 'pages/policyCenter/subscriptions/subscriptionTopic/upsert/components/Orchestration/utils'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { TypeOptions } from 'pages/dataSystems/DataSystem/types'
import { FormAssigneeSelector } from 'components/AssigneeSelector/FormAssigneeSelector'

const AddSystemCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '800px',
  minHeight: '400px',
  backgroundColor: '#FFF',
  borderRadius: '11px',
  padding: ' 32px 48px 32px 48px',
  gap: 32,
}))

const Header = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(2),
  fontSize: '1.5rem',
  fontWeight: '800',
}))

const Body = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  gap: 32,
}))

const SearchBarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
}))

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  display: 'flex',
  flex: '1',
}))

const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '1rem',
  borderTop: `1px solid ${theme.palette.lightGrey.main}`,
  paddingTop: '1rem',
}))

const DataSystemLogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '40px',
  overflow: 'hidden',
}))

const DataSystemNameContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: '1',
}))

const DataSystemPillContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}))

const DataSystemPill = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  backgroundColor: '#F5F6F6',
  padding: '6px 18px',
  borderRadius: '100px',
}))

type Props = {
  className?: string
  style?: CSSProperties
}

const breadcrumbs = [{ title: 'Data Map', link: RoutesManager.systems.root.pattern }, { title: 'Add System' }]

export const DataSystemsInstall: React.FC<Props> = () => {
  const [searchString, setSearchString] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [viewAllSystemsOpen, setViewAllSystemsOpen] = useState(false)

  const { userData } = useAuth()

  const {
    data: dataSystems,
    isLoading,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useDataSystemsInfinite({ q: searchString, notInstalled: true })

  const { data: installedDataSystems } = useInstalledDataSystems({
    params: {
      q: searchString,
    },
  })

  const installedDataSystemsExists = !!installedDataSystems?.some((installedDataSystem: InstalledDataSystemV2DTO) => {
    return installedDataSystem?.dataSystem?.name?.toLocaleLowerCase() === searchString?.toLocaleLowerCase()
  })

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const { mutateAsync: handleCreateInstalledDataSystem, data: createInstalledSystemResponse } =
    useCreateInstalledDataSystem({
      onError: () => showToast({ type: 'error', content: 'Error Adding System' }),
      onSuccess: () => {
        showToast({ type: 'success', content: 'Added System Successfully' })

        queryClient.refetchQueries([ApiQueryKeys.installedDataSystems])
      },
    })

  const { mutateAsync: handleCreateDataSystem } = useCreateDataSystem({
    onError: () => showToast({ type: 'error', content: 'Error Creating System' }),
    onSuccess: () => {
      showToast({ type: 'success', content: 'System Created Successfully' })

      queryClient.refetchQueries([ApiQueryKeys.dataSystems])
    },
  })

  const onSubmit = async (values: AddInstallFormValues) => {
    const dataSystemCodes = values?.selectedDataSystems?.map(selectedDataSystem => selectedDataSystem?.code) as string[]
    const description = values?.description
    const type =
      values?.selectedDataSystems.length > 1
        ? InstalledDataSystemRelationshipTypeV2DTO.VendorInstalledDataSystemRelationshipTypeV2
        : values?.type
    const ownerUserIds = values?.userOwner ? [values.userOwner] : undefined

    if (dataSystemCodes[0] === '') {
      const name = values?.selectedDataSystems[0]?.name ?? ''
      const code = `${userData?.organizationCode}.${formatCodeFromName(name)}`

      if (code) {
        await handleCreateDataSystem({
          params: {
            formData: {
              dataSystem: { name, code },
            },
          },
        })

        await handleCreateInstalledDataSystem({
          params: {
            formData: {
              installedDataSystem: { dataSystemCodes: [code], description, type, ownerUserIds },
            },
          },
        })
      }
    } else {
      dataSystemCodes.map(async dataSystemCode => {
        await handleCreateInstalledDataSystem({
          params: {
            formData: {
              installedDataSystem: { dataSystemCodes: [dataSystemCode], description, type, ownerUserIds },
            },
          },
        })
      })
    }

    setSubmitted(true)
  }

  const handleAddSystems = () => {
    setSubmitted(false)
  }

  interface AddInstallFormValues {
    selectedDataSystems: DataSystemV2DTO[]
    description: string
    type: InstalledDataSystemRelationshipTypeV2DTO
    userOwner?: string
  }

  const initialValues: AddInstallFormValues = {
    selectedDataSystems: [],
    description: '',
    type: InstalledDataSystemRelationshipTypeV2DTO.VendorInstalledDataSystemRelationshipTypeV2,
  }

  const isReady = !isLoading || !isRefetching

  if (!isReady) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  const validationSchema = null

  return (
    <Box>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <AddSystemCard>
        <Formik
          title="Add System"
          isReady={isReady}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ submitForm, handleChange, values, setFieldValue }) => {
            const { selectedDataSystems, description, type } = values

            const handleRemoveDataSystem = (dataSystemId: string | undefined) => {
              const filteredSelectedDataSystems = selectedDataSystems?.filter(
                selectedDataSystem => selectedDataSystem.appId !== dataSystemId,
              )

              setFieldValue('selectedDataSystems', filteredSelectedDataSystems)
            }

            const handleRemoveAllDataSystems = () => setFieldValue('selectedDataSystems', [])

            const handleCancel = () => {
              navigate(RoutesManager.systems.systems.root.getURL())
            }

            const handleFinished = () => {
              if (createInstalledSystemResponse?.data?.installedDataSystem?.id) {
                navigate(
                  RoutesManager.systems.id.overview.root.getURL({
                    id: createInstalledSystemResponse?.data?.installedDataSystem?.id,
                  }),
                )
              } else {
                navigate(RoutesManager.systems.root.getURL())
              }
            }

            const handleViewAllSystems = () => {
              setViewAllSystemsOpen(!viewAllSystemsOpen)
            }

            const dataSystemsOptions = [...dataSystems, '']

            const handleDataSystemCreate = () => {
              const newDataSystem: DataSystemV2DTO = {
                code: '',
                description: '',
                name: '',
                systemType: '',
              }

              setFieldValue('selectedDataSystems', [newDataSystem])
            }

            const handleDataSystemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
              const newDataSystem: DataSystemV2DTO = {
                ...selectedDataSystems[0],
                name: event.target.value,
              }

              setSearchString(event.target.value)

              setFieldValue('selectedDataSystems', [newDataSystem])
            }

            return (
              <>
                {!submitted ? (
                  <>
                    <Header>Add System</Header>
                    <Body>
                      {selectedDataSystems.length === 0 && (
                        <SearchBarContainer>
                          <StyledAutocomplete
                            id="DataSystemsSearch"
                            options={dataSystemsOptions ?? []}
                            size="medium"
                            // TO-DO revisit types
                            getOptionLabel={(option: any) => option.name}
                            PaperComponent={StyledOrchestrationAutocompletePaper}
                            renderOption={(props, option: any, { selected }) => {
                              if (option === '') {
                                return (
                                  <Waypoint
                                    onEnter={() => {
                                      if (!isFetchingNextPage && hasNextPage) fetchNextPage()
                                    }}
                                  />
                                )
                              }

                              return (
                                <ActionSheetItem
                                  {...props}
                                  selected={selected}
                                  onClick={() => {
                                    setFieldValue('selectedDataSystems', [...selectedDataSystems, option])
                                    setFieldValue('description', option.description)
                                  }}
                                >
                                  <ListItemText selected={selected}>
                                    <Box display="flex" alignItems="center" gap={1}>
                                      <Avatar
                                        src={option.logoUrl}
                                        alt={option.title}
                                        size={AvatarSize.xlarge}
                                        variant="rounded"
                                      />
                                      <Typography>{option.name}</Typography>
                                    </Box>
                                  </ListItemText>
                                </ActionSheetItem>
                              )
                            }}
                            renderInput={params => (
                              <TextInput
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon name="OMag" />
                                    </InputAdornment>
                                  ),
                                  endAdornment: <></>,
                                }}
                                variant="outlined"
                                placeholder="Start typing system's name"
                                onChange={e => setSearchString(e.currentTarget.value)}
                                value={searchString}
                              />
                            )}
                            noOptionsText={
                              <Box display="flex" flexDirection="column" gap={2}>
                                <Typography variant="label">No Results</Typography>
                                <Typography variant="body">
                                  There is no record of this system in our catalog. Add one manually below and we will
                                  update our catalog to include this in our next release!
                                </Typography>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  pt={2}
                                  borderTop={theme => `1px solid ${theme.palette.lightGrey.main}`}
                                >
                                  <Button
                                    color="secondary"
                                    variant="link"
                                    startIcon={<Icon name="OPlusRound" />}
                                    onClick={handleDataSystemCreate}
                                  >
                                    <Typography variant="label">Add System Manually</Typography>
                                  </Button>
                                </Box>
                              </Box>
                            }
                          />
                          <Button size="large" color="tertiary" onClick={handleViewAllSystems}>
                            View All Systems
                          </Button>
                        </SearchBarContainer>
                      )}

                      {/* Data System Pill container */}
                      <DataSystemPillContainer>
                        {selectedDataSystems?.map(selectedDataSystem => {
                          return (
                            <DataSystemPill onClick={() => null}>
                              <DataSystemLogoContainer>
                                {selectedDataSystem?.code ? (
                                  <Avatar
                                    src={selectedDataSystem?.logoUrl}
                                    alt={selectedDataSystem?.name}
                                    variant="circular"
                                    size={AvatarSize.large}
                                  />
                                ) : (
                                  <AppIcon />
                                )}
                              </DataSystemLogoContainer>
                              <DataSystemNameContainer>
                                {selectedDataSystem?.code ? (
                                  <Typography variant="label">{selectedDataSystem.name}</Typography>
                                ) : (
                                  <InlineEdit
                                    textInputVariant="ghost"
                                    placeholder="Add System Name"
                                    value={selectedDataSystem.name}
                                    onChange={handleDataSystemChange}
                                  />
                                )}
                              </DataSystemNameContainer>

                              {installedDataSystemsExists && (
                                <Typography variant="label">Data System already exists, please try another.</Typography>
                              )}

                              <Icon
                                name="OCross"
                                sx={{
                                  '&:hover': {
                                    cursor: 'pointer',
                                  },
                                }}
                                onClick={() => handleRemoveDataSystem(selectedDataSystem?.appId)}
                              />
                            </DataSystemPill>
                          )
                        })}
                      </DataSystemPillContainer>

                      {selectedDataSystems.length === 1 && (
                        <Box display="flex" flexDirection="column" gap={3}>
                          <Box>
                            <Typography variant="label" sx={{ mb: 2 }}>
                              What do you use {selectedDataSystems[0].name} for?{' '}
                              <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
                                (Optional)
                              </Typography>
                            </Typography>
                            <TextInput
                              name="description"
                              placeholder="Example: We use this system for managing email marketing campaigns."
                              value={description}
                              fullWidth
                              onChange={handleChange}
                              multiline
                              rows={3}
                            />
                          </Box>
                          <Box>
                            <FormGroup row={true}>
                              <FormRadioGroup
                                label={`What best describes your relationships with ${selectedDataSystems[0].name}?`}
                                formPropertyName="type"
                                row
                                valueKey="id"
                                renderLabel={({ name }, isSelected) => (
                                  <Typography variant={isSelected ? 'label' : 'body'}>{name}</Typography>
                                )}
                                options={[
                                  { id: 'vendor', name: 'Vendor' },
                                  { id: 'partner', name: 'Partner' },
                                  { id: 'customer', name: 'Customer' },
                                ]}
                              />
                            </FormGroup>
                          </Box>
                          <Box>
                            <FormGroup row>
                              <FormAssigneeSelector formPropertyName="userOwner" label="System Owner" />
                            </FormGroup>
                          </Box>
                        </Box>
                      )}
                    </Body>

                    <Footer>
                      <Button onClick={handleCancel} size="large" color="secondary">
                        Cancel
                      </Button>

                      {selectedDataSystems.length > 0 && (
                        <>
                          <Button
                            onClick={submitForm}
                            size="large"
                            disabled={!selectedDataSystems[0]?.name || installedDataSystemsExists}
                          >
                            Add {pluralize('System', selectedDataSystems.length)}{' '}
                            {selectedDataSystems.length > 1 && selectedDataSystems.length}
                          </Button>
                          {selectedDataSystems.length > 1 && (
                            <Button onClick={handleRemoveAllDataSystems} size="large" sx={{ marginLeft: 'auto' }}>
                              Clear Selection
                            </Button>
                          )}
                        </>
                      )}
                    </Footer>
                  </>
                ) : (
                  <>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Icon name="OutcomeSuccess" width={56} height={56} />

                      <Typography variant="h2" color={theme.palette.greenHaze.main}>
                        System Added
                      </Typography>
                    </Box>

                    <Body
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1',
                        gap: selectedDataSystems.length === 1 ? 4 : 0,
                      }}
                    >
                      {selectedDataSystems.length > 1 ? (
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap={2}
                          bgcolor="superLightGrey.main"
                          p={3}
                          borderRadius="20px"
                        >
                          {selectedDataSystems?.map((selectedDataSystem, index) => (
                            <Box display="flex" alignItems="center" gap={2}>
                              <Box>
                                {selectedDataSystem?.code ? (
                                  <Avatar
                                    src={selectedDataSystem?.logoUrl}
                                    alt={selectedDataSystem?.name}
                                    variant="rounded"
                                    size={AvatarSize.xlarge}
                                    backgroundColor="white.main"
                                  />
                                ) : (
                                  <AppIcon />
                                )}
                              </Box>

                              <DataSystemNameContainer>
                                <Typography variant="h4">{selectedDataSystem.name}</Typography>
                              </DataSystemNameContainer>
                            </Box>
                          ))}
                        </Box>
                      ) : (
                        selectedDataSystems?.map(selectedDataSystem => {
                          return (
                            <>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 2,
                                  backgroundColor: 'superLightGrey.main',
                                  padding: 3,
                                  borderRadius: '20px',
                                }}
                              >
                                <Box>
                                  {selectedDataSystem?.code ? (
                                    <Avatar
                                      src={selectedDataSystem?.logoUrl}
                                      alt={selectedDataSystem?.name}
                                      variant="rounded"
                                      size={AvatarSize.xlarge}
                                      backgroundColor="white.main"
                                    />
                                  ) : (
                                    <AppIcon />
                                  )}
                                </Box>

                                <DataSystemNameContainer>
                                  <Typography variant="h4">{selectedDataSystem.name}</Typography>
                                </DataSystemNameContainer>
                              </Box>

                              {selectedDataSystems.length === 1 && (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderRadius: '20px',
                                    p: 3,
                                    backgroundColor: 'superLightGrey.main',
                                  }}
                                >
                                  <Box display="flex" flexDirection="column" flex="1" gap={1}>
                                    {description && (
                                      <>
                                        <Typography color="darkDuskFaded.main">Details</Typography>
                                        <Typography variant="h4">{description}</Typography>
                                      </>
                                    )}

                                    <Box>
                                      <Typography variant="body">Type: {TypeOptions[type].title}</Typography>
                                    </Box>
                                    {/* TODO: add user owner display */}
                                  </Box>
                                </Box>
                              )}
                            </>
                          )
                        })
                      )}
                    </Body>

                    <Footer>
                      <Button
                        onClick={() => {
                          handleAddSystems()
                          handleRemoveAllDataSystems()
                        }}
                        size="large"
                        color="secondary"
                      >
                        Add {dataSystems?.length === 1 ? 'Another' : 'More'}
                      </Button>
                      <Button onClick={handleFinished} size="large">
                        Finish
                      </Button>
                    </Footer>
                  </>
                )}

                <SystemsSelector
                  isOpen={viewAllSystemsOpen}
                  setViewAllSystemsOpen={setViewAllSystemsOpen}
                  onClose={() => setViewAllSystemsOpen(false)}
                />
              </>
            )
          }}
        </Formik>
      </AddSystemCard>
    </Box>
  )
}
