import { Banner, Button } from '@ketch-com/deck'
import { useTagsList } from '../context/TagsContext'
import { WebTagIssueType } from 'interfaces/webtags/WebTags'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

export const TagIssueBanners = () => {
  const { webTagIssues, selectedTagIds } = useTagsList()
  const navigate = useNavigate()

  // Only show the first 3 errors, for now
  const issues = webTagIssues
    .filter(tagIssue => Object.values(WebTagIssueType).includes(tagIssue.issue.issue_type))
    .slice(0, 3)

  return (
    <>
      {issues.map(issue => (
        <Banner
          severity="error"
          title="Authentication Error"
          children={issue.errorMessage}
          action={
            <Button variant="link" onClick={() => navigate(RoutesManager.updates.tasks.list.getURL())}>
              View Issues
            </Button>
          }
          sx={{ height: '48px', mt: '24px', mb: selectedTagIds.length ? '16px' : '0px' }}
        />
      ))}
    </>
  )
}
