import { Box, Typography } from '@mui/material'
import { TooltipButton } from '@ketch-com/deck'
import { useContext } from 'react'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
import { IdentityVerificationContext } from '../context/IdentityVerificationContext'
import { useSearchParams } from 'react-router-dom'
import { getIsWorkflowExecutionStepFailed } from 'pages/orchestration/rightsQueue/utils'
import React, { useState } from 'react'
import { ConfirmRetryIDVModal } from '../../../../../../../../components/modals/rightsQueue/ConfirmRetryIDVModal'
import { IIdentityVerificationActivityParams } from '../../../../../../workflows/edit/interfaces'

type Props = {}

export const IdvDetailsHeaderActions: React.FC<Props> = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    hasResolveSucceeded,
    isRightsQueueAdmin,
    stepDetails,
    isWorkflowExecutionStepFailed,
    isWorkflowExecutionStepSuccess,
    rightInvocationId,
    rightInvocation,
    workflow,
  } = useContext(RightInvocationWorkflowStepDetailViewContext)
  const {
    resolveFormik,
    isAssignedToMe,
    workflowStep,
    isRetryingIdentityVerification,
    retryIdentityVerification,
    sanitizedStepError,
  } = useContext(IdentityVerificationContext)
  const canSubmit = isAssignedToMe && getIsWorkflowExecutionStepFailed(workflowStep)
  const resolveTooltipTitle =
    isRightsQueueAdmin && !stepDetails?.assignee
      ? ''
      : isRightsQueueAdmin && !isAssignedToMe
      ? 'Resolve is only available for assignees.'
      : isAssignedToMe
      ? ''
      : 'Resolve is only available for assignees.'
  const retryTooltipTitle = sanitizedStepError.includes('resolve')
    ? sanitizedStepError
    : isAssignedToMe
    ? 'Resend the Identity Verification request to the Data Subject.'
    : 'Retry is only available for assignees.'
  const [isConfirmRetryIDVModalOpen, setIsConfirmRetryIDVModalOpen] = useState(false)
  const canonicalRightCode = workflow?.canonicalRightCode
  const firstName = rightInvocation?.user?.first
  const lastName = rightInvocation?.user?.last
  const workflowStepActivityParams = workflowStep?.activity?.params as IIdentityVerificationActivityParams

  return (
    <Box display="flex" gap={1.5} alignItems="center">
      {workflowStep?.completedAt && (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="smallBody" color="darkDuskFaded.main">
            Last Updated
          </Typography>
          <Typography color="darkDusk.main" variant="smallBody">
            <FormattedDateRenderer date={workflowStep?.completedAt} />
          </Typography>
        </Box>
      )}

      {/* Retry Button */}

      {!isWorkflowExecutionStepSuccess && (
        <TooltipButton
          title={retryTooltipTitle}
          disabled={!isAssignedToMe || isRetryingIdentityVerification || sanitizedStepError.includes('resolve')}
          size="large"
          color="secondary"
          onClick={() => setIsConfirmRetryIDVModalOpen(true)}
        >
          Retry
        </TooltipButton>
      )}

      {isConfirmRetryIDVModalOpen && (
        <ConfirmRetryIDVModal
          isPending={isRetryingIdentityVerification}
          onCancel={() => setIsConfirmRetryIDVModalOpen(false)}
          onSubmit={() => {
            retryIdentityVerification({
              params: {
                workflowExecutionId: stepDetails?.workflowExecutionID,
                stepId: stepDetails?.stepID,
                payload: {
                  identityVerification: {
                    shouldRetry: true,
                    requestID: rightInvocationId,
                    canonicalRightCode: canonicalRightCode,
                    firstName: firstName,
                    lastName: lastName,
                    email: rightInvocation?.user?.email,
                    tileParams: {
                      authenticatorButtonText: workflowStepActivityParams?.authenticatorButtonText,
                      authenticatorID: workflowStepActivityParams?.authenticatorID,
                      authenticatorType: Number(workflowStepActivityParams?.authenticatorType),
                      body: workflowStepActivityParams?.body,
                      cc: workflowStepActivityParams?.cc,
                      code: workflowStepActivityParams?.code,
                      idvDescription: workflowStepActivityParams?.description,
                      timeoutDays: Number(workflowStepActivityParams?.identityVerificationTimeoutDays),
                      isCustomIdv: workflowStepActivityParams?.isCustomIDV,
                      subject: workflowStepActivityParams?.subject,
                    },
                  },
                },
              },
            }).finally(() => setIsConfirmRetryIDVModalOpen(false))
          }}
        />
      )}

      {/* Resolve Button */}

      {isWorkflowExecutionStepFailed && (
        <TooltipButton
          title={resolveTooltipTitle}
          pending={resolveFormik?.isSubmitting}
          disabled={
            isRightsQueueAdmin && !stepDetails?.assignee
              ? false
              : !isAssignedToMe || hasResolveSucceeded || !canSubmit || resolveFormik?.isSubmitting
          }
          size="large"
          color="primary"
          onClick={() => {
            if (isRightsQueueAdmin && !stepDetails?.assignee) {
              setSearchParams({ ...searchParams, hasAttemptedToResolveWithoutAssignee: 'true' })
              return
            }

            setSearchParams({ hasAttemptedToResolve: 'true' })
            if (canSubmit) resolveFormik?.submitForm()
          }}
        >
          Resolve
        </TooltipButton>
      )}
    </Box>
  )
}
