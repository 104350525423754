import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Icon } from '@ketch-com/deck'

type Props = {
  isExpanded?: boolean
  setIsExpanded?: (value: React.SetStateAction<boolean>) => void
  content?: React.ReactNode
}

export const TranslationsCollapse: React.FC<Props> = ({ isExpanded, setIsExpanded, content }) => {
  return (
    <>
      <Box
        sx={{
          borderTop: theme => `1px solid ${isExpanded ? theme.palette.darkDusk.main : theme.palette.iron.main}`,
        }}
        display="flex"
        alignItems="center"
        onClick={() => setIsExpanded?.(e => !e)}
        mt={2}
        pt={1.5}
        pb={isExpanded ? 3 : 0}
      >
        <Icon name={isExpanded ? 'OArrowCDown' : 'OArrowCRight'} />
        <Typography
          variant="label"
          sx={{
            cursor: 'pointer',
          }}
        >
          Translations
        </Typography>
      </Box>

      {isExpanded ? content : null}
    </>
  )
}
