import React from 'react'

import { useDeploymentPlansPaginated } from 'api/deploymentPlans/queries/useDeploymentPlansPaginated'
import { DeploymentPlansList } from 'pages/consentAndRights/deploymentPlans/list/DeploymentPlansList'

export const DeploymentPlansListContainer: React.FC = () => {
  const {
    data: deploymentPlans,
    isLoading,
    isFetching,
    pagination,
  } = useDeploymentPlansPaginated({
    params: {
      includeIssues: true,
      includeMetadata: true,
    },
  })

  return (
    <DeploymentPlansList
      isLoading={isLoading}
      isFetching={isFetching}
      deploymentPlans={deploymentPlans}
      pagination={pagination}
    />
  )
}
