import { useContext } from 'react'
import { useField, useFormikContext } from 'formik'
import { StepDetailsIdentitySpaceDTO } from '@ketch-com/figurehead'
import { useQueryClient } from 'react-query'

// api
import { ApiQueryKeys } from 'api/common/queryKeys'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { useUpdateWorkflowExecutionStep } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStep'

// components
import { showToast } from 'components/modals/AlertComponent'
import { InfoRowEditableTextField } from 'components/InfoRowEditableTextField.tsx/InfoRowEditableTextField'

export interface IdentitySpacesInfoRowType {
  canEdit: boolean
  code?: string
  defaultValue: string
  hasAttemptedToResolve?: boolean
  identitySpace: StepDetailsIdentitySpaceDTO
  identitySpaces?: StepDetailsIdentitySpaceDTO[]
  infoRowTitle: string
  isRequired?: boolean
  name: string
  placeholder: string
  showOptionalLabel?: boolean
  showRequiredLabel?: boolean
  showValidation?: boolean
}

const buildIdentitySpaceFormData = (code?: string, name: string = '', value?: string): { [key: string]: string } => {
  return { [code || name]: value || '' }
}

export const IdentitySpacesEditableInfoRow = ({
  canEdit = true,
  code,
  defaultValue,
  identitySpace,
  identitySpaces = [],
  infoRowTitle,
  isRequired = false,
  name,
  placeholder,
  showOptionalLabel = false,
  showRequiredLabel = false,
  showValidation = false,
}: IdentitySpacesInfoRowType) => {
  const { submitForm, isSubmitting, setSubmitting } = useFormikContext()
  const { stepId, workflowExecutionId, refetchWorkflowStep } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const [, { error, value }, { setValue }] = useField<string>({ name: identitySpace.name || '' })

  const queryClient = useQueryClient()

  const isIdentitySpace = identitySpaces.length > 0
  const fieldType = isIdentitySpace ? 'Identifier' : 'Task notes'

  // api
  const { mutateAsync: submitStepDetails } = useUpdateWorkflowExecutionStep({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.workflowExecutionStepDetailsV2, { workflowExecutionId, stepId }])
      await refetchWorkflowStep()
      showToast({ content: `Successfully updated ${fieldType}`, type: 'success' })
      submitForm()
      setSubmitting(false)
    },
    onError: () => {
      showToast({
        content: `Failed to update ${fieldType}`,
        type: 'error',
      })
      setSubmitting(false)
    },
  })

  // event handlers
  const handleSubmit = async () => {
    if (!!error || !value || value === defaultValue) return
    setSubmitting(true)

    const formData = isIdentitySpace
      ? { identities: buildIdentitySpaceFormData(code, name, value) }
      : {
          manualInterventionData: {
            resolutionDetails: value || '',
          },
        }

    await submitStepDetails({
      params: {
        formData,
        workflowExecutionId,
        stepId: stepId!,
      },
    })
  }

  const handleOnChange = (value: string) => {
    setValue(value)
  }

  return (
    <InfoRowEditableTextField
      inputId="identity-space-input"
      canEdit={canEdit}
      defaultValue={defaultValue}
      error={error}
      infoRowTitle={infoRowTitle}
      isLoading={isSubmitting}
      isRequired={isRequired}
      onChange={handleOnChange}
      onSubmit={handleSubmit}
      placeholder={placeholder}
      showOptionalLabel={showOptionalLabel}
      showRequiredLabel={showRequiredLabel}
      showValidation={showValidation}
      value={value}
    />
  )
}
