import React, { useContext, useCallback, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useFormikContext } from 'formik'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { PreferenceField } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import { theme, TextInput, SwitchToggle } from '@ketch-com/deck'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { SubscriptionExperienceItem } from 'interfaces/experiences/subscriptions'

const SwitchButtonContainer = styled(Box)(({ theme }) => ({
  borderRadius: 11,
  padding: theme.spacing(1.25, 2.25),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1.5),
}))

type Props = {
  organizationLanguages: LanguageWithStatusDTO[]
}

export const Description: React.FC<Props> = ({ organizationLanguages }) => {
  const { values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext<PreferenceExperienceFormValues>()
  const { sidebarActiveTab } = useContext(ExperienceUpsertContext)
  const [isDescriptionSwitchEnabled, setIsDescriptionEnabled] = useState(
    values?.subscriptions?.controlItems?.length && values?.subscriptions?.controlItems?.[0]?.itemDetail?.description
      ? true
      : false,
  )

  const resetDescriptionData = useCallback(() => {
    setFieldValue('subscriptions.controlItems[0].itemDetail.description', '')
    organizationLanguages.forEach(({ language }) => {
      setFieldValue(`translations.${language.code}.subscriptions.controlItems[0].itemDetail.description`, '')
    })
  }, [setFieldValue, organizationLanguages])

  if (sidebarActiveTab !== PreferenceField.SUBSCRIPTIONS_CONTROL) return null

  return (
    <Box>
      <SwitchButtonContainer
        sx={{
          backgroundColor: `${isDescriptionSwitchEnabled ? theme.palette.tara.main : theme.palette.fadedGrey.main}`,
        }}
      >
        <SwitchToggle
          onChange={(e, switchSelection) => {
            setIsDescriptionEnabled(switchSelection)
            if (!switchSelection) resetDescriptionData()
          }}
          checked={isDescriptionSwitchEnabled}
        />
        <Typography variant="label" color={isDescriptionSwitchEnabled ? 'greenHaze.main' : ''}>
          Description
        </Typography>
      </SwitchButtonContainer>

      {isDescriptionSwitchEnabled ? (
        <Box px={2.25}>
          <Box pt={2} pb={4}>
            <TextInput
              multiline
              fullWidth
              label="Description Text"
              value={values?.subscriptions?.controlItems?.[0]?.itemDetail?.description}
              onChange={e => {
                setFieldValue('subscriptions.controlItems[0].itemDetail.description', e?.currentTarget?.value)
              }}
              error={
                touched?.subscriptions?.controlItems?.[0].itemDetail?.description &&
                Boolean(
                  (errors.subscriptions?.controlItems as never as Required<SubscriptionExperienceItem>[])?.[0]
                    ?.itemDetail?.description,
                )
              }
              errorMessage={
                typeof (errors.subscriptions?.controlItems as never as Required<SubscriptionExperienceItem>[])?.[0]
                  ?.itemDetail?.description === 'string'
                  ? (errors.subscriptions?.controlItems as never as Required<SubscriptionExperienceItem>[])?.[0]
                      ?.itemDetail?.description
                  : ''
              }
              onBlur={() => {
                setFieldTouched('subscriptions.controlItems[0].itemDetail.description', true)
              }}
            />
          </Box>
          {organizationLanguages?.length ? (
            <Box
              display="flex"
              flexDirection="column"
              gap={4}
              py={4}
              sx={{ borderTop: ({ palette }) => `1px solid ${palette.iron.main}` }}
            >
              <Typography variant="h4" color="darkDuskFaded.main">
                Description Text Translations
              </Typography>
              <Box>
                {organizationLanguages.map(({ language, enabled }) => (
                  <Box key={language.code} marginBottom={3}>
                    <TextInput
                      multiline
                      fullWidth
                      value={
                        values?.translations?.[`${language.code}`]?.subscriptions?.controlItems?.[0]?.itemDetail
                          ?.description
                      }
                      label={
                        <Box display="flex" gap={0.5}>
                          <Typography variant="label">{language.englishName}</Typography>
                          <Typography variant="label" color="darkDuskFaded.main">
                            (Optional)
                          </Typography>
                        </Box>
                      }
                      onChange={e => {
                        setFieldValue(
                          `translations.${language.code}.subscriptions.controlItems[0].itemDetail.description`,
                          e?.currentTarget?.value,
                        )
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  )
}
