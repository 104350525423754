import { FC } from 'react'
import { Formik } from 'formik'
import { PopUp, Button } from '@ketch-com/deck'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'
import { useFinalizeRequestModalUtils } from './hooks'
import { RequestEligibleForAppealWarning } from './components'
import { MAX_FILE_UPLOAD_SIZE_4_MB } from 'utils/constants/maxFileUploadSize'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { FormInput } from 'components/form/FormInput'
import { FormDropZone } from 'components/form/FormDropZone/FormDropZone'
import { isTwilioConversationsEnabled } from 'utils/helpers/isTwilioConversationsEnabled'

type Props = {
  onClose: () => void
}

export const FinalizeRequestModalV2: FC<Props> = ({ onClose }) => {
  const {
    initialValues,
    validationSchema,
    resolve,
    isUploading,
    isMessageRequired,
    isCoverLetterRequired,
    allowAppeal,
    timeToInvokeAppealInDays,
  } = useFinalizeRequestModalUtils()

  const subjectCommunicationEnabled = isTwilioConversationsEnabled()

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={resolve}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ values, isSubmitting, handleSubmit, errors, touched }) => {
        logObjectDetails(
          {
            isSubmitting,
            values,
            errors,
            touched,
          },
          'FinalizeRequestModalV2.tsx',
          'white',
          false, // false ensures will log only in debug mode (i.e. ?debug=log)
        )
        return (
          <PopUp
            isOpen
            isLoading={isUploading}
            onClose={onClose}
            variant="dialog"
            showCloseButton
            popUpWidth="546px"
            title="Finalize Request"
            popUpActionChildren={
              <>
                <Button color="tertiary" onClick={() => onClose()} size="large">
                  Cancel
                </Button>
                <Button size="large" onClick={() => handleSubmit()}>
                  Finalize
                </Button>
              </>
            }
          >
            <Box display="flex" flexDirection="column" gap={0.5}>
              <FormRadioGroup
                sx={{
                  '& .MuiFormControlLabel-root': {
                    marginLeft: 'unset',
                  },
                }}
                formPropertyName="finalizeRequestAs"
                options={[
                  { label: 'Complete', value: 'completed' },
                  { label: 'Reject', value: 'rejected' },
                ].map(v => ({
                  title: v.label,
                  value: v.value,
                }))}
                valueKey="value"
                label={
                  <Typography variant="body" color="darkDusk.main" mb={2}>
                    Please choose how you want to proceed
                  </Typography>
                }
                renderLabel={(option, isSelected) => (
                  <Typography variant={isSelected ? 'label' : 'body'}>{option.title}</Typography>
                )}
                row
              />
              {/* Appeal eligible warning */}

              {values?.finalizeRequestAs === 'rejected' && allowAppeal && (
                <RequestEligibleForAppealWarning daysToInvokeAppeal={timeToInvokeAppealInDays} />
              )}

              {subjectCommunicationEnabled ? (
                <Box mb={3} mt={2}>
                  <FormInput
                    label="Subject Communication Message"
                    formPropertyName="subjectCommunicationMessage"
                    fullWidth
                    required={isMessageRequired}
                    rows={3}
                    multiline
                    shouldUpdateDebounced
                  />
                </Box>
              ) : null}

              <FormDropZone
                label="Upload Cover Letter"
                required={isCoverLetterRequired}
                formPropertyName="coverLetter"
                accept={{ 'application/pdf': ['.pdf'] }}
                maxFiles={1}
                maxSize={MAX_FILE_UPLOAD_SIZE_4_MB}
                containerSx={{
                  mt: subjectCommunicationEnabled ? undefined : 2,
                  mb: 2,
                }}
                hideDropzoneOnUpload
              />
            </Box>
          </PopUp>
        )
      }}
    </Formik>
  )
}
