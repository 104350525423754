import {
  FilterCategories,
  useInstalledDataSystemsV2ListInfinite,
  UseInstalledDataSystemsV2ListInfiniteParams,
} from 'api/dataSystems/queries/useInstalledDataSystemsInfiniteV2List'
import { useUsers } from 'api/users/queries/useUsers'
import React, { useState, useMemo, useEffect, useCallback, ReactNode } from 'react'
import { useDebounce } from 'react-use'
import { getFullNameUserShortDTO } from 'utils/helpers/getFullNameUserShortDTO'
import { createCustomContext } from 'utils/hooks/createCustomContext'
import { filterDisplayNameMap, initialCounts, initialFiltersData } from './constants'
import { FiltersData, SystemListFilterOption } from './SystemListFilter'
import { InstalledDataSystemV2DTO, InstalledDataSystemV2ListItemDTO } from '@ketch-com/figurehead'
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query'
import { MegaFilterSectionType } from '@ketch-com/deck'
import { useDataCategories } from 'api/dataSystemsClassifications/queries/useDataCategories'
import { AppListItem, useAppListItems } from 'pages/dataSystems/Home/components/Overview/hooks/useAppListItems'
import { useIsEntitled } from 'utils/hooks'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useDataMapRegions } from 'api/dataMap/queries/useDataMapRegions'
import { usePurposes } from 'api/purposes/queries/usePurposes'

interface FilterSectionType extends Omit<MegaFilterSectionType, 'value'> {
  value: FilterCategories
}

interface FilterContextProps {
  filterData: FiltersData
  searchQuery: string
  setFilterData: React.Dispatch<React.SetStateAction<FiltersData>>
  handleSearchInputChange: (value: string) => void
  dataSystemsV2ListInfiniteArgs: UseInstalledDataSystemsV2ListInfiniteParams
  isLoading: boolean
  ownerFilterOptions: SystemListFilterOption[]
  dataCategoryFilterOptions: SystemListFilterOption[]
  installedDataSystems: InstalledDataSystemV2ListItemDTO[]
  isRefetching: boolean
  hasNextPage?: boolean
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<
    InfiniteQueryObserverResult<
      {
        dataSystems: InstalledDataSystemV2ListItemDTO[]
        totalResults: number
        pageParam: number
      },
      unknown
    >
  >
  isFetchingNextPage: boolean
  refetch: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<
    QueryObserverResult<
      InfiniteData<{
        dataSystems: InstalledDataSystemV2ListItemDTO[]
        totalResults: number
        pageParam: number
      }>,
      unknown
    >
  >
  activeFiltersCount: number
  filterButtonText: string
  sections: FilterSectionType[]
  setCounts: React.Dispatch<React.SetStateAction<Record<string, number>>>
  resetFilters: () => void
  appListItems?: AppListItem[]
}

const { Provider, useCustomContext: useSystemListFilterContext } = createCustomContext<FilterContextProps>({
  displayName: 'SystemListFilterContext',
})

export const SystemListFilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [filterData, setFilterData] = useState<FiltersData>(initialFiltersData)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [counts, setCounts] = useState<Record<string, number>>(initialCounts)

  useDebounce(setSearchQuery, 500, [setSearchQuery])

  // inherent risk demo entitlement
  const { isEntitled } = useIsEntitled()
  const isEntitledToDemo = isEntitled(ENTITLEMENTS.DEMO)
  const inherentRiskFilterReset = useMemo(() => ({ attribute: 'inherentRisk', values: [] }), [])

  const handleSearchInputChange = useCallback((value: string) => {
    setSearchQuery(value)
  }, [])

  const resetFilters = useCallback(() => {
    setFilterData(
      prevFilterData =>
        Object.fromEntries(
          Object.entries(prevFilterData).map(([key, filterOptions]) => [
            key,
            filterOptions.map(option => ({
              ...option,
              isSelected: false,
            })),
          ]),
        ) as FiltersData,
    )
    setCounts(initialCounts)
  }, [])

  const dataSystemsV2ListInfiniteArgs = useMemo(() => {
    const filters = Object.entries(filterData).reduce((args, [attribute, valueList]) => {
      const selectedValues: string[] = valueList.reduce((selectedList, filterOption) => {
        if (filterOption.isSelected) {
          selectedList.push(filterOption.id)
        }
        return selectedList
      }, [] as string[])

      args[attribute as keyof UseInstalledDataSystemsV2ListInfiniteParams] = {
        attribute,
        values: selectedValues,
      }

      return args
    }, {} as UseInstalledDataSystemsV2ListInfiniteParams)
    if (searchQuery?.length) {
      filters.name = { attribute: 'name', values: [searchQuery] }
    } else {
      filters.name = { attribute: 'name', values: [] }
    }
    if (!isEntitledToDemo) {
      filters.inherentRisk = inherentRiskFilterReset
    }
    return filters
  }, [filterData, inherentRiskFilterReset, isEntitledToDemo, searchQuery])

  const {
    data: installedDataSystems,
    isLoading,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInstalledDataSystemsV2ListInfinite(dataSystemsV2ListInfiniteArgs)

  const [unfilteredDataSystems, setUnfilteredDataSystems] = useState<InstalledDataSystemV2DTO[] | null>(null)

  useEffect(() => {
    if (unfilteredDataSystems === null && installedDataSystems?.length) {
      setUnfilteredDataSystems(installedDataSystems)
    }
  }, [installedDataSystems, unfilteredDataSystems])

  const { data: allUsers } = useUsers({ params: { active: true } })

  const { data: allDataCategories } = useDataCategories({ params: { limit: 1000 } })
  const { data: allPurposes } = usePurposes()

  const purposesFilterOptions = useMemo(() => {
    if (allPurposes.length) {
      return allPurposes.map(purpose => ({
        name: purpose.displayName,
        id: purpose.code,
        isSelected: false,
      }))
    }
  }, [allPurposes])

  const dataCategoryFilterOptions = useMemo(() => {
    if (allDataCategories?.categories) {
      return allDataCategories?.categories.map(dataCategory => ({
        name: dataCategory.name || dataCategory.code!,
        id: dataCategory.code!,
        isSelected: false,
      }))
    }
    return []
  }, [allDataCategories?.categories])

  const regionResponse = useDataMapRegions({})
  const residencyCountryOptions = useMemo(() => {
    if (regionResponse.data) {
      return regionResponse.data.map(regionData => ({
        name: regionData.name!,
        id: regionData.code!,
        isSelected: false,
      }))
    }
  }, [regionResponse.data])

  useEffect(() => {
    if (!filterData.ownerUserIDs.length) {
      setFilterData(prev => ({
        ...prev,
        assignedCategories: [...dataCategoryFilterOptions],
      }))
    }
  }, [filterData.ownerUserIDs.length, dataCategoryFilterOptions])

  useEffect(() => {
    if (!filterData.residencyCountries.length && residencyCountryOptions?.length) {
      setFilterData(prev => ({
        ...prev,
        residencyCountries: [...residencyCountryOptions],
      }))
    }
  }, [filterData.residencyCountries.length, residencyCountryOptions])

  useEffect(() => {
    if (!filterData.assignedPurposes.length && purposesFilterOptions?.length) {
      setFilterData(prev => ({
        ...prev,
        assignedPurposes: [...purposesFilterOptions],
      }))
    }
  }, [filterData.assignedPurposes.length, purposesFilterOptions])

  const ownerFilterOptions = useMemo(() => {
    const allUserOptions = allUsers.map(u => ({
      name: getFullNameUserShortDTO(u),
      id: u.ID,
      isSelected: false,
    }))

    const userOwnerIds = unfilteredDataSystems?.reduce((acc, dataSystem) => {
      dataSystem?.ownerUserIds?.forEach(ownerUserId => {
        if (ownerUserId) {
          acc.push(ownerUserId)
        }
      })
      return acc
    }, [] as string[])

    const filteredOptions = allUserOptions.filter(u => userOwnerIds?.includes(u.id))

    return filteredOptions
  }, [allUsers, unfilteredDataSystems])

  useEffect(() => {
    if (!filterData.ownerUserIDs.length) {
      setFilterData(prev => ({
        ...prev,
        ownerUserIDs: [...ownerFilterOptions],
      }))
    }
  }, [filterData.ownerUserIDs.length, ownerFilterOptions])

  const { appListItems } = useAppListItems(installedDataSystems, allUsers)

  const activeFiltersCount = useMemo(() => {
    return Object.values(counts).reduce((acc, item) => (acc += item), 0)
  }, [counts])

  const filterButtonText = useMemo(() => {
    return `Filter ${activeFiltersCount ? `(${activeFiltersCount})` : ''}`
  }, [activeFiltersCount])

  const sections = useMemo(() => {
    return Object.keys(filterData).map(key => ({
      value: key as FilterCategories,
      label: filterDisplayNameMap[key],
    }))
  }, [filterData])

  const value = useMemo(
    () => ({
      installedDataSystems,
      filterData,
      searchQuery,
      setFilterData,
      handleSearchInputChange,
      dataSystemsV2ListInfiniteArgs,
      isLoading,
      ownerFilterOptions,
      dataCategoryFilterOptions,
      isRefetching,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
      refetch,
      activeFiltersCount,
      filterButtonText,
      sections,
      setCounts,
      resetFilters,
      appListItems,
    }),
    [
      installedDataSystems,
      filterData,
      searchQuery,
      handleSearchInputChange,
      dataSystemsV2ListInfiniteArgs,
      isLoading,
      ownerFilterOptions,
      dataCategoryFilterOptions,
      isRefetching,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
      refetch,
      activeFiltersCount,
      filterButtonText,
      sections,
      resetFilters,
      appListItems,
    ],
  )

  return <Provider value={value}>{children}</Provider>
}

export { useSystemListFilterContext }
