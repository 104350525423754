import { Box, Typography, styled } from '@mui/material'
import { RichTextRenderer } from '../../../../../../RichTextRenderer'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { useContext } from 'react'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { SelectableSection } from '../../../../../../SelectableSection'
import { PreferenceExperienceBuilderSection } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'

const RequestsSubmittedSummaryBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginTop: '24px',
})

const Subtitle = styled(Typography)({
  fontSize: 18,
  fontWeight: 600,
})

export const RequestsSubmittedSummary: React.FC = () => {
  const { themeConfig, experienceConfig, isTheme } = useContext(BuilderContext)

  // Theme config
  const subtitleColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabSubmittedTextSubtitleColor)
  const descriptionColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabSubmittedTextDescriptionColor)

  // Experience config
  const subtitle = getIn(experienceConfig, experienceUpsertFormKeys.preferenceRequestsTabSubmittedSubtitle)
  const descripiton = getIn(experienceConfig, experienceUpsertFormKeys.preferenceRequestsTabSubmittedDescription)

  return (
    <RequestsSubmittedSummaryBox>
      <SelectableSection
        section={isTheme ? undefined : PreferenceExperienceBuilderSection.RequestsSubmittedSubtitle}
        overlayProps={{ margin: '10px 0px' }}
      >
        <Subtitle color={subtitleColor}>{subtitle}</Subtitle>
      </SelectableSection>
      <SelectableSection
        section={isTheme ? undefined : PreferenceExperienceBuilderSection.RequestsSubmittedDescription}
        overlayProps={{ margin: '10px 0px' }}
      >
        <RichTextRenderer text={descripiton} textColor={descriptionColor} />
      </SelectableSection>
    </RequestsSubmittedSummaryBox>
  )
}
