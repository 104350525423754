import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useIdentitySpace } from 'api/identitySpaces/queries/useIdentitySpace'
import { showToast } from 'components/modals/AlertComponent'
import { useDeleteIdentitySpace } from 'api/identitySpaces/mutations/useDeleteIdentitySpace'
import { Box, Typography } from '@mui/material'
import { Button, DataGrid, TabPanel, TableCell, TabGroup, Tab, PopUp } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { WorkflowDefinitionStepSummaryDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { ApplicationIdentitySpace } from 'interfaces/applications/ApplicationFormValues'

type Props = {
  identitySpaceCode: string
  onSubmit?: () => void
  onCancel: () => void
}

export const DeleteIdentitySpaceModal: React.FC<Props> = ({ identitySpaceCode, onSubmit, onCancel }) => {
  const queryClient = useQueryClient()
  const [isBusy, setIsBusy] = useState(false)

  const { data: identitySpace, isLoading } = useIdentitySpace({
    enabled: !!identitySpaceCode,
    params: {
      identitySpaceCode,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch identifier', type: 'error' })
      onCancel()
    },
  })

  const { mutate: handleDeleteIdentitySpace } = useDeleteIdentitySpace({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'identifier deleted', type: 'success' })
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to delete identifier', type: 'error' })
      setIsBusy(false)
    },
  })

  const hasProperties = (identitySpace?.applicationIdentitySpaces?.length || 0) > 0
  // Need to add an ID column for data grid, but there is no unique identifier for this data type
  const properties =
    identitySpace?.applicationIdentitySpaces?.map(p => ({
      ...p,
      id: `${p.key}-${p.format}-${p.applicationCode}-${p.variable}`,
    })) || []

  const hasWorkflowActivities = (identitySpace?.activities?.length || 0) > 0
  const workflowActivities =
    identitySpace?.activities?.map(activity => ({
      ...activity,
      id: activity.id || `${activity.code}-${activity.workflowDefinitionCode}`,
    })) || []

  const [tab, setTab] = useState(hasProperties ? 0 : 1)
  const handleTabChange = (_: never, newValue: number) => {
    setTab(newValue)
  }

  const gridColumns = [
    {
      field: tab === 1 ? 'Workflow' : 'Property',
      headerName: tab === 1 ? 'Workflow' : 'Property',
      renderCell: (params: GridRenderCellParams<WorkflowDefinitionStepSummaryDTO | ApplicationIdentitySpace>) => (
        <TableCell columnGap={1.5}>
          {tab === 1 ? (
            <NameAndCodeCellRenderer
              name={(params.row as WorkflowDefinitionStepSummaryDTO).workflowDefinitionName}
              code={(params.row as WorkflowDefinitionStepSummaryDTO).workflowDefinitionCode}
            />
          ) : (
            <Typography variant="label">
              {(params.row as ApplicationIdentitySpace).applicationName || 'Unnamed Property'}
            </Typography>
          )}
        </TableCell>
      ),
      width: 333,
      sortable: false,
    },
    {
      field: 'Link',
      headerName: '',
      renderCell: (params: GridRenderCellParams<WorkflowDefinitionStepSummaryDTO>) => (
        <TableCell>
          <Button
            color="tertiary"
            onClick={() =>
              window.open(
                RoutesManager.deployment.applications.view.root.getURL({
                  code: params.row.code,
                }),
                '_blank',
                '_noreferrer',
              )
            }
          >
            Open in New Tab
          </Button>
        </TableCell>
      ),
      width: 167,
      sortable: false,
    },
  ]

  return !hasWorkflowActivities && !hasProperties ? (
    <PopUp
      variant="dialog"
      isLoading={isLoading}
      title="Delete Identifier"
      popUpWidth="550px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button
            color="tertiary"
            size="large"
            onClick={() => {
              handleDeleteIdentitySpace({
                params: {
                  identitySpaceCode,
                },
              })
            }}
            pending={isBusy}
          >
            Delete
          </Button>
          <Button color="secondary" size="large" onClick={onCancel}>
            Close
          </Button>
        </>
      }
    >
      <Typography variant="body">
        Are you sure you want to delete <strong>{identitySpace?.name || identitySpace?.code}</strong> Identifier?
      </Typography>
    </PopUp>
  ) : (
    <PopUp
      isLoading={isLoading}
      title="Delete Identifier"
      popUpWidth="550px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Close
          </Button>
        </>
      }
    >
      <Box>
        {hasProperties && hasWorkflowActivities ? (
          <TabGroup value={tab} onChange={handleTabChange}>
            <Tab label="Properties" />
            <Tab label="Activities" />
          </TabGroup>
        ) : null}
        <TabPanel index={0} value={tab} sx={{ mx: 0, px: 0 }}>
          <Typography variant="body" component="p" mb={1.5}>
            To delete the <Typography variant="label">{identitySpace?.name || 'current'}</Typography> Identifier, you
            must remove it from any associated properties or replace it with another one.
          </Typography>
          <Typography variant="body" component="p" mb={3}>
            This Identifier is associated with the following properties:
          </Typography>
          <DataGrid
            sx={{ margin: 0, padding: 0 }}
            columns={gridColumns}
            rows={properties}
            disableColumnResize
            disableColumnFilter
            disableColumnReorder
            disableColumnPinning
            disableAggregation
            disableRowGrouping
            hideFooterRowCount
            disableColumnMenu
            disableRowHoverStates
          />
        </TabPanel>
        <TabPanel index={1} value={tab} sx={{ mx: 0, px: 0 }}>
          <Box>
            <Typography variant="body" component="p" mb={1.5}>
              To delete the <Typography variant="label">{identitySpace?.name || 'current'}</Typography> Identifier, you
              must remove it from any enabled workflows or replace it with another one.
            </Typography>
            <Typography variant="body" component="p" mb={3}>
              This Identifier is used in the following enabled workflows:
            </Typography>
            <DataGrid
              sx={{ margin: 0, padding: 0 }}
              columns={gridColumns}
              rows={workflowActivities}
              disableColumnResize
              disableColumnFilter
              disableColumnReorder
              disableColumnPinning
              disableAggregation
              disableRowGrouping
              hideFooterRowCount
              disableColumnMenu
              disableRowHoverStates
            />
          </Box>
        </TabPanel>
      </Box>
    </PopUp>
  )
}
