import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchSubscriptionTopicOrchestrations } from 'api/subscriptions/fetchers/fetchSubscriptionTopicOrchestrations'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.subscriptionTopicOrchestrations,
  queryFn: fetchSubscriptionTopicOrchestrations,
  select: res => res?.data || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useSubscriptionTopicOrchestrations = (config?: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
