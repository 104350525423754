import { ContentGroup, theme } from '@ketch-com/deck'
import { Box, Typography, styled } from '@mui/material'
import React from 'react'
import { RoutesManager } from 'utils/routing/routesManager'
import { useSidebarRoutesConfig } from 'utils/routing/useSidebarRoutesConfig'
import { HomeOverviewSection } from './HomeOverviewSection'
const SectionsBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr);',
  rowGap: '24px',
  columnGap: '32px',
  paddingBottom: '48px',
})

export const SectionLinks: React.FC = () => {
  // Section links
  const routes = useSidebarRoutesConfig().filter(section => section.path !== RoutesManager.home.root.getURL())
  const sectionHeadings = routes.map(({ title, description }, index) => (
    <ContentGroup
      key={index}
      titleCustomComponent={
        <Box sx={{ lineHeight: '20.3px' }}>
          <Typography variant="h3">{title}</Typography>
          <Typography
            component={'p'}
            lineHeight="20.3px !important"
            color={theme.palette.Text.Secondary}
            sx={{ wordBreak: 'normal', height: '60px !important' }}
          >
            {description}
          </Typography>
        </Box>
      }
    />
  ))
  const sectionLinks = routes.map(({ subRoutes }, index) => <HomeOverviewSection key={index} items={subRoutes} />)

  return (
    <SectionsBox>
      {sectionHeadings}
      {sectionLinks}
    </SectionsBox>
  )
}
