import React from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { useFormikContext, ErrorMessage } from 'formik'
import { Box, Typography } from '@mui/material'
import { SubscriptionTopicFormData } from 'pages/policyCenter/subscriptions/interfaces'
import { FormRow, theme } from '@ketch-com/deck'
import { SubscriptionContactMethodDTO, WebhookDTO, AppDTO } from '@ketch-com/figurehead'
import { Orchestration } from 'pages/policyCenter/subscriptions/subscriptionTopic/upsert/components'
import { FormSwitch } from 'components/form/FormSwitch'

const PREFIX = 'ContactMethods'

const classes = {
  contactMethodItemBackground: `${PREFIX}-contactMethodItemBackground`,
  contactMethodItemBorder: `${PREFIX}-contactMethodItemBorder`,
  contactMethodItemMargin: `${PREFIX}-contactMethodItemMargin`,
}

const Root = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.contactMethodItemBorder}`]: {
    borderBottom: `1px solid ${palette.iron.main}`,
  },
  [`& .${classes.contactMethodItemBackground}`]: {
    backgroundColor: palette.superLightGrey.main,
    border: 'none',
    borderRadius: theme.spacing(2),
  },
  [`& .${classes.contactMethodItemMargin}`]: {
    margin: theme.spacing(0, 0, 2),
  },
}))

type Props = {
  subscriptionContactMethods: SubscriptionContactMethodDTO[]
  webhooksData: WebhookDTO[]
  systemsData: AppDTO[]
}
export const ContactMethods: React.FC<Props> = ({
  subscriptionContactMethods = [],
  webhooksData = [],
  systemsData = [],
}) => {
  const { isSubmitting, values } = useFormikContext<SubscriptionTopicFormData>()
  const { contactMethods } = values

  subscriptionContactMethods.sort((a, b) => {
    if (!a.name && !b.name) return 0
    if (!a.name) return 1
    if (!b.name) return -1
    return a.name.localeCompare(b.name)
  })

  return (
    <Root>
      <FormRow
        hasTopBorder
        title="Communication Channels"
        subTitle="Select the channels this Subscription will be broadcasted on. Options you select here might be available for end-users to choose from in the experience."
      >
        <ErrorMessage name="contactMethods">
          {msg => {
            if (typeof msg === 'string')
              return (
                <Typography fontWeight={400} fontSize={12} color="chileanFire.main">
                  {msg}
                </Typography>
              )
            return null
          }}
        </ErrorMessage>
        {subscriptionContactMethods.map((channel, index) => {
          const { code = '', name = '' } = channel
          return (
            <Box
              key={code}
              display="flex"
              flexDirection="column"
              gap={2}
              p={2}
              className={clsx({
                [classes.contactMethodItemBackground]: contactMethods?.[code]?.status,
                [classes.contactMethodItemBorder]:
                  !(subscriptionContactMethods.length - 1 === index) &&
                  !contactMethods?.[subscriptionContactMethods[index + 1].code].status,
                [classes.contactMethodItemMargin]:
                  !(subscriptionContactMethods.length - 1 === index) &&
                  contactMethods?.[subscriptionContactMethods[index].code].status &&
                  contactMethods?.[subscriptionContactMethods[index + 1].code].status,
              })}
            >
              <Box display="flex" alignItems="center" gap={3}>
                <FormSwitch size="medium" formPropertyName={`contactMethods.${code}.status`} disabled={isSubmitting} />
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    variant="h4"
                    sx={{
                      color: ({ palette }) => (contactMethods?.[code]?.status ? palette.greenHaze.main : 'none'),
                    }}
                  >
                    {name}
                  </Typography>
                  <Typography variant="footnote" color="darkDuskFaded.main">
                    Code: {code}
                  </Typography>
                </Box>
              </Box>
              {contactMethods?.[code]?.status ? (
                <Orchestration webhooksData={webhooksData} systemsData={systemsData} channel={channel} />
              ) : null}
            </Box>
          )
        })}
      </FormRow>
    </Root>
  )
}
