import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { Orchestration } from 'pages/policyCenter/subscriptions/subscriptionControl/upsert/components'
import { SubscriptionControlFormData } from 'pages/policyCenter/subscriptions/interfaces'
import {
  getSubscriptionControlFormInitialValues,
  getSubscriptionControlFormValidationSchema,
  getSortedControlTranslations,
} from 'pages/policyCenter/subscriptions/utils'
import {
  SubscriptionControlDTO,
  WebhookDTO,
  GetSubscriptionControlOrchestrationsResponseBodyDTO,
  AppDTO,
} from '@ketch-com/figurehead'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'

type Props = {
  onSubmit: (values: SubscriptionControlFormData) => void
  isReady: boolean
  subscriptionControl: SubscriptionControlDTO
  organizationLanguages: LanguageWithStatusDTO[]
  webhooksData: WebhookDTO[]
  systemsData: AppDTO[]
  subscriptionControlOrchestrations: GetSubscriptionControlOrchestrationsResponseBodyDTO
}
export const EditSubscriptionControl: React.FC<Props> = ({
  onSubmit,
  isReady,
  subscriptionControl,
  organizationLanguages,
  webhooksData,
  systemsData,
  subscriptionControlOrchestrations,
}) => {
  const navigate = useNavigate()
  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Subscriptions', link: RoutesManager.policyCenter.subscriptions.list.subscriptionControls.root.getURL() },
    { title: 'Edit' },
  ]

  // Include new languages added to the org or all languages incase of no translation
  organizationLanguages?.forEach?.(orgLang => {
    const hasLanguage = Boolean(subscriptionControl.translations?.[orgLang?.language?.code])
    if (!orgLang.system && !hasLanguage) {
      if (!subscriptionControl.translations) subscriptionControl.translations = {}
      subscriptionControl.translations[orgLang?.language?.code] = {}
    }
  })

  // BE has translations map arranged by langCode instead of langName
  subscriptionControl.translations = getSortedControlTranslations(
    subscriptionControl.translations,
    organizationLanguages,
  )
  const initialValues = getSubscriptionControlFormInitialValues({
    subscriptionControl,
    subscriptionControlOrchestrations,
  })
  const validationSchema = getSubscriptionControlFormValidationSchema({
    webhooksData,
    systemsData,
  })

  const formikProps = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
  })

  const { isSubmitting, submitForm } = formikProps

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={UpsertLayoutMode.edit}
          renderFormTitle={() => <ContentGroup title="Edit Subscription" titleVariant="h2" isDivider />}
          onAccept={submitForm}
          onCancel={() => {
            navigate(RoutesManager.policyCenter.subscriptions.list.subscriptionControls.root.getURL())
          }}
          acceptActionButtonText="Save"
          cancelActionButtonText="Cancel"
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {isReady ? (
              <Orchestration webhooksData={webhooksData} systemsData={systemsData} />
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%" m={6}>
                <Spinner size="32px" thickness={2.5} />
              </Box>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
