import { Icon, InfoRow, theme } from '@ketch-com/deck'
import { Box, styled, Tooltip, Typography } from '@mui/material'
import { UserRolesDisplayData } from '../utils/getUserRolesDisplayData'

interface Props {
  data: UserRolesDisplayData
}

const ContentBox = styled(Box)({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
})

const TooltipBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

export const RolePermissionInfoRow: React.FC<Props> = ({ data }) => {
  const { section, description, tooltips, permission } = data

  const tooltip =
    tooltips.find(t => permission.toLowerCase().includes(t.permLevelKey)) ||
    tooltips.find(t => t.permLevelKey === 'none')

  return (
    <InfoRow
      title={section}
      titleAddendum={description}
      marginTop="32px"
      sx={{
        '.InfoRow-helpTextTitle': { fontWeight: '600 !important', color: `${theme.palette.Text.Secondary} !important` },
        '.InfoRow-helpTextAddendum': {
          fontSize: '14px !important',
          color: `${theme.palette.Text.Secondary} !important`,
        },
      }}
    >
      <ContentBox>
        <Typography variant="label">{permission}</Typography>
        <Tooltip
          placement="bottom"
          title={
            <TooltipBox>
              <Typography variant="smallLabel">{tooltip?.permLevelTitle}</Typography>
              <Typography variant="smallBody">{tooltip?.permLevelDescription}</Typography>
            </TooltipBox>
          }
        >
          <Box display="flex">
            <Icon name="FUnknown" width={16} height={16} iconColor={theme.palette.Black.o48} />
          </Box>
        </Tooltip>
      </ContentBox>
    </InfoRow>
  )
}
