import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { ASSET_TYPE_ENUM } from 'interfaces/assets'
import { getDoesAssetSupportIdentitySpace } from 'pages/assetManager/components/EditAssetForm/utils'
import { detailViewInfoRowConfig } from 'pages/assetManager/constants/detailViewInfoRowConfig'
import { GetDataPreviewResponseBody } from 'pages/assetManager/interfaces'

export const useInfoRowsConfig = (assetSummary?: AssetSummaryDTO, dataPreview?: GetDataPreviewResponseBody) => {
  const filterConditions: any[] = []
  const doesAssetSupportIdentitySpace = getDoesAssetSupportIdentitySpace(assetSummary)
  const doesAssetSupportAutoDiscovery =
    assetSummary?.asset?.resource?.resourceType?.canonicalResourceType ===
    ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATABASE

  if (!doesAssetSupportIdentitySpace) {
    filterConditions.push('Identifier')
  }

  if (!doesAssetSupportAutoDiscovery) {
    filterConditions.push('Deep Classification')
  }

  if (!dataPreview) filterConditions.push('Data Preview')

  const payload = [...detailViewInfoRowConfig].filter(infoRowTitle => {
    return filterConditions.indexOf(infoRowTitle) === -1
  })

  return payload
}
