import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useDeploymentPlan } from 'api/deploymentPlans/queries/useDeploymentPlan'
import { useDeploymentPlanVersions } from 'api/deploymentPlans/queries/useDeploymentPlanVersions'
import { useDeploymentPlanVersion } from 'api/deploymentPlans/queries/useDeploymentPlanVersion'
import { DeploymentPlanView } from 'pages/consentAndRights/deploymentPlans/view/DeploymentPlanView'

export const DeploymentPlanViewContainer: React.FC = () => {
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()
  const { queries } = useQueryParams<{ version?: string }>()

  const { version } = queries

  const onError = () => {
    navigate(RoutesManager.deployment.deploymentPlans.root.getURL())
  }

  const { data: deploymentPlan, isLoading: isDeploymentPlanLoading } = useDeploymentPlan({
    params: {
      deploymentPlanCode: code!,
      includeIssues: true,
      includeMetadata: true,
    },
    onError,
  })

  const { data: deploymentPlanVersion, isLoading: isDeploymentPlanVersionLoading } = useDeploymentPlanVersion({
    enabled: !!version && !!deploymentPlan,
    params: {
      deploymentPlanCode: deploymentPlan?.code as string,
      version: version!,
      includeIssues: true,
      includeMetadata: true,
    },
    onError,
  })

  const { data: versions, isLoading: isVersionsLoading } = useDeploymentPlanVersions({
    enabled: !!deploymentPlan,
    params: {
      deploymentPlanCode: deploymentPlan?.code as string,
      includeMetadata: true,
      ordering: {
        version: 'desc',
      },
    },
    onError,
  })

  const isReady = !isVersionsLoading && !isDeploymentPlanLoading && !isDeploymentPlanVersionLoading

  return (
    <DeploymentPlanView
      isReady={isReady}
      deploymentPlan={version ? deploymentPlanVersion : deploymentPlan}
      versions={versions}
    />
  )
}
