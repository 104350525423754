import { Box } from '@mui/material'
import { ReactComponent as NoIcon } from 'assets/icons/no.svg'
import { ReactComponent as YesIcon } from 'assets/icons/green_check.svg'
import { Text } from 'components/ui-kit/typography/Text'

type Props = {
  enabled: boolean
  text: string
}

export const ContentChip: React.FC<Props> = ({ enabled, text }) => {
  return (
    <Box
      sx={({ palette, spacing }) => ({
        color: enabled ? palette.greenHaze.main : palette.darkDuskFaded.main,
        backgroundColor: palette.white.main,
        padding: spacing(0.5, 1.5, 0.5, 1),
        margin: spacing(0, 1, 1, 0),
      })}
      display="inline-flex"
      gap={0.5}
      alignItems="center"
      borderRadius={100}
    >
      {enabled ? <YesIcon /> : <NoIcon />}
      <Text color={enabled ? 'greenHaze' : 'darkDuskFaded'} weight={600}>
        {text}
      </Text>
    </Box>
  )
}
