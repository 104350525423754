import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { APIListRequestParams } from 'api/common/utils'
import { fetchRelationshipsPaginatedV2 } from '../fetchers/fetchRelationshipsPaginatedV2'
import { FindRelationshipsResponseBodyDTO, MappingDTO } from '@ketch-com/figurehead'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type LastPage = FindRelationshipsResponseBodyDTO

type Params = APIListRequestParams<{
  type?: number
}>

type Options = {
  enabled?: boolean
}

type UseRelationshipsInfiniteArgs = { options?: Options; params: Params }

export const useRelationshipsInfiniteV2 = ({
  options: { enabled = true } = {},
  params,
}: UseRelationshipsInfiniteArgs) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )

  const { limit = 50 } = params

  const resp = useInfiniteQuery({
    enabled: !!enabled,
    queryKey: [
      ApiQueryKeys.relationshipsInfinite,
      {
        ...params,
      },
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchRelationshipsResp = await fetchRelationshipsPaginatedV2({
        limit,
        start: (pageParam - 1) * limit,
        isV2FeatureFlagEnabled,
        ...params,
      })

      const { data } = fetchRelationshipsResp

      data.relationships = data.relationships ?? []
      data.totalResults = data.totalResults ?? 0

      return data
    },
    getNextPageParam: ({ totalResults }: LastPage, allPages: LastPage[]) => {
      let totalRelationshipsFetchedSoFar = 0
      allPages?.forEach?.(p => {
        totalRelationshipsFetchedSoFar += (p?.relationships || []).length
      })
      const nextPageParam = Math.ceil(totalRelationshipsFetchedSoFar / limit) + 1
      const getNextPageParamReturnValue = totalRelationshipsFetchedSoFar === totalResults ? undefined : nextPageParam
      return getNextPageParamReturnValue
    },
    staleTime: 1000 * 60 * 5, // five minutes
  })

  let infiniteRelationships: MappingDTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    infiniteRelationships = [...infiniteRelationships, ...(page?.relationships || [])]
    totalResults = page?.totalResults || 0
  })

  const payload = {
    ...resp,
    data: infiniteRelationships,
    totalResults,
  }

  return payload
}
