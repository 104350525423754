import { ICanvasStep } from '../../interfaces'
import { getUuidWithoutHyphensAndStartingWithALetter } from '../getUuidWithoutHyphensAndStartingWithALetter'

/**
 * Replaces step IDs in an array of canvas steps with collision-free duplicated IDs.
 * @param {Array<ICanvasStep>} steps - The array of canvas steps.
 * @returns {Array<ICanvasStep>} The array of canvas steps with replaced step IDs.
 */
export const getStepsWithReplacedStepIdsForCollisionFreeDuplication = (steps: Array<ICanvasStep>) => {
  // Collect all step IDs as strings
  const collectedStepIds = steps.map(step => String(step.ID)).filter(Boolean)
  let stringifiedSteps = JSON.stringify(steps)

  // Replace step IDs with newly generated IDs
  collectedStepIds.forEach(stepId => {
    const newStepId = getUuidWithoutHyphensAndStartingWithALetter()
    stringifiedSteps = stringifiedSteps.replaceAll(stepId, newStepId)
  })

  // Parse the modified JSON string back into an array of canvas steps
  const payload = JSON.parse(stringifiedSteps) as Array<ICanvasStep>

  return payload
}
