export enum AssetTypeEnum {
  CANONICAL_RESOURCE_TYPE_DATABASE = 1,
  CANONICAL_RESOURCE_TYPE_DATASET = 2,
  CANONICAL_RESOURCE_TYPE_ATTRIBUTE = 3,
}

export enum AssetTypeAccessorEnum {
  CANONICAL_RESOURCE_TYPE_DATABASE = 'CANONICAL_RESOURCE_TYPE_DATABASE',
  CANONICAL_RESOURCE_TYPE_DATASET = 'CANONICAL_RESOURCE_TYPE_DATASET',
  CANONICAL_RESOURCE_TYPE_ATTRIBUTE = 'CANONICAL_RESOURCE_TYPE_ATTRIBUTE',
}

export const ASSET_TYPE_ENUM = {
  CANONICAL_RESOURCE_TYPE_DATABASE: 1,
  CANONICAL_RESOURCE_TYPE_DATASET: 2,
  CANONICAL_RESOURCE_TYPE_ATTRIBUTE: 3,
}

export const ASSET_TYPE_NAMES: {
  [key: number]: string
} = {
  0: 'unspecified',
  [AssetTypeEnum.CANONICAL_RESOURCE_TYPE_DATABASE]: 'database',
  [AssetTypeEnum.CANONICAL_RESOURCE_TYPE_DATASET]: 'dataset',
  [AssetTypeEnum.CANONICAL_RESOURCE_TYPE_ATTRIBUTE]: 'attribute',
}

export const ASSET_TYPE_NAME_TO_ENUM: {
  [key: string]: number
} = {
  database: AssetTypeEnum.CANONICAL_RESOURCE_TYPE_DATABASE,
  schema: AssetTypeEnum.CANONICAL_RESOURCE_TYPE_DATASET,
  dataset: AssetTypeEnum.CANONICAL_RESOURCE_TYPE_ATTRIBUTE,
}

export const ASSET_TYPE_OPTIONS = [
  {
    id: AssetTypeEnum.CANONICAL_RESOURCE_TYPE_DATABASE,
    name: ASSET_TYPE_NAMES[AssetTypeEnum.CANONICAL_RESOURCE_TYPE_DATABASE],
  },
  {
    id: AssetTypeEnum.CANONICAL_RESOURCE_TYPE_DATASET,
    name: ASSET_TYPE_NAMES[AssetTypeEnum.CANONICAL_RESOURCE_TYPE_DATASET],
  },
  {
    id: AssetTypeEnum.CANONICAL_RESOURCE_TYPE_ATTRIBUTE,
    name: ASSET_TYPE_NAMES[AssetTypeEnum.CANONICAL_RESOURCE_TYPE_ATTRIBUTE],
  },
]
