import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { Chip } from '@ketch-com/deck'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { SENSITIVE_DEPLOYMENT_PLANS_STATUSES } from 'interfaces/deploymentPlans-v2/deploymentPlanStatus'
import { Text } from 'components/ui-kit/typography/Text'
import { Status } from 'components/ui-kit/status/Status'
import { Badge } from 'components/ui-kit/badge/Badge'
import { Group } from 'components/ui-layouts/group/Group'

const PREFIX = 'DeploymentPlanViewDetails'

const classes = {
  codeText: `${PREFIX}-codeText`,
}

const StyledGroup = styled(Group)(({ theme }) => ({
  [`& .${classes.codeText}`]: {
    color: theme.palette.darkChrome.main,
  },
}))

type Props = {
  isLatest: boolean
  deploymentPlan: DeploymentPlanDTO
}

export const DeploymentPlanViewDetails: React.FC<Props> = ({ isLatest, deploymentPlan }) => {
  const { code, status, version, isOrchestrationOnly } = deploymentPlan

  return (
    <StyledGroup>
      {SENSITIVE_DEPLOYMENT_PLANS_STATUSES.includes(status) ? (
        <Status size="big" variant="active">
          Deployed
        </Status>
      ) : (
        <Status size="big" variant="inactive">
          Undeployed
        </Status>
      )}

      {isOrchestrationOnly ? (
        <Chip
          clickable
          size="small"
          label={<Typography variant="smallLabel">Quiet Mode</Typography>}
          sx={{ cursor: 'auto' }}
        />
      ) : null}

      {!isLatest && <Badge variant="outline">v{version}</Badge>}

      <Group gap={3}>
        <Text className={classes.codeText} size={10} weight={700} transform="uppercase">
          Code
        </Text>
        <Text className={classes.codeText} size={11}>
          {code}
        </Text>
      </Group>
    </StyledGroup>
  )
}
