import { useHeaderRoutesConfig } from 'utils/routing/useHeaderRoutesConfig'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useAppNavigationMode } from '.'
import { useIsKetchFree } from 'utils/hooks/useIsKetchFree'
import { useEffect, useRef, useState } from 'react'
import { AppNavigationSize, SidebarMode } from '../types'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppNavigationSize } from './useAppNavigationSize'
import { getNextSidebarModeOnHover, getNextSidebarModeOnResize } from './getNextSidebarMode'
import { SidebarSearchResult, SidebarSection } from '../components/navigationSidebar/utils'

export const useAppNavigationUtils = () => {
  // NEW

  const [sidebarMode, setSidebarMode] = useState<SidebarMode>(SidebarMode.Compact)
  const [isSidebarHovered, setIsSidebarHovered] = useState(false)
  const [showCreateOverlay, setShowCreateOverlay] = useState(false)
  const [sidebarOpenSection, setSidebarOpenSection] = useState<SidebarSection | undefined>()
  const [sidebarSearchResults, setSidebarSearchResults] = useState<SidebarSearchResult[]>([])
  const [sidebarSearch, setSidebarSearch] = useState('')
  const [searchActive, setSearchActive] = useState(false)
  const searchInputRef = useRef<HTMLInputElement>(null)
  const appContentRef = useRef<HTMLDivElement>(null)

  // Not permitted / entitled modals
  const [showNotPermittedModal, setShowNotPermittedModal] = useState(false)
  const [showNotEntitledModal, setShowNotEntitledModal] = useState(false)
  const [missingEntitlements, setMissingEntitlements] = useState<ENTITLEMENTS[]>([])

  // undefined === not pinned, false === pinned closed, true === pinned open
  const [isSidebarPinned, setIsSidebarPinned] = useState<boolean | undefined>()
  const appNavigationSize = useAppNavigationSize()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isPermitted } = useIsPermitted()
  const { isEntitled } = useIsEntitled()

  // Determine new sidebar mode on window resize
  useEffect(() => {
    if ((isSidebarPinned === true || isSidebarPinned === false) && appNavigationSize !== AppNavigationSize.FullSize)
      setIsSidebarPinned(undefined)

    setSidebarMode(getNextSidebarModeOnResize(appNavigationSize))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appNavigationSize])

  // Determine new sidebar mode on hover
  useEffect(() => {
    setSidebarMode(getNextSidebarModeOnHover(sidebarMode, isSidebarHovered, appNavigationSize))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarHovered])

  // Clear search and set inactive whenever sidebar mode changes
  useEffect(() => {
    if (sidebarMode !== SidebarMode.Open && sidebarMode !== SidebarMode.Overlay) setSidebarSearch('')
    setSearchActive(false)
  }, [sidebarMode])

  // Reset scroll of app content to top whenever pathname changes
  useEffect(() => {
    if (appContentRef.current) appContentRef.current.scrollTop = 0
  }, [pathname])

  // OLD
  const isEntitledToDemo = isEntitled(ENTITLEMENTS.DEMO)
  const headerRoutesConfig = useHeaderRoutesConfig({ isEntitledToDemo })
  const { isKetchFree } = useIsKetchFree()
  const shouldShowDevelopersIcon = isPermitted([
    PERMISSIONS.TRANSPONDER_READ,
    PERMISSIONS.API_KEY_READ,
    PERMISSIONS.HOOK_READ,
    PERMISSIONS.WEBHOOK_READ,
    PERMISSIONS.AUTHENTICATION_READ,
  ])
  const { isViewMode, isCreateMode, isEditMode, isPreviewMode } = useAppNavigationMode()
  const isRegularSize = !isEditMode && !isCreateMode && !isViewMode && !isPreviewMode
  const subItemsType: 'light' | 'dark' = isEditMode || isCreateMode || isPreviewMode ? 'light' : 'dark'
  const subItemsSize: 'small' | 'regular' =
    isEditMode || isCreateMode || isViewMode || isPreviewMode ? 'small' : 'regular'

  const payload = {
    // New
    sidebarMode,
    setSidebarMode,
    isSidebarPinned,
    setIsSidebarPinned,
    appNavigationSize,
    sidebarOpenSection,
    setSidebarOpenSection,
    isSidebarHovered,
    setIsSidebarHovered,
    showCreateOverlay,
    setShowCreateOverlay,
    sidebarSearchResults,
    setSidebarSearchResults,
    searchActive,
    setSearchActive,
    sidebarSearch,
    setSidebarSearch,
    searchInputRef,
    appContentRef,
    showNotPermittedModal,
    setShowNotPermittedModal,
    showNotEntitledModal,
    setShowNotEntitledModal,
    missingEntitlements,
    setMissingEntitlements,
    navigate,
    isPermitted,
    isEntitled,

    // Old
    subItemsType,
    subItemsSize,
    headerRoutesConfig,
    shouldShowDevelopersIcon,
    isCreateMode,
    isEditMode,
    isViewMode,
    isPreviewMode,
    isRegularSize,
    isKetchFree,
  }

  return payload
}

export type UseAppNavigationUtilsReturnType = ReturnType<typeof useAppNavigationUtils>
