import React from 'react'

import { IdentitySpacesList } from 'pages/settings/identitySpaces/list/IdentitySpacesList'
import { useIdentitySpacesPaginated } from 'api/identitySpaces/queries/useIdentitySpacePaginated'

export const IdentitySpacesListContainer: React.FC = () => {
  const {
    data: identitySpaces,
    isLoading,
    isFetching,
    pagination,
  } = useIdentitySpacesPaginated({
    params: {
      includeMetadata: true,
      includeIssues: true,
    },
  })

  return (
    <IdentitySpacesList
      isLoading={isLoading}
      isFetching={isFetching}
      identitySpaces={identitySpaces}
      pagination={pagination}
    />
  )
}
