import { ContextVariableCategoryDTO } from '@ketch-com/figurehead'

export const CONTEXT_VARIABLE_TYPES = {
  STRING: 'String',
  NUMBER: 'Number',
  BOOLEAN: 'Boolean',
  LIST: 'Enum',
}

export const dataTypeOptions = [
  { id: '4', variableTypeName: CONTEXT_VARIABLE_TYPES.STRING },
  { id: '3', variableTypeName: CONTEXT_VARIABLE_TYPES.BOOLEAN },
  { id: '2', variableTypeName: CONTEXT_VARIABLE_TYPES.NUMBER },
  { id: '1', variableTypeName: CONTEXT_VARIABLE_TYPES.LIST },
]

export enum ContextVariableType {
  String = 4,
  Boolean = 3,
  Number = 2,
  List = 1,
}

export const contextVariableCategoryOptions = [
  {
    id: ContextVariableCategoryDTO.DataSubjectContextVariableCategory,
    label: 'Data Subject',
    description: 'Data Subject attribute that is not collected during request intake.',
  },
  {
    id: ContextVariableCategoryDTO.OutcomeContextVariableCategory,
    label: 'Outcome',
    description: 'Attribute relating to the outcome of Workflow Activities.',
  },
]

export const upsertContextVariableCategoryOptions = [
  {
    id: ContextVariableCategoryDTO.DataSubjectContextVariableCategory,
    label: 'Data Subject Variable',
    description: 'Data Subject attribute that is not collected during request intake.',
  },
  {
    id: ContextVariableCategoryDTO.OutcomeContextVariableCategory,
    label: 'Outcome Variable',
    description: 'Attribute relating to the outcome of Workflow Activities.',
  },
]
