import { Box } from '@mui/material'
import { Button, Icon } from '@ketch-com/deck'
import { useContext } from 'react'
import { WorkflowCanvasContext } from '../WorkflowCanvas/context/WorkflowCanvasContext'
import { styled } from '@mui/styles'
import Typography from '@mui/material/Typography'

const UndoRedoContainer = styled(Box)({
  display: 'flex',
  gap: '8px',
  position: 'absolute',
  top: 12,
  zIndex: 10,
})

const UndoRedoButton = styled(Button)({
  background: 'white',
  boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.20), 0px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  borderRadius: '100px',
  height: '32px',
  width: '76px',
})

export const CanvasUndoRedo: React.FC = () => {
  const { sidebarWidth, handleUndo, handleRedo, canUndo, canRedo } = useContext(WorkflowCanvasContext)

  return (
    <UndoRedoContainer left={sidebarWidth + 68}>
      <UndoRedoButton
        onClick={handleUndo}
        disabled={!canUndo}
        variant="flat"
        endIcon={<Icon name={'OUndo'} width={16} height={16} />}
        enableShadowEffect
      >
        <Typography variant="smallLabel" pr={0.25}>
          Undo
        </Typography>
      </UndoRedoButton>
      <UndoRedoButton
        onClick={handleRedo}
        disabled={!canRedo}
        variant="flat"
        endIcon={<Icon name={'ORedo'} width={16} height={16} />}
        enableShadowEffect
      >
        <Typography variant="smallLabel" pr={0.25}>
          Redo
        </Typography>
      </UndoRedoButton>
    </UndoRedoContainer>
  )
}
