import React from 'react'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { ReactComponent as KetchSecondaryGlyphViolet } from 'assets/icons/ketchSecondaryGlyphViolet.svg'
import { Box, Typography } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { Link } from 'components/ui-kit/link/Link'
import { useStyles } from '../hooks/useStyles'

type Props = {
  children?: React.ReactNode
  isSuccessful?: boolean
}
export const RegistrationLayout: React.FC<Props> = ({ children, isSuccessful }) => {
  const classes = useStyles()

  return (
    <Flex className={classes.columnContainer}>
      <Box display="flex" justifyContent="center" mb={8}>
        <KetchSecondaryGlyphViolet />
      </Box>
      {children}

      {!isSuccessful && (
        <Box mt={4}>
          <Text className={classes.signInOption}>
            Already have an account?{' '}
            <Link to="/">
              <Typography variant="labelLink" color="sphere.main">
                Sign In
              </Typography>
            </Link>
          </Text>
        </Box>
      )}
    </Flex>
  )
}
