/**
 * Generic stack implementation. A limit parameter may be included to cap the number of items.
 */

export interface StackType<T> {
  push(item: T): void
  pop(): T | undefined
  peek(): T | undefined
  isEmpty(): boolean
  size(): number
}

export class Stack<T> implements StackType<T> {
  _items: T[] = [] // Allow accessing _items for flexibility, but shouldn't be needed in most cases
  private readonly limit: number

  constructor(limit: number = Infinity) {
    this.limit = limit
  }

  push(item: T): void {
    if (this.isFull()) {
      // Remove the last item if the stack is full
      this._items.shift()
    }
    this._items.push(item)
  }

  pop(): T | undefined {
    return this._items.pop()
  }

  peek(): T | undefined {
    return this._items[this._items.length - 1]
  }

  isFull(): boolean {
    return this._items.length >= this.limit
  }

  isEmpty(): boolean {
    return this._items.length === 0
  }

  size(): number {
    return this._items.length
  }

  clear(): void {
    this._items = []
  }
}
