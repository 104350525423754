import { APIRequestParams } from 'api/common/utils'
import { GetFormFieldResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  formFieldId: string
  includeMetadata?: boolean
  includeAuthenticators?: boolean
}>

export const fetchFormField = ({ formFieldId, ...filters }: Params) =>
  API.get<GetFormFieldResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/form-fields/${formFieldId}`,
      params: {
        filters: {
          ...filters,
        },
      },
    }),
  )
