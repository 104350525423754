import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { ControllerDTO, ControllerFormDTO } from 'interfaces/controllers/controller'

type Params = {
  organizationCode: string
  formData: ControllerFormDTO
}

type Response = { controller: ControllerDTO }

export const createController = ({ organizationCode, formData }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/controllers`,
    }),
    formData,
  )
