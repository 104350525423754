import React from 'react'

import { PolicyDocumentDTO, PolicyDocumentType, POLICY_DOCUMENT_NAMES } from 'interfaces/policyDocuments/policyDocument'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { Text } from 'components/ui-kit/typography/Text'
import { Table } from 'components/ui-layouts/table/Table'
import { DeploymentPlanDocumentAssignment } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/deploymentPlanDocuments/DeploymentPlanDocumentAssignment'

const items = [
  {
    policyDocumentType: PolicyDocumentType.PRIVACY_POLICY,
    name: POLICY_DOCUMENT_NAMES[PolicyDocumentType.PRIVACY_POLICY],
  },
  {
    policyDocumentType: PolicyDocumentType.TERMS_OF_SERVICE,
    name: POLICY_DOCUMENT_NAMES[PolicyDocumentType.TERMS_OF_SERVICE],
  },
]

type Props = {
  policyDocuments: PolicyDocumentDTO[]
  editMode: boolean
}

export const DeploymentPlanDocuments: React.FC<Props> = ({ policyDocuments, editMode }) => (
  <FormSection title="Documents">
    <Table
      items={items}
      cellSettings={{
        documentType: {
          label: 'Document Type',
          width: 250,
          cellRenderer: ({ policyDocumentType }) => (
            <Text size={14} weight={600}>
              {POLICY_DOCUMENT_NAMES[policyDocumentType]}
            </Text>
          ),
        },
        assignedDocuments: {
          label: 'Assigned Documents',
          cellRenderer: ({ policyDocumentType }) => (
            <DeploymentPlanDocumentAssignment
              policyDocumentType={policyDocumentType}
              policyDocuments={policyDocuments}
              editMode={editMode}
            />
          ),
        },
      }}
    />
  </FormSection>
)
