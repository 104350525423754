import React, { createContext } from 'react'
import { useTemplatesListUtils, UseTemplatesListUtilsReturn } from '../hooks'

export const TemplatesContext = createContext({} as UseTemplatesListUtilsReturn)

type Props = {
  children?: React.ReactNode
}

export const TemplatesContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useTemplatesListUtils()

  return (
    <TemplatesContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </TemplatesContext.Provider>
  )
}
