import { Box, Typography, styled } from '@mui/material'
import { RichTextRenderer } from '../../../../RichTextRenderer'
import { useContext } from 'react'
import { BuilderContext } from '../../../../../Builder'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { Icon } from '@ketch-com/deck'
import {
  BuilderImageVariant,
  PreferenceExperienceBuilderSection,
  PreferenceThemeBuilderSection,
} from '../../../../../utils/enums'
import { SelectableSection } from '../../../../SelectableSection'
import { ImageContainer } from '../../ImageContainer'
import { SectionEmptyState } from '../../../../SectionEmptyState'

const WelcomeTabBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const WelcomeTextContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '48px',
}))

const WelcomeTextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  maxWidth: '592px',
  wordWrap: 'break-word',
}))

const QuickLinksBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}))

const QuickLinkBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
}))

const AboutTextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  maxWidth: '592px',
  wordWrap: 'break-word',
}))

export const WelcomeTab: React.FC = () => {
  const { themeConfig, experienceConfig, isTheme } = useContext(BuilderContext)

  // Get theme config values
  const heroBannerVisible = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabHeroBannerVisible)
  const heroBannerUrl = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabHeroBannerImageUrl)
  const welcomeTextTitleColor = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabWelcomeMsgTitleColor)
  const welcomeTextSubtitleColor = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabWelcomeMsgSubtitleColor)
  const welcomeTextLinkColor = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabWelcomeMsgLinkColor)
  const welcomeTextLinkUnderline = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabWelcomeMsgLinkUnderline)
  const quickLinksTextColor = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkColor)
  const quickLinksUnderline = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkUnderline)
  const quickLinkShowArrow = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabQuickLinksShowArrows)
  const aboutTitleColor = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabAboutTitleColor)
  const aboutDescriptionColor = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabAboutTextColor)
  const aboutLinkColor = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabAboutLinkColor)
  const aboutLinkUnderline = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabAboutLinkUnderline)

  // Get experience config values
  const welcomeTextVisible = getIn(experienceConfig, experienceUpsertFormKeys.preferenceWelcomeTabWelcomeMsgVisible)
  const welcomeTextTitle = getIn(experienceConfig, experienceUpsertFormKeys.preferenceWelcomeTabWelcomeMsgTitle)
  const welcomeTextSubTitle = getIn(experienceConfig, experienceUpsertFormKeys.preferenceWelcomeTabWelcomeMsgSubtitle)
  const quickLinksVisible = getIn(experienceConfig, experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksVisible)
  const quickLinkOneText = getIn(experienceConfig, experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkOneTitle)
  const quickLinkTwoText = getIn(experienceConfig, experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkTwoTitle)
  const quickLinkThreeText = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkThreeTitle,
  )

  const quickLinks = [quickLinkOneText, quickLinkTwoText, quickLinkThreeText]

  const aboutTitleVisible = getIn(experienceConfig, experienceUpsertFormKeys.preferenceWelcomeTabAboutVisible)
  const aboutTitle = getIn(experienceConfig, experienceUpsertFormKeys.preferenceWelcomeTabAboutTitle)
  const aboutDescription = getIn(experienceConfig, experienceUpsertFormKeys.preferenceWelcomeTabAboutDescription)

  const hasHeroBannerArea = heroBannerVisible && (isTheme || heroBannerUrl)

  return (
    <WelcomeTabBox>
      {/* Hero banner */}
      <SelectableSection
        section={isTheme ? PreferenceThemeBuilderSection.WelcomeTabHeroBanner : undefined}
        overlayProps={{ marginBottom: '4px' }}
      >
        {heroBannerVisible ? (
          <ImageContainer variant={BuilderImageVariant.HeroBanner} imageUrl={heroBannerUrl} />
        ) : isTheme ? (
          <SectionEmptyState section="Hero Banner" />
        ) : null}
      </SelectableSection>

      <WelcomeTextContentBox>
        {/* Welcome Text */}
        <SelectableSection
          overlayProps={{
            marginTop: hasHeroBannerArea ? (welcomeTextVisible ? '24px' : '20px') : welcomeTextVisible ? '8px' : '4px',
            marginBottom: '4px',
          }}
          section={
            isTheme
              ? PreferenceThemeBuilderSection.WelcomeTabWelcomeMessage
              : PreferenceExperienceBuilderSection.WelcomeTabWelcomeMessage
          }
        >
          <WelcomeTextBox mt={hasHeroBannerArea ? (welcomeTextVisible ? '12px' : '16px') : '0px'}>
            {welcomeTextVisible ? (
              <>
                <Typography
                  fontSize="28px"
                  fontWeight={600}
                  color={welcomeTextTitleColor}
                  maxWidth={'592px'}
                  sx={{ wordWrap: 'break-word' }}
                >
                  {welcomeTextTitle}
                </Typography>
                <RichTextRenderer
                  text={welcomeTextSubTitle}
                  textColor={welcomeTextSubtitleColor}
                  fontSize={20}
                  lineHeight="30px !important"
                  linkColor={welcomeTextLinkColor}
                  underlineLinks={welcomeTextLinkUnderline}
                />
              </>
            ) : (
              <SectionEmptyState section="Welcome Text" />
            )}
          </WelcomeTextBox>
        </SelectableSection>

        {/* Quick Links */}
        <SelectableSection
          section={
            isTheme
              ? PreferenceThemeBuilderSection.WelcomeTabQuickLinks
              : PreferenceExperienceBuilderSection.WelcomeTabQuickLinks
          }
        >
          <QuickLinksBox>
            {quickLinksVisible ? (
              <>
                {quickLinks.map(text => (
                  <QuickLinkBox>
                    <Typography
                      fontSize="14px"
                      fontWeight={600}
                      color={quickLinksTextColor}
                      lineHeight="20px"
                      maxWidth={'514px'}
                      sx={{ textDecoration: quickLinksUnderline ? 'underline' : 'none', wordWrap: 'break-word' }}
                    >
                      {text}
                    </Typography>
                    {quickLinkShowArrow ? (
                      <Icon name={'OArrowRight'} iconColor={quickLinksTextColor} width={20} height={20} />
                    ) : null}
                  </QuickLinkBox>
                ))}
              </>
            ) : (
              <SectionEmptyState section="Quick Links" />
            )}
          </QuickLinksBox>
        </SelectableSection>

        {/* About */}
        <SelectableSection
          section={
            isTheme
              ? PreferenceThemeBuilderSection.WelcomeTabAbout
              : aboutTitleVisible
              ? PreferenceExperienceBuilderSection.WelcomeTabAbout
              : undefined
          }
        >
          <AboutTextBox>
            {aboutTitleVisible ? (
              <Typography
                fontSize="18px"
                fontWeight={600}
                color={aboutTitleColor}
                maxWidth={'592px'}
                sx={{ wordWrap: 'break-word' }}
              >
                {aboutTitle}
              </Typography>
            ) : (
              <SelectableSection
                overlayProps={{ marginBottom: '4px', marginTop: '4px' }}
                section={isTheme ? undefined : PreferenceExperienceBuilderSection.WelcomeTabAbout}
              >
                <SectionEmptyState section="About Text Title" />
              </SelectableSection>
            )}
            <SelectableSection
              overlayProps={{ marginBottom: '8px', marginTop: '8px' }}
              section={
                !isTheme && !aboutTitleVisible
                  ? PreferenceExperienceBuilderSection.WelcomeTabAboutDescription
                  : undefined
              }
            >
              <RichTextRenderer
                text={aboutDescription}
                textColor={aboutDescriptionColor}
                linkColor={aboutLinkColor}
                underlineLinks={aboutLinkUnderline}
              />
            </SelectableSection>
          </AboutTextBox>
        </SelectableSection>
      </WelcomeTextContentBox>
    </WelcomeTabBox>
  )
}
