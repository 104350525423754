import React from 'react'
import { Box, Chip } from '@mui/material'

import { Text } from 'components/ui-kit/typography/Text'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

type PurposeChipProps = {
  name?: string
}

export const AssetChip: React.FC<PurposeChipProps> = ({ name }) => {
  return (
    <Chip
      clickable={false}
      // TODO:BAC - implement onClick
      onClick={() => {}}
      label={
        <Box display="flex" alignItems="center">
          <Text size={14} color="royalBlue">
            {name}
          </Text>
        </Box>
      }
      sx={{
        textTransform: 'capitalize',
        color: 'royalBlue.main',
        cursor: 'pointer',
        backgroundColor: 'sphereFaded.main',
        '&:hover': {
          backgroundColor: `rgba(87, 116, 255, 0.2);`,
        },
      }}
      /* onDelete is required when there's a deleteIcon provided. 
      The icon is just asthenic so, we provide an empty function here */
      onDelete={() => {}}
      deleteIcon={
        <ChevronRightIcon
          style={{
            fontSize: 20,
          }}
          sx={[
            {
              fill: ({ palette }) => palette.royalBlue.main,
            },
          ]}
        />
      }
    />
  )
}
