import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'

type TooltipProps = { x?: number; y?: number; name: string; active: boolean }

export const ChartTooltip: React.FC<TooltipProps> = ({ name, x, y, active }) => {
  if (!active) return null

  return (
    <Box
      sx={{
        background: ({ palette }) => palette.darkDusk.main,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column',
        py: 1,
        pl: 1.25,
        pr: 3,
      }}
    >
      <Text size={12} weight={700} color="white" mb={0.5}>
        {name}
      </Text>

      <Text size={12} weight={500} color="white" mb={0.5}>
        Access {y}&#x25;
      </Text>

      <Text size={12} weight={500} color="white">
        Usage {x}&#x25;
      </Text>
    </Box>
  )
}
