import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import moment from 'moment'
import { KETCH_DML } from 'pages/developers/hooks/constants'
import { getAmPm } from '../components/EditAutoDiscoveryFormSection/utils'

type Args = { database: MaybeNull<AssetSummaryDTO> }

export const getInitialValues = ({ database }: Args): EditDatabaseFormValues => {
  const hooksPayload = [
    ...(database?.asset?.dmlPreHooks || []),
    {
      assignmentCount: 9999,
      dmlCode: 'dummy',
      id: 'dummy',
      name: KETCH_DML,
      operation: 99999,
      technology: 'dummy',
      type: 99,
    },
    ...(database?.asset?.dmlPostHooks || []),
  ]

  return {
    name: database?.asset?.resource?.name || '',
    description: database?.asset?.description || '',
    dataCategory: database?.asset?.dataCategory?.[0]?.value || '',
    labels: (database?.asset?.assignedLabels || []) as ManageLabelModalLabel[],
    inferredLabels: (database?.asset?.inferredLabels || []) as ManageLabelModalLabel[],
    dataOwner: database?.asset?.owner || '',
    hooks: hooksPayload,
    purposes: database?.asset?.purposes || [],
    schedule: {
      time: database?.asset?.schedule?.time ? moment(new Date(database?.asset?.schedule?.time)).format('h:mm') : '',
      frequency: database?.asset?.schedule?.frequency ? String(database?.asset?.schedule?.frequency) : '0',
      day: database?.asset?.schedule?.day ? String(database?.asset?.schedule?.day) : '',
      isCustomizeStartingTimeChecked: !!database?.asset?.schedule?.time,
      period: database?.asset?.schedule?.time ? getAmPm(new Date(database?.asset?.schedule?.time)) : '1',
    },
  }
}
