import moment from 'moment'

type DateRange = {
  startDate?: string
  endDate?: string
}

export function getFirstDayOfEachMonthBetweenDates({ startDate, endDate }: DateRange): string[] {
  const start = moment(startDate)
  const end = moment(endDate)
  const dates: string[] = []

  while (start.isBefore(end) || start.isSame(end)) {
    dates.push(start.startOf('month').format('YYYY-MM-DD'))
    start.add(1, 'month')
  }

  return dates
}
