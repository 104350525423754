import { useTheme } from '@mui/material'

import { styled } from '@mui/material/styles'

import { SectionTitle } from 'components/ui-kit/typography/compositions/SectionTitle'
import { Table } from 'components/ui-layouts/table/Table'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { ColorRGB } from 'components/ui-kit/colorRgb/ColorRGB'

const PREFIX = 'ThemeDetailsTab'

const classes = {
  root: `${PREFIX}-root`,
  color: `${PREFIX}-color`,
}

const Root = styled('div')({
  [`&.${classes.root}`]: {
    marginBottom: 48,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  [`& .${classes.color}`]: {
    width: 28,
    height: 28,
    borderRadius: '50%',
  },
})

type Props = {
  title: string
  items: {
    name: string | JSX.Element
    color: string
  }[]
}

export const ThemeExperienceDetails = ({ title, items }: Props) => {
  const { palette } = useTheme()

  return (
    <Root className={classes.root}>
      <SectionTitle>{title}</SectionTitle>

      <Table
        variant="page"
        items={items}
        cellSettings={{
          color: {
            width: 70,
            cellRenderer: ({ color }) => (
              <div
                className={classes.color}
                style={{
                  background: color,
                  ...(color?.toLowerCase() === palette.white.main && {
                    border: `1px solid ${palette.iron.main}`,
                  }),
                }}
              />
            ),
          },
          property: {
            label: 'Property',
            cellRenderer: ({ name }) => <TextCellRenderer value={name} />,
          },
          rgb: {
            label: 'RGB',
            width: 200,
            cellRenderer: ({ color }) => <ColorRGB color={color} />,
          },
          hex: {
            label: 'Hex',
            width: 140,
            cellRenderer: ({ color }) => <TextCellRenderer value={color.toUpperCase()} />,
          },
        }}
      />
    </Root>
  )
}
