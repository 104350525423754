import { getSumOfPropertyInAnArrayOfObjects } from 'utils/helpers/array/getSumOfPropertyInAnArrayOfObjects'
import { ReshapedDataForChart } from './getReshapedDataForChart'

// function to separate the data grouped by months
const groupBy = (
  arr: ReshapedDataForChart[],
  property: string,
): {
  [key: string]: ReshapedDataForChart[]
} => {
  return arr.reduce(function (memo: any, x: any) {
    if (!memo[x[property]]) {
      memo[x[property]] = []
    }
    memo[x[property]].push(x)
    return memo
  }, {})
}

export const getMonthlyData = (reshapedData: ReshapedDataForChart[]) => {
  const dataByMonth = groupBy(reshapedData, 'name')
  const payload: ReshapedDataForChart[] = []

  Object.entries(dataByMonth)?.forEach?.(([key, trendDataForMonth]) => {
    const monthSumPayload: any = {}

    trendDataForMonth?.forEach?.(el => {
      const properties = Object.keys(el)
      const targetProperties = ['overallLine', 'noConsent', 'consentLine', 'consent', 'noConsentRequired']
      properties?.forEach?.((property: string) => {
        if (targetProperties.includes(property)) {
          const propertySum = getSumOfPropertyInAnArrayOfObjects(trendDataForMonth, property)
          monthSumPayload[property] = propertySum
        } else {
          monthSumPayload[property] = el[property as keyof ReshapedDataForChart]
        }
      })
    })

    monthSumPayload['name'] = key

    payload.push(monthSumPayload)
  })

  return payload
}
