import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { ApplicationDTO } from 'interfaces/applications/application'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
  includeIssues?: boolean
}>

type Response = APIListResponse<{ applications: ApplicationDTO[]; totalResults?: number }>

export const fetchApplications = ({ organizationCode, includeMetadata, includeIssues, start, limit }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/applications`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
        },
      },
    }),
  )
