import { MaybeNull, ObjectLiteral } from 'interfaces'
import { ExperienceCanonicalRightFormTemplate, PreferenceExperienceDTO } from 'interfaces/experiences/experience'
import { ExperienceSwitchRenderLogic } from 'interfaces/experiences/experienceSwitchRenderLogic'
import { FormMode } from 'interfaces/formModes/formMode'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { PreferenceExperienceFormValues } from '../../../interfaces/PreferenceExperienceFormValues'
import { deserialize } from '../../common'
import { PreferenceExperienceTranslationFields } from '../interfaces'
import { getPreferenceDefaultValues } from './getPreferenceDefaultValues'
import { SubscriptionTopicDTO } from '@ketch-com/figurehead'

export const getPreferenceInitialValues = ({
  experience,
  organizationLanguages,
  formMode,
  organizationName,
  canonicalRights,
  subscriptionTopics,
}: {
  experience: MaybeNull<PreferenceExperienceDTO>
  organizationLanguages: LanguageWithStatusDTO[]
  formMode: FormMode
  organizationName: string
  canonicalRights?: ExperienceCanonicalRightFormTemplate[]
  subscriptionTopics?: SubscriptionTopicDTO[]
}): PreferenceExperienceFormValues => {
  const defaultValues = getPreferenceDefaultValues({ canonicalRights, organizationName, subscriptionTopics })
  if (!experience) {
    return {
      name: '',
      code: '',
      title: defaultValues.title,
      overview: defaultValues.overview,
      rights: defaultValues.rights,
      consents: defaultValues.consents,
      subscriptions: {
        ...defaultValues.subscriptions,
        bodyDescription: deserialize(defaultValues?.subscriptions?.bodyDescription || ''),
      },
      translations: organizationLanguages.reduce<ObjectLiteral<PreferenceExperienceTranslationFields>>(
        (acc, { language }) =>
          ({
            ...acc,
            [language.code]: {
              title: '',
              overview: {
                tabName: '',
                bodyTitle: '',
                bodyDescription: deserialize(''),
              },
              rights: {
                tabName: '',
                bodyTitle: '',
                bodyDescription: deserialize(''),
                buttonText: '',
                requestDetailsTitle: '',
                personalDetailsTitle: '',
              },
              consents: {
                tabName: '',
                bodyTitle: '',
                bodyDescription: deserialize(''),
                buttonText: '',
                consentTitle: '',
                switchOnText: '',
                switchOffText: '',
              },
              subscriptions: {
                tabName: '',
                title: '', // Title of the Subscriptions list
                bodyTitle: '',
                bodyDescription: deserialize(''),
                switchOnText: '',
                switchOffText: '',
                primaryButtonText: '',
                secondaryButtonText: '',
                controlItems: [],
              },
            },
          }) as any,
        {},
      ),
    }
  }

  const duplicate = formMode === FormMode.DUPLICATE
  const overview = experience?.preference?.overview
  const rights = experience?.preference?.rights
  const consents = experience?.preference?.consents
  const subscriptions =
    (experience?.preference?.subscriptions as typeof defaultValues.subscriptions) || defaultValues.subscriptions
  const translations = experience?.preference?.translations

  subscriptions.topicItems = subscriptions?.topicItems?.map(expTopic => {
    const currentTopic = subscriptionTopics?.filter(topic => topic?.code === expTopic?.code)?.[0]

    return { ...expTopic, name: currentTopic?.name || '' }
  })

  return {
    name: duplicate ? '' : experience.name,
    code: duplicate ? '' : experience.code,
    title: experience?.preference?.title || '',
    overview: {
      bodyDescription: deserialize(overview?.bodyDescription || ''),
      bodyTitle: overview?.bodyTitle || '',
      extensions: {},
      tabName: overview?.tabName || '',
    },
    rights: {
      bodyDescription: deserialize(rights?.bodyDescription || ''),
      bodyTitle: rights?.bodyTitle || '',
      buttonText: rights?.buttonText || '',
      extensions: {},
      tabName: rights?.tabName || '',
      personalDetailsTitle: rights?.personalDetailsTitle || '',
      personalDetailsTitleType: !!rights?.personalDetailsTitle ? 'custom' : 'default',
      showPersonalDetailsTitle: !!rights?.showPersonalDetailsTitle,
      requestDetailsRequired: !!rights?.requestDetailsRequired,
      requestTitleType: !!rights?.requestDetailsTitle ? 'custom' : 'default',
      requestDetailsTitle: rights?.requestDetailsTitle || '',
      showRequestDetailsTitle: !!rights?.showRequestDetailsTitle,
      showRequestDetailsField: !!rights?.showRequestDetailsField,
      canonicalRightFormTemplates: rights?.canonicalRightFormTemplates || [],
      customRightFormTemplates: rights?.customRightFormTemplates || [],
      hasSingleFormTemplate: rights?.hasSingleFormTemplate || false,
      singleFormTemplateID: rights?.singleFormTemplateID || '',
      canonicalRightsOrder: rights?.canonicalRightsOrder || [],
      recaptchaEnabled: rights?.recaptchaEnabled || false,
    },
    consents: {
      bodyDescription: deserialize(consents?.bodyDescription || ''),
      bodyTitle: consents?.bodyTitle || '',
      buttonText: consents?.buttonText || '',
      consentTitle: consents?.consentTitle || '',
      extensions: {},
      hideConsentTitle: consents?.hideConsentTitle || false,
      hideLegalBases: consents?.hideLegalBases || false,
      isCustomSwitchTextSwitchOn: !!consents?.switchOnText || !!consents?.switchOffText,
      isCustomTextSwitchOn: !!consents?.consentTitle,
      switchOffText: consents?.switchOffText || '',
      switchOnText: consents?.switchOnText || '',
      switchTextRenderLogic: consents?.switchTextRenderLogic || ExperienceSwitchRenderLogic.RENDER_LEGAL_BASIS,
      tabName: consents?.tabName || '',
    },
    subscriptions: {
      tabName: subscriptions?.tabName || '',
      title: subscriptions?.title || '', // Title of the Subscriptions list
      bodyTitle: subscriptions?.bodyTitle || '',
      bodyDescription: deserialize(subscriptions?.bodyDescription || ''),
      topicItems: subscriptions?.topicItems || [],
      controlItems: subscriptions?.controlItems || [],
      switchOnText: subscriptions?.switchOnText || '',
      switchOffText: subscriptions?.switchOffText || '',
      switchTextRenderLogic: subscriptions?.switchTextRenderLogic || ExperienceSwitchRenderLogic.RENDER_NEVER,
      primaryButtonText: subscriptions?.primaryButtonText || '',
      secondaryButtonText: subscriptions?.secondaryButtonText || '',
      hideSubscriptionDetailTitle: subscriptions?.hideSubscriptionDetailTitle || false,
    },
    translations: organizationLanguages.reduce<ObjectLiteral<PreferenceExperienceTranslationFields>>(
      (acc, { language }) => {
        const translation = translations?.[language.code]

        const overviewTranslation = translation?.overview
        const rightsTranslation = translation?.rights
        const consentsTranslation = translation?.consents
        const subscriptionsTranslation = translation?.subscriptions

        return {
          ...acc,
          [language.code]: {
            title: translation?.title || '',
            overview: {
              bodyDescription: deserialize(overviewTranslation?.bodyDescription || ''),
              bodyTitle: overviewTranslation?.bodyTitle || '',
              tabName: overviewTranslation?.tabName || '',
            },
            rights: {
              bodyDescription: deserialize(rightsTranslation?.bodyDescription || ''),
              bodyTitle: rightsTranslation?.bodyTitle || '',
              buttonText: rightsTranslation?.buttonText || '',
              tabName: rightsTranslation?.tabName || '',
              requestDetailsTitle: rightsTranslation?.requestDetailsTitle || '',
              personalDetailsTitle: rightsTranslation?.personalDetailsTitle || '',
            },
            consents: {
              bodyDescription: deserialize(consentsTranslation?.bodyDescription || ''),
              bodyTitle: consentsTranslation?.bodyTitle || '',
              buttonText: consentsTranslation?.buttonText || '',
              consentTitle: consentsTranslation?.consentTitle || '',
              switchOffText: consentsTranslation?.switchOffText || '',
              switchOnText: consentsTranslation?.switchOnText || '',
              tabName: consentsTranslation?.tabName || '',
            },
            subscriptions: {
              bodyDescription: deserialize(subscriptionsTranslation?.bodyDescription || ''),
              bodyTitle: subscriptionsTranslation?.bodyTitle || '',
              primaryButtonText: subscriptionsTranslation?.primaryButtonText || '',
              secondaryButtonText: subscriptionsTranslation?.secondaryButtonText || '',
              title: subscriptionsTranslation?.title || '',
              switchOffText: subscriptionsTranslation?.switchOffText || '',
              switchOnText: subscriptionsTranslation?.switchOnText || '',
              tabName: subscriptionsTranslation?.tabName || '',
              controlItems: subscriptionsTranslation?.controlItems || [],
            },
          },
        } as any
      },
      {},
    ),
  }
}
