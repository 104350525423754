import { JurisdictionCanonicalRightDTO } from '@ketch-com/figurehead'
import { ExperienceSwitchRenderLogic } from 'interfaces/experiences/experienceSwitchRenderLogic'
import { deserialize } from '../../common'
import { ensureArray } from 'utils/helpers/array'
import { transformJurisdictionCanonicalRightsToExperienceCanonicalRightFormTemplates } from '.'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces'
import { SubscriptionTopicDTO } from '@ketch-com/figurehead'
import {
  CONTROL_DEFAULTS,
  GlobalControl,
  SWITCH_DEFAULT_TEXT,
} from '../../../components/UpsertPreference/components/Sidebar/components/SubscriptionFields/constants'

export const getPreferenceDefaultValues = ({
  canonicalRights,
  organizationName,
  subscriptionTopics,
}: {
  canonicalRights?: JurisdictionCanonicalRightDTO[]
  organizationName: string
  subscriptionTopics?: SubscriptionTopicDTO[]
}) => {
  return {
    title: 'Your Privacy',
    overview: {
      tabName: 'Overview',
      bodyTitle: '',
      bodyDescription: deserialize(
        `Welcome to ${organizationName}! We're glad you're here and want you to know that we respect your privacy and your right to control how we collect, use, and store your personal data. To learn more about our privacy practices, please read our [Privacy Policy](privacyPolicy).`,
      ),
      extensions: {},
    },
    rights: {
      tabName: 'Your Rights',
      bodyTitle: 'Exercise your rights',
      bodyDescription: deserialize(
        `Applicable privacy laws give you certain rights with respect to our collection, use, and storage of your personal data, and we welcome your exercise of those rights. Please complete the form below so that we can validate and fulfill your request. For more detail, please read our [Privacy Policy](privacyPolicy).`,
      ),
      buttonText: 'Submit',
      extensions: {},

      personalDetailsTitle: '',
      personalDetailsTitleType: 'default',
      showPersonalDetailsTitle: true,

      requestDetailsTitle: '',
      requestTitleType: 'default',

      showRequestDetailsField: true,
      showRequestDetailsTitle: true,
      requestDetailsRequired: false,

      canonicalRightFormTemplates: transformJurisdictionCanonicalRightsToExperienceCanonicalRightFormTemplates(
        ensureArray(canonicalRights),
      ),
      customRightFormTemplates: [],
      hasSingleFormTemplate: true,
      singleFormTemplateID: '',
      canonicalRightsOrder: ['get', 'delete', 'restrict', 'update'],
      recaptchaEnabled: true,
    },
    consents: {
      tabName: 'Consent',
      bodyTitle: 'Choose how we use your data',
      bodyDescription: deserialize(
        `We collect and use data--including, where applicable, your personal data--for the purposes listed below. Please indicate whether or not that's ok with you by toggling the switches below. For more information on our privacy practices, please read our [Privacy Policy](privacyPolicy).`,
      ),
      buttonText: 'Submit',
      consentTitle: '',
      hideConsentTitle: false,
      hideLegalBases: false,
      switchOnText: '',
      switchOffText: '',
      switchTextRenderLogic: ExperienceSwitchRenderLogic.RENDER_ALL,
      extensions: {},
      isCustomSwitchTextSwitchOn: false,
      isCustomTextSwitchOn: false,
    },
    subscriptions: {
      tabName: 'Subscriptions',
      title: '', // Title of the Subscriptions list
      bodyTitle: 'Marketing Communication Preferences',
      bodyDescription: 'Let us know how you would like us to reach out to you about various topics.',
      switchOnText: SWITCH_DEFAULT_TEXT.OPT_IN,
      switchOffText: SWITCH_DEFAULT_TEXT.OPT_OUT,
      switchTextRenderLogic: ExperienceSwitchRenderLogic.RENDER_ALL,
      primaryButtonText: 'Save',
      secondaryButtonText: 'Cancel',
      hideSubscriptionDetailTitle: false,
      topicItems: subscriptionTopics?.map(topic => ({
        type: 1,
        code: topic?.code,
        name: topic?.name || '',
      })) as PreferenceExperienceFormValues['subscriptions']['topicItems'],
      controlItems: [
        {
          type: 2,
          code: GlobalControl.Code,
          itemDetail: {
            title: CONTROL_DEFAULTS.TITLE,
            description: '',
            switchOffText: SWITCH_DEFAULT_TEXT.OPT_OUT,
            switchOnText: SWITCH_DEFAULT_TEXT.OPT_IN,
            switchTextRenderLogic: ExperienceSwitchRenderLogic.RENDER_ALL,
            positionType: 2,
            impactType: 1,
          },
        },
      ] as PreferenceExperienceFormValues['subscriptions']['controlItems'],
    },
  }
}
