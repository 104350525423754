import { FormTemplateDTO } from '@ketch-com/figurehead'
import { LanguageDTO } from 'interfaces/languages/language'

type OutputObject = {
  [code: string]: {
    title: string
  }
}

type Args = {
  formTemplate?: FormTemplateDTO
  languages: LanguageDTO[]
}

export function getFormTitleTranslations({ languages, formTemplate }: Args): OutputObject {
  const output: OutputObject = {}

  languages.forEach(language => {
    output[language.code] = {
      title: Object.entries(formTemplate?.translations || {}).find(([code]) => code === language.code)?.[1].title || '',
    }
  })

  return output
}
