import React from 'react'
import Box from '@mui/material/Box'
import { Text } from 'components/ui-kit/typography/Text'

import { Button } from '@ketch-com/deck'
import { useAppSelector } from 'store/hooks'
import { getPolicyStatements } from 'store/dsrTransponderFormSlice/selectors'

type Props = {
  code: string
  setConfigureDatasetModalCode: (code: string) => void
  activeDatabase: string
}

export const DatasetTableActionCellRenderer: React.FC<Props> = ({
  code,
  setConfigureDatasetModalCode,
  activeDatabase,
}) => {
  const policyStatements = useAppSelector(getPolicyStatements)

  const isRowChecked = !!policyStatements?.[activeDatabase]?.statements?.[code]
  const addedActionsCount =
    Object.values(policyStatements?.[activeDatabase]?.statements || {}).filter(value => value.parentAssetCode === code)
      ?.length || 0

  if (!isRowChecked) return null

  return (
    <>
      {addedActionsCount === 0 && (
        <Button
          onClick={() => {
            setConfigureDatasetModalCode(code)
          }}
          color="tertiary"
        >
          Add
        </Button>
      )}

      {addedActionsCount > 0 && (
        <Box display="flex" alignItems="center">
          <Box mr={4}>
            <Text size={14} weight={600}>
              {addedActionsCount} actions added
            </Text>
          </Box>
          <Button
            onClick={() => {
              setConfigureDatasetModalCode(code)
            }}
            color="secondary"
          >
            Edit
          </Button>
        </Box>
      )}
    </>
  )
}
