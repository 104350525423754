import { LabelDefinitionDTO as LabelDTO } from '@ketch-com/figurehead'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'

type Args = { labelOptions: LabelDTO[] }

export const getReducedLabelsOptions = ({ labelOptions }: Args): ManageLabelModalLabel[] =>
  labelOptions
    .filter(label => !label?.isSystemLabel)
    .reduce((accumulator: ManageLabelModalLabel[], label: LabelDTO) => {
      if ((label?.allowedValues?.length || 0) > 0) {
        return [
          ...accumulator,
          ...(label?.allowedValues || []).map((r: string) => ({
            code: label?.code,
            value: r || '',
            name: label?.name,
            usageLimit: label?.usageLimit || 0,
            isSystemLabel: label?.isSystemLabel || false,
          })),
        ] as ManageLabelModalLabel[]
      } else {
        return [
          ...accumulator,
          {
            code: label?.code,
            value: label?.allowedValues?.[0] || '',
            name: label?.name,
            usageLimit: label?.usageLimit || 0,
            isSystemLabel: label?.isSystemLabel || false,
          },
        ] as ManageLabelModalLabel[]
      }
    }, [])
