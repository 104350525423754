import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { Box, Grid, Paper } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import clsx from 'clsx'

import { ApiQueryKeys } from 'api/common/queryKeys'

import { Text } from 'components/ui-kit/typography/Text'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { ReactComponent as RightArrowIcon } from 'assets/icons/arrow_right_relationships.svg'
import { MappingDTO } from '@ketch-com/figurehead'
import { useDeleteRelationshipV2 } from 'api/assets/mutations/useDeleteRelationshipV2'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      paper: {
        position: 'relative',
        padding: spacing(2, 0),
        borderBottom: `1px solid ${palette.iron.main}`,
      },
      paperLeftColumn: {
        position: 'relative',
        borderRight: `1px solid ${palette.iron.main}`,
      },
      paperRightColumn: {
        paddingLeft: spacing(4.75),
      },
      primaryKeyPurple: {
        color: palette.heliotrope.main,
      },
      gridRoot: {
        '& .MuiGrid-item': {
          padding: spacing(0, 2),
        },
      },
      foreignKeyBadge: {
        backgroundColor: palette.fadedGrey.main,
        borderRadius: spacing(4),
        padding: spacing(0.75, 1.75),
      },
      primaryKeyBadge: {
        backgroundColor: 'rgba(156,100,255,0.25)',
        borderRadius: spacing(4),
        padding: spacing(0.75, 1.75),
        display: 'inline-block',
      },
      withMarginBottom: {
        marginBottom: spacing(1),
      },
      rightArrowIcon: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        right: -15,
        top: '45%',
        padding: spacing(0.25),
        borderRadius: '50%',
      },
      overlayDiv: {
        zIndex: 10,
        backgroundColor: palette.fadedGrey.main,
        position: 'absolute',
        border: '1px solid rgba(7, 26, 36, 0.5)',
        borderRadius: '100px',
      },
    }),
  { name: 'DeleteRelationshipModal' },
)

type Props = {
  relationship: MappingDTO
  onSubmit?: () => void
  onCancel: () => void
}

export const DeleteRelationshipModal: React.FC<Props> = ({ relationship, onSubmit, onCancel }) => {
  const { id, foreignKey, primaryKey, foreignKeyParent, primaryKeyParent } = relationship
  const classes = useStyles()
  const { id: relationshipId } = relationship
  const queryClient = useQueryClient()

  const [isBusy, setIsBusy] = useState(false)

  const { mutateAsync: handleDeleteRelationship } = useDeleteRelationshipV2({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      onSubmit?.()
      showToast({ content: 'Relationship deleted', type: 'success' })
      await queryClient.refetchQueries([ApiQueryKeys.relationshipsInfinite, { type: relationship.type }])
    },
    onError: () => {
      showToast({ content: 'Failed to delete Relationship', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <Dialog
      isLoading={false}
      isSaving={isBusy}
      title="Delete Relationship"
      contentWidth={650}
      submitBtnLabel="Delete"
      cancelBtnLabel="Close"
      withTitleBorderBottom={true}
      onSubmitBtnClick={() => {
        handleDeleteRelationship({
          params: {
            relationshipId: relationshipId!,
          },
        })
      }}
      onCancelBtnClick={onCancel}
    >
      <Box>
        <Text size={16}>Are you sure you want to delete the following relationship</Text>
      </Box>
      <br />

      <Box mt={2}>
        <Grid id="myGrid" container spacing={2} className={classes.gridRoot}>
          {/* Left Column */}

          <Grid item xs={6} className={classes.paperLeftColumn}>
            <Box className={classes.rightArrowIcon}>
              <RightArrowIcon />
            </Box>
            <Paper elevation={0} className={classes.paper}>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Box mb={1}>
                  <Text size={12} weight={500}>
                    {foreignKeyParent?.resource?.name}
                  </Text>
                </Box>
                {foreignKey!.map((key, index) => (
                  <Box
                    key={key?.resource?.id}
                    zIndex={5}
                    id={`${id}-foreign-key-${index}`}
                    className={clsx(classes.foreignKeyBadge, {
                      [classes.withMarginBottom]: index !== foreignKey!.length - 1,
                    })}
                  >
                    {key?.resource?.name}
                  </Box>
                ))}
              </Box>
            </Paper>
          </Grid>

          {/* Right Column */}

          <Grid item xs={6}>
            <Paper elevation={0} className={clsx(classes.paper, classes.paperRightColumn)}>
              <Box display="flex" justifyContent="space-between" flex={1}>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Box mb={1}>
                    <Text size={12} weight={500}>
                      {primaryKeyParent?.resource?.name}
                    </Text>
                  </Box>
                  {primaryKey!.map((key, index) => (
                    <Box
                      id={`${id}-primary-key-${index}`}
                      key={index}
                      className={clsx(classes.primaryKeyBadge, {
                        [classes.withMarginBottom]: index !== foreignKey!.length - 1,
                      })}
                    >
                      {key?.resource?.name}
                    </Box>
                  ))}
                </Box>

                <Box display="flex" flexDirection="column" justifyContent="flex-end" />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}
