import {
  Button,
  CustomButtonProps,
  Icon,
  IconMap,
  ListItemText,
  ActionSheetWrapper,
  ActionSheetItem,
} from '@ketch-com/deck'
import { ReactNode, useState } from 'react'
import { FieldValidator, useField } from 'formik'
import { Box, InputLabel, Typography } from '@mui/material'
import { FormError } from './FormError'

interface FormOptionsButtonProps extends CustomButtonProps {
  buttonOpenIcon?: keyof typeof IconMap
  buttonClosedIcon?: keyof typeof IconMap
}
interface FormOptionActionSheetProps<T> {
  buttonProps?: FormOptionsButtonProps
  formPropertyName: string
  items: T[]
  renderItem: (color: T) => ReactNode
  validate?: FieldValidator
  valueKey: keyof T
  required?: boolean
  label?: ReactNode | string
  showOptionalLabelText?: boolean
  placeholder?: string
  onItemClick?: (v: T) => void
}

export const FormOptionActionSheet = <T,>({
  renderItem,
  items,
  validate,
  formPropertyName,
  valueKey,
  buttonProps,
  required,
  label,
  showOptionalLabelText,
  placeholder,
  onItemClick,
}: FormOptionActionSheetProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const [field, meta, { setTouched, setValue }] = useField({ name: formPropertyName, validate })
  const { value } = field
  const { touched, error } = meta

  const showError = error && touched

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (_: React.MouseEvent<HTMLElement>, index: number) => {
    setValue(items[index][valueKey])
    onItemClick && onItemClick(items[index])
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setTouched(true)
  }

  const renderedItems = () => {
    return items.map((item, index) => (
      <ActionSheetItem
        key={index}
        selected={item[valueKey] === value}
        onClick={event => handleMenuItemClick(event, index)}
      >
        <ListItemText selected={item[valueKey] === value}>{renderItem(item)}</ListItemText>
      </ActionSheetItem>
    ))
  }

  const currentItem = items.find(item => item[valueKey] === value)

  return (
    <>
      <InputLabel>
        {required ? (
          typeof label !== 'string' ? (
            label
          ) : (
            <Typography variant="label" color="darkDusk.main">
              {label}
            </Typography>
          )
        ) : (
          <>
            <Typography variant="label" color="darkDusk.main">
              {label}
            </Typography>{' '}
            {showOptionalLabelText && (
              <Typography variant="label" color="darkGrey.main">
                {'(Optional)'}
              </Typography>
            )}
          </>
        )}
      </InputLabel>
      <Button
        {...buttonProps}
        endIcon={
          <Icon
            name={open ? buttonProps?.buttonOpenIcon || 'OArrowCUp' : buttonProps?.buttonClosedIcon || 'OArrowCDown'}
          />
        }
        onClick={handleButtonClick}
      >
        {currentItem ? renderItem(currentItem) : placeholder ? placeholder : ''}
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} onBlur={field.onBlur}>
        {renderedItems()}
      </ActionSheetWrapper>
      {showError && (
        <Box>
          <FormError msg={error} />
        </Box>
      )}
    </>
  )
}
