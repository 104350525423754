import React, { createContext } from 'react'
import { useWorkflowCanvasUtils } from '../utils'
import { WorkflowCanvasUtils } from '../utils/useWorkflowCanvasUtils'

export const WorkflowCanvasContext = createContext({} as WorkflowCanvasUtils)

type Props = {
  children?: React.ReactNode | undefined
}

export const WorkflowCanvasContextProvider: React.FC<Props> = ({ children }) => {
  // Use utils hook
  const hookValues = useWorkflowCanvasUtils()

  return <WorkflowCanvasContext.Provider value={{ ...hookValues }}>{children}</WorkflowCanvasContext.Provider>
}

export const withWorkflowCanvasContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <WorkflowCanvasContextProvider>
        <Component {...props} />
      </WorkflowCanvasContextProvider>
    )
  }
}
