import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  id?: string
}>

type Response = {}

export const deleteTeam = ({ id }: Params) =>
  API.delete<Response>(
    formatRequestString({
      entityUrl: `/api/teams/${id || -1}`,
    }),
  )
