import React, { useState, useEffect } from 'react'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { Box, InputLabel, Typography } from '@mui/material'
import { FieldValidator, useField } from 'formik'
import { FormDropZoneWithUpload } from 'components/form/FormDropZone/FormDropZoneWithUpload'
import { FormDropZoneProps } from 'components/form/FormDropZone/FormDropZoneWithUpload.types'
import { Button, theme } from '@ketch-com/deck'

type Props = Omit<FormDropZoneProps, 'onUpload'> & {
  required?: boolean
  label?: string
  name: string
  validate?: FieldValidator
  previewModeOn?: boolean
  isShowImagePreview?: boolean
}

export const FormImageUpload = ({
  accept = {
    'image/*': ['.png'],
  },
  name,
  validate,
  label,
  required = true,
  disabled,
  onUploadingStateChange,
  onFileDialogCancel,
  uploadContext,
  isShowImagePreview = true,
  onUploadComplete,
  ...rest
}: Props) => {
  const [{ value }, , { setValue }] = useField<FileInfoDTO[]>({ name, validate })
  const [previewFile, setPreviewFile] = useState<FileInfoDTO[]>([])

  const onSetValue = (nextValue: FileInfoDTO[]) => {
    setPreviewFile(nextValue)
  }

  useEffect(() => {
    if (typeof value === 'string') {
      setPreviewFile([
        {
          public_url: value,
          name: '',
          ID: '',
          content_type: '',
          download_url: '',
          extension: '',
          info_url: '',
          size: '',
        },
      ])
    }
  }, [value])

  return (
    <div>
      {!!label && (
        <InputLabel required={required}>
          <Typography variant="label" color="darkDusk.main">
            {label}
          </Typography>
        </InputLabel>
      )}

      {previewFile && !!previewFile?.length ? (
        <Box>
          {/* Uploaded image display */}
          <Box maxWidth={205}>
            <img
              src={previewFile?.[0].public_url}
              alt={`preview of ${previewFile?.[0].name}`}
              style={{
                maxWidth: '205px',
                borderRadius: 13,
                border: `1px solid ${theme.palette.iron.main}`,
              }}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Box display="flex" gap={1}>
              <Button
                size="medium"
                color="secondary"
                onClick={async () => {
                  onSetValue([])
                  await setValue([])
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <FormDropZoneWithUpload
          {...rest}
          name={name}
          title="Upload Logo"
          subtitle="Drop file here or use the button below to locate your file."
          allowDuplicates
          accept={accept}
          showOnlyButton={true}
          uploadContext={uploadContext}
          disabled={disabled}
          onUploadComplete={files => {
            onSetValue(files)
            onUploadComplete?.(files)
          }}
          required={required}
          disableDownload
          showUploadSizes
          maxFiles={2}
          isUploadOneFile
          hideUploadedFilesView
        />
      )}
    </div>
  )
}
