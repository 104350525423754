import { Text } from 'components/ui-kit/typography/Text'

type Props = {
  x?: number
  y?: number
  payload?: { value?: any }
  customXOffset?: number
  customYOffset?: number
  unitAdornment?: string
  customTickFormatter?: any
  index?: number
}

export const CustomAxisTickMark: React.FC<Props> = ({
  x,
  y,
  payload,
  customXOffset,
  customYOffset,
  unitAdornment,
  customTickFormatter,
  index,
}) => {
  return (
    <g transform={`translate(${(x || 0) - (customXOffset || 0)},${(y || 0) - (customYOffset || 0)})`}>
      <foreignObject width={45} height={25}>
        <Text weight={500} size={12} color="darkDuskFaded">
          {customTickFormatter ? customTickFormatter(parseInt(payload?.value), index) : payload?.value}
          {unitAdornment || null}
        </Text>
      </foreignObject>
    </g>
  )
}
