import React from 'react'
import Box from '@mui/material/Box'
import { EditOutlined, DeleteOutline } from '@mui/icons-material'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'

import { Text } from 'components/ui-kit/typography/Text'
import { DataAssetsUsedTable, DataUsageQueriesGraph } from './components'
import { useParams } from 'react-router-dom'
import { useAssetsInfiniteV2 } from 'api/assets/queries/useAssetsInfiniteV2'
import { getAssetTypeHumanReadable } from 'pages/assetManager/utils'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { DetailsClasses, StyledBox } from 'pages/insights/activityAndAccess/components/detailsStyles'

type Props = {}

export const DataUsageDetails: React.FC<Props> = props => {
  const { code: userId } = useParams<{ code?: string }>()
  const {
    data: assets,
    isLoading: isLoadingAssets,
    // isFetchingNextPage,
    // hasNextPage,
    // fetchNextPage,
  } = useAssetsInfiniteV2({
    params: {
      limit: 20,
    },
  })
  const assetSummary = assets.find(ass => ass?.asset?.resource?.id === userId)

  if (isLoadingAssets) {
    return <Spinner />
  }

  return (
    <StyledBox className={DetailsClasses.container}>
      <Text size={12} weight={600} color="darkDuskFaded">
        {getAssetTypeHumanReadable(assetSummary)}
      </Text>

      <Box mb={6} display="flex" alignItems="flex-start" justifyContent="space-between">
        <Text size={28} weight={800}>
          {assetSummary?.asset?.resource?.name}
        </Text>
        <ButtonOptions
          size="big"
          items={[
            {
              content: 'Edit',
              icon: <EditOutlined />,
              onClick: () => {},
            },
            {
              content: 'Delete',
              icon: <DeleteOutline />,
              onClick: () => {},
            },
          ]}
        />
      </Box>

      <DataUsageQueriesGraph />

      <DataAssetsUsedTable />
    </StyledBox>
  )
}
