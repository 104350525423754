import React, { ReactNode } from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { useNavigate, To } from 'react-router-dom'
import Box from '@mui/material/Box'
import qs from 'query-string'
// TODO:BAC - will support sticky header; de-scoped for first iteration
// import { Waypoint } from 'react-waypoint'

import { ButtonBack } from 'components/ui-kit/buttonBack/ButtonBack'
import { Text } from 'components/ui-kit/typography/Text'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { ReactComponent as TransponderIcon } from 'assets/icons/transponder-icon.svg'
import { Chip } from 'components/ui-kit/chip/Chip'
import { Badge } from 'components/ui-kit/badge/Badge'
import { useGetAssetTechnologySpecificHumanReadableName } from 'pages/assetManager/hooks'
import { getLabelDTOUniqueIdentifier } from 'pages/assetManager/assets/view/components/AssetDetailViewRelatedAssets/components/DataCategoriesBulkAssignModal/utils'
import { popEntryFromBackToUrlHistory } from 'store/assetDetailViewSlice'
import { useAppDispatch } from 'store/hooks'
// TODO:BAC - will support sticky header; de-scoped for first iteration
// import { useAppDispatch } from 'store/hooks'
// import { HEADER_DISPLAY_MODE, setDisplayMode } from 'store/assetDetailViewSlice'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      containerMain: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: spacing(5),
        minHeight: 65,
      },
      buttonBack: {
        marginRight: spacing(2),
        height: 65,
        width: 44,
      },
      assetTypeIcon: {
        height: 20,
        width: 20,
        marginRight: spacing(1),
      },
      technologiesList: {
        display: 'flex',
        marginLeft: spacing(0.25),
        '& > div': {
          marginLeft: spacing(-0.375),
        },
      },
      transponderChip: {
        backgroundColor: palette.fadedGrey.main,
        color: palette.darkDuskFaded.main,
        padding: spacing(0.2125, 0.75),
        fontSize: 11,
        fontWeight: 500,
      },
      techImage: {
        height: 22,
        width: 22,
      },
    }),

  { name: 'AssetManagerViewLayoutHeader' },
)

type Props = {
  assetSummary?: AssetSummaryDTO
  backTo?: To
  backToScrollToTarget?: string
  actions?: ReactNode | ReactNode[]
}

export const AssetManagerViewLayoutHeader: React.FC<Props> = ({
  backTo,
  actions,
  backToScrollToTarget,
  assetSummary,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const getAssetTechnologySpecificHumanReadableName = useGetAssetTechnologySpecificHumanReadableName()
  const dispatch = useAppDispatch()

  const handleBackButtonOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (backTo && backToScrollToTarget) {
      navigate({
        pathname: backTo as string,
        search: qs.stringify({ targetId: backToScrollToTarget }),
      })
    } else if (backTo) {
      navigate(backTo)
    } else {
      navigate(-1)
    }
    dispatch(popEntryFromBackToUrlHistory())
  }

  return (
    <Box className={classes.containerMain}>
      {/* Metadata */}

      <Box display="flex" alignItems="center">
        {/* TODO:BAC - will support sticky header; de-scoped for first iteration */}
        {/* <Waypoint
          onLeave={() => {
            setTimeout(() => {
              dispatch(setDisplayMode(HEADER_DISPLAY_MODE.STUCK))
            }, 500)
          }}
          onEnter={() => {
            setTimeout(() => {
              dispatch(setDisplayMode(HEADER_DISPLAY_MODE.FREE))
            }, 500)
          }}
        /> */}

        <ButtonBack size="big" className={classes.buttonBack} onClick={handleBackButtonOnClick} />

        <Box display="flex" flexDirection="column" paddingRight={2}>
          <Text
            size={28}
            weight={800}
            mb={0.75}
            sx={{
              overflowWrap: 'anywhere',
            }}
          >
            {assetSummary?.asset?.resource?.name || assetSummary?.asset?.resource?.id || ''}
          </Text>
          <Box display="flex" alignItems="center" gap={1}>
            <img
              src={getAssetCloudImgUrl(assetSummary?.asset?.connection?.technology || '')}
              className={classes.techImage}
              alt={assetSummary?.asset?.connection?.technology || 'technology image'}
            />

            <Text size={14} weight={600} lineHeight="unset">
              {getAssetTechnologySpecificHumanReadableName(assetSummary)}
            </Text>

            <Chip className={classes.transponderChip} icon={<TransponderIcon />}>
              {assetSummary?.asset?.connection?.name || ''}
            </Chip>

            {assetSummary?.asset?.dataCategory?.map?.(d => {
              return <Badge key={getLabelDTOUniqueIdentifier(d)}>{d?.value}</Badge>
            })}
          </Box>
        </Box>
      </Box>

      {/* Actions */}

      <Box alignSelf="flex-start">{actions}</Box>
    </Box>
  )
}
