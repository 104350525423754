import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createEvent } from 'api/orchestrationEventsV2/fetchers/createEvent'

const useCustomMutation = createUseMutation({
  mutationFn: createEvent,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateEvent = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
