/**
 * Capitalizes the first letter of the input string.
 *
 * @param {string} str - The string to be capitalized.
 * @returns {string} The input string with the first letter capitalized.
 * If the input string is empty, returns an empty string.
 *
 * @example
 * ```
 * const capitalized = capitalizeFirstLetterOfString("example");
 * console.info(capitalized); // Output: "Example"
 * ```
 */
export const capitalizeFirstLetterOfString = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const camelCaseToTitleWords = (str: string) => {
  const sentence = str.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
  return sentence.slice(0, 1).toUpperCase() + sentence.slice(1)
}
