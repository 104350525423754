import React, { createContext } from 'react'
import { DataMapUtils, useDataMapUtils } from '../utils/useDataMapUtils'
export const DataMapContext = createContext({} as DataMapUtils)

type Props = {
  children?: React.ReactNode | undefined
}

export const DataMapContextProvider: React.FC<Props> = ({ children }) => {
  // Use utils hook
  const hookValues = useDataMapUtils()

  return <DataMapContext.Provider value={{ ...hookValues }}>{children}</DataMapContext.Provider>
}

export const withDataMapContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <DataMapContextProvider>
        <Component {...props} />
      </DataMapContextProvider>
    )
  }
}
