import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import { Typography } from '@mui/material'
import { RoutesManager } from 'utils/routing/routesManager'
import { ApplicationDTO } from 'interfaces/applications/application'
import { APPLICATION_PLATFORM_NAME } from 'interfaces/applications/applicationPlatform'
import { SENSITIVE_APPLICATION_STATUSES } from 'interfaces/applications/applicationStatus'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { CookieViewTabActionCell } from './CookieViewTabActionCell'

export const cookieApplicationsTabGridColumns: GridColDef<ApplicationDTO>[] = [
  {
    align: 'left',
    headerAlign: 'left',
    field: 'application',
    headerName: 'Property',
    sortable: false,
    flex: 1,
    maxWidth: 250,
    renderCell: ({ row }: GridRenderCellParams<ApplicationDTO>) => {
      return <TableCell title={row.name} subTitle={row.code} sx={{ margin: '8px 0' }}></TableCell>
    },
  },
  {
    align: 'left',
    headerAlign: 'left',
    field: 'type',
    headerName: 'Type',
    sortable: false,
    flex: 1,
    maxWidth: 250,
    renderCell: ({ row }: GridRenderCellParams<ApplicationDTO>) => {
      return (
        <TableCell>
          <Chip size="small" label={APPLICATION_PLATFORM_NAME[row.platform]} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    headerAlign: 'left',
    field: 'deploymentStatus',
    headerName: 'Deployment Status',
    sortable: false,
    flex: 1,
    maxWidth: 250,
    renderCell: ({ row }: GridRenderCellParams<ApplicationDTO>) => {
      return (
        <TableCell>
          {SENSITIVE_APPLICATION_STATUSES.includes(row.status) ? (
            <Status label="Deployed" icon="Dot" status={StatusState.success} variant={StatusVariant.ghost} />
          ) : (
            <Status label="Undeployed" icon="Dot" status={StatusState.draft} variant={StatusVariant.ghost} />
          )}
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'lastUpdated',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Last Updated',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ApplicationDTO>) => {
      if (row.metadata?.updatedAt) {
        return <TableCell title={formatDateTimeFromUnix(row.metadata?.updatedAt)}></TableCell>
      }
      return (
        <TableCell>
          <Typography color={'lightGrey.main'} variant="body">
            None
          </Typography>
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'actions',
    headerAlign: 'left',
    headerName: '',
    sortable: false,
    maxWidth: 100,
    renderCell: ({ row }: GridRenderCellParams<ApplicationDTO>) => {
      return <CookieViewTabActionCell row={row} route={RoutesManager.deployment.applications.view.root} />
    },
  },
]
