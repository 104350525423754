export enum ApplicationPlatform {
  WEB = 1,
  MOBILE = 2,
  CTV = 5,
}

export const APPLICATION_PLATFORM_NAME = {
  [ApplicationPlatform.WEB]: 'Web',
  [ApplicationPlatform.MOBILE]: 'Mobile',
  [ApplicationPlatform.CTV]: 'CTV',
}

export const APPLICATION_PLATFORM_OPTIONS = [
  {
    id: ApplicationPlatform.WEB,
    name: APPLICATION_PLATFORM_NAME[ApplicationPlatform.WEB],
  },
  {
    id: ApplicationPlatform.MOBILE,
    name: APPLICATION_PLATFORM_NAME[ApplicationPlatform.MOBILE],
  },
  {
    id: ApplicationPlatform.CTV,
    name: APPLICATION_PLATFORM_NAME[ApplicationPlatform.CTV],
  },
]
