import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  userId: string
}

export const deleteUser = ({ organizationCode, userId }: Params) =>
  API.delete<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/members/${userId}`,
    }),
  )
