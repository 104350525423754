import Box from '@mui/material/Box'
import { FormFieldTypeDTO, RightInvocationFormDataDTO } from '@ketch-com/figurehead'
import Typography from '@mui/material/Typography'
import { ensureArray } from 'utils/helpers/array'
import { Button, DataGrid, PopUp, FormRow } from '@ketch-com/deck'
import { viewContextVariablesModalColumns } from 'pages/settings/contextVariables/components/viewContextVariablesModalUtils'

type Props = {
  formDataItem?: RightInvocationFormDataDTO
  onEdit?: () => void
  onCancel: () => void
}

export const ViewFormDataModal: React.FC<Props> = ({ onCancel, formDataItem = {}, onEdit }) => {
  const { name, code, value, values, options, type } = formDataItem
  const isCheckbox = type === FormFieldTypeDTO.CheckboxFormFieldType

  const items = [
    {
      label: 'Code',
      value: <Typography variant="label">{code}</Typography>,
    },
    {
      label: 'Name',
      value: <Typography variant="label">{name}</Typography>,
    },

    ...(isCheckbox
      ? [
          {
            label: 'Values',
            value: (
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  wordBreak: 'break-all',
                }}
              >
                <Box component="ul" pl={2.5} my={0}>
                  {ensureArray(values)?.map(value => (
                    <Box component="li" key={value} my={1}>
                      <Typography variant="label">
                        {options?.find?.(option => option.value === value)?.label || value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            ),
          },
        ]
      : [
          {
            label: 'Value',
            value: <Typography variant="label">{value}</Typography>,
          },
        ]),
  ]

  return (
    <PopUp
      title={name}
      variant="modal"
      onClose={onCancel}
      popUpWidth="774px"
      popUpActionChildren={
        <>
          {!!onEdit && (
            <Button color="tertiary" size="large" onClick={onEdit}>
              Edit
            </Button>
          )}
          <Button color="secondary" size="large" onClick={onCancel}>
            Close
          </Button>
        </>
      }
    >
      <FormRow title="Overview">
        {items.length && (
          <DataGrid
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              includeOutliers: false,
              columns: viewContextVariablesModalColumns.map(column => column.field),
              expand: true,
            }}
            getRowHeight={() => 'auto'}
            columns={viewContextVariablesModalColumns}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowHoverStates
            disableRowSelectionOnClick
            getRowId={row => row.label}
            rows={items}
            hideFooter
          />
        )}
      </FormRow>
    </PopUp>
  )
}
