import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchSystems } from '../fetchers/fetchSystems'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.assessment,
  queryFn: fetchSystems,
  select: res => res?.data || null,
})

type Config = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useSystems = (config: Config) => {
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
