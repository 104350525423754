export enum ApplianceApprovalStatusTypes {
  UNAUTHORIZED = 0, // note this default value and will not show up in response
  AUTHORIZED = 1,
  BANNED = 2,
}

export const ApplianceApprovalStatusNames = {
  [ApplianceApprovalStatusTypes.UNAUTHORIZED]: 'Authorized',
  [ApplianceApprovalStatusTypes.AUTHORIZED]: 'Unauthorized',
  [ApplianceApprovalStatusTypes.BANNED]: 'Banned',
}

export const ApplianceApprovalStatusOptions = [
  {
    id: ApplianceApprovalStatusTypes.UNAUTHORIZED,
    name: ApplianceApprovalStatusNames[ApplianceApprovalStatusTypes.UNAUTHORIZED],
  },
  {
    id: ApplianceApprovalStatusTypes.AUTHORIZED,
    name: ApplianceApprovalStatusNames[ApplianceApprovalStatusTypes.AUTHORIZED],
  },
  { id: ApplianceApprovalStatusTypes.BANNED, name: ApplianceApprovalStatusNames[ApplianceApprovalStatusTypes.BANNED] },
]
