import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button, EmptyState, PopUp, theme } from '@ketch-com/deck'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { List, ListItem } from '@mui/material'

const filterList = (items: SearchListItem[], search: string) => {
  const searchStr = search.toLowerCase()
  return items.filter(
    item => item.label?.toLowerCase().includes(searchStr) || item.id?.toLowerCase().includes(searchStr),
  )
}

type SearchListItem = {
  id: string
  label: string
}

type Props = {
  /** Modal title */
  title?: string
  /** Modal subtitle */
  subtitle?: string
  /** Cancel button text */
  cancelButtonLabel?: string
  /** Items to display in the modal */
  items: SearchListItem[]
  /** Function to call when the cancel button is clicked */
  onCancel: () => void
}

/**
 * A modal with a searchable list of items
 */
export const SearchListModal: React.FC<Props> = ({ title, subtitle, cancelButtonLabel, items, onCancel }) => {
  const [search, setSearch] = useState('')
  const [listedItems, setListedItems] = useState(items)

  // Update input value and filter list elements when something new is typed
  const handleSearchChange = (newValue: string) => {
    setSearch(newValue)
    setListedItems(filterList(items, newValue))
  }

  return (
    <PopUp
      variant="modal"
      title={title}
      subTitle={subtitle}
      popUpWidth="825px"
      onClose={onCancel}
      popUpActionChildren={
        <Button color="secondary" size="large" onClick={onCancel}>
          {cancelButtonLabel}
        </Button>
      }
    >
      {/* Search */}

      <SearchTextInput
        size="medium"
        value={search}
        onChange={handleSearchChange}
        showClearButton
        onClear={() => setSearch('')}
        fullWidth={true}
      />

      {/* Summary */}
      <Box display="flex" alignItems="center">
        <Typography variant="label" mr={0.75}>
          {items.length} Vendors | {listedItems.length} found
        </Typography>
      </Box>

      {/* List */}
      <Box display="flex" alignItems="flex-start" justifyContent="center" height="400px" gap={2}>
        {listedItems?.length > 0 ? (
          <List sx={{ py: 'unset', width: '100%' }}>
            {listedItems.map(item => {
              return (
                <ListItem
                  sx={{
                    p: 2,
                    borderBottom: `1px solid ${theme.palette.fadedDarkGrey.main}`,
                  }}
                >
                  <Typography>{item.label}</Typography>
                </ListItem>
              )
            })}
          </List>
        ) : (
          <EmptyState
            iconName="OFilter"
            title="No results found"
            subTitle="Sorry, we couldn't find any items that match your search criteria."
          />
        )}
      </Box>
    </PopUp>
  )
}
