import moment from 'moment'
import { ChartTrendPeriod, RightStatsPeriodDTO, RightStatsPeriodDataPointDTO } from 'interfaces/rights/rightStats'
import {
  getDatesInRange,
  getFirstDayOfEachMonthBetweenDates,
  getMondaysBetweenDates,
  getStartAndEndDateFromTrendPeriod,
} from '.'

type Args = {
  rightsStatsPeriod?: RightStatsPeriodDTO | null
  trendPeriod?: ChartTrendPeriod
}
/**
 * NOTE: This is required because the stats summary job was broken for a large chunk of time resulting in missing data points
 * in UAT that will not happen in PROD.  In order for QA and presentation to be consistent, we need to interpolate the missing
 * data points in UAT.  This is a temporary solution until the missing data points are backfilled in UAT.
 *
 * Generates and merges interpolated rights period data points based on trend periods with the existing rights stats period data points.
 * The merged and sorted list of data points is then returned as part of a new rightsStatsPeriod object.
 *
 * @param {Object} args - The argument object.
 * @param {RightStatsPeriodDTO | null} [args.rightsStatsPeriod={}] - The rights stats period object. Defaults to an empty object when not provided.
 * @param {ChartTrendPeriod} args.trendPeriod - The trend period for which to generate interpolated data points.
 *
 * @returns {RightStatsPeriodDTO} - The rights stats period object including interpolated data points and existing ones, sorted by date.
 */
export const getInterpolatedRightsPeriod = ({ rightsStatsPeriod = {}, trendPeriod }: Args) => {
  let interpolatedRightsPeriodDataPoints: RightStatsPeriodDataPointDTO[] = []

  const { startDate, endDate } = getStartAndEndDateFromTrendPeriod(trendPeriod!)

  switch (trendPeriod) {
    case ChartTrendPeriod.DAILY:
      const dailyDateArray: string[] = getDatesInRange({ startDate, endDate })
      dailyDateArray.forEach(date => {
        if (!rightsStatsPeriod?.dataPoints?.find(dataPoint => dataPoint.date === date)) {
          interpolatedRightsPeriodDataPoints.push({
            date,
          })
        }
      })

      break
    case ChartTrendPeriod.WEEKLY:
      const weeklyDateArray: string[] = getMondaysBetweenDates({ startDate, endDate })
      weeklyDateArray.forEach(date => {
        if (!rightsStatsPeriod?.dataPoints?.find(dataPoint => dataPoint.date === date)) {
          interpolatedRightsPeriodDataPoints.push({
            date,
          })
        }
      })

      break
    case ChartTrendPeriod.MONTHLY_PAST_TWELVE:
      const pastTwelveDateArray: string[] = getFirstDayOfEachMonthBetweenDates({ startDate, endDate })
      pastTwelveDateArray.forEach(date => {
        if (!rightsStatsPeriod?.dataPoints?.find(dataPoint => dataPoint.date === date)) {
          interpolatedRightsPeriodDataPoints.push({
            date,
          })
        }
      })
      break
    case ChartTrendPeriod.MONTHLY_PAST_CAL_YEAR:
      const pastCalYearDateArray: string[] = getFirstDayOfEachMonthBetweenDates({ startDate, endDate })
      pastCalYearDateArray.forEach(date => {
        if (!rightsStatsPeriod?.dataPoints?.find(dataPoint => dataPoint.date === date)) {
          interpolatedRightsPeriodDataPoints.push({
            date,
          })
        }
      })
      break
    default:
  }

  // We need to merge the existing dataPoints and interpolated ones
  const allDataPoints = [...(rightsStatsPeriod?.dataPoints || []), ...interpolatedRightsPeriodDataPoints]

  const sortedDataPoints = allDataPoints.sort?.((a, b) => moment(a.date).diff(moment(b.date)))

  const copyRightsStatsPeriod = { ...rightsStatsPeriod, dataPoints: sortedDataPoints }

  return copyRightsStatsPeriod
}
