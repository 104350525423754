import { Chip, SxProps, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import clsx from 'clsx'

import { Text } from 'components/ui-kit/typography/Text'

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    chip: {
      textTransform: 'capitalize',
      color: palette.royalBlue.main,
      backgroundColor: palette.sphereFaded.main,
      '&.link-chip-large': {
        padding: spacing(3, 0),
        borderRadius: 100,

        '& img': {
          height: 36,
          width: 36,
        },
      },
      '&.link-chip-grey': {
        background: palette.fadedGrey.main,
        '& span': {
          color: palette.darkDusk.main,
        },
      },
    },
    chevron: {
      fill: palette.royalBlue.main,
      fontSize: 20,
    },
  }),
)

type LinkChipNotClickableProps = {
  text?: string
  className?: string
  onClick?: () => void
  size?: 'medium' | 'large'
  variant?: 'grey' | 'blue'
  sx?: SxProps<Theme>
}

export const DataOwnerChip: React.FC<LinkChipNotClickableProps> = ({
  text = '',
  className,
  onClick = () => {},
  size = 'medium',
  variant = 'blue',
  sx,
}) => {
  const classes = useStyles()

  const sizeClass = `link-chip-${size}`
  const colorClass = `link-chip-${variant}`

  return (
    <Chip
      className={clsx(classes.chip, className, sizeClass, colorClass)}
      clickable={false}
      label={
        <Text size={14} color="royalBlue">
          {text}
        </Text>
      }
      sx={sx}
    />
  )
}
