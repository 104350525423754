import React from 'react'

import { useDataSubjectTypesPaginated } from 'api/dataSubjectTypes/queries/useDataSubjectTypesPaginated'
import { DataSubjectTypesList } from 'pages/settings/dataSubjectTypes/list/DataSubjectTypesList'

export const DataSubjectTypesListContainer = () => {
  const {
    data: dataSubjectTypes,
    isLoading,
    isFetching,
    pagination,
  } = useDataSubjectTypesPaginated({
    params: {
      includeMetadata: true,
    },
  })

  return (
    <DataSubjectTypesList
      isLoading={isLoading}
      isFetching={isFetching}
      dataSubjectTypes={dataSubjectTypes}
      pagination={pagination}
    />
  )
}
