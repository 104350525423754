import { useContext } from 'react'

// components
import Box from '@mui/material/Box'
import { ContentGroup, InfoRow } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { InfoRowAssignUser } from '../../../InfoRowAssignUser'
import { InfoRowInlineUpload } from '../../../InfoRowInlineUpload'
import { RightsQueueFormInfoRowTextField } from '../../../FormInfoRowTextField'

// utils
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

// types
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { StepDetailsDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'

type Props = {
  resolutionAttachments?: FileInfoDTO[] | undefined
  stepDetails: StepDetailsDTO | null
  isAssignedToMe: boolean
  showRequiredFields?: boolean
}

export const AppDetails: React.FC<Props> = ({
  resolutionAttachments,
  stepDetails,
  isAssignedToMe,
  showRequiredFields,
}) => {
  // provider
  const { rightInvocation, stepId, workflowExecutionId, workflowStep, canEdit } = useContext(
    RightInvocationWorkflowStepDetailViewContext,
  )

  // hooks
  const { isPermitted } = useIsPermitted()

  // component utils
  const canReassign = isPermitted(PERMISSIONS.WORKFLOW_TASK_ASSIGN)
  const { assigneeInfo, executionData, assignee } = stepDetails as StepDetailsDTO
  const { hasResolutionNotes, hasResolutionAttachments } = executionData?.manualTask || {}
  const isNotComplete = workflowStep?.status !== WorkflowExecutionStepStatus.SUCCESS
  const supportsAppRetry = !!executionData?.app?.supportsAppRetry

  const isStepErrorStatus =
    workflowStep?.status === WorkflowExecutionStepStatus.FAIL ||
    workflowStep?.status === WorkflowExecutionStepStatus.INVALID_CREDENTIALS

  const isStepSuccessStatus = workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS

  //@ts-expect-error StepDetailsDTO needs to be updated to also have the result attribute
  let stepDetailsResult = (stepDetails?.results?.[stepId!]?.[0] as StepDetailsDTO)?.result

  if (isStepSuccessStatus && !stepDetailsResult) {
    stepDetailsResult = stepDetails?.results?.[stepId!]
  }

  const stepError = supportsAppRetry
    ? executionData?.app?.lastError || ''
    : (stepDetails as unknown as WorkflowExecutionStepDetailsDTO)?.results?.[stepId!]?.error || ''

  const showNotesRequired =
    showRequiredFields && hasResolutionNotes && !stepDetails?.manualInterventionData?.resolutionDetails

  const showAttachmentsRequired = showRequiredFields && hasResolutionAttachments && !resolutionAttachments?.length

  return (
    <Box display="flex" flexDirection="column" gap={6} mb={6}>
      {/* Description */}
      {isStepErrorStatus && (
        <Box display="flex" flexDirection="column" gap={4}>
          <ContentGroup variant="inner-page-header" titleVariant="h3" title="Error" />
          <InfoRow title="Error Details">{stepError ? stepError : <EmptyValueRenderer />}</InfoRow>
        </Box>
      )}
      {isStepSuccessStatus && (
        <Box display="flex" flexDirection="column" gap={4}>
          <ContentGroup variant="inner-page-header" titleVariant="h3" title="Success" />
          {/* maybe we need to change the title to just Details?? */}
          <InfoRow title="Success Details">
            {stepDetailsResult ? (
              stepDetailsResult.msg || stepDetailsResult.message || stepDetailsResult.shortMessage || stepDetailsResult
            ) : (
              <EmptyValueRenderer />
            )}
          </InfoRow>
        </Box>
      )}
      {!isStepErrorStatus && !isStepSuccessStatus && (
        <Box display="flex" flexDirection="column" gap={4}>
          <InfoRow title="Details">
            {stepError ? (
              stepError
            ) : stepDetailsResult ? (
              stepDetailsResult.msg || stepDetailsResult.message || stepDetailsResult.shortMessage
            ) : (
              <EmptyValueRenderer />
            )}
          </InfoRow>
        </Box>
      )}
      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup variant="inner-page-header" titleVariant="h3" title="Details" />
        {/* Assignees */}
        <InfoRowAssignUser
          infoRowTitle="Assignee"
          assignee={assignee}
          assigneeInfo={assigneeInfo || null}
          canEdit={canReassign && isNotComplete}
          isComplete={isNotComplete}
          workflowExecutionId={workflowExecutionId}
          workflowStepId={stepId}
        />
      </Box>

      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup variant="inner-page-header" titleVariant="h3" title="Connection" />
        <InfoRow title="Connection">
          {executionData?.app?.appInstanceName ? executionData?.app?.appInstanceName : <EmptyValueRenderer />}
        </InfoRow>
      </Box>

      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup
          variant="inner-page-header"
          titleVariant="h3"
          title="Manual Intervention"
          subTitle="To resolve this activity through Manual Intervention, please complete the fields below."
        />

        <RightsQueueFormInfoRowTextField
          infoRowTitle="Notes"
          canEdit={canEdit}
          name="resolutionDetails"
          placeholder="Add Notes"
          defaultValue={stepDetails?.manualInterventionData?.resolutionDetails || ''}
          showRequiredLabel={showNotesRequired}
          isRequired={hasResolutionNotes}
          showValidation={showNotesRequired}
        />

        <InfoRowInlineUpload
          attachments={resolutionAttachments}
          canEdit={canEdit}
          disableInEditDownload
          infoRowTitle="Attachments"
          isRequired={hasResolutionAttachments}
          name="resolutionAttachments"
          showFileData={true}
          showRequiredLabel={showAttachmentsRequired}
          showUploadSizes
          uploadContext={{
            version: 'resolution_attachments',
            folder: `${rightInvocation?.id}_${stepId}`,
            bucket: '',
          }}
        />
      </Box>
    </Box>
  )
}
