import { InfoRow, InfoRowProps } from '@ketch-com/deck'
import { useEffect, useState } from 'react'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { DocumentOptionsSearchAutocomplete } from 'pages/dataSystems/DataSystem/components/DocumentOptionsSearchAutocomplete'
import { DocumentOptionType } from 'pages/dataSystems/DataSystem/types'
import DocumentChip from 'components/chip/DocumentChip'
import { Grid } from '@mui/material'

interface ChipContentDocDropdownEditInfoRowProps extends InfoRowProps {
  setValues: (selectedChips?: DocumentOptionType[]) => void
  values: DocumentOptionType[]
  allDocuments: DocumentOptionType[]
}

export default function ChipContentDocDropdownEditInfoRow({
  allDocuments,
  setValues,
  values,
  ...rest
}: ChipContentDocDropdownEditInfoRowProps) {
  const [localValues, setLocalValues] = useState<DocumentOptionType[]>([])
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setLocalValues(values)
  }, [values])

  return (
    <InfoRow
      {...rest}
      isEditable
      isEditing={isEditing}
      onEditChange={() => {
        setIsEditing(true)
      }}
      onAcceptChange={() => {
        setIsEditing(false)
        setValues(localValues)
      }}
      onCancelChange={() => {
        setIsEditing(false)
        setLocalValues(values)
      }}
      isEmpty={localValues.length === 0}
    >
      {isEditing ? (
        <DocumentOptionsSearchAutocomplete
          documents={allDocuments}
          selectedDocuments={localValues}
          updateSelectedList={v => setLocalValues(v)}
        />
      ) : localValues.length > 0 ? (
        <Grid container spacing={2}>
          {localValues.map(v => (
            <Grid item xs={12} md={7} key={v.code}>
              <DocumentChip document={v} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyValueRenderer />
      )}
    </InfoRow>
  )
}
