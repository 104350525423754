import React, { Dispatch, SetStateAction } from 'react'
import { Box, Typography, styled } from '@mui/material'
import { Relationships, GetRelationship } from 'pages/assetManager/interfaces'
import { ReactComponent as RelationshipIcon } from 'assets/icons/pointer_right.svg'
import { theme, Chip, Icon, Button } from '@ketch-com/deck'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { MaybeNull } from 'interfaces'

const PREFIX = 'Relationship'

const classes = {
  fk: `${PREFIX}-fk`,
  pk: `${PREFIX}-pk`,
  relationshipDetailsContainer: `${PREFIX}-relationshipDetailsContainer`,
  textContainer: `${PREFIX}-textContainer`,
}

const StyledBox = styled(Box)(() => ({
  [`& .${classes.fk}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.darkChrome.main,
    border: `1px solid ${theme.palette.iron.main}`,
    borderRadius: 5,
    width: 32,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 'none',
  },

  [`& .${classes.pk}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.darkViolet.main,
    backgroundColor: theme.palette.fadedHeliotrope.main,
    border: `1px solid ${theme.palette.fadedHeliotrope.main}`,
    borderRadius: 5,
    width: 32,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 'none',
  },

  [`& .${classes.relationshipDetailsContainer}`]: {
    flexBasis: 536,
    border: `1px solid ${theme.palette.iron.main}`,
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1),
    margin: theme.spacing(2, 0),
    overflow: 'hidden',
  },

  [`& .${classes.textContainer}`]: {
    maxWidth: 400,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}))

type Props = {
  relationships?: Relationships
  isFetchingNextPage?: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => {}
  shouldDelete?: boolean
  setDeleteRelationship?: Dispatch<SetStateAction<MaybeNull<GetRelationship>>>
}

export const Relationship: React.FC<Props> = (props: Props) => {
  let currentDatabaseResourceId = ''
  let currentConnection = ''
  return (
    <StyledBox>
      {props?.relationships?.map((relationship, index) => {
        const shouldDisplayDatabase =
          currentDatabaseResourceId !== relationship?.databaseResourceId ||
          currentConnection !== relationship?.connectionCode
        currentDatabaseResourceId = relationship?.databaseResourceId || ''
        currentConnection = relationship?.connectionCode || ''
        return (
          <Box key={relationship?.id} borderBottom={`1px solid ${theme.palette.iron.main}`}>
            {shouldDisplayDatabase ? (
              <Box display="flex" alignItems="center" gap={1} mt={4}>
                <Box display="flex" alignItems="center" gap={0.5}>
                  <img
                    width={16}
                    height={16}
                    src={getAssetCloudImgUrl(relationship?.technology || '')}
                    alt={relationship?.technology || 'technology image'}
                  />
                  <Icon name="Database" width="16px" height="16px" />
                </Box>
                <Typography variant="h4" className={classes.textContainer}>
                  {relationship?.databaseResourceName}
                </Typography>

                <Chip icon={<Icon name="OConnectionTrue" />} label={relationship?.connectionCode} size="small" />
              </Box>
            ) : null}

            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box className={classes.relationshipDetailsContainer}>
                <Box display="flex" mb={1}>
                  <Chip
                    icon={
                      <Box display="flex" alignItems="center" gap={0.5} pr="4px">
                        <img
                          width={16}
                          height={16}
                          src={getAssetCloudImgUrl(relationship?.technology || '')}
                          alt={relationship?.technology || 'technology image'}
                        />
                        <Icon
                          name="Table"
                          sx={{ width: '16px!important', height: '16px!important' }}
                          width="16px"
                          height="16px"
                        />
                      </Box>
                    }
                    label={relationship?.foreignTableResourceName}
                    size="small"
                  />
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <Box className={classes.fk}>FK</Box>
                  <Typography variant="body" className={classes.textContainer}>
                    {relationship?.foreignSchemaResourceName
                      ? `${relationship.foreignSchemaResourceName}.${relationship.foreignTableResourceName}.${relationship.foreignKeyColumnName}`
                      : `${relationship.foreignTableResourceName}.${relationship.foreignKeyColumnName}`}
                  </Typography>
                </Box>
              </Box>

              <RelationshipIcon />

              <Box className={classes.relationshipDetailsContainer}>
                <Box display="flex" mb={1}>
                  <Chip
                    icon={
                      <Box display="flex" alignItems="center" gap={0.5} pr="4px">
                        <img
                          width={16}
                          height={16}
                          src={getAssetCloudImgUrl(relationship?.technology || '')}
                          alt={relationship?.technology || 'technology image'}
                        />
                        <Icon
                          name="Table"
                          sx={{ width: '16px!important', height: '16px!important' }}
                          width="16px"
                          height="16px"
                        />
                      </Box>
                    }
                    label={relationship?.primaryTableResourceName}
                    size="small"
                  />
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <Box className={classes.pk}>PK</Box>
                  <Typography variant="body" className={classes.textContainer}>
                    {relationship?.primarySchemaResourceName
                      ? `${relationship.primarySchemaResourceName}.${relationship.primaryTableResourceName}.${relationship.primaryKeyColumnVal}`
                      : `${relationship.primaryTableResourceName}.${relationship.primaryKeyColumnVal}`}
                  </Typography>
                </Box>
              </Box>

              {props.shouldDelete ? (
                <Button
                  variant="iconCustomRounded"
                  color="tertiary"
                  onClick={() => {
                    if (props.setDeleteRelationship) props.setDeleteRelationship(relationship)
                  }}
                >
                  <Icon name="FBin" width="20px" height="20px" />
                </Button>
              ) : null}
            </Box>
          </Box>
        )
      })}
    </StyledBox>
  )
}
