import React from 'react'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { Formik } from 'formik'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useWorkflows } from 'api/workflows/queries/useWorkflows'
import { useAssignWorkflow } from 'api/rightInvocations/mutations/useAssignWorkflow'
import { showToast } from 'components/modals/AlertComponent'
import { initialValues, RightInvocationAssignFormValues, validationSchema } from './utils'
import { Box, FormHelperText, Typography } from '@mui/material'
import { Button, PopUp } from '@ketch-com/deck'
import { FormDroplistButton } from 'components/form/FormDroplistButton'

type Props = {
  onClose: () => void
}

export const RightInvocationAssignWorkflowModal: React.FC<Props> = ({ onClose }) => {
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()

  const { data: workflows, isLoading } = useWorkflows()

  const { mutateAsync: handleAssignWorkflow } = useAssignWorkflow({
    onSuccess: async () => {
      showToast({ content: 'Workflow assigned', type: 'success' })
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      onClose()
    },
    onError: () => {
      showToast({ content: 'Failed to assign workflow', type: 'error' })
    },
  })

  const onSubmit = async ({ workflowCode }: RightInvocationAssignFormValues) => {
    await handleAssignWorkflow({
      params: {
        rightInvocationId: id!,
        workflowCode,
      },
    })

    await queryClient.refetchQueries([ApiQueryKeys.rightInvocationV2, { rightInvocationId: id }])
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ submitForm, isValid, isSubmitting, values }) => (
        <>
          <PopUp
            title="Workflow Selection"
            variant="modal"
            isLoading={isLoading}
            onClose={onClose}
            popUpActionChildren={
              <>
                <Button color="secondary" size="large" onClick={onClose} pending={isSubmitting} disabled={!isValid}>
                  Close
                </Button>
                <Button color="primary" size="large" disabled={!isValid} pending={isSubmitting} onClick={submitForm}>
                  Save
                </Button>
              </>
            }
          >
            <Box mt={2}>
              <FormDroplistButton
                disableClearable
                fullWidth
                formPropertyName="workflowCode"
                label="Workflow"
                placeholder="Select workflow"
                valueKey="code"
                items={workflows}
                required
                isOptionEqualToValue={(option, value) => option.code === value.code}
              />
              <FormHelperText>
                <Typography variant="footnote">Once selected, the request will start immediately.</Typography>
              </FormHelperText>
            </Box>
          </PopUp>
        </>
      )}
    </Formik>
  )
}
