export enum PreferenceField {
  MAIN_TITLE = 'PREFERENCE_MAIN_TITLE',

  OVERVIEW_TAB_NAME = 'PREFERENCE_OVERVIEW_TAB_NAME',
  OVERVIEW_BODY_TITLE = 'PREFERENCE_OVERVIEW_BODY_TITLE',
  OVERVIEW_BODY_DESCRIPTION = 'PREFERENCE_OVERVIEW_BODY_DESCRIPTION',

  RIGHTS_TAB_NAME = 'PREFERENCE_RIGHTS_TAB_NAME',

  RIGHTS_FORM_SETTINGS = 'PREFERENCE_RIGHTS_FORM_SETTINGS',
  RIGHTS_REQUEST = 'PREFERENCE_RIGHTS_REQUEST',
  RIGHTS_BODY_TITLE = 'PREFERENCE_RIGHTS_BODY_TITLE',
  RIGHTS_BODY_DESCRIPTION = 'PREFERENCE_RIGHTS_BODY_DESCRIPTION',
  RIGHTS_FOOTER_BUTTONS = 'PREFERENCE_RIGHTS_FOOTER_BUTTONS',
  PREFERENCE_PURPOSES = 'PREFERENCE_PURPOSES',

  PREFERENCES_TAB_NAME = 'PREFERENCE_PREFERENCES_TAB_NAME',
  PREFERENCES_BODY_TITLE = 'PREFERENCE_PREFERENCES_BODY_TITLE',
  PREFERENCES_BODY_DESCRIPTION = 'PREFERENCE_PREFERENCES_BODY_DESCRIPTION',
  PREFERENCES_SUBMIT_BUTTON = 'PREFERENCE_PREFERENCES_SUBMIT_BUTTON',

  SUBSCRIPTIONS_TAB_NAME = 'PREFERENCE_SUBSCRIPTIONS_TAB_NAME',
  SUBSCRIPTIONS_BODY_TITLE = 'PREFERENCE_SUBSCRIPTIONS_BODY_TITLE',
  SUBSCRIPTIONS_BODY_DESCRIPTION = 'PREFERENCE_SUBSCRIPTIONS_BODY_DESCRIPTION',
  SUBSCRIPTIONS_PRIMARY_BUTTON = 'PREFERENCE_SUBSCRIPTIONS_PRIMARY_BUTTON',
  SUBSCRIPTIONS_SECONDARY_BUTTON = 'PREFERENCE_SUBSCRIPTIONS_SECONDARY_BUTTON',
  SUBSCRIPTIONS_TOPICS = 'PREFERENCE_SUBSCRIPTIONS_TOPICS',
  SUBSCRIPTIONS_CONTROL = 'PREFERENCE_SUBSCRIPTIONS_CONTROL',
}
