import { useContext } from 'react'
import { BuilderContext } from '../../../Builder'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material'
import { RichTextRenderer } from '../../RichTextRenderer'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from '../../../../../../../themes-v3/upsert/utils/useThemeValidationSchema'
import { BannerContainerLayout, BannerContainerPosition, BannerPosition } from '@ketch-sdk/ketch-types'

const positionWidth: { [position: string]: string } = {
  [BannerContainerPosition.Bottom]: '465px',
  [BannerContainerPosition.Top]: '465px',
  [BannerContainerPosition.LeftCorner]: '288px',
  [BannerContainerPosition.RightCorner]: '288px',
  [BannerContainerPosition.BottomMiddle]: '310px',
  [BannerContainerPosition.Center]: '310px',
}

export const BannerDescription: React.FC = () => {
  const { themeConfig, experienceEnglishConfig } = useContext(BuilderContext)

  const layout = getIn(themeConfig, themeUpsertFormKeys.bannerContainerLayout) as BannerContainerLayout
  const position = getIn(themeConfig, themeUpsertFormKeys.bannerContainerPosition) as BannerPosition

  const descriptionBoxSx: SxProps = {
    width: positionWidth[position],
    wordWrap: 'break-word',
  }

  return (
    <Box sx={descriptionBoxSx}>
      <RichTextRenderer
        text={experienceEnglishConfig.banner?.description}
        textColor={getIn(themeConfig, themeUpsertFormKeys.bannerDescriptionTextColor)}
        linkColor={getIn(themeConfig, themeUpsertFormKeys.bannerDescriptionLinkColor)}
        underlineLinks={getIn(themeConfig, themeUpsertFormKeys.bannerDescriptionUnderlineLinks)}
        center={layout === BannerContainerLayout.Centered}
        fontSize={13}
      />
    </Box>
  )
}
