import { Button, PopUp, Radio, theme } from '@ketch-com/deck'
import { Box, FormControlLabel, RadioGroup, styled, Typography } from '@mui/material'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useMemo, useState } from 'react'
import { useUpdateWebTags } from 'api/webtags/mutations/useUpdateWebTags'
import { ConnectionDropList } from './ConnectionDropList'
import { ProviderDropList } from './ProviderDropList'

type Props = {
  onClose: () => void
}

enum ConnectionsTarget {
  AllConnections = 'allConnections',
  SpecificConnections = 'specificConnections',
}

const connectionsTargetTitles = {
  [ConnectionsTarget.AllConnections]: 'All Connections',
  [ConnectionsTarget.SpecificConnections]: 'Specific Connections',
}

const connectionsTargetDescriptions = {
  [ConnectionsTarget.AllConnections]: 'All available connections and discoverable tags will be updated',
  [ConnectionsTarget.SpecificConnections]: 'Only selected connection and its tags will be updated',
}

const OptionBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

const SpecificConnectionsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '12px',
  marginLeft: '35px',
})

/**
 * Modal to handle pushing updates to tag managers
 */
export const UpdateTagsModal: React.FC<Props> = ({ onClose }) => {
  const [connectionsTarget, setConnectionsTarget] = useState<ConnectionsTarget>(ConnectionsTarget.AllConnections)
  const [appCode, setAppCode] = useState('')
  const [appInstanceID, setAppInstanceID] = useState('')

  // Allow clicking the update button when all connections is selected or if specific connections is selected AND
  // and app and connection have been chosen
  const allowUpdate = useMemo(
    () => connectionsTarget === ConnectionsTarget.AllConnections || (appCode && appInstanceID),
    [connectionsTarget, appCode, appInstanceID],
  )

  const { mutateAsync: updateWebTags, isLoading: isUpdating } = useUpdateWebTags({
    onSuccess: () => {
      showToast({ content: 'Updated web tags', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Web tag update failed', type: 'error' })
    },
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConnectionsTarget(e.target.value as ConnectionsTarget)
  }

  // Format request body and make update call, then call onClose
  const handleUpdate = async () => {
    const formData = connectionsTarget === ConnectionsTarget.AllConnections ? {} : { appCode, appInstanceID }
    await updateWebTags({ params: { formData } })
    onClose()
  }

  return (
    <PopUp
      title="Update Tags"
      subTitle="Push your changes from Ketch."
      popUpWidth="560px"
      variant="modal"
      onClose={onClose}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isUpdating} onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" size="large" pending={isUpdating} onClick={handleUpdate} disabled={!allowUpdate}>
            Update
          </Button>
        </>
      }
    >
      <Box display={'flex'} flexDirection={'column'} gap="12px">
        <Typography variant="label">Search for</Typography>
        <RadioGroup value={connectionsTarget} onChange={handleChange} sx={{ gap: '24px' }}>
          {Object.values(ConnectionsTarget).map((status, index) => (
            <OptionBox key={status}>
              <FormControlLabel
                value={status}
                control={<Radio />}
                label={
                  <>
                    <Typography component={'p'}>{connectionsTargetTitles[status]}</Typography>
                    <Typography component={'p'} color={theme.palette.Text.Secondary}>
                      {connectionsTargetDescriptions[status]}
                    </Typography>
                  </>
                }
                sx={{ ml: '0px' }}
              />
            </OptionBox>
          ))}
        </RadioGroup>

        {/* Provider + connections picker */}
        {connectionsTarget === ConnectionsTarget.SpecificConnections && (
          <SpecificConnectionsBox>
            <Typography>Please select a provider and a connection to initiate update:</Typography>

            <ProviderDropList
              value={appCode}
              onChange={value => {
                setAppCode(value || '')
                setAppInstanceID('')
              }}
              sx={{ mb: '8px' }}
            />

            <ConnectionDropList
              appCode={appCode}
              value={appInstanceID}
              onChange={option => {
                setAppInstanceID(option?.value || '')
              }}
              disabled={!appCode}
            />
          </SpecificConnectionsBox>
        )}
      </Box>
    </PopUp>
  )
}
