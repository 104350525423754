import { Box } from '@mui/material'

import { Checkbox } from 'components/ui-kit/checkbox/Checkbox'

import { useMegaFilterStyles } from '../../hooks'
import { FILTER_STATES } from '../../constants'
import { FilterConfigItem } from '../../utils/getFiltersConfig'
import { LoadingIndicator } from '../LoadingIndicator'
import { useMegaFilterPurposesExpandableRowUtils } from './hooks'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { removePurposesFilter, addPurposesFilter } from 'store/megaFilterStateSlice'

type Props = {
  rowData: FilterConfigItem
  filterState: FILTER_STATES
}

export const MegaFilterPurposesExpandableRow: React.FC<Props> = ({ rowData, filterState }) => {
  const classes = useMegaFilterStyles({ filterState })

  const {
    infinitePurposes,
    handleScroll,
    isFetchingNextPage,
    isLoading,
    setSearchString,
    isLoadingSearchPurposes,
    searchString,
    purposesState,
    dispatch,
  } = useMegaFilterPurposesExpandableRowUtils()

  return (
    <Box className={classes.filterOptionsContainer}>
      <Box>
        <SearchInput
          fullWidth
          isSearchPending={isLoadingSearchPurposes}
          size="small"
          onChange={nextSearchString => setSearchString(nextSearchString)}
          onClear={() => setSearchString('')}
          value={searchString}
        />
      </Box>

      {/* Initial Loading Indicator, When Expansion Panel First Opens */}

      {isLoading && (
        <Box mt={2}>
          <LoadingIndicator />
        </Box>
      )}

      <Box onScroll={handleScroll} pb={1} display="flex" flexDirection="column" maxHeight={150} overflow="scroll">
        {infinitePurposes.map(purpose => {
          const isChecked = purposesState.includes(purpose.ID)
          return (
            <Box className={classes.typeCheckboxContainer} key={purpose.ID}>
              <Checkbox
                id={purpose.ID}
                name={purpose.ID}
                title={purpose.displayName}
                value={isChecked}
                onChange={() =>
                  isChecked ? dispatch(removePurposesFilter(purpose.ID)) : dispatch(addPurposesFilter(purpose.ID))
                }
              />
            </Box>
          )
        })}

        {isFetchingNextPage && (
          <Box mt={0.5}>
            <LoadingIndicator />
          </Box>
        )}
      </Box>
    </Box>
  )
}
