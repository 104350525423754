import React from 'react'

import { RegulationDTO } from 'interfaces/regulations/regulation'
import { excludeFalsy } from 'utils/helpers/excludeFalsy'
import { getRegulationNameByCode } from 'utils/helpers/regulations'
import { Chip } from '@ketch-com/deck'
import { Tooltip, Typography } from '@mui/material'

type Props = {
  regulationCodes?: string[]
  regulations: RegulationDTO[]
}

export const PolicyScopeRegulations: React.FC<Props> = ({ regulationCodes, regulations }) => {
  const regulationsNames = (regulationCodes || [])
    .map(regulationCode => getRegulationNameByCode(regulationCode, regulations))
    .filter(excludeFalsy)

  return regulationsNames.length ? (
    <Tooltip
      placement="right"
      title={
        <>
          {regulationsNames.map(name => (
            <div key={name}>{name}</div>
          ))}
        </>
      }
    >
      <span>
        <Chip size="small" clickable label={regulationsNames.length} />
      </span>
    </Tooltip>
  ) : (
    <Typography variant="body">None</Typography>
  )
}
