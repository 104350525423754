import { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Box, Typography } from '@mui/material'
import { Button, PopUp, TooltipButton } from '@ketch-com/deck'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { TaskHeaderTimestamp } from './TaskHeaderTimestamp'
import { RoutesManager } from 'utils/routing/routesManager'
import { TaskContext } from '../context'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'

type Props = {}

export const TaskDetailsHeaderActions: React.FC<Props> = () => {
  const { isAssignedToMe } = useContext(RightInvocationWorkflowStepDetailViewContext)
  const {
    canEdit,
    isEditMode,
    resolve,
    setEditMode,
    stepDetails,
    workflowStep,
    isWorkflowComplete,
    isWorkflowExecutionTerminated,
  } = useContext(TaskContext)

  const navigate = useNavigate()
  const { id } = useParams<any>()

  const [isOpen, setIsOpen] = useState(false)

  const { isCompleteWorkflowExecutionStepLoading } = useContext(TaskContext)

  const isNotSuccess = workflowStep?.status !== WorkflowExecutionStepStatus.SUCCESS
  const isInProgress = workflowStep?.status === WorkflowExecutionStepStatus.IN_PROGRESS
  const isComplete = workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS

  const tooltipTitle = isAssignedToMe ? '' : 'Resolve is only available for assignees.'
  const handleEdit = () => {
    if (isEditMode) {
      setEditMode(false)
      navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id }))
    } else {
      setIsOpen(true)
    }
  }

  const isResolveDisabled = !isAssignedToMe || isCompleteWorkflowExecutionStepLoading

  return (
    <Box display="flex" gap={1.5} alignItems="center">
      <TaskHeaderTimestamp
        startedAt={stepDetails?.startedAt}
        updatedAt={stepDetails?.updatedAt}
        completedAt={stepDetails?.completedAt}
        status={stepDetails?.status}
      />

      {(isInProgress || isNotSuccess) && !isWorkflowExecutionTerminated && (
        <TooltipButton
          title={tooltipTitle}
          pending={isCompleteWorkflowExecutionStepLoading}
          disabled={isResolveDisabled}
          size="large"
          onClick={() => resolve()}
        >
          Resolve
        </TooltipButton>
      )}

      {isComplete && canEdit && !isWorkflowComplete && (
        <Box>
          <Button size="large" onClick={handleEdit}>
            {isEditMode ? 'Submit Edits' : 'Edit'}
          </Button>
        </Box>
      )}

      {isOpen && (
        <PopUp
          title="Edit Task"
          variant="dialog"
          onClose={_ => setIsOpen(false)}
          popUpActionChildren={
            <>
              <Button
                color="secondary"
                size="large"
                onClick={e => {
                  setIsOpen(false)
                }}
              >
                Close
              </Button>
              <Button
                color="primary"
                size="large"
                onClick={_ => {
                  setEditMode(!isEditMode)
                  setIsOpen(false)
                }}
              >
                Edit
              </Button>
            </>
          }
        >
          <Typography>
            This Task has already been completed, are you sure you want to proceed with editing? Please note, Outcome
            and Data Subject Variables cannot be edited post Task completion due to their relationship with Decision
            Gateways.
          </Typography>
        </PopUp>
      )}
    </Box>
  )
}
