import qs from 'query-string'
import { ObjectLiteral } from 'interfaces'

/**
 * Formats a request string for a given entity and parameters
 * @param entityUrl URL for the resource (e.g. /api/v2/right-invocations)
 * @param params Optional object containing key-value pairs to include in the request as query parameters
 * @returns The formatted request string
 */
export const formatRequestStringV2 = ({ entityUrl, params = {} }: { entityUrl: string; params?: ObjectLiteral }) => {
  // Destructure the filters from the params object
  const { filters } = params

  // If filters are present, reduce them to a new object where each key-value pair is URI encoded
  // Otherwise, return an empty object
  const reducedFilters = Object.entries(filters || {}).reduce?.((acc: any, [key, value]) => {
    if (!value) return acc
    // If the value is an array, convert it to a comma-separated string
    if (Array.isArray(value)) {
      return { ...acc, [key]: value.join(',') }
    }
    // Otherwise, return the URI encoded value as a string
    return { ...acc, [key]: value as string }
  }, {})

  // Convert the reduced filters object to a query string
  const queryStr = qs.stringify(reducedFilters)

  // Get the base URL from the figureheadConfig object in the global window object, if it exists
  // Otherwise, use the provided entityUrl
  const baseUrl = (window as any)?.figureheadConfig?.REACT_APP_BASE_URL
    ? `${(window as any)?.figureheadConfig?.REACT_APP_BASE_URL}${entityUrl}`
    : entityUrl

  // Initialize the payload with the base URL
  let payload = baseUrl

  // If a query string was created, append it to the payload
  if (queryStr) {
    payload += `?${queryStr}`
  }

  return payload
}
