import React, { useContext } from 'react'
import { AppNavigationContext } from '../../../context/AppNavigationContext'
import { Box, Typography, styled } from '@mui/material'
import { Button, Icon, theme } from '@ketch-com/deck'
import { GatedNavigateStatus, useNavigateGated } from 'utils/routing/hooks/useNavigateGated'
import { SidebarSearchResult } from '../utils'

const ResultsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 14px 0px 8px',
  gap: '32px',
})

const ResultBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
})

const ParentsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
})

const ParentBox = styled(Box)({
  display: 'flex',
  gap: '8px',
})

const PromptBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '14px 16px 16px 16px',
  gap: '6px',
})

export const SidebarSearchResults: React.FC = () => {
  const { sidebarSearchResults, setSidebarSearch, sidebarSearch, setSearchActive, searchInputRef } =
    useContext(AppNavigationContext)
  const navigateGated = useNavigateGated()
  const showStartTypingPrompt = sidebarSearchResults.length === 0 && !sidebarSearch
  const showNoResultsPrompt = sidebarSearchResults.length === 0 && sidebarSearch

  const handleItemClick = ({ path, permissions, entitlements }: SidebarSearchResult) => {
    const { status } = navigateGated(path, permissions, entitlements)
    if (status === GatedNavigateStatus.Success) {
      setSidebarSearch('')
      setSearchActive(false)
    }
  }

  const handleClear = () => {
    setSidebarSearch('')
    searchInputRef.current?.focus()
  }

  return (
    <ResultsBox>
      {showStartTypingPrompt || showNoResultsPrompt ? (
        <PromptBox>
          {showStartTypingPrompt && (
            <>
              <Typography variant="label" color={theme.palette.Text.Secondary}>
                Quick Search
              </Typography>
              <Typography variant="smallBody" color={theme.palette.Text.Secondary}>
                Begin typing the feature or page name.
              </Typography>
            </>
          )}
          {showNoResultsPrompt && (
            <>
              <Typography variant="label" color={theme.palette.Text.Secondary}>
                Nothing Found
              </Typography>
              <Typography variant="smallBody" color={theme.palette.Text.Secondary}>
                Unfortunately, we couldn't find anything related to your query.
              </Typography>
              <Button color="tertiary" onClick={handleClear} sx={{ mt: '18px' }}>
                Reset
              </Button>
            </>
          )}
        </PromptBox>
      ) : (
        sidebarSearchResults.map(item => (
          <ResultBox>
            <ParentsBox>
              {item.parents.map((parent, index) => (
                // Increase padding by 12px for each item after the first
                <ParentBox pl={index * 1.5}>
                  <Box width={2} height={16} borderRadius={100} bgcolor={theme.palette.Text.Secondary} />
                  <Typography variant="smallLabel" color={theme.palette.Text.Secondary}>
                    {parent}
                  </Typography>
                </ParentBox>
              ))}
            </ParentsBox>
            <Button
              fullWidth
              variant="iconCustomRounded"
              onClick={() => handleItemClick(item)}
              sx={theme => ({
                height: '34px',
                borderRadius: '8px',
                paddingLeft: '10px',
                paddingRight: '7px',
                transition: 'width 0.3s ease',
                backgroundColor: `${theme.palette.Action.Disabled} !important`,
                color: theme.palette.Action.Primary,
                width: '100%',
                gap: '4px',
                justifyContent: 'space-between',

                '& span': {
                  margin: '0px !important',
                },
                '& .DeckBtnWrapChild': {
                  width: '100%',

                  // These 3 styles make the text not jump as wraps/unwraps when the sidebar width changes
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  transition: 'width 0.3s ease',
                },
              })}
              endIcon={<Icon name="OArrowRight" iconColor={theme.palette.Action.Primary} width={20} height={20} />}
              title=""
            >
              <Typography variant="label">{item.title}</Typography>
            </Button>
            {/* Description display descoped for now */}
            {/* {description && (
              <DescriptionBox>
                <Icon name="FInfo" iconColor={theme.palette.Text.Secondary} width={16} height={16} />
                <Typography variant="footnote" color={theme.palette.Text.Secondary}>
                  {description}
                </Typography>
              </DescriptionBox>
            )} */}
          </ResultBox>
        ))
      )}
    </ResultsBox>
  )
}
