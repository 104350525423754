import { useContext, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Button, DropListButton, ListItemText, ActionSheetItem } from '@ketch-com/deck'
import { FormFieldCategoryDTO, WebhooksContextTimelineDTO } from '@ketch-com/figurehead'
import { useState } from 'react'
import { useFormFields } from 'api/formFields/queries/useFormFields'
import { ApiCallContext } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/context/ApiCallContext'
import { FormDataInputValue } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'
import { FormFieldInlineForm } from '../../../../../..'

type Props = {
  timelineItem: WebhooksContextTimelineDTO
}

export const UnknownFormData: React.FC<Props> = ({ timelineItem }) => {
  const { submitTimelineChange } = useContext(ApiCallContext)

  const { formData = {} } = timelineItem
  const { isActionEnabled } = formData
  const formDataCode = formData?.data?.[0]?.code
  const formDataValueOriginal = formData?.data?.[0]?.value

  const { data: formFields } = useFormFields({
    itemsPerPage: 1000,
    params: {
      ordering: { title: 'asc' },
      includeMetadata: true,
      category: FormFieldCategoryDTO.CustomFormFieldCategory,
    },
  })

  const [showForm, setShowForm] = useState(false)
  const [selectedCode, setSelectedCode] = useState<string>('')
  const [formValue, setFormValue] = useState<FormDataInputValue>()
  const formField = formFields?.find(f => f.code === selectedCode)

  const submit = () => {
    const isFormValueAnArray = Array.isArray(formValue)

    const payload = {
      timelineItem,
      timelineItemToReplace: {
        ...timelineItem,
        formData: {
          data: [
            {
              code: formDataCode,
              value: String(formDataValueOriginal),
              name: formData?.data?.[0]?.name,
            },
          ],
          isActionEnabled: false,
        },
      },
      newTimelineItem: {
        ...timelineItem,
        formData: {
          data: [
            {
              code: selectedCode,
              ...(!isFormValueAnArray ? { value: String(formValue) } : { values: formValue }),
              name: formField?.name || formField?.title,
            },
          ],
          isActionEnabled: false,
        },
      },
    }

    submitTimelineChange(payload)
  }

  useEffect(() => {
    if (!isActionEnabled) setShowForm(false)
  }, [isActionEnabled])

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography>
          Form Field received:&nbsp;<Typography variant="label">{formDataCode}</Typography>
        </Typography>
      </Box>
      <Box mt={0.5} mb={1.5}>
        <Typography variant="label">
          Issue:<Typography>&nbsp;Form Field is not found in your organization.</Typography>
        </Typography>
      </Box>

      {/* Action Enabled View */}

      {isActionEnabled && !showForm ? (
        <Box display="flex" gap={1.5}>
          <Button color="tertiary" onClick={() => setShowForm(true)}>
            Change Form Field
          </Button>
        </Box>
      ) : null}

      {/* Form */}

      {showForm ? (
        <Box mt={1.5} width={500}>
          <Grid container alignItems="flex-end" gap={1.5}>
            <Grid item xs={10}>
              <DropListButton
                // see https://github.com/mui/material-ui/issues/33661 for more details
                disableClearable={!!(selectedCode !== null)}
                fullWidth
                label="Change To"
                isOptionEqualToValue={(option, value) => option.code === value.code}
                getOptionLabel={option => option.name || ''}
                renderOption={(props, option, { selected }) => (
                  <ActionSheetItem {...props} key={option.code} selected={selected}>
                    <ListItemText selected={selected}>{option.name}</ListItemText>
                  </ActionSheetItem>
                )}
                options={formFields}
                onChange={(_, value) => value && value.code && setSelectedCode(value.code)}
                value={formFields.find(elem => selectedCode === elem.code) || null}
                placeholder="Select"
                size="small"
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-start">
              <FormFieldInlineForm
                formField={formField}
                onCancel={() => {
                  setShowForm(false)
                  setFormValue(undefined)
                }}
                formValue={formValue}
                setFormValue={setFormValue}
                onSubmit={submit}
                isUnknown
              />
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </Box>
  )
}
