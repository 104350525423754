import {
  ActionSheetItem,
  Button,
  Chip,
  ChipsAutocomplete,
  ChipsAutocompleteOptionType,
  Icon,
  ListItemText,
  SwitchToggle,
  TextInput,
  theme,
} from '@ketch-com/deck'
import { Box, InputAdornment, Link, Popover, styled, Tooltip, Typography } from '@mui/material'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import pluralize from 'pluralize'
import React, { useMemo, useRef, useState } from 'react'
import { useTagsList } from '../context/TagsContext'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useBulkUpdateWebTagPurposes } from 'api/webtags/mutations/useBulkUpdateWebTagPurposes'
import { WebTagDTO, WebTagPurposeBulkUpdateFormDTO } from 'interfaces/webtags/WebTags'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useQueryClient } from 'react-query'
import { AdvancedConsentModeAppCodes, AppCode } from '../utils/enums'

type Props = {
  tag: WebTagDTO
  purposes?: PurposeDTO[]
  advancedConsentModeEnabled?: boolean
}

const PurposesPopover = styled(Popover)({
  marginTop: '8px',

  '.MuiPaper-root': {
    borderRadius: '11px',
    border: `2px solid ${theme.palette.Common.Divider}`,
  },
})

const PopoverBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '310px',

  // Ensure the autocomplete is full width
  '& > .MuiBox-root:first-of-type': {
    width: '100%',
  },
})

const AdvancedConsentBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  borderTop: `1px solid ${theme.palette.Black.o48}`,
  padding: '12px 10px 12px 10px',
})

// Chip + popover that allow mapping purposes to a tag or toggling Built-in consent checks mode
export const TagPurposesMultiSelectChip: React.FC<Props> = ({ tag, purposes, advancedConsentModeEnabled }) => {
  const queryClient = useQueryClient()
  const { purposes: allPurposes, isPurposesLoading, isTagsWriter } = useTagsList()

  // Tag purposes update hook
  const { mutateAsync: bulkUpdatePurposes, isLoading: isBulkUpdating } = useBulkUpdateWebTagPurposes({
    onError: () => {
      showToast({ content: 'Tag purposes update failed', type: 'error' })
    },
  })

  // Popover variables and methods
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  // Autocomplete variables and methods
  const ref = useRef()
  const options: ChipsAutocompleteOptionType[] = allPurposes.map(purpose => ({
    label: purpose.name,
    value: purpose.ID,
  }))
  const value =
    purposes?.map(purpose => ({
      label: purpose.name,
      value: purpose.ID,
    })) || []
  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: ChipsAutocompleteOptionType) => (
    <ActionSheetItem {...props} key={option.value}>
      <ListItemText>{option.label}</ListItemText>
    </ActionSheetItem>
  )

  // Built-in consent checks switch
  const handleAdvancedConsentModeChange = async (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const purposeIDs = purposes?.map(purpose => purpose.ID) || []
    const tagPurposeMappings: WebTagPurposeBulkUpdateFormDTO['tagPurposeMappings'] = [
      {
        tagID: tag.ID,
        appCode: tag.appCode,
        appInstanceID: tag.appInstanceID,
        purposeIDs,
        advancedConsentModeEnabled: checked,
      },
    ]
    await bulkUpdatePurposes({ params: { formData: { tagPurposeMappings } } })

    // Refresh tags
    await queryClient.invalidateQueries(ApiQueryKeys.webTagsPaginated)
  }
  const showAdvancedConsentMode = useMemo(
    () => AdvancedConsentModeAppCodes.includes(tag.appCode as AppCode),
    [tag.appCode],
  )

  const handleChange = async (_: React.SyntheticEvent, newValue: ChipsAutocompleteOptionType[]) => {
    const purposeIDs = newValue.map(purpose => purpose.value)
    const tagPurposeMappings: WebTagPurposeBulkUpdateFormDTO['tagPurposeMappings'] = [
      {
        tagID: tag.ID,
        appCode: tag.appCode,
        appInstanceID: tag.appInstanceID,
        purposeIDs,
      },
    ]
    await bulkUpdatePurposes({ params: { formData: { tagPurposeMappings } } })

    // Refresh tags
    await queryClient.invalidateQueries(ApiQueryKeys.webTagsPaginated)
  }

  const handlePurposeDelete = async (newPurposes: ChipsAutocompleteOptionType[]) => {
    const purposeIDs = newPurposes.map(purpose => purpose.value)
    const tagPurposeMappings: WebTagPurposeBulkUpdateFormDTO['tagPurposeMappings'] = [
      {
        tagID: tag.ID,
        appCode: tag.appCode,
        appInstanceID: tag.appInstanceID,
        purposeIDs,
      },
    ]
    await bulkUpdatePurposes({ params: { formData: { tagPurposeMappings } } })

    // Refresh tags
    await queryClient.invalidateQueries(ApiQueryKeys.webTagsPaginated)
  }

  return (
    <div>
      <Chip
        label={
          <Typography variant={advancedConsentModeEnabled || purposes?.length ? 'label' : 'body'}>
            {advancedConsentModeEnabled
              ? 'Built-in consent checks'
              : purposes?.length
              ? pluralize('Purpose', purposes?.length, true)
              : 'No purposes'}
          </Typography>
        }
        deleteIcon={<Icon name={open ? 'OArrowCUp' : 'OArrowCDown'} />}
        aria-describedby={id}
        onDelete={handleClick}
        onClick={handleClick}
        clickable
      />
      <PurposesPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <PopoverBox>
          <Box display="flex" flexDirection="column" padding="8px 16px 8px 16px">
            <ChipsAutocomplete
              ref={ref.current}
              fullWidth
              renderChipItem={advancedConsentModeEnabled ? () => null : undefined}
              loading={isPurposesLoading || isBulkUpdating}
              renderOption={renderOption}
              isOptionEqualToValue={(elem, value) => elem.value === value.value}
              disableClearable
              placeholder="Select a value"
              chipSize="medium"
              size="medium"
              disabled={!isTagsWriter || advancedConsentModeEnabled}
              multiple
              options={options}
              renderInput={params => (
                <TextInput
                  {...params}
                  variant="ghost"
                  placeholder="Add Purpose"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <Icon name="OMag" />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                    endAdornment: null,
                  }}
                />
              )}
              onChange={handleChange}
              handleChipClick={clickedPurpose => {
                const newPurposes = value.filter(purpose => purpose.value !== clickedPurpose.value)
                handlePurposeDelete(newPurposes)
              }}
              value={value}
              width="100%"
            />
            {advancedConsentModeEnabled && <Box mt="-28px" />}
            {advancedConsentModeEnabled || purposes ? null : (
              <Typography color={theme.palette.darkDuskFaded.main} mt="-16px" mb="12px">
                No purposes assigned yet.
              </Typography>
            )}
          </Box>
          {showAdvancedConsentMode && (
            <AdvancedConsentBox>
              <SwitchToggle
                size="small"
                checked={advancedConsentModeEnabled}
                onChange={handleAdvancedConsentModeChange}
                disabled={!isTagsWriter}
              />
              <Typography pt="2px">Use Built-In Consent Checks</Typography>
              <Tooltip
                placement="bottom-start"
                title={
                  <Typography variant="body">
                    Google Tags will use built-In consent checks. Read more{' '}
                    <Link
                      href="https://docs.ketch.com/ketch/docs/google-tag-manager-integration#for-advanced-consent-mode"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ color: 'white !important', textDecorationColor: 'white !important ', fontWeight: 600 }}
                    >
                      here
                    </Link>
                    .
                  </Typography>
                }
              >
                <span>
                  <Button
                    variant="iconCustomRounded"
                    size="small"
                    sx={{
                      width: '24px !important',
                      height: '24px',
                      minWidth: '24px',
                      ':hover': { background: 'none' },
                    }}
                  >
                    <Icon name="FUnknown" iconColor={theme.palette.Black.o48} width={16} height={16} />
                  </Button>
                </span>
              </Tooltip>
            </AdvancedConsentBox>
          )}
        </PopoverBox>
      </PurposesPopover>
    </div>
  )
}
