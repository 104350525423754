import { useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'

import { getJurisdictionData } from 'pages/insights/dataMap/components/DataMapVisualization/utils/jurisdictionData'
import { FindMapDataResponseBodyDTO } from '@ketch-com/figurehead'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import DeckGL from '@deck.gl/react'
import { FlyToInterpolator, WebMercatorViewport } from '@deck.gl/core'
import { Box, Typography } from '@mui/material'
import bbox from '@turf/bbox'
import { Button } from '@ketch-com/deck'
import { useExportRopaCSVData, useLayers } from 'pages/insights/dataMap/components/DataMapVisualization/hooks'
import { useAuth, useIsPermitted } from 'utils/hooks'
import moment from 'moment'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

const exportEnabledOrgList = ['zendesk']

interface Props {
  visualizationData: FindMapDataResponseBodyDTO
  mapLabel?: string
  updateSelectedJurisdiction: (jurisdiction: string) => void
  selectedJurisdiction: string
  hideTitle?: boolean
  hideExportButton?: boolean
}

const initialViewState = {
  latitude: 45,
  longitude: 0,
  zoom: 0.9,
  bearing: 0,
  pitch: 0,
  transitionDuration: 750,
  transitionInterpolator: new FlyToInterpolator(),
}
export const MapVisualization: React.FC<Props> = ({
  visualizationData,
  mapLabel,
  updateSelectedJurisdiction,
  selectedJurisdiction,
  hideTitle = false,
  hideExportButton = false,
}) => {
  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()
  const isEntitledToDemo = isEntitled(ENTITLEMENTS.DEMO)
  const isEntitledToStatsDatamap = isEntitled(ENTITLEMENTS.STATS_DATAMAP)
  const isPermittedToDatamap = isPermitted(PERMISSIONS.DATAMAP_READ)

  const { userData } = useAuth()
  const [viewState, setViewState] = useState(initialViewState)
  const [hoverInfo, setHoverInfo] = useState<any>(null)
  const { jurisdictionGeoData, jurisdictionDataTransfers } = useMemo(
    () => getJurisdictionData(visualizationData),
    [visualizationData],
  )
  const layers = useLayers({ jurisdictionGeoData, jurisdictionDataTransfers, selectedJurisdiction })

  useEffect(() => {
    if (!selectedJurisdiction) setViewState(initialViewState)
  }, [selectedJurisdiction])

  const {
    data: csvData,
    headers: csvHeaders,
    hasNextPage,
    isLoading,
  } = useExportRopaCSVData(isEntitledToStatsDatamap && isPermittedToDatamap)

  const isExportEnabledForOrg = exportEnabledOrgList.includes(userData.organizationCode)

  return (
    <Box
      sx={{
        height: 650,
        position: 'relative',
      }}
    >
      {!hideExportButton && !selectedJurisdiction && (isEntitledToDemo || isExportEnabledForOrg) ? (
        <Box sx={{ position: 'absolute', top: 25, right: 50, zIndex: 1 }}>
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={`${userData.organizationName} DATA_MAP_${moment().format('MM-DD-YYYY')}`}
            style={{ textDecoration: 'none' }}
          >
            <Button size="large" pending={hasNextPage || isLoading}>
              Export
            </Button>
          </CSVLink>
        </Box>
      ) : null}

      <DeckGL
        viewState={viewState}
        getCursor={() => 'auto'}
        controller={{
          dragPan: false,
          doubleClickZoom: false,
          scrollZoom: false,
        }}
        layers={layers}
        onViewStateChange={e => setViewState(e.viewState)}
        onClick={info => {
          const { layer, object } = info

          if (selectedJurisdiction) {
            updateSelectedJurisdiction('')
            return
          }

          if (layer) {
            const [minLng, minLat, maxLng, maxLat] = bbox(object)
            const viewport: any = new WebMercatorViewport({ ...viewState, width: 850 })
            const { longitude, latitude, zoom } = viewport.fitBounds(
              [
                [minLng, minLat],
                [maxLng, maxLat],
              ],
              {
                padding: 10,
              },
            )
            setViewState({
              ...initialViewState,
              longitude,
              latitude,
              zoom,
            })
            updateSelectedJurisdiction(layer.id)
          }
        }}
        onHover={info => setHoverInfo(info)}
      >
        {!hideTitle && (
          <Typography
            sx={{
              position: 'absolute',
              zIndex: 1,
              pointerEvents: 'none',
              left: ({ spacing }) => spacing(4),
              top: ({ spacing }) => spacing(4),
            }}
            variant="h1"
          >
            {mapLabel || 'ROPA Report'}
          </Typography>
        )}
        {!selectedJurisdiction && hoverInfo?.object && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: 1,
              pointerEvents: 'none',
              left: hoverInfo.x,
              top: hoverInfo.y,
              backgroundColor: `rgba(${hoverInfo.object.properties.jurisdictionStrokeColor})`,
              color: 'white.main',
              padding: ({ spacing }) => spacing(0.5, 1),
              borderRadius: ({ spacing }) => spacing(12.25),
              fontSize: ({ spacing }) => spacing(1.25),
            }}
          >
            {hoverInfo.object.properties.jurisdictionName}
          </Box>
        )}
      </DeckGL>
    </Box>
  )
}
