import React, { useState, useEffect, JSXElementConstructor } from 'react'
import { isEmpty } from 'lodash'
import { TreeView, TreeItemContentProps } from '@mui/x-tree-view'
import { ObjectLiteral } from 'interfaces'
import { SearchInput } from 'components/form/SearchInput'
import { constructListItem } from 'components/transferList/utils'
import { Typography, Box } from '@mui/material'
import { theme } from '@ketch-com/deck'

interface Props<T> {
  items: T[]
  title?: string
  searchString?: string
  onItemClick: (item: T) => void
  onSearch?: (nextSearchString: string) => void
  type: string
  expandableKey?: string
  defaultExpanded?: string[]
  ContentComponent: JSXElementConstructor<TreeItemContentProps>
}
export function Tree<T extends ObjectLiteral>({
  title,
  items,
  onItemClick,
  searchString,
  onSearch,
  type,
  expandableKey,
  defaultExpanded,
  ContentComponent,
}: Props<T>) {
  const [expanded, setExpanded] = useState<string[]>(defaultExpanded || [])

  useEffect(() => {
    if (type === 'INCLUDE' && defaultExpanded) {
      setExpanded(defaultExpanded)
    }
  }, [defaultExpanded, type])

  const handleNodeToggle = (event: React.ChangeEvent<{}>, nodes: string[]) => {
    setExpanded(nodes)
  }

  return (
    <Box sx={{ flex: 1, userSelect: 'none' }}>
      {title && (
        <Box sx={{ marginBottom: '6px' }}>
          <Typography variant="label">{title}</Typography>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '4px',
          border: `1px solid ${theme.palette.iron.main}`,
          maxWidth: '518px',
        }}
      >
        {onSearch && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              borderBottom: `1px solid ${theme.palette.iron.main}`,
              background: theme.palette.bone.main,
              padding: '8px',
            }}
          >
            <SearchInput
              startSearchFromChar={0}
              fullWidth
              debounceTimeout={0}
              value={searchString!}
              onChange={() => {}}
              onSearch={onSearch}
              onClear={() => onSearch('')}
              sx={{ flexGrow: 1 }}
            />
          </Box>
        )}

        {isEmpty(items) && !isEmpty(searchString) && (
          <Box
            sx={{
              height: '370px',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
              overflowY: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '40px auto',
                textAlign: 'center',
                width: '200px',
              }}
            >
              <Typography color="grey">No Items found matching your search criteria</Typography>
            </Box>
          </Box>
        )}

        {isEmpty(items) && isEmpty(searchString) && (
          <Box
            sx={{
              height: '370px',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
              overflowY: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '40px auto',
                textAlign: 'center',
                width: '200px',
              }}
            >
              <Typography color="grey">No Items</Typography>
            </Box>
          </Box>
        )}

        {!isEmpty(items) && (
          <Box
            sx={{
              height: '370px',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
              overflowY: 'auto',
            }}
          >
            <TreeView
              expanded={expanded}
              onNodeToggle={handleNodeToggle}
              sx={{
                padding: '4px 8px 8px 8px',
              }}
            >
              {items.map(item =>
                constructListItem({
                  item,
                  searchString,
                  onItemClick,
                  type,
                  expandableKey,
                  expanded,
                  ContentComponent,
                }),
              )}
            </TreeView>
          </Box>
        )}
      </Box>
    </Box>
  )
}
