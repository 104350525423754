import moment from 'moment'

import { ChartTrendPeriod } from 'interfaces/rights/rightStats'

export const formatChartDate = (trendPeriod: ChartTrendPeriod, date: string) => {
  switch (trendPeriod) {
    case ChartTrendPeriod.DAILY:
      return moment(date).format('ddd')

    case ChartTrendPeriod.WEEKLY:
      return `${moment(date).format('MMM')} ${moment(date).format('DD')}`

    case ChartTrendPeriod.MONTHLY_PAST_TWELVE:
      return `${moment(date).format('MMM')}`

    case ChartTrendPeriod.MONTHLY_PAST_CAL_YEAR:
      return `${moment(date).format('MMM')}`

    default:
      return moment(date).format('ddd')
  }
}
