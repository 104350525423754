import React, { Dispatch, SetStateAction } from 'react'
import { isEmpty } from 'lodash'
import { Waypoint } from 'react-waypoint'
import { Box } from '@mui/material'
import { Relationships } from 'pages/assetManager/interfaces'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/workflows.svg'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { Relationship } from 'pages/assetManager/relationshipMapping/components'
import { EmptyState, CircularProgress, CircularProgressSize } from '@ketch-com/deck'

type Props = {
  isReady?: boolean
  relationships?: Relationships
  isFetchingNextPage?: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => {}
  setIsCreateRelationshipMappingModalOpen?: Dispatch<SetStateAction<boolean>>
}

export const CreatedRelationships: React.FC<Props> = props => {
  const {
    isReady,
    relationships,
    setIsCreateRelationshipMappingModalOpen,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage = () => {},
  } = props
  const { isPermitted } = useIsPermitted()
  const isPermittedToCreateRelationshipMapping = isPermitted(PERMISSIONS.ASSET_RELATIONSHIP_WRITE)

  if (!isReady) return <Spinner />

  if (isReady && isEmpty(relationships))
    return (
      <EmptyState
        customIcon={<EmptyStateIcon />}
        title="You have not created any relationships yet"
        subTitle="Create your first one to populate the table."
        primaryButtonTitle="Create Relationship"
        primaryButtonTooltipTitle={!isPermittedToCreateRelationshipMapping ? 'Create Relation' : undefined}
        primaryButtonProps={{
          onClick: () => setIsCreateRelationshipMappingModalOpen?.(true),
        }}
      />
    )

  return (
    <>
      <Relationship {...props} shouldDelete={true} />
      {!isFetchingNextPage ? (
        <Waypoint
          topOffset={300}
          onEnter={() => {
            if (!isFetchingNextPage && hasNextPage) fetchNextPage()
          }}
        />
      ) : (
        <Box my={2}>
          <CircularProgress size={CircularProgressSize.small} />
        </Box>
      )}
    </>
  )
}
