import { useMemo } from 'react'
import * as Yup from 'yup'

import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { useCheckIsCookieCodeAvailable } from 'api/cookies/mutations/useCheckIsCookieCodeAvailable'

export const useCookieValidationSchema = ({
  isEditMode,
  isKetchCookie,
}: {
  isEditMode?: boolean
  isKetchCookie?: boolean
}) => {
  const { mutateAsync: handleCheckIsCookieCodeAvailable } = useCheckIsCookieCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsCookieCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(
    () =>
      Yup.object().shape({
        ...(!isEditMode &&
          !isKetchCookie && {
            code: codeValidationSchema,
          }),
        name: Yup.string().max(75, 'Name must not exceed 75 characters').required('Please enter a cookie name'),
        serviceProvider: Yup.string().required('Please define the service provider for this cookie'),
        host: Yup.string().required('Please define the host for this cookie'),
        duration: Yup.number().required('Please select the duration for this cookie'),
        provenance: Yup.number().required('Please select the type for this cookie'),
        category: Yup.number().required('Please select the category for this cookie'),
        purposes: Yup.array().of(Yup.object()),
        propertyCookies: Yup.array().of(Yup.object()),
      }),
    [isEditMode, isKetchCookie, codeValidationSchema],
  )
}
