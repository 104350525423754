import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { CreateWebhookFormValues } from '../components/UpsertWebhookModal/utils/getInitialValues'
import { mapFormValuesToFormData } from '../components/UpsertWebhookModal/utils'
import { showToast } from 'components/modals/AlertComponent'
import { useDebounce } from 'react-use'
import { useCreateWebhook } from 'api/webhooks/mutations/useCreateWebhook'
import { useUpdateWebhook } from 'api/webhooks/mutations/useUpdateWebhook'
import { useDeleteWebhook } from 'api/webhooks/mutations/useDeleteWebhook'
import { useWebhooks } from 'api/webhooks/queries/useWebhooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'

export const useWebhooksListUtils = () => {
  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()
  const queryClient = useQueryClient()
  const [searchString, setSearchString] = useState('')
  const [searchQueryValue, setSearchQueryValue] = useState('')
  const [isCreateHookModalOpen, setIsCreateHookModalOpen] = useState<boolean>(false)
  const [hookToDeleteId, setHookToDeleteId] = useState<string>('')
  const [hookToEditId, setHookToEditId] = useState<string>('')

  const isPermittedToCreateHooks = isPermitted([PERMISSIONS.WEBHOOK_WRITE])
  const isEntitledToCreateHooks = isEntitled(ENTITLEMENTS.WEBHOOKS)

  const {
    data: webhooks,
    isLoading: isLoadingWebhooks,
    pagination,
  } = useWebhooks({
    params: {
      query: searchQueryValue,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch webhooks', type: 'error' })
    },
  })

  const { mutateAsync: handleCreateWebhook } = useCreateWebhook({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.webhooksPaginated])
    },
  })

  const { mutateAsync: handleUpdateWebhook } = useUpdateWebhook({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.webhooksPaginated])
      showToast({ content: 'Webhook updated', type: 'success' })
      setHookToEditId('')
    },
  })

  const { mutateAsync: handleDeleteWebhook } = useDeleteWebhook({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.webhooksPaginated])
      setHookToDeleteId('')
      showToast({ content: 'Webhook deleted', type: 'success' })
    },
  })

  const handleCreateHookOnClick = () => setIsCreateHookModalOpen(true)

  const handleSubmit = async (values: CreateWebhookFormValues) => {
    const formData = mapFormValuesToFormData(values)

    hookToEditId
      ? await handleUpdateWebhook({
          onError: () => showToast({ content: 'Failed to update webhook', type: 'error' }),
          onSuccess: () => showToast({ content: 'Webhook updated', type: 'success' }),
          params: {
            formData,
            webhookId: hookToEditId,
          },
        })
      : await handleCreateWebhook({
          onError: () => showToast({ content: 'Failed to create webhook', type: 'error' }),
          onSuccess: () => {
            setIsCreateHookModalOpen(false)
            showToast({ content: 'Webhook created', type: 'success' })
          },
          params: {
            formData,
          },
        })
  }

  const handleSubmitDeleteHook = async () => {
    await handleDeleteWebhook({
      onError: () => showToast({ content: 'Failed to delete webhook', type: 'error' }),
      onSuccess: () => showToast({ content: 'Webhook deleted', type: 'success' }),
      params: {
        webhookId: hookToDeleteId,
      },
    })
  }

  useDebounce(
    () => {
      if (searchString) {
        setSearchQueryValue(searchString)
      } else {
        setSearchQueryValue('')
      }
    },
    500,
    [searchString],
  )

  return {
    handleCreateHookOnClick,
    handleSubmit,
    handleSubmitDeleteHook,
    webhooks,
    hookToDeleteId,
    hookToEditId,
    isCreateHookModalOpen,
    isLoadingWebhooks,
    isPermittedToCreateHooks,
    isEntitledToCreateHooks,
    pagination,
    searchString,
    setHookToDeleteId,
    setHookToEditId,
    setIsCreateHookModalOpen,
    setSearchString,
  }
}
