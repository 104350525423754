import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import { isEmpty } from 'lodash'

import { ICanvasStep, IWorkflowConfig, IWorkflowItems } from 'pages/orchestration/workflows/edit/interfaces'
import { SidebarList } from 'pages/orchestration/workflows/edit/components/sidebar/components/SidebarList'
import { SidebarSearch } from 'pages/orchestration/workflows/edit/components/sidebar/components/SidebarSearch'
import { capitalizeFirstLetterOfString } from 'utils/text/stringUtils'
import { Button, Icon, Spinner } from '@ketch-com/deck'

const getWrapperStyles = (sidebarWidth: number) => ({
  top: 0,
  left: 0,
  bottom: 0,
  position: 'absolute',
  width: sidebarWidth,
  background: '#FFFFFF',
  zIndex: 10,
  padding: '18px 24px',
  boxSizing: 'border-box',
  overflowY: 'auto',
  overflowX: 'hidden',
})

type Props = {
  isExpanded: boolean
  sidebarWidth: number
  workflowItems: IWorkflowItems
  isActivitiesListLoading?: boolean
  handleToggleSidebar: () => void
  handleStartItemDrag: (step: ICanvasStep) => void
  handleStopItemDrag: () => void
}

export const CanvasSidebar: React.FC<Props> = props => {
  const {
    isExpanded,
    sidebarWidth,
    workflowItems,
    handleToggleSidebar,
    handleStartItemDrag,
    handleStopItemDrag,
    isActivitiesListLoading,
  } = props
  const { values } = useFormikContext<IWorkflowConfig>()
  const { activities, gateways, connectors, apps, appslvl2 } = workflowItems

  const [search, setSearch] = useState('')

  if (isActivitiesListLoading) {
    return (
      <Box
        sx={{
          ...getWrapperStyles(sidebarWidth),
        }}
      >
        <Box display="flex" alignItems="center" mt={4} justifyContent="center">
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box
        sx={{
          ...getWrapperStyles(sidebarWidth),
        }}
      >
        {isExpanded && <SidebarSearch search={search} setSearch={setSearch} />}

        {!isEmpty(gateways) && (
          <SidebarList
            isSidebarExpanded={isExpanded}
            name="Gateways"
            items={gateways}
            search={search}
            handleStartItemDrag={handleStartItemDrag}
            handleStopItemDrag={handleStopItemDrag}
          />
        )}

        {!isEmpty(activities) && (
          <SidebarList
            isSidebarExpanded={isExpanded}
            name="Activities"
            items={activities}
            search={search}
            handleStartItemDrag={handleStartItemDrag}
            handleStopItemDrag={handleStopItemDrag}
            infoHoverHint={`Activities that support the selected workflow type, ${capitalizeFirstLetterOfString(
              values?.canonicalRightCode || '',
            )}, are displayed below.`}
          />
        )}

        {(!isEmpty(connectors) || !isEmpty(apps) || !isEmpty(appslvl2)) && (
          <SidebarList
            isSidebarExpanded={isExpanded}
            name="Systems"
            items={[...connectors, ...apps, ...appslvl2]}
            search={search}
            handleStartItemDrag={handleStartItemDrag}
            handleStopItemDrag={handleStopItemDrag}
            infoHoverHint={`Systems that support the selected workflow type, ${capitalizeFirstLetterOfString(
              values?.canonicalRightCode || '',
            )}, are displayed below.`}
          />
        )}
      </Box>

      <Button
        onClick={() => {
          handleToggleSidebar()
          setSearch('')
        }}
        variant="iconCustomRounded"
        enableShadowEffect
        sx={{
          display: 'flex',
          position: 'absolute',
          top: 12,
          left: sidebarWidth + 12,
          zIndex: 10,
          background: 'white',
        }}
      >
        <Icon name={isExpanded ? 'OArrowCLeft' : 'OArrowCRight'} />
      </Button>
    </>
  )
}
