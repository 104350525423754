import { FC, useContext } from 'react'

import { PREVIEW_DEVICE_MODE_OPTIONS } from 'interfaces/preview/previewDeviceMode'
import { Dropdown } from 'components/ui-kit/dropdown/Dropdown'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ConsentExperienceTab } from 'pages/consentAndRights/experiences/upsert/utils/consent/constants'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { ExperiencePreviewContext } from '../context/ExperiencePreviewContext'

type Props = {}

export const PreviewLayoutToolbar: FC<Props> = () => {
  const {
    activeLanguage,
    activePolicy,
    activeTab,
    activeTheme,
    activeThemeCode,
    isConsentExperience,
    isReady,
    languageItems,
    mode,
    policyScopesItems,
    setActiveTab,
    setMode,
    themesItems,
  } = useContext(ExperiencePreviewContext)

  if (!isReady) return <Spinner />

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Box>
            <Typography variant="label">Theme</Typography>
          </Box>
          <Dropdown
            popoverHasMinWidth
            size="small"
            value={activeThemeCode}
            items={themesItems}
            disabled={themesItems.length < 2}
            minWidthOverride={300}
          >
            {activeTheme?.name}
          </Dropdown>
        </Box>

        <Box ml={2}>
          <Box>
            <Typography variant="label">Languages</Typography>
          </Box>
          <Dropdown
            size="small"
            value={activeLanguage?.language?.code}
            items={languageItems}
            disabled={languageItems.length < 2}
            minWidthOverride={300}
          >
            {activeLanguage?.language?.englishName}
          </Dropdown>
        </Box>

        <Box ml={2}>
          <Box>
            <Typography variant="label">Jurisdiction</Typography>
          </Box>
          <Dropdown
            size="small"
            value={activePolicy?.code}
            items={policyScopesItems}
            disabled={policyScopesItems.length < 2}
            minWidthOverride={300}
          >
            {activePolicy?.name}
          </Dropdown>
        </Box>
      </Box>

      <Box>
        {isConsentExperience && (
          <SegmentTabs
            value={activeTab}
            tabs={[
              {
                id: ConsentExperienceTab.BANNER,
                name: ConsentExperienceTab.BANNER,
              },
              {
                id: ConsentExperienceTab.MODAL,
                name: ConsentExperienceTab.MODAL,
              },
            ]}
            onChange={tab => {
              setActiveTab(tab)
            }}
          />
        )}

        <SegmentTabs tabs={PREVIEW_DEVICE_MODE_OPTIONS} value={mode} onChange={setMode} />
      </Box>
    </Box>
  )
}
