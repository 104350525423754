import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchSubscriptionTopics } from 'api/subscriptions/fetchers/fetchSubscriptionTopics'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.subscriptionTopicsPaginated,
  queryFn: fetchSubscriptionTopics,
  select: res => res?.data || null,
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useSubscriptionTopicsPaginated = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
