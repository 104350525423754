import Box from '@mui/material/Box'
import { CSSProperties, ReactNode } from 'react'

import { ReactComponent as Head } from './head.svg'
import { ReactComponent as Tail } from './tail.svg'
import { styled } from '@mui/styles'

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  webkitUserSelect: 'none',
  mozUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
}))

const HeadContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
}))

const Body = styled(Box)(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: palette.lighterGrey.main,
  borderRadius: '7px',
  overflow: 'hidden',
}))

const TailContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
}))

export enum BadgeDovetailVariant {
  HEAD = 'head',
  TAIL = 'tail',
}

export interface Props {
  variant?: BadgeDovetailVariant | undefined
  children: ReactNode
  onClick?: () => void
  style?: CSSProperties
  className?: string
}

export const BadgeDovetail: React.FC<Props> = ({ variant, children, onClick, style, className }) => {
  return (
    <Root style={style} className={className} onClick={onClick}>
      <HeadContainer>{variant === 'tail' && <Head />}</HeadContainer>
      <Body
        style={{
          borderRadius: variant === 'head' ? '7px 0 0 7px' : variant === 'tail' ? '0 7px 7px 0' : '7px',
          padding: variant === 'head' ? '0px 5px 0px 10px' : variant === 'tail' ? '0px 10px 0px 5px' : '5px 10px',
        }}
      >
        {children}
      </Body>
      <TailContainer>{variant === 'head' && <Tail />}</TailContainer>
    </Root>
  )
}
