import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchOrchestrationEvents } from 'api/orchestrationEventsV2/fetchers/fetchOrchestrationEvents'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.eventsPaginated,
  queryFn: fetchOrchestrationEvents,
  select: res => res?.data?.events || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useOrchestrationEventsPaginated = (config?: QueryConfig) =>
  useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
