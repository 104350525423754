import {
  SaveOrganizationDataSystemGlobalCustomAttributesRequestBodyDTO,
  SaveOrganizationDataSystemGlobalCustomAttributesResponseBodyDTO,
} from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const saveDataSystemGlobalCustomAttribute = (
  reqBody: SaveOrganizationDataSystemGlobalCustomAttributesRequestBodyDTO,
) => {
  return API.post<SaveOrganizationDataSystemGlobalCustomAttributesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/data-systems/global-custom-attributes`,
    }),
    reqBody,
  )
}
