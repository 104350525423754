import { CreateDmlHookRequestBodyDTO } from '@ketch-com/figurehead'
import { CreateHookFormValues } from './getInitialValues'

export const mapFormValuesToFormData = (values: CreateHookFormValues): CreateDmlHookRequestBodyDTO => {
  return {
    hook: {
      name: values.name,
      type: Number(values.type),
      technology: values.technology,
      operation: Number(values.operation),
      dmlCode: values.code,
    },
  }
}
