import { RightInvocationDTO } from '@ketch-com/figurehead'
import { WorkflowActivityCategory, WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import {
  SupportedDetailViewWorkflowStepCodes,
  BasicSupportedDetailViewWorkflowStepCodes,
  SupportedDetailViewWorkflowStepNames,
} from '../stepDetails/interfaces'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { ExecutionResultAssetDTO } from '@ketch-com/figurehead'
import { WorkflowDTO } from 'interfaces/workflows'

type GetIsWorkflowStepDetailSupportedParams = {
  stepName: SupportedDetailViewWorkflowStepNames
  stepCode: SupportedDetailViewWorkflowStepCodes
  rightInvocation?: RightInvocationDTO
  stepIndex: number
  isAppeal?: boolean
  dsrExecutionsV2?: ExecutionResultAssetDTO[]
  workflowDefinition?: WorkflowDTO | null
}

export function getIsWorkflowStepDetailSupported({
  stepName,
  stepCode,
  rightInvocation,
  stepIndex,
  isAppeal = false,
  dsrExecutionsV2,
  workflowDefinition,
}: GetIsWorkflowStepDetailSupportedParams): boolean {
  // Fetch the current workflow execution step
  const currentActivityWorkflowExecutionStep = isAppeal
    ? rightInvocation?.appealWorkflowExecution?.steps?.[stepIndex]
    : rightInvocation?.workflowExecution?.steps?.[stepIndex]

  // Determine the category of the workflow step
  const category = currentActivityWorkflowExecutionStep?.activityCategory

  // Determine if there's a manual intervention status for the workflow step
  const stepManualInterventionStatus = !!currentActivityWorkflowExecutionStep?.manualInterventionStatus

  // Check if the release supports manual intervention
  const isManualInterventionReleased = isRelease(Release.RightsQueueManualIntervention)

  // Define conditions for specific workflow step types
  const isIdvLike = SupportedDetailViewWorkflowStepCodes.Idv === stepCode && stepManualInterventionStatus
  const isSetPermitLike = SupportedDetailViewWorkflowStepCodes.SetPermit === stepCode
  const isEmailLike = [
    SupportedDetailViewWorkflowStepCodes.Email,
    SupportedDetailViewWorkflowStepCodes.DataSubjectEmail,
  ].includes(stepCode)

  // Define condition for app-like categories
  const isAppLike = [
    WorkflowActivityCategory.APP,
    WorkflowActivityCategory.APP_LEVEL_2,
    WorkflowActivityCategory.CONNECTOR,
  ].includes(category! as WorkflowActivityCategory)

  // Get array of all supported codes
  const codeValuesArray = Object.values(
    isManualInterventionReleased ? SupportedDetailViewWorkflowStepCodes : BasicSupportedDetailViewWorkflowStepCodes,
  )

  // Define supported conditions
  let isAppWithManualInterventionStatus = isAppLike
  let isStepSupportedByCode = codeValuesArray.includes(stepCode)
  const isEndTile = SupportedDetailViewWorkflowStepCodes.End === stepCode

  // If manual intervention is supported, modify the conditions
  if (isManualInterventionReleased) {
    isAppWithManualInterventionStatus =
      (isAppLike || isEmailLike || isSetPermitLike || isIdvLike) && stepManualInterventionStatus
    isStepSupportedByCode = codeValuesArray.includes(stepCode) && stepManualInterventionStatus
  }

  // Check if the step name is supported
  const isStepSupportedByName = Object.values(SupportedDetailViewWorkflowStepNames).includes(stepName)

  // internalSystemDSR supports detail view if dsrExecutionsV2 has responded with data
  const isInternalSystemDsrDetailView =
    stepCode === SupportedDetailViewWorkflowStepCodes.InternalSystemDsr && Boolean(dsrExecutionsV2?.length)

  const workflowDefinitionStep = workflowDefinition?.steps.find(
    step => step.ID === currentActivityWorkflowExecutionStep?.stepID,
  )
  const isDecision =
    workflowDefinitionStep?.gateway?.mode === WorkflowActivityGatewayMode.DECISION_MULTI ||
    workflowDefinitionStep?.gateway?.mode === WorkflowActivityGatewayMode.DECISION_SINGLE

  return (
    isAppWithManualInterventionStatus ||
    isStepSupportedByName ||
    isStepSupportedByCode ||
    isEndTile ||
    isInternalSystemDsrDetailView ||
    isDecision
  )
}
