import React, { useState } from 'react'
import Box from '@mui/material/Box'
import pluralize from 'pluralize'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { AssetManagerWidgetLayout } from 'pages/assetManager/components'
import { AssetSummaryDTO, PurposeDTO } from '@ketch-com/figurehead'
import { createStyles, makeStyles } from '@mui/styles'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { mapValuesToPayload } from './components/PurposesWidgetEditModal/utils'
import { MaybeNull } from 'interfaces'
import { PurposeOverviewModal, PurposeChip } from './components'
import { PurposesWidgetEditModal } from './components/PurposesWidgetEditModal'
import { ReactComponent as PurposesEmptyStateIcon } from 'assets/icons/purposes-widget-empty-state-icon.svg'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { useUpdateAssetV2 } from 'api/assets/mutations/useUpdateAssetV2'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      searchInput: {
        border: 'none',
        borderBottom: `1px solid ${palette.iron.main}`,
        borderRadius: 0,
      },
      emptyState: {
        backgroundColor: palette.whiteFaded.main,
        padding: spacing(4, 0),
        minHeight: 0,
      },
    }),
  { name: 'PurposesWidget' },
)

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
  purposes: PurposeDTO[]
}

export const PurposesWidget: React.FC<Props> = ({ assetSummary, purposes }) => {
  const classes = useStyles()
  const [targetPurposeCode, setTargetPurposeCode] = useState<string>('')
  const [isEditMode, setIsEditMode] = useState(false)
  const purposeCount = purposes?.length || 0
  const assetId = assetSummary?.asset?.resource?.id || ''
  const resourceTypeCode = assetSummary?.asset?.resource?.resourceType?.code || ''
  const queryClient = useQueryClient()

  const { mutate: handleUpdateAsset, isLoading: isUpdatingAsset } = useUpdateAssetV2({
    onError: () => {
      showToast({ content: 'Failed to update purposes', type: 'error' })
    },
    onSuccess: async () => {
      await queryClient.refetchQueries([
        ApiQueryKeys.resource,
        {
          assetCode: assetSummary?.asset?.resource?.id,
        },
      ])
      showToast({ content: 'Purposes updated', type: 'success' })
      setIsEditMode(false)
    },
  })

  return (
    <AssetManagerWidgetLayout
      isEditDisabled={false}
      title="Purposes"
      isEditModalOpen={isEditMode}
      subTitle={`${purposeCount} ${pluralize('purpose', purposeCount)} assigned.`}
      onEdit={() => setIsEditMode(true)}
      editModal={
        <PurposesWidgetEditModal
          assetSummary={assetSummary}
          isUpdatingAssetLabels={isUpdatingAsset}
          onSubmit={purposes => {
            const formData = mapValuesToPayload({
              assetSummary,
              purposes,
            })
            handleUpdateAsset({
              params: {
                assetCode: assetId || '',
                resourceTypeCode,
                formData,
              },
            })
          }}
          onCancel={() => {
            setIsEditMode(false)
          }}
        />
      }
    >
      {purposeCount > 0 ? (
        <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
          {purposes.map(p => {
            return (
              <PurposeChip key={p?.code} purpose={p?.name || ''} onDelete={() => setTargetPurposeCode(p?.code || '')} />
            )
          })}
        </Box>
      ) : (
        <EmptyState
          className={classes.emptyState}
          title="No purposes assigned"
          description="This asset is not associated with any purposes."
          titleTextVariant="faded"
          descriptionTextVariant="faded"
          variant="page"
          icon={<PurposesEmptyStateIcon />}
        />
      )}

      {targetPurposeCode ? (
        <PurposeOverviewModal purposeCode={targetPurposeCode as string} onCancel={() => setTargetPurposeCode('')} />
      ) : null}
    </AssetManagerWidgetLayout>
  )
}
