import { APIListRequestParams } from 'api/common/utils'
import { ListTableAssetSummariesForDatabaseResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  assetId?: string
  identityRelationType?: number
  isPersonal?: boolean
  scenarioId?: string
}>

export const fetchDatabaseDatasetSummariesPaginatedV3 = ({
  assetId,
  limit,
  nextToken,
  identityRelationType,
  isPersonal,
  query,
  scenarioId,
}: Params) => {
  return API.get<ListTableAssetSummariesForDatabaseResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v3/assets/${assetId}/list-tables`,
      params: {
        filters: {
          limit,
          ...(query && {
            query,
          }),
          ...(identityRelationType && {
            identityRelationType,
          }),
          ...(isPersonal && {
            isPersonal,
          }),
          ...(scenarioId && {
            scenarioId,
          }),
          ...(nextToken && {
            nextToken,
          }),
        },
      },
    }),
  )
}
