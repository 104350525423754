import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { AssignedCategoryV3DTO } from '@ketch-com/figurehead'
import { fetchAssignedDataCategories } from '../fetchers/fetchAssignedDataCategories'

export const useAssignedDataCategoriesInfinite = ({
  installedDataSystemId,
  limit = 50,
}: {
  installedDataSystemId?: string
  start?: number
  limit?: number
}) => {
  const resp = useInfiniteQuery({
    queryKey: [ApiQueryKeys.assignedDataCategories, { installedDataSystemId }],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchAssignedCategoriesResult = await fetchAssignedDataCategories({
        installedDataSystemId,
        start: (pageParam - 1) * limit,
        limit,
      })

      return {
        assignedCategories: fetchAssignedCategoriesResult?.data?.assignedCategories ?? [],
        totalResults: fetchAssignedCategoriesResult?.data?.totalResults ?? 0,
      }
    },
    getNextPageParam: ({ totalResults }, allPages) => {
      let totalItemsFetched = 0
      allPages?.forEach?.(pageOfResults => {
        totalItemsFetched += pageOfResults?.assignedCategories?.length || 0
      })
      const nextPageParam = Math.ceil(totalItemsFetched / limit) + 1

      return totalItemsFetched === totalResults ? undefined : nextPageParam
    },
  })

  let dataCategoriesInfinite: AssignedCategoryV3DTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    const newDataCategories = page?.assignedCategories
    dataCategoriesInfinite = [...dataCategoriesInfinite, ...newDataCategories]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: {
      assignedCategories: dataCategoriesInfinite,
      totalResults,
    },
  }
}
