import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  workflowExecutionId: string
  stepId: string
}

type Response = { step: WorkflowExecutionStepDetailsDTO }

export const fetchWorkflowExecutionStepDetails = ({ organizationCode, workflowExecutionId, stepId }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/executions/${workflowExecutionId}/steps/${stepId}`,
    }),
  )
