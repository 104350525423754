import { useConnections } from 'api/assets/queries/useConnections'
import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO, ResourceTypeDTO } from '@ketch-com/figurehead'

type Args = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export const useAssetParents = ({ assetSummary }: Args) => {
  const { data: connections } = useConnections()
  const resourceTypeCode = assetSummary?.asset?.resource?.resourceType?.code
  const assetConnectionResourceTypes = connections?.find(
    c => c?.connectionID === assetSummary?.asset?.connection?.connectionID,
  )?.resourceTypes

  let targetResourceTypeCode = resourceTypeCode

  const findParent = (resourceType: ResourceTypeDTO) =>
    (resourceType?.contains || [])?.indexOf?.(targetResourceTypeCode || '') > -1

  const payload = []
  while (targetResourceTypeCode) {
    const parent = assetConnectionResourceTypes?.find(findParent)

    if (assetSummary?.asset?.resource?.hierarchies?.[targetResourceTypeCode]?.name || '') {
      payload.push({
        name: assetSummary?.asset?.resource?.hierarchies?.[targetResourceTypeCode]?.name || '',
        resourceTypeCode: targetResourceTypeCode,
        canonicalResourceType:
          assetSummary?.asset?.resource?.hierarchies?.[targetResourceTypeCode]?.resourceType?.canonicalResourceType ||
          0,
      })
    }
    targetResourceTypeCode = parent?.code
  }

  return payload.reverse()
}
