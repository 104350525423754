import { EndStepFormValues } from './getValidationSchema'

export const getInitialValues = (): EndStepFormValues => {
  return {
    finalizeRequestAs: '',
    coverLetter: [],
    subjectCommunicationMessage: '',
    reasonForFinalization: '',
  }
}
