import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

import { ApiQueryKeys } from 'api/common/queryKeys'
/**
 * This hook is used to refetch the unread notifications count when the user navigates to a new page.
 */
export const useRefetchUnreadNotificationsCountOnNavigation = () => {
  const location = useLocation()
  const queryClient = useQueryClient()

  useEffect(() => {
    const refetch = async () => {
      queryClient.refetchQueries([ApiQueryKeys.notificationsUnreadCount])
    }
    refetch()
  }, [location, queryClient])
}
