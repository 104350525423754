import { APIListRequestParams } from 'api/common/utils'
import { GetPropertiesResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{}>

export const fetchProperties = ({ limit, start }: Params) =>
  API.get<GetPropertiesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/properties`,
      params: {
        filters: {
          start,
          limit,
        },
      },
    }),
  )
