import { styled } from '@mui/system'

import { ReactComponent as TrashIcon } from 'assets/icons/trash-can-black.svg'

export const DeleteIcon = styled(TrashIcon)(({ theme }) => ({
  cursor: 'pointer',
  height: 16,
  width: 16,
  path: {
    fill: theme.palette.fadedDarkGrey.main,
  },
}))
