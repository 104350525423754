import React, { useContext, useEffect, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useUpdateWorkflowExecutionStep } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStep'
import { showToast } from 'components/modals/AlertComponent'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
import { ContextVariableCategoryDTO, ContextVariableDTO } from '@ketch-com/figurehead'
import { OutcomeVariablesInitialValues } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/Task/components/TaskDetails/components/OutcomeVariables/utils'
import { ContextVariableType } from 'pages/settings/contextVariables/constants'
import { DataSubjectVariablesInitialValues } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/Task/components/TaskDetails/components/DataSubjectVariables/utils'

type Args = {
  name: string
  inputId?: string
  canEdit: boolean
  contextVariable?: ContextVariableDTO
}

export const useInlineCtxVariableUtils = ({ name, inputId, canEdit = true, contextVariable }: Args) => {
  // context
  const { stepId, workflowExecutionId, refetchWorkflowStep } = useContext(RightInvocationWorkflowStepDetailViewContext)
  const { isSubmitting, setSubmitting, initialValues } = useFormikContext<
    DataSubjectVariablesInitialValues | OutcomeVariablesInitialValues
  >()

  const [, { error, value }, { setValue }] = useField<string>({ name })
  const [isEditing, setIsEditing] = useState(false)
  const queryClient = useQueryClient()

  // api
  const { mutateAsync: submitStepDetails } = useUpdateWorkflowExecutionStep({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.workflowExecutionStepDetailsV2, { workflowExecutionId, stepId }])
      await refetchWorkflowStep()
      showToast({ content: 'Successfully updated Task', type: 'success' })
      setIsEditing(false)
      setSubmitting(false)
    },
    onError: () => {
      showToast({ content: 'Failed to update Task', type: 'error' })
    },
  })

  // event handlers
  const handleSubmit = async () => {
    if (!!error || !value || !contextVariable?.code) return
    setSubmitting(true)

    const payloadKey =
      contextVariable?.category === ContextVariableCategoryDTO.DataSubjectContextVariableCategory
        ? 'dataSubjectContext'
        : 'outcomeContext'

    function parseVariableValue(stringValue: string): any {
      switch (contextVariable?.type) {
        case ContextVariableType.String:
          return stringValue
        case ContextVariableType.Boolean:
          return stringValue === 'true'
        case ContextVariableType.Number:
          return parseFloat(stringValue)
        default:
          return stringValue
      }
    }

    await submitStepDetails({
      params: {
        formData: {
          [payloadKey]: {
            [contextVariable.code]: parseVariableValue(value),
          },
        },
        workflowExecutionId,
        stepId: stepId!,
      },
    })
  }

  const handleEditMode = () => {
    if (!canEdit) return
    setIsEditing(!isEditing)
  }

  // TODO:BAC - auto save on blur is a desired feature from product but not currently implemented
  const handleOnBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    return
    // TODO: - implement
  }

  // add edit mode listener for abstracted edit button
  useEffect(() => {
    if (!canEdit) {
      setIsEditing(false)
    }
  }, [canEdit])

  // focus input when entering edit mode
  useEffect(() => {
    if (isEditing && inputId) {
      const input = document.getElementById(inputId)
      if (input) input.focus()
    }
  }, [isEditing, inputId])

  return {
    isEditing,
    isSubmitting,
    handleEditMode,
    handleSubmit,
    handleOnBlur,
    initialValues,
    value,
    error,
    setValue,
    setIsEditing,
  }
}
