import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchDataTypes } from '../fetchers/fetchDataTypes'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.fetchDataTypes,
  queryFn: fetchDataTypes,
  select: res => res?.data || null,
})

type QueryConfig = CustomQueryConfig<typeof useCustomQuery>

export const useFetchDataTypes = (config: QueryConfig) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params,
    },
  })
}
