import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { GetAssetHierarchiesResponseBodyDTO, AssetSummaryDTO } from '@ketch-com/figurehead'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { getAssetType } from 'pages/assetManager/utils'
import { RelatedPath } from 'pages/assetManager/dsrConfig/interfaces'
import { getRelatedDataset } from 'pages/assetManager/dsrConfig/utils'
import { ReactComponent as PersonAvatarSmall } from 'assets/icons/person-avatar-small.svg'
import { Chip } from 'components/ui-kit/chip/Chip'
import { theme } from '@ketch-com/deck'

type Props = {
  assetsHierarchies: GetAssetHierarchiesResponseBodyDTO[]
  identitySpaces: IdentitySpaceDTO[]
  relatedPaths: RelatedPath[]
}

export const RelatedDatasetIdentity: React.FC<Props> = ({ assetsHierarchies, identitySpaces, relatedPaths }) => {
  const { datasetId: relatedDataSetId } = getRelatedDataset(relatedPaths[0])

  const assetHierarchies = useMemo(
    () =>
      assetsHierarchies.filter(
        assetHierarchies =>
          assetHierarchies.hierarchySummaries?.some(
            ({ asset }) => asset?.resource?.hierarchies?.$parent?.id === relatedDataSetId,
          ),
      )?.[0]?.hierarchySummaries || [],
    [assetsHierarchies, relatedDataSetId],
  )

  const assetAttributesSummary: AssetSummaryDTO[] = useMemo(
    () => assetHierarchies?.filter(hierarchySummary => hierarchySummary.asset?.identitySpace?.code) || [],
    [assetHierarchies],
  )

  assetAttributesSummary?.forEach?.(attribute => {
    const matchedIdentitySpace = identitySpaces?.find(
      identitySpace => attribute.asset?.identitySpace?.code === identitySpace.code,
    )

    if (matchedIdentitySpace) {
      const { asset = {} } = attribute
      const { identitySpace: assetIdentitySpace = {} } = asset

      assetIdentitySpace.name = matchedIdentitySpace.name
    }
  })

  const providerCode = assetAttributesSummary?.[0]?.asset?.connection?.providerCode || ''
  const attributeType = getAssetType(providerCode, 3).toLowerCase()
  return (
    <Box>
      {assetAttributesSummary.length ? (
        assetAttributesSummary.map((attributeSummary, index) => {
          const assetId = attributeSummary.asset?.resource?.id
          const assetName = attributeSummary.asset?.resource?.name || ''
          const identitySpaceName = attributeSummary.asset?.identitySpace?.name
          const technology = attributeSummary.asset?.connection?.technology || ''
          return (
            <Box key={assetId} display="flex" gap={3} alignItems="center" my={2}>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Box display="flex" alignItems="center" gap={0.5}>
                  <img src={getAssetCloudImgUrl(technology)} alt={technology} width={15} height={15} />
                  <Typography fontSize={11} fontWeight={500} sx={{ textTransform: 'capitalize' }}>
                    {attributeType}
                  </Typography>
                </Box>
                <Typography fontSize={14} fontWeight={600}>
                  {assetName}
                </Typography>
              </Box>
              <Typography fontSize={16} fontWeight={700} color={theme.palette.darkDuskFaded.main}>
                =
              </Typography>

              <Chip size="small">
                <Box display="flex" alignItems="center" gap={1}>
                  <PersonAvatarSmall />
                  <Box>
                    <strong>Identity:</strong> {identitySpaceName}
                  </Box>
                </Box>
              </Chip>
            </Box>
          )
        })
      ) : (
        <Typography variant="body">None</Typography>
      )}
    </Box>
  )
}
