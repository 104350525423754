import { TextInput } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { FormDataInputValue } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'

type Props = {
  formValue: FormDataInputValue
  setFormValue: (v: FormDataInputValue) => void
}
export const TextValueInput: React.FC<Props> = ({ formValue, setFormValue }) => {
  return (
    <Box display="flex" flexDirection="column">
      <TextInput
        label="Enter Value"
        fullWidth
        value={(formValue as string) || ''}
        onChange={e => setFormValue(e.target.value)}
        size="small"
      />
    </Box>
  )
}
