import { FormMode } from 'interfaces/formModes/formMode'

export enum DsrConfigureFormStep {
  USER_LOOKUP = 'User Lookup',
  RELATED_TABLES = 'Related Tables',
  CONFIGURATION = 'Configuration',
}

export type DsrConfigFormData = {
  identitySpace: {
    // attributeId
    [key: string]: {
      checked: boolean
      attributeId: string
      attributeName: string
      identitySpaceName: string
      identitySpaceCode: string
    }
  }
  relatedDatasets: {
    // datasetId
    [key: string]: {
      included: boolean
      // hashId
      [key: string]: boolean
    }
  }
  actions: {
    // datasetId
    [key: string]: {
      // attributeId
      [key: string]: {
        command: number
        updateValue: string
        // Used only for edit
        dsrConfigId?: string
      }
    }
  }
}

export type RelatedAssets = {
  key: string
  datasetName: string
  datasetId: string
  attributeName: string
  attributeId: string
  relationshipId: string
  technology: string
  hashID: string
  providerCode: string
}

export type RelatedPath = {
  hashID: string
  path: {
    relationships: RelatedAssets[][]
  }[]
}

export type RelatedPathsAndAssets = {
  // key is datasetId
  [key: string]: RelatedPath[]
}

export type UrlParams = {
  id: string
  scenarioId: string
  code: string
  formMode: FormMode
  resourceTypeCode: string
}
