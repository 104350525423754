import { AssetSummaryDTO, ConnectionDTO, ResourceTypeDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { AssetTypeEnum } from 'interfaces/assets'
import { isEmpty } from 'lodash'
import { TabsRoutesConfig } from 'components/ui-kit/tabs/TabsRoutes'
import pluralize from 'pluralize'
import { ASSETS_PROVIDER_CODE } from 'pages/assetManager/constants'

const getTabPathname = (assetSummary: AssetSummaryDTO, rowKey: string): string | undefined => {
  switch (rowKey) {
    case 'Overview':
      return `${RoutesManager.assetManager.assets.detail.overview.getURL({
        code: assetSummary?.asset?.resource?.id,
        resourceTypeCode: assetSummary?.asset?.resource?.resourceType?.code || '',
      })}`
    case 'dsrConfiguration':
      return `${RoutesManager.assetManager.assets.detail.dsrConfiguration.getURL({
        code: assetSummary?.asset?.resource?.id,
        resourceTypeCode: assetSummary?.asset?.resource?.resourceType?.code || '',
      })}`
    default:
      return `${RoutesManager.assetManager.assets.detail.tab.getURL({
        code: assetSummary?.asset?.resource?.id,
        resourceTypeCode: assetSummary?.asset?.resource?.resourceType?.code || '',
        tab: rowKey,
      })}`
  }
}

const getTabCount = (hierarchyCounts: HierarchyCount[], resourceTypeCode: string) => {
  return hierarchyCounts.find(h => h?.resourceTypeCode === resourceTypeCode)?.count || 0
}

type HierarchyCount = {
  tileTitle?: string
  count?: number
  canonicalResourceType?: AssetTypeEnum
  resourceTypeCode?: string
}

const getAllResourceTypes = (connections: ConnectionDTO[]) => {
  const allConnections = connections.reduce((acc, connection) => {
    return [...acc, ...(connection?.resourceTypes || [])]
  }, [] as ResourceTypeDTO[])
  // de-duplication in filter function
  return allConnections.filter((value, index) => {
    const _value = JSON.stringify(value)
    return (
      index ===
      allConnections.findIndex(obj => {
        return JSON.stringify(obj) === _value
      })
    )
  })
}

const getHierarchyCounts = ({ assetSummary, connections }: UseAssetDetailTabsArgs): HierarchyCount[] =>
  Object.entries(assetSummary?.hierarchyCounts || {}).map(([key, value]) => {
    const allResourceTypes = getAllResourceTypes(connections)
    const resourceType = allResourceTypes?.find(rt => rt.code === key) || {}
    const canonicalResourceType = resourceType?.canonicalResourceType || 0
    const humanReadableKey = connections?.[0]?.resourceTypes?.find(rt => rt.code === key)?.name || key
    return { tileTitle: humanReadableKey, count: value, canonicalResourceType, resourceTypeCode: resourceType?.code }
  })

type UseAssetDetailTabsArgs = {
  assetSummary?: AssetSummaryDTO
  connections: ConnectionDTO[]
}

const getTabs = (g: Map<string, ResourceTypeDTO>, code: string) => {
  const queue: string[] = []
  const out: ResourceTypeDTO[] = []

  const r = g.get(code)
  if (r) {
    if (r.contains) {
      queue.push(...r.contains)
    }
  }

  while (queue.length > 0) {
    const c = queue.shift()
    if (c) {
      const r = g.get(c)
      if (r) {
        out.push(r)
        if (r.contains) {
          queue.push(...r.contains)
        }
      }
    }
  }

  return out
}

export const useAssetDetailTabs = ({ assetSummary, connections }: UseAssetDetailTabsArgs) => {
  const hierarchyCounts: HierarchyCount[] = getHierarchyCounts({ assetSummary, connections })
  const assetProviderCode = assetSummary?.asset?.connection?.providerCode || ''
  const resourceTypes = assetSummary?.asset?.connection?.resourceTypes || []
  const resourceTypeContains = new Map(resourceTypes.map(resourceType => [resourceType.code || '', resourceType]))
  const tabs = getTabs(resourceTypeContains, assetSummary?.asset?.resource?.resourceType?.code || '')

  const overviewTabs: TabsRoutesConfig[] = []
  if (!isEmpty(tabs)) {
    overviewTabs.push({
      title: 'Overview',
      count: 0,
      resourceTypeCode: '',
      shouldShowCount: false,
      link: {
        pathname: getTabPathname(assetSummary as AssetSummaryDTO, 'Overview'),
      },
    })

    if (
      !assetSummary?.asset?.resource?.resourceType?.code?.includes('.schema') &&
      assetProviderCode !== ASSETS_PROVIDER_CODE.MONGO_DB &&
      assetProviderCode !== ASSETS_PROVIDER_CODE.DOCUMENT_DB
    ) {
      const attributeTab = tabs.filter(tab => tab?.code?.indexOf('column') !== -1)
      const attributeCode = attributeTab.length && attributeTab?.[0]?.code
      if (!assetSummary?.asset?.metadata?.deletedAt)
        overviewTabs.push({
          title: 'DSR Configuration',
          disabled: getTabCount(hierarchyCounts, attributeCode || '') === 0,
          shouldShowCount: false,
          link: {
            pathname: getTabPathname(assetSummary as AssetSummaryDTO, 'dsrConfiguration'),
          },
        })
    }

    tabs?.forEach?.(tab => {
      const titlePayload = tab?.name?.split?.(' ')?.[1] || tab?.name || ''

      overviewTabs.push({
        // ternary required because the pluralize library converts 'schema' to 'schemata' instead of the desired 'schemas'.
        title: titlePayload.toLowerCase() === 'schema' ? 'Schemas' : pluralize(titlePayload, 12),
        count: getTabCount(hierarchyCounts, tab?.code || ''),
        disabled: getTabCount(hierarchyCounts, tab?.code || '') === 0,
        shouldShowCount: true,
        resourceTypeCode: tab?.code,
        link: {
          pathname: getTabPathname(assetSummary as AssetSummaryDTO, tab?.code || ''),
        },
      })
    })
  }

  return overviewTabs
}
