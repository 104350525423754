import { Capabilities } from './Capabilities'

export const CapabilitiesNames = {
  [Capabilities.PERMIT_PROPAGATION_APP_CAPABILITY]: 'Consent Orchestration',
  [Capabilities.RIGHTS_PROPAGATION_APP_CAPABILITY]: 'Rights Orchestration',
  [Capabilities.TAG_ORCHESTRATION_APP_CAPABILITY]: 'Tag Orchestration',
  [Capabilities.INTROSPECTION_APP_CAPABILITY]: 'Data Discovery',
  [Capabilities.SUBSCRIPTION_APP_CAPABILITY]: 'Preference Orchestration',
}

export const ShortCapabilitiesNames = {
  [Capabilities.PERMIT_PROPAGATION_APP_CAPABILITY]: 'Consent',
  [Capabilities.RIGHTS_PROPAGATION_APP_CAPABILITY]: 'Rights',
  [Capabilities.TAG_ORCHESTRATION_APP_CAPABILITY]: 'Tag',
  [Capabilities.INTROSPECTION_APP_CAPABILITY]: 'Data Discovery',
  [Capabilities.SUBSCRIPTION_APP_CAPABILITY]: 'Preference',
}
