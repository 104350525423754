import { FC } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Chip } from '@ketch-com/deck'
import { Theme } from '@mui/material/styles'
import { styled } from '@mui/system'
import DeleteIcon from '@mui/icons-material/Delete'

import { FormTemplatesDropdown, RemoveRightButton } from '.'
import { ExperienceCanonicalRightFormTemplate } from 'interfaces/experiences/experience'
import { TooltipV2 } from 'components/ui-kit/tooltip/TooltipV2'
import { fieldNames } from 'pages/consentAndRights/experiences/upsert/utils/common'

type Props = {
  index: number
  onClick: (selectedTemplate: ExperienceCanonicalRightFormTemplate) => void
  remove?: <T>(index: number) => T | undefined
  template: ExperienceCanonicalRightFormTemplate
}

export const StyledUl = styled('ul')(({ theme }: { theme?: Theme }) => ({
  paddingLeft: 20,
  paddingRight: 10,
  marginTop: 10,
  marginBottom: 6,
}))

export const FormTemplate: FC<Props> = ({ index, onClick, remove, template }) => {
  const errorMessageName = `${fieldNames.preference.rights.canonicalRightFormTemplates.root}[${index}].formTemplateID`

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        backgroundColor: 'superLightGrey.main',
        borderRadius: 2.75,
        p: 1.5,
        mb: 2,
      }}
    >
      <Box mb={2}>
        <Box alignItems="center" display="flex" gap={1} flex={1} justifyContent="space-between" width="100%">
          <Typography variant="h4" color="darkDuskFaded.main">
            {template?.name}
          </Typography>

          {/* rights count button for canonical rights only */}
          {template?.rights && (
            <Chip
              clickable
              selectable
              label={
                <TooltipV2
                  placement="bottom-start"
                  arrow
                  title={
                    <StyledUl>
                      {template?.rights?.map(right => (
                        <Box component="li" key={right?.code}>
                          <Typography variant="smallLabel">{right?.name}</Typography>
                        </Box>
                      ))}
                    </StyledUl>
                  }
                >
                  <Typography variant="body">
                    {' '}
                    <b>{template?.rights?.length || 0}</b> Rights
                  </Typography>
                </TooltipV2>
              }
            />
          )}

          {/* remove button for custom rights only */}
          {remove && (
            <RemoveRightButton
              onClick={() => {
                remove(index!)
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </RemoveRightButton>
          )}
        </Box>

        <Box mt={2}>
          <FormTemplatesDropdown
            onClick={onClick}
            templateId={template?.formTemplateID || ''}
            errorMessageName={errorMessageName}
          />
        </Box>
      </Box>
    </Box>
  )
}
