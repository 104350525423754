import { DataGrid, InfoRow, TableCell, theme } from '@ketch-com/deck'
import { Typography } from '@mui/material'
import React, { useContext } from 'react'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import {
  getOrgPlanName,
  getOrgPlanCost,
  getOrgPlanUniques,
} from 'components/planAndBilling/common/utils/planAndBilling'

type GridItem = {
  planName: string
  planCost: number
  billingStartDate: number
  billingEndDate: number
  planUniques: string
}

export const BillingDetails: React.FC = () => {
  const { orgPlan } = useContext(PlanAndBillingContext)

  const rows: GridItem[] = [
    {
      planName: getOrgPlanName(orgPlan?.planProductID),
      planCost: getOrgPlanCost(orgPlan?.planProductID),
      billingStartDate: orgPlan?.billingPaymentPeriod?.start || 0,
      billingEndDate: orgPlan?.billingPaymentPeriod?.end || 0,
      planUniques: getOrgPlanUniques(orgPlan?.planProductID),
    },
  ]

  const columns: GridColDef<GridItem>[] = [
    {
      align: 'left',
      headerAlign: 'left',
      field: 'plan',
      headerName: 'Plan',
      renderCell: ({ row }: GridRenderCellParams<GridItem>) => {
        return (
          <TableCell display="flex" flexDirection="column">
            <Typography variant="label" alignSelf="start">
              {row.planName}
            </Typography>
            <Typography variant="body" sx={{ color: theme.palette.Text.Secondary }} alignSelf="start">
              {row.planUniques}
            </Typography>
          </TableCell>
        )
      },
      flex: 1,
      sortable: false,
    },
    {
      align: 'left',
      headerAlign: 'left',
      field: 'cost',
      headerName: 'Cost',
      renderCell: ({ row }: GridRenderCellParams<GridItem>) => <TableCell title={`$${row.planCost}.00 USD/month`} />,
      flex: 1,
      sortable: false,
    },
    {
      align: 'left',
      headerAlign: 'left',
      field: 'billingPeriod',
      headerName: 'Billing Period',
      renderCell: ({ row }: GridRenderCellParams<GridItem>) => (
        <TableCell>
          <FormattedDateRenderer format="MMM D YYYY" date={row.billingStartDate} /> -{' '}
          <FormattedDateRenderer format="MMM D YYYY" date={row.billingEndDate} />
        </TableCell>
      ),
      flex: 1,
      sortable: false,
    },
  ]

  return (
    <InfoRow title="Billing Details">
      <DataGrid
        disableRowSelectionOnClick
        disableMultipleRowSelection
        columns={columns}
        rows={rows}
        getRowId={row => row.planName}
        disableColumnResize
        disableColumnFilter
        disableColumnReorder
        disableColumnPinning
        disableAggregation
        disableRowGrouping
        hideFooter
        disableColumnMenu
        disableRowHoverStates
      />
    </InfoRow>
  )
}
