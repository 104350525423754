export enum PurposeDataRole {
  PROCESSOR = 1,
  CONTROLLER = 2,
  JOINT_CONTROLLER = 3,
  JOINT_PROCESSOR = 4,
  CO_CONTROLLER = 5,
}

export const PURPOSE_DATA_ROLE_NAMES = {
  [PurposeDataRole.PROCESSOR]: 'Processor',
  [PurposeDataRole.CONTROLLER]: 'Controller',
  [PurposeDataRole.JOINT_CONTROLLER]: 'Joint Controller',
  [PurposeDataRole.JOINT_PROCESSOR]: 'Joint Processor',
  [PurposeDataRole.CO_CONTROLLER]: 'Joint Co-Controller',
}

export const PURPOSE_DATA_ROLE_OPTIONS = [
  {
    id: PurposeDataRole.CONTROLLER,
    name: PURPOSE_DATA_ROLE_NAMES[PurposeDataRole.CONTROLLER],
  },
  {
    id: PurposeDataRole.PROCESSOR,
    name: PURPOSE_DATA_ROLE_NAMES[PurposeDataRole.PROCESSOR],
  },
]
