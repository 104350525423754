import React from 'react'
import { MaybeNull } from 'interfaces/common'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { useUsersCurrentTimezone } from 'utils/hooks'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type Props = {
  date?: MaybeNull<string | number>
  format?: string
  showTimezone?: boolean
}

export const FormattedDateRenderer: React.FC<Props> = ({ date, format, showTimezone = false }) => {
  const timeZoneShortFormat = useUsersCurrentTimezone()

  if (!date) return <EmptyValueRenderer variant="smallBody" />

  if (showTimezone) return `${formatDateTimeFromUnix(date, format)} ${timeZoneShortFormat}`

  return formatDateTimeFromUnix(date, format)
}
