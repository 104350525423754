import { API } from 'utils/api/api'
import { APIRequestParams } from 'api/common/utils'
import { formatRequestString } from 'utils/helpers/api/queryString'

// types
import { CreateCustomRightNameRequestBodyDTO } from '@ketch-com/figurehead'
type Params = APIRequestParams<{
  formData: CreateCustomRightNameRequestBodyDTO
}>

export const createCustomRightName = ({ formData }: Params) =>
  API.post<CreateCustomRightNameRequestBodyDTO>(
    formatRequestString({
      entityUrl: `/api/custom-right-names`,
    }),
    formData,
  )
