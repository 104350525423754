import React from 'react'
import Box from '@mui/material/Box'
import { useGridApiRef, GridColDef, GridEventListener, GridRowClassNameParams } from '@mui/x-data-grid-premium'
import { DataGrid, Icon } from '@ketch-com/deck'

const columns: GridColDef[] = [
  { field: 'activity', headerName: 'Activity', width: 200 },
  {
    field: 'assignee',
    headerName: 'Assignee',
    width: 150,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 150,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
  },
]

type WorkflowActivity = {
  id: string
  activity: string
  assignee: 'Automated' | 'MT' | 'KR' | 'AG'
  description: string
  status: 'Completed' | 'In Progress' | 'Not Started'
  activities?: WorkflowActivity[] // Nested activities
}

// HeaderRow is the row with id and path only, the last item in the path array is a Header title
type HeaderRow = {
  path: string[]
  id: string
}

type WorkflowActivityWithPath = WorkflowActivity & { path: string[] }
type WorkflowActivityWithPathAndHeaders = WorkflowActivityWithPath | HeaderRow

//  The following example will render the tree
//  - Sarah
//      - Thomas
//          - Robert
//          - Karen
//          Group A
//          - Tom

//  const columns: GridColDef[] = [{ field: 'jobTitle', width: 250 }];
//  const rows: GridRowsProp = [
//    { path: ['Sarah'], jobTitle: 'CEO', id: 0 },
//    { path: ['Sarah', 'Thomas'], jobTitle: 'Head of Sales', id: 1 },
//    { path: ['Sarah', 'Thomas', 'Robert'], jobTitle: 'Sales Person', id: 2 },
//    { path: ['Sarah', 'Thomas', 'Karen'], jobTitle: 'Sales Person', id: 3 },
//    { path: ['Sarah', 'Thomas', 'Group A'], id: 4 },  // header cell row has path and id only
//    { path: ['Sarah', 'Thomas', 'Tom'], jobTitle: 'Sales Person', id: 5 },
//  ];

// DataGrid TreeData rows must be flatten array with path
// for each element of array add property path
// paths of top level elements is array with only one element is item activity: [activity]
// each nested element has path array from his parent elements and last element is own activity: [...parentsActivity, activity]
const flattenDataAndAddPaths = (data: WorkflowActivity[]): WorkflowActivityWithPath[] => {
  const flatData: WorkflowActivityWithPath[] = []

  const handleFlat = (array: WorkflowActivity[], path: string[] = []) => {
    for (let i = 0; i < array.length; i++) {
      const item = array[i]
      const startPath = path
      path = [...path, item.activity]

      flatData.push({ path, ...item })
      if (Array.isArray(item.activities)) {
        handleFlat(item.activities, path)
      }
      path = startPath
    }
  }

  handleFlat(data)

  return flatData
}

// using arbitrary logic for example purpouse
// create and insert HeaderRows - empty rows with id and path only
const injectCellHeadersIntoData = (rows: WorkflowActivityWithPath[]): WorkflowActivityWithPathAndHeaders[] => {
  const rowsWithLabels: WorkflowActivityWithPathAndHeaders[] = []
  let groupNumber = 1
  for (let i = rows.length - 1; i >= 0; i--) {
    // insert row of type HeaderRow after each third row
    if (i % 3 === 0 && rows[i].path.length > 1) {
      // copy path of previous rows
      let newPath = [...rows[i].path]
      // replace last element of path array with title 'Group 1', 'Group 2',...
      newPath[rows[i].path.length - 1] = `Group ${groupNumber++}`
      // create and push row with path and arbitrary id
      rowsWithLabels.push({ path: newPath, id: `${rows[i].id}:${rows[i].id}` })
    }
    rowsWithLabels.push(rows[i])
  }
  return rowsWithLabels
}

const generateDummyData = (): WorkflowActivity[] => {
  let id = 1
  const randomItem = <T extends any>(arr: T[]): T => arr[Math.floor(Math.random() * arr.length)]

  const generateActivity = (depth: number = 0): WorkflowActivity => {
    const maxDepth = 3 // You can adjust this for more or less nesting depth
    const activity: WorkflowActivity = {
      id: `${id++}`,
      activity: `Activity ${Math.floor(Math.random() * 1000)}`,
      assignee: randomItem(['Automated', 'MT', 'KR', 'AG']),
      description: `Description ${Math.floor(Math.random() * 1000)}`,
      status: randomItem(['Completed', 'In Progress', 'Not Started']),
    }

    // Randomly decide to nest further, and ensure we haven't exceeded maxDepth
    // setting to `> 0.2` for higher chance of nesting
    if (Math.random() > 0.2 && depth < maxDepth) {
      activity.activities = Array.from({ length: Math.floor(Math.random() * 4) }).map(() => generateActivity(depth + 1))
    }

    return activity
  }

  return Array.from({ length: 5 }).map(() => generateActivity())
}

type Props = {}

export const DataGridExpandableExample: React.FC<Props> = props => {
  const apiRef = useGridApiRef()
  const dummyData = generateDummyData()
  const rows = flattenDataAndAddPaths(dummyData)
  const rowsWithCellHeader = injectCellHeadersIntoData(rows)

  const onRowClick = React.useCallback<GridEventListener<'rowClick'>>(
    params => {
      const rowNode = apiRef.current.getRowNode(params.id)
      if (rowNode && rowNode.type === 'group') {
        apiRef.current.setRowChildrenExpansion(params.id, !rowNode.childrenExpanded)
      }
    },
    [apiRef],
  )

  // getRowClassName prop from @mui can be used to apply a custom CSS class on each row.
  // method used to add some custom classes to style rows
  const getRowClassName = (params: GridRowClassNameParams) => {
    const rowNode = apiRef.current.getRowNode(params.id)
    const expandedRowClass = rowNode && rowNode.type === 'group' && rowNode.childrenExpanded ? 'groupRowExpanded' : ''
    const rowHeaderClass = Object.keys(params.row).length === 2 ? 'customRowHeader' : ''
    return `customRowSpan-${params.row.path?.length} ${rowHeaderClass} ${expandedRowClass}`
  }

  return (
    <Box style={{ width: 'auto' }}>
      <DataGrid
        className="Expandable"
        getRowClassName={getRowClassName}
        getTreeDataPath={(row: WorkflowActivityWithPathAndHeaders) => row.path}
        apiRef={apiRef}
        treeData
        onRowClick={onRowClick}
        slots={{
          treeDataCollapseIcon: () => <Icon name="OArrowCDown" />,
          treeDataExpandIcon: () => <Icon name="OArrowCRight" />,
        }}
        slotProps={{
          baseIconButton: {
            onClick: () => false, // let the rowClick handler deal with the click
          },
        }}
        rows={rowsWithCellHeader}
        columns={columns}
      />
    </Box>
  )
}
