import { getURLParams, IDParams } from './utils'

export const insights = {
  root: {
    path: '/reports',
    pattern: '/reports',
    getURL: () => '/reports',
  },

  permits: {
    root: {
      path: '/reports/consent',
      pattern: '/consent',
      getURL: () => '/reports/consent',
    },
    chineseTranslation: {
      path: '/reports/consent/:language',
      pattern: '/consent/:language',
      getURL: ({ code }: getURLParams) => `/reports/consent/${code}`,
    },
  },

  activityAndAccess: {
    root: {
      path: '/reports/activity-and-access',
      pattern: '/activity-and-access',
      getURL: () => '/reports/activity-and-access',
    },
    userActivity: {
      root: {
        path: '/reports/activity-and-access/user-activity',
        getURL: () => '/reports/activity-and-access/user-activity',
      },
      view: {
        root: {
          path: '/reports/activity-and-access/user-activity/:code/view',
          getURL: ({ code }: getURLParams) => `/reports/activity-and-access/user-activity/${code}/view`,
        },
      },
    },
    dataUsage: {
      root: {
        path: '/reports/activity-and-access/data-usage',
        getURL: () => '/reports/activity-and-access/data-usage',
      },
      view: {
        root: {
          path: '/reports/activity-and-access/data-usage/:code/view',
          getURL: ({ code }: getURLParams) => `/reports/activity-and-access/data-usage/${code}/view`,
        },
      },
    },
    accessUtilization: {
      root: {
        path: '/reports/activity-and-access/access-utilization',
        getURL: () => '/reports/activity-and-access/access-utilization',
      },
      view: {
        root: {
          path: '/reports/activity-and-access/access-utilization/:code/view',
          getURL: ({ code }: getURLParams) => `/reports/activity-and-access/access-utilization/${code}/view`,
        },
      },
    },
  },

  rights: {
    root: {
      path: '/reports/rights',
      pattern: '/rights',
      getURL: () => '/reports/rights',
    },
    chineseTranslation: {
      path: '/reports/rights/:language',
      pattern: '/rights/:language',
      getURL: ({ code }: getURLParams) => `/reports/rights/${code}`,
    },
  },

  auditLogs: {
    root: {
      path: '/reports/audit-logs',
      pattern: '/audit-logs',
      getURL: () => '/reports/audit-logs',
    },
  },

  dataMap: {
    root: {
      path: '/reports/ropa',
      pattern: '/ropa/*',
      getURL: () => '/reports/ropa',
    },

    purposes: {
      root: {
        pattern: '/purposes',
        getURL: () => `/reports/ropa/purposes`,
      },
    },

    processingActivities: {
      root: {
        pattern: '/processing-activities',
        getURL: () => `/reports/ropa/processing-activities`,
      },

      view: {
        root: {
          pattern: '/reports/ropa/processing-activities/view/:id/*',
          getURL: ({ id }: IDParams) => `/reports/ropa/processing-activities/view/${id}`,
        },

        overview: {
          root: {
            pattern: '/overview',
            getURL: ({ id }: IDParams) => `/reports/ropa/processing-activities/view/${id}/overview`,
          },
        },

        dataSystems: {
          root: {
            pattern: '/data-systems',
            getURL: ({ id }: IDParams) => `/reports/ropa/processing-activities/view/${id}/data-systems`,
          },
        },
      },
    },

    view: {
      root: {
        path: '/reports/ropa/view/:code',
        pattern: '/ropa/view/:code',
        getURL: ({ code }: getURLParams) => `/reports/ropa/view/${code}`,
      },

      overview: {
        root: {
          path: '/reports/ropa/view/:code/overview',
          pattern: '/overview',
          getURL: ({ code }: getURLParams) => `/reports/ropa/view/${code}/overview`,
        },
      },

      dataSystems: {
        root: {
          path: '/reports/ropa/view/:code/data-systems',
          pattern: '/data-systems',
          getURL: ({ code }: getURLParams) => `/reports/ropa/view/${code}/data-systems`,
        },
      },

      dataSystem: {
        root: {
          path: '/reports/ropa/view/:code/data-systems/:system',
          pattern: '/ropa/view/:code/data-systems/:system',
          getURL: ({ code, system }: any) => `/reports/ropa/view/${code}/data-systems/${system}`,
        },
      },

      processingActivities: {
        root: {
          path: '/reports/ropa/view/:code/processing-activities',
          pattern: '/processing-activities',
          getURL: ({ code }: getURLParams) => `/reports/ropa/view/${code}/processing-activities`,
        },
      },
    },
  },

  dataRisks: {
    root: {
      path: '/reports/data-risks',
      pattern: '/data-risks',
      getURL: () => '/reports/data-risks',
    },
    unutilizedData: {
      root: {
        path: '/reports/data-risks/unutilized-data',
        getURL: () => '/reports/data-risks/unutilized-data',
      },
      view: {
        root: {
          path: '/reports/data-risks/unutilized-data/:code/view',
          getURL: ({ code }: getURLParams) => `/reports/data-risks/unutilized-data/${code}/view`,
        },
      },
    },
    overAccess: {
      root: {
        path: '/reports/data-risks/over-access',
        getURL: () => '/reports/data-risks/over-access',
      },
      view: {
        root: {
          path: '/reports/data-risks/over-access/:code/view',
          getURL: ({ code }: getURLParams) => `/reports/data-risks/over-access/${code}/view`,
        },
      },
    },
    policiesControls: {
      root: {
        path: '/reports/data-risks/policies-controls',
        getURL: () => '/reports/data-risks/policies-controls',
      },
      policies: {
        root: {
          path: '/reports/data-risks/policies-controls/policies/:code?',
          getURL: ({ code }: getURLParams) => `/reports/data-risks/policies-controls/policies/${code || ''}`,
        },
      },
      securityControls: {
        root: {
          path: '/reports/data-risks/policies-controls/security-controls/:code?',
          getURL: ({ code }: getURLParams) => `/reports/data-risks/policies-controls/security-controls/${code || ''}`,
        },
      },
    },
    thirdPartyRisk: {
      root: {
        path: '/reports/data-risks/third-party-risk',
        getURL: () => '/reports/data-risks/third-party-risk',
      },
      privacyControls: {
        root: {
          path: '/reports/data-risks/third-party-risk/privacy-controls/:code?',
          getURL: ({ code }: getURLParams) => `/reports/data-risks/third-party-risk/privacy-controls/${code || ''}`,
        },
      },
      unknownTransfers: {
        root: {
          path: '/reports/data-risks/third-party-risk/unknown-transfers/:code?',
          getURL: ({ code }: getURLParams) => `/reports/data-risks/third-party-risk/unknown-transfers/${code || ''}`,
        },
      },
      undisclosedSystems: {
        root: {
          path: '/reports/data-risks/third-party-risk/undisclosed-systems/:code?',
          getURL: ({ code }: getURLParams) => `/reports/data-risks/third-party-risk/undisclosed-systems/${code || ''}`,
        },
      },
    },
  },
}
