import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { formatBytes } from 'utils/formatters/formatBytes'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { fileTypesHumanReadableMap } from '../../Message/constants'
import { FileTypesHumanReadableMapKeys } from 'utils/constants/fileTypesHumanReadableMap'
import { Chip, Icon } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  attachment: File
  setFiles: React.Dispatch<React.SetStateAction<File[]>>
}

export const ComposeMessageAttachment: React.FC<Props> = ({ attachment, setFiles }) => {
  const [uploadDate] = useState<Date>(() => new Date())

  return (
    <Box display="flex" flexDirection="column" mb={1}>
      <Box display="flex" alignItems="center">
        <Chip
          label={attachment?.name}
          clickable
          icon={
            fileTypesHumanReadableMap?.[attachment?.type as FileTypesHumanReadableMapKeys] ===
            fileTypesHumanReadableMap['application/pdf'] ? (
              <Icon name="OPdf" />
            ) : (
              <Typography
                variant="smallFootnote"
                sx={{
                  border: theme => `1px solid ${theme.palette.royalBlue.main}`,
                  color: theme => `${theme.palette.royalBlue.main} !important`,
                  textTransform: 'uppercase',
                  borderRadius: '6px',
                  fontWeight: 600,
                  p: '5px 3px',
                }}
              >
                {fileTypesHumanReadableMap?.[attachment?.type as FileTypesHumanReadableMapKeys] || 'file'}
              </Typography>
            )
          }
          onDelete={() => {
            setFiles(prev => prev.filter(file => file.name !== attachment.name))
          }}
          deleteIcon={<Icon name="OCross" />}
        />
      </Box>

      {/* Metadata */}

      <Box display="flex" alignItems="center" gap={0.5}>
        <Typography
          variant="footnote"
          sx={{
            borderRight: '1px solid',
            borderColor: 'grey.main',
            pr: 0.5,
          }}
        >
          {formatBytes(attachment?.size)}
        </Typography>
        <Typography variant="footnote">
          Uploaded <FormattedDateRenderer date={(uploadDate.getTime() || 0) / 1000} />
        </Typography>
      </Box>
    </Box>
  )
}
