export enum LinkUrlType {
  PRIVACY_POLICY = 'privacyPolicy',
  TERMS_OF_SERVICE = 'termsOfService',
  CUSTOM = 'custom',
  TRIGGER_MODAL = 'triggerModal',
}

export const LINK_URL_TYPE_OPTIONS = [
  {
    title: 'Privacy Policy',
    value: LinkUrlType.PRIVACY_POLICY,
  },
  {
    title: 'Terms of Service',
    value: LinkUrlType.TERMS_OF_SERVICE,
  },
  {
    title: 'Trigger Modal',
    value: LinkUrlType.TRIGGER_MODAL,
  },
  {
    title: 'Custom Link',
    value: LinkUrlType.CUSTOM,
  },
]
