import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchDataCategories } from 'api/dataSystemsCategories/fetchers/fetchDataCategories'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.dataCategories,
  queryFn: fetchDataCategories,
  select: res => res?.data,
})

type QueryConfig = CustomQueryConfig<typeof useCustomQuery>

export const useDataCategories = (config?: QueryConfig) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
