import {
  fetchDeploymentPlanPreviewConfiguration,
  FetchDeploymentPlanPreviewConfigurationParams,
} from 'api/deploymentPlans/fetchers/fetchDeploymentPlanPreviewConfiguration'

type Params = {
  configurations: FetchDeploymentPlanPreviewConfigurationParams[]
}

export const fetchDeploymentPlanPreviewConfigurations = ({ configurations }: Params) =>
  Promise.all(
    configurations.map(({ experienceCode, policyScopeCode, themeCode }) =>
      fetchDeploymentPlanPreviewConfiguration({
        experienceCode,
        policyScopeCode,
        themeCode,
      }),
    ),
  )
