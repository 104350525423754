import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Button, Chip, TableCell } from '@ketch-com/deck'
import {
  ProcessingActivityAssessmentRequirementDTO,
  ProcessingActivityDTO,
  ProcessingActivityProcessingFrequencyDTO,
  ProcessingActivityTypeDTO,
} from '@ketch-com/figurehead'
import {
  ProcessingActivityAssessmentRequirementLabel,
  ProcessingActivityFrequencyLabel,
  ProcessingActivityTypeLabel,
} from '../../../constants'
import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'

const PurposeProcessingActivitiesButtonCell = ({ row }: { row: ProcessingActivityDTO }) => {
  const navigate = useNavigate()
  return (
    <TableCell>
      <Button
        color="tertiary"
        onClick={() =>
          navigate(
            RoutesManager.insights.dataMap.processingActivities.view.overview.root.getURL({
              id: row.id,
            }),
          )
        }
      >
        View
      </Button>
    </TableCell>
  )
}

export const dataMapProcessingActivitiesColumns: GridColDef<ProcessingActivityDTO>[] = [
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ProcessingActivityDTO>) => {
      return <TableCell sx={{ padding: '8px 0' }} title={row.name} />
    },
  },
  {
    align: 'left',
    field: 'type',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Type of Processing',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ProcessingActivityDTO>) => {
      return (
        <TableCell
          sx={{ padding: '8px 0' }}
          title={ProcessingActivityTypeLabel[row.type || ProcessingActivityTypeDTO.UnspecifiedProcessingActivityType]}
        />
      )
    },
  },
  {
    align: 'left',
    field: 'systems',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Data Systems',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ProcessingActivityDTO>) => {
      return (
        <TableCell sx={{ padding: '8px 0' }}>
          <Chip label={row.dataSystems?.length.toString() || '0'} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'categories',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Data Categories',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ProcessingActivityDTO>) => {
      return (
        <TableCell sx={{ padding: '8px 0' }}>
          <Chip label={row.dataCategories?.length.toString() || '0'} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'processingFrequency',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Processing Frequency',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ProcessingActivityDTO>) => {
      return (
        <TableCell
          title={
            ProcessingActivityFrequencyLabel[
              row.processingFrequency ||
                ProcessingActivityProcessingFrequencyDTO.UnspecifiedProcessingActivityProcessingFrequency
            ]
          }
        />
      )
    },
  },
  {
    align: 'left',
    field: 'assessmentStatus',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Assessment Status',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ProcessingActivityDTO>) => {
      return (
        <TableCell
          title={
            ProcessingActivityAssessmentRequirementLabel[
              row.assessmentRequirement ||
                ProcessingActivityAssessmentRequirementDTO.UnspecifiedProcessingActivityAssessmentRequirement
            ]
          }
        />
      )
    },
  },
  {
    align: 'left',
    field: 'viewButton',
    headerAlign: 'left',
    headerName: '',
    maxWidth: 100,
    flex: 1,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ProcessingActivityDTO>) => {
      return <PurposeProcessingActivitiesButtonCell row={row} />
    },
  },
]
