import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createWebhook } from '../fetchers/createWebhook'

const useCustomMutation = createUseMutation({
  mutationFn: createWebhook,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateWebhook = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
