import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { UrlParams } from 'pages/assetManager/scenario/interfaces'
import { Formik } from 'formik'
import { CustomSqlContext } from 'pages/assetManager/scenario/details/components/CustomSql/context'
import { MaybeNull } from 'interfaces'
import { CustomSqlFormData } from 'pages/assetManager/scenario/details/components/CustomSql/interfaces'
import { CustomSqlUpsertForm } from 'pages/assetManager/scenario/details/components/CustomSql/components'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { useCustomSqlInitialValues } from 'pages/assetManager/scenario/details/components/CustomSql/hooks'
import { getCustomSqlValidationSchema } from 'pages/assetManager/scenario/details/components/CustomSql/utils'
import { CreateDatabaseDsrConfigurationSqlReq } from '@ketch-com/windlass/dist/schemas'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'
import { SCRIPT_TYPE } from 'pages/assetManager/scenario/details/constants'

type CustomSqlUpsertModalProps = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

interface BackwardsCompatibleDsrConfigurationSql extends CreateDatabaseDsrConfigurationSqlReq {
  identitySpaceCodes: string[]
}

export const CustomSqlUpsertModal: React.FC<CustomSqlUpsertModalProps> = ({ assetSummary }) => {
  const { id: databaseResourceId = '', scenarioId = '' } = useParams<UrlParams>()
  const { upsertCustomSql } = useContext(CustomSqlContext)

  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isMultipleIdentitiesFlagEnabled = !isFeatureFlagEnabled(
    FEATURE_FLAGS.IMPORT_CONTEXT,
    FEATURE_FLAGS_VALUES.IMPORT_CONTEXT_SINGLE_IDENTITY,
  )

  const initialValues = useCustomSqlInitialValues()
  const validationSchema = getCustomSqlValidationSchema(isMultipleIdentitiesFlagEnabled)

  const submitCustomSql = (values: CustomSqlFormData) => {
    upsertCustomSql({
      params: {
        data: {
          databaseResourceId,
          scenarioId,
          identitySpaceCode: values?.identitySpaceCode || '',
          identitySpaceCodes: values?.identitySpaceCodes || [],
          name: values?.name || '',
          ...(values.scriptType === SCRIPT_TYPE.SINGLE && {
            sqlQuery: values?.sqlQuery || '',
          }),
          ...(values.scriptType === SCRIPT_TYPE.MULTIPLE && {
            sqlQueries: values?.sqlQueries || [],
          }),
          id: values.id || '',
        } as BackwardsCompatibleDsrConfigurationSql,
      },
    })
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitCustomSql}
    >
      {() => {
        return <CustomSqlUpsertForm />
      }}
    </Formik>
  )
}
