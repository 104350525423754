import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { deleteProcessingActivityModelBiasFile } from '../fetchers/deleteProcessingActivityModelBiasFile'

const useCustomMutation = createUseMutation({
  mutationFn: deleteProcessingActivityModelBiasFile,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeleteProcessingActivityModelBiasFile = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation, {})
}
