import { Button, Icon, Status, StatusVariant, TableCell } from '@ketch-com/deck'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'
import { ApplianceWork } from '@ketch-com/windlass/dist/appliance/appliances'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { getInputSubjectName } from './getInputSubjectName'
import { workStatusColorMapping, workStatusTextMapping } from '../constants'

export const transponderViewColumns = (
  handlePayloadButtonClick: React.Dispatch<React.SetStateAction<ApplianceWork | null>>,
): GridColDef<ApplianceWork>[] => [
  {
    align: 'left',
    field: 'status',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Status',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ApplianceWork>) => {
      const statusCopy =
        row.status === 'FAILED' || row.IngestionWork?.status === 'FAILED'
          ? workStatusTextMapping['FAILED']
          : workStatusTextMapping[row.status]
      const statusColor =
        row.status === 'FAILED' || row.IngestionWork?.status === 'FAILED'
          ? workStatusColorMapping['FAILED']
          : workStatusColorMapping[row.status]
      return (
        <TableCell>
          <Status icon="Dot" variant={StatusVariant.ghost} status={statusColor} label={statusCopy} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'workName',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Work Name',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ApplianceWork>) => {
      return (
        <TableCell
          title={(row.inputSubject && getInputSubjectName(row.inputSubject)) || row.applianceID}
          subTitle={row.applianceID}
        />
      )
    },
  },
  {
    align: 'left',
    field: 'createdAt',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Created At',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ApplianceWork>) => {
      return <TableCell title={formatDateTimeFromUnix(row.createdAt)} />
    },
  },
  {
    align: 'left',
    field: 'startedAt',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Started At',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ApplianceWork>) => {
      return <TableCell title={formatDateTimeFromUnix(row.startTime)} />
    },
  },
  {
    align: 'right',
    field: 'payloadModal',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Payload',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ApplianceWork>) => {
      return (
        <TableCell>
          {
            <Button
              color="tertiary"
              endIcon={<Icon name="OCode" />}
              onClick={() => {
                handlePayloadButtonClick(row)
              }}
            >
              View
            </Button>
          }
        </TableCell>
      )
    },
  },
]
