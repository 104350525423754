import { AssessmentTemplateStatusDTO, AssessmentTemplateDTO, ArchiveStatusDTO } from '@ketch-com/figurehead'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { fetchAssessmentTemplates } from '../fetchers/fetchAssessmentTemplates'

const defaultLimit = 30

export const useInfiniteAssessmentTemplates = ({
  name,
  approvalStatus,
  archiveStatus,
  customLimit,
}: {
  name?: string
  approvalStatus?: AssessmentTemplateStatusDTO
  archiveStatus?: ArchiveStatusDTO
  customLimit?: number
}) => {
  const limit = customLimit || defaultLimit

  const resp = useInfiniteQuery({
    queryKey: [ApiQueryKeys.assessmentTemplates, { name, approvalStatus, archiveStatus }],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchAssessmentTemplatesResult = await fetchAssessmentTemplates({
        limit,
        start: (pageParam - 1) * limit,
        name: name ? name : undefined,
        approvalStatus: approvalStatus ? approvalStatus : undefined,
        archiveStatus: archiveStatus ? archiveStatus : undefined,
      })

      return {
        assessmentTemplates: fetchAssessmentTemplatesResult.data?.data ?? [],
        totalResults: fetchAssessmentTemplatesResult.data?.meta?.count ?? 0,
      }
    },
    getNextPageParam: ({ totalResults }, allPages) => {
      let totalItemsFetched = 0
      allPages?.forEach?.(pageOfResults => {
        totalItemsFetched += pageOfResults?.assessmentTemplates?.length || 0
      })
      const nextPageParam = Math.ceil(totalItemsFetched / limit) + 1

      return totalItemsFetched === totalResults ? undefined : nextPageParam
    },
  })

  let infiniteAssessmentTemplates: AssessmentTemplateDTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    const newAssessmentTemplates = page?.assessmentTemplates
    infiniteAssessmentTemplates = [...infiniteAssessmentTemplates, ...newAssessmentTemplates]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: infiniteAssessmentTemplates,
    totalResults,
  }
}
