import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Box, Typography } from '@mui/material'
import { CreateRelationdhipFormData } from 'pages/assetManager/relationshipMapping/interfaces'
import { useSelectAssetsState } from 'pages/assetManager/relationshipMapping/components/CreatedRelationships/components/CreateRelationshipMappingModal/hooks/useSelectAssetsState'
import { ColumnResources } from 'pages/assetManager/interfaces'
import { useColumnResources } from 'api/assets/queries/useColumnResources'
import { DropListButton, ActionSheetItem } from '@ketch-com/deck'

export const ColumnAssetsPrimary = () => {
  const { setFieldValue, setFieldTouched, isSubmitting, values, errors, touched } =
    useFormikContext<CreateRelationdhipFormData>()
  const { setInputValue, searchQueryValue, setSearchQueryValue } = useSelectAssetsState<ColumnResources>()

  useEffect(() => {
    setInputValue('')
    setSearchQueryValue('')
  }, [values?.primaryTableResourceId, setInputValue, setSearchQueryValue])

  const { data: columnResourcesData, isLoading: isColumnResourcesDataLoading } = useColumnResources({
    params: {
      query: searchQueryValue,
      limit: 200,
      parentTableId: values?.primaryTableResourceId,
    },
  })

  const isError = !!errors?.primaryColumnResourceId && !!touched?.primaryColumnResourceId
  const errorMessage = errors?.primaryColumnResourceId || 'no-error'

  return (
    <Box>
      <DropListButton
        placeholder="Select Column"
        key={values.primaryTableResourceId}
        noOptionsText="No Primary Key"
        loading={isColumnResourcesDataLoading}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={isSubmitting}
        options={columnResourcesData?.columnResources || []}
        filterOptions={x => x}
        getOptionLabel={option => option?.name || ''}
        renderOption={(props, option, { selected }) => {
          return (
            <ActionSheetItem key={option.id} selected={selected} {...props}>
              <Box display="flex" gap={0.5} flexDirection="column">
                <Box alignSelf="flex-start">
                  <Typography variant="body">{option?.name}</Typography>
                </Box>
                <Box alignSelf="flex-start" display="flex" alignItems="center" gap={0.5}>
                  <Typography variant="footnote">Type: {`${option?.dataType}`}</Typography>
                </Box>
              </Box>
            </ActionSheetItem>
          )
        }}
        onBlur={() => {
          setFieldTouched('primaryColumnResourceId', true)
        }}
        onChange={(e, tableResource) => {
          setFieldValue('primaryColumnResourceId', tableResource?.id)
        }}
        onInputChange={(event, value) => {
          setInputValue(value)
        }}
        error={isError}
        errorMessage={errorMessage}
      />
    </Box>
  )
}
