import React, { useEffect, useState } from 'react'

import { AssetSummaryDTO, PurposeDTO } from '@ketch-com/figurehead'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { getReducedPurposeOptions } from '../utils'

type Args = {
  assetSummary?: AssetSummaryDTO
}

export const usePurposesWidgetEditModalUtils = ({ assetSummary }: Args) => {
  const [newPurpose, setNewPurpose] = useState('')
  const [purposes, setPurposes] = useState<PurposeDTO[]>([])
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)

  useEffect(() => {
    const currentAssignedPurposes = (assetSummary?.asset?.purposes || []).map(purpose => ({
      code: purpose?.code,
      name: purpose?.name,
      description: purpose?.description,
    }))

    setPurposes([...(currentAssignedPurposes as PurposeDTO[])])
  }, [assetSummary, setPurposes])

  const { data: purposeOptions, isLoading: isLoadingPurposeOptions } = usePurposes({})

  const handleLabelInputValueOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setNewPurpose(e.target.value)
  }

  const handleAutocompleteOnChange = (event: React.ChangeEvent<{}>, value: PurposeDTO[]): void => {
    setPurposes(currentLabels => {
      const payload = [...currentLabels, value[value.length - 1]]
      return payload
    })
    setNewPurpose('')
  }

  const handleRemoveTag = (purposeToRemoveCode: string) => {
    setPurposes(purposes => purposes.filter(purpose => purpose?.code !== purposeToRemoveCode))
  }

  return {
    handleAutocompleteOnChange,
    handleLabelInputValueOnChange,
    handleRemoveTag,
    isLoading: isLoadingPurposeOptions,
    isOptionsOpen,
    newPurpose,
    purposeOptions: getReducedPurposeOptions({ purposeOptions }),
    purposes,
    setIsOptionsOpen,
    setNewPurpose,
  }
}
