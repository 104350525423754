import { FC } from 'react'
import { Button, PopUp, Icon } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

type HowWeCalculateUniquesModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const HowWeCalculateUniquesModal: FC<HowWeCalculateUniquesModalProps> = ({ isOpen, onClose }) => {
  return (
    <PopUp
      title="How Do We Calculate Unique Users?"
      variant="modal"
      isOpen={isOpen}
      onClose={onClose}
      popUpWidth="658px"
      popUpActionChildren={
        <>
          <Button color="tertiary" size="large" onClick={onClose}>
            Close
          </Button>
        </>
      }
    >
      <Box py={3} px={9} display="flex" flexDirection="column" gap={5.5}>
        <Box display="flex" gap={3}>
          <Icon name="FaqUniqueIds" width={56} height={56} />
          <Box display="flex" flexDirection="column" gap="6px">
            <Typography variant="label">We Measure Unique Identities Across All Your Properties</Typography>
            <Typography>
              We count the number of unique users that visit each one of your properties; most often we use the Ketch
              managed identifier to do this. We then sum the visitors across all properties to get the total
              organization level unique number.
            </Typography>
          </Box>
        </Box>

        <Box display="flex" gap={3}>
          <Icon name="FaqUniqueMonth" width={56} height={56} />
          <Box display="flex" flexDirection="column" gap="6px">
            <Typography variant="label">Unique Users per Month</Typography>
            <Typography>
              Each month, you'll receive a count of unique users. We will evaluate this unique number against your
              plan's stated limit and determine if you need a change in plan.
            </Typography>
          </Box>
        </Box>

        <Box display="flex" gap={3}>
          <Icon name="FaqUpgrade" width={56} height={56} />
          <Box display="flex" flexDirection="column" gap="6px">
            <Typography variant="label">Upgrade is Easy</Typography>
            <Typography>
              If you need to increase your unique user limit, upgrading is simple. Choose the plan that suits you best
              or
              <Typography
                component="a"
                color="primary"
                href="https://www.ketch.com/contact-us"
                target="_blank"
                sx={{
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                &nbsp;contact our team&nbsp;
              </Typography>
              for assistance.
            </Typography>
          </Box>
        </Box>
      </Box>
    </PopUp>
  )
}
