import { PreferenceExperienceFormValues } from '../../../interfaces/PreferenceExperienceFormValues'
import { ExperienceCreationContextLocationState } from '..'
import { ConsentExperienceFormValues } from '../../consent/interfaces'

export const getExperienceCreationLocationState = ({
  referrer,
  values,
}: {
  referrer: string
  values: ConsentExperienceFormValues | PreferenceExperienceFormValues
}): ExperienceCreationContextLocationState => ({
  creationContext: { referrer, values },
})
