import React, { useContext } from 'react'
import { PrivacyProtocolContext } from '../context/PrivacyProtocolContext'
import { PrivacyProtocolConfigurationResponseBodyStatus } from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'
import { Box } from '@mui/material'
import { Chip, Status, StatusState, StatusVariant } from '@ketch-com/deck'

/**
 * Icon and status section for a protocol configuration
 * @constructor
 */
export const PrivacyProtocolViewDetails: React.FC = () => {
  const { protocol } = useContext(PrivacyProtocolContext)

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Chip size="small" label="Privacy Protocol" />
      <Status
        variant={StatusVariant.outlined}
        icon="Dot"
        status={
          protocol.config?.status === PrivacyProtocolConfigurationResponseBodyStatus.enabled
            ? StatusState.success
            : StatusState.draft
        }
        label={
          protocol.config?.status === PrivacyProtocolConfigurationResponseBodyStatus.enabled ? 'Enabled' : 'Disabled'
        }
      />
    </Box>
  )
}
