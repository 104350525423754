import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  policyCode: string
}

export const deletePolicyV2 = ({ policyCode }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/v2/policies/${policyCode}`,
    }),
  )
