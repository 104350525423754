import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { deleteExperience } from 'api/experiences-v2/fetchers/deleteExperience'

const useCustomMutation = createUseMutation({
  mutationFn: deleteExperience,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeleteExperience = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
