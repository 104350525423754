import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { JurisdictionPermitStatsDTO } from '@ketch-com/figurehead'
import { FormattedNumberRenderer } from 'components/renderers/FormattedNumberRenderer'
import { Box, Typography } from '@mui/material'
import { LinearProgress, Chip, TableCell } from '@ketch-com/deck'

export const allowanceBreakdownPerExperienceColumns = (
  translate: (a: string) => string,
  isDemo?: boolean,
): GridColDef[] => [
  {
    field: 'name',
    flex: 1,
    headerName: 'Experience',
    sortable: false,
    renderCell: ({ row: { name } }: GridRenderCellParams<JurisdictionPermitStatsDTO>) => {
      const displayName = name?.includes('DELETED') ? name?.replace('DELETED', '') : name || 'DELETED'
      const isDeleted = name?.includes('DELETED')

      return (
        <TableCell sx={{ py: '5px', height: '52px' }}>
          <Box display="flex" flexDirection="column">
            <Box>
              <Typography variant="label">{displayName}</Typography>
            </Box>
          </Box>
          {isDeleted && (
            <Box display="flex" ml={2}>
              <Chip size="small" label={translate('Deleted')} />
            </Box>
          )}
        </TableCell>
      )
    },
  },

  ...(isDemo
    ? []
    : [
        {
          field: 'allowed',
          headerName: translate('Allowed'),
          width: 150,
          sortable: false,
          renderCell: ({
            row,
          }: GridRenderCellParams<JurisdictionPermitStatsDTO & { allowed: number; allowanceRate: number }>) => (
            <TableCell sx={{ py: '5px', height: '52px' }}>
              <FormattedNumberRenderer value={row.allowed} />
            </TableCell>
          ),
        },
      ]),
  {
    field: 'allowanceRate',
    headerName: translate('Allowance Rate'),
    width: 180,
    sortable: false,
    renderCell: ({
      row,
    }: GridRenderCellParams<JurisdictionPermitStatsDTO & { allowed: number; allowanceRate: number }>) => {
      return (
        <TableCell sx={{ py: '5px', height: '52px' }}>
          <LinearProgress value={row.allowanceRate} label={`${row.allowanceRate.toFixed(1)} %`} />
        </TableCell>
      )
    },
  },
]
