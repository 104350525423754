import React, { useContext } from 'react'

import {
  PreferenceField,
  isFieldRequired,
  SIDEBAR_TITLES,
  useOnRemove,
  fieldNames,
} from 'pages/consentAndRights/experiences/upsert/utils/common'
import { Button } from '@ketch-com/deck'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { UpsertExperienceFieldSidebar } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceFieldSidebar'
import { onRemoveHandledCases } from 'pages/consentAndRights/experiences/upsert/utils/common/hooks/useOnRemove'
import { OverviewFields, PreferencesFields, RightsFields, PreferencePurposeFields, Translations } from './components'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import { SubscriptionFields } from './components/SubscriptionFields/SubscriptionFields'

type Props = {}

export const Sidebar: React.FC<Props> = () => {
  const { sidebarActiveTab, organizationLanguages } = useContext(ExperienceUpsertContext)
  const { onRemove } = useOnRemove({ organizationLanguages })

  const shouldShowTranslations =
    !!organizationLanguages?.length &&
    sidebarActiveTab !== PreferenceField.PREFERENCE_PURPOSES &&
    sidebarActiveTab !== PreferenceField.SUBSCRIPTIONS_TOPICS &&
    sidebarActiveTab !== PreferenceField.SUBSCRIPTIONS_CONTROL &&
    sidebarActiveTab !== PreferenceField.RIGHTS_FORM_SETTINGS

  if (!sidebarActiveTab) return null

  const isSubscriptionsSideBar =
    sidebarActiveTab === PreferenceField.SUBSCRIPTIONS_TAB_NAME ||
    sidebarActiveTab === PreferenceField.SUBSCRIPTIONS_BODY_TITLE ||
    sidebarActiveTab === PreferenceField.SUBSCRIPTIONS_BODY_DESCRIPTION ||
    sidebarActiveTab === PreferenceField.SUBSCRIPTIONS_PRIMARY_BUTTON ||
    sidebarActiveTab === PreferenceField.SUBSCRIPTIONS_SECONDARY_BUTTON ||
    sidebarActiveTab === PreferenceField.SUBSCRIPTIONS_TOPICS ||
    sidebarActiveTab === PreferenceField.SUBSCRIPTIONS_CONTROL

  return (
    <UpsertExperienceFieldSidebar
      title={SIDEBAR_TITLES[sidebarActiveTab]}
      required={isFieldRequired({ field: sidebarActiveTab })}
      actions={
        sidebarActiveTab !== PreferenceField.RIGHTS_FORM_SETTINGS &&
        !isSubscriptionsSideBar && (
          <Button
            disabled={!onRemoveHandledCases.includes(sidebarActiveTab!)}
            size="large"
            color="secondary"
            onClick={() => {
              onRemove(sidebarActiveTab!)
            }}
          >
            Remove
          </Button>
        )
      }
    >
      <>
        {sidebarActiveTab === PreferenceField.MAIN_TITLE && (
          <FormRow marginBottom={24}>
            <FormInput
              required={isFieldRequired({ field: PreferenceField.MAIN_TITLE })}
              fullWidth
              name={fieldNames.preference.title.root}
              label="Display Text"
            />
          </FormRow>
        )}

        {/* Overview Fields */}

        <OverviewFields />

        {/* Preferences Fields */}

        <PreferencesFields />

        {/* Rights Fields */}

        <RightsFields />

        {/* Purposes Fields */}

        <PreferencePurposeFields />

        {/* Subscription Fields */}

        <SubscriptionFields />
      </>

      {shouldShowTranslations && <Translations />}
    </UpsertExperienceFieldSidebar>
  )
}
