import { ContentGroup, ListLayout } from '@ketch-com/deck'
import { Box, Divider, styled } from '@mui/material'
import { OverviewCard } from 'components/OverviewCard/OverviewCard'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { useNavigateGated } from 'utils/routing/hooks/useNavigateGated'
import { RoutesManager } from 'utils/routing/routesManager'
import { SidebarRouteItem } from 'utils/routing/types'
import { useSidebarRoutesConfig } from 'utils/routing/useSidebarRoutesConfig'

const CardsBox = styled(Box)({
  display: 'flex',
  gap: '24px',
})

export const PrivacyProgramOverview: React.FC = () => {
  const navigateGated = useNavigateGated()

  const breadcrumbs = [{ title: 'Privacy Program' }]

  // Get privacy program routes
  const privacyProgramRoutes = useSidebarRoutesConfig().find(route =>
    route.path.includes(RoutesManager.policyCenter.root.getURL()),
  )?.subRoutes as { [subRouteSection: string]: SidebarRouteItem[] }

  const handleCardClick = (item: SidebarRouteItem) => {
    navigateGated(
      item.path,
      item.permissions,
      item.entitlements,
      item.requireAllPermissions,
      item.requireAllEntitlements,
    )
  }

  const riskCards = privacyProgramRoutes['Risk'].map((item, index) => (
    <OverviewCard key={index} cardTitle={item.title} onClick={() => handleCardClick(item)} />
  ))

  const foundationsCards = privacyProgramRoutes['Foundations'].map((item, index) => (
    <OverviewCard key={index} cardTitle={item.title} onClick={() => handleCardClick(item)} />
  ))

  return (
    <ListLayout headerTitle="Privacy Program" headerProps={{ isDivider: true }} sx={{ px: '154px' }}>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      {/* Risk cards */}
      {Boolean(riskCards.length) && (
        <>
          <ContentGroup
            title="Risk"
            titleVariant="h3"
            subTitle="Manage privacy essentials, mitigate risk, and define your general stance on data processing."
          >
            <CardsBox>{riskCards}</CardsBox>
          </ContentGroup>
          <Divider sx={{ mt: 6, mb: 2 }} />
        </>
      )}

      {/* Foundations cards */}
      {Boolean(foundationsCards.length) && (
        <ContentGroup
          title="Foundations"
          titleVariant="h3"
          subTitle="Configure and manage key components of your data privacy program, including jurisdictions, purposes, and more."
        >
          <CardsBox>{foundationsCards}</CardsBox>
        </ContentGroup>
      )}
    </ListLayout>
  )
}
