import { IApprovalActivityParams, ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { isApprovalActivityParamsTypeGuard } from 'pages/orchestration/workflows/edit/interfaces/typeGuards'

interface IGetApprovalInitialValuesParams {
  step: ICanvasStep
}

export const getApprovalInitialValues = (params: IGetApprovalInitialValuesParams): IApprovalActivityParams => {
  const { step } = params

  if (isApprovalActivityParamsTypeGuard(step.activity?.params)) {
    const description = step.description || ''
    const code = step.code || ''
    const approver = step.activity?.params?.approver || ''
    const details = step.activity?.params?.details || ''
    const activity = step.activity?.params?.activity || ''
    const timeToCompleteDays = step.activity?.params?.timeToCompleteDays || undefined

    return {
      description,
      code,
      approver,
      details,
      activity,
      timeToCompleteDays,
    }
  }

  return {
    description: '',
    code: '',
    approver: '',
    details: '',
    activity: '',
  }
}
