import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { WebhookDTO } from '@ketch-com/figurehead'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { DataGrid, Icon, TableCell, PopUp, Button, InfoRow, Chip } from '@ketch-com/deck'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'

const PREFIX = 'UpsertWebhookModal'

const classes = {
  hideShowSensitiveIcon: `${PREFIX}-hideShowSensitiveIcon`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.hideShowSensitiveIcon}`]: {
    width: 18,
    height: 14,
    cursor: 'pointer',
    color: palette.darkDusk.main,
  },
}))

type Props = {
  webhook?: WebhookDTO
  onCancel?: () => void
  shouldHideAssetsAssignmentTab?: boolean
}

export const WebhookOverviewModal: React.FC<Props> = ({ onCancel, webhook }) => {
  const [revealedHeaderKeys, setRevealedHeaderKeys] = useState(new Set<string>())

  const toggleVisible = (key: string): void => {
    const revealedHeaderKeysCopy = new Set(revealedHeaderKeys)
    if (revealedHeaderKeysCopy.has(key)) {
      revealedHeaderKeysCopy.delete(key)
    } else {
      revealedHeaderKeysCopy.add(key)
    }
    setRevealedHeaderKeys(revealedHeaderKeysCopy)
  }

  const webhookHeaderColumns: GridColDef[] = [
    {
      align: 'left',
      field: 'key',
      headerAlign: 'left',
      headerName: 'Key',
      sortable: false,
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <TableCell>
            <Typography title={row.key}>{row.key}</Typography>
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'value',
      flex: 2,
      headerAlign: 'left',
      headerName: 'Value',
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        const key = row.key ?? ''
        const isShowSensitive = revealedHeaderKeys.has(key)

        return (
          <TableCell>
            <StyledBox display="flex" alignItems="center">
              <Typography
                mr={1}
                sx={{ textOverflow: 'ellipsis', maxWidth: 150, overflow: 'hidden', whiteSpace: 'nowrap' }}
              >
                {isShowSensitive ? row.value : '•••••••••'}
              </Typography>
              <Icon
                name={isShowSensitive ? 'FEyeHide' : 'FEyeShow'}
                onClick={() => toggleVisible(key)}
                className={classes.hideShowSensitiveIcon}
              />
            </StyledBox>
          </TableCell>
        )
      },
    },
  ]

  // in case of test mode, webhook headers could be an array
  // with a single elem, which has no attributes
  const dataGridRows =
    webhook?.headers
      ?.filter(elem => Object.keys(elem).length > 0)
      .map((elem, index) => ({ ...elem, rowIndex: index })) || []

  return (
    <PopUp
      title={webhook?.name}
      popUpWidth="664px"
      variant="dialog"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Close
          </Button>
        </>
      }
    >
      <StyledBox display="flex" flexDirection="column" gap={2}>
        <InfoRow title="Name">{webhook?.name}</InfoRow>
        <InfoRow title="Webhook URL">
          <Box sx={{ wordBreak: 'break-word' }}>{webhook?.url}</Box>
        </InfoRow>
        <InfoRow title="Headers">
          {dataGridRows.length ? (
            <DataGrid
              sx={{
                ml: -3,
                mb: -2,
                '.MuiDataGrid-main': {
                  minHeight: '100%',
                },
              }}
              rows={dataGridRows || []}
              columns={webhookHeaderColumns}
              autosizeOnMount
              disableChildrenSorting
              disableColumnMenu
              disableColumnResize
              disableColumnReorder
              disableBorder
              disableRowHoverStates
              disableRowSelectionOnClick
              disableAggregation
              hideFooterRowCount
              getRowId={row => row.rowIndex}
            />
          ) : (
            <EmptyValueRenderer />
          )}
        </InfoRow>
        <InfoRow title="Event Subscriptions">
          {webhook?.events?.length ? (
            <Box display="flex" gap={1}>
              {webhook?.events.map((event, index) => <Chip key={`${event}${index}`} label={event} />)}
            </Box>
          ) : (
            <EmptyValueRenderer />
          )}
        </InfoRow>
        <InfoRow title="Test Mode">{webhook?.testMode ? 'Yes' : 'No'}</InfoRow>
      </StyledBox>
    </PopUp>
  )
}
