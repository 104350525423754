export enum OperationTypeEnum {
  STORED_PROCEDURE = 1,
}

export enum OperationTypeAccessorEnum {
  STORED_PROCEDURE = 'STORED_PROCEDURE',
}

export const HOOK_OPERATION_TYPE_ENUM = {
  STORED_PROCEDURE: 1,
}

export const HOOK_OPERATION_TYPE_NAMES: {
  [key: number]: string
} = {
  0: 'Unspecified',
  [OperationTypeEnum.STORED_PROCEDURE]: 'Stored Procedure',
}

export const OPERATION_TYPE_NAME_TO_ENUM: {
  [key: string]: number
} = {
  database: OperationTypeEnum.STORED_PROCEDURE,
}

export const OPERATION_TYPE_OPTIONS = [
  {
    id: OperationTypeEnum.STORED_PROCEDURE,
    name: HOOK_OPERATION_TYPE_NAMES[OperationTypeEnum.STORED_PROCEDURE],
  },
]
