import Box from '@mui/material/Box'
import { Routes, Route, useLocation } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ViewLayoutHeaderLeftMenu, ViewLayoutLeftMenu } from 'components/ui-layouts/viewLayoutLeftMenu'
import { ReactComponent as AttributesIcon } from 'assets/icons/attributes_dark.svg'
import { ReactComponent as ThirdPartyRiskIcon } from 'assets/icons/thirdPartyRisk.svg'
import { PrivacyControlsMenu } from './components/PrivacyControlsMenu'
import { PrivacyControlsDetails } from './components/PrivacyControlsDetails'
import { Button } from '@ketch-com/deck'
import { DetailsEmptyState } from '../components/layout/DetailsEmptyState'
import { UndisclosedSystemsMenu } from './components/UndisclosedSystemsMenu'
import { UndisclosedSystemsDetails } from './components/UndisclosedSystemsDetails'
import { UnknownTransfersMenu } from './components/UnknownTransfersMenu'
import { UnknownTransfersDetails } from './components/UnknownTransfersDetails'
import { ThirdPartyRiskTabs } from './components/ThirdPartyRiskTabs'
import {
  privacyControlsMockData,
  undisclosedSystemsMockData,
  unKnownTransfersMockData,
} from '../__mocks__/thirdPartyRisk'
import { useStyles } from '../components/layout/styles'
import { DataRiskHeaderNav } from '../components/layout/DataRiskHeaderNav'
import { useScrollToTopOnLoad } from 'utils/hooks/useScrollToTopOnLoad'
import { showClipboardToast } from 'pages/insights/utils'
import { relativePath } from 'utils/routing/relativePath'

type Props = {}

export const ThirdPartyRiskContainer: React.FC<Props> = () => {
  const location = useLocation()
  const classes = useStyles()

  useScrollToTopOnLoad()

  const breadcrumbs = [
    { title: 'Reports', link: RoutesManager.insights.root.getURL() },
    { title: 'Data Risks', link: RoutesManager.insights.dataRisks.root.getURL() },
    { title: 'Third Party Risk', link: RoutesManager.insights.dataRisks.thirdPartyRisk.root.getURL() },
  ]

  const emptyState = (
    <DetailsEmptyState
      title="Select a system"
      description="Check the panel on the left to look up and select the system to see the insights."
      icon={<AttributesIcon />}
    />
  )

  return (
    <ViewLayoutLeftMenu breadcrumbs={breadcrumbs}>
      <ViewLayoutHeaderLeftMenu
        title="Third Party Risk"
        titleComponent={<DataRiskHeaderNav />}
        details="Identify risks associated with privacy controls among your third party data systems."
        detailsClassName={classes.subtitle}
        backTo={RoutesManager.insights.dataRisks.root.getURL()}
        icon={<ThirdPartyRiskIcon />}
        actions={
          <Button color="tertiary" size="large" clipboardText={window.location.href} onClick={showClipboardToast}>
            Share
          </Button>
        }
      />

      <ThirdPartyRiskTabs />

      <Box display="flex" gap={1.5}>
        <Routes location={location}>
          <Route
            path={relativePath(
              RoutesManager.insights.dataRisks.thirdPartyRisk.privacyControls.root.path,
              RoutesManager.insights.dataRisks.thirdPartyRisk.root.path,
            )}
            element={
              <>
                <PrivacyControlsMenu rows={privacyControlsMockData.privacyControls} />
                <PrivacyControlsDetails
                  privacyControlsRecords={privacyControlsMockData.privacyControls}
                  emptyState={emptyState}
                />
              </>
            }
          />
          <Route
            path={relativePath(
              RoutesManager.insights.dataRisks.thirdPartyRisk.unknownTransfers.root.path,
              RoutesManager.insights.dataRisks.thirdPartyRisk.root.path,
            )}
            element={
              <>
                <UnknownTransfersMenu rows={unKnownTransfersMockData.unknownTransfers} />
                <UnknownTransfersDetails
                  unknownTransfersRecords={unKnownTransfersMockData.unknownTransfers}
                  emptyState={emptyState}
                />
              </>
            }
          />
          <Route
            path={relativePath(
              RoutesManager.insights.dataRisks.thirdPartyRisk.undisclosedSystems.root.path,
              RoutesManager.insights.dataRisks.thirdPartyRisk.root.path,
            )}
            element={
              <>
                <UndisclosedSystemsMenu rows={undisclosedSystemsMockData.undisclosedSystems} />
                <UndisclosedSystemsDetails
                  undisclosedSystemsRecords={undisclosedSystemsMockData.undisclosedSystems}
                  emptyState={emptyState}
                />
              </>
            }
          />
          <Route
            path={relativePath(
              RoutesManager.insights.dataRisks.thirdPartyRisk.root.path,
              RoutesManager.insights.dataRisks.thirdPartyRisk.root.path,
            )}
            element={
              <>
                <PrivacyControlsMenu rows={privacyControlsMockData.privacyControls} />
                {emptyState}
              </>
            }
          />
          {/* <Route to={RoutesManager.insights.dataRisks.thirdPartyRisk.root.getURL()} /> */}
        </Routes>
      </Box>
    </ViewLayoutLeftMenu>
  )
}
