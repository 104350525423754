import { Box, Typography } from '@mui/material'
import { FormInput } from 'components/form/FormInput'
import { RadioGroup } from 'components/RadioGroup/RadioGroup'
import { DeploymentMethods, useTransponderCreate } from '../../context'
import { showToast } from 'components/modals/AlertComponent'
import { ConfigItemDisplay } from 'pages/developers/transponders/components'
import { useGetArtifactoryCreds } from 'api/assets/mutations/useCreateArtifactoryCreds'
import { useEffect, useState } from 'react'

const ketchRepositoryCredentialErrorCopy = 'An error occured.  Please regenerate key'

export const TransponderCreateFormSection: React.FC = () => {
  const { deploymentMethod, setCode, handleDeploymentMethodChange } = useTransponderCreate()
  const [artifactoryKey, setArtifactoryKey] = useState({ password: '', username: '' })

  const {
    mutateAsync: handleArtifactoryKeyGeneration,
    isError: artifactoryError,
    isLoading,
  } = useGetArtifactoryCreds({
    onSuccess: ({ data }) => {
      setArtifactoryKey(data)
      showToast({ content: 'Ketch Repository key generated', type: 'success' })
    },
    onError: () => showToast({ content: 'Failed to generate Ketch Repository key', type: 'error' }),
  })
  useEffect(() => {
    handleArtifactoryKeyGeneration()
  }, [handleArtifactoryKeyGeneration])

  const handleArtifactoryRegenerate = () => {
    handleArtifactoryKeyGeneration()
  }

  return (
    <Box display="flex" flexDirection="column" gap={6} paddingY={3}>
      <Box display="flex" flexDirection="column" gap={3} width="66%">
        <Typography variant="h2">Basic Details</Typography>

        <Box>
          <Typography variant="label" paddingLeft={1.25}>
            Select Deployment Mode
          </Typography>

          <RadioGroup
            row
            name="deploymentMode"
            options={[
              {
                title: DeploymentMethods.helmChart,
                value: DeploymentMethods.helmChart,
              },
              {
                title: DeploymentMethods.awsElasticBeanstalk,
                value: DeploymentMethods.awsElasticBeanstalk,
              },
            ]}
            onChange={handleDeploymentMethodChange}
            value={deploymentMethod}
          />
        </Box>

        <ConfigItemDisplay
          alwaysVisible
          value={artifactoryError ? ketchRepositoryCredentialErrorCopy : artifactoryKey.username}
          error={artifactoryError}
          onRegenerate={handleArtifactoryRegenerate}
          name="Ketch Repository User Name"
          type="key"
          isLoading={isLoading}
        />

        <ConfigItemDisplay
          value={artifactoryError ? ketchRepositoryCredentialErrorCopy : artifactoryKey.password}
          error={artifactoryError}
          onRegenerate={handleArtifactoryRegenerate}
          name="Ketch Repository Key"
          type="key"
          isLoading={isLoading}
        />

        <FormInput
          fullWidth
          required
          shouldUpdateDebounced
          label="Transponder Name"
          formPropertyName="name"
          placeholder="Add name"
          onChange={e => {
            setCode(e.target.value)
          }}
          sx={{ marginTop: 3 }}
        />
      </Box>
    </Box>
  )
}
