import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces/common'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans/deploymentPlan'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { ExperienceDTO } from 'interfaces/experiences/experience'
import { PolicyDocumentDTO } from 'interfaces/policyDocuments/policyDocument'
import { ThemeDTO } from 'interfaces/themes/theme'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useValidationSchema, DeploymentPlanFormValues } from 'pages/consentAndRights/deploymentPlans/upsert/utils'
import { getInitialValues, DeploymentPlanFormStep } from 'pages/consentAndRights/deploymentPlans/upsert/create/utils'
import { RoutesManager } from 'utils/routing/routesManager'
import { CreateLayout } from 'components/ui-layouts/createLayout/CreateLayout'
import { CreateLayoutActions } from 'components/ui-layouts/createLayout/CreateLayoutActions'
import { CreateLayoutStepContent } from 'components/ui-layouts/createLayout/CreateLayoutStepContent'
import { Button } from '@ketch-com/deck'
import { Group } from 'components/ui-layouts/group/Group'
import { DeploymentPlanBasicDetails } from 'pages/consentAndRights/deploymentPlans/upsert/components/DeploymentPlanBasicDetails'
import { DeploymentPlanExperiences } from 'pages/consentAndRights/deploymentPlans/upsert/components/deploymentPlanExperiences/DeploymentPlanExperiences'
import { DeploymentPlanDocuments } from 'pages/consentAndRights/deploymentPlans/upsert/components/deploymentPlanDocuments/DeploymentPlanDocuments'

type Props = {
  isReady: boolean
  duplicate: boolean
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
  policyScopes: PolicyScopeDTO[]
  experiences: ExperienceDTO[]
  policyDocuments: PolicyDocumentDTO[]
  themes: ThemeDTO[]
  onSubmit: (values: DeploymentPlanFormValues) => Promise<void>
}

const steps = Object.values(DeploymentPlanFormStep)

const breadcrumbs = [
  { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
  { title: 'Deployment Plans', link: RoutesManager.deployment.deploymentPlans.root.getURL() },
  { title: 'Create New Plan' },
]

export const DeploymentPlanCreate: React.FC<Props> = ({
  isReady,
  duplicate,
  deploymentPlan,
  policyScopes,
  experiences,
  policyDocuments,
  themes,
  onSubmit,
}) => {
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()

  const isEntitledToContentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)

  const initialValues = useMemo(
    () => getInitialValues({ duplicate, deploymentPlan, policyScopes }),
    [duplicate, deploymentPlan, policyScopes],
  )

  const validationSchema = useValidationSchema({ isEntitledToContentAndDisclosure })

  return (
    <CreateLayout
      isReady={isReady}
      breadcrumbs={breadcrumbs}
      title="Create New Plan"
      initialValues={initialValues}
      validationSchema={validationSchema}
      steps={steps}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm }, { isLastStep, setNextStep }) => (
        <>
          <CreateLayoutStepContent step={DeploymentPlanFormStep.BASIC_DETAILS}>
            <DeploymentPlanBasicDetails themes={themes} />
          </CreateLayoutStepContent>

          <CreateLayoutStepContent step={DeploymentPlanFormStep.EXPERIENCES}>
            <DeploymentPlanExperiences policyScopes={policyScopes} experiences={experiences} />
          </CreateLayoutStepContent>

          <CreateLayoutStepContent step={DeploymentPlanFormStep.DOCUMENTS}>
            <DeploymentPlanDocuments policyDocuments={policyDocuments} editMode={false} />
          </CreateLayoutStepContent>

          <CreateLayoutActions>
            <Group>
              <Button
                color="secondary"
                size="large"
                pending={isSubmitting}
                onClick={() => {
                  navigate(RoutesManager.deployment.deploymentPlans.root.getURL())
                }}
              >
                Cancel
              </Button>

              <Button
                size="large"
                pending={isSubmitting}
                onClick={() => {
                  if (isLastStep) {
                    submitForm()
                  } else {
                    setNextStep()
                  }
                }}
              >
                {isLastStep ? 'Save & Finish' : 'Next'}
              </Button>
            </Group>
          </CreateLayoutActions>
        </>
      )}
    </CreateLayout>
  )
}
