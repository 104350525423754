import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { deleteBusinessFunction } from '../fetchers/deleteBusinessFunction'

const useCustomMutation = createUseMutation({
  mutationFn: deleteBusinessFunction,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeleteBusinessFunction = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
