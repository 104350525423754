import { Icon } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { getIn } from 'formik'
import { clamp } from 'lodash'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { BUILDER_CONSTANTS } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { useContext } from 'react'

const RequestsFormHeaderBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
})

const ReturnButtonBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 30,
  height: 30,
})

export const RequestsFormHeader: React.FC = () => {
  const { themeConfig } = useContext(BuilderContext)

  // Theme config
  const titleColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormHeaderTitleColor)
  const backButtonBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabFormHeaderReturnButtonBackgroundColor,
  )
  const backButtonIconColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabFormHeaderReturnButtonIconColor,
  )
  const backButtonCornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormHeaderReturnButtonCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'

  return (
    <RequestsFormHeaderBox>
      <ReturnButtonBox sx={{ background: backButtonBackgroundColor, borderRadius: backButtonCornerRadius }}>
        <Icon name={'OArrowLeft'} iconColor={backButtonIconColor} />
      </ReturnButtonBox>
      <Typography fontSize={28} fontWeight={600} color={titleColor}>
        Access Data
      </Typography>
    </RequestsFormHeaderBox>
  )
}
