import React from 'react'
import { useLocation } from 'react-router-dom'

import { MaybeNull } from 'interfaces/common'
import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { ViewLayout } from 'components/ui-layouts/viewLayout/ViewLayout'
import { ViewLayoutHeader } from 'components/ui-layouts/viewLayout/ViewLayoutHeader'
import { ViewLayoutContent } from 'components/ui-layouts/viewLayout/ViewLayoutContent'
import { ThemeViewActions } from 'pages/consentAndRights/themes/view/components/ThemeViewActions'
import { ThemeViewDetails } from 'pages/consentAndRights/themes/view/components/ThemeViewDetails'
import { ThemeViewRoutes } from 'pages/consentAndRights/themes/view/ThemeViewRoutes'
import { ThemeVersionsMessage } from 'pages/consentAndRights/themes/view/components/ThemeVersionsMessage'

type Props = {
  isReady: boolean
  theme: MaybeNull<ThemeDTO>
  versions: ThemeDTO[]
}

export const ThemeView: React.FC<Props> = ({ isReady, theme, versions }) => {
  const { search } = useLocation()
  const { queries } = useQueryParams<{ version?: string }>()

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Themes', link: RoutesManager.deployment.themes.root.getURL() },
    { title: theme?.name || theme?.code },
  ]

  if (!isReady || !theme) {
    return <ViewLayout isReady={false} breadcrumbs={breadcrumbs} />
  }

  const { code, name } = theme
  const isLatest = !queries.version || !!theme.latest

  return (
    <ViewLayout
      isReady={isReady}
      breadcrumbs={breadcrumbs}
      versionsMessage={!isLatest && <ThemeVersionsMessage theme={theme} />}
    >
      <ViewLayoutHeader
        title={name || code}
        backTo={RoutesManager.deployment.themes.root.getURL()}
        details={<ThemeViewDetails theme={theme} />}
        actions={isLatest && <ThemeViewActions theme={theme} />}
      />

      <ViewLayoutContent
        tabs={[
          {
            title: 'Details',
            link: {
              pathname: `${RoutesManager.deployment.themes.view.details.root.getURL({ code })}`,
              search,
            },
          },
          {
            title: 'Versions',
            link: {
              pathname: `${RoutesManager.deployment.themes.view.versions.root.getURL({ code })}`,
              search,
            },
          },
        ]}
      >
        <ThemeViewRoutes theme={theme} versions={versions} />
      </ViewLayoutContent>
    </ViewLayout>
  )
}
