import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { ExperienceDTO } from 'interfaces/experiences/experience'
import { useDeleteExperience } from 'api/experiences/mutations/useDeleteExperience'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { Text } from 'components/ui-kit/typography/Text'
import { showToast } from 'components/ui-kit/toastr/Toastr'

type Props = {
  experience: ExperienceDTO
  onClose: () => void
  onDelete?: () => any
}

export const ExperienceDeleteModal: React.FC<Props> = ({ experience, onClose, onDelete = onClose }) => {
  const queryClient = useQueryClient()

  const { name, code } = experience
  const [isBusy, setIsBusy] = useState(false)

  const { mutate: handleDeleteExperience } = useDeleteExperience({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onDelete?.()
      showToast({ content: 'Experience deleted', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to delete experience', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <Dialog
      isSaving={isBusy}
      title="Delete Experience"
      submitBtnLabel="Delete"
      cancelBtnLabel="Close"
      onSubmitBtnClick={() => {
        handleDeleteExperience({
          params: {
            experienceCode: code!,
          },
        })
      }}
      onCancelBtnClick={onClose}
    >
      <Text size={14}>
        Are you sure you want to delete <strong>{name || code}</strong> experience?
      </Text>
    </Dialog>
  )
}
