import { APIListRequestParams } from 'api/common/utils'
import { GetDmlHookResponseBodyDTO } from '@ketch-com/figurehead'

import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  hookId?: string
}>

export const fetchDmlHook = ({ hookId }: Params) =>
  API.get<GetDmlHookResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/dml-hooks/${hookId}`,
      params: {
        filters: {},
      },
    }),
  )
