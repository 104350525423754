import React, { HTMLAttributes } from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

import PriorityHighSharpIcon from '@mui/icons-material/PriorityHighSharp'
import Box from '@mui/material/Box'
import ClearSharpIcon from '@mui/icons-material/ClearSharp'
import CheckSharpIcon from '@mui/icons-material/CheckSharp'
import { ReactComponent as ChatAlertIcon } from 'assets/icons/chat_alert.svg'
import { SxProps, Theme } from '@mui/material'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseBanner: {
        width: '100%',
        boxSizing: 'border-box',
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 11,
        padding: '11px 16px',
        fontFamily: typography.fontFamily,
        fontSize: typography.pxToRem(12),
        lineHeight: typography.pxToRem(17),
        fontWeight: 500,
        letterSpacing: '0.01em',
      },
      baseIcon: {
        fontSize: typography.pxToRem(16),
        flexShrink: 0,
        marginRight: 10,
      },
      baseContent: {
        flexGrow: 1,
        marginLeft: 2,
      },
      baseActions: {
        paddingLeft: 16,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      baseAction: {
        cursor: 'pointer',

        '&:hover': {
          opacity: 0.8,
        },

        '&:not(:first-child)': {
          marginLeft: 16,
        },
      },

      // Types:
      typeSuccess: {
        border: `1px solid ${palette.greenHaze.main}`,
        background: palette.tara.main,
        color: palette.greenHaze.main,

        '& $baseIcon': {
          color: palette.greenHaze.main,
        },
        '& $baseActions': {
          borderLeft: `1px solid ${palette.greenHaze.main}`,
        },
      },
      typeFailure: {
        border: `1px solid ${palette.red.main}`,
        background: palette.lightRed.main,
        color: palette.red.main,

        '& $baseIcon': {
          color: palette.red.main,
        },
        '& $baseActions': {
          borderLeft: `1px solid ${palette.red.main}`,
        },
      },
      typeWarning: {
        border: `1px solid ${palette.chileanFire.main}`,
        background: palette.bleachWhite.main,
        color: palette.chileanFire.main,

        '& $baseIcon': {
          color: palette.chileanFire.main,
        },
        '& $baseActions': {
          borderLeft: `1px solid ${palette.chileanFire.main}`,
        },
      },
      typeNeutral: {
        border: `1px solid ${palette.white.main}`,
        background: palette.darkDusk.main,
        color: palette.white.main,

        '& $baseActions': {
          borderLeft: `1px solid ${palette.white.main}`,
        },
      },

      typeInfo: {
        border: 'none',
        background: palette.fadedHeliotrope.main,
        color: palette.pink.main,
      },
    }),
  { name: 'Banner' },
)

type Action = {
  title: React.ReactNode
  onClick: () => void
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  /** Component variant - reflects the colour of border and background */
  variant?: 'success' | 'failure' | 'warning' | 'neutral' | 'info'
  /** Component actions list */
  actions?: Action[]
  /** Component custom className */
  className?: string
  /** Container style overrides */
  containerSx?: SxProps<Theme>
}

export type BannerProps = Props

/**
 * -
 */
export const Banner: React.FC<Props> = ({
  containerSx,
  variant = 'success',
  className,
  actions = [],
  children,
  ...rest
}) => {
  const classes = useStyles()

  const componentClassName = clsx(
    classes.baseBanner,
    {
      [classes.typeSuccess]: variant === 'success',
      [classes.typeFailure]: variant === 'failure',
      [classes.typeWarning]: variant === 'warning',
      [classes.typeNeutral]: variant === 'neutral',
      [classes.typeInfo]: variant === 'info',
    },
    className,
  )

  return (
    <Box {...rest} className={componentClassName} sx={containerSx}>
      {variant === 'success' && <CheckSharpIcon className={classes.baseIcon} />}
      {variant === 'failure' && <ClearSharpIcon className={classes.baseIcon} />}
      {variant === 'warning' && <PriorityHighSharpIcon className={classes.baseIcon} />}
      {variant === 'info' && <ChatAlertIcon className={classes.baseIcon} />}

      <Box className={classes.baseContent}>{children}</Box>

      {!!actions.length && (
        <Box className={classes.baseActions}>
          {actions.map((action, index) => (
            <Box key={index} className={classes.baseAction} onClick={action.onClick}>
              {action.title}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
