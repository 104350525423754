import * as Yup from 'yup'

interface IValidationParams {
  usedCodes?: Array<string>
}

export const getApprovalValidationSchema = (params: IValidationParams) =>
  Yup.object().shape({
    code: Yup.string()
      .notOneOf(params?.usedCodes || [], 'This code is already in use')
      .required('This field is required'),
    description: Yup.string().required('Description is required'),
    approver: Yup.string().required('Please select approver'),
    details: Yup.string().required('Please input details'),
    activity: Yup.string().required('Please select Activity / Workflow'),
    timeToCompleteDays: Yup.number()
      .integer()
      .moreThan(-1, 'Number of days must be a whole number less than 365, or 0 to not require a due date.')
      .lessThan(365, 'Number of days must be a whole number less than 365, or 0 to not require a due date.'),
  })
