import React from 'react'
import { styled } from '@mui/material/styles'
import numeral from 'numeral'
import clsx from 'clsx'
import { Bar, ComposedChart, Tooltip, XAxis, YAxis } from 'recharts'
import { useTranslation } from 'react-i18next'

import { RightStatsPeriodDTO, ChartTrendPeriod } from 'interfaces/rights/rightStats'
import { MaybeNull, ObjectLiteral } from 'interfaces'
import { formatChartDate, getInterpolatedRightsPeriod } from 'pages/insights/utils'
import { COMMON, RIGHTS_STATISTICS } from 'i18n/types'
import { useQueryParams } from 'utils/hooks'
import moment from 'moment'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { ChartDescriptionSection } from './RightsTrendQueueFlowChart/components'

const PREFIX = 'RightsTrendQueueChart'

const classes = {
  container: `${PREFIX}-container`,
  headerTitle: `${PREFIX}-headerTitle`,
  headerSubTitle: `${PREFIX}-headerSubTitle`,
  legend: `${PREFIX}-legend`,
  legendIcon: `${PREFIX}-legendIcon`,
  legendIconSquare: `${PREFIX}-legendIconSquare`,
  legendIconLine: `${PREFIX}-legendIconLine`,
  legendIconDashedLine: `${PREFIX}-legendIconDashedLine`,
  legendText: `${PREFIX}-legendText`,
  legendItem: `${PREFIX}-legendItem`,
  content: `${PREFIX}-content`,
  chartWrapper: `${PREFIX}-chartWrapper`,
  chartDescriptionHeader: `${PREFIX}-chartDescriptionHeader`,
  chartDescription: `${PREFIX}-chartDescription`,
  chartDescriptionSection: `${PREFIX}-chartDescriptionSection`,
  chartDescriptionRow: `${PREFIX}-chartDescriptionRow`,
  chartTooltip: `${PREFIX}-chartTooltip`,
  chartTooltipHeaderRow: `${PREFIX}-chartTooltipHeaderRow`,
  charTooltipRow: `${PREFIX}-charTooltipRow`,
}

const Root = styled('div')(({ theme: { palette } }) => ({
  [`& .${classes.container}`]: {
    width: 580,
  },

  [`& .${classes.legend}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: 22,
    marginTop: 32,
  },

  [`& .${classes.legendIcon}`]: {
    marginRight: 10,
  },

  [`& .${classes.legendIconSquare}`]: {
    width: 16,
    height: 16,
    borderRadius: 2,
  },

  [`& .${classes.legendIconLine}`]: {
    width: 16,
    height: 1,
    borderBottom: '2px solid',
  },

  [`& .${classes.legendIconDashedLine}`]: {
    width: 16,
    height: 1,
    borderBottom: '2px dashed',
  },

  [`& .${classes.legendText}`]: {},

  [`& .${classes.legendItem}`]: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 32,
  },

  [`& .${classes.content}`]: {
    marginTop: 20,
  },

  [`& .${classes.chartWrapper}`]: {},

  [`& .${classes.chartDescriptionHeader}`]: {
    display: 'block',
    marginTop: 32,
  },

  [`& .${classes.chartDescription}`]: {
    display: 'flex',
    marginTop: 16,
  },

  [`& .${classes.chartDescriptionSection}`]: {
    marginRight: 40,
  },

  [`& .${classes.chartDescriptionRow}`]: {
    display: 'block',
    marginBottom: 4,
  },

  [`&.${classes.chartTooltip}`]: {
    background: palette.darkDusk.main,
    borderRadius: 5,
    color: palette.white.main,
    fontSize: 12,
    fontWeight: 500,
    padding: '8px 10px',
    minWidth: 175,
  },

  [`& .${classes.chartTooltipHeaderRow}`]: {
    display: 'block',
    marginBottom: 12,
  },

  [`& .${classes.charTooltipRow}`]: {
    display: 'block',
    marginBottom: 2,
  },
}))

type Props = {
  trendPeriod: ChartTrendPeriod
  rightsStatsPeriod: MaybeNull<RightStatsPeriodDTO>
}

type TooltipProps = {
  active?: boolean
  payload: any
  label: string
}

const RightsTrendQueueChartTooltip = (props: TooltipProps) => {
  const { t } = useTranslation([RIGHTS_STATISTICS, COMMON])
  const { active, label, payload } = props

  const { queries } = useQueryParams<{
    trendPeriod?: ChartTrendPeriod
  }>()

  const labelOverride = !![ChartTrendPeriod.MONTHLY_PAST_CAL_YEAR, ChartTrendPeriod.MONTHLY_PAST_TWELVE].includes(
    queries?.trendPeriod!,
  )
    ? moment(payload?.[0]?.payload?.date).format('MMMM')
    : label

  if (active && payload && payload.length) {
    const urgentValue = payload.find((item: ObjectLiteral) => item.dataKey === 'urgent')?.value || 0
    const dueSoonValue = Math.abs(payload.find((item: ObjectLiteral) => item.dataKey === 'dueSoon')?.value || 0)
    const onTrackValue = Math.abs(payload.find((item: ObjectLiteral) => item.dataKey === 'onTrack')?.value || 0)

    return (
      <Root className={classes.chartTooltip}>
        <div className={classes.chartTooltipHeaderRow}>
          <div className={classes.charTooltipRow}>{labelOverride}</div>
        </div>

        <div className={classes.charTooltipRow}>
          {t('Urgent')}: {urgentValue}
        </div>
        <div className={classes.charTooltipRow}>
          {t('Due Soon')}: {dueSoonValue}
        </div>
        <div className={classes.charTooltipRow}>
          {t('On Track')}: {onTrackValue}
        </div>
      </Root>
    )
  }

  return null
}

export const RightsTrendQueueChart: React.FC<Props> = ({ rightsStatsPeriod, trendPeriod }) => {
  const { t } = useTranslation([RIGHTS_STATISTICS, COMMON])

  const rightsStatsPeriodWithInterpolatedDataPoints = getInterpolatedRightsPeriod({
    rightsStatsPeriod,
    trendPeriod,
  })

  const data =
    rightsStatsPeriodWithInterpolatedDataPoints?.dataPoints?.map(point => ({
      ...point,
      name: formatChartDate(trendPeriod, point?.date || ''),
      urgent: point.pending,
      dueSoon: point.fulfilledSevenDays,
      onTrack: point.onTrack,
    })) || []

  return (
    <Root className={classes.container}>
      <Typography variant="h3">{t('Queue')}</Typography>

      <Typography color="grey">{t('How are stats changing over time?')}</Typography>

      <div className={classes.legend}>
        <div className={classes.legendItem}>
          <div className={clsx(classes.legendIcon, classes.legendIconSquare)} style={{ backgroundColor: '#EF6160' }} />
          <Typography variant="smallLabel" className={classes.legendText}>
            {t('Urgent')}
          </Typography>
        </div>
        <div className={classes.legendItem}>
          <div className={clsx(classes.legendIcon, classes.legendIconSquare)} style={{ backgroundColor: '#FC9F72' }} />
          <Typography variant="smallLabel" className={classes.legendText}>
            {t('Due Soon')}
          </Typography>
        </div>
        <div className={classes.legendItem}>
          <div className={clsx(classes.legendIcon, classes.legendIconSquare)} style={{ backgroundColor: '#FCE9A4' }} />
          <Typography variant="smallLabel" className={classes.legendText}>
            {t('On Track')}
          </Typography>
        </div>
      </div>

      <div className={classes.content}>
        <div className={classes.chartWrapper}>
          <ComposedChart
            width={580}
            height={240}
            data={data}
            margin={{
              top: 10,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            barSize={40}
          >
            <XAxis tickLine={false} padding={{ left: 30, right: 30 }} dataKey="name" />
            <YAxis tickLine={false} tickFormatter={value => `${numeral(value).format('0')}`} />

            <Bar dataKey="urgent" stackId="bars" fill="#EF6160" />
            <Bar dataKey="dueSoon" stackId="bars" fill="#FC9F72" />
            <Bar dataKey="onTrack" stackId="bars" fill="#FCE9A4" />

            <Tooltip
              content={props => (
                <RightsTrendQueueChartTooltip active={props.active} label={props.label} payload={props.payload} />
              )}
            />
          </ComposedChart>
        </div>

        <Typography variant="label" className={classes.chartDescriptionHeader}>
          {t('View Average')}
        </Typography>

        <Box display="flex" mt={2.25}>
          <ChartDescriptionSection mainText={String(rightsStatsPeriod?.avgPending || 0)} subText={t('Pending')} />
          <ChartDescriptionSection mainText={String(rightsStatsPeriod?.avgOnTrack || 0)} subText={t('On Track')} />
          <ChartDescriptionSection mainText={String(rightsStatsPeriod?.avgDueSoon || 0)} subText={t('Due Soon')} />
          <ChartDescriptionSection mainText={String(rightsStatsPeriod?.avgUrgent || 0)} subText={t('Urgent')} />
        </Box>
      </div>
    </Root>
  )
}
