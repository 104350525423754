import { useEffect, useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getPaginationState, getRootPathString } from 'store/paginationPreservationSlice/selectors'
import { setPage as setPagePreserveState } from 'store/paginationPreservationSlice'
import { Pagination } from './paginatedQuery'
import { useLocation } from 'react-router-dom'

type UsePaginatedQueryPreservedPageArgs = {
  queryKey: string
  params: any
  totalPages: number
}

const usePagePreservation = ({ queryKey, params, totalPages }: UsePaginatedQueryPreservedPageArgs) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { pagination: reduxPaginationState } = useAppSelector(getPaginationState)
  const reduxRootPathString = useAppSelector(getRootPathString)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reduxPaginationStateKey = useMemo(() => JSON.stringify([queryKey, { ...params }]), [params])
  const reduxPage = reduxPaginationState?.[reduxPaginationStateKey] || 0

  useEffect(() => {
    dispatch(setPagePreserveState({ key: reduxPaginationStateKey, value: reduxPage }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxPaginationStateKey])

  useEffect(() => {
    if (reduxPage >= 0 && totalPages > 0 && reduxPage > totalPages - 1) {
      dispatch(setPagePreserveState({ key: reduxPaginationStateKey, value: totalPages - 1 }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxPage, totalPages])

  useEffect(() => {
    const hasRootPathChanged = async () => {
      const rootPathString = [location.pathname.split('/')?.[1], location.pathname.split('/')?.[2]].join('/')
      if (reduxRootPathString !== rootPathString) {
        dispatch(setPagePreserveState({ key: reduxPaginationStateKey, value: 0 }))
      }
    }
    hasRootPathChanged()
  }, [location, reduxRootPathString, dispatch, reduxPaginationStateKey])
}

export function createUsePaginatedQueryPreservedPage<QueryFnData, QueryFnParams, QuerySelectorData>({
  queryKey,
  queryFn,
  select,
  enabled = true,
}: {
  queryKey: string
  queryFn: (params: QueryFnParams) => Promise<QueryFnData>
  select: (res?: QueryFnData) => QuerySelectorData
  enabled?: boolean
}) {
  return function useCustomPaginatedQueryPreservedPage({
    params,
    itemsPerPage = 20,
    ...queryOptions
  }: { params: QueryFnParams; itemsPerPage?: number } & Omit<
    UseQueryOptions<QueryFnData, unknown>,
    'queryKey' | 'queryFn' | 'select' | 'keepPreviousData'
  >) {
    const dispatch = useAppDispatch()
    const { pagination: reduxPaginationState } = useAppSelector(getPaginationState)
    const reduxPaginationStateKey = useMemo(() => JSON.stringify([queryKey, { ...params }]), [params])
    const reduxPage = reduxPaginationState?.[reduxPaginationStateKey] || 0

    const paginatedParams = {
      ...params,
      start: reduxPage * itemsPerPage,
      limit: itemsPerPage,
    }

    const { data, isFetching, ...rest } = useQuery([queryKey, paginatedParams], () => queryFn(paginatedParams), {
      enabled,
      keepPreviousData: true,
      ...(queryOptions as any),
      refetchInterval: 0,
    })

    const totalPages = (data as any)?.data?.total_results
      ? Math.ceil(+((data as any)?.data?.total_results || 0) / itemsPerPage)
      : Math.ceil(+((data as any)?.data?.totalResults || 0) / itemsPerPage)

    usePagePreservation({ queryKey, params, totalPages })

    const pagination: Pagination = {
      isFirst: reduxPage === 0,
      isLast: reduxPage === totalPages - 1,
      page: reduxPage,
      totalPages,
      onPageChange: nextPage => {
        dispatch(setPagePreserveState({ key: reduxPaginationStateKey, value: nextPage }))
      },
      isPending: isFetching,
    }

    return { ...rest, isFetching, pagination, data: useMemo(() => select(data), [data]) }
  }
}
