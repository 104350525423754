import { BuilderImageVariant } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'
import { ImageContainer } from '../../../../ImageContainer'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { useContext } from 'react'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { SectionEmptyState } from '../../../../../../SectionEmptyState'

export const RequestsSubmittedBanner: React.FC = () => {
  const { themeConfig, isTheme } = useContext(BuilderContext)

  const bannerVisible = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabSubmittedBannerVisible)
  const summaryBannerUrl = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabSubmittedBannerImageUrl)

  return bannerVisible ? (
    <ImageContainer variant={BuilderImageVariant.SummaryBanner} imageUrl={summaryBannerUrl} />
  ) : isTheme ? (
    <SectionEmptyState section="Banner" />
  ) : null
}
