import { API } from 'utils/api/api'
import { APIRequestParams } from 'api/common/utils'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { PutInstalledDataSystemV2ResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  id: string
  formData: any
}>

export const updateInstalledDataSystem = ({ id, formData }: Params) =>
  API.put<PutInstalledDataSystemV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/installed-data-systems/${id}`,
    }),
    formData,
  )
