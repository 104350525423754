import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchWorkflowExecutionStepDetailsV2 } from 'api/workflowExecutions/fetchers/fetchWorkflowExecutionStepDetailsV2'
import { ApiQueryKeys } from 'api/common/queryKeys'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.workflowExecutionStepDetailsV2,
  queryFn: fetchWorkflowExecutionStepDetailsV2,
  select: res => res?.data?.stepDetails || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useWorkflowExecutionStepDetailsV2 = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
