import { Typography } from '@mui/material'
import { Button, theme } from '@ketch-com/deck'
import React, { useContext } from 'react'
import { styled } from '@mui/styles'
import Box from '@mui/material/Box'
import { ThemeUpsertContext } from '../../../context/ThemeUpsertContext'
import { ThemeBuilderSectionLabels } from '../../../../../experiences-v2/upsert/components/builder/utils/labels'
import {
  BannerThemeBuilderSection,
  ModalThemeBuilderSection,
  PreferenceThemeBuilderSection,
  ThemeBuilderSection,
} from '../../../../../experiences-v2/upsert/components/builder/utils/enums'
import { themeUpsertFormKeys } from '../../../utils/useThemeValidationSchema'
import { getIn, useFormikContext } from 'formik'
import { ThemeConfig } from '@ketch-sdk/ketch-types'
import { defaultThemeV3DTO } from '../../../utils/defaults'

// Mapping of builder sections to the form values which should be reset when "Reset to Defaults" is clicked
const resetMapping: { [section: string]: string[] } = {
  [BannerThemeBuilderSection.Container]: [themeUpsertFormKeys.resetBannerContainer],
  [BannerThemeBuilderSection.Header]: [themeUpsertFormKeys.resetBannerHeader],
  [BannerThemeBuilderSection.Description]: [themeUpsertFormKeys.resetBannerDescription],
  [BannerThemeBuilderSection.ActionButtons]: [themeUpsertFormKeys.resetBannerButtons],
  [BannerThemeBuilderSection.ActionButtonsSecondary]: [themeUpsertFormKeys.resetBannerButtons],
  [BannerThemeBuilderSection.ActionButtonsTertiary]: [themeUpsertFormKeys.resetBannerButtons],
  [BannerThemeBuilderSection.Footer]: [themeUpsertFormKeys.bannerFooterKetchBadgeVisible],

  [ModalThemeBuilderSection.Container]: [themeUpsertFormKeys.resetModalContainer],
  [ModalThemeBuilderSection.Header]: [themeUpsertFormKeys.resetModalHeader],
  [ModalThemeBuilderSection.Description]: [themeUpsertFormKeys.resetModalDescription],
  [ModalThemeBuilderSection.ListHeader]: [themeUpsertFormKeys.resetModalListHeader],
  [ModalThemeBuilderSection.ListHeaderAcceptAll]: [themeUpsertFormKeys.resetModalListHeader],
  [ModalThemeBuilderSection.ListHeaderRejectAll]: [themeUpsertFormKeys.resetModalListHeader],
  [ModalThemeBuilderSection.PurposeList]: [themeUpsertFormKeys.resetModalPurposeList],
  [ModalThemeBuilderSection.Footer]: [themeUpsertFormKeys.resetModalFooter],

  [PreferenceThemeBuilderSection.WelcomeTabContainer]: [themeUpsertFormKeys.resetPreferenceWelcomeTabContainer],
  [PreferenceThemeBuilderSection.WelcomeTabHeader]: [themeUpsertFormKeys.resetPreferenceWelcomeTabHeader],
  [PreferenceThemeBuilderSection.WelcomeTabNavigation]: [themeUpsertFormKeys.resetPreferenceWelcomeTabNavigation],
  [PreferenceThemeBuilderSection.WelcomeTabExitButton]: [
    themeUpsertFormKeys.resetPreferenceWelcomeTabExitButton,
    themeUpsertFormKeys.preferenceWelcomeTabContainerExitButtonPosition,
  ],
  [PreferenceThemeBuilderSection.WelcomeTabHeroBanner]: [themeUpsertFormKeys.resetPreferenceWelcomeTabHeroBanner],
  [PreferenceThemeBuilderSection.WelcomeTabWelcomeMessage]: [themeUpsertFormKeys.resetPreferenceWelcomeTabWelcomeMsg],
  [PreferenceThemeBuilderSection.WelcomeTabQuickLinks]: [themeUpsertFormKeys.resetPreferenceWelcomeTabQuickLinks],
  [PreferenceThemeBuilderSection.WelcomeTabAbout]: [themeUpsertFormKeys.resetPreferenceWelcomeTabAbout],
  [PreferenceThemeBuilderSection.WelcomeTabKetchBadge]: [themeUpsertFormKeys.preferenceWelcomeTabFooterShowKetchBadge],
  [PreferenceThemeBuilderSection.PurposesTabHeader]: [themeUpsertFormKeys.resetPreferencePurposesTabHeader],
  [PreferenceThemeBuilderSection.PurposesTabListHeader]: [themeUpsertFormKeys.resetPreferencePurposesTabListHeader],
  [PreferenceThemeBuilderSection.PurposesTabListHeaderAcceptAll]: [
    themeUpsertFormKeys.resetPreferencePurposesTabListHeader,
  ],
  [PreferenceThemeBuilderSection.PurposesTabListHeaderRejectAll]: [
    themeUpsertFormKeys.resetPreferencePurposesTabListHeader,
  ],
  [PreferenceThemeBuilderSection.PurposesTabList]: [themeUpsertFormKeys.resetPreferencePurposesTabList],
  [PreferenceThemeBuilderSection.PurposesTabFooter]: [themeUpsertFormKeys.resetPreferencePurposesTabFooter],
  [PreferenceThemeBuilderSection.SubscriptionsTabHeader]: [themeUpsertFormKeys.resetPreferenceSubscriptionsTabHeader],
  [PreferenceThemeBuilderSection.SubscriptionsTabUnsubscribeAll]: [
    themeUpsertFormKeys.resetPreferenceSubscriptionsTabUnsubscribeAll,
  ],
  [PreferenceThemeBuilderSection.SubscriptionsTabList]: [themeUpsertFormKeys.resetPreferenceSubscriptionsTabList],
  [PreferenceThemeBuilderSection.SubscriptionsTabFooter]: [themeUpsertFormKeys.resetPreferenceSubscriptionsTabFooter],
  [PreferenceThemeBuilderSection.RequestsHomeHeader]: [themeUpsertFormKeys.resetPreferenceRequestsTabHomeHeader],
  [PreferenceThemeBuilderSection.RequestsHomeDsrLink]: [themeUpsertFormKeys.resetPreferenceRequestsTabHomeDsrLink],
  [PreferenceThemeBuilderSection.RequestsHomeRightsList]: [
    themeUpsertFormKeys.resetPreferenceRequestsTabHomeRightsList,
  ],
  [PreferenceThemeBuilderSection.RequestsFormHeader]: [themeUpsertFormKeys.resetPreferenceRequestsTabFormHeader],
  [PreferenceThemeBuilderSection.RequestsFormContent]: [themeUpsertFormKeys.resetPreferenceRequestsTabFormContent],
  [PreferenceThemeBuilderSection.RequestsFormContentFields]: [
    themeUpsertFormKeys.resetPreferenceRequestsTabFormContent,
  ],
  [PreferenceThemeBuilderSection.RequestsFormActionButton]: [
    themeUpsertFormKeys.resetPreferenceRequestsTabFormActionButton,
  ],
  [PreferenceThemeBuilderSection.RequestsSubmittedHeader]: [
    themeUpsertFormKeys.resetPreferenceRequestsTabSubmittedHeader,
  ],
  [PreferenceThemeBuilderSection.RequestsSubmittedBanner]: [
    themeUpsertFormKeys.resetPreferenceRequestsTabSubmittedBanner,
  ],
  [PreferenceThemeBuilderSection.RequestsSubmittedText]: [themeUpsertFormKeys.resetPreferenceRequestsTabSubmittedText],
  [PreferenceThemeBuilderSection.RequestsSubmittedFooter]: [
    themeUpsertFormKeys.resetPreferenceRequestsTabSubmittedFooter,
  ],
}

export const hideBorderSections: ThemeBuilderSection[] = [
  BannerThemeBuilderSection.ActionButtons,
  PreferenceThemeBuilderSection.RequestsFormContent,
]

const SidebarHeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

export const SidebarHeader: React.FC = () => {
  const { themeBuilderSection } = useContext(ThemeUpsertContext)

  const { setFieldValue } = useFormikContext<ThemeConfig>()

  // Reset this section to the default values
  const handleResetClick = () => {
    if (themeBuilderSection) {
      resetMapping[themeBuilderSection].forEach(field => setFieldValue(field, getIn(defaultThemeV3DTO, field)))
    }
  }

  return (
    <SidebarHeaderBox
      sx={
        themeBuilderSection && hideBorderSections.includes(themeBuilderSection)
          ? {}
          : {
              borderBottom: `1px solid ${theme.palette.iron.main}`,
              paddingBottom: '16px',
            }
      }
    >
      <Typography variant={'h3'}>
        {themeBuilderSection ? ThemeBuilderSectionLabels[themeBuilderSection] : 'Section'}
      </Typography>
      <Button
        onClick={handleResetClick}
        variant={'text'}
        sx={{ marginLeft: 'auto', ':hover': { textDecoration: 'none' } }}
      >
        <Typography variant={'labelLink'} color={theme.palette.sphere.main}>
          Reset to Defaults
        </Typography>
      </Button>
    </SidebarHeaderBox>
  )
}
