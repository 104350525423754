import { makeStyles, createStyles } from '@mui/styles'

export const useStylesMobile = makeStyles(
  ({ typography, palette, spacing }) =>
    createStyles({
      container: {
        flex: 1,
        height: '100vh',
        background: palette.white.main,
        overflowY: 'scroll',
      },
      logo: {
        maxWidth: '175px',
      },
      loginLink: {
        marginRight: spacing(6),
        width: 'auto',
        fontSize: typography.pxToRem(14),
        color: palette.sphere.main,
      },
      leftArrow: {
        marginRight: spacing(1.5),
      },
      fullWidth: {
        width: '100%',
      },
    }),
  { name: 'Register' },
)
