import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

type Params = APIListRequestParams<{
  assetId: string
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchAssetSummaryDynamic = ({ assetId, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/assets/${assetId}`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/assets/${assetId}`

  return API.get<AssetSummaryDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {},
      },
    }),
  )
}
