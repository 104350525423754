import { IApiCallActivityAltParams, ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'

interface IGetApiCallInitialValuesParams {
  step: ICanvasStep
}

export const getApiCallInitialValues = (params: IGetApiCallInitialValuesParams): IApiCallActivityAltParams => {
  const { step } = params

  if (!!step.code) {
    return {
      code: step.code || '',
      description: step.description || '',
      webhookId: step.workflow?.params?.webhookId || '',
      requestTimeout: step.workflow?.params?.requestTimeout || '',
      assignee: step?.workflow?.params?.assignee || '',
      timeToCompleteDays: step.workflow?.params?.timeToCompleteDays || undefined,
    }
  }

  return {
    code: '',
    description: '',
    webhookId: '',
    requestTimeout: '',
    assignee: '',
    timeToCompleteDays: undefined,
  }
}
