import React, { useEffect, useState } from 'react'

// api
import { useNavigate } from 'react-router-dom'
import { useUsers } from 'api/users/queries/useUsers'
import { useReassignStep } from 'pages/orchestration/rightsQueue/viewV2/components/RightsQueueWorkflowTab/components/reassignAutomatedStep/useReassignStep'

// components
import { Chip, Icon, InfoRow } from '@ketch-com/deck'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { UserAvatar } from 'components/avatar/UserAvatar'

// types
import { MemberShortDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { AssigneeOrTeamSelector } from 'components/AssigneeSelector/AssigneeOrTeamSelector'
import { AssigneeFormatDelimter, TeamPrefix, fromAssignee, toAssignee } from 'utils/helpers/teamStringParser'
import { useTeam } from 'api/teams/queries/useTeam'
import { useTeamDefaultAssignee } from 'api/teams/queries/useTeamDefaultAssignee'
import { useAuth } from 'utils/hooks'
import { TeamChip } from 'components/chip/TeamChip'
import { UserChip } from 'components/chip/UserChip'

// types
type Props = {
  assigneeInfo: MemberShortDTO | null
  assignee?: string
  canEdit?: boolean
  workflowExecutionId?: string
  workflowStepId?: string
  infoRowTitle: string
  isError?: boolean
  onHandleSubmit?: () => void
  isComplete?: boolean
}

export const InfoRowAssignUser: React.FC<Props> = ({
  assigneeInfo,
  assignee,
  canEdit = false,
  workflowExecutionId,
  workflowStepId,
  infoRowTitle,
  isError,
  onHandleSubmit,
  isComplete = false,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [assigneeId, setAssigneeId] = useState(assignee || '')
  const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>()
  const navigate = useNavigate()
  const { userData } = useAuth()

  const { data: users } = useUsers({
    params: {
      active: true,
    },
  })

  //TODO:JA In release 2, simplify this convoluted logic
  const isInitialAssigneeViaTeam = assignee?.startsWith(TeamPrefix)
  const isAssignedViaTeam = assigneeId.startsWith(TeamPrefix)
  const [team, teamAssignee] = fromAssignee(assigneeId)

  useEffect(() => {
    if (!isInitialAssigneeViaTeam && assigneeInfo?.id) {
      setAssigneeId(assigneeInfo.id)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assigneeInfo])

  const handleEditMode = () => {
    setLoading(false)
    setIsEditing(false)
  }

  const handleSetLoadingAfterReassignAssignee = () => {
    setLoading(false)
  }

  const { handleReassignWorkflowExecutionStepAssignee } = useReassignStep(
    workflowExecutionId || '',
    workflowStepId || '',
    handleEditMode,
    handleSetLoadingAfterReassignAssignee,
  )

  //TODO:JA In release 2, use new `includeAssignees` flag when fetching teams to not need this call
  useTeamDefaultAssignee({
    params: { id: selectedTeamId },
    enabled: !!selectedTeamId,
    onSuccess: data => {
      handleSubmit(data?.data?.assigneeId)
    },
  })

  const handleSubmit = (customAssignee?: string) => {
    const assigneeIdToSubmit = customAssignee ? toAssignee(assigneeId, customAssignee) : assigneeId
    setAssigneeId(assigneeIdToSubmit)
    setSelectedTeamId(undefined)
    setLoading(true)
    handleReassignWorkflowExecutionStepAssignee({
      assigneeId: assigneeIdToSubmit,
    })
    if (onHandleSubmit) {
      onHandleSubmit()
    }
  }

  // add edit mode listener for abstracted edit button
  useEffect(() => {
    if (!canEdit) {
      setIsEditing(false)
    }
  }, [canEdit])

  const currentAssignee = users?.find(user => teamAssignee === user.ID)

  const { data: teamData, isLoading: isLoadingTeam } = useTeam({ params: { id: team }, enabled: !!team })
  const isDeletedOrUnknownTeam = !teamData && !isLoadingTeam
  const currentUserIsNonAdminTeamMember = (teamData?.memberIds || []).includes(userData.userId) && !canEdit

  return (
    <InfoRow
      // Users within the assigned team can edit whenever the step is not complete
      // Otherwise, editability is baked into the canEdit variable
      isEditable={canEdit || (!isComplete && currentUserIsNonAdminTeamMember)}
      isEditing={isEditing}
      isLoading={isLoading}
      isValidation={isError}
      isEmpty={!currentAssignee}
      title={infoRowTitle}
      onEditChange={() => {
        setIsEditing(true)
      }}
      onAcceptChange={() => {
        setIsEditing(false)
        // When assignee is a team without an assignee, go find the default assignee for it before submitting
        // Otherwise, submit directly
        if (isAssignedViaTeam && !assigneeId.includes(AssigneeFormatDelimter)) {
          setSelectedTeamId(assigneeId)
        } else {
          handleSubmit()
        }
      }}
      onCancelChange={() => {
        setIsEditing(false)
        setAssigneeId(assignee ? assignee : assigneeInfo?.id || '')
      }}
    >
      {isEditing ? (
        <AssigneeOrTeamSelector
          hasAssignToMeButton
          onChange={ID => ID && setAssigneeId(ID)}
          selectedAssigneeId={assigneeId}
          currentUserIsNonAdminTeamMember={currentUserIsNonAdminTeamMember}
        />
      ) : (
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          {currentAssignee ? (
            !isAssignedViaTeam || isDeletedOrUnknownTeam ? (
              <Chip
                label={`${currentAssignee?.firstName || ''} ${currentAssignee?.lastName || ''}`}
                icon={
                  <Box paddingLeft={0.25}>
                    <UserAvatar user={currentAssignee} />
                  </Box>
                }
                clickable
                onClick={() =>
                  navigate(RoutesManager.settings.users.view.root.getURL({ id: currentAssignee?.ID || '' }))
                }
                onDelete={() =>
                  navigate(RoutesManager.settings.users.view.root.getURL({ id: currentAssignee?.ID || '' }))
                }
                deleteIcon={<Icon name="OArrowCRight" />}
              />
            ) : (
              <Box display="flex" gap={1.5}>
                <TeamChip team={teamData} />
                <UserChip
                  user={currentAssignee}
                  secondaryLabel={teamData?.defaultAssigneeId ? 'Predefined Assignee' : 'Random Assignee'}
                />
              </Box>
            )
          ) : (
            <Typography color="darkDuskFaded.main" variant="body">
              No Assignee
            </Typography>
          )}
        </Box>
      )}
    </InfoRow>
  )
}
