import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type DeleteApplianceParams = APIRequestParams<{
  applianceId: string
}>

export const deleteAppliance = ({ applianceId }: DeleteApplianceParams) =>
  API.delete(
    formatRequestString({
      entityUrl: `/api/appliances/${applianceId}`,
    }),
  )
