import Box from '@mui/material/Box'
import { Text } from 'components/ui-kit/typography/Text'

type Props = {
  children?: React.ReactNode
  heading?: string
  flexBasis?: string
}

export const GreyBox: React.FC<Props> = ({ heading = '', children, flexBasis = 'unset' }) => (
  <Box p={2.5} mb={1.5} flexBasis={flexBasis} sx={{ backgroundColor: 'superLightGrey.main', borderRadius: 3 }}>
    {heading ? (
      <Text component="div" weight={700} size={16} sx={{ mb: 2.5 }}>
        {heading}
      </Text>
    ) : null}
    {children}
  </Box>
)
