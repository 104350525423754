import { createContext } from 'react'
import { TrackersOnNewPropertiesUtils } from 'pages/policyCenter/trackers/interfaces'
import { useTrackersOnNewPropertiesUtils } from 'pages/policyCenter/trackers/hooks'

export const TrackersOnNewPropertiesContext = createContext({} as TrackersOnNewPropertiesUtils)

type Props = {
  children?: React.ReactNode
}

export const TrackersOnNewPropertiesContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useTrackersOnNewPropertiesUtils()

  return (
    <TrackersOnNewPropertiesContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </TrackersOnNewPropertiesContext.Provider>
  )
}

export const withTrackersOnNewPropertiesContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <TrackersOnNewPropertiesContextProvider>
        <Component {...props} />
      </TrackersOnNewPropertiesContextProvider>
    )
  }
}
