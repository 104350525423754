import React, { createContext } from 'react'
import { RedactionUtils, useRedactionUtils } from '../hooks/useRedactionUtils'

export const RedactionContext = createContext({} as RedactionUtils)

type Props = {
  children?: React.ReactNode
}

export const RedactionContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useRedactionUtils()

  return (
    <RedactionContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </RedactionContext.Provider>
  )
}

export const withRedactionContextContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <RedactionContextProvider>
        <Component {...props} />
      </RedactionContextProvider>
    )
  }
}
