import { ReactNode } from 'react'
import { InfoRow, Spinner } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

export type ContentRow = {
  title: string
  info: ReactNode
}

type Props = {
  title: string
  subtitle: string
  // Empty rows are accepted for a cleaner dev experience when adding rows conditionally.
  contentRows: (ContentRow | false)[]
  isLoading: boolean
  containerClassName?: string
  shouldPadRows?: boolean
}

export const ViewModeLayout: React.FC<Props> = ({
  title,
  subtitle,
  contentRows,
  isLoading,
  containerClassName,
  shouldPadRows = false,
}) => {
  const visibleRows = contentRows.filter(row => row) as ContentRow[]

  return (
    <div className={containerClassName}>
      {isLoading ? (
        <Box
          sx={{
            margin: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : (
        <>
          <Box pb={4}>
            <Typography variant="h3">{title}</Typography>
            <Typography
              variant="body"
              sx={{
                color: theme => theme.palette.darkDuskFaded.main,
              }}
            >
              {subtitle}
            </Typography>
          </Box>
          {visibleRows.map(({ title, info }) => (
            <InfoRow key={title} title={title} pb={4}>
              {info}
            </InfoRow>
          ))}
        </>
      )}
    </div>
  )
}
