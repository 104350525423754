import { ICanvasStep } from '../../interfaces'
import { getStepType } from './getStepType'
import { CanvasStepType } from '../constants'

/**
 * Get the step(s) following some step. Will be one step for all
 * @param step
 */
export const getNextSteps = (step: ICanvasStep): Array<string | number | undefined> => {
  const stepType = getStepType(step)
  switch (stepType) {
    case CanvasStepType.Activity:
      return !!step.activity?.transition ? [step.activity.transition] : []
    case CanvasStepType.Placeholder:
      return !!step.placeholder?.transition ? [step.placeholder.transition] : []
    case CanvasStepType.Workflow:
      return !!step.workflow?.transition ? [step.workflow.transition] : []
    case CanvasStepType.Start:
      return !!step.start?.transition ? [step.start.transition] : []
    case CanvasStepType.End:
      return !!step.finish?.transition ? [step.finish.transition] : []
    case CanvasStepType.Gateway:
      return step.gateway?.transitions.length ? step.gateway.transitions.map(transition => transition.ID) : []
  }
  return []
}
