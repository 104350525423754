import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useTheme } from 'api/themes-v2/queries/useTheme'
import { RoutesManager } from 'utils/routing/routesManager'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { ThemePreview } from 'pages/consentAndRights/themes/preview/ThemesPreview'

export const ThemesPreviewContainer: React.FC = () => {
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()

  const redirectToThemesList = () => {
    navigate(RoutesManager.deployment.themes.root.getURL())
  }

  const { data: theme, isLoading: isThemeLoading } = useTheme({
    params: {
      themeCode: code!,
    },
    onError: () => {
      showToast({ content: 'Failed to load theme', type: 'error' })
      redirectToThemesList()
    },
  })

  return <ThemePreview isReady={!isThemeLoading} theme={theme} />
}
