import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ProcessingActivityContext, withProcessingActivityContext } from './context/ProcessingActivityContext'
import { RoutesManager } from 'utils/routing/routesManager'
import { ProcessingActivityTabs } from './components/ProcessingActivityTabs'
import { ProcessingActivityWidgets } from './components/ProcessingActivityWidgets'
import { EditApprovedActivityDialog } from './components/EditApprovedActivityDialog'
import { Box, Typography } from '@mui/material'
import { Spinner, ViewLayout, Chip, TooltipButton, PopUp, Button } from '@ketch-com/deck'
import { Outlet, useNavigate } from 'react-router-dom'
import { ProcessingActivityTypeLabel } from '../constants'
import {
  AssessmentStatusDTO,
  ProcessingActivityAssessmentRequirementDTO,
  ProcessingActivityStatusDTO,
  ProcessingActivityTypeDTO,
} from '@ketch-com/figurehead'
import { renderStatus } from '../home/components/ProcessingActivityTile'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { isUndefined } from 'lodash'
import { showToast } from 'components/modals/AlertComponent'
import { ProcessingActivityOptionsButton } from './components/ProcessingActivityOptionsButton'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

const ProcessingActivityWithContext: React.FC = () => {
  const {
    processingActivity,
    isPreviousVersion,
    showEditApprovedDialog,
    isProcessingActivityLoading,
    handleDeleteProcessingActivity,
    handleStatusUpdate,
    refetchVersions,
    isStatusUpdating,
  } = useContext(ProcessingActivityContext)
  const navigate = useNavigate()

  const { isPermitted } = useIsPermitted()
  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)

  const { location, id } = useContext(ProcessingActivityContext)

  const handleApproveConfirmationClick = async () => {
    try {
      // Update status
      await handleStatusUpdate({
        params: {
          activityId: processingActivity.id || '',
          formData: {
            status: ProcessingActivityStatusDTO.ApprovedProcessingActivityStatus,
          },
        },
      })
      // Get new versions
      await refetchVersions()
      setShowApproveDialog(false)
    } catch {
      showToast({ content: 'Error in approval process', type: 'error' })
    }
  }

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Processing Activities', link: RoutesManager.policyCenter.processingActivities.root.getURL() },
    {
      title: processingActivity.name,
      link: RoutesManager.policyCenter.processingActivities.view.root.getURL({ id: processingActivity.id }),
    },
  ]

  const onDeleteProcessingActivity = () => {
    handleDeleteProcessingActivity({
      params: { activityId: processingActivity.id || '' },
      onSuccess: () => {
        navigate(RoutesManager.policyCenter.processingActivities.root.getURL())
      },
    })
  }
  // Determine if assessment can be approved
  const hasAssessmentFile =
    !isUndefined(processingActivity.assessmentFile) && Object.keys(processingActivity.assessmentFile).length > 0
  const assessmentRequired =
    processingActivity.assessmentRequirement ===
    ProcessingActivityAssessmentRequirementDTO.RequiredProcessingActivityAssessmentRequirement
  const alreadyApproved = processingActivity.status === ProcessingActivityStatusDTO.ApprovedProcessingActivityStatus
  const noAssessmentOrNotComplete =
    !Array.isArray(processingActivity.assessments) ||
    (processingActivity.assessments!.length > 0 &&
      processingActivity.assessments![0].attributes?.status !== AssessmentStatusDTO.ApprovedAssessmentStatus)

  const disableApprove: boolean =
    isPreviousVersion || (!hasAssessmentFile && (alreadyApproved || (assessmentRequired && noAssessmentOrNotComplete)))

  const tabs = useMemo(
    () => [
      {
        label: 'Overview',
        link: {
          pathname: RoutesManager.policyCenter.processingActivities.view.overview.root.getURL({
            id,
          }),
          search: location.search,
        },
      },
      {
        label: 'Data Sources',
        link: {
          pathname: RoutesManager.policyCenter.processingActivities.view.dataSources.root.getURL({
            id,
          }),
          search: location.search,
        },
      },
      {
        label: 'Data Flow',
        link: {
          pathname: RoutesManager.policyCenter.processingActivities.view.dataFlow.root.getURL({
            id,
          }),
          search: location.search,
        },
      },
      {
        label: 'Versions',
        link: {
          pathname: RoutesManager.policyCenter.processingActivities.view.versions.root.getURL({
            id,
          }),
          search: location.search,
        },
      },
    ],
    [id, location.search],
  )

  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (location.pathname.includes(tab.link.pathname)) {
        setCurrentTab(index)
      }
    })
  }, [location.pathname, tabs])

  return isProcessingActivityLoading ? (
    <Box display="flex" alignItems="center" justifyContent="center" flex={1} flexDirection="column">
      <Spinner size="32px" thickness={2.5} />
    </Box>
  ) : (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />

      <ViewLayout
        contentGroupProps={{
          title: processingActivity.name,
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.policyCenter.processingActivities.root.getURL()),
          },
          infoBlockComponent: (
            <Box display="flex" gap={1}>
              <Chip
                size="small"
                label={
                  ProcessingActivityTypeLabel[
                    processingActivity.type || ProcessingActivityTypeDTO.UnspecifiedProcessingActivityType
                  ]
                }
              />
              {renderStatus(
                processingActivity.status || ProcessingActivityStatusDTO.UnspecifiedProcessingActivityStatus,
              )}
            </Box>
          ),
          actionRightBlockComponent: (
            <Box display="flex" gap={1.5}>
              {isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_WRITE) && (
                <ProcessingActivityOptionsButton
                  disabled={isPreviousVersion}
                  processingActivity={processingActivity}
                  handleDelete={onDeleteProcessingActivity}
                />
              )}
              {isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_APPROVE) &&
                processingActivity.status !== ProcessingActivityStatusDTO.ApprovedProcessingActivityStatus && (
                  <TooltipButton
                    disableHoverListener={!disableApprove || isPreviousVersion}
                    title="To approve this Processing Activity an approved assessment is required."
                    size="large"
                    disabled={disableApprove}
                    onClick={() => setShowApproveDialog(true)}
                  >
                    Approve
                  </TooltipButton>
                )}
            </Box>
          ),
        }}
        widgetContentComponent={<ProcessingActivityWidgets disabled={isPreviousVersion} />}
        tabsComponentProps={{
          tabItems: tabs,
          value: currentTab,
          onChange: (_, value) => {
            navigate({ pathname: tabs[value].link.pathname, search: tabs[value].link.search })
          },
        }}
        customHeaderContentComponent={
          <ProcessingActivityTabs
            tabsComponentProps={{
              tabItems: tabs,
              value: currentTab,
              onChange: (_, value) => {
                navigate({ pathname: tabs[value].link.pathname, search: tabs[value].link.search })
              },
            }}
          />
        }
      >
        <Outlet />
      </ViewLayout>
      {showEditApprovedDialog && <EditApprovedActivityDialog />}
      {showApproveDialog && (
        <PopUp
          title="Confirm Approval"
          variant="dialog"
          popUpWidth="550px"
          onClose={() => setShowApproveDialog(false)}
          popUpActionChildren={
            <>
              <Button
                color="secondary"
                size="large"
                pending={isStatusUpdating}
                onClick={() => setShowApproveDialog(false)}
              >
                Close
              </Button>
              <Button color="primary" size="large" pending={isStatusUpdating} onClick={handleApproveConfirmationClick}>
                Approve
              </Button>
            </>
          }
        >
          <Typography display="block" variant="label">
            You will be approving {processingActivity.name}.
          </Typography>
          <Typography variant="body">Please confirm you wish to proceed.</Typography>
        </PopUp>
      )}
    </>
  )
}

export const ProcessingActivity = withProcessingActivityContext(ProcessingActivityWithContext)
