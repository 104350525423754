import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchDataSubjectTypes } from '../fetchers/fetchDataSubjectTypes'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.dataSubjectTypesPaginated,
  queryFn: fetchDataSubjectTypes,
  select: res => res?.data?.dataSubjectTypes || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useDataSubjectTypes = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    itemsPerPage: config?.itemsPerPage || 200,
    params: {
      start: 0,
      limit: 200,
      ...config?.params,
    },
  })
}
