export enum ExperienceType {
  Consent = 'consent',
  Preference = 'preference',
  Progressive = 'progressive',
}

export enum LegacyExperienceType {
  CONSENT = 1,
  PREFERENCE = 2,
}

export const LegacyExperienceTypeMapping = {
  [ExperienceType.Consent]: 1,
  [ExperienceType.Preference]: 2,
  [ExperienceType.Progressive]: 3,
}

export const EXPERIENCE_TYPE_NAMES = {
  [ExperienceType.Consent]: 'Consent & Disclosure',
  [ExperienceType.Preference]: 'Preference Management',
}

export const EXPERIENCE_TYPE_OPTIONS = [
  {
    id: ExperienceType.Consent,
    name: EXPERIENCE_TYPE_NAMES[ExperienceType.Consent],
  },
  {
    id: ExperienceType.Preference,
    name: EXPERIENCE_TYPE_NAMES[ExperienceType.Preference],
  },
]
