import { useQueryClient } from 'react-query'
import { useCreateInstalledDataSystem } from 'api/dataSystems/mutations/useCreateInstalledDataSystem'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { DiscoveredSystemsInstallFormValues } from '../../../../DiscoveredDataSystems/utils/formValues'
import { InstalledDataSystemRelationshipTypeV2DTO } from '@ketch-com/figurehead'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useDeleteInstalledDataSystem } from 'api/dataSystems/mutations/useDeleteInstalledDataSystem'
import { useInstalledDataSystemsInfinite } from 'api/dataSystems/queries/useInstalledDataSystemsInfinite'
import { useState } from 'react'
import { mockDiscoveredSystems } from 'api/dataSystems/__mocks__'
import { useApplications } from 'api/applications/queries/useApplications'
import { ApplicationPlatform } from 'interfaces/applications/applicationPlatform'

export const useSystemsOverviewUtils = () => {
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const isDemo = isPermitted(PERMISSIONS.DEMO)

  const { data: properties } = useApplications()
  const webProperties = properties.filter(property => property.platform === ApplicationPlatform.WEB)

  // Fetch the installed demo systems - TODO: Delete once we have actual discovered systems functionality
  const demoInstalledSystems = [
    useInstalledDataSystemsInfinite({ q: 'google ads' }).data,
    useInstalledDataSystemsInfinite({ q: 'facebook ads' }).data,
    useInstalledDataSystemsInfinite({ q: 'adobe analytics' }).data,
    useInstalledDataSystemsInfinite({ q: 'shopify' }).data,
  ]

  const deleteDemoSystems = () => {
    demoInstalledSystems
      .map(systems => systems.map(system => system.id))
      .flat()
      .forEach(id => {
        if (id) {
          handleDeleteInstalledDataSystem({ params: { id } })
        }
      })
    setDiscoveredSystems(allDiscoveredSystems)
  }

  // TODO: When not using this for demo, use actual discovered systems from the systems property
  // const { data: allDiscoveredSystems, isLoading: isDiscoveredSystemsLoading } = useDiscoveredDataSystems()
  const allDiscoveredSystems = mockDiscoveredSystems
  const isDiscoveredSystemsLoading = false

  // Filter the list of discovered systems to only include uninstalled ones - won't have to do this in non-demo version
  const uninstalledDiscoveredSystems = allDiscoveredSystems.filter(
    discoveredSystem =>
      !demoInstalledSystems.some(installedSystems =>
        installedSystems.some(installedSystem => installedSystem.dataSystem?.name === discoveredSystem.name),
      ),
  )

  const [discoveredSystems, setDiscoveredSystems] = useState(uninstalledDiscoveredSystems)

  // Create a new installed system
  const { mutateAsync: handleCreateInstalledDataSystem } = useCreateInstalledDataSystem({
    onError: () => showToast({ type: 'error', content: 'Error Adding System' }),
    onSuccess: () => {
      showToast({ type: 'success', content: 'Added System Successfully' })
      queryClient.refetchQueries(ApiQueryKeys.dataSystems)
    },
  })

  // Delete an installed system
  const { mutate: handleDeleteInstalledDataSystem } = useDeleteInstalledDataSystem({
    onMutate: () => {},
    onSuccess: async () => {
      showToast({ content: 'Data system deleted', type: 'success' })
      await queryClient.refetchQueries([ApiQueryKeys.installedDataSystems, ApiQueryKeys.installedDataSystemsStats])
      await queryClient.refetchQueries()
      setDiscoveredSystems(allDiscoveredSystems)
    },
    onError: () => {
      showToast({ content: 'Failed to delete data system', type: 'error' })
    },
  })

  // Install each selected systems
  const onSubmit = async (values: DiscoveredSystemsInstallFormValues) => {
    const dataSystemCodes = values?.selectedDataSystems?.map(selectedDataSystem => selectedDataSystem?.code) as string[]
    const description = values?.description
    const type =
      values?.selectedDataSystems.length > 1
        ? InstalledDataSystemRelationshipTypeV2DTO.VendorInstalledDataSystemRelationshipTypeV2
        : values?.type

    dataSystemCodes.map(async dataSystemCode => {
      await handleCreateInstalledDataSystem({
        params: {
          formData: {
            installedDataSystem: { dataSystemCodes: [dataSystemCode], description, type },
          },
        },
      })
    })
  }

  return {
    webProperties,
    uninstalledDiscoveredSystems,
    discoveredSystems,
    setDiscoveredSystems,
    isDiscoveredSystemsLoading,
    handleCreateInstalledDataSystem,
    handleDeleteInstalledDataSystem,
    onSubmit,
    isDemo,
    deleteDemoSystems,
  }
}

export type SystemsOverviewUtils = ReturnType<typeof useSystemsOverviewUtils>
