import React from 'react'
import Box from '@mui/material/Box'
import { FormFieldViewBasicDetails, FormFieldViewItemsWithoutTranslation } from '.'
import { ContentGroup } from '@ketch-com/deck'

type Props = {}

export const FormFieldDetailViewOverviewTab: React.FC<Props> = () => {
  return (
    <Box display="flex" flexDirection="column" gap={4} pb={6}>
      <ContentGroup variant="inner-page-header" titleVariant="h3" title="Overview" />
      {/* Basic details */}

      <FormFieldViewBasicDetails />

      {/* Items, if applicable */}

      <FormFieldViewItemsWithoutTranslation />
    </Box>
  )
}
