import { useEffect, useState } from 'react'

import { useUserMe } from 'api/users/queries/useUserMe'
import { getFullNameUserShortDTO } from 'utils/helpers/getFullNameUserShortDTO'

export const useIntercomIdentify = () => {
  const [isMounted, setIsMounted] = useState(false)
  const { data: user } = useUserMe()

  useEffect(() => {
    const intercomClient = (window as any)?.Intercom

    if (!isMounted && !!intercomClient && !!user) {
      intercomClient('boot', {
        // https://developers.intercom.com/installing-intercom/web/attributes-objects/
        email: user.email,
        user_id: `${user.organization.code}|${user.email}`,
        created_at: user.metadata.createdAt,
        name: getFullNameUserShortDTO(user),
        company: {
          id: user.organization.code,
          name: user.organization.name,
          plan: user.organization.planProductID,
          products: Object.keys(user.organization.products ?? {}),
        },
      })

      setIsMounted(true)
    }
  }, [setIsMounted, isMounted, user])
}
