import { useMemo } from 'react'

import { GeoJsonLayer } from '@deck.gl/layers'
import { IMapRegion } from '../../list/PolicyScopesList'
import { hexToRGBArray } from 'pages/insights/dataMap/components/DataMapVisualization/utils/colors'
import { mapFeatures } from './mapFeatures'
import { RGBAColor } from 'deck.gl'
import { theme } from '@ketch-com/deck'

type Args = {
  regions?: IMapRegion[]
}

export const usePolicyScopesMapLayersSingle = ({ regions }: Args) => {
  const layers: any = useMemo(
    () => [
      new GeoJsonLayer({
        id: 'geojson',
        filled: true,
        stroked: true,
        pickable: true,
        data: mapFeatures,
        lineWidthMinPixels: 0.3,
        lineWidthMaxPixels: 0.3,
        parameters: {
          depthTest: false,
        },
        getFillColor: (polygon: any) => {
          const region = regions?.find(r => r.id === polygon.id)
          if (region) region['fillColor'] = hexToRGBArray(theme.palette.bloom.main) as RGBAColor

          return region ? (region?.fillColor as RGBAColor) : (hexToRGBArray(theme.palette.bone.main) as RGBAColor)
        },
        getLineColor: (polygon: any) => {
          const region = regions?.find(r => r.id === polygon.id)
          if (region) region['fillColor'] = hexToRGBArray(theme.palette.label4.main) as RGBAColor

          return region
            ? (region?.fillColor as RGBAColor)
            : (hexToRGBArray(theme.palette.dataMapStrokeDefault.main) as RGBAColor)
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regions],
  )

  return layers
}
