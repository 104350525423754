import { Box, Typography } from '@mui/material'
import { useStyles } from '../hooks'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { Checkbox } from 'components/ui-kit/checkbox/Checkbox'
import { RecaptchaDisclosureSignUp } from 'components/ui-kit/recaptcha/RecaptchaDisclosureSignUp'
import { orgNameHint } from '../constants'
import { RegistrationHeading } from '../components/RegistrationHeading'
import { styled } from '@mui/styles'
import { ChangeEvent, useEffect } from 'react'
import { useFormikContext } from 'formik'

const PRIVACY_POLICY_LINK = 'https://www.ketch.com/privacy-policy'
const UNSUBSCRIBE_LINK = 'https://content.ketch.com/hs/manage-preferences/unsubscribe-simple?via_redirect=true'
const TERMS_OF_SERVICE_LINK = 'https://www.ketch.com/terms-of-service'

const SignupLink = styled('a')(({ theme: { palette } }) => ({
  color: palette.sphere.main,
  textDecoration: 'none',
}))

export const OrganizationInfo = ({ isChecked, setIsChecked, isUSOrAU }: any) => {
  const classes = useStyles()

  const { setFieldValue } = useFormikContext()

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Update state that allows form to be submitted
    setIsChecked(e.target.checked)
    // Update form value
    setFieldValue('marketingConsent', e.target.checked)
  }

  // Marketing consent set by default for US and Australia
  useEffect(() => {
    setFieldValue('marketingConsent', isUSOrAU)
    setIsChecked(isUSOrAU)
  }, [isUSOrAU, setFieldValue, setIsChecked])

  return (
    <div className={classes.flexContainer}>
      <RegistrationHeading formTitle="Almost done!" formSubtitle="Tell us about your business." />
      <FormRow marginBottom={24}>
        <FormInput
          fullWidth
          required
          autocomplete="off"
          label="Company / Organization Name"
          name="orgCode"
          hint={orgNameHint}
        />
      </FormRow>
      <FormRow marginBottom={24}>
        <FormInput fullWidth required autocomplete="off" label="Website Link" name="website" />
      </FormRow>
      <FormRow marginBottom={24}>
        <FormInput fullWidth autocomplete="off" label="Privacy Policy Link" name="privacyPolicy" />
      </FormRow>

      {/* Display consent and disclosures depending on country code */}
      {isUSOrAU ? (
        <Box display="flex" alignItems="start" mb={3}>
          <Typography variant="fadedLabel" fontWeight={400}>
            By clicking 'Create Account' you agree to Ketch's{' '}
            <SignupLink href={TERMS_OF_SERVICE_LINK} rel={'noreferrer'} target={'_blank'}>
              Terms of Service
            </SignupLink>
            , the receipt of the requested information as well as sales and marketing communications related to Ketch
            products, services, and events. You can{' '}
            <SignupLink href={UNSUBSCRIBE_LINK} rel={'noreferrer'} target={'_blank'}>
              unsubscribe
            </SignupLink>{' '}
            at any time in accordance with our{' '}
            <SignupLink href={PRIVACY_POLICY_LINK} rel={'noreferrer'} target={'_blank'}>
              Privacy Policy
            </SignupLink>
            .
          </Typography>
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="start" gap={1} mb={3}>
            <Checkbox value={isChecked} onChange={handleCheckboxChange} />
            <Typography variant="body">
              Check this box to receive sales and marketing communications related to Ketch products, services, and
              events. You can{' '}
              <SignupLink href={UNSUBSCRIBE_LINK} rel={'noreferrer'} target={'_blank'}>
                unsubscribe
              </SignupLink>{' '}
              any time in accordance with our{' '}
              <SignupLink href={PRIVACY_POLICY_LINK} rel={'noreferrer'} target={'_blank'}>
                Privacy Policy
              </SignupLink>
              .
            </Typography>
          </Box>
          <Box display="flex" alignItems="start" mb={3}>
            <Typography variant="fadedLabel" fontWeight={400}>
              By clicking 'Create Account' you agree to Ketch's{' '}
              <SignupLink href={TERMS_OF_SERVICE_LINK} rel={'noreferrer'} target={'_blank'}>
                Terms of Service
              </SignupLink>
              and the receipt of communications associated with the creation and management of your Ketch Free account.
            </Typography>
          </Box>
        </>
      )}

      <Box mb={3}>
        <RecaptchaDisclosureSignUp />
      </Box>
    </div>
  )
}
