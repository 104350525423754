import Box from '@mui/material/Box'
import { EmptyState } from '@ketch-com/deck'

// Empty state art
import { ReactComponent as NoActivitiesArt } from 'assets/icons/emptyState/EmptyStateProcessingActivities.svg'
import { useContext } from 'react'
import { ProcessingActivitiesContext } from '../context/ProcessingActivitiesContext'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormMode } from 'interfaces/formModes/formMode'

export const NoProcessingActivities: React.FC = () => {
  const { navigate } = useContext(ProcessingActivitiesContext)

  return (
    <Box
      mt={2.5}
      bgcolor="white.main"
      borderRadius="11px"
      py={4}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <EmptyState
        customIcon={<NoActivitiesArt />}
        title="Track your processing"
        subTitle="There are no processing activities created yet. Click on the button below to request your first one!"
        primaryButtonTitle="Request Processing Activity"
        primaryButtonProps={{
          onClick: () =>
            navigate(RoutesManager.policyCenter.processingActivities.upsert.getURL({ formMode: FormMode.CREATE })),
        }}
      />
    </Box>
  )
}
