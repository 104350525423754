import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { Chip } from '@ketch-com/deck'
import { ApplicationDTO } from 'interfaces/applications/application'
import { APPLICATION_PLATFORM_NAME } from 'interfaces/applications/applicationPlatform'
import { DataDeploymentStatus } from 'pages/consentAndRights/applications/list/components'

type Props = {
  isLatest: boolean
  application: ApplicationDTO
}

export const ApplicationViewDetails: FC<Props> = ({ isLatest, application }) => {
  const { code, platform, status, version, environments } = application

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Chip size="small" label={APPLICATION_PLATFORM_NAME[platform]} />
      {!isLatest && <Chip size="small" label={`v${version}`} />}
      {!!code && (
        <Chip
          size="small"
          label={
            <Typography variant="smallLabel">
              Code: <Typography variant="smallBody">{code}</Typography>
            </Typography>
          }
        />
      )}
      <DataDeploymentStatus status={status} statusVariant="outlined" environments={environments} />
    </Box>
  )
}
