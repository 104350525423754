import React from 'react'
import Box from '@mui/material/Box'

import { getMediumAndLargeColor } from 'components/ui-kit/status/StatusV2/utils'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { getRightInvocationCanonicalStatusVariant, getStatusText } from '../../utilsV2'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Icon } from '@ketch-com/deck'
import { getRightInvocationStatusIconName } from '../../utilsV2/getRightInvocationStatusIcon'
import { Typography } from '@mui/material'

type Props = {
  rightInvocation: RightInvocationDTO
}

export const RightListStatusCellRendererV2: React.FC<Props> = ({ rightInvocation }) => {
  const variant = getRightInvocationCanonicalStatusVariant(rightInvocation)
  const statusText = getStatusText({ rightInvocation })
  const color = getMediumAndLargeColor(variant)

  const requestStatusTextContent = rightInvocation?.fulfilledAt ? (
    <FormattedDateRenderer date={rightInvocation?.fulfilledAt} format="MMM D, YYYY" />
  ) : (
    rightInvocation.requestStatusText
  )

  return (
    <Box display="flex" alignItems="center">
      <Icon name={getRightInvocationStatusIconName(rightInvocation)} iconColor={color} />
      <Box display="flex" flexDirection="column" py={0.5}>
        <Typography variant="smallLabel" color={color}>
          {statusText}
        </Typography>
        <Typography variant="footnote" color={color}>
          {requestStatusTextContent}
        </Typography>
      </Box>
    </Box>
  )
}
