import React from 'react'
import { Box, RadioGroup as MUIRadioGroup, SxProps, Theme, Typography } from '@mui/material'
import { Radio, RadioProps } from '@ketch-com/deck'

export type RadioOptionValue = {
  title: React.ReactNode
  value: any
  disabled?: boolean
}

export type RadioOptions = RadioOptionValue[]

export interface Props extends RadioProps {
  options: RadioOptions
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** SX Overrides */
  sx?: SxProps<Theme>
  row?: boolean
  /** Align radio flex-start */
  alignFlexStart?: boolean
  optionsWithBorderBottom?: boolean
}

export function RadioGroup({
  name,
  id = name,
  value,
  onChange,
  options,
  row,
  size = 'medium',
  sx,
  alignFlexStart = false,
  optionsWithBorderBottom = false,
  ...baseRadioProps
}: Props) {
  return (
    <MUIRadioGroup id={id} row={row} name={name} value={value} onChange={onChange} sx={sx}>
      {options.map((option, index) => (
        <Box display="flex" alignItems="center" key={option.value}>
          <Radio
            id={`${id}-${index}${option.title || ''}`}
            name={name}
            value={option.value}
            checked={option.value === value}
            disabled={option.disabled}
            size={size}
            {...baseRadioProps}
          />
          <Typography variant={option.value === value ? 'label' : 'body'}>{option.title}</Typography>
        </Box>
      ))}
    </MUIRadioGroup>
  )
}
