import { useCallback } from 'react'
import { FormFieldDTO, FormTemplateDTO } from '@ketch-com/figurehead'
import { LanguageDTO } from 'interfaces/languages/language'
import { getFormTitleTranslations, getFullyHydratedFormTemplate } from '../utils'
import { useGenerateDefaultFormTemplateSection } from '.'
import { useIsEditMode, useQueryParams } from 'utils/hooks'
import { FormTemplatePreviewQueryParams } from '../../preview/interfaces'

type Args = {
  formTemplate?: FormTemplateDTO
  enabledLanguages: LanguageDTO[]
  formFields: FormFieldDTO[]
}

export const useGetFormTemplateInitialValues = () => {
  const generateDefaultFormTemplateSectionDTO = useGenerateDefaultFormTemplateSection()
  const isEditMode = useIsEditMode()

  // rehydrate from query params if being redirected from previewing a form that is being created
  const { queries } = useQueryParams<{
    formDTO?: FormTemplatePreviewQueryParams['formDTO']
  }>()
  const getFormTemplateInitialValues = useCallback(
    ({ formTemplate, enabledLanguages, formFields }: Args): FormTemplateDTO => {
      const formDTO = queries?.formDTO
      if (formDTO) return JSON.parse(formDTO) as FormTemplateDTO

      // make sure to add missing languages to translations when rehydrating
      if (formTemplate && isEditMode) return getFullyHydratedFormTemplate({ formTemplate, enabledLanguages })

      const payload = {
        id: '',
        name: '',
        title: '',
        translations: getFormTitleTranslations({ languages: enabledLanguages, formTemplate }),
        code: '',
        sections: [generateDefaultFormTemplateSectionDTO({ enabledLanguages, formFields })],
      }

      return payload
    },
    [generateDefaultFormTemplateSectionDTO, isEditMode, queries?.formDTO],
  )

  return getFormTemplateInitialValues
}
