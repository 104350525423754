import React from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'

import { showToast } from 'components/modals/AlertComponent'
import { useWebhook } from 'api/webhooks/queries/useWebhook'
import { useDeleteWebhook } from 'api/webhooks/mutations/useDeleteWebhook'
import { Button, PopUp } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  hookToDeleteId: string
  onSubmit?: () => void
  onCancel: () => void
}

export const DeleteWebhookModal: React.FC<Props> = ({ onSubmit, onCancel, hookToDeleteId }) => {
  const queryClient = useQueryClient()

  const { data: hook, isLoading: isLoadingHook } = useWebhook({
    params: {
      webhookId: hookToDeleteId || '',
    },
  })

  const { mutateAsync: handleDeleteWebhook, isLoading: isMutating } = useDeleteWebhook({
    onSuccess: async () => {
      onSubmit?.()
      showToast({ content: 'Hook deleted', type: 'success' })
      await queryClient.refetchQueries([ApiQueryKeys.webhooksPaginated])
    },
    onError: () => {
      showToast({ content: 'Failed to delete Hook', type: 'error' })
    },
  })

  return (
    <PopUp
      isLoading={isLoadingHook}
      variant="dialog"
      title="Delete Hook"
      popUpWidth="550px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Close
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={() =>
              handleDeleteWebhook({
                params: {
                  webhookId: hookToDeleteId!,
                },
              })
            }
            pending={isMutating}
          >
            Delete
          </Button>
        </>
      }
    >
      <Typography variant="body">
        Are you sure you want to delete <strong>{hook?.name}</strong> hook?
      </Typography>

      <Typography variant="body">
        Deleting this Hook will immediately remove it from any future DSR workflow executions involving assets formerly
        assigned to this Hook.
      </Typography>
    </PopUp>
  )
}
