import { EventV2DTO } from '@ketch-com/figurehead'

export enum RightType {
  RIGHT_TYPE_UNSPECIFIED = 0,
  RIGHT_TYPE_CANONICAL = 1,
  RIGHT_TYPE_CUSTOM = 2,
}

export type EventsByType = {
  events: EventV2DTO[]
  title: string
  type?: RightType
}

export enum DataRoleType {
  DATA_ROLE_UNSPECIFIED = 0,
  DATA_ROLE_PROCESSOR = 1,
  DATA_ROLE_CONTROLLER = 2,
  DATA_ROLE_JOINT_CONTROLLER = 3,
  DATA_ROLE_JOINT_PROCESSOR = 4,
  DATA_ROLE_CO_CONTROLLER = 5,
}

export const DATA_ROLE_NAME = {
  [DataRoleType.DATA_ROLE_UNSPECIFIED]: 'Unspecified',
  [DataRoleType.DATA_ROLE_PROCESSOR]: 'Processor',
  [DataRoleType.DATA_ROLE_CONTROLLER]: 'Controller',
  [DataRoleType.DATA_ROLE_JOINT_CONTROLLER]: 'Joint Controller',
  [DataRoleType.DATA_ROLE_JOINT_PROCESSOR]: 'Joint Processor',
  [DataRoleType.DATA_ROLE_CO_CONTROLLER]: 'Co Controller',
}

export const DATA_ROLE_OPTIONS = [
  {
    id: DataRoleType.DATA_ROLE_CONTROLLER,
    name: DATA_ROLE_NAME[DataRoleType.DATA_ROLE_CONTROLLER],
  },
  {
    id: DataRoleType.DATA_ROLE_PROCESSOR,
    name: DATA_ROLE_NAME[DataRoleType.DATA_ROLE_PROCESSOR],
  },

  // TODO:BAC - While the enum defines other codes, the Figma doesn't expose the commented out options as of 10/19/2022

  //   {
  //     id: DataRoleType.DATA_ROLE_UNSPECIFIED,
  //     name: DATA_ROLE_NAME[DataRoleType.DATA_ROLE_UNSPECIFIED],
  //   },
  //   {
  //     id: DataRoleType.DATA_ROLE_JOINT_CONTROLLER,
  //     name: DATA_ROLE_NAME[DataRoleType.DATA_ROLE_JOINT_CONTROLLER],
  //   },
  //   {
  //     id: DataRoleType.DATA_ROLE_JOINT_PROCESSOR,
  //     name: DATA_ROLE_NAME[DataRoleType.DATA_ROLE_JOINT_PROCESSOR],
  //   },
  //   {
  //     id: DataRoleType.DATA_ROLE_CO_CONTROLLER,
  //     name: DATA_ROLE_NAME[DataRoleType.DATA_ROLE_CO_CONTROLLER],
  //   },
]
