import * as Yup from 'yup'
import { WebhookDTO, AppDTO } from '@ketch-com/figurehead'

type Args = {
  webhooksData: WebhookDTO[]
  systemsData: AppDTO[]
}

export const getSubscriptionControlFormValidationSchema = ({ webhooksData, systemsData }: Args) => {
  return Yup.object().shape({
    basicDetails: Yup.object().shape({
      name: Yup.string().required('Required'),
      code: Yup.string(),
      description: Yup.string(),
    }),
    orchestrations: Yup.array(
      Yup.object().shape({
        type: Yup.string(),
        appInstanceId: Yup.string().when('type', {
          is: 'system',
          then: Yup.string().required('Required'),
        }),
      }),
    ).test({
      name: `orchestrations`,
      test: (parent, { createError }) => {
        const isValid = Boolean(parent?.length)
        if (isValid) return true
        if (webhooksData?.length || systemsData?.length)
          return createError({
            path: `orchestrations`,
            message: 'Please select a orchestration',
          })
        return createError({
          path: `orchestrations`,
          message: 'Please configure a webhook or system to orchestrate subscription',
        })
      },
    }),
  })
}
