import { IDParams, CodeParams } from './utils'

export const admin = {
  root: {
    pattern: '/admin',
    getURL: () => '/admin',
  },

  callback: {
    root: {
      pattern: '/callback',
      getURL: () => '/admin/callback',
    },
  },

  accounts: {
    root: {
      pattern: '/accounts',
      getURL: () => `/admin/accounts`,
    },

    accounts: {
      root: {
        pattern: '/accounts',
        getURL: () => `/admin/accounts/accounts`,
      },

      view: {
        root: {
          pattern: '/accounts/view/:id',
          getURL: ({ id }: IDParams) => `/admin/accounts/accounts/view/${id}`,
        },

        overview: {
          root: {
            pattern: '/overview',
            getURL: ({ id }: IDParams) => `/admin/accounts/accounts/view/${id}/overview`,
          },
        },

        license: {
          root: {
            pattern: '/license',
            getURL: ({ id }: IDParams) => `/admin/accounts/accounts/view/${id}/license`,
          },
        },

        entitlements: {
          root: {
            pattern: '/entitlements',
            getURL: ({ id }: IDParams) => `/admin/accounts/accounts/view/${id}/entitlements`,
          },
        },

        featureFlags: {
          root: {
            pattern: '/feature-flags',
            getURL: ({ id }: IDParams) => `/admin/accounts/accounts/view/${id}/feature-flags`,
          },
        },
      },

      upsert: {
        root: {
          pattern: '/accounts/edit/:id',
          getURL: ({ id }: IDParams) => `/admin/accounts/accounts/edit/${id}`,
        },
      },
    },
  },

  product: {
    root: {
      pattern: '/product',
      getURL: () => `/admin/product`,
    },

    galleries: {
      root: {
        pattern: '/galleries',
        getURL: () => `/admin/product/galleries`,
      },

      view: {
        root: {
          pattern: '/galleries/view/:code',
          getURL: ({ code }: CodeParams) => `/admin/product/galleries/view/${code}`,
        },

        overview: {
          root: {
            pattern: '/overview',
            getURL: ({ code }: CodeParams) => `/admin/product/galleries/view/${code}/overview`,
          },
        },

        apps: {
          root: {
            pattern: '/apps',
            getURL: ({ code }: CodeParams) => `/admin/product/galleries/view/${code}/apps`,
          },
        },
      },

      upsert: {
        root: {
          pattern: '/galleries/edit/:code',
          getURL: ({ code }: CodeParams) => `/admin/product/galleries/edit/${code}`,
        },
      },
    },
  },

  skus: {
    root: {
      pattern: '/skus',
      getURL: () => `/admin/skus`,
    },

    skus: {
      root: {
        pattern: '/skus',
        getURL: () => `/admin/skus/skus`,
      },

      view: {
        root: {
          pattern: '/skus/view/:id',
          getURL: ({ id }: IDParams) => `/admin/skus/skus/view/${id}`,
        },

        overview: {
          root: {
            pattern: '/overview',
            getURL: ({ id }: IDParams) => `/admin/skus/skus/view/${id}/overview`,
          },
        },

        parentSkus: {
          root: {
            pattern: '/parent-skus',
            getURL: ({ id }: IDParams) => `/admin/skus/skus/view/${id}/parent-skus`,
          },
        },

        childSkus: {
          root: {
            pattern: '/child-skus',
            getURL: ({ id }: IDParams) => `/admin/skus/skus/view/${id}/child-skus`,
          },
        },

        permissionSets: {
          root: {
            pattern: '/permission-sets',
            getURL: ({ id }: IDParams) => `/admin/skus/skus/view/${id}/permission-sets`,
          },
        },
      },

      upsert: {
        root: {
          pattern: '/skus/edit/:id',
          getURL: ({ id }: IDParams) => `/admin/skus/skus/edit/${id}`,
        },
      },
    },
  },

  permissions: {
    root: {
      pattern: '/permissions',
      getURL: () => `/admin/permissions`,
    },

    permissions: {
      root: {
        pattern: '/permissions',
        getURL: () => `/admin/permissions/permissions`,
      },

      view: {
        root: {
          pattern: '/permissions/view/:id',
          getURL: ({ id }: IDParams) => `/admin/permissions/permissions/view/${id}`,
        },

        overview: {
          root: {
            pattern: '/overview',
            getURL: ({ id }: IDParams) => `/admin/permissions/permissions/view/${id}/overview`,
          },
        },

        sets: {
          root: {
            pattern: '/sets',
            getURL: ({ id }: IDParams) => `/admin/permissions/permissions/view/${id}/sets`,
          },
        },
      },

      upsert: {
        root: {
          pattern: '/permissions/edit/:id',
          getURL: ({ id }: IDParams) => `/admin/permissions/permissions/edit/${id}`,
        },
      },
    },

    permissionsSets: {
      root: {
        pattern: '/permissions-sets',
        getURL: () => `/admin/permissions/permissions-sets`,
      },

      view: {
        root: {
          pattern: '/permissions-sets/view/:id',
          getURL: ({ id }: IDParams) => `/admin/permissions/permissions-sets/view/${id}`,
        },

        overview: {
          root: {
            pattern: '/overview',
            getURL: ({ id }: IDParams) => `/admin/permissions/permissions-sets/view/${id}/overview`,
          },
        },

        permissions: {
          root: {
            pattern: '/permissions',
            getURL: ({ id }: IDParams) => `/admin/permissions/permissions-sets/view/${id}/permissions`,
          },
        },

        skus: {
          root: {
            pattern: '/skus',
            getURL: ({ id }: IDParams) => `/admin/permissions/permissions-sets/view/${id}/skus`,
          },
        },
      },

      upsert: {
        root: {
          pattern: '/permissions-sets/edit/:id',
          getURL: ({ id }: IDParams) => `/admin/permissions/permissions-sets/edit/${id}`,
        },
      },
    },
  },

  featureFlags: {
    root: {
      pattern: '/feature-flags',
      getURL: () => `/admin/feature-flags`,
    },

    featureFlags: {
      root: {
        pattern: '/feature-flags',
        getURL: () => `/admin/feature-flags/feature-flags`,
      },

      view: {
        root: {
          pattern: '/feature-flags/view/:id',
          getURL: ({ id }: IDParams) => `/admin/feature-flags/feature-flags/view/${id}`,
        },

        overview: {
          root: {
            pattern: '/overview',
            getURL: ({ id }: IDParams) => `/admin/feature-flags/feature-flags/view/${id}/overview`,
          },
        },
      },

      upsert: {
        root: {
          pattern: '/feature-flags/edit/:id',
          getURL: ({ id }: IDParams) => `/admin/feature-flags/feature-flags/edit/${id}`,
        },
      },
    },

    treatments: {
      root: {
        pattern: '/treatments',
        getURL: () => `/admin/feature-flags/treatments`,
      },

      view: {
        root: {
          pattern: '/treatments/view/:id',
          getURL: ({ id }: IDParams) => `/admin/feature-flags/treatments/view/${id}`,
        },

        overview: {
          root: {
            pattern: '/overview',
            getURL: ({ id }: IDParams) => `/admin/feature-flags/treatments/view/${id}/overview`,
          },
        },

        featureFlags: {
          root: {
            pattern: '/feature-flags',
            getURL: ({ id }: IDParams) => `/admin/feature-flags/treatments/view/${id}/feature-flags`,
          },
        },
      },

      upsert: {
        root: {
          pattern: '/treatments/edit/:id',
          getURL: ({ id }: IDParams) => `/admin/feature-flags/treatments/edit/${id}`,
        },
      },
    },
  },
}
