import { Formik, Form } from 'formik'
import { Dropzone } from 'components/ui-kit/dropzoneV2'
import { Button } from '@ketch-com/deck'
import { useUploadFile } from 'api/files/mutations/useUploadFile'
import Box from '@mui/material/Box'
import * as Yup from 'yup'
import { UploadContext, FormValues } from '../../../../components/ui-kit/dropzoneV2/interfaces'

const uploadContext: UploadContext = {
  acl: '3',
  bucket: '',
  folder: 'c0bd482b-13dd-4406-8379-a447310cb846_bebef3aafeca44d8f9b31e95025ffea64',
  version: 'cover_letter',
}

export function DropzoneExample() {
  const { mutateAsync: handleUploadFile, isLoading: isUploadingFile } = useUploadFile({
    onSuccess: data => {
      console.info('\n', `handleFileUpload success `, data, '\n')
    },
    onError: error => {
      console.info('\n', `handleFileUpload error `, error, '\n')
    },
  })

  return (
    <Formik<FormValues>
      initialValues={{ files: [] }}
      validationSchema={Yup.object().shape({
        files: Yup.array().min(1, 'Please upload at least one file'),
      })}
      onSubmit={async (values, { resetForm }) => {
        const { files: acceptedFiles } = values

        await Promise.all(
          acceptedFiles.map(async file => {
            try {
              const { data } = (await handleUploadFile({
                params: {
                  file,
                  ...uploadContext,
                },
              }))!

              return { data, file }
            } catch (error) {
              return { error, file }
            }
          }),
        )

        resetForm()
      }}
    >
      {formik => {
        return (
          <Box display="flex" flexDirection="column">
            <Form>
              <Box mb={1}>
                <Dropzone
                  accept={{ 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'], 'application/pdf': ['.pdf'] }}
                  name="files"
                />
              </Box>

              <Button size="large" type="submit" pending={isUploadingFile} sx={{ mr: 2 }}>
                Submit
              </Button>

              <Button color="secondary" size="large" onClick={() => formik.resetForm()}>
                Clear
              </Button>

              <pre
                style={{
                  wordBreak: 'break-all',
                }}
              >
                {JSON.stringify(formik, null, 2) || 'nothing to preview'}
              </pre>
            </Form>
          </Box>
        )
      }}
    </Formik>
  )
}
