import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchTrackerDetails } from '../fetchers/fetchTrackerDetails'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.trackerDetails,
  queryFn: fetchTrackerDetails,
  select: res => res?.data,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useTrackerDetails = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params,
    },
  })
}
