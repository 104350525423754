import React from 'react'
import { Badge } from 'components/ui-kit/badge/Badge'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'

type Props = {
  dataCategory?: string | undefined
}

export const DataCategoryCellRenderer: React.FC<Props> = ({ dataCategory }) => {
  return dataCategory ? <Badge variant="neutral">{dataCategory}</Badge> : <EmptyValueRenderer />
}
