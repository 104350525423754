import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'
import { Text } from 'components/ui-kit/typography/Text'
import { AccessUtilizationWidget, DataUsageWidget, RatioWidget, UserActivityWidget } from './components'

const PREFIX = 'PermitsFilters'

const classes = {
  titleContainer: `${PREFIX}-titleContainer`,
  filtersList: `${PREFIX}-filtersList`,
  activityWidgetsContainer: `${PREFIX}-activityWidgetsContainer`,
}

const StyledContentBoundaries = styled(ContentBoundaries)(({ theme: { palette, spacing } }) => ({
  [`& .${classes.titleContainer}`]: {
    margin: spacing(2, 0, 3, 0),
    paddingBottom: spacing(2.5),
    borderBottom: `1px solid ${palette.iron.main}`,
  },

  [`& .${classes.filtersList}`]: {
    marginTop: 26,
  },

  [`& .${classes.activityWidgetsContainer}`]: {
    display: 'flex',
    gap: spacing(2),
    minHeight: 509,
  },
}))

type Props = {}

export const ActivityAndAccess: React.FC<Props> = props => {
  return (
    <StyledContentBoundaries>
      {/* Title Row */}

      <Box className={classes.titleContainer}>
        <Text size={34} weight={800}>
          Activity & Access
        </Text>
      </Box>

      {/* Activity Row */}

      <Box display="flex" flexDirection="column" mb={6}>
        <Text size={28} weight={800} color="grey" sx={{ mb: 3 }}>
          Activity
        </Text>

        <Box className={classes.activityWidgetsContainer}>
          <UserActivityWidget />

          <DataUsageWidget />
        </Box>
      </Box>

      {/* Access Row */}

      <Box display="flex" flexDirection="column">
        <Text size={28} weight={800} color="grey" sx={{ mb: 3 }}>
          Access
        </Text>

        <Box className={classes.activityWidgetsContainer}>
          <AccessUtilizationWidget />

          <RatioWidget />
        </Box>
      </Box>
    </StyledContentBoundaries>
  )
}
