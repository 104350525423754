import React from 'react'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { IdentitySpaceViewMappingTable } from 'pages/settings/identitySpaces/view/components/IdentitySpaceViewMappingTable'
import { FindVerifiersResponseBodyDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { Typography, Box } from '@mui/material'
import { ContentGroup, FormRow, InfoRow } from '@ketch-com/deck'

type Props = {
  identitySpace: IdentitySpaceDTO
  pushesTo: IdentitySpaceDTO[]
  verifiersData: MaybeNull<FindVerifiersResponseBodyDTO>
}

export const IdentitySpaceTabOverview: React.FC<Props> = ({ identitySpace, pushesTo, verifiersData }) => {
  const { isEntitled } = useIsEntitled()
  const isEntitledToVerifiedIdentities = isEntitled(ENTITLEMENTS.VERIFIED_IDENTITIES)

  const { verifiers = [] } = verifiersData || {}
  const verifierName = verifiers?.filter(verifier => verifier?.id === identitySpace?.verifierID)?.[0]?.name
  return (
    <>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Settings', link: RoutesManager.settings.root.getURL() },
          { title: 'Identifiers', link: RoutesManager.settings.identitySpaces.root.getURL() },
          {
            title: identitySpace?.name || identitySpace?.code,
            link: RoutesManager.settings.identitySpaces.view.root.getURL({ code: identitySpace.code }),
          },
          { title: 'Overview' },
        ]}
      />

      <Box display="flex" flexDirection="column" gap={3} mb={6}>
        <ContentGroup title="Basic Details" titleVariant="h3" />

        <InfoRow title="Format" isEmpty={!identitySpace.formatCode}>
          <Typography variant="body">{identitySpace.formatCode || <EmptyValueRenderer />}</Typography>
        </InfoRow>

        <InfoRow title="Marketing Preference Identity">
          <Typography variant="body">{identitySpace.IsMarketingPreferences ? 'Yes' : 'No'}</Typography>
        </InfoRow>

        {isEntitledToVerifiedIdentities && identitySpace.isAuthentication && (
          <InfoRow title="Verified Identity" isEmpty={!verifierName}>
            <Typography variant="body">{verifierName || <EmptyValueRenderer />}</Typography>
          </InfoRow>
        )}
      </Box>

      {pushesTo.length ? (
        <FormRow title="Identity Mappings" subTitle={`Pushes To (${pushesTo.length})`}>
          <Box>
            <IdentitySpaceViewMappingTable identitySpaces={pushesTo} />
          </Box>
        </FormRow>
      ) : null}
    </>
  )
}
