import { useAssetV2 } from 'api/assets/queries/useAssetV2'
import { useLabelsV2OrV3BasedOnFeatureFlag } from 'api/labels/queries/useLabelsV2OrV3BasedOnFeatureFlag'
import { useFormikContext } from 'formik'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import React, { useEffect, useState } from 'react'
import { getIsKeyStrokeValid, getReducedLabelsOptions } from '../utils'

export const useManageLabelsInputUtils = ({ assetCode }: { assetCode: string }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [newLabel, setNewLabel] = useState('')
  const [labels, setLabels] = useState<ManageLabelModalLabel[]>([])
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [invalidInputMessage, setInvalidInputMessage] = useState<string | null>(null)
  const { setFieldValue, values } = useFormikContext<EditDatabaseFormValues>()

  const { data: assetSummary, isLoading: isLoadingAsset } = useAssetV2({
    params: {
      assetCode,
    },
  })

  useEffect(() => {
    const customLabels = assetSummary?.asset?.assignedLabels || []

    const currentLabels = [...customLabels].map(l => ({
      code: l?.code,
      value: l?.value || '',
      name: l?.name,
      isSystemLabel: l?.isSystemLabel,
    }))
    setLabels([...(currentLabels as ManageLabelModalLabel[])])
  }, [assetSummary, setLabels])

  React.useEffect(() => {
    setFieldValue('labels', labels)
  }, [labels, setFieldValue])

  const { data: labelOptions, isLoading: isLoadingLabelOptions } = useLabelsV2OrV3BasedOnFeatureFlag()

  const handleSetInvalidInputMessage = (message: string): void => {
    setInvalidInputMessage(message)
    setTimeout(() => {
      setInvalidInputMessage(null)
    }, 2500)
  }

  const handleLabelInputValueOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    if (getIsKeyStrokeValid(e.target.value)) {
      setNewLabel(e.target.value)
    }

    if (!getIsKeyStrokeValid(e.target.value)) {
      handleSetInvalidInputMessage("You've entered an invalid key")
    }
  }

  const handleAutocompleteOnChange = (event: React.ChangeEvent<{}>, value: ManageLabelModalLabel[]): void => {
    setLabels(l => ((value?.length || 0) > l?.length ? [...l, value[value.length - 1]] : [...l]))
    setNewLabel('')
  }

  const handleRemoveTag = (labelToRemoveUniqueIdentifier: string) => {
    setFieldValue(
      'labels',
      values.labels.filter(l => {
        const uniqueIdentifier = `${l?.code || ''}${l?.value || ''}${l?.name || ''}`
        return uniqueIdentifier !== labelToRemoveUniqueIdentifier
      }),
    )
    setLabels(labels =>
      labels.filter(l => {
        const uniqueIdentifier = `${l?.code || ''}${l?.value || ''}${l?.name || ''}`
        return uniqueIdentifier !== labelToRemoveUniqueIdentifier
      }),
    )
  }

  return {
    handleAutocompleteOnChange,
    handleLabelInputValueOnChange,
    handleRemoveTag,
    isBusy,
    isOptionsOpen,
    labels,
    newLabel,
    setIsBusy,
    setIsOptionsOpen,
    invalidInputMessage,
    isLoading: isLoadingAsset || isLoadingLabelOptions,
    labelOptions: getReducedLabelsOptions({ labelOptions }),
  }
}
