import { ICanvasStep } from '../../pages/orchestration/workflows/edit/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface WorkflowCanvasCopiedStepsState {
  // Copied workflow steps, may be a activity, gateway, or path
  steps: ICanvasStep[]
  // Canonical right code of the workflow where steps were copied
  copiedStepsCanonicalRightCode?: string
}

const initialState = {
  steps: [],
  copiedStepsCanonicalRightCode: undefined,
} as WorkflowCanvasCopiedStepsState

export const workflowCanvasCopiedStepsSlice = createSlice({
  name: 'workflowCanvasCopiedStepsState',
  initialState,
  reducers: {
    copySteps: (state, action: PayloadAction<ICanvasStep[]>) => {
      state.steps = action.payload
    },
    setCopiedStepsCanonicalRightCode: (state, action: PayloadAction<string>) => {
      state.copiedStepsCanonicalRightCode = action.payload
    },
  },
})

export const { copySteps, setCopiedStepsCanonicalRightCode } = workflowCanvasCopiedStepsSlice.actions

export default workflowCanvasCopiedStepsSlice.reducer
