import { flatMap } from 'lodash'
import { CookieDTO, CookiePage, InfiniteCookieResult } from 'interfaces/cookies/cookie'
import { useInfiniteCookies } from 'api/cookies/queries/useInfiniteCookies'
import { showToast } from 'components/modals/AlertComponent'
import { useAppSelector } from 'store/hooks'
import { getCookiesListFilterState } from 'store/cookiesListFilterSlice/selectors'

const getStatusFilterTestResult = ({ cookie, statusFilter }: { cookie: CookieDTO; statusFilter: number | null }) => {
  if (statusFilter === null) return true
  if (statusFilter !== null && cookie?.status) return statusFilter === cookie.status
  if (statusFilter !== null && !cookie?.status) return statusFilter === 0
  return true
}

const handleOnSettled = ({
  result,
  fetchNextPage,
  error,
}: {
  result: InfiniteCookieResult
  fetchNextPage: () => {}
  error: unknown
}): void => {
  if (error) {
    showToast({ content: 'Failed to fetch cookies', type: 'error' })
    return
  }
  const { pages } = result
  const totalResults = pages?.[0]?.totalResults || 0
  let totalFetchedCookiesSoFar = 0
  pages?.forEach?.((p: CookiePage) => {
    totalFetchedCookiesSoFar += p.cookies.length
  })
  if (totalFetchedCookiesSoFar < totalResults) {
    fetchNextPage()
  }
}

export const useCookiesListContainerData = () => {
  const {
    status: statusFilter,
    category: categoryFilter,
    query: searchText,
  } = useAppSelector(getCookiesListFilterState)

  const { fetchNextPage, hasNextPage, isFetching, isLoading, data } = useInfiniteCookies({
    onSettled: (result: InfiniteCookieResult, error: unknown): void =>
      handleOnSettled({ result, fetchNextPage, error }),
  })

  const cookies =
    flatMap(data?.pages, p => p.cookies as CookieDTO[])
      .filter(c => (searchText ? c?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) : true))
      .filter(c => (categoryFilter !== null ? c.category === categoryFilter : true))
      .filter(c => getStatusFilterTestResult({ cookie: c, statusFilter })) || []

  return { cookies, isLoading, isFetching, hasNextPage }
}
