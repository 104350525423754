import { IDParams } from './utils'

export const accounts = {
  root: {
    pattern: '/accounts',
    getURL: () => `/accounts`,
  },

  accounts: {
    root: {
      pattern: '/accounts',
      getURL: () => `/accounts/accounts`,
    },

    view: {
      root: {
        pattern: '/accounts/view/:id',
        getURL: ({ id }: IDParams) => `/accounts/accounts/view/${id}`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ id }: IDParams) => `/accounts/accounts/view/${id}/overview`,
        },
      },

      license: {
        root: {
          pattern: '/license',
          getURL: ({ id }: IDParams) => `/accounts/accounts/view/${id}/license`,
        },
      },

      entitlements: {
        root: {
          pattern: '/entitlements',
          getURL: ({ id }: IDParams) => `/accounts/accounts/view/${id}/entitlements`,
        },
      },

      featureFlags: {
        root: {
          pattern: '/feature-flags',
          getURL: ({ id }: IDParams) => `/accounts/accounts/view/${id}/feature-flags`,
        },
      },
    },

    upsert: {
      root: {
        pattern: '/accounts/edit/:id',
        getURL: ({ id }: IDParams) => `/accounts/accounts/edit/${id}`,
      },
    },
  },
}
