import { WebhooksContextTimelineDTO } from '@ketch-com/figurehead'
import {
  WebhooksContextTimelineItemStatus,
  WebhooksContextDetailsType,
  WebhooksContextDetailsStatus,
} from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'
import pluralize from 'pluralize'

// see also: TimelineItemContent.tsx when adding or removing entries here
export const getContextDetailsString = ({
  status,
  webhookDetails,
  identities,
  contextVariables,
  dataCollected,
  outcomeVariables,
  formData,
}: WebhooksContextTimelineDTO) => {
  /* START */
  if (status === WebhooksContextTimelineItemStatus.START) return 'API Call Initiated'

  /* ERROR */
  if (status === WebhooksContextTimelineItemStatus.ERROR) {
    if (webhookDetails?.status === WebhooksContextDetailsStatus.WEBHOOK) {
      // the webhook returned a success 2xx statusCode but there is an error (such as during validation), so return the custom message
      if (
        webhookDetails?.statusCode !== undefined &&
        webhookDetails.statusCode >= 200 &&
        webhookDetails.statusCode < 300
      ) {
        return 'Webhook Response Error'
      }

      return `Webhook Error - Status ${webhookDetails?.statusCode || '(No status code received)'}`
    }
    if (webhookDetails?.status === WebhooksContextDetailsStatus.CALLBACK) {
      return `Callback Error ${webhookDetails?.statusCode || '(No status code received)'}`
    }
  }

  /* UNKNOWN */
  if (status === WebhooksContextTimelineItemStatus.UNKNOWN) {
    if (identities) {
      return 'Unknown Identifier'
    } else if (contextVariables) {
      return 'Unknown Data Subject Variable'
    } else if (outcomeVariables) {
      return 'Unknown Outcome Variable'
    } else if (formData) {
      return 'Unknown Form Field'
    }
  }

  /* INVALID */
  if (status === WebhooksContextTimelineItemStatus.INVALID) {
    if (contextVariables) return 'Invalid Data Subject Variable'
    if (outcomeVariables) return 'Invalid Outcome Variable'
    if (formData) return 'Invalid Form Field Value'
  }

  /* SUCCESS */
  if (status === WebhooksContextTimelineItemStatus.SUCCESS) {
    if (webhookDetails?.status === WebhooksContextDetailsStatus.WEBHOOK) {
      if (webhookDetails?.type === WebhooksContextDetailsType.REQUEST) {
        return 'Called Webhook'
      }

      if (webhookDetails?.type === WebhooksContextDetailsType.RESPONSE) {
        return 'Webhook Responded'
      }
    }

    if (webhookDetails?.status === WebhooksContextDetailsStatus.CALLBACK) {
      if (webhookDetails?.type === WebhooksContextDetailsType.REQUEST) {
        return 'Received Status Event'
      }

      if (webhookDetails?.type === WebhooksContextDetailsType.RESPONSE) {
        return 'Callback Response'
      }
    }

    if (identities) {
      return `${Object.keys(identities?.data || {}).length || 0} Identifiers Received`
    }

    if (contextVariables) {
      return `${Object.keys(contextVariables?.data || {}).length || 0} Data Subject ${pluralize(
        'Variable',
        Object.keys(contextVariables?.data || {}).length,
      )} Received`
    }

    if (outcomeVariables) {
      return `${Object.keys(outcomeVariables?.data || {}).length || 0} Outcome ${pluralize(
        'Variable',
        Object.keys(outcomeVariables?.data || {}).length,
      )} Received`
    }

    if (formData) {
      return `${formData?.data?.length || 0} Form ${pluralize('Field', formData?.data?.length || 0)} Received`
    }

    if (dataCollected) {
      return 'Data Was Collected'
    }
  }

  /* DEFAULT */
  return 'API Call Step'
}
