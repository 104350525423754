export enum Capabilities {
  UNSPECIFIED_APP_CAPABILITY = 0,
  PERMIT_PROPAGATION_APP_CAPABILITY = 1,
  PERMIT_INGESTION_APP_CAPABILITY = 2,
  RIGHTS_PROPAGATION_APP_CAPABILITY = 3,
  RIGHTS_INGESTION_APP_CAPABILITY = 4,
  TAG_ORCHESTRATION_APP_CAPABILITY = 5,
  INTROSPECTION_APP_CAPABILITY = 6,
  FORTIFICATION_APP_CAPABILITY = 7,
  SUBSCRIPTION_APP_CAPABILITY = 8,
}
