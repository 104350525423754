import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { CanonicalPurposeDTO } from 'interfaces/purposes/canonicalPurpose'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{}>

type Response = APIListResponse<{ canonicalPurposes: CanonicalPurposeDTO[] }>

export const fetchGenericCanonicalPurposes = ({ includeMetadata, start, limit }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/canonical-purposes`,
      params: {
        filters: {
          includeMetadata,
          start,
          limit,
        },
      },
    }),
  )
