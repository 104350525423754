import { CircularProgress, CircularProgressSize, ListLayout } from '@ketch-com/deck'
import { Box, Divider, styled } from '@mui/material'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RightsCard } from './components/RightsCard'
import { ConsentCard } from './components/ConsentCard'
import { SectionLinks } from './components/SectionLinks'
import { useIsKetchFree } from 'utils/hooks/useIsKetchFree'
import { Home } from './Home'

const StatsBox = styled(Box)({
  display: 'flex',
  maxHeight: '400px',
  gap: '24px',
})

export const HomeOverview: React.FC = () => {
  const breadcrumbs = [{ title: 'Home' }]
  const { isKetchFree, isLoading } = useIsKetchFree()

  if (isLoading) return <CircularProgress size={CircularProgressSize.xLarge} />

  return isKetchFree ? (
    <Home />
  ) : (
    <ListLayout
      headerTitle={'Get Started'}
      headerProps={{ isDivider: true }}
      sx={{
        px: '154px',
      }}
    >
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <>
        {/* Stats */}
        <StatsBox>
          <RightsCard />
          <ConsentCard />
        </StatsBox>

        <Divider sx={{ mt: 6, mb: 2 }} />

        {/* Page links */}
        <SectionLinks />
      </>
    </ListLayout>
  )
}
