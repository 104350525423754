import { DataCategoryDTO, DataSensitivityDTO, DataTypeDTO, ConfirmationStatusV3DTO } from '@ketch-com/figurehead'
import { useMemo } from 'react'

export type ClassificationList = DataCategoryDTO[] | DataSensitivityDTO[] | DataTypeDTO[]
export interface ClassificationListItem extends DataCategoryDTO, DataSensitivityDTO, DataTypeDTO {
  id?: string
}

function useSplitClassificationsByStatus(classificationList: ClassificationList) {
  const { assigned, unassigned } = useMemo(() => {
    const assigned: ClassificationList = []
    const unassigned: ClassificationList = []

    classificationList.forEach(classification => {
      if (classification.confirmationStatus === ConfirmationStatusV3DTO.DismissedConfirmationStatusV3) {
        unassigned.push(classification)
      } else {
        assigned.push(classification)
      }
    })

    return { assigned, unassigned }
  }, [classificationList])

  return { assigned, unassigned }
}

export default useSplitClassificationsByStatus
