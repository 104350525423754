import { FC } from 'react'
import {
  Button,
  Chip,
  ContentGroup,
  DataGrid,
  EmptyState,
  Status,
  StatusState,
  StatusVariant,
  TableCell,
  theme,
} from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'
import { ApplicationDTO } from 'interfaces/applications/application'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { useQueryParams } from 'utils/hooks/useQueryParams'

type Props = {
  application: ApplicationDTO
  versions: ApplicationDTO[]
}

const pageSize = 25

export const ApplicationVersions: FC<Props> = ({ application, versions }) => {
  const { queries, setQueryParam, removeQueryParam } = useQueryParams<{ version?: string }>()
  const currentVersion = queries.version ? +queries.version : application.version
  const columns: GridColDef<ApplicationDTO>[] = [
    {
      align: 'left',
      field: 'latest',
      flex: 1,
      maxWidth: 150,
      headerAlign: 'left',
      headerName: 'Status',
      sortable: false,
      renderCell: ({ row }) => (
        <TableCell>
          {!!row.latest && (
            <Status variant={StatusVariant.ghost} status={StatusState.success} icon="Dot" label="Latest" />
          )}
        </TableCell>
      ),
    },
    {
      align: 'left',
      field: 'version',
      flex: 1,
      headerAlign: 'left',
      headerName: 'Version',
      sortable: false,
      renderCell: ({ row }) => (
        <TableCell>
          <Chip size="small" label={`v${row.version}`} />
        </TableCell>
      ),
    },
    {
      align: 'left',
      field: 'name',
      flex: 1,
      headerAlign: 'left',
      headerName: 'Property',
      sortable: false,
      renderCell: ({ row }) => <TableCell title={row.name} subTitle={row.code} />,
    },
    {
      align: 'left',
      field: 'lastUpdated',
      flex: 1,
      headerAlign: 'left',
      headerName: 'Last Updated',
      sortable: false,
      renderCell: ({ row }) => {
        if (row.metadata?.updatedAt) {
          return <TableCell title={formatDateTimeFromUnix(row.metadata?.createdAt)} />
        }
        return (
          <TableCell>
            <Typography color="lightGrey.main" variant="body">
              None
            </Typography>
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'actions',
      flex: 1,
      maxWidth: 100,
      headerAlign: 'left',
      headerName: '',
      sortable: false,
      renderCell: ({ row }) => (
        <TableCell>
          <Button
            disabled={row.version === currentVersion}
            color="tertiary"
            onClick={() => {
              if (row.latest) {
                removeQueryParam('version')
              } else {
                setQueryParam('version', row.version)
              }
            }}
          >
            View
          </Button>
        </TableCell>
      ),
    },
  ]

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {versions.length ? (
        <>
          <ContentGroup title="Version History" titleVariant="h3" variant="inner-page-header" />
          <DataGrid
            autosizeOnMount
            rows={versions}
            columns={columns}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnReorder
            disableColumnResize
            disableRowHoverStates
            disableRowSelectionOnClick
            hideFooter={versions.length <= pageSize * 2}
            pagination={versions.length > pageSize * 2}
            pageSize={pageSize}
            getRowId={row => row.ID}
            getRowHeight={() => 'auto'}
            sx={{
              '& .MuiDataGrid-virtualScroller': {
                overflow: 'hidden',
              },
            }}
          />
        </>
      ) : (
        <EmptyState
          title="No versions exist for the selected application"
          subTitle="As you make changes to this application, you can view that history here"
          sx={{
            padding: '48px 16px',
            backgroundColor: theme.palette.Black.o8,
            borderRadius: '11px',
          }}
        />
      )}
    </Box>
  )
}
