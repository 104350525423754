import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep, IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { StepIcon } from 'pages/orchestration/workflows/edit/components/stepIcon/StepIcon'
import { useFormikContext } from 'formik'
import { getAllowedTilesCodes } from '../utils'
import { Icon, MegaFilterAccordion, theme } from '@ketch-com/deck'
import { AccordionDetails, AccordionSummary, Tooltip, Typography } from '@mui/material'

type Props = {
  isSidebarExpanded: boolean
  name: string
  items: Array<ICanvasStep>
  search: string
  handleStartItemDrag: (step: ICanvasStep) => void
  handleStopItemDrag: () => void
  infoHoverHint?: string
}

export const SidebarList: React.FC<Props> = ({
  isSidebarExpanded,
  items,
  name,
  search,
  handleStartItemDrag,
  handleStopItemDrag,
  infoHoverHint,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const { values: formikValues } = useFormikContext<IWorkflowConfig>()

  const listFiltered = search.length
    ? items.filter(item => item?.name?.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    : items

  const hasFilteredItems = !!listFiltered.length
  const allowToggle = !search || !hasFilteredItems

  useEffect(() => {
    // Open list after finding items inside if it was closed
    if (search && !isOpen && hasFilteredItems) {
      setIsOpen(true)
    }
  }, [search, isOpen, hasFilteredItems])

  const allowedTilesCodes = getAllowedTilesCodes(formikValues)

  return (
    <>
      <MegaFilterAccordion
        expanded={isOpen}
        onChange={() => {
          allowToggle && setIsOpen(isOpen => !isOpen)
        }}
      >
        <AccordionSummary
          disableTouchRipple
          className="MegaFilterAccordionSummary"
          expandIcon={<Icon name="OArrowCRight" />}
        >
          <Typography variant={isOpen ? 'label' : 'body'}>{name}</Typography>
          {infoHoverHint && (
            <Tooltip title={infoHoverHint}>
              <Box display="flex" alignItems="center" ml={1}>
                <Icon name="FInfo" iconColor={theme.palette.darkDuskFaded.main} height={18} width={18} />
              </Box>
            </Tooltip>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {hasFilteredItems && (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridGap: 0,
                gridAutoRows: 95,
                ...(!isSidebarExpanded && {
                  padding: '11px',
                  gridTemplateColumns: 'repeat(1, 1fr)',
                  borderBottom: 'none',
                }),
              }}
            >
              {listFiltered.map(item => {
                const { name, activityCode } = item

                const shouldForceTile = allowedTilesCodes.find(a => a.code === (activityCode as WorkflowActivityCode))

                const isDraggable = allowedTilesCodes.length && !shouldForceTile ? false : true

                return (
                  <Box
                    key={item?.iconCode}
                    sx={{
                      display: 'inline-flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      userSelect: 'none',
                      padding: '2px',
                      ...(!!allowedTilesCodes.length && {
                        opacity: !!shouldForceTile ? 1 : 0.5,
                      }),
                    }}
                  >
                    <StepIcon
                      draggable={isDraggable}
                      notDraggableTooltipText={
                        allowedTilesCodes?.length > 0 && !shouldForceTile ? allowedTilesCodes?.[0]?.disabledMessage : ''
                      }
                      step={item}
                      stepName={name}
                      onDragStart={() => handleStartItemDrag(item)}
                      onDragEnd={() => handleStopItemDrag()}
                    />
                  </Box>
                )
              })}
            </Box>
          )}
        </AccordionDetails>
      </MegaFilterAccordion>
    </>
  )
}
