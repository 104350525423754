import React from 'react'
import { MenuItem, Button, Menu, Box } from '@mui/material'
import { Error } from 'components/ui-kit/form/common/error/Error'
import CheckSharpIcon from '@mui/icons-material/CheckSharp'

import { DropdownV2Props } from './interfaces'
import { EndIcon, DropdownHint } from './components'
import { useDropdownV2Utils } from './hooks'
import { Text } from 'components/ui-kit/typography/Text'
import { getDropdownV2SxProps } from './utils'

export const DropdownV2: React.FC<DropdownV2Props> = ({
  className,
  disabled = false,
  errorMessage = 'Required',
  fullWidth = false,
  hint,
  id,
  isMenuItemBorderless = false,
  isPending = false,
  isValid = true,
  items,
  onChange,
  onClose,
  onOpen,
  placeholder,
  size = 'medium',
  value,
  variant = 'contained',
  sx,
}) => {
  const { dropdownAnchorEl, isDropdownOpen, handleDropdownOnClick, handleMenuOnClose, handleMenuItemOnClick } =
    useDropdownV2Utils({
      onOpen,
      onClose,
      onChange,
    })
  const dropdownV2SxProps = getDropdownV2SxProps({ isDropdownOpen, value, variant, isValid, sxOverrides: sx })

  return (
    <>
      <Box>
        <Button
          className={className}
          disabled={disabled}
          size={size}
          variant={variant}
          disableRipple
          disableFocusRipple
          disableElevation
          fullWidth={fullWidth}
          onClick={handleDropdownOnClick}
          endIcon={<EndIcon isPending={isPending} isDropdownOpen={isDropdownOpen} />}
          sx={dropdownV2SxProps}
        >
          <Box>
            {(!!value && items.find(option => option.id === value)?.title) || placeholder || 'Select'}
            <DropdownHint hint={hint} />
          </Box>
        </Button>
        {!isValid && <Error>{errorMessage}</Error>}
      </Box>

      <Menu
        id={id}
        anchorEl={dropdownAnchorEl}
        open={isDropdownOpen}
        onClose={handleMenuOnClose}
        PaperProps={{
          sx: {
            borderRadius: 1.25,
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            py: 0,
          },
        }}
      >
        {items.map(option => {
          const isActive = !!value && option.id === value

          return (
            <MenuItem
              disableRipple
              id={option.id}
              className={isMenuItemBorderless ? 'borderless' : undefined}
              onClick={handleMenuItemOnClick}
              key={option.id}
              value={option.id}
              sx={{
                py: 1.5,
                minWidth: 170,
                '&:not(:last-child)': {
                  borderBottom: ({ palette }) => (isMenuItemBorderless ? undefined : `1px solid ${palette.iron.main}`),
                },
                background: isActive ? 'rgba(87, 116, 255, 0.1)' : undefined,
                color: ({ palette }) => (isActive ? palette.sphere.main : undefined),
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                {option.content ? (
                  option.content
                ) : (
                  <Text size={14} weight={isActive ? 600 : 'normal'} color={isActive ? 'sphere' : undefined}>
                    {option.title}
                  </Text>
                )}
                {isActive ? (
                  <Box
                    sx={{
                      '& svg': {
                        color: ({ palette }) => palette.sphere.main,
                        fontSize: ({ typography }) => typography.pxToRem(18),
                      },
                    }}
                    display="flex"
                    alignItems="center"
                  >
                    <CheckSharpIcon />
                  </Box>
                ) : null}
              </Box>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
