import React from 'react'
import { Box, Typography } from '@mui/material'

interface MyComponentProps {
  description?: string
}

export const SectionDescription: React.FC<MyComponentProps> = ({ description }) => (
  // TODO:BAC - should abstract to typography variant in deck theme
  <Typography
    component={Box}
    variant="body"
    sx={{
      '& p': {
        margin: 0,
      },
      '& ul': {
        margin: 0,
      },
      '& a': {
        color: 'darkDusk.main',
        textDecoration: 'underline',
      },
      mb: 3,
    }}
    dangerouslySetInnerHTML={{
      __html: description || '',
    }}
  />
)
