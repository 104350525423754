import { TabSegmented, TabSegmentedGroup } from '@ketch-com/deck'
import { TabSegmentedGroupProps } from '@ketch-com/deck/dist/v1.5/molecules/TabSegmented'
import { Box, styled, SxProps, Theme, Tooltip, Typography } from '@mui/material'
import { FieldValidator, useField } from 'formik'
import { ObjectLiteral } from 'interfaces'
import { isNumber } from 'lodash'
import React from 'react'

const FormSegmentButtonsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

interface Props<T extends ObjectLiteral> extends Omit<TabSegmentedGroupProps, 'onChange'> {
  /** Formik field name */
  formPropertyName: string
  /** Array of items to render in menu */
  options: T[]
  /** Text to be displayed above Component */
  label?: React.ReactNode
  /** Is the field disabled */
  disabled?: boolean
  /** Tooltip to show when the field is disabled */
  disabledTooltip?: string
  /** Custom validation method */
  validate?: FieldValidator
  /** Where in T to find the item value, defaults to 'value' */
  valueKey?: string
  /** Where in T to find the item label, defaults to  'label' */
  labelKey?: string
  /** Default value */
  defaultValue?: string
  /** Option renderer */
  renderOption?: (item: T, isSelected?: boolean) => React.ReactNode
  /** set values to number */
  isInt?: boolean
  /** Function to call on change, in addition to onChangeInner which updates the formik field */
  onChange?: (e: React.MouseEvent<HTMLElement, MouseEvent>, nextValue: any) => void
  /** Label sx props */
  labelSx?: SxProps<Theme> | undefined
  /** TabSegmentedGroup sx props */
  sx?: SxProps<Theme> | undefined
}

export const FormSegmentButtons: React.FC<Props<ObjectLiteral>> = ({
  formPropertyName,
  isInt = false,
  label = '',
  disabled = false,
  disabledTooltip = '',
  onChange,
  options,
  renderOption,
  validate,
  valueKey = 'value',
  labelKey = 'label',
  defaultValue = null,
  labelSx,
  sx,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, _, { setValue }] = useField({ name: formPropertyName, validate })

  const onChangeInner = (e: React.MouseEvent<HTMLElement, MouseEvent>, nextValue: any) => {
    // Set formik value
    const val = isNumber(field.value) ? +nextValue : nextValue
    if (isInt) {
      setValue(parseInt(val as string))
    } else {
      setValue(val)
    }

    // Call provided change handler, if any
    if (onChange) {
      onChange(e, nextValue)
    }
  }

  return (
    <FormSegmentButtonsBox>
      {/* Render label */}
      {label && (
        <Typography variant="label" sx={labelSx}>
          {label}
        </Typography>
      )}

      {/* Render segment buttons */}
      <Tooltip title={disabled ? disabledTooltip : ''} placement="bottom">
        <TabSegmentedGroup
          value={(typeof field.value === 'boolean' ? String(field.value) : field.value) || defaultValue}
          exclusive
          onChange={onChangeInner}
          disabled={disabled}
          sx={sx}
        >
          {options.map(option => (
            <TabSegmented key={option[valueKey]} value={isInt ? parseInt(option[valueKey]) : option[valueKey]}>
              {renderOption ? renderOption(option, field.value === option[valueKey]) : option[labelKey]}
            </TabSegmented>
          ))}
        </TabSegmentedGroup>
      </Tooltip>
    </FormSegmentButtonsBox>
  )
}
