import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { WorkflowExecutionStepInterventionStatus } from 'interfaces/workflowExecutions/workflowExecutionStepInterventionStatus'

export enum WorkflowExecutionStepName {
  START = 'Start Flow',
  TASK = 'Task',
  APPROVAL = 'Approval',
  END = 'End Flow',
  API_CALL = 'API Call',
  DECISION_MULTI = 'Decision Multi',
  DECISION_SINGLE = 'Decision Single',
  DECISION_GATEWAY = 'Decision Gateway',
}

export enum WorkflowExecutionStepCode {
  DATA_REVIEW = 'standard.DataReviewWorkflow',
}

export type WorkflowExecutionStepDTO = {
  workflowExecutionID: string
  stepID: string
  stepName?: WorkflowExecutionStepName | string
  stepDescription?: string
  startedAt?: string
  completedAt?: string
  dueAt?: number
  status: WorkflowExecutionStepStatus
  activityStepCode?: string
  manualInterventionStatus?: WorkflowExecutionStepInterventionStatus
  assignee?: string
  approver?: string
  issues?: IssueDTO[]
  dataReviewStatus?: number
  currentDesignee?: string
}

export type IssueDTO = {
  ID: string
  status: number
  entityType: string
  entityID: string
  entityCode: string
  organizationCode: string
  deprecatedTenantCode: string
}

export type WorkflowExecutionDTO = {
  ID: string
  orgCode: string
  workflowDefinitionCode: string
  workflowDefinitionVersion: string
  workflowDefinitionName: string
  startedAt: string
  completedAt: string
  status: WorkflowExecutionStepStatus
  steps: WorkflowExecutionStepDTO[]
  results: {
    [key: string]: any
  }
}

export type WorkflowExecutionUpdateDTO = {
  temporalRunID?: string
  temporalWorkflowID: string
  signalName: string
  signalValue: {
    status?: string
    resolutionDetails?: string
    resolutionAttachments?: string[]
    selectedPaths?: string[]
    command?: string
    subjectCommunicationMessage?: string
    coverLetter?: string
    completed?: boolean
    selectedBy?: string
    importContextValue?: string
    reasonForFinalization?: string
    // shouldResendToWebhook is populated for the api call tile
    shouldResendToWebhook?: boolean
  }
}

export type WorkflowExecutionStepCompleteDTO = {
  results: {
    resolutionDetails?: string
    resolutionAttachments?: string[]
    dsrResolutionAttachments?: string[]
    decisionBoolean?: boolean
    availablePaths?: string
  }
}
