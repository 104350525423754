import { ApiQueryKeys } from 'api/common/queryKeys'
import { TaskDTO } from '@ketch-com/figurehead'
import { useInfiniteQuery } from 'react-query'
import { fetchTasksPaginated } from '../fetchers/fetchTasksPaginated'

const limit = 100

export const useInfiniteTasks = ({ query, status }: { query?: string; status?: string }) => {
  const resp = useInfiniteQuery({
    queryKey: [ApiQueryKeys?.tasksInfinite, { query, status, ordering: 'created_at' }],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchCookiesResults = await fetchTasksPaginated({
        limit,
        start: (pageParam - 1) * limit,
        query,
        status,
      })

      const { data } = fetchCookiesResults

      data.tasks = data?.tasks ?? []
      data.totalResults = data.totalResults ?? 0

      return data
    },
    getNextPageParam: ({ totalResults }, allPages) => {
      let totalTasksFetchedSoFar = 0
      allPages?.forEach?.(pageOfResults => {
        totalTasksFetchedSoFar += pageOfResults?.tasks?.length || 0
      })
      const nextPageParam = Math.ceil(totalTasksFetchedSoFar / limit) + 1

      return totalTasksFetchedSoFar === totalResults ? undefined : nextPageParam
    },
    refetchInterval: 1000 * 30 * 1, // 30 seconds
  })

  let infiniteTasks: TaskDTO[] = []

  let totalResults = 0

  resp?.data?.pages?.forEach?.(page => {
    infiniteTasks = [...infiniteTasks, ...(page?.tasks || [])]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: infiniteTasks,
    totalResults,
  }
}
