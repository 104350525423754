import {
  UpdateAssetAssignedClassificationsV3RequestBodyDTO,
  UpdateAssetAssignedClassificationsV3ResponseBodyDTO,
} from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  installedDataSystemId?: string
  formData: UpdateAssetAssignedClassificationsV3RequestBodyDTO
}

export const updateAssignedCategories = ({ installedDataSystemId, formData }: Params) => {
  return API.put<UpdateAssetAssignedClassificationsV3ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v3/assets/${installedDataSystemId}/classifications`,
    }),
    formData,
  )
}
