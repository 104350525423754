import { createContext } from 'react'
import { MegaFilterUtils, useMegaFilterUtils } from '../utils/useMegaFilterUtils'

export const CustomMegaFilterContext = createContext({} as MegaFilterUtils)

type Props = {
  children?: React.ReactNode | undefined
}

export const MegaFilterContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useMegaFilterUtils()

  return <CustomMegaFilterContext.Provider value={{ ...hookValues }}>{children}</CustomMegaFilterContext.Provider>
}

export const withMegaFilterContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <MegaFilterContextProvider>
        <Component {...props} />
      </MegaFilterContextProvider>
    )
  }
}
