import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { ProcessingActivityDataSystemProcessingStageDTO, ProcessingActivityTypeDTO } from '@ketch-com/figurehead'
import { DataMapProcessingActivityContext } from '../context/DataMapProcessingActivityContext'
import { Icon, TabSegmented, TabSegmentedGroup, TextInput } from '@ketch-com/deck'

const segmentsTabOptions = [
  {
    id: ProcessingActivityDataSystemProcessingStageDTO.UnspecifiedProcessingActivityDataSystemProcessingStage,
    name: 'All',
  },
  {
    id: ProcessingActivityDataSystemProcessingStageDTO.InputProcessingActivityDataSystemProcessingStage,
    name: 'Inputs',
  },
  {
    id: ProcessingActivityDataSystemProcessingStageDTO.OutputProcessingActivityDataSystemProcessingStage,
    name: 'Outputs',
  },
]

const processingSegmentTab = {
  id: ProcessingActivityDataSystemProcessingStageDTO.ProcessProcessingActivityDataSystemProcessingStage,
  name: 'Processors',
}

export const DataSourcesFilters: React.FC = () => {
  const { processingActivity, searchString, setSearchString, activityStage, setActivityStage } = useContext(
    DataMapProcessingActivityContext,
  )

  // Don't include processing filter in AI/Model type activities
  const segmentButtons =
    processingActivity.type === ProcessingActivityTypeDTO.ModelProcessingActivityType
      ? segmentsTabOptions
      : [...segmentsTabOptions, processingSegmentTab]

  return (
    <Box display="flex" alignItems="center" gap={2} sx={{ width: '100%' }}>
      <TextInput
        autoFocus
        value={searchString}
        startIcon={<Icon name="OMag" />}
        endIcon={searchString && <Icon sx={{ cursor: 'pointer' }} name="OCross" onClick={() => setSearchString('')} />}
        size="small"
        placeholder="Search"
        onChange={event => setSearchString(event.target.value)}
      />

      {processingActivity?.dataSystems?.length && (
        <Typography variant="label">{processingActivity?.dataSystems?.length} total</Typography>
      )}

      <Box ml="auto">
        <TabSegmentedGroup
          size="small"
          value={activityStage}
          exclusive
          onChange={(_, value) => {
            setActivityStage(value)
          }}
        >
          {segmentButtons.map(segmentButton => {
            return (
              <TabSegmented value={segmentButton.id} key={segmentButton.id}>
                {segmentButton.name}
              </TabSegmented>
            )
          })}
        </TabSegmentedGroup>
      </Box>
    </Box>
  )
}
