import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { updateDataReviewStatus } from 'api/dataReview/fetchers/updateDataReviewStatus'

const useCustomMutation = createUseMutation({
  mutationFn: updateDataReviewStatus,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateReviewStatus = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation)
}
