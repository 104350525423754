import React, { useState } from 'react'
import { styled } from '@mui/styles'
import { Box, Typography, ButtonBase } from '@mui/material'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces/common'
import { useGetAssetTechnologySpecificHumanReadableName } from 'pages/assetManager/hooks'
import { AssetDeletedModal } from 'pages/assetManager/assets/view/components'

type Props = {
  isReady: boolean
  assetSummary: MaybeNull<AssetSummaryDTO>
}

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: theme.palette.whiteDisabled.main,
  color: theme.palette.white.main,
  padding: theme.spacing(0.5, 1.25, 0.5, 1.25),
  borderRadius: '5px',
}))

export const AssetDeleted: React.FC<Props> = ({ isReady, assetSummary }) => {
  const [showAssetDeletedModal, setShowAssetDeletedModal] = useState(false)
  const deletedTimeStamp = isReady && assetSummary?.asset?.metadata?.deletedAt
  const getAssetTechnologySpecificHumanReadableName = useGetAssetTechnologySpecificHumanReadableName()
  const assetType = getAssetTechnologySpecificHumanReadableName(assetSummary) || ''
  if (deletedTimeStamp) {
    return (
      <Box
        bgcolor="darkDusk.main"
        borderRadius="10px"
        pl={2}
        pr={0.75}
        py={0.75}
        mb={3}
        width={1272}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="label" color="white.main">
          This {assetType.toLowerCase()} was unreachable since the scan done on{' '}
          {formatDateTimeFromUnix(deletedTimeStamp)}.
        </Typography>
        <StyledButton disableRipple onClick={() => setShowAssetDeletedModal(true)}>
          <Typography variant="label">Learn More</Typography>
        </StyledButton>
        {showAssetDeletedModal ? (
          <AssetDeletedModal assetType={assetType} onCancel={() => setShowAssetDeletedModal(false)} />
        ) : null}
      </Box>
    )
  }
  return null
}
