import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { updateCustomRightName } from 'api/customRightNames/fetchers/updateCustomRightName'

const useCustomMutation = createUseMutation({
  mutationFn: updateCustomRightName,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateCustomRightName = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
