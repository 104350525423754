import { isUndefined, omit } from 'lodash'

import { WorkflowFormDTO } from 'interfaces/workflows'
import { ICanvasStep, IWorkflowConfig, IWorkflowItems } from 'pages/orchestration/workflows/edit/interfaces'

export const transformWorkflowToBE = ({
  workflow,
  sidebarItems,
}: {
  workflow: IWorkflowConfig
  sidebarItems: IWorkflowItems
}): WorkflowFormDTO => {
  const steps = workflow?.steps || []
  const newSteps: ICanvasStep[] = []

  steps?.forEach?.(workflowStep => {
    const stepToReturn = omit(workflowStep, ['valid', 'iconCode', 'iconURL', 'activity.options'])
    const isActivity = !isUndefined(stepToReturn?.activity)
    const isWorkflow = !isUndefined(stepToReturn?.workflow)
    const isGateway = !isUndefined(stepToReturn?.gateway)

    const sidebarItemsActivitiesAndConnectors = [
      ...sidebarItems.activities,
      ...sidebarItems.connectors,
      ...sidebarItems.apps,
      ...sidebarItems.appslvl2,
    ]

    if (isActivity) {
      const code = stepToReturn.activity?.code

      const sidebarItemWithCode = sidebarItemsActivitiesAndConnectors.filter(
        sidebarItem => sidebarItem?.activity?.code === code,
      )[0]

      if (sidebarItemWithCode) {
        stepToReturn.activityCode = sidebarItemWithCode.activityCode
        stepToReturn.activity!.options = sidebarItemWithCode.activity!.options
        stepToReturn.activity!.outputs = sidebarItemWithCode.activity!.outputs
      }
    }

    if (isWorkflow) {
      const code = stepToReturn.workflow?.code

      const sidebarItemWithCode = sidebarItemsActivitiesAndConnectors.filter(
        sidebarItem => sidebarItem?.workflow?.code === code,
      )[0]

      if (sidebarItemWithCode) {
        stepToReturn.activityCode = sidebarItemWithCode.activityCode
        stepToReturn.workflow!.options = sidebarItemWithCode.workflow!.options
        stepToReturn.workflow!.outputs = sidebarItemWithCode.workflow!.outputs
      }
    }

    if (isGateway) {
      const mode = stepToReturn.gateway?.mode
      const sidebarItemWithCode = sidebarItems?.gateways?.filter(sidebarItem => sidebarItem?.gateway?.mode === mode)[0]

      if (sidebarItemWithCode) {
        stepToReturn.activityCode = sidebarItemWithCode.activityCode
      }
    }

    newSteps.push(stepToReturn)
  })

  const payload = {
    ...workflow,
    steps: newSteps,
  }

  return payload
}
