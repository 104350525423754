import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from '../../common/paginatedQuery'
import { ApiQueryKeys } from '../../common/queryKeys'
import { fetchProcessingActivityVersions } from '../fetchers/fetchProcessingActivityVersions'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.processingActivityVersions,
  queryFn: fetchProcessingActivityVersions,
  select: res => res?.data || {},
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useProcessingActivityVersions = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
