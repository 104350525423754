import { ConfigSelectorOptions } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/EndFlowFormV3/utils'
import { OptionEnum } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/components/ManualFormSection'
import { ICanvasStep, IEndFlowActivityParams } from 'pages/orchestration/workflows/edit/interfaces'
import { isEndFlowActivityParamsTypeGuard } from 'pages/orchestration/workflows/edit/interfaces/typeGuards'

interface IGetEndInitialValuesParams {
  step: ICanvasStep
}

export const getEndInitialValues = (params: IGetEndInitialValuesParams): IEndFlowActivityParams => {
  const { step } = params
  const defaultMessage = `Dear {{first_name}} {{last_name}},\n\nYour request has been completed. To view your results, please visit the Privacy Portal.`

  if (isEndFlowActivityParamsTypeGuard(step.workflow?.params)) {
    const assignee = step.workflow?.params?.assignee || ''
    const code = step.code || ''
    const configSelector = step.workflow?.params?.configSelector || ''
    const coverLetterFile = step.workflow?.params?.coverLetterFile || undefined
    const description = step.description || ''
    const isCoverLetterRequired = step.workflow?.params?.isCoverLetterRequired || false
    const isMessageRequired = step.workflow?.params?.isMessageRequired || false
    const isManual = configSelector === ConfigSelectorOptions.Manual
    const manualCoverLetter = isManual && isCoverLetterRequired ? OptionEnum.Required : OptionEnum.Optional
    const manualMessage = isManual && isMessageRequired ? OptionEnum.Required : OptionEnum.Optional
    const subjectCommunicationMessage = step.workflow?.params?.subjectCommunicationMessage || defaultMessage
    // new for appeals/reject
    const finalizeRequestAs = step.workflow?.params?.finalizeRequestAs || 'completed'
    const allowAppeal = step.workflow?.params?.allowAppeal || false
    const appealWorkflowDefinitionCode = step.workflow?.params?.appealWorkflowDefinitionCode || undefined // TODO:BAC - not sure if this should be id or workflow object
    const timeToCompleteDays = step.workflow?.params?.timeToCompleteDays || undefined

    return {
      assignee,
      code,
      configSelector,
      coverLetterFile,
      description,
      isCoverLetterRequired,
      isMessageRequired,
      manualCoverLetter,
      manualMessage,
      subjectCommunicationMessage,
      // new for appeals/reject
      finalizeRequestAs,
      allowAppeal,
      appealWorkflowDefinitionCode,
      timeToCompleteDays,
    }
  }

  return {
    assignee: '',
    appealWorkflowDefinitionCode: '',
    code: '',
    configSelector: ConfigSelectorOptions.Automated,
    coverLetterFile: [],
    description: '',
    isCoverLetterRequired: false,
    isMessageRequired: false,
    manualCoverLetter: '',
    manualMessage: '',
    subjectCommunicationMessage: defaultMessage,
    finalizeRequestAs: 'completed',
    allowAppeal: false,
    timeToCompleteDays: undefined,
  }
}
