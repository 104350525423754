import { StatusVariantV3Enum } from 'components/ui-kit/status/interfaces'
import { RightInvocationDTO, RightInvocationRequestStatusDTO } from '@ketch-com/figurehead'

export const getRightInvocationCanonicalStatusVariant = (rightInvocation: RightInvocationDTO): StatusVariantV3Enum => {
  const { requestStatus } = rightInvocation

  switch (requestStatus) {
    case RightInvocationRequestStatusDTO.FulfilledRightInvocationRequestStatus:
      return StatusVariantV3Enum.activeEnabled
    case RightInvocationRequestStatusDTO.RejectedRightInvocationRequestStatus:
      return StatusVariantV3Enum.draft
    case RightInvocationRequestStatusDTO.DueSoonRightInvocationRequestStatus:
      return StatusVariantV3Enum.inProgress
    case RightInvocationRequestStatusDTO.DueTodayRightInvocationRequestStatus:
      return StatusVariantV3Enum.errorCanceled
    case RightInvocationRequestStatusDTO.OverdueRightInvocationRequestStatus:
      return StatusVariantV3Enum.errorCanceled
    case RightInvocationRequestStatusDTO.OnTrackRightInvocationRequestStatus:
      return StatusVariantV3Enum.finishedDone
    default:
      return StatusVariantV3Enum.finishedDone
  }
}
