import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchFilesInfoList } from 'api/files/fetchers/fetchFilesInfoList'
import { excludeFalsy } from 'utils/helpers/excludeFalsy'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.filesList,
  queryFn: fetchFilesInfoList,
  select: res => {
    if (res) return res.filter(excludeFalsy)
    return []
  },
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useFilesInfoList = (config: Config) =>
  useCustomQuery({
    ...config,
    params: config.params!,
  })
