import React from 'react'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import Box from '@mui/material/Box'
import { Chip } from '@ketch-com/deck'
import Typography from '@mui/material/Typography'

type Props = {
  isLatest: boolean
  policyScope: PolicyScopeDTO
}

export const PolicyScopeViewDetails: React.FC<Props> = ({ isLatest, policyScope }) => {
  const { code, version } = policyScope

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {!isLatest && <Chip size="small" label={`v${version}`} />}
      <Chip
        size="small"
        label={
          <Typography variant="smallLabel">
            Code: <Typography variant="smallBody">{code}</Typography>
          </Typography>
        }
      />
    </Box>
  )
}
