import React from 'react'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'

import { AppMarketPlaceEntityDTO } from 'interfaces/apps'
import { formatCodeFromName } from 'utils/formatters'
import { FormRow } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { Box } from '@mui/material'

type Props = {
  isEditMode?: boolean
  marketplaceEntity?: AppMarketPlaceEntityDTO
}

export const AppFormSectionBasicDetails: React.FC<Props> = ({ isEditMode = false, marketplaceEntity }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext()

  return (
    <FormRow title="Connection Details">
      <Box display="flex" flexDirection="column" gap={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormInput
              required
              fullWidth
              label="Name"
              formPropertyName="name"
              placeholder="Example: My New Connection"
              onChange={e => {
                if (!isEditMode) {
                  setFieldTouched('code', true)
                  setFieldValue('code', formatCodeFromName(e.target.value))
                }
              }}
              shouldUpdateDebounced
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormInput
              required
              fullWidth
              disabled={isEditMode}
              label="Code"
              formPropertyName="code"
              placeholder="Example: CN957384"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={9}>
            <FormInput
              fullWidth
              label="Description"
              formPropertyName="description"
              placeholder="Example: My New Connection Description"
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
      </Box>
    </FormRow>
  )
}
