import { CodeParams } from './utils'

export const product = {
  root: {
    pattern: '/product',
    getURL: () => `/product`,
  },

  galleries: {
    root: {
      pattern: '/galleries',
      getURL: () => `/product/galleries`,
    },

    view: {
      root: {
        pattern: '/galleries/view/:code',
        getURL: ({ code }: CodeParams) => `/product/galleries/view/${code}`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ code }: CodeParams) => `/product/galleries/view/${code}/overview`,
        },
      },

      apps: {
        root: {
          pattern: '/apps',
          getURL: ({ code }: CodeParams) => `/product/galleries/view/${code}/apps`,
        },
      },
    },

    upsert: {
      root: {
        pattern: '/galleries/edit/:code',
        getURL: ({ code }: CodeParams) => `/product/galleries/edit/${code}`,
      },
    },
  },
}
