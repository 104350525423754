import React from 'react'
import Highlighter, { HighlighterProps } from 'react-highlight-words'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      baseHighlight: {
        padding: 0,
        backgroundColor: palette.caramel.main,
      },
    }),
  { name: 'HighlightText' },
)

type Props = {
  autoEscape?: HighlighterProps['autoEscape']
  text: HighlighterProps['textToHighlight']
  parts: HighlighterProps['searchWords']
}

export const HighlightText: React.FC<Props> = ({ autoEscape = true, text, parts }) => {
  const classes = useStyles()

  return (
    <Highlighter
      highlightClassName={classes.baseHighlight}
      searchWords={parts}
      autoEscape={autoEscape}
      textToHighlight={text}
    />
  )
}
