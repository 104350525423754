import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

import { ReshapedPerJurisdictionDataForPieChart } from '../../../utils/getTransformedPerJurisdictionData'
import { formatCount } from '../../../../PermitsSummary/components/PermitStatisticsOverall/utils'

const PREFIX = 'DistributionPerScopeTwoOrLess'

const classes = {
  baseDot: `${PREFIX}-baseDot`,
  chartDescriptionRow: `${PREFIX}-chartDescriptionRow`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.baseDot}`]: {
    borderRadius: '50%',
    width: 10,
    height: 10,
  },

  [`& .${classes.chartDescriptionRow}`]: {
    display: 'block',
    marginBottom: 2,
  },
}))

type Props = { data: ReshapedPerJurisdictionDataForPieChart[] }

export const DistributionPerScopeTwoOrLess: React.FC<Props> = ({ data }) => {
  return (
    <StyledBox display="flex" flexDirection="column" justifyContent="center" flex={3.5}>
      <Box mt={1.5} display="flex" flexDirection="column">
        {data.map(jurisdiction => {
          return (
            <Box display="flex" key={jurisdiction?.name}>
              {/* Col 1 */}
              <Box pt={0.5}>
                <Box className={classes.baseDot} sx={{ backgroundColor: jurisdiction?.color || 'black' }} />
              </Box>

              {/* Col 2 */}

              <Box pl={1} flex={1} display="flex" flexDirection="column">
                <Typography variant="label" sx={{ mb: 0.5 }}>
                  {jurisdiction?.name || ''}
                </Typography>
                <Typography variant="smallBody" sx={{ mb: 0.5 }}>
                  {formatCount(jurisdiction?.totalRequests || 0)}
                </Typography>
                <Typography
                  variant="smallBody"
                  color={`${jurisdiction.consentRequiredColor}.main`}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {jurisdiction.consentRequiredText}
                </Typography>
              </Box>
            </Box>
          )
        })}
      </Box>
    </StyledBox>
  )
}
