/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import * as monaco from 'monaco-editor'

// mui components
import Box from '@mui/material/Box'
import { MonacoEditor } from './MonacoEditor'

// ui-kit
import { showToast } from 'components/modals/AlertComponent'

// utils
import { isJson } from '../utils/isJson'

// types
import { FormFile } from 'api/dataReview/fetchers/uploadDataReviewFiles'
import { Button, Icon } from '@ketch-com/deck'
type Props = {
  activeFile: FormFile
  isComplete: boolean
  isSaving: boolean
  onExclude: (isExcluded: boolean) => void
  setActiveFile: (activeFile: FormFile) => void
  setInvalidFormatFiles: (fileName: string, isValid: boolean) => void
}

export const RawData = ({
  activeFile,
  isComplete,
  isSaving,
  onExclude,
  setActiveFile,
  setInvalidFormatFiles,
}: Props) => {
  const [editor, setEditor] = useState<any>()
  const [value, setValue] = useState<string>()
  const [defaultValue] = useState<string>(activeFile?.rawData || '')

  // simple JSON validation
  const validateJSON = (value: string) => {
    let error
    try {
      JSON.parse(value)
    } catch (e) {
      error = e
    }
    return !error
  }

  // simple csv validation
  // TODO:GP perhaps convert to JSON and parse?
  const validateCSV = (csvString: string) => !!csvString?.length && !csvString?.includes(',,')

  // handle input validation for CSV and JSON data types
  const validateInput = (value: string) => {
    let isValid = true
    if (isJson(activeFile?.name)) {
      isValid = validateJSON(value)
    } else {
      isValid = validateCSV(value)
    }

    if (!isValid) {
      showToast({
        content: `Invalid ${isJson(activeFile?.name) ? 'JSON' : 'CSV'}`,
        type: 'error',
      })
    }

    if (activeFile?.name) {
      setInvalidFormatFiles(activeFile?.name, isValid)
    }
  }

  // Update active file state
  const updateActiveFile = (rawData: string | undefined, change: monaco.editor.IModelContentChange) => {
    let redactions = activeFile?.redactions || []
    setActiveFile({
      ...activeFile,
      redactions: defaultValue === rawData ? [] : [...redactions, change],
      rawData,
    })
  }

  const handleChange = (value: string | undefined, ev: monaco.editor.IModelContentChangedEvent) => {
    setValue(value)
    updateActiveFile(value, ev.changes[0])
  }

  // debounce validation call on type
  useDebounce(
    () => {
      if (!activeFile?.rawData?.length) return
      validateInput(activeFile?.rawData)
    },
    500,
    [activeFile?.rawData],
  )

  useEffect(() => {
    if (!activeFile?.rawData?.length) return
    setValue(activeFile?.rawData)
  }, [activeFile?.rawData])

  return (
    <Box display="flex" width="100%" height="100vh">
      <Box width="100%" px={2}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Button
            sx={{
              backgroundColor: theme => (activeFile?.isExcluded ? theme.palette.sphere.main : theme.palette.white.main),
            }}
            disabled={isComplete || isSaving}
            variant="flat"
            enableShadowEffect
            startIcon={<Icon name="ODecline" inheritViewBox />}
            onClick={() => onExclude(!!activeFile?.isExcluded)}
          >
            {activeFile?.isExcluded ? 'Excluded' : 'Exclude'}
          </Button>

          <Button
            sx={{
              backgroundColor: theme => (activeFile?.isExcluded ? theme.palette.sphere.main : theme.palette.white.main),
            }}
            disabled={isComplete}
            variant="flat"
            enableShadowEffect
            startIcon={<Icon name="OMag" inheritViewBox />}
            onClick={() => editor.getAction('editor.action.startFindReplaceAction').run('')}
          >
            Find & Replace
          </Button>
        </Box>

        <MonacoEditor
          language={isJson(activeFile?.name) ? 'json' : 'plaintext'}
          onChange={handleChange}
          onMount={editor => setEditor(editor)}
          value={value}
        />
      </Box>
    </Box>
  )
}
