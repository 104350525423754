import React from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'

import { makeStyles, createStyles } from '@mui/styles'

import { Badge } from 'components/ui-kit/badge/Badge'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { LabelDTO } from '@ketch-com/figurehead'

const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      firstBadge: {
        marginRight: spacing(2),
      },

      capitalCase: {
        textTransform: 'capitalize',
      },
    }),
  { name: 'ViewListLayoutContent' },
)

type Props = {
  labels: LabelDTO[]
  shouldShowNone?: boolean
}

export const CategoryLabels: React.FC<Props> = ({ labels, shouldShowNone = true }) => {
  const classes = useStyles()

  const categoryLabels = (labels || []).filter(l => l.isSystemLabel)

  if (categoryLabels.length < 1) return shouldShowNone ? <EmptyValueRenderer>Not assigned</EmptyValueRenderer> : null

  return (
    <Box display="flex" alignItems="center">
      {categoryLabels?.length > 0 &&
        categoryLabels.map((l, index) => (
          <Badge
            key={l?.value || l.name}
            className={clsx(classes.capitalCase, {
              [classes.firstBadge]: index !== categoryLabels.length - 1,
            })}
          >
            {l?.value || l.name}
          </Badge>
        ))}
    </Box>
  )
}
