import { useEffect, useState } from 'react'
import { useDebounce } from 'react-use'

import { MegaFilterLabelOption } from 'interfaces/labels/MegaFilterLabelOption'

import { LabelDefinitionDTO as LabelDTO } from '@ketch-com/figurehead'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useLabelsInfiniteV2 } from 'api/labels/queries/useLabelsInfiniteV2'

import { getLabelUniqueIdentifier, getTransformLabels } from '../../../utils'
import { AssetListFilters } from '../../../../../interfaces'
import { useLabelsSearchOptions } from '../hooks'

export const useMegaFilterLabelsExpandableRowUtils = () => {
  const { queries, setQueryParam, removeQueryParam } = useQueryParams<AssetListFilters>()
  const [searchString, setSearchString] = useState<string>('')
  const {
    setSearchLabelValue,
    labelOptions: searchLabels,
    isLoadingLabels: isLoadingSearchLabels,
    /* labelOptions, isLoadingLabels, setLabelOptions, queryValue, */
  } = useLabelsSearchOptions()

  const {
    data: respData,
    fetchNextPage: infiniteLabelsFetchNextPage,
    isFetchingNextPage: infiniteLabelsIsFetchingNextPage,
    hasNextPage: infiniteLabelsHasNextPage,
    isLoading: infiniteLabelsIsLoading,
  } = useLabelsInfiniteV2({
    /* onSettled */
  })

  let labelsAllPages: LabelDTO[] = []

  respData?.pages?.forEach?.(page => {
    labelsAllPages = [...labelsAllPages, ...(page?.labels || [])]
  })

  const handleScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    const scrollHeight = e.currentTarget.scrollHeight
    const scrollTop = e.currentTarget.scrollTop
    const scrollDiff = scrollHeight - scrollTop
    const clientHeight = e.currentTarget.clientHeight

    const shouldFetchNextPage = scrollDiff <= clientHeight
    if (shouldFetchNextPage && infiniteLabelsHasNextPage) infiniteLabelsFetchNextPage()
  }

  const transformedLabelsAllPages = getTransformLabels(labelsAllPages)

  const combinedLabelsAllPagesAndSearchLabelsDeDuplicated = [...transformedLabelsAllPages, ...searchLabels].filter(
    (value, index) => {
      const _value = JSON.stringify(value)
      return (
        index ===
        transformedLabelsAllPages.findIndex(obj => {
          return JSON.stringify(obj) === _value
        })
      )
    },
  )

  // split labelsAllPages into selected and unselected arrays
  const [selectedLabels, unselectedLabels] = combinedLabelsAllPagesAndSearchLabelsDeDuplicated.reduce(
    (acc, label) => {
      const labelUniqueIdentifier = getLabelUniqueIdentifier(label)
      const selected = !!(queries?.label || '')?.split(',').includes(labelUniqueIdentifier)
      if (selected) {
        acc[0].push(label)
      } else {
        acc[1].push(label)
      }
      return acc
    },
    [[], []] as MegaFilterLabelOption[][],
  )

  useEffect(() => {
    if (unselectedLabels.length === 0 && infiniteLabelsHasNextPage) {
      infiniteLabelsFetchNextPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unselectedLabels.length, infiniteLabelsHasNextPage])

  useDebounce(
    () => {
      if (searchString) {
        setSearchLabelValue(searchString)
      } else {
        setSearchLabelValue('')
      }
    },
    500,
    [searchString],
  )

  return {
    handleScroll,
    infiniteLabelsHasNextPage,
    infiniteLabelsIsFetchingNextPage,
    infiniteLabelsIsLoading,
    isLoadingSearchLabels,
    queries,
    removeQueryParam,
    searchString,
    selectedLabels,
    setQueryParam,
    setSearchString,
    unselectedLabels,
  }
}
