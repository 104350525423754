import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { IsBusinessFunctionCodeAvailableResponseBodyDTO } from '@ketch-com/figurehead'

type Params = {
  code: string
}

export const checkIfBusinessFunctionCodeAvailable = async ({ code }: Params) => {
  const { data } = await API.get<IsBusinessFunctionCodeAvailableResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/business-functions/${code}/available`,
    }),
  )

  return !!data?.available
}
