import React, { useState } from 'react'

import { CanvasTutorialModal } from 'components/modals/workflows/CanvasTutorialModal'
import { Button } from '@ketch-com/deck'

export const CanvasTutorial: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {isOpen && <CanvasTutorialModal onCancel={() => setIsOpen(false)} />}
      <Button
        variant="flat"
        enableShadowEffect
        sx={{
          backgroundColor: theme => theme.palette.white.main,
          position: 'absolute',
          top: 12,
          right: 12,
          zIndex: 10,
          '&:hover': {
            backgroundColor: theme => theme.palette.white.main,
          },
        }}
        onClick={() => setIsOpen(true)}
      >
        Need help using the canvas?
      </Button>
    </>
  )
}
