import React from 'react'
import { SubscriptionControls } from 'pages/policyCenter/subscriptions/subscriptionControl/list/SubscriptionControls'
import { useSubscriptionControlsPaginated } from 'api/subscriptions/queries/useSubscriptionControlsPaginated'
import { showToast } from 'components/modals/AlertComponent'
import { Box } from '@mui/material'
import { Spinner } from '@ketch-com/deck'

export const SubscriptionControlsContainer: React.FC = () => {
  const {
    data: subscriptionControls,
    isLoading: subscriptionControlsLoading,
    pagination: subscriptionControlsPagination,
  } = useSubscriptionControlsPaginated({
    params: {},
    onError: () => {
      showToast({ content: 'Failed to fetch global control', type: 'error' })
    },
  })

  const isReady = !subscriptionControlsLoading
  if (isReady)
    return (
      <SubscriptionControls
        subscriptionControls={subscriptionControls}
        isLoading={subscriptionControlsLoading}
        pagination={subscriptionControlsPagination}
      />
    )

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" m={6}>
      <Spinner size="32px" thickness={2.5} />
    </Box>
  )
}
