import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useTheme } from 'api/themes-v2/queries/useTheme'
import { useThemeVersion } from 'api/themes-v2/queries/useThemeVersion'
import { useThemeVersions } from 'api/themes-v2/queries/useThemeVersions'
import { ThemeView } from 'pages/consentAndRights/themes/view/ThemeView'

export const ThemeViewContainer: React.FC = () => {
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()
  const { queries } = useQueryParams<{ version?: string }>()
  const { version } = queries

  const handleRedirectToThemesList = () => {
    navigate(RoutesManager.deployment.themes.root.getURL())
  }

  const { data: theme, isLoading: isThemeLoading } = useTheme({
    params: {
      themeCode: code!,
      includeMetadata: true,
    },
    onError: handleRedirectToThemesList,
  })

  const { data: themeVersion, isLoading: isThemeVersionLoading } = useThemeVersion({
    enabled: !!version,
    params: {
      themeCode: code!,
      version: version!,
    },
    onError: handleRedirectToThemesList,
  })

  const { data: versions, isLoading: isVersionsLoading } = useThemeVersions({
    params: {
      themeCode: code!,
      includeMetadata: true,
      ordering: {
        version: 'desc',
      },
    },
  })

  const isReady = !isVersionsLoading && version ? !isThemeVersionLoading : !isThemeLoading

  return <ThemeView isReady={isReady} theme={version ? themeVersion : theme} versions={versions} />
}
