import { CookieDTO, CookieFormDTO } from 'interfaces/cookies/cookie'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  cookieCode: string
  formData: CookieFormDTO
}

type Response = { cookie: CookieDTO }

export const updateCookie = ({ organizationCode, cookieCode, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/cookies/${cookieCode}`,
    }),
    formData,
  )
