import { useMemo } from 'react'
import * as Yup from 'yup'

import { useBasicDetailsValidation } from './useBasicDetailsValidation'
import { formFieldTypeValidation } from './formFieldTypeValidation'

export const useFormFieldValidationSchema = ({
  isEditMode = false,
  isDetailViewMode = false,
}: { isEditMode?: boolean; isDetailViewMode?: boolean } = {}) => {
  const basicDetailsValidationSchema = useBasicDetailsValidation({ isEditMode })

  return useMemo(
    () =>
      Yup.object().shape({
        ...(isDetailViewMode ? {} : basicDetailsValidationSchema.fields),
        ...formFieldTypeValidation.fields,
      }),
    [basicDetailsValidationSchema, isDetailViewMode],
  )
}
