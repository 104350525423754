import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useNavigate, useLocation } from 'react-router-dom'
import { Waypoint } from 'react-waypoint'
import numeral from 'numeral'

import { Text } from 'components/ui-kit/typography/Text'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { RoutesManager } from 'utils/routing/routesManager'
import { TeamMemberDTO } from '../../__mocks__/generateDummyTeamMembers'
import { useAssetsInfiniteV2 } from 'api/assets/queries/useAssetsInfiniteV2'
import { AssetTypeIcon } from 'pages/assetManager/components'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { InfiniteScrollLoadingIndicator } from 'components/ui-layouts/table/components/infiniteScrollLoadingIndicator'
import { SegmentTabSortOption } from '../../components'
import { staticDescendingArray } from '../../__mocks__/generateDescendingArray'

const PREFIX = 'DataUsageMenu'

const classes = {
  container: `${PREFIX}-container`,
  searchInput: `${PREFIX}-searchInput`,
  assetTypeIcon: `${PREFIX}-assetTypeIcon`,
  chevronRightIcon: `${PREFIX}-chevronRightIcon`,
  dataUsageListItem: `${PREFIX}-dataUsageListItem`,
  tabClassName: `${PREFIX}-tabClassName`,
}

const StyledBox = styled(Box)(({ theme: { spacing, palette } }) => ({
  [`&.${classes.container}`]: {
    flex: 1,
    maxWidth: 358,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: spacing(1.375),
    backgroundColor: palette.white.main,
    maxHeight: 732,
    padding: spacing(2.5),
    overflowY: 'auto',
  },

  [`& .${classes.searchInput}`]: {
    border: 'none',
    borderBottom: `1px solid ${palette.iron.main}`,
    borderRadius: 0,
  },

  [`& .${classes.assetTypeIcon}`]: {
    height: 14,
    width: 14,
    marginRight: spacing(1.5),
  },

  [`& .${classes.chevronRightIcon}`]: {
    color: palette.fadedDarkGrey.main,
    height: 16,
    '&[data-active="active"]': {
      color: palette.darkDusk.main,
    },
  },

  [`& .${classes.dataUsageListItem}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(1.25, 0.75, 1.25, 1.25),
    borderRadius: spacing(1.375),
    '&:hover': {
      backgroundColor: palette.sphereFaded.main,
      cursor: 'pointer',
    },
    '&[data-active="active"]': {
      backgroundColor: palette.sphereFaded.main,
      cursor: 'unset',
    },
  },

  [`& .${classes.tabClassName}`]: {
    padding: spacing(0.75, 1.5),
    minHeight: 27,
  },
}))

export enum TabSortType {
  ASC = 'asc',
  DEC = 'dec',
}

type Props = { teamMembers: TeamMemberDTO[] }

export const DataUsageMenu: React.FC<Props> = props => {
  const navigate = useNavigate()

  const [sort, setSort] = useState<TabSortType>(TabSortType.ASC)
  const [searchString, setSearchString] = useState('')
  const location = useLocation()
  const userId = location?.pathname?.split('/')?.[4] || ''

  const {
    data: assets,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useAssetsInfiniteV2({
    params: {
      limit: 20,
      query: searchString,
    },
  })

  const tabFilterOptions = [
    {
      id: TabSortType.ASC,
      name: <SegmentTabSortOption type={TabSortType.ASC} isActive={TabSortType.ASC === sort} />,
    },
    {
      id: TabSortType.DEC,
      name: <SegmentTabSortOption type={TabSortType.DEC} isActive={TabSortType.DEC === sort} />,
    },
  ]

  return (
    <StyledBox className={classes.container}>
      <Box display="flex" alignItems="center" mb={3}>
        <Box display="flex" alignItems="center">
          <SearchInput
            size="small"
            fullWidth
            className={classes.searchInput}
            onChange={nextSearchString => setSearchString(nextSearchString)}
            onClear={() => setSearchString('')}
            value={searchString}
          />
        </Box>

        <SegmentTabs
          tabClassName={classes.tabClassName}
          value={sort}
          tabs={tabFilterOptions}
          onChange={sort => setSort(sort)}
        />
      </Box>

      {isLoading ? <Spinner /> : null}

      {assets.map((assetSummary, index) => {
        const isActive = assetSummary?.asset?.resource?.id === userId
        const dataActive = isActive ? 'active' : ''
        const totalRequests = 43958
        const queriesCount = Math.round((staticDescendingArray[index] / 100) * totalRequests)

        return (
          <Box
            key={assetSummary?.asset?.resource?.id}
            data-active={dataActive}
            onClick={() => {
              const targetPath = RoutesManager.insights.activityAndAccess.dataUsage.view.root.getURL({
                code: assetSummary?.asset?.resource?.id,
              })
              navigate(`${targetPath}?index=${index}`)
            }}
            className={classes.dataUsageListItem}
          >
            {/* Name */}
            <Box display="flex" alignItems="center">
              {dataActive ? (
                <AssetTypeIcon
                  assetType={assetSummary?.asset?.resource?.resourceType?.canonicalResourceType || 0}
                  className={classes.assetTypeIcon}
                />
              ) : (
                <AssetTypeIcon
                  assetType={assetSummary?.asset?.resource?.resourceType?.canonicalResourceType || 0}
                  className={classes.assetTypeIcon}
                />
              )}
              <Text size={14} lineHeight={2.5} weight={isActive ? 600 : undefined}>
                {assetSummary?.asset?.resource?.name}
              </Text>
            </Box>
            {(assets?.length || 0) - 5 === index ? (
              <Waypoint
                onEnter={() => {
                  if (!isFetchingNextPage && hasNextPage) fetchNextPage()
                }}
              />
            ) : null}
            {/* Asset Count */}
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Text size={12} color={isActive ? 'black' : 'darkDuskFaded'} weight={500}>
                {numeral(queriesCount).format('0,0')}
              </Text>
              <ChevronRightIcon className={classes.chevronRightIcon} data-active={dataActive} />
            </Box>
          </Box>
        )
      })}

      <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
    </StyledBox>
  )
}
