import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { UpdateTrackerPropertyDisclosureBody, Tracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type Params = APIRequestParams<{
  data: UpdateTrackerPropertyDisclosureBody
  encodedTrackerKey: string
  propertyId: string
}>

export const upsertTrackerPropertyDisclosure = ({ data, encodedTrackerKey, propertyId }: Params) => {
  return API.put<Tracker>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/trackers/${encodedTrackerKey}/disclosure/${propertyId}`,
    }),
    data,
  )
}
