import React from 'react'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { Typography } from '@mui/material'
import { useDeleteTheme } from 'api/themes-v3/mutations/useDeleteTheme'
import { showToast } from '../AlertComponent'

type Props = {
  themeId: string
  onSubmit?: () => void
  onCancel: () => void
}

export const DeleteThemeModalV3: React.FC<Props> = ({ themeId, onSubmit, onCancel }) => {
  const { mutateAsync: handleDeleteTheme, isLoading: isThemeDeleting } = useDeleteTheme()

  const handleSubmit = async () => {
    try {
      await handleDeleteTheme({
        params: {
          id: themeId || '',
        },
        onSuccess: () => {
          onSubmit?.()
          showToast({ content: 'Theme Deleted', type: 'success' })
        },
      })
    } catch {}
  }

  return (
    <Dialog
      title="Delete Theme"
      contentWidth={550}
      submitBtnLabel="Delete"
      cancelBtnLabel="Close"
      onSubmitBtnClick={handleSubmit}
      onCancelBtnClick={onCancel}
      isSaving={isThemeDeleting}
    >
      <Typography variant="body">Are you sure you want to delete this theme?</Typography>
    </Dialog>
  )
}
