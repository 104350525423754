import React from 'react'
import { Badge } from 'components/ui-kit/badge/Badge'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { Box } from '@mui/material'
import { Table } from 'components/ui-layouts/table/Table'
import { getInferredClassifications } from 'pages/assetManager/classifications/utils'

type Props = {}

export const ClassificationsInferred: React.FC<Props> = () => {
  return (
    <Box>
      <Table
        items={getInferredClassifications()}
        cellSettings={{
          classification: {
            label: 'Classification',
            width: 600,
            cellRenderer: ({ name }) => <Badge>{name}</Badge>,
          },
          description: {
            label: 'Description',
            cellRenderer: ({ description }) => <TextCellRenderer value={description} />,
          },
        }}
      />
    </Box>
  )
}
