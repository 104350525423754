import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material'

import clsx from 'clsx'
import { Collapse } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { isFunction } from 'lodash'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { MaybeUndefined, ObjectLiteral } from 'interfaces'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { InfiniteScrollLoadingIndicator } from './components/infiniteScrollLoadingIndicator'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      table: {
        width: '100%',
      },
      tableHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',

        '& $tableRow': {
          '& $tableCell': {
            fontWeight: 600,
            minHeight: 28,
            minWidth: 1,
            fontSize: 12,
          },
        },
      },
      tableBody: {},
      tableRowWrapper: {
        width: '100%',

        '&:not(:last-child)': {
          borderBottom: `1px solid ${palette.iron.main}`,
        },
      },
      tableRowWrapperWithoutBorderBottom: {
        width: '100%',
      },
      tableRowWrapperExpanded: {
        borderBottom: `1px solid ${palette.iron.main}`,
      },
      tableRow: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        boxSizing: 'border-box',
      },
      tableRowExpandContent: {
        padding: '5px 0 5px 40px',
        background: palette.ironLight.main,
      },
      removeTableRowExpandContentPadding: {
        padding: 0,
      },
      tableRowExpandContentBorderTop: {
        borderTop: `1px solid ${palette.darkDusk.main}`,
      },
      tableRowClickable: {
        cursor: 'pointer',

        '&:hover': {
          background: palette.fadedGrey.main,

          '& $chevronIcon': {
            opacity: 0.7,
          },
        },
      },
      tableCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: palette.darkDusk.main,
        fontSize: typography.pxToRem(14),
        padding: '5px 0 5px 18px',
        minHeight: 52,
        minWidth: 1,
      },
      smallHeaderText: {
        fontSize: typography.pxToRem(12),
      },
      capitalizedHeaderText: {
        textTransform: 'capitalize',
      },
      tableCellExpandCollapse: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: palette.darkDusk.main,
        fontSize: typography.pxToRem(14),
        minHeight: 52,
        minWidth: 40,
      },
      tableCellIndicatorColumn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 12,
      },
      cellContent: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      pending: {
        minHeight: 52,
      },
      pendingContainer: {
        width: '100%',
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      chevronIcon: {
        color: palette.darkDusk.main,
        fontSize: typography.pxToRem(20),
      },

      // Variants:
      variantListView: {},
      variantPageView: {
        width: 'auto',
        boxSizing: 'border-box',
        marginLeft: -16,
        marginRight: -16,
      },
      variantNested: {},
      variantModal: {
        width: 'auto',
        boxSizing: 'border-box',
        marginLeft: -16,
      },
      nonExpandableRowContainer: {
        padding: '0 18px 18px',
      },
      removeTableRowNonExpandContentPadding: {
        padding: 0,
      },
      removeTableCellPadding: {
        padding: 0,
      },
    }),
  { name: 'Table' },
)

export type TableVariants = 'page' | 'list' | 'nested' | 'modal'

export interface Props<T> {
  /** Check if current iterator row has ability to expand */
  isRowExpandable?: (item: T, rowIndex: number) => boolean
  /** Rows to expand on mount */
  expandRowsOnMount?: number[]
  /** Component expandable row item renderer */
  renderExpandableRow?: (item: T, rowIndex: number) => React.ReactNode
  /** Component indicator column item renderer */
  renderIndicatorColumn?: (item: T, rowIndex: number) => React.ReactNode
  /** Component variant -support different margins */
  variant?: TableVariants
  /** Component in loading state */
  pending?: boolean
  /** Small header text toggle */
  isSmallHeaderText?: boolean
  /** Capitalized header text toggle */
  isCapitalizedHeaderText?: boolean
  /** Remove expanded content container padding toggle */
  shouldRemoveTableRowExpandContentPadding?: boolean
  /** Remove non-expandable row padding toggle */
  shouldRemoveTableRowNonExpandContentPadding?: boolean
  /** Remove celling padding toggle */
  shouldRemoveCellPadding?: boolean
  /** Boolean to toggle if table rows have bottom border */
  shouldRowHaveBorderBottom?: boolean
  /** Config prop for "check all" functionality */
  hasCheckAll?: boolean
  /** Component condition to display or hide header */
  hideHeader?: boolean
  /** Component condition to add border to expand content container */
  hasRowExpandContentBorderTop?: boolean
  /** Component list of items which will result in table rows and columns */
  items: T[]
  /** Component custom className */
  className?: string
  /** Component expandable content className */
  tableRowExpandContentContainerClassName?: string
  /** Component row custom className (string or function) */
  rowClassName?: string | ((row: T, rowIndex: number) => MaybeUndefined<string>)
  /** Component row unique key identifier (string or function) */
  rowIdKey?: string | ((row: T, rowIndex: number) => string | number)
  /** Component columns (width / labels) config */
  cellSettings: {
    [key: string]: {
      showCell?: boolean
      label?: string
      width?: number
      flex?: number
      justifyContent?: 'flex-start' | 'flex-end' | 'center'
      labelNodeRenderer?: (items: T[]) => React.ReactNode
      cellRenderer: (row: T, rowIndex: number) => React.ReactNode | React.ReactNode[]
      className?: string | ((row: T | 'header', rowIndex?: number) => MaybeUndefined<string>)
      withCellContentStyle?: boolean
    }
  }
  /** Component row click handler */
  onRowClick?: (row: T, rowIndex: number, event?: React.MouseEvent<HTMLDivElement>) => void
  /** Component container sx prop */
  containerSx?: SxProps<Theme>
  /** Component row sx prop */
  rowSx?: SxProps<Theme>
  /** Component header sx prop */
  headerSx?: SxProps<Theme>
  /** Toggle for turbo buttons */
  shouldHideTurboButtons?: boolean
  /** Toggle for empty state */
  emptyState?: React.ReactNode
  /** Toggle for fetching next page */
  isFetchingNextPage?: boolean
  /** Check if current iterator row needs non expandable row */
  shouldRenderNonExpandableRow?: (item: T, rowIndex: number) => boolean
  /** Renders additional non expandable row */
  renderNonExpandableRow?: (item: T, rowIndex: number) => React.ReactNode
  /** non expandable row container className */
  nonExpandableRowContainerClassName?: string
}

export function Table<T extends ObjectLiteral>({
  isRowExpandable,
  expandRowsOnMount,
  pending = false,
  hideHeader = false,
  isSmallHeaderText = false,
  isCapitalizedHeaderText = false,
  hasCheckAll = false,
  shouldRemoveTableRowExpandContentPadding = false,
  shouldRemoveTableRowNonExpandContentPadding = false,
  shouldRemoveCellPadding = false,
  variant = 'list',
  items,
  className,
  cellSettings,
  rowClassName,
  tableRowExpandContentContainerClassName,
  rowIdKey,
  onRowClick,
  renderExpandableRow,
  renderIndicatorColumn,
  hasRowExpandContentBorderTop = false,
  shouldRowHaveBorderBottom = true,
  containerSx,
  headerSx,
  rowSx,
  emptyState = false,
  isFetchingNextPage = false,
  shouldRenderNonExpandableRow,
  renderNonExpandableRow,
  nonExpandableRowContainerClassName,
}: Props<T>) {
  const classes = useStyles()
  const cellKeys = Object.keys(cellSettings)
  // TODO:BAC Maybe lazily initialize this with logic to expand if filters are checked based on URL query params?
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({})
  const supportsExpandCollapse = !!renderExpandableRow
  const supportsIndicatorColumn = !!renderIndicatorColumn
  const componentClassList = clsx(
    classes.table,
    {
      [classes.variantPageView]: variant === 'page',
      [classes.variantListView]: variant === 'list',
      [classes.variantNested]: variant === 'nested',
      [classes.variantModal]: variant === 'modal',
    },
    className,
  )

  useEffect(() => {
    if (expandRowsOnMount?.length) {
      expandRowsOnMount?.forEach?.(rowIndex => {
        setExpandedRows(prevState => ({ ...prevState, [rowIndex]: true }))
      })
    }
  }, [expandRowsOnMount])

  return (
    <Box className={componentClassList} sx={containerSx}>
      {/* Table Header */}

      {!hideHeader && !emptyState && (
        <Box className={classes.tableHeader} sx={headerSx}>
          <Box className={classes.tableRow}>
            {supportsExpandCollapse && <Box className={classes.tableCellExpandCollapse} />}

            {supportsIndicatorColumn && <Box className={classes.tableCellIndicatorColumn} />}

            {cellKeys.map((cellKey, index) => {
              const { label, width, flex = 1, className, labelNodeRenderer, showCell = true } = cellSettings[cellKey]
              const extraClassName = isFunction(className) ? className('header') : className

              const extraStyles = {
                flex: width ? 0 : flex,
                ...(!!width && {
                  flexBasis: width,
                }),
              }

              return labelNodeRenderer ? (
                <Box
                  key={cellKey}
                  className={clsx(classes.tableCell, extraClassName, {
                    [classes.smallHeaderText]: isSmallHeaderText,
                    [classes.capitalizedHeaderText]: isCapitalizedHeaderText,
                    [classes.removeTableCellPadding]: shouldRemoveCellPadding,
                  })}
                  style={extraStyles}
                >
                  {labelNodeRenderer(items)}
                </Box>
              ) : (
                showCell && (
                  <Box
                    key={cellKey}
                    className={clsx(classes.tableCell, extraClassName, {
                      [classes.smallHeaderText]: isSmallHeaderText,
                      [classes.capitalizedHeaderText]: isCapitalizedHeaderText,
                      [classes.removeTableCellPadding]: shouldRemoveCellPadding,
                    })}
                    style={extraStyles}
                  >
                    {label}
                  </Box>
                )
              )
            })}
          </Box>
        </Box>
      )}

      {/* Table Body */}

      {pending ? (
        <Box className={classes.tableBody}>
          <Box className={classes.pendingContainer}>
            <Spinner />
          </Box>
        </Box>
      ) : (
        <Box className={classes.tableBody}>
          {!!emptyState
            ? emptyState
            : items.map((rowData, rowIndex) => {
                const showNonExpandableRow = shouldRenderNonExpandableRow?.(rowData, rowIndex) || false
                const isExpandable = isRowExpandable?.(rowData, rowIndex) || false
                const isExpanded = expandedRows[rowIndex]
                const rowClassList = clsx(
                  classes.tableRow,
                  {
                    [classes.tableRowClickable]: isFunction(onRowClick) || isExpandable,
                  },
                  isFunction(rowClassName) ? rowClassName(rowData, rowIndex) : rowClassName,
                )

                const handleRowClick = (event: React.MouseEvent<HTMLDivElement>) => {
                  if (isExpandable) {
                    if (!isExpanded) {
                      setExpandedRows(prevState => ({ ...prevState, [rowIndex]: true }))
                    } else {
                      setExpandedRows(prevState => ({ ...prevState, [rowIndex]: false }))
                    }
                  } else {
                    onRowClick?.(rowData, rowIndex, event)
                  }
                }

                /* Table Rows */

                return (
                  <Box
                    key={rowIdKey ? (isFunction(rowIdKey) ? rowIdKey(rowData, rowIndex) : rowData[rowIdKey]) : rowIndex}
                    className={clsx({
                      [classes.tableRowWrapperExpanded]: isExpanded,
                      [classes.tableRowWrapper]: shouldRowHaveBorderBottom,
                      [classes.tableRowWrapperWithoutBorderBottom]: !shouldRowHaveBorderBottom,
                    })}
                    sx={rowSx}
                  >
                    <Box
                      className={rowClassList}
                      onClick={handleRowClick}
                      id={
                        rowIdKey ? (isFunction(rowIdKey) ? rowIdKey(rowData, rowIndex) : rowData[rowIdKey]) : rowIndex
                      }
                      sx={{ ...(!!onRowClick && { '& *': { cursor: 'pointer !important' } }) }}
                    >
                      {supportsExpandCollapse && (
                        <Box className={classes.tableCellExpandCollapse}>
                          {isExpandable && (
                            <>
                              {expandedRows[rowIndex] ? (
                                <KeyboardArrowDownIcon className={classes.chevronIcon} />
                              ) : (
                                <ChevronRightIcon className={classes.chevronIcon} />
                              )}
                            </>
                          )}
                        </Box>
                      )}

                      {supportsIndicatorColumn && (
                        <Box className={classes.tableCellIndicatorColumn}>
                          {renderIndicatorColumn?.(rowData, rowIndex)}
                        </Box>
                      )}

                      {cellKeys.map(cellKey => {
                        const {
                          cellRenderer,
                          width,
                          flex = 1,
                          className,
                          showCell = true,
                          justifyContent,
                          withCellContentStyle = true,
                        } = cellSettings[cellKey]
                        const extraClassName = isFunction(className) ? className(rowData, rowIndex) : className

                        const extraStyles = {
                          flex: width ? 0 : flex,
                          ...(!!width && {
                            flexBasis: width,
                          }),
                          ...(justifyContent && {
                            justifyContent,
                          }),
                        }

                        return (
                          showCell && (
                            <Box
                              key={cellKey}
                              className={clsx(classes.tableCell, extraClassName, {
                                [classes.removeTableCellPadding]: shouldRemoveCellPadding,
                              })}
                              style={extraStyles}
                            >
                              <span
                                className={clsx({
                                  [classes.cellContent]: withCellContentStyle,
                                })}
                              >
                                {cellRenderer(rowData, rowIndex)}
                              </span>
                            </Box>
                          )
                        )
                      })}
                    </Box>

                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                      <Box
                        className={clsx(
                          classes.tableRowExpandContent,
                          {
                            [classes.removeTableRowExpandContentPadding]: shouldRemoveTableRowExpandContentPadding,
                            [classes.tableRowExpandContentBorderTop]: hasRowExpandContentBorderTop,
                          },
                          tableRowExpandContentContainerClassName,
                        )}
                      >
                        {renderExpandableRow?.(rowData, rowIndex)}
                      </Box>
                    </Collapse>

                    {showNonExpandableRow ? (
                      <Box
                        className={clsx(classes.nonExpandableRowContainer, nonExpandableRowContainerClassName, {
                          [classes.removeTableRowNonExpandContentPadding]: shouldRemoveTableRowNonExpandContentPadding,
                        })}
                      >
                        {renderNonExpandableRow?.(rowData, rowIndex)}
                      </Box>
                    ) : null}
                  </Box>
                )
              })}
        </Box>
      )}
      <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
    </Box>
  )
}
