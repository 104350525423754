import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import clsx from 'clsx'

import { ObjectLiteral } from 'interfaces'
import { Pagination } from 'api/common/paginatedQuery'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { Props as TableProps, Table } from 'components/ui-layouts/table/Table'
import { Group } from 'components/ui-layouts/group/Group'
import { Text } from 'components/ui-kit/typography/Text'
import { ButtonPagination } from 'components/ui-kit/buttonPagination/ButtonPagination'
import { usePaginationHelpers } from 'utils/hooks/usePaginationHelpers'
import { useInputStyles } from 'components/ui-kit/input/Input'
import { InputBase } from '@mui/material'

export const useTablePaginatedStyles = makeStyles(
  createStyles({
    pagination: {
      marginTop: 16,
    },
    targetPageInput: {
      maxWidth: 60,
      // hide up/down arrows on number input
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  }),
  { name: 'TablePaginated' },
)

export interface Props<T> extends TableProps<T> {
  pagination: Pagination
}

export function TablePaginated<T extends ObjectLiteral>({
  pagination,
  pending = false,
  shouldHideTurboButtons = false,
  ...tableProps
}: Props<T>) {
  const classes = useTablePaginatedStyles()
  const inputClasses = useInputStyles()

  const {
    totalPages,
    isFirst,
    isLast,
    handleLoadPrevPage,
    handleLoadNextPage,
    isPrevPending,
    isNextPending,
    page,
    handleSetTargetPage,
    isInputPageModeActive,
    setIsInputPageModeActive,
    setState: setPendingState,
  } = usePaginationHelpers({ pagination })

  return (
    <>
      <Table pending={pending} {...tableProps} />

      {totalPages > 1 && (
        <Group className={classes.pagination} gap={8} inline={false} justifyContent="flex-end">
          {!shouldHideTurboButtons && (
            <ButtonPagination
              size="regular"
              disabled={isFirst || isNextPending}
              pending={false}
              direction="prev"
              title="Go to first page"
              isSkipToLimit={true}
              onClick={() => {
                setPendingState({ isPrevPending: true })
                handleSetTargetPage(0)
              }}
            />
          )}

          <ButtonPagination
            size="regular"
            disabled={isFirst || isNextPending}
            pending={isPrevPending}
            title="Go to previous page"
            direction="prev"
            onClick={handleLoadPrevPage}
          />

          {isInputPageModeActive ? (
            <InputBase
              type="number"
              id="targetPageInput"
              onBlur={() => setIsInputPageModeActive(false)}
              className={clsx(inputClasses.baseInput, inputClasses.sizeSmall, classes.targetPageInput)}
              onKeyPress={e => {
                if (e.key === 'Escape') setIsInputPageModeActive(false)
                if (e.key === 'Enter') {
                  const targetPageInputElement = document.getElementById('targetPageInput') as HTMLInputElement
                  const targetPage = Number(targetPageInputElement.value) - 1
                  if (targetPage >= 0 && targetPage < totalPages) {
                    handleSetTargetPage(targetPage)
                    setIsInputPageModeActive(false)
                  } else {
                    showToast({ content: `Page ${targetPage + 1} is not available`, type: 'error' })
                  }
                }
              }}
            />
          ) : (
            <Text size={14} color="grey" onClick={() => setIsInputPageModeActive(true)}>
              {page + 1} / {totalPages}
            </Text>
          )}

          <ButtonPagination
            size="regular"
            disabled={isLast || isPrevPending}
            pending={isNextPending}
            direction="next"
            title="Go to next page"
            onClick={handleLoadNextPage}
          />

          {!shouldHideTurboButtons && (
            <ButtonPagination
              size="regular"
              disabled={isLast || isPrevPending}
              pending={false}
              direction="next"
              title="Go to last page"
              isSkipToLimit={true}
              onClick={() => {
                setPendingState({ isNextPending: true })
                handleSetTargetPage(totalPages - 1)
              }}
            />
          )}
        </Group>
      )}
    </>
  )
}
