import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchDataMapVisualization } from '../fetchers/fetchDataMapVisualization'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.dataMapVisualization,
  queryFn: fetchDataMapVisualization,
  select: res => res?.data || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useDataMapVisualization = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
