import { PreferenceCenterPage } from '@ketch-sdk/ketch-types'
import { PreviewDisplayMode } from './enums'

export const previewDisplayModeLabels = {
  [PreviewDisplayMode.Desktop]: 'Desktop',
  [PreviewDisplayMode.Mobile]: 'Mobile',
}

export const previewPreferencePageLabels = {
  [PreferenceCenterPage.Welcome]: '1. Welcome',
  // TODO:JB - Fix numbering here once we enable the privacy policy page
  [PreferenceCenterPage.PrivacyPolicy]: '2. Privacy Policy',
  [PreferenceCenterPage.Purpose]: '2. Purpose',
  [PreferenceCenterPage.Subscriptions]: '3. Subscriptions',
  [PreferenceCenterPage.RequestsHome]: '4. Requests',
  [PreferenceCenterPage.RequestsForm]: 'Right Form',
  [PreferenceCenterPage.RequestsSubmitted]: 'Summary',
}
