import React from 'react'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import { Box, Typography, Tooltip } from '@mui/material'
import { CircularProgress, CircularProgressSize, EmptyState } from '@ketch-com/deck'

import { usePermitStatsOverall } from 'api/permitStatistics/queries/usePermitsStatsOverall'
import { useAppSelector } from 'store/hooks'
import { getPermitStatisticsFilterState } from 'store/permitStatisticsFilterStateSlice/selectors'
import { formatCount } from './utils'
import { ConsentPercentBlock } from './components'
import { Info } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'

const PREFIX = 'PermitStatsOverall'

const classes = {
  infoIcon: `${PREFIX}-infoIcon`,
  tippyWrapper: `${PREFIX}-tippyWrapper`,
}

const StyledBox = styled(Box)(({ theme: { typography, spacing, palette } }) => ({
  [`& .${classes.infoIcon}`]: {
    fontSize: typography.pxToRem(16),
    marginLeft: spacing(1),
  },

  [`& .${classes.tippyWrapper}`]: {
    paddingTop: 3,
    cursor: 'pointer',
    color: palette.darkDuskFaded.main,
    '&:hover': {
      color: palette.darkDusk.main,
    },
  },
}))

type Props = {}

export const PermitStatisticsOverall: React.FC<Props> = () => {
  const { startDate, endDate, identitySpace, jurisdiction, environment, property, purpose } =
    useAppSelector(getPermitStatisticsFilterState)
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])

  const { data: fetchedData, isLoading: isLoadingPermitStatsOverall } = usePermitStatsOverall({
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  const data = [
    {
      consent: fetchedData?.consent || 0,
      noConsent: fetchedData.noConsent || 0,
      noConsentRequired: fetchedData.consentNotRequired || 0,
      name: 'Page A',
    },
  ]

  const total = (data?.[0]?.consent || 0) + (data?.[0]?.noConsent || 0) + (data?.[0]?.noConsentRequired || 0)

  const consentCount = data?.[0]?.consent || 0
  const noConsentCount = data?.[0]?.noConsent || 0
  const noConsentRequiredCount = data?.[0]?.noConsentRequired || 0
  const allowanceRate = ((consentCount + noConsentRequiredCount) / total) * 100
  const consentPercentage = (data[0].consent / total) * 100
  const noConsentPercentage = (data[0].noConsent / total) * 100
  const noConsentRequiredPercentage = (data[0].noConsentRequired / total) * 100

  return (
    <StyledBox
      sx={{
        background: theme => theme.palette.white.main,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 549,
        padding: 4,
        width: 416,
      }}
    >
      {/* Header */}

      <Box display="flex" flexDirection="column">
        <Typography variant="h3">{t('Overall')}</Typography>
        <Typography variant="body" color="darkDuskFaded.main" sx={{ mb: 6.375 }}>
          {t('How much personal data can be used?')}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flex: 1,
        }}
      >
        {/* Row */}

        <Box
          sx={{
            display: 'flex',
            flex: 1,
            ...((isLoadingPermitStatsOverall || isNaN(allowanceRate)) && {
              justifyContent: 'center',
              alignItems: 'center',
            }),
          }}
        >
          {isLoadingPermitStatsOverall || isNaN(allowanceRate) ? (
            <>
              {isLoadingPermitStatsOverall ? (
                <CircularProgress size={CircularProgressSize.xLarge} />
              ) : isNaN(allowanceRate) ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <EmptyState
                    colorTheme="darkDuskFaded"
                    iconName="OFilter"
                    title="No data for selected filter criteria"
                  />
                </Box>
              ) : null}
            </>
          ) : (
            <>
              {/* Col Left */}

              <Box display="flex" flexDirection="column" flex={1}>
                <Typography variant="pound" sx={{ fontSize: '54px', mb: 0.75, fontWeight: 700, lineHeight: '56px' }}>
                  {allowanceRate.toFixed(1)}%
                </Typography>
                <Box display="flex" alignItems="center" mb={9.75}>
                  <Typography variant="smallLabel" color="darkDusk.main">
                    {t('Allowance Rate')}
                  </Typography>

                  <Tooltip
                    className={classes.tippyWrapper}
                    title={t(
                      'The percentage of permits that allow you to use the data for a given purpose across all legal bases.',
                    )}
                  >
                    <Info className={classes.infoIcon} />
                  </Tooltip>
                </Box>
                <Typography variant="h2" sx={{ mb: 0.825, fontWeight: 700 }}>
                  {formatCount(consentCount + noConsentRequiredCount)}
                </Typography>
                <Typography variant="smallLabel" sx={{ mb: 3 }}>
                  {t('Allowed')}
                </Typography>
                <Typography variant="h2" sx={{ mb: 0.825, fontWeight: 700 }}>
                  {formatCount(total)}
                </Typography>
                <Typography variant="smallLabel" sx={{ mb: 3 }}>
                  {t('Total')}
                </Typography>
              </Box>

              {/* Col Right */}

              <Box display="flex" flexDirection="column" flex={1}>
                <Box sx={{ gap: 0.625, width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                  {/* No Consent */}

                  <ConsentPercentBlock
                    title={t('No Consent')}
                    percent={noConsentPercentage}
                    fillColor="graph12.main"
                    textColor="darkDusk"
                    count={noConsentCount}
                  />

                  {/* No Consent Required */}

                  <ConsentPercentBlock
                    title={t('Consent Not Required')}
                    textColor="white"
                    percent={noConsentRequiredPercentage}
                    fillColor="graph13.main"
                    count={noConsentRequiredCount}
                  />

                  {/* Consent  */}

                  <ConsentPercentBlock
                    title={t('Consent')}
                    textColor="white"
                    percent={consentPercentage}
                    fillColor="graph10.main"
                    count={consentCount}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </StyledBox>
  )
}
