import React from 'react'
import { Banner, Button } from '@ketch-com/deck'
import { Box, Typography, AlertColor } from '@mui/material'

type Props = {}

export const BannerExample: React.FC<Props> = props => {
  const BANNER_DATA = [
    { title: 'Success Banner', severity: 'success' },
    { title: 'Warning Banner', severity: 'warning' },
    { title: 'Error Banner', severity: 'error' },
    { title: 'Info Banner', severity: 'info' },
  ]

  const INFOBOX_DATA = [
    {
      title: 'Success InfoBox',
      severity: 'success',
      variant: 'standard',
      actionTitle: 'Some buttons',
    },
    {
      title: 'Warning InfoBox',
      severity: 'warning',
      variant: 'outlined',
      actionTitle: 'Some buttons',
    },
    {
      title: 'Error InfoBox',
      severity: 'error',
      variant: 'filled',
      actionTitle: 'Some buttons',
    },
    {
      title: 'Info InfoBox',
      severity: 'info',
      variant: 'standard',
      actionTitle: 'Some buttons',
    },
  ]

  return (
    <>
      <Typography variant="h3" mb={2}>
        DECK Banner component
      </Typography>
      {BANNER_DATA.map(banner => (
        <Box sx={{ marginTop: 2 }} key={banner.severity as AlertColor}>
          <Banner
            title={banner.title}
            severity={banner.severity as AlertColor}
            action={<Button variant="link">Action</Button>}
          >
            Banner Content
          </Banner>
        </Box>
      ))}

      <Typography variant="h3" mt={4}>
        DECK InfoBox component
      </Typography>

      {INFOBOX_DATA.map(banner => (
        <Box sx={{ marginTop: 2 }} key={banner.severity as AlertColor}>
          <Banner
            isInfoBox
            title={banner.title}
            severity={banner.severity as AlertColor}
            actionTitle={banner.actionTitle}
            variant={banner.variant as 'standard' | 'outlined' | 'filled'}
            action={
              <>
                <Button variant="outlined">Action 1</Button>
                <Button variant="outlined">Action 2</Button>
              </>
            }
          >
            Description of an issue or some overview text about this banner, context or other important information.
          </Banner>
        </Box>
      ))}

      <Typography variant="h3" mt={4}>
        DECK InfoBox Compact component
      </Typography>

      {INFOBOX_DATA.map(banner => (
        <Box sx={{ marginTop: 2 }} key={banner.severity as AlertColor}>
          <Banner
            isInfoBox
            isCompact
            title={banner.title}
            severity={banner.severity as AlertColor}
            actionTitle={banner.actionTitle}
            variant={banner.variant as 'standard' | 'outlined' | 'filled'}
            action={
              <>
                <Button variant="link" color="secondary">
                  Action 1
                </Button>
                <Button variant="link" color="secondary">
                  Action 2
                </Button>
              </>
            }
          >
            Description of an issue or some overview text about this banner, context or other important information.
          </Banner>
        </Box>
      ))}
    </>
  )
}
