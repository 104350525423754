import React from 'react'
import { Box, Grid } from '@mui/material'
import Markdown from 'markdown-to-jsx'

import { AppFormFieldDTO, AppFormFieldDTOType } from 'interfaces/apps'
import { FormDropdownWithDynamicOptions } from '../components/FormDropdownWithDynamicOptions'
import { FormInput } from 'components/form/FormInput'
import { FormRadioGroup } from 'components/form/FormRadioGroup'

type GetFormInputArgs = {
  field: AppFormFieldDTO
  index: number
  isEditMode: boolean
}

export const getFormInput = ({ field, index, isEditMode }: GetFormInputArgs): React.ReactNode => {
  const { editable, showValueOnEdit } = field

  if (!editable && !showValueOnEdit && isEditMode) {
    return null
  }

  if (field.type === AppFormFieldDTOType.RADIO_BUTTON_FORM_COMPONENT_TYPE) {
    return (
      <Box mb={2} key={index}>
        {!!field?.description && (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Markdown>{field.description}</Markdown>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormRadioGroup
              renderLabel={option => option.title}
              formPropertyName={`fields[${index}].value`}
              label={field.title}
              size="medium"
              row
              hideOptionalLabel
              options={(field?.data?.values || [])
                ?.filter(v => v.value !== 'ketch')
                .map(v => ({
                  title: v.label,
                  value: v.value,
                }))}
            />
          </Grid>
        </Grid>
      </Box>
    )
  }

  if (field.type === AppFormFieldDTOType.DROPDOWN_FORM_COMPONENT_TYPE) {
    return <FormDropdownWithDynamicOptions field={field} key={index} index={index} />
  }

  return (
    <Box mb={2} key={index}>
      {!!field?.description && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Markdown>{field.description}</Markdown>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormInput
            disabled={!editable && isEditMode}
            required={field.required}
            fullWidth
            label={field.title}
            formPropertyName={`fields[${index}].value`}
            placeholder={showValueOnEdit || !isEditMode ? field.title : '********'}
            type={!showValueOnEdit && isEditMode ? 'password' : 'text'}
            hint={!showValueOnEdit && isEditMode ? 'Secret value cannot be displayed.' : undefined}
            shouldUpdateDebounced
          />
        </Grid>
      </Grid>
    </Box>
  )
}
