import { ContextVariableDTO } from '@ketch-com/figurehead'
import { DataGrid } from '@ketch-com/deck'
import { variableUsedInWorkflowsColumns } from './variableUsedInWorkflowsTableUtils'

type Props = {
  contextVariable?: ContextVariableDTO
}

export const VariableUsedInWorkflowsTable: React.FC<Props> = ({ contextVariable }) => {
  return (
    <DataGrid
      sx={{
        '&.MuiDataGrid-root': {
          '& .MuiDataGrid-overlayWrapper': {
            height: !contextVariable?.activities?.length ? '150px' : '100%',
          },
        },
      }}
      noRowsOverlayProps={{
        buttonTitle: '',
        subTitle: '',
      }}
      autosizeOnMount
      autosizeOptions={{
        includeHeaders: true,
        includeOutliers: false,
        columns: variableUsedInWorkflowsColumns.map(column => column.field),
        expand: true,
      }}
      getRowHeight={() => 'auto'}
      columns={variableUsedInWorkflowsColumns}
      disableChildrenSorting
      disableColumnMenu
      disableColumnPinning
      disableColumnResize
      disableColumnReorder
      disableRowHoverStates
      disableRowSelectionOnClick
      getRowId={row => row.code}
      rows={contextVariable?.activities || []}
      hideFooter
    />
  )
}
