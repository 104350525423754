import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { CreateRelationshipsRequestBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  formData: CreateRelationshipsRequestBodyDTO
  isV2FeatureFlagEnabled?: boolean
}>

export const createRelationshipV2 = ({ formData, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/relationships`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/relationships`

  return API.post<{}>(
    formatRequestString({
      entityUrl,
    }),
    formData,
  )
}
