import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, Icon, Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import { SubscriptionTopicOrchestrationDTO, SubscriptionTopicTranslationDTO } from '@ketch-com/figurehead'
import { Box, Typography } from '@mui/material'
import { GridValidRowModel } from '@mui/x-data-grid'
import {
  iterableSubscriptionGroupItems,
  shopifySubscriptionGroupItems,
  salesforceMarketingCloudSubscriptionGroupItems,
} from 'pages/policyCenter/subscriptions/constants'
import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'
import { getLanguageEnglishNameByCode } from '../../utils'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'

export interface OrchestrationRowType extends GridValidRowModel {
  key: string
  subscriptionTopicOrchestrations: SubscriptionTopicOrchestrationDTO[]
}
export interface TranslationsRowType extends GridValidRowModel {
  key: string
  subscriptionTopicTranslation: SubscriptionTopicTranslationDTO
}

export const translationsTopicsColumns = (
  organizationLanguages: LanguageWithStatusDTO[],
): GridColDef<TranslationsRowType>[] => [
  {
    align: 'left',
    field: 'language',
    headerAlign: 'left',
    headerName: 'Language',
    sortable: false,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<TranslationsRowType>) => {
      const languageEnglishName = getLanguageEnglishNameByCode(row.key, organizationLanguages)
      return <TableCell title={languageEnglishName} />
    },
  },
  {
    align: 'left',
    field: 'displayName',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Display Name Translation',
    sortable: false,
    renderCell: ({ row: { subscriptionTopicTranslation } }: GridRenderCellParams<TranslationsRowType>) => {
      if (subscriptionTopicTranslation.name) return <TableCell title={subscriptionTopicTranslation.name} />
      return (
        <Typography color="darkDuskFaded.main" variant="body">
          None
        </Typography>
      )
    },
  },
  {
    align: 'left',
    field: 'displayDescription',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Display Description Translation',
    sortable: false,
    renderCell: ({ row: { subscriptionTopicTranslation } }: GridRenderCellParams<TranslationsRowType>) => {
      if (subscriptionTopicTranslation.description)
        return <TableCell title={subscriptionTopicTranslation.description} />
      return (
        <Typography color="darkDuskFaded.main" variant="body">
          None
        </Typography>
      )
    },
  },
]

export const SubscriptionTopicOrchestrationsInfoRowContent = ({
  orchestration,
}: {
  orchestration: OrchestrationRowType
}) => {
  const topicOrchestrations = orchestration.subscriptionTopicOrchestrations
  const navigate = useNavigate()

  const handleOnAppChipClickHandler = (appId?: string) => {
    const targetUrl = RoutesManager.systems.id.root.getURL({
      id: appId,
    })
    navigate(targetUrl)
  }
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" width="100%">
      {topicOrchestrations.map((topicOrchestration, index) => {
        const fieldValue =
          topicOrchestration?.app?.params?.fieldName ||
          topicOrchestration?.app?.params?.groupID ||
          topicOrchestration?.app?.params?.subscriptionGroupId
        if (topicOrchestration?.app) {
          return (
            <Box
              py={1}
              key={index}
              display="flex"
              alignItems="center"
              width="100%"
              gap={3}
              sx={{
                borderBottom: index !== topicOrchestrations.length - 1 ? '1px solid' : 'none',
                borderColor: theme => theme.palette.iron.main,
              }}
            >
              <Chip
                clickable
                label={
                  topicOrchestration?.app?.appName
                    ? topicOrchestration?.app?.appName.charAt(0).toUpperCase() +
                      topicOrchestration?.app?.appName.slice(1)
                    : ''
                }
                icon={
                  <img
                    src={topicOrchestration?.app?.logoUrl}
                    alt={topicOrchestration?.app?.appName}
                    width={24}
                    height={24}
                  />
                }
                deleteIcon={<Icon name="OArrowCRight" />}
                onDelete={() => {
                  handleOnAppChipClickHandler(topicOrchestration?.app?.appId)
                }}
                onClick={() => {
                  handleOnAppChipClickHandler(topicOrchestration?.app?.appId)
                }}
              />
              <Box display="flex" alignItems="center" gap={2}>
                <Status
                  label={topicOrchestration?.app?.connectionName || ''}
                  variant={StatusVariant.filled}
                  status={StatusState.success}
                  icon="OConnectionTrue"
                />
                {topicOrchestration?.app?.params?.subscriptionGroup ? (
                  <Box display="flex" gap={0.5} alignItems="center">
                    <Typography variant="smallLabel" lineHeight="1.4em">
                      Type:
                    </Typography>
                    <Typography variant="smallBody">
                      {[
                        ...iterableSubscriptionGroupItems,
                        ...shopifySubscriptionGroupItems,
                        ...salesforceMarketingCloudSubscriptionGroupItems,
                      ].filter(item => item.id === topicOrchestration?.app?.params?.subscriptionGroup)?.[0]?.name || ''}
                    </Typography>
                  </Box>
                ) : null}
                {fieldValue ? (
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <Typography variant="smallLabel" lineHeight="1.4em">
                      Field:
                    </Typography>
                    <Typography variant="smallBody">{fieldValue}</Typography>
                  </Box>
                ) : null}
              </Box>
            </Box>
          )
        }
        return (
          <Box
            key={index}
            py={1}
            sx={{
              borderBottom: index !== topicOrchestrations.length - 1 ? '1px solid' : 'none',
              borderColor: theme => theme.palette.iron.main,
            }}
            display="flex"
            alignItems="center"
            width="100%"
          >
            <Chip
              clickable
              label={topicOrchestration?.webhook?.name}
              icon={<Icon name="FApi" />}
              deleteIcon={<Icon name="OArrowCRight" />}
              onDelete={() => {
                navigate(RoutesManager.developers.webhooks.root.getURL())
              }}
              onClick={() => {
                navigate(RoutesManager.developers.webhooks.root.getURL())
              }}
            />
          </Box>
        )
      })}
    </Box>
  )
}
