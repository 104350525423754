import React, { memo } from 'react'
import { Box, Typography } from '@mui/material'
import { transponderAWSElasticBeanstalkInstructions, transponderHelmInstructions } from '../../markdown'
import { MarkdownRenderer } from '../MarkdownRenderer'
import { DeploymentMethods, useTransponderCreate } from '../../context'

export const TransponderCreateFormInstructions: React.FC = memo(() => {
  const { deploymentMethod } = useTransponderCreate()

  return (
    <Box marginTop={2}>
      <Typography variant="h2" marginBottom={3}>
        Instructions
      </Typography>
      <Box overflow="auto" maxHeight="80vh">
        <MarkdownRenderer
          markdown={
            deploymentMethod === DeploymentMethods.awsElasticBeanstalk
              ? transponderAWSElasticBeanstalkInstructions
              : transponderHelmInstructions
          }
        />
      </Box>
    </Box>
  )
})
