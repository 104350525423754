import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchUnreadNotificationsCount } from '../fetchers/fetchUnreadNotificationsCount'
import { useMatch } from 'react-router-dom'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.notificationsUnreadCount,
  queryFn: fetchUnreadNotificationsCount,
  select: res => res?.data?.notificationsCount?.unread || 0,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

const thirtySeconds = 1000 * 30 * 1
const oneSecond = 1000 * 1
const subjectCommunicationPath = '/orchestration/rights-queue/view/:id/subject-communication'

export const useUnreadNotificationsCount = (config?: Config) => {
  const match = useMatch(subjectCommunicationPath)
  return useCustomQuery({
    ...config,
    /* Decrease refetch interval when the user is on subjectCommunicationPath */
    refetchInterval: !!match ? oneSecond : thirtySeconds,
    ...(!!match && {
      /* implement exponential rolloff of retry request interval in the event of error */
      retryDelay: attempt => {
        return Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000)
      },
    }),
    params: {
      ...config?.params,
    },
  })
}
