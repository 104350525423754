import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Button, TableCell } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { Box, Typography } from '@mui/material'
import { PurposeDTO } from '@ketch-com/figurehead'

const BusinessFunctionsPurposeCell = ({ purpose }: { purpose: PurposeDTO }) => {
  const navigate = useNavigate()
  return (
    <TableCell
      sx={{ margin: '8px 0' }}
      onClick={() => {
        navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code: purpose.code }))
      }}
    >
      <Box
        display="flex"
        sx={{
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
          }}
          variant="label"
          color="darkDusk.main"
        >
          {purpose.name}
        </Typography>
        <Typography variant="footnote" color="darkDuskFaded.main">
          {purpose.code}
        </Typography>
      </Box>
    </TableCell>
  )
}

const BusinessFunctionsMoreCell = ({ purpose }: { purpose: PurposeDTO }) => {
  const navigate = useNavigate()
  return (
    <TableCell
      sx={{ margin: '8px 0' }}
      onClick={() => {
        navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code: purpose.code }))
      }}
    >
      <Button
        color="secondary"
        onClick={() => navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code: purpose.code }))}
      >
        View
      </Button>
    </TableCell>
  )
}
export const businessFunctionsOverviewColumns: GridColDef<PurposeDTO>[] = [
  {
    align: 'left',
    field: 'purpose',
    headerAlign: 'left',
    headerName: 'Purpose',
    flex: 0.3,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) => {
      return <BusinessFunctionsPurposeCell purpose={row} />
    },
  },
  {
    align: 'left',
    field: 'description',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Description',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) => (
      <TableCell sx={{ margin: '8px 0' }} title={row.description} />
    ),
  },
  {
    align: 'left',
    field: 'more',

    headerAlign: 'left',
    headerName: '',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) => {
      return <BusinessFunctionsMoreCell purpose={row} />
    },
  },
]
