import { useContext } from 'react'
import { StepDetailsTileWithEditMode } from 'pages/orchestration/rightsQueue/stepDetails/components'
import { RightInvocationWorkflowStepDetailViewContext } from '../context'
import { SubjectDetailsContent, IdentitySpacesContentV2 } from './components'
import { Icon, Widget, theme } from '@ketch-com/deck'
import { Box } from '@mui/material'

type Props = {}

export const StepDetailsColumn: React.FC<Props> = () => {
  const { stepDetails } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const isRequestDetailsCollapsible = Boolean(Object.keys(stepDetails?.subjectDetails || {}).length)

  const identities = stepDetails?.identitySpacesList
  const identitiesLength = identities?.length || 0
  const isIdentitySpacesCollapsible = Boolean(identitiesLength)

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Widget
        expandable
        title="Request Details"
        sx={{
          '& .MuiAccordionDetails-root > div > div': {
            justifyContent: !isRequestDetailsCollapsible ? 'center' : 'auto',
          },
        }}
        content={
          isRequestDetailsCollapsible && <SubjectDetailsContent subjectDetails={stepDetails?.subjectDetails || {}} />
        }
        emptyStateProps={{
          title: 'No Subject Details',
          subTitle: 'This DSR request is missing Subject Details.',
        }}
      />

      <Widget
        expandable
        title="Identifiers"
        subTitle={!!identitiesLength ? `${identitiesLength} spaces available` : ''}
        content={isIdentitySpacesCollapsible && <IdentitySpacesContentV2 identities={identities} />}
        emptyStateProps={{
          title: 'No Identifiers',
          subTitle: 'This DSR request has no Identifiers associated.',
          customIcon: <Icon name="OFingerprint" width={44} height={44} iconColor={theme.palette.darkDuskFaded.main} />,
        }}
      />

      <StepDetailsTileWithEditMode title="Data Subject Variables" />
    </Box>
  )
}
