export const developmentUtils = {
  root: {
    pattern: '/development-utils',
    getURL: () => '/development-utils',
  },

  sandbox: {
    pattern: '/sandbox',
    getURL: () => '/development-utils/sandbox',
    autoCompleteMenuSingleSelectExample: {
      pattern: '/autocomplete-menu-single-select',
      getURL: () => '/development-utils/sandbox/autocomplete-menu-single-select',
    },
    autoCompleteMenuMultiSelectExample: {
      pattern: '/autocomplete-menu-multi-select',
      getURL: () => '/development-utils/sandbox/autocomplete-menu-multi-select',
    },
    autocompleteCompareContrast: {
      pattern: '/autocomplete-compare-contrast',
      getURL: () => '/development-utils/sandbox/autocomplete-compare-contrast',
    },
    modalImplementationExample: {
      pattern: '/modal',
      getURL: () => '/development-utils/sandbox/modal',
    },
    buttons: {
      pattern: '/buttons',
      getURL: () => '/development-utils/sandbox/buttons',
    },

    segmentTabsExample: {
      pattern: '/segment-tabs',
      getURL: () => '/development-utils/sandbox/segment-tabs',
    },

    tabsExample: {
      pattern: '/tabs',
      getURL: () => '/development-utils/sandbox/tabs',
    },
    datePickerExample: {
      pattern: '/date-picker',
      getURL: () => '/development-utils/sandbox/date-picker',
    },
    dateRangePickerExample: {
      pattern: '/date-range-picker',
      getURL: () => '/development-utils/sandbox/date-range-picker',
    },
    inlineEdit: {
      pattern: '/inline-edit',
      getURL: () => '/development-utils/sandbox/inline-edit',
    },
    autocompleteInlineEdit: {
      pattern: '/autocomplete-inline-edit',
      getURL: () => '/development-utils/sandbox/autocomplete-inline-edit',
    },
    status: {
      pattern: '/status',
      getURL: () => '/development-utils/sandbox/status',
    },
    avatar: {
      pattern: '/avatar',
      getURL: () => '/development-utils/sandbox/avatar',
    },

    dataGridPaginationExample: {
      pattern: '/data-grid-pagination',
      getURL: () => '/development-utils/sandbox/data-grid-pagination',
    },

    dataGridInfinityScrollExample: {
      pattern: '/data-grid-infinity-scroll',
      getURL: () => '/development-utils/sandbox/data-grid-infinity-scroll',
    },
    dataGridInfinityBrowserScrollExample: {
      pattern: '/data-grid-infinity-browser-scroll',
      getURL: () => '/development-utils/sandbox/data-grid-infinity-browser-scroll',
    },
    dataGridInfinityWaypointScrollExample: {
      pattern: '/data-grid-infinity-waypoint-scroll',
      getURL: () => '/development-utils/sandbox/data-grid-infinity-waypoint-scroll',
    },
    dataGridPartOfLayoutExample: {
      pattern: '/data-grid-part-of-layout-example',
      getURL: () => '/development-utils/sandbox/data-grid-part-of-layout-example',
    },
    infoRow: {
      pattern: '/info-row',
      getURL: () => '/development-utils/sandbox/info-row',
    },
    dropListButton: {
      pattern: '/drop-list-button',
      getURL: () => '/development-utils/sandbox/drop-list-button',
      async: {
        pattern: '/drop-list-async-button',
        getURL: () => '/development-utils/sandbox/drop-list-async-button',
      },
    },
    progress: {
      pattern: '/progress',
      getURL: () => '/development-utils/sandbox/progress',
    },

    dropzone: {
      pattern: '/dropzone',
      getURL: () => '/development-utils/sandbox/dropzone',
    },
    dataGridCustomFilters: {
      pattern: '/data-grid-custom-filters',
      getURL: () => '/development-utils/sandbox/data-grid-custom-filters',
    },
    contentGroupPartOfLayoutExample: {
      pattern: '/content-group-part-of-layout-example',
      getURL: () => '/development-utils/sandbox/content-group-part-of-layout-example',
    },
    dataGridExpandableExample: {
      pattern: '/data-grid-expandable',
      getURL: () => '/development-utils/sandbox/data-grid-expandable',
    },
    bannerExample: {
      pattern: '/banner',
      getURL: () => '/development-utils/sandbox/banner',
    },
    createLayoutFormExample: {
      pattern: '/create-layout-form',
      getURL: () => '/development-utils/sandbox/create-layout-form',
    },
    editLayoutFormExample: {
      pattern: '/edit-layout-form',
      getURL: () => '/development-utils/sandbox/edit-layout-form',
    },
    settingsLayoutExample: {
      pattern: '/settings-layout',
      getURL: () => '/development-utils/sandbox/settings-layout',
    },
    viewLayoutExample: {
      pattern: '/view-layout',
      getURL: () => '/development-utils/sandbox/view-layout',
    },
    dataGridFilters: {
      pattern: '/data-grid-filters',
      getURL: () => '/development-utils/sandbox/data-grid-filters',
    },
    cardExamples: {
      pattern: '/card',
      getURL: () => '/development-utils/sandbox/card',
    },
    labelChipExample: {
      pattern: '/label-chip',
      getURL: () => '/development-utils/sandbox/label-chip',
    },
    textInputColor: {
      pattern: '/text-input-color',
      getURL: () => '/development-utils/sandbox/text-input-color',
    },
    dropZoneExample: {
      pattern: '/drop-zone-deck',
      getURL: () => '/development-utils/sandbox/drop-zone-deck',
    },
    megaFitlerExample: {
      pattern: '/mega-filter',
      getURL: () => '/development-utils/sandbox/mega-filter',
    },
    chipsAutocompleteExample: {
      pattern: '/chips-autocomplete-example',
      getURL: () => '/development-utils/sandbox/chips-autocomplete-example',
    },
  },
  ketchTagDebugUrlBuilder: {
    pattern: '/ketch-tag-debug-url-builder',
    getURL: () => '/development-utils/ketch-tag-debug-url-builder',
  },
}
