export type Relation = {
  // ForeignKey
  foreignKey?: string
  // ForeignKeyParent
  foreignKeyParent?: { code: string }
  // PrimaryKey
  primaryKey?: string
  // PrimaryKeyParent
  primaryKeyParent?: { code: string }
  // Type
  // type?: number
}

export type CreateRelationshipInitialValues = {
  relations: Relation[]
  datasetCode: string
  attributeResourceTypeCode: string
  referenceDatasetCode: string
  referenceAttributeResourceTypeCode: string
}

export const getInitialValues = (): CreateRelationshipInitialValues => {
  return {
    datasetCode: '',
    attributeResourceTypeCode: '',
    referenceDatasetCode: '',
    referenceAttributeResourceTypeCode: '',
    relations: [
      {
        foreignKey: '',
        primaryKey: '',
      },
    ],
  }
}
