import { Paper, autocompleteClasses, styled } from '@mui/material'

export const StyledOrchestrationAutocompletePaper = styled(Paper)(({ theme }) => ({
  boxShadow: ' 0px 10px 20px 0px rgba(0, 0, 0, 0.20), 0px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  backgroundColor: theme.palette.white.main,
  marginTop: 5,
  marginBottom: 5,
  borderRadius: 8,
  padding: '4px 8px 8px 8px',
  [`& .${autocompleteClasses.listbox}`]: {
    [`& .${autocompleteClasses.option}`]: {
      borderBottom: 'unset',
      alignItems: 'center',
      '&[aria-selected=true]': {
        '&.Mui-focused': {
          backgroundColor: theme.palette.sphereFaded.main,
        },
      },
    },
    '& .MuiAutocomplete-groupLabel': {
      lineHeight: '32px',
    },
  },
  [`& .${autocompleteClasses.noOptions}`]: {
    color: theme.palette.darkDuskFaded.main,
    ...theme.typography.body,
    letterSpacing: '0.14px',
  },
}))
