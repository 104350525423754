import React from 'react'

import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { useHandleEditOnClick, useHandleViewAssetOnClick } from '../hooks'

type Props = {
  assetSummary: AssetSummaryDTO
  buttonOptionsClassName?: string
  isDisabled?: boolean
}

export const AssetsListTableActionsCellRenderer: React.FC<Props> = ({
  assetSummary,
  buttonOptionsClassName,
  isDisabled,
}) => {
  const handleEditOnClick = useHandleEditOnClick(assetSummary)
  const handleViewOnClick = useHandleViewAssetOnClick(assetSummary)
  return (
    <ButtonOptions
      size="big"
      disabled={isDisabled}
      className={buttonOptionsClassName}
      items={[
        {
          content: 'View',
          onClick: () => handleViewOnClick(),
        },
        {
          content: 'Assign',
          hidden: true, // TODO: un-hide when designed and BE ready
          onClick: () => {},
        },
        {
          content: 'Edit',
          onClick: () => handleEditOnClick(),
        },
      ]}
    />
  )
}
