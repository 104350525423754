import Rollbar from 'rollbar'
import { getEnvFromHostname } from 'utils/environment'

const rollbarConfig: Rollbar.Configuration = {
  accessToken: (window as any).figureheadConfig?.REACT_APP_ROLLBAR_PUBLIC_KEY,
  environment: getEnvFromHostname(),
  captureUncaught: true,
  captureUnhandledRejections: true,
}

export default rollbarConfig
