import { InstalledDataSystemV2ListItemDTO } from '@ketch-com/figurehead'
import { useMemo, useRef } from 'react'
import { generateColorSchemeArray } from '../utils/cardAvatarColorGenerator'
import { UserShortDTO } from 'interfaces/users/users'

export interface OwnerAvatarColors {
  color: string
  backgroundColor: string
}
export interface AppListItem {
  app: InstalledDataSystemV2ListItemDTO
  ownerAvatarColors: OwnerAvatarColors
}

export function useAppListItems(installedDataSystems: InstalledDataSystemV2ListItemDTO[], allUsers: UserShortDTO[]) {
  const colorList = useMemo(() => generateColorSchemeArray(), [])
  const colorListIndex = useRef(0)

  const colorToOwnerMap = useMemo(() => {
    if (allUsers.length && colorList.length) {
      return allUsers.reduce(
        (acc, user) => {
          acc[user.ID] = colorList[colorListIndex.current]
          colorListIndex.current = (colorListIndex.current + 1) % colorList.length
          return acc
        },
        {} as Record<string, Record<string, string>>,
      )
    }
  }, [allUsers, colorList])

  const appListItems = useMemo(() => {
    if (colorToOwnerMap) {
      const appList = installedDataSystems.map(installedDataSystem => {
        const item = {
          app: installedDataSystem,
          ownerAvatarColors: installedDataSystem?.ownerUsers?.[0].id
            ? colorToOwnerMap[installedDataSystem.ownerUsers[0].id]
            : colorList[5],
        }
        colorListIndex.current = (colorListIndex.current + 1) % colorList.length
        return item
      })
      return appList as AppListItem[]
    }
  }, [colorList, colorToOwnerMap, installedDataSystems])

  return { appListItems }
}
