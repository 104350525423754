import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useTheme } from 'api/themes-v2/queries/useTheme'
import { useCreateTheme } from 'api/themes-v2/mutations/useCreateTheme'
import { useUpdateTheme } from 'api/themes-v2/mutations/useUpdateTheme'
import { ThemeFormValues, mapUpsertThemeValuesToPayload } from 'pages/consentAndRights/themes/upsert/utils'

import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'

import { showToast } from 'components/ui-kit/toastr/Toastr'
import { ThemesUpsert } from 'pages/consentAndRights/themes/upsert/ThemesUpsert'

export const ThemesUpsertContainer: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { code } = useParams<{ code: string }>()
  const isEditMode = code !== NEW_ITEM_CODE

  const { mutateAsync: handleCreateTheme } = useCreateTheme()
  const { mutateAsync: handleUpdateTheme } = useUpdateTheme()

  const { data: theme, isLoading: isThemeLoading } = useTheme({
    enabled: isEditMode,
    params: {
      themeCode: code!,
      includeMetadata: true,
    },
    onError: () => {
      navigate(RoutesManager.deployment.themes.root.getURL())
    },
  })

  const onSubmit = async (values: ThemeFormValues) => {
    try {
      const formData = mapUpsertThemeValuesToPayload({ values })

      const { data } = await (isEditMode
        ? handleUpdateTheme({
            params: {
              themeCode: code!,
              formData,
            },
          })
        : handleCreateTheme({
            params: {
              formData,
            },
          }))

      if (isEditMode) {
        await queryClient.refetchQueries([ApiQueryKeys.theme, { themeCode: code }])
      }

      await queryClient.refetchQueries(ApiQueryKeys.entitlements)

      showToast({ content: `Theme ${isEditMode ? 'updated' : 'created'}`, type: 'success' })
      navigate(RoutesManager.deployment.themes.view.root.getURL({ code: data.theme.code }))
    } catch (e) {
      showToast({ content: `Failed to ${isEditMode ? 'update' : 'create'} theme`, type: 'error' })
    }
  }

  return <ThemesUpsert isReady={!isThemeLoading} isEditMode={isEditMode} theme={theme} onSubmit={onSubmit} />
}
