import moment from 'moment'

type DateRange = {
  startDate?: string
  endDate?: string
}

export function getMondaysBetweenDates(dateRange: DateRange): string[] {
  const startMoment = moment(dateRange.startDate)
  const endMoment = moment(dateRange.endDate)
  const mondays: string[] = []

  // Set the startDate to the first Monday on or after the startDate
  while (startMoment.day() !== 1) {
    startMoment.add(1, 'days')
  }

  // While the current Monday is before or on the endDate
  while (startMoment.isSameOrBefore(endMoment)) {
    // Add the current Monday to the array
    mondays.push(startMoment.format('YYYY-MM-DD'))

    // Get the next Monday
    startMoment.add(1, 'weeks')
  }

  return mondays
}
