import React from 'react'
import clsx from 'clsx'
import { DistributiveOmit as Omit } from '@mui/types'
import { makeStyles, createStyles } from '@mui/styles'
import { Typography, TypographyProps } from '@mui/material'

const useStyles = makeStyles(
  createStyles({
    contentTitle: {},
  }),
  { name: 'EmptyValueRenderer' },
)

export type Props = Omit<TypographyProps, 'size' | 'weight'>

export const EmptyValueRenderer: React.FC<Props> = ({ children, className, color = 'lightGrey', ...props }) => {
  const classes = useStyles()

  return (
    <Typography variant="body" className={clsx(classes.contentTitle, className)} color={color} {...props}>
      {children || 'None'}
    </Typography>
  )
}
