import { useMemo } from 'react'
import { Box } from '@mui/material'
import { ContentGroup, DataGrid, UpsertLayout, UpsertLayoutMode, EmptyState } from '@ketch-com/deck'
import { FormikProvider, useFormik } from 'formik'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { libraryViewCookiesColumns } from './utils/cookiesLibraryDataGridColumns'
import { MaybeNull } from 'interfaces'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Pagination } from 'api/common/paginatedQuery'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateCookies.svg'
import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'

type Props = {
  isFetching: boolean
  isLoading: boolean
  cookies: CookieDTO[]
  pagination: Pagination
  onSubmit: (values: { cookieCode: MaybeNull<string> }) => Promise<void>
}

export const CookiesLibraryView: React.FC<Props> = ({ isFetching, isLoading, cookies, pagination, onSubmit }) => {
  const navigate = useNavigate()

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Cookies', link: RoutesManager.policyCenter.cookies.root.getURL() },
    { title: 'Create' },
  ]

  const formikProps = useFormik({
    initialValues: { cookieCode: null },
    onSubmit: onSubmit,
    validateOnMount: true,
    enableReinitialize: true,
  })

  const totalPages = pagination.totalPages

  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  const { values, isSubmitting, submitForm } = formikProps
  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          sx={{
            '& .UpsertLayout-content': {
              marginBottom: '48px',
            },
          }}
          mode={UpsertLayoutMode.edit}
          renderFormTitle={() => <ContentGroup title="Create Cookie" titleVariant="h2" isDivider />}
          onAccept={submitForm}
          onCancel={() => {
            navigate(RoutesManager.policyCenter.cookies.root.getURL())
          }}
          acceptActionButtonText={values.cookieCode ? 'Next' : 'Skip'}
          cancelActionButtonText="Cancel"
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {!isLoading && !isFetching && !cookies.length ? (
              <Box
                sx={{
                  padding: '34px 0',
                }}
              >
                <EmptyState customIcon={<EmptyStateIcon />} title="There are no cookies for this organization" />
              </Box>
            ) : (
              <>
                <DataGrid
                  sx={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    height: isLoading || isFetching ? '500px' : '',
                  }}
                  autosizeOnMount
                  autosizeOptions={{
                    includeHeaders: true,
                    includeOutliers: false,
                    columns: libraryViewCookiesColumns.map((column, index) => column.field),
                    expand: true,
                  }}
                  getRowHeight={() => 'auto'}
                  columns={libraryViewCookiesColumns}
                  disableChildrenSorting
                  disableColumnMenu
                  disableColumnPinning
                  disableColumnResize
                  disableColumnReorder
                  getRowId={row => row.ID}
                  loading={isLoading || isFetching}
                  rows={cookies}
                  pagination
                  paginationMode="server"
                  paginationModel={paginationModel}
                  onPaginationModelChange={onPaginationModelChange}
                  pageSizeOptions={[rowsPerPage]}
                  rowCount={pagination.totalResults}
                />
              </>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
