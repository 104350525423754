import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { FormMode } from 'interfaces/formModes/formMode'
import { ApplicationDTO } from 'interfaces/applications/application'
import { useApplication } from 'api/applications/queries/useApplication'
import { useCreateApplication } from 'api/applications/mutations/useCreateApplication'
import { useUpdateApplication } from 'api/applications/mutations/useUpdateApplication'
import { mapApplicationValuesToPayload } from 'pages/consentAndRights/applications/upsert/utils'
import { RoutesManager } from 'utils/routing/routesManager'
import { ApplicationCreate } from 'pages/consentAndRights/applications/upsert/create/ApplicationCreate'
import { ApplicationEdit } from 'pages/consentAndRights/applications/upsert/edit/ApplicationEdit'
import { showToast } from 'components/modals/AlertComponent'
import { ApplicationFormValues } from 'interfaces/applications/ApplicationFormValues'
import { useDataSubjectTypes } from 'api/dataSubjectTypes/queries/useDataSubjectTypes'
import { useThemes } from 'api/themes-v3/queries/useThemes'
import { usePolicyDocuments } from 'api/policyDocuments/queries/usePolicyDocuments'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'

export const ApplicationUpsertContainer = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { code, formMode } = useParams<{ code?: string; formMode: FormMode }>()

  const isEditMode = formMode === FormMode.EDIT

  const { data: dataSubjectTypes, isLoading: isLoadingDataSubjectTypes } = useDataSubjectTypes({
    itemsPerPage: 1000,
    params: {},
  })

  const { data: themes, isLoading: isThemesLoading } = useThemes()
  const filteredThemes = themes.filter(theme => !!theme.summary)

  const { data: policyDocuments, isLoading: isPolicyDocumentLoading } = usePolicyDocuments()

  const { data: identitySpacesList, isLoading: isIdentitySpacesListLoading } = useIdentitySpaces()

  const onUpsertSuccess = async ({ code }: ApplicationDTO) => {
    await queryClient.refetchQueries(ApiQueryKeys.entitlements)
    navigate(RoutesManager.deployment.applications.view.root.getURL({ code }))
  }

  const { mutateAsync: handleCreateApplication } = useCreateApplication({
    onSuccess: ({ data }) => {
      showToast({ content: 'Property created', type: 'success' })
      onUpsertSuccess(data.application)
    },
    onError: () => {
      showToast({ content: 'Failed to create property', type: 'error' })
    },
  })

  const { mutateAsync: handleUpdateApplication } = useUpdateApplication({
    onSuccess: ({ data }) => {
      showToast({ content: 'Property updated', type: 'success' })
      onUpsertSuccess(data.application)
    },
    onError: () => {
      showToast({ content: 'Failed to update property', type: 'error' })
    },
  })

  const { data: application, isLoading } = useApplication({
    enabled: isEditMode,
    params: {
      applicationCode: code!,
    },
    onError: () => {
      navigate(RoutesManager.deployment.applications.root.getURL())
    },
  })

  const onSubmit = async (values: ApplicationFormValues) => {
    const formData = mapApplicationValuesToPayload({ values, dataSubjectTypes })

    await (isEditMode
      ? handleUpdateApplication({
          params: {
            applicationCode: code!,
            formData,
          },
        })
      : handleCreateApplication({
          params: {
            formData,
          },
        }))
  }

  const isEditReady =
    !isLoading &&
    !!application &&
    !isLoadingDataSubjectTypes &&
    !isThemesLoading &&
    !isPolicyDocumentLoading &&
    !isIdentitySpacesListLoading
  const isCreateReady =
    !isLoadingDataSubjectTypes && !isThemesLoading && !isPolicyDocumentLoading && !isIdentitySpacesListLoading

  return isEditMode ? (
    <ApplicationEdit
      isReady={isEditReady}
      application={application}
      dataSubjectTypes={dataSubjectTypes}
      themes={filteredThemes}
      policyDocuments={policyDocuments}
      identitySpacesList={identitySpacesList}
      onSubmit={onSubmit}
    />
  ) : (
    <ApplicationCreate
      isReady={isCreateReady}
      dataSubjectTypes={dataSubjectTypes}
      themes={filteredThemes}
      policyDocuments={policyDocuments}
      identitySpacesList={identitySpacesList}
      onSubmit={onSubmit}
    />
  )
}
