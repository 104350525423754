import { WORKFLOW_TYPE_ENUM, OBLIGATION_ENUM } from '../../../constants'

type Args = {
  canonicalRightCode: string
  updateValue: string
}

export const getShouldResetAttributeError = ({ canonicalRightCode, updateValue }: Args): boolean => {
  switch (canonicalRightCode) {
    case WORKFLOW_TYPE_ENUM.DELETE:
      return updateValue !== OBLIGATION_ENUM.UPDATE
    case WORKFLOW_TYPE_ENUM.GET:
      return updateValue !== OBLIGATION_ENUM.GET
    default:
      return updateValue !== OBLIGATION_ENUM.UPDATE
  }
}
