import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, TableCell } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import numeral from 'numeral'
import { ReshapedPerIdentitySpaceDTO } from '../utils/getTransformedPerIdentitySpaceData'

export const distributionPerIdentityDataGridColumns = (translate: (a: string) => string): GridColDef[] => [
  {
    field: 'name',
    flex: 1,
    headerName: '',
    width: 228,
    sortable: false,
    renderCell: ({ row: { name, code } }: GridRenderCellParams<ReshapedPerIdentitySpaceDTO>) => {
      const displayName = name?.includes('DELETED') ? code : name || 'DELETED'
      const isDeleted = name?.includes('DELETED')

      return (
        <TableCell sx={{ height: '52px', flexDirection: 'column', alignItems: 'start !important' }}>
          <Typography variant="label" color="darkDusk.main" sx={{ mb: 0.625, whiteSpace: 'initial' }}>
            {displayName}
          </Typography>
          {isDeleted && (
            <Box display="flex">
              <Chip size="small" label={translate('Deleted')} />
            </Box>
          )}
        </TableCell>
      )
    },
  },
  {
    field: 'totalRequests',
    headerName: '',
    width: 352,
    sortable: false,
    renderCell: ({ row: { percent, totalRequests } }: GridRenderCellParams<ReshapedPerIdentitySpaceDTO>) => {
      return (
        <TableCell width="100%" flexDirection="column" height="52px" sx={{ py: '6px', alignItems: 'start !important' }}>
          <Box
            sx={{
              width: `${percent}%`,
              height: 10,
              borderRadius: 5,
              backgroundColor: 'graph11.main',
            }}
          />

          <Typography variant="smallLabel" color="darkDusk.main">
            {numeral(totalRequests).format('0,0')}
          </Typography>
        </TableCell>
      )
    },
  },
]
