import { APIListRequestParams } from 'api/common/utils'
import { GetWebhooksContextResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  workflowExecutionId?: string | null | undefined
  stepId?: string | null | undefined
}>

export const fetchWebhookContext = ({ workflowExecutionId, stepId }: Params) =>
  API.get<GetWebhooksContextResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/executions/${workflowExecutionId}/steps/${stepId}/webhooks-context`,
      params: {},
    }),
  )
