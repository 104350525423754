import { ContentGroup, SwitchToggle } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { useContext } from 'react'
import { RoutesManager } from 'utils/routing/routesManager'
import { TrackersSettingsContext } from '../../context'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'

/**
 * Component for configuring the initial settings for trackers when they are discovered
 */
export const DiscoverySettings: React.FC = () => {
  const queryClient = useQueryClient()

  const { trackerConfiguration, updateTrackerConfiguration } = useContext(TrackersSettingsContext)
  const { disclose_by_default, block_by_default } = trackerConfiguration || {
    // Defaults
    disclose_by_default: true,
    block_by_default: false,
  }

  const handleBlockByDefaultChange = async (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    await updateTrackerConfiguration({ params: { data: { disclose_by_default, block_by_default: checked } } })
    await queryClient.invalidateQueries(ApiQueryKeys.trackerConfiguration)
  }

  return (
    <Box>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
          { title: 'Trackers', link: RoutesManager.policyCenter.trackers.list.approvedTrackers.root.getURL() },
          { title: 'Settings' },
          { title: 'Blocking' },
        ]}
      />
      <ContentGroup
        title="Blocking"
        subTitle="Configure the default blocking behavior for newly discovered trackers."
        titleVariant="h4"
        variant="inner-page-header"
      />
      <Box mt="20px">
        <SwitchToggle size="small" checked={block_by_default} onChange={handleBlockByDefaultChange} />
        <Typography variant={block_by_default ? 'label' : 'body'} ml={1}>
          Block When Discovered
        </Typography>
      </Box>
    </Box>
  )
}
