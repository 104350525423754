import { APIListRequestParams } from 'api/common/utils'
import { GetVerifierUsageResponseBodyDTO } from '@ketch-com/figurehead'

import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  verifierId?: string
}>

export const fetchVerifierUsage = ({ verifierId }: Params) =>
  API.get<GetVerifierUsageResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/verifiers/${verifierId}/usage`,
    }),
  )
