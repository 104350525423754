import { round } from 'lodash'
import { ICoordinates } from 'pages/orchestration/workflows/edit/interfaces'

interface IGetCanvasBoundaries {
  x: number
  y: number
  canvasWidth: number
  canvasHeight: number
  canvasViewPortWidth: number
  canvasViewPortHeight: number
  scale: number
}

export const getCanvasBoundaries = (params: IGetCanvasBoundaries): ICoordinates => {
  const { x, y, scale, canvasWidth, canvasHeight, canvasViewPortWidth, canvasViewPortHeight } = params

  // Min and max possible x and y cords
  // Equal to the scaled width/height of the canvas minus the visible width/height (unless < 0 which is the upper left corner)
  const leftmostX = 0
  const rightmostX = Math.max(round(canvasWidth * scale) - canvasViewPortWidth, 0)
  const uppermostY = 0
  const bottommostY = Math.max(round(canvasHeight * scale) - canvasViewPortHeight, 0)

  const positionToReturn = { x, y }
  const isReachedLeft = x >= leftmostX
  const isReachedTop = y >= uppermostY
  const isReachedRight = Math.abs(x) >= rightmostX
  const isReachedBottom = Math.abs(y) >= bottommostY
  const isReachedBorder = isReachedLeft || isReachedRight || isReachedTop || isReachedBottom

  if (isReachedBorder) {
    if (isReachedLeft) {
      positionToReturn.x = leftmostX
    }

    if (isReachedRight) {
      positionToReturn.x = -rightmostX
    }

    if (isReachedTop) {
      positionToReturn.y = uppermostY
    }

    if (isReachedBottom) {
      positionToReturn.y = -bottommostY
    }
  }
  return positionToReturn
}
