export enum ThemePreviewViewType {
  BANNER = 'Banner',
  MODAL = 'Modal',
  FORM = 'Form',
}

export const ThemePreviewViewTypeOptions = [
  {
    id: ThemePreviewViewType.BANNER,
    name: ThemePreviewViewType.BANNER,
  },
  {
    id: ThemePreviewViewType.MODAL,
    name: ThemePreviewViewType.MODAL,
  },
  {
    id: ThemePreviewViewType.FORM,
    name: ThemePreviewViewType.FORM,
  },
]
