import { Icon } from '@ketch-com/deck'
import { Box, Typography, styled } from '@mui/material'

export enum SwitchVariant {
  on,
  off,
  alwaysOn,
  alwaysOff,
}

const ContainerBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})

const SwitchBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '40px',
  height: '24px',
  padding: '2px',
  background: 'black',
  borderRadius: 100,
})

const SwitchBallBox = styled(Box)({
  display: 'flex',
  borderRadius: 100,
  background: 'white',
  width: '20px',
  height: '20px',
})

type Props = {
  variant?: SwitchVariant
  onBackgroundColor?: string
  onTextColor?: string
  offBackgroundColor?: string
  offTextColor?: string
  onText?: string
  offText?: string
  alwaysOnText?: string
  alwaysOffText?: string
  showText?: boolean
}

export const BuilderSwitch: React.FC<Props> = ({
  variant = SwitchVariant.on,
  onBackgroundColor = 'black',
  onTextColor = 'black',
  offBackgroundColor = 'rgba(7, 26, 36, 0.56)', // Not a deck color
  offTextColor = 'black',
  onText,
  offText,
  showText = true,
}) => {
  const isOn = variant === SwitchVariant.on || variant === SwitchVariant.alwaysOn

  // NOTE: Always on/off descoped so just using onText and offText for any switches set to that variant for now.
  // Uncomment below to enable.
  const isDisabled = false
  // const isDisabled = variant === SwitchVariant.alwaysOn || variant === SwitchVariant.alwaysOff

  const variantText = {
    [SwitchVariant.on]: onText,
    [SwitchVariant.off]: offText,
    // NOTE: Always on/off descoped so just using onText and offText for any switches set to that variant for now
    [SwitchVariant.alwaysOn]: onText,
    [SwitchVariant.alwaysOff]: offText,
  }

  return (
    <ContainerBox>
      {showText && (
        <Box display={'flex'} justifyContent={'flex-end'} maxWidth={'65px'}>
          <Typography
            variant="smallLabel"
            color={isOn ? onTextColor : offTextColor}
            maxWidth={'65px'}
            sx={{ wordWrap: 'break-word' }}
            textAlign={'right'}
          >
            {variantText[variant]}
          </Typography>
        </Box>
      )}
      <SwitchBox
        sx={{
          background: isOn ? onBackgroundColor : offBackgroundColor,
          opacity: isDisabled ? '0.2' : '1.0',
        }}
      >
        {isOn ? (
          <>
            <Icon name="OCheck" iconColor="white" width="16px" height="16px" />
            <SwitchBallBox />
          </>
        ) : (
          <>
            <SwitchBallBox />
            <Icon name="OCross" iconColor="white" width="16px" height="16px" />
          </>
        )}
      </SwitchBox>
    </ContainerBox>
  )
}
