import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { retryExecution } from '../fetchers/retryExecution'

const useCustomMutation = createUseMutation({
  mutationFn: retryExecution,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useRetryExecution = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
