import { MetaData } from 'interfaces/common'
import { ApplicationEnvironmentDTO } from 'interfaces/applications/application'
import { FileInfoDTO } from 'interfaces/files/fileInfo'

export enum PolicyDocumentStatus {
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished',
  UNSPECIFIED = 'Unspecified',
}

export enum PolicyDocumentType {
  POLICY_DOCUMENT_UNSPECIFIED = 0,
  PRIVACY_POLICY = 1,
  TERMS_OF_SERVICE = 2,
  DPIA = 3,
  DPA = 5,
  MSA = 6,
  POLICY_DOCUMENT_OTHER = 8,
}

export const POLICY_DOCUMENT_NAMES = {
  [PolicyDocumentType.POLICY_DOCUMENT_UNSPECIFIED]: 'All Types',
  [PolicyDocumentType.PRIVACY_POLICY]: 'Privacy Policy',
  [PolicyDocumentType.TERMS_OF_SERVICE]: 'Terms of Service',
  [PolicyDocumentType.DPIA]: 'DPIA',
  [PolicyDocumentType.DPA]: 'DPA',
  [PolicyDocumentType.MSA]: 'MSA',
  [PolicyDocumentType.POLICY_DOCUMENT_OTHER]: 'Other',
}

export const POLICY_DOCUMENT_OPTIONS = [
  {
    id: PolicyDocumentType.PRIVACY_POLICY,
    name: POLICY_DOCUMENT_NAMES[PolicyDocumentType.PRIVACY_POLICY],
  },
  {
    id: PolicyDocumentType.TERMS_OF_SERVICE,
    name: POLICY_DOCUMENT_NAMES[PolicyDocumentType.TERMS_OF_SERVICE],
  },
  {
    id: PolicyDocumentType.DPIA,
    name: POLICY_DOCUMENT_NAMES[PolicyDocumentType.DPIA],
  },
  {
    id: PolicyDocumentType.DPA,
    name: POLICY_DOCUMENT_NAMES[PolicyDocumentType.DPA],
  },
  {
    id: PolicyDocumentType.MSA,
    name: POLICY_DOCUMENT_NAMES[PolicyDocumentType.MSA],
  },
  {
    id: PolicyDocumentType.POLICY_DOCUMENT_OTHER,
    name: POLICY_DOCUMENT_NAMES[PolicyDocumentType.POLICY_DOCUMENT_OTHER],
  },
]

export interface PolicyDocumentDTO {
  ID: string
  URL: string
  active: boolean
  code: string
  latest: boolean
  metadata?: MetaData
  hasIssues?: boolean
  name: string
  organizationID: string
  policyDocumentType: PolicyDocumentType
  description?: string
  status: PolicyDocumentStatus
  applicationEnvironments?: ApplicationEnvironmentDTO[]
  platform?: string
  version: number
  currentID: string
  isApproved?: boolean
  policyDocumentLocation?: any
  uploadFolder?: string
  fileInfo?: FileInfoDTO
}

export interface PolicyDocumentFormDTO {
  code: string
  name: string
  policyDocumentType: PolicyDocumentType
  URL: string
  metadata?: MetaData
  description: string
  version: number
  policyDocumentLocation?: number
  uploadFolder?: string
  fileInfo?: FileInfoDTO
}

export interface ApprovePolicyDocumentFormDTO {
  code: string
  name: string
  policyDocumentType: PolicyDocumentType
  URL: string
  description: string
  version: number
  policyDocumentLocation?: number
  isApproved?: boolean
}
