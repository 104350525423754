import { useContext } from 'react'
import { ExperienceUpsertContext } from '../../../context/ExperienceUpsertContext'
import {
  BannerExperienceBuilderSection,
  ModalExperienceBuilderSection,
  PreferenceExperienceBuilderSection,
  ProgressiveExperienceBuilderSection,
} from '../../builder/utils/enums'
import { BannerExperienceHeaderForm } from './forms/BannerExperienceHeaderForm'
import { BannerExperienceDescriptionForm } from './forms/BannerExperienceDescriptionForm'
import { BannerExperienceButtonsForm } from './forms/BannerExperienceButtonsForm'
import { BannerExperienceFooterForm } from './forms/BannerExperienceFooterForm'
import { ModalExperienceHeaderForm } from './forms/ModalExperienceHeaderForm'
import { ModalExperienceDescriptionForm } from './forms/ModalExperienceDescriptionForm'
import { ModalExperienceListHeaderForm } from './forms/ModalExperienceListHeaderForm'
import { ModalExperiencePurposesListForm } from './forms/ModalExperiencePurposesListForm'
import { ModalExperienceFooterForm } from './forms/ModalExperienceFooterForm'
import { ModalExperienceDescriptionTitleForm } from './forms/ModalExperienceDescriptionTitleForm'
import { PreferenceExperienceWelcomeTabNavigationForm } from './forms/PreferenceExperienceWelcomeTabNavigationForm'
import { PreferenceExperienceWelcomeTabWelcomeMessageForm } from './forms/PreferenceExperienceWelcomeTabWelcomeMessageForm'
import { PreferenceExperienceWelcomeTabQuickLinksForm } from './forms/PreferenceExperienceWelcomeTabQuickLinksForm'
import { PreferenceExperienceWelcomeTabAboutForm } from './forms/PreferenceExperienceWelcomeTabAboutForm'
import { PreferenceExperienceWelcomeTabHeaderForm } from './forms/PreferenceExperienceWelcomeTabHeaderForm'
import { PreferenceExperienceWelcomeTabExitButtonForm } from './forms/PreferenceExperienceWelcomeTabExitButton'
import { PreferenceExperiencePurposesTabHeaderDescriptionForm } from './forms/PreferenceExperiencePurposesTabHeaderDescriptionForm'
import { PreferenceExperiencePurposesTabListHeaderForm } from './forms/PreferenceExperiencePurposesTabListHeaderForm'
import { PreferenceExperiencePurposesTabListForm } from './forms/PreferenceExperiencePurposesTabListForm'
import { PreferenceExperiencePurposesTabActionButtonForm } from './forms/PreferenceExperiencePurposesTabActionButtonForm'
import { PreferenceExperiencePurposesTabHeaderTitleForm } from './forms/PreferenceExperiencePurposesTabHeaderTitleForm'
import { PreferenceExperienceSubscriptionsTabHeaderTitleForm } from './forms/PreferenceExperienceSubscriptionsTabHeaderTitleForm'
import { PreferenceExperienceSubscriptionsTabUnsubscribeAllForm } from './forms/PreferenceExperienceSubscriptionsTabUnsubscribeAllForm'
import { PreferenceExperienceSubscriptionsTabListForm } from './forms/PreferenceExperienceSubscriptionsTabListForm'
import { PreferenceExperienceSubscriptionsTabActionButtonForm } from './forms/PreferenceExperienceSubscriptionsTabActionButtonForm'
import { PreferenceExperienceSubscriptionsTabHeaderDescriptionForm } from './forms/PreferenceExperienceSubscriptionsTabHeaderDescriptionForm'
import { PreferenceExperienceRequestsTabHomeHeaderTitleForm } from './forms/PreferenceExperienceRequestsTabHomeHeaderTitleForm'
import { PreferenceExperienceRequestsTabHomeHeaderDescriptionForm } from './forms/PreferenceExperienceRequestsTabHomeHeaderDescriptionForm'
import { PreferenceExperienceRequestsTabHomeDsrLinkForm } from './forms/PreferenceExperienceRequestsTabHomeDsrLinkForm'
import { PreferenceExperienceRequestsTabHomeRightsListForm } from './forms/PreferenceExperienceRequestsTabHomeRightsListForm'
import { PreferenceExperienceRequestsTabFormContentForm } from './forms/PreferenceExperienceRequestsTabFormContentForm'
import { PreferenceExperienceRequestsTabFormActionButtonForm } from './forms/PreferenceExperienceRequestsTabFormActionButtonForm'
import { PreferenceExperienceRequestsTabSubmittedHeaderForm } from './forms/PreferenceExperienceRequestsTabSubmittedHeaderForm'
import { PreferenceExperienceRequestsTabSubmittedSubtitleForm } from './forms/PreferenceExperienceRequestsTabSubmittedSubtitleForm'
import { PreferenceExperienceRequestsTabSubmittedDescriptionForm } from './forms/PreferenceExperienceRequestsTabSubmittedDescriptionForm'
import { PreferenceExperienceRequestsTabSubmittedActionButtonForm } from './forms/PreferenceExperienceRequestsTabSubmittedActionButtonForm'
import { PreferenceExperienceWelcomeTabGpcForm } from './forms/PreferenceExperienceWelcomeTabGpcForm'
import { ProgressiveExperienceContainerForm } from './forms/ProgressiveExperienceContainerForm'
import { ProgressiveExperienceHeaderForm } from './forms/ProgressiveExperienceHeaderForm'
import { ProgressiveExperienceDescriptionForm } from './forms/ProgressiveExperienceDescriptionForm'
import { ProgressiveExperienceButtonsForm } from './forms/ProgressiveExperienceButtonsForm'
import { ProgressiveExperienceSubscriptionsForm } from './forms/ProgressiveExperienceSubscriptionsForm'
import { ProgressiveExperienceSwitchButtonForm } from './forms/ProgressiveExperienceSwitchButtonForm'

const sectionForm: { [section: string]: React.ReactNode } = {
  [BannerExperienceBuilderSection.Header]: <BannerExperienceHeaderForm />,
  [BannerExperienceBuilderSection.Description]: <BannerExperienceDescriptionForm />,
  [BannerExperienceBuilderSection.ActionButtons]: <BannerExperienceButtonsForm />,
  [BannerExperienceBuilderSection.Footer]: <BannerExperienceFooterForm />,
  [ModalExperienceBuilderSection.Header]: <ModalExperienceHeaderForm />,
  [ModalExperienceBuilderSection.Description]: <ModalExperienceDescriptionForm />,
  [ModalExperienceBuilderSection.DescriptionTitle]: <ModalExperienceDescriptionTitleForm />,
  [ModalExperienceBuilderSection.ListHeader]: <ModalExperienceListHeaderForm />,
  [ModalExperienceBuilderSection.PurposeList]: <ModalExperiencePurposesListForm />,
  [ModalExperienceBuilderSection.Footer]: <ModalExperienceFooterForm />,
  [PreferenceExperienceBuilderSection.WelcomeTabHeader]: <PreferenceExperienceWelcomeTabHeaderForm />,
  [PreferenceExperienceBuilderSection.WelcomeTabNavigation]: <PreferenceExperienceWelcomeTabNavigationForm />,
  [PreferenceExperienceBuilderSection.WelcomeTabWelcomeMessage]: <PreferenceExperienceWelcomeTabWelcomeMessageForm />,
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinks]: <PreferenceExperienceWelcomeTabQuickLinksForm />,
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLinkTwo]: <PreferenceExperienceWelcomeTabQuickLinksForm />,
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLinkThree]: <PreferenceExperienceWelcomeTabQuickLinksForm />,
  [PreferenceExperienceBuilderSection.WelcomeTabAbout]: <PreferenceExperienceWelcomeTabAboutForm />,
  [PreferenceExperienceBuilderSection.WelcomeTabExitButton]: <PreferenceExperienceWelcomeTabExitButtonForm />,
  [PreferenceExperienceBuilderSection.WelcomeTabGpcBadge]: <PreferenceExperienceWelcomeTabGpcForm />,
  [PreferenceExperienceBuilderSection.WelcomeTabAboutDescription]: <PreferenceExperienceWelcomeTabAboutForm />,
  [PreferenceExperienceBuilderSection.PurposesTabHeaderTitle]: <PreferenceExperiencePurposesTabHeaderTitleForm />,
  [PreferenceExperienceBuilderSection.PurposesTabHeaderDescription]: (
    <PreferenceExperiencePurposesTabHeaderDescriptionForm />
  ),
  [PreferenceExperienceBuilderSection.PurposesTabListHeader]: <PreferenceExperiencePurposesTabListHeaderForm />,
  [PreferenceExperienceBuilderSection.PurposesTabList]: <PreferenceExperiencePurposesTabListForm />,
  [PreferenceExperienceBuilderSection.PurposesTabActionButton]: <PreferenceExperiencePurposesTabActionButtonForm />,
  [PreferenceExperienceBuilderSection.SubscriptionsTabHeaderTitle]: (
    <PreferenceExperienceSubscriptionsTabHeaderTitleForm />
  ),
  [PreferenceExperienceBuilderSection.SubscriptionsTabHeaderDescription]: (
    <PreferenceExperienceSubscriptionsTabHeaderDescriptionForm />
  ),
  [PreferenceExperienceBuilderSection.SubscriptionsTabUnsubscribeAll]: (
    <PreferenceExperienceSubscriptionsTabUnsubscribeAllForm />
  ),
  [PreferenceExperienceBuilderSection.SubscriptionsTabList]: <PreferenceExperienceSubscriptionsTabListForm />,
  [PreferenceExperienceBuilderSection.SubscriptionsTabActionButton]: (
    <PreferenceExperienceSubscriptionsTabActionButtonForm />
  ),
  [PreferenceExperienceBuilderSection.RequestsHomeHeaderTitle]: <PreferenceExperienceRequestsTabHomeHeaderTitleForm />,
  [PreferenceExperienceBuilderSection.RequestsHomeHeaderDescription]: (
    <PreferenceExperienceRequestsTabHomeHeaderDescriptionForm />
  ),
  [PreferenceExperienceBuilderSection.RequestsHomeDsrLink]: <PreferenceExperienceRequestsTabHomeDsrLinkForm />,
  [PreferenceExperienceBuilderSection.RequestsHomeRightsListTitle]: (
    <PreferenceExperienceRequestsTabHomeRightsListForm />
  ),
  [PreferenceExperienceBuilderSection.RequestsHomeRightsListRequestTypes]: (
    <PreferenceExperienceRequestsTabHomeRightsListForm />
  ),
  [PreferenceExperienceBuilderSection.RequestsFormContent]: <PreferenceExperienceRequestsTabFormContentForm />,
  [PreferenceExperienceBuilderSection.RequestsFormActionButton]: (
    <PreferenceExperienceRequestsTabFormActionButtonForm />
  ),
  [PreferenceExperienceBuilderSection.RequestsSubmittedHeader]: <PreferenceExperienceRequestsTabSubmittedHeaderForm />,
  [PreferenceExperienceBuilderSection.RequestsSubmittedSubtitle]: (
    <PreferenceExperienceRequestsTabSubmittedSubtitleForm />
  ),
  [PreferenceExperienceBuilderSection.RequestsSubmittedDescription]: (
    <PreferenceExperienceRequestsTabSubmittedDescriptionForm />
  ),
  [PreferenceExperienceBuilderSection.RequestsSubmittedActionButton]: (
    <PreferenceExperienceRequestsTabSubmittedActionButtonForm />
  ),
  [ProgressiveExperienceBuilderSection.Container]: <ProgressiveExperienceContainerForm />,
  [ProgressiveExperienceBuilderSection.Header]: <ProgressiveExperienceHeaderForm />,
  [ProgressiveExperienceBuilderSection.Description]: <ProgressiveExperienceDescriptionForm />,
  [ProgressiveExperienceBuilderSection.ActionButtons]: <ProgressiveExperienceButtonsForm />,
  [ProgressiveExperienceBuilderSection.Subscriptions]: <ProgressiveExperienceSubscriptionsForm />,
  [ProgressiveExperienceBuilderSection.SwitchButton]: <ProgressiveExperienceSwitchButtonForm />,
}

export const SidebarForm: React.FC = () => {
  const { experienceBuilderSection } = useContext(ExperienceUpsertContext)

  return experienceBuilderSection ? <>{sectionForm[experienceBuilderSection]}</> : null
}
