import { APIRequestParams } from 'api/common/utils'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  rightInvocationId: string
  includeIssues?: boolean
}>

type Response = { rightInvocation: RightInvocationDTO & { channelURL: string } }

export const fetchRightInvocationV2 = ({ includeMetadata, includeIssues, rightInvocationId }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/v2/right-invocations/${rightInvocationId}`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
        },
      },
    }),
  )
