import React from 'react'
import Box from '@mui/material/Box'
import { RightInvocationDTO, WorkflowExecutionDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { Typography } from '@mui/material'
import { LinearProgress } from '@ketch-com/deck'

type Props = {
  workflowExecution: WorkflowExecutionDTO
  rightInvocation: RightInvocationDTO
}

export const WorkflowExecutionProgressV2: React.FC<Props> = ({ workflowExecution, rightInvocation }) => {
  const { steps } = workflowExecution
  const isFinalized = rightInvocation?.finalization?.finalizedAt || false

  const skippedStepsLength = (steps || []).filter(step => step.status === WorkflowExecutionStepStatus.SKIPPED).length

  const doneStepsLength = (steps || []).filter(({ status }) => status === WorkflowExecutionStepStatus.SUCCESS).length
  const stepsLength = (steps?.length || 0) - skippedStepsLength

  // When finalized, no more steps will be completed, so list the # of done steps on both sides of the fraction.
  const numerator = doneStepsLength
  const denominator = isFinalized ? doneStepsLength : stepsLength

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <LinearProgress value={(numerator / denominator) * 100} sx={{ mr: 1 }} />
      <Typography variant="label">
        {numerator} / {denominator}
      </Typography>
    </Box>
  )
}
