import { FindDataSystemsV2ResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  q?: string
  start?: number
  limit?: number
  hasIntegration?: boolean
  notInstalled?: boolean
}>

export const fetchDataSystems = ({ q, hasIntegration, notInstalled, start, limit }: Params) => {
  return API.get<FindDataSystemsV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: '/api/v2/data-systems',
      params: {
        filters: {
          query: q,
          hasIntegration,
          notInstalled,
          start,
          limit,
        },
      },
    }),
  )
}
