import React from 'react'
import { Grid } from '@mui/material'
import { theme } from '@ketch-com/deck'
import { SignUpInsights } from 'pages/onboarding/signUp/components/SignUpInsights'
import { SignUpActions } from 'pages/onboarding/signUp/components/SignUpActions'
import { Formik } from 'formik'
import { useSignUp } from 'pages/onboarding/signUp/common/hooks/useSignUp'
import { useLocation } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { MobileSignUp } from 'pages/onboarding/signUp/components/MobileSignUp'

export const SignUp: React.FC = () => {
  const location = useLocation()
  const { validationSchema, initialValues, onSignUpFormSubmit } = useSignUp()
  const isSignUpCompleted = location.pathname === RoutesManager.onboarding.signUp.completed.getURL()
  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        return onSignUpFormSubmit(values)
      }}
    >
      <>
        {/* Mobile */}
        <Grid
          container
          bgcolor={theme.palette.white.main}
          minHeight="100vh"
          justifyContent="stretch"
          display={{ xs: 'flex', md: 'none' }}
        >
          <Grid item width="100vw">
            <MobileSignUp />
          </Grid>
        </Grid>

        {/* Desktop */}
        <Grid
          container
          bgcolor={theme.palette.white.main}
          minHeight="100vh"
          justifyContent="stretch"
          display={{ xs: 'none', md: 'flex' }}
        >
          <Grid
            item
            display={{ xs: 'none', sm: 'flex' }}
            maxWidth={isSignUpCompleted ? '35% !important' : '900px !important'}
            sm={6}
          >
            <SignUpInsights />
          </Grid>
          <Grid item xs={12} sm={6} md={6} py={6}>
            <SignUpActions />
          </Grid>
        </Grid>
      </>
    </Formik>
  )
}
