import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep } from '../../../interfaces'
import { WORKFLOW_TYPE_ENUM } from '../../sidebarConfigs/forms/activity/DsrTransponderForm/constants'

export const getIsAccessFlowWithoutIDVerificationTile = (steps: ICanvasStep[], canonicalRightCode?: string) => {
  const isAccessFlow = canonicalRightCode === WORKFLOW_TYPE_ENUM.GET
  if (!isAccessFlow) return false

  // Check both activityCode + activity.code to work around activityCode not being populated in some odd cases
  const hasIdvStep = Boolean(
    steps.find(
      step =>
        step.activityCode === WorkflowActivityCode.IDENTITY_VERIFICATION ||
        step.activity?.code === WorkflowActivityCode.IDENTITY_VERIFICATION,
    ),
  )
  if (hasIdvStep) return false

  return true
}
