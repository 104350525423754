import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createExperience } from 'api/experiences-v2/fetchers/createExperience'

const useCustomMutation = createUseMutation({
  mutationFn: createExperience,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateExperience = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
