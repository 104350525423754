import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { PerIdentitySpacePermitStatsDTO } from '@ketch-com/figurehead'
import { CircularProgress, CircularProgressSize, EmptyState, DataGrid } from '@ketch-com/deck'

import { Pagination } from 'api/common/paginatedQuery'
import { getTransformedPerIdentitySpaceData, distributionPerIdentityDataGridColumns } from '../../utils'
import { useIsLoadingAggregate } from 'pages/insights/permitsStatistics/utils'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'

const PREFIX = 'DistributionPerIdentitySpace'

const classes = {
  nameCell: `${PREFIX}-nameCell`,
  percentCell: `${PREFIX}-percentCell`,
}

const StyledBox = styled(Box)(() => ({
  [`& .${classes.nameCell}`]: {
    alignItems: 'flex-start',
  },

  [`& .${classes.percentCell}`]: {
    display: 'block',
  },
}))

type Props = {
  identitySpaces: PerIdentitySpacePermitStatsDTO[]
  isLoading: boolean
  identitySpacesMaxNumberOfRequests: number
  pagination: Pagination
}

export const DistributionPerIdentitySpace: React.FC<Props> = ({
  identitySpaces,
  isLoading,
  pagination,
  identitySpacesMaxNumberOfRequests,
}) => {
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])
  const transformedIdentitySpaceData = getTransformedPerIdentitySpaceData(
    identitySpaces,
    identitySpacesMaxNumberOfRequests,
  )
  const isLoadingAggregate = useIsLoadingAggregate()

  const totalPages = pagination.totalPages
  const isPending = pagination.isPending

  const rowsPerPage = React.useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = React.useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  if (isLoadingAggregate || isLoading) {
    return (
      <StyledBox
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: ({ palette }) => palette.white.main,
          borderRadius: 2,
          padding: 4,
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h3">{t('Per Identifier')}</Typography>
          <Typography variant="body" color="darkDuskFaded.main">
            {t('Which Identifiers are the most requests submitted against?')}
          </Typography>
        </Box>

        <Box flex={1} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={CircularProgressSize.xLarge} />
        </Box>
      </StyledBox>
    )
  }

  if (!isLoadingAggregate && !isLoading && identitySpaces.length === 0) {
    return (
      <StyledBox
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: ({ palette }) => palette.white.main,
          borderRadius: 2,
          padding: 4,
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h3">{t('Per Identifier')}</Typography>
          <Typography variant="body" color="darkDuskFaded.main">
            {t('Which Identifiers are the most requests submitted against?')}
          </Typography>
        </Box>

        <Box flex={1} display="flex" alignItems="center" justifyContent="center">
          <EmptyState colorTheme="darkDuskFaded" iconName="OFilter" title="No data for selected filter criteria" />
        </Box>
      </StyledBox>
    )
  }

  return (
    <StyledBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        borderRadius: 2,
        background: ({ palette }) => palette.white.main,
        padding: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box mb={3.626} display="flex" flexDirection="column">
          <Typography variant="h3">{t('Per Identifier')}</Typography>
          <Typography variant="body" color="darkDuskFaded.main" sx={{ mb: 3.5 }}>
            {t('Which Identifiers are the most requests submitted against?')}
          </Typography>
          <DataGrid
            getRowId={row => row?.code || row?.name}
            sx={{
              '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-row::before': {
                display: 'none',
              },
              '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
                backgroundColor: 'transparent',
                backgroundImage: 'unset',
              },
              padding: 0,
            }}
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              includeOutliers: false,
              columns: distributionPerIdentityDataGridColumns(t).map((column, index) => column.field),
              expand: true,
            }}
            getRowHeight={() => 'auto'}
            columns={distributionPerIdentityDataGridColumns(t)}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowSelectionOnClick
            loading={isLoading || isLoadingAggregate || isPending}
            rows={transformedIdentitySpaceData}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            pageSizeOptions={[rowsPerPage]}
            rowCount={pagination.totalResults}
            hideFooterPagination={totalPages < 2}
          />
        </Box>
        <Typography variant="body" color="darkDusk.main">
          *{t('The same user can belong to multiple Identifiers.')}
        </Typography>
      </Box>
    </StyledBox>
  )
}
