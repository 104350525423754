import React, { useMemo, useState } from 'react'
import { Box, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { showToast } from 'components/modals/AlertComponent'
import { useContextCustomRightNameUtils } from '../hooks'
import { Chip, Button, ListLayout, EmptyState, DataGrid, ContentGroup, TabGroup, Tab } from '@ketch-com/deck'
import { DeleteCustomRightNameModal } from '../components'
import { RoutesManager } from 'utils/routing/routesManager'
import { useDeleteCustomRightName } from 'api/customRightNames/mutations/useDeleteCustomRightName'
import { Details, Usage } from '../components/ViewLayoutExpandedDetails/'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { useIsPermitted } from 'utils/hooks'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { GridRowParams } from '@mui/x-data-grid'
import { CustomRightNameDTO } from '@ketch-com/figurehead'
import { getCustomRightNameListColumns } from '../utils/customRightNameListGridUtils'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {}

export const CustomRightNameList: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const isCustomRightNameWriter = isPermitted(PERMISSIONS.CUSTOM_NAME_RIGHTS_WRITE)
  const {
    isEmptyState,
    searchString,
    setSearchString,
    handleCreateName,
    showDetailedView,
    setShowDetailedView,
    tabs,
    handleTabChange,
    currentTab,
    customRightNamesList,
    isLoading,
    refetchCustomRightNameList,
  } = useContextCustomRightNameUtils()
  const [currentRowItem, setCurrentRowItem] = useState<any>()
  const [confirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false)
  const [isBusy, setIsBusy] = useState(false)

  const breadcrumbs = [
    {
      title: 'Settings',
      link: RoutesManager.settings.root.getURL(),
    },
    { title: 'Right Names', link: RoutesManager.settings.customRightName.root.getURL() },
  ]

  const gridData = searchString
    ? customRightNamesList.filter(item => item?.name?.toLowerCase().includes(searchString.toLowerCase()))
    : customRightNamesList

  const getHeaderButtons = useMemo(() => {
    if (!isCustomRightNameWriter) {
      return (
        <>
          <Tooltip arrow placement="bottom" title={'You do not have the permissions delete a name.'}>
            <span>
              <Button disabled size="large" color="tertiary" onClick={() => setIsConfirmDeleteDialogOpen(true)}>
                Delete
              </Button>
            </span>
          </Tooltip>
          <Tooltip arrow placement="bottom" title={'You do not have the permissions edit a name.'}>
            <span>
              <Button
                disabled
                size="large"
                color="primary"
                onClick={() => {
                  navigate(RoutesManager.settings.customRightName.upsert.root.getURL({ id: currentRowItem.id }))
                }}
              >
                Edit
              </Button>
            </span>
          </Tooltip>
        </>
      )
    }

    return (
      <>
        <Button size="large" color="tertiary" onClick={() => setIsConfirmDeleteDialogOpen(true)}>
          Delete
        </Button>
        <Button
          size="large"
          color="primary"
          onClick={() => {
            navigate(RoutesManager.settings.customRightName.upsert.root.getURL({ id: currentRowItem.id }))
          }}
        >
          Edit
        </Button>
      </>
    )
  }, [currentRowItem, navigate, isCustomRightNameWriter])

  const determineCreateNameButton = () => {
    if (!isEmptyState && isCustomRightNameWriter) {
      return <Button onClick={handleCreateName}>Create Name</Button>
    } else if (!isEmptyState && !isCustomRightNameWriter) {
      // User is not permitted to write
      return (
        <Tooltip arrow placement="bottom" title={'You do not have the permissions to create a name.'}>
          <span>
            <Button disabled onClick={handleCreateName}>
              Create Name
            </Button>
          </span>
        </Tooltip>
      )
    }
  }

  const { mutate: handleDeleteCrn } = useDeleteCustomRightName({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'Custom Right Name deleted', type: 'success' })
      setIsBusy(false)
      setIsConfirmDeleteDialogOpen(false)
      setShowDetailedView(false)
      refetchCustomRightNameList()
    },
    onError: () => {
      showToast({ content: 'Failed to delete Custom Right Name', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      {showDetailedView ? (
        <Box display="flex" flexDirection="column" gap={4}>
          <ContentGroup
            titleVariant="h2"
            variant="inner-page-header"
            title={currentRowItem.name}
            infoBlockComponent={<Chip label="Custom Right Name" size="small" />}
            showBackButton={true}
            actionRightBlockComponent={getHeaderButtons}
            propsBackButton={{
              onClick: e => {
                setShowDetailedView(false)
                handleTabChange(e, 0)
              },
            }}
          >
            <TabGroup value={currentTab} onChange={handleTabChange}>
              {tabs.map((tabItem, index) => (
                <Tab {...tabItem} key={index} />
              ))}
            </TabGroup>
          </ContentGroup>
          <Box>
            {currentTab === 0 ? (
              <Details values={currentRowItem} tab={currentTab} />
            ) : (
              <Usage values={currentRowItem} tab={currentTab} />
            )}
          </Box>
        </Box>
      ) : (
        <ListLayout
          sx={{
            '&.DeckLayout-wrapper .DeckLayout-content': {
              minHeight: 'auto',
            },
          }}
          headerTitle="Custom Right Names"
          customHeaderRightActionComponent={determineCreateNameButton()}
          actionHeaderBottomBlockComponent={
            !isEmptyState ? (
              <SearchTextInput
                size="small"
                onChange={nextSearchString => setSearchString(nextSearchString)}
                showClearButton
                value={searchString}
                placeholder="Search"
              />
            ) : null
          }
          headerProps={{
            isDivider: true,
          }}
        >
          {isEmptyState ? (
            <Box
              sx={{
                backgroundColor: 'bone.main',
                padding: 5,
                borderRadius: '11px',
              }}
            >
              <EmptyState
                title="There are no Custom Right Names for this organization"
                subTitle="Create your first one to populate the table"
                iconName="FEdit2"
                primaryButtonTitle="Create Custom Right Name"
                primaryButtonProps={{
                  onClick: () =>
                    navigate(RoutesManager.settings.customRightName.upsert.root.getURL({ id: NEW_ITEM_ID })),
                }}
              />
            </Box>
          ) : (
            <Box>
              <DataGrid
                sx={{
                  cursor: 'pointer',
                  '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                    backgroundImage: 'unset',
                    cursor: 'auto',
                  },
                  ...(isLoading && {
                    '& .MuiDataGrid-virtualScroller': {
                      height: '300px',
                    },
                  }),
                }}
                autosizeOnMount
                autosizeOptions={{
                  expand: true,
                  includeHeaders: true,
                  includeOutliers: false,
                }}
                getRowHeight={() => 'auto'}
                columns={getCustomRightNameListColumns({ setCurrentRowItem })}
                disableChildrenSorting
                disableColumnMenu
                disableColumnPinning
                disableColumnResize
                disableColumnReorder
                disableRowSelectionOnClick
                getRowId={row => row.id}
                rows={gridData}
                hideFooter
                loading={isLoading || isBusy}
                noRowsOverlayProps={{
                  buttonTitle: '',
                }}
                onRowClick={({ row: rowItem }: GridRowParams<CustomRightNameDTO>) => {
                  setCurrentRowItem(rowItem)
                  setShowDetailedView(true)
                }}
              />
            </Box>
          )}
        </ListLayout>
      )}

      {/* Upsert Modal */}

      {confirmDeleteDialogOpen && (
        <DeleteCustomRightNameModal
          handleDeleteCrn={handleDeleteCrn}
          id={currentRowItem.id}
          onClose={() => setIsConfirmDeleteDialogOpen(false)}
        />
      )}
    </>
  )
}
