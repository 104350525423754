import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { Button, Chip } from '@ketch-com/deck'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { FormTemplateDTO } from '@ketch-com/figurehead'
import { FormTemplateUpsertContext } from '../../upsert/context/FormTemplateUpsertContextProvider'
import { MaybeNull } from 'interfaces'
import { FormMode } from 'interfaces/formModes/formMode'
import { useQueryParams } from 'utils/hooks'
import { FormTemplatePreviewQueryParams, PreviewFrom } from '../interfaces'
import { NEW_ITEM_ID } from 'utils/constants/misc'

type Props = {
  formTemplateDTO: MaybeNull<FormTemplateDTO>
}

export const FormTemplatePreviewHeader: React.FC<Props> = ({ formTemplateDTO }) => {
  const { isEditMode } = useContext(FormTemplateUpsertContext)
  const navigate = useNavigate()
  const { queries } = useQueryParams<{
    from?: FormTemplatePreviewQueryParams['from']
  }>()

  const handleExitPreview = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (queries?.from === PreviewFrom.Detail) {
      navigate(RoutesManager.deployment.forms.templates.root.getURL())
    }

    if (queries?.from === PreviewFrom.Editing) {
      const targetPathname = RoutesManager.deployment.forms.templates.upsert.root.getURL({
        id: formTemplateDTO?.id,
        formMode: FormMode.EDIT,
      })

      navigate({
        pathname: targetPathname,
        search: createSearchParams({
          formDTO: JSON.stringify(formTemplateDTO),
        }).toString(),
      })
    }

    if (queries?.from === PreviewFrom.New) {
      navigate({
        pathname: RoutesManager.deployment.forms.templates.upsert.root.getURL({
          id: NEW_ITEM_ID,
          formMode: FormMode.CREATE,
        }),
        search: createSearchParams({
          formDTO: JSON.stringify(formTemplateDTO),
        }).toString(),
      })
    }
  }

  const handleEditClick = () =>
    navigate(
      RoutesManager.deployment.forms.templates.upsert.root.getURL({
        formMode: FormMode.EDIT,
        id: formTemplateDTO?.id,
      }),
    )

  return (
    <Box width="100%">
      <Box mt={-3} mb={3} width="100%" px={4} py={2} bgcolor="white.main" boxShadow="0px 4px 10px rgba(7, 26, 36, 0.1)">
        <Box display="flex" justifyContent="space-between" alignItems="center" maxWidth={1280} margin="auto">
          {/* Left: Name and Code input */}

          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            sx={{
              wordBreak: 'break-all',
            }}
          >
            <Typography variant="h2">{formTemplateDTO?.name}</Typography>

            {formTemplateDTO?.code && (
              <Box>
                <Chip
                  size="small"
                  label={
                    <Typography variant="smallLabel">
                      Code: <Typography variant="smallBody">{formTemplateDTO?.code}</Typography>
                    </Typography>
                  }
                />
              </Box>
            )}
          </Box>

          {/* Right: Actions */}

          <Box display="flex" gap={1.5} alignItems="center">
            {isEditMode && (
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography variant="smallBody" color="darkDuskFaded.main">
                  Last Updated
                </Typography>

                <Typography color="darkDusk.main" variant="smallBody">
                  <FormattedDateRenderer date={formTemplateDTO?.metadata?.updatedAt} />
                </Typography>
              </Box>
            )}

            <Button size="large" color="secondary" onClick={handleEditClick}>
              Edit
            </Button>

            <Button size="large" color="primary" onClick={handleExitPreview}>
              Exit Preview
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
