import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { updateFormField } from '../fetchers/updateFormField'

const useCustomMutation = createUseMutation({
  mutationFn: updateFormField,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateFormField = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
