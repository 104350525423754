import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useAppInstanceUsages } from 'api/apps/queries/useAppInstanceUsages'
import { showToast } from 'components/modals/AlertComponent'
import { useDeleteConnection } from 'api/dataSystems/mutations/useDeleteConnection'
import { ConnectionV2DTO } from '@ketch-com/figurehead'
import { Button, DataGrid, PopUp, Spinner, TableCell } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { WorkflowDTO } from 'interfaces/workflows'
type Props = {
  connection: ConnectionV2DTO
  onSubmit?: () => void
  onCancel: () => void
}

export const DeleteAppInstanceModal: React.FC<Props> = props => {
  const queryClient = useQueryClient()
  const { connection, onSubmit, onCancel } = props
  const [isBusy, setIsBusy] = useState(false)

  const {
    data: appInstanceUsages,
    isLoading: isUsagesLoading,
    isFetching: isUsagesFetching,
  } = useAppInstanceUsages({
    params: {
      appInstanceId: connection?.appInstanceId ?? '',
    },
  })

  const { mutate: handleDeleteAppInstance } = useDeleteConnection({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'Connection deleted', type: 'success' })

      await queryClient.refetchQueries(ApiQueryKeys.connections)
      await queryClient.refetchQueries(ApiQueryKeys.installedDataSystem)
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to delete connection', type: 'error' })
      setIsBusy(false)
    },
  })

  const hasUsages = !!appInstanceUsages?.workflows?.length

  return (
    <>
      <PopUp
        title="Confirm Deletion"
        variant="dialog"
        popUpWidth="660px"
        onClose={onCancel}
        popUpActionChildren={
          <>
            <Button color="secondary" size="large" pending={isBusy} onClick={onCancel}>
              Close
            </Button>
            <Button
              color="primary"
              size="large"
              disabled={!!hasUsages}
              pending={isBusy}
              onClick={() => {
                handleDeleteAppInstance({
                  params: {
                    id: connection.id ?? '',
                  },
                })
              }}
            >
              Delete
            </Button>
          </>
        }
      >
        {isUsagesLoading || isUsagesFetching ? (
          <Box display="flex" alignItems="center" justifyContent="center" m={3}>
            <Spinner size="32px" thickness={2.5} />
          </Box>
        ) : hasUsages ? (
          <>
            <Typography>
              This connection cannot be deleted until it is no longer being used in the following contexts.
            </Typography>

            <DataGrid
              autosizeOnMount
              autosizeOptions={{
                includeHeaders: true,
                includeOutliers: false,
                columns: columns.map(column => column.field),
                expand: true,
              }}
              getRowHeight={() => 'auto'}
              columns={columns}
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              disableRowHoverStates
              disableRowSelectionOnClick
              getRowId={row => row.code}
              rows={appInstanceUsages?.workflows || []}
              hideFooter
              noRowsOverlayProps={{
                buttonTitle: '',
              }}
            />
          </>
        ) : (
          <Typography>
            Once you delete the connection, all associated functionalities will be unavailable. Are you sure you want to
            continue?
          </Typography>
        )}
      </PopUp>
    </>
  )
}

const columns: GridColDef<WorkflowDTO>[] = [
  {
    align: 'left',
    field: 'productArea',
    headerAlign: 'left',
    headerName: 'Product Area',
    sortable: false,
    flex: 1,

    renderCell: ({ row }: GridRenderCellParams<WorkflowDTO>) => {
      return <TableCell title="Workflow"></TableCell>
    },
  },
  {
    align: 'left',
    field: 'itemName',
    headerAlign: 'left',
    headerName: 'Used By',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { name, code } }: GridRenderCellParams<WorkflowDTO>) => {
      return <TableCell title={name} subTitle={code}></TableCell>
    },
  },
]
