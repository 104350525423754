import { createUseQuery, CustomQueryConfig } from '../../common/query'
import { ApiQueryKeys } from '../../common/queryKeys'
import { fetchPrivacyProtocols } from '../fetchers/fetchPrivacyProtocols'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.privacyProtocols,
  queryFn: fetchPrivacyProtocols,
  select: res => res?.data.privacyProtocols || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const usePrivacyProtocols = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
