import { DataRoleType } from 'interfaces/events'
import { RightInvocationFormValues } from 'interfaces/rightInvocations'

export const getRightInvocationInitialValues = (): RightInvocationFormValues => ({
  country: '',
  dataRole: DataRoleType.DATA_ROLE_CONTROLLER,
  dataSubjectTypeCode: '',
  dataSubjectTypeRelationshipDetails: '',
  description: '',
  email: '',
  first: '',
  city: '',
  addressLine1: '',
  addressLine2: '',
  postalCode: '',
  phone: '',
  // temp value required here to prevent validation errors from preventing form submission.
  // "(As entered above)" will be replaced by email value in mapCreateRightInvocationValuesToPayload.ts.
  // Email input is required so value will not be empty.
  identities: [{ code: 'email', value: '(As entered above)' }],
  last: '',
  policyScopeCode: '',
  rightCode: '',
  stateRegion: '',
  formData: [],
})
