import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import clsx from 'clsx'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseError: {
        display: 'block',
        fontSize: typography.pxToRem(12),
        fontWeight: 500,
        marginTop: 4,
        color: palette.chileanFire.main,
      },
    }),
  { name: 'Error' },
)

export interface Props {
  children?: React.ReactNode
  /** Component custom className */
  className?: any
}

/**
 * -
 */
export const Error: React.FC<Props> = ({ children, className }) => {
  const classes = useStyles()

  return <span className={clsx(classes.baseError, className)}>{children}</span>
}
