import * as Yup from 'yup'
import { object } from 'yup'

import { CreateAuthenticatorFormValues } from './getAuthenticatorInitialValues'
import { useMemo } from 'react'
import { mergeSchemas } from 'pages/policyCenter/purposes/upsert/utils/purposeFormValidationSchemaUtils'

export const useAuthenticatorDetailsValidationSchema = () =>
  object().shape({
    type: Yup.string().required('Required'),
    accessTokenUrl: Yup.string().url('Access Token URL must be a valid URL').required('Auth URL is required'),
    authUrl: Yup.string().url('Auth URL must be a valid URL').required('Auth URL is required'),
    clientId: Yup.string().required('Client ID is required'),
    clientSecret: Yup.string().required('Client Secret is required'),
    name: Yup.string().required('Name is required'),
    redirectUrl: Yup.string().url('Redirect URL must be a valid URL'),
    scopes: Yup.string(),
  })

export const useAuthenticatorVariablesMappingValidationSchema = () =>
  object().shape({
    variableClaimMappings: Yup.array()
      .of(
        Yup.object().shape({
          sourceKey: Yup.string().required('Required'),
          destination: Yup.number().required('Required'),
          destinationKey: Yup.string()
            .required('Required')
            .test(
              'Unique Destination/Destination Key Test',
              'Cannot contain a dash (-) or a period (.)',
              (value, context: any) => {
                let isValid = true
                // validation per jira comment: https://ketch-com.atlassian.net/browse/KD-4404?focusedCommentId=14160
                // value cannot contain a period
                if (value && value.includes('.')) isValid = false
                // value cannot contain a dash
                if (value && value.includes('-')) isValid = false

                return isValid
              },
            )
            .test('Unique Destination/Destination Key Test', 'Must start with a letter', (value, context: any) => {
              let isValid = true
              // validation per jira comment: https://ketch-com.atlassian.net/browse/KD-4404?focusedCommentId=14160
              const regex = /^[a-zA-Z]/
              // regex test to make sure value starts with a letter
              if (value && !regex.test(value)) isValid = false
              return isValid
            }),
        }),
      )
      .min(1, 'At least one variable claim mapping is required')
      .test(
        'Unique Destination/Destination Key Test',
        'You have a duplicated Type/Option pair in your list of Variable Mappings. The last one in the list will overwrite any preceding variables.',
        (value, context: any) => {
          const [{ value: formValues }] = context.from as { value: CreateAuthenticatorFormValues }[]
          const mappingChecksums = (formValues?.variableClaimMappings || [])
            .map(v => {
              // if there's not yet enough data to calculate a checksum, return empty string
              if (!v.destination || !v.destinationKey) return ''
              return `${v?.destination || ''}_${v?.destinationKey || ''}`
            })
            // remove any empty strings
            .filter(v => !!v)
          const isDuplication = mappingChecksums.length !== new Set(mappingChecksums).size

          return !isDuplication
        },
      ),
  })

export const useGetCreateAuthenticatorValidationSchema = ({ currentFormStep }: { currentFormStep: number }) => {
  const authenticatorDetailsValidationSchema = useAuthenticatorDetailsValidationSchema()
  const authenticatorVariablesMappingValidationSchema = useAuthenticatorVariablesMappingValidationSchema()
  return useMemo(() => {
    if (currentFormStep === 0) {
      return authenticatorDetailsValidationSchema
    } else {
      return mergeSchemas(authenticatorDetailsValidationSchema, authenticatorVariablesMappingValidationSchema)
    }
  }, [authenticatorDetailsValidationSchema, authenticatorVariablesMappingValidationSchema, currentFormStep])
}

export const useGetEditAuthenticatorValidationSchema = () => {
  const authenticatorDetailsValidationSchema = useAuthenticatorDetailsValidationSchema()
  const authenticatorVariablesMappingValidationSchema = useAuthenticatorVariablesMappingValidationSchema()
  return mergeSchemas(authenticatorDetailsValidationSchema, authenticatorVariablesMappingValidationSchema)
}
