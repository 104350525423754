import React from 'react'
import { useFormikContext, FieldArrayRenderProps } from 'formik'
import { Box, Typography, Tooltip } from '@mui/material'
import {
  SubscriptionTopicFormData,
  OrchestrationData,
  ConnectionItem,
} from 'pages/policyCenter/subscriptions/interfaces'
import { Chip, Icon, theme } from '@ketch-com/deck'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { FormInput } from 'components/form/FormInput'

type Props = {
  orchestration: OrchestrationData
  fieldArrayHelpers: FieldArrayRenderProps
  fieldArrayIndex: number
  channelCode: string
  isSystemConnectionsLoading: boolean
  connectionItems: ConnectionItem[]
}

export const Hubspot: React.FC<Props> = ({
  orchestration,
  fieldArrayHelpers,
  fieldArrayIndex,
  channelCode,
  isSystemConnectionsLoading,
  connectionItems,
}) => {
  const { isSubmitting, setFieldValue } = useFormikContext<SubscriptionTopicFormData>()
  const { remove } = fieldArrayHelpers

  return (
    <Box display="flex" alignItems="flex-start" gap={3} py={1.5}>
      <Chip
        icon={<img src={orchestration?.appLogo?.url} alt={orchestration?.appLogo?.title} width={24} height={24} />}
        disabled={isSubmitting || isSystemConnectionsLoading}
        size="medium"
        label={orchestration.name}
        onDelete={() => remove(fieldArrayIndex)}
        deleteIcon={<Icon name="OCross" />}
      />
      <FormDroplistButton
        sx={{ width: 220 }}
        required
        size="small"
        disabled={isSubmitting || isSystemConnectionsLoading}
        items={connectionItems || []}
        formPropertyName={`contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appInstanceId`}
        valueKey="id"
        placeholder="Select Connection"
        label="Select Connection"
        onItemClick={option => {
          setFieldValue(`contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appInstanceId`, option.id)
          setFieldValue(`contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appInstanceName`, option.name)
        }}
      />
      <Box display="flex" gap={1}>
        <Box>
          <FormInput
            shouldUpdateDebounced
            required
            size="small"
            formPropertyName={`contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appParams.groupID`}
            placeholder="Subscription Type"
            disabled={isSubmitting || isSystemConnectionsLoading}
          />
        </Box>
        <Tooltip
          placement="bottom"
          title={
            <Typography variant="body">
              {orchestration.name} require additional configuration to ensure orchestration function properly. Specify a
              field in {orchestration.name} that will be controlled by this channel.
            </Typography>
          }
        >
          <Box display="flex" alignItems="center" height={28}>
            <Icon name="FUnknown" iconColor={theme.palette.darkDuskFaded.main} width={20} height={20} />
          </Box>
        </Tooltip>
      </Box>
    </Box>
  )
}
