import { JurisdictionDTO, StackDTO, StackGroupDTO } from '@ketch-com/figurehead'
import { useMemo } from 'react'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

export type StackGroupWithId = Omit<StackGroupDTO, 'purposes'> & {
  id?: string
  purposes?: string[]
}

export interface StackForm {
  name: string
  jurisdictions: string[]
  groups: StackGroupWithId[]
}

export const getEmptyStackGroup = (): StackGroupWithId => ({
  name: '',
  purposes: [],
  translations: {},
  id: uuidv4(), //StackGroups do not have an ID, but one is needed on the FE to manage the dynamic form
})

export const getPurposeStacksInitialValues = (stack?: StackDTO) => {
  return {
    name: stack?.name || '',
    jurisdictions: stack?.jurisdictions?.map(j => j.code || '') || [],
    groups: stack?.groups?.map(group => ({ ...group, purposes: group.purposes?.map(p => p.code || '') || [] })) || [
      getEmptyStackGroup(),
    ],
  }
}

export const getSubmitValues = (stackForm: StackForm, jurisdictions: JurisdictionDTO[]) => ({
  name: stackForm.name,
  jurisdictions: stackForm.jurisdictions.map(code => ({ code, name: jurisdictions.find(j => j.code === code)?.name })),
  groups: stackForm.groups.map(group => ({
    name: group.name,
    translations: group.translations,
    purposes: group.purposes?.map(code => ({ code })),
  })),
})

export const usePurposeStacksValidationSchema = () => {
  return useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required('Required'),
        jurisdictions: Yup.array().of(Yup.string()).min(1),
        groups: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required('Required'),
            purposes: Yup.array().of(Yup.string()).min(1),
          }),
        ),
      }),
    [],
  )
}
