import React from 'react'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'

import Box from '@mui/material/Box'
import { FormRow } from '@ketch-com/deck'
import { FormSwitch } from 'components/form/FormSwitch'
import { FormInput } from 'components/form/FormInput'
import { DataSubjectTypeLanguages } from './DataSubjectTypeLanguages'

import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { formatCodeFromName } from 'utils/formatters'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'

type Props = {
  isEditMode: boolean
  languages: LanguageWithStatusDTO[]
}

export const CustomDataSubjectType: React.FC<Props> = ({ isEditMode, languages }) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext<DataSubjectTypeDTO>()
  return (
    <FormRow title="Create Custom Subject type" subTitle="Provide translations to all languages bellow.">
      <Box>
        <FormSwitch
          formPropertyName="isCustomType"
          onChange={e => e.currentTarget.value === 'on' && setFieldValue('templateId', '')}
          label="Custom Subject Type"
          inlineLabel
          size="medium"
        />
      </Box>

      {!!values.isCustomType && (
        <>
          <Box mb={2}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormInput
                  required
                  fullWidth
                  label="Name"
                  formPropertyName="name"
                  placeholder="Enter recognizable Subject Type name"
                  shouldUpdateDebounced
                  onChange={e => {
                    if (!isEditMode) {
                      setFieldTouched('code', true)
                      setFieldValue('code', formatCodeFromName(e.target.value))
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={2}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormInput
                  disabled={isEditMode}
                  required
                  fullWidth
                  label="Code"
                  formPropertyName="code"
                  placeholder="Enter code"
                  shouldUpdateDebounced
                />
              </Grid>
            </Grid>
          </Box>
          <DataSubjectTypeLanguages languages={languages} />
        </>
      )}
    </FormRow>
  )
}
