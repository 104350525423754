import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createIdentitySpace } from 'api/identitySpaces/fetchers/createIdentitySpace'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomMutation = createUseMutation({
  mutationFn: createIdentitySpace,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateIdentitySpace = (config?: Config) => {
  const { userData } = useAuth()
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    organizationCode: userData.organizationCode,
  })
}
