import React from 'react'
import { Box } from '@mui/material'
import { protocolId } from '../constants'
import { theme } from '@ketch-com/deck'

import { ReactComponent as GPCIcon } from 'assets/icons/gpc_logo.svg'
import { ReactComponent as GoogleIcon } from 'assets/icons/google-consent-mode.svg'
import { ReactComponent as IABStandardIcon } from 'assets/icons/iab-standard.svg'
import { ReactComponent as IABTCFIcon } from 'assets/icons/iab-tcf.svg'
import { ReactComponent as IABCanadaIcon } from 'assets/icons/iab-canada.svg'

type Props = {
  protocolId: string
  variant?: 'small' | 'large'
}

export const protocolIcons: { [key: string]: any } = {
  [protocolId.gpc]: <GPCIcon />,
  [protocolId.google]: <GoogleIcon />,
  [protocolId.tcfeu]: <IABTCFIcon />,
  [protocolId.tcfeuLegacy]: <IABTCFIcon />,
  [protocolId.tcfca]: <IABCanadaIcon />,
  [protocolId.usca]: <IABStandardIcon />,
  [protocolId.uscaLegacy]: <IABStandardIcon />,
  [protocolId.usva]: <IABStandardIcon />,
  [protocolId.usps]: <IABStandardIcon />,
  [protocolId.usco]: <IABStandardIcon />,
  [protocolId.usut]: <IABStandardIcon />,
  [protocolId.usct]: <IABStandardIcon />,
  [protocolId.usnat]: <IABStandardIcon />,
  '': <IABStandardIcon />,
}

/**
 * An icon container for privacy protocol icons.
 *
 */
export const ProtocolIcon: React.FC<Props> = ({ protocolId, variant }) => {
  const Icon = protocolIcons[protocolId]

  const props = variant === 'large' ? { width: '50px', height: '50px' } : {}

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="8px"
      sx={{
        background: theme.palette.fadedGrey.main,
        padding: variant === 'small' ? '6px' : '6px',
        width: variant === 'large' ? '62px' : '48px',
        height: variant === 'large' ? '62px' : '48px',
      }}
    >
      {!!Icon ? React.cloneElement(Icon, props) : <></>}
    </Box>
  )
}
