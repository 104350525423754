import React, { useMemo } from 'react'
import { Card, ContentGroup, DataGrid, EmptyState, Spinner } from '@ketch-com/deck'
import { useAuditLogRightInvocations } from '../hooks'
import { auditLogsInvokedRightsColumns } from './auditLogsDataGridUtils'
import { Box } from '@mui/material'

export const AuditLogsInvokedRights: React.FC<{}> = () => {
  const right = useAuditLogRightInvocations()

  const { pagination } = right
  const totalPages = pagination.totalPages
  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  return (
    <Card
      sx={{
        padding: '32px',
        width: '50%',
        background: theme => theme.palette.white.main,
      }}
      customContentCard={
        <Box
          sx={{
            height: 440,
            width: '100%',
          }}
        >
          {right.isLoading ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <Spinner size="32px" thickness={2.5} />
            </Box>
          ) : (
            <>
              <ContentGroup
                title="Invoked Rights"
                subTitle="What rights were invoked for this identifier?"
                variant="inner-page-header"
                titleVariant="h3"
              />

              {!right.data.length ? (
                <Box
                  sx={{
                    height: '100%',
                  }}
                >
                  <EmptyState
                    iconName="OScales"
                    title="No rights invoked yet"
                    subTitle="Invoked rights will be displayed here."
                  />
                </Box>
              ) : (
                <DataGrid
                  sx={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    height: 'auto',
                  }}
                  autosizeOnMount
                  columns={auditLogsInvokedRightsColumns}
                  disableChildrenSorting
                  disableColumnMenu
                  disableColumnPinning
                  disableColumnResize
                  disableColumnReorder
                  getRowId={row => row.id}
                  loading={right.isLoading || pagination.isPending}
                  rows={right.data}
                  pagination
                  paginationMode="server"
                  paginationModel={paginationModel}
                  onPaginationModelChange={onPaginationModelChange}
                  pageSizeOptions={[rowsPerPage]}
                  rowCount={pagination.totalResults}
                />
              )}
            </>
          )}
        </Box>
      }
    />
  )
}
