import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchAuthenticators } from '../fetchers/fetchAuthenticators'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.authenticatorsPaginated,
  queryFn: fetchAuthenticators,
  select: res => res?.data?.authenticators || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useAuthenticators = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    itemsPerPage: config?.itemsPerPage || 10,
    params: {
      ...config?.params,
    },
  })
}
