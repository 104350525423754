import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, TableCell } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { PURPOSE_DATA_ROLE_NAMES, PurposeDataRole } from 'interfaces/purposes/purposeDataRole'

type WorkflowTabDataType = {
  name?: string
  description?: string
  code?: string
  dataSubjectTypeName?: string
  dataRole?: number
}

export const useWorkflowTabEventsColumns = (): GridColDef<WorkflowTabDataType>[] => [
  {
    align: 'left',
    field: 'name',
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    flex: 0.7,

    renderCell: ({ row: { name, code } }: GridRenderCellParams<WorkflowTabDataType>) => {
      return <TableCell title={name} subTitle={code} />
    },
  },
  {
    align: 'left',
    field: 'description',
    headerAlign: 'left',
    headerName: 'Description',
    sortable: false,
    flex: 1,
    renderCell: ({ row: { description } }: GridRenderCellParams<WorkflowTabDataType>) => {
      return <TableCell title={description} />
    },
  },
  {
    align: 'left',
    field: 'dataRole',
    headerAlign: 'left',
    headerName: 'Data Role',
    sortable: false,
    flex: 1,
    maxWidth: 180,
    renderCell: ({ row: { dataRole } }: GridRenderCellParams<WorkflowTabDataType>) => {
      return (
        <TableCell>
          {dataRole ? <Chip label={PURPOSE_DATA_ROLE_NAMES[dataRole as PurposeDataRole]} /> : <EmptyValueRenderer />}
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'dataSubjectType',
    headerAlign: 'left',
    headerName: 'Subject Type',
    sortable: false,
    flex: 1,
    renderCell: ({ row: { dataSubjectTypeName } }: GridRenderCellParams<WorkflowTabDataType>) => {
      return (
        <TableCell>{dataSubjectTypeName ? <Chip label={dataSubjectTypeName} /> : <EmptyValueRenderer />}</TableCell>
      )
    },
  },
]
