import { theme } from '@ketch-com/deck'
import { StatusVariantV3Enum } from '../../interfaces'

const { palette } = theme

export const getMediumAndLargeColor = (variant: StatusVariantV3Enum): string => {
  switch (variant) {
    case StatusVariantV3Enum.draft:
      return palette.darkDusk.main
    case StatusVariantV3Enum.new:
      return palette.heliotrope.main
    case StatusVariantV3Enum.inProgress:
      return palette.chileanFire.main
    case StatusVariantV3Enum.activeEnabled:
      return palette.greenHaze.main
    case StatusVariantV3Enum.errorCanceled:
      return palette.red.main
    case StatusVariantV3Enum.finishedDone:
      return palette.sphere.main
    case StatusVariantV3Enum.inactiveArchive:
      return palette.darkDuskFaded.main
    default:
      return palette.darkDusk.main
  }
}
