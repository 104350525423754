import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import clsx from 'clsx'

import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'
import {
  NavigationBreadCrumbs,
  NavigationBreadCrumbsProps,
} from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

const useStyles = makeStyles(
  theme =>
    createStyles({
      layout: {
        position: 'relative',
        flex: 1,
        padding: theme.spacing(0, 1.75),
        borderRadius: 11,
        width: 1280,
      },
      loading: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        minHeight: 250,
      },
      versionsMessage: {
        marginBottom: 24,
      },
      issuesMessage: {
        marginBottom: 24,
      },
      ignoreFlex: {
        flex: 0,
      },
    }),
  { name: 'ViewLayoutLeftMenu' },
)

type Props = {
  children?: React.ReactNode
  isReady?: boolean
  breadcrumbs?: NavigationBreadCrumbsProps['items']
  issuesMessage?: React.ReactNode
  versionsMessage?: React.ReactNode
  ignoreFlex?: boolean
}

export const ViewLayoutLeftMenu: React.FC<Props> = ({
  isReady = true,
  breadcrumbs = [],
  children,
  issuesMessage,
  versionsMessage,
  ignoreFlex = false,
}) => {
  const classes = useStyles()

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      {isReady && !!issuesMessage && (
        <ContentBoundaries className={classes.issuesMessage} width={1280}>
          {issuesMessage}
        </ContentBoundaries>
      )}
      {isReady && !!versionsMessage && (
        <ContentBoundaries className={classes.versionsMessage} width={1280}>
          {versionsMessage}
        </ContentBoundaries>
      )}
      <div
        className={clsx(classes.layout, {
          [classes.ignoreFlex]: ignoreFlex,
        })}
      >
        {isReady ? (
          children
        ) : (
          <Flex className={classes.loading} alignItems="center">
            <Spinner />
          </Flex>
        )}
      </div>
    </>
  )
}
