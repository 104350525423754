import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchAppInstances } from 'api/apps/fetchers/fetchAppInstances'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.appInstances,
  queryFn: fetchAppInstances,
  select: res => res?.data?.instances || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery, 'organizationCode'>

export const useAppInstances = (config?: Config) => {
  const { userData } = useAuth()

  return useCustomQuery({
    ...config,
    params: {
      start: 0,
      limit: 1000,
      organizationCode: userData.organizationCode!,
      ...config?.params,
    },
  })
}
