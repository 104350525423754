import { useMemo, useState } from 'react'
import {
  Avatar,
  AvatarSize,
  Button,
  ContentGroup,
  Icon,
  InlineEdit,
  SettingsLayout,
  SettingsLayoutMenuItem,
  TextInput,
} from '@ketch-com/deck'
import { Box } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

interface Props {}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    someClassName: {
      backgroundColor: palette.bone.main,
    },
  }),
)

export const SettingsLayoutExample: React.FC<Props> = props => {
  const [renderedElem, setRenderedElem] = useState(1)
  const [selectedElem, setSelectedElem] = useState<string>()

  const classes = useStyles()

  const getHeaderButtons = useMemo(() => {
    return (
      <>
        <Button size="medium" color="tertiary" startIcon={<Icon name="OInfo" />}>
          How does it work
        </Button>
        <Button size="medium" color="tertiary" startIcon={<Icon name="OCog" />}>
          Settings
        </Button>
        <Button size="medium" color="primary">
          Primary action
        </Button>
      </>
    )
  }, [])

  const menuItems: SettingsLayoutMenuItem[] = [
    {
      title: 'Section A',
      onClickHandler: () => {
        setRenderedElem(1)
        setSelectedElem('Section A')
      },
      listItemProps: {
        selected: selectedElem === 'Section A',
      },
    },
    {
      title: 'Section B',
      onClickHandler: () => {
        setRenderedElem(2)
        setSelectedElem('Section B')
      },
      listItemProps: {
        selected: selectedElem === 'Section B',
      },
    },
    {
      title: 'Section C',
      onClickHandler: () => {
        setRenderedElem(1)
        setSelectedElem('Section C')
      },
      listItemProps: {
        selected: selectedElem === 'Section C',
      },
    },
    {
      title: 'Section D',
      onClickHandler: () => {
        setRenderedElem(2)
        setSelectedElem('Section D')
      },
      listItemProps: {
        selected: selectedElem === 'Section D',
      },
    },
    {
      title: 'Section E',
      subItems: [
        {
          title: 'Section E1',
          onClickHandler: () => {
            setRenderedElem(3)
            setSelectedElem('Section E1')
          },
          listItemProps: {
            selected: selectedElem === 'Section E1',
          },
        },
        {
          title: 'Section E2',
          onClickHandler: () => {
            setRenderedElem(4)
            setSelectedElem('Section E2')
          },
          listItemProps: {
            selected: selectedElem === 'Section E2',
          },
        },
      ],
    },
  ]

  const getRenderedChild = () => {
    switch (renderedElem) {
      case 1:
        return (
          <Box display="flex" flexDirection="column" gap={3}>
            <ContentGroup
              title={selectedElem}
              titleVariant="h2"
              isDivider
              actionRightBlockComponent={getHeaderButtons}
            />
            <TextInput />
          </Box>
        )
      case 2:
        return (
          <Box display="flex" flexDirection="column" gap={3}>
            <ContentGroup
              title={selectedElem}
              titleVariant="h2"
              isDivider
              actionRightBlockComponent={getHeaderButtons}
            />
            <InlineEdit />
          </Box>
        )
      case 3:
        return (
          <Box display="flex" flexDirection="column" gap={3}>
            <ContentGroup
              title={selectedElem}
              titleVariant="h2"
              isDivider
              actionRightBlockComponent={getHeaderButtons}
            />
            <TextInput />
            <InlineEdit />
          </Box>
        )
      case 4:
        return (
          <Box
            sx={{
              height: '500px',
              backgroundColor: theme => theme.palette.bone.main,
              padding: 2,
            }}
          >
            <Avatar icon="OGlobe" size={AvatarSize['2xlarge']} />
          </Box>
        )
    }
  }

  return (
    <Box
      sx={{
        height: '100vh',
        paddingTop: '24px',
        backgroundColor: theme => theme.palette.bone.main,
      }}
    >
      <SettingsLayout className={classes.someClassName} menuItems={menuItems}>
        {getRenderedChild()}
      </SettingsLayout>
    </Box>
  )
}
