import { Typography, TypographyProps } from '@mui/material'

export interface EmptyValueRendererType extends TypographyProps {
  text?: string
}

export const EmptyValueRenderer = ({ text, ...rest }: EmptyValueRendererType) => {
  return (
    <Typography color="darkDuskFaded.main" {...rest}>
      {text || 'None'}
    </Typography>
  )
}
