import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useApiKey } from 'api/apiKeys/queries/useApiKey'
import { showToast } from 'components/modals/AlertComponent'
import { useDeactivateApiKey } from 'api/apiKeys/mutations/useDeactivateApiKey'
import { ApiKeyStatus } from 'interfaces/apiKeys'
import { useEnableApiKey } from 'api/apiKeys/mutations/useEnableApiKey'
import { Button, PopUp, Spinner } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

type Props = {
  apiKeyId: string
  onSubmit?: () => Promise<void>
  onCancel: () => void
}

export const ToggleApiKeyActivationStatusModal: React.FC<Props> = ({ apiKeyId, onSubmit, onCancel }) => {
  const queryClient = useQueryClient()

  const [isBusy, setIsBusy] = useState(false)

  const { data: apiKey, isLoading: isApiKeyLoading } = useApiKey({
    enabled: !!apiKeyId,
    params: {
      apiKeyId,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch API Key', type: 'error' })
      onCancel()
    },
  })

  const { mutate: handleDeactivateApiKey } = useDeactivateApiKey({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'API Key deactivated', type: 'success' })

      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to deactivate API Key', type: 'error' })
      setIsBusy(false)
    },
  })

  const { mutate: handleEnableApiKey } = useEnableApiKey({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'API Key activated', type: 'success' })

      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to activate API Key', type: 'error' })
      setIsBusy(false)
    },
  })

  if (!apiKey) return null

  return (
    <PopUp
      title={apiKey.status === ApiKeyStatus.ENABLED ? 'Deactivate API Key' : 'Activate API Key'}
      popUpWidth="550px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isBusy} onClick={onCancel}>
            Close
          </Button>
          <Button
            color="primary"
            size="large"
            pending={isBusy}
            onClick={() => {
              if (apiKey.status === ApiKeyStatus.ENABLED) {
                handleDeactivateApiKey({
                  params: {
                    apiKeyId: apiKey.id,
                  },
                })
              } else {
                handleEnableApiKey({
                  params: {
                    apiKeyId: apiKey.id,
                  },
                })
              }
            }}
          >
            {apiKey.status === ApiKeyStatus.ENABLED ? 'Deactivate' : 'Activate'}
          </Button>
        </>
      }
    >
      {isApiKeyLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : (
        <Typography>
          {apiKey.status === ApiKeyStatus.DISABLED && (
            <>
              Are you sure you want to activate <Typography variant="label">{apiKey?.name}</Typography>?
            </>
          )}
          {apiKey.status === ApiKeyStatus.ENABLED && (
            <>
              Are you sure you want to deactivate <Typography variant="label">{apiKey?.name}</Typography>?
            </>
          )}
        </Typography>
      )}
    </PopUp>
  )
}
