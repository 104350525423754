export type CountryWithCodeType = {
  code: string
  value: string
}
export const countriesWithCodes: CountryWithCodeType[] = [
  { code: 'US', value: 'United States' },
  { code: 'GB', value: 'United Kingdom' },
  { code: 'CA', value: 'Canada' },
  { code: 'FR', value: 'France' },
  { code: 'DE', value: 'Germany' },
  { code: 'IT', value: 'Italy' },
  { code: 'ES', value: 'Spain' },
  { code: 'AU', value: 'Australia' },
  { code: 'BR', value: 'Brazil' },
  { code: 'IN', value: 'India' },
  // add more countries as needed
]
export const options = ['Option A', 'Option B', 'Option C', 'Option D']
