import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { ForceCompleteRightInvocationRequestBodyDTO } from '@ketch-com/figurehead'

type Params = {
  requestId: string
  formData: ForceCompleteRightInvocationRequestBodyDTO
}

export const forceCompleteRightInvocation = ({ requestId, formData }: Params) =>
  API.put<{}>(
    formatRequestString({
      entityUrl: `/api/v2/right-invocations/${requestId}/complete`,
    }),
    formData,
  )
