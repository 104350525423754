import React from 'react'
import Box from '@mui/material/Box'
import clsx from 'clsx'
import { SxProps, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { useField, FieldValidator } from 'formik'
import { isNil } from 'lodash'

import { InlineEdit, InlineEditProps } from 'components/ui-kit/inlineEdit/InlineEdit'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { Label } from 'components/ui-kit/form/common/label/Label'

const useStyles = makeStyles(
  createStyles({
    root: ({ maxWidth, fullWidth }: { maxWidth?: number; fullWidth?: boolean }) => ({
      display: 'inline-block',
      ...(fullWidth && {
        display: 'block',
        minWidth: '100%',
      }),
      maxWidth: fullWidth || isNil(maxWidth) ? '100%' : maxWidth,
    }),
  }),
  { name: 'FormInlineEdit' },
)

type Props = Omit<InlineEditProps, 'value' | 'valid' | 'onBlur' | 'name'> & {
  /** Formik field name */
  name: string
  /** Display Asterisk as required field */
  required?: boolean
  /** Text to be displayed above Component */
  label?: string
  /** Custom validation method */
  validate?: FieldValidator
  /** Style overrides */
  sx?: SxProps<Theme>
  /** Additional onBlur logic */
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
}

export const FormInlineEdit: React.FC<Props> = ({
  label,
  name,
  validate,
  required = false,
  fullWidth,
  maxWidth,
  className,
  onChange: onChangeOuter,
  sx,
  onBlur,
  ...rest
}) => {
  const classes = useStyles({ fullWidth, maxWidth })
  const [{ value, onChange, onBlur: formikOnBlur }, { error, touched }] = useField({ name, validate })
  const showError = error && touched

  return (
    <Box className={clsx(classes.root, className)} sx={sx}>
      {!!label && <Label required={required}>{label}</Label>}
      <InlineEdit
        {...rest}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        name={name}
        valid={!showError}
        value={value}
        onChange={e => {
          onChange(e)
          onChangeOuter?.(e)
        }}
        onBlur={e => {
          onBlur?.(e)
          formikOnBlur(e)
        }}
      />
      {showError && <Error>{error}</Error>}
    </Box>
  )
}
