import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, TableCell } from '@ketch-com/deck'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { DataSubjectTypesListDropdown } from './DataSubjectTypesListDropdown'

export const useDataSubjectTypesListColumns = ({
  dataSubjectTypes,
}: {
  dataSubjectTypes: DataSubjectTypeDTO[]
}): GridColDef<DataSubjectTypeDTO>[] => [
  {
    align: 'left',
    field: 'subjectType',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Subject Type',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<DataSubjectTypeDTO>) => {
      return <TableCell title={row.name} subTitle={row.code} />
    },
  },
  {
    align: 'left',
    field: 'additionalInformation',
    flex: 0.4,
    headerAlign: 'left',
    headerName: 'Additional Information',
    sortable: false,
    renderCell: ({ row: { requiresUserInput } }: GridRenderCellParams<DataSubjectTypeDTO>) => {
      return <TableCell title={requiresUserInput ? 'Collected' : 'Not Collected'} />
    },
  },
  {
    align: 'left',
    field: 'translations',
    flex: 0.2,
    headerAlign: 'left',
    headerName: 'Translations',
    sortable: false,
    renderCell: ({ row: { translations } }: GridRenderCellParams<DataSubjectTypeDTO>) => {
      return (
        <TableCell>
          <Chip label={Object.keys(translations || {}).length} size="small" />
        </TableCell>
      )
    },
  },
  {
    align: 'right',
    field: 'dropDown',
    flex: 1,
    headerName: ' ',
    headerAlign: 'left',
    maxWidth: 80,
    sortable: false,
    renderCell: ({ row: dataSubjectType }: GridRenderCellParams<DataSubjectTypeDTO>) => {
      return (
        <TableCell>
          <DataSubjectTypesListDropdown dataSubjectType={dataSubjectType} dataSubjectTypes={dataSubjectTypes} />
        </TableCell>
      )
    },
  },
]
