import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces/common'
import { CanonicalPurposeDTO } from 'interfaces/purposes/canonicalPurpose'
import { AppDescriptorDTO } from 'interfaces/apps'

import { RoutesManager } from 'utils/routing/routesManager'
import { PermitPropagationFormValues, getInitialValues } from 'pages/consentAndRights/consent/systems/edit/utils'

import { ContentGroup, DataGrid, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'
import { getPermitPropagationEditListColumns } from './permitPropagationEditGridUtils'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isReady: boolean
  appDescriptor: MaybeNull<AppDescriptorDTO>
  canonicalPurposes: CanonicalPurposeDTO[]
  onSubmit: (values: PermitPropagationFormValues) => Promise<void>
}

export const PermitPropagationEdit: React.FC<Props> = ({ isReady, onSubmit, appDescriptor, canonicalPurposes }) => {
  const navigate = useNavigate()

  const appName = appDescriptor?.app?.name
  const appId = appDescriptor?.app?.ID
  const appInstances = appDescriptor?.appInstances || []

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    {
      title: 'Consent',
      link: RoutesManager.orchestration.consent.systems.root.getURL(),
    },
    {
      title: appName,
      link: RoutesManager.orchestration.consent.systems.view.root.getURL({ id: appId }),
    },
    { title: 'Manage Purposes' },
  ]

  const appCanonicalPurposes = useMemo(() => {
    if (!appDescriptor || !canonicalPurposes.length) {
      return []
    }

    return (
      appDescriptor?.app?.supportedPurposes?.map(
        canonicalPurposeCode => canonicalPurposes.find(({ code }) => code === canonicalPurposeCode)!,
      ) || []
    )
  }, [appDescriptor, canonicalPurposes])

  const initialValues = useMemo(() => getInitialValues({ appDescriptor }), [appDescriptor])

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,

    onSubmit: onSubmit,
  })
  const { submitForm, isSubmitting } = formikProps

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={UpsertLayoutMode.edit}
          renderFormTitle={() => <ContentGroup title="Manage Purposes" titleVariant="h2" isDivider />}
          onAccept={submitForm}
          acceptActionButtonText="Save"
          onCancel={() => {
            navigate(RoutesManager.orchestration.consent.systems.view.root.getURL({ id: appId }))
          }}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {isReady ? (
              <Box pt={2}>
                {appInstances.map(({ ID, name, code }) => (
                  <Box display="flex" flexDirection="column" gap={3} pb={6} key={ID}>
                    <ContentGroup variant="inner-page-header" title={name} subTitle={code} titleVariant="h3" />
                    <DataGrid
                      autosizeOnMount
                      autosizeOptions={{
                        expand: true,
                        includeHeaders: true,
                        includeOutliers: false,
                      }}
                      getRowHeight={() => 'auto'}
                      columns={getPermitPropagationEditListColumns({ ID })}
                      disableChildrenSorting
                      disableColumnMenu
                      disableColumnPinning
                      disableColumnResize
                      disableColumnReorder
                      disableRowSelectionOnClick
                      disableRowHoverStates
                      getRowId={row => row.code}
                      rows={appCanonicalPurposes || []}
                      hideFooter
                      noRowsOverlayProps={{
                        buttonTitle: '',
                      }}
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%" m={6}>
                <Spinner size="32px" thickness={2.5} />
              </Box>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
