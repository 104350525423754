import { Box, Typography, styled } from '@mui/material'
import { RichTextRenderer } from '../../../../../../RichTextRenderer'
import { useContext } from 'react'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { clamp } from 'lodash'
import { BUILDER_CONSTANTS } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'

const RequestsFormActionButtonBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginTop: '40px',
})

const ActionButtonBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 24px',
  width: 'fit-content',
})

const reCaptchaNotice =
  '<p>This site is protected by reCAPTCHA and the Google <u>Privacy Policy</u> and <u>Terms of Service</u> apply.</p>'

export const RequestsFormActionButton: React.FC = () => {
  const { themeConfig, experienceConfig } = useContext(BuilderContext)

  // Theme config
  const buttonBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormActionButtonBackground)
  const buttonTextColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormActionButtonTextColor)

  const actionButtonCornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormActionButtonCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'

  // Experience config
  const buttonText = getIn(experienceConfig, experienceUpsertFormKeys.preferenceRequestsTabRightFormActionButtonText)
  const showReCaptchaNotice = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceRequestsTabRightFormEnableRecaptcha,
  )

  return (
    <RequestsFormActionButtonBox>
      <ActionButtonBox sx={{ background: buttonBackgroundColor, borderRadius: actionButtonCornerRadius }}>
        <Typography
          fontSize={'14px'}
          fontWeight={600}
          color={buttonTextColor}
          sx={{
            maxWidth: '236px',
            wordWrap: 'break-word',
          }}
        >
          {buttonText}
        </Typography>
      </ActionButtonBox>
      {showReCaptchaNotice && <RichTextRenderer text={reCaptchaNotice} />}
    </RequestsFormActionButtonBox>
  )
}
