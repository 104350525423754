import React from 'react'

// api
import { useOrchestrationEventsPaginated } from 'api/orchestrationEventsV2/queries/useOrchestrationEventsPaginated'
import { useWorkflows } from 'api/workflows/queries/useWorkflows'

// components
import { EventsList } from 'pages/orchestration/events/listV2/EventsList'

// dux
import { useAppSelector } from 'store/hooks'
import { getEventsListFilterState } from 'store/eventsListFilterSlice/selectors'

export const EventsListV2Container: React.FC = () => {
  const { dataRole, dataSubjectTypeCode, rightCode, rightType, searchQuery } = useAppSelector(getEventsListFilterState)

  // events
  const {
    data: events,
    isLoading: isEventsListLoading,
    pagination,
  } = useOrchestrationEventsPaginated({
    params: {
      dataRole,
      rightType,
      rightCode,
      query: searchQuery,
      dataSubjectTypeCode,
    },
  })

  // workflows
  const { data: workflows, isLoading: isWorkflowsListLoading } = useWorkflows()

  return (
    <EventsList
      isReady={!isEventsListLoading && !isWorkflowsListLoading}
      events={events}
      workflows={workflows}
      isLoading={isEventsListLoading || isWorkflowsListLoading}
      pagination={pagination}
    />
  )
}
