import React, { forwardRef } from 'react'
import { find, isEmpty } from 'lodash'
import { useSetState } from 'react-use'

import { WebTagDTO } from 'interfaces/webtags/WebTags'
import { useWebTag } from 'api/webtags/queries/useWebTag'
import { useUpdateWebTagPurposes } from 'api/webtags/mutations/useUpdateWebTagPurposes'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { matchSearch } from 'utils/helpers/matchSearch'
import { showToast } from 'components/modals/AlertComponent'
import { TransferList } from 'components/transferList/TransferList'
import { Button, ListItem, PopUp } from '@ketch-com/deck'
import { Typography, Box } from '@mui/material'
import Highlighter from 'react-highlight-words'
import { TreeItemContentProps } from '@mui/x-tree-view'

type Props = {
  webTagId: string
  onSubmit?: () => void
  onCancel: () => void
}

type State = {
  isBusy: boolean
  mappedPurposes: WebTagDTO['purposes']
  mappedPurposesSearchString: string
  availablePurposesSearchString: string
}

export const TransferListItemContentComponent = forwardRef(function CustomContent(
  { item, onItemClick, type, searchString }: TreeItemContentProps,
  ref: React.Ref<HTMLLIElement & HTMLDivElement>,
) {
  const purpose = item as WebTagDTO
  return (
    <Box onClick={onItemClick}>
      <ListItem
        sx={{
          maxHeight: 'unset',
        }}
        ref={ref}
        tabIndex={0}
        onExpandableActionIconClick={onItemClick}
        isExpandableAction
        expandableActionLevelItem="one"
        expandableActionIcon={type === 'INCLUDE' ? 'FMinusRound' : 'FPlusRound'}
        startElement={
          <Box display="flex" flexDirection="column">
            <Highlighter searchWords={searchString?.split(' ') || []} textToHighlight={purpose.name} />
            <Typography variant="smallFootnote">{purpose.code}</Typography>
          </Box>
        }
      />
    </Box>
  )
})

export const MapPurposesToWebTagModal: React.FC<Props> = ({ webTagId, onSubmit, onCancel }) => {
  const [state, setState] = useSetState<State>({
    isBusy: false,
    mappedPurposes: [],
    mappedPurposesSearchString: '',
    availablePurposesSearchString: '',
  })

  const { isLoading: isWebTagLoading, isFetching: isWebTagFetching } = useWebTag({
    enabled: !!webTagId,
    params: {
      webTagId,
    },
    onSuccess: ({ data }) => {
      setState({ mappedPurposes: data?.purposes || [] })
    },
    onError: () => {
      showToast({ content: 'Failed to fetch tag', type: 'error' })
      onCancel()
    },
  })

  const {
    data: purposesList,
    isLoading: isPurposesListLoading,
    isFetching: isPurposesListFetching,
  } = usePurposes({
    onError: () => {
      showToast({ content: 'Failed to fetch purposes', type: 'error' })
      onCancel()
    },
  })

  const { mutateAsync: handleUpdateWebTag } = useUpdateWebTagPurposes({
    onSuccess: () => {
      showToast({ content: 'Purposes mapping updated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update purposes mapping', type: 'error' })
    },
  })

  const handleSubmit = async () => {
    setState({ isBusy: true })

    await handleUpdateWebTag({
      params: {
        webTagId,
        formData: {
          tagID: webTagId,
          purposeIDs: state.mappedPurposes.map(({ ID }) => ID) || [],
        },
      },
    })
    await onSubmit?.()
  }

  const mappedPurposesList =
    state.mappedPurposes?.filter(purpose => matchSearch(purpose.name, state.mappedPurposesSearchString)) || []

  const availablePurposesList =
    purposesList?.filter(
      purpose =>
        purpose.enabled &&
        isEmpty(find(state.mappedPurposes, { code: purpose.code })) &&
        matchSearch(purpose.name, state.availablePurposesSearchString),
    ) || []

  return (
    <PopUp
      variant="modal"
      isLoading={isWebTagLoading || isWebTagFetching || isPurposesListLoading || isPurposesListFetching}
      title="Manage Purposes Dependencies"
      popUpWidth="950px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="primary" size="large" pending={state.isBusy} onClick={handleSubmit}>
            Apply
          </Button>
        </>
      }
    >
      <TransferList
        excludedTitle="All Purposes"
        excludedItems={availablePurposesList}
        excludedItemsSearchString={state.availablePurposesSearchString}
        includedTitle="Selected Purposes"
        includedItems={mappedPurposesList}
        includedItemsSearchString={state.mappedPurposesSearchString}
        onChange={nextIncludedItems => setState({ mappedPurposes: nextIncludedItems })}
        onSearchIncludedItems={nextSearchString => setState({ mappedPurposesSearchString: nextSearchString })}
        onSearchExcludedItems={nextSearchString => setState({ availablePurposesSearchString: nextSearchString })}
        ContentComponent={TransferListItemContentComponent}
      />
    </PopUp>
  )
}
