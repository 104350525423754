import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  identitySpaceCode: string
  organizationCode: string
}

export const deleteIdentitySpace = ({ organizationCode, identitySpaceCode }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/identity-spaces/${identitySpaceCode}`,
    }),
  )
