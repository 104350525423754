import {
  GetUnapprovedTrackersResponseBody,
  GetUnapprovedTrackersParams,
} from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchUnapprovedTrackers = ({ nextToken, limit, searchString }: GetUnapprovedTrackersParams) => {
  return API.get<GetUnapprovedTrackersResponseBody>(
    formatRequestString({
      entityUrl: '/api/privacy-configuration/trackers/unapproved',
      params: {
        filters: {
          ...(nextToken && {
            nextToken,
          }),
          ...(limit && {
            limit,
          }),
          ...(searchString && {
            searchString,
          }),
        },
      },
    }),
  )
}
