import { useState } from 'react'
import { useDebounce } from 'react-use'
import { useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useCreateRelationshipV2 } from 'api/assets/mutations/useCreateRelationshipV2'
import { useQueryParams } from 'utils/hooks/useQueryParams'

import { CreateRelationshipInitialValues } from '../components/CreateRelationshipModal/utils/getInitialValues'
import { mapFormValuesToFormData } from '../components/CreateRelationshipModal/utils'
import { ApiQueryKeys } from 'api/common/queryKeys'

export const useRelationshipsViewUtils = () => {
  const [searchString, setSearchString] = useState('')
  const { setQueryParam, removeQueryParam, queries } = useQueryParams<{ search?: string }>()
  const [isCreateRelationshipModalOpen, setIsCreateRelationshipModalOpen] = useState(false)
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()

  const { mutateAsync: handleCreateRelationship } = useCreateRelationshipV2({
    onSuccess: async ({ data }) => {
      await queryClient.refetchQueries([ApiQueryKeys.relationshipsInfinite, { type: 2 }])
      showToast({ content: 'Relationship created', type: 'success' })
    },
    onError: () => showToast({ content: 'Failed to create relationship', type: 'error' }),
  })

  useDebounce(
    () => {
      if (searchString && searchString !== searchParams.get('search')) {
        setQueryParam('search', searchString)
      }

      if (!searchString) {
        removeQueryParam('search')
      }
    },
    500,
    [searchString],
  )

  const handleSubmit = async (values: CreateRelationshipInitialValues) => {
    const formData = mapFormValuesToFormData(values)

    await handleCreateRelationship({
      onSuccess: () => {
        setIsCreateRelationshipModalOpen(false)
        showToast({ content: 'Relationship created', type: 'success' })
      },
      params: {
        formData,
      },
    })
  }

  const tabs = [
    {
      title: 'Created',
      link: {
        pathname: RoutesManager.assetManager.relationships.list.created.getURL(),
        search: queries.search,
      },
    },
    {
      title: 'Discovered',
      link: {
        pathname: RoutesManager.assetManager.relationships.list.discovered.getURL(),
        search: queries.search,
      },
    },
  ]

  return {
    isCreateRelationshipModalOpen,
    setIsCreateRelationshipModalOpen,
    handleSubmit,
    tabs,
    searchString,
    setSearchString,
  }
}
