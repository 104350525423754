import React, { useContext } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FieldArray, useFormikContext, ErrorMessage } from 'formik'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { PreferenceField } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import { DropListButton, TextInput, Button, Icon, IconDictionary, theme } from '@ketch-com/deck'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormMode } from 'interfaces/formModes/formMode'
import { useSubscriptionTopics } from '../hooks'

const SelectSubscriptionTopic = styled(Box)(({ theme }) => ({
  borderRadius: 11,
  backgroundColor: theme.palette.sphereFaded.main,
  padding: theme.spacing(1.5),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const SelectedSubscriptionTopic = styled(Box)(({ theme }) => ({
  borderRadius: 11,
  backgroundColor: theme.palette.fadedGrey.main,
  padding: theme.spacing(1.5),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1.5),
}))

const CreateSubscriptionTopic = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.iron.main}`,
  padding: theme.spacing(4, 0),
  margin: theme.spacing(4, 0, 0),
}))

type Props = {}

export const SubscriptionTopicsList: React.FC<Props> = () => {
  const {
    isSubscriptionTopicsLoading,
    subscriptionTopics,
    setSubscriptionsTopicInputValue,
    selectedSubscriptionsTopic,
    setSelectedSubscriptionsTopic,
  } = useSubscriptionTopics()

  const { sidebarActiveTab } = useContext(ExperienceUpsertContext)
  const { values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext<PreferenceExperienceFormValues>()

  if (sidebarActiveTab !== PreferenceField.SUBSCRIPTIONS_TOPICS) return null

  return (
    <Box>
      <Box>
        <ErrorMessage name="subscriptions.topicItems">
          {msg => {
            if (Array.isArray(msg)) {
              const errorMessage = msg.filter(element => element)?.[0]?.name || ''
              if (errorMessage)
                return (
                  <Box mb={2}>
                    <Typography variant="body" color="chileanFire.main">
                      {errorMessage}
                    </Typography>
                  </Box>
                )
            }
          }}
        </ErrorMessage>
        <FieldArray
          name="subscriptions.topicItems"
          render={arrayHelpers => {
            return (
              <Box>
                <Box>
                  <DragDropContext
                    onDragEnd={(result: DropResult) => {
                      if (!result.destination) return

                      const startIndex = result.source.index
                      const endIndex = result.destination.index

                      const newOrder = Array.from(values?.subscriptions?.topicItems || [])
                      const [removed] = newOrder.splice(startIndex, 1)
                      newOrder.splice(endIndex, 0, removed)

                      setFieldValue('subscriptions.topicItems', newOrder)
                    }}
                  >
                    <Droppable droppableId="droppable">
                      {provided => {
                        const { innerRef, droppableProps } = provided
                        return (
                          <Box {...droppableProps} ref={innerRef}>
                            {values?.subscriptions?.topicItems?.map((topic, topicIndex) => {
                              const isDragDisabled = values?.subscriptions?.topicItems.length === 1

                              return (
                                <Draggable
                                  key={topic?.code}
                                  draggableId={topic?.code}
                                  index={topicIndex}
                                  isDragDisabled={isDragDisabled}
                                >
                                  {provided => {
                                    return (
                                      <SelectedSubscriptionTopic
                                        sx={{
                                          backgroundColor: ({ palette }) =>
                                            !(topic?.name && topic?.code) ? palette.Error.PrimaryLight : null,
                                        }}
                                        ref={provided.innerRef}
                                        key={topic?.code}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <Box display="flex" alignItems="center" gap={1}>
                                          <Icon
                                            name={IconDictionary.ODragHandle}
                                            iconColor={theme.palette.fadedDarkGrey.main}
                                          />
                                          <Typography variant="label">
                                            {topic?.name && topic?.code
                                              ? topic?.name
                                              : `Subscription Deleted (code: ${topic?.code})`}
                                          </Typography>
                                        </Box>
                                        <Button
                                          variant="icon"
                                          color="white"
                                          onClick={() => {
                                            arrayHelpers.remove(topicIndex)
                                          }}
                                        >
                                          <Icon name={IconDictionary.FBin} width={16} height={16} />
                                        </Button>
                                      </SelectedSubscriptionTopic>
                                    )
                                  }}
                                </Draggable>
                              )
                            })}

                            {provided.placeholder}
                          </Box>
                        )
                      }}
                    </Droppable>
                  </DragDropContext>

                  <SelectSubscriptionTopic>
                    <DropListButton
                      value={selectedSubscriptionsTopic}
                      size="small"
                      sx={{ width: 200 }}
                      loading={isSubscriptionTopicsLoading}
                      filterOptions={x => x}
                      clearOnBlur
                      noOptionsText="No Subscriptions"
                      getOptionDisabled={option =>
                        values?.subscriptions?.topicItems?.some(topic => topic?.code === option?.code)
                      }
                      onChange={(e, topic, reason) => {
                        if (reason === 'clear') {
                          setSelectedSubscriptionsTopic(null)
                        } else {
                          setSelectedSubscriptionsTopic(topic)
                        }
                      }}
                      onInputChange={(e, inputVal) => {
                        setSubscriptionsTopicInputValue(inputVal)
                      }}
                      options={subscriptionTopics?.topics || []}
                      renderInput={params => (
                        <TextInput
                          {...params}
                          error={touched?.subscriptions?.topicItems && Boolean(errors?.subscriptions?.topicItems)}
                          errorMessage={
                            typeof errors?.subscriptions?.topicItems === 'string'
                              ? errors?.subscriptions?.topicItems
                              : ''
                          }
                          onBlur={() => {
                            setFieldTouched('subscriptions.topicItems', true)
                          }}
                          InputProps={{
                            ...params.InputProps,
                          }}
                          placeholder="Select Subscriptions"
                          variant="outlined"
                        />
                      )}
                      renderOption={(props, topic) => {
                        return (
                          <Box
                            component="li"
                            {...props}
                            key={topic?.code}
                            display="flex"
                            flexDirection="column"
                            gap={0.5}
                            py={2}
                          >
                            <Typography alignSelf="flex-start" variant="body">
                              {topic?.name}
                            </Typography>
                            <Typography alignSelf="flex-start" variant="footnote" color="darkDuskFaded.main">
                              {topic?.code}
                            </Typography>
                          </Box>
                        )
                      }}
                      getOptionLabel={option => option?.name || ''}
                      isOptionEqualToValue={(option, value) => option?.code === value?.code}
                    />
                    <Button
                      color="secondary"
                      disabled={!selectedSubscriptionsTopic}
                      onClick={() => {
                        arrayHelpers.push({
                          name: selectedSubscriptionsTopic?.name,
                          code: selectedSubscriptionsTopic?.code,
                          type: 1,
                        })
                        setSubscriptionsTopicInputValue('')
                        setSelectedSubscriptionsTopic(null)
                      }}
                    >
                      Add
                    </Button>
                  </SelectSubscriptionTopic>
                </Box>

                <CreateSubscriptionTopic>
                  <Typography variant="body">Haven’t created any subscription yet?</Typography>
                  <Box>
                    <Button
                      endIcon={<Icon name={IconDictionary.OShare} iconColor={theme.palette.sphere.main} />}
                      color="tertiary"
                      onClick={() => {
                        window.open(
                          RoutesManager.policyCenter.subscriptions.upsert.subscriptionTopic.root.getURL({
                            code: 'new',
                            formMode: FormMode.CREATE,
                          }),
                          '_blank',
                          'noreferrer',
                        )
                      }}
                    >
                      Create Subscription
                    </Button>
                  </Box>
                </CreateSubscriptionTopic>
              </Box>
            )
          }}
        />
      </Box>
    </Box>
  )
}
