import { RoutesManager } from 'utils/routing/routesManager'
import { Button, Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { FormTemplateExperienceDTO, FormTemplateExperienceStatusDTO } from '@ketch-com/figurehead'
import { AssociatedRightsCellRenderer } from './components'
import { Link } from 'react-router-dom'

export const getDataGridColumns = ({ isExpV1 }: { isExpV1: boolean }): GridColDef<FormTemplateExperienceDTO>[] => [
  {
    align: 'left',
    field: 'experience',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Experience',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<FormTemplateExperienceDTO>) => {
      return <TableCell title={row.name} subTitle={row.code} />
    },
  },
  {
    align: 'left',
    field: 'associatedRights',
    flex: 0.5,
    headerAlign: 'left',
    headerName: 'Associated Rights',
    sortable: false,
    renderCell: ({ row: experience }: GridRenderCellParams<FormTemplateExperienceDTO>) => {
      return (
        <TableCell>
          <AssociatedRightsCellRenderer experience={experience} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'status',
    flex: 0.6,
    headerAlign: 'left',
    headerName: 'Deployment Status',
    sortable: false,
    renderCell: ({ row: { status } }: GridRenderCellParams<FormTemplateExperienceDTO>) => {
      return (
        <TableCell>
          {status === FormTemplateExperienceStatusDTO.DeployedFormTemplateExperienceStatus ? (
            <Status icon="Dot" variant={StatusVariant.ghost} status={StatusState.success} label="Deployed" />
          ) : (
            <Status icon="Dot" variant={StatusVariant.ghost} status={StatusState.draft} label="Undeployed" />
          )}
        </TableCell>
      )
    },
  },
  {
    align: 'right',
    field: 'actions',
    flex: 1,
    headerAlign: 'left',
    headerName: ' ',
    sortable: false,
    maxWidth: 200,
    renderCell: ({ row: experience }: GridRenderCellParams<FormTemplateExperienceDTO>) => {
      const targetUrl = isExpV1
        ? RoutesManager.deployment.experiences.view.root.getURL({
            code: experience.code,
          })
        : RoutesManager.deployment.experiencesV2.view.preview.root.getURL({
            id: experience?.id,
          })
      return (
        <TableCell>
          <Link to={targetUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Button color="tertiary">Open In New Tab</Button>
          </Link>
        </TableCell>
      )
    },
  },
]
