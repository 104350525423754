import React from 'react'
import { Box, Typography } from '@mui/material'
import { Icon, IconDictionary, Button, theme } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { PropertySummary, UnapprovedTracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { useUpsertTrackerPropertyDisclosure } from 'api/trackers/mutations/useUpsertTrackerPropertyDisclosure'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useUpsertTrackerPropertyBlocking } from 'api/trackers/mutations/useUpsertTrackerPropertyBlocking'
import { useFeatureFlag } from 'utils/hooks'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type Props = {
  tracker: UnapprovedTracker
  trackerPropertyItem: PropertySummary
  trackerPropertyItemIndex: number
}

export const PropertyDisclosureAndBlocking: React.FC<Props> = ({
  tracker = {} as UnapprovedTracker,
  trackerPropertyItem,
  trackerPropertyItemIndex,
}) => {
  const queryClient = useQueryClient()
  const trackerPropertiesItems = tracker?.properties
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const { isFeatureFlagEnabled } = useFeatureFlag()

  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const isTrackerBlockingRelease =
    getIsPermitted([PERMISSIONS.DEMO]) ||
    isFeatureFlagEnabled(
      FEATURE_FLAGS.PROPERTY_TAGS_TRACKER_BLOCKING,
      FEATURE_FLAGS_VALUES.PROPERTY_TAGS_TRACKER_BLOCKING_ENABLED,
    )

  const {
    mutateAsync: upsertUnapprovedTrackerPropertyDisclosure,
    isLoading: isUpdatingUnapprovedTrackerPropertyDisclosure,
  } = useUpsertTrackerPropertyDisclosure({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.unapprovedTrackersInfinite])
      showToast({ content: 'Updated disclosure', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update disclosure', type: 'error' })
    },
  })

  const {
    mutateAsync: upsertUnapprovedTrackerPropertyBlocking,
    isLoading: isUpdatingUnapprovedTrackerPropertyBlocking,
  } = useUpsertTrackerPropertyBlocking({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.unapprovedTrackersInfinite])
      showToast({ content: 'Updated blocking status', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update blocking status', type: 'error' })
    },
  })

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderBottom: ({ palette }) =>
          trackerPropertiesItems?.length - 1 !== trackerPropertyItemIndex ? `1px solid ${palette.iron.main}` : 'none',
        paddingBottom: ({ spacing }) =>
          trackerPropertiesItems?.length - 1 !== trackerPropertyItemIndex ? spacing(1.5) : 0,
        marginBottom: ({ spacing }) =>
          trackerPropertiesItems?.length - 1 !== trackerPropertyItemIndex ? spacing(1.5) : 0,
      }}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="label">{trackerPropertyItem?.name}</Typography>
        <Typography variant="smallBody" color="darkDuskFaded.main">
          {trackerPropertyItem?.type}
        </Typography>
      </Box>
      <Box display="flex" gap={1}>
        {/* Disclosure button */}
        <Button
          sx={{
            width: 32,
            height: 32,
            borderRadius: '100%',
            padding: 0.25,
          }}
          disabled={!isPermittedToWriteCookie || isUpdatingUnapprovedTrackerPropertyDisclosure}
          onClick={() => {
            upsertUnapprovedTrackerPropertyDisclosure({
              params: {
                data: {
                  isDisclosed: trackerPropertyItem?.isDisclosed ? false : true,
                },
                encodedTrackerKey: tracker?.encodedKey || '',
                propertyId: trackerPropertyItem?.id || '',
              },
            })
          }}
          variant={trackerPropertyItem?.isDisclosed ? 'contained' : 'iconCustomRounded'}
          color={trackerPropertyItem?.isDisclosed ? 'primary' : 'tertiary'}
          children={
            trackerPropertyItem?.isDisclosed ? (
              <Icon name={IconDictionary.OEyeShow} iconColor={theme.palette.white.main} />
            ) : (
              <Icon name={IconDictionary.OEyeHide} />
            )
          }
        />

        {/* Blocking button */}
        {isTrackerBlockingRelease && (
          <Button
            sx={{
              width: 32,
              height: 32,
              borderRadius: '100%',
              padding: 0.25,
            }}
            disabled={!isPermittedToWriteCookie || isUpdatingUnapprovedTrackerPropertyBlocking}
            onClick={() => {
              upsertUnapprovedTrackerPropertyBlocking({
                params: {
                  data: {
                    isBlocked: !trackerPropertyItem.isBlocked,
                  },
                  encodedTrackerKey: tracker?.encodedKey || '',
                  propertyId: trackerPropertyItem?.id || '',
                },
              })
            }}
            variant={trackerPropertyItem?.isBlocked ? 'iconCustomRounded' : 'contained'}
            color={trackerPropertyItem?.isBlocked ? 'tertiary' : 'primary'}
            children={
              trackerPropertyItem?.isBlocked ? (
                <Icon
                  name={IconDictionary.ODecline}
                  iconColor={trackerPropertyItem?.isBlocked ? theme.palette.Black.o100 : theme.palette.white.main}
                />
              ) : (
                <Icon name={IconDictionary.OCheckRound2} />
              )
            }
          />
        )}
      </Box>
    </Box>
  )
}
