import React from 'react'
import { FieldArray, Formik } from 'formik'
import Box from '@mui/material/Box'
import { makeStyles, createStyles } from '@mui/styles'
import clsx from 'clsx'

import { Modal } from 'components/ui-kit/modal/Modal'
import { getInitialValues, getValidationSchema } from './utils'
import { Table } from 'components/ui-layouts/table/Table'
import { Button } from 'components/ui-kit/button/Button'
import {
  DatasetInputCellRenderer,
  ForeignKeyInputCellRenderer,
  PrimaryKeyInputCellRenderer,
  ReferenceDatasetInputCellRenderer,
} from './components'
import { CreateRelationshipInitialValues } from './utils/getInitialValues'
import { Text } from 'components/ui-kit/typography/Text'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      removeCell: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingTop: 0,
      },
      addSection: {
        paddingTop: 18,
        borderTop: `1px solid ${palette.iron.main}`,
      },
      borderBottom: {
        borderBottom: `1px solid ${palette.iron.main}`,
      },
      datasetInputCellRenderer: {
        width: '100%',
        display: 'block',
      },
      withMarginTop: {
        marginTop: spacing(2),
      },
    }),
  { name: 'CreateLabelFormBasicDetails' },
)

type Props = {
  isUpdatingAssetLabels?: boolean
  onSubmit?: (values: CreateRelationshipInitialValues) => void
  onCancel: () => void
}

export const CreateRelationshipModal: React.FC<Props> = ({
  onSubmit = () => {},
  onCancel,
  isUpdatingAssetLabels = false,
}) => {
  const classes = useStyles()
  /* "edit" functionality is out of scope for now, so, "getInitialValues" will take no args */
  const initialValues = getInitialValues()
  const validationSchema = getValidationSchema()

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, values, isSubmitting, handleSubmit }) => {
        return (
          <Modal
            cancelBtnLabel="Cancel"
            contentWidth={938}
            isLoading={false}
            isRemoveMaxHeight
            isSaving={isSubmitting}
            isValid={isValid}
            onCancelBtnClick={onCancel}
            onSubmitBtnClick={handleSubmit}
            submitBtnLabel="Create"
            subtitle="Define which attributes act like a Foreign Key and map them to a Primary Key attributes."
            title="Create a Relation"
          >
            <Table
              className={clsx(classes.borderBottom, classes.withMarginTop)}
              items={[{ foo: 'bar' }]} // there will only ever be one row, and the contents are not dynamic, so, hard-coding foobar
              cellSettings={{
                dataset: {
                  labelNodeRenderer: () => (
                    <Text size={12} weight={600}>
                      Dataset
                    </Text>
                  ),
                  className: classes.datasetInputCellRenderer,
                  cellRenderer: () => <DatasetInputCellRenderer placeholder="Search..." />,
                },
                referenceDataset: {
                  labelNodeRenderer: () => (
                    <Text size={12} weight={600} color="heliotrope">
                      Reference Dataset
                    </Text>
                  ),
                  className: classes.datasetInputCellRenderer,
                  cellRenderer: () => <ReferenceDatasetInputCellRenderer placeholder="Search..." />,
                },
                removeButton: {
                  className: classes.removeCell,
                  width: 150,
                  cellRenderer: () => ' ',
                },
              }}
            />
            <Box sx={{ py: 1.375 }} />

            <FieldArray name="relations">
              {({ push, remove, replace }) => (
                <>
                  <Table
                    className={!!values?.relations?.length ? classes.borderBottom : undefined}
                    items={values?.relations || []}
                    cellSettings={{
                      foreignKeys: {
                        labelNodeRenderer: () => (
                          <Text size={12} weight={600}>
                            Foreign Key
                          </Text>
                        ),
                        className: classes.datasetInputCellRenderer,
                        cellRenderer: (_, rowIndex) => (
                          <ForeignKeyInputCellRenderer placeholder="Search..." rowIndex={rowIndex} replace={replace} />
                        ),
                      },
                      primaryKeys: {
                        className: classes.datasetInputCellRenderer,
                        labelNodeRenderer: () => (
                          <Text size={12} weight={600} color="heliotrope">
                            Primary Key
                          </Text>
                        ),
                        cellRenderer: (_, rowIndex) => (
                          <PrimaryKeyInputCellRenderer placeholder="Search..." rowIndex={rowIndex} replace={replace} />
                        ),
                      },
                      removeButton: {
                        className: classes.removeCell,
                        width: 150,
                        cellRenderer: (_, rowIndex) => (
                          <Button
                            variant="tertiary"
                            disabled={rowIndex === 0}
                            tooltip={rowIndex === 0 ? 'There must be at lest one foreign/primary key pair' : undefined}
                            onClick={() => {
                              remove(rowIndex)
                            }}
                          >
                            Remove
                          </Button>
                        ),
                      },
                    }}
                  />
                  <Box mt={3} pb={6} pl={3}>
                    <Button
                      shouldRemoveMinWidth
                      variant="secondary"
                      onClick={() => {
                        push({
                          foreignKey: '',
                          primaryKey: '',
                        })
                      }}
                    >
                      Add
                    </Button>
                  </Box>
                </>
              )}
            </FieldArray>
          </Modal>
        )
      }}
    </Formik>
  )
}
