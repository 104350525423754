import * as Yup from 'yup'
import { GetAssetHierarchiesResponseBodyDTO } from '@ketch-com/figurehead'
import { numberTypes } from 'pages/assetManager/dsrConfig/constants'

type Params = {
  primaryDatasetId: string
  assetsHierarchies: GetAssetHierarchiesResponseBodyDTO[]
}

export const getDsrConfigFormValidationSchema = ({ primaryDatasetId, assetsHierarchies }: Params) => {
  return Yup.lazy(values => {
    const { identitySpace, relatedDatasets, actions } = values
    const identitySpaceKeys = Object.keys(identitySpace)
    const identitySpaceFields = identitySpaceKeys.reduce((obj, key) => {
      obj[key] = Yup.object().shape({
        checked: Yup.boolean(),
      })

      return obj
    }, {} as any)

    const relatedDatasetsKeys = Object.keys(relatedDatasets)
    const includedDatasetIds = relatedDatasetsKeys.filter(
      relatedDatasetId => relatedDatasets[relatedDatasetId].included,
    )
    includedDatasetIds.push(primaryDatasetId)
    const actionsKeys = Object.keys(actions)
    const actionFields = actionsKeys.reduce((actionObj, actionKey) => {
      const selectedAttributes = assetsHierarchies.filter(
        assetHierarchies =>
          assetHierarchies?.hierarchySummaries?.some(
            assetSummary => assetSummary.asset?.resource?.hierarchies?.$parent?.id === actionKey,
          ),
      )?.[0]
      const attributes = actions[actionKey]
      const attributesKey = Object.keys(attributes)
      const typedAttributes = attributesKey.reduce((obj, key) => {
        const selectedAttributeSummary = selectedAttributes?.hierarchySummaries?.filter(
          hierarchySummary => hierarchySummary?.asset?.resource?.id === key,
        )[0]
        const selectedAttributeType = selectedAttributeSummary?.asset?.resource?.dataType?.name || ''
        const isNumberType = numberTypes.find(type => type === selectedAttributeType)

        if (isNumberType) {
          obj[key] = Yup.object().shape({
            command: Yup.number(),
            updateValue: Yup.number().when('command', {
              is: 6,
              then: schema => schema.typeError('Number Required').required('Number Required'),
            }),
          })
        } else {
          // String Type
          obj[key] = Yup.object().shape({
            command: Yup.number(),
            updateValue: Yup.string().when('command', {
              is: 6,
              then: schema => schema.required('String Required'),
            }),
          })
        }
        return obj
      }, {} as any)

      actionObj[actionKey] = Yup.object().shape(typedAttributes)
      return actionObj
    }, {} as any)
    return Yup.object().shape({
      identitySpace: Yup.object()
        .shape(identitySpaceFields)
        .test({
          name: 'atLeastOneRequired',
          test: (parent, { createError }) => {
            const isValid = identitySpaceKeys.some(field => Boolean(parent[field].checked))
            if (isValid) return true
            return createError({
              path: 'identitySpace',
              message: 'Please select at least one identity lookup path.',
            })
          },
        }),
      actions: Yup.object()
        .shape(actionFields)
        .test({
          name: 'configureAllSelectedTables',
          test: (parent, { createError }) => {
            const isValid = includedDatasetIds.every(includedDatasetId => {
              const attributes = parent[includedDatasetId] || {}
              const attributeIds = Object.keys(attributes)
              return attributeIds.some(attributeId => attributes?.[attributeId]?.command !== -1)
            })

            if (isValid) return true
            return createError({
              path: 'actions',
              message:
                includedDatasetIds?.length > 1
                  ? 'Please configure at least one column for each of the included tables or exclude them from the related tables list.'
                  : 'Please configure at least one column of the table.',
            })
          },
        }),
    })
  })
}
