import { MaybeNull } from 'interfaces/common'
import { ControllerDTO, ControllerFormDTO } from 'interfaces/controllers/controller'

type Params = {
  controller: MaybeNull<ControllerDTO>
}

export const getControllerFormValues = (params: Params): ControllerFormDTO => ({
  name: params?.controller?.name || '',
  code: params?.controller?.code || '',
})
