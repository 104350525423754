import * as Yup from 'yup'

export const getUserFormValidation = ({ isEditMode }: { isEditMode?: boolean }) => {
  const validation = {
    email: Yup.string().email('Incorrect format').required('Required').max(254, 'Email may not exceed 254 characters'),
    firstName: Yup.string(),
    lastName: Yup.string(),
    roles: Yup.object()
      .shape({
        // This ensures that the object keys can be any string and values must be strings that are not undefined
      })
      .test('values-check', 'All values must be defined strings', value => {
        if (typeof value !== 'object' || value === null) return false
        return Object.values(value).every(v => typeof v === 'string' && v !== undefined)
      }),
  }

  if (isEditMode) {
    validation.firstName = Yup.string().required('Required')
    validation.lastName = Yup.string().required('Required')
  }

  return Yup.object().shape(validation)
}
