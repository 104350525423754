import { FormFieldConfig } from 'components/ui-layouts/createLayoutV2/CreateLayoutV2'
import { FormFieldFormStep } from '../constants/getFormFieldFromSteps'
import { translationsValidation, formFieldTypeValidation, useBasicDetailsValidation } from '../utils/validation'

/**
 * Generates a custom form field configuration based on the provided form field steps.
 *
 * @param {Object} params - The input parameters for the function.
 * @param {boolean} [params.isEditMode=false] - Determines if the form is in edit mode.
 * @param {FormFieldFormStep[]} params.createFormFieldSteps - An array of form field steps used to generate the form field configuration.
 * @returns {FormFieldConfig[]} - An array of form field configuration objects.
 */
export const useCustomFormFieldConfig = ({
  isEditMode = false,
  createFormFieldSteps,
}: {
  isEditMode?: boolean
  createFormFieldSteps: FormFieldFormStep[]
}): FormFieldConfig[] => {
  const stepOneValidation = useBasicDetailsValidation({ isEditMode })
  const payload: FormFieldConfig[] = [
    {
      title: createFormFieldSteps?.[0],
      validationSchema: stepOneValidation,
    },
    {
      title: createFormFieldSteps?.[1],
      validationSchema: translationsValidation,
    },
    {
      title: createFormFieldSteps?.[2],
      validationSchema: formFieldTypeValidation,
    },
  ]

  return payload
}
