import { FormColorInput } from '../FormColorInput'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import FormControlLabel from '@mui/material/FormControlLabel'
import { FormCheckbox } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/FormCheckbox'
import { Typography } from '@mui/material'
import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'

export const ModalThemeDescriptionForm: React.FC = () => {
  return (
    <>
      <SidebarFormGroup sx={{ pt: 0 }}>
        <FormColorInput
          name={themeUpsertFormKeys.modalDescriptionTitleColor}
          label={'Title Color'}
          fullWidth
          required
        />
        <FormColorInput name={themeUpsertFormKeys.modalDescriptionTextColor} label={'Text Color'} fullWidth required />
        <FormColorInput name={themeUpsertFormKeys.modalDescriptionLinkColor} label={'Link Color'} fullWidth required />
        <FormControlLabel
          control={<FormCheckbox name={themeUpsertFormKeys.modalDescriptionUnderlineLinks} />}
          label={<Typography variant={'label'}>Underline Links</Typography>}
        />
      </SidebarFormGroup>
    </>
  )
}
