import { APIListRequestParams } from 'api/common/utils'
import { useClassificationsInfiniteV2 } from './useClassificationsInfiniteV2'
import { useClassificationsInfiniteV3 } from './useClassificationsInfiniteV3'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type Params = APIListRequestParams<{
  includeSystemLabels?: boolean
}>

export const useClassificationsV2OrV3BasedOnFeatureFlag = ({ params }: { params: Params }) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )

  /* V2 */

  const {
    data: classificationsDataV2,
    isLoading: isLoadingV2,
    hasNextPage: hasNextPageV2,
    fetchNextPage: fetchNextPageV2,
    isFetchingNextPage: isFetchingNextPageV2,
    totalResults: totalResultsV2,
  } = useClassificationsInfiniteV2({
    params: {
      ...params,
    },
    options: {
      enabled: isV2FeatureFlagEnabled,
    },
  })

  const payloadV2 = {
    data: classificationsDataV2 || [],
    isLoading: isLoadingV2,
    hasNextPage: hasNextPageV2,
    fetchNextPage: fetchNextPageV2,
    isFetchingNextPage: isFetchingNextPageV2,
    totalResults: totalResultsV2,
  }

  /* V3 */

  const {
    classificationsData: classificationsDataV3,
    isLoading: isLoadingV3,
    hasNextPage: hasNextPageV3,
    fetchNextPage: fetchNextPageV3,
    isFetchingNextPage: isFetchingNextPageV3,
    totalResults: totalResultsV3,
  } = useClassificationsInfiniteV3({
    params: {
      ...params,
    },
    options: {
      enabled: !isV2FeatureFlagEnabled,
    },
  })

  const payloadV3 = {
    data: classificationsDataV3 || [],
    isLoading: isLoadingV3,
    hasNextPage: hasNextPageV3,
    fetchNextPage: fetchNextPageV3,
    isFetchingNextPage: isFetchingNextPageV3,
    totalResults: totalResultsV3,
  }

  return isV2FeatureFlagEnabled ? payloadV2 : payloadV3
}
