export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const kilobyteInByes = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const magnitudePowerOfTen = Math.floor(Math.log(bytes) / Math.log(kilobyteInByes))

  return (
    parseFloat((bytes / Math.pow(kilobyteInByes, magnitudePowerOfTen)).toFixed(dm)) + ' ' + sizes[magnitudePowerOfTen]
  )
}
