import { GetInstalledDataSystemsStatsV2ResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchInstalledDataSystemsStats = () =>
  API.get<GetInstalledDataSystemsStatsV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/installed-data-systems/stats`,
    }),
  )
