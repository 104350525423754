import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchFormFields } from '../fetchers/fetchFormFields'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.formFieldsPaginated,
  queryFn: fetchFormFields,
  select: res => res?.data?.formFields || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useFormFields = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
