import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchDataSubjectTypeTemplates } from '../fetchers/fetchDataSubjectTypeTemplates'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.dataSubjectTypeTemplates,
  queryFn: fetchDataSubjectTypeTemplates,
  select: res => res?.data?.templates || [],
})

type QueryConfig = CustomQueryConfig<typeof useCustomQuery>

export const useDataSubjectTypeTemplates = (config?: QueryConfig) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
