import { APIRequestParams } from 'api/common/utils'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  formData: DataSubjectTypeDTO
}>

type Response = {}

export const createDataSubjectType = ({ formData }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/data-subject-types`,
    }),
    { dataSubjectType: formData },
  )
