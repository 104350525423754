import React from 'react'
import { SxProps, Theme } from '@mui/material'
import { Avatar as DeckAvatar } from '@ketch-com/deck'
import { MemberShortDTO } from '@ketch-com/figurehead'
import { AvatarSize } from '@ketch-com/deck'
import { UserDTO, UserShortDTO } from 'interfaces/users/users'
import { avatarColorBucket } from './avatarColorBucket'

//ChatGPT generated function to generate a numeric index from a user's ID
function generateNumberFromUserId(id: string) {
  let sum = 0
  for (let i = 0; i < id.length; i++) {
    sum += id.charCodeAt(i)
  }
  return sum
}

export type AvatarEntity = {
  id: string
  email?: string
  firstName?: string
  lastName?: string
  name: string
}

type Props = {
  borderColor?: string
  size?: AvatarSize
  sx?: SxProps<Theme>
  user?: AvatarEntity | UserDTO | UserShortDTO | MemberShortDTO
  alternativeText?: string
}

function generateInitials(entity: AvatarEntity | UserDTO | UserShortDTO | MemberShortDTO) {
  if (entity.firstName) {
    const first = entity.firstName.charAt(0)
    const last = entity.lastName ? entity.lastName.charAt(0) : entity.firstName.charAt(1)
    return `${first}${last}`.toUpperCase()
  } else if ('name' in entity) {
    const words = entity.name.split(' ')
    const first = words[0].charAt(0)
    const last = words.length > 1 ? words[1].charAt(0) : words[0].charAt(1)
    return `${first}${last}`.toUpperCase()
  } else {
    return 'UN'
  }
}

export const UserAvatar: React.FC<Props> = ({ user, size = AvatarSize.small, sx, borderColor, alternativeText }) => {
  const bucketIndex =
    generateNumberFromUserId((user as any)?.ID || (user as any)?.id || '') % avatarColorBucket.length || 0
  const { fg: color, bg: backgroundColor } = avatarColorBucket[bucketIndex]

  // Use alt text if provided, otherwise use user initials. Fall back to empty string.
  const text = alternativeText ? alternativeText : generateInitials(user || {})

  return (
    <DeckAvatar size={size} textColor={color} backgroundColor={backgroundColor} sx={sx} borderColor={borderColor}>
      {text}
    </DeckAvatar>
  )
}
