import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchWebhook } from '../fetchers/fetchWebhook'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.webhook,
  queryFn: fetchWebhook,
  select: res => res?.data?.webhook || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useWebhook = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
    enabled: config.enabled ?? true,
  })
}
