export const useUsersCurrentTimezone = () => {
  const date = new Date()
  const estString = date.toLocaleString('en-US', {
    timeZoneName: 'short',
  })

  const estStringParts = estString.split(' ')
  const timezone = estStringParts[estStringParts.length - 1]
  return timezone || ''
}
