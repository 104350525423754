import { Tracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIRequestParams } from 'api/common/utils'

type Params = APIRequestParams<{
  encodedTrackerKey?: string | undefined
  includeMetadata?: boolean | undefined
}>

export const fetchTrackerDetails = ({ encodedTrackerKey }: Params) => {
  return API.get<Tracker>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/tracker/${encodedTrackerKey}`,
      params: {
        filters: {},
      },
    }),
  )
}
