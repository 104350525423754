import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchJurisdictionsCanonicalRights } from 'api/rights/fetchers/fetchJurisdictionsCanonicalRights'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.jurisdictionsCanonicalRights,
  queryFn: fetchJurisdictionsCanonicalRights,
  select: res => res?.data?.canonicalRights || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useJurisdictionsCanonicalRights = (config?: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config?.params,
      start: 0,
      limit: 1000,
    },
  })
