import { FC } from 'react'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactComponent as DragAndDropIndicatorIcon } from 'assets/icons/ic_drag_handle.svg'
import { SvgIcon } from '@mui/material'

type Props = {
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  option: string
}

// Need to map canonical right code to user friendly name
const rightNamesMap: { [key: string]: string } = {
  get: 'Provide Data',
  delete: 'Delete Data',
  restrict: 'Restrict Processing',
  update: 'Update Data',
}

export const DraggableCanonicalRight: FC<Props> = ({ provided, snapshot, option }) => {
  return (
    <Box
      ref={provided.innerRef}
      key={option}
      sx={{
        backgroundColor: ({ palette }) => palette.white.main,
        borderRadius: 2.75,
        p: 1.5,
        mb: 2,
        border: ({ palette }) => `1px solid ${palette.bone.main}`,
        boxShadow: snapshot.isDragging ? '0px 12px 20px 0px rgba(7, 26, 36, 0.20)' : 'none',
        display: 'flex',
        alignItems: 'center',
      }}
      style={{
        ...provided.draggableProps.style,
      }}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {/* Drag Handle */}
      <Box mr={2} pt={0.5}>
        <SvgIcon component={DragAndDropIndicatorIcon} inheritViewBox sx={{ height: 14, width: 14 }} />
      </Box>

      {/* Title */}
      <Box gap={1}>
        <Typography variant="h4" color="initial">
          {rightNamesMap[option]}
        </Typography>
      </Box>
    </Box>
  )
}
