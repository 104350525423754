import Box from '@mui/material/Box'
import { Text } from 'components/ui-kit/typography/Text'
import { useParams } from 'react-router-dom'
import { DataRiskDetailLayout } from 'pages/insights/dataRisks/components/layout/DataRiskDetailLayout'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { getRiskScoreDetails } from 'pages/insights/utils'
import { ThirdPartyRisk } from '../../__mocks__/thirdPartyRisk'
import { getDataSystemType } from 'pages/insights/dataMap/utils/getDataSystemType'
import { capitalize } from 'lodash'
import { ThirdPartyRiskLowerContent } from './ThirdPartyRiskLowerContent'
import { ReactComponent as ArrowsIcon } from 'assets/icons/data_risks_suggestions_arrows.svg'
import { ReactComponent as ChecksIcon } from 'assets/icons/data_risks_suggestions_checks.svg'
import { ReactComponent as MobileIcon } from 'assets/icons/blue_mobile.svg'
import { ReactComponent as WebIcon } from 'assets/icons/blue_web.svg'
import { GreyBox } from '../../components/layout/GreyBox'
import { LinkChip } from 'components/ui-kit/chip/LinkChip'

type Props = {
  undisclosedSystemsRecords: ThirdPartyRisk[]
  emptyState: JSX.Element
}

export const UndisclosedSystemsDetails: React.FC<Props> = ({ undisclosedSystemsRecords, emptyState }) => {
  const { code: recordId } = useParams<{ code?: string }>()
  const dataSystem = undisclosedSystemsRecords.find(record => record.id === recordId)
  const riskScoreDetails = getRiskScoreDetails(dataSystem?.riskScore || 0)

  if (!dataSystem) {
    return emptyState
  }

  const actions = (
    <ButtonOptions size="big" items={[{ content: <Text>Export</Text> }, { content: <Text>Compare</Text> }]} />
  )

  const whyContent = (
    <Box>
      <Text component="div" size={20} weight={700} mb={1.5} lineHeight="28px">
        Found on properties but not disclosed in your consent
      </Text>
      <Text component="div" lineHeight="20px">
        This system is unknown and isn't configured in your Privacy portal.
      </Text>
    </Box>
  )

  const suggestionsContent = [
    {
      icon: <ChecksIcon />,
      textContent: (
        <Text lineHeight="20px" component="div">
          Consider disclosing all 3rd party systems and connecting their apps through Data Systems to automate privacy
          signals.
        </Text>
      ),
    },
    {
      icon: <ArrowsIcon />,
      textContent: (
        <Text lineHeight="20px" component="div">
          Automation ensures that all consumer consent and rights signals are captured and enforced.&nbsp;
          <Text color="royalBlue" onClick={() => {}}>
            Check this video
          </Text>
          &nbsp;about implementation techniques.
        </Text>
      ),
    },
  ]

  const lowerContentRows = [<ThirdPartyRiskLowerContent record={dataSystem} key={1} />]

  const mainContent = (
    <GreyBox heading="Found on Properties">
      <Box display="flex" flexDirection="column" gap={1} height={220}>
        <Box>
          <LinkChip icon={<WebIcon />} text="Global Website" />
        </Box>
        <Box>
          <LinkChip icon={<WebIcon />} text="Mobile Website" />
        </Box>
        <Box>
          <LinkChip icon={<MobileIcon />} text="Android App" />
        </Box>
        <Box>
          <LinkChip icon={<MobileIcon />} text="iOS App" />
        </Box>
      </Box>
    </GreyBox>
  )

  return (
    <DataRiskDetailLayout
      heading={dataSystem?.name || ''}
      headingType={capitalize(getDataSystemType(dataSystem?.canonicalResourceType || 0))}
      actions={actions}
      mainContent={mainContent}
      riskScoreDetails={riskScoreDetails}
      riskScore={dataSystem?.riskScore}
      whyContent={whyContent}
      suggestionContent={suggestionsContent}
      lowerContentRows={lowerContentRows}
    />
  )
}
