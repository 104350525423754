import { Icon } from '@ketch-com/deck'
import { FormFieldStyle } from '@ketch-sdk/ketch-types'
import { Box, SxProps, Typography, styled } from '@mui/material'
import { getIn } from 'formik'
import { clamp } from 'lodash'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import React, { useContext } from 'react'
import { BUILDER_CONSTANTS } from '../utils/enums'

export const FormFieldBox = styled(Box)({})

export const InputBox = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: '9.5px 12px 0px 12px',
  marginTop: '4px',
})

export const Label = styled(Typography)({
  fontSize: '14px',
  fontWeight: 600,
})

export const Error = styled(Typography)({
  fontSize: '12px',
  fontWeight: 500,
})

export const InputContent = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
})

export type BuilderFormFieldProps = {
  variant?: 'text' | 'paragraph' | 'dropdown'
  label: string | React.ReactNode
  hasError?: boolean
  isActive?: boolean
  input?: string
}

export const BuilderFormField: React.FC<BuilderFormFieldProps> = ({
  label,
  variant = 'text',
  hasError = false,
  isActive = false,
  input,
}) => {
  const { themeConfig } = useContext(BuilderContext)

  // Theme config
  const style = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormFieldsStyle)
  const inactiveBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabFormFieldsInactiveBackground,
  )
  const inactiveOutlineColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormFieldsInactiveOutline)
  const activeBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormFieldsActiveBackground)
  const activeOutlineColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormFieldsActiveOutline)
  const errorBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormFieldsErrorBackground)
  const errorOutlineColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormFieldsErrorOutline)
  const labelColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormFieldsLabelColor)
  const hintTextColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormFieldsHintTextColor)
  const inputTextColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormFieldsInputTextColor)
  const borderRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormFieldsCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'

  // Build possible sx values
  const inputBoxSxVariants: { [key: string]: SxProps } = {
    inactive: {
      background: inactiveBackgroundColor,
      border: style === FormFieldStyle.Box ? `1px solid ${inactiveOutlineColor}` : '',
      borderBottom: style === FormFieldStyle.Box ? '' : `1px solid ${inactiveOutlineColor}`,
      borderRadius: style === FormFieldStyle.Box ? borderRadius : '',
    },
    active: {
      background: activeBackgroundColor,
      border: style === FormFieldStyle.Box ? `1px solid ${activeOutlineColor}` : '',
      borderBottom: style === FormFieldStyle.Box ? '' : `1px solid ${activeOutlineColor}`,
      borderRadius: style === FormFieldStyle.Box ? borderRadius : '',
    },
    error: {
      background: errorBackgroundColor,
      border: style === FormFieldStyle.Box ? `1px solid ${errorOutlineColor}` : '',
      borderBottom: style === FormFieldStyle.Box ? '' : `1px solid ${errorOutlineColor}`,
      borderRadius: style === FormFieldStyle.Box ? borderRadius : '',
    },
  }

  // Determine which theme config values to use based on props
  const inputBoxSx = hasError
    ? inputBoxSxVariants.error
    : isActive
    ? inputBoxSxVariants.active
    : inputBoxSxVariants.inactive

  return (
    <FormFieldBox mb={hasError ? '-20px' : ''}>
      <Label color={labelColor}>{label}</Label>
      <InputBox height={variant === 'paragraph' ? '75px' : '40px'} sx={inputBoxSx}>
        <InputContent color={input ? inputTextColor : hintTextColor}>{input ?? 'Hint'}</InputContent>
        {variant === 'dropdown' && <Icon name={'OArrowCDown'} />}
      </InputBox>
      {hasError && <Error color={errorOutlineColor}>Required</Error>}
    </FormFieldBox>
  )
}
