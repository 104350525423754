import { SidebarFormGroup } from '../SidebarFormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Box, Typography } from '@mui/material'
import { FormTextInput } from '../FormTextInput'
import { FormCheckbox } from '../FormCheckbox'
import { experienceUpsertFormKeys } from '../../../../utils/useExperienceValidationSchema'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { useContext } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { FormSectionSwitch } from '../FormSectionSwitch'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'

export const BannerExperienceHeaderForm: React.FC = () => {
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const { values } = useFormikContext<ExperienceV2DTO>()
  const hasLanguages = !isLanguagesLoading && !!languages.length
  const showForm = getIn(values, experienceUpsertFormKeys.bannerHeaderVisible)

  return (
    <>
      <FormSectionSwitch name={experienceUpsertFormKeys.bannerHeaderVisible} title={'Header'} />
      {showForm && (
        <Box p={'16px 18px'}>
          <SidebarFormGroup hasBorderTop={false} sx={{ paddingTop: 0 }}>
            <FormTextInput
              label={'Title Text'}
              name={experienceUpsertFormKeys.bannerHeaderTitle}
              fullWidth
              charLimit={200}
            />
          </SidebarFormGroup>
          <SidebarFormGroup hasBorderTop hasBorderBottom={hasLanguages}>
            <FormControlLabel
              control={<FormCheckbox name={experienceUpsertFormKeys.bannerHeaderCloseButtonVisible} />}
              label={<Typography variant={'label'}>Show "X" Button</Typography>}
            />
          </SidebarFormGroup>
          <SidebarFormTranslations
            title={'Title Text Translations'}
            name={experienceUpsertFormKeys.bannerHeaderTitle}
            variant={'text'}
            charLimit={200}
          />
        </Box>
      )}
    </>
  )
}
