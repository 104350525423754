import React from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'

import { Text } from 'components/ui-kit/typography/Text'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useDmlHook } from 'api/dmlHooks/queries/useDmlHook'
import { useDeleteDmlHook } from 'api/dmlHooks/mutations/useDeleteDmlHook'

type Props = {
  hookToDeleteId: string
  onSubmit?: () => void
  onCancel: () => void
}

export const DeleteHookModal: React.FC<Props> = ({ onSubmit, onCancel, hookToDeleteId }) => {
  const queryClient = useQueryClient()

  const { data: hook, isLoading: isLoadingHook } = useDmlHook({
    params: {
      hookId: hookToDeleteId || '',
    },
  })

  const { mutateAsync: handleDeleteDmlHook, isLoading: isMutating } = useDeleteDmlHook({
    onSuccess: async () => {
      onSubmit?.()
      showToast({ content: 'Hook deleted', type: 'success' })
      await queryClient.refetchQueries([ApiQueryKeys.dmlHooks])
    },
    onError: () => {
      showToast({ content: 'Failed to delete Hook', type: 'error' })
    },
  })

  return (
    <Dialog
      isLoading={isLoadingHook}
      isSaving={isMutating}
      title="Delete Hook"
      contentWidth={550}
      submitBtnLabel="Delete"
      cancelBtnLabel="Close"
      withTitleBorderBottom={true}
      onSubmitBtnClick={() => {
        handleDeleteDmlHook({
          params: {
            hookId: hookToDeleteId!,
          },
        })
      }}
      onCancelBtnClick={onCancel}
    >
      <Text size={16} sx={{ mb: 3, display: 'block' }}>
        Are you sure you want to delete <strong>{hook?.name}</strong> hook?
      </Text>

      <Text size={16}>
        Deleting this Hook will immediately remove it from any future DSR workflow executions involving assets formerly
        assigned to this Hook.
      </Text>
    </Dialog>
  )
}
