import { useConsent } from 'api/auditLogs/queries/useConsent'
import { useQueryParams } from 'utils/hooks/useQueryParams'

export const useAuditLogConsent = () => {
  const { queries } = useQueryParams<{ identifierSearch?: string; identitySpaceCode?: string }>()
  const { identifierSearch, identitySpaceCode } = queries
  const isEnableQueries = !!identifierSearch && !!identitySpaceCode

  const consentResp = useConsent({
    enabled: isEnableQueries,
    params: {
      identitySpaceCode,
      identityValue: identifierSearch,
    },
  })

  return consentResp
}
