import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { updateWorkflowExecutionStepReviewers } from 'api/workflowExecutions/fetchers/updateWorkflowExecutionStepReviewers'

const useCustomMutation = createUseMutation({
  mutationFn: updateWorkflowExecutionStepReviewers,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateWorkflowExecutionStepReviewers = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
