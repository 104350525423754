import { StepDetailsVariableDTO } from '@ketch-com/figurehead'
import { StepDetailsTypeEnum } from 'interfaces/workflows/StepDetailsTypeEnum'
import * as Yup from 'yup'

export const getDataSubjectVariablesValidationSchema = () => {
  return Yup.object().shape({
    dataSubjectContext: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().test({
          name: 'dataSubjectVariables',
          test: (value, context: any) => {
            const inputConfig = context?.from?.[0]?.value as StepDetailsVariableDTO

            /* test for required */
            if (inputConfig?.isRequired && !value) return context?.createError({ message: 'Required' })

            /* test for integer */
            const isNumber = inputConfig.type === StepDetailsTypeEnum.INTEGER
            if (isNumber && !Number.isInteger(Number(value)) && inputConfig?.isRequired) {
              return context?.createError({ message: 'Must be an integer' })
            }

            if (isNumber && !!value && !Number.isInteger(Number(value))) {
              return context?.createError({ message: 'Must be an integer' })
            }

            return true
          },
        }),
      }),
    ),
  })
}
