import { useState, useEffect, useRef } from 'react'
import { Message, Conversation, Client, Paginator, ConnectionState } from '@twilio/conversations'
import { useChatToken } from 'api/chat/queries/useChatToken'
import { addParticipantToConversationIfNotExist } from 'api/chat/fetchers/addParticipantToConversation'
import { getAvatarColorMap } from '../../utils'
import { showToast } from 'components/modals/AlertComponent'
import { fetchChatToken } from 'api/chat/fetchers/fetchChatToken'

type UseTwilioConversationClientArgs = {
  conversationId?: string
}

export type AvatarColorMap = Map<string, { bg: string; fg: string }>

export const useTwilioConversationClient = ({ conversationId }: UseTwilioConversationClientArgs) => {
  const [client, setClient] = useState<Client>()
  const [twilioConversation, setTwilioConversation] = useState<Conversation>()
  const [twilioMessages, setTwilioMessages] = useState<Message[]>([])
  const [clientIteration, setClientIteration] = useState(0)
  const [connectionState, setConnectionState] = useState<ConnectionState>()
  const [avatarColorMap, setAvatarColorMap] = useState<AvatarColorMap>(new Map())
  const [unreadCount, setUnreadCount] = useState(0)
  const { data: token, isLoading: isLoadingChatToken } = useChatToken({})
  const [isConversationClosedWithoutCurrentUserAsParticipant, setIsConversationClosedWithoutCurrentUserAsParticipant] =
    useState(false)

  // need to use a ref due to closure in the addMessage listener sometimes having conversationId as empty string
  const conversationIdRef = useRef(conversationId)

  useEffect(() => {
    conversationIdRef.current = conversationId
  }, [conversationId])

  useEffect(() => {
    if (!token || isLoadingChatToken || clientIteration > 10) return

    const client = new Client(token || '')
    if (!!client) setClient(client)

    client.on('messageAdded', async (message: Message) => {
      console.info(`messageAdded`)

      const doesMessageBelongToConversation = message?.conversation?.sid === conversationIdRef.current

      if (!doesMessageBelongToConversation) return

      setTwilioMessages(prev => {
        const newMessages = [...prev, message]
        setAvatarColorMap(getAvatarColorMap({ messages: newMessages }))
        return newMessages
      })
    })
    client.on('messageRemoved', message => {
      console.info(`messageRemoved`)
      setTwilioMessages(prev => prev.filter(m => m.sid !== message.sid))
      showToast({ content: 'Message removed', type: 'success' })
    })
    client.on('tokenAboutToExpire', async () => {
      console.info(`tokenAboutToExpire: about to refresh token`)
      try {
        const tokenResp = await fetchChatToken()
        client.updateToken(tokenResp?.data?.token || '')
      } catch (error) {
        console.error('\n', '\n', `tokenAboutToExpire: failed to refresh token error: `, error, '\n', '\n')
      }
    })
    client.on('tokenExpired', async () => {
      console.info(`tokenExpired: token expired`)
      try {
        const tokenResp = await fetchChatToken()
        client.updateToken(tokenResp?.data?.token || '')
      } catch (error) {
        console.error('\n', '\n', `tokenExpired: failed to refresh token = `, error, '\n', '\n')
      }
    })
    client.on('connectionStateChanged', state => {
      console.info(`connectionStateChanged: state = `, state)
      setConnectionState(state)
    })
    return () => {
      client?.removeAllListeners()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIteration, token])

  useEffect(() => {
    if (!client || !conversationId || !token || isLoadingChatToken || clientIteration > 10) return

    const conversationInit = async () => {
      let sdkConversation = null
      try {
        sdkConversation = await client?.getConversationBySid(conversationId)
        const messagesPaginator: Paginator<Message> = await sdkConversation.getMessages()
        const messages: Message[] = messagesPaginator.items

        setAvatarColorMap(getAvatarColorMap({ messages }))

        setTwilioMessages(messages)
      } catch (error) {
        console.error('\n', `Failed to find and set conversation by id: ${conversationId}`, '\n')
        console.error('\n', `error:`, error as Error, '\n')
      }

      if (sdkConversation) {
        setTwilioConversation(sdkConversation)
      } else {
        try {
          await addParticipantToConversationIfNotExist({ conversationId })
          setClientIteration(x => x + 1) // force client to reinitialize
        } catch (error) {
          console.error('\n', `addParticipantToConversationIfNotExist`, error as Error, '\n')
          setIsConversationClosedWithoutCurrentUserAsParticipant(true)
        }
      }
    }

    conversationInit()
  }, [client, conversationId, isLoadingChatToken, token, clientIteration])

  // get and set unread count on init and on new message
  useEffect(() => {
    if (!twilioConversation) return

    const getUnreadCount = async (): Promise<void> => {
      const count = await twilioConversation?.getUnreadMessagesCount?.()
      /* There's a bug (or simply misunderstood behavior) of the Conversations 
      API where, before the Figurehead participant has sent a message, 
      getUnreadMessagesCount returns 'null', which results in the unread 
      count not being correct when the data subject sends the first message 
      from porthole.  

      The hack fix is to set unread count to the number of messages until 
      getUnreadMessagesCount stops returning null (and instead
      returns 0). */
      if (count === null && twilioMessages.length > 0) {
        setUnreadCount(twilioMessages.length)
      } else {
        setUnreadCount(count || 0)
      }
    }

    getUnreadCount()
  }, [twilioConversation, twilioMessages])

  return {
    twilioChatClient: client,
    isLoadingChatToken,
    setClientIteration,
    twilioConversation,
    twilioMessages,
    connectionState,
    avatarColorMap,
    isConversationClosedWithoutCurrentUserAsParticipant,
    unreadCount,
    setUnreadCount,
  }
}
