import React from 'react'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { Typography, TypographyProps } from '@mui/material'

type Props = {
  firstName?: string
  lastName?: string
  prefix?: string
  textVariant?: TypographyProps['variant']
  renderEmpty?: boolean
}

export const FirstNameLastNameRenderer: React.FC<Props> = ({
  firstName,
  lastName,
  prefix,
  textVariant,
  renderEmpty = true,
}) => {
  if (!firstName && !lastName) return renderEmpty ? <EmptyValueRenderer /> : null

  // Ensures that we don't have an empty space is firstName or lastName is empty
  const nameString = `${firstName} ${lastName}`.trim()

  return <Typography variant={textVariant}>{`${prefix ? ' ' + prefix + ' ' : ''}${nameString}`}</Typography>
}
