import { FindBusinessFunctionsResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Props = APIListRequestParams<{
  purposeId?: string
  query?: string | null | undefined
}>

export const fetchBusinessFunctions = ({ purposeId, query, limit = 1000 }: Props) =>
  API.get<FindBusinessFunctionsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/business-functions`,
      params: {
        filters: {
          ...(limit && {
            limit,
          }),
          ...(query && {
            name: query,
          }),
          ...(purposeId && {
            purposeId,
          }),
        },
      },
    }),
  )
