import { PutTeam200, PutTeamBody } from 'interfaces/services/harbormaster/teams_gen.schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  formData: PutTeamBody
}

type Response = { team: PutTeam200 }

export const updateTeam = ({ formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/teams/${formData?.team?.id ? decodeURIComponent(formData.team.id) : '-1'}`,
    }),
    formData,
  )
