import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FieldArray, Formik } from 'formik'
import { ContextVariableDTO } from '@ketch-com/figurehead'

import { formatCodeFromName } from 'utils/formatters'
import { dataTypeOptions, upsertContextVariableCategoryOptions } from 'pages/settings/contextVariables/constants'
import { useUpsertContextVariablesModalUtils } from './hooks/useUpsertContextVariablesModalUtils'
import { VariableUsedInWorkflowsTable } from 'pages/settings/contextVariables/components'
import { Banner, Button, PopUp, Icon } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { FormRadioGroup } from 'components/form/FormRadioGroup'

type Props = {
  contextVariable?: ContextVariableDTO
  onSubmit: (value?: string) => Promise<void>
  onCancel: () => void
}

export const UpsertContextVariablesModal: React.FC<Props> = ({ onSubmit, onCancel, contextVariable = {} }) => {
  const {
    initialValues,
    validationSchema,
    onFormikSubmit,
    isEditMode,
    isVariableInUseInWorkflows,
    isCreateContextVariableLoading,
    isUpdateContextVariableLoading,
  } = useUpsertContextVariablesModalUtils({
    contextVariable,
    onSubmit,
  })

  return (
    <Formik validateOnMount initialValues={initialValues} validationSchema={validationSchema} onSubmit={onFormikSubmit}>
      {({ values, isSubmitting, handleSubmit, setFieldValue, setFieldTouched, validateForm }) => {
        return (
          <PopUp
            title={isEditMode ? 'Edit Variable' : 'Create a Variable'}
            variant="modal"
            popUpWidth="774px"
            onClose={onCancel}
            popUpActionChildren={
              <>
                <Button
                  color="secondary"
                  size="large"
                  onClick={onCancel}
                  pending={isCreateContextVariableLoading || isUpdateContextVariableLoading || isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  size="large"
                  onClick={() => handleSubmit()}
                  pending={isCreateContextVariableLoading || isUpdateContextVariableLoading || isSubmitting}
                >
                  {isEditMode ? 'Save' : 'Create'}
                </Button>
              </>
            }
          >
            <Box display="flex" flexDirection="column" gap={4}>
              {/* Name */}

              <Box>
                <FormInput
                  required
                  fullWidth
                  formPropertyName="name"
                  label="Name"
                  placeholder="Add recognizable context name"
                  onChange={e => {
                    if (!isEditMode) {
                      setFieldTouched('code', true)
                      setFieldValue('code', formatCodeFromName(e.target.value))
                    }
                  }}
                />
              </Box>

              {/* Code */}
              <Box>
                <FormInput
                  disabled={isEditMode}
                  required
                  fullWidth
                  formPropertyName="code"
                  label="Code"
                  placeholder="Add name for variable"
                />
              </Box>

              {/* Warning Message when editing a DSR _NOT_ currently used by any workflows */}

              {isEditMode && !isVariableInUseInWorkflows && (
                <Box mb={3}>
                  <Banner
                    sx={{
                      '&.MuiPaper-root': {
                        width: 'auto',
                      },
                    }}
                    isCompact
                    severity="info"
                    title="Changes will only impact newly invoked DSRs."
                  />
                </Box>
              )}

              {/* Warning Message when editing a DSR currently used by workflows */}

              {isEditMode && isVariableInUseInWorkflows && (
                <Box px={0.125}>
                  <Box display="flex" flexDirection="column" mb={1}>
                    <Banner
                      sx={{
                        '&.MuiPaper-root': {
                          width: 'auto',
                        },
                      }}
                      isCompact
                      severity="warning"
                      title="To limit unintended results, variables in use can only have their name edited."
                    />
                  </Box>

                  <Typography ml={2} mb={1.5} variant="body" component="div">
                    This variable is used in the following workflows:
                  </Typography>
                  <VariableUsedInWorkflowsTable contextVariable={contextVariable} />
                </Box>
              )}

              {/* Variable Type */}

              <Box>
                <FormRadioGroup
                  sx={{
                    marginLeft: '12px',
                  }}
                  labelSx={{
                    mb: 2,
                  }}
                  label="Variable Type"
                  hideOptionalLabel
                  formPropertyName="category"
                  row
                  valueKey="id"
                  renderLabel={({ label, description }, isSelected?) => {
                    return (
                      <Box display="flex" flexDirection="column" sx={{ maxWidth: 210 }}>
                        <Typography variant={isSelected ? 'label' : 'body'} display="block">
                          {label}
                        </Typography>
                        <Typography variant="footnote">{description}</Typography>
                      </Box>
                    )
                  }}
                  options={upsertContextVariableCategoryOptions.map(cat => ({
                    ...cat,
                    ...(isVariableInUseInWorkflows && {
                      disabled: true,
                    }),
                  }))}
                />
              </Box>

              {/* Data Type */}

              <Box>
                <FormRadioGroup
                  labelSx={{
                    mb: 2,
                  }}
                  sx={{
                    marginLeft: '12px',
                  }}
                  label="Data Type"
                  hideOptionalLabel
                  formPropertyName="type"
                  row
                  valueKey="id"
                  renderLabel={({ variableTypeName }, isSelected?) => {
                    return <Typography>{variableTypeName}</Typography>
                  }}
                  onChange={(e, value) => {
                    if (value !== '1') {
                      setFieldValue('values', [])
                      setFieldTouched('values', false)
                    }
                    if (value === '1') {
                      setFieldValue('values', ['', ''])
                    }
                    setTimeout(() => {
                      validateForm()
                    }, 500)
                  }}
                  options={dataTypeOptions.map(el => ({
                    ...el,
                    ...(isVariableInUseInWorkflows && {
                      disabled: true,
                    }),
                  }))}
                />
              </Box>

              {/* Enum Values */}
              {values.type === 1 ? (
                <Box display="flex" flexDirection="column" gap={1}>
                  <FieldArray name="values">
                    {({ push, remove }) => (
                      <>
                        {values?.values?.map?.((listValue, index: number) => {
                          const initialValuesLength = initialValues?.values?.length || 0
                          const isPartOfInitialValues = index <= initialValuesLength - 1

                          return (
                            <Box key={`${index}-listItem`}>
                              <Box display="flex" alignItems="center" gap={2}>
                                <Box
                                  width={593}
                                  minHeight={90}
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="flex-start"
                                >
                                  <FormInput
                                    formPropertyName={`values[${index}]`}
                                    label={`Item ${index + 1}`}
                                    fullWidth
                                    placeholder=""
                                    required
                                    disabled={isPartOfInitialValues ? isVariableInUseInWorkflows : false}
                                  />
                                </Box>
                                {values?.values?.length === 1 || isPartOfInitialValues ? null : (
                                  <Box display="flex" alignItems="center" height={44}>
                                    <Button
                                      variant="iconCustomRounded"
                                      color="tertiary"
                                      onClick={() => remove(index)}
                                      disabled={
                                        !isPartOfInitialValues ? false : values?.values?.length === 1 || !!isEditMode
                                      }
                                    >
                                      <Icon name="FBin" />
                                    </Button>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          )
                        })}

                        <Box display="flex" alignItems="center" mb={6}>
                          <Button color="secondary" onClick={() => push('')}>
                            Add Option
                          </Button>
                        </Box>
                      </>
                    )}
                  </FieldArray>
                </Box>
              ) : null}
            </Box>
          </PopUp>
        )
      }}
    </Formik>
  )
}
