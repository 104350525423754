import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createDeploymentPlan } from 'api/deploymentPlans/fetchers/createDeploymentPlan'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomMutation = createUseMutation({
  mutationFn: createDeploymentPlan,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateDeploymentPlan = (config?: Config) => {
  const { userData } = useAuth()
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    organizationCode: userData.organizationCode,
  })
}
