import { RGBAColor } from '@deck.gl/core/utils/color'
import { ArcLayer, GeoJsonLayer, ScatterplotLayer } from '@deck.gl/layers'
import { FeatureCollection } from '@turf/helpers'
import { hexToRGBArray } from 'pages/insights/dataMap/components/DataMapVisualization/utils/colors'
import { theme } from '@ketch-com/deck'

interface DataTransfers {
  from: {
    name: string
    coordinates: number[]
  }
  to: {
    name: string
    coordinates: number[]
  }
}
interface JurisdictionDataTransfers {
  [key: string]: {
    dataTransfers: DataTransfers[]
  }
}

type UseLayers = ({
  jurisdictionGeoData,
  jurisdictionDataTransfers,
  selectedJurisdiction,
}: {
  jurisdictionGeoData: FeatureCollection
  jurisdictionDataTransfers: JurisdictionDataTransfers
  selectedJurisdiction: string
}) => any

/**
 * Returns configured deck.gl layers
 *
 * @param jurisdictionGeoData - A GeoJson FeatureCollection
 * @param jurisdictionDataTransfers - DataTransfers of jurisdictions
 * @param selectedJurisdiction - Jurisdiction selected by user
 * @returns any configured deck.gl layers
 *
 */
export const useLayers: UseLayers = ({ jurisdictionGeoData, jurisdictionDataTransfers, selectedJurisdiction }) => {
  const layers = jurisdictionGeoData.features.flatMap(jurisdiction => {
    const {
      properties: { id: jurisdictionCode, jurisdictionFillColor, jurisdictionStrokeColor },
    } = jurisdiction as any

    let layersList: any = [
      new GeoJsonLayer({
        id: jurisdictionCode,
        filled: true,
        stroked: true,
        pickable: true,
        data: jurisdiction,
        lineWidthMinPixels: 0.3,
        lineWidthMaxPixels: 0.3,
        getFillColor: () =>
          selectedJurisdiction && jurisdictionCode !== selectedJurisdiction
            ? hexToRGBArray(theme.palette.white.main)
            : jurisdictionFillColor,
        getLineColor: () =>
          selectedJurisdiction && jurisdictionCode !== selectedJurisdiction
            ? hexToRGBArray(theme.palette.dataMapStrokeDefault.main)
            : jurisdictionStrokeColor,
        updateTriggers: {
          getFillColor: [hexToRGBArray(theme.palette.white.main), jurisdictionFillColor],
          getLineColor: [hexToRGBArray(theme.palette.dataMapStrokeDefault.main), jurisdictionStrokeColor],
        },
        getTooltip: (object: any) => object && object.message,
      }),
    ]

    if (jurisdictionCode !== 'default' && jurisdictionDataTransfers?.[jurisdictionCode]?.dataTransfers?.length) {
      const arcLayer = new ArcLayer({
        id: `arcLayer${jurisdictionCode}`,
        visible: !selectedJurisdiction,
        data: jurisdictionDataTransfers[jurisdictionCode].dataTransfers,
        pickable: false,
        lineWidthMinPixels: 1,
        getTilt: 50,
        getHeight: 0.2,
        getSourcePosition: (d: any) => d.from.coordinates,
        getTargetPosition: (d: any) => d.to.coordinates,
        getSourceColor: () => jurisdictionStrokeColor,
        getTargetColor: () => jurisdictionStrokeColor,
      })

      const scatterPlotLayer = jurisdictionDataTransfers[jurisdictionCode].dataTransfers.flatMap(transfer => {
        return [
          new ScatterplotLayer({
            id: `scatterplotFrom${jurisdictionCode}`,
            visible: !selectedJurisdiction,
            data: [{ coordinates: transfer.from.coordinates }],
            pickable: false,
            opacity: 1,
            stroked: false,
            filled: true,
            radiusMinPixels: 1.5,
            radiusMaxPixels: 1.5,
            getPosition: (d: any) => d.coordinates,
            getFillColor: () => jurisdictionStrokeColor,
          }),
          new ScatterplotLayer({
            id: `scatterplotTo${transfer.to.name}`,
            visible: !selectedJurisdiction,
            data: [{ coordinates: transfer.to.coordinates }],
            pickable: false,
            opacity: 1,
            stroked: false,
            filled: true,
            radiusMinPixels: 1.5,
            radiusMaxPixels: 1.5,
            getPosition: (d: any) => d.coordinates,
            getFillColor: () => hexToRGBArray(theme.palette.darkDusk.main) as RGBAColor,
          }),
        ]
      })

      layersList = [...layersList, arcLayer, ...scatterPlotLayer]
    }
    return layersList
  })

  const dedupedLayers = Array.from(new Set(layers.map(layer => layer.id))).map(layerId => {
    return layers.find(layer => layer.id === layerId)
  })

  return dedupedLayers
}
