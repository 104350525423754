import { FindOrganizationDataSystemGlobalCustomAttributesResponseBodyDTO } from '@ketch-com/figurehead'

import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchDataSystemGlobalCustomAttributes = () =>
  API.get<FindOrganizationDataSystemGlobalCustomAttributesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/data-systems/global-custom-attributes`,
    }),
  )
