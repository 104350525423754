import { APIListRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { TableResources } from 'pages/assetManager/interfaces'

type params = APIListRequestParams<{
  nextToken?: string
  parentDbId?: string
}>

export const fetchTableResources = ({ nextToken, limit = 20, parentDbId = '', query }: params) => {
  return API.get<TableResources>(
    formatRequestString({
      entityUrl: `/api/asset-manager/databases/${parentDbId}/tables`,
      params: {
        filters: {
          ...(query && {
            searchString: query,
          }),
          ...(limit && {
            limit,
          }),
          ...((nextToken || {}) && {
            nextToken,
          }),
        },
      },
    }),
  )
}
