import React, { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces/common'
import { RegionDTO } from 'interfaces/regions/region'
import { RegulationDTO } from 'interfaces/regulations/regulation'
import { RightDTO } from 'interfaces/rights/right'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'

import { useValidationSchema } from 'pages/policyCenter/policyScopes/upsert/utils'
import { RoutesManager } from 'utils/routing/routesManager'

import { UpsertLayout, UpsertLayoutMode, ContentGroup, Spinner } from '@ketch-com/deck'
import { PolicyScopeDetails } from 'pages/policyCenter/policyScopes/upsert/components/PolicyScopeDetails'
import { PolicyScopeRegionsAndRegulations } from 'pages/policyCenter/policyScopes/upsert/components/PolicyScopeRegionsAndRegulations'
import { PolicyScopeRights } from './PolicyScopeRights'
import { PolicyScopeFormValues } from '../interfaces'
import { getEditPolicyScopeFormInitialValues } from '../utils/getEditPolicyScopeFormInitialValues'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'
import { useFormik, FormikProvider } from 'formik'
import { NavigationBreadCrumbs } from '../../../../../components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Box } from '@mui/material'

interface EditPolicyScopeProps {
  isReady: boolean
  onSubmit: (values: PolicyScopeFormValues) => Promise<void>
  policyScope: MaybeNull<PolicyScopeDTO>
  regulations: RegulationDTO[]
  regionGroups: RegionDTO[]
  rights: RightDTO[]
}

export const EditPolicyScope: React.FC<EditPolicyScopeProps> = ({
  isReady,
  onSubmit,
  policyScope,
  regulations,
  regionGroups,
  rights,
}) => {
  const navigate = useNavigate()

  const initialValues = useMemo(
    () => getEditPolicyScopeFormInitialValues({ policyScope, regulations }),
    [policyScope, regulations],
  )
  const validationSchema = useValidationSchema({ isEditMode: true })

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  })

  const name = policyScope?.name || ''
  const code = policyScope?.code || ''

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Jurisdictions', link: RoutesManager.policyCenter.policyScopes.root.getURL() },
    {
      title: name || code,
      link: RoutesManager.policyCenter.policyScopes.view.root.getURL({ code }),
    },
    { title: 'Edit Jurisdiction' },
  ]

  const { submitForm, isSubmitting, validateForm, setFieldTouched, values, errors } = formikProps

  useEffect(() => {
    logObjectDetails(
      {
        values,
        errors,
      },
      'EditPolicyScope.tsx',
      'white',
      false, // false ensures will log only in debug mode (i.e. ?debug=log)
    )
  }, [values, errors])

  const onAccept = async () => {
    const errors = await validateForm()
    if (Object.keys(errors).length) {
      Object.keys(errors).forEach(errorKey => {
        if (errors.rightsFulfillment && errorKey === 'rightsFulfillment' && Array.isArray(errors.rightsFulfillment)) {
          errors.rightsFulfillment.forEach((rightsFulfillment, idx) => {
            Object.keys(rightsFulfillment).forEach(errorKey => {
              setFieldTouched(`rightsFulfillment[${idx}].${errorKey}`, true)
            })
          })
        } else {
          setFieldTouched(errorKey, true)
        }
      })
      return
    }
    submitForm()
  }

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          sx={{
            '&.UpsertLayout-container': {
              '& .UpsertLayout-stepper': {
                // 8px gap between layout stepper and content
                top: '8px',
              },
            },
          }}
          mode={UpsertLayoutMode.edit}
          showStepper={false}
          renderFormTitle={() => <ContentGroup title="Edit Jurisdiction" titleVariant="h2" isDivider />}
          onAccept={onAccept}
          onCancel={() => {
            navigate(RoutesManager.policyCenter.policyScopes.view.root.getURL({ code }))
          }}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {isReady ? (
              <>
                <PolicyScopeDetails isEditMode />

                <PolicyScopeRegionsAndRegulations isEditMode regulations={regulations} regionGroups={regionGroups} />

                <PolicyScopeRights rights={rights} />
              </>
            ) : (
              <Box
                sx={{
                  margin: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spinner size="32px" thickness={2.5} />
              </Box>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
