import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

type UseStylesProps = {
  isSelected: boolean
}

export const useAssetListItemStyles = makeStyles<Theme, UseStylesProps>(
  ({ palette, spacing, shape }) =>
    createStyles({
      container: {
        border: ({ isSelected }) => (isSelected ? `1.5px solid ${palette.sphere.main}` : 'none'),
        backgroundColor: palette.white.main,
        borderRadius: (shape.borderRadius as number) * 3,
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: spacing(2),
        padding: spacing(1.375, 2.25, 2, 2.25),
        position: 'relative',
        // NOTE: Design has requested that the on-hover styles be removed.
        // Should the need arise in the future the below pattern can be used as a guide

        // '&:hover': {
        //   '& $assetStatTileContainer': {
        //     backgroundColor: palette.fadedGrey.main,
        //     transition: 'background-color 0.3s ease-in-out 0.8s',
        //   },

        //   '& $tileText': {
        //     color: 'black',
        //     transition: 'color 0.3s ease-in-out 0.8s',
        //   },
        //   '& $assetTileValue': {
        //     color: palette.darkDusk.main,
        //     transition: 'color 0.3s ease-in-out 0.8s',
        //   },
        // },
      },
      shouldPreserveHoverStyles: {
        '& $assetStatTileContainer': {
          backgroundColor: palette.fadedGrey.main,
        },

        '& $tileText': {
          color: 'black',
        },

        '& $assetTileValue': {
          color: palette.darkDusk.main,
        },
      },

      hiddenRow: {
        maxHeight: 0,
        overflow: 'hidden',
        transition: 'max-height 0.3s ease-in-out 1s',
      },

      /* First Row */

      firstRowContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      dataSetIcon: {
        marginRight: spacing(1),
      },
      primaryKeyText: {
        marginLeft: spacing(0.5),
      },
      dataOwnerText: {
        marginRight: spacing(3),
      },
      techImage: {
        height: 22,
        width: 22,
        marginRight: spacing(1),
      },

      /* Second Row */
      secondRowContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      policiesPillIndicator: {
        padding: spacing(0.5, 1),
        background: palette.marine.main,
        borderRadius: '100px',
        color: palette.persianBlue.main,
      },
      transponderChip: {
        backgroundColor: palette.fadedGrey.main,
        marginRight: spacing(1),
        color: palette.darkDuskFaded.main,
        padding: spacing(0.2125, 0.75),
        fontSize: 11,
        fontWeight: 500,
      },
      assetName: {
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        },
      },

      /* Third Row */
      thirdRowContainer: {
        display: 'flex',
        alignItems: 'center',
      },
      techTileImage: {
        width: 22,
        height: 22,
        marginBottom: spacing(1),
      },
      tileText: {
        transition: 'color 0.3s ease-in-out 0.8s',
      },
      assetStatTileContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '1px solid rgba(7, 26, 36, 0.08)',
        borderRadius: spacing(1.25),
        padding: spacing(1.75, 2.25),
        marginRight: spacing(2.25),
        backgroundColor: palette.white.main,
        transition: 'background-color 0.3s ease-in-out 0.8s',
        height: '100%',
        minHeight: 75,
      },
      assetTileValue: {
        textTransform: 'capitalize',
        '&:hover': {
          color: palette.darkDusk.main,
        },
      },
      capitalCase: {
        textTransform: 'capitalize',
      },

      /* Fourth Row */

      fourthRowContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      actionRowHorizontalRule: {
        display: 'flex',
        borderBottom: `2px solid ${palette.iron.main}`,
      },
      descriptionIcon: {
        marginRight: spacing(1),
        height: 12.5,
        width: 11.375,
        minHeight: 12.5,
        minWidth: 11.375,
      },
      visuallyHide: {
        opacity: 0,
      },
      buttonOptions: {
        background: palette.white.main,
      },
    }),
  { name: 'DataSetListCard' },
)
