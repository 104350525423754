import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  queryKey: [string, { dsrPolicyId: string }]
}

export const fetchDsrPolicies = ({ queryKey }: Params) => {
  const [, { dsrPolicyId }] = queryKey
  return API.get(
    formatRequestString({
      entityUrl: `/api/dsr-policies/${dsrPolicyId}`,
    }),
  )
}
