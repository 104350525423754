import { useConnections } from 'api/assets/queries/useConnections'
import { ConnectionDTO, ResourceTypeDTO } from '@ketch-com/figurehead'

export const useDeDuplicatedConnections = () => {
  const { data: connections } = useConnections()
  const getDeDuplicatedConnections = (connections: ConnectionDTO[]) => {
    const allConnections = connections.reduce((acc, connection) => {
      return [...acc, ...(connection?.resourceTypes || [])]
    }, [] as ResourceTypeDTO[])
    // de-duplication in filter function
    return allConnections.filter((value, index) => {
      const _value = JSON.stringify(value)
      return (
        index ===
        allConnections.findIndex(obj => {
          return JSON.stringify(obj) === _value
        })
      )
    })
  }

  return getDeDuplicatedConnections(connections)
}
