import * as Yup from 'yup'

interface IValidationParams {
  usedCodes?: Array<string>
}

export const getIdentityVerificationFormValidation = (params: IValidationParams) =>
  Yup.object().shape({
    code: Yup.string()
      .notOneOf(params?.usedCodes || [], 'This code is already in use')
      .required('This field is required'),
    description: Yup.string().required('Description is required'),
    subject: Yup.string().required('This field is required'),
    body: Yup.string().required('This field is required'),
    identityVerificationTimeoutDays: Yup.number()
      .typeError('Must be a number between 1 and 50')
      .min(1, 'Must be at least 1')
      .max(50, 'Must not exceed 50')
      .required('This field is required'),
    cc: Yup.array().of(Yup.string()),
    // inputs on Verification Options tab view
    lexisNexisButtonText: Yup.string().test(
      'LexisNexis Button Text Test',
      'Required if Authentication and LexisNexis are both selected',
      (value, context) => {
        const { parent } = context
        if (parent.isLexisNexis && parent.isAuthentication) {
          return !!value
        } else return true
      },
    ),
    authenticatorButtonText: Yup.string().test(
      'Authenticator Button Text Test',
      'Required if Authentication and LexisNexis are both selected',
      (value, context) => {
        const { parent } = context
        if (parent.isLexisNexis && parent.isAuthentication) {
          return !!value
        } else return true
      },
    ),
    authenticatorID: Yup.string().test(
      'AuthenticatorID Test',
      'Required if Authentication is selected',
      (value, context) => {
        const { parent } = context
        if (parent.isAuthentication) {
          return !!value
        } else return true
      },
    ),
    lexisNexisAppInstanceID: Yup.string().test(
      'lexisNexisAppInstanceID Test',
      'Required if Authentication is selected',
      (value, context) => {
        const { parent } = context
        if (parent.isLexisNexis) {
          return !!value
        } else return true
      },
    ),
  })
