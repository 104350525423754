import { faker } from '@faker-js/faker'

type RiskTrend = {
  month?: string
  riskScore?: number
}

export type CompanyInfo = {
  companyName?: string
  legalName?: string
  companyType?: string
  employees?: number
  industry?: string
  sector?: string
  linkedIn?: string
}

export type ThirdPartyRisk = {
  id?: string
  name?: string
  about?: CompanyInfo
  logo?: string
  summary?: string
  riskScore?: number
  riskCategory?: string
  canonicalResourceType?: number
  personalDataTypes?: string[]
  dataCategories?: string[]
  purposes?: {
    name?: string
    code?: string
  }[]
  riskTrend?: RiskTrend[]
}

export type ThirdPartyRisks = {
  privacyControls?: ThirdPartyRisk[]
  totalResults?: number
}

const dataCategories = [
  'ZIP',
  'First Name',
  'School',
  'Last Name',
  'User ID',
  'SSN',
  'Job Title',
  'Salary',
  'Gender',
  'Email',
  'Middle Initial',
  'Employer',
  'Address',
  'Medical History',
  'Purchase History',
  'Snacking Trends',
  'Shoe Size',
  'Browsing Trends',
  'Search History',
]

const purposes = [
  { name: 'Candidate Recruiting', code: 'candidate_recruiting' },
  { name: 'Analytics', code: 'analytics' },
  { name: 'DSR Processing (Controller)', code: 'dsr_processing' },
  { name: 'Marketing', code: 'marketing' },
]

const undisclosedSystemsSummaries = ['Found on properties but not disclosed in your consent']
const unknownTransfersSummaries = ['Attracts a large number of data collectors']
const privacySummaries = [
  'DSR and consent controls not in place',
  'DSR controls not in place',
  'Consent controls not in place',
  'DSR and consent controls are not configured',
  'Consent controls are not configured',
  'DSR controls are not configured',
]

const apps = [
  {
    name: 'Alchemer',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/alchemer/1.52.12/118e1f61835e65132ec7111429644922b490fb14cc87a983f6dd9043803f172b/logo.svg',
    type: 2,
  },
  {
    name: 'Amazon Redshift',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/amazon-redshift/0.0.7/8081c937186086c8a29a34f90314e93bd03e7a3d3f462e15f8c2847125123c83/logo.svg',
    type: 1,
  },
  {
    name: 'Amazon S3',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/amazon-s3/0.1.2/bf3a16681cf66282c50d4d46c5521b6aab479f0181831e7102f6dc5fe9fe0159/logo.svg',
    type: 1,
  },
  {
    name: 'Autopilot',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/autopilot/1.30.2/fab75217ad0e46c26e83faed39e153512b11c84b186930820ba1881457c54db0/logo.svg',
    type: 2,
  },
  {
    name: 'Coveo',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/coveo/1.52.11/bd0bf487bc4668468b1524c80befe2eca7bef07fa1cc0b5b306e773132a3dd4f/logo.svg',
    type: 2,
  },
  {
    name: 'Discourse',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/discourse/1.12.2/b6de2df7d452b15fc0290ec3e4a336ff22ebb231bed8ab17a6f69f9393402484/logo.svg',
    type: 2,
  },
  {
    name: 'Dynamic Yield',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/dynamic_yield/1.31.8/21ff737a40f023ba89c5f89fa5b33ddfe4e27f8a6e52d75bf58160944262b550/logo.svg',
    type: 2,
  },
  {
    name: 'Facebook Ads',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/facebookAds/0.3.5/070b6a0b83b2dcf836e31d9c4a978f92c9bc506f6ce46312e3988886fc715a60/logo.svg',
    type: 2,
  },
  {
    name: 'Google Analytics',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/google_analytics/1.67.0/e6d5bb1e898ff06565aeb65d2a3e64e4fa67f3dfcda7b80af7bc8d2a8f40fab8/logo.svg',
    type: 2,
  },
  {
    name: 'Google Marketing',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/google_marketing/0.2.5/f8040033f29d4fb6073a9f97dc3c01742253552e59829fea19464dbccd3e45ce/logo.svg',
    type: 2,
  },
  {
    name: 'Heap Analytics',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/heap/1.66.0/4b4617e3609256f2c6287ca17579fb0557c938533a03ce0eb5a7697aac564363/logo.svg',
    type: 2,
  },
  {
    name: 'Hubspot',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/hubspot/1.37.2/4110ba25f28a48bb93cc65baaf5e2ed4b9f2882b9a8d034a477ef440710fd8d6/logo.svg',
    type: 2,
  },
  {
    name: 'IAB CCPA',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/IAB%20CCPA/1.34.3/68e7482bee17f7a385ac19d5059a2f028ad4cb47ab7c2c75dec5bbde4e98c775/logo.svg',
    type: 2,
  },
  {
    name: 'Judge.Me',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/judgeme/1.52.13/73094df637dd910e5110b32f468c8708324eeee64a359027eaa502a0afe517dc/logo.svg',
    type: 2,
  },
  {
    name: 'Ketch Sync',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/ketchSync/1.33.0/b743c5bd7f48e8d6aaa83d639516cc3a88ec8d32d9be5983bd79ba7919080ff4/logo.svg',
    type: 1,
  },
  {
    name: 'Klaviyo',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/klaviyo/1.65.1/c91f48270ccaf34608965deb1de0be2ed27b594f05e964df68191547cf2f6f51/logo.svg',
    type: 2,
  },
  {
    name: 'LinkedIn',
    logo: 'https://transom.dev.b10s.io/transom/assets/ketch_apps/linkedin/0.7.12/3438e6ee6339f26ed2c8dca631e94fd080fa0ae78cdd60a5dd446b6fa2634804/logo.svg',
    type: 2,
  },
  {
    name: 'Mailchimp',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mailchimp/0.1.7/f63f0618a14024415529e19c636fe1d220e604f3c5fa2fd25e31b40455743f85/logo.svg',
    type: 2,
  },
  {
    name: 'Mailgun',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mailgun/1.55.0/4473a3ad43aaf70b65ab0bb5efbafafc33510c6301e6a5d551ca12804018a126/logo.svg',
    type: 2,
  },
  {
    name: 'MariaDB',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mariadb/0.2.0/9cdcc88126b726776cff6e9572faba7d85da6f09371226d19312e7bf49b02bee/logo.svg',
    type: 1,
  },
  {
    name: 'Marketo',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/marketo/1.69.1/cc894a7df917e1a24c3eb44db101705ef4fcc33ab35aeb31c206ffd82a661ba2/logo.svg',
    type: 2,
  },
  {
    name: 'Mixpanel',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mixpanel/1.31.12/4e41ed839a7b516b43b3d32f89f735dc220fc29179dce975f6fa247b33637519/logo.svg',
    type: 2,
  },
  {
    name: 'MongoDB',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mongodb/0.2.1/22244e0f3c723b0f1a6d5fbfc45d9b60af8a0b6f3da7df10649f20933e098e10/logo.svg',
    type: 1,
  },
  {
    name: 'mParticle',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mParticle/1.61.5/12a4fd68aa81212ec798101c0ed03b04f112115f11ce66c03c8828a7f5546dd2/logo.svg',
    type: 2,
  },
  {
    name: 'Mysql',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mysql/0.1.2/52f2a07caa647d7cb97ded9618e13d02217b1581ff670dfb3d99942febb3d8c3/logo.svg',
    type: 1,
  },
  {
    name: 'Optimizely',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/optimizely/1.69.0/bfd0c7d979fdeeeb08549dd1601d77e2ca01f1fe8a84f462a5bab6083f4b5289/logo.svg',
    type: 2,
  },
  {
    name: 'Oracle',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/oracle/0.2.0/e4213f519ef895c9c67171fafd0fd710830cf258707a245efeb8ba03874201ea/logo.svg',
    type: 1,
  },
  {
    name: 'Outreach',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/outreach/1.35.16/0d9a56b6326e3c66a60b4c700d259dd7bd42abbd41d75b1fc76cf3ecbed6114e/logo.svg',
    type: 2,
  },
  {
    name: 'Pendo',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/pendo/1.60.1/3849a4eade7ee9fe84bfd5b75ab306d531548b9fc3d50db7f89e096ebace98de/logo.svg',
    type: 2,
  },
  {
    name: 'Qualtrics',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/qualtrics/1.57.0/abecf7b66c92c687cdf647bfbab3c39cfe7458f06312a2007bb32f00b51504b5/logo.svg',
    type: 2,
  },
  {
    name: 'Redshift',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/redshift/0.0.9/8081c937186086c8a29a34f90314e93bd03e7a3d3f462e15f8c2847125123c83/logo.svg',
    type: 1,
  },
  {
    name: 'SalesForce Sales Cloud CRM',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/salesforce/1.67.1/3cf821829c46c38f711c58f3ff9bd55a64f5ffa2df37abc186a5a277910cb38f/logo.svg',
    type: 2,
  },
  {
    name: 'Segment',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/segment/1.64.3/40b3fb60e63585e86ebb4bc0e16efbb3ab6e104676560f522a79508378e99934/logo.svg',
    type: 2,
  },
  {
    name: 'SendGrid',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/sendgrid/1.61.0/14f2c3388777ef052768264e52c7ff368f96a8336f885817e93f224a3b563565/logo.svg',
    type: 2,
  },
  {
    name: 'shopify',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/shopify/1.69.3/245d99328546426e7403286aa021e4d21684fffd90419dd46a77b69432567d37/logo.svg',
    type: 2,
  },
  {
    name: 'Snapchat',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/snapchat/1.35.11/8eec3bcc0a619675b10e3919e6b7f618941598352fadf159390212e407cd0c53/logo.svg',
    type: 2,
  },
  {
    name: 'Snowflake',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/snowflake/0.1.1/53f5298918795ca2332e8e91428d1aed9e5deee03c017bea70c53290aa5c0dac/logo.svg',
    type: 1,
  },
  {
    name: 'Splash',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/splash/1.61.9/30d65b321478aef840a592663043729715c1a06c7b9a379b7ca53c10b05d9104/logo.svg',
    type: 2,
  },
  {
    name: 'TikTok Ads',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/tiktok_ads/1.30.9/c5d49f16d222d036ff865b7a2a4de7a321f8cdc8c3c7aedc31e6667c73fbab03/logo.svg',
    type: 2,
  },
  {
    name: 'Twitter Ads',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/twitter_ads/0.1.4/49bc251000bbeae7a6c1ec3bdfd0fbf7c7c3cc49d30c1ff27a504d213c870cac/logo.svg',
    type: 2,
  },
  {
    name: 'Yotpo/Swell',
    logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/swell/1.31.10/271c7b1a26b1350ac571312befb8271ae6b04cd1aa03ae65fcf7ac2c78006795/logo.svg',
    type: 2,
  },
]
const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const generateThirdPartyRiskData = (key: string): ThirdPartyRisks => {
  return {
    [key]: apps.map(app => {
      const riskScore = faker.datatype.number({ min: 10, max: 100 })
      let riskCategory: string = 'Extreme'
      if (riskScore < 30) riskCategory = 'Acceptable'
      if (riskScore < 60) riskCategory = 'Tolerable'
      if (riskScore < 80) riskCategory = 'Critical'

      const now = faker.datatype.number({ min: 0, max: 11 })
      const riskTrend: RiskTrend[] = []
      for (let j = 0; j <= now; j++) {
        riskTrend.push({
          month: month[j],
          riskScore: j === now ? riskScore : faker.datatype.number({ min: 10, max: riskScore }),
        })
      }
      return {
        id: faker.datatype.uuid(),
        name: app.name,
        logo: app.logo,
        summary: faker.helpers.arrayElement(
          key === 'privacyControls'
            ? privacySummaries
            : key === 'unknownTransfers'
            ? unknownTransfersSummaries
            : undisclosedSystemsSummaries,
        ),
        riskScore,
        riskCategory,
        canonicalResourceType: app.type,
        purposes: faker.helpers.arrayElements(purposes, 3),
        personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
        dataCategories: faker.helpers.arrayElements(dataCategories, 16),
        about: {
          companyName: app.name.split(' ')[0],
          legalName: `${app.name.split(' ')[0]} ${faker.helpers.arrayElement(['Inc', 'LLC'])}`,
          companyType: faker.helpers.arrayElement(['Public', 'Private']),
          employees: faker.helpers.arrayElement([50, 500, 1500, 5000, 12000, 30000]),
          industry: 'Internet Software & Services',
          sector: 'Information Technology',
          linkedIn: `https://www.linkedin.com/in/${app.name}`,
        },
        riskTrend,
      }
    }),
    totalResults: apps.length,
  }
}

// Uncomment to generate new data
// Only generate once so that refreshing the page works
// console.info(generateThirdPartyRiskData('privacyControls'))
// console.info(generateThirdPartyRiskData('unknownTransfers'))
// console.info(generateThirdPartyRiskData('undisclosedSystems'))

export const privacyControlsMockData = {
  privacyControls: [
    {
      id: '02c70bb0-82b3-4c68-b6bd-876644bb9a3d',
      name: 'Alchemer',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/alchemer/1.52.12/118e1f61835e65132ec7111429644922b490fb14cc87a983f6dd9043803f172b/logo.svg',
      summary: 'DSR and consent controls not in place',
      riskScore: 25,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'SSN',
        'Shoe Size',
        'Browsing Trends',
        'Address',
        'Last Name',
        'Employer',
        'Job Title',
        'ZIP',
        'Search History',
        'Email',
        'Middle Initial',
        'Medical History',
        'User ID',
        'Snacking Trends',
        'Gender',
        'School',
      ],
      about: {
        companyName: 'Alchemer',
        legalName: 'Alchemer Inc',
        companyType: 'Public',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Alchemer',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 23,
        },
        {
          month: 'Feb',
          riskScore: 25,
        },
      ],
    },
    {
      id: '1d98bfb4-3e06-49b7-a016-2136d2183d26',
      name: 'Amazon Redshift',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/amazon-redshift/0.0.7/8081c937186086c8a29a34f90314e93bd03e7a3d3f462e15f8c2847125123c83/logo.svg',
      summary: 'DSR controls are not configured',
      riskScore: 52,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Salary',
        'Email',
        'School',
        'User ID',
        'SSN',
        'Gender',
        'First Name',
        'Search History',
        'Snacking Trends',
        'Address',
        'ZIP',
        'Last Name',
        'Job Title',
        'Medical History',
        'Shoe Size',
        'Purchase History',
      ],
      about: {
        companyName: 'Amazon',
        legalName: 'Amazon Inc',
        companyType: 'Public',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Amazon Redshift',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 52,
        },
      ],
    },
    {
      id: '9613f98a-6554-43b2-b8b9-b1454850c8fc',
      name: 'Amazon S3',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/amazon-s3/0.1.2/bf3a16681cf66282c50d4d46c5521b6aab479f0181831e7102f6dc5fe9fe0159/logo.svg',
      summary: 'Consent controls are not configured',
      riskScore: 94,
      riskCategory: 'Extreme',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'User ID',
        'Address',
        'Last Name',
        'Job Title',
        'SSN',
        'Gender',
        'Middle Initial',
        'School',
        'Email',
        'Shoe Size',
        'Employer',
        'Snacking Trends',
        'Salary',
        'ZIP',
        'Medical History',
        'Browsing Trends',
      ],
      about: {
        companyName: 'Amazon',
        legalName: 'Amazon Inc',
        companyType: 'Public',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Amazon S3',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 52,
        },
        {
          month: 'Feb',
          riskScore: 94,
        },
      ],
    },
    {
      id: '3e7e251c-4f69-4013-9ea0-99ede5a33693',
      name: 'Autopilot',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/autopilot/1.30.2/fab75217ad0e46c26e83faed39e153512b11c84b186930820ba1881457c54db0/logo.svg',
      summary: 'DSR controls are not configured',
      riskScore: 69,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'School',
        'Browsing Trends',
        'Gender',
        'Middle Initial',
        'User ID',
        'First Name',
        'Purchase History',
        'Search History',
        'ZIP',
        'Last Name',
        'SSN',
        'Medical History',
        'Address',
        'Employer',
        'Email',
        'Shoe Size',
      ],
      about: {
        companyName: 'Autopilot',
        legalName: 'Autopilot Inc',
        companyType: 'Public',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Autopilot',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 15,
        },
        {
          month: 'Feb',
          riskScore: 55,
        },
        {
          month: 'Mar',
          riskScore: 54,
        },
        {
          month: 'Apr',
          riskScore: 48,
        },
        {
          month: 'May',
          riskScore: 57,
        },
        {
          month: 'Jun',
          riskScore: 30,
        },
        {
          month: 'Jul',
          riskScore: 34,
        },
        {
          month: 'Aug',
          riskScore: 44,
        },
        {
          month: 'Sep',
          riskScore: 14,
        },
        {
          month: 'Oct',
          riskScore: 68,
        },
        {
          month: 'Nov',
          riskScore: 69,
        },
      ],
    },
    {
      id: '38bf3dd3-7096-42b2-ba08-9d444f24504c',
      name: 'Coveo',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/coveo/1.52.11/bd0bf487bc4668468b1524c80befe2eca7bef07fa1cc0b5b306e773132a3dd4f/logo.svg',
      summary: 'Consent controls are not configured',
      riskScore: 54,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Browsing Trends',
        'Shoe Size',
        'Employer',
        'Snacking Trends',
        'SSN',
        'Gender',
        'Medical History',
        'Salary',
        'Address',
        'School',
        'Search History',
        'Job Title',
        'User ID',
        'Middle Initial',
        'First Name',
        'Last Name',
      ],
      about: {
        companyName: 'Coveo',
        legalName: 'Coveo Inc',
        companyType: 'Private',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Coveo',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 26,
        },
        {
          month: 'Feb',
          riskScore: 47,
        },
        {
          month: 'Mar',
          riskScore: 18,
        },
        {
          month: 'Apr',
          riskScore: 54,
        },
      ],
    },
    {
      id: '0702b99e-a96d-4d73-a53f-d3562194dfaf',
      name: 'Discourse',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/discourse/1.12.2/b6de2df7d452b15fc0290ec3e4a336ff22ebb231bed8ab17a6f69f9393402484/logo.svg',
      summary: 'DSR controls not in place',
      riskScore: 17,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'Gender',
        'Job Title',
        'Salary',
        'Address',
        'Shoe Size',
        'Purchase History',
        'Medical History',
        'Middle Initial',
        'First Name',
        'Snacking Trends',
        'School',
        'SSN',
        'Email',
        'User ID',
        'Browsing Trends',
      ],
      about: {
        companyName: 'Discourse',
        legalName: 'Discourse Inc',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Discourse',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 11,
        },
        {
          month: 'Feb',
          riskScore: 13,
        },
        {
          month: 'Mar',
          riskScore: 10,
        },
        {
          month: 'Apr',
          riskScore: 15,
        },
        {
          month: 'May',
          riskScore: 13,
        },
        {
          month: 'Jun',
          riskScore: 13,
        },
        {
          month: 'Jul',
          riskScore: 11,
        },
        {
          month: 'Aug',
          riskScore: 16,
        },
        {
          month: 'Sep',
          riskScore: 14,
        },
        {
          month: 'Oct',
          riskScore: 17,
        },
      ],
    },
    {
      id: 'b6be8bb1-25e1-4260-8a92-bed59ef1b14f',
      name: 'Dynamic Yield',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/dynamic_yield/1.31.8/21ff737a40f023ba89c5f89fa5b33ddfe4e27f8a6e52d75bf58160944262b550/logo.svg',
      summary: 'DSR and consent controls are not configured',
      riskScore: 10,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Address',
        'Browsing Trends',
        'Search History',
        'Email',
        'SSN',
        'Medical History',
        'Last Name',
        'Gender',
        'ZIP',
        'Purchase History',
        'School',
        'Employer',
        'First Name',
        'Salary',
        'User ID',
        'Shoe Size',
      ],
      about: {
        companyName: 'Dynamic',
        legalName: 'Dynamic LLC',
        companyType: 'Public',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Dynamic Yield',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 10,
        },
        {
          month: 'Feb',
          riskScore: 10,
        },
      ],
    },
    {
      id: 'cc85d27e-9213-4e87-9cb9-1af4f3fee90e',
      name: 'Facebook Ads',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/facebookAds/0.3.5/070b6a0b83b2dcf836e31d9c4a978f92c9bc506f6ce46312e3988886fc715a60/logo.svg',
      summary: 'Consent controls not in place',
      riskScore: 20,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Browsing Trends',
        'Job Title',
        'Snacking Trends',
        'User ID',
        'First Name',
        'ZIP',
        'Gender',
        'Medical History',
        'Address',
        'Salary',
        'Purchase History',
        'SSN',
        'Last Name',
        'Employer',
        'Shoe Size',
        'School',
      ],
      about: {
        companyName: 'Facebook',
        legalName: 'Facebook LLC',
        companyType: 'Public',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Facebook Ads',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 18,
        },
        {
          month: 'Feb',
          riskScore: 20,
        },
      ],
    },
    {
      id: '6419e128-dc4c-4178-be8c-438e574f193e',
      name: 'Google Analytics',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/google_analytics/1.67.0/e6d5bb1e898ff06565aeb65d2a3e64e4fa67f3dfcda7b80af7bc8d2a8f40fab8/logo.svg',
      summary: 'Consent controls are not configured',
      riskScore: 45,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Browsing Trends',
        'Last Name',
        'Snacking Trends',
        'Employer',
        'Gender',
        'User ID',
        'Salary',
        'ZIP',
        'Shoe Size',
        'Medical History',
        'Address',
        'School',
        'Middle Initial',
        'Search History',
        'Email',
        'Job Title',
      ],
      about: {
        companyName: 'Google',
        legalName: 'Google LLC',
        companyType: 'Private',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Google Analytics',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 35,
        },
        {
          month: 'Feb',
          riskScore: 17,
        },
        {
          month: 'Mar',
          riskScore: 45,
        },
      ],
    },
    {
      id: 'ab844df7-195f-42bb-a2f4-39c0e91248b8',
      name: 'Google Marketing',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/google_marketing/0.2.5/f8040033f29d4fb6073a9f97dc3c01742253552e59829fea19464dbccd3e45ce/logo.svg',
      summary: 'Consent controls not in place',
      riskScore: 95,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Gender',
        'Salary',
        'Medical History',
        'Address',
        'Snacking Trends',
        'Browsing Trends',
        'Last Name',
        'First Name',
        'Employer',
        'Shoe Size',
        'SSN',
        'Job Title',
        'Email',
        'ZIP',
        'School',
        'Purchase History',
      ],
      about: {
        companyName: 'Google',
        legalName: 'Google Inc',
        companyType: 'Private',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Google Marketing',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 69,
        },
        {
          month: 'Feb',
          riskScore: 46,
        },
        {
          month: 'Mar',
          riskScore: 36,
        },
        {
          month: 'Apr',
          riskScore: 95,
        },
      ],
    },
    {
      id: '6b39d692-5851-4007-b783-c5eb316ca0ba',
      name: 'Heap Analytics',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/heap/1.66.0/4b4617e3609256f2c6287ca17579fb0557c938533a03ce0eb5a7697aac564363/logo.svg',
      summary: 'DSR and consent controls are not configured',
      riskScore: 73,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Job Title',
        'Salary',
        'Shoe Size',
        'Snacking Trends',
        'Middle Initial',
        'First Name',
        'Search History',
        'School',
        'ZIP',
        'Address',
        'Medical History',
        'Purchase History',
        'Employer',
        'User ID',
        'Gender',
        'Browsing Trends',
      ],
      about: {
        companyName: 'Heap',
        legalName: 'Heap Inc',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Heap Analytics',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 65,
        },
        {
          month: 'Feb',
          riskScore: 20,
        },
        {
          month: 'Mar',
          riskScore: 61,
        },
        {
          month: 'Apr',
          riskScore: 51,
        },
        {
          month: 'May',
          riskScore: 20,
        },
        {
          month: 'Jun',
          riskScore: 68,
        },
        {
          month: 'Jul',
          riskScore: 72,
        },
        {
          month: 'Aug',
          riskScore: 71,
        },
        {
          month: 'Sep',
          riskScore: 73,
        },
      ],
    },
    {
      id: '27889d0a-70d6-4175-b4f4-195ab744d097',
      name: 'Hubspot',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/hubspot/1.37.2/4110ba25f28a48bb93cc65baaf5e2ed4b9f2882b9a8d034a477ef440710fd8d6/logo.svg',
      summary: 'Consent controls not in place',
      riskScore: 85,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'User ID',
        'First Name',
        'Search History',
        'School',
        'Medical History',
        'Last Name',
        'Email',
        'Employer',
        'Middle Initial',
        'Snacking Trends',
        'Shoe Size',
        'Salary',
        'Gender',
        'Browsing Trends',
        'Job Title',
        'Address',
      ],
      about: {
        companyName: 'Hubspot',
        legalName: 'Hubspot Inc',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Hubspot',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 85,
        },
      ],
    },
    {
      id: '4245be6e-2e6c-4f68-984a-3cc0bdaef513',
      name: 'IAB CCPA',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/IAB%20CCPA/1.34.3/68e7482bee17f7a385ac19d5059a2f028ad4cb47ab7c2c75dec5bbde4e98c775/logo.svg',
      summary: 'Consent controls not in place',
      riskScore: 41,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Snacking Trends',
        'Email',
        'Address',
        'Middle Initial',
        'Employer',
        'Medical History',
        'Search History',
        'User ID',
        'Shoe Size',
        'Job Title',
        'SSN',
        'Purchase History',
        'Last Name',
        'Salary',
        'School',
        'Browsing Trends',
      ],
      about: {
        companyName: 'IAB',
        legalName: 'IAB LLC',
        companyType: 'Private',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/IAB CCPA',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 38,
        },
        {
          month: 'Feb',
          riskScore: 27,
        },
        {
          month: 'Mar',
          riskScore: 28,
        },
        {
          month: 'Apr',
          riskScore: 15,
        },
        {
          month: 'May',
          riskScore: 23,
        },
        {
          month: 'Jun',
          riskScore: 41,
        },
      ],
    },
    {
      id: 'b372a3c5-c093-42fa-833f-f40f9d1ad066',
      name: 'Judge.Me',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/judgeme/1.52.13/73094df637dd910e5110b32f468c8708324eeee64a359027eaa502a0afe517dc/logo.svg',
      summary: 'DSR and consent controls not in place',
      riskScore: 72,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Middle Initial',
        'Snacking Trends',
        'Search History',
        'Gender',
        'ZIP',
        'Medical History',
        'Job Title',
        'Employer',
        'Address',
        'Shoe Size',
        'Last Name',
        'User ID',
        'Browsing Trends',
        'Email',
        'Salary',
        'SSN',
      ],
      about: {
        companyName: 'Judge.Me',
        legalName: 'Judge.Me LLC',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Judge.Me',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 66,
        },
        {
          month: 'Feb',
          riskScore: 16,
        },
        {
          month: 'Mar',
          riskScore: 22,
        },
        {
          month: 'Apr',
          riskScore: 37,
        },
        {
          month: 'May',
          riskScore: 51,
        },
        {
          month: 'Jun',
          riskScore: 72,
        },
      ],
    },
    {
      id: 'd32ba71f-0fb0-4465-a048-8e2b9df53050',
      name: 'Ketch Sync',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/ketchSync/1.33.0/b743c5bd7f48e8d6aaa83d639516cc3a88ec8d32d9be5983bd79ba7919080ff4/logo.svg',
      summary: 'DSR controls not in place',
      riskScore: 50,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Search History',
        'Snacking Trends',
        'User ID',
        'Purchase History',
        'Medical History',
        'Browsing Trends',
        'Shoe Size',
        'School',
        'Salary',
        'ZIP',
        'Gender',
        'Email',
        'Last Name',
        'Middle Initial',
        'Job Title',
        'Employer',
      ],
      about: {
        companyName: 'Ketch',
        legalName: 'Ketch LLC',
        companyType: 'Public',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Ketch Sync',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 24,
        },
        {
          month: 'Feb',
          riskScore: 40,
        },
        {
          month: 'Mar',
          riskScore: 40,
        },
        {
          month: 'Apr',
          riskScore: 10,
        },
        {
          month: 'May',
          riskScore: 21,
        },
        {
          month: 'Jun',
          riskScore: 50,
        },
      ],
    },
    {
      id: '7c4bf014-8f5e-4dee-a9a6-a103a435cf0e',
      name: 'Klaviyo',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/klaviyo/1.65.1/c91f48270ccaf34608965deb1de0be2ed27b594f05e964df68191547cf2f6f51/logo.svg',
      summary: 'DSR controls are not configured',
      riskScore: 68,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'First Name',
        'Job Title',
        'Shoe Size',
        'Snacking Trends',
        'SSN',
        'Salary',
        'Purchase History',
        'School',
        'Employer',
        'Address',
        'Medical History',
        'User ID',
        'Last Name',
        'Search History',
        'ZIP',
        'Gender',
      ],
      about: {
        companyName: 'Klaviyo',
        legalName: 'Klaviyo Inc',
        companyType: 'Public',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Klaviyo',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 67,
        },
        {
          month: 'Feb',
          riskScore: 24,
        },
        {
          month: 'Mar',
          riskScore: 26,
        },
        {
          month: 'Apr',
          riskScore: 52,
        },
        {
          month: 'May',
          riskScore: 52,
        },
        {
          month: 'Jun',
          riskScore: 42,
        },
        {
          month: 'Jul',
          riskScore: 57,
        },
        {
          month: 'Aug',
          riskScore: 64,
        },
        {
          month: 'Sep',
          riskScore: 10,
        },
        {
          month: 'Oct',
          riskScore: 66,
        },
        {
          month: 'Nov',
          riskScore: 68,
        },
      ],
    },
    {
      id: 'd4cf6305-93e4-4c9b-b9bf-c792b604d570',
      name: 'LinkedIn',
      logo: 'https://transom.dev.b10s.io/transom/assets/ketch_apps/linkedin/0.7.12/3438e6ee6339f26ed2c8dca631e94fd080fa0ae78cdd60a5dd446b6fa2634804/logo.svg',
      summary: 'Consent controls not in place',
      riskScore: 17,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Address',
        'Gender',
        'Middle Initial',
        'School',
        'Email',
        'Purchase History',
        'Last Name',
        'Search History',
        'Medical History',
        'First Name',
        'Salary',
        'Browsing Trends',
        'Shoe Size',
        'SSN',
        'ZIP',
        'Job Title',
      ],
      about: {
        companyName: 'LinkedIn',
        legalName: 'LinkedIn LLC',
        companyType: 'Public',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/LinkedIn',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 14,
        },
        {
          month: 'Feb',
          riskScore: 13,
        },
        {
          month: 'Mar',
          riskScore: 10,
        },
        {
          month: 'Apr',
          riskScore: 10,
        },
        {
          month: 'May',
          riskScore: 13,
        },
        {
          month: 'Jun',
          riskScore: 16,
        },
        {
          month: 'Jul',
          riskScore: 11,
        },
        {
          month: 'Aug',
          riskScore: 17,
        },
      ],
    },
    {
      id: '01275ea7-2507-43ef-aac4-0dcdaa46adef',
      name: 'Mailchimp',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mailchimp/0.1.7/f63f0618a14024415529e19c636fe1d220e604f3c5fa2fd25e31b40455743f85/logo.svg',
      summary: 'DSR and consent controls not in place',
      riskScore: 46,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'First Name',
        'Browsing Trends',
        'ZIP',
        'Middle Initial',
        'Address',
        'Snacking Trends',
        'Job Title',
        'Medical History',
        'Search History',
        'Purchase History',
        'User ID',
        'SSN',
        'Email',
        'Shoe Size',
        'School',
      ],
      about: {
        companyName: 'Mailchimp',
        legalName: 'Mailchimp Inc',
        companyType: 'Private',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Mailchimp',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 43,
        },
        {
          month: 'Feb',
          riskScore: 35,
        },
        {
          month: 'Mar',
          riskScore: 15,
        },
        {
          month: 'Apr',
          riskScore: 28,
        },
        {
          month: 'May',
          riskScore: 16,
        },
        {
          month: 'Jun',
          riskScore: 10,
        },
        {
          month: 'Jul',
          riskScore: 46,
        },
      ],
    },
    {
      id: 'bcb1b546-f0fc-4459-936f-37d296b90824',
      name: 'Mailgun',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mailgun/1.55.0/4473a3ad43aaf70b65ab0bb5efbafafc33510c6301e6a5d551ca12804018a126/logo.svg',
      summary: 'DSR and consent controls not in place',
      riskScore: 77,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Email',
        'Last Name',
        'Address',
        'SSN',
        'Gender',
        'Salary',
        'Medical History',
        'First Name',
        'ZIP',
        'Snacking Trends',
        'User ID',
        'Middle Initial',
        'Browsing Trends',
        'Employer',
        'Purchase History',
        'Job Title',
      ],
      about: {
        companyName: 'Mailgun',
        legalName: 'Mailgun LLC',
        companyType: 'Private',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Mailgun',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 11,
        },
        {
          month: 'Feb',
          riskScore: 77,
        },
      ],
    },
    {
      id: '081544e1-7457-418f-a5b8-0d814ca4562e',
      name: 'MariaDB',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mariadb/0.2.0/9cdcc88126b726776cff6e9572faba7d85da6f09371226d19312e7bf49b02bee/logo.svg',
      summary: 'DSR and consent controls are not configured',
      riskScore: 67,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Email',
        'Purchase History',
        'Search History',
        'School',
        'Snacking Trends',
        'Last Name',
        'ZIP',
        'First Name',
        'Employer',
        'Salary',
        'Browsing Trends',
        'Gender',
        'User ID',
        'Address',
        'Middle Initial',
        'Job Title',
      ],
      about: {
        companyName: 'MariaDB',
        legalName: 'MariaDB LLC',
        companyType: 'Public',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/MariaDB',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 22,
        },
        {
          month: 'Feb',
          riskScore: 13,
        },
        {
          month: 'Mar',
          riskScore: 11,
        },
        {
          month: 'Apr',
          riskScore: 67,
        },
      ],
    },
    {
      id: '17347f50-62ee-4de7-bc5c-4d20ac186da3',
      name: 'Marketo',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/marketo/1.69.1/cc894a7df917e1a24c3eb44db101705ef4fcc33ab35aeb31c206ffd82a661ba2/logo.svg',
      summary: 'DSR controls are not configured',
      riskScore: 60,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Search History',
        'Gender',
        'ZIP',
        'Snacking Trends',
        'Email',
        'Shoe Size',
        'SSN',
        'School',
        'Medical History',
        'Address',
        'Middle Initial',
        'Job Title',
        'Salary',
        'Purchase History',
        'Last Name',
        'Employer',
      ],
      about: {
        companyName: 'Marketo',
        legalName: 'Marketo LLC',
        companyType: 'Private',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Marketo',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 31,
        },
        {
          month: 'Feb',
          riskScore: 10,
        },
        {
          month: 'Mar',
          riskScore: 22,
        },
        {
          month: 'Apr',
          riskScore: 46,
        },
        {
          month: 'May',
          riskScore: 30,
        },
        {
          month: 'Jun',
          riskScore: 39,
        },
        {
          month: 'Jul',
          riskScore: 12,
        },
        {
          month: 'Aug',
          riskScore: 33,
        },
        {
          month: 'Sep',
          riskScore: 28,
        },
        {
          month: 'Oct',
          riskScore: 60,
        },
      ],
    },
    {
      id: 'b91b1806-dc66-495a-974c-86c471bbb7e6',
      name: 'Mixpanel',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mixpanel/1.31.12/4e41ed839a7b516b43b3d32f89f735dc220fc29179dce975f6fa247b33637519/logo.svg',
      summary: 'DSR and consent controls are not configured',
      riskScore: 35,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'School',
        'User ID',
        'Middle Initial',
        'ZIP',
        'Medical History',
        'Email',
        'Salary',
        'Shoe Size',
        'Job Title',
        'SSN',
        'Gender',
        'Employer',
        'Search History',
        'Purchase History',
        'Browsing Trends',
        'Snacking Trends',
      ],
      about: {
        companyName: 'Mixpanel',
        legalName: 'Mixpanel Inc',
        companyType: 'Public',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Mixpanel',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 35,
        },
      ],
    },
    {
      id: '65643229-13f2-4675-b839-3b4c6b20eadb',
      name: 'MongoDB',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mongodb/0.2.1/22244e0f3c723b0f1a6d5fbfc45d9b60af8a0b6f3da7df10649f20933e098e10/logo.svg',
      summary: 'Consent controls are not configured',
      riskScore: 12,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Search History',
        'Shoe Size',
        'User ID',
        'Gender',
        'Last Name',
        'Middle Initial',
        'SSN',
        'School',
        'First Name',
        'ZIP',
        'Browsing Trends',
        'Salary',
        'Address',
        'Email',
        'Job Title',
        'Medical History',
      ],
      about: {
        companyName: 'MongoDB',
        legalName: 'MongoDB LLC',
        companyType: 'Private',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/MongoDB',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 11,
        },
        {
          month: 'Feb',
          riskScore: 10,
        },
        {
          month: 'Mar',
          riskScore: 11,
        },
        {
          month: 'Apr',
          riskScore: 12,
        },
        {
          month: 'May',
          riskScore: 10,
        },
        {
          month: 'Jun',
          riskScore: 12,
        },
      ],
    },
    {
      id: 'd917875c-3544-48e8-9163-740afce18f64',
      name: 'mParticle',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mParticle/1.61.5/12a4fd68aa81212ec798101c0ed03b04f112115f11ce66c03c8828a7f5546dd2/logo.svg',
      summary: 'Consent controls are not configured',
      riskScore: 55,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Middle Initial',
        'Browsing Trends',
        'Purchase History',
        'Email',
        'Employer',
        'Shoe Size',
        'SSN',
        'First Name',
        'Medical History',
        'Job Title',
        'Salary',
        'User ID',
        'ZIP',
        'Search History',
        'Snacking Trends',
        'School',
      ],
      about: {
        companyName: 'mParticle',
        legalName: 'mParticle Inc',
        companyType: 'Public',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/mParticle',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 31,
        },
        {
          month: 'Feb',
          riskScore: 35,
        },
        {
          month: 'Mar',
          riskScore: 12,
        },
        {
          month: 'Apr',
          riskScore: 44,
        },
        {
          month: 'May',
          riskScore: 47,
        },
        {
          month: 'Jun',
          riskScore: 14,
        },
        {
          month: 'Jul',
          riskScore: 32,
        },
        {
          month: 'Aug',
          riskScore: 11,
        },
        {
          month: 'Sep',
          riskScore: 15,
        },
        {
          month: 'Oct',
          riskScore: 34,
        },
        {
          month: 'Nov',
          riskScore: 55,
        },
      ],
    },
    {
      id: 'e9cf85d1-3f7a-4bac-962c-6362282945ba',
      name: 'Mysql',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mysql/0.1.2/52f2a07caa647d7cb97ded9618e13d02217b1581ff670dfb3d99942febb3d8c3/logo.svg',
      summary: 'Consent controls not in place',
      riskScore: 78,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Snacking Trends',
        'Gender',
        'ZIP',
        'Purchase History',
        'Job Title',
        'Search History',
        'Medical History',
        'Shoe Size',
        'Email',
        'User ID',
        'Employer',
        'First Name',
        'SSN',
        'Browsing Trends',
        'Salary',
        'Middle Initial',
      ],
      about: {
        companyName: 'Mysql',
        legalName: 'Mysql Inc',
        companyType: 'Public',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Mysql',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 60,
        },
        {
          month: 'Feb',
          riskScore: 10,
        },
        {
          month: 'Mar',
          riskScore: 37,
        },
        {
          month: 'Apr',
          riskScore: 75,
        },
        {
          month: 'May',
          riskScore: 78,
        },
      ],
    },
    {
      id: '07d34659-cbb4-41db-9d3b-5ecd4649632c',
      name: 'Optimizely',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/optimizely/1.69.0/bfd0c7d979fdeeeb08549dd1601d77e2ca01f1fe8a84f462a5bab6083f4b5289/logo.svg',
      summary: 'DSR controls are not configured',
      riskScore: 18,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Job Title',
        'Employer',
        'Address',
        'First Name',
        'User ID',
        'Medical History',
        'Search History',
        'Gender',
        'Snacking Trends',
        'Purchase History',
        'ZIP',
        'Browsing Trends',
        'Shoe Size',
        'School',
        'Middle Initial',
        'SSN',
      ],
      about: {
        companyName: 'Optimizely',
        legalName: 'Optimizely Inc',
        companyType: 'Private',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Optimizely',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 10,
        },
        {
          month: 'Feb',
          riskScore: 18,
        },
        {
          month: 'Mar',
          riskScore: 10,
        },
        {
          month: 'Apr',
          riskScore: 13,
        },
        {
          month: 'May',
          riskScore: 18,
        },
      ],
    },
    {
      id: '5d5fd219-1785-45d8-8ced-1f809e0a45cd',
      name: 'Oracle',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/oracle/0.2.0/e4213f519ef895c9c67171fafd0fd710830cf258707a245efeb8ba03874201ea/logo.svg',
      summary: 'Consent controls are not configured',
      riskScore: 68,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Middle Initial',
        'Gender',
        'Job Title',
        'Browsing Trends',
        'Medical History',
        'SSN',
        'Salary',
        'School',
        'Address',
        'Employer',
        'Snacking Trends',
        'First Name',
        'Purchase History',
        'User ID',
        'Email',
        'ZIP',
      ],
      about: {
        companyName: 'Oracle',
        legalName: 'Oracle LLC',
        companyType: 'Public',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Oracle',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 57,
        },
        {
          month: 'Feb',
          riskScore: 68,
        },
      ],
    },
    {
      id: '0ac983a1-f667-48be-b473-8149fb0505a8',
      name: 'Outreach',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/outreach/1.35.16/0d9a56b6326e3c66a60b4c700d259dd7bd42abbd41d75b1fc76cf3ecbed6114e/logo.svg',
      summary: 'Consent controls are not configured',
      riskScore: 31,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Shoe Size',
        'Search History',
        'Purchase History',
        'SSN',
        'Medical History',
        'Email',
        'Employer',
        'Gender',
        'School',
        'Address',
        'Salary',
        'Middle Initial',
        'First Name',
        'Browsing Trends',
        'User ID',
        'Job Title',
      ],
      about: {
        companyName: 'Outreach',
        legalName: 'Outreach Inc',
        companyType: 'Public',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Outreach',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 28,
        },
        {
          month: 'Feb',
          riskScore: 31,
        },
        {
          month: 'Mar',
          riskScore: 24,
        },
        {
          month: 'Apr',
          riskScore: 13,
        },
        {
          month: 'May',
          riskScore: 31,
        },
        {
          month: 'Jun',
          riskScore: 19,
        },
        {
          month: 'Jul',
          riskScore: 11,
        },
        {
          month: 'Aug',
          riskScore: 31,
        },
        {
          month: 'Sep',
          riskScore: 13,
        },
        {
          month: 'Oct',
          riskScore: 31,
        },
      ],
    },
    {
      id: '1071deb3-7afa-463f-91a7-b689eac0dadc',
      name: 'Pendo',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/pendo/1.60.1/3849a4eade7ee9fe84bfd5b75ab306d531548b9fc3d50db7f89e096ebace98de/logo.svg',
      summary: 'Consent controls are not configured',
      riskScore: 75,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Medical History',
        'Email',
        'Shoe Size',
        'Employer',
        'Browsing Trends',
        'SSN',
        'School',
        'Gender',
        'First Name',
        'Last Name',
        'Job Title',
        'Purchase History',
        'ZIP',
        'User ID',
        'Snacking Trends',
        'Address',
      ],
      about: {
        companyName: 'Pendo',
        legalName: 'Pendo LLC',
        companyType: 'Public',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Pendo',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 17,
        },
        {
          month: 'Feb',
          riskScore: 72,
        },
        {
          month: 'Mar',
          riskScore: 61,
        },
        {
          month: 'Apr',
          riskScore: 25,
        },
        {
          month: 'May',
          riskScore: 75,
        },
      ],
    },
    {
      id: 'a5784a7d-77d7-4ad3-96c5-5e92c9824450',
      name: 'Qualtrics',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/qualtrics/1.57.0/abecf7b66c92c687cdf647bfbab3c39cfe7458f06312a2007bb32f00b51504b5/logo.svg',
      summary: 'Consent controls not in place',
      riskScore: 88,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'SSN',
        'Gender',
        'Employer',
        'Last Name',
        'Browsing Trends',
        'Salary',
        'Snacking Trends',
        'Purchase History',
        'Search History',
        'First Name',
        'School',
        'Middle Initial',
        'Email',
        'Address',
        'ZIP',
        'Medical History',
      ],
      about: {
        companyName: 'Qualtrics',
        legalName: 'Qualtrics LLC',
        companyType: 'Public',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Qualtrics',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 66,
        },
        {
          month: 'Feb',
          riskScore: 24,
        },
        {
          month: 'Mar',
          riskScore: 64,
        },
        {
          month: 'Apr',
          riskScore: 88,
        },
      ],
    },
    {
      id: 'f1912870-d086-4eff-8582-e0cf058161fc',
      name: 'Redshift',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/redshift/0.0.9/8081c937186086c8a29a34f90314e93bd03e7a3d3f462e15f8c2847125123c83/logo.svg',
      summary: 'DSR controls not in place',
      riskScore: 57,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Middle Initial',
        'Medical History',
        'Job Title',
        'Last Name',
        'Email',
        'Address',
        'Employer',
        'Snacking Trends',
        'ZIP',
        'SSN',
        'Search History',
        'Salary',
        'Shoe Size',
        'School',
        'Gender',
        'First Name',
      ],
      about: {
        companyName: 'Redshift',
        legalName: 'Redshift LLC',
        companyType: 'Public',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Redshift',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 31,
        },
        {
          month: 'Feb',
          riskScore: 27,
        },
        {
          month: 'Mar',
          riskScore: 47,
        },
        {
          month: 'Apr',
          riskScore: 54,
        },
        {
          month: 'May',
          riskScore: 16,
        },
        {
          month: 'Jun',
          riskScore: 28,
        },
        {
          month: 'Jul',
          riskScore: 47,
        },
        {
          month: 'Aug',
          riskScore: 57,
        },
      ],
    },
    {
      id: 'ccbcd74d-ae79-4940-bf9f-9c1851eaa96a',
      name: 'SalesForce Sales Cloud CRM',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/salesforce/1.67.1/3cf821829c46c38f711c58f3ff9bd55a64f5ffa2df37abc186a5a277910cb38f/logo.svg',
      summary: 'Consent controls not in place',
      riskScore: 64,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'First Name',
        'Snacking Trends',
        'Last Name',
        'Shoe Size',
        'Email',
        'SSN',
        'Address',
        'ZIP',
        'User ID',
        'Browsing Trends',
        'School',
        'Middle Initial',
        'Gender',
        'Job Title',
        'Search History',
        'Employer',
      ],
      about: {
        companyName: 'SalesForce',
        legalName: 'SalesForce Inc',
        companyType: 'Public',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/SalesForce Sales Cloud CRM',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 64,
        },
      ],
    },
    {
      id: '4a6754ef-3433-4cc8-be3b-0552924b6452',
      name: 'Segment',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/segment/1.64.3/40b3fb60e63585e86ebb4bc0e16efbb3ab6e104676560f522a79508378e99934/logo.svg',
      summary: 'Consent controls are not configured',
      riskScore: 24,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Snacking Trends',
        'Last Name',
        'SSN',
        'Email',
        'Shoe Size',
        'Middle Initial',
        'Medical History',
        'ZIP',
        'First Name',
        'Purchase History',
        'Employer',
        'Search History',
        'Address',
        'User ID',
        'Browsing Trends',
        'School',
      ],
      about: {
        companyName: 'Segment',
        legalName: 'Segment Inc',
        companyType: 'Public',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Segment',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 20,
        },
        {
          month: 'Feb',
          riskScore: 24,
        },
        {
          month: 'Mar',
          riskScore: 12,
        },
        {
          month: 'Apr',
          riskScore: 23,
        },
        {
          month: 'May',
          riskScore: 10,
        },
        {
          month: 'Jun',
          riskScore: 24,
        },
      ],
    },
    {
      id: 'fdff5daf-7cb1-4297-acd2-e453c7ef7871',
      name: 'SendGrid',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/sendgrid/1.61.0/14f2c3388777ef052768264e52c7ff368f96a8336f885817e93f224a3b563565/logo.svg',
      summary: 'DSR controls are not configured',
      riskScore: 58,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Salary',
        'Last Name',
        'Search History',
        'User ID',
        'Email',
        'Middle Initial',
        'Address',
        'First Name',
        'ZIP',
        'Gender',
        'Browsing Trends',
        'Snacking Trends',
        'SSN',
        'Shoe Size',
        'Employer',
        'School',
      ],
      about: {
        companyName: 'SendGrid',
        legalName: 'SendGrid Inc',
        companyType: 'Private',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/SendGrid',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 30,
        },
        {
          month: 'Feb',
          riskScore: 58,
        },
      ],
    },
    {
      id: '5604b8d3-b2e8-4d26-a3ec-efad4dfda9bd',
      name: 'shopify',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/shopify/1.69.3/245d99328546426e7403286aa021e4d21684fffd90419dd46a77b69432567d37/logo.svg',
      summary: 'DSR and consent controls not in place',
      riskScore: 91,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Search History',
        'Address',
        'Employer',
        'Middle Initial',
        'Shoe Size',
        'Gender',
        'ZIP',
        'Salary',
        'Browsing Trends',
        'SSN',
        'Medical History',
        'Purchase History',
        'Last Name',
        'User ID',
        'Job Title',
        'Email',
      ],
      about: {
        companyName: 'shopify',
        legalName: 'shopify LLC',
        companyType: 'Private',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/shopify',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 86,
        },
        {
          month: 'Feb',
          riskScore: 91,
        },
        {
          month: 'Mar',
          riskScore: 63,
        },
        {
          month: 'Apr',
          riskScore: 48,
        },
        {
          month: 'May',
          riskScore: 45,
        },
        {
          month: 'Jun',
          riskScore: 24,
        },
        {
          month: 'Jul',
          riskScore: 42,
        },
        {
          month: 'Aug',
          riskScore: 41,
        },
        {
          month: 'Sep',
          riskScore: 62,
        },
        {
          month: 'Oct',
          riskScore: 91,
        },
      ],
    },
    {
      id: 'b293618d-8986-47aa-8568-ad5b912b7496',
      name: 'Snapchat',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/snapchat/1.35.11/8eec3bcc0a619675b10e3919e6b7f618941598352fadf159390212e407cd0c53/logo.svg',
      summary: 'DSR and consent controls are not configured',
      riskScore: 57,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Address',
        'Shoe Size',
        'Employer',
        'Purchase History',
        'ZIP',
        'User ID',
        'Salary',
        'Email',
        'SSN',
        'Medical History',
        'Last Name',
        'Search History',
        'Browsing Trends',
        'First Name',
        'Gender',
        'School',
      ],
      about: {
        companyName: 'Snapchat',
        legalName: 'Snapchat LLC',
        companyType: 'Public',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Snapchat',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 50,
        },
        {
          month: 'Feb',
          riskScore: 55,
        },
        {
          month: 'Mar',
          riskScore: 28,
        },
        {
          month: 'Apr',
          riskScore: 37,
        },
        {
          month: 'May',
          riskScore: 57,
        },
      ],
    },
    {
      id: '1bd990be-7e5b-49a4-95cc-1010f107a8e6',
      name: 'Snowflake',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/snowflake/0.1.1/53f5298918795ca2332e8e91428d1aed9e5deee03c017bea70c53290aa5c0dac/logo.svg',
      summary: 'DSR and consent controls not in place',
      riskScore: 97,
      riskCategory: 'Extreme',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Snacking Trends',
        'Medical History',
        'Job Title',
        'Gender',
        'Shoe Size',
        'Address',
        'Email',
        'User ID',
        'First Name',
        'Browsing Trends',
        'ZIP',
        'Salary',
        'Purchase History',
        'Employer',
        'Middle Initial',
        'Last Name',
      ],
      about: {
        companyName: 'Snowflake',
        legalName: 'Snowflake LLC',
        companyType: 'Public',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Snowflake',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 78,
        },
        {
          month: 'Feb',
          riskScore: 93,
        },
        {
          month: 'Mar',
          riskScore: 22,
        },
        {
          month: 'Apr',
          riskScore: 53,
        },
        {
          month: 'May',
          riskScore: 27,
        },
        {
          month: 'Jun',
          riskScore: 97,
        },
      ],
    },
    {
      id: '8aecce74-1095-4ffb-903b-280ca9ce94cd',
      name: 'Splash',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/splash/1.61.9/30d65b321478aef840a592663043729715c1a06c7b9a379b7ca53c10b05d9104/logo.svg',
      summary: 'Consent controls not in place',
      riskScore: 17,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'School',
        'First Name',
        'Snacking Trends',
        'Search History',
        'Salary',
        'Medical History',
        'User ID',
        'Purchase History',
        'Shoe Size',
        'Address',
        'Middle Initial',
        'Browsing Trends',
        'Employer',
        'SSN',
        'ZIP',
      ],
      about: {
        companyName: 'Splash',
        legalName: 'Splash Inc',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Splash',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 15,
        },
        {
          month: 'Feb',
          riskScore: 14,
        },
        {
          month: 'Mar',
          riskScore: 16,
        },
        {
          month: 'Apr',
          riskScore: 17,
        },
        {
          month: 'May',
          riskScore: 12,
        },
        {
          month: 'Jun',
          riskScore: 13,
        },
        {
          month: 'Jul',
          riskScore: 16,
        },
        {
          month: 'Aug',
          riskScore: 10,
        },
        {
          month: 'Sep',
          riskScore: 17,
        },
      ],
    },
    {
      id: '4083f685-4a5d-45c7-a6db-fdfb5cd6050d',
      name: 'TikTok Ads',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/tiktok_ads/1.30.9/c5d49f16d222d036ff865b7a2a4de7a321f8cdc8c3c7aedc31e6667c73fbab03/logo.svg',
      summary: 'Consent controls not in place',
      riskScore: 77,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Medical History',
        'Address',
        'School',
        'Employer',
        'SSN',
        'Middle Initial',
        'First Name',
        'Shoe Size',
        'Email',
        'User ID',
        'ZIP',
        'Job Title',
        'Snacking Trends',
        'Search History',
        'Browsing Trends',
        'Last Name',
      ],
      about: {
        companyName: 'TikTok',
        legalName: 'TikTok LLC',
        companyType: 'Private',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/TikTok Ads',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 19,
        },
        {
          month: 'Feb',
          riskScore: 24,
        },
        {
          month: 'Mar',
          riskScore: 66,
        },
        {
          month: 'Apr',
          riskScore: 60,
        },
        {
          month: 'May',
          riskScore: 30,
        },
        {
          month: 'Jun',
          riskScore: 44,
        },
        {
          month: 'Jul',
          riskScore: 43,
        },
        {
          month: 'Aug',
          riskScore: 77,
        },
      ],
    },
    {
      id: '4fa631b2-e3d0-4b39-bbd1-ea611ee3daee',
      name: 'Twitter Ads',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/twitter_ads/0.1.4/49bc251000bbeae7a6c1ec3bdfd0fbf7c7c3cc49d30c1ff27a504d213c870cac/logo.svg',
      summary: 'DSR controls are not configured',
      riskScore: 59,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Search History',
        'School',
        'Gender',
        'Medical History',
        'Job Title',
        'Employer',
        'Address',
        'Shoe Size',
        'ZIP',
        'User ID',
        'First Name',
        'Salary',
        'Snacking Trends',
        'SSN',
        'Middle Initial',
        'Last Name',
      ],
      about: {
        companyName: 'Twitter',
        legalName: 'Twitter LLC',
        companyType: 'Public',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Twitter Ads',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 39,
        },
        {
          month: 'Feb',
          riskScore: 51,
        },
        {
          month: 'Mar',
          riskScore: 16,
        },
        {
          month: 'Apr',
          riskScore: 40,
        },
        {
          month: 'May',
          riskScore: 21,
        },
        {
          month: 'Jun',
          riskScore: 34,
        },
        {
          month: 'Jul',
          riskScore: 59,
        },
      ],
    },
    {
      id: 'adafd0bc-7cf4-4c31-8816-1c21a5500e6c',
      name: 'Yotpo/Swell',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/swell/1.31.10/271c7b1a26b1350ac571312befb8271ae6b04cd1aa03ae65fcf7ac2c78006795/logo.svg',
      summary: 'DSR and consent controls not in place',
      riskScore: 74,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Salary',
        'Snacking Trends',
        'Shoe Size',
        'Medical History',
        'Email',
        'SSN',
        'School',
        'Browsing Trends',
        'Purchase History',
        'Address',
        'ZIP',
        'User ID',
        'Middle Initial',
        'First Name',
        'Gender',
        'Last Name',
      ],
      about: {
        companyName: 'Yotpo/Swell',
        legalName: 'Yotpo/Swell LLC',
        companyType: 'Private',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Yotpo/Swell',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 12,
        },
        {
          month: 'Feb',
          riskScore: 68,
        },
        {
          month: 'Mar',
          riskScore: 10,
        },
        {
          month: 'Apr',
          riskScore: 74,
        },
      ],
    },
  ],
  totalResults: 41,
}

export const unKnownTransfersMockData = {
  unknownTransfers: [
    {
      id: 'e298956f-041a-496f-a6f3-d5127315f020',
      name: 'Alchemer',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/alchemer/1.52.12/118e1f61835e65132ec7111429644922b490fb14cc87a983f6dd9043803f172b/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 29,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'SSN',
        'Middle Initial',
        'Salary',
        'Medical History',
        'First Name',
        'School',
        'ZIP',
        'Snacking Trends',
        'Last Name',
        'Purchase History',
        'Employer',
        'Address',
        'Job Title',
        'Email',
        'Browsing Trends',
        'User ID',
      ],
      about: {
        companyName: 'Alchemer',
        legalName: 'Alchemer Inc',
        companyType: 'Public',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Alchemer',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 23,
        },
        {
          month: 'Feb',
          riskScore: 24,
        },
        {
          month: 'Mar',
          riskScore: 14,
        },
        {
          month: 'Apr',
          riskScore: 25,
        },
        {
          month: 'May',
          riskScore: 23,
        },
        {
          month: 'Jun',
          riskScore: 27,
        },
        {
          month: 'Jul',
          riskScore: 24,
        },
        {
          month: 'Aug',
          riskScore: 17,
        },
        {
          month: 'Sep',
          riskScore: 23,
        },
        {
          month: 'Oct',
          riskScore: 29,
        },
      ],
    },
    {
      id: 'f3cfeae7-bb11-49ea-bfaa-dadc37ad57d5',
      name: 'Amazon Redshift',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/amazon-redshift/0.0.7/8081c937186086c8a29a34f90314e93bd03e7a3d3f462e15f8c2847125123c83/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 68,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'First Name',
        'Browsing Trends',
        'Gender',
        'Medical History',
        'Address',
        'Middle Initial',
        'User ID',
        'School',
        'Purchase History',
        'SSN',
        'Last Name',
        'Search History',
        'Shoe Size',
        'Email',
        'ZIP',
        'Employer',
      ],
      about: {
        companyName: 'Amazon',
        legalName: 'Amazon LLC',
        companyType: 'Public',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Amazon Redshift',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 19,
        },
        {
          month: 'Feb',
          riskScore: 17,
        },
        {
          month: 'Mar',
          riskScore: 14,
        },
        {
          month: 'Apr',
          riskScore: 59,
        },
        {
          month: 'May',
          riskScore: 28,
        },
        {
          month: 'Jun',
          riskScore: 16,
        },
        {
          month: 'Jul',
          riskScore: 68,
        },
      ],
    },
    {
      id: 'c7994bd8-56d1-4a7e-99f2-a811c71eee0e',
      name: 'Amazon S3',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/amazon-s3/0.1.2/bf3a16681cf66282c50d4d46c5521b6aab479f0181831e7102f6dc5fe9fe0159/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 52,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'Purchase History',
        'Gender',
        'ZIP',
        'Email',
        'User ID',
        'Snacking Trends',
        'Shoe Size',
        'SSN',
        'Salary',
        'School',
        'Browsing Trends',
        'Address',
        'Middle Initial',
        'First Name',
        'Search History',
      ],
      about: {
        companyName: 'Amazon',
        legalName: 'Amazon LLC',
        companyType: 'Public',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Amazon S3',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 34,
        },
        {
          month: 'Feb',
          riskScore: 24,
        },
        {
          month: 'Mar',
          riskScore: 29,
        },
        {
          month: 'Apr',
          riskScore: 50,
        },
        {
          month: 'May',
          riskScore: 10,
        },
        {
          month: 'Jun',
          riskScore: 47,
        },
        {
          month: 'Jul',
          riskScore: 52,
        },
      ],
    },
    {
      id: '60c88501-59f2-4f2c-bf56-26301e70a4e5',
      name: 'Autopilot',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/autopilot/1.30.2/fab75217ad0e46c26e83faed39e153512b11c84b186930820ba1881457c54db0/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 56,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Salary',
        'Address',
        'Medical History',
        'Email',
        'Last Name',
        'SSN',
        'School',
        'Purchase History',
        'ZIP',
        'First Name',
        'Shoe Size',
        'Job Title',
        'Employer',
        'User ID',
        'Snacking Trends',
        'Search History',
      ],
      about: {
        companyName: 'Autopilot',
        legalName: 'Autopilot LLC',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Autopilot',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 42,
        },
        {
          month: 'Feb',
          riskScore: 37,
        },
        {
          month: 'Mar',
          riskScore: 37,
        },
        {
          month: 'Apr',
          riskScore: 13,
        },
        {
          month: 'May',
          riskScore: 27,
        },
        {
          month: 'Jun',
          riskScore: 48,
        },
        {
          month: 'Jul',
          riskScore: 41,
        },
        {
          month: 'Aug',
          riskScore: 23,
        },
        {
          month: 'Sep',
          riskScore: 42,
        },
        {
          month: 'Oct',
          riskScore: 32,
        },
        {
          month: 'Nov',
          riskScore: 56,
        },
      ],
    },
    {
      id: 'fe154d4c-261f-40c9-8705-006cb600a50e',
      name: 'Coveo',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/coveo/1.52.11/bd0bf487bc4668468b1524c80befe2eca7bef07fa1cc0b5b306e773132a3dd4f/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 96,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'First Name',
        'Middle Initial',
        'Snacking Trends',
        'Medical History',
        'Job Title',
        'Employer',
        'SSN',
        'Gender',
        'Address',
        'ZIP',
        'School',
        'Search History',
        'User ID',
        'Browsing Trends',
        'Email',
        'Salary',
      ],
      about: {
        companyName: 'Coveo',
        legalName: 'Coveo LLC',
        companyType: 'Public',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Coveo',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 11,
        },
        {
          month: 'Feb',
          riskScore: 28,
        },
        {
          month: 'Mar',
          riskScore: 66,
        },
        {
          month: 'Apr',
          riskScore: 28,
        },
        {
          month: 'May',
          riskScore: 55,
        },
        {
          month: 'Jun',
          riskScore: 32,
        },
        {
          month: 'Jul',
          riskScore: 52,
        },
        {
          month: 'Aug',
          riskScore: 74,
        },
        {
          month: 'Sep',
          riskScore: 33,
        },
        {
          month: 'Oct',
          riskScore: 96,
        },
      ],
    },
    {
      id: 'f327d283-0c7e-47d1-baec-249284c3bebc',
      name: 'Discourse',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/discourse/1.12.2/b6de2df7d452b15fc0290ec3e4a336ff22ebb231bed8ab17a6f69f9393402484/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 52,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'First Name',
        'Email',
        'School',
        'User ID',
        'Search History',
        'Gender',
        'ZIP',
        'Last Name',
        'Medical History',
        'Purchase History',
        'Job Title',
        'Employer',
        'Middle Initial',
        'Salary',
        'Shoe Size',
        'Snacking Trends',
      ],
      about: {
        companyName: 'Discourse',
        legalName: 'Discourse LLC',
        companyType: 'Private',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Discourse',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 41,
        },
        {
          month: 'Feb',
          riskScore: 33,
        },
        {
          month: 'Mar',
          riskScore: 47,
        },
        {
          month: 'Apr',
          riskScore: 42,
        },
        {
          month: 'May',
          riskScore: 52,
        },
      ],
    },
    {
      id: '82e59867-8b35-442e-9ebf-925b482a08f4',
      name: 'Dynamic Yield',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/dynamic_yield/1.31.8/21ff737a40f023ba89c5f89fa5b33ddfe4e27f8a6e52d75bf58160944262b550/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 75,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Gender',
        'Email',
        'Snacking Trends',
        'ZIP',
        'Medical History',
        'SSN',
        'User ID',
        'Employer',
        'School',
        'Purchase History',
        'Middle Initial',
        'Salary',
        'First Name',
        'Last Name',
        'Search History',
        'Browsing Trends',
      ],
      about: {
        companyName: 'Dynamic',
        legalName: 'Dynamic LLC',
        companyType: 'Private',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Dynamic Yield',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 13,
        },
        {
          month: 'Feb',
          riskScore: 60,
        },
        {
          month: 'Mar',
          riskScore: 47,
        },
        {
          month: 'Apr',
          riskScore: 75,
        },
      ],
    },
    {
      id: '51502059-a4ad-47ca-b19e-ddb476719996',
      name: 'Facebook Ads',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/facebookAds/0.3.5/070b6a0b83b2dcf836e31d9c4a978f92c9bc506f6ce46312e3988886fc715a60/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 53,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'ZIP',
        'Browsing Trends',
        'Snacking Trends',
        'Job Title',
        'Last Name',
        'Gender',
        'Email',
        'User ID',
        'Address',
        'Employer',
        'Shoe Size',
        'SSN',
        'Purchase History',
        'Salary',
        'First Name',
        'Medical History',
      ],
      about: {
        companyName: 'Facebook',
        legalName: 'Facebook LLC',
        companyType: 'Public',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Facebook Ads',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 41,
        },
        {
          month: 'Feb',
          riskScore: 50,
        },
        {
          month: 'Mar',
          riskScore: 53,
        },
        {
          month: 'Apr',
          riskScore: 28,
        },
        {
          month: 'May',
          riskScore: 53,
        },
      ],
    },
    {
      id: 'f983557d-9e5f-4120-99a3-30aea3e85fba',
      name: 'Google Analytics',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/google_analytics/1.67.0/e6d5bb1e898ff06565aeb65d2a3e64e4fa67f3dfcda7b80af7bc8d2a8f40fab8/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 67,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'User ID',
        'Middle Initial',
        'ZIP',
        'Job Title',
        'Email',
        'Employer',
        'Medical History',
        'Browsing Trends',
        'Shoe Size',
        'School',
        'Salary',
        'First Name',
        'SSN',
        'Gender',
        'Search History',
        'Snacking Trends',
      ],
      about: {
        companyName: 'Google',
        legalName: 'Google LLC',
        companyType: 'Public',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Google Analytics',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 40,
        },
        {
          month: 'Feb',
          riskScore: 24,
        },
        {
          month: 'Mar',
          riskScore: 26,
        },
        {
          month: 'Apr',
          riskScore: 33,
        },
        {
          month: 'May',
          riskScore: 60,
        },
        {
          month: 'Jun',
          riskScore: 39,
        },
        {
          month: 'Jul',
          riskScore: 56,
        },
        {
          month: 'Aug',
          riskScore: 27,
        },
        {
          month: 'Sep',
          riskScore: 66,
        },
        {
          month: 'Oct',
          riskScore: 67,
        },
        {
          month: 'Nov',
          riskScore: 67,
        },
      ],
    },
    {
      id: 'ac3610d6-fcf1-4f14-96d5-bd7aaeaa2d07',
      name: 'Google Marketing',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/google_marketing/0.2.5/f8040033f29d4fb6073a9f97dc3c01742253552e59829fea19464dbccd3e45ce/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 43,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Middle Initial',
        'Browsing Trends',
        'Employer',
        'ZIP',
        'User ID',
        'Job Title',
        'SSN',
        'Medical History',
        'Search History',
        'Shoe Size',
        'Salary',
        'First Name',
        'School',
        'Last Name',
        'Purchase History',
        'Email',
      ],
      about: {
        companyName: 'Google',
        legalName: 'Google Inc',
        companyType: 'Private',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Google Marketing',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 33,
        },
        {
          month: 'Feb',
          riskScore: 33,
        },
        {
          month: 'Mar',
          riskScore: 14,
        },
        {
          month: 'Apr',
          riskScore: 27,
        },
        {
          month: 'May',
          riskScore: 25,
        },
        {
          month: 'Jun',
          riskScore: 16,
        },
        {
          month: 'Jul',
          riskScore: 31,
        },
        {
          month: 'Aug',
          riskScore: 32,
        },
        {
          month: 'Sep',
          riskScore: 29,
        },
        {
          month: 'Oct',
          riskScore: 13,
        },
        {
          month: 'Nov',
          riskScore: 19,
        },
        {
          month: 'Dec',
          riskScore: 43,
        },
      ],
    },
    {
      id: '352f38da-e983-44b6-ae4e-b8d207b5ecdb',
      name: 'Heap Analytics',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/heap/1.66.0/4b4617e3609256f2c6287ca17579fb0557c938533a03ce0eb5a7697aac564363/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 51,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'SSN',
        'Salary',
        'First Name',
        'Snacking Trends',
        'Middle Initial',
        'User ID',
        'Address',
        'Job Title',
        'School',
        'Gender',
        'Purchase History',
        'Last Name',
        'Shoe Size',
        'Browsing Trends',
        'Medical History',
        'Email',
      ],
      about: {
        companyName: 'Heap',
        legalName: 'Heap Inc',
        companyType: 'Private',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Heap Analytics',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 30,
        },
        {
          month: 'Feb',
          riskScore: 14,
        },
        {
          month: 'Mar',
          riskScore: 49,
        },
        {
          month: 'Apr',
          riskScore: 48,
        },
        {
          month: 'May',
          riskScore: 38,
        },
        {
          month: 'Jun',
          riskScore: 15,
        },
        {
          month: 'Jul',
          riskScore: 51,
        },
      ],
    },
    {
      id: '0ee55432-f6a5-4e4f-829b-3f963baf1aec',
      name: 'Hubspot',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/hubspot/1.37.2/4110ba25f28a48bb93cc65baaf5e2ed4b9f2882b9a8d034a477ef440710fd8d6/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 94,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Middle Initial',
        'Snacking Trends',
        'Browsing Trends',
        'Last Name',
        'Medical History',
        'ZIP',
        'School',
        'User ID',
        'Shoe Size',
        'Email',
        'Job Title',
        'Salary',
        'Address',
        'Gender',
        'SSN',
        'Search History',
      ],
      about: {
        companyName: 'Hubspot',
        legalName: 'Hubspot Inc',
        companyType: 'Public',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Hubspot',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 64,
        },
        {
          month: 'Feb',
          riskScore: 32,
        },
        {
          month: 'Mar',
          riskScore: 63,
        },
        {
          month: 'Apr',
          riskScore: 94,
        },
      ],
    },
    {
      id: '2b353912-a62a-46da-afa7-6a33107a173e',
      name: 'IAB CCPA',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/IAB%20CCPA/1.34.3/68e7482bee17f7a385ac19d5059a2f028ad4cb47ab7c2c75dec5bbde4e98c775/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 77,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Medical History',
        'Shoe Size',
        'Employer',
        'Job Title',
        'SSN',
        'Purchase History',
        'User ID',
        'Email',
        'First Name',
        'Salary',
        'Search History',
        'Address',
        'Last Name',
        'Middle Initial',
        'Snacking Trends',
        'School',
      ],
      about: {
        companyName: 'IAB',
        legalName: 'IAB Inc',
        companyType: 'Public',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/IAB CCPA',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 67,
        },
        {
          month: 'Feb',
          riskScore: 69,
        },
        {
          month: 'Mar',
          riskScore: 77,
        },
      ],
    },
    {
      id: 'a52f7b62-ba72-47f9-abc0-fd634bf303cc',
      name: 'Judge.Me',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/judgeme/1.52.13/73094df637dd910e5110b32f468c8708324eeee64a359027eaa502a0afe517dc/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 43,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'First Name',
        'Medical History',
        'Purchase History',
        'Last Name',
        'Gender',
        'Salary',
        'Employer',
        'Job Title',
        'User ID',
        'ZIP',
        'Snacking Trends',
        'Middle Initial',
        'Email',
        'Browsing Trends',
        'School',
        'Shoe Size',
      ],
      about: {
        companyName: 'Judge.Me',
        legalName: 'Judge.Me Inc',
        companyType: 'Public',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Judge.Me',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 31,
        },
        {
          month: 'Feb',
          riskScore: 43,
        },
      ],
    },
    {
      id: '26c1a525-6228-44e9-ada0-625bae77a4d9',
      name: 'Ketch Sync',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/ketchSync/1.33.0/b743c5bd7f48e8d6aaa83d639516cc3a88ec8d32d9be5983bd79ba7919080ff4/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 99,
      riskCategory: 'Extreme',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'ZIP',
        'Employer',
        'Last Name',
        'Snacking Trends',
        'First Name',
        'Medical History',
        'Browsing Trends',
        'Gender',
        'Middle Initial',
        'School',
        'Search History',
        'Shoe Size',
        'SSN',
        'Salary',
        'Job Title',
        'Address',
      ],
      about: {
        companyName: 'Ketch',
        legalName: 'Ketch Inc',
        companyType: 'Public',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Ketch Sync',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 34,
        },
        {
          month: 'Feb',
          riskScore: 99,
        },
      ],
    },
    {
      id: '636efe38-5157-4a9a-b970-55e39d798cd6',
      name: 'Klaviyo',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/klaviyo/1.65.1/c91f48270ccaf34608965deb1de0be2ed27b594f05e964df68191547cf2f6f51/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 52,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Medical History',
        'First Name',
        'Purchase History',
        'School',
        'Job Title',
        'SSN',
        'Gender',
        'Snacking Trends',
        'Address',
        'Employer',
        'Shoe Size',
        'Browsing Trends',
        'Salary',
        'Middle Initial',
        'User ID',
        'Email',
      ],
      about: {
        companyName: 'Klaviyo',
        legalName: 'Klaviyo LLC',
        companyType: 'Private',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Klaviyo',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 29,
        },
        {
          month: 'Feb',
          riskScore: 16,
        },
        {
          month: 'Mar',
          riskScore: 45,
        },
        {
          month: 'Apr',
          riskScore: 46,
        },
        {
          month: 'May',
          riskScore: 20,
        },
        {
          month: 'Jun',
          riskScore: 11,
        },
        {
          month: 'Jul',
          riskScore: 22,
        },
        {
          month: 'Aug',
          riskScore: 52,
        },
      ],
    },
    {
      id: '97254470-d167-4c5f-8d28-4f2717a119d5',
      name: 'LinkedIn',
      logo: 'https://transom.dev.b10s.io/transom/assets/ketch_apps/linkedin/0.7.12/3438e6ee6339f26ed2c8dca631e94fd080fa0ae78cdd60a5dd446b6fa2634804/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 35,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Email',
        'Last Name',
        'Purchase History',
        'Browsing Trends',
        'Job Title',
        'Salary',
        'Medical History',
        'Address',
        'First Name',
        'ZIP',
        'Middle Initial',
        'Shoe Size',
        'Gender',
        'School',
        'User ID',
        'Search History',
      ],
      about: {
        companyName: 'LinkedIn',
        legalName: 'LinkedIn Inc',
        companyType: 'Public',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/LinkedIn',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 35,
        },
      ],
    },
    {
      id: '52a9b3ad-bc71-4938-9f3c-037d0b080543',
      name: 'Mailchimp',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mailchimp/0.1.7/f63f0618a14024415529e19c636fe1d220e604f3c5fa2fd25e31b40455743f85/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 87,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Email',
        'School',
        'Middle Initial',
        'Purchase History',
        'SSN',
        'Address',
        'Salary',
        'Shoe Size',
        'Job Title',
        'Gender',
        'Last Name',
        'Browsing Trends',
        'Snacking Trends',
        'Employer',
        'Search History',
        'ZIP',
      ],
      about: {
        companyName: 'Mailchimp',
        legalName: 'Mailchimp Inc',
        companyType: 'Private',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Mailchimp',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 32,
        },
        {
          month: 'Feb',
          riskScore: 39,
        },
        {
          month: 'Mar',
          riskScore: 43,
        },
        {
          month: 'Apr',
          riskScore: 15,
        },
        {
          month: 'May',
          riskScore: 85,
        },
        {
          month: 'Jun',
          riskScore: 16,
        },
        {
          month: 'Jul',
          riskScore: 32,
        },
        {
          month: 'Aug',
          riskScore: 65,
        },
        {
          month: 'Sep',
          riskScore: 75,
        },
        {
          month: 'Oct',
          riskScore: 18,
        },
        {
          month: 'Nov',
          riskScore: 56,
        },
        {
          month: 'Dec',
          riskScore: 87,
        },
      ],
    },
    {
      id: '1d8fc7eb-b72e-4444-bea9-ba459f18281a',
      name: 'Mailgun',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mailgun/1.55.0/4473a3ad43aaf70b65ab0bb5efbafafc33510c6301e6a5d551ca12804018a126/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 65,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'First Name',
        'Snacking Trends',
        'Shoe Size',
        'ZIP',
        'SSN',
        'Salary',
        'School',
        'Browsing Trends',
        'User ID',
        'Purchase History',
        'Job Title',
        'Middle Initial',
        'Gender',
        'Search History',
        'Email',
        'Address',
      ],
      about: {
        companyName: 'Mailgun',
        legalName: 'Mailgun LLC',
        companyType: 'Private',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Mailgun',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 58,
        },
        {
          month: 'Feb',
          riskScore: 55,
        },
        {
          month: 'Mar',
          riskScore: 43,
        },
        {
          month: 'Apr',
          riskScore: 11,
        },
        {
          month: 'May',
          riskScore: 20,
        },
        {
          month: 'Jun',
          riskScore: 58,
        },
        {
          month: 'Jul',
          riskScore: 47,
        },
        {
          month: 'Aug',
          riskScore: 52,
        },
        {
          month: 'Sep',
          riskScore: 65,
        },
      ],
    },
    {
      id: 'e48d0982-cebf-435f-8441-7aa395c27392',
      name: 'MariaDB',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mariadb/0.2.0/9cdcc88126b726776cff6e9572faba7d85da6f09371226d19312e7bf49b02bee/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 42,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'School',
        'Snacking Trends',
        'Employer',
        'First Name',
        'Last Name',
        'Shoe Size',
        'Job Title',
        'Middle Initial',
        'SSN',
        'Salary',
        'Address',
        'Browsing Trends',
        'Purchase History',
        'Email',
        'Search History',
        'User ID',
      ],
      about: {
        companyName: 'MariaDB',
        legalName: 'MariaDB Inc',
        companyType: 'Public',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/MariaDB',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 25,
        },
        {
          month: 'Feb',
          riskScore: 22,
        },
        {
          month: 'Mar',
          riskScore: 41,
        },
        {
          month: 'Apr',
          riskScore: 23,
        },
        {
          month: 'May',
          riskScore: 20,
        },
        {
          month: 'Jun',
          riskScore: 24,
        },
        {
          month: 'Jul',
          riskScore: 22,
        },
        {
          month: 'Aug',
          riskScore: 17,
        },
        {
          month: 'Sep',
          riskScore: 19,
        },
        {
          month: 'Oct',
          riskScore: 29,
        },
        {
          month: 'Nov',
          riskScore: 30,
        },
        {
          month: 'Dec',
          riskScore: 42,
        },
      ],
    },
    {
      id: '29ccd72c-4315-4b3b-bac3-d8ed164678b0',
      name: 'Marketo',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/marketo/1.69.1/cc894a7df917e1a24c3eb44db101705ef4fcc33ab35aeb31c206ffd82a661ba2/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 100,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Medical History',
        'Search History',
        'Email',
        'Salary',
        'Snacking Trends',
        'Browsing Trends',
        'First Name',
        'Shoe Size',
        'SSN',
        'Address',
        'Last Name',
        'Employer',
        'User ID',
        'Purchase History',
        'School',
        'Middle Initial',
      ],
      about: {
        companyName: 'Marketo',
        legalName: 'Marketo Inc',
        companyType: 'Public',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Marketo',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 53,
        },
        {
          month: 'Feb',
          riskScore: 44,
        },
        {
          month: 'Mar',
          riskScore: 32,
        },
        {
          month: 'Apr',
          riskScore: 70,
        },
        {
          month: 'May',
          riskScore: 100,
        },
      ],
    },
    {
      id: 'b45390cd-10fd-4c56-8378-10d93cd70c62',
      name: 'Mixpanel',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mixpanel/1.31.12/4e41ed839a7b516b43b3d32f89f735dc220fc29179dce975f6fa247b33637519/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 59,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Medical History',
        'User ID',
        'Snacking Trends',
        'School',
        'Middle Initial',
        'Gender',
        'Salary',
        'Last Name',
        'Job Title',
        'SSN',
        'Search History',
        'Address',
        'Shoe Size',
        'Purchase History',
        'First Name',
        'Browsing Trends',
      ],
      about: {
        companyName: 'Mixpanel',
        legalName: 'Mixpanel Inc',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Mixpanel',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 56,
        },
        {
          month: 'Feb',
          riskScore: 44,
        },
        {
          month: 'Mar',
          riskScore: 26,
        },
        {
          month: 'Apr',
          riskScore: 44,
        },
        {
          month: 'May',
          riskScore: 37,
        },
        {
          month: 'Jun',
          riskScore: 59,
        },
      ],
    },
    {
      id: '3abefdc3-0451-4a8a-9866-d85b742f87d2',
      name: 'MongoDB',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mongodb/0.2.1/22244e0f3c723b0f1a6d5fbfc45d9b60af8a0b6f3da7df10649f20933e098e10/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 54,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'Browsing Trends',
        'SSN',
        'Snacking Trends',
        'ZIP',
        'School',
        'Salary',
        'Address',
        'Middle Initial',
        'Search History',
        'Purchase History',
        'User ID',
        'Email',
        'Employer',
        'Job Title',
        'Gender',
      ],
      about: {
        companyName: 'MongoDB',
        legalName: 'MongoDB LLC',
        companyType: 'Public',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/MongoDB',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 54,
        },
      ],
    },
    {
      id: '5b75d599-cf7f-49aa-b2b3-36fafc181861',
      name: 'mParticle',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mParticle/1.61.5/12a4fd68aa81212ec798101c0ed03b04f112115f11ce66c03c8828a7f5546dd2/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 51,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Browsing Trends',
        'Last Name',
        'User ID',
        'Purchase History',
        'Search History',
        'Employer',
        'Email',
        'Job Title',
        'First Name',
        'Salary',
        'ZIP',
        'Medical History',
        'SSN',
        'Middle Initial',
        'School',
        'Address',
      ],
      about: {
        companyName: 'mParticle',
        legalName: 'mParticle LLC',
        companyType: 'Public',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/mParticle',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 51,
        },
      ],
    },
    {
      id: 'fc68944b-4a6b-4393-abe2-e2ce0c6375e9',
      name: 'Mysql',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mysql/0.1.2/52f2a07caa647d7cb97ded9618e13d02217b1581ff670dfb3d99942febb3d8c3/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 75,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'ZIP',
        'Shoe Size',
        'Employer',
        'Address',
        'Middle Initial',
        'User ID',
        'Search History',
        'Job Title',
        'First Name',
        'Browsing Trends',
        'Email',
        'Purchase History',
        'Last Name',
        'Snacking Trends',
        'SSN',
        'Salary',
      ],
      about: {
        companyName: 'Mysql',
        legalName: 'Mysql LLC',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Mysql',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 69,
        },
        {
          month: 'Feb',
          riskScore: 57,
        },
        {
          month: 'Mar',
          riskScore: 43,
        },
        {
          month: 'Apr',
          riskScore: 35,
        },
        {
          month: 'May',
          riskScore: 53,
        },
        {
          month: 'Jun',
          riskScore: 74,
        },
        {
          month: 'Jul',
          riskScore: 34,
        },
        {
          month: 'Aug',
          riskScore: 75,
        },
      ],
    },
    {
      id: '5e7157be-25f4-4eb8-95b8-76c36b27af8f',
      name: 'Optimizely',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/optimizely/1.69.0/bfd0c7d979fdeeeb08549dd1601d77e2ca01f1fe8a84f462a5bab6083f4b5289/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 51,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'First Name',
        'Snacking Trends',
        'Middle Initial',
        'School',
        'Job Title',
        'Browsing Trends',
        'Shoe Size',
        'Purchase History',
        'User ID',
        'ZIP',
        'Address',
        'Salary',
        'Email',
        'Gender',
        'Search History',
        'SSN',
      ],
      about: {
        companyName: 'Optimizely',
        legalName: 'Optimizely LLC',
        companyType: 'Public',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Optimizely',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 49,
        },
        {
          month: 'Feb',
          riskScore: 31,
        },
        {
          month: 'Mar',
          riskScore: 51,
        },
      ],
    },
    {
      id: 'a88e5151-14d4-47d7-96c1-80f340ca5677',
      name: 'Oracle',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/oracle/0.2.0/e4213f519ef895c9c67171fafd0fd710830cf258707a245efeb8ba03874201ea/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 54,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Address',
        'Last Name',
        'First Name',
        'School',
        'Gender',
        'Snacking Trends',
        'Search History',
        'Job Title',
        'Middle Initial',
        'Browsing Trends',
        'User ID',
        'SSN',
        'Employer',
        'Medical History',
        'Purchase History',
        'Email',
      ],
      about: {
        companyName: 'Oracle',
        legalName: 'Oracle Inc',
        companyType: 'Private',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Oracle',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 19,
        },
        {
          month: 'Feb',
          riskScore: 47,
        },
        {
          month: 'Mar',
          riskScore: 32,
        },
        {
          month: 'Apr',
          riskScore: 40,
        },
        {
          month: 'May',
          riskScore: 18,
        },
        {
          month: 'Jun',
          riskScore: 27,
        },
        {
          month: 'Jul',
          riskScore: 30,
        },
        {
          month: 'Aug',
          riskScore: 50,
        },
        {
          month: 'Sep',
          riskScore: 54,
        },
        {
          month: 'Oct',
          riskScore: 35,
        },
        {
          month: 'Nov',
          riskScore: 21,
        },
        {
          month: 'Dec',
          riskScore: 54,
        },
      ],
    },
    {
      id: '6ac9b222-eb82-4103-9532-367824584453',
      name: 'Outreach',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/outreach/1.35.16/0d9a56b6326e3c66a60b4c700d259dd7bd42abbd41d75b1fc76cf3ecbed6114e/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 21,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'ZIP',
        'Employer',
        'Last Name',
        'Purchase History',
        'Snacking Trends',
        'SSN',
        'School',
        'Email',
        'Address',
        'Salary',
        'Shoe Size',
        'Browsing Trends',
        'Middle Initial',
        'First Name',
        'User ID',
        'Gender',
      ],
      about: {
        companyName: 'Outreach',
        legalName: 'Outreach Inc',
        companyType: 'Public',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Outreach',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 14,
        },
        {
          month: 'Feb',
          riskScore: 20,
        },
        {
          month: 'Mar',
          riskScore: 19,
        },
        {
          month: 'Apr',
          riskScore: 21,
        },
        {
          month: 'May',
          riskScore: 13,
        },
        {
          month: 'Jun',
          riskScore: 15,
        },
        {
          month: 'Jul',
          riskScore: 10,
        },
        {
          month: 'Aug',
          riskScore: 19,
        },
        {
          month: 'Sep',
          riskScore: 21,
        },
        {
          month: 'Oct',
          riskScore: 15,
        },
        {
          month: 'Nov',
          riskScore: 17,
        },
        {
          month: 'Dec',
          riskScore: 21,
        },
      ],
    },
    {
      id: 'c9ec2686-98e0-482d-9d5c-4afcfeb9e0c3',
      name: 'Pendo',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/pendo/1.60.1/3849a4eade7ee9fe84bfd5b75ab306d531548b9fc3d50db7f89e096ebace98de/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 26,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Job Title',
        'Last Name',
        'Purchase History',
        'School',
        'ZIP',
        'Shoe Size',
        'Gender',
        'First Name',
        'Browsing Trends',
        'Middle Initial',
        'Medical History',
        'Address',
        'SSN',
        'Email',
        'User ID',
        'Employer',
      ],
      about: {
        companyName: 'Pendo',
        legalName: 'Pendo Inc',
        companyType: 'Private',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Pendo',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 26,
        },
      ],
    },
    {
      id: 'adf8abd5-bc01-4ab9-9939-f591e2bc95cf',
      name: 'Qualtrics',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/qualtrics/1.57.0/abecf7b66c92c687cdf647bfbab3c39cfe7458f06312a2007bb32f00b51504b5/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 85,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'First Name',
        'Snacking Trends',
        'Last Name',
        'Medical History',
        'Employer',
        'Salary',
        'Address',
        'Gender',
        'Shoe Size',
        'Purchase History',
        'ZIP',
        'Middle Initial',
        'Browsing Trends',
        'Email',
        'Job Title',
        'SSN',
      ],
      about: {
        companyName: 'Qualtrics',
        legalName: 'Qualtrics LLC',
        companyType: 'Private',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Qualtrics',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 26,
        },
        {
          month: 'Feb',
          riskScore: 81,
        },
        {
          month: 'Mar',
          riskScore: 50,
        },
        {
          month: 'Apr',
          riskScore: 17,
        },
        {
          month: 'May',
          riskScore: 82,
        },
        {
          month: 'Jun',
          riskScore: 79,
        },
        {
          month: 'Jul',
          riskScore: 83,
        },
        {
          month: 'Aug',
          riskScore: 71,
        },
        {
          month: 'Sep',
          riskScore: 15,
        },
        {
          month: 'Oct',
          riskScore: 22,
        },
        {
          month: 'Nov',
          riskScore: 73,
        },
        {
          month: 'Dec',
          riskScore: 85,
        },
      ],
    },
    {
      id: 'bdaf75e7-0e0a-4872-9beb-6a4f31fbff52',
      name: 'Redshift',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/redshift/0.0.9/8081c937186086c8a29a34f90314e93bd03e7a3d3f462e15f8c2847125123c83/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 99,
      riskCategory: 'Extreme',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'School',
        'Browsing Trends',
        'User ID',
        'ZIP',
        'Medical History',
        'Job Title',
        'Address',
        'First Name',
        'SSN',
        'Purchase History',
        'Snacking Trends',
        'Salary',
        'Email',
        'Search History',
        'Employer',
        'Last Name',
      ],
      about: {
        companyName: 'Redshift',
        legalName: 'Redshift Inc',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Redshift',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 71,
        },
        {
          month: 'Feb',
          riskScore: 49,
        },
        {
          month: 'Mar',
          riskScore: 66,
        },
        {
          month: 'Apr',
          riskScore: 12,
        },
        {
          month: 'May',
          riskScore: 99,
        },
      ],
    },
    {
      id: '35ab2609-3d38-4ec4-85a7-318fb91f7105',
      name: 'SalesForce Sales Cloud CRM',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/salesforce/1.67.1/3cf821829c46c38f711c58f3ff9bd55a64f5ffa2df37abc186a5a277910cb38f/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 23,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Browsing Trends',
        'Salary',
        'Job Title',
        'Address',
        'Medical History',
        'First Name',
        'Shoe Size',
        'Email',
        'User ID',
        'SSN',
        'ZIP',
        'Purchase History',
        'Search History',
        'School',
        'Snacking Trends',
        'Gender',
      ],
      about: {
        companyName: 'SalesForce',
        legalName: 'SalesForce LLC',
        companyType: 'Private',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/SalesForce Sales Cloud CRM',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 12,
        },
        {
          month: 'Feb',
          riskScore: 19,
        },
        {
          month: 'Mar',
          riskScore: 23,
        },
      ],
    },
    {
      id: '1ad90276-8eba-4302-bf30-983816a5efed',
      name: 'Segment',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/segment/1.64.3/40b3fb60e63585e86ebb4bc0e16efbb3ab6e104676560f522a79508378e99934/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 84,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Salary',
        'SSN',
        'Search History',
        'User ID',
        'First Name',
        'Purchase History',
        'Employer',
        'Middle Initial',
        'ZIP',
        'Gender',
        'Shoe Size',
        'Address',
        'Medical History',
        'School',
        'Snacking Trends',
        'Job Title',
      ],
      about: {
        companyName: 'Segment',
        legalName: 'Segment LLC',
        companyType: 'Private',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Segment',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 48,
        },
        {
          month: 'Feb',
          riskScore: 42,
        },
        {
          month: 'Mar',
          riskScore: 11,
        },
        {
          month: 'Apr',
          riskScore: 50,
        },
        {
          month: 'May',
          riskScore: 26,
        },
        {
          month: 'Jun',
          riskScore: 30,
        },
        {
          month: 'Jul',
          riskScore: 49,
        },
        {
          month: 'Aug',
          riskScore: 51,
        },
        {
          month: 'Sep',
          riskScore: 55,
        },
        {
          month: 'Oct',
          riskScore: 40,
        },
        {
          month: 'Nov',
          riskScore: 84,
        },
      ],
    },
    {
      id: '7ae37ee4-0c31-417b-a9e7-2dae41e833b6',
      name: 'SendGrid',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/sendgrid/1.61.0/14f2c3388777ef052768264e52c7ff368f96a8336f885817e93f224a3b563565/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 72,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Salary',
        'Search History',
        'Middle Initial',
        'Medical History',
        'Last Name',
        'Gender',
        'Employer',
        'Address',
        'Job Title',
        'ZIP',
        'Browsing Trends',
        'Purchase History',
        'First Name',
        'Shoe Size',
        'User ID',
        'Snacking Trends',
      ],
      about: {
        companyName: 'SendGrid',
        legalName: 'SendGrid LLC',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/SendGrid',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 60,
        },
        {
          month: 'Feb',
          riskScore: 29,
        },
        {
          month: 'Mar',
          riskScore: 36,
        },
        {
          month: 'Apr',
          riskScore: 30,
        },
        {
          month: 'May',
          riskScore: 38,
        },
        {
          month: 'Jun',
          riskScore: 41,
        },
        {
          month: 'Jul',
          riskScore: 35,
        },
        {
          month: 'Aug',
          riskScore: 72,
        },
      ],
    },
    {
      id: '6986b827-368f-44e2-a6c9-c7d5bf78271a',
      name: 'shopify',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/shopify/1.69.3/245d99328546426e7403286aa021e4d21684fffd90419dd46a77b69432567d37/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 98,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Shoe Size',
        'Last Name',
        'Address',
        'Medical History',
        'Gender',
        'Employer',
        'Job Title',
        'User ID',
        'Browsing Trends',
        'ZIP',
        'Purchase History',
        'First Name',
        'Snacking Trends',
        'Salary',
        'Middle Initial',
        'School',
      ],
      about: {
        companyName: 'shopify',
        legalName: 'shopify Inc',
        companyType: 'Private',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/shopify',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 11,
        },
        {
          month: 'Feb',
          riskScore: 86,
        },
        {
          month: 'Mar',
          riskScore: 48,
        },
        {
          month: 'Apr',
          riskScore: 98,
        },
      ],
    },
    {
      id: 'a50ff77d-67c6-41f9-8561-705e69f6d7e8',
      name: 'Snapchat',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/snapchat/1.35.11/8eec3bcc0a619675b10e3919e6b7f618941598352fadf159390212e407cd0c53/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 41,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Browsing Trends',
        'Snacking Trends',
        'Salary',
        'Shoe Size',
        'Job Title',
        'User ID',
        'Last Name',
        'First Name',
        'Gender',
        'School',
        'Employer',
        'Middle Initial',
        'Medical History',
        'SSN',
        'Search History',
        'Purchase History',
      ],
      about: {
        companyName: 'Snapchat',
        legalName: 'Snapchat LLC',
        companyType: 'Private',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Snapchat',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 29,
        },
        {
          month: 'Feb',
          riskScore: 33,
        },
        {
          month: 'Mar',
          riskScore: 28,
        },
        {
          month: 'Apr',
          riskScore: 38,
        },
        {
          month: 'May',
          riskScore: 37,
        },
        {
          month: 'Jun',
          riskScore: 26,
        },
        {
          month: 'Jul',
          riskScore: 26,
        },
        {
          month: 'Aug',
          riskScore: 29,
        },
        {
          month: 'Sep',
          riskScore: 38,
        },
        {
          month: 'Oct',
          riskScore: 35,
        },
        {
          month: 'Nov',
          riskScore: 13,
        },
        {
          month: 'Dec',
          riskScore: 41,
        },
      ],
    },
    {
      id: 'b866f05e-fa35-4850-b5dd-7c6646789e2d',
      name: 'Snowflake',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/snowflake/0.1.1/53f5298918795ca2332e8e91428d1aed9e5deee03c017bea70c53290aa5c0dac/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 20,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'Snacking Trends',
        'Address',
        'Search History',
        'Job Title',
        'Gender',
        'Shoe Size',
        'Salary',
        'User ID',
        'School',
        'SSN',
        'Email',
        'Purchase History',
        'Browsing Trends',
        'Medical History',
        'First Name',
      ],
      about: {
        companyName: 'Snowflake',
        legalName: 'Snowflake Inc',
        companyType: 'Private',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Snowflake',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 11,
        },
        {
          month: 'Feb',
          riskScore: 11,
        },
        {
          month: 'Mar',
          riskScore: 14,
        },
        {
          month: 'Apr',
          riskScore: 20,
        },
      ],
    },
    {
      id: 'f6b4c165-9184-4d2a-9a44-28299aad15ed',
      name: 'Splash',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/splash/1.61.9/30d65b321478aef840a592663043729715c1a06c7b9a379b7ca53c10b05d9104/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 29,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Employer',
        'Shoe Size',
        'Email',
        'Last Name',
        'Job Title',
        'Salary',
        'ZIP',
        'Search History',
        'User ID',
        'Medical History',
        'SSN',
        'First Name',
        'Gender',
        'Address',
        'Purchase History',
        'School',
      ],
      about: {
        companyName: 'Splash',
        legalName: 'Splash LLC',
        companyType: 'Private',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Splash',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 25,
        },
        {
          month: 'Feb',
          riskScore: 20,
        },
        {
          month: 'Mar',
          riskScore: 13,
        },
        {
          month: 'Apr',
          riskScore: 26,
        },
        {
          month: 'May',
          riskScore: 10,
        },
        {
          month: 'Jun',
          riskScore: 11,
        },
        {
          month: 'Jul',
          riskScore: 11,
        },
        {
          month: 'Aug',
          riskScore: 12,
        },
        {
          month: 'Sep',
          riskScore: 14,
        },
        {
          month: 'Oct',
          riskScore: 18,
        },
        {
          month: 'Nov',
          riskScore: 29,
        },
      ],
    },
    {
      id: 'f1ff9bac-a031-4f96-a099-6c489390530a',
      name: 'TikTok Ads',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/tiktok_ads/1.30.9/c5d49f16d222d036ff865b7a2a4de7a321f8cdc8c3c7aedc31e6667c73fbab03/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 10,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Email',
        'Address',
        'Purchase History',
        'Search History',
        'Salary',
        'First Name',
        'User ID',
        'ZIP',
        'Job Title',
        'Medical History',
        'Browsing Trends',
        'SSN',
        'Shoe Size',
        'Middle Initial',
        'Snacking Trends',
        'Employer',
      ],
      about: {
        companyName: 'TikTok',
        legalName: 'TikTok LLC',
        companyType: 'Public',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/TikTok Ads',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 10,
        },
        {
          month: 'Feb',
          riskScore: 10,
        },
        {
          month: 'Mar',
          riskScore: 10,
        },
        {
          month: 'Apr',
          riskScore: 10,
        },
        {
          month: 'May',
          riskScore: 10,
        },
        {
          month: 'Jun',
          riskScore: 10,
        },
        {
          month: 'Jul',
          riskScore: 10,
        },
        {
          month: 'Aug',
          riskScore: 10,
        },
      ],
    },
    {
      id: 'e55aee1b-9d8b-4774-964b-c7c94f1022c1',
      name: 'Twitter Ads',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/twitter_ads/0.1.4/49bc251000bbeae7a6c1ec3bdfd0fbf7c7c3cc49d30c1ff27a504d213c870cac/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 62,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Middle Initial',
        'Browsing Trends',
        'Salary',
        'Last Name',
        'Job Title',
        'Email',
        'School',
        'First Name',
        'Search History',
        'Gender',
        'Medical History',
        'ZIP',
        'Purchase History',
        'Shoe Size',
        'Snacking Trends',
        'Address',
      ],
      about: {
        companyName: 'Twitter',
        legalName: 'Twitter LLC',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Twitter Ads',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 54,
        },
        {
          month: 'Feb',
          riskScore: 34,
        },
        {
          month: 'Mar',
          riskScore: 62,
        },
      ],
    },
    {
      id: '2cdf0174-20ef-4de3-a1e5-5fccbd687eb2',
      name: 'Yotpo/Swell',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/swell/1.31.10/271c7b1a26b1350ac571312befb8271ae6b04cd1aa03ae65fcf7ac2c78006795/logo.svg',
      summary: 'Attracts a large number of data collectors',
      riskScore: 75,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Address',
        'Employer',
        'Salary',
        'School',
        'Browsing Trends',
        'Email',
        'Search History',
        'Snacking Trends',
        'SSN',
        'Gender',
        'Middle Initial',
        'ZIP',
        'Job Title',
        'User ID',
        'Purchase History',
        'Medical History',
      ],
      about: {
        companyName: 'Yotpo/Swell',
        legalName: 'Yotpo/Swell Inc',
        companyType: 'Public',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Yotpo/Swell',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 65,
        },
        {
          month: 'Feb',
          riskScore: 75,
        },
      ],
    },
  ],
  totalResults: 41,
}

export const undisclosedSystemsMockData = {
  undisclosedSystems: [
    {
      id: '709f0299-a861-425f-9a6f-f74ef993e226',
      name: 'Alchemer',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/alchemer/1.52.12/118e1f61835e65132ec7111429644922b490fb14cc87a983f6dd9043803f172b/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 86,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'User ID',
        'ZIP',
        'Purchase History',
        'Medical History',
        'First Name',
        'Search History',
        'Shoe Size',
        'Browsing Trends',
        'Last Name',
        'Gender',
        'Snacking Trends',
        'Middle Initial',
        'Email',
        'SSN',
        'Address',
        'Employer',
      ],
      about: {
        companyName: 'Alchemer',
        legalName: 'Alchemer LLC',
        companyType: 'Private',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Alchemer',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 82,
        },
        {
          month: 'Feb',
          riskScore: 21,
        },
        {
          month: 'Mar',
          riskScore: 36,
        },
        {
          month: 'Apr',
          riskScore: 71,
        },
        {
          month: 'May',
          riskScore: 53,
        },
        {
          month: 'Jun',
          riskScore: 85,
        },
        {
          month: 'Jul',
          riskScore: 19,
        },
        {
          month: 'Aug',
          riskScore: 86,
        },
      ],
    },
    {
      id: 'e7bdca9d-28a9-471f-b294-d0e2fb10ea6f',
      name: 'Amazon Redshift',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/amazon-redshift/0.0.7/8081c937186086c8a29a34f90314e93bd03e7a3d3f462e15f8c2847125123c83/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 77,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Employer',
        'Last Name',
        'User ID',
        'Medical History',
        'Address',
        'Snacking Trends',
        'First Name',
        'Purchase History',
        'School',
        'Browsing Trends',
        'SSN',
        'Middle Initial',
        'Email',
        'Salary',
        'Gender',
        'Search History',
      ],
      about: {
        companyName: 'Amazon',
        legalName: 'Amazon LLC',
        companyType: 'Public',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Amazon Redshift',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 38,
        },
        {
          month: 'Feb',
          riskScore: 64,
        },
        {
          month: 'Mar',
          riskScore: 69,
        },
        {
          month: 'Apr',
          riskScore: 61,
        },
        {
          month: 'May',
          riskScore: 77,
        },
      ],
    },
    {
      id: 'a028f835-4f37-489c-92bd-2b740ffbce5c',
      name: 'Amazon S3',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/amazon-s3/0.1.2/bf3a16681cf66282c50d4d46c5521b6aab479f0181831e7102f6dc5fe9fe0159/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 62,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Address',
        'Medical History',
        'Last Name',
        'User ID',
        'Middle Initial',
        'Shoe Size',
        'Gender',
        'School',
        'Purchase History',
        'Employer',
        'Browsing Trends',
        'Search History',
        'Email',
        'Salary',
        'First Name',
        'ZIP',
      ],
      about: {
        companyName: 'Amazon',
        legalName: 'Amazon LLC',
        companyType: 'Public',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Amazon S3',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 48,
        },
        {
          month: 'Feb',
          riskScore: 39,
        },
        {
          month: 'Mar',
          riskScore: 55,
        },
        {
          month: 'Apr',
          riskScore: 48,
        },
        {
          month: 'May',
          riskScore: 15,
        },
        {
          month: 'Jun',
          riskScore: 23,
        },
        {
          month: 'Jul',
          riskScore: 34,
        },
        {
          month: 'Aug',
          riskScore: 61,
        },
        {
          month: 'Sep',
          riskScore: 43,
        },
        {
          month: 'Oct',
          riskScore: 59,
        },
        {
          month: 'Nov',
          riskScore: 34,
        },
        {
          month: 'Dec',
          riskScore: 62,
        },
      ],
    },
    {
      id: 'd273188c-6ec4-4e22-8191-871033193bbc',
      name: 'Autopilot',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/autopilot/1.30.2/fab75217ad0e46c26e83faed39e153512b11c84b186930820ba1881457c54db0/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 44,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'User ID',
        'Medical History',
        'Email',
        'Last Name',
        'SSN',
        'Salary',
        'Search History',
        'Job Title',
        'Middle Initial',
        'Shoe Size',
        'Snacking Trends',
        'ZIP',
        'Employer',
        'Browsing Trends',
        'Purchase History',
        'Address',
      ],
      about: {
        companyName: 'Autopilot',
        legalName: 'Autopilot LLC',
        companyType: 'Private',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Autopilot',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 23,
        },
        {
          month: 'Feb',
          riskScore: 33,
        },
        {
          month: 'Mar',
          riskScore: 12,
        },
        {
          month: 'Apr',
          riskScore: 17,
        },
        {
          month: 'May',
          riskScore: 18,
        },
        {
          month: 'Jun',
          riskScore: 12,
        },
        {
          month: 'Jul',
          riskScore: 44,
        },
        {
          month: 'Aug',
          riskScore: 44,
        },
      ],
    },
    {
      id: '83a5af9a-4260-47cb-84a4-0bfc3a1ae712',
      name: 'Coveo',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/coveo/1.52.11/bd0bf487bc4668468b1524c80befe2eca7bef07fa1cc0b5b306e773132a3dd4f/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 56,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'Salary',
        'Address',
        'School',
        'Email',
        'Job Title',
        'Employer',
        'SSN',
        'Gender',
        'Medical History',
        'Purchase History',
        'Snacking Trends',
        'Shoe Size',
        'ZIP',
        'Browsing Trends',
        'Search History',
      ],
      about: {
        companyName: 'Coveo',
        legalName: 'Coveo Inc',
        companyType: 'Private',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Coveo',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 56,
        },
      ],
    },
    {
      id: 'd1bec580-ca48-45fc-b423-1739b97668ab',
      name: 'Discourse',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/discourse/1.12.2/b6de2df7d452b15fc0290ec3e4a336ff22ebb231bed8ab17a6f69f9393402484/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 41,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'Job Title',
        'Gender',
        'Address',
        'Email',
        'School',
        'User ID',
        'Snacking Trends',
        'First Name',
        'Salary',
        'SSN',
        'Employer',
        'Search History',
        'Browsing Trends',
        'Medical History',
        'ZIP',
      ],
      about: {
        companyName: 'Discourse',
        legalName: 'Discourse Inc',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Discourse',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 28,
        },
        {
          month: 'Feb',
          riskScore: 15,
        },
        {
          month: 'Mar',
          riskScore: 12,
        },
        {
          month: 'Apr',
          riskScore: 37,
        },
        {
          month: 'May',
          riskScore: 37,
        },
        {
          month: 'Jun',
          riskScore: 31,
        },
        {
          month: 'Jul',
          riskScore: 12,
        },
        {
          month: 'Aug',
          riskScore: 22,
        },
        {
          month: 'Sep',
          riskScore: 41,
        },
      ],
    },
    {
      id: '348f3e33-d2ca-49f1-a901-ddc0a9e48ad8',
      name: 'Dynamic Yield',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/dynamic_yield/1.31.8/21ff737a40f023ba89c5f89fa5b33ddfe4e27f8a6e52d75bf58160944262b550/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 81,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'User ID',
        'Snacking Trends',
        'Employer',
        'School',
        'ZIP',
        'Salary',
        'Job Title',
        'Browsing Trends',
        'First Name',
        'Middle Initial',
        'Shoe Size',
        'Last Name',
        'Address',
        'Email',
        'SSN',
        'Gender',
      ],
      about: {
        companyName: 'Dynamic',
        legalName: 'Dynamic LLC',
        companyType: 'Public',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Dynamic Yield',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 27,
        },
        {
          month: 'Feb',
          riskScore: 16,
        },
        {
          month: 'Mar',
          riskScore: 41,
        },
        {
          month: 'Apr',
          riskScore: 26,
        },
        {
          month: 'May',
          riskScore: 81,
        },
      ],
    },
    {
      id: 'd883789b-5291-4bdd-b34d-499d2ade7dd3',
      name: 'Facebook Ads',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/facebookAds/0.3.5/070b6a0b83b2dcf836e31d9c4a978f92c9bc506f6ce46312e3988886fc715a60/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 98,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Job Title',
        'Gender',
        'Purchase History',
        'Salary',
        'Middle Initial',
        'SSN',
        'User ID',
        'Address',
        'Medical History',
        'Shoe Size',
        'Email',
        'School',
        'Browsing Trends',
        'ZIP',
        'Employer',
        'Last Name',
      ],
      about: {
        companyName: 'Facebook',
        legalName: 'Facebook Inc',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Facebook Ads',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 39,
        },
        {
          month: 'Feb',
          riskScore: 94,
        },
        {
          month: 'Mar',
          riskScore: 88,
        },
        {
          month: 'Apr',
          riskScore: 47,
        },
        {
          month: 'May',
          riskScore: 98,
        },
      ],
    },
    {
      id: '7d1ece59-ced2-406b-a42b-06b578234aec',
      name: 'Google Analytics',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/google_analytics/1.67.0/e6d5bb1e898ff06565aeb65d2a3e64e4fa67f3dfcda7b80af7bc8d2a8f40fab8/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 14,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'SSN',
        'Job Title',
        'Shoe Size',
        'School',
        'User ID',
        'Medical History',
        'Snacking Trends',
        'Address',
        'Salary',
        'Email',
        'Middle Initial',
        'Browsing Trends',
        'Gender',
        'Search History',
        'ZIP',
        'Purchase History',
      ],
      about: {
        companyName: 'Google',
        legalName: 'Google Inc',
        companyType: 'Public',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Google Analytics',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 10,
        },
        {
          month: 'Feb',
          riskScore: 12,
        },
        {
          month: 'Mar',
          riskScore: 13,
        },
        {
          month: 'Apr',
          riskScore: 12,
        },
        {
          month: 'May',
          riskScore: 10,
        },
        {
          month: 'Jun',
          riskScore: 12,
        },
        {
          month: 'Jul',
          riskScore: 13,
        },
        {
          month: 'Aug',
          riskScore: 14,
        },
        {
          month: 'Sep',
          riskScore: 11,
        },
        {
          month: 'Oct',
          riskScore: 12,
        },
        {
          month: 'Nov',
          riskScore: 13,
        },
        {
          month: 'Dec',
          riskScore: 14,
        },
      ],
    },
    {
      id: 'fa951fe7-3d96-4e93-9104-811d5ff2931b',
      name: 'Google Marketing',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/google_marketing/0.2.5/f8040033f29d4fb6073a9f97dc3c01742253552e59829fea19464dbccd3e45ce/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 71,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Address',
        'Last Name',
        'Browsing Trends',
        'Job Title',
        'ZIP',
        'Purchase History',
        'User ID',
        'School',
        'Gender',
        'SSN',
        'Middle Initial',
        'First Name',
        'Search History',
        'Snacking Trends',
        'Shoe Size',
        'Email',
      ],
      about: {
        companyName: 'Google',
        legalName: 'Google Inc',
        companyType: 'Private',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Google Marketing',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 40,
        },
        {
          month: 'Feb',
          riskScore: 22,
        },
        {
          month: 'Mar',
          riskScore: 15,
        },
        {
          month: 'Apr',
          riskScore: 71,
        },
      ],
    },
    {
      id: 'feefac87-9ffb-42c3-ae8e-c95223b870c3',
      name: 'Heap Analytics',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/heap/1.66.0/4b4617e3609256f2c6287ca17579fb0557c938533a03ce0eb5a7697aac564363/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 92,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Purchase History',
        'Snacking Trends',
        'Gender',
        'Medical History',
        'Last Name',
        'User ID',
        'Browsing Trends',
        'Address',
        'Job Title',
        'ZIP',
        'Email',
        'SSN',
        'Employer',
        'Middle Initial',
        'Salary',
        'First Name',
      ],
      about: {
        companyName: 'Heap',
        legalName: 'Heap LLC',
        companyType: 'Private',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Heap Analytics',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 49,
        },
        {
          month: 'Feb',
          riskScore: 73,
        },
        {
          month: 'Mar',
          riskScore: 52,
        },
        {
          month: 'Apr',
          riskScore: 24,
        },
        {
          month: 'May',
          riskScore: 92,
        },
      ],
    },
    {
      id: '46f66c64-4050-4922-a0f5-1b4af30cd6c9',
      name: 'Hubspot',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/hubspot/1.37.2/4110ba25f28a48bb93cc65baaf5e2ed4b9f2882b9a8d034a477ef440710fd8d6/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 96,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'School',
        'Address',
        'Purchase History',
        'User ID',
        'Middle Initial',
        'Gender',
        'Medical History',
        'Employer',
        'ZIP',
        'Email',
        'Search History',
        'SSN',
        'Last Name',
        'Job Title',
        'First Name',
        'Browsing Trends',
      ],
      about: {
        companyName: 'Hubspot',
        legalName: 'Hubspot Inc',
        companyType: 'Private',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Hubspot',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 30,
        },
        {
          month: 'Feb',
          riskScore: 46,
        },
        {
          month: 'Mar',
          riskScore: 51,
        },
        {
          month: 'Apr',
          riskScore: 70,
        },
        {
          month: 'May',
          riskScore: 75,
        },
        {
          month: 'Jun',
          riskScore: 66,
        },
        {
          month: 'Jul',
          riskScore: 34,
        },
        {
          month: 'Aug',
          riskScore: 36,
        },
        {
          month: 'Sep',
          riskScore: 78,
        },
        {
          month: 'Oct',
          riskScore: 29,
        },
        {
          month: 'Nov',
          riskScore: 96,
        },
      ],
    },
    {
      id: '60a16a87-f1f7-4cc0-aef0-b58e9d06f890',
      name: 'IAB CCPA',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/IAB%20CCPA/1.34.3/68e7482bee17f7a385ac19d5059a2f028ad4cb47ab7c2c75dec5bbde4e98c775/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 19,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'SSN',
        'User ID',
        'Medical History',
        'Shoe Size',
        'School',
        'Email',
        'Address',
        'Search History',
        'First Name',
        'ZIP',
        'Browsing Trends',
        'Employer',
        'Middle Initial',
        'Job Title',
        'Last Name',
        'Purchase History',
      ],
      about: {
        companyName: 'IAB',
        legalName: 'IAB LLC',
        companyType: 'Private',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/IAB CCPA',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 19,
        },
      ],
    },
    {
      id: '1c516661-4cce-45e0-8498-7d6d53db6acf',
      name: 'Judge.Me',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/judgeme/1.52.13/73094df637dd910e5110b32f468c8708324eeee64a359027eaa502a0afe517dc/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 87,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'Search History',
        'SSN',
        'User ID',
        'ZIP',
        'Middle Initial',
        'Employer',
        'Gender',
        'Job Title',
        'Browsing Trends',
        'First Name',
        'Email',
        'Shoe Size',
        'Address',
        'Purchase History',
        'Snacking Trends',
      ],
      about: {
        companyName: 'Judge.Me',
        legalName: 'Judge.Me Inc',
        companyType: 'Public',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Judge.Me',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 47,
        },
        {
          month: 'Feb',
          riskScore: 59,
        },
        {
          month: 'Mar',
          riskScore: 76,
        },
        {
          month: 'Apr',
          riskScore: 31,
        },
        {
          month: 'May',
          riskScore: 87,
        },
      ],
    },
    {
      id: 'b5f91b86-a175-4478-babc-e129ac299735',
      name: 'Ketch Sync',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/ketchSync/1.33.0/b743c5bd7f48e8d6aaa83d639516cc3a88ec8d32d9be5983bd79ba7919080ff4/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 89,
      riskCategory: 'Extreme',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Shoe Size',
        'Medical History',
        'Address',
        'Purchase History',
        'Job Title',
        'Salary',
        'Browsing Trends',
        'SSN',
        'Last Name',
        'Gender',
        'School',
        'Middle Initial',
        'User ID',
        'Snacking Trends',
        'First Name',
        'ZIP',
      ],
      about: {
        companyName: 'Ketch',
        legalName: 'Ketch LLC',
        companyType: 'Private',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Ketch Sync',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 47,
        },
        {
          month: 'Feb',
          riskScore: 79,
        },
        {
          month: 'Mar',
          riskScore: 28,
        },
        {
          month: 'Apr',
          riskScore: 56,
        },
        {
          month: 'May',
          riskScore: 48,
        },
        {
          month: 'Jun',
          riskScore: 28,
        },
        {
          month: 'Jul',
          riskScore: 37,
        },
        {
          month: 'Aug',
          riskScore: 36,
        },
        {
          month: 'Sep',
          riskScore: 35,
        },
        {
          month: 'Oct',
          riskScore: 89,
        },
      ],
    },
    {
      id: '35cc1b33-524e-41aa-bcc5-f7a13e29e010',
      name: 'Klaviyo',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/klaviyo/1.65.1/c91f48270ccaf34608965deb1de0be2ed27b594f05e964df68191547cf2f6f51/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 78,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'First Name',
        'ZIP',
        'SSN',
        'School',
        'Email',
        'Salary',
        'Search History',
        'Shoe Size',
        'Job Title',
        'Address',
        'User ID',
        'Employer',
        'Purchase History',
        'Gender',
        'Middle Initial',
      ],
      about: {
        companyName: 'Klaviyo',
        legalName: 'Klaviyo LLC',
        companyType: 'Public',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Klaviyo',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 23,
        },
        {
          month: 'Feb',
          riskScore: 69,
        },
        {
          month: 'Mar',
          riskScore: 17,
        },
        {
          month: 'Apr',
          riskScore: 10,
        },
        {
          month: 'May',
          riskScore: 76,
        },
        {
          month: 'Jun',
          riskScore: 78,
        },
      ],
    },
    {
      id: '60036314-4a95-44a5-a5ae-3607c046636a',
      name: 'LinkedIn',
      logo: 'https://transom.dev.b10s.io/transom/assets/ketch_apps/linkedin/0.7.12/3438e6ee6339f26ed2c8dca631e94fd080fa0ae78cdd60a5dd446b6fa2634804/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 58,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'School',
        'Email',
        'Browsing Trends',
        'Shoe Size',
        'Purchase History',
        'Salary',
        'Employer',
        'Job Title',
        'SSN',
        'Snacking Trends',
        'Last Name',
        'First Name',
        'ZIP',
        'Address',
        'Gender',
        'Search History',
      ],
      about: {
        companyName: 'LinkedIn',
        legalName: 'LinkedIn LLC',
        companyType: 'Private',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/LinkedIn',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 55,
        },
        {
          month: 'Feb',
          riskScore: 20,
        },
        {
          month: 'Mar',
          riskScore: 25,
        },
        {
          month: 'Apr',
          riskScore: 36,
        },
        {
          month: 'May',
          riskScore: 53,
        },
        {
          month: 'Jun',
          riskScore: 30,
        },
        {
          month: 'Jul',
          riskScore: 41,
        },
        {
          month: 'Aug',
          riskScore: 38,
        },
        {
          month: 'Sep',
          riskScore: 58,
        },
      ],
    },
    {
      id: '33127b2f-6bb2-4d17-89d7-7d3b53e9d309',
      name: 'Mailchimp',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mailchimp/0.1.7/f63f0618a14024415529e19c636fe1d220e604f3c5fa2fd25e31b40455743f85/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 22,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Last Name',
        'Browsing Trends',
        'Salary',
        'Search History',
        'First Name',
        'Email',
        'School',
        'Gender',
        'ZIP',
        'Purchase History',
        'Shoe Size',
        'Snacking Trends',
        'Address',
        'User ID',
        'Job Title',
        'Employer',
      ],
      about: {
        companyName: 'Mailchimp',
        legalName: 'Mailchimp Inc',
        companyType: 'Public',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Mailchimp',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 11,
        },
        {
          month: 'Feb',
          riskScore: 21,
        },
        {
          month: 'Mar',
          riskScore: 12,
        },
        {
          month: 'Apr',
          riskScore: 13,
        },
        {
          month: 'May',
          riskScore: 22,
        },
      ],
    },
    {
      id: '6bf0ae5d-3335-4b1c-8f27-34312c039e54',
      name: 'Mailgun',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mailgun/1.55.0/4473a3ad43aaf70b65ab0bb5efbafafc33510c6301e6a5d551ca12804018a126/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 73,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Snacking Trends',
        'Job Title',
        'Medical History',
        'User ID',
        'Middle Initial',
        'Address',
        'Search History',
        'Last Name',
        'ZIP',
        'SSN',
        'Salary',
        'Purchase History',
        'First Name',
        'Employer',
        'Shoe Size',
        'Gender',
      ],
      about: {
        companyName: 'Mailgun',
        legalName: 'Mailgun LLC',
        companyType: 'Public',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Mailgun',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 72,
        },
        {
          month: 'Feb',
          riskScore: 34,
        },
        {
          month: 'Mar',
          riskScore: 24,
        },
        {
          month: 'Apr',
          riskScore: 12,
        },
        {
          month: 'May',
          riskScore: 31,
        },
        {
          month: 'Jun',
          riskScore: 73,
        },
      ],
    },
    {
      id: '7b8a1d9b-8cc0-497d-8840-042aaf1ddb47',
      name: 'MariaDB',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mariadb/0.2.0/9cdcc88126b726776cff6e9572faba7d85da6f09371226d19312e7bf49b02bee/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 85,
      riskCategory: 'Extreme',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Gender',
        'Email',
        'Browsing Trends',
        'Medical History',
        'Last Name',
        'Address',
        'First Name',
        'Snacking Trends',
        'Middle Initial',
        'Salary',
        'Purchase History',
        'Job Title',
        'ZIP',
        'Employer',
        'Shoe Size',
        'School',
      ],
      about: {
        companyName: 'MariaDB',
        legalName: 'MariaDB LLC',
        companyType: 'Public',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/MariaDB',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 47,
        },
        {
          month: 'Feb',
          riskScore: 67,
        },
        {
          month: 'Mar',
          riskScore: 73,
        },
        {
          month: 'Apr',
          riskScore: 79,
        },
        {
          month: 'May',
          riskScore: 13,
        },
        {
          month: 'Jun',
          riskScore: 41,
        },
        {
          month: 'Jul',
          riskScore: 27,
        },
        {
          month: 'Aug',
          riskScore: 34,
        },
        {
          month: 'Sep',
          riskScore: 10,
        },
        {
          month: 'Oct',
          riskScore: 85,
        },
      ],
    },
    {
      id: '4120e6aa-3a90-4947-accb-d5d354fa905d',
      name: 'Marketo',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/marketo/1.69.1/cc894a7df917e1a24c3eb44db101705ef4fcc33ab35aeb31c206ffd82a661ba2/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 56,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Shoe Size',
        'Last Name',
        'Browsing Trends',
        'User ID',
        'Search History',
        'First Name',
        'Medical History',
        'Salary',
        'Purchase History',
        'Gender',
        'ZIP',
        'SSN',
        'Email',
        'Employer',
        'Address',
        'Snacking Trends',
      ],
      about: {
        companyName: 'Marketo',
        legalName: 'Marketo Inc',
        companyType: 'Private',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Marketo',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 21,
        },
        {
          month: 'Feb',
          riskScore: 34,
        },
        {
          month: 'Mar',
          riskScore: 16,
        },
        {
          month: 'Apr',
          riskScore: 31,
        },
        {
          month: 'May',
          riskScore: 25,
        },
        {
          month: 'Jun',
          riskScore: 13,
        },
        {
          month: 'Jul',
          riskScore: 19,
        },
        {
          month: 'Aug',
          riskScore: 48,
        },
        {
          month: 'Sep',
          riskScore: 56,
        },
      ],
    },
    {
      id: 'a6c1cd89-6ee7-4d63-bc39-574023a46767',
      name: 'Mixpanel',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mixpanel/1.31.12/4e41ed839a7b516b43b3d32f89f735dc220fc29179dce975f6fa247b33637519/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 73,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Purchase History',
        'Email',
        'Address',
        'Shoe Size',
        'Last Name',
        'User ID',
        'Search History',
        'Middle Initial',
        'Gender',
        'ZIP',
        'Employer',
        'School',
        'Browsing Trends',
        'Snacking Trends',
        'SSN',
        'Salary',
      ],
      about: {
        companyName: 'Mixpanel',
        legalName: 'Mixpanel LLC',
        companyType: 'Public',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Mixpanel',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 14,
        },
        {
          month: 'Feb',
          riskScore: 68,
        },
        {
          month: 'Mar',
          riskScore: 10,
        },
        {
          month: 'Apr',
          riskScore: 16,
        },
        {
          month: 'May',
          riskScore: 40,
        },
        {
          month: 'Jun',
          riskScore: 62,
        },
        {
          month: 'Jul',
          riskScore: 73,
        },
      ],
    },
    {
      id: '47e559fb-855f-4ff1-9cd4-2c8c24660f10',
      name: 'MongoDB',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mongodb/0.2.1/22244e0f3c723b0f1a6d5fbfc45d9b60af8a0b6f3da7df10649f20933e098e10/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 65,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'First Name',
        'SSN',
        'Salary',
        'Medical History',
        'School',
        'Shoe Size',
        'User ID',
        'Employer',
        'Browsing Trends',
        'Job Title',
        'Snacking Trends',
        'Last Name',
        'Purchase History',
        'Address',
        'ZIP',
        'Email',
      ],
      about: {
        companyName: 'MongoDB',
        legalName: 'MongoDB LLC',
        companyType: 'Public',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/MongoDB',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 65,
        },
      ],
    },
    {
      id: 'fe1b70dc-dfb3-4cae-bc52-4520a5414586',
      name: 'mParticle',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mParticle/1.61.5/12a4fd68aa81212ec798101c0ed03b04f112115f11ce66c03c8828a7f5546dd2/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 73,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Snacking Trends',
        'School',
        'User ID',
        'First Name',
        'ZIP',
        'Purchase History',
        'Email',
        'Medical History',
        'Employer',
        'Browsing Trends',
        'Search History',
        'SSN',
        'Middle Initial',
        'Shoe Size',
        'Address',
        'Salary',
      ],
      about: {
        companyName: 'mParticle',
        legalName: 'mParticle Inc',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/mParticle',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 44,
        },
        {
          month: 'Feb',
          riskScore: 72,
        },
        {
          month: 'Mar',
          riskScore: 60,
        },
        {
          month: 'Apr',
          riskScore: 30,
        },
        {
          month: 'May',
          riskScore: 12,
        },
        {
          month: 'Jun',
          riskScore: 16,
        },
        {
          month: 'Jul',
          riskScore: 24,
        },
        {
          month: 'Aug',
          riskScore: 25,
        },
        {
          month: 'Sep',
          riskScore: 37,
        },
        {
          month: 'Oct',
          riskScore: 38,
        },
        {
          month: 'Nov',
          riskScore: 31,
        },
        {
          month: 'Dec',
          riskScore: 73,
        },
      ],
    },
    {
      id: '5d4f2ad5-e31e-4d7e-bfb8-9f9d29f73e83',
      name: 'Mysql',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/mysql/0.1.2/52f2a07caa647d7cb97ded9618e13d02217b1581ff670dfb3d99942febb3d8c3/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 92,
      riskCategory: 'Extreme',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Snacking Trends',
        'Purchase History',
        'Shoe Size',
        'First Name',
        'SSN',
        'User ID',
        'Search History',
        'Employer',
        'School',
        'Salary',
        'Address',
        'Medical History',
        'Browsing Trends',
        'Email',
        'Job Title',
        'ZIP',
      ],
      about: {
        companyName: 'Mysql',
        legalName: 'Mysql Inc',
        companyType: 'Public',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Mysql',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 38,
        },
        {
          month: 'Feb',
          riskScore: 92,
        },
      ],
    },
    {
      id: 'f65518e7-219e-4936-8152-46f3b9b0db0a',
      name: 'Optimizely',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/optimizely/1.69.0/bfd0c7d979fdeeeb08549dd1601d77e2ca01f1fe8a84f462a5bab6083f4b5289/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 59,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Gender',
        'ZIP',
        'Snacking Trends',
        'Email',
        'School',
        'SSN',
        'Salary',
        'Middle Initial',
        'Employer',
        'First Name',
        'Address',
        'Last Name',
        'Search History',
        'Shoe Size',
        'User ID',
        'Browsing Trends',
      ],
      about: {
        companyName: 'Optimizely',
        legalName: 'Optimizely LLC',
        companyType: 'Private',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Optimizely',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 45,
        },
        {
          month: 'Feb',
          riskScore: 22,
        },
        {
          month: 'Mar',
          riskScore: 12,
        },
        {
          month: 'Apr',
          riskScore: 36,
        },
        {
          month: 'May',
          riskScore: 50,
        },
        {
          month: 'Jun',
          riskScore: 58,
        },
        {
          month: 'Jul',
          riskScore: 30,
        },
        {
          month: 'Aug',
          riskScore: 40,
        },
        {
          month: 'Sep',
          riskScore: 59,
        },
      ],
    },
    {
      id: '3a7db863-c724-445b-a42e-4810b40b9c1d',
      name: 'Oracle',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/oracle/0.2.0/e4213f519ef895c9c67171fafd0fd710830cf258707a245efeb8ba03874201ea/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 25,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Browsing Trends',
        'ZIP',
        'SSN',
        'Address',
        'First Name',
        'School',
        'Employer',
        'Job Title',
        'Email',
        'User ID',
        'Middle Initial',
        'Purchase History',
        'Shoe Size',
        'Search History',
        'Salary',
        'Snacking Trends',
      ],
      about: {
        companyName: 'Oracle',
        legalName: 'Oracle LLC',
        companyType: 'Public',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Oracle',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 20,
        },
        {
          month: 'Feb',
          riskScore: 25,
        },
      ],
    },
    {
      id: '48438848-cb0e-41b9-b8f8-3e471d24ee9d',
      name: 'Outreach',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/outreach/1.35.16/0d9a56b6326e3c66a60b4c700d259dd7bd42abbd41d75b1fc76cf3ecbed6114e/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 77,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Purchase History',
        'Snacking Trends',
        'Medical History',
        'Middle Initial',
        'Shoe Size',
        'Last Name',
        'Job Title',
        'First Name',
        'Employer',
        'School',
        'Gender',
        'Search History',
        'ZIP',
        'Salary',
        'User ID',
        'Address',
      ],
      about: {
        companyName: 'Outreach',
        legalName: 'Outreach LLC',
        companyType: 'Public',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Outreach',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 10,
        },
        {
          month: 'Feb',
          riskScore: 41,
        },
        {
          month: 'Mar',
          riskScore: 42,
        },
        {
          month: 'Apr',
          riskScore: 77,
        },
      ],
    },
    {
      id: 'e543fde3-a525-4d7a-adbf-dd9e75063089',
      name: 'Pendo',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/pendo/1.60.1/3849a4eade7ee9fe84bfd5b75ab306d531548b9fc3d50db7f89e096ebace98de/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 18,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Gender',
        'First Name',
        'Middle Initial',
        'Employer',
        'Purchase History',
        'Last Name',
        'Shoe Size',
        'Snacking Trends',
        'User ID',
        'Medical History',
        'Email',
        'Job Title',
        'SSN',
        'Search History',
        'School',
        'ZIP',
      ],
      about: {
        companyName: 'Pendo',
        legalName: 'Pendo LLC',
        companyType: 'Public',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Pendo',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 17,
        },
        {
          month: 'Feb',
          riskScore: 18,
        },
        {
          month: 'Mar',
          riskScore: 14,
        },
        {
          month: 'Apr',
          riskScore: 15,
        },
        {
          month: 'May',
          riskScore: 17,
        },
        {
          month: 'Jun',
          riskScore: 13,
        },
        {
          month: 'Jul',
          riskScore: 14,
        },
        {
          month: 'Aug',
          riskScore: 18,
        },
      ],
    },
    {
      id: '3640fa58-709d-4341-813a-8172bc86971e',
      name: 'Qualtrics',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/qualtrics/1.57.0/abecf7b66c92c687cdf647bfbab3c39cfe7458f06312a2007bb32f00b51504b5/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 55,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Browsing Trends',
        'Shoe Size',
        'ZIP',
        'User ID',
        'Job Title',
        'Email',
        'Address',
        'First Name',
        'School',
        'Purchase History',
        'Middle Initial',
        'Search History',
        'Last Name',
        'Snacking Trends',
        'SSN',
        'Employer',
      ],
      about: {
        companyName: 'Qualtrics',
        legalName: 'Qualtrics LLC',
        companyType: 'Private',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Qualtrics',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 26,
        },
        {
          month: 'Feb',
          riskScore: 50,
        },
        {
          month: 'Mar',
          riskScore: 20,
        },
        {
          month: 'Apr',
          riskScore: 52,
        },
        {
          month: 'May',
          riskScore: 55,
        },
      ],
    },
    {
      id: 'e48d27fb-666e-4768-a0c6-ca1d6e06ef48',
      name: 'Redshift',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/redshift/0.0.9/8081c937186086c8a29a34f90314e93bd03e7a3d3f462e15f8c2847125123c83/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 44,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'First Name',
        'Last Name',
        'Gender',
        'Shoe Size',
        'Salary',
        'Snacking Trends',
        'Medical History',
        'School',
        'Employer',
        'Search History',
        'Middle Initial',
        'Address',
        'ZIP',
        'User ID',
        'Email',
        'Purchase History',
      ],
      about: {
        companyName: 'Redshift',
        legalName: 'Redshift Inc',
        companyType: 'Public',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Redshift',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 36,
        },
        {
          month: 'Feb',
          riskScore: 30,
        },
        {
          month: 'Mar',
          riskScore: 44,
        },
      ],
    },
    {
      id: '2d79b3db-8251-4930-ae3b-5df6ed3e79b8',
      name: 'SalesForce Sales Cloud CRM',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/salesforce/1.67.1/3cf821829c46c38f711c58f3ff9bd55a64f5ffa2df37abc186a5a277910cb38f/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 18,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Purchase History',
        'Job Title',
        'SSN',
        'Browsing Trends',
        'ZIP',
        'Salary',
        'User ID',
        'Search History',
        'Middle Initial',
        'Medical History',
        'Address',
        'First Name',
        'Snacking Trends',
        'Employer',
        'School',
        'Last Name',
      ],
      about: {
        companyName: 'SalesForce',
        legalName: 'SalesForce Inc',
        companyType: 'Public',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/SalesForce Sales Cloud CRM',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 18,
        },
      ],
    },
    {
      id: 'd9fe3909-f0df-4c1c-ae43-1a752b292ddf',
      name: 'Segment',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/segment/1.64.3/40b3fb60e63585e86ebb4bc0e16efbb3ab6e104676560f522a79508378e99934/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 75,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Shoe Size',
        'Email',
        'Medical History',
        'School',
        'Middle Initial',
        'Snacking Trends',
        'Gender',
        'Browsing Trends',
        'ZIP',
        'User ID',
        'Search History',
        'Purchase History',
        'Salary',
        'Last Name',
        'Address',
        'First Name',
      ],
      about: {
        companyName: 'Segment',
        legalName: 'Segment Inc',
        companyType: 'Public',
        employees: 30000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Segment',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 55,
        },
        {
          month: 'Feb',
          riskScore: 45,
        },
        {
          month: 'Mar',
          riskScore: 25,
        },
        {
          month: 'Apr',
          riskScore: 38,
        },
        {
          month: 'May',
          riskScore: 72,
        },
        {
          month: 'Jun',
          riskScore: 36,
        },
        {
          month: 'Jul',
          riskScore: 63,
        },
        {
          month: 'Aug',
          riskScore: 27,
        },
        {
          month: 'Sep',
          riskScore: 32,
        },
        {
          month: 'Oct',
          riskScore: 60,
        },
        {
          month: 'Nov',
          riskScore: 50,
        },
        {
          month: 'Dec',
          riskScore: 75,
        },
      ],
    },
    {
      id: '7bbd62c4-a052-4089-9a7e-0de65f565cd9',
      name: 'SendGrid',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/sendgrid/1.61.0/14f2c3388777ef052768264e52c7ff368f96a8336f885817e93f224a3b563565/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 89,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'SSN',
        'Shoe Size',
        'Address',
        'Medical History',
        'Gender',
        'User ID',
        'First Name',
        'Browsing Trends',
        'Snacking Trends',
        'Purchase History',
        'Search History',
        'Salary',
        'Middle Initial',
        'Employer',
        'Email',
        'School',
      ],
      about: {
        companyName: 'SendGrid',
        legalName: 'SendGrid Inc',
        companyType: 'Public',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/SendGrid',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 89,
        },
      ],
    },
    {
      id: '9fb1dca4-ebe8-4b93-8d5b-a1e1db2c998e',
      name: 'shopify',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/shopify/1.69.3/245d99328546426e7403286aa021e4d21684fffd90419dd46a77b69432567d37/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 97,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Search History',
        'Shoe Size',
        'Medical History',
        'Employer',
        'Middle Initial',
        'User ID',
        'Gender',
        'Address',
        'Browsing Trends',
        'Last Name',
        'School',
        'Salary',
        'Email',
        'SSN',
        'Purchase History',
        'Job Title',
      ],
      about: {
        companyName: 'shopify',
        legalName: 'shopify Inc',
        companyType: 'Public',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/shopify',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 97,
        },
      ],
    },
    {
      id: '0a53e213-5db5-4d8c-9d83-540203f6c863',
      name: 'Snapchat',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/snapchat/1.35.11/8eec3bcc0a619675b10e3919e6b7f618941598352fadf159390212e407cd0c53/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 85,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Employer',
        'Search History',
        'Gender',
        'Medical History',
        'Shoe Size',
        'Address',
        'Browsing Trends',
        'SSN',
        'Purchase History',
        'Salary',
        'Job Title',
        'Last Name',
        'Email',
        'First Name',
        'ZIP',
        'Middle Initial',
      ],
      about: {
        companyName: 'Snapchat',
        legalName: 'Snapchat Inc',
        companyType: 'Private',
        employees: 1500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Snapchat',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 56,
        },
        {
          month: 'Feb',
          riskScore: 27,
        },
        {
          month: 'Mar',
          riskScore: 78,
        },
        {
          month: 'Apr',
          riskScore: 75,
        },
        {
          month: 'May',
          riskScore: 18,
        },
        {
          month: 'Jun',
          riskScore: 69,
        },
        {
          month: 'Jul',
          riskScore: 85,
        },
      ],
    },
    {
      id: '4adcaa1d-760b-4c2c-8fb9-c6cbe1b1033a',
      name: 'Snowflake',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/snowflake/0.1.1/53f5298918795ca2332e8e91428d1aed9e5deee03c017bea70c53290aa5c0dac/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 64,
      riskCategory: 'Critical',
      canonicalResourceType: 1,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'SSN',
        'Email',
        'Browsing Trends',
        'School',
        'Purchase History',
        'Search History',
        'Address',
        'Employer',
        'Salary',
        'Shoe Size',
        'Last Name',
        'Medical History',
        'ZIP',
        'User ID',
        'Snacking Trends',
        'First Name',
      ],
      about: {
        companyName: 'Snowflake',
        legalName: 'Snowflake Inc',
        companyType: 'Private',
        employees: 500,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Snowflake',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 37,
        },
        {
          month: 'Feb',
          riskScore: 22,
        },
        {
          month: 'Mar',
          riskScore: 60,
        },
        {
          month: 'Apr',
          riskScore: 38,
        },
        {
          month: 'May',
          riskScore: 43,
        },
        {
          month: 'Jun',
          riskScore: 38,
        },
        {
          month: 'Jul',
          riskScore: 28,
        },
        {
          month: 'Aug',
          riskScore: 41,
        },
        {
          month: 'Sep',
          riskScore: 39,
        },
        {
          month: 'Oct',
          riskScore: 38,
        },
        {
          month: 'Nov',
          riskScore: 64,
        },
      ],
    },
    {
      id: '7149ff70-99fc-48fa-a770-5013bffe1c4e',
      name: 'Splash',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/splash/1.61.9/30d65b321478aef840a592663043729715c1a06c7b9a379b7ca53c10b05d9104/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 99,
      riskCategory: 'Extreme',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Middle Initial',
        'SSN',
        'User ID',
        'Salary',
        'ZIP',
        'Gender',
        'Shoe Size',
        'Employer',
        'Purchase History',
        'Job Title',
        'Last Name',
        'First Name',
        'Search History',
        'Email',
        'School',
        'Snacking Trends',
      ],
      about: {
        companyName: 'Splash',
        legalName: 'Splash Inc',
        companyType: 'Private',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Splash',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 74,
        },
        {
          month: 'Feb',
          riskScore: 37,
        },
        {
          month: 'Mar',
          riskScore: 52,
        },
        {
          month: 'Apr',
          riskScore: 99,
        },
      ],
    },
    {
      id: '0d339934-e76f-4497-800f-b266a74f4346',
      name: 'TikTok Ads',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/tiktok_ads/1.30.9/c5d49f16d222d036ff865b7a2a4de7a321f8cdc8c3c7aedc31e6667c73fbab03/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 48,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Marketing',
          code: 'marketing',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'SSN',
        'First Name',
        'User ID',
        'Gender',
        'School',
        'Purchase History',
        'Shoe Size',
        'Middle Initial',
        'ZIP',
        'Address',
        'Search History',
        'Snacking Trends',
        'Last Name',
        'Browsing Trends',
        'Email',
        'Employer',
      ],
      about: {
        companyName: 'TikTok',
        legalName: 'TikTok Inc',
        companyType: 'Public',
        employees: 5000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/TikTok Ads',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 48,
        },
      ],
    },
    {
      id: '574cab2b-cb8d-449b-9d61-7327c127b782',
      name: 'Twitter Ads',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/twitter_ads/0.1.4/49bc251000bbeae7a6c1ec3bdfd0fbf7c7c3cc49d30c1ff27a504d213c870cac/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 44,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'Marketing',
          code: 'marketing',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Snacking Trends',
        'Browsing Trends',
        'School',
        'Employer',
        'ZIP',
        'Middle Initial',
        'Address',
        'Job Title',
        'Medical History',
        'Search History',
        'Salary',
        'Purchase History',
        'User ID',
        'Gender',
        'Last Name',
        'First Name',
      ],
      about: {
        companyName: 'Twitter',
        legalName: 'Twitter Inc',
        companyType: 'Private',
        employees: 50,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Twitter Ads',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 33,
        },
        {
          month: 'Feb',
          riskScore: 18,
        },
        {
          month: 'Mar',
          riskScore: 18,
        },
        {
          month: 'Apr',
          riskScore: 41,
        },
        {
          month: 'May',
          riskScore: 30,
        },
        {
          month: 'Jun',
          riskScore: 44,
        },
      ],
    },
    {
      id: '7cf25dcd-3c0b-441b-a63c-a75ddb4cc2b3',
      name: 'Yotpo/Swell',
      logo: 'https://dev.ketchcdn.com/transom/assets/ketch_apps/swell/1.31.10/271c7b1a26b1350ac571312befb8271ae6b04cd1aa03ae65fcf7ac2c78006795/logo.svg',
      summary: 'Found on properties but not disclosed in your consent',
      riskScore: 43,
      riskCategory: 'Critical',
      canonicalResourceType: 2,
      purposes: [
        {
          name: 'Analytics',
          code: 'analytics',
        },
        {
          name: 'DSR Processing (Controller)',
          code: 'dsr_processing',
        },
        {
          name: 'Candidate Recruiting',
          code: 'candidate_recruiting',
        },
      ],
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: [
        'Employer',
        'Job Title',
        'Search History',
        'First Name',
        'SSN',
        'Last Name',
        'Email',
        'ZIP',
        'Medical History',
        'Purchase History',
        'Gender',
        'Browsing Trends',
        'Address',
        'Middle Initial',
        'School',
        'Shoe Size',
      ],
      about: {
        companyName: 'Yotpo/Swell',
        legalName: 'Yotpo/Swell Inc',
        companyType: 'Private',
        employees: 12000,
        industry: 'Internet Software & Services',
        sector: 'Information Technology',
        linkedIn: 'https://www.linkedin.com/in/Yotpo/Swell',
      },
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 39,
        },
        {
          month: 'Feb',
          riskScore: 31,
        },
        {
          month: 'Mar',
          riskScore: 21,
        },
        {
          month: 'Apr',
          riskScore: 41,
        },
        {
          month: 'May',
          riskScore: 40,
        },
        {
          month: 'Jun',
          riskScore: 38,
        },
        {
          month: 'Jul',
          riskScore: 32,
        },
        {
          month: 'Aug',
          riskScore: 43,
        },
      ],
    },
  ],
  totalResults: 41,
}
