import { useMemo } from 'react'
import * as Yup from 'yup'
import { flatten } from 'lodash'
import { ObjectLiteral } from 'interfaces'
import { UpsertDeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { ExperienceDefaultDisplay } from 'interfaces/experiences-v2/experienceDefaultDisplay'
import { ExperienceType, LegacyExperienceType } from 'interfaces/experiences-v2/experienceType'
import { useCheckIsDeploymentPlanCodeAvailable } from 'api/deploymentPlans-v2/mutations/useCheckIsDeploymentPlanCodeAvailable'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { excludeFalsy } from 'utils/helpers/excludeFalsy'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'

export enum DeploymentPlanFormSpecialValues {
  NO_EXPERIENCE = 'No Experience',
  NO_DOCUMENT = 'No Document',
}

export type DeploymentPlanFormValues = {
  code: string
  name: string
  isOrchestrationOnly?: boolean
  privacyPolicyCode: string | DeploymentPlanFormSpecialValues.NO_DOCUMENT
  termsOfServiceCode: string | DeploymentPlanFormSpecialValues.NO_DOCUMENT
  themeID: string
  policyScopeExperiences: {
    [policyScopeCode: string]: {
      [ExperienceType.Consent]: {
        experienceID: string | DeploymentPlanFormSpecialValues.NO_EXPERIENCE
        experienceDefault: ExperienceDefaultDisplay | ''
      }
      [ExperienceType.Preference]: {
        experienceID: string | DeploymentPlanFormSpecialValues.NO_EXPERIENCE
      }
    }
  }
}

export const useValidationSchema = ({
  isEditMode,
  isEntitledToContentAndDisclosure,
}: {
  isEditMode?: boolean
  isEntitledToContentAndDisclosure: boolean
}) => {
  const { mutateAsync: handleCheckIsDeploymentPlanCodeAvailable } = useCheckIsDeploymentPlanCodeAvailable()
  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsDeploymentPlanCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(
    () =>
      Yup.object().shape({
        ...(!isEditMode && {
          code: codeValidationSchema,
        }),
        name: Yup.string().max(75, 'Name must not exceed 75 characters').required('Required'),
        ...(!isRelease(Release.PropertyThemeDocuments) && {
          themeID: Yup.string().required('Required'),
        }),
        ...(!isRelease(Release.PropertyThemeDocuments) && {
          privacyPolicyCode: Yup.string().required('Required'),
        }),
        ...(!isRelease(Release.PropertyThemeDocuments) && {
          termsOfServiceCode: Yup.string().required('Required'),
        }),
        policyScopeExperiences: Yup.lazy(policyScopeCodesMap => {
          const policyScopeCodes = Object.keys(policyScopeCodesMap as ObjectLiteral)

          const validators = policyScopeCodes.reduce(
            (acc, code) => ({
              ...acc,
              [code]: Yup.object().shape({
                ...(isEntitledToContentAndDisclosure && {
                  [ExperienceType.Consent]: Yup.object().shape({
                    experienceID: Yup.string().required('Required'),
                    experienceDefault: Yup.number().when('experienceID', {
                      is: DeploymentPlanFormSpecialValues.NO_EXPERIENCE,
                      otherwise: Yup.number().required('Required'),
                    }),
                  }),
                }),
                [ExperienceType.Preference]: Yup.object().shape({
                  experienceID: Yup.string().required('Required'),
                }),
              }),
            }),
            {},
          )

          return Yup.object().shape(validators)
        }),
      }),
    [isEditMode, codeValidationSchema, isEntitledToContentAndDisclosure],
  )
}

export const mapValuesToPayload = ({
  values,
  isEntitledToContentAndDisclosure,
}: {
  values: DeploymentPlanFormValues
  isEntitledToContentAndDisclosure: boolean
}): UpsertDeploymentPlanDTO => {
  const policyScopeExperiences = flatten(
    Object.entries(values.policyScopeExperiences).map(([policyScopeCode, policyScopeExperience]) => {
      const consent = policyScopeExperience[ExperienceType.Consent]
      const preference = policyScopeExperience[ExperienceType.Preference]

      const isNoExperienceConsent = consent.experienceID === DeploymentPlanFormSpecialValues.NO_EXPERIENCE
      const isNoExperiencePreference = preference.experienceID === DeploymentPlanFormSpecialValues.NO_EXPERIENCE

      return [
        isEntitledToContentAndDisclosure && {
          policyScopeCode,
          experienceType: LegacyExperienceType.CONSENT as const,
          experienceID: isNoExperienceConsent ? '' : consent.experienceID,
          ...(!isNoExperienceConsent && {
            experienceDefault: consent.experienceDefault || ExperienceDefaultDisplay.MODAL,
          }),
        },
        {
          policyScopeCode,
          experienceType: LegacyExperienceType.PREFERENCE as const,
          experienceID: isNoExperiencePreference ? '' : preference.experienceID,
        },
      ].filter(excludeFalsy)
    }),
  )

  return {
    code: values.code,
    name: values.name,
    isOrchestrationOnly: values.isOrchestrationOnly,
    ...(!isRelease(Release.PropertyThemeDocuments) && {
      themeID: values.themeID,
    }),
    ...(!isRelease(Release.PropertyThemeDocuments) && {
      privacyPolicyCode:
        values.privacyPolicyCode === DeploymentPlanFormSpecialValues.NO_DOCUMENT ? '' : values.privacyPolicyCode,
    }),
    ...(!isRelease(Release.PropertyThemeDocuments) && {
      termsOfServiceCode:
        values.termsOfServiceCode === DeploymentPlanFormSpecialValues.NO_DOCUMENT ? '' : values.termsOfServiceCode,
    }),
    policyScopeExperiences,
  }
}
