// layout utils
import { ProtectedRoute } from 'components/ui-routing/ProtectedRoute'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'

import { PermitsStatisticsContainer } from 'pages/insights/permitsStatistics/PermitsStatisticsContainer'
import { RightsStatistics } from 'pages/insights/rightsStatistics/RightsStatistics'
import { AuditLogsContainer } from 'pages/insights/auditLogs/AuditLogsContainer'
import { DataMapPurposeContainer } from 'pages/insights/dataMap/view/purposes/DataMapPurposeContainer'
import { DataMapPurposeDataSystemView } from 'pages/insights/dataMap/view/purposes/dataSystem/DataMapPurposeDataSystemView'
import { ActivityAndAccessContainer } from 'pages/insights/activityAndAccess/ActivityAndAccessContainer'
import { UserActivityContainer } from 'pages/insights/activityAndAccess/userActivity/UserActivityContainer'
import { AccessUtilizationContainer } from 'pages/insights/activityAndAccess/accessUtilization/AccessUtilizationContainer'
import { DataUsageContainer } from 'pages/insights/activityAndAccess/dataUsage/DataUsageContainer'
import { DataRisksContainer } from 'pages/insights/dataRisks/DataRisksContainer'
import { UnutilizedDataContainer } from 'pages/insights/dataRisks/unutilizedData/UnutilizedDataContainer'
import { OverAccessContainer } from './dataRisks/overAccess/OverAccessContainer'
import { ThirdPartyRiskContainer } from './dataRisks/thirdPartyRisk/ThirdPartyRiskContainer'
import { PoliciesControlsContainer } from './dataRisks/policiesControls/PoliciesControlsContainer'
import { relativePath } from '../../utils/routing/relativePath'
import { PurposesTab } from './dataMap/home/components/PurposesTab'
import { ProcessingActivitiesTab } from './dataMap/home/components/ProcessingActivitiesTab'
import { DataMap } from './dataMap/home/DataMap'
import { DataMapProcessingActivity } from './dataMap/view/processingActivities/DataMapProcessingActivity'
import { OverviewTab } from './dataMap/view/processingActivities/components/OverviewTab'
import { DataSystemsTab } from './dataMap/view/processingActivities/components/DataSystemsTab'
import { ReportsOverview } from './overview/ReportsOverview'

export const reportsRoutesChildren = [
  {
    index: true,
    element: <ProtectedRoute component={ReportsOverview} permissions={[]} requireAllPermissions={false} />,
  },
  {
    element: <ProtectedRoute component={ReportsOverview} permissions={[]} requireAllPermissions={false} />,
    path: RoutesManager.insights.root.path,
  },
  {
    element: <ProtectedRoute component={RightsStatistics} permissions={PERMISSIONS.RIGHT_INSIGHTS_READ} />,
    path: RoutesManager.insights.rights.root.path,
  },
  {
    element: <ProtectedRoute component={RightsStatistics} permissions={PERMISSIONS.RIGHT_INSIGHTS_READ} />,
    path: RoutesManager.insights.rights.chineseTranslation.path,
  },
  {
    element: <ProtectedRoute component={PermitsStatisticsContainer} permissions={PERMISSIONS.PERMIT_INSIGHTS_READ} />,
    path: RoutesManager.insights.permits.root.path,
  },
  {
    element: <ProtectedRoute component={PermitsStatisticsContainer} permissions={PERMISSIONS.PERMIT_INSIGHTS_READ} />,
    path: RoutesManager.insights.permits.chineseTranslation.path,
  },
  {
    element: (
      <ProtectedRoute
        component={AuditLogsContainer}
        permissions={[PERMISSIONS.PERMIT_INSIGHTS_READ, PERMISSIONS.RIGHT_INSIGHTS_READ]}
      />
    ),
    path: RoutesManager.insights.auditLogs.root.path,
  },
  {
    element: <ProtectedRoute component={DataMap} permissions={PERMISSIONS.DATAMAP_READ} />,
    path: relativePath(RoutesManager.insights.dataMap.root.pattern),
    children: [
      {
        element: <ProtectedRoute component={PurposesTab} permissions={PERMISSIONS.DATAMAP_READ} />,
        path: relativePath(RoutesManager.insights.dataMap.purposes.root.pattern),
      },
      {
        element: <ProtectedRoute component={ProcessingActivitiesTab} permissions={PERMISSIONS.DATAMAP_READ} />,
        path: relativePath(RoutesManager.insights.dataMap.processingActivities.root.pattern),
        children: [],
      },
    ],
  },
  {
    element: <ProtectedRoute component={DataMapProcessingActivity} permissions={PERMISSIONS.DATAMAP_READ} />,
    path: RoutesManager.insights.dataMap.processingActivities.view.root.pattern,
    children: [
      {
        element: <ProtectedRoute component={OverviewTab} permissions={PERMISSIONS.DATAMAP_READ} />,
        path: relativePath(RoutesManager.insights.dataMap.processingActivities.view.overview.root.pattern),
      },
      {
        element: <ProtectedRoute component={DataSystemsTab} permissions={PERMISSIONS.DATAMAP_READ} />,
        path: relativePath(RoutesManager.insights.dataMap.processingActivities.view.dataSystems.root.pattern),
      },
    ],
  },
  {
    element: <ProtectedRoute component={ActivityAndAccessContainer} permissions={PERMISSIONS.DEMO} />, // TODO:BAC - change permissions when no longer a demo only route
    path: RoutesManager.insights.activityAndAccess.root.path,
  },
  {
    element: <ProtectedRoute component={DataRisksContainer} permissions={PERMISSIONS.DEMO} />, // TODO: Update accordingly on feature complete
    path: RoutesManager.insights.dataRisks.root.path,
  },

  {
    element: <ProtectedRoute component={DataMapPurposeDataSystemView} permissions={PERMISSIONS.DATAMAP_READ} />,
    path: RoutesManager.insights.dataMap.view.dataSystem.root.path,
  },
  {
    element: <ProtectedRoute component={DataMapPurposeContainer} permissions={PERMISSIONS.DATAMAP_READ} />,
    path: `${RoutesManager.insights.dataMap.view.root.path}/*`,
  },
  {
    element: <ProtectedRoute component={UserActivityContainer} permissions={PERMISSIONS.DEMO} />, // TODO:BAC - change permissions when no longer a demo only route,
    path: `${RoutesManager.insights.activityAndAccess.userActivity.root.path}/*`,
  },
  {
    element: <ProtectedRoute component={DataUsageContainer} permissions={PERMISSIONS.DEMO} />, // TODO:BAC - change permissions when no longer a demo only route
    path: `${RoutesManager.insights.activityAndAccess.dataUsage.root.path}/*`,
  },
  {
    element: <ProtectedRoute component={AccessUtilizationContainer} permissions={PERMISSIONS.DEMO} />, // TODO:BAC - change permissions when no longer a demo only route
    path: `${RoutesManager.insights.activityAndAccess.accessUtilization.root.path}/*`,
  },
  {
    element: <ProtectedRoute component={UnutilizedDataContainer} permissions={PERMISSIONS.DEMO} />, // TODO: Update accordingly on feature complete
    path: `${RoutesManager.insights.dataRisks.unutilizedData.root.path}/*`,
  },
  {
    element: <ProtectedRoute component={OverAccessContainer} permissions={PERMISSIONS.DEMO} />, // TODO: Update accordingly on feature complete
    path: `${RoutesManager.insights.dataRisks.overAccess.root.path}/*`,
  },
  {
    element: <ProtectedRoute component={ThirdPartyRiskContainer} permissions={PERMISSIONS.DEMO} />, // TODO: Update accordingly on feature complete
    path: `${RoutesManager.insights.dataRisks.thirdPartyRisk.root.path}/*`,
  },
  {
    element: <ProtectedRoute component={PoliciesControlsContainer} permissions={PERMISSIONS.DEMO} />, // TODO: Update accordingly on feature complete
    path: `${RoutesManager.insights.dataRisks.policiesControls.root.path}/*`,
  },
]
