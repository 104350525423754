import { v4 } from 'uuid'
import { isProd, isLocal, isUat } from 'utils/environment'
import Rollbar from 'rollbar'
import { CacheKeys } from 'utils/CacheUtils'

export enum levels {
  ERROR = 'error',
  WARN = 'warn',
  INFO = 'info',
  DEBUG = 'debug',
}

export interface LogToken {
  errorMsg: string
  level: levels
  pageUrl: string
  sessionId: string
  userId: string
}

const getLogObject = (level: levels, errorMsg: string): LogToken => {
  const userId = localStorage.getItem(CacheKeys.userId) || 'unknown-user'
  const sessionId = localStorage.getItem(CacheKeys.sessionId) || v4()
  localStorage.setItem(CacheKeys.sessionId, sessionId)
  return {
    errorMsg,
    level,
    pageUrl: `${window.location.hostname}${window.location.pathname}`,
    sessionId,
    userId,
  }
}

const handleLogging = (logObject: LogToken, args?: unknown) => {
  // eslint-disable-next-line no-useless-return
  if (isLocal() || isUat()) {
    // eslint-disable-next-line no-console
    console[logObject.level](logObject, args)
  } else if (isProd()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Rollbar[logObject.level](logObject, args)
  } else {
    return
  }
}

const log = (level: levels) => (errorCode: string, args?: unknown) => {
  const logObject = getLogObject(level, errorCode)
  handleLogging(logObject, args)
}

export const Logger = {
  error: log(levels.ERROR),
  info: log(levels.INFO),
  warn: log(levels.WARN),
  debug: log(levels.DEBUG),
}
