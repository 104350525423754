import React from 'react'
import { Icon, Chip, ChipProps, IconDictionary, CustomIconName, FileExtensionIcon } from '@ketch-com/deck'
import { FileWithPreview } from '../dropzoneV2/interfaces'

type AttachmentChipV2Props = Omit<ChipProps, 'deleteIcon'> & {
  file: FileWithPreview
  deleteIconName?: CustomIconName
  iconColor?: string
}
/**
 * V2 of the attachment chip required to support new Dropzone's use of standard File type.
 */
export const AttachmentChipV2: React.FC<AttachmentChipV2Props> = ({ ...rest }) => {
  const { deleteIconName, size, iconColor, ...restProps } = rest

  return (
    <Chip
      size={size || 'small'}
      icon={<FileExtensionIcon iconVariant="outlined" extension={rest?.file?.name} />}
      onDelete={rest?.onDelete || rest?.onClick}
      deleteIcon={<Icon name={deleteIconName || IconDictionary.ODownload} />}
      {...restProps}
    />
  )
}
