import { APIListRequestParams } from 'api/common/utils'
import { FetchAssetsPaginatedResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  canonicalResourceType?: number | null | undefined
  hookId?: string | null | undefined
  nextToken?: string | null | undefined
  query?: string | null | undefined
  resourceTypeCode?: string | null | undefined
}>

export const fetchAssetsPaginatedV3 = ({
  canonicalResourceType,
  resourceTypeCode,
  query,
  hookId,
  limit,
  nextToken,
}: Params) => {
  return API.get<FetchAssetsPaginatedResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v3/assets`,
      params: {
        filters: {
          ...(canonicalResourceType && {
            canonicalResourceType,
          }),
          ...(resourceTypeCode && {
            resourceTypeCode,
          }),
          ...(query && {
            query,
          }),
          ...(hookId && {
            hookID: hookId,
          }),
          limit,
          ...(nextToken && {
            nextToken,
          }),
        },
      },
    }),
  )
}
