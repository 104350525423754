import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { checkIsLabelCodeAvailableV2 } from 'api/labels/fetchers/checkIsLabelCodeAvailableV2'
import { useAuth } from 'utils/hooks/useAuth'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

const useCustomMutation = createUseMutation({
  mutationFn: checkIsLabelCodeAvailableV2,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCheckIsLabelCodeAvailableV2 = (config?: Config) => {
  const { userData } = useAuth()
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    organizationCode: userData.organizationCode,
    isV2FeatureFlagEnabled,
  })
}
