import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { PutProcessingActivityResponseBodyDTO, PutProcessingActivityRequestBodyDTO } from '@ketch-com/figurehead'

type Params = {
  id: string
  formData: PutProcessingActivityRequestBodyDTO
}

export const editProcessingActivity = ({ id, formData }: Params) =>
  API.put<PutProcessingActivityResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/processing-activities/${id}`,
    }),
    formData,
  )
