import { useState } from 'react'
import { debounce } from 'lodash'

import { useRelatedAssetsPaginatedV2 } from 'api/assets/queries/useRelatedAssetsPaginatedV2'
import { AssetDTO, ResourceDTO } from '@ketch-com/figurehead'
import { ASSET_TYPE_ENUM } from 'interfaces/assets'

type Args = { isEnabled?: boolean; assetCode?: string; resourceTypeCode?: string }

export const useRelatedResourceSearchOptions = ({ isEnabled = true, assetCode = '', resourceTypeCode = '' }: Args) => {
  const [queryValue, setQueryValue] = useState('')
  const [assetType, setAssetType] = useState<number | undefined>()
  const [options, setOptions] = useState<ResourceDTO[]>([])
  const [placeholderOptions, setPlaceholderOptions] = useState<ResourceDTO[]>([])

  const { isLoading: isLoadingResources, isFetching: isFetchingResources } = useRelatedAssetsPaginatedV2({
    enabled: !!assetType && !!queryValue && isEnabled,
    itemsPerPage: 500,
    params: {
      canonicalResourceType: assetType,
      query: queryValue,
      assetCode,
      resourceTypeCode,
    },
    onSuccess: ({ data }) => {
      const payload = (data.hierarchies || []).map((r: AssetDTO) => {
        return r?.resource
      })
      setOptions(payload as ResourceDTO[])
    },
  })

  const { isLoading: isLoadingPlaceholderResources, isFetching: isFetchingPlaceholderResources } =
    useRelatedAssetsPaginatedV2({
      enabled: !!assetCode,
      itemsPerPage: 100,
      params: {
        canonicalResourceType: ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_ATTRIBUTE,
        assetCode,
        resourceTypeCode,
      },
      onSuccess: ({ data }) => {
        const payload = (data.hierarchies || []).map((r: AssetDTO) => {
          return r?.resource
        })
        setPlaceholderOptions(payload as ResourceDTO[])
      },
    })

  return {
    queryValue,
    setSearchValue: debounce((searchValue, assetTypeValue: number) => {
      setQueryValue(searchValue)
      setAssetType(assetTypeValue)
    }, 500),
    // de-duplicate array after merging "placeholderOptions" and "options"
    resourceOptions: [...new Map([...options, ...placeholderOptions].map(v => [v.id, v])).values()],
    isLoadingResources:
      isLoadingResources || isFetchingResources || isLoadingPlaceholderResources || isFetchingPlaceholderResources,
    setResourceOptions: setOptions,
  }
}
