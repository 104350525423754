import { Button, Checkbox, Icon, Radio, theme } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { memo, useCallback } from 'react'
import { ControlledInlineEditProps, ControlledInlineEdit } from './ControlledInlineEdit'

interface FieldOptions {
  value: string
}

export interface FieldOptionsEditorProps extends FieldOptions {
  index: number
  optionIndex: number
  onValueChange: (value: string, index: number, optionIndex: number) => void
  onDeleteOption: (index: number, optionIndex: number) => void
  isMultiSelect: boolean
}

export const CustomFieldOption: React.FC<FieldOptionsEditorProps> = memo(
  ({ value, index, optionIndex, onValueChange, onDeleteOption, isMultiSelect }) => {
    const handleSaveValue: ControlledInlineEditProps['onChange'] = useCallback(
      val => {
        onValueChange(val, index, optionIndex)
      },
      [index, onValueChange, optionIndex],
    )

    return (
      <Box display="flex" justifyContent="space-between" borderBottom={`1px solid ${theme.palette.Common.Divider}`}>
        <Box display="flex" paddingBottom={1} overflow="scroll" paddingRight={1}>
          {isMultiSelect ? <Checkbox disabled /> : <Radio disabled />}
          <ControlledInlineEdit placeholder={value} value={value} onChange={handleSaveValue} />
        </Box>
        <Button
          variant="iconSubtle"
          onClick={() => {
            onDeleteOption(index, optionIndex)
          }}
        >
          <Icon name="OBin" />
        </Button>
      </Box>
    )
  },
)
