import Box from '@mui/material/Box'
import React, { useContext } from 'react'
import { Card, Icon, theme } from '@ketch-com/deck'
import { ProcessingActivitiesContext } from '../context/ProcessingActivitiesContext'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormMode } from 'interfaces/formModes/formMode'

export const ProcessingActivitiesSummary: React.FC = () => {
  const { navigate, summary, canWrite } = useContext(ProcessingActivitiesContext)

  return (
    <Box display="flex" alignItems="center" gap={2.5} width="100%">
      {/* Summary tiles */}
      <Card
        cardVariant="stretchWidth"
        cardTitle={!!summary.total ? String(summary.total) : '0'}
        subTitle="Processing Activities"
        isNumerical
      />
      <Card
        cardVariant="stretchWidth"
        cardTitle={!!summary.approved ? String(summary.approved) : '0'}
        subTitle="Approved"
        textColor={theme.palette.greenHaze.main}
        backgroundColor="#9bf7ac80"
        isNumerical
        primaryIcon={<Icon name="FCheckRound" width="32px" height="32px" />}
      />

      <Card
        cardVariant="stretchWidth"
        cardTitle={!!summary.requested ? String(summary.requested) : '0'}
        subTitle="Requested"
        textColor={theme.palette.sphere.main}
        backgroundColor={theme.palette.sphereFaded.main}
        isNumerical
        primaryIcon={<Icon name="OArrowWall" width="32px" height="32px" />}
      />
      {/* Only show create tile if user has write permissions */}
      {canWrite && (
        <Card
          cardVariant="fixedWidth"
          cardTitle="Request New Processing"
          isClickable
          onClick={() =>
            navigate(
              RoutesManager.policyCenter.processingActivities.upsert.getURL({ id: 'new', formMode: FormMode.CREATE }),
            )
          }
        />
      )}
    </Box>
  )
}
