import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { ConsentExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/utils/consent/interfaces'

const initialState = {
  values: {} as ConsentExperienceFormValues | PreferenceExperienceFormValues,
  referrer: '',
}

export const rightsQueueFilterSlice = createSlice({
  name: 'rightsQueueFilterSlice',
  initialState,
  reducers: {
    setExperienceBuilderReduxState: (
      state,
      action: PayloadAction<{ values: ConsentExperienceFormValues | PreferenceExperienceFormValues; referrer: string }>,
    ) => {
      const { values, referrer } = action.payload
      state.values = values
      state.referrer = referrer
    },
    resetExperienceBuilderReduxState: () => {
      return initialState
    },
  },
})

export const { setExperienceBuilderReduxState, resetExperienceBuilderReduxState } = rightsQueueFilterSlice.actions

export default rightsQueueFilterSlice.reducer
