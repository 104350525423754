import { APIListRequestParams } from 'api/common/utils'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { API } from 'utils/api/api'
import { FetchAppsResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIListRequestParams<{
  query?: string
  event?: string
}>

export const fetchSystems = ({ query, event, start, limit }: Params) =>
  API.get<FetchAppsResponseBodyDTO>(
    formatRequestString({
      entityUrl: '/api/apps',
      params: {
        filters: {
          start,
          limit,
          ...(query && {
            query,
          }),
          ...(event && {
            event,
          }),
        },
      },
    }),
  )
