import { APIListRequestParams } from 'api/common/utils'
import { FetchLabelDefinitionsResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  includeIssues?: boolean
  includeSystemLabels?: boolean
}>

export const fetchClassificationsPaginatedV3 = ({
  includeMetadata,
  includeIssues,
  limit,
  includeSystemLabels,
  nextToken,
}: Params) => {
  return API.get<FetchLabelDefinitionsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v3/labels`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          limit,
          ...(includeSystemLabels && {
            includeSystemLabels,
          }),
          ...(nextToken && {
            nextToken,
          }),
        },
      },
    }),
  )
}
