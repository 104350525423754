import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { useCallback } from 'react'
import { useDeDuplicatedConnections } from './useDeDuplicatedConnections'

export const useGetAssetTechnologySpecificHumanReadableName = () => {
  const connections = useDeDuplicatedConnections()

  const getAssetTechnologySpecificHumanReadableName = useCallback(
    (assetSummary?: AssetSummaryDTO | null) => {
      const resourceTypeCode = assetSummary?.asset?.resource?.resourceType?.code
      const connectionType = connections.find(connection => connection.code === resourceTypeCode)
      return connectionType?.name?.split?.(' ')?.[1]
    },
    [connections],
  )

  return getAssetTechnologySpecificHumanReadableName
}
