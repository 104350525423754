import { Routes, Route, useLocation } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { DataMapPurposeView } from './overview/DataMapPurposeView'
import { DataMapPurposeProcessingActivities } from './processingActivities/DataMapPurposeProcessingActivities'

type Props = {
  purposeCode: string
}

export const DataMapPurposeRoutes: React.FC<Props> = ({ purposeCode }) => {
  const location = useLocation()

  return (
    <Routes location={location}>
      <Route path="/" element={<DataMapPurposeView purposeCode={purposeCode} />} />
      <Route
        path={RoutesManager.insights.dataMap.view.overview.root.pattern}
        element={<DataMapPurposeView purposeCode={purposeCode} />}
      />
      {/* TODO:JB - Re-enable once backend is ready */}
      {/*<Route*/}
      {/*  path={RoutesManager.insights.dataMap.view.dataSystems.root.pattern}*/}
      {/*  element={<DataMapPurposeDataSystems purposeCode={purposeCode} />}*/}
      {/*/>*/}
      <Route
        path={RoutesManager.insights.dataMap.view.processingActivities.root.pattern}
        element={<DataMapPurposeProcessingActivities purposeCode={purposeCode} />}
      />
    </Routes>
  )
}
