import { Checkbox, CheckboxProps } from '@ketch-com/deck'

import { useFormikContext } from 'formik'
import { CookieFormDTO } from 'interfaces/cookies/cookie'
import { PurposeDTO } from 'interfaces/purposes/purpose'

type FormCookiePurposesCheckboxProps = {
  row: PurposeDTO
} & CheckboxProps

const cookiePurposesCheckboxFormKeys = {
  purposes: 'purposes',
  propertyCookies: 'propertyCookies',
} as const

export const FormCookiePurposesCheckbox = ({ row, ...rest }: FormCookiePurposesCheckboxProps) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext<CookieFormDTO>()

  const isChecked = !!values.purposes?.find(item => item.ID === row.ID)

  const onChange = () => {
    setFieldTouched(cookiePurposesCheckboxFormKeys.purposes)

    if (isChecked) {
      const nextPurposes = values.purposes?.filter(item => item.ID !== row.ID)

      setFieldValue(cookiePurposesCheckboxFormKeys.purposes, nextPurposes)

      if (!nextPurposes?.length) {
        setFieldValue(cookiePurposesCheckboxFormKeys.propertyCookies, [])
      }
    } else {
      setFieldValue(cookiePurposesCheckboxFormKeys.purposes, [...(values?.purposes || []), row])
    }
  }

  return <Checkbox {...rest} checked={isChecked} onChange={onChange} inputProps={{ 'aria-label': 'controlled' }} />
}
