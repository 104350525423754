import {
  SubscriptionTopicFormData,
  SubscriptionControlFormData,
  OrchestrationType,
  OrchestrationData,
} from 'pages/policyCenter/subscriptions/interfaces'
import {
  SubscriptionTopicDTO,
  SubscriptionControlDTO,
  SubscriptionContactMethodDTO,
  GetSubscriptionTopicOrchestrationsResponseBodyDTO,
  GetSubscriptionControlOrchestrationsResponseBodyDTO,
} from '@ketch-com/figurehead'
import { appParams } from 'pages/policyCenter/subscriptions/constants'

type subscriptionTopicArgs = {
  subscriptionTopic: SubscriptionTopicDTO
  subscriptionContactMethods: SubscriptionContactMethodDTO[]
  subscriptionTopicOrchestrations?: GetSubscriptionTopicOrchestrationsResponseBodyDTO
}

type subscriptionControlArgs = {
  subscriptionControl: SubscriptionControlDTO
  subscriptionControlOrchestrations: GetSubscriptionControlOrchestrationsResponseBodyDTO
}

export const getSubscriptionTopicFormInitialValues = ({
  subscriptionTopic = {} as SubscriptionTopicDTO,
  subscriptionContactMethods = [],
  subscriptionTopicOrchestrations = {},
}: subscriptionTopicArgs): SubscriptionTopicFormData => {
  const contactMethods: SubscriptionTopicFormData['contactMethods'] = {}
  subscriptionContactMethods?.forEach?.(channel => {
    const { code = '' } = channel
    contactMethods[code] = { status: false, orchestrations: [] }
    contactMethods[code].status = subscriptionTopic.contactMethods?.includes(code) ?? false
    // subscriptionTopicOrchestrations is available only in edit mode
    contactMethods[code].orchestrations =
      subscriptionTopicOrchestrations?.orchestrations?.[code]?.map(orchestration => {
        if (orchestration.app)
          return {
            type: OrchestrationType.SYSTEM,
            appCode: orchestration?.app?.code || '',
            appInstanceId: orchestration?.app?.instanceId || '',
            appParams: orchestration?.app?.params || appParams,
            name: orchestration?.app?.appName || '',
            appLogo: { url: orchestration?.app?.logoUrl, title: orchestration?.app?.appName },
            appInstanceName: orchestration?.app?.connectionName || '',
          }
        if (orchestration.webhook)
          return {
            type: OrchestrationType.WEBHOOK,
            webhookId: orchestration?.webhook?.id || '',
            name: orchestration?.webhook?.name || '',
          }

        return {} as OrchestrationData
      }) || []
  })
  return {
    basicDetails: {
      name: subscriptionTopic.name || '',
      description: subscriptionTopic.description || '',
      code: subscriptionTopic.code || '',
      translations: subscriptionTopic.translations || {},
    },
    contactMethods,
    legalBasis: {
      code: subscriptionTopic.legalBasisCode || '',
    },
  }
}

export const getSubscriptionControlFormInitialValues = ({
  subscriptionControl = {} as SubscriptionControlDTO,
  subscriptionControlOrchestrations = {},
}: subscriptionControlArgs): SubscriptionControlFormData => {
  return {
    basicDetails: {
      name: subscriptionControl.name || '',
      description: subscriptionControl.description || '',
      code: subscriptionControl.code || '',
      translations: subscriptionControl.translations || {},
    },
    orchestrations:
      subscriptionControlOrchestrations?.orchestrations?.map(orchestration => {
        if (orchestration.app)
          return {
            type: OrchestrationType.SYSTEM,
            appCode: orchestration?.app?.code,
            appInstanceId: orchestration?.app?.instanceId,
            name: orchestration?.app?.appName || '',
            appLogo: { url: orchestration?.app?.logoUrl, title: orchestration?.app?.appName },
            appInstanceName: orchestration?.app?.connectionName || '',
          }
        if (orchestration.webhook)
          return {
            type: OrchestrationType.WEBHOOK,
            webhookId: orchestration?.webhook?.id,
            name: orchestration?.webhook?.name,
          }

        return {} as OrchestrationData
      }) || [],
  } as SubscriptionControlFormData
}
