// The below code is translated from harbormaster
// DO NOT ADJUST PARSING LOGIC HERE WITHOUT ENSURING CHANGES ARE SAME IN HARBORMASTER

export const AssigneeFormatDelimter = '||'
export const TeamPrefix = 'team|'

// FromAssignee splits assignee into its constituent teamID, userID parts
// Assignee may be in one of these formats:
// 'team|uuid||auth0|<id>' (auth0 may also be 'samlp|org|<id>', etc)
// 'auth0|<id>'
// 'team|uuid'
export const fromAssignee = (assignee: string): [string, string] => {
  const assigneeStringParts = assignee.split(AssigneeFormatDelimter)

  if (assigneeStringParts.length > 1) {
    return [assigneeStringParts[0], assigneeStringParts[1]]
  }

  if (assigneeStringParts.length === 1) {
    if (assigneeStringParts[0].startsWith(TeamPrefix)) {
      return [assigneeStringParts[0], '']
    }

    return ['', assigneeStringParts[0]]
  }

  return ['', '']
}

export const toAssignee = (team: string, user: string): string => {
  if (team && user) {
    return `${team}${AssigneeFormatDelimter}${user}`
  }

  if (team) {
    return team
  }

  return user
}
