import React from 'react'
import { Box, styled } from '@mui/material'
import { sortBy } from 'lodash'

import { useAuth } from 'utils/hooks/useAuth'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useUserOrganizations } from 'api/users/queries/useUserOrganizations'
import { CircularProgress, CircularProgressSize, Icon, ListItem, TextInput, theme } from '@ketch-com/deck'
import { saveOrgId } from 'utils/CacheUtils'

const ScrollingListBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxHeight: 200,
  overflowY: 'auto',
})

export interface Props {
  onChange: () => void
}

export const HeaderOrganizationsList: React.FC<Props> = ({ onChange }) => {
  const { userData, logout } = useAuth()
  const [searchString, setSearchString] = React.useState('')
  const orgSearchInputId = 'organizations-search-input'

  const isUserIdPresent = !!userData.userId

  const { data: organizations, isLoading: isUserOrganizationsLoading } = useUserOrganizations({
    enabled: isUserIdPresent,
    params: {
      includeMetadata: true,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch user organizations', type: 'error' })
    },
  })

  const isReady = !isUserOrganizationsLoading

  const handleChangeOrganization = async (nextOrganizationId: string) => {
    try {
      logout()
      saveOrgId(nextOrganizationId)
      window.location.reload()
    } catch (e) {
      showToast({ content: 'Failed to switch organization', type: 'error' })
    }
  }

  const organizationsList = sortBy(
    organizations.map(organization => {
      const { name, auth0OrgID } = organization

      return {
        content: name,
        active: auth0OrgID === userData?.organizationId,
        onClick: () => {
          onChange()
          handleChangeOrganization(auth0OrgID)
        },
      }
    }),
    'content',
  )

  const filteredOrganizationsList = organizationsList.filter(o =>
    searchString ? o.content.toLowerCase().includes(searchString.toLowerCase()) : true,
  )

  const shouldShowSearchInput = organizationsList?.length > 4

  /* focus to search input on menu open */
  React.useEffect(() => {
    if (shouldShowSearchInput) document!.getElementById(orgSearchInputId)?.focus()
  }, [shouldShowSearchInput])

  const onSearchChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setSearchString(e.target.value as string)

  return (
    <Box>
      {shouldShowSearchInput && (
        <TextInput
          variant={'ghost'}
          value={searchString}
          placeholder="Search"
          startIcon={<Icon name={'OMag'} iconColor={theme.palette.Black.o48} />}
          fullWidth
          autoFocus
          onChange={onSearchChange}
          sx={{ mb: '12px' }}
        />
      )}
      <ScrollingListBox>
        {isReady ? (
          filteredOrganizationsList.map((organization, index) => (
            <ListItem
              key={`${index}-${organization.content}`}
              title={organization.content}
              onClick={organization.onClick}
              selected={organization.active}
            />
          ))
        ) : (
          <CircularProgress size={CircularProgressSize.xLarge} sx={{ my: 2 }} />
        )}
      </ScrollingListBox>
    </Box>
  )
}
