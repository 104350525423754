import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeStyles, createStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import pluralize from 'pluralize'

import { Table } from 'components/ui-layouts/table/Table'
import { AssetSummaryDTO, GetAssetHierarchiesResponseBodyDTO, LabelDTO } from '@ketch-com/figurehead'
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { Waypoint } from 'react-waypoint'
import { Badge } from 'components/ui-kit/badge/Badge'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { CountCellRenderer, DataCategoriesCountCellRenderer, AssetsListTableActionsCellRenderer } from '.'
import { useDeDuplicatedConnections } from 'pages/assetManager/hooks/useDeDuplicatedConnections'
import { getLabelDTOUniqueIdentifier } from 'pages/assetManager/assets/view/components/AssetDetailViewRelatedAssets/components/DataCategoriesBulkAssignModal/utils/getLabelDTOUniqueIdentifier'
import { RoutesManager } from 'utils/routing/routesManager'
import { pushEntryOntoBackToUrlHistory } from 'store/assetDetailViewSlice'
import { useAppDispatch } from 'store/hooks'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      tableRowSelected: {
        border: `1px solid ${palette.sphere.main}`,
      },
      assetsTable: {
        backgroundColor: palette.white.main,
        borderRadius: spacing(1),
      },
      tableAssetTypeIcon: {
        width: 22,
        height: 22,
        marginRight: spacing(1),
      },
      dataSetIcon: {
        marginRight: spacing(1),
      },
      buttonOptions: {
        background: palette.white.main,
        padding: spacing(1, 1),
      },
    }),
  { name: 'SnowflakeDatasetTable' },
)

type Props = {
  assets: AssetSummaryDTO[]
  isLoading: boolean
  isFetchingNextPage?: boolean
  resourceTypeCode?: string
  hasNextPage?: boolean
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<GetAssetHierarchiesResponseBodyDTO, unknown>>
}

export const SnowflakeDatasetTable: React.FC<Props> = ({
  assets,
  isLoading,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  resourceTypeCode,
}) => {
  const classes = useStyles()
  const connections = useDeDuplicatedConnections()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const connectionType = connections.find(connection => connection.code === resourceTypeCode)
  const attributeConnectionType = connections.find(connection => connection.code === connectionType?.contains?.[0])
  const attributeConnectionTypeName = attributeConnectionType?.name?.split(' ')?.[1] || 'Attribute'
  const attributeConnectionTypeCode = attributeConnectionType?.code || ''

  return (
    <Table
      isSmallHeaderText
      items={assets}
      pending={isLoading}
      className={classes.assetsTable}
      rowIdKey={assetSummary => assetSummary?.asset?.resource?.id || ''}
      cellSettings={{
        name: {
          width: 445,
          label: 'Name',
          cellRenderer: assetSummary => (
            <TextCellRenderer
              onClick={e => {
                dispatch(pushEntryOntoBackToUrlHistory(location.pathname))
                navigate(
                  RoutesManager.assetManager.assets.detail.root.getURL({
                    code: assetSummary?.asset?.resource?.id || '',
                    resourceTypeCode: assetSummary?.asset?.resource?.resourceType?.code || '',
                  }),
                )
              }}
              value={assetSummary?.asset?.resource?.name}
            />
          ),
        },
        personalDataType: {
          width: 147,
          label: 'Data Sensitivity',
          cellRenderer: assetSummary => {
            const hasPersonalDataTypes = (assetSummary?.asset?.dataCategory?.length || 0) > 0
            return hasPersonalDataTypes ? (
              <Box display="flex" alignItems="center" gap={1}>
                {assetSummary?.asset?.dataCategory?.map((dataCategory: LabelDTO) => {
                  return <Badge key={getLabelDTOUniqueIdentifier(dataCategory)}>{dataCategory?.value}</Badge>
                })}
              </Box>
            ) : (
              <EmptyValueRenderer color="darkDuskFaded" />
            )
          },
        },
        attributes: {
          width: 100,
          label: pluralize(attributeConnectionTypeName, 7),
          cellRenderer: assetSummary => {
            const count = assetSummary?.hierarchyCounts?.[attributeConnectionTypeCode] || 0
            return <CountCellRenderer count={count} />
          },
        },
        labels: {
          width: 100,
          label: 'Classifications',
          cellRenderer: (assetSummary, index) => {
            return (
              <>
                <DataCategoriesCountCellRenderer assetSummary={assetSummary} />
                {(assets?.length || 0) - 5 === index ? (
                  <Waypoint
                    onEnter={() => {
                      if (!isFetchingNextPage && hasNextPage) fetchNextPage()
                    }}
                  />
                ) : null}
              </>
            )
          },
        },
        actions: {
          cellRenderer: assetSummary => {
            const shouldDisableEditAndViewLinks = false
            return (
              <AssetsListTableActionsCellRenderer
                isDisabled={shouldDisableEditAndViewLinks}
                buttonOptionsClassName={classes.buttonOptions}
                assetSummary={assetSummary}
              />
            )
          },
        },
      }}
    />
  )
}
