import { makeStyles, createStyles } from '@mui/styles'

import BranchSvg from 'assets/images/branch.svg'

export const useTreeStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      treeContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '36px 0',
        position: 'relative',
        // top circle
        '&::before': {
          borderRadius: '0.5rem',
          border: `2px solid ${palette.iron.main}`,
          content: "''",
          display: 'block',
          height: '0.325rem',
          width: '0.325rem',
          left: '-0.095rem',
          position: 'absolute',
          top: '-0.325rem',
        },
        // bottom circle
        '&::after': {
          borderRadius: '0.325rem',
          border: `2px solid ${palette.iron.main}`,
          content: "''",
          display: 'block',
          height: '0.325rem',
          width: '0.325rem',
          left: '-0.095rem',
          position: 'absolute',
          bottom: '-0.325rem',
        },
      },
      treeNodeSVG: {
        borderLeft: `2px solid ${palette.iron.main}`,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '2rem',
        position: 'relative',
        '&::before': {
          backgroundImage: `url(${BranchSvg})`,
          content: "''",
          display: 'block',
          height: '2.5rem',
          left: '-0.25rem',
          position: 'absolute',
          top: 0,
          width: '2.5rem',
        },
      },
      treeChild: {
        background: 'rgba(218, 221, 222, 0.25)',
        borderRadius: spacing(2),
        alignItems: 'center',
        flex: 1,
        marginBottom: '2rem',
        padding: spacing(2),
        position: 'relative',
      },
      withMarginRight: {
        marginRight: spacing(1.5),
      },
      capitalize: {
        textTransform: 'capitalize',
      },
    }),
  { name: 'TreeStyles' },
)
