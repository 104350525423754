import { FormColorInput } from '../FormColorInput'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormCheckbox } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/FormCheckbox'

export const BannerThemeDescriptionForm: React.FC = () => {
  return (
    <SidebarFormGroup sx={{ pt: 0 }}>
      <FormColorInput
        name={themeUpsertFormKeys.bannerDescriptionTextColor}
        label={'Description Text Color'}
        fullWidth
        required
      />
      <FormColorInput name={themeUpsertFormKeys.bannerDescriptionLinkColor} label={'Link Color'} fullWidth required />
      <FormControlLabel
        control={<FormCheckbox name={themeUpsertFormKeys.bannerDescriptionUnderlineLinks} />}
        label={<Typography variant={'label'}>Underline Links</Typography>}
      />
    </SidebarFormGroup>
  )
}
