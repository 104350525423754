import { Box, Typography } from '@mui/material'
import React from 'react'

import { ReactComponent as KetchTextLogo } from 'assets/icons/ketch-text-logo.svg'
import { getContrastColor } from '../utils/getContrastColor'

type Props = {
  contrastBackgroundColor: string
}

export const KetchBadge: React.FC<Props> = ({ contrastBackgroundColor }) => {
  const contrastColor = getContrastColor(contrastBackgroundColor, '#000000', '#FFFFFF')

  return (
    <>
      <Box display="flex" gap="6px" alignItems={'center'}>
        <Typography fontSize={10} fontWeight={600} lineHeight="14.5px" letterSpacing={'-0.1px'} color={contrastColor}>
          Powered by
        </Typography>
        <KetchTextLogo fill={contrastColor} />
      </Box>
    </>
  )
}
