import { FC } from 'react'
import { Formik } from 'formik'
import { PopUp, Button } from '@ketch-com/deck'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'
import { useFinalizeRequestModalUtils } from './hooks'
import { AllowAppealCard } from './components'
import { RightInvocationStatusDTO } from '@ketch-com/figurehead'
import { finalizeRequestFormKeys } from './utils/getValidationSchema'
import { MAX_FILE_UPLOAD_SIZE_4_MB } from 'utils/constants/maxFileUploadSize'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { FormInput } from 'components/form/FormInput'
import { FormDropZone } from 'components/form/FormDropZone/FormDropZone'
import { isTwilioConversationsEnabled } from 'utils/helpers/isTwilioConversationsEnabled'
const { FulfilledRightInvocationStatus, RejectedRightInvocationStatus } = RightInvocationStatusDTO

type Props = {
  onClose: () => void
}

export const FinalizeRequestModal: FC<Props> = ({ onClose }) => {
  const {
    initialValues,
    validationSchema,
    resolve,
    isUploading,
    isMessageRequired,
    isCoverLetterRequired,
    allowAppeal,
    rightInvocation,
  } = useFinalizeRequestModalUtils({ onClose })

  // only allow appeal one level deep
  const isAlreadyAppealed = rightInvocation?.appeal?.isAppealed

  const subjectCommunicationEnabled = isTwilioConversationsEnabled()

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={resolve}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ values, isSubmitting, handleSubmit, errors, touched }) => {
        const showAllowAppealSection =
          allowAppeal &&
          !isAlreadyAppealed &&
          !!values.finalizedStatus &&
          values.finalizedStatus !== FulfilledRightInvocationStatus
        logObjectDetails(
          {
            allowAppeal,
            isSubmitting,
            values,
            errors,
            touched,
            rightInvocation,
            showAllowAppealSection,
          },
          'FinalizeRequestModal.tsx',
          'white',
          false, // false ensures will log only in debug mode (i.e. ?debug=log)
        )
        return (
          <>
            <PopUp
              isOpen
              isLoading={isUploading}
              onClose={onClose}
              variant="modal"
              showCloseButton
              title="Finalize Request"
              popUpActionChildren={
                <>
                  <Button color="tertiary" onClick={() => onClose()} size="large">
                    Cancel
                  </Button>
                  <Button size="large" onClick={() => handleSubmit()}>
                    Finalize
                  </Button>
                </>
              }
            >
              <Box display="flex" flexDirection="column">
                <FormRadioGroup
                  sx={{
                    '& .MuiFormControlLabel-root': {
                      marginLeft: 'unset',
                    },
                  }}
                  formPropertyName={finalizeRequestFormKeys.finalizedStatus}
                  options={[
                    { label: 'Complete', value: FulfilledRightInvocationStatus },
                    { label: 'Reject', value: RejectedRightInvocationStatus },
                  ].map(v => ({
                    title: v.label,
                    value: v.value,
                  }))}
                  valueKey="value"
                  label={
                    <Typography variant="body" color="darkDusk.main" mb={2}>
                      Please choose how you want to proceed
                    </Typography>
                  }
                  renderLabel={(option, isSelected) => (
                    <Typography variant={isSelected ? 'label' : 'body'}>{option.title}</Typography>
                  )}
                  row
                />

                {/* Allow Appeal Input */}

                {showAllowAppealSection && (
                  <>
                    <Typography mb={2}>
                      The regulation this DSR was submitted under supports the right to appeal. To allow this Data
                      Subject their right to appeal, please indicate below.{' '}
                    </Typography>

                    <AllowAppealCard />
                  </>
                )}

                {subjectCommunicationEnabled ? (
                  <Box mb={3} mt={2}>
                    <FormInput
                      label="Subject Communication Message"
                      formPropertyName={finalizeRequestFormKeys.subjectCommunicationMessage}
                      fullWidth
                      required={isMessageRequired}
                      rows={3}
                      multiline
                      shouldUpdateDebounced
                    />
                  </Box>
                ) : null}
                <FormDropZone
                  label="Upload Cover Letter"
                  required={isCoverLetterRequired}
                  formPropertyName={finalizeRequestFormKeys.coverLetter}
                  accept={{ 'application/pdf': ['.pdf'] }}
                  maxFiles={1}
                  maxSize={MAX_FILE_UPLOAD_SIZE_4_MB}
                  containerSx={{
                    mt: subjectCommunicationEnabled ? undefined : 2,
                    mb: 2,
                  }}
                  hideDropzoneOnUpload
                />
                <FormInput label="Reason For Finalization" formPropertyName="reasonForFinalization" fullWidth />
              </Box>
            </PopUp>
          </>
        )
      }}
    </Formik>
  )
}
