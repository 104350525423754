import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetAssetsStatisticsResponseBodyDTO } from '@ketch-com/figurehead'
import { APIRequestParams } from 'api/common/utils'

type Params = APIRequestParams<{
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchAssetsStatistics = ({ isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/assets-statistics`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/assets-statistics`

  return API.get<GetAssetsStatisticsResponseBodyDTO>(
    formatRequestString({
      entityUrl,
    }),
  )
}
