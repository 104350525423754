import React from 'react'
import clsx from 'clsx'
import { InputBase } from '@mui/material'
import { SxProps, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseTextArea: {
        borderRadius: 5,
        boxSizing: 'border-box',
        fontFamily: typography.fontFamily,
        fontSize: typography.pxToRem(14),
        lineHeight: typography.pxToRem(20),
        letterSpacing: '0.01em',
        width: '100%',
        color: palette.darkDusk.main,

        '& .MuiInputBase-inputMultiline': {
          height: 60,
        },

        '& .MuiTextAreaBase-TextArea': {
          padding: 0,
          margin: 0,
        },

        '&.Mui-disabled': {
          border: `1px solid ${palette.fadedDarkGrey.main}`,
          background: palette.fadedGrey.main,
        },

        '&:not(.Mui-disabled)': {
          '&:hover': {
            border: `1px solid ${palette.darkDuskFaded.main}`,
          },

          '&.Mui-focused': {
            border: `1px solid ${palette.sphere.main}`,
          },
        },
      },

      // Sizes:
      sizeSmall: {
        padding: '4px 14px',
        fontSize: typography.pxToRem(14),
        minHeight: 68,
      },
      sizeRegular: {
        padding: '12px 14px',
        fontSize: typography.pxToRem(14),
        minHeight: 84,
      },
      sizeLarge: {
        padding: '12px 14px',
        fontSize: typography.pxToRem(14),
        minHeight: 144,
        '& .MuiInputBase-inputMultiline': {
          height: '144px !important',
        },
      },

      // States:
      invalid: {
        borderColor: `${palette.chileanFire.main} !important`,
      },

      withBorder: {
        border: `1px solid ${palette.iron.main}`,
      },
    }),
  { name: 'TextArea' },
)

export interface Props {
  /** ID of an element */
  id?: string
  /** Name of an element */
  name?: string
  /** Test ID of an element for selectors */
  testId?: string
  /** Custom element className */
  className?: any
  /** TextArea rows number (controlled) */
  rows?: number
  /** TextArea value (controlled) */
  value: string | number
  /** TextArea value change method */
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  /** TextArea value blur method */
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  /** TextArea value focus method */
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  /** TextArea placeholder */
  placeholder?: string
  /** TextArea type */
  type?: 'number' | 'password' | 'text'
  /** TextArea size */
  size?: 'small' | 'regular' | 'large'
  /** Should be focused on mount */
  autofocus?: boolean
  /** Valid state */
  valid?: boolean
  /** Disable state */
  disabled?: boolean
  /** Full width */
  fullWidth?: boolean
  /** Has border */
  hasBorder?: boolean
  /** Custom styles */
  sx?: SxProps<Theme>
}

/**
 * -
 */
export const TextArea: React.FC<Props> = ({
  name,
  id = name,
  testId,
  className,
  rows = 3,
  value,
  placeholder = '',
  type = 'text',
  size = 'regular',
  autofocus = false,
  valid = true,
  disabled = false,
  fullWidth = false,
  hasBorder = true,
  onChange,
  onBlur,
  onFocus,
  sx,
}) => {
  const classes = useStyles()

  const textAreaClassName = clsx(
    classes.baseTextArea,
    {
      [classes.sizeSmall]: size === 'small',
      [classes.sizeRegular]: size === 'regular',
      [classes.sizeLarge]: size === 'large',
      [classes.invalid]: !valid,
      [classes.withBorder]: hasBorder,
    },
    className,
  )

  return (
    <InputBase
      id={id}
      multiline
      rows={rows}
      name={name}
      data-test-id={`TextArea-${testId}`}
      type={type}
      placeholder={placeholder}
      autoFocus={autofocus}
      disabled={disabled}
      fullWidth={fullWidth}
      className={textAreaClassName}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      sx={sx}
    />
  )
}
