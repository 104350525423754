import { useApplicationsPaginated } from 'api/applications/queries/useApplicationsPaginated'
import { ApplicationsList } from 'pages/consentAndRights/applications/list/ApplicationsList'

export const ApplicationsListContainer = () => {
  const {
    data: { applications, totalResults },
    isLoading,
    isFetching,
    pagination,
  } = useApplicationsPaginated({
    params: {
      includeMetadata: true,
      includeIssues: true,
    },
  })

  return (
    <ApplicationsList
      isLoading={isLoading}
      isFetching={isFetching}
      applications={applications}
      pagination={pagination}
      totalResults={totalResults}
    />
  )
}
