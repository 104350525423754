import React from 'react'
import { Box, Chip } from '@mui/material'

import { Text } from 'components/ui-kit/typography/Text'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'

type Props = {
  attributeName?: string
}

export const AttributeChip: React.FC<Props> = ({ attributeName }) => {
  if (!attributeName) return <EmptyValueRenderer />
  return (
    <Chip
      clickable={false}
      // TODO:BAC - implement onClick
      onClick={() => {}}
      label={
        <Box display="flex" alignItems="center">
          <Text size={14} color="black">
            {attributeName}
          </Text>
        </Box>
      }
      sx={{
        textTransform: 'capitalize',
        backgroundColor: 'fadedGrey.main',
        '&:hover': {
          backgroundColor: `rgba(87, 116, 255, 0.2);`,
        },
      }}
    />
  )
}
