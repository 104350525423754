import React from 'react'
import { Box, Chip, SxProps, Theme } from '@mui/material'
import { alpha } from '@mui/material/styles'

import { Text } from 'components/ui-kit/typography/Text'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const purposeChipSx: SxProps<Theme> = {
  textTransform: 'capitalize',
  color: 'royalBlue.main',
  cursor: 'pointer',
  backgroundColor: 'sphereFaded.main',
  '&:hover': {
    backgroundColor: theme => alpha(theme.palette.sphere.main, 0.2),
  },
}

type PurposeChipProps = {
  purpose: string
  onDelete?: () => void
}

export const PurposeChip: React.FC<PurposeChipProps> = ({ purpose, onDelete = () => {} }) => {
  if (!purpose) return <EmptyValueRenderer color="darkDuskFaded" />
  return (
    <Chip
      clickable={false}
      // TODO:BAC - implement onClick
      onClick={() => {}}
      label={
        <Box display="flex" alignItems="center">
          <Text size={14} color="royalBlue">
            {purpose}
          </Text>
        </Box>
      }
      sx={purposeChipSx}
      // onDelete is required because of the deleteIcon prop
      onDelete={onDelete}
      deleteIcon={
        <ChevronRightIcon
          style={{
            fontSize: 20,
          }}
          sx={[
            {
              fill: ({ palette }) => palette.royalBlue.main,
            },
          ]}
        />
      }
    />
  )
}
