import React from 'react'

import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { getApplicationEntityName } from 'utils/messages/entityName'
import { Banner, Button, BannerProps } from '@ketch-com/deck'

type Props = Omit<BannerProps, 'children'> & {
  actions?: {
    title: string
    onClick: () => void
  }
  entityType: ApplicationEntity
}

export const getBannerText = (entityType: ApplicationEntity) => {
  switch (entityType) {
    case ApplicationEntity.PERMIT_PROPAGATION:
      return 'Purposes for this property have not been configured yet.'

    case ApplicationEntity.WEBTAG:
      return 'Purposes for this tag have not been configured yet.'

    case ApplicationEntity.DPIA:
      return 'Ketch recommends you conduct a DPIA given the canonical purpose chosen for this purpose.'

    case ApplicationEntity.POLICY_ON_HOLD:
      return "This policy is on hold because it's not assigned to any workflow."

    default: {
      const entityName = getApplicationEntityName({ entityType })

      return `${entityName.singular} configurations need to be updated`
    }
  }
}

export const IssuesMessage: React.FC<Props> = ({ actions, entityType, severity = 'error', ...rest }) => {
  return (
    <>
      <Banner
        sx={{
          width: '100%',
        }}
        title={getBannerText(entityType)}
        action={
          actions && (
            <Button variant="link" onClick={actions.onClick}>
              {actions.title}
            </Button>
          )
        }
        severity={severity}
      />
    </>
  )
}
