import React from 'react'

import { WorkflowDTO } from 'interfaces/workflows'
import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { DataGrid, EmptyState } from '@ketch-com/deck'

import { Box, Typography } from '@mui/material'
import { useWorkflowTabVersionsColumns } from './utils/workflowTabVersionsUtils'

type Props = {
  workflow: WorkflowDTO
  versions: WorkflowDTO[]
}

export const WorkflowTabVersions: React.FC<Props> = ({ workflow, versions }) => {
  const { queries, setQueryParam, removeQueryParam } = useQueryParams<{ version?: string }>()
  const { name, code } = workflow
  const { version: qVersion } = queries

  const sortedVersions = [...versions].sort((a, b) => (b?.createdAt || 0) - (a?.createdAt || 0))
  const latestVersion = sortedVersions[0]?.version
  const currentVersion = queries.version || workflow.version

  const handleViewVersion = ({ version }: WorkflowDTO) => {
    if (version === latestVersion) {
      if (qVersion) {
        removeQueryParam('version')
      }
    } else {
      setQueryParam('version', version!)
    }
  }

  const columns = useWorkflowTabVersionsColumns({
    latestVersion,
    currentVersion,
    handleViewVersion,
  })

  return (
    <>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
          { title: 'Workflows', link: RoutesManager.orchestration.workflows.root.getURL() },
          {
            title: name || code,
            link: RoutesManager.orchestration.workflows.view.root.getURL({ code }),
          },
          { title: 'Versions' },
        ]}
      />

      <Box display="flex" flexDirection="column" gap={4} mb={4}>
        <Typography variant="h3">Version History</Typography>
        {!versions.length ? (
          <Box
            mt={2.5}
            bgcolor="bone.main"
            borderRadius="11px"
            py={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <EmptyState
              iconName="OClock"
              title="No versions exist for the selected workflow"
              subTitle="As you make changes to this workflow, you can view that history here"
            />
          </Box>
        ) : (
          <DataGrid
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              includeOutliers: false,
              columns: columns.map(column => column.field),
              expand: true,
            }}
            getRowHeight={() => 'auto'}
            columns={columns}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowHoverStates
            disableRowSelectionOnClick
            getRowId={row => row.version}
            rows={sortedVersions}
            hideFooter
          />
        )}
      </Box>
    </>
  )
}
