import React, { useContext } from 'react'
import { ConsentExperienceTab } from '../../utils/consent/constants'
import { UpsertBanner, UpsertModal, UpsertJit } from './components'
import { ExperienceUpsertContext } from '../../context/ExperienceUpsertContext'

type Props = {}

export const UpsertConsent: React.FC<Props> = () => {
  const { activeTab, isEditMode, organizationLanguages } = useContext(ExperienceUpsertContext)

  return (
    <>
      {activeTab === ConsentExperienceTab.BANNER && (
        <UpsertBanner isEditMode={isEditMode} organizationLanguages={organizationLanguages} />
      )}

      {activeTab === ConsentExperienceTab.MODAL && (
        <UpsertModal isEditMode={isEditMode} organizationLanguages={organizationLanguages} />
      )}

      {activeTab === ConsentExperienceTab.JIT && (
        <UpsertJit isEditMode={isEditMode} organizationLanguages={organizationLanguages} />
      )}
    </>
  )
}
