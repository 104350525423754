import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchLabelsV2 } from 'api/labels/fetchers/fetchLabelsV2'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.labels,
  queryFn: fetchLabelsV2,
  select: res => res?.data?.labels || [],
})

export type UseLabelsConfig = CustomQueryConfig<typeof useCustomQuery>

export const useLabelsV2 = (config?: UseLabelsConfig) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
