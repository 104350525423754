import * as Yup from 'yup'
import { validationRegExp } from 'utils/constants/forms/validationRegExp'

export const getValidationSchema = () =>
  Yup.object().shape({
    schedule: Yup.object().shape({
      time: Yup.string().when('isCustomizeStartingTimeChecked', {
        is: true,
        then: Yup.string()
          .max(5, 'Must be 5 characters or less')
          .matches(validationRegExp.TIME, 'not a valid time format')
          .required('Required'),
        otherwise: Yup.string(),
      }),
      period: Yup.string(),
      frequency: Yup.string(),
      day: Yup.string().when('frequency', {
        is: '2',
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
      isCustomizeStartingTimeChecked: Yup.boolean(),
    }),
    name: Yup.string().required(),
    description: Yup.string(),
    dataCategory: Yup.string(),
    labels: Yup.array().of(
      Yup.object().shape({
        code: Yup.string().required('Required'),
        value: Yup.string(),
      }),
    ),
    hooks: Yup.array().of(Yup.object().shape({})),
  })
