import { APIListRequestParams } from 'api/common/utils'
import { FindVerifiersResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  query?: string
}>

export const fetchVerifiers = ({ query, limit, start }: Params) =>
  API.get<FindVerifiersResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/verifiers`,
      params: {
        filters: {
          start,
          limit,
          ...(query && {
            query,
          }),
        },
      },
    }),
  )
