import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { EditAttributeFormValues } from 'interfaces/assets'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'

type Args = { attribute: MaybeNull<AssetSummaryDTO> }

export const getInitialValues = ({ attribute }: Args): EditAttributeFormValues => {
  if (!attribute) {
    return {
      name: '',
      description: '',
      dataCategory: '',
      dataOwner: '',
      labels: [],
      inferredLabels: [],
      identitySpace: '',
    }
  }
  return {
    name: attribute?.asset?.resource?.name || '',
    description: attribute?.asset?.description || '',
    dataCategory: attribute?.asset?.dataCategory?.[0]?.value || '',
    dataOwner: attribute?.asset?.owner || '',
    labels: (attribute?.asset?.assignedLabels || []) as ManageLabelModalLabel[],
    inferredLabels: (attribute?.asset?.inferredLabels || []) as ManageLabelModalLabel[],
    identitySpace: attribute?.asset?.identitySpace?.code || '',
  }
}
