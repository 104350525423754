import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, Icon, TableCell } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { CanonicalPurposeDTO } from 'interfaces/purposes/canonicalPurpose'
import { StatusRenderer } from 'components/renderers/StatusRenderer'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { PurposeDTO } from '@ketch-com/figurehead'
import { getUniqueDataSubjectTypes } from '../edit/utils'
import { DataSubjectTypeBadge } from 'pages/consentAndRights/applications/list/components/DataSubjectTypeBadge'

export const getPermitPropagationViewListColumns = ({
  purposes,
}: {
  purposes:
    | {
        [canonicalPurposeCode: string]: boolean
      }
    | undefined
}): GridColDef<CanonicalPurposeDTO>[] => [
  {
    align: 'left',
    field: 'status',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Status',
    sortable: false,
    renderCell: ({ row: { code } }: GridRenderCellParams<CanonicalPurposeDTO>) => {
      return (
        <TableCell>
          <StatusRenderer active={!!purposes?.[code]} activeTitle="Enabled" inactiveTitle="Disabled" />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'ketchPurpose',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Ketch Purpose',
    sortable: false,
    renderCell: ({ row: { name, code } }: GridRenderCellParams<CanonicalPurposeDTO>) => {
      return <TableCell title={name} subTitle={code} />
    },
  },
  {
    align: 'left',
    field: 'customPurposes',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Associated Custom Purpose(s)',
    sortable: false,
    renderCell: ({ row: { purposes } }: GridRenderCellParams<CanonicalPurposeDTO>) => {
      return <CustomPurposesCell purposes={purposes} />
    },
  },
  {
    align: 'left',
    field: 'dataSubjectTypes',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Subject Type',
    sortable: false,
    renderCell: ({ row: canonicalPurpose }: GridRenderCellParams<CanonicalPurposeDTO>) => {
      return (
        <TableCell>
          <DataSubjectTypeBadge dataSubjectTypes={getUniqueDataSubjectTypes(canonicalPurpose)} />
        </TableCell>
      )
    },
  },
]

const CustomPurposesCell = ({ purposes }: { purposes: PurposeDTO[] | undefined }) => {
  const navigate = useNavigate()
  return (
    <TableCell>
      <Box display="flex" flexDirection="column" gap={1}>
        {(purposes || []).map(({ name, code }) => (
          <Chip
            size={'small'}
            label={name}
            deleteIcon={<Icon name={'OArrowCRight'} />}
            onClick={() => navigate(RoutesManager.policyCenter.purposes.view.overview.root.getURL({ code }))}
            onDelete={() => navigate(RoutesManager.policyCenter.purposes.view.overview.root.getURL({ code }))}
            clickable
            sx={{ width: 'fit-content' }}
          />
        ))}
      </Box>
    </TableCell>
  )
}
