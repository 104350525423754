import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchPermissions } from 'api/permissions/fetchers/fetchPermissions'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.permissions,
  queryFn: fetchPermissions,
  select: res => res?.data?.permissionCodes || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery, 'userId'>

export const usePermissions = (config?: Config) => {
  const { userData } = useAuth()

  return useCustomQuery({
    ...config,
    enabled: !!userData?.organizationCode && !!userData?.userId,
    params: {
      ...config?.params,
      organizationCode: userData.organizationCode,
      userId: userData.userId,
    },
  })
}
