import React, { useContext } from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { round } from 'lodash'

import { ObjectLiteral } from 'interfaces'
import { Button, Icon, theme } from '@ketch-com/deck'
import { WorkflowCanvasContext } from '../WorkflowCanvas/context/WorkflowCanvasContext'

const useStyles = makeStyles(
  ({ typography }) =>
    createStyles({
      wrapper: {
        position: 'absolute',
        bottom: 12,
        left: ({ sidebarWidth }: ObjectLiteral) => sidebarWidth + 12,
        display: 'flex',
        gap: 8,
        zIndex: 10,
      },
      button: {
        background: 'white',
        '&:not(:first-child)': {
          marginLeft: 8,
        },
        boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.20), 0px 1px 5px 0px rgba(0, 0, 0, 0.05);',
      },
      icon: {
        width: 10,
        height: 10,
      },
      zoomValue: {
        height: 34,
        background: 'white',
        fontWeight: 'bold',
        fontSize: typography.pxToRem(13),
        letterSpacing: typography.pxToRem(0.1),
        lineHeight: 1,
        color: '#222222',
        borderRadius: 17,
        padding: '6px 10px',
        display: 'flex',
        alignItems: 'center',
        userSelect: 'none',
        boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.20), 0px 1px 5px 0px rgba(0, 0, 0, 0.05);',

        '&:hover': {
          cursor: 'initial',
        },
      },
    }),
  { name: 'CanvasZoom' },
)

export const CanvasZoom: React.FC = () => {
  const {
    scale,
    sidebarWidth,
    isAtInitialZoom,
    isAtInitialPosition,
    handleZoomIn,
    handleZoomOut,
    handleSetInitialZoom,
    handleSetInitialPosition,
  } = useContext(WorkflowCanvasContext)

  const classes = useStyles({ sidebarWidth })
  const showResetButton = !isAtInitialZoom || !isAtInitialPosition

  const handleResetClick = () => {
    handleSetInitialZoom()
    handleSetInitialPosition()
  }

  return (
    <div className={classes.wrapper}>
      <Button
        variant="iconCustomRounded"
        color="warning"
        enableShadowEffect
        onClick={handleZoomIn}
        sx={{ background: theme.palette.white.main }}
      >
        <Icon name={'OPlus'} />
      </Button>
      <Button
        variant="iconCustomRounded"
        color="warning"
        onClick={handleZoomOut}
        enableShadowEffect
        sx={{ background: theme.palette.white.main }}
      >
        <Icon name={'OMinus'} />
      </Button>

      {showResetButton && (
        <Button
          variant="iconCustomRounded"
          color="warning"
          enableShadowEffect
          onClick={handleResetClick}
          sx={{ background: theme.palette.white.main }}
        >
          <Icon name={'OCrosshair'} />
        </Button>
      )}

      <div className={classes.zoomValue}>{round(100 * scale)}%</div>
    </div>
  )
}
