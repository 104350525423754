import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        padding: '11px 30px',
        minWidth: 120,
        border: `1px solid ${theme.palette.white.main}`,
        borderRadius: 3,
        backgroundColor: theme.palette.white.main,

        '&:focus, &:active': {
          outline: 0,
        },
      },
      inverted: {
        backgroundColor: 'transparent',
      },
    }),
  { name: 'UpsertExperiencePreviewButton' },
)

type Props = {
  children?: React.ReactNode
  inverted?: boolean
  className?: string
  classes?: Partial<ReturnType<typeof useStyles>>
}

export const UpsertExperiencePreviewButton: React.FC<Props> = ({
  children,
  className,
  inverted = false,
  classes: _classes = {},
}) => {
  const classes = useStyles({ classes: _classes })

  return (
    <button type="button" className={clsx(classes.root, { [classes.inverted]: inverted }, className)}>
      {children}
    </button>
  )
}
