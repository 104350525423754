import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { ContextVariableDTO } from '@ketch-com/figurehead'

type Params = {
  code: string
  formData: {
    contextVariable: ContextVariableDTO
  }
}

type Response = { contextVariable: ContextVariableDTO }

export const updateContextVariable = ({ code, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/context-variables/${code}`,
      params: {
        filters: {},
      },
    }),

    formData,
  )
