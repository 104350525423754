import Box from '@mui/material/Box'
import { v4 as uuidv4 } from 'uuid'

import Typography from '@mui/material/Typography'

import { FormTextArea } from 'components/ui-kit/form/textArea/FormTextArea'
import { FormCheckbox } from 'components/ui-kit/form/checkbox/FormCheckbox'
import { FormDropZone } from 'components/ui-kit/form/dropZone/FormDropZone'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { AccessControlType } from 'components/ui-kit/form/imageUpload/hooks'
import { MAX_FILE_UPLOAD_SIZE_4_MB } from 'utils/constants/maxFileUploadSize'
import { isTwilioConversationsEnabled } from 'utils/helpers/isTwilioConversationsEnabled'

interface Props {
  onDelete: (file: FileInfoDTO) => void
}

export const AutomatedFormSection: React.FC<Props> = ({ onDelete }) => {
  const subjectCommunicationEnabled = isTwilioConversationsEnabled()

  return (
    <Box mt={2}>
      <Typography variant="h4" color="fadedDarkGrey.main">
        Subject Communication
      </Typography>

      {subjectCommunicationEnabled ? (
        <>
          <Box mt={2} mb={1}>
            <FormCheckbox
              name="isMessageRequired"
              title="Send message via Subject Communication"
              sx={{ fontWeight: 600 }}
            />
          </Box>
          <Box mb={2}>
            <FormTextArea name="subjectCommunicationMessage" fullWidth required rows={7} />
          </Box>
        </>
      ) : null}

      <Box mb={1} mt={subjectCommunicationEnabled ? undefined : 2}>
        <FormCheckbox name="isCoverLetterRequired" title="Send Cover Letter" sx={{ fontWeight: 600 }} />
      </Box>
      <Box mb={2}>
        <FormDropZone
          accept={{
            'application/pdf': ['.pdf'],
          }}
          name="coverLetterFile"
          maxFiles={1}
          maxSize={MAX_FILE_UPLOAD_SIZE_4_MB}
          onDelete={onDelete}
          subtitle=""
          uploadContext={{
            acl: `${AccessControlType.signed}`,
            bucket: '',
            folder: uuidv4(),
            version: 'cover_letter',
          }}
          validateOnTouch={false}
        />
      </Box>
    </Box>
  )
}
