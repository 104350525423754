import React from 'react'

import { Typography } from '@mui/material'
import { PopUp, Button } from '@ketch-com/deck'

type Props = {
  onSubmit: () => void
  onCancel: () => void
}

export const ConfirmCheckRightModal: React.FC<Props> = ({ onSubmit, onCancel }) => {
  return (
    <PopUp
      isOpen
      title="Warning"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button color="primary" size="large" onClick={() => onSubmit()}>
            Confirm
          </Button>
        </>
      }
      variant="dialog"
    >
      <Typography>
        Ketch recommends fulfilling this right via Consent Management. Would you still like to fulfill the selected
        right via Rights Orchestration?
      </Typography>
    </PopUp>
  )
}
