import moment from 'moment'
import { Box } from '@mui/material'
import { GridRenderCellParams, GridColDef, GridComparatorFn } from '@mui/x-data-grid-premium'
import { Chip, Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import { WorkflowDTO } from 'interfaces/workflows'
import { IssuesCellRenderer } from 'components/renderers/IssuesCellRenderer'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { WorkflowsListDropdown } from './WorkflowsListDropdown'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

const dateComparator: GridComparatorFn<string | undefined> = (v1, v2) => {
  const v1DateMoment = v1 ? moment.unix(+v1) : moment.unix(+0)
  const v2DateMoment = v2 ? moment.unix(+v2) : moment.unix(+0)

  if (moment(v1DateMoment).isBefore(v2DateMoment)) {
    return -1
  } else if (moment(v1DateMoment).isAfter(v2DateMoment)) {
    return 1
  }
  return 0
}

export const useWorkflowsListDataGridColumns = (isEntitledToCreateWorkflow: boolean): GridColDef<WorkflowDTO>[] => {
  return [
    {
      align: 'left',
      field: 'issues',
      headerAlign: 'left',
      headerName: ' ',
      maxWidth: 50,
      sortable: false,

      renderCell: ({ row }: GridRenderCellParams<WorkflowDTO>) => (
        <TableCell>
          <IssuesCellRenderer hasIssues={row.hasIssues} entityType={ApplicationEntity.WORKFLOW} />
        </TableCell>
      ),
    },
    {
      align: 'left',
      field: 'nameAndCode',
      flex: 1,
      headerAlign: 'left',
      headerName: 'Name',
      sortable: false,
      renderCell: ({ row: { name, code } }: GridRenderCellParams<WorkflowDTO>) => {
        return <TableCell title={name} subTitle={code} />
      },
    },
    {
      align: 'left',
      field: 'activityCount',
      flex: 0.6,
      headerAlign: 'left',
      headerName: 'Activity Count',
      sortable: true,
      type: 'number',
      valueGetter: ({ row: { stepCount } }) => stepCount || 0,
      renderCell: ({ row: { stepCount } }: GridRenderCellParams<WorkflowDTO>) => {
        return (
          <TableCell>
            <Chip label={stepCount} size="small" />
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'mappedEvents',
      flex: 0.75,
      headerAlign: 'left',
      headerName: 'Event Assignments',
      valueGetter: ({ row: { customEventIDs = [], canonicalEventIDs = [] } }) =>
        customEventIDs.length + canonicalEventIDs.length,
      sortable: true,
      type: 'number',
      renderCell: ({ row: { customEventIDs = [], canonicalEventIDs = [] } }: GridRenderCellParams<WorkflowDTO>) => {
        return (
          <TableCell>
            <Chip label={customEventIDs.length + canonicalEventIDs.length} size="small" />
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'status',
      flex: 0.6,
      headerAlign: 'left',
      headerName: 'Status',
      sortable: true,
      type: 'boolean',
      valueGetter: params => !!params.row.isActive,
      renderCell: ({ row: { isActive } }: GridRenderCellParams<WorkflowDTO>) => {
        return (
          <TableCell>
            <Status
              icon="Dot"
              variant={StatusVariant.ghost}
              label={isActive ? 'Enabled' : 'Disabled'}
              status={isActive ? StatusState.success : StatusState.draft}
            />
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'canonicalRightCode',
      flex: 0.5,
      headerAlign: 'left',
      headerName: 'Type',
      sortable: true,
      type: 'string',
      valueGetter: params => params.row.canonicalRightCode,
      renderCell: ({ row: { canonicalRightCode } }: GridRenderCellParams<WorkflowDTO>) => {
        return (
          <TableCell>
            {canonicalRightCode ? (
              <Box textTransform="capitalize">
                <Status label={canonicalRightCode} variant={StatusVariant.filled} status={StatusState.new} />
              </Box>
            ) : (
              <EmptyValueRenderer />
            )}
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'updatedAt',
      flex: 0.8,
      headerAlign: 'left',
      headerName: 'Last Updated',
      sortable: true,
      sortComparator: dateComparator,

      valueGetter: params => params.row.updatedAt,
      renderCell: ({ row: { updatedAt } }: GridRenderCellParams<WorkflowDTO>) => {
        return <TableCell>{updatedAt ? <FormattedDateRenderer date={updatedAt} /> : <EmptyValueRenderer />}</TableCell>
      },
    },
    {
      align: 'left',
      field: 'lastRun',
      flex: 0.8,
      headerAlign: 'left',
      headerName: 'Last Run',
      sortable: true,
      sortComparator: dateComparator,
      valueGetter: params => params.row.lastRunAnyVersion,
      renderCell: ({ row: { lastRunAnyVersion } }: GridRenderCellParams<WorkflowDTO>) => {
        return (
          <TableCell>
            {lastRunAnyVersion ? <FormattedDateRenderer date={lastRunAnyVersion} /> : <EmptyValueRenderer />}
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'dropDown',
      flex: 1,
      maxWidth: 60,
      headerAlign: 'left',
      sortable: false,
      headerName: ' ',
      renderCell: ({ row: workflow }: GridRenderCellParams<WorkflowDTO>) => {
        return (
          <TableCell>
            <WorkflowsListDropdown workflow={workflow} isEntitledToCreateWorkflow={isEntitledToCreateWorkflow} />
          </TableCell>
        )
      },
    },
  ]
}
