import React, { createContext } from 'react'
import { DataSystemWidgetsUtils, useDataSystemWidgetsUtils } from '../utils/useDataSystemWidgetsUtils'

export const DataSystemWidgetsContext = createContext({} as DataSystemWidgetsUtils)

type Props = {
  children?: React.ReactNode | undefined
}

export const DataSystemWidgetsContextProvider: React.FC<Props> = ({ children }) => {
  // Use utils hook
  const hookValues = useDataSystemWidgetsUtils()

  return <DataSystemWidgetsContext.Provider value={{ ...hookValues }}>{children}</DataSystemWidgetsContext.Provider>
}

export const withDataSystemWidgetsContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <DataSystemWidgetsContextProvider>
        <Component {...props} />
      </DataSystemWidgetsContextProvider>
    )
  }
}
