import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { updateSubscriptionTopic } from '../fetchers/updateSubscriptionTopic'

const useCustomMutation = createUseMutation({
  mutationFn: updateSubscriptionTopic,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateSubscriptionTopic = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
