import React, { useContext } from 'react'
import Box from '@mui/material/Box'

import { ActionButtons, BodyDescription, BodyTitle, FormTemplates, Request } from './components'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'

type Props = {}

export const RightsPreview: React.FC<Props> = () => {
  const { getOverlayProps } = useContext(ExperienceUpsertContext)
  return (
    <>
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          padding: '16px 32px',
        }}
      >
        <BodyTitle getOverlayProps={getOverlayProps} />

        <BodyDescription getOverlayProps={getOverlayProps} />

        <Box my={4} />

        <Request getOverlayProps={getOverlayProps} />

        <Box my={3} />

        <FormTemplates getOverlayProps={getOverlayProps} />
      </Box>

      <ActionButtons getOverlayProps={getOverlayProps} />
    </>
  )
}
