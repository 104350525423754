import React from 'react'
import { Chip, theme } from '@ketch-com/deck'
import { Tooltip, Typography } from '@mui/material'
import { CustomRightNameJurisdictionRightDTO } from '@ketch-com/figurehead'

type Props = {
  jurisdictionRights: CustomRightNameJurisdictionRightDTO[] | undefined
}

export const JurisdictionCell: React.FC<Props> = ({ jurisdictionRights }) =>
  jurisdictionRights?.length ? (
    <Tooltip
      arrow
      placement="bottom"
      title={
        <>
          {jurisdictionRights.map((right, index) => (
            <ul
              style={{
                listStylePosition: 'outside',
                paddingLeft: '15px',
              }}
              key={`${right.jurisdictionName}${index}`}
            >
              <li>{right.jurisdictionName}</li>
            </ul>
          ))}
        </>
      }
    >
      <span>
        <Chip size="small" clickable label={jurisdictionRights.length} />
      </span>
    </Tooltip>
  ) : (
    <Typography variant="body" color={theme.palette.fadedDarkGrey.main}>
      None
    </Typography>
  )
