import React from 'react'

export const getElementPairs = (e: React.PointerEvent<HTMLDivElement>) => {
  const incomingElement = e.target as HTMLDivElement
  let element: HTMLDivElement
  if (incomingElement.id.includes('foreign-key')) {
    element = incomingElement
  } else {
    element = document.getElementById(incomingElement.id.replace('primary-key', 'foreign-key')) as HTMLDivElement
  }
  const sisterElement = document.getElementById(element.id.replace('foreign-key', 'primary-key')) as HTMLDivElement

  return [element, sisterElement]
}
