import { Button, Icon, theme } from '@ketch-com/deck'
import { Box, Typography, styled } from '@mui/material'
import { useDataMapVisualization } from 'api/dataMap/queries/useDataMapVisualization'
import { EntitledBlurWrapper } from 'components/entitledBlurWrapper/EntitledBlurWrapper'
import { showToast } from 'components/modals/AlertComponent'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { DataMapVisualization } from 'pages/insights/dataMap/components/DataMapVisualization'
import { useIsEntitled, useIsPermitted } from 'utils/hooks'
import { useNavigateGated } from 'utils/routing/hooks/useNavigateGated'
import { RoutesManager } from 'utils/routing/routesManager'

const DataMapBox = styled(Box)({
  height: 320,
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '16px',

  // Clips the datamap image
  display: 'flex',
  justifyContent: 'center',
  maxWidth: '100%',
  // width: '100%',
})

const HeaderBox = styled(Box)({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  height: '72px',
  width: '100%',
  padding: '16px 20px 8px 20px',
  background: 'rgba(255, 255, 255, 0.5)',
  backdropFilter: 'blur(10px)',
  zIndex: 10,

  // Re-enable hover effects for the header box only
  pointerEvents: 'auto',
})

const VisualizationBox = styled(Box)({
  transform: 'translateY(-63px)',
})

export const DatamapOverviewVisualization: React.FC = () => {
  // Visualization data
  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()
  const isEntitledToStatsDatamap = isEntitled(ENTITLEMENTS.STATS_DATAMAP)
  const isPermittedToDatamap = isPermitted(PERMISSIONS.DATAMAP_READ)
  const { data: visualizationData } = useDataMapVisualization({
    enabled: isEntitledToStatsDatamap && isPermittedToDatamap,
    onError: () => {
      showToast({ content: 'Failed to fetch data map visualization', type: 'error' })
    },
  })

  const navigateGated = useNavigateGated()

  return (
    <DataMapBox
      onClick={
        isEntitledToStatsDatamap
          ? () =>
              navigateGated(
                RoutesManager.insights.dataMap.root.getURL(),
                [PERMISSIONS.DATAMAP_READ],
                [ENTITLEMENTS.STATS_DATAMAP],
              )
          : undefined
      }
      // Disable the regular data map hover effects
      sx={{ ...(isEntitledToStatsDatamap ? { pointerEvents: 'none' } : {}) }}
    >
      <HeaderBox>
        <Box>
          <Typography variant="h3" component="p">
            ROPA
          </Typography>
          <Typography color={theme.palette.Text.Secondary}>Explore your live data map.</Typography>
        </Box>
        {isEntitledToStatsDatamap && (
          <Button variant="icon" color="tertiary">
            <Icon name="OArrowCRight" />
          </Button>
        )}
      </HeaderBox>
      <EntitledBlurWrapper entitlements={[ENTITLEMENTS.STATS_DATAMAP]} overlaySx={{ pt: 8 }}>
        <VisualizationBox>
          <DataMapVisualization visualizationData={visualizationData} hideTitle hideExportButton />
        </VisualizationBox>
      </EntitledBlurWrapper>
    </DataMapBox>
  )
}
