import { useContext } from 'react'
import { Formik } from 'formik'
import { EndFlowContext } from '../../../context/EndFlowContext'
import { Chip, ContentGroup, Icon, InfoRow } from '@ketch-com/deck'
import { StepDetailsEndExecutionDataFinalizationDetailsFinalizedStatusDTO } from '@ketch-com/figurehead'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { InfoRowInlineUpload } from 'pages/orchestration/rightsQueue/stepDetails/components/InfoRowInlineUpload'
import { Box } from '@mui/material'
import { isTwilioConversationsEnabled } from 'utils/helpers/isTwilioConversationsEnabled'

const { RejectedStepDetailsEndExecutionDataFinalizationDetailsFinalizedStatus } =
  StepDetailsEndExecutionDataFinalizationDetailsFinalizedStatusDTO

type Props = {}

export const Resolution: React.FC<Props> = () => {
  const { coverLetter, subjectCommunicationMessage, stepDetails } = useContext(EndFlowContext)

  const isRejected =
    stepDetails?.executionData?.end?.finalizationDetails?.finalizedStatus ===
    RejectedStepDetailsEndExecutionDataFinalizationDetailsFinalizedStatus

  const subjectCommunicationEnabled = isTwilioConversationsEnabled()

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <ContentGroup titleVariant="h3" variant="inner-page-header" title="Resolution" />
      <InfoRow title="Request Finalized As" sx={subjectCommunicationEnabled ? undefined : { mb: 2 }}>
        {isRejected ? (
          <Chip label="Rejected" icon={<Icon name="ODecline" />} />
        ) : (
          <Chip label="Completed" icon={<Icon name="OCheck" />} />
        )}
      </InfoRow>

      {subjectCommunicationEnabled ? (
        <InfoRow title="Subject Communication Message">{subjectCommunicationMessage || <EmptyValueRenderer />}</InfoRow>
      ) : null}

      <Formik
        enableReinitialize
        initialValues={{
          coverLetter,
        }}
        onSubmit={() => {}}
        validateOnMount
      >
        {() => (
          <InfoRowInlineUpload
            attachments={coverLetter}
            infoRowTitle="Cover Letter"
            name="coverLetter"
            canEdit={false}
            isRequired={false}
            uploadContext={{}}
          />
        )}
      </Formik>
    </Box>
  )
}
