import React from 'react'
import Box from '@mui/material/Box'

import { Spinner } from 'components/ui-kit/spinner/Spinner'

type Props = {}

export const LoadingOptionsSpinner: React.FC<Props> = () => (
  <Box py={0.75} display="flex" justifyContent="center" alignItems="center">
    <Spinner size={28} />
  </Box>
)
