export enum ApplianceConnectionStatusTypes {
  DISCONNECTED = 0, // note this default value and will not show up in response
  CONNECTED = 1,
  PENDING = 2,
}

export const applianceConnectionStatusNames: {
  [key: number]: ApplianceConnectionStatusName
} = {
  [-1]: 'Status',
  [ApplianceConnectionStatusTypes.DISCONNECTED]: 'Disconnected',
  [ApplianceConnectionStatusTypes.CONNECTED]: 'Connected',
  [ApplianceConnectionStatusTypes.PENDING]: 'Pending',
}

export type ApplianceConnectionStatusName = 'Status' | 'Disconnected' | 'Connected' | 'Pending'

export const applianceConnectionsStatusOptions: ApplianceConnectionStatusName[] = [
  'Status',
  applianceConnectionStatusNames[ApplianceConnectionStatusTypes.DISCONNECTED],
  applianceConnectionStatusNames[ApplianceConnectionStatusTypes.CONNECTED],
  applianceConnectionStatusNames[ApplianceConnectionStatusTypes.PENDING],
]
