import React from 'react'
import { Formik } from 'formik'
import { Modal } from 'components/ui-kit/modal/Modal'
import { useQueryClient } from 'react-query'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { FormTextArea } from 'components/ui-kit/form/textArea/FormTextArea'
import { Box } from '@mui/material'
import { useValidationSchema } from 'pages/assetManager/classifications/hooks'
import { useCreateLabelV2 } from 'api/labels/mutations/useCreateLabelV2'
import { useUpdateLabelV2 } from 'api/labels/mutations/useUpdateLabelV2'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { Classification } from 'pages/assetManager/classifications/types'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type Props = {
  data?: Classification
  onCancel: () => void
}

export const ClassificationModal: React.FC<Props> = ({ data = { name: '', code: '', description: '' }, onCancel }) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )
  const isEditMode = Boolean(data?.name || data?.description)
  const validationSchema = useValidationSchema(isEditMode)
  const queryClient = useQueryClient()

  const { mutateAsync: handleCreateLabel } = useCreateLabelV2({
    onSuccess: async () => {
      showToast({ content: 'Classification created', type: 'success' })
      await queryClient.invalidateQueries(
        isV2FeatureFlagEnabled ? ApiQueryKeys.classificationsPaginatedV2 : ApiQueryKeys.classificationsPaginatedV3,
      )
      onCancel()
    },
    onError: () => {
      showToast({ content: 'Failed to create classification', type: 'error' })
    },
  })

  const { mutateAsync: handleUpdateLabel } = useUpdateLabelV2({
    onSuccess: async () => {
      showToast({ content: 'Classification updated', type: 'success' })
      await queryClient.invalidateQueries(
        isV2FeatureFlagEnabled ? ApiQueryKeys.classificationsPaginatedV2 : ApiQueryKeys.classificationsPaginatedV3,
      )
      onCancel()
    },
    onError: () => {
      showToast({ content: 'Failed to update classification', type: 'error' })
    },
  })

  const onSubmit = async (formValues: Classification) => {
    const formData = {
      labelDefinition: {
        code: formValues?.code || formValues?.name,
        description: formValues?.description || '',
        name: formValues?.name,
      },
    }
    await (isEditMode
      ? handleUpdateLabel({
          params: {
            formData,
            labelCode: formData?.labelDefinition?.code || '',
          },
        })
      : handleCreateLabel({
          params: {
            formData,
          },
        }))
  }

  return (
    <Formik initialValues={data} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Modal
          title={isEditMode ? 'Edit Classification' : 'Create Classification'}
          onCancelBtnClick={onCancel}
          submitBtnLabel={isEditMode ? 'Save' : 'Create'}
          onSubmitBtnClick={handleSubmit}
        >
          <Box mt={2}>
            <FormRow>
              <FormInput autofocus required fullWidth name="name" label="Label" />
            </FormRow>
            <FormRow>
              <FormTextArea required fullWidth label="Description" name="description" rows={8} />
            </FormRow>
          </Box>
        </Modal>
      )}
    </Formik>
  )
}
