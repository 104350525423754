import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import { Chip, DropListButton, Icon, InfoRow, ListItemText, ActionSheetItem } from '@ketch-com/deck'
import { RightsQueueViewContext } from '../../../context/RightsQueueViewContext'
import { RoutesManager } from 'utils/routing/routesManager'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useUpdateRightInvocationAppealWorkflowDefinitionCode } from 'api/workflowExecutions/mutations/useUpdateRightInvocationAppealWorkflowDefinitionCode'
import { showToast } from 'components/modals/AlertComponent'

export const AppealWorkflowInfoRow: React.FC = () => {
  const {
    rightInvocation,
    isAppealed,
    appealWorkflowDefinitionCode,
    appealWorkflow,
    isEditingAppealWorkflow,
    setIsEditingAppealWorkflow,
    appealWorkflows,
    refetchRightInvocation,
    isRightsQueueAdmin,
  } = useContext(RightsQueueViewContext)

  const navigate = useNavigate()

  const { mutateAsync: handleUpdateRightInvocationAppealWorkflowDefinitionCode } =
    useUpdateRightInvocationAppealWorkflowDefinitionCode({
      onError: () => showToast({ type: 'error', content: 'Failed to update appeal workflow.' }),
      onSuccess: async () => {
        await refetchRightInvocation()
        showToast({ type: 'success', content: 'Appeal workflow updated.' })
      },
    })

  const canEditAppealWorkflow = !isAppealed && isRightsQueueAdmin

  const formik = useFormik({
    initialValues: {
      appealWorkflowDefinitionCode: appealWorkflowDefinitionCode || '',
    },
    onSubmit: async values => {
      try {
        await handleUpdateRightInvocationAppealWorkflowDefinitionCode({
          params: {
            rightInvocationId: rightInvocation?.id || '',
            formData: {
              appealWorkflowDefinitionCode: values.appealWorkflowDefinitionCode,
            },
          },
        })
        setIsEditingAppealWorkflow(false)
      } catch (e) {
        console.error(e)
      }
    },
    validationSchema: Yup.object({
      appealWorkflowDefinitionCode: Yup.string().required('Required'),
    }),
  })

  const showError = !!formik.touched.appealWorkflowDefinitionCode && !!formik.errors.appealWorkflowDefinitionCode

  return (
    <InfoRow
      title="Workflow Used"
      isEditable={canEditAppealWorkflow}
      isEditing={isEditingAppealWorkflow}
      onEditChange={() => setIsEditingAppealWorkflow(true)}
      onAcceptChange={formik.handleSubmit}
      onCancelChange={() => {
        setIsEditingAppealWorkflow(false)
        formik.setFieldValue('appealWorkflowDefinitionCode', appealWorkflowDefinitionCode || '')
      }}
    >
      <Box display="flex" alignItems="center">
        {isEditingAppealWorkflow ? (
          <Box display="flex" flex={1} flexDirection="column">
            <DropListButton
              fullWidth
              width="300px"
              error={showError}
              value={formik.values.appealWorkflowDefinitionCode}
              options={appealWorkflows.map(workflow => workflow.code)}
              onChange={(event, newValue) => {
                formik.setFieldValue('appealWorkflowDefinitionCode', newValue)
              }}
              renderOption={(props, option, { selected }) => (
                <ActionSheetItem selected={selected} {...props}>
                  <ListItemText selected={selected}>{option}</ListItemText>
                </ActionSheetItem>
              )}
              errorMessage="Required"
            />
          </Box>
        ) : (
          <Chip
            clickable
            label={appealWorkflow?.name || 'Workflow'}
            onClick={() =>
              navigate(
                RoutesManager.orchestration.workflows.view.root.getURL({
                  code: appealWorkflow?.code || '',
                }),
              )
            }
            deleteIcon={<Icon name="OArrowCRight" />}
            onDelete={() =>
              navigate(
                RoutesManager.orchestration.workflows.view.root.getURL({
                  code: appealWorkflow?.code || '',
                }),
              )
            }
          />
        )}
      </Box>
    </InfoRow>
  )
}
