import { PERMITS_STATISTICS, TranslatorFunction } from 'i18n/types'

type GetConsentRequiredTextArgs = {
  consentRequiredCount?: number
  totalCount?: number
  entity: string
  translate?: TranslatorFunction
}

export const getConsentRequiredText = ({
  consentRequiredCount = 0,
  totalCount = 0,
  entity = '',
  translate = input => input,
}: GetConsentRequiredTextArgs): string => {
  let payload = ''

  if (consentRequiredCount && totalCount && consentRequiredCount === totalCount) {
    payload = translate('consent required', { ns: PERMITS_STATISTICS })
  }
  if (!consentRequiredCount) {
    payload = translate('consent not required', { ns: PERMITS_STATISTICS })
  }
  if (consentRequiredCount && totalCount && consentRequiredCount < totalCount) {
    payload = translate('consent required for x of y z', {
      ns: PERMITS_STATISTICS,
      x: consentRequiredCount,
      y: totalCount,
      z: translate(entity, { ns: PERMITS_STATISTICS }),
    })
  }

  return payload
}
