import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import {
  CreateBillingPortalSessionBody,
  CreateBillingPortalSession200,
} from '@ketch-com/harbormaster/dist/organization/subscriptions.schemas'

type Params = APIRequestParams<{
  reqBody: CreateBillingPortalSessionBody
}>

export const createBillingPortalSession = ({ reqBody }: Params) =>
  API.post<CreateBillingPortalSession200>(
    formatRequestString({
      entityUrl: `/api/subscriptions/billing-portal`,
    }),
    reqBody,
  )
