import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createProcessingActivity } from 'api/processingActivities/fetchers/createProcessingActivity'

const useCustomMutation = createUseMutation({
  mutationFn: createProcessingActivity,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateProcessingActivity = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation, {})
}
