import { useLocation } from 'react-router-dom'
import { useQueryParams } from 'utils/hooks'

export const useAppNavigationMode = () => {
  const { queries } = useQueryParams<{ duplicate?: string }>()
  const { pathname } = useLocation()

  const isCreateMode =
    pathname.endsWith('/create/preference') ||
    pathname.endsWith('/create') ||
    pathname.endsWith('/create/new') ||
    pathname.indexOf('/create') !== -1 ||
    !!queries.duplicate ||
    pathname.indexOf('/duplicate/') !== -1 ||
    pathname.indexOf('/edit/new') !== -1 ||
    pathname.indexOf('/deploy/') !== -1 ||
    pathname.indexOf('/library') !== -1 ||
    pathname.indexOf('/policy-scopes-library') !== -1 ||
    pathname.indexOf('/marketplace/apps/datamap') !== -1 ||
    pathname.indexOf('/upsert/instance/new') !== -1

  const isEditMode =
    pathname.indexOf('/edit/') !== -1 ||
    pathname.indexOf('/edit') !== -1 ||
    pathname.indexOf('/change-password') !== -1 ||
    pathname.indexOf('/data-review') !== -1

  const isViewMode = pathname.indexOf('/view') !== -1
  const isPreviewMode = pathname.indexOf('/preview/') !== -1 || pathname.indexOf('/show') !== -1

  const payload = {
    isViewMode,
    isCreateMode,
    isEditMode,
    isPreviewMode,
  }

  return payload
}
