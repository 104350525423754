import { LabelDTO } from '@ketch-com/figurehead'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'

export const getLabelDtoArrayFromManageLabelModalLabelArray = (
  manageLabelModalLabels: ManageLabelModalLabel[],
): LabelDTO[] => {
  return manageLabelModalLabels.map(l => ({
    code: l.code,
    value: l.value,
    name: l.name,
    isSystemLabel: l.isSystemLabel,
  }))
}
