import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { EventV2DTO, CreateEventAndAssignWorkflowV2ResponseBodyDTO } from '@ketch-com/figurehead'

type Params = {
  event: EventV2DTO
}

export const createEvent = (params: Params) => {
  return API.post<CreateEventAndAssignWorkflowV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/events`,
    }),
    {
      event: params.event,
    },
  )
}
