import { Outlet, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import {
  DataMapProcessingActivityContext,
  withDataMapProcessingActivityContext,
} from './context/DataMapProcessingActivityContext'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Chip, Spinner, ViewLayout } from '@ketch-com/deck'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { ProcessingActivityTypeDTO } from '@ketch-com/figurehead'
import { ProcessingActivityTypeLabel } from '../../constants'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

const DataMapProcessingActivityWithContext: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const navigate = useNavigate()
  const { id, processingActivity, isProcessingActivityLoading } = useContext(DataMapProcessingActivityContext)

  const breadcrumbs = [
    { title: 'Reports', link: RoutesManager.insights.root.getURL() },
    { title: 'Data Map', link: RoutesManager.insights.dataMap.processingActivities.root.getURL() },
    {
      title: processingActivity.name,
      link: RoutesManager.insights.dataMap.processingActivities.view.overview.root.getURL({
        id: processingActivity.id,
      }),
    },
  ]

  const tabs = useMemo(
    () => [
      {
        label: 'Overview',
        link: {
          pathname: RoutesManager.insights.dataMap.processingActivities.view.overview.root.getURL({ id }),
        },
      },
      {
        label: (
          <Box>
            <Typography variant="label">Data Systems</Typography>{' '}
            <Typography variant="label" color="darkDuskFaded.main">
              {processingActivity.dataSystems?.length}
            </Typography>
          </Box>
        ),
        link: {
          pathname: RoutesManager.insights.dataMap.processingActivities.view.dataSystems.root.getURL({ id }),
        },
      },
    ],
    [processingActivity.dataSystems?.length, id],
  )

  useEffect(() => {
    navigate(tabs[currentTab].link)
  }, [currentTab, navigate, tabs])

  return isProcessingActivityLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
      }}
    >
      <Spinner size="32px" thickness={2.5} />
    </Box>
  ) : (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs} />
      <ViewLayout
        contentGroupProps={{
          title: processingActivity.name,
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.insights.dataMap.processingActivities.root.getURL()),
          },
          infoBlockComponent: (
            <Box display="flex" gap={1}>
              <Chip size="small" label="Processing Activity" />
              <Chip
                size="small"
                label={
                  ProcessingActivityTypeLabel[
                    processingActivity.type || ProcessingActivityTypeDTO.UnspecifiedProcessingActivityType
                  ]
                }
              />
            </Box>
          ),
          actionRightBlockComponent: processingActivity?.metadata?.updatedAt && (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="smallBody" color="darkDuskFaded.main">
                Last Updated
              </Typography>
              <Typography variant="smallBody" color="darkDusk.main">
                {formatDateTimeFromUnix(processingActivity.metadata.updatedAt)}
              </Typography>
            </Box>
          ),
        }}
        tabsComponentProps={{
          tabItems: tabs,
          value: currentTab,
          onChange: (_, value) => {
            setCurrentTab(value)
          },
        }}
      >
        <Outlet />
      </ViewLayout>
    </>
  )
}

export const DataMapProcessingActivity = withDataMapProcessingActivityContext(DataMapProcessingActivityWithContext)
