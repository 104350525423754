import React from 'react'
import { useFormikContext } from 'formik'
import { Checkbox } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { PolicyScopeFormValues } from '../../../interfaces'

export const SelectAllRightsCheckbox: React.FC = () => {
  const {
    values: { rightsFulfillment, ...restValues },
    setValues,
  } = useFormikContext<PolicyScopeFormValues>()

  const selectedCount = rightsFulfillment.filter(({ checked }) => checked).length
  const totalCount = rightsFulfillment.length

  return (
    <Box pl={2.25} mb={2} display="flex" alignItems="center" gap="10px">
      <Checkbox
        checked={selectedCount === totalCount}
        indeterminate={selectedCount > 0 && selectedCount < totalCount}
        onChange={event => {
          const nextValue = event.target.checked
          setValues({
            ...restValues,
            rightsFulfillment: rightsFulfillment.map(fulfillment => {
              return {
                ...fulfillment,
                checked: nextValue,
                allowAppeal: nextValue ? fulfillment.allowAppeal : false,
              }
            }),
          })
        }}
      />
      <Typography
        variant={selectedCount > 0 ? 'label' : 'body'}
      >{`${selectedCount}/${totalCount} Selected`}</Typography>
    </Box>
  )
}
