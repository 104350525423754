import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { TableCell } from '@ketch-com/deck'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { Typography } from '@mui/material'
import { RoutesManager } from 'utils/routing/routesManager'
import { CookieViewTabActionCell } from './CookieViewTabActionCell'

export const cookieOverviewTabGridColumns: GridColDef<PurposeDTO>[] = [
  {
    align: 'left',
    headerAlign: 'left',
    field: 'name',
    headerName: 'Purpose',
    sortable: false,
    flex: 1,
    maxWidth: 250,
    renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) => {
      return <TableCell title={row.name} subTitle={row.code} sx={{ margin: '8px 0' }}></TableCell>
    },
  },
  {
    align: 'left',
    field: 'description',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Description',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) => {
      if (row.description) return <TableCell title={row.description}></TableCell>
      return (
        <TableCell>
          <Typography color={'lightGrey.main'} variant="body">
            None
          </Typography>
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'actions',
    headerAlign: 'left',
    headerName: '',
    sortable: false,
    maxWidth: 100,
    renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) => {
      return <CookieViewTabActionCell row={row} route={RoutesManager.policyCenter.purposes.view.root} />
    },
  },
]
