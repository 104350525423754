import { WorkflowExecutionStepCompleteDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  workflowExecutionId: string
  stepId: string
  formData?: WorkflowExecutionStepCompleteDTO
  availablePaths?: string | undefined
}

export const completeWorkflowExecutionStep = ({
  organizationCode,
  workflowExecutionId,
  stepId,
  formData,
  availablePaths,
}: Params) => {
  return API.put<{}>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/executions/${workflowExecutionId}/steps/${stepId}/complete`,
      params: {
        filters: {
          ...(typeof availablePaths !== 'undefined' && { verified: availablePaths === 'verified' }),
        },
      },
    }),
    {
      orgCode: organizationCode,
      executionID: workflowExecutionId,
      stepID: stepId,
      ...formData,
    },
  )
}
