import { ControllerDTO, ControllerFormDTO } from 'interfaces/controllers/controller'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { API } from 'utils/api/api'

type Params = {
  organizationCode: string
  controllerCode: string
  formData: ControllerFormDTO
}

type Response = { controller: ControllerDTO }

export const updateController = ({ organizationCode, controllerCode, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/controllers/${controllerCode}`,
    }),
    formData,
  )
