import React from 'react'
import { useQueryClient } from 'react-query'
import { Icon, IconDictionary, theme, Button } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { TrackerProperty } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useUpsertTrackerPropertyBlocking } from 'api/trackers/mutations/useUpsertTrackerPropertyBlocking'
import { TrackerKeys } from 'pages/policyCenter/trackers/interfaces'
import { MaybeNull } from 'interfaces'

export type TrackerPropertyBlockedProps = {
  trackerKeys: MaybeNull<TrackerKeys>
  trackerProperty: TrackerProperty
}

export const TrackerPropertyBlocked: React.FC<TrackerPropertyBlockedProps> = ({ trackerKeys, trackerProperty }) => {
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const queryClient = useQueryClient()

  const { mutateAsync: upsertTrackerPropertyBlocking, isLoading: isUpdatingAcceptedTrackerPropertyBlocking } =
    useUpsertTrackerPropertyBlocking({
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite]),
          queryClient.invalidateQueries([ApiQueryKeys.approvedTrackerPropertiesInfinite]),
          queryClient.invalidateQueries([ApiQueryKeys.trackerDetails]),
        ])
        showToast({ content: 'Updated blocking status', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to update blocking status', type: 'error' })
      },
    })

  return (
    <Button
      sx={{
        width: 32,
        height: 32,
        borderRadius: '100%',
        padding: 0.25,
      }}
      disabled={!isPermittedToWriteCookie || isUpdatingAcceptedTrackerPropertyBlocking}
      onClick={() => {
        upsertTrackerPropertyBlocking({
          params: {
            data: {
              isBlocked: !trackerProperty.isBlocked,
            },
            encodedTrackerKey: trackerKeys?.encodedTrackerKey || '',
            propertyId: trackerProperty.id || '',
          },
        })
      }}
      variant={trackerProperty.isBlocked ? 'iconCustomRounded' : 'contained'}
      color={trackerProperty.isBlocked ? 'tertiary' : 'primary'}
      children={
        trackerProperty.isBlocked ? (
          <Icon name={IconDictionary.ODecline} />
        ) : (
          <Icon name={IconDictionary.OCheckRound2} iconColor={theme.palette.white.main} />
        )
      }
    />
  )
}
