import { useState } from 'react'
import { useDebounce } from 'react-use'
import { useQueryClient } from 'react-query'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useContextVariablesPaginated } from 'api/contextVariables/queries/useContextVariablesPaginated'
import { useSearchParams } from 'react-router-dom'
import { ContextVariableCategoryDTO, ContextVariableDTO } from '@ketch-com/figurehead'
import { contextVariableCategoryOptions } from '../constants'

export const useContextVariablesListUtils = () => {
  const [queryString, setQueryString] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    data: contextVariables,
    isLoading,
    isFetching,
    pagination,
    isRefetching,
  } = useContextVariablesPaginated({
    params: {
      includeMetadata: true,
      query: queryString,
      category: searchParams.get('category') as ContextVariableCategoryDTO,
    },
  })
  const [selectedContextVariable, setSelectedContextVariable] = useState<ContextVariableDTO | undefined>(undefined)
  const [searchString, setSearchString] = useState('')
  const [isUpsertModalOpen, setIsUpsertModalOpen] = useState(false)
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const queryClient = useQueryClient()

  const { isEntitled } = useIsEntitled()
  const isEntitledToCreateContextVariable = isEntitled(ENTITLEMENTS.WEBHOOKS)

  const { isPermitted } = useIsPermitted()
  const isPermittedToCreateContextVariable = isPermitted([PERMISSIONS.CONTEXT_VARIABLE_WRITE])

  const onCreateSubmit = async () => {
    await queryClient.refetchQueries(ApiQueryKeys.contextVariablesPaginated)
    setIsUpsertModalOpen(false)
  }

  const onEditSubmit = async () => {
    setIsUpsertModalOpen(false)
    setSelectedContextVariable(undefined)
    await queryClient.refetchQueries(ApiQueryKeys.contextVariablesPaginated)
  }

  const isEmptyState = !isFetching && !contextVariables.length && !queryString

  useDebounce(
    () => {
      if (searchString) {
        setQueryString(searchString)
      } else {
        setQueryString('')
      }
    },
    500,
    [searchString],
  )

  const categoryFilterOptions = [
    {
      active: !searchParams.get('category'),
      content: 'All',
      value: '',
      onClick: () => setSearchParams({}),
    },
    ...contextVariableCategoryOptions.map(contextVar => ({
      active: contextVar.id === searchParams.get('category'),
      content: contextVar.label,
      value: contextVar.id,
      onClick: () =>
        setSearchParams({
          category: contextVar.id,
        }),
    })),
  ]

  return {
    categoryFilterOptions,
    contextVariables,
    isEmptyState,
    isEntitledToCreateContextVariable,
    isFetching,
    isLoading,
    isPermittedToCreateContextVariable,
    isRefetching,
    isUpsertModalOpen,
    isViewModalOpen,
    onCreateSubmit,
    onEditSubmit,
    pagination,
    searchParams,
    searchString,
    selectedContextVariable,
    setIsUpsertModalOpen,
    setIsViewModalOpen,
    setSearchParams,
    setSearchString,
    setSelectedContextVariable,
  }
}
