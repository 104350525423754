import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { SubscriptionTopicDTO } from '@ketch-com/figurehead'
import { FormMode } from 'interfaces/formModes/formMode'
import { DeleteSubscriptionTopicModal } from 'pages/policyCenter/subscriptions/subscriptionTopic/list/components'
import { Button, Icon, ActionSheetWrapper, ActionSheetItem } from '@ketch-com/deck'

type Props = {
  subscriptionTopic?: SubscriptionTopicDTO
}

export const SubscriptionTopicsListDropdown: React.FC<Props> = ({ subscriptionTopic }) => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const isPermittedToManageSubscriptions = isPermitted(PERMISSIONS.SUBSCRIPTIONS_WRITE)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button size="medium" variant="iconSubtle" onClick={handleClickListItem}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
        {isPermittedToManageSubscriptions && (
          <ActionSheetItem
            onClick={() => {
              navigate(
                RoutesManager.policyCenter.subscriptions.upsert.subscriptionTopic.root.getURL({
                  code: subscriptionTopic?.code,
                  formMode: FormMode.EDIT,
                }),
              )
              handleClose()
            }}
          >
            Edit
          </ActionSheetItem>
        )}
        {isPermittedToManageSubscriptions && isPermittedToManageSubscriptions && <ActionSheetItem divider />}
        {isPermittedToManageSubscriptions && (
          <ActionSheetItem
            onClick={() => {
              setIsDeleteOpen(true)
              handleClose()
            }}
          >
            Delete
          </ActionSheetItem>
        )}
      </ActionSheetWrapper>
      {isDeleteOpen && (
        <DeleteSubscriptionTopicModal
          subscriptionTopic={subscriptionTopic}
          onSubmit={() => {
            setIsDeleteOpen(false)
          }}
          onCancel={() => {
            setIsDeleteOpen(false)
          }}
        />
      )}
    </>
  )
}
