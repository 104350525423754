import { Message } from '@twilio/conversations'
import { uniq } from 'lodash'
import { avatarColorBucket } from 'components/ui-kit/avatar/avatarColorBucket'

type GetAvatarColorMapArgs = {
  messages: Message[]
}

export const getAvatarColorMap = ({ messages }: GetAvatarColorMapArgs) => {
  const messageSenderIds = uniq(messages.map(m => m.author)).filter(Boolean)

  const colorMapPayload = new Map()
  messageSenderIds?.forEach?.((id, index) => {
    const bucketIndex = index % avatarColorBucket.length || 0
    const colorBucket = avatarColorBucket[bucketIndex]
    colorMapPayload.set(id, colorBucket)
  })
  return colorMapPayload
}
