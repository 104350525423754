import { fetchGlobalVendorList } from '../fetchers/fetchGlobalVendorList'
import { ApiQueryKeys } from '../../common/queryKeys'
import { createUseQuery, CustomQueryConfig } from '../../common/query'
import { VendorsList } from '@ketch-com/supercargo/dist/gvl_gen.schemas'

const fallbackGvl: VendorsList = {
  purposes: [],
  vendors: [],
}

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.globalVendorList,
  queryFn: fetchGlobalVendorList,
  select: res => res?.data?.gvl || fallbackGvl,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useGlobalVendorList = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
