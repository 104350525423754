import { Box } from '@mui/material'
import { Button } from '@ketch-com/deck'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { FieldArray, useFormikContext } from 'formik'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { getEmptyStackGroup, StackForm } from '../utils/formValues'
import { GroupTile } from './GroupTile'

type Props = {
  purposes: PurposeDTO[]
}

export const PurposesForm: React.FC<Props> = ({ purposes }) => {
  const { values } = useFormikContext<StackForm>()
  const { groups } = values

  return (
    <FormSection title="Groups">
      <FieldArray
        name="groups"
        render={helpers => (
          <Box>
            {groups.map((group, index) => (
              <GroupTile
                key={group.id}
                group={group}
                index={index}
                helpers={helpers}
                purposes={purposes}
                hasMoreThanOneGroup={groups?.length > 1}
              />
            ))}
            <Button color="secondary" onClick={() => helpers.push(getEmptyStackGroup())}>
              Add Group
            </Button>
          </Box>
        )}
      />
    </FormSection>
  )
}
