import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { deletePolicyV2 } from 'api/policies/fetchers/deletePolicyV2'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomMutation = createUseMutation({
  mutationFn: deletePolicyV2,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeletePolicyV2 = (config?: Config) => {
  const { userData } = useAuth()
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation, {
    organizationCode: userData.organizationCode,
  })
}
