import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import {
  BasicDetails,
  ProcessingDetails,
  DataSystems,
  ReviewProcessingActivity,
} from 'pages/policyCenter/processingActivities/upsert/components'
import { ProcessingActivitiesFormStep } from 'pages/policyCenter/processingActivities/constants'
import { ProcessingActivityDTO } from '@ketch-com/figurehead'
import { useCreateProcessingActivity } from 'api/processingActivities/mutations/useCreateProcessingActivity'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import {
  getProcessingActivitiesFormInitialValues,
  getProcessingActivitiesFormValidation,
} from 'pages/policyCenter/processingActivities/upsert/utils'
import { MaybeNull } from 'interfaces'
import { FormikProvider, useFormik } from 'formik'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {}

export const CreateProcessingActivities: React.FC<Props> = () => {
  const [processingActivityReviewData, setProcessingActivityReviewData] =
    useState<MaybeNull<ProcessingActivityDTO>>(null)
  const navigate = useNavigate()
  const [currentFormStep, setCurrentFormStep] = useState(0)
  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Processing Activities', link: RoutesManager.policyCenter.processingActivities.root.getURL() },
    { title: 'Request New Processing Activity' },
  ]

  const { mutate: handleCreateProcessingActivity } = useCreateProcessingActivity({
    onSuccess: async ({ data }) => {
      const { processingActivity = [] as ProcessingActivityDTO } = data
      showToast({ content: ' Created processing activity', type: 'success' })
      setProcessingActivityReviewData(processingActivity)
    },
    onError: () => {
      showToast({ content: 'Unable to create processing activity', type: 'error' })
    },
  })

  const handleSubmit = (processingActivityFormData: ProcessingActivityDTO) => {
    handleCreateProcessingActivity({
      params: {
        formData: { processingActivity: processingActivityFormData },
      },
    })
  }
  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: getProcessingActivitiesFormInitialValues(),
    validationSchema: getProcessingActivitiesFormValidation(),
    onSubmit: handleSubmit,
  })

  const { isSubmitting, submitForm, validateForm, setFieldTouched } = formikProps

  if (processingActivityReviewData)
    return <ReviewProcessingActivity processingActivity={processingActivityReviewData} breadcrumbs={breadcrumbs} />

  const formSteps = [
    ProcessingActivitiesFormStep.BASIC_DETAILS,
    ProcessingActivitiesFormStep.PROCESSING_DETAILS,
    ProcessingActivitiesFormStep.DATA_SYSTEMS,
  ]

  const renderedChildren = [<BasicDetails />, <ProcessingDetails />, <DataSystems />]

  const isLastStep = formSteps.length - 1 === currentFormStep

  const onAccept = async () => {
    const errors = await validateForm()
    if (Object.keys(errors).length) {
      Object.keys(errors).forEach(errorKey => {
        setFieldTouched(errorKey, true)
      })
      return
    }
    if (isLastStep) {
      submitForm()
    } else {
      setCurrentFormStep(prev => prev + 1)
    }
  }

  return (
    <FormikProvider value={formikProps}>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <UpsertLayout
        mode={UpsertLayoutMode.create}
        showStepper
        formSteps={formSteps}
        currentFormStep={currentFormStep}
        renderFormTitle={() => <ContentGroup title="Request New Processing Activity" titleVariant="h2" isDivider />}
        onAccept={onAccept}
        acceptButtonProps={{
          pending: isSubmitting,
        }}
        sx={{
          '&.UpsertLayout-container': {
            '& .UpsertLayout-stepper': {
              // 8px gap between layout stepper and content
              top: '8px',
            },
          },
        }}
        acceptActionButtonText={isLastStep ? 'Submit Request' : 'Next'}
        onCancel={() => {
          navigate(RoutesManager.policyCenter.processingActivities.root.getURL())
        }}
        cancelButtonProps={{
          pending: isSubmitting,
        }}
      >
        <form autoComplete="off">
          <>{renderedChildren.filter((_, i) => currentFormStep >= i)}</>
        </form>
      </UpsertLayout>
    </FormikProvider>
  )
}
