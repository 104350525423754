import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  workflowExecutionId: string
  stepId: string
  assigneeId: string
}

export const updateWorkflowExecutionStepAssignee = ({
  organizationCode,
  workflowExecutionId,
  stepId,
  assigneeId,
}: Params) =>
  API.put<{}>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/executions/${workflowExecutionId}/steps/${stepId}/assignees/${assigneeId}`,
    }),
  )
