import { VerifierDTO } from '@ketch-com/figurehead'

type Args = {
  verifier: VerifierDTO
  isEditMode: boolean
}

export const useVerifiersFormInitialValues = ({ verifier, isEditMode }: Args): VerifierDTO => {
  if (!isEditMode) {
    return {
      id: '',
      name: '',
      type: 0,
      publicKey: '',
      sharedSecret: '',
      jwksUrl: '',
    }
  }

  return {
    id: verifier.id,
    name: verifier.name,
    type: verifier.type,
    publicKey: verifier.publicKey,
    sharedSecret: verifier.sharedSecret,
    jwksUrl: verifier.jwksUrl,
  }
}
