import { ExperienceDTO, UpsertExperienceDTO } from 'interfaces/experiences/experience'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  experienceCode: string
  formData: UpsertExperienceDTO
}

type Response = { experience: ExperienceDTO }

export const updateExperience = ({ organizationCode, experienceCode, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/experiences/${experienceCode}`,
    }),
    formData,
  )
