import { APIListRequestParams } from 'api/common/utils'
import { FetchAuthenticatorsResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  query?: string
}>

export const fetchAuthenticators = ({ query, limit, start }: Params) =>
  API.get<FetchAuthenticatorsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/authenticators`,
      params: {
        filters: {
          start,
          limit,
          ...(query && {
            query,
          }),
        },
      },
    }),
  )
