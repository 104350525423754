import { valueof } from 'interfaces'

export const FORM_MODES_ENUM = {
  CREATE_NEW: 'CREATE_NEW',
  DUPLICATE: 'DUPLICATE',
  EDIT: 'EDIT',
} as const

export type FormMode = valueof<typeof FORM_MODES_ENUM>

const titleByMode = {
  [FORM_MODES_ENUM.CREATE_NEW]: 'Create',
  [FORM_MODES_ENUM.DUPLICATE]: 'Duplicate',
  [FORM_MODES_ENUM.EDIT]: 'Edit',
}

const saveSuccessTextByMode = {
  [FORM_MODES_ENUM.CREATE_NEW]: 'created',
  [FORM_MODES_ENUM.DUPLICATE]: 'created',
  [FORM_MODES_ENUM.EDIT]: 'updated',
}

const saveErrorTextByMode = {
  [FORM_MODES_ENUM.CREATE_NEW]: 'create',
  [FORM_MODES_ENUM.DUPLICATE]: 'create',
  [FORM_MODES_ENUM.EDIT]: 'update',
}

export const formModes = {
  ...FORM_MODES_ENUM,

  titleByMode,
  saveSuccessTextByMode,
  saveErrorTextByMode,
}
