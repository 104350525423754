import { FC } from 'react'
import { Button, PopUp } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { OrgPlanNames, OrgPlanMaxUniquesLimit } from 'components/planAndBilling/common/constants/planAndBilling'

type FreeDowngradeModalProps = {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onSubmit: () => void
}

export const FreeDowngradeModal: FC<FreeDowngradeModalProps> = ({ isOpen, isLoading, onClose, onSubmit }) => {
  return (
    <PopUp
      title={`Downgrade to "${OrgPlanNames.free}" plan?`}
      variant="modal"
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      popUpWidth="560px"
      popUpActionChildren={
        <>
          <Button color="tertiary" size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button color="error" size="large" onClick={onSubmit} disabled={isLoading}>
            Downgrade
          </Button>
        </>
      }
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="body">
          Are you sure you want to downgrade from your current "
          <Typography variant="label">{OrgPlanNames.starter}</Typography>" plan?
        </Typography>
        <Typography variant="body">
          If you proceed, your plan limit will be updated to{' '}
          <Typography variant="label">{OrgPlanMaxUniquesLimit.free}</Typography> unique users per month.
        </Typography>
      </Box>
    </PopUp>
  )
}
