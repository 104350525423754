import { APIListRequestParams } from 'api/common/utils'
import { GetAssetHierarchiesResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  assetCode?: string | null | undefined
  start?: number
  canonicalResourceType?: number | null | undefined
  resourceTypeCode?: string | null | undefined
  query?: string | null | undefined
  identityRelationType?: number
  isPersonal?: boolean
  nextToken?: string | null | undefined
}>

export const fetchRelatedAssetsPaginatedV3 = ({
  assetCode,
  canonicalResourceType,
  resourceTypeCode,
  query,
  identityRelationType,
  isPersonal,
  nextToken,
  limit,
}: Params) => {
  return API.get<GetAssetHierarchiesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v3/assets/${assetCode}/hierarchies`,
      params: {
        filters: {
          ...(canonicalResourceType && {
            canonicalResourceType,
          }),
          ...(resourceTypeCode && {
            resourceTypeCode,
          }),
          ...(query && {
            query,
          }),
          ...(identityRelationType && {
            identityRelationType,
          }),
          ...(isPersonal && {
            isPersonal,
          }),
          ...(nextToken && {
            nextToken,
          }),
          limit,
        },
      },
    }),
  )
}
