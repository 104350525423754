import React from 'react'

import { Checkbox } from 'components/ui-kit/checkbox/Checkbox'
import { ObjectLiteral } from 'interfaces'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { getPolicyStatements } from 'store/dsrTransponderFormSlice/selectors'
import { useFormikContext } from 'formik'
import { IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { selectDataset, deselectDataset, clearAttributeUpdateErrors } from 'store/dsrTransponderFormSlice'
import { Statement } from 'store/dsrTransponderFormSlice'
import { getObligationEffectByCanonicalRightCode } from '../utils'
import { isEqual } from 'lodash'

interface Props<T> {
  items: T[]
  activeDatabase: string
}

export const CheckAllDatasetsCellRenderer = <T extends ObjectLiteral>({ items, activeDatabase }: Props<T>) => {
  const policyStatements = useAppSelector(getPolicyStatements)
  const dispatch = useAppDispatch()
  const { values: formikValues } = useFormikContext<IWorkflowConfig>()

  const getCheckAllValue = () => {
    const currentPageOfResultsAssetCodes = items.map(item => item.code).sort()
    const statementEntries = Object.entries((policyStatements?.[activeDatabase]?.statements as Statement) || {})
      .map(([id]) => id)
      // filter out statements not in current page of results (where current page of results is "items")
      .filter(id => currentPageOfResultsAssetCodes.includes(id))
      .sort()

    if (statementEntries.length > 0 && isEqual(currentPageOfResultsAssetCodes, statementEntries)) return true
    if (
      statementEntries.length > 0 &&
      !isEqual(currentPageOfResultsAssetCodes, statementEntries) &&
      statementEntries.some(id => currentPageOfResultsAssetCodes.includes(id)) // required test with multiple pages of results
    )
      return 'indeterminate'
    return false
  }
  const checkAllValue = getCheckAllValue()

  const handleToggleSelectAll = () => {
    if (!checkAllValue) {
      const statementsPayload = items.reduce((acc, { code }) => {
        const effect = getObligationEffectByCanonicalRightCode(formikValues?.canonicalRightCode || '')
        return {
          ...acc,
          [code]: {
            effect,
            assetType: 'dataset',
          },
        }
      }, {} as Statement)

      const policyPayload = {
        ...policyStatements,
        [activeDatabase]: {
          ...policyStatements?.[activeDatabase],
          resource: activeDatabase,
          statements: {
            ...policyStatements?.[activeDatabase]?.statements,
            ...statementsPayload,
          },
        },
      }
      dispatch(selectDataset(policyPayload))
    } else {
      const itemsCodes = items.map(({ code }) => code)
      const copyPolicyStatements = { ...policyStatements }
      const statementEntries = Object.entries(copyPolicyStatements[activeDatabase].statements as Statement).filter(
        el => {
          return !itemsCodes.includes(el[0])
        },
      )

      const updatedStatements = Object.fromEntries(statementEntries)

      const deselectPayload = {
        ...copyPolicyStatements,
        [activeDatabase]: { ...copyPolicyStatements[activeDatabase], statements: updatedStatements },
      }
      dispatch(deselectDataset(deselectPayload))
      dispatch(clearAttributeUpdateErrors())
    }
  }

  return <Checkbox value={checkAllValue} onChange={() => handleToggleSelectAll()} />
}
