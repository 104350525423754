import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'
import { isFunction } from 'lodash'
import { Formik, FormikConfig, FormikProps } from 'formik'

import { ObjectLiteral } from 'interfaces'
import { Spinner } from 'components/ui-kit/spinner/Spinner'

type Props<I> = Omit<FormikConfig<I>, 'initialValues' | 'onSubmit' | 'children'> & {
  isReady?: boolean
  validateOnMount?: boolean
  reducedPadding?: boolean
  initialValues?: FormikConfig<I>['initialValues']
  onSubmit?: FormikConfig<I>['onSubmit']
  children: React.ReactNode | ((formikProps: FormikProps<I>) => React.ReactNode)
  signup?: boolean
}

const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        padding: '44px 40px',
        background: theme.palette.white.main,
        borderRadius: 11,
        width: 480,
      },
      reducedPadding: {
        padding: theme.spacing(1, 2),
        width: 'auto',
      },
      signup: {
        width: 700,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
      },
    }),
  { name: 'FormLayout' },
)

export function FormLayout<I extends ObjectLiteral>({
  children,
  isReady = true,
  reducedPadding = false,
  signup = false,
  validateOnMount,
  enableReinitialize,
  initialValues = {} as I,
  onSubmit = () => {},
  ...rest
}: Props<I>) {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.root, {
        [classes.reducedPadding]: reducedPadding,
        [classes.signup]: signup,
      })}
    >
      {isReady ? (
        <Formik
          {...rest}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnMount={validateOnMount}
          enableReinitialize={enableReinitialize}
        >
          {formikProps => (
            <form autoComplete="off" onSubmit={formikProps.handleSubmit}>
              {isFunction(children) ? children(formikProps) : children}
            </form>
          )}
        </Formik>
      ) : (
        <Spinner padding={50} />
      )}
    </div>
  )
}
