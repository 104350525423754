import { find, isEmpty, findIndex } from 'lodash'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { getTransitionsFromStep } from 'pages/orchestration/workflows/edit/utils/steps/transitionsFromStep'

interface IGetSiblingsFromStepParams {
  step: ICanvasStep
  steps: Array<ICanvasStep>
}

interface IGetSiblingsFromStep {
  parentStep?: ICanvasStep
  parentSteps?: ICanvasStep[]
  parentTransitionIndex?: number
  childStep?: ICanvasStep
}

export const getSiblingsFromStep = (params: IGetSiblingsFromStepParams): IGetSiblingsFromStep => {
  const { step, steps } = params
  const { ID } = step
  const stepTransitions = getTransitionsFromStep({ step, steps })
  const resultToReturn: IGetSiblingsFromStep = {
    parentStep: undefined, // Primary parent step to use
    parentSteps: [], // Joins will have multiple parent steps
    parentTransitionIndex: 0,
    childStep: undefined,
  }

  steps?.forEach?.(workflowStep => {
    const isChildStepInParentTransitions = !isEmpty(find(stepTransitions, { ID: workflowStep.ID }))
    if (isChildStepInParentTransitions) {
      resultToReturn.childStep = workflowStep
    }

    const childTransitions = getTransitionsFromStep({ step: workflowStep, steps })
    const isStepInChildTransitions = !isEmpty(find(childTransitions, { ID }))

    if (isStepInChildTransitions) resultToReturn.parentSteps?.push(workflowStep)

    if (isStepInChildTransitions && !resultToReturn.parentStep) {
      resultToReturn.parentStep = workflowStep
      resultToReturn.parentTransitionIndex = findIndex(childTransitions, { ID })
    }
  })

  return resultToReturn
}
