import { Box } from '@mui/material'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
import { useContext } from 'react'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { DecisionGatewayContext } from '../context/DecisionGatewayContext'
import { ContentGroup, InfoRow } from '@ketch-com/deck'
import { InfoRowAssignUser } from '../../../InfoRowAssignUser'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type Props = {}

export const DecisionStepDetails: React.FC<Props> = () => {
  const { workflowStep, stepDetails, workflowExecutionId, isRightsQueueAdmin } = useContext(
    RightInvocationWorkflowStepDetailViewContext,
  )
  const { assigneeInfo, stepID, assignee } = stepDetails

  const { gatewayContext } = useContext(DecisionGatewayContext)
  const isSuccess = workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS
  const canReassign = isRightsQueueAdmin && !isSuccess

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <ContentGroup variant="inner-page-header" title="Details" titleVariant="h3" />
      <InfoRow title="Decision Mode">
        {gatewayContext?.gateway?.mode === 4 ? 'Multiple paths' : 'Single decision only'}
      </InfoRow>
      <InfoRow title="Description">
        {workflowStep?.description ? workflowStep?.description : <EmptyValueRenderer />}
      </InfoRow>

      {/* "Task Timer, days" is de-scoped */}
      {/* <InfoRow
        hasPadding
        title="Task Timer, days"
        info={
          rightInvocation?.dueAt ? (
            <Typography variant="body">{getDaysRemaining(rightInvocation as any)}</Typography>
          ) : (
            <EmptyValueRenderer />
          )
        }
      /> */}

      {/* Assignees */}

      <InfoRowAssignUser
        infoRowTitle="Assignee"
        assigneeInfo={assigneeInfo || null}
        assignee={assignee}
        canEdit={canReassign}
        isComplete={isSuccess}
        workflowExecutionId={workflowExecutionId}
        workflowStepId={stepID}
      />
    </Box>
  )
}
