import { MaybeNull } from 'interfaces/common'
import { createUseFilters } from 'utils/hooks/createUseFilters'

export const usePoliciesListFilters = createUseFilters<{
  status: MaybeNull<string>
  search: string
}>({
  status: '',
  search: '',
})
