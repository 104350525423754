import React from 'react'
import { AssetsHomePage } from 'pages/assetManager/assets/home/AssetsHomePage'
import { useAssetsStatistics } from 'api/assets/queries/useAssetsStatistics'
import { useAssetsDataSystems } from 'api/assets/queries/useAssetsDataSystems'

export const AssetsHomePageContainer: React.FC = () => {
  const { data: assetStatistics, isLoading: isLoadingAssetStatistics } = useAssetsStatistics()
  const { data: assetsDataSystems, isLoading: isLoadingAssetsDataSystems } = useAssetsDataSystems()

  return (
    <AssetsHomePage
      isLoading={isLoadingAssetStatistics || isLoadingAssetsDataSystems}
      assetStatistics={assetStatistics}
      assetsDataSystems={assetsDataSystems}
    />
  )
}
