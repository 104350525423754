import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { Button, ListLayout, TabSegmented, TabSegmentedGroup } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { useIsEntitled, useIsPermitted } from 'utils/hooks'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { FormMode } from 'interfaces/formModes/formMode'

enum SubscriptionsTab {
  Topics = 'topics',
  Controls = 'controls',
}

export const SubscriptionsContainer: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()
  const isEntitledToCreateSubscriptions = isEntitled(ENTITLEMENTS.SUBSCRIPTIONS)
  const isPermittedToCreateSubscriptions = isPermitted(PERMISSIONS.SUBSCRIPTIONS_WRITE)

  const selectedTab = window.location.pathname.endsWith('subscriptions/topics')
    ? SubscriptionsTab.Topics
    : SubscriptionsTab.Controls

  const handleTabChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    if (newValue === SubscriptionsTab.Topics) {
      navigate(RoutesManager.policyCenter.subscriptions.list.subscriptionTopics.root.getURL())
    } else {
      navigate(RoutesManager.policyCenter.subscriptions.list.subscriptionControls.root.getURL())
    }
  }

  const canCreateSubscription = isEntitledToCreateSubscriptions && isPermittedToCreateSubscriptions

  const handleRedirectToCreateSubscription = () => {
    navigate(
      RoutesManager.policyCenter.subscriptions.upsert.subscriptionTopic.root.getURL({
        code: 'new',
        formMode: FormMode.CREATE,
      }),
    )
  }

  /* Redirect if base route is navigated to  */
  useEffect(() => {
    if (location.pathname === RoutesManager.policyCenter.subscriptions.root.getURL()) {
      navigate(RoutesManager.policyCenter.subscriptions.list.subscriptionTopics.root.getURL())
    }
  }, [location.pathname, navigate])

  return (
    <ListLayout
      headerTitle={''}
      headerProps={{
        variant: 'inner-page-header',
      }}
      customHeaderContentComponent={
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: '-24px' }}>
          <TabSegmentedGroup
            canDeselectCurrentValue={false}
            value={selectedTab}
            exclusive
            onChange={handleTabChange}
            size="small"
          >
            <TabSegmented value={SubscriptionsTab.Topics} key="1">
              All Subscriptions
            </TabSegmented>
            <TabSegmented value={SubscriptionsTab.Controls} key="2">
              Global Control
            </TabSegmented>
          </TabSegmentedGroup>
          {selectedTab === SubscriptionsTab.Topics && (
            <Button color="primary" disabled={!canCreateSubscription} onClick={handleRedirectToCreateSubscription}>
              Create Subscription
            </Button>
          )}
        </Box>
      }
    >
      <Outlet />
    </ListLayout>
  )
}
