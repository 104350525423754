import React, { useMemo } from 'react'

import { PolicyDocumentDTO, PolicyDocumentType } from 'interfaces/policyDocuments/policyDocument'
import { FormDropList } from 'components/ui-kit/form/dropList/FormDropList'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { DeploymentPlanFormSpecialValues } from 'pages/consentAndRights/deploymentPlans/upsert/utils'

type Props = {
  policyDocumentType: PolicyDocumentType
  policyDocuments: PolicyDocumentDTO[]
  editMode: boolean
}

export const DeploymentPlanDocumentAssignment: React.FC<Props> = ({
  policyDocumentType,
  policyDocuments,
  editMode,
}) => {
  const isTermsOfService = policyDocumentType === PolicyDocumentType.TERMS_OF_SERVICE
  const isPrivacyPolicy = policyDocumentType === PolicyDocumentType.PRIVACY_POLICY

  const items = useMemo(() => {
    const filteredPolicyDocs = policyDocuments.filter(
      policyDocument => policyDocument.policyDocumentType === policyDocumentType,
    )
    return [
      ...(isPrivacyPolicy && editMode && filteredPolicyDocs.length === 0
        ? [{ code: DeploymentPlanFormSpecialValues.NO_DOCUMENT, name: DeploymentPlanFormSpecialValues.NO_DOCUMENT }]
        : []),
      ...(isTermsOfService
        ? [{ code: DeploymentPlanFormSpecialValues.NO_DOCUMENT, name: DeploymentPlanFormSpecialValues.NO_DOCUMENT }]
        : []),
      ...filteredPolicyDocs,
    ]
  }, [policyDocumentType, policyDocuments, isTermsOfService, isPrivacyPolicy, editMode])

  const name = isTermsOfService ? 'termsOfServiceCode' : 'privacyPolicyCode'

  return (
    <FormDropList
      name={name}
      valueKey="code"
      items={items}
      placeholder="Select Document"
      renderItem={({ name, code }) => (
        <NameAndCodeCellRenderer name={name} {...(code !== DeploymentPlanFormSpecialValues.NO_DOCUMENT && { code })} />
      )}
    />
  )
}
