import { APIRequestParams } from 'api/common/utils'
import { OrganizationDTO, OrganizationFormDTO } from 'interfaces/organizations/organization'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  currentOrganizationCode: string
  includeMetadata?: boolean
  formData: OrganizationFormDTO
}>

type Response = { organization: OrganizationDTO }

export const updateOrganization = ({ currentOrganizationCode, formData, includeMetadata }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${currentOrganizationCode}/organization`,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
    formData,
  )
