export enum DATA_TYPE_NAMES {
  STRING = 'String',
  INTEGER = 'Integer',
  BOOLEAN = 'Boolean',
  DECIMAL = 'Decimal',
  INT = 'Int',
  INT_8 = 'Int8',
  INT_16 = 'Int16',
  INT_32 = 'Int32',
  INT_64 = 'Int64',
  U_INT = 'UInt',
  U_INT_8 = 'UInt8',
  U_INT_16 = 'UInt16',
  U_INT_32 = 'UInt32',
  U_INT_64 = 'UInt64',
}
