import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { deleteNotFoundTrackerOnProperty } from '../fetchers/deleteNotFoundTrackerOnProperty'

const useCustomMutation = createUseMutation({
  mutationFn: deleteNotFoundTrackerOnProperty,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeleteNotFoundTrackerOnProperty = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
