import { ExperienceAB } from 'pages/consentAndRights/applications/upsert/components/ApplicationPredicates.types'
import { Grid } from '@mui/material'
import { TextInput, DropListButton, Chip } from '@ketch-com/deck'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ChevronRight } from '@mui/icons-material'

type Props = {
  allExperiences: ExperienceV2DTO[]
  experience: ExperienceAB
  onChange: (exp: ExperienceAB, key: string, value: string, isDelete: boolean) => void
}
export const EditExperienceAB = ({ allExperiences, experience, onChange }: Props) => {
  const updateExperience = (key: string, value: string) => {
    onChange(experience, key, value, false)
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={6}>
        <DropListButton
          fullWidth
          getOptionLabel={option => option.name || ''}
          label={'Select Experience'}
          onChange={(e, value) => value?.id && updateExperience('experienceId', value.id)}
          options={allExperiences}
          value={allExperiences.find(i => i.id === experience.experienceId)}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <TextInput
          label={'Rotation'}
          inputProps={{ max: 100, min: 0 }}
          onChange={e => updateExperience('chance', e.target.value)}
          required
          type="number"
          value={experience.chance}
        />
      </Grid>
    </Grid>
  )
}

export const ViewExp = ({ experience, allExperiences }: Omit<Props, 'onChange'>) => {
  const expName = allExperiences.find(exp => exp.id === experience.experienceId)?.name || 'Unnamed experience'
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={4}>
        <Chip color="primary" deleteIcon={<ChevronRight />} label={expName} onDelete={() => {}} />
      </Grid>
      <Grid item xs={3}>
        {`${experience.chance}% of time`}
      </Grid>
    </Grid>
  )
}
