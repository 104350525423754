import { PutFormTemplateRequestBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  formData: PutFormTemplateRequestBodyDTO
}

type Response = {}

export const updateFormTemplate = ({ formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/form-templates/${formData?.formTemplate?.id || ''}`,
    }),
    formData,
  )
