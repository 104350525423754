import React, { useContext } from 'react'

import { RoutesManager } from 'utils/routing/routesManager'
import { Button } from '@ketch-com/deck'
import { MaybeNull } from 'interfaces'
import { WorkflowExecutionDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { RightsQueueViewContext } from 'pages/orchestration/rightsQueue/viewV2/context/RightsQueueViewContext'
import { useNavigateGated } from 'utils/routing/hooks/useNavigateGated'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

type Props = {
  workflowExecution: MaybeNull<WorkflowExecutionDTO>
  workflowExecutionId: string
  workflowExecutionStepId: string
}

export const WorkflowExecutionRedactionStepActions: React.FC<Props> = ({
  workflowExecutionId,
  workflowExecutionStepId,
}) => {
  const { rightInvocationId } = useContext(RightsQueueViewContext)
  const navigateGated = useNavigateGated()

  const handleActionClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault()
    e.stopPropagation()

    const navigationPayload = {
      rightInvocationId,
      stepId: workflowExecutionStepId,
      executionId: workflowExecutionId,
    }

    const redactRoute = RoutesManager.orchestration.rightsQueue.reviewData.root.getURL(navigationPayload)

    navigateGated(redactRoute, PERMISSIONS.REDACTION_WRITE, ENTITLEMENTS.REDACTION)
  }

  return (
    <>
      <Button color="tertiary" onClick={handleActionClick}>
        View
      </Button>
    </>
  )
}
