import { useMemo } from 'react'
import { createInputSubjectList } from '../utils/createInputSubjectList'
import { useApplianceWorks } from 'api/appliances/queries/useApplianceWorks'

interface UseInputSubjectsHookProps {
  applianceID?: string
}

export function useInputSubjects({ applianceID }: UseInputSubjectsHookProps) {
  const { data: fillApplianceList, isLoading } = useApplianceWorks({
    enabled: !!applianceID,
    params: {
      applianceId: applianceID!,
      params: {
        filters: {
          limit: 1000,
        },
      },
    },
  })

  const inputSubjects = useMemo(() => {
    if (fillApplianceList && !isLoading) {
      return createInputSubjectList(fillApplianceList?.ApplianceWork)
    }
    return []
  }, [fillApplianceList, isLoading])

  return {
    inputSubjects,
    isLoading,
  }
}
