import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { WebTagDTO } from 'interfaces/webtags/WebTags'
import { isUndefined } from 'lodash'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export type WebTagsListParams = APIListRequestParams<{
  organizationCode: string
  includeMetadata?: boolean
  includeIssues?: boolean
  q?: string
  appCode?: string
  appInstanceId?: string
  purposeId?: string
  enabled?: string
  paused?: boolean
  propertyID?: string
  propertyEnvID?: string
  approved?: boolean
}>

export type TagsListResponse = APIListResponse<{
  tags: WebTagDTO[]
  totalResults: number
  totalEnabled: number
  // Mapping of app codes to the url for the apps logo
  logoURLs: { [appCode: string]: string }
}>

export const fetchWebTagsV2 = ({
  organizationCode,
  includeMetadata,
  includeIssues,
  start,
  limit,
  q,
  appCode,
  appInstanceId,
  propertyID,
  propertyEnvID,
  purposeId,
  approved,
  enabled,
  paused,
}: WebTagsListParams) =>
  API.get<TagsListResponse>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/tags`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
          ...(q && {
            q,
          }),
          ...(appCode && {
            appCode,
          }),
          ...(appInstanceId && {
            appInstanceID: appInstanceId,
          }),
          ...(propertyID && {
            propertyID,
          }),
          ...(propertyEnvID && {
            propertyEnvID,
          }),
          ...(purposeId && {
            purposeID: purposeId,
          }),
          ...(enabled && {
            enabled,
          }),
          ...(!isUndefined(approved) && {
            approved,
          }),
          ...(!isUndefined(paused) && {
            paused,
          }),
        },
      },
    }),
  )
