import React, { useContext, useEffect, useState } from 'react'
import { FormSectionSwitch } from '../FormSectionSwitch'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { Box } from '@mui/material'
import { TabPanel, Button, Icon, ActionSheetWrapper, ActionSheetItem, ListItemText } from '@ketch-com/deck'
import { FormTextInput } from '../FormTextInput'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ExperienceBuilderSectionLabels } from '../../../builder/utils/labels'
import { PreferenceExperienceBuilderSection } from '../../../builder/utils/enums'
import { SidebarFormRadioGroup } from '../SidebarFormRadioGroup'
import { PreferenceCenterPage, QuickLinkAction } from '@ketch-sdk/ketch-types'
import { QuickLinkActionLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { ExperienceServerDropdownButton } from '../ExperienceServerDropdownButton'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { getPreferencePageLabel } from 'pages/consentAndRights/experiences-v2/upsert/utils/utils'

type LabelRendererProps = {
  variant: 'url' | 'page'
  linkNumber: number
}

const omitPages = [
  PreferenceCenterPage.Welcome,
  PreferenceCenterPage.RequestsForm,
  PreferenceCenterPage.RequestsSubmitted,
  PreferenceCenterPage.PrivacyPolicy,
]

const QuickLinkLabelRenderer: React.FC<LabelRendererProps> = ({ variant, linkNumber }) => {
  const { entitledPreferencePages } = useContext(ExperienceUpsertContext)
  const { values, setFieldValue } = useFormikContext<ExperienceV2DTO>()

  // Get current form values
  const urlName = `layout.preference.tabs.welcome.quickLinks.links[${linkNumber}].url`
  const pageName = `layout.preference.tabs.welcome.quickLinks.links[${linkNumber}].page`
  const page = getIn(values, pageName)

  const handlePageChange = (p: PreferenceCenterPage) => {
    setFieldValue(pageName, p)
  }

  return variant === 'url' ? (
    <FormTextInput name={urlName} placeholder="Paste URL here" size="small" fullWidth />
  ) : (
    <ExperienceServerDropdownButton
      items={entitledPreferencePages.filter(p => !omitPages.includes(p))}
      value={page}
      renderItem={(p, index) => getPreferencePageLabel(p, index)}
      onChange={handlePageChange}
    />
  )
}

const experienceBuilderSectionTabMap: { [section: string]: number } = {
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinks]: 0,
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLinkTwo]: 1,
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLinkThree]: 2,
}

export const PreferenceExperienceWelcomeTabQuickLinksForm: React.FC = () => {
  const { values, isSubmitting, setFieldValue } = useFormikContext<ExperienceV2DTO>()
  const { experienceBuilderSection, initialValues, entitledPreferencePages } = useContext(ExperienceUpsertContext)
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length

  // Current tab of the button form
  const [tab, setTab] = React.useState(0)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleActionMenuItemClick = (_: React.MouseEvent<HTMLElement>, index: number) => {
    setTab(index)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const showForm = getIn(values, experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksVisible)
  const actionOne = getIn(values, experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkOneAction)
  const actionTwo = getIn(values, experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkTwoAction)
  const actionThree = getIn(values, experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkThreeAction)

  const secondLinkObj = values.layout?.preference?.tabs?.welcome?.quickLinks?.links?.[1]
  const thirdLinkObj = values.layout?.preference?.tabs?.welcome?.quickLinks?.links?.[2]

  let isQuickLinkTwoVisible = false
  let isQuickLinkThreeVisible = false

  if (secondLinkObj) {
    isQuickLinkTwoVisible = typeof secondLinkObj.visible === 'boolean' ? secondLinkObj.visible : true
  }
  if (thirdLinkObj) {
    isQuickLinkThreeVisible = typeof thirdLinkObj.visible === 'boolean' ? thirdLinkObj.visible : true
  }

  useEffect(() => {
    // if we should display the quick links but no visible attribute on the links,
    // we assume they are should be displayed ---> to be backwards compatible with older experiences

    if (showForm) {
      if (secondLinkObj && typeof secondLinkObj.visible !== 'boolean') {
        setFieldValue(experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkTwoVisible, true)
      }
      if (thirdLinkObj && typeof thirdLinkObj.visible !== 'boolean') {
        setFieldValue(experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkThreeVisible, true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (experienceBuilderSection) {
      setTab(experienceBuilderSectionTabMap[experienceBuilderSection])
    } else {
      setTab(0)
    }
  }, [experienceBuilderSection, isSubmitting])

  const updateFieldsOnLinkActionChange = (value: QuickLinkAction, pageKey: string, urlKey: string) => {
    const defaults = initialValues.layout?.preference?.tabs?.welcome?.quickLinks?.links?.[0]

    if (value === QuickLinkAction.OpenUrl) {
      setFieldValue(pageKey, undefined)
      setFieldValue(urlKey, defaults?.url)
    } else {
      setFieldValue(urlKey, undefined)
      if (defaults?.page) {
        setFieldValue(pageKey, defaults?.page)
      } else {
        const defaultFirstPage = entitledPreferencePages.filter(p => !omitPages.includes(p))[0]
        setFieldValue(pageKey, defaultFirstPage)
      }
    }
  }

  return (
    <>
      <SidebarFormGroup sx={{ py: 0 }}>
        <FormSectionSwitch
          name={experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksVisible}
          title={ExperienceBuilderSectionLabels[PreferenceExperienceBuilderSection.WelcomeTabQuickLinks]}
          onHandleChange={e => {
            setFieldValue(experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkOneVisible, e.target.checked)
          }}
        />
      </SidebarFormGroup>
      {showForm && (
        <SidebarFormGroup sx={{ pt: '12px', gap: 0 }}>
          <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider', pb: 2 }}>
            <Button endIcon={<Icon name="OArrowCDown" />} onClick={handleButtonClick} color="tertiary">
              {`Link ${tab + 1}`}
            </Button>
            <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose}>
              {Array.from({ length: 3 }, (v, i) => (
                <ActionSheetItem selected={tab === i} key="i" onClick={event => handleActionMenuItemClick(event, i)}>
                  <ListItemText selected={tab === i}>Link {i + 1}</ListItemText>
                </ActionSheetItem>
              ))}
            </ActionSheetWrapper>
          </Box>
          {/* Link 1 */}
          <Box px={2.25}>
            <TabPanel value={tab} index={0} sx={{ px: 0 }}>
              <SidebarFormGroup hasBorderBottom sx={{ paddingTop: '0' }}>
                <SidebarFormRadioGroup
                  name={experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkOneAction}
                  title={'Action'}
                  onChange={value => {
                    updateFieldsOnLinkActionChange(
                      value,
                      experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkOnePage,
                      experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkOneUrl,
                    )
                  }}
                  options={Object.values(QuickLinkAction).map(action => ({
                    label: QuickLinkActionLabels[action],
                    belowLabel:
                      actionOne === action ? (
                        <QuickLinkLabelRenderer
                          variant={action === QuickLinkAction.OpenUrl ? 'url' : 'page'}
                          linkNumber={0}
                        />
                      ) : null,
                    value: action,
                  }))}
                />
              </SidebarFormGroup>
              <SidebarFormGroup hasBorderBottom={hasLanguages}>
                <FormTextInput
                  name={experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkOneTitle}
                  label={'Link 1 Text'}
                  fullWidth
                  charLimit={100}
                />
              </SidebarFormGroup>
              <SidebarFormTranslations
                title={'Link 1 Translations'}
                name={experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkOneTitle}
                variant={'text'}
                charLimit={100}
              />
            </TabPanel>
            {/* Link 2 */}
            <TabPanel value={tab} index={1} sx={{ px: 0 }}>
              <FormSectionSwitch
                sx={{ mb: 3 }}
                size="small"
                name={experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkTwoVisible}
                title={
                  ExperienceBuilderSectionLabels[PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLink2Visible]
                }
              />
              {showForm && isQuickLinkTwoVisible && (
                <>
                  <SidebarFormGroup hasBorderBottom sx={{ paddingTop: '0' }}>
                    <SidebarFormRadioGroup
                      name={experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkTwoAction}
                      title={'Action'}
                      onChange={value => {
                        updateFieldsOnLinkActionChange(
                          value,
                          experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkTwoPage,
                          experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkTwoUrl,
                        )
                      }}
                      options={Object.values(QuickLinkAction).map(action => ({
                        label: QuickLinkActionLabels[action],
                        belowLabel:
                          actionTwo === action ? (
                            <QuickLinkLabelRenderer
                              variant={action === QuickLinkAction.OpenUrl ? 'url' : 'page'}
                              linkNumber={1}
                            />
                          ) : null,
                        value: action,
                      }))}
                    />
                  </SidebarFormGroup>
                  <SidebarFormGroup hasBorderBottom={hasLanguages}>
                    <FormTextInput
                      name={experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkTwoTitle}
                      label={'Link 2 Text'}
                      fullWidth
                      charLimit={100}
                    />
                  </SidebarFormGroup>
                  <SidebarFormTranslations
                    title={'Link 2 Translations'}
                    name={experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkTwoTitle}
                    variant={'text'}
                    charLimit={100}
                  />
                </>
              )}
            </TabPanel>
            {/* Link 3 */}
            <TabPanel value={tab} index={2} sx={{ px: 0 }}>
              <FormSectionSwitch
                size="small"
                sx={{ mb: 3 }}
                name={experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkThreeVisible}
                title={
                  ExperienceBuilderSectionLabels[PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLink3Visible]
                }
              />
              {showForm && isQuickLinkThreeVisible && (
                <>
                  <SidebarFormGroup hasBorderBottom sx={{ paddingTop: '0' }}>
                    <SidebarFormRadioGroup
                      name={experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkThreeAction}
                      title={'Action'}
                      onChange={value => {
                        updateFieldsOnLinkActionChange(
                          value,
                          experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkThreePage,
                          experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkThreeUrl,
                        )
                      }}
                      options={Object.values(QuickLinkAction).map(action => ({
                        label: QuickLinkActionLabels[action],
                        belowLabel:
                          actionThree === action ? (
                            <QuickLinkLabelRenderer
                              variant={action === QuickLinkAction.OpenUrl ? 'url' : 'page'}
                              linkNumber={2}
                            />
                          ) : null,
                        value: action,
                      }))}
                    />
                  </SidebarFormGroup>
                  <SidebarFormGroup hasBorderBottom={hasLanguages}>
                    <FormTextInput
                      name={experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkThreeTitle}
                      label={'Link 3 Text'}
                      fullWidth
                      charLimit={100}
                    />
                  </SidebarFormGroup>
                  <SidebarFormTranslations
                    title={'Link 3 Translations'}
                    name={experienceUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkThreeTitle}
                    variant={'text'}
                    charLimit={100}
                  />
                </>
              )}
            </TabPanel>
          </Box>
        </SidebarFormGroup>
      )}
    </>
  )
}
