import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import {
  AssignCategoryToPurposeBody,
  AssignCategoryToPurposeResponse,
} from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type Params = APIRequestParams<{
  categoryCode: string
  data: AssignCategoryToPurposeBody
}>

export const upsertTrackersCategoryPurposes = ({ categoryCode, data }: Params) => {
  return API.put<AssignCategoryToPurposeResponse>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/tracker/categories/${categoryCode}`,
    }),
    data,
  )
}
