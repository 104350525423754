import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchDeploymentPlanPreviewConfigurations } from 'api/deploymentPlans/fetchers/fetchDeploymentPlanPreviewConfigurations'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.deploymentPlanPreviewConfigurations,
  queryFn: fetchDeploymentPlanPreviewConfigurations,
  select: res => (res || []).map(({ data }) => data),
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useDeploymentPlanPreviewConfigurations = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
