import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

// utils
import { getInitialValues, getValidationSchema } from 'pages/orchestration/events/create/utils'
import { RoutesManager } from 'utils/routing/routesManager'

// components
import { Group } from 'components/ui-layouts/group/Group'
import { Button } from '@ketch-com/deck'
import { UpsertLayout } from 'components/ui-layouts/upsertLayout/UpsertLayout'
import { UpsertLayoutFooter } from 'components/ui-layouts/upsertLayout/UpsertLayoutFooter'
import { CreateEventForm } from 'pages/orchestration/events/create/CreateEventForm'

// types
import { EventV2DTO } from '@ketch-com/figurehead'
import { RightDTO } from 'interfaces/rights/right'

type Props = {
  isReady: boolean
  rights: RightDTO[]
  onSubmit: (values: EventV2DTO) => Promise<void>
  events: EventV2DTO[]
}

export const CreateEvent: React.FC<Props> = ({ isReady, rights, onSubmit, events }) => {
  const navigate = useNavigate()
  const initialValues = useMemo(getInitialValues, [])
  const validationSchema = useMemo(getValidationSchema, [])

  return (
    <UpsertLayout
      isReady={isReady}
      title="Create Custom Event"
      breadcrumbs={[
        { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
        { title: 'Events', link: RoutesManager.orchestration.events.root.getURL() },
        { title: 'Create Custom Event' },
      ]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm, isValid }) => (
        <>
          <CreateEventForm rights={rights} events={events} />
          <UpsertLayoutFooter>
            <Group>
              <Button
                disabled={isSubmitting}
                color="secondary"
                size="large"
                onClick={() => {
                  navigate(RoutesManager.orchestration.events.root.getURL())
                }}
              >
                Cancel
              </Button>
              <Button disabled={!isValid} size="large" onClick={submitForm} pending={isSubmitting}>
                Save
              </Button>
            </Group>
          </UpsertLayoutFooter>
        </>
      )}
    </UpsertLayout>
  )
}
