import React from 'react'
import { Typography } from '@mui/material'
import { Button, PopUp } from '@ketch-com/deck'

type Props = {
  onSubmit: () => void
  onCancel: () => void
}

export const JoinGatewayConfirmDeleteModal: React.FC<Props> = ({ onSubmit, onCancel }) => {
  return (
    <PopUp
      title="Delete Join"
      variant="dialog"
      popUpWidth="550px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="primary" size="large" onClick={onSubmit}>
            Confirm
          </Button>
        </>
      }
    >
      <Typography>
        Once this Join tile is deleted you will no longer be able to convert back to Multi Decision Gateway. Please
        confirm if you would like to proceed.
      </Typography>
    </PopUp>
  )
}
