import React, { CSSProperties, useContext, useState } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import {
  BannerThemeBuilderSection,
  ExperienceBuilderSection,
  PreferenceExperienceBuilderSection,
  ThemeBuilderSection,
} from '../utils/enums'
import { BuilderContext } from '../Builder'
import { styled } from '@mui/styles'
import { theme } from '@ketch-com/deck'

type Props = {
  hovered?: boolean
  section?: ExperienceBuilderSection | ThemeBuilderSection
  overlayProps?: CSSProperties
  sectionBorderRadius?: number
  sectionTopLeftBorderRadius?: number
  sectionTopRightBorderRadius?: number
  sectionBottomLeftBorderRadius?: number
  sectionBottomRightBorderRadius?: number
  children?: React.ReactNode
} & BoxProps

// Sections that should have the same hover box
const equivalentSections: { [section: string]: string[] } = {
  [BannerThemeBuilderSection.ActionButtons]: [
    BannerThemeBuilderSection.ActionButtonsSecondary,
    BannerThemeBuilderSection.ActionButtonsTertiary,
  ],

  [PreferenceExperienceBuilderSection.RequestsHomeRightsListTitle]: [
    PreferenceExperienceBuilderSection.RequestsHomeRightsListRequestTypes,
  ],
}

const SelectableBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 'fit-content',
}))

const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  transition: 'opacity .2s linear',
  zIndex: 5,
  top: -10,
  right: -10,
  bottom: -10,
  left: -10,
}))

// Get border radius, prioritizing corner if passed. Do not allow radius below 5px
const getBorder = (sectionBorderRadius: number, cornerBorderRadius: number | undefined) => {
  const priorityRadius = cornerBorderRadius ?? sectionBorderRadius
  return priorityRadius > 5 ? `${priorityRadius}px` : '5px'
}

export const SelectableSection: React.FC<Props> = ({
  section,
  overlayProps,
  sectionBorderRadius = 5,
  sectionTopLeftBorderRadius,
  sectionTopRightBorderRadius,
  sectionBottomLeftBorderRadius,
  sectionBottomRightBorderRadius,
  ...props
}) => {
  const { activeSection, setActiveSection, isTheme } = useContext(BuilderContext)
  const [hovered, setHovered] = useState(false)
  const isActiveSection =
    section &&
    activeSection &&
    (section === activeSection ||
      equivalentSections[section]?.includes(activeSection) ||
      equivalentSections[activeSection]?.includes(section))

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (section && !!setActiveSection) {
      setActiveSection(section)
    }
  }

  const handleMouseOver = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setHovered(true)
    e.stopPropagation()
  }

  const handleMouseOut = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setHovered(false)
  }

  const borderRadius = `${getBorder(sectionBorderRadius, sectionTopLeftBorderRadius)} ${getBorder(
    sectionBorderRadius,
    sectionTopRightBorderRadius,
  )} ${getBorder(sectionBorderRadius, sectionBottomLeftBorderRadius)} ${getBorder(
    sectionBorderRadius,
    sectionBottomRightBorderRadius,
  )}`

  return section ? (
    <SelectableBox
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      data-test-id={`SelectableSection-${section}`}
      {...props}
    >
      <Overlay
        style={{
          // Show outline and pointer when hovered, or when this section is selected in experience or theme builder
          ...(hovered || isActiveSection
            ? {
                outline: `2px solid ${theme.palette.heliotrope.main}`,
                cursor: 'pointer',
              }
            : {}),
          // Show background when hovered, or when this section is selected in experience builder only
          ...(hovered || (isActiveSection && !isTheme)
            ? {
                background: theme.palette.fadedHeliotrope.main,
              }
            : {}),
          borderRadius,
          ...overlayProps,
        }}
      />
      {props.children}
    </SelectableBox>
  ) : (
    <Box {...props}>{props.children}</Box>
  )
}
