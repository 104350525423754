import { Box, Typography } from '@mui/material'

import { useFormikContext } from 'formik'

import { OPERATOR_OPTIONS } from '../../types'
import { IGetDecisionGatewayInitialValues } from '../utils/getDecisionGatewayInitialValues'
import { Banner, Checkbox, theme } from '@ketch-com/deck'
import { useFormFields } from 'api/formFields/queries/useFormFields'
import { FormFieldCategoryDTO, FormFieldTypeDTO } from '@ketch-com/figurehead'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { FormInput } from 'components/form/FormInput'
import { FormError } from 'components/form/FormError'

type Props = {
  index: number
}

const getOperatorOptions = (contextVariableType?: number | string): { id: string; name: string }[] => {
  switch (contextVariableType) {
    case 1: //list
      return [OPERATOR_OPTIONS[0], OPERATOR_OPTIONS[1]]
    case 2: //integer
      return OPERATOR_OPTIONS
    case 3: //boolean
      return [OPERATOR_OPTIONS[0]]
    case 4: //string
      return [OPERATOR_OPTIONS[0], OPERATOR_OPTIONS[1]]
    case 'checkbox':
      return [OPERATOR_OPTIONS[6], OPERATOR_OPTIONS[7]]
    default:
      return [OPERATOR_OPTIONS[0], OPERATOR_OPTIONS[1]]
  }
}

export const FormFields: React.FC<Props> = ({ index }) => {
  const { data: formFieldVariables } = useFormFields({
    itemsPerPage: 2000,
    params: {
      includeMetadata: true,
      includeReservedFormFields: true,
    },
  })

  const { values, errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext<IGetDecisionGatewayInitialValues>()

  const checkboxError = errors?.transitions?.[index]?.hasOwnProperty('operand') && touched.transitions?.[index]?.operand

  const selectedFormFieldVariable = [...formFieldVariables].find(
    cv => cv.code === values?.transitions?.[index]?.variable,
  )

  const getFormFieldCategory = (formFieldCategory: string | undefined) => {
    switch (formFieldCategory) {
      case FormFieldCategoryDTO.DefaultFormFieldCategory:
        return 1
      case FormFieldCategoryDTO.CustomFormFieldCategory:
        return 2
      case FormFieldCategoryDTO.ReservedFormFieldCategory:
        return 3
      default:
        return 0
    }
  }

  return (
    <>
      <Box marginBottom={2}>
        <Banner severity="info">
          Please ensure all Experiences that trigger this workflow include the selected Form Field.
        </Banner>
      </Box>

      {/* Variable */}
      <Box marginBottom={1.5}>
        <FormDroplistButton
          size="small"
          formPropertyName={`transitions.${index}.variable`}
          fullWidth
          required
          label="Form Field"
          placeholder="Select Form Field"
          items={formFieldVariables.map(cv => ({
            id: cv.code,
            name: cv.name,
            formFieldId: cv.id,
            formFieldCategory: cv.category,
          }))}
          valueKey="id"
          disableClearable
          onItemClick={({ id, formFieldId, formFieldCategory }) => {
            /* Reset downstream choices when variable changes */
            setFieldValue(`transitions.${index}.operator`, '') // called "condition" the UI
            setFieldTouched(`transitions.${index}.operator`, false)

            setFieldValue(`transitions.${index}.value`, '') // called "Enter Variable Value" in the UI
            setFieldTouched(`transitions.${index}.value`, false)

            setFieldValue(`transitions.${index}.outcomeVariableStepID`, '') // only applies to outcome variables, but doesn't hurt to clear it
            setFieldTouched(`transitions.${index}.outcomeVariableStepID`, false)

            setFieldValue(`transitions.${index}.operand`, '') // called "condition" the UI
            setFieldTouched(`transitions.${index}.operand`, false)

            setFieldValue(`transitions.${index}.formFieldID`, formFieldId)
            setFieldTouched(`transitions.${index}.formFieldID`, false)

            setFieldValue(`transitions.${index}.formFieldCode`, id)
            setFieldTouched(`transitions.${index}.formFieldCode`, false)

            setFieldValue(`transitions.${index}.formFieldCategory`, getFormFieldCategory(formFieldCategory))
            setFieldTouched(`transitions.${index}.variableCategory`, false)
          }}
        />
      </Box>

      {/* Condition */}
      <Box marginBottom={1.5}>
        <FormDroplistButton
          size="small"
          required
          fullWidth
          formPropertyName={`transitions.${index}.operator`}
          label="Condition"
          placeholder="Select Condition"
          items={getOperatorOptions(selectedFormFieldVariable?.type)}
          valueKey="id"
          disableClearable
        />
      </Box>

      {/* Operand (varies based on type of variable) */}
      {selectedFormFieldVariable?.type === 'checkbox' ? (
        <>
          <Typography variant="label">Select Variable Value</Typography>
          <Box
            p={1}
            display="flex"
            flexDirection="column"
            gap={0.5}
            sx={{
              backgroundColor: theme.palette.white.main,
              borderRadius: '5px',
            }}
          >
            {selectedFormFieldVariable?.options?.map(option => {
              const operands = JSON.parse(values.transitions[index].operand || '[]')

              return (
                <Box key={option?.value} display="flex" alignItems="center">
                  <Box>
                    <Checkbox
                      value={option?.value}
                      name={`transitions.${index}.operand`}
                      onChange={() => {
                        let newValues = []

                        if (operands.includes(option?.value)) {
                          newValues = operands.filter((val: any) => val !== option?.value)
                        } else {
                          newValues = [...operands, option?.value]
                        }

                        setFieldValue(
                          `transitions.${index}.operand`,
                          !!newValues.length ? JSON.stringify(newValues) : '',
                        )
                      }}
                      checked={operands.includes(option?.value)}
                    />
                  </Box>
                  <Typography variant="body" component="div">
                    {option?.label}
                  </Typography>
                </Box>
              )
            })}
          </Box>
          {checkboxError && (
            <Box>
              <FormError msg="Select at least one option" />
            </Box>
          )}
        </>
      ) : selectedFormFieldVariable?.type === 'dropdown' ? (
        <FormDroplistButton
          size="small"
          required
          fullWidth
          formPropertyName={`transitions.${index}.operand`}
          label="Select Variable Value"
          placeholder="Select Value"
          items={
            selectedFormFieldVariable?.options?.map(option => ({
              id: option.value,
              name: option.value,
            })) || []
          }
          valueKey="id"
          disableClearable
        />
      ) : (
        <FormInput
          shouldUpdateDebounced
          size="small"
          disabled={!values?.transitions?.[index]?.variable}
          required
          type={selectedFormFieldVariable?.type === FormFieldTypeDTO.TextFormFieldType ? 'text' : 'number'}
          formPropertyName={`transitions.${index}.operand`}
          fullWidth
          label="Enter Variable Value"
          placeholder="Variable"
        />
      )}
    </>
  )
}
