import React, { useState } from 'react'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { useQueryClient } from 'react-query'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { DeleteLabelModal } from 'components/modals/labels/DeleteLabelModal'
import { useDeleteLabelV2 } from 'api/labels/mutations/useDeleteLabelV2'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { Classification, SetClassificationModal } from 'pages/assetManager/classifications/types'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type Props = {
  label: Classification
  setClassificationModal: SetClassificationModal
}

export const ClassificationActions: React.FC<Props> = ({ label, setClassificationModal }) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )
  const queryClient = useQueryClient()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteAsset = getIsPermitted([PERMISSIONS.ASSET_WRITE])

  const { mutateAsync: handleDeleteLabel } = useDeleteLabelV2({
    onSuccess: async () => {
      showToast({ content: 'Classification deleted', type: 'success' })
      await queryClient.invalidateQueries(
        isV2FeatureFlagEnabled ? ApiQueryKeys.classificationsPaginatedV2 : ApiQueryKeys.classificationsPaginatedV3,
      )
      setIsDeleteModalOpen(false)
    },
    onError: () => {
      showToast({ content: 'Failed to delete classification', type: 'error' })
      setIsDeleteModalOpen(false)
    },
  })

  const onSubmit = async () => await handleDeleteLabel({ params: { labelCode: label?.code || '' } })

  return (
    <>
      <ButtonOptions
        size="small"
        items={[
          {
            content: 'Edit',
            onClick: () =>
              setClassificationModal(prevValues => ({
                ...prevValues,
                data: label,
                display: true,
              })),
            icon: <EditOutlined />,
            hidden: !isPermittedToWriteAsset,
          },
          {
            content: 'Delete',
            onClick: () => setIsDeleteModalOpen(true),
            icon: <DeleteOutline />,
            hidden: !isPermittedToWriteAsset,
          },
        ]}
      />

      {isDeleteModalOpen && (
        <DeleteLabelModal
          labelName={label?.name || ''}
          onSubmit={onSubmit}
          onCancel={() => {
            setIsDeleteModalOpen(false)
          }}
        />
      )}
    </>
  )
}
