import Box from '@mui/material/Box'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Text } from 'components/ui-kit/typography/Text'

type Props = {
  assetSummary?: AssetSummaryDTO
}

export const LastUpdated: React.FC<Props> = ({ assetSummary }) => {
  return (
    <Box display="flex" alignItems="center" sx={{ pt: 2, pb: 4 }}>
      <Text size={14}>
        <FormattedDateRenderer date={assetSummary?.asset?.metadata?.updatedAt} />
      </Text>
    </Box>
  )
}
