import React from 'react'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'

import { formatCodeFromName } from 'utils/formatters'
import { FormRow } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'

type Props = {
  isEditMode?: boolean
}

export const PolicyScopeDetails: React.FC<Props> = ({ isEditMode = false }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext()

  return (
    <>
      <FormRow title="Basic Details">
        <Grid container>
          <Grid item xs={8}>
            <FormInput
              fullWidth
              required
              label="Name"
              formPropertyName="name"
              placeholder="Enter jurisdiction name"
              onChange={e => {
                if (!isEditMode) {
                  setFieldTouched('code', true)
                  setFieldValue('code', formatCodeFromName(e.target.value))
                }
              }}
            />
          </Grid>
        </Grid>

        <Grid container mt={2}>
          <Grid item xs={8}>
            <FormInput
              required
              disabled={isEditMode}
              fullWidth
              label="Code"
              formPropertyName="code"
              placeholder="Enter jurisdiction code"
            />
          </Grid>
        </Grid>

        <Grid container mt={2}>
          <Grid item xs={12}>
            <FormInput
              fullWidth
              label="Description"
              formPropertyName="description"
              placeholder="Enter description"
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
      </FormRow>
    </>
  )
}
