import { DsrFormPolicyStatements } from 'store/dsrTransponderFormSlice'

type Args = {
  activeDatabase: string
  policyStatements: DsrFormPolicyStatements
  attributeCode: string
  datasetCode: string
  updateValue: string
}

export const getUpdateEffectSelectionPayload = ({
  activeDatabase,
  policyStatements,
  attributeCode,
  datasetCode,
  updateValue,
}: Args): DsrFormPolicyStatements => {
  const payload = {
    ...policyStatements,
    [activeDatabase]: {
      ...policyStatements?.[activeDatabase],
      resource: activeDatabase,
      statements: {
        ...policyStatements?.[activeDatabase]?.statements,
        [`${attributeCode}`]: {
          effect: updateValue,
          assetType: 'attribute',
          parentAssetCode: datasetCode,
        },
      },
    },
  }

  if (updateValue === 'noAction') {
    delete payload?.[activeDatabase]?.statements?.[`${attributeCode}`]
  }

  return payload
}
