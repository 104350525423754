import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { IWorkflowConfig } from '../../../interfaces'
import { getIsApprovalTileWithoutATransition } from './getIsApprovalTileWithoutATransition'
import { getIsIdentityVerificationTileWithoutATransition } from './getIsIdentityVerificationTileWithoutATransition'

type AllowedTilesCodes = { code: string; disabledMessage: string }[]

export const getAllowedTilesCodes = (formikValues: IWorkflowConfig): AllowedTilesCodes => {
  const payload: AllowedTilesCodes = []
  const { steps } = formikValues
  const isApprovalTileWithoutATransition = getIsApprovalTileWithoutATransition(steps)
  const isIdentityVerificationTileWithoutATransition = getIsIdentityVerificationTileWithoutATransition(steps)

  if (isApprovalTileWithoutATransition) {
    payload.push({
      code: WorkflowActivityCode.DECISION_BOOLEAN,
      disabledMessage: 'Decision Boolean gateway must follow the Approval tile',
    })
  }

  if (isIdentityVerificationTileWithoutATransition) {
    payload.push({
      code: WorkflowActivityCode.DECISION_BOOLEAN,
      disabledMessage: 'Decision Boolean gateway must follow the Identity Verification tile',
    })
  }

  return payload
}
