import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import * as Yup from 'yup'
import { identitySpaceFieldValidator } from '../components/TaskDetails/components/IdentitySpaces/utils/getIdentitySpacesValidationSchema'

type Args = { workflowStep?: WorkflowExecutionStepDetailsDTO }

export const getTaskViewValidationSchema = ({ workflowStep }: Args) => {
  const {
    hasResolutionNotes,
    hasResolutionAttachments,
    hasDsrResolutionAttachments,
    hasIdentitySpaces,
    isDsrResolutionAttachmentsRequired,
    isResolutionAttachmentsRequired,
    isResolutionNotesRequired,
  } = workflowStep?.activity?.params || {}

  return Yup.object().shape({
    ...(hasResolutionAttachments &&
      isResolutionAttachmentsRequired && {
        resolutionAttachments: Yup.array().min(1, 'Required'),
      }),
    ...(hasResolutionNotes &&
      isResolutionNotesRequired && {
        resolutionDetails: Yup.string().required('Required'),
      }),
    ...(hasDsrResolutionAttachments &&
      isDsrResolutionAttachmentsRequired && {
        dsrResolutionAttachments: Yup.array().min(1, 'Required'),
      }),
    ...(hasIdentitySpaces && {
      identitySpaces: identitySpaceFieldValidator,
    }),
  })
}
