import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createFormField } from '../fetchers/createFormField'

const useCustomMutation = createUseMutation({
  mutationFn: createFormField,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateFormField = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
