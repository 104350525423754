import { APIListRequestParams } from 'api/common/utils'
import { FetchDataSubjectTypeTemplatesResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  query?: string
}>

export const fetchDataSubjectTypeTemplates = ({ query }: Params) =>
  API.get<FetchDataSubjectTypeTemplatesResponseBodyDTO>(
    formatRequestString({
      entityUrl: '/api/data-subject-type-templates',
      params: {
        filters: {
          query,
        },
      },
    }),
  )
