import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'utils/hooks/useAuth'

export const useAuthRedirect = () => {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
  }, [navigate, isAuthenticated])
}
