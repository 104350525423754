import { Box, BoxProps, styled } from '@mui/material'
import React, { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren, BoxProps {
  selected: boolean
}

const WrapperBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '2px',
  paddingRight: '2px',
  width: '100%',
})

const SelectedBar = styled(Box)(({ theme }) => ({
  width: 2,
  height: 18,
  background: theme.palette.Highlight.PrimaryDark,
  borderRadius: 100,
}))

export const SidebarItemWrapper: React.FC<Props> = ({ selected = false, children, ...rest }) => {
  return (
    <WrapperBox {...rest}>
      {children}
      <SelectedBar visibility={selected ? 'unset' : 'hidden'} />
    </WrapperBox>
  )
}
