import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { DeleteSubscriptionTopicResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  code: string
}>

export const deleteSubscriptionTopic = ({ code }: Params) =>
  API.delete<DeleteSubscriptionTopicResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/subscriptions/topics/${code}`,
    }),
  )
