import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'
import { useField, FieldValidator } from 'formik'

import { Hint } from 'components/ui-kit/form/common/hint/Hint'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { Input, Props as BaseInputProps } from 'components/ui-kit/input/Input'
import { Error } from 'components/ui-kit/form/common/error/Error'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseFormInput: {
        display: 'inline-block',
        position: 'relative',
      },
      input: {
        '& .MuiInputBase-input': {
          zIndex: 1,
          position: 'absolute',
          left: 0,
          right: 0,
          height: '100%',
        },
      },
      baseContent: {
        position: 'absolute',
        top: 0,
        left: 16,
        right: 16,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      withLabel: {
        top: 20,
      },
      color: ({ value }: { value: string }) => ({
        backgroundColor: value,
        border: `1px solid ${palette.iron.main}`,
        width: 18,
        height: 18,
        borderRadius: '50%',
      }),
      text: {
        fontSize: typography.pxToRem(14),
      },
      fullWidth: {
        display: 'block',
      },
    }),
  { name: 'FormColorInput' },
)

type OmittedProps = Omit<BaseInputProps, 'name' | 'value' | 'valid' | 'type'>

interface Props extends OmittedProps {
  /** Formik field name */
  name: string
  /** Text to be displayed below input */
  hint?: string
  /** Text to be displayed above input */
  label?: string | React.ReactNode
  /** Display Asterisk as required field */
  required?: boolean
  /** Custom validation method */
  validate?: FieldValidator
  /** Toggle "Optional" display */
  shouldShowOptional?: boolean
  /** Add escape hatch for automatic Formik update */
  shouldOverrideOnChange?: boolean
  /** Override label "Optional" copy */
  optionalOverrideText?: string
  /** Width */
  width?: string | number
}

export const FormColorInput: React.FC<Props> = ({
  name,
  hint = '',
  label = '',
  required = false,
  validate,
  onChange,
  shouldShowOptional = true,
  shouldOverrideOnChange = false,
  optionalOverrideText,
  fullWidth,
  width,
  ...baseInputProps
}) => {
  const [field, meta] = useField({ name, validate })
  const classes = useStyles({ value: field.value })
  const showError = meta.error && meta.touched

  const onChangeInner = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // Formik onChange
    field.onChange(e)

    // Outer onChange
    onChange?.(e)
  }

  return (
    <div className={clsx(classes.baseFormInput, { [classes.fullWidth]: fullWidth })}>
      {label && (
        <Label
          shouldShowOptional={shouldShowOptional}
          optionalOverrideText={optionalOverrideText}
          id={baseInputProps.id}
          required={required}
        >
          {label}
        </Label>
      )}

      <Input
        type="color"
        className={classes.input}
        sx={{ width }}
        {...baseInputProps}
        {...field}
        valid={!showError}
        fullWidth={fullWidth}
        onChange={onChangeInner}
      />

      <div className={clsx(classes.baseContent, { [classes.withLabel]: !!label })}>
        <div className={classes.text}>{field.value.toUpperCase()}</div>
        <div className={classes.color} />
      </div>

      {showError && <Error>{meta.error}</Error>}

      {hint && !showError && <Hint>{hint}</Hint>}
    </div>
  )
}
