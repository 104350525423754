import React from 'react'
import { useSetState } from 'react-use'

import { MaybeNull } from 'interfaces'
import { WorkflowExecutionDTO, WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { WorkflowExecutionStepInterventionStatus } from 'interfaces/workflowExecutions/workflowExecutionStepInterventionStatus'
import { useAuth } from 'utils/hooks/useAuth'
import { Button } from '@ketch-com/deck'
import { hasInvalidCredentialsIssue } from 'pages/orchestration/rightsQueue/utilsV2/hasInvalidCredentialsIssue'
import { WorkflowExecutionStepViewInvalidCredentialsModal } from '../viewInvalidCredentialsStep/WorkflowExecutionStepViewInvalidCredentialsModal'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionAutomatedStepCompleteModalContainer } from '../completeAutomatedStep/WorkflowExecutionAutomatedStepCompleteModalContainer'
import { WorkflowExecutionAutomatedStepReassignModalContainer } from '../reassignAutomatedStep/WorkflowExecutionAutomatedStepReassignModalContainer'
import { WorkflowExecutionAutomatedStepViewModal } from '../viewAutomatedStep/WorkflowExecutionAutomatedStepViewModal'

type Props = {
  rightInvocation: RightInvocationDTO
  workflowExecution: MaybeNull<WorkflowExecutionDTO>
  workflowExecutionStep: WorkflowExecutionStepDTO
}

export const WorkflowExecutionAutomatedStepActions: React.FC<Props> = ({
  rightInvocation,
  workflowExecution,
  workflowExecutionStep,
}) => {
  const { userData } = useAuth()
  const [
    { isAutomatedViewOpen, isAutomatedCompleteOpen, isAutomatedReassignOpen, isInvalidCredentialsOpen },
    setState,
  ] = useSetState({
    isAutomatedViewOpen: false,
    isAutomatedCompleteOpen: false,
    isAutomatedReassignOpen: false,
    isInvalidCredentialsOpen: false,
  })

  const { status, manualInterventionStatus, assignee } = workflowExecutionStep
  const isAssignedToMe = assignee === userData.userId
  const hasInvalidCredentials = hasInvalidCredentialsIssue(status, workflowExecutionStep.issues)

  return (
    <>
      <Button
        color="tertiary"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()

          const showManualComplete =
            (status === WorkflowExecutionStepStatus.IN_PROGRESS &&
              manualInterventionStatus === WorkflowExecutionStepInterventionStatus.NEEDED) ||
            (status === WorkflowExecutionStepStatus.FAIL && isAssignedToMe)
          const showManualReassign =
            status === WorkflowExecutionStepStatus.FAIL &&
            manualInterventionStatus === WorkflowExecutionStepInterventionStatus.NEEDED

          if (hasInvalidCredentials) {
            setState({ isInvalidCredentialsOpen: true })
          } else if (showManualComplete) {
            setState({ isAutomatedCompleteOpen: true })
          } else if (showManualReassign) {
            setState({ isAutomatedReassignOpen: true })
          } else {
            setState({ isAutomatedViewOpen: true })
          }
        }}
      >
        View
      </Button>

      {isAutomatedCompleteOpen && (
        <WorkflowExecutionAutomatedStepCompleteModalContainer
          rightInvocation={rightInvocation}
          workflowExecutionStep={workflowExecutionStep}
          onClose={() => {
            setState({
              isAutomatedCompleteOpen: false,
            })
          }}
        />
      )}

      {isAutomatedViewOpen && (
        <WorkflowExecutionAutomatedStepViewModal
          workflowExecution={workflowExecution}
          workflowExecutionStep={workflowExecutionStep}
          onClose={() => {
            setState({
              isAutomatedViewOpen: false,
            })
          }}
        />
      )}

      {isAutomatedReassignOpen && (
        <WorkflowExecutionAutomatedStepReassignModalContainer
          workflowExecution={workflowExecution}
          workflowExecutionStep={workflowExecutionStep}
          onClose={() => {
            setState({
              isAutomatedReassignOpen: false,
            })
          }}
        />
      )}

      {isInvalidCredentialsOpen && (
        <WorkflowExecutionStepViewInvalidCredentialsModal
          workflowExecutionStep={workflowExecutionStep}
          onClose={() => {
            setState({
              isInvalidCredentialsOpen: false,
            })
          }}
        />
      )}
    </>
  )
}
