import React, { createContext } from 'react'
import { useWorkflowStepDetailsContainerUtils } from '../hooks'
import { useWorkflowStepDetailsContainerUtilsReturn } from '../hooks/useWorkflowStepDetailsContainerUtils'

export const RightInvocationWorkflowStepDetailViewContext = createContext(
  {} as useWorkflowStepDetailsContainerUtilsReturn,
)

type Props = {
  children?: React.ReactNode
}

export const RightInvocationWorkflowStepDetailViewContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useWorkflowStepDetailsContainerUtils()

  return (
    <RightInvocationWorkflowStepDetailViewContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </RightInvocationWorkflowStepDetailViewContext.Provider>
  )
}

export const withRightInvocationWorkflowStepDetailViewContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <RightInvocationWorkflowStepDetailViewContextProvider>
        <Component {...props} />
      </RightInvocationWorkflowStepDetailViewContextProvider>
    )
  }
}
