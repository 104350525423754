import React, { useContext } from 'react'
import { Box, FormControlLabel, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { PreferenceField } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import { Radio, RadioGroup } from '@ketch-com/deck'

type Props = {}

export const Position: React.FC<Props> = () => {
  const { sidebarActiveTab } = useContext(ExperienceUpsertContext)
  const { values, setFieldValue } = useFormikContext<PreferenceExperienceFormValues>()

  if (sidebarActiveTab !== PreferenceField.SUBSCRIPTIONS_CONTROL) return null

  return (
    <Box ml={2}>
      <RadioGroup
        row
        name="positionType"
        value={values?.subscriptions?.controlItems?.[0]?.itemDetail?.positionType}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, value) => {
          setFieldValue('subscriptions.controlItems[0].itemDetail.positionType', Number(value))
        }}
      >
        <FormControlLabel
          value={1}
          control={<Radio />}
          label={
            <Typography
              variant={`${values?.subscriptions?.controlItems?.[0]?.itemDetail?.positionType === 1 ? 'label' : 'body'}`}
            >
              Top
            </Typography>
          }
        />
        <FormControlLabel
          value={2}
          control={<Radio />}
          label={
            <Typography
              variant={`${values?.subscriptions?.controlItems?.[0]?.itemDetail?.positionType === 2 ? 'label' : 'body'}`}
            >
              Bottom
            </Typography>
          }
        />
      </RadioGroup>
    </Box>
  )
}
