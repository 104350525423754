import React, { createContext } from 'react'
import { useApiCallContainerUtils, UseApiCallContainerUtils } from '../hooks/useApiCallContainerUtils'

export const ApiCallContext = createContext({} as UseApiCallContainerUtils)

type Props = {
  children?: React.ReactNode
}

export const ApiCallContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useApiCallContainerUtils()

  return (
    <ApiCallContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </ApiCallContext.Provider>
  )
}

export const withApiCallContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <ApiCallContextProvider>
        <Component {...props} />
      </ApiCallContextProvider>
    )
  }
}
