import React from 'react'

import { CanonicalPurposeDTO } from 'interfaces/purposes/canonicalPurpose'
import { AppsInstancesPurposeDTO } from 'interfaces/apps'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { Box, List, ListItem, Tooltip, Typography } from '@mui/material'
import { Chip } from '@ketch-com/deck'

type Props = {
  appsInstancesPurpose: AppsInstancesPurposeDTO
  canonicalPurposes: CanonicalPurposeDTO[]
}

export const PermitPropagationCanonicalPurposesRenderer: React.FC<Props> = ({
  appsInstancesPurpose,
  canonicalPurposes,
}) => {
  const { app, purposes } = appsInstancesPurpose
  const configured = Object.values(purposes || {}).filter(Boolean)

  return configured.length ? (
    <Tooltip
      title={
        <Box>
          <List sx={{ listStyleType: 'disc', pl: 1.5 }}>
            {app?.supportedPurposes?.map(canonicalPurposeCode => (
              <ListItem sx={{ display: 'list-item' }} disablePadding key={canonicalPurposeCode}>
                <Typography variant="smallBody" color="white.main">
                  {canonicalPurposes.find(({ code }) => code === canonicalPurposeCode)?.name}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      }
    >
      <Box>
        <Chip clickable size="small" label={`${configured?.length || 0} / ${app?.supportedPurposes?.length || 0}`} />
      </Box>
    </Tooltip>
  ) : (
    <EmptyValueRenderer text={'Not Configured'} />
  )
}
