import React, { createContext } from 'react'
import { ThemeViewUtils, useThemeViewUtils } from '../utils/useThemeViewUtils'

export const ThemeViewContext = createContext({} as ThemeViewUtils)

type Props = {
  children?: React.ReactNode | undefined
}

export const ThemeViewContextProvider: React.FC<Props> = ({ children }) => {
  // Use utils hook
  const hookValues = useThemeViewUtils()

  return <ThemeViewContext.Provider value={{ ...hookValues }}>{children}</ThemeViewContext.Provider>
}

export const withThemeViewContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <ThemeViewContextProvider>
        <Component {...props} />
      </ThemeViewContextProvider>
    )
  }
}
