import { useState } from 'react'
import { useUsers } from 'api/users/queries/useUsers'
import { useDatabasesPaginated } from 'api/assets/queries/useDatabasesPaginated'
import { useIdentitySpacesPaginated } from 'api/identitySpaces/queries/useIdentitySpacePaginated'
import { useContextVariablesPaginated } from 'api/contextVariables/queries/useContextVariablesPaginated'
import { ContextVariableCategoryDTO } from '@ketch-com/figurehead'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'

export const useImportContextUtils = (step: ICanvasStep) => {
  const [databaseQuery, setDatabaseQuery] = useState(step?.workflow?.params?.databaseResourceName)

  const [toContextVariableQuery, setToContextVariableQuery] = useState(step?.workflow?.params?.identityToName)
  const { isLoading: isLoadingUsers } = useUsers({ params: { active: true } })
  const { data: databaseResourcesData, isLoading: isLoadingDatabaseResources } = useDatabasesPaginated({
    params: {
      type: 'SQL',
      searchString: databaseQuery,
      limit: 20,
    },
  })

  const { data: identitySpaces, isLoading: isLoadingIdentitySpaces } = useIdentitySpacesPaginated({
    params: {
      query: '',
      limit: 300,
    },
  })

  const { data: toContextVariables, isLoading: isLoadingToContextVariables } = useContextVariablesPaginated({
    params: {
      query: toContextVariableQuery,
      category: ContextVariableCategoryDTO.DataSubjectContextVariableCategory,
    },
  })
  const isLoading =
    isLoadingUsers || isLoadingDatabaseResources || isLoadingIdentitySpaces || isLoadingToContextVariables
  return {
    isLoadingUsers,
    isLoadingDatabaseResources,
    databaseResources: databaseResourcesData?.databaseResources || [],
    isLoadingFromIdentitySpaces: isLoadingIdentitySpaces,
    fromIdentitySpaces: identitySpaces,
    isLoadingToIdentitySpaces: isLoadingIdentitySpaces,
    toIdentitySpaces: identitySpaces,
    isLoadingToContextVariables,
    toContextVariables,
    setDatabaseQuery,
    setToContextVariableQuery,
    isLoading,
  }
}
