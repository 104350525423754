export enum WorkflowExecutionStepStatus {
  PENDING = 1,
  IN_PROGRESS = 2,
  SUCCESS = 3,
  FAIL = 4,
  INVALID_CREDENTIALS = 5,
  TERMINATED = 6,
  SKIPPED = 7,
  NOT_STARTED = 99, // "Not started" never gets directly returned from BE, since it is only used for workflow previews, showing steps that are not yet executed.
  MIXED = 100, // "Mixed" never gets directly returned from BE, since it is only used for workflow previews, indicating a group with steps that are in multiple statuses.
}

export const WORKFLOW_EXECUTION_STEP_NAMES = {
  [WorkflowExecutionStepStatus.PENDING]: 'Pending',
  [WorkflowExecutionStepStatus.IN_PROGRESS]: 'In Progress',
  [WorkflowExecutionStepStatus.SUCCESS]: 'Completed',
  [WorkflowExecutionStepStatus.FAIL]: 'Fail',
  [WorkflowExecutionStepStatus.INVALID_CREDENTIALS]: 'Invalid Credentials',
  [WorkflowExecutionStepStatus.TERMINATED]: 'Terminated',
  [WorkflowExecutionStepStatus.SKIPPED]: 'Skipped',
  [WorkflowExecutionStepStatus.NOT_STARTED]: 'Not Started',
  [WorkflowExecutionStepStatus.MIXED]: 'Mixed',
}
