import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { CreateProcessingActivityRequestBodyDTO, CreateProcessingActivityResponseBodyDTO } from '@ketch-com/figurehead'

type Params = {
  formData: CreateProcessingActivityRequestBodyDTO
}

export const createProcessingActivity = ({ formData }: Params) =>
  API.post<CreateProcessingActivityResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/processing-activities`,
    }),
    formData,
  )
