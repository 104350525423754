import React from 'react'

import { PolicyScopesList } from 'pages/policyCenter/policyScopes/list/PolicyScopesList'
import { usePolicyScopesPaginated } from 'api/policyScopes/queries/usePolicyScopesPaginated'
import { useRights } from 'api/rights/queries/useRights'
import { useRegulations } from 'api/regulations/queries/useRegulations'

export const PolicyScopesListContainer: React.FC = () => {
  const {
    data: policyScopes,
    isLoading: isPolicyScopesLoading,
    pagination,
  } = usePolicyScopesPaginated({
    params: {
      includeIssues: true,
      includeMetadata: true,
    },
  })

  const { data: rights, isLoading: isRightsLoading } = useRights({
    params: {
      includeIssues: true,
      includeMetadata: true,
    },
  })

  const { data: regulations, isLoading: isRegulationsLoading } = useRegulations({
    params: {
      includeIssues: true,
      includeMetadata: true,
    },
  })

  const isReady = !isPolicyScopesLoading && !isRightsLoading && !isRegulationsLoading

  return (
    <PolicyScopesList
      policyScopes={policyScopes}
      regulations={regulations}
      rights={rights}
      pagination={pagination}
      isReady={isReady}
    />
  )
}
