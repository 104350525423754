import React from 'react'
import { useFormikContext } from 'formik'

import { ExperienceDTO } from 'interfaces/experiences/experience'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { ExperienceType } from 'interfaces/experiences/experienceType'
import { EXPERIENCE_DEFAULT_DISPLAY_OPTIONS } from 'interfaces/experiences/experienceDefaultDisplay'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import {
  DeploymentPlanFormValues,
  DeploymentPlanFormSpecialValues,
} from 'pages/consentAndRights/deploymentPlans/upsert/utils'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { Table } from 'components/ui-layouts/table/Table'
import { FormDropList } from 'components/ui-kit/form/dropList/FormDropList'
import { DeploymentPlanExperienceAssignment } from 'pages/consentAndRights/deploymentPlans/upsert/components/deploymentPlanExperiences/DeploymentPlanExperienceAssignment'

type Props = {
  policyScopes: PolicyScopeDTO[]
  experiences: ExperienceDTO[]
}

export const DeploymentPlanExperiences: React.FC<Props> = ({ policyScopes, experiences }) => {
  const { values } = useFormikContext<DeploymentPlanFormValues>()
  const { isEntitled } = useIsEntitled()
  const isEntitledToContentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)

  return (
    <FormSection title="Experiences">
      <Table
        variant="page"
        items={policyScopes}
        cellSettings={{
          policyScope: {
            label: 'Jurisdiction',
            cellRenderer: ({ code, name }) => <NameAndCodeCellRenderer name={name} code={code} />,
          },
          ...(isEntitledToContentAndDisclosure && {
            consentAndDisclosure: {
              label: 'Consent & Disclosure Experience',
              width: 300,
              cellRenderer: ({ code }) => (
                <DeploymentPlanExperienceAssignment
                  experienceType={ExperienceType.CONSENT}
                  policyScopeCode={code}
                  experiences={experiences}
                />
              ),
            },
            defaultConsentDisplay: {
              label: 'Default Consent Display',
              width: 300,
              cellRenderer: ({ code }) => {
                const isNoExperienceSelected =
                  values.policyScopeExperiences[code]?.[ExperienceType.CONSENT]?.experienceID ===
                  DeploymentPlanFormSpecialValues.NO_EXPERIENCE

                return (
                  <>
                    {!isNoExperienceSelected && (
                      <FormDropList
                        name={`policyScopeExperiences.${code}.${ExperienceType.CONSENT}.experienceDefault`}
                        items={EXPERIENCE_DEFAULT_DISPLAY_OPTIONS}
                        placeholder="Select Default Display"
                      />
                    )}
                  </>
                )
              },
            },
          }),
          preference: {
            label: 'Preference Experience',
            width: 300,
            cellRenderer: ({ code }) => (
              <DeploymentPlanExperienceAssignment
                experienceType={ExperienceType.PREFERENCE}
                policyScopeCode={code}
                experiences={experiences}
              />
            ),
          },
        }}
      />
    </FormSection>
  )
}
