import { mapValues, groupBy } from 'lodash'

import { MaybeNull } from 'interfaces/common'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans/deploymentPlan'

import { ExperienceDTO } from 'interfaces/experiences/experience'
import { ExperienceType } from 'interfaces/experiences/experienceType'
import { ExperienceDefaultDisplay } from 'interfaces/experiences/experienceDefaultDisplay'
import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { DEFAULT_THEME_CODE } from 'utils/constants/themes'
import { PreviewConfigurationDTO } from '@ketch-com/figurehead'

export enum DeploymentPlanView {
  BANNER = 'BANNER',
  MODAL = 'MODAL',
  PREFERENCE = 'PREFERENCE',
}

export type ConsentConfigurationMapping = {
  experienceDefault: ExperienceDefaultDisplay
  configuration: PreviewConfigurationDTO
}

export type ConfigurationMapping = {
  [ExperienceType.CONSENT]?: ConsentConfigurationMapping
  [ExperienceType.PREFERENCE]?: {
    configuration: PreviewConfigurationDTO
  }
}

export type ConfigurationMappings = {
  [policyScopeCode: string]: ConfigurationMapping
}

export const getConsentExperienceView = (consentConfigurationMapping: ConsentConfigurationMapping) => {
  const experienceDefault = consentConfigurationMapping.experienceDefault

  let activeView =
    experienceDefault === ExperienceDefaultDisplay.BANNER ? DeploymentPlanView.BANNER : DeploymentPlanView.MODAL

  const purposes = consentConfigurationMapping.configuration.purposes
  const purposesOptIn = purposes?.filter(({ requiresOptIn }) => requiresOptIn)

  if (purposesOptIn?.length) {
    if (experienceDefault === ExperienceDefaultDisplay.BANNER) {
      const isActionButtonConfigured = Boolean(
        consentConfigurationMapping?.configuration?.experiences?.consent?.banner?.secondaryButtonText,
      )

      if (!isActionButtonConfigured) {
        activeView = DeploymentPlanView.MODAL
      }
    }
  } else if (!purposes?.length) {
    if (experienceDefault === ExperienceDefaultDisplay.MODAL) {
      activeView = DeploymentPlanView.BANNER
    }
  }

  return activeView
}

export const getValidPolicyScopesExperiences = ({
  deploymentPlan,
  experiences,
}: {
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
  experiences: ExperienceDTO[]
}) =>
  (deploymentPlan?.policyScopeExperiences || []).filter(
    // TODO: Some of old entities has no policyScopeCode or has invalid experienceID
    ({ policyScopeCode, experienceID }) => policyScopeCode && experiences.find(({ ID }) => ID === experienceID),
  )

export const getConfigurationsParams = ({
  deploymentPlan,
  experiences,
  themes,
}: {
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
  experiences: ExperienceDTO[]
  themes: ThemeDTO[]
}) => {
  const themeCode = themes.find(({ ID }) => ID === deploymentPlan?.themeID)?.code || DEFAULT_THEME_CODE

  return getValidPolicyScopesExperiences({ deploymentPlan, experiences }).map(({ experienceID, policyScopeCode }) => ({
    experienceCode: experiences.find(({ ID }) => ID === experienceID)?.code as string,
    policyScopeCode,
    themeCode,
  }))
}

export const getConfigurationsMappings = ({
  deploymentPlan,
  experiences,
  deploymentPlanPreviewConfigurations,
}: {
  deploymentPlan: DeploymentPlanDTO
  experiences: ExperienceDTO[]
  deploymentPlanPreviewConfigurations: PreviewConfigurationDTO[]
}): ConfigurationMappings =>
  mapValues(
    groupBy(getValidPolicyScopesExperiences({ deploymentPlan, experiences }), ({ policyScopeCode }) => policyScopeCode),
    policyScopeExperiences =>
      policyScopeExperiences.reduce<ConfigurationMapping>(
        (acc, { experienceType, experienceDefault, policyScopeCode }) => {
          const isConsent = experienceType === ExperienceType.CONSENT

          const configuration = deploymentPlanPreviewConfigurations.find(
            ({ jurisdiction, experiences }) =>
              jurisdiction?.code === policyScopeCode &&
              (isConsent ? !!experiences?.consent : !!experiences?.preference),
          )!

          const configurationWithValidPurposes = {
            ...configuration,
            purposes: (configuration.purposes || []).filter(
              ({ allowsOptOut, requiresOptIn }) => allowsOptOut || requiresOptIn,
            ),
          }

          return {
            ...acc,
            ...(isConsent
              ? {
                  [ExperienceType.CONSENT]: {
                    experienceDefault: experienceDefault!,
                    configuration: configurationWithValidPurposes,
                  },
                }
              : {
                  [ExperienceType.PREFERENCE]: {
                    configuration: configurationWithValidPurposes,
                  },
                }),
          }
        },
        {},
      ),
  )
