export enum ApplicationStatus {
  DEPLOYED = 'Deployed',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  PUBLISHED = 'Published',
  TESTING = 'Testing',
  FULFILLED = 'Fulfilled',
  OVERDUE = 'Overdue',
}

export const SENSITIVE_APPLICATION_STATUSES = [
  ApplicationStatus.DEPLOYED,
  ApplicationStatus.ACTIVE,
  ApplicationStatus.PUBLISHED,
  ApplicationStatus.TESTING,
]
