import React, { useState } from 'react'
import pluralize from 'pluralize'
import { Box, AlertColor } from '@mui/material'
import { Banner, Button, PopUp } from '@ketch-com/deck'
import { AssignCategoryToPurposeResponse } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { CategoryPurposeMapping } from 'pages/policyCenter/trackers/trackersSettings/components'

type Props = {
  categoryToPurposeResponse: AssignCategoryToPurposeResponse
}

export const CategoryToPurposeResponse: React.FC<Props> = ({ categoryToPurposeResponse }: Props) => {
  const [showUpdateMappings, setShowUpdateMappings] = useState(false)
  return (
    <Box mb={2} width={560}>
      <Banner
        isCompact
        title={`${categoryToPurposeResponse?.length} ${pluralize(
          'Tracker',
          categoryToPurposeResponse?.length,
        )} Updated`}
        severity={'info' as AlertColor}
        variant={'filled'}
        action={
          <Button variant="link" onClick={() => setShowUpdateMappings(true)}>
            View Updates
          </Button>
        }
      >
        <Box width={360}>Recent changes to the settings below have led to updates in tracker-purpose mappings.</Box>
      </Banner>

      {showUpdateMappings ? (
        <PopUp
          title={`${categoryToPurposeResponse?.length} ${pluralize(
            'Tracker',
            categoryToPurposeResponse?.length,
          )} Updated`}
          popUpWidth="820px"
          onClose={() => setShowUpdateMappings(false)}
          popUpActionChildren={
            <Button
              color="secondary"
              size="large"
              onClick={() => {
                setShowUpdateMappings(false)
              }}
            >
              Close
            </Button>
          }
        >
          <Box>
            {categoryToPurposeResponse?.map((categoryPurposeMapping, i) => (
              <CategoryPurposeMapping key={i} categoryPurposeMapping={categoryPurposeMapping} />
            ))}
          </Box>
        </PopUp>
      ) : null}
    </Box>
  )
}
