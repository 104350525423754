import React from 'react'
import { Box, Typography, styled } from '@mui/material'

import { Button, Icon, PopUp } from '@ketch-com/deck'

const FaqBody = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  padding: '18px 32px',
}))

const FaqRow = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(4),
}))

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const FaqModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <PopUp
      title="How Does it Work"
      isOpen={isOpen}
      popUpWidth="800px"
      onClose={() => onClose()}
      popUpActionChildren={
        <Button color="secondary" size="large" onClick={() => onClose()}>
          Cancel
        </Button>
      }
    >
      <FaqBody>
        <FaqRow>
          <Box>
            <Icon width={56} height={56} name="FaqLabelsA" />
          </Box>
          <Box>
            <Typography variant="h4">Classifications & Categories</Typography>
            <Typography variant="body">
              Classifications are based on data sensitivity and value to facilitate proper security measures and access
              controls. Categories are based on common attributes or characteristics of data to form a distinct group.
            </Typography>
          </Box>
        </FaqRow>
        <FaqRow>
          <Box>
            <Icon width={56} height={56} name="FaqLabelsB" />
          </Box>
          <Box>
            <Typography variant="h4">Automated Labeling</Typography>
            <Typography variant="body">
              Our AI engine streamlines label creation and assignment, automatically creating Categories and
              Classifications for any data assets or system you add. Automated labeling has two levels of confidence and
              you’re in control to calibrate a precision by confirming or dismissing a label.
            </Typography>
          </Box>
        </FaqRow>
        <FaqRow>
          <Box>
            <Icon width={56} height={56} name="FaqLabelsC" />
          </Box>
          <Box>
            <Typography variant="h4">Review, Confirm or Dismiss</Typography>
            <Typography variant="body">
              When an automated label is created or assigned, you can keep or remove it. By confirming or dismissing a
              label, you help us calibrate the algorithm so that it will be more precise in defining labels next time.
            </Typography>
          </Box>
        </FaqRow>
        <FaqRow>
          <Box>
            <Icon width={56} height={56} name="FaqLabelsD" />
          </Box>
          <Box>
            <Typography variant="h4">Build Automation Rules</Typography>
            <Typography variant="body">
              You can create rules on how Categories inform which Classifications to assign and vice versa. Example: if
              an object has a “Sensitive” Category assigned, it should also get “Email” and “Address” Classifications
              automatically.
            </Typography>
          </Box>
        </FaqRow>
      </FaqBody>
    </PopUp>
  )
}
