import React, { ReactNode } from 'react'
import { Box } from '@mui/material'

import { Text } from 'components/ui-kit/typography/Text'

type InfoRowProps = {
  title: string
  info: ReactNode
  hasBorderBottom?: boolean
}

export const HookExecutedDmlModalInfoRow: React.FC<InfoRowProps> = ({ title, info, hasBorderBottom = true }) => {
  return (
    <Box
      sx={{
        borderBottom: ({ palette }) => (hasBorderBottom ? `1px solid ${palette.iron.main}` : undefined),
        pt: 2,
        pb: 2,
        minHeight: 69,
      }}
      display="flex"
    >
      <Box display="flex" flex={3.25}>
        <Text size={14} color="grey">
          {title}
        </Text>
      </Box>
      <Box display="flex" flex={8.75}>
        {info}
      </Box>
    </Box>
  )
}
