import React from 'react'
import { showToast } from 'components/modals/AlertComponent'
import { useParams } from 'react-router-dom'
import { useLegalBases } from 'api/legalBases/queries/useLegalBases'
import { ViewSubscriptionTopic } from 'pages/policyCenter/subscriptions/subscriptionTopic/view/ViewSubscriptionTopic'
import { UrlParams } from 'pages/assetManager/dsrConfig/interfaces'
import { useSubscriptionTopic } from 'api/subscriptions/queries/useSubscriptionTopic'
import { SubscriptionTopicDTO } from '@ketch-com/figurehead'
import { useOrganizationLanguagesPaginated } from 'api/languages/queries/useOrganizationLanguagesPaginated'
import { useSubscriptionContactMethodsPaginated } from 'api/subscriptions/queries/useSubscriptionContactMethodsPaginated'
import { useSubscriptionTopicOrchestrations } from 'api/subscriptions/queries/useSubscriptionTopicOrchestrations'

export const ViewSubscriptionTopicContainer: React.FC = () => {
  const { code } = useParams<UrlParams>()
  const { data: legalBases, isLoading: isLegalBasesLoading } = useLegalBases({
    onError: () => {
      showToast({ content: 'Failed to fetch legal basis', type: 'error' })
    },
  })

  const { data: subscriptionTopic, isLoading: isSubscriptionTopicLoading } = useSubscriptionTopic({
    params: { code, includeMetadata: true },
    onError: () => {
      showToast({ content: 'Failed to fetch subscriptions', type: 'error' })
    },
  })

  const { data: organizationLanguages, isLoading: isOrganizationLanguagesLoading } = useOrganizationLanguagesPaginated({
    params: {
      includeMetadata: true,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch organization languages', type: 'error' })
    },
  })

  const { data: subscriptionContactMethods, isLoading: isSubscriptionContactMethodsLoading } =
    useSubscriptionContactMethodsPaginated({
      params: {},
      onError: () => {
        showToast({ content: 'Failed to fetch subscription contact methods', type: 'error' })
      },
    })

  const { data: subscriptionTopicOrchestrations, isLoading: isSubscriptionTopicOrchestrationsLoading } =
    useSubscriptionTopicOrchestrations({
      params: { code },
      onError: () => {
        showToast({ content: 'Failed to fetch subscription orchestrations', type: 'error' })
      },
    })

  const isReady =
    !isLegalBasesLoading &&
    !isSubscriptionTopicLoading &&
    !isOrganizationLanguagesLoading &&
    !isSubscriptionContactMethodsLoading &&
    !isSubscriptionTopicOrchestrationsLoading

  return (
    <ViewSubscriptionTopic
      isReady={isReady}
      legalBases={legalBases}
      organizationLanguages={organizationLanguages}
      subscriptionTopic={subscriptionTopic?.topic || ({} as SubscriptionTopicDTO)}
      subscriptionContactMethods={subscriptionContactMethods?.contactMethods || []}
      subscriptionTopicOrchestrations={subscriptionTopicOrchestrations || {}}
    />
  )
}
