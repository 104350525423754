import React, { useContext, useMemo } from 'react'
import { isUndefined } from 'lodash'

import {
  WorkflowActivityConnectorCode,
  WorkflowActivityGatewayMode,
} from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep, ICoordinates } from 'pages/orchestration/workflows/edit/interfaces'
import { Activity } from 'pages/orchestration/workflows/edit/konvaEntities/Activity'
import { Workflow } from 'pages/orchestration/workflows/edit/konvaEntities/Workflow'
import { Gateway } from 'pages/orchestration/workflows/edit/konvaEntities/Gateway'
import { Placeholder } from 'pages/orchestration/workflows/edit/konvaEntities/Placeholder'
import { EndFlow } from 'pages/orchestration/workflows/edit/konvaEntities/EndFlow'
import { JoinGatewayInfo } from '../../utils/steps/getJoinTileRenderDetails'
import { isNewStepConnectedNestStart } from '../../utils/steps/isNewStepConnectedNestStart'
import { EditWorkflowContext } from '../../contexts/EditWorkflowContext'
import { CanvasImage } from '../../konvaEntities/common/CanvasImage'
import * as CanvasConstants from '../../utils/constants'
import SelectedStateSVG from 'assets/icons/canvas_selected_state.svg'
import SelectedStateNoGlowSVG from 'assets/icons/canvas_selected_no_glow_state.svg'
import { getAbsoluteCoordinates } from '../../utils/positioning/absoluteCoordinates'
import { WorkflowCanvasContext } from '../../components/WorkflowCanvas/context/WorkflowCanvasContext'

interface IStepByTypeProps {
  steps: Array<ICanvasStep>
  step: ICanvasStep
  gridPosition: ICoordinates
  joinStepDetails?: JoinGatewayInfo
  connectedNestId?: string
  connectedNestStartPosition?: number
}

export const DecideStepType: React.FC<IStepByTypeProps> = ({
  steps,
  step,
  gridPosition,
  joinStepDetails,
  connectedNestId,
  connectedNestStartPosition,
}) => {
  const isEndTile =
    !isUndefined(step?.finish) || step?.workflow?.temporalFunctionName === WorkflowActivityConnectorCode.END
  const isActivity = !isUndefined(step?.activity)
  const isWorkflow = !isUndefined(step?.workflow)
  const isNonJoinGateway = !isUndefined(step?.gateway) && !joinStepDetails
  const isPlaceholder = !isUndefined(step?.placeholder)

  // For performance reasons, this needs to be in a useMemo. Be careful that it doesn't produce wacky results with the hot reloading dev server.
  const isNewStepConnectedNest = useMemo(
    () =>
      isNewStepConnectedNestStart({
        step,
        steps,
        requireAllPathsLeadingToJoinValidation: true,
      }),
    [steps, step],
  )

  // If the step represents the start of a connected nest, pass its ID and Y axis position down to all children
  const newConnectedNestId = connectedNestId
    ? connectedNestId
    : isNewStepConnectedNest
    ? (step.ID as string)
    : undefined
  const newConnectedNestYPosition = connectedNestStartPosition
    ? connectedNestStartPosition
    : isNewStepConnectedNest
    ? gridPosition.y
    : connectedNestStartPosition

  const commonProps = {
    gridPosition,
    step,
    steps,
    connectedNestId: newConnectedNestId,
    connectedNestStartPosition: newConnectedNestYPosition,
  }

  if (isEndTile) {
    return <EndFlow {...commonProps} />
  }

  if (isActivity) {
    return <Activity {...commonProps} />
  }

  if (isWorkflow) {
    return <Workflow {...commonProps} />
  }

  if (isNonJoinGateway) {
    return <Gateway {...commonProps} />
  }

  if (isPlaceholder) {
    return <Placeholder {...commonProps} />
  }

  if (joinStepDetails?.shouldDrawTile) {
    return <Gateway {...commonProps} gridPosition={joinStepDetails.lineEndPosition} />
  }

  return null
}

export const StepByType: React.FC<IStepByTypeProps> = props => {
  const { step, gridPosition, joinStepDetails } = props

  const { activeStep } = useContext(EditWorkflowContext)
  const { hoveredCopySteps } = useContext(WorkflowCanvasContext)
  const isJoinGateway = step?.gateway?.mode === WorkflowActivityGatewayMode.JOIN

  // Step is active if it matches the active step's ID
  // Joins should only show active state if they are the chosen nest path to draw the join
  const isActiveStep =
    activeStep?.ID === step.ID && (!isJoinGateway || (isJoinGateway && joinStepDetails?.shouldDrawTile))

  const hasCopyHoveredSteps = !!hoveredCopySteps.length
  const isCopyHoveredStep =
    hasCopyHoveredSteps && hoveredCopySteps.includes(step) && !(isJoinGateway && !joinStepDetails?.shouldDrawTile)
  const isActiveGatewayForHoveredPath = isActiveStep && hasCopyHoveredSteps && !isCopyHoveredStep

  // The below coordinates were adjusted manually to center images of different size and aspect ratio
  const selectedBackgroundYPosition = (joinStepDetails?.lineEndPosition.y || gridPosition.y) - 0.22
  const selectedBackgroundPosition = getAbsoluteCoordinates({
    x: gridPosition.x - 0.27,
    y: selectedBackgroundYPosition,
  })

  return (
    <>
      {isActiveStep || isCopyHoveredStep ? (
        <CanvasImage
          useShadow={!isActiveGatewayForHoveredPath}
          svgURL={isActiveGatewayForHoveredPath ? SelectedStateNoGlowSVG : SelectedStateSVG}
          position={selectedBackgroundPosition}
          width={CanvasConstants.GRID_IMG_SIZE + 44}
          height={CanvasConstants.GRID_IMG_SIZE + 45}
          listening={false}
        />
      ) : null}
      <DecideStepType {...props} />
    </>
  )
}
