import { Box, Typography, styled } from '@mui/material'
import { getIn } from 'formik'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { useContext } from 'react'
import { BuilderSwitch, SwitchVariant } from '../../../../../BuilderSwitch'
import { SubscriptionListStyle } from '@ketch-sdk/ketch-types'
import { BuilderCheckbox, CheckboxVariant } from '../../../../../BuilderCheckbox'
import { clamp } from 'lodash'
import { BUILDER_CONSTANTS } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'

type SampleListItemData = {
  name: string
  description: string
  on: boolean
}

const sampleListData: SampleListItemData[] = [
  {
    name: 'Latest News',
    description: 'Stay up to date with all of the latest news.',
    on: true,
  },
  {
    name: 'Offers',
    description: 'Receive information on flash sales and promotions.',
    on: false,
  },
  {
    name: 'Account Updates',
    description: 'Be alerted if there are any changes to your account.',
    on: true,
  },
  {
    name: 'Events and Happenings',
    description: 'Stay up to date on important events happening in your area.',
    on: true,
  },
]

const sampleCheckboxData = ['Email', 'SMS', 'Mail', 'Phone']

const PurposeItemsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '32px',
})

const PurposeItemBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '12px 16px 16px 16px',
})

const PurposeItemHeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const PurposeItemHeaderLeftBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'center',
})

const CheckboxesBox = styled(Box)({
  display: 'flex',
  gap: '40px',
})

export const SubscriptionsList: React.FC = () => {
  const { themeConfig, experienceConfig } = useContext(BuilderContext)

  // Theme config
  const listItemStyle = getIn(themeConfig, themeUpsertFormKeys.preferenceSubscriptionsTabListStyle)
  const listItemBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.preferenceSubscriptionsTabListBackgroundColor)
  const listItemTextColor = getIn(themeConfig, themeUpsertFormKeys.preferenceSubscriptionsTabListTextColor)
  const listItemCornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferenceSubscriptionsTabListCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'
  const switchButtonsOnBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabListSwitchButtonOnBackgroundColor,
  )
  const switchButtonsOnTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabListSwitchButtonOnTextColor,
  )
  const switchButtonsOffBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabListSwitchButtonOffBackgroundColor,
  )
  const switchButtonsOffTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabListSwitchButtonOffTextColor,
  )
  const checkboxesOnBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabListCheckboxSelectedBackgroundColor,
  )
  const checkboxesOnTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabListCheckboxSelectedTextColor,
  )
  const checkboxesOffBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabListCheckboxUnselectedBackgroundColor,
  )
  const checkboxesOffTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabListCheckboxUnselectedTextColor,
  )
  const isFilledStyle = listItemStyle === SubscriptionListStyle.Filled

  // Experience config
  const showSwitchButtonLabels = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceSubscriptionsTabListSwitchButtonLabelsVisible,
  )
  const switchButtonLabelsOnText = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceSubscriptionsTabListSwitchLabelsOnText,
  )
  const switchButtonLabelsOffText = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceSubscriptionsTabListSwitchLabelsOffText,
  )
  return (
    <PurposeItemsBox>
      {sampleListData.map(({ name, description, on }) => (
        <PurposeItemBox
          sx={{
            background: isFilledStyle ? listItemBackgroundColor : '',
            borderBottom: `1.5px solid ${listItemBackgroundColor}`,
            borderRadius: isFilledStyle ? listItemCornerRadius : '',
          }}
        >
          {/* Header */}
          <PurposeItemHeaderBox>
            <PurposeItemHeaderLeftBox>
              <Typography fontSize="16px" fontWeight={700} color={listItemTextColor} sx={{ wordWrap: 'break-word' }}>
                {name}
              </Typography>
              <Typography fontSize="14px" fontWeight={400} color={listItemTextColor} sx={{ wordWrap: 'break-word' }}>
                {description}
              </Typography>
            </PurposeItemHeaderLeftBox>
            <Box>
              <BuilderSwitch
                variant={on ? SwitchVariant.on : SwitchVariant.off}
                onBackgroundColor={switchButtonsOnBackgroundColor}
                onTextColor={switchButtonsOnTextColor}
                offBackgroundColor={switchButtonsOffBackgroundColor}
                offTextColor={switchButtonsOffTextColor}
                onText={switchButtonLabelsOnText}
                offText={switchButtonLabelsOffText}
                showText={showSwitchButtonLabels}
              />
            </Box>
          </PurposeItemHeaderBox>

          <CheckboxesBox>
            {sampleCheckboxData.map(label => (
              <BuilderCheckbox
                variant={on ? CheckboxVariant.on : CheckboxVariant.disabled}
                onBackgroundColor={checkboxesOnBackgroundColor}
                onTextColor={checkboxesOnTextColor}
                offBackgroundColor={checkboxesOffBackgroundColor}
                offTextColor={checkboxesOffTextColor}
                label={label}
              />
            ))}
          </CheckboxesBox>
        </PurposeItemBox>
      ))}
    </PurposeItemsBox>
  )
}
