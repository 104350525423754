import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { Appliance } from '@ketch-com/windlass/dist/appliance/appliances'

type Params = APIRequestParams<{
  applianceId: string
}>

export const fetchAppliance = ({ applianceId }: Params) =>
  API.get<Appliance>(
    formatRequestString({
      entityUrl: `/api/asset-manager/appliances/${applianceId}`,
    }),
  )
