import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { EmptyState } from '@ketch-com/deck'
import { ProcessingActivitiesContext } from '../context/ProcessingActivitiesContext'

export const NoSearchResultsBox: React.FC = () => {
  const { clearAllSearchParams } = useContext(ProcessingActivitiesContext)

  return (
    <Box
      mt={2.5}
      bgcolor="white.main"
      borderRadius="11px"
      py={4}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <EmptyState
        title="No results found"
        subTitle="Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try again."
        primaryButtonTitle="Reset Filters"
        iconName="OFilter"
        primaryButtonProps={{
          onClick: clearAllSearchParams,
        }}
      />
    </Box>
  )
}
