import { APIListRequestParams } from 'api/common/utils'
import { GetDatabasesParams, DatabaseResources } from '@ketch-com/windlass/dist/schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<GetDatabasesParams>

export const fetchDatabases = ({ searchString, nextToken, limit, type }: Params) => {
  return API.get<DatabaseResources>(
    formatRequestString({
      entityUrl: `/api/asset-manager/databases`,
      params: {
        filters: {
          ...(searchString && {
            searchString,
          }),
          ...(nextToken && {
            nextToken,
          }),
          ...(limit && {
            limit,
          }),
          ...(type && {
            type,
          }),
        },
      },
    }),
  )
}
