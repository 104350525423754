import { useQueries } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { fetchAssetSummaryDynamic } from '../fetchers/fetchAssetSummaryDynamic'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type Params = {
  assetIds: (string | undefined)[]
  enabled?: boolean
}

export const useAssetsSummaryDynamic = ({ assetIds, enabled }: Params) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )

  const queries = assetIds.map((assetId = '') => ({
    queryKey: [ApiQueryKeys.resource, assetId],
    queryFn: () => fetchAssetSummaryDynamic({ assetId, isV2FeatureFlagEnabled }),
    enabled,
  }))

  const results = useQueries(queries as any).map((r: any) => {
    return {
      data: (r?.data?.data?.assetSummary || []) as AssetSummaryDTO[],
      isLoading: r?.isLoading || r?.isFetching || false,
    }
  })

  const assetsSummary = results.flatMap(result => result.data)

  const payload = { assetsSummary, isLoading: results.some(r => r?.isLoading) }

  return payload
}
