import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { AssignEventWorkflowV2BodyDTO, AssignEventWorkflowV2ResponseBodyDTO } from '@ketch-com/figurehead'

type Params = {
  formData: AssignEventWorkflowV2BodyDTO
}

type Response = { eventWorkflow: AssignEventWorkflowV2ResponseBodyDTO }

export const assignEventWorkflow = ({ formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/v2/events/workflows/assign`,
    }),
    formData,
  )
