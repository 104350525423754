import React from 'react'

import { MaybeNull } from 'interfaces'
import { WorkflowExecutionDTO, WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { useUsers } from 'api/users/queries/useUsers'
import { WorkflowExecutionAutomatedStepReassignModal } from './WorkflowExecutionAutomatedStepReassignModal'
import { useReassignWorkflowStep } from 'pages/orchestration/rightsQueue/hooks'

type Props = {
  workflowExecution: MaybeNull<WorkflowExecutionDTO>
  workflowExecutionStep: WorkflowExecutionStepDTO
  onClose: () => void
}

export const WorkflowExecutionAutomatedStepReassignModalContainer: React.FC<Props> = ({
  workflowExecution,
  workflowExecutionStep,
  onClose,
}) => {
  const { workflowExecutionID, stepID } = workflowExecutionStep

  const { data: users, isLoading: isUsersLoading } = useUsers({
    params: {
      active: true,
    },
  })

  const reassignStep = useReassignWorkflowStep(workflowExecutionID, stepID, onClose)

  return (
    <WorkflowExecutionAutomatedStepReassignModal
      isReady={!isUsersLoading}
      users={users}
      workflowExecution={workflowExecution}
      workflowExecutionStep={workflowExecutionStep}
      onClose={onClose}
      onSubmit={reassignStep}
    />
  )
}
