import { Button, PopUp, theme } from '@ketch-com/deck'
import { Box, Typography, styled } from '@mui/material'
import { useContext } from 'react'
import { AppNavigationContext } from '../context/AppNavigationContext'
import { ReactComponent as NotEntitledArt } from 'assets/icons/navigation/art_feature_gated.svg'
import { getDemoFormLink } from 'utils/helpers/getDemoFormLink'
import { useUserMe } from 'api/users/queries/useUserMe'

const TextBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  margin: '0px 24px',
  height: '50px',
})

export const NotEntitledModal: React.FC = () => {
  const { setShowNotEntitledModal, missingEntitlements } = useContext(AppNavigationContext)
  const { data: user } = useUserMe()

  const onCloseClick = () => setShowNotEntitledModal(false)

  const onContactSalesClick = () => {
    setShowNotEntitledModal(false)
    window.open(getDemoFormLink(missingEntitlements, user || undefined), '_blank', 'noreferrer')
  }

  return (
    <PopUp
      variant="modal"
      title="Unlock This Feature!"
      onClose={onCloseClick}
      sx={{ margin: 0 }}
      popUpContentProps={{
        sx: {
          margin: '0px !important',
          paddingBottom: '0px !important',
        },
      }}
      popUpActionChildren={
        <>
          <Button size="large" color="tertiary" onClick={onCloseClick}>
            Close
          </Button>
          <Button
            size="large"
            onClick={onContactSalesClick}
            sx={{ backgroundColor: `${theme.palette.Success.Primary} !important` }}
          >
            Upgrade
          </Button>
        </>
      }
    >
      <TextBox>
        <Typography variant="label" fontSize="16px" letterSpacing="-0.14px">
          This part of the product is not available under your current subscription.
        </Typography>
        <Typography>Please contact our sales team to upgrade and gain access.</Typography>
      </TextBox>
      <NotEntitledArt />
    </PopUp>
  )
}
