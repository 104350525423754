import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetProcessingActivityResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  activityId: string
}>

export const fetchProcessingActivity = ({ activityId, includeMetadata }: Params) => {
  return API.get<GetProcessingActivityResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/processing-activities/${activityId}`,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
  )
}
