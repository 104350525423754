import { WorkflowExecutionUpdateDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  formData: WorkflowExecutionUpdateDTO
}

export const updateWorkflowExecution = ({ organizationCode, formData }: Params) =>
  API.post<{}>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/signal`,
    }),
    formData,
  )
