import { styled } from '@mui/system'
import Box from '@mui/material/Box'

interface StyledBoxProps {
  isDragActive: boolean
  hasFiles: boolean
  error: boolean
}

export const DropzoneRoot = styled(Box, {
  shouldForwardProp: prop => prop !== 'isDragActive' && prop !== 'hasFiles' && prop !== 'error',
})<StyledBoxProps>(({ theme, isDragActive, hasFiles, error }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '2px dashed',
    borderRadius: '8px',
    padding: theme.spacing(2),
    backgroundColor: isDragActive ? theme.palette.bone.main : theme.palette.bone.main,
    borderColor: error ? theme.palette.chileanFire.main : hasFiles ? theme.palette.iron.main : theme.palette.iron.main,
  }
})
