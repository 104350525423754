import React from 'react'

import { MaybeNull } from 'interfaces/common'
import { WorkflowDTO, WorkflowSummaryDTO } from 'interfaces/workflows'
import { Chip, Status, StatusState, StatusVariant } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

type Props = {
  isLatest: boolean
  workflow: WorkflowDTO
  workflowSummary: MaybeNull<WorkflowSummaryDTO>
}

export const WorkflowViewDetails: React.FC<Props> = ({ isLatest, workflow, workflowSummary }) => {
  const { code, version, canonicalRightCode } = workflow
  const isActive = !!workflowSummary?.isActive

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {isActive ? (
        <Status variant={StatusVariant.outlined} icon="Dot" status={StatusState.success} label="Enabled" />
      ) : (
        <Status variant={StatusVariant.outlined} icon="Dot" status={StatusState.draft} label="Disabled" />
      )}

      {canonicalRightCode && (
        <Box textTransform="capitalize">
          <Status label={canonicalRightCode} variant={StatusVariant.filled} status={StatusState.new} />
        </Box>
      )}

      {!isLatest && <Chip size="small" label={`v${version}`} />}
      <Chip
        size="small"
        label={
          <Typography variant="smallLabel">
            Code: <Typography variant="smallBody">{code}</Typography>
          </Typography>
        }
      />
    </Box>
  )
}
