import { createUseQuery, CustomQueryConfig } from '../../common/query'
import { ApiQueryKeys } from '../../common/queryKeys'
import { fetchPrivacyProtocol } from '../fetchers/fetchPrivacyProtocol'
import {
  PrivacyProtocol,
  PrivacyProtocolConfigurationResponseBodyStatus,
} from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'

const fallbackProtocol: PrivacyProtocol = {
  id: '',
  name: '',
  purposes: [],
  supportsJurisdictionSelection: false,
  supportsPurposeMapping: false,
  supportsOtherOptions: false,
  supportsVendorSelection: false,
  supportsGoogleVendorSelection: false,
  config: {
    status: PrivacyProtocolConfigurationResponseBodyStatus.none,
    purposeMapping: {},
    createdAt: 0,
    updatedAt: 0,
    jurisdictions: [],
  },
}

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.privacyProtocol,
  queryFn: fetchPrivacyProtocol,
  select: res => res?.data.privacyProtocol || fallbackProtocol,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const usePrivacyProtocol = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
