import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces/common'
import { CanonicalPurposeDTO } from 'interfaces/purposes/canonicalPurpose'
import { AppDescriptorDTO } from 'interfaces/apps'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { excludeFalsy } from 'utils/helpers/excludeFalsy'
import { Button, ContentGroup, DataGrid, Spinner, ViewLayout } from '@ketch-com/deck'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import Box from '@mui/material/Box'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Typography } from '@mui/material'
import { IssuesMessage } from 'components/issuesMessage/IssuesMessage'
import { getPermitPropagationViewListColumns } from './permitPropagationViewUtils'

type Props = {
  isReady: boolean
  appDescriptor: MaybeNull<AppDescriptorDTO>
  canonicalPurposes: CanonicalPurposeDTO[]
}

export const PermitPropagationView: React.FC<Props> = ({ isReady, appDescriptor, canonicalPurposes }) => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()

  const appCanonicalPurposes = useMemo(() => {
    if (!appDescriptor || !canonicalPurposes.length) {
      return []
    }

    return (appDescriptor?.app?.supportedPurposes || []).map(
      canonicalPurposeCode => canonicalPurposes.find(({ code }) => code === canonicalPurposeCode)!,
    )
  }, [appDescriptor, canonicalPurposes])

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    {
      title: 'Consent',
      link: RoutesManager.orchestration.consent.systems.root.getURL(),
    },
    { title: appDescriptor?.app?.name },
  ]

  if (!isReady || !appDescriptor) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  const { app, appInstances = [] } = appDescriptor

  const isConfigured = appInstances.some(({ purposes }) => Object.values(purposes || {}).some(Boolean))
  const isPermittedToManagePermitPropagation = isPermitted(PERMISSIONS.PERMIT_ORCH_WRITE)
  const dates = appInstances.map(({ updatedAt }) => updatedAt).filter(excludeFalsy)

  const handleNavigateToEdit = () => {
    navigate(RoutesManager.orchestration.consent.systems.edit.root.getURL({ id: app.ID }))
  }

  const banners = (
    <>
      {!isConfigured && (
        <IssuesMessage
          actions={
            isPermittedToManagePermitPropagation
              ? {
                  title: 'Configure',
                  onClick: handleNavigateToEdit,
                }
              : undefined
          }
          severity="warning"
          entityType={ApplicationEntity.PERMIT_PROPAGATION}
        />
      )}
    </>
  )

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      <Box display="flex" flexDirection="column" gap="24px" alignItems="center" flex={1}>
        {banners}
        <ViewLayout
          contentGroupProps={{
            title: app.name,
            showBackButton: true,
            propsBackButton: {
              onClick: () => navigate(RoutesManager.orchestration.consent.systems.root.getURL()),
            },

            actionRightBlockComponent: (
              <Box display="flex" gap={1.5} alignItems="center">
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Typography variant="smallBody" color="darkDuskFaded.main">
                    Last Updated
                  </Typography>
                  <Typography color="darkDusk.main" variant="smallBody">
                    {dates.length ? (
                      <FormattedDateRenderer date={Math.max(...dates)} />
                    ) : (
                      <EmptyValueRenderer variant="smallBody" />
                    )}
                  </Typography>
                </Box>
                {isPermittedToManagePermitPropagation && (
                  <Button size="large" onClick={handleNavigateToEdit}>
                    Manage Purposes
                  </Button>
                )}
              </Box>
            ),
          }}
        >
          {appInstances.map(({ ID, name, code, purposes }) => (
            <Box display="flex" flexDirection="column" gap={3} pb={6} key={ID}>
              <ContentGroup variant="inner-page-header" title={name} subTitle={code} titleVariant="h3" />
              <Box>
                <DataGrid
                  autosizeOnMount
                  autosizeOptions={{
                    expand: true,
                    includeHeaders: true,
                    includeOutliers: false,
                  }}
                  getRowHeight={() => 'auto'}
                  columns={getPermitPropagationViewListColumns({ purposes })}
                  disableChildrenSorting
                  disableColumnMenu
                  disableColumnPinning
                  disableColumnResize
                  disableColumnReorder
                  disableRowSelectionOnClick
                  disableRowHoverStates
                  getRowId={row => row.code}
                  rows={appCanonicalPurposes || []}
                  hideFooter
                  noRowsOverlayProps={{
                    buttonTitle: '',
                    subTitle: '',
                    title: 'No purposes mapped',
                  }}
                />
              </Box>
            </Box>
          ))}
        </ViewLayout>
      </Box>
    </>
  )
}
