import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Button, Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import {
  GetConsentStatusResponseBodyPurposesItemDTO,
  GetRightInvocationsByIdentityResponseBodyRightInvocationsItemDTO,
} from '@ketch-com/figurehead'
import { AuditLogsPermitsRightInvocationStatusCellRenderer } from './AuditLogsPermitsRightInvocationStatusCellRenderer'
import { Typography } from '@mui/material'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

const AuditLogsDataGridActionCell = ({ route }: { route: string }) => {
  const navigate = useNavigate()
  return (
    <TableCell>
      <Button color="tertiary" onClick={() => navigate(route)}>
        View
      </Button>
    </TableCell>
  )
}

export const auditLogsPermitsColumns: GridColDef<GetConsentStatusResponseBodyPurposesItemDTO>[] = [
  {
    align: 'left',
    field: 'status',
    headerAlign: 'left',
    headerName: 'Status',
    sortable: false,
    flex: 0.5,
    renderCell: ({ row }: GridRenderCellParams<GetConsentStatusResponseBodyPurposesItemDTO>) => {
      return (
        <TableCell>
          <Status
            variant={StatusVariant.ghost}
            icon={row.allowed ? 'OCheck' : 'OCross'}
            status={row.allowed ? StatusState.success : StatusState.draft}
            label={row.allowed ? 'Allowed' : 'Not Allowed'}
          />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'rowFilters',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Purpose',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<GetConsentStatusResponseBodyPurposesItemDTO>) => {
      return <TableCell sx={{ padding: '8px 0' }} title={row.name} subTitle={row.code}></TableCell>
    },
  },
  {
    align: 'left',
    field: 'actions',
    headerAlign: 'left',
    headerName: '',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<GetConsentStatusResponseBodyPurposesItemDTO>) => {
      return (
        <AuditLogsDataGridActionCell route={RoutesManager.policyCenter.purposes.view.root.getURL({ code: row.code })} />
      )
    },
  },
]

export const auditLogsInvokedRightsColumns: GridColDef<GetRightInvocationsByIdentityResponseBodyRightInvocationsItemDTO>[] =
  [
    {
      align: 'left',
      field: 'invokedRight',
      headerAlign: 'left',
      headerName: 'Invoked Right',
      sortable: false,
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams<GetRightInvocationsByIdentityResponseBodyRightInvocationsItemDTO>) => {
        return (
          <TableCell sx={{ padding: '8px 0' }}>
            <Typography variant="label">{row.name}</Typography>
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'date',
      flex: 1,
      headerAlign: 'left',
      headerName: 'Date',
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<GetRightInvocationsByIdentityResponseBodyRightInvocationsItemDTO>) => {
        return (
          <TableCell sx={{ padding: '8px 0' }}>
            {!row.date ? (
              <Typography color="darkGrey.main">None</Typography>
            ) : (
              <Typography>{formatDateTimeFromUnix(row.date)}</Typography>
            )}
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'fulfillmentStatus',
      headerAlign: 'left',
      headerName: 'Fulfillment Status',
      flex: 0.7,
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<GetRightInvocationsByIdentityResponseBodyRightInvocationsItemDTO>) => {
        return (
          <TableCell>
            <AuditLogsPermitsRightInvocationStatusCellRenderer rightInvocation={row} />
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'actions',
      headerAlign: 'left',
      headerName: '',
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<GetRightInvocationsByIdentityResponseBodyRightInvocationsItemDTO>) => {
        return (
          <AuditLogsDataGridActionCell
            route={RoutesManager.orchestration.rightsQueue.view.root.getURL({ id: row.id })}
          />
        )
      },
    },
  ]
