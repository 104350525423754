import React from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import { makeStyles, createStyles } from '@mui/styles'
import { useField, FieldValidator } from 'formik'
import { SxProps, Theme } from '@mui/material'
import { isNumber } from 'lodash'

import { ObjectLiteral } from 'interfaces'
import { Text } from 'components/ui-kit/typography/Text'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { RadioGroup, Props as BaseRadioGroupProps } from 'components/ui-kit/radioGroup/RadioGroup'

const useStyles = makeStyles(
  createStyles({
    baseFormSwitch: {
      display: 'inline-block',
    },
    withBorderBottom: {
      display: 'block',
    },
    columnLayout: {
      '& $baseLabel': {
        marginBottom: 14,
      },
    },
    baseLabel: {
      display: 'block',
      marginBottom: 16,
    },
  }),
  { name: 'FormRadioGroup' },
)

type OmittedProps = Omit<BaseRadioGroupProps, 'name' | 'value' | 'options'>

interface Props<T> extends OmittedProps {
  /** Formik field name */
  name: string
  /** Text to be displayed above Component */
  label?: string
  /** Display Asterisk as required field */
  required?: boolean
  /** Custom validation method */
  validate?: FieldValidator
  /** Value key refers to item value */
  valueKey?: string
  /** Array of items to render in menu */
  options: T[]
  /** Label renderer */
  renderLabel?: (item: T) => React.ReactNode
  // set values to number
  isInt?: boolean
  /** container sx prop override */
  containerSx?: SxProps<Theme>
  /** Align flex start */
  alignFlexStart?: boolean
  optionsWithBorderBottom?: boolean
}

/**
 * -
 */
export function FormRadioGroup<T extends ObjectLiteral & { disabled?: boolean } = ObjectLiteral>({
  name,
  label = '',
  validate,
  valueKey = 'value',
  options,
  onChange,
  renderLabel = option => option.title,
  isInt = false,
  alignFlexStart = false,
  containerSx,
  optionsWithBorderBottom = false,
  ...baseRadioGroupProps
}: Props<T>) {
  const classes = useStyles()
  const [field, meta, { setValue }] = useField({ name, validate })
  const showError = meta.error && meta.touched

  const onChangeInner = (e: React.ChangeEvent<HTMLInputElement>, nextValue: string | number) => {
    let val = isNumber(field.value) ? +nextValue : nextValue
    if (isInt) {
      setValue(parseInt(val as string))
    } else {
      setValue(val)
    }
    onChange?.(e, val)
  }

  return (
    <Box
      className={clsx(classes.baseFormSwitch, {
        [classes.columnLayout]: baseRadioGroupProps.layout === 'column',
        [classes.withBorderBottom]: optionsWithBorderBottom,
      })}
      sx={containerSx}
    >
      {label && (
        <Text size={12} color="black" weight={600} className={classes.baseLabel}>
          {label}
        </Text>
      )}

      <RadioGroup
        {...field}
        onChange={onChangeInner}
        options={options.map(option => ({
          value: isInt ? parseInt(option[valueKey]) : option[valueKey],
          title: renderLabel(option),
          disabled: option.disabled,
        }))}
        alignFlexStart={alignFlexStart}
        optionsWithBorderBottom={optionsWithBorderBottom}
        {...baseRadioGroupProps}
      />

      {showError && <Error>{meta.error}</Error>}
    </Box>
  )
}
