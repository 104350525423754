import React from 'react'
import { useParams } from 'react-router-dom'

import { TransponderView } from './TransponderView'
import { useAppliance } from 'api/appliances/queries/useAppliance'

export const TransponderViewContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const { data: appliance, isLoading: isLoadingAppliance } = useAppliance({
    params: {
      applianceId: id!,
    },
  })

  return <TransponderView isReady={!isLoadingAppliance} appliance={appliance} />
}
