import { Box, Skeleton, styled } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { PreviewDisplayMode } from '../../../utils/enums'

const PreviewBackgroundBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
})

interface Props extends PropsWithChildren {
  variant: PreviewDisplayMode
  isLoading: boolean
  hasGreaterBackgroundHeight: boolean
}

// Preview background component that renders behind the preview iFrame
export const PreviewBackground: React.FC<Props> = ({ variant, isLoading, hasGreaterBackgroundHeight, children }) => {
  return (
    <>
      {isLoading && (
        <PreviewBackgroundBox>
          {variant === PreviewDisplayMode.Mobile ? (
            <MobileSkeleton isLoading={isLoading} />
          ) : (
            <DesktopSkeleton isLoading={isLoading} hasGreaterBackgroundHeight={hasGreaterBackgroundHeight} />
          )}
        </PreviewBackgroundBox>
      )}
      {children}
    </>
  )
}

interface SkeletonProps {
  isLoading: boolean
  hasGreaterBackgroundHeight?: boolean
}

// Loading skeleton for mobile preview
const MobileSkeleton: React.FC<SkeletonProps> = ({ isLoading }) => (
  <Box width={'100%'} height={'100%'}>
    <Skeleton
      variant={'rectangular'}
      animation={isLoading ? 'pulse' : false}
      height={573}
      sx={{
        bgcolor: '#0000004C',
      }}
    />
  </Box>
)

// Loading skeleton for desktop preview
const DesktopSkeleton: React.FC<SkeletonProps> = ({ isLoading, hasGreaterBackgroundHeight }) => (
  <Box width={'100%'} height={'100%'}>
    <Skeleton
      variant={'rectangular'}
      animation={isLoading ? 'pulse' : false}
      height={hasGreaterBackgroundHeight ? 769 : 570}
      sx={{
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        bgcolor: '#0000004C',
      }}
    />
  </Box>
)
