import { useContext } from 'react'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { useQueryClient } from 'react-query'
import { useUpdateWorkflowExecutionStep } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStep'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useFormikContext } from 'formik'
import { WorkflowExecutionStepCompleteFormValues } from '../../../interfaces'
import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'

export const useTaskDetailsUtils = () => {
  // provider
  const {
    rightInvocation,
    stepId,
    workflowExecutionId,
    workflowExecution,
    refetchWorkflowStep,
    stepDetails,
    hasAttemptedToResolve,
    workflowStep,
  } = useContext(RightInvocationWorkflowStepDetailViewContext)
  const { errors } = useFormikContext<WorkflowExecutionStepCompleteFormValues>()

  // component utils
  const { assignee, assigneeInfo, manualInterventionData } = stepDetails
  const { resolutionDetails } = manualInterventionData || {}

  const {
    hasDataSubjectVariables,
    hasDsrResolutionAttachments,
    hasIdentitySpaces,
    hasResolutionAttachments,
    hasResolutionNotes,
    hasOutcomeVariables: hasOutcomeVariablesWorkflowStepActivityParams,
    isDsrResolutionAttachmentsRequired,
    isResolutionAttachmentsRequired,
    isResolutionNotesRequired,
  } = workflowStep?.activity?.params || {}

  const hasOutcomeVariables = !!(
    stepDetails?.executionData?.manualTask?.hasOutcomeVariables || hasOutcomeVariablesWorkflowStepActivityParams
  )

  const showAttachmentsRequired = hasResolutionAttachments && isResolutionAttachmentsRequired

  const showDsrAttachmentsRequired = hasDsrResolutionAttachments && isDsrResolutionAttachmentsRequired

  const isWorkflowExecutionTerminated = workflowExecution?.status === WorkflowExecutionStepStatus.TERMINATED

  const queryClient = useQueryClient()
  const { mutateAsync: submitStepDetails } = useUpdateWorkflowExecutionStep({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.workflowExecutionStepDetailsV2, { workflowExecutionId, stepId }])
      await refetchWorkflowStep()
    },
  })

  const onUploadComplete = async (attachments: FileInfoDTO[]) => {
    await submitStepDetails({
      params: {
        formData: {
          manualInterventionData: {
            resolutionDetails: manualInterventionData?.resolutionDetails || '',
            decisionBoolean: manualInterventionData?.decisionBoolean || false,
          },
        },
        workflowExecutionId,
        stepId,
      },
    })
  }

  const stepError =
    (stepDetails as unknown as WorkflowExecutionStepDetailsDTO)?.results?.[stepId as string]?.error || ''

  const shouldShowOutcomesHeader =
    hasOutcomeVariables ||
    hasResolutionNotes ||
    hasResolutionAttachments ||
    hasDsrResolutionAttachments ||
    hasDataSubjectVariables ||
    hasIdentitySpaces

  const payload = {
    assignee,
    assigneeInfo,
    errors,
    hasAttemptedToResolve,
    isDsrResolutionAttachmentsRequired,
    hasDataSubjectVariables,
    hasDsrResolutionAttachments,
    hasIdentitySpaces,
    hasOutcomeVariables,
    hasResolutionAttachments,
    hasResolutionNotes,
    isResolutionAttachmentsRequired,
    isWorkflowExecutionTerminated,
    isResolutionNotesRequired,
    onUploadComplete,
    rightInvocation,
    resolutionDetails,
    showAttachmentsRequired,
    showDsrAttachmentsRequired,
    shouldShowOutcomesHeader,
    stepDetails,
    stepError,
    stepId,
    workflowExecution,
    workflowExecutionId,
    workflowStep,
  }

  logObjectDetails(
    {
      payload,
    },
    'useTaskDetailsUtils.ts',
    'white',
    false, // false ensures will log only in debug mode (i.e. ?debug=log)
  )

  return payload
}
