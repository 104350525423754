import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIRequestParams } from 'api/common/utils'

type Params = APIRequestParams<{
  identitySpaceCode: string
  organizationCode: string
  includeIssues?: boolean
}>

type Response = { identitySpace: IdentitySpaceDTO }

export const fetchIdentitySpace = ({ organizationCode, includeMetadata, includeIssues, identitySpaceCode }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/identity-spaces/${identitySpaceCode}`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
        },
      },
    }),
  )
