import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSetState } from 'react-use'
import { useNavigate } from 'react-router-dom'

import { FormMode } from 'interfaces/formModes/formMode'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'
import { ExperienceDeleteModal } from 'pages/consentAndRights/experiences-v2/modals/ExperienceDeleteModal'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { ExperienceStatus } from 'interfaces/experiences-v2/experienceStatus'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'

type Props = {
  experience: ExperienceV2DTO
}

export const ExperiencesListDropdown: React.FC<Props> = ({ experience }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { isPermitted } = useIsPermitted()
  const isPermittedToManageExperiences = isPermitted(PERMISSIONS.EXP_WRITE)

  const { isEntitled } = useIsEntitled()
  const isEntitledToCreateExperience = isEntitled(ENTITLEMENTS.EXPERIENCES)
  const isEntitledToConsentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)
  const isEntitledToRights = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)

  const isConsent = experience.type === ExperienceType.Consent
  const isDeployed = experience.status === ExperienceStatus.Deployed

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [{ isEditConfirmationOpen, isDeleteOpen }, setState] = useSetState({
    isEditConfirmationOpen: false,
    isDeleteOpen: false,
  })

  const handleRedirectToUpsert = ({ formMode }: { formMode: FormMode }) => {
    navigate(
      RoutesManager.deployment.experiencesV2.upsert.root.getURL({
        id: experience.id,
        formMode,
        experienceType: isConsent ? ExperienceType.Consent : ExperienceType.Preference,
      }),
    )
  }

  let showDuplicate = isEntitledToCreateExperience

  if (showDuplicate) {
    if (isConsent) {
      showDuplicate = isEntitledToConsentAndDisclosure
    } else {
      showDuplicate = isEntitledToConsentAndDisclosure || isEntitledToRights
    }
  }

  return (
    <>
      <Button size="medium" variant="iconSubtle" onClick={handleClickListItem}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
        {isPermittedToManageExperiences && (
          <ActionSheetItem
            onClick={() => {
              if (isDeployed) {
                setState({ isEditConfirmationOpen: true })
              } else {
                handleRedirectToUpsert({ formMode: FormMode.EDIT })
              }
              handleClose()
            }}
          >
            Edit
          </ActionSheetItem>
        )}
        {isPermittedToManageExperiences && showDuplicate && <ActionSheetItem divider />}
        {/*  TODO:JB - Duplicate */}
        {isPermittedToManageExperiences && showDuplicate && (
          <ActionSheetItem
            onClick={() => {
              handleRedirectToUpsert({ formMode: FormMode.DUPLICATE })
            }}
          >
            Duplicate
          </ActionSheetItem>
        )}
        {isPermittedToManageExperiences && !isDeployed && <ActionSheetItem divider />}
        {isPermittedToManageExperiences && !isDeployed && (
          <ActionSheetItem
            onClick={() => {
              setState({ isDeleteOpen: true })
              handleClose()
            }}
          >
            Delete
          </ActionSheetItem>
        )}
      </ActionSheetWrapper>

      {isEditConfirmationOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.EXPERIENCE}
          onSubmit={() => {
            handleRedirectToUpsert({ formMode: FormMode.EDIT })
          }}
          onCancel={() => {
            setState({ isEditConfirmationOpen: false })
          }}
        />
      )}

      {isDeleteOpen && (
        <ExperienceDeleteModal
          experience={experience}
          onDelete={() => {
            queryClient.refetchQueries(ApiQueryKeys.experiencesPaginated)
            setState({ isDeleteOpen: false })
          }}
          onClose={() => {
            setState({ isDeleteOpen: false })
          }}
        />
      )}
    </>
  )
}
