import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react'
import clsx from 'clsx'
import { Box, CircularProgress, Collapse, Typography } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import ClearSharpIcon from '@mui/icons-material/ClearSharp'
import ReactDropzone, { DropzoneProps as ReactDropzoneProps, FileRejection } from 'react-dropzone'
import { AxiosError } from 'axios'

import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { useUploadFile } from 'api/files/mutations/useUploadFile'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { Banner } from 'components/ui-kit/banner/Banner'
import { Text } from 'components/ui-kit/typography/Text'
import { Button, Icon } from '@ketch-com/deck'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import numeral from 'numeral'
import { Chip as DeckChip } from '@ketch-com/deck'
import { handleDelete, handleDownloadOnClick } from './DropZone.utils'
import { useDeleteFile } from 'api/files/mutations/useDeleteFile'
import { useDownloadFile } from 'api/files/mutations/useDownloadFile'

const useStyles = makeStyles(
  ({ typography, palette, spacing }) =>
    createStyles({
      root: {
        position: 'relative',
        width: '100%',
        minHeight: 158,
        padding: 20,
        border: `1.5px dashed ${palette.iron.main}`,
        borderRadius: 11,
        backgroundColor: palette.bone.main,
        cursor: 'pointer',

        '&:focus': {
          outline: 0,
          border: `2px dashed ${palette.sphere.main}`,
        },

        '&$invalid': {
          border: `2px dashed ${palette.chileanFire.main}`,

          '&:focus': {
            border: `2px dashed ${palette.chileanFire.main}`,
          },
        },
      },
      invalid: {},
      disabled: {
        cursor: 'not-allowed',
      },
      content: {
        textAlign: 'center',
      },
      customButton: { margin: '12px 0' },
      browseButton: {
        marginTop: spacing(2),
      },
      collapse: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
      },
      bannerTitle: {
        color: palette.chileanFire.main,
      },
      bannerIcon: {
        fontSize: typography.pxToRem(12),
      },
    }),
  { name: 'DropZone' },
)

type UploadFailedPayload = {
  error: AxiosError
  file: File
}

const isUploadFailedPayload = (value: any): value is UploadFailedPayload => value?.error && value?.file

export type DropzoneProps = Omit<ReactDropzoneProps, 'children' | 'onDrop'> & {
  onUpload: (files: FileInfoDTO[]) => void
  dropMessage?: React.ReactNode
  onUploadingStateChange?: (isUploading: boolean) => void
  valid?: boolean
  value?: FileInfoDTO[]
  uploadContext?: {
    version?: string
    folder?: string
    bucket?: string
    acl?: string
    includeInDataSubjectFolder?: boolean
  }
  showOnlyButton?: boolean
  customButtonText?: string
  accept?: {}
  title?: string
  subtitle?: string
  allowDuplicates?: boolean
  icon?: React.ReactNode
  locked?: boolean
  onClickWhenLocked?: () => void
  /** Callback fired when a file is deleted */
  onDelete?: (file: FileInfoDTO) => void
  /** Should deleting an uploaded file be disabled */
  disableDelete?: boolean
  /** Should the dropzone be hidden when a file is uploaded (value is set) */
  hideDropzoneOnUpload?: boolean
  /** Display format for uploaded files */
  fileInfoDisplayVariant?: 'standard' | 'buttons'
}

export function DropZone({
  onUpload,
  disabled,
  dropMessage,
  onUploadingStateChange,
  valid = true,
  accept = {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
    'application/pdf': ['.pdf'],
    // TODO:GP replace when we have an updated PDFTron license
    // 'application/msword': ['.doc'],
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    // 'application/vnd.ms-excel': ['.xls'],
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'text/csv': ['.csv'],
    'application/json': ['.json'],
  },
  uploadContext,
  value,
  showOnlyButton = false,
  customButtonText = 'Change File',
  title = 'Upload File',
  subtitle = 'You can drop a file here for quick upload or use the button below to locate it in your file manager.',
  allowDuplicates = false,
  icon,
  locked,
  onClickWhenLocked,
  onDelete,
  hideDropzoneOnUpload,
  fileInfoDisplayVariant,
  disableDelete,
  ...rest
}: DropzoneProps) {
  const classes = useStyles()
  const timeout = useRef(0)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadErrors, setUploadErrors] = useState<string[]>([])

  useEffect(() => {
    onUploadingStateChange?.(isUploading)
  }, [isUploading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => () => window.clearTimeout(timeout.current), [])

  // Mutation hooks for uploading, deleting, and downloading
  const { mutateAsync: handleUploadFile } = useUploadFile()
  const { mutateAsync: handleDeleteFile, isLoading: isDeletingFile } = useDeleteFile({
    // onSuccess: async () => await queryClient.refetchQueries(ApiQueryKeys.filesList),
  })
  const { mutateAsync: handleDownloadFile, isLoading: isDownloadingFile } = useDownloadFile()

  const onDropAccepted = async (acceptedFiles: File[]) => {
    const currentFileNames = value?.map(file => file.name) || []
    const acceptedFileNames = acceptedFiles.map(file => file.name)
    const duplicateFileNames = allowDuplicates ? [] : acceptedFileNames.filter(name => currentFileNames.includes(name))
    if (duplicateFileNames.length) {
      showToast({
        content: `You have already uploaded a file with name "${duplicateFileNames?.[0]}"`,
        type: 'error',
      })
      return
    }

    setIsUploading(true)

    const responses = await Promise.all(
      acceptedFiles.map(async file => {
        try {
          const { data } = (await handleUploadFile({
            params: {
              file,
              ...uploadContext,
            },
          }))!

          return { data, file }
        } catch (error) {
          return { error, file } as UploadFailedPayload
        }
      }),
    )

    const uploads: FileInfoDTO[] = []
    const errors: string[] = []

    responses?.forEach?.(response => {
      // Sometimes server returns 200 but uploads nothing
      // `!response.data` - covers this case
      if (isUploadFailedPayload(response) || !response.data) {
        errors.push(response.file.name)
      } else {
        uploads.push(response.data)
      }
    })

    if (!errors.length) {
      showToast({ content: `${responses.length === 1 ? 'File' : 'Files'} uploaded`, type: 'success' })
    } else {
      showToast({ content: `Failed to upload ${responses.length === 1 ? 'file' : 'files'}`, type: 'error' })
    }

    setUploadErrors(errors)

    timeout.current = window.setTimeout(() => {
      setUploadErrors([])
    }, 4000)

    onUpload(uploads)
    setIsUploading(false)
  }

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    let content = rejectedFiles?.[0]?.errors?.[0]?.message
    if (content.indexOf('100000000') !== -1) content = 'Unsupported File Size'
    if (content.indexOf('image/jpeg') !== -1) content = 'Unsupported File Type'
    showToast({
      content,
      type: 'error',
    })
  }

  const isDisabled = disabled || isUploading

  const supportedFileTypes = Object.entries(accept)
    .map(([key, value]) => value.map(x => x.replace('.', '').toUpperCase()))
    .flat()

  const maxSizeInMB = rest.maxSize ? numeral(rest.maxSize / 1_000_000).format('0') : null

  const handleDragEnter = useCallback(
    (e: React.DragEvent<HTMLElement>) => {
      if (locked) {
        e.stopPropagation()
        e.preventDefault()
        if (onClickWhenLocked) onClickWhenLocked()
      }
    },
    [locked, onClickWhenLocked],
  )

  return (
    <ReactDropzone
      {...rest}
      disabled={isDisabled}
      accept={accept}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      multiple={false}
      maxSize={rest?.maxSize || 100000000}
      onDragEnter={handleDragEnter}
      noKeyboard={locked} // Disable keyboard for when locked because there is no way to call our onLocked before the dialog opens
    >
      {({ getRootProps, getInputProps, open, inputRef }) =>
        showOnlyButton ? (
          <Box>
            <input {...getInputProps()} />

            <Button
              pending={isUploading}
              className={classes.customButton}
              color="secondary"
              onClick={e => {
                open()
                e.stopPropagation()
              }}
            >
              {customButtonText}
            </Button>
          </Box>
        ) : (
          <>
            {/* Dropzone */}
            {!(hideDropzoneOnUpload && value?.length) && (
              <Flex
                className={clsx(classes.root, { [classes.disabled]: isDisabled, [classes.invalid]: !valid })}
                justifyContent="center"
                alignItems="center"
                {...getRootProps()}
                onClick={e => {
                  if (locked) {
                    e.stopPropagation()
                    e.preventDefault()
                    if (onClickWhenLocked) onClickWhenLocked()
                  }
                }}
              >
                <input {...getInputProps()} />
                <Flex className={classes.content} flexDirection="column" alignItems="center">
                  {isUploading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {/* Optionally display an icon */}
                      {icon && <Box mb={0.75}>{icon}</Box>}

                      <Text size={16} weight={700} sx={{ color: 'darkDusk.main', marginBottom: 2 }}>
                        {title}
                      </Text>
                      <Text size={12}>{subtitle}</Text>
                      <Text size={12}>
                        Supported file type{supportedFileTypes.length > 1 ? 's' : ''}: {supportedFileTypes.join(', ')}
                        {maxSizeInMB && (
                          <>
                            <br />
                            <Typography variant="smallLabel">Maximum file size: {maxSizeInMB} MB</Typography>
                          </>
                        )}
                      </Text>

                      <Button
                        className={classes.browseButton}
                        disabled={disabled}
                        color="secondary"
                        onClick={e => {
                          if (locked && onClickWhenLocked) {
                            e.stopPropagation()
                            e.preventDefault()
                            if (onClickWhenLocked) onClickWhenLocked()
                            return
                          }
                          e.stopPropagation()
                          if (locked) {
                            e.preventDefault()
                            onClickWhenLocked?.()
                            return
                          }
                          open()
                        }}
                      >
                        Browse...
                      </Button>
                      {dropMessage ? dropMessage : null}
                    </>
                  )}
                </Flex>

                <Collapse className={classes.collapse} in={!!uploadErrors.length}>
                  <Banner
                    variant="warning"
                    onClick={e => {
                      e.stopPropagation()
                    }}
                    actions={[
                      {
                        title: <ClearSharpIcon className={classes.bannerIcon} />,
                        onClick: () => {
                          setUploadErrors([])
                        },
                      },
                    ]}
                  >
                    <Text size={12} weight={700} className={classes.bannerTitle}>
                      Failed to upload {uploadErrors.length === 1 ? 'file' : 'files'}:&nbsp;
                    </Text>
                    {uploadErrors.join(', ')}
                  </Banner>
                </Collapse>
              </Flex>
            )}

            {/* Uploaded Files Display */}
            {fileInfoDisplayVariant === 'buttons' && (
              <Box>
                {value?.map(attachment => (
                  <Fragment key={attachment.ID}>
                    <Box mt={2} mb={1}>
                      <DeckChip
                        onDelete={() =>
                          handleDelete({
                            attachment,
                            value,
                            handleDeleteFile,
                            onDelete,
                          })
                        }
                        disabled={isDeletingFile || isUploading || isDownloadingFile || disableDelete}
                        deleteIcon={<Icon name={'OCross'} />}
                        label={<Typography variant="body">{attachment.name}</Typography>}
                        sx={{ mr: 2, pr: 0.25 }}
                      />
                      <Button
                        onClick={() => handleDownloadOnClick({ attachment, handleDownloadFile })}
                        color="tertiary"
                        sx={{ mr: 1.5 }}
                      >
                        Download
                      </Button>

                      {/*TODO:JB Get change file working*/}
                      {/*<input {...getInputProps()} />*/}
                      {/*<Button*/}
                      {/*  color="secondary"*/}
                      {/*  onClick={e => {*/}
                      {/*    open()*/}
                      {/*    e.stopPropagation()*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  Change File*/}
                      {/*</Button>*/}
                    </Box>
                    {/*<Typography variant="smallBody" color={theme.palette.darkDuskFaded.main}>*/}
                    {/*  Last Updated {formatDateTimeFromUnix(attachment)}*/}
                    {/*</Typography>*/}
                  </Fragment>
                ))}
              </Box>
            )}
          </>
        )
      }
    </ReactDropzone>
  )
}
