import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { PolicyScopeDTO, UpsertPolicyScopeDTO } from 'interfaces/policyScopes/policyScope'

type Params = {
  organizationCode: string
  formData: UpsertPolicyScopeDTO
}

type Response = { policyScope: PolicyScopeDTO }

export const createPolicyScope = ({ organizationCode, formData }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/policy-scopes`,
    }),
    formData,
  )
