import { Capabilities } from './Capabilities'
import { CapabilitiesNames, ShortCapabilitiesNames } from './CapabilitiesNames'

export const CapabilitiesList = [
  {
    name: CapabilitiesNames[Capabilities.PERMIT_PROPAGATION_APP_CAPABILITY],
    code: Capabilities.PERMIT_PROPAGATION_APP_CAPABILITY,
    enabled: false,
  },
  {
    name: CapabilitiesNames[Capabilities.RIGHTS_PROPAGATION_APP_CAPABILITY],
    code: Capabilities.RIGHTS_PROPAGATION_APP_CAPABILITY,
    enabled: false,
  },
  {
    code: Capabilities.TAG_ORCHESTRATION_APP_CAPABILITY,
    name: CapabilitiesNames[Capabilities.TAG_ORCHESTRATION_APP_CAPABILITY],
    enabled: false,
  },
  {
    code: Capabilities.INTROSPECTION_APP_CAPABILITY,
    name: CapabilitiesNames[Capabilities.INTROSPECTION_APP_CAPABILITY],
    enabled: false,
  },
  {
    code: Capabilities.SUBSCRIPTION_APP_CAPABILITY,
    name: CapabilitiesNames[Capabilities.SUBSCRIPTION_APP_CAPABILITY],
    enabled: false,
  },
]

export const ShortNameCapabilitiesList = [
  {
    name: ShortCapabilitiesNames[Capabilities.PERMIT_PROPAGATION_APP_CAPABILITY],
    code: Capabilities.PERMIT_PROPAGATION_APP_CAPABILITY,
    enabled: false,
  },
  {
    name: ShortCapabilitiesNames[Capabilities.RIGHTS_PROPAGATION_APP_CAPABILITY],
    code: Capabilities.RIGHTS_PROPAGATION_APP_CAPABILITY,
    enabled: false,
  },
  {
    code: Capabilities.TAG_ORCHESTRATION_APP_CAPABILITY,
    name: ShortCapabilitiesNames[Capabilities.TAG_ORCHESTRATION_APP_CAPABILITY],
    enabled: false,
  },
  {
    code: Capabilities.INTROSPECTION_APP_CAPABILITY,
    name: ShortCapabilitiesNames[Capabilities.INTROSPECTION_APP_CAPABILITY],
    enabled: false,
  },
  {
    code: Capabilities.SUBSCRIPTION_APP_CAPABILITY,
    name: ShortCapabilitiesNames[Capabilities.SUBSCRIPTION_APP_CAPABILITY],
    enabled: false,
  },
]
