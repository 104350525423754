import React from 'react'

import { MaybeNull } from 'interfaces/common'
import { ViewLayout } from 'components/ui-layouts/viewLayout/ViewLayout'
import { ViewLayoutHeader } from 'components/ui-layouts/viewLayout/ViewLayoutHeader'
import { ViewLayoutContent } from 'components/ui-layouts/viewLayout/ViewLayoutContent'
import { PolicyDetailTabRoutes, PolicyViewActions, PolicyViewHeaderDetails } from './components'
import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { RoutesManager } from 'utils/routing/routesManager'
import { IssuesMessage } from 'components/ui-layouts/issuesMessage/IssuesMessage'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'

type Props = {
  isReady: boolean
  policy: MaybeNull<AssetPolicyDTO>
  purpose: MaybeNull<PurposeDTO>
}

export const PolicyView: React.FC<Props> = ({ isReady, policy }) => {
  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.assetManager.assets.list.getURL() },
    { title: 'Policies', link: RoutesManager.policyCenter.policies.list.getURL() },
    { title: policy?.name || '' },
  ]

  const tabs = [
    {
      id: 'overview',
      title: 'Overview',
      link: RoutesManager.policyCenter.policies.detail.overview.getURL({ id: policy?.id }),
    },
    {
      id: 'logic',
      title: 'Logic',
      link: RoutesManager.policyCenter.policies.detail.logic.getURL({ id: policy?.id }),
    },
    {
      id: 'versions',
      title: 'Versions',
      link: RoutesManager.policyCenter.policies.detail.versions.getURL({ id: policy?.id }),
    },
  ]

  if (!isReady || !policy) {
    return <ViewLayout isReady={false} breadcrumbs={breadcrumbs} />
  }

  // TODO:BAC `hasIssues` should be dynamic for final implementation
  const hasIssues = true

  const issuesMessageActions = [
    {
      title: 'View Workflows',
      // TODO:BAC - implement onClick
      onClick: () => {},
    },
  ]

  return (
    <ViewLayout
      issuesMessage={
        !!hasIssues && (
          <IssuesMessage
            variant="warning"
            entityType={ApplicationEntity.POLICY_ON_HOLD}
            actions={issuesMessageActions}
          />
        )
      }
      isReady={isReady}
      breadcrumbs={breadcrumbs}
    >
      <ViewLayoutHeader
        title={policy?.name || ''}
        backTo={RoutesManager.policyCenter.policies.list.getURL()}
        details={<PolicyViewHeaderDetails policy={policy} />}
        actions={<PolicyViewActions policy={policy} />}
      />
      <ViewLayoutContent tabs={tabs}>
        <PolicyDetailTabRoutes policy={policy} />
      </ViewLayoutContent>
    </ViewLayout>
  )
}
