import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useApiKey } from 'api/apiKeys/queries/useApiKey'
import { useDeleteApiKey } from 'api/apiKeys/mutations/useDeleteApiKey'
import { showToast } from 'components/modals/AlertComponent'
import { Button, PopUp, Spinner } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

type Props = {
  apiKeyId: string
  onSubmit?: () => Promise<void>
  onCancel: () => void
}

export const DeleteApiKeyModal: React.FC<Props> = ({ apiKeyId, onSubmit, onCancel }) => {
  const queryClient = useQueryClient()

  const [isBusy, setIsBusy] = useState(false)

  const { data: apiKey, isLoading: isApiKeyLoading } = useApiKey({
    enabled: !!apiKeyId,
    params: {
      apiKeyId,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch API Key', type: 'error' })
      onCancel()
    },
  })

  const { mutateAsync: handleDeleteApiKey } = useDeleteApiKey({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'API Key deleted', type: 'success' })

      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to delete API Key', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <PopUp
      variant="dialog"
      title="Delete API Key"
      popUpWidth="550px"
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isBusy} onClick={onCancel}>
            Close
          </Button>
          <Button
            color="primary"
            size="large"
            pending={isBusy}
            onClick={() => {
              handleDeleteApiKey({
                params: {
                  apiKeyId,
                },
              })
            }}
          >
            Delete
          </Button>
        </>
      }
      onClose={onCancel}
    >
      {isApiKeyLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : (
        <Typography>
          Are you sure you want to delete <Typography variant="label">{apiKey?.name}</Typography>?
        </Typography>
      )}
    </PopUp>
  )
}
