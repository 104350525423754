import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import { SidebarFormRadioGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormRadioGroup'
import { ItemStyle } from '@ketch-sdk/ketch-types'
import { ItemStyleLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { getIn, useFormikContext } from 'formik'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { handleButtonStyleChange } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'
import {
  BuilderTextInputMode,
  FormTextInput,
} from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'

export const PreferenceThemeRequestsTabSubmittedFooterForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ThemeV3DTO>()

  const isActionButtonFilled =
    getIn(values, themeUpsertFormKeys.preferenceRequestsTabSubmittedFooterActionButtonStyle) === ItemStyle.Filled

  return (
    <>
      {/* TODO:JB - Descoped */}
      {/* <SidebarFormGroup sx={{ pt: 0, gap: '16px' }} hasBorderBottom>
        <FormColorInput
          name={themeUpsertFormKeys.preferenceRequestsTabSubmittedFooterBackgroundColor}
          label={'Footer Background Color'}
          fullWidth
          required
        />
      </SidebarFormGroup> */}
      <SidebarFormGroup title={'Action Button'} sx={{ pt: 0, gap: '16px' }}>
        <SidebarFormRadioGroup
          name={themeUpsertFormKeys.preferenceRequestsTabSubmittedFooterActionButtonStyle}
          title={'Style'}
          options={Object.values(ItemStyle).map(style => ({
            label: ItemStyleLabels[style],
            value: style,
          }))}
          row
          onChange={newStyle =>
            handleButtonStyleChange(
              values,
              setFieldValue,
              newStyle,
              themeUpsertFormKeys.preferenceRequestsTabSubmittedFooterActionButtonTextColor,
            )
          }
        />
        <FormColorInput
          name={
            isActionButtonFilled
              ? themeUpsertFormKeys.preferenceRequestsTabSubmittedFooterActionButtonBackgroundColor
              : themeUpsertFormKeys.preferenceRequestsTabSubmittedFooterActionButtonOutlineColor
          }
          label={`Action Button ${isActionButtonFilled ? 'Fill' : 'Outline'} Color`}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferenceRequestsTabSubmittedFooterActionButtonTextColor}
          label={'Action Button Text Color'}
          fullWidth
          required
        />
        <FormTextInput
          name={themeUpsertFormKeys.preferenceRequestsTabSubmittedFooterActionButtonCornerRadius}
          label="Action Button Corner Radius"
          inputWidth="92px"
          mode={BuilderTextInputMode.CornerRadius}
        />
      </SidebarFormGroup>
    </>
  )
}
