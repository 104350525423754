import React, { Fragment, memo, useContext } from 'react'
import { Group } from 'react-konva'
import { find, isUndefined } from 'lodash'

import { WorkflowActivityCode, WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep, ICoordinates } from 'pages/orchestration/workflows/edit/interfaces'
import { CANVAS_TRANSITION_CONNECTION_TYPES } from 'pages/orchestration/workflows/edit/interfaces/enums'
import { CanvasContext } from 'pages/orchestration/workflows/edit/contexts/CanvasContext'
import { getAbsoluteCoordinates } from 'pages/orchestration/workflows/edit/utils/positioning/absoluteCoordinates'
import { getGatewayTransitionPosition } from '../utils/positioning/gatewayTransitionPosition'
import { TransitionPlaceholder } from 'pages/orchestration/workflows/edit/konvaEntities/transitions/TransitionPlaceholder'
import { TransitionConnection } from 'pages/orchestration/workflows/edit/konvaEntities/transitions/TransitionConnection'
import { WarningIcon } from 'pages/orchestration/workflows/edit/konvaEntities/common/WarningIcon'
import { ConnectionCircle } from 'pages/orchestration/workflows/edit/konvaEntities/common/ConnectionCircle'
import { CanvasStepImage } from 'pages/orchestration/workflows/edit/konvaEntities/common/CanvasStepImage'
import { CanvasNameText } from 'pages/orchestration/workflows/edit/konvaEntities/common/CanvasNameText'
import { CanvasDescriptionText } from 'pages/orchestration/workflows/edit/konvaEntities/common/CanvasDescriptionText'
import { CanvasTransitionText } from './common/CanvasTransitionText'
import { StepByType } from 'pages/orchestration/workflows/edit/konvaLayers/steps/StepByType'
import * as CanvasConstants from 'pages/orchestration/workflows/edit/utils/constants'
import { WorkflowCanvasContext } from '../components/WorkflowCanvas/context/WorkflowCanvasContext'
import { EditWorkflowContext } from '../contexts/EditWorkflowContext'

interface IGatewayProps {
  gridPosition: ICoordinates
  step: ICanvasStep
  steps: Array<ICanvasStep>
  connectedNestId?: string
  connectedNestStartPosition?: number
}

export const Gateway: React.FC<IGatewayProps> = memo(
  ({ gridPosition, step, steps, connectedNestId, connectedNestStartPosition }) => {
    const { name = '', description = '', valid, gateway } = step

    const transitions = gateway?.transitions || []
    const transitionsSorted: Array<{ [key: string]: any }> = []
    const canvasContext = useContext(CanvasContext)
    const { activeStep } = useContext(EditWorkflowContext)
    const { hoveredCopySteps } = useContext(WorkflowCanvasContext)
    const stepAbsolutePosition = getAbsoluteCoordinates(gridPosition)
    const isSplitGateway = step?.gateway?.mode === WorkflowActivityGatewayMode.SPLIT
    const isJoinGateway = step?.gateway?.mode === WorkflowActivityGatewayMode.JOIN

    // End the connected nest at the join tile
    const connectedNestIdForChildren = isJoinGateway ? undefined : connectedNestId
    const connectedNestStartPositionForChildren = isJoinGateway ? undefined : connectedNestStartPosition

    const connectedNestStartStep = steps.find(({ ID }) => ID === connectedNestId)

    let connectedTransitionsCount = 0
    // We need to render blue lines on top of dashed lines (Konva handles this by render order)
    transitions?.forEach?.((item, index) => {
      if (!item.ID || !isUndefined(find(steps, { ID: item.ID })?.placeholder)) {
        transitionsSorted.unshift({ ...item, index })
      } else {
        transitionsSorted.push({ ...item, index })
        connectedTransitionsCount++
      }
    })

    // Active gateways should have a different shadow when the copy path button is hovered
    const isActiveNonJoinStep = activeStep?.ID === step.ID && !isJoinGateway
    const hasCopyHoveredSteps = !!hoveredCopySteps.length
    const isCopyHoveredStep = hasCopyHoveredSteps && hoveredCopySteps.includes(step)
    const isActiveGatewayForHoveredPath = isActiveNonJoinStep && hasCopyHoveredSteps && !isCopyHoveredStep

    return (
      <Group>
        <Group
          x={stepAbsolutePosition.x}
          y={stepAbsolutePosition.y}
          onClick={event => {
            canvasContext.handleChangeActiveStep?.({ step, connectedNestStartStep })

            event.cancelBubble = true
          }}
        >
          <CanvasStepImage
            useShadow={!isActiveGatewayForHoveredPath}
            step={step}
            position={{
              x: CanvasConstants.GRID_IMG_GAP,
              y: CanvasConstants.GRID_IMG_GAP,
            }}
          />

          <CanvasNameText text={name} />

          <CanvasDescriptionText text={description} />

          {!valid && <WarningIcon />}

          <ConnectionCircle />
        </Group>

        {transitionsSorted.map(transition => {
          const { ID, index, name } = transition
          const isTransitionValid = !isSplitGateway || connectedTransitionsCount >= 2
          const transitionStep = find(steps, { ID })
          const transitionGridPosition = getGatewayTransitionPosition({
            gridPosition,
            index,
            step,
            steps,
            transitions,
          })

          if (!transitionStep) {
            const isDecisionBoolean = step?.activityCode === WorkflowActivityCode.DECISION_BOOLEAN
            return (
              <Fragment key={index}>
                {isDecisionBoolean && (
                  <CanvasTransitionText index={index} text={name} gridPosition={transitionGridPosition} />
                )}
                <TransitionConnection
                  type={CANVAS_TRANSITION_CONNECTION_TYPES.DASHED}
                  step={step}
                  gridPositionStart={gridPosition}
                  gridPositionEnd={transitionGridPosition}
                  connectedNestId={connectedNestIdForChildren}
                />

                <TransitionPlaceholder
                  isValid={isTransitionValid}
                  gridPosition={transitionGridPosition}
                  step={step}
                  steps={steps}
                  transitionIndex={index}
                  connectedNestId={connectedNestIdForChildren}
                  connectedNestStartPosition={connectedNestStartPositionForChildren}
                />
              </Fragment>
            )
          }

          return (
            <Fragment key={index}>
              <CanvasTransitionText index={index} text={name} gridPosition={transitionGridPosition} />

              <TransitionConnection
                step={step}
                nextStep={transitionStep}
                gridPositionStart={gridPosition}
                gridPositionEnd={transitionGridPosition}
                connectedNestId={connectedNestIdForChildren}
              />

              <StepByType
                steps={steps}
                step={transitionStep}
                gridPosition={transitionGridPosition}
                connectedNestId={connectedNestIdForChildren}
                connectedNestStartPosition={connectedNestStartPositionForChildren}
              />
            </Fragment>
          )
        })}

        {transitionsSorted.length === 0 ? (
          <>
            <TransitionConnection
              type={CANVAS_TRANSITION_CONNECTION_TYPES.DASHED}
              step={step}
              nextStep={undefined}
              gridPositionStart={gridPosition}
              gridPositionEnd={{
                x: gridPosition.x + 2,
                y: gridPosition.y,
              }}
              connectedNestId={connectedNestId}
            />
            <TransitionPlaceholder
              gridPosition={{
                x: gridPosition.x + 2,
                y: gridPosition.y,
              }}
              step={step}
              steps={steps}
              transitionIndex={0}
            />
          </>
        ) : null}
      </Group>
    )
  },
)
