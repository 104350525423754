import { ActionSheetItem, ActionSheetWrapper, Chip, LabelChipVariant } from '@ketch-com/deck'
import { ConfirmationStatusV3DTO, DataCategoryDTO, DataTypeDTO } from '@ketch-com/figurehead'
import { useState } from 'react'
import { AssetsTabTableLabelChip } from './AssetsTabTableLabelChip'

export enum ClassificationIdString {
  assetDataCategoryId = 'assetDataCategoryId',
  assetDataTypeId = 'assetDataTypeId',
  assetDataSensitivityId = 'assetDataSensitivityId',
}
interface ClassificationExpansionDropdownProps {
  label: string
  items: DataCategoryDTO[] | DataTypeDTO[]
  labelChipVariant?: LabelChipVariant
  idKeyValue: ClassificationIdString
  handleManualClassificationVerification: (
    assetClassificationId: string,
    confirmationStatus: ConfirmationStatusV3DTO,
  ) => void
  viewInsightsProps?: { classificationFilterKey: string }
}

export const CalssificationExpansionDropdown: React.FC<ClassificationExpansionDropdownProps> = ({
  label,
  idKeyValue,
  items,
  labelChipVariant,
  handleManualClassificationVerification,
  viewInsightsProps,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Chip clickable label={label} onClick={handleClickListItem} />
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
        {items.map(item => (
          <ActionSheetItem key={item.id}>
            <AssetsTabTableLabelChip
              PopperProps={{
                disablePortal: false,
              }}
              title={item.name!}
              hideConfidence
              variant={labelChipVariant}
              isDone={item.confirmationStatus === ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3}
              onConfirm={() =>
                handleManualClassificationVerification(
                  (item as any)[idKeyValue],
                  ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
                )
              }
              onDismiss={() =>
                handleManualClassificationVerification(
                  (item as any)[idKeyValue],
                  ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
                )
              }
              viewInsightsProps={
                viewInsightsProps
                  ? {
                      classificationFilterKey: viewInsightsProps.classificationFilterKey,
                      classificationCode: item.code!,
                      classificationName: item.name!,
                      classificationId: item.id!,
                      classificationDescription:
                        // Needs to be added to asset list api
                        // item.description ||
                        'No description available',
                      onClickCallback: handleClose,
                    }
                  : undefined
              }
            />
          </ActionSheetItem>
        ))}
      </ActionSheetWrapper>
    </>
  )
}
