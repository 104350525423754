import React, { useState } from 'react'
import Box from '@mui/material/Box'

import { RightInvocationDTO } from '@ketch-com/figurehead'

import Typography from '@mui/material/Typography'
import { UserAvatar } from 'components/avatar/UserAvatar'
import { joinNonEmpty } from 'utils/helpers/joinNonEmpty'
import { WorkflowExecutionStepStatusRendererV2 } from '..'
import { MANUAL_ACTIVITY_CODE_LIST, WORKFLOW_EXECUTION_STEP_NAMES_V2 } from '../../interfaces'
import { useCurrentActivityCellRendererUtils } from './hooks/useCurrentActivityCellRendererUtils'
import { Avatar, AvatarSize, Status, StatusState, StatusVariant } from '@ketch-com/deck'
import { MultipleStepInfoModal } from './MultipleStepInfoModal/MultipleStepInfoModal'
import { renderIcon } from './utils/renderIcon'
import { ActivityTimerChip } from 'pages/orchestration/rightsQueue/components/ActivityTimerChip'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { Tooltip } from '@mui/material'
import { WorkflowExecutionDTO } from 'interfaces/workflowExecutions/workflowExecution'

type Props = {
  rightInvocation: RightInvocationDTO
}

export const CurrentActivityCellRenderer: React.FC<Props> = ({ rightInvocation }) => {
  const [isMultipleStepModalOpen, setIsMultipleStepModalOpen] = useState(false)
  const {
    currentActivityStatus,
    assignedUser,
    backgroundColor,
    iconURL,
    stepName,
    currentActivityStepCode,
    stepVariant,
    shouldShowStatus,
    dueAt,
    currentActivityWorkflowExecutionSteps,
    currentFailedSteps,
    currentInProgressSteps,
    currentTimers,
    workflowExecution,
    isWorkflowFinalized,
  } = useCurrentActivityCellRendererUtils({ rightInvocation })

  if (!rightInvocation.workflowExecution || isWorkflowFinalized) return <EmptyValueRenderer />

  if (currentActivityWorkflowExecutionSteps.length > 1) {
    return (
      <Tooltip
        title={
          <Typography variant="smallLabel" color="white.main">
            Click here for more details.
          </Typography>
        }
        PopperProps={{
          sx: {
            zIndex: 1200,
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={0.5}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()

            setIsMultipleStepModalOpen(true)
          }}
        >
          <Avatar textColor="white.main" backgroundColor="darkDusk.main" size={AvatarSize.small}>
            {currentActivityWorkflowExecutionSteps.length}
          </Avatar>
          <Box display="flex" alignItems="flex-start" flexDirection="column">
            <Typography>Multiple</Typography>
            <Box display="flex" alignItems="center" gap={0.5} flexWrap="wrap">
              {currentFailedSteps > 0 ? (
                <Status
                  variant={StatusVariant.filled}
                  label={`${currentFailedSteps} Failed`}
                  status={StatusState.error}
                />
              ) : null}
              {currentInProgressSteps > 0 ? (
                <Status
                  variant={StatusVariant.filled}
                  label={`${currentInProgressSteps} In Progress`}
                  status={StatusState.inProgress}
                />
              ) : null}
              {currentTimers.length > 0 ? (
                <Status
                  variant={StatusVariant.filled}
                  label={`${currentTimers.length} Timers`}
                  status={StatusState.draft}
                />
              ) : null}
            </Box>
          </Box>
          {isMultipleStepModalOpen ? (
            <MultipleStepInfoModal
              onClose={e => {
                e?.stopPropagation()
                e?.preventDefault()

                setIsMultipleStepModalOpen(false)
              }}
              steps={currentActivityWorkflowExecutionSteps}
              name={rightInvocation.workflowExecution.workflowDefinitionName}
              rightInvocationId={rightInvocation.id}
              // Need to force it to the hand-rolled type here to access the all-caps ID :(
              workflowExecutionId={(workflowExecution as unknown as WorkflowExecutionDTO)?.ID}
            />
          ) : null}
        </Box>
      </Tooltip>
    )
  }

  if (MANUAL_ACTIVITY_CODE_LIST.includes(currentActivityStepCode || '__dummy_value_that_will_never_match__'))
    return (
      <Box display="flex" alignItems="center">
        {/* Left Col */}

        <Box display="flex" flexDirection="column" mr={0.5}>
          {renderIcon(stepName, backgroundColor, iconURL)}
        </Box>

        {/* Right Col*/}
        <Box display="flex" flexWrap="wrap" alignItems="center" rowGap={0.5}>
          <Box display="flex" flexDirection="column" mr={0.5}>
            {assignedUser ? (
              <>
                {/* Row 1 */}

                <Typography variant="body">{stepName}</Typography>

                {/* Row 2 */}

                <Box display="flex" alignItems="center">
                  <Box
                    sx={{
                      wordBreak: 'initial',
                    }}
                  >
                    <UserAvatar
                      size={AvatarSize.small}
                      user={assignedUser}
                      sx={{
                        mr: 0.5,
                      }}
                    />
                  </Box>
                  <Typography variant="footnote" color="darkDusk.main">
                    {joinNonEmpty([assignedUser?.firstName, assignedUser?.lastName])}
                  </Typography>
                </Box>
              </>
            ) : (
              <Box display="flex" flexDirection="column">
                <Typography variant="body">{stepName}</Typography>

                {shouldShowStatus ? (
                  <WorkflowExecutionStepStatusRendererV2
                    variant={stepVariant}
                    statusText={
                      WORKFLOW_EXECUTION_STEP_NAMES_V2?.[
                        currentActivityStatus as keyof typeof WORKFLOW_EXECUTION_STEP_NAMES_V2
                      ]
                    }
                  />
                ) : null}
              </Box>
            )}
          </Box>

          {dueAt ? <ActivityTimerChip dueAt={dueAt} /> : null}
        </Box>
      </Box>
    )

  return (
    <Box display="flex" alignItems="center">
      {/* Left Col */}

      <Box display="flex" flexDirection="column" mr={0.5}>
        {renderIcon(stepName, backgroundColor, iconURL)}
      </Box>

      {/* Right Col*/}
      <Box display="flex" flexWrap="wrap" alignItems="center" rowGap={0.5}>
        <Box display="flex" flexDirection="column" mr={0.5}>
          {/* Row 1 */}

          <Typography variant="body">{stepName}</Typography>

          {/* Row 2 */}

          <Box display="flex" alignItems="center">
            {assignedUser ? (
              <>
                <Box
                  sx={{
                    wordBreak: 'initial',
                  }}
                >
                  <UserAvatar
                    size={AvatarSize.small}
                    user={assignedUser}
                    sx={{
                      mr: 0.5,
                    }}
                  />
                </Box>
                <Typography variant="footnote" color="darkDusk.main">
                  {joinNonEmpty([assignedUser?.firstName, assignedUser?.lastName])}
                </Typography>
              </>
            ) : shouldShowStatus ? (
              <WorkflowExecutionStepStatusRendererV2
                variant={stepVariant}
                statusText={
                  WORKFLOW_EXECUTION_STEP_NAMES_V2?.[
                    currentActivityStatus as keyof typeof WORKFLOW_EXECUTION_STEP_NAMES_V2
                  ]
                }
              />
            ) : null}
          </Box>
        </Box>

        {dueAt ? <ActivityTimerChip dueAt={dueAt} /> : null}
      </Box>
    </Box>
  )
}
