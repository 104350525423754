import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  code: string
}

export const deletePolicyScope = ({ organizationCode, code }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/policy-scopes/${code}`,
    }),
  )
