import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  organizationCode: string
  workflowCode: string
}>

export const deleteWorkflow = ({ organizationCode, workflowCode }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/workflows/${workflowCode}`,
    }),
  )
