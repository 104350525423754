import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createAuthenticator } from '../fetchers/createAuthenticator'

const useCustomMutation = createUseMutation({
  mutationFn: createAuthenticator,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateAuthenticator = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
