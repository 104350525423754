import React from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'

import { makeStyles, createStyles } from '@mui/styles'

import { Text } from 'components/ui-kit/typography/Text'
import { SxProps, Theme } from '@mui/material'

const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        padding: '16px 18px 48px 18px',
        borderBottom: `1px solid ${theme.palette.iron.main}`,

        '&:last-child': {
          borderBottom: 0,
        },
      },
      hasHeaderBorder: {
        borderBottom: `1px solid ${theme.palette.iron.main}`,
      },
      removeTopPadding: {
        paddingTop: 0,
      },
      removeLeftPadding: {
        paddingLeft: 0,
      },
      removeBottomPadding: {
        paddingBottom: 0,
      },
    }),
  { name: 'FormSection' },
)

type Props = {
  children?: React.ReactNode
  title?: React.ReactNode
  subTitle?: React.ReactNode
  className?: string
  shouldRemoveTopPadding?: boolean
  shouldRemoveLeftPadding?: boolean
  shouldRemoveBottomPadding?: boolean
  isOptional?: boolean
  hasHeaderBorder?: boolean
  hasHorizontalPadding?: boolean
  containerSx?: SxProps<Theme>
}

export const FormSection: React.FC<Props> = ({
  title,
  subTitle,
  className,
  children,
  shouldRemoveTopPadding = false,
  shouldRemoveLeftPadding = false,
  shouldRemoveBottomPadding = false,
  isOptional = false,
  hasHeaderBorder = false,
  containerSx,
}) => {
  const classes = useStyles()

  return (
    <Box
      className={clsx(
        classes.root,
        {
          [classes.removeTopPadding]: shouldRemoveTopPadding,
          [classes.removeLeftPadding]: shouldRemoveLeftPadding,
          [classes.removeBottomPadding]: shouldRemoveBottomPadding,
        },
        className,
      )}
      sx={containerSx}
    >
      {!!(title || subTitle) && (
        <Box
          mb={1.5}
          pb={2.5}
          sx={({ palette }) => ({
            borderBottom: hasHeaderBorder ? `1px solid ${palette.iron.main}` : undefined,
          })}
        >
          <Text component="div" size={20} weight={700}>
            {title}
            {isOptional && (
              <Text sx={{ ml: 1 }} size={20} weight={700} color="lightGrey">
                (Optional)
              </Text>
            )}
          </Text>
          <Text component="div" size={14} color="grey" sx={{ mt: 0.875 }}>
            {subTitle}
          </Text>
        </Box>
      )}
      {children}
    </Box>
  )
}
