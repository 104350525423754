import { APIListRequestParams } from 'api/common/utils'
import { GetConnectionV2ResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  id?: string
  start?: number
  limit?: number
}>

export const fetchConnection = ({ id, start, limit }: Params) => {
  return API.get<GetConnectionV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/connections/${id}`,
      params: {
        filters: {
          start,
          limit,
          includeDetails: true,
        },
      },
    }),
  )
}
