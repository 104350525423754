import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ICanvasStep, IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { getSidebarIconsCollection } from 'pages/orchestration/workflows/edit/utils/api'
import { transformWorkflowFromBE } from 'pages/orchestration/workflows/edit/utils/api'
import { useValidationSchema } from 'pages/orchestration/workflows/edit/utils/validation/workflow'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { RoutesManager } from 'utils/routing/routesManager'
import { formModes } from 'utils/constants/formModes'
import { useWorkflowActivities } from 'api/workflows/queries/useWorkflowActivities'
import { useWorkflow } from 'api/workflows/queries/useWorkflow'
import { useUpdateWorkflow } from 'api/workflows/mutations/useUpdateWorkflow'
import { getFormMode } from 'utils/helpers/form'
import { transformSidebarEntities } from 'pages/orchestration/workflows/edit/utils/api'
import { transformWorkflowToBE } from 'pages/orchestration/workflows/edit/utils/api'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { hasMultipleEnds } from 'pages/orchestration/workflows/edit/components/WorkflowCanvas/utils/hasMultipleEnds'
import { getIsContextVariableReferencedInGatewayButNotSetToRequired } from '../components/sidebarConfigs/forms/activity/TaskForm/utils'
import { FormikHelpers } from 'formik'
import { SubjectVariableWarningMessage } from '../components/sidebarConfigs/forms/activity/TaskForm/utils/getIsContextVariableReferencedInGatewayButNotSetToRequired'
import { ContextVariableCategoryDTO } from '@ketch-com/figurehead'
import { useContextVariablesPaginated } from 'api/contextVariables/queries/useContextVariablesPaginated'
import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { MaybeNull } from 'interfaces'
import { useTeams } from 'api/teams/queries/useTeams'

type FormHandler = (values: IWorkflowConfig, formikHelpers?: FormikHelpers<IWorkflowConfig>) => void | Promise<any>

export const useEditWorkflowUtils = () => {
  const { code } = useParams<{ code: string }>()
  const location = useLocation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [canonicalRightCode, setCanonicalRightCode] = useState('')
  const [
    unRequiredDataSubjectVariablesReferencedInTasksWarning,
    setUnRequiredDataSubjectVariablesReferencedInTasksWarning,
  ] = useState<SubjectVariableWarningMessage[]>([])
  const [activeStep, setActiveStep] = useState<MaybeNull<ICanvasStep>>(null)

  // Preload task teams for better dropdown performance
  useTeams({})

  const mode = getFormMode({ itemId: code!, location })
  const isEditMode = mode === formModes.EDIT
  const isDuplicateMode = mode === formModes.DUPLICATE

  const { data: outcomeVariables, isLoading: isLoadingOutcomeVariables } = useContextVariablesPaginated({
    params: { limit: 2000, category: ContextVariableCategoryDTO.OutcomeContextVariableCategory },
  })

  const { data: dataSubjectVariables, isLoading: isLoadingDataSubjectVariables } = useContextVariablesPaginated({
    params: { limit: 2000, category: ContextVariableCategoryDTO.DataSubjectContextVariableCategory },
  })

  const { data: workflow, isLoading: isWorkflowLoading } = useWorkflow({
    enabled: mode !== formModes.CREATE_NEW,
    params: {
      workflowCode: code!,
    },
  })

  const { data: workflowActivitiesRaw, isLoading: isWorkflowActivitiesRawLoading } = useWorkflowActivities({
    enabled: !isWorkflowLoading,
    params: {
      canonicalRightCode: canonicalRightCode || workflow?.canonicalRightCode || '',
    },
  })

  const sidebarItems = useMemo(
    () => transformSidebarEntities(workflowActivitiesRaw, canonicalRightCode),
    [workflowActivitiesRaw, canonicalRightCode],
  )

  const sidebarItemsWithoutHiddenTiles = useMemo(
    () => ({
      ...sidebarItems,
      gateways: sidebarItems.gateways.filter(({ gateway }) => gateway?.mode !== WorkflowActivityGatewayMode.JOIN),
    }),
    [sidebarItems],
  )

  // Cache the join activity since it only added programatically following a split or a join
  const standardJoinActivity = useMemo(
    () => sidebarItems.gateways.find(({ gateway }) => gateway?.mode === WorkflowActivityGatewayMode.JOIN),
    [sidebarItems],
  ) as ICanvasStep

  const { mutateAsync: handleUpdateWorkflow } = useUpdateWorkflow()

  // NOTE: for workflow entities we use PUT for create and edit
  const onSubmit: FormHandler = async (values, formikHelpers) => {
    const formData = transformWorkflowToBE({
      workflow: values,
      sidebarItems,
    })

    try {
      const { data } = await handleUpdateWorkflow({
        params: {
          workflowCode: values.code,
          formData,
        },
      })

      const upsertedCode = data.workflow.code

      await queryClient.refetchQueries(ApiQueryKeys.entitlements)

      if (isEditMode) {
        await queryClient.refetchQueries([ApiQueryKeys.workflow, { workflowCode: upsertedCode }])
      }

      if (isEditMode) {
        showToast({ content: 'Workflow updated', type: 'success' })
      } else {
        showToast({ content: 'Workflow created', type: 'success' })
      }

      navigate(RoutesManager.orchestration.workflows.view.root.getURL({ code: upsertedCode }))
    } catch (e) {
      if (isEditMode) {
        showToast({ content: 'Failed to update workflow', type: 'error' })
      } else {
        showToast({ content: 'Failed to create workflow', type: 'error' })
      }
    }
  }

  const workflowItemsIcons = useMemo(() => getSidebarIconsCollection(sidebarItems), [sidebarItems])
  const initialValues = useMemo(
    () =>
      transformWorkflowFromBE({
        workflow,
        workflowItemsIcons,
        mode,
      }),
    [workflow, workflowItemsIcons, mode],
  )

  const validationSchema = useValidationSchema({ isEditMode })

  // Initialize state variables for controlling the visibility of two modal dialogs.
  // - showMultipleEndTilesModal controls the visibility of a modal related to multiple end tiles.
  const [showMultipleEndTilesModal, setShowMultipleEndTilesModal] = useState(false)

  // This function is invoked when the multiple end tiles modal is confirmed by the user.
  // It hides the modal and then proceeds with the submission process.
  const handleConfirmMultipleEndTilesModal = (values: IWorkflowConfig) => {
    // Close multiple end tiles modal
    setShowMultipleEndTilesModal(false)

    // Submit the workflow
    onSubmit(values)
  }

  // This function is invoked when the multiple end tiles modal is confirmed by the user.
  const handleConfirmSubmitWithUnRequiredDataSubjectVariablesReferencedInTasks: FormHandler = values => {
    // Close multiple end tiles modal
    setUnRequiredDataSubjectVariablesReferencedInTasksWarning([])
    // Submit the workflow
    onSubmit(values)
  }

  /**
   * Submits the workflow form after performing various checks.
   *
   * @param {object} values - The form values.
   * @param {object} formikHelpers - The Formik form helper functions.
   * @returns {void}
   */
  const submitWithWorkflowConfigChecks: FormHandler = (values, formikHelpers) => {
    const canonicalRightCode = workflow?.canonicalRightCode || values.canonicalRightCode

    // Check if the workflow has multiple end steps
    if (hasMultipleEnds(values.steps, canonicalRightCode)) {
      setShowMultipleEndTilesModal(true)
      return
    }

    const warningMessageArray = getIsContextVariableReferencedInGatewayButNotSetToRequired({
      steps: values.steps,
    })

    // Check if the workflow has a data subject tile that is not set to required in a gateway
    if (warningMessageArray.length > 0) {
      formikHelpers?.setSubmitting(false)
      setUnRequiredDataSubjectVariablesReferencedInTasksWarning(warningMessageArray)
      return
    }

    // Submit the workflow
    onSubmit(values, formikHelpers)
  }

  const isReady =
    !isWorkflowLoading &&
    !isWorkflowActivitiesRawLoading &&
    !isLoadingOutcomeVariables &&
    !isLoadingDataSubjectVariables

  const payload = {
    canonicalRightCode,
    handleConfirmMultipleEndTilesModal,
    handleConfirmSubmitWithUnRequiredDataSubjectVariablesReferencedInTasks,
    initialValues,
    isActivitiesListLoading: isWorkflowActivitiesRawLoading,
    isDuplicateMode,
    isEditMode,
    isReady,
    onSubmit,
    setCanonicalRightCode,
    setShowMultipleEndTilesModal,
    setUnRequiredDataSubjectVariablesReferencedInTasksWarning,
    showMultipleEndTilesModal,
    standardJoinActivity,
    submitWithWorkflowConfigChecks,
    unRequiredDataSubjectVariablesReferencedInTasksWarning,
    validationSchema,
    workflow,
    workflowActivities: sidebarItemsWithoutHiddenTiles,
    workflowItemsIcons,
    dataSubjectVariables,
    outcomeVariables,
    activeStep,
    setActiveStep,
  }

  return payload
}

export type UseEditWorkflowUtilsReturnType = ReturnType<typeof useEditWorkflowUtils>
