import { IWorkflowItems } from 'pages/orchestration/workflows/edit/interfaces'

export const getSidebarIconsCollection = (sidebarItems: IWorkflowItems): { [key: string]: string } => {
  const { gateways, apps, appslvl2, activities, connectors } = sidebarItems
  const iconsByCodeMap: { [key: string]: string } = {}

  gateways?.forEach?.(({ iconCode, iconURL }) => {
    if (iconURL != null) {
      iconsByCodeMap[iconCode!] = iconURL
    }
  })

  activities?.forEach?.(({ iconCode, iconURL }) => {
    if (iconURL != null) {
      iconsByCodeMap[iconCode!] = iconURL
    }
  })

  apps?.forEach?.(({ iconCode, iconURL }) => {
    if (iconURL != null) {
      iconsByCodeMap[iconCode!] = iconURL
    }
  })

  appslvl2?.forEach?.(({ iconCode, iconURL }) => {
    if (iconURL != null) {
      iconsByCodeMap[iconCode!] = iconURL
    }
  })

  connectors?.forEach?.(({ iconCode, iconURL }) => {
    if (iconURL != null) {
      iconsByCodeMap[iconCode!] = iconURL
    }
  })

  return iconsByCodeMap
}
