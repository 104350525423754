import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useDebounce } from 'react-use'
import { useDeleteAuthenticator } from 'api/authenticators/mutations/useDeleteAuthenticator'
import { useAuthenticators } from 'api/authenticators/queries/useAuthenticators'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'

export const useAuthenticatorsListUtils = () => {
  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()
  const queryClient = useQueryClient()
  const [searchString, setSearchString] = useState('')
  const [searchQueryValue, setSearchQueryValue] = useState('')
  const [authenticatorToDeleteId, setAuthenticatorToDeleteId] = useState<string>('')

  const isPermittedToCreateAuthenticators = isPermitted([PERMISSIONS.AUTHENTICATION_WRITE])
  const isEntitledToCreateAuthenticators = isEntitled(ENTITLEMENTS.AUTHENTICATION)

  const {
    data: authenticators,
    isLoading: isLoadingAuthenticators,
    pagination,
  } = useAuthenticators({
    params: {
      query: searchQueryValue,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch authenticators', type: 'error' })
    },
  })

  const { mutateAsync: handleDeleteAuthenticator } = useDeleteAuthenticator({})

  const handleSubmitDeleteAuthenticator = async () => {
    await handleDeleteAuthenticator({
      onError: () => showToast({ content: 'Failed to delete authenticator', type: 'error' }),
      onSuccess: async () => {
        setAuthenticatorToDeleteId('')
        showToast({ content: 'Authenticator deleted', type: 'success' })
        await queryClient.refetchQueries([ApiQueryKeys.authenticatorsPaginated])
      },
      params: {
        authenticatorId: authenticatorToDeleteId,
      },
    })
  }

  useDebounce(
    () => {
      if (searchString) {
        setSearchQueryValue(searchString)
      } else {
        setSearchQueryValue('')
      }
    },
    500,
    [searchString],
  )

  return {
    authenticators,
    authenticatorToDeleteId,
    handleSubmitDeleteAuthenticator,
    isEntitledToCreateAuthenticators,
    isLoadingAuthenticators,
    isPermittedToCreateAuthenticators,
    pagination,
    searchString,
    setAuthenticatorToDeleteId,
    setSearchString,
    searchQueryValue,
  }
}
