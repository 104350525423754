import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'

import { useAuth } from 'utils/hooks/useAuth'
import { fetchPurposes } from '../fetchers/fetchPurposes'
import { PurposeDTO } from 'interfaces/purposes/purpose'

type LastPage = { purposes: PurposeDTO[]; totalResults: number; cursor?: string }

const limit = 10

export const usePurposesInfinite = ({ onSettled }: { onSettled?: any }) => {
  const { userData } = useAuth()

  const resp = useInfiniteQuery({
    queryKey: [
      ApiQueryKeys.purposesInfinite,
      {
        includeIssues: true,
        includeMetadata: true,
        organizationCode: userData?.organizationCode || '',
      },
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchPurposesResp = await fetchPurposes({
        includeIssues: true,
        includeMetadata: true,
        limit,
        organizationCode: userData?.organizationCode || '',
        start: (pageParam - 1) * limit,
      })

      const { data } = fetchPurposesResp

      data.purposes = data.purposes ?? []
      data.totalResults = data.totalResults ?? 0

      return data
    },
    getNextPageParam: ({ totalResults }: LastPage, allPages: LastPage[]) => {
      let totalPurposesFetchedSoFar = 0
      allPages?.forEach?.(p => {
        totalPurposesFetchedSoFar += p.purposes.length
      })
      const nextPageParam = Math.ceil(totalPurposesFetchedSoFar / limit) + 1
      const getNextPageParamReturnValue = totalPurposesFetchedSoFar === totalResults ? undefined : nextPageParam
      return getNextPageParamReturnValue
    },
    onSettled,
    staleTime: 1000 * 60 * 5, // five minutes
  })

  let infinitePurposes: PurposeDTO[] = []

  resp.data?.pages?.forEach?.(page => {
    infinitePurposes = [...infinitePurposes, ...page.purposes]
  })

  return {
    ...resp,
    data: infinitePurposes,
  }
}
