import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { Typography } from '@mui/material'
import { Button, PopUp } from '@ketch-com/deck'
import { SubscriptionTopicDTO } from '@ketch-com/figurehead'
import { useDeleteSubscriptionTopic } from 'api/subscriptions/mutations/useDeleteSubscriptionTopic'
import { showToast } from 'components/modals/AlertComponent'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  subscriptionTopic?: SubscriptionTopicDTO
  onSubmit: () => void
  onCancel: () => void
}

export const DeleteSubscriptionTopicModal: React.FC<Props> = ({ subscriptionTopic, onSubmit, onCancel }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { mutate: deleteSubscriptionTopic } = useDeleteSubscriptionTopic({
    onSuccess: () => {
      showToast({ content: 'Deleted Subscription', type: 'success' })
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.subscriptionTopicsPaginated] })
      navigate(RoutesManager.policyCenter.subscriptions.list.subscriptionTopics.root.getURL())
    },
    onError: () => {
      showToast({ content: 'Unable to delete subscription', type: 'success' })
    },
  })
  return (
    <>
      <PopUp
        isOpen={true}
        onClose={onCancel}
        popUpActionChildren={
          <>
            <Button color="secondary" size="large" onClick={onCancel}>
              Close
            </Button>
            <Button
              color="primary"
              size="large"
              onClick={() => {
                onSubmit()
                if (subscriptionTopic) {
                  deleteSubscriptionTopic({ params: { code: subscriptionTopic?.code || '' } })
                }
              }}
            >
              Delete
            </Button>
          </>
        }
        variant="dialog"
        title="Delete Subscription"
        popUpWidth="550px"
      >
        <Typography fontSize={14}>
          Are you sure you want to delete <strong>{subscriptionTopic?.name}</strong> subscription?
        </Typography>
        <Typography fontSize={14}>
          If this subscription is part of any experience, it will be removed from those experiences.
        </Typography>
      </PopUp>
    </>
  )
}
