import React, { useState } from 'react'
import { Button, Icon, ActionSheetWrapper, ActionSheetItem } from '@ketch-com/deck'
import { ConfirmDeleteMessageModal } from './ConfirmDeleteMessageModal'
import { showToast } from 'components/modals/AlertComponent'
import { Message as TwilioMessage } from '@twilio/conversations'

type Props = {
  message: TwilioMessage
}

export const MessageDropdownMenu: React.FC<Props> = ({ message }) => {
  const [isConfirmDeleteMessageModalOpen, setIsConfirmDeleteMessageModalOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const handleRemoveMessage = async () => {
    setIsDeleting(true)
    try {
      await message.remove()
      showToast({ content: 'Message removed', type: 'success' })
    } catch (error) {
      showToast({ content: 'Failed to remove message', type: 'error' })
    } finally {
      setIsDeleting(false)
    }
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button size="medium" variant="iconSubtle" onClick={handleClickListItem}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
        <ActionSheetItem
          onClick={() => {
            setIsConfirmDeleteMessageModalOpen(true)
            handleClose()
          }}
        >
          Delete
        </ActionSheetItem>
      </ActionSheetWrapper>
      {isConfirmDeleteMessageModalOpen && (
        <ConfirmDeleteMessageModal
          isDeleting={isDeleting}
          onSubmit={handleRemoveMessage}
          onCancel={() => {
            setIsConfirmDeleteMessageModalOpen(false)
            handleClose()
          }}
        />
      )}
    </>
  )
}
