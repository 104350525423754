import { AppNavigationContext } from 'components/appLayout/appNavigation/context/AppNavigationContext'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsEntitled, useIsPermitted } from 'utils/hooks'
import { RoutesManager } from '../routesManager'
import { useIsKetchFree } from 'utils/hooks/useIsKetchFree'

export enum GatedNavigateStatus {
  Success = 'success',
  NotPermitted = 'notPermitted',
  NotEntitled = 'notEntitled',
}

export interface GatedNavigateResult {
  status: GatedNavigateStatus
  missingEntitlements?: ENTITLEMENTS[]
}

export const useNavigateGated = () => {
  // Base navigate function
  const navigate = useNavigate()
  const { isKetchFree } = useIsKetchFree()
  const { isPermitted } = useIsPermitted()
  const { isEntitledWithDetails } = useIsEntitled()
  const { setShowNotEntitledModal, setShowNotPermittedModal, setMissingEntitlements } = useContext(AppNavigationContext)

  // Permission and entitlement gated navigate function
  const navigateGated = useCallback(
    (
      path: string,
      permissions: PERMISSIONS | PERMISSIONS[] = [],
      entitlements: ENTITLEMENTS | ENTITLEMENTS[] = [],
      requireAllPermissions: boolean = false,
      requireAllEntitlements: boolean = false,
    ): GatedNavigateResult => {
      // Determine if permitted and entitled
      const permitted = isPermitted(permissions, requireAllPermissions)
      const { entitled, missingEntitlements } = isEntitledWithDetails(entitlements, requireAllEntitlements)

      // NOTE - Temporary fix until the new ketch free plan is released and customers are migrated
      if (
        isKetchFree &&
        !permitted &&
        (path === RoutesManager.deployment.consentHome.root.getURL() ||
          path === RoutesManager.systems.systemsHome.root.getURL())
      ) {
        setShowNotEntitledModal(true)
        setMissingEntitlements([ENTITLEMENTS.CONSENT_MANAGEMENT] || [])
        return { status: GatedNavigateStatus.NotEntitled, missingEntitlements: missingEntitlements }
      }

      if (!entitled) {
        // If not entitled or permitted, return corresponding result and do not navigate to path
        setShowNotEntitledModal(true)
        setMissingEntitlements(missingEntitlements || [])
        return { status: GatedNavigateStatus.NotEntitled, missingEntitlements: missingEntitlements }
      }
      if (!permitted) {
        setShowNotPermittedModal(true)
        return { status: GatedNavigateStatus.NotPermitted }
      }

      // If permitted and entitled, navigate to path and return success
      navigate(path)
      return { status: GatedNavigateStatus.Success }
    },
    [
      navigate,
      isKetchFree,
      isPermitted,
      isEntitledWithDetails,
      setShowNotEntitledModal,
      setShowNotPermittedModal,
      setMissingEntitlements,
    ],
  )

  return navigateGated
}
