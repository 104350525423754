import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useDebounce } from 'react-use'

import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { getPoliciesListFilterState } from 'store/policiesListFilterSlice/selectors'
import { Group } from 'components/ui-layouts/group/Group'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { setSearchQuery, clearSearchQuery, setTypeFilter, Type } from 'store/policiesListFilterSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'

const PREFIX = 'RightsQueueFilters'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledGroup = styled(Group)({
  [`&.${classes.root}`]: {
    marginTop: 24,
  },
})

const segmentsTabOptions = [
  {
    id: 'all',
    name: 'All',
  },
  {
    id: 'access',
    name: 'Access',
  },
  {
    id: 'rights',
    name: 'Rights',
  },
  {
    id: 'retention',
    name: 'Retention',
  },
]

type Props = {
  policies: AssetPolicyDTO[]
}

export const PoliciesListFilters: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const { type, searchQuery } = useAppSelector(getPoliciesListFilterState)
  const [searchString, setSearchString] = useState(() => searchQuery || '')

  useDebounce(
    () => {
      if (searchString) {
        dispatch(setSearchQuery(searchString))
      } else {
        dispatch(clearSearchQuery())
      }
    },
    500,
    [searchString],
  )

  return (
    <StyledGroup className={classes.root}>
      <SearchInput
        size="small"
        onChange={nextSearchString => setSearchString(nextSearchString)}
        onClear={() => setSearchString('')}
        value={searchString}
      />

      <SegmentTabs tabs={segmentsTabOptions} value={type} onChange={value => dispatch(setTypeFilter(value as Type))} />
    </StyledGroup>
  )
}
