import { WorkflowActivityConnectorCode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep } from '../../../interfaces'
import { WORKFLOW_TYPE_ENUM } from '../../sidebarConfigs/forms/activity/DsrTransponderForm/constants'

/**
 * This function checks if a workflow has multiple end steps.
 * It only performs the check for access flows, identified by canonicalRightCode being equal to WORKFLOW_TYPE_ENUM.GET.
 *
 * @param {ICanvasStep[]} steps - An array of steps that constitute the workflow.
 * @param {string} [canonicalRightCode] - A string representing the canonical code of the workflow's right side.
 *                                        It is optional because not all workflows have this property.
 *
 * @returns {boolean} - Returns true if the workflow has multiple end steps, false otherwise.
 */
export const hasMultipleEnds = (steps: ICanvasStep[], canonicalRightCode?: string) => {
  // Check if the workflow is an access flow
  const isAccessFlow = canonicalRightCode === WORKFLOW_TYPE_ENUM.GET
  // If the workflow is not an access flow, return false
  if (!isAccessFlow) return false

  // Filter the steps to get only the end steps
  const endSteps = steps.filter(step => step.activityCode === WorkflowActivityConnectorCode.END)

  // If the number of end steps is greater than 1, return true, else return false
  return endSteps.length > 1
}
