import React, { createContext } from 'react'
import { useBuilderUtils, UseBuilderUtilsReturnType } from './utils/useBuilderUtils'
import { BuilderMode, BuilderTarget, BuilderType, ExperienceBuilderSection, ThemeBuilderSection } from './utils/enums'
import { BuilderView } from './BuilderView'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { PreferenceCenterPage } from '@ketch-sdk/ketch-types'

/**
 * NOTE: This wrapper serves to inject props into the BuilderContext. This provides
 * the usual context benefits (e.g. avoiding prop drilling), while allowing the
 * builder within different contexts. E.g. Experience and Theme edit screens.
 */

export type BuilderProps = {
  /** Experience or theme builder */
  builderTarget: BuilderTarget

  /** Experience or theme builder */
  builderType: BuilderType

  /** View or edit mode */
  builderMode: BuilderMode

  /** Page within the preference center*/
  preferencePage?: PreferenceCenterPage

  /** Theme config object */
  themeConfig: ThemeV3DTO

  /** Experience config object */
  experienceConfig: ExperienceV2DTO

  /** The active builder section */
  activeSection?: ExperienceBuilderSection | ThemeBuilderSection

  /** Function to call when setting active section (edit mode only) */
  setActiveSection?: (section: ExperienceBuilderSection | ThemeBuilderSection) => void
}

type ContextProps = BuilderProps & { children: React.ReactNode }

export const BuilderContext = createContext({} as UseBuilderUtilsReturnType)

const BuilderContextProvider: React.FC<ContextProps> = ({ children, ...props }) => {
  const hookValues = useBuilderUtils(props)

  return <BuilderContext.Provider value={{ ...hookValues }}>{children}</BuilderContext.Provider>
}

export const Builder: React.FC<BuilderProps> = props => {
  return (
    <BuilderContextProvider {...props}>
      <BuilderView />
    </BuilderContextProvider>
  )
}
