import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { useDebounce } from 'react-use'

import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { Badge } from 'components/ui-kit/badge/Badge'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { DatabaseCheckboxCellRenderer } from './DatabaseCheckboxCellRenderer'
import { setPolicyStatements, setSelectedDatabases } from 'store/dsrTransponderFormSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getPolicyStatements, getSelectedDatabases } from 'store/dsrTransponderFormSlice/selectors'
import { CheckAllDatabasesCellRenderer } from '.'
import { useAssetsPaginatedV2 } from 'api/assets/queries/useAssetsPaginatedV2'
import { AssetDTO, LabelDTO, ResourceDTO } from '@ketch-com/figurehead'
import { ASSET_TYPE_ENUM } from 'interfaces/assets'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { Button, PopUp } from '@ketch-com/deck'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'

interface TransformedAsset {
  code: string
  name: string
  assignedLabels: LabelDTO[]
  inferredLabels: LabelDTO[]
  technology: string
  dataType: string
  connectionName?: string
}

interface Props {
  setIsAddDatabasesModalOpen: (isOpen: boolean) => void
}

export const AddDatabaseModal: React.FC<Props> = ({ setIsAddDatabasesModalOpen }) => {
  const dispatch = useAppDispatch()
  const selectedDatabasesRedux = useAppSelector(getSelectedDatabases)
  const policyStatements = useAppSelector(getPolicyStatements)

  const [searchString, setSearchString] = useState('')
  const [queryString, setQueryString] = useState('')

  const [selectedDatabases, setSelected] = useState<string[]>(() => selectedDatabasesRedux || [])

  /* debounce search query input */
  useDebounce(
    () => {
      if (searchString) {
        setQueryString(searchString)
      } else {
        setQueryString('')
      }
    },
    500,
    [searchString],
  )

  const handleToggleSelectDatabase = (code: string) => {
    if (selectedDatabases.includes(code)) {
      setSelected(selectedDatabases.filter(s => s !== code))
    } else {
      setSelected([...selectedDatabases, code])
    }
  }

  const {
    data: assets,
    pagination,
    isLoading,
    isFetching,
  } = useAssetsPaginatedV2({
    itemsPerPage: 10,
    params: {
      canonicalResourceType: ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATABASE,
      query: queryString,
    },
  })

  const transformedAssets = (assets || [])?.map(({ asset }) => {
    const { resource, connection, assignedLabels, inferredLabels } = asset as AssetDTO
    const { id, name } = resource as ResourceDTO

    return {
      code: id || '',
      name: name || '',
      assignedLabels: assignedLabels || [],
      inferredLabels: inferredLabels || [],
      technology: connection?.technology || '',
      dataType: asset?.dataCategory?.[0]?.value || '',
      connectionName: connection?.name || '',
    } as TransformedAsset
  })

  return (
    <PopUp
      title="Add Databases"
      subTitle="Choose databases from the list below."
      variant="modal"
      onClose={() => {
        setIsAddDatabasesModalOpen(false)
      }}
      popUpWidth="900px"
      popUpActionChildren={
        <>
          <Button
            color="secondary"
            size="large"
            onClick={() => {
              setIsAddDatabasesModalOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={() => {
              const copyPolicyStatements = { ...policyStatements }
              for (const key of Object.keys(copyPolicyStatements)) {
                if (!selectedDatabases.includes(key)) {
                  delete copyPolicyStatements[key]
                }
              }
              dispatch(setSelectedDatabases(selectedDatabases))
              dispatch(setPolicyStatements(copyPolicyStatements))
              setIsAddDatabasesModalOpen(false)
            }}
          >
            Add
          </Button>
        </>
      }
    >
      <Box flexDirection="column" flex={1}>
        <Box display="flex" alignItems="center" pb={3.5}>
          <SearchTextInput
            onChange={nextSearchString => setSearchString(nextSearchString)}
            value={searchString}
            showClearButton
            placeholder="Search for database"
          />
          {isFetching && !!searchString ? (
            <Box display="flex" alignItems="center" ml={1}>
              <Spinner size={14} />
            </Box>
          ) : null}
        </Box>

        <TablePaginated
          hasCheckAll
          isSmallHeaderText
          items={transformedAssets}
          pagination={pagination}
          pending={isLoading}
          rowIdKey="code"
          variant="page"
          cellSettings={{
            checkboxColumn: {
              width: 40,
              label: '',
              labelNodeRenderer: items => (
                <CheckAllDatabasesCellRenderer
                  items={items}
                  selectedDatabases={selectedDatabases}
                  setSelected={setSelected}
                  isLoading={isLoading}
                />
              ),
              cellRenderer: ({ code }) => (
                <DatabaseCheckboxCellRenderer
                  isChecked={!!selectedDatabases.includes(code || '')}
                  handleToggleSelectDatabase={handleToggleSelectDatabase}
                  code={code || ''}
                />
              ),
            },
            name: {
              width: 350,
              label: 'Name',
              cellRenderer: ({ name, connectionName }) => <TextCellRenderer weight={600} value={name} />,
            },
            transponderName: {
              width: 175,
              label: 'Connection',
              cellRenderer: ({ connectionName }) => <TextCellRenderer weight={600} value={connectionName} />,
            },
            technologies: {
              width: 115,
              label: 'Technologies',
              cellRenderer: ({ technology }) => <TextCellRenderer weight={600} value={technology || 'fooBar'} />,
            },
            dataType: {
              width: 100,
              label: 'Data Type',
              cellRenderer: asset =>
                asset?.dataType ? <Badge variant="neutral">{asset?.dataType}</Badge> : <EmptyValueRenderer />,
            },
            labels: {
              width: 60,
              label: 'Classifications',
              cellRenderer: asset => {
                const assignedLabels = asset?.assignedLabels || []
                const inferredLabels = asset?.inferredLabels || []
                const collectedLabels = [...assignedLabels, ...inferredLabels]
                return <Badge>{collectedLabels?.length || '0'}</Badge>
              },
            },
          }}
        />
      </Box>
    </PopUp>
  )
}
