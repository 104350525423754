import React, { useCallback, useMemo } from 'react'

import { Button, DataGrid } from '@ketch-com/deck'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { getGridColumns } from './utils/createRightInvocationDataGridUtils'
import { useFormikContext } from 'formik'
import { RightInvocationFormValues } from 'interfaces/rightInvocations'
import { Box } from '@mui/material'
import { getRightInvocationEmptyIdentityValue } from './utils'

const InternalDataGridComponent = ({ identitySpaces }: { identitySpaces: IdentitySpaceDTO[] }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<RightInvocationFormValues>()
  const { identities } = values

  const handleRemove = useCallback(
    (index: number) => {
      setFieldTouched('identities')
      const newValues = [...identities.slice(0, index), ...identities.slice(index + 1)]
      setFieldValue('identities', newValues)
    },
    [setFieldValue, setFieldTouched, identities],
  )

  const handlePush = useCallback(() => {
    setFieldTouched('identities')
    const newValues = [...identities, getRightInvocationEmptyIdentityValue()]
    setFieldValue('identities', newValues)
  }, [identities, setFieldValue, setFieldTouched])

  const columns = useMemo(
    () => getGridColumns({ identitySpaces, remove: handleRemove, identitiesLength: identities.length }),
    [identitySpaces, handleRemove, identities.length],
  )
  const rows = useMemo(
    () =>
      identities.map((identity, id) => ({
        ...identity,
        rowIndex: id,
        ID: id,
      })),
    [identities],
  )

  return (
    <>
      <DataGrid
        autosizeOnMount
        columns={columns}
        rows={rows}
        disableChildrenSorting
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        disableRowHoverStates
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        getRowId={row => row.ID}
        hideFooter
        hideFooterRowCount
      />
      <Box
        sx={{
          paddingTop: 2.25,
          borderTop: `1px solid`,
          borderColor: 'iron.main',
        }}
      >
        <Button color="secondary" onClick={handlePush}>
          Add Identity
        </Button>
      </Box>
    </>
  )
}
export const CreateRightInvocationDataGrid = React.memo(InternalDataGridComponent)
