import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { ScanConfigurationBody, Tracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type Params = APIRequestParams<{
  data: ScanConfigurationBody
}>

export const upsertTrackerConfiguration = ({ data }: Params) => {
  return API.put<Tracker>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/tracker/configuration`,
    }),
    data,
  )
}
