import React, { createContext } from 'react'
import {
  DataMapProcessingActivityUtils,
  useDataMapProcessingActivityUtils,
} from '../utils/useDataMapProcessingActivityUtils'

export const DataMapProcessingActivityContext = createContext({} as DataMapProcessingActivityUtils)

type Props = {
  children?: React.ReactNode | undefined
}

export const DataMapProcessingActivityContextProvider: React.FC<Props> = ({ children }) => {
  // Use utils hook
  const hookValues = useDataMapProcessingActivityUtils()

  return (
    <DataMapProcessingActivityContext.Provider value={{ ...hookValues }}>
      {children}
    </DataMapProcessingActivityContext.Provider>
  )
}

export const withDataMapProcessingActivityContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <DataMapProcessingActivityContextProvider>
        <Component {...props} />
      </DataMapProcessingActivityContextProvider>
    )
  }
}
