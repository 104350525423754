import { useEffect, useState } from 'react'
import { CreatePatchDSRConfigsRequestBodyDTO } from '@ketch-com/figurehead'
import { useNavigate, useParams } from 'react-router-dom'
import { useAssetsHierarchiesDynamic } from 'api/assets/queries/useAssetsHierarchiesDynamic'
import { DSRConfigCreate } from 'pages/assetManager/dsrConfig/upsert/create/DSRConfigCreate'
import { DSRConfigEdit } from 'pages/assetManager/dsrConfig/upsert/edit/DSRConfigEdit'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { useRelatedDataSets } from 'api/assets/queries/useRelatedDataSets'
import { FormMode } from 'interfaces/formModes/formMode'
import { RelatedPathsAndAssets } from 'pages/assetManager/dsrConfig/interfaces'
import { useScenario } from 'api/assets/queries/useScenario'
import { useAssetsDsrConfigDynamic } from 'api/assets/queries/useAssetsDsrConfigDynamic'
import { getRelatedPathsAndAssets } from 'pages/assetManager/dsrConfig/utils'
import { MaybeNull } from 'interfaces'
import { useAssetsSummaryDynamic } from 'api/assets/queries/useAssetsSummaryDynamic'
import { RoutesManager } from 'utils/routing/routesManager'
import { LookUpTab } from 'pages/assetManager/scenario/details/constants'
import { useUpsertDsrConfiguration } from 'api/assets/mutations/useUpsertDsrConfiguration'
import { getURLParams } from 'utils/routing/routes/utils'
import { useDsrConfigInitiator } from 'pages/assetManager/hooks'

export const DSRConfigUpsertContainer = () => {
  const { initiatorIsDatabase } = useDsrConfigInitiator()
  const [relatedPathsAndAssets, setRelatedPathsAndAssets] = useState<MaybeNull<RelatedPathsAndAssets>>(null)
  const { id: primaryDatasetId, scenarioId, formMode, assetType } = useParams<getURLParams & { formMode: FormMode }>()
  const navigate = useNavigate()
  const isEditMode = formMode === FormMode.EDIT

  const { data: relatedDataSets, isLoading: isRelatedDataSetsLoading } = useRelatedDataSets({
    params: {
      assetId: primaryDatasetId,
      isPersonal: true,
      lookupType: 0,
      depth: 5,
    },
  })

  const { data: identitySpaces, isLoading: isIdentitySpacesLoading } = useIdentitySpaces()

  const { data: scenarioData, isLoading: isScenarioLoading } = useScenario({
    params: {
      scenarioId,
    },
  })

  const { assetsDsrConfig, isLoading: isAssetsDsrConfigLoading } = useAssetsDsrConfigDynamic({
    assetIds: [[primaryDatasetId], Object.keys(relatedPathsAndAssets || {})].flat(),
    scenarioId: scenarioId!,
    enabled: Boolean(relatedPathsAndAssets),
  })

  const { assetsHierarchies, isLoading: isAssetsHierarchiesLoading } = useAssetsHierarchiesDynamic({
    limit: 200,
    assetIds: [[primaryDatasetId], Object.keys(relatedPathsAndAssets || {})].flat(),
    canonicalRightCode: scenarioData.scenario?.canonicalRight?.code || '',
    enabled: Boolean(relatedPathsAndAssets) && !!scenarioData.scenario?.canonicalRight?.code,
  })

  const { assetsSummary, isLoading: isAssetsSummaryLoading } = useAssetsSummaryDynamic({
    assetIds: [[primaryDatasetId], Object.keys(relatedPathsAndAssets || {})].flat(),
    enabled: Boolean(relatedPathsAndAssets),
  })

  const { mutate: upsertDsr } = useUpsertDsrConfiguration({
    onSuccess: () => {
      showToast({ content: 'Saved configuration', type: 'success' })
      if (initiatorIsDatabase) {
        // assetsSummary[0] is primary asset summary
        const { id: databaseId = '' } =
          assetsSummary[0]?.asset?.resource?.hierarchies?.CANONICAL_RESOURCE_TYPE_DATABASE || {}
        navigate(
          RoutesManager.assetManager.assets.detail.scenarioDetailsTab.getURL({
            resourceTypeCode: assetType,
            id: databaseId,
            scenarioId,
            tab: LookUpTab.DIRECT,
          }),
        )
      } else {
        navigate(
          RoutesManager.assetManager.assets.detail.dsrConfiguration.getURL({
            code: primaryDatasetId,
            resourceTypeCode: assetType,
          }),
        )
      }
    },
    onError: () => {
      showToast({ content: 'Unable to save configuration.', type: 'error' })
    },
  })

  const onSubmit = (formData: {
    createData: CreatePatchDSRConfigsRequestBodyDTO
    patchData: CreatePatchDSRConfigsRequestBodyDTO
  }) => {
    const dsrConfigs = []

    if (formData?.createData?.datasetDSRConfiguration?.length) dsrConfigs.push(formData.createData)
    if (formData?.patchData?.datasetDSRConfiguration?.length) dsrConfigs.push(formData.patchData)

    if (dsrConfigs.length) {
      dsrConfigs?.forEach?.(data => {
        const isCreate = data?.datasetDSRConfiguration?.some(config => !config.id)
        upsertDsr({
          params: {
            data,
            type: isCreate ? 'create' : 'patch',
          },
        })
      })
    }
  }

  const isReady =
    !isAssetsSummaryLoading &&
    !isAssetsHierarchiesLoading &&
    !isIdentitySpacesLoading &&
    !isRelatedDataSetsLoading &&
    !isScenarioLoading &&
    !isAssetsDsrConfigLoading &&
    Boolean(relatedPathsAndAssets)

  useEffect(() => {
    if (!isRelatedDataSetsLoading) {
      const relatedPathsAndAssets = getRelatedPathsAndAssets({ relatedDataSets, primaryDatasetId: primaryDatasetId! })
      setRelatedPathsAndAssets(relatedPathsAndAssets)
    }
  }, [relatedDataSets, isRelatedDataSetsLoading, primaryDatasetId])

  return isEditMode ? (
    <DSRConfigEdit
      isReady={isReady}
      identitySpaces={identitySpaces}
      assetsHierarchies={assetsHierarchies}
      assetsSummary={assetsSummary}
      relatedPathsAndAssets={relatedPathsAndAssets || {}}
      scenarioData={scenarioData}
      assetsDsrConfig={assetsDsrConfig}
      onSubmit={onSubmit}
    />
  ) : (
    <DSRConfigCreate
      isReady={isReady}
      identitySpaces={identitySpaces}
      assetsHierarchies={assetsHierarchies}
      assetsSummary={assetsSummary}
      relatedPathsAndAssets={relatedPathsAndAssets || {}}
      scenarioData={scenarioData}
      assetsDsrConfig={assetsDsrConfig}
      onSubmit={onSubmit}
    />
  )
}
