import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { ApiKeyDTO } from 'interfaces/apiKeys'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
}>

type Response = APIListResponse<{ keys: ApiKeyDTO[] }>

export const fetchApiKeys = ({ organizationCode, start, limit }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/api-keys`,
      params: {
        filters: {
          start,
          limit,
        },
      },
    }),
  )
