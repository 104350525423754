import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  id: string
  formData: ThemeV3DTO
}

export const updateTheme = ({ id, formData }: Params) =>
  API.put<ThemeV3DTO>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/themes/${id}`,
    }),
    { theme: formData },
  )
