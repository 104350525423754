import { ICanvasStep } from '../../interfaces'
import { WorkflowActivityGatewayMode } from '../../../../../../interfaces/workflowActivities/workflowActivity'
import { isUndefined } from 'lodash'
import { getTransitionsFromStep } from './transitionsFromStep'
import { pathContainsGateway } from './pathContainsGateway'
import { getNextJoinTile } from './getNextJoinTile'

type Args = {
  pathStartStepId: string
  steps: ICanvasStep[]
  visited?: Set<String>
  untilEnd?: boolean
}

/**
 * Get all steps within a gateway path
 * @param pathStartStepId Id of the first step in the path
 * @param steps All steps in the workflow (or at least in the path)
 * @param untilEnd If false, only get steps between gateway and next join. Otherwise get all steps until end
 * @param visited Set of visited step IDs
 */
export const getStepsInPath = ({
  pathStartStepId,
  steps,
  untilEnd = false,
  visited = new Set<string>(),
}: Args): ICanvasStep[] => {
  let currentStep = steps.find(step => step.ID === pathStartStepId)
  const stepsInPath: ICanvasStep[] = []

  while (true) {
    // Determine the type of step we are at
    const currentStepIsGateway = !!currentStep && !isUndefined(currentStep.gateway)
    const currentStepIsJoin =
      !!currentStep &&
      !isUndefined(currentStep.gateway) &&
      currentStep.gateway.mode === WorkflowActivityGatewayMode.JOIN
    const currentStepIsEnd = !!currentStep && !isUndefined(currentStep.finish)
    const stepVisited = !!currentStep && visited.has(currentStep.ID?.toString() || '')

    // Determine if at last step
    if (!currentStep || currentStepIsEnd || (!untilEnd && currentStepIsJoin)) {
      // Include end in the path
      if (!stepVisited && currentStepIsEnd) {
        stepsInPath.push(currentStep!)
        visited.add(currentStep?.ID?.toString() || '')
      }
      break
    }

    // Append the current step in all other cases
    if (!!currentStep) {
      if (!stepVisited) stepsInPath.push(currentStep!)
      visited.add(currentStep.ID?.toString() || '')
    }

    // Append the all paths whenever a gateway is reached
    if (currentStepIsGateway) {
      currentStep.gateway?.transitions.forEach(transition => {
        stepsInPath.push(
          ...getStepsInPath({
            pathStartStepId: transition.ID?.toString() || '',
            steps,
            visited,
          }),
        )
      })
    }

    // Move to next step
    const nextSteps = getTransitionsFromStep({ step: currentStep, steps })
    currentStep = nextSteps.length ? nextSteps[0] : undefined
  }

  // Add join tile
  if (!untilEnd && pathContainsGateway(stepsInPath)) {
    const join = getNextJoinTile({ step: stepsInPath[0], steps })
    if (join) stepsInPath.push(join)
  }

  return stepsInPath
}
