import React, { Fragment } from 'react'
import { WebhooksContextTimelineFormDataDTO, RightInvocationFormDataDTO, FormFieldTypeDTO } from '@ketch-com/figurehead'
import { Box, Typography } from '@mui/material'
import { FormDataChip } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/components'

type Props = {
  formData?: WebhooksContextTimelineFormDataDTO
}

export const FormDataTimelineContent: React.FC<Props> = ({ formData }) => {
  return (
    <Box display="flex" flexDirection="column" gap={1.5}>
      {formData?.data?.map((data: RightInvocationFormDataDTO, dataIndex) => {
        const isCheckbox = data?.type === FormFieldTypeDTO.CheckboxFormFieldType

        if (isCheckbox) {
          return (
            <Fragment key={`${data?.code}-${dataIndex}`}>
              <Box display="flex" alignItems="center">
                <FormDataChip formDataItem={data} />
              </Box>
              <Typography>Values:</Typography>
              <Box
                component="ul"
                pl={2.5}
                sx={{
                  listStyle: 'initial',
                }}
              >
                {data?.values?.map?.((val, valIndex) => {
                  return (
                    <Box
                      component="li"
                      key={`${val}-${valIndex}`}
                      sx={{
                        wordBreak: 'break-all',
                      }}
                    >
                      <Typography
                        variant="label"
                        sx={{
                          wordBreak: 'break-all',
                        }}
                      >
                        {data?.options?.find(option => option.value === val)?.label}
                      </Typography>
                    </Box>
                  )
                })}
              </Box>
            </Fragment>
          )
        }

        const value = data?.options?.find(option => option.value === data?.value)?.label || data?.value

        return (
          <Box display="flex" gap={1} key={`${data?.code}-${dataIndex}`} flexWrap="wrap" alignItems="center">
            <FormDataChip formDataItem={data} />
            <Typography variant="body">Value:</Typography>
            <Typography sx={{ overflowWrap: 'anywhere' }} variant="label">{`${value}`}</Typography>
          </Box>
        )
      })}
    </Box>
  )
}
