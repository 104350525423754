import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import { FormikErrors, FormikTouched } from 'formik'

import { Text } from 'components/ui-kit/typography/Text'
import { Input } from 'components/ui-kit/input/Input'
import { Dropdown } from 'components/ui-kit/dropdown/Dropdown'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { Info as InfoIcon } from '@mui/icons-material'
import { Checkbox } from 'components/ui-kit/checkbox/Checkbox'
import { DAY_OF_WEEK_OPTIONS, FREQUENCY_OPTIONS, PERIOD_OPTIONS } from './constants'
import { useEditAutoDiscoveryFormSectionUtils } from './hooks'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { RadioGroup } from 'components/ui-kit/radioGroup/RadioGroup'
import { getUtcOffsetMessage } from './utils'

const useStyles = makeStyles(
  ({ spacing, palette, typography }) =>
    createStyles({
      timeInput: {
        maxWidth: '72px',
        marginRight: spacing(2),
      },
      frequencyDropdown: {
        marginRight: spacing(3),
        width: 236,
      },
      dayOfWeekRadioGroup: {
        marginBottom: spacing(2),
      },
      infoIcon: {
        color: palette.lightGrey.main,
        fontSize: typography.pxToRem(12),
        marginRight: spacing(1),
      },
      hidden: {
        opacity: 0,
      },
    }),
  { name: 'EditAutoDiscoveryFormSection' },
)

type Props = {
  assetSummary?: AssetSummaryDTO
  errors: FormikErrors<EditDatabaseFormValues>
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  isUpdatingDatabase: boolean
  values: EditDatabaseFormValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<EditDatabaseFormValues>>
  handleChange: {
    (e: React.ChangeEvent<any>): void
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1,
    ): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void
  }
  touched: FormikTouched<EditDatabaseFormValues>
  setTouched: (
    touched: FormikTouched<EditDatabaseFormValues>,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<EditDatabaseFormValues>>
}

export const EditAutoDiscoveryFormSection: React.FC<Props> = ({
  assetSummary,
  errors,
  handleChange,
  isUpdatingDatabase,
  values,
  setFieldValue,
  touched,
  setTouched,
}) => {
  const classes = useStyles()
  const { shouldShowStartOnInput, shouldShowEveryMonthInfo, handleStartTimeInputOnChange } =
    useEditAutoDiscoveryFormSectionUtils({
      assetSummary,
      setFieldValue,
      values,
    })

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" mb={1}>
        <Text size={14} weight={600} sx={{ minWidth: 260 }}>
          Frequency
        </Text>
      </Box>

      {/* Frequency Input */}

      <Box display="flex" alignItems="center" mb={2}>
        <Dropdown
          disabled={isUpdatingDatabase}
          className={classes.frequencyDropdown}
          placeholder="Select Frequency"
          name="schedule.frequency"
          value={values?.schedule?.frequency}
          items={FREQUENCY_OPTIONS.map(({ id, name }) => ({
            active: values?.schedule?.frequency === id,
            content: name,
            value: id,
            onClick: () => {
              setFieldValue('schedule.frequency', id)
              if (id === '2') {
                setFieldValue('schedule.day', '1')
              }
            },
          }))}
        >
          {FREQUENCY_OPTIONS.find(({ id }) => id === values?.schedule?.frequency)?.name}
        </Dropdown>

        {shouldShowEveryMonthInfo ? (
          <>
            <InfoIcon className={classes.infoIcon} />
            <Text size={11} weight={500} color="darkDuskFaded">
              Deep Classification will be triggered on the first day of every month.
            </Text>
          </>
        ) : null}
      </Box>

      <Box display="flex" alignItems="center" mb={1}>
        {shouldShowStartOnInput ? (
          <Text size={14} weight={600}>
            Start On
          </Text>
        ) : null}
      </Box>

      {/* Day Input */}

      {shouldShowStartOnInput ? (
        <Box display="flex" alignItems="center" mb={2}>
          <RadioGroup
            name="schedule.day"
            layout="row"
            onChange={handleChange}
            options={DAY_OF_WEEK_OPTIONS.map(({ id, name }) => ({
              disabled: isUpdatingDatabase,
              title: name,
              value: id,
            }))}
            value={values?.schedule?.day}
          />
        </Box>
      ) : null}

      {/* Customize Start Time Checkbox */}

      <Box display="flex" alignItems="center" mb={1}>
        <Checkbox
          disabled={isUpdatingDatabase}
          title="Customize Starting Time"
          name="schedule.isCustomizeStartingTimeChecked"
          value={!!values?.schedule?.isCustomizeStartingTimeChecked}
          onChange={() => {
            if (!!values?.schedule?.isCustomizeStartingTimeChecked) {
              setFieldValue(`schedule.isCustomizeStartingTimeChecked`, false)
            } else {
              setFieldValue(`schedule.isCustomizeStartingTimeChecked`, true)
            }
          }}
        />
      </Box>

      {values?.schedule?.isCustomizeStartingTimeChecked ? (
        <>
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <Input
                disabled={isUpdatingDatabase}
                className={classes.timeInput}
                onChange={handleStartTimeInputOnChange}
                placeholder="12:00"
                name="schedule.time"
                type="text"
                onBlur={() => setTouched({ ...touched, schedule: { ...touched.schedule, time: true } })}
                value={values?.schedule?.time || ''}
                valid={touched?.schedule?.time ? !errors.schedule?.time : true}
              />
              <RadioGroup
                name="schedule.period"
                layout="row"
                onChange={handleChange}
                value={values?.schedule?.period}
                options={PERIOD_OPTIONS.map(({ id, name }) => ({
                  disabled: isUpdatingDatabase,
                  title: name,
                  value: id,
                }))}
              />
            </Box>
          </Box>

          {touched?.schedule?.time && errors.schedule?.time ? <Error>{errors?.schedule?.time}</Error> : null}

          <Box display="flex" flexDirection="column">
            {values?.schedule?.isCustomizeStartingTimeChecked ? (
              <Box display="flex" alignItems="center" mt={1}>
                <Text size={14} color="darkDuskFaded">
                  {getUtcOffsetMessage()}
                </Text>
              </Box>
            ) : null}
          </Box>
        </>
      ) : null}
    </Box>
  )
}
