import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetSubscriptionControlResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

type Params = APIListRequestParams<{
  code?: string
}>

export const fetchSubscriptionControl = ({ code, includeMetadata }: Params) =>
  API.get<GetSubscriptionControlResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/subscriptions/controls/${code}`,
      params: {
        filters: {
          ...(includeMetadata && {
            includeMetadata,
          }),
        },
      },
    }),
  )
