import { useNavigate, useParams } from 'react-router-dom'
import { UrlParams } from 'pages/consentAndRights/consent/privacyProtocols/upsert/utils/interfaces'
import { usePrivacyProtocol } from 'api/privacyProtocols/queries/usePrivacyProtocol'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { usePurposesV2 } from 'api/purposesV2/queries/usePurposesV2'
import { useJurisdictionsV2 } from 'api/jurisdictionsV2/queries/useJurisdictionsV2'
import { useGlobalVendorList } from 'api/globalVendorList/queries/useGlobalVendorList'
import { useUpdatePrivacyProtocolConfigStatus } from 'api/privacyProtocols/mutations/useUpdatePrivacyProtocolConfigStatus'
import { PatchPrivacyProtocolConfigBody } from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'
import { googleACProtocols, gvlProtocols, gvlRegion, protocolId } from '../../constants'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useGoogleVendorList } from 'api/googleVendorList/queries/useGoogleVendorList'

export const usePrivacyProtocolUtils = () => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const canWrite = isPermitted(PERMISSIONS.PERMIT_ORCH_WRITE)

  // Page details
  const { id } = useParams<UrlParams>()
  const region = gvlProtocols.includes(id || '')
    ? id === protocolId.tcfca
      ? gvlRegion.canada
      : gvlRegion.europe
    : gvlRegion.europe

  // Fetch data
  const {
    data: protocol,
    refetch: refetchProtocol,
    isLoading: isProtocolLoading,
    isFetching: isProtocolFetching,
  } = usePrivacyProtocol({
    params: {
      protocolId: id,
    },
    onError: () => {
      showToast({ content: `Failed to fetch protocol`, type: 'error' })
    },
  })
  const {
    data: purposes,
    isLoading: isPurposesLoading,
    isFetching: isPurposesFetching,
  } = usePurposesV2({
    onError: () => {
      showToast({ content: `Failed to fetch purposes`, type: 'error' })
    },
  })
  const {
    data: jurisdictions,
    isLoading: isJurisdictionsLoading,
    isFetching: isJurisdictionsFetching,
  } = useJurisdictionsV2({
    onError: () => {
      showToast({ content: `Failed to fetch jurisdictions`, type: 'error' })
    },
  })
  const {
    data: globalVendorList,
    isLoading: isGlobalVendorListLoading,
    isFetching: isGlobalVendorListFetching,
  } = useGlobalVendorList({
    enabled: gvlProtocols.includes(id || ''),
    params: { region },
    onError: () => {
      showToast({ content: `Failed to fetch global vendor list`, type: 'error' })
    },
  })

  const {
    data: googleVendorList,
    isLoading: isGoogleVendorListLoading,
    isFetching: isGoogleVendorListFetching,
  } = useGoogleVendorList({
    enabled: googleACProtocols.includes(id || ''),
    params: { region },
    onError: () => {
      showToast({ content: `Failed to fetch Google additional consents vendor list`, type: 'error' })
    },
  })

  const isReady =
    !(isProtocolLoading || isProtocolFetching) &&
    !(isPurposesLoading || isPurposesFetching) &&
    !(isJurisdictionsLoading || isJurisdictionsFetching) &&
    !(isGlobalVendorListLoading || isGlobalVendorListFetching) &&
    !(isGoogleVendorListLoading || isGoogleVendorListFetching)

  // Update status
  const { mutateAsync: updatePrivacyProtocolConfigStatus } = useUpdatePrivacyProtocolConfigStatus()
  const updateStatus = async (id: string, formData: PatchPrivacyProtocolConfigBody): Promise<any> => {
    await updatePrivacyProtocolConfigStatus({
      params: { id, formData },
      onError: () => {
        showToast({ content: `Failed to update protocol`, type: 'error' })
      },
      onSuccess: () => {
        showToast({ content: `Updated protocol`, type: 'success' })
      },
    })
  }

  return {
    navigate,
    canWrite,
    protocol,
    refetchProtocol,
    purposes,
    jurisdictions,
    globalVendorList,
    googleVendorList,
    isReady,
    updateStatus,
  }
}

export type PrivacyProtocolUtils = ReturnType<typeof usePrivacyProtocolUtils>
