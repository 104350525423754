import { WebhooksContextTimelineDTO } from '@ketch-com/figurehead'

export enum WebhooksContextTimelineItemStatus {
  UNDEFINED = 0,
  ERROR = 1,
  START = 2,
  SUCCESS = 3,
  INVALID = 4,
  UNKNOWN = 5,
}

export enum WebhooksContextDetailsStatus {
  UNDEFINED = 0,
  WEBHOOK = 1,
  CALLBACK = 2,
}

export enum WebhooksContextDetailsType {
  UNDEFINED = 0,
  REQUEST = 1,
  RESPONSE = 2,
}

export type SubmitTimelineChangeProps = {
  timelineItem: WebhooksContextTimelineDTO
  newTimelineItem: WebhooksContextTimelineDTO
  timelineItemToReplace: WebhooksContextTimelineDTO
}

export type FormDataInputValue = string | number | boolean | undefined | string[]
