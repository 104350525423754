import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { Plan } from '@ketch-com/harbormaster/dist/organization/plans.schemas'

type Params = APIRequestParams<{
  organizationCode: string
}>

export const fetchPlan = ({ organizationCode }: Params) =>
  API.get<Plan>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/plan`,
    }),
  )
