import { AssessmentDTO } from '@ketch-com/figurehead'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { fetchAssessments } from '../fetchers/fetchAssessments'
import { showToast } from 'components/ui-kit/toastr/Toastr'

const limit = 30

export const useInfiniteAssessments = ({
  name,
  status,
  initialAssignee,
}: {
  name?: string
  status?: string
  initialAssignee?: string
}) => {
  const resp = useInfiniteQuery({
    onError: () => showToast({ type: 'error', content: 'Failed to load Assessments' }),
    queryKey: [ApiQueryKeys.assessments, { name, status }],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchAssessmentResult = await fetchAssessments({
        limit,
        start: (pageParam - 1) * limit,
        name: name || undefined,
        status: status || undefined,
        initialAssignee,
      })

      return {
        assessments: fetchAssessmentResult.data?.data ?? [],
        totalResults: fetchAssessmentResult.data?.meta?.count ?? 0,
        meta: fetchAssessmentResult.data?.meta ?? {},
      }
    },
    getNextPageParam: ({ totalResults }, allPages) => {
      let totalItemsFetched = 0
      allPages?.forEach?.(pageOfResults => {
        totalItemsFetched += pageOfResults?.assessments?.length || 0
      })
      const nextPageParam = Math.ceil(totalItemsFetched / limit) + 1

      return totalItemsFetched === totalResults ? undefined : nextPageParam
    },
  })

  let infiniteAssessments: AssessmentDTO[] = []

  let totalResults = 0

  let meta = {}

  resp.data?.pages?.forEach?.(page => {
    const newAssessments = page?.assessments as AssessmentDTO[]
    infiniteAssessments = [...infiniteAssessments, ...newAssessments]
    totalResults = page?.totalResults || 0
    meta = page?.meta || {}
  })

  return {
    ...resp,
    data: infiniteAssessments,
    totalResults,
    meta,
  }
}
