import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { EditSchemaFormValues } from 'interfaces/assets'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import { KETCH_DML } from 'pages/developers/hooks/constants'

type Args = { schema: MaybeNull<AssetSummaryDTO> }

export const getInitialValues = ({ schema }: Args): EditSchemaFormValues => {
  if (!schema) {
    return {
      name: '',
      description: '',
      dataCategory: '',
      labels: [],
      inferredLabels: [],
      dataOwner: '',
      hooks: [],
    }
  }

  const hooksPayload = [
    ...(schema?.asset?.dmlPreHooks || []),
    {
      assignmentCount: 9999,
      dmlCode: 'dummy',
      id: 'dummy',
      name: KETCH_DML,
      operation: 99999,
      technology: 'dummy',
      type: 99,
    },
    ...(schema?.asset?.dmlPostHooks || []),
  ]

  return {
    name: schema?.asset?.resource?.name || '',
    description: schema?.asset?.description || '',
    dataCategory: schema?.asset?.dataCategory?.[0]?.value || '',
    labels: (schema?.asset?.assignedLabels || []) as ManageLabelModalLabel[],
    inferredLabels: (schema?.asset?.inferredLabels || []) as ManageLabelModalLabel[],
    dataOwner: schema?.asset?.owner || '',
    hooks: hooksPayload,
  }
}
