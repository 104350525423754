import React, { useState, useEffect } from 'react'
import { useDebounce } from 'react-use'
import { uniqBy } from 'lodash'

// API
import { useDataSubjectTypes } from 'api/dataSubjectTypes/queries/useDataSubjectTypes'
import { useOrchestrationEvents } from 'api/orchestrationEventsV2/queries/useOrchestrationEvents'

// components
import { Box } from '@mui/material'
import { DropList } from 'components/ui-kit/dropList/DropList'
import { Group } from 'components/ui-layouts/group/Group'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { Text } from 'components/ui-kit/typography/Text'

// dux
import { getEventsListFilterState } from 'store/eventsListFilterSlice/selectors'
import {
  clearFilterState,
  clearSearchQuery,
  setDataSubjectTypeCodeFilter,
  setRightCodeFilter,
  setRightTypeFilter,
  setSearchQuery,
} from 'store/eventsListFilterSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'

// types
import { DataRoleType } from 'interfaces/events'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { RightType } from 'interfaces/events'

const segmentsTabOptions = [
  {
    id: RightType.RIGHT_TYPE_UNSPECIFIED,
    name: 'All',
  },
  {
    id: RightType.RIGHT_TYPE_CANONICAL,
    name: 'Canonical',
  },
  {
    id: RightType.RIGHT_TYPE_CUSTOM,
    name: 'Custom',
  },
]

type Props = {}

export const EventsListFilters: React.FC<Props> = () => {
  // hooks
  const dispatch = useAppDispatch()
  const { dataRole, dataSubjectTypeCode, rightCode, rightType, searchQuery } = useAppSelector(getEventsListFilterState)
  const [searchString, setSearchString] = useState(() => searchQuery || '')
  const [activeRight, setActiveRight] = useState('')

  // apis
  const { data: dataSubjectTypes } = useDataSubjectTypes()
  const { data: events } = useOrchestrationEvents({
    params: {
      dataRole,
      rightType,
      dataSubjectTypeCode,
    },
  })

  // Handles reset of data when switched from controller to processor view with dataSubjectTypeCode set as customer
  useEffect(() => {
    if (dataRole === DataRoleType.DATA_ROLE_PROCESSOR) {
      dispatch(setDataSubjectTypeCodeFilter(''))
    }
  }, [dataRole, dataSubjectTypeCode, dispatch])

  // utils
  const eventsList = events.map(({ rightCode: code, rightName }) => ({
    active: rightCode === code,
    content: rightName,
    onClick: () => {
      dispatch(setRightCodeFilter(code || ''))
      setActiveRight(rightName || 'Right')
    },
  }))

  const eventsAllOption = {
    active: rightCode === '',
    content: 'All Rights',
    onClick: () => dispatch(setRightCodeFilter('')),
  }
  const activeDataSubjectType = dataSubjectTypes.find(({ code }) => code === dataSubjectTypeCode)

  const dataSubjectTypesList = dataSubjectTypes.map(({ code, name }) => {
    return {
      active: dataSubjectTypeCode === code,
      content: <NameAndCodeCellRenderer name={name} code={code} shouldCapitalizeName />,
      onClick: () => {
        dispatch(setDataSubjectTypeCodeFilter(code || ''))
      },
    }
  })

  const dataSubjectTypesAllOption = {
    active: dataSubjectTypeCode === '',
    content: <>All Subject Types</>,
    onClick: () => dispatch(setDataSubjectTypeCodeFilter('')),
  }

  useDebounce(
    () => {
      if (searchString) {
        dispatch(setSearchQuery(searchString))
      } else {
        dispatch(clearSearchQuery())
      }
    },
    500,
    [searchString],
  )

  return (
    <Group>
      <SearchInput
        size="small"
        placeholder="Search Event Name"
        onChange={nextSearchString => setSearchString(nextSearchString)}
        onClear={() => setSearchString('')}
        value={searchString}
      />

      <SegmentTabs
        tabs={segmentsTabOptions}
        value={rightType}
        onChange={value => dispatch(setRightTypeFilter(value))}
      />

      <DropList
        placeholder={rightCode === '' ? 'All Rights' : ''}
        items={uniqBy([eventsAllOption, ...eventsList], 'content')}
        value={rightCode}
      >
        {activeRight}
      </DropList>

      {dataRole !== DataRoleType.DATA_ROLE_PROCESSOR && (
        <DropList
          placeholder={dataSubjectTypeCode === '' ? 'All Subject Types' : ''}
          items={[dataSubjectTypesAllOption, ...dataSubjectTypesList]}
          value={dataSubjectTypeCode}
        >
          <NameAndCodeCellRenderer
            name={activeDataSubjectType?.name}
            code={activeDataSubjectType?.code}
            shouldCapitalizeName
          />
        </DropList>
      )}

      <Box ml={2} onClick={() => dispatch(clearFilterState())} display="flex" alignItems="center">
        <Text
          size={14}
          color="sphere"
          sx={{
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
              color: 'palette.sphere.main',
              fontWeight: 400,
            },
          }}
        >
          Clear All
        </Text>
      </Box>
    </Group>
  )
}
