import React from 'react'
import { Box, Typography } from '@mui/material'
import { FormSwitch } from 'components/form/FormSwitch'
import { theme } from '@ketch-com/deck'

type Props = {}

export const MarketingPreferenceIdentity: React.FC<Props> = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      pb={2}
      pt={2}
      mt={3}
      borderTop={`1px solid ${theme.palette.iron.main}`}
    >
      <Box display="flex" alignItems="center" gap={3}>
        <FormSwitch formPropertyName="IsMarketingPreferences" size="medium" />
        <Typography variant="h4">Marketing Preference Identity</Typography>
      </Box>
    </Box>
  )
}
