import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { ProcessingActivityDTO } from '@ketch-com/figurehead'
import { processingActivityType } from 'pages/policyCenter/processingActivities/constants'
import { FormRow, Icon, theme } from '@ketch-com/deck'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { FormInput } from 'components/form/FormInput'

type Props = {}

export const BasicDetails: React.FC<Props> = () => {
  const { isSubmitting } = useFormikContext<ProcessingActivityDTO>()

  return (
    <FormRow title="Basic Details">
      <Box display="flex" flexDirection="column" gap={3}>
        <FormRadioGroup
          label={
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography variant="label" color="darkDusk.main">
                Type
              </Typography>
              <Tooltip
                title={
                  <Box display="flex" flexDirection="column" gap={2} px={0.5} py={1}>
                    <Box display="flex" flexDirection="column" gap={0.5}>
                      <Typography variant="label">
                        <strong>Standard</strong>
                      </Typography>
                      <Typography variant="smallBody">
                        Processing involves a combination of internal and external tools that interoperate to achieve
                        specific business objectives.
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" gap={0.5}>
                      <Typography variant="label">
                        <strong>AI-Model</strong>
                      </Typography>
                      <Typography variant="smallBody">
                        Processing specific to the training and deployment of an AI model.
                      </Typography>
                    </Box>
                  </Box>
                }
              >
                <Box display="flex" alignItems="center">
                  <Icon name="FUnknown" width={18} height={18} iconColor={theme.palette.darkDuskFaded.main} />
                </Box>
              </Tooltip>
            </Box>
          }
          required
          formPropertyName="type"
          row
          valueKey="type"
          renderLabel={({ label }, isSelected) => (
            <Typography variant={isSelected ? 'label' : 'body'}>{label}</Typography>
          )}
          options={processingActivityType.map(el => ({
            ...el,
            ...(isSubmitting && {
              disabled: true,
            }),
          }))}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <FormInput
          disabled={isSubmitting}
          required
          fullWidth
          formPropertyName="name"
          label="Name"
          placeholder="Example: Credit application approval process"
          shouldUpdateDebounced
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <FormInput
          disabled={isSubmitting}
          required
          fullWidth
          formPropertyName="description"
          label="Description"
          placeholder="Describe briefly the goal of this processing activity and how it will be used."
          shouldUpdateDebounced
        />
      </Box>
    </FormRow>
  )
}
