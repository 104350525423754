import { useContext, useState } from 'react'
import { ImportContextData } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/ImportContext/context'
import { Box, Typography } from '@mui/material'
import { useDebounce } from 'react-use'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { useFormikContext, ErrorMessage } from 'formik'
import { IImportContextParams } from 'pages/orchestration/workflows/edit/interfaces'
import { MaybeNull } from 'interfaces'
import { DatabaseResourceDetail } from '@ketch-com/windlass/dist/schemas'
import { DropListButton, Icon, ListItemText, ActionSheetItem } from '@ketch-com/deck'
import { FormError } from 'components/form/FormError'

export const SelectDatabase: React.FC = () => {
  const { databaseResources, isLoadingDatabaseResources, setDatabaseQuery } = useContext(ImportContextData)
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext<IImportContextParams>()
  const [inputValue, setInputValue] = useState('')
  const [selectedDatabaseResource, setSelectedDatabaseResource] = useState<MaybeNull<DatabaseResourceDetail>>(
    () =>
      databaseResources?.filter(databaseResource => databaseResource?.id === values?.databaseResourceId)?.[0] || null,
  )

  useDebounce(
    () => {
      if (inputValue) {
        setDatabaseQuery(inputValue)
      } else {
        setDatabaseQuery('')
      }
    },
    500,
    [inputValue],
  )

  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Typography variant="label">Database</Typography>
      <DropListButton
        sx={{
          ...(touched?.databaseResourceId &&
            errors?.databaseResourceId && {
              '& .MuiFormControl-root.MuiTextField-root': {
                '& .MuiOutlinedInput-root': {
                  border: ({ palette }) => `1px solid ${palette.chileanFire.main}`,
                },
              },
            }),
        }}
        placeholder="Select Database"
        loading={isLoadingDatabaseResources}
        value={selectedDatabaseResource}
        size="medium"
        filterOptions={x => x}
        clearOnBlur
        includeInputInList
        getOptionDisabled={option => option?.id === values?.databaseResourceId}
        onBlur={() => {
          setFieldTouched('databaseResourceId', true)
        }}
        onChange={(e, databaseResource, reason) => {
          if (reason === 'clear') {
            setFieldValue('databaseResourceId', '')
            setFieldValue('databaseResourceName', '')
            setFieldValue('databaseResourceTechnology', '')
            setFieldValue('databaseResourceTypeCode', '')
            setSelectedDatabaseResource(null)
          } else {
            setFieldValue('databaseResourceId', databaseResource?.id)
            setFieldValue('databaseResourceName', databaseResource?.name)
            setFieldValue('databaseResourceTechnology', databaseResource?.technology)
            setFieldValue('databaseResourceTypeCode', databaseResource?.providerCode)
            setSelectedDatabaseResource(databaseResource)
          }
        }}
        onInputChange={(e, inputVal) => {
          setInputValue(inputVal)
        }}
        options={databaseResources}
        renderOption={(props, databaseResource, { selected }) => {
          return (
            <ActionSheetItem selected={selected} {...props} key={databaseResource?.id}>
              <ListItemText selected={selected}>
                <Box key={databaseResource?.id} display="flex" flexDirection="column" gap={1} py={0.5}>
                  <Typography alignSelf="flex-start" variant="body">
                    {databaseResource?.name}
                  </Typography>
                  <Box alignSelf="flex-start" display="flex" alignItems="center" gap={0.5}>
                    <img
                      width={16}
                      height={16}
                      src={getAssetCloudImgUrl(databaseResource?.technology || '')}
                      alt={databaseResource?.technology || 'technology image'}
                    />
                    <Icon name="OConnectionTrue" />
                    <Typography variant="footnote" color="darkDuskFaded.main">
                      {databaseResource?.connectionCode}
                    </Typography>
                  </Box>
                </Box>
              </ListItemText>
            </ActionSheetItem>
          )
        }}
        getOptionLabel={option => option?.name || ''}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
      />

      <ErrorMessage name="databaseResourceId">
        {msg => {
          if (typeof msg === 'string')
            return (
              <Box>
                <FormError msg={msg} />
              </Box>
            )
          return null
        }}
      </ErrorMessage>
    </Box>
  )
}
