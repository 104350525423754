import React from 'react'
import { createStyles, makeStyles } from '@mui/styles'

import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { useHandleEditOnClick, useHandleViewAssetOnClick } from 'pages/assetManager/assets/list/hooks'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      buttonOptions: {
        background: palette.white.main,
      },
    }),
  { name: '' },
)

type Props = {
  asset: AssetSummaryDTO
}

export const AssignedAssetsListTableActionsCellRenderer: React.FC<Props> = ({ asset }) => {
  const classes = useStyles()
  const handleEditOnClick = useHandleEditOnClick(asset)
  const handleViewOnClick = useHandleViewAssetOnClick(asset)
  return (
    <ButtonOptions
      className={classes.buttonOptions}
      items={[
        {
          content: 'View',
          onClick: () => handleViewOnClick(),
        },
        {
          content: 'Assign',
          hidden: true, // TODO: un-hide when designed and BE ready
          onClick: () => {},
        },
        {
          content: 'Edit',
          onClick: () => handleEditOnClick(),
        },
      ]}
    />
  )
}
