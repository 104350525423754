import { useState, useEffect } from 'react'

import { Pagination } from 'api/common/paginatedQuery'
import { useKeyPress, usePrevious, useSetState } from 'react-use'

type Props = {
  pagination: Pagination
}

export const usePaginationHelpers = ({ pagination }: Props) => {
  const { page, totalPages = 0, onPageChange, isFirst, isLast, isPending, totalResults } = pagination
  const prevIsPending = usePrevious(isPending)
  const [isInputPageModeActive, setIsInputPageModeActive] = useState(false)

  const [{ isPrevPending, isNextPending }, setState] = useSetState({
    isPrevPending: false,
    isNextPending: false,
  })

  useEffect(() => {
    if (prevIsPending && !isPending) {
      setState({
        isPrevPending: false,
        isNextPending: false,
      })
    }
  }, [prevIsPending, isPending, setState])

  const handleLoadPrevPage = async () => {
    setState({ isPrevPending: true })
    onPageChange(page - 1)
  }

  const handleLoadNextPage = async () => {
    setState({ isNextPending: true })
    onPageChange(page + 1)
  }

  const handleSetTargetPage = (targetPage: number) => {
    setState({ isNextPending: true })
    onPageChange(targetPage)
  }

  const predicate: any = (event: any) => {
    if (event.key.toLowerCase() === 'escape') {
      return true
    }
  }
  const [isEscapePressed] = useKeyPress(predicate)

  useEffect(() => {
    if (isEscapePressed) {
      setIsInputPageModeActive(false)
    }
  }, [isEscapePressed])

  useEffect(() => {
    if (isInputPageModeActive) {
      const input = document.getElementById('targetPageInput')
      if (input) {
        input.focus()
      }
    }
  }, [isInputPageModeActive])

  return {
    totalPages,
    isFirst,
    isLast,
    handleLoadPrevPage,
    handleLoadNextPage,
    isPrevPending,
    onPageChange,
    isNextPending,
    page,
    isPending,
    handleSetTargetPage,
    isInputPageModeActive,
    setIsInputPageModeActive,
    setState,
    totalResults,
  }
}
