import { Box, styled } from '@mui/material'
import React, { useContext, useEffect, useRef } from 'react'
import desktopBackground from 'assets/images/previewBackground/bg-desktop-v2.png'
import mobileBackground from 'assets/images/previewBackground/bg-mobile-v2.png'
import { ReactComponent as PhoneDots } from 'assets/images/phone-preview-dots.svg'
import { ConsentExperienceType } from 'interfaces/experiences-v2/consentExperienceType'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import {
  ExperiencePreviewVariant,
  SHOW_BANNER_PREVIEW_MESSAGE,
  SHOW_MODAL_PREVIEW_MESSAGE,
  SHOW_PREFERENCE_PREVIEW_MESSAGE,
} from './constants'
import { ExperiencePreviewContext, withExperiencePreviewContext } from './context/ExperiencePreviewContext'
import { PreviewDisplayMode } from '../../utils/enums'
import { ExperiencePreviewHeader } from './components/ExperiencePreviewHeader'
import { usePreviewConfig } from 'api/experiences-v2/queries/usePreviewConfig'
import { useExperience } from 'api/experiences-v2/queries/useExperience'
import { PreviewMessage, PreviewMessageType } from '@ketch-sdk/ketch-types'
import { getPreviewMessage } from './utils'
import { ThemePreviewHeader } from './components/ThemePreviewHeader'
import { ThemeType } from 'pages/consentAndRights/themes-v3/upsert/utils/enums'
import { DeploymentPreviewHeader } from './components/DeploymentPreviewHeader'
import { PreviewBackground } from './components/PreviewBackground'

type Props = {
  /* Type of preview */
  variant: ExperiencePreviewVariant

  /* Experience ID */
  experienceID?: string

  /* Theme ID */
  themeID?: string

  /* Deployment Plan ID */
  deploymentID?: string
}

const PreviewBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '11px',
  border: `1px solid ${theme.palette.iron.main}`,
}))

const DesktopPreviewBox = styled(Box)({
  zIndex: 2,
})

const MobilePreviewBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '30px 0px 35px 0px',
  background: theme.palette.dataMapFillDefault.main,
  borderBottomLeftRadius: '11px',
  borderBottomRightRadius: '11px',
}))

interface DesktopPreviewBackgroundBoxProps {
  hasGreaterBackgroundHeight: boolean
}

const DesktopPreviewBackgroundBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'experiencePreviewVariant',
})<DesktopPreviewBackgroundBoxProps>(({ hasGreaterBackgroundHeight }) => ({
  backgroundImage: `url('${desktopBackground}')`,
  position: 'relative',
  width: '100%',
  height: '100%',
  minHeight: !!hasGreaterBackgroundHeight ? '769px' : '570px',
  zIndex: 1,
  borderBottomLeftRadius: '11px',
  borderBottomRightRadius: '11px',
}))

const MobilePreviewBackgroundBox = styled(Box)({
  backgroundImage: `url('${mobileBackground}')`,
  position: 'relative',
  height: '100%',
  width: '100%',
})

const PhoneOuterBox = styled(Box)({
  display: 'flex',
  background: 'white',
  width: '386px',
  height: '685px',
  padding: '8.711px 13px 13px 13px',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '31px',
  boxShadow:
    '30px 2px 40px 0px rgba(255, 255, 255, 0.20) inset, -30px -2px 30px 0px rgba(229, 229, 229, 0.15) inset, 25px 35px 50px 0px rgba(7, 26, 36, 0.15), 0px -4px 8px 0px rgba(7, 26, 36, 0.19) inset;',
})

const PhoneHeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '32.5px',
  width: '100%',
  marginTop: '8.289px',
  borderTopLeftRadius: '15px',
  borderTopRightRadius: '15px',
  background: theme.palette.Black.o4,
}))

const PhoneFooterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '48px',
  width: '100%',
  marginTop: '8.289px',
  borderBottomLeftRadius: '15px',
  borderBottomRightRadius: '15px',
  background: theme.palette.Black.o4,
}))

const ExperiencePreviewPhone: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <PhoneOuterBox>
      <PhoneDots />
      <PhoneHeaderBox />
      <MobilePreviewBackgroundBox>{children}</MobilePreviewBackgroundBox>
      <PhoneFooterBox />
    </PhoneOuterBox>
  )
}

const themeTypeMessageMap: { [themeTypeKey: string]: PreviewMessage } = {
  [ThemeType.Banner]: SHOW_BANNER_PREVIEW_MESSAGE,
  [ThemeType.Modal]: SHOW_MODAL_PREVIEW_MESSAGE,
  [ThemeType.Preference]: SHOW_PREFERENCE_PREVIEW_MESSAGE,
}

const themeTypeMessageTypeMap: { [themeTypeKey: string]: PreviewMessageType } = {
  [ThemeType.Banner]: PreviewMessageType.ShowBanner,
  [ThemeType.Modal]: PreviewMessageType.ShowModal,
  [ThemeType.Preference]: PreviewMessageType.ShowPreference,
}

export const ExperiencePreviewWithoutContext: React.FC<Props> = ({ variant, experienceID, themeID, deploymentID }) => {
  const {
    displayMode,
    consentExperienceType,
    isPreviewReady,
    setIsPreviewReady,
    theme,
    language,
    jurisdiction,
    themeType,
  } = useContext(ExperiencePreviewContext)

  const iRef = useRef<HTMLIFrameElement | null>(null)

  const previewVariantHeaders = {
    [ExperiencePreviewVariant.ConsentExperience]: (
      <ExperiencePreviewHeader variant={ExperienceType.Consent} isLoading={!isPreviewReady} />
    ),
    [ExperiencePreviewVariant.PreferenceExperience]: (
      <ExperiencePreviewHeader variant={ExperienceType.Preference} isLoading={!isPreviewReady} />
    ),
    [ExperiencePreviewVariant.Theme]: <ThemePreviewHeader isLoading={!isPreviewReady} />,
    [ExperiencePreviewVariant.DeploymentPlan]: <DeploymentPreviewHeader isLoading={!isPreviewReady} />,
  }

  //
  // Data and flags
  //

  const { data: experience } = useExperience({
    enabled: !!experienceID,
    params: {
      id: experienceID || '',
      includeMetadata: true,
      includeIssues: true,
    },
  })

  const { data: previewConfig } = usePreviewConfig({
    params: {
      experienceID: experienceID,
      ...(variant !== ExperiencePreviewVariant.DeploymentPlan
        ? { themeID: variant === ExperiencePreviewVariant.Theme ? themeID : theme?.id }
        : undefined),
      languageCode: language?.language.code,
      jurisdictionCode: jurisdiction?.code,
      deploymentID,
    },
    onSuccess: response => {
      // Hack to force iframe reload on config change
      if (iRef.current && !isPreviewReady) {
        iRef.current.src += ''
      }
    },
  })

  const isConsent = experience?.type === ExperienceType.Consent

  //
  // Effects for managing fetching and communication with iFrame
  //

  // Send consent experience type updates to lanyard
  useEffect(() => {
    if (isConsent && isPreviewReady) {
      const message =
        consentExperienceType === ConsentExperienceType.Banner
          ? SHOW_BANNER_PREVIEW_MESSAGE
          : SHOW_MODAL_PREVIEW_MESSAGE
      iRef.current?.contentWindow?.postMessage(message)
    }
  }, [consentExperienceType, isPreviewReady, isConsent])

  // Send theme type updates to lanyard
  useEffect(() => {
    if (isPreviewReady) {
      const message = themeTypeMessageMap[themeType]
      iRef.current?.contentWindow?.postMessage(message)
    }
  }, [themeType, isPreviewReady])

  // Send initial config
  useEffect(() => {
    if (isPreviewReady && previewConfig) {
      // Send initial view mode message for preference only
      if (variant === ExperiencePreviewVariant.PreferenceExperience) {
        iRef.current?.contentWindow?.postMessage(SHOW_PREFERENCE_PREVIEW_MESSAGE)
      }

      // Send inital config and display mode
      let initialMessage: PreviewMessage
      if (
        variant === ExperiencePreviewVariant.ConsentExperience ||
        variant === ExperiencePreviewVariant.PreferenceExperience
      ) {
        initialMessage = isConsent
          ? consentExperienceType === ConsentExperienceType.Banner
            ? getPreviewMessage(PreviewMessageType.ShowBanner, previewConfig)
            : getPreviewMessage(PreviewMessageType.ShowModal, previewConfig)
          : getPreviewMessage(PreviewMessageType.ShowPreference, previewConfig)
      } else {
        initialMessage = getPreviewMessage(themeTypeMessageTypeMap[themeType], previewConfig)
      }

      iRef.current?.contentWindow?.postMessage(initialMessage)
    }
  }, [isPreviewReady, isConsent, previewConfig, consentExperienceType, themeType, variant])

  // Listen for the ready signal from preview.html
  useEffect(() => {
    window.addEventListener('message', (e: MessageEvent) => {
      if (e.data.type === 'lanyardReady' && !isPreviewReady) {
        setIsPreviewReady(true)
      }
    })
  }, [isPreviewReady, setIsPreviewReady])

  //
  // Display components
  //

  const isDesktop = displayMode === PreviewDisplayMode.Desktop
  const OuterBox = isDesktop ? DesktopPreviewBox : MobilePreviewBox
  const InnerBox = isDesktop ? DesktopPreviewBackgroundBox : ExperiencePreviewPhone

  const hasGreaterBackgroundHeight =
    themeType === ThemeType.Preference ||
    themeType === ThemeType.Modal ||
    variant === ExperiencePreviewVariant.PreferenceExperience ||
    (variant === ExperiencePreviewVariant.ConsentExperience && consentExperienceType === ConsentExperienceType.Modal)

  const iFrameStyle: React.CSSProperties = {
    ...(isDesktop
      ? { width: '100%', height: hasGreaterBackgroundHeight ? '769px' : '570px', border: 0 }
      : { width: '100%', height: '100%', border: 0 }),
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    background: 'transparent',
    borderRadius: '11px',
  }

  return (
    <PreviewBox>
      {previewVariantHeaders[variant]}
      <OuterBox>
        <InnerBox hasGreaterBackgroundHeight={hasGreaterBackgroundHeight}>
          <PreviewBackground
            variant={displayMode}
            isLoading={!isPreviewReady}
            hasGreaterBackgroundHeight={hasGreaterBackgroundHeight}
          >
            {previewConfig ? (
              <iframe
                title="experience preview"
                ref={iRef}
                style={iFrameStyle}
                src={`${window.location.origin}/preview.html?is_preview=true`}
              />
            ) : null}
          </PreviewBackground>
        </InnerBox>
      </OuterBox>
    </PreviewBox>
  )
}

export const ExperiencePreview = withExperiencePreviewContext(ExperiencePreviewWithoutContext)
