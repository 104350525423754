import { WebhookDTO, WebhookHeaderDTO } from '@ketch-com/figurehead'

export type CreateWebhookFormValues = {
  headers?: WebhookHeaderDTO[]
  name?: string
  url?: string
  testMode?: boolean
  events?: {
    subscriptions?: boolean
    rights?: boolean
    consent?: boolean
  }
}

type Args = {
  webhook?: WebhookDTO
  entitlements?: {
    subscriptions: boolean
    rights: boolean
    consent: boolean
  }
}

export const getInitialValues = ({ webhook, entitlements }: Args): CreateWebhookFormValues => {
  const isCreateMode = Object.keys(webhook || {}).length === 0
  if (!isCreateMode) {
    return {
      name: webhook?.name || '',
      url: webhook?.url || '',
      testMode: webhook?.testMode || false,
      events: {
        ...(entitlements?.subscriptions && {
          subscriptions: webhook?.events?.includes('subscriptions') || false,
        }),
        ...(entitlements?.rights && {
          rights: webhook?.events?.includes('rights') || false,
        }),
        ...(entitlements?.consent && {
          consent: webhook?.events?.includes('consent') || false,
        }),
      },
      headers: webhook?.headers,
    }
  }

  return {
    name: '',
    url: '',
    testMode: false,
    events: {
      ...(entitlements?.subscriptions && {
        subscriptions: false,
      }),
      ...(entitlements?.rights && {
        rights: false,
      }),
      ...(entitlements?.consent && {
        consent: false,
      }),
    },
    headers: [{ key: '', value: '' }],
  }
}
