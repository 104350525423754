import { StepDetailsDTO } from '@ketch-com/figurehead'

/**
 * Get string representation of identity verification paths.
 *
 * @param {StepDetailsDTO | null} stepDetails - Identity verification step details.
 * @returns {string} - 'verified' or '' based on decisionBoolean, which is returned from server as
 * a boolean. However, the "resolve" request requires a string value of 'verified' or 'not_verified', so, this
 * function converts the boolean to a string.
 */
export function getIdentityVerificationAvailablePathsStringValue(stepDetails?: StepDetailsDTO | null): string {
  // NOTE: there is no way for FE to distinguish between user affirmatively setting 'not_verified' and never having sent anything at all
  // due to inability of our BE to return falsy values.  This is why we return an empty string instead of 'not_verified'
  return stepDetails?.manualInterventionData?.decisionBoolean ? 'verified' : ''
}
