import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Grid } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { FieldArray, useFormikContext } from 'formik'

import { Text } from 'components/ui-kit/typography/Text'
import { ReactComponent as FiltersIcon } from 'assets/icons/filters.svg'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { Button } from '@ketch-com/deck'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { IconSpacer } from './IconSpacer'
import { ReactComponent as RemoveBadgeIcon } from 'assets/icons/negative-thin.svg'
import { PolicyFormValues } from 'interfaces/policies/PolicyFormValues'
import { STRING_FILTER_OPTIONS } from '../constants'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { useAutocompleteStyles } from '../hooks'
import { AutocompleteNameInput } from '.'

const PREFIX = 'FiltersFormSection'

const classes = {
  container: `${PREFIX}-container`,
  withBorderBottom: `${PREFIX}-withBorderBottom`,
  plusButton: `${PREFIX}-plusButton`,
  deleteSectionButton: `${PREFIX}-deleteSectionButton`,
  deleteButton: `${PREFIX}-deleteButton`,
  withMarginRight: `${PREFIX}-withMarginRight`,
  withPointer: `${PREFIX}-withPointer`,
  hidden: `${PREFIX}-hidden`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing, shadows } }) => ({
  [`&.${classes.container}`]: {
    backgroundColor: palette.white.main,
    borderRadius: spacing(1.5),
    boxShadow: shadows[1],
  },

  [`& .${classes.withBorderBottom}`]: {
    borderBottom: `1px solid ${palette.iron.main}`,
  },

  [`& .${classes.plusButton}`]: {
    borderRadius: '50%',
    height: spacing(3.5),
    width: spacing(3.5),
    padding: spacing(2),
    minWidth: 'unset',
  },

  [`& .${classes.deleteSectionButton}`]: {
    borderRadius: '50%',
    height: spacing(3.5),
    width: spacing(3.5),
    padding: spacing(2),
    minWidth: 'unset',
    fontSize: 18,
  },

  [`& .${classes.deleteButton}`]: {
    borderRadius: '50%',
    height: spacing(3.5),
    width: spacing(3.5),
    padding: spacing(2),
    fontSize: 16,
    minWidth: 'unset',
    color: 'black',
  },

  [`& .${classes.withMarginRight}`]: {
    marginRight: spacing(3),
  },

  [`& .${classes.withPointer}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.hidden}`]: {
    opacity: 0,
  },
}))

type Props = {
  onAddFilter: (event: React.MouseEvent) => void
  onRemoveFilter: (event: React.MouseEvent) => void
  onRemoveFiltersFormSection: (event: React.MouseEvent) => void
}

export const FiltersFormSection: React.FC<Props> = ({ onAddFilter, onRemoveFilter, onRemoveFiltersFormSection }) => {
  const autocompleteClasses = useAutocompleteStyles()
  const { setFieldValue } = useFormikContext<PolicyFormValues>()

  const handleAutocompleteNamedOnChange = (
    event: React.ChangeEvent<{}>,
    value: string[],
    typeEnum: number,
    index: number,
  ): void => {
    if (value?.length > 0) {
      setFieldValue(`filters[${index}].name`, value[0])
    } else {
      setFieldValue(`filters[${index}].name`, '')
    }
  }

  return (
    <StyledBox mt={2} display="flex" flexDirection="column" className={classes.container}>
      {/* Row 1 */}

      <Box display="flex" alignItems="center" justifyContent="space-between" p={3} className={classes.withBorderBottom}>
        <Box display="flex" alignItems="center">
          <Box mr={3}>
            <FiltersIcon />
          </Box>
          <Box mr={2}>
            <Text size={20} weight={700}>
              And the following filters:
            </Text>
          </Box>

          <Button id="addFilter" onClick={onAddFilter} className={classes.plusButton} color="tertiary">
            <AddIcon />
          </Button>
        </Box>
        <Box ml={2}>
          <Button
            id="addAccessConfig"
            onClick={onRemoveFiltersFormSection}
            color="primary"
            className={classes.deleteSectionButton}
          >
            <DeleteIcon fontSize="inherit" />
          </Button>
        </Box>
      </Box>

      {/* Row 2 */}

      <FieldArray name="filters">
        {({ form }) => {
          const { values, errors }: any = form
          const { filters } = values
          return (
            <Box pt={3} pb={1}>
              {filters.map((f: any, index: number) => (
                <Box
                  key={`${f}_${index}`}
                  display="flex"
                  alignItems="center"
                  px={3}
                  mb={index !== filters?.length - 1 ? 3 : 0}
                >
                  <IconSpacer />

                  <Grid key={index} container spacing={2} alignItems="flex-end">
                    <Grid item xs={4}>
                      {/* Name Input */}

                      {!filters[index].name && (
                        <AutocompleteNameInput
                          index={index}
                          handleAutocompleteNamedOnChange={handleAutocompleteNamedOnChange}
                          valueKey="filters"
                          labelName="Attribute Named"
                          placeholder="Select Attribute"
                        />
                      )}

                      {/* Name Input With Value */}

                      {filters[index].name && (
                        <Box>
                          <Label>Attribute Named</Label>
                          <Box display="flex" alignItems="center" className={autocompleteClasses.proxyInput}>
                            <Box display="flex" alignItems="center" className={autocompleteClasses.selectedValueTag}>
                              {filters[index].name}
                              <RemoveBadgeIcon
                                className={autocompleteClasses.removeSelectedValueBadgeIcon}
                                onClick={() => {
                                  setFieldValue(`filters[${index}].name`, '')
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}

                      {!errors?.filters?.[index]?.name && <Error className={classes.hidden}>hidden</Error>}
                    </Grid>

                    <Grid xs={1} item container direction="row" alignItems="flex-end" justifyContent="center">
                      <Box pb={0.5}>
                        <Text size={16} weight={700}>
                          value
                        </Text>
                      </Box>
                      <Error className={classes.hidden}>hidden</Error>
                    </Grid>

                    <Grid item xs={3}>
                      <FormDropdown
                        fullWidth
                        hasErrorSpacer
                        className={classes.withMarginRight}
                        label="Condition"
                        required
                        name={`filters[${index}].condition`}
                        placeholder="Select Condition"
                        items={STRING_FILTER_OPTIONS}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <FormInput
                        required
                        fullWidth
                        hasErrorSpacer
                        label="Attribute Value"
                        name={`filters[${index}].value`}
                        placeholder="Attribute"
                      />
                    </Grid>

                    <Grid item xs={1}>
                      {filters.length > 1 && (
                        <Box ml={2}>
                          <Button
                            id={index.toString()}
                            onClick={onRemoveFilter}
                            className={classes.deleteButton}
                            color="tertiary"
                          >
                            <DeleteIcon fontSize="inherit" />
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
          )
        }}
      </FieldArray>
    </StyledBox>
  )
}
