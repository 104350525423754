import { ICanvasStep } from '../../interfaces'
import { getStepType } from './getStepType'
import { CanvasStepType } from '../constants'

/**
 * Update all activity steps that point to oldStepId to point to newStepId
 */
export const updateAllTransitions = (steps: ICanvasStep[], oldStepId: string, newStepId: string) => {
  steps.forEach(step => {
    const stepType = getStepType(step)

    switch (stepType) {
      case CanvasStepType.Activity:
        if (step.activity?.transition === oldStepId) {
          step.activity!.transition = newStepId
        }
        break
    }
  })
}
