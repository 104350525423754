import { ActionSheetItem, DropListButton, ListItemText } from '@ketch-com/deck'
import { SxProps } from '@mui/material'
import { MaybeNull } from 'interfaces'
import React from 'react'
import { AppCode } from '../utils/enums'
import { appCodeLabels } from '../utils/labels'

type Props = {
  onChange: (value: MaybeNull<string>) => void
  value: string | null
  disabled?: boolean
  sx?: SxProps
}

const options = [AppCode.GoogleTagManager, AppCode.AdobeExperiencePlatform]

export const ProviderDropList: React.FC<Props> = ({ onChange, value, disabled, sx }) => {
  const currentValue = value || null
  return (
    <DropListButton
      value={currentValue}
      placeholder="Select Provider"
      label="Provider"
      disabled={disabled}
      options={options}
      onChange={(_, newValue) => {
        onChange(newValue)
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <ActionSheetItem key={option} selected={selected} {...props}>
            <ListItemText selected={selected}>{appCodeLabels[option]}</ListItemText>
          </ActionSheetItem>
        )
      }}
      getOptionLabel={option => appCodeLabels[option]}
      sx={sx}
    />
  )
}
