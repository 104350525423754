import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { useAuth } from 'utils/hooks/useAuth'
import { bulkUpdateWebTagPurposes } from '../fetchers/bulkUpdateWebTagPurposes'

const useCustomMutation = createUseMutation({
  mutationFn: bulkUpdateWebTagPurposes,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useBulkUpdateWebTagPurposes = (config?: Config) => {
  const { userData } = useAuth()
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    organizationCode: userData.organizationCode,
  })
}
