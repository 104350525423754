import React, { useMemo } from 'react'
import { ApiKeyRoleDTO } from 'interfaces/apiKeys/ApiKeyRoleDTO'
import { getApiKeyFormSectionRolesOptions } from '../utils'
import { useFormikContext } from 'formik'
import { ApiKeyFormValues } from 'interfaces/apiKeys'
import { useIsCurrentUserOrgAdmin } from 'utils/hooks/useIsCurrentUserOrgAdmin'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { DataGrid, FormRow } from '@ketch-com/deck'
import { DataGridRowsType, getApiKeyFormSectionColumns } from './utils'

type Props = {
  isEditMode?: boolean
  roles: ApiKeyRoleDTO[]
}

export const ApiKeyFormSectionRoles: React.FC<Props> = ({ roles }) => {
  const { isEntitled } = useIsEntitled()
  const apiKeyFormSectionRolesOptions = useMemo(
    () => getApiKeyFormSectionRolesOptions(roles, isEntitled),
    [roles, isEntitled],
  )
  const { values } = useFormikContext<ApiKeyFormValues>()
  const isCurrentUserOrgAdmin = useIsCurrentUserOrgAdmin()
  const rows: DataGridRowsType[] = isCurrentUserOrgAdmin
    ? Object.values(apiKeyFormSectionRolesOptions)
    : Object.values(apiKeyFormSectionRolesOptions).filter(o => o.displayName !== 'Organization')

  const columns = useMemo(
    () =>
      getApiKeyFormSectionColumns({
        isCurrentUserOrgAdmin: Boolean(isCurrentUserOrgAdmin),
        values,
      }),
    [isCurrentUserOrgAdmin, values],
  )

  return (
    <FormRow title="Roles Within Platform Features">
      <DataGrid
        autosizeOnMount
        columns={columns}
        rows={rows}
        disableChildrenSorting
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        disableRowHoverStates
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        getRowId={row => row.group}
        hideFooter
        hideFooterRowCount
      />
    </FormRow>
  )
}
