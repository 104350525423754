import React, { useContext } from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import {
  OrgPlanIDs,
  OrgPlanMaxUniquesText,
  OrgPlanNames,
} from 'components/planAndBilling/common/constants/planAndBilling'
import { Button, Icon, theme, Status, StatusVariant, StatusState } from '@ketch-com/deck'

const proPlanOptions = [
  {
    title: OrgPlanMaxUniquesText.pro,
    tooltip:
      'Uncapped number of unique visitors who will see the Ketch privacy experience on your website or mobile app each month.',
  },
  {
    title: 'Consent Management Platform (CMP)',
    tooltip:
      'Includes unlimited policy templates for privacy laws, customizable privacy experiences, website tracker scanning and blocking, and consent record storage.',
  },
  {
    title: 'Data Subject Rights (DSR) Automation',
    tooltip:
      'Extended feature set of our consent management platform with data subject rights automation via custom workflows.',
  },
  {
    title: 'System and App Integrations',
    tooltip: 'Select from 1000+ integrations including Google Analytics, HubSpot, MailChimp, and more.',
  },
  {
    title: 'Unlimited Support',
    tooltip:
      'Dedicated Ketch Customer Experience Manager to guide your implementation, product configurations, and support ongoing questions and requests.',
  },
  {
    title: 'Additional Product Access',
    tooltip:
      'Access to complete Ketch Data Permissioning Platform including Data Mapping, Data Discovery and Classification, Risk Assessments and Reporting, and Marketing Preference Management.',
  },
  {
    title: 'Developer Extensions',
    tooltip: 'Developer tools like custom identifiers, webhooks, authenticators and more to satisfy custom use cases.',
  },
]

export const Pro = () => {
  const { orgPlan } = useContext(PlanAndBillingContext)
  const isPlusPlanActive = orgPlan?.planProductID === OrgPlanIDs?.plus

  return (
    <Box
      flexBasis={0}
      flexGrow={1}
      pt={2}
      pb={4}
      sx={{
        border: `1px solid ${theme.palette.Common.Divider}`,
        borderRadius: 3,
      }}
    >
      <Box px={2}>
        <Box mb={2} display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="h3">{OrgPlanNames.pro}</Typography>
          <Typography noWrap variant="h4" color={theme.palette.Text.Secondary}>
            Custom Pricing
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="body">Ketch's enterprise solution covering all privacy program use cases.</Typography>
        </Box>

        {isPlusPlanActive ? (
          <Box
            sx={{
              '& > div': {
                display: 'flex',
                padding: '9px 8px',
                fontSize: '14px',
                width: '100%',
              },
            }}
          >
            <Status
              icon="FCheckRound"
              label="You are on Pro"
              variant={StatusVariant.outlined}
              status={StatusState.draft}
            />
          </Box>
        ) : (
          <Button
            fullWidth
            size="large"
            onClick={() => {
              window.open('https://www.ketch.com/request-pricing', '_blank', 'noopener,noreferrer')
            }}
            color="secondary"
          >
            Contact Sales
          </Button>
        )}
      </Box>

      <Box
        mt={2.5}
        pt={0.5}
        px={2}
        display="flex"
        flexDirection="column"
        sx={{
          borderTop: `1px solid ${theme.palette.Common.Divider}`,
        }}
      >
        {proPlanOptions.map((planOption, index) => (
          <Box key={index} py="6px" display="flex" justifyContent="space-between" alignItems="center" gap={0.5}>
            <Typography variant={index === 0 ? 'label' : 'body'} pr={!planOption.tooltip ? 3 : 0}>
              {planOption.title}
            </Typography>
            {planOption.tooltip && (
              <Tooltip title={planOption.tooltip} placement="bottom">
                <Box display="inline-flex">
                  <Icon name="OInfo" iconColor={theme.palette.Black.o32} />
                </Box>
              </Tooltip>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
