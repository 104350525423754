import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// types
import { DataRoleType, RightType } from 'interfaces/events'

interface EventsListFilterState {
  dataRole: number
  dataSubjectTypeCode: string
  rightCode: string
  rightType: number
  searchQuery: string
}

const initialState = {
  dataRole: DataRoleType.DATA_ROLE_CONTROLLER,
  dataSubjectTypeCode: '',
  rightCode: '',
  rightType: RightType.RIGHT_TYPE_UNSPECIFIED,
  searchQuery: '',
} as EventsListFilterState

export const eventsListFilterSlice = createSlice({
  name: 'eventsListFilterSlice',
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload
    },
    clearSearchQuery: state => {
      state.searchQuery = ''
    },
    setDataRole: (state, action: PayloadAction<number>) => {
      state.dataRole = action.payload
    },
    setRightTypeFilter: (state, action: PayloadAction<number>) => {
      state.rightType = action.payload
    },
    setRightCodeFilter: (state, action: PayloadAction<string>) => {
      state.rightCode = action.payload
    },
    setDataSubjectTypeCodeFilter: (state, action: PayloadAction<string>) => {
      state.dataSubjectTypeCode = action.payload
    },
    clearFilterState: state => ({
      ...initialState,
      dataRole: state.dataRole,
    }),
  },
})

export const {
  clearFilterState,
  clearSearchQuery,
  setDataRole,
  setDataSubjectTypeCodeFilter,
  setRightCodeFilter,
  setRightTypeFilter,
  setSearchQuery,
} = eventsListFilterSlice.actions

export default eventsListFilterSlice.reducer
