import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Button, Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { WorkflowDTO } from 'interfaces/workflows'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'

export const useWorkflowTabVersionsColumns = ({
  latestVersion,
  currentVersion,
  handleViewVersion,
}: {
  latestVersion?: string
  currentVersion?: string
  handleViewVersion: (w: WorkflowDTO) => void
}): GridColDef<WorkflowDTO>[] => {
  return [
    {
      align: 'left',
      field: 'status',
      headerAlign: 'left',
      headerName: 'Status',
      sortable: false,
      flex: 0.3,

      renderCell: ({ row: { version } }: GridRenderCellParams<WorkflowDTO>) => {
        return (
          <TableCell>
            {version === latestVersion && (
              <Status variant={StatusVariant.ghost} status={StatusState.success} icon="Dot" label="Latest" />
            )}
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'version',
      headerAlign: 'left',
      headerName: 'Version',
      sortable: false,
      flex: 0.5,

      renderCell: ({ row: { version } }: GridRenderCellParams<WorkflowDTO>) => {
        return (
          <TableCell>
            {version === latestVersion && (
              <Status variant={StatusVariant.outlined} status={StatusState.draft} label={`v${version}`} />
            )}
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'name',
      headerAlign: 'left',
      headerName: 'Name',
      sortable: false,
      flex: 1,
      renderCell: ({ row: { name, code } }: GridRenderCellParams<WorkflowDTO>) => {
        return <TableCell title={name} subTitle={code} />
      },
    },
    {
      align: 'left',
      field: 'updatedAt',
      headerAlign: 'left',
      headerName: 'Created',
      sortable: false,
      flex: 1,
      renderCell: ({ row: { createdAt } }: GridRenderCellParams<WorkflowDTO>) => {
        return <TableCell>{createdAt ? <FormattedDateRenderer date={createdAt} /> : <EmptyValueRenderer />}</TableCell>
      },
    },
    {
      align: 'left',
      field: 'actions',
      headerAlign: 'left',
      headerName: ' ',
      sortable: false,
      maxWidth: 100,
      flex: 1,
      renderCell: ({ row: workflowVersion }: GridRenderCellParams<WorkflowDTO>) => {
        return (
          <TableCell>
            <Button
              color="tertiary"
              disabled={workflowVersion.version === currentVersion}
              onClick={() => handleViewVersion(workflowVersion)}
            >
              View
            </Button>
          </TableCell>
        )
      },
    },
  ]
}
