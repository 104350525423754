import { CONTEXT_VARIABLE_TYPES } from 'pages/settings/contextVariables/constants'

export const getTypesString = (type: number): string => {
  switch (type) {
    case 1:
      return CONTEXT_VARIABLE_TYPES.LIST
    case 2:
      return CONTEXT_VARIABLE_TYPES.NUMBER
    case 3:
      return CONTEXT_VARIABLE_TYPES.BOOLEAN
    case 4:
      return CONTEXT_VARIABLE_TYPES.STRING

    default:
      return ''
  }
}
