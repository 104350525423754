import React from 'react'
import { Box } from '@mui/material'

import { Text } from 'components/ui-kit/typography/Text'

type Props = {
  hint?: string | null
}

export const DropdownHint: React.FC<Props> = ({ hint }) => {
  return !!hint ? (
    <Box display="flex" alignItems="center">
      <Text size={11} color="grey">
        {hint}
      </Text>
    </Box>
  ) : null
}
