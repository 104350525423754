import { ListItem } from '@ketch-com/deck'
import { Box, styled } from '@mui/material'
import React from 'react'
import { SidebarRouteItem } from 'utils/routing/types'
import { isArray } from 'lodash'
import { SidebarNestedItem } from './SidebarNestedItem'

const SidebarNestedItemsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '8px 0px 16px 22px',
})

const ItemsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

type Props = {
  items?: SidebarRouteItem[] | { [subRouteSection: string]: SidebarRouteItem[] }
}

export const SidebarNestedItems: React.FC<Props> = ({ items }) => {
  const hasSections = typeof items === 'object' && Object.entries(items).length && !isArray(items)

  if (!(hasSections || !!items?.length)) return null

  return (
    <SidebarNestedItemsBox>
      {hasSections ? (
        Object.entries(items as { [subRouteSection: string]: SidebarRouteItem[] }).map(([section, sectionItems]) => (
          <ItemsBox>
            <ListItem
              title={section}
              selected
              endIcon={<></>}
              sx={theme => ({
                marginRight: '6px',
                minHeight: '24px',
                cursor: 'default',
                background: 'none !important',
                '& .MuiTypography-root': {
                  fontWeight: '600 !important',
                  fontSize: '12px !important',
                  color: `${theme.palette.Text.Secondary} !important`,
                },
              })}
            />
            {sectionItems.map(item => (
              <SidebarNestedItem item={item} />
            ))}
          </ItemsBox>
        ))
      ) : (
        <ItemsBox>
          {(items as SidebarRouteItem[]).map(item => (
            <SidebarNestedItem item={item} key={item.title} />
          ))}
        </ItemsBox>
      )}
    </SidebarNestedItemsBox>
  )
}
