import { DeleteFormTemplateResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  formTemplateId?: string
}

export const deleteFormTemplate = ({ formTemplateId }: Params) =>
  API.delete<DeleteFormTemplateResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/form-templates/${formTemplateId}`,
    }),
  )
