import React, { ReactNode, useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import { InputAdornment, Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system/styleFunctionSx'
import { isFunction } from 'lodash'
import { Icon, ActionSheetItem, ListItemText, TextInput } from '@ketch-com/deck'
import { StyledOrchestrationAutocompletePaper } from 'pages/policyCenter/subscriptions/subscriptionTopic/upsert/components/Orchestration/utils'
import { ChipList, ChipData } from 'components/chipList/ChipList'

type SearchOption = {
  /** Unique id value of the option */
  id: string
  /** Display string for the option */
  label: string
  /** Optional description for the option */
  description?: string
}

type Props = {
  /** Options that can be searched and added to the chip list */
  options: SearchOption[]
  /** List of IDs which should be initially selected - i.e. in the chip list */
  selectedOptions?: string[]
  /** Component to render above the search field */
  title?: ReactNode
  /** String to display within the search field */
  inputPrompt?: string
  /** Boolean indicating if there is an error */
  showError?: boolean
  /** Error message, displayed below the search field */
  errorMsg?: string
  /** Function to call when an item from the search field is clicked */
  onSelect?: (selectedId: string) => void
  /** Function to call when an item from the chip list is deleted */
  onDeselect?: (deselectedId: string) => void
  /** Styles for the search field */
  inputSx?: SystemStyleObject<Theme>
  /** Styles for the container */
  containerSx?: SystemStyleObject<Theme>
}

/**
 * A auto-completing search field that populates a list of chips below it
 * whenever one is selected. The chips can be deleted from the list and will
 * appear back in the search field.
 */
export const SearchChipList: React.FC<Props> = ({
  options,
  selectedOptions,
  title,
  inputPrompt,
  showError,
  errorMsg,
  onSelect,
  onDeselect,
  inputSx,
  containerSx,
}) => {
  // Keep track of which chips are selected and not selected
  const [unselected, setUnselected] = useState<SearchOption[]>(options.filter(o => !selectedOptions?.includes(o.id)))
  const [selected, setSelected] = useState<ChipData[]>(
    selectedOptions?.map(key => ({
      key,
      label: options.find(o => o.id === key)?.label || '',
    })) || [],
  )

  const handleSelect = (event: React.SyntheticEvent, added: SearchOption[]) => {
    // Add a new element to chip list and remove from search dropdown
    if (added) {
      const newList: ChipData[] = [...added.map(v => ({ key: v.id, label: v.label }))]
      // Call provided chip add handler on each added chip (should just be one)
      if (isFunction(onSelect)) {
        newList.forEach(chip => onSelect(chip.key))
      }
      // Update component state
      setSelected(oldSelected => [...oldSelected, ...newList])
      setUnselected(oldNotSelected => oldNotSelected.filter(option => !added.some(v => v.id === option.id)))
    }
  }

  // Remove from chip list and add back to search dropdown
  const handleDeselect = (deletedChip: ChipData) => () => {
    // Call provided chip delete handler
    if (isFunction(onDeselect)) {
      onDeselect(deletedChip.key)
    }
    // Update component state
    setSelected(prevSelected => prevSelected.filter(chip => chip.key !== deletedChip.key))
    setUnselected(prevNotSelected => [
      ...prevNotSelected,
      {
        id: deletedChip.key,
        label: deletedChip.label,
      },
    ])
  }

  return (
    <Box display="flex" flexDirection="column" sx={containerSx}>
      {title}
      {/* Unselected items search list */}
      <Autocomplete
        options={unselected}
        multiple
        value={[]}
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        size="small"
        // Input field
        renderInput={params => (
          <TextInput
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Icon name="OMag" />
                </InputAdornment>
              ),
              endAdornment: null,
            }}
            placeholder={inputPrompt}
            size="medium"
            error={showError}
            errorMessage={errorMsg}
            variant="outlined"
          />
        )}
        // Option field
        renderOption={(props, value, { selected }) => {
          const uniqueIdentifier = `${value?.id || ''}`
          return (
            <ActionSheetItem selected={selected} {...props} key={uniqueIdentifier}>
              <ListItemText selected={selected}>
                <Box>
                  <Typography variant="body" lineHeight="18px">
                    {value.label}
                  </Typography>
                  <Typography display="block" variant="smallBody" lineHeight="18px" color="dark">
                    {value.description}
                  </Typography>
                </Box>
              </ListItemText>
            </ActionSheetItem>
          )
        }}
        PaperComponent={StyledOrchestrationAutocompletePaper}
        onChange={handleSelect}
        sx={{ width: 350, boxSizing: 'border-box' }}
      />

      {/* Selected items chip list */}
      <ChipList
        data={selected}
        onDelete={handleDeselect}
        containerSx={{ mt: selected.length > 0 ? '16px' : 0 }}
        deleteIcon={<Icon name={'OCross'} height={24} width={24} />}
        chipSize={'medium'}
      />
    </Box>
  )
}
