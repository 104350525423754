import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  code: string
}

export const deleteContextVariable = ({ code }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/context-variables/${code}`,
      params: {
        filters: {},
      },
    }),
  )
