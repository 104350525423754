import { ActionSheetItem, DropListButton, ListItemText } from '@ketch-com/deck'
import { SxProps } from '@mui/material'
import { useAppInstances } from 'api/apps/queries/useAppInstances'
import { MaybeNull } from 'interfaces'
import React from 'react'

type Props = {
  appCode?: string
  onChange: (value: MaybeNull<OptionType>) => void
  value: string | null
  disabled?: boolean
  sx?: SxProps
}

type OptionType = { label: string; value: string }

export const ConnectionDropList: React.FC<Props> = ({ appCode, onChange, value, disabled, sx }) => {
  // Get app instances
  const { data, isLoading } = useAppInstances({
    params: {
      appCode,
    },
  })

  // Build options
  const options: OptionType[] = data.map(instance => ({ label: instance.name, value: instance.ID }))
  const currentValue = value ? options.find(option => option.value === value) : null

  return (
    <DropListButton
      value={currentValue}
      placeholder="Select Connection"
      label="Connection"
      loading={isLoading}
      disabled={disabled}
      options={options}
      onChange={(event, newValue) => {
        onChange(newValue)
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <ActionSheetItem key={option.value} selected={selected} {...props}>
            <ListItemText selected={selected}>{option.label}</ListItemText>
          </ActionSheetItem>
        )
      }}
      sx={sx}
    />
  )
}
