import { useMemo } from 'react'
import * as Yup from 'yup'

import { useCheckIsControllerCodeAvailable } from 'api/controllers/mutations/useCheckIsControllerCodeAvailable'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'

export const useValidationSchema = ({ isEditMode }: { isEditMode?: boolean } = {}) => {
  const { mutateAsync: handleCheckIsControllerCodeAvailable } = useCheckIsControllerCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsControllerCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(
    () =>
      Yup.object().shape({
        ...(!isEditMode && {
          code: codeValidationSchema,
        }),
        name: Yup.string().max(75, 'Name must not exceed 75 characters').required('Required'),
      }),
    [isEditMode, codeValidationSchema],
  )
}
