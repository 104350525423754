import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import Box from '@mui/material/Box'
import { useCopyToClipboard } from 'react-use'

import { useNavigate } from 'react-router-dom'
import { MaybeNull } from 'interfaces/common'

import { ApiKeyDTO, ApiKeyRoleDTO } from 'interfaces/apiKeys'
import { ApiKeyViewActions } from './components/ApiKeyViewActions'
import { ApiKeyViewDetails } from './components/ApiKeyViewDetails'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { Button, ContentGroup, DataGrid, FormRow, Password, Spinner, TableCell } from '@ketch-com/deck'
import { ToggleApiKeyActivationStatusModal } from 'components/modals/apiKeys/ToggleApiKeyActivationStatusModal'
import { DeleteApiKeyModal } from 'components/modals/apiKeys/DeleteApiKeyModal'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import {
  parseRoleDisplayName,
  roleGroupDescriptions,
  roleGroupNames,
} from 'pages/settings/users/view/utils/getUserRolesDisplayData'
import { ROLE_GROUPS } from 'utils/constants/organizationEntitlements'

type Props = {
  isReady: boolean
  apiKey: MaybeNull<ApiKeyDTO>
}

type DataGridApiKeyRole = ApiKeyRoleDTO & { index: number }

export const ApiKeyView: React.FC<Props> = ({ isReady, apiKey }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false)
  const [, copyToClipboard] = useCopyToClipboard()

  const breadcrumbs = [
    { title: 'Developers', link: RoutesManager.developers.apiKeys.root.getURL() },
    { title: 'API Keys', link: RoutesManager.developers.apiKeys.root.getURL() },
    { title: apiKey?.name },
  ]

  const handleDeactivateApiKey = async () => {
    await queryClient.refetchQueries(ApiQueryKeys.apiKeysPaginated)
    navigate(RoutesManager.developers.apiKeys.root.getURL())
  }

  const rows: DataGridApiKeyRole[] = apiKey
    ? apiKey.roles
        .filter(role => Object.values(ROLE_GROUPS).includes(role.group))
        .map((role, index) => ({ ...role, index }))
    : []

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs} />
      {!isReady || !apiKey ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : (
        <>
          <Box display="flex" flexDirection="column" gap={4}>
            <ContentGroup
              variant="inner-page-header"
              isDivider
              title={apiKey.name}
              infoBlockComponent={<ApiKeyViewDetails apiKey={apiKey} />}
              showBackButton
              propsBackButton={{
                onClick: () => navigate(RoutesManager.developers.apiKeys.root.getURL()),
              }}
              actionRightBlockComponent={
                <ApiKeyViewActions
                  onRemoveClick={() => setIsRemoveModalOpen(true)}
                  onEditClick={() => navigate(RoutesManager.developers.apiKeys.upsert.getURL({ id: apiKey?.id }))}
                  onDeactivateClick={() => setIsDeactivateModalOpen(d => !d)}
                  apiKey={apiKey}
                />
              }
            />
            <Box display="flex" flexDirection="column">
              <FormRow title="View">
                <Box display="flex" alignItems="center" gap={2}>
                  <Box width="50%">
                    <Password showIcon value={apiKey.id} onChange={() => {}} fullWidth />
                  </Box>
                  <Button
                    color="secondary"
                    size="large"
                    onClick={() => {
                      setIsCopied(true)
                      copyToClipboard(apiKey.id)
                      setTimeout(() => {
                        setIsCopied(false)
                      }, 2000)
                    }}
                  >
                    {isCopied ? 'Copied!' : 'Copy'}
                  </Button>
                </Box>
              </FormRow>
              <FormRow title="Permissions Within App Sections">
                <DataGrid
                  autosizeOnMount
                  autosizeOptions={{
                    expand: true,
                    includeHeaders: true,
                    includeOutliers: false,
                  }}
                  getRowHeight={() => 'auto'}
                  columns={[
                    {
                      align: 'left',
                      field: 'name',
                      flex: 1,
                      headerAlign: 'left',
                      headerName: 'Section',
                      sortable: false,
                      renderCell: ({ row: { group } }: GridRenderCellParams<DataGridApiKeyRole>) => {
                        return <TableCell title={`${roleGroupNames[group]}`} subTitle={roleGroupDescriptions[group]} />
                      },
                    },
                    {
                      align: 'left',
                      field: 'input',
                      flex: 1,
                      headerAlign: 'left',
                      headerName: 'Permission',
                      sortable: false,
                      renderCell: ({ row: { name } }: GridRenderCellParams<DataGridApiKeyRole>) => {
                        return <TableCell title={parseRoleDisplayName(name)} />
                      },
                    },
                  ]}
                  disableChildrenSorting
                  disableColumnMenu
                  disableColumnPinning
                  disableColumnResize
                  disableColumnReorder
                  disableRowSelectionOnClick
                  disableRowHoverStates
                  getRowId={row => row.index}
                  rows={rows}
                  hideFooter
                  noRowsOverlayProps={{
                    buttonTitle: '',
                    subTitle: '',
                  }}
                />
              </FormRow>
            </Box>
          </Box>
          {isRemoveModalOpen && (
            <DeleteApiKeyModal
              apiKeyId={apiKey.id!}
              onCancel={() => setIsRemoveModalOpen(false)}
              onSubmit={async () => {
                await queryClient.refetchQueries(ApiQueryKeys.apiKeysPaginated)
                navigate(RoutesManager.developers.apiKeys.root.getURL())
              }}
            />
          )}

          {isDeactivateModalOpen && (
            <ToggleApiKeyActivationStatusModal
              apiKeyId={apiKey.id!}
              onCancel={() => setIsDeactivateModalOpen(false)}
              onSubmit={handleDeactivateApiKey}
            />
          )}
        </>
      )}
    </>
  )
}
