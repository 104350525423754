import React from 'react'

type UseForceRerenderToAdjustRowHeightArgs = {
  isLoadingReferences?: boolean
}
export const useForceRerenderToAdjustRowHeight = ({ isLoadingReferences }: UseForceRerenderToAdjustRowHeightArgs) => {
  const [, updateState] = React.useState<number>(0)
  const forceUpdate = React.useCallback(() => updateState(1), [])
  React.useEffect(() => {
    if (!isLoadingReferences) {
      forceUpdate()
    }
  }, [isLoadingReferences, forceUpdate])
}
