import React from 'react'
import { get, take } from 'lodash'
import { Box, Typography } from '@mui/material'
import { Button, Chip } from '@ketch-com/deck'
import { RegionDTO } from 'interfaces/regions/region'
import { RegulationDTO } from 'interfaces/regulations/regulation'
import { IPlainRegion, IRegionFE } from 'interfaces'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { getRegulationNameByCode } from 'utils/helpers/regulations'
import { ViewAssociatedRegions } from 'components/modals/policyScopes/ViewAssociatedRegions'
import { addRegionToPlainList, transformRegionsForFeTree } from 'pages/policyCenter/policyScopes/utils'
import { PolicyScopesMapNew } from '../../components/map/PolicyScopesMapSingle'

const initialViewState = {
  latitude: 45,
  longitude: 0,
  zoom: 0,
  bearing: 0,
  pitch: 0,
}

const REGIONS_COUNT_TO_PREVIEW = 6

interface IPolicyScopeBasicDetailsProps {
  policyScope: PolicyScopeDTO
  regulations: RegulationDTO[]
  regionGroups: RegionDTO[]
}

const getRegionNameByCode = (regionCode: string, plainRegionsList: IPlainRegion[]) => {
  const currentRegion = plainRegionsList.find(({ code }) => code === regionCode)

  return get(currentRegion, 'name') || ''
}

export const PolicyScopeBasicDetails: React.FC<IPolicyScopeBasicDetailsProps> = ({
  policyScope,
  regulations = [],
  regionGroups = [],
}) => {
  const { name: policyScopeName, regulationCodes = [], regionCodes = [] } = policyScope
  const regionsCount = regionCodes.length
  const [isAllRegionsShown, setIsAllRegionsShown] = React.useState(false)
  const shouldShowViewAllRegions = regionsCount > REGIONS_COUNT_TO_PREVIEW
  const regionsForMap = regionCodes.map(code => ({
    id: code,
    value: 0,
    policyScopeName,
    itemsInScopeCount: regionsCount,
  }))

  const regionsHierarchyTransformed: IRegionFE[] = []
  const plainRegionsList: IPlainRegion[] = []

  regionGroups?.forEach?.(region => transformRegionsForFeTree(region, regionsHierarchyTransformed))
  regionsHierarchyTransformed?.forEach?.(region => addRegionToPlainList(region, plainRegionsList))

  const regionNames = regionCodes.map(regionCode => getRegionNameByCode(regionCode, plainRegionsList))
  const regionNamesSorted = regionNames.sort()
  const firstNRegions = take(regionNamesSorted, REGIONS_COUNT_TO_PREVIEW)

  return (
    <Box>
      <Box display="flex" px={2} mb={1}>
        {/* Left Col */}

        <Box display="flex" flex={1} flexDirection="column">
          <Typography variant="h3" mb="29px">
            Basic Details
          </Typography>
          <Box display="flex" sx={{ minHeight: 250 }}>
            {/* Regulations Col */}

            <Box flex={1} flexDirection="column" display="flex">
              <Typography variant="smallLabel" mb="14px">
                Regulations
              </Typography>

              <Box display="inline-flex" flexWrap="wrap" alignItems="center" gap={1}>
                {regulationCodes.length ? (
                  regulationCodes.map((regulationCode, index) => (
                    <Chip key={index} label={getRegulationNameByCode(regulationCode, regulations)} size="medium" />
                  ))
                ) : (
                  <Typography color={theme => theme.palette.lightGrey.main}>None</Typography>
                )}
              </Box>
            </Box>

            {/* Regions Col */}

            <Box sx={{ minWidth: '90px', marginRight: '21px' }} flex={1} display="flex" flexDirection="column">
              <Typography variant="smallLabel" mb="14px">
                Regions <span style={{ color: '#858585', fontWeight: 'normal' }}>{regionsCount}</span>
              </Typography>
              <Box display="flex" flexDirection="column">
                {firstNRegions.map(region => (
                  <Typography variant="body" key={region}>
                    {region}
                  </Typography>
                ))}
              </Box>

              <Box mt={1}>
                {shouldShowViewAllRegions && (
                  <Button color="tertiary" onClick={() => setIsAllRegionsShown(true)}>
                    View all
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Right Col */}

        <Box display="flex" flex={0.85} flexDirection="column" sx={{ height: 350, pr: 2 }}>
          <PolicyScopesMapNew regions={regionsForMap} initialViewState={initialViewState} />
        </Box>
      </Box>
      {isAllRegionsShown && (
        <ViewAssociatedRegions regionNames={regionNamesSorted} onCancel={() => setIsAllRegionsShown(false)} />
      )}
    </Box>
  )
}
