import React from 'react'

import { Box, Typography } from '@mui/material'
import { Button, PopUp } from '@ketch-com/deck'
import { useCopyToClipboard } from 'react-use'

type Props = {
  title?: string
  subtitle?: string
  headers?: string
  body?: string
  onCancel: () => void
}

export const APICallDetailsModal: React.FC<Props> = ({ title, subtitle, headers, body, onCancel }) => {
  const [, copyToClipboard] = useCopyToClipboard()

  let prettyPrintedBody: string
  try {
    prettyPrintedBody = JSON.stringify(JSON.parse(body || ''), null, 2)
  } catch {
    prettyPrintedBody = body || 'No details available.'
  }

  let bodyObject: any
  try {
    bodyObject = JSON.parse(body || '{}')
  } catch {
    bodyObject = {}
  }

  const isLocalhost = window.location.hostname === 'localhost'
  return (
    <>
      <PopUp
        isOpen
        title={title || ' '}
        popUpWidth="750px"
        variant="modal"
        onClose={() => onCancel()}
        popUpActionChildren={
          <Button
            color="secondary"
            size="large"
            onClick={e => {
              onCancel()
            }}
          >
            Close
          </Button>
        }
      >
        {/* NOTE: Developer utility for easy copy/paste of API Call uid and auth token. Only renders when on `localhost` */}
        <Box alignItems="center" mb={3} display={isLocalhost ? 'flex' : 'none'}>
          <Button
            sx={{ mr: 1 }}
            color="tertiary"
            onClick={() => {
              copyToClipboard(bodyObject?.metadata?.uid || 'nothing-to-copy')
            }}
          >
            Copy UID
          </Button>

          <Button
            color="tertiary"
            onClick={() => {
              copyToClipboard(bodyObject?.request?.callbacks?.[0]?.headers?.Authorization || 'nothing-to-copy')
            }}
          >
            Copy Auth Token
          </Button>
        </Box>

        {/* Subtitle description  */}
        {subtitle && <Typography variant="label">{subtitle}</Typography>}

        {/* Headers and Body with formatting  */}
        <Box width="fit-content" sx={{ p: 2, fontFamily: 'Courier', backgroundColor: '#F6F8FA', color: '#2A51A4' }}>
          {headers && (
            <>
              <Box>{headers}</Box>
              <Box>&nbsp;</Box>
            </>
          )}
          <Box width="fit-content">
            <pre style={{ margin: 0 }}>{prettyPrintedBody}</pre>
          </Box>
        </Box>
      </PopUp>
    </>
  )
}
