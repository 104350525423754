import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

import { Flex } from 'components/ui-layouts/flex/Flex'
import { UpsertExperiencePreviewText } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewText'

const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        height: 300,
        backgroundColor: theme.palette.fadedGrey.main,
        borderRadius: '4px 4px 3px 3px',
        width: '100%',
      },
      text: {
        color: '#9e9e9e',
      },
    }),
  { name: 'UpsertExperiencePreviewPlaceholder' },
)

type Props = {
  children?: React.ReactNode
  className?: string
}

export const UpsertExperiencePreviewPlaceholder: React.FC<Props> = ({ className, children }) => {
  const classes = useStyles()

  return (
    <Flex className={clsx(classes.root, className)} justifyContent="center" alignItems="center">
      <UpsertExperiencePreviewText className={classes.text}>{children}</UpsertExperiencePreviewText>
    </Flex>
  )
}
