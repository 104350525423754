import React from 'react'
import clsx from 'clsx'
import { includes, isUndefined } from 'lodash'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'

import { WorkflowActivityCode, WorkflowActivityConnectorCode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'

import EndFlowSVG from 'assets/icons/workflow/activities/endFlowActivity.svg'
import { SxProps, Theme, Typography, styled } from '@mui/material'
import { theme } from '@ketch-com/deck'

const StyledStepIcon = styled(Box)(() => ({
  '&.StepIcon': {
    flexShrink: 0,
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const CustomIconWrapper = styled(Box)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 50,
  height: 50,
  '&.TypeGateway': {
    background: '#222222',
    borderRadius: 11,
  },
  '&.TypeActivity': {
    background: '#9C64FF',
    borderRadius: 11,
  },
  '&.TypeApp': {
    background: '#F0F1F5',
    borderRadius: 11,
  },
  '&.TypeEndFlow': {
    background: 'transparent',
    borderRadius: 0,

    '& .StepIconSvg': {
      width: 50,
      height: 50,
    },
  },
  '&.DsrActivity': {
    background: '#9C64FF',
    borderRadius: 11,
  },
}))

type Props = {
  step: ICanvasStep
  stepName?: string
  className?: any
  notDraggableTooltipText?: string
  draggable?: boolean
  onDragStart?: () => void
  onDragEnd?: () => void
  sx?: SxProps<Theme>
}

export const StepIcon: React.FC<Props> = ({
  step,
  stepName,
  className,
  draggable = false,
  onDragStart,
  onDragEnd,
  notDraggableTooltipText = '',
  sx,
}) => {
  const isEndFlow =
    !isUndefined(step?.finish) && step.workflow?.temporalFunctionName !== WorkflowActivityConnectorCode.END
  const isEndFlowV2 = step.workflow?.temporalFunctionName === WorkflowActivityConnectorCode.END
  const isGateway = !isUndefined(step?.gateway)
  const isActivity = !isUndefined(step?.activity) || !isUndefined(step?.workflow)
  const isApp = isActivity && !includes(Object.values(WorkflowActivityCode), step.activity?.code)
  const iconURL = isEndFlow ? EndFlowSVG : step?.iconURL

  return (
    <StyledStepIcon className={clsx('StepIcon', className)} sx={sx}>
      <Tooltip
        title={notDraggableTooltipText}
        componentsProps={{ tooltip: { sx: { background: 'black', fontSize: 14, color: 'white', padding: 1 } } }}
      >
        <CustomIconWrapper
          className={clsx(className, {
            TypeGateway: isGateway,
            TypeActivity: isActivity && !isApp,
            TypeApp: isApp,
            TypeEndFlow: isEndFlow || isEndFlowV2,
            DsrActivity:
              step.activityCode === WorkflowActivityCode.DSR_TRANSPONDER ||
              step.iconCode === WorkflowActivityCode.DSR_TRANSPONDER ||
              step.activityCode === WorkflowActivityCode.INTERNAL_SYSTEM_DSR ||
              step.iconCode === WorkflowActivityCode.INTERNAL_SYSTEM_DSR ||
              step.activityCode === WorkflowActivityCode.IMPORT_CONTEXT ||
              step.iconCode === WorkflowActivityCode.IMPORT_CONTEXT ||
              step.workflow?.temporalFunctionName === WorkflowActivityConnectorCode.API_CALL_V2 ||
              step.workflow?.temporalFunctionName === WorkflowActivityConnectorCode.DATA_REVIEW,
          })}
          draggable={draggable}
          onDragStart={onDragStart}
          onDrag={() => false}
          onDragEnd={onDragEnd}
          sx={{
            cursor: draggable ? 'grab' : 'not-allowed',
            opacity: '0.999',
          }}
        >
          <img draggable={false} src={iconURL} alt={stepName} height={36} width={36} className="StepIconSvg" />
        </CustomIconWrapper>
      </Tooltip>

      {stepName && (
        <Typography
          variant="footnote"
          color={theme.palette.darkDusk.main}
          sx={{
            my: 0.75,
          }}
        >
          {stepName}
        </Typography>
      )}
    </StyledStepIcon>
  )
}
