import React from 'react'
import { Box, Tooltip } from '@mui/material'
import { Icon, theme } from '@ketch-com/deck'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { getApplicationEntityName } from 'utils/messages/entityName'

export const getTooltipContent = (entityType: ApplicationEntity) => {
  switch (entityType) {
    case ApplicationEntity.PERMIT_PROPAGATION:
      return 'Purposes for this property have not been configured yet.'
    case ApplicationEntity.CONSENT_PERMIT_PROPAGATION:
      return 'Purposes for this system have not been configured yet.'
    case ApplicationEntity.DPIA:
      return 'Ketch recommends you conduct a DPIA given the canonical purpose chosen for this purpose.'
    default: {
      const entityName = getApplicationEntityName({ entityType })
      return `${entityName.singular} configurations need to be updated`
    }
  }
}

type Props = {
  className?: string
  hasIssues?: boolean
  entityType: ApplicationEntity
}

export const IssuesCellRenderer: React.FC<Props> = ({ className, hasIssues = false, entityType }) => {
  if (!hasIssues) {
    return null
  }

  return (
    <Box className={className} display="flex" justifyContent="center" alignItems="center">
      <Tooltip title={getTooltipContent(entityType)}>
        <span>
          <Icon name="FImportant" iconColor={theme.palette.chileanFire.main} />
        </span>
      </Tooltip>
    </Box>
  )
}
