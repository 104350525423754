import React from 'react'
import { Box, TooltipProps, Typography } from '@mui/material'
import { EmptyValueRendererV2 } from 'components/ui-kit/typography/compositions/EmptyValueRendererV2'
import { TooltipV2 } from 'components/ui-kit/tooltip/TooltipV2'
import { Theme } from '@mui/material/styles'
import { styled } from '@mui/system'

interface TimesUsedContainerBoxProps {
  timesUsed: number
  theme?: Theme
}

export const TimesUsedContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'timesUsed',
})<TimesUsedContainerBoxProps>(({ theme, timesUsed }: { theme?: Theme; timesUsed: number }) => ({
  backgroundColor: theme?.palette?.marine?.main,
  borderRadius: timesUsed <= 9 ? '50%' : '100px',
  padding: '10px 8px',
  height: '24px',
  width: timesUsed <= 9 ? '24px' : undefined,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

type Props = {
  timesUsed?: number
  tooltipTitle?: React.ReactNode
  emptyValueDisplayText?: React.ReactNode
  placement?: TooltipProps['placement']
}

export const TimesUsedCellRenderer: React.FC<Props> = ({
  timesUsed = 0,
  tooltipTitle = null,
  emptyValueDisplayText,
  placement = 'top',
}) => {
  if (timesUsed === 0)
    return emptyValueDisplayText ? (
      <EmptyValueRendererV2>{emptyValueDisplayText}</EmptyValueRendererV2>
    ) : (
      <EmptyValueRendererV2 />
    )

  return (
    <TooltipV2 arrow={true} title={timesUsed > 0 ? tooltipTitle : ''} placement={placement}>
      <TimesUsedContainer timesUsed={timesUsed}>
        <Typography variant="footnote" color="persianBlue.main" lineHeight="0px">
          {timesUsed}
        </Typography>
      </TimesUsedContainer>
    </TooltipV2>
  )
}
