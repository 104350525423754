import {
  WorkflowActivity,
  WorkflowActivityCategory,
  WorkflowActivityGatewayMode,
  WorkflowActivityCode,
  WorkflowActivityConnectorCode,
} from 'interfaces/workflowActivities/workflowActivity'
import { IWorkflowItems } from 'pages/orchestration/workflows/edit/interfaces'

import { getActivityParamsByType, getWorkflowActivityParamsByType } from '.'

export const transformSidebarEntities = (
  workflowActivities: WorkflowActivity[],
  canonicalRightCode: string,
): IWorkflowItems => {
  const sidebarItems: IWorkflowItems = {
    gateways: [],
    activities: [],
    connectors: [],
    apps: [],
    appslvl2: [],
  }

  workflowActivities?.forEach?.(workflowActivity => {
    const { code, name, icon, category, gateway, workflow, activity } = workflowActivity
    const iconURL = `${icon?.URL}`

    switch (category) {
      case WorkflowActivityCategory.GATEWAY: {
        const { mode } = gateway!

        const getEmptyTransition = (params?: { name: string }) => ({
          ID: undefined,
          variable: '',
          operator: '',
          operand: '',
          name: params?.name || '',
        })

        sidebarItems.gateways.push({
          name,
          code: '',
          activityCode: code,
          description: '',
          iconCode: `gateway_${mode}`,
          iconURL,
          valid: code === 'join' ? true : false,
          gateway: {
            code,
            mode,
            params: {},
            transitions:
              mode === WorkflowActivityGatewayMode.JOIN
                ? [getEmptyTransition()]
                : [
                    getEmptyTransition({
                      name: mode === WorkflowActivityGatewayMode.DECISION_BOOLEAN ? 'Positive' : 'Path 1',
                    }),
                    getEmptyTransition({
                      name: mode === WorkflowActivityGatewayMode.DECISION_BOOLEAN ? 'Negative' : 'Path 2',
                    }),
                  ],
          },
        })

        break
      }

      case WorkflowActivityCategory.STANDARD: {
        const baseFields = {
          name,
          code: '',
          activityCode: code,
          description: '',
          iconCode: code,
          iconURL,
          valid: true,
        }

        if (workflow) {
          const { temporalFunctionName, options, outputs } = workflow

          sidebarItems.activities.push({
            ...baseFields,
            workflow: {
              code: code as WorkflowActivityConnectorCode,
              transition: undefined,
              temporalFunctionName: temporalFunctionName || '',
              params: getWorkflowActivityParamsByType(code as WorkflowActivityConnectorCode),
              options: options || {},
              outputs: outputs || [],
            },
          })
        }

        if (activity) {
          const { temporalFunctionName, options, outputs } = activity

          sidebarItems.activities.push({
            ...baseFields,
            activity: {
              code: code as WorkflowActivityCode,
              transition: undefined,
              temporalFunctionName: temporalFunctionName || '',
              params: getActivityParamsByType(code as WorkflowActivityCode),
              options: options || {},
              outputs: outputs || [],
            },
          })
        }

        break
      }

      case WorkflowActivityCategory.DSR: {
        if (code === 'internalSystemDSR' && (canonicalRightCode === 'update' || canonicalRightCode === 'restrict'))
          // internalSystemDSR doesn't support update and restrict currently.
          break
        const baseFields = {
          name,
          code: '',
          activityCode: code,
          description: '',
          iconCode: code,
          iconURL,
          valid: true,
        }

        if (workflow) {
          const { temporalFunctionName, options, outputs } = workflow

          sidebarItems.activities.push({
            ...baseFields,
            workflow: {
              code: code as WorkflowActivityConnectorCode,
              transition: undefined,
              temporalFunctionName: temporalFunctionName || '',
              params: getWorkflowActivityParamsByType(code as WorkflowActivityConnectorCode),
              options: options || {},
              outputs: outputs || [],
            },
          })
        }

        if (activity) {
          const { temporalFunctionName, options, outputs } = activity

          sidebarItems.activities.push({
            ...baseFields,
            activity: {
              code: code as WorkflowActivityCode,
              transition: undefined,
              temporalFunctionName: temporalFunctionName || '',
              params: getActivityParamsByType(code as WorkflowActivityCode),
              options: options || {},
              outputs: outputs || [],
            },
          })
        }

        break
      }

      case WorkflowActivityCategory.CONNECTOR: {
        const baseFields = {
          name,
          code: '',
          activityCode: code,
          description: '',
          iconCode: code,
          iconURL,
          valid: true,
        }

        if (workflow) {
          const { temporalFunctionName, options, outputs } = workflow

          sidebarItems.connectors.push({
            ...baseFields,
            workflow: {
              code: code as WorkflowActivityConnectorCode,
              transition: undefined,
              temporalFunctionName: temporalFunctionName || '',
              params: getWorkflowActivityParamsByType(code as WorkflowActivityConnectorCode),
              options: options || {},
              outputs: outputs || [],
            },
          })
        }

        if (activity) {
          const { temporalFunctionName, options, outputs } = activity

          sidebarItems.connectors.push({
            ...baseFields,
            activity: {
              code: code as WorkflowActivityCode,
              transition: undefined,
              temporalFunctionName: temporalFunctionName || '',
              params: getActivityParamsByType(code as WorkflowActivityCode),
              options: options || {},
              outputs: outputs || [],
            },
          })
        }

        break
      }

      case WorkflowActivityCategory.APP: {
        const baseFields = {
          name,
          code: '',
          activityCode: code,
          description: '',
          iconCode: code,
          iconURL,
          valid: true,
        }

        if (workflow) {
          const { temporalFunctionName, config, options, outputs } = workflow

          sidebarItems.apps.push({
            ...baseFields,
            workflow: {
              code: code as WorkflowActivityConnectorCode,
              transition: undefined,
              temporalFunctionName: temporalFunctionName || '',
              // TODO - for "params" - currently only pastes over the info from activity definition config.
              // In future, may need additional user inputted params passed through as well
              params: config || {},
              options: options || {},
              outputs: outputs || [],
            },
          })
        }

        if (activity) {
          const { temporalFunctionName, config, options, outputs } = activity

          sidebarItems.apps.push({
            ...baseFields,
            activity: {
              code: code as WorkflowActivityCode,
              transition: undefined,
              temporalFunctionName: temporalFunctionName || '',
              // TODO - for "params" - currently only pastes over the info from activity definition config.
              // In future, may need additional user inputted params passed through as well
              params: config || {},
              options: options || {},
              outputs: outputs || [],
            },
          })
        }

        break
      }

      case WorkflowActivityCategory.APP_LEVEL_2: {
        const baseFields = {
          name,
          code: '',
          activityCode: code,
          description: '',
          iconCode: code,
          iconURL,
          valid: true,
        }

        if (workflow) {
          const { temporalFunctionName, config, options, outputs } = workflow

          sidebarItems.appslvl2.push({
            ...baseFields,
            workflow: {
              code: code as WorkflowActivityConnectorCode,
              transition: undefined,
              temporalFunctionName: temporalFunctionName || '',
              // TODO - for "params" - currently only pastes over the info from activity definition config.
              // In future, may need additional user inputted params passed through as well
              params: config || {},
              options: options || {},
              outputs: outputs || [],
            },
          })
        }

        if (activity) {
          const { temporalFunctionName, config, options, outputs } = activity

          sidebarItems.appslvl2.push({
            ...baseFields,
            activity: {
              code: code as WorkflowActivityCode,
              transition: undefined,
              temporalFunctionName: temporalFunctionName || '',
              // TODO - for "params" - currently only pastes over the info from activity definition config.
              // In future, may need additional user inputted params passed through as well
              params: config || {},
              options: options || {},
              outputs: outputs || [],
            },
          })
        }
      }
    }
  })

  return sidebarItems
}
