import React, { useState } from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import { CircularProgress, Popover, SxProps, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { MaybeNull } from 'interfaces/common'
import { List, ListItemType as BaseListItemType } from 'components/ui-kit/list/List'

type UseStylesProps = {
  popoverHasMinWidth?: boolean
  minWidthOverride?: number | string
}

const useStyles = makeStyles<Theme, UseStylesProps>(
  ({ typography, palette }) =>
    createStyles({
      baseDropdown: {
        display: 'inline-flex',
        alignItems: 'center',
        fontFamily: typography.fontFamily,
        borderRadius: 5,
        letterSpacing: '-0.01em',
        color: palette.darkDusk.main,
        background: palette.fadedGrey.main,
        border: `1px solid ${palette.iron.main}`,

        '& $progressIcon': {
          color: palette.darkDusk.main,
        },

        '&:not($disabled)': {
          '&:hover': {
            color: palette.royalBlue.main,
            border: `1px solid ${palette.iron.main}`,
            cursor: 'pointer',

            '& $baseContent': {
              color: palette.royalBlue.main,
            },
          },

          '&:active': {
            color: palette.persianBlue.main,
            cursor: 'pointer',
            border: `1px solid ${palette.persianBlue.main}`,
          },
        },
      },
      baseContent: {
        flexGrow: 1,
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '100%',
        overflow: 'hidden',
      },
      baseIcon: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      baseHint: {
        color: `${palette.darkDuskFaded.main} !important`,
      },
      progressIcon: {},
      chevronIcon: {
        color: palette.darkDusk.main,
      },

      // Popover
      popoverRoot: {
        marginTop: 5,
        borderRadius: 5,
      },
      popover: {
        borderRadius: 5,
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.2)',
        minWidth: hasMinWidth => (hasMinWidth ? 170 : 0),
      },

      // Sizes:
      sizeSmall: {
        padding: '0 10px',
        lineHeight: typography.pxToRem(14),
        fontSize: typography.pxToRem(12),
        fontWeight: 600,
        minWidth: ({ minWidthOverride }) => minWidthOverride || 60,
        height: 28,

        '& $baseHint': {
          fontSize: typography.pxToRem(10),
        },

        '& $baseContent': {
          marginRight: 6,
        },

        '& $progressIcon': {
          marginRight: 3,
        },

        '& $chevronIcon': {
          marginTop: 2,
          fontSize: typography.pxToRem(24),
        },
      },
      sizeRegular: {
        padding: '0 18px',
        lineHeight: typography.pxToRem(18),
        fontSize: typography.pxToRem(14),
        fontWeight: 600,
        minWidth: ({ minWidthOverride }) => minWidthOverride || 80,
        height: 44,

        '& $baseHint': {
          fontSize: typography.pxToRem(11),
        },

        '& $baseContent': {
          marginRight: 8,
        },

        '& $progressIcon': {
          marginRight: 4,
        },

        '& $chevronIcon': {
          marginTop: 3,
          fontSize: typography.pxToRem(24),
        },
      },

      // States:
      emptyValue: {
        '& $baseContent': {
          color: palette.darkDuskFaded.main,
        },
      },
      invalid: {
        borderColor: `${palette.chileanFire.main} !important`,
      },
      disabled: {
        opacity: 0.3,
        cursor: 'not-allowed',
      },
      pending: {
        color: palette.fadedDarkGrey.main,
        border: `1px solid ${palette.persianBlue.main}`,

        '& $progressIcon': {
          color: palette.persianBlue.main,
        },
        '& $chevronIcon': {
          color: palette.persianBlue.main,
        },
      },
      open: {
        color: palette.persianBlue.main,
        cursor: 'pointer',
        border: `1px solid ${palette.persianBlue.main}`,
      },
      isFormFieldPreview: {
        borderColor: palette.doomedGrey.main,
      },
    }),
  { name: 'Dropdown' },
)

export interface Props {
  children?: React.ReactNode
  /** Checks if the component should be disabled */
  disabled?: boolean
  /** Checks if the component should be in loading state */
  pending?: boolean
  /** Component size - reflects the size of the dropdown */
  size?: 'regular' | 'small'
  /** Component custom className */
  className?: string
  /** Component items list to be displayed in popover */
  items: BaseListItemType[]
  /** Component value to indicate non-empty state */
  value: MaybeNull<any>
  /** Component placeholder */
  placeholder?: string
  /** Component value hint */
  hint?: MaybeNull<string>
  /** Component Valid state */
  valid?: boolean
  /** Component open handler */
  onOpen?: () => any
  /** Component close handler */
  onClose?: () => any
  /** Ignores typical minimum width **/
  popoverHasMinWidth?: boolean
  /** name to pass to data-testid */
  name?: string
  /** Is Experience Builder Preview */
  isFormFieldPreview?: boolean
  /** Override empty value should be dark bold text */
  isEmptyValueDarkBlackText?: boolean
  /** Container SX */
  containerSx?: SxProps<Theme>
  /** Override min width */
  minWidthOverride?: number | string
}

export type DropdownProps = Props

/**
 * -
 */
export const Dropdown: React.FC<Props> = ({
  disabled = false,
  pending = false,
  size = 'regular',
  className,
  children,
  items,
  value,
  placeholder,
  hint,
  valid = true,
  onOpen,
  containerSx,
  onClose,
  popoverHasMinWidth = true,
  minWidthOverride,
  isFormFieldPreview = false,
  isEmptyValueDarkBlackText = false,
  name,
}) => {
  const classes = useStyles({ popoverHasMinWidth, minWidthOverride })
  const [anchorEl, setAnchorEl] = useState<MaybeNull<HTMLDivElement>>(null)
  const isOpen = Boolean(anchorEl)
  const popoverId = isOpen ? 'dropdown-actions-popover' : undefined

  const dropdownClassName = clsx(
    classes.baseDropdown,
    {
      [classes.sizeSmall]: size === 'small',
      [classes.sizeRegular]: size === 'regular',

      [classes.emptyValue]: isEmptyValueDarkBlackText ? false : !value,
      [classes.open]: isOpen,
      [classes.pending]: pending,
      [classes.disabled]: disabled,
      [classes.invalid]: !valid,
      [classes.isFormFieldPreview]: isFormFieldPreview,
    },
    className,
  )

  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
    onOpen?.()
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        data-testid={name}
        className={dropdownClassName}
        {...(!disabled && !pending && { onClick: handleOpen })}
        sx={containerSx}
      >
        <Box className={classes.baseContent}>
          {value ? children : placeholder} {hint && <Box className={classes.baseHint}>{hint}</Box>}
        </Box>
        <Box className={classes.baseIcon}>
          {pending && (
            <CircularProgress
              size={size === 'small' ? 14 : 16}
              classes={{
                root: classes.progressIcon,
              }}
            />
          )}

          {isOpen ? (
            <KeyboardArrowUpIcon className={classes.chevronIcon} />
          ) : (
            <KeyboardArrowDownIcon className={classes.chevronIcon} />
          )}
        </Box>
      </Box>

      <Popover
        id={popoverId}
        open={isOpen}
        classes={{
          root: classes.popoverRoot,
          paper: clsx(classes.popover, !popoverHasMinWidth && 'no-min-width'),
        }}
        anchorEl={anchorEl}
        onClose={() => {
          handleCloseMenu()
          onClose?.()
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List
          size={size}
          items={items.map(item => ({
            ...item,
            onClick: e => {
              if (item.onClick) {
                item.onClick(e)
              }
              handleCloseMenu()
            },
          }))}
        />
      </Popover>
    </>
  )
}
