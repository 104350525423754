import { FormFieldDropdownOptionDTO, FormFieldDTO, FormFieldTypeDTO, FormFieldVariantDTO } from '@ketch-com/figurehead'
import { LanguageDTO } from 'interfaces/languages/language'
import { v4 as uuidv4 } from 'uuid'
import { CheckboxOptionDTO, DropdownOptionDTO, FormFieldFormDTO } from '../interfaces'

export const getDropdownOptionTranslations = (
  dropdownOption?: FormFieldDropdownOptionDTO,
  languages?: LanguageDTO[],
) => {
  const transformedDropdownOptionTranslations = Object.entries(dropdownOption?.translations || {})?.map(
    ([code, translation]) => ({
      title: languages?.find(language => language.code === code)?.englishName || '',
      fieldLabelTranslation: translation,
      code,
    }),
  )

  const filteredLanguages = languages?.filter(
    language => !transformedDropdownOptionTranslations?.some(translation => translation.code === language.code),
  )

  const transformedFilteredLanguages = (filteredLanguages || [])?.map(language => ({
    title: language.code || '',
    fieldLabelTranslation: '',
    code: language.code || '',
  }))

  const payload = [...transformedDropdownOptionTranslations, ...transformedFilteredLanguages]

  return payload
}

type GetFormFieldInitialValuesArgs = {
  formField?: FormFieldDTO
  languages?: LanguageDTO[]
}

/**
 * @param {object} params - An object containing the parameters for the function
 * @param {FormField} params.formField - The form field object to be transformed
 * @param {Language[]} params.languages - An array of language objects to be used for transforming the form field
 * @returns {FormFieldFormDTO} - The transformed form field object
 */
export const getFormFieldInitialValues = ({
  formField,
  languages,
}: GetFormFieldInitialValuesArgs): FormFieldFormDTO => {
  if (formField) {
    // Transform translations to include english name of language
    const transformedTranslations = Object.entries(formField?.translations || {})?.map(([code, translation]) => ({
      code,
      title: languages?.find(language => language.code === code)?.englishName || '',
      fieldTitleTranslation: translation?.title,
      uiHintTranslation: translation?.hint,
    }))

    // Filter languages that are not included in the translations object
    const filteredLanguages = languages?.filter(
      language => !transformedTranslations?.some(translation => translation.code === language.code),
    )

    // Transform filtered languages to include default values
    const transformedFilteredLanguages = (filteredLanguages || [])?.map(language => ({
      code: language.code || '',
      title: language?.englishName || '',
      fieldTitleTranslation: '',
      uiHintTranslation: '',
    }))

    // Transform dropdown options to include translations
    const transformedDropdownOptions: DropdownOptionDTO[] = (formField?.options || [])?.map(dropdownOption => ({
      id: uuidv4(),
      label: dropdownOption?.label,
      value: dropdownOption?.value,
      translations: getDropdownOptionTranslations(dropdownOption, languages),
    }))

    // Transform dropdown options to include translations
    const transformedCheckboxOptions: CheckboxOptionDTO[] = (formField?.options || [])?.map(checkboxOption => ({
      id: uuidv4(),
      label: checkboxOption?.label,
      value: checkboxOption?.value,
      translations: getDropdownOptionTranslations(checkboxOption, languages),
    }))

    const isCheckbox = formField?.type === FormFieldTypeDTO.CheckboxFormFieldType
    const isDropdown = formField?.type === FormFieldTypeDTO.DropdownFormFieldType

    // Create the payload object
    const payload: FormFieldFormDTO = {
      code: formField.code,
      title: formField.title,
      identitySpaceId: formField?.identitySpace?.code,
      uiHint: formField.hint,
      inputType: formField?.type || '',
      variant: formField.variant || '',
      translations: [...transformedTranslations, ...transformedFilteredLanguages],
      dropdownOptions: isDropdown ? transformedDropdownOptions : [],
      checkboxOptions: isCheckbox ? transformedCheckboxOptions : [],
      name: formField?.name,
    }

    return payload
  }

  // Create translations and dropdown options objects with default values
  const translations = (languages || [])?.map(language => ({
    code: language.code || '',
    fieldTitleTranslation: '',
    title: language?.englishName || '',
    uiHintTranslation: '',
  }))

  const dropdownOptionTranslationsInitialValues = (languages || [])?.map(language => ({
    code: language.code || '',
    fieldLabelTranslation: '',
    title: language.englishName || '',
  }))

  const dropdownOptionsInitialValues = [
    {
      id: uuidv4(),
      label: '',
      value: '',
      translations: dropdownOptionTranslationsInitialValues,
    },
    {
      id: uuidv4(),
      label: '',
      value: '',
      translations: dropdownOptionTranslationsInitialValues,
    },
  ]
  /* checkbox should start with only one item by default */
  const checkboxOptionsInitialValues = [
    {
      id: uuidv4(),
      label: '',
      value: '',
      translations: dropdownOptionTranslationsInitialValues,
    },
  ]

  const payload = {
    name: '',
    code: '',
    title: '',
    identitySpaceId: '',
    uiHint: '',
    inputType: FormFieldTypeDTO.TextFormFieldType,
    variant: FormFieldVariantDTO.InputFormFieldVariant,
    translations: translations,
    dropdownOptions: dropdownOptionsInitialValues,
    checkboxOptions: checkboxOptionsInitialValues,
  }

  return payload
}
