import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  organizationCode: string
}>

type Response = {
  entitlementStatus: {
    [key in ENTITLEMENTS]: {
      allowed: boolean
      numberLicensed: number
      numberRemaining: number
    }
  }
}

export const fetchEntitlements = ({ organizationCode }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/entitlements`,
    }),
  )
