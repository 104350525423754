export const preferenceDataMock = {
  title: 'Your Privacy',
  overviewTab: {
    tabName: 'Privacy Policy',
    bodyTitle: 'Privacy Overview',
    bodyDescription:
      'When you visit any web site, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalised web experience.\n\nBecause we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.',
  },
  consentsTab: {
    tabName: 'Preferences',
    bodyTitle: 'Preferences',
    bodyDescription:
      'Axonic, Inc. determines the use of personal data collected on our media properties and across the internet. We may collect data that you submit to us directly or data that we collect automatically including from cookies (such as device information or IP address). Axonic, Inc. determines the use of personal data collected on our media properties and across the internet. We may collect data that you submit to us directly or data that we collect automatically including from cookies (such as device information or IP address).',
    buttonText: 'Save',
  },
  rightsTab: {
    tabName: 'Data Subject Rights',
    bodyTitle: 'Data Privacy Request Form',
    bodyDescription:
      'When you visit any web site, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalised web experience.\n\nBecause we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.',
    buttonText: 'Submit',
  },
}
