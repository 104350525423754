import { ApiQueryKeys } from 'api/common/queryKeys'
import { isQueryEnabled } from 'api/common/utils'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchLanguages } from 'api/languages/fetchers/fetchLanguages'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.language,
  queryFn: fetchLanguages,
  select: res => res?.data?.languages || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useLanguages = (config?: Config) => {
  const { isPermitted } = useIsPermitted()

  return useCustomQuery({
    ...config,
    enabled: isPermitted(PERMISSIONS.TRANSLATIONS_READ) && isQueryEnabled(config?.enabled),
    params: {
      start: 0,
      limit: 1000,
      ...config?.params,
    },
  })
}
