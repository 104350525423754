import { ExperienceDTO, UpsertExperienceDTO } from 'interfaces/experiences/experience'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  formData: UpsertExperienceDTO
}

type Response = { experience: ExperienceDTO }

export const createExperience = ({ organizationCode, formData }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/experiences`,
    }),
    formData,
  )
