import { Checkbox } from '@ketch-com/deck'
import { FormFieldDTO } from '@ketch-com/figurehead'
import { Box, Typography } from '@mui/material'
import { FormDataInputValue } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'

type Props = {
  formValue: FormDataInputValue
  setFormValue: (v: FormDataInputValue) => void
  formField?: FormFieldDTO
  isUnknown?: boolean
}
export const CheckboxInput: React.FC<Props> = ({ formValue, setFormValue, formField, isUnknown = false }) => {
  const checkboxItems = (formField?.options || []).map(({ label, value }) => ({
    id: value,
    name: label,
  }))

  return (
    <Box maxWidth={962}>
      {!isUnknown && (
        <Typography mb={0.5} variant="smallLabel">
          New Value
        </Typography>
      )}
      <Box display="flex" flexDirection="column">
        {checkboxItems.map(item => {
          const isChecked = Array.isArray(formValue) ? formValue.includes(item?.id || '') : false
          return (
            <Box display="flex" alignItems="center">
              <Checkbox
                key={item.id}
                id={item.id}
                checked={!!isChecked}
                name={item.id}
                onClick={_ => {
                  const currentValues = Array.isArray(formValue) ? formValue : []

                  if (!isChecked) {
                    const newValues = [...currentValues, item.id] as string[]
                    setFormValue(newValues)
                  } else {
                    const newValues = currentValues.filter(v => v !== item.id)
                    setFormValue(newValues)
                  }
                }}
              />
              <Typography variant={!!isChecked ? 'label' : 'body'}>{item?.name}</Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
