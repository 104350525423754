import { ICanvasStep } from '../../interfaces'
import { isUndefined } from 'lodash'
import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { getTransitionsFromStep } from './transitionsFromStep'
import { getNextJoinTile } from './getNextJoinTile'

type Params = {
  pathStartStepId: string
  steps: ICanvasStep[]
  lastStep?: ICanvasStep
  visited?: Set<String>
  stopAtJoin?: boolean
}

/**
 * Get the last step in a sequence of steps
 * @param pathStartStepId ID of the step to start at
 * @param steps All steps in the workflow
 * @param visited Steps that have already been visited (used recursively)
 * @param lastStep The current last step (used recursively)
 * @param stopAtJoin
 */
export const getLastStepOfPath = ({
  pathStartStepId,
  steps,
  lastStep,
  visited = new Set<string>(),
  stopAtJoin = false,
}: Params): ICanvasStep | undefined => {
  let currentStep = steps.find(step => step.ID === pathStartStepId)

  // Continue until undefined is reach
  while (currentStep) {
    // Determine the type of step we are at
    const currentStepIsGateway = !!currentStep && !isUndefined(currentStep.gateway)
    const currentStepIsDecisionBoolean =
      currentStepIsGateway && currentStep!.gateway!.mode === WorkflowActivityGatewayMode.DECISION_BOOLEAN
    const currentStepIsJoin =
      !!currentStep &&
      !isUndefined(currentStep.gateway) &&
      currentStep.gateway.mode === WorkflowActivityGatewayMode.JOIN

    if (currentStepIsJoin && stopAtJoin) break

    // Update visited and last step
    if (!!currentStep) {
      visited.add(currentStep.ID?.toString() || '')
      lastStep = currentStep
    }

    // Decide what step to move to
    if (currentStepIsDecisionBoolean) {
      // If decision boolean reached, call getLastStep recursively on the first path
      return currentStep.gateway!.transitions.length
        ? getLastStepOfPath({
            pathStartStepId: currentStep.gateway!.transitions[0].ID?.toString() || '',
            steps,
            visited,
            lastStep,
          })
        : lastStep
    } else if (currentStepIsGateway && !currentStepIsJoin) {
      // If split or decision gateway reached, move to the join
      currentStep = getNextJoinTile({ step: currentStep, steps })
    } else {
      // Otherwise just move to next step
      const nextSteps = getTransitionsFromStep({ step: currentStep, steps })
      currentStep = nextSteps.length ? nextSteps[0] : undefined
    }
  }
  return lastStep
}
