import { APIListRequestParams } from 'api/common/utils'
import { GetAssetHierarchiesResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  assetCode?: string | null | undefined
  start?: number
  canonicalResourceType?: number | null | undefined
  resourceTypeCode?: string | null | undefined
  query?: string | null | undefined
  identityRelationType?: number
  isPersonal?: boolean
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchRelatedAssetsPaginatedV2 = ({
  assetCode,
  start,
  limit,
  canonicalResourceType,
  resourceTypeCode,
  query,
  identityRelationType,
  isPersonal,
  isV2FeatureFlagEnabled,
}: Params) => {
  let entityUrl = `/api/v3/assets/${assetCode}/hierarchies`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/assets/${assetCode}/hierarchies`

  return API.get<GetAssetHierarchiesResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          start,
          limit,
          ...(isV2FeatureFlagEnabled &&
            canonicalResourceType && {
              canonicalResourceType,
            }),
          ...(resourceTypeCode && {
            resourceTypeCode,
          }),
          ...(query && {
            query,
          }),
          ...(identityRelationType && {
            identityRelationType,
          }),
          ...(isPersonal && {
            isPersonal,
          }),
        },
      },
    }),
  )
}
