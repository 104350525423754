import { useEffect, useMemo, useState } from 'react'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'

/**
 * React Hook to manage the selection of an active policy scope.
 *
 * @param {string} initialPolicyScopeCode - The code of the policy scope that should initially be set as active.
 * @returns {Object} An object containing the currently active policy scope object, and a list of policy scope items with an active flag, their content, and an onClick handler.
 *
 * @hook
 */
export const usePolicyScope = (initialPolicyScopeCode: string) => {
  // Fetch policy scopes data from API.
  const { data: policyScopes, isLoading: isLoadingPolicyScopes } = usePolicyScopes()

  /**
   * @type {React.Dispatch<React.SetStateAction<PolicyScope | undefined>>}
   * State and setter for the active policy scope object.
   */
  const [activePolicy, setActivePolicy] = useState(() => {
    return policyScopes.find(policy => policy.code === initialPolicyScopeCode)
  })

  // Effect to update activePolicy whenever initialPolicyScopeCode changes.
  useEffect(() => {
    setActivePolicy(policyScopes.find(policy => policy.code === initialPolicyScopeCode))
  }, [initialPolicyScopeCode, policyScopes])

  // Memoize policy scopes items to optimize performance.
  const policyScopesItems = useMemo(
    () =>
      policyScopes?.map(policy => ({
        active: policy.code === activePolicy?.code, // Indicate if the policy scope is active.
        content: policy.name, // Content of the policy scope item.
        onClick: () => setActivePolicy(policy), // Handler to set the active policy scope.
      })) || [],
    [activePolicy, policyScopes],
  )

  return { activePolicy, policyScopesItems, isLoadingPolicyScopes }
}
