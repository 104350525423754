import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchTeam } from '../fetchers/fetchTeam'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.team,
  queryFn: fetchTeam,
  select: res => res?.data?.team,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useTeam = (config?: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
