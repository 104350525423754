import { faker } from '@faker-js/faker'

export const generateRatioWidgetDummyDataAlpha = (count: number) => {
  const data = []
  for (let i = 0; i < count; i++) {
    data.push({
      name: `${faker.name.firstName()} ${faker.name.lastName()}`,
      x: faker.datatype.number({ min: 5, max: 25 }),
      y: faker.datatype.number({ min: 10, max: 100 }),
      // z: faker.datatype.number({ min: 100, max: 200 }),
      z: 100,
    })
  }
  return data
}

export const generateRatioWidgetDummyDataBeta = (count: number) => {
  const data = []
  for (let i = 0; i < count; i++) {
    data.push({
      name: `${faker.name.firstName()} ${faker.name.lastName()}`,
      x: faker.datatype.number({ min: 35, max: 100 }),
      y: faker.datatype.number({ min: 10, max: 100 }),
      z: 100,
    })
  }
  return data
}

export const generateRatioWidgetDummyDataTheta = (count: number) => {
  const data = []
  for (let i = 0; i < count; i++) {
    data.push({
      name: `Team Average`,
      x: faker.datatype.number({ min: 50, max: 70 }),
      y: faker.datatype.number({ min: 10, max: 100 }),
      z: 300,
    })
  }
  return data
}

export const teamMemberSpecificData = [
  {
    x: faker.datatype.number({ min: 50, max: 70 }),
    y: faker.datatype.number({ min: 10, max: 100 }),
    z: 50,
    name: 'Team Member',
  },
]

export const getTeamMemberSpecificData = () => [
  {
    x: faker.datatype.number({ min: 50, max: 70 }),
    y: faker.datatype.number({ min: 10, max: 100 }),
    z: 50,
    name: 'Team Member',
  },
]
