import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { APIListRequestParams } from 'api/common/utils'
import { fetchDatabaseDatasetSummariesPaginatedV3 } from '../fetchers/fetchDatabaseDatasetSummariesPaginatedV3'

type Params = APIListRequestParams<{
  query?: string | null | undefined
  assetId?: string
  identityRelationType?: number
  isPersonal?: boolean
  scenarioId?: string
}>

type Options = {
  enabled?: boolean
}

export const useDatabaseDatasetSummariesInfiniteV3 = ({ options, params }: { options?: Options; params: Params }) => {
  const resp = useInfiniteQuery({
    enabled: !!options?.enabled,
    queryKey: [
      ApiQueryKeys.databaseDatasetSummariesPaginatedV3,
      {
        includeMetadata: true,
        ...params,
      },
    ],
    queryFn: async ({ pageParam }) => {
      const fetchDatasetsResp = await fetchDatabaseDatasetSummariesPaginatedV3({
        limit: params?.limit || 20,
        assetId: params.assetId,
        nextToken: pageParam?.nextToken,
        ...params,
      })

      return fetchDatasetsResp
    },
    getNextPageParam: lastPage => {
      const nextToken = lastPage?.data?.next?.nextToken
      return nextToken ? { nextToken } : undefined
    },
  })

  return {
    ...resp,
    assetsData: resp?.data?.pages?.map(page => page?.data?.hierarchySummaries || []).flat() || [],
    totalResults: resp?.data?.pages?.[0]?.data?.totalResults || 0,
  }
}
