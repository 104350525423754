import { APIListRequestParams } from 'api/common/utils'
import { GetUniqueNamesResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  start?: number
  type?: number | null
  query?: string | null | undefined
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchAssetsUniqueNamesPaginatedV2 = ({ start, limit, type, query, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/assets/unique-names`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/assets/unique-names`

  return API.get<GetUniqueNamesResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          start,
          limit,
          ...(type && {
            type,
          }),
          ...(query && {
            name: query,
          }),
        },
      },
    }),
  )
}
