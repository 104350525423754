import React from 'react'
import { useFormikContext } from 'formik'
import { Box, Typography } from '@mui/material'
import { CreateRelationdhipFormData } from 'pages/assetManager/relationshipMapping/interfaces'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { useSelectAssetsState } from 'pages/assetManager/relationshipMapping/components/CreatedRelationships/components/CreateRelationshipMappingModal/hooks/useSelectAssetsState'
import { DatabaseResources } from 'pages/assetManager/interfaces'
import { useDatabaseResources } from 'api/assets/queries/useDatabaseResources'
import { DropListButton, ActionSheetItem } from '@ketch-com/deck'

type Props = {
  isForiegn?: boolean
}
export const DatabaseAssets: React.FC<Props> = Props => {
  const { setFieldValue, setFieldTouched, setTouched, isSubmitting, errors, touched } =
    useFormikContext<CreateRelationdhipFormData>()
  const { setInputValue, searchQueryValue } = useSelectAssetsState<DatabaseResources>()
  const { data: databaseResourcesData, isLoading: isDatabaseResourcesDataLoading } = useDatabaseResources({
    params: {
      query: searchQueryValue,
      limit: 200,
    },
  })

  const isError = !!errors?.databaseResourceId && !!touched?.databaseResourceId
  const errorMessage = errors?.databaseResourceId || 'no-error'

  return (
    <Box>
      <DropListButton
        noOptionsText="No Databases"
        loading={isDatabaseResourcesDataLoading}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={isSubmitting}
        sx={{
          width: 272,
        }}
        placeholder="Select Database"
        options={databaseResourcesData?.databaseResources || []}
        getOptionLabel={option => option?.name || ''}
        renderOption={(props, option, { selected }) => {
          return (
            <ActionSheetItem key={option.id} selected={selected} {...props}>
              <Box display="flex" gap={0.5} flexDirection="column">
                <Box alignSelf="flex-start">
                  <Typography variant="body">{option?.name}</Typography>
                </Box>
                <Box alignSelf="flex-start" display="flex" alignItems="center" gap={0.5}>
                  <img
                    width={16}
                    height={16}
                    src={getAssetCloudImgUrl(option?.technology || '')}
                    alt={option?.technology || 'technology image'}
                  />
                  <Typography variant="footnote">Database</Typography>
                </Box>
              </Box>
            </ActionSheetItem>
          )
        }}
        onBlur={() => {
          setFieldTouched('databaseResourceId', true)
        }}
        onChange={(e, databaseResource) => {
          setTouched({})
          setFieldValue('foriegnTableResourceId', '')
          setFieldValue('primaryTableResourceId', '')
          setFieldValue('foriegnColumnResourceId', '')
          setFieldValue('primaryColumnResourceId', '')
          setFieldValue('databaseResourceId', databaseResource?.id)
          setFieldValue('technology', databaseResource?.technology)
        }}
        onInputChange={(event, value) => {
          setInputValue(value)
        }}
        error={isError}
        errorMessage={errorMessage}
      />
    </Box>
  )
}
