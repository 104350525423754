import { APIListRequestParams, OrderBy } from 'api/common/utils'
import { FetchTasksResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestStringV2 } from 'utils/helpers/api/formatRequestStringV2'

type Params = APIListRequestParams<{
  ordering?: OrderBy
  start?: number
  status?: string
  query?: string
}>

export const fetchTasksPaginated = ({ ordering, start, limit, query, status }: Params) => {
  return API.get<FetchTasksResponseBodyDTO>(
    formatRequestStringV2({
      entityUrl: `/api/tasks`,
      params: {
        filters: {
          start,
          limit,
          ordering,
          status,
          query,
        },
      },
    }),
  )
}
