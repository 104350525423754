import { useQueryParams } from 'utils/hooks'
import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, DataGrid, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { Box, styled } from '@mui/material'
import { CSSProperties } from 'react'
import { useNavigate } from 'react-router-dom'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { useInstalledDataSystemsInfinite } from 'api/dataSystems/queries/useInstalledDataSystemsInfinite'
import { Filters, ShowFilters } from 'pages/dataSystems/components/Filters/Filters'
import { FormikProvider, useFormik } from 'formik'
import { getInstalledDataSystemV2DTOColumns } from './systemSelectorUtils'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

const FiltersContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '1.5rem',
}))

type Props = {
  className?: string
  style?: CSSProperties
}

export const SystemSelector: React.FC<Props> = ({ className, style }) => {
  const { queries } = useQueryParams<{
    q?: string
    capabilities?: string
    integrations?: string
  }>()

  const navigate = useNavigate()

  const {
    data: installedDataSystems,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInstalledDataSystemsInfinite({
    q: queries.q,
    capabilities: queries?.capabilities ? [queries?.capabilities] : [],
  })

  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.systems.root.pattern },
    { title: 'Create New Connection', link: RoutesManager.systems.connections.create.pattern },
  ]

  const onSubmit = async (values: { id: string }) => {
    const { id } = values
    if (id) {
      navigate(RoutesManager.systems.connections.upsert.root.getURL({ id, instanceId: NEW_ITEM_ID }))
    }
  }

  const initialValues = { id: '' }

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: onSubmit,
  })

  const { values, isSubmitting, submitForm, setFieldValue } = formikProps

  const dataGridColumns = getInstalledDataSystemV2DTOColumns({
    setFieldValue,
    values,
  })
  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={UpsertLayoutMode.create}
          currentFormStep={0}
          showStepper={false}
          renderFormTitle={() => (
            <ContentGroup
              title="Select System"
              titleVariant="h2"
              isDivider
              subTitle="Select a system to add and view available integration capabilities."
            />
          )}
          onAccept={submitForm}
          onCancel={() => {
            navigate(RoutesManager.systems.root.pattern)
          }}
          acceptButtonProps={{
            pending: isSubmitting,
            disabled: !values.id,
          }}
          acceptActionButtonText="Continue"
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            <Box display="flex" flexDirection="column" gap={3} pb={6}>
              <FiltersContainer>
                <Filters show={[ShowFilters.SEARCH, ShowFilters.CAPABILITIES]} />
              </FiltersContainer>

              <Box height="500px">
                <DataGrid
                  autosizeOnMount
                  autosizeOptions={{
                    includeHeaders: true,
                    includeOutliers: false,
                    expand: true,
                  }}
                  getRowHeight={() => 'auto'}
                  columns={dataGridColumns}
                  disableChildrenSorting
                  disableColumnMenu
                  disableColumnPinning
                  disableColumnResize
                  disableColumnReorder
                  disableRowHoverStates
                  disableRowSelectionOnClick
                  getRowId={row => row.id}
                  rows={installedDataSystems}
                  loading={isFetchingNextPage || isLoading}
                  hideFooter
                  noRowsOverlayProps={{
                    title: 'No apps matching your search criteria',
                    subTitle: '',
                    buttonTitle: '',
                  }}
                  paginationLoading={isFetchingNextPage || isLoading}
                  onRowsScrollEnd={() => {
                    if (!isFetchingNextPage && hasNextPage) fetchNextPage()
                  }}
                />
              </Box>
            </Box>
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
