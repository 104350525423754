import { LegalBaseDTO } from 'interfaces/legalBases/legalBase'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'

type Args = {
  purposeLegalBases?: {
    [policyScopeCode: string]: string
  }
  jurisdictions?: PolicyScopeDTO[]
  legalBases?: LegalBaseDTO[]
}

export const useLegalBasesInfoRowData = ({ purposeLegalBases, jurisdictions, legalBases }: Args) => {
  if (!purposeLegalBases || !jurisdictions || !legalBases) return []

  const entries = Object.entries(purposeLegalBases || {})
  const payload: { jurisdictionName: string; legalBaseName: string }[] = []

  for (const [key, value] of entries) {
    const jurisdictionName = jurisdictions?.find?.(j => j.code === key)?.name || ''
    const legalBaseName = legalBases?.find?.(l => l.code === value)?.name || ''
    payload.push({ jurisdictionName, legalBaseName })
  }

  return payload
}
