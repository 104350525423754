import React, { useState } from 'react'
import { useField } from 'formik'
import { useQueryClient } from 'react-query'
import { theme } from '@ketch-com/deck'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { DropZone } from 'components/ui-kit/dropZone/DropZone'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { List, Typography, Box } from '@mui/material'
import { useDeleteFile } from 'api/files/mutations/useDeleteFile'
import { TooltipV2 } from 'components/ui-kit/tooltip/TooltipV2'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { StyledListItemText } from './components'
import { handleUpload, handleDelete } from './FormDropZone.utils'
import { FormDropZoneProps } from './FormDropZone.types'
import { AttachmentChip } from 'components/ui-kit/chip/AttachmentChip'
import { convertFileInfoDTOLocalToFileInfoDTO } from 'utils/api/serializeFileInfoDTO'
import { truncateStringWithEllipse } from 'utils/formatters'

export function FormDropZone({
  name,
  validate,
  label,
  dropMessage,
  required = true,
  disabled,
  disableDelete = false,
  disableDownload = false,
  hideDropzoneOnUpload = false,
  hideFileInfo = false,
  fileInfoDisplayVariant = 'standard',
  locked = false,
  onClickWhenLocked,
  hideErrorText = false,
  maxFiles = Infinity,
  onUploadingStateChange,
  onFileDialogCancel,
  onUploadComplete,
  onDelete,
  uploadContext,
  validateOnTouch = true,
  truncateAttachmentNameChars = undefined,
  ...rest
}: FormDropZoneProps) {
  // Initialize hooks and variables

  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<FileInfoDTO[]>({ name, validate })
  const [isUploading, setIsUploading] = useState(false)
  const queryClient = useQueryClient()

  // Mutation hooks for deleting and downloading a file
  const { mutateAsync: handleDeleteFile, isLoading: isDeletingFile } = useDeleteFile({
    onSuccess: async () => await queryClient.refetchQueries(ApiQueryKeys.filesList),
  })

  // Define error states
  const showError = !!error && touched
  const isRequiredAndOnlyFile = required && value.length === 1 && maxFiles !== 1

  return (
    <Box width="100%">
      {!!label && <Label required={required}>{label}</Label>}
      <DropZone
        {...rest}
        value={value}
        uploadContext={uploadContext}
        valid={!!dropMessage ? false : !showError}
        disabled={disabled || value.length >= maxFiles}
        onUpload={(files: FileInfoDTO[]) => handleUpload({ files, value, setValue, queryClient, onUploadComplete })}
        dropMessage={dropMessage}
        onUploadingStateChange={isUploading => {
          setIsUploading(isUploading)
          onUploadingStateChange?.(isUploading)
        }}
        onFileDialogCancel={() => {
          if (validateOnTouch) {
            // only setTouched if validateOnTouch is true
            setTouched(true)
          }

          onFileDialogCancel?.()
        }}
        locked={locked}
        onClickWhenLocked={onClickWhenLocked}
        onDelete={onDelete}
        hideDropzoneOnUpload={hideDropzoneOnUpload}
        fileInfoDisplayVariant={fileInfoDisplayVariant}
        disableDelete={disableDelete}
      />
      {!hideErrorText && showError && <Error>{error}</Error>}

      {!hideFileInfo && (
        <>
          {fileInfoDisplayVariant === 'standard' && (
            <List sx={{ mt: 2 }}>
              {value?.map(attachment => {
                return (
                  <StyledListItemText key={attachment.ID}>
                    <Box display="flex" flexDirection="column" gap={1} alignItems="flex-start">
                      <AttachmentChip
                        size="medium"
                        iconColor={theme.palette.doomedGrey.main}
                        deleteIconName="OCross"
                        file={convertFileInfoDTOLocalToFileInfoDTO(attachment)}
                        key={attachment.ID}
                        disabled={isDeletingFile || isUploading || disableDelete}
                        title={attachment.name}
                        label={
                          <TooltipV2
                            arrow
                            title={
                              isRequiredAndOnlyFile
                                ? `This is a required field and cannot be left empty. Before deleting ${attachment.name}, please upload the file you would like to replace it with.`
                                : undefined
                            }
                          >
                            <Box>
                              {!!truncateAttachmentNameChars
                                ? truncateStringWithEllipse(attachment.name, truncateAttachmentNameChars)
                                : attachment.name}
                            </Box>
                          </TooltipV2>
                        }
                        onDelete={() => {
                          handleDelete({
                            attachment,
                            required,
                            value,
                            maxFiles,
                            handleDeleteFile,
                            setValue,
                            onDelete,
                          })
                        }}
                      />

                      <Box pl={2} display="flex" alignItems="center">
                        <Typography
                          fontSize={11}
                          fontWeight={700}
                          lineHeight="15px"
                          letterSpacing="0.11px"
                          color="darkDuskFaded.main"
                        >
                          {attachment.size}
                        </Typography>
                      </Box>
                    </Box>
                  </StyledListItemText>
                )
              })}
            </List>
          )}
        </>
      )}
    </Box>
  )
}
