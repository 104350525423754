import { useCallback } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, TableCell, Avatar } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { DataGridProProps } from '@mui/x-data-grid-pro'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { useQueryParams } from 'utils/hooks'
import { useInstalledDataSystemsInfinite } from 'api/dataSystems/queries/useInstalledDataSystemsInfinite'

const columns = [
  {
    field: 'logo',
    headerName: 'Logo',
    renderCell: (params: GridRenderCellParams) => (
      <TableCell>
        <Avatar src={params.value} />
      </TableCell>
    ),
    width: 150,
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    renderCell: (params: GridRenderCellParams) => <TableCell>{params.value}</TableCell>,
    width: 150,
    sortable: false,
  },
  {
    field: 'description',
    headerName: 'Description',
    renderCell: (params: GridRenderCellParams) => <TableCell>{params.value}</TableCell>,
    width: 250,
    sortable: false,
  },
  {
    field: 'systemType',
    headerName: 'System Type',
    renderCell: (params: GridRenderCellParams) => <TableCell>{params.value}</TableCell>,
    width: 150,
    sortable: false,
  },
  {
    field: 'type',
    headerName: 'Type',
    renderCell: (params: GridRenderCellParams) => <TableCell>{params.value}</TableCell>,
    width: 150,
    sortable: false,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    renderCell: (params: GridRenderCellParams) => (
      <TableCell>{formatDateTimeFromUnix(params.value, 'MMM DD YYYY')}</TableCell>
    ),
    width: 150,
    sortable: false,
  },
  {
    field: 'updatedBy',
    headerName: 'Updated By',
    renderCell: (params: GridRenderCellParams) => <TableCell>{params.value}</TableCell>,
    width: 150,
    sortable: false,
  },
]

const useDataGridInfinityData = () => {
  const { queries } = useQueryParams<{
    q?: string
    capabilities?: string
    connectionStatuses?: string
  }>()

  const {
    data: installedDataSystems,
    isLoading,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInstalledDataSystemsInfinite({
    q: queries.q,
    capabilities: queries?.capabilities ? [queries?.capabilities] : [],
  })

  const isPaginationLoading = isRefetching || isFetchingNextPage

  const rows = installedDataSystems.map(item => ({
    id: item.id,
    logo: item.dataSystem?.logoUrl,
    name: item.dataSystem?.name,
    description: item.dataSystem?.description,
    systemType: item.dataSystem?.systemType,
    updatedAt: item.dataSystem?.updatedAt,
    updatedBy: item.dataSystem?.updatedBy,
    type: item.type,
  }))

  const handleOnRowsScrollEnd: DataGridProProps['onRowsScrollEnd'] = useCallback(() => {
    if (hasNextPage && !isPaginationLoading && !isLoading) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isPaginationLoading, isLoading])

  return { rows, isPaginationLoading, handleOnRowsScrollEnd, isLoading }
}

export const DataGridInfinityScrollExample = () => {
  const { rows, isPaginationLoading, handleOnRowsScrollEnd, isLoading } = useDataGridInfinityData()

  return (
    <Box style={{ width: '100%', maxHeight: '100%', height: '70vh' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        paginationLoading={isPaginationLoading}
        loading={isLoading}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableChildrenSorting
        hideFooter={false}
        hideFooterPagination={false}
        isPaginationLoading
      />
    </Box>
  )
}
