import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  apiKeyId: string
}

export const deleteApiKey = ({ organizationCode, apiKeyId }: Params) =>
  API.delete<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/api-keys/${apiKeyId}`,
    }),
  )
