import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'

import { IdentitySpaceMappingDirection } from 'interfaces/identitySpaces/identitySpace'
import {
  emptyIdentitySpaceMappingValue,
  getOptions,
  IdentitySpaceFormValues,
  identitySpaceMappingDirectionsList,
} from 'pages/settings/identitySpaces/upsert/utils/formValues'

import { Button, ListItemText, ActionSheetItem, Radio } from '@ketch-com/deck'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { Box, Typography } from '@mui/material'

type Props = {
  isEditMode: boolean
  index: number
  remove: (index: number) => void
  push: (obj: { direction: IdentitySpaceMappingDirection; identitySpaceCode: string }) => void
}

export const IdentitySpaceMappingRow: React.FC<Props> = ({ isEditMode, index, remove, push }) => {
  const { values, setFieldValue } = useFormikContext<IdentitySpaceFormValues>()
  const options = useMemo(() => getOptions({ values, isEditMode, index }), [values, isEditMode, index])

  return (
    <Grid
      container
      key={index}
      alignItems="center"
      pt={2}
      pb={2}
      sx={{
        borderBottom: theme => `1px solid ${theme.palette.iron.main}`,
      }}
    >
      {identitySpaceMappingDirectionsList.map((space, key) => (
        <Grid item xs={4} key={key}>
          <Radio
            id={`mappings[${index}].direction${key}`}
            data-test-id={`Radio-id-space-mapping-direction-list`}
            onChange={() => setFieldValue(`mappings[${index}].direction`, space.value)}
            value={space.value}
            checked={values.mappings[index].direction === space.value}
            sx={{ mr: '1px' }}
          />
          <Typography>{space.title}</Typography>
        </Grid>
      ))}
      <Grid item xs={4}>
        <FormDroplistButton
          formPropertyName={`mappings[${index}].identitySpaceCode`}
          placeholder="Select Identifier"
          valueKey="code"
          items={options}
          hideOptionalLabel
          required
          size="small"
          disableClearable
          renderOption={(props, option, _, isSelected) => {
            return (
              <ActionSheetItem key={option.ID} selected={isSelected} {...props}>
                <ListItemText selected={isSelected}>
                  <Box display="flex" flexDirection="column">
                    <Typography>{option.name}</Typography>
                    <Typography variant="footnote">{option.code}</Typography>
                  </Box>
                </ListItemText>
              </ActionSheetItem>
            )
          }}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          textAlign: 'right',
        }}
      >
        <Button
          onClick={() => {
            remove(index)
            if (values.mappings.length === 1) {
              setFieldValue('hasMappings', false)
              push(emptyIdentitySpaceMappingValue)
            }
          }}
          color="tertiary"
        >
          Remove
        </Button>
      </Grid>
    </Grid>
  )
}
