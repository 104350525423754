import { mapValues } from 'lodash'

import { MaybeNull } from 'interfaces'
import { AppDescriptorDTO, EditAppsInstancesPurposesDTO } from 'interfaces/apps'
import { CanonicalPurposeDTO } from 'interfaces/purposes/canonicalPurpose'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'

export type PermitPropagationFormValues = {
  [appInstanceId: string]: {
    [canonicalPurposeCode: string]: boolean
  }
}

export const getInitialValues = ({ appDescriptor }: { appDescriptor: MaybeNull<AppDescriptorDTO> }) => {
  const appCanonicalPurposesCodes = appDescriptor?.app?.supportedPurposes || []

  return (appDescriptor?.appInstances || []).reduce<PermitPropagationFormValues>(
    (acc, { ID, purposes }) => ({
      ...acc,
      [ID]: appCanonicalPurposesCodes.reduce(
        (res, code) => ({
          ...res,
          [code]: purposes?.[code] || false,
        }),
        {},
      ),
    }),
    {},
  )
}

export const mapValuesToPayload = (values: PermitPropagationFormValues): EditAppsInstancesPurposesDTO => ({
  appInstancesPurposes: mapValues(values, value => ({
    purposes: value,
  })),
})

export const getUniqueDataSubjectTypes = (canonicalPurpose: CanonicalPurposeDTO): DataSubjectTypeDTO[] => {
  const dataSubjectTypesByCode = new Map<string, DataSubjectTypeDTO>()

  for (const purpose of canonicalPurpose.purposes ?? []) {
    for (const dataSubjectType of purpose.dataSubjectTypes ?? []) {
      dataSubjectTypesByCode.set(dataSubjectType.code ?? '', dataSubjectType)
    }
  }

  return [...dataSubjectTypesByCode.values()].sort((a, b) => {
    const aName = a.name ?? ''
    const bName = b.name ?? ''
    if (aName < bName) {
      return -1
    }
    if (aName === bName) {
      return 0
    }
    return 1
  })
}
