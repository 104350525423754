import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { getIn, useFormikContext } from 'formik'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { Box } from '@mui/material'
import { Tab, TabPanel, TabGroup } from '@ketch-com/deck'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import {
  BuilderTextInputMode,
  FormTextInput,
} from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'
import { FormSectionSwitch } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormSectionSwitch'
import { FormFileUpload } from 'components/ui-kit/form/fileUpload/FormFileUpload'
import { themeFileAllowedTypes, themeFileUploadContext } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'

export const PreferenceThemeWelcomeTabNavigationForm: React.FC = () => {
  const { values } = useFormikContext<ThemeV3DTO>()

  // Current tab of the button form
  const [tab, setTab] = React.useState(0)

  const showIconsForm = getIn(values, themeUpsertFormKeys.preferenceWelcomeTabNavigationIconsVisible)

  return (
    <>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
            <Tab size={'medium'} label="Layout" />
            <Tab size={'medium'} label="Icons" />
          </TabGroup>
        </Box>
        {/* Layout form */}
        <TabPanel value={tab} index={0} sx={{ px: 0 }}>
          <SidebarFormGroup sx={{ pt: 0 }} hasBorderBottom>
            <FormColorInput
              name={themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutBackgroundColor}
              label={'Navigation Container Fill Color'}
              fullWidth
              required
            />
            <FormTextInput
              name={themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutCornerRadius}
              label={'Navigation Corner Radius'}
              mode={BuilderTextInputMode.CornerRadius}
              max={24}
              inputWidth="92px"
            />
          </SidebarFormGroup>
          <SidebarFormGroup hasBorderBottom>
            {/* Descoped */}
            {/* <FormColorInput
              name={themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutItemUnselectedBackgroundColor}
              label={'Navigation Item Default Background Color'}
              fullWidth
              required
            /> */}
            <FormColorInput
              name={themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutItemUnselectedTextColor}
              label={'Navigation Item Default Text Color'}
              fullWidth
              required
            />
          </SidebarFormGroup>
          <SidebarFormGroup hasBorderBottom>
            <FormColorInput
              name={themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutItemSelectedBackgroundColor}
              label={'Navigation Item Selected Background Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutItemSelectedTextColor}
              label={'Navigation Item Selected Text Color'}
              fullWidth
              required
            />
          </SidebarFormGroup>
          <SidebarFormGroup>
            <FormTextInput
              name={themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutItemCornerRadius}
              label={'Navigation Item Corner Radius'}
              mode={BuilderTextInputMode.CornerRadius}
              max={24}
              inputWidth="92px"
            />
          </SidebarFormGroup>
        </TabPanel>

        {/* Icons form */}
        <TabPanel value={tab} index={1} sx={{ px: 0, pb: '16px' }}>
          <SidebarFormGroup sx={{ py: 0, gap: '16px' }}>
            <FormSectionSwitch title={'Icons'} name={themeUpsertFormKeys.preferenceWelcomeTabNavigationIconsVisible} />
            {showIconsForm && (
              <Box px={'18px'}>
                <SidebarFormGroup sx={{ pt: 0, pb: '16px' }} hasBorderBottom>
                  <FormFileUpload
                    name={themeUpsertFormKeys.preferenceWelcomeTabNavigationWelcomeIconUrl}
                    label={'"Welcome" Page Icon'}
                    belowButtonLabel={'Container size: 24x24'}
                    uploadContext={themeFileUploadContext}
                    accept={themeFileAllowedTypes}
                  />
                </SidebarFormGroup>
                {/* TODO:JB - Descoped for V0 */}
                {/* <SidebarFormGroup sx={{ py: '16px' }} hasBorderBottom>
                  <FormFileUpload
                    name={themeUpsertFormKeys.preferenceWelcomeTabNavigationPrivacyPolicyIconUrl}
                    label={'"Privacy Policy" Page Icon'}
                    belowButtonLabel={'Container size: 24x24'}
                    uploadContext={themeFileUploadContext}
                    accept={themeFileAllowedTypes}
                  />
                </SidebarFormGroup> */}
                <SidebarFormGroup sx={{ py: '16px' }} hasBorderBottom>
                  <FormFileUpload
                    name={themeUpsertFormKeys.preferenceWelcomeTabNavigationPurposesIconUrl}
                    label={'"Purposes" Page Icon'}
                    belowButtonLabel={'Container size: 24x24'}
                    uploadContext={themeFileUploadContext}
                    accept={themeFileAllowedTypes}
                  />
                </SidebarFormGroup>
                <SidebarFormGroup sx={{ py: '16px' }} hasBorderBottom>
                  <FormFileUpload
                    name={themeUpsertFormKeys.preferenceWelcomeTabNavigationSubscriptionsIconUrl}
                    label={'"Subscriptions" Page Icon'}
                    belowButtonLabel={'Container size: 24x24'}
                    uploadContext={themeFileUploadContext}
                    accept={themeFileAllowedTypes}
                  />
                </SidebarFormGroup>
                <SidebarFormGroup sx={{ pt: '16px', pb: 0 }}>
                  <FormFileUpload
                    name={themeUpsertFormKeys.preferenceWelcomeTabNavigationRequestsIconUrl}
                    label={'"Requests" Page Icon'}
                    belowButtonLabel={'Container size: 24x24'}
                    uploadContext={themeFileUploadContext}
                    accept={themeFileAllowedTypes}
                  />
                </SidebarFormGroup>
              </Box>
            )}
          </SidebarFormGroup>
        </TabPanel>
      </Box>
    </>
  )
}
