import { useExperienceValidationSchema } from './useExperienceValidationSchema'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormMode } from '../../../../../interfaces/formModes/formMode'
import { useNavigate } from 'react-router'
import { ExperienceBuilderSection } from '../components/builder/utils/enums'
import { useOrganizationLanguages } from '../../../../../api/languages/queries/useOrganizationLanguages'
import { useCreateExperience } from 'api/experiences-v2/mutations/useCreateExperience'
import { useUpdateExperience } from 'api/experiences-v2/mutations/useUpdateExperience'
import { RoutesManager } from 'utils/routing/routesManager'
import { useExperience } from 'api/experiences-v2/queries/useExperience'
import { useIsEntitled, useIsPermitted } from 'utils/hooks'
import { useThemesPaginated } from 'api/themes-v3/queries/useThemesPaginated'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ConsentExperienceType } from 'interfaces/experiences-v2/consentExperienceType'
import { EntitlementLayoutConfig, PreferenceCenterPage } from '@ketch-sdk/ketch-types'
import { useFormTemplatesInfinite } from 'api/formTemplates/queries/useFormTemplatesInfinite'
import { useJurisdictionsCanonicalRights } from 'api/rights/queries/useJurisdictionsCanonicalRights'
import { transformJurisdictionCanonicalRightsToExperienceCanonicalRightFormTemplates } from './transformJurisdictionCanonicalRightsToExperienceCanonicalRightFormTemplates'
import { getExperienceSummary } from './utils'
import { useTheme } from 'api/themes-v3/queries/useTheme'
import { useSubscriptionTopicsPaginated } from 'api/subscriptions/queries/useSubscriptionTopicsPaginated'
import { showToast } from 'components/modals/AlertComponent'
import { useExperienceInitialValues } from './useExperienceInitialValues'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { defaultThemeV3DTO } from 'pages/consentAndRights/themes-v3/upsert/utils/defaults'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'

type OptionType = { label?: string; value?: string }

export const useExperienceUpsertUtils = () => {
  // Get URL param info
  const { id, experienceType, formMode } = useParams<{
    id?: string
    experienceType: ExperienceType
    formMode: FormMode
  }>()
  const isEditMode = formMode === FormMode.EDIT
  const isDuplicateMode = formMode === FormMode.DUPLICATE

  // Utils
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const { isEntitled } = useIsEntitled()

  // Get entitlement info
  const hasPurposesEntitlement = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)
  const hasSubscriptionsEntitlement = isEntitled(ENTITLEMENTS.SUBSCRIPTIONS)
  const hasRightsEntitlement = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)
  const entitlementInfo: EntitlementLayoutConfig = {
    hasPurposesEntitlement,
    hasSubscriptionsEntitlement,
    hasRightsEntitlement,
  }
  const entitledPreferencePages: PreferenceCenterPage[] = useMemo(() => {
    return [
      PreferenceCenterPage.Welcome,
      ...(hasPurposesEntitlement ? [PreferenceCenterPage.Purpose] : []),
      ...(hasSubscriptionsEntitlement ? [PreferenceCenterPage.Subscriptions] : []),
      ...(hasRightsEntitlement ? [PreferenceCenterPage.RequestsHome] : []),
      ...(hasRightsEntitlement ? [PreferenceCenterPage.RequestsForm] : []),
      ...(hasRightsEntitlement ? [PreferenceCenterPage.RequestsSubmitted] : []),
    ]
  }, [hasPurposesEntitlement, hasSubscriptionsEntitlement, hasRightsEntitlement])

  // Languages
  const { data: languages, isLoading: isLanguagesLoading } = useOrganizationLanguages()

  // Canonical Rights
  const { data: canonicalRights, isLoading: isCanonicalRightsLoading } = useJurisdictionsCanonicalRights()
  const canonicalRightTemplates =
    transformJurisdictionCanonicalRightsToExperienceCanonicalRightFormTemplates(canonicalRights)

  // Subscriptions
  const { data: allSubscriptionTopics } = useSubscriptionTopicsPaginated({
    params: {
      includeMetadata: true,
      limit: 1000,
    },
  })

  // Forms
  const { data: formTemplates, isLoading: isFormTemplatesLoading } = useFormTemplatesInfinite({
    options: {
      itemsPerPage: 1000,
      enabled: true,
    },
    params: {
      ordering: { created_at: 'desc' },
      includeMetadata: true,
    },
  })
  const formTemplateOptions: OptionType[] = formTemplates
    .filter(form => !!form.id && !!form.name)
    .map(form => ({
      value: form.id!,
      label: form.name!,
    }))
  const defaultFormTemplate = formTemplates.find(form => form.code === 'default')

  // States
  const [consentExperienceType, setConsentExperienceType] = useState(ConsentExperienceType.Banner)
  const [experienceBuilderSection, setExperienceBuilderSection] = useState<ExperienceBuilderSection | undefined>()
  const [preferencePage, setPreferencePage] = useState<PreferenceCenterPage>(PreferenceCenterPage.Welcome)
  const [customFormID, setCustomFormID] = useState<string>()

  // API Hooks
  const { data: experience, isLoading: isExperienceLoading } = useExperience({
    enabled: isEditMode || isDuplicateMode,
    params: {
      id: id || '',
      includeMetadata: true,
      includeIssues: true,
    },
    onError: () => navigate(RoutesManager.deployment.experiencesV2.root.getURL()),
  })
  const { mutateAsync: handleCreateExperience, isLoading: isExperienceCreateLoading } = useCreateExperience({
    onSuccess: response => {
      if (response?.data?.id) {
        navigate(RoutesManager.deployment.experiencesV2.view.preview.root.getURL({ id: response.data.id }))
      } else {
        navigate(RoutesManager.deployment.experiencesV2.root.getURL())
      }
      showToast({ content: 'Created Experience', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to create experience', type: 'error' })
    },
  })
  const { mutateAsync: handleUpdateExperience, isLoading: isExperienceUpdateLoading } = useUpdateExperience({
    onSuccess: response => {
      if (response?.data?.id) {
        navigate(RoutesManager.deployment.experiencesV2.view.preview.root.getURL({ id: response.data.id }))
      } else {
        navigate(RoutesManager.deployment.experiencesV2.root.getURL())
      }
      showToast({ content: 'Updated Experience', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update experience', type: 'error' })
    },
  })

  // Theme
  const { data: themes, isLoading: isThemesLoading } = useThemesPaginated({
    params: {
      includeMetadata: true,
    },
  })
  const defaultThemeOption = {
    label: 'No Theme',
    value: 'default',
  }
  const themeOptions = [
    defaultThemeOption,
    ...themes.filter(theme => !!theme.summary).map(theme => ({ label: theme.name, value: theme.id })),
  ]
  const [themeConfig, setThemeConfig] = useState(defaultThemeV3DTO)
  const [selectedThemeOption, setSelectedThemeOption] = useState<OptionType>(defaultThemeOption)

  // API Hooks
  useTheme({
    enabled: selectedThemeOption.value !== 'default',
    params: {
      id: selectedThemeOption.value || '',
      includeMetadata: true,
    },
    onSuccess: theme => setThemeConfig(theme.data),
    onError: () => navigate(RoutesManager.deployment.themesV3.root.getURL()),
  })

  const handleUpsertExperience = isEditMode ? handleUpdateExperience : handleCreateExperience

  // Form config
  const initialValues = useExperienceInitialValues(
    formMode ?? FormMode.CREATE,
    experienceType ?? ExperienceType.Consent,
    experience,
    entitlementInfo,
    defaultFormTemplate,
    allSubscriptionTopics?.topics ?? [],
  )

  const validationSchema = useExperienceValidationSchema()
  const onSubmit = async (formData: ExperienceV2DTO) => {
    // Compute experience summary data
    const summary = getExperienceSummary(formData, formTemplateOptions, allSubscriptionTopics?.topics ?? [])
    const formDataWithSummary: ExperienceV2DTO = {
      ...formData,
      summary,
    }

    logObjectDetails(formDataWithSummary, 'useExperienceUpsertUtils.ts')

    // Upsert experience
    try {
      await handleUpsertExperience({
        params: {
          formData: formDataWithSummary,
          id: id || '',
        },
      })
    } catch {
      showToast({ content: 'Failed to create experience', type: 'error' })
    }
  }

  return {
    id,
    isPermitted,
    isEntitled,
    isEditMode,
    experience,
    isExperienceLoading,
    languages,
    isLanguagesLoading,
    formTemplateOptions,
    isFormTemplatesLoading,
    canonicalRightTemplates,
    isCanonicalRightsLoading,
    isExperienceCreateLoading,
    isExperienceUpdateLoading,
    experienceType,
    formMode,
    navigate,
    themes,
    selectedThemeOption,
    setSelectedThemeOption,
    defaultThemeOption,
    themeOptions,
    isThemesLoading,
    initialValues,
    validationSchema,
    onSubmit,
    consentExperienceType,
    setConsentExperienceType,
    experienceBuilderSection,
    setExperienceBuilderSection,
    themeConfig,
    setThemeConfig,
    preferencePage,
    setPreferencePage,
    allSubscriptionTopics,
    hasPurposesEntitlement,
    hasSubscriptionsEntitlement,
    hasRightsEntitlement,
    entitledPreferencePages,
    customFormID,
    setCustomFormID,
  }
}

export type UseExperienceUpsertUtilsReturnType = ReturnType<typeof useExperienceUpsertUtils>
