import { ApplicationEnvironmentDTO } from 'interfaces/applications/application'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  applicationCode: string
  environmentCode: string
  formData: {
    deploymentID?: string
  }
}

export const updateApplicationEnvironment = ({
  organizationCode,
  applicationCode,
  environmentCode,
  formData,
}: Params) =>
  API.put<ApplicationEnvironmentDTO>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/applications/${applicationCode}/environments/${environmentCode}/configuration`,
    }),
    formData,
  )
