import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useUser } from 'api/users/queries/useUser'
import { useDeleteUser } from 'api/users/mutations/useDeleteUser'
import { showToast } from 'components/modals/AlertComponent'
import { joinNonEmpty } from '../../../utils/helpers/joinNonEmpty'
import { Button, PopUp } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  userId: string
  onSubmit?: () => Promise<void>
  onCancel: () => void
}

export const DeleteUserModal: React.FC<Props> = props => {
  const queryClient = useQueryClient()

  const { userId, onSubmit, onCancel } = props
  const [isBusy, setIsBusy] = useState(false)

  const { data: user, isLoading: isUserLoading } = useUser({
    enabled: !!userId,
    params: {
      userId,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch user', type: 'error' })
      onCancel()
    },
  })

  const { mutate: handleDeleteUser } = useDeleteUser({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'User deleted', type: 'success' })

      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to delete user', type: 'error' })
      setIsBusy(false)
    },
  })

  const userName = user?.firstName ? joinNonEmpty([user?.firstName, user?.lastName]) : user?.email

  return (
    <PopUp
      isLoading={isUserLoading}
      title="Delete User"
      variant="dialog"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Close
          </Button>
          <Button
            pending={isBusy}
            color="primary"
            size="large"
            onClick={() => {
              handleDeleteUser({
                params: {
                  userId,
                },
              })
            }}
          >
            Delete
          </Button>
        </>
      }
    >
      <Typography variant="body">
        Are you sure you want to delete <strong>{userName}</strong>?
      </Typography>
    </PopUp>
  )
}
