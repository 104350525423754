import { makeStyles, createStyles } from '@mui/styles'

export const useAutocompleteStyles = makeStyles(
  ({ palette, spacing, typography }) =>
    createStyles({
      inputLabelRoot: {
        fontSize: typography.pxToRem(14),
        fontWeight: 600,
        color: 'black',
        opacity: 1,
        '&.Mui-focused': {
          color: 'black',
          fontWeight: 600,
        },
      },
      inputRoot: {
        border: `1px solid ${palette.iron.main}`,
        borderRadius: spacing(1),
        fontFamily: typography.fontFamily,
        color: palette.darkDusk.main,
        background: palette.white.main,

        '&.MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
          padding: spacing(0.75, 0.75),
        },

        '&.Mui-error': {
          borderColor: `${palette.chileanFire.main} !important`,
        },
      },
      selectedValueTag: {
        backgroundColor: palette.fadedGrey.main,
        borderRadius: spacing(4),
        padding: spacing(0.75, 1.75),
      },
      removeSelectedValueBadgeIcon: {
        cursor: 'pointer',
        marginLeft: spacing(1.5),
        height: typography.pxToRem(14),
        width: typography.pxToRem(14),
      },
      proxyInput: {
        border: `1px solid ${palette.iron.main}`,
        borderRadius: spacing(1),
        fontFamily: typography.fontFamily,
        color: palette.darkDusk.main,
        background: palette.white.main,
        padding: spacing(0.75, 0.75),
      },
      proxyLabelsInput: {
        border: `1px solid ${palette.iron.main}`,
        borderRadius: spacing(1),
        fontFamily: typography.fontFamily,
        color: palette.darkDusk.main,
        background: palette.white.main,
        padding: spacing(1, 0.75),
      },
      attributeSelectOptionContainer: {
        display: 'flex',
        flexDirection: 'column',
      },
      hidden: {
        opacity: 0,
      },
    }),
  { name: 'CreatePolicyAutocomplete' },
)
