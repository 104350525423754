import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { updateUserProfile } from 'api/users/fetchers/updateUserProfile'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomMutation = createUseMutation({
  mutationFn: updateUserProfile,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateUserProfile = (config?: Config) => {
  const { userData } = useAuth()
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    userId: userData.userId,
  })
}
