import { experienceUpsertFormKeys } from '../../../../utils/useExperienceValidationSchema'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { FormTextInput } from '../FormTextInput'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { useContext } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'

export const ModalExperienceFooterForm: React.FC = () => {
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length
  return (
    <>
      {/* GPC descoped */}
      {/* <FormControlLabel
        control={<FormCheckbox name={experienceUpsertFormKeys.modalFooterGpcBadgeVisible} />}
        label={<Typography variant={'label'}>GPC Signal Indicator</Typography>}
      /> */}
      <SidebarFormGroup hasBorderBottom={hasLanguages} sx={{ pt: 0, m: 0 }}>
        <FormTextInput
          label={'Action Button Text'}
          name={experienceUpsertFormKeys.modalFooterActionButtonText}
          fullWidth
        />
      </SidebarFormGroup>
      <SidebarFormTranslations
        title={'Action Button Text Translations'}
        name={experienceUpsertFormKeys.modalFooterActionButtonText}
        variant={'text'}
      />
    </>
  )
}
