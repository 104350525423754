import React, { useContext, useState, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { FieldArray, useFormikContext } from 'formik'
import { styled } from '@mui/material/styles'
import { PreferenceField } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import {
  UnsubscribeAllTabs,
  CONTROL_DEFAULTS,
  SWITCH_DEFAULT_TEXT,
  GlobalControl,
} from 'pages/consentAndRights/experiences/upsert/components/UpsertPreference/components/Sidebar/components/SubscriptionFields/constants'
import { theme, Tab, TabGroup, TabPanel, SwitchToggle } from '@ketch-com/deck'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { Spinner } from '@ketch-com/deck'
import { ExperienceSwitchRenderLogic } from 'interfaces/experiences/experienceSwitchRenderLogic'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { Description, Impact, Position, SwitchButton, Title } from './components'

const UnsubscribeAllContainer = styled(Box)(({ theme }) => ({
  borderRadius: 11,
  padding: theme.spacing(1.75, 2.25),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1.5),
}))

type Props = {}

export const SubscriptionControl: React.FC<Props> = () => {
  const { isPermitted } = useIsPermitted()
  const hasDemoPermissions = isPermitted(PERMISSIONS.DEMO)
  const { sidebarActiveTab } = useContext(ExperienceUpsertContext)
  const [activeTab, setActiveTab] = useState(0)
  const { values } = useFormikContext<PreferenceExperienceFormValues>()
  const isUnsubscribeAllEnabled = Boolean(values?.subscriptions?.controlItems.length)
  const { data: orgLangs, isLoading: isLoadingOrgLangs } = useOrganizationLanguages()
  const organizationLanguages = useMemo(() => orgLangs.filter(({ system }) => !system), [orgLangs])

  if (sidebarActiveTab !== PreferenceField.SUBSCRIPTIONS_CONTROL) return null

  if (isLoadingOrgLangs)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" py={4}>
        <Spinner size={35} thickness={3} />
      </Box>
    )

  return (
    <Box>
      <FieldArray
        name="subscriptions.controlItems"
        render={arrayHelpers => {
          return (
            <Box display="flex" flexDirection="column" gap={0.5}>
              <UnsubscribeAllContainer
                sx={{
                  backgroundColor: `${
                    isUnsubscribeAllEnabled ? theme.palette.tara.main : theme.palette.fadedGrey.main
                  }`,
                }}
              >
                <SwitchToggle
                  size="medium"
                  onChange={(e, enableUnsubscribeAll) => {
                    if (enableUnsubscribeAll) {
                      arrayHelpers.push({
                        type: 2,
                        code: GlobalControl.Code,
                        itemDetail: {
                          title: CONTROL_DEFAULTS.TITLE,
                          description: '',
                          switchOffText: SWITCH_DEFAULT_TEXT.OPT_OUT,
                          switchOnText: SWITCH_DEFAULT_TEXT.OPT_IN,
                          switchTextRenderLogic: ExperienceSwitchRenderLogic.RENDER_ALL,
                          positionType: 2,
                          impactType: 1,
                        },
                      })
                    } else {
                      arrayHelpers.remove(0)
                    }
                  }}
                  checked={isUnsubscribeAllEnabled}
                />
                <Typography variant="h4" color={isUnsubscribeAllEnabled ? 'greenHaze.main' : ''}>
                  Unsubscribe From All
                </Typography>
              </UnsubscribeAllContainer>

              {!isUnsubscribeAllEnabled ? (
                <Typography variant="body" color="darkDuskFaded.main">
                  Active Global Control is recommended for 2+ subscriptions in the list.{' '}
                </Typography>
              ) : null}
            </Box>
          )
        }}
      />

      {isUnsubscribeAllEnabled ? (
        <Box>
          <TabGroup size="small" value={activeTab}>
            <Tab label={UnsubscribeAllTabs.Title} onClick={() => setActiveTab(0)} />
            <Tab label={UnsubscribeAllTabs.Description} onClick={() => setActiveTab(1)} />
            <Tab label={UnsubscribeAllTabs.SwitchButton} onClick={() => setActiveTab(2)} />
            <Tab label={UnsubscribeAllTabs.Position} onClick={() => setActiveTab(3)} />
            {/* TODO: remove demo permissions once impact feature is completed */}
            {hasDemoPermissions ? <Tab label={UnsubscribeAllTabs.Impact} onClick={() => setActiveTab(4)} /> : null}
          </TabGroup>

          <TabPanel value={activeTab} index={0} sx={{ padding: theme.spacing(3, 0, 0) }}>
            <Title organizationLanguages={organizationLanguages} />
          </TabPanel>

          <TabPanel value={activeTab} index={1} sx={{ padding: theme.spacing(3, 0, 0) }}>
            <Description organizationLanguages={organizationLanguages} />
          </TabPanel>

          <TabPanel value={activeTab} index={2} sx={{ padding: theme.spacing(3, 0, 0) }}>
            <SwitchButton organizationLanguages={organizationLanguages} />
          </TabPanel>

          <TabPanel value={activeTab} index={3} sx={{ padding: theme.spacing(2, 0, 0) }}>
            <Position />
          </TabPanel>

          <TabPanel value={activeTab} index={4} sx={{ padding: theme.spacing(2, 0, 0) }}>
            <Impact />
          </TabPanel>
        </Box>
      ) : null}
    </Box>
  )
}
