import { flatMap } from 'lodash'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useQueries } from 'react-query'
import { fetchDsrPolicies } from 'api/dsrPolicy/fetchers/fetchDsrPolicies'
import { PolicyDTO } from '@ketch-com/figurehead'

export const useDsrPolicies = (dsrPolicyIdArray: string[]) => {
  const queries = dsrPolicyIdArray.map(dsrPolicyId => ({
    queryKey: [ApiQueryKeys.dsrPolicies, { dsrPolicyId }],
    queryFn: fetchDsrPolicies,
  }))

  const results = useQueries(queries as any).map((r: any) => ({
    dsrPolicies: r.data || [],
    isLoading: r?.isLoading || r?.isFetching || false,
  }))

  const dsrPolicies = flatMap(results, result => result.dsrPolicies?.data?.policy as PolicyDTO[])

  return { dsrPolicies, isLoading: results.some(r => r?.isLoading) }
}
