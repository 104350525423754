import React, { useMemo } from 'react'

import { MaybeNull } from 'interfaces'
import { ActionSheetItem, DropListButton, ListItemText } from '@ketch-com/deck'

type Props = {
  value?: string | null
  placeholder?: string
  onChange: (value: MaybeNull<string>) => void
  pending?: boolean
}

const options = [
  {
    value: 'all',
    label: 'All Statuses',
  },
  {
    value: 'true',
    label: 'Enabled',
  },
  {
    value: 'false',
    label: 'Disabled',
  },
]

export const StatusDropList: React.FC<Props> = props => {
  const { value, onChange, placeholder = 'Status', pending } = props

  const currentValue = useMemo(() => {
    const _value = value || 'all'

    return options.find(elem => elem.value === _value)
  }, [value])

  return (
    <DropListButton
      disableClearable
      isOptionEqualToValue={(option, value) => option === value}
      renderOption={(props, option, { selected }) => {
        return (
          <ActionSheetItem selected={selected} {...props}>
            <ListItemText selected={selected}>{option.label}</ListItemText>
          </ActionSheetItem>
        )
      }}
      options={options}
      onChange={(_, value) => value && onChange(value.value === 'all' ? null : value.value)}
      value={currentValue}
      placeholder={value ? placeholder : 'All Statuses'}
      size="small"
      loading={pending}
    />
  )
}
