import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { RoutesManager } from 'utils/routing/routesManager'
import { ThemeDetailsTab } from 'pages/consentAndRights/themes/view/tabs/ThemeDetailsTab'
import { ThemeVersionsTab } from 'pages/consentAndRights/themes/view/tabs/ThemeVersionsTab'

type Props = {
  theme: ThemeDTO
  versions: ThemeDTO[]
}

export const ThemeViewRoutes: React.FC<Props> = ({ theme, versions }) => {
  const location = useLocation()

  return (
    <Routes location={location}>
      <Route path="/" element={<ThemeDetailsTab theme={theme} />} />
      <Route
        path={RoutesManager.deployment.themes.view.details.root.pattern}
        element={<ThemeDetailsTab theme={theme} />}
      />

      <Route
        path={RoutesManager.deployment.themes.view.versions.root.pattern}
        element={<ThemeVersionsTab theme={theme} versions={versions} />}
      />
    </Routes>
  )
}
