import React, { createContext } from 'react'
import { ExperiencePreviewUtils, useExperiencePreviewUtils } from '../hooks/useExperiencePreviewUtils'

export const ExperiencePreviewContext = createContext({} as ExperiencePreviewUtils)

type Props = {
  children?: React.ReactNode
}

export const ExperiencePreviewContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useExperiencePreviewUtils()

  return (
    <ExperiencePreviewContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </ExperiencePreviewContext.Provider>
  )
}

export const withExperiencePreviewContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <ExperiencePreviewContextProvider>
        <Component {...props} />
      </ExperiencePreviewContextProvider>
    )
  }
}
