import { useMemo } from 'react'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { useContext } from 'react'
import { Formik } from 'formik'
import { getIdentitySpacesInitialValues, getIdentitySpacesValidationSchema } from './utils'
import { TaskContext } from '../../../../context'
import { ContentGroup } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { IdentitySpacesEditableInfoRow } from 'pages/orchestration/rightsQueue/stepDetails/components/IdentitySpacesEditableInfoRow'

type Props = {}

export const IdentitySpaces: React.FC<Props> = () => {
  const { isTaskComplete, stepDetails, hasAttemptedToResolve } = useContext(
    RightInvocationWorkflowStepDetailViewContext,
  )
  const { canEdit, isEditMode } = useContext(TaskContext)
  const shouldPreventEditOfCompletedTaskNotInEditMode = isTaskComplete && !isEditMode

  const initialValues = useMemo(() => getIdentitySpacesInitialValues(stepDetails), [stepDetails])
  const validationSchema = useMemo(() => getIdentitySpacesValidationSchema(), [])

  if (!stepDetails?.stepIdentitySpaces) return null

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={() => {}}
    >
      {({ errors, values }) => {
        return (
          <Box display="flex" flexDirection="column" gap={4}>
            <ContentGroup variant="inner-page-header" titleVariant="h3" title="Identifiers" />
            {values?.identitySpaces?.map((identitySpace, variableIndex) => {
              const canEditInfoRow = canEdit && !shouldPreventEditOfCompletedTaskNotInEditMode
              return (
                <Box
                  key={variableIndex}
                  pb={values?.identitySpaces?.length && variableIndex === values?.identitySpaces?.length - 1 ? 4 : 0}
                >
                  <IdentitySpacesEditableInfoRow
                    canEdit={canEditInfoRow}
                    code={identitySpace.code || ''}
                    defaultValue={identitySpace.value || ''}
                    hasAttemptedToResolve={hasAttemptedToResolve}
                    identitySpace={identitySpace}
                    identitySpaces={values.identitySpaces}
                    infoRowTitle={identitySpace.name || ''}
                    isRequired={identitySpace.isRequired}
                    name={identitySpace.name || ''}
                    placeholder="Enter Value"
                    showOptionalLabel={!identitySpace.isRequired}
                    showRequiredLabel={identitySpace.isRequired}
                    showValidation={hasAttemptedToResolve && identitySpace.isRequired && !identitySpace.value}
                  />
                </Box>
              )
            })}
          </Box>
        )
      }}
    </Formik>
  )
}
