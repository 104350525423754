import React, { createContext } from 'react'
import { PrivacyProtocolsUtils, usePrivacyProtocolsUtils } from '../utils/usePrivacyProtocolsUtils'

export const PrivacyProtocolsContext = createContext({} as PrivacyProtocolsUtils)

type Props = {
  children?: React.ReactNode | undefined
}

export const PrivacyProtocolsContextProvider: React.FC<Props> = ({ children }) => {
  // Use utils hook
  const hookValues = usePrivacyProtocolsUtils()

  return <PrivacyProtocolsContext.Provider value={{ ...hookValues }}>{children}</PrivacyProtocolsContext.Provider>
}

export const withPrivacyProtocolsContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <PrivacyProtocolsContextProvider>
        <Component {...props} />
      </PrivacyProtocolsContextProvider>
    )
  }
}
