import React, { useContext } from 'react'
import { ExperienceViewContext } from '../context/ExperienceViewContext'
import { ContentGroup, DataGrid, EmptyState, Spinner } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { experienceDeploymentsColumns } from '../utils/experienceDeploymentsGridUtils'

export const ExperienceDeployments: React.FC = () => {
  const { experience, deploymentPlans, isDeploymentPlansLoading } = useContext(ExperienceViewContext)

  const columns = experienceDeploymentsColumns({
    deploymentPlans,
  })

  const { propertyEnvironments } = experience ?? {}

  if (isDeploymentPlansLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" padding={2} height="100%">
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column" gap={4} mb={4}>
      <ContentGroup title="Workflow Details" titleVariant="h3" variant="inner-page-header" />

      {propertyEnvironments?.length ? (
        <DataGrid
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: false,
            columns: columns.map(column => column.field),
            expand: true,
          }}
          getRowHeight={() => 'auto'}
          columns={columns}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowHoverStates
          disableRowSelectionOnClick
          getRowId={row => row.ID}
          rows={propertyEnvironments}
          hideFooter
        />
      ) : (
        <Box
          sx={{
            backgroundColor: 'bone.main',
            borderRadius: '11px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 5,
          }}
        >
          <EmptyState
            title="Experience not used in any deployments"
            subTitle="Once a relevant deployment plan uses this experience, it will be displayed here."
            iconName="OJoin"
          />
        </Box>
      )}
    </Box>
  )
}
