import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  code: string
}

export const checkIsThemeCodeAvailable = ({ organizationCode, code }: Params) =>
  API.get<{ available?: boolean }>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/themes/${code}/available`,
    }),
  ).then(({ data }) => !!data?.available)
