import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Type = 'all' | 'access' | 'rights' | 'retention'

export type Status = '' | 'awaiting-approval' | 'pending' | 'enabled' | 'disabled'

interface PoliciesListFilterState {
  searchQuery: string
  type: Type
  status: Status
}

const initialState = {
  searchQuery: '',
  type: 'all',
  status: '',
} as PoliciesListFilterState

export const policiesListFilterSlice = createSlice({
  name: 'policiesListFilterSlice',
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload
    },
    clearSearchQuery: state => {
      state.searchQuery = ''
    },

    setTypeFilter: (state, action: PayloadAction<Type>) => {
      state.type = action.payload
    },

    setStatusFilter: (state, action: PayloadAction<Status>) => {
      state.status = action.payload
    },
    clearStatusFilter: state => {
      state.status = ''
    },
  },
})

export const { setTypeFilter, setSearchQuery, clearSearchQuery, setStatusFilter, clearStatusFilter } =
  policiesListFilterSlice.actions

export default policiesListFilterSlice.reducer
