import React from 'react'

import { ApiKeyDTO } from 'interfaces/apiKeys'
import { ApiKeyStatus } from 'interfaces/apiKeys'
import { Status, StatusState, StatusVariant } from '@ketch-com/deck'

type Props = {
  apiKey: ApiKeyDTO
}

export const ApiKeyViewDetails: React.FC<Props> = props => {
  const { apiKey } = props

  return (
    <Status
      icon="Dot"
      label={apiKey.status === ApiKeyStatus.ENABLED ? 'Active' : 'Deactivated'}
      variant={StatusVariant.outlined}
      status={apiKey.status === ApiKeyStatus.ENABLED ? StatusState.success : StatusState.draft}
    />
  )
}
