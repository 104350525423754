export const getAutocompleteNameError = ({
  errors,
  valueKey,
  index,
}: {
  errors: any
  valueKey: string
  index: number
}): any => {
  switch (valueKey) {
    case 'accessAllowances':
      return !!errors?.accessAllowances?.[index]?.labelName || undefined
    case 'attributeEffects':
      return !!errors?.attributeEffects?.[index]?.labelName || undefined
    case 'filters':
      return !!errors?.filters?.[index]?.name || undefined
    default:
      return false
  }
}
