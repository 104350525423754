import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks'

export const useEntitlements = () => {
  const { isEntitled } = useIsEntitled()
  const isEntitledToConsentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)
  const isEntitledToRights = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)
  const isEntitledToSubscriptions = isEntitled(ENTITLEMENTS.SUBSCRIPTIONS)

  return {
    isEntitledToConsentAndDisclosure,
    isEntitledToRights,
    isEntitledToSubscriptions,
  }
}
