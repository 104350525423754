import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { UpdateSkipForDSRConfigRequestBodyDTO } from '@ketch-com/figurehead'

type Params = {
  scenarioId: string
  assetId: string
  payload: UpdateSkipForDSRConfigRequestBodyDTO
  isV2FeatureFlagEnabled?: boolean
}

export const skipDatasetFromScenario = ({ scenarioId, assetId, payload, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/assets/${assetId}/set-dsr-skip`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/assets/${assetId}/set-dsr-skip`

  return API.post<Response>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          scenarioId,
        },
      },
    }),
    payload,
  )
}
