import React from 'react'
import { Modal } from 'components/ui-kit/modal/Modal'
import { Box } from '@mui/material'
import { LabelDTO } from '@ketch-com/figurehead'
import { Chip } from '@ketch-com/deck'

type Props = {
  classifications?: LabelDTO[]
  onCancel: () => void
}

export const ClassificationsModal: React.FC<Props> = ({ classifications, onCancel }) => {
  return (
    <Modal title={'Classification'} onCancelBtnClick={onCancel} cancelBtnLabel="Close" contentWidth={750}>
      <Box mt={2} display="flex" gap={2} flexWrap="wrap">
        {classifications?.map(classification => {
          const classificationName = classification?.name || classification?.value || ''
          return <Chip key={classification?.code} size="small" label={classificationName} />
        })}
      </Box>
    </Modal>
  )
}
