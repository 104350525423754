import { createContext, useContext } from 'react'
import { PlanAndBillingUtils } from 'components/planAndBilling/common/types/planAndBilling'
import { usePlanAndBillingUtils } from 'components/planAndBilling/common/hooks/usePlanAndBillingUtils'

export const PlanAndBillingContext = createContext({} as PlanAndBillingUtils)

export const usePlanAndBilling = () => {
  const context = useContext(PlanAndBillingContext)
  if (context === undefined) {
    throw new Error('usePlanAndBilling must be used within a PlanAndBillingContextProvider')
  }
  return context
}

type Props = {
  children?: React.ReactNode
}

export const PlanAndBillingContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = usePlanAndBillingUtils()

  return (
    <PlanAndBillingContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </PlanAndBillingContext.Provider>
  )
}

export const withPlanAndBillingContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <PlanAndBillingContextProvider>
        <Component {...props} />
      </PlanAndBillingContextProvider>
    )
  }
}
