import { LanguageDTO, LanguagesConfigurationFormDTO } from 'interfaces/languages/language'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIRequestParams } from 'api/common/utils'

type Params = APIRequestParams<{
  organizationCode: string
  formData: LanguagesConfigurationFormDTO
}>

type Response = { languages: LanguageDTO[] }

export const updateLanguagesConfiguration = ({ organizationCode, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/languages`,
    }),
    formData,
  )
