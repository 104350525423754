import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchWorkflowExecution } from 'api/workflowExecutions/fetchers/fetchWorkflowExecution'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.workflowExecution,
  queryFn: fetchWorkflowExecution,
  select: res => res?.data?.workflowExecution || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery, 'organizationCode'>

export const useWorkflowExecution = (config: Config) => {
  const { userData } = useAuth()

  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
      organizationCode: userData.organizationCode!,
    },
    staleTime: 30 * 1000, // 30 seconds
  })
}
