import React, { useState } from 'react'
import { WebTagDTO } from 'interfaces/webtags/WebTags'
import { useQueryClient } from 'react-query'
import { Button, Icon, TableCell, theme } from '@ketch-com/deck'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useUpdateWebTagStatus } from 'api/webtags/mutations/useUpdateWebTagStatus'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { Tooltip, Typography } from '@mui/material'
import { useTagsList } from '../context/TagsContext'

type Props = {
  tag: WebTagDTO
  disabled?: boolean
}

// Table cell that can be clicked to enable or disable a tag
export const TagStatusUpdateCell: React.FC<Props> = ({ tag, disabled = false }) => {
  const { isTagsWriter } = useTagsList()
  const queryClient = useQueryClient()
  const isEnabled = tag.enabled || false
  const [isHovered, setIsHovered] = useState(false)

  // Update status hook
  const { mutate: handleUpdateWebTag } = useUpdateWebTagStatus({
    onSuccess: async () => {
      await queryClient.invalidateQueries(ApiQueryKeys.webTagsPaginated)
    },
    onError: () => {
      showToast({ content: 'Failed to update status', type: 'error' })
    },
  })

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) return

    // Stop propagation so this click doesn't select the while row
    e.stopPropagation()

    // Update tag status
    handleUpdateWebTag({
      params: {
        webTagId: tag.ID,
        formData: {
          ...tag,
          // Flip enabled status
          enabled: !isEnabled,
        },
      },
    })
  }

  const text = isEnabled ? 'Enabled' : 'Disabled'

  // The disabled message to show when the user has write permissions but the button is disabled for
  // some other reason
  const disabledMessage = tag.advancedConsentModeEnabled
    ? 'This tag cannot be disabled when built-in consent checks are turned on'
    : 'Assign a purpose to enable this tag'

  return (
    <Tooltip
      title={
        disabled
          ? isTagsWriter
            ? disabledMessage
            : 'You do not have the required permission to enable and disable tags'
          : ''
      }
      placement="bottom-start"
    >
      <div>
        <TableCell
          onClick={handleClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          // Only show hover affects when not disabled
          sx={{
            ...(disabled
              ? { pointerEvents: 'none' }
              : { ':hover': { textDecoration: 'underline', cursor: 'pointer' } }),
          }}
        >
          {/* Icon button */}
          <Button
            disabled={disabled}
            variant={isEnabled ? 'contained' : 'iconCustomRounded'}
            color={isEnabled ? 'primary' : 'tertiary'}
            children={
              isEnabled ? <Icon name="OCheckRound2" iconColor={theme.palette.white.main} /> : <Icon name="ODecline" />
            }
            sx={{
              width: 32,
              height: 32,
              borderRadius: '100%',
              padding: 0.25,
              textDecoration: 'none !important',
            }}
          />

          {/* Text */}
          <Typography
            variant="smallLabel"
            color={isEnabled ? theme.palette.Action.Primary : theme.palette.Text.Secondary}
            sx={{
              textDecoration: isHovered ? 'underline' : 'none',
              opacity: disabled ? '0.3' : '1.0',
              ...(disabled ? {} : { ':hover': { textDecoration: 'underline' } }),
            }}
          >
            {text}
          </Typography>
        </TableCell>
      </div>
    </Tooltip>
  )
}
