import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { Card, Chip, Icon, theme } from '@ketch-com/deck'
import Typography from '@mui/material/Typography'
import { isUndefined } from 'lodash'
import { AssessmentProgressDetails } from '../../../assessments/components/AssessmentProgressDetails'
import { ProcessingActivitiesContext } from '../context/ProcessingActivitiesContext'
import { RoutesManager } from 'utils/routing/routesManager'
import {
  ProcessingActivityAssessmentRequirementDTO,
  ProcessingActivityDTO,
  ProcessingActivityStatusDTO,
} from '@ketch-com/figurehead'
import { ProcessingActivityAssessmentRequirementLabel, ProcessingActivityTypeLabel } from '../../constants'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { FormMode } from 'interfaces/formModes/formMode'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

/**
 * Determine the text element to show for an assessment requirement value
 * @param assessmentRequirement
 */
const renderAssessmentRequirement = (assessmentRequirement: ProcessingActivityAssessmentRequirementDTO) => {
  const str = ProcessingActivityAssessmentRequirementLabel[assessmentRequirement]
  switch (assessmentRequirement) {
    case ProcessingActivityAssessmentRequirementDTO.RequiredProcessingActivityAssessmentRequirement:
    case ProcessingActivityAssessmentRequirementDTO.RecommendedProcessingActivityAssessmentRequirement:
    case ProcessingActivityAssessmentRequirementDTO.MaybeRequiredProcessingActivityAssessmentRequirement:
      return <Typography variant="body">{str}</Typography>
    default:
      return (
        <Typography variant="body" color={theme.palette.darkDuskFaded.main}>
          {str}
        </Typography>
      )
  }
}

/**
 * Determine the text element to show for a status value
 * @param status
 */
export const renderStatus = (status: ProcessingActivityStatusDTO) => {
  let icon, text
  switch (status) {
    case ProcessingActivityStatusDTO.ApprovedProcessingActivityStatus: {
      icon = <Icon name="FCheckRound" iconColor={theme.palette.greenHaze.main} width={24} height={24} />
      text = (
        <Typography variant="smallLabel" color={theme.palette.greenHaze.main}>
          Approved
        </Typography>
      )
      break
    }
    case ProcessingActivityStatusDTO.PendingProcessingActivityStatus: {
      icon = <Icon name="OArrowWall" iconColor={theme.palette.sphere.main} width={24} height={24} />
      text = (
        <Typography variant="smallLabel" color={theme.palette.sphere.main}>
          Requested
        </Typography>
      )
      break
    }
    default: {
      icon = <></>
      text = (
        <Typography variant="smallLabel" color={theme.palette.darkDuskFaded.main}>
          Status Unknown
        </Typography>
      )
      break
    }
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start">
      {icon}
      {text}
    </Box>
  )
}

export const ProcessingActivityTile: React.FC<ProcessingActivityDTO> = ({
  id,
  name,
  status,
  assessmentRequirement,
  assessments,
  type,
  metadata,
}) => {
  const { navigate, handleDeleteProcessingActivity, refetchProcessingActivities } =
    useContext(ProcessingActivitiesContext)
  const { isPermitted } = useIsPermitted()

  const dropdownOptionsReader = [
    {
      label: 'View',
      onClick: () => {
        navigate(RoutesManager.policyCenter.processingActivities.view.overview.root.getURL({ id }))
      },
    },
  ]

  const dropdownOptionsWriter = [
    {
      label: 'Edit',
      onClick: () => {
        navigate(RoutesManager.policyCenter.processingActivities.upsert.getURL({ id, formMode: FormMode.EDIT }))
      },
    },
    { divider: true },
    {
      label: 'Delete',
      onClick: async () => {
        handleDeleteProcessingActivity({
          params: { activityId: id || '' },
          onSuccess: async () => {
            await refetchProcessingActivities()
          },
        })
      },
    },
  ]

  // Decide dropdown options based on permissions
  const dropdownOptions = isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_WRITE)
    ? [...dropdownOptionsReader, { divider: true }, ...dropdownOptionsWriter]
    : dropdownOptionsReader

  return (
    <Card
      sx={{
        cursor: 'pointer',
      }}
      onClick={() => navigate(RoutesManager.policyCenter.processingActivities.view.overview.root.getURL({ id }))}
      cardVariant="media"
      cardTitle={name}
      moreMenuItems={dropdownOptions}
      footerText={`Last Updated ${formatDateTimeFromUnix(metadata?.updatedAt)}`}
      cardContent={
        <>
          <Box>
            <Typography variant="smallLabel" mb="4px" display="block" color={theme => theme.palette.darkDuskFaded.main}>
              Status
            </Typography>
            {isUndefined(status) ? <Typography variant="smallLabel">Unknown</Typography> : renderStatus(status)}
          </Box>
          {/* Assessment - Render assessment progress if present, otherwise status string */}
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="smallLabel" color={theme.palette.darkDuskFaded.main}>
              Assessment
            </Typography>
            {isUndefined(assessments) || !assessments.length ? (
              renderAssessmentRequirement(
                assessmentRequirement ||
                  ProcessingActivityAssessmentRequirementDTO.UnspecifiedProcessingActivityAssessmentRequirement,
              )
            ) : (
              <Box display="flex" alignItems="center">
                <AssessmentProgressDetails assessment={assessments[0]} shorten />
              </Box>
            )}
          </Box>

          {/* Type */}
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="smallLabel" color={theme.palette.darkDuskFaded.main}>
              Type
            </Typography>
            {/** hmm ? */}
            <Chip
              size="small"
              sx={{ width: 'fit-content' }}
              label={type ? ProcessingActivityTypeLabel[type] : 'Unknown'}
            />
          </Box>
        </>
      }
    />
  )
}
