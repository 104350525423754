import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchAssetTablesPaginatedV3 } from '../fetchers/fetchAssetTablesPaginatedV3'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.assetTablesPaginatedV3,
  queryFn: fetchAssetTablesPaginatedV3,
  select: res => res?.data?.assets || null,
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useAssetTablesPaginatedV3 = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
