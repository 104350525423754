import React, { useContext, useMemo, useState } from 'react'

import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon, theme } from '@ketch-com/deck'
import { HeaderSectionWrapper } from './HeaderSectionWrapper'
import { AppNavigationContext } from '../../../context/AppNavigationContext'
import { Tooltip, Typography } from '@mui/material'
import { useIsEntitled, useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

interface DevelopersMenuOption {
  title: string
  route?: string
  isSubsectionTitle?: boolean
  entitlements?: ENTITLEMENTS[]
  permissions?: PERMISSIONS[]
}

const options: DevelopersMenuOption[] = [
  {
    title: 'Developers',
    isSubsectionTitle: true,
  },
  {
    title: 'Transponders',
    route: RoutesManager.developers.transponders.list.getURL(),
    entitlements: [ENTITLEMENTS.TRANSPONDERS],
    permissions: [PERMISSIONS.TRANSPONDER_READ],
  },
  {
    title: 'API Keys',
    route: RoutesManager.developers.apiKeys.root.getURL(),
    entitlements: [ENTITLEMENTS.API_KEY],
    permissions: [PERMISSIONS.API_KEY_READ],
  },
  {
    title: 'Webhooks',
    route: RoutesManager.developers.webhooks.list.getURL(),
    entitlements: [ENTITLEMENTS.WEBHOOKS],
    permissions: [PERMISSIONS.WEBHOOK_READ],
  },
  {
    title: 'Authenticators',
    route: RoutesManager.developers.authenticators.root.getURL(),
    entitlements: [ENTITLEMENTS.CUSTOM_AUTHENTICATORS],
    permissions: [PERMISSIONS.AUTHENTICATION_READ],
  },
  {
    title: 'Token Verifiers',
    route: RoutesManager.developers.verifiers.list.getURL(),
    entitlements: [ENTITLEMENTS.VERIFIED_IDENTITIES],
    permissions: [PERMISSIONS.AUTHENTICATION_READ],
  },
]

export const HeaderDevelopersLink: React.FC = () => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const { isEntitled } = useIsEntitled()
  const { setShowNotEntitledModal, setShowNotPermittedModal } = useContext(AppNavigationContext)

  // Action sheet state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const isOnDevelopersPage = window.location.pathname.includes('/developers')

  const displayedOptions = useMemo(
    () =>
      options.filter(
        option =>
          (!option.entitlements && !option.permissions) ||
          (isEntitled(option.entitlements || []) && isPermitted(option.permissions)),
      ),
    [isEntitled, isPermitted],
  )

  const isEntitledToDevelopers = useMemo(
    () =>
      isEntitled([
        ENTITLEMENTS.DEVELOPER_EXTENSIONS,
        ENTITLEMENTS.API_KEY,
        ENTITLEMENTS.CUSTOM_AUTHENTICATORS,
        ENTITLEMENTS.TRANSPONDERS,
        ENTITLEMENTS.VERIFIED_IDENTITIES,
        ENTITLEMENTS.WEBHOOKS,
      ]),
    [isEntitled],
  )

  const isPermittedToDevelopers = useMemo(
    () =>
      isPermitted(
        [
          PERMISSIONS.TRANSPONDER_READ,
          PERMISSIONS.API_KEY_READ,
          PERMISSIONS.HOOK_READ,
          PERMISSIONS.WEBHOOK_READ,
          PERMISSIONS.AUTHENTICATION_READ,
        ],
        false,
      ),
    [isPermitted],
  )

  // Show developers dropdown if entitled and permitted, otherwise not entitled or permitted modal
  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!isEntitledToDevelopers) {
      setShowNotEntitledModal(true)
    } else if (!isPermittedToDevelopers) {
      setShowNotPermittedModal(true)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleMenuItemClick = (route?: string) => {
    if (route) {
      navigate(route)
      setAnchorEl(null)
    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <HeaderSectionWrapper selected={isOnDevelopersPage} sx={{ ml: '8px' }}>
      {/* Developers routes menu */}
      <ActionSheetWrapper
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ mt: '20px' }}
      >
        {displayedOptions.map(({ title, route, isSubsectionTitle }) => {
          const selected = !!route && window.location.pathname.includes(route)

          return (
            <ActionSheetItem
              key={title}
              subSectionTitle={isSubsectionTitle}
              onClick={() => handleMenuItemClick(route)}
              selected={selected}
              showSelectedIcon={false}
            >
              {isSubsectionTitle ? <Typography variant={'smallLabel'}>{title}</Typography> : title}
            </ActionSheetItem>
          )
        })}
      </ActionSheetWrapper>

      {/* Developers icon button */}
      <Tooltip title={<Typography variant="label">Developers</Typography>}>
        <span>
          <Button
            id="developers-button"
            variant={'iconCustomRounded'}
            color={'tertiary'}
            onClick={handleButtonClick}
            sx={{
              width: '28px',
              height: '28px',
              minWidth: '28px',
              backgroundColor: isOnDevelopersPage ? `${theme.palette.Highlight.PrimaryDark} !important` : undefined,
            }}
          >
            <Icon name={'OCode'} iconColor={isOnDevelopersPage ? theme.palette.White.o100 : theme.palette.Black.o100} />
          </Button>
        </span>
      </Tooltip>
    </HeaderSectionWrapper>
  )
}
