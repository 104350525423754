import React from 'react'
import { Box, Typography } from '@mui/material'
import { ExperienceDTO } from 'interfaces/experiences/experience'
import { Spinner, DataGrid, InfoRow, Chip, Icon, IconDictionary, Button, EmptyState, TableCell } from '@ketch-com/deck'
import { useSubscriptionTopicsPaginated } from 'api/subscriptions/queries/useSubscriptionTopicsPaginated'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { styled } from '@mui/styles'
import { RoutesManager } from 'utils/routing/routesManager'

const InfoRowContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  paddingBottom: theme.spacing(4),
}))

type Props = {
  experience: ExperienceDTO
}

export const ExperienceSubscriptions: React.FC<Props> = ({ experience }) => {
  const { data: subscriptionTopics, isLoading: isSubscriptionTopicsLoading } = useSubscriptionTopicsPaginated({
    params: {
      includeMetadata: true,
      limit: 200,
    },
  })

  if (isSubscriptionTopicsLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <Spinner size={35} thickness={3} />
      </Box>
    )
  }

  const rows =
    experience?.preference?.subscriptions?.topicItems?.map(topic => ({
      id: topic.code,
      name: subscriptionTopics?.topics?.filter(subTopic => subTopic?.code === topic?.code)?.[0]?.name || '',
    })) || []

  const cols = [
    {
      width: 1030,
      field: 'name',
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            {params?.row?.name ? (
              <Typography variant="label">{params?.row?.name}</Typography>
            ) : (
              <Typography variant="label" color="chileanFire.main">
                {`Subscription Deleted (code: ${params?.row?.id})`}
              </Typography>
            )}
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 180,
      field: 'id',
      headerName: '',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            {params?.row?.name ? (
              <Button
                color="tertiary"
                onClick={() => {
                  window.open(
                    RoutesManager.policyCenter.subscriptions.view.subscriptionTopic.root.getURL({
                      code: params?.row?.id,
                    }),
                    '_blank',
                    'noreferrer',
                  )
                }}
              >
                Open In New Tab
              </Button>
            ) : null}
          </TableCell>
        )
      },
      sortable: false,
    },
  ]

  return (
    <Box>
      <Box>
        <Typography variant="h3">Subscriptions</Typography>

        {!experience?.preference?.subscriptions?.topicItems?.length ? (
          <Box
            sx={{
              backgroundColor: ({ palette }) => palette.bone.main,
              borderRadius: '11px',
              padding: ({ spacing }) => spacing(8, 0),
              marginTop: ({ spacing }) => spacing(2),
            }}
          >
            <EmptyState
              size="medium"
              customIcon={<Icon name={IconDictionary.OCheckedList} width={50} height={50} />}
              title="There are no subscriptions associated with this experience."
              subTitle="Assign a subscription to this experience to populate the table."
            />
          </Box>
        ) : (
          <DataGrid
            sx={{
              paddingLeft: 0,
              paddingRight: 0,
              marginTop: 2,
            }}
            rows={rows}
            columns={cols}
            checkboxSelection={false}
            hideFooter
            hideFooterPagination
            disableBorder
            disableColumnMenu
            disableColumnPinning
            disableColumnReorder
            disableColumnResize
            disableChildrenSorting
            disableRowSelectionOnClick
            loading={false}
            disableRowHoverStates
            columnMenuProps={
              {
                slots: {
                  columnMenuFilterItem: null,
                  columnMenuAggregationItem: null,
                  columnMenuGroupingItem: null,
                },
              } as any
            }
          />
        )}
      </Box>
      <Box pt={5}>
        <Typography variant="h3">Unsubscribe From All</Typography>

        <InfoRow title="Unsubscribe From All" marginTop={3}>
          <InfoRowContent>
            {experience?.preference?.subscriptions?.controlItems?.length ? <Chip label="On" /> : <Chip label="Off" />}
          </InfoRowContent>
        </InfoRow>
        <InfoRow title="Impact">
          <InfoRowContent>
            {experience?.preference?.subscriptions?.controlItems?.[0]?.itemDetail?.impactType === 1
              ? 'Universal Unsubscribe'
              : 'Impacts This Experience Only'}
          </InfoRowContent>
        </InfoRow>
      </Box>
    </Box>
  )
}
