import React, { HTMLAttributes, useCallback, useMemo, useRef } from 'react'
import { useAuth } from 'utils/hooks'
import { useUsers } from 'api/users/queries/useUsers'
import { getFullNameUserShortDTO } from 'utils/helpers/getFullNameUserShortDTO'
import { ActionSheetItem, AvatarSize, Button, DropListButton, ListItemText, TextInput } from '@ketch-com/deck'
import {
  AutocompleteRenderInputParams,
  Box,
  InputAdornment,
  Popper,
  Typography,
  styled,
  autocompleteClasses,
  inputBaseClasses,
  textFieldClasses,
  InputProps,
  Grid,
} from '@mui/material'
import { UserAvatar } from 'components/avatar/UserAvatar'
import { Team } from 'interfaces/services/harbormaster/teams_gen.schemas'
import { fromAssignee } from 'utils/helpers/teamStringParser'
import { UserStatus } from 'interfaces/users/userStatus'
import { useFormikContext } from 'formik'
import { ListItem } from 'components/form/FormAssigneeOrTeamSelector'

type FormAssigneeSelectorProps = {
  formPropertyName: string
  label?: string
  hasAssignToMeButton?: boolean
  onSelect?: () => void
  disabled?: boolean
  size?: 'small' | 'medium'
  width?: string
  required?: boolean
}

const CustomPopper = styled(Popper)(({ theme }) => ({
  boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.20), 0px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  borderRadius: 5,
  backgroundColor: theme.palette.white.main,
  width: 'fit-content !important',
  maxWidth: 300,

  '& .MuiTypography-root': {
    overflowWrap: 'anywhere',
  },

  [`& .${autocompleteClasses.root}`]: {
    [`& .${textFieldClasses.root}`]: {
      padding: '8px 8px 0 8px',
      [`& .${inputBaseClasses.root}`]: {
        backgroundColor: theme.palette.white.main,
      },
    },
  },
}))

export const FormAssigneeSelector: React.FC<FormAssigneeSelectorProps> = ({
  formPropertyName,
  label,
  width,
  onSelect,
  hasAssignToMeButton = true,
  disabled = false,
  size = 'small',
  required = true,
}) => {
  const { userData } = useAuth()
  const { data: usersList } = useUsers({ params: { active: true } })
  const { setFieldValue, values } = useFormikContext()
  const assigneeId = (values as any)[formPropertyName]
  const [team, teamAssignee] = fromAssignee(assigneeId || '')
  const ref = useRef()

  const onChange = useCallback(
    (id?: string) => {
      if (id) setFieldValue(formPropertyName, id)
      if (onSelect) onSelect()
    },
    [formPropertyName, onSelect, setFieldValue],
  )

  const options = useMemo(() => {
    const me = usersList.find(({ ID }) => userData.userId === ID)
    const meOption = me ? [{ id: me?.ID, name: getFullNameUserShortDTO(me), isMe: true }] : []

    const usersOptions = (usersList || [])
      // Filter out inactive users
      .filter(f => f.status === UserStatus.ACTIVE)
      .map(user => ({
        id: user?.ID,
        name: getFullNameUserShortDTO(user),
      }))
      .filter(({ id }) => id !== me?.ID)

    return [...meOption, ...usersOptions] as ListItem[]
  }, [userData.userId, usersList]) as Team[]

  const qualifiedAssigneeId = team || teamAssignee
  const selectedValue = options.find(option => option.id === qualifiedAssigneeId)

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      const currentAssignee = selectedValue ? usersList.find(user => user?.ID === selectedValue.id) : undefined

      return (
        <TextInput
          {...params}
          value={selectedValue?.name}
          InputProps={
            {
              ...params.InputProps,
              value: selectedValue?.name,
              placeholder: `Select ${label || 'Assignee'}`,
              startAdornment: params.inputProps.value ? (
                <InputAdornment position="start">
                  <UserAvatar user={currentAssignee} size={AvatarSize.small} />
                </InputAdornment>
              ) : null,
              'data-test-id': `${label || 'Assignee'}OrTeamSelector`,
            } as Partial<InputProps>
          }
        />
      )
    },

    [label, selectedValue, usersList],
  )

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, opt: unknown, _: any, isSelected?: boolean) => {
      const option = opt as ListItem

      // Allow for non-clickable section headers in the list
      if (option.header) {
        return (
          <ActionSheetItem key={option.id} subSectionTitle>
            {option.name}
          </ActionSheetItem>
        )
      }

      const rowSubtitle = option.isMe ? '(Assign to me)' : undefined

      return (
        <ActionSheetItem
          selected={isSelected}
          {...props}
          key={option.id}
          data-test-id={`${label || 'Assignee'}Option-${option.id}`}
        >
          <Box display="flex" alignItems="center">
            <ListItemText selected={isSelected}>
              <Box display="flex" alignItems="center" gap={1}>
                <UserAvatar user={usersList.find(user => option.id === user?.ID)} size={AvatarSize.small} />
                <Box>
                  {option.name}
                  {rowSubtitle ? (
                    <Typography variant="smallBody" color="Text.Secondary" component="div" sx={{ mt: -0.25 }}>
                      {rowSubtitle}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            </ListItemText>
          </Box>
        </ActionSheetItem>
      )
    },
    [label, usersList],
  )

  const labelText: React.ReactNode = !required ? (
    <Box marginBottom={1}>
      <Typography variant="label">
        {label} <Typography color="Text.Secondary">(Optional)</Typography>
      </Typography>
    </Box>
  ) : (
    <Box marginBottom={1}>
      <Typography variant="label">{label}</Typography>
    </Box>
  )

  return (
    <Box marginBottom={3}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box ref={ref}>
            <DropListButton
              disabled={disabled}
              disableClearable
              size={size}
              onChange={(_, object) => onChange(object?.id)}
              fullWidth
              label={labelText}
              options={options}
              renderInput={renderInput}
              getOptionLabel={(opt: Team) => opt.name || opt.id || ''}
              renderOption={renderOption}
              width={width}
              PopperComponent={CustomPopper}
              //TODO:JA DroplistButton is not typed correctly - to be a controlled input, an unset value needs to be `null`
              value={selectedValue || (null as any)}
              componentsProps={{ popper: { placement: 'bottom-start' } }}
            />
            {hasAssignToMeButton ? (
              <Button
                color="secondary"
                sx={{ mt: 1 }}
                onClick={() => {
                  onChange(userData.userId)
                }}
              >
                Assign to me
              </Button>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
