import { StepDetailsDTO } from '@ketch-com/figurehead'

function parseVariableValue(ctxVarValue?: string | number | boolean): any {
  if (typeof ctxVarValue === 'string') {
    return ctxVarValue
  } else if (typeof ctxVarValue === 'boolean') {
    return ctxVarValue ? 'true' : 'false'
  } else if (typeof ctxVarValue === 'number') {
    return ctxVarValue.toString()
  } else {
    return ''
  }
}

export const getOutcomeVariablesInitialValues = (stepDetails?: StepDetailsDTO) => {
  const outcomeContextInitialValues = stepDetails?.outcomeContext?.map(outcomeVariable => {
    return {
      ...outcomeVariable,
      value: parseVariableValue(outcomeVariable.value),
    }
  })

  const initialValues = { outcomeContext: outcomeContextInitialValues }

  return initialValues
}
