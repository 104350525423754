import React, { createContext } from 'react'
import {
  AuthenticatorsUpsertViewUtilsProps,
  useAuthenticatorsUpsertViewUtils,
} from '../hooks/useAuthenticatorsUpsertViewUtils'

export const AuthenticatorUpsertContext = createContext({} as AuthenticatorsUpsertViewUtilsProps)

type Props = {
  children?: React.ReactNode
}

export const AuthenticatorUpsertProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useAuthenticatorsUpsertViewUtils()

  return <AuthenticatorUpsertContext.Provider value={hookValues}>{children}</AuthenticatorUpsertContext.Provider>
}

export const withFormAuthenticatorUpsertContext = (Component: React.FC) => {
  return (props: any) => {
    const hookValues = useAuthenticatorsUpsertViewUtils()

    return (
      <AuthenticatorUpsertContext.Provider value={hookValues}>
        <Component {...props} />
      </AuthenticatorUpsertContext.Provider>
    )
  }
}
