const NO_SPACES = /^\S*$/
const START_FROM_UPPER_OR_LOWER_CASE = /^[a-zA-Z]+.+/
const SPECIAL_SYMBOLS = /^[a-zA-Z][a-zA-Z_:0-9]*$/
const NUMERIC_INTEGER_STRING = /^\d+$/
const NON_ZERO_NUMERIC_INTEGER_STRING = /^[1-9]\d*$/
const MIN_EIGHT_CHARS = /.{8,}/
const MIN_TEN_CHARS = /.{10,}/
const MIN_THREE_CHARS = /.{3,}/
const MAX_FIFTY_CHARS = /^.{0,50}$/
const ONLY_LOWERCASE_CHARS_NUMBERS_AND_UNDERSCORE = /^[a-z_0-9]*$/
const ONE_UPPERCASE = /[A-Z]/
const ONE_LOWERCASE = /[a-z]/
const ONE_NUMBER = /\d/
const CONTAINS_SPECIAL_CHARACTERS = /[^\w\s]/
const TIME = /^(1[012]|[1-9]):[0-5][0-9]$/

export const validationRegExp = {
  CONTAINS_SPECIAL_CHARACTERS,
  MAX_FIFTY_CHARS,
  MIN_EIGHT_CHARS,
  MIN_TEN_CHARS,
  MIN_THREE_CHARS,
  NO_SPACES,
  NUMERIC_INTEGER_STRING,
  NON_ZERO_NUMERIC_INTEGER_STRING,
  ONE_LOWERCASE,
  ONE_NUMBER,
  ONE_UPPERCASE,
  ONLY_LOWERCASE_CHARS_NUMBERS_AND_UNDERSCORE,
  SPECIAL_SYMBOLS,
  START_FROM_UPPER_OR_LOWER_CASE,
  TIME,
}
