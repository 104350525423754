import { CreateDSRPoliciesRequestBodyDTO, PolicyDTO, PolicyStatementDTO } from '@ketch-com/figurehead'
import { DsrFormPolicyStatements } from 'store/dsrTransponderFormSlice'
import { getObligationEffectByCanonicalRightCode } from '.'
import { OBLIGATION_ENUM } from '../constants'

const getTransformedObligation = (obligationUrl: string) => {
  switch (obligationUrl) {
    case OBLIGATION_ENUM.NULLIFY: // nullify
      return OBLIGATION_ENUM.UPDATE
    case OBLIGATION_ENUM.UPDATE_AS_HASHED: // hashed
      return OBLIGATION_ENUM.UPDATE
    case OBLIGATION_ENUM.UPDATE_AS_MASKED: // masked
      return OBLIGATION_ENUM.UPDATE
    default:
      return obligationUrl
  }
}

const getFunctionNameByObligation = (obligationUrl: string): string | undefined => {
  switch (obligationUrl) {
    case OBLIGATION_ENUM.NULLIFY: // nullify
      return 'nullify'
    case OBLIGATION_ENUM.UPDATE_AS_HASHED: // hashed
      return 'hash'
    case OBLIGATION_ENUM.UPDATE_AS_MASKED: // masked
      return 'mask'
    case OBLIGATION_ENUM.UPDATE: // update
      return 'setValue'
    default:
      return undefined
  }
}

type Args = {
  policyStatements: DsrFormPolicyStatements
  canonicalRightCode: string
}

type StatementsPayload = { [key: string]: PolicyStatementDTO }

export const getCreateDsrPolicyFormData = ({
  policyStatements,
  canonicalRightCode,
}: Args): CreateDSRPoliciesRequestBodyDTO => {
  const policiesPayloadArray = []

  for (const [databaseCode, { statements, id }] of Object.entries(policyStatements)) {
    const policyPayload: PolicyDTO = {
      resource: databaseCode,
    }
    if (id) policyPayload.id = id

    if (Object.keys(statements || {}).length === 0) break

    const collectStatementsPerDataset: any = {}
    for (const [keyAssetCode, { effect, value, assetType, parentAssetCode }] of Object.entries(statements || {})) {
      if (assetType === 'dataset' && !collectStatementsPerDataset?.[keyAssetCode]) {
        collectStatementsPerDataset[keyAssetCode] = []
      }

      if (assetType === 'attribute' && parentAssetCode && !collectStatementsPerDataset?.[parentAssetCode]) {
        collectStatementsPerDataset[parentAssetCode] = [
          { effect, value, assetType, parentAssetCode, assetCode: keyAssetCode },
        ]
      }

      if (assetType === 'attribute' && parentAssetCode && !!collectStatementsPerDataset?.[parentAssetCode]) {
        collectStatementsPerDataset[parentAssetCode] = [
          ...collectStatementsPerDataset[parentAssetCode],
          { effect, value, assetType, parentAssetCode, assetCode: keyAssetCode },
        ]
      }
    }

    const statementsPayload: StatementsPayload = {}

    for (const [datasetCode, value] of Object.entries(collectStatementsPerDataset)) {
      /* for datasets where 0 attribute level effects have been specified */
      if ((value as any).length === 0) {
        statementsPayload[datasetCode] = {
          obligation: getObligationEffectByCanonicalRightCode(canonicalRightCode),
        }
      }

      if ((value as any).length > 0 && !statementsPayload[datasetCode]) {
        statementsPayload[datasetCode] = {
          effects: (value as any).reduce((acc: any, curr: any) => {
            const copyAcc: any = { ...acc }
            const newKey: string = curr.assetCode
            copyAcc[newKey] = {
              obligation: getTransformedObligation(curr.effect),
              value: curr.value,
              ...(!!getFunctionNameByObligation(curr.effect) && {
                functionName: getFunctionNameByObligation(curr.effect),
              }),
            }
            return copyAcc
          }, {}),
        }
      }
    }

    policyPayload.statements = statementsPayload
    policiesPayloadArray.push(policyPayload)
  }

  const payload = { policies: [...policiesPayloadArray] }

  return payload
}
