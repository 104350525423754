import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'

import { RouterProvider } from 'react-router-dom'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { LicenseInfo } from '@mui/x-license-pro'
import { MUI_LICENSE_KEY } from '@ketch-com/deck'

import { store } from 'store'

import { ReactQueryProvider } from 'components/providers/ReactQueryProvider'
import { theme } from '@ketch-com/deck'

import { useClipboardButtons } from 'utils/hooks/useClipboardButtons'
import { router } from 'utils/routing/router'
import { ToastComponent } from 'components/ui-kit/toastr/Toastr'
import { Posthog } from 'utils/helpers/Posthog'
import { AlertComponent } from 'components/modals/AlertComponent'

import './declarations'
import { Provider as RollbarProvider } from '@rollbar/react'
import rollbarConfig from 'utils/instrumentation/Rollbar'

const posthogPublicKey = (window as any)?.figureheadConfig?.REACT_APP_POSTHOG_PUBLIC_KEY

export const App = () => {
  useClipboardButtons()
  LicenseInfo.setLicenseKey(MUI_LICENSE_KEY)

  return (
    <RollbarProvider config={rollbarConfig}>
      <ReactQueryProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GoogleReCaptchaProvider
              reCaptchaKey={(window as any).figureheadConfig.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}
              useEnterprise={false}
              scriptProps={{
                appendTo: 'body', // optional, default to "head", can be "head" or "body",
                nonce: 'f11925ed8d4b48b710a0edaf20dfd007',
              }}
            >
              <ReduxProvider store={store}>
                <RouterProvider router={router} />
                <ToastComponent />
                <AlertComponent />
                {posthogPublicKey ? <Posthog /> : null}
              </ReduxProvider>
            </GoogleReCaptchaProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ReactQueryProvider>
    </RollbarProvider>
  )
}
