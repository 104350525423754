import { styled } from '@mui/system'

import { ReactComponent as TrashIcon } from 'assets/icons/trash-can-black.svg'

export const DeleteButton = styled(TrashIcon)(({ theme }) => ({
  backgroundColor: theme.palette.fadedGrey.main,
  borderRadius: 5,
  color: theme.palette.darkDusk.main,
  cursor: 'pointer',
  height: 28,
  marginLeft: 5,
  padding: 6,
  width: 28,
}))
