import { APIRequestParams } from 'api/common/utils'
import { WebTagUpdateFormDTO } from 'interfaces/webtags/WebTags'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  organizationCode: string
  formData?: WebTagUpdateFormDTO
}>

type Response = {}

export const updateWebTags = ({ organizationCode, formData }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/tags/syncToTagmanager`,
    }),
    formData,
  )
