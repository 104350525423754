import { API } from 'utils/api/api'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIListRequestParams, APIListResponse } from 'api/common/utils'

type Params = APIListRequestParams<{
  organizationCode: string
  includeIssues?: boolean
}>

type Response = APIListResponse<{ policyScopes?: PolicyScopeDTO[] }>

export const fetchPolicyScopes = ({ organizationCode, includeMetadata, includeIssues, start, limit }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/policy-scopes`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
        },
      },
    }),
  )
