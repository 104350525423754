import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { InputBase, Tooltip, Typography } from '@mui/material'
import { Conversation, Client } from '@twilio/conversations'
import { ComposeMessageAttachment, DisabledInputOverlay } from './components'
import { useComposeMessageInputUtils } from './hooks'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { Button, Icon, theme } from '@ketch-com/deck'

type Props = {
  twilioChatClient?: Client
  twilioConversation?: Conversation
  hasTwilioClientCatastrophicallyFailed: boolean
  rightInvocation?: RightInvocationDTO
}

export const ComposeMessageInput: React.FC<Props> = ({
  twilioChatClient,
  twilioConversation,
  hasTwilioClientCatastrophicallyFailed,
  rightInvocation,
}) => {
  const {
    acceptedFileTypesArray,
    files,
    filesInputKey,
    handleChange,
    handleSubmit,
    isPermittedToWrite,
    isSendEnabled,
    isSubmitting,
    onFilesChange,
    setFiles,
    values,
  } = useComposeMessageInputUtils({
    twilioChatClient,
    twilioConversation,
    hasTwilioClientCatastrophicallyFailed,
    rightInvocation,
  })

  const [isFocusedTextarea, setIsFocusedTextarea] = useState(false)

  return (
    <Tooltip
      title={
        hasTwilioClientCatastrophicallyFailed
          ? "Unable to connect to chat. Please try refreshing the page. If this doesn't work, please contact support."
          : !isPermittedToWrite
          ? 'You do not have permission to communicate with the Subject.'
          : null
      }
    >
      <Box
        sx={{
          pt: 1,
          opacity: isSubmitting ? 0.5 : 1,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            border: ({ palette }) => `1px solid ${isFocusedTextarea ? palette.sphere.main : palette.iron.main}`,
            borderRadius: '5px',
            pl: 1.75,
            pt: 1.5,
            pb: 2,
            maxHeight: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Absolute positioned grey overlay */}

          {(!isPermittedToWrite || hasTwilioClientCatastrophicallyFailed) && <DisabledInputOverlay />}

          {/* Input  */}

          <InputBase
            multiline
            minRows={1}
            fullWidth
            name="message"
            value={values.message}
            onChange={event => {
              handleChange(event)
              window?.scrollTo(0, document?.body?.scrollHeight)
            }}
            placeholder="Type a message..."
            sx={{
              pr: 1.75,
              overflowY: 'auto',
              maxHeight: '40vh',
              minHeight: '0',
              alignItems: 'flex-start',
            }}
            onFocus={() => setIsFocusedTextarea(true)}
            onBlur={() => setIsFocusedTextarea(false)}
          />

          {/* Attachments Row */}

          {files?.length > 0 ? (
            <Box display="flex" flexDirection="column" gap={1} flexWrap="wrap" flexGrow="0" flexShrink="0">
              {files?.map(attachment => (
                <ComposeMessageAttachment attachment={attachment} key={attachment.name} setFiles={setFiles} />
              ))}
            </Box>
          ) : null}

          {/* Attachment / Send Row */}

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              pt: 1.5,
              pr: 1.75,
              flexGrow: 0,
              flexShrink: 0,
            }}
          >
            {/* Add Attachment Button Icon */}

            {isPermittedToWrite && !hasTwilioClientCatastrophicallyFailed && !isSubmitting ? (
              <button style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', padding: 0 }}>
                <label htmlFor="file-input">
                  <Tooltip
                    title={
                      <Box maxWidth="160px">
                        <Typography variant="label" color="white.main">
                          Upload files.
                        </Typography>
                        <br />
                        <Typography>Supported file types:</Typography>
                        <br />
                        <Typography textTransform="capitalize">{acceptedFileTypesArray?.join(', ')}</Typography>
                      </Box>
                    }
                  >
                    <Box>
                      <Icon name="OClip" iconColor={theme.palette.sphere.main} sx={{ cursor: 'pointer' }} />
                    </Box>
                  </Tooltip>
                </label>
                <input
                  id="file-input"
                  key={filesInputKey}
                  type="file"
                  accept={acceptedFileTypesArray?.join(', .')}
                  style={{ display: 'none' }}
                  onChange={async event => {
                    await onFilesChange(event)
                    window?.scrollTo(0, document?.body?.scrollHeight)
                  }}
                />
              </button>
            ) : (
              <Icon name="OClip" iconColor={theme.palette.sphere.main} sx={{ opacity: '0.24' }} />
            )}

            {/* Send Message Button Icon */}

            <Button
              disabled={!isSendEnabled && !isSubmitting}
              fullWidth={false}
              pending={isSubmitting}
              size="medium"
              variant="iconSubtle"
              onClick={() => {
                if (isSendEnabled) {
                  handleSubmit()
                }
              }}
            >
              <Icon name="OSend" iconColor={theme.palette.sphere.main} />
            </Button>
          </Box>
        </Box>
      </Box>
    </Tooltip>
  )
}
