import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'
import { isEmpty } from 'lodash'

import { TabsRoutes, TabsRoutesConfig } from 'components/ui-kit/tabs/TabsRoutes'
import { Theme } from '@mui/material'
type UseStylesProps = {
  paddingOverride?: string
}

const useStyles = makeStyles<Theme, UseStylesProps>(
  theme =>
    createStyles({
      root: {
        padding: '24px 0',

        '&:not($withTabs)': {
          borderTop: `1px solid ${theme.palette.iron.main}`,
        },

        '&:not($hasBorderTop)': {
          borderTop: 0,
        },
      },
      content: props => ({
        paddingLeft: props.paddingOverride || 18,
        paddingRight: props.paddingOverride || 18,
      }),
      hasBorderTop: {},
      withTabs: {},
      tabsWrapper: {
        position: 'relative',
        borderBottom: `1px solid ${theme.palette.iron.main}`,
        marginBottom: 24,
      },
      tabs: {
        position: 'absolute',
        bottom: -1,
      },
      tabsAside: {
        position: 'absolute',
        right: 0,
        bottom: 8,
      },
    }),
  { name: 'ViewLayoutContent' },
)

type Props = {
  children?: React.ReactNode
  tabs?: TabsRoutesConfig[]
  tabsAside?: React.ReactNode
  className?: string
  contentClassName?: string
  hasBorderTop?: boolean
  paddingOverride?: string
}

export const ViewLayoutContent: React.FC<Props> = ({
  tabs = [],
  children,
  className,
  contentClassName,
  hasBorderTop = true,
  paddingOverride,
  tabsAside,
}) => {
  const classes = useStyles({ paddingOverride })
  const hasTabs = !isEmpty(tabs)

  const classList = clsx(classes.root, className, {
    [classes.withTabs]: hasTabs,
    [classes.hasBorderTop]: hasBorderTop,
  })

  return (
    <div className={classList}>
      {hasTabs && (
        <div className={classes.tabsWrapper}>
          <TabsRoutes tabs={tabs} size="regular" className={classes.tabs} />
          {tabsAside ? <div className={classes.tabsAside}>{tabsAside}</div> : null}
        </div>
      )}
      <div className={clsx(classes.content, contentClassName)}>{children}</div>
    </div>
  )
}
