import { Box } from '@mui/material'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { Text } from 'components/ui-kit/typography/Text'
import { createStyles, makeStyles } from '@mui/styles'
import { DataMapContactDTO } from '@ketch-com/figurehead'

type Props = {
  onClose: () => void
  contact: DataMapContactDTO
  title: string
}

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    root: {
      borderBottom: `1px solid ${palette.iron.main}`,
      padding: spacing(2, 0.5),
    },
    photo: {
      width: 96,
      marginRight: 64,
    },
    dataRow: {
      display: 'flex',
      minHeight: 56,

      '&:last-child': {
        marginBottom: 0,
      },
    },
    assignedToBox: {
      border: `1px solid ${palette.iron.main}`,
      borderRadius: 11,
      padding: spacing(1.5),
      marginRight: spacing(1.5),
    },
  }),
)

export const ContactModal: React.FC<Props> = ({ onClose, contact, title }) => {
  const classes = useStyles()
  return (
    <Dialog title={title} contentWidth={560} cancelBtnLabel="Close" onCancelBtnClick={onClose}>
      <Box className={classes.root}>
        <Box display="flex" className={classes.dataRow}>
          <Box width={140}>
            <Text size={14} color="grey">
              Name
            </Text>
          </Box>
          <Box width={350}>
            <Text size={14}>{contact.name}</Text>
          </Box>
        </Box>
        <Box className={classes.dataRow}>
          <Box width={140}>
            <Text size={14} color="grey">
              Role
            </Text>
          </Box>
          <Box width={350}>
            <Text size={14}>{contact.role}</Text>
          </Box>
        </Box>
        <Box className={classes.dataRow}>
          <Box width={140}>
            <Text size={14} color="grey">
              Email
            </Text>
          </Box>
          <Box width={350}>
            <Text size={14}>{contact.email}</Text>
          </Box>
        </Box>
        <Box className={classes.dataRow}>
          <Box width={140}>
            <Text size={14} color="grey">
              Assigned to
            </Text>
          </Box>
          <Box width={350} display="flex">
            <Box className={classes.assignedToBox}>
              <Text weight={700} size={16} component="div">
                {contact.assignedTo?.databases}
              </Text>
              <Text size={12}>Databases</Text>
            </Box>
            <Box className={classes.assignedToBox}>
              <Text weight={700} size={16} component="div">
                {contact.assignedTo?.datasets}
              </Text>
              <Text size={12}>Datasets</Text>
            </Box>
            <Box className={classes.assignedToBox}>
              <Text weight={700} size={16} component="div">
                {contact.assignedTo?.attributes}
              </Text>
              <Text size={12}>Attributes</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
