import React from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useIsEntitled } from 'utils/hooks'
import { MaybeNull } from 'interfaces/common'
import { ExperienceDTO } from 'interfaces/experiences/experience'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { Banner } from 'components/ui-kit/banner/Banner'
import { IssuesMessage } from 'components/ui-layouts/issuesMessage/IssuesMessage'
import { ViewLayout } from 'components/ui-layouts/viewLayout/ViewLayout'
import { ViewLayoutHeader } from 'components/ui-layouts/viewLayout/ViewLayoutHeader'
import { ViewLayoutContent } from 'components/ui-layouts/viewLayout/ViewLayoutContent'
import { ExperienceViewActions } from 'pages/consentAndRights/experiences/view/components/ExperienceViewActions'
import { ExperienceViewDetails } from 'pages/consentAndRights/experiences/view/components/ExperienceViewDetails'
import { ExperienceOverview } from 'pages/consentAndRights/experiences/view/tabs/ExperienceOverview'
import { ExperienceTranslations } from 'pages/consentAndRights/experiences/view/tabs/ExperienceTranslations'
import { ExperienceDeployments } from 'pages/consentAndRights/experiences/view/tabs/ExperienceDeployments'
import { ExperienceVersions } from 'pages/consentAndRights/experiences/view/tabs/ExperienceVersions'
import { FormMode } from 'interfaces/formModes/formMode'
import { ExperienceUrlTypeParam } from 'interfaces/experiences/experienceUrlTypeParam'
import { ExperienceForms } from './tabs/ExperienceForms'
import { ExperienceSubscriptions } from './tabs/ExperienceSubscriptions'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

type Props = {
  isReady: boolean
  experience: MaybeNull<ExperienceDTO>
  versions: ExperienceDTO[]
  organizationLanguages: LanguageWithStatusDTO[]
}

export const ExperienceView: React.FC<Props> = ({ isReady, experience, versions, organizationLanguages }) => {
  const { isEntitled } = useIsEntitled()
  const isEntitledToSubscriptions = isEntitled(ENTITLEMENTS.SUBSCRIPTIONS)
  const location = useLocation()
  const { removeQueryParam } = useQueryParams<{ version?: string }>()
  const navigate = useNavigate()

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Experiences', link: RoutesManager.deployment.experiences.root.getURL() },
    { title: experience?.name || experience?.code },
  ]

  if (!isReady || !experience) {
    return <ViewLayout isReady={false} breadcrumbs={breadcrumbs} />
  }

  const { name, code, hasIssues, latest, consent } = experience
  const hasExtraLanguages = !!organizationLanguages.length
  const isLatest = !versions.length || !!latest

  const tabs = [
    {
      title: 'Overview',
      link: {
        pathname: RoutesManager.deployment.experiences.view.overview.root.getURL({ code }),
        search: location.search,
      },
    },
    {
      title: 'Translations',
      link: {
        pathname: RoutesManager.deployment.experiences.view.translations.root.getURL({ code }),
        search: location.search,
      },
      hidden: !hasExtraLanguages,
    },
    // Conditional spreading of the "Forms" tab
    ...(!!experience?.preference
      ? [
          {
            title: 'Forms',
            link: {
              pathname: RoutesManager.deployment.experiences.view.forms.root.getURL({ code }),
            },
          },
        ]
      : []),
    ...(!!experience?.preference && isEntitledToSubscriptions
      ? [
          {
            title: 'Subscriptions',
            link: {
              pathname: RoutesManager.deployment.experiences.view.subscriptions.root.getURL({ code }),
              search: location.search,
            },
          },
        ]
      : []),
    {
      title: 'Deployments',
      link: {
        pathname: RoutesManager.deployment.experiences.view.deployments.root.getURL({ code }),
        search: location.search,
      },
    },
    {
      title: 'Versions',
      link: {
        pathname: RoutesManager.deployment.experiences.view.versions.root.getURL({ code }),
        search: location.search,
      },
    },
  ]

  const issuesMessageActions = [
    {
      title: 'Update',
      onClick: () => {
        navigate(
          RoutesManager.deployment.experiences.upsert.root.getURL({
            code,
            formMode: FormMode.EDIT,
            experienceType: consent ? ExperienceUrlTypeParam.CONSENT : ExperienceUrlTypeParam.PREFERENCE,
          }),
        )
      },
    },
  ]

  return (
    <ViewLayout
      breadcrumbs={breadcrumbs}
      issuesMessage={
        !!hasIssues && <IssuesMessage actions={issuesMessageActions} entityType={ApplicationEntity.EXPERIENCE} />
      }
      versionsMessage={
        !isLatest && (
          <Banner
            variant="warning"
            actions={[
              {
                title: 'View Current',
                onClick: () => {
                  removeQueryParam('version')
                },
              },
            ]}
          >
            You are viewing an old version of this experience. Return to the current version to manage or activate.
          </Banner>
        )
      }
    >
      <ViewLayoutHeader
        title={name || code}
        backTo={RoutesManager.deployment.experiences.root.getURL()}
        details={<ExperienceViewDetails isLatest={isLatest} experience={experience} />}
        actions={isLatest && <ExperienceViewActions experience={experience} />}
      />

      <ViewLayoutContent tabs={tabs}>
        <Routes location={location}>
          <Route path="/" element={<ExperienceOverview experience={experience} />} />
          <Route
            path={RoutesManager.deployment.experiences.view.overview.root.pattern}
            element={<ExperienceOverview experience={experience} />}
          />

          {hasExtraLanguages && (
            <Route
              path={RoutesManager.deployment.experiences.view.translations.root.pattern}
              element={<ExperienceTranslations experience={experience} organizationLanguages={organizationLanguages} />}
            />
          )}

          <Route
            path={RoutesManager.deployment.experiences.view.deployments.root.pattern}
            element={<ExperienceDeployments experience={experience} />}
          />

          {!!experience?.preference && (
            <Route
              path={RoutesManager.deployment.experiences.view.forms.root.pattern}
              element={<ExperienceForms experience={experience} />}
            />
          )}

          <Route
            path={RoutesManager.deployment.experiences.view.versions.root.pattern}
            element={<ExperienceVersions experience={experience} versions={versions} />}
          />

          {isEntitledToSubscriptions && (
            <Route
              path={RoutesManager.deployment.experiences.view.subscriptions.root.pattern}
              element={<ExperienceSubscriptions experience={experience} />}
            />
          )}
        </Routes>
      </ViewLayoutContent>
    </ViewLayout>
  )
}
