import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { OrganizationDTO, OrganizationFormDTO } from 'interfaces/organizations/organization'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { RoutesManager } from 'utils/routing/routesManager'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useCreateOrganization } from 'api/organizations/mutations/useCreateOrganization'
import { useUpdateOrganization } from 'api/organizations/mutations/useUpdateOrganization'
import { OrganizationUpsert } from 'pages/settings/organizations/upsert/OrganizationUpsert'
import { useCurrentOrganization } from 'utils/hooks/useCurrentOrganization'

export const OrganizationUpsertContainer: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { id } = useParams<{ id: string }>()
  const isEditMode = id !== NEW_ITEM_ID

  const onUpsertSuccess = async ({ auth0OrgID }: OrganizationDTO) => {
    await queryClient.refetchQueries(ApiQueryKeys.entitlements)
    await queryClient.refetchQueries([ApiQueryKeys.organization, { organizationId: auth0OrgID }])

    if (isEditMode) {
      showToast({ content: 'Organization updated', type: 'success' })
    } else {
      showToast({ content: 'Organization created', type: 'success' })
    }

    navigate(RoutesManager.settings.organization.root.getURL())
  }

  const handleRedirectToOrganizationsList = () => {
    navigate(RoutesManager.settings.organization.root.getURL())
  }

  const { mutateAsync: handleCreateOrganization } = useCreateOrganization({
    onSuccess: ({ data }) => onUpsertSuccess(data.organization),
    onError: () => {
      showToast({ content: 'Failed to create organization', type: 'error' })
    },
  })

  const { mutateAsync: handleUpdateOrganization } = useUpdateOrganization({
    onSuccess: ({ data }) => onUpsertSuccess(data.organization),
    onError: () => {
      showToast({ content: 'Failed to update organization', type: 'error' })
    },
  })

  const { organization, isLoading: isOrganizationLoading } = useCurrentOrganization({
    onError: handleRedirectToOrganizationsList,
  })

  const handleSubmit = async (values: OrganizationFormDTO) => {
    const formValues = { ...values }

    if (values?.logoURL && Array.isArray(values.logoURL)) {
      formValues.logoURL = values.logoURL[0]?.public_url || ''
    }

    await (isEditMode
      ? handleUpdateOrganization({
          params: {
            formData: formValues,
          },
        })
      : handleCreateOrganization({
          params: {
            formData: formValues,
          },
        }))
  }

  return (
    <OrganizationUpsert
      isReady={!isOrganizationLoading}
      isEditMode={isEditMode}
      organization={organization}
      onSubmit={handleSubmit}
    />
  )
}
