export const ASSET_TYPE_OPTIONS = [
  { id: 'database', name: 'Database' },
  { id: 'dataset', name: 'Dataset' },
]

export const CRITERIA_OPTIONS = [
  { id: 'labeled', name: 'Has Data Category' },
  { id: 'named', name: 'Named' },
]

export const EFFECT_OPTIONS = [
  { id: 'mask', name: 'Mask' },
  { id: 'hash', name: 'Hash' },
  { id: 'deny', name: 'Deny' },
]

export const NUMBER_FILTER_OPTIONS = [
  { id: 'equal_to', name: 'Equal to' },
  { id: 'not_equal_to', name: 'Not equal to' },
  { id: 'less_than', name: 'Less than' },
  { id: 'greater_than', name: 'Greater than' },
]

export const DATE_FILTER_OPTIONS = [
  { id: 'equal_to', name: 'Equal to' },
  { id: 'not_equal_to', name: 'Not equal to' },
  { id: 'less_than', name: 'Less than' },
  { id: 'greater_than', name: 'Greater than' },
]

export const STRING_FILTER_OPTIONS = [
  { id: 'equal_to', name: 'Equal to' },
  { id: 'not_equal_to', name: 'Not equal to' },
  { id: 'in', name: 'In' },
  { id: 'not_in', name: 'Not in' },
]
