import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import Box from '@mui/material/Box'

import { Text } from 'components/ui-kit/typography/Text'
import { FormRadioGroup } from 'components/ui-kit/form/radioGroup/FormRadioGroup'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'
import { Info as InfoIcon } from '@mui/icons-material'
import { FormCheckbox } from 'components/ui-kit/form/checkbox/FormCheckbox'
import { DAY_OF_WEEK_OPTIONS, FREQUENCY_OPTIONS, PERIOD_OPTIONS } from './constants'
import { useEditAutoDiscoveryFormSectionUtils } from './hooks'
import { getUtcOffsetMessage } from './utils'

const useStyles = makeStyles(
  ({ spacing, palette, typography }) =>
    createStyles({
      timeInput: {
        maxWidth: '72px',
        marginRight: spacing(3),
      },

      frequencyDropdown: {
        marginRight: spacing(3),
        width: 236,
      },

      infoIcon: {
        color: palette.lightGrey.main,
        fontSize: typography.pxToRem(12),
        marginRight: spacing(1),
      },
    }),
  { name: 'EditAutoDiscoveryFormSection' },
)

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export const EditAutoDiscoveryFormSection: React.FC<Props> = ({ assetSummary }) => {
  const classes = useStyles()
  const {
    shouldShowStartOnInput,
    shouldShowEveryMonthInfo,
    handleStartTimeInputOnChange,
    handleFrequencyOnChange,
    values,
  } = useEditAutoDiscoveryFormSectionUtils({ assetSummary })

  return (
    <FormSection title="Classification" subTitle="Define an optimal time for classification.">
      {/* Frequency Input Label Row */}

      <Box display="flex" alignItems="center" mb={0.75}>
        <Text size={14} weight={600} sx={{ minWidth: 260 }}>
          Frequency
        </Text>

        {shouldShowStartOnInput ? (
          <Text size={14} weight={600}>
            Start On
          </Text>
        ) : null}
      </Box>

      {/* Frequency Input Row */}

      <Box display="flex" alignItems="center" mb={3}>
        <FormDropdown
          className={classes.frequencyDropdown}
          placeholder="Select Frequency"
          name="schedule.frequency"
          items={FREQUENCY_OPTIONS}
          onChange={handleFrequencyOnChange}
        />

        {shouldShowEveryMonthInfo ? (
          <>
            <InfoIcon className={classes.infoIcon} />
            <Text size={11} weight={500} color="darkDuskFaded">
              Deep Classification will be triggered on the first day of every month.
            </Text>
          </>
        ) : null}

        {shouldShowStartOnInput ? (
          <FormRadioGroup
            name="schedule.day"
            layout="row"
            valueKey="id"
            renderLabel={({ name }) => name}
            options={DAY_OF_WEEK_OPTIONS}
          />
        ) : null}
      </Box>

      {/* Customize Start Time Checkbox */}
      {values.schedule.frequency !== '0' ? (
        <Box display="flex" alignItems="center" mb={0.75}>
          <FormCheckbox name="schedule.isCustomizeStartingTimeChecked" title="Customize Starting Time" />
        </Box>
      ) : null}

      {/* Start Time Input Row */}

      {values?.schedule?.isCustomizeStartingTimeChecked ? (
        <Box display="flex" alignItems="center">
          <FormInput
            required={values?.schedule?.isCustomizeStartingTimeChecked}
            className={classes.timeInput}
            shouldOverrideOnChange
            onChange={handleStartTimeInputOnChange}
            placeholder="12:00"
            name="schedule.time"
            type="text"
            shouldShowOptional={false}
          />
          <FormRadioGroup
            required={values?.schedule?.isCustomizeStartingTimeChecked}
            name="schedule.period"
            layout="row"
            valueKey="id"
            renderLabel={({ name }) => name}
            options={PERIOD_OPTIONS}
          />
        </Box>
      ) : null}

      {values?.schedule?.isCustomizeStartingTimeChecked ? (
        <Box display="flex" alignItems="center" mt={1}>
          <Text size={14} color="darkDuskFaded">
            {getUtcOffsetMessage()}
          </Text>
        </Box>
      ) : null}
    </FormSection>
  )
}
