import React, { createContext } from 'react'
import { UseEditWorkflowUtilsReturnType, useEditWorkflowUtils } from '../hooks'

export const EditWorkflowContext = createContext({} as UseEditWorkflowUtilsReturnType)

type Props = {
  children?: React.ReactNode
}

export const EditWorkflowContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useEditWorkflowUtils()

  return (
    <EditWorkflowContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </EditWorkflowContext.Provider>
  )
}
