import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'

import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { Text } from 'components/ui-kit/typography/Text'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { List, ListItemText } from '@mui/material'
import { IconButton } from '@mui/material'
import { useDownloadFile } from 'api/files/mutations/useDownloadFile'
import { showToast } from 'components/ui-kit/toastr/Toastr'

const useStyles = makeStyles(
  theme =>
    createStyles({
      listItem: {
        padding: '0 12px 12px 12px',
        marginBottom: 12,
        borderBottom: `1px solid ${theme.palette.iron.main}`,

        '&:last-child': {
          borderBottom: 0,
          marginBottom: 0,
        },
      },
      link: {
        width: 'auto',

        '&:hover': {
          textDecoration: 'none',
        },
      },
    }),
  { name: 'WorkflowExecutionStepAttachments' },
)

type Props = {
  attachments: FileInfoDTO[]
  isDownloadAllowed?: boolean
}

export const WorkflowExecutionStepAttachments: React.FC<Props> = ({ attachments, isDownloadAllowed = true }) => {
  const classes = useStyles()
  const { mutateAsync: handleDownloadFile, isLoading: isDownloadingFile } = useDownloadFile()

  const handleDownloadOnClick = async (attachment: FileInfoDTO) => {
    try {
      const { data } = await handleDownloadFile({
        params: {
          fileUrl: attachment.download_url,
        },
      })
      let a: HTMLAnchorElement | null = document.createElement('a')
      a.href = URL.createObjectURL(data)
      a.download = attachment.name
      a.click()
      a = null
      showToast({ content: `Downloaded file ${attachment.name}`, type: 'success' })
    } catch (error) {
      showToast({
        content: `Unable to download file ${attachment.name}`,
        type: 'error',
      })
    }
  }

  return (
    <List>
      {attachments.map(attachment => (
        <ListItemText key={attachment.ID} className={classes.listItem}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text size={14} marginRight={0.5}>
              {attachment.name}
            </Text>
            {isDownloadAllowed ? (
              <Flex alignItems="center">
                <Text size={14} color="grey" marginTop={0.5}>
                  {attachment.size}
                </Text>

                <IconButton
                  aria-label="download file"
                  disableFocusRipple
                  disableRipple
                  onClick={() => {
                    handleDownloadOnClick(attachment)
                  }}
                  disabled={isDownloadingFile}
                  sx={{ pt: 0, pb: 0 }}
                >
                  <DownloadIcon />
                </IconButton>
              </Flex>
            ) : null}
          </Flex>
        </ListItemText>
      ))}
    </List>
  )
}
