import { useAssetV2 } from 'api/assets/queries/useAssetV2'
import { useLabelsV2OrV3BasedOnFeatureFlag } from 'api/labels/queries/useLabelsV2OrV3BasedOnFeatureFlag'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import React, { useEffect, useState } from 'react'
import {
  getIsKeyStrokeValid,
  getReducedLabelsOptions,
} from 'pages/assetManager/components/ManageLabelsInput/utils/index'
import { getUniqueIdentifier } from 'pages/assetManager/assets/view/components/AssetDetailViewRelatedAssets/components/DataCategoriesBulkAssignModal/utils'

export const useDataCategoriesBulkAssignModalUtils = ({ assetCode }: { assetCode: string }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [newLabel, setNewLabel] = useState('')
  const [labels, setLabels] = useState<ManageLabelModalLabel[]>([])
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [invalidInputMessage, setInvalidInputMessage] = useState<string | null>(null)

  const { data: assetSummary, isLoading: isLoadingAsset } = useAssetV2({
    params: {
      assetCode,
    },
  })

  useEffect(() => {
    const currentAssignedLabels = (assetSummary?.asset?.assignedLabels || []).map(l => ({
      code: l?.code,
      value: l?.value || '',
      name: l?.name,
      isSystemLabel: l?.isSystemLabel,
    }))

    setLabels([...(currentAssignedLabels as ManageLabelModalLabel[])])
  }, [assetSummary, setLabels])

  const { data: labelOptions, isLoading: isLoadingLabelOptions } = useLabelsV2OrV3BasedOnFeatureFlag()

  const handleSetInvalidInputMessage = (message: string): void => {
    setInvalidInputMessage(message)
    setTimeout(() => {
      setInvalidInputMessage(null)
    }, 2500)
  }

  const handleLabelInputValueOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    if (getIsKeyStrokeValid(e.target.value)) {
      setNewLabel(e.target.value)
    }

    if (!getIsKeyStrokeValid(e.target.value)) {
      handleSetInvalidInputMessage("You've entered an invalid key")
    }
  }

  const handleAutocompleteOnChange = (event: React.ChangeEvent<{}>, value: ManageLabelModalLabel[]): void => {
    setLabels(currentLabels => {
      const payload = [...currentLabels, value[value.length - 1]]
      return payload
    })
    setNewLabel('')
  }

  const handleRemoveTag = (labelToRemoveUniqueIdentifier: string) => {
    setLabels(labels =>
      labels.filter(l => {
        const uniqueIdentifier = getUniqueIdentifier(l)
        return uniqueIdentifier !== labelToRemoveUniqueIdentifier
      }),
    )
  }

  return {
    assetSummary,
    handleAutocompleteOnChange,
    handleLabelInputValueOnChange,
    handleRemoveTag,
    invalidInputMessage,
    isBusy,
    isLoading: isLoadingAsset || isLoadingLabelOptions,
    isOptionsOpen,
    labelOptions: getReducedLabelsOptions({ labelOptions }),
    labels,
    newLabel,
    setIsBusy,
    setIsOptionsOpen,
    setNewLabel,
  }
}
