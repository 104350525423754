import React from 'react'
import { useNavigate } from 'react-router-dom'

import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { PolicyDocumentType, POLICY_DOCUMENT_NAMES } from 'interfaces/policyDocuments/policyDocument'
import { RoutesManager } from 'utils/routing/routesManager'
import { excludeFalsy } from 'utils/helpers/excludeFalsy'
import { SectionTitle } from 'components/ui-kit/typography/compositions/SectionTitle'
import { Text } from 'components/ui-kit/typography/Text'
import { Table } from 'components/ui-layouts/table/Table'
import { Button } from '@ketch-com/deck'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { ReactComponent as EmptyStateIcon } from '../../../../../assets/icons/emptyState/EmptyStateDocuments.svg'
import { NEW_ITEM_CODE } from '../../../../../utils/constants/misc'
import { EmptyState } from '../../../../../components/ui-layouts/emptyState/EmptyState'
import { useIsEntitled } from '../../../../../utils/hooks/useIsEntitled'
import { useIsPermitted } from '../../../../../utils/hooks/useIsPermitted'
import { PERMISSIONS } from '../../../../../interfaces/permissions/permissions'
import { ENTITLEMENTS } from '../../../../../interfaces/entitlements/entitlements'

type Props = {
  deploymentPlan: DeploymentPlanDTO
}

export const DeploymentPlanDocuments: React.FC<Props> = ({ deploymentPlan }) => {
  const navigate = useNavigate()
  const { privacyPolicy, termsOfService } = deploymentPlan
  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()

  const isPermittedToCreatePolicyDocuments = isPermitted([PERMISSIONS.POLDOC_WRITE])
  const isEntitledToCreatePolicyDocuments = isEntitled(ENTITLEMENTS.QTY_POLICY_DOCUMENT)
  const canCreatePolicyDocuments = isEntitledToCreatePolicyDocuments && isPermittedToCreatePolicyDocuments

  const items = [
    !!privacyPolicy && {
      documentTypeName: POLICY_DOCUMENT_NAMES[PolicyDocumentType.PRIVACY_POLICY],
      name: privacyPolicy.name,
      code: privacyPolicy.code,
    },
    !!termsOfService && {
      documentTypeName: POLICY_DOCUMENT_NAMES[PolicyDocumentType.TERMS_OF_SERVICE],
      name: termsOfService.name,
      code: termsOfService.code,
    },
  ].filter(excludeFalsy)

  return (
    <>
      {!items.length ? (
        <EmptyState
          variant="list"
          icon={<EmptyStateIcon />}
          title="There are no policy documents for this deployment plan"
          description={
            canCreatePolicyDocuments
              ? 'Create your first one and add it to this deployment plan to populate this section!'
              : null
          }
          actions={[
            {
              title: 'Create Document',
              hidden: !canCreatePolicyDocuments,
              size: 'big',
              onClick: () => {
                navigate(RoutesManager.policyCenter.policyDocuments.upsert.root.getURL({ code: NEW_ITEM_CODE }))
              },
            },
          ]}
        />
      ) : (
        <>
          <SectionTitle>Associated Documents</SectionTitle>

          <Table
            items={items}
            cellSettings={{
              documentType: {
                label: 'Document Type',
                width: 250,
                cellRenderer: ({ documentTypeName }) => (
                  <Text size={14} weight={600}>
                    {documentTypeName}
                  </Text>
                ),
              },
              document: {
                label: 'Document',
                cellRenderer: ({ name, code }) => <NameAndCodeCellRenderer name={name} code={code} />,
              },
              viewButton: {
                width: 110,
                cellRenderer: ({ code }) => (
                  <Button
                    color="tertiary"
                    onClick={() => {
                      navigate(RoutesManager.policyCenter.policyDocuments.view.root.getURL({ code }))
                    }}
                  >
                    View
                  </Button>
                ),
              },
            }}
          />
        </>
      )}
    </>
  )
}
