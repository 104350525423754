import React from 'react'
import { ReactComponent as DatabaseIcon } from 'assets/icons/ass_list_item_database_icon_small.svg'
import { ReactComponent as DatasetIcon } from 'assets/icons/ass_list_item_dataset_icon_small.svg'
import { ReactComponent as AttributeIcon } from 'assets/icons/ass_list_item_attribute_icon_small.svg'
import { ReactComponent as SchemaIcon } from 'assets/icons/ass_list_item_schema_icon_small.svg'

import { ASSET_TYPE_ENUM } from 'interfaces/assets'

type Props = {
  assetType: number
  className: string
}

export const AssetTypeIcon: React.FC<Props> = ({ assetType, className }) => {
  if (ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATABASE === assetType) return <DatabaseIcon className={className} />
  if (ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATASET === assetType) return <DatasetIcon className={className} />
  if (ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_ATTRIBUTE === assetType) return <AttributeIcon className={className} />
  return <SchemaIcon className={className} />
}
