import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { useNavigate, To } from 'react-router-dom'

import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { Group } from 'components/ui-layouts/group/Group'
import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'
import { Text } from 'components/ui-kit/typography/Text'
import { Button } from '@ketch-com/deck'
import {
  NavigationBreadCrumbs,
  NavigationBreadCrumbsProps,
} from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flex: 1,
        padding: '0 12px 12px 12px',
      },
      loading: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        minHeight: 250,
      },
      header: {
        padding: '13px 20px',
        borderRadius: 11,
        backgroundColor: theme.palette.white.main,
      },
      nameText: {
        display: 'block',
        marginBottom: 9,
      },
      codeText: {
        color: theme.palette.darkChrome.main,
      },
      toolbar: {
        marginBottom: 24,
        padding: '12px 10px',
        borderBottom: `1px solid ${theme.palette.iron.main}`,
      },
      content: {
        position: 'relative',
        flex: 1,
        padding: '24px 12px 0 12px',
      },
    }),
  { name: 'PreviewLayout' },
)

type Props = {
  children?: React.ReactNode
  isReady?: boolean
  name?: string
  code?: string
  exitTo: To
  toolbar: React.ReactNode
  breadcrumbs: NavigationBreadCrumbsProps['items']
}

export const PreviewLayout: React.FC<Props> = ({
  isReady = true,
  name,
  code,
  exitTo,
  toolbar,
  breadcrumbs,
  children,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <ContentBoundaries className={classes.root} width={1366}>
      {isReady ? (
        <>
          <NavigationBreadCrumbs type="light" items={breadcrumbs} />

          <Flex className={classes.header} justifyContent="space-between" alignItems="center">
            <div>
              <Text className={classes.nameText} size={20} weight={700} lineHeight={1.4}>
                {name}
              </Text>

              <Group gap={4} inline={false}>
                <Text className={classes.codeText} size={10} lineHeight={2} weight={700}>
                  CODE
                </Text>
                <Text className={classes.codeText} size={11} lineHeight={1.36}>
                  {code}
                </Text>
              </Group>
            </div>

            <Button
              color="secondary"
              size="large"
              onClick={() => {
                navigate(exitTo)
              }}
            >
              Exit Preview
            </Button>
          </Flex>
          <div className={classes.toolbar}>{toolbar}</div>
          <div className={classes.content}>{children}</div>
        </>
      ) : (
        <Flex className={classes.loading} alignItems="center">
          <Spinner />
        </Flex>
      )}
    </ContentBoundaries>
  )
}
