import React, { useState } from 'react'

import { MaybeNull } from 'interfaces/common'
import Box from '@mui/material/Box'
import { RightInvocationDTO, WorkflowExecutionDTO as GeneratedWorkflowExecutionDTO } from '@ketch-com/figurehead'
import { PurposeDataRole, PURPOSE_DATA_ROLE_NAMES } from 'interfaces/purposes/purposeDataRole'
import { WorkflowExecutionDTO } from 'interfaces/workflowExecutions/workflowExecution'

import { getDetailViewStatusText, getRightInvocationCanonicalStatusVariantV2 } from '../../utilsV2'
import { Chip, Icon, Status, StatusState, StatusVariant } from '@ketch-com/deck'
import { Typography } from '@mui/material'
import { getRightInvocationStatusIconName } from '../../utilsV2/getRightInvocationStatusIcon'
import { WorkflowExecutionProgressV2 } from '../../listV2/components/RightsQueueListTable/components'
import { TooltipV2 } from 'components/ui-kit/tooltip/TooltipV2'

type Props = {
  rightInvocation: RightInvocationDTO
  workflowExecution: MaybeNull<WorkflowExecutionDTO>
}

export const RightsQueueViewDetails: React.FC<Props> = ({ rightInvocation, workflowExecution }) => {
  const { id, controllerCode } = rightInvocation
  const isAppealed = rightInvocation.appeal?.isAppealed

  const displayId = id?.split('-').at(-1)

  const [hoverIdText, setHoverIdText] = useState('Click to copy')

  // Show copied checkmark and text for 2 seconds on click
  const handleCopyIdClick = () => {
    // Copy the right invocation ID to clipboard
    navigator.clipboard.writeText(id || '')
    setHoverIdText('Copied!')
    setTimeout(() => {
      setHoverIdText('Click to copy Request ID')
    }, 2000)
  }

  return (
    <Box gap={1} display="flex" alignItems="center" flexWrap="wrap">
      <Chip
        size="small"
        label={PURPOSE_DATA_ROLE_NAMES[controllerCode ? PurposeDataRole.PROCESSOR : PurposeDataRole.CONTROLLER]}
      />

      <Chip
        size="small"
        label={
          <Typography variant="smallLabel">
            Request ID: ... <Typography variant="smallBody">{displayId}</Typography>
          </Typography>
        }
        deleteIcon={
          <TooltipV2 title={hoverIdText} placement="bottom">
            <span style={{ display: 'flex' }}>
              <Icon name={'OCopy'} />
            </span>
          </TooltipV2>
        }
        onDelete={handleCopyIdClick}
      />

      {isAppealed && <Status label="Appeal" variant={StatusVariant.filled} status={StatusState.new} />}

      <Status
        icon={getRightInvocationStatusIconName(rightInvocation)}
        status={getRightInvocationCanonicalStatusVariantV2(rightInvocation)}
        variant={StatusVariant.ghost}
        label={getDetailViewStatusText(rightInvocation)}
      />

      <Status
        variant={StatusVariant.filled}
        status={getRightInvocationCanonicalStatusVariantV2(rightInvocation)}
        label={rightInvocation?.requestStatusText || ''}
      />

      {!!workflowExecution && (
        <WorkflowExecutionProgressV2
          workflowExecution={
            rightInvocation?.appealWorkflowExecution || (workflowExecution as unknown as GeneratedWorkflowExecutionDTO)
          }
          rightInvocation={rightInvocation}
        />
      )}
    </Box>
  )
}
