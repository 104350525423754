import { useState, useCallback } from 'react'
import { FindMapDataResponseBodyDTO } from '@ketch-com/figurehead'
import { MapVisualization } from 'pages/insights/dataMap/components/DataMapVisualization/components/MapVisualization'
import { JurisdictionDetails } from 'pages/insights/dataMap/components/DataMapVisualization/components/JurisdictionDetails'
import { Box, Grid } from '@mui/material'
import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'

type Props = {
  visualizationData: FindMapDataResponseBodyDTO
  hideTitle?: boolean
  hideExportButton?: boolean
}

export const DataMapVisualization: React.FC<Props> = ({ visualizationData, hideTitle, hideExportButton }) => {
  const [selectedJurisdiction, setSelectedJurisdiction] = useState('')
  const updateSelectedJurisdiction = useCallback((jurisdiction: string) => {
    setSelectedJurisdiction(jurisdiction)
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'white.main',
        mt: -3,
        mb: 3,
      }}
    >
      <ContentBoundaries>
        <Grid
          container
          spacing={0}
          sx={{
            backgroundColor: 'white.main',
          }}
        >
          <Grid item xs>
            <MapVisualization
              visualizationData={visualizationData}
              updateSelectedJurisdiction={updateSelectedJurisdiction}
              selectedJurisdiction={selectedJurisdiction}
              hideTitle={hideTitle}
              hideExportButton={hideExportButton}
            />
          </Grid>
          {selectedJurisdiction && (
            <Grid item xs={4}>
              <JurisdictionDetails
                visualizationData={visualizationData}
                updateSelectedJurisdiction={updateSelectedJurisdiction}
                selectedJurisdiction={selectedJurisdiction}
              />
            </Grid>
          )}
        </Grid>
      </ContentBoundaries>
    </Box>
  )
}
