import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// utils
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'

// components
import { showToast } from 'components/modals/AlertComponent'
import Typography from '@mui/material/Typography'

// types
import { useUpdateWorkflowExecution } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecution'
import { StepDetailsDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { useConnections } from 'api/dataSystems/queries/useConnections'
import { Button, PopUp } from '@ketch-com/deck'

type Props = {
  onCancel: () => void
  stepDetails: StepDetailsDTO | null
}

export const RetryAppModal: React.FC<Props> = ({ onCancel, stepDetails }) => {
  const navigate = useNavigate()
  const [isBusy, setIsBusy] = useState(false)
  const { rightInvocation, workflowExecutionId } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const { data: connections } = useConnections({
    params: {
      appInstanceId: stepDetails?.executionData?.app?.appInstanceID,
    },
  })

  const { mutateAsync: handleUpdateWorkflowExecution } = useUpdateWorkflowExecution({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      setTimeout(async () => {
        showToast({ content: 'Retrying initiated', type: 'success' })
        // TODO:BAC - if, in the future, we decide not to navigate user to workflow tab and instead stay on the detail view, we ill need to refetch queries using "await queryClient.refetchQueries([<relevant_query_keys_here>])"
        navigate(
          RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({
            id: rightInvocation?.id || '',
          }),
        )
        // artificial delay to allow for workflow to be updated before navigating to workflow tab
      }, 5000)
    },
    onError: () => {
      showToast({ content: 'Failed to retry', type: 'error' })
      setIsBusy(false)
      onCancel()
    },
  })

  return (
    <PopUp
      variant="dialog"
      title="Retry"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button
            color="secondary"
            size="large"
            pending={isBusy}
            onClick={e => {
              onCancel()
            }}
          >
            Close
          </Button>
          <Button
            color="primary"
            size="large"
            pending={isBusy}
            onClick={async e => {
              await handleUpdateWorkflowExecution({
                params: {
                  formData: {
                    temporalWorkflowID: `${workflowExecutionId}${stepDetails?.stepID}`,
                    signalName: 'level_2_app_impel',
                    signalValue: {
                      command: 'retry',
                    },
                  },
                },
              })
            }}
          >
            Retry
          </Button>
        </>
      }
    >
      <Typography variant="body">
        Before retrying, please assert this Connection's configurations are correct and up to date. To view
        configurations for this Connection, click{' '}
        <Button
          variant="link"
          color="secondary"
          onClick={() => {
            navigate(
              RoutesManager.systems.connections.upsert.root.getURL({
                id: connections?.[0]?.installedDataSystem?.id,
                instanceId: connections?.[0]?.id,
              }),
            )
          }}
          sx={{
            fontSize: '14px',
            marginTop: '-2px',
          }}
        >
          here
        </Button>
        .
      </Typography>
    </PopUp>
  )
}
