import { formatRequestString } from 'utils/helpers/api/queryString'
import { API } from 'utils/api/api'
import { PutConnectionV2RequestBodyDTO, PutConnectionV2ResponseBodyDTO } from '@ketch-com/figurehead'

type Params = {
  id: string
  formData: PutConnectionV2RequestBodyDTO
}

export const updateConnection = ({ id, formData }: Params) =>
  API.put<PutConnectionV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/connections/${id}`,
    }),
    formData,
  )
