import React, { useState, useMemo } from 'react'
import { Formik } from 'formik'

import { MaybeNull } from 'interfaces/common'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { WorkflowExecutionCompleteFormValues } from './utils'
import { joinNonEmpty } from 'utils/helpers/joinNonEmpty'
import { FormInput } from 'components/form/FormInput'
// TODO: needs to get updated
import { FormDropZone } from 'components/ui-kit/form/dropZone/FormDropZone'
import { RightInvocationIdentitySpaces } from 'pages/orchestration/rightsQueue/viewV2/components/RightInvocationIdentitySpaces'
import { Box, Typography } from '@mui/material'
import { Button, PopUp, FormRow, InfoRow, Spinner } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type Props = {
  isReady: boolean
  filesInfo: FileInfoDTO[]
  rightInvocation?: RightInvocationDTO
  workflowExecutionStep?: WorkflowExecutionStepDTO
  workflowExecutionStepDetails: MaybeNull<WorkflowExecutionStepDetailsDTO>
  onSubmit: (values: WorkflowExecutionCompleteFormValues) => Promise<void>
  onClose: () => void
}

export const WorkflowExecutionCompleteModal: React.FC<Props> = ({
  isReady,
  filesInfo,
  rightInvocation,
  workflowExecutionStep,
  workflowExecutionStepDetails,
  onSubmit,
  onClose,
}) => {
  const [isUploading, setIsUploading] = useState(false)

  const resolutionDetails =
    workflowExecutionStepDetails?.results?.[workflowExecutionStep?.stepID || '']?.resolutionDetails || ''
  const stepDetailsText = workflowExecutionStepDetails?.activity?.params?.details

  const initialValues: WorkflowExecutionCompleteFormValues = useMemo(
    () => ({
      resolutionDetails,
      resolutionAttachments: filesInfo,
    }),
    [resolutionDetails, filesInfo],
  )

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {({ submitForm, isSubmitting }) => (
        <>
          <PopUp
            variant="modal"
            title="Complete Request"
            popUpWidth="840px"
            popUpActionChildren={
              <>
                <Button color="secondary" size="large" onClick={onClose} pending={isSubmitting} disabled={!isUploading}>
                  Close
                </Button>
                <Button
                  color="primary"
                  size="large"
                  onClick={submitForm}
                  pending={isSubmitting}
                  disabled={!isUploading}
                >
                  Save
                </Button>
              </>
            }
          >
            {!isReady ? (
              <Box>
                <Spinner size="32px" thickness={2.5} />
              </Box>
            ) : (
              <Box>
                <FormRow title="Task">
                  <InfoRow title="Details">
                    {stepDetailsText ? <Typography>{stepDetailsText}</Typography> : <EmptyValueRenderer />}
                  </InfoRow>
                </FormRow>
                <FormRow title="Data Subject">
                  <InfoRow title="Name">
                    {rightInvocation?.user?.first || rightInvocation?.user?.last ? (
                      <Typography>
                        {joinNonEmpty([rightInvocation?.user?.first, rightInvocation?.user?.last])}
                      </Typography>
                    ) : (
                      <EmptyValueRenderer />
                    )}
                  </InfoRow>
                  <InfoRow title="Identifiers">
                    {rightInvocation?.identities?.length ? (
                      <RightInvocationIdentitySpaces max={6} identities={rightInvocation?.identities} />
                    ) : (
                      <EmptyValueRenderer />
                    )}
                  </InfoRow>

                  <InfoRow title="Email">
                    {rightInvocation?.user?.email ? (
                      <Typography>{rightInvocation?.user?.email}</Typography>
                    ) : (
                      <EmptyValueRenderer />
                    )}
                  </InfoRow>
                </FormRow>
                <FormRow title="Resolution">
                  <FormInput
                    required
                    fullWidth
                    formPropertyName="resolutionDetails"
                    placeholder="Type here"
                    label="Notes"
                    shouldUpdateDebounced
                  />
                </FormRow>

                <FormRow title="Resolution">
                  <FormDropZone
                    label="Attachments"
                    name="resolutionAttachments"
                    disabled={isSubmitting}
                    onUploadingStateChange={setIsUploading}
                  />
                </FormRow>
              </Box>
            )}
          </PopUp>
        </>
      )}
    </Formik>
  )
}
