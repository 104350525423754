import React, { useEffect } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import moment from 'moment'

import { ChartTrendPeriod } from 'interfaces/rights/rightStats'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useRightsStatsPeriod } from 'api/rights/queries/useRightsStatsPeriod'
import { CircularProgress, CircularProgressSize, Tab, TabGroup, TabSegmented, TabSegmentedGroup } from '@ketch-com/deck'
import { RightsTrendQueueChart, RightsTrendQueueFlowChart } from './components'
import { COMMON, RIGHTS_STATISTICS } from 'i18n/types'
import { DataRoleType } from 'interfaces/events'
import Typography from '@mui/material/Typography'
import { getStartAndEndDateFromTrendPeriod } from 'pages/insights/utils'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

const PREFIX = 'RightsTrend'

const classes = {
  container: `${PREFIX}-container`,
  content: `${PREFIX}-content`,
  flexWrapper: `${PREFIX}-flexWrapper`,
  tabs: `${PREFIX}-tabs`,
}

const Root = styled('div')(({ theme: { palette } }) => ({
  [`&.${classes.container}`]: {
    marginTop: 32,
  },

  [`& .${classes.content}`]: {
    boxSizing: 'border-box',
    background: palette.white.main,
    borderRadius: 11,
    padding: 32,
    marginTop: 24,
  },

  [`& .${classes.flexWrapper}`]: {
    display: 'flex',
    width: '100%',
    marginTop: 16,

    '&:first-child': {
      marginRight: 80,
    },
  },

  [`& .${classes.tabs}`]: {
    width: '100%',
  },
}))

type Props = {}

export const RightsTrend: React.FC<Props> = () => {
  const theme = useTheme()
  const { t } = useTranslation([RIGHTS_STATISTICS, COMMON])
  const { queries, setQueryParam, setMultipleQueryParams } = useQueryParams<{
    trendPeriod?: ChartTrendPeriod
    policyScope?: string
    dataSubjectType?: string
    dataRole?: DataRoleType
    startDate?: string
    endDate?: string
  }>()
  const { trendPeriod = ChartTrendPeriod.DAILY } = queries

  const breadcrumbs = [
    { title: 'Reports', link: RoutesManager.insights.root.getURL() },
    { title: 'Rights', link: RoutesManager.insights.rights.root.getURL() },
  ]

  const [currentTab, setCurrentTab] = React.useState(ChartTrendPeriod.DAILY)
  const [currentSegmentTab, setCurrentSegmentTab] = React.useState<ChartTrendPeriod>(
    queries.trendPeriod || ChartTrendPeriod.MONTHLY_PAST_TWELVE,
  )

  const { data: rightsStatsPeriod, isLoading: isLoadingRightsStatsPeriod } = useRightsStatsPeriod({
    params: {
      policyScope: queries?.policyScope,
      dataSubjectType: queries?.dataSubjectType,
      dataRole: queries?.dataRole,
      trendPeriod,
      startDate: queries?.startDate,
      endDate: queries?.endDate,
    },
  })

  const pastCalendarYearVariant = queries.trendPeriod === ChartTrendPeriod.MONTHLY_PAST_CAL_YEAR ? 'label' : 'body'
  const pastTwelveMonthsVariant = queries.trendPeriod === ChartTrendPeriod.MONTHLY_PAST_TWELVE ? 'label' : 'body'

  useEffect(() => {
    const setDefaultSearchParamsOnMount = async () => {
      const { startDate, endDate } = getStartAndEndDateFromTrendPeriod(ChartTrendPeriod.DAILY)
      setMultipleQueryParams([
        { key: 'trendPeriod', value: ChartTrendPeriod.DAILY },
        { key: 'startDate', value: startDate },
        { key: 'endDate', value: endDate },
      ])
    }

    // Only set default search params if one of the required params is missing
    if (!queries?.trendPeriod || !queries?.startDate || !queries?.endDate) {
      setDefaultSearchParamsOnMount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (_: React.SyntheticEvent, newValue: ChartTrendPeriod) => {
    setCurrentTab(newValue)
    const { startDate, endDate } = getStartAndEndDateFromTrendPeriod(newValue)
    setMultipleQueryParams([
      { key: 'startDate', value: startDate },
      { key: 'endDate', value: endDate },
      {
        key: 'trendPeriod',
        value: newValue,
      },
    ])
  }

  const handleChangeSegmentTabs = (_: React.SyntheticEvent, nextStatus: ChartTrendPeriod) => {
    setCurrentSegmentTab(nextStatus)

    setQueryParam('trendPeriod', nextStatus)
    const { startDate, endDate } = getStartAndEndDateFromTrendPeriod(nextStatus)
    setMultipleQueryParams([
      { key: 'startDate', value: startDate },
      { key: 'endDate', value: endDate },
    ])
  }

  return (
    <Root className={classes.container}>
      <Typography variant="h2">{t('Trends')}</Typography>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />

      <div className={classes.content}>
        <Box sx={{ width: '100%' }} className={classes.tabs}>
          <TabGroup value={currentTab} onChange={handleChange}>
            <Tab label={t('Daily', { ns: 'common' })} value={ChartTrendPeriod.DAILY} />
            <Tab label={t('Weekly', { ns: 'common' })} value={ChartTrendPeriod.WEEKLY} />
            <Tab label={t('Monthly', { ns: 'common' })} value={ChartTrendPeriod.MONTHLY_PAST_TWELVE} />
          </TabGroup>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
          {/* Left */}

          {ChartTrendPeriod.MONTHLY_PAST_CAL_YEAR === trendPeriod ? (
            <Typography color={theme.palette.darkDuskFaded.main} variant="h3">
              {moment().subtract(1, 'year').format('YYYY')}
            </Typography>
          ) : ChartTrendPeriod.MONTHLY_PAST_TWELVE === trendPeriod ? (
            <Typography color={theme.palette.darkDuskFaded.main} variant="h3">
              {moment().subtract(1, 'year').format('YYYY')} &mdash; {moment().format('YYYY')}
            </Typography>
          ) : (
            <Box />
          )}

          {/* Right */}

          {[ChartTrendPeriod.MONTHLY_PAST_CAL_YEAR, ChartTrendPeriod.MONTHLY_PAST_TWELVE].includes(trendPeriod) ? (
            <TabSegmentedGroup value={currentSegmentTab} exclusive onChange={handleChangeSegmentTabs} size="small">
              <TabSegmented value={ChartTrendPeriod.MONTHLY_PAST_CAL_YEAR} key={ChartTrendPeriod.MONTHLY_PAST_CAL_YEAR}>
                <Typography variant={pastCalendarYearVariant} mr={0.5}>
                  Past Calendar Year
                </Typography>
              </TabSegmented>
              <TabSegmented value={ChartTrendPeriod.MONTHLY_PAST_TWELVE} key={ChartTrendPeriod.MONTHLY_PAST_TWELVE}>
                <Typography variant={pastTwelveMonthsVariant} mr={0.5}>
                  Past 12 Months
                </Typography>
              </TabSegmented>
            </TabSegmentedGroup>
          ) : (
            // this Box is just a dummy placeholder for the layout "space-between"
            // to work when SegmentTabs is not being rendered
            <Box />
          )}
        </Box>

        {!!isLoadingRightsStatsPeriod ? (
          <Box className={classes.flexWrapper} justifyContent="center" alignItems="center">
            <CircularProgress size={CircularProgressSize.xLarge} />
          </Box>
        ) : (
          <Box className={classes.flexWrapper} justifyContent="space-between">
            <RightsTrendQueueChart trendPeriod={trendPeriod} rightsStatsPeriod={rightsStatsPeriod} />
            <RightsTrendQueueFlowChart trendPeriod={trendPeriod} rightsStatsPeriod={rightsStatsPeriod} />
          </Box>
        )}
      </div>
    </Root>
  )
}
