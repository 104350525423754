import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createDmlHook } from '../fetchers/createDmlHook'

const useCustomMutation = createUseMutation({
  mutationFn: createDmlHook,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateDmlHook = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
