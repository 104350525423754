import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

export const useHandleEditOnClick = (assetSummary: AssetSummaryDTO) => {
  const navigate = useNavigate()

  const handleEditOnClick = useCallback(() => {
    navigate(
      RoutesManager.assetManager.assets.upsert.getURL({
        code: assetSummary?.asset?.resource?.id || '',
        resourceTypeCode: assetSummary?.asset?.resource?.resourceType?.code || '',
      }),
    )
  }, [assetSummary, navigate])

  return handleEditOnClick
}
