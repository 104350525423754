import { MaybeNull } from 'interfaces'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'

type GetDataSubjectTypeFormValuesArgs = {
  dataSubjectType: MaybeNull<DataSubjectTypeDTO>
}

export const getDataSubjectTypeFormValues = ({
  dataSubjectType,
}: GetDataSubjectTypeFormValuesArgs): DataSubjectTypeDTO => {
  if (!dataSubjectType) {
    return {
      code: '',
      isCustomType: false,
      name: '',
      requiresUserInput: false,
      templateId: '',
      translations: {},
    }
  }

  return {
    code: dataSubjectType.code,
    name: dataSubjectType.name,
    isCustomType: !!dataSubjectType.isCustomType ? dataSubjectType.isCustomType : false,
    requiresUserInput: !!dataSubjectType.requiresUserInput ? dataSubjectType.requiresUserInput : false,
    templateId: dataSubjectType.templateId,
    translations: dataSubjectType.translations,
  }
}
