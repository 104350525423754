import React from 'react'

import { LanguagesList } from 'pages/consentAndRights/languages/list/LanguagesList'
import { useOrganizationLanguagesPaginated } from 'api/languages/queries/useOrganizationLanguagesPaginated'

export const LanguagesListContainer: React.FC = () => {
  const { data: languages, isLoading } = useOrganizationLanguagesPaginated({
    params: {
      includeMetadata: true,
    },
  })

  return <LanguagesList isLoading={isLoading} languages={languages} />
}
