import { useLocation, useNavigate } from 'react-router-dom'

import { FormFieldCategoryDTO } from '@ketch-com/figurehead'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'
import { useFormFields } from 'api/formFields/queries/useFormFields'
import { useIsExperienceServerAdmin } from 'pages/consentAndRights/forms/hooks'
import { useDispatch } from 'react-redux'
import { useFormFieldsInfinite } from 'api/formFields/queries/useFormFieldsInfinite'
import { FormMode } from 'interfaces/formModes/formMode'
import { useIsEntitled, useQueryParams } from 'utils/hooks'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

export const useFormFieldsListUtils = () => {
  const { queries } = useQueryParams<{ search?: string }>()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  // fetch data
  const { data: defaultFormFields, isLoading: isLoadingDefaultFormFields } = useFormFields({
    itemsPerPage: 100,
    params: {
      ordering: { title: 'asc' },
      category: FormFieldCategoryDTO.DefaultFormFieldCategory,
      includeMetadata: true,
    },
  })

  const infiniteCustomFormFieldsResp = useFormFieldsInfinite({
    options: {
      itemsPerPage: 50,
      enabled: true,
    },
    params: {
      ordering: { created_at: 'desc' },
      category: FormFieldCategoryDTO.CustomFormFieldCategory,
      query: queries.search || undefined,
      includeMetadata: true,
      includeAuthenticators: true,
    },
  })

  const { isUserExperienceServerAdmin, isUserLoading } = useIsExperienceServerAdmin()

  const handleCreateNewFormFieldOnClick = () => {
    navigate(
      RoutesManager.deployment.forms.fields.upsert.root.getURL({
        id: NEW_ITEM_ID,
        formMode: FormMode.CREATE,
      }),
    )
  }

  const isLoadingAggregate = isLoadingDefaultFormFields || isUserLoading

  const { isEntitled } = useIsEntitled()

  const isEntitledToExpServerRights = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)

  const payload = {
    infiniteCustomFormFieldsResp,
    isEntitledToExpServerRights,
    defaultFormFields,
    handleCreateNewFormFieldOnClick,
    navigate,
    isLoadingAggregate,
    isLoadingDefaultFormFields,
    isUserExperienceServerAdmin,
    location,
    dispatch,
  }

  return payload
}

export type UseFormFieldsListUtilsReturn = ReturnType<typeof useFormFieldsListUtils>
