import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchConnection } from '../fetchers/fetchConnection'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.connection,
  queryFn: fetchConnection,
  select: res => res?.data.connection || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useConnection = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
