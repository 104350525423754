import React, { useState } from 'react'
import { useDebounce } from 'react-use'

import { useFiltersV3 } from 'utils/hooks'
import { RightsQueueMegaFilterButtonPanelIds } from '../constants'
import { Checkbox, Icon, Spinner, TextInput } from '@ketch-com/deck'
import { Typography, Box } from '@mui/material'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'

const panelId = RightsQueueMegaFilterButtonPanelIds.jurisdictions

type Props = {
  jurisdictions: PolicyScopeDTO[]
  isLoading: boolean
  handlePaginationReset: () => void
}

export const JurisdictionFilter: React.FC<Props> = ({ jurisdictions, isLoading, handlePaginationReset }) => {
  const { clearAllEntriesForKey, removeQueryParamFromList, replaceParams, getPanelParamValuesArrayByPanelId } =
    useFiltersV3()
  const [searchString, setSearchString] = useState<string>('')
  const [searchLabelValue, setSearchLabelValue] = useState<string>('')

  const panelParamValuesArray = getPanelParamValuesArrayByPanelId(panelId)

  const isAllJurisdictionsSelected = panelParamValuesArray.length === jurisdictions?.length

  useDebounce(
    () => {
      if (searchString) {
        setSearchLabelValue(searchString)
      } else {
        setSearchLabelValue('')
      }
    },
    500,
    [searchString],
  )

  const filteredJurisdictions = jurisdictions.filter(jurisdiction => {
    const isChecked = !!panelParamValuesArray.find(param => param === jurisdiction.code)
    return (
      jurisdiction.name.toLowerCase().includes(searchLabelValue.toLowerCase()) ||
      jurisdiction.code.toLowerCase().includes(searchLabelValue.toLowerCase()) ||
      isChecked // always show checked jurisdictions
    )
  })

  return (
    <Box display="flex" flexWrap="wrap" flexDirection="column" alignItems="center" width="100%">
      {/* Search */}
      <Box paddingX={1.5} paddingTop={1} paddingBottom={1.5} width="100%">
        <TextInput
          sx={{ mb: 1 }}
          value={searchString}
          startIcon={<Icon name="OMag" />}
          endIcon={
            searchString && <Icon sx={{ cursor: 'pointer' }} name="OCross" onClick={() => setSearchString('')} />
          }
          size="small"
          fullWidth
          placeholder="Search Jurisdictions"
          onChange={event => setSearchString(event.target.value)}
        />
      </Box>

      {isLoading ? (
        // Loading Spinner
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="16px" thickness={2} />
        </Box>
      ) : !jurisdictions.length || !filteredJurisdictions.length ? (
        <Box height={34} width="100%" pl={1.5}>
          <Typography color="darkDuskFaded.main">No results found</Typography>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          sx={{ bgcolor: 'background.paper', maxHeight: 200, overflow: 'auto', width: '100%' }}
        >
          {/* All Jurisdictions Checkbox */}

          <Box display="flex" alignItems="center" padding="0px 3px">
            <Checkbox
              id="all-subject-types"
              name="all-subject-types"
              checked={isAllJurisdictionsSelected}
              onChange={() => {
                if (isAllJurisdictionsSelected) {
                  clearAllEntriesForKey(panelId)
                } else {
                  replaceParams({ [panelId]: jurisdictions.map(j => j.code).join(','), page: '0' })
                }
                handlePaginationReset()
              }}
            />
            <Typography>All</Typography>
          </Box>

          {/* Jurisdictions Checkboxes */}

          {filteredJurisdictions.map(jurisdiction => {
            const isChecked = !!panelParamValuesArray.find(param => param === jurisdiction.code)
            return (
              <Box key={jurisdiction.code} display="flex" alignItems="center" padding="1px 3px">
                <Checkbox
                  id={jurisdiction.code}
                  name={jurisdiction.code}
                  title={jurisdiction.name}
                  checked={isChecked}
                  onChange={() => {
                    if (isChecked) {
                      removeQueryParamFromList({ key: panelId, value: jurisdiction.code })
                    } else {
                      const paramValues = getPanelParamValuesArrayByPanelId(panelId)
                      replaceParams({ [panelId]: [...paramValues, jurisdiction.code].join(','), page: '0' })
                    }
                    handlePaginationReset()
                  }}
                />
                <Typography
                  sx={{
                    wordBreak: 'break-word',
                  }}
                >
                  {jurisdiction.name}
                </Typography>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}
