import React from 'react'
import { Box, Typography } from '@mui/material'
import { PopUp, Button, Icon, IconDictionary } from '@ketch-com/deck'

type Props = {
  setShowHowDoesItWork: React.Dispatch<React.SetStateAction<boolean>>
}

export const HowDoesItWork: React.FC<Props> = ({ setShowHowDoesItWork }) => {
  return (
    <PopUp
      popUpWidth="750px"
      variant="modal"
      onClose={e => {
        setShowHowDoesItWork(false)
      }}
      showCloseButton
      title={'How Does it Work'}
      popUpActionChildren={
        <Button color="secondary" onClick={e => setShowHowDoesItWork(false)}>
          Close
        </Button>
      }
    >
      <Box display="flex" flexDirection="column" gap={4} width={560} mx="auto" my={2}>
        <Box display="flex" alignItems="flex-start" gap={3}>
          <Icon name={IconDictionary.FaqCookiesA} width={56} height={56} />
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="label">Scan, Discover, Categorize</Typography>
            <Typography variant="body">
              We scan trackers set on your properties production environments, and match them with our database to
              automatically categorize them against standard tracker categories.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-start" gap={3}>
          <Icon name={IconDictionary.FaqCookiesB} width={56} height={56} />
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="label">Configure Purpose Mappings</Typography>
            <Typography variant="body">
              Trackers get disclosed in experiences when they are associated with a purpose, and you tag them for
              display. Your mappings between tracker categories and purposes automate the default assignment of each
              tracker to a purpose, but you can always change these defaults.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-start" gap={3}>
          <Icon name={IconDictionary.FaqCookiesD} width={56} height={56} />
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="label">Manage Issues</Typography>
            <Typography variant="body">
              If a tracker was not detected on a property or needs your attention, you can address these issues through
              the widget on the main screen.
            </Typography>
          </Box>
        </Box>
      </Box>
    </PopUp>
  )
}
