import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createDataSubjectType } from 'api/dataSubjectTypes/fetchers/createDataSubjectType'

const useCustomMutation = createUseMutation({
  mutationFn: createDataSubjectType,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateDataSubjectType = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
