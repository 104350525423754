import React, { useState } from 'react'
import Box from '@mui/material/Box'
import pluralize from 'pluralize'

import { createStyles, makeStyles } from '@mui/styles'
import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO, LabelDTO } from '@ketch-com/figurehead'
import { AssetManagerWidgetLayout, OtherLabelsV2 } from 'pages/assetManager/components'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ReactComponent as TagIcon } from 'assets/icons/tags-and-labels.svg'
import { useUpdateAssetLabelsV2 } from 'api/assets/mutations/useUpdateAssetLabelsV2'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { DataCategoriesEditModal } from './components'
import { mapValuesToPayload } from './components/DataCategoriesEditModal/utils'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      searchInput: {
        border: 'none',
        borderBottom: `1px solid ${palette.iron.main}`,
        borderRadius: 0,
      },
      emptyState: {
        backgroundColor: palette.whiteFaded.main,
        padding: spacing(4, 0),
        minHeight: 0,
      },
    }),
  { name: 'DataCategoriesWidget' },
)

type SearchFilterArgs = { label: LabelDTO; searchString: string }

const searchFilter = ({ label, searchString }: SearchFilterArgs) => {
  const tempString = Object.values(label).join('').replace(' ', '').toLowerCase()
  return tempString.includes(searchString)
}

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}
// Table whose columns have inferred labels: http://localhost:3000/asset-manager/assets/54a16621-c3e8-5dfe-9b29-c6b9f78cbaf6/view/snowflake.column

export const DataCategoriesWidget: React.FC<Props> = ({ assetSummary }) => {
  const classes = useStyles()
  const [searchString, setSearchString] = useState('')
  const [isEditMode, setIsEditMode] = useState(false)
  const queryClient = useQueryClient()

  const collectedLabels = [
    ...(assetSummary?.asset?.assignedLabels || []),
    ...(assetSummary?.asset?.inferredLabels || []),
  ]
  const labelCount = collectedLabels.length
  const shouldShowSearch = labelCount > 30
  const filteredLabels = collectedLabels.filter(o => searchFilter({ label: o, searchString }))
  const assetId = assetSummary?.asset?.resource?.id || ''

  const { mutate: handleUpdateAssetLabels, isLoading: isUpdatingAssetLabels } = useUpdateAssetLabelsV2({
    onSuccess: async () => {
      showToast({ content: 'Classifications updated', type: 'success' })
      await queryClient.refetchQueries([ApiQueryKeys.resource, { assetCode: assetId }])
    },
    onError: () => {
      showToast({ content: 'Failed to update classifications', type: 'error' })
    },
    onSettled: () => {
      setIsEditMode(false)
    },
  })

  return (
    <AssetManagerWidgetLayout
      isEditDisabled={false}
      title="Classifications"
      subTitle={`${labelCount} ${pluralize('classification', labelCount || 0)}.`}
      isEditModalOpen={isEditMode}
      onEdit={() => setIsEditMode(true)}
      editModal={
        <DataCategoriesEditModal
          isUpdatingAssetLabels={isUpdatingAssetLabels}
          assetCode={assetSummary?.asset?.resource?.id || ''}
          onSubmit={labels => {
            const formData = mapValuesToPayload({
              labels,
            })
            handleUpdateAssetLabels({
              params: {
                assetCode: assetId || '',
                formData,
              },
            })
          }}
          onCancel={() => {
            setIsEditMode(false)
          }}
        />
      }
    >
      {shouldShowSearch ? (
        <Box display="flex" alignItems="center" mb={2}>
          <SearchInput
            size="small"
            fullWidth
            className={classes.searchInput}
            onChange={nextSearchString => setSearchString(nextSearchString)}
            onClear={() => setSearchString('')}
            value={searchString}
          />
        </Box>
      ) : null}

      {collectedLabels.length > 0 ? (
        <OtherLabelsV2 labels={shouldShowSearch ? filteredLabels : collectedLabels} shouldShowNone={false} />
      ) : (
        <EmptyState
          className={classes.emptyState}
          title="No classifications assigned"
          description="This asset has no classifications."
          titleTextVariant="faded"
          descriptionTextVariant="faded"
          variant="page"
          icon={<TagIcon />}
        />
      )}
    </AssetManagerWidgetLayout>
  )
}
