import React from 'react'

import { useIdentitySpace } from 'api/identitySpaces/queries/useIdentitySpace'
import { Typography } from '@mui/material'
import { Spinner } from '@ketch-com/deck'

type Props = {
  identitySpaceCode: string
}

export const IdentitySpaceCellRenderer: React.FC<Props> = ({ identitySpaceCode }) => {
  const { data: identitySpace, isLoading: isIdentitySpaceLoading } = useIdentitySpace({
    params: {
      identitySpaceCode,
    },
  })
  if (isIdentitySpaceLoading) return <Spinner size="14px" thickness={1.5} />
  return <Typography>{identitySpace?.name}</Typography>
}
