import { useParams } from 'react-router-dom'
import { ScenarioDetails } from 'pages/assetManager/scenario/details/ScenarioDetails'
import { useAssetV2 } from 'api/assets/queries/useAssetV2'
import { UrlParams } from 'pages/assetManager/dsrConfig/interfaces'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { useDsrConfigCount } from 'api/assets/queries/useDsrConfigCount'

type Props = {}

export const ScenarioDetailsContainer: React.FC<Props> = () => {
  const { id, resourceTypeCode = '' } = useParams<UrlParams>()

  const { data: assetSummary, isLoading: isAssetSummaryLoading } = useAssetV2({
    params: {
      assetCode: id!,
      resourceTypeCode,
    },
  })
  const { data: dsrConfigCount, isLoading: isDsrConfigCountLoading } = useDsrConfigCount({
    params: {
      assetId: id,
      start: 0,
      limit: 100,
      canonicalRightCode: 'get,delete',
    },
  })
  const isReady = !isAssetSummaryLoading && !isDsrConfigCountLoading
  if (isReady) return <ScenarioDetails assetSummary={assetSummary} dsrConfigCount={dsrConfigCount} />

  return <Spinner />
}
