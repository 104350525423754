import { SplitDsrSqlScriptReq, SplitDsrSqlScriptResp } from '@ketch-com/windlass/dist/schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const splitCustomSqls = (reqPayload: SplitDsrSqlScriptReq) => {
  return API.post<SplitDsrSqlScriptResp>(
    formatRequestString({
      entityUrl: `/api/asset-manager/dsr-sql/multiple`,
    }),
    reqPayload,
  )
}
