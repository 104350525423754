import { ICanvasStep } from '../../interfaces'
import { CanvasStepType } from '../constants'
import { isUndefined } from 'lodash'

/**
 * Determine the type of a canvas step
 * @param step A canvas step to determine the type of
 */
export const getStepType = (step: ICanvasStep): CanvasStepType => {
  if (!isUndefined(step.placeholder)) return CanvasStepType.Placeholder
  if (!isUndefined(step.activity)) return CanvasStepType.Activity
  if (!isUndefined(step.gateway)) return CanvasStepType.Gateway
  if (!isUndefined(step.workflow)) return CanvasStepType.Workflow
  if (!isUndefined(step.finish)) return CanvasStepType.End
  if (!isUndefined(step.start)) return CanvasStepType.Start
  return CanvasStepType.Undefined
}
