import React from 'react'

import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { Typography, Box } from '@mui/material'
import { Chip } from '@ketch-com/deck'

type Props = {
  identitySpace: IdentitySpaceDTO
}

export const IdentitySpaceViewDetails: React.FC<Props> = props => {
  const { queries } = useQueryParams<{ version?: string }>()
  const { version } = queries
  const { identitySpace } = props

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" alignItems="center" gap={1} flexWrap={'wrap'}>
        {identitySpace.code && (
          <Chip
            size="small"
            label={
              <Typography variant="smallLabel">
                Code: <Typography variant="smallBody">{identitySpace.code}</Typography>
              </Typography>
            }
          />
        )}
        {identitySpace.typeCode && <Chip label={identitySpace.typeCode} size="small" />}
        {identitySpace.formatCode && <Chip label={identitySpace.formatCode} size="small" />}

        {(!identitySpace.latest || !!version) && identitySpace.version && (
          <Chip label={`v${identitySpace.version}`} size="small" />
        )}
      </Box>
    </Box>
  )
}
