import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { updateDataSubjectType } from 'api/dataSubjectTypes/fetchers/updateDataSubjectType'

const useCustomMutation = createUseMutation({
  mutationFn: updateDataSubjectType,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateDataSubjectType = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
