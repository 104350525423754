import { FetchFormTemplatePreviewsResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

import { API } from 'utils/api/api'
import { formatRequestStringV2 } from 'utils/helpers/api/formatRequestStringV2'
type Params = APIListRequestParams<{
  languageCode?: string
}>
export const fetchFormTemplatesPreviews = ({ languageCode }: Params) =>
  API.get<FetchFormTemplatePreviewsResponseBodyDTO>(
    formatRequestStringV2({
      entityUrl: `/api/form-templates/previews`,
      params: {
        filters: {
          ...(languageCode && { languageCode }),
        },
      },
    }),
  )
