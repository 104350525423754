import React from 'react'
import { Box } from '@mui/material'
import clsx from 'clsx'

import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { Text, TextColorOptions } from 'components/ui-kit/typography/Text'

import { AssetChip } from '../components'
import { getAllowAccessChipText, getNamedOrLabeled, getPolicyEffectDisplayText } from '../utils'
import { useTreeStyles } from '../hooks'
import { getPolicyEffectDisplayTextColor } from '../utils/getPolicyEffectDisplayTextColor'

type Props = {
  policy: AssetPolicyDTO
}

export const ApplyEffectsSection: React.FC<Props> = ({ policy }) => {
  const classes = useTreeStyles()
  const { effects } = policy

  if (!effects?.length) {
    return null
  }

  return (
    <>
      <Box display="flex" alignItems="center" mb={4} mt={5}>
        <Text size={14} weight={600}>
          Apply Effects:
        </Text>
      </Box>

      <Box className={classes.treeContainer}>
        {(effects || []).map(effect => (
          <Box key={JSON.stringify(effect)} className={classes.treeNodeSVG}>
            <Box className={classes.treeChild}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  {/* Mask/Deny/Hash */}

                  <Text
                    className={clsx(classes.withMarginRight, classes.capitalize)}
                    size={14}
                    weight={600}
                    color={getPolicyEffectDisplayTextColor(effect?.effect || '') as TextColorOptions}
                  >
                    {getPolicyEffectDisplayText(effect?.effect || '')}
                  </Text>

                  <Text className={classes.withMarginRight} size={14}>
                    attributes
                  </Text>

                  {/* Asset Type */}

                  <Text className={classes.withMarginRight} size={14} weight={600}>
                    {getNamedOrLabeled(effect)}
                  </Text>

                  <AssetChip name={getAllowAccessChipText({ type: getNamedOrLabeled(effect), ass: effect })} />
                </Box>
                <Box display="flex" alignItems="center">
                  <Text size={11} weight={500} color="darkDuskFaded">
                    {/* TODO:BAC - Make dynamic.  This will need to be returned on the fetched policy */}
                    42 assets affected
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  )
}
