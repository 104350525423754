import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { deleteDataSubjectType } from 'api/dataSubjectTypes/fetchers/deleteDataSubjectType'

const useCustomMutation = createUseMutation({
  mutationFn: deleteDataSubjectType,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeleteDataSubjectType = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
