import React, { useCallback } from 'react'
import { useFormikContext } from 'formik'
import { Box, ContainerProps, Grid } from '@mui/material'
import { Team } from 'interfaces/services/harbormaster/teams_gen.schemas'
import { AssigneeOrTeamSelector } from 'components/AssigneeSelector/AssigneeOrTeamSelector'

type Props = {
  name: string
  label?: string
  size?: 'small' | 'medium'
  inFormRow?: boolean
  showAssignToMeButton?: boolean
  showLabel?: boolean
  width?: string
  onItemClick?: () => void
  isOptional?: boolean
}

// Assignee option rows can be users, teams, or header dividers
// All three require ID+Name
export type ListItem = {
  id: string
  name: string
  header?: string // Indicate that it is an unclickable header row
  isMe?: boolean // Indicate that it is the current user, indicate as such
} & Team

export const FormAssigneeOrTeamSelector: React.FC<Props> = ({
  label,
  name,
  size = 'medium',
  width,
  inFormRow = true,
  showAssignToMeButton = true,
  showLabel = true,
  onItemClick = () => {},
  isOptional = false,
}) => {
  const Container: React.FC<ContainerProps> = useCallback(
    ({ children }) =>
      inFormRow ? (
        <Box marginBottom={3}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <>{children}</>
      ),
    [inFormRow],
  )

  const { setFieldValue, values } = useFormikContext()
  // `Name` param should be a safe reference to the field name
  const assigneeId = (values as any)[name]

  return (
    <Container>
      <AssigneeOrTeamSelector
        size={size}
        width={width}
        label={showLabel ? label : undefined}
        hasAssignToMeButton={showAssignToMeButton}
        onChange={ID => {
          if (ID) setFieldValue(name, ID)

          onItemClick?.()
        }}
        selectedAssigneeId={assigneeId}
        required={!isOptional}
      />
    </Container>
  )
}
