import React from 'react'
import { Box } from '@mui/material'

import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { InfoRow, DataAssetsImpactedInfo, ApprovalInfo } from './components'
import { getPolicyTypeDisplayText } from './utils'
import { PurposeChip } from '..'

type Props = {
  policy: AssetPolicyDTO
}

export const OverviewTab: React.FC<Props> = ({ policy }) => {
  return (
    <>
      <Box
        sx={{
          borderBottom: ({ palette }) => `1px solid ${palette.iron.main}`,
          pb: 3.5,
          pt: 1.5,
        }}
        display="flex"
        alignItems="center"
      >
        <Text size={20} weight={700}>
          Basic Details
        </Text>
      </Box>

      <InfoRow
        title="Description"
        info={policy?.description ? <Text size={14}>{policy?.description}</Text> : <EmptyValueRenderer />}
        hasPadding={true}
      />

      <InfoRow
        title="Type"
        hasPadding={true}
        info={
          <Text size={14} weight={600}>
            {getPolicyTypeDisplayText(policy?.category || 0)}
          </Text>
        }
      />

      <InfoRow title="Purpose" info={<PurposeChip purpose={policy?.purpose || ''} />} hasPadding={true} />

      <InfoRow
        title="Data Assets Impacted"
        hasPadding={true}
        info={
          <DataAssetsImpactedInfo
            policy={
              policy as AssetPolicyDTO & {
                assetsImpacted: { [key: string]: string }
              }
            }
          />
        }
      />

      <InfoRow
        title="Approval"
        hasPadding={true}
        info={
          <ApprovalInfo
            policy={
              policy as AssetPolicyDTO & {
                approval: { [key: string]: string }[]
              }
            }
          />
        }
        hasBorderBottom={false}
      />
    </>
  )
}
