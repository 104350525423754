import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchStack } from '../fetchers/fetchStack'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.stack,
  queryFn: fetchStack,
  select: res => res?.data?.stack || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useStack = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
