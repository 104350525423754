import {
  ListInstalledDataSystemsV2RequestBodyDTO,
  ListInstalledDataSystemsV2ResponseBodyDTO,
} from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchInstalledDataSystemsV2List = ({ filters, pagination }: ListInstalledDataSystemsV2RequestBodyDTO) => {
  return API.post<ListInstalledDataSystemsV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/installed-data-systems/list`,
    }),
    { filters, pagination },
  )
}
