import React, { useContext } from 'react'
import { Routes, Route } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { ThemeVersionsTab } from 'pages/consentAndRights/themes-v3/view/tabs/ThemeVersionsTab'
import { ThemeViewContext } from './context/ThemeViewContext'
import { ThemeDeploymentTab } from './tabs/ThemeDeploymentTab'
import { ExperiencePreview } from 'pages/consentAndRights/experiences-v2/view/components/experiencePreview/ExperiencePreview'
import { ExperiencePreviewVariant } from 'pages/consentAndRights/experiences-v2/view/components/experiencePreview/constants'

export const ThemeViewRoutes: React.FC = () => {
  const { location, theme } = useContext(ThemeViewContext)

  return (
    <Routes location={location}>
      <Route path="/" element={<ExperiencePreview variant={ExperiencePreviewVariant.Theme} themeID={theme?.id} />} />
      <Route
        path={RoutesManager.deployment.themesV3.view.preview.root.pattern}
        element={<ExperiencePreview variant={ExperiencePreviewVariant.Theme} themeID={theme?.id} />}
      />
      <Route path={RoutesManager.deployment.themesV3.view.deployment.root.pattern} element={<ThemeDeploymentTab />} />
      <Route path={RoutesManager.deployment.themesV3.view.versions.root.pattern} element={<ThemeVersionsTab />} />
    </Routes>
  )
}
