import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  themeCode: string
  organizationCode: string
}>

export const deleteTheme = ({ organizationCode, themeCode }: Params) =>
  API.delete<null>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/themes/${themeCode}`,
    }),
  )
