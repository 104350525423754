import React from 'react'

import { useUsersPaginated } from 'api/users/queries/useUsersPaginated'
import { UsersList } from 'pages/settings/users/list/UsersList'

export const UsersListContainer = () => {
  const {
    data: users,
    isLoading,
    isFetching,
    pagination,
  } = useUsersPaginated({
    params: {
      includeMetadata: true,
    },
  })

  return <UsersList isLoading={isLoading} isFetching={isFetching} users={users} pagination={pagination} />
}
