import { useFormikContext } from 'formik'
import { useContext, useEffect } from 'react'
import { ExperienceUpsertContext } from '../context/ExperienceUpsertContext'
import { getErrorSection } from '../utils/utils'

/**
 * Component that watches the formik error state and switches the experience
 * builder section if there is a section mapped to a particular error.
 */
export const ExperienceFormErrorSectionSwitcher: React.FC = () => {
  const { setExperienceBuilderSection } = useContext(ExperienceUpsertContext)
  const { isValid, isValidating, isSubmitting, errors } = useFormikContext()

  useEffect(() => {
    if (!isValid && !isValidating && !isSubmitting) {
      const errorSection = getErrorSection(errors)
      if (errorSection) setExperienceBuilderSection(errorSection)
    }
  }, [isValid, isValidating, isSubmitting, errors, setExperienceBuilderSection])

  return null
}
