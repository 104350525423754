import { ExperienceBannerPrimaryButtonAction } from 'interfaces/experiences/experienceBannerPrimaryButtonAction'
import { PreferenceExperienceFormValues } from '../../../interfaces/PreferenceExperienceFormValues'
import { ConsentExperienceFormValues } from '../../consent/interfaces'
import { BannerField, JitField, ModalField, PreferenceField } from '../constants'
import { ExperienceFormField } from '../interfaces'

export const isFieldRequired = ({
  field,
  values,
}: {
  field: ExperienceFormField
  values?: ConsentExperienceFormValues | PreferenceExperienceFormValues
}) => {
  const alwaysRequiredFields = new Set([
    BannerField.DESCRIPTION,
    BannerField.CONFIRM_BUTTON,

    ModalField.MAIN_TITLE,
    ModalField.CONFIRM_BUTTON,

    JitField.MAIN_TITLE,
    JitField.DECLINE_BUTTON,
    JitField.CONFIRM_BUTTON,

    PreferenceField.MAIN_TITLE,
    PreferenceField.OVERVIEW_TAB_NAME,
    PreferenceField.OVERVIEW_BODY_DESCRIPTION,
    PreferenceField.RIGHTS_TAB_NAME,
    PreferenceField.RIGHTS_BODY_DESCRIPTION,
    PreferenceField.RIGHTS_FOOTER_BUTTONS,
    PreferenceField.PREFERENCES_TAB_NAME,
    PreferenceField.PREFERENCES_SUBMIT_BUTTON,
    PreferenceField.SUBSCRIPTIONS_TAB_NAME,
    PreferenceField.SUBSCRIPTIONS_PRIMARY_BUTTON,
    PreferenceField.SUBSCRIPTIONS_SECONDARY_BUTTON,
  ])

  switch (field) {
    case BannerField.ACTION_BUTTON:
      return !!(
        (values as ConsentExperienceFormValues)?.banner.primaryButtonAction ===
        ExperienceBannerPrimaryButtonAction.ACCEPT_ALL
      )

    default:
      return alwaysRequiredFields.has(field)
  }
}
