import React, { MouseEvent, useState } from 'react'
import { Formik, Form } from 'formik'
import { MenuAutocomplete, ListItemText, ActionSheetItem, Icon, Button } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

interface MyOption {
  myLabel: string
  myValue: string
}

// TODO:BAC - dummy options should be able to take on any shape (so long as consistent)
const dummyOptions: MyOption[] = [
  {
    myLabel: 'Apple',
    myValue: 'apple',
  },
  {
    myLabel: 'Banana',
    myValue: 'banana',
  },
  {
    myLabel: 'Orange',
    myValue: 'orange',
  },
  {
    myLabel: 'Pineappleeeeeeeeeeeeeeeeeeeeeeeee', // to show how it handles a long label
    myValue: 'pineapple',
  },
]

export const AutoCompleteSingleSelectExample: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)

  const open = Boolean(anchorEl)

  const handleClose = () => {
    if (anchorEl) anchorEl.focus()
    setAnchorEl(undefined)
  }

  const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h3" mb={2}>
        Autocomplete Single Select Example with Formik anchored to a button
      </Typography>

      <Formik
        initialValues={{
          autocompleteField: '',
        }}
        onSubmit={values => console.info('onSubmit, values = ', values)}
      >
        {formik => {
          const { values, setFieldValue } = formik

          return (
            <Form>
              <Button
                size="large"
                color="primary"
                disableRipple
                onClick={handleClick}
                endIcon={<Icon name="OArrowCDown" />}
              >
                {(values && values.autocompleteField) || 'Select'}
              </Button>
              <MenuAutocomplete
                multiple={false}
                options={dummyOptions}
                value={dummyOptions.find(opt => opt.myValue === values.autocompleteField) || null}
                isAutocompleteOpen={open}
                anchorEl={anchorEl}
                handleOnClose={handleClose}
                wrapperPopperId={'wrapper-popper-id'}
                isOptionEqualToValue={(option, value) => option.myValue === value.myValue}
                getOptionLabel={elem => elem.myLabel}
                noOptionsText={'Nothing found message text'}
                showSelectionCountSection={true}
                handleOnChange={(newValue, reason) => {
                  setFieldValue('autocompleteField', newValue ? newValue.myValue : '')
                }}
                renderOption={(props, option, { selected }) => (
                  <ActionSheetItem selected={selected} {...props}>
                    <ListItemText selected={selected}>{option.myLabel}</ListItemText>
                  </ActionSheetItem>
                )}
              />

              <Box mt={2}>
                <Button color="tertiary" type="submit" size="large">
                  Submit Formik Form
                </Button>
              </Box>

              <pre>{JSON.stringify(formik, null, 2) || 'nothing to preview'}</pre>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}
