import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { getArtifactoryCreds } from '../fetchers/createArtifactoryCreds'

const useCustomMutation = createUseMutation({
  mutationFn: getArtifactoryCreds,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useGetArtifactoryCreds = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
