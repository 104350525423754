import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIListRequestParams } from 'api/common/utils'
import { VendorsResponse } from '@ketch-com/supercargo/dist/gvl_gen.schemas'

type Params = APIListRequestParams<{
  region?: string
}>

export const fetchGoogleVendorList = ({ region }: Params) =>
  API.get<VendorsResponse>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/vendors/${region}?type=google_vendor_list`,
    }),
  )
