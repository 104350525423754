import { Chip, InfoRow, InfoRowProps } from '@ketch-com/deck'
import { useEffect, useState } from 'react'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { RadioGroup, RadioOptionValue } from 'components/RadioGroup/RadioGroup'
import { InherentRiskLevelColorOption } from 'pages/dataSystems/DataSystem/types'

export type ChipContentRadioEditOptions = {
  [key: string]: RadioOptionValue
}

type EditComponentProps = {
  value?: string
  setValue: (selectedChips?: string) => void
  options: ChipContentRadioEditOptions
  allowDelete?: boolean
  // chipOptions and radioOptions exists for if we need different options for what to display vs what to allow as selectable.
  // This is to maintain backwards compatibility
  // ex: if datatype is 'unknown' we'd like to be able to display that
  // but it should never be selectable
  // each defaults to using options
  chipOptions?: ChipContentRadioEditOptions
  radioOptions?: ChipContentRadioEditOptions
  isCustomField?: boolean
  chipColorPalette?: InherentRiskLevelColorOption
}

export interface ChipContentInfoRowProps extends InfoRowProps, EditComponentProps {}

export default function ChipContentRadioEditInfoRow({
  allowDelete = true,
  options,
  setValue,
  value,
  chipOptions,
  chipColorPalette,
  radioOptions,
  isCustomField = false,
  ...rest
}: ChipContentInfoRowProps) {
  const [localValue, setLocalValue] = useState<string>()
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const chipOptionList = chipOptions || options
  const radioOptionList = radioOptions || options

  return (
    <InfoRow
      {...rest}
      isEditable
      isEditing={isEditing}
      onEditChange={() => {
        setIsEditing(true)
      }}
      onAcceptChange={() => {
        setIsEditing(false)
        setValue(localValue)
      }}
      onCancelChange={() => {
        setIsEditing(false)
      }}
      onDeleteChange={
        allowDelete
          ? () => {
              setIsEditing(false)
              setValue()
            }
          : undefined
      }
      isEmpty={localValue?.length === 0}
      titleAddendum={isCustomField ? 'Custom' : undefined}
    >
      {isEditing ? (
        <RadioGroup
          options={Object.values(radioOptionList)}
          value={localValue}
          onChange={e => {
            setLocalValue(e.currentTarget.value)
          }}
        />
      ) : localValue ? (
        <Chip
          label={chipOptionList[localValue].title}
          sx={{
            color: chipColorPalette?.color,
            backgroundColor: chipColorPalette?.background,
            '&:hover': {
              backgroundColor: chipColorPalette?.background,
              cursor: 'auto',
            },
          }}
          onClick={e => {
            e.stopPropagation()
          }}
        />
      ) : (
        <EmptyValueRenderer />
      )}
    </InfoRow>
  )
}
