import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { UserShortDTO } from 'interfaces/users/users'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
  includeMetadata?: boolean
  active?: boolean
}>

type Response = APIListResponse<{ members: UserShortDTO[] }>

export const fetchUsers = ({ organizationCode, includeMetadata, active, start, limit }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/members`,
      params: {
        filters: {
          includeMetadata,
          start,
          limit,
          active,
        },
      },
    }),
  )
