import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { fetchRelationshipMappingPaginated } from '../fetchers/fetchRelationshipMappingPaginated'
import { APIListRequestParams } from 'api/common/utils'

type Options = {
  enabled?: boolean
}

type Params = APIListRequestParams<{
  discovered?: boolean
  assetType?: string
  query?: string
}>

type RelationshipMappingData = {
  params: Params
  options?: Options
}

export const useRelationshipMappingInfinite = ({
  options: { enabled = true } = {},
  params,
}: RelationshipMappingData) => {
  const resp = useInfiniteQuery({
    enabled: !!enabled,
    queryKey: [
      ApiQueryKeys.relationshipMapping,
      {
        ...params,
      },
    ],
    queryFn: async ({ pageParam = {} }) => {
      const fetchRelationshipsResp = await fetchRelationshipMappingPaginated({
        nextToken: pageParam?.nextToken,
        ...params,
      })

      return fetchRelationshipsResp
    },
    getNextPageParam: lastPage => {
      const nextToken = lastPage?.data?.nextToken
      return nextToken ? { nextToken } : undefined
    },
  })

  return {
    ...resp,
    relationships: resp?.data?.pages?.map(page => page?.data?.relationships || []).flat() || [],
    totalResults: resp?.data?.pages?.[0]?.data?.totalResults || 0,
  }
}
