import { fetchGoogleVendorList } from 'api/googleVendorList/fetchers/fetchGoogleVendorList'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { VendorsList } from '@ketch-com/supercargo/dist/gvl_gen.schemas'

const fallbackGoogleList: VendorsList = {
  vendors: [],
}

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.googleVendorList,
  queryFn: fetchGoogleVendorList,
  select: res => res?.data?.vendors || fallbackGoogleList,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useGoogleVendorList = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
