import { usePermitStatsTrend } from 'api/permitStatistics/queries/usePermitsStatsTrend'
import { getPermitStatisticsFilterState } from 'store/permitStatisticsFilterStateSlice/selectors'
import { useAppSelector } from 'store/hooks'
import { faker } from '@faker-js/faker'
import { useMemo } from 'react'
import moment from 'moment'
import { getReshapedDataForChart, getMonthlyData, getIsAllTrendDataEmpty } from '../utils'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'

export const usePermitsTrendChartUtils = () => {
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])
  const { startDate, endDate, identitySpace, jurisdiction, environment, property, purpose, trendPeriod } =
    useAppSelector(getPermitStatisticsFilterState)
  const { data: dailyTrendData, isLoading: isLoadingTrendData } = usePermitStatsTrend({
    enabled: !!startDate && !!endDate,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  // hard code "isLocalDevelopment" to false in order to see un-augmented data being returned
  const isLocalDevelopment = false
  // uncomment the following line to see dummy being added to empty days to see more robust data viz
  // const isLocalDevelopment = window?.location?.href?.includes?.('localhost')

  // remove days with no data. Any day with only a `timeStamp` field is considered empty.
  const filteredDailyTrendDataProd = dailyTrendData || []

  const filteredDailyTrendDataLocalDevelopment = useMemo(() => {
    return (dailyTrendData || []).map(t => {
      const isRandomlyEmpty = faker.datatype.boolean()
      const keyCount = Object.keys(t || {}).length

      if (isRandomlyEmpty) return { ...t }

      if (keyCount > 1) {
        return t
      }
      if (keyCount === 1) {
        return {
          timeStamp: t.timeStamp,
          noConsent: faker.datatype.number({ min: 1, max: 200 }),
          consentNotRequired: faker.datatype.number({ min: 1, max: 200 }),
          consent: faker.datatype.number({ min: 0, max: 200 }),
        }
      }
      return { ...t }
    })
  }, [dailyTrendData])

  const filteredDailyTrendData = isLocalDevelopment
    ? filteredDailyTrendDataLocalDevelopment // "filteredDailyTrendDataLocalDevelopment" will have random data injected into to each empty day to better visualize typical results. See "isLocalDevelopment" above.
    : filteredDailyTrendDataProd

  const reshapedDailyTrendData = getReshapedDataForChart(
    filteredDailyTrendData.sort((a, b) => (a?.timeStamp || 0) - (b?.timeStamp || 0)),
    trendPeriod,
    startDate,
    endDate,
    t,
  )
  const monthlyData = getMonthlyData(reshapedDailyTrendData)

  const dailyAggregateData = filteredDailyTrendData.reduce(
    (acc, curr) => {
      acc.consent! += curr?.consent || 0
      acc.consentNotRequired! += curr?.consentNotRequired || 0
      acc.noConsent! += curr?.noConsent || 0
      return acc
    },
    {
      consent: 0,
      consentNotRequired: 0,
      noConsent: 0,
    },
  )

  const aggregateAllowed = (dailyAggregateData.consent || 0) + (dailyAggregateData.consentNotRequired || 0)
  const aggregateTotal =
    (dailyAggregateData.consent || 0) +
    (dailyAggregateData.consentNotRequired || 0) +
    (dailyAggregateData.noConsent || 0)
  const aggregateAllowanceRate = (aggregateAllowed / (aggregateTotal || 1)) * 100

  const shouldShowEmptyState = getIsAllTrendDataEmpty(filteredDailyTrendData)

  const dataForCSVDownload = reshapedDailyTrendData.map(t => {
    return {
      date: `${t?.fullDayName}, ${t?.monthName} ${t?.dateOfMonth}, ${t?.year}`,
      'No Consent Count': t?.noConsent || 0,
      'Consent Not Required Count': t?.noConsentRequired || 0,
      'Consent Count': t?.consent || 0,
    }
  })

  const csvData = [
    [...Object.keys(dataForCSVDownload?.[0] || {})],
    ...dataForCSVDownload.map(t => {
      return Object.values(t || {})
    }),
  ]

  return {
    aggregateAllowanceRate,
    aggregateAllowed,
    aggregateTotal,
    csvData,
    dailyAggregateData,
    isLoadingTrendData,
    monthlyData,
    reshapedDailyTrendData,
    shouldShowEmptyState,
    trendPeriod,
  }
}
