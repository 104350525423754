import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { Link } from 'components/ui-kit/link/Link'
import { Box, Typography, Tooltip } from '@mui/material'
import { useCopyToClipboard } from 'react-use'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { Icon } from '@ketch-com/deck'

const useStyles = makeStyles(
  ({ typography, palette, spacing }) =>
    createStyles({
      root: {
        whiteSpace: 'normal',
      },
      nameText: {
        display: 'block',
        color: palette.darkDusk.main,
      },
      nameTextRegular: {
        fontSize: typography.pxToRem(14),
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '-0.01em',
      },
      nameTextBig: {
        fontSize: typography.pxToRem(20),
        lineHeight: 1.4,
        fontWeight: 700,
        letterSpacing: '-0.02em',
      },
      codeText: {
        display: 'block',
        fontWeight: 500,
        color: palette.darkDuskFaded.main,
      },
      codeTextRegular: {
        fontSize: typography.pxToRem(11),
        lineHeight: 1.36,
        letterSpacing: '0.01em',
      },
      codeTextBig: {
        fontSize: typography.pxToRem(14),
        lineHeight: 1.43,
        letterSpacing: '-0.01em',
      },
      infoIcon: {
        fontSize: typography.pxToRem(14),
        marginLeft: spacing(1),
      },
      capitalize: {
        textTransform: 'capitalize',
      },
      boldName: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '-0.01em',
      },
    }),
  { name: 'NameAndCodeCellRenderer' },
)
export interface Props {
  /** Entity name */
  name?: React.ReactNode
  /** Entity code */
  code?: React.ReactNode
  /** Component size code */
  size?: 'big' | 'regular' | 'normal'
  /** Custom wrapper className */
  className?: string
  /** Custom classes */
  classes?: Partial<ReturnType<typeof useStyles>>
  /** Optional shortcut link */
  shortCutLink?: string | null
  /** Optional capitalize name */
  shouldCapitalizeName?: boolean
  /** Optional max width with ellipsis */
  maxWidth?: number
  /** Is name bold */
  isNameBold?: boolean
  /** truncation function */
  truncateCodeFunction?: (id?: string) => string
}

export const NameAndCodeCellRenderer: React.FC<Props> = ({
  name,
  code,
  size = 'regular',
  className,
  classes: _classes,
  shortCutLink = null,
  shouldCapitalizeName = false,
  maxWidth,
  isNameBold = false,
  truncateCodeFunction,
}) => {
  const classes = useStyles({ classes: _classes })
  const [, copyToClipboard] = useCopyToClipboard()

  const isRegularSize = size === 'regular'
  const isBigSize = size === 'big'

  const maxWidthStyle = maxWidth
    ? { maxWidth, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }
    : undefined

  return (
    <Box className={clsx(classes.root, className)} sx={maxWidthStyle}>
      {!name && !code && <EmptyValueRenderer color="fadedDarkGrey" />}
      {!!name && (
        <Box
          display="inline"
          sx={maxWidthStyle}
          title={typeof name === 'string' ? name : undefined}
          className={clsx(classes.nameText, {
            [classes.nameTextRegular]: isRegularSize,
            [classes.nameTextBig]: isBigSize,
            [classes.capitalize]: shouldCapitalizeName,
            [classes.boldName]: isNameBold,
          })}
        >
          {name}

          {!!shortCutLink && (
            <Link variant="black" to={shortCutLink}>
              <Tooltip title="View Experience">
                <Icon name="OInfo" width={14} height={14} sx={{ marginLeft: ({ spacing }) => spacing(1) }} />
              </Tooltip>
            </Link>
          )}
        </Box>
      )}
      {!!code && !truncateCodeFunction && (
        <Box
          display="inline"
          sx={maxWidthStyle}
          title={typeof code === 'string' ? code : undefined}
          className={clsx(classes.codeText, {
            [classes.codeTextRegular]: isRegularSize,
            [classes.codeTextBig]: isBigSize,
          })}
        >
          {code}
        </Box>
      )}

      {!!code && !!truncateCodeFunction && typeof code === 'string' && (
        <Box
          display="inline"
          sx={maxWidthStyle}
          onClick={e => {
            e.stopPropagation()
            copyToClipboard(code)
            showToast({ content: 'Copied to clipboard', type: 'success' })
          }}
          className={clsx(classes.codeText, {
            [classes.codeTextRegular]: isRegularSize,
            [classes.codeTextBig]: isBigSize,
          })}
        >
          <Tooltip
            title={
              <Box display="flex" p={1} flexDirection="column">
                <Typography variant="label" color="white.main" mb={1}>
                  {code}
                </Typography>

                <Typography variant="smallLabel" color="white.main">
                  Click to copy to clipboard
                </Typography>
              </Box>
            }
          >
            <Box component="span">{truncateCodeFunction ? truncateCodeFunction(code) : code}</Box>
          </Tooltip>
        </Box>
      )}
    </Box>
  )
}
