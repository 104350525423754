import { Box } from '@mui/material'
import { WorkflowExecutionStepName } from 'interfaces/workflowExecutions/workflowExecution'
import { Icon } from '@ketch-com/deck'

export const renderIcon = (stepName?: string, backgroundColor?: string, iconURL?: string, size = 'small') => {
  if (stepName === WorkflowExecutionStepName.START || stepName === WorkflowExecutionStepName.END) {
    return (
      <Icon
        inheritViewBox
        name={stepName === WorkflowExecutionStepName.START ? 'WStart' : 'WEnd'}
        width={24}
        height={24}
      />
    )
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
        borderRadius: '6px',
        height: size === 'small' ? 24 : 36,
        width: size === 'small' ? 24 : 36,
        maxHeight: size === 'small' ? 52 : 78,
        backgroundColor,
        flexShrink: 0,
      }}
    >
      <img
        src={iconURL}
        alt=""
        style={{
          width: '100%',
        }}
      />
    </Box>
  )
}
