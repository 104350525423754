import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { LegalBaseDTO } from 'interfaces/legalBases/legalBase'
import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import {
  BasicDetails,
  ContactMethods,
  LegalBasis,
} from 'pages/policyCenter/subscriptions/subscriptionTopic/upsert/components'
import { SubscriptionTopicFormData, UrlParams } from 'pages/policyCenter/subscriptions/interfaces'
import {
  getSubscriptionTopicFormInitialValues,
  getSortedTopicTranslations,
} from 'pages/policyCenter/subscriptions/utils'
import { useSubscriptionTopicFormValidation } from 'pages/policyCenter/subscriptions/hooks'
import { FormMode } from 'interfaces/formModes/formMode'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import {
  SubscriptionContactMethodDTO,
  SubscriptionTopicDTO,
  WebhookDTO,
  GetSubscriptionTopicOrchestrationsResponseBodyDTO,
  AppDTO,
} from '@ketch-com/figurehead'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'

type Props = {
  legalBases: LegalBaseDTO[]
  subscriptionContactMethods: SubscriptionContactMethodDTO[]
  onSubmit: (values: SubscriptionTopicFormData) => void
  isReady: boolean
  subscriptionTopic: SubscriptionTopicDTO
  organizationLanguages: LanguageWithStatusDTO[]
  webhooksData: WebhookDTO[]
  systemsData: AppDTO[]
  subscriptionTopicOrchestrations: GetSubscriptionTopicOrchestrationsResponseBodyDTO
}
export const EditSubscriptionTopic: React.FC<Props> = ({
  onSubmit,
  isReady,
  legalBases,
  subscriptionTopic,
  organizationLanguages,
  subscriptionContactMethods,
  webhooksData,
  systemsData,
  subscriptionTopicOrchestrations,
}) => {
  const navigate = useNavigate()
  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Subscriptions', link: RoutesManager.policyCenter.subscriptions.list.subscriptionTopics.root.getURL() },
    { title: 'Edit' },
  ]

  const { formMode } = useParams<UrlParams>()
  const isEditMode = formMode === FormMode.EDIT

  // Include new languages added to the org or all languages incase of no translation
  organizationLanguages?.forEach?.(orgLang => {
    const hasLanguage = Boolean(subscriptionTopic.translations?.[orgLang?.language?.code])
    if (!orgLang.system && !hasLanguage) {
      if (!subscriptionTopic.translations) subscriptionTopic.translations = {}
      subscriptionTopic.translations[orgLang?.language?.code] = {}
    }
  })

  // BE has translations map arranged by langCode instead of langName
  subscriptionTopic.translations = getSortedTopicTranslations(subscriptionTopic.translations, organizationLanguages)
  const initialValues = getSubscriptionTopicFormInitialValues({
    subscriptionTopic,
    subscriptionContactMethods,
    subscriptionTopicOrchestrations,
  })

  const validationSchema = useSubscriptionTopicFormValidation({
    isEditMode,
    subscriptionContactMethods,
    webhooksData,
    systemsData,
  })

  const formikProps = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
  })

  const { isSubmitting, submitForm } = formikProps
  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={UpsertLayoutMode.edit}
          renderFormTitle={() => <ContentGroup title="Edit Subscription" titleVariant="h2" isDivider />}
          onAccept={submitForm}
          onCancel={() => {
            navigate(RoutesManager.policyCenter.subscriptions.list.subscriptionTopics.root.getURL())
          }}
          acceptActionButtonText="Save"
          cancelActionButtonText="Cancel"
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {isReady ? (
              <>
                <BasicDetails organizationLanguages={organizationLanguages} />
                <ContactMethods
                  subscriptionContactMethods={subscriptionContactMethods}
                  webhooksData={webhooksData}
                  systemsData={systemsData}
                />
                <LegalBasis />
              </>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%" m={6}>
                <Spinner size="32px" thickness={2.5} />
              </Box>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
