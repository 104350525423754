import { useRelatedAssetsInfiniteV3 } from './useRelatedAssetsInfiniteV3'
import { useRelatedAssetsInfiniteV2 } from './useRelatedAssetsInfiniteV2'
import { APIListRequestParams } from 'api/common/utils'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type Params = APIListRequestParams<{
  canonicalResourceType?: number | null | undefined
  resourceTypeCode?: string | null | undefined
  query?: string | null | undefined
  assetCode?: string | null | undefined
  identityRelationType?: number
  isPersonal?: boolean
}>

type UseV2OrV3RelatedAssetsInfiniteBasedOnFeatureFlagArgs = {
  params: Params
}

export const useV2OrV3RelatedAssetsInfiniteBasedOnFeatureFlag = ({
  params,
}: UseV2OrV3RelatedAssetsInfiniteBasedOnFeatureFlagArgs) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )

  /* V2 */

  const {
    data: infiniteAssetsDataV2,
    isLoading: isLoadingV2,
    hasNextPage: hasNextPageV2,
    fetchNextPage: fetchNextPageV2,
    isFetchingNextPage: isFetchingNextPageV2,
    totalResults: totalResultsV2,
  } = useRelatedAssetsInfiniteV2({
    enabled: isV2FeatureFlagEnabled,
    params: {
      ...params,
    },
  })

  const payloadV2 = {
    infiniteAssetsData: infiniteAssetsDataV2 || [],
    isLoading: isLoadingV2,
    hasNextPage: hasNextPageV2,
    fetchNextPage: fetchNextPageV2,
    isFetchingNextPage: isFetchingNextPageV2,
    totalResults: totalResultsV2,
  }

  /* V3 */

  const {
    assetsData: assetsDataV3,
    isLoading: isLoadingV3,
    hasNextPage: hasNextPageV3,
    fetchNextPage: fetchNextPageV3,
    isFetchingNextPage: isFetchingNextPageV3,
    totalResults: totalResultsV3,
  } = useRelatedAssetsInfiniteV3({
    options: {
      enabled: !isV2FeatureFlagEnabled,
    },
    params: {
      ...params,
    },
  })

  const payloadV3 = {
    infiniteAssetsData: assetsDataV3 || [],
    isLoading: isLoadingV3,
    hasNextPage: hasNextPageV3,
    fetchNextPage: fetchNextPageV3,
    isFetchingNextPage: isFetchingNextPageV3,
    totalResults: totalResultsV3,
  }

  return isV2FeatureFlagEnabled ? payloadV2 : payloadV3
}
