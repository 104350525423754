import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { updateExperience } from 'api/experiences-v2/fetchers/updateExperience'

const useCustomMutation = createUseMutation({
  mutationFn: updateExperience,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateExperience = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
