import { LabelChip, LabelChipProps, theme } from '@ketch-com/deck'
import React, { useState } from 'react'
import { ViewInsightsButton, ViewInsightsButtonProps } from './ViewInsightsButton'
import { Box, Typography } from '@mui/material'

interface ViewInsightsProps extends ViewInsightsButtonProps {
  onClickCallback?: () => void
}

interface AssetsTabTableLabelChipProps extends LabelChipProps {
  onClickCallback?: () => void
  onMouseOverCallback?: () => void
  viewInsightsProps?: ViewInsightsProps
}

export const AssetsTabTableLabelChip: React.FC<AssetsTabTableLabelChipProps> = ({
  onClickCallback,
  onMouseOverCallback,
  closeTooltip,
  viewInsightsProps,
  ...rest
}) => {
  const [closeTooltipInternal, setCloseTooltipInternal] = useState(false)

  const handleOnClick = () => {
    setCloseTooltipInternal(true)
    if (onClickCallback) onClickCallback()
  }

  const handleViewInsightsClick = () => {
    setCloseTooltipInternal(true)
    if (viewInsightsProps?.onClickCallback) viewInsightsProps.onClickCallback()
  }

  const handleMouseOver = () => {
    if (closeTooltipInternal) setCloseTooltipInternal(false)
    if (onMouseOverCallback) onMouseOverCallback()
  }

  return (
    <LabelChip
      {...rest}
      closeTooltip={closeTooltip || closeTooltipInternal}
      onMouseOver={handleMouseOver}
      onClick={handleOnClick}
      customLabelDetailsContent={
        viewInsightsProps ? (
          <ViewInsightsButton {...viewInsightsProps} onClickCallback={handleViewInsightsClick} />
        ) : (
          <Box marginTop="6px">
            <Typography variant="body" color={theme.palette.Text.PrimaryLight}>
              See Overview tab for details of filtered asset.
            </Typography>
          </Box>
        )
      }
    />
  )
}
