import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { CanonicalPurposeDTO } from 'interfaces/purposes/canonicalPurpose'
import { PurposeDataRole } from 'interfaces/purposes/purposeDataRole'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
  filterDataRole?: PurposeDataRole
  includeDataSubjectTypes?: boolean
}>

type Response = APIListResponse<{ canonicalPurposes: CanonicalPurposeDTO[] }>

export const fetchCanonicalPurposes = ({
  includeMetadata,
  organizationCode,
  start,
  limit,
  filterDataRole,
  includeDataSubjectTypes,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/canonical-purposes`,
      params: {
        filters: {
          includeMetadata,
          start,
          limit,
          filterDataRole,
          includeDataSubjectTypes,
        },
      },
    }),
  )
