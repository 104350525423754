import { Icon, theme } from '@ketch-com/deck'
import { Box, Typography, styled } from '@mui/material'
import { PreferenceCenterPage } from '@ketch-sdk/ketch-types'
import { BuilderType } from './builder/utils/enums'

type Props = {
  builderType: BuilderType
  preferencePage?: PreferenceCenterPage
}

type BannerDetails = {
  title: string
  subtitle: string
}

const activeSectionBannerText: { [key: string]: BannerDetails } = {
  [PreferenceCenterPage.Purpose]: {
    title: 'The displayed set of Purposes is provided as an example.',
    subtitle: "To view your organization's Purposes for a given Jurisdiction, visit the Experience Preview page.",
  },
  [PreferenceCenterPage.Subscriptions]: {
    title: 'The displayed set of Subscriptions is provided as an example.',
    subtitle: "To view your organization's Subscriptions for a given Jurisdiction, visit the Experience Preview page.",
  },
  [PreferenceCenterPage.RequestsHome]: {
    title: 'The displayed rights are provided as an example.',
    subtitle: "To view your organization's rights for a given Jurisdiction, visit the Experience Preview page.",
  },
  [PreferenceCenterPage.RequestsForm]: {
    title: 'The displayed Form is provided as an example.',
    subtitle: "To view your organization's Forms for a given Jurisdiction, visit the Experience Preview page.",
  },
}

const builderTypeBannerText: { [key: string]: BannerDetails } = {
  [BuilderType.Modal]: {
    title: 'The displayed set of Purposes is provided as an example.',
    subtitle: "To view your organization's Purposes for a given Jurisdiction, visit the Experience Preview page.",
  },
}

const BackgroundBox = styled(Box)(({ theme }) => ({
  background: '#FFFFFF',
  width: '100%',
  borderRadius: '11px',
}))

const ExampleDataBannerBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  background: theme.palette.fadedHeliotrope.main,
  borderRadius: '11px',
  padding: '4px 16px',
  height: '48px',
}))

const LeftBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
})

// TODO:JB - Preview button descoped for now
// const RightBox = styled(Box)({})

const TextBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

const BannerText = styled(Typography)(({ theme }) => ({
  color: theme.palette.pink.main,
}))

export const UpsertExperienceExampleDataBanner: React.FC<Props> = ({ builderType, preferencePage }) => {
  const isModal = builderType === BuilderType.Modal
  const bannerDetails = isModal ? builderTypeBannerText[builderType] : activeSectionBannerText[preferencePage || '']
  const showBanner = isModal || (preferencePage && preferencePage in activeSectionBannerText)

  return showBanner ? (
    <BackgroundBox>
      <ExampleDataBannerBox>
        <LeftBox>
          <Icon name={'OInfo'} iconColor={theme.palette.pink.main} />
          <TextBox>
            <BannerText variant="label">{bannerDetails.title}</BannerText>
            <BannerText variant="body">{bannerDetails.subtitle}</BannerText>
          </TextBox>
        </LeftBox>
        {/* TODO:JB - Preview button descoped for now */}
        {/* <RightBox>
          {'|  '}
          <Typography>Preview</Typography>
        </RightBox> */}
      </ExampleDataBannerBox>
    </BackgroundBox>
  ) : null
}
