import { APIListRequestParams } from 'api/common/utils'
import { FetchAssetsPaginatedResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{}>

export const fetchAssetTablesPaginatedV3 = ({ limit, query, nextToken }: Params) => {
  const entityUrl = `/api/v3/tables`

  return API.get<FetchAssetsPaginatedResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          ...(query && {
            query,
          }),
          ...(limit && {
            limit,
          }),
          ...(nextToken && {
            nextToken,
          }),
        },
      },
    }),
  )
}
