import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Text } from 'components/ui-kit/typography/Text'
import { RiskScoreInfo } from 'pages/insights/utils'

type Suggestion = {
  icon: JSX.Element
  textContent: JSX.Element
}

type Props = {
  heading: string
  riskScoreDetails: RiskScoreInfo
  riskScore?: number
  headingType?: string
  headingTypeIcon?: JSX.Element
  actions?: JSX.Element
  mainContent?: JSX.Element
  whyContent?: JSX.Element
  suggestionContent?: Suggestion[]
  lowerContentRows?: JSX.Element[]
}

const PREFIX = 'DataRiskDetailLayout'

export const DataRiskDetailLayoutClasses = {
  chip: `${PREFIX}-chip`,
}

const LowerContentRowContainer = styled(Box)(({ theme: { spacing } }) => ({
  [`& .${DataRiskDetailLayoutClasses.chip}`]: {
    marginRight: spacing(1),
    marginBottom: spacing(1),
  },
}))

export const DataRiskDetailLayout: React.FC<Props> = ({
  heading,
  headingType,
  headingTypeIcon,
  actions,
  mainContent,
  riskScoreDetails,
  riskScore = 0,
  whyContent,
  suggestionContent,
  lowerContentRows,
}) => {
  // const [showHoverContent, setShowHoverContent] = useState(false)
  return (
    <Box p={3} display="flex" flexDirection="column" flex={1} bgcolor="white.main" borderRadius={3}>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Box flexBasis="65%">
          {(headingTypeIcon || headingType) && (
            <Box display="flex" gap={1} mb={1}>
              {headingTypeIcon}
              <Text weight={600}>{headingType}</Text>
            </Box>
          )}
          <Box>
            <Text weight={800} size={28}>
              {heading}
            </Text>
          </Box>
        </Box>
        <Box flexBasis="35%">
          <Box display="flex" gap={1} mt={-0.5} mr={-0.5} justifyContent="flex-end">
            {actions}
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Box flexBasis="568px">{mainContent}</Box>
        <Box flexBasis="211px">
          <Box display="flex" gap={0.75} mb={2}>
            <Text weight={600}>Current Risk Score</Text>
            {/* TODO: Hiding this until we are able to explain how the risk score is generated 🤷‍♂️
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ backgroundColor: 'fadedGrey.main', borderRadius: 18, height: 18, width: 18 }}
            >
              <Text
                weight={600}
                onMouseEnter={() => setShowHoverContent(true)}
                onMouseLeave={() => setShowHoverContent(false)}
                sx={{ p: 1.25 }}
              >
                ?
              </Text>
              {showHoverContent ? (
                <Box
                  position="absolute"
                  display="flex"
                  top={220}
                  right={130}
                  width={220}
                  borderRadius={2}
                  sx={({ palette, spacing }) => ({
                    backgroundColor: palette.darkDusk.main,
                    padding: spacing(1.25, 1.75),
                  })}
                >
                  <Text size={12} weight={600} color="white" lineHeight="18px">
                    Details content pending
                  </Text>
                </Box>
              ) : null}
            </Box> */}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{ backgroundColor: riskScoreDetails.bgColor, borderRadius: 112, height: 112, width: 112 }}
            mb={2}
          >
            <Text color={riskScoreDetails.textColorName} size={48} weight={500} letterSpacing="-1px">
              {riskScore}
            </Text>
            <Text color={riskScoreDetails.textColorName} weight={600} letterSpacing="-0.25px">
              {riskScoreDetails.category}
            </Text>
          </Box>
          <Text component="div" size={12} color={riskScoreDetails.textColorName} weight={600} lineHeight="17px">
            {riskScoreDetails.flavorText}
          </Text>
        </Box>
      </Box>
      <Box display="flex" mb={4} gap={1.5} ml={3} mr={3}>
        <Box flexBasis="35%" pr={5}>
          <Text component="div" weight={700} size={16} mb={2.75}>
            Why?
          </Text>
          {whyContent}
        </Box>
        <Box flexBasis="65%">
          <Text component="div" weight={700} size={16} mb={2.75}>
            Suggestions
          </Text>
          {suggestionContent?.map((suggestion, index) => (
            <Box display="flex" gap={2.5} mb={3} key={index}>
              <Box pt={0.5}>{suggestion.icon}</Box>
              <Box flexGrow={1}>{suggestion.textContent}</Box>
            </Box>
          ))}
        </Box>
      </Box>
      <LowerContentRowContainer display="flex" gap={1.5}>
        {lowerContentRows?.map(row => row)}
      </LowerContentRowContainer>
    </Box>
  )
}
