import {
  BannerThemeConfig,
  BannerContainerPosition,
  BannerContainerLayout,
  BannerContainerSize,
  ItemStyle,
  ModalThemeConfig,
  ModalContainerPosition,
  PreferenceThemeConfig,
  ExitButtonPosition,
  SubscriptionListStyle,
  FormFieldStyle,
  ThemeConfig,
  ListLayout,
} from '@ketch-sdk/ketch-types'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { ThemeStatus } from 'interfaces/themes-v3/themeStatus'

enum Colors {
  DEFAULT_BLACK = '#000000',
  DEFAULT_WHITE = '#FFFFFF',
  DEFAULT_GREY = '#EBEDED',
  DEFAULT_GREY_TWO = '#C1C6C8',
  DEFAULT_GREY_THREE = '#69757B',
  DEFAULT_GREY_FOUR = '#707070',
  DEFAULT_GREY_FIVE = '#C2C2C2',
  DEFAULT_GREY_SIX = '#E6E8E9',
  DEFAULT_GREY_SEVEN = '#F6F6F6',
  DEFAULT_RED = '#F00000',
}

const defaultBannerThemeConfig: BannerThemeConfig = {
  container: {
    backdrop: {
      visible: true,
      background: {
        color: Colors.DEFAULT_BLACK,
        opacity: 24,
      },
      disableContentInteractions: true,
    },
    background: {
      color: Colors.DEFAULT_WHITE,
      opacity: 1,
    },
    cornerRadius: 0,
    position: BannerContainerPosition.Bottom,
    layout: BannerContainerLayout.Horizontal,
    size: BannerContainerSize.Standard,
    font: '',
  },
  header: {
    title: {
      color: Colors.DEFAULT_BLACK,
      opacity: 1,
    },
    returnButton: {
      background: {
        color: Colors.DEFAULT_GREY,
        opacity: 1,
      },
      icon: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      cornerRadius: 0,
    },
  },
  description: {
    link: {
      color: Colors.DEFAULT_BLACK,
      opacity: 1,
      underline: true,
    },
    text: {
      color: Colors.DEFAULT_BLACK,
      opacity: 1,
    },
  },
  buttons: {
    primary: {
      style: ItemStyle.Filled,
      background: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      text: {
        color: Colors.DEFAULT_WHITE,
        opacity: 1,
      },
      cornerRadius: 0,
      outline: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
    },
    secondary: {
      style: ItemStyle.Outlined,
      background: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      text: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      cornerRadius: 0,
      outline: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
    },
    tertiary: {
      style: ItemStyle.Outlined,
      background: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      text: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      cornerRadius: 0,
      outline: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
    },
  },
  footer: {
    ketchBadgeVisible: true,
  },
}

const defaultModalThemeConfig: ModalThemeConfig = {
  container: {
    position: ModalContainerPosition.Center,
    font: '',
    background: {
      color: Colors.DEFAULT_WHITE,
      opacity: 1,
    },
    cornerRadius: 0,
    backdrop: {
      visible: true,
      background: {
        color: Colors.DEFAULT_BLACK,
        opacity: 24,
      },
      disableContentInteractions: true,
    },
  },
  header: {
    background: {
      color: Colors.DEFAULT_GREY_SEVEN,
      opacity: 1,
    },
    title: {
      color: Colors.DEFAULT_BLACK,
      opacity: 1,
      underline: false,
    },
    returnButton: {
      background: {
        color: Colors.DEFAULT_GREY,
        opacity: 1,
      },
      icon: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      cornerRadius: 0,
    },
  },
  description: {
    title: {
      color: Colors.DEFAULT_BLACK,
      opacity: 1,
      underline: false,
    },
    text: {
      color: Colors.DEFAULT_BLACK,
      opacity: 1,
      underline: false,
    },
    link: {
      color: Colors.DEFAULT_BLACK,
      opacity: 1,
      underline: true,
    },
  },
  purposeListHeader: {
    title: {
      color: Colors.DEFAULT_BLACK,
      opacity: 1,
      underline: false,
    },
    acceptAllButton: {
      style: ItemStyle.Filled,
      background: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      text: {
        color: Colors.DEFAULT_WHITE,
        opacity: 1,
        underline: false,
      },
      outline: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      // NA
      icon: {
        color: '',
        opacity: 1,
      },
      cornerRadius: 0,
    },
    rejectAllButton: {
      style: ItemStyle.Filled,
      background: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      text: {
        color: Colors.DEFAULT_WHITE,
        opacity: 1,
        underline: false,
      },
      outline: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      // NA
      icon: {
        color: '',
        opacity: 1,
      },
      cornerRadius: 0,
    },
  },
  purposeList: {
    purposeListItems: {
      layout: ListLayout.Expandable,
      style: ItemStyle.Filled,
      purposeFill: {
        color: Colors.DEFAULT_GREY,
        opacity: 1,
      },
      purposeContent: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      purposeLinks: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      arrowIcon: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      purposeOutline: {
        color: Colors.DEFAULT_GREY,
        opacity: 1,
      },
      purposeCornerRadius: 0,
    },
    switchButtons: {
      on: {
        background: {
          color: Colors.DEFAULT_BLACK,
          opacity: 1,
        },
        text: {
          color: Colors.DEFAULT_BLACK,
          opacity: 1,
          // NA
          underline: false,
        },
      },
      off: {
        background: {
          color: Colors.DEFAULT_GREY_TWO,
          opacity: 1,
        },
        text: {
          color: Colors.DEFAULT_BLACK,
          opacity: 1,
          underline: false,
        },
      },
    },
  },
  footer: {
    background: {
      color: Colors.DEFAULT_WHITE,
      opacity: 1,
    },
    actionButton: {
      style: ItemStyle.Filled,
      background: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      text: {
        color: Colors.DEFAULT_WHITE,
        opacity: 1,
        underline: false,
      },
      // NA
      icon: {
        color: '',
        opacity: 1,
      },
      outline: {
        color: Colors.DEFAULT_BLACK,
        opacity: 1,
      },
      // NA
      cornerRadius: 0,
    },
    ketchBadgeVisible: true,
  },
}

const defaultPreferenceThemeConfig: PreferenceThemeConfig = {
  container: {
    font: '',
    background: { color: Colors.DEFAULT_WHITE, opacity: 1 },
    exitPosition: ExitButtonPosition.bottomLeft,
  },
  header: {
    background: { color: Colors.DEFAULT_GREY_SEVEN, opacity: 1 },
    title: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
    logo: { name: '', url: '' },
  },
  navigation: {
    layout: {
      background: { color: Colors.DEFAULT_GREY_SIX, opacity: 1 },
      cornerRadius: 0,
      item: {
        unselectedBackground: { color: Colors.DEFAULT_GREY_SIX, opacity: 1 },
        unselectedText: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
        selectedBackground: { color: Colors.DEFAULT_BLACK, opacity: 1 },
        selectedText: { color: Colors.DEFAULT_WHITE, opacity: 1, underline: false },
        cornerRadius: 0,
      },
    },
    iconsVisible: true,
    icons: {
      welcome: { name: '', url: '' },
      privacyPolicy: { name: '', url: '' },
      purposes: { name: '', url: '' },
      subscriptions: { name: '', url: '' },
      requests: { name: '', url: '' },
    },
  },
  exitButton: {
    background: { color: Colors.DEFAULT_GREY_SIX, opacity: 1 },
    text: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
    iconVisible: true,
    useDefaultIcon: true,
    iconColor: { color: Colors.DEFAULT_BLACK, opacity: 1 },
    icon: { name: '', url: '' },
    cornerRadius: 0,
  },
  ketchBadgeVisible: true,
  tabs: {
    welcome: {
      heroBanner: {
        visible: true,
        image: { name: '', url: '' },
      },
      welcomeMsg: {
        title: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
        subtitle: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
        link: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: true },
      },
      quickLinks: {
        title: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
        link: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
        showArrows: true,
      },
      about: {
        title: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
        text: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
        link: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: true },
      },
    },
    privacyPolicy: {
      text: { color: '', opacity: 1, underline: false },
      link: { color: '', opacity: 1, underline: false },
    },
    purposes: {
      header: {
        title: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
        description: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
        link: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: true },
      },
      purposeListHeader: {
        title: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
        acceptAllButton: {
          style: ItemStyle.Filled,
          background: { color: Colors.DEFAULT_BLACK, opacity: 1 },
          text: { color: Colors.DEFAULT_WHITE, opacity: 1, underline: false },
          icon: { color: '', opacity: 1 },
          outline: {
            color: Colors.DEFAULT_BLACK,
            opacity: 1,
          },
          cornerRadius: 0,
        },
        rejectAllButton: {
          style: ItemStyle.Filled,
          background: { color: Colors.DEFAULT_BLACK, opacity: 1 },
          text: { color: Colors.DEFAULT_WHITE, opacity: 1, underline: false },
          icon: { color: '', opacity: 1 },
          outline: {
            color: Colors.DEFAULT_BLACK,
            opacity: 1,
          },
          cornerRadius: 0,
        },
      },
      purposeList: {
        purposeListItems: {
          layout: ListLayout.Expandable,
          style: ItemStyle.Filled,
          purposeFill: { color: Colors.DEFAULT_GREY, opacity: 1 },
          purposeContent: { color: Colors.DEFAULT_BLACK, opacity: 1 },
          purposeLinks: { color: Colors.DEFAULT_BLACK, opacity: 1 },
          purposeOutline: {
            color: Colors.DEFAULT_GREY,
            opacity: 1,
          },
          arrowIcon: { color: Colors.DEFAULT_BLACK, opacity: 1 },
          purposeCornerRadius: 0,
        },
        switchButtons: {
          on: {
            background: { color: Colors.DEFAULT_BLACK, opacity: 1 },
            text: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          },
          off: {
            background: { color: Colors.DEFAULT_GREY_TWO, opacity: 1 },
            text: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          },
        },
      },
      footer: {
        background: { color: Colors.DEFAULT_WHITE, opacity: 1 },
        actionButton: {
          style: ItemStyle.Filled,
          background: { color: Colors.DEFAULT_BLACK, opacity: 1 },
          text: { color: Colors.DEFAULT_WHITE, opacity: 1, underline: false },
          icon: { color: '', opacity: 1 },
          outline: {
            color: Colors.DEFAULT_BLACK,
            opacity: 1,
          },
          cornerRadius: 0,
        },
      },
    },
    subscriptions: {
      header: {
        title: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
        description: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
        link: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: true },
      },
      unsubscribeAll: {
        background: { color: Colors.DEFAULT_GREY, opacity: 1 },
        text: { color: Colors.DEFAULT_BLACK, opacity: 1 },
        cornerRadius: 0,
        switchButton: {
          on: {
            background: { color: Colors.DEFAULT_BLACK, opacity: 1 },
            text: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          },
          off: {
            background: { color: Colors.DEFAULT_GREY_THREE, opacity: 1 },
            text: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          },
        },
      },
      list: {
        layout: {
          style: SubscriptionListStyle.Filled,
          background: { color: Colors.DEFAULT_GREY, opacity: 1 },
          text: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          link: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          cornerRadius: 0,
        },
        switchButton: {
          on: {
            background: { color: Colors.DEFAULT_BLACK, opacity: 1 },
            text: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          },
          off: {
            background: { color: Colors.DEFAULT_GREY_TWO, opacity: 1 },
            text: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          },
        },
        checkbox: {
          selected: {
            background: { color: Colors.DEFAULT_BLACK, opacity: 1 },
            label: { color: Colors.DEFAULT_BLACK, opacity: 1 },
          },
          unselected: {
            background: { color: Colors.DEFAULT_GREY_TWO, opacity: 1 },
            label: { color: Colors.DEFAULT_BLACK, opacity: 1 },
          },
        },
      },
      footer: {
        background: { color: Colors.DEFAULT_WHITE, opacity: 1 },
        actionButton: {
          style: ItemStyle.Filled,
          background: { color: Colors.DEFAULT_BLACK, opacity: 1 },
          text: { color: Colors.DEFAULT_WHITE, opacity: 1, underline: false },
          icon: { color: '', opacity: 1 },
          outline: {
            color: Colors.DEFAULT_BLACK,
            opacity: 1,
          },
          cornerRadius: 0,
        },
      },
    },
    requests: {
      home: {
        header: {
          title: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          description: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          link: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: true },
        },
        dsrPortalLink: {
          title: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          description: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          arrowIcon: { color: Colors.DEFAULT_BLACK, opacity: 1 },
          background: { color: Colors.DEFAULT_GREY, opacity: 1 },
          cornerRadius: 0,
        },
        rightsList: {
          title: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          item: {
            title: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
            arrowIcon: { color: Colors.DEFAULT_BLACK, opacity: 1 },
            background: { color: Colors.DEFAULT_GREY, opacity: 1 },
            cornerRadius: 0,
          },
        },
      },
      rightForm: {
        header: {
          title: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          returnButton: {
            background: { color: Colors.DEFAULT_GREY, opacity: 1 },
            icon: { color: Colors.DEFAULT_BLACK, opacity: 1 },
            cornerRadius: 0,
          },
        },
        form: {
          dividers: {
            title: { color: Colors.DEFAULT_GREY_FOUR, opacity: 1, underline: false },
            subtitle: { color: Colors.DEFAULT_GREY_FOUR, opacity: 1, underline: false },
          },
          fields: {
            style: FormFieldStyle.Box,
            active: {
              background: { color: Colors.DEFAULT_WHITE, opacity: 1 },
              outline: { color: Colors.DEFAULT_BLACK, opacity: 1 },
            },
            inactive: {
              background: { color: Colors.DEFAULT_WHITE, opacity: 1 },
              outline: { color: Colors.DEFAULT_GREY_FIVE, opacity: 1 },
            },
            error: {
              background: { color: Colors.DEFAULT_WHITE, opacity: 1 },
              outline: { color: Colors.DEFAULT_RED, opacity: 1 },
            },
            cornerRadius: 0,
            fieldLabel: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
            hintText: { color: Colors.DEFAULT_GREY_FIVE, opacity: 1, underline: false },
            inputText: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          },
          checkboxes: {
            selected: {
              background: { color: Colors.DEFAULT_BLACK, opacity: 1 },
              label: { color: Colors.DEFAULT_BLACK, opacity: 1 },
            },
            unselected: {
              background: { color: Colors.DEFAULT_GREY_TWO, opacity: 1 },
              label: { color: Colors.DEFAULT_BLACK, opacity: 1 },
            },
            error: {
              background: { color: Colors.DEFAULT_RED, opacity: 1 },
              label: { color: Colors.DEFAULT_RED, opacity: 1 },
            },
          },
        },
        actionButton: {
          style: ItemStyle.Filled,
          background: { color: Colors.DEFAULT_BLACK, opacity: 1 },
          text: { color: Colors.DEFAULT_WHITE, opacity: 1, underline: false },
          icon: { color: '', opacity: 1 },
          outline: {
            color: Colors.DEFAULT_BLACK,
            opacity: 1,
          },
          cornerRadius: 0,
        },
      },
      submitted: {
        header: {
          title: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          returnButton: {
            background: { color: Colors.DEFAULT_GREY, opacity: 1 },
            icon: { color: Colors.DEFAULT_BLACK, opacity: 1 },
            cornerRadius: 0,
          },
        },
        banner: {
          visible: true,
          image: { name: '', url: '' },
        },
        text: {
          title: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
          description: { color: Colors.DEFAULT_BLACK, opacity: 1, underline: false },
        },
        footer: {
          background: { color: Colors.DEFAULT_WHITE, opacity: 1 },
          actionButton: {
            style: ItemStyle.Filled,
            background: { color: Colors.DEFAULT_BLACK, opacity: 1 },
            outline: {
              color: Colors.DEFAULT_BLACK,
              opacity: 1,
            },
            text: { color: Colors.DEFAULT_WHITE, opacity: 1 },
            cornerRadius: 0,
          },
        },
      },
    },
  },
}

export const defaultThemeConfig: ThemeConfig = {
  banner: defaultBannerThemeConfig,
  modal: defaultModalThemeConfig,
  preference: defaultPreferenceThemeConfig,
}

export const defaultThemeV3DTO: ThemeV3DTO = {
  name: '',
  status: ThemeStatus.Undeployed,
  content: defaultThemeConfig,
}
