import React, { useContext, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { Button } from '@ketch-com/deck'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { BillingDetails } from 'components/planAndBilling/components/billingDetails/BillingDetails'
import { PlanDetails } from 'components/planAndBilling/components/planDetails/PlanDetails'
import { UniqueUsers } from 'components/planAndBilling/components/uniqueUsers/UniqueUsers'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import { Spinner } from '@ketch-com/deck'
import {
  StripeRedirectURL,
  StripeInvokeAction,
  StripeInvokeActionResponse,
  OrgPlanIDs,
} from 'components/planAndBilling/common/constants/planAndBilling'
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import { BillingStatus } from '@ketch-com/harbormaster/dist/organization/plans.schemas'
import { showToast } from 'components/modals/AlertComponent'

const breadcrumbs = [
  {
    title: 'Settings',
    link: RoutesManager.settings.root.getURL(),
  },
  { title: 'Plan & Billing', link: RoutesManager.settings.planAndBilling.root.getURL() },
]

export const PlanAndBilling = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { isReady, createBillingPortal, orgPlan } = useContext(PlanAndBillingContext)

  useEffect(() => {
    const plgAction = searchParams.get('plg_action')
    const plgStatus = searchParams.get('plg_status')

    if (plgAction === StripeInvokeAction.PlanUpgrade) {
      if (plgStatus === StripeInvokeActionResponse.Success && orgPlan?.planProductID === OrgPlanIDs.starter) {
        showToast({ content: 'Plan upgraded', type: 'success' })
      }
      if (plgStatus === StripeInvokeActionResponse.Cancel && orgPlan?.planProductID === OrgPlanIDs.free) {
        showToast({ content: 'Unable to upgrade plan', type: 'error' })
      }
    }

    if (plgAction === StripeInvokeAction.PlanDowngrade) {
      if (plgStatus === StripeInvokeActionResponse.Success && orgPlan?.planProductID === OrgPlanIDs.free) {
        showToast({ content: 'Plan downgraded', type: 'success' })
      }
      if (plgStatus === StripeInvokeActionResponse.Cancel && orgPlan?.planProductID === OrgPlanIDs.starter) {
        showToast({ content: 'Unable to downgrade plan', type: 'error' })
      }
    }

    if (plgAction === StripeInvokeAction.BillingFailed) {
      if (orgPlan?.billingStatus === BillingStatus.ok) {
        showToast({ content: 'Billing updated', type: 'success' })
      }
      if (orgPlan?.billingStatus === BillingStatus.in_grace_period) {
        showToast({ content: 'Unable update billing', type: 'error' })
      }
    }

    if (plgAction === StripeInvokeAction.BillingMissed) {
      if (orgPlan?.billingStatus === BillingStatus.ok) {
        showToast({ content: 'Billing updated', type: 'success' })
      }
      if (orgPlan?.billingStatus === BillingStatus.missed) {
        showToast({ content: 'Unable update billing', type: 'error' })
      }
    }

    if (location.search)
      setTimeout(() => {
        navigate(RoutesManager.settings.planAndBilling.root.getURL())
      }, 4000)
  }, [location.search, searchParams, navigate, orgPlan?.billingStatus, orgPlan])

  if (!isReady)
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" p={5}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h2">Plan & Billing</Typography>
        <Button
          size="medium"
          color="secondary"
          onClick={() => {
            createBillingPortal({
              params: {
                reqBody: {
                  returnURL: (() => {
                    const url = new URL(StripeRedirectURL)
                    url.searchParams.set('action', StripeInvokeAction.BillingManage)
                    return url.toString()
                  })(),
                },
              },
            })
          }}
        >
          Manage Billing Information
        </Button>
      </Box>
      <Box mb={4}>
        <BillingDetails />
      </Box>
      <Box mb={2}>
        <UniqueUsers />
      </Box>

      <PlanDetails />
    </>
  )
}
