import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { IconDictionary } from '@ketch-com/deck'

export const getWorkflowExecutionStepStatusIcon = (
  status: WorkflowExecutionStepStatus,
  isOverdue: boolean,
): keyof typeof IconDictionary => {
  if (isOverdue) return IconDictionary.FClock

  switch (status) {
    case WorkflowExecutionStepStatus.PENDING:
      return IconDictionary.FEyeShow
    case WorkflowExecutionStepStatus.FAIL:
      return IconDictionary.FImportant
    case WorkflowExecutionStepStatus.INVALID_CREDENTIALS:
      return IconDictionary.OClock
    case WorkflowExecutionStepStatus.IN_PROGRESS:
      return IconDictionary.FPointer
    case WorkflowExecutionStepStatus.SUCCESS:
      return IconDictionary.FCheckRound
    case WorkflowExecutionStepStatus.NOT_STARTED:
      return IconDictionary.OArrowWall
    case WorkflowExecutionStepStatus.SKIPPED:
      return IconDictionary.OCross
    case WorkflowExecutionStepStatus.MIXED:
      return IconDictionary.FStatusMultiple
    default:
      return IconDictionary.OClock
  }
}
