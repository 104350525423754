import { StatusVariant } from 'components/ui-kit/status/Status'

export enum DatasetExecutionStatusEnum {
  SUCCESS = 1,
  FAILURE = 2,
  SKIPPED = 3,
}

export const DATASET_EXECUTION_STATUS_NAMES_MAP: {
  [key: number]: string
} = {
  0: 'unspecified',
  [DatasetExecutionStatusEnum.SUCCESS]: 'success',
  [DatasetExecutionStatusEnum.FAILURE]: 'failure',
  [DatasetExecutionStatusEnum.SKIPPED]: 'skipped',
}

export const DATASET_EXECUTION_STATUS_VARIANT_MAP: {
  [key: number]: StatusVariant
} = {
  0: 'error',
  [DatasetExecutionStatusEnum.SUCCESS]: 'active',
  [DatasetExecutionStatusEnum.FAILURE]: 'error',
  [DatasetExecutionStatusEnum.SKIPPED]: 'in-progress',
}
