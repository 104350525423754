import { SidebarSection } from 'components/appLayout/appNavigation/components/navigationSidebar/utils'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import React from 'react'

export enum SidebarSubRouteSection {
  Risk = 'Risk',
  Foundation = 'Foundations',
  Capture = 'Capture',
  Orchestration = 'Orchestration',
  Systems = 'Systems',
  Reporting = 'Reporting',
}

export interface SidebarRouteItem {
  title: string
  path: string
  sectionId?: SidebarSection
  description?: string
  unselectedIcon?: React.ReactElement
  selectedIcon?: React.ReactElement
  permissions?: PERMISSIONS[]
  entitlements?: ENTITLEMENTS[]
  requireAllPermissions?: boolean
  requireAllEntitlements?: boolean
  subRoutes?: SidebarRouteItem[] | { [subRouteSection: string]: SidebarRouteItem[] }
}
