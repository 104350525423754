export enum ThemeBannerPosition {
  BOTTOM = 1,
  TOP = 2,
  BOTTOM_LEFT = 3,
  BOTTOM_RIGHT = 4,
}

export const THEME_BANNER_POSITION_NAME = {
  [ThemeBannerPosition.BOTTOM]: 'Bottom',
  [ThemeBannerPosition.TOP]: 'Top',
  [ThemeBannerPosition.BOTTOM_LEFT]: 'Bottom Left',
  [ThemeBannerPosition.BOTTOM_RIGHT]: 'Bottom Right',
}

export const THEME_BANNER_POSITION_OPTIONS = [
  {
    id: ThemeBannerPosition.BOTTOM,
    name: THEME_BANNER_POSITION_NAME[ThemeBannerPosition.BOTTOM],
  },
  {
    id: ThemeBannerPosition.TOP,
    name: THEME_BANNER_POSITION_NAME[ThemeBannerPosition.TOP],
  },
  {
    id: ThemeBannerPosition.BOTTOM_LEFT,
    name: THEME_BANNER_POSITION_NAME[ThemeBannerPosition.BOTTOM_LEFT],
  },
  {
    id: ThemeBannerPosition.BOTTOM_RIGHT,
    name: THEME_BANNER_POSITION_NAME[ThemeBannerPosition.BOTTOM_RIGHT],
  },
]
