import { WorkflowDefinitionStepSummaryDTO } from '@ketch-com/figurehead'
import { ApplicationIdentitySpace } from 'interfaces/applications/ApplicationFormValues'
import { MetaData } from 'interfaces/common'

export type IdentitySpaceMapDTO = {
  sourceIdentitySpaceCode: string
  destinationIdentitySpaceCode: string
  organizationID: string
  organizationCode: string
  ttl?: string
}

export type IdentitySpaceDTO = {
  ID: string
  name: string
  code: string
  description?: string
  organizationID: string
  typeCode?: string
  formatCode?: string
  latest?: boolean
  managed?: boolean
  marketplaceAppID?: string
  metadata?: MetaData
  hasIssues?: boolean
  sourceIdentitySpaceMaps?: IdentitySpaceMapDTO[]
  destinationIdentitySpaceMaps?: IdentitySpaceMapDTO[]
  version?: string
  isAuthentication?: boolean
  verifierID?: string
  IsMarketingPreferences?: boolean
  applicationIdentitySpaces?: ApplicationIdentitySpace[]
  activities?: WorkflowDefinitionStepSummaryDTO[]
}

export enum IdentitySpaceMappingDirection {
  RECEIVE = 'RECEIVE',
  PUSH = 'PUSH',
}

export type IdentitySpacesCatalogDTO = {
  formats: {
    code: string
    name: string
  }[]
  types: {
    code: string
    name: string
    class: number
  }[]
}

export interface IdentitySpaceFormDTO {
  name: string
  code: string
  destinationIdentitySpaceMaps?: {
    sourceIdentitySpaceCode: string
  }[]
  sourceIdentitySpaceMaps?: {
    destinationIdentitySpaceCode: string
  }[]
  formatCode: string
  marketplaceAppID?: string
  typeCode: string
  version?: number
  isAuthentication?: boolean
  verifierID?: string
  IsMarketingPreferences?: boolean
}
