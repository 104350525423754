import { useRelatedAssetsPaginatedV2 } from 'api/assets/queries/useRelatedAssetsPaginatedV2'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { ASSET_TYPE_ENUM } from 'interfaces/assets'
import React from 'react'

type Props = {
  code: string
}

export const AttributesCellRenderer: React.FC<Props> = ({ code }) => {
  const {
    // pagination,
    data: relatedAttributes,
    isLoading: isLoadingRelatedAttributes,
  } = useRelatedAssetsPaginatedV2({
    enabled: !!code,
    itemsPerPage: 100,
    params: {
      canonicalResourceType: ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_ATTRIBUTE,
      assetCode: code!,
    },
  })

  if (isLoadingRelatedAttributes) {
    return null
  }

  return (relatedAttributes || [])?.length > 0 ? (
    <TextCellRenderer value={(relatedAttributes || [])?.length} />
  ) : (
    <EmptyValueRenderer />
  )
}
