import React, { useContext } from 'react'
import Box from '@mui/material/Box'

import { PreferenceField, fieldNames } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { InterpolatedTextArea } from 'pages/consentAndRights/experiences/upsert/components/common/interpolatedTextArea/InterpolatedTextArea'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import { useFormikContext } from 'formik'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import Typography from '@mui/material/Typography'

type Props = {}

export const Rights: React.FC<Props> = () => {
  const { activeSidebarTab, sidebarActiveTab, organizationLanguages } = useContext(ExperienceUpsertContext)
  const { values } = useFormikContext<PreferenceExperienceFormValues>()
  const { rights: rightsFormikValues } = values

  switch (sidebarActiveTab) {
    case PreferenceField.RIGHTS_TAB_NAME:
      return (
        <>
          <Typography variant="h4" color="fadedDarkGrey.main" mb={2.5}>
            Translations
          </Typography>

          {organizationLanguages.map(({ language }) => (
            <FormRow key={language.code} marginBottom={24}>
              <FormInput
                fullWidth
                required={false}
                name={fieldNames.preference.rights.tabName.translation(language.code)}
                label={`Display Text (${language.englishName})`}
                placeholder="Add translation"
              />
            </FormRow>
          ))}
        </>
      )
    case PreferenceField.RIGHTS_BODY_TITLE:
      return (
        <>
          <Typography variant="h4" color="fadedDarkGrey.main" mb={2.5}>
            Translations
          </Typography>

          {organizationLanguages.map(({ language }) => (
            <FormRow key={language.code} marginBottom={24}>
              <FormInput
                fullWidth
                required={false}
                name={fieldNames.preference.rights.bodyTitle.translation(language.code)}
                label={`Display Text (${language.englishName})`}
                placeholder="Add translation"
              />
            </FormRow>
          ))}
        </>
      )
    case PreferenceField.RIGHTS_BODY_DESCRIPTION:
      return (
        <>
          <Typography variant="h4" color="fadedDarkGrey.main" mb={2.5}>
            Translations
          </Typography>

          {organizationLanguages.map(({ language }) => (
            <FormRow key={language.code} marginBottom={24}>
              <InterpolatedTextArea
                required={false}
                name={fieldNames.preference.rights.bodyDescription.translation(language.code)}
                label={`Display Text (${language.englishName})`}
                placeholder="Add translation"
              />
            </FormRow>
          ))}
        </>
      )
    case PreferenceField.RIGHTS_FOOTER_BUTTONS:
      return (
        <>
          <Typography variant="h4" color="fadedDarkGrey.main" mb={2.5}>
            Translations
          </Typography>

          {organizationLanguages.map(({ language }) => (
            <FormRow key={language.code} marginBottom={24}>
              <FormInput
                fullWidth
                required={false}
                name={fieldNames.preference.rights.buttonText.translation(language.code)}
                label={`Submit Button Label (${language.englishName})`}
                placeholder="Add translation"
              />
            </FormRow>
          ))}
        </>
      )
    case PreferenceField.RIGHTS_REQUEST: {
      const showRequestDetailsTitleTranslation =
        activeSidebarTab === 'title' &&
        rightsFormikValues.showRequestDetailsTitle &&
        rightsFormikValues.requestTitleType === 'custom'
      return (
        <Box display={showRequestDetailsTitleTranslation ? 'flex' : 'none'} flexDirection="column">
          <Typography variant="h4" color="fadedDarkGrey.main" mb={2.5}>
            Translations
          </Typography>

          {organizationLanguages.map(({ language }) => (
            <Box key={language.code} mb={3}>
              <FormInput
                fullWidth
                required={false}
                name={fieldNames.preference.rights.requestDetailsTitle.translation(language.code)}
                label={`Display Text (${language.englishName})`}
                placeholder="Add translation"
              />
            </Box>
          ))}
        </Box>
      )
    }
    case PreferenceField.RIGHTS_FORM_SETTINGS: {
      const showPersonalDetailsTitleTranslation =
        activeSidebarTab === 'title' &&
        rightsFormikValues.showPersonalDetailsTitle &&
        rightsFormikValues.personalDetailsTitleType === 'custom'
      return (
        <Box display={showPersonalDetailsTitleTranslation ? 'flex' : 'none'} flexDirection="column">
          <Typography variant="h4" color="fadedDarkGrey.main" mb={2.5}>
            Translations
          </Typography>

          {organizationLanguages.map(({ language }) => (
            <Box key={language.code} mb={3}>
              <FormInput
                fullWidth
                required={false}
                name={fieldNames.preference.rights.personalDetailsTitle.translation(language.code)}
                label={`Display Text (${language.englishName})`}
                placeholder="Add translation"
              />
            </Box>
          ))}
        </Box>
      )
    }

    default:
      return null
  }
}
