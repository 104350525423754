import { Box, Typography, styled } from '@mui/material'
import { getIn } from 'formik'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import React, { useContext } from 'react'

type BuilderFormSectionHeadingProps = {
  title: string
  subtitle: string
}

const SectionHeadingBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

const Title = styled(Typography)({
  fontSize: 18,
  fontWeight: 600,
})

const Subtitle = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
})

export const BuilderFormSectionHeading: React.FC<BuilderFormSectionHeadingProps> = ({ title, subtitle }) => {
  const { themeConfig } = useContext(BuilderContext)

  // Theme config
  const titleColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormDividersTitleColor)
  const subtitleColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabFormDividersSubtitleColor)

  return (
    <SectionHeadingBox>
      <Title color={titleColor}>{title}</Title>
      <Subtitle color={subtitleColor}>{subtitle}</Subtitle>
    </SectionHeadingBox>
  )
}
