import React, { useContext, useState } from 'react'

import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import Box from '@mui/material/Box'
import { Button, PopUp } from '@ketch-com/deck'
import { useUserMe } from 'api/users/queries/useUserMe'
import { useUsersCurrentTimezone } from 'utils/hooks'
import { RightsQueueViewContext } from '../../context/RightsQueueViewContext'
import Typography from '@mui/material/Typography'
import { FinalizeRequestModal, ConfirmDeleteDSRModal, WorkflowExecutionCompleteModalContainer } from '..'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { ExtendRequestModal } from '../ExtendRequestModal'

type Props = {}

export const RightsQueueViewActionsV2: React.FC<Props> = () => {
  const { isKetchFree, forceCompleteRightInvocation, isForceCompleteUpdating, workflowExecution, rightInvocation } =
    useContext(RightsQueueViewContext)

  const [isCompleteRequestOpen, setIsCompleteRequestOpen] = useState(false)
  const [isSoftDeleteDSRModalOpen, setIsSoftDeleteDSRModalOpen] = useState(false)
  const [isForceCompleteDialogOpen, setIsForceCompleteDialogOpen] = useState(false)
  const [isFinalizeModalOpen, setIsFinalizeModalOpen] = useState(false)
  const [isExtendModalOpen, setIsExtendModalOpen] = useState(false)
  const { isEntitled } = useIsEntitled()
  const timeZoneShortFormat = useUsersCurrentTimezone()

  const { data: userMe } = useUserMe({
    params: {
      includeMetadata: true,
    },
  })
  const isRightsQueueAdmin = userMe?.roles?.find(role => role.code === 'queue_admin')

  const steps = workflowExecution?.steps || []

  const isBasicApplicationUser = isEntitled(ENTITLEMENTS.PROCESSOR_BASIC)
  const isCompleted = !!rightInvocation?.finalization?.finalizedAt
  const stepToCompleteRequest = steps.find(({ stepID }) => stepID === 'Level1Integration')

  const shouldShowCompleteRequestButton = isBasicApplicationUser && !isCompleted && !!stepToCompleteRequest

  const handleForceCompleteSubmitClick = async () => {
    const reason = 'Ketch free forced completion of right invocation'
    await forceCompleteRightInvocation({
      params: {
        requestId: rightInvocation?.id || '',
        formData: { reason },
      },
      onSuccess: () => {
        setIsForceCompleteDialogOpen(false)
      },
    })
  }

  return (
    <>
      <Box display="flex" alignItems="center" gap="12px">
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="smallBody" color="darkDuskFaded.main">
            Last Updated
          </Typography>
          {rightInvocation?.metadata?.updatedAt ? (
            <Typography color="darkDusk.main" variant="smallBody">
              {formatDateTimeFromUnix(rightInvocation?.metadata?.updatedAt)} {timeZoneShortFormat}
            </Typography>
          ) : (
            <EmptyValueRenderer />
          )}
        </Box>

        {isKetchFree && !isCompleted && (
          <Button size="large" onClick={() => setIsForceCompleteDialogOpen(true)}>
            Complete Request
          </Button>
        )}

        <Box display="flex" alignItems="center" gap={1}>
          {isRightsQueueAdmin && (
            <Button size="large" color="tertiary" onClick={() => setIsSoftDeleteDSRModalOpen(true)}>
              Delete
            </Button>
          )}

          {isRightsQueueAdmin && !isCompleted && (
            <Button size="large" color="secondary" onClick={() => setIsExtendModalOpen(true)}>
              Extend
            </Button>
          )}

          {isRightsQueueAdmin && !isKetchFree && !isCompleted && !!workflowExecution && (
            <Button sx={{ minWidth: 'max-content' }} size="large" onClick={() => setIsFinalizeModalOpen(true)}>
              Finalize Request
            </Button>
          )}
        </Box>

        {shouldShowCompleteRequestButton && (
          <Button
            size="large"
            onClick={() => {
              setIsCompleteRequestOpen(true)
            }}
          >
            Complete Request
          </Button>
        )}
      </Box>

      {isCompleteRequestOpen && (
        <WorkflowExecutionCompleteModalContainer
          rightInvocation={rightInvocation || undefined}
          workflowExecutionStep={stepToCompleteRequest}
          onClose={() => setIsCompleteRequestOpen(false)}
        />
      )}

      {isFinalizeModalOpen && <FinalizeRequestModal onClose={() => setIsFinalizeModalOpen(false)} />}

      {isExtendModalOpen && <ExtendRequestModal onClose={() => setIsExtendModalOpen(false)} />}

      {isSoftDeleteDSRModalOpen && (
        <ConfirmDeleteDSRModal rightInvocation={rightInvocation} onClose={() => setIsSoftDeleteDSRModalOpen(false)} />
      )}

      {isForceCompleteDialogOpen && (
        <PopUp
          variant="dialog"
          title="Complete Request"
          onClose={(_: object, reason: string) => {
            if (reason === 'backdropClick') {
              return
            }
            setIsForceCompleteDialogOpen(false)
          }}
          popUpActionChildren={
            <>
              <Button
                color="secondary"
                size="large"
                onClick={() => setIsForceCompleteDialogOpen(false)}
                pending={isForceCompleteUpdating}
              >
                Close
              </Button>
              <Button
                color="primary"
                size="large"
                onClick={handleForceCompleteSubmitClick}
                pending={isForceCompleteUpdating}
              >
                Confirm
              </Button>
            </>
          }
        >
          <Typography>
            Before finalizing, confirm you have fulfilled the requirements of the request, and notified the data subject
            accordingly.
            <br />
            <br />
            If you would like to leverage Ketch workflow automation in the future, please reach out to our{' '}
            <Button
              variant="link"
              color="secondary"
              href="https://www.ketch.com/request-a-demo"
              sx={{
                fontSize: '14px',
                marginTop: '-2px',
              }}
            >
              sales team
            </Button>
            .
          </Typography>
        </PopUp>
      )}
    </>
  )
}
