import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { CSVLink } from 'react-csv'

import { Button } from '@ketch-com/deck'
import { DateRangePicker } from '../../components'
import { faker } from '@faker-js/faker'
import moment from 'moment'

const PREFIX = 'UserActivityActions'

const classes = {
  exportButton: `${PREFIX}-exportButton`,
}

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  [`& .${classes.exportButton}`]: {
    marginLeft: spacing(1.5),
  },
}))

type Props = {}

// empty array of length 10
const dummyExportData = Array.from({ length: 10 }).map(() => ({
  date: faker.date.past().toISOString(),
  noConsent: faker.datatype.number({ min: 2010, max: 87484 }),
  noConsentRequired: faker.datatype.number({ min: 2010, max: 87484 }),
  consent: faker.datatype.number({ min: 2010, max: 87484 }),
}))

export const UserActivityActions: React.FC<Props> = () => {
  const dataForCSVDownload = dummyExportData.map(t => {
    return {
      Date: t.date,
      'No Consent Count': t?.noConsent || 0,
      'Consent Not Required Count': t?.noConsentRequired || 0,
      'Consent Count': t?.consent || 0,
    }
  })

  const csvData = [
    [...Object.keys(dataForCSVDownload?.[0] || {})],
    ...dataForCSVDownload.map(t => {
      return Object.values(t || {})
    }),
  ]

  return (
    <StyledBox display="flex" alignItems="center">
      <DateRangePicker size="large" />

      <CSVLink
        data={csvData}
        filename={`user-activity-${moment().format('YYYY-MM-DD')}.csv`}
        style={{ textDecoration: 'none' }}
      >
        <Button
          className={classes.exportButton}
          id="team-activity-export-button"
          color="tertiary"
          size="large"
          onClick={() => {}}
        >
          Share
        </Button>
      </CSVLink>
    </StyledBox>
  )
}
