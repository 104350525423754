import React, { useContext, useState, useCallback } from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useFormikContext } from 'formik'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { PreferenceField } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import { TextInput, TabSegmented, TabSegmentedGroup, SwitchToggle } from '@ketch-com/deck'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { SWITCH_DEFAULT_TEXT, SWITCH_MODES } from '../../../constants'

const SwitchButtonContainer = styled(Box)(({ theme }) => ({
  borderRadius: 11,
  padding: theme.spacing(1.75, 2.25),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

type Props = {
  organizationLanguages: LanguageWithStatusDTO[]
}

export const SubscriptionTopicsSwitchButton: React.FC<Props> = ({ organizationLanguages }) => {
  const { values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext<PreferenceExperienceFormValues>()
  const { sidebarActiveTab } = useContext(ExperienceUpsertContext)

  const [switchMode, setSwitchMode] = useState<(typeof SWITCH_MODES)[keyof typeof SWITCH_MODES]>(SWITCH_MODES.ON)
  const enableSwitchButtonLabels = values?.subscriptions?.switchTextRenderLogic === 1

  const resetSwitchData = useCallback(() => {
    setFieldValue('subscriptions.switchOnText', SWITCH_DEFAULT_TEXT.OPT_IN)
    setFieldValue('subscriptions.switchOffText', SWITCH_DEFAULT_TEXT.OPT_OUT)
    organizationLanguages.forEach(({ language }) => {
      setFieldValue(`translations.${language.code}.subscriptions.switchOnText`, '')
      setFieldValue(`translations.${language.code}.subscriptions.switchOffText`, '')
    })
    setSwitchMode(SWITCH_MODES.ON)
  }, [setFieldValue, setSwitchMode, organizationLanguages])

  if (sidebarActiveTab !== PreferenceField.SUBSCRIPTIONS_TOPICS) return null

  return (
    <Box>
      <SwitchButtonContainer
        sx={{
          backgroundColor: ({ palette }) => `${enableSwitchButtonLabels ? palette.tara.main : palette.fadedGrey.main}`,
        }}
      >
        <SwitchToggle
          onChange={(e, isSwitchEnabled) => {
            if (isSwitchEnabled) {
              setFieldValue('subscriptions.switchTextRenderLogic', 1)
            } else {
              setFieldValue('subscriptions.switchTextRenderLogic', 4)
              resetSwitchData()
            }
          }}
          checked={enableSwitchButtonLabels}
        />
        <Typography variant="h4" color={enableSwitchButtonLabels ? 'greenHaze.main' : ''}>
          Switch Button Labels
        </Typography>
      </SwitchButtonContainer>

      {enableSwitchButtonLabels ? (
        <Box px={2.25}>
          <Box display="flex" flexDirection="column" gap={4} py={4}>
            <TextInput
              fullWidth
              label="Switch Button ON Text Label"
              value={values?.subscriptions?.switchOnText}
              onChange={e => {
                setFieldValue('subscriptions.switchOnText', e?.currentTarget?.value)
              }}
              error={touched?.subscriptions?.switchOnText && Boolean(errors?.subscriptions?.switchOnText)}
              errorMessage={
                typeof errors?.subscriptions?.switchOnText === 'string' ? errors?.subscriptions?.switchOnText : ''
              }
              onBlur={() => {
                setFieldTouched('subscriptions.switchOnText', true)
              }}
            />
            <TextInput
              fullWidth
              label="Switch Button OFF Text Label"
              value={values?.subscriptions?.switchOffText}
              onChange={e => {
                setFieldValue('subscriptions.switchOffText', e?.currentTarget?.value)
              }}
              error={touched?.subscriptions?.switchOffText && Boolean(errors?.subscriptions?.switchOffText)}
              errorMessage={
                typeof errors?.subscriptions?.switchOffText === 'string' ? errors?.subscriptions?.switchOffText : ''
              }
              onBlur={() => {
                setFieldTouched('subscriptions.switchOffText', true)
              }}
            />
          </Box>

          {organizationLanguages?.length ? (
            <Box
              display="flex"
              flexDirection="column"
              gap={4}
              py={4}
              sx={{ borderTop: ({ palette }) => `1px solid ${palette.iron.main}` }}
            >
              <Typography variant="h4" color="darkDuskFaded.main">
                Switch Button Label Translations
              </Typography>

              <Box>
                <TabSegmentedGroup
                  exclusive
                  value={switchMode}
                  onChange={(
                    event: React.MouseEvent<HTMLElement>,
                    mode: (typeof SWITCH_MODES)[keyof typeof SWITCH_MODES],
                  ) => {
                    setSwitchMode(mode)
                  }}
                  size="small"
                >
                  <TabSegmented value={SWITCH_MODES.ON}>
                    <Typography variant={`${switchMode === SWITCH_MODES.ON ? 'smallLabel' : 'smallBody'}`}>
                      ON Text Label
                    </Typography>
                  </TabSegmented>
                  <TabSegmented value={SWITCH_MODES.OFF}>
                    <Typography variant={`${switchMode === SWITCH_MODES.OFF ? 'smallLabel' : 'smallBody'}`}>
                      OFF Text Label
                    </Typography>
                  </TabSegmented>
                </TabSegmentedGroup>
              </Box>

              {switchMode === SWITCH_MODES.ON ? (
                <Box>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <Box key={language.code} marginBottom={3}>
                      <TextInput
                        fullWidth
                        value={values?.translations?.[`${language.code}`]?.subscriptions?.switchOnText}
                        label={
                          <Box display="flex" gap={0.5}>
                            <Typography variant="label">{language.englishName}</Typography>
                            <Typography variant="label" color="darkDuskFaded.main">
                              (Optional)
                            </Typography>
                          </Box>
                        }
                        onChange={e => {
                          setFieldValue(
                            `translations.${language.code}.subscriptions.switchOnText`,
                            e?.currentTarget?.value,
                          )
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              ) : null}

              {switchMode === SWITCH_MODES.OFF ? (
                <Box>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <Box key={language.code} marginBottom={3}>
                      <TextInput
                        fullWidth
                        value={values?.translations?.[`${language.code}`]?.subscriptions?.switchOffText}
                        label={
                          <Box display="flex" gap={0.5}>
                            <Typography variant="label">{language.englishName}</Typography>
                            <Typography variant="label" color="darkDuskFaded.main">
                              (Optional)
                            </Typography>
                          </Box>
                        }
                        onChange={e => {
                          setFieldValue(
                            `translations.${language.code}.subscriptions.switchOffText`,
                            e?.currentTarget?.value,
                          )
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              ) : null}
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  )
}
