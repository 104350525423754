import { ObjectLiteral } from 'interfaces'
import { ApiKeyRoleDTO } from 'interfaces/apiKeys/ApiKeyRoleDTO'
import { NO_PERMISSION } from '../../constants'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import {
  parseRoleDisplayName,
  RoleGroup,
  roleGroupDescriptions,
  roleGroupNames,
} from 'pages/settings/users/view/utils/getUserRolesDisplayData'

export type ApiKeyFormSectionRolesOptions = ObjectLiteral<{
  displayName: string
  description: string
  group: string
  options: { name: string; id: string }[]
}>

export const getApiKeyFormSectionRolesOptions = (
  roles: ApiKeyRoleDTO[],
  isEntitled: (requiredEntitlements: ENTITLEMENTS | ENTITLEMENTS[], requireAll?: boolean) => boolean,
) => {
  const apiKeyFormSectionRolesOptions: ApiKeyFormSectionRolesOptions = {}
  const copiedFilteredRoles = getCopiedFilteredRoles(roles, isEntitled)

  copiedFilteredRoles?.forEach?.(r => {
    if (!apiKeyFormSectionRolesOptions?.[r.group]) {
      const optionItem = {
        name: parseRoleDisplayName(r.name),
        id: r.code,
      }
      apiKeyFormSectionRolesOptions[r.group] = {
        options: [optionItem],
        group: r.group,
        displayName: roleGroupNames[r.group],
        description: roleGroupDescriptions[r.group],
      }
    } else {
      const optionItem = {
        name: parseRoleDisplayName(r.name),
        id: r.code,
      }
      apiKeyFormSectionRolesOptions[r.group] = {
        ...apiKeyFormSectionRolesOptions[r.group],
        options: [...apiKeyFormSectionRolesOptions[r.group]?.options, optionItem],
      }
    }
  })

  const tempKeys = Object.keys(apiKeyFormSectionRolesOptions)

  tempKeys?.forEach?.(key => {
    if (key !== 'org') {
      apiKeyFormSectionRolesOptions[key] = {
        ...apiKeyFormSectionRolesOptions[key],
        options: [...apiKeyFormSectionRolesOptions[key]?.options, { name: 'None', id: NO_PERMISSION }],
      }
    }
  })

  return apiKeyFormSectionRolesOptions
}

export const getCopiedFilteredRoles = (
  roles: ApiKeyRoleDTO[],
  isEntitled: (requiredEntitlements: ENTITLEMENTS | ENTITLEMENTS[], requireAll?: boolean) => boolean,
): ApiKeyRoleDTO[] => {
  return [...(roles || [])]
    .filter(
      r =>
        roleGroupNames[r.group] &&
        r.code !== 'everyone' &&
        r.code !== 'org_owner' &&
        r.code !== 'ketch_admin' &&
        (r.group !== RoleGroup.CarCapture || isEntitled(ENTITLEMENTS.EXPERIENCES)) &&
        (r.group !== RoleGroup.PrivacyProgramRisk ||
          isEntitled([ENTITLEMENTS.ASSESSMENTS, ENTITLEMENTS.PROCESSING_ACTIVITIES], false)) &&
        (r.group !== RoleGroup.DataMapSystems || isEntitled(ENTITLEMENTS.DATA_SYSTEMS)) &&
        (r.group !== RoleGroup.DataMapAssets || isEntitled(ENTITLEMENTS.ASSET_MANAGER)),
    )
    .sort((a, b) => ((a?.name ?? '') > (b?.name ?? '') ? 1 : a === b ? 0 : -1))
}
