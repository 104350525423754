import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  theme =>
    createStyles({
      sidebar: {
        position: 'relative',
        width: 500,
        marginLeft: 24,
        marginTop: -60,
        padding: '13px 24px',
        borderRadius: 11,
        backgroundColor: theme.palette.white.main,
        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.05), 0px 6px 25px rgba(0, 0, 0, 0.15)',
      },
    }),
  { name: 'UpsertInlineLayoutPreviewSidebar' },
)

type Props = {
  children?: React.ReactNode
}

export const UpsertInlineLayoutPreviewSidebar: React.FC<Props> = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.sidebar}>{children}</div>
}
