import { Box, Typography, styled } from '@mui/material'
import { BadgeDovetail } from 'pages/dataSystems/Labels/components/BadgeDovetail/BadgeDovetail'
import { ReactComponent as RestoreIcon } from 'assets/icons/restore.svg'

const Body = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '.25rem',
}))

type Props = {
  name: string
  handleRestore?: () => void
}

export const RestoreBadge: React.FC<Props> = ({ name, handleRestore }) => {
  return (
    <BadgeDovetail onClick={handleRestore} style={{ cursor: 'pointer' }}>
      <Body>
        <Typography variant="subtitle2">{name}</Typography>
        {handleRestore && <RestoreIcon />}
      </Body>
    </BadgeDovetail>
  )
}
