// components
import Box from '@mui/material/Box'
import { Chip } from 'components/ui-kit/chip/Chip'
import { Text } from 'components/ui-kit/typography/Text'

// types
import { JurisdictionDTO } from '@ketch-com/figurehead'
type Props = {
  data: JurisdictionDTO[]
}

export const ChipsList = ({ data }: Props) =>
  data && data.length ? (
    <Box mb={-1}>
      {data.map(d => (
        <Chip sx={{ mb: 1, mr: 1 }} key={d.code}>
          {d?.name || ''}
        </Chip>
      ))}
    </Box>
  ) : (
    <Text>None</Text>
  )
