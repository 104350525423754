import * as Yup from 'yup'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { useCheckIfSubscriptionCodeAvailable } from 'api/subscriptions/mutations/useCheckIfSubscriptionCodeAvailable'
import { SubscriptionContactMethodDTO, WebhookDTO, AppDTO } from '@ketch-com/figurehead'
import { systems } from 'pages/policyCenter/subscriptions/constants'
import { useMemo } from 'react'

type Args = {
  isEditMode: boolean
  subscriptionContactMethods: SubscriptionContactMethodDTO[]
  webhooksData: WebhookDTO[]
  systemsData: AppDTO[]
}

export function mergeSchemas(...schemas: Array<Yup.ObjectSchema<any>>) {
  const [first, ...rest] = schemas
  const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first)
  return merged
}

export const useSubscriptionTopicBasicDetailsValidationSchema = (isEditMode: boolean) => {
  const { mutateAsync: handleCheckIsContextVariableCodeAvailable } = useCheckIfSubscriptionCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsContextVariableCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return Yup.object().shape({
    basicDetails: Yup.object().shape({
      ...(!isEditMode && {
        code: codeValidationSchema,
      }),
      name: Yup.string().required('Required'),
      description: Yup.string(),
    }),
  })
}

export const useSubscriptionTopicContactMethodValidationSchema = (
  subscriptionContactMethods: SubscriptionContactMethodDTO[],
  webhooksData: WebhookDTO[],
  systemsData: AppDTO[],
) => {
  const contactMethodFields: { [key: string]: any } = {}
  subscriptionContactMethods?.forEach?.(channel => {
    const { code = '' } = channel
    contactMethodFields[code] = Yup.object().shape({
      status: Yup.boolean(),
      orchestrations: Yup.array().when('status', {
        is: true,
        then: Yup.array(
          Yup.object()
            .shape({
              type: Yup.string(),
              name: Yup.string(),
              appInstanceId: Yup.string().when('type', {
                is: 'system',
                then: Yup.string().required('Required'),
              }),
              appParams: Yup.object()
                .when(['type', 'name'], {
                  is: (type: string, name: string) => type === 'system' && name.toLowerCase() === systems.marketo,
                  then: Yup.object().shape({
                    fieldName: Yup.string().required('Required'),
                    groupID: Yup.string(),
                  }),
                })
                .when(['type', 'name'], {
                  is: (type: string, name: string) => type === 'system' && name.toLowerCase() === systems.braze,
                  then: Yup.object().shape({
                    fieldName: Yup.string(),
                    groupID: Yup.string().required('Required'),
                  }),
                })
                .when(['type', 'name'], {
                  is: (type: string, name: string) => type === 'system' && name.toLowerCase() === systems.hubspot,
                  then: Yup.object().shape({
                    fieldName: Yup.string(),
                    groupID: Yup.string().required('Required'),
                  }),
                })
                .when(['type', 'name'], {
                  is: (type: string, name: string) => type === 'system' && name.toLowerCase() === systems.iterable,
                  then: Yup.object().shape({
                    subscriptionGroup: Yup.string().required('Required'),
                    subscriptionGroupId: Yup.string().required('Required'),
                  }),
                })
                .when(['type', 'name'], {
                  is: (type: string, name: string) => type === 'system' && name.toLowerCase() === systems.shopify,
                  then: Yup.object().shape({
                    subscriptionGroup: Yup.string().required('Required'),
                  }),
                }),
            })
            .when(['type', 'name'], {
              is: (type: string, name: string) =>
                type === 'system' && name.toLowerCase() === systems.salesforceMarketingCloud,
              then: Yup.object().shape({
                subscriptionGroup: Yup.string().required('Required'),
                subscriptionGroupId: Yup.string().required('Required'),
              }),
            }),
        ).test({
          name: `contactMethods.${code}.orchestrations`,
          test: (parent, { createError }) => {
            const isValid = Boolean(parent?.length)
            if (isValid) return true
            if (webhooksData?.length || systemsData?.length)
              return createError({
                path: `contactMethods.${code}.orchestrations`,
                message: 'Please select a orchestration',
              })
            return createError({
              path: `contactMethods.${code}.orchestrations`,
              message: 'Please configure a webhook or system to orchestrate subscription',
            })
          },
        }),
      }),
    })
  })

  return Yup.object().shape({
    contactMethods: Yup.object()
      .shape(contactMethodFields)
      .test({
        name: 'atLeastOneContactMethodRequired',
        test: (parent, { createError }) => {
          const isValid = Object.keys(parent).some(channel => parent[channel].status)
          if (isValid) return true
          return createError({
            path: 'contactMethods',
            message: 'Please select a communication channel',
          })
        },
      }),
  })
}

export const getSubscriptionTopicLegalBasisValidationSchema = () => {
  return Yup.object().shape({
    legalBasis: Yup.object().shape({
      code: Yup.string().required('Required'),
    }),
  })
}

export const useCreateSubscriptionTopicFormValidation = ({
  currentFormStep,
  isEditMode,
  subscriptionContactMethods,
  webhooksData,
  systemsData,
}: Args & { currentFormStep: number }) => {
  const basicDetailsValidation = useSubscriptionTopicBasicDetailsValidationSchema(isEditMode)
  const contactMethodVadlidation = useSubscriptionTopicContactMethodValidationSchema(
    subscriptionContactMethods,
    webhooksData,
    systemsData,
  )
  const legalBasisValidationSchema = getSubscriptionTopicLegalBasisValidationSchema()
  return useMemo(() => {
    if (currentFormStep === 0) {
      return basicDetailsValidation
    } else if (currentFormStep === 1) {
      return mergeSchemas(basicDetailsValidation, contactMethodVadlidation)
    } else if (currentFormStep === 2) {
      return mergeSchemas(basicDetailsValidation, contactMethodVadlidation, legalBasisValidationSchema)
    }
  }, [currentFormStep, basicDetailsValidation, contactMethodVadlidation, legalBasisValidationSchema])
}

export const useSubscriptionTopicFormValidation = ({
  isEditMode,
  subscriptionContactMethods,
  webhooksData,
  systemsData,
}: Args) => {
  const basicDetailsValidation = useSubscriptionTopicBasicDetailsValidationSchema(isEditMode)
  const contactMethodVadlidation = useSubscriptionTopicContactMethodValidationSchema(
    subscriptionContactMethods,
    webhooksData,
    systemsData,
  )
  const legalBasisValidationSchema = getSubscriptionTopicLegalBasisValidationSchema()
  return mergeSchemas(basicDetailsValidation, contactMethodVadlidation, legalBasisValidationSchema)
}
