import React from 'react'

import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { FieldTypeFormSection, BasicDetailsFormSection } from '.'
import { useFormFieldUpsertContainerUtils } from '../hooks'
import { FormFieldUpsertContextProvider } from '../context/FormFieldUpsertContextProvider'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'

type Props = {}

export const CreateFormField: React.FC<Props> = () => {
  const {
    breadcrumbs,
    initialValues,
    validationSchema,
    isReady,
    getDummyDropdownItem,
    targetBackToUrl,
    handleSubmit,
    navigate,
  } = useFormFieldUpsertContainerUtils()

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  const { isSubmitting, submitForm } = formikProps

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={UpsertLayoutMode.create}
          showStepper={false}
          renderFormTitle={() => <ContentGroup title="Create Custom Form Field" titleVariant="h2" isDivider />}
          onAccept={submitForm}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          acceptActionButtonText="Save"
          onCancel={() => {
            navigate(targetBackToUrl)
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {!isReady ? (
              <Box display="flex" justifyContent="center" alignItems="center" py={10}>
                <Spinner size="32px" thickness={2.5} />
              </Box>
            ) : (
              <FormFieldUpsertContextProvider getDummyDropdownItem={getDummyDropdownItem}>
                <BasicDetailsFormSection />

                <FieldTypeFormSection />
              </FormFieldUpsertContextProvider>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
