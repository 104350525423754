import { useContext, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useContextVariable } from 'api/contextVariables/queries/useContextVariable'
import { Button } from '@ketch-com/deck'
import { WebhooksContextTimelineDTO } from '@ketch-com/figurehead'
import { getTypesString } from 'pages/settings/contextVariables/utils'
import { useState } from 'react'
import { ContextVariableChip } from 'pages/orchestration/rightsQueue/stepDetails/components'
import { ContextVariableInlineForm } from './ContextVariableInlineForm'
import { ApiCallContext } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/context/ApiCallContext'
import { FormDataInputValue } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'

type Props = {
  timelineItem: WebhooksContextTimelineDTO
}

export const InvalidOutcomeVariable: React.FC<Props> = ({ timelineItem }) => {
  const { submitTimelineChange } = useContext(ApiCallContext)

  const { outcomeVariables = {} } = timelineItem
  const { isActionEnabled } = outcomeVariables
  const [contextVariableKey, contextVariableValue] = Object.entries(outcomeVariables?.data || { '': '' })?.[0]

  const [showForm, setShowForm] = useState(false)
  const { data: contextVariable } = useContextVariable({ params: { code: contextVariableKey } })
  const [formValue, setFormValue] = useState<FormDataInputValue>()

  const submit = () => {
    submitTimelineChange({
      timelineItem,
      timelineItemToReplace: {
        ...timelineItem,
        ...{ outcomeVariables: { ...timelineItem.outcomeVariables, isActionEnabled: false } },
      },
      newTimelineItem: {
        ...timelineItem,
        ...{ outcomeVariables: { data: { [contextVariableKey]: formValue }, isActionEnabled: false } },
      },
    })
  }

  useEffect(() => {
    if (!isActionEnabled) setShowForm(false)
  }, [isActionEnabled])

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={0.75}>
        <Typography>
          Outcome variable received:&nbsp;
          <ContextVariableChip code={contextVariableKey} />
        </Typography>
      </Box>
      <Box mb={0.75}>
        <Typography>
          Value received:&nbsp;
          <Typography color="chileanFire.main" variant="label">
            {typeof contextVariableValue !== 'string' ? String(contextVariableValue) : `"${contextVariableValue}"`}
          </Typography>
        </Typography>
      </Box>
      <Box mb={1.5}>
        <Box>
          <Typography>
            <Typography variant="label">Issue:&nbsp;</Typography>a received value doesn't match outcome variable type.
            type.
          </Typography>
        </Box>
        <Typography variant="label">Acceptable Type:&nbsp;</Typography>
        <Typography>{getTypesString(contextVariable?.type || -1) || 'Unknown'}</Typography>
      </Box>
      {isActionEnabled ? (
        <Button color="tertiary" onClick={() => setShowForm(true)}>
          Change Value
        </Button>
      ) : null}
      {showForm ? (
        <Box mt={2} width={500}>
          <ContextVariableInlineForm
            contextVariable={contextVariable}
            onCancel={() => setShowForm(false)}
            formValue={formValue}
            setFormValue={setFormValue}
            onSubmit={submit}
          />
        </Box>
      ) : null}
    </Box>
  )
}
