import React from 'react'
import { compact, isEmpty, omit, size } from 'lodash'
import Grid from '@mui/material/Grid'
import { FieldArray, Formik } from 'formik'
import { Button, Icon, theme } from '@ketch-com/deck'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'
import { getAdobeValidationSchema } from 'pages/orchestration/workflows/edit/utils/forms/connector/adobe/adobeValidation'
import {
  getAdobeInitialValues,
  getIdentitySpacesInitialValues,
} from 'pages/orchestration/workflows/edit/utils/forms/connector/adobe/adobeInitialValues'
import { IAdobeConnectActivityParams, ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import * as mocks from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/connector/adobe/mocks'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { formatCodeFromName } from 'utils/formatters'
import { Box, Typography } from '@mui/material'
import { FormInput } from 'components/form/FormInput'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { FormCheckbox } from 'components/form/FormCheckbox'

type Props = {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

// NOTE: All connectors are implemented in single form for Demo purposes.
// After BE will be ready possibly this should be moved to separate forms

export const AdobeConnectorForm: React.FC<Props> = props => {
  const { step, steps, handleClose, handleRemove, handleSave } = props
  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))

  // mocks
  const conditions = mocks.getMockConditions()
  const connections = mocks.getMockConnections(step) || []
  const rights = mocks.getMockRights(step)
  const idSpaces = mocks.getMockIdSpaces(step)
  const identityName = mocks.getMockIdentityName(step)
  const propagationTypes = mocks.getMockPropagationTypes()

  const { data: purposes, isLoading } = usePurposes()
  const onSubmit = (values: any) => {
    const rightsList: string[] = []

    Object.values(values.rightsMap)?.forEach?.((value, key) => {
      if (value) {
        rightsList.push(Object.keys(values.rightsMap)[key])
      }
    })

    handleSave({
      step: {
        ...step,
        valid: true,
        code: values.code,
        description: values.description,
        workflow: {
          ...step.workflow,
          params: {
            ...values,
            rights: rightsList,
            rightsMap: undefined,
          },
        } as ICanvasStep['workflow'],
      },
    })
  }

  return (
    <SidebarConfigsWrapper isLoading={isLoading} handleClose={handleClose}>
      <Formik
        validateOnMount
        initialValues={getAdobeInitialValues({ step, identitySpaces: idSpaces })}
        validationSchema={getAdobeValidationSchema({ usedCodes })}
        onSubmit={onSubmit}
      >
        {({ isValid, values, setValues, setFieldValue, submitForm }) => {
          const isPermitType = values.type === 'permit'
          const isRightsType = values.type === 'rights'

          return (
            <>
              <SidebarConfigsHeader
                isValid={isValid}
                step={step}
                steps={steps}
                handleSave={() => submitForm()}
                handleRemove={() => handleRemove({ step })}
                handleClose={handleClose}
              />

              <SidebarConfigsBody sx={{ px: 3 }}>
                <Box mb={5}>
                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormInput
                          required
                          formPropertyName="description"
                          fullWidth
                          label="Description Label"
                          placeholder="Enter description"
                          onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
                          shouldUpdateDebounced
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={8}>
                        <FormInput
                          formPropertyName="code"
                          fullWidth
                          label="Code"
                          placeholder="Enter code"
                          required
                          shouldUpdateDebounced
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormDroplistButton
                          required
                          fullWidth
                          formPropertyName="connection"
                          label="Connection"
                          placeholder="Select Connection"
                          items={connections}
                          disableClearable
                          valueKey="id"
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormRadioGroup
                          id="secondaryButtonDestination"
                          hideOptionalLabel
                          formPropertyName="type"
                          label="Action"
                          row={false}
                          valueKey="id"
                          renderLabel={({ name }) => name}
                          options={propagationTypes}
                          onChange={(event, value) => {
                            const isPermitType = value === 'permit'
                            const isRightsType = value === 'rights'

                            const newValues = {
                              // NOTE: When selecting 'permit' clear 'rights' values and set initial values for 'permit'. And vise versa
                              ...(isPermitType &&
                                omit(
                                  { ...values, identitySpaces: getIdentitySpacesInitialValues(idSpaces) },
                                  'rights',
                                )),
                              ...(isRightsType && omit({ ...values, rights: [] }, 'identitySpaces')),
                              type: value,
                            } as IAdobeConnectActivityParams

                            setValues(newValues)
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {identityName && (
                    <Box marginBottom={3}>
                      <Typography variant="smallBody">
                        Signals sent using <Typography variant="smallLabel">{identityName}</Typography> Identifier
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box py={1.5}>
                  {/* Permit */}
                  {isPermitType && !isEmpty(values.identitySpaces) && !isEmpty(idSpaces) && (
                    <>
                      {idSpaces.map(({ name, id }) => {
                        const value = values?.identitySpaces?.[id]

                        if (!value) return null

                        return (
                          <Box
                            key={id}
                            sx={{
                              '&:not(:last-child)': {
                                paddingBottom: 3,
                                marginBottom: 1.5,
                                borderBottom: theme => `1px solid ${theme.palette.iron.main}`,
                              },
                            }}
                          >
                            <Box marginBottom={2.5}>
                              <Typography color={theme.palette.darkDuskFaded.main}>
                                set <Typography variant="label">{name}</Typography> to true
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 1.5,

                                '& > :nth-child(2)': {
                                  marginRight: 0.75,
                                  marginLeft: 0.75,
                                },
                              }}
                            >
                              <Typography>If</Typography>
                              <Box>
                                <FormDroplistButton
                                  formPropertyName={`identitySpaces[${id}].condition`}
                                  size="small"
                                  placeholder="Condition"
                                  items={conditions}
                                  disableClearable
                                  hideOptionalLabel
                                  valueKey="id"
                                />
                              </Box>

                              <Typography>of the following permits are true</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flexStart',
                                alignItems: 'stretch',
                                marginTop: 1.5,
                              }}
                            >
                              <Box
                                sx={{
                                  width: 40,
                                  marginRight: 1.25,
                                  display: 'flex',
                                  alignItems: 'stretch',
                                  justifyContent: 'center',
                                  padding: '16px 0',
                                }}
                              >
                                <Box
                                  sx={{
                                    width: '1px',
                                    height: '100%',
                                    backgroundColor: ({ palette }) => palette.iron.main,
                                  }}
                                />
                              </Box>
                              <Box flexGrow={1}>
                                <FieldArray
                                  name={`identitySpaces[${id}].permits`}
                                  render={({ push, remove }) => {
                                    const permits = value.permits
                                    const permitsCount = size(permits)

                                    return (
                                      <>
                                        {!isEmpty(permits) &&
                                          permits.map((_: any, index: number) => (
                                            <Box marginBottom={1.5} key={index}>
                                              <Grid container spacing={1}>
                                                <Grid item xs={10}>
                                                  <FormDroplistButton
                                                    required
                                                    fullWidth
                                                    formPropertyName={`identitySpaces[${id}].permits.${index}`}
                                                    label="Permit"
                                                    valueKey="code"
                                                    placeholder="Select Permit"
                                                    items={purposes}
                                                    disableClearable
                                                  />
                                                </Grid>

                                                {permitsCount > 1 && (
                                                  <Box display="flex" alignItems="center" mt={2.5} ml={2}>
                                                    <Button
                                                      variant="iconCustom"
                                                      color="tertiary"
                                                      onClick={() => remove(index)}
                                                    >
                                                      <Icon name="OCross" />
                                                    </Button>
                                                  </Box>
                                                )}
                                              </Grid>
                                            </Box>
                                          ))}

                                        <Box mt={1.5}>
                                          <Button color="secondary" onClick={() => push('')}>
                                            Add Permit
                                          </Button>
                                        </Box>
                                      </>
                                    )
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        )
                      })}
                    </>
                  )}

                  {/* Rights */}
                  {isRightsType && !isEmpty(rights) && (
                    <>
                      <Box marginBottom={1.5}>
                        <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
                          Select the rights signals to propagate
                        </Typography>
                      </Box>

                      {rights.map((right, index) => (
                        <Box marginBottom={1.5} key={index}>
                          <FormCheckbox name={`rightsMap.${right.id}`} label={right.name} inlineLabel />
                        </Box>
                      ))}
                    </>
                  )}
                </Box>
              </SidebarConfigsBody>
            </>
          )
        }}
      </Formik>
    </SidebarConfigsWrapper>
  )
}
