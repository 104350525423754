// layout utils
import { ProtectedRoute } from 'components/ui-routing/ProtectedRoute'
import { relativePath } from 'utils/routing/relativePath'
import { RoutesManager } from 'utils/routing/routesManager'

import { SystemSelector, AddConnectionForm } from 'pages/dataSystems/ConnectionNew'
import { DataSystemsInstall } from 'pages/dataSystems/DataSystemsInstall'
import { lazyLoadRoute } from 'utils/routing/lazyLoadRoute'
import { Labels } from '../dataSystems/Labels/Labels'
import { DataMapOverview } from './overview/DatamapOverview'
import { SystemsSection } from './systemsSection/SystemsSection'
import { Overview } from './Home/components/Overview/Overview'
import { Connections } from './components/Connections/Connections'
import { DataSytemContainer } from './DataSystem/DataSystemContainer'
import { DataSystemsSettings } from './Settings/DataSystemsSettings'

export const DataMapRoutesChildren = [
  /**
   * Datamap > Systems
   */

  // Systems home
  {
    element: (
      <ProtectedRoute component={SystemsSection} permissions={RoutesManager.systems.systemsHome.root.permissions} />
    ),
    path: relativePath(RoutesManager.systems.systemsHome.root.pattern),
    children: [
      // Data systems list
      {
        element: <ProtectedRoute component={Overview} permissions={RoutesManager.systems.systems.root.permissions} />,
        path: relativePath(RoutesManager.systems.systems.root.pattern),
      },
      // Connections list
      {
        element: (
          <ProtectedRoute component={Connections} permissions={RoutesManager.systems.connections.root.permissions} />
        ),
        path: relativePath(RoutesManager.systems.connections.root.pattern),
      },
    ],
  },
  // Settings
  {
    element: (
      <ProtectedRoute
        component={DataSystemsSettings}
        permissions={RoutesManager.systems.systems.settings.root.permissions}
      />
    ),
    path: relativePath(RoutesManager.systems.systems.settings.root.pattern),
  },

  // HOME
  {
    index: true,
    element: <ProtectedRoute component={DataMapOverview} permissions={[]} />,
  },
  {
    element: <ProtectedRoute component={DataMapOverview} permissions={[]} />,
    path: '',
  },
  {
    element: <ProtectedRoute component={DataMapOverview} permissions={RoutesManager.systems.root.permissions} />,
    path: relativePath(RoutesManager.systems.root.pattern),
  },

  // DATA SYSTEM
  {
    element: <ProtectedRoute component={DataSytemContainer} permissions={RoutesManager.systems.id.root.permissions} />,
    path: `${relativePath(RoutesManager.systems.id.root.pattern)}/*`,
  },

  // NEW CONNECTION
  {
    element: (
      <ProtectedRoute component={SystemSelector} permissions={RoutesManager.systems.connections.create.permissions} />
    ),
    path: relativePath(RoutesManager.systems.connections.create.pattern),
  },
  // Add connection page
  {
    element: (
      <ProtectedRoute
        component={AddConnectionForm}
        permissions={RoutesManager.systems.connections.upsert.root.permissions}
      />
    ),
    path: relativePath(RoutesManager.systems.connections.upsert.root.pattern),
  },
  // Edit Connection page, same component, has split logic inside
  {
    element: (
      <ProtectedRoute
        component={AddConnectionForm}
        permissions={RoutesManager.systems.connections.upsert.edit.permissions}
      />
    ),
    path: relativePath(RoutesManager.systems.connections.upsert.edit.pattern),
  },

  // Install Data Systems
  {
    element: (
      <ProtectedRoute component={DataSystemsInstall} permissions={RoutesManager.systems.create.root.permissions} />
    ),
    path: relativePath(RoutesManager.systems.create.root.pattern),
  },

  {
    element: <ProtectedRoute component={Labels} permissions={RoutesManager.systems.labels.root.permissions} />,
    path: `${RoutesManager.systems.labels.root.pattern}`,
    children: [
      {
        path: RoutesManager.systems.labels.root.pattern,
        lazy: async () => {
          const { DataCategories } = await import('../dataSystems/Labels/DataCategories')
          return lazyLoadRoute(DataCategories, RoutesManager.systems.labels.root.permissions)
        },
      },
      {
        path: RoutesManager.systems.labels.classifications.pattern,
        lazy: async () => {
          const { Classifications } = await import('../dataSystems/Labels/Classifications')
          return lazyLoadRoute(Classifications, RoutesManager.systems.labels.classifications.permissions)
        },
      },
      {
        path: RoutesManager.systems.labels.dataCategories.pattern,
        lazy: async () => {
          const { DataCategories } = await import('../dataSystems/Labels/DataCategories')
          return lazyLoadRoute(DataCategories, RoutesManager.systems.labels.dataCategories.permissions)
        },
      },
    ],
  },
]
