import { APIListRequestParams } from 'api/common/utils'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { API } from 'utils/api/api'
import { FetchAppConnectionsResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIListRequestParams<{
  systemCode: string
}>

export const fetchSystemConnections = ({ systemCode }: Params) =>
  API.get<FetchAppConnectionsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/apps/${systemCode}/connections`,
      params: {
        filters: {},
      },
    }),
  )
