export enum ExperienceDefaultDisplay {
  BANNER = 1,
  MODAL = 2,
}

export const EXPERIENCE_DEFAULT_DISPLAY_NAMES = {
  [ExperienceDefaultDisplay.BANNER]: 'Banner',
  [ExperienceDefaultDisplay.MODAL]: 'Modal',
}

export const EXPERIENCE_DEFAULT_DISPLAY_OPTIONS = [
  {
    id: ExperienceDefaultDisplay.BANNER,
    name: EXPERIENCE_DEFAULT_DISPLAY_NAMES[ExperienceDefaultDisplay.BANNER],
  },
  {
    id: ExperienceDefaultDisplay.MODAL,
    name: EXPERIENCE_DEFAULT_DISPLAY_NAMES[ExperienceDefaultDisplay.MODAL],
  },
]
