import React from 'react'
import numeral from 'numeral'
import pluralize from 'pluralize'
import Box from '@mui/material/Box'
import { Bar, ComposedChart, Tooltip, XAxis, YAxis, ReferenceLine, Line } from 'recharts'
import { useTranslation } from 'react-i18next'
import { COMMON, RIGHTS_STATISTICS } from 'i18n/types'
import Divider from '@mui/material/Divider'

import { MaybeNull } from 'interfaces'
import { RightStatsPeriodDTO, ChartTrendPeriod } from 'interfaces/rights/rightStats'
import { formatChartDate, getInterpolatedRightsPeriod } from 'pages/insights/utils'
import * as Components from './components'
import Typography from '@mui/material/Typography'

const { LegendBox, LegendItem, RightsTrendQueueFlowChartTooltip, ChartDescriptionSection } = Components

type Props = {
  trendPeriod: ChartTrendPeriod
  rightsStatsPeriod: MaybeNull<RightStatsPeriodDTO>
}

export const RightsTrendQueueFlowChart: React.FC<Props> = ({ trendPeriod, rightsStatsPeriod }) => {
  const { t } = useTranslation([RIGHTS_STATISTICS, COMMON])

  const rightsStatsPeriodWithInterpolatedDataPoints = getInterpolatedRightsPeriod({
    rightsStatsPeriod,
    trendPeriod,
  })

  const data =
    rightsStatsPeriodWithInterpolatedDataPoints?.dataPoints?.map(point => ({
      ...point,
      name: formatChartDate(trendPeriod, point.date || ''),
      invoked: point.invokedOnDate || 0,
      fulfilled: -(point?.fulfilledOnDate || 0),
      fulfilledLine: -(point?.fulfilledOnDate || 0) * 0.75,
    })) || []

  return (
    <Box width={580}>
      <Box display="flex" flexDirection="column">
        {/* Title */}

        <Typography variant="h3">{t('Queue Flow')}</Typography>

        {/* Subtitle */}

        <Typography color="grey">{t('How is the queue flow changing over time?')}</Typography>
      </Box>

      {/* Chart Legend */}

      <LegendBox>
        <LegendItem variant="invoked" label={t('Invoked')} />
        <LegendItem variant="fulfilled" label={t('Fulfilled')} />
      </LegendBox>

      {/* Chart */}

      <Box mt={2.5}>
        <Box display="flex" flex={1}>
          <ComposedChart
            width={580}
            height={240}
            data={data}
            margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
            barSize={40}
            stackOffset="sign"
          >
            <XAxis tickLine={false} padding={{ left: 30, right: 30 }} dataKey="name" />
            <YAxis tickLine={false} tickFormatter={value => `${numeral(Math.abs(value)).format('0')}`} />
            <ReferenceLine y={0} stroke="#000" />
            <Bar dataKey="invoked" fill="#E27965" stackId="stack" />
            <Bar dataKey="fulfilled" fill="#28988B" stackId="stack" />
            <Line type="natural" dataKey="fulfilledLine" stroke="#FCE9A4" strokeWidth={2} dot={false} />
            <Tooltip
              content={props => (
                <RightsTrendQueueFlowChartTooltip active={props.active} label={props.label} payload={props.payload} />
              )}
            />
          </ComposedChart>
        </Box>
      </Box>

      {/* Chart Statistics Row */}

      <Box display="flex" alignItems="center">
        {/* View Summary */}

        <Box display="flex" flexDirection="column">
          {/* Row 1 */}

          <Box display="flex" alignItems="center" mt={4}>
            <Typography variant="label">{t('View Summary')}</Typography>
          </Box>

          {/* Row 2 */}

          <Box display="flex" mt={2.25}>
            <ChartDescriptionSection
              mainText={String((rightsStatsPeriod?.totalFulfilled || 0) - (rightsStatsPeriod?.totalInvoked || 0) || 0)}
              subText={t('Backlog')}
            />
            <ChartDescriptionSection mainText={String(rightsStatsPeriod?.totalInvoked || 0)} subText={t('Invoked')} />
            <ChartDescriptionSection
              mainText={String(rightsStatsPeriod?.totalFulfilled || 0)}
              subText={t('Fulfilled')}
            />
          </Box>
        </Box>

        {/* Divider */}

        <Box display="flex" alignItems="center" mt={3} mr={5}>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              height: '60px',
              background: 'iron.main',
            }}
          />
        </Box>
        {/* Fulfillment Time Summary */}

        <Box display="flex" flexDirection="column">
          {/* Row 1 */}

          <Box display="flex" alignItems="center" mt={4}>
            <Typography variant="label">Fulfillment Time Summary</Typography>
          </Box>

          {/* Row 2 */}

          <Box display="flex" mt={2.25}>
            <ChartDescriptionSection
              mainText={`${rightsStatsPeriod?.avgFulfillTime || 0} ${pluralize(
                'day',
                rightsStatsPeriod?.avgFulfillTime || 0,
              )}`}
              subText="Average"
            />
            <ChartDescriptionSection
              mainText={`${rightsStatsPeriod?.medialFulfillTime || 0} ${pluralize(
                'day',
                rightsStatsPeriod?.medialFulfillTime || 0,
              )}`}
              subText="Median"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
