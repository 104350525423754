import React, { forwardRef, CSSProperties, HTMLAttributes } from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

const styles = createStyles({
  root: ({
    inline,
    ...rest
  }: Pick<Props, 'inline' | 'flexDirection' | 'flexWrap' | 'justifyContent' | 'alignItems'>) => ({
    display: inline ? 'inline-flex' : 'flex',
    ...rest,
  }),
})

const useStyles = makeStyles(styles, { name: 'Flex' })

type Props = {
  inline?: boolean
  flexDirection?: CSSProperties['flexDirection']
  flexWrap?: CSSProperties['flexWrap']
  justifyContent?: CSSProperties['justifyContent']
  alignItems?: CSSProperties['alignItems']
} & HTMLAttributes<HTMLDivElement>

export type FlexProps = Props

/**
 * @deprecated This component is deprecated and should not be used anymore.
 */
export const Flex = forwardRef<HTMLDivElement, Props>(function Flex(
  { className, inline = false, flexDirection, flexWrap, justifyContent, alignItems, ...rest },
  ref,
) {
  const classes = useStyles({
    inline,
    flexDirection,
    flexWrap,
    justifyContent,
    alignItems,
  })

  return <div {...rest} ref={ref} className={clsx(classes.root, className)} />
})
