import { Cell, Pie, PieChart } from 'recharts'

type Props = {
  progress: number
  small?: boolean
}

export const ConfigurationProgress: React.FC<Props> = ({ progress, small }) => {
  const data = [
    { percent: progress, value: progress, color: '#9C64FF' },
    { percent: 100 - progress, value: 100 - progress, color: 'rgba(7, 26, 36, 0.08)' },
  ]

  return (
    <PieChart
      width={small ? 24 : 34}
      height={small ? 24 : 34}
      data={data}
      margin={{
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        fill="#9C64FF"
        stroke=""
        innerRadius={small ? 8 : 12}
        outerRadius={small ? 11 : 17}
        paddingAngle={0}
        dataKey="percent"
        startAngle={90}
        endAngle={-270}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
    </PieChart>
  )
}
