import { useState } from 'react'
import { debounce } from 'lodash'

import { AssetSummaryDTO, ResourceDTO } from '@ketch-com/figurehead'
import { useAssetsPaginatedV2 } from 'api/assets/queries/useAssetsPaginatedV2'
import { useAssetTablesPaginatedV3 } from 'api/assets/queries/useAssetTablesPaginatedV3'
import { ASSET_TYPE_ENUM } from 'interfaces/assets'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type Args = { isEnabled?: boolean }

export const useResourceSearchOptions = ({ isEnabled = true }: Args) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )
  const [queryValue, setQueryValue] = useState('')
  const [assetType, setAssetType] = useState<number | undefined>()
  const [options, setOptions] = useState<ResourceDTO[]>([])
  const [placeholderOptions, setPlaceholderOptions] = useState<ResourceDTO[]>([])

  const { isLoading: isLoadingResources, isFetching: isFetchingResources } = useAssetsPaginatedV2({
    enabled: !!assetType && !!queryValue && isEnabled && isV2FeatureFlagEnabled,
    itemsPerPage: 500,
    params: {
      canonicalResourceType: assetType,
      query: queryValue,
    },
    onSuccess: ({ data }) => {
      const payload = (data.assets || []).map((r: AssetSummaryDTO) => {
        return r.asset?.resource
      })
      setOptions(payload as ResourceDTO[])
    },
  })

  const { isLoading: isLoadingPlaceholderResources, isFetching: isFetchingPlaceholderResources } = useAssetsPaginatedV2(
    {
      enabled: isV2FeatureFlagEnabled,
      itemsPerPage: 100,
      params: {
        canonicalResourceType: ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATASET,
      },
      onSuccess: ({ data }) => {
        const payload = (data.assets || []).map((r: AssetSummaryDTO) => {
          return r.asset?.resource
        })
        setPlaceholderOptions(payload as ResourceDTO[])
      },
    },
  )

  const { isLoading: isLoadingTables, isFetching: isFetchingTables } = useAssetTablesPaginatedV3({
    enabled: !!assetType && !!queryValue && isEnabled && !isV2FeatureFlagEnabled,
    itemsPerPage: 500,
    params: {
      query: queryValue,
    },
    onSuccess: ({ data }) => {
      const payload = (data.assets || []).map((r: AssetSummaryDTO) => {
        if (r?.asset?.resource) {
          if (!r?.asset?.resource?.properties) r.asset.resource.properties = {}
          const attributeResourceTypeCode: string = Object.keys(r?.hierarchyCounts || {})?.[0] || ''
          r.asset.resource.properties['attributeResourceTypeCode'] = attributeResourceTypeCode
        }
        return r.asset?.resource
      })
      setOptions(payload as ResourceDTO[])
    },
  })

  const { isLoading: isLoadingPlaceholderTables, isFetching: isFetchingPlaceholderTables } = useAssetTablesPaginatedV3({
    enabled: !isV2FeatureFlagEnabled,
    itemsPerPage: 100,
    params: {},
    onSuccess: ({ data }) => {
      const payload = (data.assets || []).map((r: AssetSummaryDTO) => {
        if (r?.asset?.resource) {
          if (!r?.asset?.resource?.properties) r.asset.resource.properties = {}
          const attributeResourceTypeCode: string = Object.keys(r?.hierarchyCounts || {})?.[0] || ''
          r.asset.resource.properties['attributeResourceTypeCode'] = attributeResourceTypeCode
        }
        return r.asset?.resource
      })
      setPlaceholderOptions(payload as ResourceDTO[])
    },
  })

  return {
    queryValue,
    setSearchValue: debounce((searchValue, assetTypeValue: number) => {
      setQueryValue(searchValue)
      setAssetType(assetTypeValue)
    }, 500),
    // de-duplicate array after merging "placeholderOptions" and "options"
    resourceOptions: [...new Map([...options, ...placeholderOptions].map(v => [v.id, v])).values()],
    isLoadingResources:
      isLoadingResources ||
      isFetchingResources ||
      isLoadingPlaceholderResources ||
      isFetchingPlaceholderResources ||
      isLoadingTables ||
      isFetchingTables ||
      isLoadingPlaceholderTables ||
      isFetchingPlaceholderTables,
    setResourceOptions: setOptions,
  }
}
