import React from 'react'
import { useAppInstancesUsages } from 'api/apps/queries/useAppInstancesUsages'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { Button, DataGrid, PopUp, Spinner, TableCell } from '@ketch-com/deck'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { WorkflowDTO } from 'interfaces/workflows'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { Box } from '@mui/material'

type Props = {
  dataSystemName: string
  appInstanceId: string
  onCancel?: () => void
}

export const WorkflowsModal: React.FC<Props> = ({ dataSystemName, appInstanceId = '', onCancel }) => {
  const navigate = useNavigate()

  const { workflows, isLoading: isLoadingWorkflows } = useAppInstancesUsages([appInstanceId])

  if (!appInstanceId) {
    return null
  }

  const columns = getDataGridColumns(navigate)
  return (
    <PopUp
      title={`Usage of “${dataSystemName}” in Workflows`}
      subTitle="See in which workflows this connection is currently used."
      variant="modal"
      popUpWidth="845px"
      onClose={onCancel}
      popUpActionChildren={
        <Button color="secondary" size="large" onClick={onCancel}>
          Close
        </Button>
      }
    >
      {isLoadingWorkflows ? (
        <Box display="flex" alignItems="center" justifyContent="center" m={3}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : (
        <DataGrid
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: false,
            columns: columns.map(column => column.field),
            expand: true,
          }}
          getRowHeight={() => 'auto'}
          columns={columns}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowHoverStates
          disableRowSelectionOnClick
          getRowId={row => row.code}
          rows={workflows}
          hideFooter
          noRowsOverlayProps={{
            buttonTitle: '',
          }}
        />
      )}
    </PopUp>
  )
}

const getDataGridColumns = (navigate: NavigateFunction): GridColDef<WorkflowDTO>[] => [
  {
    align: 'left',
    field: 'name',
    headerAlign: 'left',
    headerName: 'Workflow Name',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { name, code } }: GridRenderCellParams<WorkflowDTO>) => {
      return <TableCell title={name} subTitle={code} />
    },
  },
  {
    align: 'left',
    field: 'count',
    headerAlign: 'left',
    headerName: 'Activity Count',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { stepCount } }: GridRenderCellParams<WorkflowDTO>) => {
      if (stepCount) return <TableCell title={String(stepCount)} />
      return (
        <TableCell>
          <EmptyValueRenderer />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'actions',
    headerAlign: 'left',
    headerName: 'Used By',
    sortable: false,
    maxWidth: 100,
    flex: 1,

    renderCell: ({ row: { code } }: GridRenderCellParams<WorkflowDTO>) => {
      return (
        <TableCell>
          <Button
            color="tertiary"
            onClick={() => navigate(RoutesManager.orchestration.workflows.view.root.getURL({ code }))}
          >
            View
          </Button>
        </TableCell>
      )
    },
  },
]
