import { ExecutionResultAssetDTO, ExecutionResultHookDTO } from '@ketch-com/figurehead'
import { KETCH_DML } from 'pages/developers/hooks/constants'

export type Process = {
  name?: string
  preview?: string
  payload: ExecutionResultHookDTO[]
}

export const getProcessesPayload = (executionResult: ExecutionResultAssetDTO): Process[] => {
  let payload: Process[] = []
  executionResult?.processes?.forEach?.(process => {
    const preHooks = [...(process?.preHookResults || [])]
    const ketchDmlHook = [
      {
        executedDml: process?.ketchDml?.[0]?.executedDml,
        status: process?.ketchDml?.[0]?.status,
        hook: {
          assignmentCount: 9999,
          dmlCode: process?.ketchDml?.[0]?.executedDml || '',
          id: process?.ketchDml?.[0]?.hook?.id,
          name: process?.ketchDml?.[0]?.hook?.name || KETCH_DML,
          operation: 99999,
          technology: '',
          type: 99,
        },
      } as ExecutionResultHookDTO,
    ]

    const postHooks = [...(process?.postHookResults || [])]
    payload = [
      ...payload,
      {
        name: process?.ketchDml?.[0]?.hook?.name || KETCH_DML,
        preview: process?.ketchDml?.[0]?.executedDml,
        payload: [...preHooks, ...ketchDmlHook, ...postHooks],
      },
    ]
  })
  return payload
}
