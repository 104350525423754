import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIRequestParams } from 'api/common/utils'
import { ContextVariableDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  code: string
}>

type Response = { contextVariable: ContextVariableDTO }

export const fetchContextVariable = ({ code }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/context-variables/${code}`,
      params: {
        filters: {},
      },
    }),
  )
