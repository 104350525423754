import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { DataSystemSummary } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type Params = APIRequestParams<{
  requestBody: DataSystemSummary
  encodedTrackerKey: string
}>

export const updateTrackerDataSystem = ({ encodedTrackerKey, requestBody }: Params) => {
  return API.put(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/tracker/${encodedTrackerKey}/data-system`,
      params: {
        filters: {},
      },
    }),
    requestBody,
  )
}
