import React, { useState } from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import { DmlHookDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'
import IconButton from '@mui/material/IconButton'
import { HOOK_TYPE_NAMES, KETCH_DML } from 'pages/developers/hooks/constants'

import { createStyles, makeStyles } from '@mui/styles'
import { HookOverviewModal } from 'pages/developers/hooks/components'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      chipContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: spacing(0.825, 1.75),
        minHeight: 36,
        borderRadius: 100,
      },
      hookChip: {
        backgroundColor: palette.sphereFaded.main,
      },
      ketchDmlChip: {
        backgroundColor: palette.fadedGrey.main,
      },
      prePostBadge: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
        borderColor: 'rgba(87, 116, 255, 0.3)',
        padding: spacing(0.5, 0.825),
        borderRadius: spacing(1),
      },
      iconButton: {
        color: 'royalBlue.main',
        padding: 0,
        marginLeft: spacing(0.75),
      },
    }),
  { name: 'HookWidgetHookChip' },
)

type Props = {
  hook: DmlHookDTO
  shouldHideAssetsAssignmentTab?: boolean
}

export const HookWidgetHookChip: React.FC<Props> = ({ hook, shouldHideAssetsAssignmentTab = false }) => {
  const classes = useStyles()
  const [isHookOverviewModalOpen, setIsHookOverviewModalOpen] = useState<boolean>(false)

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box
          className={clsx(classes.chipContainer, {
            [classes.ketchDmlChip]: hook?.name === KETCH_DML,
            [classes.hookChip]: hook?.name !== KETCH_DML,
          })}
        >
          {/* PRE/POST badge */}

          {hook?.name !== KETCH_DML && (
            <Box className={classes.prePostBadge}>
              <Text size={14} color="royalBlue">
                {HOOK_TYPE_NAMES[hook?.type || 0]}
              </Text>
            </Box>
          )}

          {/* Hook Name */}

          <Text sx={{ ml: 0.5 }} size={14} color={hook?.name === KETCH_DML ? 'black' : 'royalBlue'}>
            {hook?.name}
          </Text>

          {hook?.name !== KETCH_DML && (
            <IconButton
              color="primary"
              className={classes.iconButton}
              aria-label="remove hook"
              disableFocusRipple
              disableRipple
              onClick={() => setIsHookOverviewModalOpen(true)}
            >
              <ChevronRightIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      {isHookOverviewModalOpen ? (
        <HookOverviewModal
          hook={hook}
          onCancel={() => setIsHookOverviewModalOpen(false)}
          shouldHideAssetsAssignmentTab={shouldHideAssetsAssignmentTab}
        />
      ) : null}
    </>
  )
}
