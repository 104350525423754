import { ObjectLiteral } from 'interfaces'
import { UpsertConsentExperienceDTO, ConsentExperienceConfigDTO } from 'interfaces/experiences/experience'
import { isEmpty } from 'lodash'
import { serialize } from '../../common'
import { ConsentExperienceFormValues } from '../interfaces'

export const mapConsentValuesToPayload = ({
  values,
  isEntitledToJit,
}: {
  values: ConsentExperienceFormValues
  isEntitledToJit: boolean
}): UpsertConsentExperienceDTO => {
  const { name, code, banner, modal, jit, translations } = values

  const { isCustomSwitchTextSwitchOn, isCustomTextSwitchOn, ...restModal } = modal

  return {
    name,
    code,
    consent: {
      banner: {
        ...banner,
        footerDescription: serialize(banner.footerDescription),
        showCloseIcon: !!values?.banner?.showCloseIcon,
      },
      modal: {
        ...restModal,
        bodyDescription: serialize(modal.bodyDescription),
        showCloseIcon: !!values?.modal?.showCloseIcon,
      },
      ...(isEntitledToJit && {
        jit: {
          ...jit,
          bodyDescription: serialize(jit.bodyDescription),
          showCloseIcon: !!values?.jit?.showCloseIcon,
        },
      }),
      ...(!isEmpty(translations) && {
        translations: Object.entries(translations!).reduce<ObjectLiteral<ConsentExperienceConfigDTO>>(
          (acc, [languageCode, translation]) => ({
            ...acc,
            [languageCode]: {
              banner: {
                ...translation.banner,
                footerDescription: serialize(translation.banner.footerDescription),
                secondaryButtonDestination: banner.secondaryButtonDestination,
                primaryButtonAction: banner.primaryButtonAction,
              },
              modal: {
                ...translation.modal,
                bodyDescription: serialize(translation.modal.bodyDescription),
              },
              ...(isEntitledToJit && {
                jit: {
                  ...translation.jit,
                  bodyDescription: serialize(translation.jit.bodyDescription),
                  moreInfoDestination: jit.moreInfoDestination,
                },
              }),
            },
          }),
          {},
        ),
      }),
    },
  }
}
