import {
  useApprovedTrackersUtils,
  useTrackersSettingsUtils,
  useUnapprovedTrackersUtils,
  useNotFoundTrackersUtils,
  useApprovedTrackerPropertiesUtils,
  useTrackerDetailsUtils,
  useTrackersFilterUtils,
  useTrackersOnNewPropertiesUtils,
  useTrackersWithNewPurposesUtils,
} from 'pages/policyCenter/trackers/hooks'

export type TrackerKeys = {
  trackerKey: string
  encodedTrackerKey: string
}

export const TrackerType = {
  cookie: 'Cookie',
  localStorage: 'Local Storage',
  sessionStorage: 'Session Storage',
} as const

export enum DrawerType {
  unapproved = 'unapproved',
  review = 'review',
}

export enum ProvenanceType {
  Icon = 'Icon',
  Text = 'Text',
  Chip = 'Chip',
}

export type ApprovedTrackersUtils = ReturnType<typeof useApprovedTrackersUtils>
export type UnapprovedTrackersUtils = ReturnType<typeof useUnapprovedTrackersUtils>
export type NotFoundTrackersUtils = ReturnType<typeof useNotFoundTrackersUtils>
export type TrackersSettingsUtils = ReturnType<typeof useTrackersSettingsUtils>
export type ApprovedTrackerPropertiesUtils = ReturnType<typeof useApprovedTrackerPropertiesUtils>
export type TrackerDetailsUtils = ReturnType<typeof useTrackerDetailsUtils>
export type TrackersFilterUtils = ReturnType<typeof useTrackersFilterUtils>
export type TrackersOnNewPropertiesUtils = ReturnType<typeof useTrackersOnNewPropertiesUtils>
export type TrackersWithNewPurposesUtils = ReturnType<typeof useTrackersWithNewPurposesUtils>
