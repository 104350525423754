import { createUseQuery, CustomQueryConfig } from '../../common/query'
import { ApiQueryKeys } from '../../common/queryKeys'
import { fetchJurisdictionsV2 } from '../fetchers/fetchJurisdictionsV2'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.jurisdictionsV2,
  queryFn: fetchJurisdictionsV2,
  select: res => res?.data?.jurisdictions || [], // TODO: Remove mock once backend is ready
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useJurisdictionsV2 = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
