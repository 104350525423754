import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchWorkflows } from 'api/workflows/fetchers/fetchWorkflows'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.workflows,
  queryFn: fetchWorkflows,
  select: res => res?.data?.workflows || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery, 'organizationCode'>

export const useWorkflows = (config?: Config) => {
  const { userData } = useAuth()

  return useCustomQuery({
    ...config,
    params: {
      start: 0,
      limit: 1000,
      ...config?.params,
      organizationCode: userData.organizationCode!,
    },
  })
}
