import { Typography } from '@mui/material'
import React, { useContext } from 'react'
import { getHumanReadableFieldType } from '../../utils'
import { FormFieldDetailViewContext } from '../context/FormFieldDetailViewContext'
import { FormFieldTypeDTO, FormFieldVariantDTO } from '@ketch-com/figurehead'
import { Chip, InfoRow } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type Props = {}

export const FormFieldViewBasicDetails: React.FC<Props> = () => {
  const { formField } = useContext(FormFieldDetailViewContext)

  const isText = formField?.type === FormFieldTypeDTO.TextFormFieldType
  const isCheckbox = formField?.type === FormFieldTypeDTO.CheckboxFormFieldType

  return (
    <>
      {!isCheckbox && (
        <InfoRow title="Display Name">
          <Typography variant="body">{formField?.title}</Typography>
        </InfoRow>
      )}

      {!isCheckbox && (
        <InfoRow title="Hint Text">
          {formField?.hint ? <Typography variant="body">{formField?.hint}</Typography> : <EmptyValueRenderer />}
        </InfoRow>
      )}

      <InfoRow title="Code">
        <Typography variant="body">{formField?.code}</Typography>
      </InfoRow>

      <InfoRow title="Field Type">
        <Chip label={getHumanReadableFieldType(formField)} />
      </InfoRow>
      {isText && (
        <InfoRow title="Size">
          <Chip label={formField?.variant === FormFieldVariantDTO.TextareaFormFieldVariant ? 'Text Area' : 'Normal'} />
        </InfoRow>
      )}

      {isText && (
        <InfoRow title="Identifier Mapping">
          {formField?.identitySpace?.name ? <Chip label={formField?.identitySpace?.name} /> : <EmptyValueRenderer />}
        </InfoRow>
      )}
    </>
  )
}
