import React, { useContext, useState } from 'react'
import { FormikHelpers } from 'formik'
import Typography from '@mui/material/Typography'
import { Formik } from 'formik'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import Box from '@mui/material/Box'
import { useUpdateWorkflowExecutionStep } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStep'
import { getIdentityVerificationAvailablePathsStringValue } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/IdentityVerification/utils'
import { IdentityVerificationContext } from '../../../context/IdentityVerificationContext'
import { showToast } from 'components/modals/AlertComponent'
import { Icon, InfoRow, theme } from '@ketch-com/deck'
import { FormRadioGroup } from 'components/form/FormRadioGroup'

type ViewModeWithValueProps = {
  hasAttemptedToResolve: boolean
  resolveFormikErrorsAvailablePaths?: string
  availablePaths?: string
}

const ViewModeWithValue: React.FC<ViewModeWithValueProps> = ({
  hasAttemptedToResolve,
  resolveFormikErrorsAvailablePaths,
  availablePaths,
}) => (
  <Box display="flex" flexDirection="column" flex={1}>
    <StatusBox
      isError={hasAttemptedToResolve && !!resolveFormikErrorsAvailablePaths}
      icon={
        <Icon
          name="OCheck"
          iconColor={availablePaths === 'verified' ? theme.palette.darkDusk.main : theme.palette.darkDuskFaded.main}
          sx={{ mr: 2 }}
        />
      }
      text="Verified"
      isSelected={availablePaths === 'verified'}
      withBorderBottom
    />
    <StatusBox
      isError={!!hasAttemptedToResolve && !!resolveFormikErrorsAvailablePaths}
      icon={
        <Icon
          iconColor={availablePaths === 'verified' ? theme.palette.darkDuskFaded.main : theme.palette.darkDusk.main}
          name="OCross"
          sx={{ mr: 2 }}
        />
      }
      text="Not Verified"
      isSelected={availablePaths === 'not_verified'}
    />
  </Box>
)

interface StatusBoxProps {
  icon: React.ReactNode
  text: string
  isSelected: boolean
  isError?: boolean
  withBorderBottom?: boolean
}

const StatusBox: React.FC<StatusBoxProps> = ({ icon, text, isSelected, isError, withBorderBottom }) => {
  return (
    <Box
      display="flex"
      pb={withBorderBottom ? 1.5 : 0}
      pt={!withBorderBottom ? 1.5 : 0}
      sx={{ borderBottom: withBorderBottom ? '1px solid' : undefined, borderColor: 'iron.main' }}
      alignItems="center"
    >
      {icon}
      <Typography variant={isSelected ? 'label' : 'body'} component="div">
        {text}
      </Typography>
    </Box>
  )
}

type Props = {}

export const SelectedPath: React.FC<Props> = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const { workflowStep, hasAttemptedToResolve, stepDetails, workflowExecutionId, refetchStepDetails, canEdit } =
    useContext(RightInvocationWorkflowStepDetailViewContext)
  const { resolveFormik } = useContext(IdentityVerificationContext)

  const availablePaths = getIdentityVerificationAvailablePathsStringValue(stepDetails)

  const initialValues = {
    availablePaths,
  }

  const { mutateAsync: updateWorkflowExecutionStep } = useUpdateWorkflowExecutionStep({
    onSuccess: async () => {
      await refetchStepDetails()
      showToast({ content: 'Updated workflow step', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update workflow step', type: 'error' })
    },
  })

  type MyFormValues = {
    availablePaths: string
  }

  async function handleOnSubmit(values: MyFormValues, formikHelpers: FormikHelpers<MyFormValues>): Promise<any> {
    try {
      await updateWorkflowExecutionStep({
        params: {
          workflowExecutionId,
          stepId: workflowStep?.ID,
          formData: {
            manualInterventionData: {
              decisionBoolean: values.availablePaths === 'verified' ? true : false,
              resolutionDetails: stepDetails?.manualInterventionData?.resolutionDetails,
            },
          },
        },
      })
      resolveFormik?.setFieldValue('availablePaths', values.availablePaths)
    } catch (error) {
      console.error('error', error)
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validateOnMount>
      {({ values, setFieldValue, submitForm, isSubmitting }) => {
        return (
          <InfoRow
            title="Available paths"
            isValidation={hasAttemptedToResolve && !!resolveFormik?.errors?.availablePaths}
            isEditable={canEdit}
            isEditing={isEditMode}
            isLoading={isSubmitting}
            onEditChange={() => {
              setIsEditMode(true)
            }}
            onAcceptChange={async () => {
              setIsEditMode(false)
              await submitForm()
            }}
            onCancelChange={() => {
              setIsEditMode(false)
              setFieldValue('availablePaths', availablePaths)
            }}
          >
            {isEditMode ? (
              <FormRadioGroup
                formPropertyName="availablePaths"
                row={false}
                valueKey="id"
                renderLabel={({ name }) => name}
                options={[
                  { id: 'verified', name: 'Verified' },
                  { id: 'not_verified', name: 'Not Verified' },
                ]}
                hideOptionalLabel
              />
            ) : values?.availablePaths ? (
              <ViewModeWithValue
                hasAttemptedToResolve={hasAttemptedToResolve}
                resolveFormikErrorsAvailablePaths={resolveFormik?.errors?.availablePaths}
                availablePaths={values?.availablePaths}
              />
            ) : (
              'Verified, Not Verified'
            )}
          </InfoRow>
        )
      }}
    </Formik>
  )
}
