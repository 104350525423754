import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        padding: '18px 0 14px 0',
        borderTop: `1px solid ${theme.palette.iron.main}`,
        position: 'sticky',
        bottom: 0,
        backgroundColor: theme.palette.white.main,
      },
    }),
  { name: 'UpsertLayoutFooter' },
)

type Props = {
  children?: React.ReactNode
}
export const UpsertLayoutFooter: React.FC<Props> = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.root}>{children}</div>
}
