import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { Formik, FormikConfig, FormikProps } from 'formik'

import { ObjectLiteral } from 'interfaces/index'
import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { Flex } from 'components/ui-layouts/flex/Flex'
import {
  NavigationBreadCrumbs,
  NavigationBreadCrumbsProps,
} from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

const useStyles = makeStyles(
  () =>
    createStyles({
      root: ({ removePadding, marginBottom }: { removePadding?: boolean; marginBottom?: string | number }) => ({
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flex: 1,
        padding: removePadding ? 0 : '0 12px',
        marginBottom,
      }),
      loading: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        minHeight: 250,
      },
      content: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      },
    }),
  { name: 'UpsertInlineLayout' },
)

type Props<I> = Omit<FormikConfig<I>, 'children'> & {
  isReady?: boolean
  validateOnMount?: boolean
  fullWidth?: boolean
  removePadding?: boolean
  marginBottom?: string | number
  breadcrumbs: NavigationBreadCrumbsProps['items']
  children: (formikProps: FormikProps<I>) => React.ReactNode
}

export function UpsertInlineLayout<I extends ObjectLiteral>({
  isReady = true,
  validateOnMount = true,
  enableReinitialize = true,
  fullWidth = false,
  removePadding = false,
  marginBottom,
  breadcrumbs,
  children,
  ...rest
}: Props<I>) {
  const classes = useStyles({ removePadding, marginBottom })

  return (
    <ContentBoundaries className={classes.root} width={fullWidth ? '100%' : 1366}>
      {isReady ? (
        <>
          <NavigationBreadCrumbs type="light" items={breadcrumbs} />

          <Formik {...rest} validateOnMount={validateOnMount} enableReinitialize={enableReinitialize}>
            {formikProps => (
              <form autoComplete="off" className={classes.content}>
                {children(formikProps)}
              </form>
            )}
          </Formik>
        </>
      ) : (
        <Flex className={classes.loading} alignItems="center">
          <Spinner />
        </Flex>
      )}
    </ContentBoundaries>
  )
}
