import { cloneDeep, isUndefined } from 'lodash'

import { ICanvasStep, IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { omitDisconnectedSteps } from 'pages/orchestration/workflows/edit/utils/steps/omitDisconnectedSteps'
import { getUuidWithoutHyphensAndStartingWithALetter } from '../getUuidWithoutHyphensAndStartingWithALetter'

export interface ICreatePlaceholder {
  workflow?: IWorkflowConfig
  parentStep: ICanvasStep
  childStep: ICanvasStep
}

interface ICreatePlaceholderReturn {
  newStep: ICanvasStep
  newSteps: Array<ICanvasStep>
}

// Creates a placeholder tile to be used below or in other instances where one is needed directly
export const createPlaceholderStep = (
  childStepId?: string | number,
  name: string = '',
  customId: string = getUuidWithoutHyphensAndStartingWithALetter(),
): ICanvasStep => ({
  ID: customId,
  valid: false,
  name,
  placeholder: {
    transition: childStepId,
  },
})

// Inserts a placeholder into the workflow, on plus button click
export const createPlaceholder = (params: ICreatePlaceholder): ICreatePlaceholderReturn => {
  const { workflow, parentStep, childStep } = params
  const newStep = createPlaceholderStep(childStep.ID)

  const updatedSteps: Array<ICanvasStep> = []

  workflow!.steps?.forEach?.(workflowStep => {
    const { ID } = workflowStep

    // We must do a deep copy here to prevent weird readonly errors after copying
    const itemToReturn = cloneDeep(workflowStep)

    if (ID === parentStep?.ID) {
      const isStart = !isUndefined(itemToReturn?.start)
      const isActivity = !isUndefined(itemToReturn?.activity)
      const isGateway = !isUndefined(itemToReturn?.gateway)
      const isChildWorkflow = !isUndefined(itemToReturn?.workflow)

      if (isStart) {
        itemToReturn.start!.transition = newStep.ID
      }

      if (isActivity) {
        itemToReturn.activity!.transition = newStep.ID
      }

      if (isGateway) {
        itemToReturn.gateway!.transitions = itemToReturn.gateway!.transitions.map(transition => {
          if (childStep.ID === transition.ID) {
            return { ...transition, ...newStep, name: transition.name }
          }

          return transition
        })
      }

      if (isChildWorkflow) {
        itemToReturn.workflow!.transition = newStep.ID
      }
    }
    updatedSteps.push(itemToReturn)
  })

  const payload = {
    newStep,
    newSteps: omitDisconnectedSteps({ steps: [...updatedSteps, newStep] }),
  }

  return payload
}
