import { useFormikContext } from 'formik'
import { CustomSqlFormData } from '../interfaces'
import { Box, Typography } from '@mui/material'
import { FormTextArea } from 'components/ui-kit/form/textArea/FormTextArea'
import { SCRIPT_TYPE } from '../../../constants'
import { useMemo } from 'react'
import { Banner } from '@ketch-com/deck'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'

export const CustomSqlUpsertQueryInput: React.FC = () => {
  const { values } = useFormikContext<CustomSqlFormData>()

  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isMultipleIdentitiesFlagEnabled = !isFeatureFlagEnabled(
    FEATURE_FLAGS.IMPORT_CONTEXT,
    FEATURE_FLAGS_VALUES.IMPORT_CONTEXT_SINGLE_IDENTITY,
  )

  const SqlInfo = useMemo(
    () => (
      <Banner
        severity="info"
        isInfoBox
        sx={{
          flexShrink: 2,
          '&.MuiPaper-root': {
            width: '100%',
          },
        }}
      >
        <Typography variant="body">
          The SQL query should have double brackets around the
          {isMultipleIdentitiesFlagEnabled ? (
            values.identitySpaceCodes.map((code, idx) => (
              <Typography key={code} variant="label">{` {{${code}}}${
                idx <= values.identitySpaceCodes.length - 3 ? ',' : ''
              } ${
                values.identitySpaceCodes.length > 1 && idx === values.identitySpaceCodes.length - 2 ? 'and ' : ''
              }`}</Typography>
            ))
          ) : (
            <Typography variant="label">{` {{${values.identitySpaceCode}}} `}</Typography>
          )}{' '}
          Identifier code in the where filter.{' '}
          {values?.scriptType === SCRIPT_TYPE.SINGLE
            ? 'If you have a script with multiple query statements, please separate each statement with a semicolon.'
            : ''}
        </Typography>
        {isMultipleIdentitiesFlagEnabled && values.identitySpaceCodes.length ? (
          <Typography variant="body">
            Click on the chips above to directly interpolate the identifier code into the SQL script input.
          </Typography>
        ) : null}
      </Banner>
    ),
    [isMultipleIdentitiesFlagEnabled, values],
  )

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {values?.sqlQueries?.length ? (
        <>
          {values?.sqlQueries?.map((_, index) => {
            return (
              <Box key={`sqlQueries${index}`}>
                <FormTextArea
                  fullWidth
                  rows={6}
                  name={`sqlQueries[${index}]`}
                  label={`SQL Script ${index + 1}`}
                  placeholder={`Paste your SQL script here`}
                />
                {SqlInfo}
              </Box>
            )
          })}
        </>
      ) : (
        <Box display="flex" flexDirection="column" gap={1}>
          <FormTextArea
            fullWidth
            rows={6}
            name="sqlQuery"
            label="SQL Script"
            placeholder={`Paste your SQL script here`}
          />
          {SqlInfo}
        </Box>
      )}
    </Box>
  )
}
