import { RoutesManager } from 'utils/routing/routesManager'

export const isAssessmentRelatedTaskType = (taskType?: string): boolean => {
  return taskType ? taskType.includes('assessment') : false
}

export const isAppConnectionTaskType = (taskType?: string): boolean => {
  return taskType ? taskType.includes('appConnection') : false
}

export const getDisplayIdentifierHeaderByTaskType = (taskType?: string) => {
  if (isAssessmentRelatedTaskType(taskType)) {
    return 'Assessment'
  } else if (isAppConnectionTaskType(taskType)) {
    return 'Connection ID'
  }
  return 'Request ID'
}

export const getRedirectUrlByTaskType = (taskType?: string, displayIdentifier?: string, viewUrl?: string) => {
  // assessment related taskTypes go to the assessment viewURL (or if not exists, some default)
  if (isAssessmentRelatedTaskType(taskType)) {
    return viewUrl ? viewUrl : RoutesManager.policyCenter.assessments.list.getURL()
  }

  // data system connection failure taskType go to the datasystem connection viewURL (or if not exists, some default)
  if (isAppConnectionTaskType(taskType)) {
    return viewUrl ? viewUrl : RoutesManager.systems.connections.root.getURL()
  }

  // all else (i.e., workflow related tasks) go to workflow pages
  return RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({
    id: displayIdentifier,
  })
}

export const getAssignedByHeaderByTaskType = (taskType?: string) => {
  switch (taskType) {
    case 'assessmentRejectedQuestionTask':
      return 'Rejected By'
    case 'assessmentReviewTask':
      return 'Requested By'
    default:
      return 'Assigned By'
  }
}
