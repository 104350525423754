import { useState, useEffect, useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

export type Pagination = {
  isPending: boolean
  isFirst: boolean
  isLast: boolean
  page: number
  totalPages: number
  onPageChange: (nextPage: number) => void
  totalResults?: number
}

export type CustomPaginatedQueryConfig<
  QueryType extends (...args: any[]) => any,
  StaticParams extends keyof Parameters<QueryType>[0]['params'] | '' = '',
> = Omit<Parameters<QueryType>[0], 'params'> & {
  params: Omit<Parameters<QueryType>[0]['params'], StaticParams | 'start'>
}

export function createUsePaginatedQuery<QueryFnData, QueryFnParams, QuerySelectorData>({
  queryKey,
  queryFn,
  select,
}: {
  queryKey: string
  queryFn: (params: QueryFnParams) => Promise<QueryFnData>
  select: (res?: QueryFnData) => QuerySelectorData
}) {
  return function useCustomPaginatedQuery({
    params,
    itemsPerPage = 20,
    ...queryOptions
  }: { params: QueryFnParams; itemsPerPage?: number } & Omit<
    UseQueryOptions<QueryFnData, unknown>,
    'queryKey' | 'queryFn' | 'select' | 'keepPreviousData'
  >) {
    const [page, setPage] = useState(0)

    const paginatedParams = {
      ...params,
      start: page * itemsPerPage,
      limit: itemsPerPage,
    }

    const { data, isFetching, ...rest } = useQuery([queryKey, paginatedParams], () => queryFn(paginatedParams), {
      keepPreviousData: true,
      ...(queryOptions as any),
      refetchInterval: 0,
    })

    // Get total results, taking into account the different casings this field may use
    const totalResults =
      (data as any)?.data?.totalResults || (data as any)?.data?.TotalResults || (data as any)?.data?.total_results || 0

    const totalPages = totalResults
      ? Math.ceil(+(totalResults || 0) / itemsPerPage)
      : Math.ceil(+(totalResults || 0) / itemsPerPage)

    useEffect(() => {
      if (page >= 0 && totalPages > 0 && page > totalPages - 1) {
        setPage(totalPages - 1)
      }
    }, [page, totalPages])

    const pagination: Pagination = {
      isFirst: page === 0,
      isLast: page === totalPages - 1,
      page,
      totalPages,
      onPageChange: setPage,
      isPending: isFetching,
      totalResults,
    }

    return { ...rest, isFetching, pagination, data: useMemo(() => select(data), [data]) }
  }
}
