import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ActionSheetItem,
  ActionSheetWrapper,
  AvatarSize,
  Button,
  Chip,
  Icon,
  Spinner,
} from '@ketch-com/deck'
import { Box, Tooltip, Typography } from '@mui/material'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { Team } from 'interfaces/services/harbormaster/teams_gen.schemas'
import React, { useState } from 'react'
import { TeamMemberListItem } from './TeamMemberListItem'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { TeamAssignmentsModal, ViewModes, ViewTabs } from './TeamAssignmentsModal'
import { UserAvatar } from 'components/avatar/UserAvatar'
import { UserShortDTO } from 'interfaces/users/users'
import { useWorkflowAssignments } from 'api/users/queries/useWorkflowAssignments'
import { useInstalledDataSystems } from 'api/dataSystems/queries/useInstalledDataSystems'
import { useProcessingActivitiesPaginated } from 'api/processingActivities/queries/useProcessingActivitiesPaginated'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

type Props = { team: Team; isOpen: boolean; setIsOpen: (isOpen: boolean) => void }

export const TeamsListItem: React.FC<Props> = ({ team, isOpen, setIsOpen }) => {
  const navigate = useNavigate()
  const [teamModalState, setTeamModalState] = useState<ViewModes>('none')
  const [teamModalTab, setTeamModalTab] = useState<ViewTabs>('workflowActivities')

  const { isPermitted } = useIsPermitted()
  const isTeamsWriter = isPermitted(PERMISSIONS.TEAM_WRITE)

  const toggleOpen = () => setIsOpen(!isOpen)
  const [dropdownAnchor, setDropdownAnchor] = useState<null | HTMLElement>(null)
  const {
    data: teamAssignments,
    isLoading: isLoadingAssignments,
    isRefetching: isRefetchingAssignments,
  } = useWorkflowAssignments({
    params: { id: team.id },
    enabled: isOpen,
  })

  const {
    data: assignedSystems,
    isLoading: isLoadingSystems,
    isRefetching: isRefetchingSystems,
  } = useInstalledDataSystems({
    params: {
      limit: 300,
      ownerUserIds: team.id,
    },
    enabled: isOpen,
  })

  const {
    data: processingActivities,
    isLoading: isLoadingProcessingActivities,
    isRefetching: isRefetchingProcessingActivities,
  } = useProcessingActivitiesPaginated({
    itemsPerPage: 300,
    params: {
      teamIds: team.id ? [team.id] : undefined,
      includeMetadata: true,
    },
    enabled: isOpen,
  })

  const isLoading =
    isLoadingAssignments ||
    isRefetchingAssignments ||
    isLoadingProcessingActivities ||
    isRefetchingProcessingActivities ||
    isRefetchingSystems ||
    isLoadingSystems

  const handleDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setDropdownAnchor(event.currentTarget)
  }

  const handleDropdownClose = (event: any) => {
    event.stopPropagation()
    setDropdownAnchor(null)
  }

  const onDeleteClick = (event: any) => {
    handleDropdownClose(event)
    setTeamModalState('delete')
    setTeamModalTab(
      teamAssignments?.length ? 'workflowActivities' : assignedSystems?.length ? 'systems' : 'processingActivities',
    )
  }

  const onModalClose = () => {
    setTeamModalState('none')
  }

  // Deleted users seem to still be linked to teams
  // This workaround prevents their display
  const members = (team.members || []).filter(member => !!member.firstName || !!member.lastName || !!member.email)

  return (
    <>
      <Accordion
        expanded={isOpen}
        sx={({ palette }) => ({
          border: `1px solid ${isOpen ? palette.Black.o8 : palette.Common.Divider}`,
          backgroundColor: isOpen ? palette.Black.o8 : undefined,
        })}
      >
        <AccordionSummary size="large" onClick={toggleOpen}>
          <Box display="flex" alignItems="center" gap={1}>
            <UserAvatar
              user={{ ID: team.id } as UserShortDTO}
              alternativeText={team.label?.toUpperCase()}
              size={AvatarSize.large}
            />
            <Box display="flex" flexDirection="column">
              <Typography variant="h3">{team.name}</Typography>
              <Typography variant="body" color="text.secondary">
                {members.length} members
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Tooltip placement="bottom" title={isTeamsWriter ? '' : 'You do not have permission to manage teams.'}>
              <span>
                <Button size="medium" variant="iconSubtle" onClick={handleDropdownClick} disabled={!isTeamsWriter}>
                  <Icon name="OMore" />
                </Button>
              </span>
            </Tooltip>
            <ActionSheetWrapper
              anchorEl={dropdownAnchor}
              open={!!dropdownAnchor}
              onClose={handleDropdownClose}
              transitionDuration={0}
            >
              <ActionSheetItem
                onClick={e => {
                  handleDropdownClose(e)
                  navigate(RoutesManager.settings.teams.upsert.root.getURL({ id: team.id }))
                }}
              >
                Edit
              </ActionSheetItem>
              <ActionSheetItem onClick={onDeleteClick}>Delete</ActionSheetItem>
            </ActionSheetWrapper>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box px={2} pb={3} gap={1.5} flexDirection="column">
            <Typography component="div" variant="smallLabel">
              Assignments
            </Typography>

            <Box mt={1}>
              <Box display="flex" gap={1} alignItems="center">
                {teamAssignments?.length ? (
                  <Chip
                    clickable
                    label={`${teamAssignments.length} Assignments`}
                    onClick={() => {
                      setTeamModalState('view')
                      setTeamModalTab('workflowActivities')
                    }}
                    deleteIcon={<Icon name="OArrowCRight" />}
                    onDelete={() => {
                      setTeamModalState('view')
                      setTeamModalTab('workflowActivities')
                    }}
                  />
                ) : null}
                {assignedSystems?.length ? (
                  <Chip
                    clickable
                    label={`${assignedSystems.length} Owned Systems`}
                    onClick={() => {
                      setTeamModalState('view')
                      setTeamModalTab('systems')
                    }}
                    deleteIcon={<Icon name="OArrowCRight" />}
                    onDelete={() => {
                      setTeamModalState('view')
                      setTeamModalTab('systems')
                    }}
                  />
                ) : null}
                {processingActivities?.length ? (
                  <Chip
                    clickable
                    label={`${processingActivities.length} Processing Activities`}
                    onClick={() => {
                      setTeamModalState('view')
                      setTeamModalTab('processingActivities')
                    }}
                    deleteIcon={<Icon name="OArrowCRight" />}
                    onDelete={() => {
                      setTeamModalState('view')
                      setTeamModalTab('processingActivities')
                    }}
                  />
                ) : null}
                {isLoading ? <Spinner size={24} /> : null}
              </Box>
              {!isLoading && !teamAssignments?.length && !assignedSystems?.length && !processingActivities?.length ? (
                <EmptyValueRenderer />
              ) : null}
            </Box>
          </Box>
          <Box px={2} pb={3} gap={1.5} flexDirection="column">
            <Typography component="div" variant="smallLabel">
              Description
            </Typography>
            {team.description ? (
              <Typography variant="body" color="Text.Secondary">
                {team.description}
              </Typography>
            ) : (
              <EmptyValueRenderer />
            )}
          </Box>
          <Box>
            <Typography component="div" px={2} pb={1.5} variant="smallLabel">
              Members
            </Typography>
            <Box bgcolor="white.main" py={1} px={2} borderRadius="11px">
              {members.map(member => (
                <TeamMemberListItem key={member.id} member={member} />
              ))}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      {teamModalState !== 'none' ? (
        <TeamAssignmentsModal
          mode={teamModalState}
          team={team}
          onClose={onModalClose}
          teamAssignments={teamAssignments}
          processingActivities={processingActivities}
          assignedSystems={assignedSystems}
          teamModalTab={teamModalTab}
          setTeamModalTab={setTeamModalTab}
        />
      ) : null}
    </>
  )
}
