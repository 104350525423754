export enum VariableClaimMappingDestination {
  IDENTITY_SPACE = 1,
  DATA_SUBJECT = 2,
  DATA_SUBJECT_VARIABLES = 3,
}

export const VariableClaimMappingDestinationNames = {
  [VariableClaimMappingDestination.IDENTITY_SPACE]: 'Identifier',
  [VariableClaimMappingDestination.DATA_SUBJECT]: 'Subject Form Data',
  [VariableClaimMappingDestination.DATA_SUBJECT_VARIABLES]: 'Data Subject Variable',
}

const { IDENTITY_SPACE, DATA_SUBJECT, DATA_SUBJECT_VARIABLES } = VariableClaimMappingDestination

export const destinationItems = [
  {
    name: VariableClaimMappingDestinationNames[IDENTITY_SPACE],
    id: IDENTITY_SPACE,
  },
  {
    name: VariableClaimMappingDestinationNames[DATA_SUBJECT],
    id: DATA_SUBJECT,
  },
  {
    name: VariableClaimMappingDestinationNames[DATA_SUBJECT_VARIABLES],
    id: DATA_SUBJECT_VARIABLES,
  },
]
