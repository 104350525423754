export enum FetchRightInvocationsFilterKeys {
  statuses = 'request_status',
  rightCodes = 'rightCodes',
  subjectTypeCodes = 'subjectTypeCodes',
  policyScopeCodes = 'policyScopeCodes',
}

export enum FetchRightInvocationsOrderByKeys {
  invoked_at = 'invoked_at',
  status = 'request_status',
}

export const rightInvocationOrderByList = [
  FetchRightInvocationsOrderByKeys.invoked_at,
  FetchRightInvocationsOrderByKeys.status,
] as const
