import React, { HTMLAttributes, useState } from 'react'
import { Box } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { ActionSheetItem, AvatarSize, Chip, ChipsAutocomplete, Icon, ListItemText, TextInput } from '@ketch-com/deck'
import { UserAvatar } from 'components/avatar/UserAvatar'
import { StyledAutoCompleteContainer } from 'pages/dataSystems/DataSystem/components/styledComponents'
import { UserAndTeamOptionType } from 'pages/dataSystems/DataSystem/types'

type OptionListItemType = UserAndTeamOptionType

type Props = {
  values: OptionListItemType[]
  options: Array<OptionListItemType>
  updateValues: (updatedList: OptionListItemType[]) => void
}

export const OwnerSearchAutocomplete: React.FC<Props> = ({ values = [], options = [], updateValues = () => {} }) => {
  const [selected, setSelected] = useState([...values])
  const [inputValue, setInputValue] = useState('')

  const onOptionSelect = (e: React.SyntheticEvent<Element, Event>, owners: OptionListItemType[]) => {
    e.preventDefault()
    setSelected(owners)
    updateValues(owners)
  }

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: OptionListItemType,
    _: any,
    isSelected?: boolean,
  ) => {
    return (
      <ActionSheetItem selected={isSelected} {...props} key={option.id}>
        <Box display="flex" alignItems="center" width="100%">
          <ListItemText selected={isSelected}>
            <Box display="flex" alignItems="center" gap={1}>
              <UserAvatar user={option} size={AvatarSize.small} />
              <Box>{option.name}</Box>
            </Box>
          </ListItemText>
        </Box>
      </ActionSheetItem>
    )
  }

  return (
    <StyledAutoCompleteContainer>
      <ChipsAutocomplete
        inRowChips
        chipSize="medium"
        getOptionLabel={option => option?.name ?? ''}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        multiple
        onChange={(e, newValue) => onOptionSelect(e, newValue)}
        options={options}
        renderOption={renderOption}
        sx={{ width: '52%' }}
        value={selected}
        renderInput={params => (
          <TextInput
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Icon name="OMag" />
                </InputAdornment>
              ),
              endAdornment: null,
            }}
            onChange={e => setInputValue(e.currentTarget.value)}
            placeholder="Start typing a name"
            value={inputValue}
            variant="outlined"
          />
        )}
        renderChipItem={(data, index): React.ReactNode => {
          return (
            <Box key={`${index}-${data.value}`}>
              <Chip
                size="medium"
                label={data.name ?? data.value}
                deleteIcon={<Icon name="OCross" />}
                onDelete={() => {
                  setSelected(selected.filter(label => label.value !== data.value))
                  updateValues(selected.filter(label => label.value !== data.value))
                }}
              />
            </Box>
          )
        }}
      />
    </StyledAutoCompleteContainer>
  )
}
