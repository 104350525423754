import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchWorkflows } from 'api/workflows/fetchers/fetchWorkflows'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.workflowsPaginated,
  queryFn: fetchWorkflows,
  select: res => res?.data?.workflows || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery, 'organizationCode'>

export const useWorkflowsPaginated = (config?: QueryConfig) => {
  const { userData } = useAuth()

  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
      organizationCode: userData.organizationCode!,
    },
  })
}
