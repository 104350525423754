import React, { useEffect } from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import Box from '@mui/material/Box'

import { AssetSummaryDTO, ConnectionDTO, FetchAssetProcessingActivitiesResponseBodyDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces/common'
import { RoutesManager } from 'utils/routing/routesManager'
import { AssetDetailViewActions } from './components'

import { AssetManagerViewLayout } from 'components/ui-layouts/assetManager/AssetManagerViewLayout'
import { AssetManagerViewLayoutHeader } from 'components/ui-layouts/assetManager/AssetManagerViewLayoutHeader'
import { TabsRoutes } from 'components/ui-kit/tabs/TabsRoutes'
import { isEmpty } from 'lodash'
import { useAssetDetailBreadcrumbs, useAssetDetailTabs } from './hooks'
import { AssetDetailViewRoutes } from './components/AssetDetailViewRoutes'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { getAssetDetailViewState } from 'store/assetDetailViewSlice/selectors'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { pushEntryOntoBackToUrlHistory } from 'store/assetDetailViewSlice'
import { GetDataPreviewResponseBody } from 'pages/assetManager/interfaces'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      sectionTitle: {
        marginBottom: spacing(0.5),
      },
      subSectionTitle: {
        color: palette.lightGrey.main,
        marginBottom: spacing(3),
      },
      viewModeLayoutContainer: {
        padding: spacing(0, 2),
      },
      tabsRoutes: {
        borderBottom: `1px solid ${palette.iron.main}`,
        marginBottom: spacing(3),
      },
    }),
  { name: 'AssetDetailView' },
)

type Props = {
  isReady: boolean
  assetSummary: MaybeNull<AssetSummaryDTO>
  connections: ConnectionDTO[]
  processingActivities: FetchAssetProcessingActivitiesResponseBodyDTO
  dataPreview?: GetDataPreviewResponseBody
}

export const AssetDetailView: React.FC<Props> = ({
  isReady,
  assetSummary,
  connections,
  processingActivities,
  dataPreview,
}) => {
  const classes = useStyles()
  const tabs = useAssetDetailTabs({ assetSummary: assetSummary || undefined, connections })
  const hasTabs = !isEmpty(tabs)
  const { backToUrlHistory } = useAppSelector(getAssetDetailViewState)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (backToUrlHistory.indexOf(RoutesManager.assetManager.assets.list.getURL()) === -1) {
      dispatch(pushEntryOntoBackToUrlHistory(RoutesManager.assetManager.assets.list.getURL()))
    }
  }, [dispatch, backToUrlHistory])

  const breadcrumbs = useAssetDetailBreadcrumbs({ assetSummary: assetSummary || null })

  if (!isReady || !assetSummary || !processingActivities) {
    return (
      <AssetManagerViewLayout
        breadcrumbs={breadcrumbs}
        assetSummary={assetSummary}
        processingActivities={processingActivities}
      >
        <Box display="flex" alignItems="center" py={6}>
          <Spinner />
        </Box>
      </AssetManagerViewLayout>
    )
  }

  const targetBackToUrl =
    backToUrlHistory[backToUrlHistory.length - 1] || RoutesManager.assetManager.assets.list.getURL()

  return (
    <AssetManagerViewLayout
      breadcrumbs={breadcrumbs}
      assetSummary={assetSummary}
      processingActivities={processingActivities}
    >
      <AssetManagerViewLayoutHeader
        assetSummary={assetSummary}
        backTo={targetBackToUrl}
        actions={<AssetDetailViewActions assetSummary={assetSummary} />}
        backToScrollToTarget={
          targetBackToUrl === RoutesManager.assetManager.assets.list.getURL()
            ? assetSummary?.asset?.resource?.id
            : undefined
        }
      />

      {hasTabs ? <TabsRoutes tabs={tabs} size="regular" className={classes.tabsRoutes} /> : null}

      <AssetDetailViewRoutes assetSummary={assetSummary} dataPreview={dataPreview} />
    </AssetManagerViewLayout>
  )
}
