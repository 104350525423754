import * as Yup from 'yup'
import { useMemo } from 'react'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { ContextVariableDTO } from '@ketch-com/figurehead'
import { useCheckIsContextVariableCodeAvailable } from 'api/contextVariables/mutations/useCheckIsContextVariableCodeAvailable'
import { countBy } from 'lodash'

type Args = {
  contextVariable: ContextVariableDTO
  isEditMode: boolean
}

export const useContextVariablesValidation = ({ isEditMode }: Args) => {
  const { mutateAsync: handleCheckIsContextVariableCodeAvailable } = useCheckIsContextVariableCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsContextVariableCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(
    () =>
      Yup.object().shape({
        ...(!isEditMode && {
          code: codeValidationSchema,
        }),
        name: Yup.string().required('Required').max(99, 'Max 99 characters').min(3, 'Min 3 characters'),
        type: Yup.number().required('Required'),
        category: Yup.string().required('Required'),
        values: Yup.array().when('type', {
          is: 1,
          then: Yup.array().of(
            Yup.string()
              .required('Required')
              .max(250, 'Max 250 characters')
              .test('Test for value uniqueness', 'Value must be unique.', (value, context: any) => {
                let isValid = true
                if (!value) return true
                const allValues = context?.parent?.filter(Boolean) as string[]
                const occurrenceMap = countBy(allValues)
                if (occurrenceMap[value] > 1) isValid = false
                return isValid
              }),
          ),
        }),
      }),
    [isEditMode, codeValidationSchema],
  )
}
