import React, { useContext } from 'react'
import { SidebarRouteItem } from 'utils/routing/types'
import { AppNavigationContext } from '../../../../context/AppNavigationContext'
import { Button, Icon, theme } from '@ketch-com/deck'
import { Box, SxProps, Typography } from '@mui/material'
import { SidebarItemWrapper } from '../SidebarItemWrapper'
import { SidebarMode } from '../../../../types'
import { SidebarNestedItems } from './components/SidebarNestedItems'
import { GatedNavigateStatus, useNavigateGated } from 'utils/routing/hooks/useNavigateGated'

interface Props {
  item: SidebarRouteItem
}

export const SidebarItem: React.FC<Props> = ({ item }) => {
  const { sidebarMode, sidebarOpenSection, setSidebarOpenSection } = useContext(AppNavigationContext)
  const navigateGated = useNavigateGated()

  // undefined === not forced open or closed, false === forced closed, true === forced open
  const hasSubroutes = !!item.subRoutes?.length || !!Object.keys(item.subRoutes || {}).length
  // Item is selected (purple) if path ENDS with item.path, or if the item is not open and one of its children is selected
  const isSelected =
    window.location.pathname.endsWith(item.path) ||
    (sidebarOpenSection !== item.sectionId && window.location.pathname.includes(item.path))
  // Child is selected if parent is not selected and path INCLUDES item.path
  const isChildSelected = !isSelected && window.location.pathname.includes(item.path)
  const isSidebarExpanded = sidebarMode === SidebarMode.Open || sidebarMode === SidebarMode.Overlay
  const isItemOpen = isSidebarExpanded && sidebarOpenSection === item.sectionId

  const handleItemClick = () => {
    const { status } = navigateGated(
      item.path,
      item.permissions,
      item.entitlements,
      item.requireAllPermissions,
      item.requireAllEntitlements,
    )
    if (status === GatedNavigateStatus.Success && item.sectionId) setSidebarOpenSection(item.sectionId)
  }

  const handleEndIconClick = (e: React.MouseEvent) => {
    setSidebarOpenSection(isItemOpen ? undefined : item.sectionId)
    // Stop propagation so end icon can be clicked in isolation
    e.stopPropagation()
  }

  const selectedIcon = React.cloneElement(item.selectedIcon || <></>, {
    iconColor: theme.palette.Highlight.PrimaryDark,
  })

  const openIcon = React.cloneElement(item.selectedIcon || <></>, {
    iconColor: theme.palette.Black.o48,
  })

  const startIcon =
    isSelected || (!isSidebarExpanded && isChildSelected)
      ? selectedIcon
      : isItemOpen || (!isItemOpen && isSidebarExpanded && isChildSelected)
      ? openIcon
      : item.unselectedIcon
  const endIcon = isSidebarExpanded ? (
    <Button
      variant="icon"
      color="tertiary"
      onClick={handleEndIconClick}
      size="small"
      sx={{ background: 'none', width: '24px !important', height: '24 !important', padding: '2px' }}
    >
      <Icon
        name={isItemOpen ? 'OArrowCDown' : 'OArrowCRight'}
        iconColor={isSelected ? theme.palette.Highlight.Secondary : theme.palette.Black.o32}
        width={20}
        height={20}
      />
    </Button>
  ) : undefined

  const expandedSx: SxProps = {
    width: '100%',
    justifyContent: 'space-between',
  }

  const compactSx: SxProps = {
    width: '40px',
  }

  const selectedSx: SxProps = {
    backgroundColor: `${theme.palette.Highlight.Disabled} !important`,
    '& svg>path': {
      fill: theme.palette.Highlight.PrimaryDark,
    },
    color: theme.palette.Highlight.PrimaryDark,
  }

  const childSelectedSx: SxProps = isSidebarExpanded
    ? {
        backgroundColor: `rgba(7, 26, 36, 0.06) !important`,
      }
    : selectedSx

  const openSx: SxProps = {
    backgroundColor: `rgba(7, 26, 36, 0.06) !important`,
  }

  return (
    <Box>
      <SidebarItemWrapper selected={isSelected || (!isSidebarExpanded && isChildSelected)}>
        <Button
          fullWidth
          variant="iconCustomRounded"
          onClick={handleItemClick}
          sx={{
            height: '40px',
            borderRadius: '8px',
            paddingLeft: '8px',
            paddingRight: '8px',
            transition: 'width 0.3s ease',

            '& span': {
              margin: '0px !important',
            },
            '& .DeckBtnWrapChild': {
              width: '100%',

              // These 3 styles make the text not jump as wraps/unwraps when the sidebar width changes
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              transition: 'width 0.3s ease',
            },
            ...(isItemOpen ? openSx : {}),
            ...(isSelected ? selectedSx : {}),
            ...(isChildSelected && (isItemOpen || !isSidebarExpanded || (!isItemOpen && isSidebarExpanded))
              ? childSelectedSx
              : {}),
            ...(isSidebarExpanded ? expandedSx : compactSx),
          }}
          startIcon={startIcon}
          endIcon={hasSubroutes && endIcon}
          title=""
        >
          {isSidebarExpanded ? (
            <Typography variant="label" pl="8px">
              {item.title}
            </Typography>
          ) : (
            ''
          )}
        </Button>
      </SidebarItemWrapper>
      {isItemOpen && <SidebarNestedItems items={item.subRoutes} />}
    </Box>
  )
}
