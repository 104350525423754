import { useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getRootPathString } from 'store/paginationPreservationSlice/selectors'
import { RIGHTS_QUEUE_PAGINATION_REDUX_KEY, setPage, setRootPathString } from 'store/paginationPreservationSlice'

/** helper hook to keep reduxRootPathString state in sync with locationRootPathString.
 * When they get out of sync, a sister hook in "createUsePaginatedQueryPreservedPage.ts" will
 * reset the page back to 0 but will do nothing if there is no change, thus preserving the page location. */
export const useSyncRootStringPath = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const reduxRootPathString = useAppSelector(getRootPathString)

  useEffect(() => {
    const locationRootPathString = [location.pathname.split('/')?.[1], location.pathname.split('/')?.[2]].join('/')

    const rootPathHasChanged = reduxRootPathString !== locationRootPathString

    if (rootPathHasChanged) {
      // we reset the pagination value if we leave rights queue route
      if (reduxRootPathString === RIGHTS_QUEUE_PAGINATION_REDUX_KEY) {
        dispatch(
          setPage({
            key: RIGHTS_QUEUE_PAGINATION_REDUX_KEY,
            value: 0,
          }),
        )
      }
      dispatch(setRootPathString(locationRootPathString))
    }
  }, [location, reduxRootPathString, dispatch])
}
