import React, { useState } from 'react'
import { Rect, Text, Group, RegularPolygon } from 'react-konva'

import * as CanvasConstants from 'pages/orchestration/workflows/edit/utils/constants'

export const WarningIcon: React.FC = () => {
  const [showTooltip, setShowTooltip] = useState(false)
  const position = {
    x: CanvasConstants.GRID_CELL_WIDTH,
    y: 0,
  }

  return (
    <Group x={position.x} y={position.y}>
      <RegularPolygon
        x={0}
        y={0}
        shadowColor="#212121"
        shadowBlur={4}
        shadowOffset={{ x: 0, y: 0 }}
        shadowOpacity={0.5}
        sides={3}
        radius={11}
        fill="#DD0000"
        stroke="#FFF"
        strokeWidth={1}
      />

      <Text text="!" position={{ x: -2, y: -6 }} align="center" fill="#FFFFFF" fontSize={11} fontStyle="bold" />

      <Rect
        x={-12}
        y={-12}
        width={24}
        height={20}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseOut={() => setShowTooltip(false)}
      />

      {showTooltip && (
        <Group x={-100} y={-35}>
          <Rect x={0} y={0} width={210} height={20} cornerRadius={4} fill="#616161" opacity={0.9} />
          <Text
            text="Edit to address configuration issues"
            position={{ x: 10, y: 5 }}
            size={10}
            align="center"
            fill="#FFFFFF"
            fontSize={11}
            fontStyle="bold"
          />
        </Group>
      )}
    </Group>
  )
}
