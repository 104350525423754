import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { mapValuesToPayload } from 'pages/policyCenter/policies/upsert/utils'
import { PolicyUpsertView } from 'pages/policyCenter/policies/upsert/PolicyUpsertView'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { PolicyFormValues } from 'interfaces/policies/PolicyFormValues'
import { useCreatePolicyV2 } from 'api/policies/mutations/useCreatePolicyV2'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { RoutesManager } from 'utils/routing/routesManager'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { usePolicyV2 } from 'api/policies/queries/usePolicyV2'
import { useUpdatePolicyV2 } from 'api/policies/mutations/useUpdatePolicyV2'
import { NEW_ITEM_ID } from 'utils/constants/misc'

export const PolicyUpsertViewContainer: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()

  const { mutate: handleCreatePolicy, isLoading: isCreatingPolicy } = useCreatePolicyV2()
  const { mutate: handleUpdatePolicy, isLoading: isUpdatingPolicy } = useUpdatePolicyV2()

  const { data: policy, isLoading: isLoadingPolicy } = usePolicyV2({
    enabled: id !== NEW_ITEM_ID,
    params: {
      code: id!,
    },
  })

  const { data: purposes, isLoading: isLoadingPurposes } = usePurposes()

  const onSubmit = async (values: PolicyFormValues) => {
    const formData = mapValuesToPayload({
      values,
    })

    if (id === NEW_ITEM_ID) {
      handleCreatePolicy({
        params: {
          formData,
        },
        onSuccess: async () => {
          showToast({ content: 'Policy created', type: 'success' })
          await queryClient.refetchQueries(ApiQueryKeys.policies)
          navigate(RoutesManager.policyCenter.policies.list.getURL())
        },
        onError: () => {
          showToast({ content: 'Failed to create policy', type: 'error' })
        },
      })
    } else {
      handleUpdatePolicy({
        params: {
          formData: {
            id,
            ...formData,
          },
          policyCode: id!,
        },
        onSuccess: async () => {
          showToast({ content: 'Policy was updated', type: 'success' })
          await queryClient.refetchQueries(ApiQueryKeys.policies)
          navigate(RoutesManager.policyCenter.policies.list.getURL())
        },
        onError: () => {
          showToast({ content: 'Failed to update policy', type: 'error' })
        },
      })
    }
  }

  return (
    <PolicyUpsertView
      isReady={!isLoadingPurposes || !(id !== NEW_ITEM_ID && isLoadingPolicy)}
      onSubmit={onSubmit}
      purposes={purposes}
      isCreatingPolicy={isCreatingPolicy || isUpdatingPolicy}
      isUpdatingPolicy={false}
      policy={policy}
    />
  )
}
