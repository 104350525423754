import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchPermitStatsTrend } from '../fetchers/fetchPermitStatsTrend'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.permitsStatsTrend,
  queryFn: fetchPermitStatsTrend,
  select: res => res?.data?.trends || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const usePermitStatsTrend = (config?: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      start: 0,
      limit: 1000,
      ...config?.params,
    },
  })
}
