import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
// TODO:BAC - will be needed when bulk upsert implemented
// import { useQueryClient } from 'react-query'

import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { getAssetDetailViewState } from 'store/assetDetailViewSlice/selectors'
import { MaybeNull } from 'interfaces'
import { setIsShowOnlySelected, setSearchQuery, setSearchString } from 'store/assetDetailViewSlice'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useDebounce } from 'react-use'
import { useResetBulkEditState } from '.'
import { useUpdateAssetLabelsV2 } from 'api/assets/mutations/useUpdateAssetLabelsV2'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useV2OrV3RelatedAssetsInfiniteBasedOnFeatureFlag } from 'api/assets/queries/useV2OrV3RelatedAssetsInfiniteBasedOnFeatureFlag'
// TODO:BAC - will be needed when bulk upsert implemented
// import { ApiQueryKeys } from 'api/common/queryKeys'

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export const useAssetDetailViewRelatedAssetsUtils = ({ assetSummary }: Props) => {
  // TODO:BAC - will be needed when bulk upsert implemented
  // const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const { selectedAssets, isShowOnlySelected, searchString, searchQuery, isAssignDataCategoriesModalOpen } =
    useAppSelector(getAssetDetailViewState)

  const { tab: resourceTypeCodeUrlParam } = useParams<{ tab: string }>()

  const {
    infiniteAssetsData: assets,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useV2OrV3RelatedAssetsInfiniteBasedOnFeatureFlag({
    params: {
      assetCode: assetSummary?.asset?.resource?.id || '',
      limit: 50,
      resourceTypeCode: resourceTypeCodeUrlParam || '',
      query: searchQuery || '',
    },
  })

  const assetsToDisplayInTable = useMemo(
    () =>
      isShowOnlySelected
        ? assets.filter(assetSummary => selectedAssets.includes(assetSummary?.asset?.resource?.id as string))
        : assets,
    [assets, isShowOnlySelected, selectedAssets],
  )

  useDebounce(() => dispatch(setSearchQuery(searchString || '')), 500, [searchString])

  useResetBulkEditState()

  const { mutate: handleUpdateAssetLabels, isLoading: isUpdatingAssetLabels } = useUpdateAssetLabelsV2({
    onSuccess: async () => {
      showToast({ content: 'Classifications updated', type: 'success' })
      // TODO:BAC - will be needed when bulk upsert implemented
      // await queryClient.refetchQueries([ApiQueryKeys.resource, { assetCode: assetId }])
    },
    onError: () => {
      showToast({ content: 'Failed to update classifications', type: 'error' })
    },
    onSettled: () => {},
  })

  return {
    assets,
    assetsToDisplayInTable,
    fetchNextPage,
    handleUpdateAssetLabels,
    hasNextPage,
    isAssignDataCategoriesModalOpen,
    isFetchingNextPage,
    isLoading,
    isShowOnlySelected,
    resourceTypeCodeUrlParam,
    searchString,
    selectedAssets,
    setIsShowOnlySelected,
    setSearchString,
    isUpdatingAssetLabels,
  }
}
