import { APIRequestParams } from 'api/common/utils'
import { ExperienceDTO } from 'interfaces/experiences/experience'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  experienceCode: string
  organizationCode: string
  includeIssues?: boolean
  languageCode?: string
}>

type Response = { experience: ExperienceDTO }

export const fetchExperience = ({
  organizationCode,
  includeMetadata,
  includeIssues,
  experienceCode,
  languageCode,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/experiences/${experienceCode}`,
      params: {
        filters: {
          ...(includeMetadata && { includeMetadata }),
          ...(includeIssues && { includeIssues }),
          ...(languageCode && { languageCode }),
        },
      },
    }),
  )
