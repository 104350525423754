import { Radio, TableCell } from '@ketch-com/deck'
import { useFormikContext } from 'formik'
import { CookieDTO } from 'interfaces/cookies/cookie'

export const cookiesLibraryViewCodeTableCellFormKeys = {
  cookieCode: 'cookieCode',
} as const

export const CookiesLibraryViewCodeTableCell = ({ row }: { row: CookieDTO }) => {
  const { values, setFieldValue } = useFormikContext<{ cookieCode: string }>()
  const isChecked = values.cookieCode === row.code

  return (
    <TableCell>
      <Radio
        checked={isChecked}
        value={isChecked}
        onChange={() => {
          if (isChecked) {
            setFieldValue(cookiesLibraryViewCodeTableCellFormKeys.cookieCode, null)
          } else {
            setFieldValue(cookiesLibraryViewCodeTableCellFormKeys.cookieCode, row.code)
          }
        }}
      />
    </TableCell>
  )
}
