import React, { HTMLAttributes } from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import { makeStyles, createStyles } from '@mui/styles'
import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'
import Info from '@mui/icons-material/Info'
import { SxProps, Theme } from '@mui/material'
// import { ReactComponent as QuestionMarkGrayIcon } from 'assets/icons/about_question_mark_gray.svg'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseLabel: {
        display: 'block',
        fontWeight: 600,
      },
      baseOptional: {
        fontSize: typography.pxToRem(14),
        lineHeight: typography.pxToRem(20),
        color: palette.darkDuskFaded.main,
      },
      // Colors:
      colorBlack: {
        color: palette.darkDusk.main,
      },
      colorGrey: {
        color: palette.darkDuskFaded.main,
      },
      sizeRegular: {
        fontSize: typography.pxToRem(14),
        marginBottom: 4,
      },
      sizeSmall: {
        fontSize: typography.pxToRem(12),
        marginBottom: 2,
      },
      tooltip: {
        display: 'inline-flex !important',
        verticalAlign: 'middle',
        marginLeft: 5,
      },
      tooltipContent: {
        maxWidth: 120,
      },
      withoutMarginBottom: {
        marginBottom: 0,
      },
    }),
  { name: 'Label' },
)

export interface Props extends HTMLAttributes<HTMLLabelElement> {
  children?: React.ReactNode
  /** ID of an element */
  id?: string
  /** Color of text element */
  color?: 'black' | 'grey'
  /** Show required asterisk sign */
  required?: boolean
  /** Label size */
  size?: 'regular' | 'medium' | 'small'
  /** Text to be displayed in the tooltip */
  info?: string
  /** Toggle margin below label */
  hasMarginBottom?: boolean
  /** Toggle "Optional" display */
  shouldShowOptional?: boolean
  /** Provide an alternate text for the "(Optional)" text */
  optionalOverrideText?: string
  /** SX Overrides */
  sx?: SxProps<Theme>
}

/**
 * -
 */
export const Label: React.FC<Props> = ({
  id,
  color = 'black',
  size = 'regular',
  required = true,
  info = '',
  children,
  className,
  shouldShowOptional,
  optionalOverrideText,
  sx,
  hasMarginBottom = true,
  ...rest
}) => {
  const classes = useStyles()

  const componentClassName = clsx(
    classes.baseLabel,
    {
      [classes.colorBlack]: color === 'black',
      [classes.colorGrey]: color === 'grey',
      [classes.sizeRegular]: size === 'regular',
      [classes.sizeSmall]: size === 'small',
      [classes.withoutMarginBottom]: !hasMarginBottom,
    },
    className,
  )

  return (
    <Box component="label" {...rest} htmlFor={id} className={componentClassName} sx={sx}>
      {children}{' '}
      {!required && shouldShowOptional && (
        <span className={classes.baseOptional}>
          {optionalOverrideText ? `(${optionalOverrideText})` : `(Optional)`}
        </span>
      )}
      {info && (
        <Tooltip className={classes.tooltip} tag="span" content={<div className={classes.tooltipContent}>{info}</div>}>
          <Info className={`${classes.colorGrey} ${classes.sizeRegular}`} />
        </Tooltip>
      )}
    </Box>
  )
}
