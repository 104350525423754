import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/ui-kit/toastr/Toastr'

import { useCreateAuthenticator } from 'api/authenticators/mutations/useCreateAuthenticator'
import { useUpdateAuthenticator } from 'api/authenticators/mutations/useUpdateAuthenticator'
import { useAuthenticator } from 'api/authenticators/queries/useAuthenticator'
import { CreateAuthenticatorFormValues } from '../utils/getAuthenticatorInitialValues'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { RoutesManager } from 'utils/routing/routesManager'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { mapAuthenticatorFormValuesToFormData } from '../utils'
import { useFormFields } from 'api/formFields/queries/useFormFields'
import { useContextVariablesPaginated } from 'api/contextVariables/queries/useContextVariablesPaginated'
import { ContextVariableCategoryDTO } from '@ketch-com/figurehead'

export const useAuthenticatorsUpsertViewUtils = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { id: authenticatorToEditId } = useParams<{ id: string }>()

  const isEditMode = authenticatorToEditId !== NEW_ITEM_ID

  const { data: authenticatorToEdit, isLoading: isLoadingAuthenticatorToEdit } = useAuthenticator({
    enabled: !!authenticatorToEditId && isEditMode,
    params: {
      authenticatorId: authenticatorToEditId || '',
    },
  })

  const { data: allFormFields, isLoading: isLoadingAllFormFields } = useFormFields({
    itemsPerPage: 1000,
    params: {
      includeMetadata: true,
    },
  })

  const { data: identitySpaces, isLoading: isLoadingIdentitySpaces } = useIdentitySpaces({
    params: {},
  })

  const { data: dataSubjectVariables, isLoading: isLoadingDataSubjectVariables } = useContextVariablesPaginated({
    itemsPerPage: 1000,
    params: {
      includeMetadata: true,
      category: ContextVariableCategoryDTO.DataSubjectContextVariableCategory,
    },
  })

  const { mutateAsync: handleCreateAuthenticator } = useCreateAuthenticator({
    onSuccess: async () => {
      showToast({ content: 'Authenticator created', type: 'success' })
      await queryClient.refetchQueries([ApiQueryKeys.authenticatorsPaginated])
      navigate(RoutesManager.developers.authenticators.list.getURL())
    },
  })

  const { mutateAsync: handleUpdateAuthenticator } = useUpdateAuthenticator({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.authenticatorsPaginated])
      showToast({ content: 'Authenticator updated', type: 'success' })
      navigate(RoutesManager.developers.authenticators.list.getURL())
    },
  })

  const handleSubmit = async (values: CreateAuthenticatorFormValues) => {
    const formData = mapAuthenticatorFormValuesToFormData(values)

    isEditMode
      ? await handleUpdateAuthenticator({
          onError: () => showToast({ content: 'Failed to update authenticator', type: 'error' }),
          onSuccess: () => showToast({ content: 'Authenticator updated', type: 'success' }),
          params: {
            formData,
            authenticatorId: authenticatorToEditId,
          },
        })
      : await handleCreateAuthenticator({
          onError: () => showToast({ content: 'Failed to create authenticator', type: 'error' }),
          onSuccess: () => {
            showToast({ content: 'Authenticator created', type: 'success' })
          },
          params: {
            formData,
          },
        })
  }

  const isReady =
    !isLoadingAuthenticatorToEdit &&
    !isLoadingIdentitySpaces &&
    !isLoadingAllFormFields &&
    !isLoadingDataSubjectVariables

  const payload = {
    isEditMode,
    authenticatorToEdit,
    allFormFields,
    isLoadingIdentitySpaces,
    isReady,
    handleSubmit,
    dataSubjectVariables,
    identitySpaces,
  }

  return payload
}

export type AuthenticatorsUpsertViewUtilsProps = ReturnType<typeof useAuthenticatorsUpsertViewUtils>
