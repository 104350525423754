export const ROLE_GROUPS = {
  Organization: 'org',
  DataMapAssets: 'data_map_assets',
  DataMapSystems: 'data_map_systems',
  DataMapProcessingActivities: 'data_map_processing_activities',
  ConsentAndRightsCapture: 'car_capture',
  ConsentAndRightsConfig: 'car_orch_config',
  ConsentAndRightsQueue: 'car_orch_queue',
  PrivacyProgramFoundations: 'privacy_program_foundations',
  PrivacyProgramRisk: 'privacy_program_risk',
  DeveloperTools: 'developer_tools',
}

export const ENTITLEMENT_CODES = {
  EXPERIENCE_SERVER: 'exp_server',
  EXPERIENCE_SERVER_RIGHTS: 'exp_server_rights',
  ORCHESTRATION_RIGHTS: 'orch_rights',
  ASSET_MANAGER: 'asset_manager',
}
