import { formatRequestString } from 'utils/helpers/api/queryString'
import { API } from 'utils/api/api'
import { CreateConnectionV2RequestBodyDTO, CreateConnectionV2ResponseBodyDTO } from '@ketch-com/figurehead'

type Params = {
  formData: CreateConnectionV2RequestBodyDTO
}

export const createConnection = ({ formData }: Params) =>
  API.post<CreateConnectionV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/connections`,
    }),
    formData,
  )
