import * as Yup from 'yup'

export const extendRequestFormKeys = {
  extensionDays: 'extensionDays',
  extensionReason: 'reason',
} as const

export const getValidationSchema = () =>
  Yup.object().shape({
    [extendRequestFormKeys.extensionDays]: Yup.number()
      .integer('Extension days must be a positive number')
      .positive('Extension days must be a positive number')
      .max(365, 'Maximum extension is 365 days')
      .required('Required'),
    [extendRequestFormKeys.extensionReason]: Yup.string().notRequired(),
  })
