import React from 'react'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { FormEditor, renderDocumentLinkerModal } from 'components/ui-kit/form/editor'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { useContext } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'

export const PreferenceExperienceRequestsTabSubmittedDescriptionForm: React.FC = () => {
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length

  return (
    <>
      <SidebarFormGroup hasBorderBottom={hasLanguages} sx={{ pt: 0 }}>
        <FormEditor
          label={'Description Text'}
          required={false}
          id={experienceUpsertFormKeys.preferenceRequestsTabSubmittedDescription.replaceAll('.', '_')}
          name={experienceUpsertFormKeys.preferenceRequestsTabSubmittedDescription}
          linkChangeModal={renderDocumentLinkerModal}
          charLimit={6000}
        />
      </SidebarFormGroup>
      <SidebarFormTranslations
        title={'Description Text Translations'}
        name={experienceUpsertFormKeys.preferenceRequestsTabSubmittedDescription}
        variant={'richText'}
        charLimit={6000}
      />
    </>
  )
}
