import {
  WorkflowActivityCode,
  WorkflowActivityConnectorCode,
  WorkflowActivityGatewayMode,
} from 'interfaces/workflowActivities/workflowActivity'
import { isUndefined, isNil, find } from 'lodash'
import { ICanvasStep } from '../interfaces'

export const getAreTransitionsValid = (steps: ICanvasStep[]) => {
  const doesStepsHaveUndefinedTransitionsArray = steps.reduce((acc, step, index) => {
    if (step.code === WorkflowActivityCode.START) {
      acc.push(!!step?.start?.transition)
      return acc
    }
    if (!isUndefined(step?.finish) || step.code === WorkflowActivityCode.END) {
      acc.push(true)
      return acc
    }
    if (step?.workflow?.temporalFunctionName === WorkflowActivityConnectorCode.END) {
      acc.push(true)
      return acc
    }
    if (!isUndefined(step?.activity)) {
      acc.push(!!step?.activity?.transition)
      return acc
    }
    if (!isUndefined(step?.workflow)) {
      acc.push(!isNil(step?.workflow?.transition))
      return acc
    }
    if (!isUndefined(step?.placeholder)) {
      acc.push(false)
      return acc
    }

    const isJoinGateway = step?.gateway?.mode === WorkflowActivityGatewayMode.JOIN

    if (!isUndefined(step?.gateway) && isJoinGateway) {
      acc.push(!isNil(step?.gateway?.transitions?.[0]?.ID))
      return acc
    }

    if (!isUndefined(step?.gateway) && !isJoinGateway) {
      const transitions = step?.gateway?.transitions || []

      const connectedTransitions = transitions.filter(item =>
        Boolean(item.ID && isUndefined(find(steps, { ID: item.ID })?.placeholder)),
      ).length

      const areAllTransitionsConnected = connectedTransitions >= transitions.length

      acc.push(areAllTransitionsConnected)
      return acc
    }

    return acc
  }, [] as boolean[])

  const areTransitionsValid = !doesStepsHaveUndefinedTransitionsArray.includes(false)

  return areTransitionsValid
}
