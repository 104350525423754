import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createContextVariable } from 'api/contextVariables/fetchers/createContextVariable'

const useCustomMutation = createUseMutation({
  mutationFn: createContextVariable,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateContextVariable = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
