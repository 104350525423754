import React from 'react'
import { FormFieldsListContextProvider } from 'pages/consentAndRights/forms/fields/list/context/FormFieldsListContext'

export const withFormFieldsListContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <FormFieldsListContextProvider>
        <Component {...props} />
      </FormFieldsListContextProvider>
    )
  }
}
