import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { uploadFile, uploadFiles } from 'api/files/fetchers/uploadFile'

const useCustomMutation = createUseMutation({
  mutationFn: uploadFile,
})
const useMultipleFilesUploadCustomMutation = createUseMutation({
  mutationFn: uploadFiles,
})

type Config = CustomMutationConfig<typeof useCustomMutation>
type MultiFileConfig = CustomMutationConfig<typeof useMultipleFilesUploadCustomMutation>

export const useUploadFile = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}

export const useUploadFiles = (config?: MultiFileConfig) => {
  const mutation = useMultipleFilesUploadCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
