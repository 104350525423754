import { FormSectionSwitch } from '../FormSectionSwitch'
import { ExperienceBuilderSectionLabels } from '../../../builder/utils/labels'
import { PreferenceExperienceBuilderSection } from '../../../builder/utils/enums'
import Box from '@mui/material/Box'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { FormTextInput } from '../FormTextInput'
import { experienceUpsertFormKeys } from '../../../../utils/useExperienceValidationSchema'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { useContext } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'

export const PreferenceExperiencePurposesTabHeaderTitleForm: React.FC = () => {
  const { values } = useFormikContext<ExperienceV2DTO>()
  const showForm = getIn(values, experienceUpsertFormKeys.preferencePurposesTabHeaderVisible)
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length

  return (
    <>
      <FormSectionSwitch
        name={experienceUpsertFormKeys.preferencePurposesTabHeaderVisible}
        title={ExperienceBuilderSectionLabels[PreferenceExperienceBuilderSection.PurposesTabHeaderTitle]}
      />
      {showForm && (
        <Box p={'16px 18px'}>
          <SidebarFormGroup hasBorderTop={false} sx={{ paddingTop: 0 }} hasBorderBottom={hasLanguages}>
            <FormTextInput
              label={'Page Title Text'}
              name={experienceUpsertFormKeys.preferencePurposesTabHeaderTitle}
              fullWidth
              charLimit={200}
            />
          </SidebarFormGroup>
          <SidebarFormTranslations
            title={'Page Title Text Translations'}
            name={experienceUpsertFormKeys.preferencePurposesTabHeaderTitle}
            variant={'text'}
            charLimit={200}
          />
        </Box>
      )}
    </>
  )
}
