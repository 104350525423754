import { ProcessingActivitiesContext, withProcessingActivitiesContext } from './context/ProcessingActivitiesContext'
import { ProcessingActivitiesHeader } from './components/ProcessingActivitiesHeader'
import { ProcessingActivitiesFilters } from './components/ProcessingActivitiesFilters'
import { ProcessingActivitiesList } from './components/ProcessingActivitiesList'
import Box from '@mui/material/Box'
import { useContext } from 'react'
import { NoProcessingActivities } from './components/NoProcessingActivities'
import { ProcessingActivitiesSummary } from './components/ProcessingActivitiesSummary'
import { ListLayout, Spinner } from '@ketch-com/deck'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'

const ProcessingActivitiesWithContext: React.FC = () => {
  const { hasProcessingActivities, hasFilters, isLoading, isProcessingActivitiesRefetching } =
    useContext(ProcessingActivitiesContext)

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Processing Activities', link: RoutesManager.policyCenter.processingActivities.root.getURL() },
  ]

  return (
    <ListLayout
      headerTitle="Processing Activities"
      headerProps={{
        isDivider: true,
      }}
      customHeaderRightActionComponent={<ProcessingActivitiesHeader />}
    >
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" flex={1} height="100%">
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : // Ensures we don't show the no processing activities art when the list is empty or refetching due to a filter

      hasProcessingActivities || hasFilters || isProcessingActivitiesRefetching ? (
        <>
          {/* Summary tiles */}
          <ProcessingActivitiesSummary />

          {/* Filters */}
          <ProcessingActivitiesFilters />

          {/* Processing Activities Card List */}
          <ProcessingActivitiesList />
        </>
      ) : (
        <>
          {/* Empty state */}
          <NoProcessingActivities />
        </>
      )}
    </ListLayout>
  )
}

export const ProcessingActivities = withProcessingActivitiesContext(ProcessingActivitiesWithContext)
