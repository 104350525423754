import * as Yup from 'yup'

export const getValidationSchema = () =>
  Yup.object().shape({
    datasetCode: Yup.string().required('Required'),
    referenceDatasetCode: Yup.string()
      .required('Required')
      .test('Reference Dataset Code Validation', 'Cannot be the same as Dataset Code', (value, context: any) => {
        const [{ value: formValue }] = context.from
        const isValid = value !== formValue.datasetCode
        return isValid
      }),
    relations: Yup.array().of(
      Yup.object().shape({
        foreignKey: Yup.string().required('Required'),
        primaryKey: Yup.string()
          .required('Required')
          .test(
            'foreign and primary key cannot be the same',
            'Cannot be the same as Foreign Key',
            (value, context: any) => {
              const [{ value: formValue }] = context.from
              const isValid = formValue.primaryKey !== formValue.foreignKey
              return isValid
            },
          ),
      }),
    ),
  })
