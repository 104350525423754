import { FindTeams200, FindTeamsParams } from 'interfaces/services/harbormaster/teams_gen.schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchTeams = ({ start, limit, query, ids, memberIds, includeAssignee }: FindTeamsParams) =>
  API.get<FindTeams200>(
    formatRequestString({
      entityUrl: `/api/teams`,
      params: {
        filters: {
          ...(start && { start }),
          ...(limit && { limit }),
          ...(query && { query }),
          ...(ids && { ids }),
          ...(memberIds && { memberIds }),
          ...(includeAssignee && { includeAssignee }),
        },
      },
    }),
  )
