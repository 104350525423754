import { useEffect, useState } from 'react'
import { AppNavigationSize } from '../types'

// Maximum width for each AppNavigationSize value
const breakpointWidths = {
  [AppNavigationSize.Hidden]: 1342,
  [AppNavigationSize.Compact]: 1500,
  [AppNavigationSize.FullSize]: Infinity,
}

/**
 * Custom hook for monitoring the app navigation container width
 * @returns Current app navigation size
 */
export const useAppNavigationSize = () => {
  const [appNavigationSize, setAppNavigationSize] = useState<AppNavigationSize>(AppNavigationSize.Hidden)

  useEffect(() => {
    /**
     * Update navigation size state whenever one of the boundaries is crossed
     */
    const handleResize = () => {
      const width = window.innerWidth
      if (width < breakpointWidths[AppNavigationSize.Hidden]) {
        setAppNavigationSize(AppNavigationSize.Hidden)
      } else if (width < breakpointWidths[AppNavigationSize.Compact]) {
        setAppNavigationSize(AppNavigationSize.Compact)
      } else {
        setAppNavigationSize(AppNavigationSize.FullSize)
      }
    }

    // Add event listener
    window.addEventListener('resize', handleResize)
    handleResize()

    // Tear down event listener when component removed from DOM
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return appNavigationSize
}
