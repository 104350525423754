import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { Typography } from '@mui/material'
import { ApiCallRemovalWarningMessageConfig } from '../ApiCallForm'
import { Table } from 'components/ui-layouts/table/Table'
import { EditWorkflowContext } from 'pages/orchestration/workflows/edit/contexts/EditWorkflowContext'
import { useContext } from 'react'

type Props = {
  removalWarningConfig?: ApiCallRemovalWarningMessageConfig[]
  setRemovalWarningConfig: React.Dispatch<React.SetStateAction<ApiCallRemovalWarningMessageConfig[]>>
}

export const ApiCallTileRemovalWarningModal: React.FC<Props> = ({ removalWarningConfig, setRemovalWarningConfig }) => {
  const { outcomeVariables } = useContext(EditWorkflowContext)
  return (
    <Dialog
      isOpen={!!removalWarningConfig?.length}
      isSaving={false}
      title="Warning"
      cancelBtnLabel="Close"
      contentWidth={700}
      onCancelBtnClick={() => setRemovalWarningConfig([])}
    >
      <Typography mb={3}>
        Before removing the API tile, please remove all corresponding Gateway dependencies.
      </Typography>

      <Table
        pending={false}
        items={removalWarningConfig || []}
        isSmallHeaderText
        cellSettings={{
          variable: {
            label: 'Variable',
            cellRenderer: ({ transition }) => {
              const outcomeVariable = outcomeVariables.find(({ code }) => code === transition.variable)
              return <Typography variant="body">{outcomeVariable?.name}</Typography>
            },
          },
          task: {
            label: 'Task',
            cellRenderer: ({ apiCallStep }) => <Typography variant="body">{apiCallStep.description}</Typography>,
          },
          gateway: {
            label: 'Gateway',
            cellRenderer: ({ workflowStep }) => <Typography variant="body">{workflowStep.description}</Typography>,
          },
        }}
      />
    </Dialog>
  )
}
