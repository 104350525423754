import { FileInfoDTO } from 'interfaces/files/fileInfo'

export type WorkflowExecutionAppStepFormValues = {
  resolutionDetails: string | undefined
  resolutionAttachments: FileInfoDTO[] | undefined
}

export type StepDetailsExecutionDataPermits = {
  [key: string]: string
}

export const SetPermitsForPurposeValues = {
  0: 'Unknown',
  1: 'Do Nothing',
  2: 'Opt Out',
  3: 'Opt In',
}
