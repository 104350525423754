import React from 'react'
import Box from '@mui/material/Box'
import { useFormikContext } from 'formik'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { PreferenceField } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { UpsertExperienceEditableOverlay } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEditableOverlay'
import { UpsertExperienceEmptyField } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEmptyField'
import { UpsertExperiencePreviewButton } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewButton'
import { UpsertExperiencePreviewText } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewText'
import { useUpsertPreferenceStyles } from 'pages/consentAndRights/experiences/upsert/components/UpsertPreference/hooks'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'

type Props = {
  getOverlayProps: (name: PreferenceField) => {
    active: boolean
    onClick: () => void
  }
}

interface StyledTypographyProps {
  isUnderlined?: boolean
}

const DisclosureTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isUnderlined',
})<StyledTypographyProps>(({ isUnderlined }) => ({
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '-0.12px',
  textDecoration: isUnderlined ? 'underline' : 'none',
}))

export const ActionButtons: React.FC<Props> = ({ getOverlayProps }) => {
  const classes = useUpsertPreferenceStyles()

  const { values } = useFormikContext<PreferenceExperienceFormValues>()
  const { rights } = values

  return (
    <Box p={3}>
      <UpsertExperienceEditableOverlay {...getOverlayProps(PreferenceField.RIGHTS_FOOTER_BUTTONS)}>
        <Box p={1}>
          <Box display="flex" gap={2} mb={1}>
            {rights?.buttonText ? (
              <UpsertExperiencePreviewButton className={classes.acceptButton}>
                <UpsertExperiencePreviewText size="small" color="white" bold>
                  {rights.buttonText}
                </UpsertExperiencePreviewText>
              </UpsertExperiencePreviewButton>
            ) : (
              <UpsertExperienceEmptyField required button size="small">
                Submit Button
              </UpsertExperienceEmptyField>
            )}

            <UpsertExperiencePreviewButton className={classes.denyButton} inverted>
              <UpsertExperiencePreviewText bold size="small" color="blue">
                Cancel
              </UpsertExperiencePreviewText>
            </UpsertExperiencePreviewButton>
          </Box>

          {/* Required recaptcha disclosure */}

          <Box display="flex" alignItems="center">
            {values?.rights?.recaptchaEnabled ? (
              <DisclosureTypography>
                This site is protected by reCAPTCHA and the Google{' '}
                <DisclosureTypography isUnderlined>Privacy Policy</DisclosureTypography> and{' '}
                <DisclosureTypography isUnderlined>Terms of Service</DisclosureTypography> apply.
              </DisclosureTypography>
            ) : (
              <DisclosureTypography color="darkDuskFaded.main">Enable reCAPTCHA</DisclosureTypography>
            )}
          </Box>
        </Box>
      </UpsertExperienceEditableOverlay>
    </Box>
  )
}
