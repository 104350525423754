import {
  Operation,
  OperationTypesWithDesc,
  Rule,
} from 'pages/consentAndRights/applications/upsert/components/ApplicationPredicates.types'
import { Grid } from '@mui/material'
import { Chip } from '@ketch-com/deck'
import { ChevronRight } from '@mui/icons-material'

type Props = {
  rule: Rule
  index: number
}
export default function ViewRule({ rule, index }: Props) {
  const detailValue = rule.variableName ? `${rule.variableName} = ${rule.details}` : rule.details
  return (
    <Grid container spacing={1}>
      {index > 0 && (
        <Grid item xs={12} mb={1}>
          <Chip label={rule.logicOp} color={'secondary'} />
        </Grid>
      )}
      <Grid item xs={6} md={3}>
        <Chip label={OperationTypesWithDesc[rule.type]} secondaryLabel={'Type'} />
      </Grid>
      <Grid item xs={1}>
        <ChevronRight color="disabled" />
      </Grid>
      {Operation[rule.operation] && (
        <Grid item xs={6} md={3}>
          <Chip label={Operation[rule.operation].label} secondaryLabel={'Operation'} />
        </Grid>
      )}
      {rule.details && (
        <>
          <Grid item xs={1} textAlign="center">
            <ChevronRight color="disabled" />
          </Grid>
          <Grid item xs={6} md={3}>
            <Chip label={detailValue} secondaryLabel={Operation[rule.operation].detailLabel} />
          </Grid>
        </>
      )}
    </Grid>
  )
}
