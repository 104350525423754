import { APIListRequestParams } from 'api/common/utils'
import { GetPermitStatsPurposeResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  startDate?: string
  endDate?: string
  environment?: string
  identitySpace?: string
  jurisdiction?: string
  property?: string
  purpose?: string
}>

export const fetchPermitStatsPurpose = ({
  startDate,
  endDate,
  environment,
  identitySpace,
  jurisdiction,
  limit,
  property,
  purpose,
  start,
}: Params) =>
  API.get<GetPermitStatsPurposeResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/permit-stats/purpose`,
      params: {
        filters: {
          start,
          limit,
          ...(startDate && { startDate }),
          ...(endDate && { endDate }),
          ...(jurisdiction && {
            jurisdiction,
          }),
          ...(identitySpace && {
            identitySpace,
          }),
          ...(purpose && {
            purpose,
          }),
          ...(property && {
            property,
          }),
          ...(environment && {
            environment,
          }),
        },
      },
    }),
  )
