export enum ExperienceSwitchRenderLogic {
  RENDER_ALL = 1,
  RENDER_LEGAL_BASIS = 2,
  RENDER_CHANGE = 3,
  RENDER_NEVER = 4,
}

export const EXPERIENCE_SWITCH_RENDER_LOGIC_NAMES = {
  // always renders the switch text regardless of state
  [ExperienceSwitchRenderLogic.RENDER_ALL]: 'Always Renders',

  // renders the switch text only if different from the legal basis default
  [ExperienceSwitchRenderLogic.RENDER_LEGAL_BASIS]: 'Renders only if different from the legal basis default',

  // renders the switch only if the user changes the state of the switch
  [ExperienceSwitchRenderLogic.RENDER_CHANGE]: '<Not Implemented>',

  // never renders the switch text
  [ExperienceSwitchRenderLogic.RENDER_NEVER]: 'Never renders',
}

export const EXPERIENCE_SWITCH_RENDER_LOGIC_OPTIONS = [
  {
    id: ExperienceSwitchRenderLogic.RENDER_ALL,
    name: EXPERIENCE_SWITCH_RENDER_LOGIC_NAMES[ExperienceSwitchRenderLogic.RENDER_ALL],
  },
  {
    id: ExperienceSwitchRenderLogic.RENDER_LEGAL_BASIS,
    name: EXPERIENCE_SWITCH_RENDER_LOGIC_NAMES[ExperienceSwitchRenderLogic.RENDER_LEGAL_BASIS],
  },
  /*
  {
    id: ExperienceSwitchRenderLogic.RENDER_CHANGE,
    name: EXPERIENCE_SWITCH_RENDER_LOGIC_NAMES[ExperienceSwitchRenderLogic.RENDER_CHANGE],
  },
  */
  {
    id: ExperienceSwitchRenderLogic.RENDER_NEVER,
    name: EXPERIENCE_SWITCH_RENDER_LOGIC_NAMES[ExperienceSwitchRenderLogic.RENDER_NEVER],
  },
]

export const EXPERIENCE_SUBSCRIPTIONS_SWITCH_RENDER_LOGIC_OPTIONS = [
  {
    id: ExperienceSwitchRenderLogic.RENDER_ALL,
    name: EXPERIENCE_SWITCH_RENDER_LOGIC_NAMES[ExperienceSwitchRenderLogic.RENDER_ALL],
  },
  {
    id: ExperienceSwitchRenderLogic.RENDER_NEVER,
    name: EXPERIENCE_SWITCH_RENDER_LOGIC_NAMES[ExperienceSwitchRenderLogic.RENDER_NEVER],
  },
]
