import { createContext } from 'react'
import { TrackersFilterUtils } from 'pages/policyCenter/trackers/interfaces'
import { useTrackersFilterUtils } from 'pages/policyCenter/trackers/hooks'

export const TrackersFilterContext = createContext({} as TrackersFilterUtils)

type Props = {
  children?: React.ReactNode
}

export const TrackersFilterContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useTrackersFilterUtils()

  return (
    <TrackersFilterContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </TrackersFilterContext.Provider>
  )
}

export const withTrackersFilterContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <TrackersFilterContextProvider>
        <Component {...props} />
      </TrackersFilterContextProvider>
    )
  }
}
