import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { CookieDTO } from 'interfaces/cookies/cookie'

type Params = APIRequestParams<{
  cookieCode: string
  organizationCode: string
  version: string
  includeIssues?: boolean
}>

type Response = { cookie: CookieDTO }

export const fetchCookieVersion = ({ organizationCode, cookieCode, version, includeMetadata, includeIssues }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/cookies/${cookieCode}/versions/${version}`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
        },
      },
    }),
  )
