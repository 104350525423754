import { PutDmlHookRequestBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  formData: PutDmlHookRequestBodyDTO
  hookId?: string
}

type Response = {}

export const updateDmlHook = ({ formData, hookId }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/dml-hooks/${hookId}`,
    }),
    formData,
  )
