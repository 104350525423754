import React, { useCallback, useContext } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { FieldArray, useFormikContext } from 'formik'
import Box from '@mui/material/Box'

import { Button } from '@ketch-com/deck'
import { CheckboxOptionDTO, FormFieldFormDTO } from 'pages/consentAndRights/forms/fields/upsert/interfaces'
import { DraggableCheckboxItem } from '.'
import { FormFieldContext } from '../../../context/FormFieldUpsertContextProvider'

export const reorderCheckboxOptions = (list: CheckboxOptionDTO[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

type Props = {}

export const CheckboxInputConfig: React.FC<Props> = () => {
  const [expandedTranslations, setExpandedTranslations] = React.useState<string[]>([])
  const { values, setFieldValue } = useFormikContext<FormFieldFormDTO>()
  const { getDummyDropdownItem } = useContext(FormFieldContext)

  const onDragEnd = useCallback(
    (result: DropResult) => {
      // handle item dropped outside the list area
      if (!result.destination) return
      const startIndex = result.source.index
      const endIndex = result.destination.index
      const newItems = reorderCheckboxOptions(values?.checkboxOptions || [], startIndex, endIndex)
      setFieldValue('checkboxOptions', newItems)
    },
    [setFieldValue, values?.checkboxOptions],
  )

  return (
    <Box display={(values.inputType as any) === 'checkbox' ? 'flex' : 'none'} flexDirection="column" p={2.25}>
      <FieldArray
        name="checkboxOptions"
        render={({ push, remove }) => (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => {
                const { innerRef, droppableProps } = provided
                return (
                  <Box {...droppableProps} ref={innerRef}>
                    {(values?.checkboxOptions || []).map((option, checkboxOptionIndex) => {
                      const isTranslationExpanded = expandedTranslations.includes(option.id)
                      const isDragDisabled = (values?.checkboxOptions || [])?.length === 1

                      return (
                        <Draggable
                          key={option.id}
                          draggableId={option?.id || ''}
                          index={checkboxOptionIndex}
                          isDragDisabled={isDragDisabled}
                        >
                          {(provided, snapshot) => (
                            <DraggableCheckboxItem
                              key={option.id}
                              checkboxOptionIndex={checkboxOptionIndex}
                              isTranslationExpanded={isTranslationExpanded}
                              remove={remove}
                              setExpandedTranslations={setExpandedTranslations}
                              provided={provided}
                              snapshot={snapshot}
                              option={option}
                            />
                          )}
                        </Draggable>
                      )
                    })}

                    {provided.placeholder}

                    {/* Add Item Button */}

                    <Box display="flex" alignItems="center">
                      <Button color="secondary" onClick={() => push(getDummyDropdownItem())}>
                        Add Checkbox
                      </Button>
                    </Box>
                  </Box>
                )
              }}
            </Droppable>
          </DragDropContext>
        )}
      />
    </Box>
  )
}
