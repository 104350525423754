import React from 'react'
import { Box } from '@mui/material'

import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'

import { AttributeChip } from '../components'
import { useTreeStyles } from '../hooks'
import { capitalizeFirstLetterOfString } from 'utils/text/stringUtils'

type Props = {
  policy: AssetPolicyDTO
}

export const ApplyFiltersSection: React.FC<Props> = ({ policy }) => {
  const classes = useTreeStyles()
  const { rowFilters } = policy

  if (!rowFilters?.length) {
    return null
  }

  return (
    <>
      <Box display="flex" alignItems="center" mb={4} mt={5}>
        <Text size={14} weight={600}>
          Apply Filters:
        </Text>
      </Box>

      <Box className={classes.treeContainer}>
        {(rowFilters || []).map(filter => (
          <Box key={JSON.stringify(filter)} className={classes.treeNodeSVG}>
            <Box className={classes.treeChild}>
              <Box display="flex" flexDirection="column">
                <Text size={14} weight={500} color="darkDuskFaded">
                  Show attribute...
                </Text>

                <Box display="flex" alignItems="center" my={1}>
                  <AttributeChip attributeName={filter.attributeName} />
                </Box>

                <Text size={14} weight={500} color="darkDuskFaded">
                  ... if their value is
                </Text>

                <Box display="flex" alignItems="center" mt={1}>
                  <Text size={14} weight={500} color="black">
                    {capitalizeFirstLetterOfString(filter?.condition?.split('_').join(' ') || '')}:
                  </Text>
                  &nbsp;
                  <Text size={14} weight={500} color="darkDuskFaded">
                    {filter?.value}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  )
}
