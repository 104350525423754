import React from 'react'
import { Box, Typography } from '@mui/material'

import { makeStyles, createStyles } from '@mui/styles'

import { Button } from '@ketch-com/deck'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      container: {
        background: palette.white.main,
        borderRadius: 11,
        width: '100%',
      },
      cardTitle: {
        color: palette.darkDuskFaded.main,
        letterSpacing: '0.13em',
        marginBottom: spacing(1),
        textTransform: 'uppercase',
      },
      orgCardHeaderRow: {
        borderBottom: `1px solid ${palette.iron.main}`,
        paddingBottom: spacing(2),
        paddingLeft: spacing(2),
      },
    }),
  { name: 'ReturnFromStripeCard' },
)

type Props = {
  onHideReturnFromStripeMessage: () => void
}

export const ReturnFromStripeCard: React.FC<Props> = ({ onHideReturnFromStripeMessage }) => {
  const classes = useStyles()

  return (
    <Box pt={2} px={2} mb={2} className={classes.container}>
      <Box display="flex" className={classes.orgCardHeaderRow} justifyContent="space-between" alignItems="center">
        <Box display="flex" flexDirection="column">
          <Typography variant="footnote" className={classes.cardTitle}>
            Notice
          </Typography>
          <Box display="flex" flexDirection="row">
            <Box mr={1}>
              <Typography variant="h3">Plan Notice</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box p={2} display="flex">
        <Typography variant="body">
          If you have updated your subscription plan it may take about 10-15 minutes for the changes to be reflected in
          the system. Please refresh this page after waiting.
        </Typography>
      </Box>

      <Box p={2} display="flex">
        <Button
          onClick={() => {
            onHideReturnFromStripeMessage()
          }}
          color="primary"
        >
          Got it
        </Button>
      </Box>
    </Box>
  )
}
