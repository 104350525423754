import { find, get } from 'lodash'

import { ICanvasStep, ICoordinates } from 'pages/orchestration/workflows/edit/interfaces'
import { getNestedTransitionsAmount } from './nestedTransitionsAmount'

interface IGetGatewayTransitionPosition {
  gridPosition: ICoordinates
  index: number
  step: ICanvasStep
  steps: Array<ICanvasStep>
  transitions?: Array<{
    ID?: number | string
  }>
}
export const getGatewayTransitionPosition = (params: IGetGatewayTransitionPosition): ICoordinates => {
  const { index, gridPosition, steps, transitions } = params
  const positionToReturn = {
    x: gridPosition.x + 2,
    y: gridPosition.y,
  }
  let connectedCells = 0

  if (!index) {
    return positionToReturn
  }

  for (let i = 0; i < index; i++) {
    const connectedStepId = get(transitions![i], 'ID')
    const connectedStep = find(steps, { ID: connectedStepId! })
    const connectedCellsCount = getNestedTransitionsAmount({
      step: connectedStep!,
      steps,
    })

    // We have -1 to skip 1 item on the same Y axis
    connectedCells += connectedCellsCount - 1
  }

  if (!connectedCells) {
    positionToReturn.y = gridPosition.y + index * 2
  } else {
    positionToReturn.y = gridPosition.y + index * 2 + connectedCells * 2
  }

  return positionToReturn
}
