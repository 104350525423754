import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { Tracker, UpdateTrackerPropertyBlockingBody } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

export type UpsertTrackerPropertyBlockingParams = APIRequestParams<{
  data: UpdateTrackerPropertyBlockingBody
  encodedTrackerKey: string
  propertyId: string
}>

export const upsertTrackerPropertyBlocking = ({
  data,
  encodedTrackerKey,
  propertyId,
}: UpsertTrackerPropertyBlockingParams) => {
  return API.put<Tracker>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/trackers/${encodedTrackerKey}/block/${propertyId}`,
    }),
    data,
  )
}
