import { Button, DataGrid, PopUp, TableCell } from '@ketch-com/deck'
import { WorkflowExecutionStepDTO } from '@ketch-com/figurehead'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { renderIcon } from '../utils/renderIcon'
import { getBackgroundColorFromCategory } from '../hooks/useCurrentActivityCellRendererUtils'
import { Typography } from '@mui/material'
import { AssigneeCellRenderer } from './AssigneeCellRenderer'
import { WorkflowExecutionStepStatusRendererV3 } from 'pages/orchestration/rightsQueue/viewV2/components/RightsQueueWorkflowTab/components/WorkflowExecutionStepStatusRendererV3'
import { ActivityTimerChip } from 'pages/orchestration/rightsQueue/components/ActivityTimerChip'

type Props = {
  steps: WorkflowExecutionStepDTO[]
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  name?: string
  rightInvocationId?: string
  workflowExecutionId?: string
}

export const MultipleStepInfoModal: React.FC<Props> = ({
  steps,
  onClose,
  name,
  rightInvocationId,
  workflowExecutionId,
}) => {
  const navigate = useNavigate()

  const rows = steps.map(step => ({
    id: step.stepID,
    name: step.stepName,
    icon: step.icon,
    assignee: step.assignee,
    status: step.status,
    dueAt: step.dueAt,
    category: step.activityCategory,
  }))

  type RowType = (typeof rows)[0]

  const gridColumns = [
    {
      field: 'Activity',
      headerName: 'Activity',
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowType>) => (
        <TableCell columnGap={1.5}>
          {renderIcon(
            params?.row?.name,
            getBackgroundColorFromCategory(params.row.category),
            params?.row?.icon?.url,
            'large',
          )}
          <Typography variant="label">{params.row.name}</Typography>
          {params?.row?.dueAt ? <ActivityTimerChip dueAt={params?.row?.dueAt} /> : null}
        </TableCell>
      ),
      minWidth: 280,
      sortable: false,
    },
    {
      field: 'Assignee',
      headerName: 'Assignee',
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowType>) => (
        <AssigneeCellRenderer assigneeId={params?.row?.assignee || ''} />
      ),
      minWidth: 244,
      sortable: false,
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowType>) => (
        <TableCell>
          <WorkflowExecutionStepStatusRendererV3 status={params?.row?.status || 0} />
        </TableCell>
      ),
      minWidth: 139,
      sortable: false,
    },
    {
      field: 'Link',
      headerName: '',
      renderCell: (params: GridRenderCellParams<RowType>) => (
        <TableCell>
          <Button
            color="tertiary"
            onClick={() =>
              navigate(
                RoutesManager.orchestration.rightsQueue.stepDetails.root.getURL({
                  id: rightInvocationId || '',
                  stepId: params?.row?.id || '',
                  workflowExecutionId: workflowExecutionId || '',
                }),
              )
            }
          >
            View
          </Button>
        </TableCell>
      ),
      width: 102,
      sortable: false,
    },
  ]

  return (
    <PopUp
      variant="modal"
      onClose={onClose}
      showCloseButton
      title={`Current Activities${name ? ` for ${name}` : ''}`}
      subTitle={rightInvocationId ? `Request ID: ${rightInvocationId}` : undefined}
      popUpActionChildren={
        <>
          <Button size="large" onClick={e => onClose(e)} color="secondary">
            Close
          </Button>
          <Button
            size="large"
            onClick={() =>
              navigate(RoutesManager.orchestration.rightsQueue.view.overview.root.getURL({ id: rightInvocationId }))
            }
          >
            View Request
          </Button>
        </>
      }
    >
      <DataGrid
        rowCount={steps.length}
        rows={rows}
        columns={gridColumns}
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableChildrenSorting
        disableColumnResize
        hideFooterRowCount
        disableRowHoverStates
        disableRowSelectionOnClick
        hideFooter
      />
    </PopUp>
  )
}
