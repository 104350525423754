import clsx from 'clsx'
import { styled } from '@mui/material/styles'

import { ThemeBannerPosition } from 'interfaces/themes-v2/themeBannerPosition'

const PREFIX = 'BannerPositionVisualization'

const classes = {
  root: `${PREFIX}-root`,
  position: `${PREFIX}-position`,
  top: `${PREFIX}-top`,
  bottom: `${PREFIX}-bottom`,
  bottomLeft: `${PREFIX}-bottomLeft`,
  bottomRight: `${PREFIX}-bottomRight`,
}

const Root = styled('div')(({ theme: { palette } }) => ({
  [`&.${classes.root}`]: {
    position: 'relative',
    width: 36,
    height: 26,
    border: `2px solid ${palette.darkDusk.main}`,
  },

  [`& .${classes.position}`]: {
    position: 'absolute',
    backgroundColor: palette.white.main,
  },

  [`& .${classes.top}`]: {
    width: '100%',
    height: 10,
    top: 0,
    borderBottom: `2px solid ${palette.darkDusk.main}`,
  },

  [`& .${classes.bottom}`]: {
    bottom: 0,
    width: '100%',
    height: 10,
    borderTop: `2px solid ${palette.darkDusk.main}`,
  },

  [`& .${classes.bottomLeft}`]: {
    left: 4,
    bottom: 4,
    width: 10,
    height: 10,
    border: `2px solid ${palette.darkDusk.main}`,
  },

  [`& .${classes.bottomRight}`]: {
    right: 4,
    bottom: 4,
    width: 10,
    height: 10,
    border: `2px solid ${palette.darkDusk.main}`,
  },
}))

type Props = {
  position: ThemeBannerPosition
}

export const BannerPositionVisualization = ({ position }: Props) => {
  return (
    <Root className={classes.root}>
      <div
        className={clsx(classes.position, {
          [classes.bottom]: position === ThemeBannerPosition.BOTTOM,
          [classes.top]: position === ThemeBannerPosition.TOP,
          [classes.bottomLeft]: position === ThemeBannerPosition.BOTTOM_LEFT,
          [classes.bottomRight]: position === ThemeBannerPosition.BOTTOM_RIGHT,
        })}
      />
    </Root>
  )
}
