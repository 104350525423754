// layout
import { ProtectedRoute } from 'components/ui-routing/ProtectedRoute'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

// pages
import { TranspondersListContainer } from './transponders/list/TranspondersListContainer'
import { TransponderViewContainer } from './transponders/view/TransponderViewContainer'
import { TranspondersUpsertContainer } from './transponders/upsert/TranspondersUpsertContainer'
import { ApiKeyListContainer } from 'pages/developers/apiKeys/list/ApiKeyListContainer'
import { ApiKeyUpsertContainer } from 'pages/developers/apiKeys/upsert/ApiKeyUpsertContainer'
import { ApiKeyViewContainer } from 'pages/developers/apiKeys/view/ApiKeyViewContainer'
import { RoutesManager } from 'utils/routing/routesManager'
import { HooksListContainer } from './hooks/list/HooksListContainer'
import { WebhooksListContainer } from './webhooks/list/WebhooksListContainer'
import { AuthenticatorsListContainer } from './authentication/list/AuthenticatorsListContainer'
import { AuthenticatorsUpsertContainer } from './authentication/upsert/AuthenticatorsUpsertContainer'
import { VerifiersContainer } from './verifiers/VerifiersContainer'
import { Developers } from './Developers'
import { TranspondersCreateContainer } from './transponders/create/TranspondersCreateContainer'

export const developerRoutesChildren = [
  {
    element: (
      <ProtectedRoute
        component={Developers}
        permissions={[
          PERMISSIONS.TRANSPONDER_READ,
          PERMISSIONS.TRANSPONDER_READ,
          PERMISSIONS.API_KEY_READ,
          PERMISSIONS.HOOK_READ,
          PERMISSIONS.WEBHOOK_READ,
          PERMISSIONS.AUTHENTICATION_READ,
        ]}
        requireAllPermissions={false}
      />
    ),
    path: RoutesManager.developers.root.pattern,
    children: [
      {
        element: <ProtectedRoute component={TranspondersListContainer} permissions={PERMISSIONS.TRANSPONDER_READ} />,
        path: '/developers/',
      },
      {
        element: <ProtectedRoute component={TranspondersListContainer} permissions={PERMISSIONS.TRANSPONDER_READ} />,
        path: RoutesManager.developers.transponders.list.pattern,
      },
      {
        element: <ProtectedRoute component={ApiKeyListContainer} permissions={PERMISSIONS.API_KEY_READ} />,
        path: RoutesManager.developers.apiKeys.list.pattern,
      },
      {
        element: <ProtectedRoute component={ApiKeyViewContainer} permissions={PERMISSIONS.API_KEY_READ} />,
        path: RoutesManager.developers.apiKeys.detail.pattern,
      },
      {
        element: <ProtectedRoute component={HooksListContainer} permissions={PERMISSIONS.HOOK_READ} />,
        path: RoutesManager.developers.hooks.list.pattern,
      },
      {
        element: <ProtectedRoute component={WebhooksListContainer} permissions={PERMISSIONS.WEBHOOK_READ} />,
        path: RoutesManager.developers.webhooks.list.pattern,
      },
      {
        element: (
          <ProtectedRoute component={AuthenticatorsListContainer} permissions={PERMISSIONS.AUTHENTICATION_READ} />
        ),
        path: RoutesManager.developers.authenticators.list.pattern,
      },
      {
        element: <ProtectedRoute component={VerifiersContainer} permissions={PERMISSIONS.AUTHENTICATION_READ} />,
        path: RoutesManager.developers.verifiers.list.pattern,
      },
    ],
  },
  {
    element: <ProtectedRoute component={TransponderViewContainer} permissions={PERMISSIONS.TRANSPONDER_READ} />,
    path: RoutesManager.developers.transponders.detail.pattern,
  },
  {
    element: <ProtectedRoute component={TranspondersUpsertContainer} permissions={PERMISSIONS.TRANSPONDER_READ} />,
    path: RoutesManager.developers.transponders.upsert.pattern,
  },
  {
    element: <ProtectedRoute component={TranspondersCreateContainer} permissions={PERMISSIONS.AUTHENTICATION_WRITE} />,
    path: RoutesManager.developers.transponders.create.pattern,
  },
  {
    element: (
      <ProtectedRoute
        component={ApiKeyUpsertContainer}
        permissions={[PERMISSIONS.API_KEY_WRITE, PERMISSIONS.ORG_ADMIN]}
      />
    ),
    path: RoutesManager.developers.apiKeys.upsert.pattern,
  },
  {
    element: (
      <ProtectedRoute component={AuthenticatorsUpsertContainer} permissions={PERMISSIONS.AUTHENTICATION_WRITE} />
    ),
    path: RoutesManager.developers.authenticators.upsert.pattern,
  },
]
