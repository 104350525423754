import { useMemo } from 'react'
import * as Yup from 'yup'
import { useCheckIsPolicyScopeCodeAvailable } from 'api/policyScopes/mutations/useCheckIsPolicyScopeCodeAvailable'
import { validationRegExp } from 'utils/constants/forms/validationRegExp'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { PolicyScopeFormValues } from '../interfaces'
import { validationMessages } from 'utils/constants/forms/validationMessages'

export const useValidationSchema = ({ isEditMode }: { isEditMode?: boolean } = {}) => {
  const { mutateAsync: handleCheckIsPolicyScopeCodeAvailable } = useCheckIsPolicyScopeCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsPolicyScopeCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(
    () =>
      Yup.object().shape({
        ...(!isEditMode && {
          code: codeValidationSchema,
        }),
        name: Yup.string().max(75, 'Name must not exceed 75 characters').required('Required'),
        regulationCodes: Yup.array().required('Required'),
        regionCodes: Yup.array().required('Required'),
        rightsFulfillment: Yup.array().of(
          Yup.object().shape({
            fulfillment: Yup.string().when('checked', {
              is: true,
              then: Yup.string()
                .matches(validationRegExp.NUMERIC_INTEGER_STRING, validationMessages.POSITIVE_INTEGER)
                .required('Required'),
            }),
          }),
        ),
        timeToInvokeAppealInDays: Yup.string()
          .matches(validationRegExp.NON_ZERO_NUMERIC_INTEGER_STRING, validationMessages.POSITIVE_INTEGER)
          .test('timeToInvokeAppealInDays', 'Required', function (value, context) {
            const rightsFulfillment = context?.parent?.rightsFulfillment || []
            const isRequired = (rightsFulfillment as PolicyScopeFormValues['rightsFulfillment']).some(
              ({ allowAppeal }) => allowAppeal,
            )
            const isValid = isRequired ? !!value : true

            return isValid
          }),
        timeToFulfillAppealInDays: Yup.string()
          .matches(validationRegExp.NON_ZERO_NUMERIC_INTEGER_STRING, validationMessages.POSITIVE_INTEGER)
          .test('timeToFulfillAppealInDays', 'Required', (value, context) => {
            const rightsFulfillment = context?.parent?.rightsFulfillment || []
            const isRequired = (rightsFulfillment as PolicyScopeFormValues['rightsFulfillment']).some(
              ({ allowAppeal }) => allowAppeal,
            )
            const isValid = isRequired ? !!value : true

            return isValid
          }),
      }),
    [isEditMode, codeValidationSchema],
  )
}
