import React, { ReactNode } from 'react'
import { Box } from '@mui/material'

import { Text } from 'components/ui-kit/typography/Text'

type InfoRowProps = {
  title: string
  titleAddendum?: ReactNode
  info: ReactNode
  hasBorderBottom?: boolean
}

export const InfoRow: React.FC<InfoRowProps> = ({ title, info, hasBorderBottom = true, titleAddendum }) => {
  return (
    <Box
      sx={{
        borderBottom: ({ palette }) => (hasBorderBottom ? `1px solid ${palette.iron.main}` : undefined),
      }}
      display="flex"
    >
      <Box display="flex" flex={1.15} justifyContent="flex-start" flexDirection="column">
        <Box
          display="flex"
          alignItems="center"
          sx={{
            pt: 2,
            pb: 4,
          }}
        >
          <Text size={14} color="grey">
            {title}
          </Text>
          {titleAddendum}
        </Box>
      </Box>
      <Box display="flex" flex={4.15} gap={1} sx={{ py: 2 }}>
        {info}
      </Box>
    </Box>
  )
}
