import React from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useFormikContext } from 'formik'
import { Button, InputAdornment, Popover } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { Label } from 'components/ui-kit/form/common/label/Label'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { LoadingOptionsSpinner } from 'pages/policyCenter/policies/upsert/CreatePolicyForm/components'
import { EndIcon } from 'components/ui-kit/dropdown/DropdownV2/components'
import { getAutocompleteInputCellRendererSxProps } from '../utils'
import { useAutocompleteStyles, useResourceSearchOptions } from '../hooks'
import { CreateRelationshipInitialValues } from '../utils/getInitialValues'
import { ResourceDTO } from '@ketch-com/figurehead'
import { ASSET_TYPE_ENUM } from 'interfaces/assets'

type Props = {
  label?: string
  placeholder?: string
}

export const ReferenceDatasetInputCellRenderer: React.FC<Props> = ({ label, placeholder }) => {
  const autocompleteClasses = useAutocompleteStyles()
  const { setFieldValue, errors, touched, setTouched, values } = useFormikContext<CreateRelationshipInitialValues>()
  const { resourceOptions, isLoadingResources, setSearchValue } = useResourceSearchOptions({})

  const value = values.referenceDatasetCode
  const isTouched = touched.referenceDatasetCode
  const isValid = !errors?.referenceDatasetCode
  const errorMessage = errors?.referenceDatasetCode || 'no-error'

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setTimeout(() => {
      document!.getElementById('dataset')?.focus()
    }, 250)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? 'simple-popover' : undefined

  return (
    <>
      <Button
        size="medium"
        variant="contained"
        disableRipple
        disableFocusRipple
        disableElevation
        onClick={handleClick}
        endIcon={<EndIcon isPending={false} isDropdownOpen={false} color="heliotrope.main" />}
        sx={getAutocompleteInputCellRendererSxProps({ value, isValid, isTouched, isHeliotrope: true })}
      >
        {resourceOptions.find(resource => resource.id === values.referenceDatasetCode)?.name || 'Select...'}
      </Button>
      <Error
        className={clsx({
          [autocompleteClasses.hidden]: !isTouched || isValid,
        })}
      >
        {errorMessage}
      </Error>

      <Popover
        id={popoverId}
        open={open}
        PaperProps={{
          sx: {
            minWidth: '400px',
            p: 1.25,
            borderRadius: 1.5,
          },
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <>
          {label && <Label>{label}</Label>}
          <Autocomplete
            options={resourceOptions}
            id="dataset"
            fullWidth
            openOnFocus
            popupIcon={null}
            autoComplete={false}
            multiple={false}
            classes={{ inputRoot: autocompleteClasses.inputRoot }}
            loading={isLoadingResources}
            loadingText={<LoadingOptionsSpinner />}
            onChange={(_, v) => {
              if (v) {
                setFieldValue(`referenceDatasetCode`, v?.id)
                setFieldValue(`referenceAttributeResourceTypeCode`, v?.properties?.attributeResourceTypeCode)
              } else {
                setFieldValue(`referenceDatasetCode`, '')
                setFieldValue(`referenceAttributeResourceTypeCode`, '')
              }
              handleClose()
            }}
            getOptionLabel={(option: ResourceDTO) => option.name as string}
            renderOption={(props, option) => (
              <li {...{ ...props, key: option.id }}>
                <Box display="flex" alignItems="center" className={autocompleteClasses.selectedValueTag}>
                  {option?.name}
                </Box>
              </li>
            )}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            onInputChange={(_, newInputValue) =>
              setSearchValue(newInputValue, ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATASET)
            }
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                placeholder={placeholder}
                error={!!errorMessage && !!isTouched}
                helperText={errorMessage}
                onBlur={() => setTouched({ ...touched, referenceDatasetCode: true })}
                inputProps={{
                  ...params.inputProps,
                  form: {
                    autoComplete: 'off',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{
                          color: 'heliotrope.main',
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  sx: {
                    opacity: 0,
                    '&.Mui-error': {
                      color: ({ palette }) => palette.chileanFire.main,
                      opacity: 1,
                    },
                  },
                }}
              />
            )}
          />
        </>
      </Popover>
    </>
  )
}
