import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchAuthenticator } from '../fetchers/fetchAuthenticator'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.authenticator,
  queryFn: fetchAuthenticator,
  select: res => res?.data?.authenticator || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useAuthenticator = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
