import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { fetchCookies } from 'api/cookies/fetchers/fetchCookies'
import { useAuth } from 'utils/hooks/useAuth'
import { CookieDTO } from 'interfaces/cookies/cookie'

type LastPage = { cookies: CookieDTO[]; totalResults: number; cursor?: string }

const limit = 50

export const useInfiniteCookies = ({ onSettled }: { onSettled: any }) => {
  const { userData } = useAuth()

  return useInfiniteQuery({
    queryKey: [ApiQueryKeys.cookiesInfinite],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchCookiesResults = await fetchCookies({
        includeIssues: true,
        includeMetadata: true,
        limit,
        organizationCode: userData?.organizationCode || '',
        start: (pageParam - 1) * limit,
        used: true,
      })

      const { data } = fetchCookiesResults

      data.cookies = data.cookies ?? []
      data.totalResults = data.totalResults ?? 0

      return data
    },
    getNextPageParam: ({ totalResults }: LastPage, allPages: LastPage[]) => {
      let totalFetchedCookiesSoFar = 0
      allPages?.forEach?.(p => {
        totalFetchedCookiesSoFar += p.cookies.length
      })
      const nextPageParam = Math.ceil(totalFetchedCookiesSoFar / limit) + 1

      return totalFetchedCookiesSoFar === totalResults ? undefined : nextPageParam
    },
    onSettled,
  })
}
