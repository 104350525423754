import { APIRequestParams } from 'api/common/utils'
import { GetFormTemplateResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  formTemplateId: string
  includeMetadata?: boolean
}>

export const fetchFormTemplate = ({ formTemplateId, includeMetadata }: Params) =>
  API.get<GetFormTemplateResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/form-templates/${formTemplateId}`,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
  )
