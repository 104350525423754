import { useAppliances } from 'api/appliances/queries/useAppliances'
import { TranspondersList } from 'pages/developers/transponders/list/TranspondersList'
import { useAppliancesListFilters } from './hooks'
import { getFilteredAppliances } from './utils'

export const TranspondersListContainer = () => {
  const [{ search, status }] = useAppliancesListFilters()
  const { data: appliances, isLoading: isLoadingAppliances } = useAppliances()

  return (
    <div>
      <TranspondersList
        appliances={getFilteredAppliances({ appliances, status, search })}
        isLoading={isLoadingAppliances}
      />
    </div>
  )
}
