import { ChartTrendPeriod } from 'interfaces/rights/rightStats'
import moment from 'moment'

export function getStartAndEndDateFromTrendPeriod(period: ChartTrendPeriod): { startDate: string; endDate: string } {
  let startDate: moment.Moment
  let endDate: moment.Moment

  switch (period) {
    case ChartTrendPeriod.DAILY:
      startDate = moment().subtract(7, 'days').startOf('day')
      endDate = moment().endOf('day')
      break
    case ChartTrendPeriod.WEEKLY:
      startDate = moment().subtract(5, 'weeks').startOf('week')
      endDate = moment().add(1, 'week').startOf('week')
      break
    case ChartTrendPeriod.MONTHLY_PAST_TWELVE:
      startDate = moment().subtract(1, 'year').startOf('month')
      endDate = moment().endOf('month')
      break
    case ChartTrendPeriod.MONTHLY_PAST_CAL_YEAR:
      startDate = moment().subtract(1, 'year').startOf('year')
      endDate = moment().subtract(1, 'year').endOf('year')
      break
    default:
      startDate = moment().subtract(7, 'days').startOf('day')
      endDate = moment().endOf('day')
  }

  const payload = {
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  }

  return payload
}
