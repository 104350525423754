import React, { useState, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import {
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Scatter,
  ResponsiveContainer,
  ZAxis,
  Label,
  Tooltip,
} from 'recharts'

import { Text } from 'components/ui-kit/typography/Text'
import { Tabs } from 'components/ui-kit/tabs/Tabs'
import {
  generateRatioWidgetDummyDataAlpha,
  generateRatioWidgetDummyDataBeta,
  generateRatioWidgetDummyDataTheta,
} from '../__mocks__/ratioWidgetDummyData'
import { RatioWidgetTooltip, CustomRechartLabel, CustomAxisTickMark, OrangeDot, BlackDot } from '.'
import { theme } from '@ketch-com/deck'

const PREFIX = 'RatioWidget'

const classes = {
  container: `${PREFIX}-container`,
  widgetIcon: `${PREFIX}-widgetIcon`,
  nameCell: `${PREFIX}-nameCell`,
  percentCell: `${PREFIX}-percentCell`,
  personAvatarIcon: `${PREFIX}-personAvatarIcon`,
  tabs: `${PREFIX}-tabs`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    background: palette.white.main,
    borderRadius: spacing(1),
    padding: spacing(3),
  },

  [`& .${classes.widgetIcon}`]: {
    height: 48,
    width: 48,
  },

  [`& .${classes.nameCell}`]: {
    alignItems: 'center',
  },

  [`& .${classes.percentCell}`]: {
    alignItems: 'center',
    display: 'block',
    paddingRight: spacing(4),
  },

  [`& .${classes.personAvatarIcon}`]: {
    height: 12,
    width: 12,
    marginRight: spacing(1.5),
  },

  [`& .${classes.tabs}`]: {
    width: '100%',
    borderBottom: `1px solid ${palette.iron.main}`,
  },
}))

type Props = {}

export const RatioWidget: React.FC<Props> = props => {
  const [tab, setTab] = useState<string>('team')
  const teamAverageData = useMemo(() => generateRatioWidgetDummyDataTheta(1), [])
  const warningLevelData = useMemo(() => generateRatioWidgetDummyDataAlpha(10), [])
  const okLevelData = useMemo(() => generateRatioWidgetDummyDataBeta(10), [])

  return (
    <StyledBox className={classes.container}>
      {/* Title Row */}

      <Box display="flex" flexDirection="column" mb={3}>
        <Text size={20} weight={700} mb={1.25}>
          Ratio
        </Text>
        <Text size={14} color="darkDuskFaded">
          Access to usage ratio across your team.
        </Text>
      </Box>

      <Tabs
        className={classes.tabs}
        tabSpacingOverride={3}
        config={[
          {
            key: 'team',
            active: tab === 'team',
            title: 'Team',
            onClick: () => setTab('team'),
          },
          {
            key: 'avg',
            active: tab === 'avg',
            title: 'Avg',
            onClick: () => setTab('avg'),
          },
        ]}
        size="regular"
      />
      <ResponsiveContainer maxHeight={370}>
        <ScatterChart margin={{ top: 20, right: 20, bottom: 8, left: -13 }}>
          <CartesianGrid
            strokeDasharray="0 0"
            horizontalPoints={[20, 104.375, 179.75, 250.125]}
            verticalPoints={[127.5, 208, 288.5, 369]}
          />
          <XAxis
            stroke={theme.palette.iron.main}
            tick={<CustomAxisTickMark customXOffset={15} customYOffset={7} unitAdornment="%" />}
            dataKey="x"
            type="number"
            ticks={[50, 100]}
            domain={[0, 100]}
            tickLine={false}
          >
            <Label
              content={props => {
                return <CustomRechartLabel textSize={12} title="Usage" xOffset={347} yOffset={346} />
              }}
            />
          </XAxis>
          <YAxis
            stroke={theme.palette.iron.main}
            tick={<CustomAxisTickMark customXOffset={28} customYOffset={10} unitAdornment="%" />}
            dataKey="y"
            type="number"
            ticks={[0, 50, 100]}
            domain={[0, 100]}
            tickLine={false}
          >
            <Label
              content={props => {
                return <CustomRechartLabel textSize={12} title="Access" xOffset={0} yOffset={22} />
              }}
            />
          </YAxis>
          <ZAxis dataKey="z" range={tab === 'team' ? [20, 200] : [200, 1000]} unit="%" />

          <Tooltip
            cursor={false}
            content={props => {
              return <RatioWidgetTooltip active={props.active} label={props.label} payload={props.payload} />
            }}
          />

          {tab === 'team' ? (
            <>
              <Scatter name="alpha" data={warningLevelData} isAnimationActive={false} shape={<OrangeDot />} />
              <Scatter name="beta" data={okLevelData} shape={<BlackDot />} isAnimationActive={false} />
            </>
          ) : (
            <Scatter name="theta" data={teamAverageData} fill="rgba(7, 26, 36, 0.5)" isAnimationActive={false} />
          )}
        </ScatterChart>
      </ResponsiveContainer>
    </StyledBox>
  )
}
