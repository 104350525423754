import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchCanonicalRights } from 'api/rights/fetchers/fetchCanonicalRights'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.rightsCanonical,
  queryFn: fetchCanonicalRights,
  select: res => res?.data?.canonicalRights || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useCanonicalRights = (config?: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config?.params,
      start: 0,
      limit: 1000,
    },
  })
