import React, { useContext } from 'react'

import { PreferenceField, fieldNames } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { Overview, Preference, Rights, Subscriptions } from './components'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'

type Props = {}

export const Translations: React.FC<Props> = () => {
  const { sidebarActiveTab, organizationLanguages } = useContext(ExperienceUpsertContext)

  return (
    <>
      {/* Main Title */}

      {sidebarActiveTab === PreferenceField.MAIN_TITLE && (
        <>
          {organizationLanguages.map(({ language, enabled }) => (
            <FormRow key={language.code} marginBottom={24}>
              <FormInput
                fullWidth
                required={false}
                name={fieldNames.preference.title.translation(language.code)}
                label={`Display Text (${language.englishName})`}
              />
            </FormRow>
          ))}
        </>
      )}

      {/* Overview Translations */}

      <Overview />

      {/* Preferences Translations */}

      <Preference />

      {/* Subscriptions Translations */}

      <Subscriptions />

      {/* Rights Translations */}

      <Rights />
    </>
  )
}
