import Box from '@mui/material/Box'

import { Button, Icon, Widget, theme } from '@ketch-com/deck'
import Typography from '@mui/material/Typography'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import React, { ChangeEvent, useContext, useRef, useState } from 'react'
import { ProcessingActivityContext } from '../../context/ProcessingActivityContext'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { acceptableModelBiasFileTypes, MAX_FILE_SIZE } from '../../../constants'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { formatBytes } from 'utils/formatters'
import { ModelBiasWidgetActionSheet } from './components/ModelBiasWidgetActionSheet'

export const ModelBiasWidget: React.FC = () => {
  const {
    processingActivity,
    uploadModelBiasFile,
    downloadModelBiasFile,
    removeModelBiasFile,
    isProcessingActivityApproved,
    setShowEditApprovedDialog,
    isModelBiasFileUpdating,
    isModelBiasFileDeleting,
  } = useContext(ProcessingActivityContext)
  const { isPermitted } = useIsPermitted()
  const canWrite = isPermitted(PERMISSIONS.ASSESSMENT_WRITE)
  const uploadModelBiasFileRef = useRef<HTMLInputElement | null>(null)

  // State to track loading
  const [uploadLoading, setUploadLoading] = useState(false)

  const handleOnRemoveActionSheetItem = async () => {
    if (isProcessingActivityApproved) {
      setShowEditApprovedDialog(true)
    } else {
      await removeModelBiasFile(processingActivity.id || '')
      // Remove the old file from the input element
      if (uploadModelBiasFileRef.current?.value) {
        uploadModelBiasFileRef.current.value = ''
      }
    }
  }

  const handleFilesChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setUploadLoading(true)
      const { files: assets } = event.target
      if (!assets?.length) {
        return
      }
      const newFiles = Array.from(assets).filter(file => {
        const { type, size } = file
        const isAcceptableType = Object.keys(acceptableModelBiasFileTypes).includes(type)
        const isAcceptableSize = size <= MAX_FILE_SIZE
        if (!isAcceptableType) {
          showToast({ content: 'File type is not supported', type: 'error' })
        }
        if (!isAcceptableSize) {
          showToast({ content: `File size is too big. Max size is ${formatBytes(MAX_FILE_SIZE)}`, type: 'error' })
        }
        return isAcceptableType && isAcceptableSize
      })

      // Upload the files
      await uploadModelBiasFile(processingActivity.id || '', newFiles[0])
      setUploadLoading(false)
    } catch {
      setUploadLoading(false)
    }
  }

  const hasModelBiasFile =
    !!processingActivity.modelBiasFile && Object.keys(processingActivity.modelBiasFile).length > 0

  return (
    <>
      <Widget
        title="Model Bias"
        subTitle="Upload a 3rd-party bias report."
        expandable
        content={
          <Box display="flex" flexDirection="column" gap={0.25} width="100%">
            {hasModelBiasFile ? (
              <Box width="100%">
                <Typography display="block" variant="smallLabel" color={theme.palette.darkDuskFaded.main} mb={1}>
                  Report
                </Typography>
                <Box display="flex" alignItems="center" width="100%" gap={0.75}>
                  <Icon name="OClip" iconColor={theme.palette.darkDusk.main} />
                  <Typography variant="label">
                    {processingActivity?.modelBiasFile?.name || (
                      <Typography variant="smallLabel">'No File Name'</Typography>
                    )}
                  </Typography>
                  <Box ml="auto">
                    <ModelBiasWidgetActionSheet
                      canWrite={canWrite}
                      pending={isModelBiasFileUpdating || isModelBiasFileDeleting}
                      handleDownloadModelBiasFile={downloadModelBiasFile}
                      handleOnRemove={handleOnRemoveActionSheetItem}
                    />
                  </Box>
                </Box>
              </Box>
            ) : canWrite ? (
              <Box>
                <Typography display="block" variant="smallLabel" color={theme.palette.darkDuskFaded.main} mb={1}>
                  Upload Report
                </Typography>
                <Button
                  color="tertiary"
                  pending={uploadLoading}
                  onClick={() => {
                    if (isProcessingActivityApproved) {
                      setShowEditApprovedDialog(true)
                    } else {
                      uploadModelBiasFileRef.current?.click()
                    }
                  }}
                >
                  Browse...
                </Button>
              </Box>
            ) : (
              <Typography variant="smallLabel" color={theme.palette.darkDuskFaded.main}>
                No Report
              </Typography>
            )}
          </Box>
        }
        footerComponent={
          processingActivity?.modelBiasUpdatedAt && (
            <Typography mt={2} variant="footnote">
              Last updated {formatDateTimeFromUnix(processingActivity.modelBiasUpdatedAt)}
            </Typography>
          )
        }
      />
      <input type="file" ref={uploadModelBiasFileRef} onChange={handleFilesChange} hidden />
    </>
  )
}
