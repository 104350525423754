import React, { createContext } from 'react'
import { PrivacyProtocolUtils, usePrivacyProtocolUtils } from '../utils/usePrivacyProtocolUtils'

export const PrivacyProtocolContext = createContext({} as PrivacyProtocolUtils)

type Props = {
  children?: React.ReactNode | undefined
}

export const PrivacyProtocolContextProvider: React.FC<Props> = ({ children }) => {
  // Use utils hook
  const hookValues = usePrivacyProtocolUtils()

  return <PrivacyProtocolContext.Provider value={{ ...hookValues }}>{children}</PrivacyProtocolContext.Provider>
}

export const withPrivacyProtocolContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <PrivacyProtocolContextProvider>
        <Component {...props} />
      </PrivacyProtocolContextProvider>
    )
  }
}
