import {
  ProcessingActivityProcessingFrequencyDTO,
  ProcessingActivityTypeDTO,
  ProcessingActivityDTO,
} from '@ketch-com/figurehead'

export const getProcessingActivitiesFormInitialValues = (): ProcessingActivityDTO => {
  return {
    type: ProcessingActivityTypeDTO.StandardProcessingActivityType,
    name: '',
    description: '',
    processingFrequency: ProcessingActivityProcessingFrequencyDTO.OngoingProcessingActivityProcessingFrequency,
    purposes: [],
    businessFunctions: [],
    dataSystems: [],
    teams: [],
  }
}
