import { theme } from '@ketch-com/deck'

type IconProps = { x?: number; y?: number }

export const PersonIcon: React.FC<IconProps> = props => {
  const { x, y } = props
  return (
    <svg x={x} y={y} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill={theme.palette.chileanFire.main} />
      <circle cx="10" cy="10" r="10" stroke={theme.palette.white.main} />
      <path
        d="M7.375 7.3783C7.48784 10.8743 12.5127 10.8735 12.625 7.37824C12.514 3.87351 7.48552 3.87431 7.375 7.3783Z"
        stroke={theme.palette.white.main}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 15.2502C6.06182 10.8294 14.19 11.1926 15.25 15.2502"
        stroke={theme.palette.white.main}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
