import React from 'react'
import { Box, Typography } from '@mui/material'
import { MaybeNull } from 'interfaces'
import { GetRelationship } from 'pages/assetManager/interfaces'
import { Button, PopUp } from '@ketch-com/deck'

type Props = {
  deleteRelationship: MaybeNull<GetRelationship>
  onSubmit: (relationship: MaybeNull<GetRelationship>) => void
  onCancel: () => void
}

export const DeleteRelationshipMappingModal: React.FC<Props> = ({ deleteRelationship, onSubmit, onCancel }) => (
  <PopUp
    title="Delete Relationship"
    popUpWidth="550px"
    variant="dialog"
    onClose={onCancel}
    popUpActionChildren={
      <>
        <Button color="secondary" size="large" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" size="large" onClick={() => onSubmit(deleteRelationship)}>
          Delete
        </Button>
      </>
    }
  >
    <Box>
      <Typography variant="body">Are you sure you want to delete the relationship?</Typography>
    </Box>
  </PopUp>
)
