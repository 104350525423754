import { Box, styled } from '@mui/material'
import { RequestsFormHeader } from './components/RequestsFormHeader'
import { RequestsFormContent } from './components/RequestsFormContent'
import { RequestsFormActionButton } from './components/RequestsFormActionButton'
import { SelectableSection } from '../../../../../SelectableSection'
import { useContext } from 'react'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import {
  PreferenceExperienceBuilderSection,
  PreferenceThemeBuilderSection,
} from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'
import { getIn } from 'formik'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'

const RequestsFormBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

export const RequestsFormTab: React.FC = () => {
  const { isTheme, experienceConfig } = useContext(BuilderContext)

  const showReCaptchaNotice = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceRequestsTabRightFormEnableRecaptcha,
  )

  return (
    <RequestsFormBox>
      <SelectableSection
        section={isTheme ? PreferenceThemeBuilderSection.RequestsFormHeader : undefined}
        overlayProps={{
          margin: '-4px 0px',
        }}
      >
        <RequestsFormHeader />
      </SelectableSection>
      <SelectableSection
        section={
          isTheme
            ? PreferenceThemeBuilderSection.RequestsFormContent
            : PreferenceExperienceBuilderSection.RequestsFormContent
        }
        overlayProps={{
          marginTop: '36px',
          marginBottom: showReCaptchaNotice ? '-138px' : '', // Have hover box cover button and recaptcha notice if recaptcha is enabled
        }}
      >
        <RequestsFormContent />
      </SelectableSection>
      <SelectableSection
        section={
          isTheme
            ? PreferenceThemeBuilderSection.RequestsFormActionButton
            : PreferenceExperienceBuilderSection.RequestsFormActionButton
        }
        overlayProps={{
          marginTop: '28px',
          marginBottom: '-8px',
        }}
      >
        <RequestsFormActionButton />
      </SelectableSection>
    </RequestsFormBox>
  )
}
