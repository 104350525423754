import React, { memo } from 'react'
import { find } from 'lodash'

import { ICanvasStep, ICoordinates } from 'pages/orchestration/workflows/edit/interfaces'
import { TransitionPlaceholder } from 'pages/orchestration/workflows/edit/konvaEntities/transitions/TransitionPlaceholder'

interface IPlaceholderProps {
  gridPosition: ICoordinates
  step: ICanvasStep
  steps: Array<ICanvasStep>
  connectedNestId?: string
  connectedNestStartPosition?: number
}

export const Placeholder: React.FC<IPlaceholderProps> = memo(
  ({ gridPosition, step, steps, connectedNestId, connectedNestStartPosition }) => {
    const { placeholder } = step
    const connectedStep = find(steps, { ID: placeholder?.transition })
    return (
      <TransitionPlaceholder
        gridPosition={gridPosition}
        step={step}
        nextStep={connectedStep}
        steps={steps}
        connectedNestId={connectedNestId}
        transitionIndex={0}
        connectedNestStartPosition={connectedNestStartPosition}
      />
    )
  },
)
