import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material'
import { FieldArrayRenderProps, getIn } from 'formik'
import { StackGroupWithId } from '../utils/formValues'
import { Text } from 'components/ui-kit/typography/Text'
import SearchIcon from '@mui/icons-material/Search'
import { useState } from 'react'
import { Input } from 'components/ui-kit/input/Input'
import { Chip } from 'components/ui-kit/chip/Chip'
import { DeleteButton } from 'pages/orchestration/events/listV2/components/DeleteButton'
import { GroupNumber } from './GroupNumber'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { DeleteStackGroupModal } from 'components/modals/stacks/DeleteStackGroupModal'
import { PurposeDTO } from 'interfaces/purposes/purpose'

type GroupTileProps = {
  group: StackGroupWithId
  index: number
  helpers: FieldArrayRenderProps
  purposes: PurposeDTO[]
  hasMoreThanOneGroup: boolean
}

export const GroupTile = ({ group, index, helpers, purposes, hasMoreThanOneGroup }: GroupTileProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const nameKey = `groups[${index}].name`
  const purposeKey = `groups[${index}].purposes`

  const hasNameBeenTouched = Boolean(getIn(helpers.form.touched, nameKey))
  const hasNameError = Boolean(getIn(helpers.form.errors, nameKey)) && hasNameBeenTouched

  const hasPurposeBeenTouched = Boolean(getIn(helpers.form.touched, purposeKey))
  const hasPurposeError = Boolean(getIn(helpers.form.errors, purposeKey)) && hasPurposeBeenTouched

  const setNameTouched = () => helpers.form.setFieldTouched(nameKey, true)
  const setPurposeTouched = () => helpers.form.setFieldTouched(purposeKey, true)

  const deleteGroup = () => helpers.remove(index)

  const purposesInOtherGroups: string[] =
    helpers.form.values?.groups
      ?.map((otherGroup: { name: string; purposes: string[] }) =>
        otherGroup.name !== group.name ? otherGroup.purposes : [],
      )
      ?.flat() || []
  const selectablePurposes = purposes.filter(p => !purposesInOtherGroups.includes(p?.code || '') && !!p?.enabled)

  return (
    <Box
      p={3}
      sx={({ palette }) => ({ backgroundColor: palette.ironLight.main })}
      borderRadius="11px"
      mb={2}
      position="relative"
    >
      <Box display="flex" gap={1.5}>
        <Box>
          <GroupNumber index={index} />
        </Box>
        <Box width={600}>
          <Text component="div" weight={600} sx={{ mb: 0.25 }}>
            Group Name
          </Text>
          <Text component="div" size={11} color="grey" sx={{ mb: 2 }}>
            Group Name displays in Experiences on your Properties
          </Text>
          <Input
            valid={!hasNameError}
            fullWidth
            value={group.name || ''}
            onChange={({ target }) => {
              const newGroup = { ...group, name: target.value }
              helpers.replace(index, newGroup)
              setNameTouched()
            }}
            onBlur={setNameTouched}
            placeholder="Example: Purpose Group A"
          />
          {hasNameError ? (
            <Box mt={0.5}>
              <Error>Required</Error>
            </Box>
          ) : null}

          <Text component="div" weight={600} sx={{ mb: 0.25, mt: 3 }}>
            Purposes
          </Text>
          <Text component="div" size={11} color="grey" sx={{ mb: 2 }}>
            Use a dropdown below to select purposes that relate to group.
          </Text>
          <Box display="inline-block">
            <Autocomplete
              size="small"
              multiple
              options={selectablePurposes}
              value={[]}
              onBlur={setPurposeTouched}
              renderTags={() => null}
              sx={({ palette }) => ({ backgroundColor: palette.white.main, width: 350 })}
              getOptionLabel={purpose => purpose.name || ''}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              filterOptions={(options, state) =>
                options.filter(
                  option =>
                    !group.purposes?.includes(option.code) &&
                    !purposesInOtherGroups.includes(option.code) &&
                    option.name?.toLowerCase().includes(state.inputValue.toLowerCase()),
                )
              }
              onChange={(e, newValue) => {
                const purpose = newValue?.[0]
                const newGroup = { ...group, purposes: [...(group?.purposes || []), purpose?.code] }
                helpers.replace(index, newGroup)
                setPurposeTouched()
              }}
              renderOption={(props, value) => {
                const uniqueIdentifier = `${value?.code || ''}-${value?.id || ''}-${value?.name || ''}`
                return (
                  <li {...props} key={uniqueIdentifier}>
                    {value.name}
                  </li>
                )
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Start typing a purpose name"
                  sx={({ palette }) => ({
                    border: hasPurposeError ? `1px solid ${palette.chileanFire.main}` : undefined,
                  })}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: <></>,
                  }}
                />
              )}
            />
          </Box>
          {group?.purposes && group?.purposes.length > 0 ? (
            <Box mt={2} display="flex" gap={1} flexWrap="wrap">
              {(group?.purposes || []).map(pId => {
                const purpose = purposes.find(p => p.code === pId)
                return (
                  <Chip
                    key={`${purpose?.name}-${purpose?.id}`}
                    onRemove={() => {
                      const newGroup = { ...group, purposes: (group?.purposes || []).filter(p => p !== pId) }
                      helpers.replace(index, newGroup)
                      setPurposeTouched()
                    }}
                  >
                    {purpose?.name}
                  </Chip>
                )
              })}
            </Box>
          ) : null}
          {hasPurposeError ? (
            <Box mt={1.5}>
              <Error>At least one purpose is required</Error>
            </Box>
          ) : null}
        </Box>
      </Box>

      {hasMoreThanOneGroup ? (
        <Box position="absolute" top={24} right={24}>
          <DeleteButton onClick={() => setIsModalOpen(true)} />
        </Box>
      ) : null}

      {isModalOpen ? (
        <DeleteStackGroupModal stackGroup={group} onSubmit={deleteGroup} onCancel={() => setIsModalOpen(false)} />
      ) : null}
    </Box>
  )
}
