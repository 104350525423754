import { Box, Typography, styled } from '@mui/material'
import { getIn } from 'formik'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { useContext } from 'react'
import { Icon } from '@ketch-com/deck'
import { clamp } from 'lodash'
import { BUILDER_CONSTANTS } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'

const DsrLinkBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 16px 12px 16px',
})

const TextContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '475px',
})

export const RequestsHomeDsrLink: React.FC = () => {
  const { themeConfig, experienceConfig } = useContext(BuilderContext)

  // Theme config
  const backgroundColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkBackground)
  const titleColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkTitleColor)
  const descriptionColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkDescriptionColor,
  )
  const arrowIconColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkArrowIcon)
  const cornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'

  // Experience config
  const title = getIn(experienceConfig, experienceUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkTitle)
  const description = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkDescription,
  )

  return (
    <DsrLinkBox sx={{ background: backgroundColor, borderRadius: cornerRadius }}>
      <TextContentBox>
        <Typography fontSize={16} fontWeight={700} color={titleColor} sx={{ wordWrap: 'break-word' }}>
          {title}
        </Typography>
        <Typography fontSize={14} fontWeight={400} color={descriptionColor} sx={{ wordWrap: 'break-word' }}>
          {description}
        </Typography>
      </TextContentBox>
      <Icon name={'OArrowRight'} iconColor={arrowIconColor} />
    </DsrLinkBox>
  )
}
