import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { SubscriptionTopicDTO, SubscriptionControlDTO } from '@ketch-com/figurehead'

export const getLanguageEnglishNameByCode = (languageCode: string, organizationLanguages: LanguageWithStatusDTO[]) => {
  if (!languageCode) return ''
  return (
    organizationLanguages.filter(orgLang => orgLang.language.code === languageCode)?.[0]?.language?.englishName || ''
  )
}

export const getLanguageNativeNameByCode = (languageCode: string, organizationLanguages: LanguageWithStatusDTO[]) => {
  if (!languageCode) return ''
  return (
    organizationLanguages.filter(orgLang => orgLang.language.code === languageCode)?.[0]?.language?.nativeName || ''
  )
}

export const getSortedTopicTranslations = (
  topicTranslations: SubscriptionTopicDTO['translations'],
  organizationLanguages: LanguageWithStatusDTO[],
) => {
  const sortedTopicTranslations: SubscriptionTopicDTO['translations'] = {}
  organizationLanguages?.forEach?.(orgLang => {
    const orgLangCode = orgLang.language.code
    if (topicTranslations && topicTranslations[orgLangCode])
      sortedTopicTranslations[orgLangCode] = topicTranslations[orgLangCode]
  })
  return sortedTopicTranslations
}

export const getSortedControlTranslations = (
  controlTranslations: SubscriptionControlDTO['translations'],
  organizationLanguages: LanguageWithStatusDTO[],
) => {
  const sortedControlTranslations: SubscriptionControlDTO['translations'] = {}
  organizationLanguages?.forEach?.(orgLang => {
    const orgLangCode = orgLang.language.code
    if (controlTranslations && controlTranslations[orgLangCode])
      sortedControlTranslations[orgLangCode] = controlTranslations[orgLangCode]
  })
  return sortedControlTranslations
}
