import Box from '@mui/material/Box'
import { Text } from 'components/ui-kit/typography/Text'
import { useParams } from 'react-router-dom'
import { DataRiskDetailLayout } from 'pages/insights/dataRisks/components/layout/DataRiskDetailLayout'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { GreyBox } from 'pages/insights/dataRisks/components/layout/GreyBox'
import { getRiskScoreDetails } from 'pages/insights/utils'
import { Policy } from '../../__mocks__/policiesControls'
import { getDataSystemType } from 'pages/insights/dataMap/utils/getDataSystemType'
import { useStyles } from '../../components/layout/styles'
import { capitalize } from 'lodash'
import { ReactComponent as DataRisksSuggestionTrash } from 'assets/icons/data_risk_suggestion_trash.svg'
import { ReactComponent as DataRisksSuggestionShield } from 'assets/icons/data_risks_suggestion_shield.svg'
import { PoliciesControlsLowerContent } from './PoliciesControlsLowerContent'
import { ContentChip } from './ContentChip'

type Props = {
  securityControlsRecords: Policy[]
  emptyState: JSX.Element
}

export const SecurityControlsDetails: React.FC<Props> = ({ securityControlsRecords, emptyState }) => {
  const { code: assetId } = useParams<{ code?: string }>()
  const dataSystem = securityControlsRecords.find(asset => asset.id === assetId)
  const riskScoreDetails = getRiskScoreDetails(dataSystem?.riskScore || 0)
  const classes = useStyles()

  if (!dataSystem) {
    return emptyState
  }

  const actions = (
    <ButtonOptions size="big" items={[{ content: <Text>Export</Text> }, { content: <Text>Compare</Text> }]} />
  )

  const whyContent = (
    <Box>
      <Text component="div" size={20} weight={700} mb={1.5} lineHeight="28px">
        Additional measures are required to ensure security
      </Text>
      <Text component="div" lineHeight="20px">
        This database contains data that require more additional security measures.
      </Text>
    </Box>
  )

  const suggestionsContent = [
    {
      icon: <DataRisksSuggestionShield />,
      textContent: (
        <Text lineHeight="20px" component="div">
          This database is used for Revenue Data Processing. Since it contains financial data consider&nbsp;
          <Text color="royalBlue" onClick={() => {}}>
            encrypting it at rest.
          </Text>
        </Text>
      ),
    },
    {
      icon: <DataRisksSuggestionTrash />,
      textContent: (
        <Text lineHeight="20px" component="div">
          If this database contains archived or obsolete records—consider deleting or restricting access for security
          reasons.
        </Text>
      ),
    },
  ]

  const lowerContentRows = [<PoliciesControlsLowerContent record={dataSystem} key={1} />]

  const disabledMeasures = [
    'Encryption at Rest',
    'Principal of Least Privilege',
    'Physical Security',
    'Retention: 4 years',
  ]
  const enabledMeasures = ['Access Control Policies', 'Encryption in Transit', 'User Roles']
  const mainContent = (
    <GreyBox heading="Security Controls">
      <Box display="flex" gap={1.5}>
        <Box flexBasis="50%">
          {disabledMeasures.map(measure => (
            <ContentChip key={measure} text={measure} enabled={false} />
          ))}
        </Box>
        <Box flexBasis="50%">
          {enabledMeasures.map(measure => (
            <ContentChip key={measure} text={measure} enabled={true} />
          ))}
        </Box>
      </Box>
    </GreyBox>
  )

  return (
    <DataRiskDetailLayout
      heading={dataSystem?.name || ''}
      headingType={capitalize(getDataSystemType(dataSystem?.canonicalResourceType || 0))}
      headingTypeIcon={
        <img
          src="https://dev.ketchcdn.com/transom/assets/ketch_apps/snowflake/0.1.1/53f5298918795ca2332e8e91428d1aed9e5deee03c017bea70c53290aa5c0dac/logo.svg"
          alt="snowflake"
          className={classes.iconSmall}
        />
      }
      actions={actions}
      mainContent={mainContent}
      riskScoreDetails={riskScoreDetails}
      riskScore={dataSystem?.riskScore}
      whyContent={whyContent}
      suggestionContent={suggestionsContent}
      lowerContentRows={lowerContentRows}
    />
  )
}
