import { useDataMapVisualization } from 'api/dataMap/queries/useDataMapVisualization'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useDataMapPurposesInfinite } from 'api/dataMap/queries/useDataMapPurposesInfinite'
import { useLocation } from 'react-router-dom'
import { useInfiniteProcessingActivities } from '../../../../api/processingActivities/queries/useInfiniteProcessingActivities'
import { ProcessingActivityDTO, ProcessingActivityStatusDTO } from '@ketch-com/figurehead'
import { useNavigate } from 'react-router'
import { useIsEntitled } from 'utils/hooks'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

export const useDataMapUtils = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()
  const isEntitledToStatsDatamap = isEntitled(ENTITLEMENTS.STATS_DATAMAP)

  const { data: visualizationData, isLoading: isVisualizationDataLoading } = useDataMapVisualization({
    enabled: isEntitledToStatsDatamap,
    onError: () => {
      showToast({ content: 'Failed to fetch data map visualization', type: 'error' })
    },
  })
  const {
    data: purposes,
    isLoading: isPurposesLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useDataMapPurposesInfinite({
    params: {
      limit: 10,
    },
  })

  const {
    data: processingActivities,
    isLoading: isProcessingActivitiesLoading,
    refetch: refetchProcessingActivities,
    hasNextPage: hasNextPAPage,
    fetchNextPage: fetchNextPAPage,
    isFetchingNextPage: isFetchingNextPAPage,
  } = useInfiniteProcessingActivities({
    includeMetadata: true,
    customLimit: 10,
  })

  const isProcessingActivityApproved = (activity: ProcessingActivityDTO) =>
    activity.status === ProcessingActivityStatusDTO.ApprovedProcessingActivityStatus

  return {
    location,
    navigate,
    visualizationData,
    isVisualizationDataLoading,
    purposes,
    isPurposesLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    processingActivities,
    isProcessingActivitiesLoading,
    hasNextPAPage,
    fetchNextPAPage,
    isFetchingNextPAPage,
    refetchProcessingActivities,
    isProcessingActivityApproved,
  }
}

export type DataMapUtils = ReturnType<typeof useDataMapUtils>
