import React, { useCallback, useContext, useMemo, useState } from 'react'
import { GridRowClassNameParams, useGridApiRef, GridEventListener } from '@mui/x-data-grid-premium'
import { Box } from '@mui/material'
import { DataGrid, EmptyState } from '@ketch-com/deck'
import {
  ProcessingActivityAssetDTO,
  ProcessingActivityDataSystemDTO,
  ProcessingActivityDataSystemProcessingStageDTO,
  ProcessingActivityTypeDTO,
} from '@ketch-com/figurehead'
import { isUndefined } from 'lodash'
import { DataMapProcessingActivityContext } from '../context/DataMapProcessingActivityContext'
import { PersonalDataTypesModal } from '../../../../../policyCenter/processingActivities/upsert/components'
import {
  DataGridAssetObjectType,
  DataGridObjectType,
  dataSourcesListColumns,
  groupingColDef,
} from './dataSourcesListUtils'

export const DataSourcesList: React.FC = () => {
  const { processingActivity, searchString, activityStage, setSearchString, setActivityStage } = useContext(
    DataMapProcessingActivityContext,
  )
  const isModelType = processingActivity.type === ProcessingActivityTypeDTO.ModelProcessingActivityType

  const [dataCategoryModalObject, setDataCategoryModalObject] = useState<
    ProcessingActivityDataSystemDTO | ProcessingActivityAssetDTO
  >()
  const [showDataCategoriesModal, setShowDataCategoriesModal] = useState(false)

  const displayedDataSources = processingActivity?.dataSystems?.filter(system => {
    const searchMatch = system?.dataSystem?.name?.toLowerCase().includes(searchString)
    const stageMatch =
      system?.processingStages?.includes(activityStage) ||
      activityStage ===
        ProcessingActivityDataSystemProcessingStageDTO.UnspecifiedProcessingActivityDataSystemProcessingStage
    return searchMatch && stageMatch
  })

  const handleDataCategoriesClick = useCallback(
    (object: ProcessingActivityDataSystemDTO | ProcessingActivityAssetDTO) => {
      setDataCategoryModalObject(object)
      setShowDataCategoriesModal(!showDataCategoriesModal)
    },
    [showDataCategoriesModal],
  )

  const handleResetFiltersClick = () => {
    setSearchString('')
    setActivityStage(
      ProcessingActivityDataSystemProcessingStageDTO.UnspecifiedProcessingActivityDataSystemProcessingStage,
    )
  }

  const apiRef = useGridApiRef()

  const onRowClick = React.useCallback<GridEventListener<'rowClick'>>(
    params => {
      const rowNode = apiRef.current.getRowNode(params.id)
      if (rowNode && rowNode.type === 'group') {
        apiRef.current.setRowChildrenExpansion(params.id, !rowNode.childrenExpanded)
      }
    },
    [apiRef],
  )

  const getRowClassName = (params: GridRowClassNameParams) => {
    //add class name to row with Header. Row with header has only id and path
    const rowHeaderClass = Object.keys(params.row).length === 2 ? 'DeckRowHeader' : ''
    return `DeckRowSpan-${params.row.path.length} ${rowHeaderClass}`
  }

  const rows = useMemo(() => {
    const result: (DataGridObjectType | DataGridAssetObjectType)[] = []
    if (displayedDataSources) {
      displayedDataSources.forEach(elem => {
        if (!elem.assets) {
          result.push({ ...elem, path: [elem.id || ''], handleDataCategoriesClick })
        } else {
          result.push({ ...elem, path: [elem.id || ''], handleDataCategoriesClick })
          elem.assets.forEach(asset => {
            result.push({
              ...asset,
              handleDataCategoriesClick,
              logoUrl: elem.dataSystem?.logoUrl,
              path: [elem.id || '', asset.id || ''],
            })
          })
        }
      })
    }
    return result
  }, [displayedDataSources, handleDataCategoriesClick])

  return displayedDataSources && displayedDataSources.length ? (
    <Box mt={4}>
      <DataGrid
        sx={{
          padding: 'unset',
          // we update the padding added by DataGrid for "groupingColDef" passed as prop below
          '&.DeckExpandable:not(.DeckWithCheckbox)': {
            '& .MuiDataGrid-columnHeader:first-of-type': {
              '& .MuiDataGrid-columnHeaderDraggableContainer': {
                paddingLeft: '18px',
              },
            },
          },
        }}
        apiRef={apiRef}
        columns={dataSourcesListColumns(isModelType)}
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        getRowClassName={getRowClassName}
        getRowHeight={() => 'auto'}
        getTreeDataPath={row => row.path}
        groupingColDef={groupingColDef}
        hideFooter
        onRowClick={onRowClick}
        rows={rows}
        treeData
        slotProps={{
          baseIconButton: {
            onClick: () => {
              return false // let the rowClick handler deal with the click
            },
          },
        }}
      />

      {/* Modal */}
      {showDataCategoriesModal && !isUndefined(dataCategoryModalObject) && (
        <PersonalDataTypesModal
          dataCategoriesObject={dataCategoryModalObject}
          onCancel={() => setShowDataCategoriesModal(false)}
        />
      )}
    </Box>
  ) : (
    // No results indicator
    <Box>
      <EmptyState
        iconName="OFilter"
        title="No results found"
        subTitle="Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try again."
        primaryButtonTitle="Reset to Defaults"
        primaryButtonProps={{
          onClick: handleResetFiltersClick,
          color: 'tertiary',
        }}
      />
    </Box>
  )
}
