import { IdentitySpaceDTO, IdentitySpaceFormDTO } from 'interfaces/identitySpaces/identitySpace'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  identitySpaceCode: string
  formData: IdentitySpaceFormDTO
}

type Response = { identitySpace: IdentitySpaceDTO }

export const updateIdentitySpace = ({ organizationCode, identitySpaceCode, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/identity-spaces/${identitySpaceCode}`,
    }),
    formData,
  )
