import { ExperienceUrlTypeParam } from 'interfaces/experiences/experienceUrlTypeParam'
import { FormMode } from 'interfaces/formModes/formMode'
import { CodeParams, IDParams } from './utils'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'

// TODO:JB - Rename to ConsentRights after production release
export const deployment = {
  root: {
    pattern: '/consent-and-rights',
    getURL: () => '/consent-and-rights',
  },

  // Consent & Rights > Experiences
  experiencesHome: {
    root: {
      pattern: '/experiences-home',
      getURL: () => '/consent-and-rights/experiences-home',
    },
  },

  // Consent & Rights > Properties
  propertiesHome: {
    root: {
      pattern: '/properties-home',
      getURL: () => '/consent-and-rights/properties-home',
    },
  },

  // Consent & Rights > Rights
  rightsHome: {
    root: {
      pattern: '/rights-home',
      getURL: () => '/consent-and-rights/rights-home',
    },
  },

  // Consent & Rights > Consent
  consentHome: {
    root: {
      pattern: '/consent-home',
      getURL: () => '/consent-and-rights/consent-home',
    },
  },

  forms: {
    root: {
      pattern: '/forms',
      getURL: () => '/consent-and-rights/experiences-home/forms',
    },

    fields: {
      root: {
        pattern: '/fields',
        getURL: () => '/consent-and-rights/experiences-home/forms/fields',
      },

      view: {
        root: {
          pattern: '/experiences-home/forms/fields/view/:id',
          getURL: ({ id }: IDParams) => `/consent-and-rights/experiences-home/forms/fields/view/${id}/overview`,
        },

        overview: {
          root: {
            pattern: '/overview',
            getURL: ({ id }: IDParams) => `/consent-and-rights/experiences-home/forms/fields/view/${id}/overview`,
          },
        },

        translations: {
          root: {
            pattern: '/translations',
            getURL: ({ id }: IDParams) => `/consent-and-rights/experiences-home/forms/fields/view/${id}/translations`,
          },
        },

        usage: {
          root: {
            pattern: '/usage',
            getURL: ({ id }: IDParams) => `/consent-and-rights/experiences-home/forms/fields/view/${id}/usage`,
          },
        },
      },

      upsert: {
        root: {
          pattern: `/experiences-home/forms/fields/:formMode/:id`,
          getURL: ({ id, formMode }: IDParams & { formMode: FormMode }) =>
            `/consent-and-rights/experiences-home/forms/fields/${formMode}${id ? `/${id}` : ''}`,
        },
      },
    },

    templates: {
      root: {
        pattern: '/templates',
        getURL: () => '/consent-and-rights/experiences-home/forms/templates',
      },

      view: {
        root: {
          pattern: '/experiences-home/forms/templates/view/:id',
          getURL: ({ id }: IDParams) => `/consent-and-rights/experiences-home/forms/templates/view/${id}`,
        },
      },

      upsert: {
        root: {
          pattern: `/experiences-home/forms/templates/:formMode/:id`,
          getURL: ({ id, formMode }: IDParams & { formMode: FormMode }) =>
            `/consent-and-rights/experiences-home/forms/templates/${formMode}${id ? `/${id}` : ''}`,
        },

        preview: {
          root: {
            pattern: `/experiences-home/forms/templates/:formMode/:id/preview`,
            getURL: ({ id, formMode }: IDParams & { formMode: FormMode }) =>
              `/consent-and-rights/experiences-home/forms/templates/${formMode}${id ? `/${id}` : '/new'}/preview`,
          },
        },
      },
    },
  },

  applications: {
    root: {
      pattern: '/properties',
      getURL: () => '/consent-and-rights/properties-home/properties',
    },

    view: {
      root: {
        pattern: '/properties-home/properties/view/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/properties-home/properties/view/${code}`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/properties-home/properties/view/${code}/overview`,
        },
      },

      environments: {
        root: {
          pattern: '/environments',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/properties-home/properties/view/${code}/environments`,
        },
      },

      dataLayer: {
        root: {
          pattern: '/data-layer',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/properties-home/properties/view/${code}/data-layer`,
        },
      },

      cookies: {
        root: {
          pattern: '/cookies',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/properties-home/properties/view/${code}/cookies`,
        },
      },

      trackers: {
        root: {
          pattern: '/trackers',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/properties-home/properties/view/${code}/trackers`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/properties-home/properties/view/${code}/versions`,
        },
      },
    },

    upsert: {
      root: {
        pattern: `/properties-home/properties/:formMode/:code?`,
        getURL: ({ code, formMode }: CodeParams & { formMode: FormMode }) =>
          `/consent-and-rights/properties-home/properties/${formMode}${code ? `/${code}` : ''}`,
      },
    },

    deploy: {
      root: {
        pattern: '/consent-and-rights/properties-home/properties/deploy/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/properties-home/properties/deploy/${code}`,
      },
    },
  },

  experiences: {
    root: {
      pattern: '/experiences',
      getURL: () => '/consent-and-rights/experiences',
    },

    view: {
      root: {
        pattern: '/experiences/view/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/experiences/view/${code}`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/experiences/view/${code}/overview`,
        },
      },

      translations: {
        root: {
          pattern: '/translations',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/experiences/view/${code}/translations`,
        },
      },

      forms: {
        root: {
          pattern: '/forms',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/experiences/view/${code}/forms`,
        },
      },

      subscriptions: {
        root: {
          pattern: '/subscriptions',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/experiences/view/${code}/subscriptions`,
        },
      },

      deployments: {
        root: {
          pattern: '/deployments',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/experiences/view/${code}/deployments`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/experiences/view/${code}/versions`,
        },
      },
    },

    preview: {
      root: {
        pattern: `/experiences/preview/:experienceType/:code?`,
        getURL: ({ code, experienceType }: CodeParams & { experienceType: ExperienceUrlTypeParam }) =>
          `/consent-and-rights/experiences/preview/${experienceType}${code ? `/${code}` : ''}`,
      },
    },

    upsert: {
      root: {
        pattern: `/experiences/:formMode/:experienceType/:code?`,
        getURL: ({
          code,
          formMode,
          experienceType,
        }: CodeParams & { experienceType: ExperienceUrlTypeParam; formMode: FormMode }) =>
          `/consent-and-rights/experiences/${formMode}/${experienceType}${code ? `/${code}` : ''}`,
      },
    },
  },

  themes: {
    root: {
      pattern: '/themes',
      getURL: () => '/consent-and-rights/themes',
    },

    view: {
      root: {
        pattern: '/themes/view/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/themes/view/${code}`,
      },

      details: {
        root: {
          pattern: '/details',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/themes/view/${code}/details`,
        },
      },

      colorScheme: {
        root: {
          pattern: '/color-scheme',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/themes/view/${code}/color-scheme`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/themes/view/${code}/versions`,
        },
      },
    },

    preview: {
      root: {
        pattern: '/themes/preview/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/themes/preview/${code}`,
      },

      banner: {
        root: {
          pattern: '/themes/preview/:code/banner',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/themes/preview/${code}/banner`,
        },
      },

      modal: {
        root: {
          pattern: '/themes/preview/:code/modal',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/themes/preview/${code}/modal`,
        },
      },

      rightsForm: {
        root: {
          pattern: '/themes/preview/:code/rights-form',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/themes/preview/${code}/rights-form`,
        },
      },
    },

    upsert: {
      root: {
        pattern: '/themes/edit/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/themes/edit/${code}`,
      },
    },
  },

  experiencesV2: {
    root: {
      pattern: '/experiences-v2',
      getURL: () => '/consent-and-rights/experiences-home/experiences-v2',
    },

    view: {
      root: {
        pattern: '/experiences-home/experiences-v2/view/:id',
        getURL: ({ id }: IDParams) => `/consent-and-rights/experiences-home/experiences-v2/view/${id}`,
      },

      preview: {
        root: {
          pattern: '/preview',
          getURL: ({ id }: IDParams) => `/consent-and-rights/experiences-home/experiences-v2/view/${id}/preview`,
        },
      },

      subscriptions: {
        root: {
          pattern: '/subscriptions',
          getURL: ({ id }: IDParams) => `/consent-and-rights/experiences-home/experiences-v2/view/${id}/subscriptions`,
        },
      },

      forms: {
        root: {
          pattern: '/forms',
          getURL: ({ id }: IDParams) => `/consent-and-rights/experiences-home/experiences-v2/view/${id}/forms`,
        },
      },

      deployments: {
        root: {
          pattern: '/deployments',
          getURL: ({ id }: IDParams) => `/consent-and-rights/experiences-home/experiences-v2/view/${id}/deployments`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ id }: IDParams) => `/consent-and-rights/experiences-home/experiences-v2/view/${id}/versions`,
        },
      },
    },

    preview: {
      root: {
        pattern: `/experiences-home/experiences-v2/preview/:experienceType/:id?`,
        getURL: ({ id, experienceType }: IDParams & { experienceType: ExperienceType }) =>
          `/consent-and-rights/experiences-home/experiences-v2/preview/${experienceType}${id ? `/${id}` : ''}`,
      },
    },

    upsert: {
      root: {
        pattern: `/experiences-home/experiences-v2/:formMode/:experienceType/:id?`,
        getURL: ({ id, formMode, experienceType }: IDParams & { experienceType: ExperienceType; formMode: FormMode }) =>
          `/consent-and-rights/experiences-home/experiences-v2/${formMode}/${experienceType}${id ? `/${id}` : ''}`,
      },
    },
  },

  themesV3: {
    root: {
      pattern: '/themes-v3/',
      getURL: () => '/consent-and-rights/properties-home/themes-v3/',
    },

    view: {
      root: {
        pattern: '/properties-home/themes-v3/view/:id',
        getURL: ({ id }: IDParams) => `/consent-and-rights/properties-home/themes-v3/view/${id}`,
      },

      preview: {
        root: {
          pattern: '/details',
          getURL: ({ id }: IDParams) => `/consent-and-rights/properties-home/themes-v3/view/${id}/details`,
        },
      },

      deployment: {
        root: {
          pattern: '/deployment',
          getURL: ({ id }: IDParams) => `/consent-and-rights/properties-home/themes-v3/view/${id}/deployment`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ id }: IDParams) => `/consent-and-rights/properties-home/themes-v3/view/${id}/versions`,
        },
      },
    },

    preview: {
      root: {
        pattern: '/themes-v3/preview/:id',
        getURL: ({ id }: IDParams) => `/consent-and-rights/properties-home/themes-v3/preview/${id}`,
      },

      banner: {
        root: {
          pattern: '/themes-v3/preview/:id/banner',
          getURL: ({ id }: IDParams) => `/consent-and-rights/properties-home/themes-v3/preview/${id}/banner`,
        },
      },

      modal: {
        root: {
          pattern: '/themes-v3/preview/:id/modal',
          getURL: ({ id }: IDParams) => `/consent-and-rights/properties-home/themes-v3/preview/${id}/modal`,
        },
      },

      rightsForm: {
        root: {
          pattern: '/themes-v3/preview/:id/rights-form',
          getURL: ({ id }: IDParams) => `/consent-and-rights/properties-home/themes-v3/preview/${id}/rights-form`,
        },
      },
    },

    upsert: {
      root: {
        pattern: `/properties-home/themes-v3/:formMode/:id?`,
        getURL: ({ id, formMode }: IDParams & { formMode: FormMode }) =>
          `/consent-and-rights/properties-home/themes-v3/${formMode}${id ? `/${id}` : ''}`,
      },
    },
  },

  deploymentPlans: {
    root: {
      pattern: '/deployment-plans',
      getURL: () => '/consent-and-rights/deployment-plans',
    },

    view: {
      root: {
        pattern: '/deployment-plans/view/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/deployment-plans/view/${code}`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/deployment-plans/view/${code}/overview`,
        },
      },

      documents: {
        root: {
          pattern: '/documents',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/deployment-plans/view/${code}/documents`,
        },
      },

      deployments: {
        root: {
          pattern: '/deployments',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/deployment-plans/view/${code}/deployments`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/deployment-plans/view/${code}/versions`,
        },
      },
    },

    upsert: {
      root: {
        pattern: '/deployment-plans/edit/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/deployment-plans/edit/${code}`,
      },
    },

    deploy: {
      root: {
        pattern: '/deployment-plans/deploy/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/deployment-plans/deploy/${code}`,
      },
    },

    preview: {
      root: {
        pattern: '/deployment-plans/preview/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/deployment-plans/preview/${code}`,
      },
    },
  },

  deploymentPlansV2: {
    root: {
      pattern: '/deployment-plans-v2',
      getURL: () => '/consent-and-rights/properties-home/deployment-plans-v2',
    },

    view: {
      root: {
        pattern: '/properties-home/deployment-plans-v2/view/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/properties-home/deployment-plans-v2/view/${code}`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ code }: CodeParams) =>
            `/consent-and-rights/properties-home/deployment-plans-v2/view/${code}/overview`,
        },
      },

      preview: {
        root: {
          pattern: '/preview',
          getURL: ({ code }: CodeParams) =>
            `/consent-and-rights/properties-home/deployment-plans-v2/view/${code}/preview`,
        },
      },

      documents: {
        root: {
          pattern: '/documents',
          getURL: ({ code }: CodeParams) =>
            `/consent-and-rights/properties-home/deployment-plans-v2/view/${code}/documents`,
        },
      },

      deployments: {
        root: {
          pattern: '/deployments',
          getURL: ({ code }: CodeParams) =>
            `/consent-and-rights/properties-home/deployment-plans-v2/view/${code}/deployments`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ code }: CodeParams) =>
            `/consent-and-rights/properties-home/deployment-plans-v2/view/${code}/versions`,
        },
      },
    },

    upsert: {
      root: {
        pattern: '/properties-home/deployment-plans-v2/edit/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/properties-home/deployment-plans-v2/edit/${code}`,
      },
    },

    deploy: {
      root: {
        pattern: '/properties-home/deployment-plans-v2/deploy/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/properties-home/deployment-plans-v2/deploy/${code}`,
      },
    },
  },

  languages: {
    root: {
      pattern: '/languages',
      getURL: () => '/settings/languages',
    },
  },

  purposeStacks: {
    root: {
      pattern: '/purpose-stacks',
      getURL: () => '/consent-and-rights/experiences-home/purpose-stacks',
    },

    view: {
      root: {
        pattern: '/experiences-home/purpose-stacks/view/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/experiences-home/purpose-stacks/view/${code}`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ code }: CodeParams) => `/consent-and-rights/experiences-home/purpose-stacks/view/${code}/overview`,
        },
      },

      translations: {
        root: {
          pattern: '/translations',
          getURL: ({ code }: CodeParams) =>
            `/consent-and-rights/experiences-home/purpose-stacks/view/${code}/translations`,
        },
      },
    },

    upsert: {
      root: {
        pattern: '/experiences-home/purpose-stacks/edit/:code',
        getURL: ({ code }: CodeParams) => `/consent-and-rights/experiences-home/purpose-stacks/edit/${code}`,
      },
    },
  },
}
