import React from 'react'
import Grid from '@mui/material/Grid'

import { OverviewConfigDTO } from 'interfaces/experiences/experience'
import { InterpolateLinks } from 'pages/consentAndRights/experiences/components/InterpolateLinks'
import { Text } from 'components/ui-kit/typography/Text'
import { SubSectionTitle } from 'components/ui-kit/typography/compositions/SubSectionTitle'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'

type Props = {
  title?: string
  config?: OverviewConfigDTO
}

export const OverviewDetails: React.FC<Props> = ({ title, config }) => {
  const { tabName, bodyTitle, bodyDescription } = config || {}

  return (
    <>
      <FormRow>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <SubSectionTitle>Main Title</SubSectionTitle>
            {title ? <Text size={14}>{title}</Text> : <EmptyValueRenderer />}
          </Grid>

          <Grid item xs={3}>
            <SubSectionTitle>Tab Name</SubSectionTitle>
            {tabName ? <Text size={14}>{tabName}</Text> : <EmptyValueRenderer />}
          </Grid>

          <Grid item xs={3}>
            <SubSectionTitle>Body Title</SubSectionTitle>
            {bodyTitle ? <Text size={14}>{bodyTitle}</Text> : <EmptyValueRenderer />}
          </Grid>
        </Grid>
      </FormRow>

      <FormRow>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <SubSectionTitle>Body Description</SubSectionTitle>
            {bodyDescription ? (
              <Text size={14}>
                <InterpolateLinks linkColor="light-blue" text={bodyDescription} />
              </Text>
            ) : (
              <EmptyValueRenderer />
            )}
          </Grid>
        </Grid>
      </FormRow>
    </>
  )
}
