import { useState } from 'react'

import { showToast } from 'components/modals/AlertComponent'
import { useParams, useSearchParams } from 'react-router-dom'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { useRightInvocationV2 } from 'api/rightInvocationsV2/queries/useRightInvocationV2'
import { useUpdateWebhookContext } from 'api/executions/mutations/useUpdateWebhookContext'
import { useUsers } from 'api/users/queries/useUsers'
import { useWebhook } from 'api/webhooks/queries/useWebhook'
import { useWebhookContext } from 'api/executions/queries/useWebhookContext'
import { useWorkflowExecutionStepDetails } from 'api/workflowExecutions/queries/useWorkflowExecutionStepDetails'
import { useWorkflowExecutionV2 } from 'api/workflowExecutions/queries/useWorkflowExecutionV2'
import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { useAuth, useClearSearchParamsOnMount } from 'utils/hooks'
import { useUserMe } from 'api/users/queries/useUserMe'
import { useWorkflowExecutionStepDetailsV2 } from 'api/workflowExecutions/queries/useWorkflowExecutionStepDetailsV2'
import { useContextVariablesPaginated } from 'api/contextVariables/queries/useContextVariablesPaginated'
import { ContextVariableCategoryDTO } from '@ketch-com/figurehead'
import { useWorkflow } from 'api/workflows/queries/useWorkflow'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useQueryClient } from 'react-query'
import { getIsWorkflowExecutionStepFailed, getIsWorkflowExecutionStepSuccess } from '../../utils'
import { fromAssignee } from 'utils/helpers/teamStringParser'

export const useWorkflowStepDetailsContainerUtils = () => {
  const queryClient = useQueryClient()
  useClearSearchParamsOnMount()
  const [searchParams] = useSearchParams()
  const {
    id: rightInvocationId,
    stepId: workflowExecutionStepId,
    workflowExecutionId,
  } = useParams<{
    id: string
    stepId: string
    workflowExecutionId: string
  }>()

  const auth = useAuth()
  const { data: userMe } = useUserMe()

  const [hasResolveSucceeded, setHasResolveSucceeded] = useState<boolean>(false)

  /* fetch data */

  const { data: rightInvocation, isLoading: isLoadingRightInvocation } = useRightInvocationV2({
    enabled: !!rightInvocationId,
    params: {
      rightInvocationId: rightInvocationId || '',
      includeMetadata: true,
      includeIssues: true,
    },
  })

  const { data: workflowExecution, isLoading: isLoadingWorkflowExecution } = useWorkflowExecutionV2({
    enabled: !!workflowExecutionId,
    params: {
      workflowExecutionId: workflowExecutionId || '',
    },
  })

  const {
    data: workflowStep,
    isLoading: isLoadingWorkflowStep,
    refetch: refetchWorkflowStep,
  } = useWorkflowExecutionStepDetails({
    enabled: !!workflowExecutionId && !!workflowExecutionStepId,
    params: {
      workflowExecutionId: workflowExecutionId || '',
      stepId: workflowExecutionStepId || '',
    },
  })

  const isUseWebhookContextEnabled =
    !!rightInvocation?.workflowExecutionID &&
    !!workflowExecutionStepId &&
    (workflowStep?.activity?.code === WorkflowActivityCode.API_CALL_V2 ||
      workflowStep?.activity?.code === WorkflowActivityCode.API_CALL)

  const {
    data: webhookContext,
    isLoading: isWebhookContextLoading,
    refetch: refetchContextVariables,
  } = useWebhookContext({
    enabled: isUseWebhookContextEnabled,
    params: { workflowExecutionId: rightInvocation?.workflowExecutionID, stepId: workflowExecutionStepId },
    onError: () => {
      showToast({ content: 'Failed to fetch API call details', type: 'error' })
    },
  })

  const { data: dataSubjectVariables, isLoading: isLoadingDataSubjectVariables } = useContextVariablesPaginated({
    params: { limit: 2000, category: ContextVariableCategoryDTO.DataSubjectContextVariableCategory },
  })

  const { data: webhook, isLoading: isWebhookLoading } = useWebhook({
    params: { webhookId: webhookContext?.webhook?.id },
    enabled: !isWebhookContextLoading && !!webhookContext && !!webhookContext?.webhook?.id,
  })

  const { data: users, isLoading: isUsersLoading } = useUsers({
    params: {
      active: true,
    },
  })

  const { data: stepDetails, isLoading: isStepDetailsLoading } = useWorkflowExecutionStepDetailsV2({
    enabled: !!workflowExecutionId && !!workflowExecutionStepId,
    params: { workflowExecutionId: workflowExecutionId || '', stepId: workflowExecutionStepId || '' },
    onError: () => {
      showToast({ content: 'Failed to fetch workflow step', type: 'error' })
    },
  })

  const { data: workflow, isLoading: isLoadingWorkflow } = useWorkflow({
    enabled: !!workflowExecution?.workflowDefinitionCode,
    params: {
      workflowCode: workflowExecution?.workflowDefinitionCode || '',
    },
  })

  /* submit handlers */

  const { mutateAsync: submitWebhookContext } = useUpdateWebhookContext({
    onSuccess: async () => {
      showToast({ content: 'Successfully updated API call', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update API call', type: 'error' })
    },
  })

  const submitNotes = async (notes: string) => {
    const formData = { notes }
    await submitWebhookContext({
      params: { formData, workflowExecutionId: rightInvocation?.workflowExecutionID, stepId: workflowExecutionStepId },
    })
  }

  async function refetchStepDetails() {
    await queryClient.refetchQueries([
      ApiQueryKeys.workflowExecutionStepDetailsV2,
      { workflowExecutionId: rightInvocation?.workflowExecutionID, stepId: workflowStep?.ID },
    ])

    await queryClient.refetchQueries([
      ApiQueryKeys.workflowExecutionStepDetails,
      {
        workflowExecutionId: rightInvocation?.workflowExecutionID,
        stepId: workflowStep?.ID,
        organizationCode: auth?.userData?.organizationCode,
      },
    ])
  }

  /* derived state */

  const isRightsQueueAdmin = !!userMe?.roles?.find(role => role.code === 'queue_admin')
  const isTaskComplete = workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS
  const [assignedTeam, teamAssignee] = fromAssignee(stepDetails?.assignee || '')
  const isAssignedToMe = teamAssignee === userMe?.ID

  const hasAttemptedToResolve = searchParams.get('hasAttemptedToResolve') === 'true'
  const isWorkflowExecutionTerminated = workflowExecution?.status === WorkflowExecutionStepStatus.TERMINATED
  const isWorkflowExecutionStepFailed = getIsWorkflowExecutionStepFailed(workflowStep)
  const isWorkflowExecutionStepSuccess = getIsWorkflowExecutionStepSuccess(workflowStep)
  const canEdit = isWorkflowExecutionStepFailed && isAssignedToMe && !isWorkflowExecutionTerminated

  const appealReason = rightInvocation?.appeal?.appealReason

  const isReady =
    !isStepDetailsLoading &&
    !isLoadingRightInvocation &&
    !isLoadingWorkflowExecution &&
    !isLoadingWorkflowStep &&
    !isWebhookContextLoading &&
    !isWebhookLoading &&
    !isUsersLoading &&
    !isLoadingDataSubjectVariables &&
    !isLoadingWorkflow

  const payload = {
    appealReason,
    auth,
    canEdit,
    dataSubjectVariables,
    hasAttemptedToResolve,
    hasResolveSucceeded,
    isAssignedToMe,
    isWorkflowExecutionStepFailed,
    isWorkflowExecutionStepSuccess,
    isReady,
    isRightsQueueAdmin,
    isStepDetailsLoading,
    isTaskComplete,
    isWorkflowExecutionTerminated,
    refetchContextVariables,
    refetchStepDetails,
    refetchWorkflowStep,
    rightInvocation,
    rightInvocationId,
    setHasResolveSucceeded,
    stepDetails,
    stepId: workflowExecutionStepId,
    submitNotes,
    userMe,
    users,
    webhook,
    webhookContext,
    workflow,
    workflowExecution,
    workflowExecutionId,
    workflowStep,
    assignedTeam,
    teamAssignee,
  }

  return payload
}

export type useWorkflowStepDetailsContainerUtilsReturn = ReturnType<typeof useWorkflowStepDetailsContainerUtils>
