import { ColorNames } from '@ketch-com/deck'

type GetConsentRequiredColorArgs = {
  consentRequiredCount?: number
  totalCount?: number
}

export const getConsentRequiredColor = ({
  consentRequiredCount = 0,
  totalCount = 0,
}: GetConsentRequiredColorArgs): ColorNames => {
  if (consentRequiredCount && totalCount && consentRequiredCount === totalCount) return 'darkDusk'
  if (!consentRequiredCount) return 'graph13'
  if (consentRequiredCount && totalCount && consentRequiredCount < totalCount) return 'graph11'
  return 'darkDusk'
}
