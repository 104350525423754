import Box from '@mui/material/Box'

import { Text } from 'components/ui-kit/typography/Text'

type TooltipProps = {
  active?: boolean
  payload: any
  teamMemberName?: string
}

export const AccessUtilizationGraphTooltip = ({ active, payload, teamMemberName }: TooltipProps) => {
  if (active && payload) {
    const [{ payload: subPayload }] = payload
    const displayName = subPayload?.name === 'Team Member' ? teamMemberName : subPayload?.name
    return (
      <Box
        sx={{
          background: ({ palette }) => palette.darkDusk.main,
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          py: 1,
          pl: 1.25,
          pr: 3,
        }}
      >
        <Text size={12} weight={700} color="white" mb={0.5}>
          {displayName}
        </Text>

        <Text size={12} weight={500} color="white" mb={0.5}>
          Access {subPayload?.y}&#x25;
        </Text>

        <Text size={12} weight={500} color="white">
          Usage {subPayload?.x}&#x25;
        </Text>
      </Box>
    )
  }

  return null
}
