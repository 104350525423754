import React from 'react'

import { MaybeNull } from 'interfaces'
import { WorkflowDTO } from 'interfaces/workflows'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { Banner, Button } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  workflow: MaybeNull<WorkflowDTO>
}

export const WorkflowVersionsMessage: React.FC<Props> = () => {
  const { removeQueryParam } = useQueryParams<{ version?: string }>()

  return (
    <Banner
      sx={{
        width: '100%',
      }}
      title="You are viewing an old version of this workflow. Return to the current version to manage or activate."
      action={
        <Button
          variant="link"
          onClick={() => removeQueryParam('version')}
          sx={{
            '&.MuiButtonBase-root.MuiButton-root': {
              '&:hover': {
                '& .DeckBtnWrapChild': {
                  borderColor: theme => theme.palette.chileanFire.main,
                },
              },
            },
          }}
        >
          <Typography variant="label" color="chileanFire.main">
            View Current
          </Typography>
        </Button>
      }
      severity="warning"
    />
  )
}
