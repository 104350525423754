import { SubscriptionTopicTranslationDTO, WebhookDTO, AppDTO } from '@ketch-com/figurehead'
import { FormMode } from 'interfaces/formModes/formMode'

export enum SubscriptionsTopicFormStep {
  BASIC_DETAILS = 'Basic Details',
  CHANNELS = 'Channels',
  LEGAL_BASIS = 'Legal Basis',
}

export enum OrchestrationType {
  WEBHOOK = 'webhook',
  SYSTEM = 'system', // App
}

export type OrchestrationData = {
  type?: OrchestrationType
  webhookId?: string
  appCode?: string
  name?: string
  appLogo?: { url?: string; title?: string }
  appInstanceId?: string
  appParams?: {
    [key: string]: string
  }
  appInstanceName?: string
}

export type SubscriptionTopicFormData = {
  basicDetails: {
    name: string
    description: string
    code: string
    translations: { [key: string]: SubscriptionTopicTranslationDTO }
  }
  contactMethods: {
    // contact method/channel code
    [key: string]: {
      status: boolean
      orchestrations: OrchestrationData[]
    }
  }
  legalBasis: {
    code: string
  }
}

export type SubscriptionControlFormData = {
  basicDetails: {
    name: string
    description: string
    code: string
    translations: { [key: string]: SubscriptionTopicTranslationDTO }
  }
  orchestrations: OrchestrationData[]
}

export type UrlParams = {
  code: string
  formMode: FormMode
}

export type WebhookOptions = {
  id?: string
  name?: string
  url?: string
}

export type OrchestrationOptionsData = WebhookDTO & AppDTO & { system?: string; webhook?: string }

export type ConnectionItem = {
  id?: string
  name?: string
  disabled?: boolean
}
