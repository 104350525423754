import { ContextVariableCategoryDTO, ContextVariableDTO } from '@ketch-com/figurehead'
import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { GatewayTransitionDTO } from 'pages/orchestration/workflows/edit/interfaces/ICanvasStep'
import { getStepsToStartCount } from 'pages/orchestration/workflows/edit/utils/steps'
import pluralize from 'pluralize'

type Args = {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  ctxVariable: ContextVariableDTO
}

export const getIsVariableReferencedInDownstreamDecisionGateway = ({ step, steps, ctxVariable }: Args) => {
  const sortedSteps = [...steps].sort((a, b) => {
    const aStepCountFromStepToStart = getStepsToStartCount({ step: a, steps })
    const bStepCountFromStepToStart = getStepsToStartCount({ step: b, steps })

    return aStepCountFromStepToStart - bStepCountFromStepToStart
  })

  const { code } = ctxVariable

  const stepIndex = sortedSteps.findIndex(s => s.ID === step.ID)

  const downstreamDecisionGatewaysTransitions = sortedSteps
    .filter((s, i) => i > stepIndex && s.activityCode === WorkflowActivityCode.DECISION_MULTI)
    .map(gw => gw?.gateway?.transitions)
    .flat() as GatewayTransitionDTO[]

  const downstreamDecisionGateways = sortedSteps.filter(
    (s, i) => i > stepIndex && s.activityCode === WorkflowActivityCode.DECISION_MULTI,
  )

  const downstreamDecisionGatewaysReferencingVariable = downstreamDecisionGateways.filter(
    gatewayStep => gatewayStep?.gateway?.transitions?.find(transition => transition.variable === code),
  )

  const isReferenced = !!downstreamDecisionGatewaysTransitions.find(transition => transition.variable === code)

  const ctxVariableName = ctxVariable?.name || ctxVariable?.code

  const outcomeWarningMessage = isReferenced
    ? `Variable, "${ctxVariableName}" is required for automated path selection in ${pluralize(
        'Gateway',
        downstreamDecisionGatewaysReferencingVariable.length || 0,
      )} ${downstreamDecisionGatewaysReferencingVariable
        .map(gw => `"${gw.description}"`)
        .join(', ')}.  You must first remove the reference before deleting "${ctxVariableName}".`
    : ''

  const dsrWarningMessage = isReferenced
    ? `Variable, "${ctxVariableName}" is required for automated path selection in ${pluralize(
        'Gateway',
        downstreamDecisionGatewaysReferencingVariable.length || 0,
      )} ${downstreamDecisionGatewaysReferencingVariable
        .map(gw => `"${gw.description}"`)
        .join(', ')}.  Are you sure you want to delete "${ctxVariableName}"?`
    : ''

  return {
    isReferenced,
    warningMessage:
      ctxVariable?.category === ContextVariableCategoryDTO.OutcomeContextVariableCategory
        ? outcomeWarningMessage
        : dsrWarningMessage,
  }
}
