import React from 'react'

import { DataReviewStepDTO } from '@ketch-com/figurehead'
import { Button, PopUp, InfoRow } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

const titleMap = {
  'Approve Data Redaction': 'Approval',
  'Save Data Redaction': 'Save',
  'Submit for Approval Data Redaction': 'Submit',
}
type Props = {
  buttonLabel: string
  dataReviewSteps?: DataReviewStepDTO[]
  invalidFormatFiles: string[]
  modalSubmitType: string
  onCancel: () => void
  onSubmit?: () => void
  totalRedactionCount?: number
}

export const RedactionHeaderActionsModal: React.FC<Props> = ({
  buttonLabel,
  // dataReviewSteps,
  invalidFormatFiles,
  modalSubmitType,
  onCancel,
  onSubmit,
  // totalRedactionCount
}) => {
  const title = (Object.keys(titleMap) as (keyof typeof titleMap)[]).find(key => {
    return titleMap[key] === modalSubmitType
  })

  // const filesTotal = dataReviewSteps.reduce((acc, data) => acc + (data?.files || []).length, 0)

  return (
    <PopUp
      title={title}
      variant="dialog"
      popUpWidth="880px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Close
          </Button>
          <Button color="primary" size="large" onClick={onSubmit}>
            {modalSubmitType === 'Approval'
              ? buttonLabel
              : modalSubmitType === 'Submit'
              ? 'Submit for Approval'
              : 'Save'}
          </Button>
        </>
      }
    >
      <InfoRow title="Note">
        <Box display="flex" flexDirection="column">
          <Typography variant="label" color="chileanFire.main">
            Your {modalSubmitType === 'Submit' ? 'submission' : modalSubmitType.toLowerCase()} is final.
          </Typography>
          <Typography>After saving you won't be able to undo any changes.</Typography>
        </Box>
      </InfoRow>

      {!!invalidFormatFiles.length && (
        <InfoRow title="Error">
          <Box display="flex" flexDirection="column">
            <Typography variant="label" color="Red.Dark.o100">
              Formatting errors exist in the following file{`${invalidFormatFiles.length > 0 ? 's' : ''}`}:
            </Typography>
            <Typography>After saving you won't be able to undo any changes.</Typography>
          </Box>
        </InfoRow>
      )}

      {/* TODO:GP add counts when logic is sound */}
      {/* {!!totalRedactionCount && (
        <Grid
          container
          spacing={1}
          sx={{
            borderBottom: ({ palette }) => `1px solid ${palette.iron.main}`,
            pt: 2,
            pb: 2,
          }}
        >
          <Grid item xs={3}>
            <Text size={14} color="darkDuskFaded">
              Summary
            </Text>
          </Grid>
          <Grid item xs={9} display="flex">
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                border: ({ palette }) => `1px solid ${palette.iron.main}`,
                borderRadius: 2,
                p: 1.5,
                mr: 1,
              }}
            >
              <Text size={16} weight={700} mb={1.5}>
                {filesTotal}
              </Text>
              <Text size={12} weight={500}>
                Files Total
              </Text>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                border: ({ palette }) => `1px solid ${palette.iron.main}`,
                borderRadius: 2,
                p: 1.5,
                mr: 2,
              }}
            >
              <Text size={16} weight={700} mb={1.5}>
                {totalRedactionCount}
              </Text>
              <Text size={12} weight={500}>
                Items Redacted
              </Text>
            </Box>
          </Grid>
        </Grid>
      )} */}
    </PopUp>
  )
}
