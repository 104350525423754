import { formatRequestString } from '../../../../utils/helpers/api/queryString'
import { KETCH_CDN_API } from '../client/client'

type KetchCdnFetchIpDTO = {
  location?: {
    countryCode?: string
    regionCode?: string
  }
}

export const fetchIpLocation = () =>
  KETCH_CDN_API.get<KetchCdnFetchIpDTO>(
    formatRequestString({
      entityUrl: `/web/v2/ip`,
    }),
  )
