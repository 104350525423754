import React from 'react'
import { Box } from '@mui/material'
import { SignUpCompleteForm } from 'pages/onboarding/signUp/components/SignUpCompleteForm'
import { SignUpForm } from 'pages/onboarding/signUp/components/SignUpForm'
import { SignUpFormFields } from 'pages/onboarding/signUp/common/types/signUp'
import { useFormikContext } from 'formik'
import { Spinner } from '@ketch-com/deck'
import { Routes, Route } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { relativePath } from 'utils/routing/relativePath'
import { SignUpCompleted } from 'pages/onboarding/signUp/components/SignUpCompleted'

export const MobileSignUp: React.FC = () => {
  const { isSubmitting } = useFormikContext<SignUpFormFields>()
  if (isSubmitting)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" width="100vw" height="100vh">
        <Spinner size="30px" thickness={3} />
      </Box>
    )
  return (
    <Box px={2} py={4}>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <SignUpForm isMobileLayout={true} />
              <SignUpCompleteForm isMobileLayout={true} />
            </>
          }
        />
        <Route
          path={relativePath(
            RoutesManager.onboarding.signUp.complete.pattern,
            RoutesManager.onboarding.signUp.root.pattern,
          )}
          element={
            <>
              <SignUpForm isMobileLayout={true} />
              <SignUpCompleteForm isMobileLayout={true} />
            </>
          }
        />
        <Route
          path={relativePath(
            RoutesManager.onboarding.signUp.completed.pattern,
            RoutesManager.onboarding.signUp.root.pattern,
          )}
          element={<SignUpCompleted isMobileLayout={true} />}
        />
      </Routes>
    </Box>
  )
}
