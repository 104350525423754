import { object, string, array } from 'yup'

export const getValidationSchema = () =>
  object().shape({
    name: string().max(75, 'Name must not exceed 75 characters').required('Required'),
    description: string(),
    purpose: object().shape({
      id: string().required('Required'),
    }),
    accessAllowances: array().of(
      object().shape({
        assetType: string().required('Required'),
        criteria: string().required('Required'),
        labelName: object()
          .shape({
            named: string(),
            type: string(),
            labeled: object().shape({
              code: string(),
              value: string(),
              name: string(),
            }),
          })
          .test('Allowance Label Name', 'Required', (value, context: any) => {
            const [, { value: firstParentValue }] = context.from
            /* If assetType or criteria have not been selected cannot validate, so, default to true ("pass") */
            if (!firstParentValue?.assetType || !firstParentValue?.criteria) return true

            if (firstParentValue?.criteria === 'labeled') {
              return Object.keys(firstParentValue?.labelName || {}).length > 0
            }

            if (firstParentValue?.criteria === 'named') {
              return !!firstParentValue?.labelName?.named
            }

            return false
          }),
      }),
    ),
    attributeEffects: array().of(
      object().shape({
        effect: string().required('Required'),
        criteria: string().required('Required'),
        labelName: object()
          .shape({
            named: string(),
            type: string(),
            labeled: object().shape({
              code: string(),
              value: string(),
              name: string(),
            }),
          })
          .test('Effect Label Name', 'Required', (value: any, context: any) => {
            const [, { value: firstParentValue }] = context.from
            /* If effect or criteria have not been selected cannot validate, so, default to true ("pass") */
            if (!firstParentValue?.effect || !firstParentValue?.criteria) return true

            if (firstParentValue?.criteria === 'labeled') {
              return !!value?.code
            }

            if (firstParentValue?.criteria === 'named') {
              return !!value?.named
            }

            return false
          }),
      }),
    ),
    filters: array().of(
      object().shape({
        name: string().required('Required'),
        condition: string().required('Required'),
        value: string().required('Required'),
      }),
    ),
  })
