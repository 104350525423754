import React, { createContext } from 'react'
import { SystemsOverviewUtils, useSystemsOverviewUtils } from '../utils/useSystemsOverviewUtils'

export const SystemsOverviewContext = createContext({} as SystemsOverviewUtils)

type Props = {
  children?: React.ReactNode | undefined
}

export const SystemsOverviewContextProvider: React.FC<Props> = ({ children }) => {
  // Use utils hook
  const hookValues = useSystemsOverviewUtils()

  return <SystemsOverviewContext.Provider value={{ ...hookValues }}>{children}</SystemsOverviewContext.Provider>
}

export const withSystemsOverviewContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <SystemsOverviewContextProvider>
        <Component {...props} />
      </SystemsOverviewContextProvider>
    )
  }
}
