import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { FindSubscriptionContactMethodsResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

type Params = APIListRequestParams<{
  code?: string
}>

export const fetchSubscriptionContactMethods = ({ start, limit, query, includeMetadata }: Params) =>
  API.get<FindSubscriptionContactMethodsResponseBodyDTO>(
    formatRequestString({
      entityUrl: '/api/subscriptions/methods',
      params: {
        filters: {
          start,
          limit,
          ...(query && {
            query,
          }),
          ...(includeMetadata && {
            includeMetadata,
          }),
        },
      },
    }),
  )
