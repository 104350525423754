import React from 'react'
import { Box } from '@mui/material'
import { ReactComponent as SuccessfulRegistrationMail } from 'assets/icons/registration_account_created.svg'
import { RegistrationHeading } from '../components/RegistrationHeading'

export const Successful = ({ props }: any) => {
  return (
    <>
      <RegistrationHeading formTitle="Account created." formSubtitle="Check your mailbox for an activation link." />
      <Box mb={4}>
        <SuccessfulRegistrationMail />
      </Box>
    </>
  )
}
