import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { useAuth } from 'utils/hooks/useAuth'
import { bulkEnableWebTags } from '../fetchers/bulkEnableWebTags'

const useCustomMutation = createUseMutation({
  mutationFn: bulkEnableWebTags,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useBulkEnableWebTags = (config?: Config) => {
  const { userData } = useAuth()
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    organizationCode: userData.organizationCode,
  })
}
