import { Auth0Provider, CacheLocation } from '@auth0/auth0-react'
import { useAuthConfig } from 'api/auth/queries/useAuthConfig'
import { Navigate, Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { AuthProviderHelper } from './AuthProviderHelper'
import { RoutesManager } from 'utils/routing/routesManager'
import { InitialLoadingScreen } from 'pages/auth/InitialLoadingScreen'
import { CacheKeys, retrieve } from 'utils/CacheUtils'

export const Auth0ProviderLayout: React.FC = () => {
  const {
    data: authConfig,
    isLoading: isLoadingAuthConfig,
    isError: isAuthConfigError,
  } = useAuthConfig({
    staleTime: Infinity,
  })
  const navigate = useNavigate()
  const savedOrgId = retrieve(CacheKeys.orgId)
  const [searchParams] = useSearchParams()

  /** onRedirectCallback allows user to be redirected to the page they were attempting
   *  to access before being redirected to the login flow
   */
  const onRedirectCallback = (appState: any | undefined) => {
    if (appState?.returnTo) navigate(appState?.returnTo)
  }

  // https://community.auth0.com/t/why-is-authentication-lost-after-refreshing-my-single-page-application/56276
  const optionsToPersistSessionAcrossPageRefresh = {
    cacheLocation: 'localstorage' as CacheLocation,
    useRefreshTokens: true,
  }

  const authConfigPayload = {
    domain: authConfig?.domain || '',
    clientId: authConfig?.clientId || '',
    audience: authConfig?.audience || '',
    scope: authConfig?.scope || '',
    useRefreshTokens: authConfig?.useRefreshTokens || true,
    redirectUri: authConfig?.redirectUri || window.location.origin + RoutesManager.callback.root.getURL() || '',
    ...(searchParams.get('invitation') && {
      invitation: searchParams.get('invitation') || '',
    }),
    ...((authConfig?.organization || savedOrgId || searchParams.get('organization')) && {
      organization: searchParams.get('organization') || authConfig?.organization || savedOrgId || '',
    }),
  }

  if (isLoadingAuthConfig) return <InitialLoadingScreen />

  if (isAuthConfigError) return <Navigate to={RoutesManager.auth.error.root.getURL()} />

  return (
    <Auth0Provider
      {...authConfigPayload}
      {...optionsToPersistSessionAcrossPageRefresh}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthProviderHelper>
        <Outlet />
      </AuthProviderHelper>
    </Auth0Provider>
  )
}
