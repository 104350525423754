import { FindDataSensitivityV3ResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type FetchDataSensitivitiesParams = APIListRequestParams

export const fetchDataSensitivities = ({ limit, start }: FetchDataSensitivitiesParams) => {
  let entityUrl = '/api/v3/data-sensitivities'

  return API.get<FindDataSensitivityV3ResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          start,
          limit,
        },
      },
    }),
  )
}
