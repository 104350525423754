import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'

interface IGetInternalSystemDSRWorkflowInitialValuesParams {
  step: ICanvasStep
}

interface IGetInternalSystemDSRWorkflowInitialValues {
  description: string
  code: string
}

export const getInternalSystemDSRWorkflowInitialValues = (
  params: IGetInternalSystemDSRWorkflowInitialValuesParams,
): IGetInternalSystemDSRWorkflowInitialValues => {
  const { step } = params

  const description = step.description || ''
  const code = step.code || ''

  return {
    description,
    code,
  }
}
