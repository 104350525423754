import React, { useState } from 'react'
import { Formik } from 'formik'
import { compact } from 'lodash'
import { showToast } from 'components/ui-kit/toastr/Toastr'

import { IApiCallActivityAltParams, ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'
import { formatCodeFromName } from 'utils/formatters'
import { useWebhooks } from 'api/webhooks/queries/useWebhooks'
import { getApiCallInitialValues, getApiCallValidationSchema } from './utils'
import { useUsers } from 'api/users/queries/useUsers'
import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { ApiCallTileRemovalWarningModal } from './components'
import { GatewayTransitionDTO } from 'pages/orchestration/workflows/edit/interfaces/ICanvasStep'
import { Box, Typography } from '@mui/material'
import { FormInput } from 'components/form/FormInput'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { FormAssigneeOrTeamSelector } from 'components/form/FormAssigneeOrTeamSelector'

export type ApiCallRemovalWarningMessageConfig = {
  outcomeVariableStepID: string
  workflowStep: ICanvasStep
  transition: GatewayTransitionDTO
  apiCallStep: ICanvasStep
}

interface IApiCallFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const ApiCallForm: React.FC<IApiCallFormProps> = ({ step, steps, handleClose, handleRemove, handleSave }) => {
  const [removalWarningConfig, setRemovalWarningConfig] = useState<ApiCallRemovalWarningMessageConfig[]>([])
  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))
  const { isLoading: isLoadingUsers } = useUsers({ params: { active: true } })

  const { data: webhooks, isLoading: isLoadingWebhooks } = useWebhooks({
    itemsPerPage: 1000,
    params: {
      event: 'rights',
    },
    onError: () => {
      showToast({ content: 'Failed to fetch webhooks', type: 'error' })
    },
  })

  const onSubmit = (values: IApiCallActivityAltParams) => {
    handleSave({
      step: {
        ...step,
        valid: true,
        code: values.code,
        description: values.description,
        workflow: {
          ...step.workflow,
          params: {
            assignee: values.assignee,
            webhookId: values.webhookId,
            requestTimeout: values.requestTimeout,
            timeToCompleteDays: values.timeToCompleteDays,
          },
        } as ICanvasStep['workflow'],
      },
    })
  }

  return (
    <SidebarConfigsWrapper isLoading={isLoadingWebhooks || isLoadingUsers} handleClose={handleClose}>
      <Formik
        initialValues={getApiCallInitialValues({ step })}
        validationSchema={getApiCallValidationSchema({ usedCodes })}
        initialErrors={{ error: 'error' }}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {form => {
          const { isValid, submitForm, setFieldValue } = form

          return (
            <>
              <SidebarConfigsHeader
                isValid={isValid}
                step={step}
                steps={steps}
                handleSave={() => submitForm()}
                copyable
                handleRemove={() => {
                  // const allWorkflowApiCallTileSteps = steps.filter(
                  //   step => step?.activityCode === WorkflowActivityConnectorCode.API_CALL_V2,
                  // )

                  const warningMessageConfig: ApiCallRemovalWarningMessageConfig[] = []

                  const allWorkflowDecisionMultiSteps = steps.filter(
                    step => step?.activityCode === WorkflowActivityCode.DECISION_MULTI,
                  )

                  allWorkflowDecisionMultiSteps.forEach(workflowStep => {
                    workflowStep?.gateway?.transitions.forEach(transition => {
                      if (transition?.outcomeVariableStepID && transition?.outcomeVariableStepID === step.ID) {
                        warningMessageConfig.push({
                          outcomeVariableStepID: transition?.outcomeVariableStepID,
                          workflowStep,
                          transition,
                          apiCallStep: step,
                        })
                      }
                    })
                  })

                  if (!!warningMessageConfig.length) {
                    setRemovalWarningConfig(warningMessageConfig)
                    return
                  }

                  handleRemove({ step })
                }}
                handleClose={handleClose}
              />

              <SidebarConfigsBody sx={{ px: 3 }}>
                <Box mb={3}>
                  <FormInput
                    required
                    formPropertyName="description"
                    fullWidth
                    label="Description Label"
                    placeholder="Enter description"
                    onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
                    shouldUpdateDebounced
                  />
                </Box>

                <Box mb={5}>
                  <FormInput
                    formPropertyName="code"
                    fullWidth
                    label="Code"
                    placeholder="Enter code"
                    required
                    shouldUpdateDebounced
                  />
                </Box>

                <Typography variant="h3" sx={{ mb: 3 }}>
                  Configuration
                </Typography>
                <FormDroplistButton
                  disableClearable
                  required
                  fullWidth
                  formPropertyName="webhookId"
                  label="Webhook"
                  placeholder="Select Webhook"
                  valueKey="id"
                  items={webhooks.map(webhook => ({
                    id: webhook.id,
                    name: webhook.name,
                  }))}
                  sx={{ mb: 5 }}
                />

                {/*TODO readd after support*/}
                {/*<FormInput*/}
                {/*  required*/}
                {/*  type="number"*/}
                {/*  name="requestTimeout"*/}
                {/*  placeholder=""*/}
                {/*  label="Request Timeout, Minutes"*/}
                {/*  min={1}*/}
                {/*  max={4000}*/}
                {/*  hint="4000 minutes is the maximum amount."*/}
                {/*  width={100}*/}
                {/*  sx={{ mb: 6 }}*/}
                {/*/>*/}

                <Typography variant="h3" sx={{ mb: 3 }}>
                  Assignee
                </Typography>

                <FormAssigneeOrTeamSelector label="Assignee" name="assignee" />

                <Typography variant="body" component="div">
                  Select a person for the review process if the API Call configuration returns an error or requires
                  manual intervention. Admins still can re-assign this activity later in the DSR process.
                </Typography>

                {/* Activity Timer - TODO:JA re enable when issue causing KD-10288 is resolved */}
                {false ? (
                  <FormInput
                    sx={{
                      mt: 3,
                    }}
                    type="number"
                    formPropertyName="timeToCompleteDays"
                    label="Time To Complete (Days)"
                    placeholder="0"
                    inputWidth="80px"
                    shouldUpdateDebounced
                    alwaysShowError
                  />
                ) : null}
              </SidebarConfigsBody>
            </>
          )
        }}
      </Formik>

      <ApiCallTileRemovalWarningModal
        setRemovalWarningConfig={setRemovalWarningConfig}
        removalWarningConfig={removalWarningConfig}
      />
    </SidebarConfigsWrapper>
  )
}
