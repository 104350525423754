import { FormEditor, renderDocumentLinkerModal } from 'components/ui-kit/form/editor'
import { experienceUpsertFormKeys } from '../../../../utils/useExperienceValidationSchema'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { useContext } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { FormSectionSwitch } from '../FormSectionSwitch'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { Box } from '@mui/material'

export const ProgressiveExperienceDescriptionForm: React.FC = () => {
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const { values } = useFormikContext<ExperienceV2DTO>()
  const hasLanguages = !isLanguagesLoading && !!languages.length
  const showForm = getIn(values, experienceUpsertFormKeys.progressiveExperienceDescriptionVisible)
  return (
    <>
      <FormSectionSwitch
        name={experienceUpsertFormKeys.progressiveExperienceDescriptionVisible}
        title={'Description'}
      />
      {showForm && (
        <Box p={'16px 18px'}>
          <SidebarFormGroup hasBorderBottom={hasLanguages} sx={{ pt: 0 }}>
            <FormEditor
              label={'Description Text'}
              required={false}
              id={experienceUpsertFormKeys.progressiveExperienceDescription.replaceAll('.', '_')}
              name={experienceUpsertFormKeys.progressiveExperienceDescription}
              linkChangeModal={renderDocumentLinkerModal}
              charLimit={1600}
            />
          </SidebarFormGroup>
          <SidebarFormTranslations
            title={'Description Text Translations'}
            name={experienceUpsertFormKeys.progressiveExperienceDescription}
            variant={'richText'}
            charLimit={1600}
          />
        </Box>
      )}
    </>
  )
}
