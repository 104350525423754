import {
  ApiCallContext,
  withApiCallContext,
} from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/context/ApiCallContext'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { RoutesManager } from 'utils/routing/routesManager'

import {
  ApiCallStepDetails,
  ApiCallStepDetailsTimeline,
  ApiCallStepDetailsHeaderDetails,
  ApiCallStepDetailsHeaderActions,
} from './components'
import { StepDetailsColumn, WorkflowStepHeaderIcon } from 'pages/orchestration/rightsQueue/stepDetails/components'
import { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Box } from '@mui/material'
import { Spinner, ViewLayout } from '@ketch-com/deck'

type Props = {}

export const ApiCallViewWithContext: React.FC<Props> = () => {
  const { workflowStep, stepId, workflowExecutionId } = useContext(RightInvocationWorkflowStepDetailViewContext)
  const { webhookContext, isReady, resolve } = useContext(ApiCallContext)
  const navigate = useNavigate()
  const { id } = useParams<{
    id?: string
  }>()

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    { title: 'Rights Queue', link: RoutesManager.orchestration.rightsQueue.root.getURL() },
    {
      title: 'Request',
      link: RoutesManager.orchestration.rightsQueue.view.root.getURL({ id }),
    },
    {
      title: 'API Call',
      link: RoutesManager.orchestration.rightsQueue.stepDetails.root.getURL({ id, stepId, workflowExecutionId }),
    },
  ]

  if (!isReady) {
    return (
      <>
        <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )
  }

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      <ViewLayout
        contentGroupProps={{
          title: workflowStep?.name,
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id })),
          },
          imageBlockComponent: <WorkflowStepHeaderIcon />,
          infoBlockComponent: <ApiCallStepDetailsHeaderDetails workflowStep={workflowStep} />,
          actionRightBlockComponent: (
            <ApiCallStepDetailsHeaderActions
              resolve={resolve}
              workflowStep={workflowStep}
              webhookContext={webhookContext}
            />
          ),
        }}
        widgetContentComponent={<StepDetailsColumn />}
      >
        <Box display="flex" flexDirection="column" gap={6} mb={6}>
          <ApiCallStepDetails />

          <ApiCallStepDetailsTimeline webhookContext={webhookContext || {}} />
        </Box>
      </ViewLayout>
    </>
  )
}

export const ApiCallView = withApiCallContext(ApiCallViewWithContext)
