import React, { createContext, FC } from 'react'
import { useFormTemplateUpsertContainerUtils, UseFormFieldUpsertContainerUtilsReturnType } from '../hooks'

export const FormTemplateUpsertContext = createContext({} as UseFormFieldUpsertContainerUtilsReturnType)

type Props = {
  children: React.ReactNode
}
export const FormTemplateUpsertContextProvider: FC<Props> = ({ children }) => {
  const values = useFormTemplateUpsertContainerUtils()
  return (
    <FormTemplateUpsertContext.Provider
      value={{
        ...values,
      }}
    >
      {children}
    </FormTemplateUpsertContext.Provider>
  )
}

export const withFormTemplateUpsertContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <FormTemplateUpsertContextProvider>
        <Component {...props} />
      </FormTemplateUpsertContextProvider>
    )
  }
}
