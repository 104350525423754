import { Box } from '@mui/material'
import { Chip } from 'components/ui-kit/chip/Chip'
import { LinkChip } from 'components/ui-kit/chip/LinkChip'
import { GreyBox } from '../../components/layout/GreyBox'
import { Text } from 'components/ui-kit/typography/Text'
import { useState } from 'react'
import { Policy } from '../../__mocks__/policiesControls'
import { DataRiskDetailLayoutClasses } from '../../components/layout/DataRiskDetailLayout'

type Props = {
  record: Policy
}

export const PoliciesControlsLowerContent: React.FC<Props> = ({ record }) => {
  const { purposes, personalDataTypes, dataCategories } = record
  const [showMore, setShowMore] = useState(false)

  const categoriesToShow = (showMore ? dataCategories : dataCategories?.slice(0, 9)) || []

  return (
    <>
      <GreyBox flexBasis="50%" heading="Data">
        <Text component="div" color="grey" sx={{ mb: 1.5 }}>
          Data Sensitivity
        </Text>
        <Box mb={3.5}>
          {personalDataTypes?.map((dataType, index) => (
            <Chip className={DataRiskDetailLayoutClasses.chip} key={index}>
              {dataType}
            </Chip>
          ))}
        </Box>
        <Text component="div" color="grey" sx={{ mb: 1.5 }}>
          Classifications
        </Text>
        <Box>
          {categoriesToShow.map((category, index) => (
            <Chip className={DataRiskDetailLayoutClasses.chip} key={index}>
              {category}
            </Chip>
          ))}
        </Box>
        {(dataCategories?.length || 0) - 10 > 0 && (
          <Text sx={{ mt: 1.5 }} component="div" color="royalBlue" onClick={() => setShowMore(!showMore)}>
            {showMore ? 'Show Less' : `View ${(dataCategories?.length || 0) - 10} More`}
          </Text>
        )}
      </GreyBox>
      <GreyBox flexBasis="50%" heading="Purposes">
        {purposes?.map((purpose, index) => (
          <Box key={index}>
            <LinkChip className={DataRiskDetailLayoutClasses.chip} text={purpose.name} />
          </Box>
        ))}
      </GreyBox>
    </>
  )
}
