import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'

import { ExperienceDTO } from 'interfaces/experiences/experience'
import { ExperienceType, EXPERIENCE_TYPE_NAMES } from 'interfaces/experiences/experienceType'
import { SENSITIVE_EXPERIENCE_STATUSES } from 'interfaces/experiences/experienceStatus'

import { Text } from 'components/ui-kit/typography/Text'
import { Status } from 'components/ui-kit/status/Status'
import { Badge } from 'components/ui-kit/badge/Badge'
import { Group } from 'components/ui-layouts/group/Group'

const useStyles = makeStyles(
  theme =>
    createStyles({
      codeText: {
        color: theme.palette.darkChrome.main,
      },
    }),
  { name: 'ExperienceViewDetails' },
)

type Props = {
  isLatest: boolean
  experience: ExperienceDTO
}

export const ExperienceViewDetails: React.FC<Props> = ({ isLatest, experience }) => {
  const classes = useStyles()
  const { code, consent, status, version } = experience

  return (
    <Group>
      <Badge variant="dark-blue">
        {consent ? EXPERIENCE_TYPE_NAMES[ExperienceType.CONSENT] : EXPERIENCE_TYPE_NAMES[ExperienceType.PREFERENCE]}
      </Badge>

      {SENSITIVE_EXPERIENCE_STATUSES.includes(status) ? (
        <Status size="big" variant="active">
          Deployed
        </Status>
      ) : (
        <Status size="big" variant="inactive">
          Undeployed
        </Status>
      )}

      {!isLatest && <Badge variant="outline">v{version}</Badge>}

      <Group gap={3}>
        <Text className={classes.codeText} size={10} weight={700} transform="uppercase">
          Code
        </Text>
        <Text className={classes.codeText} size={11}>
          {code}
        </Text>
      </Group>
    </Group>
  )
}
