import React from 'react'

import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { Text } from 'components/ui-kit/typography/Text'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { getRenderTags, getRenderTag, getFilterOptions } from './utils'
import { useManageLabelsInputUtils, useStyles } from './hooks'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'

type Props = {
  assetCode: string
}

export const ManageLabelsInput: React.FC<Props> = ({ assetCode }) => {
  const _ = useManageLabelsInputUtils({ assetCode })
  const classes = useStyles()

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
        <Text size={14} weight={600}>
          Classifications
        </Text>
        {_.invalidInputMessage && (
          <Box className={classes.baseError} ml={2}>
            {_.invalidInputMessage}
          </Box>
        )}
      </Box>
      <FormRow>
        <Autocomplete
          id="labels"
          multiple
          popupIcon={null}
          disableClearable
          onChange={_.handleAutocompleteOnChange}
          open={_.isOptionsOpen}
          options={[..._.labelOptions]}
          value={[..._.labels]}
          getOptionLabel={(option: ManageLabelModalLabel) => option.name}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Escape') {
              _.setIsOptionsOpen(false)
              if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
            }
          }}
          filterOptions={options => getFilterOptions({ options, newLabel: _.newLabel, labels: _.labels })}
          renderTags={(value: ManageLabelModalLabel[]) =>
            getRenderTags({ value, handleRemoveTag: _.handleRemoveTag, labels: _.labels })
          }
          renderOption={getRenderTag}
          renderInput={params => (
            <TextField
              {...params}
              onFocus={() => _.setIsOptionsOpen(true)}
              onBlur={() => _.setIsOptionsOpen(false)}
              InputProps={{
                ...params.InputProps,
              }}
              inputProps={{
                ...params.inputProps,
                onChange: _.handleLabelInputValueOnChange,
                value: _.newLabel,
              }}
            />
          )}
        />
      </FormRow>
    </>
  )
}
