import * as Yup from 'yup'

interface ISetPermitsValidationSchemaParams {
  usedCodes?: Array<string>
}

export const getDataReviewValidationSchema = (params: ISetPermitsValidationSchemaParams) =>
  Yup.object().shape({
    approver: Yup.string(),
    assignee: Yup.string().required('Assignee is required'),
    code: Yup.string()
      .notOneOf(params?.usedCodes || [], 'This code is already in use')
      .required('This field is required'),
    description: Yup.string().required('Required'),
    timeToCompleteDays: Yup.number()
      .integer()
      .moreThan(-1, 'Number of days must be a whole number less than 365, or 0 to not require a due date.')
      .lessThan(365, 'Number of days must be a whole number less than 365, or 0 to not require a due date.'),
  })
