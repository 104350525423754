import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { deleteSubscriptionTopic } from 'api/subscriptions/fetchers/deleteSubscriptionTopic'

const useCustomMutation = createUseMutation({
  mutationFn: deleteSubscriptionTopic,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeleteSubscriptionTopic = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
