import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { ListDsrAttributeActionsSummaryBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

type Params = APIListRequestParams<{
  assetId: string
  canonicalRightCode: string
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchPermittedAttrActionsSummary = ({
  assetId,
  canonicalRightCode,
  isV2FeatureFlagEnabled,
  limit,
}: Params) => {
  let entityUrl = `/api/v3/dsr-configs/assets/${assetId}/permittedAttrActionsSummary`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/dsr-configs/assets/${assetId}/permittedAttrActionsSummary`

  return API.get<ListDsrAttributeActionsSummaryBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          ...(limit && {
            limit,
          }),
          ...(canonicalRightCode && {
            canonicalRightCode,
          }),
        },
      },
    }),
  )
}
