import { useAuth } from 'utils/hooks/useAuth'
import { useOrganization } from 'api/organizations/queries/useOrganization'

export const useCurrentOrganization = ({ ...options }) => {
  const { userData } = useAuth()

  const { data: organization, isLoading } = useOrganization({
    enabled: !!userData?.organizationCode,
    params: {
      organizationCode: userData?.organizationCode || '',
      includeMetadata: true,
    },
    ...options,
  })

  return { organization, isLoading }
}
