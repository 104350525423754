import React from 'react'
import { Box } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import { Text } from 'components/ui-kit/typography/Text'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { InfoRow } from 'pages/policyCenter/policies/viewV2/components/OverviewTab/components'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { PurposeChip } from 'pages/policyCenter/policies/viewV2/components'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Status, StatusVariant } from 'components/ui-kit/status/Status'
import {
  POLICY_STATUS_TITLE_MAP,
  POLICY_STATUS_VARIANT_MAP,
} from 'pages/policyCenter/policies/listV2/tempInterfaces/PolicyStatus'
import { TypeCellRenderer } from 'pages/policyCenter/policies/listV2/components'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      status: {
        padding: spacing(0),
      },
      baseModalContent: {
        paddingTop: spacing(0),
      },
    }),
  { name: 'PolicyOverviewModal' },
)

type Props = {
  policy?: AssetPolicyDTO
  onCancel?: () => void
}

export const PolicyOverviewModal: React.FC<Props> = ({ onCancel, policy }) => {
  const classes = useStyles()
  const statusVariant = POLICY_STATUS_VARIANT_MAP[policy?.status || 0] as StatusVariant

  return (
    <Dialog
      baseModalContentClassName={classes.baseModalContent}
      title={policy?.name}
      subtitle={
        <Box display="flex" alignItems="center" gap={1} pb={5}>
          <TypeCellRenderer category={policy?.category} />

          <Status className={classes.status} size="regular" variant={statusVariant}>
            {POLICY_STATUS_TITLE_MAP[policy?.status || 0]}
          </Status>

          <Box display="flex" alignItems="center">
            <Text size={10} color="darkChrome" weight={700} transform="uppercase" letterSpacing="0.15em" mr={0.5}>
              Code
            </Text>

            <Text size={11} weight={500} color="grey">
              {policy?.id}
            </Text>
          </Box>
        </Box>
      }
      contentWidth={968}
      submitBtnLabel="Delete"
      cancelBtnLabel="Close"
      onCancelBtnClick={onCancel}
      withTitleBorderBottom={true}
    >
      <Box>
        <InfoRow
          title="Description"
          hasBorderBottom
          info={
            policy?.description ? (
              <Text size={14}>{policy?.description}</Text>
            ) : (
              <EmptyValueRenderer color="darkDuskFaded" />
            )
          }
        />

        <InfoRow
          title="Purpose"
          hasBorderBottom
          info={
            policy?.purpose ? (
              <PurposeChip purpose={policy?.purpose || ''} />
            ) : (
              <EmptyValueRenderer color="darkDuskFaded" />
            )
          }
        />

        {/*  TODO:BAC - de-scoped this row for now. Needs BE API work to support */}
        {/* <InfoRow
          title="Data Assets Impacted"
          info={
            <DataAssetsImpactedInfo
              policy={
                policy as AssetPolicyDTO & {
                  assetsImpacted: { [key: string]: string }
                }
              }
            />
          }
        /> */}

        <InfoRow title="Last Updated" info={<FormattedDateRenderer date={policy?.metadata?.updatedAt} />} />
      </Box>
    </Dialog>
  )
}
