import React from 'react'
import Grid from '@mui/material/Grid'

import { JitConfigDTO } from 'interfaces/experiences/experience'
import { EXPERIENCE_ACTION_BUTTON_DESTINATION_NAMES } from 'interfaces/experiences/experienceActionButtonDestination'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { InterpolateLinks } from 'pages/consentAndRights/experiences/components/InterpolateLinks'
import { Text } from 'components/ui-kit/typography/Text'
import { SubSectionTitle } from 'components/ui-kit/typography/compositions/SubSectionTitle'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'

type Props = {
  config?: JitConfigDTO
}

export const JitDetails: React.FC<Props> = ({ config }) => {
  const { isEntitled } = useIsEntitled()
  const isEntitledToJit = isEntitled(ENTITLEMENTS.EXP_SERVER_JIT)

  if (!isEntitledToJit) {
    return null
  }

  const { title, bodyDescription, moreInfoText, moreInfoDestination, declineButtonText, acceptButtonText } =
    config || {}

  return (
    <>
      <FormRow>
        <SubSectionTitle>Main Title</SubSectionTitle>
        {title ? <Text size={14}>{title}</Text> : <EmptyValueRenderer />}
      </FormRow>

      <FormRow>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <SubSectionTitle>Body Description</SubSectionTitle>
            {bodyDescription ? (
              <Text size={14}>
                <InterpolateLinks linkColor="light-blue" text={bodyDescription} />
              </Text>
            ) : (
              <EmptyValueRenderer />
            )}
          </Grid>
        </Grid>
      </FormRow>

      <FormRow>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <SubSectionTitle>Primary Button Text</SubSectionTitle>
            {moreInfoText ? <Text size={14}>{moreInfoText}</Text> : <EmptyValueRenderer />}
          </Grid>

          <Grid item xs={3}>
            <SubSectionTitle>Primary Button Action</SubSectionTitle>
            {moreInfoDestination ? (
              <Text size={14}>{EXPERIENCE_ACTION_BUTTON_DESTINATION_NAMES[moreInfoDestination]}</Text>
            ) : (
              <EmptyValueRenderer />
            )}
          </Grid>

          <Grid item xs={3}>
            <SubSectionTitle>Secondary Button Text</SubSectionTitle>
            {acceptButtonText ? <Text size={14}>{acceptButtonText}</Text> : <EmptyValueRenderer />}
          </Grid>

          <Grid item xs={3}>
            <SubSectionTitle>Secondary Button Action</SubSectionTitle>
            {declineButtonText ? <Text size={14}>{declineButtonText}</Text> : <EmptyValueRenderer />}
          </Grid>
        </Grid>
      </FormRow>
    </>
  )
}
