import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'

const PREFIX = 'PermitsTrendChart'

const classes = {
  legend: `${PREFIX}-legend`,
  legendIcon: `${PREFIX}-legendIcon`,
  legendIconSquare: `${PREFIX}-legendIconSquare`,
  legendIconCircle: `${PREFIX}-legendIconCircle`,
  legendIconLineAllowed: `${PREFIX}-legendIconLineAllowed`,
  legendIconLineTotal: `${PREFIX}-legendIconLineTotal`,
  legendItem: `${PREFIX}-legendItem`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.legend}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 474,
    height: 22,
  },

  [`& .${classes.legendIcon}`]: {
    marginRight: 10,
  },

  [`& .${classes.legendIconSquare}`]: {
    width: 16,
    height: 16,
    borderRadius: 2,
  },

  [`& .${classes.legendIconCircle}`]: {
    width: 8,
    height: 8,
    borderRadius: '50%',
  },

  [`& .${classes.legendIconLineAllowed}`]: {
    width: 16,
    height: 1,
    borderBottom: `2px solid ${palette.graph11.main}`,
    borderRadius: 2,
  },

  [`& .${classes.legendIconLineTotal}`]: {
    width: 16,
    height: 1,
    borderBottom: `2px solid ${palette.heliotrope.main}`,
    borderRadius: 2,
  },

  [`& .${classes.legendItem}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}))

type Props = {
  reshapedDailyTrendData: any
}
export const Legend: React.FC<Props> = ({ reshapedDailyTrendData }) => {
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])
  return (
    <StyledBox
      mt={3}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      sx={{ opacity: reshapedDailyTrendData.length === 0 ? 0 : 1 }}
    >
      <Box className={classes.legend}>
        <Box className={classes.legendItem}>
          <Box
            className={clsx(classes.legendIcon, classes.legendIconCircle)}
            sx={{
              backgroundColor: ({ palette }) => palette.graph10.main,
            }}
          />
          <Typography variant="footnote" fontWeight="500" color="darkDusk.main">
            {t('Consent')}
          </Typography>
        </Box>
        <Box className={classes.legendItem}>
          <Box
            className={clsx(classes.legendIcon, classes.legendIconCircle)}
            sx={{
              backgroundColor: ({ palette }) => palette.graph13.main,
            }}
          />
          <Typography variant="footnote" fontWeight="500" color="darkDusk.main">
            {t('Consent Not Required')}
          </Typography>
        </Box>
        <Box className={classes.legendItem}>
          <Box
            className={clsx(classes.legendIcon, classes.legendIconCircle)}
            sx={{
              backgroundColor: ({ palette }) => palette.graph12.main,
            }}
          />
          <Typography variant="footnote" fontWeight="500" color="darkDusk.main">
            {t('No Consent')}
          </Typography>
        </Box>
        <Box className={classes.legendItem}>
          <Box className={clsx(classes.legendIcon, classes.legendIconLineAllowed)} />
          <Typography variant="footnote" fontWeight="500" color="darkDusk.main">
            {t('Allowed')}
          </Typography>
        </Box>

        <Box className={classes.legendItem}>
          <Box className={clsx(classes.legendIcon, classes.legendIconLineTotal)} />
          <Typography variant="footnote" fontWeight="500" color="darkDusk.main">
            {t('Total')}
          </Typography>
        </Box>
      </Box>
    </StyledBox>
  )
}
