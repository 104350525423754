import { compact, includes, isEmpty } from 'lodash'
import { FormikContextType } from 'formik'

import { RegionDTO } from 'interfaces/regions/region'
import { PolicyScopeTemplateDTO } from 'interfaces/policyScopes/policyScope'
import { ObjectLiteral } from 'interfaces'
import { PolicyScopeFormValues } from '../interfaces'

interface IGetOptionalRegionsItemProps {
  item: RegionDTO
  filterFn: (item: RegionDTO) => boolean
}

export const filterRegionsItems = ({ item, filterFn }: IGetOptionalRegionsItemProps) => {
  if (item.regions) {
    // NOTE: when item has child regions, display it only if some filtered children are available. Check recursively.
    const childrenAvailable: RegionDTO[] = compact(item.regions.map(item => filterRegionsItems({ item, filterFn })))

    return childrenAvailable.length
      ? {
          ...item,
          regions: childrenAvailable,
        }
      : null
  } else {
    // NOTE: when item doesn't have child regions (bottom level item) display it the list
    // when it matches filter criteria itself
    const isAvailable = filterFn(item)

    return isAvailable ? item : null
  }
}

function addRegionItemToSelectedList<T extends ObjectLiteral>(item: T, idsToSelect: string[]) {
  // NOTE: store only bottom level items as selected IDS
  if (item.regions) {
    item.regions?.forEach?.((item: T) => addRegionItemToSelectedList(item, idsToSelect))
  } else {
    idsToSelect.push(item.code)
  }
}

export function handleSelectRegions(
  item: RegionDTO,
  selectedRegionsCodes: string[],
  setSelectedRegionsCodes: (value: string[]) => void,
) {
  const idsToSelect: string[] = []

  addRegionItemToSelectedList(item, idsToSelect)
  setSelectedRegionsCodes([...selectedRegionsCodes, ...idsToSelect])
}

const addRegionItemToDeselectList = (item: RegionDTO, idsToDeselect: string[]) => {
  idsToDeselect.push(item.code)

  if (item.regions) {
    item.regions?.forEach?.(item => addRegionItemToDeselectList(item, idsToDeselect))
  }
}

export const handleDeselectRegions = (
  item: RegionDTO,
  selectedRegionsCodes: string[],
  setSelectedRegionsCodes: (value: string[]) => void,
) => {
  const idsToDeselect: string[] = []

  addRegionItemToDeselectList(item, idsToDeselect)

  setSelectedRegionsCodes(selectedRegionsCodes.filter(regionId => !includes(idsToDeselect, regionId)))
}

export const handleSelectTemplate = ({
  template,
  formContext,
}: {
  template: PolicyScopeTemplateDTO
  formContext: FormikContextType<PolicyScopeFormValues>
}) => {
  const { values, setFieldTouched, setValues, touched } = formContext

  // Make regions touched to display error if value will be cleared
  const isRegionsTouched = touched.regionCodes

  !isRegionsTouched && setFieldTouched('regionCodes')

  const rightsFulfillment = template.rightsFulfillment || []

  const rightsFulfillmentToSet = rightsFulfillment.map(({ rightCode, fulfillment }) => ({
    checked: true,
    rightCode,
    fulfillment: `${fulfillment}`,
  }))

  setValues({
    ...values,
    // Use [] for 'No template' option
    regionCodes: template.regionCodes || [],
    regulationCodes: template.regulationCodes || [],
    rightsFulfillment: rightsFulfillmentToSet || [],
  })
}

interface IGetSelectedRegionWarningProps {
  region: RegionDTO
  currentPolicyScopeCode: string
}

type GetSelectedRegionWarning = (props: IGetSelectedRegionWarningProps) => string
// NOTE: get  warning for Region item recursively. If some of the children has warnings display in parent that some of the children has warnings
export const getSelectedRegionWarning: GetSelectedRegionWarning = ({ region, currentPolicyScopeCode }) => {
  let warning = ''

  // Lowest level item
  if (!region.regions) {
    const isRegionSelected = !!region.policyScopeCode
    const isCurrentPolicyScope = region.policyScopeCode === currentPolicyScopeCode

    if (isRegionSelected && !isCurrentPolicyScope) {
      warning = 'This region is currently mapped to another jurisdiction'
    }
    // Upper level item
  } else {
    const childWarnings: string[] = []

    region.regions?.forEach?.(region => {
      const childWarning = getSelectedRegionWarning({ region, currentPolicyScopeCode })

      childWarning && childWarnings.push(childWarning)
    })

    if (!isEmpty(childWarnings)) {
      warning = 'This group has regions that are currently mapped to another jurisdiction'
    }
  }

  return warning
}
