import React, { useState } from 'react'

import { ReactComponent as TagIcon } from 'assets/icons/tags-and-labels.svg'

import { StepDetailsTile } from 'pages/orchestration/rightsQueue/stepDetails/components'

import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ManualBadge, AutomatedBadge } from './Badges'

import { Button } from '@ketch-com/deck'
import { ReactComponent as TrashIcon } from 'assets/icons/trash-can-black.svg'
import { BadgeDovetailVariant } from 'pages/dataSystems/Labels/components/BadgeDovetail/BadgeDovetail'
import { Box, Typography, styled } from '@mui/material'
import { useParams } from 'react-router-dom'
import { ConfirmationStatusV3DTO, LabelMethodV3DTO } from '@ketch-com/figurehead'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useUpdateAssignedCategories } from 'api/dataSystemsClassifications/mutations/useUpdateAssignedCategories'
import { useQueryClient } from 'react-query'
import { useAssignedDataCategoriesInfinite } from 'api/dataSystemsClassifications/queries/useAssignedDataCategoriesInfinite'
import { AssignedCategoriesEditModal, AssignedCategoriesRestoreModal } from './Modals'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'

type Props = {}

const BadgeContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: spacing(1.5),
  maxHeight: '350px',
  overflow: 'scroll',
  paddingBottom: '20px',
  marginBottom: '20px',
  borderBottom: '1px solid #CCC',
}))

const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

const SubTitle = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(0.5),
}))

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '16px',
  height: '16px',
}))

const StyledLinkIcon = styled(LinkIcon)(({ theme }) => ({
  display: 'flex',
  width: '16px',
  height: '16px',
  '& path': {
    fill: theme.palette.darkDuskFaded.main,
  },
}))

const DismissedButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '125px',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.fadedGrey.main,
  borderRadius: '5px',
  padding: '5px 10px',
  gap: '8px',
  cursor: 'pointer',
}))

export const ClassificationsWidget: React.FC<Props> = () => {
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteLabel = getIsPermitted([PERMISSIONS.LABEL_WRITE])

  const { code } = useParams<{ code: string }>()

  const { data: dataAssignedCategories } = useAssignedDataCategoriesInfinite({
    installedDataSystemId: code,
  })

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false)

  const handleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen)
  }

  const handleRestoreModal = () => {
    setIsRestoreModalOpen(!isRestoreModalOpen)
  }

  const queryClient = useQueryClient()

  const { mutateAsync: handleUpdateAssignedCategories } = useUpdateAssignedCategories({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.assignedDataCategories, { installedDataSystemId: code }])

      showToast({ content: 'Assigned classifications updated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update assigned classifications', type: 'error' })
    },
  })

  const handleConfirmation = (assignedCategoryId: string | undefined, confirmationStatus: ConfirmationStatusV3DTO) => {
    const assignedCategoriesRemap = dataAssignedCategories?.assignedClassifications?.map(assignedCategory => {
      if (assignedCategory.id === assignedCategoryId) {
        return {
          ...assignedCategory,
          confirmationStatus,
        }
      } else {
        return assignedCategory
      }
    })

    handleUpdateAssignedCategories({
      params: {
        installedDataSystemId: code,
        formData: { assignedClassifications: assignedCategoriesRemap },
      },
    })
  }

  const assignedClassifications = dataAssignedCategories?.assignedClassifications?.filter(
    assignedClassification =>
      assignedClassification?.confirmationStatus !== ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
  )

  const assignedClassificationsDismissed = dataAssignedCategories?.assignedClassifications?.filter(
    assignedClassification =>
      assignedClassification?.confirmationStatus === ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
  )

  return (
    <Box>
      <StepDetailsTile
        title=" Classifications"
        subTitle={`Classifications Assigned ${assignedClassifications.length}`}
        isCollapsible={!!dataAssignedCategories?.totalResults}
        content={
          <Box sx={{ position: 'relative' }}>
            {isPermittedToWriteLabel && (
              <Box style={{ position: 'absolute', right: '0', top: '-60px' }}>
                <Button
                  color="tertiary"
                  variant="icon"
                  onClick={e => {
                    e.stopPropagation()
                    handleEditModal()
                  }}
                >
                  <EditIcon />
                </Button>
              </Box>
            )}

            <BadgeContainer>
              {assignedClassifications?.map(assignedCategory => {
                const category = assignedCategory?.classification

                return (
                  <Box key={assignedCategory.id}>
                    {assignedCategory?.method !== LabelMethodV3DTO.ManualLabelMethodV3 ? (
                      <AutomatedBadge
                        name={category?.name ?? ''}
                        description={category?.description ?? ''}
                        confirmationStatus={assignedCategory?.confirmationStatus}
                        confidence={assignedCategory?.confidence}
                        variant={BadgeDovetailVariant.HEAD}
                        handleConfirm={() =>
                          handleConfirmation(
                            assignedCategory?.id,
                            ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
                          )
                        }
                        handleDismiss={() =>
                          handleConfirmation(
                            assignedCategory?.id,
                            ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
                          )
                        }
                      />
                    ) : (
                      <ManualBadge
                        name={category?.name ?? ''}
                        description={category?.description ?? ''}
                        variant={BadgeDovetailVariant.HEAD}
                      />
                    )}
                  </Box>
                )
              })}
            </BadgeContainer>

            <Footer>
              <SubTitle>
                <IconContainer>
                  <StyledLinkIcon />
                </IconContainer>

                <Typography variant="smallLabel" color="darkDuskFaded.main">
                  Data Map
                </Typography>
              </SubTitle>

              {isPermittedToWriteLabel && (
                <DismissedButton onClick={!!assignedClassificationsDismissed?.length ? handleRestoreModal : () => null}>
                  <TrashIcon />
                  <Typography variant="subtitle2">Dismissed {assignedClassificationsDismissed?.length}</Typography>
                </DismissedButton>
              )}
            </Footer>

            <AssignedCategoriesEditModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} />
            <AssignedCategoriesRestoreModal isOpen={isRestoreModalOpen} onClose={() => setIsRestoreModalOpen(false)} />
          </Box>
        }
        fallback={
          <Box sx={{ position: 'relative' }}>
            {isPermittedToWriteLabel && (
              <Box sx={{ position: 'absolute', right: '0', top: '-60px' }}>
                <Button
                  color="tertiary"
                  variant="icon"
                  onClick={e => {
                    e.stopPropagation()
                    handleEditModal()
                  }}
                >
                  <EditIcon />
                </Button>
              </Box>
            )}

            <EmptyState
              title="No Classifications"
              description="This system has no associated classifications."
              icon={<TagIcon />}
              spacerHeight={0}
              variant="page"
            />
          </Box>
        }
      />
      <AssignedCategoriesEditModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} />
      <AssignedCategoriesRestoreModal isOpen={isRestoreModalOpen} onClose={() => setIsRestoreModalOpen(false)} />
    </Box>
  )
}
