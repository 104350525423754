import { Banner } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { FormInput } from 'components/form/FormInput'
import { useFormikContext } from 'formik'
import { IImportContextParams } from 'pages/orchestration/workflows/edit/interfaces'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'

export const ImportContextSqlInput: React.FC = () => {
  const { values } = useFormikContext<IImportContextParams>()

  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isMultipleIdentitiesFlagEnabled = !isFeatureFlagEnabled(
    FEATURE_FLAGS.IMPORT_CONTEXT,
    FEATURE_FLAGS_VALUES.IMPORT_CONTEXT_SINGLE_IDENTITY,
  )

  if (isMultipleIdentitiesFlagEnabled) {
    const placeholderText = `Example: select ColumnName as {{${values.identityToCode}}} from TableName where member_id = {{${values.identitiesFrom?.[0]?.code}}}`

    return (
      <Box display="flex" flexDirection="column" gap={1}>
        <FormInput
          required
          fullWidth
          formPropertyName="query"
          multiline
          rows={6}
          label="SQL Query"
          placeholder={placeholderText}
        />
        <Banner
          severity="info"
          isInfoBox
          sx={{
            flexShrink: 2,
            '&.MuiPaper-root': {
              width: '100%',
            },
          }}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="body">This query must use all selected identifiers.</Typography>
            <Typography variant="body">
              The SQL query should use double bracket syntax to include the following Lookup Identifier codes{' '}
              {values.identitiesFrom?.map((identity, idx) => (
                <Typography variant="label">{`{{${identity.code}}}${
                  values.identitiesFrom?.length && idx <= values.identitiesFrom.length - 3 ? ',' : ''
                } ${
                  values.identitiesFrom?.length &&
                  values.identitiesFrom.length > 1 &&
                  idx === values.identitiesFrom?.length - 2
                    ? 'and '
                    : ''
                }`}</Typography>
              ))}{' '}
              in the where filter, as well as the{' '}
              <Typography variant="label">{`{{${values.identityToCode}}}`}</Typography> around the added Identifier code
              as the returning column name.
            </Typography>
          </Box>
        </Banner>
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <FormInput
        required
        fullWidth
        formPropertyName="query"
        multiline
        rows={3}
        label="SQL Query"
        placeholder={`Example: select ColumnName as {{${values.identityToCode}}} from TableName where member_id = {{${values.identityFromCode}}}`}
      />
      <Typography variant="body">
        This query must return a single column with a single row containing the added Identifier value.
      </Typography>
      <Typography variant="body">
        The SQL query should have <Typography variant="label">{`{{${values.identityFromCode}}}`}</Typography> around the
        Lookup Identifier code in the where filter, as well as the{' '}
        <Typography variant="label">{`{{${values.identityToCode}}}`}</Typography> around the added Identifier code as
        the returning column name.
      </Typography>
    </Box>
  )
}
