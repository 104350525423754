import { useMemo } from 'react'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { useContext } from 'react'
import { Formik } from 'formik'
import { getOutcomeVariablesInitialValues, getOutcomeVariablesValidationSchema } from './utils'
import { TaskContext } from '../../../../context'
import { InfoRowCtxVariable } from '../InfoRowCtxVariable/InfoRowCtxVariable'
import { ContentGroup } from '@ketch-com/deck'
import { Box } from '@mui/material'

type Props = {}

export const OutcomeVariables: React.FC<Props> = () => {
  const { stepDetails, hasAttemptedToResolve } = useContext(RightInvocationWorkflowStepDetailViewContext)
  const { canEdit } = useContext(TaskContext)

  const initialValues = useMemo(() => getOutcomeVariablesInitialValues(stepDetails), [stepDetails])
  const validationSchema = useMemo(() => getOutcomeVariablesValidationSchema(), [])

  if (!stepDetails?.outcomeContext?.length) return null

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={() => {}}
    >
      {({ errors }) => {
        return (
          <Box display="flex" flexDirection="column" gap={4}>
            <ContentGroup variant="inner-page-header" titleVariant="h3" title="Outcome Variables" />
            {stepDetails?.outcomeContext?.map((variable, variableIndex) => {
              if (!variable.isConfiguredForStep) return null

              return (
                <InfoRowCtxVariable
                  key={variableIndex}
                  variable={variable}
                  canEdit={canEdit}
                  name={`outcomeContext[${variableIndex}].value`}
                  variableIndex={variableIndex}
                  errors={errors}
                  hasAttemptedToResolve={hasAttemptedToResolve}
                />
              )
            })}
          </Box>
        )
      }}
    </Formik>
  )
}
