import { CreateWebhookRequestBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  formData: CreateWebhookRequestBodyDTO
}

type Response = {}

export const createWebhook = ({ formData }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/webhooks`,
    }),
    formData,
  )
