import React from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import { makeStyles, createStyles } from '@mui/styles'

import { ListItem, Props as BaseListItemProps } from 'components/ui-kit/list/ListItem'
import { SxProps, Theme } from '@mui/material'

const useStyles = makeStyles(
  createStyles({
    baseList: {
      width: '100%',
      display: 'inline-flex',
      flexDirection: 'column',
    },
  }),
  { name: 'List' },
)

export type ListItemType = Omit<BaseListItemProps, 'className' | 'size' | 'borderless'>

type Props = Pick<BaseListItemProps, 'size' | 'borderless'> & {
  /** Custom component className */
  className?: string
  /** Component items list */
  items: ListItemType[]
  /** Hide active Icon */
  hideActiveIcon?: boolean
  /** Container SX */
  containerSx?: SxProps<Theme>
}

export const List: React.FC<Props> = ({ items, size, borderless, className, hideActiveIcon, containerSx }) => {
  const classes = useStyles()
  const componentClassName = clsx(classes.baseList, className)
  const visibleItems = items.filter(item => !item.hidden)

  if (!visibleItems.length) {
    return null
  }

  return (
    <Box className={componentClassName} sx={containerSx}>
      {visibleItems.map((item, index) => (
        <ListItem key={index} size={size} borderless={borderless} hideActiveIcon={hideActiveIcon} {...item} />
      ))}
    </Box>
  )
}
