import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { Button, InlineEditSize } from '@ketch-com/deck'
import { Link, createSearchParams, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormTemplateUpsertContext } from '../context/FormTemplateUpsertContextProvider'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { FormTemplateDTO } from '@ketch-com/figurehead'
import { useFormikContext } from 'formik'
import { formatCodeFromName } from 'utils/formatters'
import { getFormTemplateDeployedExperiences } from '../utils'
import { FormTemplateSaveChangesModal } from 'pages/consentAndRights/forms/components'
import { FormMode } from 'interfaces/formModes/formMode'
import { PreviewFrom } from '../../preview/interfaces'
import { FormInlineEdit } from 'components/form/FormInlineEdit'

type Props = {}

export const UpsertFormTemplateHeader: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { formTemplate, isEditMode, isFormTemplateSaveChangesModalOpen, setIsFormTemplateSaveChangesModalOpen } =
    useContext(FormTemplateUpsertContext)
  const { submitForm, setFieldValue, isSubmitting, values, setFieldTouched } = useFormikContext<FormTemplateDTO>()
  return (
    <Box width="100%">
      <Box mt={-3} mb={3} width="100%" px={4} py={2} bgcolor="white.main" boxShadow="0px 4px 10px rgba(7, 26, 36, 0.1)">
        <Box display="flex" justifyContent="space-between" alignItems="center" maxWidth={1280} margin="auto">
          {/* Left: Name and Code input */}

          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            sx={{
              wordBreak: 'break-all',
            }}
          >
            <FormInlineEdit
              sx={{
                input: {
                  maxWidth: '750px !important',
                },
                '& .InlineEdit-content': {
                  '&.InlineEdit-2xlarge': {
                    maxWidth: '750px',
                    height: 'auto',
                    minHeight: '46px',
                  },
                },
              }}
              formPropertyName="name"
              placeholder="Form Name"
              size={InlineEditSize['2xlarge']}
              onChange={e => {
                if (!isEditMode) {
                  setFieldValue('code', formatCodeFromName(e.target.value))
                  setFieldTouched('code')
                }
              }}
              shouldUpdateDebounced
              required
            />

            <FormInlineEdit
              sx={{
                input: {
                  maxWidth: '750px !important',
                },
                '& .InlineEdit-content': {
                  '&.InlineEdit-medium': {
                    maxWidth: '750px',
                    height: 'auto',
                    minHeight: '32px',
                  },
                },
              }}
              required
              formPropertyName="code"
              placeholder="Add Code"
              disabled={isEditMode}
              shouldUpdateDebounced
            />
          </Box>

          {/* Right: Actions */}

          <Box display="flex" gap={1.5} alignItems="center">
            {isEditMode && (
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography variant="smallBody" color="darkDuskFaded.main">
                  Last Updated
                </Typography>

                <Typography color="darkDusk.main" variant="smallBody">
                  <FormattedDateRenderer date={formTemplate?.metadata?.updatedAt} />
                </Typography>
              </Box>
            )}

            <Button
              size="large"
              color="tertiary"
              onClick={() => {
                navigate({
                  pathname: RoutesManager.deployment.forms.templates.upsert.preview.root.getURL({
                    formMode: FormMode.EDIT,
                    id: formTemplate?.id || '',
                  }),
                  search: createSearchParams({
                    from: isEditMode ? PreviewFrom.Editing : PreviewFrom.New,
                    formDTO: JSON.stringify(values),
                  }).toString(),
                })
              }}
              disabled={isSubmitting}
            >
              Preview
            </Button>

            <Link to={RoutesManager.deployment.forms.templates.root.getURL()} style={{ textDecoration: 'none' }}>
              <Button size="large" color="secondary" onClick={() => {}} disabled={isSubmitting}>
                Cancel
              </Button>
            </Link>

            <Button
              size="large"
              color="primary"
              onClick={() => {
                if (isEditMode && getFormTemplateDeployedExperiences(formTemplate).length > 0) {
                  setIsFormTemplateSaveChangesModalOpen(true)
                  return
                } else {
                  submitForm()
                }
              }}
              pending={isSubmitting}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
      {isFormTemplateSaveChangesModalOpen && (
        <FormTemplateSaveChangesModal
          isSubmitting={isSubmitting}
          formTemplate={formTemplate}
          onCancel={() => {
            setIsFormTemplateSaveChangesModalOpen(false)
          }}
          onConfirm={() => submitForm()}
        />
      )}
    </Box>
  )
}
