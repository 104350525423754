export const TeamMemberDot = (props: any) => {
  const { x, y } = props

  return (
    <svg x={x} y={y} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="10.5" fill="#071A24" stroke="white" />
      <path
        d="M8.375 8.3783C8.48784 11.8743 13.5127 11.8735 13.625 8.37824C13.514 4.87351 8.48552 4.87431 8.375 8.3783Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.75 16.2502C7.06182 11.8294 15.19 12.1926 16.25 16.2502"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
