import { APIListRequestParams } from 'api/common/utils'
import { GetInstalledDataSystemProcessingActivitiesResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  id?: string
}>

export const getInstalledDataSystemProcessingActivities = ({ id }: Params) => {
  return API.get<GetInstalledDataSystemProcessingActivitiesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/installed-data-systems/${id}/processing-activities`,
    }),
  )
}
