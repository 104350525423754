import { styled } from '@mui/system'
import Box from '@mui/material/Box'

export const TaskHeaderIconContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.heliotrope.main,
  borderRadius: '14px',
  display: 'flex',
  height: '64px',
  justifyContent: 'center',
  width: '64px',
}))
