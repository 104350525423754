import { BusinessFunctionDTO } from '@ketch-com/figurehead'
import { FormMode } from 'interfaces/formModes/formMode'
import { useNavigate, useParams } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { useCreateBusinessFunction } from 'api/businessFunctions/mutations/useCreateBusinessFunction'
import { useEditBusinessFunction } from 'api/businessFunctions/mutations/useEditBusinessFunction'
import { BusinessFunctionCreateLayout } from './components/BusinessFunctionCreateLayout'
import { BusinessFunctionEditLayout } from './components/BusinessFunctionEditLayout'
import { showToast } from 'components/modals/AlertComponent'

export const BusinessFunctionUpsert = () => {
  const { formMode } = useParams<{ code: string; formMode: FormMode }>()
  const navigate = useNavigate()

  const onUpsertSuccess = async (businessFunction: BusinessFunctionDTO | undefined) => {
    navigate(RoutesManager.policyCenter.businessFunctions.view.root.getURL({ code: businessFunction?.code }))
  }

  const { mutateAsync: handleCreateBusinessFunction } = useCreateBusinessFunction({
    onSuccess: resp => onUpsertSuccess(resp.data.businessFunction),
    onError: () => {
      showToast({ content: 'Failed to create a new business function', type: 'error' })
    },
  })

  const { mutateAsync: handleUpdateBusinessFunction } = useEditBusinessFunction({
    onSuccess: resp => onUpsertSuccess(resp.data.businessFunction),
    onError: () => {
      showToast({ content: 'Failed to edit the business function', type: 'error' })
    },
  })

  return formMode === FormMode.EDIT ? (
    <BusinessFunctionEditLayout onSubmit={handleUpdateBusinessFunction} />
  ) : (
    <BusinessFunctionCreateLayout onSubmit={handleCreateBusinessFunction} />
  )
}
