import {
  PutProcessingActivityAssessmentRequirementRequestBodyDTO,
  PutProcessingActivityAssessmentRequirementResponseBodyDTO,
} from '@ketch-com/figurehead'
import { API } from '../../../utils/api/api'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

type Params = {
  formData: PutProcessingActivityAssessmentRequirementRequestBodyDTO
  activityId: string
}

export const updateProcessingActivityAssessmentRequirement = ({ formData, activityId }: Params) =>
  API.put<PutProcessingActivityAssessmentRequirementResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/processing-activities/${activityId}/assessment-requirement`,
    }),
    formData,
  )
