import React from 'react'

import { useParams } from 'react-router-dom'
import { useAssetV2 } from 'api/assets/queries/useAssetV2'
import { useResourcePoliciesPaginatedV2 } from 'api/policies/queries/useResourcePoliciesPaginatedV2'
import { useConnections } from 'api/assets/queries/useConnections'
import { AssetUpsert } from './AssetUpsert'
import { getURLParams } from 'utils/routing/routes/utils'

type Props = {}

export const AssetUpsertContainer: React.FC<Props> = props => {
  const { code, resourceTypeCode = '' } = useParams<getURLParams>()
  const { data: connections, isLoading: isLoadingConnections } = useConnections()

  const { data: assetSummary, isLoading: isLoadingAsset } = useAssetV2({
    params: {
      assetCode: code!,
      resourceTypeCode,
    },
  })

  const {
    data: resourcePolicies,
    isLoading: isLoadingResourcePolicies,
    pagination: policiesPagination,
  } = useResourcePoliciesPaginatedV2({
    enabled: !!assetSummary?.asset?.resource?.id,
    itemsPerPage: 10,
    params: {
      resourceCode: assetSummary?.asset?.resource?.id || '',
    },
  })

  const isReady = !isLoadingAsset && !isLoadingResourcePolicies && !isLoadingConnections
  return (
    <AssetUpsert
      isReady={isReady}
      assetSummary={assetSummary}
      policies={resourcePolicies}
      pagination={policiesPagination}
      connections={connections}
    />
  )
}
