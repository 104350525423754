import { useRightsInvocationsPaginated } from 'api/auditLogs/queries/useRightsInvocationsPaginated'
import { useQueryParams } from 'utils/hooks/useQueryParams'

export const useAuditLogRightInvocations = () => {
  const { queries } = useQueryParams<{ identifierSearch?: string; identitySpaceCode?: string }>()
  const { identifierSearch, identitySpaceCode } = queries
  const isEnableQueries = !!identifierSearch && !!identitySpaceCode

  const rightInvocationResp = useRightsInvocationsPaginated({
    enabled: isEnableQueries,
    itemsPerPage: 5,
    params: {
      identitySpaceCode,
      identityValue: identifierSearch,
    },
  })

  return rightInvocationResp
}
