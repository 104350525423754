import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { Chip as DeckChip } from '@ketch-com/deck'

import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { IssuesCellRenderer } from 'components/ui-layouts/table/cellRenderers/IssuesCellRenderer'
import { RightListStatusCellRendererV2 } from '../..'
import { RightsQueueListTableColumnV2 } from '../interfaces'
import { CurrentActivityCellRenderer, WorkflowExecutionProgressV2 } from '../components'
import Typography from '@mui/material/Typography'
import { joinNonEmpty } from 'utils/helpers/joinNonEmpty'
import {
  FetchRightInvocationsFilterKeys,
  FetchRightInvocationsOrderByKeys,
} from 'interfaces/rightInvocationsV2/FetchRightInvocationsFilterKeys'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

function truncateId(id?: string): string {
  if (!id) return ''

  // Split the string by '-'
  const parts = id.split('-')

  // Take the substring up to the first '-'
  const firstPart = parts[0]

  // Take the last 4 characters from the original id string
  const last4Chars = id.slice(-4)

  // Combine and return the two extracted parts
  return `${firstPart}-...${last4Chars}`
}

export const useRightsQueueListTableColumnConfigV2 = (): RightsQueueListTableColumnV2[] => {
  return [
    {
      id: FetchRightInvocationsFilterKeys.statuses,
      label: 'Request Status',
      minWidth: 130,
      align: 'right',
      disablePadding: false,
      numeric: false,
      cellRenderer: rightInvocation => (
        <Box display="flex" alignItems="center">
          <RightListStatusCellRendererV2 rightInvocation={rightInvocation} />

          {/* TODO:BAC - Combining Issues column here for now.  Not sure how will integrate in V2 */}
          <IssuesCellRenderer hasIssues={rightInvocation.hasIssues} entityType={ApplicationEntity.RIGHTS_QUEUE} />
        </Box>
      ),
    },
    {
      id: 'subject-name',
      label: 'Subject Name',
      minWidth: 139,
      align: 'right',
      disablePadding: false,
      numeric: false,
      cellRenderer: ({ user }) => {
        const subjectName = joinNonEmpty([user?.first, user?.last])
        return user ? (
          <Typography title={subjectName} variant="body">
            {subjectName}
          </Typography>
        ) : (
          <EmptyValueRenderer />
        )
      },
    },
    {
      id: 'request-code',
      label: 'Request',
      minWidth: 296,
      align: 'right',
      disablePadding: false,
      numeric: false,
      cellRenderer: rightInvocation => {
        const { rightName, id, appeal } = rightInvocation
        const appealWorkflowExecutionID = appeal?.results?.appealWorkflowExecutionID

        return (
          <Box display="flex">
            <NameAndCodeCellRenderer
              isNameBold
              name={rightName || 'Right Invocation'}
              code={id}
              truncateCodeFunction={truncateId}
            />

            {appealWorkflowExecutionID && (
              <DeckChip
                label="Appeal"
                size="small"
                sx={{
                  backgroundColor: 'fadedHeliotrope.main',
                  color: 'darkViolet.main',
                  ml: 1,
                }}
              />
            )}
          </Box>
        )
      },
    },
    {
      id: 'current-activity',
      label: 'Current Activity',
      minWidth: 250,
      align: 'right',
      disablePadding: false,
      numeric: false,
      cellRenderer: rightInvocation => <CurrentActivityCellRenderer rightInvocation={rightInvocation} />,
    },
    {
      id: 'progress',
      label: 'Progress',
      minWidth: 156,
      align: 'right',
      disablePadding: false,
      numeric: false,
      cellRenderer: rightInvocation => {
        const isAppealed = !!rightInvocation?.appeal?.isAppealed
        const workflowExecution = isAppealed
          ? rightInvocation?.appealWorkflowExecution
          : rightInvocation?.workflowExecution

        return workflowExecution ? (
          <WorkflowExecutionProgressV2 workflowExecution={workflowExecution} rightInvocation={rightInvocation} />
        ) : (
          <EmptyValueRenderer />
        )
      },
    },
    {
      id: 'workflow',
      label: 'Workflow',
      minWidth: 182,
      align: 'right',
      disablePadding: false,
      numeric: false,
      cellRenderer: rightInvocation => {
        const isAppealed = !!rightInvocation?.appeal?.isAppealed
        const workflowExecution = isAppealed
          ? rightInvocation?.appealWorkflowExecution
          : rightInvocation?.workflowExecution

        return workflowExecution?.workflowDefinitionName ? (
          <Typography title={workflowExecution?.workflowDefinitionName} variant="body">
            {workflowExecution?.workflowDefinitionName}
          </Typography>
        ) : (
          <EmptyValueRenderer />
        )
      },
    },
    {
      id: FetchRightInvocationsOrderByKeys.invoked_at,
      label: 'Invoked Date',
      minWidth: 120,
      align: 'right',
      disablePadding: false,
      numeric: false,
      cellRenderer: rightInvocation => {
        return <FormattedDateRenderer date={rightInvocation?.invokedAt} format="MMM DD YYYY" />
      },
    },
    {
      id: 'subject-type',
      label: 'Subject Type',
      align: 'right',
      disablePadding: false,
      numeric: true,
      cellRenderer: ({ user }) => {
        return (
          <Chip
            sx={{
              padding: '2px 14px',
              height: 33,
              justifyContent: 'flex-start',
              borderRadius: '100px',
              '& .MuiChip-label': {
                padding: 0,
              },
            }}
            label={
              <Box display="flex" flexDirection="column">
                <Typography variant="mediumFootnote">{user?.dataSubjectTypeName}</Typography>
                <Typography variant="smallFootnote">{user?.dataSubjectTypeCode}</Typography>
              </Box>
            }
          />
        )
      },
    },
  ]
}
