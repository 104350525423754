import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { Button } from '@ketch-com/deck'
import { DeleteSubscriptionTopicModal } from 'pages/policyCenter/subscriptions/subscriptionTopic/list/components'
import { SubscriptionTopicDTO } from '@ketch-com/figurehead'
import { FormMode } from 'interfaces/formModes/formMode'
import { Box, Typography } from '@mui/material'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'

type Props = {
  subscriptionTopic: SubscriptionTopicDTO
}

export const SubscriptionTopicViewActions: React.FC<Props> = ({ subscriptionTopic }) => {
  const navigate = useNavigate()
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const { isPermitted } = useIsPermitted()
  const isPermittedToManageSubscriptions = isPermitted(PERMISSIONS.SUBSCRIPTIONS_WRITE)

  return (
    <>
      <Box display="flex" gap="12px" alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="smallBody" color="darkDuskFaded.main">
            Last Updated
          </Typography>
          {subscriptionTopic?.lastUpdated ? (
            <Typography color="darkDusk.main" variant="smallBody">
              {formatDateTimeFromUnix(subscriptionTopic.lastUpdated)}
            </Typography>
          ) : (
            <Typography color="lightGrey.main" variant="smallBody">
              None
            </Typography>
          )}
        </Box>
        {isPermittedToManageSubscriptions && (
          <>
            <Button
              size="large"
              color="tertiary"
              onClick={() => {
                setIsDeleteOpen(true)
              }}
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                if (subscriptionTopic) {
                  navigate(
                    RoutesManager.policyCenter.subscriptions.upsert.subscriptionTopic.root.getURL({
                      code: subscriptionTopic.code,
                      formMode: FormMode.EDIT,
                    }),
                  )
                }
              }}
              color="secondary"
              size="large"
            >
              Edit
            </Button>
          </>
        )}
      </Box>

      {isDeleteOpen && (
        <DeleteSubscriptionTopicModal
          subscriptionTopic={subscriptionTopic}
          onSubmit={() => {
            setIsDeleteOpen(false)
          }}
          onCancel={() => {
            setIsDeleteOpen(false)
          }}
        />
      )}
    </>
  )
}
