import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { RegulationDTO } from 'interfaces/regulations/regulation'
import { APIListRequestParams, APIListResponse } from 'api/common/utils'

type Params = APIListRequestParams<{
  includeIssues?: boolean
}>

type Response = APIListResponse<{ regulations?: RegulationDTO[] }>

export const fetchRegulations = ({ includeMetadata, includeIssues, start, limit }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/regulations`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
        },
      },
    }),
  )
