import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { TableCell } from '@ketch-com/deck'
import { FormTemplateSummaryDTO } from '@ketch-com/figurehead'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { TemplatesDropdown } from '../components/TemplatesDropdown'

export const useTemplatesTabListColumns = ({
  isUserExperienceServerAdmin,
}: {
  isUserExperienceServerAdmin: boolean
}): GridColDef<FormTemplateSummaryDTO>[] => [
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<FormTemplateSummaryDTO>) => {
      return <TableCell title={row.name} subTitle={row.code} />
    },
  },
  {
    align: 'left',
    field: 'lastUpdated',
    flex: 0.5,
    headerAlign: 'left',
    headerName: 'Last Updated',
    sortable: false,
    renderCell: ({ row: { metadata } }: GridRenderCellParams<FormTemplateSummaryDTO>) => {
      return (
        <TableCell>
          {metadata?.updatedAt ? <FormattedDateRenderer date={metadata?.updatedAt} /> : <EmptyValueRenderer />}
        </TableCell>
      )
    },
  },
  {
    align: 'right',
    field: 'actions',
    headerAlign: 'left',
    headerName: '',
    sortable: false,
    maxWidth: 80,
    flex: 1,
    renderCell: ({ row: formTemplateSummary }: GridRenderCellParams<FormTemplateSummaryDTO>) => {
      return (
        <TableCell>
          <TemplatesDropdown
            isUserExperienceServerAdmin={isUserExperienceServerAdmin}
            formTemplateSummary={formTemplateSummary}
          />
        </TableCell>
      )
    },
  },
]
