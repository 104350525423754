import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { deleteProcessingActivityDataFlowFile } from '../fetchers/deleteProcessingActivityDataFlowFile'

const useCustomMutation = createUseMutation({
  mutationFn: deleteProcessingActivityDataFlowFile,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeleteProcessingActivityDataFlowFile = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation, {})
}
