import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import moment from 'moment'
import { getAmPm } from 'pages/assetManager/databases/upsert/components/EditAutoDiscoveryFormSection/utils'
import { KETCH_DML } from 'pages/developers/hooks/constants'

type Args = { assetSummary?: AssetSummaryDTO }

export const getInitialValues = ({ assetSummary }: Args): EditDatabaseFormValues => {
  const hooksPayload = [
    ...(assetSummary?.asset?.dmlPreHooks || []),
    {
      assignmentCount: 9999,
      dmlCode: 'dummy',
      id: 'dummy',
      name: KETCH_DML,
      operation: 99999,
      technology: 'dummy',
      type: 99,
    },
    ...(assetSummary?.asset?.dmlPostHooks || []),
  ]

  const payload = {
    name: assetSummary?.asset?.resource?.name || '',
    description: assetSummary?.asset?.description || '',
    dataCategory: assetSummary?.asset?.dataCategory?.[0]?.value || '',
    labels: (assetSummary?.asset?.assignedLabels || []) as ManageLabelModalLabel[],
    inferredLabels: (assetSummary?.asset?.inferredLabels || []) as ManageLabelModalLabel[],
    dataOwner: assetSummary?.asset?.owner || '',
    hooks: hooksPayload,
    purposes: assetSummary?.asset?.purposes || [],
    identitySpace: assetSummary?.asset?.identitySpace?.code || '',
    schedule: {
      time: assetSummary?.asset?.schedule?.time
        ? moment(new Date(assetSummary?.asset?.schedule?.time)).format('h:mm')
        : '',
      frequency: assetSummary?.asset?.schedule?.frequency ? String(assetSummary?.asset?.schedule?.frequency) : '1',
      day: assetSummary?.asset?.schedule?.day ? String(assetSummary?.asset?.schedule?.day) : '',
      isCustomizeStartingTimeChecked: !!assetSummary?.asset?.schedule?.time,
      period: assetSummary?.asset?.schedule?.time ? getAmPm(new Date(assetSummary?.asset?.schedule?.time)) : '1',
    },
  }

  return payload
}
