import { GeoJsonLayer } from '@deck.gl/layers'
import { IMapRegion } from '../../list/PolicyScopesList'
import { mapFeatures } from './mapFeatures'
import { RGBAColor } from 'deck.gl'
import {
  dataMapFillColors,
  dataMapStrokeColors,
} from 'pages/insights/dataMap/components/DataMapVisualization/utils/jurisdictionData'
import { theme } from '@ketch-com/deck'
import { hexToRGBArray } from 'pages/insights/dataMap/components/DataMapVisualization/utils/colors'
import { useMemo } from 'react'

type Args = {
  regions?: IMapRegion[]
}

export const usePolicyScopesMapLayersMultiple = ({ regions }: Args) => {
  const groupedRegions = regions?.reduce((acc: any, region) => {
    const key = region?.policyScopeCode || 'unknown'
    if (!!region?.policyScopeCode && !acc?.[key] && key !== 'unknown') {
      acc[region?.policyScopeCode] = []
    }
    if (key !== 'unknown') {
      acc[key].push(region)
    }
    return acc
  }, {})

  const groupedRegionKeys = Object.keys(groupedRegions)

  const layers: any = useMemo(
    () => [
      new GeoJsonLayer({
        id: 'geojson',
        filled: true,
        stroked: true,
        pickable: true,
        data: mapFeatures,
        lineWidthMinPixels: 0.3,
        lineWidthMaxPixels: 0.3,
        parameters: {
          depthTest: false,
        },
        getFillColor: (polygon: any) => {
          const region = regions?.find(r => r.id === polygon.id)
          const fillColorIndex = groupedRegionKeys.indexOf(region?.policyScopeCode || '')
          const wrappedFillColorIndex =
            fillColorIndex > dataMapFillColors.length ? fillColorIndex % dataMapFillColors.length : fillColorIndex
          if (region) region['fillColor'] = dataMapFillColors[wrappedFillColorIndex] as RGBAColor

          return region ? (region?.fillColor as RGBAColor) : [152, 11, 238, 0]
        },
        getLineColor: (polygon: any) => {
          const region = regions?.find(r => r.id === polygon.id)
          const fillColorIndex = groupedRegionKeys.indexOf(region?.policyScopeCode || '')
          const wrappedFillColorIndex =
            fillColorIndex > dataMapStrokeColors.length ? fillColorIndex % dataMapStrokeColors.length : fillColorIndex
          if (region) region['fillColor'] = dataMapStrokeColors[wrappedFillColorIndex] as RGBAColor

          return region
            ? (region?.fillColor as RGBAColor)
            : (hexToRGBArray(theme.palette.dataMapStrokeDefault.main) as RGBAColor)
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regions],
  )

  return layers
}
