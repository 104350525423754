import { WorkflowExecutionDTO } from 'interfaces/workflowExecutions/workflowExecution'
import {
  RightInvocationDTO,
  RightInvocationStatusDTO,
  WorkflowExecutionPreviewPathDTO,
  WorkflowExecutionPreviewStepDTO,
  WorkflowExecutionPreviewStepStatusDTO,
} from '@ketch-com/figurehead'
import { FlattenedWorkflowExecutionStepPreviewDTO } from './workflowPreviewColumns'

const doesStepAlreadyExistInFlattenedList = (flatData: FlattenedWorkflowExecutionStepPreviewDTO[], stepID?: string) => {
  return !!flatData.find(ele => {
    const path = ele.path || []
    const lastPathPart = path.length > 0 ? path[path.length - 1] : undefined

    return stepID === lastPathPart && stepID !== undefined
  })
}

const filterForUniquesInArray = (value: any, index: number, array: any[]) => {
  return array.indexOf(value) === index
}

type ParsedRowGroup = { groupContainsMixedPathStatuses: boolean; groupHasAnyVisibleChildren: boolean }
const EmptyParsedRowGroup: ParsedRowGroup = { groupContainsMixedPathStatuses: false, groupHasAnyVisibleChildren: false }

// Iterates through nested path objects to discern important info about the group
const parseGroupPaths = (
  step: FlattenedWorkflowExecutionStepPreviewDTO,
  showNotStarted: boolean,
  showSkipped: boolean,
  isWorkflowCompleted: boolean,
): ParsedRowGroup => {
  const statuses =
    step.paths
      // Pull all steps in all paths
      ?.flatMap(({ steps }) => steps)
      // Strip undefined steps
      .filter(x => !!x)
      // Filter out hidden steps
      .filter(step => shouldDisplayItem(step?.status!, isWorkflowCompleted ? showSkipped : showNotStarted, showSkipped))
      // Pull all statuses from all steps
      .map(step => step?.status)
      // Strip undefined statuses
      .filter(x => !!x)
      // Remove duplicates
      .filter(filterForUniquesInArray) || []

  // A group has mixed path statuses if the visible child steps display > 1 status
  const groupContainsMixedPathStatuses =
    statuses.length > 1 &&
    // Steps not yet completed should never display a mixed status
    step.status === WorkflowExecutionPreviewStepStatusDTO.CompletedWorkflowExecutionPreviewStepStatus

  // When workflow is finalized, "Not started" steps are shown as skipped
  // So if completed and showSkipped, ensure the steps masquerading as skipped get shown
  if (isWorkflowCompleted && showSkipped)
    statuses.push(WorkflowExecutionPreviewStepStatusDTO.SkippedWorkflowExecutionPreviewStepStatus)

  // Get a list of all statuses that should currently display
  const visibleStatuses = Object.values(WorkflowExecutionPreviewStepStatusDTO).filter(status => {
    if (!showSkipped && status === WorkflowExecutionPreviewStepStatusDTO.SkippedWorkflowExecutionPreviewStepStatus)
      return false
    if (!showNotStarted && status === WorkflowExecutionPreviewStepStatusDTO.PendingWorkflowExecutionPreviewStepStatus)
      return false
    return true
  })

  // A group has visible children if any of its child step statuses are visible
  const groupHasAnyVisibleChildren = statuses.filter(status => visibleStatuses.includes(status!)).length > 0

  return { groupContainsMixedPathStatuses, groupHasAnyVisibleChildren }
}

const shouldDisplayItem = (
  status: WorkflowExecutionPreviewStepStatusDTO,
  showNotStarted: boolean,
  showSkipped: boolean,
): boolean => {
  let shouldDisplay = true

  if (!showNotStarted && status === WorkflowExecutionPreviewStepStatusDTO.PendingWorkflowExecutionPreviewStepStatus) {
    shouldDisplay = false
  }

  if (!showSkipped && status === WorkflowExecutionPreviewStepStatusDTO.SkippedWorkflowExecutionPreviewStepStatus) {
    shouldDisplay = false
  }

  return shouldDisplay
}

type ParsedRowsResponse = {
  rows: FlattenedWorkflowExecutionStepPreviewDTO[]
  hasSkippedSteps: boolean
  hasNotStartedSteps: boolean
}

// API returns nested object of steps
// DataGrid demands a flat list with `path` key representing the nesting
export const parsePreviewRows = (
  rows: WorkflowExecutionPreviewStepDTO[],
  showSkipped: boolean,
  showNotStarted: boolean,
  workflowExecution: WorkflowExecutionDTO | null,
  rightInvocation: RightInvocationDTO | null,
): ParsedRowsResponse => {
  const flatData: FlattenedWorkflowExecutionStepPreviewDTO[] = []
  const isWorkflowCompleted = rightInvocation?.status !== RightInvocationStatusDTO.PendingRightInvocationStatus
  let headerCount = 1
  let hasSkippedSteps = false
  let hasNotStartedSteps = false

  // Recursively iterate thru nested object to create a flattened list of rows
  const flattenNestedStep = (array: FlattenedWorkflowExecutionStepPreviewDTO[], path: string[] = []) => {
    // Add each child step to parent array
    for (let i = 0; i < array.length; i++) {
      const item = array[i]
      const startPath = path
      path = [...path, item.stepID as string]

      // Check for duplicates sent from backend :(
      if (!doesStepAlreadyExistInFlattenedList(flatData, item.stepID)) {
        const isGroup = !!item.paths

        // Parse the group's path for info on its child rows
        const { groupContainsMixedPathStatuses, groupHasAnyVisibleChildren } = isGroup
          ? parseGroupPaths(item, showNotStarted, showSkipped, isWorkflowCompleted)
          : EmptyParsedRowGroup

        const status =
          isWorkflowCompleted &&
          item.status !== WorkflowExecutionPreviewStepStatusDTO.CompletedWorkflowExecutionPreviewStepStatus
            ? WorkflowExecutionPreviewStepStatusDTO.SkippedWorkflowExecutionPreviewStepStatus
            : item.status || WorkflowExecutionPreviewStepStatusDTO.SkippedWorkflowExecutionPreviewStepStatus

        // Only add rows that should be visible
        if (shouldDisplayItem(status, showNotStarted, showSkipped)) {
          flatData.push({
            path,
            ...item,
            id: item.stepID,
            isGroup: isGroup && groupHasAnyVisibleChildren,
            hasMixedStatuses: groupContainsMixedPathStatuses,
            status,
          })
        }

        // If step is skipped or not started, ensure that is marked so that we can enable the filtering checkboxes
        if (status === WorkflowExecutionPreviewStepStatusDTO.SkippedWorkflowExecutionPreviewStepStatus) {
          hasSkippedSteps = true
        }
        if (status === WorkflowExecutionPreviewStepStatusDTO.PendingWorkflowExecutionPreviewStepStatus) {
          hasNotStartedSteps = true
        }

        // For each child step, add a header row and run recursively
        if (isGroup) {
          //eslint-disable-next-line no-loop-func
          item?.paths?.forEach((itemPath: WorkflowExecutionPreviewPathDTO) => {
            // Path header rows should be added when 1 or more steps are visible in the current path
            const shouldAddHeader = itemPath.steps
              ?.map(itemPathStep => shouldDisplayItem(itemPathStep.status!, showNotStarted, showSkipped))
              .includes(true)

            if (shouldAddHeader) {
              // Create+add header row - empty rows with id and path only
              let newPath = [...path]
              const pathId = `Header-${headerCount}`
              newPath.push(pathId)
              flatData.push({
                path: newPath, // Rows are grouped on this path - all steps with this path will fall under it
                id: pathId,
                stepName: itemPath.name, // This field is displayed as the header row title
              } as FlattenedWorkflowExecutionStepPreviewDTO)

              headerCount++
            }

            // Recurse for each child step
            flattenNestedStep(itemPath.steps as FlattenedWorkflowExecutionStepPreviewDTO[], path)
          })
        }
      }

      path = startPath
    }
  }

  // Kick off recursive fn
  flattenNestedStep(rows as FlattenedWorkflowExecutionStepPreviewDTO[])

  // Ensure each row has an index so we can look up the step off of the workflow execution later
  // Can this be removed in favor of looking up steps by their IDs?
  const resultRows = flatData.map(row => ({
    ...row,
    rowIndex: workflowExecution?.steps?.findIndex(({ stepID }) => stepID === row.stepID) || 0,
  }))

  return { rows: resultRows, hasSkippedSteps, hasNotStartedSteps }
}
