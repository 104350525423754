import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { CookieDTO, CookieFormDTO } from 'interfaces/cookies/cookie'
import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'
import { showToast } from 'components/modals/AlertComponent'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useCookie } from 'api/cookies/queries/useCookie'
import { useCreateCookie } from 'api/cookies/mutations/useCreateCookie'
import { useUpdateCookie } from 'api/cookies/mutations/useUpdateCookie'
import { CreateCookieView } from 'pages/policyCenter/cookies/upsert/create/CreateCookieView'
import { EditCookieView } from 'pages/policyCenter/cookies/upsert/edit/EditCookieView'

export const CookieUpsertContainer: React.FC = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()
  const { queries } = useQueryParams<{ ketchCode?: string }>()

  const isEditMode = code !== NEW_ITEM_CODE
  const isKetchCookie = !!queries?.ketchCode

  const onUpsertSuccess = async ({ code }: CookieDTO) => {
    await queryClient.refetchQueries(ApiQueryKeys.entitlements)
    navigate(RoutesManager.policyCenter.cookies.view.root.getURL({ code }))
  }

  const { mutateAsync: handleCreateCookie } = useCreateCookie({
    onSuccess: ({ data }) => {
      showToast({ content: 'Cookie created', type: 'success' })
      onUpsertSuccess(data.cookie)
    },
    onError: () => {
      showToast({ content: 'Failed to create cookie', type: 'error' })
    },
  })

  const { mutateAsync: handleUpdateCookie } = useUpdateCookie({
    onSuccess: ({ data }) => {
      showToast({ content: 'Cookie updated', type: 'success' })
      onUpsertSuccess(data.cookie)
    },
    onError: () => {
      showToast({ content: 'Failed to update cookie', type: 'error' })
    },
  })

  const {
    data: cookie,
    isLoading: isCookieLoading,
    isFetching: isCookieFetching,
  } = useCookie({
    enabled: isEditMode,
    params: {
      cookieCode: code!,
    },
    onError: () => {
      navigate(RoutesManager.policyCenter.cookies.root.getURL())
    },
  })

  const {
    data: ketchCookie,
    isLoading: isKetchCookieLoading,
    isFetching: isKetchCookieFetching,
  } = useCookie({
    enabled: isKetchCookie,
    params: {
      cookieCode: queries.ketchCode!,
    },
    onError: () => {
      navigate(RoutesManager.policyCenter.cookies.root.getURL())
    },
  })

  const onSubmit = async (formData: CookieFormDTO) => {
    await (isEditMode
      ? handleUpdateCookie({
          params: {
            cookieCode: code!,
            formData,
          },
        })
      : handleCreateCookie({
          params: {
            formData,
          },
        }))
  }

  const isLoading = isCookieLoading || isKetchCookieLoading
  const isFetching = isCookieFetching || isKetchCookieFetching

  return isEditMode ? (
    <EditCookieView
      isKetchCookie={isKetchCookie}
      isReady={!isLoading && !isFetching}
      cookie={cookie}
      onSubmit={onSubmit}
    />
  ) : (
    <CreateCookieView
      isReady={!isLoading && !isFetching}
      isKetchCookie={isKetchCookie}
      cookie={cookie}
      ketchCookie={ketchCookie}
      onSubmit={onSubmit}
    />
  )
}
