import { useContext } from 'react'
import { FormikHelpers } from 'formik'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { showToast } from 'components/modals/AlertComponent'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useUpdateWorkflowExecution } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecution'
import { RoutesManager } from 'utils/routing/routesManager'
import { EndStepFormValues, getValidationSchema, getInitialValues } from '../utils'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
import { useUpdateWorkflowExecutionStep } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStep'
import { ensureArray } from 'utils/helpers/array'
import { useUploadFile } from 'api/files/mutations/useUploadFile'
import { AccessControlType } from 'components/ui-kit/form/imageUpload/hooks'

export const useFinalizeRequestModalUtils = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { stepDetails, rightInvocation, stepId, workflowExecutionId, refetchWorkflowStep, workflowStep } = useContext(
    RightInvocationWorkflowStepDetailViewContext,
  )
  const isCoverLetterRequired = stepDetails?.executionData?.end?.isCoverLetterRequired || false
  const isMessageRequired = stepDetails?.executionData?.end?.isMessageRequired || false

  const initialValues = getInitialValues()

  const validationSchema = getValidationSchema({ isCoverLetterRequired, isMessageRequired })

  const { mutateAsync: submitStepDetails, isLoading: isSubmittingStepDetails } = useUpdateWorkflowExecutionStep({
    onSuccess: async () => {
      showToast({ content: 'Manual intervention data successfully saved', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to save manual intervention data', type: 'error' })
    },
  })
  const { mutateAsync: handleCompleteWorkflowExecutionStep, isLoading: isCompletingWorkflowExecutionStep } =
    useUpdateWorkflowExecution({
      onSuccess: async () => {
        await queryClient.refetchQueries([ApiQueryKeys.workflowExecutionStepDetailsV2, { workflowExecutionId, stepId }])
        await queryClient.refetchQueries([ApiQueryKeys.workflowExecution, { workflowExecutionId }])
        await refetchWorkflowStep()
        showToast({ content: 'End Flow was successfully resolved', type: 'success' })
        navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocation?.id! }))
      },
      onError: () => {
        showToast({ content: 'Failed to resolve End Flow', type: 'error' })
      },
    })

  const { mutateAsync: handleUploadFile, isLoading: isUploadingFile } = useUploadFile({
    onError: error => showToast({ content: 'Failed to upload file', type: 'error' }),
  })

  const resolve = async (values: EndStepFormValues, formikHelpers: FormikHelpers<EndStepFormValues>) => {
    const { coverLetter: acceptedFiles } = values

    const responses = await Promise.all(
      ensureArray(acceptedFiles).map(async file => {
        try {
          const fileUploadParams = {
            file: file as any,
            acl: `${AccessControlType.signed}`,
            bucket: '',
            folder: `${rightInvocation?.id}_${workflowStep?.ID}`,
            version: 'cover_letter',
          }
          const { data } = (await handleUploadFile({
            params: fileUploadParams,
          }))!

          return { data, file }
        } catch (error) {
          return { error, file }
        }
      }),
    )

    const { subjectCommunicationMessage, reasonForFinalization } = values

    const formData = {
      params: {
        formData: {
          temporalWorkflowID: `${workflowExecutionId}${stepId}`,
          signalName: 'end_workflow_resolve',
          signalValue: {
            completed: true,
            subjectCommunicationMessage,
            coverLetter: responses?.[0]?.data?.info_url || '',
            reasonForFinalization,
            finalizeRequestAs: values.finalizeRequestAs,
          },
        },
      },
    }

    const stepDetails = submitStepDetails({
      params: {
        formData: {
          manualInterventionData: {
            subjectCommunicationMessage,
          },
        },
        workflowExecutionId,
        stepId,
      },
    })

    const workflowExecution = handleCompleteWorkflowExecutionStep(formData)

    await Promise.all([stepDetails, workflowExecution])
  }

  return {
    initialValues,
    validationSchema,
    resolve,
    isUploading: isUploadingFile || isSubmittingStepDetails || isCompletingWorkflowExecutionStep,
    isMessageRequired,
    isCoverLetterRequired,
    allowAppeal: !!stepDetails.executionData?.end?.appealDetails?.allowAppeal,
    timeToInvokeAppealInDays: stepDetails.executionData?.end?.appealDetails?.timeToInvokeAppealInDays || 0,
  }
}
