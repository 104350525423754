import React from 'react'
import { useField, FieldValidator } from 'formik'
import { Box, InputLabel, Typography } from '@mui/material'
import { SwitchToggle, SwitchToggleProps } from '@ketch-com/deck'
import { FormError } from './FormError'

type OmittedProps = Omit<SwitchToggleProps, 'value'>

interface Props extends OmittedProps {
  /** Formik field name */
  formPropertyName: string
  /** Text to be displayed below Component */
  hint?: string
  /** Text to be displayed above Component */
  label?: string
  /** Display Asterisk as required field */
  required?: boolean
  /** Custom validation method */
  validate?: FieldValidator
  /** Should label be displayed inline? (Default: false) */
  inlineLabel?: boolean
  /** Should label be displayed as bold? (Default: true) */
  boldLabel?: boolean
  /** Custom onChange method */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  shouldShowOptional?: boolean
}

export const FormSwitch: React.FC<Props> = ({
  formPropertyName,
  hint = '',
  inlineLabel = false,
  boldLabel = true,
  label = '',
  onChange: onChangeOuter,
  required = false,
  shouldShowOptional = false,
  validate,
  ...baseSwitchProps
}) => {
  const [field, meta] = useField({ name: formPropertyName, validate, type: 'checkbox' })
  const showError = meta.error && meta.touched

  return (
    <Box display={inlineLabel ? 'block' : 'inline-block'}>
      <Box display={inlineLabel ? 'inline-flex' : 'block'} alignItems="center" flexDirection="row-reverse" gap={1.25}>
        <InputLabel>
          {required ? (
            <Typography variant={boldLabel ? 'label' : 'body'} color="darkDusk.main">
              {label}
            </Typography>
          ) : (
            <>
              <Typography variant={boldLabel ? 'label' : 'body'} color="darkDusk.main">
                {label}
              </Typography>{' '}
              {shouldShowOptional && (
                <Typography variant={boldLabel ? 'label' : 'body'} color="darkGrey.main">
                  {'(Optional)'}
                </Typography>
              )}
            </>
          )}
        </InputLabel>
        <SwitchToggle
          {...field}
          {...baseSwitchProps}
          onChange={(e, checked) => {
            field.onChange(e)
            onChangeOuter?.(e, checked)
          }}
        />
      </Box>
      <Box>
        {showError && <FormError msg={meta.error} />}
        {hint && !showError && (
          <Typography variant="smallLabel" color="darkDuskFaded.main">
            {hint}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
