import React from 'react'
import { Status, StatusState, StatusVariant } from '@ketch-com/deck'

export type TranslationDTO = {
  code?: string
  title?: string
  fieldTitleTranslation?: string
  uiHintTranslation?: string
}

type Props = {
  englishName: string
  languageObj: any
}

export const LanguageCellRenderer: React.FC<Props> = ({ languageObj, englishName }) => {
  const { language, enabled } = languageObj

  return (
    <Status
      status={enabled ? StatusState.success : StatusState.draft}
      variant={StatusVariant.ghost}
      icon="Dot"
      label={englishName ? englishName : language}
    />
  )
}
