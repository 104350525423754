import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Checkbox, TableCell } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { PurposeDTO } from 'interfaces/purposes/purpose'

const BusinessFunctionsUpsertPurposeCell = ({
  purpose,
  onCheckboxChange,
  checkIsChecked,
}: {
  purpose: PurposeDTO
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, purpose: PurposeDTO) => void
  checkIsChecked: (purpose: PurposeDTO) => boolean
}) => {
  return (
    <TableCell sx={{ margin: '8px 0' }}>
      <>
        <Checkbox
          checked={checkIsChecked(purpose)}
          onChange={event => onCheckboxChange(event, purpose)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Box
          display="flex"
          sx={{
            flexDirection: 'column',
          }}
        >
          <Typography variant="label" color="darkDusk.main">
            {purpose.name}
          </Typography>
          <Typography variant="footnote" color="darkDuskFaded.main">
            {purpose.code}
          </Typography>
        </Box>
      </>
    </TableCell>
  )
}

export const businessFunctionsUpsertColumns = (
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, purpose: PurposeDTO) => void,
  checkIsChecked: (purpose: PurposeDTO) => boolean,
): GridColDef<PurposeDTO>[] => [
  {
    align: 'left',
    field: 'purpose',
    headerAlign: 'left',
    headerName: 'Purpose',
    minWidth: 300,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) => {
      return (
        <BusinessFunctionsUpsertPurposeCell
          purpose={row}
          onCheckboxChange={onCheckboxChange}
          checkIsChecked={checkIsChecked}
        />
      )
    },
  },
  {
    align: 'left',
    field: 'description',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Description',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) =>
      row.description ? (
        <TableCell sx={{ margin: '8px 0' }} title={row.description} />
      ) : (
        <TableCell sx={{ margin: '8px 0' }}>
          <Typography color="darkGrey.main">None</Typography>
        </TableCell>
      ),
  },
]
