import React from 'react'
import { useFormik } from 'formik'
import { DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers-pro'
import { DatePicker, DatePickersActionBar, DatePickersActionBarProps } from '@ketch-com/deck'
import { Dayjs } from 'dayjs'
import { Typography } from '@mui/material'

type Props = {}

type FormValues = {
  value: Dayjs | null
  previousValue: Dayjs | null
  isOpen: boolean
}

export const DatePickerExample: React.FC<Props> = props => {
  const formik = useFormik<FormValues>({
    initialValues: {
      value: null,
      previousValue: null,
      isOpen: false,
    },
    onSubmit: () => {},
  })

  const onCloseHandler = () => formik.setFieldValue('isOpen', false)

  const onClearHandler = () => formik.setFieldValue('value', null)

  const handleOnCancel = () => {
    formik.setFieldValue('value', formik.values.previousValue)
    formik.setFieldValue('isOpen', false)
  }

  const onChangeHandler = (newValue: Dayjs | null, context: PickerChangeHandlerContext<DateValidationError>) => {
    formik.setFieldValue('value', newValue)
  }

  const onOpenHandler = () => {
    formik.setFieldValue('isOpen', true)
    formik.setFieldValue('previousValue', formik.values.value)
  }

  const onAcceptHandler = (newValue: Dayjs | null) => {
    formik.setFieldValue('previousValue', newValue)
    formik.setFieldValue('value', newValue)
    formik.setFieldValue('isOpen', false)
  }

  return (
    <>
      <Typography variant="h3" mb={2}>
        Date Picker component
      </Typography>
      <DatePicker
        {...props}
        open={formik.values.isOpen}
        onOpen={onOpenHandler}
        value={formik.values.value}
        onClose={onCloseHandler}
        onChange={onChangeHandler}
        onAccept={onAcceptHandler}
        slots={{
          actionBar: (props: DatePickersActionBarProps) => {
            return <DatePickersActionBar {...props} handleOnClear={onClearHandler} handleOnCancel={handleOnCancel} />
          },
        }}
      />
      <pre>{JSON.stringify(formik.values, null, 2) || 'nothing to preview'}</pre>
    </>
  )
}
