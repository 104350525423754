import React from 'react'
import Box from '@mui/material/Box'

import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'
import {
  FREQUENCY_OPTIONS,
  DAY_OF_WEEK_OPTIONS,
} from 'pages/assetManager/databases/upsert/components/EditAutoDiscoveryFormSection/constants'
import { getUtcOffsetMessage } from 'pages/assetManager/databases/upsert/components/EditAutoDiscoveryFormSection/utils'

type Props = {
  assetSummary?: AssetSummaryDTO
}

export const AutoDiscoveryDisplay: React.FC<Props> = ({ assetSummary }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        {!assetSummary?.asset?.schedule ? (
          <Text size={14}>Disabled</Text>
        ) : (
          <Box display="flex" alignItems="center">
            <Text size={14}>
              {FREQUENCY_OPTIONS.find(o => o.id === String(assetSummary?.asset?.schedule?.frequency))?.name}
              {assetSummary?.asset?.schedule?.day
                ? ` (${DAY_OF_WEEK_OPTIONS.find(o => o.id === String(assetSummary?.asset?.schedule?.day))?.fullName})`
                : null}

              {assetSummary?.asset?.schedule?.time
                ? `, ${new Date(assetSummary?.asset?.schedule?.time).toLocaleTimeString([], {
                    hour: 'numeric',
                    minute: '2-digit',
                  })}`
                : null}
            </Text>
          </Box>
        )}
      </Box>
      {assetSummary?.asset?.schedule?.time ? (
        <Text size={14} color="darkDuskFaded" mt={1}>
          {getUtcOffsetMessage()}
        </Text>
      ) : null}
    </Box>
  )
}
