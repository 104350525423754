import React from 'react'
import { Checkbox } from 'components/ui-kit/checkbox/Checkbox'

type Props = {
  code: string
  isChecked: boolean
  handleToggleSelectDatabase: (code: string) => void
}

export const DatabaseCheckboxCellRenderer: React.FC<Props> = ({ code, isChecked, handleToggleSelectDatabase }) => (
  <Checkbox
    value={isChecked}
    onChange={() => {
      handleToggleSelectDatabase(code)
    }}
  />
)
