import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ExperienceViewActions } from 'pages/consentAndRights/experiences-v2/view/components/ExperienceViewActions'
import { ExperienceDeployments } from 'pages/consentAndRights/experiences-v2/view/tabs/ExperienceDeployments'
import { ExperienceVersions } from 'pages/consentAndRights/experiences-v2/view/tabs/ExperienceVersions'
import { ExperienceForms } from './tabs/ExperienceForms'
import { ExperienceViewContext, withExperienceViewContext } from './context/ExperienceViewContext'
import { ExperienceSubscriptions } from './tabs/ExperienceSubscriptions'
import { ExperienceViewDetails } from './components/ExperienceViewDetails'
import { ExperiencePreviewVariant } from './components/experiencePreview/constants'
import { ExperiencePreview } from './components/experiencePreview/ExperiencePreview'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Spinner, ViewLayout } from '@ketch-com/deck'
import { Box } from '@mui/material'

const ExperienceViewWithoutContext: React.FC = () => {
  const navigate = useNavigate()
  const { location, experience, isReady } = useContext(ExperienceViewContext)
  const { pathname } = location

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Experiences', link: RoutesManager.deployment.experiencesV2.root.getURL() },
    { title: experience?.name },
  ]

  const [currentTab, setCurrentTab] = useState(0)
  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    navigate(tabs[newValue].link)
  }

  const tabs = useMemo(
    () => [
      {
        label: 'Preview',
        link: {
          pathname: RoutesManager.deployment.experiencesV2.view.preview.root.getURL({ id: experience?.id }),
          search: location.search,
        },
      },
      // TODO:JB - Descoped
      // ...(!isConsent
      //   ? [
      //       {
      //         title: 'Forms',
      //         link: {
      //           pathname: RoutesManager.deployment.experiencesV2.view.forms.root.getURL({ id: experience.id }),
      //           search: location.search,
      //         },
      //       },
      //     ]
      //   : []),
      // ...(!isConsent
      //   ? [
      //       {
      //         title: 'Subscriptions',
      //         link: {
      //           pathname: RoutesManager.deployment.experiencesV2.view.subscriptions.root.getURL({ id: experience.id }),
      //           search: location.search,
      //         },
      //       },
      //     ]
      //   : []),
      {
        label: 'Deployments',
        link: {
          pathname: RoutesManager.deployment.experiencesV2.view.deployments.root.getURL({ id: experience?.id }),
          search: location.search,
        },
      },
      // {
      //   title: 'Versions',
      //   link: {
      //     pathname: RoutesManager.deployment.experiencesV2.view.versions.root.getURL({ id: experience.id }),
      //     search: location.search,
      //   },
      // },
    ],
    [experience?.id, location.search],
  )

  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (pathname.includes(tab.link.pathname)) {
        setCurrentTab(index)
      }
    })
  }, [currentTab, pathname, tabs])

  if (!isReady || !experience) {
    return (
      <>
        <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )
  }

  // TODO:JB - Descoped
  const isConsent = experience.type === ExperienceType.Consent

  // TODO:JB - isLatest / verions
  // const isLatest = !versions.length || !!latest

  // TODO:JB - Issues message actions
  // const issuesMessageActions = [
  //   {
  //     title: 'Update',
  //     onClick: () => {
  //       navigate(
  //         RoutesManager.deployment.experiencesV2.upsert.root.getURL({
  //           id: experience.id,
  //           formMode: FormMode.EDIT,
  //           experienceType: isConsent ? ExperienceType.Consent : ExperienceType.Preference,
  //         }),
  //       )
  //     },
  //   },
  // ]

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      <ViewLayout
        contentGroupProps={{
          title: experience.name,
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.deployment.experiencesV2.root.getURL()),
          },
          infoBlockComponent: <ExperienceViewDetails />,
          actionRightBlockComponent: <ExperienceViewActions />,
        }}
        tabsComponentProps={{ value: currentTab, onChange: handleTabChange, tabItems: tabs }}
      >
        <Routes location={location}>
          <Route
            path="/"
            element={
              <ExperiencePreview
                variant={
                  isConsent ? ExperiencePreviewVariant.ConsentExperience : ExperiencePreviewVariant.PreferenceExperience
                }
                experienceID={experience.id}
              />
            }
          />
          <Route
            path={RoutesManager.deployment.experiencesV2.view.preview.root.pattern}
            element={
              <ExperiencePreview
                variant={
                  isConsent ? ExperiencePreviewVariant.ConsentExperience : ExperiencePreviewVariant.PreferenceExperience
                }
                experienceID={experience.id}
              />
            }
          />
          <Route path={RoutesManager.deployment.experiencesV2.view.forms.root.pattern} element={<ExperienceForms />} />
          <Route
            path={RoutesManager.deployment.experiencesV2.view.subscriptions.root.pattern}
            element={<ExperienceSubscriptions />}
          />
          <Route
            path={RoutesManager.deployment.experiencesV2.view.deployments.root.pattern}
            element={<ExperienceDeployments />}
          />
          <Route
            path={RoutesManager.deployment.experiencesV2.view.versions.root.pattern}
            element={<ExperienceVersions />}
          />
        </Routes>
      </ViewLayout>
    </>
  )
}

export const ExperienceV2View = withExperienceViewContext(ExperienceViewWithoutContext)
