import { ApplicationIdentitySpaceFormat } from 'interfaces/applications/ApplicationIdentitySpaceFormat'
import { ApplicationIdentitySpaceType } from 'interfaces/applications/applicationIdentitySpaceType'
import { ApplicationIdentitySpaceEncoding } from 'interfaces/applications/ApplicationIdentitySpaceEncoding'

export const getIdentitySpaceEmptyValue = (
  isMobile: boolean = false,
): {
  identitySpaceID: string
  format: ApplicationIdentitySpaceFormat.STRING | string
  variable: string
  type: ApplicationIdentitySpaceType.QUERY_STRING | string
  encoding: ApplicationIdentitySpaceEncoding
  key: string
} => ({
  format: isMobile ? ApplicationIdentitySpaceFormat.STRING : '',
  identitySpaceID: '',
  key: '',
  type: isMobile ? ApplicationIdentitySpaceType.QUERY_STRING : '',
  variable: '',
  encoding: ApplicationIdentitySpaceEncoding.NONE,
})
