import { useState } from 'react'
import { compact } from 'lodash'
import { ContextVariableCategoryDTO } from '@ketch-com/figurehead'

import { ICanvasStep, ITaskActivityParams } from 'pages/orchestration/workflows/edit/interfaces'
import { useUsers } from 'api/users/queries/useUsers'
import { useContextVariablesPaginated } from 'api/contextVariables/queries/useContextVariablesPaginated'
import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { TaskRemovalWarningMessageConfig } from '../interfaces'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'

type UseTaskFormUtilsProps = {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const useTaskFormUtils = ({ step, steps, handleClose, handleRemove, handleSave }: UseTaskFormUtilsProps) => {
  const [variablesWarning, setVariablesWarning] = useState('')
  const [taskRemovalWarningMessageConfig, setTaskRemovalWarningMessageConfig] = useState<
    TaskRemovalWarningMessageConfig[]
  >([])

  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))

  const { isLoading: isLoadingUsers } = useUsers({ params: { active: true } })

  const [selectedDataSubjectCodeForAddition, setSelectedDataSubjectCodeForAddition] = useState<string>('')
  const [selectedOutcomeCodeForAddition, setSelectedOutcomeCodeForAddition] = useState<string>('')
  const [selectedIdentitySpaceCodeForAddition, setSelectedIdentitySpaceCodeForAddition] = useState<string>('')

  const [unAddedOutcomeVariableWarning, setUnAddedOutcomeVariableWarning] = useState<boolean>(false)
  const [unAddedDataSubjectVariableWarning, setUnAddedDataSubjectVariableWarning] = useState<boolean>(false)
  const [unAddedIdentitySpaceWarning, setUnAddedIdentitySpaceWarning] = useState<boolean>(false)

  const { data: dataSubjectVariables, isLoading: isLoadingDataSubjectVariables } = useContextVariablesPaginated({
    params: { limit: 2000, category: ContextVariableCategoryDTO.DataSubjectContextVariableCategory },
  })

  const { data: outcomeVariables, isLoading: isLoadingOutcomeVariables } = useContextVariablesPaginated({
    params: { limit: 2000, category: ContextVariableCategoryDTO.OutcomeContextVariableCategory },
  })

  const { data: identitySpaces, isLoading: isLoadingIdentitySpaces } = useIdentitySpaces({
    params: { limit: 2000 },
  })

  const onSubmit = (values: ITaskActivityParams) => {
    if (
      [
        values.hasResolutionNotes,
        values.hasResolutionAttachments,
        values.hasDsrResolutionAttachments,
        values.hasDataSubjectVariables,
        values.hasOutcomeVariables,
        values.hasIdentitySpaces,
      ].filter(Boolean).length < 1
    ) {
      setVariablesWarning('Please select a minimum of one Outcome for this Task.')
      return
    }

    const payload = {
      step: {
        ...step,
        valid: true,
        code: values.code,
        description: values.description,
        activity: {
          ...step.activity,
          params: {
            assignee: values.assignee,
            details: values.details,

            // Notes
            hasResolutionNotes: values.hasResolutionNotes,
            isResolutionNotesRequired: values.isResolutionNotesRequired,

            // Attachments
            hasResolutionAttachments: values.hasResolutionAttachments,
            isResolutionAttachmentsRequired: values.isResolutionAttachmentsRequired,

            // Attachments for Data Subject
            hasDsrResolutionAttachments: values.hasDsrResolutionAttachments,
            isDsrResolutionAttachmentsRequired: values.isDsrResolutionAttachmentsRequired,

            // Data Subject Variables
            hasDataSubjectVariables: values.hasDataSubjectVariables,
            dataSubjectVariables: values.dataSubjectVariables,

            // Outcome Variables
            hasOutcomeVariables: values.hasOutcomeVariables,
            outcomeVariables: values.outcomeVariables,

            // Identifier
            hasIdentitySpaces: values.hasIdentitySpaces,
            identitySpaces: values.identitySpaces,

            // Activity Timer
            timeToCompleteDays: values.timeToCompleteDays,
          },
        } as ICanvasStep['activity'],
      },
    }

    handleSave(payload)
  }

  const isLoadingAggregate =
    isLoadingUsers || isLoadingDataSubjectVariables || isLoadingOutcomeVariables || isLoadingIdentitySpaces

  const collectedDataSubjectVariableCodesUsedInTaskTilesCodes = (steps: Array<ICanvasStep>): Array<string> => {
    const taskSteps = steps
      .filter(step => step.activityCode === WorkflowActivityCode.TASK)
      .map(step => (step?.activity?.params as ITaskActivityParams)?.dataSubjectVariables)
      .filter(Boolean)

    const taskStepsDataSubjectVariableCodes = taskSteps.flatMap(taskStep => taskStep?.map(variable => variable.code))

    return taskStepsDataSubjectVariableCodes
  }

  const dsrCodesAlreadyUsedInWorkflow = collectedDataSubjectVariableCodesUsedInTaskTilesCodes(steps)
  const filteredDataSubjectVariables = dataSubjectVariables?.filter(
    variable => !dsrCodesAlreadyUsedInWorkflow.includes(variable?.code || ''),
  )

  return {
    dataSubjectVariables,
    filteredDataSubjectVariables,
    handleClose,
    handleRemove,
    identitySpaces,
    isLoadingAggregate,
    onSubmit,
    outcomeVariables,
    selectedDataSubjectCodeForAddition,
    selectedIdentitySpaceCodeForAddition,
    selectedOutcomeCodeForAddition,
    setSelectedDataSubjectCodeForAddition,
    setSelectedIdentitySpaceCodeForAddition,
    setSelectedOutcomeCodeForAddition,
    setTaskRemovalWarningMessageConfig,
    setUnAddedDataSubjectVariableWarning,
    setUnAddedIdentitySpaceWarning,
    setUnAddedOutcomeVariableWarning,
    setVariablesWarning,
    taskRemovalWarningMessageConfig,
    unAddedDataSubjectVariableWarning,
    unAddedIdentitySpaceWarning,
    unAddedOutcomeVariableWarning,
    usedCodes,
    variablesWarning,
  }
}
