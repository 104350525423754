import { RightsFormMode, RightMappingDetail, RightFormMapping } from '@ketch-sdk/ketch-types'
import { Box, List, Tooltip, Typography, styled } from '@mui/material'
import { FieldArray, getIn, useFormikContext } from 'formik'
import { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { experienceUpsertFormKeys } from '../../../utils/useExperienceValidationSchema'
import { Draggable } from 'react-beautiful-dnd'
import { Button, Chip, DropListButton, Icon, ListItem, theme } from '@ketch-com/deck'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ExperienceUpsertContext } from '../../../context/ExperienceUpsertContext'
import pluralize from 'pluralize'
import { CanonicalRightCodeLabels } from '../../../utils/labels'

type RightMappingDraggableProps = {
  variant: RightsFormMode
  rightMapping: RightMappingDetail
  index: number
  defaultFormDropdownRender?: (formId?: string) => ReactNode
  onPreviewForm?: (formId?: string) => void
  shouldOpened?: boolean
}

const RightMappingDraggableBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 0px 12px 0px',
  justifyContent: 'center',
  background: theme.palette.fadedGrey.main,
  borderRadius: '11px',
  minHeight: '54px',
}))

const HeaderBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  padding: '0px 12px 0px 4px',
})

const ContentHeaderBox = styled(Box)({
  padding: '12px 16px',
})

const ContentFooterBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  padding: '12px 16px 4px 16px',
})

const RightToFormMappingBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  padding: '12px 16px',
  borderTop: `1px solid ${theme.palette.iron.main}`,
  '&:last-child': {
    borderBottom: `1px solid ${theme.palette.iron.main}`,
  },
}))

const RightToFormMappingIndexBox = styled(Box)({
  display: 'flex',
  borderRadius: 100,
  background: 'white',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
})

const RightToFormMappingDropdownsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '8px',
})

const RightToFormMappingRightDropdownBox = styled(Box)({
  display: 'flex',
  gap: '8px',
})

const RightsTooltipListItem = styled(ListItem)({
  display: 'list-item',
  padding: '0px 0px 0px 8px',
  margin: '0px 0px 0px 16px',
  minHeight: 0,
})

export const RightMappingDraggable: FC<RightMappingDraggableProps> = ({
  variant,
  rightMapping,
  index,
  defaultFormDropdownRender,
  onPreviewForm,
  shouldOpened = true,
}) => {
  // Data
  const { canonicalRight, rightFormMappings } = rightMapping
  const { canonicalRightTemplates, isCanonicalRightsLoading, formTemplateOptions, isFormTemplatesLoading } =
    useContext(ExperienceUpsertContext)

  // Form info
  const fieldName = `${experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsDetails}[${index}]`
  const { values, errors } = useFormikContext<ExperienceV2DTO>()
  const defaultFormId = getIn(values, `${fieldName}.defaultFormID`)
  const defaultFormError = getIn(errors, `${fieldName}.defaultFormID`)
  const defaultFormValue = formTemplateOptions.find(option => option.value === defaultFormId)
  const isSingleMode =
    getIn(values, experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsMode) === RightsFormMode.Single

  // State
  const [open, setOpen] = useState(!isSingleMode) // Open by default if not in single mode

  // Hooks
  const rightOptions = useMemo(() => {
    return canonicalRightTemplates
      .find(template => template.code === canonicalRight)
      ?.rights?.map(right => ({
        value: right.code,
        label: right.name,
      }))
  }, [canonicalRightTemplates, canonicalRight])

  // Ensures form sections are open when there is an error
  useEffect(() => {
    if (variant === RightsFormMode.Custom && defaultFormError) setOpen(true)
  }, [defaultFormError, variant])

  return (
    <Draggable key={canonicalRight} draggableId={canonicalRight || ''} index={index}>
      {provided => {
        return (
          <RightMappingDraggableBox
            ref={provided.innerRef}
            key={canonicalRight}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <HeaderBox>
              <Box display={'flex'} alignItems={'center'} gap={'4px'}>
                <Icon name={'ODragHandle'} iconColor={theme.palette.fadedDarkGrey.main} />
                {variant === RightsFormMode.Custom && (
                  <Icon
                    name={open ? 'OArrowCDown' : 'OArrowCRight'}
                    onClick={() => {
                      setOpen(!open)
                    }}
                  />
                )}
                <Box>
                  <Typography variant="h4">{CanonicalRightCodeLabels[canonicalRight!]}</Typography>
                  {variant === RightsFormMode.Custom && !!rightMapping.rightFormMappings?.length && (
                    <Typography variant={'smallLabel'} color={theme.palette.darkDuskFaded.main}>
                      {/* We use length + 1 here as it's the default form + custom ones */}
                      {rightMapping.rightFormMappings?.length + 1}{' '}
                      {pluralize('form', rightMapping.rightFormMappings?.length + 1)}
                    </Typography>
                  )}
                </Box>
              </Box>

              {/* Rights hover chip */}
              {(rightOptions?.length ?? 0) > 0 && (
                <Tooltip
                  title={
                    rightOptions ? (
                      <List sx={{ listStyleType: 'disc', display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {rightOptions.map(right => (
                          <RightsTooltipListItem>{right.label}</RightsTooltipListItem>
                        ))}
                      </List>
                    ) : (
                      ''
                    )
                  }
                  placement="left"
                  arrow
                >
                  <span>
                    <Chip
                      size={'small'}
                      label={<Typography variant={'smallLabel'}>{rightOptions?.length || 0} rights</Typography>}
                      clickable
                    />
                  </span>
                </Tooltip>
              )}
            </HeaderBox>

            {open && shouldOpened && (
              <FieldArray
                name={`${fieldName}.rightFormMappings`}
                render={arrayHelpers => (
                  <Box>
                    {/* Content Header - Default form selector */}
                    <ContentHeaderBox
                      sx={{
                        ...(!!defaultFormDropdownRender && {
                          display: 'flex',
                          alignItems: 'flex-end',
                          gap: 1,
                          '& > div:first-of-type': {
                            width: '100%',
                          },
                        }),
                      }}
                    >
                      <DropListButton
                        size={'small'}
                        label="Default Form"
                        placeholder="Select Form"
                        options={formTemplateOptions}
                        value={defaultFormValue}
                        loading={isFormTemplatesLoading}
                        onChange={(e, newValue) => {
                          // Update defaultFormId
                          arrayHelpers.form.setFieldValue(`${fieldName}.defaultFormID`, newValue?.value)
                          onPreviewForm?.(newValue?.value)
                        }}
                        error={!!defaultFormError}
                        errorMessage={defaultFormError}
                      />
                      {!!defaultFormDropdownRender && defaultFormDropdownRender(defaultFormValue?.value)}
                    </ContentHeaderBox>

                    {/* Content body - Right to Form Mappings */}
                    <Box>
                      {rightFormMappings?.map((mapping, mappingIndex) => {
                        // Field names
                        const mappingFieldName = `${fieldName}.rightFormMappings[${mappingIndex}]`
                        const mappingRightFieldName = `${mappingFieldName}.rightCode`
                        const mappingFormFieldName = `${mappingFieldName}].formID`

                        // Get right and form values
                        const rightCode = getIn(values, mappingRightFieldName)
                        const selectedRight = rightOptions?.find(rightOption => rightOption.value === rightCode)
                        const formId = getIn(values, mappingFormFieldName)
                        const selectedForm = formTemplateOptions.find(formOption => formOption.value === formId)

                        // Get right and form errors
                        const rightError = getIn(errors, mappingRightFieldName)
                        const formError = getIn(errors, mappingFormFieldName)

                        // Right form options available to this specific mapping box
                        const mappingRightOptions = canonicalRightTemplates
                          .find(template => template.code === canonicalRight)
                          ?.rights?.map(right => ({
                            value: right.code,
                            label: right.name,
                          }))
                          .filter(
                            right =>
                              selectedRight?.value === right.value ||
                              !rightFormMappings?.some(formMapping => formMapping.rightCode === right.value),
                          )
                        return (
                          <RightToFormMappingBox key={mappingIndex}>
                            <Box pt="22px">
                              <RightToFormMappingIndexBox>
                                <Typography variant={'smallLabel'}>{mappingIndex + 1}</Typography>
                              </RightToFormMappingIndexBox>
                            </Box>
                            <RightToFormMappingDropdownsBox>
                              <RightToFormMappingRightDropdownBox width={'100%'}>
                                <Box width={'100%'}>
                                  {/* Right dropdown */}
                                  <DropListButton
                                    size={'small'}
                                    label="Right"
                                    placeholder="Select Right"
                                    options={mappingRightOptions || []}
                                    loading={isCanonicalRightsLoading}
                                    value={selectedRight}
                                    onChange={(e, newValue) => {
                                      // Get current value and update rightCode field
                                      const current = getIn(values, mappingFieldName)
                                      arrayHelpers.replace(mappingIndex, {
                                        ...current,
                                        rightCode: newValue?.value,
                                      })
                                    }}
                                    error={!!rightError}
                                    errorMessage={rightError}
                                  />
                                </Box>

                                <Box pt="20px">
                                  {/* Delete mapping button */}
                                  <Button
                                    variant={'iconCustomRounded'}
                                    onClick={() => {
                                      arrayHelpers.remove(mappingIndex)
                                    }}
                                    sx={theme => ({
                                      background: theme.palette.fadedGrey.main,
                                      borderRadius: '5px',
                                      height: '28px',
                                      width: '28px',
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                    })}
                                  >
                                    <Icon name={'FBin'} width={20} height={20} />
                                  </Button>
                                </Box>
                              </RightToFormMappingRightDropdownBox>

                              {/* Form dropdown */}
                              <DropListButton
                                size={'small'}
                                label="Form"
                                placeholder="Select Form"
                                options={formTemplateOptions}
                                loading={isFormTemplatesLoading}
                                value={selectedForm}
                                onChange={(e, newValue) => {
                                  // Get current value and update formID field
                                  const current = getIn(values, mappingFieldName)
                                  arrayHelpers.replace(mappingIndex, {
                                    ...current,
                                    formID: newValue?.value,
                                  } as RightFormMapping)
                                }}
                                error={!!formError}
                                errorMessage={formError}
                              />
                            </RightToFormMappingDropdownsBox>
                          </RightToFormMappingBox>
                        )
                      })}
                    </Box>

                    {/* Content Footer - Add mapping button */}
                    <ContentFooterBox>
                      <Button
                        variant={'link'}
                        startIcon={
                          <Icon name="OPlusRound" iconColor={theme.palette.sphere.main} width={24} height={24} />
                        }
                        onClick={() => {
                          arrayHelpers.push({
                            rightCode: '',
                            formID: '',
                          } as RightFormMapping)
                        }}
                      >
                        <Typography
                          variant={'label'}
                          color={theme.palette.sphere.main}
                          sx={theme => ({ textDecorationColor: `${theme.palette.sphere.main} !important` })}
                        >
                          Add Custom Form Mapping
                        </Typography>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        arrow
                        title={
                          <Typography variant={'smallBody'}>
                            You can assign a Form to a specific Right and keep other Rights using a default form.
                            <br />
                            <br />
                            Click on a button here, choose a Right, and a Form template to create a mapping.
                            <br />
                            <br />
                            You can create multiple custom mappings for specific Rights that necessitate a corresponding
                            Form.
                          </Typography>
                        }
                      >
                        <Box sx={{ display: 'flex' }}>
                          <Icon
                            name="FUnknown"
                            iconColor={theme.palette.darkDuskFaded.main}
                            width={16}
                            height={16}
                            sx={{ ml: '6px' }}
                          />
                        </Box>
                      </Tooltip>
                    </ContentFooterBox>
                  </Box>
                )}
              />
            )}
          </RightMappingDraggableBox>
        )
      }}
    </Draggable>
  )
}
