import { AccordionDetails, Box, Typography } from '@mui/material'
import { ContextVariableCategoryDTO, FormFieldCategoryDTO, GatewayTransitionDTO } from '@ketch-com/figurehead'
import React, { useContext, useState } from 'react'
import { ContextVariableChip } from '../../..'
import { OPERATOR_OPTIONS } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/gateway/types'
import { DecisionGatewayContext } from '../context/DecisionGatewayContext'
import { useAuth } from 'utils/hooks'
import { getDoesTransitionHaveIssues } from '../utils'
import { useFormField } from 'api/formFields/queries/useFormField'
import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'
import { Accordion, AccordionSummary, Checkbox, Chip, Icon, Radio, theme } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

export type PathTileVariantOptions = 'check' | 'radio' | 'none' | 'selected'

type PathTileProps = {
  transition: GatewayTransitionDTO
  variant: PathTileVariantOptions
  isChecked: boolean
  onCheckClick: () => void
  isManualStep: boolean
}

export const PathTile: React.FC<PathTileProps> = ({ transition, variant, isChecked, onCheckClick, isManualStep }) => {
  const { gatewayContext } = useContext(DecisionGatewayContext)

  const { data: formFieldDetails } = useFormField({
    params: {
      formFieldId: transition.formFieldId || '',
      includeMetadata: true,
    },
  })

  const isDataSubjectVariable =
    transition?.variableCategory === ContextVariableCategoryDTO.DataSubjectContextVariableCategory

  const isFormFieldVariable =
    transition.variableCategory === ContextVariableCategoryDTO.FormFieldContextVariableCategory

  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const hasIssues = getDoesTransitionHaveIssues(transition)
  const receivedValue = isDataSubjectVariable
    ? String(gatewayContext?.dataSubjectContext?.find(d => d.code === transition.variable)?.value) || 'No Value'
    : String(
        gatewayContext?.outcomeContext?.find(
          d => d.code === transition.variable && d.outcomeVariableStepId === transition.outcomeVariableStepId,
        )?.value,
      ) || 'No Value'
  const receivedOperator = `${OPERATOR_OPTIONS.find(option => option.id === transition.operator)?.name} ${String(
    transition.operand,
  )}`

  const formFieldRecievedValue = () => {
    if (
      transition.formFieldCategory === FormFieldCategoryDTO.DefaultFormFieldCategory ||
      transition.formFieldCategory === FormFieldCategoryDTO.ReservedFormFieldCategory
    ) {
      switch (transition?.formFieldCode) {
        case 'addressLine1':
          return String(gatewayContext?.subjectDetails?.addressLine1)
        case 'addressLine2':
          return String(gatewayContext?.subjectDetails?.addressLine2)
        case 'city':
          return String(gatewayContext?.subjectDetails?.city)
        case 'country':
          return String(gatewayContext?.subjectDetails?.country)
        case 'dataSubjectTypeCode':
          return String(gatewayContext?.subjectDetails?.dataSubjectTypeName)
        case 'description':
          return String(gatewayContext?.subjectDetails?.description)
        case 'email':
          return String(gatewayContext?.subjectDetails?.email)
        case 'firstName':
          return String(gatewayContext?.subjectDetails?.firstName)
        case 'lastName':
          return String(gatewayContext?.subjectDetails?.lastName)
        case 'phone':
          return String(gatewayContext?.subjectDetails?.phone)
        case 'postalCode':
          return String(gatewayContext?.subjectDetails?.postalCode)
        case 'stateRegionCode':
          return String(gatewayContext?.subjectDetails?.stateRegionCode)
        default:
          return 'undefined'
      }
    } else if (transition.formFieldCategory === FormFieldCategoryDTO.CustomFormFieldCategory) {
      const formFieldData = gatewayContext?.subjectDetails?.formData?.find(f => f.code === transition.formFieldCode)

      if (formFieldData) {
        if (formFieldDetails?.type === 'dropdown') {
          const dropdown = formFieldDetails?.options?.find(option => formFieldData?.value === option.value)

          return String(dropdown?.label)
        }

        if (formFieldDetails?.type === 'checkbox') {
          const checkboxes = formFieldDetails?.options?.filter(
            option => formFieldData?.values?.includes(option?.value || ''),
          )

          return (
            <Box component="ul" margin={0} sx={{ 'padding-inline-start': '20px' }}>
              {checkboxes?.map(checkbox => <li>{checkbox?.label}</li>)}
            </Box>
          )
        } else {
          return String(formFieldData.value)
        }
      } else {
        return 'undefined'
      }
    } else {
      return 'undefined'
    }
  }

  const FormFieldConditionValue = () => {
    const operator = OPERATOR_OPTIONS.find(option => option.id === transition?.operator)

    if (formFieldDetails?.type === 'checkbox') {
      const expectedOptions = formFieldDetails?.options?.filter(option =>
        JSON.parse(transition?.operand || '').includes(option.value),
      )

      return (
        <Box>
          {operator?.name}
          <Box component="ul" margin={0} sx={{ 'padding-inline-start': '20px' }}>
            {expectedOptions?.map(expectedOption => <li>{expectedOption.label}</li>)}
          </Box>
        </Box>
      )
    } else if (formFieldDetails?.type === 'dropdown') {
      const expectedOption = formFieldDetails?.options?.find(option =>
        JSON.parse(transition?.operand || '').includes(option.value),
      )

      return (
        <Box>
          {operator?.name} {expectedOption?.label}
        </Box>
      )
    } else {
      return (
        <Box>
          {operator?.name} {transition?.operand}
        </Box>
      )
    }
  }

  const { userData } = useAuth()

  const navigate = useNavigate()

  const userDataUserName = [userData?.firstName, userData?.lastName].filter(Boolean).join(' ')

  const isSelectedByEqualToCurrentUser = transition?.selectedBy === 'currentUser'
  const selectedByInfoUserName = [transition?.selectedByInfo?.firstName, transition?.selectedByInfo?.lastName]
    .filter(Boolean)
    .join(' ')

  return (
    <Accordion
      color={theme.palette.superLightGrey.main}
      expanded={isExpanded}
      onChange={() => (isManualStep ? null : setIsExpanded(b => !b))}
      sx={{
        border: ({ palette }) => (variant === 'selected' ? `1px solid ${palette.greenHaze.main}` : undefined),
      }}
    >
      <AccordionSummary icon={isManualStep ? 'OArrowCRight' : undefined}>
        <Box display="flex" alignItems="center" gap={1}>
          {hasIssues && <Icon name="OImportant" iconColor={theme.palette.chileanFire.main} />}
          <Box display="inline-flex" flexDirection="column">
            <Typography variant="label">{transition?.name || 'Path'}</Typography>
            <Typography color="darkDuskFaded.main">{transition?.description || 'Description'}</Typography>
          </Box>
        </Box>

        {variant === 'radio' && (
          <Box display="flex" alignItems="center" gap={0.25}>
            <Radio checked={isChecked} onChange={onCheckClick} onClick={event => event.stopPropagation()} />
            <Typography variant={isChecked ? 'label' : 'body'}>Follow This Path</Typography>
          </Box>
        )}

        {variant === 'check' && (
          <Box display="flex" alignItems="center" gap={0.25}>
            <Checkbox checked={isChecked} onChange={onCheckClick} onClick={event => event.stopPropagation()} />
            <Typography variant={isChecked ? 'label' : 'body'}>Follow This Path</Typography>
          </Box>
        )}
        {variant === 'selected' && (
          <Box display="flex" gap={1.5} alignItems="center">
            <Icon name="FCheckRound" iconColor={theme.palette.greenHaze.main} height={34} width={34} />
            <Box display="flex" flexDirection="column">
              <Typography variant="label" color="greenHaze.main">
                Selected
              </Typography>
              <Typography variant="smallBody">
                {isManualStep || transition?.selectedBy ? '' : 'Automatically'}
                <Typography variant="smallLabel">
                  {transition?.selectedBy
                    ? ` by ${isSelectedByEqualToCurrentUser ? userDataUserName : selectedByInfoUserName}`
                    : ''}
                </Typography>
              </Typography>
            </Box>
          </Box>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Box mt={1} ml={1.5} mb={1.5}>
          <Box display="flex" alignItems="center">
            <Typography variant="body">
              {isFormFieldVariable
                ? 'Form Field Variable'
                : isDataSubjectVariable
                ? 'Data Subject Variable'
                : 'Outcome Variable'}
            </Typography>
            :&nbsp;
            {transition.variable ? (
              isFormFieldVariable ? (
                transition.formFieldCategory === FormFieldCategoryDTO.CustomFormFieldCategory ? (
                  <Chip
                    clickable
                    label={formFieldDetails?.name}
                    onClick={() =>
                      navigate(
                        RoutesManager.deployment.forms.fields.view.overview.root.getURL({
                          id: transition.formFieldId,
                        }),
                      )
                    }
                    deleteIcon={<Icon name="OArrowCRight" />}
                    onDelete={() =>
                      navigate(
                        RoutesManager.deployment.forms.fields.view.overview.root.getURL({
                          id: transition.formFieldId,
                        }),
                      )
                    }
                  />
                ) : (
                  <Chip label={formFieldDetails?.name} />
                )
              ) : (
                <ContextVariableChip code={transition.variable} />
              )
            ) : (
              <EmptyValueRenderer />
            )}
          </Box>
          <Box mt={2}>
            <Typography variant="body">Value received:</Typography>&nbsp;
            <Typography variant="label" color={hasIssues ? 'chileanFire.main' : undefined}>
              {transition?.variableCategory === ContextVariableCategoryDTO.FormFieldContextVariableCategory
                ? formFieldRecievedValue()
                : receivedValue}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body">Condition and expected value:</Typography>
            &nbsp;
            <Typography variant="label">
              {transition?.variableCategory === ContextVariableCategoryDTO.FormFieldContextVariableCategory ? (
                <FormFieldConditionValue />
              ) : (
                receivedOperator
              )}
            </Typography>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
