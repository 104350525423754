import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { useBusinessFunction } from 'api/businessFunctions/queries/useBusinessFunction'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { BusinessFunctionDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { BusinessFunctionOverviewActions } from './components/BusinessFunctionOverviewActions'
import { showToast } from 'components/modals/AlertComponent'
import { Avatar, AvatarSize, Chip, ContentGroup, DataGrid, InfoRow, ViewLayout } from '@ketch-com/deck'
import { getTextColorForTile } from '../utils/colorMappings'
import { businessFunctionsOverviewColumns } from './components/businessFunctionOverviewDataGridUtils'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

export const BusinessFunctionOverview = () => {
  const { code } = useParams<{ code: string }>()
  const { data, isLoading } = useBusinessFunction({
    params: { code },
    onError: () => {
      showToast({ content: 'Failed to fetch business functions details', type: 'error' })
    },
  })
  const navigate = useNavigate()

  if (!data || isLoading) return <Spinner padding={50} />

  const businessFunction = data as BusinessFunctionDTO

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Business Functions', link: RoutesManager.policyCenter.businessFunctions.root.getURL() },
    { title: code || 'Loading...', link: RoutesManager.policyCenter.businessFunctions.view.root.getURL({ code }) },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs} />
      <ViewLayout
        contentGroupProps={{
          title: businessFunction?.name,
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.policyCenter.businessFunctions.root.getURL()),
          },
          imageBlockComponent: (
            <Avatar
              size={AvatarSize.xlarge}
              textColor={getTextColorForTile(businessFunction?.labelColor) || '#333'}
              backgroundColor={businessFunction?.labelColor}
            >
              {businessFunction?.label?.toUpperCase()}
            </Avatar>
          ),
          infoBlockComponent: <Chip label={`CODE: ${code}`} size="small" />,
          actionRightBlockComponent: <BusinessFunctionOverviewActions businessFunction={businessFunction} />,
        }}
      >
        <>
          <Box mb={4}>
            <ContentGroup title="Basic Details" variant="inner-page-header" titleVariant="h3" />
          </Box>

          <InfoRow title="Description" mb={4}>
            {businessFunction.description ? (
              <Typography>{businessFunction.description}</Typography>
            ) : (
              <Typography color={'darkDuskFaded.main'} variant="body">
                None
              </Typography>
            )}
          </InfoRow>
          <InfoRow title="Contact" mb={6}>
            {
              <>
                {(businessFunction.contactFirstName || businessFunction.contactLastName) && (
                  <Typography variant="label">
                    {`${businessFunction.contactFirstName || '(No first name provided)'} ${
                      businessFunction.contactLastName || '(No last name provided)'
                    }`}
                  </Typography>
                )}
                {businessFunction.contactTitle || businessFunction.contactEmail ? (
                  <>
                    {businessFunction.contactTitle && (
                      <Box>
                        <Typography variant="body">{businessFunction.contactTitle}</Typography>
                      </Box>
                    )}
                    {businessFunction.contactEmail && (
                      <Box>
                        <Typography variant="body"> {businessFunction.contactEmail}</Typography>
                      </Box>
                    )}
                  </>
                ) : (
                  <Typography color={'darkDuskFaded.main'} variant="body">
                    None
                  </Typography>
                )}
              </>
            }
          </InfoRow>
          {businessFunction.purposes && businessFunction.purposes.length && (
            <>
              <Box mb={4}>
                <ContentGroup title="Purposes" variant="inner-page-header" titleVariant="h3" />
              </Box>
              <DataGrid
                sx={{
                  height: 'auto',
                  paddingLeft: 'unset',
                  paddingRight: 'unset',
                  paddingTop: 'unset',
                }}
                autosizeOnMount
                columns={businessFunctionsOverviewColumns}
                disableChildrenSorting
                disableColumnMenu
                disableColumnPinning
                disableColumnReorder
                disableColumnResize
                getRowId={row => row.id}
                getRowHeight={() => 'auto'}
                hideFooter
                loading={isLoading}
                rows={businessFunction.purposes}
              />
            </>
          )}
        </>
      </ViewLayout>
    </>
  )
}
