import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import { DeleteOutline, EditOutlined, VisibilityOutlined } from '@mui/icons-material'

import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { RoutesManager } from 'utils/routing/routesManager'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'
import { FormMode } from 'interfaces/formModes/formMode'
import { DeleteThemeModalV3 } from 'components/modals/themes/DeleteThemeModalV3'
import { ThemeStatus } from 'interfaces/themes-v3/themeStatus'

type Props = {
  theme: ThemeV3DTO
  refetchThemes: () => void
}

export const ThemesListDropdown: React.FC<Props> = ({ theme, refetchThemes }) => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()

  const [{ isEditConfirmationOpen, isDeleteOpen }, setState] = useSetState({
    isEditConfirmationOpen: false,
    isDeleteOpen: false,
  })

  const { id } = theme
  const isPermittedToManageThemes = isPermitted(PERMISSIONS.THEME_WRITE)
  const isDeployed = theme.status === ThemeStatus.Deployed

  const handleRedirectToUpsertTheme = () => {
    navigate(RoutesManager.deployment.themesV3.upsert.root.getURL({ id: theme?.id, formMode: FormMode.EDIT }))
  }

  return (
    <>
      <ButtonOptions
        items={[
          {
            content: 'Edit',
            icon: <EditOutlined />,
            hidden: !isPermittedToManageThemes,
            onClick: () => {
              if (isDeployed) {
                setState({ isEditConfirmationOpen: true })
              } else {
                handleRedirectToUpsertTheme()
              }
            },
          },
          {
            content: 'Preview',
            icon: <VisibilityOutlined />,
            hidden: !isPermittedToManageThemes,
            onClick: () => navigate(RoutesManager.deployment.themesV3.view.preview.root.getURL({ id })),
          },
          {
            content: 'Delete',
            icon: <DeleteOutline />,
            hidden: !isPermittedToManageThemes || isDeployed,
            onClick: () => setState({ isDeleteOpen: true }),
          },
        ]}
      />

      {isDeleteOpen && (
        <DeleteThemeModalV3
          themeId={theme?.id || ''}
          onSubmit={async () => {
            refetchThemes()
            setState({ isDeleteOpen: false })
          }}
          onCancel={() => setState({ isDeleteOpen: false })}
        />
      )}

      {isEditConfirmationOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.THEME}
          onSubmit={handleRedirectToUpsertTheme}
          onCancel={() => {
            setState({ isEditConfirmationOpen: false })
          }}
        />
      )}
    </>
  )
}
