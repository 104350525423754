import { DocumentOptionType } from 'pages/dataSystems/DataSystem/types'
import { Chip } from '@ketch-com/deck'
import { formatLastUpdatedDate } from 'utils/helpers/dateTime'

export type PartialPolicyDocumentDTO = Partial<DocumentOptionType> & Required<Pick<DocumentOptionType, 'code'>>

type Props = {
  document: PartialPolicyDocumentDTO
}

export default function DocumentChip({ document }: Props) {
  const version = document.version ? formatLastUpdatedDate(document.version) : undefined
  const desc = document.description || 'No Description'
  const caption = version && desc ? `${desc} | ${version}` : version ? version : desc
  return <Chip label={document.name || document.code} secondaryLabel={caption} />
}
