import { ProcessingActivityContext } from '../context/ProcessingActivityContext'
import React, { useContext } from 'react'
import Typography from '@mui/material/Typography'
import { ProcessingActivityStatusDTO } from '@ketch-com/figurehead'
import { Button, PopUp } from '@ketch-com/deck'

/**
 * Wrapper component that intercepts clicks on child components and will display
 * a warning prompt if the current processing activity is in the approved state.
 */
export const EditApprovedActivityDialog: React.FC = () => {
  const { processingActivity, handleStatusUpdate, setShowEditApprovedDialog } = useContext(ProcessingActivityContext)

  const handleSubmitClick = async () => {
    await handleStatusUpdate({
      params: {
        activityId: processingActivity.id || '',
        formData: {
          status: ProcessingActivityStatusDTO.PendingProcessingActivityStatus,
        },
      },
    })
    setShowEditApprovedDialog(false)
  }

  return (
    <PopUp
      title="Edit Processing Activity"
      variant="dialog"
      onClose={() => setShowEditApprovedDialog(false)}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={() => setShowEditApprovedDialog(false)}>
            Cancel
          </Button>
          <Button color="primary" size="large" onClick={handleSubmitClick}>
            Confirm
          </Button>
        </>
      }
    >
      <Typography display="block" variant="body" mb={2}>
        This processing activity is currently approved and any edits made will reset it to the "Requested" state.
      </Typography>
      <Typography variant="body">Please confirm this action.</Typography>
    </PopUp>
  )
}
