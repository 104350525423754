export enum FormFieldFormStep {
  DETAILS = 'Basic Details',
  FIELD_TYPE = 'Field Type',
}

/**
 * Returns an array of form field steps.
 *
 * @returns {Array} An array containing the form field steps.
 */
export const getFormFieldFromSteps = () => [FormFieldFormStep.DETAILS, FormFieldFormStep.FIELD_TYPE]

export const createFormFieldSteps = getFormFieldFromSteps()
