import React from 'react'
import Grid from '@mui/material/Grid'

import { FormInput } from 'components/form/FormInput'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { Typography } from '@mui/material'
import { FormRow } from '@ketch-com/deck'

type Props = {
  isEditMode: boolean
}

export const UserFormSectionDetails: React.FC<Props> = ({ isEditMode }) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isUpdateProfileDisabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.UPDATE_USER_PROFILE_DISABLED,
    FEATURE_FLAGS_VALUES.UPDATE_USER_PROFILE_DISABLED_TRUE,
  )

  return (
    <FormRow title="Details">
      <Grid container gap={1.5}>
        {isEditMode && (
          <>
            <Grid item xs={6}>
              <FormInput
                required
                fullWidth
                label="First Name"
                formPropertyName="firstName"
                placeholder="Example: James"
                disabled={isUpdateProfileDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                required
                fullWidth
                label="Last Name"
                formPropertyName="lastName"
                placeholder="Example: Smith"
                disabled={isUpdateProfileDisabled}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <FormInput
            required
            fullWidth
            label="Email Address"
            formPropertyName="email"
            placeholder="Example: james.smith@gmail.com"
            disabled={isEditMode}
          />
        </Grid>
      </Grid>

      {isEditMode && isUpdateProfileDisabled ? (
        <Typography variant="footnote">
          Profile information is synced from the login provider and cannot be edited here.
        </Typography>
      ) : null}
    </FormRow>
  )
}
