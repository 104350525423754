import { Box, Typography } from '@mui/material'

import { ObjectLiteral } from 'interfaces'
import { ChartTrendPeriod } from 'interfaces/rights/rightStats'
import { ReshapedDataForChart } from '../utils/getReshapedDataForChart'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS, TranslatorFunction } from 'i18n/types'
import { formatCount } from '../utils'

type TooltipProps = {
  active?: boolean
  payload: any
  label: string
  trendPeriod?: ChartTrendPeriod
  startEndDifferenceInDays?: number
}

const getOrdinal = (day: number) => {
  if (day > 3 && day < 21) return 'th'
  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

const getLabel = (arg: ReshapedDataForChart, translate: TranslatorFunction, trendPeriod?: ChartTrendPeriod) => {
  const month = translate(arg?.monthName || '', { ns: 'common' })
  const dayOfWeek = translate(arg?.fullDayName || '', { ns: 'common' })
  if (trendPeriod === ChartTrendPeriod.DAILY)
    return `${dayOfWeek}, ${month} ${arg?.dateOfMonth}${getOrdinal(parseInt(arg?.dateOfMonth || ''))}`
  if (trendPeriod === ChartTrendPeriod.MONTHLY_PAST_TWELVE) return `${month} ${arg?.year}`

  return `${dayOfWeek}, ${month} ${arg?.dateOfMonth}, ${arg?.year}`
}

export const ConsentTrendChartTooltip = ({ active, payload, trendPeriod }: TooltipProps) => {
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])
  if (active && payload && payload.length) {
    const noConsentValue = payload.find((item: ObjectLiteral) => item.dataKey === 'noConsent')?.value || 0
    const noConsentRequiredValue = Math.abs(
      payload.find((item: ObjectLiteral) => item.dataKey === 'noConsentRequired')?.value || 0,
    )
    const consentValue = Math.abs(payload.find((item: ObjectLiteral) => item.dataKey === 'consent')?.value || 0)

    const total = (consentValue || 0) + (noConsentValue || 0) + (noConsentRequiredValue || 0)
    const consentCount = consentValue || 0
    const noConsentRequiredCount = noConsentRequiredValue || 0
    const allowanceRate = (((consentCount + noConsentRequiredCount) / (total || 1)) * 100).toFixed(1)

    return (
      <Box
        sx={{
          background: ({ palette }) => palette.darkDusk.main,
          borderRadius: 1,
          py: 1,
          px: 1.25,
          minWidth: 175,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="body" color="white.main" sx={{ mb: 0.5, fontWeight: 500, lineHeight: '16px' }}>
          {getLabel(payload?.[0]?.payload as ReshapedDataForChart, t, trendPeriod)}
        </Typography>
        <Typography variant="body" color="white.main" sx={{ mb: 0.5, fontWeight: 500, lineHeight: '16px' }}>
          {t('Allowance Rate')}: {allowanceRate}%
        </Typography>
        <Typography variant="body" color="white.main" sx={{ mb: 2, fontWeight: 500, lineHeight: '16px' }}>
          {t('Total')}: {formatCount(total)}
        </Typography>
        <Typography variant="body" color="white.main" sx={{ mb: 0.5, fontWeight: 500, lineHeight: '16px' }}>
          {t('No Consent')}: {formatCount(noConsentValue)}
        </Typography>
        <Typography variant="body" color="white.main" sx={{ mb: 0.5, fontWeight: 500, lineHeight: '16px' }}>
          {t('Consent Not Required')}: {formatCount(noConsentRequiredValue)}
        </Typography>
        <Typography variant="body" color="white.main" sx={{ mb: 0.5, fontWeight: 500, lineHeight: '16px' }}>
          {t('Consent')}: {formatCount(consentValue)}
        </Typography>
      </Box>
    )
  }

  return null
}
