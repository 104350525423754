import { theme } from '@ketch-com/deck'

export function generateColorSchemeArray() {
  const colorNames = ['Pink', 'Blue', 'Violet', 'Green', 'Yellow', 'Red']
  const letters = ['A', 'B', 'C', 'D', 'E', 'F']
  const colorList = []
  const colorOptions: Record<string, Record<string, string>> = theme.palette.Infographics

  for (const letter of letters) {
    for (const colorName of colorNames) {
      const key = `${colorName}${letter}`
      if (colorOptions[key]) {
        colorList.push({
          color: colorOptions[key].Primary,
          backgroundColor: colorOptions[key].Secondary,
        })
      }
    }
  }

  return colorList
}
