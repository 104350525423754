import { TrendPermitStatsDTO } from '@ketch-com/figurehead'

export const getIsAllTrendDataEmpty = (trends: TrendPermitStatsDTO[]): boolean => {
  let maxKeysLength = 1
  for (let i = 0; i < trends.length; i++) {
    const el = trends[i]
    const elementKeyCount = Object.keys(el).length
    if (elementKeyCount > maxKeysLength) {
      maxKeysLength = elementKeyCount
    }
  }
  return maxKeysLength < 2
}
