import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces/common'
import { UserDTO } from 'interfaces/users/users'
import { UserRoleDTO } from 'interfaces/users/userRole'
import { RoutesManager } from 'utils/routing/routesManager'
import { joinNonEmpty } from 'utils/helpers/joinNonEmpty'
import { ContentGroup, UpsertLayoutMode, UpsertLayout, Spinner } from '@ketch-com/deck'
import { UserFormValues, getUserFormValues, getUserFormValidation } from 'pages/settings/users/upsert/utils'
import { UserFormSectionDetails } from './components/UserFormSectionDetails'
import { UserFormSectionRoles } from 'pages/settings/users/upsert/components/UserFormSectionRoles'
import { OrganizationDTO } from 'interfaces/organizations/organization'
import { NavigationBreadCrumbs } from '../../../../components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { useFormik, FormikProvider } from 'formik'
import { Box } from '@mui/material'

type Props = {
  isReady: boolean
  isEditMode: boolean
  user: MaybeNull<UserDTO>
  roles: UserRoleDTO[]
  organization: MaybeNull<OrganizationDTO>
  onSubmit: (values: UserFormValues) => Promise<void>
}

export const UsersUpsertView: React.FC<Props> = ({ isReady, isEditMode, user, roles, onSubmit }) => {
  const navigate = useNavigate()

  const initialValues = useMemo(() => getUserFormValues({ user }), [user])
  const validationSchema = useMemo(() => getUserFormValidation({ isEditMode }), [isEditMode])

  const breadcrumbs = isEditMode
    ? [
        { title: 'Settings', link: RoutesManager.settings.root.getURL() },
        { title: 'Users', link: RoutesManager.settings.users.root.getURL() },
        {
          title: joinNonEmpty([user?.firstName, user?.lastName]),
          link: RoutesManager.settings.users.view.root.getURL({ id: user?.ID }),
        },
        { title: 'Edit' },
      ]
    : [
        { title: 'Settings', link: RoutesManager.settings.root.getURL() },
        { title: 'Users', link: RoutesManager.settings.users.root.getURL() },
        { title: 'Invite User' },
      ]

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })

  const { isSubmitting, submitForm } = formikProps

  return (
    <FormikProvider value={formikProps}>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <UpsertLayout
        mode={UpsertLayoutMode.edit}
        renderFormTitle={() => (
          <ContentGroup title={isEditMode ? 'Edit User' : 'Invite User'} titleVariant="h2" isDivider />
        )}
        onAccept={submitForm}
        onCancel={() => {
          if (isEditMode) {
            navigate(RoutesManager.settings.users.view.root.getURL({ id: user?.ID }))
          } else {
            navigate(RoutesManager.settings.users.root.getURL())
          }
        }}
        acceptButtonProps={{
          pending: isSubmitting,
        }}
        acceptActionButtonText={isEditMode ? 'Save' : 'Invite'}
      >
        {isReady ? (
          <>
            <UserFormSectionDetails isEditMode={isEditMode} />

            <UserFormSectionRoles roles={roles} />
          </>
        ) : (
          <Box
            sx={{
              margin: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner size="32px" thickness={2.5} />
          </Box>
        )}
      </UpsertLayout>
    </FormikProvider>
  )
}
