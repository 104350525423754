import { Box, FormHelperText } from '@mui/material'
import { useFormikContext } from 'formik'
import { TextInput } from '@ketch-com/deck'
import { Values } from '../utils'

export const InputStepExample = ({ formFieldValue }: { formFieldValue: keyof Values }) => {
  const formikContext = useFormikContext<Values>()
  const { errors, values, setFieldValue } = formikContext
  return (
    <>
      <Box display="flex" flexDirection="column">
        <TextInput
          label="Some label"
          placeholder="Some placeholder"
          value={values[formFieldValue]}
          error={!!errors[formFieldValue]}
          onChange={e => {
            setFieldValue(formFieldValue, e.target.value)
          }}
        />
        {!!errors[formFieldValue] && (
          <FormHelperText sx={{ color: 'chileanFire.main' }}>{errors[formFieldValue]}</FormHelperText>
        )}
      </Box>
    </>
  )
}
