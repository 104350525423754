import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { compact } from 'lodash'
import { isEmailActivityParamsTypeGuard } from 'pages/orchestration/workflows/edit/interfaces/typeGuards'

interface IParams {
  step: ICanvasStep
}

interface IGetDataSubjectEmailInitialValues {
  description: string
  code: string
  to: Array<string>
  cc: Array<string>
  subject: string
  body: string
  hidePrivacyPortalLink: boolean
}

export const getDataSubjectEmailInitialValues = (params: IParams): IGetDataSubjectEmailInitialValues => {
  const { step } = params

  const emailSubject = 'Data request update'
  const emailBody = `Dear {{first_name}} {{last_name}},

Your request has been updated! To see more details, please obtain an access code using the button below, and login to the portal.
  
Request ID: {{request_id}}`

  if (isEmailActivityParamsTypeGuard(step.activity?.params)) {
    const description = step.description || ''
    const code = step.code || ''
    const cc = step.activity?.params?.cc || []
    const subject = step.activity?.params?.subject || emailSubject
    const body = step.activity?.params?.body || emailBody
    const hidePrivacyPortalLink = step.activity?.params?.hidePrivacyPortalLink || false

    return {
      description,
      code,
      to: ['(Data Subject)'],
      cc: compact(cc),
      subject,
      body,
      hidePrivacyPortalLink,
    }
  }

  return {
    description: '',
    code: '',
    to: ['(Data Subject)'],
    cc: [],
    subject: emailSubject,
    body: emailBody,
    hidePrivacyPortalLink: false,
  }
}
