import * as Yup from 'yup'

import { MaybeNull } from 'interfaces/common'
import { OrganizationDTO, OrganizationFormDTO } from 'interfaces/organizations/organization'

export const getOrganizationFormValues = ({
  organization,
}: {
  organization: MaybeNull<OrganizationDTO>
}): OrganizationFormDTO => ({
  code: organization?.code || '',
  name: organization?.name || '',
  primaryBrandColor: organization?.primaryBrandColor || '',
  secondaryBrandColor: organization?.secondaryBrandColor || '',
  logoURL: organization?.logoURL || '',
})

export const getOrganizationFormValidation = () =>
  Yup.object().shape({
    name: Yup.string().max(75, 'Name must not exceed 75 characters').required('Required'),
    organizationLogoImage: Yup.object().nullable(),
    primaryBrandColor: Yup.string().matches(/^#/, 'Only hex values allowed').required('Required'),
    secondaryBrandColor: Yup.string().matches(/^#/, 'Only hex values allowed').required('Required'),
  })
