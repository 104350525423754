import { FC, useState, useMemo } from 'react'
import { FormikProvider, Form, useFormik } from 'formik'
import { useUpdateApplicationEnvironment } from 'api/applications/mutations/useUpdateApplicationEnvironment'
import { useDeploymentPlans } from 'api/deploymentPlans/queries/useDeploymentPlans'
import { ApplicationDTO } from 'interfaces/applications/application'
import {
  ApplicationDeployFormValues,
  getInitialValues,
  validationSchema,
  getSelectionMetadata,
  isDeploymentOverwritten,
  ApplicationDeploymentEnvironment,
} from 'pages/consentAndRights/applications/modals/manageDeployment/utils'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Box, FormControlLabel, Typography } from '@mui/material'
import {
  ActionSheetItem,
  Button,
  Checkbox,
  DataGrid,
  DropListButton,
  ListItemText,
  PopUp,
  Spinner,
  TableCell,
} from '@ketch-com/deck'
import { showToast } from 'components/modals/AlertComponent'

type Props = {
  application: ApplicationDTO
  onClose: () => void
  onSuccess?: () => any
}

export const ApplicationManageDeploymentModal: FC<Props> = ({ application, onClose, onSuccess }) => {
  const [isOverwriteModalOpen, setIsOverwriteModalOpen] = useState(false)

  const { data: deploymentPlans, isFetching } = useDeploymentPlans()
  const { mutateAsync: handleUpdateApplicationEnvironment } = useUpdateApplicationEnvironment()

  const formik = useFormik<ApplicationDeployFormValues>({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: getInitialValues(application),
    validationSchema,
    onSubmit: async ({ environments }: ApplicationDeployFormValues) => {
      try {
        const requests = environments.map(({ selected, code, deploymentID }) =>
          handleUpdateApplicationEnvironment({
            params: {
              applicationCode: application.code,
              environmentCode: code,
              formData: {
                ...(selected && {
                  deploymentID,
                }),
              },
            },
          }),
        )

        await Promise.all(requests)
        await onSuccess?.()
        showToast({ content: 'Property deployment has been updated', type: 'success' })
      } catch (e) {
        showToast({ content: 'Failed to update property deployment', type: 'error' })
      }
    },
  })

  const { isSubmitting, isValid, values, submitForm, setFieldValue } = formik
  const { environments } = values
  const { selectedCount, totalCount, value } = getSelectionMetadata(values)

  const columns = useMemo((): GridColDef<ApplicationDeploymentEnvironment>[] => {
    return [
      {
        align: 'left',
        field: 'code',
        flex: 1,
        maxWidth: 215,
        headerAlign: 'left',
        headerName: 'Name',
        sortable: false,
        renderCell: ({ row }) => (
          <TableCell>
            <FormControlLabel
              label={row.code}
              control={
                <Checkbox
                  checked={row.selected}
                  onChange={() => {
                    setFieldValue(
                      'environments',
                      environments.map(({ selected, ...rest }) => ({
                        ...rest,
                        selected: row.id === rest.id ? !selected : selected,
                      })),
                    )
                  }}
                />
              }
            />
          </TableCell>
        ),
      },
      {
        align: 'left',
        field: 'pattern',
        flex: 1,
        maxWidth: 215,
        headerAlign: 'left',
        headerName: 'Pattern',
        sortable: false,
        renderCell: ({ row }) => <TableCell title={row?.pattern ?? 'None'} />,
      },
      {
        align: 'left',
        field: 'deployment',
        flex: 1,
        maxWidth: 320,
        headerAlign: 'left',
        headerName: 'Deployment Plan',
        sortable: false,
        renderCell: ({ row }) => (
          <TableCell sx={{ width: '100%', '& > div': { width: '100%' } }}>
            <DropListButton
              fullWidth
              disableClearable
              placeholder="Select"
              options={deploymentPlans}
              defaultValue={deploymentPlans.find(deploymentPlan => deploymentPlan.ID === row.defaultDeploymentID)}
              size="small"
              error={row.selected && !row.deploymentID ? !isValid : false}
              disabled={!deploymentPlans.length || !row.selected}
              getOptionLabel={option => option.name}
              renderOption={(props, option, { selected }) => (
                <ActionSheetItem selected={selected} {...props}>
                  <ListItemText selected={selected}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body">{option.name}</Typography>
                      <Typography variant="footnote">{option.code}</Typography>
                    </Box>
                  </ListItemText>
                </ActionSheetItem>
              )}
              onChange={(_, newValue) => {
                setFieldValue(
                  'environments',
                  environments.map(environment => {
                    if (row.id === environment.id) {
                      return {
                        ...environment,
                        deploymentID: newValue !== null ? newValue.ID : '',
                      }
                    }
                    return environment
                  }),
                )
              }}
            />
          </TableCell>
        ),
      },
    ]
  }, [deploymentPlans, environments, isValid, setFieldValue])

  return (
    <PopUp
      isOpen
      title="Manage Deployment"
      subTitle="Select or update the deployment plan association with an environment to ensure accurate configurations."
      variant="modal"
      isLoading={isSubmitting}
      onClose={onClose}
      popUpWidth="830px"
      popUpActionChildren={
        <>
          <Button size="large" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="large"
            disabled={isFetching || !isValid}
            onClick={() => {
              if (isDeploymentOverwritten({ values, application })) {
                setIsOverwriteModalOpen(true)
              } else {
                submitForm()
              }
            }}
          >
            Save
          </Button>
        </>
      }
    >
      {isFetching ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : (
        <FormikProvider value={formik}>
          <Form>
            <Box>
              <FormControlLabel
                label={`${selectedCount}/${totalCount} Selected`}
                control={
                  <Checkbox
                    checked={value === true}
                    indeterminate={value === 'indeterminate'}
                    onChange={() => {
                      const nextValue = value === 'indeterminate' || !value
                      setFieldValue(
                        'environments',
                        environments.map(({ selected, ...rest }) => ({
                          ...rest,
                          selected: nextValue,
                        })),
                      )
                    }}
                  />
                }
                sx={{
                  margin: 0,
                }}
              />
              <DataGrid
                disableChildrenSorting
                disableColumnMenu
                disableColumnPinning
                disableColumnReorder
                disableColumnResize
                disableRowHoverStates
                disableRowSelectionOnClick
                hideFooter
                rows={environments}
                columns={columns}
                getRowId={row => row.id}
                getRowHeight={() => 'auto'}
                sx={{
                  '& .MuiDataGrid-virtualScroller': {
                    overflow: 'hidden',
                  },
                }}
              />
              {isOverwriteModalOpen && (
                <PopUp
                  isOpen
                  onClose={() => setIsOverwriteModalOpen(false)}
                  variant="dialog"
                  title="Property Deployment Update"
                  popUpWidth="560px"
                  popUpActionChildren={
                    <>
                      <Button
                        color="secondary"
                        size="large"
                        onClick={e => {
                          e.stopPropagation()
                          setIsOverwriteModalOpen(false)
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        size="large"
                        onClick={e => {
                          e.stopPropagation()
                          setIsOverwriteModalOpen(false)
                          submitForm()
                        }}
                      >
                        Confirm
                      </Button>
                    </>
                  }
                >
                  <Typography>
                    The updates you made will override the deployment association for selected environments. Please
                    confirm if you want to proceed.
                  </Typography>
                </PopUp>
              )}
            </Box>
          </Form>
        </FormikProvider>
      )}
    </PopUp>
  )
}
