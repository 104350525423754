/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

// mui components
import Box from '@mui/material/Box'

// ui-kit
import { PageNavigator } from './PageNavigator'
import { StyledZoomLevel } from './StyledZoomLevel'

// types
import { DataReviewFileDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces/common'
import { Typography } from '@mui/material'
import { Button, Icon, TooltipButton } from '@ketch-com/deck'

type Props = {
  activeFile?: MaybeNull<DataReviewFileDTO>
  annotationManager: any
  documentViewer: any
  isComplete: boolean
  isDocumentLoaded: boolean
  isSaving: boolean
  onAnnotationDoubleClicked?: () => void
  onDocumentLoaded: () => void
  onExclude: (isExcluded: boolean) => void
  onFileRedact: (annotation: any, action: string) => void
  scrollView: any
  setAnnotationManager: (annotationManager: any) => void
  setDocumentViewer: (documentViewer: any) => void
  viewer: any
}

// init webview core
const Core = (window as any).Core

export const DocumentViewer = ({
  activeFile,
  annotationManager,
  documentViewer,
  isComplete,
  isDocumentLoaded,
  isSaving,
  onDocumentLoaded,
  onExclude,
  onFileRedact,
  scrollView,
  setAnnotationManager,
  setDocumentViewer,
  viewer,
}: Props) => {
  // state
  const [isDraggable, setIsDraggable] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageCount, setPageCount] = useState<number>(0)
  const [zoomLevel, setZoomLevel] = useState<number>(1)

  useEffect(() => {
    // configure webview core
    Core.setWorkerPath('/webviewer')
    Core.enableFullPDF()

    // init document viewer
    const documentViewer = new Core.DocumentViewer()
    // set scroll view dom ref
    documentViewer?.setScrollViewElement(scrollView.current)
    // set viewer dom ref
    documentViewer?.setViewerElement(viewer.current)
    // enable annotations (redaction)
    documentViewer?.enableAnnotations()

    // set annotation styles
    documentViewer?.getTool(Core.Tools.ToolNames.REDACTION).setStyles(() => ({
      StrokeThickness: 1000,
      StrokeColor: new Core.Annotations.Color(0, 0, 0),
      FillColor: new Core.Annotations.Color(0, 0, 0),
    }))

    // set doc viewer react state
    setDocumentViewer(documentViewer)

    // init annotation manager
    const annotationManager = documentViewer?.getAnnotationManager()
    annotationManager?.enableRedaction()
    setAnnotationManager(annotationManager)

    // reset page counts
    setPageNumber(1)
  }, [])

  // documentViewer listeners
  useEffect(() => {
    if (!documentViewer) return
    // init document listener
    documentViewer?.addEventListener('documentLoaded', onDocumentLoaded)

    // init page listener
    documentViewer?.addEventListener('pageNumberUpdated', onPageNumberUpdated)

    // init zoom listener
    documentViewer?.addEventListener('zoomUpdated', onZoomUpdated)

    // cleanup listeners
    return () => {
      documentViewer?.removeEventListener('documentLoaded', onDocumentLoaded)
      documentViewer?.removeEventListener('pageNumberUpdated', onPageNumberUpdated)
      documentViewer?.removeEventListener('zoomUpdated', onZoomUpdated)
    }
  }, [documentViewer])

  // annotation manager listeners
  useEffect(() => {
    if (!annotationManager) return
    // init event listeners
    annotationManager?.addEventListener('annotationChanged', onFileRedact)

    // destroy event listener
    return () => {
      annotationManager?.removeEventListener('annotationChanged', onFileRedact)
    }
  }, [annotationManager])

  // drag listener
  useEffect(() => {
    if (!documentViewer) return
    !!isDraggable ? dragTool() : selectTool()
  }, [documentViewer, isDraggable])

  // page listener
  useEffect(() => {
    if (!documentViewer || !isDocumentLoaded) return

    if (documentViewer && isDocumentLoaded) {
      setPageCount(documentViewer?.getPageCount() || 1)

      // On document load set initial zoom to 1
      documentViewer?.zoomTo(1)
      setZoomLevel(1)
      setPageNumber(1)
    }
  }, [documentViewer, isDocumentLoaded])

  //
  // pdf tron utils
  //

  // zoom utils
  const onZoomUpdated = (zoom: number) => documentViewer && setZoomLevel(zoom)

  const zoomOut = () => {
    if (documentViewer?.getZoomLevel() >= 0.5) {
      documentViewer?.zoomTo(documentViewer?.getZoomLevel() - 0.25)
    }
  }

  const zoomIn = () => {
    documentViewer?.zoomTo(documentViewer?.getZoomLevel() + 0.25)
  }

  // page utils
  const onPageNumberUpdated = (pageNumber: number) => documentViewer && setPageNumber(pageNumber)

  const handlePageDown = () => {
    let newPage = pageNumber + 1
    pageNumber < pageCount && documentViewer?.setCurrentPage(newPage, false)
  }

  const handlePageUp = () => {
    let newPage = pageNumber - 1
    pageNumber > 1 && documentViewer?.setCurrentPage(newPage, false)
  }

  // drag utils
  const dragTool = () => {
    const panTool = documentViewer?.getTool(Core.Tools.ToolNames.PAN)
    documentViewer?.setToolMode(panTool)
  }

  const selectTool = () => {
    documentViewer?.setToolMode(documentViewer?.getTool(Core.Tools.ToolNames.EDIT))
  }

  // box redaction utils
  const createRedaction = () => {
    documentViewer?.setToolMode(documentViewer?.getTool(Core.Tools.ToolNames.REDACTION))
  }

  return (
    <Box
      sx={{
        height: isDocumentLoaded ? 'auto' : '1px',
        overflow: isDocumentLoaded ? 'scroll' : 'hidden',
        visibility: isDocumentLoaded ? 'visible' : 'hidden',
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="center" mb={4} pt={1}>
        <Box display="flex" gap={1}>
          <TooltipButton
            title="Redact"
            disabled={isComplete || activeFile?.isExcluded}
            variant="flat"
            startIcon={<Icon name="OArea" inheritViewBox />}
            enableShadowEffect
            sx={{
              backgroundColor: theme => theme.palette.white.main,
            }}
            onClick={createRedaction}
          >
            Area
          </TooltipButton>

          <TooltipButton
            title="Pan"
            disabled={isComplete}
            variant="iconCustomRounded"
            enableShadowEffect
            sx={{
              backgroundColor: theme => (isDraggable ? theme.palette.sphere.main : theme.palette.white.main),
            }}
            onClick={() => setIsDraggable(!isDraggable)}
          >
            <Icon name="OHand" inheritViewBox />
          </TooltipButton>

          <TooltipButton
            sx={{
              backgroundColor: theme => (activeFile?.isExcluded ? theme.palette.sphere.main : theme.palette.white.main),
            }}
            title={activeFile?.isExcluded ? 'Include File' : 'Exclude File'}
            disabled={isComplete || isSaving}
            variant="flat"
            enableShadowEffect
            onClick={() => onExclude(!!activeFile?.isExcluded)}
            startIcon={<Icon name="ODecline" />}
          >
            {activeFile?.isExcluded ? 'Excluded' : 'Exclude'}
          </TooltipButton>

          <PageNavigator
            handlePageUp={handlePageUp}
            handlePageDown={handlePageDown}
            pageNumber={pageNumber}
            pageCount={pageCount}
          />
        </Box>

        <Box display="flex" ml={4} gap={1}>
          <Button
            variant="iconCustomRounded"
            enableShadowEffect
            aria-label="Zoom In"
            onClick={zoomIn}
            sx={{
              backgroundColor: theme => theme.palette.white.main,
            }}
          >
            <Icon name="OPlus" inheritViewBox />
          </Button>

          <Button
            variant="iconCustomRounded"
            enableShadowEffect
            disabled={zoomLevel === 0.25}
            aria-label="Zoom Out"
            onClick={zoomOut}
            sx={{
              backgroundColor: theme => theme.palette.white.main,
            }}
          >
            <Icon name="OMinus" inheritViewBox />
          </Button>
          {/* TODO:GP implement dropdown */}
          <StyledZoomLevel>
            <Typography variant="smallLabel">{`${(zoomLevel * 100).toFixed(0)}%`}</Typography>
            {/* <KeyboardArrowDownIcon /> */}
          </StyledZoomLevel>
        </Box>
      </Box>
      <Box
        sx={{
          height: '71vh',
          width: '100%',
          overflow: 'scroll',
          pt: 1.25,
        }}
        ref={scrollView}
      >
        <Box ref={viewer} width="100%" height="100%" margin="0 auto"></Box>
      </Box>
    </Box>
  )
}
