import { styled } from '@mui/system'
import Box from '@mui/material/Box'

export const StyledZoomLevel = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.white.main,
  borderRadius: 100,
  display: 'flex',
  height: 32,
  justifyContent: 'center',
  padding: 8,
  boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.05)',
}))
