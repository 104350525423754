import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import {
  AccessAndUsageRisksHighlight,
  AccessAndUsageRisksOverAccess,
  AccessAndUsageRisksUnutilizedData,
} from './components'

const PREFIX = 'AccessAndUsageRisks'

const classes = {
  accessAndUsageRisksContainer: `${PREFIX}-accessAndUsageRisksContainer`,
  accessAndUsageRisksLabel: `${PREFIX}-accessAndUsageRisksLabel`,
  accessAndUsageRisksItemsContainer: `${PREFIX}-accessAndUsageRisksItemsContainer`,
  accessAndUsageRisksUnutilizedDataContainer: `${PREFIX}-accessAndUsageRisksUnutilizedDataContainer`,
  accessAndUsageRisksOverAccessContainer: `${PREFIX}-accessAndUsageRisksOverAccessContainer`,
  accessAndUsageRisksHighlightContainer: `${PREFIX}-accessAndUsageRisksHighlightContainer`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.accessAndUsageRisksContainer}`]: {
    margin: spacing(5, 0),
  },

  [`& .${classes.accessAndUsageRisksLabel}`]: {
    fontSize: 28,
    fontWeight: 800,
    color: 'rgba(7, 26, 36, 0.5)',
  },

  [`& .${classes.accessAndUsageRisksItemsContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    height: 496,
    marginTop: spacing(2),
  },

  [`& .${classes.accessAndUsageRisksUnutilizedDataContainer}`]: {
    flexBasis: 460,
    flexGrow: 0,
    backgroundColor: `${palette.white.main}`,
    borderRadius: spacing(1.5),
  },

  [`& .${classes.accessAndUsageRisksOverAccessContainer}`]: {
    flexBasis: 460,
    flexGrow: 0,
    backgroundColor: `${palette.white.main}`,
    borderRadius: spacing(1.5),
  },

  [`& .${classes.accessAndUsageRisksHighlightContainer}`]: {
    flexBasis: 328,
    flexGrow: 0,
    backgroundColor: `${palette.lavender.main}`,
    borderRadius: spacing(1.5),
  },
}))

type Props = {}

//TODO: Complete UI
export const AccessAndUsageRisks: React.FC<Props> = () => {
  return (
    <StyledBox className={classes.accessAndUsageRisksContainer}>
      <Text className={classes.accessAndUsageRisksLabel}>Access & Usage Risks</Text>
      <Box className={classes.accessAndUsageRisksItemsContainer}>
        <AccessAndUsageRisksUnutilizedData />
        <AccessAndUsageRisksOverAccess />
        <AccessAndUsageRisksHighlight />
      </Box>
    </StyledBox>
  )
}
