import { AssetPolicyAssetDTO } from '@ketch-com/figurehead'

type Args = {
  type: 'named' | 'labeled'
  ass: AssetPolicyAssetDTO
}

export const getAllowAccessChipText = ({ type, ass }: Args): string => {
  if (type === 'named') {
    return ass?.named || ''
  }

  if (type === 'labeled') {
    const temp = Object.entries(ass?.labeled || {})?.[0]?.filter(el => !!el)

    if (temp.length === 1) return (temp?.[0] as string) || ''

    return `${temp[0]} | ${temp[1]}`
  }

  return 'identified by'
}
