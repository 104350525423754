import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import {
  DeleteNotFoundTrackersResponseBody,
  DeleteAllNotFoundTrackersParams,
} from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type params = APIRequestParams<DeleteAllNotFoundTrackersParams>

export const deleteAllNotFoundTrackers = ({ searchString }: params) =>
  API.delete<DeleteNotFoundTrackersResponseBody>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/trackers/not-found?searchString=${searchString}`,
    }),
  )
