import { MaybeNull } from 'interfaces/common'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  infoUrl: string
}

export const fetchFileInfo = ({ infoUrl }: Params) =>
  API.get<MaybeNull<FileInfoDTO>>(
    formatRequestString({
      entityUrl: infoUrl,
    }),
  )
