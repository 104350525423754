import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { getFormMode } from 'utils/helpers/form'
import { RoutesManager } from 'utils/routing/routesManager'
import { formModes } from 'utils/constants/formModes'
import { getRegionsHierarchy, mapValuesToPayload } from 'pages/policyCenter/policyScopes/upsert/utils'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useRegionsHierarchy } from 'api/regions/queries/useRegionsHierarchy'
import { useRights } from 'api/rights/queries/useRights'
import { useRegulations } from 'api/regulations/queries/useRegulations'
import { usePolicyScopeTemplates } from 'api/policyScopes/queries/usePolicyScopeTemplates'
import { usePolicyScope } from 'api/policyScopes/queries/usePolicyScope'
import { useCreatePolicyScope } from 'api/policyScopes/mutations/useCreatePolicyScope'
import { useUpdatePolicyScope } from 'api/policyScopes/mutations/useUpdatePolicyScope'
import { useCustomRightNameList } from 'api/customRightNames/queries/useCustomRightNameList'
import { showToast } from 'components/modals/AlertComponent'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useAuth } from 'utils/hooks/useAuth'
import { demoOnlyRegulations } from '../constants'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { PolicyScopeFormValues } from '../interfaces'

export const usePolicyScopeUpsertUtils = () => {
  // All the logic before the return statement goes here...
  const queryClient = useQueryClient()
  const location = useLocation()
  const navigate = useNavigate()
  const { code: policyScopeCode } = useParams<{ code: string }>()
  const { queries } = useQueryParams<{ templateCode?: string }>()
  const mode = getFormMode({ itemId: policyScopeCode!, location })
  const isEditMode = mode === formModes.EDIT
  const { userData } = useAuth()
  const { isPermitted } = useIsPermitted()
  const hasDemoPermissions = isPermitted(PERMISSIONS.DEMO)

  const { data: regionGroups, isLoading: isRegionsLoading } = useRegionsHierarchy()

  const { data: customRightNamesList, isLoading: isCrnListLoading } = useCustomRightNameList({
    params: {
      includeMetadata: true,
    },
  })

  const { data: rights, isLoading: isRightsLoading } = useRights({
    params: {
      includeIssues: true,
      includeMetadata: true,
    },

    onError: () => {
      showToast({ content: 'Failed to load rights', type: 'error' })
    },
  })

  const { data: regulations, isLoading: isRegulationsLoading } = useRegulations({
    params: {
      includeIssues: true,
      includeMetadata: true,
    },

    onError: () => {
      showToast({ content: 'Failed to load regulations', type: 'error' })
    },
  })

  const { data: templates, isLoading: isTemplatesLoading } = usePolicyScopeTemplates({
    enabled: !!queries.templateCode,
    params: {
      includeIssues: true,
      includeMetadata: true,
    },

    onError: () => {
      showToast({ content: 'Failed to load policy templates', type: 'error' })
    },
  })

  const { data: policyScope, isLoading: isPolicyScopeLoading } = usePolicyScope({
    enabled: isEditMode,
    params: {
      includeIssues: true,
      includeMetadata: true,
      policyScopeCode: policyScopeCode!,
    },

    onError: () => {
      showToast({ content: 'Failed to load jurisdiction', type: 'error' })
    },
  })

  const { mutateAsync: handleCreatePolicyScope } = useCreatePolicyScope({
    onSuccess: ({ data }) => {
      showToast({ content: `Jurisdiction ${formModes.saveSuccessTextByMode[mode]}`, type: 'success' })
      navigate(RoutesManager.policyCenter.policyScopes.view.root.getURL({ code: data.policyScope.code }))
    },
    onError: () => {
      showToast({ content: `Failed to ${formModes.saveErrorTextByMode[mode]} jurisdiction`, type: 'error' })
    },
  })

  const { mutateAsync: handleUpdatePolicyScope } = useUpdatePolicyScope({
    onSuccess: async ({ data }) => {
      await queryClient.refetchQueries([
        ApiQueryKeys.policyScope,
        {
          includeIssues: true,
          includeMetadata: true,
          policyScopeCode,
          organizationCode: userData?.organizationCode,
        },
      ])
      showToast({ content: `Jurisdiction ${formModes.saveSuccessTextByMode[mode]}`, type: 'success' })
      navigate(RoutesManager.policyCenter.policyScopes.view.root.getURL({ code: data.policyScope.code }))
    },
    onError: () => {
      showToast({ content: `Failed to ${formModes.saveErrorTextByMode[mode]} jurisdiction`, type: 'error' })
    },
  })

  const onSubmit = async (values: PolicyScopeFormValues) => {
    const formData = mapValuesToPayload(values)

    await (isEditMode
      ? handleUpdatePolicyScope({
          params: {
            policyScopeCode: policyScopeCode!,
            formData,
          },
        })
      : handleCreatePolicyScope({
          params: {
            formData,
          },
        }))
  }

  const regionsHierarchyTransformed = getRegionsHierarchy(regionGroups)
  const policyScopeTemplate =
    templates?.find(policyScopeTemplate => policyScopeTemplate.code === queries?.templateCode) || null

  const isReady =
    !isRegionsLoading && !isRightsLoading && !isRegulationsLoading && !isTemplatesLoading && !isPolicyScopeLoading

  /* If user doesn't have demo permissions, we need to filter out demo-only regulations */
  const filteredRegulations = regulations.filter(regionGroup =>
    hasDemoPermissions ? true : !demoOnlyRegulations.includes(regionGroup.code),
  )

  // Return all the values
  return {
    isEditMode,
    rights,
    policyScope,
    onSubmit,
    regionsHierarchyTransformed,
    policyScopeTemplate,
    isReady,
    filteredRegulations,
    customRightNamesList,
    isCrnListLoading,
  }
}

export type UsePolicyScopeUpsertUtils = ReturnType<typeof usePolicyScopeUpsertUtils>
