export enum ApplicationIdentitySpaceEncoding {
  NONE = 'none',
  BASE64 = 'base64',
}

export const APPLICATION_IDENTITY_SPACE_ENCODING_NAME = {
  [ApplicationIdentitySpaceEncoding.NONE]: 'None',
  [ApplicationIdentitySpaceEncoding.BASE64]: 'Base64',
}

export const APPLICATION_IDENTITY_SPACE_ENCODING_OPTIONS = [
  {
    id: ApplicationIdentitySpaceEncoding.NONE,
    name: APPLICATION_IDENTITY_SPACE_ENCODING_NAME[ApplicationIdentitySpaceEncoding.NONE],
  },
  {
    id: ApplicationIdentitySpaceEncoding.BASE64,
    name: APPLICATION_IDENTITY_SPACE_ENCODING_NAME[ApplicationIdentitySpaceEncoding.BASE64],
  },
]
