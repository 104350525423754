import { WorkflowActivityCode, WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep } from '../../../interfaces'

const buildMustHaveDecisionBooleanErrorMessage = (errors: string[]) => {
  if (!errors || errors.length === 0) return

  const hasIDVError = errors.includes(WorkflowActivityCode.IDENTITY_VERIFICATION)

  const hasApprovalError = errors.includes(WorkflowActivityCode.APPROVAL)

  return `Please place a Decision Boolean tile after your ${
    hasIDVError && hasApprovalError
      ? 'Identity Verification and Approval'
      : hasApprovalError
      ? 'Approval'
      : 'Identity Verification'
  } tile${errors.length > 1 ? 's' : ''}.`
}

export const getIdentityVerificationOrApprovalTileWithoutDecisionBooleanErrors = (steps: ICanvasStep[]) =>
  buildMustHaveDecisionBooleanErrorMessage(
    steps
      .filter(
        step =>
          // Check both activityCode + activity.code to work around activityCode not being populated in some odd cases
          step.activityCode === WorkflowActivityCode.IDENTITY_VERIFICATION ||
          step.activity?.code === WorkflowActivityCode.IDENTITY_VERIFICATION ||
          step.activityCode === WorkflowActivityCode.APPROVAL ||
          step.activity?.code === WorkflowActivityCode.APPROVAL,
      )
      .map(step => {
        const transitionId = step?.activity?.transition
        if (!transitionId) return undefined

        const nextStep = steps.find(step => step.ID === transitionId)

        // Check both activityCode + gateway.mode to work around activityCode not being populated in some odd cases
        const isNextStepDecisionBoolean =
          nextStep?.activityCode === WorkflowActivityCode.DECISION_BOOLEAN ||
          nextStep?.gateway?.mode === WorkflowActivityGatewayMode.DECISION_BOOLEAN

        return isNextStepDecisionBoolean ? undefined : step?.activity?.code || step.activityCode
      })
      .filter(x => x !== undefined) as string[],
  )
