import React, { useMemo } from 'react'

import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { DataGrid, EmptyState, Status, StatusState, StatusVariant, TableCell, Chip, Button } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Box, Typography } from '@mui/material'

type Props = {
  identitySpace: IdentitySpaceDTO
  versions: IdentitySpaceDTO[]
}

export const IdentitySpaceTabVersions: React.FC<Props> = ({ identitySpace, versions }) => {
  const { queries, setQueryParam, removeQueryParam } = useQueryParams<{ version?: string }>()

  const { name, code, version } = identitySpace
  const currentVersion = queries.version ? +queries.version : version

  const columns = useMemo(
    () => [
      {
        field: 'status',
        headerName: 'Status',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {!!params?.row?.latest && (
              <Status label="Latest" variant={StatusVariant.ghost} status={StatusState.success} icon="Dot" />
            )}
          </TableCell>
        ),
        sortable: false,
        flex: 1,
      },
      {
        field: 'version',
        headerName: 'Version',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <Chip label={`v${params?.row?.version}`} size="small" />
          </TableCell>
        ),
        flex: 1,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <NameAndCodeCellRenderer name={params?.row?.name} code={params?.row?.code} />
          </TableCell>
        ),
        flex: 1,
        sortable: false,
      },
      {
        field: 'updatedAt',
        headerName: 'Created',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <FormattedDateRenderer date={params?.row?.metadata?.createdAt} />
          </TableCell>
        ),
        flex: 1,
        sortable: false,
      },
      {
        field: 'action',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <Button
              color="tertiary"
              disabled={params?.row?.version === currentVersion}
              onClick={() => {
                if (params?.row?.latest) {
                  removeQueryParam('version')
                } else {
                  setQueryParam('version', params?.row?.version!)
                }
              }}
            >
              View
            </Button>
          </TableCell>
        ),
        width: 100,
        sortable: false,
        renderHeader: () => null,
      },
    ],
    [currentVersion, removeQueryParam, setQueryParam],
  )

  return (
    <>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Settings', link: RoutesManager.settings.root.getURL() },
          { title: 'Identifiers', link: RoutesManager.settings.identitySpaces.root.getURL() },
          {
            title: name || code,
            link: RoutesManager.settings.identitySpaces.view.root.getURL({ code }),
          },
          { title: 'Versions' },
        ]}
      />

      {!versions.length ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={6}
          borderRadius="11px"
          bgcolor="bone.main"
        >
          <EmptyState
            iconName="OClock"
            title="No versions exist for the selected Identifier"
            subTitle="As you make changes to this Identifier, you can view that history here"
          />
        </Box>
      ) : (
        <>
          <Typography variant="h3" mb={3}>
            Version History
          </Typography>
          <DataGrid
            rows={versions}
            columns={columns}
            disableColumnMenu
            disableColumnPinning
            disableColumnReorder
            disableChildrenSorting
            isPaginationLoading
            disableRowSelectionOnClick
            disableRowHoverStates
            disableColumnResize
            hideFooter
            getRowId={row => row.ID}
            sx={{
              height: 'auto',
            }}
          />
        </>
      )}
    </>
  )
}
