import React, { useEffect, useState } from 'react'
import { usePrevious } from 'react-use'
import { makeStyles, createStyles } from '@mui/styles'
import { FormikConfig, FormikProps, FormikProvider, useFormik } from 'formik'
import { findLastIndex, isFunction } from 'lodash'

import { ObjectLiteral } from 'interfaces'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { Text } from 'components/ui-kit/typography/Text'
import { FormStep } from 'components/ui-kit/form/common/step/FormStep'
import {
  NavigationBreadCrumbs,
  NavigationBreadCrumbsProps,
} from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { CreateLayoutContext } from 'components/ui-layouts/createLayout/CreateLayoutContext'
import { CreateLayoutActionsPortal } from 'components/ui-layouts/createLayout/CreateLayoutActions'

const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        width: 1280,
        position: 'relative',
      },
      steps: {
        position: 'sticky',
        top: 8,
        width: 240,
        marginRight: 8,
        padding: '8px 0',
        borderRadius: 11,
        backgroundColor: theme.palette.white.main,
        alignSelf: 'flex-start',
        cursor: 'auto',
      },
      step: {
        width: '100%',
        marginBottom: 14,
        padding: '0 13px 0 18px',

        '&:last-child': {
          marginBottom: 0,
        },
      },
      contentWrapper: {
        flex: 1,
        position: 'relative',
        padding: '0 14px',
        backgroundColor: theme.palette.white.main,
        borderRadius: 11,
      },
      header: {
        padding: '22px 18px',
        borderBottom: `1px solid ${theme.palette.iron.main}`,
      },
      footer: {
        position: 'sticky',
        zIndex: 1,
        bottom: 0,
        padding: '18px 0',
        borderTop: `1px solid ${theme.palette.iron.main}`,
        backgroundColor: theme.palette.white.main,
      },
    }),
  { name: 'CreateLayout' },
)

type State = {
  isLastStep: boolean
  isActiveStepValid: boolean
  setNextStep: () => void
}

type Props<I, T> = Omit<FormikConfig<I>, 'children'> & {
  isReady?: boolean
  title: string
  steps?: T[] | ((formikProps: FormikProps<I>) => T[])
  getIsActiveStepValid?: (activeStep: T, formikProps: FormikProps<I>) => boolean
  children: (formikProps: FormikProps<I>, state: State) => React.ReactNode
  breadcrumbs: NavigationBreadCrumbsProps['items']
}

export function CreateLayout<I extends ObjectLiteral, T extends string = string>({
  isReady = true,
  title,
  steps = [],
  getIsActiveStepValid = () => true,
  breadcrumbs,
  validateOnMount = true,
  enableReinitialize = true,
  children,
  ...rest
}: Props<I, T>) {
  const classes = useStyles()
  const formikProps = useFormik({ ...rest, validateOnMount, enableReinitialize })
  const formSteps = isFunction(steps) ? steps(formikProps) : steps
  const [activeStep, setActiveStep] = useState(formSteps[0])
  const activeStepIndex = formSteps.indexOf(activeStep)
  const previousActiveStepIndex = usePrevious(activeStepIndex)

  useEffect(() => {
    if (!formSteps.includes(activeStep)) {
      const lastValidStepIndex = findLastIndex(formSteps, step => getIsActiveStepValid(step, formikProps))

      setActiveStep(formSteps[lastValidStepIndex])
    }
  }, [formSteps, activeStep, formikProps, getIsActiveStepValid])

  useEffect(() => {
    if (activeStepIndex !== previousActiveStepIndex && activeStepIndex) {
      const domNode = document.getElementById('create-form-steps-container')
      const domNodeChildren = domNode?.children || []

      if (domNodeChildren[activeStepIndex]) {
        domNodeChildren[activeStepIndex]?.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [activeStepIndex, previousActiveStepIndex])

  const state = {
    isLastStep: activeStepIndex === formSteps.length - 1,
    isActiveStepValid: getIsActiveStepValid(activeStep, formikProps),
    setNextStep: () => {
      const nextStepIndex = activeStepIndex + 1

      if (nextStepIndex <= formSteps.length - 1) {
        setActiveStep(formSteps[nextStepIndex])
      }
    },
  }

  return (
    <CreateLayoutContext.Provider
      value={{
        isSectionActive: (step: T) => {
          const stepIndex = formSteps.indexOf(step)

          return stepIndex !== -1 && stepIndex <= activeStepIndex
        },
      }}
    >
      <FormikProvider value={formikProps}>
        <Flex className={classes.root}>
          {isReady ? (
            <>
              <NavigationBreadCrumbs type="light" items={breadcrumbs} />

              {formSteps && formSteps.length >= 1 && (
                <div className={classes.steps}>
                  {formSteps.map((step, stepIndex) => (
                    <FormStep
                      key={step}
                      className={classes.step}
                      index={stepIndex + 1}
                      title={step}
                      isCompleted={stepIndex < activeStepIndex}
                      isDisabled={stepIndex > activeStepIndex}
                    />
                  ))}
                </div>
              )}

              <form autoComplete="off" className={classes.contentWrapper}>
                {title ? (
                  <div className={classes.header}>
                    <Text size={28} weight={800}>
                      {title}
                    </Text>
                  </div>
                ) : null}

                <div id="create-form-steps-container">{children(formikProps, state)}</div>

                <CreateLayoutActionsPortal.Target className={classes.footer} />
              </form>
            </>
          ) : (
            <Spinner padding={50} />
          )}
        </Flex>
      </FormikProvider>
    </CreateLayoutContext.Provider>
  )
}
