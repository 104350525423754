import * as Yup from 'yup'

import { WorkflowExecutionStepCompleteDTO } from 'interfaces/workflowExecutions/workflowExecution'

import { WorkflowExecutionEmailStepFromValues } from '../interfaces'

export const getValidationSchema = ({
  hasResolutionNotes,

  hasResolutionAttachments,
}: {
  hasResolutionNotes: boolean
  hasResolutionAttachments: boolean
}) =>
  Yup.object().shape({
    ...(hasResolutionNotes && {
      resolutionDetails: Yup.string().required('Required'),
    }),
    ...(hasResolutionAttachments && {
      resolutionAttachments: Yup.array().min(1, 'Required'),
    }),
  })

export const mapValuesToPayload = (values: WorkflowExecutionEmailStepFromValues): WorkflowExecutionStepCompleteDTO => ({
  results: {
    resolutionDetails: values?.resolutionDetails,
    resolutionAttachments: values?.resolutionAttachments?.map(file => file.info_url || ''),
  },
})
