import * as Yup from 'yup'

export type WorkflowExecutionStepApproveFormValues = {
  assigneeId: string
  decisionBoolean?: boolean
}

export const getValidationSchema = () =>
  Yup.object().shape({
    assigneeId: Yup.string().required('Required'),
  })
