import { UserRoleDTO } from 'interfaces/users/userRole'
import { NO_PERMISSION } from '../../constants'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import {
  roleGroupNames,
  roleGroupDescriptions,
  getCopiedFilteredRoles,
  RoleTooltipDetails,
  roleGroupTooltips,
  roleGroupPriority,
  parseRoleDisplayName,
} from 'pages/settings/users/view/utils/getUserRolesDisplayData'

type UserFormSectionRolesOptions = {
  [key: string]: {
    roleName: string
    roleDescription: string
    roleGroupPriority: number
    tooltips: RoleTooltipDetails[]
    group: string
    options: { name: string; id: string }[]
  }
}

export const getUserFormSectionRolesOptions = (
  roles: UserRoleDTO[],
  isEntitled: (requiredEntitlements: ENTITLEMENTS | ENTITLEMENTS[], requireAll?: boolean) => boolean,
) => {
  const temp: UserFormSectionRolesOptions = {}
  const copiedFilteredRoles = getCopiedFilteredRoles(roles, isEntitled)

  copiedFilteredRoles?.forEach?.(r => {
    if (!temp?.[r.group]) {
      const optionItem = {
        name: parseRoleDisplayName(r.name),
        id: r.code,
      }
      temp[r.group] = {
        options: [optionItem],
        group: r.group,
        roleName: roleGroupNames[r.group],
        roleDescription: roleGroupDescriptions[r.group],
        roleGroupPriority: roleGroupPriority[r.group],
        tooltips: roleGroupTooltips[r.group],
      }
    } else {
      const optionItem = {
        name: parseRoleDisplayName(r.name),
        id: r.code,
      }
      temp[r.group] = {
        ...temp[r.group],
        options: [...temp[r.group]?.options, optionItem],
      }
    }
  })

  const tempKeys = Object.keys(temp)

  tempKeys?.forEach?.(key => {
    if (key !== 'org') {
      temp[key] = {
        ...temp[key],
        options: [...temp[key]?.options, { name: 'None', id: NO_PERMISSION }],
      }
    }
  })

  return temp
}
