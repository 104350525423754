import React, { forwardRef, MouseEvent } from 'react'
import { TreeItemContentProps, useTreeItem } from '@mui/x-tree-view'
import Box from '@mui/material/Box'
import { Icon, ListItem, Chip } from '@ketch-com/deck'
import Highlighter from 'react-highlight-words'
import { Typography } from '@mui/material'
import { RegionDTO } from 'interfaces/regions/region'

declare module '@mui/x-tree-view' {
  interface TreeItemContentProps {
    item: { [x: string]: any }
    nestedTreeLevel: number
    onItemClick: (e: MouseEvent<HTMLDivElement>) => void
    type: string
    searchString?: string
  }
}

const expandableActionLevelItemValue = (nestedTreeLevel: number) => {
  if (nestedTreeLevel === 0) {
    return 'one'
  }
  if (nestedTreeLevel === 1) {
    return 'two'
  }
  if (nestedTreeLevel === 2) {
    return 'three'
  }
  return 'inherit'
}

export const PolicyScopeRegionsAndRegulationContentComponent = forwardRef(function CustomContent(
  { item, nodeId, nestedTreeLevel, searchString, onItemClick, type }: TreeItemContentProps,
  ref: React.Ref<HTMLLIElement & HTMLDivElement>,
) {
  const { expanded, handleExpansion } = useTreeItem(nodeId)
  const region = item as RegionDTO
  return (
    <ListItem
      sx={{
        maxHeight: 'unset',
      }}
      ref={ref}
      onClick={e => {
        e.stopPropagation()
        handleExpansion(e as React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>)
      }}
      tabIndex={0}
      onExpandableActionIconClick={onItemClick}
      isExpandableAction
      expandableActionLevelItem={expandableActionLevelItemValue(nestedTreeLevel)}
      expandableActionIcon={type === 'INCLUDE' ? 'FMinusRound' : 'FPlusRound'}
      startElement={
        <Box display="flex" flexDirection="column">
          <Highlighter searchWords={searchString?.split(' ') || []} textToHighlight={region.name} />
          <Typography variant="smallFootnote">{region.code}</Typography>
        </Box>
      }
      startIcon={region.regions && region.regions.length && <Icon name={expanded ? 'OArrowCDown' : 'OArrowCRight'} />}
      endElement={<Chip size="small" label={region.code} />}
    />
  )
})
