import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { isUndefined } from 'lodash'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { getSiblingsFromStep } from 'pages/orchestration/workflows/edit/utils/steps/siblingsFromStep'

interface ICanReachStartPath {
  step: ICanvasStep
  steps: Array<ICanvasStep>
}

export function canReachStartPath(params: ICanReachStartPath): boolean {
  const { step, steps } = params
  const { parentStep, parentSteps = [] } = getSiblingsFromStep({ step, steps })
  const isStart = !isUndefined(step.start)
  const isJoin = step.gateway?.mode === WorkflowActivityGatewayMode.JOIN

  if (isStart) {
    return true
  }

  if ((isJoin && parentSteps?.length === 0) || (!isJoin && !parentStep)) {
    return false
  }

  // Can reach start if any of the parent steps can reach start
  return parentSteps.map(ps => canReachStartPath({ step: ps, steps })).includes(true)
}
