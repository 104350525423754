import React from 'react'
import { Box, SxProps, Theme, Typography } from '@mui/material'
import { Icon, theme } from '@ketch-com/deck'
import { CapabilitiesList } from 'interfaces/dataSystems/Capabilities'
import { ShortNameCapabilitiesList } from 'interfaces/dataSystems/Capabilities/CapabilitiesList'

type Props = {
  supportedCapabilites: number[] | undefined
  hasNoConnections?: boolean
  hasCredentialsIssue?: boolean
  sx?: SxProps<Theme> | undefined
  isShortVersion?: boolean
}

export const Capabilites: React.FC<Props> = ({
  supportedCapabilites,
  hasNoConnections,
  hasCredentialsIssue,
  sx,
  isShortVersion = false,
}) => {
  if (!supportedCapabilites) {
    return null
  }

  const status = hasNoConnections ? 'noConnections' : hasCredentialsIssue ? 'credentialIssue' : 'active'

  const capabilities = !isShortVersion
    ? CapabilitiesList.filter(({ code }) => supportedCapabilites?.includes(code)).map(capability => ({
        ...capability,
        status,
      }))
    : ShortNameCapabilitiesList.filter(({ code }) => supportedCapabilites?.includes(code)).map(capability => ({
        ...capability,
        status,
      }))

  const renderedItems = capabilities.map(capability => {
    return (
      <Box
        key={capability.code}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: theme => theme.spacing(0.5),
        }}
      >
        {hasNoConnections ? (
          <Icon name="OCheck" width={20} height={20} iconColor={theme.palette.darkGrey.main} />
        ) : hasCredentialsIssue ? (
          <Icon name="OImportant" iconColor={theme.palette.chileanFire.main} width={20} height={20} />
        ) : (
          <Icon name="FCheckRound" iconColor={theme.palette.greenHaze.main} width={20} height={20} />
        )}
        <Typography
          variant="smallLabel"
          color={hasNoConnections ? 'darkGrey.main' : hasCredentialsIssue ? 'chileanFire.main' : 'greenHaze.main'}
          pl={0.5}
        >
          {capability.name}
        </Typography>
      </Box>
    )
  })

  if (isShortVersion) {
    return <>{renderedItems}</>
  }
  return (
    <Box display="flex" flexDirection="column" sx={sx}>
      {renderedItems}
    </Box>
  )
}
