import React, { createContext, ReactNode, FC } from 'react'
import { UseIdvViewUtils, useIdvViewUtils } from '../hooks/useIdvViewUtils'

// Creating context
export const IdentityVerificationContext = createContext({} as UseIdvViewUtils)

type Props = {
  children: ReactNode
}

// Provider component
export const IdentityVerificationProvider: FC<Props> = ({ children }) => {
  const hookValues = useIdvViewUtils()

  return (
    <IdentityVerificationContext.Provider value={{ ...hookValues }}>{children}</IdentityVerificationContext.Provider>
  )
}

// Higher Order Component to inject context into components
export const withIdentityVerificationContext = (Component: FC) => {
  return (props: any) => (
    <IdentityVerificationProvider>
      <Component {...props} />
    </IdentityVerificationProvider>
  )
}
