import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { Header } from '..'
import Typography from '@mui/material/Typography'
import { FormTemplateUpsertContext } from 'pages/consentAndRights/forms/templates/upsert/context/FormTemplateUpsertContextProvider'
import { FormTemplateDTO } from '@ketch-com/figurehead'
import { useFormikContext } from 'formik'
import { Tab, TabGroup } from '@ketch-com/deck'
import { Description, Title, FormFields } from './components'

type Props = {}

export const FormTemplateSection: React.FC<Props> = () => {
  const { values, setFieldValue } = useFormikContext<FormTemplateDTO>()
  const [currentTab, setCurrentTab] = useState(0)
  const { activeSectionIndex, activeSidebarTab, setActiveSidebarTab, mandatoryFormFieldIds } =
    useContext(FormTemplateUpsertContext)

  useEffect(() => {
    if (activeSidebarTab === 'title') {
      setCurrentTab(0)
    } else if (activeSidebarTab === 'description') {
      setCurrentTab(1)
    } else {
      setCurrentTab(2)
    }
  }, [activeSidebarTab])

  if (typeof activeSectionIndex !== 'number') return null

  const isActiveSectionPersonalDetailsSection = values?.sections?.[activeSectionIndex]?.formFields?.some(formField =>
    mandatoryFormFieldIds.includes(formField?.id || ''),
  )

  return (
    <Box display="flex" flexDirection="column">
      <Header
        title={isActiveSectionPersonalDetailsSection ? 'Personal Details' : 'Section Settings'}
        isRequired={true}
        onRemove={() => {
          const sections = values?.sections?.filter((section, index) => index !== activeSectionIndex)
          setFieldValue('sections', sections)
        }}
      />

      <Typography variant="body" component={Box} mb={2.25}>
        {isActiveSectionPersonalDetailsSection ? (
          <>
            Title and description are optional. For this section only, Email Address, First Name, and Last Name fields
            are required.
          </>
        ) : (
          <>
            All elements are optional, however, every section must contain at least one element. Elements left empty
            will be excluded from this section.
          </>
        )}
      </Typography>

      <Box mb={3}>
        <TabGroup value={currentTab}>
          <Tab key={1} label="Title" onClick={() => setActiveSidebarTab('title')} />
          <Tab key={2} label="Description" onClick={() => setActiveSidebarTab('description')} />
          <Tab key={3} label="Fields" onClick={() => setActiveSidebarTab('fields')} />
        </TabGroup>
      </Box>
      {activeSidebarTab === 'title' && <Title />}
      {activeSidebarTab === 'description' && <Description />}
      {activeSidebarTab === 'fields' && <FormFields />}
    </Box>
  )
}
