import React, { Fragment, useContext, useMemo, useState } from 'react'
import { FieldArray, Formik } from 'formik'
import { compact } from 'lodash'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { Button } from '@ketch-com/deck'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'
import { formatCodeFromName } from 'utils/formatters'
import { Box, Typography } from '@mui/material'
import { DecisionPathTile } from './components'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { IGetDecisionGatewayInitialValues } from './utils/getDecisionGatewayInitialValues'
import { ContextVariableCategoryEnum } from 'interfaces/workflows/ContextVariableCategoryEnum'
import { EditWorkflowContext } from 'pages/orchestration/workflows/edit/contexts/EditWorkflowContext'
import {
  getDecisionGatewayInitialValues,
  getDecisionGatewayTransitionItem,
  getDecisionGatewayValidationSchema,
} from './utils'
import { DecisionGatewayConfirmDeleteModal } from './components/DecisionGatewayConfirmDeleteModal'
import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { getNextJoinTile } from 'pages/orchestration/workflows/edit/utils/steps/getNextJoinTile'
import { ContextVariableDTO, FormFieldDTO } from '@ketch-com/figurehead'
import { useFormFields } from 'api/formFields/queries/useFormFields'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { FormInput } from 'components/form/FormInput'
import { FormAssigneeOrTeamSelector } from 'components/form/FormAssigneeOrTeamSelector'

interface IDecisionGatewayFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep; pathId?: string }) => void
}

const formatOperand = (relevantContextVariable: any, transition: any) => {
  if (
    relevantContextVariable?.type === 1 ||
    relevantContextVariable?.type === 4 ||
    relevantContextVariable?.type === 'text' ||
    relevantContextVariable?.type === 'dropdown'
  )
    return `"${transition.operand}"`

  if (relevantContextVariable?.type === 2) return String(transition?.operand)
  return transition.operand
}

export const DecisionGatewayForm: React.FC<IDecisionGatewayFormProps> = ({
  step,
  steps,
  handleClose,
  handleRemove,
  handleSave,
}) => {
  const usedCodes = useMemo(
    () => compact(steps.map(({ code }) => code).filter(code => code !== step.code)),
    [steps, step.code],
  )
  const [showModal, setShowModal] = useState(false)
  const { outcomeVariables, dataSubjectVariables } = useContext(EditWorkflowContext)

  const { data: formFieldVariables, isLoading: isLoadingFormFields } = useFormFields({
    itemsPerPage: 2000,
    params: {
      includeMetadata: true,
      includeReservedFormFields: true,
    },
  })

  // Map as ContextVariableDTO to support FormFieldDTO
  const formFieldVariablesRemap = (formFieldVariables as ContextVariableDTO[]).map(({ name, code, type }) => {
    return {
      name,
      code,
      type,
    }
  })

  const onSubmit = (values: IGetDecisionGatewayInitialValues) => {
    const payload = {
      step: {
        ...step,
        description: values.description,
        code: values.code,
        name: values.name,
        valid: true,
        gateway: {
          ...step.gateway,
          transitions: values.transitions.map(transition => {
            const variableType = transition.variableCategory
            const relevantContextVariable = (
              variableType === ContextVariableCategoryEnum.DATA_SUBJECT_VARIABLE
                ? dataSubjectVariables
                : outcomeVariables
            ).find(cv => cv.code === transition.variable)

            const relevantFormFieldVariable = formFieldVariables.find(
              (cv: FormFieldDTO) => cv.code === transition.variable,
            )

            return {
              ...transition,
              variableCategory: parseInt(String(transition.variableCategory)),
              operand: formatOperand(
                variableType === ContextVariableCategoryEnum.FORM_FIELD_VARIABLE
                  ? relevantFormFieldVariable
                  : relevantContextVariable,
                transition,
              ),
            }
          }),
          mode: values.isSingleDecision === 'true' ? 3 : 4,
          params: {
            descriptionLabel: values.descriptionLabel,
            assignee: values.assignee,
            pathSelector: values.pathSelector,
            timeToCompleteDays: values.timeToCompleteDays,
          },
        } as ICanvasStep['gateway'],
      },
    }

    handleSave(payload)
  }

  const stepIndex = useMemo(() => steps.findIndex(({ code }) => code === step.code), [steps, step])

  // Single decisions can delete their respective joins, but after this, cannot be switched back to multi decisions
  // so... if single decision and every path gets to a nest start OR end before a join, disable the selector to switch back to multi decision
  const shouldDisableMultiDecisionSelection = useMemo(() => {
    const stepTransitions = step.gateway?.transitions || []

    if (step.gateway?.mode === WorkflowActivityGatewayMode.DECISION_MULTI) {
      return false
    }

    const doAllPathsGetToAJoinBeforeANestStartOrEndTile = stepTransitions
      .map(transition => getNextJoinTile({ step: transition, steps, onlyUntilNextNestStart: true }))
      .includes(undefined)

    return doAllPathsGetToAJoinBeforeANestStartOrEndTile
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoadingFormFields) {
    return <></>
  }

  return (
    <SidebarConfigsWrapper isLoading={false} handleClose={handleClose}>
      <Formik
        initialValues={getDecisionGatewayInitialValues({ step }, [
          ...outcomeVariables,
          ...dataSubjectVariables,
          ...formFieldVariablesRemap,
        ])}
        validationSchema={getDecisionGatewayValidationSchema({ usedCodes })}
        validateOnMount
        onSubmit={onSubmit}
      >
        {form => {
          const { isValid, values, submitForm, setFieldValue } = form

          return (
            <Fragment>
              <SidebarConfigsHeader
                isValid={isValid}
                step={step}
                steps={steps}
                handleSave={() => submitForm()}
                handleRemove={() => setShowModal(true)}
                handleClose={handleClose}
                hoverContent="Decision Gateway can execute different paths that either fulfill the automated evaluation criteria or were selected by the assignee manually."
                copyable
              />

              <SidebarConfigsBody>
                <Box px={3}>
                  <Box pb={3}>
                    <Typography variant="h3">Type</Typography>
                  </Box>
                  <Box marginBottom={5}>
                    <FormRadioGroup
                      hideOptionalLabel
                      formPropertyName="isSingleDecision"
                      row={false}
                      renderLabel={(option: any) => (
                        <>
                          <Typography component="div" variant={option.selected ? 'label' : 'body'}>
                            {option.title}
                          </Typography>
                          {option.hint ? (
                            <Typography component="div" variant="smallBody" color="darkDuskFaded.main">
                              {option.hint}
                            </Typography>
                          ) : null}
                        </>
                      )}
                      options={[
                        {
                          title: 'Multi Decision',
                          value: 'false',
                          selected: values.isSingleDecision === 'false',
                          disabled: shouldDisableMultiDecisionSelection,
                        },
                        {
                          title: 'Single Decision',
                          value: 'true',
                          selected: values.isSingleDecision === 'true',
                          hint: 'The first path to meet the specified criteria is traversed.',
                        },
                      ]}
                      onChange={() => {}}
                    />
                  </Box>

                  <Box marginBottom={3}>
                    <Typography variant="h3">Details</Typography>
                  </Box>
                  <Box marginBottom={3}>
                    <FormInput
                      shouldUpdateDebounced
                      formPropertyName="descriptionLabel"
                      fullWidth
                      label="Description Label"
                      placeholder="Description"
                      onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
                      required
                    />
                  </Box>
                  <Box marginBottom={3}>
                    <FormInput
                      formPropertyName="code"
                      fullWidth
                      label="Code"
                      placeholder="Code"
                      required
                      shouldUpdateDebounced
                    />
                  </Box>

                  <Box marginBottom={3}>
                    <FormInput
                      formPropertyName="name"
                      fullWidth
                      label="Title"
                      placeholder="Example: Complete the request"
                      required
                      shouldUpdateDebounced
                    />
                  </Box>
                  <Box marginBottom={3}>
                    <FormInput
                      formPropertyName="description"
                      fullWidth
                      label="Description"
                      placeholder="Example: Please look through the request and assist in its completion."
                      required
                      shouldUpdateDebounced
                      multiline
                      rows={3}
                    />
                  </Box>
                  <Box marginBottom={values.pathSelector !== 'manual' ? 5 : 3}>
                    <FormAssigneeOrTeamSelector label="Assignee" name="assignee" />
                  </Box>
                  {values.pathSelector === 'manual' ? (
                    <Box marginBottom={5}>
                      <FormInput
                        type="number"
                        formPropertyName="timeToCompleteDays"
                        label="Time To Complete (Days)"
                        placeholder="0"
                        inputWidth="80px"
                      />
                    </Box>
                  ) : null}
                  <Box marginBottom={3}>
                    <Typography variant="h3">Paths</Typography>
                  </Box>
                  <Box marginBottom={3}>
                    <FormRadioGroup
                      hideOptionalLabel
                      formPropertyName="pathSelector"
                      row
                      renderLabel={(option, isSelected) => (
                        <Typography variant={isSelected ? 'label' : 'body'}>{option.title}</Typography>
                      )}
                      options={[
                        { title: 'Automatic Selection', value: 'auto' },
                        { title: 'Selected Manually by Assignee', value: 'manual' },
                      ]}
                    />
                  </Box>
                  <Box marginBottom={3}>
                    <FieldArray
                      name="transitions"
                      render={({ push, remove, insert }) => (
                        <Box>
                          <DragDropContext
                            onDragEnd={(dragData: DropResult) => {
                              const sourceIndex = dragData?.source?.index
                              const sourceObject = values?.transitions?.[sourceIndex]
                              const destinationIndex = dragData?.destination?.index

                              if (
                                sourceIndex === undefined ||
                                destinationIndex === undefined ||
                                sourceObject === undefined
                              ) {
                                return
                              }

                              remove(sourceIndex)
                              insert(destinationIndex, sourceObject)
                            }}
                          >
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <Box {...provided.droppableProps} ref={provided.innerRef}>
                                  {values.transitions.map((_: any, index: number) => (
                                    <DecisionPathTile
                                      step={step}
                                      steps={steps}
                                      stepIndex={stepIndex}
                                      key={index}
                                      index={index}
                                      remove={remove}
                                      copyable
                                    />
                                  ))}
                                  {provided.placeholder}
                                </Box>
                              )}
                            </Droppable>
                          </DragDropContext>
                          <Button
                            sx={{ ml: 1.5, mt: 2 }}
                            color="secondary"
                            onClick={() =>
                              push({
                                ...getDecisionGatewayTransitionItem(),
                                name: `Path ${values.transitions.length + 1}`,
                              })
                            }
                          >
                            Add Path
                          </Button>
                        </Box>
                      )}
                    />
                  </Box>
                </Box>
              </SidebarConfigsBody>
              {showModal ? (
                <DecisionGatewayConfirmDeleteModal
                  onSubmit={pathId => handleRemove({ step, pathId })}
                  onCancel={() => setShowModal(false)}
                  step={step}
                  stepType={
                    values?.isSingleDecision === 'true'
                      ? WorkflowActivityGatewayMode.DECISION_SINGLE
                      : WorkflowActivityGatewayMode.DECISION_MULTI
                  }
                />
              ) : null}
            </Fragment>
          )
        }}
      </Formik>
    </SidebarConfigsWrapper>
  )
}
