import React from 'react'
import { styled } from '@mui/styles'
import { Box } from '@mui/material'

import { SystemsListItem } from './SystemsListItem'

import { ReactComponent as EmptyStateDataSystemsIcon } from 'assets/icons/emptyState/EmptyStateDataSystems.svg'

import { useNavigate } from 'react-router-dom'
import { Waypoint } from 'react-waypoint'
import { RoutesManager } from 'utils/routing/routesManager'
import { EmptyState, Spinner } from '@ketch-com/deck'
import { InfiniteScrollLoadingIndicator } from 'components/InfiniteScrollLoadingIndicator'
import { useSystemListFilterContext } from 'pages/dataSystems/components/Filters/SystemListFilterV2/SystemListFilterV2Context'

const AppsListContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gridGap: '1.25rem',
  margin: '0 0 2rem 0',
}))

export const SystemsList: React.FC = () => {
  const {
    isLoading,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    activeFiltersCount,
    resetFilters,
    appListItems,
  } = useSystemListFilterContext()

  const navigate = useNavigate()

  const isReady = !isRefetching && !isLoading

  if (isReady && appListItems?.length === 0 && !activeFiltersCount) {
    return (
      <Box
        sx={{
          backgroundColor: 'white.main',
          padding: 5,
          borderRadius: '11px',
        }}
      >
        <EmptyState
          title="Add, Connect, Automate"
          subTitle="There are no systems added yet. Click on the button below to add and connect your first system!"
          customIcon={<EmptyStateDataSystemsIcon />}
          primaryButtonTitle="Add System"
          primaryButtonProps={{
            onClick: () => navigate(RoutesManager.systems.create.root.getURL()),
          }}
        />
      </Box>
    )
  }
  if (appListItems) {
    return (
      <>
        <Box paddingBottom={4}>
          <>
            {isReady && appListItems?.length === 0 && activeFiltersCount ? (
              <Box
                sx={{
                  backgroundColor: 'white.main',
                  padding: 5,
                  borderRadius: '11px',
                }}
              >
                <EmptyState
                  title="No results found"
                  subTitle="Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try again."
                  iconName="OFilter"
                  primaryButtonTitle="Reset to Defaults"
                  primaryButtonProps={{
                    onClick: () => {
                      resetFilters()
                    },
                  }}
                />
              </Box>
            ) : (
              <AppsListContainer>
                {appListItems?.map(item => (
                  <SystemsListItem app={item.app} ownerAvatarColors={item.ownerAvatarColors} key={item.app.id} />
                ))}
              </AppsListContainer>
            )}
          </>
          {isReady ? (
            <Waypoint
              onEnter={() => {
                if (!isFetchingNextPage && hasNextPage) fetchNextPage()
              }}
            />
          ) : (
            <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
          )}
        </Box>
      </>
    )
  }
  return (
    <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
      <Spinner size="32px" thickness={2.5} />
    </Box>
  )
}
