import { Box } from '@mui/material'

import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { LabelSelectPill } from '../LabelSelectPill'
import { LoadingIndicator } from '../LoadingIndicator'
import { getLabelUniqueIdentifier } from '../../utils'
import { useMegaFilterStyles } from '../../hooks'
import { FILTER_STATES } from '../../constants'
import { FilterConfigItem } from '../../utils/getFiltersConfig'
import { useMegaFilterLabelsExpandableRowUtils } from './hooks'

type Props = {
  rowData: FilterConfigItem
  filterState: FILTER_STATES
}

export const MegaFilterLabelsExpandableRow: React.FC<Props> = ({
  /* rowData, TODO: not sure if need */
  filterState,
}) => {
  const classes = useMegaFilterStyles({ filterState })
  const {
    handleScroll,
    // infiniteLabelsHasNextPage, // TODO:BAC use this to indicate to user that infinite scroll is exhausted
    infiniteLabelsIsFetchingNextPage,
    infiniteLabelsIsLoading,
    isLoadingSearchLabels,
    queries,
    removeQueryParam,
    searchString,
    selectedLabels,
    setQueryParam,
    setSearchString,
    unselectedLabels,
  } = useMegaFilterLabelsExpandableRowUtils()

  return (
    <Box className={classes.filterOptionsContainer}>
      <Box>
        <SearchInput
          fullWidth
          isSearchPending={isLoadingSearchLabels}
          size="small"
          onChange={nextSearchString => setSearchString(nextSearchString)}
          onClear={() => setSearchString('')}
          value={searchString}
        />
      </Box>

      {/* Initial Loading Indicator, When Expansion Panel First Opens */}

      {infiniteLabelsIsLoading && (
        <Box mt={2}>
          <LoadingIndicator />
        </Box>
      )}

      {/* Selected Container */}

      {selectedLabels.length > 0 && (
        <Box mt={2} pl={2} display="flex" maxHeight={150} overflow="scroll" flexWrap="wrap">
          {selectedLabels.map(label => {
            const labelUniqueIdentifier = getLabelUniqueIdentifier(label)
            const isSelected = !!(queries?.label || '')?.split(',').includes(labelUniqueIdentifier)

            const handleBadgeOnClick = () => {
              const currentLabels = (queries?.label || '').split(',') || []
              const potentiallyDuplicatedLabels = [...currentLabels, labelUniqueIdentifier]

              if (isSelected) {
                const payload = [...new Set(potentiallyDuplicatedLabels)]
                  .filter(el => !!el)
                  .filter(el => el !== labelUniqueIdentifier)
                  .join(',')

                if (payload.length === 0) {
                  removeQueryParam('label')
                } else {
                  setQueryParam('label', payload)
                }
              } else {
                const payload = [...new Set(potentiallyDuplicatedLabels)].filter(el => !!el).join(',')
                setQueryParam('label', payload)
              }
            }

            return (
              <LabelSelectPill key={labelUniqueIdentifier} onClick={handleBadgeOnClick} isSelected={isSelected}>
                {label.isDerived ? `${label.code} | ${label.value}` : label.name}
              </LabelSelectPill>
            )
          })}
        </Box>
      )}

      {/* TODO: style this <hr /> */}
      {selectedLabels.length > 0 && <hr />}

      {/* Unselected Container */}

      {unselectedLabels.length > 0 && (
        <Box
          mt={2}
          onScroll={handleScroll}
          pl={2}
          pb={2}
          display="flex"
          maxHeight={150}
          overflow="scroll"
          flexWrap="wrap"
        >
          {unselectedLabels.map(label => {
            const labelUniqueIdentifier = getLabelUniqueIdentifier(label)
            const isSelected = !!(queries?.label || '')?.split(',').includes(labelUniqueIdentifier)

            const handleBadgeOnClick = () => {
              const currentLabels = (queries?.label || '').split(',') || []

              const potentiallyDuplicatedLabels = [...currentLabels, labelUniqueIdentifier]

              if (isSelected) {
                const payload = [...new Set(potentiallyDuplicatedLabels)]
                  .filter(el => !!el)
                  .filter(el => el !== labelUniqueIdentifier)
                  .join(',')

                if (payload.length === 0) {
                  removeQueryParam('label')
                } else {
                  setQueryParam('label', payload)
                }
              } else {
                const payload = [...new Set(potentiallyDuplicatedLabels)].filter(el => !!el).join(',')
                setQueryParam('label', payload)
              }
            }
            return (
              <LabelSelectPill key={labelUniqueIdentifier} onClick={handleBadgeOnClick} isSelected={isSelected}>
                {label.isDerived ? `${label.code} | ${label.value}` : label.name}
              </LabelSelectPill>
            )
          })}

          {infiniteLabelsIsFetchingNextPage && <LoadingIndicator />}
        </Box>
      )}

      {unselectedLabels.length === 0 && (
        <Box pl={2} py={2}>
          No more to select
        </Box>
      )}
    </Box>
  )
}
