import React, { useState } from 'react'

// api
import { Pagination } from 'api/common/paginatedQuery'

// dux
import { getEventsListFilterState } from 'store/eventsListFilterSlice/selectors'
import { useAppSelector } from 'store/hooks'

// components
import Box from '@mui/material/Box'
import { Badge } from 'components/ui-kit/badge/Badge'
import { DeleteIcon } from './DeleteIcon'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { InlineEdit } from './InlineEdit'
import { LinkChip } from 'components/ui-kit/chip/LinkChip'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { Text } from 'components/ui-kit/typography/Text'
import { ViewListLayoutContent } from 'components/ui-layouts/viewListLayout/ViewListLayoutContent'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'

// types
import { EventV2DTO } from '@ketch-com/figurehead'
import { WorkflowDTO } from 'interfaces/workflows'
import { RightsModalType } from 'interfaces/rights/right'
import { DataRoleType, RightType } from 'interfaces/events'
import { DeleteEventModal } from 'components/modals/events/DeleteEventModal'
import { useDataSubjectTypes } from 'api/dataSubjectTypes/queries/useDataSubjectTypes'

type Props = {
  events: EventV2DTO[]
  hasDataSubjectFilter?: boolean
  isLoading: boolean
  pagination: Pagination
  setRightsModal: (rightsModal: RightsModalType) => void
  title: string
  type?: RightType
  workflows: WorkflowDTO[]
}

type ModalProps = {
  event: EventV2DTO
  showModal: boolean
}

export const EventsListTable: React.FC<Props> = ({
  events,
  hasDataSubjectFilter = false,
  isLoading,
  pagination,
  setRightsModal,
  title,
  type,
  workflows,
}) => {
  // hooks
  const [deleteModal, setDeleteModal] = useState<ModalProps>()
  const { dataRole } = useAppSelector(getEventsListFilterState)
  const { data: dataSubjectTypes } = useDataSubjectTypes()

  // utils
  const showEventCell =
    dataRole === DataRoleType.DATA_ROLE_PROCESSOR || hasDataSubjectFilter || dataSubjectTypes?.length === 1

  const showRightsCell =
    (dataRole === DataRoleType.DATA_ROLE_PROCESSOR || dataSubjectTypes?.length === 1) &&
    type !== RightType.RIGHT_TYPE_CUSTOM

  const showSubjectCell = dataRole !== DataRoleType.DATA_ROLE_PROCESSOR
  // Using 1st index since all the events return same custom events
  const resultingCustomEventsExist = !!events[0]?.resultingCustomEvents?.length

  const showLinkChip =
    dataRole !== DataRoleType.DATA_ROLE_PROCESSOR &&
    !hasDataSubjectFilter &&
    type !== RightType.RIGHT_TYPE_CUSTOM &&
    resultingCustomEventsExist &&
    dataSubjectTypes?.length > 1
  // Hiding rights bubble to show rights column when dataSubjectTypes.length === 1

  return (
    <Box mb={3}>
      <Box my={2}>
        <Text color="black" lineHeight={2} size={20} weight={700}>
          {title}
        </Text>

        {showLinkChip && (
          <LinkChip
            text="Rights"
            sx={{
              marginLeft: 1,
            }}
            onClick={() =>
              setRightsModal({
                showModal: true,
                canonicalRightCode: events[0]?.canonicalRightCode || '',
              })
            }
          />
        )}
      </Box>

      <ViewListLayoutContent>
        <TablePaginated
          rowIdKey="id"
          pending={isLoading}
          items={events}
          pagination={pagination}
          cellSettings={{
            event: {
              showCell: showEventCell,
              width: 350,
              label: 'Event',
              cellRenderer: ({ rightName }) => (
                <Text size={14} weight={600}>
                  {rightName}
                </Text>
              ),
            },
            rights: {
              showCell: showRightsCell,
              width: 100,
              label: 'Rights',
              cellRenderer: ({ canonicalRightCode, resultingCustomEvents }) => (
                <Box
                  color="royalBlue.main"
                  onClick={() =>
                    !!resultingCustomEvents?.length &&
                    setRightsModal({
                      showModal: true,
                      canonicalRightCode: canonicalRightCode || '',
                    })
                  }
                  sx={{
                    alignItems: 'center',
                    backgroundColor: 'marine.main',
                    borderRadius: '100%',
                    color: 'royalBlue.main',
                    cursor: 'pointer',
                    display: 'flex',
                    fontSize: 11,
                    fontWeight: 500,
                    height: '24px',
                    justifyContent: 'center',
                    width: '24px',
                  }}
                >
                  {resultingCustomEvents?.length}
                </Box>
              ),
            },
            dataSubject: {
              showCell: showSubjectCell,
              label: 'Subject Type',
              cellRenderer: ({ dataSubjectTypeName, dataSubjectTypeCode }) => (
                <NameAndCodeCellRenderer name={dataSubjectTypeName} code={dataSubjectTypeCode} shouldCapitalizeName />
              ),
            },

            workflowName: {
              label: 'Workflow Assignment',
              width: 430,
              cellRenderer: orchestrationEvent => {
                const workflowName = workflows.find(search => search.code === orchestrationEvent?.workflowCode)?.name
                return <InlineEdit orchestrationEvent={orchestrationEvent} workflowName={workflowName} />
              },
            },
            activityCount: {
              label: 'Activity Count',
              width: 150,
              cellRenderer: ({ workflowCode }) => {
                const workflowByCode = workflows.find(search => search.code === workflowCode)

                if (workflowByCode) {
                  return <Badge variant="neutral">{workflowByCode.stepCount || 0}</Badge>
                }

                return <EmptyValueRenderer color="fadedDarkGrey" />
              },
            },
            lastRun: {
              label: 'Last Run',
              cellRenderer: ({ workflowCode }) => {
                const workflowByCode = workflows.find(search => search.code === workflowCode)

                if (workflowByCode) {
                  return <FormattedDateRenderer date={workflowByCode?.lastRunAnyVersion} />
                }

                return <EmptyValueRenderer color="fadedDarkGrey" />
              },
            },
            delete: {
              cellRenderer: event =>
                event?.rightType === RightType.RIGHT_TYPE_CUSTOM && (
                  <DeleteIcon
                    onClick={() =>
                      setDeleteModal({
                        event,
                        showModal: true,
                      })
                    }
                  />
                ),
              label: '',
              width: 50,
            },
          }}
        />
      </ViewListLayoutContent>

      {deleteModal?.showModal && (
        <DeleteEventModal
          event={deleteModal?.event}
          onSubmit={() =>
            setDeleteModal({
              ...deleteModal,
              showModal: false,
            })
          }
          onCancel={() =>
            setDeleteModal({
              ...deleteModal,
              showModal: false,
            })
          }
        />
      )}
    </Box>
  )
}
