import { AssetPolicyAssetDTO } from '@ketch-com/figurehead'

export const getNamedOrLabeled = (el: AssetPolicyAssetDTO): 'named' | 'labeled' => {
  if (el.named) {
    return 'named'
  }

  if (el.labeled) {
    return 'labeled'
  }

  return 'named'
}
