import React, { useMemo, useRef, useState } from 'react'
import { Box } from '@mui/material'

import { MaybeNull } from 'interfaces/common'
import { TransponderViewActions } from './components'
import { RoutesManager } from 'utils/routing/routesManager'
import {
  Button,
  Chip,
  ContentGroup,
  DataGrid,
  InfoRow,
  PopUp,
  Spinner,
  Status,
  StatusVariant,
  theme,
  ViewLayout,
} from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { TransponderWorkListFilters } from './components/TransponderViewWorkFilters'
import { useTransponderWorkFilters } from './hooks/useTransponderWorkFilters'
import { transponderViewColumns } from './utils'
import { useApplianceWorks } from 'api/appliances/queries/useApplianceWorks'
import { Appliance, ApplianceWork } from '@ketch-com/windlass/dist/appliance/appliances'
import { createPayloadJsonString } from './utils/createPayloadJsonString'
import { ApplianceCreationPrompt } from './components/TransponderViewEmptyWorkQueue'
import { listStatusColorMapping } from './constants'
import { useInputSubjects } from './hooks/useInputSubjectOptions'

type TransponderViewProps = {
  isReady: boolean
  appliance: MaybeNull<Appliance>
}

export const TransponderView: React.FC<TransponderViewProps> = ({ isReady, appliance }) => {
  const [modalPayload, setModalPayload] = useState<ApplianceWork | null>(null)
  const [{ status, inputSubject }] = useTransponderWorkFilters()
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 30,
  })

  const applianceWorksQueryConfig = useMemo(
    () => ({
      enabled: !!appliance?.applianceID,
      params: {
        applianceId: appliance?.applianceID!,
        params: {
          filters: {
            offset: paginationModel.page * paginationModel.pageSize,
            limit: paginationModel.pageSize,
            status: status.name !== 'All Statuses' ? status.name : undefined,
            subject: inputSubject.name !== 'All Input Subjects' ? inputSubject.id : undefined,
          },
        },
      },
    }),
    [appliance?.applianceID, inputSubject, paginationModel.page, paginationModel.pageSize, status],
  )

  const { data: applianceWorksData, isLoading } = useApplianceWorks(applianceWorksQueryConfig)

  // The rowcount ref is to prevent a case where we have rowcount undefined.
  const rowCountRef = useRef(applianceWorksData?.totalCount || 0)

  const rowCount = useMemo(() => {
    if (applianceWorksData?.totalCount !== undefined) {
      rowCountRef.current = applianceWorksData.totalCount
    }
    return rowCountRef.current
  }, [applianceWorksData?.totalCount])

  const navigate = useNavigate()

  const breadcrumbs = [
    { title: 'Developers', link: RoutesManager.developers.transponders.list.getURL() },
    { title: 'Transponders', link: RoutesManager.developers.transponders.list.getURL() },
    { title: appliance?.name || appliance?.applianceID || '' },
  ]

  const { inputSubjects } = useInputSubjects({ applianceID: appliance?.applianceID })

  if (!isReady || !appliance) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  const columns = transponderViewColumns(setModalPayload)
  const modalPayloadType = !modalPayload ? null : typeof modalPayload === 'string' ? 'transponderKey' : 'applianceWork'

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      <ViewLayout
        contentGroupProps={{
          title: appliance?.name || appliance?.applianceID || '',
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.developers.transponders.list.getURL()),
          },
          infoBlockComponent: (
            <Box display="flex" alignItems="center" gap={1}>
              {appliance.Instances?.[0]?.deploymentVersion ? (
                <Chip label={`v${appliance.Instances?.[0]?.deploymentVersion}`} size="small" />
              ) : (
                <EmptyValueRenderer />
              )}{' '}
              <Status
                variant={StatusVariant.outlined}
                icon={appliance.ConnectionStatus === 'Connected' ? 'OConnectionTrue' : 'OConnectionIssue'}
                status={listStatusColorMapping[appliance.ConnectionStatus || 'Disconnected']}
                label={appliance.ConnectionStatus || 'Unknown'}
              />{' '}
            </Box>
          ),
          actionRightBlockComponent: <TransponderViewActions appliance={appliance!} />,
        }}
      >
        <Box display="flex" flexDirection="column" gap={6} mb={6}>
          <Box display="flex" flexDirection="column" gap={4}>
            <ContentGroup variant="inner-page-header" titleVariant="h3" title="Details" />
            <InfoRow title="URL">{appliance.url}</InfoRow>
            <InfoRow title="Deployment Method">{appliance.Instances?.[0].deploymentType}</InfoRow>
          </Box>

          <Box display="flex" flexDirection="column" gap={4}>
            <ContentGroup
              variant="inner-page-header"
              titleVariant="h3"
              title="Queue"
              subTitle="The data in the queue will be present only for the last 30 days.  After that it gets purged."
              actionBottomBlockComponent={
                <TransponderWorkListFilters
                  inputSubjects={inputSubjects || []}
                  setPaginationModel={setPaginationModel}
                />
              }
            />
            {!isLoading && !applianceWorksData?.ApplianceWork ? (
              <ApplianceCreationPrompt />
            ) : (
              <DataGrid
                sx={{
                  '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                    backgroundImage: 'unset',
                    cursor: 'auto',
                  },
                  ...(isLoading && {
                    '& .MuiDataGrid-virtualScroller': {
                      height: '300px',
                    },
                  }),
                }}
                autosizeOnMount
                autosizeOptions={{
                  expand: true,
                  includeHeaders: true,
                  includeOutliers: false,
                }}
                getRowHeight={() => 'auto'}
                columns={columns}
                getRowId={row => row.id}
                rows={applianceWorksData?.ApplianceWork || []}
                checkboxSelection={false}
                hideFooter={false}
                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnPinning
                disableColumnReorder
                disableColumnResize
                disableChildrenSorting={false}
                loading={isLoading}
                pagination
                paginationMode="server"
                rowCount={rowCount}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[30]}
              />
            )}
          </Box>
        </Box>
        {modalPayload && (
          <PopUp
            onClose={() => setModalPayload(null)}
            variant="modal"
            popUpWidth="900px"
            title="Payload"
            subTitle={
              modalPayloadType === 'transponderKey'
                ? 'Within your transponder, click Add Organization and paste this key as the Transponder Key'
                : `Work ID: ${modalPayload.applianceID}`
            }
            popUpActionChildren={
              <Button color="primary" size="large" onClick={() => setModalPayload(null)}>
                Close
              </Button>
            }
          >
            <Box
              whiteSpace="pre-wrap"
              padding={2}
              borderRadius={3}
              sx={{ backgroundColor: theme.palette.Common.AppBackground }}
            >
              {createPayloadJsonString(modalPayload)}
            </Box>
            )
          </PopUp>
        )}
      </ViewLayout>
    </>
  )
}
