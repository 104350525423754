export const transponderHelmInstructions = `
The following tutorials cover installing the Transponder to a Kubernetes cluster via Helm chart.

### Basic Installation
The following tutorial covers installing the Transponder to a Kubernetes cluster.

- [Deploying Transponder to a Local Kubernetes](https://docs.ketch.com/ketch/docs/deploying-transponder-to-a-local-kubernetes)
- [Deploying Transponder to an EKS with Fargate](https://docs.ketch.com/ketch/docs/deploying-transponder-to-an-eks-with-fargate)

### Secrets Providers
The following tutorials cover using different secret providers for the Transponder.

- [Using AWS Secrets Manager as the Secret Provider](https://docs.ketch.com/ketch/docs/using-aws-secrets-manager-as-the-secret-provider)
- [Using Hashicorp Vault as the Secret Provider](https://docs.ketch.com/ketch/docs/using-hashicorp-vault-as-the-secret-provider)
- [Using Azure Key Vault as the Secret Provider](https://docs.ketch.com/ketch/docs/using-azure-key-vault-as-the-secret-provider)

### Load Balancers
The following tutorials cover using different load balancers for the Transponder.

- [Using AWS Load Balancer Controller with Transponder](https://docs.ketch.com/ketch/docs/using-aws-load-balancer-controller-with-transponder)
- [Using Nginx Ingress Controller with Transponder](https://docs.ketch.com/ketch/docs/using-nginx-ingress-controller-with-transponder)
- [Using Azure Load Balancer with Transponder](https://docs.ketch.com/ketch/docs/using-azure-load-balancers-with-transponder)
- [Using Google Cloud Load Balancer with Transponder](https://docs.ketch.com/ketch/docs/using-google-cloud-load-balancers-with-transponder)
- [Using Cert-Manager with Transponder](https://docs.ketch.com/ketch/docs/using-cert-manager-with-transponder)
`
