import React from 'react'

import {
  WorkflowExecutionStepStatus,
  WORKFLOW_EXECUTION_STEP_NAMES,
} from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { IssueDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { hasInvalidCredentialsIssue } from 'pages/orchestration/rightsQueue/utilsV2'
import { DataReviewStatusEnum } from 'interfaces/dataReview'
import { Status, StatusState, StatusVariant } from '@ketch-com/deck'

type Props = {
  dataReviewStatus?: number
  issues?: IssueDTO[]
  status: WorkflowExecutionStepStatus
}

const variantMap = {
  [WorkflowExecutionStepStatus.PENDING]: StatusState.draft,
  [WorkflowExecutionStepStatus.IN_PROGRESS]: StatusState.inProgress,
  [WorkflowExecutionStepStatus.SUCCESS]: StatusState.success,
  [WorkflowExecutionStepStatus.FAIL]: StatusState.error,
  [WorkflowExecutionStepStatus.INVALID_CREDENTIALS]: StatusState.warning,
  [WorkflowExecutionStepStatus.TERMINATED]: StatusState.error,
  [WorkflowExecutionStepStatus.SKIPPED]: StatusState.draft,
  [WorkflowExecutionStepStatus.NOT_STARTED]: StatusState.draft,
  [WorkflowExecutionStepStatus.MIXED]: StatusState.draft,
} as const

export const WorkflowExecutionStepStatusRendererLegacy: React.FC<Props> = ({ dataReviewStatus, status, issues }) => (
  <Status
    icon="Dot"
    label={
      hasInvalidCredentialsIssue(status, issues)
        ? WORKFLOW_EXECUTION_STEP_NAMES[WorkflowExecutionStepStatus.INVALID_CREDENTIALS]
        : dataReviewStatus === DataReviewStatusEnum.DATA_REVIEW_STATUS_SUBMITTED
        ? 'Pending Review'
        : WORKFLOW_EXECUTION_STEP_NAMES[status]
    }
    status={
      hasInvalidCredentialsIssue(status, issues)
        ? variantMap[WorkflowExecutionStepStatus.INVALID_CREDENTIALS]
        : variantMap[status]
    }
    variant={StatusVariant.outlined}
  />
)
