import { APIListRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { FetchStacksResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIListRequestParams<{
  purposeCode?: string
}>

export const fetchStacks = ({ includeMetadata, purposeCode }: Params) =>
  API.get<FetchStacksResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/stacks`,
      params: {
        filters: {
          includeMetadata,
          ...(purposeCode && {
            purposeCode,
          }),
        },
      },
    }),
  )
