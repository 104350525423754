import React from 'react'
import { showToast } from 'components/modals/AlertComponent'
import { useParams, useNavigate } from 'react-router-dom'
import { useLegalBases } from 'api/legalBases/queries/useLegalBases'
import { EditSubscriptionTopic } from 'pages/policyCenter/subscriptions/subscriptionTopic/upsert/edit/EditSubscriptionTopic'
import { CreateSubscriptionTopic } from 'pages/policyCenter/subscriptions/subscriptionTopic/upsert/create/CreateSubscriptionTopic'
import { UrlParams } from 'pages/assetManager/dsrConfig/interfaces'
import { FormMode } from 'interfaces/formModes/formMode'
import { SubscriptionTopicFormData } from 'pages/policyCenter/subscriptions/interfaces'
import {
  SubscriptionTopicDTO,
  PutSubscriptionTopicRequestBodyDTO,
  PutSubscriptionTopicOrchestrationsRequestBodyDTO,
  SubscriptionTopicOrchestrationDTO,
  SubscriptionOrchestrationAppDTO,
  SubscriptionOrchestrationWebhookDTO,
} from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { useSubscriptionTopic } from 'api/subscriptions/queries/useSubscriptionTopic'
import { useSubscriptionTopicOrchestrations } from 'api/subscriptions/queries/useSubscriptionTopicOrchestrations'
import { useOrganizationLanguagesPaginated } from 'api/languages/queries/useOrganizationLanguagesPaginated'
import { useUpdateSubscriptionTopic } from 'api/subscriptions/mutations/useUpdateSubscriptionTopic'
import { useCreateSubscriptionTopic } from 'api/subscriptions/mutations/useCreateSubscriptionTopic'
import { useSubscriptionContactMethodsPaginated } from 'api/subscriptions/queries/useSubscriptionContactMethodsPaginated'
import { useWebhooks } from 'api/webhooks/queries/useWebhooks'
import { useSystems } from 'api/apps/queries/useSystems'
import { useUpdateSubscriptionTopicOrchestrations } from 'api/subscriptions/mutations/useUpdateSubscriptionTopicOrchestrations'

export const UpsertSubscriptionTopicContainer: React.FC = () => {
  const subscriptionTopicOrchestration = { orchestrations: {} } as PutSubscriptionTopicOrchestrationsRequestBodyDTO
  const navigate = useNavigate()
  const { formMode, code } = useParams<UrlParams>()
  const isEditMode = formMode === FormMode.EDIT

  const { data: legalBases, isLoading: isLegalBasesLoading } = useLegalBases({
    onError: () => {
      showToast({ content: 'Failed to fetch legal basis', type: 'error' })
    },
  })
  const { data: subscriptionTopic, isLoading: isSubscriptionTopicLoading } = useSubscriptionTopic({
    params: { code },
    onError: () => {
      showToast({ content: 'Failed to fetch subscriptions', type: 'error' })
    },
    enabled: isEditMode,
  })

  const { data: subscriptionTopicOrchestrations, isLoading: isSubscriptionTopicOrchestrationsLoading } =
    useSubscriptionTopicOrchestrations({
      params: { code },
      onError: () => {
        showToast({ content: 'Failed to fetch subscription orchestrations', type: 'error' })
      },
      enabled: isEditMode,
    })

  const { data: organizationLanguages, isLoading: isOrganizationLanguagesLoading } = useOrganizationLanguagesPaginated({
    params: {
      includeMetadata: true,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch organization languages', type: 'error' })
    },
  })

  const { data: subscriptionContactMethods, isLoading: isSubscriptionContactMethodsLoading } =
    useSubscriptionContactMethodsPaginated({
      params: {},
      onError: () => {
        showToast({ content: 'Failed to fetch contact methods', type: 'error' })
      },
    })

  const { data: webhooksData = [], isLoading: isLoadingWebhooksData } = useWebhooks({
    itemsPerPage: 100,
    params: { event: 'subscriptions' },
    onError: () => {
      showToast({ content: 'Failed to fetch subscription webhooks', type: 'error' })
    },
  })

  const { data: systemsData, isLoading: isLoadingSystemsData } = useSystems({
    itemsPerPage: 100,
    params: { event: 'subscriptions' },
    onError: () => {
      showToast({ content: 'Failed to fetch subscription systems', type: 'error' })
    },
  })

  const { mutate: updateSubscriptionTopicOrchestrations } = useUpdateSubscriptionTopicOrchestrations({
    onSuccess: () => {
      showToast({ content: 'Saved subscription orchestrations', type: 'success' })
      navigate(RoutesManager.policyCenter.subscriptions.list.subscriptionTopics.root.getURL())
    },
    onError: () => {
      showToast({ content: 'Unable to save subscription orchestrations', type: 'success' })
    },
  })

  const { mutate: createSubscriptionTopic } = useCreateSubscriptionTopic({
    onSuccess: ({ data }) => {
      showToast({ content: 'Created Subscription', type: 'success' })
      updateSubscriptionTopicOrchestrations({
        params: { data: subscriptionTopicOrchestration, code: data?.topic?.code || '' },
      })
    },
    onError: () => {
      showToast({ content: 'Unable to create subscription', type: 'success' })
    },
  })

  const { mutate: updateSubscriptionTopic } = useUpdateSubscriptionTopic({
    onSuccess: () => {
      showToast({ content: 'Saved Subscription', type: 'success' })
      updateSubscriptionTopicOrchestrations({
        params: { data: subscriptionTopicOrchestration, code: code || '' },
      })
    },
    onError: () => {
      showToast({ content: 'Unable to save subscription', type: 'success' })
    },
  })

  const onSubmit = (formikValues: SubscriptionTopicFormData) => {
    const { basicDetails, contactMethods, legalBasis } = formikValues

    let channels: string[] = []
    for (const [channel, { status }] of Object.entries(contactMethods)) {
      if (status) channels.push(channel)
    }

    const data: PutSubscriptionTopicRequestBodyDTO = {
      topic: {
        code: basicDetails.code || '',
        name: basicDetails.name,
        description: basicDetails.description,
        contactMethods: channels,
        legalBasisCode: legalBasis.code,
        translations: basicDetails.translations,
      } as SubscriptionTopicDTO,
    }

    for (const [channel, { status, orchestrations }] of Object.entries(contactMethods)) {
      if (status && subscriptionTopicOrchestration.orchestrations) {
        subscriptionTopicOrchestration.orchestrations[channel] = orchestrations.map(orchestration => {
          if (orchestration?.appInstanceId) {
            return {
              app: {
                code: orchestration?.appCode,
                instanceId: orchestration?.appInstanceId,
                params: orchestration?.appParams,
                appName: orchestration?.name,
                logoUrl: orchestration?.appLogo?.url,
                connectionName: orchestration?.appInstanceName,
              } as SubscriptionOrchestrationAppDTO,
            } as SubscriptionTopicOrchestrationDTO
          }

          return {
            webhook: {
              id: orchestration?.webhookId,
              name: orchestration?.name,
            } as SubscriptionOrchestrationWebhookDTO,
          } as SubscriptionTopicOrchestrationDTO
        })
      }
    }

    if (isEditMode) updateSubscriptionTopic({ params: { data, code: basicDetails.code } })
    else createSubscriptionTopic({ params: { data } })
  }

  const isReady =
    !isLegalBasesLoading &&
    !isSubscriptionTopicLoading &&
    !isOrganizationLanguagesLoading &&
    !isSubscriptionContactMethodsLoading &&
    !isLoadingWebhooksData &&
    !isLoadingSystemsData &&
    !isSubscriptionTopicOrchestrationsLoading

  return isEditMode ? (
    <EditSubscriptionTopic
      isReady={isReady}
      onSubmit={onSubmit}
      legalBases={legalBases}
      subscriptionTopic={subscriptionTopic?.topic || ({} as SubscriptionTopicDTO)}
      organizationLanguages={organizationLanguages}
      subscriptionContactMethods={subscriptionContactMethods?.contactMethods || []}
      webhooksData={webhooksData}
      systemsData={systemsData?.apps || []}
      subscriptionTopicOrchestrations={subscriptionTopicOrchestrations || {}}
    />
  ) : (
    <CreateSubscriptionTopic
      isReady={isReady}
      onSubmit={onSubmit}
      legalBases={legalBases}
      organizationLanguages={organizationLanguages}
      subscriptionContactMethods={subscriptionContactMethods?.contactMethods || []}
      webhooksData={webhooksData}
      systemsData={systemsData?.apps || []}
    />
  )
}
