import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { TooltipButton } from '@ketch-com/deck'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'

type Props = { workflowStep: WorkflowExecutionStepDetailsDTO | null; resolve: () => void }
export const DecisionStepDetailsHeaderActions: React.FC<Props> = ({ workflowStep, resolve }) => {
  const { isAssignedToMe } = useContext(RightInvocationWorkflowStepDetailViewContext)
  const tooltipTitle = isAssignedToMe ? '' : 'Resolve is only available for assignees.'
  const isResolveDisabled = !isAssignedToMe

  return (
    <Box display="flex" gap={1.5} alignItems="center" minHeight={44}>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="smallBody" color="darkDuskFaded.main">
          Last Updated
        </Typography>
        <Typography color="darkDusk.main" variant="smallBody">
          <FormattedDateRenderer date={workflowStep?.completedAt} />
        </Typography>
      </Box>

      {workflowStep?.status !== WorkflowExecutionStepStatus.SUCCESS && (
        <TooltipButton size="large" color="primary" onClick={resolve} disabled={isResolveDisabled} title={tooltipTitle}>
          Resolve
        </TooltipButton>
      )}
    </Box>
  )
}
