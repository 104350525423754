import { useCallback, useContext, useMemo } from 'react'
import { ImportContextData } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/ImportContext/context'
import { Box, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { IImportContextParams } from 'pages/orchestration/workflows/edit/interfaces'
import { ListItemText, ActionSheetItem, ChipsAutocomplete, ChipsAutocompleteOptionType } from '@ketch-com/deck'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { renderSelectedMultipleIdentityChips } from 'pages/utils/renderSelectedMultipleIdentityChips'

export const SelectFromIdentitySpace: React.FC = () => {
  const { fromIdentitySpaces, isLoadingFromIdentitySpaces } = useContext(ImportContextData)
  const { values, setFieldValue, isSubmitting } = useFormikContext<IImportContextParams>()

  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isMultipleIdentitiesFlagEnabled = !isFeatureFlagEnabled(
    FEATURE_FLAGS.IMPORT_CONTEXT,
    FEATURE_FLAGS_VALUES.IMPORT_CONTEXT_SINGLE_IDENTITY,
  )

  const handleSelectOption = useCallback(
    (identity: IdentitySpaceDTO | ChipsAutocompleteOptionType | ChipsAutocompleteOptionType[] | null) => {
      if (!identity) return
      if (isMultipleIdentitiesFlagEnabled && Array.isArray(identity)) {
        const identities = identity.map(i => ({ name: i?.label || i?.value, code: i?.value }))
        setFieldValue('identitiesFrom', identities)
      } else {
        const idSpaceDtoIdentity = identity as IdentitySpaceDTO
        setFieldValue('identityFromName', idSpaceDtoIdentity?.name)
        setFieldValue('identityFromCode', idSpaceDtoIdentity?.code)
      }
    },
    [isMultipleIdentitiesFlagEnabled, setFieldValue],
  )

  const handleDeselectValue = useCallback(
    (chipData: ChipsAutocompleteOptionType) => {
      const filteredIdentitesFrom = values.identitiesFrom?.filter(identity => identity.code !== chipData.value)
      setFieldValue('identitiesFrom', filteredIdentitesFrom)
    },
    [setFieldValue, values.identitiesFrom],
  )
  const options: ChipsAutocompleteOptionType[] = useMemo(
    () => fromIdentitySpaces.map(identity => ({ value: identity.code, label: identity.name })) || [],
    [fromIdentitySpaces],
  )
  const chipValues = useMemo(
    () => values.identitiesFrom?.map(identity => ({ value: identity.code, label: identity.name })) || [],
    [values.identitiesFrom],
  )

  const renderChips = useMemo(() => renderSelectedMultipleIdentityChips(handleDeselectValue), [handleDeselectValue])

  if (isMultipleIdentitiesFlagEnabled) {
    return (
      <ChipsAutocomplete
        chipSize="small"
        fullWidth
        inRowChips
        options={options}
        filterSelectedOptions={false}
        label="Lookup Identifiers"
        loading={isLoadingFromIdentitySpaces}
        getOptionLabel={option => option?.label || ''}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        onChange={(e, identity) => {
          handleSelectOption(identity)
        }}
        renderChipItem={renderChips}
        disabled={isSubmitting}
        handleChipClick={handleDeselectValue}
        placeholder={
          chipValues.length ? `${chipValues.length} Selected Identifiers` : 'Start typing an Identifier name'
        }
        value={chipValues}
      />
    )
  }
  return (
    <Box width={214}>
      <FormDroplistButton
        label="Lookup Identifier"
        disableClearable
        required
        formPropertyName="identityFromCode"
        onItemClick={handleSelectOption}
        size="small"
        valueKey="code"
        placeholder="Select Identity"
        items={fromIdentitySpaces}
        getOptionLabel={option => option?.name || ''}
        isOptionEqualToValue={(option, value) => option?.code === value?.code}
        getOptionDisabled={option => option?.code === values?.code}
        renderOption={(props, option, _, isSelected) => {
          return (
            <ActionSheetItem {...props} key={option.code} selected={isSelected}>
              <ListItemText selected={isSelected}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography alignSelf="flex-start" variant="body">
                    {option?.name}
                  </Typography>
                  <Typography alignSelf="flex-start" variant="footnote" color="darkDuskFaded.main">
                    {option?.code}
                  </Typography>
                </Box>
              </ListItemText>
            </ActionSheetItem>
          )
        }}
      />
    </Box>
  )
}
