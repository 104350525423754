import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { DeleteNotFoundTrackersResponseBody } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type Params = {
  encodedTrackerKey: string
}

export const deleteNotFoundTracker = ({ encodedTrackerKey }: Params) =>
  API.delete<DeleteNotFoundTrackersResponseBody>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/tracker/${encodedTrackerKey}/not-found`,
    }),
  )
