import { EmptyState, theme } from '@ketch-com/deck'
import { memo, useCallback } from 'react'
import { ReactComponent as TransponderCreateIcon } from 'assets/icons/emptyState/EmptyStateApplicationsList.svg'
import { useVisitTransponderDocumentation } from '../../hooks/useTransponderDocumentation'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

export const ApplianceCreationPrompt: React.FC = memo(() => {
  const navigate = useNavigate()

  const handleVisitTransponderDocumentation = useVisitTransponderDocumentation()
  const handleDocumentationButtonClick = useCallback(() => {
    handleVisitTransponderDocumentation()
  }, [handleVisitTransponderDocumentation])

  return (
    <EmptyState
      title="The Ketch Transponder"
      subTitle="Ketch Transponder is the Ketch secure service deployed in your cloud infrastructure.  It interacts with your data systems to facilitate various functionalities such as discovery, classification, DSRs, etc., without Ketch needing direct access to your system credentials."
      primaryButtonTitle="Create Transponder"
      customIcon={<TransponderCreateIcon />}
      primaryButtonProps={{
        onClick: () => {
          navigate(RoutesManager.developers.transponders.create.getURL())
        },
      }}
      secondaryButtonTitle="Read Documentation"
      secondaryButtonProps={{
        onClick: handleDocumentationButtonClick,
      }}
      sx={{
        borderRadius: 4,
        border: `1px solid ${theme.palette.Common.Divider}`,
      }}
    />
  )
})
