import { TransponderCreationProvider } from './context'
import { TranspondersCreate } from './TranspondersCreate'

export const TranspondersCreateContainer: React.FC = () => {
  return (
    <TransponderCreationProvider>
      <TranspondersCreate />
    </TransponderCreationProvider>
  )
}
