import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { validateTransponderName } from '../fetchers/validateTransponderName'

const useCustomMutation = createUseMutation({
  mutationFn: validateTransponderName,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useValidateTransponderName = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
