import { APIListRequestParams } from 'api/common/utils'
import { ListInstalledDataSystemV2AssetsResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export type GetInstalledDataSystemAssetsParams = APIListRequestParams<{
  id?: string
  filters?: Record<string, string[] | string | undefined>
}>

export const getInstalledDataSystemAssets = ({ id, filters }: GetInstalledDataSystemAssetsParams) => {
  return API.get<ListInstalledDataSystemV2AssetsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/installed-data-systems/${id}/assets`,
      params: {
        filters,
      },
    }),
  )
}
