import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Box } from '@mui/material'
import { Spinner } from '@ketch-com/deck'
import { ApiQueryKeys } from 'api/common/queryKeys'

// This component is designed to be defensive against race conditions where the new steps are clicked before the workflow execution re-loads.
// It is intended to be used as a fallback state when the new step cannot yet be found.
export const RetryLoadingState: React.FC<{}> = () => {
  const [retryCount, setRetryCount] = useState(1)
  const queryClient = useQueryClient()

  const refetch = async () => {
    if (retryCount > 3) return

    await queryClient.refetchQueries(ApiQueryKeys.workflowExecutionV2)

    setRetryCount(retryCount + 1)
  }

  useEffect(() => {
    const intervalId = setInterval(refetch, 1000)
    return () => clearInterval(intervalId)

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    </>
  )
}
