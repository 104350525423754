import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import React from 'react'
import { useStyles } from '../hooks/useStyles'

type HeadingProps = {
  formTitle: string
  formSubtitle: string
  formSecondSubtitle?: string
}

export const RegistrationHeading = ({ formTitle, formSubtitle, formSecondSubtitle }: HeadingProps) => {
  const classes = useStyles()

  return (
    <Box mb={4}>
      <Box mb={2.25}>
        <Text className={classes.formTitle}>{formTitle}</Text>
      </Box>
      <Box>
        <Text className={classes.formSubtitle}>{formSubtitle}</Text>
      </Box>
      {formSecondSubtitle ? (
        <Box>
          <Text className={classes.formSubtitle}>{formSecondSubtitle}</Text>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  )
}
