import React from 'react'
import Box from '@mui/material/Box'

import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { optionsArray } from '../utils'
import { IEndFlowActivityParams } from 'pages/orchestration/workflows/edit/interfaces'
import { useFormikContext } from 'formik'
import { WorkflowDTO } from 'interfaces/workflows'
import { isTwilioConversationsEnabled } from 'utils/helpers/isTwilioConversationsEnabled'
import { FormCheckbox } from 'components/form/FormCheckbox'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { Button } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { FormAssigneeOrTeamSelector } from 'components/form/FormAssigneeOrTeamSelector'

type Props = {
  appealWorkflows: WorkflowDTO[]
}

export const ManualForm: React.FC<Props> = ({ appealWorkflows }) => {
  const { values, setFieldValue } = useFormikContext<IEndFlowActivityParams>()
  const navigate = useNavigate()
  const subjectCommunicationEnabled = isTwilioConversationsEnabled()

  return (
    <>
      <FormCheckbox
        onChange={(e, checked) => {
          setFieldValue('allowAppeal', checked)
          if (!checked) setFieldValue('appealWorkflowDefinitionCode', '')
        }}
        name="allowAppeal"
        label="Allow Appeal"
        inlineLabel
        sx={{ mb: 2 }}
      />

      {/* If allow appeal is checked, display Appeal Workflow dropdown */}

      {values.allowAppeal && (
        <>
          <Box sx={{ mb: 2, maxWidth: 374 }}>
            <FormDroplistButton
              fullWidth
              formPropertyName="appealWorkflowDefinitionCode"
              label="Appeal Workflow"
              placeholder="Select workflow"
              valueKey="code"
              labelTooltipText="Same-type workflows only. Nested appeal workflows won't run if included in selected workflow."
              items={appealWorkflows}
              hideOptionalLabel
              disableClearable
            />
          </Box>

          <Typography variant="body" display="block" component="div">
            Data Subjects in certain Jurisdictions can appeal rejected requests. Configure appeal time settings in
            Privacy Policy <span>&rarr;</span>{' '}
            <Button
              variant="link"
              color="secondary"
              onClick={() => navigate(RoutesManager.policyCenter.policyScopes.list.root.getURL())}
            >
              <Typography variant="label">Jurisdictions</Typography>
            </Button>
            .
          </Typography>
        </>
      )}

      <FormAssigneeOrTeamSelector label="Assignee" name="assignee" />

      <Box mb={3}>
        <FormInput
          type="number"
          formPropertyName="timeToCompleteDays"
          label="Time To Complete (Days)"
          placeholder="0"
          inputWidth="80px"
          alwaysShowError
        />
      </Box>

      {/* Subject Communication Section */}

      <Typography variant="h4" color="darkDuskFaded.main">
        Subject Communication
      </Typography>

      {subjectCommunicationEnabled ? (
        <>
          <Box mb={2} mt={2}>
            <FormRadioGroup
              label="Message via Subject Communication"
              hideOptionalLabel
              row
              formPropertyName="manualMessage"
              renderLabel={({ name }) => name}
              options={optionsArray}
              valueKey="id"
            />
          </Box>
        </>
      ) : null}

      <Box mb={2} mt={subjectCommunicationEnabled ? undefined : 2}>
        <FormRadioGroup
          formPropertyName="manualCoverLetter"
          renderLabel={({ name }) => name}
          options={optionsArray}
          valueKey="id"
          hideOptionalLabel
          label="Cover Letter Attachment"
          row
        />
      </Box>
    </>
  )
}
