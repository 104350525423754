import React, { CSSProperties } from 'react'
import Konva from 'konva'
import { round } from 'lodash'

import { MaybeNull } from 'interfaces/common'
import { ICoordinates } from 'pages/orchestration/workflows/edit/interfaces'

interface ICanvasDropWrapper {
  children?: React.ReactNode
  x: number
  y: number
  scale: number
  stage: MaybeNull<Konva.Stage>
  handleDropComplete: (coordinates: ICoordinates) => void
  style?: CSSProperties
}

export const CanvasDropWrapper: React.FC<ICanvasDropWrapper> = ({
  x,
  y,
  stage,
  scale,
  handleDropComplete,
  children,
  style,
}) => {
  const handleItemDrop = (event: React.DragEvent<HTMLDivElement>) => {
    stage!.setPointersPositions(event)
    const stagePointerPosition = stage!.getPointerPosition()!

    handleDropComplete({
      x: round((stagePointerPosition.x - x) / scale),
      y: round((stagePointerPosition.y - y) / scale),
    })
  }

  return (
    <div style={style} onDrop={handleItemDrop} onDragOver={e => e.preventDefault()}>
      {children}
    </div>
  )
}
