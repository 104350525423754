import React from 'react'
import Grid from '@mui/material/Grid'
import { FormRow } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { Box } from '@mui/material'

export const ApiKeyFormSectionDetails: React.FC = () => (
  <FormRow title="Basic Details">
    <Box display="flex" flexDirection="column" gap={3}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormInput
            required
            fullWidth
            label="Name"
            formPropertyName="name"
            placeholder="Example: Facebook"
            shouldUpdateDebounced
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormInput
            required
            fullWidth
            label="Description"
            formPropertyName="description"
            placeholder="Example: Facebook integration api key."
            multiline
            rows={3}
            shouldUpdateDebounced
          />
        </Grid>
      </Grid>
    </Box>
  </FormRow>
)
