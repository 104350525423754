import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { TabSegmented, TabSegmentedGroup } from '@ketch-com/deck'
import Typography from '@mui/material/Typography'

type Props = {}

enum TestValues {
  one,
  two,
  three,
  four,
}

export const SegmentTabsExample: React.FC<Props> = props => {
  const [alignment, setAlignment] = React.useState(TestValues.one)

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: TestValues) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment)
    }
  }
  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Stack spacing={2} alignItems="center">
          <TabSegmentedGroup value={alignment} exclusive onChange={handleChange} aria-label="Small sizes" size="small">
            <TabSegmented value={TestValues.one} key="1">
              Button1!
            </TabSegmented>
            <TabSegmented value={TestValues.two} key="2">
              Button2!
            </TabSegmented>
            <TabSegmented value={TestValues.three} key="3">
              Button3!
            </TabSegmented>
            <TabSegmented value={TestValues.four} key="4" icon="FArrowCDown" />
          </TabSegmentedGroup>
        </Stack>

        <Typography variant="label">
          size="small" <br /> icon='FArrowCDown'
        </Typography>
      </Box>
    </Box>
  )
}
