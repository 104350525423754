import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useFetchCustomRightName } from 'api/customRightNames/queries/useFetchCustomRightName'
import { Button, DataGrid, PopUp, Spinner, TableCell } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  onClose: () => void
  id: string
  handleDeleteCrn: any
}
export const DeleteCustomRightNameModal: React.FC<Props> = ({ onClose, id, handleDeleteCrn }) => {
  const { data, isLoading } = useFetchCustomRightName({
    params: {
      id: id || '',
    },
  })

  const rows =
    data.jurisdictionRights?.map((jurisdiction, index) => ({
      id: index,
      right: jurisdiction.rightName,
      jurisdiction: jurisdiction.jurisdictionName,
      jurisdictionCode: jurisdiction.jurisdictionCode,
    })) || []

  const cols = [
    {
      width: 165,
      field: 'right',
      headerName: 'Right',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell sx={{ marginTop: '5px', marginBottom: '5px' }}>
            <Typography variant="label">{params?.row?.right}</Typography>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 140,
      field: 'Jurisdiction',
      headerName: 'Jurisdiction',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return <NameAndCodeCellRenderer code={params.row.jurisdictionCode} name={params.row.jurisdiction} />
      },
      sortable: false,
    },
    {
      width: 180,
      field: 'Link',
      headerName: '',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell
            isRightAlign
            sx={{
              left: '26px',
              position: 'relative',
            }}
          >
            {params?.row?.right ? (
              <Button
                color="tertiary"
                onClick={() => {
                  window.open(
                    RoutesManager.policyCenter.policyScopes.view.root.getURL({ code: params.row.jurisdictionCode }),
                    '_blank',
                    'noreferrer',
                  )
                }}
              >
                Open In New Tab
              </Button>
            ) : null}
          </TableCell>
        )
      },
      sortable: false,
    },
  ]
  return (
    <>
      {!data.jurisdictionRights ? (
        <PopUp
          title="Delete Custom Right Name"
          variant="dialog"
          popUpWidth="550px"
          onClose={onClose}
          popUpActionChildren={
            <>
              <Button color="secondary" size="large" onClick={onClose}>
                Close
              </Button>
              <Button
                color="primary"
                size="large"
                onClick={() =>
                  handleDeleteCrn({
                    params: {
                      id,
                    },
                  })
                }
              >
                Delete
              </Button>
            </>
          }
        >
          {isLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
              <Spinner size="32px" thickness={2.5} />
            </Box>
          ) : (
            <Typography variant="body" component={Box}>
              Are you sure you want to delete{' '}
              <Typography variant="label" color="darkDusk.main">
                {data.name}
              </Typography>
              ?
            </Typography>
          )}
        </PopUp>
      ) : (
        <PopUp
          title="Delete Custom Right Name"
          variant="dialog"
          popUpWidth="550px"
          onClose={onClose}
          popUpActionChildren={
            <>
              <Button color="secondary" size="large" onClick={onClose}>
                Close
              </Button>
            </>
          }
        >
          {isLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
              <Spinner size="32px" thickness={2.5} />
            </Box>
          ) : (
            <>
              <Typography variant="body" component={Box}>
                <Typography variant="label" color="darkDusk.main">
                  {data.name}
                </Typography>
                {` is currently in use. Before deleting, please replace it with another name in the following Jurisdiction${
                  data.jurisdictionRights.length > 1 ? 's' : ''
                }.`}
              </Typography>

              <DataGrid
                columns={cols}
                rows={rows}
                sx={{ paddingLeft: 0, paddingRight: 0 }}
                hidePaginationRowsInfo={true}
                disableColumnResize
                disableColumnFilter
                disableColumnReorder
                disableColumnPinning
                disableAggregation
                disableRowGrouping
                hideFooter
                disableColumnMenu
                disableRowHoverStates
                disableRowSelectionOnClick
                getRowHeight={() => 'auto'}
              />
            </>
          )}
        </PopUp>
      )}
    </>
  )
}
