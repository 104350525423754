import React, { PropsWithChildren, useContext } from 'react'
import { Box, styled } from '@mui/material'
import { NavigationHeader } from './components/navigationHeader/NavigationHeader'
import { NavigationSidebar } from './components/navigationSidebar/NavigationSidebar'
import { AppNavigationContext, withAppNavigationContext } from './context/AppNavigationContext'
import { CreateOverlay } from './components/createOverlay/CreateOverlay'
import { NotEntitledModal } from './components/NotEntitledModal'
import { NotPermittedModal } from './components/NotPermittedModal'

const NavigationBoxNew = styled(Box)({
  display: 'flex',
  flexDirection: 'column',

  // Navigation box fills the entire screen
  height: '100%',
})

const ContentWrapperBox = styled(Box)({
  display: 'flex',

  // The sidebar and page content fill the entire screen minus 44px for the header
  height: 'calc(100% - 44px)',
  maxWidth: '100%',
})

const ContentBox = styled(Box)({
  display: 'flex',
  width: '100%',
  overflowY: 'auto',
})

const AppNavigationWithoutContext: React.FC<PropsWithChildren> = ({ children }) => {
  const { showCreateOverlay, showNotPermittedModal, showNotEntitledModal, appContentRef } =
    useContext(AppNavigationContext)

  return (
    <>
      {/* App */}
      <NavigationBoxNew id="app-navigation">
        {/* Header */}
        <NavigationHeader />

        <ContentWrapperBox id="app-content-wrapper">
          {/* Sidebar */}
          <NavigationSidebar />

          {/* App content */}
          <ContentBox id="app-content" ref={appContentRef}>
            {children}
          </ContentBox>
        </ContentWrapperBox>
      </NavigationBoxNew>

      {/* Create Overlay */}
      {showCreateOverlay && <CreateOverlay />}

      {/* Not permitted modal */}
      {showNotPermittedModal && <NotPermittedModal />}

      {/* Not entitled modal */}
      {showNotEntitledModal && <NotEntitledModal />}
    </>
  )
}

export const AppNavigation = withAppNavigationContext(AppNavigationWithoutContext)
