export const LOGICAL_OP = {
  AND: 'AND',
  OR: 'OR',
  NOT: 'AND NOT',
} as const

export enum OperationTypes {
  COHORTS = 'COHORTS',
  CONSENT = 'CONSENT',
  EVENTS = 'EVENTS',
  PAGE_ATTR = 'PAGE_ATTR',
}
export const OperationTypesWithDesc: Record<OperationTypes, string> = {
  [OperationTypes.COHORTS]: 'CDP Audiences',
  [OperationTypes.CONSENT]: 'Consent State',
  [OperationTypes.EVENTS]: 'JS Events',
  [OperationTypes.PAGE_ATTR]: 'Variables On Page',
} as const

export type OperationStruct = Record<
  string,
  { key: string; label: string; detailLabel?: string; detailHelperText?: string }
>
export const Operation: OperationStruct = {
  CLICK: { key: 'CLICK', label: 'On Click', detailLabel: 'Element ID' },
  HOVER: { key: 'HOVER', label: 'On Hover', detailLabel: 'Element ID' },
  BLUR: { key: 'BLUR', label: 'On Blur', detailLabel: 'Element ID' },
  CUSTOM: { key: 'CUSTOM', label: 'Custom Event', detailLabel: 'Event Name' },
  OPT_IN: { key: 'OPT_IN', label: 'When Opted In', detailLabel: 'Purpose' },
  OPT_OUT: { key: 'OPT_OUT', label: 'When Opted Out', detailLabel: 'Purpose' },
  GENERAL_VARIABLE: {
    key: 'GENERAL_VARIABLE',
    label: 'In General Variable',
    detailLabel: 'Value',
    detailHelperText: 'Supports JSON Notation eg. dl.audience=gender_male',
  },
  LOCAL_STORAGE: { key: 'LOCAL_STORAGE', label: 'In LocalStorage', detailLabel: 'Value' },
  COOKIE: { key: 'COOKIE', label: 'In Cookie', detailLabel: 'Value' },
  URL: { key: 'URL', label: 'In The URL Path', detailLabel: 'Path Name' },
  QUERY_PARAMS: { key: 'QUERY_PARAMS', label: 'From Query Parameter', detailLabel: 'Value' },
  DATALAYER: { key: 'DATALAYER', label: 'In Data Layer', detailLabel: 'Value' },
  SESSION_STORAGE: { key: 'SESSION_STORAGE', label: 'In SessionStorage', detailLabel: 'Value' },
}

type ValidOperationsStruct = Record<OperationTypes, Array<keyof OperationStruct>>

export const ValidOperations: ValidOperationsStruct = {
  [OperationTypes.COHORTS]: [
    Operation.COOKIE.key,
    Operation.DATALAYER.key,
    Operation.GENERAL_VARIABLE.key,
    Operation.LOCAL_STORAGE.key,
    Operation.SESSION_STORAGE.key,
  ],
  [OperationTypes.CONSENT]: [Operation.OPT_IN.key, Operation.OPT_OUT.key],
  [OperationTypes.EVENTS]: [Operation.CLICK.key, Operation.HOVER.key, Operation.BLUR.key, Operation.CUSTOM.key],
  [OperationTypes.PAGE_ATTR]: [
    Operation.COOKIE.key,
    Operation.DATALAYER.key,
    Operation.GENERAL_VARIABLE.key,
    Operation.LOCAL_STORAGE.key,
    Operation.SESSION_STORAGE.key,
    Operation.QUERY_PARAMS.key,
    Operation.URL.key,
  ],
}

enum StringOperatorKeys {
  CONTAINS = 'CONTAINS',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
}

export const StringOperators: Record<StringOperatorKeys, string> = {
  [StringOperatorKeys.CONTAINS]: 'Contains',
  [StringOperatorKeys.EQUALS]: '=',
  [StringOperatorKeys.NOT_EQUALS]: '≠',
}

export type Rule = {
  id: string
  logicOp: keyof typeof LOGICAL_OP
  type: OperationTypes
  operation: keyof OperationStruct
  details: string
  variableName?: string
  stringOperator?: StringOperatorKeys
}

export type ExperienceAB = {
  id: string
  experienceId: string
  chance: number
}

export type RuleSet = {
  id: string
  experiences: ExperienceAB[]
  rules: Rule[]
}
