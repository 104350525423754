import React from 'react'
import moment from 'moment'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'

import { NotificationDTO } from '@ketch-com/figurehead'
import { AppHeaderNotificationCardTextWithLinksReplaced } from './AppHeaderNotificationCardTextWithLinksReplaced'
import { Typography } from '@mui/material'

const NewNotificationIcon = styled(Box)(({ theme }) => ({
  width: 6,
  height: 6,
  borderRadius: '50%',
  background: theme.palette.heliotrope.main,
  marginRight: 4,
}))

type Props = {
  isNew?: boolean
  isLast?: boolean
  isFirst?: boolean
  notification?: NotificationDTO
}

export const AppHeaderNotificationCard: React.FC<Props> = ({ notification, isLast, isNew = false }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderBottom: ({ palette }) => (!isLast ? `1px solid ${palette.iron.main}` : 'none'),
        py: '5px',
      }}
    >
      {isNew ? (
        <Box>
          <NewNotificationIcon />
        </Box>
      ) : (
        <Box width="10px" />
      )}

      <Box>
        <Box>
          <AppHeaderNotificationCardTextWithLinksReplaced notification={notification} isNew={isNew} />
        </Box>
        <Typography sx={{ fontStyle: 'capitalize' }} color="darkDuskFaded.main">
          {moment((notification?.metadata?.createdAt || 0) * 1000).fromNow()}
        </Typography>
      </Box>
    </Box>
  )
}
