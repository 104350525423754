import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchSystemConnections } from '../fetchers/fetchSystemConnections'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.appConnections,
  queryFn: fetchSystemConnections,
  select: res => res?.data?.apps || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useSystemConnections = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
