import { HTMLAttributes } from 'react'
import clsx from 'clsx'
import { Box } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  ({ typography, palette, spacing }) =>
    createStyles({
      baseBadge: {
        alignItems: 'center',
        borderRadius: 100,
        background: palette.fadedGrey.main,
        color: palette.heliotrope.main,
        display: 'inline-block',
        fontFamily: typography.fontFamily,
        fontSize: typography.pxToRem(11),
        fontWeight: 500,
        height: spacing(4),
        letterSpacing: '0.01em',
        lineHeight: typography.pxToRem(13),
        padding: spacing(1.25, 1.75),
        cursor: 'pointer',
        wordWrap: 'normal',
      },
      selected: {
        background: palette.heliotrope.main,
        color: palette.white.main,
        fontWeight: 600,
      },
    }),
  { name: 'LabelSelectPill' },
)

export interface Props extends HTMLAttributes<HTMLDivElement> {
  /** Status custom className */
  className?: string
  /** optional onClick handler */
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined

  isSelected: boolean
}

export const LabelSelectPill: React.FC<Props> = ({ className, children, onClick, isSelected }) => {
  const classes = useStyles()

  const badgeClassName = clsx(
    classes.baseBadge,
    {
      [classes.selected]: isSelected,
    },
    className,
  )

  return (
    <Box mr={2} mb={2} display="flex" alignItems="center" onClick={onClick} className={badgeClassName}>
      {children}
    </Box>
  )
}
