import React, { useState } from 'react'
import { Box } from '@mui/material'
import { DropListButton, Icon, ListItemText, ActionSheetItem, TextInput, TooltipButton } from '@ketch-com/deck'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { useQueryParams } from 'utils/hooks/useQueryParams'

export const AuditLogFilters: React.FC<{}> = () => {
  const { queries, setMultipleQueryParams } = useQueryParams<{ identifierSearch?: string; identitySpaceCode: string }>()
  const [searchString, setSearchString] = useState(() => {
    const { identifierSearch } = queries
    return identifierSearch || ''
  })

  const [selectedIdentitySpaceCode, setSelectedIdentitySpaceCode] = useState(() => {
    const { identitySpaceCode } = queries
    return identitySpaceCode || ''
  })
  const { data: identitySpaces } = useIdentitySpaces()
  const identitySpaceDropdownValue = (identitySpaces || []).find(({ code }) => code === selectedIdentitySpaceCode)

  const handleDropdownClick = (code: string) => {
    setSelectedIdentitySpaceCode(code)
  }

  const handleSubmitSearch = (e: React.MouseEvent) => {
    e.preventDefault()
    setMultipleQueryParams([
      { key: 'identifierSearch', value: searchString },
      { key: 'identitySpaceCode', value: selectedIdentitySpaceCode },
    ])
  }

  const handleSearchInputOnKeyPress = (e: React.KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter' && searchString && selectedIdentitySpaceCode) {
      setMultipleQueryParams([
        { key: 'identifierSearch', value: searchString },
        { key: 'identitySpaceCode', value: selectedIdentitySpaceCode },
      ])
    }
  }

  const identitySpaceDropdownItems = (identitySpaces || []).map(({ code, name }) => ({
    content: name,
    code,
    onClick: () => handleDropdownClick(code),
  }))

  const dropListButtonCurrentValue = identitySpaceDropdownItems.find(
    item => item.code === identitySpaceDropdownValue?.code,
  )

  return (
    <Box display="flex" alignItems="center" gap={2} mb={3}>
      {/* Select */}
      <DropListButton
        size="small"
        width="300px"
        options={identitySpaceDropdownItems}
        placeholder="Select Identifier"
        getOptionLabel={option => option.content}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        disableClearable
        onChange={(_, newValue) => {
          if (newValue) {
            const currentItem = identitySpaceDropdownItems.find(item => item.code === newValue.code)
            if (currentItem) {
              currentItem.onClick && currentItem.onClick()
            }
          }
        }}
        value={{
          code: dropListButtonCurrentValue?.code || '',
          content: dropListButtonCurrentValue?.content || '',
          onClick: dropListButtonCurrentValue?.onClick,
        }}
        renderOption={(props, option) => (
          <ActionSheetItem selected={option.code === identitySpaceDropdownValue?.code} {...props}>
            <ListItemText selected={option.code === identitySpaceDropdownValue?.code}>{option.content}</ListItemText>
          </ActionSheetItem>
        )}
      />

      {/* Search Input */}
      <TextInput
        sx={{
          width: '260px',
        }}
        size="small"
        value={searchString}
        startIcon={<Icon name="OMag" />}
        endIcon={searchString && <Icon sx={{ cursor: 'pointer' }} name="OCross" onClick={() => setSearchString('')} />}
        placeholder="Search for a value..."
        onChange={event => setSearchString(event.target.value)}
        onKeyDown={handleSearchInputOnKeyPress}
      />

      {/* Submit Button */}
      <TooltipButton
        disabled={!selectedIdentitySpaceCode || !searchString}
        onClick={handleSubmitSearch}
        title={
          !selectedIdentitySpaceCode
            ? 'Please select an Identifier'
            : !searchString
            ? 'Please enter an identifier'
            : undefined
        }
        color="primary"
      >
        Search
      </TooltipButton>
    </Box>
  )
}
