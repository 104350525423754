import { formatRequestString } from 'utils/helpers/api/queryString'
import { API } from 'utils/api/api'
import { EditAppsInstancesPurposesDTO } from 'interfaces/apps'

type Params = {
  organizationCode: string
  formData: EditAppsInstancesPurposesDTO
}

export const updateAppsInstancesPurposes = ({ organizationCode, formData }: Params) =>
  API.put<{}>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/app-purposes`,
    }),
    formData,
  )
