import React from 'react'

import { showToast } from 'components/ui-kit/toastr/Toastr'
import { ApiKeyList } from 'pages/developers/apiKeys/list/ApiKeyList'
import { useApiKeys } from 'api/apiKeys/queries/useApiKeys'

export const ApiKeyListContainer: React.FC = () => {
  const { data, isLoading, isFetching } = useApiKeys({
    params: {
      includeMetadata: true,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch API Keys', type: 'error' })
    },
  })

  return <ApiKeyList isLoading={isLoading} isFetching={isFetching} apiKeys={data} />
}
