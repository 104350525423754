import React, { useState } from 'react'

import { MaybeNull } from 'interfaces/common'
import { ThemePreviewViewType, ThemePreviewViewTypeOptions } from 'interfaces/themes-v2/themePreview'
import { PreviewDeviceMode, PREVIEW_DEVICE_MODE_OPTIONS } from 'interfaces/preview/previewDeviceMode'
import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { RoutesManager } from 'utils/routing/routesManager'

import { bannerDataMock } from 'pages/consentAndRights/experiences/preview/components/bannerPreview/utils'
import { modalDataMock } from 'pages/consentAndRights/experiences/preview/components/modalPreview/utils'
import { preferenceDataMock } from 'pages/consentAndRights/experiences/preview/components/preferencePreview/utils'
import { PreviewLayout } from 'components/ui-layouts/previewLayout/PreviewLayout'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { Group } from 'components/ui-layouts/group/Group'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { BannerScreenPreview } from 'pages/consentAndRights/experiences/preview/components/bannerPreview/BannerScreenPreview'
import { ModalScreenPreview } from 'pages/consentAndRights/experiences/preview/components/modalPreview/ModalScreenPreview'
import { PreferenceScreenPreview } from 'pages/consentAndRights/experiences/preview/components/preferencePreview/PreferenceScreenPreview'

type Props = {
  isReady: boolean
  theme: MaybeNull<ThemeDTO>
}

export const ThemePreview: React.FC<Props> = ({ isReady, theme }) => {
  const [mode, setMode] = useState(PreviewDeviceMode.DESKTOP)
  const [view, setView] = useState(ThemePreviewViewType.BANNER)

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Themes', link: RoutesManager.deployment.themes.root.getURL() },
    {
      title: theme?.name || theme?.code,
      link: RoutesManager.deployment.themes.view.root.getURL({ code: theme?.code }),
    },
    { title: 'Preview' },
  ]

  return (
    <PreviewLayout
      isReady={isReady}
      breadcrumbs={breadcrumbs}
      name={theme?.name}
      code={theme?.code}
      exitTo={RoutesManager.deployment.themes.view.root.getURL({ code: theme?.code })}
      toolbar={
        <Flex alignItems="flex-end">
          <Group gap={12}>
            <SegmentTabs tabs={ThemePreviewViewTypeOptions} value={view} onChange={setView} />
            <SegmentTabs tabs={PREVIEW_DEVICE_MODE_OPTIONS} value={mode} onChange={setMode} />
          </Group>
        </Flex>
      }
    >
      {!!theme && (
        <>
          {view === ThemePreviewViewType.BANNER && (
            <BannerScreenPreview mode={mode} theme={theme} {...bannerDataMock} />
          )}

          {view === ThemePreviewViewType.MODAL && <ModalScreenPreview mode={mode} theme={theme} {...modalDataMock} />}

          {view === ThemePreviewViewType.FORM && (
            <PreferenceScreenPreview mode={mode} theme={theme} {...preferenceDataMock} />
          )}
        </>
      )}
    </PreviewLayout>
  )
}
