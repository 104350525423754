import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
  includeIssues?: boolean
  appCode?: string
  used?: boolean
}>

type Response = APIListResponse<{ cookies: CookieDTO[] }>

export const fetchCookies = ({
  organizationCode,
  includeMetadata,
  includeIssues,
  start,
  limit,
  appCode,
  used,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/cookies`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
          appCode,
          used,
        },
      },
    }),
  )
