import { ContentGroup, ListLayout } from '@ketch-com/deck'
import { Box, Divider, styled } from '@mui/material'
import { OverviewCard } from 'components/OverviewCard/OverviewCard'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { useNavigateGated } from 'utils/routing/hooks/useNavigateGated'
import { RoutesManager } from 'utils/routing/routesManager'
import { SidebarRouteItem } from 'utils/routing/types'
import { useSidebarRoutesConfig } from 'utils/routing/useSidebarRoutesConfig'

const CardsBox = styled(Box)({
  display: 'flex',
  gap: '24px',
})

export const ConsentRightsOverview: React.FC = () => {
  const navigateGated = useNavigateGated()
  const breadcrumbs = [{ title: 'Consent & Rights' }]

  const handleCardClick = (item: SidebarRouteItem) => {
    navigateGated(
      item.path,
      item.permissions,
      item.entitlements,
      item.requireAllPermissions,
      item.requireAllEntitlements,
    )
  }

  // Get consent and rights routes
  const consentRightsRoutes = useSidebarRoutesConfig().find(route =>
    route.path.includes(RoutesManager.deployment.root.getURL()),
  )?.subRoutes as { [subRouteSection: string]: SidebarRouteItem[] }

  const captureCards = consentRightsRoutes['Capture'].map((item, index) => (
    <OverviewCard key={index} cardTitle={item.title} onClick={() => handleCardClick(item)} />
  ))

  const orchestrationCards = consentRightsRoutes['Orchestration'].map((item, index) => (
    <OverviewCard key={index} cardTitle={item.title} onClick={() => handleCardClick(item)} />
  ))

  return (
    <ListLayout headerTitle="Consent & Rights" headerProps={{ isDivider: true }} sx={{ px: '154px' }}>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      {/* Capture cards */}
      {Boolean(captureCards.length) && (
        <>
          <ContentGroup
            title="Capture"
            titleVariant="h3"
            subTitle="Create experiences, add your websites and apps to go live with your privacy program."
          >
            <CardsBox>{captureCards}</CardsBox>
          </ContentGroup>
          <Divider sx={{ mt: 6, mb: 2 }} />
        </>
      )}

      {/* Orchestration cards */}
      {Boolean(orchestrationCards.length) && (
        <ContentGroup
          title="Orchestration"
          titleVariant="h3"
          subTitle="Automate DSARs, configure workflows, and gain insights into consent automation."
        >
          <CardsBox>{orchestrationCards}</CardsBox>
        </ContentGroup>
      )}
    </ListLayout>
  )
}
