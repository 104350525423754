import Box from '@mui/material/Box'
import { DataSourcesFilters } from './components/DataSourcesFilters'
import { DataSourcesList } from './components/DataSourcesList'
import { ContentGroup } from '@ketch-com/deck'

export const DataSourcesTab = () => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {/* Heading text */}
      <Box>
        <ContentGroup
          title="Data Sources"
          subTitle="The systems and data assets involved in this processing."
          titleVariant="h3"
          variant="inner-page-header"
        />
      </Box>

      {/* Search bar and sliding filter */}
      <DataSourcesFilters />

      {/* Systems list */}
      <DataSourcesList />
    </Box>
  )
}
