import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { softDeleteRightInvocation } from '../fetchers/softDeleteRightInvocation'

const useCustomMutation = createUseMutation({
  mutationFn: softDeleteRightInvocation,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useSoftDeleteRightInvocation = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation, {})
}
