import React, { Fragment, useState } from 'react'
import { To, useNavigate } from 'react-router-dom'
import { NavigationBreadCrumbsPortal } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbsPortal'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { CustomIconName, Icon, theme } from '@ketch-com/deck'
import { Crumb } from '../components/navigationHeader/components/headerBreadcrumbs/components/Crumb'
import { Box, styled } from '@mui/material'

const BreadcrumbsBox = styled(Box)({
  paddingLeft: '32px',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  maxWidth: 880,
})

export type NavigationBreadCrumbsProps = Props

export type BreadcrumbNavigationItems = {
  title?: string
  link?: To
  icon?: CustomIconName
}[]

const useCondenseBreadcrumbs = ({ items }: { items: BreadcrumbNavigationItems }) => {
  const navigate = useNavigate()
  const [width, setWidth] = useState<number>(0)

  const breadcrumbsContainerWidth = document.getElementById('breadcrumbs-child')?.getBoundingClientRect().width
  React.useEffect(() => {
    if (typeof breadcrumbsContainerWidth === 'number') {
      setWidth(w => (breadcrumbsContainerWidth > w ? breadcrumbsContainerWidth : w))
    }
  }, [breadcrumbsContainerWidth])

  const shouldShowCondensed = width > 800

  const condensedBreadcrumbItems = shouldShowCondensed
    ? items.slice(1, items.length - 1).map(el => ({
        content: el.title,
        onClick: () => navigate(el.link as string),
      }))
    : []
  const firstCondensedBreadcrumb = shouldShowCondensed ? items[0] : null
  const lastCondensedBreadcrumb = shouldShowCondensed ? items[items.length - 1] : null

  return {
    shouldShowCondensed,
    condensedBreadcrumbItems,
    firstCondensedBreadcrumb,
    lastCondensedBreadcrumb,
  }
}

type Props = {
  type: 'light' | 'dark'
  items: BreadcrumbNavigationItems
}

export const NavigationBreadCrumbs: React.FC<Props> = ({ items }) => {
  const separator = <Icon name={'OArrowCRight'} width={20} height={20} iconColor={theme.palette.Black.o32} />
  const { shouldShowCondensed, condensedBreadcrumbItems, firstCondensedBreadcrumb, lastCondensedBreadcrumb } =
    useCondenseBreadcrumbs({ items })

  return (
    <NavigationBreadCrumbsPortal.Source>
      {
        <BreadcrumbsBox id="breadcrumbs-child">
          {!shouldShowCondensed &&
            items.map(({ title, link, icon }, index) => (
              <Fragment key={`${index}-${title}`}>
                <Crumb title={title} link={link} icon={icon} clickable={index !== items.length - 1} />
                {index < items.length - 1 && separator}
              </Fragment>
            ))}
          {shouldShowCondensed && (
            <>
              <Crumb {...firstCondensedBreadcrumb} />
              {separator}
              <ButtonOptions size="small" items={condensedBreadcrumbItems} />
              {separator}
              <Crumb {...lastCondensedBreadcrumb} clickable={false} />
            </>
          )}
        </BreadcrumbsBox>
      }
    </NavigationBreadCrumbsPortal.Source>
  )
}
