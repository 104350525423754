import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { MaybeNull } from 'interfaces/common'
import { CookieDTO, CookieFormDTO } from 'interfaces/cookies/cookie'
import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { useCookieValidationSchema } from 'pages/policyCenter/cookies/upsert/utils/formValidation'
import { getCookieInitialValues } from 'pages/policyCenter/cookies/upsert/utils/formValues'
import { CookieBasicDetailsFormSection } from 'pages/policyCenter/cookies/upsert/components/CookieBasicDetailsFormSection'
import { CookiePurposesFormSection } from 'pages/policyCenter/cookies/upsert/components/CookiePurposesFormSection'
import { CookieApplicationsFormSection } from 'pages/policyCenter/cookies/upsert/components/CookieApplicationsFormSection'
import { FormikProvider, useFormik } from 'formik'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isReady: boolean
  isKetchCookie: boolean
  cookie: MaybeNull<CookieDTO>
  onSubmit: (values: CookieFormDTO) => Promise<void>
}

export const EditCookieView: React.FC<Props> = ({ isReady, isKetchCookie, cookie, onSubmit }) => {
  const navigate = useNavigate()

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Cookies', link: RoutesManager.policyCenter.cookies.root.getURL() },
    {
      title: cookie?.name || cookie?.code,
      link: RoutesManager.policyCenter.cookies.view.root.getURL({ code: cookie?.code }),
    },
    { title: 'Edit' },
  ]

  const initialValues = useMemo(() => getCookieInitialValues(cookie), [cookie])
  const validationSchema = useCookieValidationSchema({ isEditMode: true, isKetchCookie })

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })

  const { submitForm, isSubmitting } = formikProps

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={UpsertLayoutMode.edit}
          renderFormTitle={() => <ContentGroup title="Edit Cookie" titleVariant="h2" isDivider />}
          onAccept={submitForm}
          onCancel={() => {
            navigate(RoutesManager.policyCenter.cookies.view.root.getURL({ code: cookie?.code }))
          }}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            <CookieBasicDetailsFormSection isEditMode isKetchCookie={isKetchCookie} />
            <CookiePurposesFormSection isKetchCookie={isKetchCookie} />
            <CookieApplicationsFormSection isKetchCookie={isKetchCookie} />
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
