import React from 'react'

import { MaybeNull } from 'interfaces'
import { SearchInput } from 'components/form/SearchInput'
import Box from '@mui/material/Box'

type Props = {
  filters: {
    queryString: MaybeNull<string>
  }
  onChange: (nextFilters: { queryString?: string }) => void
}

export const PolicyScopeLibraryFilters: React.FC<Props> = ({ filters, onChange }) => {
  return (
    <Box mt="16px" mb="20px">
      <SearchInput
        size="small"
        onChange={nextSearchString => onChange({ queryString: nextSearchString })}
        onClear={() => onChange({ queryString: '' })}
        value={filters.queryString || ''}
      />
    </Box>
  )
}
