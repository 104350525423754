import React from 'react'
import Box from '@mui/material/Box'
import { FormTemplateDTO } from '@ketch-com/figurehead'
import { Chip } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  formTemplate?: FormTemplateDTO
}

export const FormTemplateDetails: React.FC<Props> = ({ formTemplate }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" alignItems="center" gap={2}>
        <Chip label="Form" size="small" />
        <Chip
          size="small"
          label={
            <Typography variant="smallLabel">
              Code: <Typography variant="smallBody">{formTemplate?.code}</Typography>
            </Typography>
          }
        />
      </Box>
    </Box>
  )
}
