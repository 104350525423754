import React from 'react'

import { Button, PopUp } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  labelName: string
  isPending?: boolean
  onSubmit?: () => void
  onCancel: () => void
}

export const DeleteLabelModal: React.FC<Props> = ({ labelName, onSubmit, onCancel, isPending }) => (
  <PopUp
    title={`Confirm Delete ${labelName}`}
    popUpWidth="550px"
    variant="dialog"
    onClose={onCancel}
    popUpActionChildren={
      <>
        <Button color="secondary" size="large" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          size="large"
          // @ts-ignore
          onClick={onSubmit}
          pending={isPending}
        >
          Delete
        </Button>
      </>
    }
  >
    <Typography variant="body">
      Once you delete the classification, it will be gone forever, and removed from all resources to which it was
      assigned. Are you sure you want to continue?
    </Typography>
  </PopUp>
)
