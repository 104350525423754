import { GetRightInvocationsByIdentityResponseBodyRightInvocationsItemDTO } from '@ketch-com/figurehead'
import { Status, StatusState, StatusVariant } from '@ketch-com/deck'

type Props = {
  className?: string
  variant?: StatusVariant
  rightInvocation: GetRightInvocationsByIdentityResponseBodyRightInvocationsItemDTO
}

export const AuditLogsPermitsRightInvocationStatusCellRenderer: React.FC<Props> = ({
  rightInvocation,
  variant = StatusVariant.ghost,
}) => {
  const { status } = rightInvocation

  if (status?.toLowerCase() === 'completed') {
    return <Status icon="Dot" label="Completed" variant={variant} status={StatusState.success} />
  }

  if (status?.toLowerCase() === 'on track') {
    return <Status icon="Dot" label="On Track" status={StatusState.draft} variant={variant} />
  }

  if (status?.toLowerCase() === 'due soon') {
    return <Status icon="Dot" label="Due soon" status={StatusState.warning} variant={variant} />
  }
  if (status?.toLowerCase() === 'urgent') {
    return <Status icon="Dot" label="Urgent" status={StatusState.error} variant={variant} />
  }
  return null
}
