import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { PatchTrackersOnNewPropertiesResponseBody } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type Params = {
  encodedTrackerKey: string
}

export const confirmTrackerOnNewProperties = ({ encodedTrackerKey }: Params) =>
  API.put<PatchTrackersOnNewPropertiesResponseBody>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/tracker/${encodedTrackerKey}/new-properties`,
    }),
  )
