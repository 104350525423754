import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getExperienceBuilderFormState } from '../selectors/getExperienceBuilderFormState'
import { resetExperienceBuilderReduxState, setExperienceBuilderReduxState } from '..'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { ConsentExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/utils/consent/interfaces'

export const useExperienceBuilderFormStateUtils = () => {
  const dispatch = useAppDispatch()

  const { values, referrer } = useAppSelector(getExperienceBuilderFormState)

  const resetPersistedReduxValues = () => dispatch(resetExperienceBuilderReduxState())

  const setState = ({
    values,
    referrer,
  }: {
    values: ConsentExperienceFormValues | PreferenceExperienceFormValues
    referrer?: string
  }) => {
    dispatch(setExperienceBuilderReduxState({ values, referrer: referrer || '' }))
  }

  return {
    values,
    referrer,
    resetPersistedReduxValues,
    setState,
  }
}
