import { ConfigurationV2, PreviewMessage, PreviewMessageType } from '@ketch-sdk/ketch-types'

export const getPreviewMessage = (type: PreviewMessageType, config: ConfigurationV2): PreviewMessage => ({
  type,
  payload: config,
})

export function withDefaultFirst<
  T extends Record<K, string | undefined> | Partial<Record<K, string>>,
  K extends string,
>(items: T[], key: K): T[] {
  return items.sort((a, b) => {
    // Check if the property exists and includes 'default', defaulting to false if undefined
    const aValueIncludesDefault = a[key]?.includes('default') ?? false
    const bValueIncludesDefault = b[key]?.includes('default') ?? false

    if (aValueIncludesDefault) return -1 // a should come first
    if (bValueIncludesDefault) return 1 // b should come first
    return 0 // Keep original order if neither includes 'default'
  })
}
