import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  code: string
}

export const deleteBusinessFunction = ({ code }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/business-functions/${code}`,
    }),
  )
