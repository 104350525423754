import React, { useContext } from 'react'
import { RoutesManager } from 'utils/routing/routesManager'
import { ViewLayout } from 'components/ui-layouts/viewLayout/ViewLayout'
import { ViewLayoutHeader } from 'components/ui-layouts/viewLayout/ViewLayoutHeader'
import { ViewLayoutContent } from 'components/ui-layouts/viewLayout/ViewLayoutContent'
import { ThemeViewActions } from 'pages/consentAndRights/themes-v3/view/components/ThemeViewActions'
import { ThemeViewDetails } from 'pages/consentAndRights/themes-v3/view/components/ThemeViewDetails'
import { ThemeViewContext, withThemeViewContext } from './context/ThemeViewContext'
import { ThemeViewRoutes } from './ThemeViewRoutes'

export const ThemeV3ViewWithoutContext: React.FC = () => {
  const { location, theme, isThemeLoading } = useContext(ThemeViewContext)

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Themes', link: RoutesManager.deployment.themesV3.root.getURL() },
    { title: theme?.name },
  ]

  if (isThemeLoading || !theme) {
    return <ViewLayout isReady={false} breadcrumbs={breadcrumbs} />
  }

  const { id, name } = theme

  return (
    <ViewLayout
      isReady={!isThemeLoading}
      breadcrumbs={breadcrumbs}
      // TODO: Versions message
      // versionsMessage={!isLatest && <ThemeVersionsMessage theme={theme} />}
    >
      <ViewLayoutHeader
        title={name || id}
        backTo={RoutesManager.deployment.themesV3.root.getURL()}
        details={<ThemeViewDetails />}
        // TODO:JB - Hide when not latest version
        actions={<ThemeViewActions />}
      />

      <ViewLayoutContent
        paddingOverride="0px"
        tabs={[
          {
            title: 'Preview',
            link: {
              pathname: `${RoutesManager.deployment.themesV3.view.preview.root.getURL({ id })}`,
              search: location.search,
            },
          },
          // {
          //   title: 'Deployment',
          //   link: {
          //     pathname: `${RoutesManager.deployment.themesV3.view.deployment.root.getURL({ id })}`,
          //     search: location.search,
          //   },
          // },
          // {
          //   title: 'Versions',
          //   link: {
          //     pathname: `${RoutesManager.deployment.themesV3.view.versions.root.getURL({ id })}`,
          //     search: location.search,
          //   },
          // },
        ]}
      >
        <ThemeViewRoutes />
      </ViewLayoutContent>
    </ViewLayout>
  )
}

export const ThemeV3View = withThemeViewContext(ThemeV3ViewWithoutContext)
