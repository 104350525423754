import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

// api
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useRights } from 'api/rights/queries/useRights'
import { useCreateEvent } from 'api/orchestrationEventsV2/mutations/useCreateEvent'

// components
import { CreateEvent } from 'pages/orchestration/events/create/CreateEvent'
import { showToast } from 'components/ui-kit/toastr/Toastr'

// utils
import { RoutesManager } from 'utils/routing/routesManager'

// types
import { EventV2DTO } from '@ketch-com/figurehead'
import { useOrchestrationEventsPaginated } from 'api/orchestrationEventsV2/queries/useOrchestrationEventsPaginated'

export const CreateEventContainer: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { data: rights, isLoading: isRightsLoading } = useRights({
    params: {
      onlyOrgJurisdictionRights: true,
    },
  })

  const { mutateAsync: handleCreateEvent } = useCreateEvent()

  const onSubmit = async (values: EventV2DTO) => {
    try {
      await handleCreateEvent({
        params: {
          event: {
            ...values,
          },
        },
      })

      await queryClient.refetchQueries(ApiQueryKeys.eventsPaginated)
      showToast({ content: 'Event was created', type: 'success' })
      navigate(RoutesManager.orchestration.events.root.getURL())
    } catch (e) {
      showToast({ content: 'Failed to create event', type: 'error' })
    }
  }

  // events
  const { data: events, isLoading: isEventsListLoading } = useOrchestrationEventsPaginated({
    params: {},
  })

  const isReady = !isEventsListLoading && !isRightsLoading

  return <CreateEvent isReady={isReady} rights={rights} onSubmit={onSubmit} events={events} />
}
