import { APIRequestParams } from 'api/common/utils'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans/deploymentPlan'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  deploymentPlanCode: string
  organizationCode: string
  version: string
  includeIssues?: boolean
}>

type Response = { deployment: DeploymentPlanDTO }

export const fetchDeploymentPlanVersion = ({
  organizationCode,
  deploymentPlanCode,
  version,
  includeMetadata,
  includeIssues,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/deployments/${deploymentPlanCode}/versions/${version}`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
        },
      },
    }),
  )
