import React, { useMemo, useState } from 'react'
import { Formik, ErrorMessage, FieldArray } from 'formik'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

import { getInitialValues, getValidationSchema } from './utils'
import { CreateWebhookFormValues } from './utils/getInitialValues'
import { useWebhook } from 'api/webhooks/queries/useWebhook'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { Button, Icon, PopUp, FormRow } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { FormCheckbox } from 'components/form/FormCheckbox'
import { FormSwitch } from 'components/form/FormSwitch'

const PREFIX = 'UpsertWebhookModal'

const classes = {
  hideShowSensitiveIcon: `${PREFIX}-hideShowSensitiveIcon`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.hideShowSensitiveIcon}`]: {
    cursor: 'pointer',
    color: palette.darkDusk.main,
  },
}))

type Props = {
  isUpdatingAssetLabels?: boolean
  onSubmit?: (values: CreateWebhookFormValues) => void
  onCancel: () => void
  hookToEditId?: string
}

export const UpsertWebhookModal: React.FC<Props> = ({ onSubmit = () => {}, onCancel, hookToEditId }) => {
  const { isEntitled } = useIsEntitled()
  const [revealedHeaderKeys, setRevealedHeaderKeys] = useState(new Set<string>())
  const { data: hookToEdit, isLoading: isLoadingHookToEdit } = useWebhook({
    enabled: !!hookToEditId,
    params: {
      webhookId: hookToEditId || '',
    },
  })

  const isEntitledToSubscriptions = isEntitled(ENTITLEMENTS.SUBSCRIPTIONS)
  const isEntitledToRights = isEntitled(ENTITLEMENTS.ORCHESTRATION_RIGHTS)
  const isEntitledToConsent = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)
  const displayEventSubscriptions = isEntitledToSubscriptions || isEntitledToRights || isEntitledToConsent

  const initialValues = useMemo(
    () =>
      getInitialValues({
        webhook: hookToEdit || {},
        entitlements: {
          subscriptions: isEntitledToSubscriptions,
          rights: isEntitledToRights,
          consent: isEntitledToConsent,
        },
      }),
    [hookToEdit, isEntitledToSubscriptions, isEntitledToRights, isEntitledToConsent],
  )
  const validationSchema = getValidationSchema()

  const toggleVisible = (key: string): void => {
    const revealedHeaderKeysCopy = new Set(revealedHeaderKeys)
    if (revealedHeaderKeysCopy.has(key)) {
      revealedHeaderKeysCopy.delete(key)
    } else {
      revealedHeaderKeysCopy.add(key)
    }
    setRevealedHeaderKeys(revealedHeaderKeysCopy)
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, values }) => {
        return (
          <PopUp
            title={hookToEditId ? 'Edit Webhook' : 'Create a Webhook'}
            popUpWidth="654px"
            isLoading={isLoadingHookToEdit}
            onClose={onCancel}
            popUpContentProps={{
              sx: {
                '&.DeckPopUpTitleTypeModal': {
                  margin: 0,
                },
              },
            }}
            popUpActionChildren={
              <>
                <Button color="secondary" size="large" onClick={onCancel}>
                  Cancel
                </Button>
                <Button color="primary" size="large" onClick={() => handleSubmit()} pending={isSubmitting}>
                  {hookToEditId ? 'Update' : 'Create'}
                </Button>
              </>
            }
          >
            <StyledBox pt={3} px={3}>
              {/* Name Row */}
              <Box pb={3}>
                <FormInput
                  fullWidth
                  required
                  formPropertyName="name"
                  label="Name"
                  placeholder="Example: Validation Hook"
                />
              </Box>

              {/* URL Row */}
              <Box pb={3}>
                <FormInput
                  disabled={values.testMode}
                  fullWidth
                  required
                  formPropertyName="url"
                  label="Webhook URL"
                  placeholder="Example: https://server.com"
                />
              </Box>

              {/* Header Key/Value Pair Rows */}

              <FormRow
                title="Webhook Headers"
                subTitle="Define headers that will be placed on every call to this webhook"
              >
                <Box display="flex" alignItems="center" mb={0.5}>
                  <Typography variant="label" sx={{ flex: 0.625 }}>
                    Key
                  </Typography>
                  <Typography variant="label" sx={{ flex: 1 }}>
                    Value
                  </Typography>
                </Box>

                <FieldArray
                  name="headers"
                  render={({ push, remove }) => (
                    <>
                      {values?.headers?.map?.((header, index: number) => {
                        const headerKey = `headers[${index}].key`
                        const headerValue = `headers[${index}].value`

                        const key = header?.key ?? ''
                        const isShowSensitive = revealedHeaderKeys.has(key)

                        return (
                          <Box key={`${index}-headers`} mb={2} mt={0}>
                            <Box display="flex" alignItems="flex-start" gap={3}>
                              {/* Key Input */}
                              <FormInput
                                disabled={values.testMode}
                                formPropertyName={headerKey}
                                required
                                fullWidth
                                placeholder="Enter Header Key"
                                sx={{ flex: 0.625 }}
                              />

                              {/* Value Input */}
                              <FormInput
                                disabled={values.testMode}
                                required
                                fullWidth
                                formPropertyName={headerValue}
                                placeholder="Bearer $auth"
                                type={isShowSensitive ? 'text' : 'password'}
                                sx={{ flex: 1 }}
                                endIcon={
                                  <Icon
                                    name={isShowSensitive ? 'FEyeHide' : 'FEyeShow'}
                                    onClick={() => toggleVisible(key)}
                                    className={classes.hideShowSensitiveIcon}
                                  />
                                }
                              />

                              {/* Delete Button */}
                              <Box>
                                {values?.headers?.length === 1 ? null : (
                                  <Button
                                    id={index.toString()}
                                    variant="iconCustomRounded"
                                    color="tertiary"
                                    onClick={() => remove(index)}
                                    disabled={values?.headers?.length === 1 || values.testMode}
                                  >
                                    <Icon name="OBin" />
                                  </Button>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        )
                      })}

                      <Box display="flex" alignItems="center" mt={1.625}>
                        <Button
                          color="secondary"
                          onClick={() => {
                            push({ key: '', value: '' })
                          }}
                          disabled={values.testMode}
                        >
                          Add
                        </Button>
                      </Box>
                    </>
                  )}
                />
              </FormRow>

              {displayEventSubscriptions ? (
                <>
                  <Typography variant="label" display="block">
                    Event Subscriptions
                  </Typography>

                  <ErrorMessage name="events">
                    {msg => {
                      if (typeof msg === 'string')
                        return (
                          <Typography fontWeight={400} fontSize={12} color="chileanFire.main">
                            {msg}
                          </Typography>
                        )
                      return null
                    }}
                  </ErrorMessage>
                  {isEntitledToSubscriptions ? (
                    <FormCheckbox name="events.subscriptions" label="Subscriptions" inlineLabel sx={{ ml: '-9px' }} />
                  ) : null}
                  {isEntitledToRights ? (
                    <FormCheckbox name="events.rights" label="Rights" inlineLabel sx={{ ml: '-9px' }} />
                  ) : null}
                  {isEntitledToConsent ? (
                    <FormCheckbox name="events.consent" label="Consent" inlineLabel sx={{ ml: '-9px' }} />
                  ) : null}
                </>
              ) : null}

              <Box display="flex" alignItems="center" gap={1} mt={2.5} mb={1} component="label">
                <Typography variant="label">Test Mode</Typography>
                <FormSwitch formPropertyName="testMode" />
              </Box>
              <Typography>
                Test mode will make the request payload available in the Ketch platform but will not call the webhook.
                Users are expected to send the callback request from their system for the workflow to continue.
              </Typography>
            </StyledBox>
          </PopUp>
        )
      }}
    </Formik>
  )
}
