// TODO: abstract to DB/BE service

/**
 * Returns session storage variable 'release'.
 * Can be used to toggle features specific to a release.
 *
 * @return string - release name
 */
export const getRelease = () => {
  return window.sessionStorage.getItem('release')
}
