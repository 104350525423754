import React from 'react'
import { Circle, Group, Line } from 'react-konva'

import * as CanvasConstants from 'pages/orchestration/workflows/edit/utils/constants'

const STROKE_WIDTH = 2
const CIRCLE_OFFSET = CanvasConstants.GRID_CELL_WIDTH / 2 - STROKE_WIDTH / 2

export const ConnectionCircle: React.FC = () => {
  const circleAbsolutePos = {
    x: 0,
    y: CIRCLE_OFFSET,
  }

  return (
    <Group x={circleAbsolutePos.x} y={circleAbsolutePos.y}>
      <Circle radius={5} fill={CanvasConstants.CANVAS_LINE_COLOR} strokeWidth={STROKE_WIDTH} stroke="white" />
      <Line points={[0, 0, 0, 0]} strokeWidth={STROKE_WIDTH} stroke={CanvasConstants.CANVAS_LINE_COLOR} />
    </Group>
  )
}
