import { GetRelatedAssetsResponseBodyDTO } from '@ketch-com/figurehead'
import {
  RelatedAssets,
  RelatedPathsAndAssets,
  RelatedPath,
  DsrConfigFormData,
} from 'pages/assetManager/dsrConfig/interfaces'

type Args = {
  relatedDataSets: GetRelatedAssetsResponseBodyDTO
  primaryDatasetId: string
}

export const getRelatedPathsAndAssets = ({ relatedDataSets = {}, primaryDatasetId: configuringDatasetId }: Args) => {
  const relatedPathsAndAssets: RelatedPathsAndAssets = {}
  relatedDataSets.relatedAssetsPath?.forEach?.(({ path = [], hashID = '' }) => {
    /*
      relatedAssetsPath list isn't 1:1 match with UI/UX (ie length of dataset !== length of relatedAssetsPath)
      relatedAssetsPath list should be enumerated to map related datasets with related paths
    */
    let assetIdComparator: string = configuringDatasetId
    const pathLength = path.length
    const relatedPaths = path.map(({ relationships = [] }, pathIndex) => {
      const isEndOfPath = pathLength - 1 === pathIndex
      const relationshipsLength = relationships.length
      const pathRelationships = relationships.map((relationship, relationshipIndex) => {
        const isEndOfRelationship = relationshipsLength - 1 === relationshipIndex
        const primaryTechnology = relationship.primaryKey?.connection?.technology
        const foreignTechnology = relationship.foreignKey?.connection?.technology
        const primaryKeyResource = relationship.primaryKey?.resource
        const foreignKeyResource = relationship.foreignKey?.resource
        const primaryKeyParent = primaryKeyResource?.hierarchies?.$parent
        const foreignKeyParent = foreignKeyResource?.hierarchies?.$parent
        const primaryProviderCode = relationship.primaryKey?.connection?.providerCode
        const foreignProviderCode = relationship.foreignKey?.connection?.providerCode

        const { id: primaryDatasetId = '', name: primaryDatasetName = '' } = primaryKeyParent || {}
        const { id: foreignDatasetId = '', name: foreignDatasetName = '' } = foreignKeyParent || {}

        const primaryKeyObj: RelatedAssets = {
          key: 'PK',
          attributeName: primaryKeyResource?.name || '',
          attributeId: primaryKeyResource?.id || '',
          datasetName: primaryDatasetName || '',
          datasetId: primaryDatasetId || '',
          relationshipId: relationship.id || '',
          technology: primaryTechnology || '',
          hashID,
          providerCode: primaryProviderCode || '',
        }
        const foreignKeyObj: RelatedAssets = {
          key: 'FK',
          attributeName: foreignKeyResource?.name || '',
          attributeId: foreignKeyResource?.id || '',
          datasetName: foreignDatasetName || '',
          datasetId: foreignDatasetId || '',
          relationshipId: relationship.id || '',
          technology: foreignTechnology || '',
          hashID,
          providerCode: foreignProviderCode || '',
        }
        if (assetIdComparator === primaryDatasetId) {
          if (isEndOfRelationship) assetIdComparator = foreignDatasetId
          if (isEndOfPath && isEndOfRelationship && !relatedPathsAndAssets[assetIdComparator])
            relatedPathsAndAssets[assetIdComparator] = []
          return [primaryKeyObj, foreignKeyObj]
        }
        if (assetIdComparator === foreignDatasetId) {
          if (isEndOfRelationship) assetIdComparator = primaryDatasetId
          if (isEndOfPath && isEndOfRelationship && !relatedPathsAndAssets[assetIdComparator])
            relatedPathsAndAssets[assetIdComparator] = []
          return [foreignKeyObj, primaryKeyObj]
        }
        return []
      })
      return { relationships: pathRelationships }
    })
    relatedPathsAndAssets[assetIdComparator].push({ hashID, path: relatedPaths })
  })
  return relatedPathsAndAssets
}

export const getRelatedDataset = (relatedPaths: RelatedPath) => {
  const { path } = relatedPaths
  const { relationships } = path[path.length - 1]
  return relationships[relationships.length - 1][1]
}

export const getPrimaryDataset = (relatedPaths: RelatedPath) => {
  const { path } = relatedPaths
  const { relationships } = path[0]
  return relationships[0][0]
}

export const getHasRelatedDataSets = (relatedDatasets = {} as DsrConfigFormData['relatedDatasets']) =>
  Boolean(Object.keys(relatedDatasets).filter(relatedDatasetKey => relatedDatasets[relatedDatasetKey].included).length)
