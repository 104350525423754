// Variants of the tags list selectable via the segment buttons
export enum TagListState {
  All = 'all',
  Active = 'active',
  Paused = 'paused',
}

export enum SourceType {
  TagManager = 'tagmanager',
  Property = 'property',
}

export enum AppCode {
  GoogleTagManager = 'googletag',
  AdobeExperiencePlatform = 'aep',
  Property = 'property',
}

export const AdvancedConsentModeAppCodes = [AppCode.GoogleTagManager]
