import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Banner, Button, Spinner, ViewLayout } from '@ketch-com/deck'
import { MaybeNull } from 'interfaces/common'
import { ApplicationDTO } from 'interfaces/applications/application'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { FormMode } from 'interfaces/formModes/formMode'
import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { IssuesMessage } from 'components/issuesMessage/IssuesMessage'
import { ApplicationViewDetails } from 'pages/consentAndRights/applications/view/components/ApplicationViewDetails'
import { ApplicationViewActions } from 'pages/consentAndRights/applications/view/components/ApplicationViewActions'
import { ApplicationOverview } from 'pages/consentAndRights/applications/view/tabs/ApplicationOverview'
import { ApplicationDataLayer } from 'pages/consentAndRights/applications/view/tabs/ApplicationDataLayer'
import { ApplicationTrackers } from 'pages/consentAndRights/applications/view/tabs/ApplicationTrackers'
import { ApplicationVersions } from 'pages/consentAndRights/applications/view/tabs/ApplicationVersions'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isReady: boolean
  application: MaybeNull<ApplicationDTO>
  versions: ApplicationDTO[]
  themes: ThemeV3DTO[]
}

export const ApplicationView: FC<Props> = ({ isReady, application, versions, themes }) => {
  const [currentTab, setCurrentTab] = useState(0)
  const { search, pathname } = useLocation()
  const { removeQueryParam } = useQueryParams<{ version?: string }>()
  const navigate = useNavigate()

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Properties', link: RoutesManager.deployment.applications.root.getURL() },
    { title: application?.name || application?.code },
  ]

  const tabs = useMemo(
    () => [
      {
        label: 'Overview',
        link: {
          pathname: RoutesManager.deployment.applications.view.overview.root.getURL({ code: application?.code }),
          search,
        },
      },
      {
        label: 'Data Layer',
        link: {
          pathname: RoutesManager.deployment.applications.view.dataLayer.root.getURL({ code: application?.code }),
          search,
        },
      },
      {
        label: 'Trackers',
        link: {
          pathname: RoutesManager.deployment.applications.view.trackers.root.getURL({ code: application?.code }),
          search,
        },
      },
      {
        label: 'Versions',
        link: {
          pathname: RoutesManager.deployment.applications.view.versions.root.getURL({ code: application?.code }),
          search,
        },
      },
    ],
    [search, application?.code],
  )

  const issuesMessageActions = {
    title: 'Update',
    onClick: () => {
      navigate(RoutesManager.deployment.applications.upsert.root.getURL({ code, formMode: FormMode.EDIT }))
    },
  }

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    navigate(tabs[newValue].link)
  }

  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (pathname.includes(tab.link.pathname)) {
        setCurrentTab(index)
      }
    })
  }, [tabs, currentTab, pathname])

  if (!isReady || !application) {
    return (
      <>
        <NavigationBreadCrumbs type="dark" items={breadcrumbs} />
        <Box display="flex" justifyContent="center" alignItems="center" borderRadius={1.375} width="1280px" flex={1}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )
  }

  const { name, code, hasIssues, latest } = application

  // TODO :: !versions.length - for old applications.
  const isLatest = !versions.length || !!latest

  const banners = (
    <>
      {!!hasIssues && <IssuesMessage entityType={ApplicationEntity.APPLICATION} actions={issuesMessageActions} />}
      {!isLatest && (
        <Banner
          severity="warning"
          title="You are viewing an old version of this property. Return to the current version to manage or edit."
          action={
            <Button
              variant="link"
              onClick={() => {
                removeQueryParam('version')
              }}
            >
              View Current
            </Button>
          }
          sx={{
            width: '100%',
          }}
        />
      )}
    </>
  )

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs} />
      <Box display="flex" flexDirection="column" gap="24px" alignItems="center" flex="1">
        {banners}
        <ViewLayout
          contentGroupProps={{
            title: name || code,
            showBackButton: true,
            propsBackButton: {
              onClick: () => navigate(RoutesManager.deployment.applications.root.getURL()),
            },
            infoBlockComponent: <ApplicationViewDetails isLatest={isLatest} application={application} />,
            actionRightBlockComponent: isLatest && <ApplicationViewActions application={application} />,
          }}
          tabsComponentProps={{
            tabItems: tabs,
            value: currentTab,
            onChange: handleTabChange,
          }}
        >
          <Routes>
            <Route
              path="/"
              element={<ApplicationOverview application={application} isLatest={isLatest} themes={themes} />}
            />

            <Route
              path={RoutesManager.deployment.applications.view.overview.root.pattern}
              element={<ApplicationOverview application={application} isLatest={isLatest} themes={themes} />}
            />

            <Route
              path={RoutesManager.deployment.applications.view.trackers.root.pattern}
              element={<ApplicationTrackers application={application} />}
            />

            <Route
              path={RoutesManager.deployment.applications.view.dataLayer.root.pattern}
              element={<ApplicationDataLayer application={application} />}
            />

            <Route
              path={RoutesManager.deployment.applications.view.versions.root.pattern}
              element={<ApplicationVersions application={application} versions={versions} />}
            />
          </Routes>
        </ViewLayout>
      </Box>
    </>
  )
}
