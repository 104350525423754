import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { UserDTO } from 'interfaces/users/users'

const formBaseURL = 'https://content.ketch.com/upgrade'

enum FieldValues {
  ConsentManagement = 'Consent%20management',
  DataMapping = 'Data%20mapping',
  DDC = 'Discovery%20and%20classification',
  RightsAutomation = 'Data%20subject%20rights%20automation',
  MarketingPreferences = 'Marketing%20preference%20management',
  RiskAssessments = 'Risk%20assessments',
}

// What form option a missing entitlement should map to
const EntitlementFormOptions: { [entitlement: string]: string[] } = {
  [ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE]: [FieldValues.ConsentManagement],
  [ENTITLEMENTS.CONSENT_MANAGEMENT]: [FieldValues.ConsentManagement],
  [ENTITLEMENTS.STATS_CONSENT]: [FieldValues.ConsentManagement],
  [ENTITLEMENTS.JURISDICTIONS]: [FieldValues.ConsentManagement],
  [ENTITLEMENTS.PURPOSES]: [FieldValues.ConsentManagement],
  [ENTITLEMENTS.POLICY_DOCUMENTS]: [FieldValues.ConsentManagement],
  [ENTITLEMENTS.CONTROLLERS]: [FieldValues.ConsentManagement, FieldValues.RightsAutomation],
  [ENTITLEMENTS.TRACKERS]: [FieldValues.ConsentManagement],
  [ENTITLEMENTS.ORCHESTRATION_CONSENT]: [FieldValues.ConsentManagement],
  [ENTITLEMENTS.ORCHESTRATION_PROTOCOLS]: [FieldValues.ConsentManagement],

  [ENTITLEMENTS.RIGHTS_MANAGEMENT]: [FieldValues.RightsAutomation],
  [ENTITLEMENTS.STATS_RIGHTS]: [FieldValues.RightsAutomation],
  [ENTITLEMENTS.WORKFLOWS]: [FieldValues.RightsAutomation],

  [ENTITLEMENTS.MARKETING_PREFERENCES]: [FieldValues.MarketingPreferences],
  [ENTITLEMENTS.SUBSCRIPTIONS]: [FieldValues.MarketingPreferences],

  [ENTITLEMENTS.DATA_MAPPING]: [FieldValues.DataMapping],
  [ENTITLEMENTS.STATS_DATAMAP]: [FieldValues.DataMapping],
  [ENTITLEMENTS.PROCESSING_ACTIVITIES]: [FieldValues.DataMapping],

  [ENTITLEMENTS.ASSESSMENTS]: [FieldValues.RiskAssessments],

  [ENTITLEMENTS.DATA_DISCOVERY]: [FieldValues.DDC],
}

/**
 * Build a link to our hubspot request a demo form with query params that will prepopulate the form
 */
export const getDemoFormLink = (missingEntitlements: ENTITLEMENTS[], user?: UserDTO) => {
  let queryParams: string[] = [
    // Always set country dropdown unselected
    'country__dropdown_=null',
    // Prepopulate how did you hear about Ketch box with message indicating they came from within the app
    'how_did_you_hear_about_ketch_=I%20clicked%20the%20"Contact%20Sales"%20link%20within%20app.ketch.com!',
    // Set field indicating redirect from within the ketch app
    'ketch_platform_redirect=Yes',
  ]

  // Append user info to url
  if (user?.firstName) queryParams.push(`firstname=${user.firstName}`)
  if (user?.lastName) queryParams.push(`lastname=${user.lastName}`)
  if (user?.email) queryParams.push(`email=${user.email}`)

  // Append product interest info to url based on missing entitlements, if any
  missingEntitlements.forEach(entitlement => {
    if (entitlement in EntitlementFormOptions) {
      queryParams.push(`product_interests=${EntitlementFormOptions[entitlement].join(',')}`)
    }
  })

  return `${formBaseURL}?${queryParams.join('&')}`
}
