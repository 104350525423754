import React from 'react'
import { Box, Typography } from '@mui/material'
import {
  SubscriptionTopicDTO,
  SubscriptionContactMethodDTO,
  GetSubscriptionTopicOrchestrationsResponseBodyDTO,
} from '@ketch-com/figurehead'
import { LegalBaseDTO } from 'interfaces/legalBases/legalBase'
import { RoutesManager } from 'utils/routing/routesManager'
import { SubscriptionTopicViewActions } from 'pages/policyCenter/subscriptions/subscriptionTopic/view/components'
import { getSortedTopicTranslations } from 'pages/policyCenter/subscriptions/utils'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { Chip, ContentGroup, DataGrid, InfoRow, Spinner, ViewLayout } from '@ketch-com/deck'
import {
  OrchestrationRowType,
  SubscriptionTopicOrchestrationsInfoRowContent,
  TranslationsRowType,
  translationsTopicsColumns,
} from './viewSubscriptionTopicUtils'
import { useNavigate } from 'react-router-dom'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  legalBases: LegalBaseDTO[]
  isReady: boolean
  subscriptionTopic: SubscriptionTopicDTO
  organizationLanguages: LanguageWithStatusDTO[]
  subscriptionContactMethods: SubscriptionContactMethodDTO[]
  subscriptionTopicOrchestrations: GetSubscriptionTopicOrchestrationsResponseBodyDTO
}

export const ViewSubscriptionTopic: React.FC<Props> = ({
  subscriptionTopic,
  isReady,
  legalBases,
  organizationLanguages,
  subscriptionContactMethods,
  subscriptionTopicOrchestrations,
}) => {
  const navigate = useNavigate()
  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Subscriptions', link: RoutesManager.policyCenter.subscriptions.list.subscriptionTopics.root.getURL() },
    { title: subscriptionTopic.name },
  ]

  // BE has translations map arranged by langCode instead of langName
  subscriptionTopic.translations = getSortedTopicTranslations(subscriptionTopic.translations, organizationLanguages)

  const contactMethodCodeNameMap: { [key: string]: string } = {}
  subscriptionTopic?.contactMethods?.forEach?.(code => {
    const contactMethodName =
      subscriptionContactMethods.filter(method => {
        if (method.code === code) return true
        return false
      })?.[0]?.name || code

    contactMethodCodeNameMap[code] = contactMethodName
  })

  const orchestrationsRows: OrchestrationRowType[] = Object.entries(
    subscriptionTopicOrchestrations?.orchestrations || {},
  ).map(elem => ({
    subscriptionTopicOrchestrations: elem[1],
    key: elem[0],
  }))
  const translationsRows: TranslationsRowType[] = Object.entries(subscriptionTopic?.translations || {}).map(elem => ({
    subscriptionTopicTranslation: elem[1],
    key: elem[0],
  }))

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs} />
      <ViewLayout
        contentGroupProps={{
          title: subscriptionTopic.name,
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.policyCenter.subscriptions.list.subscriptionTopics.root.getURL()),
          },
          infoBlockComponent: (
            <Chip
              size="small"
              label={
                <Typography variant="smallLabel">
                  Code: <Typography variant="smallBody">{subscriptionTopic.code}</Typography>
                </Typography>
              }
            />
          ),
          actionRightBlockComponent: <SubscriptionTopicViewActions subscriptionTopic={subscriptionTopic} />,
        }}
      >
        <Box>
          {isReady ? (
            <Box display="flex" flexDirection="column" gap={6}>
              <Box display="flex" flexDirection="column" gap={4}>
                <ContentGroup title="Basic Details" titleVariant="h3" variant="inner-page-header" />
                <InfoRow title="Display Description">
                  {subscriptionTopic?.description ? (
                    <Typography variant="body">{subscriptionTopic?.description}</Typography>
                  ) : (
                    <Typography color="darkDuskFaded.main">None</Typography>
                  )}
                </InfoRow>
                <InfoRow title="Legal Basis">
                  {subscriptionTopic.legalBasisCode ? (
                    <Box display="flex" flexWrap="wrap" gap={1}>
                      {legalBases
                        .filter(basis => basis.code === subscriptionTopic.legalBasisCode)
                        .map(basis => (
                          <Chip key={basis.code} label={basis.name} />
                        ))}
                    </Box>
                  ) : (
                    <Typography color="darkDuskFaded.main">None</Typography>
                  )}
                </InfoRow>
              </Box>
              <Box display="flex" flexDirection="column" gap={3}>
                <ContentGroup title="Communication Channels" titleVariant="h3" variant="inner-page-header" />
                <Box display="flex" flexDirection="column" gap={4}>
                  {orchestrationsRows.map(subscriptionTopic => {
                    return (
                      <InfoRow
                        title={contactMethodCodeNameMap[subscriptionTopic.key]}
                        titleAddendum={subscriptionTopic.key}
                      >
                        <SubscriptionTopicOrchestrationsInfoRowContent orchestration={subscriptionTopic} />
                      </InfoRow>
                    )
                  })}
                </Box>
              </Box>
              {translationsRows?.length ? (
                <Box display="flex" flexDirection="column" gap={3}>
                  <ContentGroup title="Translations" titleVariant="h3" variant="inner-page-header" />
                  <DataGrid
                    sx={{
                      '& .MuiDataGrid-virtualScroller': {
                        height: !(translationsRows && translationsRows.length) ? '300px' : 'auto',
                      },
                    }}
                    autosizeOnMount
                    columns={translationsTopicsColumns(organizationLanguages)}
                    disableChildrenSorting
                    disableColumnMenu
                    disableColumnPinning
                    disableColumnReorder
                    disableColumnResize
                    disableRowHoverStates
                    disableRowSelectionOnClick
                    getRowHeight={() => 'auto'}
                    getRowId={row => row.key}
                    hideFooter
                    loading={!translationsRows.length}
                    rows={translationsRows}
                  />
                </Box>
              ) : null}
            </Box>
          ) : (
            <Box display="flex" margin="100px" justifyContent="center" alignItems="center">
              <Spinner size="32px" thickness={2.5} />
            </Box>
          )}
        </Box>
      </ViewLayout>
    </>
  )
}
