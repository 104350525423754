import { useCallback } from 'react'

export enum TransponderDocumentationSlugs {
  secretProviderAWS = 'using-aws-secrets-manager-as-the-secret-provider',
  connectionHelmChart = 'deploying-transponder-to-a-local-kubernetes',
  connectionAWSElasticBeanstalk = 'deploying-transponder-to-elastic-beanstalk',
  loadBalancerAWSLoadBalancer = 'using-aws-load-balancer-controller-with-transponder',
  deployingTransponders = 'deploying-ketch-transponders',
  connectingTransponders = 'connection-the-ketch-transponder-to-ketch',
}

const documentationBaseUrl = 'https://docs.ketch.com/ketch/docs/'

const transponderSlugsMap = {
  default: TransponderDocumentationSlugs.deployingTransponders,
  'Helm Chart Method': TransponderDocumentationSlugs.connectionHelmChart,
  'AWS Elastic Beanstalk': TransponderDocumentationSlugs.connectionAWSElasticBeanstalk,
  // add more mapping as necessary
}

export const useVisitTransponderDocumentation = (deploymentMethod?: keyof typeof transponderSlugsMap) => {
  const handleVisitTransponderDocumentation = useCallback(
    (docsSlug?: TransponderDocumentationSlugs) => {
      const slug =
        docsSlug || (deploymentMethod && transponderSlugsMap[deploymentMethod]) || transponderSlugsMap.default
      window.open(`${documentationBaseUrl}${slug}`, '_blank')
    },
    [deploymentMethod],
  )

  return handleVisitTransponderDocumentation
}
