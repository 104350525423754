import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { fetchExecutionsPaginated } from '../fetchers/fetchExecutionsPaginated'
import { APIListRequestParams } from 'api/common/utils'
import {
  ExecutionResultAssetDTO,
  ExecutionResultForDBDTO,
  GetScenarioBasedExecutionResultsResponseBodyDTO,
} from '@ketch-com/figurehead'
// import { generateExecutionResultsMockData } from '../__mocks__/generateExecutionResultsMockData'

type LastPage = GetScenarioBasedExecutionResultsResponseBodyDTO

type Params = APIListRequestParams<{
  query?: string | null | undefined
  itemsPerPage?: number
  executionId?: string | null | undefined
  stepId?: string | null | undefined
  status?: string | null | undefined
  activityStepCode?: string
  enabled?: boolean
}>

export const useExecutionsInfinite = ({ onSettled, params }: { onSettled?: any; params: Params }) => {
  const { itemsPerPage: limit = 20 } = params

  const resp = useInfiniteQuery({
    queryKey: [
      ApiQueryKeys.executionResultsInfinite,
      {
        includeIssues: true,
        includeMetadata: true,
        ...params,
      },
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchAssetsResp = await fetchExecutionsPaginated({
        limit,
        start: (pageParam - 1) * limit,
        ...params,
      })

      const { data } = fetchAssetsResp

      data.assetResults = data.assetResults ?? []
      data.totalResults = data.totalResults ?? 0

      return data
    },
    getNextPageParam: ({ totalResults }: LastPage, allPages: LastPage[]) => {
      let totalAssetsFetchedSoFar = 0
      allPages?.forEach?.(p => {
        totalAssetsFetchedSoFar += p?.assetResults?.length || 0
      })
      const nextPageParam = Math.ceil(totalAssetsFetchedSoFar / limit) + 1
      const getNextPageParamReturnValue = totalAssetsFetchedSoFar === totalResults ? undefined : nextPageParam
      return getNextPageParamReturnValue
    },
    onSettled,
    enabled: params.enabled ?? true,
  })

  let infiniteAssetResults: ExecutionResultAssetDTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    infiniteAssetResults = [...infiniteAssetResults, ...(page?.assetResults || [])]
    totalResults = page?.totalResults || 0
  })

  let infiniteAssetResultsV2: ExecutionResultForDBDTO[] = []

  resp.data?.pages?.forEach?.(page => {
    infiniteAssetResultsV2 = [...infiniteAssetResultsV2, ...(page?.assetResultsV2 || [])]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: infiniteAssetResults,
    dataV2: infiniteAssetResultsV2,
    totalResults,
  }
}
