import { MetaData } from 'interfaces/common'
import { PurposeDTO } from 'interfaces/purposes/purpose'

export type WebTagDTO = {
  ID: string
  name: string
  code: string
  version: number
  enabled?: boolean
  latest?: boolean
  organizationCode: string
  appCode: string
  appName: string
  appInstanceID: string
  appInstanceName: string
  purposes: PurposeDTO[]
  advancedConsentModeEnabled?: boolean
  externalID: string
  hasIssues?: boolean
  approved: boolean
  metadata: MetaData
}

export type WebTagPurposeFormDTO = {
  tagID: WebTagDTO['ID']
  purposeIDs: PurposeDTO['ID'][]
}

export type WebTagStatusFormDTO = {
  enabled: boolean
}

export type WebTagUpdateFormDTO = {
  appCode?: string
  appInstanceID?: string
}

export type WebTagStatusBulkUpdateFormDTO = {
  tags: {
    ID: string
    appCode: string
    appInstanceID: string
  }[]
}

export type WebTagPurposeBulkUpdateFormDTO = {
  tagPurposeMappings: {
    tagID: string
    appCode: string
    appInstanceID: string
    purposeIDs?: string[]
    advancedConsentModeEnabled?: boolean
  }[]
}

// TODO:JB - Add other issue types here
export enum WebTagIssueType {
  Credentials = 'app_instance_credentials',
  GoogleTagAuthenticationError = 'googletag_TagSyncToKetch_AuthenticationError',
}

export type WebTagIssue = {
  appCode: string
  appInstanceID: string
  connectionID: string
  installedDataSystemID: string
  errorMessage: string
  issue: {
    id: string
    issue_type: WebTagIssueType
  }
}
