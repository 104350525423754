import React from 'react'
import { WorkflowExecutionStepStatusRenderer } from 'pages/orchestration/rightsQueue/viewV2/components/RightsQueueWorkflowTab/components/WorkflowExecutionStepStatusRenderer'
import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'

type Props = {
  workflowStep: WorkflowExecutionStepDTO
}

export const RedactionHeaderDetails: React.FC<Props> = ({ workflowStep }) => {
  return (
    <WorkflowExecutionStepStatusRenderer
      dataReviewStatus={workflowStep?.dataReviewStatus}
      status={workflowStep.status!}
      dueAt={workflowStep?.dueAt}
      code="review_redact"
    />
  )
}
