import React from 'react'
import { isNumber } from 'lodash'

import { Text } from 'components/ui-kit/typography/Text'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'

export interface Props {
  /** Value to be rendered */
  value?: React.ReactNode
  size?: number
  weight?: 'normal' | 500 | 600 | 700 | 800
  onClick?: React.MouseEventHandler<HTMLSpanElement>
}

export const TextCellRenderer: React.FC<Props> = ({ value, size = 14, weight = 'normal', onClick }) => (
  <>
    {value || isNumber(value) ? (
      <Text
        onClick={onClick}
        title={typeof value === 'string' ? value : undefined}
        size={size}
        weight={weight}
        color="black"
      >
        {value}
      </Text>
    ) : (
      <EmptyValueRenderer />
    )}
  </>
)
