import { MaybeNull, ObjectLiteral } from 'interfaces'
import { UserRoleDTO } from 'interfaces/users/userRole'
import { UserDTO } from 'interfaces/users/users'
import { UserFormValues } from './interfaces'
import { ROLE_GROUPS } from 'utils/constants/organizationEntitlements'
import { NO_PERMISSION } from '../../constants'
import { roleGroupNames } from 'pages/settings/users/view/utils/getUserRolesDisplayData'

// Initial form values are a map of group name to role code
const getExistingUserRoles = (userRoles: UserRoleDTO[]): ObjectLiteral<string> => {
  let start: ObjectLiteral<string> = {}
  userRoles.forEach(r => {
    start[r.group] = r.code
  })
  return start
}

type GetGroupRoleValueArgs = { group: string }

const getGroupRoleInitialValue = ({ group }: GetGroupRoleValueArgs): string => {
  switch (group) {
    case ROLE_GROUPS.Organization: {
      return 'org_user'
    }
    default: {
      return NO_PERMISSION
    }
  }
}

type GetUserFormValuesArgs = {
  user: MaybeNull<UserDTO>
}

export const getUserFormValues = ({ user }: GetUserFormValuesArgs): UserFormValues => {
  if (!user) {
    return {
      firstName: '',
      lastName: '',
      email: '',
      roles: Object.keys(roleGroupNames).reduce<ObjectLiteral>(
        (acc, group) => ({
          ...acc,
          [group]: getGroupRoleInitialValue({ group }),
        }),
        {},
      ),
    }
  }

  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    roles: getExistingUserRoles(user.roles),
  }
}
