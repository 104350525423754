import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { FetchConnectionsResponseBodyDTO } from '@ketch-com/figurehead'
import { APIRequestParams } from 'api/common/utils'

type Params = APIRequestParams<{
  isV2FeatureFlagEnabled?: boolean
}>
export const fetchConnections = ({ isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/assets/connections`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/assets/connections`

  return API.get<FetchConnectionsResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {},
      },
    }),
  )
}
