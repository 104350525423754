import React, { useContext, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { PreferenceField } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import { TabGroup, Tab, TabPanel, theme } from '@ketch-com/deck'

import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { Spinner } from '@ketch-com/deck'
import { SubscriptionTopicsList, SubscriptionTopicsSwitchButton } from './components'
import { SubscriptionTopicsTab } from '../../constants'

type Props = {}

export const SubscriptionTopics: React.FC<Props> = () => {
  const { sidebarActiveTab } = useContext(ExperienceUpsertContext)
  const [activeTab, setActiveTab] = useState(0)
  const { data: orgLangs, isLoading: isLoadingOrgLangs } = useOrganizationLanguages()
  const organizationLanguages = useMemo(() => orgLangs.filter(({ system }) => !system), [orgLangs])

  if (sidebarActiveTab !== PreferenceField.SUBSCRIPTIONS_TOPICS) return null

  if (isLoadingOrgLangs)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" py={4}>
        <Spinner size={35} thickness={3} />
      </Box>
    )

  return (
    <Box>
      <TabGroup size="small" value={activeTab}>
        <Tab label={SubscriptionTopicsTab.SubscriptionTopicsList} onClick={() => setActiveTab(0)} />
        <Tab label={SubscriptionTopicsTab.SubscriptionTopicsSwitchButton} onClick={() => setActiveTab(1)} />
      </TabGroup>

      <TabPanel value={activeTab} index={0} sx={{ padding: theme.spacing(3, 0, 0) }}>
        <SubscriptionTopicsList />
      </TabPanel>

      <TabPanel value={activeTab} index={1} sx={{ padding: theme.spacing(3, 0, 0) }}>
        <SubscriptionTopicsSwitchButton organizationLanguages={organizationLanguages} />
      </TabPanel>
    </Box>
  )
}
