import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchOnboardingProgress } from '../fetchers/fetchOnboardingProgress'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.onboardingProgressForCanduIntegration,
  queryFn: fetchOnboardingProgress,
  select: res => res?.data?.onboardingProgress || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useOnboardingProgress = (config: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
