import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { ConfirmTrackersWithNewPurposesResponseBody } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type params = {
  encodedTrackerKey: string
}

export const confirmTrackerWithNewPurposes = ({ encodedTrackerKey }: params) =>
  API.put<ConfirmTrackersWithNewPurposesResponseBody>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/tracker/${encodedTrackerKey}/new-purposes`,
    }),
  )
