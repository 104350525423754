import React, { useContext, useCallback } from 'react'
import { FormTemplateUpsertContext } from 'pages/consentAndRights/forms/templates/upsert/context/FormTemplateUpsertContextProvider'
import { FormTemplateDTO } from '@ketch-com/figurehead'

import Box from '@mui/material/Box'
import { FieldArray, useFormikContext } from 'formik'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { AddFieldRow, DraggableFormField } from './components'

export const reorderDropdownOptions = <T,>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

type Props = {}

export const FormFields: React.FC<Props> = () => {
  const { values, setFieldValue } = useFormikContext<FormTemplateDTO>()
  const { activeSectionIndex } = useContext(FormTemplateUpsertContext)

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const formFields =
        typeof activeSectionIndex === 'number' ? values?.sections?.[activeSectionIndex]?.formFields : []
      // handle item dropped outside the list area
      if (!result.destination) return
      const startIndex = result.source.index
      const endIndex = result.destination.index
      const newItems = reorderDropdownOptions(formFields || [], startIndex, endIndex)
      setFieldValue(`sections.${activeSectionIndex}.formFields`, newItems)
    },
    [setFieldValue, values?.sections, activeSectionIndex],
  )

  if (typeof activeSectionIndex !== 'number') return null

  const formFields = values?.sections?.[activeSectionIndex]?.formFields

  return (
    <FieldArray
      name={`sections.[${activeSectionIndex}].formFields`}
      render={({ push, remove }) => (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => {
              const { innerRef, droppableProps } = provided
              return (
                <Box {...droppableProps} ref={innerRef}>
                  {(formFields || []).map((field, formFieldIndex) => {
                    const isDragDisabled = (formFields || [])?.length === 1

                    return (
                      <Draggable
                        key={field.id}
                        draggableId={field?.id || ''}
                        index={formFieldIndex}
                        isDragDisabled={isDragDisabled}
                      >
                        {(provided, snapshot) => (
                          <DraggableFormField
                            key={field.id}
                            formFieldIndex={formFieldIndex}
                            remove={remove}
                            provided={provided}
                            snapshot={snapshot}
                            option={field}
                          />
                        )}
                      </Draggable>
                    )
                  })}

                  {provided.placeholder}

                  {/* Add Item Button */}

                  <AddFieldRow push={push} />
                </Box>
              )
            }}
          </Droppable>
        </DragDropContext>
      )}
    />
  )
}
