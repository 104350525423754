import { MaybeNull } from 'interfaces'
import { WorkflowExecutionDTO, WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { WorkflowExecutionStepStatusRendererLegacy } from 'pages/orchestration/rightsQueue/viewV2/components/RightsQueueWorkflowTab/components/reassignAutomatedStep/WorkflowExecutionStepStatusRendererLegacy'
import { TransponderDsrOverviewModal } from '../TransponderDsrOverviewModal'
import { Box, Typography } from '@mui/material'
import { Button, PopUp } from '@ketch-com/deck'

type Props = {
  workflowExecution: MaybeNull<WorkflowExecutionDTO>
  workflowExecutionStep: WorkflowExecutionStepDTO
  onClose: () => void
}

export const WorkflowExecutionAutomatedStepViewModal: React.FC<Props> = ({
  workflowExecution,
  workflowExecutionStep,
  onClose,
}) => {
  const { status, stepID } = workflowExecutionStep
  const resolutionDetails = workflowExecution?.results?.[stepID]?.result?.msg
  const isDsrTransponderStep =
    workflowExecutionStep?.activityStepCode === 'dsrTransponder' ||
    workflowExecutionStep?.activityStepCode === 'internalSystemDSR'
  // isAssetManagerV3AssetIDs flags whether results are from deprecated assetManager v2 which doesn't support the detailed results screen or v3 which does (KD-7936)
  const isAssetManagerV3AssetIDs =
    isDsrTransponderStep && workflowExecution?.results?.[stepID]?.[0]?.areWindlassAssetIDs

  if (isDsrTransponderStep && workflowExecutionStep?.manualInterventionStatus !== 2 && isAssetManagerV3AssetIDs) {
    return <TransponderDsrOverviewModal onCancel={onClose} workflowExecutionStep={workflowExecutionStep} />
  }
  return (
    <PopUp
      popUpWidth="550px"
      title="Automated Activity"
      popUpTitleStatusComponent={<WorkflowExecutionStepStatusRendererLegacy status={workflowExecutionStep.status} />}
      variant="dialog"
      onClose={onClose}
      popUpActionChildren={
        <>
          <Button color="primary" onClick={onClose} size="large">
            Close
          </Button>
        </>
      }
    >
      <Typography>
        {status === WorkflowExecutionStepStatus.IN_PROGRESS && 'Activity is currently being processed.'}
        {status === WorkflowExecutionStepStatus.PENDING && 'Processing will start shortly.'}
        {status === WorkflowExecutionStepStatus.SUCCESS && 'Activity successfully completed.'}
      </Typography>

      {!!resolutionDetails && (
        <Box mt={4}>
          <Typography variant="label">Resolution Details</Typography>
          <br />
          <Typography>{resolutionDetails}</Typography>
        </Box>
      )}
    </PopUp>
  )
}
