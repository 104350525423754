import { useFilesInfoList } from 'api/files/queries/useFilesInfoList'
import { useWorkflowExecutionStepDetailsV2 } from 'api/workflowExecutions/queries/useWorkflowExecutionStepDetailsV2'
import { showToast } from 'components/modals/AlertComponent'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
import { useContext, useMemo } from 'react'
import { WorkflowExecutionAppStepFormValues } from '../interfaces'
import { getValidationSchema } from '../utils'

export const useFormUtils = () => {
  // context
  const { rightInvocation, stepId, workflowExecutionId } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const { data: stepDetails, isLoading: isStepDetailsLoading } = useWorkflowExecutionStepDetailsV2({
    params: { workflowExecutionId, stepId: stepId! },
    enabled: !!workflowExecutionId,
    onError: () => {
      showToast({ content: 'Failed to fetch details', type: 'error' })
    },
  })

  // TODO:GP remove below calls once BE is updated
  // NOTE: this will require replacing all instances of FileInfoDTO with the new generated type
  const { data: resolutionAttachments } = useFilesInfoList({
    params: {
      version: 'resolution_attachments',
      folder: `${rightInvocation?.id}_${stepId}`,
    },
  })

  const hasResolutionNotes = stepDetails?.executionData?.manualTask?.hasResolutionNotes || false
  const hasResolutionAttachments = stepDetails?.executionData?.manualTask?.hasResolutionAttachments || false
  const resolutionDetails = stepDetails?.manualInterventionData?.resolutionDetails || ''

  const validationSchema = useMemo(
    () =>
      getValidationSchema({
        hasResolutionAttachments,
        hasResolutionNotes,
      }),
    [hasResolutionAttachments, hasResolutionNotes],
  )

  const initialValues: WorkflowExecutionAppStepFormValues = useMemo(
    () => ({
      resolutionAttachments,
      resolutionDetails,
    }),
    [resolutionAttachments, resolutionDetails],
  )

  return {
    resolutionAttachments,
    initialValues,
    isStepDetailsLoading,
    stepDetails,
    validationSchema,
  }
}
