import { Box, Typography } from '@mui/material'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'

// types
import { WorkflowExecutionStepStatusDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'

type Props = {
  startedAt?: number
  updatedAt?: number
  completedAt?: number
  status?: WorkflowExecutionStepStatusDTO
}

export const EndDetailsHeaderTimestamp: React.FC<Props> = ({ updatedAt, completedAt = null, status }) => {
  const inProgress = status === WorkflowExecutionStepStatus.IN_PROGRESS
  const completed = status === WorkflowExecutionStepStatus.SUCCESS && updatedAt === completedAt

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="smallBody" color="darkDuskFaded.main">
        {!!inProgress ? 'Last Updated' : completed ? 'Completed' : 'Last Edited'}
      </Typography>
      <Typography color="darkDusk.main" variant="smallBody">
        <FormattedDateRenderer date={updatedAt} />
      </Typography>
    </Box>
  )
}
