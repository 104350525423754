import { useContext } from 'react'
import { BuilderContext } from '../../../Builder'
import Box from '@mui/material/Box'
import { Typography, styled } from '@mui/material'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from '../../../../../../../themes-v3/upsert/utils/useThemeValidationSchema'
import { ItemStyle } from '@ketch-sdk/ketch-types'
import { SectionEmptyState } from '../../SectionEmptyState'
import { clamp } from 'lodash'
import { BUILDER_CONSTANTS } from '../../../utils/enums'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'

const ButtonsBox = styled(Box)({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '12px',
  height: 'fit-content',
  flexWrap: 'nowrap',
  padding: '8px 16px 16px 16px',
  width: 'fit-content',
})

const ButtonBox = styled(Box)({
  // Handles button resizing and text wrapping within container
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  alignItems: 'flex-end',
  minWidth: 0,
  width: 'fit-content',
  padding: '6.5px 16px',
  gap: '12px',
  height: 'fit-content',
  textAlign: 'center',
})

export const ProgressiveActionButtons: React.FC = () => {
  const { themeConfig, experienceConfig } = useContext(BuilderContext)

  // Experience config
  const primaryText = getIn(experienceConfig, experienceUpsertFormKeys.progressiveExperienceActionButtonsPrimaryText)
  const secondaryText = getIn(
    experienceConfig,
    experienceUpsertFormKeys.progressiveExperienceActionButtonsSecondaryText,
  )
  const tertiaryText = getIn(experienceConfig, experienceUpsertFormKeys.progressiveExperienceActionButtonsTertiaryText)
  const tertiaryVisible = getIn(
    experienceConfig,
    experienceUpsertFormKeys.progressiveExperienceActionButtonsTertiaryVisible,
  )

  // Theme config - We are using the banner theme for demo
  const primaryStyle = getIn(themeConfig, themeUpsertFormKeys.bannerPrimaryButtonStyle)
  const primaryBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.bannerPrimaryButtonBackgroundColor)
  const primaryTextColor = getIn(themeConfig, themeUpsertFormKeys.bannerPrimaryButtonTextColor)
  const primaryCornerRadius = clamp(
    getIn(themeConfig, themeUpsertFormKeys.bannerPrimaryButtonCornerRadius),
    BUILDER_CONSTANTS.MIN_BORER_RADIUS,
    BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
  )

  const secondaryStyle = getIn(themeConfig, themeUpsertFormKeys.bannerSecondaryButtonStyle)
  const secondaryBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.bannerSecondaryButtonBackgroundColor)
  const secondaryTextColor = getIn(themeConfig, themeUpsertFormKeys.bannerSecondaryButtonTextColor)
  const secondaryCornerRadius = clamp(
    getIn(themeConfig, themeUpsertFormKeys.bannerSecondaryButtonCornerRadius),
    BUILDER_CONSTANTS.MIN_BORER_RADIUS,
    BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
  )

  const tertiaryStyle = getIn(themeConfig, themeUpsertFormKeys.bannerTertiaryButtonStyle)
  const tertiaryBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.bannerTertiaryButtonBackgroundColor)
  const tertiaryTextColor = getIn(themeConfig, themeUpsertFormKeys.bannerTertiaryButtonTextColor)
  const tertiaryCornerRadius = clamp(
    getIn(themeConfig, themeUpsertFormKeys.bannerTertiaryButtonCornerRadius),
    BUILDER_CONSTANTS.MIN_BORER_RADIUS,
    BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
  )

  return (
    <ButtonsBox>
      <ButtonBox
        sx={{
          border: `1.5px solid ${primaryBackgroundColor}`,
          background: primaryStyle === ItemStyle.Filled ? primaryBackgroundColor : '',
          borderRadius: primaryCornerRadius + 'px',
        }}
      >
        <Typography variant={'label'} color={primaryTextColor}>
          {primaryText}
        </Typography>
      </ButtonBox>

      <ButtonBox
        sx={{
          border: `1.5px solid ${secondaryBackgroundColor}`,
          background: secondaryStyle === ItemStyle.Filled ? secondaryBackgroundColor : '',
          borderRadius: secondaryCornerRadius + 'px',
        }}
      >
        <Typography variant={'label'} color={secondaryTextColor}>
          {secondaryText}
        </Typography>
      </ButtonBox>

      {tertiaryVisible ? (
        <ButtonBox
          sx={{
            border: `1.5px solid ${tertiaryBackgroundColor}`,
            background: tertiaryStyle === ItemStyle.Filled ? tertiaryBackgroundColor : '',
            borderRadius: tertiaryCornerRadius + 'px',
          }}
        >
          <Typography variant={'label'} color={tertiaryTextColor}>
            {tertiaryText}
          </Typography>
        </ButtonBox>
      ) : (
        <SectionEmptyState section={'Button'} width={'105px'} justifyContent={'center'} compact />
      )}
    </ButtonsBox>
  )
}
