import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Grid, TextField } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { FieldArray, useFormikContext } from 'formik'
import clsx from 'clsx'
import { Autocomplete } from '@mui/material'

import { Text } from 'components/ui-kit/typography/Text'
import { ReactComponent as EffectsIcon } from 'assets/icons/effects.svg'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { Button } from '@ketch-com/deck'
import { IconSpacer } from './IconSpacer'
import { CRITERIA_OPTIONS, EFFECT_OPTIONS } from '../constants'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { ReactComponent as RemoveBadgeIcon } from 'assets/icons/remove_icon_bold.svg'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import { PolicyFormValues } from 'interfaces/policies/PolicyFormValues'
import { getRenderSelectedLabel } from 'pages/assetManager/components/ManageLabelsInput/utils'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { useAutocompleteStyles } from '../hooks'
import { AutocompleteLabeledInput, AutocompleteNameInput } from '.'
import { getFilterLabeledOptions, getFilterNamedOptions } from '../utils'

const PREFIX = 'EffectsFormSection'

const classes = {
  container: `${PREFIX}-container`,
  withBorderBottom: `${PREFIX}-withBorderBottom`,
  plusButton: `${PREFIX}-plusButton`,
  deleteButton: `${PREFIX}-deleteButton`,
  deleteSectionButton: `${PREFIX}-deleteSectionButton`,
  withMarginRight: `${PREFIX}-withMarginRight`,
  withPointer: `${PREFIX}-withPointer`,
  hidden: `${PREFIX}-hidden`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing, shadows } }) => ({
  [`&.${classes.container}`]: {
    marginTop: spacing(2),
    backgroundColor: palette.white.main,
    borderRadius: spacing(1.5),
    boxShadow: shadows[1],
  },

  [`& .${classes.withBorderBottom}`]: {
    borderBottom: `1px solid ${palette.iron.main}`,
  },

  [`& .${classes.plusButton}`]: {
    borderRadius: '50%',
    height: spacing(3.5),
    width: spacing(3.5),
    padding: spacing(2),
    minWidth: 'unset',
  },

  [`& .${classes.deleteButton}`]: {
    borderRadius: '50%',
    height: spacing(3.5),
    width: spacing(3.5),
    padding: spacing(2),
    fontSize: 16,
    minWidth: 'unset',
    color: 'black',
  },

  [`& .${classes.deleteSectionButton}`]: {
    borderRadius: '50%',
    height: spacing(3.5),
    width: spacing(3.5),
    padding: spacing(2),
    minWidth: 'unset',
    fontSize: 18,
  },

  [`& .${classes.withMarginRight}`]: {
    marginRight: spacing(3),
  },

  [`& .${classes.withPointer}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.hidden}`]: {
    opacity: 0,
  },
}))

type Props = {
  onAddAttributeEffect: (event: React.MouseEvent) => void
  onRemoveAttributeEffect: (event: React.MouseEvent) => void
  onRemoveEffectsFormSection: (event: React.MouseEvent) => void
}

export const EffectsFormSection: React.FC<Props> = ({
  onAddAttributeEffect,
  onRemoveAttributeEffect,
  onRemoveEffectsFormSection,
}) => {
  const autocompleteClasses = useAutocompleteStyles()

  const { /* values, isSubmitting, */ setFieldValue } = useFormikContext<PolicyFormValues>()

  const handleAutocompleteLabeledOnChange = (
    event: React.ChangeEvent<{}>,
    value: ManageLabelModalLabel[],
    index: number,
  ): void => {
    if (value?.length > 0) {
      setFieldValue(`attributeEffects[${index}].labelName`, { ...value[0] })
    } else {
      setFieldValue(`attributeEffects[${index}].labelName`, {})
    }
  }

  const handleAutocompleteNamedOnChange = (
    event: React.ChangeEvent<{}>,
    value: string[],
    typeEnum: number,
    index: number,
  ): void => {
    if (value?.length > 0) {
      setFieldValue(`attributeEffects[${index}].labelName`, {
        type: typeEnum,
        named: value[0],
        labeled: {}, // optional, leave value empty if a boolean label type
      })
    } else {
      setFieldValue(`attributeEffects[${index}].labelName`, {})
    }
  }

  return (
    <StyledBox display="flex" flexDirection="column" className={classes.container}>
      {/* Row 1 */}

      <Box display="flex" alignItems="center" justifyContent="space-between" p={3} className={classes.withBorderBottom}>
        <Box display="flex" alignItems="center">
          <Box mr={3}>
            <EffectsIcon />
          </Box>
          <Box mr={2}>
            <Text size={20} weight={700}>
              With the following attribute effects:
            </Text>
          </Box>

          <Button id="addAccessConfig" onClick={onAddAttributeEffect} className={classes.plusButton} color="tertiary">
            <AddIcon />
          </Button>
        </Box>
        <Box ml={2}>
          <Button
            id="addAccessConfig"
            onClick={onRemoveEffectsFormSection}
            color="primary"
            className={classes.deleteSectionButton}
          >
            <DeleteIcon fontSize="inherit" />
          </Button>
        </Box>
      </Box>

      {/* Row 2 */}

      <FieldArray name="valuesSerialized">
        {({ form }) => {
          const { values, setFieldValue, errors }: any = form
          const { attributeEffects } = values

          return (
            <Box pt={3} pb={1}>
              {attributeEffects.map((a: any, index: number) => (
                <Box
                  className={clsx({
                    [classes.withBorderBottom]: index !== attributeEffects?.length - 1 ? 3 : 0,
                  })}
                  key={`${a}_${index}`}
                  px={3}
                  pb={index !== attributeEffects?.length - 1 ? 2 : 0}
                  mb={index !== attributeEffects?.length - 1 ? 3 : 0}
                >
                  {/* Row 1 */}

                  <Box mb={2} display="flex" alignItems="center">
                    <IconSpacer />

                    <Grid key={index} container spacing={2} alignItems="flex-end">
                      <Grid item xs={4}>
                        <FormDropdown
                          fullWidth
                          className={classes.withMarginRight}
                          label="Effect"
                          required
                          name={`attributeEffects[${index}].effect`}
                          placeholder="Select Effect"
                          items={EFFECT_OPTIONS}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        {attributeEffects.length > 1 && (
                          <Box ml={2}>
                            <Button
                              id={index.toString()}
                              onClick={onRemoveAttributeEffect}
                              className={classes.deleteButton}
                              color="tertiary"
                            >
                              <DeleteIcon fontSize="inherit" />
                            </Button>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  {/* Row 2 */}

                  <Grid key={index} container spacing={2} alignItems="flex-end">
                    <Grid item xs={3}>
                      <Box display="flex" alignItems="flex-end" width="100%">
                        <IconSpacer />
                        <Text size={16} weight={700}>
                          attribute(s):
                        </Text>
                      </Box>
                      <Error className={classes.hidden}>hidden</Error>
                    </Grid>

                    <Grid item xs={3}>
                      <FormDropdown
                        hasErrorSpacer
                        fullWidth
                        className={classes.withMarginRight}
                        label="Criteria"
                        required
                        name={`attributeEffects[${index}].criteria`}
                        placeholder="Select Criteria"
                        items={CRITERIA_OPTIONS}
                        onChange={() => {
                          setFieldValue(`attributeEffects[${index}].labelName`, '')
                        }}
                      />
                    </Grid>

                    <Grid item xs={5}>
                      {/* Placeholder/Disabled Input, before "labeled" or "named" is selected */}

                      {attributeEffects[index].criteria === '' && (
                        <Autocomplete
                          autoComplete={false}
                          multiple
                          popupIcon={null}
                          classes={{ inputRoot: autocompleteClasses.inputRoot }}
                          disabled={attributeEffects[index].criteria === ''}
                          id={`attributeEffects[${index}].labelName`}
                          options={[]}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label=""
                              placeholder="Select Criteria"
                              inputProps={{
                                ...params.inputProps,
                                form: {
                                  autoComplete: 'off',
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                              }}
                            />
                          )}
                        />
                      )}

                      {/* Label Input */}

                      {attributeEffects[index].criteria === 'labeled' &&
                        Object.keys(attributeEffects[index].labelName)?.length === 0 && (
                          <AutocompleteLabeledInput
                            handleAutocompleteLabeledOnChange={handleAutocompleteLabeledOnChange}
                            index={index}
                            valueKey="attributeEffects"
                            filterOptions={options => getFilterLabeledOptions({ options, attributeEffects })}
                          />
                        )}

                      {/* Label Input With Value */}

                      {attributeEffects[index].criteria === 'labeled' &&
                        Object.keys(attributeEffects[index].labelName)?.length > 1 && (
                          <>
                            <Label>Labeled</Label>

                            <Box display="flex" alignItems="center" className={autocompleteClasses.proxyLabelsInput}>
                              {getRenderSelectedLabel({
                                value: attributeEffects[index].labelName,
                                onRemove: () => {
                                  setFieldValue(`attributeEffects[${index}].labelName`, {})
                                },
                              })}
                            </Box>
                          </>
                        )}

                      {/* Name Input */}

                      {attributeEffects[index].criteria === 'named' && !attributeEffects[index].labelName?.named && (
                        <AutocompleteNameInput
                          index={index}
                          handleAutocompleteNamedOnChange={handleAutocompleteNamedOnChange}
                          valueKey="attributeEffects"
                          filterOptions={options => getFilterNamedOptions({ options, attributeEffects })}
                        />
                      )}

                      {/* Name Input With Value */}

                      {attributeEffects[index].criteria === 'named' && attributeEffects[index].labelName?.named && (
                        <Box>
                          <Label>Named</Label>
                          <Box display="flex" alignItems="center" className={autocompleteClasses.proxyInput}>
                            <Box display="flex" alignItems="center" className={autocompleteClasses.selectedValueTag}>
                              {attributeEffects[index].labelName?.named}
                              <RemoveBadgeIcon
                                className={autocompleteClasses.removeSelectedValueBadgeIcon}
                                onClick={() => {
                                  setFieldValue(`attributeEffects[${index}].labelName`, {})
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {!errors?.attributeEffects?.[index]?.labelName && (
                        <Error className={classes.hidden}>hidden</Error>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
          )
        }}
      </FieldArray>
    </StyledBox>
  )
}
