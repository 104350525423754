import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchLegalBases } from 'api/legalBases/fetchers/fetchLegalBasises'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.legalBases,
  queryFn: fetchLegalBases,
  select: res => res?.data?.legalBases || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useLegalBases = (config?: Config) =>
  useCustomQuery({
    ...config,
    params: {
      start: 0,
      limit: 1000,
      ...config?.params,
    },
  })
