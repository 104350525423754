import { useContext } from 'react'

import { showToast } from 'components/modals/AlertComponent'
import { FinalizeRequestFormValues, getValidationSchema, getInitialValues } from '../utils'
import { useUploadFile } from 'api/files/mutations/useUploadFile'
import { AccessControlType } from 'components/ui-kit/form/imageUpload/hooks'
import { RightsQueueViewContext } from '../../../context/RightsQueueViewContext'
import moment from 'moment'
import { useForceCompleteRightInvocation } from 'api/rightInvocationsV2/mutations/useForceCompleteRightInvocation'

type Args = {
  onClose: () => void
}

export const useFinalizeRequestModalUtils = ({ onClose }: Args) => {
  const { rightInvocation, refetchRightInvocation } = useContext(RightsQueueViewContext)

  const isCoverLetterRequired = false // always optional per product spec: https://ketch-com.slack.com/archives/C05UY1TRLUV/p1698875157526269
  const isMessageRequired = false // always optional per product spec: https://ketch-com.slack.com/archives/C05UY1TRLUV/p1698875157526269

  const initialValues = getInitialValues()
  const validationSchema = getValidationSchema({ isCoverLetterRequired, isMessageRequired })

  const { mutateAsync: handleUploadFile, isLoading: isUploadingFile } = useUploadFile({
    onError: () => showToast({ content: 'Failed to upload Cover Letter', type: 'error' }),
  })

  const { mutateAsync: handleForceCompleteRightInvocation, isLoading: isForceCompleteLoading } =
    useForceCompleteRightInvocation({
      onSuccess: async () => {
        showToast({
          content: 'Request finalized successfully',
          type: 'success',
        })
        await refetchRightInvocation()
        onClose()
      },
      onError: () => {
        showToast({
          content: 'Failed to finalize request',
          type: 'error',
        })
      },
    })

  const resolve = async (values: FinalizeRequestFormValues) => {
    const acceptedFile = values?.coverLetter?.[0]
    const secondsFromEpochTime = moment().unix()
    const uploadFolder = `${rightInvocation?.id}_forceComplete_${secondsFromEpochTime}`
    const uploadVersion = 'cover_letter'
    const acceptedFileName = values?.coverLetter?.[0]?.name

    if (acceptedFile) {
      try {
        const fileUploadParams = {
          file: acceptedFile,
          acl: `${AccessControlType.signed}`,
          bucket: '',
          folder: uploadFolder,
          version: uploadVersion,
        }
        await handleUploadFile({ params: fileUploadParams })
      } catch (error) {
        console.error(error)
      }
    }

    const forceCompleteRightInvocationFormData = {
      finalizedStatus: values?.finalizedStatus,
      appealWorkflowDefinitionCode: values?.appealWorkflowDefinitionCode,
      privacyPortalDetails: {
        subjectCommunicationMessage: values?.subjectCommunicationMessage,
        coverLetter: {
          version: uploadVersion,
          folder: uploadFolder,
          key: acceptedFileName,
        },
      },
      reason: values?.reasonForFinalization,
      requestAllowsAppeal: values?.requestAllowsAppeal,
      shouldPropagateToPrivacyPortal: true, // hard-coded per BE spec
    }

    try {
      await handleForceCompleteRightInvocation({
        // Notes about payload from BE here: https://ketch-com.atlassian.net/browse/KD-10045
        params: {
          requestId: rightInvocation?.id || '',
          formData: forceCompleteRightInvocationFormData,
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  return {
    initialValues,
    validationSchema,
    resolve,
    rightInvocation,
    isUploading: isUploadingFile || isForceCompleteLoading,
    isMessageRequired,
    isCoverLetterRequired,
    allowAppeal: !!rightInvocation?.appeal?.settings?.rightAllowsAppeal,
    timeToInvokeAppealInDays: rightInvocation?.appeal?.settings?.timeToInvokeAppealInDays || 0,
  }
}
