import React from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import { DeleteOutline, EditOutlined, VisibilityOutlined } from '@mui/icons-material'

import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { ThemeStatus } from 'interfaces/themes-v2/themeStatus'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { RoutesManager } from 'utils/routing/routesManager'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { DeleteThemeModal } from 'components/modals/themes/DeleteThemeModal'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'

type Props = {
  theme: ThemeDTO
}

export const ThemesListDropdown: React.FC<Props> = ({ theme }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()

  const [{ isEditConfirmationOpen, isDeleteOpen }, setState] = useSetState({
    isEditConfirmationOpen: false,
    isDeleteOpen: false,
  })

  const { code, status } = theme
  const isPublished = status === ThemeStatus.PUBLISHED
  const isPermittedToManageThemes = isPermitted(PERMISSIONS.THEME_WRITE)

  const handleRedirectToUpsertTheme = () => {
    navigate(RoutesManager.deployment.themes.upsert.root.getURL({ code }))
  }

  return (
    <>
      <ButtonOptions
        items={[
          {
            content: 'Edit',
            icon: <EditOutlined />,
            hidden: !isPermittedToManageThemes,
            onClick: () => {
              if (isPublished) {
                setState({ isEditConfirmationOpen: true })
              } else {
                handleRedirectToUpsertTheme()
              }
            },
          },
          {
            content: 'Preview',
            icon: <VisibilityOutlined />,
            hidden: !isPermittedToManageThemes,
            onClick: () => navigate(RoutesManager.deployment.themes.preview.root.getURL({ code })),
          },
          {
            content: 'Delete',
            icon: <DeleteOutline />,
            hidden: !isPermittedToManageThemes || isPublished,
            onClick: () => setState({ isDeleteOpen: true }),
          },
        ]}
      />

      {isDeleteOpen && (
        <DeleteThemeModal
          themeCode={code}
          onSubmit={() => {
            setState({ isDeleteOpen: false })
            return queryClient.refetchQueries(ApiQueryKeys.themesPaginated)
          }}
          onCancel={() => {
            setState({ isDeleteOpen: false })
          }}
        />
      )}

      {isEditConfirmationOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.THEME}
          onSubmit={handleRedirectToUpsertTheme}
          onCancel={() => {
            setState({ isEditConfirmationOpen: false })
          }}
        />
      )}
    </>
  )
}
