export enum OptionEnum {
  Optional = 'OPTIONAL',
  Required = 'REQUIRED',
}

export enum ConfigSelectorOptions {
  Automated = 'automated',
  Manual = 'manual',
}
export const configOptions = Object.entries(ConfigSelectorOptions).map(([name, id]) => ({ name, id }))

export enum FinalizeRequestAsOptions {
  Completed = 'completed',
  Rejected = 'rejected',
}

export const finalizeRequestAsOptions = Object.entries(FinalizeRequestAsOptions).map(([name, id]) => ({ name, id }))

// Convert enum value to boolean
export const convertEnumToBool = (value: string) => value === OptionEnum.Required

// Convert it to an array
export const optionsArray = Object.entries(OptionEnum).map(([name, id]) => ({ name, id }))
