import { useCallback } from 'react'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'

export type CustomMutationConfig<MutationType extends (...args: any[]) => any> = Parameters<MutationType>[0]

export function useBindMutationStaticParams<MutationFnData, MutationFnParams, MutationFnStaticParams>(
  mutation: UseMutationResult<MutationFnData, any, MutationFnParams, unknown>,
  staticParams?: MutationFnStaticParams,
) {
  const { mutate, mutateAsync, ...rest } = mutation
  const serializedStaticParams = JSON.stringify(staticParams)

  const mutateFnStable = useCallback(
    (
      customMutateParams?: {
        params?: Omit<Parameters<typeof mutate>[0], keyof MutationFnStaticParams>
      } & Parameters<typeof mutate>[1],
    ) => {
      const { params, ...rest } = customMutateParams || {}
      mutate({ ...staticParams, ...params } as MutationFnParams, rest)
    },
    [mutate, serializedStaticParams], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const mutateAsyncFnStable = useCallback(
    (
      customMutateAsyncParams?: {
        params?: Omit<Parameters<typeof mutateAsync>[0], keyof MutationFnStaticParams>
      } & Parameters<typeof mutateAsync>[1],
    ) => {
      const { params, ...rest } = customMutateAsyncParams || {}
      const mutationParams = { ...staticParams, ...params } as MutationFnParams
      return mutateAsync(mutationParams, rest)
    },
    [mutateAsync, serializedStaticParams], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    ...rest,
    mutate: mutateFnStable,
    mutateAsync: mutateAsyncFnStable,
  }
}

export function createUseMutation<MutationFnData, MutationFnParams>({
  mutationFn,
}: {
  mutationFn: (params: MutationFnParams) => Promise<MutationFnData>
}) {
  return function useCustomMutation(options?: UseMutationOptions<MutationFnData, any, MutationFnParams, unknown>) {
    return useMutation(mutationFn, options)
  }
}
