import React, { useContext } from 'react'

import { PreferenceField, fieldNames } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { InterpolatedTextArea } from 'pages/consentAndRights/experiences/upsert/components/common/interpolatedTextArea/InterpolatedTextArea'
import { Typography } from '@mui/material'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'

type Props = {}

export const Preference: React.FC<Props> = () => {
  const { sidebarActiveTab, organizationLanguages } = useContext(ExperienceUpsertContext)
  return (
    <>
      {sidebarActiveTab === PreferenceField.PREFERENCES_TAB_NAME && (
        <>
          <Typography variant="h4" color="fadedDarkGrey.main" mb={2.5}>
            Translations
          </Typography>

          {organizationLanguages.map(({ language }) => (
            <FormRow key={language.code} marginBottom={24}>
              <FormInput
                fullWidth
                required={false}
                name={fieldNames.preference.consents.tabName.translation(language.code)}
                label={`Display Text (${language.englishName})`}
              />
            </FormRow>
          ))}
        </>
      )}

      {sidebarActiveTab === PreferenceField.PREFERENCES_BODY_TITLE && (
        <>
          <Typography variant="h4" color="fadedDarkGrey.main" mb={2.5}>
            Translations
          </Typography>

          {organizationLanguages.map(({ language }) => (
            <FormRow key={language.code} marginBottom={24}>
              <FormInput
                fullWidth
                required={false}
                name={fieldNames.preference.consents.bodyTitle.translation(language.code)}
                label={`Display Text (${language.englishName})`}
              />
            </FormRow>
          ))}
        </>
      )}

      {sidebarActiveTab === PreferenceField.PREFERENCES_BODY_DESCRIPTION && (
        <>
          <Typography variant="h4" color="fadedDarkGrey.main" mb={2.5}>
            Translations
          </Typography>

          {organizationLanguages.map(({ language }) => (
            <FormRow key={language.code} marginBottom={24}>
              <InterpolatedTextArea
                required={false}
                name={fieldNames.preference.consents.bodyDescription.translation(language.code)}
                label={`Display Text (${language.englishName})`}
              />
            </FormRow>
          ))}
        </>
      )}

      {sidebarActiveTab === PreferenceField.PREFERENCES_SUBMIT_BUTTON && (
        <>
          <Typography variant="h4" color="fadedDarkGrey.main" mb={2.5}>
            Translations
          </Typography>

          {organizationLanguages.map(({ language }) => (
            <FormRow key={language.code} marginBottom={24}>
              <FormInput
                fullWidth
                required={false}
                name={fieldNames.preference.consents.buttonText.translation(language.code)}
                label={`Display Text (${language.englishName})`}
              />
            </FormRow>
          ))}
        </>
      )}
    </>
  )
}
