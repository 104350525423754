import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { PreferenceField } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import { TextInput } from '@ketch-com/deck'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { SubscriptionExperienceItem } from 'interfaces/experiences/subscriptions'

type Props = {
  organizationLanguages: LanguageWithStatusDTO[]
}

export const Title: React.FC<Props> = ({ organizationLanguages }) => {
  const { values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext<PreferenceExperienceFormValues>()
  const { sidebarActiveTab } = useContext(ExperienceUpsertContext)

  if (sidebarActiveTab !== PreferenceField.SUBSCRIPTIONS_CONTROL) return null

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={4} pb={4}>
        <TextInput
          fullWidth
          label="Title Text Label"
          value={values?.subscriptions?.controlItems?.[0]?.itemDetail?.title}
          onChange={e => {
            setFieldValue('subscriptions.controlItems[0].itemDetail.title', e?.currentTarget?.value)
          }}
          error={
            touched?.subscriptions?.controlItems?.[0]?.itemDetail?.title &&
            Boolean(
              (errors.subscriptions?.controlItems as never as Required<SubscriptionExperienceItem>[])?.[0]?.itemDetail
                ?.title,
            )
          }
          errorMessage={
            typeof (errors.subscriptions?.controlItems as never as Required<SubscriptionExperienceItem>[])?.[0]
              ?.itemDetail?.title === 'string'
              ? (errors.subscriptions?.controlItems as never as Required<SubscriptionExperienceItem>[])?.[0]?.itemDetail
                  ?.title
              : ''
          }
          onBlur={() => {
            setFieldTouched('subscriptions.controlItems[0].itemDetail.title', true)
          }}
        />
      </Box>

      {organizationLanguages?.length ? (
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          py={4}
          sx={{ borderTop: ({ palette }) => `1px solid ${palette.iron.main}` }}
        >
          <Typography variant="h4" color="darkDuskFaded.main">
            Title Text Translations
          </Typography>

          <Box>
            {organizationLanguages.map(({ language, enabled }) => (
              <Box key={language.code} marginBottom={3}>
                <TextInput
                  fullWidth
                  value={
                    values?.translations?.[`${language.code}`]?.subscriptions?.controlItems?.[0]?.itemDetail?.title
                  }
                  label={
                    <Box display="flex" gap={0.5}>
                      <Typography variant="label">{language.englishName}</Typography>
                      <Typography variant="label" color="darkDuskFaded.main">
                        (Optional)
                      </Typography>
                    </Box>
                  }
                  onChange={e => {
                    setFieldValue(
                      `translations.${language.code}.subscriptions.controlItems[0].itemDetail.title`,
                      e?.currentTarget?.value,
                    )
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}
