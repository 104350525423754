import React from 'react'
import { Avatar, Box } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { duotoneLight } from 'react-syntax-highlighter/dist/cjs/styles/prism'

import { DmlHookDTO } from '@ketch-com/figurehead'
import { ExecutedDmlCellRenderer, HookExecutionStatusCellRenderer } from './components'
import { HookChipCellRenderer } from 'pages/assetManager/components'
import { Process } from '../../utils/getProcessesPayload'
import { Table } from 'components/ui-layouts/table/Table'
import { Text } from 'components/ui-kit/typography/Text'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      indexAvatar: {
        backgroundColor: palette.fadedGrey.main,
        height: 24,
        width: 24,
        marginRight: spacing(1),
      },
      expandContent: {
        background: palette.white.main,
        padding: 0,
      },
      hookTable: {
        paddingLeft: spacing(2),
      },
      executedDmlCell: {
        maxHeight: 80,
        overflow: 'hidden',
      },
    }),
  { name: 'ExpandableRowTable' },
)

type Props = {
  processes: Process[]
}

export const ExpandableRowTable: React.FC<Props> = ({ processes = [] }) => {
  const classes = useStyles()

  return (
    <Table
      variant="page"
      isSmallHeaderText
      items={processes}
      isRowExpandable={rowData => true}
      tableRowExpandContentContainerClassName={classes.expandContent}
      renderExpandableRow={process => {
        return (
          <Table
            className={classes.hookTable}
            variant="page"
            isSmallHeaderText
            tableRowExpandContentContainerClassName={classes.expandContent}
            items={process?.payload}
            cellSettings={{
              dataset: {
                width: 720,
                label: 'Hook',
                cellRenderer: (executionResultHook, index) => (
                  <Box display="flex" alignItems="center">
                    <Avatar className={classes.indexAvatar}>
                      <Text size={14}>{index + 1}</Text>
                    </Avatar>

                    <HookChipCellRenderer
                      hook={executionResultHook?.hook as DmlHookDTO}
                      shouldHideAssetsAssignmentTab
                    />
                  </Box>
                ),
              },
              status: {
                label: 'Status',
                width: 240,
                cellRenderer: executionResultHook => (
                  <HookExecutionStatusCellRenderer executionResultHook={executionResultHook} />
                ),
              },
              populatedArguments: {
                width: 150,
                label: 'Executed DML',
                cellRenderer: executionResultHook => (
                  <ExecutedDmlCellRenderer executionResultHook={executionResultHook} />
                ),
              },
            }}
          />
        )
      }}
      cellSettings={{
        name: {
          width: 140,
          label: 'Process',
          cellRenderer: process => <Text size={14}>{process?.name}</Text>,
        },

        dmlCode: {
          label: 'Executed DML',
          className: classes.executedDmlCell,
          cellRenderer: process => (
            <SyntaxHighlighter language="sql" style={duotoneLight} wrapLongLines={true}>
              {process?.preview || ''}
            </SyntaxHighlighter>
          ),
        },
      }}
    />
  )
}
