import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import { FormControlLabel, Typography } from '@mui/material'
import { FormCheckbox } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormCheckbox'

export const PreferenceThemeWelcomeTabWelcomeMessageForm: React.FC = () => {
  return (
    <SidebarFormGroup sx={{ pt: 0 }}>
      <FormColorInput
        name={themeUpsertFormKeys.preferenceWelcomeTabWelcomeMsgTitleColor}
        label={'Title Color'}
        fullWidth
        required
      />
      <FormColorInput
        name={themeUpsertFormKeys.preferenceWelcomeTabWelcomeMsgSubtitleColor}
        label={'Sub-Title Color'}
        fullWidth
        required
      />
      <FormColorInput
        name={themeUpsertFormKeys.preferenceWelcomeTabWelcomeMsgLinkColor}
        label={'Link Color'}
        fullWidth
        required
      />
      <FormControlLabel
        control={<FormCheckbox name={themeUpsertFormKeys.preferenceWelcomeTabWelcomeMsgLinkUnderline} />}
        label={<Typography variant={'label'}>Underline Links</Typography>}
        sx={{ px: '2px', mt: '-8px' }}
      />
    </SidebarFormGroup>
  )
}
