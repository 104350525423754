import { AppCapabilities } from './AppCapabilities'
import { AppCapabilitiesNames } from './AppCapabilitiesNames'

export const AppCapabilitiesList = [
  {
    name: AppCapabilitiesNames[AppCapabilities.PERMIT_PROPAGATION_APP_CAPABILITY],
    code: AppCapabilities.PERMIT_PROPAGATION_APP_CAPABILITY,
    enabled: false,
  },
  {
    name: AppCapabilitiesNames[AppCapabilities.PERMIT_INGESTION_APP_CAPABILITY],
    code: AppCapabilities.PERMIT_INGESTION_APP_CAPABILITY,
    enabled: false,
  },
  {
    name: AppCapabilitiesNames[AppCapabilities.RIGHTS_PROPAGATION_APP_CAPABILITY],
    code: AppCapabilities.RIGHTS_PROPAGATION_APP_CAPABILITY,
    enabled: false,
  },
  {
    code: AppCapabilities.RIGHTS_INGESTION_APP_CAPABILITY,
    name: AppCapabilitiesNames[AppCapabilities.RIGHTS_INGESTION_APP_CAPABILITY],
    enabled: false,
  },
  {
    code: AppCapabilities.TAG_ORCHESTRATION_APP_CAPABILITY,
    name: AppCapabilitiesNames[AppCapabilities.TAG_ORCHESTRATION_APP_CAPABILITY],
    enabled: false,
  },
]
