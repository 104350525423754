import { Icon, TextInput } from '@ketch-com/deck'
import React, { useContext, useEffect } from 'react'
import { SidebarItemWrapper } from './SidebarItemWrapper'
import { Box } from '@mui/material'
import { SidebarMode } from '../../../types'
import { AppNavigationContext } from '../../../context/AppNavigationContext'
import { useSidebarRoutesConfig } from 'utils/routing/useSidebarRoutesConfig'
import { getSidebarSearchRoutes } from '../utils'

export const SidebarSearch: React.FC = () => {
  const { sidebarMode, setSidebarSearchResults, setSearchActive, sidebarSearch, setSidebarSearch, searchInputRef } =
    useContext(AppNavigationContext)

  const isExpanded = sidebarMode === SidebarMode.Open || sidebarMode === SidebarMode.Overlay

  const sidebarRoutes = useSidebarRoutesConfig()

  // Set seach active when focused
  const handleFocus = () => {
    setSearchActive(true)
  }

  // Set search inactive when focus is lost, unless a search has been entered
  const handleBlur = () => {
    if (!sidebarSearch) setSearchActive(false)
  }

  const handleClear = () => {
    // This if makes it so if the X is clicked when the sidebar already has no text,
    if (sidebarSearch) {
      setSidebarSearch('')
      searchInputRef.current?.focus()
    }
  }

  // Update search results
  useEffect(
    () => setSidebarSearchResults(sidebarSearch ? getSidebarSearchRoutes(sidebarRoutes, sidebarSearch) : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sidebarSearch],
  )

  useEffect(() => {
    if (sidebarSearch) setSearchActive(true)
  }, [sidebarSearch, setSearchActive])

  return (
    <SidebarItemWrapper selected={false} sx={{ mb: '20px', mt: '24px' }}>
      <Box sx={{ width: '100%', ml: '8px', mr: '10px' }}>
        <TextInput
          inputRef={searchInputRef}
          value={sidebarSearch}
          onChange={e => setSidebarSearch(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          variant="ghost"
          startIcon={<Icon name="OMag" />}
          endIcon={
            isExpanded && sidebarSearch ? (
              <Icon name="OCross" onClick={handleClear} sx={{ cursor: 'pointer' }} />
            ) : undefined
          }
          placeholder="Search"
          size="small"
          fullWidth
          inputWidth={isExpanded ? '100%' : '24px'}
          inputProps={{ style: { display: isExpanded ? 'block' : 'none' } }}
          sx={{
            transition: 'width 0.3s ease',
            ...(isExpanded ? {} : { borderBottom: 'none !important' }),
          }}
        />
      </Box>
    </SidebarItemWrapper>
  )
}
