import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetExecutionSQLResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

type Params = APIListRequestParams<{
  rightCode?: string
  datasetId?: string
  scenarioId?: string
  start?: number
  query?: string
  includeMetadata?: boolean
  limit?: number
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchSql = ({
  datasetId,
  scenarioId,
  rightCode,
  start,
  limit,
  includeMetadata,
  query,
  isV2FeatureFlagEnabled,
}: Params) => {
  let entityUrl = `/api/v3/execution-sql`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/execution-sql`

  return API.get<GetExecutionSQLResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          ...(start && {
            start,
          }),
          ...(limit && {
            limit,
          }),
          ...(datasetId && {
            assetId: datasetId,
          }),
          ...(scenarioId && {
            scenarioId,
          }),
          ...(rightCode && {
            rightCode,
          }),
          ...(query && {
            query,
          }),
        },
      },
    }),
  )
}
