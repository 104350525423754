import { useState } from 'react'
import { useNotFoundTrackersInfinite } from 'api/trackers/queries/useNotFoundTrackersInfinite'

import { GetNotFoundTrackersParams } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

export const useNotFoundTrackersUtils = () => {
  const [notFoundTrackersParams, setNotFoundTrackersParams] = useState<GetNotFoundTrackersParams>({ searchString: '' })

  const {
    trackers: notFoundTrackers,
    isLoading: isLoadingNotFoundTrackers,
    isFetching: isFetchingNotFoundTrackers,
    isFetchingNextPage: isFetchingNextNotFoundTrackers,
    hasNextPage: hasNextNotFoundTrackers,
    fetchNextPage: fetchNextNotFoundTrackers,
    totalResults: totalResultsForNotFoundTrackers,
  } = useNotFoundTrackersInfinite(notFoundTrackersParams)

  const isReady = !isLoadingNotFoundTrackers

  return {
    isReady,
    notFoundTrackers,
    isLoadingNotFoundTrackers,
    isFetchingNotFoundTrackers,
    isFetchingNextNotFoundTrackers,
    hasNextNotFoundTrackers,
    fetchNextNotFoundTrackers,
    notFoundTrackersParams,
    setNotFoundTrackersParams,
    totalResultsForNotFoundTrackers,
  }
}
