import { CreateAuthenticatorRequestBodyDTO } from '@ketch-com/figurehead'
import { CreateAuthenticatorFormValues } from './getAuthenticatorInitialValues'

export const mapAuthenticatorFormValuesToFormData = (
  values: CreateAuthenticatorFormValues,
): CreateAuthenticatorRequestBodyDTO => {
  return {
    authenticator: {
      name: values.name,
      type: Number(values.type),
      accessTokenUrl: values.accessTokenUrl,
      authUrl: values.authUrl,
      clientId: values.clientId,
      clientSecret: values.clientSecret,
      redirectUrl: values.redirectUrl,
      scopes: (values.scopes || '')
        .split(' ')
        // remove any empty strings
        .filter(s => !!s)
        // ensure separated by a single space
        .join(' '),
      variableClaimMappings: (values?.variableClaimMappings || []).map(v => ({
        destination: Number(v.destination),
        destinationKey: v.destinationKey,
        sourceKey: v.sourceKey,
      })),
    },
  }
}
