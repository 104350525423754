import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { MaybeNull } from 'interfaces/common'
import { ViewLayout } from 'components/ui-layouts/viewLayout/ViewLayout'
import { ViewLayoutHeader } from 'components/ui-layouts/viewLayout/ViewLayoutHeader'
import { ViewLayoutContent } from 'components/ui-layouts/viewLayout/ViewLayoutContent'
import { PolicyViewActions } from './components'
import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'
import { List } from 'components/ui-kit/list/List'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { ASSET_TYPE_NAMES } from 'interfaces/assets'
import { getMapEffectUrlToString } from 'pages/assetManager/utils'
import { RoutesManager } from 'utils/routing/routesManager'

const PREFIX = 'PoliciesTableExpandableRow'

const classes = {
  container: `${PREFIX}-container`,
  columnHeaderText: `${PREFIX}-columnHeaderText`,
  bulletList: `${PREFIX}-bulletList`,
  blueText: `${PREFIX}-blueText`,
  marginBottom: `${PREFIX}-marginBottom`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.container}`]: {
    border: `1px solid ${palette.lightGrey.main}`,
    borderRadius: spacing(1),
  },

  [`& .${classes.columnHeaderText}`]: {
    marginBottom: spacing(1),
  },

  [`& .${classes.bulletList}`]: {
    listStyleType: 'disc',
  },

  [`& .${classes.blueText}`]: {
    color: palette.sphere.main,
  },

  [`& .${classes.marginBottom}`]: {
    marginBottom: spacing(2),
  },
}))

type Props = {
  isReady: boolean
  policy: MaybeNull<AssetPolicyDTO>
  purpose: MaybeNull<PurposeDTO>
}

export const PolicyView: React.FC<Props> = ({ isReady, policy, purpose }) => {
  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.assetManager.assets.list.getURL() },
    { title: 'Policies', link: RoutesManager.policyCenter.policies.list.getURL() },
    { title: policy?.name || '' },
  ]

  if (!isReady || !policy) {
    return <ViewLayout isReady={false} breadcrumbs={breadcrumbs} />
  }

  return (
    <ViewLayout isReady={isReady} breadcrumbs={breadcrumbs}>
      <ViewLayoutHeader
        title={policy?.name || ''}
        backTo={RoutesManager.policyCenter.policies.list.getURL()}
        actions={<PolicyViewActions policy={policy} />}
      />
      <ViewLayoutContent>
        <StyledBox p={2.25} className={classes.container}>
          {/* Purpose */}

          <Text size={14}>When accessing data for the purpose of</Text>
          <List
            className={classes.marginBottom}
            borderless
            items={[
              {
                active: false,
                content: (
                  <Text size={14}>
                    "<span className={classes.blueText}>{purpose?.displayName}</span>"
                  </Text>
                ),
                disabled: true,
                icon: <>&#8226;</>,
              },
            ]}
            size="small"
          />

          {/* Resources */}

          <Text size={14}>allow access to the following resources:</Text>
          <List
            borderless
            className={classes.marginBottom}
            items={
              (policy?.assets || [])?.length > 0
                ? (policy?.assets || []).map(ass => {
                    if (ass?.named) {
                      return {
                        active: false,
                        content: (
                          <Text size={14}>
                            <span className={classes.blueText}>
                              {ASSET_TYPE_NAMES[ass?.type || 0]} named "{ass.named}"
                            </span>
                          </Text>
                        ),
                        disabled: true,
                        icon: <>&#8226;</>,
                      }
                    }

                    if (Object.keys(ass?.labeled || {}).length > 0) {
                      return {
                        active: false,
                        content: (
                          <Text size={14}>
                            <span className={classes.blueText}>
                              {ASSET_TYPE_NAMES[ass?.type || 0]} labeled "{Object.keys(ass?.labeled || {})?.[0]}{' '}
                              {Object.values(ass?.labeled || {})?.[0]}"
                            </span>
                          </Text>
                        ),
                        disabled: true,
                        icon: <>&#8226;</>,
                      }
                    }

                    return {
                      active: false,
                      content: null,
                      disabled: true,
                      icon: null,
                    }
                  })
                : []
            }
            size="small"
          />

          {/* Effects */}

          <Text size={14}>with the following attribute effects</Text>
          <List
            className={classes.marginBottom}
            borderless
            items={
              (policy?.effects || [])?.length > 0
                ? (policy.effects || []).map(effect => {
                    if (effect?.named) {
                      return {
                        active: false,
                        content: (
                          <Text size={14}>
                            <span className={classes.blueText}>{getMapEffectUrlToString(effect?.effect || '')}</span>{' '}
                            attributes <span className={classes.blueText}>{effect.named}</span>
                          </Text>
                        ),
                        disabled: true,
                        icon: <>&#8226;</>,
                      }
                    }

                    if (Object.keys(effect?.labeled || {}).length > 0) {
                      return {
                        active: false,
                        content: (
                          <Text size={14}>
                            <span className={classes.blueText}>{getMapEffectUrlToString(effect?.effect || '')}</span>{' '}
                            labeled{' '}
                            <span className={classes.blueText}>
                              {Object.keys(effect?.labeled || {})?.[0]} {Object.values(effect?.labeled || {})?.[0]}
                            </span>
                          </Text>
                        ),
                        disabled: true,
                        icon: <>&#8226;</>,
                      }
                    }

                    return {
                      active: false,
                      content: null,
                      disabled: true,
                      icon: null,
                    }
                  })
                : []
            }
            size="small"
          />

          {/* Filters */}

          {(policy?.rowFilters || [])?.length > 0 && (
            <>
              <Text size={14}>and the following row filters:</Text>
              <List
                borderless
                items={
                  (policy?.rowFilters || [])?.length > 0
                    ? (policy.rowFilters || []).map(filter => ({
                        active: false,
                        content: (
                          <Text size={14}>
                            <span className={classes.blueText}>{filter.attributeName}</span> {filter.condition}{' '}
                            <span className={classes.blueText}>{filter.value}</span>
                          </Text>
                        ),
                        disabled: true,
                        icon: <>&#8226;</>,
                      }))
                    : []
                }
                size="small"
              />
            </>
          )}
        </StyledBox>
      </ViewLayoutContent>
    </ViewLayout>
  )
}
