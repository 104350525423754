import { Theme } from '@mui/material/styles'
import { styled } from '@mui/system'

/**
 * This is a styled component that is used to render the tooltip list of things another thing is used in.
 */
export const FormsUsedInStyledUl = styled('ul')(({ theme }: { theme?: Theme }) => ({
  paddingLeft: 20,
  paddingRight: 10,
  marginTop: 10,
  marginBottom: 6,
}))
