import React, { createContext, useContext, ReactNode } from 'react'

interface CreateCustomContextOptions<T> {
  displayName: string
  defaultValue?: T
}

export function createCustomContext<T>(options: CreateCustomContextOptions<T>) {
  const Context = createContext<T | undefined>(options.defaultValue)

  // set a display name for React DevTools
  if (options.displayName) {
    Context.displayName = options.displayName
  }

  // Custom hook for using the context
  const useCustomContext = (): T => {
    const context = useContext(Context)
    if (context === undefined) {
      throw new Error(`${options.displayName} must be used within its Provider`)
    }
    return context
  }

  // Custom provider component
  const Provider = ({ children, value }: { children: ReactNode; value: T }) => {
    return <Context.Provider value={value}>{children}</Context.Provider>
  }

  return { Provider, useCustomContext }
}
