import { useMemo } from 'react'
import { object, string, array, boolean } from 'yup'

import { MaybeNull } from 'interfaces/common'
import { AppDescriptorDTO } from 'interfaces/apps'
import { useCheckIsAppInstanceCodeAvailable } from 'api/apps/mutations/useCheckIsAppInstanceCodeAvailable'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { mergeSchemas } from 'pages/policyCenter/purposes/upsert/utils/purposeFormValidationSchemaUtils'

export const useGetValidationSchemaBasicDetailsStep = ({
  isEditMode,
  appDescriptor,
}: {
  isEditMode?: boolean
  appDescriptor: MaybeNull<AppDescriptorDTO>
}) => {
  const { mutateAsync: handleCheckIsCodeAvailable } = useCheckIsAppInstanceCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsCodeAvailable({
        params: {
          appId: appDescriptor?.marketplaceEntity?.appID || '',
          code: value,
        },
      }),
  })

  return useMemo(
    () =>
      object().shape({
        ...(!isEditMode && {
          code: codeValidationSchema,
        }),
        name: string().max(75, 'Name must not exceed 75 characters').required('Required'),
        description: string(),
      }),
    [isEditMode, codeValidationSchema],
  )
}

export const validationSchemaIdentitySpacesStep = object().shape({
  identitySpaces: array().of(
    object().shape({
      managed: boolean(),
      userIdentitySpaceCode: string(),
    }),
  ),
})

export const useGetValidationSchemaFieldsStep = ({ isEditMode }: { isEditMode?: boolean }) => {
  return object().shape({
    fields: array().of(
      object().shape({
        name: string(),
        ...(!isEditMode && {
          value: string().test('is-required', 'Please enter a valid value', (value, context: any) => {
            const getIsValidIfRequired = (): boolean => {
              if (context?.parent?.required) {
                /* validate min length requirement if one exists */
                if (context?.parent?.minLength && !((value?.length || 0) >= context?.parent?.minLength)) {
                  return context?.createError({
                    message: `Must be at least ${context?.parent?.minLength} characters`,
                  })
                }
                /* validate maxLength requirement if one exists */
                if (context?.parent?.maxLength && !((value?.length || 0) <= context?.parent?.maxLength)) {
                  return context?.createError({
                    message: `Must be no longer than ${context?.parent?.maxLength} characters`,
                  })
                }
                return !!value
              } else {
                /* if not required, return true so as not to prevent submission */
                return true
              }
            }

            const [, parent2] = context.from
            if ((context?.parent?.conditionForDisplay?.length || 0) > 0) {
              const shouldDisplayArray: boolean[] = (context?.parent?.conditionForDisplay || []).map(
                ({ name, value }: any) => {
                  const valueOfInterest = (parent2?.value?.fields || []).find((v: any) => v.name === name)?.value
                  return valueOfInterest === value
                },
              )
              /* if any entries in shouldDisplayArray are false then the input will not displayed, thus we do not care what the value is, so, return true so as to not prevent submission */
              if (shouldDisplayArray.some((s: boolean) => !s)) {
                return true
              }
              return getIsValidIfRequired()
            }
            /* if the value is required, make sure value is truthy, and test min/max length if included */
            return getIsValidIfRequired()
          }),
        }),
        required: boolean(),
        conditionForDisplay: array().of(
          object().shape({
            name: string(),
            value: string(),
          }),
        ),
      }),
    ),
  })
}

export const useGetCreateAddConnectionFormValidationSchema = ({
  currentFormStep,
  isEditMode,
  appDescriptor,
  doesNotRequireIdentityInput,
}: {
  doesNotRequireIdentityInput?: boolean
  currentFormStep: number
  isEditMode?: boolean
  appDescriptor: MaybeNull<AppDescriptorDTO>
}) => {
  const validationSchemaBasicDetails = useGetValidationSchemaBasicDetailsStep({
    isEditMode,
    appDescriptor,
  })
  const validationSchemaFieldsStep = useGetValidationSchemaFieldsStep({
    isEditMode,
  })
  return useMemo(() => {
    if (currentFormStep === 0) {
      return validationSchemaBasicDetails
    } else if (currentFormStep === 1) {
      if (!doesNotRequireIdentityInput) {
        return mergeSchemas(validationSchemaBasicDetails, validationSchemaIdentitySpacesStep)
      } else {
        return mergeSchemas(validationSchemaBasicDetails, validationSchemaFieldsStep)
      }
    } else if (currentFormStep === 2) {
      return mergeSchemas(validationSchemaBasicDetails, validationSchemaIdentitySpacesStep, validationSchemaFieldsStep)
    }
  }, [currentFormStep, doesNotRequireIdentityInput, validationSchemaBasicDetails, validationSchemaFieldsStep])
}

export const useGetEditConnectionFormValidationSchema = ({
  isEditMode,
  appDescriptor,
}: {
  isEditMode?: boolean
  appDescriptor: MaybeNull<AppDescriptorDTO>
}) => {
  const validationSchemaBasicDetails = useGetValidationSchemaBasicDetailsStep({
    isEditMode,
    appDescriptor,
  })

  const validationSchemaFieldsStep = useGetValidationSchemaFieldsStep({
    isEditMode,
  })

  return mergeSchemas(validationSchemaBasicDetails, validationSchemaFieldsStep, validationSchemaIdentitySpacesStep)
}
