import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  entityUrl: string
}>

export const fetchDynamicDropdownValues = ({ entityUrl }: Params) =>
  API.get<any>(
    formatRequestString({
      entityUrl,
      params: {},
    }),
  )
