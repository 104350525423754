import { ListLayout } from '@ketch-com/deck'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { SectionTabMultiPath } from 'interfaces/sectionTabs/sectionTabs'
import { max } from 'lodash'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useNavigateGated } from 'utils/routing/hooks/useNavigateGated'
import { RoutesManager } from 'utils/routing/routesManager'

const tabs: SectionTabMultiPath[] = [
  {
    tabProps: {
      label: 'Experiences',
    },
    path: [RoutesManager.deployment.experiencesV2.root.getURL()],
    permissions: [PERMISSIONS.EXP_READ],
    entitlements: [ENTITLEMENTS.CONSENT_MANAGEMENT, ENTITLEMENTS.RIGHTS_MANAGEMENT, ENTITLEMENTS.MARKETING_PREFERENCES],
  },
  {
    tabProps: {
      label: 'Subscriptions',
    },
    path: [
      RoutesManager.policyCenter.subscriptions.list.subscriptionTopics.root.getURL(),
      RoutesManager.policyCenter.subscriptions.list.subscriptionControls.root.getURL(),
    ],
    permissions: [PERMISSIONS.SUBSCRIPTIONS_READ],
    entitlements: [ENTITLEMENTS.SUBSCRIPTIONS],
  },
  {
    tabProps: {
      label: 'Forms',
    },
    path: [RoutesManager.deployment.forms.templates.root.getURL(), RoutesManager.deployment.forms.fields.root.getURL()],
    permissions: [PERMISSIONS.EXP_WRITE],
    entitlements: [ENTITLEMENTS.RIGHTS_MANAGEMENT],
  },
  {
    tabProps: {
      label: 'Purpose Stacks',
    },
    path: [RoutesManager.deployment.purposeStacks.root.getURL()],
    permissions: [PERMISSIONS.EXP_READ],
    entitlements: [ENTITLEMENTS.CONSENT_MANAGEMENT, ENTITLEMENTS.MARKETING_PREFERENCES],
  },
]

export const ExperiencesSection: React.FC = () => {
  const navigate = useNavigate()
  const navigateGated = useNavigateGated()
  const { pathname } = useLocation()

  // Determine what tab is currently selected based on URL
  const tab = tabs.findIndex(t => t.path.some(path => pathname.includes(path)))
  const tabItems = Object.values(tabs).map(t => t.tabProps)

  /* Redirect to experiences if base route is navigated to  */
  useEffect(() => {
    if (pathname === RoutesManager.deployment.experiencesHome.root.getURL()) {
      navigate(RoutesManager.deployment.experiencesV2.root.getURL())
    }
  }, [pathname, navigate])

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    const { path, entitlements, permissions } = tabs[newTab]
    navigateGated(path[0], permissions, entitlements)
  }

  return (
    <ListLayout
      headerTitle="Experiences"
      tabsComponentProps={{ value: max([tab, 0]), onChange: handleTabChange, tabItems }}
    >
      <Outlet />
    </ListLayout>
  )
}
