import { IRegionFE } from 'interfaces'

type AddChildRegionToTotalCount = (region: IRegionFE, increaseCounter: () => void) => void
const addChildRegionToTotalCount: AddChildRegionToTotalCount = (region, increaseCounter) => {
  if (region.regions) {
    region.regions?.forEach?.((region: IRegionFE) => {
      addChildRegionToTotalCount(region, increaseCounter)
    })
  } else {
    increaseCounter()
  }
}

// NOTE: get recursive count of innermost items in the regions tree
type GetRegionsRecursiveCount = (regions: IRegionFE[]) => number
export const getRegionsRecursiveCount: GetRegionsRecursiveCount = regions => {
  let count = 0
  const increaseCounter = () => count++

  regions?.forEach?.((region: IRegionFE) => {
    addChildRegionToTotalCount(region, increaseCounter)
  })

  return count
}
