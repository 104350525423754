import { ListLayout } from '@ketch-com/deck'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { SectionTab } from 'interfaces/sectionTabs/sectionTabs'
import { max } from 'lodash'
import { HowDoesItWork } from 'pages/policyCenter/trackers/list/approvedTrackers/components'
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useIsDemoOrg } from 'utils/hooks/useIsDemoOrg'
import { useNavigateGated } from 'utils/routing/hooks/useNavigateGated'
import { RoutesManager } from 'utils/routing/routesManager'

const tabs: SectionTab[] = [
  {
    tabProps: {
      label: 'Properties',
    },
    path: RoutesManager.deployment.applications.root.getURL(),
    permissions: [PERMISSIONS.PROPERTY_READ],
    entitlements: [ENTITLEMENTS.CONSENT_MANAGEMENT, ENTITLEMENTS.RIGHTS_MANAGEMENT, ENTITLEMENTS.MARKETING_PREFERENCES],
  },
  {
    tabProps: {
      label: 'Themes',
    },
    path: RoutesManager.deployment.themesV3.root.getURL(),
    permissions: [PERMISSIONS.THEME_READ],
    entitlements: [ENTITLEMENTS.CONSENT_MANAGEMENT, ENTITLEMENTS.RIGHTS_MANAGEMENT, ENTITLEMENTS.MARKETING_PREFERENCES],
  },
  {
    tabProps: {
      label: 'Deployment',
    },
    path: RoutesManager.deployment.deploymentPlansV2.root.getURL(),
    permissions: [PERMISSIONS.DEPLOY_READ],
    entitlements: [ENTITLEMENTS.CONSENT_MANAGEMENT, ENTITLEMENTS.RIGHTS_MANAGEMENT, ENTITLEMENTS.MARKETING_PREFERENCES],
  },
]

export const PropertiesSection = () => {
  const navigate = useNavigate()
  const navigateGated = useNavigateGated()
  const { isDemoOrg } = useIsDemoOrg()
  const { pathname } = useLocation()

  // Trackers state
  const isTrackersPage = pathname.includes(RoutesManager.policyCenter.trackers.root.pattern)
  const [showHowDoesItWork, setShowHowDoesItWork] = useState(false)

  // Determine what tab is currently selected based on URL
  const tab = tabs.findIndex(t => pathname.includes(t.path))
  const tabItems = Object.values(tabs).map(t => t.tabProps)

  /* Redirect if base route is navigated to  */
  useEffect(() => {
    if (pathname === RoutesManager.deployment.propertiesHome.root.getURL()) {
      navigate(RoutesManager.deployment.applications.root.getURL())
    }
  }, [pathname, navigate])

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    const { path, entitlements, permissions } = tabs[newTab]
    navigateGated(path, permissions, entitlements)
  }

  return (
    <ListLayout
      // For demos we show "& Rules" to tell a better story when travelling from the experience builder to the "rule builder"
      headerTitle={isDemoOrg ? 'Properties & Rules' : 'Properties'}
      tabsComponentProps={{ value: max([tab, 0]), onChange: handleTabChange, tabItems }}
      headerRightActionComponentProps={
        isTrackersPage
          ? {
              headerRightActionSettingsButtonProps: {
                onClick: () => navigate(RoutesManager.policyCenter.trackers.settings.root.getURL()),
              },
              headerRightActionInfoButtonProps: {
                onClick: () => {
                  setShowHowDoesItWork(true)
                },
              },
            }
          : {}
      }
    >
      <Outlet />
      {showHowDoesItWork ? <HowDoesItWork setShowHowDoesItWork={setShowHowDoesItWork} /> : null}
    </ListLayout>
  )
}
