import { useInfiniteQuery } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchNotFoundTrackerProperties } from '../fetchers/fetchNotFoundTrackerProperties'
import { GetNotFoundTrackerPropertiesParams } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type Params = GetNotFoundTrackerPropertiesParams & { enabled?: boolean; encodedTrackerKey: string; limit?: number }

export const useNotFoundTrackerPropertiesInfinite = (params: Params) => {
  const { enabled = true, limit = 20 } = params
  const resp = useInfiniteQuery({
    enabled,
    queryKey: [ApiQueryKeys.notFoundTrackerPropertiesInfinite, { ...params }],
    queryFn: async ({ pageParam = {} }) => {
      const categories = await fetchNotFoundTrackerProperties({
        limit,
        nextToken: pageParam.nextToken,
        ...params,
      })
      return categories
    },
    getNextPageParam: lastPage => {
      const nextToken = lastPage?.data?.nextToken
      return nextToken ? { nextToken } : null
    },
  })

  return {
    ...resp,
    trackerProperties: resp?.data?.pages?.map(page => page?.data?.properties || []).flat() || [],
  }
}
