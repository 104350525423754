import React, { useContext } from 'react'
import { Box, FormControlLabel, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { PreferenceField } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import { Radio, RadioGroup } from '@ketch-com/deck'

type Props = {}

export const Impact: React.FC<Props> = () => {
  const { sidebarActiveTab } = useContext(ExperienceUpsertContext)
  const { values, setFieldValue } = useFormikContext<PreferenceExperienceFormValues>()

  if (sidebarActiveTab !== PreferenceField.SUBSCRIPTIONS_CONTROL) return null

  return (
    <Box ml={2}>
      <RadioGroup
        name="impactType"
        value={values?.subscriptions?.controlItems?.[0]?.itemDetail?.impactType}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, value) => {
          setFieldValue('subscriptions.controlItems[0].itemDetail.impactType', Number(value))
        }}
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <FormControlLabel
              value={1}
              control={<Radio />}
              label={
                <Typography
                  variant={`${
                    values?.subscriptions?.controlItems?.[0]?.itemDetail?.impactType === 1 ? 'label' : 'body'
                  }`}
                >
                  Universal Unsubscribe
                </Typography>
              }
            />
            <Typography variant="body" color="darkDuskFaded.main">
              Unsubscribe from all unsubscribes from <Typography variant="label">all subscriptions topics</Typography>{' '}
              available in your organization AND calls the corresponding global marketing controls opt-outs in the
              customer engagement tools.
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <FormControlLabel
              value={2}
              control={<Radio />}
              label={
                <Typography
                  variant={`${
                    values?.subscriptions?.controlItems?.[0]?.itemDetail?.impactType === 2 ? 'label' : 'body'
                  }`}
                >
                  Impacts This Experience Only
                </Typography>
              }
            />
            <Typography variant="body" color="darkDuskFaded.main">
              Unsubscribe from all will only affect subscriptions that are listed in{' '}
              <Typography variant="label">this experience</Typography>. Please note that this will NOT call the global
              marketing controls opt-out in the customer engagement tools.
            </Typography>
          </Box>
        </Box>
      </RadioGroup>
    </Box>
  )
}
