import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  applicationCode: string
  organizationCode: string
}>

type Response = { script: string }

export const fetchApplicationScript = ({ organizationCode, includeMetadata, applicationCode }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/applications/${applicationCode}/script`,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
  )
