import React, { useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'

import { MaybeNull } from 'interfaces/common'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { ChangeLanguagesListModal } from 'components/modals/languages/ChangeLanguagesListModal'
import { ChangeLanguageStatusModal } from 'components/modals/languages/ChangeLanguageStatusModal'
import {
  ListLayout,
  Button,
  TooltipButton,
  TableCell,
  Status,
  StatusState,
  StatusVariant,
  DataGrid,
} from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  isLoading: boolean
  languages: LanguageWithStatusDTO[]
}

export const LanguagesList: React.FC<Props> = ({ isLoading, languages }) => {
  const queryClient = useQueryClient()

  const { isPermitted } = useIsPermitted()
  const isPermittedToManageLanguages = isPermitted([PERMISSIONS.TRANSLATIONS_WRITE])

  const [isManageOpen, setIsManageOpen] = useState(false)
  const [itemToChange, setItemToChange] = useState<MaybeNull<LanguageWithStatusDTO>>(null)

  const reFetchLanguages = () => queryClient.refetchQueries(ApiQueryKeys.organizationsLanguagesPaginated)

  const columns = useMemo(
    () => [
      {
        field: 'language',
        headerName: 'Language',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <Typography variant="label">{params.row.language.englishName}</Typography>
          </TableCell>
        ),
        sortable: false,
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {params.row.enabled ? (
              <Status label="Enabled" status={StatusState.success} variant={StatusVariant.ghost} icon="Dot" />
            ) : (
              <Status label="Disabled" status={StatusState.draft} variant={StatusVariant.ghost} icon="Dot" />
            )}
          </TableCell>
        ),
        width: 200,
        sortable: false,
      },
      {
        field: 'added',
        headerName: 'Added',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <FormattedDateRenderer date={params.row.metadata?.createdAt} />
          </TableCell>
        ),
        width: 250,
        sortable: false,
      },
      {
        renderCell: (params: GridRenderCellParams) =>
          isPermittedToManageLanguages ? (
            <TableCell>
              <TooltipButton
                title={params.row.system ? 'You can not modify system language' : undefined}
                color={params.row.enabled ? 'tertiary' : 'secondary'}
                disabled={params.row.system}
                onClick={() => setItemToChange(params.row)}
              >
                {params.row.enabled ? 'Disable' : 'Enable'}
              </TooltipButton>
            </TableCell>
          ) : null,
        width: 150,
        sortable: false,
        field: 'action',
        renderHeader: () => null,
      },
    ],
    [isPermittedToManageLanguages],
  )

  const rows = [
    {
      enabled: true,
      system: true,
      language: {
        code: 'en-us',
        englishName: 'English',
        nativeName: 'English',
      },
    },
    ...languages,
  ].map(item => ({ ...item, id: item.language.code }))

  const breadcrumbs = [
    { title: 'Settings', link: RoutesManager.settings.root.getURL() },
    { title: 'Languages', link: RoutesManager.deployment.languages.root.getURL() },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ListLayout
        headerTitle="Languages"
        headerProps={{ isDivider: true }}
        customHeaderRightActionComponent={
          <Box justifyContent="space-between" alignItems="center">
            {isPermittedToManageLanguages && <Button onClick={() => setIsManageOpen(true)}>Manage Languages</Button>}
          </Box>
        }
      >
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isLoading}
          disableColumnMenu
          disableColumnPinning
          disableColumnReorder
          disableChildrenSorting
          isPaginationLoading
          disableRowSelectionOnClick
          disableRowHoverStates
          disableColumnResize
          disableBorder
          hideFooter
          sx={{
            height: 'auto',
          }}
        />

        {isManageOpen && (
          <ChangeLanguagesListModal
            onSubmit={() => {
              setIsManageOpen(false)
              return reFetchLanguages()
            }}
            onCancel={() => setIsManageOpen(false)}
          />
        )}

        {itemToChange?.language.code && (
          <ChangeLanguageStatusModal
            item={itemToChange}
            onSubmit={() => {
              setItemToChange(null)
              return reFetchLanguages()
            }}
            onCancel={() => setItemToChange(null)}
          />
        )}
      </ListLayout>
    </>
  )
}
