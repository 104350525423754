import React from 'react'
import { useFormikContext } from 'formik'

import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { PreferenceField } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { UpsertExperienceEditableOverlay } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEditableOverlay'
import { UpsertExperienceEmptyField } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEmptyField'
import { UpsertExperiencePreviewText } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewText'
import { useUpsertPreferenceStyles } from 'pages/consentAndRights/experiences/upsert/components/UpsertPreference/hooks'

type Props = {
  getOverlayProps: (name: PreferenceField) => {
    active: boolean
    onClick: () => void
  }
}

export const BodyTitle: React.FC<Props> = ({ getOverlayProps }) => {
  const classes = useUpsertPreferenceStyles()

  const { values } = useFormikContext<PreferenceExperienceFormValues>()
  const { rights } = values

  return (
    <UpsertExperienceEditableOverlay
      className={classes.bodyTitle}
      {...getOverlayProps(PreferenceField.RIGHTS_BODY_TITLE)}
    >
      {rights?.bodyTitle ? (
        <UpsertExperiencePreviewText bold size="large">
          {rights.bodyTitle}
        </UpsertExperiencePreviewText>
      ) : (
        <UpsertExperienceEmptyField size="large">Body Title</UpsertExperienceEmptyField>
      )}
    </UpsertExperienceEditableOverlay>
  )
}
