import { Box } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { Text } from 'components/ui-kit/typography/Text'
import { getAssetCloudLongName } from 'pages/assetManager/utils/getAssetCloudLongName'
import { DataMapDataSystemSystemInfoDTO } from '@ketch-com/figurehead'
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight' // TODO:BAC - de-scoped until we will support this icon being clickable.

type Props = {
  title: string
  technology?: string
  content?: DataMapDataSystemSystemInfoDTO
}

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      container: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 100,
        background: palette.sphereFaded.main,
        marginRight: spacing(3),
        height: 56,
        padding: spacing(1.5, 1.75, 1.5, 2),
      },
      image: {},
    }),
  { name: 'OverviewTechnologyChip' },
)

export const OverviewTechnologyChip = ({ title, technology = '', content }: Props) => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <img
        width={32}
        height={32}
        src={content?.logoUrl || getAssetCloudImgUrl(technology)}
        className={classes.image}
        alt=""
      />
      <Box ml={1.75} display="flex" justifyContent="center" flexDirection="column">
        <Text component="div" size={9} weight={600} mb={0.5}>
          {title}
        </Text>
        <Text component="div" size={14} color="royalBlue">
          {content?.systemName || getAssetCloudLongName(technology)}
        </Text>
      </Box>
      {/* // TODO:BAC - Temporarily removing right arrow icon because currently this chip is not clickable, tho, one day it will be */}
      {/* <KeyboardArrowRightIcon
        sx={{
          color: 'royalBlue.main',
        }}
      /> */}
    </Box>
  )
}
