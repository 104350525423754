import React, { useContext } from 'react'
import { Button, CustomIconName, Icon } from '@ketch-com/deck'
import { AppNavigationContext } from '../../../context/AppNavigationContext'
import { AppNavigationSize, SidebarMode } from '../../../types'
import { getNextSidebarModeOnClick } from '../../../utils/getNextSidebarMode'

const getCollapseButtonIcon = (sidebarMode: SidebarMode, appNavigationSize: AppNavigationSize): CustomIconName => {
  // Always show hamburger icon when no fullsize
  if (appNavigationSize !== AppNavigationSize.FullSize) {
    return 'ONav'
  }

  switch (sidebarMode) {
    case SidebarMode.Closed:
      return 'ONav'

    case SidebarMode.Compact:
      return 'ONavShow'

    case SidebarMode.Open:
      return 'ONavHide'

    case SidebarMode.Overlay:
      // Since we must be in fullsize mode here, show pin open icon as we are hovering a pinned closed sidebar
      return 'ONavShow'
  }
}

export const HeaderSidebarControlButton: React.FC = () => {
  const { sidebarMode, setSidebarMode, appNavigationSize, setIsSidebarPinned } = useContext(AppNavigationContext)

  const handleClick = () => {
    // Set pinned state
    switch (sidebarMode) {
      case SidebarMode.Closed:
        setIsSidebarPinned(true)
        break

      case SidebarMode.Compact:
        setIsSidebarPinned(true)
        break

      case SidebarMode.Open:
        setIsSidebarPinned(false)
        break

      case SidebarMode.Overlay:
        setIsSidebarPinned(false)
        break
    }

    // Set sidebar mode
    setSidebarMode(getNextSidebarModeOnClick(sidebarMode, appNavigationSize))
  }

  return (
    <Button
      id="sidebar-control-button"
      variant="flat"
      onClick={handleClick}
      sx={{
        width: '52px',
        height: '43px',
        borderRadius: 0,
      }}
    >
      <Icon name={getCollapseButtonIcon(sidebarMode, appNavigationSize)} />
    </Button>
  )
}
