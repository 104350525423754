import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { DataSystemV2DTO } from '@ketch-com/figurehead'
import { fetchDataSystems } from '../fetchers/fetchDataSystems'

const limit = 50

export const useDataSystemsInfinite = ({
  q,
  hasIntegration,
  notInstalled,
}: {
  q?: string
  hasIntegration?: boolean
  notInstalled?: boolean
}) => {
  const resp = useInfiniteQuery({
    queryKey: [ApiQueryKeys.dataSystems, { q, hasIntegration }],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchDataSystemsResult = await fetchDataSystems({
        limit,
        start: (pageParam - 1) * limit,
        q,
        hasIntegration,
        notInstalled,
      })

      return {
        dataSystems: fetchDataSystemsResult.data.dataSystems ?? [],
        totalResults: fetchDataSystemsResult.data.totalResults ?? 0,
      }
    },
    getNextPageParam: ({ totalResults }, allPages) => {
      let totalItemsFetched = 0
      allPages?.forEach?.(pageOfResults => {
        totalItemsFetched += pageOfResults?.dataSystems?.length || 0
      })
      const nextPageParam = Math.ceil(totalItemsFetched / limit) + 1

      return totalItemsFetched === totalResults ? undefined : nextPageParam
    },
  })

  let dataSystemsInfinite: DataSystemV2DTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    const newDataSystems = page?.dataSystems
    dataSystemsInfinite = [...dataSystemsInfinite, ...newDataSystems]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: dataSystemsInfinite,
    totalResults,
  }
}
