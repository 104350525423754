import { PutAssetRequestBodyDTO } from '@ketch-com/figurehead'
import { EditAttributeFormValues } from 'interfaces/assets'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { getLabelDtoArrayFromManageLabelModalLabelArray } from 'pages/assetManager/utils'

type Args = { values: EditAttributeFormValues; identitySpaces: IdentitySpaceDTO[] }

export const mapValuesToPayload = ({ values, identitySpaces }: Args): PutAssetRequestBodyDTO => {
  let identitySpacePayload: IdentitySpaceDTO | undefined
  if (values.identitySpace) {
    identitySpacePayload = identitySpaces.find(identitySpace => identitySpace.code === values.identitySpace)
  }

  return {
    assetSummary: {
      asset: {
        description: values.description,
        assignedLabels: getLabelDtoArrayFromManageLabelModalLabelArray(values.labels),
        inferredLabels: getLabelDtoArrayFromManageLabelModalLabelArray(values.inferredLabels),
        owner: values.dataOwner,
        ...(!!identitySpacePayload && {
          identitySpace: {
            code: identitySpacePayload?.code,
            name: identitySpacePayload?.name,
          },
        }),
        ...(values.dataCategory && {
          dataCategory: [
            {
              isSystemLabel: true,
              code: 'data_category',
              value: values.dataCategory,
              name: 'DataCategory',
            },
          ],
        }),
      },
    },
  }
}
