import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchExperience } from 'api/experiences/fetchers/fetchExperience'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.experience,
  queryFn: fetchExperience,
  select: res => res?.data?.experience || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery, 'organizationCode'>

export const useExperience = (config: Config) => {
  const { userData } = useAuth()

  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
      organizationCode: userData.organizationCode!,
    },
  })
}
