import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  policyCode: string
  formData: AssetPolicyDTO
}

type Response = any

export const updatePolicyV2 = ({ policyCode, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/v2/policies/${policyCode}`,
    }),
    formData,
  )
