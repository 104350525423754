import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'

export const PreferenceThemeWelcomeTabContainerForm: React.FC = () => {
  return (
    <>
      {/* TODO:JB - Font selector */}
      <SidebarFormGroup sx={{ pt: 0 }}>
        <FormColorInput
          name={themeUpsertFormKeys.preferenceWelcomeTabContainerBackgroundColor}
          label={'Background Color'}
          fullWidth
          required
        />
      </SidebarFormGroup>
    </>
  )
}
