import React from 'react'

import { ThemePreviewViewType } from 'interfaces/themes-v2/themePreview'
import { BannerView, FormView, ModalView } from './components'

type Props = {
  view: ThemePreviewViewType
}

export const ThemeUpsertSidebar: React.FC<Props> = ({ view }) => {
  const isBannerView = view === ThemePreviewViewType.BANNER
  const isModalView = view === ThemePreviewViewType.MODAL
  const isFormView = view === ThemePreviewViewType.FORM

  if (isBannerView) return <BannerView />

  if (isModalView) return <ModalView />

  if (isFormView) return <FormView />

  return null
}
