import { SendbirdGroupChat } from '@sendbird/chat/groupChannel'

/**
 * Gets a group channel by URL.
 *
 * @param sb A sendbird SDK instance
 * @param channelUrl The URL of the channel to get
 */
export const getGroupChannel = async (sb: SendbirdGroupChat, channelUrl?: string, useCache = true) => {
  if (!channelUrl) {
    return null
  }

  try {
    return useCache ? sb.groupChannel.getChannel(channelUrl) : sb.groupChannel.getChannelWithoutCache(channelUrl)
  } catch (e) {
    console.error(e)
    return null
  }
}
