import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces'
import { OrganizationDTO, OrganizationFormDTO } from 'interfaces/organizations/organization'
import { formatCodeFromName } from 'utils/formatters'
import { RoutesManager } from 'utils/routing/routesManager'
import { getOrganizationFormValidation, getOrganizationFormValues } from 'pages/settings/organizations/upsert/utils'
import { FormInput } from 'components/form/FormInput'
import { FormImageUpload } from 'pages/settings/organizations/upsert/components/FormImageUpload/FormImageUpload'
import { AccessControlType } from 'components/ui-kit/form/imageUpload/hooks/index'
import { MAX_FILE_UPLOAD_SIZE_4_MB } from 'utils/constants/maxFileUploadSize'

import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode, FormRow } from '@ketch-com/deck'
import { NavigationBreadCrumbs } from '../../../../components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Box } from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import { FormInputColor } from 'components/form/FormInputColor'

type Props = {
  isReady: boolean
  isEditMode: boolean
  organization: MaybeNull<OrganizationDTO>
  onSubmit: (values: OrganizationFormDTO) => Promise<void>
}

export const OrganizationUpsert: React.FC<Props> = ({ isReady, isEditMode, organization, onSubmit }) => {
  const navigate = useNavigate()

  const validationSchema = useMemo(getOrganizationFormValidation, [])
  const initialValues = useMemo(
    () => ({
      ...getOrganizationFormValues({ organization }),
    }),
    [organization],
  )

  const breadcrumbs = isEditMode
    ? [
        { title: 'Settings', link: RoutesManager.settings.root.getURL() },
        { title: 'Organization', link: RoutesManager.settings.organization.root.getURL() },
        { title: 'Edit Organization' },
      ]
    : [
        { title: 'Settings', link: RoutesManager.settings.root.getURL() },
        { title: 'Organization', link: RoutesManager.settings.organization.root.getURL() },
        { title: 'Create' },
      ]

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })

  const { isSubmitting, submitForm, setFieldValue, setFieldTouched } = formikProps

  return (
    <FormikProvider value={formikProps}>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <UpsertLayout
        mode={UpsertLayoutMode.edit}
        renderFormTitle={() => (
          <ContentGroup title={isEditMode ? 'Edit Organization' : 'Create Organization'} titleVariant="h2" isDivider />
        )}
        onAccept={submitForm}
        onCancel={() => navigate(RoutesManager.settings.organization.root.getURL())}
        acceptButtonProps={{
          pending: isSubmitting,
        }}
        acceptActionButtonText="Save"
      >
        {isReady ? (
          <Box display="flex" flexDirection="column" gap={3}>
            <FormRow title="Basic Details">
              <Grid container padding={0} gap={3}>
                <Grid item xs={6}>
                  <FormInput
                    required
                    fullWidth
                    label="Name"
                    formPropertyName="name"
                    placeholder="Example: My New Organization"
                    onChange={e => {
                      if (!isEditMode) {
                        const formattedCode = formatCodeFromName(e.target.value)

                        setFieldValue('code', formattedCode)
                        setFieldTouched('code', true)
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    required
                    fullWidth
                    disabled={isEditMode}
                    label="Code"
                    formPropertyName="code"
                    placeholder="Example: default_organization"
                  />
                </Grid>
              </Grid>
            </FormRow>
            {/* TODO - disable logo upload until BE built */}
            <FormRow title="Logo" subTitle="The logo will be used in automated customer communication." hasTopBorder>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {/* /!* See components/completeStep/WorkflowExecutionStepCompleteModal.tsx *!/ */}
                  <FormImageUpload
                    previewModeOn={true}
                    name="logoURL"
                    disabled={isSubmitting}
                    maxSize={MAX_FILE_UPLOAD_SIZE_4_MB}
                    uploadContext={{
                      version: `${new Date().getTime()}`,
                      folder: 'logo',
                      bucket: '',
                      acl: `${AccessControlType.public}`,
                    }}
                  />
                </Grid>
              </Grid>
            </FormRow>

            <FormRow
              title="Colors"
              subTitle="The colors will be used to create an experience server theme template."
              hasTopBorder
            >
              <Grid container padding={0} gap={3}>
                <Grid item xs={6}>
                  <FormInputColor
                    shouldUpdateDebounced
                    id="primaryBrandColor"
                    required
                    fullWidth
                    label="Primary Brand Color"
                    formPropertyName="primaryBrandColor"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInputColor
                    shouldUpdateDebounced
                    id="secondaryBrandColor"
                    required
                    fullWidth
                    label="Secondary Brand Color"
                    formPropertyName="secondaryBrandColor"
                  />
                </Grid>
              </Grid>
            </FormRow>
          </Box>
        ) : (
          <Box
            sx={{
              margin: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner size="32px" thickness={2.5} />
          </Box>
        )}
      </UpsertLayout>
    </FormikProvider>
  )
}
