import React, { useState } from 'react'

import { Text } from 'components/ui-kit/typography/Text'
import { ExecutionResultHookDTO } from '@ketch-com/figurehead'
import { HookExecutedDmlModal } from './HookExecutedDmlModal'

type ExecutedDmlCellRendererProps = {
  executionResultHook?: ExecutionResultHookDTO
}

export const ExecutedDmlCellRenderer: React.FC<ExecutedDmlCellRendererProps> = ({ executionResultHook }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <>
      <Text size={14} color="sphere" sx={{ textDecoration: 'underline' }} onClick={() => setIsModalOpen(true)}>
        View
      </Text>
      {isModalOpen && <HookExecutedDmlModal hook={executionResultHook} onCancel={() => setIsModalOpen(false)} />}
    </>
  )
}
