import Box from '@mui/material/Box'

import { makeStyles, createStyles } from '@mui/styles'

import { Text } from 'components/ui-kit/typography/Text'
import { List } from 'components/ui-kit/list/List'
import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { usePurpose } from 'api/purposes/queries/usePurpose'
import { ASSET_TYPE_NAMES } from 'interfaces/assets'
import { getMapEffectUrlToString } from 'pages/assetManager/utils'

type Props = {
  policy: AssetPolicyDTO
}

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      columnContainer: {
        borderRight: `1px solid ${palette.lightGrey.main}`,
      },
      columnHeaderText: {
        marginBottom: spacing(1),
      },
      bulletList: {
        listStyleType: 'disc',
      },
    }),
  { name: 'PoliciesTableExpandableRow' },
)

export const PoliciesTableExpandableRow: React.FC<Props> = ({ policy }) => {
  const classes = useStyles()

  const { data: purpose } = usePurpose({
    enabled: !!policy?.purpose,
    params: {
      purposeCode: policy?.purpose || '',
    },
  })

  return (
    <Box display="flex" flexDirection="row">
      {/* Column 1: Purpose */}

      <Box flex={1} p={2.25} className={classes.columnContainer} display="flex" flexDirection="column">
        <Text className={classes.columnHeaderText} size={12} weight={600}>
          Purposes
        </Text>
        <Text size={12}>When accessing data for the purpose of</Text>
        <List
          borderless
          items={[
            {
              active: false,
              content: <Text size={12}>{purpose?.displayName}</Text>,
              disabled: false,
              icon: <>&#8226;</>,
              onClick: () => {},
            },
          ]}
          size="small"
        />
      </Box>

      {/* Column 2: Resources */}

      <Box flex={1.25} p={2.25} className={classes.columnContainer} display="flex" flexDirection="column">
        <Text className={classes.columnHeaderText} size={12} weight={600}>
          Resources
        </Text>

        <Text size={12}>Allow access to the following resources:</Text>
        <List
          borderless
          items={
            (policy?.assets || [])?.length > 0
              ? (policy?.assets || []).map(ass => {
                  if (ass?.named) {
                    return {
                      active: false,
                      content: (
                        <Text size={12}>
                          {ASSET_TYPE_NAMES[ass?.type || 0]} named {ass.named}
                        </Text>
                      ),
                      disabled: false,
                      icon: <>&#8226;</>,
                      onClick: () => {},
                    }
                  }

                  if (Object.keys(ass?.labeled || {}).length > 0) {
                    return {
                      active: false,
                      content: (
                        <Text size={12}>
                          {ASSET_TYPE_NAMES[ass?.type || 0]} labeled {Object.keys(ass?.labeled || {})?.[0]}{' '}
                          {Object.values(ass?.labeled || {})?.[0]}
                        </Text>
                      ),
                      disabled: false,
                      icon: <>&#8226;</>,
                      onClick: () => {},
                    }
                  }

                  return {
                    active: false,
                    content: null,
                    disabled: false,
                    icon: null,
                    onClick: () => {},
                  }
                })
              : []
          }
          size="small"
        />
      </Box>

      {/* Column 3: Effects and Filters */}

      <Box p={2.25} flex={1} display="flex" flexDirection="column">
        <Text className={classes.columnHeaderText} size={12} weight={600}>
          Attribute Effects
        </Text>

        <Text size={12}>with the following attribute effects</Text>
        <List
          borderless
          items={
            (policy?.effects || [])?.length > 0
              ? (policy?.effects || []).map(effect => {
                  if (effect?.named) {
                    return {
                      active: false,
                      content: (
                        <Text size={12}>
                          {getMapEffectUrlToString(effect?.effect || '')} attributes {effect?.named || ''}
                        </Text>
                      ),
                      disabled: false,
                      icon: <>&#8226;</>,
                      onClick: () => {},
                    }
                  }

                  if (Object.keys(effect?.labeled || {}).length > 0) {
                    return {
                      active: false,
                      content: (
                        <Text size={12}>
                          {getMapEffectUrlToString(effect?.effect || '')} labeled{' '}
                          {Object.keys(effect?.labeled || {})?.[0]} {Object.values(effect?.labeled || {})?.[0]}
                        </Text>
                      ),
                      disabled: false,
                      icon: <>&#8226;</>,
                      onClick: () => {},
                    }
                  }

                  return {
                    active: false,
                    content: null,
                    disabled: false,
                    icon: null,
                    onClick: () => {},
                  }
                })
              : []
          }
          size="small"
        />

        <Text className={classes.columnHeaderText} size={12} weight={600}>
          Row Filters
        </Text>

        <Text size={12}>and the following row filters:</Text>

        <List
          borderless
          items={
            (policy?.rowFilters || [])?.length > 0
              ? (policy?.rowFilters || []).map(filter => ({
                  active: false,
                  content: (
                    <Text size={12}>
                      {filter.attributeName} {filter.condition} {filter.value}
                    </Text>
                  ),
                  disabled: false,
                  icon: <>&#8226;</>,
                  onClick: () => {},
                }))
              : []
          }
          size="small"
        />
      </Box>
    </Box>
  )
}
