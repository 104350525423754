import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { WorkflowExecutionUpdateDTO } from 'interfaces/workflowExecutions/workflowExecution'

export type WorkflowExecutionCompleteFormValues = {
  resolutionDetails: string
  resolutionAttachments: FileInfoDTO[]
}

export const mapValuesToPayload = ({
  values,
  workflowExecutionId,
}: {
  values: WorkflowExecutionCompleteFormValues
  workflowExecutionId: string
}): WorkflowExecutionUpdateDTO => ({
  temporalWorkflowID: `${workflowExecutionId}Level1Integration`,
  signalName: 'processorTaskComplete',
  signalValue: {
    resolutionDetails: values.resolutionDetails,
    resolutionAttachments: values.resolutionAttachments.map(file => file.info_url),
  },
})
