import { WorkflowActivityCode, WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep } from '../../interfaces'
import { getStepTransitions } from './getStepTransitions'
import { getNextJoinTile } from './getNextJoinTile'

type Props = {
  step: ICanvasStep
  steps: ICanvasStep[]
  requireNestedGatewayValidation?: boolean
  requireAllPathsLeadingToJoinValidation?: boolean
}

const getParentSteps = ({ steps, step }: Props) => {
  return steps.filter(listStep => getStepTransitions(listStep).includes(step?.ID))
}

const getStepListFromLastJoin = (steps: ICanvasStep[], step: ICanvasStep, stepChain: ICanvasStep[] = []) => {
  const parentStep = getParentSteps({ steps, step })[0]

  const parentStepIsJoinOrStartTile =
    parentStep?.gateway?.mode === WorkflowActivityGatewayMode.JOIN ||
    parentStep?.activityCode === WorkflowActivityCode.START ||
    !parentStep

  if (!parentStepIsJoinOrStartTile) {
    getStepListFromLastJoin(steps, parentStep, stepChain)
  }

  stepChain.push(parentStep)

  return stepChain
}

export const isNewStepRootOfConnectedNest = ({ steps, step }: Props) => {
  const stepList = getStepListFromLastJoin(steps, step)

  const doesStepListHaveAConnectedNestStart =
    stepList.filter(listStep =>
      // Preventing nested gateway validation is required to avoid an infinite loop, since these methods call each other.
      isNewStepConnectedNestStart({ steps, step: listStep, requireNestedGatewayValidation: false }),
    ).length > 0

  return !doesStepListHaveAConnectedNestStart
}

export const isNewStepConnectedNestStart = ({
  step,
  steps,
  requireNestedGatewayValidation = false,
  requireAllPathsLeadingToJoinValidation = false,
}: Props): boolean => {
  const isNestStart =
    step?.gateway?.mode === WorkflowActivityGatewayMode.SPLIT ||
    step?.gateway?.mode === WorkflowActivityGatewayMode.DECISION_MULTI ||
    step?.gateway?.mode === WorkflowActivityGatewayMode.DECISION_SINGLE

  if (!isNestStart) return false

  const isRootOfConnectedNest = requireNestedGatewayValidation ? isNewStepRootOfConnectedNest({ steps, step }) : true
  if (!isRootOfConnectedNest) return false

  const nextJoinTiles = requireAllPathsLeadingToJoinValidation
    ? (step.gateway?.transitions || []).map(transition =>
        getNextJoinTile({ step: transition, steps: steps, onlyUntilNextNestStart: false }),
      )
    : []

  const doAllPathsLeadToJoin = !nextJoinTiles.includes(undefined)
  return doAllPathsLeadToJoin
}
