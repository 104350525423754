import React from 'react'

import { UserDTO } from 'interfaces/users/users'
import { Button, DataGrid, Icon, TableCell } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useInstalledDataSystems } from 'api/dataSystems/queries/useInstalledDataSystems'
import { InstalledDataSystemV2DTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  user: UserDTO
}

export const UsersViewSystems: React.FC<Props> = ({ user }) => {
  const { data, isLoading } = useInstalledDataSystems({
    itemsPerPage: 300,
    params: {
      ownerUserIds: user.ID,
    },
  })
  const navigate = useNavigate()

  return (
    <Box>
      <Typography variant="h3" my={3}>
        Systems
      </Typography>
      <DataGrid
        loading={isLoading}
        rows={data || []}
        disableChildrenSorting
        disableMultipleColumnsSorting
        noRowsOverlayProps={{
          subTitle: 'No Systems are assigned to this user.',
          buttonTitle: '',
        }}
        columns={[
          {
            align: 'left',
            field: 'name',
            headerAlign: 'left',
            headerName: 'Name',
            flex: 1,
            sortable: false,
            renderCell: ({ row }: GridRenderCellParams<InstalledDataSystemV2DTO>) => {
              return (
                <Box display="flex" gap={1} alignItems="center">
                  <TableCell title={row.dataSystem?.name} subTitle={row.description} />
                </Box>
              )
            },
          },
          {
            align: 'left',
            field: 'view',
            headerAlign: 'left',
            headerName: '',
            flex: 1,
            sortable: false,
            renderCell: ({ row }: GridRenderCellParams<InstalledDataSystemV2DTO>) => {
              return (
                <TableCell>
                  <Button color="tertiary" onClick={() => navigate(RoutesManager.systems.root.getURL())}>
                    <Box component="span" mr={0.5}>
                      View
                    </Box>
                    <Icon width={20} height={20} name="OShare" />
                  </Button>
                </TableCell>
              )
            },
          },
        ]}
      />
    </Box>
  )
}
