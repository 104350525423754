import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormRadioGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormRadioGroup'
import { ItemStyle } from '@ketch-sdk/ketch-types'
import { ItemStyleLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { getIn, useFormikContext } from 'formik'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { handleButtonStyleChange } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'
import {
  BuilderTextInputMode,
  FormTextInput,
} from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'

export const PreferenceThemePurposesTabFooterForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ThemeV3DTO>()

  const style = getIn(values, themeUpsertFormKeys.preferencePurposesTabFooterActionButtonStyle)
  const isFilled = style === ItemStyle.Filled

  return (
    <>
      {/* TODO:JB - Descoped */}
      {/* <SidebarFormGroup sx={{ pt: 0, gap: '16px' }} hasBorderBottom>
        <FormColorInput
          name={themeUpsertFormKeys.preferencePurposesTabFooterBackgroundColor}
          label={'Footer Background Color'}
          fullWidth
          required
        />
      </SidebarFormGroup> */}
      <SidebarFormGroup sx={{ pt: 0, gap: '16px' }} title={'Action Button'}>
        <SidebarFormRadioGroup
          name={themeUpsertFormKeys.preferencePurposesTabFooterActionButtonStyle}
          title={'Style'}
          options={Object.values(ItemStyle).map(style => ({
            label: ItemStyleLabels[style],
            value: style,
          }))}
          row
          onChange={newStyle =>
            handleButtonStyleChange(
              values,
              setFieldValue,
              newStyle,
              themeUpsertFormKeys.preferencePurposesTabFooterActionButtonTextColor,
            )
          }
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferencePurposesTabFooterActionButtonBackgroundColor}
          label={isFilled ? 'Background Color' : 'Border Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferencePurposesTabFooterActionButtonTextColor}
          label={'Text Color'}
          fullWidth
          required
        />
        <FormTextInput
          name={themeUpsertFormKeys.preferencePurposesTabFooterActionButtonCornerRadius}
          label="Corner Radius"
          inputWidth="92px"
          mode={BuilderTextInputMode.CornerRadius}
        />
      </SidebarFormGroup>
    </>
  )
}
