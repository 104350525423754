import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'
import { Chip, Icon, theme } from '@ketch-com/deck'

const PREFIX = 'PermitsTrendChart'

const classes = {
  legend: `${PREFIX}-legend`,
  legendIcon: `${PREFIX}-legendIcon`,
  legendIconSquare: `${PREFIX}-legendIconSquare`,
  legendIconCircle: `${PREFIX}-legendIconCircle`,
  legendIconLineAllowed: `${PREFIX}-legendIconLineAllowed`,
  legendIconLineTotal: `${PREFIX}-legendIconLineTotal`,
  legendItem: `${PREFIX}-legendItem`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.legend}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 22,
    gap: '24px',
  },

  [`& .${classes.legendIcon}`]: {
    marginRight: 10,
  },

  [`& .${classes.legendIconSquare}`]: {
    width: 16,
    height: 16,
    borderRadius: 2,
  },

  [`& .${classes.legendIconCircle}`]: {
    width: 8,
    height: 8,
    borderRadius: '50%',
  },

  [`& .${classes.legendIconLineAllowed}`]: {
    width: 16,
    height: 1,
    borderBottom: `2px solid ${palette.Infographics.VioletA.Primary}`,
    borderRadius: 2,
  },

  [`& .${classes.legendIconLineTotal}`]: {
    width: 16,
    height: 1,
    borderBottom: `2px solid ${palette.Black.o100}`,
    borderRadius: 2,
  },

  [`& .${classes.legendItem}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}))

type Props = {
  reshapedDailyTrendData: any
}
export const Legend: React.FC<Props> = ({ reshapedDailyTrendData }) => {
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])
  return (
    <StyledBox
      mt={3}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ opacity: reshapedDailyTrendData.length === 0 ? 0 : 1, mb: '16px', mr: '16px', ml: '16px' }}
    >
      <Chip label="Last 90 Days" size="small" />
      <Box className={classes.legend}>
        <Box className={classes.legendItem}>
          <Box
            className={clsx(classes.legendIcon, classes.legendIconCircle)}
            sx={{
              backgroundColor: ({ palette }) => palette.Infographics.GreenA.Primary,
            }}
          />
          <Typography variant="smallLabel">{t('Consent')}</Typography>
        </Box>
        <Box className={classes.legendItem}>
          <Box
            className={clsx(classes.legendIcon, classes.legendIconCircle)}
            sx={{
              backgroundColor: ({ palette }) => palette.Infographics.BlueC.Primary,
            }}
          />
          <Typography variant="smallLabel">{t('Consent Not Required')}</Typography>
        </Box>
        <Box className={classes.legendItem}>
          <Box
            className={clsx(classes.legendIcon, classes.legendIconCircle)}
            sx={{
              backgroundColor: ({ palette }) => palette.Black.o16,
            }}
          />
          <Typography variant="smallLabel">{t('No Consent')}</Typography>
        </Box>
        <Box className={classes.legendItem}>
          <Icon
            name="OMinus"
            iconColor={theme.palette.Infographics.VioletA.Primary}
            width={20}
            height={20}
            sx={{ mr: '4px' }}
          />
          <Typography variant="smallLabel">{t('Allowed')}</Typography>
        </Box>

        <Box className={classes.legendItem}>
          <Icon name="OMinus" iconColor={theme.palette.Black.o100} width={20} height={20} sx={{ mr: '4px' }} />
          <Typography variant="smallLabel">{t('Total')}</Typography>
        </Box>
      </Box>
    </StyledBox>
  )
}
