import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'
import { TabsListContainer } from './tabs/tabsListContainer'
import { MaybeNull } from 'interfaces/common'
import { useUnreadNotificationsCount } from 'api/notifications/queries/useUnreadNotificationsCount'
import { NavigationUpdatesContextProvider } from './context/NavigationUpdatesContext'
import { ActionSheetWrapper, Icon, theme } from '@ketch-com/deck'
import { Tooltip, Typography } from '@mui/material'
import { HeaderSectionWrapper } from '../HeaderSectionWrapper'

export const HeaderNotifications: React.FC = () => {
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState<MaybeNull<HTMLDivElement>>(null)

  const isOpen = Boolean(anchorEl)
  const isOnNotificationsPage = location.pathname.includes('/updates')

  const popoverId = isOpen ? 'user-profile-popover' : undefined

  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    event.preventDefault()

    setAnchorEl(event.currentTarget)
  }

  const handleClose = React.useCallback(() => {
    setAnchorEl(null)
  }, [])

  useEffect(() => {
    handleClose()
  }, [location.pathname, handleClose])

  const { data: unreadNotificationsCount } = useUnreadNotificationsCount()
  const hasUnreadNotifications = unreadNotificationsCount > 0

  const fontColor = isOnNotificationsPage
    ? theme.palette.White.o100
    : hasUnreadNotifications
    ? theme.palette.Action.PrimaryDark
    : theme.palette.Black.o100
  const backgroundColor = isOnNotificationsPage
    ? theme.palette.Highlight.PrimaryDark
    : hasUnreadNotifications
    ? theme.palette.Action.Disabled
    : theme.palette.Black.o8

  const darkModeSx = {
    display: 'flex',
    alignItems: 'center',
    color: 'darkDusk.main',
    height: '28px',
    pl: 0.75,
    pr: 1.5,
    py: 0.125,
    borderRadius: 12.5,
    '&:hover': {
      opacity: 0.8,
      cursor: 'pointer',
    },
    backgroundColor,
  }

  return (
    <NavigationUpdatesContextProvider onClose={handleClose}>
      <HeaderSectionWrapper selected={isOnNotificationsPage} sx={{ mx: '8px' }}>
        <Tooltip title={<Typography variant="label">Notifications</Typography>}>
          <span>
            <Box id="notifications-button" aria-describedby={popoverId} onClick={handleOpen} sx={darkModeSx}>
              <Icon name={!!unreadNotificationsCount ? 'FChatNotification' : 'OChat'} iconColor={fontColor} />
              <Typography variant="smallLabel" color={fontColor} ml={0.25}>
                {unreadNotificationsCount}
              </Typography>
            </Box>
          </span>
        </Tooltip>
      </HeaderSectionWrapper>

      <ActionSheetWrapper
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        transitionDuration={0}
        sx={{
          '& .MuiPopover-paper': {
            minWidth: '387px',
            maxWidth: '387px',
            '& .MuiList-root': {
              paddingRight: '8px !important',
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <TabsListContainer />
      </ActionSheetWrapper>
    </NavigationUpdatesContextProvider>
  )
}
