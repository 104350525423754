import Box from '@mui/material/Box'
import { Text } from 'components/ui-kit/typography/Text'
import { useParams } from 'react-router-dom'
import { DataRiskDetailLayout } from 'pages/insights/dataRisks/components/layout/DataRiskDetailLayout'
import { ReactComponent as DataRisksSuggestionPeople } from 'assets/icons/data_risks_suggestion_people.svg'
import { ReactComponent as DataRisksSuggestionShield } from 'assets/icons/data_risks_suggestion_shield.svg'
import { Button } from '@ketch-com/deck'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { GreyBox } from 'pages/insights/dataRisks/components/layout/GreyBox'
import { getRiskScoreDetails } from 'pages/insights/utils'
import { Table } from 'components/ui-layouts/table/Table'
import { AssetTypeIcon } from 'pages/assetManager/components'
import { OverAccessData } from '../../__mocks__/overAccess'
import { LineChart } from 'pages/insights/dataRisks/components/LineChart'
import { OverAccessChart } from './OverAccessChart/OverAccessChart'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'

type Props = {
  overAccessRecords: OverAccessData[]
}

type TableRow = {
  name: string
  lastUsed: number
  icon: JSX.Element
}

export const OverAccessDetails: React.FC<Props> = ({ overAccessRecords }) => {
  const { code: overAccessRowId } = useParams<{ code?: string }>()
  const overAccessRecord = overAccessRecords.find(record => record.id === overAccessRowId)
  const riskScoreDetails = getRiskScoreDetails(overAccessRecord?.riskScore || 0)

  const actions = (
    <ButtonOptions size="big" items={[{ content: <Text>Export</Text> }, { content: <Text>Compare</Text> }]} />
  )

  const whyContent = (
    <Box>
      <Text component="div" sx={{ mb: 0.75 }}>
        Total access, PII records
      </Text>
      <Text component="div" sx={{ mb: 4 }} weight={700} size={20}>
        123,785
      </Text>
      <Text component="div" sx={{ mb: 0.75 }}>
        Access utilization, PII records
      </Text>
      <Text weight={700} size={20} color={riskScoreDetails.textColorName}>
        75,165 (68%) not used
      </Text>
    </Box>
  )

  const suggestionsContent = [
    {
      icon: <DataRisksSuggestionShield />,
      textContent: (
        <Text lineHeight="20px" component="div">
          Consider restricting or revising {overAccessRecord?.name?.split(' ')[0]}'s access rights to eliminate adjacent
          data breach risks.
        </Text>
      ),
    },
    {
      icon: <DataRisksSuggestionPeople />,
      textContent: (
        <Text lineHeight="20px" component="div">
          If {overAccessRecord?.name?.split(' ')[0]} has role-based access—consider reviewing your role access models to
          ensure efficiency and security.
        </Text>
      ),
    },
  ]

  const tableDataSystems: TableRow[] = [
    { name: 'Archive', lastUsed: new Date().getTime(), icon: <AssetTypeIcon assetType={1} className="" /> },
    { name: 'CUSTOMER', lastUsed: new Date().getTime(), icon: <AssetTypeIcon assetType={2} className="" /> },
    { name: 'Store', lastUsed: new Date().getTime(), icon: <AssetTypeIcon assetType={1} className="" /> },
    { name: 'CALL CENTER', lastUsed: new Date().getTime(), icon: <AssetTypeIcon assetType={2} className="" /> },
    {
      name: 'EMPLOYEE_PERSONAL_INFO',
      lastUsed: new Date().getTime(),
      icon: <AssetTypeIcon assetType={2} className="" />,
    },
  ]

  const lowerContentRows = [
    <GreyBox heading="Top 5 Unused" flexBasis="67%" key={1}>
      <Table
        items={tableDataSystems}
        cellSettings={{
          name: {
            label: 'Name',
            cellRenderer: (row: TableRow) => (
              <Box display="flex" gap={1.5}>
                {row.icon}
                <Text>{row.name}</Text>
              </Box>
            ),
          },
          lastUsed: {
            width: 175,
            labelNodeRenderer: () => <Text weight={600}>Last Used</Text>,
            cellRenderer: (row: TableRow) => (
              <Text color="grey" align="right" component="div" width="100%">
                {formatDateTimeFromUnix(row.lastUsed / 1000)}
              </Text>
            ),
          },
        }}
      />
      <Box mt={2.5}>
        <Button color="tertiary">View Report</Button>
      </Box>
    </GreyBox>,
    <GreyBox heading="Usage vs Access" flexBasis="33%" key={2}>
      <OverAccessChart riskScore={overAccessRecord?.riskScore || 50} name={overAccessRecord?.name || 'Current User'} />
      <Box mt={2.5}>
        <Button color="tertiary">View Report</Button>
      </Box>
    </GreyBox>,
  ]

  const mainContent = (
    <Box width={568} height={286}>
      <LineChart riskTrend={overAccessRecord?.riskTrend} />
    </Box>
  )

  return (
    <DataRiskDetailLayout
      heading={overAccessRecord?.name || ''}
      actions={actions}
      mainContent={mainContent}
      riskScoreDetails={riskScoreDetails}
      riskScore={overAccessRecord?.riskScore}
      whyContent={whyContent}
      suggestionContent={suggestionsContent}
      lowerContentRows={lowerContentRows}
    />
  )
}
