import React, { Fragment } from 'react'
import Grid from '@mui/material/Grid'
import { Formik } from 'formik'
import { compact } from 'lodash'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { formatCodeFromName } from 'utils/formatters'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'

import { getInternalSystemDSRWorkflowInitialValues } from 'pages/orchestration/workflows/edit/utils/forms/activities/internalSystemDSRWorkflow/internalSystemDSRInitialValues'
import { getInternalSystemDSRFormValidationSchema } from 'pages/orchestration/workflows/edit/utils/forms/activities/internalSystemDSRWorkflow/internalSystemDSRValidation'
import { Box } from '@mui/material'
import { FormInput } from 'components/form/FormInput'

interface IChildWorkflowFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const InternalSystemDSRForm: React.FC<IChildWorkflowFormProps> = props => {
  const { step, steps, handleClose, handleRemove, handleSave } = props
  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))

  const onSubmit = (values: any) => {
    handleSave({
      step: {
        ...step,
        valid: true,
        code: values.code,
        description: values.description,
      },
    })
  }

  return (
    <SidebarConfigsWrapper handleClose={handleClose}>
      <Formik
        initialValues={getInternalSystemDSRWorkflowInitialValues({ step })}
        validationSchema={getInternalSystemDSRFormValidationSchema({ usedCodes })}
        validateOnMount
        onSubmit={onSubmit}
      >
        {form => {
          const { isValid, submitForm, setFieldValue } = form

          return (
            <Fragment>
              <SidebarConfigsHeader
                isValid={isValid}
                step={step}
                steps={steps}
                handleSave={() => submitForm()}
                handleRemove={() => handleRemove({ step })}
                handleClose={handleClose}
                copyable
              />

              <SidebarConfigsBody sx={{ px: 3 }}>
                <Box>
                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormInput
                          required
                          formPropertyName="description"
                          fullWidth
                          label="Description Label"
                          placeholder="Enter description"
                          onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
                          shouldUpdateDebounced
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <FormInput
                        formPropertyName="code"
                        fullWidth
                        label="Code"
                        placeholder="Enter code"
                        required
                        shouldUpdateDebounced
                      />
                    </Grid>
                  </Grid>
                </Box>
              </SidebarConfigsBody>
            </Fragment>
          )
        }}
      </Formik>
    </SidebarConfigsWrapper>
  )
}
