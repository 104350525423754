import { useMemo } from 'react'
import { getContrastColor, fullConfigBeta } from '@ketch-com/lanyard'
import { uniq } from 'lodash'

import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { ColorScheme } from 'components/ui-kit/colorScheme/ColorScheme'

type Props = {
  theme: ThemeDTO
}

export const ThemeColorScheme = ({ theme }: Props) => {
  const colors = useMemo(() => {
    const {
      bannerBackgroundColor = fullConfigBeta?.theme?.bannerBackgroundColor,
      bannerContentColor,
      bannerButtonColor = fullConfigBeta?.theme?.bannerButtonColor,
      modalHeaderBackgroundColor = fullConfigBeta?.theme?.modalHeaderBackgroundColor,
      modalHeaderContentColor,
      modalContentColor = fullConfigBeta?.theme?.modalContentColor,
      modalButtonColor = fullConfigBeta?.theme?.modalButtonColor,
      formHeaderBackgroundColor = fullConfigBeta?.theme?.formHeaderBackgroundColor,
      formHeaderContentColor,
      formContentColor = fullConfigBeta?.theme?.formContentColor,
      formButtonColor = fullConfigBeta?.theme?.formButtonColor,
      modalSwitchOffColor = '#daddde',
      modalSwitchOnColor = '#071a24',
      formSwitchOffColor = '#daddde',
      formSwitchOnColor = '#071a24',
      bannerSecondaryButtonColor = bannerBackgroundColor,
    } = theme

    return uniq([
      bannerBackgroundColor,
      bannerContentColor || getContrastColor(bannerBackgroundColor),
      bannerButtonColor,
      modalHeaderBackgroundColor,
      modalHeaderContentColor || getContrastColor(modalHeaderBackgroundColor),
      modalContentColor,
      modalButtonColor,
      formHeaderBackgroundColor,
      formHeaderContentColor || getContrastColor(formHeaderBackgroundColor),
      formContentColor,
      formButtonColor,
      modalSwitchOffColor,
      modalSwitchOnColor,
      modalSwitchOffColor,
      modalSwitchOnColor,
      bannerSecondaryButtonColor,
      formSwitchOffColor,
      formSwitchOnColor,
    ]).slice(0, 7)
  }, [theme])

  return <ColorScheme colors={colors} />
}
