import React from 'react'
import { Box } from '@mui/material'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'

import { AssetSummaryDTO, DmlHookDTO } from '@ketch-com/figurehead'
import { DraggableHookItem, HooksSelect } from '../components'
import { KETCH_DML } from 'pages/developers/hooks/constants'
import { FormikErrors } from 'formik'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { MaybeNull } from 'interfaces'

type Props = {
  onDragEnd: (result: DropResult) => void
  hooks: DmlHookDTO[]
  values: EditDatabaseFormValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<EditDatabaseFormValues>>
  assetSummary?: MaybeNull<AssetSummaryDTO>
}

export const HooksFormSection: React.FC<Props> = ({ onDragEnd, hooks, setFieldValue, values, assetSummary }) => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          my: 2.25,
          pb: 2.25,
          borderBottom: ({ palette }) => `1px solid ${palette.iron.main}`,
        }}
      >
        <HooksSelect setFieldValue={setFieldValue} values={values} assetSummary={assetSummary} />
      </Box>
      <Box display="flex" flexDirection="column">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => {
              const { innerRef, droppableProps } = provided
              return (
                <Box {...droppableProps} ref={innerRef} p={1}>
                  {(hooks || []).map((hook: DmlHookDTO, index: number) => {
                    const dmlIndex = hooks.findIndex((item: any) => item.name === KETCH_DML)
                    const shouldHaveBorderBottom =
                      hooks.length - 1 !== index && index !== dmlIndex - 1 && hook?.name !== KETCH_DML

                    return (
                      <Draggable
                        key={hook.id}
                        draggableId={hook?.id || ''}
                        index={index}
                        isDragDisabled={hook?.name === KETCH_DML}
                      >
                        {(provided, snapshot) => (
                          <DraggableHookItem
                            provided={provided}
                            snapshot={snapshot}
                            hook={hook}
                            index={index}
                            shouldHaveBorderBottom={shouldHaveBorderBottom}
                            setFieldValue={setFieldValue}
                            values={values}
                          />
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </Box>
              )
            }}
          </Droppable>
        </DragDropContext>
      </Box>
    </>
  )
}
