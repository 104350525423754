import React from 'react'
import { FieldTypeFormSection, BasicDetailsFormSection } from '.'
import { useFormFieldUpsertContainerUtils } from '../hooks'
import { FormFieldUpsertContextProvider } from '../context/FormFieldUpsertContextProvider'
import { Banner, Button, ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import Box from '@mui/material/Box'
import pluralize from 'pluralize'
import { FormFieldSaveChangesModal, FormFieldUsedInTemplatesListModal } from '../../../components'
import { useScrollToTopOnLoad } from 'utils/hooks'
import { useFormFieldsRelationshipData } from 'pages/consentAndRights/forms/hooks'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormikProvider, useFormik } from 'formik'

type Props = {}

export const EditFormField: React.FC<Props> = () => {
  const [isTemplatesListModalOpen, setIsTemplatesListModalOpen] = React.useState(false)
  const [isSaveChangesModalOpen, setIsSaveChangesModalOpen] = React.useState(false)
  const {
    breadcrumbs,
    initialValues,
    validationSchema,
    isReady,
    getDummyDropdownItem,
    targetBackToUrl,
    handleSubmit,
    navigate,
    formField,
  } = useFormFieldUpsertContainerUtils()

  const { formFieldAuthenticators, formFieldExperiencesActive, formFieldTemplatesActive, formFieldActivitiesActive } =
    useFormFieldsRelationshipData(formField)

  const totalDeleteBlockingAssociations =
    formFieldTemplatesActive.length + formFieldExperiencesActive.length + formFieldAuthenticators.length

  const shouldWarn = totalDeleteBlockingAssociations > 0

  const generateMessage = (): string => {
    const categories = [
      { label: `deployed Form`, count: formFieldTemplatesActive?.length },
      { label: `Experience`, count: formFieldExperiencesActive?.length },
      { label: `Authenticator`, count: formFieldAuthenticators?.length },
      { label: `enabled Workflow`, count: formFieldActivitiesActive?.length },
    ]

    const filteredCategories = categories
      .filter(({ count }) => count !== 0)
      .map(({ label, count }) => `${count} ${pluralize(label, count ?? 0)}`)

    if (filteredCategories.length > 1) {
      filteredCategories.splice(-1, 0, 'and')
    }

    const message = filteredCategories.join(', ')

    return `This field is used in ${message}. All changes will take effect immediately after saving.`.replace(
      'and,',
      'and',
    )
  }

  useScrollToTopOnLoad()

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  const { isSubmitting, submitForm } = formikProps

  return (
    <>
      {shouldWarn && (
        <Box width={1280} mb={2}>
          <Banner
            severity="warning"
            title={generateMessage()}
            onClick={e => {
              e.stopPropagation()
            }}
            action={
              <Button
                variant="link"
                onClick={() => {
                  setIsTemplatesListModalOpen(true)
                }}
              >
                View List
              </Button>
            }
          />
        </Box>
      )}
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={UpsertLayoutMode.edit}
          renderFormTitle={() => <ContentGroup title="Edit Custom Form Field" titleVariant="h2" isDivider />}
          onAccept={() => {
            if (shouldWarn) {
              setIsSaveChangesModalOpen(true)
              return
            } else {
              submitForm()
            }
          }}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          acceptActionButtonText="Save"
          onCancel={() => {
            navigate(targetBackToUrl)
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {!isReady ? (
              <Box display="flex" justifyContent="center">
                <Spinner size="32px" thickness={2.5} />
              </Box>
            ) : (
              <FormFieldUpsertContextProvider getDummyDropdownItem={getDummyDropdownItem}>
                <BasicDetailsFormSection />

                <FieldTypeFormSection />
                {isSaveChangesModalOpen && (
                  <FormFieldSaveChangesModal
                    formField={formField}
                    onCancel={() => setIsSaveChangesModalOpen(false)}
                    onConfirm={() => submitForm()}
                    isSubmitting={isSubmitting}
                  />
                )}
              </FormFieldUpsertContextProvider>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>

      {isTemplatesListModalOpen && (
        <FormFieldUsedInTemplatesListModal
          onCancel={() => setIsTemplatesListModalOpen(false)}
          formField={formField}
          message={generateMessage()}
        />
      )}
    </>
  )
}
