import { DeleteFormFieldResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  formFieldId?: string
}

export const deleteFormField = ({ formFieldId }: Params) =>
  API.delete<DeleteFormFieldResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/form-fields/${formFieldId}`,
    }),
  )
