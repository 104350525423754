import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { ReactComponent as VerticalArrowIcon } from 'assets/icons/asc-dec-black-arrow.svg'
import { getRiskScoreDetails } from 'pages/insights/utils'
import { averageRiskScoreMockData } from 'pages/insights/dataRisks/__mocks__/dataRisks'

const PREFIX = 'DataRisksIntelligenceStats'

const classes = {
  dataRisksIntelligenceStatsContainer: `${PREFIX}-dataRisksIntelligenceStatsContainer`,
  dataRisksIntelligenceStatsAvgContainer: `${PREFIX}-dataRisksIntelligenceStatsAvgContainer`,
  dataRisksIntelligenceStatsItemsContainer: `${PREFIX}-dataRisksIntelligenceStatsItemsContainer`,
  dataRisksIntelligenceStatsAvgBg: `${PREFIX}-dataRisksIntelligenceStatsAvgBg`,
  dataRisksIntelligenceStatsAvgLabel: `${PREFIX}-dataRisksIntelligenceStatsAvgLabel`,
  dataRisksIntelligenceStatsAvgScoreContainer: `${PREFIX}-dataRisksIntelligenceStatsAvgScoreContainer`,
  dataRisksIntelligenceStatsAvgDiff: `${PREFIX}-dataRisksIntelligenceStatsAvgDiff`,
  dataRisksIntelligenceStatsAvgScore: `${PREFIX}-dataRisksIntelligenceStatsAvgScore`,
  dataRisksIntelligenceStatsAvgRiskCategory: `${PREFIX}-dataRisksIntelligenceStatsAvgRiskCategory`,
  dataRisksIntelligenceStatsItemsCategory: `${PREFIX}-dataRisksIntelligenceStatsItemsCategory`,
  dataRisksIntelligenceStatsItemsCircle: `${PREFIX}-dataRisksIntelligenceStatsItemsCircle`,
  dataRisksIntelligenceStatsItemsValue: `${PREFIX}-dataRisksIntelligenceStatsItemsValue`,
  rotate180: `${PREFIX}-rotate180`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.dataRisksIntelligenceStatsContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flex: '0 1 380px',
  },

  [`& .${classes.dataRisksIntelligenceStatsAvgContainer}`]: {
    flex: '0 1 225px',
    alignSelf: 'center',
  },

  [`& .${classes.dataRisksIntelligenceStatsItemsContainer}`]: {
    flex: '0 1 140px',
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'space-evenly',
  },

  [`& .${classes.dataRisksIntelligenceStatsAvgBg}`]: {
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    position: 'relative',
    width: 225,
    height: 225,

    '&::before': {
      content: '""',
      width: 225,
      height: 225,
      borderRadius: spacing(4),
      backgroundColor: palette.bone.main,
      transform: 'rotate(45deg)',
      position: 'absolute',
    },
  },

  [`& .${classes.dataRisksIntelligenceStatsAvgLabel}`]: {
    position: 'relative',
    fontSize: 14,
    fontWeight: 600,
    color: palette.darkDusk.main,
    width: 70,
    display: 'block',
    textAlign: 'center',
  },

  [`& .${classes.dataRisksIntelligenceStatsAvgScoreContainer}`]: {
    width: 100,
    height: 100,
    backgroundColor: palette.white.main,
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },

  [`& .${classes.dataRisksIntelligenceStatsAvgDiff}`]: {
    position: 'relative',
    fontSize: 12,
    fontWeight: 600,
  },

  [`& .${classes.dataRisksIntelligenceStatsAvgScore}`]: {
    fontSize: 48,
    fontWeight: 500,
    lineHeight: 'normal',
    height: 42,
  },

  [`& .${classes.dataRisksIntelligenceStatsAvgRiskCategory}`]: {
    fontSize: 12,
    fontWeight: 600,
  },

  [`& .${classes.dataRisksIntelligenceStatsItemsCategory}`]: {
    fontSize: 12,
    fontWeight: 600,
  },

  [`& .${classes.dataRisksIntelligenceStatsItemsCircle}`]: {
    width: 80,
    height: 80,
    borderRadius: '50%',
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },

  [`& .${classes.dataRisksIntelligenceStatsItemsValue}`]: {
    fontSize: 26,
    fontWeight: 500,
  },

  [`& .${classes.rotate180}`]: {
    transform: 'rotate(180deg)',
  },
}))

type Props = {}

//TODO: Real Data
export const DataRisksIntelligenceStats: React.FC<Props> = () => {
  const { data = 0, security = 0, systems = 0 } = averageRiskScoreMockData // TODO: replace with real data
  const riskAverage = Math.round((data + security + systems) / 3)
  const riskAverageScoreDetails = getRiskScoreDetails(riskAverage)
  const dataRiskScoreDetails = getRiskScoreDetails(data)
  const securityRiskScoreDetails = getRiskScoreDetails(security)
  const systemsRiskScoreDetails = getRiskScoreDetails(systems)

  return (
    <StyledBox className={classes.dataRisksIntelligenceStatsContainer}>
      <Box className={classes.dataRisksIntelligenceStatsAvgContainer}>
        <Box className={classes.dataRisksIntelligenceStatsAvgBg}>
          <Text className={classes.dataRisksIntelligenceStatsAvgLabel}>Average Risk Score</Text>
          <Box className={classes.dataRisksIntelligenceStatsAvgScoreContainer}>
            <Text className={classes.dataRisksIntelligenceStatsAvgScore} color={riskAverageScoreDetails.textColorName}>
              {riskAverage}
            </Text>
            <Text
              className={classes.dataRisksIntelligenceStatsAvgRiskCategory}
              color={riskAverageScoreDetails.textColorName}
            >
              {riskAverageScoreDetails.category}
            </Text>
          </Box>
          <Text className={classes.dataRisksIntelligenceStatsAvgDiff}>
            +8 <VerticalArrowIcon className={classes.rotate180} />
          </Text>
        </Box>
      </Box>
      <Box className={classes.dataRisksIntelligenceStatsItemsContainer}>
        <Box
          className={classes.dataRisksIntelligenceStatsItemsCircle}
          sx={{ backgroundColor: `${dataRiskScoreDetails.bgColor}` }}
        >
          <Text className={classes.dataRisksIntelligenceStatsItemsCategory}>Data</Text>
          <Text className={classes.dataRisksIntelligenceStatsItemsValue} color={dataRiskScoreDetails.textColorName}>
            {data}
          </Text>
        </Box>
        <Box
          className={classes.dataRisksIntelligenceStatsItemsCircle}
          sx={{ backgroundColor: `${systemsRiskScoreDetails.bgColor}`, position: 'relative', left: 50 }}
        >
          <Text className={classes.dataRisksIntelligenceStatsItemsCategory}>Systems</Text>
          <Text className={classes.dataRisksIntelligenceStatsItemsValue} color={systemsRiskScoreDetails.textColorName}>
            {systems}
          </Text>
        </Box>
        <Box
          className={classes.dataRisksIntelligenceStatsItemsCircle}
          sx={{ backgroundColor: `${securityRiskScoreDetails.bgColor}` }}
        >
          <Text className={classes.dataRisksIntelligenceStatsItemsCategory}>Security</Text>
          <Text className={classes.dataRisksIntelligenceStatsItemsValue} color={securityRiskScoreDetails.textColorName}>
            {security}
          </Text>
        </Box>
      </Box>
    </StyledBox>
  )
}
