import { useEffect } from 'react'
import { useExperienceBuilderFormStateUtils } from './useExperienceBuilderFormStateUtils'

export const useResetExperienceBuilderReduxFormStateOnMount = () => {
  const { resetPersistedReduxValues } = useExperienceBuilderFormStateUtils()

  useEffect(() => {
    resetPersistedReduxValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
