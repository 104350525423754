import React from 'react'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import { Box, Typography } from '@mui/material'
import { AddDataSubjectVariableRow } from './components'
import { StepDetailsTypeEnum } from 'interfaces/workflows/StepDetailsTypeEnum'
import { useStepDetailsTileWithEditModeUtils } from './hooks'
import { AppealBadge } from '../AppealBadge'
import { Button, Icon, Widget, theme } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { FormError } from 'components/form/FormError'

type Props = {
  title: string
  subTitle?: string
}

export const StepDetailsTileWithEditMode: React.FC<Props> = ({ title, subTitle }) => {
  const {
    handleExitEditMode,
    handleSubmit,
    initialValues,
    isAssignedToMe,
    isEditMode,
    isRightsQueueAdmin,
    isTaskComplete,
    isWorkflowExecutionTerminated,
    selectedDataSubjectVariableForAddition,
    setIsEditMode,
    setSelectedDataSubjectVariableForAddition,
    setUnAddedVariableWarning,
    stepDetails,
    styles,
    unAddedVariableWarning,
    validationSchema,
    isInternalSystemDSRStep,
  } = useStepDetailsTileWithEditModeUtils()

  const shouldShowFallback =
    !stepDetails?.dataSubjectContext?.filter(variable => !!variable?.value)?.length && !isEditMode
  const canEditDueToAssignmentOrAdmin = !!isAssignedToMe || !!isRightsQueueAdmin
  const isEditDisabled =
    !canEditDueToAssignmentOrAdmin || isTaskComplete || isWorkflowExecutionTerminated || isInternalSystemDSRStep

  if (shouldShowFallback) {
    return (
      <Widget
        title={title}
        subTitle={subTitle}
        expandable
        emptyStateProps={{
          title: 'No Context Received',
          subTitle: 'This DSR request has no Context Variables received yet.',
          customIcon: <Icon name="OCode" iconColor={theme.palette.darkDuskFaded.main} height="44px" width="44px" />,
          size: 'medium',
        }}
        actionButton={
          <>
            {!isEditDisabled && !isEditMode && (
              <Button
                variant="icon"
                color="tertiary"
                onClick={e => {
                  e.stopPropagation()
                  setIsEditMode(true)
                }}
              >
                <Icon name="FEdit" />
              </Button>
            )}
          </>
        }
      />
    )
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues || {}}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, handleSubmit, resetForm }) => {
        return (
          <Widget
            expandable
            title={title}
            subTitle={subTitle}
            footerComponent={
              isEditMode && (
                <Box display="flex" gap={1} alignItems="center">
                  <Button
                    color="secondary"
                    variant="icon"
                    onClick={e => {
                      e.stopPropagation()
                      handleExitEditMode(resetForm)
                    }}
                  >
                    <Icon name="OCross" />
                  </Button>
                  <Button
                    color="primary"
                    pending={isSubmitting}
                    onClick={e => {
                      e.stopPropagation()
                      if (selectedDataSubjectVariableForAddition) {
                        setUnAddedVariableWarning(true)
                        return
                      }
                      handleSubmit()
                    }}
                  >
                    Save
                  </Button>
                </Box>
              )
            }
            actionButton={
              !isEditDisabled &&
              !isEditMode && (
                <Button
                  variant="icon"
                  color="tertiary"
                  onClick={e => {
                    e.stopPropagation()
                    setIsEditMode(true)
                  }}
                >
                  <Icon name="FEdit" />
                </Button>
              )
            }
            content={
              <Box>
                {/* Content, Edit Mode */}
                {isEditMode ? (
                  <Box>
                    <FieldArray
                      name="dataSubjectVariables"
                      render={({ push, remove }) => (
                        <>
                          {values?.dataSubjectVariables?.map((variable, index) => {
                            return (
                              <Box key={variable.code} sx={styles.editItemContainer}>
                                {/* Row 1: Name | Delete Icon Button */}

                                <Box display="flex" flex={1} justifyContent="space-between" mb={1} alignItems="center">
                                  {/* Left */}

                                  <Typography
                                    variant="label"
                                    sx={{
                                      wordBreak: 'break-word',
                                      overflowWrap: 'break-word',
                                    }}
                                  >
                                    {variable.name}
                                  </Typography>

                                  {/* Right */}

                                  {!stepDetails?.dataSubjectContext?.map(dsv => dsv?.code)?.includes(variable?.code) ? (
                                    <Button
                                      variant="iconSubtle"
                                      onClick={e => {
                                        e.stopPropagation()
                                        remove(index)
                                      }}
                                    >
                                      <Icon name="FBin" />
                                    </Button>
                                  ) : null}
                                </Box>

                                {/* Row 2: Input */}

                                <Box display="block">
                                  {variable?.type === StepDetailsTypeEnum.STRING && (
                                    <FormInput
                                      hideOptionalLabel
                                      fullWidth
                                      size="small"
                                      id={variable.code}
                                      required={variable.isRequired}
                                      formPropertyName={`dataSubjectVariables[${index}].value`}
                                      placeholder="Enter value..."
                                    />
                                  )}

                                  {variable?.type === StepDetailsTypeEnum.INTEGER && (
                                    <FormInput
                                      hideOptionalLabel
                                      fullWidth
                                      size="small"
                                      id={variable.code}
                                      required={variable.isRequired}
                                      formPropertyName={`dataSubjectVariables[${index}].value`}
                                      placeholder="Enter value..."
                                      type="number"
                                    />
                                  )}

                                  {variable?.type === StepDetailsTypeEnum.BOOLEAN && (
                                    <FormRadioGroup
                                      sx={{
                                        '& .MuiFormControlLabel-root': {
                                          marginLeft: 0,
                                        },
                                      }}
                                      label={<></>}
                                      required={variable.isRequired}
                                      formPropertyName={`dataSubjectVariables[${index}].value`}
                                      row
                                      valueKey="id"
                                      renderLabel={({ name }) => name}
                                      options={[
                                        { id: 'true', name: 'True' },
                                        { id: 'false', name: 'False' },
                                      ]}
                                    />
                                  )}

                                  {variable?.type === StepDetailsTypeEnum.LIST && (
                                    <Box display="flex" flexDirection="column">
                                      <FormDroplistButton
                                        formPropertyName={`dataSubjectVariables[${index}].value`}
                                        hideOptionalLabel
                                        size="small"
                                        labelValueKey="value"
                                        disableClearable
                                        items={
                                          variable?.options?.map(item => ({
                                            active: item === values?.dataSubjectVariables?.[index]?.value,
                                            value: item,
                                            content: item,
                                          })) || []
                                        }
                                        valueKey="value"
                                        placeholder="Select an option..."
                                      />

                                      <ErrorMessage name={`dataSubjectVariables[${index}].value`}>
                                        {msg => <FormError msg={msg} />}
                                      </ErrorMessage>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            )
                          })}

                          <AddDataSubjectVariableRow
                            push={push}
                            selectedDataSubjectVariableForAddition={selectedDataSubjectVariableForAddition}
                            setSelectedDataSubjectVariableForAddition={setSelectedDataSubjectVariableForAddition}
                            setUnAddedVariableWarning={setUnAddedVariableWarning}
                          />
                          {unAddedVariableWarning && (
                            <Box display="flex" alignItems="center">
                              <FormError
                                msg={`Oops! Looks like you meant to add this variable. Either click "add" or clear the
                                selection.`}
                              />
                            </Box>
                          )}
                        </>
                      )}
                    />
                  </Box>
                ) : (
                  // Content, View Mode
                  <Box display="flex" flexDirection="column" gap={2}>
                    {stepDetails?.dataSubjectContext?.map?.((variable, index) => {
                      // skip falsey values except 'false' and '0' which are valid values for variables of bool or int type
                      if (!variable.value && variable.value !== false && variable.value !== 0) return null

                      return (
                        <Box key={index} display="flex" flexDirection="column">
                          <Box display="flex" alignItems="center" flexWrap="wrap" gap={0.75}>
                            <Typography
                              variant="smallLabel"
                              color="darkDuskFaded.main"
                              sx={{ overflowWrap: 'anywhere', textTransform: 'capitalize', width: '100%' }}
                            >
                              {variable.name}
                            </Typography>

                            {variable.isAppealValue && <AppealBadge />}
                          </Box>

                          {!!variable?.value?.toString() ? (
                            <Typography variant="body" sx={{ overflowWrap: 'break-word', width: '100%' }}>
                              {variable?.value?.toString()}
                            </Typography>
                          ) : (
                            <EmptyValueRenderer />
                          )}
                        </Box>
                      )
                    })}
                  </Box>
                )}
              </Box>
            }
          />
        )
      }}
    </Formik>
  )
}
