import React, { useState, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  TransponderDocumentationSlugs,
  useVisitTransponderDocumentation,
} from '../../hooks/useTransponderDocumentation'
import { createCustomContext } from 'utils/hooks/createCustomContext'

export enum DeploymentMethods {
  helmChart = 'Helm Chart Method',
  awsElasticBeanstalk = 'AWS Elastic Beanstalk',
}

type TransponderCreationProgress = 'inProgress' | 'success' | 'error'

export interface TransponderContextProps {
  deploymentMethod: DeploymentMethods
  status: TransponderCreationProgress
  code?: string
  token?: string
  applianceId?: string
  setDeploymentMethod: (method: DeploymentMethods) => void
  setApplianceId: (applianceId: string) => void
  setStatus: (status: TransponderCreationProgress) => void
  setCode: (code: string) => void
  handleDeploymentMethodChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleVisitDocs: (docsSlug?: TransponderDocumentationSlugs) => void
  handleExitFlow: () => void
  setToken: (token: string) => void
}

const { Provider, useCustomContext: useTransponderCreate } = createCustomContext<TransponderContextProps>({
  displayName: 'TransponderContext',
})

export const TransponderCreationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [deploymentMethod, setDeploymentMethod] = useState(DeploymentMethods.helmChart)
  const [status, setStatus] = useState<TransponderCreationProgress>('inProgress')
  const [code, setCode] = useState<string | undefined>()
  const [token, setToken] = useState<string | undefined>()
  const [applianceId, setApplianceId] = useState<string | undefined>()

  const navigate = useNavigate()
  const handleVisitTransponderDocumentation = useVisitTransponderDocumentation(deploymentMethod)

  const handleDeploymentMethodChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDeploymentMethod(event.target.value as DeploymentMethods)
  }, [])

  const handleExitFlow = useCallback(() => {
    navigate('/developers/transponders')
  }, [navigate])

  const value = useMemo(
    () => ({
      applianceId,
      code,
      deploymentMethod,
      status,
      token,
      setApplianceId,
      setCode,
      setDeploymentMethod,
      setStatus,
      handleDeploymentMethodChange,
      handleVisitDocs: handleVisitTransponderDocumentation,
      handleExitFlow,
      setToken,
    }),
    [
      applianceId,
      deploymentMethod,
      status,
      code,
      token,
      setDeploymentMethod,
      setStatus,
      setCode,
      setToken,
      setApplianceId,
      handleDeploymentMethodChange,
      handleVisitTransponderDocumentation,
      handleExitFlow,
    ],
  )

  return <Provider value={value}>{children}</Provider>
}

export { useTransponderCreate }
