import React, { ReactNode, useState } from 'react'

import {
  ActionsCellRenderer,
  AttributeEffectsCellRenderer,
  PoliciesTableExpandableRow,
  PurposeCellRenderer,
  ResourcesCellRenderer,
  ResourcesDetailsCellRenderer,
  RowFiltersCellRenderer,
} from './components'
import { MaybeNull } from 'interfaces'
import { PolicyNameRenderer } from './components/PolicyNameRenderer'
import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateCommon.svg'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { Pagination } from 'api/common/paginatedQuery'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { INSUFFICIENT_PERMISSIONS_TOOLTIP } from 'utils/constants/INSUFFICIENT_PERMISSIONS_TOOLTIP'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

type Props = {
  isExpandable?: boolean
  policies: MaybeNull<AssetPolicyDTO[]>
  shouldShowSearchInput?: boolean
  pending?: boolean
  pagination: Pagination
  emptyState?: ReactNode
}

export const PoliciesTablePaginated: React.FC<Props> = ({
  policies = [],
  isExpandable = true,
  shouldShowSearchInput = true,
  pending = false,
  pagination,
  emptyState,
}) => {
  const [searchString, setSearchString] = useState('')
  const navigate = useNavigate()
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWritePolicy = getIsPermitted([PERMISSIONS.ASSET_POLICY_WRITE])

  if (!emptyState && (!policies || policies?.length < 1)) {
    return (
      <EmptyState
        title="There are no policies for this organization"
        description="Create your first one to populate the table"
        actions={[
          {
            title: 'Create Policy',
            size: 'big',
            onClick: () => navigate(RoutesManager.policyCenter.policies.upsert.getURL({ id: 'new' })),
            disabled: !isPermittedToWritePolicy,
            tooltip: !isPermittedToWritePolicy ? INSUFFICIENT_PERMISSIONS_TOOLTIP : undefined,
          },
        ]}
        variant="list"
        icon={<EmptyStateIcon />}
      />
    )
  }

  if (emptyState && (!policies || policies?.length < 1)) {
    return <>{emptyState}</>
  }

  return (
    <>
      {shouldShowSearchInput && (
        <FormRow>
          <SearchInput
            onChange={nextSearchString => setSearchString(nextSearchString)}
            onClear={() => setSearchString('')}
            value={searchString}
          />
        </FormRow>
      )}

      <TablePaginated
        pending={!!pending}
        pagination={pagination}
        isRowExpandable={() => isExpandable}
        renderExpandableRow={policy => <PoliciesTableExpandableRow policy={policy} />}
        items={policies || []}
        isSmallHeaderText
        onRowClick={({ id }) => {
          navigate(RoutesManager.policyCenter.policies.detail.overview.getURL({ id }))
        }}
        shouldRemoveTableRowExpandContentPadding
        cellSettings={{
          name: {
            width: 180,
            label: 'Policy Name',
            cellRenderer: ({ name, assets }) => <PolicyNameRenderer policyName={name || ''} resources={assets || []} />,
          },
          purpose: {
            width: 180,
            label: 'Purpose',
            cellRenderer: ({ purpose, assets }) => (
              <PurposeCellRenderer purpose={purpose || ''} resources={assets || []} />
            ),
          },
          resources: {
            width: 120,
            label: 'Resources',
            cellRenderer: ({ assets }) => <ResourcesCellRenderer resources={assets || []} />,
          },
          resourcesDetails: {
            width: 330,
            label: 'Resources Details',
            cellRenderer: ({ assets }) => <ResourcesDetailsCellRenderer resources={assets || []} />,
          },
          attributeEffects: {
            width: 120,
            label: 'Attribute Effects',
            cellRenderer: ({ effects, assets }) => (
              <AttributeEffectsCellRenderer
                hasAttributeEffects={(effects || [])?.length > 0 || false}
                resources={(assets || []).map(() => ({ name: 'foo', icon: 'tag' }))}
              />
            ),
          },
          rowFilters: {
            width: 120,
            label: 'Row Filters',
            cellRenderer: ({ rowFilters, assets }) => (
              <RowFiltersCellRenderer
                hasRowFilters={(rowFilters || [])?.length > 0 || false}
                resources={(assets || []).map(() => ({ name: 'foo', icon: 'tag' }))}
              />
            ),
          },
          actions: {
            label: '',
            cellRenderer: ({ id }) => (
              <ActionsCellRenderer
                policyId={id || ''}
                resources={[
                  { name: 'foo', icon: 'tag' },
                  { name: 'bar', icon: 'abc' },
                ]}
              />
            ),
          },
        }}
      />
    </>
  )
}
