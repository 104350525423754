import { API } from 'utils/api/api'
import { APIRequestParams } from 'api/common/utils'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { CreateDataSystemV2RequestBodyDTO, CreateInstalledDataSystemV2ResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  formData: CreateDataSystemV2RequestBodyDTO
}>

export const createDataSystem = ({ formData }: Params) =>
  API.post<CreateInstalledDataSystemV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/data-systems`,
    }),
    formData,
  )
