import { PurposeDTO } from '@ketch-com/figurehead'

import { useMemo } from 'react'
import * as Yup from 'yup'

import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { useCheckIfBusinessFunctionCodeAvailable } from 'api/businessFunctions/queries/useCheckIfBusinessFunctionCodeIsAvailable'

export interface BusinessFunctionFormValues {
  name: string
  label: string
  labelColor: string
  code: string
  description: string
  contactEmail: string
  contactFirstName: string
  contactLastName: string
  contactTitle: string
  purposes: PurposeDTO[]
}

export enum FormStep {
  DETAILS = 'Details',
  PURPOSES = 'Purposes',
}

export const initialState = {
  name: '',
  label: '',
  labelColor: '#E7AFAF',
  code: '',
  description: '',
  contactEmail: '',
  contactFirstName: '',
  contactLastName: '',
  contactTitle: '',
  purposes: [],
}

export const useValidationSchema = ({ isEditMode }: { isEditMode?: boolean } = {}) => {
  const { mutateAsync: handleCheckIfBusinessFunctionAvailable } = useCheckIfBusinessFunctionCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIfBusinessFunctionAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(
    () =>
      Yup.object().shape({
        ...(!isEditMode && {
          code: codeValidationSchema,
        }),
        name: Yup.string().max(75, 'Name must not exceed 75 characters').required('Required'),
        label: Yup.string().max(3, 'Label text must not exceed 3 characters').required('Required'),
      }),
    [isEditMode, codeValidationSchema],
  )
}
