import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchPermitStatsPurpose } from '../fetchers/fetchPermitStatsPurpose'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.permitsStatsPurpose,
  queryFn: fetchPermitStatsPurpose,
  select: res => res?.data?.purposes || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const usePermitsStatsPurpose = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    itemsPerPage: config?.itemsPerPage || 5,
    params: {
      ...config?.params,
    },
  })
}
