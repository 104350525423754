import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { IActivityParams } from 'pages/orchestration/workflows/edit/interfaces'

export const getActivityParamsByType = (code: WorkflowActivityCode): IActivityParams => {
  switch (code) {
    case WorkflowActivityCode.API_CALL:
      return {
        httpMethod: '',
        url: '',
        body: '',
      }

    case WorkflowActivityCode.APPROVAL:
      return {
        approver: '',
        activity: '',
        details: '',
      }

    case WorkflowActivityCode.TASK:
      return {
        assignee: '',
        details: '',
        hasResolutionNotes: false,
        hasResolutionAttachments: false,
        hasDsrResolutionAttachments: false,
        dsrResolutionDescription: '',
      }

    case WorkflowActivityCode.EMAIL:
      return {
        to: [],
        cc: [],
        subject: '',
        body: '',
      }

    case WorkflowActivityCode.DATA_SUBJECT_EMAIL:
      return {
        cc: [],
        subject: '',
        body: '',
      }

    case WorkflowActivityCode.FULFILL_RIGHT:
      return {}

    default:
      return {}
  }
}
