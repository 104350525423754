import React from 'react'
import { useFormikContext } from 'formik'
import { Box } from '@mui/material'
import { SubscriptionTopicFormData } from 'pages/policyCenter/subscriptions/interfaces'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { ContentGroup, DataGrid } from '@ketch-com/deck'
import { SubscriptionsTranslationsStepDataGridType, subscriptionsTranslationsColumns } from './translationsUtils'

type Props = {
  organizationLanguages: LanguageWithStatusDTO[]
}

export const Translations: React.FC<Props> = ({ organizationLanguages }) => {
  const { values } = useFormikContext<SubscriptionTopicFormData>()

  const translationsRows: SubscriptionsTranslationsStepDataGridType[] = Object.entries(
    values.basicDetails.translations,
  ).map(entry => {
    return {
      languageCode: entry[0],
      translation: entry[1],
    }
  })

  return (
    <Box mt={6}>
      <Box mb={3}>
        <ContentGroup
          variant="inner-page-header"
          titleVariant="h3"
          title="Translations"
          subTitle="Translations define how displayed Name and Description will be shown in Experiences, and can be added later."
        />
      </Box>
      <DataGrid
        autosizeOnMount
        columns={subscriptionsTranslationsColumns(organizationLanguages)}
        disableChildrenSorting
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        disableRowSelectionOnClick
        disableRowHoverStates
        getRowId={row => row.languageCode}
        getRowHeight={() => 'auto'}
        hideFooter
        rows={translationsRows}
      />
    </Box>
  )
}
