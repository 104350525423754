import React, { useMemo } from 'react'
import Box from '@mui/material/Box'

import { WorkflowExecutionStepDTO, WorkflowExecutionStepName } from 'interfaces/workflowExecutions/workflowExecution'
import {
  WorkflowActivity,
  WorkflowActivityCategory,
  WorkflowActivityConnectorCode,
} from 'interfaces/workflowActivities/workflowActivity'

import { Typography } from '@mui/material'
import { WorkflowDTO } from 'interfaces/workflows'
import { Icon, IconDictionary, Status, StatusState, StatusVariant } from '@ketch-com/deck'
import pluralize from 'pluralize'

type Props = {
  workflowExecutionStep: WorkflowExecutionStepDTO
  workflowActivities: WorkflowActivity[]
  workflowDefinition: WorkflowDTO | null
}

export const WorkflowExecutionStepActivityRenderer: React.FC<Props> = ({
  workflowExecutionStep,
  workflowActivities,
  workflowDefinition,
}) => {
  const { activityStepCode, stepName } = workflowExecutionStep

  const workflowActivity = useMemo(
    () => workflowActivities.find(({ code }) => code === activityStepCode),
    [activityStepCode, workflowActivities],
  )

  const workflowStepDefinition = workflowDefinition?.steps.find(({ ID }) => ID === workflowExecutionStep.stepID)
  const timeToCompleteDays =
    workflowStepDefinition?.workflow?.params?.timeToCompleteDays ||
    workflowStepDefinition?.activity?.params?.timeToCompleteDays ||
    workflowStepDefinition?.gateway?.params?.timeToCompleteDays

  const category = workflowActivity?.category

  const isStandard = category === WorkflowActivityCategory.STANDARD || category === WorkflowActivityCategory.DSR
  const isGateway = category === WorkflowActivityCategory.GATEWAY
  const isAppLike = [
    WorkflowActivityCategory.APP,
    WorkflowActivityCategory.APP_LEVEL_2,
    WorkflowActivityCategory.CONNECTOR,
  ].includes(category!)

  const iconURL = workflowActivity?.icon?.URL

  if (stepName === WorkflowExecutionStepName.START) {
    return (
      <Box display="flex" alignItems="center" my={0.75} gap={1.5}>
        <Icon name="WStart" width={36} height={36} inheritViewBox />
        <Typography>{stepName}</Typography>
      </Box>
    )
  }

  if (stepName === WorkflowExecutionStepName.END) {
    return (
      <Box display="flex" alignItems="center" my={0.75}>
        {activityStepCode === WorkflowActivityConnectorCode.END ? (
          <img src={iconURL} alt="" height="36px" width="36px" />
        ) : (
          <Icon name="WEnd" width={36} height={36} inheritViewBox />
        )}
        <Box my={-2} ml={1.5}>
          <Typography variant="body" component="div" pt={1}>
            {stepName}
          </Typography>
          {timeToCompleteDays ? (
            <Status
              variant={StatusVariant.ghost}
              status={StatusState.draft}
              icon={IconDictionary.OClock}
              label={`${timeToCompleteDays} ${pluralize('day', timeToCompleteDays)}`}
            />
          ) : null}
        </Box>
      </Box>
    )
  }

  return (
    <Box display="flex" alignItems="center" my={0.75}>
      <Box
        sx={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          mr: '12px',
          padding: '5px',
          borderRadius: '10px',
          height: '36px',
          width: '36px',
          backgroundColor: isStandard
            ? 'heliotrope.main'
            : isGateway
            ? 'darkDusk.main'
            : isAppLike
            ? 'bone.main'
            : 'darkDusk.main',
        }}
      >
        <img
          src={iconURL}
          alt=""
          style={{
            width: '100%',
          }}
        />
      </Box>

      <Box my={-2}>
        <Typography variant="body" component="div" pt={timeToCompleteDays ? 1 : undefined}>
          {stepName}
        </Typography>
        {timeToCompleteDays ? (
          <Status
            variant={StatusVariant.ghost}
            status={StatusState.draft}
            icon={IconDictionary.OClock}
            label={`${timeToCompleteDays} ${pluralize('day', timeToCompleteDays)}`}
          />
        ) : null}
      </Box>
    </Box>
  )
}
