export const BUILDER_CONSTANTS = {
  MIN_BORER_RADIUS: 0,
  MAX_BORDER_RADIUS: 36,
  PREF_NAV_MIN_BORER_RADIUS: 0,
  PREF_NAV_MAX_BORDER_RADIUS: 24,
}

export enum BuilderTarget {
  Experience = 'experience',
  Theme = 'theme',
}

export enum BuilderType {
  Banner = 'banner',
  Modal = 'modal',
  Preference = 'preference',
  Progressive = 'progressive',
}

export enum BuilderMode {
  View = 'view',
  Edit = 'edit',
}

export type ExperienceBuilderSection =
  | BannerExperienceBuilderSection
  | ModalExperienceBuilderSection
  | PreferenceExperienceBuilderSection
  | ProgressiveExperienceBuilderSection
export type ThemeBuilderSection = BannerThemeBuilderSection | ModalThemeBuilderSection | PreferenceThemeBuilderSection

export enum BannerExperienceBuilderSection {
  Header = 'bannerHeader',
  Footer = 'bannerFooter',
  Description = 'bannerDescription',
  ActionButtons = 'bannerActionButtons',
}

export enum ModalExperienceBuilderSection {
  Header = 'modalHeader',
  Description = 'modalDescription',
  DescriptionTitle = 'modalDescriptionTitle',
  ListHeader = 'modalListHeader',
  PurposeList = 'modalPurposeList',
  Footer = 'modalFooter',
}

export enum PreferenceExperienceBuilderSection {
  WelcomeTabHeader = 'welcomeTabHeader',
  WelcomeTabNavigation = 'welcomeTabNavigation',
  WelcomeTabWelcomeMessage = 'welcomeTabWelcomeMessage',
  WelcomeTabQuickLinks = 'welcomeTabQuickLinks',
  WelcomeTabQuickLinksLinkTwo = 'welcomeTabQuickLinksLinkTwo',
  WelcomeTabQuickLinksLinkThree = 'welcomeTabQuickLinksLinkThree',
  WelcomeTabQuickLinksLink2Visible = 'welcomeTabQuickLinksLink2Visible',
  WelcomeTabQuickLinksLink3Visible = 'welcomeTabQuickLinksLink3Visible',
  WelcomeTabAbout = 'welcomeAbout',
  WelcomeTabFooter = 'welcomeTabFooter',
  WelcomeTabExitButton = 'welcomeTabExitButton',
  WelcomeTabGpcBadge = 'welcomeTabGpcBadge',
  WelcomeTabAboutDescription = 'welcomeTabAboutDescription',
  PurposesTabHeaderTitle = 'purposesTabHeaderTitle',
  PurposesTabHeaderDescription = 'purposesTabHeaderDescription',
  PurposesTabListHeader = 'purposesTabListHeader',
  PurposesTabList = 'purposesTabList',
  PurposesTabActionButton = 'purposesTabActionButton',
  SubscriptionsTabHeaderTitle = 'subscriptionsTabHeaderTitle',
  SubscriptionsTabHeaderDescription = 'subscriptionsTabHeaderDescription',
  SubscriptionsTabUnsubscribeAll = 'subscriptionsTabUnsubscribeAll',
  SubscriptionsTabList = 'subscriptionsTabList',
  SubscriptionsTabActionButton = 'subscriptionsTabActionButton',
  RequestsHomeHeaderTitle = 'requestsHomeHeaderTitle',
  RequestsHomeHeaderDescription = 'requestsHomeHeaderDescription',
  RequestsHomeDsrLink = 'requestsHomDsrLink',
  RequestsHomeRightsListTitle = 'requestsHomeRightsList',
  RequestsHomeRightsListRequestTypes = 'requestsHomeRightsListRequestTypes',
  RequestsFormContent = 'requestsFormContent',
  RequestsFormActionButton = 'requestsFormActionButton',
  RequestsSubmittedHeader = 'requestsSubmittedHeader',
  RequestsSubmittedSubtitle = 'requestsSubmittedSubtitle',
  RequestsSubmittedDescription = 'requestsSubmittedDescription',
  RequestsSubmittedActionButton = 'requestsSubmittedActionButton',
}

export enum BannerThemeBuilderSection {
  Container = 'bannerContainer',
  Header = 'bannerHeader',
  Description = 'bannerDescription',
  ActionButtons = 'bannerActionButtons',
  ActionButtonsSecondary = 'bannerActionButtonsSecondary',
  ActionButtonsTertiary = 'bannerActionButtonsTertiary',
  Footer = 'bannerFooter',
}

export enum ModalThemeBuilderSection {
  Container = 'modalContainer',
  Header = 'modalHeader',
  Description = 'modalDescription',
  ListHeader = 'modalListHeader',
  ListHeaderAcceptAll = 'modalListHeaderAcceptAll',
  ListHeaderRejectAll = 'modalListHeaderRejectAll',
  PurposeList = 'modalPurposeList',
  Footer = 'modalFooter',
}

export enum PreferenceThemeBuilderSection {
  WelcomeTabContainer = 'welcomeTabContainer',
  WelcomeTabHeader = 'welcomeTabHeader',
  WelcomeTabNavigation = 'welcomeTabNavigation',
  WelcomeTabExitButton = 'welcomeTabExitButton',
  WelcomeTabHeroBanner = 'welcomeTabHeroBanner',
  WelcomeTabWelcomeMessage = 'welcomeTabWelcomeMessage',
  WelcomeTabQuickLinks = 'welcomeTabQuickLinks',
  WelcomeTabAbout = 'welcomeAbout',
  WelcomeTabKetchBadge = 'welcomeKetchBadge',
  PurposesTabHeader = 'purposesTabHeader',
  PurposesTabListHeader = 'purposesTabListHeader',
  PurposesTabListHeaderAcceptAll = 'purposesTabListHeaderAcceptAll',
  PurposesTabListHeaderRejectAll = 'purposesTabListHeaderRejectAll',
  PurposesTabList = 'purposesTabList',
  PurposesTabFooter = 'purposesTabFooter',
  SubscriptionsTabHeader = 'subscriptionsTabHeader',
  SubscriptionsTabUnsubscribeAll = 'subscriptionsTabUnsubscribeAll',
  SubscriptionsTabList = 'subscriptionsTabList',
  SubscriptionsTabFooter = 'subscriptionsTabFooter',
  RequestsHomeHeader = 'requestsHeader',
  RequestsHomeDsrLink = 'requestsDsrLink',
  RequestsHomeRightsList = 'requestsRightsList',
  RequestsFormHeader = 'requestsFormHeader',
  RequestsFormContent = 'requestsFormContent',
  RequestsFormContentFields = 'requestsFormContentFields',
  RequestsFormActionButton = 'requestsFormActionButton',
  RequestsSubmittedHeader = 'requestsSubmittedHeader',
  RequestsSubmittedBanner = 'requestsSubmittedBanner',
  RequestsSubmittedText = 'requestsSubmittedText',
  RequestsSubmittedFooter = 'requestsSubmittedFooter',
}

export enum ProgressiveExperienceBuilderSection {
  Container = 'progressiveConsentContainer',
  Header = 'progressiveConsentHeader',
  Description = 'progressiveConsentDescription',
  Subscriptions = 'progressiveConsentSubscriptions',
  ActionButtons = 'progressiveConsentActionButtons',
  SwitchButton = 'progressiveConsentSwitchButton',
}

export const LanyardColors = {
  Black100: '#071A24',
  Black48: '#071A247A',
  Black10: '#071A241A',
  White100: '#FFFFFF',
  White24: '#FFFFFF3D',
  White64: '#FFFFFFA3',
  GreyBackground: '#D7DADC',
}

export enum BuilderImageVariant {
  Header = 'header',
  Navigation = 'nav',
  HeroBanner = 'heroBanner',
  SummaryBanner = 'summaryBanner',
}
