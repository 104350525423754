import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchIdentitySpaces } from 'api/identitySpaces/fetchers/fetchIdentitySpaces'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.identitySpaces,
  queryFn: fetchIdentitySpaces,
  select: res => res?.data?.identitySpaces || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery, 'organizationCode'>

export const useIdentitySpaces = (config?: Config) => {
  const { userData } = useAuth()

  return useCustomQuery({
    ...config,
    params: {
      start: 0,
      limit: 1000,
      ...config?.params,
      organizationCode: userData.organizationCode!,
    },
  })
}
