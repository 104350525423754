import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormFieldDTO } from '@ketch-com/figurehead'
import { FormFieldModalUsedInSection } from '.'
import { useFormFieldsRelationshipData } from '../hooks'
import pluralize from 'pluralize'
import { Button, PopUp } from '@ketch-com/deck'

type Props = {
  onCancel: () => void
  onConfirm: () => void
  formField?: FormFieldDTO
  isSubmitting?: boolean
}

export const FormFieldSaveChangesModal: React.FC<Props> = ({ onCancel, formField, onConfirm, isSubmitting }) => {
  const { formFieldAuthenticators, formFieldExperiencesActive, formFieldTemplatesActive, formFieldActivities } =
    useFormFieldsRelationshipData(formField)

  const generateMessage = (): string => {
    const categories = [
      { label: `Form`, count: formFieldTemplatesActive?.length },
      { label: `Experience`, count: formFieldExperiencesActive?.length },
      { label: `Authenticator`, count: formFieldAuthenticators?.length },
      { label: `Workflow`, count: formFieldActivities?.length },
    ]

    const filteredCategories = categories
      .filter(({ count }) => count !== 0)
      .map(({ label, count }) => `${count} ${pluralize(label, count ?? 0)}`)

    if (filteredCategories.length > 1) {
      filteredCategories.splice(-1, 0, 'and')
    }

    const message = filteredCategories.join(', ')

    return `This field is used in currently deployed items: ${message}. All changes will take effect immediately. Are you sure you want
    to continue?`.replace('and,', 'and')
  }

  return (
    <PopUp
      title="Save Changes"
      variant="modal"
      onClose={onCancel}
      popUpWidth="684px"
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isSubmitting} onClick={onCancel}>
            Cancel
          </Button>
          <Button color="primary" size="large" pending={isSubmitting} onClick={onConfirm}>
            Save
          </Button>
        </>
      }
    >
      <Typography variant="body" component={Box}>
        {generateMessage()}
      </Typography>

      <FormFieldModalUsedInSection formField={formField} />
    </PopUp>
  )
}
