import React from 'react'
import { useParams } from 'react-router-dom'

import { useJurisdictionPurposes } from 'api/purposes/queries/useJurisdictionPurposes'
import { PolicyScopePurposes } from 'pages/policyCenter/policyScopes/view/tabs/PolicyScopePurposes'
import { useLegalBases } from 'api/legalBases/queries/useLegalBases'

export const PolicyScopePurposesContainer: React.FC = () => {
  const { code } = useParams<{ code: string }>()

  const { data, isLoading } = useJurisdictionPurposes({
    params: {
      code,
    },
  })

  const { data: legalBases, isLoading: isLegalBasesLoading } = useLegalBases()

  const isReady = !isLoading && !isLegalBasesLoading

  return (
    <PolicyScopePurposes
      isReady={isReady}
      stack={data?.stack || {}}
      purposesByScope={data?.purposes || []}
      policyScopeCode={code!}
      legalBases={legalBases}
    />
  )
}
