import { PreferenceCenterPage } from '@ketch-sdk/ketch-types'
import { Box, Typography, styled } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import { BuilderContext } from '../../../Builder'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { BUILDER_CONSTANTS, BuilderImageVariant } from '../../../utils/enums'
import { ImageContainer } from './ImageContainer'
import { clamp } from 'lodash'

const NavigationBox = styled(Box)(({ theme }) => ({
  width: '280px',
}))

const TabBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '12px 16px',
  minHeight: '48px',
}))

const TabIconBox = styled(Box)(({ theme }) => ({
  width: 24,
  height: 24,
}))

const excludePages = [
  // Descoped for v1
  PreferenceCenterPage.PrivacyPolicy,
  // These two don't appear in navigation panel
  PreferenceCenterPage.RequestsForm,
  PreferenceCenterPage.RequestsSubmitted,
]

export const PreferenceNavigation: React.FC = () => {
  const { themeConfig, experienceConfig, preferencePage, entitledPreferencePages } = useContext(BuilderContext)

  const tabs = useMemo(
    () => entitledPreferencePages.filter(page => !excludePages.includes(page)),
    [entitledPreferencePages],
  )

  // Theme config
  const containerBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutBackgroundColor,
  )
  const containerCornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutCornerRadius),
      BUILDER_CONSTANTS.PREF_NAV_MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.PREF_NAV_MAX_BORDER_RADIUS,
    ) + 'px'
  // Descoped
  // const tabUnselectedBackgroundColor = getIn(
  //   themeConfig,
  //   themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutItemUnselectedBackgroundColor,
  // )
  const tabUnselectedTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutItemUnselectedTextColor,
  )
  const tabSelectedBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutItemSelectedBackgroundColor,
  )
  const tabSelectedTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutItemSelectedTextColor,
  )
  const tabCornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutItemCornerRadius),
      BUILDER_CONSTANTS.PREF_NAV_MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.PREF_NAV_MAX_BORDER_RADIUS,
    ) + 'px'
  const showIcons = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabNavigationIconsVisible)
  const navIconUrls = useMemo(
    () => ({
      [PreferenceCenterPage.Welcome]: getIn(
        themeConfig,
        themeUpsertFormKeys.preferenceWelcomeTabNavigationWelcomeIconUrl,
      ),
      [PreferenceCenterPage.PrivacyPolicy]: getIn(
        themeConfig,
        themeUpsertFormKeys.preferenceWelcomeTabNavigationPrivacyPolicyIconUrl,
      ),
      [PreferenceCenterPage.Purpose]: getIn(
        themeConfig,
        themeUpsertFormKeys.preferenceWelcomeTabNavigationPurposesIconUrl,
      ),
      [PreferenceCenterPage.Subscriptions]: getIn(
        themeConfig,
        themeUpsertFormKeys.preferenceWelcomeTabNavigationSubscriptionsIconUrl,
      ),
      [PreferenceCenterPage.RequestsHome]: getIn(
        themeConfig,
        themeUpsertFormKeys.preferenceWelcomeTabNavigationRequestsIconUrl,
      ),
      [PreferenceCenterPage.RequestsForm]: '',
      [PreferenceCenterPage.RequestsSubmitted]: '',
    }),
    [themeConfig],
  )

  // Experience config
  const navLabels = useMemo(
    () => ({
      [PreferenceCenterPage.Welcome]: getIn(
        experienceConfig,
        experienceUpsertFormKeys.preferenceWelcomeTabNavigationWelcomeTitle,
      ),
      [PreferenceCenterPage.PrivacyPolicy]: getIn(
        experienceConfig,
        experienceUpsertFormKeys.preferenceWelcomeTabNavigationPrivacyPolicyTitle,
      ),
      [PreferenceCenterPage.Purpose]: getIn(
        experienceConfig,
        experienceUpsertFormKeys.preferenceWelcomeTabNavigationPurposesTitle,
      ),
      [PreferenceCenterPage.Subscriptions]: getIn(
        experienceConfig,
        experienceUpsertFormKeys.preferenceWelcomeTabNavigationSubscriptionsTitle,
      ),
      [PreferenceCenterPage.RequestsHome]: getIn(
        experienceConfig,
        experienceUpsertFormKeys.preferenceWelcomeTabNavigationRequestsTitle,
      ),
      [PreferenceCenterPage.RequestsForm]: '',
      [PreferenceCenterPage.RequestsSubmitted]: '',
    }),
    [experienceConfig],
  )

  return (
    <NavigationBox sx={{ background: containerBackgroundColor, borderRadius: containerCornerRadius }}>
      {tabs.map(tab => {
        const isSelected = tab === preferencePage
        const showIcon = navIconUrls[tab] && showIcons
        return (
          <TabBox
            sx={{
              background: isSelected ? tabSelectedBackgroundColor : '',
              borderRadius: tabCornerRadius,
            }}
            key={tab}
          >
            {showIcon && (
              <TabIconBox>
                <ImageContainer variant={BuilderImageVariant.Navigation} imageUrl={navIconUrls[tab]} />
              </TabIconBox>
            )}
            <Typography
              component={'div'}
              variant="label"
              color={isSelected ? tabSelectedTextColor : tabUnselectedTextColor}
              sx={{ wordWrap: 'break-word', maxWidth: showIcon ? '212px' : '236px' }}
            >
              {navLabels[tab]}
            </Typography>
          </TabBox>
        )
      })}
    </NavigationBox>
  )
}
