import { APIListRequestParams, APIListResponse, Ordering, formatOrdering } from 'api/common/utils'
import { ExperienceDTO } from 'interfaces/experiences/experience'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
  experienceCode: string
  ordering?: Ordering<'version'>
}>

type Response = APIListResponse<{ experiences: ExperienceDTO[] }>

export const fetchExperienceVersions = ({
  organizationCode,
  experienceCode,
  includeMetadata,
  start,
  limit,
  ordering,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/experiences/${experienceCode}/versions`,
      params: {
        filters: {
          includeMetadata,
          start,
          limit,
          ordering: formatOrdering(ordering),
        },
      },
    }),
  )
