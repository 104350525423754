import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { DeleteApiKeyModal } from 'components/modals/apiKeys/DeleteApiKeyModal'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

import { AuthenticatorDTO } from '@ketch-com/figurehead'
import { AuthenticatorOverviewModal } from '.'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { Box } from '@mui/material'

type Props = {
  authenticator: AuthenticatorDTO
  setAuthenticatorToDeleteId: (value: React.SetStateAction<string>) => void
}

export const AuthenticatorsActionsCellRenderer: React.FC<Props> = ({ authenticator, setAuthenticatorToDeleteId }) => {
  const navigate = useNavigate()

  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isBusy] = useState(false)
  const [isAuthenticatorOverviewModalOpen, setIsAuthenticatorOverviewModalOpen] = useState(false)

  const isPermittedToReadAuthenticators = isPermitted(PERMISSIONS.AUTHENTICATION_READ)
  const isPermittedToEditAuthenticators = isPermitted(PERMISSIONS.AUTHENTICATION_WRITE)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Button size="medium" variant="iconSubtle" onClick={handleClickListItem} disabled={isBusy}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transitionDuration={0}
        onClick={e => e.stopPropagation()}
      >
        {isPermittedToReadAuthenticators && (
          <ActionSheetItem
            onClick={e => {
              handleClose()
              setIsAuthenticatorOverviewModalOpen(true)
            }}
          >
            View
          </ActionSheetItem>
        )}
        {isPermittedToReadAuthenticators && isPermittedToEditAuthenticators && <ActionSheetItem divider />}
        {isPermittedToEditAuthenticators && (
          <ActionSheetItem
            onClick={e => {
              handleClose()
              navigate(RoutesManager.developers.authenticators.upsert.getURL({ id: authenticator?.id }))
            }}
          >
            Edit
          </ActionSheetItem>
        )}
        {isPermittedToEditAuthenticators && <ActionSheetItem divider />}
        {isPermittedToEditAuthenticators && (
          <ActionSheetItem
            onClick={e => {
              handleClose()
              setAuthenticatorToDeleteId(authenticator?.id || '')
            }}
          >
            Delete
          </ActionSheetItem>
        )}
      </ActionSheetWrapper>

      {isDeleteModalOpen && (
        <DeleteApiKeyModal
          apiKeyId={authenticator?.id || ''}
          onCancel={() => setIsDeleteModalOpen(false)}
          onSubmit={async () => {
            await queryClient.refetchQueries(ApiQueryKeys.apiKeysPaginated)
            setIsDeleteModalOpen(false)
          }}
        />
      )}

      {isAuthenticatorOverviewModalOpen ? (
        <AuthenticatorOverviewModal
          authenticator={authenticator}
          onCancel={() => setIsAuthenticatorOverviewModalOpen(false)}
        />
      ) : null}
    </Box>
  )
}
