import React, { Dispatch, SetStateAction } from 'react'
import { isEmpty } from 'lodash'
import { Waypoint } from 'react-waypoint'
import { Box } from '@mui/material'
import { Relationships } from 'pages/assetManager/interfaces'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/workflows.svg'
import { Relationship } from 'pages/assetManager/relationshipMapping/components'

type Props = {
  isReady?: boolean
  isFetchingRelationships?: boolean
  relationships?: Relationships
  isFetchingNextPage?: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => {}
  setIsCreateRelationshipMappingModalOpen?: Dispatch<SetStateAction<boolean>>
}

export const DiscoveredRelationships: React.FC<Props> = props => {
  const { isReady, relationships, isFetchingNextPage, hasNextPage, fetchNextPage = () => {} } = props

  if (!isReady) return <Spinner />

  if (isReady && isEmpty(relationships))
    return <EmptyState variant="list" icon={<EmptyStateIcon />} title="No relations have been discovered yet" />

  return (
    <>
      <Relationship {...props} />
      {!isFetchingNextPage ? (
        <Waypoint
          topOffset={300}
          onEnter={() => {
            if (!isFetchingNextPage && hasNextPage) fetchNextPage()
          }}
        />
      ) : (
        <Box my={2}>
          <Spinner size="small" />
        </Box>
      )}
    </>
  )
}
