import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'

import { Button } from 'components/ui-kit/button/Button'
import { ReactComponent as EditIcon } from 'assets/icons/edit-pen-dark-dusk-faded.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/trash-can-black.svg'
import { ReactComponent as WhiteCheckMarkIcon } from 'assets/icons/white-check-mark.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg'
import { FormikState } from 'formik'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { Spinner } from 'components/ui-kit/spinner/Spinner'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      containerInfo: {
        position: 'relative',
        // TODO:BAC - uncomment to un-disable in-line edit button
        // '&:hover': {
        //   '& $editButtonContainer': {
        //     display: 'block',
        //   },
        // },
        padding: spacing(2, 0, 4, 0),
      },
      toggleEditModeButton: {
        maxWidth: 60,
        color: palette.darkDusk.main,
      },
      deleteButton: {
        width: 28,
        padding: 7,
        color: palette.darkDusk.main,
        marginRight: spacing(2),
      },
      cancelButton: {
        width: 28,
        padding: 2,
        color: palette.darkDusk.main,
        marginRight: spacing(1),
      },
      submitButton: {
        width: 28,
        padding: 2,
        color: palette.darkDusk.main,
      },
      editButtonContainer: {
        backgroundColor: palette.white.main,
        display: 'none',
        position: 'absolute',
        top: 8,
        right: 8,
      },
    }),
  { name: 'InlineEditLayout' },
)

type Props = {
  displayModeContent?: React.ReactNode
  editModeContent?: React.ReactNode
  editModeKey: string
  error?: React.ReactNode
  isEditMode?: boolean
  isLoadingRequiredFormData?: boolean
  onSubmit?: () => void
  pending?: boolean
  resetForm: (nextState?: Partial<FormikState<EditDatabaseFormValues>> | undefined) => void
  resetInputOnCancel?: () => void
  onDelete?: () => void
  setEditRow: Dispatch<SetStateAction<string>>
}

export const InlineEditLayout: React.FC<Props> = ({
  displayModeContent,
  editModeContent,
  editModeKey,
  error,
  isEditMode,
  isLoadingRequiredFormData,
  onDelete,
  onSubmit,
  pending,
  resetInputOnCancel,
  setEditRow,
}) => {
  const classes = useStyles()

  useEffect(() => {
    if (!isEditMode) resetInputOnCancel?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode])

  if (isLoadingRequiredFormData && isEditMode) return <Spinner />

  if (!isEditMode) {
    return (
      <Box display="flex" flex={1} className={classes.containerInfo}>
        <Box className={classes.editButtonContainer}>
          <Button
            shouldRemoveMinWidth
            className={classes.toggleEditModeButton}
            onClick={() => setEditRow(editModeKey)}
            variant="tertiary"
            size="regular"
          >
            <EditIcon />
          </Button>
        </Box>

        {displayModeContent}
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Box display="flex" alignItems="flex-start" gap={2}>
        {/* Edit Mode Content */}

        <Box display="flex" alignItems="center" flex={1} flexDirection="row" pt={2} pb={4}>
          {editModeContent}
        </Box>

        {/* Save/Cancel Buttons Container */}

        <Box display="flex" alignItems="center" pt={2} pb={4}>
          <Button
            shouldRemoveMinWidth
            className={classes.deleteButton}
            onClick={() => {
              onDelete?.()
              onSubmit?.()
            }}
            variant="tertiary"
            size="regular"
          >
            <DeleteIcon fontSize="inherit" />
          </Button>

          <Button
            shouldRemoveMinWidth
            className={classes.cancelButton}
            onClick={() => {
              setEditRow('')
              resetInputOnCancel?.()
            }}
            variant="tertiary"
            size="regular"
          >
            <CloseIcon fontSize="inherit" />
          </Button>

          <Button
            disabled={!!error}
            tooltip={error}
            pending={pending}
            shouldRemoveMinWidth
            className={classes.submitButton}
            onClick={() => onSubmit?.()}
            variant="primary"
            size="regular"
          >
            <WhiteCheckMarkIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
