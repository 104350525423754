import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, CircularProgress } from '@mui/material'

type Props = {
  isDropdownOpen: boolean
  isPending?: boolean
  size?: 'small' | 'medium' | 'large' | undefined
  color?: string
}

export const EndIcon: React.FC<Props> = ({ isDropdownOpen, isPending, size, color }) => {
  const pendingIcon = isPending ? (
    <CircularProgress
      size={size === 'small' ? 14 : 16}
      sx={{
        color: ({ palette }) => palette.persianBlue.main,
        mr: 1,
      }}
    />
  ) : null

  return isDropdownOpen ? (
    <Box display="flex" alignItems="center">
      {pendingIcon}
      <KeyboardArrowUpIcon
        sx={{
          color: ({ palette }) => color || palette.darkDusk.main,
        }}
      />
    </Box>
  ) : (
    <Box display="flex" alignItems="center">
      {pendingIcon}
      <KeyboardArrowDownIcon
        sx={{
          color: ({ palette }) => color || palette.darkDusk.main,
        }}
      />
    </Box>
  )
}
