import React from 'react'

// assets
import { ReactComponent as AboutIconGray } from 'assets/icons/about_question_mark_light_gray.svg'

// services
import { useWorkflows } from 'api/workflows/queries/useWorkflows'

// components
import { Box } from '@mui/material'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { Text } from 'components/ui-kit/typography/Text'

type Props = {
  canonicalRightCode?: string
  hasMaxWidth?: boolean
}

export const WorkflowOptionsCellRenderer: React.FC<Props> = ({ canonicalRightCode, hasMaxWidth = false }) => {
  const { data: workflows, isLoading: isWorkflowsListLoading } = useWorkflows({
    params: {
      canonicalRightCode,
    },
  })

  if (isWorkflowsListLoading)
    return (
      <FormDropdown
        disabled
        name="workflowCode"
        placeholder="Select Workflow"
        items={workflows}
        valueKey="code"
        pending={true}
      />
    )

  return workflows.length > 0 ? (
    <FormDropdown
      fullWidth={hasMaxWidth}
      disabled={isWorkflowsListLoading}
      name="workflowCode"
      placeholder="Select Workflow"
      items={workflows}
      valueKey="code"
      renderItem={({ name, code }) => <NameAndCodeCellRenderer name={name} code={code} />}
      renderValue={({ name, code }) => <NameAndCodeCellRenderer name={name} code={code} maxWidth={200} />}
    />
  ) : (
    <Box display="flex" alignItems="center">
      <Box mr={1} display="flex" alignItems="center">
        <Text size={14}>No available workflows</Text>
      </Box>
      <Tooltip
        content={
          <Box display="flex" alignItems="center" justifyContent="center">
            There are no workflows that match the Canonical Right for this event.
          </Box>
        }
      >
        <AboutIconGray />
      </Tooltip>
    </Box>
  )
}
