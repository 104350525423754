import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchScenarios } from '../fetchers/fetchScenarios'
import { useQueryClient } from 'react-query'
import { FindScenariosResponseBodyDTO } from '@ketch-com/figurehead'
import { AxiosResponse } from 'axios'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.scenarios,
  queryFn: fetchScenarios,
  select: res => res?.data || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useScenarios = (config?: Config) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )
  const queryClient = useQueryClient()
  return useCustomQuery({
    ...config,
    params: {
      isV2FeatureFlagEnabled,
      ...config?.params,
    },
    initialData: () => {
      const scenariosRes =
        queryClient.getQueryData<AxiosResponse<FindScenariosResponseBodyDTO, any>>([
          ApiQueryKeys.scenarios,
          { limit: 100, rightType: 0, start: 0, canonicalRightCode: 'get,delete' },
        ]) || ({} as AxiosResponse<FindScenariosResponseBodyDTO, any>)
      const scenariosResClone = { ...scenariosRes }
      const scenariosData: FindScenariosResponseBodyDTO = structuredClone(scenariosRes?.data || {})
      const { scenario: scenarios = [] } = scenariosData
      scenariosData.scenario = scenarios.filter(scenario => scenario.rightType === config?.params?.rightType)
      scenariosData.totalResults = scenariosData?.scenario?.length

      if (scenariosResClone?.data) scenariosResClone.data = scenariosData

      return scenariosResClone
    },
  })
}
