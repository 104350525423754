import { APIListRequestParams } from 'api/common/utils'
import { FetchDataSubjectTypesResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  query?: string
  includeDeleted?: boolean
}>

export const fetchDataSubjectTypes = ({ query, limit, start, includeDeleted }: Params) =>
  API.get<FetchDataSubjectTypesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/data-subject-types`,
      params: {
        filters: {
          start,
          limit,
          ...(query && {
            query,
          }),
          includeDeleted,
        },
      },
    }),
  )
