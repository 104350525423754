import { AssetTypeIcon } from 'pages/assetManager/components'
import { RoutesManager } from 'utils/routing/routesManager'
import { DataRiskDetailMenu } from '../../components/layout/DataRiskDetailMenu'
import { useStyles } from '../../components/layout/styles'
import { Policy } from '../../__mocks__/policiesControls'

type Props = {
  rows: Policy[]
}

export const PoliciesMenu: React.FC<Props> = ({ rows }) => {
  const classes = useStyles()
  return (
    <DataRiskDetailMenu
      route={RoutesManager.insights.dataRisks.policiesControls.policies.root}
      items={rows.map(row => ({
        id: row.id || '',
        text: row.name || '',
        score: row.riskScore || 0,
        icon: <AssetTypeIcon assetType={row.canonicalResourceType || 1} className={classes.icon} />,
      }))}
    />
  )
}
