import { faker } from '@faker-js/faker'

type RiskTrend = {
  month?: string
  riskScore?: number
}

export type UnutilizedDataAssets = {
  id?: string
  name?: string
  canonicalResourceType?: number
  dataRiskScore?: number
  assetType?: string
  personalDataTypes?: string[]
  dataCategories?: string[]
  lastUsed?: {
    name?: string
    timestamp?: number
  }
  purposes?: {
    name?: string
    code?: string
  }[]
  recordCount?: number
  riskTrend?: RiskTrend[]
}

const dataCategories = [
  'ZIP',
  'First Name',
  'School',
  'Last Name',
  'User ID',
  'SSN',
  'Job Title',
  'Salary',
  'Gender',
  'Email',
  'Middle Initial',
  'Employer',
  'Address',
  'Medical History',
  'Purchase History',
  'Snacking Trends',
  'Shoe Size',
  'Browsing Trends',
  'Search History',
]

const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const purposes = [
  { name: 'Candidate Recruiting', code: 'candidate_recruiting' },
  { name: 'Analytics', code: 'analytics' },
  { name: 'DSR Processing (Controller)', code: 'dsr_processing' },
  { name: 'Marketing', code: 'marketing' },
]

const names = [
  'EMPLOYEE_PERSONAL_DATA',
  'ss_mysql',
  'AWS SF North Medical',
  'Email Lists Subscribers',
  'VENDORS',
  'Archive',
  'Opt-out backup',
  'XTrytsn980-912',
  'Users',
  'STORE',
  'Archive.iiKKS.LP',
  'CALL CENTER',
  'CUSTOMER',
  'CATALOG_SALES',
  'STORE (backup)',
  'Air_Quality_data_ML',
  'Expired_CATALOG_SALES',
  'Sales_Legacy',
  'Pre-2019 Marketing Data',
  'INCIDENT_REPORTS',
]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const generateAssets = (): UnutilizedDataAssets[] =>
  names.map(name => {
    const dataRiskScore = faker.datatype.number({ min: 10, max: 100 })
    const now = faker.datatype.number({ min: 0, max: 11 })
    const riskTrend: RiskTrend[] = []
    for (let j = 0; j <= now; j++) {
      riskTrend.push({
        month: month[j],
        riskScore: j === now ? dataRiskScore : faker.datatype.number({ min: 10, max: dataRiskScore }),
      })
    }

    return {
      id: faker.datatype.uuid(),
      name,
      canonicalResourceType: faker.datatype.number({ min: 1, max: 3 }),
      dataRiskScore,
      assetType: faker.helpers.arrayElement(['Database', 'System']),
      personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
      dataCategories: faker.helpers.arrayElements(dataCategories, 16),
      lastUsed: {
        name: `${faker.name.firstName()} ${faker.name.lastName()}`,
        timestamp: faker.datatype.number({ min: 1498867200000, max: 1656633600000 }),
      },
      purposes: faker.helpers.arrayElements(purposes, 3),
      recordCount: faker.datatype.number({ min: 200, max: 5000 }),
      riskTrend,
    }
  })

// Only generate once so that refreshing the page works
// console.info(generateAssets()) // logged output and pasted to hardcode for now

export const assets = [
  {
    id: 'f79515ad-d179-4dfc-aa7c-10c06f1bc915',
    name: 'EMPLOYEE_PERSONAL_DATA',
    canonicalResourceType: 3,
    dataRiskScore: 55,
    assetType: 'Database',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'Purchase History',
      'School',
      'Salary',
      'Shoe Size',
      'SSN',
      'User ID',
      'Snacking Trends',
      'Browsing Trends',
      'Last Name',
      'Middle Initial',
      'ZIP',
      'Employer',
      'Address',
      'Email',
      'Gender',
      'Medical History',
    ],
    lastUsed: {
      name: 'Matilda Marks',
      timestamp: 1625550336984,
    },
    purposes: [
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
      {
        name: 'Marketing',
        code: 'marketing',
      },
      {
        name: 'Analytics',
        code: 'analytics',
      },
    ],
    recordCount: 1252,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 10,
      },
      {
        month: 'Feb',
        riskScore: 10,
      },
      {
        month: 'Mar',
        riskScore: 51,
      },
      {
        month: 'Apr',
        riskScore: 30,
      },
      {
        month: 'May',
        riskScore: 36,
      },
      {
        month: 'Jun',
        riskScore: 54,
      },
      {
        month: 'Jul',
        riskScore: 11,
      },
      {
        month: 'Aug',
        riskScore: 38,
      },
      {
        month: 'Sep',
        riskScore: 55,
      },
      {
        month: 'Oct',
        riskScore: 55,
      },
    ],
  },
  {
    id: '745a2010-7770-446a-9f97-a6fd079b1bb5',
    name: 'ss_mysql',
    canonicalResourceType: 2,
    dataRiskScore: 48,
    assetType: 'Database',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'Salary',
      'Medical History',
      'Job Title',
      'Email',
      'SSN',
      'Search History',
      'Snacking Trends',
      'First Name',
      'School',
      'Shoe Size',
      'Last Name',
      'Browsing Trends',
      'Purchase History',
      'User ID',
      'Middle Initial',
      'Gender',
    ],
    lastUsed: {
      name: 'Randal Adams',
      timestamp: 1595904263718,
    },
    purposes: [
      {
        name: 'Marketing',
        code: 'marketing',
      },
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
      {
        name: 'Analytics',
        code: 'analytics',
      },
    ],
    recordCount: 2452,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 48,
      },
    ],
  },
  {
    id: '6763054a-f8a1-490f-99b9-e5588228deef',
    name: 'AWS SF North Medical',
    canonicalResourceType: 2,
    dataRiskScore: 46,
    assetType: 'System',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'School',
      'SSN',
      'Salary',
      'Email',
      'Search History',
      'Job Title',
      'User ID',
      'Gender',
      'First Name',
      'Purchase History',
      'Medical History',
      'Shoe Size',
      'Employer',
      'Middle Initial',
      'Last Name',
      'Browsing Trends',
    ],
    lastUsed: {
      name: 'Loraine Volkman',
      timestamp: 1605885453349,
    },
    purposes: [
      {
        name: 'Analytics',
        code: 'analytics',
      },
      {
        name: 'Candidate Recruiting',
        code: 'candidate_recruiting',
      },
      {
        name: 'Marketing',
        code: 'marketing',
      },
    ],
    recordCount: 2301,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 17,
      },
      {
        month: 'Feb',
        riskScore: 23,
      },
      {
        month: 'Mar',
        riskScore: 12,
      },
      {
        month: 'Apr',
        riskScore: 21,
      },
      {
        month: 'May',
        riskScore: 26,
      },
      {
        month: 'Jun',
        riskScore: 30,
      },
      {
        month: 'Jul',
        riskScore: 46,
      },
    ],
  },
  {
    id: '9747f39a-3685-4048-97cf-6424b6e7ea27',
    name: 'Email Lists Subscribers',
    canonicalResourceType: 1,
    dataRiskScore: 63,
    assetType: 'System',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'Middle Initial',
      'Employer',
      'Browsing Trends',
      'ZIP',
      'Last Name',
      'SSN',
      'Shoe Size',
      'Email',
      'Snacking Trends',
      'Medical History',
      'Search History',
      'Job Title',
      'First Name',
      'User ID',
      'School',
      'Salary',
    ],
    lastUsed: {
      name: 'Lucio Aufderhar',
      timestamp: 1608393538867,
    },
    purposes: [
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
      {
        name: 'Candidate Recruiting',
        code: 'candidate_recruiting',
      },
      {
        name: 'Analytics',
        code: 'analytics',
      },
    ],
    recordCount: 263,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 13,
      },
      {
        month: 'Feb',
        riskScore: 21,
      },
      {
        month: 'Mar',
        riskScore: 63,
      },
    ],
  },
  {
    id: '77d199df-0fe2-481b-8938-62cb0bcfca24',
    name: 'VENDORS',
    canonicalResourceType: 3,
    dataRiskScore: 68,
    assetType: 'Database',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'Salary',
      'Gender',
      'Address',
      'Last Name',
      'Email',
      'Shoe Size',
      'Purchase History',
      'Medical History',
      'Search History',
      'Employer',
      'First Name',
      'Browsing Trends',
      'SSN',
      'Job Title',
      'ZIP',
      'User ID',
    ],
    lastUsed: {
      name: 'Joy Spencer',
      timestamp: 1571756096319,
    },
    purposes: [
      {
        name: 'Analytics',
        code: 'analytics',
      },
      {
        name: 'Candidate Recruiting',
        code: 'candidate_recruiting',
      },
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
    ],
    recordCount: 3000,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 31,
      },
      {
        month: 'Feb',
        riskScore: 45,
      },
      {
        month: 'Mar',
        riskScore: 13,
      },
      {
        month: 'Apr',
        riskScore: 12,
      },
      {
        month: 'May',
        riskScore: 68,
      },
    ],
  },
  {
    id: '56867320-2ce9-4a75-9eed-c695627ff137',
    name: 'Archive',
    canonicalResourceType: 3,
    dataRiskScore: 63,
    assetType: 'Database',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'Job Title',
      'School',
      'Salary',
      'Employer',
      'Browsing Trends',
      'Address',
      'ZIP',
      'Gender',
      'First Name',
      'Search History',
      'Shoe Size',
      'SSN',
      'Email',
      'Last Name',
      'Purchase History',
      'Medical History',
    ],
    lastUsed: {
      name: 'Sadie Gerlach',
      timestamp: 1649305527585,
    },
    purposes: [
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
      {
        name: 'Marketing',
        code: 'marketing',
      },
      {
        name: 'Analytics',
        code: 'analytics',
      },
    ],
    recordCount: 2398,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 46,
      },
      {
        month: 'Feb',
        riskScore: 28,
      },
      {
        month: 'Mar',
        riskScore: 17,
      },
      {
        month: 'Apr',
        riskScore: 33,
      },
      {
        month: 'May',
        riskScore: 38,
      },
      {
        month: 'Jun',
        riskScore: 63,
      },
    ],
  },
  {
    id: '0cc76c61-ea4a-4cd3-8e2a-f3a1dfc8a9fd',
    name: 'Opt-out backup',
    canonicalResourceType: 2,
    dataRiskScore: 97,
    assetType: 'System',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'Middle Initial',
      'User ID',
      'Employer',
      'Salary',
      'Job Title',
      'Snacking Trends',
      'Gender',
      'Address',
      'First Name',
      'Medical History',
      'Browsing Trends',
      'Purchase History',
      'Shoe Size',
      'SSN',
      'Last Name',
      'ZIP',
    ],
    lastUsed: {
      name: 'Ubaldo Towne',
      timestamp: 1501994059601,
    },
    purposes: [
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
      {
        name: 'Candidate Recruiting',
        code: 'candidate_recruiting',
      },
      {
        name: 'Marketing',
        code: 'marketing',
      },
    ],
    recordCount: 1920,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 20,
      },
      {
        month: 'Feb',
        riskScore: 65,
      },
      {
        month: 'Mar',
        riskScore: 47,
      },
      {
        month: 'Apr',
        riskScore: 97,
      },
      {
        month: 'May',
        riskScore: 12,
      },
      {
        month: 'Jun',
        riskScore: 73,
      },
      {
        month: 'Jul',
        riskScore: 91,
      },
      {
        month: 'Aug',
        riskScore: 97,
      },
    ],
  },
  {
    id: '83c51d9d-8f1c-4482-85f2-089ffcfc8fde',
    name: 'XTrytsn980-912',
    canonicalResourceType: 2,
    dataRiskScore: 58,
    assetType: 'Database',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'Email',
      'First Name',
      'Job Title',
      'ZIP',
      'Snacking Trends',
      'Shoe Size',
      'SSN',
      'Purchase History',
      'Browsing Trends',
      'Middle Initial',
      'Address',
      'User ID',
      'Last Name',
      'Medical History',
      'Gender',
      'Salary',
    ],
    lastUsed: {
      name: 'Desiree Quigley',
      timestamp: 1528911503707,
    },
    purposes: [
      {
        name: 'Marketing',
        code: 'marketing',
      },
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
      {
        name: 'Candidate Recruiting',
        code: 'candidate_recruiting',
      },
    ],
    recordCount: 2185,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 32,
      },
      {
        month: 'Feb',
        riskScore: 35,
      },
      {
        month: 'Mar',
        riskScore: 58,
      },
      {
        month: 'Apr',
        riskScore: 53,
      },
      {
        month: 'May',
        riskScore: 18,
      },
      {
        month: 'Jun',
        riskScore: 56,
      },
      {
        month: 'Jul',
        riskScore: 34,
      },
      {
        month: 'Aug',
        riskScore: 33,
      },
      {
        month: 'Sep',
        riskScore: 53,
      },
      {
        month: 'Oct',
        riskScore: 39,
      },
      {
        month: 'Nov',
        riskScore: 58,
      },
    ],
  },
  {
    id: '917949a5-014d-4b22-ac62-1a06a6f12483',
    name: 'Users',
    canonicalResourceType: 1,
    dataRiskScore: 28,
    assetType: 'Database',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'First Name',
      'Email',
      'Job Title',
      'Shoe Size',
      'Employer',
      'Last Name',
      'Snacking Trends',
      'Purchase History',
      'Address',
      'Salary',
      'SSN',
      'User ID',
      'ZIP',
      'Gender',
      'Medical History',
      'School',
    ],
    lastUsed: {
      name: 'Guido Schneider',
      timestamp: 1626447048102,
    },
    purposes: [
      {
        name: 'Analytics',
        code: 'analytics',
      },
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
      {
        name: 'Candidate Recruiting',
        code: 'candidate_recruiting',
      },
    ],
    recordCount: 1637,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 20,
      },
      {
        month: 'Feb',
        riskScore: 24,
      },
      {
        month: 'Mar',
        riskScore: 11,
      },
      {
        month: 'Apr',
        riskScore: 17,
      },
      {
        month: 'May',
        riskScore: 13,
      },
      {
        month: 'Jun',
        riskScore: 21,
      },
      {
        month: 'Jul',
        riskScore: 15,
      },
      {
        month: 'Aug',
        riskScore: 28,
      },
    ],
  },
  {
    id: '77a7d42b-dca1-49b7-85df-b40f4f3725a5',
    name: 'STORE',
    canonicalResourceType: 1,
    dataRiskScore: 78,
    assetType: 'System',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'School',
      'Salary',
      'Shoe Size',
      'First Name',
      'Email',
      'User ID',
      'SSN',
      'Middle Initial',
      'Medical History',
      'Gender',
      'Last Name',
      'Search History',
      'Snacking Trends',
      'Purchase History',
      'Address',
      'Browsing Trends',
    ],
    lastUsed: {
      name: 'Roxane Macejkovic',
      timestamp: 1598823058599,
    },
    purposes: [
      {
        name: 'Candidate Recruiting',
        code: 'candidate_recruiting',
      },
      {
        name: 'Analytics',
        code: 'analytics',
      },
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
    ],
    recordCount: 3663,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 52,
      },
      {
        month: 'Feb',
        riskScore: 26,
      },
      {
        month: 'Mar',
        riskScore: 46,
      },
      {
        month: 'Apr',
        riskScore: 56,
      },
      {
        month: 'May',
        riskScore: 53,
      },
      {
        month: 'Jun',
        riskScore: 78,
      },
      {
        month: 'Jul',
        riskScore: 32,
      },
      {
        month: 'Aug',
        riskScore: 53,
      },
      {
        month: 'Sep',
        riskScore: 22,
      },
      {
        month: 'Oct',
        riskScore: 74,
      },
      {
        month: 'Nov',
        riskScore: 78,
      },
    ],
  },
  {
    id: '3ba866ad-88f8-4331-b70e-720deaa4f7d7',
    name: 'Archive.iiKKS.LP',
    canonicalResourceType: 3,
    dataRiskScore: 93,
    assetType: 'System',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'Job Title',
      'School',
      'Snacking Trends',
      'Browsing Trends',
      'Middle Initial',
      'Employer',
      'SSN',
      'Medical History',
      'Gender',
      'Purchase History',
      'Address',
      'Shoe Size',
      'ZIP',
      'Last Name',
      'Email',
      'Search History',
    ],
    lastUsed: {
      name: 'River Franey',
      timestamp: 1520146115952,
    },
    purposes: [
      {
        name: 'Candidate Recruiting',
        code: 'candidate_recruiting',
      },
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
      {
        name: 'Marketing',
        code: 'marketing',
      },
    ],
    recordCount: 3430,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 26,
      },
      {
        month: 'Feb',
        riskScore: 76,
      },
      {
        month: 'Mar',
        riskScore: 77,
      },
      {
        month: 'Apr',
        riskScore: 21,
      },
      {
        month: 'May',
        riskScore: 58,
      },
      {
        month: 'Jun',
        riskScore: 50,
      },
      {
        month: 'Jul',
        riskScore: 93,
      },
    ],
  },
  {
    id: '5dad651a-4b69-4379-9e48-7c3d497733fc',
    name: 'CALL CENTER',
    canonicalResourceType: 2,
    dataRiskScore: 80,
    assetType: 'System',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'ZIP',
      'SSN',
      'Gender',
      'Snacking Trends',
      'Address',
      'Employer',
      'Salary',
      'User ID',
      'Search History',
      'Middle Initial',
      'Medical History',
      'Job Title',
      'Purchase History',
      'First Name',
      'Last Name',
      'Browsing Trends',
    ],
    lastUsed: {
      name: 'Jabari Kiehn',
      timestamp: 1655687952643,
    },
    purposes: [
      {
        name: 'Marketing',
        code: 'marketing',
      },
      {
        name: 'Analytics',
        code: 'analytics',
      },
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
    ],
    recordCount: 4013,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 80,
      },
      {
        month: 'Feb',
        riskScore: 22,
      },
      {
        month: 'Mar',
        riskScore: 56,
      },
      {
        month: 'Apr',
        riskScore: 74,
      },
      {
        month: 'May',
        riskScore: 25,
      },
      {
        month: 'Jun',
        riskScore: 11,
      },
      {
        month: 'Jul',
        riskScore: 10,
      },
      {
        month: 'Aug',
        riskScore: 58,
      },
      {
        month: 'Sep',
        riskScore: 60,
      },
      {
        month: 'Oct',
        riskScore: 33,
      },
      {
        month: 'Nov',
        riskScore: 80,
      },
    ],
  },
  {
    id: '92b13705-e7e4-4193-8176-f6bca3875194',
    name: 'CUSTOMER',
    canonicalResourceType: 3,
    dataRiskScore: 87,
    assetType: 'Database',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'School',
      'Shoe Size',
      'Job Title',
      'ZIP',
      'First Name',
      'Search History',
      'Medical History',
      'Snacking Trends',
      'User ID',
      'Email',
      'SSN',
      'Purchase History',
      'Middle Initial',
      'Browsing Trends',
      'Employer',
      'Last Name',
    ],
    lastUsed: {
      name: 'Triston Hagenes',
      timestamp: 1648350202921,
    },
    purposes: [
      {
        name: 'Candidate Recruiting',
        code: 'candidate_recruiting',
      },
      {
        name: 'Marketing',
        code: 'marketing',
      },
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
    ],
    recordCount: 345,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 22,
      },
      {
        month: 'Feb',
        riskScore: 27,
      },
      {
        month: 'Mar',
        riskScore: 22,
      },
      {
        month: 'Apr',
        riskScore: 39,
      },
      {
        month: 'May',
        riskScore: 40,
      },
      {
        month: 'Jun',
        riskScore: 72,
      },
      {
        month: 'Jul',
        riskScore: 73,
      },
      {
        month: 'Aug',
        riskScore: 17,
      },
      {
        month: 'Sep',
        riskScore: 12,
      },
      {
        month: 'Oct',
        riskScore: 61,
      },
      {
        month: 'Nov',
        riskScore: 87,
      },
      {
        month: 'Dec',
        riskScore: 87,
      },
    ],
  },
  {
    id: '0141c397-8227-4603-95f5-0c4e9ba6260b',
    name: 'CATALOG_SALES',
    canonicalResourceType: 2,
    dataRiskScore: 21,
    assetType: 'System',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'Snacking Trends',
      'Job Title',
      'Employer',
      'Shoe Size',
      'Gender',
      'ZIP',
      'User ID',
      'Last Name',
      'School',
      'Salary',
      'Purchase History',
      'First Name',
      'Search History',
      'Browsing Trends',
      'SSN',
      'Middle Initial',
    ],
    lastUsed: {
      name: 'Louie Stehr',
      timestamp: 1576047015276,
    },
    purposes: [
      {
        name: 'Analytics',
        code: 'analytics',
      },
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
      {
        name: 'Marketing',
        code: 'marketing',
      },
    ],
    recordCount: 3162,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 11,
      },
      {
        month: 'Feb',
        riskScore: 14,
      },
      {
        month: 'Mar',
        riskScore: 12,
      },
      {
        month: 'Apr',
        riskScore: 17,
      },
      {
        month: 'May',
        riskScore: 13,
      },
      {
        month: 'Jun',
        riskScore: 21,
      },
      {
        month: 'Jul',
        riskScore: 16,
      },
      {
        month: 'Aug',
        riskScore: 18,
      },
      {
        month: 'Sep',
        riskScore: 21,
      },
    ],
  },
  {
    id: '379ea4a2-58ff-431b-86ff-30d44f825346',
    name: 'STORE (backup)',
    canonicalResourceType: 2,
    dataRiskScore: 94,
    assetType: 'Database',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'Last Name',
      'Shoe Size',
      'School',
      'User ID',
      'Address',
      'Gender',
      'ZIP',
      'Middle Initial',
      'Snacking Trends',
      'Medical History',
      'SSN',
      'Email',
      'Salary',
      'Purchase History',
      'First Name',
      'Browsing Trends',
    ],
    lastUsed: {
      name: 'Christian Walter',
      timestamp: 1604690250742,
    },
    purposes: [
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
      {
        name: 'Candidate Recruiting',
        code: 'candidate_recruiting',
      },
      {
        name: 'Analytics',
        code: 'analytics',
      },
    ],
    recordCount: 1745,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 48,
      },
      {
        month: 'Feb',
        riskScore: 91,
      },
      {
        month: 'Mar',
        riskScore: 88,
      },
      {
        month: 'Apr',
        riskScore: 94,
      },
    ],
  },
  {
    id: 'c59da9f8-a3cd-44da-b77c-951227ea9e2d',
    name: 'Air_Quality_data_ML',
    canonicalResourceType: 3,
    dataRiskScore: 71,
    assetType: 'System',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'SSN',
      'Salary',
      'Middle Initial',
      'Purchase History',
      'Employer',
      'ZIP',
      'First Name',
      'School',
      'Email',
      'Address',
      'Gender',
      'Search History',
      'Shoe Size',
      'User ID',
      'Job Title',
      'Snacking Trends',
    ],
    lastUsed: {
      name: 'Wyman Langosh',
      timestamp: 1573501933568,
    },
    purposes: [
      {
        name: 'Marketing',
        code: 'marketing',
      },
      {
        name: 'Analytics',
        code: 'analytics',
      },
      {
        name: 'Candidate Recruiting',
        code: 'candidate_recruiting',
      },
    ],
    recordCount: 2951,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 48,
      },
      {
        month: 'Feb',
        riskScore: 71,
      },
    ],
  },
  {
    id: '325d1d49-be4a-4403-b690-9fd22b7c45b2',
    name: 'Expired_CATALOG_SALES',
    canonicalResourceType: 1,
    dataRiskScore: 68,
    assetType: 'System',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'Snacking Trends',
      'ZIP',
      'Search History',
      'Job Title',
      'Salary',
      'SSN',
      'Gender',
      'Middle Initial',
      'Email',
      'School',
      'Last Name',
      'Address',
      'Shoe Size',
      'User ID',
      'Medical History',
      'Browsing Trends',
    ],
    lastUsed: {
      name: 'Elenora Swift',
      timestamp: 1530231544088,
    },
    purposes: [
      {
        name: 'Analytics',
        code: 'analytics',
      },
      {
        name: 'Marketing',
        code: 'marketing',
      },
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
    ],
    recordCount: 330,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 62,
      },
      {
        month: 'Feb',
        riskScore: 38,
      },
      {
        month: 'Mar',
        riskScore: 68,
      },
    ],
  },
  {
    id: '3bfaaf84-cd0a-422c-a4d2-41990a4330dc',
    name: 'Sales_Legacy',
    canonicalResourceType: 2,
    dataRiskScore: 54,
    assetType: 'Database',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'School',
      'Browsing Trends',
      'Shoe Size',
      'Search History',
      'SSN',
      'Medical History',
      'Snacking Trends',
      'Job Title',
      'Last Name',
      'Middle Initial',
      'First Name',
      'Gender',
      'Address',
      'Purchase History',
      'Employer',
      'ZIP',
    ],
    lastUsed: {
      name: 'Karson Cummings',
      timestamp: 1610317751068,
    },
    purposes: [
      {
        name: 'Candidate Recruiting',
        code: 'candidate_recruiting',
      },
      {
        name: 'Analytics',
        code: 'analytics',
      },
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
    ],
    recordCount: 2086,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 14,
      },
      {
        month: 'Feb',
        riskScore: 29,
      },
      {
        month: 'Mar',
        riskScore: 36,
      },
      {
        month: 'Apr',
        riskScore: 40,
      },
      {
        month: 'May',
        riskScore: 40,
      },
      {
        month: 'Jun',
        riskScore: 16,
      },
      {
        month: 'Jul',
        riskScore: 33,
      },
      {
        month: 'Aug',
        riskScore: 33,
      },
      {
        month: 'Sep',
        riskScore: 54,
      },
    ],
  },
  {
    id: 'ead30e74-552e-44f8-8f1c-25ddd1962fc5',
    name: 'Pre-2019 Marketing Data',
    canonicalResourceType: 2,
    dataRiskScore: 61,
    assetType: 'System',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'Last Name',
      'First Name',
      'Search History',
      'Employer',
      'Salary',
      'User ID',
      'Snacking Trends',
      'Medical History',
      'Job Title',
      'SSN',
      'Shoe Size',
      'Email',
      'Address',
      'Purchase History',
      'School',
      'Gender',
    ],
    lastUsed: {
      name: 'Brandi Cummerata',
      timestamp: 1577760289439,
    },
    purposes: [
      {
        name: 'Analytics',
        code: 'analytics',
      },
      {
        name: 'Candidate Recruiting',
        code: 'candidate_recruiting',
      },
      {
        name: 'DSR Processing (Controller)',
        code: 'dsr_processing',
      },
    ],
    recordCount: 4953,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 40,
      },
      {
        month: 'Feb',
        riskScore: 35,
      },
      {
        month: 'Mar',
        riskScore: 51,
      },
      {
        month: 'Apr',
        riskScore: 61,
      },
    ],
  },
  {
    id: '07e5c614-020e-41e4-95fe-d0b0dceae08d',
    name: 'INCIDENT_REPORTS',
    canonicalResourceType: 3,
    dataRiskScore: 52,
    assetType: 'System',
    personalDataTypes: ['Sensitive', 'Personal Information (PI)'],
    dataCategories: [
      'Salary',
      'SSN',
      'Email',
      'Snacking Trends',
      'First Name',
      'Shoe Size',
      'Address',
      'Employer',
      'User ID',
      'Middle Initial',
      'Last Name',
      'Gender',
      'ZIP',
      'School',
      'Job Title',
      'Browsing Trends',
    ],
    lastUsed: {
      name: 'Adrain Nader',
      timestamp: 1622180331025,
    },
    purposes: [
      {
        name: 'Analytics',
        code: 'analytics',
      },
      {
        name: 'Marketing',
        code: 'marketing',
      },
      {
        name: 'Candidate Recruiting',
        code: 'candidate_recruiting',
      },
    ],
    recordCount: 600,
    riskTrend: [
      {
        month: 'Jan',
        riskScore: 47,
      },
      {
        month: 'Feb',
        riskScore: 21,
      },
      {
        month: 'Mar',
        riskScore: 47,
      },
      {
        month: 'Apr',
        riskScore: 52,
      },
    ],
  },
]
