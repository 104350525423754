import {
  UpdateDataSystemGlobalCustomAttributeConfigurationSelectOptionRequestBodyDTO,
  UpdateDataSystemGlobalCustomAttributeConfigurationSelectOptionResponseBodyDTO,
} from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export interface UpdateDataSystemGlobalCustomAttributeOptionParams {
  attributeId: string
  optionId: string
  reqBody: UpdateDataSystemGlobalCustomAttributeConfigurationSelectOptionRequestBodyDTO
}

export const updateDataSystemGlobalCustomAttributeOption = ({
  attributeId,
  optionId,
  reqBody,
}: UpdateDataSystemGlobalCustomAttributeOptionParams) => {
  return API.put<UpdateDataSystemGlobalCustomAttributeConfigurationSelectOptionResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/data-systems/global-custom-attributes/${attributeId}/options/${optionId}`,
    }),
    reqBody,
  )
}
