import { useParams } from 'react-router-dom'
import { ASSETS_TYPES } from 'pages/assetManager/constants'
import { getURLParams } from 'utils/routing/routes/utils'

export const useDsrConfigInitiator = () => {
  const { assetType } = useParams<getURLParams>()
  const initiatorIsDatabase = assetType?.toLowerCase()?.indexOf(ASSETS_TYPES.DATABASE.toLowerCase()) !== -1
  const initiatorIsDataset = assetType?.toLowerCase()?.indexOf(ASSETS_TYPES.DATASET.toLowerCase()) !== -1
  return {
    initiatorIsDatabase,
    initiatorIsDataset,
  }
}
