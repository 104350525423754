import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces/common'

import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { ApiKeyFormSectionDetails } from './components/ApiKeyFormSectionDetails'
import { ApiKeyDTO } from 'interfaces/apiKeys'
import { getApiKeyFormValidation, getApiKeyFormInitialValues } from 'pages/developers/apiKeys/upsert/utils'

import { RoutesManager } from 'utils/routing/routesManager'
import { ApiKeyFormSectionRoles } from './components/ApiKeyFormSectionRoles'
import { ApiKeyRoleDTO } from 'interfaces/apiKeys/ApiKeyRoleDTO'
import { ApiKeyFormValues } from 'interfaces/apiKeys'
import { OrganizationDTO } from 'interfaces/organizations/organization'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'

type Props = {
  isReady: boolean
  isEditMode: boolean
  apiKey: MaybeNull<ApiKeyDTO>
  onSubmit: (values: ApiKeyFormValues) => Promise<void>
  roles: ApiKeyRoleDTO[]
  organization: MaybeNull<OrganizationDTO>
}

export const ApiKeyUpsertView: React.FC<Props> = ({ isReady, isEditMode, apiKey, onSubmit, roles, organization }) => {
  const navigate = useNavigate()

  const initialValues: ApiKeyFormValues = useMemo(
    () => getApiKeyFormInitialValues({ apiKey, isEditMode, organization }),
    [apiKey, isEditMode, organization],
  )
  const validationSchema = useMemo(getApiKeyFormValidation, [])

  const breadcrumbs = isEditMode
    ? [
        { title: 'Settings', link: RoutesManager.settings.root.getURL() },
        { title: 'API Keys', link: RoutesManager.developers.apiKeys.root.getURL() },
        {
          title: apiKey?.name,
          link: RoutesManager.developers.apiKeys.detail.getURL({ id: apiKey?.id }),
        },
        { title: 'Edit' },
      ]
    : [
        { title: 'Settings', link: RoutesManager.settings.root.getURL() },
        { title: 'API Keys', link: RoutesManager.developers.apiKeys.root.getURL() },
        { title: 'Create' },
      ]

  const formikProps = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
  })

  const { isSubmitting, submitForm } = formikProps

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={UpsertLayoutMode.edit}
          renderFormTitle={() => (
            <ContentGroup title={isEditMode ? 'Edit API Key' : 'Create API Key'} titleVariant="h2" isDivider />
          )}
          onAccept={submitForm}
          onCancel={() => {
            if (isEditMode) {
              navigate(RoutesManager.developers.apiKeys.detail.getURL({ id: apiKey?.id }))
            } else {
              navigate(RoutesManager.developers.apiKeys.root.getURL())
            }
          }}
          acceptActionButtonText="Save"
          cancelActionButtonText="Cancel"
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {isReady ? (
              <>
                <ApiKeyFormSectionDetails />
                <ApiKeyFormSectionRoles roles={roles} />
              </>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%" m={6}>
                <Spinner size="32px" thickness={2.5} />
              </Box>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
