import { Box } from '@mui/material'
import { BusinessFunctionDTO } from '@ketch-com/figurehead'
import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { FormikProvider, useFormik } from 'formik'
import { BusinessFunctionUpsertDetails } from './BusinessFunctionUpsertDetails'
import { BusinessFunctionUpsertPurposes } from './BusinessFunctionUpsertPurposes'
import { FormMode } from 'interfaces/formModes/formMode'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'
import { useBusinessFunction } from 'api/businessFunctions/queries/useBusinessFunction'
import { useNavigate, useParams } from 'react-router-dom'
import { useValidationSchema } from '../../utils/formHelpers'

type Props = {
  onSubmit: (bfn: any) => Promise<any>
}

export const BusinessFunctionEditLayout = ({ onSubmit }: Props) => {
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()
  const { data, isLoading } = useBusinessFunction({ params: { code } })
  const validationSchema = useValidationSchema({ isEditMode: true })

  const businessFunction = data as BusinessFunctionDTO

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Business Functions', link: RoutesManager.policyCenter.businessFunctions.root.getURL() },
    {
      title: 'Edit',
      link: RoutesManager.policyCenter.businessFunctions.upsert.root.getURL({ formMode: FormMode.EDIT, code }),
    },
  ]

  const formikProps = useFormik({
    initialValues: businessFunction,
    onSubmit: values => onSubmit({ params: { formData: values } }),
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  })

  const { isSubmitting, submitForm } = formikProps

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          renderFormTitle={() => <ContentGroup title="Edit Business Function" titleVariant="h2" isDivider />}
          mode={UpsertLayoutMode.edit}
          onAccept={submitForm}
          onCancel={() => {
            navigate(RoutesManager.policyCenter.businessFunctions.view.root.getURL({ code }))
          }}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          {!isLoading && Boolean(data) ? (
            <>
              <BusinessFunctionUpsertDetails />
              <BusinessFunctionUpsertPurposes />
            </>
          ) : (
            <Box display="flex" m="100px 0" alignItems="center" justifyContent="center">
              <Spinner size="32px" thickness={2.5} />
            </Box>
          )}
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
