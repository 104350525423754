import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { Text } from 'components/ui-kit/typography/Text'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { usePolicyV2 } from 'api/policies/queries/usePolicyV2'
import { useDeletePolicyV2 } from 'api/policies/mutations/useDeletePolicyV2'

type Props = {
  policyId: string
  onSubmit?: () => void
  onCancel: () => void
}

export const DeletePolicyModal: React.FC<Props> = ({ policyId, onSubmit = () => {}, onCancel }) => {
  const queryClient = useQueryClient()
  const [isBusy, setIsBusy] = useState(false)

  const { data: policy, isLoading: isLoadingPolicy } = usePolicyV2({
    params: {
      code: policyId,
    },
  })

  const { mutate: handleDeletePolicy } = useDeletePolicyV2({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'Policy deleted', type: 'success' })

      await queryClient.refetchQueries(ApiQueryKeys.policiesPaginated)
      onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to delete transponder', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <Dialog
      isLoading={isLoadingPolicy}
      isSaving={isBusy}
      title="Confirm Deletion"
      contentWidth={550}
      submitBtnLabel="Confirm"
      cancelBtnLabel="Cancel"
      onSubmitBtnClick={() => {
        handleDeletePolicy({
          params: {
            policyCode: policyId,
          },
        })
      }}
      onCancelBtnClick={onCancel}
    >
      <Text size={14}>
        Are you sure you want to delete <strong>{policy?.name}</strong>? You cannot reverse this action.
      </Text>
    </Dialog>
  )
}
