import { styled } from '@mui/system'

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'

export const EditButton = styled(EditIcon)(({ theme }) => ({
  color: theme.palette.fadedDarkGrey.main,
  cursor: 'pointer',
  height: 18,
  marginLeft: 5,
  width: 24,
}))
