import { useState } from 'react'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { AttributeConfiguration } from 'pages/assetManager/dsrConfig/upsert/components/DatasetConfiguration/components'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { DatasetPersonalDataType } from 'pages/assetManager/dsrConfig/upsert/components/DatasetConfiguration/components'
import { GetAssetHierarchiesResponseBodyDTO, AssetSummaryDTO } from '@ketch-com/figurehead'
import { getAssetType } from 'pages/assetManager/utils'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'

type Props = {
  includedDatasetIds: (string | undefined)[]
  assetsHierarchies: GetAssetHierarchiesResponseBodyDTO[]
  assetsSummary: AssetSummaryDTO[]
  identitySpaces: IdentitySpaceDTO[]
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme: { palette, spacing } }) => ({
    borderBottom: `1px solid ${palette.iron.main}`,
    '&:before': {
      display: 'none',
    },
    '.MuiAccordionSummary-root.Mui-expanded': {
      backgroundColor: palette.sphereFaded.main,
      borderBottom: `1px solid ${palette.sphere.main}`,
    },
  }),
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowRightIcon sx={{ width: 20, height: 20, color: 'darkDusk' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(1, 0, 1, 1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export const DatasetCollection: React.FC<Props> = ({
  includedDatasetIds,
  assetsHierarchies,
  assetsSummary,
  identitySpaces,
}) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const primaryAttributeAsset = assetsHierarchies?.[0]?.hierarchySummaries?.[0].asset
  const primaryAttributeAssetConnection = primaryAttributeAsset?.connection
  const technology = primaryAttributeAssetConnection?.technology || ''
  const providerCode = primaryAttributeAssetConnection?.providerCode || ''
  const datasetType = getAssetType(providerCode, 2)

  const dataSetType = (
    <Box display="flex" alignItems="center" gap={0.5} mt={0.5}>
      <img src={getAssetCloudImgUrl(technology)} alt={technology} width={15} height={15} />
      <Text size={11} weight={500} sx={{ textTransform: 'capitalize' }}>
        {datasetType}
      </Text>
    </Box>
  )

  const configuringDataSets = includedDatasetIds.flatMap(datasetId => {
    const selectedAsset = assetsHierarchies.filter(
      assetHierarchies =>
        assetHierarchies?.hierarchySummaries?.[0].asset?.resource?.hierarchies?.$parent?.id === datasetId,
    )[0]
    const selectedDataset = selectedAsset?.hierarchySummaries?.[0].asset?.resource?.hierarchies?.$parent

    return (
      <Accordion key={datasetId} expanded={expanded === datasetId} onChange={handleChange(datasetId!)}>
        <AccordionSummary>
          <Box display="flex" alignItems="center" width="100%" gap={1}>
            <Box flexBasis={282} overflow="hidden">
              <NameAndCodeCellRenderer name={selectedDataset?.name} code={dataSetType} />
            </Box>
            <Box flexGrow={1}>
              <DatasetPersonalDataType datasetId={selectedDataset?.id} assetsSummary={assetsSummary} />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <AttributeConfiguration assetHierarchies={selectedAsset} identitySpaces={identitySpaces} />
        </AccordionDetails>
      </Accordion>
    )
  })

  return (
    <>
      <Box display="flex" alignItems="center" mb={1.5}>
        <Text fontSize={12} fontWeight={600} flexBasis={318}>
          <strong>Name</strong>
        </Text>
        <Text fontSize={12} fontWeight={600} flexGrow={1}>
          <strong>Data Sensitivity</strong>
        </Text>
      </Box>
      <Box ml={-2} mr={-2}>
        {configuringDataSets}
      </Box>
    </>
  )
}
