import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useDebounce } from 'react-use'
import { Box, Typography } from '@mui/material'
import { InfoRow, Chip, Icon, IconDictionary, DropListButton, TextInput, Spinner, PopUp, Button } from '@ketch-com/deck'
import {
  TrackersCategory,
  PurposeDetail,
  AssignCategoryToPurposeResponse,
} from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { useUpsertTrackersCategoryPurposes } from 'api/trackers/mutations/useUpsertTrackersCategoryPurposes'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { TrackersSettingsContext } from 'pages/policyCenter/trackers/context'
import { RoutesManager } from 'utils/routing/routesManager'
import pluralize from 'pluralize'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { MaybeNull } from 'interfaces/common'
import { CategoryToPurposeResponse } from 'pages/policyCenter/trackers/trackersSettings/components'

enum UpdateMappings {
  SAVE = 'Save',
  DELETE = 'Delete',
}

type Props = {
  trackersCategory: TrackersCategory
}

export const CategoriesToPurposes: React.FC<Props> = ({ trackersCategory }) => {
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const navigate = useNavigate()
  const { allPurposeList } = useContext(TrackersSettingsContext)
  const [purposeListQuery, setPurposeListQuery] = useState('')
  const [updateMappings, setUpdateMappings] = useState<MaybeNull<UpdateMappings>>(null)
  const [categoryToPurposeResponse, setCategoryToPurposeResponse] =
    useState<MaybeNull<AssignCategoryToPurposeResponse>>(null)
  const queryClient = useQueryClient()
  const [purposes, setPurposes] = useState<PurposeDetail[]>(trackersCategory?.purposes || [])
  const [previousPurposes, setPreviousPurposes] = useState<PurposeDetail[]>(trackersCategory?.purposes || [])
  const [isEditingPurposes, setIsEditingPurposes] = useState(false)
  const [inputValue, setInputValue] = useState('')

  useDebounce(
    () => {
      if (inputValue) {
        setPurposeListQuery(inputValue)
      } else {
        setPurposeListQuery('')
      }
    },
    500,
    [inputValue],
  )

  useEffect(() => {
    setPurposes(trackersCategory?.purposes || [])
  }, [trackersCategory?.purposes])

  const { data: purposeList, isLoading: isLoadingPurposeList } = usePurposes({
    params: {
      query: purposeListQuery,
      limit: 100,
    },
  })

  const { mutateAsync: upsertTrackersCategoryPurposes, isLoading: isUpdatingTrackersCategoryPurposes } =
    useUpsertTrackersCategoryPurposes({
      onSuccess: async ({ data }) => {
        await queryClient.invalidateQueries([ApiQueryKeys.trackerCategories])
        if (data) {
          setCategoryToPurposeResponse(data)
          showToast({ content: 'Updated mappings', type: 'success' })
        }
      },
      onError: () => {
        showToast({ content: 'Failed to update mappings', type: 'error' })
      },
    })

  return (
    <Box my={2}>
      <InfoRow
        shouldEditOnContentClick={false}
        isEditable={isPermittedToWriteCookie ? true : false}
        title={trackersCategory?.name || ''}
        titleAddendum={`${purposes?.length || 0} ${pluralize('purpose', purposes?.length || 0)}`}
        isEditing={isEditingPurposes}
        onEditChange={() => {
          setIsEditingPurposes(true)
          setPreviousPurposes(purposes)
        }}
        onAcceptChange={() => {
          setUpdateMappings(UpdateMappings.SAVE)
        }}
        onCancelChange={() => {
          setIsEditingPurposes(false)
          setPurposes(previousPurposes)
        }}
        onDeleteChange={() => {
          setUpdateMappings(UpdateMappings.DELETE)
        }}
        isEmpty={!purposes?.length}
      >
        {categoryToPurposeResponse && !isEditingPurposes ? (
          <CategoryToPurposeResponse categoryToPurposeResponse={categoryToPurposeResponse} />
        ) : null}
        {isEditingPurposes ? (
          <Box mb={2}>
            <DropListButton
              key={purposes?.length}
              value={null}
              size="small"
              disabled={isUpdatingTrackersCategoryPurposes}
              loading={isLoadingPurposeList}
              filterOptions={x => x}
              clearOnBlur
              noOptionsText="No Purposes"
              getOptionDisabled={option => purposes?.some(purpose => purpose?.id === option?.ID)}
              onChange={(e, selectedPurpose) => {
                if (selectedPurpose)
                  setPurposes([
                    ...purposes,
                    {
                      name: selectedPurpose?.name,
                      id: selectedPurpose?.ID,
                      code: selectedPurpose?.code,
                    },
                  ])
                setInputValue('')
              }}
              onInputChange={(e, inputVal) => {
                setInputValue(inputVal)
              }}
              options={purposeList}
              renderInput={params => (
                <TextInput
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  placeholder="Add Purpose"
                  variant="outlined"
                />
              )}
              renderOption={(props, purpose) => {
                return (
                  <Box
                    component="li"
                    {...props}
                    key={purpose?.code}
                    display="flex"
                    flexDirection="column"
                    gap={0.5}
                    py={2}
                  >
                    <Typography alignSelf="flex-start" variant="body">
                      {purpose?.name}
                    </Typography>
                    <Typography alignSelf="flex-start" variant="footnote" color="darkDuskFaded.main">
                      {purpose?.code}
                    </Typography>
                  </Box>
                )
              }}
              getOptionLabel={option => option?.name || ''}
              isOptionEqualToValue={(option, value) => option?.code === value?.code}
            />
          </Box>
        ) : null}

        <Box mb={2}>
          {isUpdatingTrackersCategoryPurposes ? (
            <Box display="flex" justifyContent="center" alignItems="center" my={4}>
              <Spinner size={20} thickness={2} />
            </Box>
          ) : (
            <Box display="flex" alignItems="flex-start" flexWrap="wrap" gap={1}>
              {purposes?.length ? (
                purposes?.map((purpose, index) => {
                  const purposeCode = allPurposeList.filter(p => p?.ID === purpose?.id)?.[0]?.code
                  if (!isEditingPurposes) {
                    return (
                      <Chip
                        key={index}
                        clickable
                        label={purpose?.name}
                        deleteIcon={<Icon name="OArrowCRight" />}
                        onClick={() => {
                          if (purposeCode) {
                            navigate(
                              RoutesManager.policyCenter.purposes.view.overview.root.getURL({ code: purposeCode }),
                            )
                          }
                        }}
                        onDelete={() => {
                          if (purposeCode) {
                            navigate(
                              RoutesManager.policyCenter.purposes.view.overview.root.getURL({ code: purposeCode }),
                            )
                          }
                        }}
                      />
                    )
                  } else {
                    return (
                      <Chip
                        key={index}
                        label={purpose?.name}
                        deleteIcon={<Icon name={IconDictionary.OCross} />}
                        onDelete={() => {
                          const newArray = purposes?.filter(listedPurpose => listedPurpose?.id !== purpose?.id)
                          setPurposes(newArray)
                        }}
                      />
                    )
                  }
                })
              ) : (
                <Typography>None</Typography>
              )}
            </Box>
          )}
        </Box>
      </InfoRow>
      {updateMappings ? (
        <PopUp
          title="Update Mappings"
          popUpWidth="400px"
          onClose={() => setUpdateMappings(null)}
          popUpActionChildren={
            <>
              <Button
                color="secondary"
                size="large"
                onClick={() => {
                  setUpdateMappings(null)
                }}
                pending={isUpdatingTrackersCategoryPurposes}
                disabled={isUpdatingTrackersCategoryPurposes}
              >
                Close
              </Button>
              <Button
                color="primary"
                size="large"
                onClick={() => {
                  if (updateMappings === UpdateMappings.SAVE)
                    upsertTrackersCategoryPurposes({
                      params: {
                        categoryCode: trackersCategory?.code || '',
                        data: {
                          purposeIDs: purposes?.map(p => p?.id || ''),
                        },
                      },
                      onSuccess: () => {
                        setIsEditingPurposes(false)
                        setUpdateMappings(null)
                      },
                    })

                  if (updateMappings === UpdateMappings.DELETE)
                    upsertTrackersCategoryPurposes({
                      params: {
                        categoryCode: trackersCategory?.code || '',
                        data: {
                          purposeIDs: [],
                        },
                      },
                      onSuccess: () => {
                        setPurposes([])
                        setIsEditingPurposes(false)
                        setUpdateMappings(null)
                      },
                    })
                }}
                pending={isUpdatingTrackersCategoryPurposes}
                disabled={isUpdatingTrackersCategoryPurposes}
              >
                Save & Update
              </Button>
            </>
          }
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="body">
              Due to your recent settings changes, we recommend updating your trackers association with purposes.
            </Typography>
            <Typography variant="label">Please confirm if you wish to update.</Typography>
          </Box>
        </PopUp>
      ) : null}
    </Box>
  )
}
