import { GetTeam200 } from 'interfaces/services/harbormaster/teams_gen.schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchTeam = ({ id }: { id?: string }) =>
  API.get<GetTeam200>(
    formatRequestString({
      entityUrl: `/api/teams/${id ? decodeURIComponent(id) : '-1'}`,
      params: {
        includeDetails: true,
      },
    }),
  )
