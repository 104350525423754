import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { checkIfBusinessFunctionCodeAvailable } from 'api/businessFunctions/fetchers/checkIfBusinessFunctionCodeAvailable'

const useCustomMutation = createUseMutation({
  mutationFn: checkIfBusinessFunctionCodeAvailable,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCheckIfBusinessFunctionCodeAvailable = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
