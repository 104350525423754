import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import { FormCookieApplicationsCheckbox } from './FormCookieApplicationsCheckbox'
import { SENSITIVE_APPLICATION_STATUSES } from 'interfaces/applications/applicationStatus'
import { ApplicationDTO } from 'interfaces/applications/application'

export const cookieApplicationsGridColumns: GridColDef<ApplicationDTO>[] = [
  {
    field: 'used',
    headerName: '',
    sortable: false,
    maxWidth: 50,
    renderCell: ({ row }: GridRenderCellParams<ApplicationDTO>) => {
      return <FormCookieApplicationsCheckbox row={row} />
    },
  },
  {
    align: 'left',
    field: 'name',
    headerAlign: 'left',
    headerName: 'Name',
    flex: 0.3,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ApplicationDTO>) => {
      return <TableCell title={row.name} subTitle={row.code} sx={{ margin: '8px 0' }}></TableCell>
    },
  },
  {
    align: 'left',
    field: 'deploymentStatus',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Deployment Status',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ApplicationDTO>) => {
      return (
        <TableCell>
          {SENSITIVE_APPLICATION_STATUSES.includes(row.status) ? (
            <Status icon="Dot" status={StatusState.success} variant={StatusVariant.ghost} label="Deployed" />
          ) : (
            <Status icon="Dot" status={StatusState.draft} variant={StatusVariant.ghost} label="Undeployed" />
          )}
        </TableCell>
      )
    },
  },
]
