import { UserFormDTO } from 'interfaces/users/users'
import { NONE, NO_PERMISSION } from '../../constants'
import { UserFormValues } from './interfaces'

type Args = { values: UserFormValues }

export const getUserFormPayload = ({ values }: Args): UserFormDTO => ({
  firstName: values.firstName,
  lastName: values.lastName,
  email: values.email,
  roles: Object.values(values.roles)
    .filter(v => v !== NONE)
    .filter(v => v !== NO_PERMISSION)
    .map(v => ({ code: v })),
})
