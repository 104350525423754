import { useParams } from 'react-router-dom'
import { FormMode } from '../../../../../interfaces/formModes/formMode'
import { useNavigate } from 'react-router'
import { useThemeValidationSchema } from './useThemeValidationSchema'
import { ThemeType } from './enums'
import { useMemo, useState } from 'react'
import { ThemeBuilderSection } from '../../../experiences-v2/upsert/components/builder/utils/enums'
import { useCreateTheme } from 'api/themes-v3/mutations/useCreateTheme'
import { useUpdateTheme } from 'api/themes-v3/mutations/useUpdateTheme'
import { RoutesManager } from 'utils/routing/routesManager'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { useTheme } from 'api/themes-v3/queries/useTheme'
import { PreferenceCenterPage } from '@ketch-sdk/ketch-types'
import { getThemeSummary } from './utils'
import { showToast } from 'components/modals/AlertComponent'
import { defaultThemeV3DTO } from './defaults'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'
import { useIsEntitled } from 'utils/hooks'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

export const useThemeUpsertUtils = () => {
  // Get URL param info
  const { id, formMode } = useParams<{
    id?: string
    formMode: FormMode
  }>()
  const isEditMode = formMode === FormMode.EDIT

  // Utils
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()

  // Get entitlement info
  const hasPurposesEntitlement = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)
  const hasSubscriptionsEntitlement = isEntitled(ENTITLEMENTS.SUBSCRIPTIONS)
  const hasRightsEntitlement = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)
  const entitledPreferencePages: PreferenceCenterPage[] = useMemo(() => {
    return [
      PreferenceCenterPage.Welcome,
      ...(hasPurposesEntitlement ? [PreferenceCenterPage.Purpose] : []),
      ...(hasSubscriptionsEntitlement ? [PreferenceCenterPage.Subscriptions] : []),
      ...(hasRightsEntitlement ? [PreferenceCenterPage.RequestsHome] : []),
      ...(hasRightsEntitlement ? [PreferenceCenterPage.RequestsForm] : []),
      ...(hasRightsEntitlement ? [PreferenceCenterPage.RequestsSubmitted] : []),
    ]
  }, [hasPurposesEntitlement, hasSubscriptionsEntitlement, hasRightsEntitlement])

  // States
  const [themeType, setThemeType] = useState<ThemeType>(ThemeType.Banner)
  const [themeBuilderSection, setThemeBuilderSection] = useState<ThemeBuilderSection | undefined>()
  const [preferencePage, setPreferencePage] = useState<PreferenceCenterPage>(PreferenceCenterPage.Welcome)

  // API Hooks
  const { data: theme, isLoading: isThemeLoading } = useTheme({
    enabled: isEditMode,
    params: {
      id: id || '',
      includeMetadata: true,
    },
    onError: () => navigate(RoutesManager.deployment.themesV3.root.getURL()),
  })
  const { mutateAsync: handleCreateTheme, isLoading: isThemeCreateLoading } = useCreateTheme({
    onSuccess: ({ data }) => {
      navigate(
        data?.id
          ? RoutesManager.deployment.themesV3.view.preview.root.getURL({ id: data.id })
          : RoutesManager.deployment.themesV3.root.getURL(),
      )
      showToast({ content: 'Theme Created', type: 'success' })
    },
    onError: e => {
      showToast({ content: 'Failed to create theme', type: 'error' })
    },
  })
  const { mutateAsync: handleUpdateTheme, isLoading: isThemeUpdateLoading } = useUpdateTheme({
    onSuccess: ({ data }) => {
      navigate(
        data?.id
          ? RoutesManager.deployment.themesV3.view.preview.root.getURL({ id: data.id })
          : RoutesManager.deployment.themesV3.root.getURL(),
      )
      showToast({ content: 'Theme Updated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update theme', type: 'error' })
    },
  })
  const handleUpsertTheme = formMode === FormMode.CREATE ? handleCreateTheme : handleUpdateTheme

  // Form config
  const initialValues = isEditMode && theme ? theme : defaultThemeV3DTO
  const validationSchema = useThemeValidationSchema()
  const onSubmit = async (formData: ThemeV3DTO) => {
    // Compute theme summary
    const summary = getThemeSummary(formData)
    const formDataWithSummary: ThemeV3DTO = {
      ...formData,
      summary,
    }

    logObjectDetails(formDataWithSummary, 'useThemeUpsertUtils.ts')

    // Upsert theme
    await handleUpsertTheme({
      params: {
        formData: formDataWithSummary,
        id: id || '',
      },
    })
  }

  return {
    id,
    isEditMode,
    isThemeLoading,
    formMode,
    navigate,
    initialValues,
    isThemeCreateLoading,
    isThemeUpdateLoading,
    validationSchema,
    onSubmit,
    themeType,
    setThemeType,
    themeBuilderSection,
    setThemeBuilderSection,
    preferencePage,
    setPreferencePage,
    entitledPreferencePages,
  }
}

export type UseThemeUpsertUtilsReturnType = ReturnType<typeof useThemeUpsertUtils>
