import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Text } from 'components/ui-kit/typography/Text'
import { GetDSRConfigCountResponseBodyDTO, ScenarioDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { ScenarioStats, ScenarioAssetConfiguration } from 'pages/assetManager/scenario/list/components'

const PREFIX = 'ScenarioStats'

const classes = {
  rightsConfiguration: `${PREFIX}-rights-configuration`,
}

const Root = styled(Box)(({ theme: { spacing, palette } }) => ({
  [`& .${classes.rightsConfiguration}`]: {
    backgroundColor: palette.superLightGrey.main,
    margin: spacing(1, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(1.5),
    borderRadius: spacing(1.5),
  },
}))

type Props = {
  scenario: ScenarioDTO
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
  isDatasetScenario?: boolean
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export const Scenario: React.FC<Props> = ({ scenario, dsrConfigCount, assetSummary, isDatasetScenario }) => {
  return (
    <Root>
      <Box className={classes.rightsConfiguration}>
        <Text size={14}>{scenario.name}</Text>
        {isDatasetScenario ? (
          <ScenarioAssetConfiguration scenario={scenario} assetSummary={assetSummary} />
        ) : (
          <ScenarioStats scenario={scenario} dsrConfigCount={dsrConfigCount} assetSummary={assetSummary} />
        )}
      </Box>
    </Root>
  )
}
