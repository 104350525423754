import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Statement = {
  [key: string]: { effect?: string; value?: string; assetType?: string; parentAssetCode?: string }
}

export type DsrFormPolicyStatements = {
  [key: string]: {
    id?: string | undefined
    resource: string
    statements?: Statement
  }
}

export type AttributeUpdateErrors = {
  [key: string]: {
    attributeCode?: string
    message?: string
    error?: string
    isValid?: boolean
  }
}

interface DsrTransponderFormState {
  selectedDatabases: string[]
  policyStatements: DsrFormPolicyStatements
  isConfigureDatasetAttributeModalValid: boolean
  attributeUpdateErrors: AttributeUpdateErrors
  code: string
  description: string
}

const initialState = {
  selectedDatabases: [],
  policyStatements: {},
  isConfigureDatasetAttributeModalValid: true,
  attributeUpdateErrors: {},
  code: 'ketch_transponder',
  description: 'Transponder',
} as DsrTransponderFormState

export const dsrTransponderFormSlice = createSlice({
  name: 'dsrTransponderFormSlice',
  initialState,
  reducers: {
    hydrateFormState: (state, action: PayloadAction<DsrTransponderFormState>) => {
      if (action.payload.selectedDatabases.length) {
        state.selectedDatabases = action.payload.selectedDatabases
      }
      if (Object.keys(action.payload.policyStatements).length) {
        state.policyStatements = action.payload.policyStatements
      }
      state.code = action.payload.code
      state.description = action.payload.description
    },

    setSelectedDatabases: (state, action: PayloadAction<string[]>) => {
      state.selectedDatabases = action.payload
    },
    clearDatabases: state => {
      state.selectedDatabases = []
    },

    setPolicyStatements: (state, action: PayloadAction<DsrFormPolicyStatements>) => {
      state.policyStatements = action.payload
    },
    selectDataset: (state, action: PayloadAction<DsrFormPolicyStatements>) => {
      state.policyStatements = action.payload
    },

    deselectDataset: (state, action: PayloadAction<DsrFormPolicyStatements>) => {
      state.policyStatements = action.payload
    },
    updateAttributeStatement: (state, action: PayloadAction<DsrFormPolicyStatements>) => {
      state.policyStatements = action.payload
    },

    setIsConfigureDatasetAttributeModalValid: (state, action: PayloadAction<boolean>) => {
      state.isConfigureDatasetAttributeModalValid = action.payload
    },

    setAttributeUpdateErrors: (state, action: PayloadAction<AttributeUpdateErrors>) => {
      state.attributeUpdateErrors = action.payload
    },

    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload
    },

    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload
    },

    clearAttributeUpdateErrors: state => {
      state.attributeUpdateErrors = {}
    },
  },
})

export const {
  clearAttributeUpdateErrors,
  clearDatabases,
  deselectDataset,
  hydrateFormState,
  selectDataset,
  setIsConfigureDatasetAttributeModalValid,
  setPolicyStatements,
  setSelectedDatabases,
  setAttributeUpdateErrors,
  setCode,
  setDescription,
  updateAttributeStatement,
} = dsrTransponderFormSlice.actions

export default dsrTransponderFormSlice.reducer
