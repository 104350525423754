import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { updateProcessingActivityDataSystemConsentStatus } from '../fetchers/updateProcessingActivityDataSystemConsentStatus'

const useCustomMutation = createUseMutation({
  mutationFn: updateProcessingActivityDataSystemConsentStatus,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateProcessingActivityDataSystemConsentStatus = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
