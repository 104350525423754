import React, { SyntheticEvent, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { TabProps, ViewLayout } from '@ketch-com/deck'
import { RoutesManager } from 'utils/routing/routesManager'
import { TrackersSettingsContext, withTrackersSettingsContext } from 'pages/policyCenter/trackers/context'
import { CategoriesMapping } from 'pages/policyCenter/trackers/trackersSettings/components'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { DiscoverySettings } from './components/DiscoverySettings'
import { useFeatureFlag, useIsPermitted } from 'utils/hooks'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

export enum TrackerSettingsTab {
  Mapping,
  DiscoverySettings,
}

const TAB_ITEMS: TabProps[] = [
  {
    label: 'Category Mapping',
    value: TrackerSettingsTab.Mapping,
  },
  {
    label: 'Blocking',
    value: TrackerSettingsTab.DiscoverySettings,
  },
]

const TrackersSettingsWithContext: React.FC = () => {
  const navigate = useNavigate()
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const { currentTab, setCurrentTab } = useContext(TrackersSettingsContext)
  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }
  const isTrackerBlockingRelease =
    getIsPermitted([PERMISSIONS.DEMO]) ||
    isFeatureFlagEnabled(
      FEATURE_FLAGS.PROPERTY_TAGS_TRACKER_BLOCKING,
      FEATURE_FLAGS_VALUES.PROPERTY_TAGS_TRACKER_BLOCKING_ENABLED,
    )

  return (
    <>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
          { title: 'Trackers', link: RoutesManager.policyCenter.trackers.list.approvedTrackers.root.getURL() },
          { title: 'Settings' },
        ]}
      />
      <ViewLayout
        tabsComponentProps={
          isTrackerBlockingRelease ? { value: currentTab, onChange: handleTabChange, tabItems: TAB_ITEMS } : undefined
        }
        contentGroupProps={{
          showBackButton: true,
          title: 'Trackers Settings',
          propsBackButton: {
            onClick: () => {
              navigate(RoutesManager.policyCenter.trackers.list.approvedTrackers.root.getURL())
            },
          },
        }}
      >
        {!isTrackerBlockingRelease || currentTab === TrackerSettingsTab.Mapping ? (
          <CategoriesMapping />
        ) : (
          <DiscoverySettings />
        )}
      </ViewLayout>
    </>
  )
}

export const TrackersSettings = withTrackersSettingsContext(TrackersSettingsWithContext)
