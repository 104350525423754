import { Avatar, AvatarSize, ContentGroup, FormRow } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import { BusinessFunctionFormValues } from '../../utils/formHelpers'
import { ColorMapping, colorMapping, getTextColorForTile } from '../../utils/colorMappings'
import { formatCodeFromName } from 'utils/formatters'
import { FormInput } from 'components/form/FormInput'
import { FormMode } from 'interfaces/formModes/formMode'
import { FormOptionActionSheet } from '../../../../../components/form/FormOptionActionSheet'

export const BusinessFunctionUpsertDetails = () => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext<BusinessFunctionFormValues>()
  const { formMode } = useParams<{ formMode: FormMode }>()
  return (
    <FormRow title="Basic Details">
      <Box mb={3} display="flex" alignItems="start">
        <Box mr={6} width={450}>
          <FormInput
            fullWidth
            required
            formPropertyName="name"
            label="Name"
            placeholder="Example: Marketing"
            shouldUpdateDebounced
            onChange={e => {
              if (formMode !== FormMode.EDIT) {
                setFieldTouched('code', true)
                setFieldValue('code', formatCodeFromName(e.target.value))
              }
            }}
          />
        </Box>
        <Box mr={1.25}>
          <FormInput
            fullWidth
            required
            formPropertyName="label"
            label={
              <>
                <Typography variant="label">Label&nbsp;</Typography>
                <Typography color="darkGrey.main" variant="label">
                  (3 letters limit)
                </Typography>
              </>
            }
            placeholder="Example: XX"
            onChange={e => {
              if (e.target.value?.length > 3) {
                setFieldValue('label', e.target.value.substring(0, 3))
              }
            }}
          />
        </Box>
        <Box mr={1.25}>
          <FormOptionActionSheet
            buttonProps={{
              color: 'tertiary',
              size: 'large',
            }}
            valueKey="backgroundColor"
            formPropertyName="labelColor"
            items={colorMapping}
            label="Label Color"
            renderItem={(item: ColorMapping) => (
              <Box
                borderRadius={64}
                height={24}
                width={24}
                bgcolor={item.backgroundColor}
                display="flex"
                justifyContent="center"
                alignItems="center"
              />
            )}
          />
        </Box>
        <Box mt={2.5}>
          <Avatar
            size={AvatarSize.xlarge}
            textColor={getTextColorForTile(values.labelColor) || '#333'}
            backgroundColor={values.labelColor}
          >
            {values.label || 'ABC'}
          </Avatar>
        </Box>
      </Box>
      <Box mb={3} width={450}>
        <FormInput
          fullWidth
          required
          formPropertyName="code"
          label="Code"
          disabled={formMode === FormMode.EDIT}
          placeholder="Example: marketing"
        />
      </Box>
      <Box mb={6} width={700}>
        <FormInput
          shouldUpdateDebounced
          fullWidth
          multiline
          rows={3}
          formPropertyName="description"
          label="Description"
          placeholder="Example: Our marketing team and advertising unit."
        />
      </Box>
      <Box
        sx={{
          borderTop: theme => `1px solid ${theme.palette.iron.main}`,
          paddingTop: '16px',
        }}
      >
        <Box mb={3}>
          <ContentGroup
            subTitle="Provide contact information for function lead."
            titleCustomComponent={
              <Box display="flex" alignItems="center">
                <Typography variant="h3">Contact&nbsp;</Typography>
                <Typography variant="h3" color="darkGrey.main">
                  {'(Optional)'}
                </Typography>
              </Box>
            }
          />
        </Box>
        <Box width={724} mb={3} display="flex">
          <Box width={350} mr={3}>
            <FormInput
              shouldUpdateDebounced
              required
              fullWidth
              formPropertyName="contactFirstName"
              label="First Name"
              placeholder="Example: John"
            />
          </Box>
          <Box width={350}>
            <FormInput
              shouldUpdateDebounced
              required
              fullWidth
              formPropertyName="contactLastName"
              label="Last Name"
              placeholder="Example: Doe"
            />
          </Box>
        </Box>
        <Box display="flex" width={724}>
          <Box width={350} mr={3}>
            <FormInput
              shouldUpdateDebounced
              required
              fullWidth
              formPropertyName="contactEmail"
              label="Email"
              placeholder="Example: email@gmail.com"
            />
          </Box>
          <Box width={350}>
            <FormInput
              shouldUpdateDebounced
              required
              fullWidth
              formPropertyName="contactTitle"
              label="Title"
              placeholder="Example: CSO"
            />
          </Box>
        </Box>
      </Box>
    </FormRow>
  )
}
