import React from 'react'
import { useNavigate } from 'react-router-dom'

import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { SENSITIVE_DEPLOYMENT_PLANS_STATUSES } from 'interfaces/deploymentPlans-v2/deploymentPlanStatus'
import { Pagination } from 'api/common/paginatedQuery'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'

import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ViewListLayout } from 'components/ui-layouts/viewListLayout/ViewListLayout'
import { ViewListLayoutContent } from 'components/ui-layouts/viewListLayout/ViewListLayoutContent'
import { ViewListLayoutHeader } from 'components/ui-layouts/viewListLayout/ViewListLayoutHeader'
import { IssuesCellRenderer } from 'components/ui-layouts/table/cellRenderers/IssuesCellRenderer'
import { Button } from 'components/ui-kit/button/Button'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { Status } from 'components/ui-kit/status/Status'
import { Badge } from 'components/ui-kit/badge/Badge'
import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { DeploymentPlansListDropdown } from 'pages/consentAndRights/deploymentPlans-v2/list/DeploymentPlansListDropdown'

import { ReactComponent as EmptyStateDeploymentPlansIcon } from 'assets/icons/emptyState/EmptyStateDeploymentPlans.svg'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FirstNameLastNameRenderer } from 'components/renderers/FirstNameLastNameRenderer'

type Props = {
  deploymentPlans: DeploymentPlanDTO[]
  isLoading: boolean
  isFetching: boolean
  pagination: Pagination
}

export const DeploymentPlansList: React.FC<Props> = ({ isLoading, isFetching, deploymentPlans, pagination }) => {
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Properties', link: RoutesManager.deployment.propertiesHome.root.getURL() },
    { title: 'Deployments', link: RoutesManager.deployment.deploymentPlansV2.root.getURL() },
  ]

  const isPermittedToCreateDeploymentPlans = isPermitted(PERMISSIONS.DEPLOY_WRITE)
  const isEntitledToCreateDeploymentPlans = isEntitled(ENTITLEMENTS.QTY_DEPLOYMENT_PLAN)
  const canCreateDeploymentPlans = isPermittedToCreateDeploymentPlans && isEntitledToCreateDeploymentPlans

  const handleCreateDeploymentPlanRedirect = () => {
    navigate(RoutesManager.deployment.deploymentPlansV2.upsert.root.getURL({ code: NEW_ITEM_CODE }))
  }

  return (
    <ViewListLayout>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ViewListLayoutHeader
        title={''}
        borderBottom={'none'}
        marginBottom={24}
        paddingBottom={0}
        actions={
          isPermittedToCreateDeploymentPlans && (
            <Button
              disabled={!isEntitledToCreateDeploymentPlans}
              tooltip={
                !isEntitledToCreateDeploymentPlans
                  ? 'No deployment plans entitlements remaining, please delete one or contact your admin for more'
                  : null
              }
              onClick={handleCreateDeploymentPlanRedirect}
            >
              Create Plan
            </Button>
          )
        }
      />

      <ViewListLayoutContent>
        {!isFetching && !deploymentPlans.length ? (
          <EmptyState
            variant="list"
            icon={<EmptyStateDeploymentPlansIcon />}
            title="There are no deployment plans for this organization"
            description={canCreateDeploymentPlans ? 'Create your first one to populate the table.' : null}
            actions={[
              {
                title: 'Create Plan',
                hidden: !canCreateDeploymentPlans,
                size: 'big',
                onClick: handleCreateDeploymentPlanRedirect,
              },
            ]}
          />
        ) : (
          <TablePaginated
            rowIdKey="ID"
            pending={isLoading}
            items={deploymentPlans}
            onRowClick={({ code }) => {
              navigate(RoutesManager.deployment.deploymentPlansV2.view.root.getURL({ code }))
            }}
            pagination={pagination}
            cellSettings={{
              issues: {
                width: 35,
                cellRenderer: ({ hasIssues }) => (
                  <IssuesCellRenderer hasIssues={hasIssues} entityType={ApplicationEntity.DEPLOYMENT_PLAN} />
                ),
              },
              deploymentPlan: {
                label: 'Deployment Plan',
                cellRenderer: ({ name, code }) => <NameAndCodeCellRenderer name={name} code={code} />,
              },
              deployments: {
                label: 'Deployments',
                width: 189,
                cellRenderer: ({ applicationEnvironments }) =>
                  applicationEnvironments?.length ? (
                    <Tooltip
                      position="bottom"
                      content={
                        <ul>
                          {applicationEnvironments.map(({ ID, applicationName, code }) => (
                            <li key={ID}>
                              {applicationName} ({code})
                            </li>
                          ))}
                        </ul>
                      }
                    >
                      <Badge>{applicationEnvironments.length}</Badge>
                    </Tooltip>
                  ) : (
                    <EmptyValueRenderer />
                  ),
              },
              status: {
                label: 'Status',
                width: 217,
                cellRenderer: ({ status }) =>
                  SENSITIVE_DEPLOYMENT_PLANS_STATUSES.includes(status) ? (
                    <Status variant="active">Deployed</Status>
                  ) : (
                    <Status variant="inactive">Undeployed</Status>
                  ),
              },
              lastUpdated: {
                label: 'Last Updated',
                width: 180,
                cellRenderer: ({ metadata }) => <FormattedDateRenderer date={metadata?.updatedAt} />,
              },
              updatedBy: {
                label: 'Updated By',
                width: 200,
                cellRenderer: ({ audit }) => (
                  <FirstNameLastNameRenderer
                    firstName={audit?.updatedBy?.firstName}
                    lastName={audit?.updatedBy?.lastName}
                  />
                ),
              },
              dropDown: {
                width: 66,
                cellRenderer: deploymentPlan => <DeploymentPlansListDropdown deploymentPlan={deploymentPlan} />,
              },
            }}
          />
        )}
      </ViewListLayoutContent>
    </ViewListLayout>
  )
}
