import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/system'
import { Box } from '@mui/material'

import { RedactionContext } from '../context/RedactionContext'
import { RedactionHeaderActions } from './RedactionHeaderActions'
import { RedactionHeaderDetails } from './RedactionHeaderDetails'
import { ContentGroup } from '@ketch-com/deck'

const IconContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.heliotrope.main,
  borderRadius: '10px',
  display: 'flex',
  height: '48px',
  width: '48px',
  justifyContent: 'center',
}))

type Props = {}

export const RedactionHeader: React.FC<Props> = () => {
  const navigate = useNavigate()
  const {
    dataReviewSteps,
    handleSaveFiles,
    invalidFormatFiles,
    isSaving,
    rightInvocation,
    workflowExecutionStep,
    workflowActivity,
    totalRedactionCount,
  } = useContext(RedactionContext)

  return (
    <Box width="100%" p="0 12px 12px 12px" mt="-12px">
      <Box
        sx={{
          backgroundColor: theme => theme.palette.white.main,
          borderRadius: '11px',
          paddingTop: theme => theme.spacing(2),
          paddingBottom: theme => theme.spacing(2),
          paddingLeft: theme => theme.spacing(2.25),
          paddingRight: theme => theme.spacing(2.25),
        }}
      >
        <ContentGroup
          variant="inner-page-header"
          showBackButton
          propsBackButton={{
            onClick: () => navigate(-1),
          }}
          title="Data Redaction"
          imageBlockComponent={
            <IconContainer>
              <img src={workflowActivity?.icon?.URL} alt="" />
            </IconContainer>
          }
          infoBlockComponent={<RedactionHeaderDetails workflowStep={workflowExecutionStep!} />}
          actionRightBlockComponent={
            <RedactionHeaderActions
              dataReviewSteps={dataReviewSteps}
              invalidFormatFiles={invalidFormatFiles}
              isSaving={isSaving}
              rightInvocation={rightInvocation!}
              saveFiles={handleSaveFiles}
              totalRedactionCount={totalRedactionCount}
              workflowExecutionStep={workflowExecutionStep!}
            />
          }
        />
      </Box>
    </Box>
  )
}
