import React, { useState } from 'react'
import { Box, Collapse, SvgIcon, Typography } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useTheme } from '@mui/styles'

const useThemedStyles = ({ isExpanded }: { isExpanded: boolean }) => {
  const theme = useTheme()

  return {
    containerSx: {
      backgroundColor: 'white.main',
      borderRadius: 1.375,
      p: 2.5,
      mb: 2,
    },
    withPointerSx: {
      cursor: 'pointer',
    },
    chevronSx: {
      color: 'darkDusk.main',
      fontSize: theme.typography.pxToRem(20),
      transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
      transition: 'transform 0.2s ease-in-out',

      '& > path': {
        fill: theme.palette.darkDusk.main,
      },
    },
  }
}

type Props = {
  title: string
  subTitle?: string
  isCollapsible?: boolean
  content: React.ReactNode
  fallback: React.ReactNode
}

export const StepDetailsTile: React.FC<Props> = ({ title, subTitle, isCollapsible, content, fallback }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const { withPointerSx, chevronSx, containerSx } = useThemedStyles({ isExpanded })

  return (
    <Box sx={containerSx}>
      {isCollapsible ? (
        <>
          {/* Header */}
          <Box display="flex" alignItems="center" onClick={() => setIsExpanded(b => !b)} sx={withPointerSx}>
            <Box display="flex" alignItems="center" pr={1.875} sx={withPointerSx}>
              <SvgIcon component={ChevronRightIcon} inheritViewBox sx={chevronSx} />
            </Box>

            <Box sx={withPointerSx}>
              <Typography variant="h4" display="block" sx={withPointerSx}>
                {title}
              </Typography>

              <Typography
                display={subTitle ? undefined : 'none'}
                variant="body"
                color="darkDuskFaded.main"
                sx={withPointerSx}
              >
                {subTitle}
              </Typography>
            </Box>
          </Box>

          {/* Content */}

          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box pt={2.5}>{content}</Box>
          </Collapse>
        </>
      ) : (
        <>
          <Box>
            <Typography variant="h4" display="block">
              {title}
            </Typography>

            <Typography display={subTitle ? undefined : 'none'} variant="body" color="darkDuskFaded.main">
              {subTitle}
            </Typography>
          </Box>

          <Box pt={2.5}>{fallback}</Box>
        </>
      )}
    </Box>
  )
}
