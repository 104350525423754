import React from 'react'

import { CookiesList } from './CookiesList'
import { useCookiesListContainerData } from './utils'

export const CookiesListContainer: React.FC = () => {
  const { cookies, isLoading, isFetching, hasNextPage } = useCookiesListContainerData()

  return <CookiesList cookies={cookies} isLoading={isFetching || isLoading || !!hasNextPage} />
}
