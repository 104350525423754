import { APIListRequestParams, OrderBy } from 'api/common/utils'
import { ContextVariableCategoryDTO, FetchContextVariablesResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  category?: ContextVariableCategoryDTO
}>

export const fetchContextVariablesPaginated = ({ start, limit, includeMetadata, query, category, ordering }: Params) =>
  API.get<FetchContextVariablesResponseBodyDTO>(
    formatRequestString({
      entityUrl: '/api/context-variables',
      params: {
        filters: {
          start,
          limit,
          includeMetadata,
          query,
          ...(category && { category }),
          ordering: ordering || OrderBy.CREATED_AT,
        },
      },
    }),
  )
