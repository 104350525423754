import { uniqBy } from 'lodash'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { getSiblingsFromStep } from 'pages/orchestration/workflows/edit/utils/steps/siblingsFromStep'
import { getTransitionToStep } from 'pages/orchestration/workflows/edit/utils/steps/transitionsToStep'

interface IGetStepsBeforeStep {
  step: ICanvasStep
  steps: Array<ICanvasStep>
}

export const getStepsBeforeStep = (props: IGetStepsBeforeStep): ICanvasStep[] => {
  const { step, steps } = props
  const { parentStep } = getSiblingsFromStep({ step, steps })

  if (!parentStep || parentStep?.start) {
    return []
  }

  // In case of JOIN we will receive all transitions which lead to it and will get list
  // of all their children and children of their children
  if (parentStep?.gateway?.mode === WorkflowActivityGatewayMode.JOIN) {
    const transitionsToJoin = getTransitionToStep({ step: parentStep, steps })
    const stepsFromJoin: ICanvasStep[] = []

    transitionsToJoin?.forEach?.(transition => {
      const stepsBeforeTransition = getStepsBeforeStep({ step: transition, steps })

      stepsFromJoin.push(transition)
      stepsBeforeTransition?.forEach?.(transitionBeforeJoin => stepsFromJoin.push(transitionBeforeJoin))
    })

    return [parentStep, ...uniqBy(stepsFromJoin, 'ID')]
  }

  return [parentStep, ...getStepsBeforeStep({ step: parentStep, steps })]
}
