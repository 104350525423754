import { Link } from 'react-router-dom'

import { NotificationDTO } from '@ketch-com/figurehead'
import { useUpdateNotificationById } from 'api/notifications/mutations/useUpdateNotificationById'
import { NotificationStatus } from 'interfaces/notificationsV2'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { getSubstringInBraces, parseStringWithTemplateVars } from 'pages/updates/notifications/list/utils'
import { Typography } from '@mui/material'

type CreateTextLinkElementArgs = {
  notification?: NotificationDTO
  isNew?: boolean
}

export const AppHeaderNotificationCardTextWithLinksReplaced = ({ notification, isNew }: CreateTextLinkElementArgs) => {
  const parsedBodyArray = parseStringWithTemplateVars(notification?.body || '')

  const queryClient = useQueryClient()

  const { mutate } = useUpdateNotificationById({
    onSuccess: () => {
      queryClient.refetchQueries([ApiQueryKeys.notificationsInfinite])
      queryClient.refetchQueries([ApiQueryKeys.notificationsUnreadCount])
    },
  })

  const payload = parsedBodyArray.map(substring => {
    const substringBetweenBraces = getSubstringInBraces(substring)
    const isTemplateString = !!substringBetweenBraces

    if (isTemplateString) {
      const linkText = notification?.linkTextMapping?.[substringBetweenBraces]?.text || ''
      const linkUrl = notification?.linkTextMapping?.[substringBetweenBraces]?.url || ''
      return (
        <Link
          to={linkUrl}
          key={linkUrl}
          style={{ all: 'unset' }}
          onClick={() =>
            mutate({ params: { notificationId: notification?.id || '', status: NotificationStatus.READ } })
          }
        >
          <Typography
            color="sphere.main"
            variant="body"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
              },
            }}
          >
            {linkText}
          </Typography>
        </Link>
      )
    } else {
      return (
        <Typography variant="body" key={substring.replaceAll(' ', '')}>
          {substring}
        </Typography>
      )
    }
  })

  return <>{payload}</>
}
