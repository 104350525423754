import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { EditDatasetFormValues } from 'interfaces/assets'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import { KETCH_DML } from 'pages/developers/hooks/constants'

type Args = { dataset: MaybeNull<AssetSummaryDTO> }

export const getInitialValues = ({ dataset }: Args): EditDatasetFormValues => {
  if (!dataset) {
    return {
      name: '',
      description: '',
      dataCategory: '',
      labels: [],
      inferredLabels: [],
      dataOwner: '',
      hooks: [],
    }
  }

  const hooksPayload = [
    ...(dataset?.asset?.dmlPreHooks || []),
    {
      assignmentCount: 9999,
      dmlCode: 'dummy',
      id: 'dummy',
      name: KETCH_DML,
      operation: 99999,
      technology: 'dummy',
      type: 99,
    },
    ...(dataset?.asset?.dmlPostHooks || []),
  ]

  return {
    name: dataset?.asset?.resource?.name || '',
    description: dataset?.asset?.description || '',
    dataCategory: dataset?.asset?.dataCategory?.[0]?.value || '',
    labels: (dataset?.asset?.assignedLabels || []) as ManageLabelModalLabel[],
    inferredLabels: (dataset?.asset?.inferredLabels || []) as ManageLabelModalLabel[],
    dataOwner: dataset?.asset?.owner || '',
    hooks: hooksPayload,
  }
}
