import React from 'react'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { Banner, Button } from '@ketch-com/deck'

type Props = {
  cookie: CookieDTO
}

export const CookieVersionsMessage: React.FC<Props> = () => {
  const { removeQueryParam } = useQueryParams<{ version?: string }>()

  return (
    <Banner
      sx={{
        width: '100%',
      }}
      severity="warning"
      action={
        <Button variant="link" onClick={() => removeQueryParam('version')}>
          View Current
        </Button>
      }
    >
      You are viewing an old version of this cookie. Return to the current version to manage or activate.
    </Banner>
  )
}
