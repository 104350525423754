import { APIRequestParams } from 'api/common/utils'
import { ExperienceDTO } from 'interfaces/experiences/experience'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  experienceCode: string
  organizationCode: string
  version: string
  includeIssues?: boolean
}>

type Response = { experience: ExperienceDTO }

export const fetchExperienceVersion = ({
  organizationCode,
  experienceCode,
  version,
  includeMetadata,
  includeIssues,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/experiences/${experienceCode}/versions/${version}`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
        },
      },
    }),
  )
