import Box from '@mui/material/Box'
import { Tab, TabPanel, TabGroup } from '@ketch-com/deck'
import React from 'react'
import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormRadioGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormRadioGroup'
import { ItemStyle, ListLayout } from '@ketch-sdk/ketch-types'
import {
  ItemStyleLabels,
  ItemStyleLabelsWhenLayoutExpandable,
} from '../../../../../../experiences-v2/upsert/utils/labels'
import {
  BuilderTextInputMode,
  FormTextInput,
} from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'
import { getIn, useFormikContext } from 'formik'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'

export const PreferenceThemePurposesTabListForm: React.FC = () => {
  const { values } = useFormikContext<ThemeV3DTO>()

  // Current tab of the button form
  const [tab, setTab] = React.useState(0)

  const listItemStyle = getIn(values, themeUpsertFormKeys.preferencePurposesTabListItemsStyle)
  const listItemLayout = getIn(values, themeUpsertFormKeys.preferencePurposesTabListItemsLayout)
  const isFilledStyle = listItemStyle === ItemStyle.Filled
  const isOutlineBottomOnly = listItemLayout === ListLayout.Expandable

  return (
    <>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
            <Tab size={'medium'} label="List Items" />
            <Tab size={'medium'} label="Switch Buttons" />
          </TabGroup>
        </Box>
        {/* List items form */}
        <TabPanel value={tab} index={0} sx={{ px: 0 }}>
          <SidebarFormGroup sx={{ paddingTop: 0 }}>
            {/* NOTE: Purpose list layout descoped for v0 */}
            {/* <SidebarFormRadioGroup
              name={themeUpsertFormKeys.modalPurposeListLayout}
              title={'List layout'}
              options={Object.values(ListLayout).map(layout => ({
                label: ListLayoutLabels[layout],
                value: layout,
              }))}
              row
            /> */}
            <SidebarFormRadioGroup
              name={themeUpsertFormKeys.preferencePurposesTabListItemsStyle}
              title={'List style'}
              options={Object.values(ItemStyle).map(style => ({
                label: isOutlineBottomOnly ? ItemStyleLabelsWhenLayoutExpandable[style] : ItemStyleLabels[style],
                value: style,
              }))}
              row
            />
            {isFilledStyle ? (
              <FormColorInput
                name={themeUpsertFormKeys.preferencePurposesTabListItemsPurposeFillColor}
                label={'Purpose Fill Color'}
                fullWidth
                required
              />
            ) : (
              <FormColorInput
                name={themeUpsertFormKeys.preferencePurposesTabListItemsPurposeOutlineColor}
                label={isOutlineBottomOnly ? 'Purpose Underline Color' : 'Purpose Outline Color'}
                fullWidth
                required
              />
            )}
            <FormColorInput
              name={themeUpsertFormKeys.preferencePurposesTabListItemsPurposeContentColor}
              label={'Purpose Content Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferencePurposesTabListItemsPurposeLinksColor}
              label={'Purpose Links Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferencePurposesTabListItemsArrowIconColor}
              label={'Arrow Icon Color'}
              fullWidth
              required
            />
            <FormTextInput
              name={themeUpsertFormKeys.preferencePurposesTabListItemsPurposeCornerRadius}
              label={'Purpose Corner Radius (px)'}
              mode={BuilderTextInputMode.CornerRadius}
              inputWidth="92px"
            />
          </SidebarFormGroup>
        </TabPanel>

        {/* Switch buttons form */}
        <TabPanel value={tab} index={1} sx={{ px: 0 }}>
          <SidebarFormGroup sx={{ paddingTop: 0 }}>
            <FormColorInput
              name={themeUpsertFormKeys.preferencePurposesTabListSwitchButtonsOnBackgroundColor}
              label={'Switch Button ON Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferencePurposesTabListSwitchButtonsOnTextColor}
              label={'Switch Button ON Text Label Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferencePurposesTabListSwitchButtonsOffBackgroundColor}
              label={'Switch Button OFF Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferencePurposesTabListSwitchButtonsOffTextColor}
              label={'Switch Button OFF Text Label Color'}
              fullWidth
              required
            />
          </SidebarFormGroup>
        </TabPanel>
      </Box>
    </>
  )
}
