import React, { useState } from 'react'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'

interface IEmailFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const EndFlowForm: React.FC<IEmailFormProps> = props => {
  const { step, handleClose, handleRemove, steps } = props
  const [isReady] = useState(true)

  return (
    <SidebarConfigsWrapper isLoading={!isReady} handleClose={handleClose}>
      <SidebarConfigsHeader
        isValid
        steps={steps}
        step={step}
        handleSave={() => {}}
        handleRemove={() => handleRemove({ step })}
        handleClose={handleClose}
      />
      <SidebarConfigsBody>&nbsp;</SidebarConfigsBody>
    </SidebarConfigsWrapper>
  )
}
