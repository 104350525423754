import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ColorNames } from '@ketch-com/deck'
import numeral from 'numeral'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.darkDusk.main,
    color: theme.palette.white.main,
    maxWidth: 220,
    minWidth: 87,
    padding: '8px 10px',
    borderRadius: '8px',
    '& .MuiTooltip-arrow': {
      color: 'black',
    },
  },
}))

type Props = {
  fillColor: string
  percent: number
  count: number
  title: string
  textColor: ColorNames
}

export const ConsentPercentBlock: React.FC<Props> = ({ fillColor, percent, count, title, textColor }) => {
  return (
    <HtmlTooltip
      arrow
      placement="left"
      title={
        percent < 10 ? (
          <Box display="flex" flexDirection="column">
            <Typography variant="smallLabel" color="white.main" sx={{ mb: 0.5 }}>
              {percent.toFixed(1)}%
            </Typography>
            <Typography variant="smallLabel" color="white.main" sx={{ mb: 0.5 }}>
              {title}
            </Typography>
            <Typography variant="smallLabel" color="white.main">
              {numeral(count).format('0,0')}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="smallLabel" color="white.main">
              {numeral(count).format('0,0')}
            </Typography>
          </Box>
        )
      }
    >
      <Box
        flex={percent || undefined}
        sx={{
          backgroundColor: fillColor,
          p: percent ? 1 : undefined,
          borderRadius: ({ spacing }) => spacing(0.75),
          minHeight: ({ spacing }) => spacing(1),
        }}
      >
        {percent >= 10 ? (
          <>
            <Typography variant="label" color={`${textColor}.main`} sx={{ display: 'block', mb: 0.5 }}>
              {percent.toFixed(1)}%
            </Typography>
            <Typography variant="smallLabel" color={`${textColor}.main`} sx={{ display: 'block' }}>
              {title}
            </Typography>
          </>
        ) : null}
      </Box>
    </HtmlTooltip>
  )
}
