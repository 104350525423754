import { useInfiniteQuery } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { APIListRequestParams } from 'api/common/utils'
import { fetchRelatedAssetsPaginatedV3 } from '../fetchers/fetchRelatedAssetsPaginatedV3'

type UseRelatedAssetsInfiniteV3Params = APIListRequestParams<{
  canonicalResourceType?: number | null | undefined
  resourceTypeCode?: string | null | undefined
  query?: string | null | undefined
  assetCode?: string | null | undefined
  identityRelationType?: number
  isPersonal?: boolean
}>

type Options = {
  enabled?: boolean
}

type UseRelatedAssetsInfiniteV3Args = {
  params: UseRelatedAssetsInfiniteV3Params
  options?: Options
}

export const useRelatedAssetsInfiniteV3 = ({
  params,
  options: { enabled = true } = {},
}: UseRelatedAssetsInfiniteV3Args) => {
  const resp = useInfiniteQuery({
    enabled,
    queryKey: [ApiQueryKeys.relatedResourcesInfiniteV3, { ...params }],
    queryFn: async ({ pageParam = {} }) => {
      const fetchResult = await fetchRelatedAssetsPaginatedV3({
        limit: params.limit || 20,
        nextToken: pageParam.nextToken,
        ...params,
      })
      return fetchResult
    },
    getNextPageParam: lastPage => {
      const nextToken = lastPage?.data?.next?.nextToken
      return nextToken ? { nextToken } : undefined
    },
  })

  return {
    ...resp,
    assetsData: resp?.data?.pages?.map(page => page?.data?.hierarchySummaries || []).flat() || [],
    totalResults: resp?.data?.pages?.[0]?.data?.totalResults || 0,
  }
}
