import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { UnassignEventWorkflowV2BodyDTO, UnassignEventWorkflowV2ResponseBodyDTO } from '@ketch-com/figurehead'

type Params = {
  formData: UnassignEventWorkflowV2BodyDTO
}

type Response = { eventWorkflow: UnassignEventWorkflowV2ResponseBodyDTO }

export const unassignEventWorkflow = ({ formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/v2/events/workflows/unassign`,
    }),
    formData,
  )
