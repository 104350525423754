import { Status, StatusState, StatusVariant } from '@ketch-com/deck'
import React from 'react'

type Props = {
  title: string
}

export const InputTypeBadge: React.FC<Props> = ({ title }) => {
  return <Status label={title} variant={StatusVariant.filled} status={StatusState.new} />
}
