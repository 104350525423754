import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

import { ObjectLiteral } from 'interfaces'

export const dataMock = {
  privacyPolicy: '#',
  termsOfService: '#',
}

const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        whiteSpace: 'pre-line',
        overflowWrap: 'break-word',
      },
      link: {
        color: 'currentColor',
      },
      lightBlue: {
        color: theme.palette.sphere.main,
      },
    }),
  { name: 'InterpolateLinks' },
)

const interpolateLinks = ({
  str,
  data,
  className,
}: {
  str: string
  data: ObjectLiteral<string>
  className: string
}) => {
  const linksTemplates = str.match(/\[(.*?)\)/gm) || []

  if (!linksTemplates.length) {
    return str
  }

  const meta = linksTemplates.map((lt, index) => {
    const textMatch = lt.match(/\[(.*?)\]/gm)
    const text = ((textMatch && textMatch[0]) || '').slice(1, -1)

    const linkKeyMatch = lt.match(/\((.*?)\)/gm)
    const linkKey = ((linkKeyMatch && linkKeyMatch[0]) || '').slice(1, -1)

    let link = linkKey

    if (data[linkKey]) {
      link = data[linkKey]
    }

    return {
      template: lt,
      startPos: str.indexOf(lt),
      link: !!(text && link) && (
        <a key={index} href={link} className={className} target="_blank" rel="noreferrer">
          {text}
        </a>
      ),
    }
  })

  const result: (string | React.ReactNode)[] = []

  let lastPos = 0

  meta?.forEach?.(({ template, startPos, link }) => {
    const s = str.slice(lastPos, startPos)

    result.push(s, link || template)
    lastPos = startPos + template.length
  })

  result.push(str.slice(lastPos))

  return result
}

type Props = {
  className?: string
  linkColor?: 'default' | 'light-blue'
  text: string
  data?: ObjectLiteral<string>
}

export const InterpolateLinks: React.FC<Props> = ({ className, linkColor = 'default', text, data = dataMock }) => {
  const classes = useStyles()

  return (
    <span className={clsx(classes.root, className)}>
      {interpolateLinks({
        str: text,
        data,
        className: clsx(classes.link, { [classes.lightBlue]: linkColor === 'light-blue' }),
      })}
    </span>
  )
}
