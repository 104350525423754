import { Tab, TabGroup, TabProps } from '@ketch-com/deck'
import { Box } from '@mui/material'

type TabsComponentProps = {
  tabItems: Array<TabProps>
  value?: any
  onChange?: (event: React.SyntheticEvent, value: any) => void
  'aria-label'?: string
  'aria-labelledby'?: string
}

const TabsComponent = ({ tabItems, ...tabsProps }: TabsComponentProps) =>
  tabItems && (
    <TabGroup {...tabsProps}>
      {tabItems.map((tabItem, index) => (
        <Tab {...tabItem} key={index} />
      ))}
    </TabGroup>
  )

export const ProcessingActivityTabs = ({ tabsComponentProps }: { tabsComponentProps: TabsComponentProps }) => {
  return (
    <Box
      className="DeckLayout-header-content"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {tabsComponentProps && (
        <Box flex="1">
          <TabsComponent {...tabsComponentProps} />
        </Box>
      )}
    </Box>
  )
}
