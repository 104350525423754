import React from 'react'
import { useFormikContext, FieldArrayRenderProps } from 'formik'
import { showToast } from 'components/modals/AlertComponent'
import {
  SubscriptionTopicFormData,
  OrchestrationData,
  ConnectionItem,
} from 'pages/policyCenter/subscriptions/interfaces'
import { useSystemConnections } from 'api/apps/queries/useSystemConnections'
import { systems } from 'pages/policyCenter/subscriptions/constants'
import {
  Braze,
  Marketo,
  Iterable,
  Shopify,
  Hubspot,
} from 'pages/policyCenter/subscriptions/subscriptionTopic/upsert/components/Orchestration/systems'
import { SalesforceMarketingCloud } from './systems/SalesforceMarketingCloud'

type Props = {
  orchestration: OrchestrationData
  fieldArrayHelpers: FieldArrayRenderProps
  fieldArrayIndex: number
  channelCode: string
}

export const SystemOrchestration: React.FC<Props> = props => {
  const { orchestration, fieldArrayHelpers, fieldArrayIndex, channelCode } = props
  const { values } = useFormikContext<SubscriptionTopicFormData>()
  const { remove } = fieldArrayHelpers
  const { data: systemConnections, isLoading: isSystemConnectionsLoading } = useSystemConnections({
    params: { systemCode: orchestration?.appCode || '' },
    onSuccess: () => {},
    onError: () => {
      remove(fieldArrayIndex)
      showToast({ content: 'Failed to fetch system connections', type: 'error' })
    },
    enabled: Boolean(orchestration.appCode),
  })

  const connectionItems: ConnectionItem[] =
    systemConnections?.map(connection => ({
      id: connection?.id,
      name: connection?.name,
      disabled: values?.contactMethods?.[channelCode]?.orchestrations?.some(
        orchestration => orchestration.appInstanceId === connection?.id,
      ),
    })) || []

  switch (orchestration?.name?.toLowerCase()) {
    case systems.marketo:
      return (
        <Marketo connectionItems={connectionItems} isSystemConnectionsLoading={isSystemConnectionsLoading} {...props} />
      )
    case systems.braze:
      return (
        <Braze connectionItems={connectionItems} isSystemConnectionsLoading={isSystemConnectionsLoading} {...props} />
      )
    case systems.hubspot:
      return (
        <Hubspot connectionItems={connectionItems} isSystemConnectionsLoading={isSystemConnectionsLoading} {...props} />
      )
    case systems.iterable:
      return (
        <Iterable
          connectionItems={connectionItems}
          isSystemConnectionsLoading={isSystemConnectionsLoading}
          {...props}
        />
      )
    case systems.shopify:
      return (
        <Shopify connectionItems={connectionItems} isSystemConnectionsLoading={isSystemConnectionsLoading} {...props} />
      )
    case systems.salesforceMarketingCloud:
      return (
        <SalesforceMarketingCloud
          connectionItems={connectionItems}
          isSystemConnectionsLoading={isSystemConnectionsLoading}
          {...props}
        />
      )

    default:
      return <></>
  }
}
