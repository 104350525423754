import { FC } from 'react'
import { Box } from '@mui/material'
import { Status, StatusState, StatusVariant, theme } from '@ketch-com/deck'
import { ApplicationDTO } from 'interfaces/applications/application'
import { SENSITIVE_APPLICATION_STATUSES } from 'interfaces/applications/applicationStatus'
import { getDeploymentEnvironments } from 'pages/consentAndRights/applications/utils/getDeploymentEnvironments'

interface Props {
  status: ApplicationDTO['status']
  environments?: ApplicationDTO['environments']
  statusVariant?: 'ghost' | 'outlined'
}

export const DataDeploymentStatus: FC<Props> = ({ status, environments, statusVariant = 'ghost' }) => {
  const isInSensitiveStatus = SENSITIVE_APPLICATION_STATUSES.includes(status)
  const { totalCount, selectedCount } = getDeploymentEnvironments(environments)

  return totalCount > 1 ? (
    <Box
      sx={{
        '& span > span:first-of-type': {
          backgroundColor: `${theme.palette.greenHaze.main} !important`,
        },
      }}
    >
      <Status
        isMixedDot={totalCount > selectedCount}
        variant={StatusVariant[statusVariant]}
        status={selectedCount >= totalCount ? StatusState.success : StatusState.draft}
        icon="Dot"
        label={`${selectedCount}/${totalCount} Deployed`}
        mixedDotColor="#838c91"
      />
    </Box>
  ) : (
    <Status
      variant={StatusVariant[statusVariant]}
      status={isInSensitiveStatus ? StatusState.success : StatusState.draft}
      icon="Dot"
      label={isInSensitiveStatus ? 'Deployed' : 'Not Deployed'}
    />
  )
}
