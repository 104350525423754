import { useState } from 'react'
import { debounce } from 'lodash'

import { useAssetsUniqueNamesPaginatedV2 } from 'api/assets/queries/useAssetsUniqueNamesPaginatedV2'

export const useResourceSearchOptions = () => {
  const [queryValue, setQueryValue] = useState('')
  const [assetType, setAssetType] = useState<number | undefined>()
  const [options, setOptions] = useState<string[]>([])

  const { isLoading: isLoadingResources, isFetching: isFetchingResources } = useAssetsUniqueNamesPaginatedV2({
    enabled: !!assetType && !!queryValue,
    params: {
      type: assetType,
      query: queryValue,
    },
    onSuccess: ({ data }) => {
      setOptions(data?.names || [])
    },
  })

  return {
    queryValue,
    setSearchValue: debounce((searchValue, assetTypeValue: number) => {
      setQueryValue(searchValue)
      setAssetType(assetTypeValue)
    }, 500),
    resourceNameOptions: options,
    isLoadingResources: isLoadingResources || isFetchingResources,
    setResourceOptions: setOptions,
  }
}
