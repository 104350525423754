import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { RenderElementProps } from 'slate-react'

import { LinkElementRenderer } from '.'

const useStyles = makeStyles(
  createStyles({
    paragraph: {
      margin: 0,
    },
  }),
  { name: 'DocumentLinkerElement' },
)

export const InterpolatedElement: React.FC<RenderElementProps> = ({ attributes, children, element }) => {
  const classes = useStyles()

  switch (element.type) {
    case 'link':
      return (
        <LinkElementRenderer attributes={attributes} element={element}>
          {children}
        </LinkElementRenderer>
      )

    default:
      return (
        <p className={classes.paragraph} {...attributes}>
          {children}
        </p>
      )
  }
}
