export const registration = {
  root: {
    pattern: '/registration',
    getURL: () => '/registration',
  },

  register: {
    root: {
      pattern: '/register',
      getURL: () => '/registration/register',
    },
  },

  building: {
    root: {
      pattern: '/building',
      getURL: () => '/registration/building',
    },
  },
}
