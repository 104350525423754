import { ItemStyle } from '@ketch-sdk/ketch-types'
import { Box, Typography, styled } from '@mui/material'
import { getIn } from 'formik'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { useContext } from 'react'
import { SectionEmptyState } from '../../../../../SectionEmptyState'
import { SelectableSection } from '../../../../../SelectableSection'
import {
  BUILDER_CONSTANTS,
  PreferenceExperienceBuilderSection,
} from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'
import { clamp } from 'lodash'

const ListHeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '32px',
})

const ListTitleBox = styled(Box)({
  display: 'flex',
  width: '100%',
})

const ButtonsBox = styled(Box)({
  flex: '0 1 auto',
  display: 'flex',
  gap: '8px',
})

const ButtonBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '86px',
  height: '28px',
})

export const PurposesListHeader: React.FC = () => {
  const { themeConfig, experienceConfig, isTheme } = useContext(BuilderContext)

  // Theme config
  const titleColor = getIn(themeConfig, themeUpsertFormKeys.preferencePurposesTabListHeaderTitleColor)
  const acceptAllButtonStyle = getIn(
    themeConfig,
    themeUpsertFormKeys.preferencePurposesTabListHeaderAcceptAllButtonStyle,
  )
  const acceptAllButtonBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferencePurposesTabListHeaderAcceptAllButtonBackgroundColor,
  )
  const acceptAllButtonTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferencePurposesTabListHeaderAcceptAllButtonTextColor,
  )
  const acceptAllButtonCornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferencePurposesTabListHeaderAcceptAllButtonCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'
  const rejectAllButtonStyle = getIn(
    themeConfig,
    themeUpsertFormKeys.preferencePurposesTabListHeaderRejectAllButtonStyle,
  )
  const rejectAllButtonBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferencePurposesTabListHeaderRejectAllButtonBackgroundColor,
  )
  const rejectAllButtonTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferencePurposesTabListHeaderRejectAllButtonTextColor,
  )
  const rejectAllButtonCornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferencePurposesTabListHeaderRejectAllButtonCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'

  // Experience config
  const titleVisible = getIn(experienceConfig, experienceUpsertFormKeys.preferencePurposesTabListHeaderTitleVisible)
  const title = getIn(experienceConfig, experienceUpsertFormKeys.preferencePurposesTabListHeaderTitle)

  return (
    <ListHeaderBox>
      <SelectableSection
        section={isTheme ? undefined : PreferenceExperienceBuilderSection.PurposesTabListHeader}
        width={'100%'}
        overlayProps={{ marginRight: '16px' }}
      >
        <ListTitleBox>
          {titleVisible ? (
            <Typography
              fontSize="18px"
              fontWeight={600}
              color={titleColor}
              sx={{ wordWrap: 'break-word', maxWidth: '345px' }}
            >
              {title}
            </Typography>
          ) : (
            <SectionEmptyState section="List Title" hidden={isTheme} />
          )}
        </ListTitleBox>
      </SelectableSection>
      <ButtonsBox>
        <ButtonBox
          sx={{
            background: rejectAllButtonStyle === ItemStyle.Filled ? rejectAllButtonBackgroundColor : '',
            border: `1.5px solid ${rejectAllButtonBackgroundColor}`,
            borderRadius: rejectAllButtonCornerRadius,
          }}
        >
          <Typography color={rejectAllButtonTextColor} fontSize={'13px'} fontWeight={600}>
            Reject All
          </Typography>
        </ButtonBox>
        <ButtonBox
          sx={{
            background: acceptAllButtonStyle === ItemStyle.Filled ? acceptAllButtonBackgroundColor : '',
            border: `1.5px solid ${acceptAllButtonBackgroundColor}`,
            borderRadius: acceptAllButtonCornerRadius,
          }}
        >
          <Typography color={acceptAllButtonTextColor} fontSize={'13px'} fontWeight={600}>
            Accept All
          </Typography>
        </ButtonBox>
      </ButtonsBox>
    </ListHeaderBox>
  )
}
