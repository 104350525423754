import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { APIListRequestParams } from 'api/common/utils'
import { fetchClassificationsPaginatedV3 } from '../fetchers/fetchClassificationsPaginatedV3'

type Params = APIListRequestParams<{
  includeIssues?: boolean
  includeSystemLabels?: boolean
}>

type Options = {
  enabled?: boolean
}

export const useClassificationsInfiniteV3 = ({ options, params }: { options?: Options; params: Params }) => {
  const resp = useInfiniteQuery({
    enabled: !!options?.enabled,
    queryKey: [
      ApiQueryKeys.classificationsPaginatedV3,
      {
        ...params,
      },
    ],
    queryFn: async ({ pageParam }) => {
      const fetchDatasetsResp = await fetchClassificationsPaginatedV3({
        limit: params?.limit || 20,
        nextToken: pageParam?.nextToken,
        ...params,
      })

      return fetchDatasetsResp
    },
    getNextPageParam: lastPage => {
      const nextToken = lastPage?.data?.next?.nextToken
      return nextToken ? { nextToken } : undefined
    },
  })

  return {
    ...resp,
    classificationsData: resp?.data?.pages?.map(page => page?.data?.labels || []).flat() || [],
    totalResults: resp?.data?.pages?.[0]?.data?.totalResults || 0,
  }
}
