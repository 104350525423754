import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { IdentitySpaceView } from 'pages/settings/identitySpaces/view/IdentitySpaceView'
import { useIdentitySpaceVersions } from 'api/identitySpaces/queries/useIdentitySpaceVersions'
import { useIdentitySpace } from 'api/identitySpaces/queries/useIdentitySpace'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { useIdentitySpaceVersion } from 'api/identitySpaces/queries/useIdentitySpaceVersion'
import { useVerifiers } from 'api/verifiers/queries/useVerifiers'

export const IdentitySpaceViewContainer: React.FC = () => {
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()
  const { queries } = useQueryParams<{ version?: string }>()
  const { version } = queries

  const handleRedirectToIdentitySpacesList = () => {
    navigate(RoutesManager.settings.identitySpaces.root.getURL())
  }

  const { data: identitySpaces, isLoading: isIdentitySpacesLoading } = useIdentitySpaces({
    params: {
      includeMetadata: true,
      includeIssues: true,
    },
    onError: handleRedirectToIdentitySpacesList,
  })

  const { data: verifiersData, isLoading: isLoadingVerifiers } = useVerifiers({
    params: {},
    itemsPerPage: 100,
    onError: handleRedirectToIdentitySpacesList,
  })

  const { data: identitySpace, isLoading: isIdentitySpaceLoading } = useIdentitySpace({
    params: {
      identitySpaceCode: code!,
      includeMetadata: true,
    },
    onError: handleRedirectToIdentitySpacesList,
  })

  const { data: versions, isLoading: isVersionsLoading } = useIdentitySpaceVersions({
    enabled: !!identitySpace,
    params: {
      identitySpaceCode: identitySpace?.code as string,
      includeMetadata: true,
      ordering: {
        version: 'desc',
      },
    },
    onError: handleRedirectToIdentitySpacesList,
  })

  const { data: identitySpaceVersion, isLoading: isIdentitySpaceVersionLoading } = useIdentitySpaceVersion({
    enabled: !!version && !!identitySpace,
    params: {
      identitySpaceCode: identitySpace?.code as string,
      version: version!,
    },
    onError: handleRedirectToIdentitySpacesList,
  })

  const isReady =
    !isVersionsLoading && version
      ? !isIdentitySpaceVersionLoading
      : !isIdentitySpaceLoading && !isIdentitySpacesLoading && !isLoadingVerifiers

  return (
    <IdentitySpaceView
      isReady={isReady}
      identitySpace={version ? identitySpaceVersion : identitySpace}
      identitySpaces={identitySpaces}
      versions={versions}
      verifiersData={verifiersData}
    />
  )
}
