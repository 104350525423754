import { APIListRequestParams } from 'api/common/utils'
import { useAssetsInfiniteV2 } from './useAssetsInfiniteV2'
import { useAssetsInfiniteV3 } from './useAssetsInfiniteV3'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type Params = APIListRequestParams<{
  resourceTypeCode?: string | null | undefined
  query?: string | null | undefined
}>

type UseV2OrV3AssetsInfiniteBasedOnFeatureBranchArgs = {
  params: Params
}

export const useV2OrV3AssetsInfiniteBasedOnFeatureFlag = ({
  params,
}: UseV2OrV3AssetsInfiniteBasedOnFeatureBranchArgs) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )

  /* V2 */

  const {
    data: infiniteAssetsDataV2,
    isLoading: isLoadingV2,
    hasNextPage: hasNextPageV2,
    fetchNextPage: fetchNextPageV2,
    isFetchingNextPage: isFetchingNextPageV2,
    totalResults: totalResultsV2,
  } = useAssetsInfiniteV2({
    params: {
      limit: params.limit || 20,
      resourceTypeCode: params.resourceTypeCode,
      query: params?.query || '',
      enabled: isV2FeatureFlagEnabled,
    },
  })

  const { totalResults: totalAssetCountV2 } = useAssetsInfiniteV2({
    params: {
      limit: 2,
      enabled: isV2FeatureFlagEnabled,
    },
  })

  const payloadV2 = {
    infiniteAssetsData: infiniteAssetsDataV2 || [],
    isLoading: isLoadingV2,
    hasNextPage: hasNextPageV2,
    fetchNextPage: fetchNextPageV2,
    isFetchingNextPage: isFetchingNextPageV2,
    totalResults: totalResultsV2,
    totalAssetCount: totalAssetCountV2,
  }

  /* V3 */

  const {
    assetsData: assetsDataV3,
    isLoading: isLoadingV3,
    hasNextPage: hasNextPageV3,
    fetchNextPage: fetchNextPageV3,
    isFetchingNextPage: isFetchingNextPageV3,
    totalResults: totalResultsV3,
  } = useAssetsInfiniteV3({
    limit: params.limit || 20,
    resourceTypeCode: params.resourceTypeCode,
    query: params.query,
    enabled: !isV2FeatureFlagEnabled,
  })

  const { totalResults: totalAssetCountV3 } = useAssetsInfiniteV3({
    limit: 2,
    enabled: !isV2FeatureFlagEnabled,
  })

  const payloadV3 = {
    infiniteAssetsData: assetsDataV3 || [],
    isLoading: isLoadingV3,
    hasNextPage: hasNextPageV3,
    fetchNextPage: fetchNextPageV3,
    isFetchingNextPage: isFetchingNextPageV3,
    totalResults: totalResultsV3,
    totalAssetCount: totalAssetCountV3,
  }

  return isV2FeatureFlagEnabled ? payloadV2 : payloadV3
}
