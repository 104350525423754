import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Text } from 'components/ui-kit/typography/Text'
import { useParams } from 'react-router-dom'
import { DataRiskDetailLayout } from 'pages/insights/dataRisks/components/layout/DataRiskDetailLayout'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { getRiskScoreDetails } from 'pages/insights/utils'
import { ThirdPartyRisk } from '../../__mocks__/thirdPartyRisk'
import { getDataSystemType } from 'pages/insights/dataMap/utils/getDataSystemType'
import { capitalize } from 'lodash'
import { ThirdPartyRiskLowerContent } from './ThirdPartyRiskLowerContent'
import { ReactComponent as ArrowsIcon } from 'assets/icons/data_risks_suggestions_arrows.svg'
import { ReactComponent as ChecksIcon } from 'assets/icons/data_risks_suggestions_checks.svg'
import { ReactComponent as YourPropertyIcon } from 'assets/icons/data_risks_your_property.svg'
import { ReactComponent as ArrowHeadIcon } from './arrow-end.svg'
import { ReactComponent as ArrowGradientIcon } from './gradient-line.svg'
import { ReactComponent as CookieIcon } from 'assets/icons/cookie.svg'
import { GreyBox } from '../../components/layout/GreyBox'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { useState } from 'react'
import { LineChart } from 'pages/insights/dataRisks/components/LineChart'

const PREFIX = 'UnknownTransfersDetails'

const classes = {
  arrowHead: `${PREFIX}-arrowHead`,
  arrowGradient: `${PREFIX}-arrowGradient`,
  tabsRoot: `${PREFIX}-tabsRoot`,
  tabs: `${PREFIX}-tabs`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.arrowHead}`]: {
    position: 'absolute',
    top: 24,
    right: -171,
  },

  [`& .${classes.arrowGradient}`]: {
    position: 'absolute',
    width: 47,
    left: 57,
    top: 28,
  },

  [`& .${classes.tabsRoot}`]: {
    backgroundColor: palette.fadedGrey.main,
  },

  [`& .${classes.tabs}`]: {
    fontSize: 12,
    fontWeight: 500,
    color: palette.darkDusk.main,
    '&:not($tabsRoot):hover': {
      color: palette.darkDusk.main,
    },
    '&:focus': {
      color: palette.darkDusk.main,
    },
    '&.Mui-selected': {
      fontWeight: 600,
      color: palette.darkDusk.main,
    },
    '&.Mui-selected:hover': {
      color: palette.darkDusk.main,
    },
  },
}))

type Props = {
  unknownTransfersRecords: ThirdPartyRisk[]
  emptyState: JSX.Element
}

enum MainContentTabs {
  DETAILS = 'DETAILS',
  RISK_TREND = 'RISK_TREND',
}

const MAIN_CONTENT_TABS_OPTIONS = [
  {
    id: MainContentTabs.DETAILS,
    name: 'Details',
  },
  {
    id: MainContentTabs.RISK_TREND,
    name: 'Risk Trend',
  },
]

const sampleUnknownSystems = [
  'DDYT',
  '_huffpost.udir',
  'ZRKoCICMpsUBEO2H_NID',
  'liveramp.com',
  'publishertag.ids',
  'esp.criteo.com',
  'crwdcntrl.net',
  'uidapi',
  'openxtest',
]

export const UnknownTransfersDetails: React.FC<Props> = ({ unknownTransfersRecords, emptyState }) => {
  const { code: recordId } = useParams<{ code?: string }>()
  const dataSystem = unknownTransfersRecords.find(record => record.id === recordId)
  const [activeTab, setActiveTab] = useState(MainContentTabs.DETAILS)
  const riskScoreDetails = getRiskScoreDetails(dataSystem?.riskScore || 0)

  if (!dataSystem) {
    return emptyState
  }

  const actions = (
    <ButtonOptions size="big" items={[{ content: <Text>Export</Text> }, { content: <Text>Compare</Text> }]} />
  )

  const whyContent = (
    <Box>
      <Text component="div" size={20} weight={700} mb={1.5} lineHeight="28px">
        Attracts a large number of data collectors
      </Text>
      <Text component="div" lineHeight="20px">
        {dataSystem?.name} attracts from 10 to 25 different data collectors.
      </Text>
    </Box>
  )

  const suggestionsContent = [
    {
      icon: <ChecksIcon />,
      textContent: (
        <Text lineHeight="20px" component="div">
          Consider disclosing all 3rd party systems and connecting their apps through Data Systems to automate privacy
          signals.
        </Text>
      ),
    },
    {
      icon: <ArrowsIcon />,
      textContent: (
        <Text lineHeight="20px" component="div">
          Automation ensures that all consumer consent and rights signals are captured and enforced.&nbsp;
          <Text color="royalBlue" onClick={() => {}}>
            Check this video
          </Text>
          &nbsp;about implementation techniques.
        </Text>
      ),
    },
  ]

  const lowerContentRows = [<ThirdPartyRiskLowerContent record={dataSystem} key={1} />]

  const detailsTab = (
    <StyledBox display="flex" gap={6} height="100%" justifyContent="center" mt={-3.5} alignItems="center">
      <Box borderRadius={3} height={170} width={240} bgcolor="white.main" position="relative">
        <Box display="flex" justifyContent="space-evenly" alignItems="center" height="100%" pb={3.5}>
          <Box
            height={60}
            width={60}
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            sx={({ palette }) => ({ borderRadius: 100, border: `2px solid ${palette.iron.main}` })}
          >
            <YourPropertyIcon />
            <Text weight={600} size={12} sx={{ position: 'absolute', bottom: -21 }}>
              csr.pols.com
            </Text>
            <ArrowGradientIcon className={classes.arrowGradient} />
            <ArrowHeadIcon className={classes.arrowHead} />
          </Box>
          <Box
            height={44}
            width={44}
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            sx={({ palette }) => ({ borderRadius: 100, backgroundColor: palette.darkDusk.main })}
            zIndex={2}
          >
            <CookieIcon />
            <Text weight={600} size={12} sx={{ position: 'absolute', bottom: -28 }}>
              Instapage
            </Text>
          </Box>
        </Box>
        <Box
          position="absolute"
          bottom={-10}
          right="calc(50% - 47px)"
          borderRadius={100}
          sx={({ palette, spacing }) => ({ backgroundColor: palette.iron.main, padding: spacing(0.75, 1.25) })}
        >
          <Text size={11} letterSpacing="0.25px" component="div">
            Your Property
          </Text>
        </Box>
      </Box>
      <Box
        borderRadius={3}
        pt={1.5}
        height={230}
        width={180}
        bgcolor="white.main"
        position="relative"
        textAlign="center"
      >
        {sampleUnknownSystems.map(text => (
          <Text sx={{ mb: 0.75 }} size={12} key={text} component="div" weight={500}>
            {text}
          </Text>
        ))}
        <Text component="div" sx={{ mb: 0.25 }} color="royalBlue" size={12} onClick={() => {}} weight={500}>
          24 more
        </Text>
        <Box
          position="absolute"
          bottom={-10}
          right="calc(50% - 62px)"
          borderRadius={100}
          width={124}
          sx={({ palette, spacing }) => ({ backgroundColor: palette.darkDusk.main, padding: spacing(0.75, 1.25) })}
        >
          <Text size={11} letterSpacing="0.25px" component="div" color="white">
            Unknown Systems
          </Text>
        </Box>
      </Box>
    </StyledBox>
  )

  const riskTrendTab = (
    <Box height={240} mt={1}>
      <LineChart riskTrend={dataSystem?.riskTrend} />
    </Box>
  )

  const mainContent = (
    <GreyBox>
      <StyledBox height={280}>
        <SegmentTabs
          className={classes.tabsRoot}
          tabClassName={classes.tabs}
          tabs={MAIN_CONTENT_TABS_OPTIONS}
          value={activeTab}
          onChange={setActiveTab}
        />
        {activeTab === MainContentTabs.DETAILS ? detailsTab : riskTrendTab}
      </StyledBox>
    </GreyBox>
  )

  return (
    <DataRiskDetailLayout
      heading={dataSystem?.name || ''}
      headingType={capitalize(getDataSystemType(dataSystem?.canonicalResourceType || 0))}
      actions={actions}
      mainContent={mainContent}
      riskScoreDetails={riskScoreDetails}
      riskScore={dataSystem?.riskScore}
      whyContent={whyContent}
      suggestionContent={suggestionsContent}
      lowerContentRows={lowerContentRows}
    />
  )
}
