import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
import { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIdentityVerificationResolveFormUtils } from './useIdentityVerificationResolveFormUtils'
import { useRetryExecution } from '../../../../../../../../api/executions/mutations/useRetryExecution'
import { ApiQueryKeys } from '../../../../../../../../api/common/queryKeys'
import { showToast } from '../../../../../../../../components/modals/AlertComponent'
import { useQueryClient } from 'react-query'

export const useIdvViewUtils = () => {
  const [showRequiredFields, setShowRequiredFields] = useState(false)
  const { id: rightInvocationId } = useParams<{ id?: string }>()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    isReady: isContextReady,
    userMe,
    rightInvocation,
    stepId,
    workflowStep,
    stepDetails,
    isStepDetailsLoading,
    workflowExecutionId,
  } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const { resolveFormik } = useIdentityVerificationResolveFormUtils()

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    { title: 'Rights Queue', link: RoutesManager.orchestration.rightsQueue.root.getURL() },
    {
      title: rightInvocation?.policyScopeName,
      link: RoutesManager.orchestration.rightsQueue.view.root.getURL({ id: rightInvocationId }),
    },
    {
      title: 'Identity Verification',
      link: RoutesManager.orchestration.rightsQueue.stepDetails.root.getURL({
        id: rightInvocationId,
        stepId,
        workflowExecutionId,
      }),
    },
  ]
  const isReady = !!isContextReady && !isStepDetailsLoading && !isStepDetailsLoading
  const isAssignedToMe = stepDetails?.assignee === userMe?.ID
  const isCompleted = workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS

  const { mutateAsync: retryIdentityVerification, isLoading: isRetryingIdentityVerification } = useRetryExecution({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.executionResultsInfinite])
      showToast({ content: 'Retry initiated', type: 'success' })
      navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocationId }))
    },
    onError: () => {
      showToast({ content: 'Unable to retry', type: 'error' })
    },
  })

  // TODO Prachi: parse this error string and also determine if it corresponds to a retry-able error code on the backend instead
  const rpcStepError = stepDetails?.results?.[`${stepDetails.stepID}`]?.['error' as any]
  let sanitizedStepError = ''
  if (rpcStepError !== undefined) {
    let s: string[] = []
    s = rpcStepError.split('=')
    sanitizedStepError = s[s.length - 1]
  }

  return {
    breadcrumbs,
    isAssignedToMe,
    isCompleted,
    isReady,
    rightInvocationId,
    stepDetails,
    workflowStep,
    sanitizedStepError,
    showRequiredFields,
    setShowRequiredFields,
    resolveFormik,
    retryIdentityVerification,
    isRetryingIdentityVerification,
  }
}

export type UseIdvViewUtils = ReturnType<typeof useIdvViewUtils>
