import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { LegalBaseDTO } from 'interfaces/legalBases/legalBase'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  includeIssues?: boolean
}>

type Response = APIListResponse<{ legalBases: LegalBaseDTO[] }>

export const fetchLegalBases = ({ includeMetadata, includeIssues, start, limit }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: '/api/legal-bases',
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
        },
      },
    }),
  )
