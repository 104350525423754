import { makeStyles, createStyles } from '@mui/styles'

export const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      selectRoot: {
        '& .MuiSelect-select:focus': {
          backgroundColor: 'white',
        },
      },
    }),
  { name: 'AttributeTableActionCellRenderer' },
)
