// The current version of this will not handle arrays, just object.
export const createPayloadJsonString = (payload: Record<string, any>, indentLevel = 1): string => {
  const indent = '    '.repeat(indentLevel)
  let payloadString = '{\n'

  for (let key in payload) {
    const value = payload[key]
    const formattedValue =
      typeof value === 'object' && value !== null ? createPayloadJsonString(value, indentLevel + 1) : `"${value}"`

    payloadString += `${indent}"${key}": ${formattedValue},\n`
  }

  payloadString = payloadString.slice(0, -2) // Remove the last comma and newline
  payloadString += `\n${'  '.repeat(indentLevel - 1)}}`

  return payloadString
}
