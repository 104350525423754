import { TableCell } from '@ketch-com/deck'
import { RightInvocationExtensionDTO } from '@ketch-com/figurehead'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import pluralize from 'pluralize'

export const rightInvocationExtensionsColumns: GridColDef<RightInvocationExtensionDTO>[] = [
  {
    align: 'left',
    field: 'period',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Period',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<RightInvocationExtensionDTO>) => {
      return <TableCell title={`${row.extensionDays} ${pluralize('day', row.extensionDays)}`} />
    },
  },
  {
    align: 'left',
    field: 'reasonForExtension',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Reason For Extension',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<RightInvocationExtensionDTO>) => {
      return row.reason ? <TableCell title={row.reason} /> : <EmptyValueRenderer />
    },
  },
  {
    align: 'left',
    field: 'updatedAt',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Last Updated',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<RightInvocationExtensionDTO>) => {
      return <FormattedDateRenderer date={row.extendedAt} showTimezone />
    },
  },
]
