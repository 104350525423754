import React, { useCallback, useMemo } from 'react'
import { Formik } from 'formik'

import { MaybeNull } from 'interfaces/common'
import { WorkflowExecutionDTO, WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { UserShortDTO } from 'interfaces/users/users'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { WorkflowExecutionAssignee } from 'pages/orchestration/rightsQueue/components/WorkflowExecutionAssignee'
import { WorkflowExecutionStepStatusRendererLegacy } from './WorkflowExecutionStepStatusRendererLegacy'
import {
  validationSchema,
  WorkflowExecutionStepReassignFormValues,
} from 'pages/orchestration/rightsQueue/viewV2/components/RightsQueueWorkflowTab/components/reassignStep/utils'
import { AutocompleteRenderInputParams, Box, InputAdornment, Typography } from '@mui/material'
import { AvatarSize, Button, PopUp, ListItemText, ActionSheetItem, Spinner, TextInput } from '@ketch-com/deck'
import { useAuth } from 'utils/hooks/useAuth'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { UserAvatar } from 'components/avatar/UserAvatar'

type Props = {
  isReady: boolean
  users: UserShortDTO[]
  workflowExecution: MaybeNull<WorkflowExecutionDTO>
  workflowExecutionStep: WorkflowExecutionStepDTO
  onSubmit: (values: { assigneeId: string }) => Promise<void>
  onClose: () => void
}

export const WorkflowExecutionAutomatedStepReassignModal: React.FC<Props> = ({
  isReady,
  users,
  workflowExecution,
  workflowExecutionStep,
  onSubmit,
  onClose,
}) => {
  const { userData } = useAuth()
  const { isPermitted } = useIsPermitted()
  const { assignee, status, stepID } = workflowExecutionStep
  const canReassign = isPermitted(PERMISSIONS.WORKFLOW_TASK_ASSIGN)
  let errorDetails = workflowExecution?.results?.[stepID]?.error

  let level2AppError = errorDetails?.split?.('|')?.[1]?.split?.('level2AppError: ')?.[1]
  if (!level2AppError) {
    level2AppError = errorDetails?.split?.('|')?.[0]?.split?.('level2AppError: ')?.[1]
  }

  const errorDetailsSplitOnResults = errorDetails?.split?.('results: ')?.[1]

  errorDetails = !!level2AppError ? level2AppError : errorDetails

  const parsedErrorDetailsSplitOnResults = !!errorDetailsSplitOnResults
    ? JSON.parse(errorDetailsSplitOnResults)
    : errorDetails || ''

  const initialValues: WorkflowExecutionStepReassignFormValues = useMemo(
    () => ({
      assigneeId: assignee || '',
    }),
    [assignee],
  )

  const renderInput = useCallback((params: AutocompleteRenderInputParams, currentValue: UserShortDTO | null) => {
    return (
      <TextInput
        {...params}
        InputProps={{
          ...params.InputProps,
          startAdornment: params.inputProps.value && (
            <InputAdornment position="start">
              <UserAvatar user={currentValue ? currentValue : {}} size={AvatarSize.small} />
            </InputAdornment>
          ),
          placeholder: 'Select Assignee',
        }}
      />
    )
  }, [])

  const passedDownUsers = useMemo(() => [...users], [users])
  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isValid, isSubmitting, setFieldValue }) => (
        <PopUp
          title="Automated Activity"
          popUpTitleStatusComponent={<WorkflowExecutionStepStatusRendererLegacy status={status} />}
          variant="modal"
          popUpWidth="840px"
          onClose={onClose}
          popUpActionChildren={
            <>
              <Button
                color={!canReassign ? 'primary' : 'secondary'}
                size="large"
                pending={isSubmitting}
                onClick={onClose}
              >
                Close
              </Button>
              {canReassign && (
                <Button color="primary" size="large" onClick={submitForm} disabled={!isValid} pending={isSubmitting}>
                  Reassign
                </Button>
              )}
            </>
          }
        >
          {!isReady ? (
            <Box display="flex" justifyContent="center">
              <Spinner size="32px" thickness={2.5} />
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" gap={4}>
              <Typography>
                An error has caused the activity to fail. Assign to someone on your team to manually intervene.
              </Typography>

              <Box display="flex" flexDirection="column">
                <FormDroplistButton
                  sx={{
                    width: '330px',
                  }}
                  required
                  disableClearable
                  label="Task Assignee"
                  placeholder="Select Assignee"
                  formPropertyName="assigneeId"
                  valueKey="ID"
                  labelValueKey="ID"
                  items={passedDownUsers}
                  getOptionLabel={option =>
                    option.firstName || option.lastName
                      ? `${option.firstName || ''}${option.lastName ? ' ' + option.lastName : ''}`
                      : option.email
                  }
                  isOptionEqualToValue={(option, value) => option.ID === value.ID}
                  renderOption={(props, user, state, isSelected) => (
                    <ActionSheetItem key={user.ID} selected={isSelected} {...props}>
                      <ListItemText selected={isSelected}>
                        <WorkflowExecutionAssignee assignee={user} avatarSize={AvatarSize.small} />
                      </ListItemText>
                    </ActionSheetItem>
                  )}
                  renderInput={renderInput}
                />
                <Button
                  sx={{
                    marginTop: 1,
                    maxWidth: '120px',
                  }}
                  color="secondary"
                  onClick={() => setFieldValue('assigneeId', userData?.userId)}
                >
                  Assign to me
                </Button>
              </Box>
              <Box>
                <Typography variant="label">Error Details</Typography>
                {errorDetails ? (
                  <pre style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                    {JSON.stringify(parsedErrorDetailsSplitOnResults, null, 2) || ''}
                  </pre>
                ) : (
                  <Box>
                    <EmptyValueRenderer />
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </PopUp>
      )}
    </Formik>
  )
}
