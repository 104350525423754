import { useMemo } from 'react'
import * as Yup from 'yup'

import { useCheckIsThemeCodeAvailable } from 'api/themes-v2/mutations/useCheckIsThemeCodeAvailable'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { validationRegExp } from 'utils/constants/forms/validationRegExp'

export const useValidationSchema = ({ isEditMode }: { isEditMode?: boolean } = {}) => {
  const { mutateAsync: handleCheckIsThemeCodeAvailable } = useCheckIsThemeCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsThemeCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(() => {
    const colorValidationSchema = Yup.string().matches(/^#/, 'Only hex values allowed').required('Required')

    return Yup.object().shape({
      ...(!isEditMode && {
        code: codeValidationSchema,
      }),
      name: Yup.string().max(75, 'Name must not exceed 75 characters').required('Required'),

      showWatermark: Yup.bool().required('Required'),
      buttonBorderRadius: Yup.string()
        .matches(validationRegExp.NUMERIC_INTEGER_STRING, 'Should be positive integer number')
        .required('Required'),

      bannerBackgroundColor: colorValidationSchema,
      bannerContentColor: Yup.string(),
      bannerButtonColor: colorValidationSchema,
      bannerPosition: Yup.number().required('Required'),

      modalHeaderBackgroundColor: colorValidationSchema,
      modalHeaderContentColor: Yup.string(),
      modalContentColor: colorValidationSchema,
      modalButtonColor: colorValidationSchema,
      modalPosition: Yup.number().required('Required'),

      formHeaderBackgroundColor: colorValidationSchema,
      formHeaderContentColor: Yup.string(),
      formContentColor: colorValidationSchema,
      formButtonColor: colorValidationSchema,
    })
  }, [isEditMode, codeValidationSchema])
}
