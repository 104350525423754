import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { UnapprovedTracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import pluralize from 'pluralize'
import { Chip } from '@ketch-com/deck'

type Props = {
  tracker: UnapprovedTracker
}

export const Properties: React.FC<Props> = (
  { tracker = {} as UnapprovedTracker },
  ref?: React.ForwardedRef<HTMLSpanElement>,
) => {
  const noOfProperties = tracker?.properties?.length || 0
  return (
    <Tooltip
      title={
        <Box>
          {tracker?.properties?.map((property, index) => {
            return (
              <Box key={index} my={1}>
                <Typography variant="label" color="white.main">
                  {property?.name}
                </Typography>
              </Box>
            )
          })}
        </Box>
      }
      placement="bottom"
    >
      <Box>
        <Chip size="small" label={`${noOfProperties} ${pluralize('Property', noOfProperties)}`} />
      </Box>
    </Tooltip>
  )
}
