import { AssetClassificationTypeDTO, ConfirmationStatusV3DTO } from '@ketch-com/figurehead'
import { useUpdateAssignedDataCategoriesConfirmationStatus } from 'api/dataSystemsClassifications/mutations/useUpdateAssignedDataCategoriesConfirmationStatus'
import { showToast } from 'components/modals/AlertComponent'
import { useCallback } from 'react'

export const useManualClassificationVerification = (installedDataSystemId: string, refetchAssets?: () => void) => {
  const { mutateAsync: handleUpdateAssignedCategories } = useUpdateAssignedDataCategoriesConfirmationStatus({
    onSuccess: async () => {
      if (refetchAssets) refetchAssets()
      showToast({ content: 'Assigned data classification status updated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update assigned data classification status', type: 'error' })
    },
  })

  const handleManualClassificationVerification = useCallback(
    (classification: AssetClassificationTypeDTO, id: string, status: ConfirmationStatusV3DTO) => {
      handleUpdateAssignedCategories({
        params: {
          installedDataSystemId,
          classificationData: {
            classification,
            id,
            status,
          },
        },
      })
    },
    [handleUpdateAssignedCategories, installedDataSystemId],
  )

  return {
    handleManualClassificationVerification,
  }
}
