import { RightInvocationDTO, RightInvocationRequestStatusDTO } from '@ketch-com/figurehead'

export const getStatusText = ({ rightInvocation }: { rightInvocation: RightInvocationDTO }) => {
  const { requestStatus } = rightInvocation

  switch (requestStatus) {
    case RightInvocationRequestStatusDTO.FulfilledRightInvocationRequestStatus:
      return 'Completed'
    case RightInvocationRequestStatusDTO.RejectedRightInvocationRequestStatus:
      return 'Rejected'
    case RightInvocationRequestStatusDTO.DueSoonRightInvocationRequestStatus:
      return 'Due soon'
    case RightInvocationRequestStatusDTO.DueTodayRightInvocationRequestStatus:
      return 'Due today'
    case RightInvocationRequestStatusDTO.OverdueRightInvocationRequestStatus:
      return 'Overdue'
    case RightInvocationRequestStatusDTO.OnTrackRightInvocationRequestStatus:
      return 'On track'
    default:
      return 'On track'
  }
}
