import { RightDTO } from 'interfaces/rights/right'
import React from 'react'
import Typography from '@mui/material/Typography'
import { FormInlineEdit } from 'components/form/FormInlineEdit'
import { DataGrid, TableCell, FormRow } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

type Props = {
  rights: RightDTO[]
}

export const PolicyScopeAppeals: React.FC<Props> = ({ rights }) => {
  const rows = [
    { label: 'Time to Invoke, Days', inputKey: 'timeToInvokeAppealInDays' },
    { label: 'Time to Fulfill, Days', inputKey: 'timeToFulfillAppealInDays' },
  ]

  type RowType = (typeof rows)[0]

  const gridColumns = [
    {
      field: 'label',
      headerName: '',
      width: 200,
      sortable: false,
      renderCell: ({ row: { label } }: GridRenderCellParams<RowType>) => (
        <TableCell sx={{ padding: '5px 0 5px 18px' }}>
          <Typography variant="body">{label}</Typography>
        </TableCell>
      ),
    },
    {
      field: 'inputKey',
      headerName: '',
      width: 200,
      sortable: false,
      renderCell: ({ row: { inputKey } }: GridRenderCellParams<RowType>) => {
        return (
          <TableCell sx={{ padding: '5px 0 5px 18px' }}>
            <FormInlineEdit id={inputKey} formPropertyName={inputKey} placeholder="Add days" type="number" />
          </TableCell>
        )
      },
    },
  ]

  return (
    <FormRow
      title="Appeals"
      subTitle="Specify timeframes for the Data Subject to invoke their right to appeal after rejection and the time allotted to fulfill the Data Subject's appeal."
    >
      <DataGrid
        columnHeaderHeight={0}
        rowCount={rows.length}
        rows={rows}
        columns={gridColumns}
        autosizeOnMount
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableChildrenSorting
        disableColumnResize
        hideFooter
        disableRowSelectionOnClick
        getRowId={({ inputKey }) => inputKey}
        sx={{
          padding: 0,
          '.MuiDataGrid-cell': { padding: '0' },
          '& .MuiDataGrid-columnHeader[role="columnheader"]:hover': {
            backgroundImage: 'unset',
          },
        }}
        getRowHeight={() => 'auto'}
      />
    </FormRow>
  )
}
