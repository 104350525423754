import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIListRequestParams, APIListResponse, formatOrdering, Ordering } from 'api/common/utils'

type Params = APIListRequestParams<{
  organizationCode: string
  identitySpaceCode: string
  includeIssues?: boolean
  ordering?: Ordering<'version'>
}>

type Response = APIListResponse<{ identitySpaces: IdentitySpaceDTO[] }>

export const fetchIdentitySpaceVersions = ({
  organizationCode,
  identitySpaceCode,
  includeMetadata,
  includeIssues,
  start,
  limit,
  ordering,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/identity-spaces/${identitySpaceCode}/versions`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
          ordering: formatOrdering(ordering),
        },
      },
    }),
  )
