import React from 'react'
import { useField, FieldValidator } from 'formik'

import { Hint } from 'components/ui-kit/form/common/hint/Hint'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { Switch, Props as BaseSwitchProps } from 'components/ui-kit/switch/Switch'
import { Box } from '@mui/material'

type OmittedProps = Omit<BaseSwitchProps, 'name' | 'value'>

interface Props extends OmittedProps {
  /** Formik field name */
  name: string
  /** Text to be displayed below Component */
  hint?: string
  /** Text to be displayed above Component */
  label?: string
  /** Display Asterisk as required field */
  required?: boolean
  /** Custom validation method */
  validate?: FieldValidator
  /** Should label be displayed inline? (Default: false) */
  inlineLabel?: boolean
  /** Custom onChange method */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

/**
 * -
 */
export const FormSwitch: React.FC<Props> = ({
  name,
  hint = '',
  label = '',
  required = false,
  validate,
  onChange: onChangeOuter,
  inlineLabel = false,
  ...baseSwitchProps
}) => {
  const [field, meta] = useField({ name, validate, type: 'checkbox' })
  const showError = meta.error && meta.touched

  return (
    <Box display={inlineLabel ? 'block' : 'inline-block'}>
      <Box display={inlineLabel ? 'inline-flex' : 'block'} alignItems="center" flexDirection="row-reverse" gap={1.25}>
        {!!label && (
          <Label id={baseSwitchProps.id} required={required} hasMarginBottom={!inlineLabel}>
            {label}
          </Label>
        )}

        <Switch
          {...field}
          {...baseSwitchProps}
          onChange={(e, checked) => {
            field.onChange(e)
            onChangeOuter?.(e, checked)
          }}
        />
      </Box>

      {showError && <Error>{meta.error}</Error>}

      {hint && !showError && <Hint>{hint}</Hint>}
    </Box>
  )
}
