import React, { useMemo, useEffect } from 'react'
import { useFormikContext, FieldArray, ErrorMessage } from 'formik'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import { Button, Checkbox, FormRow, Icon, ListItemText, ActionSheetItem } from '@ketch-com/deck'
import { COUNTRIES, EmbargoedCountryCodes } from 'utils/constants/countries'
import { DataRoleType, DATA_ROLE_OPTIONS } from 'interfaces/events'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { getFormInputType } from './utils'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { AddFieldRow } from '../components'
import { RightDTO } from 'interfaces/rights/right'
import { RightInvocationFormValues } from 'interfaces/rightInvocations'
import { RightsFulfillment } from 'interfaces/rightsFulfillments/rightsFulfillment'
import Typography from '@mui/material/Typography'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { FormInput } from 'components/form/FormInput'
import { CreateRightInvocationDataGrid } from './CreateRightInvocationDataGrid'

type Props = {
  rights: RightDTO[]
  identitySpaces: IdentitySpaceDTO[]
  policyScopes: PolicyScopeDTO[]
  dataSubjectTypes: DataSubjectTypeDTO[]
}

export const CreateRightInvocationForm: React.FC<Props> = ({
  rights,
  policyScopes,
  identitySpaces,
  dataSubjectTypes,
}) => {
  const { values, isSubmitting, setFieldValue, setFieldTouched, errors, touched } =
    useFormikContext<RightInvocationFormValues>()

  const rightsFulfillment = useMemo(() => {
    const testRightFulfillment: RightsFulfillment = { rightCode: 'test', fulfillment: 365 }
    const rightsFulfillmentPlus =
      policyScopes.find(({ code }) => values.policyScopeCode === code)?.rightsFulfillment || []
    rightsFulfillmentPlus.push(testRightFulfillment)
    return rightsFulfillmentPlus
  }, [policyScopes, values.policyScopeCode])

  const rightsItems = useMemo(
    () => rights.filter(({ code }) => !!rightsFulfillment.find(({ rightCode }) => code === rightCode)),
    [rightsFulfillment, rights],
  )

  const selectedDataSubjectType = dataSubjectTypes.find(dst => dst.code === values.dataSubjectTypeCode)

  useEffect(() => {
    if (values.rightCode && !rightsFulfillment.find(({ rightCode }) => rightCode === values.rightCode)) {
      setFieldValue('rightCode', '')
    }
  }, [values.rightCode, rightsFulfillment]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <FormRow title="Basic Details">
        {/* Row 1 */}

        <Box display="flex" flexDirection="column" gap={3}>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <FormDroplistButton
                disableClearable
                disabled={isSubmitting}
                formPropertyName="policyScopeCode"
                fullWidth
                hideOptionalLabel
                items={policyScopes}
                label="Jurisdiction"
                labelValueKey="name"
                placeholder="Select Type"
                required
                valueKey="code"
                renderOption={(props, option, state, isSelected) => {
                  return (
                    <ActionSheetItem selected={isSelected} {...props}>
                      <ListItemText selected={isSelected}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="body">{option.name}</Typography>
                          <Typography variant="footnote">{option.code}</Typography>
                        </Box>
                      </ListItemText>
                    </ActionSheetItem>
                  )
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormDroplistButton
                disableClearable
                disabled={isSubmitting || !rightsItems.length}
                formPropertyName="rightCode"
                fullWidth
                items={rightsItems}
                label="Request Type"
                placeholder="Select Type"
                required
                valueKey="code"
              />
            </Grid>

            <Grid item xs={3}>
              <FormRadioGroup
                sx={{
                  height: '44px',
                }}
                hideOptionalLabel
                formPropertyName="dataRole"
                label="Data Role"
                row
                valueKey="id"
                renderLabel={({ name }) => <Typography>{name}</Typography>}
                options={DATA_ROLE_OPTIONS}
              />
            </Grid>
          </Grid>

          {/* Row 2 */}

          <Grid container spacing={4}>
            <Grid item xs={3}>
              {values.dataRole !== DataRoleType.DATA_ROLE_PROCESSOR && (
                <FormDroplistButton
                  required
                  disableClearable
                  fullWidth
                  formPropertyName="dataSubjectTypeCode"
                  label="Subject Type"
                  placeholder="Select Data Subject"
                  items={dataSubjectTypes.map(({ code, name }) => ({ id: code, name }))}
                  disabled={isSubmitting}
                  hideOptionalLabel
                  valueKey="id"
                  renderOption={(props, option, state, isSelected) => {
                    return (
                      <ActionSheetItem selected={isSelected} {...props}>
                        <ListItemText selected={isSelected}>
                          <Box display="flex" flexDirection="column">
                            <Typography variant="body">{option.name}</Typography>
                            <Typography variant="footnote">{option.id}</Typography>
                          </Box>
                        </ListItemText>
                      </ActionSheetItem>
                    )
                  }}
                />
              )}
            </Grid>
            {selectedDataSubjectType && selectedDataSubjectType.requiresUserInput ? (
              <Grid item xs={6}>
                <FormInput
                  required
                  fullWidth
                  formPropertyName="dataSubjectTypeRelationshipDetails"
                  label="Tell us about your relationship to us"
                />
              </Grid>
            ) : null}
          </Grid>

          {/* Subject Personal Details Section */}
          {/* Standard Inputs: firstName, lastName, email are always collected/required */}
          <Box sx={{ maxWidth: 410 }}>
            <FormInput required fullWidth formPropertyName="first" label="First Name" />
          </Box>
          <Box sx={{ maxWidth: 410 }}>
            <FormInput required fullWidth formPropertyName="last" label="Last Name" />
          </Box>
          <Box sx={{ maxWidth: 410 }}>
            <FormInput required fullWidth formPropertyName="email" label="Email Address" />
          </Box>
          <Box display="flex" flexDirection="column" gap={3} mb={3}>
            {/* Optional Fields  */}
            <FieldArray
              name="formData"
              render={({ push, remove }) => {
                return (
                  <>
                    {(values?.formData || []).map((field, fieldIndex) => {
                      const formInputType = getFormInputType(field)

                      if (formInputType === 'dropdown') {
                        const items =
                          field.code === 'country'
                            ? COUNTRIES.filter(c => !EmbargoedCountryCodes.includes(c.id))
                            : (field?.options || []).map(({ label, value }) => ({ id: value, name: label }))

                        return (
                          <Box display="flex" key={field.id}>
                            <Box sx={{ minWidth: 410, mr: 1.5 }}>
                              <FormDroplistButton
                                required
                                fullWidth
                                formPropertyName={`formData.${fieldIndex}.value`}
                                disableClearable
                                label={field.title!}
                                items={items}
                                placeholder={field?.hint}
                                valueKey="id"
                              />
                            </Box>

                            <Box display="flex" pt={3}>
                              <Button variant="iconCustomRounded" color="tertiary" onClick={() => remove(fieldIndex)}>
                                <Icon name="FBin" />
                              </Button>
                            </Box>
                          </Box>
                        )
                      }

                      if (formInputType === 'textarea') {
                        return (
                          <Box display="flex" key={field.id} maxWidth={962}>
                            <Box sx={{ minWidth: 410, mr: 1.5 }}>
                              <FormInput
                                multiline
                                rows={3}
                                required
                                fullWidth
                                formPropertyName={`formData.${fieldIndex}.value`}
                                label={field.title!}
                                placeholder={field?.hint}
                              />
                            </Box>

                            <Box display="flex" pt={1} mt={2}>
                              <Button variant="iconCustomRounded" color="tertiary" onClick={() => remove(fieldIndex)}>
                                <Icon name="FBin" />
                              </Button>
                            </Box>
                          </Box>
                        )
                      }

                      if (formInputType === 'checkbox') {
                        const checkboxItems = (field?.options || []).map(({ label, value }) => ({
                          id: value,
                          name: label,
                        }))

                        const isTouched = (touched?.formData as RightInvocationFormValues['formData'])?.[fieldIndex]
                          ?.values

                        const hasError = errors?.formData?.[fieldIndex]

                        const showError = hasError && isTouched

                        return (
                          <Box display="flex" key={field.id} flexDirection="column">
                            <Typography variant="label" id={`checkbox-group-${fieldIndex}`}>
                              {field?.name || field?.title}
                            </Typography>

                            <Box display="flex" maxWidth={962} mb={0.5} flex={1}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                flex={1}
                                sx={{
                                  py: 1.5,
                                  pl: 1.5,
                                  borderRadius: 1.25,
                                  border: '1px solid',
                                  borderColor: showError ? 'chileanFire.main' : 'iron.main',
                                }}
                              >
                                {checkboxItems.map(item => {
                                  const isChecked = values?.formData?.[fieldIndex]?.values?.includes(item?.id || '')
                                  return (
                                    <Box key={item.id} display="flex" alignItems="center" gap={0.25} mr={2} mb={1}>
                                      <Checkbox
                                        key={item.id}
                                        id={item.id}
                                        name={item.id}
                                        checked={!!isChecked}
                                        onChange={event => {
                                          const currentValues = values?.formData?.[fieldIndex]?.values || []

                                          if (event.target.checked) {
                                            setFieldValue(`formData.${fieldIndex}.values`, [...currentValues, item.id])
                                            setFieldTouched(`formData.${fieldIndex}.values`, true)
                                          } else {
                                            const newValues = currentValues.filter(value => value !== item.id)
                                            setFieldValue(`formData.${fieldIndex}.values`, newValues)
                                            setFieldTouched(`formData.${fieldIndex}.values`, true)
                                          }
                                        }}
                                      />
                                      <Typography variant={isChecked ? 'label' : 'body'}>{item?.name}</Typography>
                                    </Box>
                                  )
                                })}
                              </Box>
                              <Box pl={1.5} pt={0.5}>
                                <Button variant="iconCustomRounded" color="tertiary" onClick={() => remove(fieldIndex)}>
                                  <Icon name="FBin" />
                                </Button>
                              </Box>
                            </Box>

                            <ErrorMessage name={`formData.${fieldIndex}.values`}>
                              {msg => {
                                return (
                                  <Typography color="chileanFire.main" variant="body">
                                    {msg}
                                  </Typography>
                                )
                              }}
                            </ErrorMessage>
                          </Box>
                        )
                      }

                      return (
                        <Box display="flex" key={field.id}>
                          <Box sx={{ minWidth: 410, mr: 1.5 }}>
                            <FormInput
                              required
                              fullWidth
                              formPropertyName={`formData.${fieldIndex}.value`}
                              label={field.title!}
                              placeholder={field?.hint}
                            />
                          </Box>

                          <Box display="flex" pt={3}>
                            <Button variant="iconCustomRounded" color="tertiary" onClick={() => remove(fieldIndex)}>
                              <Icon name="FBin" />
                            </Button>
                          </Box>
                        </Box>
                      )
                    })}

                    <AddFieldRow push={push} />
                  </>
                )
              }}
            />
          </Box>
        </Box>
      </FormRow>

      <FormRow title="Identity" hasTopBorder>
        <CreateRightInvocationDataGrid identitySpaces={identitySpaces} />
      </FormRow>
    </Box>
  )
}
