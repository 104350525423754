import React, { useState } from 'react'
import { addDays, differenceInDays } from 'date-fns'
import { DateRange } from 'react-day-picker'

import { useAppDispatch, useAppSelector } from 'store/hooks'

import 'react-day-picker/dist/style.css'
import { getPermitStatisticsFilterState } from 'store/permitStatisticsFilterStateSlice/selectors'
import { updateDateRange } from 'store/permitStatisticsFilterStateSlice'

const currentDate = new Date()

const defaultSelected: DateRange = {
  from: addDays(currentDate, -6),
  to: addDays(currentDate, 0),
}

export const useDateRangePickerUtils = () => {
  const dispatch = useAppDispatch()
  const { startDate, endDate } = useAppSelector(getPermitStatisticsFilterState)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected)
  const startEndDifferenceInDays = differenceInDays(range?.to || new Date(), range?.from || new Date())

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseWithoutApply = () => {
    // put local component state back to what the redux store state is
    setRange({ from: new Date(startDate), to: new Date(endDate) })
    setAnchorEl(null)
  }

  React.useEffect(() => {
    const syncReduxStateWithComponentState = async () => {
      if (startDate && endDate) {
        setRange({ from: new Date(startDate), to: new Date(endDate) })
      } else {
        const payload = { startDate: range?.from?.toISOString() || '', endDate: range?.to?.toISOString() || '' }
        dispatch(updateDateRange(payload))
      }
    }

    syncReduxStateWithComponentState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isOpen = Boolean(anchorEl)
  const popoverId = isOpen ? 'simple-popover' : undefined

  const differenceInDaysLocalComponentRange = differenceInDays(
    range?.from || addDays(currentDate, -7),
    range?.to || currentDate,
  )

  // disable future dates and dates older than 1 year
  const disabledDays = [
    { from: addDays(currentDate, -1095), to: addDays(currentDate, -365) },
    { from: addDays(currentDate, 1), to: addDays(currentDate, 1095) },
  ]

  return {
    anchorEl,
    handleClick,
    handleCloseWithoutApply,
    isOpen,
    popoverId,
    setAnchorEl,
    range,
    setRange,
    startEndDifferenceInDays,
    differenceInDaysLocalComponentRange,
    disabledDays,
  }
}
