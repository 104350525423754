import React, { useEffect, useState } from 'react'

import { RightDTO } from 'interfaces/rights/right'
import { RegulationDTO } from 'interfaces/regulations/regulation'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import {
  getAllRightsSelectedSortedByStatus,
  matchAndAddFulfillmentDataToRight,
} from 'utils/helpers/rightsRegulationsHelpers'
import { Icon, theme, InfoRow, DataGrid, TableCell, EmptyState } from '@ketch-com/deck'
import { StatusRenderer } from 'components/renderers/StatusRenderer'
import Box from '@mui/material/Box'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import Typography from '@mui/material/Typography'
import pluralize from 'pluralize'
import { ensureArray } from 'utils/helpers/array'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

interface TableProps {
  rights: RightDTO[]
  rightsFulfillment: PolicyScopeDTO['rightsFulfillment']
  rightsCodes: string[]
}

const AppealsPermittedTable: React.FC<TableProps> = ({ rights, rightsFulfillment, rightsCodes }) => {
  const [rows, setRowData] = useState<any>([])
  useEffect(() => {
    const fetchData = async () => {
      const rightsWithFulfillment = await matchAndAddFulfillmentDataToRight(rights, rightsFulfillment)
      const rowsData = rightsWithFulfillment.map((right, index) => ({ id: index, ...right }))
      setRowData(rowsData)
    }

    fetchData()
  }, [rights, rightsFulfillment, rightsCodes])

  type RowType = (typeof rows)[0]

  const gridColumns = [
    {
      field: 'Status',
      headerName: 'Status',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const { code } = row
        return (
          <TableCell sx={{ padding: '5px 0 5px 18px' }}>
            <StatusRenderer active={rightsCodes.includes(code)} activeTitle="Included" inactiveTitle="Excluded" />
          </TableCell>
        )
      },
      width: 143,
      sortable: false,
    },
    {
      field: 'Right',
      headerName: 'Right',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const { code, name } = row
        return (
          <TableCell sx={{ padding: '5px 0 5px 18px' }}>
            <NameAndCodeCellRenderer isNameBold name={name} code={code} />
          </TableCell>
        )
      },
      width: 190,
      sortable: false,
    },
    {
      field: 'Display',
      headerName: 'Display Name',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const { translations, customRightData } = row
        return (
          <TableCell sx={{ padding: '5px 0 5px 18px' }}>
            <Typography>{customRightData.name ? customRightData.name : translations.en.name}</Typography>
          </TableCell>
        )
      },
      width: 190,
      sortable: false,
    },
    {
      field: 'Description',
      headerName: 'Description',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const { description } = row
        return (
          <TableCell sx={{ padding: '5px 0 5px 18px' }}>
            <Typography>{description || 'None'}</Typography>
          </TableCell>
        )
      },
      width: 390,
      sortable: false,
    },
    {
      field: 'Fulfillment Time',
      headerName: 'Fulfillment Time',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const { code } = row

        const time = ensureArray(rightsFulfillment).find(({ rightCode }) => rightCode === code)?.fulfillment

        const content = time ? `${time} days` : 'None'

        return (
          <TableCell sx={{ padding: '5px 0 5px 18px' }}>
            <Typography>{content}</Typography>
          </TableCell>
        )
      },
      width: 131,
      sortable: false,
    },
    {
      field: 'Right to Appeal',
      headerName: 'Right to Appeal',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const { code } = row

        const isSupported = ensureArray(rightsFulfillment).find(({ rightCode }) => rightCode === code)?.allowAppeal
        return (
          <TableCell sx={{ padding: '5px 0 5px 18px' }}>
            <Box display="flex" alignItems="center" gap={0.5}>
              <Icon
                name={isSupported ? 'OCheck' : 'OCross'}
                iconColor={isSupported ? theme.palette.darkDusk.main : theme.palette.darkDuskFaded.main}
              />

              <Typography variant="label" color={isSupported ? 'darkDusk.main' : 'darkDuskFaded.main'}>
                {isSupported ? 'Supported' : 'Not Supported'}
              </Typography>
            </Box>
          </TableCell>
        )
      },
      width: 169,
      sortable: false,
    },
  ]

  return (
    <>
      <DataGrid
        rowCount={rows.length}
        rows={rows}
        columns={gridColumns}
        autosizeOnMount
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableChildrenSorting
        disableColumnResize
        disableRowHoverStates
        hideFooter
        disableRowSelectionOnClick
        getRowId={({ id }) => id}
        sx={{
          padding: 0,
          '.MuiDataGrid-cell': { padding: '0' },
          '& .MuiDataGrid-columnHeader[role="columnheader"]:hover': {
            backgroundImage: 'unset',
          },
        }}
        getRowHeight={() => 'auto'}
      />
    </>
  )
}

interface AppealDetailsProps {
  policyScope?: PolicyScopeDTO
}

const AppealDetails: React.FC<AppealDetailsProps> = ({ policyScope }) => {
  const hasAtLeastOneRightWithAllowAppeal = ensureArray(policyScope?.rightsFulfillment)?.some(
    ({ allowAppeal }) => allowAppeal,
  )

  if (!hasAtLeastOneRightWithAllowAppeal) return null

  return (
    <>
      <Typography variant="h4" color="darkDuskFaded.main" mt={8} mb={2}>
        Appeal Details
      </Typography>

      <InfoRow sx={{ marginBottom: '32px' }} title="Time to Invoke">
        <Typography variant="body">
          {policyScope?.timeToInvokeAppealInDays || 0}{' '}
          {pluralize('day', parseInt(policyScope?.timeToInvokeAppealInDays || '0'))}
        </Typography>
      </InfoRow>

      <InfoRow title="Time to Fulfill">
        <Typography variant="body">
          {policyScope?.timeToFulfillAppealInDays || 0}{' '}
          {pluralize('day', parseInt(policyScope?.timeToFulfillAppealInDays || '0'))}
        </Typography>
      </InfoRow>
    </>
  )
}

interface Props {
  policyScope: PolicyScopeDTO
  rights: RightDTO[]
  regulations: RegulationDTO[]
}

export const PolicyScopeRights: React.FC<Props> = ({ policyScope, rights, regulations }) => {
  const { rightsFulfillment = [], regulationCodes = [] } = policyScope
  const rightsCodes = rightsFulfillment.map(({ rightCode }) => rightCode)

  const allRightsSelectedSortedByStatus = getAllRightsSelectedSortedByStatus({
    regulationCodes,
    regulations,
    rights,
    rightsCodes,
  })

  return (
    <>
      {allRightsSelectedSortedByStatus.length ? (
        <AppealsPermittedTable
          rights={allRightsSelectedSortedByStatus}
          rightsFulfillment={rightsFulfillment}
          rightsCodes={rightsCodes}
        />
      ) : (
        <EmptyState iconName="OProfile" title="No rights are currently associated with selected regulations" />
      )}

      <AppealDetails policyScope={policyScope} />
    </>
  )
}
