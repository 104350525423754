import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { updateAuthenticator } from '../fetchers/updateAuthenticator'

const useCustomMutation = createUseMutation({
  mutationFn: updateAuthenticator,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateAuthenticator = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
