import { Field, useFormikContext } from 'formik'
import { RelatedPath } from 'pages/assetManager/dsrConfig/interfaces'
import { getRelatedDataset } from 'pages/assetManager/dsrConfig/utils'
import { DsrIdentityLookupFormData } from 'pages/assetManager/dsrIdentityLookup/interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'

type Props = {
  relatedPaths: RelatedPath[]
  assetsSummary: AssetSummaryDTO[]
}

export const RelatedPathsRadio: React.FC<Props> = ({ relatedPaths, assetsSummary }) => {
  const { setFieldValue, isSubmitting } = useFormikContext<DsrIdentityLookupFormData>()
  const { datasetId: relatedDataSetId } = getRelatedDataset(relatedPaths[0])

  const relatedAssetSummary = assetsSummary.filter(({ asset }) => asset?.resource?.id === relatedDataSetId)?.[0]
  const relatedDatasetHasIdentitySpace = Boolean(relatedAssetSummary?.asset?.childIdentitySpaces?.length)

  return (
    <Field
      type="radio"
      name="datasetId"
      disabled={isSubmitting}
      value={relatedDataSetId}
      onClick={() => {
        setFieldValue('isPrimaryDataset', false)
        setFieldValue('relatedDatasetHasIdentitySpace', relatedDatasetHasIdentitySpace)
      }}
    />
  )
}
