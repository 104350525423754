import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchUsage } from '../fetchers/fetchUsage'
import { useAuth } from 'utils/hooks/useAuth'
import { GetUsageParams } from '@ketch-com/harbormaster/dist/organization/plans.schemas'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.orgUsage,
  queryFn: fetchUsage,
  select: res => res?.data || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery, 'organizationCode'> & GetUsageParams

export const useUsage = (config: Config) => {
  const { userData } = useAuth()
  return useCustomQuery({
    ...config,
    params: {
      ...config.params,
      organizationCode: userData.organizationCode,
    },
  })
}
