import React, { useState } from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import Box from '@mui/material/Box'

import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { DeletePolicyModal } from './DeletePolicyModal'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  resources: any[]
  policyId: string
}

const useStyles = makeStyles(
  () =>
    createStyles({
      hidden: {
        opacity: 0,
      },
    }),
  { name: '' },
)

export const ActionsCellRenderer: React.FC<Props> = ({ resources, policyId }) => {
  const [isDeletePolicyModalOpen, setIsDeletePolicyModalOpen] = useState<boolean>(false)
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <>
      <Box display="flex" flexDirection="column">
        {resources.map((r: any, index) => (
          <Box
            key={r.name}
            mr={1.25}
            mb={index !== resources.length - 1 ? 3.25 : 1.75}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {index === 0 ? (
              <ButtonOptions
                size="small"
                items={[
                  {
                    content: 'Edit',
                    icon: <EditOutlined />,
                    onClick: () => {
                      navigate(RoutesManager.policyCenter.policies.upsert.getURL({ id: policyId }))
                    },
                  },
                  {
                    content: 'Delete',
                    icon: <DeleteOutline />,
                    onClick: () => setIsDeletePolicyModalOpen(true),
                  },
                ]}
              />
            ) : (
              <ButtonOptions className={classes.hidden} size="small" disabled items={[]} />
            )}
          </Box>
        ))}
      </Box>

      {isDeletePolicyModalOpen && (
        <DeletePolicyModal
          policyId={policyId}
          onCancel={() => setIsDeletePolicyModalOpen(false)}
          onSubmit={async () => {
            setIsDeletePolicyModalOpen(false)
          }}
        />
      )}
    </>
  )
}
