import { PreferenceCenterPage } from '@ketch-sdk/ketch-types'
import { useExperience } from 'api/experiences-v2/queries/useExperience'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { useThemes } from 'api/themes-v3/queries/useThemes'
import { ConsentExperienceType } from 'interfaces/experiences-v2/consentExperienceType'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useIsPermitted } from 'utils/hooks'
import { IDParams } from 'utils/routing/routes/utils'
import { RoutesManager } from 'utils/routing/routesManager'
import { PreviewDisplayMode } from './enums'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { PolicyScopeDTO } from 'interfaces/policyScopes-v2/policyScope'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { usePreviewConfig } from 'api/experiences-v2/queries/usePreviewConfig'
import { useDeploymentPlans } from 'api/deploymentPlans-v2/queries/useDeploymentPlans'

export const useExperienceViewUtils = () => {
  // Utils
  const { id } = useParams<IDParams>()
  const location = useLocation()
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()

  const handleRedirectToExperiencesList = () => navigate(RoutesManager.deployment.experiencesV2.root.getURL())
  const { data: experience, isLoading: isExperienceLoading } = useExperience({
    // TODO:JB - Disable when fetching version
    // enabled: !version,
    params: {
      id: id || '',
      includeMetadata: true,
      includeIssues: true,
    },
    onError: handleRedirectToExperiencesList,
  })

  // Preview data
  const { data: languages, isLoading: isLanguagesLoading } = useOrganizationLanguages()
  const { data: themes, isLoading: isThemesLoading } = useThemes()
  const { data: jurisdictions, isLoading: isJurisdictionsLoading } = usePolicyScopes()
  const languageOptions = [
    { language: { englishName: 'English' }, enabled: true } as LanguageWithStatusDTO,
    ...languages,
  ]
  const jurisdictionOptions = [{ name: 'Global Standard' } as PolicyScopeDTO, ...jurisdictions]
  // const themeOptions = [{ name: 'Default Theme', content: defaultThemeV3DTO.content }, ...themes]
  const themeOptions = isThemesLoading
    ? []
    : themes.sort((themeA, themeB) => {
        if (themeA?.id?.includes('default')) {
          return -1 // themeA should come before b
        } else if (themeB?.id?.includes('default')) {
          return 1 // themeB should come before a
        }
        return 0 // Keep original order if neither is 'default'
      })

  // Preview state
  const [consentExperienceType, setConsentExperienceType] = useState<ConsentExperienceType>(
    ConsentExperienceType.Banner,
  )
  const [displayMode, setDisplayMode] = useState<PreviewDisplayMode>(PreviewDisplayMode.Desktop)
  const [preferenceCenterPage, setPreferenceCenterPage] = useState<PreferenceCenterPage>(PreferenceCenterPage.Welcome)
  const [language, setLanguage] = useState<LanguageWithStatusDTO>(languageOptions[0])
  const [jurisdiction, setJurisdiction] = useState<PolicyScopeDTO>(jurisdictionOptions[0])
  const [theme, setTheme] = useState<ThemeV3DTO>(themeOptions[0])
  const [isPreviewReady, setIsPreviewReady] = useState(false)

  const isReady = !isExperienceLoading && !isLanguagesLoading && !isJurisdictionsLoading && !isThemesLoading

  const { data: previewConfig, isLoading: isPreviewConfigLoading } = usePreviewConfig({
    enabled: !isReady,
    params: {
      experienceID: experience?.id || '',
      themeID: theme?.id || '',
      languageCode: language.language.code,
      jurisdictionCode: jurisdiction.code,
      // deploymentID: '',
    },
  })

  // Deployments data
  const { data: deploymentPlans, isLoading: isDeploymentPlansLoading } = useDeploymentPlans({
    enabled: !!experience?.propertyEnvironments?.length,
  })

  // TODO:JB - Versions
  // const { data: experienceVersion, isLoading: isExperienceVersionLoading } = useExperienceVersion({
  //   enabled: !!version,
  //   params: {
  //     experienceCode: code!,
  //     version: version!,
  //   },
  //   onError: handleRedirectToExperiencesList,
  // })

  // TODO:JB - Versions
  // const { data: versions, isLoading: isVersionsLoading } = useExperienceVersions({
  //   params: {
  //     experienceCode: code!,
  //     includeMetadata: true,
  //     ordering: {
  //       version: 'desc',
  //     },
  //   },
  // })

  return {
    location,
    navigate,
    isPermitted,
    experience,
    isReady,
    consentExperienceType,
    setConsentExperienceType,
    displayMode,
    setDisplayMode,
    preferenceCenterPage,
    setPreferenceCenterPage,
    language,
    languageOptions,
    setLanguage,
    jurisdiction,
    jurisdictionOptions,
    setJurisdiction,
    theme,
    themeOptions,
    setTheme,
    isPreviewReady,
    setIsPreviewReady,
    previewConfig,
    isPreviewConfigLoading,
    deploymentPlans,
    isDeploymentPlansLoading,
  }
}

export type ExperienceViewUtils = ReturnType<typeof useExperienceViewUtils>
