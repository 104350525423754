import React from 'react'
import { styled } from '@mui/material/styles'
import { SxProps, Theme } from '@mui/material'
import Box from '@mui/material/Box'

import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'
import { Text } from 'components/ui-kit/typography/Text'
import { Switch } from 'components/ui-kit/switch/Switch'

const PREFIX = 'IdentityVerificationForm'

const classes = {
  verificationSwitchContainer: `${PREFIX}-verificationSwitchContainer`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`& .${classes.verificationSwitchContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2, 2.25),
  },
}))

interface IIdentityVerificationFormProps {
  requisiteForm?: React.ReactNode
  title: string
  value: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  sx?: SxProps<Theme>
  disabled?: boolean
  tooltipContent?: React.ReactNode
  shouldHide?: boolean
}

export const VerificationOption: React.FC<IIdentityVerificationFormProps> = ({
  value,
  onChange,
  title,
  sx,
  requisiteForm,
  disabled,
  tooltipContent,
  shouldHide,
}) => {
  if (shouldHide) return null

  return (
    <StyledBox display="flex" flexDirection="column">
      <Box
        className={classes.verificationSwitchContainer}
        sx={{
          backgroundColor: ({ palette }) => (value ? palette.tara.main : 'transparent'),
          borderRadius: ({ spacing }) => spacing(1.375),
          ...sx,
        }}
      >
        <Text
          size={16}
          weight={700}
          color={value ? 'greenHaze' : undefined}
          sx={{
            opacity: disabled ? 0.5 : 1,
          }}
        >
          {title}
        </Text>
        <Tooltip content={tooltipContent}>
          <Switch disabled={disabled} value={value} onChange={e => onChange(e)} />
        </Tooltip>
      </Box>

      {!!value ? requisiteForm : null}
    </StyledBox>
  )
}
