import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  applicationCode: string
  organizationCode: string
}>

export const deleteApplication = ({ organizationCode, applicationCode }: Params) =>
  API.delete<null>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/applications/${applicationCode}`,
    }),
  )
