import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { ObjectLiteral } from 'interfaces'
import { ApplicationDTO } from 'interfaces/applications/application'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans/deploymentPlan'
import { APPLICATION_PLATFORM_NAME } from 'interfaces/applications/applicationPlatform'
import { RoutesManager } from 'utils/routing/routesManager'
import { useApplications } from 'api/applications/queries/useApplications'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { Table } from 'components/ui-layouts/table/Table'
import { Badge } from 'components/ui-kit/badge/Badge'
import { Button } from '@ketch-com/deck'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { SectionTitle } from 'components/ui-kit/typography/compositions/SectionTitle'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'

import { ReactComponent as EmptyStateDeploymentPlansIcon } from 'assets/icons/emptyState/EmptyStateDeploymentPlans.svg'

type Props = {
  deploymentPlan: DeploymentPlanDTO
}

export const DeploymentPlanDeployments: React.FC<Props> = ({ deploymentPlan }) => {
  const navigate = useNavigate()
  const { applicationEnvironments } = deploymentPlan

  const { data: applications, isLoading: isApplicationsLoading } = useApplications({
    enabled: !!applicationEnvironments?.length,
  })

  const applicationsMap = useMemo(
    () =>
      applications.reduce<ObjectLiteral<ApplicationDTO>>(
        (acc, application) => ({
          ...acc,
          [application.ID]: application,
        }),
        {},
      ),
    [applications],
  )

  if (isApplicationsLoading) {
    return <Spinner padding={50} />
  }

  return (
    <>
      <SectionTitle>Deployment Summary</SectionTitle>

      {applicationEnvironments?.length ? (
        <>
          <Table
            items={applicationEnvironments}
            cellSettings={{
              application: {
                label: 'Property',
                width: 250,
                cellRenderer: ({ applicationName, applicationID }) => (
                  <NameAndCodeCellRenderer name={applicationName} code={applicationsMap[applicationID]?.code} />
                ),
              },
              type: {
                label: 'Type',
                width: 150,
                cellRenderer: ({ applicationPlatform }) => (
                  <Badge>{APPLICATION_PLATFORM_NAME[applicationPlatform]}</Badge>
                ),
              },
              environment: {
                label: 'Environment',
                width: 200,
                cellRenderer: ({ code }) => code,
              },
              pattern: {
                label: 'Pattern',
                cellRenderer: ({ pattern }) => pattern,
              },
              viewButton: {
                width: 110,
                cellRenderer: ({ applicationID }) => (
                  <Button
                    color="tertiary"
                    onClick={() => {
                      navigate(
                        RoutesManager.deployment.applications.view.root.getURL({
                          code: applicationsMap[applicationID]?.code,
                        }),
                      )
                    }}
                  >
                    View
                  </Button>
                ),
              },
            }}
          />
        </>
      ) : (
        <EmptyState
          icon={<EmptyStateDeploymentPlansIcon />}
          bgVariant="grey"
          variant="page"
          title="No deployments use this deployment plan"
          description="Once deployed, relevant deployments will be displayed here."
        />
      )}
    </>
  )
}
