import pluralize from 'pluralize'
import { RoutesManager } from 'utils/routing/routesManager'
import React, { useContext } from 'react'
import { DataSystemWidgetsContext } from '../context/DataSystemWidgetsContext'
import { ScrollContainer } from './index'
import { Chip, Icon, Widget, theme } from '@ketch-com/deck'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

export const ProcessingActivitiesWidget: React.FC = () => {
  const { processingActivities, navigate, isPermitted } = useContext(DataSystemWidgetsContext)
  const isPermittedToReadProcessingActivities = isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_READ)

  if (!processingActivities.processingActivities?.length) {
    return (
      <Widget
        expandable={
          !!processingActivities?.processingActivities && processingActivities?.processingActivities?.length > 0
        }
        title="Processing Activities"
        subTitle={
          processingActivities.processingActivities?.length
            ? `${processingActivities.processingActivities?.length} Processing ${pluralize(
                'Activity',
                processingActivities.processingActivities?.length,
              )} Assigned.`
            : ''
        }
        emptyStateProps={{
          title: 'No Processing Activities Assigned',
          subTitle: 'This system is not associated with any processing activities.',
          iconName: 'ORelated',
          colorTheme: theme.palette.darkDuskFaded.main,
        }}
      />
    )
  }

  return (
    <Widget
      expandable={
        !!processingActivities?.processingActivities && processingActivities?.processingActivities?.length > 0
      }
      title="Processing Activities"
      subTitle={
        processingActivities.processingActivities?.length
          ? `${processingActivities.processingActivities?.length} Processing ${pluralize(
              'Activity',
              processingActivities.processingActivities?.length,
            )} Assigned.`
          : ''
      }
      content={
        <ScrollContainer>
          {processingActivities?.processingActivities?.map(activity => (
            <Chip
              clickable={isPermittedToReadProcessingActivities}
              label={activity.name}
              deleteIcon={<Icon name="OArrowCRight" />}
              onClick={
                isPermittedToReadProcessingActivities
                  ? () =>
                      navigate(
                        RoutesManager.policyCenter.processingActivities.view.overview.root.getURL({
                          id: activity.id,
                        }),
                      )
                  : undefined
              }
              onDelete={
                isPermittedToReadProcessingActivities
                  ? () =>
                      navigate(
                        RoutesManager.policyCenter.processingActivities.view.overview.root.getURL({
                          id: activity.id,
                        }),
                      )
                  : undefined
              }
            />
          ))}
        </ScrollContainer>
      }
    />
  )
}
