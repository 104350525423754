import React from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { useUsers } from 'api/users/queries/useUsers'
import { useUpdateWorkflowExecutionStepAssignee } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStepAssignee'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useWorkflowExecutionStepDetails } from 'api/workflowExecutions/queries/useWorkflowExecutionStepDetails'
import { WorkflowExecutionStepReassignFormValues } from './utils'
import { WorkflowExecutionStepReassignModal } from './WorkflowExecutionStepReassignModal'

type Props = {
  workflowExecutionStep: WorkflowExecutionStepDTO
  onClose: () => void
}

export const WorkflowExecutionStepReassignModalContainer: React.FC<Props> = ({ workflowExecutionStep, onClose }) => {
  const queryClient = useQueryClient()

  const { workflowExecutionID, stepID } = workflowExecutionStep

  const { data: users, isLoading: isUsersLoading } = useUsers({
    params: {
      active: true,
    },
  })

  const { data: workflowExecutionStepDetails, isLoading: isWorkflowExecutionStepDetailsLoading } =
    useWorkflowExecutionStepDetails({
      params: {
        workflowExecutionId: workflowExecutionID,
        stepId: stepID,
      },
    })

  const { mutateAsync: handleUpdateWorkflowExecutionStepAssignee } = useUpdateWorkflowExecutionStepAssignee({
    onSuccess: async () => {
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      showToast({ content: 'Activity reassigned', type: 'success' })
      onClose()
    },
    onError: () => {
      showToast({ content: 'Failed to reassign activity', type: 'error' })
    },
  })

  const onSubmit = async (values: WorkflowExecutionStepReassignFormValues) => {
    await handleUpdateWorkflowExecutionStepAssignee({
      params: {
        workflowExecutionId: workflowExecutionID,
        stepId: stepID,
        assigneeId: values.assigneeId,
      },
    })

    await queryClient.refetchQueries([
      ApiQueryKeys.workflowExecution,
      {
        workflowExecutionId: workflowExecutionID,
      },
    ])

    await queryClient.refetchQueries([
      ApiQueryKeys.workflowExecutionStepDetails,
      {
        workflowExecutionId: workflowExecutionID,
        stepId: stepID,
      },
    ])
  }

  return (
    <WorkflowExecutionStepReassignModal
      isReady={!isUsersLoading && !isWorkflowExecutionStepDetailsLoading}
      users={users}
      workflowExecutionStep={workflowExecutionStep}
      workflowExecutionStepDetails={workflowExecutionStepDetails}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  )
}
