import { APIRequestParams } from 'api/common/utils'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  organizationCode: string
  purposeCode: string
  includeIssues?: boolean
  includeMetadata?: boolean
  includeTransfers?: boolean
}>

type Response = { purpose: PurposeDTO }

export const fetchPurpose = ({
  organizationCode,
  purposeCode,
  includeMetadata,
  includeIssues,
  includeTransfers,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/purposes/${purposeCode}`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          includeTransfers,
        },
      },
    }),
  )
