import {
  PatchExecutionStepExecutionDataBody,
  PatchExecutionStepExecutionData200,
} from '@ketch-com/bosun/dist/mediation_gen.schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  workflowExecutionId?: string | null | undefined
  stepId?: string | null | undefined
  payload: PatchExecutionStepExecutionDataBody
}

export const patchExecution = ({ workflowExecutionId, stepId, payload }: Params) =>
  API.patch<PatchExecutionStepExecutionData200>(
    formatRequestString({
      entityUrl: `/api/v0/workflows/executions/${workflowExecutionId}/steps/${stepId}/execution-data`,
    }),
    payload,
  )
