import { Card, CardProps, Icon, theme } from '@ketch-com/deck'
import { Box, Typography, styled } from '@mui/material'

const OverviewCardBox = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
})

export const OverviewCard: React.FC<CardProps> = ({ cardTitle, ...props }) => (
  <Card
    {...props}
    cardVariant="stretchWidth"
    maxCardWidth="240px"
    backgroundColor={theme.palette.White.o100}
    textColor={theme.palette.Text.Primary}
    isClickable
    customContentCard={
      <OverviewCardBox>
        <Typography variant={'h4'}>{cardTitle}</Typography>
        <Icon name="OArrowCRight" width={32} height={28} iconColor={theme.palette.Black.o32} />
      </OverviewCardBox>
    }
  />
)
