import React from 'react'

import { OrganizationChangeSubscriptionView } from 'pages/settings/organizations/changeSubscription/OrganizationChangeSubscriptionView'
import { useCurrentOrganization } from 'utils/hooks/useCurrentOrganization'

export const OrganizationChangeSubscriptionContainer: React.FC = () => {
  const { organization, isLoading: isLoadingOrganization } = useCurrentOrganization({})

  return <OrganizationChangeSubscriptionView organization={organization} isReady={!isLoadingOrganization} />
}
