import { Box, Typography, AlertColor } from '@mui/material'
import { Button, PopUp, Icon, IconDictionary, theme, Banner, TextInput } from '@ketch-com/deck'
import { useCopyToClipboard } from 'react-use'
import { ExecutionResultAssetDTO } from '@ketch-com/figurehead'
import { useState, useContext } from 'react'
import { usePatchExecution } from 'api/executions/mutations/usePatchExecution'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { InternalSystemDsrContext } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/InternalSystemDsr/context'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'

type Props = {
  currentExecutionResult: ExecutionResultAssetDTO
  onClose: () => void
}

export const InternalSystemDsrExecutionInfoModal: React.FC<Props> = ({ currentExecutionResult, onClose }) => {
  const { stepDetails, workflowStep, rightInvocation } = useContext(InternalSystemDsrContext)
  const queryClient = useQueryClient()
  const [revisedSQL, setRevisedSQL] = useState(currentExecutionResult?.processes?.[0]?.ketchDml?.[0]?.executedDml || '')
  const [, copyToClipboard] = useCopyToClipboard()

  let isDsrExecutionSucceeded = false
  let severity: AlertColor = 'info'
  let message = 'Message'
  let bannerIcon = ''

  switch (currentExecutionResult?.status) {
    case 1:
      severity = 'success'
      message = 'Success'
      isDsrExecutionSucceeded = true
      bannerIcon = IconDictionary.FCheckRound
      break
    case 2:
      severity = 'error'
      message = 'Error'
      bannerIcon = IconDictionary.FImportant
      break
    case 3:
      severity = 'warning'
      message = "Didn't Execute"
      bannerIcon = IconDictionary.ODecline
      break
    case 5:
      severity = 'warning'
      message = 'Rolled Back'
      bannerIcon = IconDictionary.FUndo
      break
  }

  const { mutateAsync: updateRevisedSql, isLoading: isUpdatingRevisedSql } = usePatchExecution({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.executionResultsInfinite])
      showToast({ content: 'Saved successfully', type: 'success' })
      onClose()
    },
    onError: () => {
      showToast({ content: 'Unable to save', type: 'error' })
    },
  })

  return (
    <PopUp
      isOpen
      isLoading={isUpdatingRevisedSql}
      title={currentExecutionResult?.name}
      subTitle="See the details of the executed configuration and its current status."
      popUpWidth="780px"
      variant="modal"
      onClose={onClose}
      popUpActionChildren={
        <Box display="flex" gap={1}>
          {rightInvocation?.rightType === 'delete' &&
          !isDsrExecutionSucceeded &&
          workflowStep?.status !== WorkflowExecutionStepStatus.SUCCESS ? (
            <Button
              disabled={isUpdatingRevisedSql}
              color="primary"
              size="large"
              onClick={() =>
                updateRevisedSql({
                  params: {
                    workflowExecutionId: stepDetails?.workflowExecutionID,
                    stepId: stepDetails?.stepID,
                    payload: {
                      internalSystemDSR: {
                        revisedSQL: {
                          revisedSQL,
                          enactmentID: currentExecutionResult?.enactmentID,
                          connectionCode: currentExecutionResult?.connectionCode,
                        },
                      },
                    },
                  },
                })
              }
            >
              Save
            </Button>
          ) : null}
          <Button color="secondary" size="large" onClick={onClose}>
            Close
          </Button>
        </Box>
      }
    >
      <Box>
        {currentExecutionResult?.message ? (
          <Box mb={2} display="flex">
            <Banner isCompact severity={severity} icon={bannerIcon} title={message}>
              <Typography variant="body">{currentExecutionResult?.message}</Typography>
            </Banner>
          </Box>
        ) : null}

        <Box>
          {currentExecutionResult?.processes?.map((process, i) => {
            return (
              <Box key={i}>
                {process?.ketchDml?.map((dml, index) => {
                  return (
                    <Box key={index}>
                      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <Typography variant="smallLabel">Executed SQL</Typography>
                        <Button
                          variant="link"
                          color="secondary"
                          startIcon={<Icon name={IconDictionary.OCopy} />}
                          onClick={e => {
                            e.stopPropagation()
                            copyToClipboard(revisedSQL || '')
                            showToast({ content: 'Copied to clipboard', type: 'success' })
                          }}
                        >
                          Copy
                        </Button>
                      </Box>
                      {rightInvocation?.rightType !== 'delete' &&
                      (isDsrExecutionSucceeded || workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS) ? (
                        <Box
                          sx={{
                            px: 2,
                            border: `1px solid ${theme.palette.lightGrey.main}`,
                            borderRadius: 1,
                          }}
                        >
                          <Box my={2}>{dml?.executedDml}</Box>
                        </Box>
                      ) : (
                        <TextInput
                          disabled={isUpdatingRevisedSql}
                          fullWidth
                          multiline
                          value={revisedSQL}
                          onChange={e => setRevisedSQL(e.currentTarget.value)}
                        />
                      )}
                    </Box>
                  )
                })}
              </Box>
            )
          })}
        </Box>
      </Box>
    </PopUp>
  )
}
