import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'

import { ExperienceDTO } from 'interfaces/experiences/experience'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { Text } from 'components/ui-kit/typography/Text'
import { SectionTitle } from 'components/ui-kit/typography/compositions/SectionTitle'
import { FormGroup } from 'components/ui-layouts/formLayout/FormGroup'
import { BannerDetails } from 'pages/consentAndRights/experiences/view/components/BannerDetails'
import { ModalDetails } from 'pages/consentAndRights/experiences/view/components/ModalDetails'
import { JitDetails } from 'pages/consentAndRights/experiences/view/components/JitDetails'
import { OverviewDetails } from 'pages/consentAndRights/experiences/view/components/OverviewDetails'
import { RightsDetails } from 'pages/consentAndRights/experiences/view/components/RightsDetails'
import { ConsentsDetails } from 'pages/consentAndRights/experiences/view/components/ConsentsDetails'
import { SubscriptionsDetails } from 'pages/consentAndRights/experiences/view/components/SubscriptionsDetails'

const useStyles = makeStyles(
  theme =>
    createStyles({
      subTitle: {
        display: 'block',
        marginBottom: 18,
        color: theme.palette.fadedDarkGrey.main,
      },
    }),
  { name: 'ExperienceTranslations' },
)

type Props = {
  experience: ExperienceDTO
  organizationLanguages: LanguageWithStatusDTO[]
}

export const ExperienceTranslations: React.FC<Props> = ({ experience, organizationLanguages }) => {
  const classes = useStyles()
  const { consent, preference } = experience

  return (
    <>
      {organizationLanguages.map(({ language }) => (
        <FormGroup key={language.code}>
          <SectionTitle>{language.englishName}</SectionTitle>

          {!!consent && (
            <>
              {(() => {
                const consentTranslation = consent.translations?.[language.code]

                return (
                  <>
                    <Text className={classes.subTitle} size={16} weight={800}>
                      Banner Details
                    </Text>
                    <BannerDetails config={consentTranslation?.banner} />

                    <Text className={classes.subTitle} size={16} weight={800}>
                      Modal Details
                    </Text>
                    <ModalDetails config={consentTranslation?.modal} />

                    <Text className={classes.subTitle} size={16} weight={800}>
                      Just in Time Details
                    </Text>
                    <JitDetails config={consentTranslation?.jit} />
                  </>
                )
              })()}
            </>
          )}

          {!!preference && (
            <>
              {(() => {
                const preferenceTranslation = preference.translations?.[language.code]
                const title = preferenceTranslation?.title

                return (
                  <>
                    <Text className={classes.subTitle} size={16} weight={800}>
                      Overview Details
                    </Text>
                    <OverviewDetails title={title} config={preferenceTranslation?.overview} />

                    <Text className={classes.subTitle} size={16} weight={800}>
                      Rights Details
                    </Text>
                    <RightsDetails title={title} config={preferenceTranslation?.rights} />

                    <Text className={classes.subTitle} size={16} weight={800}>
                      Preferences Details
                    </Text>
                    <ConsentsDetails title={title} config={preferenceTranslation?.consents} />

                    <Text className={classes.subTitle} size={16} weight={800}>
                      Subscriptions
                    </Text>
                    <SubscriptionsDetails title={title} config={preferenceTranslation?.subscriptions} />
                  </>
                )
              })()}
            </>
          )}
        </FormGroup>
      ))}
    </>
  )
}
