import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchGenericCanonicalPurposes } from 'api/purposes/fetchers/fetchGenericCanonicalPurposes'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.purposesCanonical,
  queryFn: fetchGenericCanonicalPurposes,
  select: res => res?.data?.canonicalPurposes || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useGenericCanonicalPurposes = (config?: Config) =>
  useCustomQuery({
    ...config,
    params: {
      start: 0,
      limit: 1000,
      ...config?.params,
    },
  })
