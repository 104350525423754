import { UpdateAssetLabelsRequestBodyDTO, UpdateAssetLabelsResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  assetCode: string | undefined
  formData: UpdateAssetLabelsRequestBodyDTO
  isV2FeatureFlagEnabled?: boolean
}

export const updateAssetLabelsV2 = ({ assetCode, formData, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/assets/${assetCode}/update-labels`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/assets/${assetCode}/update-labels`

  return API.put<UpdateAssetLabelsResponseBodyDTO>(
    formatRequestString({
      entityUrl,
    }),
    formData,
  )
}
