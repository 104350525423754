import React from 'react'
import { GetAssetsStatisticsResponseBodyDTO } from '@ketch-com/figurehead'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { Button } from '@ketch-com/deck'
import { ReactComponent as DatabaseIcon } from 'assets/icons/ass_list_item_database_icon_small.svg'
import { ReactComponent as TablesIcon } from 'assets/icons/dataset.svg'
import { ReactComponent as SensitiveDataAssetsIcon } from 'assets/icons/person-avatar-small.svg'
import { ReactComponent as DataSystemsIcon } from 'assets/icons/data_systems.svg'
import { formatCount } from 'pages/insights/utils'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import pluralize from 'pluralize'

type Props = {
  assetStatistics: GetAssetsStatisticsResponseBodyDTO
}

const AssetStatisticsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}))

const AssetStatisticsItem = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.superLightGrey.main,
  borderRadius: 16,
  padding: theme.spacing(3),
  flex: 1,
}))

const StyledDatabaseIcon = styled(DatabaseIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  minWidth: 32,
  minHeight: 32,
}))

const StyledTablesIcon = styled(TablesIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  minWidth: 32,
  minHeight: 32,
}))

const StyledSensitiveDataAssetsIcon = styled(SensitiveDataAssetsIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  minWidth: 32,
  minHeight: 32,
}))

const AssetStatisticsItemHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}))

export const AssetsStatistics: React.FC<Props> = ({ assetStatistics }) => {
  const navigate = useNavigate()
  const { dataSystems = 0, databases = 0, sensitiveDataAssets = 0, datasets = 0 } = assetStatistics

  return (
    <>
      <Box display="flex" justifyContent="space-between" mt={3} mb={4}>
        <Typography variant="h3" color="darkDuskFaded.main">
          Summary
        </Typography>
        <Button disabled={false} onClick={() => navigate(RoutesManager.assetManager.assets.list.pattern)}>
          View All Assets
        </Button>
      </Box>
      <AssetStatisticsContainer>
        <AssetStatisticsItem>
          <AssetStatisticsItemHeader>
            <Typography variant="h1">{String(formatCount(databases))}</Typography>
            <StyledDatabaseIcon />
          </AssetStatisticsItemHeader>
          <Typography variant="label">{databases === 1 ? 'Database' : 'Databases'}</Typography>
        </AssetStatisticsItem>
        <AssetStatisticsItem>
          <AssetStatisticsItemHeader>
            <Typography variant="h1">{String(formatCount(datasets))}</Typography>
            <StyledTablesIcon />
          </AssetStatisticsItemHeader>
          <Typography variant="label">{datasets === 1 ? 'Table' : 'Tables'}</Typography>
        </AssetStatisticsItem>
        <AssetStatisticsItem>
          <AssetStatisticsItemHeader>
            <Typography variant="h1">{String(formatCount(sensitiveDataAssets))}</Typography>
            <StyledSensitiveDataAssetsIcon />
          </AssetStatisticsItemHeader>
          <Typography variant="label">Sensitive Data {pluralize('Asset', sensitiveDataAssets || 0)}</Typography>
        </AssetStatisticsItem>
        <AssetStatisticsItem>
          <AssetStatisticsItemHeader>
            <Typography variant="h1">{String(formatCount(dataSystems))}</Typography>
            <DataSystemsIcon />
          </AssetStatisticsItemHeader>
          <Typography variant="label">{dataSystems === 1 ? 'Data System' : 'Data Systems'}</Typography>
        </AssetStatisticsItem>
      </AssetStatisticsContainer>
    </>
  )
}
