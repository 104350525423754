import React from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

import { ReactComponent as VerticalArrowIcon } from 'assets/icons/asc-dec-black-arrow.svg'

const PREFIX = 'SegmentTabSortOption'

const classes = {
  rotate180: `${PREFIX}-rotate180`,
}

const StyledVerticalArrowIcon = styled(VerticalArrowIcon)(() => ({
  [`&.${classes.rotate180}`]: {
    transform: 'rotate(180deg)',
  },
}))

export enum TabSortType {
  ASC = 'asc',
  DEC = 'dec',
}

type Props = {
  type: TabSortType
  isActive?: boolean
}

export const SegmentTabSortOption: React.FC<Props> = ({ type, isActive }) => {
  return <StyledVerticalArrowIcon className={clsx({ [classes.rotate180]: type === TabSortType.DEC })} />
}
