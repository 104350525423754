import { Button, Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import {
  AuthenticatorDTO,
  ExperienceShortDTO,
  FormTemplateDTO,
  WorkflowDefinitionStepSummaryDTO,
} from '@ketch-com/figurehead'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { FormMode } from 'interfaces/formModes/formMode'
import { GatewayCellRenderer } from 'pages/consentAndRights/forms/components/GatewayCellRenderer'

import { Link } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

export const fieldDetailFormTemplateColumns: GridColDef<FormTemplateDTO>[] = [
  {
    align: 'left',
    field: 'experience',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Form',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<FormTemplateDTO>) => {
      return <TableCell title={row.name} />
    },
  },
  {
    align: 'right',
    field: 'openInNewTab',
    flex: 1,
    headerAlign: 'left',
    headerName: ' ',
    sortable: false,
    renderCell: ({ row: formTemplate }: GridRenderCellParams<FormTemplateDTO>) => {
      const targetUrl = RoutesManager.deployment.forms.templates.upsert.preview.root.getURL({
        formMode: FormMode.EDIT,
        id: formTemplate?.id || '',
      })

      return (
        <TableCell>
          <Link
            to={{ pathname: targetUrl, search: '?from=detail' }}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Button color="tertiary">Open In New Tab</Button>
          </Link>
        </TableCell>
      )
    },
  },
]

export const fieldDetailAuthenticatorColumns: GridColDef<AuthenticatorDTO>[] = [
  {
    align: 'left',
    field: 'experience',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Authenticator',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<AuthenticatorDTO>) => {
      return <TableCell title={row.name} />
    },
  },
  {
    align: 'right',
    field: 'openInNewTab',
    flex: 1,
    headerAlign: 'left',
    headerName: ' ',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<AuthenticatorDTO>) => {
      const targetUrl = RoutesManager.developers.authenticators.list.getURL()

      return (
        <TableCell>
          <Link to={targetUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Button color="tertiary">Open In New Tab</Button>
          </Link>
        </TableCell>
      )
    },
  },
]

export const getFieldDetailExperienceShortColumns = (isExpV1: boolean): GridColDef<ExperienceShortDTO>[] => [
  {
    align: 'left',
    field: 'experience',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Experience',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ExperienceShortDTO>) => {
      return <TableCell title={row.name} subTitle={row.code} />
    },
  },
  {
    align: 'left',
    field: 'deploymentStatus',
    flex: 0.4,
    headerAlign: 'left',
    headerName: 'Deployment Status',
    sortable: false,
    renderCell: ({ row: { status } }: GridRenderCellParams<ExperienceShortDTO>) => {
      return (
        <TableCell>
          <Status
            variant={StatusVariant.ghost}
            icon="Dot"
            label={status === 'deployed' ? 'Deployed' : 'Undeployed'}
            status={status === 'deployed' ? StatusState.success : StatusState.draft}
          />
        </TableCell>
      )
    },
  },
  {
    align: 'right',
    field: 'openInNewTab',
    flex: 1,
    headerAlign: 'left',
    headerName: ' ',
    sortable: false,
    maxWidth: 200,
    renderCell: ({ row: experience }: GridRenderCellParams<ExperienceShortDTO>) => {
      const targetUrl = isExpV1
        ? RoutesManager.deployment.experiences.view.root.getURL({
            code: experience?.code || '',
          })
        : RoutesManager.deployment.experiencesV2.view.preview.root.getURL({
            id: experience?.id,
          })

      return (
        <TableCell>
          <Link to={targetUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Button color="tertiary">Open In New Tab</Button>
          </Link>
        </TableCell>
      )
    },
  },
]

export const fieldDetailWorkflowDefinitionStepSummaryColumns: GridColDef<WorkflowDefinitionStepSummaryDTO>[] = [
  {
    align: 'left',
    field: 'workflow',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Workflow',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<WorkflowDefinitionStepSummaryDTO>) => {
      return <TableCell title={row.workflowDefinitionName} />
    },
  },
  {
    align: 'left',
    field: 'gateway',
    flex: 0.4,
    headerAlign: 'left',
    headerName: 'Decision Gateway',
    sortable: false,
    renderCell: ({ row: { name, icon } }: GridRenderCellParams<WorkflowDefinitionStepSummaryDTO>) => {
      return (
        <TableCell>
          <GatewayCellRenderer name={name} icon={icon} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'deploymentStatus',
    flex: 0.4,
    headerAlign: 'left',
    headerName: 'Status',
    sortable: false,
    renderCell: ({ row: { isActive } }: GridRenderCellParams<WorkflowDefinitionStepSummaryDTO>) => {
      return (
        <TableCell>
          <Status
            variant={StatusVariant.ghost}
            icon="Dot"
            label={isActive ? 'Enabled' : 'Disabled'}
            status={isActive ? StatusState.success : StatusState.draft}
          />
        </TableCell>
      )
    },
  },
  {
    align: 'right',
    field: 'openInNewTab',
    flex: 1,
    headerAlign: 'left',
    headerName: ' ',
    sortable: false,
    maxWidth: 200,
    renderCell: ({ row: workflowActivity }: GridRenderCellParams<WorkflowDefinitionStepSummaryDTO>) => {
      const targetUrl = RoutesManager.orchestration.workflows.view.root.getURL({
        code: workflowActivity.workflowDefinitionCode,
      })

      return (
        <TableCell>
          <Link to={targetUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Button color="tertiary">Open In New Tab</Button>
          </Link>
        </TableCell>
      )
    },
  },
]
