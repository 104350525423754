import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { CreateArtifactory200 } from '@ketch-com/harbormaster/dist/authentication/artifactory.schemas'

export const getArtifactoryCreds = () => {
  const entityUrl = '/api/artifactorycreds'
  return API.post<CreateArtifactory200>(
    formatRequestString({
      entityUrl,
    }),
  )
}
