import { Media } from '@twilio/conversations'
import { getFileUrl } from './getFileUrl'

export const getBlobFile = async (media: Media): Promise<Blob> => {
  try {
    const url = await getFileUrl(media)
    const response = await fetch(url)
    return response.blob()
  } catch (e) {
    return Promise.reject(e)
  }
}
