import React from 'react'
import clsx from 'clsx'
import { Tab, Tabs, Typography } from '@mui/material'

import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      root: {
        display: 'inline-flex',
        minHeight: 'auto',
        overflow: 'visible',
        borderRadius: 100,
        backgroundColor: palette.fadedGrey.main,
      },
      indicator: {
        display: 'none',
      },
      tab: {
        position: 'relative',
        minWidth: 'auto',
        minHeight: 'auto',
        padding: '6px 20px',
        fontSize: 14,
        fontWeight: 400,
        textTransform: 'none',
        borderRadius: 100,
        color: palette.darkDusk.main,

        '&:not($selected):hover': {
          color: palette.royalBlue.main,
        },

        '&:focus': {
          color: palette.lightBlue.main,
        },
      },
      selected: {
        fontWeight: 600,
        backgroundColor: palette.white.main,
        boxShadow: `0px 4px 10px rgba(0, 0, 0, 0.25)`,
        border: `1px solid ${palette.fadedGrey.main}`,
      },
      fixed: {
        overflow: 'visible !important',
      },
      textColorInherit: {
        opacity: 1,
      },
    }),
  { name: 'SegmentTabs' },
)

export type Props<T> = {
  /** Additional className */
  className?: string
  /** Additional tab className */
  tabClassName?: string
  /** Active tab value */
  value: T
  /** Tabs list to render */
  tabs: {
    id: T
    name: React.ReactNode
    hidden?: boolean
  }[]
  count?: number
  /** Method to change active tab */
  onChange: (nextValue: T, event: React.ChangeEvent<{}>) => void
}

export function SegmentTabs<T extends string | number = string>({
  className,
  tabs,
  value,
  onChange,
  tabClassName,
  count = 0,
}: Props<T>) {
  const classes = useStyles()

  const filteredTabs = tabs.filter(({ hidden }) => !hidden)

  if (filteredTabs.length < 2) {
    return null
  }

  return (
    <Tabs
      className={clsx(classes.root, className)}
      classes={{
        indicator: classes.indicator,
        fixed: classes.fixed,
      }}
      value={value}
      onChange={(event, nextValue: T) => {
        onChange(nextValue, event)
      }}
    >
      {filteredTabs.map(tab => (
        <Tab
          className={clsx(classes.tab, tabClassName)}
          classes={{
            selected: classes.selected,
            textColorInherit: classes.textColorInherit,
          }}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          key={tab.id}
          label={
            <>
              <Typography
                color="darkDusk.main"
                sx={{ cursor: 'pointer' }}
                variant={tab.id === value ? 'label' : 'body'}
              >
                {tab.name}
              </Typography>
              <Typography color="darkDusk.main" sx={{ cursor: 'pointer' }} variant="body">
                {count > 0 ? count : ''}
              </Typography>
            </>
          }
          value={tab.id}
        />
      ))}
    </Tabs>
  )
}
