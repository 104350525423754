import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { LegalBaseDTO } from 'interfaces/legalBases/legalBase'
import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import {
  getSubscriptionTopicFormInitialValues,
  getSortedTopicTranslations,
} from 'pages/policyCenter/subscriptions/utils'
import {
  BasicDetails,
  ContactMethods,
  LegalBasis,
} from 'pages/policyCenter/subscriptions/subscriptionTopic/upsert/components'

import { FormMode } from 'interfaces/formModes/formMode'
import {
  SubscriptionTopicFormData,
  UrlParams,
  SubscriptionsTopicFormStep,
} from 'pages/policyCenter/subscriptions/interfaces'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import {
  SubscriptionTopicDTO,
  SubscriptionTopicTranslationDTO,
  WebhookDTO,
  SubscriptionContactMethodDTO,
  AppDTO,
} from '@ketch-com/figurehead'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'
import { useCreateSubscriptionTopicFormValidation } from 'pages/policyCenter/subscriptions/hooks/useSubscriptionTopicFormValidation'
import { NestedErrorObject, concatenateAttributes } from './utils'

type Props = {
  organizationLanguages: LanguageWithStatusDTO[]
  subscriptionContactMethods: SubscriptionContactMethodDTO[]
  legalBases: LegalBaseDTO[]
  webhooksData: WebhookDTO[]
  systemsData: AppDTO[]
  onSubmit: (values: SubscriptionTopicFormData) => void
  isReady: boolean
}

const getCookieFormSteps = () => [
  SubscriptionsTopicFormStep.BASIC_DETAILS,
  SubscriptionsTopicFormStep.CHANNELS,
  SubscriptionsTopicFormStep.LEGAL_BASIS,
]

export const CreateSubscriptionTopic: React.FC<Props> = ({
  onSubmit,
  isReady,
  organizationLanguages,
  subscriptionContactMethods,
  webhooksData,
  systemsData,
}) => {
  const navigate = useNavigate()
  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Subscriptions', link: RoutesManager.policyCenter.subscriptions.list.subscriptionTopics.root.getURL() },
    { title: 'Create' },
  ]
  const { formMode } = useParams<UrlParams>()
  const isEditMode = formMode === FormMode.EDIT
  const [currentFormStep, setCurrentFormStep] = useState(0)

  const subscriptionTopicTranslations = {} as SubscriptionTopicDTO['translations']
  organizationLanguages?.forEach?.(orgLang => {
    if (!orgLang.system && subscriptionTopicTranslations)
      subscriptionTopicTranslations[orgLang.language.code] = {} as SubscriptionTopicTranslationDTO
  })

  const subscriptionTopic = { translations: subscriptionTopicTranslations } as SubscriptionTopicDTO
  // BE has translations map arranged by langCode instead of langName
  subscriptionTopic.translations = getSortedTopicTranslations(subscriptionTopic.translations, organizationLanguages)
  const initialValues = getSubscriptionTopicFormInitialValues({
    subscriptionTopic,
    subscriptionContactMethods,
  })

  const currentValidationSchema = useCreateSubscriptionTopicFormValidation({
    currentFormStep,
    isEditMode,
    subscriptionContactMethods,
    webhooksData,
    systemsData,
  })

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: currentValidationSchema,
    onSubmit: onSubmit,
  })

  const { submitForm, isSubmitting, validateForm, setFieldTouched } = formikProps

  const renderedChildren = useMemo(
    () => [
      <BasicDetails organizationLanguages={organizationLanguages} />,
      <ContactMethods
        subscriptionContactMethods={subscriptionContactMethods}
        webhooksData={webhooksData}
        systemsData={systemsData}
      />,
      <LegalBasis />,
    ],
    [organizationLanguages, subscriptionContactMethods, systemsData, webhooksData],
  )
  const isLastStep = getCookieFormSteps().length - 1 === currentFormStep
  const onAccept = async () => {
    const errors = await validateForm()
    if (Object.keys(errors).length) {
      const errorKeys = concatenateAttributes(errors as NestedErrorObject)
      errorKeys.forEach(formikErrorKey => setFieldTouched(formikErrorKey, true))
      return
    }
    if (isLastStep) {
      submitForm()
    } else {
      setCurrentFormStep(prev => prev + 1)
    }
  }

  useEffect(() => {
    if (currentFormStep > 0) {
      setTimeout(function () {
        const scrollToElement = document.getElementsByClassName('DeckFormRow')[currentFormStep]
        if (scrollToElement) {
          scrollToElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }, 0)
    }
  }, [currentFormStep])

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          sx={{
            '&.UpsertLayout-container': {
              '& .UpsertLayout-stepper': {
                // 8px gap between layout stepper and content
                top: '8px',
              },
            },
          }}
          formSteps={getCookieFormSteps()}
          mode={UpsertLayoutMode.create}
          currentFormStep={currentFormStep}
          showStepper
          renderFormTitle={() => <ContentGroup title="Create New Subscription" titleVariant="h2" isDivider />}
          onAccept={onAccept}
          onCancel={() => {
            navigate(RoutesManager.policyCenter.subscriptions.list.subscriptionTopics.root.getURL())
          }}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          acceptActionButtonText={isLastStep ? 'Save & Finish' : 'Next'}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {isReady ? (
              renderedChildren.filter((_, i) => currentFormStep >= i)
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%" m={6}>
                <Spinner size="32px" thickness={2.5} />
              </Box>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
