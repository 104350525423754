import { FormTemplateDTO, FormTemplateSectionDTO } from '@ketch-com/figurehead'
import { LanguageDTO } from 'interfaces/languages/language'

type Translation = FormTemplateDTO['translations']

const ensureFormTitleTranslations = (translations: Translation, enabledLanguages: LanguageDTO[]): Translation => {
  const copyTranslations = { ...translations } as {
    [key: string]: { title: string }
  }

  // Ensure keys for enabled languages
  enabledLanguages.forEach(lang => {
    if (!copyTranslations?.[lang?.code]) {
      copyTranslations[lang.code] = { title: '' }
    }
  })

  Object.keys(translations || {}).forEach(langCode => {
    copyTranslations[langCode].title = copyTranslations?.[langCode]?.title || ''
  })
  return copyTranslations
}

const ensureSectionTranslations = (translations: Translation, enabledLanguages: LanguageDTO[]): Translation => {
  const copyTranslations = { ...translations } as {
    [key: string]: { title: string; description: string }
  }

  // Ensure keys for enabled languages
  enabledLanguages.forEach(lang => {
    if (!copyTranslations?.[lang?.code]) {
      copyTranslations[lang.code] = { title: '', description: '' }
    }
  })

  // Fill out title and description for existing keys
  Object.keys(copyTranslations).forEach(langCode => {
    copyTranslations[langCode].title = copyTranslations?.[langCode]?.title || ''
    copyTranslations[langCode].description = copyTranslations?.[langCode]?.description || ''
  })

  return copyTranslations
}

type Args = {
  formTemplate?: FormTemplateDTO
  enabledLanguages: LanguageDTO[]
}

export const getFullyHydratedFormTemplate = ({ formTemplate, enabledLanguages }: Args): FormTemplateDTO => {
  const copyFormTemplate = { ...formTemplate } as FormTemplateDTO

  const hydratedTranslationsPayload = ensureFormTitleTranslations(copyFormTemplate.translations, enabledLanguages)
  copyFormTemplate.translations = hydratedTranslationsPayload

  const hydratedSectionsPayload = (copyFormTemplate?.sections || [])?.map(section => {
    const copySection = { ...section } as FormTemplateSectionDTO
    copySection.translations = ensureSectionTranslations(section?.translations || {}, enabledLanguages)
    return copySection
  })

  copyFormTemplate.sections = hydratedSectionsPayload

  return copyFormTemplate
}
