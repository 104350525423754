import { ApiQueryKeys } from 'api/common/queryKeys'
import { CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchApplications } from 'api/applications/fetchers/fetchApplications'
import { useAuth } from 'utils/hooks/useAuth'
import { createUsePaginatedQueryPreservedPage } from 'api/common/createUsePaginatedQueryPreservedPage'

const useCustomPaginatedQuery = createUsePaginatedQueryPreservedPage({
  queryKey: ApiQueryKeys.applicationsPaginated,
  queryFn: fetchApplications,
  select: res => ({ applications: res?.data?.applications || [], totalResults: res?.data.totalResults }),
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery, 'organizationCode'>

export const useApplicationsPaginated = (config?: QueryConfig) => {
  const { userData } = useAuth()

  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
      organizationCode: userData.organizationCode!,
    },
  })
}
