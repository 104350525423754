import { Button, Icon, TabSegmented, TabSegmentedGroup, theme } from '@ketch-com/deck'
import { Box } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { CustomAttributeConfigDTO, CustomAttributeSelectOptionDTO, CustomAttributeTypeDTO } from '@ketch-com/figurehead'
import { CustomFieldOption } from './CustomFieldOption'
import { ControlledInlineEdit, ControlledInlineEditProps } from './ControlledInlineEdit'

const typeOptions = [
  { name: 'Text', value: 'text' },
  { name: 'Single Choice', value: 'single_select' },
  { name: 'Multiple Choices', value: 'multi_select' },
]

interface CustomFieldBuilderProps {
  handleDeleteField: (index: number, id?: string) => void
  handleUpdateFields: React.Dispatch<React.SetStateAction<CustomAttributeConfigDTO[]>>
  handleAddFirstOption: (index: number) => void
  addOption?: boolean
  type: CustomAttributeTypeDTO
  label: string
  index: number
  id?: string
  options?: CustomAttributeSelectOptionDTO[]
}

export const CustomFieldBuilder: React.FC<CustomFieldBuilderProps> = ({
  handleDeleteField,
  handleUpdateFields,
  type,
  label,
  index,
  id,
  options,
  addOption,
  handleAddFirstOption,
}) => {
  const isMultiSelect = type === 'multi_select'

  const handleAddNewOption = useCallback(() => {
    handleUpdateFields(prev => {
      const updatedFields = prev.map(field => {
        if (field.index === index) {
          const newFieldOptions = field.options
            ? [...field.options, { value: `Option ${field.options.length + 1}`, index: field.options.length + 1 }]
            : [{ value: `Option ${1}`, index: 1 }]

          return {
            ...field,
            options: newFieldOptions,
            isEdited: !!field.id,
          }
        }
        return field
      })
      return updatedFields
    })
  }, [handleUpdateFields, index])

  useEffect(() => {
    if (addOption) {
      handleAddNewOption()
      handleAddFirstOption(index)
    }
  }, [addOption, handleAddFirstOption, handleAddNewOption, index])

  const handleFieldTypeChange = (event: React.MouseEvent<HTMLElement>, newFieldType: CustomAttributeTypeDTO) => {
    handleUpdateFields(prev => {
      const updatedFields = prev.map(field => {
        if (field.index === index) {
          return {
            ...field,
            type: newFieldType,
            options: newFieldType === 'text' ? [] : field.options,
            isEdited: !!field.id,
          }
        }
        return field
      })
      return updatedFields
    })
    if (!options || !options.length) handleAddFirstOption(index)
  }

  const handleFieldOptionChange = useCallback(
    (value: string, index: number, optionIndex: number) => {
      handleUpdateFields(prev => {
        const updatedFields = prev.map(field => {
          if (field.index === index) {
            return {
              ...field,
              options: field.options?.map((option, idx) => {
                if (optionIndex - 1 === idx) {
                  return {
                    ...option,
                    value,
                    isEdited: !!option.id,
                  }
                }
                return option
              }),
            }
          }
          return field
        })
        return updatedFields
      })
    },
    [handleUpdateFields],
  )

  const handleFieldLabelChange: ControlledInlineEditProps['onChange'] = label => {
    handleUpdateFields(prev => {
      const updatedFields = prev.map(field => {
        if (field.index === index) {
          return {
            ...field,
            label: label as string,
            isEdited: !!field.id,
          }
        }
        return field
      })
      return updatedFields
    })
  }

  const handleFieldOptionDelete = useCallback(
    (index: number, optionIndex: number) => {
      handleUpdateFields(prev => {
        const updatedFields = prev.map(field => {
          if (field.index === index) {
            return {
              ...field,
              options: field.options
                ?.filter((_, i) => i !== optionIndex - 1)
                .map((option, i) => {
                  if (i >= optionIndex - 1) {
                    return {
                      ...option,
                      index: option.index - 1,
                    }
                  }
                  return option
                }),
              isEdited: !!field.id,
            }
          }
          return field
        })
        return updatedFields
      })
    },
    [handleUpdateFields],
  )

  return (
    <Box
      padding={3}
      display="flex"
      justifyContent="space-between"
      gap={4}
      borderRadius={3}
      sx={{ backgroundColor: theme.palette.Black.o8 }}
    >
      <Box display="flex" flexDirection="column" gap={3} flexGrow={2} overflow="scroll" paddingRight={1}>
        <ControlledInlineEdit placeholder={label} value={label} onChange={handleFieldLabelChange} />
        <TabSegmentedGroup
          sx={{ alignSelf: 'flex-start' }}
          canDeselectCurrentValue={false}
          onChange={handleFieldTypeChange}
          value={type}
          exclusive
        >
          {typeOptions.map(option => {
            return (
              <TabSegmented key={option.value} size="small" value={option.value}>
                {option.name}
              </TabSegmented>
            )
          })}
        </TabSegmentedGroup>
        {type !== 'text' && (
          <>
            {options?.map(option => (
              <CustomFieldOption
                isMultiSelect={isMultiSelect}
                onDeleteOption={handleFieldOptionDelete}
                onValueChange={handleFieldOptionChange}
                key={option?.id || option.index}
                value={option.value}
                index={index}
                optionIndex={option.index}
              />
            ))}
            <Button
              color="secondary"
              sx={{ alignSelf: 'flex-start' }}
              onClick={() => {
                handleAddNewOption()
              }}
            >
              Add Option
            </Button>
          </>
        )}
      </Box>
      <Box display="flex" flexGrow={0} gap={1} paddingLeft={2} borderLeft={`1px solid ${theme.palette.Common.Divider}`}>
        <Button
          variant="iconSubtle"
          onClick={() => {
            handleDeleteField(index, id)
          }}
        >
          <Icon name="OBin" />
        </Button>
      </Box>
    </Box>
  )
}
