import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { checkIsPolicyScopeCodeAvailable } from 'api/policyScopes/fetchers/checkIsPolicyScopeCodeAvailable'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomMutation = createUseMutation({
  mutationFn: checkIsPolicyScopeCodeAvailable,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCheckIsPolicyScopeCodeAvailable = (config?: Config) => {
  const { userData } = useAuth()
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    organizationCode: userData.organizationCode,
  })
}
