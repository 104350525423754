import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

export type CustomQueryConfig<
  QueryType extends (...args: any[]) => any,
  StaticParams extends keyof Parameters<QueryType>[0]['params'] | '' = '',
> = Omit<Parameters<QueryType>[0], 'params'> & {
  params?: Omit<Parameters<QueryType>[0]['params'], StaticParams>
}

export function createUseQuery<QueryFnData, QueryFnParams, QuerySelectorData>({
  queryKey,
  queryFn,
  select,
}: {
  queryKey: string
  queryFn: (params: QueryFnParams) => Promise<QueryFnData>
  select: (res?: QueryFnData) => QuerySelectorData
}) {
  return function useCustomQuery({
    params,
    ...queryOptions
  }: { params: QueryFnParams } & Omit<UseQueryOptions<QueryFnData, unknown>, 'queryKey' | 'queryFn' | 'select'>) {
    const { data, ...rest } = useQuery([queryKey, params], () => queryFn(params), {
      ...(queryOptions as any),
      refetchInterval: queryOptions.refetchInterval || false,
    })
    return { ...rest, data: useMemo(() => select(data), [data]) }
  }
}
