import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { checkIsContextVariableCodeAvailable } from 'api/contextVariables/fetchers/checkIsContextVariableCodeAvailable'

const useCustomMutation = createUseMutation({
  mutationFn: checkIsContextVariableCodeAvailable,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCheckIsContextVariableCodeAvailable = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
