import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { showToast } from 'components/modals/AlertComponent'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { DeleteCustomRightNameModal } from '../components/modals/DeleteCustomRightNameModal'
import { useDeleteCustomRightName } from 'api/customRightNames/mutations/useDeleteCustomRightName'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'

interface Props {
  id: string
  setCurrentRowItem: any
}

export const CustomRightNameListDropdown: React.FC<Props> = ({ id, setCurrentRowItem }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const [isBusy, setIsBusy] = useState(false)
  const [confirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { mutate: handleDeleteCrn } = useDeleteCustomRightName({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(ApiQueryKeys.customRightNameList)
      showToast({ content: 'Custom Right Name deleted', type: 'success' })
      setIsBusy(false)
      setIsConfirmDeleteDialogOpen(false)
      setCurrentRowItem(null)
    },
    onError: () => {
      showToast({ content: 'Failed to delete Custom Right Name', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <Fragment>
      <Button
        size="medium"
        variant="iconSubtle"
        onClick={handleClickListItem}
        disabled={!isPermitted(PERMISSIONS.CUSTOM_NAME_RIGHTS_WRITE)}
        pending={isBusy}
      >
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transitionDuration={0}
        // need this so the accordion does not toggle open state on menu/action sheet close
        onClick={e => e.stopPropagation()}
      >
        <ActionSheetItem
          onClick={e => {
            e.stopPropagation()
            handleClose()
            navigate(RoutesManager.settings.customRightName.upsert.root.getURL({ id }))
          }}
        >
          Edit
        </ActionSheetItem>

        <ActionSheetItem divider />

        <ActionSheetItem
          onClick={e => {
            e.stopPropagation()
            handleClose()
            setIsConfirmDeleteDialogOpen(true)
          }}
        >
          Delete
        </ActionSheetItem>
      </ActionSheetWrapper>

      {confirmDeleteDialogOpen && (
        <DeleteCustomRightNameModal
          handleDeleteCrn={handleDeleteCrn}
          id={id}
          onClose={() => setIsConfirmDeleteDialogOpen(false)}
        />
      )}
    </Fragment>
  )
}
