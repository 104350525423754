import { ICoordinates } from 'pages/orchestration/workflows/edit/interfaces'
import { getAbsoluteCoordinates } from 'pages/orchestration/workflows/edit/utils/positioning/absoluteCoordinates'
import * as CanvasConstants from 'pages/orchestration/workflows/edit/utils/constants'

interface IGetCellBoundariesParams {
  gridPosition: ICoordinates
}

interface IGetCellBoundaries {
  xStart: number
  yStart: number
  xFinish: number
  yFinish: number
}

export const getCellBoundaries = (params: IGetCellBoundariesParams): IGetCellBoundaries => {
  const { gridPosition } = params
  const absolutePosition = getAbsoluteCoordinates(gridPosition)

  return {
    xStart: absolutePosition.x - CanvasConstants.GRID_CELL_WIDTH * 0.25,
    yStart: absolutePosition.y - CanvasConstants.GRID_CELL_HEIGHT * 0.25,
    xFinish: absolutePosition.x + CanvasConstants.GRID_CELL_WIDTH * 1.25,
    yFinish: absolutePosition.y + CanvasConstants.GRID_CELL_HEIGHT * 1.25,
  }
}
