// routing
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

// components
import { ChipsList } from './ChipsList'
import { LinkChipsList } from './LinkChipsList'
import { ViewModeLayout } from 'components/ui-layouts/viewLayout/ViewModeLayout'

// types
import { StackDTO } from '@ketch-com/figurehead'
type Props = {
  isLoading: boolean
  purposeStack: StackDTO | null
}

export const PurposeStackTabOverview: React.FC<Props> = ({ isLoading, purposeStack }) => {
  // hooks
  const navigate = useNavigate()

  // handlers
  const handleClick = (code: string) => {
    if (code) {
      navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code }))
    }
  }

  // component utils
  const contentRows = [{ title: 'Jurisdictions', info: <ChipsList data={purposeStack?.jurisdictions || []} /> }]
  purposeStack?.groups?.map(group =>
    contentRows.push({
      title: group?.name || '',
      info: <LinkChipsList data={group?.purposes || []} onClick={handleClick} />,
    }),
  )

  return (
    <ViewModeLayout title="Overview" subtitle="" isLoading={isLoading} shouldPadRows={true} contentRows={contentRows} />
  )
}
