import React from 'react'

import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { Button } from '@ketch-com/deck'
import { MaybeNull } from 'interfaces'
import { ApiKeyDTO, ApiKeyStatus } from 'interfaces/apiKeys'
import { Box } from '@mui/material'

type Props = {
  onRemoveClick: () => void
  onEditClick: () => void
  onDeactivateClick: () => void
  apiKey: MaybeNull<ApiKeyDTO>
}

export const ApiKeyViewActions: React.FC<Props> = ({ onRemoveClick, onEditClick, onDeactivateClick, apiKey }) => {
  const { isPermitted } = useIsPermitted()
  const isPermittedToManageApiKey = isPermitted([PERMISSIONS.API_KEY_WRITE, PERMISSIONS.ORG_ADMIN])

  return (
    <Box display="flex" gap={1.5} alignItems="center">
      <Button disabled={!isPermittedToManageApiKey} size="large" color="tertiary" onClick={onRemoveClick}>
        Delete
      </Button>

      <Button disabled={!isPermittedToManageApiKey} onClick={onEditClick} color="secondary" size="large">
        Edit
      </Button>

      <Button disabled={!isPermittedToManageApiKey} onClick={onDeactivateClick} color="primary" size="large">
        {apiKey?.status === ApiKeyStatus.ENABLED ? 'Deactivate' : 'Activate'}
      </Button>
    </Box>
  )
}
