import { CustomIconName } from '@ketch-com/deck'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { FormMode } from 'interfaces/formModes/formMode'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { NEW_ITEM_CODE, NEW_ITEM_ID } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'

export const filterAndSortCreateOptions = (items: CreateOverlayProps[], search: string): CreateOverlayProps[] => {
  const searchLowerCase = search.toLocaleLowerCase()

  // Filter for items that contain the search string
  const allContainingItems = items.filter(item => item.label.toLocaleLowerCase().includes(searchLowerCase))

  // Show items starting with the search string first, followed by those that just contain it, each in alphabetical order
  const startingWithSearch = allContainingItems
    .filter(item => item.label.toLocaleLowerCase().startsWith(searchLowerCase))
    .sort((a, b) => a.label.localeCompare(b.label))
  const containingSearch = allContainingItems
    .filter(item => !item.label.toLocaleLowerCase().startsWith(searchLowerCase))
    .sort((a, b) => a.label.localeCompare(b.label))

  return [...startingWithSearch, ...containingSearch]
}

export interface CreateOverlayProps {
  label: string
  value: string
  icon: CustomIconName
  permissions: PERMISSIONS[]
  path: string
  similarWords?: string[]
}

// Options for the create overlay, most common should come first in this list
export const createOverlayOptions: CreateOverlayProps[] = [
  {
    label: 'Purpose',
    value: 'purpose',
    icon: 'OScales',
    permissions: [PERMISSIONS.PURPOSE_WRITE],
    path: RoutesManager.policyCenter.purposes.library.root.getURL(),
  },
  {
    label: 'Jurisdiction',
    value: 'jurisdiction',
    icon: 'OGlobe',
    permissions: [PERMISSIONS.JURISDICTION_WRITE],
    path: RoutesManager.policyCenter.policyScopes.library.root.getURL(),
  },
  {
    label: 'Experience',
    value: 'experience',
    icon: 'ODevices',
    permissions: [PERMISSIONS.EXP_WRITE],
    path: RoutesManager.deployment.experiencesV2.upsert.root.getURL({
      formMode: FormMode.CREATE,
      experienceType: ExperienceType.Consent,
    }),
  },
  {
    label: 'Right Request',
    value: 'rightRequest',
    icon: 'OTask',
    permissions: [PERMISSIONS.RIGHT_WRITE],
    path: RoutesManager.orchestration.rightsQueue.create.root.getURL(),
  },
  {
    label: 'Workflow',
    value: 'workflow',
    icon: 'OWorkflow',
    permissions: [PERMISSIONS.WORKFLOW_WRITE],
    path: RoutesManager.orchestration.workflows.upsert.root.getURL({ code: NEW_ITEM_ID }),
  },
  {
    label: 'Theme',
    value: 'theme',
    icon: 'OPaintbrush',
    permissions: [PERMISSIONS.THEME_WRITE],
    path: RoutesManager.deployment.themesV3.upsert.root.getURL({ formMode: FormMode.CREATE }),
  },
  {
    label: 'Deployment',
    value: 'deployment',
    icon: 'OOutput',
    permissions: [PERMISSIONS.DEPLOY_WRITE],
    path: RoutesManager.deployment.deploymentPlansV2.upsert.root.getURL({ code: NEW_ITEM_CODE }),
  },
  {
    label: 'Property',
    value: 'property',
    icon: 'OComputer',
    permissions: [PERMISSIONS.PROPERTY_WRITE],
    path: RoutesManager.deployment.applications.upsert.root.getURL({ formMode: FormMode.CREATE }),
  },
  {
    label: 'Assessment',
    value: 'assessment',
    icon: 'OItems',
    permissions: [PERMISSIONS.ASSESSMENT_WRITE],
    path: RoutesManager.policyCenter.assessments.create.getURL(),
  },
  {
    label: 'Assessment Template',
    value: 'assessmentTemplate',
    icon: 'OFile',
    permissions: [PERMISSIONS.ASSESSMENT_WRITE],
    path: RoutesManager.policyCenter.assessments.templates.upsert.getURL({
      formMode: FormMode.CREATE,
    }),
  },
  {
    label: 'Assessment Question',
    value: 'assessmentQuestion',
    icon: 'OIntegrity',
    permissions: [PERMISSIONS.ASSESSMENT_WRITE],
    path: RoutesManager.policyCenter.assessments.questions.upsert.getURL({
      formMode: FormMode.CREATE,
    }),
  },
  {
    label: 'Processing Activity',
    value: 'processingActivity',
    icon: 'ORefresh',
    permissions: [PERMISSIONS.PROCESSING_ACTIVITY_WRITE],
    path: RoutesManager.policyCenter.processingActivities.upsert.getURL({ formMode: FormMode.CREATE }),
  },
  {
    label: 'Document',
    value: 'document',
    icon: 'ODocument',
    permissions: [PERMISSIONS.POLDOC_WRITE],
    path: RoutesManager.policyCenter.policyDocuments.upsert.root.getURL({ code: NEW_ITEM_CODE }),
  },
  {
    label: 'Business Function',
    value: 'businessFunction',
    icon: 'OGroup',
    permissions: [PERMISSIONS.BUSINESS_FUNCTIONS_WRITE],
    path: RoutesManager.policyCenter.businessFunctions.upsert.root.getURL({ formMode: FormMode.CREATE }),
  },
  {
    label: 'Controller',
    value: 'controller',
    icon: 'OFocus',
    permissions: [PERMISSIONS.CONTROLLER_WRITE],
    path: RoutesManager.policyCenter.controllers.upsert.root.getURL({ code: NEW_ITEM_CODE }),
  },
  {
    label: 'Subscription',
    value: 'subscription',
    icon: 'OBookmark',
    permissions: [PERMISSIONS.SUBSCRIPTIONS_WRITE],
    path: RoutesManager.policyCenter.subscriptions.upsert.subscriptionTopic.root.getURL({ formMode: FormMode.CREATE }),
  },
  {
    label: 'Form',
    value: 'form',
    icon: 'OBool',
    permissions: [PERMISSIONS.EXP_WRITE],
    path: RoutesManager.deployment.forms.templates.upsert.root.getURL({ formMode: FormMode.CREATE }),
  },
  {
    label: 'Form Field',
    value: 'formField',
    icon: 'OStacksView',
    permissions: [PERMISSIONS.EXP_WRITE],
    path: RoutesManager.deployment.forms.fields.upsert.root.getURL({ formMode: FormMode.CREATE }),
  },
  {
    label: 'Event',
    value: 'event',
    icon: 'OConnector',
    permissions: [PERMISSIONS.WORKFLOW_ASSIGN],
    path: RoutesManager.orchestration.events.create.root.getURL(),
  },
  {
    label: 'System',
    value: 'system',
    icon: 'System',
    permissions: [PERMISSIONS.DATA_SYSTEMS_WRITE],
    path: RoutesManager.systems.create.root.getURL(),
  },
  {
    label: 'API Key',
    value: 'apiKey',
    icon: 'OApi',
    permissions: [PERMISSIONS.API_KEY_WRITE],
    path: RoutesManager.developers.apiKeys.upsert.getURL({ id: NEW_ITEM_ID }),
  },
  {
    label: 'Authenticator',
    value: 'authenticator',
    icon: 'OKey',
    permissions: [PERMISSIONS.AUTHENTICATION_WRITE],
    path: RoutesManager.developers.authenticators.upsert.getURL({ id: 'new' }),
  },
  {
    label: 'Identifier',
    value: 'identifier',
    icon: 'OFingerprint',
    permissions: [PERMISSIONS.IDSP_WRITE],
    path: RoutesManager.settings.identitySpaces.upsert.root.getURL({ code: NEW_ITEM_CODE }),
  },
  {
    label: 'User',
    value: 'user',
    icon: 'OProfile',
    permissions: [PERMISSIONS.USER_ADMIN],
    path: RoutesManager.settings.users.upsert.root.getURL({ id: NEW_ITEM_ID }),
  },
  {
    label: 'Subject Type',
    value: 'subject Type',
    icon: 'OFace',
    permissions: [PERMISSIONS.SUBJECTTYPE_WRITE],
    path: RoutesManager.settings.dataSubjectTypes.upsert.root.getURL({ id: NEW_ITEM_ID }),
  },
  {
    label: 'Right Name',
    value: 'right Name',
    icon: 'OEdit',
    permissions: [PERMISSIONS.CUSTOM_NAME_RIGHTS_WRITE],
    path: RoutesManager.settings.customRightName.upsert.root.getURL({ id: NEW_ITEM_ID }),
  },
  {
    label: 'System Connection',
    value: 'systemConnection',
    icon: 'OConnectionTrue',
    permissions: [PERMISSIONS.INTEGRATIONS_WRITE],
    path: RoutesManager.systems.connections.create.getURL(),
  },
]
