import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'

import { fetchLabelsPaginatedV2 } from '../fetchers/fetchLabelsPaginatedV2'

type LastPage = any

const limit = 10

export const useLabelsInfiniteV2 = ({ onSettled }: { onSettled?: any }) => {
  return useInfiniteQuery({
    queryKey: [ApiQueryKeys.labelsInfinite],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchLabelsResults = await fetchLabelsPaginatedV2({
        includeIssues: true,
        limit,
        start: (pageParam - 1) * limit,
      })

      const { data } = fetchLabelsResults
      data.labels = data.labels ?? []
      data.totalResults = data.totalResults ?? 0

      return data
    },
    getNextPageParam: ({ totalResults }: LastPage, allPages: LastPage[]) => {
      let totalPurposesFetchedSoFar = 0
      allPages?.forEach?.(p => {
        totalPurposesFetchedSoFar += p.labels.length
      })
      const nextPageParam = Math.ceil(totalPurposesFetchedSoFar / limit) + 1
      const getNextPageParamReturnValue = totalPurposesFetchedSoFar === totalResults ? undefined : nextPageParam
      return getNextPageParamReturnValue
    },
    onSettled,
    staleTime: 1000 * 60 * 1, // one minutes
  })
}
