import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'

import { fetchDynamicDropdownValues } from '../fetchers/fetchDynamicDropdownValues'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.dynamicDropdownValues,
  queryFn: fetchDynamicDropdownValues,
  select: res => res?.data || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useDynamicDropdownValues = (config: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
