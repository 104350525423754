import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { DeleteUserModal } from 'components/modals/users/DeleteUserModal'
import { joinNonEmpty } from 'utils/helpers/joinNonEmpty'
import { MaybeNull } from 'interfaces/common'
import { RoutesManager } from 'utils/routing/routesManager'
import { UserDTO } from 'interfaces/users/users'
import { UsersViewActions } from 'pages/settings/users/view/components/UsersViewActions'
import { UsersViewHeaderDetails } from 'pages/settings/users/view/components/UsersViewHeaderDetails'
import { Spinner, ViewLayout, TabGroup, Tab } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { UsersViewAssignedActivites } from './components/UsersViewAssignedActivites'
import { UsersViewSystems } from './components/UsersViewSystems'
import { UsersViewAssessments } from './components/UsersViewAssessments'
import { UsersViewOverview } from './components/UsersViewOverview'

type Props = {
  isReady: boolean
  user: MaybeNull<UserDTO>
}

enum UserViewTabs {
  Details = 'Details',
  AssignedActivities = 'Assigned Activities',
  Systems = 'Systems',
  Assessments = 'Assessments',
}

export const UsersViewView: React.FC<Props> = ({ isReady, user }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [activeTab, setActiveTab] = useState(UserViewTabs.Details)

  const userName = user?.firstName ? joinNonEmpty([user?.firstName, user?.lastName]) : user?.email

  const breadcrumbs = [
    { title: 'Settings', link: RoutesManager.settings.users.root.getURL() },
    { title: 'Users', link: RoutesManager.settings.users.root.getURL() },
    { title: userName },
  ]

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)

  if (!isReady || !user) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      <ViewLayout
        contentGroupProps={{
          title: userName,
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.settings.users.root.getURL()),
          },
          actionRightBlockComponent: (
            <UsersViewActions
              user={user}
              onRemoveClick={() => setIsRemoveModalOpen(true)}
              onEditClick={() => navigate(RoutesManager.settings.users.upsert.root.getURL({ id: user?.ID }))}
            />
          ),
          infoBlockComponent: <UsersViewHeaderDetails user={user} />,
        }}
      >
        <TabGroup
          value={activeTab}
          onChange={(_: React.SyntheticEvent, newValue: UserViewTabs) => setActiveTab(newValue)}
        >
          <Tab key={UserViewTabs.Details} label="Overview" value={UserViewTabs.Details} />
          <Tab
            key={UserViewTabs.AssignedActivities}
            label="Assigned Activities"
            value={UserViewTabs.AssignedActivities}
          />
          <Tab key={UserViewTabs.Systems} label="Systems" value={UserViewTabs.Systems} />
          <Tab key={UserViewTabs.Assessments} label="Assessments" value={UserViewTabs.Assessments} />
        </TabGroup>

        {activeTab === UserViewTabs.Details ? <UsersViewOverview user={user} /> : null}
        {activeTab === UserViewTabs.AssignedActivities ? <UsersViewAssignedActivites user={user} /> : null}
        {activeTab === UserViewTabs.Systems ? <UsersViewSystems user={user} /> : null}
        {activeTab === UserViewTabs.Assessments ? <UsersViewAssessments user={user} /> : null}

        {isRemoveModalOpen && (
          <DeleteUserModal
            userId={user.ID}
            onCancel={() => setIsRemoveModalOpen(false)}
            onSubmit={async () => {
              await queryClient.refetchQueries(ApiQueryKeys.usersPaginated)
              navigate(RoutesManager.settings.users.root.getURL())
            }}
          />
        )}
      </ViewLayout>
    </>
  )
}
