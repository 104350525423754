import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { TableCell } from '@ketch-com/deck'
import { getLanguageEnglishNameByCode } from 'pages/policyCenter/subscriptions/utils'
import { SubscriptionTopicTranslationDTO } from '@ketch-com/figurehead'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { SubscriptionTopicFormData } from 'pages/policyCenter/subscriptions/interfaces'
import { useFormikContext } from 'formik'
import { FormInlineEdit } from 'components/form/FormInlineEdit'

export interface SubscriptionsTranslationsStepDataGridType {
  languageCode: string
  translation: SubscriptionTopicTranslationDTO
}

const TranslationsInlineEditCell = ({ languageCode, attribute }: { languageCode: string; attribute: string }) => {
  const { setFieldTouched, setFieldValue, isSubmitting } = useFormikContext<SubscriptionTopicFormData>()
  return (
    <TableCell>
      <FormInlineEdit
        disabled={isSubmitting}
        formPropertyName={`basicDetails.translations.${languageCode}.${attribute}` || ''}
        placeholder="Add Translation"
        id={`basicDetails.translations.${languageCode}.${attribute}`}
        onChange={e => {
          setFieldTouched(`basicDetails.translations.${languageCode}`, true)
          setFieldValue(`basicDetails.translations.${languageCode}.${attribute}`, e.currentTarget.value)
        }}
        // necessary so it doesn't interfere with the data grid accesibility keyboard actions
        onKeyDown={e => e.stopPropagation()}
      />
    </TableCell>
  )
}

export const subscriptionsTranslationsColumns = (
  organizationLanguages: LanguageWithStatusDTO[],
): GridColDef<SubscriptionsTranslationsStepDataGridType>[] => [
  {
    align: 'left',
    field: 'language',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Language',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<SubscriptionsTranslationsStepDataGridType>) => {
      const { languageCode } = row
      const languageEnglishName = getLanguageEnglishNameByCode(languageCode, organizationLanguages)
      return <TableCell title={languageEnglishName} />
    },
  },
  {
    align: 'left',
    field: 'displayName',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Display Name Translation',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<SubscriptionsTranslationsStepDataGridType>) => {
      return <TranslationsInlineEditCell languageCode={row.languageCode} attribute="name" />
    },
  },
  {
    align: 'left',
    field: 'displayDescription',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Display Description Translation',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<SubscriptionsTranslationsStepDataGridType>) => {
      return <TranslationsInlineEditCell languageCode={row.languageCode} attribute="description" />
    },
  },
]
