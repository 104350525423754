import { useContext } from 'react'

import { EndFlowContext } from '../../../context/EndFlowContext'
import { ContentGroup, InfoRow } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { InfoRowAssignUser } from 'pages/orchestration/rightsQueue/stepDetails/components/InfoRowAssignUser'
import { Box } from '@mui/material'

type Props = {}

export const TaskDetails: React.FC<Props> = () => {
  const { workflowExecutionId, workflowStep, canReassign, stepDetails, stepId, isComplete } = useContext(EndFlowContext)

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <ContentGroup variant="inner-page-header" titleVariant="h3" title="Task Details" />
      {/* Description */}
      <InfoRow title="Description">
        {workflowStep?.description ? workflowStep?.description : <EmptyValueRenderer />}
      </InfoRow>
      {/* Assignees */}

      <InfoRowAssignUser
        infoRowTitle="Assignee"
        assigneeInfo={stepDetails?.assigneeInfo || null}
        assignee={stepDetails.assignee}
        canEdit={canReassign}
        isComplete={isComplete}
        workflowExecutionId={workflowExecutionId}
        workflowStepId={stepId}
      />
    </Box>
  )
}
