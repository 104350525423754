import React, { useState, useContext } from 'react'
import { Banner, Button, theme } from '@ketch-com/deck'
import { AlertColor } from '@mui/material'
import { memo } from 'react'
import {
  OrgPlanAndBillingBannerVariants,
  StripeRedirectURL,
  StripeInvokeAction,
} from 'components/planAndBilling/common/constants/planAndBilling'
import { Box } from '@mui/material'
import moment from 'moment'
import { useIsCurrentUserOrgAdmin } from 'utils/hooks/useIsCurrentUserOrgAdmin'
import { PlanDetailsModal } from 'components/planAndBilling/components/uniqueUsers/PlanDetailsModal'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import pluralize from 'pluralize'

type PlanAndBillingBannerProps = {
  variant: OrgPlanAndBillingBannerVariants
  isGlobal?: boolean
}

type PlanAndBillingBannerDetails = {
  title: string
  message: string
  actionCopy: string
  actionCallback: () => void
  detailsCopy?: string
  detailsCallback?: () => void
  color: string
  icon?: string
}

export const PlanAndBillingBanners: React.FC<PlanAndBillingBannerProps> = memo(({ variant, isGlobal }) => {
  const navigate = useNavigate()
  const { createBillingPortal, isOrgOnFreePlan, isOrgOnStarterPlan, orgPlan } = useContext(PlanAndBillingContext)
  const [shouldDisplayPlanDetailsModal, setShouldDisplayPlanDetailsModal] = useState(false)
  const isCurrentUserOrgAdmin = useIsCurrentUserOrgAdmin()
  const planGraceStartDate = moment.unix(orgPlan?.planGracePeriod?.start || 0)
  const planGraceEndDate = moment.unix(orgPlan?.planGracePeriod?.end || 0)
  const billingGraceEndDate = moment.unix(orgPlan?.billingGracePeriod?.end || 0)
  const currentDate = moment()
  const planGraceDays = planGraceEndDate.diff(planGraceStartDate, 'days') + 1
  const planGraceRemainingDays = planGraceEndDate.diff(currentDate, 'days')
  const billingGraceRemainingDays = billingGraceEndDate.diff(currentDate, 'days')

  const freePlanInGrace = {
    title: 'Plan Limit Reached',
    message: `You have reached the unique user limit of your free plan. Upgrade your plan within ${planGraceRemainingDays} ${pluralize(
      'day',
      planGraceRemainingDays,
    )} to prevent any service interruptions.`,
    actionCopy: 'Upgrade',
    actionCallback: () => {
      setShouldDisplayPlanDetailsModal(true)
    },
    detailsCopy: 'View Details',
    detailsCallback: () => {
      navigate(RoutesManager.settings.planAndBilling.root.getURL())
    },
    color: 'error',
  }

  const freePlanSuspended = {
    title: 'Upgrade Required to Resume',
    message: `Over ${planGraceDays} ${pluralize(
      'day',
      planGraceDays,
    )} have elapsed since you reached the unique user limit of your free plan. Your implementation is now paused, but will resume once you upgrade.`,
    actionCopy: 'Upgrade',
    actionCallback: () => {
      setShouldDisplayPlanDetailsModal(true)
    },
    detailsCopy: 'View Details',
    detailsCallback: () => {
      navigate(RoutesManager.settings.planAndBilling.root.getURL())
    },
    color: 'error',
  }

  const starterPlanInGrace = {
    title: 'Plan Limit Reached',
    message: `You have reached the unique user limit for all Ketch self-service plans. A member of our sales team will be in touch shortly. Please begin your upgrade within ${planGraceDays} ${pluralize(
      'day',
      planGraceDays,
    )} to prevent any service interruptions.`,
    actionCopy: 'Upgrade',
    actionCallback: () => {
      setShouldDisplayPlanDetailsModal(true)
    },
    detailsCopy: 'View Details',
    detailsCallback: () => {
      navigate(RoutesManager.settings.planAndBilling.root.getURL())
    },
    color: 'error',
  }

  const starterPlanSuspended = {
    title: 'Upgrade Required to Resume',
    message: `Your implementation is now paused as ${planGraceDays} ${pluralize(
      'day',
      planGraceDays,
    )} have elapsed since you reached the unique user limit of Ketch self-service plans. Service will resume immediately after you have upgraded with one of our sales representatives.`,
    actionCopy: 'Upgrade',
    actionCallback: () => {
      setShouldDisplayPlanDetailsModal(true)
    },
    detailsCopy: 'View Details',
    detailsCallback: () => {
      navigate(RoutesManager.settings.planAndBilling.root.getURL())
    },
    color: 'error',
  }

  const billingInGrace = {
    title: 'Payment Failed',
    message: `We were unable to process your last payment. Please update your billing information within ${billingGraceRemainingDays} ${pluralize(
      'day',
      billingGraceRemainingDays,
    )} to prevent any service interruptions.`,
    actionCopy: 'Update Billing Details',
    actionCallback: () => {
      createBillingPortal({
        params: {
          reqBody: {
            returnURL: (() => {
              const url = new URL(StripeRedirectURL)
              url.searchParams.set('action', StripeInvokeAction.BillingFailed)
              return url.toString()
            })(),
          },
        },
      })
    },
    color: 'warning',
    icon: 'FFileError',
  }

  const billingMissed = {
    title: 'Payment Failed',
    message:
      "We couldn't process your payment. Your implementation is paused and will resume once you update your payment information.",
    actionCopy: 'Update Billing Information',
    actionCallback: () => {
      createBillingPortal({
        params: {
          reqBody: {
            returnURL: (() => {
              const url = new URL(StripeRedirectURL)
              url.searchParams.set('action', StripeInvokeAction.BillingMissed)
              return url.toString()
            })(),
          },
        },
      })
    },
    color: 'error',
    icon: 'FFileError',
  }

  let banner = {} as PlanAndBillingBannerDetails

  switch (variant) {
    case OrgPlanAndBillingBannerVariants.freePlanInGrace:
      banner = freePlanInGrace
      break

    case OrgPlanAndBillingBannerVariants.freePlanSuspended:
      banner = freePlanSuspended
      break

    case OrgPlanAndBillingBannerVariants.starterPlanInGrace:
      banner = starterPlanInGrace
      break

    case OrgPlanAndBillingBannerVariants.starterPlanSuspended:
      banner = starterPlanSuspended
      break

    case OrgPlanAndBillingBannerVariants.billingInGrace:
      banner = billingInGrace
      break

    case OrgPlanAndBillingBannerVariants.billingMissed:
      banner = billingMissed
      break
  }

  return (
    <Box my={isGlobal ? 0.5 : 1}>
      <Banner
        sx={{
          borderRadius: isGlobal ? 0 : '11px',
        }}
        icon={banner.icon}
        color={banner.color as AlertColor}
        title={banner.title}
        action={
          isCurrentUserOrgAdmin ? (
            <Box display="flex" alignItems="center" gap={2}>
              {isGlobal && banner?.detailsCallback ? (
                <Button
                  variant="link"
                  style={{ color: theme.palette.Error.PrimaryDark, whiteSpace: 'nowrap' }}
                  onClick={banner.detailsCallback}
                >
                  {banner.detailsCopy}
                </Button>
              ) : null}

              {banner?.actionCallback ? (
                <Button
                  color="primary"
                  style={{ color: theme.palette.Text.PrimaryLight, whiteSpace: 'nowrap' }}
                  onClick={banner.actionCallback}
                >
                  {banner.actionCopy}
                </Button>
              ) : null}
            </Box>
          ) : null
        }
      >
        {banner.message}
      </Banner>
      <PlanDetailsModal
        isOrgOnFreePlan={isOrgOnFreePlan}
        isOrgOnStarterPlan={isOrgOnStarterPlan}
        isOpen={shouldDisplayPlanDetailsModal}
        onClose={() => setShouldDisplayPlanDetailsModal(false)}
      />
    </Box>
  )
})
