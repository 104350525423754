import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Grid, TextField, Autocomplete } from '@mui/material'

import { Text } from 'components/ui-kit/typography/Text'
import { ReactComponent as PurposesIcon } from 'assets/icons/purposes.svg'
import { IconSpacer } from './IconSpacer'
import { MaybeNull } from 'interfaces/common'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { useFormikContext } from 'formik'
import { PolicyFormValues } from 'interfaces/policies/PolicyFormValues'
import { ReactComponent as RemoveBadgeIcon } from 'assets/icons/remove_icon_bold.svg'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { useAutocompleteStyles } from '../hooks'

const PREFIX = 'PurposesFormSection'

const classes = {
  container: `${PREFIX}-container`,
  withBorderBottom: `${PREFIX}-withBorderBottom`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing, shadows } }) => ({
  [`&.${classes.container}`]: {
    backgroundColor: palette.white.main,
    borderRadius: spacing(1.5),
    boxShadow: shadows[1],
  },

  [`& .${classes.withBorderBottom}`]: {
    borderBottom: `1px solid ${palette.iron.main}`,
  },
}))

type Props = {
  purposes: MaybeNull<PurposeDTO[]>
}

export const PurposesFormSection: React.FC<Props> = ({ purposes }) => {
  const autocompleteClasses = useAutocompleteStyles()
  const { values, setFieldValue, errors, touched, setTouched, validateForm } = useFormikContext<PolicyFormValues>()

  React.useEffect(() => {
    setFieldValue(
      'purpose',
      values.purpose?.id ? { id: values.purpose?.id, name: values.purpose?.name } : { id: '', name: '' },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue])

  return (
    <StyledBox mb={2} display="flex" flexDirection="column" className={classes.container}>
      {/* Row 1 */}

      <Box display="flex" alignItems="center" p={3} className={classes.withBorderBottom}>
        <Box mr={3}>
          <PurposesIcon />
        </Box>
        <Text size={20} weight={700}>
          When accessing data for the purpose of
        </Text>
      </Box>

      {/* Row 2 */}

      <Box display="flex" alignItems="center" p={3}>
        <IconSpacer />

        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={9}>
            {!values?.purpose?.id && !values?.purpose?.name && (
              <>
                <Label>Purpose</Label>
                <Autocomplete
                  popupIcon={null}
                  disableClearable
                  autoComplete={false}
                  multiple
                  classes={{ inputRoot: autocompleteClasses.inputRoot }}
                  id="purpose"
                  value={[{ id: values.purpose?.id, name: values.purpose?.name }]}
                  onChange={(_, v) => {
                    const filteredValues = v.filter(item => item.id !== '')
                    setFieldValue(
                      'purpose',
                      filteredValues?.[0]?.id
                        ? { id: filteredValues?.[0]?.id, name: filteredValues?.[0]?.name }
                        : { id: '', name: '' },
                    )
                    setTimeout(() => {
                      validateForm()
                    }, 500)
                  }}
                  options={(purposes || []).map(p => ({ id: p.code, name: p.name }))}
                  renderTags={(value: { id: string; name: string }[]) =>
                    value?.[0]?.name ? (
                      <Box
                        onClick={() => {
                          setFieldValue('purpose', { id: '', name: '' })
                        }}
                        display="flex"
                        alignItems="center"
                        className={autocompleteClasses.selectedValueTag}
                      >
                        {value?.[0]?.name}
                        <RemoveBadgeIcon className={autocompleteClasses.removeSelectedValueBadgeIcon} />
                      </Box>
                    ) : null
                  }
                  getOptionLabel={option => option.name}
                  getOptionDisabled={() => values?.purpose?.name !== ''}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Box
                        onClick={() => {
                          setFieldValue('purpose', { id: '', name: '' })
                        }}
                        display="flex"
                        alignItems="center"
                        className={autocompleteClasses.selectedValueTag}
                      >
                        {option?.name}
                      </Box>
                    </li>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder={values?.purpose?.name ? '' : 'Select purpose'}
                      helperText={touched?.purpose?.id ? errors?.purpose?.id : null}
                      error={(!!errors?.purpose && !!touched?.purpose?.id) || undefined}
                      onBlur={() => setTouched({ ...touched, purpose: { id: true, name: true } })}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        form: {
                          autoComplete: 'off',
                        },
                      }}
                    />
                  )}
                />
              </>
            )}

            {values?.purpose?.id && values?.purpose?.name && (
              <Box>
                <Label>Purpose</Label>
                <Box display="flex" alignItems="center" className={autocompleteClasses.proxyInput}>
                  <Box display="flex" alignItems="center" className={autocompleteClasses.selectedValueTag}>
                    {values.purpose.name}
                    <RemoveBadgeIcon
                      className={autocompleteClasses.removeSelectedValueBadgeIcon}
                      onClick={() => {
                        setFieldValue(`purpose`, { id: '', name: '' })
                        setTouched({ ...touched, purpose: { id: true, name: true } })
                        setTimeout(() => {
                          validateForm()
                        }, 500)
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </StyledBox>
  )
}
