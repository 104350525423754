import React, { useContext, useMemo } from 'react'
import { Stage } from 'react-konva'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

import { CanvasContext } from 'pages/orchestration/workflows/edit/contexts/CanvasContext'
import { CanvasZoom } from 'pages/orchestration/workflows/edit/components/zoom/CanvasZoom'
import { CanvasTutorial } from 'pages/orchestration/workflows/edit/components/tutorial/CanvasTutorial'
import { CanvasDropWrapper } from 'pages/orchestration/workflows/edit/components/CanvasDropWrapper'
import { CanvasSidebar } from 'pages/orchestration/workflows/edit/components/sidebar/CanvasSidebar'
import { ItemsLayer } from 'pages/orchestration/workflows/edit/konvaLayers/items/ItemsLayer'
import { BackgroundLayer } from 'pages/orchestration/workflows/edit/konvaLayers/background/BackgroundLayer'
import { SidebarConfigs } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/SidebarConfigs'
import { testForInvalidTaskRemoval } from './utils'
import { useIsPermitted } from 'utils/hooks'
import { WarningModal } from './components'
import { EditWorkflowContext } from '../../contexts/EditWorkflowContext'
import * as CanvasConstants from '../../utils/constants'
import { withWorkflowCanvasContext, WorkflowCanvasContext } from './context/WorkflowCanvasContext'
import { EditWorkflowContainerContext } from '../EditWorkflowFormContainer/context/EditWorkflowContainerContext'
import { Box } from '@mui/material'
import { styled } from '@mui/styles'
import { CanvasUndoRedo } from '../undoRedo/CanvasUndoRedo'

const CanvasWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: CanvasConstants.CANVAS_TOP_OFFSET,
  left: 0,
  right: 0,
  bottom: 0,
  flexGrow: 1,
  background: `${theme.palette.bone.main}`,
  borderRadius: 4,
}))

const WorkflowCanvasWithContext: React.FC = () => {
  // Pull from outer context
  const {
    handleReplaceStep: handleReplaceWorkflowStep,
    handleUpdateStep: handleUpdateWorkflowStep,
    values,
  } = useContext(EditWorkflowContainerContext)

  const {
    activeStep,
    canvasViewPortHeight,
    canvasViewPortWidth,
    contextValue,
    contextVariables,
    handleWheel,
    handleDragPanning,
    handleToggleSidebar,
    isExpanded,
    positionX,
    positionY,
    scale,
    setActiveDragStep,
    setDropCoordinates,
    setStageRef,
    setStageWrapperRef,
    setWarningModalMessage,
    sidebarWidth,
    stageRef,
    updateCanvasSettings,
    warningModalMessage,
    activeConnectedNestStartStep,
    removeActiveSteps,
  } = useContext(WorkflowCanvasContext)

  const {
    standardJoinActivity,
    workflowActivities: workflowItems,
    isActivitiesListLoading,
  } = useContext(EditWorkflowContext)

  const { isPermitted } = useIsPermitted()
  const hasRedactionWritePermission = isPermitted(PERMISSIONS.REDACTION_WRITE)

  const permittedWorkflowItems = useMemo(() => {
    return hasRedactionWritePermission
      ? workflowItems
      : {
          ...workflowItems,
          activities: workflowItems.activities.filter(activity => activity.name !== 'Review & Redact'),
        }
  }, [hasRedactionWritePermission, workflowItems])

  return (
    <CanvasWrapper ref={setStageWrapperRef}>
      <CanvasUndoRedo />
      <CanvasZoom />
      <CanvasTutorial />
      <CanvasSidebar
        isExpanded={isExpanded}
        sidebarWidth={sidebarWidth}
        workflowItems={permittedWorkflowItems}
        handleToggleSidebar={handleToggleSidebar}
        handleStartItemDrag={(step: ICanvasStep) => {
          setDropCoordinates({})
          setActiveDragStep(step)
        }}
        handleStopItemDrag={() => setActiveDragStep(null)}
        isActivitiesListLoading={isActivitiesListLoading}
      />

      {activeStep && (
        <SidebarConfigs
          workflowCode={values?.code}
          workflowItems={workflowItems}
          step={activeStep}
          connectedNestStartStep={activeConnectedNestStartStep || undefined}
          steps={values?.steps}
          handleClose={removeActiveSteps}
          handleSave={(params: any) => {
            const { step } = params

            handleUpdateWorkflowStep({ step })
            removeActiveSteps()
          }}
          handleRemove={({ step, pathId }: { step: ICanvasStep; pathId?: string }) => {
            const isRemoveValid = testForInvalidTaskRemoval({
              step,
              steps: values.steps,
              contextVariables,
              setWarningModalMessage,
            })

            if (!isRemoveValid) return

            handleReplaceWorkflowStep({
              step,
              stepToCreate: {},
              standardJoinActivity,
              pathId,
            })
            removeActiveSteps()
          }}
        />
      )}

      <CanvasDropWrapper
        x={positionX}
        y={positionY}
        scale={scale}
        stage={stageRef}
        handleDropComplete={({ x, y }) => {
          setDropCoordinates({ x, y })
          setTimeout(() => {
            setActiveDragStep(null)
          })
        }}
        style={{
          marginLeft: isExpanded
            ? CanvasConstants.CANVAS_SIDEBAR_EXPANDED_LEFT_OFFSET
            : CanvasConstants.CANVAS_SIDEBAR_COLLAPSED_LEFT_OFFSET,
        }}
      >
        <Stage
          isStage
          ref={setStageRef}
          x={positionX}
          y={positionY}
          scale={{
            x: scale,
            y: scale,
          }}
          onWheel={handleWheel}
          width={canvasViewPortWidth}
          height={canvasViewPortHeight}
          onClick={removeActiveSteps}
          onDragEnd={e => {
            const { attrs = {} } = e.target
            const { isStage } = attrs

            if (isStage) {
              updateCanvasSettings(prevState => ({
                ...prevState,
                positionX: attrs.x,
                positionY: attrs.y,
              }))
            }
          }}
          draggable
          dragBoundFunc={handleDragPanning}
        >
          <CanvasContext.Provider value={contextValue}>
            <BackgroundLayer />
            {isActivitiesListLoading ? null : <ItemsLayer steps={values.steps} />}
          </CanvasContext.Provider>
        </Stage>
      </CanvasDropWrapper>

      <WarningModal onClose={() => setWarningModalMessage('')} message={warningModalMessage} />
    </CanvasWrapper>
  )
}

export const WorkflowCanvas = withWorkflowCanvasContext(WorkflowCanvasWithContext)
