import { APIListRequestParams, formatOrdering, Ordering } from 'api/common/utils'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { API } from 'utils/api/api'
import { AppsInstancesPurposeDTO } from 'interfaces/apps'

type Params = APIListRequestParams<{
  organizationCode: string
  ordering?: Ordering<'hasIssues' | 'name'>
}>

type Response = {
  appsInstancesPurposes: AppsInstancesPurposeDTO[]
}

export const fetchAppsInstancesPurposes = ({ organizationCode, includeMetadata, start, limit, ordering }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/app-purposes`,
      params: {
        filters: {
          includeMetadata,
          start,
          limit,
          ordering: formatOrdering(ordering),
        },
      },
    }),
  )
