import React from 'react'
import { Box, SxProps, Theme, Typography } from '@mui/material'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type Props = {
  title?: string
  value?: React.ReactNode
  containerSx?: SxProps<Theme>
}

export const SidePanelKeyValueRenderer: React.FC<Props> = ({ title, value, containerSx }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        ...containerSx,
        wordBreak: 'break-word',
      }}
    >
      <Typography variant="smallLabel" color="darkDuskFaded.main">
        {title}
      </Typography>
      {value ? <Typography>{value}</Typography> : <EmptyValueRenderer />}
    </Box>
  )
}
