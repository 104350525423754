import * as Yup from 'yup'

interface IValidationParams {
  usedCodes?: Array<string>
}

export const getTaskFormValidationSchema = (params: IValidationParams) =>
  Yup.object().shape({
    code: Yup.string()
      .notOneOf(params?.usedCodes || [], 'This code is already in use')
      .required('This field is required'),
    description: Yup.string().required('Description is required'),
    assignee: Yup.string().required('This field is required'),
    details: Yup.string().required('This field is required'),

    // Notes
    hasResolutionNotes: Yup.boolean(),
    isResolutionNotesRequired: Yup.boolean(),

    // Attachments
    hasResolutionAttachments: Yup.boolean(),
    isResolutionAttachmentsRequired: Yup.boolean(),

    // Attachments for Data Subject
    hasDsrResolutionAttachments: Yup.boolean(),
    isDsrResolutionNotesRequired: Yup.boolean(),
    dsrResolutionDescription: Yup.string(),

    // Data Subject Variables
    hasDataSubjectVariables: Yup.boolean(),
    dataSubjectVariables: Yup.array().when('hasDataSubjectVariables', {
      is: true,
      then: Yup.array()
        .min(1, 'At least one Data Subject Variable is required')
        .of(
          Yup.object().shape({
            name: Yup.string().required('This field is required'),
            code: Yup.string().required('This field is required'),
            required: Yup.boolean(),
          }),
        ),
      otherwise: Yup.array().notRequired(),
    }),

    // Outcome Variables
    hasOutcomeVariables: Yup.boolean(),
    outcomeVariables: Yup.array().when('hasOutcomeVariables', {
      is: true,
      then: Yup.array()
        .min(1, 'At least one Outcome Variable is required')
        .of(
          Yup.object().shape({
            name: Yup.string().required('This field is required'),
            code: Yup.string().required('This field is required'),
            required: Yup.boolean(),
          }),
        ),
      otherwise: Yup.array().notRequired(),
    }),

    // Identifiers
    hasIdentitySpaces: Yup.boolean(),
    identitySpaces: Yup.array().when('hasIdentitySpaces', {
      is: true,
      then: Yup.array()
        .min(1, 'At least one Identifier is required')
        .of(
          Yup.object().shape({
            name: Yup.string().required('This field is required'),
            code: Yup.string().required('This field is required'),
            required: Yup.boolean(),
          }),
        ),
      otherwise: Yup.array().notRequired(),
    }),

    // Activity Timer
    timeToCompleteDays: Yup.number()
      .integer()
      .moreThan(-1, 'Number of days must be a whole number less than 365, or 0 to not require a due date.')
      .lessThan(365, 'Number of days must be a whole number less than 365, or 0 to not require a due date.'),
  })
