import { Banner } from '@ketch-com/deck'
import { Typography } from '@mui/material'

export const UnableToCompleteWarning = () => (
  <Banner severity="warning" title="Unable to Complete an Automated Decision" isInfoBox>
    <Typography variant="smallBody">
      Some paths are missing variable values that have canceled the automatic path selection. Please review the below
      and select the path to follow to continue the workflow.
    </Typography>
  </Banner>
)
