/**
 * Sets a session storage variable 'release' to the name specified.
 * Can be used to toggle features specific to a release.
 *
 * @param name name of the release.
 * @return null refreshes page
 */
export const setRelease = (name: string) => {
  window.sessionStorage.setItem('release', name)
  window.location.reload()
}
