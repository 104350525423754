import React from 'react'

import { UserDTO } from 'interfaces/users/users'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Button } from '@ketch-com/deck'
import { Typography, Box } from '@mui/material'

type Props = {
  user: UserDTO
  onRemoveClick: () => void
  onEditClick: () => void
}

export const UsersViewActions: React.FC<Props> = ({ user, onRemoveClick, onEditClick }) => {
  const { isPermitted } = useIsPermitted()
  const isPermittedToManageUser = isPermitted([PERMISSIONS.USER_ADMIN])

  return (
    <Box display="flex" alignItems="center" gap={1.5} height="100%">
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="smallLabel" color="darkDuskFaded.main">
          Created
        </Typography>
        <Typography color="darkDusk.main" variant="smallBody">
          <FormattedDateRenderer date={user.metadata?.createdAt} />
        </Typography>
      </Box>

      <Box>
        {isPermittedToManageUser && (
          <Button size="large" color="tertiary" onClick={onRemoveClick}>
            Delete
          </Button>
        )}
      </Box>

      <Box>
        {isPermittedToManageUser && (
          <Button onClick={onEditClick} color="secondary" size="large">
            Edit
          </Button>
        )}
      </Box>
    </Box>
  )
}
