import { FinalizeRequestFormValues, finalizeRequestFormKeys } from './getValidationSchema'

export const getInitialValues = (): FinalizeRequestFormValues => {
  return {
    [finalizeRequestFormKeys.finalizedStatus]: undefined,
    [finalizeRequestFormKeys.appealWorkflowDefinitionCode]: undefined,
    [finalizeRequestFormKeys.subjectCommunicationMessage]: '',
    [finalizeRequestFormKeys.coverLetter]: [],
    [finalizeRequestFormKeys.reasonForFinalization]: '',
    [finalizeRequestFormKeys.requestAllowsAppeal]: false,
  }
}
