import React, { useContext, useEffect } from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { getIn, useFormikContext } from 'formik'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { Box, Typography } from '@mui/material'
import { Button, Icon, Tab, TabPanel, TabGroup, theme } from '@ketch-com/deck'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import {
  BuilderTextInputMode,
  FormTextInput,
} from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'
import { FormFieldStyle } from '@ketch-sdk/ketch-types'
import { SidebarFormRadioGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormRadioGroup'
import { FormFieldStyleLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { ThemeUpsertContext } from 'pages/consentAndRights/themes-v3/upsert/context/ThemeUpsertContext'
import { PreferenceThemeBuilderSection } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'

const themeBuilderSectionTabMap: { [section: string]: number } = {
  [PreferenceThemeBuilderSection.RequestsFormContent]: 0,
  [PreferenceThemeBuilderSection.RequestsFormContentFields]: 1,
}

export const PreferenceThemeRequestsTabFormContentForm: React.FC = () => {
  const { values, setFieldValue, isSubmitting } = useFormikContext<ThemeV3DTO>()
  const { themeBuilderSection } = useContext(ThemeUpsertContext)

  // Current tab of the button form
  const [tab, setTab] = React.useState(0)

  const isFieldsStyleUnderline =
    getIn(values, themeUpsertFormKeys.preferenceRequestsTabFormFieldsStyle) === FormFieldStyle.Underline

  // Copy the checkbox theme from subscriptions
  const handleApplySubscriptionsStyleClick = () => {
    const copiedValue = getIn(values, themeUpsertFormKeys.preferenceSubscriptionsTabListCheckbox)
    const copyTo = themeUpsertFormKeys.preferenceRequestsTabFormCheckboxes
    const currentValue = getIn(values, copyTo)
    const newValue = { ...currentValue, ...copiedValue }
    setFieldValue(copyTo, newValue)
  }

  // Update the current tab of this form when the theme builder section changes
  useEffect(() => {
    if (themeBuilderSection) {
      setTab(themeBuilderSectionTabMap[themeBuilderSection])
    } else {
      setTab(0)
    }
  }, [themeBuilderSection, isSubmitting])

  return (
    <>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
            <Tab size={'medium'} label="Section Titles" />
            <Tab size={'medium'} label="Fields" />
            <Tab size={'medium'} label="Checkboxes" />
          </TabGroup>
        </Box>
        {/* Section Dividers Form */}
        <TabPanel value={tab} index={0} sx={{ px: 0 }}>
          <SidebarFormGroup sx={{ pt: 0 }}>
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormDividersTitleColor}
              label={'Title Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormDividersSubtitleColor}
              label={'Sub-Title Color'}
              fullWidth
              required
            />
          </SidebarFormGroup>
        </TabPanel>

        {/* Fields form */}
        <TabPanel value={tab} index={1} sx={{ px: 0, pb: '16px' }}>
          <SidebarFormGroup hasBorderBottom sx={{ pt: 0, gap: '16px' }}>
            <SidebarFormRadioGroup
              name={themeUpsertFormKeys.preferenceRequestsTabFormFieldsStyle}
              title={'Style'}
              options={Object.values(FormFieldStyle).map(style => ({
                label: FormFieldStyleLabels[style],
                value: style,
              }))}
              row
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormFieldsInactiveBackground}
              label={'Fill Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormFieldsInactiveOutline}
              label={isFieldsStyleUnderline ? 'Underline Color' : 'Outline Color'}
              fullWidth
              required
            />
            <FormTextInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormFieldsCornerRadius}
              label={'Corner Radius (px)'}
              mode={BuilderTextInputMode.CornerRadius}
              inputWidth="92px"
            />
          </SidebarFormGroup>
          {/* Active section */}
          <SidebarFormGroup hasBorderBottom sx={{ gap: '16px' }}>
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormFieldsActiveBackground}
              label={
                <Typography variant="label">
                  Fill Color ACTIVE{' '}
                  <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
                    (When Focused on Field)
                  </Typography>
                </Typography>
              }
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormFieldsActiveOutline}
              label={
                <Typography variant="label">
                  {isFieldsStyleUnderline ? 'Underline' : 'Outline'} Color ACTIVE{' '}
                  <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
                    (When Focused on Field)
                  </Typography>
                </Typography>
              }
              fullWidth
              required
            />
          </SidebarFormGroup>
          {/* Error section */}
          <SidebarFormGroup hasBorderBottom sx={{ gap: '16px' }}>
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormFieldsErrorBackground}
              label={
                <Typography variant="label">
                  Fill Color ERROR{' '}
                  <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
                    (Validation)
                  </Typography>
                </Typography>
              }
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormFieldsErrorOutline}
              label={
                <Typography variant="label">
                  {isFieldsStyleUnderline ? 'Underline' : 'Outline'} Color ERROR{' '}
                  <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
                    (Validation)
                  </Typography>
                </Typography>
              }
              fullWidth
              required
            />
          </SidebarFormGroup>
          {/* Text section */}
          <SidebarFormGroup sx={{ gap: '16px' }}>
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormFieldsLabelColor}
              label={'Field Label Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormFieldsHintTextColor}
              label={'Hint Text Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormFieldsInputTextColor}
              label={'Input Text Color'}
              fullWidth
              required
            />
          </SidebarFormGroup>
        </TabPanel>

        {/* Checkboxes Form */}
        <TabPanel value={tab} index={2} sx={{ px: 0 }}>
          <SidebarFormGroup sx={{ pt: 0, gap: '16px' }}>
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormCheckboxesSelectedBackgroundColor}
              label={'Checkbox SELECTED Fill Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormCheckboxesSelectedLabelColor}
              label={'Checkbox SELECTED Text Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormCheckboxesUnselectedBackgroundColor}
              label={'Checkbox NOT SELECTED Fill Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormCheckboxesUnselectedLabelColor}
              label={'Checkbox NOT SELECTED Text Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormCheckboxesErrorBackgroundColor}
              label={
                <Typography variant="label">
                  Checkbox ERROR Outline Color{' '}
                  <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
                    (Validation)
                  </Typography>
                </Typography>
              }
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceRequestsTabFormCheckboxesErrorLabelColor}
              label={
                <Typography variant="label">
                  Checkbox ERROR Text Color{' '}
                  <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
                    (Validation)
                  </Typography>
                </Typography>
              }
              fullWidth
              required
            />
            {/* Apply style from subscriptions */}
            <Box pt={'12px'}>
              <Button
                color={'tertiary'}
                endIcon={<Icon name={'OPaintbrush'} iconColor={theme.palette.sphere.main} />}
                fullWidth={false}
                onClick={handleApplySubscriptionsStyleClick}
              >
                Apply Style from Subscriptions
              </Button>
              <Typography component={'p'} variant={'smallBody'} color={theme.palette.darkDuskFaded.main} mt={'6px'}>
                Click here to apply the checkbox styles as defined on the Subscriptions page.
              </Typography>
            </Box>
          </SidebarFormGroup>
        </TabPanel>
      </Box>
    </>
  )
}
