import React from 'react'
import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { WorkflowExecutionStepStatusRenderer } from 'pages/orchestration/rightsQueue/viewV2/components/RightsQueueWorkflowTab/components/WorkflowExecutionStepStatusRenderer'

type Props = { workflowStep: WorkflowExecutionStepDetailsDTO | null }

export const DecisionStepDetailsHeaderDetails: React.FC<Props> = ({ workflowStep }) => {
  return (
    <WorkflowExecutionStepStatusRenderer
      status={workflowStep?.status!}
      dueAt={workflowStep?.dueAt}
      code="decision_gateway"
    />
  )
}
