import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  organizationCode: string
  appCode: string
  appInstanceID: string
}>

type Response = {}

export const syncWebTags = ({ organizationCode, appCode, appInstanceID }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/tags-list`,
      params: {
        filters: {
          appCode,
          appInstanceID,
        },
      },
    }),
  )
