export enum StatusVariantV3Enum {
  draft = 'draft',
  new = 'new',
  inProgress = 'inProgress',
  activeEnabled = 'activeEnabled',
  errorCanceled = 'errorCanceled',
  finishedDone = 'finishedDone',
  inactiveArchive = 'inactiveArchive',
}

export enum RightInvocationStatusTextHumanReadable {
  COMPLETED = 'Completed',
  REJECTED = 'Rejected',
  DUE_SOON = 'Due Soon',
  DUE_TODAY = 'Due Today',
  OVERDUE = 'Overdue',
  ON_TRACK = 'On Track',
  PENDING = 'Pending',
}
