import React from 'react'
import Box from '@mui/material/Box'
import { AssetPolicyAssetDTO } from '@ketch-com/figurehead'
import { v4 as uuidv4 } from 'uuid'
import { usePurpose } from 'api/purposes/queries/usePurpose'

type Props = {
  purpose: string
  resources: AssetPolicyAssetDTO[]
}

export const PurposeCellRenderer: React.FC<Props> = ({ purpose, resources }) => {
  const { data: purposeData } = usePurpose({
    enabled: !!purpose,
    params: {
      purposeCode: purpose,
    },
  })

  return (
    <Box display="flex" flexDirection="column">
      {(resources || [{}]).map((_, index) => (
        <Box
          key={uuidv4()}
          display="flex"
          flexDirection="row"
          alignItems="center"
          mb={1.75}
          style={{ opacity: index === 0 ? 1 : 0 }}
        >
          {purposeData?.name}
        </Box>
      ))}
    </Box>
  )
}
