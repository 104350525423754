import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'
import { showToast } from 'components/modals/AlertComponent'
import { CustomRightNameDTO, PutCustomRightNameRequestBodyDTO } from '@ketch-com/figurehead'
import { CustomRightNameUpsertView } from 'pages/settings/customRightName/upsert/CustomRightNameUpsertView'
import { useUpdateCustomRightName } from 'api/customRightNames/mutations/useUpdateCustomRightName'
import { useFetchCustomRightName } from 'api/customRightNames/queries/useFetchCustomRightName'
import { useCreateCustomRightName } from 'api/customRightNames/queries/useCreateCustomRightName'

export const CustomRightNameUpsertContainer: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [saveWarningModal, setSaveWarningModal] = useState(false)
  const { id } = useParams<{ id: string }>()
  const isEditMode = id !== NEW_ITEM_ID

  const onCrnUpdateSuccess = async (nextCrnUpdateType: PutCustomRightNameRequestBodyDTO) => {
    await queryClient.refetchQueries([ApiQueryKeys.customRightName, { id: nextCrnUpdateType.customRightName?.id }])
    if (isEditMode) {
      showToast({ content: 'Custom Right Name updated', type: 'success' })
    } else {
      showToast({ content: 'Custom Right Name created', type: 'success' })
    }
    navigate(RoutesManager.settings.customRightName.root.getURL())
  }

  const onCreateCustomRightName = async () => {
    await queryClient.refetchQueries(ApiQueryKeys.customRightNameList)
    showToast({ content: 'Custom Right Name created', type: 'success' })
    navigate(RoutesManager.settings.customRightName.root.getURL())
  }

  const { mutateAsync: handleCrnUpdate } = useUpdateCustomRightName({
    onSuccess: ({ data }) => {
      onCrnUpdateSuccess(data)
    },
    onError: () => {
      showToast({ content: 'Failed to update Custom Right Name', type: 'error' })
    },
  })

  const { mutateAsync: handleCrnCreate } = useCreateCustomRightName({
    onSuccess: () => onCreateCustomRightName(),
    onError: () => {
      showToast({ content: 'Failed to update Custom Right Name', type: 'error' })
    },
  })

  const { data: customRightName, isLoading: isCrnLoading } = useFetchCustomRightName({
    params: {
      id: id || '',
    },
    enabled: isEditMode,
  })

  const onSubmitCreate = async (formData: CustomRightNameDTO) => {
    await handleCrnCreate({
      params: {
        formData: {
          customRightName: formData,
        },
      },
    })
  }

  const onSubmitUpdate = async (formData: CustomRightNameDTO) => {
    if (formData.jurisdictionRights && saveWarningModal === false) {
      setSaveWarningModal(true)
    } else {
      await handleCrnUpdate({
        params: {
          id: id!,
          formData,
        },
      })
    }
  }

  return (
    <CustomRightNameUpsertView
      isReady={!isCrnLoading}
      isEditMode={isEditMode}
      customRightName={customRightName}
      onSubmitUpdate={onSubmitUpdate}
      onSubmitCreate={onSubmitCreate}
      saveWarningModal={saveWarningModal}
      setSaveWarningModal={setSaveWarningModal}
      id={id || ''}
    />
  )
}
