import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  queryKey: [string, { organizationCode: string; appInstanceId: string }]
}

export const fetchAppInstancesUsages = ({ queryKey }: Params) => {
  const [, { organizationCode, appInstanceId }] = queryKey
  return API.get(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/workflows/app-instances/${appInstanceId}`,
    }),
  )
}
