import { Radio } from '@ketch-com/deck'
import { Box, FormControlLabel, RadioGroup, Typography } from '@mui/material'
import { FormDataInputValue } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'

type Props = {
  formValue: FormDataInputValue
  setFormValue: (v: FormDataInputValue) => void
}
export const BooleanValueInput: React.FC<Props> = ({ formValue, setFormValue }) => {
  return (
    <Box>
      <Typography mb={0.5} variant="smallLabel">
        New Value
      </Typography>
      <RadioGroup value={formValue} row={false} onChange={(e, value) => setFormValue(value === 'true' ? true : false)}>
        <FormControlLabel key="true" value="true" control={<Radio />} label="TRUE" />

        <FormControlLabel key="false" value="false" control={<Radio />} label="FALSE" />
      </RadioGroup>
    </Box>
  )
}
