import { APIListRequestParams } from 'api/common/utils'
import { GetInteractionsByIdentityResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  identitySpaceCode?: string
  identityValue?: string
}>

type Response = GetInteractionsByIdentityResponseBodyDTO

export const fetchInteractionsPaginated = ({ identitySpaceCode, identityValue, start, limit }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/interactions/${identitySpaceCode}/${identityValue}`,
      params: {
        filters: {
          start,
          limit,
        },
      },
    }),
  )
