import React from 'react'

import { RightInvocationDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionStepDTO, WorkflowExecutionDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { useUsers } from 'api/users/queries/useUsers'
import { useWorkflowExecutionStepDetails } from 'api/workflowExecutions/queries/useWorkflowExecutionStepDetails'
import { useFilesInfo } from 'api/files/queries/useFilesInfo'
import { MaybeNull } from 'interfaces'
import { WorkflowExecutionStepViewModal } from './WorkflowExecutionStepViewModal'

type Props = {
  rightInvocation: RightInvocationDTO
  workflowExecution: MaybeNull<WorkflowExecutionDTO>
  workflowExecutionStep: WorkflowExecutionStepDTO
  onClose: () => void
}

export const WorkflowExecutionStepViewModalContainer: React.FC<Props> = ({
  rightInvocation,
  workflowExecution,
  workflowExecutionStep,
  onClose,
}) => {
  const { stepID, workflowExecutionID } = workflowExecutionStep

  const { data: users, isLoading: isUsersLoading } = useUsers({
    params: {
      active: true,
    },
  })

  const { data: workflowExecutionStepDetails, isLoading: isWorkflowExecutionStepDetailsLoading } =
    useWorkflowExecutionStepDetails({
      params: {
        workflowExecutionId: workflowExecutionID,
        stepId: stepID,
      },
    })

  const resolutionAttachments = workflowExecutionStepDetails?.results?.[stepID]?.resolutionAttachments || []
  const dsrResolutionAttachments = workflowExecutionStepDetails?.results?.[stepID]?.dsrResolutionAttachments || []

  const { data: filesInfo, isLoading: isFilesInfoLoading } = useFilesInfo({
    enabled: !!resolutionAttachments.length,
    params: {
      infoUrls: resolutionAttachments,
    },
  })

  const { data: dsrFilesInfo, isLoading: isDsrFilesInfoLoading } = useFilesInfo({
    enabled: !!dsrResolutionAttachments.length,
    params: {
      infoUrls: dsrResolutionAttachments,
    },
  })

  const isReady =
    !isWorkflowExecutionStepDetailsLoading && !isFilesInfoLoading && !isUsersLoading && !isDsrFilesInfoLoading

  return (
    <WorkflowExecutionStepViewModal
      isReady={isReady}
      filesInfo={filesInfo}
      dsrFilesInfo={dsrFilesInfo}
      users={users}
      rightInvocation={rightInvocation}
      workflowExecution={workflowExecution}
      workflowExecutionStep={workflowExecutionStep}
      workflowExecutionStepDetails={workflowExecutionStepDetails}
      onClose={onClose}
    />
  )
}
