import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { makeStyles, createStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'

import { MaybeNull } from 'interfaces'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import {
  ModalField,
  SIDEBAR_TITLES,
  fieldNames,
  useOnRemove,
  isFieldRequired,
  serialize,
} from 'pages/consentAndRights/experiences/upsert/utils/common'
import { InterpolateLinks } from 'pages/consentAndRights/experiences/components/InterpolateLinks'
import { Button } from '@ketch-com/deck'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { Text } from 'components/ui-kit/typography/Text'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { UpsertInlineLayoutPreview } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutPreview'
import { UpsertInlineLayoutPreviewContent } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutPreviewContent'
import { UpsertExperienceEditableOverlay } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEditableOverlay'
import { UpsertExperiencePoweredBy } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePoweredBy'
import { UpsertExperiencePreviewText } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewText'
import { UpsertExperiencePreviewButton } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewButton'
import { UpsertExperiencePreviewPlaceholder } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewPlaceholder'
import { UpsertExperienceEmptyField } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEmptyField'
import { UpsertExperienceFieldSidebar } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceFieldSidebar'
import { InterpolatedTextArea } from 'pages/consentAndRights/experiences/upsert/components/common/interpolatedTextArea/InterpolatedTextArea'
import { ConsentExperienceFormValues } from '../../../utils/consent/interfaces'
import { ConsentPurposesFields } from '.'
import { onRemoveHandledCases } from '../../../utils/common/hooks/useOnRemove'
import { FormSwitch } from 'components/ui-kit/form/switch/FormSwitch'
import { Typography } from '@mui/material'

const sectionsWithTranslations = [
  ModalField.MAIN_TITLE,
  ModalField.BODY_TITLE,
  ModalField.BODY_DESCRIPTION,
  ModalField.CONFIRM_BUTTON,
]

const useStyles = makeStyles(
  theme =>
    createStyles({
      wrapper: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      closeIcon: {
        fontSize: theme.typography.pxToRem(18),
      },
      modal: {
        width: '100%',
        height: '100%',
        maxWidth: 846,
        maxHeight: 650,
        borderRadius: 3,
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 5px 24px rgba(0, 0, 0, 0.65)',
      },
      header: {
        padding: '17px 24px 21px 24px',
        backgroundColor: theme.palette.darkDusk.main,
        color: theme.palette.white.main,
      },
      body: {
        flex: 1,
        overflowY: 'auto',
        padding: 24,
      },
      bodyTitle: {
        marginBottom: 17,
      },
      bodyDescription: {
        marginBottom: 24,
        whiteSpace: 'pre-line',
        overflowWrap: 'break-word',
      },
      footer: {
        padding: '12px 24px',
        backgroundColor: theme.palette.darkDusk.main,
      },
      translationsTitle: {
        display: 'block',
        marginBottom: 20,
      },
    }),
  { name: 'UpsertModal' },
)

type Props = {
  isEditMode: boolean
  organizationLanguages: LanguageWithStatusDTO[]
}

export const UpsertModal: React.FC<Props> = ({ isEditMode = false, organizationLanguages }) => {
  const classes = useStyles()

  const [active, setActive] = useState<MaybeNull<ModalField>>(isEditMode ? null : ModalField.MAIN_TITLE)
  const { values } = useFormikContext<ConsentExperienceFormValues>()

  const { title, bodyTitle, bodyDescription, buttonText } = values.modal

  const getOverlayProps = (name: ModalField) => ({
    active: name === active,
    onClick: () => {
      setActive(name)
    },
  })

  const { onRemove } = useOnRemove({ organizationLanguages })

  const shouldShowTranslationsSection = !!organizationLanguages?.length && sectionsWithTranslations.includes(active!)

  return (
    <UpsertInlineLayoutPreview>
      <UpsertInlineLayoutPreviewContent className={classes.content}>
        <Flex className={classes.wrapper} alignItems="center" justifyContent="center">
          <Flex className={classes.modal} flexDirection="column">
            <Flex className={classes.header} justifyContent="space-between" alignItems="center">
              <UpsertExperienceEditableOverlay {...getOverlayProps(ModalField.MAIN_TITLE)}>
                {title ? (
                  <UpsertExperiencePreviewText bold size="large" color="white">
                    {title}
                  </UpsertExperiencePreviewText>
                ) : (
                  <UpsertExperienceEmptyField required size="large">
                    Main Title
                  </UpsertExperienceEmptyField>
                )}
              </UpsertExperienceEditableOverlay>

              {/* Close Icon */}

              {values?.modal?.showCloseIcon ? (
                <UpsertExperienceEditableOverlay
                  {...getOverlayProps(ModalField.CLOSE_ICON)}
                  hideClickToEdit
                  removeMinWidth
                >
                  <CloseIcon className={classes.closeIcon} />
                </UpsertExperienceEditableOverlay>
              ) : (
                <UpsertExperienceEditableOverlay
                  {...getOverlayProps(ModalField.CLOSE_ICON)}
                  removeMinWidth
                  hideClickToEdit
                >
                  <Box display="flex" alignItems="center" sx={{ opacity: 0.75 }}>
                    <Text size={14} mr={2} color="white">
                      Enable Button
                    </Text>
                    <CloseIcon className={classes.closeIcon} />
                  </Box>
                </UpsertExperienceEditableOverlay>
              )}
            </Flex>
            <div className={classes.body}>
              <UpsertExperienceEditableOverlay
                className={classes.bodyTitle}
                {...getOverlayProps(ModalField.BODY_TITLE)}
              >
                {bodyTitle ? (
                  <UpsertExperiencePreviewText bold size="large">
                    {bodyTitle}
                  </UpsertExperiencePreviewText>
                ) : (
                  <UpsertExperienceEmptyField size="large">Body Title</UpsertExperienceEmptyField>
                )}
              </UpsertExperienceEditableOverlay>

              <UpsertExperienceEditableOverlay
                className={classes.bodyDescription}
                {...getOverlayProps(ModalField.BODY_DESCRIPTION)}
              >
                {(() => {
                  const bodyDescriptionText = serialize(bodyDescription)

                  return bodyDescriptionText ? (
                    <UpsertExperiencePreviewText size="small">
                      <InterpolateLinks text={bodyDescriptionText} />
                    </UpsertExperiencePreviewText>
                  ) : (
                    <UpsertExperienceEmptyField size="small">Body Description</UpsertExperienceEmptyField>
                  )
                })()}
              </UpsertExperienceEditableOverlay>

              <UpsertExperienceEditableOverlay
                className={classes.bodyDescription}
                {...getOverlayProps(ModalField.PREFERENCE_PURPOSES)}
              >
                <UpsertExperiencePreviewPlaceholder>Purposes List</UpsertExperiencePreviewPlaceholder>
              </UpsertExperienceEditableOverlay>
            </div>

            <Flex className={classes.footer} justifyContent="space-between" alignItems="center">
              <UpsertExperiencePoweredBy />

              <UpsertExperienceEditableOverlay {...getOverlayProps(ModalField.CONFIRM_BUTTON)}>
                {buttonText ? (
                  <UpsertExperiencePreviewButton>
                    <UpsertExperiencePreviewText bold size="small">
                      {buttonText}
                    </UpsertExperiencePreviewText>
                  </UpsertExperiencePreviewButton>
                ) : (
                  <UpsertExperienceEmptyField required button size="small">
                    Primary Button
                  </UpsertExperienceEmptyField>
                )}
              </UpsertExperienceEditableOverlay>
            </Flex>
          </Flex>
        </Flex>
      </UpsertInlineLayoutPreviewContent>

      {!!active && (
        <UpsertExperienceFieldSidebar
          title={SIDEBAR_TITLES[active]}
          required={isFieldRequired({ field: active })}
          actions={
            <Button
              disabled={!onRemoveHandledCases.includes(active!)}
              size="large"
              color="secondary"
              onClick={() => {
                onRemove(active!)
              }}
            >
              Remove
            </Button>
          }
        >
          {active === ModalField.MAIN_TITLE && (
            <FormRow marginBottom={24}>
              <FormInput
                required={isFieldRequired({ field: ModalField.MAIN_TITLE })}
                fullWidth
                name={fieldNames.consent.modal.title.root}
                label="Display Text"
              />
            </FormRow>
          )}

          {active === ModalField.BODY_TITLE && (
            <FormRow marginBottom={24}>
              <FormInput
                required={isFieldRequired({ field: ModalField.BODY_TITLE })}
                fullWidth
                name={fieldNames.consent.modal.bodyTitle.root}
                label="Display Text"
              />
            </FormRow>
          )}

          {active === ModalField.BODY_DESCRIPTION && (
            <FormRow marginBottom={24}>
              <InterpolatedTextArea
                required={isFieldRequired({ field: ModalField.BODY_DESCRIPTION })}
                label="Display Text"
                name={fieldNames.consent.modal.bodyDescription.root}
              />
            </FormRow>
          )}

          {active === ModalField.CONFIRM_BUTTON && (
            <FormInput
              required={isFieldRequired({ field: ModalField.CONFIRM_BUTTON })}
              fullWidth
              name={fieldNames.consent.modal.buttonText.root}
              label="Display Text"
            />
          )}

          {/* Purposes Fields */}

          <ConsentPurposesFields active={active} />

          {/* Close Icon Checkbox */}

          {active === ModalField.CLOSE_ICON && (
            <FormRow marginBottom={24}>
              <FormSwitch inlineLabel label="Show X Button" name="modal.showCloseIcon" />
            </FormRow>
          )}

          {shouldShowTranslationsSection && (
            <>
              <Typography mb={2.5} variant="h4" color="fadedDarkGrey.main" component="div">
                Translations
              </Typography>

              {active === ModalField.MAIN_TITLE && (
                <>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <FormRow key={language.code} marginBottom={24}>
                      <FormInput
                        fullWidth
                        required={false}
                        name={fieldNames.consent.modal.title.translation(language.code)}
                        label={`Display Text (${language.englishName})`}
                      />
                    </FormRow>
                  ))}
                </>
              )}

              {active === ModalField.BODY_TITLE && (
                <>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <FormRow key={language.code} marginBottom={24}>
                      <FormInput
                        fullWidth
                        required={false}
                        name={fieldNames.consent.modal.bodyTitle.translation(language.code)}
                        label={`Display Text (${language.englishName})`}
                      />
                    </FormRow>
                  ))}
                </>
              )}

              {active === ModalField.BODY_DESCRIPTION && (
                <>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <FormRow key={language.code} marginBottom={24}>
                      <InterpolatedTextArea
                        required={false}
                        name={fieldNames.consent.modal.bodyDescription.translation(language.code)}
                        label={`Display Text (${language.englishName})`}
                      />
                    </FormRow>
                  ))}
                </>
              )}

              {active === ModalField.CONFIRM_BUTTON && (
                <>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <FormRow key={language.code} marginBottom={24}>
                      <FormInput
                        fullWidth
                        required={false}
                        name={fieldNames.consent.modal.buttonText.translation(language.code)}
                        label={`Display Text (${language.englishName})`}
                      />
                    </FormRow>
                  ))}
                </>
              )}
            </>
          )}
        </UpsertExperienceFieldSidebar>
      )}
    </UpsertInlineLayoutPreview>
  )
}
