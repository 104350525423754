import React from 'react'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import { FormControlLabel, Typography } from '@mui/material'
import { FormCheckbox } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormCheckbox'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'

export const PreferenceThemeWelcomeTabAboutForm: React.FC = () => {
  return (
    <SidebarFormGroup sx={{ pt: 0 }}>
      <FormColorInput
        name={themeUpsertFormKeys.preferenceWelcomeTabAboutTitleColor}
        label={'Title Color'}
        fullWidth
        required
      />
      <FormColorInput
        name={themeUpsertFormKeys.preferenceWelcomeTabAboutTextColor}
        label={'Text Color'}
        fullWidth
        required
      />
      <FormColorInput
        name={themeUpsertFormKeys.preferenceWelcomeTabAboutLinkColor}
        label={'Link Color'}
        fullWidth
        required
      />
      <FormControlLabel
        control={<FormCheckbox name={themeUpsertFormKeys.preferenceWelcomeTabAboutLinkUnderline} />}
        label={<Typography variant={'label'}>Underline Links</Typography>}
        sx={{ px: '2px', mt: '-8px' }}
      />
    </SidebarFormGroup>
  )
}
