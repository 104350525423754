import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { updateDmlHook } from '../fetchers/updateDmlHook'

const useCustomMutation = createUseMutation({
  mutationFn: updateDmlHook,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateDmlHook = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
