import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchPurchasePlans } from 'api/auth/fetchers/fetchPurchasePlans'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.purchasePlans,
  queryFn: fetchPurchasePlans,
  select: res => res?.data || { plan: [] },
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const usePurchasePlansOptions = (config: Config) =>
  useCustomQuery({
    ...config,
    params: null,
  })
