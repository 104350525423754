import React from 'react'
import { Button, PopUp } from '@ketch-com/deck'
import { Typography } from '@mui/material'
// types
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'

type Props = {
  dataSubjectType: DataSubjectTypeDTO
  onCancel: () => void
}

export const CannotDeleteLastDataSubjectTypeWarningModal: React.FC<Props> = ({ dataSubjectType, onCancel }) => {
  return (
    <PopUp
      title="Delete Subject Type"
      variant="dialog"
      popUpWidth="550px"
      onClose={onCancel}
      popUpActionChildren={
        <Button color="secondary" size="large" onClick={onCancel}>
          Close
        </Button>
      }
    >
      <Typography>
        <Typography variant="label">{dataSubjectType?.name || dataSubjectType?.code}</Typography>, cannot be deleted
        because it is your last Subject Type. To delete{' '}
        <Typography variant="label">{dataSubjectType?.name || dataSubjectType?.code}</Typography>, please create an
        additional subject type.
      </Typography>
    </PopUp>
  )
}
