import {
  CreateFormFieldRequestBodyDTO,
  FormFieldCategoryDTO,
  FormFieldTypeDTO,
  FormFieldVariantDTO,
  FormFieldDTO,
  IdentitySpaceShortDTO,
} from '@ketch-com/figurehead'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { FormFieldFormDTO } from '../interfaces'

const transformOptionsToFormFieldDropdownOptionDTO = (
  dropdownOptions: FormFieldFormDTO['dropdownOptions'],
): FormFieldDTO['options'] => {
  if (!dropdownOptions) return []

  return dropdownOptions.map(dropdownOption => {
    return {
      label: dropdownOption.label,
      value: dropdownOption.value,
      translations: Object.fromEntries(
        dropdownOption.translations.map(translation => {
          return [translation!.code, translation!.fieldLabelTranslation]
        }),
      ),
    }
  })
}

const transformFormTranslationsToFormFieldDTOTranslations = (
  formTranslations: FormFieldFormDTO['translations'],
): FormFieldDTO['translations'] => {
  if (!formTranslations) return {}

  return Object.fromEntries(
    formTranslations.map(translation => {
      return [translation!.code, { title: translation!.fieldTitleTranslation, hint: translation!.uiHintTranslation }]
    }),
  )
}

const getIdentitySpaceShort = ({
  identitySpaces,
  identitySpaceId,
}: {
  identitySpaces?: IdentitySpaceDTO[]
  identitySpaceId?: string
}): IdentitySpaceShortDTO | undefined => {
  if (!identitySpaceId) return undefined
  const identitySpace = (identitySpaces || []).find(identitySpace => identitySpace.code === identitySpaceId)
  return {
    id: identitySpace?.ID,
    code: identitySpace?.code,
    name: identitySpace?.name,
  }
}

type Args = { values: FormFieldFormDTO; formField?: FormFieldDTO; identitySpaces?: IdentitySpaceDTO[] }

export const mapFormFieldValuesToPayload = ({
  values,
  formField,
  identitySpaces,
}: Args): CreateFormFieldRequestBodyDTO => {
  const dropdownOptions =
    values.inputType === FormFieldTypeDTO.DropdownFormFieldType
      ? transformOptionsToFormFieldDropdownOptionDTO(values.dropdownOptions)
      : undefined

  const checkboxOptions =
    values.inputType === FormFieldTypeDTO.CheckboxFormFieldType
      ? transformOptionsToFormFieldDropdownOptionDTO(values.checkboxOptions)
      : undefined

  const translations = transformFormTranslationsToFormFieldDTOTranslations(values.translations)
  const identitySpace = getIdentitySpaceShort({ identitySpaces, identitySpaceId: values.identitySpaceId })

  const formFieldPayload = {
    category: FormFieldCategoryDTO.CustomFormFieldCategory, // always custom in the context of editing or creating a form field.
    code: values.code,
    experiences: formField?.experiences || [],
    hint: values.uiHint,
    id: formField?.id,
    identitySpace,
    metadata: formField?.metadata, // not sure if this is needed
    options: values?.inputType === FormFieldTypeDTO.CheckboxFormFieldType ? checkboxOptions : dropdownOptions,
    title: values.title,
    translations,
    type: values.inputType as FormFieldTypeDTO,
    variant: values.variant as FormFieldVariantDTO,
    name: values.name,
  }

  return {
    formField: formFieldPayload,
  }
}
