import React from 'react'
import { Box, Typography } from '@mui/material'
import { TrackerProperty, NotFoundTracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { useUpsertTrackerPropertyDisclosure } from 'api/trackers/mutations/useUpsertTrackerPropertyDisclosure'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { Icon, IconDictionary, theme, Button } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

type Props = {
  trackerProperty: TrackerProperty
  tracker: NotFoundTracker
}

export const NotFoundTrackerPropertyDisclosure: React.FC<Props> = (
  { tracker, trackerProperty = {} as TrackerProperty },
  ref?: React.ForwardedRef<HTMLSpanElement>,
) => {
  const queryClient = useQueryClient()
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const { mutateAsync: upsertReviewTrackerPropertyDisclosure, isLoading: isUpdatingReviewTrackerPropertyDisclosure } =
    useUpsertTrackerPropertyDisclosure({
      onSuccess: async () => {
        await queryClient.invalidateQueries([ApiQueryKeys.notFoundTrackerPropertiesInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.trackerStatistics])
        showToast({ content: 'Updated disclosure', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to update disclosure', type: 'error' })
      },
    })

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Button
        sx={{
          width: 32,
          height: 32,
          borderRadius: '100%',
          padding: 0.25,
        }}
        disabled={!isPermittedToWriteCookie || isUpdatingReviewTrackerPropertyDisclosure}
        onClick={() => {
          upsertReviewTrackerPropertyDisclosure({
            params: {
              data: {
                isDisclosed: trackerProperty?.isDisclosed ? false : true,
              },
              encodedTrackerKey: tracker?.encodedKey,
              propertyId: trackerProperty?.id || '',
            },
          })
        }}
        variant={trackerProperty?.isDisclosed ? 'contained' : 'iconCustomRounded'}
        color={trackerProperty?.isDisclosed ? 'primary' : 'tertiary'}
        children={
          trackerProperty?.isDisclosed ? (
            <Icon name={IconDictionary.OEyeShow} iconColor={theme.palette.white.main} />
          ) : (
            <Icon name={IconDictionary.OEyeHide} />
          )
        }
      />

      {trackerProperty?.isDisclosed ? (
        <Typography variant="smallLabel" color="primary">
          Disclosed
        </Typography>
      ) : (
        <Typography variant="smallLabel">Hidden</Typography>
      )}
    </Box>
  )
}
