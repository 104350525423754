import React from 'react'
import { styled } from '@mui/material/styles'
import numeral from 'numeral'
import clsx from 'clsx'
import { max } from 'lodash'
import { useTranslation } from 'react-i18next'

import { MaybeNull } from 'interfaces'
import { RightStatsDTO } from 'interfaces/rights/rightStats'
import { CircularProgress, CircularProgressSize } from '@ketch-com/deck'
import { COMMON, RIGHTS_STATISTICS } from 'i18n/types'
import { Typography, Box } from '@mui/material'

const PREFIX = 'RightsSummaryWeeklyQueue'

const classes = {
  container: `${PREFIX}-container`,
  pendingContainer: `${PREFIX}-pendingContainer`,
  headerTitle: `${PREFIX}-headerTitle`,
  headerSubTitle: `${PREFIX}-headerSubTitle`,
  chartData: `${PREFIX}-chartData`,
  chartDescription: `${PREFIX}-chartDescription`,
  chartWrapper: `${PREFIX}-chartWrapper`,
  chartRow: `${PREFIX}-chartRow`,
  chartRowName: `${PREFIX}-chartRowName`,
  chartRowProgressContainer: `${PREFIX}-chartRowProgressContainer`,
  chartRowProgress: `${PREFIX}-chartRowProgress`,
  chartProgressLine: `${PREFIX}-chartProgressLine`,
  chartProgressCount: `${PREFIX}-chartProgressCount`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    boxSizing: 'border-box',
    background: theme.palette.white.main,
    borderRadius: 11,
    padding: 32,
    flex: 1,
    height: '100%',
  },

  [`&.${classes.pendingContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.chartData}`]: {
    marginTop: 29,
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.chartDescription}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 110,
  },

  [`& .${classes.chartWrapper}`]: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minWidth: 180,
  },

  [`& .${classes.chartRow}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: 16,
  },

  [`& .${classes.chartRowName}`]: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },

  [`& .${classes.chartRowProgressContainer}`]: {
    minWidth: 83,
    marginLeft: 12,
    marginRight: 12,
  },

  [`& .${classes.chartRowProgress}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },

  [`& .${classes.chartProgressLine}`]: {
    width: '100%',
    height: 20,
  },

  [`& .${classes.chartProgressCount}`]: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 20,
  },
}))

type Props = {
  isReady: boolean
  rightsReport: MaybeNull<RightStatsDTO>
}

export const RightsSummaryWeeklyQueue: React.FC<Props> = ({ isReady, rightsReport }) => {
  const { t } = useTranslation([RIGHTS_STATISTICS, COMMON])
  const maxValue = max([rightsReport?.fulfilledSevenDays, rightsReport?.invokedSevenDays]) || 1
  const data = [
    {
      name: t('Invoked'),
      percent: ((rightsReport?.invokedSevenDays || 0) / (maxValue || 1)) * 100,
      value: rightsReport?.invokedSevenDays || 0,
      color: '#E27965',
    },
    {
      name: t('Fulfilled'),
      percent: ((rightsReport?.fulfilledSevenDays || 0) / (maxValue || 1)) * 100,
      value: rightsReport?.fulfilledSevenDays || 0,
      color: '#28988B',
    },
  ]

  if (!isReady) {
    return (
      <Root className={clsx(classes.container, classes.pendingContainer)}>
        <CircularProgress size={CircularProgressSize.xLarge} />
      </Root>
    )
  }

  return (
    <Root className={classes.container}>
      <Typography variant="h3">{t('Weekly Queue Flow')}</Typography>
      <Typography color="grey">{t('What is the balance of the queue over the past seven days?')}</Typography>
      <div className={classes.chartData}>
        <Box className={classes.chartDescription} flexDirection="column">
          <Typography variant="pound" className={classes.chartRow}>
            {(rightsReport?.fulfilledSevenDays || 0) - (rightsReport?.invokedSevenDays || 0)}
          </Typography>
          <Typography variant="smallLabel">{t('Backlog')}</Typography>
        </Box>

        <div className={classes.chartWrapper}>
          {data.map((item, index) => (
            <div className={classes.chartRow} key={index}>
              <div className={classes.chartRowName}>
                <Typography variant="label">{item.name}</Typography>
              </div>
              <div className={classes.chartRowProgressContainer}>
                <div className={classes.chartRowProgress} style={{ width: `${item.percent}%`, background: item.color }}>
                  <div className={classes.chartProgressLine} />
                </div>
              </div>
              <div className={classes.chartProgressCount}>
                <Typography variant="smallLabel">{numeral(item.value).format('0,0')}</Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Root>
  )
}
