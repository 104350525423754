import React from 'react'
import Box from '@mui/material/Box'

import { FormFieldDTO } from '@ketch-com/figurehead'
import { Chip } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  formField?: FormFieldDTO
}

export const FormFieldViewDetails: React.FC<Props> = ({ formField }) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Chip
        label={
          <Typography variant="smallLabel">
            Code: <Typography variant="smallBody">{formField?.code}</Typography>
          </Typography>
        }
        size="small"
      />
    </Box>
  )
}
