import Box from '@mui/material/Box'
import { AssessmentsWidget } from './widgets/AssessmentsWidget'
import { ModelBiasWidget } from './widgets/ModelBiasWidget'
import { ConsentStatusWidget } from './widgets/ConsentStatusWidget'
import { SxProps } from '@mui/material'
import { theme } from '@ketch-com/deck'
import { useContext } from 'react'
import { ProcessingActivityContext } from '../context/ProcessingActivityContext'
import { ProcessingActivityTypeDTO } from '@ketch-com/figurehead'

const disabledStyles: SxProps = {
  opacity: 0.35,
  pointerEvents: 'none',
}

type Props = {
  disabled?: boolean
}

export const ProcessingActivityWidgets: React.FC<Props> = ({ disabled = false }) => {
  const { processingActivity } = useContext(ProcessingActivityContext)

  const showAssessmentsWidget = true
  const showModelBiasWidget = processingActivity.type === ProcessingActivityTypeDTO.ModelProcessingActivityType
  const showConsentStatusWidget =
    Array.isArray(processingActivity.dataSystems) && processingActivity.dataSystems.length > 0

  return (
    <Box display="flex" flexDirection="column" gap={2} sx={disabled ? disabledStyles : {}} mb={4}>
      {showAssessmentsWidget && <AssessmentsWidget />}
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        pt={2}
        borderTop={showModelBiasWidget || showConsentStatusWidget ? `1px solid ${theme.palette.iron.main}` : 'none'}
      >
        {showModelBiasWidget && <ModelBiasWidget />}
        {showConsentStatusWidget && <ConsentStatusWidget />}
      </Box>
    </Box>
  )
}
