import { Box, Typography } from '@mui/material'
import { SendbirdGroupChannel } from 'components/Sendbird/SendbirdGroupChannel/SendbirdGroupChannel'
import React, { useContext } from 'react'
import { SubjectDetailsCard } from '../SubjectCommunicationTab/components'
import { RightsQueueViewContext } from '../../context/RightsQueueViewContext'
import { GroupChannel } from '@sendbird/chat/groupChannel'
import { useUpdateRightInvocation } from 'api/rightInvocationsV2/mutations/useUpdateRightInvocation'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { theme } from '@ketch-com/deck'

/**
 * Subject communication tab using Sendbird.
 *
 * Initializes our group channel component within a SendbirdContext. We do this here
 * instead of on the top level of the App so that we don't unnecessarily incur costs for creating new
 * Sendbird users.
 */
export const SendbirdSubjectCommunicationTab: React.FC = () => {
  const { rightInvocation, isReadyWithoutTwilioClient } = useContext(RightsQueueViewContext)
  const queryClient = useQueryClient()

  // Same logic from https://github.com/ketch-com/figurehead/pull/3663
  const isChatInputDisabled = rightInvocation?.status === 'fulfilled' || rightInvocation?.status === 'rejected'

  const { mutateAsync: updateRightInvocation } = useUpdateRightInvocation()

  const onCreateChannel = async (channel: GroupChannel) => {
    await updateRightInvocation({
      params: {
        requestId: rightInvocation?.id ?? '',
        formData: {
          channelURL: channel.url,
        },
      },
    })

    await queryClient.invalidateQueries(ApiQueryKeys.rightInvocationV2)
  }

  if (!isReadyWithoutTwilioClient) return null

  return (
    <>
      <Box display="flex" gap="16px" width="100%">
        <Box
          display="flex"
          width="100%"
          height="65vh"
          maxWidth="846px"
          sx={{
            // Hide the sendbird image preview modal which breaks our UI
            '.sendbird-modal-root': { display: 'none' },
          }}
        >
          <SendbirdGroupChannel
            channelUrl={rightInvocation?.channelURL}
            onCreateChannel={onCreateChannel}
            // Hide the chat header
            groupChannelProps={{
              renderChannelHeader: () => <></>,
              renderMessageInput: isChatInputDisabled
                ? () => (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderTop={`1px solid ${theme.palette.Common.Divider}`}
                    >
                      <Typography variant="label" pt={3}>
                        Thread Closed
                      </Typography>
                    </Box>
                  )
                : undefined,
            }}
            channelMetadata={{ requestId: rightInvocation?.id ?? '' }}
          />
        </Box>
        <Box display="flex" flexDirection="column" maxWidth="358px">
          <SubjectDetailsCard rightInvocation={rightInvocation} />
        </Box>
      </Box>
    </>
  )
}
