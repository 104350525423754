import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchThemes } from 'api/themes-v2/fetchers/fetchThemes'
import { CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { useAuth } from 'utils/hooks/useAuth'
import { createUsePaginatedQueryPreservedPage } from 'api/common/createUsePaginatedQueryPreservedPage'

const useCustomPaginatedQuery = createUsePaginatedQueryPreservedPage({
  queryKey: ApiQueryKeys.themesPaginated,
  queryFn: fetchThemes,
  select: res => res?.data?.themes || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery, 'organizationCode'>

export const useThemesPaginated = (config?: QueryConfig) => {
  const { userData } = useAuth()

  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
      organizationCode: userData.organizationCode!,
    },
  })
}
