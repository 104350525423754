import { ConnectionV2DTO, DataSystemIdentitySpaceV2DTO } from '@ketch-com/figurehead'
import { TableCell } from '@ketch-com/deck'

import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'

export const getSystemIdentitySpaceV2DTOColumns = ({
  connection,
  identitySpacesList,
}: {
  connection: ConnectionV2DTO
  identitySpacesList: IdentitySpaceDTO[]
}): GridColDef<DataSystemIdentitySpaceV2DTO>[] => [
  {
    align: 'left',
    field: 'name',
    headerAlign: 'left',
    headerName: "System's Identity",
    sortable: false,
    flex: 1,

    renderCell: ({ row: { name, code } }: GridRenderCellParams<DataSystemIdentitySpaceV2DTO>) => {
      return <TableCell title={name} subTitle={code} />
    },
  },
  {
    align: 'left',
    field: 'userIdentitySpace',
    headerAlign: 'left',
    headerName: 'Your Organizations Identity',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { code } }: GridRenderCellParams<DataSystemIdentitySpaceV2DTO>) => {
      const userIdentitySpaceCode = connection?.identitySpaceMappings?.find(
        ({ destinationIdentitySpace }) => destinationIdentitySpace === code,
      )

      const userIdentitySpace = identitySpacesList?.find(
        ({ code }) => code === userIdentitySpaceCode?.sourceIdentitySpace,
      )

      if (!userIdentitySpace) {
        return (
          <TableCell>
            <EmptyValueRenderer />
          </TableCell>
        )
      }

      return <TableCell title={userIdentitySpace?.name} subTitle={userIdentitySpace?.code} />
    },
  },
]
