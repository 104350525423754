import { createUseFilters } from 'utils/hooks/createUseFilters'
import { TransponderWorkFilterOption } from '../components/TransponderViewWorkFilters'

export type ApplianceWorkStatus = 'QUEUED' | 'IN-PROGRESS' | 'COMPLETED' | 'FAILED'

export type StatusFilters = ApplianceWorkStatus | 'All Statuses'

export const useTransponderWorkFilters = createUseFilters<{
  status: TransponderWorkFilterOption
  inputSubject: TransponderWorkFilterOption
}>({
  status: { name: 'All Statuses' },
  inputSubject: { name: 'All Input Subjects' },
})
