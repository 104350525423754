import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

import { PreviewDeviceMode } from 'interfaces/preview/previewDeviceMode'
import {
  PreferencePreview,
  PreferencePreviewProps,
} from 'pages/consentAndRights/experiences/preview/components/preferencePreview/PreferencePreview'
import { ExperiencePreviewScreen } from 'pages/consentAndRights/experiences/preview/components/ExperiencePreviewScreen'

const useStyles = makeStyles(
  createStyles({
    mobileScreenRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    desktop: {
      padding: 0,
    },
    fullHeight: {
      height: '100%',
    },
  }),
  { name: 'PreferenceScreenPreview' },
)

type Props = {
  mode?: PreviewDeviceMode
} & Omit<PreferencePreviewProps, 'wrapperClassName' | 'className'>

export const PreferenceScreenPreview = ({ mode = PreviewDeviceMode.DESKTOP, ...rest }: Props) => {
  const classes = useStyles()

  return (
    <ExperiencePreviewScreen
      mode={mode}
      className={clsx({
        [classes.mobileScreenRoot]: mode === PreviewDeviceMode.MOBILE,
      })}
      classes={{
        desktop: classes.desktop,
      }}
    >
      <PreferencePreview wrapperClassName={classes.fullHeight} className={classes.fullHeight} {...rest} />
    </ExperiencePreviewScreen>
  )
}
