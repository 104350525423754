import React from 'react'
import { FormFieldDTO } from '@ketch-com/figurehead'
import { Box, Typography } from '@mui/material'
import { FormsUsedInStyledUl } from '.'

type Props = {
  formField?: FormFieldDTO
  type?: 'template' | 'workflow'
}

export const FormFieldTimesUsedInFormTemplatesTooltipTitle: React.FC<Props> = ({ formField, type = 'template' }) => {
  const data: { name?: string; code?: string }[] = []

  if (type === 'template') {
    const templates = formField?.templates || []

    if (templates.length < 1) return null

    templates.forEach(({ name, code }) => data.push({ name, code }))
  } else {
    const activities = formField?.activities || []

    if (activities.length < 1) return null

    activities.forEach(({ name, workflowDefinitionName }) => data.push({ name: workflowDefinitionName, code: name }))
  }

  return (
    <FormsUsedInStyledUl>
      {data?.map((row, index) => {
        return (
          <li
            key={row?.code}
            style={{
              marginBottom: index === data?.length - 1 ? 0 : 10,
            }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="smallLabel" color="white.main">
                {row?.name}
              </Typography>
              <Typography variant="footnote" color="white.main">
                {row?.code}
              </Typography>
            </Box>
          </li>
        )
      })}
    </FormsUsedInStyledUl>
  )
}
