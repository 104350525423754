import { SaveOrganizationDataSystemGlobalCustomAttributesResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

interface DeleteDataSystemGlobalCustomAttributeParams {
  id: string
}

export const deleteDataSystemGlobalCustomAttribute = ({ id }: DeleteDataSystemGlobalCustomAttributeParams) => {
  return API.delete<SaveOrganizationDataSystemGlobalCustomAttributesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/data-systems/global-custom-attributes/${id}`,
    }),
  )
}
