import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchExperiences } from 'api/experiences/fetchers/fetchExperiences'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.experiences,
  queryFn: fetchExperiences,
  select: res => res?.data?.experiences || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery, 'organizationCode'>

export const useExperiences = (config?: Config) => {
  const { userData } = useAuth()

  return useCustomQuery({
    ...config,
    params: {
      start: 0,
      limit: 1000,
      ...config?.params,
      organizationCode: userData.organizationCode!,
    },
  })
}
