import { ReactNode } from 'react'
import { styled, Theme } from '@mui/system'
import { useField } from 'formik'
import Box from '@mui/material/Box'

const StyledInput = styled('input')(({ theme }: { theme?: Theme }) => ({
  top: 0,
  left: 0,
  width: '100%',
  cursor: 'pointer',
  height: '100%',
  margin: 0,
  opacity: 0,
  padding: 0,
  zIndex: 1,
  position: 'absolute',
  '&:hover + div, &:focus + div': {
    backgroundColor: theme?.palette?.iron?.main,
  },
  '&:checked + div': {
    backgroundColor: theme?.palette?.fadedGrey.main,
    border: `2px solid ${theme?.palette?.royalBlue?.main}`,
  },
}))

const StyledBox = styled(Box)(({ theme }: { theme?: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 50,
  height: 50,
  backgroundColor: theme?.palette?.fadedGrey?.main,
}))

type Props = {
  className?: string
  name: string
  value: string | number
  children?: ReactNode
}

export const ExperiencePositionRadio = ({ className, children, value, name }: Props) => {
  const [{ value: formValue }, , { setValue, setTouched }] = useField({ name })

  return (
    <Box className={className} position="relative">
      <StyledInput
        name={name}
        type="radio"
        checked={formValue === value}
        onBlur={() => {
          setTouched(true)
        }}
        onChange={() => {
          setValue(value)
        }}
      />
      <StyledBox alignItems="center" justifyContent="center">
        {children}
      </StyledBox>
    </Box>
  )
}
