import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'
import { Link } from 'components/ui-kit/link/Link'

const useStyles = makeStyles(
  ({ typography, palette, spacing }) =>
    createStyles({
      root: {
        whiteSpace: 'normal',
      },
      nameText: {
        display: 'block',
        color: palette.darkDusk.main,
      },
      nameTextRegular: {
        fontSize: typography.pxToRem(14),
        fontWeight: 600,
        lineHeight: 1.43,
        letterSpacing: '-0.01em',
      },
      nameTextBig: {
        fontSize: typography.pxToRem(20),
        lineHeight: 1.4,
        fontWeight: 700,
        letterSpacing: '-0.02em',
      },
      codeText: {
        display: 'block',
        fontWeight: 500,
        color: palette.darkDuskFaded.main,
      },
      codeTextRegular: {
        fontSize: typography.pxToRem(11),
        lineHeight: 1.36,
        letterSpacing: '0.01em',
      },
      codeTextBig: {
        fontSize: typography.pxToRem(14),
        lineHeight: 1.43,
        letterSpacing: '-0.01em',
      },
      infoIcon: {
        fontSize: typography.pxToRem(14),
        marginLeft: spacing(1),
      },
    }),
  { name: 'NameAndCodeAndMaxLengthCellRenderer' },
)
export interface Props {
  /** Entity name */
  name?: React.ReactNode
  /** Entity code */
  code?: React.ReactNode
  /** Component max allowable length */
  maxLength?: React.ReactNode
  /** Component size code */
  size?: 'big' | 'regular'
  /** Custom wrapper className */
  className?: string
  /** Custom classes */
  classes?: Partial<ReturnType<typeof useStyles>>
  /** Optional shortcut link */
  shortCutLink?: string | null
}

export const NameAndCodeAndMaxLengthCellRenderer: React.FC<Props> = ({
  name,
  code,
  size = 'regular',
  className,
  classes: _classes,
  shortCutLink = null,
  maxLength,
}) => {
  const classes = useStyles({ classes: _classes })

  const isRegularSize = size === 'regular'
  const isBigSize = size === 'big'

  return (
    <div className={clsx(classes.root, className)}>
      {!name && !code && <EmptyValueRenderer />}
      {!!name && (
        <span
          className={clsx(classes.nameText, {
            [classes.nameTextRegular]: isRegularSize,
            [classes.nameTextBig]: isBigSize,
          })}
        >
          {name}

          {!!shortCutLink && (
            <Link variant="black" to={shortCutLink}>
              <Tooltip content="View Experience">
                <InfoOutlinedIcon className={classes.infoIcon} />
              </Tooltip>
            </Link>
          )}
        </span>
      )}
      {!!code && (
        <span
          className={clsx(classes.codeText, {
            [classes.codeTextRegular]: isRegularSize,
            [classes.codeTextBig]: isBigSize,
          })}
        >
          {code} {!!maxLength && <>(max {maxLength} chars)</>}
        </span>
      )}
    </div>
  )
}
