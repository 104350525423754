import { APIRequestParams } from 'api/common/utils'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  cookieCode: string
  organizationCode: string
  includeIssues?: boolean
}>

type Response = { cookie: CookieDTO }

export const fetchCookie = ({ organizationCode, includeMetadata, includeIssues, cookieCode }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/cookies/${cookieCode}`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
        },
      },
    }),
  )
