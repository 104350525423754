import React from 'react'
import { useNavigate } from 'react-router-dom'
import { createStyles, makeStyles } from '@mui/styles'
import { AssetSummaryDTO } from '@ketch-com/figurehead'

import { Group } from 'components/ui-layouts/group/Group'
import { Button } from 'components/ui-kit/button/Button'
import { MaybeNull } from 'interfaces'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'

const useStyles = makeStyles(
  () =>
    createStyles({
      editButton: {
        maxWidth: 60,
      },
    }),
  { name: 'AssetDetailViewActions' },
)

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export const AssetDetailViewActions: React.FC<Props> = ({ assetSummary }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteAsset = getIsPermitted([PERMISSIONS.ASSET_WRITE])

  return (
    <Group>
      <Button
        shouldRemoveMinWidth
        className={classes.editButton}
        onClick={() => {
          navigate(
            RoutesManager.assetManager.assets.upsert.getURL({
              code: assetSummary?.asset?.resource?.id || '',
              resourceTypeCode: assetSummary?.asset?.resource?.resourceType?.code || '',
            }),
          )
        }}
        disabled={!isPermittedToWriteAsset || Boolean(assetSummary?.asset?.metadata?.deletedAt)}
        tooltip={isPermittedToWriteAsset ? null : 'You do not have asset edit permissions.'}
        variant="secondary"
        size="big"
      >
        Edit
      </Button>
    </Group>
  )
}
