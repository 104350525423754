export const reporting = {
  root: {
    pattern: '/reporting',
    getURL: () => '/reporting',
  },

  permits: {
    root: {
      pattern: '/reporting/consent',
      getURL: () => '/reporting/consent',
    },

    view: {
      root: {
        pattern: '/reporting/rights',
        getURL: () => '/reporting/rights',
      },
    },
  },

  rights: {
    root: {
      pattern: '/reporting/rights',
      getURL: () => '/reporting/rights',
    },

    view: {
      root: {
        pattern: '/reporting/rights',
        getURL: () => '/reporting/rights',
      },
    },
  },
}
