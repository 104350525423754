import React from 'react'
import { useFormikContext } from 'formik'
import { compact, includes } from 'lodash'
import { useSetState } from 'react-use'
import { FormRow, EmptyState } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { RegionDTO } from 'interfaces/regions/region'
import { RegulationDTO } from 'interfaces/regulations/regulation'
import {
  filterRegionsItems,
  handleDeselectRegions,
  handleSelectRegions,
} from 'pages/policyCenter/policyScopes/upsert/utils/regionsStepHelpers'
import { getPossibleRightsFulfillment } from 'utils/helpers/rightsRegulationsHelpers'
import { Chip } from '@ketch-com/deck'
import { filterTreeItem } from 'components/transferList/utils'
import { TransferList } from 'components/transferList/TransferList'
import { GLOBAL_STANDARD_SCOPE_CODE } from 'utils/constants/policyScopes'
import { optOutRights } from '../constants'
import { PolicyScopeFormValues } from '../interfaces'
import { PolicyScopeRegionsAndRegulationContentComponent } from './PolicyScopeRegionsAndRegulationContentComponent'

type Props = {
  isEditMode?: boolean
  regulations: RegulationDTO[]
  regionGroups: RegionDTO[]
}

type State = {
  mappedItemsSearchString: string
  availableItemsSearchString: string
  mappedData: RegionDTO[]
}

export const PolicyScopeRegionsAndRegulations: React.FC<Props> = ({ regulations, regionGroups }) => {
  const [state, setState] = useSetState<State>({
    mappedData: [],
    mappedItemsSearchString: '',
    availableItemsSearchString: '',
  })

  const { values, setFieldValue, setValues } = useFormikContext<PolicyScopeFormValues>()

  const isGlobalStandardScope = values.code === GLOBAL_STANDARD_SCOPE_CODE
  const selectedRegionsCodes = values.regionCodes || []
  const setSelectedRegionsCodes = (value: string[]) => setFieldValue('regionCodes', value)
  const optionalRegions = compact(
    regionGroups.map(item =>
      filterRegionsItems({ item, filterFn: item => !includes(selectedRegionsCodes, item.code) }),
    ),
  )
  const selectedRegions = compact(
    regionGroups.map(item => filterRegionsItems({ item, filterFn: item => includes(selectedRegionsCodes, item.code) })),
  )

  const onRegulationSelect = (code: string) => {
    const updatedRegulationCodes = [...(values.regulationCodes || [])]
    const codeIndex = updatedRegulationCodes.findIndex(regulationCode => regulationCode === code)

    if (codeIndex > -1) {
      updatedRegulationCodes.splice(codeIndex, 1)
    } else {
      updatedRegulationCodes.push(code)
    }

    const rightsFulfillment = getPossibleRightsFulfillment({
      regulationCodes: updatedRegulationCodes,
      regulations,
      values,
    })

    const setValuesPayload = {
      ...values,
      regulationCodes: updatedRegulationCodes,
      rightsFulfillment: rightsFulfillment.map(rf => {
        return {
          ...rf,
          checked: optOutRights.includes(rf.rightCode) ? false : rf.checked,
          fulfillment: `${rf.fulfillment}`,
          allowAppeal: !!values?.rightsFulfillment.find(rfValues => rf.rightCode === rfValues.rightCode)?.allowAppeal,
        }
      }),
    }

    setValues(setValuesPayload)
  }

  const excludeItemsFilters = state.mappedItemsSearchString
    ? compact(optionalRegions.map(item => filterTreeItem({ item, search: state.mappedItemsSearchString })))
    : optionalRegions

  const includeItemsFilters = state.availableItemsSearchString
    ? compact(selectedRegions.map(item => filterTreeItem({ item, search: state.availableItemsSearchString })))
    : selectedRegions

  return (
    <>
      <FormRow title="Regulations">
        <Box display="flex" flexWrap="wrap" gap={1}>
          {regulations.map(({ code, name }) => (
            <Chip
              label={name}
              isSelected={values.regulationCodes?.includes(code)}
              key={code}
              clickable
              selectable
              onClick={() => onRegulationSelect(code)}
            />
          ))}
        </Box>
      </FormRow>
      <FormRow title="Regions">
        {!isGlobalStandardScope ? (
          <TransferList
            expandableKey="regions"
            excludedTitle="Available Regions"
            excludedItems={excludeItemsFilters}
            includedTitle="Selected Regions"
            onSearchIncludedItems={nextSearchString => setState({ availableItemsSearchString: nextSearchString })}
            includedItemsSearchString={state.availableItemsSearchString}
            excludedItemsSearchString={state.mappedItemsSearchString}
            onSearchExcludedItems={nextSearchString => setState({ mappedItemsSearchString: nextSearchString })}
            onChange={(items, item, type) => {
              if (type === 'INCLUDE') {
                handleSelectRegions(item, selectedRegionsCodes, setSelectedRegionsCodes)
              } else {
                handleDeselectRegions(item, selectedRegionsCodes, setSelectedRegionsCodes)
              }
            }}
            includedItems={includeItemsFilters}
            ContentComponent={PolicyScopeRegionsAndRegulationContentComponent}
          />
        ) : (
          <EmptyState
            iconName="OGlobe"
            title="The global standard jurisdiction is applied to all regions that are not mapped to a custom jurisdiction"
          />
        )}
      </FormRow>
    </>
  )
}
