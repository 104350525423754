import { Circle, Image } from 'react-konva'
import useImage from 'use-image'
import deleteSvg from 'assets/icons/closeIcon.svg'
import React, { useState } from 'react'

type Props = {
  x: number
  y: number
  onClick?: () => void
}

export const DeleteButton: React.FC<Props> = ({ x, y, onClick }) => {
  const [deleteIcon] = useImage(deleteSvg)
  const [hovered, setHovered] = useState(false)

  const handleMouseOver = () => setHovered(true)
  const handleMouseOut = () => setHovered(false)

  return (
    <>
      <Circle
        x={x}
        y={y}
        radius={16}
        fill={hovered ? 'lightgrey' : 'white'}
        shadowColor="rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.05)"
        shadowBlur={20}
        shadowOffsetX={-5}
        shadowOffsetY={15}
        shadowOpacity={0.8}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={onClick}
      />
      {/* Centered image */}
      <Image
        image={deleteIcon}
        x={x - 4.5}
        y={y - 5}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={onClick}
      />
    </>
  )
}
