import { ICanvasStep } from '../../interfaces'
import { getStepType } from './getStepType'
import { CanvasStepType } from '../constants'
import { WorkflowActivityGatewayMode } from '../../../../../../interfaces/workflowActivities/workflowActivity'

/**
 * Connect some first step to a second step
 * @param firstStep The first canvas step
 * @param secondStep The second canvas step, passed as an object or string holding the step ID
 */
export const connectSteps = (firstStep: ICanvasStep, secondStep: ICanvasStep | string | number | undefined) => {
  // Copy first step
  const newStep = firstStep

  // Determine what type of steps we are connecting
  const newStepType = getStepType(newStep)

  // Get the ID depending on how the second step was passed
  const secondStepId = typeof secondStep === 'object' ? secondStep.ID : secondStep

  switch (newStepType) {
    case CanvasStepType.Activity:
      newStep.activity!.transition = secondStepId
      break
    case CanvasStepType.Gateway:
      if (newStep.gateway!.mode === WorkflowActivityGatewayMode.JOIN) {
        newStep.gateway!.transitions = [{ ID: secondStepId }]
      } else {
        if (!newStep.gateway!.transitions.some(transition => transition.ID === secondStepId)) {
          newStep.gateway!.transitions.push({ ID: secondStepId })
        }
      }
      break
    case CanvasStepType.Workflow:
      newStep.workflow!.transition = secondStepId
      break
    case CanvasStepType.Start:
      newStep.start!.transition = secondStepId
      break
    case CanvasStepType.Placeholder:
      newStep.placeholder!.transition = secondStepId
      break
    case CanvasStepType.End:
      console.warn('End step should not have an outgoing connection!')
      break
    case CanvasStepType.Undefined:
      console.warn('Error connecting steps')
  }
}
