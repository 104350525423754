import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import {
  ViewContextVariablesModal,
  UpsertContextVariablesModal,
  DeleteContextVariablesModal,
} from 'pages/settings/contextVariables/components'
import { ContextVariableDTO } from '@ketch-com/figurehead'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'

type Props = {
  contextVariable: ContextVariableDTO
}

export const ContextVariableListDropdown: React.FC<Props> = ({ contextVariable }) => {
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const [isUpsertModalOpen, setIsUpsertModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const onSubmit = async () => {
    setIsViewModalOpen(false)
    setIsUpsertModalOpen(false)
    setIsDeleteModalOpen(false)
    await queryClient.refetchQueries(ApiQueryKeys.contextVariablesPaginated)
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button size="medium" variant="iconSubtle" onClick={handleClickListItem}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transitionDuration={0}
        onClick={e => e.stopPropagation()}
      >
        {isPermitted(PERMISSIONS.CONTEXT_VARIABLE_READ) && (
          <ActionSheetItem
            onClick={e => {
              handleClose()
              setIsViewModalOpen(true)
            }}
          >
            View
          </ActionSheetItem>
        )}
        {isPermitted(PERMISSIONS.CONTEXT_VARIABLE_READ) && isPermitted(PERMISSIONS.CONTEXT_VARIABLE_WRITE) && (
          <ActionSheetItem divider />
        )}
        {isPermitted(PERMISSIONS.CONTEXT_VARIABLE_WRITE) && (
          <ActionSheetItem
            onClick={e => {
              handleClose()
              setIsUpsertModalOpen(true)
            }}
          >
            Edit
          </ActionSheetItem>
        )}
        {isPermitted(PERMISSIONS.CONTEXT_VARIABLE_WRITE) && <ActionSheetItem divider />}
        {isPermitted(PERMISSIONS.CONTEXT_VARIABLE_WRITE) && (
          <ActionSheetItem
            onClick={e => {
              handleClose()
              setIsDeleteModalOpen(true)
            }}
          >
            Delete
          </ActionSheetItem>
        )}
      </ActionSheetWrapper>

      {isViewModalOpen && (
        <ViewContextVariablesModal
          contextVariable={contextVariable}
          onEdit={() => {
            setIsViewModalOpen(false)
            setIsUpsertModalOpen(true)
          }}
          onCancel={() => {
            setIsViewModalOpen(false)
          }}
        />
      )}

      {isUpsertModalOpen && (
        <UpsertContextVariablesModal
          contextVariable={contextVariable}
          onCancel={() => {
            setIsUpsertModalOpen(false)
          }}
          onSubmit={onSubmit}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteContextVariablesModal
          contextVariable={contextVariable}
          onCancel={() => {
            setIsDeleteModalOpen(false)
          }}
          onSubmit={onSubmit}
        />
      )}
    </>
  )
}
