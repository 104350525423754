import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { ContentGroup, DataGrid, InfoRow } from '@ketch-com/deck'
import { RightsQueueViewContext } from '../../../context/RightsQueueViewContext'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Chip, Icon } from '@ketch-com/deck'
import { rightInvocationExtensionsColumns } from '../utils'

export const WorkflowDetails: React.FC<{}> = () => {
  const { rightInvocation, original } = useContext(RightsQueueViewContext)
  const navigate = useNavigate()

  const hasExtensions = !!rightInvocation?.extensions?.length

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4} mb={4}>
        <ContentGroup title="Workflow Details" titleVariant="h3" variant="inner-page-header" />

        <InfoRow title="Workflow Used">
          <Box display="flex" alignItems="center">
            <Chip
              clickable
              deleteIcon={<Icon name="OArrowCRight" />}
              label={rightInvocation?.workflowExecution?.workflowDefinitionName || 'Workflow'}
              onClick={() =>
                navigate(
                  RoutesManager.orchestration.workflows.view.root.getURL({
                    code: rightInvocation?.workflowExecution?.workflowDefinitionCode,
                  }),
                )
              }
              onDelete={() =>
                navigate(
                  RoutesManager.orchestration.workflows.view.root.getURL({
                    code: rightInvocation?.workflowExecution?.workflowDefinitionCode,
                  }),
                )
              }
            />
          </Box>
        </InfoRow>

        <InfoRow title="Invoke Date">
          <Typography variant="body">
            <FormattedDateRenderer date={original.invokedAt} showTimezone />
          </Typography>
        </InfoRow>

        <InfoRow title="Completed" hidden={original.isPending}>
          <Typography variant="body">
            <FormattedDateRenderer date={original.finalizedAt} showTimezone />
          </Typography>
        </InfoRow>

        <InfoRow title="Request Fulfilled In" hidden={original.isPending}>
          <Typography variant="body">{`${original.daysTakenToFulfillRequest} of ${
            original.daysAllowedToFulfillRequest
          } day${original.daysAllowedToFulfillRequest === 1 ? '' : 's'}`}</Typography>
        </InfoRow>

        <InfoRow title="Request Finalized As" hidden={original.isPending}>
          <Box display="flex" alignItems="center">
            {original.isFulfilled ? (
              <Chip label="Completed" icon={<Icon name="OCheck" />} />
            ) : (
              <Chip label="Rejected" icon={<Icon name="ODecline" />} />
            )}
          </Box>
        </InfoRow>

        <InfoRow title="Forced Finalization By" hidden={!original.forcefullyCompleted}>
          <Typography variant="body">{original.finalizedByName}</Typography>
        </InfoRow>

        <InfoRow hidden={!original.forcefullyCompletedReason} title="Reason For Finalization">
          <Typography variant="body">{original.forcefullyCompletedReason}</Typography>
        </InfoRow>

        {/* Extensions */}
        {hasExtensions && (
          <InfoRow title="Extensions">
            <DataGrid
              sx={{
                height: 'auto',
                padding: 0,
              }}
              autosizeOnMount
              autosizeOptions={{
                includeHeaders: true,
                includeOutliers: false,
                columns: rightInvocationExtensionsColumns.map(column => column.field),
                expand: true,
              }}
              getRowHeight={() => 'auto'}
              columns={rightInvocationExtensionsColumns}
              disableBorder
              disableRowSelectionOnClick
              disableRowHoverStates
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              hideFooter
              getRowId={row => row.extendedAt}
              rows={rightInvocation?.extensions || []}
            />
          </InfoRow>
        )}
      </Box>
    </>
  )
}
