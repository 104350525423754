import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { deleteCustomSql } from '../fetchers/deleteCustomSql'

const useCustomMutation = createUseMutation({
  mutationFn: deleteCustomSql,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeleteCustomSql = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
