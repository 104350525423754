import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import { FormCheckbox } from 'components/ui-kit/form/checkbox/FormCheckbox'
import { RelatedPath } from 'pages/assetManager/dsrConfig/interfaces'
import { styled } from '@mui/material/styles'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { getRelatedDataset } from 'pages/assetManager/dsrConfig/utils'
import { useState, useMemo } from 'react'
import { ViewMoreModal } from 'pages/assetManager/dsrConfig/upsert/components/RelatedDataSets/components/ViewMoreModal'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'

type Props = {
  relatedPaths: RelatedPath[]
}

const PREFIX = 'RelatedDatasetPaths'

const classes = {
  pkRelationship: `${PREFIX}-pkRelationship`,
  fkRelationship: `${PREFIX}-fkRelationship`,
  infoIcon: `${PREFIX}-infoIcon`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`& .${classes.fkRelationship}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: palette.darkChrome.main,
    border: `1px solid ${palette.iron.main}`,
    borderRadius: 5,
    width: 32,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.pkRelationship}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: palette.darkViolet.main,
    backgroundColor: 'rgba(156, 100, 255, 0.25)',
    border: `1px solid rgba(156, 100, 255, 0.25)`,
    borderRadius: 5,
    width: 32,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${classes.infoIcon}`]: {
    position: 'relative',
    top: spacing(0.25),
  },
}))

export const RelatedDatasetPaths: React.FC<Props> = ({ relatedPaths }) => {
  const [pathHashId, setPathHashId] = useState('')
  const displayViewMoreModal = Boolean(pathHashId)

  const relatedDataPathElement = useMemo(() => {
    const { datasetId: relatedDataSetId } = getRelatedDataset(relatedPaths[0])
    const hasMultiplePaths = relatedPaths.length > 1
    return relatedPaths.map(relatedPath => {
      const { path, hashID } = relatedPath
      const pathLength = path.length
      let datasetPathHasReverseLookup = false
      const pathRelationships = path.flatMap(pathValue => {
        const relatedAssets = pathValue.relationships[0]
        datasetPathHasReverseLookup = datasetPathHasReverseLookup || relatedAssets[0].key === 'FK'

        return [
          <Box
            key={`${relatedAssets[0].key}${relatedAssets[0].relationshipId}`}
            className={clsx({
              [classes.pkRelationship]: relatedAssets[0].key === 'PK',
              [classes.fkRelationship]: relatedAssets[0].key === 'FK',
            })}
          >
            {relatedAssets[0].key}
          </Box>,
          <KeyboardArrowRightIcon
            key={relatedAssets[0].relationshipId}
            sx={{
              width: 16,
              height: 16,
              color: 'rgba(7, 26, 36, 0.5)',
            }}
          />,
          <Box
            key={`${relatedAssets[1].key}${relatedAssets[1].relationshipId}`}
            className={clsx({
              [classes.pkRelationship]: relatedAssets[1].key === 'PK',
              [classes.fkRelationship]: relatedAssets[1].key === 'FK',
            })}
          >
            {relatedAssets[1].key}
          </Box>,
        ]
      })

      if (pathLength > 3) {
        const remainingTables = pathLength - 2
        const diff = remainingTables === 1 ? '1 Table' : `${remainingTables} Tables`
        pathRelationships.splice(
          2,
          pathRelationships.length - 4,
          <Typography
            key={remainingTables}
            sx={{
              color: 'darkGrey.main',
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            {diff}
          </Typography>,
        )
      }

      const pathAndSelection = (
        <Box display="flex" alignItems="center" gap={1}>
          <Box width={20}>
            {datasetPathHasReverseLookup ? (
              <Tooltip
                position="bottom"
                content={
                  <Box display="flex" flexDirection="column" gap={1} p={1}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: 12,
                        lineHeight: '18px',
                      }}
                    >
                      This set of relationships contains at least one "many to one" reverse FK to PK lookups.
                    </Typography>
                    <Typography variant="smallBody">
                      That can potentially cause data to be incorrectly accessed or deleted. Please verify that only
                      data associated with this particular data subject is selected.
                    </Typography>
                  </Box>
                }
              >
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            ) : null}
          </Box>

          <Box display="flex" alignItems="center" gap={0.5}>
            {hasMultiplePaths ? (
              <Box>
                <FormCheckbox name={`relatedDatasets.${relatedDataSetId}.${hashID}`} />
              </Box>
            ) : (
              <Box display="none">
                <FormCheckbox name={`relatedDatasets.${relatedDataSetId}.${hashID}`} />
              </Box>
            )}
            {pathRelationships}
            <Typography
              sx={{
                color: 'sphere.main',
                fontWeight: 600,
                fontSize: 14,
                marginLeft: 1,
                cursor: 'pointer',
              }}
              onClick={() => {
                setPathHashId(hashID)
              }}
            >
              View More
            </Typography>
          </Box>
        </Box>
      )

      return (
        <StyledBox key={hashID} my={1}>
          {pathAndSelection}
        </StyledBox>
      )
    })
  }, [relatedPaths])

  return (
    <>
      {relatedDataPathElement}
      {displayViewMoreModal ? (
        <ViewMoreModal
          pathHashId={pathHashId}
          relatedPaths={relatedPaths}
          onCancel={() => {
            setPathHashId('')
          }}
        />
      ) : null}
    </>
  )
}
