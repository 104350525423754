import { FindClassificationsV3ResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  query?: string
  isSystemClassification?: string
  start?: number
  limit?: number
}>

export const fetchDataCategories = ({ query, isSystemClassification, start, limit }: Params) => {
  return API.get<FindClassificationsV3ResponseBodyDTO>(
    formatRequestString({
      entityUrl: '/api/v3/classifications',
      params: {
        filters: {
          query,
          isSystemClassification,
          start,
          limit,
        },
      },
    }),
  )
}
