import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
  includeIssues?: boolean
  policyScopeCode?: string
  query?: string | null | undefined
}>

type Response = APIListResponse<{ purposes: PurposeDTO[] }>

export const fetchPurposes = ({
  organizationCode,
  policyScopeCode,
  includeMetadata,
  includeIssues,
  start,
  limit,
  query,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/purposes`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          policyScopeCode,
          start,
          limit,
          ...(query && {
            query,
          }),
        },
      },
    }),
  )
