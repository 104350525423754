import { PutTeamBody, Team } from 'interfaces/services/harbormaster/teams_gen.schemas'
import { UserShortDTO } from 'interfaces/users/users'
import * as Yup from 'yup'

export type TeamFormType = {
  selectionMethod?: string
  defaultAssigneeId?: string
  description?: string
  id?: string
  label?: string
  members?: UserShortDTO[]
  name?: string
  notificationsEnabled?: boolean
}

const getUsersFromMemberIds = (memberIds?: string[], users?: UserShortDTO[]) => {
  return users?.filter(user => memberIds?.includes(user.ID)) || []
}

export const getInitialValues = (team?: Team, users?: UserShortDTO[]): TeamFormType => {
  return {
    defaultAssigneeId: team?.defaultAssigneeId || undefined,
    description: team?.description || '',
    id: team?.id || undefined,
    label: team?.label || '',
    members: getUsersFromMemberIds(team?.memberIds, users),
    name: team?.name || '',
    selectionMethod: team?.defaultAssigneeId ? 'predefined' : 'random',
    notificationsEnabled: team?.notificationsEnabled || false,
  }
}

export const getValidationSchema = () => {
  return () =>
    Yup.object().shape({
      name: Yup.string().max(75, 'Name must not exceed 75 characters').required('Please enter a team name'),
      label: Yup.string()
        .max(2, 'Label must be 2 characters')
        .min(2, 'Label must be 2 characters')
        .required('Please enter a team label'),
      description: Yup.string().max(400, 'Name must not exceed 400 characters'),
      members: Yup.array().of(Yup.object()).min(1, 'Team must contain at least one member'),
    })
}

export const mapFormValuesToPayload = (team: TeamFormType): PutTeamBody => {
  return {
    team: {
      // Leave default assignee blank for random selection
      defaultAssigneeId: team.selectionMethod === 'random' ? undefined : team?.defaultAssigneeId,
      description: team?.description,
      id: team?.id,
      label: team?.label?.toUpperCase(),
      memberIds: team?.members?.map(({ ID }) => ID) || [],
      name: team?.name,
      notificationsEnabled: team?.notificationsEnabled,
    },
  }
}
