// @ts-nocheck
import React, { memo } from 'react'
import ReactMarkdown from 'react-markdown'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { theme } from '@ketch-com/deck'

export interface MarkdownRendererProps {
  markdown: string
}

export const MarkdownRenderer: React.FC<MarkdownRendererProps> = memo(({ markdown }) => {
  const mergedStyles = { ...solarizedlight }
  mergedStyles['pre[class*="language-"]'].backgroundColor = theme.palette.Common.AppBackground

  return (
    <ReactMarkdown
      components={{
        code({ node, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '')
          return match ? (
            <SyntaxHighlighter style={mergedStyles} language={match[1]} PreTag="div" {...props}>
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          )
        },
      }}
    >
      {markdown}
    </ReactMarkdown>
  )
})
