import React from 'react'
import { Route } from 'react-router-dom'

import { Pagination } from 'api/common/paginatedQuery'
import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO, AssetPolicyDTO, ConnectionDTO } from '@ketch-com/figurehead'
import { ASSET_TYPE_ENUM } from 'interfaces/assets'
import { DatabaseUpsertContainer } from 'pages/assetManager/databases/upsert/DatabaseUpsertContainer'
import { AttributeUpsertContainer } from 'pages/assetManager/attributes/upsert/AttributeUpsertContainer'
import { DatasetUpsertContainer } from 'pages/assetManager/datasets/upsert/DatasetUpsertContainer'
import { RoutesManager } from 'utils/routing/routesManager'
import { SchemaUpsertContainer } from 'pages/assetManager/schemas/upsert/SchemaUpsertContainer'
import { Spinner } from 'components/ui-kit/spinner/Spinner'

type Props = {
  isReady: boolean
  assetSummary: MaybeNull<AssetSummaryDTO>
  policies: MaybeNull<AssetPolicyDTO[]>
  pagination: Pagination
  connections: ConnectionDTO[]
}

export const AssetUpsert: React.FC<Props> = ({ isReady, assetSummary, connections, policies, pagination }) => {
  if (!isReady) return <Spinner />

  if (
    assetSummary?.asset?.resource?.resourceType?.canonicalResourceType ===
    ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATABASE
  ) {
    return <DatabaseUpsertContainer />
  }

  if (
    assetSummary?.asset?.resource?.resourceType?.canonicalResourceType ===
    ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATASET
  ) {
    return <DatasetUpsertContainer />
  }

  if (
    assetSummary?.asset?.resource?.resourceType?.canonicalResourceType ===
    ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_ATTRIBUTE
  ) {
    return <AttributeUpsertContainer />
  }

  // TODO:BAC - not sure about this conditional. Seems a bit hacky.
  if (assetSummary?.asset?.resource?.resourceType?.code?.includes?.('.schema')) {
    return <SchemaUpsertContainer />
  }

  return <Route path={RoutesManager.assetManager.assets.list.getURL()} />
}
