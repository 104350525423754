import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { Button } from '@ketch-com/deck'
import { BusinessFunctionDTO } from '@ketch-com/figurehead'
import { FormMode } from 'interfaces/formModes/formMode'
import { formatLastUpdatedDate } from 'utils/helpers/dateTime'
import { RoutesManager } from 'utils/routing/routesManager'
import { useDeleteBusinessFunction } from 'api/businessFunctions/queries/useDeleteBusinessFunction'

export const BusinessFunctionOverviewActions = ({ businessFunction }: { businessFunction: BusinessFunctionDTO }) => {
  const navigate = useNavigate()

  const { mutateAsync: handleDeleteBusinessFunction } = useDeleteBusinessFunction({
    onSuccess: () => navigate(RoutesManager.policyCenter.businessFunctions.root.getURL()),
    onError: () => {},
  })

  const deleteBusinessFunction = () => {
    handleDeleteBusinessFunction({ params: { code: businessFunction.code || '' } })
  }

  const editBusinessFunction = () =>
    navigate(
      RoutesManager.policyCenter.businessFunctions.upsert.root.getURL({
        code: businessFunction.code,
        formMode: FormMode.EDIT,
      }),
    )

  return (
    <Box display="flex" alignItems="center" height={44} gap="12px">
      <Box display="flex" alignItems="flex-start" flexDirection="column">
        <Typography color="darkDuskFaded.main" variant="smallBody">
          Last Updated&nbsp;
        </Typography>
        <Typography color="darkDusk.main" variant="smallBody">
          {formatLastUpdatedDate(businessFunction.metadata?.updatedAt)}
        </Typography>
      </Box>
      <Button size="large" color="tertiary" onClick={deleteBusinessFunction}>
        Delete
      </Button>
      <Button size="large" color="secondary" onClick={editBusinessFunction}>
        Edit
      </Button>
    </Box>
  )
}
