import React from 'react'
import { UserDTO } from 'interfaces/users/users'
import { FormRow, InfoRow } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { useIsEntitled } from 'utils/hooks'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { UserTeamsInfoRow } from 'pages/settings/userProfile/view/UserTeamsInfoRow'
import { RolePermissionInfoRow } from './RolePermissionInfoRow'
import { getUserRoleData } from '../utils/getUserRolesDisplayData'

type Props = {
  user: UserDTO
}

export const UsersViewOverview: React.FC<Props> = ({ user }) => {
  const { isEntitled } = useIsEntitled()

  const rows = getUserRoleData(user.roles, isEntitled).sort((a, b) => a.priority - b.priority)

  return (
    <Box>
      <FormRow title="Details" sx={{ '& .DeckFormRowTitleSection': { padding: '32px 0px' } }}>
        <InfoRow title="Email">
          <Typography variant="body">{user?.email || <EmptyValueRenderer />}</Typography>
        </InfoRow>
        <UserTeamsInfoRow inForm={false} userId={user.ID} />
      </FormRow>
      <FormRow title="Roles & Permissions" sx={{ '& .DeckFormRowTitleSection': { paddingBottom: '0px' } }}>
        {rows.map(row => {
          return <RolePermissionInfoRow data={row} />
        })}
      </FormRow>
    </Box>
  )
}
