import React, { useState, useMemo, useEffect, useCallback, ReactNode } from 'react'
import { useDebounce } from 'react-use'
import { createCustomContext } from 'utils/hooks/createCustomContext'
import { GetAssetsDataSystemsResponseBodyDTO, OffsetResponseDTO } from '@ketch-com/figurehead'
import { MegaFilterSectionType } from '@ketch-com/deck'
import { useDataCategories } from 'api/dataSystemsClassifications/queries/useDataCategories'
import { AppListItem } from 'pages/dataSystems/Home/components/Overview/hooks/useAppListItems'
import useAssetQueryPayload, {
  InstalledDataSystemAssetsQueryConfig,
  PaginationModel,
} from '../hooks/useAssetQueryPayload'
import { useDataSystemsContext } from '../../context/DataSystemContext'
import { useAssetsDataSystems } from 'api/assets/queries/useAssetsDataSystems'
import { getInstalledDataSystemAssets } from 'api/dataSystems/fetchers/getInstalledDataSystemAssets'
import { AssetsTabRowEditModalProps } from '../components/AssetsTabRowEditModal'
import useFilteredAndDedupedDataSystemAssets from '../hooks/useFilteredAndDedupedDataSystemAssets'
import { DataAssetsDedupedInputItem } from '../utils/dedupeDataAssetsById'
import { AssetsFiltersData, AssetsSystemListFilterOption, AssetsTabsFilterCategories } from '../../types'
import { filterDisplayNameMap, personalDataTypeOptions } from '../constants'

interface FilterSectionType extends Omit<MegaFilterSectionType, 'value'> {
  value: AssetsTabsFilterCategories
}

const initialFiltersData = {
  dataSensitivityCodes: personalDataTypeOptions.map(option => ({
    name: option.name,
    isSelected: false,
    id: option.value,
  })),
}

const initialCounts = {
  dataSensitivityCodes: 0,
  dataCategoryCodes: 0,
  dataTypeCodes: 0,
}

export interface FilterContextProps {
  activeFiltersCount: number
  appListItems?: AppListItem[]
  assetCategory: string | undefined
  assetQueryPayload: InstalledDataSystemAssetsQueryConfig
  assetsDataSystems: GetAssetsDataSystemsResponseBodyDTO
  filterButtonText: string
  filterData: AssetsFiltersData
  handleSearchInputChange: (value: string) => void
  isLoadingAssetsDataSystems: boolean
  paginationModel: PaginationModel
  parentResourceID?: string | undefined
  resetFilters: () => void
  searchQuery: string
  sections: FilterSectionType[]
  setAssetCategory: (value: string | undefined) => void
  setCounts: React.Dispatch<React.SetStateAction<Record<string, number>>>
  setFilterData: React.Dispatch<React.SetStateAction<AssetsFiltersData>>
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>
  setParentResourceID: (value: string | undefined) => void
  snapshotCurrentQueryDetails: (
    name: string,
    resourceType: string,
    iconName: string,
    childResourceCount: number | undefined,
    childResourceType: string,
  ) => void
  drillDownLineage: DrillDownLineage
  traverseLineage: (index: number) => void
  handleClearDrilldown: () => void

  showEditModal: Omit<AssetsTabRowEditModalProps, 'refetchInstalledDatasystemAssets'> | undefined
  setShowEditModal: (value: Omit<AssetsTabRowEditModalProps, 'refetchInstalledDatasystemAssets'> | undefined) => void

  dedupedDataSystemAssets: DataAssetsDedupedInputItem[] | undefined
  isLoadingInstalledDatasystemAssets: boolean
  installedDatasystemPagination: OffsetResponseDTO | undefined
  refetchInstalledDatasystemAssets: (option?: any) => Promise<any>
}

interface LineageItemDetails {
  name: string
  resourceType: string
  iconName: string
  childResourceCount: number | undefined
  childResourceType: string
}
interface LineageItem {
  displayDetails: LineageItemDetails
  payload: InstalledDataSystemAssetsQueryConfig
}

type DrillDownLineage = LineageItem[]

const { Provider, useCustomContext: useAssetsTabFilterContext } = createCustomContext<FilterContextProps>({
  displayName: 'AssetsTabFilterContext',
})

export const AssetsTabFilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [filterData, setFilterData] = useState<AssetsFiltersData>(initialFiltersData)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [counts, setCounts] = useState<Record<string, number>>(initialCounts)
  const [showEditModal, setShowEditModal] = useState<
    Omit<AssetsTabRowEditModalProps, 'refetchInstalledDatasystemAssets'> | undefined
  >()

  useDebounce(setSearchQuery, 500, [setSearchQuery])

  const { appDescriptor } = useDataSystemsContext()
  const dataSystemCode = appDescriptor?.dataSystem?.code
  const { data: assetsDataSystems, isLoading: isLoadingAssetsDataSystems } = useAssetsDataSystems({
    params: { dataSystemCode },
  })

  const handleSearchInputChange = useCallback((value: string) => {
    setSearchQuery(value)
  }, [])

  const handleClearSearchInput = useCallback(() => {
    setSearchQuery('')
  }, [])

  const resetFilters = useCallback(() => {
    setFilterData(
      prevFilterData =>
        Object.fromEntries(
          Object.entries(prevFilterData).map(([key, filterOptions]) => [
            key,
            filterOptions.map(option => ({
              ...option,
              isSelected: false,
            })),
          ]),
        ) as AssetsFiltersData,
    )
    setCounts(initialCounts)
  }, [])

  const {
    assetCategory,
    setAssetCategory,
    parentResourceID,
    setParentResourceID,
    assetQueryPayload,
    paginationModel,
    setPaginationModel,
  } = useAssetQueryPayload(appDescriptor?.id, filterData, searchQuery?.length ? searchQuery : undefined)

  const [drillDownLineage, setDrillDownLineage] = useState<DrillDownLineage>([])

  const snapshotCurrentQueryDetails = useCallback(
    (
      name: string,
      resourceType: string,
      iconName: string,
      childResourceCount: number | undefined,
      childResourceType: string,
    ) => {
      const lineageDetails = {
        displayDetails: {
          name,
          resourceType,
          iconName,
          childResourceCount,
          childResourceType,
        },
        payload: assetQueryPayload,
      }
      setDrillDownLineage(prev => [...prev, lineageDetails])
      handleClearSearchInput()
    },
    [assetQueryPayload, handleClearSearchInput],
  )

  const handleClearDrilldown = useCallback(() => {
    setDrillDownLineage([])
    setParentResourceID(undefined)
    setAssetCategory(assetsDataSystems.dataSystems?.[0]?.assetCategories?.[0].code)
    setSearchQuery('')
    setPaginationModel({ page: 0, pageSize: 10 })
    resetFilters()
  }, [assetsDataSystems.dataSystems, resetFilters, setAssetCategory, setPaginationModel, setParentResourceID])
  const traverseLineage = useCallback(
    (index: number) => {
      const selectedLineage = drillDownLineage[index]
      const newLineage = index === 0 ? [] : drillDownLineage.slice(0, index)
      setParentResourceID(selectedLineage.payload.params.filters?.parentResourceID as string | undefined)
      setAssetCategory(selectedLineage.payload.params.filters?.resourceType as string | undefined)
      setSearchQuery('')
      setPaginationModel({ page: 0, pageSize: 10 })
      setDrillDownLineage(newLineage)
    },
    [drillDownLineage, setAssetCategory, setPaginationModel, setParentResourceID],
  )

  const { data: allDataCategories } = useDataCategories({ params: { limit: 1000 } })

  useEffect(() => {
    async function fetchData() {
      if (appDescriptor?.id && !filterData.dataTypeCodes) {
        const installedDataSystemAssets = await getInstalledDataSystemAssets({ id: appDescriptor.id })
        const filteredInstalledDataSystemAssets = installedDataSystemAssets.data.assets?.filter(
          asset => asset.dataTypes,
        )

        let uniqueDataTypes: AssetsSystemListFilterOption[] = []
        if (filteredInstalledDataSystemAssets) {
          uniqueDataTypes = Object.values(
            filteredInstalledDataSystemAssets
              .flatMap(item => item.dataTypes || [])
              .reduce(
                (acc, dataType) => {
                  if (dataType && dataType.id && dataType.name && dataType.code) {
                    acc[dataType.id] = { name: dataType.name, id: dataType.code, isSelected: false }
                  }
                  return acc
                },
                {} as Record<string, AssetsSystemListFilterOption>,
              ),
          )
        }

        setFilterData(prev => ({
          ...prev,
          dataTypeCodes: uniqueDataTypes,
        }))
      }
    }
    if (appDescriptor?.id && !filterData.dataTypeCodes) {
      fetchData()
    }
  }, [appDescriptor?.id, filterData.dataTypeCodes])

  const dataCategoryFilterOptions = useMemo(() => {
    if (allDataCategories?.categories) {
      return allDataCategories?.categories.map(dataCategory => ({
        name: dataCategory.name || dataCategory.code!,
        id: dataCategory.code!,
        isSelected: false,
      }))
    }
    return undefined
  }, [allDataCategories?.categories])

  useEffect(() => {
    if (!filterData.dataCategoryCodes && dataCategoryFilterOptions) {
      setFilterData(prev => ({
        ...prev,
        dataCategoryCodes: [...dataCategoryFilterOptions],
      }))
    }
  }, [dataCategoryFilterOptions, filterData.dataCategoryCodes])

  const activeFiltersCount = useMemo(() => {
    return Object.values(counts).reduce((acc, item) => acc + item, 0)
  }, [counts])

  const filterButtonText = useMemo(() => {
    return `Filter ${activeFiltersCount ? `(${activeFiltersCount})` : ''}`
  }, [activeFiltersCount])

  const sections = useMemo(() => {
    return Object.keys(filterData).map(key => ({
      value: key as AssetsTabsFilterCategories,
      label: filterDisplayNameMap[key],
    }))
  }, [filterData])

  const {
    dedupedDataSystemAssets,
    isLoadingInstalledDatasystemAssets,
    installedDatasystemPagination,
    refetchInstalledDatasystemAssets,
  } = useFilteredAndDedupedDataSystemAssets(assetQueryPayload)

  const value = useMemo(
    () => ({
      filterData,
      searchQuery,
      setFilterData,
      handleSearchInputChange,
      assetsDataSystems,
      isLoadingAssetsDataSystems,
      activeFiltersCount,
      filterButtonText,
      sections,
      setCounts,
      resetFilters,
      assetCategory,
      setAssetCategory,
      parentResourceID,
      setParentResourceID,
      assetQueryPayload,
      paginationModel,
      setPaginationModel,
      snapshotCurrentQueryDetails,
      drillDownLineage,
      traverseLineage,
      handleClearDrilldown,
      showEditModal,
      setShowEditModal,
      dedupedDataSystemAssets,
      isLoadingInstalledDatasystemAssets,
      installedDatasystemPagination,
      refetchInstalledDatasystemAssets,
    }),
    [
      filterData,
      searchQuery,
      handleSearchInputChange,
      assetsDataSystems,
      isLoadingAssetsDataSystems,
      activeFiltersCount,
      filterButtonText,
      sections,
      resetFilters,
      assetCategory,
      setAssetCategory,
      parentResourceID,
      setParentResourceID,
      assetQueryPayload,
      paginationModel,
      setPaginationModel,
      snapshotCurrentQueryDetails,
      drillDownLineage,
      traverseLineage,
      handleClearDrilldown,
      showEditModal,
      dedupedDataSystemAssets,
      isLoadingInstalledDatasystemAssets,
      installedDatasystemPagination,
      refetchInstalledDatasystemAssets,
    ],
  )

  return <Provider value={value}>{children}</Provider>
}

export { useAssetsTabFilterContext }
