import {
  GetTrackersOnNewPropertiesParams,
  GetTrackersOnNewPropertiesResponseBody,
} from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchTrackersOnNewProperties = ({ nextToken, limit, searchString }: GetTrackersOnNewPropertiesParams) => {
  return API.get<GetTrackersOnNewPropertiesResponseBody>(
    formatRequestString({
      entityUrl: '/api/privacy-configuration/trackers/new-properties',
      params: {
        filters: {
          ...(nextToken && {
            nextToken,
          }),
          ...(limit && {
            limit,
          }),
          ...(searchString && {
            searchString,
          }),
        },
      },
    }),
  )
}
