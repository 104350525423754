export enum OBLIGATION_ENUM {
  DELETE = 'https://ketch.com/v1/obligations/delete',
  GET = 'https://ketch.com/v1/obligations/getRow',

  NULLIFY = 'https://ketch.com/v1/obligations/nullify',
  UPDATE = 'https://ketch.com/v1/obligations/update',

  INCLUDE = 'https://ketch.com/v1/obligations/getCell',
  NO_ACTION = 'noAction',

  UPDATE_AS_HASHED = 'https://ketch.com/v1/obligations/updateAsHashed',
  UPDATE_AS_MASKED = 'https://ketch.com/v1/obligations/updateAsMasked',
}
