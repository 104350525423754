import { Button, Icon, theme } from '@ketch-com/deck'
import { useDataSystemsContext } from '../../context/DataSystemContext'
import { useDataCategoriesRolloverContext } from '../../context/DataCategoriesRolloverContext'
import { Box } from '@mui/material'
import { classificationFilterKeyToTypeMap } from '../constants'

export interface ViewInsightsButtonProps {
  classificationName: string
  classificationCode: string
  classificationId: string
  classificationFilterKey: string
  classificationDescription?: string
  onClickCallback?: () => void
}

export const ViewInsightsButton: React.FC<ViewInsightsButtonProps> = ({
  classificationName,
  classificationCode,
  classificationId,
  classificationFilterKey,
  classificationDescription,
  onClickCallback,
}) => {
  const { setdataClassificationsRolloverProps } = useDataSystemsContext()
  const { setDataClassificationStaticFilter } = useDataCategoriesRolloverContext()

  return (
    <Button
      sx={{ marginTop: '4px', width: '190px', flexGrow: 1, padding: '0px', paddingLeft: '2px' }}
      variant="text"
      onClick={() => {
        setdataClassificationsRolloverProps({
          dataClassification: {
            name: classificationName,
            code: classificationCode,
            id: classificationId,
            type: classificationFilterKeyToTypeMap[classificationFilterKey],
          },
        })
        setDataClassificationStaticFilter([classificationFilterKey, classificationCode])
        if (onClickCallback) onClickCallback()
      }}
    >
      {
        <Box display="flex" justifyContent="space-between" alignItems="center" width="190px">
          <Box display="flex" gap={0.5} alignItems="center">
            <Icon name="FAi" width="16px" height="16px" />
            View Insights
          </Box>
          <Icon name="OArrowCRight" iconColor={theme.palette.Common.AppBackground} width="16px" height="16px" />
        </Box>
      }
    </Button>
  )
}
