import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { getInitialValues, getValidationSchema } from 'pages/assetManager/databases/upsert/utils'
import { Group } from 'components/ui-layouts/group/Group'
import { Button } from '@ketch-com/deck'
import { UpsertLayout } from 'components/ui-layouts/upsertLayout/UpsertLayout'
import { UpsertLayoutFooter } from 'components/ui-layouts/upsertLayout/UpsertLayoutFooter'
import { MaybeNull } from 'interfaces/common'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { LabelDefinitionDTO as LabelDTO } from '@ketch-com/figurehead'
import { EditAssetForm } from 'pages/assetManager/components'
import { RoutesManager } from 'utils/routing/routesManager'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { usePurposes } from 'api/purposes/queries/usePurposes'
// import { getAssetSummaryWithSchedule } from './__mocks__/getAssetSummaryWithSchedule'

type Props = {
  isReady: boolean
  isUpdatingDatabase: boolean
  onSubmit: (values: EditDatabaseFormValues) => Promise<void>
  database: MaybeNull<AssetSummaryDTO>
  labels: MaybeNull<LabelDTO[]>
}

export const DatabaseUpsertView: React.FC<Props> = ({ isReady, onSubmit, database, isUpdatingDatabase }) => {
  const navigate = useNavigate()
  const initialValues = useMemo(() => getInitialValues({ database }), [database])
  const validationSchema = useMemo(getValidationSchema, [])
  const { isLoading: isLoadingPurposes } = usePurposes({})

  return (
    <UpsertLayout
      isReady={isReady && !isLoadingPurposes}
      title="Edit Database"
      breadcrumbs={[
        { title: 'Data Map', link: RoutesManager.assetManager.assets.root.getURL() },
        { title: 'Assets', link: RoutesManager.assetManager.assets.list.getURL() },
        {
          title: database?.asset?.resource?.name || database?.asset?.resource?.id,
          link: RoutesManager.assetManager.assets.detail.root.getURL({
            code: database?.asset?.resource?.id || '',
          }),
        },
        { title: 'Edit Database' },
      ]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm, isValid }) => {
        return (
          <>
            <EditAssetForm assetSummary={database} />

            <UpsertLayoutFooter>
              <Group>
                <Button
                  pending={isSubmitting}
                  color="secondary"
                  size="large"
                  onClick={() => {
                    navigate(
                      RoutesManager.assetManager.assets.detail.root.getURL({
                        code: database?.asset?.resource?.id,
                        resourceTypeCode: database?.asset?.resource?.resourceType?.code || '',
                      }),
                    )
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!isValid}
                  size="large"
                  onClick={submitForm}
                  pending={isSubmitting || isUpdatingDatabase}
                >
                  Save
                </Button>
              </Group>
            </UpsertLayoutFooter>
          </>
        )
      }}
    </UpsertLayout>
  )
}
