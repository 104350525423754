import { ApplicationDTO, UpsertApplicationDTO } from 'interfaces/applications/application'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  applicationCode: string
  formData: UpsertApplicationDTO
}

type Response = { application: ApplicationDTO }

export const updateApplication = ({ organizationCode, applicationCode, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/applications/${applicationCode}`,
    }),
    formData,
  )
