import React, { useMemo } from 'react'
import { isNil } from 'lodash'
import { makeStyles, createStyles } from '@mui/styles'
import { SxProps, Theme } from '@mui/material'
import { useField, FieldValidator } from 'formik'
import Box from '@mui/material/Box'
import clsx from 'clsx'

import { ObjectLiteral } from 'interfaces'
import { Dropdown, DropdownProps } from 'components/ui-kit/dropdown/Dropdown'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { Label } from 'components/ui-kit/form/common/label/Label'

const useStyles = makeStyles(
  createStyles({
    root: {
      display: 'inline-block',
    },
    fullWidth: {
      display: 'block',
    },
    fullWidthDropdown: {
      width: '100%',
    },
    hidden: {
      opacity: 0,
    },
  }),
  { name: 'FormDropdown' },
)

type Props<T> = Omit<DropdownProps, 'items' | 'value' | 'onOpen' | 'onClose' | 'valid' | 'name'> & {
  /** Formik field name */
  name: string
  /** Custom validation method */
  validate?: FieldValidator
  /** Value key refers to item value */
  valueKey?: string
  /** Array of items to render in menu */
  items: T[]
  /** Selected item renderer. Defaults to renderItem prop */
  renderValue?: (selectedItem: T) => React.ReactNode
  /** Item renderer */
  renderItem?: (item: T) => React.ReactNode
  /** Item change */
  onChange?: (item: T) => void
  /** Text to be displayed above Component */
  label?: string
  /** Display Asterisk as required field */
  required?: boolean
  /** Is control expands for 100% of its container width */
  fullWidth?: boolean
  /** Adds margin at bottom of input such that when multiple inputs in a row and only one has error, they are aligned */
  hasErrorSpacer?: boolean
  /** Prop to provide sx style overrides */
  sx?: SxProps<Theme>
  /** Label info text */
  labelInfo?: string
}

export function FormDropdown<T extends ObjectLiteral>({
  name,
  validate,
  valueKey = 'id',
  items,
  renderItem = ({ name }) => name,
  renderValue = renderItem,
  label,
  required = true,
  fullWidth = false,
  className,
  onChange,
  hasErrorSpacer = false,
  labelInfo = '',
  sx,
  ...rest
}: Props<T>) {
  const classes = useStyles()
  const [{ value }, { touched, error }, { setTouched, setValue }] = useField({ name, validate })
  const showError = error && touched

  const itemsMap = useMemo(
    () =>
      items.reduce<ObjectLiteral<T>>(
        (acc, item) => ({
          ...acc,
          [item[valueKey]]: item,
        }),
        {},
      ),
    [items, valueKey],
  )

  const hasValue = value !== '' && !isNil(value) && itemsMap[value]

  const componentProps = {
    ...rest,
    valid: !showError,
    items: items.map(item => ({
      active: item[valueKey] === value,
      content: renderItem(item),
      onClick: () => {
        setValue(item[valueKey])
        onChange?.(item)
      },
      disabled: Boolean(item?.disabled) || false,
    })),
    onClose: () => {
      setTouched(true)
    },
    value: hasValue,
    ...(hasValue && {
      children: renderValue(itemsMap[value]),
    }),
    name,
  }

  return (
    <Box sx={sx} id={name} className={clsx(classes.root, { [classes.fullWidth]: fullWidth })}>
      {label && (
        <Label info={labelInfo} required={required} size={rest.size}>
          {label}
        </Label>
      )}

      <Dropdown {...componentProps} className={clsx({ [classes.fullWidthDropdown]: fullWidth }, className)} />

      {showError && <Error>{error}</Error>}
      {hasErrorSpacer && !showError && <Error className={classes.hidden}>hidden</Error>}
    </Box>
  )
}
