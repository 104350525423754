import { PreviewConfigurationThemeDTO } from '@ketch-com/figurehead'
import { Theme } from '@ketch-sdk/ketch-types'
import { fullConfigBeta, getContrastColor } from '@ketch-com/lanyard' // leveraging this as source of truth

export const fullyHydratedDefaultThemeColors = {
  bannerBackgroundColor: fullConfigBeta?.theme?.bannerBackgroundColor || '#FFFFFF',
  bannerButtonColor: fullConfigBeta?.theme?.bannerButtonColor || '#000000',
  bannerContentColor: fullConfigBeta?.theme?.bannerContentColor || '#000000',
  bannerSecondaryButtonColor: fullConfigBeta?.theme?.bannerSecondaryButtonColor || '#000000',

  formBodyBackgroundColor: fullConfigBeta?.theme?.formBodyBackgroundColor || '#FFFFFF',
  formButtonColor: fullConfigBeta?.theme?.formButtonColor || '#000000',
  formContentColor: fullConfigBeta?.theme?.formContentColor || '#000000',
  formHeaderBackgroundColor: fullConfigBeta?.theme?.formHeaderBackgroundColor || '#F7F6F3',
  formHeaderContentColor: fullConfigBeta?.theme?.formHeaderContentColor || '#000000',
  formSwitchOffColor: fullConfigBeta?.theme?.formSwitchOffColor || '#7D868C',
  formSwitchOnColor: fullConfigBeta?.theme?.formSwitchOnColor || '#000000',

  modalBodyBackgroundColor: fullConfigBeta?.theme?.modalBodyBackgroundColor || '#FFFFFF',
  modalButtonColor: fullConfigBeta?.theme?.modalButtonColor || '#EAE9E1',
  modalContentColor: fullConfigBeta?.theme?.modalContentColor || '#2A2A27',
  modalHeaderBackgroundColor: fullConfigBeta?.theme?.modalHeaderBackgroundColor || '#000000',
  modalHeaderContentColor: fullConfigBeta?.theme?.modalHeaderContentColor || '#F8F8F1',
  modalSwitchOffColor: fullConfigBeta?.theme?.modalSwitchOffColor || '#7D868C',
  modalSwitchOnColor: fullConfigBeta?.theme?.modalSwitchOnColor || '#000000',
}

export const ensureHydratedThemeColors = (theme?: Theme | PreviewConfigurationThemeDTO): Theme => {
  const result = {
    // spread default theme colors first, then override with theme colors to prevent missing colors
    ...fullyHydratedDefaultThemeColors,
    ...theme,
  } as Theme

  // Handle default color case where no value is passed. Needs to be overriden to opposite of bg color
  if (!theme?.formHeaderContentColor) {
    result.formHeaderContentColor = getContrastColor(result.formHeaderBackgroundColor)
  }

  return result
}
