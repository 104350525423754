import { SendbirdGroupChat } from '@sendbird/chat/groupChannel'
import { SENDBIRD_CHANNEL_OWNER_ID } from './enums'
import { reconnectAs } from './reconnectAs'

/**
 * Creates a new group channel and adds metadata to it.
 *
 * @param sb A sendbird SDK instance
 * @param currentUserId The ID of the current user, used to reconnect after channel creation
 * @param metadata Metadata to add to the channel
 */
export const createGroupChannel = async (
  sb: SendbirdGroupChat,
  currentUserId: string,
  metadata?: Record<string, string>,
) => {
  // Create channel as channel owner ID
  await reconnectAs(sb, SENDBIRD_CHANNEL_OWNER_ID)
  const newChannel = await sb.groupChannel.createChannel({ isPublic: true })

  // Add channel metadata
  if (metadata) {
    await newChannel.createMetaData(metadata)
  }

  // Reconnect as current user
  await reconnectAs(sb, currentUserId)
  const channel = await sb.groupChannel.getChannel(newChannel.url)

  return channel
}
