import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchRightsStatsPeriod } from 'api/rights/fetchers/fetchRightsStatsPeriod'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.rightsStatsPeriod,
  queryFn: fetchRightsStatsPeriod,
  select: res => res?.data?.report || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery, 'organizationCode'>

export const useRightsStatsPeriod = (config: Config) => {
  const { userData } = useAuth()

  return useCustomQuery({
    ...config,
    params: {
      organizationCode: userData.organizationCode!,
      ...config.params!,
    },
  })
}
