import { useState } from 'react'
import { useTrackersWithNewPurposesInfinite } from 'api/trackers/queries/useTrackersWithNewPurposesInfinite'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { useConfirmTrackersWithNewPurposes } from 'api/trackers/mutations/useConfirmTrackersWithNewPurposes'

export const useTrackersWithNewPurposesUtils = () => {
  const [shouldConfirmTrackerPurposes, setShouldConfirmTrackerPurposes] = useState(false)
  const [searchString, setSearchString] = useState('')
  const queryClient = useQueryClient()

  const {
    trackers: trackersWithNewPurposes,
    totalResults: numTrackersWithNewPurposes,
    isLoading: isLoadingTrackersWithNewPurposes,
    isFetching: isFetchingTrackersWithNewPurposes,
    isFetchingNextPage: isFetchingNextTrackersWithNewPurposes,
    hasNextPage: hasNextTrackersWithNewPurposes,
    fetchNextPage: fetchNextTrackersWithNewPurposes,
  } = useTrackersWithNewPurposesInfinite({
    limit: 20,
    searchString,
  })

  const { isLoading: isLoadingPurposeList } = usePurposes({})

  const { mutateAsync: confirmTrackersWithNewPurposes, isLoading: isConfirmingTrackersWithNewPurposes } =
    useConfirmTrackersWithNewPurposes({
      onSuccess: async () => {
        setSearchString('')
        await queryClient.invalidateQueries([ApiQueryKeys.trackersWithNewPurposesInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.trackerStatistics])
        setShouldConfirmTrackerPurposes(false)
        showToast({ content: 'Updated tracker purposes', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Unable to update tracker purposes', type: 'error' })
      },
    })

  const isReady = !isLoadingTrackersWithNewPurposes && !isLoadingPurposeList

  return {
    isReady,
    trackersWithNewPurposes,
    numTrackersWithNewPurposes,
    isLoadingTrackersWithNewPurposes,
    isFetchingTrackersWithNewPurposes,
    isFetchingNextTrackersWithNewPurposes,
    hasNextTrackersWithNewPurposes,
    fetchNextTrackersWithNewPurposes,
    confirmTrackersWithNewPurposes,
    isConfirmingTrackersWithNewPurposes,
    searchString,
    setSearchString,
    shouldConfirmTrackerPurposes,
    setShouldConfirmTrackerPurposes,
  }
}
