import React, { useContext } from 'react'

import { EXPERIENCE_TYPE_OPTIONS } from 'interfaces/experiences/experienceType'
import { PREVIEW_DEVICE_MODE_OPTIONS } from 'interfaces/preview/previewDeviceMode'
import { Group } from 'components/ui-layouts/group/Group'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { Dropdown } from 'components/ui-kit/dropdown/Dropdown'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { Box } from '@mui/material'
import { DeploymentPlanPreviewContext } from '../context/DeploymentPlanPreviewContext'

const PREFIX = 'DeploymentPlanPreview'

const classes = {
  policyScope: `${PREFIX}-policyScope`,
}

type Props = {}

export const DeploymentPlanPreviewToolbar: React.FC<Props> = () => {
  const {
    activeExperienceType,
    activePolicyScopeCode,
    hasBothTypes,
    mode,
    onExperienceTypeChange,
    policyScopesItems,
    setMode,
  } = useContext(DeploymentPlanPreviewContext)

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
      <Box display="flex" flexDirection="column">
        <Label size="small">Jurisdiction</Label>
        <Dropdown
          className={classes.policyScope}
          size="small"
          value={activePolicyScopeCode}
          items={policyScopesItems}
          disabled={policyScopesItems.length < 2}
        >
          {policyScopesItems.find(item => item.code === activePolicyScopeCode)?.name}
        </Dropdown>
      </Box>

      <Group gap={12}>
        {hasBothTypes && (
          <SegmentTabs tabs={EXPERIENCE_TYPE_OPTIONS} value={activeExperienceType} onChange={onExperienceTypeChange} />
        )}

        <SegmentTabs tabs={PREVIEW_DEVICE_MODE_OPTIONS} value={mode} onChange={setMode} />
      </Group>
    </Box>
  )
}
