import React from 'react'

import { makeStyles, createStyles } from '@mui/styles'

import { useNavigate } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { Button } from '@ketch-com/deck'

import { OrganizationDTO } from 'interfaces/organizations/organization'
import { MaybeNull } from 'interfaces'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { Box, Typography } from '@mui/material'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      layout: {
        position: 'relative',
        backgroundColor: palette.white.main,
        borderRadius: 11,
      },
      container: {
        background: palette.white.main,
        borderRadius: 11,
        width: '100%',
      },
      organizationTitle: {
        color: palette.darkDuskFaded.main,
        letterSpacing: '0.13em',
        marginBottom: 8,
        textTransform: 'uppercase',
      },
      orgCardHeaderRow: {
        borderBottom: `1px solid ${palette.iron.main}`,
        paddingBottom: spacing(2),
        paddingLeft: spacing(2),
      },
      img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
      },
      imageGridItem: {
        borderRight: `1px solid ${palette.iron.main}`,
      },
      metadataRowOne: {
        borderBottom: `1px solid ${palette.iron.main}`,
      },
      colorSwatch: {
        width: 20,
        height: 20,
        borderRadius: 10,
      },
    }),
  { name: 'Organization' },
)

type Props = {
  isReady: boolean
  organization: MaybeNull<OrganizationDTO>
}

export const OrganizationChangeSubscriptionView: React.FC<Props> = ({ isReady, organization }) => {
  const classes = useStyles()
  const { queries } = useQueryParams()
  const navigate = useNavigate()

  return (
    <Box className={classes.layout}>
      <Box pt={2} px={2} mb={1} className={classes.container}>
        <Box display="flex" className={classes.orgCardHeaderRow} justifyContent="space-between" alignItems="center">
          <Box display="flex" flexDirection="column">
            <Typography variant="smallLabel" className={classes.organizationTitle}>
              ORGANIZATION
            </Typography>
            <Typography variant="h3">{organization?.name}</Typography>
          </Box>
        </Box>

        <Box display="flex" pt={2} px={2}>
          <Box display="flex" flexDirection="column" justifyContent="center" className={classes.container}>
            <Box mb={3}>
              <Typography variant="h2">
                {queries?.status === 'success' && <>Great Success!</>}

                {queries?.status === 'canceled' && <>Canceled</>}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body">
                {queries?.status === 'success' && (
                  <>
                    You have successfully updated your subscriptions. New entitlements may take a few time to register
                    in the system. Click the button below to view your current subscription details. Keep in mind it may
                    take a few minutes before changes are reflected. You can keep refreshing the page or visit later.
                  </>
                )}

                {queries?.status === 'canceled' && (
                  <>
                    Looks like you canceled or did not complete your purchase. No changes have been made to your
                    subscription plan. Click the button below to view your current subscription details.
                  </>
                )}
              </Typography>
            </Box>

            <Box pb={2}>
              <Button
                onClick={e => {
                  e.preventDefault()
                  navigate(RoutesManager.settings.organization.root.getURL())
                }}
                size="large"
              >
                View Current Subscription Details
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
