import { CreateDSRPoliciesRequestBodyDTO, CreateDSRPoliciesResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  formData: CreateDSRPoliciesRequestBodyDTO
}

type Response = CreateDSRPoliciesResponseBodyDTO

export const createDsrPolicy = ({ formData }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/dsr-policies`,
    }),
    formData,
  )
