import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useFormikContext } from 'formik'
import { VENDOR_CHOICE_OPTIONS } from 'interfaces/privacyProtocols'
import { SearchChipList } from '../../components/SearchChipList'
import { PrivacyProtocolUpsertContext } from '../context/PrivacyProtocolUpsertContext'
import { PrivacyProtocolConfigurationPutRequestBody } from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'
import { FormRow } from '@ketch-com/deck'
import { FormRadioGroup } from 'components/form/FormRadioGroup'

/**
 * Form section for selecting from a list of vendors, with the optional
 * choice of selecting all
 */
export const VendorsFormSection: React.FC = () => {
  const {
    globalVendorList: { vendors: allVendors },
  } = useContext(PrivacyProtocolUpsertContext)

  const {
    setFieldValue,
    errors,
    values: { selectAllVendors, vendors },
  } = useFormikContext<PrivacyProtocolConfigurationPutRequestBody>()

  return (
    <FormRow
      title="Vendors"
      subTitle="IAB Framework Vendors are third-party advertisers partnered with the publisher. They display external content on the website and use marketing cookies to show relevant ads to users."
    >
      <Box>
        <FormRadioGroup
          hideOptionalLabel
          formPropertyName="selectAllVendors"
          row
          valueKey="id"
          renderLabel={({ id, name, description }) => (
            <Box>
              {/* Make selected option bold */}
              <Typography
                variant={(selectAllVendors && id === true) || (!selectAllVendors && id === false) ? 'label' : 'body'}
              >
                {name}
              </Typography>
              <Typography display="block" variant="footnote" color="darkDuskFaded.main">
                {description}
              </Typography>
            </Box>
          )}
          options={VENDOR_CHOICE_OPTIONS}
          onChange={(event, value) => {
            setFieldValue('selectAllVendors', value === '1' || value === 'true', false)
          }}
        />
      </Box>

      {!selectAllVendors && (
        <SearchChipList
          options={
            allVendors?.length
              ? allVendors.map(v => ({
                  id: v.id || '',
                  label: v.name || '',
                }))
              : []
          }
          selectedOptions={vendors}
          inputPrompt="Start typing a vendor name"
          onSelect={selectedId => setFieldValue('vendors', vendors ? [...vendors, selectedId] : [selectedId], false)}
          onDeselect={deselectedId =>
            setFieldValue('vendors', vendors ? vendors.filter(vendorId => vendorId !== deselectedId) : [], false)
          }
          showError={Boolean(errors.vendors)}
          errorMsg={'Must include at least one vendor'}
        />
      )}
    </FormRow>
  )
}
