import React from 'react'
import Box from '@mui/material/Box'
import { Media, Message as TwilioMessage } from '@twilio/conversations'
import { saveAs } from 'file-saver'

import { formatBytes } from 'utils/formatters/formatBytes'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { getBlobFile } from '../utils'
import { fileTypesHumanReadableMap } from '../constants'
import { FileTypesHumanReadableMapKeys } from 'utils/constants/fileTypesHumanReadableMap'
import { Chip, Icon } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  attachment: Media
  message: TwilioMessage
}

type DownloadableMedia = {
  blob?: Blob
  filename?: string
}

export const MessageAttachment: React.FC<Props> = ({ attachment, message }) => {
  const [downloadableMedia, setDownloadableMedia] = React.useState<DownloadableMedia>()

  React.useEffect(() => {
    const getMediaDownloadLink = async () => {
      if (!attachment) return
      try {
        const fileBlob = await getBlobFile(attachment)
        setDownloadableMedia({
          filename: attachment.filename,
          blob: fileBlob,
        } as DownloadableMedia)
      } catch (error) {
        console.info('\n', `failed to get media download link, error: `, error, '\n')
      }
    }

    getMediaDownloadLink()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box display="flex" flexDirection="column" mb={1}>
      <Box display="flex" alignItems="center">
        <Chip
          label={attachment?.filename}
          clickable
          icon={
            fileTypesHumanReadableMap?.[attachment?.contentType as FileTypesHumanReadableMapKeys] ===
            fileTypesHumanReadableMap['application/pdf'] ? (
              <Icon name="OPdf" />
            ) : (
              <Typography
                variant="smallFootnote"
                sx={{
                  border: theme => `1px solid ${theme.palette.royalBlue.main}`,
                  color: theme => `${theme.palette.royalBlue.main} !important`,
                  textTransform: 'uppercase',
                  borderRadius: '6px',
                  fontWeight: 600,
                  p: '5px 3px',
                }}
              >
                {fileTypesHumanReadableMap?.[attachment?.contentType as FileTypesHumanReadableMapKeys] || 'file'}
              </Typography>
            )
          }
          onDelete={() => {
            if (!!downloadableMedia?.blob && !!downloadableMedia?.filename) {
              saveAs(downloadableMedia.blob, downloadableMedia.filename)
            }
          }}
          deleteIcon={<Icon name="ODownload" />}
        />
      </Box>

      {/* Metadata */}
      <Box display="flex" alignItems="center" gap={0.5}>
        <Typography
          variant="footnote"
          sx={{
            borderRight: '1px solid',
            borderColor: 'grey.main',
            pr: 0.5,
          }}
        >
          {formatBytes(attachment?.size)}
        </Typography>
        <Typography variant="footnote">
          Uploaded <FormattedDateRenderer date={(message?.dateCreated?.getTime() || 0) / 1000} />
        </Typography>
      </Box>
    </Box>
  )
}
