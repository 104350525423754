import React from 'react'
import { useSetState } from 'react-use'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces'
import { PolicyScopeTemplateDTO } from 'interfaces/policyScopes/policyScope'
import { RegulationDTO } from 'interfaces/regulations/regulation'
import { RoutesManager } from 'utils/routing/routesManager'
import { matchSearch } from 'utils/helpers/matchSearch'
import { sortByName } from 'utils/helpers/sort/sortByName'
import { UpsertLayout, ContentGroup, DataGrid, TableCell, Radio, FormRow } from '@ketch-com/deck'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { PolicyScopeRegulations } from 'pages/policyCenter/policyScopes/components/PolicyScopeRegulations'
import Highlighter from 'react-highlight-words'
import { PolicyScopeLibraryFilters } from 'pages/policyCenter/policyScopes/library/PolicyScopeLibraryFilters'
import { NavigationBreadCrumbs } from '../../../../components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { useFormik } from 'formik'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

type Props = {
  isFetching: boolean
  isLoading: boolean
  templates: PolicyScopeTemplateDTO[]
  regulations: RegulationDTO[]
  onSubmit: (values: { templateCode: MaybeNull<string> }) => Promise<void>
}

type State = {
  queryString: MaybeNull<string>
}

export const PolicyScopeLibraryView: React.FC<Props> = ({
  isFetching,
  isLoading,
  templates,
  regulations,
  onSubmit,
}) => {
  const navigate = useNavigate()
  const [{ queryString }, setState] = useSetState<State>({
    queryString: null,
  })

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Jurisdictions', link: RoutesManager.policyCenter.policyScopes.root.getURL() },
    { title: 'Create Jurisdiction' },
  ]

  const templatesFilteredAndSorted =
    templates
      ?.filter(item => matchSearch(item?.name, queryString || ''))
      ?.sort((a, b) => sortByName(a?.name, b?.name)) || []

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: { templateCode: null },
    onSubmit,
  })

  const { submitForm, isSubmitting, validateForm, setFieldTouched, values, setFieldValue } = formikProps

  const onAccept = async () => {
    const errors = await validateForm()
    if (Object.keys(errors).length) {
      Object.keys(errors).forEach(errorKey => {
        setFieldTouched(errorKey, true)
      })
      return
    }
    submitForm()
  }

  const gridColumns = [
    {
      field: 'used',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: ({ row: { code } }: GridRenderCellParams<PolicyScopeTemplateDTO>) => {
        const isChecked = values.templateCode === code
        return (
          <TableCell>
            <Radio
              title=""
              checked={isChecked}
              value={isChecked}
              onChange={() => {
                if (isChecked) {
                  setFieldValue(`templateCode`, null)
                } else {
                  setFieldValue(`templateCode`, code)
                }
              }}
            />
          </TableCell>
        )
      },
    },

    {
      field: 'Name',
      headerName: 'Name',
      flex: 1,
      sortable: false,
      renderCell: ({ row: { code, name } }: GridRenderCellParams<PolicyScopeTemplateDTO>) => (
        <TableCell>
          <NameAndCodeCellRenderer
            name={<Highlighter textToHighlight={name} searchWords={queryString?.split(' ') || []} />}
            code={code}
          />
        </TableCell>
      ),
    },

    {
      field: 'Regulations',
      headerName: 'Regulations',
      flex: 1,
      sortable: false,
      renderCell: ({ row: { regulationCodes } }: GridRenderCellParams<PolicyScopeTemplateDTO>) => (
        <TableCell>
          <PolicyScopeRegulations regulationCodes={regulationCodes} regulations={regulations} />
        </TableCell>
      ),
    },
    {
      field: 'Description',
      headerName: 'Description',
      flex: 1,
      sortable: false,
      renderCell: ({ row: { description } }: GridRenderCellParams<PolicyScopeTemplateDTO>) => (
        <TableCell>
          <Typography>{description || 'None'}</Typography>
        </TableCell>
      ),
    },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <UpsertLayout
        showStepper={false}
        renderFormTitle={() => <ContentGroup title="Create Jurisdiction" titleVariant="h2" />}
        onAccept={onAccept}
        onCancel={() => {
          navigate(RoutesManager.policyCenter.policyScopes.root.getURL())
        }}
        acceptButtonProps={{
          pending: isSubmitting,
        }}
        cancelButtonProps={{
          pending: isSubmitting,
        }}
        acceptActionButtonText={values.templateCode ? 'Next' : 'Skip'}
      >
        <Box mt="22px">
          <FormRow
            title="Jurisdiction Templates"
            subTitle="Speed up the creation process by using a jurisdiction template."
          >
            <PolicyScopeLibraryFilters
              filters={{ queryString }}
              onChange={nextFilters => setState(prevState => ({ ...prevState, ...nextFilters }))}
            />
            <form autoComplete="off">
              <DataGrid
                loading={isLoading || isFetching}
                rowCount={templatesFilteredAndSorted.length}
                rows={templatesFilteredAndSorted}
                columns={gridColumns}
                autosizeOnMount
                disableColumnMenu
                disableColumnPinning
                disableColumnReorder
                disableChildrenSorting
                disableColumnResize
                hideFooter
                disableRowSelectionOnClick
                getRowId={({ code }) => code}
                sx={{
                  padding: 0,
                  height:
                    isLoading || (!templatesFilteredAndSorted.length && !isLoading && !isFetching) ? '500px' : 'auto',
                  '& .MuiDataGrid-columnHeader[role="columnheader"]:hover': {
                    backgroundImage: 'unset',
                  },
                }}
                getRowHeight={() => 'auto'}
                noRowsOverlayProps={{
                  iconName: 'FPointer',
                  title: 'There are no jurisdiction templates for this organization',
                  subTitle: '',
                  buttonTitle: '',
                }}
              />
            </form>
          </FormRow>
        </Box>
      </UpsertLayout>
    </>
  )
}
