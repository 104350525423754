import { AuthenticatorDTO } from '@ketch-com/figurehead'

export type VariableClaimMappingFormValue = {
  destination?: number
  destinationKey?: string
  sourceKey?: string
}

export type CreateAuthenticatorFormValues = {
  name?: string
  type?: string
  accessTokenUrl?: string
  authUrl?: string
  clientId?: string
  clientSecret?: string
  redirectUrl?: string
  scopes?: string
  variableClaimMappings?: VariableClaimMappingFormValue[]
}

type Args = {
  authenticator?: AuthenticatorDTO
}

export const getAuthenticatorInitialValues = ({ authenticator }: Args): CreateAuthenticatorFormValues => {
  const isCreateMode = Object.keys(authenticator || {}).length === 0
  if (!isCreateMode) {
    return {
      name: authenticator?.name || '',
      type: String(authenticator?.type) || '',
      authUrl: authenticator?.authUrl || '',
      accessTokenUrl: authenticator?.accessTokenUrl || '',
      clientId: authenticator?.clientId || '',
      clientSecret: authenticator?.clientSecret || '',
      redirectUrl: authenticator?.redirectUrl || '',
      scopes: authenticator?.scopes || '',
      variableClaimMappings: (authenticator?.variableClaimMappings || [])?.map(v => ({
        destination: v.destination ? v.destination : undefined,
        destinationKey: v.destinationKey,
        sourceKey: v.sourceKey,
      })),
    }
  }

  return {
    name: '',
    type: '1',
    authUrl: '',
    accessTokenUrl: '',
    clientId: '',
    clientSecret: '',
    redirectUrl: '',
    scopes: '',
    variableClaimMappings: [
      {
        destination: undefined,
        destinationKey: '',
        sourceKey: '',
      },
    ],
  }
}
