import { ApplianceDTO } from 'interfaces/appliances'
import {
  ApplianceConnectionStatusName,
  applianceConnectionStatusNames,
} from 'interfaces/appliances/ApplianceConnectionStatus'

type ApplianceFilterFunctionArgs = {
  appliances: ApplianceDTO[]
  search: string
  status: ApplianceConnectionStatusName
}

type SearchFilterArgs = {
  appliance: ApplianceDTO
  search: string
}

type ConnectionStatusFilterArgs = {
  appliance: ApplianceDTO
  status: ApplianceConnectionStatusName
}

const searchFilter = ({ appliance, search }: SearchFilterArgs) => {
  let name = appliance?.name || appliance?.applianceID
  return name.toLowerCase().includes(search.toLowerCase())
}

const connectionStatusFilter = ({ appliance, status }: ConnectionStatusFilterArgs) => {
  if (status === 'Status') return true
  const applianceConnectionStatus = appliance?.connectionStatus || 0
  return applianceConnectionStatusNames[applianceConnectionStatus] === status
}

export const getFilteredAppliances = ({ appliances, search, status }: ApplianceFilterFunctionArgs): ApplianceDTO[] =>
  appliances.filter(el => searchFilter({ appliance: el, search }) && connectionStatusFilter({ appliance: el, status }))
