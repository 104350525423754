import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { getInitialValues, getValidationSchema } from 'pages/assetManager/datasets/upsert/utils'
import { Group } from 'components/ui-layouts/group/Group'
import { Button } from '@ketch-com/deck'
import { UpsertLayout } from 'components/ui-layouts/upsertLayout/UpsertLayout'
import { UpsertLayoutFooter } from 'components/ui-layouts/upsertLayout/UpsertLayoutFooter'
import { MaybeNull } from 'interfaces/common'
import { EditDatasetFormValues } from 'interfaces/assets'
import { LabelDefinitionDTO as LabelDTO } from '@ketch-com/figurehead'
import { EditAssetForm } from 'pages/assetManager/components'
import { RoutesManager } from 'utils/routing/routesManager'
import { AssetSummaryDTO } from '@ketch-com/figurehead'

type Props = {
  isReady: boolean
  isUpdatingDataset: boolean
  onSubmit: (values: EditDatasetFormValues) => Promise<void>
  dataset: MaybeNull<AssetSummaryDTO>
  labels: MaybeNull<LabelDTO[]>
}

export const DatasetUpsertView: React.FC<Props> = ({ isReady, onSubmit, dataset, isUpdatingDataset = false }) => {
  const navigate = useNavigate()
  const initialValues = useMemo(() => getInitialValues({ dataset }), [dataset])
  const validationSchema = useMemo(getValidationSchema, [])

  return (
    <UpsertLayout
      isReady={isReady}
      title="Edit Dataset"
      breadcrumbs={[
        { title: 'Data Map', link: RoutesManager.assetManager.assets.root.getURL() },
        { title: 'Assets', link: RoutesManager.assetManager.assets.list.getURL() },
        {
          title: dataset?.asset?.resource?.name || dataset?.asset?.resource?.id,
          link: RoutesManager.assetManager.assets.detail.root.getURL({
            code: dataset?.asset?.resource?.id || '',
          }),
        },
        { title: 'Edit Dataset' },
      ]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm, isValid }) => {
        return (
          <>
            <EditAssetForm assetSummary={dataset} />

            <UpsertLayoutFooter>
              <Group>
                <Button
                  pending={isSubmitting}
                  color="secondary"
                  size="large"
                  onClick={() => {
                    navigate(
                      RoutesManager.assetManager.assets.detail.root.getURL({
                        code: dataset?.asset?.resource?.id || '',
                        resourceTypeCode: dataset?.asset?.resource?.resourceType?.code || '',
                      }),
                    )
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!isValid}
                  size="large"
                  onClick={submitForm}
                  pending={isSubmitting || isUpdatingDataset}
                >
                  Save
                </Button>
              </Group>
            </UpsertLayoutFooter>
          </>
        )
      }}
    </UpsertLayout>
  )
}
