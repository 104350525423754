import React, { useMemo } from 'react'
import { Button, PopUp } from '@ketch-com/deck'
import { FormikProvider, useFormik } from 'formik'
import { getUserChangePasswordFormValues } from './utils/formValues'
import { Typography, Box } from '@mui/material'
import { ReactComponent as ChangePasswordIcon } from 'assets/icons/art_dsr_outcome_password.svg'
import { showToast } from '../../../../../components/modals/AlertComponent'
import { useUpdateUserPassword } from '../../../../../api/users/mutations/useUpdateUserPassword'

export type Props = {
  onClose: () => void
}

export const ChangePasswordModal: React.FC<Props> = ({ onClose }) => {
  const { mutateAsync: handleUpdateUserPassword } = useUpdateUserPassword({
    onSuccess: () => {
      showToast({ content: 'Password updated', type: 'success' })
      onClose()
    },
    onError: () => {
      showToast({ content: 'Failed to update password', type: 'error' })
    },
  })

  const onSubmit = async () => {
    await handleUpdateUserPassword()
  }
  const initialValues = useMemo(() => getUserChangePasswordFormValues(), [])

  const formikProps = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  })

  const { isSubmitting, submitForm } = formikProps

  return (
    <PopUp
      title="Update Password"
      variant="modal"
      popUpWidth="560px"
      onClose={onClose}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" size="large" pending={isSubmitting} onClick={submitForm}>
            Send Update Link
          </Button>
        </>
      }
    >
      <FormikProvider value={formikProps}>
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" mb={2}>
          <ChangePasswordIcon />
        </Box>
        <Typography variant="body">
          If you wish to update your password we’ll send you an update link to the email associated with your account.
        </Typography>
        <Typography variant="body">Click the 'Send Update Link' button below to proceed.</Typography>
      </FormikProvider>
    </PopUp>
  )
}
