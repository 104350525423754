import React from 'react'

import { Text, TextColorOptions, FontWeightOptions } from 'components/ui-kit/typography/Text'
import { ExecutionResultHookDTO } from '@ketch-com/figurehead'

const getStatusText = (hookStatus: number) => {
  switch (hookStatus) {
    case 0:
      return ''
    case 1:
      return 'Success'
    case 2:
      return 'Failure'
    case 3:
      return 'Skipped'
    case 4:
      return 'Skipped DML'
    default:
      return ''
  }
}

const getStatusTextColor = (hookStatus: number): TextColorOptions => {
  switch (hookStatus) {
    case 0:
      return 'black'
    case 1:
      return 'black'
    case 2:
      return 'chileanFire'
    case 3:
      return 'chileanFire'
    case 4:
      return 'chileanFire'
    default:
      return 'black'
  }
}

const getFontWeight = (hookStatus: number): FontWeightOptions => {
  switch (hookStatus) {
    case 0:
      return 'normal'
    case 1:
      return 'normal'
    case 2:
      return 600
    case 3:
      return 600
    case 4:
      return 600
    default:
      return 'normal'
  }
}

type Props = {
  executionResultHook?: ExecutionResultHookDTO
}

export const HookExecutionStatusCellRenderer: React.FC<Props> = ({ executionResultHook }) => {
  const fontWeight = getFontWeight(executionResultHook?.status || 0)
  const textColor = getStatusTextColor(executionResultHook?.status || 0)
  const text = getStatusText(executionResultHook?.status || 0)

  return (
    <Text weight={fontWeight} color={textColor}>
      {text}
    </Text>
  )
}
