export type RightStatsDTO = {
  avgFulfilTime?: number
  date?: string
  dueFourteenDays?: number
  dueOneDay?: number
  fulfilledOnDate?: number
  fulfilledSevenDays?: number
  invokedOnDate?: number
  invokedSevenDays?: number
  medialFulfillTime?: number
  onTime?: number
  onTrack?: number
  overdue?: number
  pending?: number
  totalInvocations?: number
}

/**
 * The BE API was unfortunately designed. It should have been a query param, but "daily"/"weekly"/"monthly" is part of the path,
 * and 3 separate endpoints are exposed.  This enum is used to map the query param to the path.
 */
export enum ChartTrendPeriodApiQueryParam {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum ChartTrendPeriod {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY_PAST_TWELVE = 'monthly_past_twelve',
  MONTHLY_PAST_CAL_YEAR = 'monthly_past_cal_year',
}

export type RightStatsPeriodDataPointDTO = {
  avgFulfillTime?: number
  date?: string
  fulfilledOnDate?: number
  fulfilledSevenDays?: number
  invokedOnDate?: number
  invokedSevenDays?: number
  medialFulfillTime?: number
  onTrack?: number
  pending?: number
  TS?: number
}

export type RightStatsPeriodDTO = {
  avgDueSoon?: number
  avgFulfillTime?: number
  avgOnTrack?: number
  avgPending?: number
  avgUrgent?: number
  dataPoints?: RightStatsPeriodDataPointDTO[]
  medialFulfillTime?: number
  totalFulfilled?: number
  totalInvoked?: number
}
