import React from 'react'
import { Box } from '@mui/material'

import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'
import { AssetListItem, AssetListTable } from './components'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { Text } from 'components/ui-kit/typography/Text'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { RoutesManager } from 'utils/routing/routesManager'
import { ReactComponent as DatabaseIcon } from 'assets/icons/database_bold.svg'
import { MegaFilter } from './components'
import { LIST_DISPLAY_MODES } from './components/MegaFilter/constants'
import { LIST_DISPLAY_MODE_OPTIONS } from './components/MegaFilter/constants'
import { getAssetsListContainerMaxHeight } from './utils'
import { useAssetsListViewStyles, useAssetsListViewUtils } from './hooks'
import { SegmentTabsWithIcons } from 'components/ui-kit/segmentTabs/SegmentTabsWithIcons'
import { useDispatch } from 'react-redux'
import { setDisplayMode } from 'store/megaFilterStateSlice'
import { ConnectionDTO } from '@ketch-com/figurehead'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { InfiniteScrollLoadingIndicator } from 'components/ui-layouts/table/components/infiniteScrollLoadingIndicator'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { UseAssetsInfiniteV3Return } from 'api/assets/queries/useAssetsInfiniteV3'

const emptyStateDescription =
  'To enable assets syncing you need to approve one of the deployed Transponders. Check the Developers section and locate a Transponder that corresponds to your data storage.'

type Props = {
  isLoading: boolean
  assets: AssetSummaryDTO[]
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
  totalResults: number
  totalAssetCount: number
  connections: ConnectionDTO[]
  fetchNextPage: UseAssetsInfiniteV3Return['fetchNextPage']
}

export const AssetsListView: React.FC<Props> = ({
  isLoading,
  assets,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  totalResults = 0,
  totalAssetCount = 0,
  connections,
}) => {
  const classes = useAssetsListViewStyles()
  const dispatch = useDispatch()

  const {
    navigate,
    searchString,
    setSearchString,
    displayMode,
    filterState,
    selectedAssets,
    handleAssetOnClick,
    handleOnClear,
    handleSelectAll,
  } = useAssetsListViewUtils({ assets })

  const breadcrumbs = [
    {
      title: 'Data Map',
      link: RoutesManager.assetManager.assets.root.getURL(),
    },
    { title: 'Assets', link: RoutesManager.assetManager.assets.list.getURL() },
  ]

  return (
    <ContentBoundaries>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs} />
      <Box className={classes.headerContainer}>
        {/* Justified Left */}

        <Text size={34} weight={800}>
          Asset Manager
        </Text>

        {/* Justified Right */}

        <Box display="flex" alignItems="center">
          {/* TODO:BAC - hide until api is delivered */}
          {/* <ReviewLabelsChip /> */}

          <SegmentTabsWithIcons
            className={classes.segmentTabs}
            tabs={LIST_DISPLAY_MODE_OPTIONS}
            value={displayMode}
            onChange={v => {
              dispatch(setDisplayMode(v))
            }}
          />

          <SearchInput
            size="small"
            startSearchFromChar={0}
            fullWidth
            debounceTimeout={0}
            value={searchString!}
            onChange={() => {}}
            onSearch={nextValue => {
              setSearchString(nextValue)
            }}
            onClear={() => setSearchString('')}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="space-between" maxWidth="100%">
        {/* Left Column: Filter */}

        <Box display="flex" flexDirection="column" flex={1}>
          <MegaFilter
            selectedCount={selectedAssets.length}
            onClear={handleOnClear}
            onSelectAll={handleSelectAll}
            totalResults={totalResults}
            totalAssetCount={totalAssetCount}
            connections={connections}
          />
        </Box>

        {/* Right Column: Asset List Items */}

        <Box
          id="assetListContainer"
          maxHeight={isLoading ? 500 : getAssetsListContainerMaxHeight()}
          sx={{
            display: 'flex',
            flex: 6,
            flexDirection: 'column',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {isLoading && (
            <Box display="flex" alignItems="center" justifyContent="center" py={3}>
              <Box ml={4}>
                <Spinner />
              </Box>
            </Box>
          )}

          {displayMode === LIST_DISPLAY_MODES.TILES &&
            assets.map((assetSummary, index) => (
              <AssetListItem
                assetSummary={assetSummary}
                index={index}
                key={assetSummary?.asset?.resource?.id || ''}
                isLastCard={assets?.length - 1 === index}
                onAssetClick={handleAssetOnClick}
                isSelected={selectedAssets.includes(assetSummary?.asset?.resource?.id || '')}
                connections={connections}
                isFifthToLastItem={index === (assets?.length || 0) - 5}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
              />
            ))}

          <AssetListTable
            displayMode={displayMode}
            assets={assets}
            filterState={filterState}
            onAssetClick={handleAssetOnClick}
            selectedAssets={selectedAssets}
            isLoading={isLoading}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />

          <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />

          {/* Empty state */}

          {!isLoading && (assets || [])?.length === 0 && (
            <EmptyState
              icon={<DatabaseIcon />}
              variant="list"
              bgVariant="white"
              title="Query did not return any results or there are no assets synced"
              description={emptyStateDescription}
              actions={[
                {
                  title: 'Check Transponders',
                  variant: 'secondary',
                  size: 'big',
                  onClick: () => {
                    navigate(RoutesManager.developers.transponders.list.getURL())
                  },
                },
              ]}
            />
          )}
        </Box>
      </Box>
    </ContentBoundaries>
  )
}
