import { FormikHelpers } from 'formik'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormRadioGroup } from 'components/ui-kit/form/radioGroup/FormRadioGroup'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { isTwilioConversationsEnabled } from 'utils/helpers/isTwilioConversationsEnabled'
import { FormAssigneeOrTeamSelector } from 'components/form/FormAssigneeOrTeamSelector'

// Define the enum
export enum OptionEnum {
  Optional = 'OPTIONAL',
  Required = 'REQUIRED',
}

// Convert it to an array
const optionsArray = Object.entries(OptionEnum).map(([name, id]) => ({ name, id }))

interface ManualFormSectionProps {
  setFieldValue: FormikHelpers<any>['setFieldValue']
}

export const ManualFormSection: React.FC<ManualFormSectionProps> = ({ setFieldValue }) => {
  const subjectCommunicationEnabled = isTwilioConversationsEnabled()

  return (
    <Box mt={2}>
      <FormAssigneeOrTeamSelector label="Assignee" name="assignee" />

      <FormInput
        sx={{
          mb: 3,
        }}
        type="number"
        name="timeToCompleteDays"
        label="Time To Complete (Days)"
        placeholder="0"
        width={80}
      />

      <Typography variant="h4" color="fadedDarkGrey.main">
        Subject Communication
      </Typography>

      {subjectCommunicationEnabled ? (
        <>
          <Box mb={1} mt={2}>
            <Typography variant="label" color="darkDusk.main">
              Message via Subject Communication
            </Typography>
          </Box>
          <Box mb={2}>
            <FormRadioGroup
              name="manualMessage"
              renderLabel={({ name }) => name}
              options={optionsArray}
              valueKey="id"
            />
          </Box>
        </>
      ) : null}

      <Box mb={1} mt={subjectCommunicationEnabled ? undefined : 2}>
        <Typography variant="label" color="darkDusk.main">
          Cover Letter attachment
        </Typography>
      </Box>
      <Box mb={2}>
        <FormRadioGroup
          name="manualCoverLetter"
          renderLabel={({ name }) => name}
          options={optionsArray}
          valueKey="id"
        />
      </Box>
    </Box>
  )
}
