import React from 'react'

import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Button } from '@ketch-com/deck'
import { DeleteFormFieldModalV3 } from '../../../components'
import { FormFieldDTO } from '@ketch-com/figurehead'
import { useLocation, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { setNavigationSliceGoBack } from 'store/navigationSlice'
import { useDispatch } from 'react-redux'
import { FormMode } from 'interfaces/formModes/formMode'
import { useIsExperienceServerAdmin } from 'pages/consentAndRights/forms/hooks'
import { Box, Typography } from '@mui/material'

type Props = {
  formField?: FormFieldDTO
}

export const FormFieldViewActions: React.FC<Props> = ({ formField }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { isUserExperienceServerAdmin } = useIsExperienceServerAdmin()
  const [isDeleteFormFieldModalOpen, setIsDeleteFormFieldModalOpen] = React.useState(false)
  const dispatch = useDispatch()

  return (
    <>
      <Box display="flex" gap={1.5}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="smallBody" color="darkDuskFaded.main">
            Created
          </Typography>

          <Typography color="darkDusk.main" variant="smallBody">
            <FormattedDateRenderer date={formField?.metadata?.createdAt} />
          </Typography>
        </Box>

        {isUserExperienceServerAdmin && (
          <Button
            size="large"
            color="tertiary"
            pending={false}
            onClick={() => {
              setIsDeleteFormFieldModalOpen(true)
            }}
          >
            Delete
          </Button>
        )}

        {isUserExperienceServerAdmin && (
          <Button
            onClick={() => {
              dispatch(setNavigationSliceGoBack(location.pathname))
              navigate(
                RoutesManager.deployment.forms.fields.upsert.root.getURL({
                  id: formField?.id,
                  formMode: FormMode.EDIT,
                }),
              )
            }}
            color="secondary"
            size="large"
          >
            Edit
          </Button>
        )}
      </Box>

      {isDeleteFormFieldModalOpen && (
        <DeleteFormFieldModalV3 onCancel={() => setIsDeleteFormFieldModalOpen(false)} formField={formField} />
      )}
    </>
  )
}
