import React, { useState } from 'react'
import clsx from 'clsx'
import { ButtonBase, Popover, CircularProgress } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import { MaybeNull } from 'interfaces/common'
import { List, ListItemType as BaseListItemType } from 'components/ui-kit/list/List'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseButton: {
        fontFamily: typography.fontFamily,
        borderRadius: 5,
        letterSpacing: '0.01em',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      baseContent: {},

      // Popover
      popoverRoot: {
        marginTop: 5,
        borderRadius: 5,
      },
      popover: {
        borderRadius: 5,
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.2)',
        minWidth: 170,
      },

      // Sizes:
      sizeExtraSmall: {
        width: 20,
        height: 16,

        '& $baseContent': {
          fontSize: typography.pxToRem(16),
        },

        '&:hover': {
          background: palette.fadedGrey.main,

          '& $baseContent': {
            color: palette.royalBlue.main,
          },
        },

        '&:active': {
          background: palette.fadedDarkGrey.main,

          '& $baseContent': {
            color: palette.persianBlue.main,
          },
        },
      },
      sizeSmall: {
        width: 38,
        height: 28,

        '& $baseContent': {
          fontSize: typography.pxToRem(16),
        },

        '&:hover': {
          background: palette.fadedGrey.main,

          '& $baseContent': {
            color: palette.royalBlue.main,
          },
        },

        '&:active': {
          background: palette.fadedDarkGrey.main,

          '& $baseContent': {
            color: palette.persianBlue.main,
          },
        },
      },
      sizeRegular: {
        width: 38,
        height: 28,
        background: palette.fadedGrey.main,

        '& $baseContent': {
          fontSize: typography.pxToRem(20),
        },

        '&:hover': {
          background: palette.iron.main,

          '& $baseContent': {
            color: palette.royalBlue.main,
          },
        },

        '&:active': {
          background: palette.fadedDarkGrey.main,

          '& $baseContent': {
            color: palette.persianBlue.main,
          },
        },
      },
      sizeBig: {
        width: 54,
        height: 44,
        background: palette.fadedGrey.main,

        '& $baseContent': {
          fontSize: typography.pxToRem(24),
        },

        '&:hover': {
          background: palette.iron.main,

          '& $baseContent': {
            color: palette.royalBlue.main,
          },
        },

        '&:active': {
          background: palette.fadedDarkGrey.main,

          '& $baseContent': {
            color: palette.persianBlue.main,
          },
        },
      },

      sizeBigNoPadding: {
        width: 24,
        height: 24,
        background: palette.fadedGrey.main,

        '& $baseContent': {
          fontSize: typography.pxToRem(24),
        },

        '&:hover': {
          background: palette.iron.main,

          '& $baseContent': {
            color: palette.royalBlue.main,
          },
        },

        '&:active': {
          background: palette.fadedDarkGrey.main,

          '& $baseContent': {
            color: palette.persianBlue.main,
          },
        },
      },

      // States:
      disabled: {
        '&:not($sizeSmall)': {
          background: palette.fadedGrey.main,
        },

        '& $baseContent': {
          color: palette.lightGrey.main,
        },
      },
      pending: {
        '& $baseContent': {
          color: palette.sphere.main,
        },
      },

      removeBackground: {
        background: 'none',
      },
    }),
  { name: 'ButtonOptions' },
)

export interface Props {
  id?: string
  /** Test ID of an element for selectors */
  testId?: string
  /** Checks if the component should be disabled */
  disabled?: boolean
  /** Checks if the component should be in loading state */
  pending?: boolean
  /** Component size - reflects the size of the button */
  size?: 'extraSmall' | 'small' | 'regular' | 'big' | 'bigNoPadding'
  /** Component custom className */
  className?: any
  /** Component items list to be displayed in popover */
  items: BaseListItemType[]
  /** Unique identifier for Intercom product tours */
  intercomTarget?: string

  onOpen?: () => void
  onClose?: () => void
  removeBackground?: boolean
}

/**
 * -
 */
export const ButtonOptions: React.FC<Props> = ({
  disabled = false,
  pending = false,
  size = 'regular',
  items,
  className,
  onOpen,
  onClose,
  id,
  testId,
  intercomTarget,
  removeBackground = true,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<MaybeNull<any>>(null)
  const visibleItems = items.filter(item => !item.hidden)

  if (!visibleItems.length) {
    return null
  }

  const isOpen = Boolean(anchorEl)
  const popoverId = isOpen ? 'button-options-popover' : undefined
  const listSize = size === 'regular' || size === 'big' || size === 'bigNoPadding' ? 'regular' : 'small'

  const buttonClassName = clsx(
    classes.baseButton,
    {
      [classes.sizeExtraSmall]: size === 'extraSmall',
      [classes.sizeSmall]: size === 'small',
      [classes.sizeRegular]: size === 'regular',
      [classes.sizeBig]: size === 'big',
      [classes.sizeBigNoPadding]: size === 'bigNoPadding',
      [classes.pending]: pending,
      [classes.disabled]: disabled,
      [classes.removeBackground]: removeBackground && !isOpen,
    },
    className,
  )

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    event.preventDefault()

    if (!disabled && !pending) {
      onOpen && onOpen()
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    event.preventDefault()
    onClose && onClose()
    setAnchorEl(null)
  }

  return (
    <>
      <ButtonBase
        id={id}
        data-test-id={testId}
        classes={{
          root: buttonClassName,
          disabled: classes.disabled,
        }}
        disabled={disabled || pending}
        disableRipple
        disableTouchRipple
        data-intercom-target={intercomTarget}
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          handleOpen(event)
        }}
      >
        {!pending ? (
          <MoreHorizIcon className={classes.baseContent} />
        ) : (
          <CircularProgress
            classes={{
              root: classes.baseContent,
            }}
            size={size === 'big' ? 18 : 16}
          />
        )}
      </ButtonBase>

      <Popover
        id={popoverId}
        open={isOpen}
        classes={{
          root: classes.popoverRoot,
          paper: classes.popover,
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List
          size={listSize}
          items={visibleItems.map(item => ({
            ...item,
            onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              if (item.onClick) {
                item.onClick(event)
              }
              handleClose(event)
            },
          }))}
        />
      </Popover>
    </>
  )
}
