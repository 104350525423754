import React from 'react'

import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { useLocation } from 'react-router-dom'
import { pushEntryOntoBackToUrlHistory } from 'store/assetDetailViewSlice'
import { useAppDispatch } from 'store/hooks'
import { useHandleEditOnClick, useHandleViewAssetOnClick } from 'pages/assetManager/assets/list/hooks'

type Props = {
  assetSummary: AssetSummaryDTO
  buttonOptionsClassName?: string
  isDisabled?: boolean
}

export const AssetsListTableActionsCellRenderer: React.FC<Props> = ({
  assetSummary,
  buttonOptionsClassName,
  isDisabled,
}) => {
  const handleEditOnClick = useHandleEditOnClick(assetSummary)
  const handleViewOnClick = useHandleViewAssetOnClick(assetSummary)
  const location = useLocation()
  const dispatch = useAppDispatch()

  return (
    <ButtonOptions
      size="big"
      disabled={isDisabled}
      className={buttonOptionsClassName}
      items={[
        {
          content: 'View',
          onClick: () => {
            dispatch(pushEntryOntoBackToUrlHistory(location.pathname))
            handleViewOnClick()
          },
        },
        {
          content: 'Assign',
          hidden: true, // TODO: un-hide when designed and BE ready
          onClick: () => {},
        },
        {
          content: 'Edit',
          onClick: () => {
            dispatch(pushEntryOntoBackToUrlHistory(location.pathname))
            handleEditOnClick()
          },
        },
      ]}
    />
  )
}
