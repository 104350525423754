import { styled } from '@mui/system'

import CloseIcon from '@mui/icons-material/Close'

export const CloseButton = styled(CloseIcon)(({ theme }) => ({
  backgroundColor: theme.palette.fadedGrey.main,
  borderRadius: 5,
  color: theme.palette.darkDusk.main,
  cursor: 'pointer',
  height: 28,
  marginLeft: 5,
  padding: 2,
  width: 28,
}))
