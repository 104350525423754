import { fullConfigBeta } from '@ketch-com/lanyard'

import { MaybeNull } from 'interfaces/common'
import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { ThemeBannerPosition } from 'interfaces/themes-v2/themeBannerPosition'
import { ThemeModalPosition } from 'interfaces/themes-v2/themeModalPosition'

import { ThemeFormValues } from '.'
import { ButtonVariant } from '@ketch-sdk/ketch-types'

export const getUpsertThemeInitialValues = ({
  theme,
  isEntitledToUnbranded,
}: {
  theme: MaybeNull<ThemeDTO>
  isEntitledToUnbranded: boolean
}): ThemeFormValues => {
  const payload: ThemeFormValues = {
    name: theme?.name || '',
    code: theme?.code || '',

    buttonBorderRadius: `${theme?.buttonBorderRadius || fullConfigBeta?.theme?.buttonBorderRadius}`,

    bannerBackgroundColor: theme?.bannerBackgroundColor || fullConfigBeta?.theme?.bannerBackgroundColor,
    bannerButtonColor: theme?.bannerButtonColor || fullConfigBeta?.theme?.bannerButtonColor,
    bannerContentColor: theme?.bannerContentColor || '',
    bannerPosition: theme?.bannerPosition || ThemeBannerPosition.BOTTOM,
    bannerSecondaryButtonColor: theme?.bannerSecondaryButtonColor || '',
    bannerForceInteraction: !!theme?.bannerForceInteraction,

    customizeBannerContentColor: !theme?.bannerContentColor, // derived form value, not persisted

    customizeFormHeaderContentColor: !theme?.formHeaderContentColor, // derived form value, not persisted
    customizeFormSwitchOffColor: !theme?.formSwitchOffColor, // derived form value, not persisted
    customizeFormSwitchOnColor: !theme?.formSwitchOnColor, // derived form value, not persisted

    customizeModalHeaderContentColor: !theme?.modalHeaderContentColor, // derived form value, not persisted
    customizeModalSwitchOffColor: !theme?.modalSwitchOffColor, // derived form value, not persisted
    customizeModalSwitchOnColor: !theme?.modalSwitchOnColor, // derived form value, not persisted

    formButtonColor: theme?.formButtonColor || fullConfigBeta?.theme?.formButtonColor,
    formContentColor: theme?.formContentColor || fullConfigBeta?.theme?.formContentColor,
    formHeaderBackgroundColor: theme?.formHeaderBackgroundColor || fullConfigBeta?.theme?.formHeaderBackgroundColor,
    formHeaderContentColor: theme?.formHeaderContentColor || '',
    formSwitchOffColor: theme?.formSwitchOffColor || '',
    formSwitchOnColor: theme?.formSwitchOnColor || '',

    modalButtonColor: theme?.modalButtonColor || fullConfigBeta?.theme?.modalButtonColor,
    modalContentColor: theme?.modalContentColor || fullConfigBeta?.theme?.modalContentColor,
    modalHeaderBackgroundColor: theme?.modalHeaderBackgroundColor || fullConfigBeta?.theme?.modalHeaderBackgroundColor,
    modalHeaderContentColor: theme?.modalHeaderContentColor || '',
    modalPosition: theme?.modalPosition || ThemeModalPosition.CENTER,
    modalSwitchOffColor: theme?.modalSwitchOffColor || '',
    modalSwitchOnColor: theme?.modalSwitchOnColor || '',

    /* TODO:BAC - QR foreground/background color inputs are not yet implemented */
    qrBackgroundColor: theme?.qrBackgroundColor || '',
    qrForegroundColor: theme?.qrForegroundColor || '',

    showWatermark: !isEntitledToUnbranded || !theme || !!theme?.showWatermark,

    bannerSecondaryButtonVariant: theme?.bannerSecondaryButtonVariant || ButtonVariant.Outlined,
    purposeButtonsLookIdentical: theme?.purposeButtonsLookIdentical || false,
    purposeFormButtonsLookIdentical: theme?.purposeFormButtonsLookIdentical || false,

    formBodyBackgroundColor: theme?.formBodyBackgroundColor || '#ffffff',
    modalBodyBackgroundColor: theme?.modalBodyBackgroundColor || '#ffffff',
  }

  return payload
}
