import Box from '@mui/material/Box'

import { AssetSummaryDTO, DataMapDataResidencyDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'

const dummyDataResidencies: DataMapDataResidencyDTO[] = [
  {
    location: 'United States',
    retention: '4 years',
    security: ['Encryption', 'Authentication'],
  },
]

type Props = {
  assetSummary?: AssetSummaryDTO
}

export const DataResidencies: React.FC<Props> = ({ assetSummary }) => {
  return (
    <>
      {/* TODO:BAC - Remove dummy data once this is ready for production.  This case will
      never catch because "Data Residencies" is commented out in "infoRowsConfig"
      not sure where this is supposed to come from. Doesn't currently exist on an AssetDTO */}
      {dummyDataResidencies?.map(residency => (
        <Box
          key={residency?.location}
          sx={{
            border: ({ palette }) => `1px solid ${palette.iron.main}`,
            borderRadius: 1.375,
            padding: ({ spacing }) => spacing(2, 2.25, 2.75, 2.25),
            minWidth: 240,
            marginRight: 2,
          }}
        >
          <Text component="div" mb={3.25} weight={600} size={14}>
            {residency.location}
          </Text>
          <Text mb={0.5} component="div" weight={700}>
            Security
          </Text>
          {residency.security?.map(security => (
            <Text component="div" mb={0.25} key={security}>
              {security}
            </Text>
          ))}
          <Text mt={2.75} mb={0.5} component="div" weight={700}>
            Retention
          </Text>
          <Text component="div">{residency.retention}</Text>
        </Box>
      ))}
    </>
  )
}
