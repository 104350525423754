import { Box, Typography } from '@mui/material'
import { Icon, theme } from '@ketch-com/deck'

export const AppealBadge = () => {
  return (
    <Box display="flex" alignItems="center" gap={0.25}>
      <Icon name={'FInput'} iconColor={theme.palette.pink.main} />
      <Typography variant="smallLabel" color="pink.main">
        Appeal Value
      </Typography>
    </Box>
  )
}
