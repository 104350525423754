import { ICanvasStep } from '../../interfaces'
import { getLastStepOfPath } from './getLastStepOfPath'
import { getStepType } from './getStepType'
import { CanvasStepType } from '../constants'

type Props = {
  gatewayStep: ICanvasStep
  steps: ICanvasStep[]
}

/**
 * Recursively get the last tile in every path for a single or nested gateways
 * @param gatewayStep A split, decision gateway, or decision boolean step
 * @param steps All steps within the outermost gateway
 */
export const getLastStepOfAllPaths = ({ gatewayStep, steps }: Props): ICanvasStep[] => {
  const lastSteps: ICanvasStep[] = []
  gatewayStep.gateway?.transitions.forEach((transition, pathIndex) => {
    // Get last step in this path
    const lastStep = getLastStepOfPath({
      pathStartStepId: transition.ID?.toString() || '',
      steps,
      stopAtJoin: true,
    })

    // Call recursively if last step returns a gateway
    if (lastStep && getStepType(lastStep) === CanvasStepType.Gateway) {
      lastSteps?.push(...getLastStepOfAllPaths({ gatewayStep: lastStep, steps }))
    } else if (lastStep) {
      // Add to last steps array
      if (lastStep) lastSteps?.push(lastStep)
    }
  })

  return lastSteps
}
