import React from 'react'

import { BadgeKeyValue } from 'components/ui-kit/badge/BadgeKeyValue'
import { Badge } from 'components/ui-kit/badge/Badge'

type Props = {
  category?: number
}

const getRender = (category?: number) => {
  switch (category) {
    case 1:
      return <BadgeKeyValue badgeKey="Rights" badgeValue="Deletion" />

    case 2:
      return <BadgeKeyValue badgeKey="Rights" badgeValue="Access" />

    case 3:
      return <BadgeKeyValue badgeKey="Rights" badgeValue="Portability" />

    case 4:
      return <Badge>Access Control</Badge>

    default:
      return <Badge>Unspecified</Badge>
  }
}

export const TypeCellRenderer: React.FC<Props> = ({ category }) => getRender(category)
