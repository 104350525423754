import { APIListRequestParams } from 'api/common/utils'
import { DatabaseDsrConfigurationSqls } from '@ketch-com/windlass/dist/schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  nextToken?: number
  databaseResourceId?: string
  scenarioId?: string
}>

export const fetchCustomSqls = ({ nextToken, limit, databaseResourceId, scenarioId }: Params) => {
  return API.get<DatabaseDsrConfigurationSqls>(
    formatRequestString({
      entityUrl: `/api/asset-manager/dsr-sql`,
      params: {
        filters: {
          limit,
          nextToken,
          databaseResourceId,
          scenarioId,
        },
      },
    }),
  )
}
