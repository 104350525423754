import { useContext } from 'react'
import { ImportContextData } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/ImportContext/context'
import { Box, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { IImportContextParams } from 'pages/orchestration/workflows/edit/interfaces'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { ListItemText, ActionSheetItem } from '@ketch-com/deck'
import { useFeatureFlag } from 'utils/hooks'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { ContextVariableDTO } from '@ketch-com/figurehead'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'

interface SelectToIdentifierProps {
  label: string
  formPropertyName: string
  itemsKey: ItemKeyOptions
  onItemClick: (identity: SelectableIdentifierToItemOption, setFieldValue: (field: string, value: any) => void) => void
}

type ItemKeyOptions = 'toIdentitySpaces' | 'toContextVariables'
export type SelectableIdentifierToItemOption = IdentitySpaceDTO | ContextVariableDTO

export const SelectToIdentifier: React.FC<SelectToIdentifierProps> = ({
  label,
  formPropertyName,
  itemsKey,
  onItemClick,
}) => {
  const importContextData = useContext(ImportContextData)
  const { values, setFieldValue } = useFormikContext<IImportContextParams>()

  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isMultipleIdentitiesFlagEnabled = !isFeatureFlagEnabled(
    FEATURE_FLAGS.IMPORT_CONTEXT,
    FEATURE_FLAGS_VALUES.IMPORT_CONTEXT_SINGLE_IDENTITY,
  )

  const handleItemClick = (identity: SelectableIdentifierToItemOption) => {
    onItemClick(identity, setFieldValue)
  }

  const width = isMultipleIdentitiesFlagEnabled ? '100%' : 214
  const size = isMultipleIdentitiesFlagEnabled ? 'medium' : 'small'
  const items: SelectableIdentifierToItemOption[] = importContextData[itemsKey] || []

  return (
    <Box width={width}>
      <FormDroplistButton
        label={label}
        disableClearable
        required
        formPropertyName={formPropertyName}
        onItemClick={handleItemClick}
        size={size}
        valueKey="code"
        placeholder="Select Identity"
        items={items}
        getOptionLabel={option => option?.name || ''}
        isOptionEqualToValue={(option, value) => option?.code === value?.code}
        getOptionDisabled={option => option?.code === values?.code}
        renderOption={(props, option, _, isSelected) => (
          <ActionSheetItem {...props} key={option.code} selected={isSelected}>
            <ListItemText selected={isSelected}>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography alignSelf="flex-start" variant="body">
                  {option?.name ? option.name : ''}
                </Typography>
                <Typography alignSelf="flex-start" variant="footnote" color="darkDuskFaded.main">
                  {option?.code}
                </Typography>
              </Box>
            </ListItemText>
          </ActionSheetItem>
        )}
      />
    </Box>
  )
}
