import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { createStyles, makeStyles } from '@mui/styles'
import { Collapse } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import clsx from 'clsx'

import { Text } from 'components/ui-kit/typography/Text'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { Button } from '@ketch-com/deck'
import { Spinner } from 'components/ui-kit/spinner/Spinner'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      container: {
        maxWidth: 358,
        backgroundColor: palette.white.main,
        borderRadius: spacing(1.375),
        padding: spacing(2.5),
      },
      chevronIcon: {
        color: palette.darkDusk.main,
        fontSize: 20,
        transform: 'rotate(0deg)',
        transition: 'transform 0.2s ease-in-out',
      },
      chevronIconOpen: {
        transform: 'rotate(90deg)',
      },
      editIcon: {
        fontSize: 20,
        color: palette.darkDusk.main,
        width: 16,
        height: 16,
      },
      withPointer: {
        cursor: 'pointer',
      },
      hidden: {
        opacity: 0,
      },
    }),
  { name: 'AssetManagerWidgetLayout' },
)

type Props = {
  children?: React.ReactNode
  title?: string
  subTitle?: string
  isReady?: boolean
  isEditDisabled?: boolean
  onEdit?: () => void
  editModal?: React.ReactNode
  isEditModalOpen?: boolean
}

export const AssetManagerWidgetLayout: React.FC<Props> = ({
  title,
  subTitle,
  children,
  isReady = true,
  isEditDisabled = true,
  onEdit = () => {},
  editModal,
  isEditModalOpen = false,
}) => {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  return (
    <Box className={classes.container}>
      {/* Header */}

      <Box display="flex" alignItems="center" onClick={() => setIsExpanded(b => !b)}>
        <Box className={classes.withPointer} display="flex" alignItems="center" pr={1.875}>
          <ChevronRightIcon
            className={clsx(classes.chevronIcon, {
              [classes.chevronIconOpen]: isExpanded,
            })}
          />
        </Box>
        <Box className={classes.withPointer} flex={1} display="flex" flexDirection="column">
          <Text size={16} weight={700} mb={0.5} cursor="pointer">
            {title}
          </Text>
          <Text size={14} color="darkDuskFaded" cursor="pointer">
            {subTitle}
          </Text>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            disabled={isEditDisabled}
            className={clsx({
              [classes.hidden]: isEditDisabled,
            })}
            color="tertiary"
            variant="icon"
            onClick={e => {
              e.stopPropagation()
              onEdit()
            }}
          >
            <EditIcon className={classes.editIcon} />
          </Button>
        </Box>
      </Box>

      {/* Content */}

      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Box pt={2.5}>{isReady ? children : <Spinner />}</Box>
      </Collapse>

      {/* Edit modal */}

      {isEditModalOpen && editModal ? editModal : null}
    </Box>
  )
}
