import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchAssetV2 } from '../fetchers/fetchAssetV2'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.resource,
  queryFn: fetchAssetV2,
  select: res => res?.data?.assetSummary || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useAssetV2 = (config: Config) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )
  return useCustomQuery({
    ...config,
    params: {
      isV2FeatureFlagEnabled,
      ...config.params!,
    },
  })
}
