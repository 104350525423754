import React from 'react'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { FormTextInput } from '../FormTextInput'
import { useContext } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'

export const PreferenceExperienceWelcomeTabExitButtonForm: React.FC = () => {
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length

  return (
    <>
      <SidebarFormGroup
        title={'Exit Button'}
        sx={{ pt: 0, pb: '24px' }}
        hasBorderTop={false}
        hasBorderBottom={hasLanguages}
      >
        <FormTextInput
          label={'Text'}
          name={experienceUpsertFormKeys.preferenceWelcomeTabFooterActionButtonText}
          fullWidth
          charLimit={30}
        />
      </SidebarFormGroup>
      <SidebarFormTranslations
        title={'Exit Button Text Translations'}
        name={experienceUpsertFormKeys.preferenceWelcomeTabFooterActionButtonText}
        variant={'text'}
        charLimit={30}
      />
    </>
  )
}
