import React, { useState } from 'react'
import { PopUp, Button } from '@ketch-com/deck'
import Box from '@mui/material/Box'

const CustomTitle = () => (
  <div>
    <h2>Custom Title Component</h2>
  </div>
)

export const ModalImplementationExample = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleDialogClose = () => {
    setIsOpen(false)
  }

  return (
    <Box display="flex" alignItems="center">
      <Button color="secondary" onClick={() => setIsOpen(true)}>
        Open Modal
      </Button>
      <PopUp
        isOpen={isOpen}
        onClose={handleDialogClose}
        id="kitchenSinkDialog"
        testId="kitchenSinkDialogTest"
        className="custom-dialog-class"
        intercomTarget="intercom-target"
        clipboardText="clipboard-text"
        popUpTitleProps={{
          className: 'custom-title-class',
        }}
        popUpCustomTitle={<CustomTitle />}
        popUpContentProps={{
          className: 'custom-content-class',
        }}
        popUpActionsProps={{
          className: 'custom-actions-class',
        }}
        popUpActionChildren={
          <>
            <Button color="tertiary" onClick={() => setIsOpen(false)}>
              Close
            </Button>
            <Button onClick={() => setIsLoading(true)}>Submit</Button>
          </>
        }
        variant="dialog"
        showCloseButton
        popUpWidth="600px"
        isLoading={isLoading}
        title="Dialog Title"
        subTitle="Dialog Sub Title"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo
        lobortis eget.
      </PopUp>
    </Box>
  )
}
