export enum ApplicationIdentitySpaceType {
  DATA_LAYER = 1,
  WINDOW = 2,
  COOKIE = 3,
  LOCAL_STORAGE = 4,
  SESSION_STORAGE = 5,
  QUERY_STRING = 6,
}

export const APPLICATION_IDENTITY_SPACE_TYPE_NAME = {
  [ApplicationIdentitySpaceType.DATA_LAYER]: 'Data Layer',
  [ApplicationIdentitySpaceType.WINDOW]: 'General Variable',
  [ApplicationIdentitySpaceType.COOKIE]: 'Cookie',
  [ApplicationIdentitySpaceType.LOCAL_STORAGE]: 'Local Storage',
  [ApplicationIdentitySpaceType.SESSION_STORAGE]: 'Session Storage',
  [ApplicationIdentitySpaceType.QUERY_STRING]: 'Query String',
}

export const APPLICATION_IDENTITY_SPACE_TYPE_OPTIONS = [
  {
    id: ApplicationIdentitySpaceType.DATA_LAYER,
    name: APPLICATION_IDENTITY_SPACE_TYPE_NAME[ApplicationIdentitySpaceType.DATA_LAYER],
  },
  {
    id: ApplicationIdentitySpaceType.WINDOW,
    name: APPLICATION_IDENTITY_SPACE_TYPE_NAME[ApplicationIdentitySpaceType.WINDOW],
  },
  {
    id: ApplicationIdentitySpaceType.COOKIE,
    name: APPLICATION_IDENTITY_SPACE_TYPE_NAME[ApplicationIdentitySpaceType.COOKIE],
  },
  {
    id: ApplicationIdentitySpaceType.LOCAL_STORAGE,
    name: APPLICATION_IDENTITY_SPACE_TYPE_NAME[ApplicationIdentitySpaceType.LOCAL_STORAGE],
  },
  {
    id: ApplicationIdentitySpaceType.SESSION_STORAGE,
    name: APPLICATION_IDENTITY_SPACE_TYPE_NAME[ApplicationIdentitySpaceType.SESSION_STORAGE],
  },
  {
    id: ApplicationIdentitySpaceType.QUERY_STRING,
    name: APPLICATION_IDENTITY_SPACE_TYPE_NAME[ApplicationIdentitySpaceType.QUERY_STRING],
  },
]
