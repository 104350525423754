import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchDataSubjectTypes } from 'api/dataSubjectTypes/fetchers/fetchDataSubjectTypes'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.dataSubjectTypesPaginated,
  queryFn: fetchDataSubjectTypes,
  select: res => res?.data?.dataSubjectTypes || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useDataSubjectTypesPaginated = (config?: QueryConfig) =>
  useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
