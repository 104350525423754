import React, { useContext, useMemo, useRef } from 'react'
import Box from '@mui/material/Box'
import {
  Tab,
  TabPanel,
  TabGroup,
  DropListButton,
  ChipsAutocomplete,
  ChipsAutocompleteOptionType,
  ListItemText,
  ActionSheetItem,
} from '@ketch-com/deck'
import { FormTextInput } from '../FormTextInput'
import { experienceUpsertFormKeys } from '../../../../utils/useExperienceValidationSchema'
import { FormSectionSwitch } from '../FormSectionSwitch'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { ProgressiveExperienceButtonActionLabels } from '../../../../utils/labels'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { styled } from '@mui/styles'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { OptionType } from 'interfaces/optionType'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { ProgressiveExperienceButtonAction, ProgressiveExperienceType } from '@ketch-sdk/ketch-types'

const ButtonFormBox = styled(Box)({
  padding: '0px 18px',
})

const ButtonActionOptions: OptionType[] = Object.values(ProgressiveExperienceButtonAction).map(action => ({
  value: action,
  label: ProgressiveExperienceButtonActionLabels[action],
}))

export const ProgressiveExperienceButtonsForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ExperienceV2DTO>()
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length
  // Current tab of the button form
  const [tab, setTab] = React.useState(0)

  const isConsentType =
    getIn(values, experienceUpsertFormKeys.progressiveExperienceType) === ProgressiveExperienceType.Consent
  console.log('isConsentType', isConsentType)

  // Get purpose options
  const { data: purposes } = usePurposes()
  const purposesOptions = useMemo(
    () =>
      purposes?.map(purpose => ({
        label: purpose.name,
        value: purpose.code,
      })),
    [purposes],
  )

  // Get the value for each action button
  const primaryButtonActionValue = ButtonActionOptions.find(
    option => option.value === getIn(values, experienceUpsertFormKeys.progressiveExperienceActionButtonsPrimaryAction),
  )
  const secondaryButtonActionValue = ButtonActionOptions.find(
    option =>
      option.value === getIn(values, experienceUpsertFormKeys.progressiveExperienceActionButtonsSecondaryAction),
  )
  const tertiaryButtonActionValue = ButtonActionOptions.find(
    option => option.value === getIn(values, experienceUpsertFormKeys.progressiveExperienceActionButtonsTertiaryAction),
  )

  // Get the current mapped purposes for each button
  const primaryButtonPurposeCodes = getIn(
    values,
    experienceUpsertFormKeys.progressiveExperienceActionButtonsPrimaryPurposeCodes,
  ) as string[]
  const primaryButtonPurposeCodesValue: ChipsAutocompleteOptionType[] = useMemo(() => {
    return primaryButtonPurposeCodes.map(code => ({
      label: purposes?.find(purpose => purpose.code === code)?.name,
      value: code,
    }))
  }, [primaryButtonPurposeCodes, purposes])

  const secondaryButtonPurposeCodes = getIn(
    values,
    experienceUpsertFormKeys.progressiveExperienceActionButtonsSecondaryPurposeCodes,
  ) as string[]
  const secondaryButtonPurposeCodesValue: ChipsAutocompleteOptionType[] = useMemo(() => {
    return secondaryButtonPurposeCodes.map(code => ({
      label: purposes?.find(purpose => purpose.code === code)?.name,
      value: code,
    }))
  }, [secondaryButtonPurposeCodes, purposes])

  const tertiaryButtonPurposeCodes = getIn(
    values,
    experienceUpsertFormKeys.progressiveExperienceActionButtonsTertiaryPurposeCodes,
  ) as string[]
  const tertiaryButtonPurposeCodesValue: ChipsAutocompleteOptionType[] = useMemo(() => {
    return tertiaryButtonPurposeCodes.map(code => ({
      label: purposes?.find(purpose => purpose.code === code)?.name,
      value: code,
    }))
  }, [tertiaryButtonPurposeCodes, purposes])

  // Should the tertiary button form be displayed
  const showTertiaryButtonForm = getIn(
    values,
    experienceUpsertFormKeys.progressiveExperienceActionButtonsTertiaryVisible,
  )

  const ref = useRef()

  const renderAutocompleteOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: ChipsAutocompleteOptionType,
  ) => (
    <ActionSheetItem {...props} key={option.value}>
      <ListItemText>{option.label}</ListItemText>
    </ActionSheetItem>
  )

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
          <Tab size={'medium'} label="Primary" />
          <Tab size={'medium'} label="Secondary" />
          <Tab size={'medium'} label="Tertiary" />
        </TabGroup>
      </Box>

      {/* Primary button form */}
      <TabPanel value={tab} index={0} sx={{ px: 0 }}>
        <SidebarFormGroup hasBorderBottom={hasLanguages} sx={{ pt: '16px', pb: isConsentType ? 0 : '32px' }}>
          <FormTextInput
            name={experienceUpsertFormKeys.progressiveExperienceActionButtonsPrimaryText}
            label={'Button Text'}
            fullWidth
            charLimit={50}
          />
          <DropListButton
            size={'medium'}
            label="Action"
            placeholder="Action"
            options={ButtonActionOptions}
            value={primaryButtonActionValue}
            onChange={(_, newValue) => {
              setFieldValue(experienceUpsertFormKeys.progressiveExperienceActionButtonsPrimaryAction, newValue.value)
            }}
            disableClearable
          />
          {isConsentType && (
            <ChipsAutocomplete
              ref={ref.current}
              fullWidth
              renderOption={renderAutocompleteOption}
              isOptionEqualToValue={(elem, value) => elem.value === value.value}
              disableClearable
              label="Selected Purposes"
              placeholder="Select a value"
              chipSize="medium"
              size="medium"
              multiple
              options={purposesOptions}
              onChange={(_, newValue) => {
                setFieldValue(
                  experienceUpsertFormKeys.progressiveExperienceActionButtonsPrimaryPurposeCodes,
                  newValue.map(value => value.value),
                )
              }}
              handleChipClick={v => {
                const currentValueWithoutPurpose = primaryButtonPurposeCodes.filter(code => code !== v.value)
                setFieldValue(
                  experienceUpsertFormKeys.progressiveExperienceActionButtonsPrimaryPurposeCodes,
                  currentValueWithoutPurpose,
                )
              }}
              value={primaryButtonPurposeCodesValue}
            />
          )}
        </SidebarFormGroup>
        <SidebarFormTranslations
          title={'Button Text Translations'}
          name={experienceUpsertFormKeys.progressiveExperienceActionButtonsPrimaryText}
          variant={'text'}
          charLimit={50}
        />
      </TabPanel>

      {/* Secondary button form*/}
      <TabPanel value={tab} index={1} sx={{ px: 0 }}>
        <SidebarFormGroup hasBorderBottom sx={{ pt: '16px', pb: isConsentType ? 0 : '32px' }}>
          <FormTextInput
            name={experienceUpsertFormKeys.progressiveExperienceActionButtonsSecondaryText}
            label={'Button Text'}
            fullWidth
            charLimit={50}
          />
          <DropListButton
            size={'medium'}
            label="Action"
            placeholder="Action"
            options={ButtonActionOptions}
            value={secondaryButtonActionValue}
            onChange={(_, newValue) => {
              setFieldValue(experienceUpsertFormKeys.progressiveExperienceActionButtonsSecondaryAction, newValue.value)
            }}
            disableClearable
          />
          {isConsentType && (
            <ChipsAutocomplete
              ref={ref.current}
              fullWidth
              renderOption={renderAutocompleteOption}
              isOptionEqualToValue={(elem, value) => elem.value === value.value}
              disableClearable
              label="Selected Purposes"
              placeholder="Select a value"
              chipSize="medium"
              size="medium"
              multiple
              options={purposesOptions}
              onChange={(_, newValue) => {
                setFieldValue(
                  experienceUpsertFormKeys.progressiveExperienceActionButtonsSecondaryPurposeCodes,
                  newValue.map(value => value.value),
                )
              }}
              handleChipClick={v => {
                const currentValueWithoutPurpose = secondaryButtonPurposeCodes.filter(code => code !== v.value)
                setFieldValue(
                  experienceUpsertFormKeys.progressiveExperienceActionButtonsSecondaryPurposeCodes,
                  currentValueWithoutPurpose,
                )
              }}
              value={secondaryButtonPurposeCodesValue}
            />
          )}
        </SidebarFormGroup>
        <SidebarFormTranslations
          title={'Button Text Translations'}
          name={experienceUpsertFormKeys.progressiveExperienceActionButtonsSecondaryText}
          variant={'text'}
          charLimit={50}
        />
      </TabPanel>

      {/* Tertiary button form*/}
      <TabPanel value={tab} index={2} sx={{ pt: '16px', pb: isConsentType ? 0 : '32px', px: 0 }}>
        <FormSectionSwitch
          title={'Tertiary Button'}
          name={experienceUpsertFormKeys.progressiveExperienceActionButtonsTertiaryVisible}
        />
        {showTertiaryButtonForm && (
          <ButtonFormBox>
            <SidebarFormGroup hasBorderBottom sx={{ pt: '16px', pb: isConsentType ? 0 : '32px' }}>
              <FormTextInput
                name={experienceUpsertFormKeys.progressiveExperienceActionButtonsTertiaryText}
                label={'Button Text'}
                fullWidth
                charLimit={50}
              />
              <DropListButton
                size={'medium'}
                label="Action"
                placeholder="Action"
                options={ButtonActionOptions}
                value={tertiaryButtonActionValue}
                onChange={(_, newValue) => {
                  setFieldValue(
                    experienceUpsertFormKeys.progressiveExperienceActionButtonsTertiaryAction,
                    newValue.value,
                  )
                }}
                disableClearable
              />
              {isConsentType && (
                <ChipsAutocomplete
                  ref={ref.current}
                  fullWidth
                  renderOption={renderAutocompleteOption}
                  isOptionEqualToValue={(elem, value) => elem.value === value.value}
                  disableClearable
                  label="Selected Purposes"
                  placeholder="Select a value"
                  chipSize="medium"
                  size="medium"
                  multiple
                  options={purposesOptions}
                  onChange={(_, newValue) => {
                    setFieldValue(
                      experienceUpsertFormKeys.progressiveExperienceActionButtonsTertiaryPurposeCodes,
                      newValue.map(value => value.value),
                    )
                  }}
                  handleChipClick={v => {
                    const currentValueWithoutPurpose = tertiaryButtonPurposeCodes.filter(code => code !== v.value)
                    setFieldValue(
                      experienceUpsertFormKeys.progressiveExperienceActionButtonsTertiaryPurposeCodes,
                      currentValueWithoutPurpose,
                    )
                  }}
                  value={tertiaryButtonPurposeCodesValue}
                />
              )}
            </SidebarFormGroup>
            <SidebarFormTranslations
              title={'Button Text Translations'}
              name={experienceUpsertFormKeys.progressiveExperienceActionButtonsTertiaryText}
              variant={'text'}
              charLimit={50}
            />
          </ButtonFormBox>
        )}
      </TabPanel>
    </Box>
  )
}
