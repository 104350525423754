import Box from '@mui/material/Box'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { OverviewTechnologyChip } from 'pages/assetManager/components'

type Props = {
  assetSummary?: AssetSummaryDTO
}

export const Technology: React.FC<Props> = ({ assetSummary }) => {
  return (
    <Box display="flex" alignItems="center" sx={{ pt: 2, pb: 4 }}>
      <OverviewTechnologyChip title="Technology" technology={assetSummary?.asset?.connection?.technology} />
    </Box>
  )
}
