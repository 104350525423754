import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useWorkflow } from 'api/workflows/queries/useWorkflow'
import { useDeleteWorkflow } from 'api/workflows/mutations/useDeleteWorkflow'
import { showToast } from 'components/modals/AlertComponent'
import { Button, PopUp, Spinner } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

type Props = {
  workflowCode: string
  onSubmit?: () => void
  onCancel: () => void
}

export const DeleteWorkflowModal: React.FC<Props> = props => {
  const queryClient = useQueryClient()

  const { workflowCode, onSubmit, onCancel } = props
  const [isBusy, setIsBusy] = useState(false)

  const { data: workflow, isLoading } = useWorkflow({
    enabled: !!workflowCode,
    params: {
      workflowCode,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch workflow', type: 'error' })
      onCancel()
    },
  })

  const { mutate: handleDeleteWorkflow } = useDeleteWorkflow({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'Workflow deleted', type: 'success' })
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to delete workflow', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <PopUp
      title="Delete Workflow"
      variant="dialog"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel} pending={isBusy}>
            Close
          </Button>
          <Button
            pending={isBusy}
            color="primary"
            size="large"
            onClick={_ => {
              handleDeleteWorkflow({
                params: {
                  workflowCode,
                },
              })
            }}
          >
            Delete
          </Button>
        </>
      }
    >
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : (
        <Typography>
          Are you sure you want to delete <Typography variant="label">{workflow?.name || workflow?.code}</Typography>{' '}
          workflow?
        </Typography>
      )}
    </PopUp>
  )
}
