import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep } from '../../interfaces'
import { getStepsBeforeStep } from './getStepsBeforeStep'
import { getNextJoinTile } from './getNextJoinTile'
import { isNewStepConnectedNestStart } from './isNewStepConnectedNestStart'

type Props = {
  steps: ICanvasStep[]
  step: ICanvasStep
}

export const isTileInConnectedNest = ({ step, steps }: Props): boolean => {
  const stepsBeforeStep = getStepsBeforeStep({ step, steps })
  const childChainHasJoin = !!getNextJoinTile({ steps, step })

  let parentChainHasDecisionBeforeLastJoinOrStart = false
  let hasHitJoinOrStart = false
  stepsBeforeStep.forEach(parent => {
    if (
      (parent.gateway?.mode === WorkflowActivityGatewayMode.DECISION_MULTI ||
        parent.gateway?.mode === WorkflowActivityGatewayMode.SPLIT ||
        parent.gateway?.mode === WorkflowActivityGatewayMode.DECISION_SINGLE) &&
      !hasHitJoinOrStart
    ) {
      // Only count the gateway if it has all paths leading to a join. Otherwise, it's disconnected.
      const isParentConnectedNest = isNewStepConnectedNestStart({
        step: parent,
        steps,
        requireAllPathsLeadingToJoinValidation: true,
      })

      parentChainHasDecisionBeforeLastJoinOrStart = isParentConnectedNest
      hasHitJoinOrStart = true
    } else if (parent.gateway?.mode === WorkflowActivityGatewayMode.JOIN) {
      hasHitJoinOrStart = true
    }
  })

  return parentChainHasDecisionBeforeLastJoinOrStart && childChainHasJoin
}
