import React from 'react'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'

import { Button, EmptyState } from '@ketch-com/deck'
import { EmptyState as OldEmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { ReactComponent as DatabaseIcon } from 'assets/icons/database_bold.svg'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'
import { SidebarConfigsHeaderDsrTransponderForm } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeaderDsrTransponderForm'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { Text } from 'components/ui-kit/typography/Text'
import * as hooks from './hooks'
import {
  AddDatabaseModal,
  ConfigureDatasetAttributeActionsModal,
  DatabaseList,
  RelatedDatasetsTable,
} from './components'
import { Switch } from 'components/ui-kit/switch/Switch'

interface IDsrTransponderFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const DsrTransponderForm: React.FC<IDsrTransponderFormProps> = ({
  step,
  handleClose,
  handleRemove,
  handleSave,
}) => {
  const classes = hooks.useStyles()
  const {
    activeDatabase,
    attributesSearchString,
    configureDatasetModalCode,
    formViewType,
    isAddDatabasesModalOpen,
    isLoadingDsrPolicies,
    isLoadingRelatedAttributes,
    isLoadingRelatedDatasets,
    onSubmit,
    relatedAttributes,
    relatedAttributesPagination,
    relatedDatasets,
    relatedDatasetsPagination,
    searchString,
    selectedDatasetObject,
    setActiveDatabase,
    setConfigureDatasetModalCode,
    setFormViewType,
    setIsAddDatabasesModalOpen,
    setSearchString,
    setDatasetSearchString,
    selectedDatabases,
    setAttributesSearchString,
    datasetSearchString,
    setIsShowSelectedDatasets,
    isShowSelectedDatasets,
  } = hooks.useDsrTransponderFormUtils({
    step,
    handleSave,
  })

  if (isLoadingDsrPolicies) {
    return (
      <SidebarConfigsWrapper handleClose={handleClose} fullWidth>
        <Box display="flex" justifyContent="center">
          <Spinner />
        </Box>
      </SidebarConfigsWrapper>
    )
  }

  return (
    <SidebarConfigsWrapper handleClose={handleClose} fullWidth>
      <>
        <SidebarConfigsHeaderDsrTransponderForm
          isValid
          step={step}
          handleSave={() => onSubmit()}
          handleRemove={() => handleRemove({ step })}
          handleClose={handleClose}
          copyable
          alternateViewType={formViewType}
          handleToggleView={() => {
            setFormViewType(formViewType => (formViewType === 'yaml' ? 'gui' : 'yaml'))
          }}
        />

        <SidebarConfigsBody sx={{ px: 3 }}>
          {formViewType === 'gui' && selectedDatabases.length === 0 && (
            <>
              <Box
                bgcolor="white.main"
                borderRadius="11px"
                py={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <EmptyState
                  iconName="ODatabaseAdd"
                  title="Add databases first"
                  subTitle="Click on the button below to look up and select the databases you want to include in this activity."
                  primaryButtonTitle="Add Databases"
                  primaryButtonProps={{
                    onClick: () => setIsAddDatabasesModalOpen(true),
                  }}
                />
              </Box>
            </>
          )}

          {formViewType === 'gui' && selectedDatabases.length > 0 && (
            <Box pl={3.25} display="flex" alignItems="stretch" height="100%">
              {/* Left Column */}

              <Box pt={2} flexDirection="column" flex={1} className={classes.databaseColumn}>
                <Box pr={2} mb={2} display="flex" alignItems="center">
                  <SearchInput
                    onChange={nextSearchString => setSearchString(nextSearchString)}
                    onClear={() => setSearchString('')}
                    value={searchString}
                    placeholder="Filter selected"
                  />
                  <Button
                    id="addAccessConfig"
                    onClick={() => {
                      setIsAddDatabasesModalOpen(true)
                    }}
                    className={classes.plusButton}
                  >
                    <AddIcon />
                  </Button>
                </Box>

                <DatabaseList
                  setActiveDatabase={setActiveDatabase}
                  activeDatabase={activeDatabase}
                  searchString={searchString}
                />
              </Box>

              {/* Right Column */}

              <Box
                flex={1}
                pt={2}
                display="flex"
                flexDirection="column"
                justifyContent={!activeDatabase ? 'center' : 'start'}
                alignItems={!activeDatabase ? 'center' : undefined}
              >
                {!activeDatabase && (
                  <OldEmptyState
                    icon={<DatabaseIcon />}
                    title="Select the Database first"
                    description="Check the panel on the left to look up and select the Database you want to include in this activity."
                  />
                )}

                {activeDatabase && (
                  <Box pl={6} pr={6} className={classes.tableContainer}>
                    <FormSection
                      shouldRemoveLeftPadding
                      title="Selected Datasets"
                      subTitle="Datasets will be scanned for identity-matching attributes. Make sure that all attributes have Identifiers assigned."
                    >
                      <Box mb={2} display="flex" alignItems="center">
                        <SearchInput
                          size="small"
                          onChange={nextSearchString => setDatasetSearchString(nextSearchString)}
                          onClear={() => setDatasetSearchString('')}
                          value={datasetSearchString}
                          disabled={isShowSelectedDatasets}
                        />

                        <Box ml={2} display="flex" alignItems="center">
                          <Switch
                            name=""
                            disabled={!!datasetSearchString}
                            value={isShowSelectedDatasets}
                            onChange={() => setIsShowSelectedDatasets(prevChecked => !prevChecked)}
                          />
                          <Box ml={1} display="flex" alignItems="center">
                            <Text size={14} weight={600}>
                              Only Selected
                            </Text>
                          </Box>
                        </Box>
                      </Box>

                      <RelatedDatasetsTable
                        activeDatabase={activeDatabase}
                        isLoadingRelatedDatasets={isLoadingRelatedDatasets}
                        relatedDatasets={relatedDatasets}
                        pagination={relatedDatasetsPagination}
                        setConfigureDatasetModalCode={setConfigureDatasetModalCode}
                      />
                    </FormSection>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </SidebarConfigsBody>

        {/* Configure Datasets Modal */}

        {configureDatasetModalCode && (
          <ConfigureDatasetAttributeActionsModal
            attributesSearchString={attributesSearchString}
            activeDatabase={activeDatabase}
            setConfigureDatasetModalCode={setConfigureDatasetModalCode}
            relatedAttributes={relatedAttributes}
            pagination={relatedAttributesPagination}
            selectedDatasetObject={selectedDatasetObject}
            isLoadingRelatedAttributes={isLoadingRelatedAttributes}
            configureDatasetModalCode={configureDatasetModalCode}
            setAttributesSearchString={setAttributesSearchString}
          />
        )}

        {/* Add Databases Modal */}

        {isAddDatabasesModalOpen && <AddDatabaseModal setIsAddDatabasesModalOpen={setIsAddDatabasesModalOpen} />}
      </>
    </SidebarConfigsWrapper>
  )
}
