import moment from 'moment'
import { addDays } from 'date-fns'
import { faker } from '@faker-js/faker'

const currentDate = new Date()

// function to generate teamMemberQueriesGraphData
export const generateTeamMemberQueriesGraphData = (num: number): any[] => {
  const names = []
  for (let i = 0; i < num; i++) {
    names.push({
      name: `${moment(addDays(currentDate, i)).format('MMM D')}`,
      date: moment(addDays(currentDate, i)).format('MMM D, YYYY'),
      queryCount: faker.datatype.number({ min: 900, max: 90000 }),
    })
  }
  return names
}

export const generatedTeamMemberQueriesGraphData = generateTeamMemberQueriesGraphData(44)
