const MAX_FIFTY_CHARS = 'Must be a maximum of 50 characters long'
const MIN_THREE_CHARS = 'Must be at least 3 characters long'
const MUST_BE_UNIQUE = 'This organization name is taken already. Please choose a different one.'
const VALUE_MUST_BE_UNIQUE = 'Value must be unique.'
const CLASSIFICATION_LABEL_MUST_BE_UNIQUE = 'This label name is taken already. Please choose a different one.'
const NO_SPACES = 'No spaces allowed!'
const NOT_MATCH_PASSWORD_REQUIREMENTS = 'Password doesn’t meet requirements'
const ONLY_LOWERCASE_CHARS_NUMBERS_AND_UNDERSCORE = 'Code must contain only lower case letters, numbers, and underscore'
const PASSWORDS_NOT_MATCH = 'Passwords do not match'
const REQUIRED = 'Required'
const SIGNUP_REQUIRED = 'This field is required to continue.'
const SIGNUP_VALID_EMAIL = 'A valid email is required to continue.'
const TOO_LONG = 'Too Long!'
const TOO_SHORT = 'Too Short!'
const MAX_CHARS_EMAIL = 'Email may not exceed 254 characters.'
const BANNED_EMAIL = 'This company is not supported by Ketch Free.'
const NON_COMPANY_EMAIL = 'Ketch free only supports accounts created with company email addresses.'
const MAX_CHARS_WEBSITE = 'Website must be a maximum of 500 characters long.'
const VALID_WEBSITE = 'A valid link is required to continue.'
const VALID_URL = 'A valid link is required.'
const MAX_CHARS_PRIVACY_POLICY =
  'Privacy policy link must be a maximum of 500 characters long. If longer, you may add it after signing up.'
const HTTP_PRIVACY_POLICY = 'Privacy policy link must start with http:// or https://.'
const VALID_PRIVACY_POLICY = 'A valid link is required to continue.'
const SELECTION_REQUIRED = 'Selection required.'
const POSITIVE_INTEGER = 'Positive integer only'

export const getMaxCharsErrorMessage = (maxChars: number) => `Must not exceed ${maxChars} characters.`

export const validationMessages = {
  CLASSIFICATION_LABEL_MUST_BE_UNIQUE,
  getMaxCharsErrorMessage,
  HTTP_PRIVACY_POLICY,
  MAX_CHARS_EMAIL,
  BANNED_EMAIL,
  NON_COMPANY_EMAIL,
  MAX_CHARS_PRIVACY_POLICY,
  MAX_CHARS_WEBSITE,
  MAX_FIFTY_CHARS,
  MIN_THREE_CHARS,
  MUST_BE_UNIQUE,
  NO_SPACES,
  NOT_MATCH_PASSWORD_REQUIREMENTS,
  ONLY_LOWERCASE_CHARS_NUMBERS_AND_UNDERSCORE,
  PASSWORDS_NOT_MATCH,
  POSITIVE_INTEGER,
  REQUIRED,
  SELECTION_REQUIRED,
  SIGNUP_REQUIRED,
  SIGNUP_VALID_EMAIL,
  TOO_LONG,
  TOO_SHORT,
  VALID_PRIVACY_POLICY,
  VALID_WEBSITE,
  VALID_URL,
  VALUE_MUST_BE_UNIQUE,
}
