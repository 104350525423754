import { BannerScreenPreview } from 'pages/consentAndRights/experiences/preview/components/bannerPreview/BannerScreenPreview'
import React, { useContext } from 'react'
import { DeploymentPlanPreviewContext } from '../context/DeploymentPlanPreviewContext'
import { ensureHydratedThemeColors } from 'utils/helpers/ensureHydratedThemeColors'

type Props = {}

export const DeploymentPlanBannerPreview: React.FC<Props> = () => {
  const { activeExperienceType, activePolicyScopeCode, data, mapping, mode, onBannerSecondaryButtonClick } =
    useContext(DeploymentPlanPreviewContext)

  const { configuration } = mapping?.[activeExperienceType] || {}

  const config = configuration?.experiences?.consent?.banner!
  const purposes = configuration?.purposes || []
  const rights = configuration?.rights || []

  return (
    <BannerScreenPreview
      key={activePolicyScopeCode}
      mode={mode}
      theme={ensureHydratedThemeColors(configuration?.theme)}
      title={config.title}
      footerDescription={config.footerDescription}
      buttonText={config.buttonText}
      secondaryButtonText={config.secondaryButtonText}
      data={data}
      purposes={purposes}
      rights={rights}
      jitPurposes={[]}
      consent={{}}
      onActionButtonClick={() => {
        onBannerSecondaryButtonClick(config.secondaryButtonDestination!)
      }}
    />
  )
}
