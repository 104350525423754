import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  theme =>
    createStyles({
      header: {
        width: '100%',
        boxSizing: 'border-box',
        padding: '13px 20px',
        borderRadius: 11,
        backgroundColor: theme.palette.white.main,
      },
    }),
  { name: 'UpsertInlineLayoutHeader' },
)

type Props = {
  children?: React.ReactNode
}

export const UpsertInlineLayoutHeader: React.FC<Props> = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.header}>{children}</div>
}
