import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'

import { Text } from 'components/ui-kit/typography/Text'
import { Button } from '@ketch-com/deck'

const PREFIX = 'AddPolicyModifierTile'

const classes = {
  container: `${PREFIX}-container`,
  plusButton: `${PREFIX}-plusButton`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing, shadows } }) => ({
  [`&.${classes.container}`]: {
    backgroundColor: palette.white.main,
    borderRadius: spacing(1.5),
    boxShadow: shadows[1],
  },

  [`& .${classes.plusButton}`]: {
    borderRadius: '50%',
    height: spacing(3.5),
    width: spacing(3.5),
    padding: spacing(2),
    minWidth: 'unset',
  },
}))

type Props = {
  onAdd: (event: React.MouseEvent) => void
  title: string
  description: string
  marginBottom?: boolean
}

export const OptionalFormSectionAddTile: React.FC<Props> = ({ onAdd, title, description, marginBottom }) => {
  return (
    <StyledBox mb={marginBottom ? 1.5 : 0} display="flex" className={classes.container}>
      {/* Col 1 */}

      <Box py={3.5} flex={1.5} display="flex" alignItems="center" justifyContent="center">
        <Button id={title} onClick={onAdd} className={classes.plusButton} color="primary">
          <AddIcon />
        </Button>
      </Box>

      {/* Col 2 */}

      <Box pt={2} flex={3} display="flex" flexDirection="column">
        <Box mb={1}>
          <Text size={12} weight={600}>
            {title}
          </Text>
        </Box>
        <Text size={11} weight={500} color="grey">
          {description}
        </Text>
      </Box>
    </StyledBox>
  )
}
