import { FieldArrayRenderProps } from 'formik'

import { Box } from '@mui/material'
import { Table } from 'components/ui-layouts/table/Table'
import { Text } from 'components/ui-kit/typography/Text'
import { InlineEdit } from 'components/ui-kit/inlineEdit/InlineEdit'

// types
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { StackGroupDTO } from '@ketch-com/figurehead'
type TileProps = {
  group: StackGroupDTO
  index: number
  helpers: FieldArrayRenderProps
  languages: LanguageWithStatusDTO[]
  onSubmit: () => void
  display?: 'view' | 'edit'
}

export const TranslationTile = ({ helpers, index, group, languages, onSubmit, display = 'edit' }: TileProps) => (
  <Box display="flex" mb={7}>
    <Box flexGrow={1}>
      <Box display="flex" ml={display === 'edit' ? 2 : 0} mb={3} mt={0.75}>
        <Text color="darkDuskFaded" size={14} width={280}>
          Purpose Group Name
        </Text>
        <Text size={14}>{group.name || 'None Provided'}</Text>
      </Box>

      {!!languages.length ? (
        <Table
          variant="page"
          items={languages}
          cellSettings={{
            language: {
              width: 280,
              label: 'Language',
              cellRenderer: ({ language: { englishName, code } }) => {
                let hasTranslation = (group?.translations && !!group?.translations[code]) || false
                return (
                  <Box display="flex" alignItems="center" gap={3}>
                    <Box
                      sx={{
                        backgroundColor: hasTranslation ? 'apple.main' : 'fadedDarkGrey.main',
                        borderRadius: '100%',
                        height: 6,
                        width: 6,
                      }}
                    />
                    <Text weight={600} color="grey">
                      {`${englishName} (${code})`}
                    </Text>
                  </Box>
                )
              },
            },
            translation: {
              label: 'Group Name Translation',
              cellRenderer: ({ language: { code } }) => (
                <InlineEdit
                  placeholder="Custom"
                  value={group?.translations?.[code] || ''}
                  onBlur={onSubmit}
                  onChange={({ target }) => {
                    const newGroup = { ...group, translations: { ...group.translations, [code]: target.value } }
                    helpers.replace(index, newGroup)
                  }}
                />
              ),
            },
          }}
        />
      ) : (
        <Box display="flex" ml={2} mb={3} mt={0.75}>
          <Text color="darkDuskFaded" size={14} width={250}>
            Translations
          </Text>
          <Text size={14}>None</Text>
        </Box>
      )}
    </Box>
  </Box>
)
