import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormSectionSwitch } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormSectionSwitch'
import { getIn, useFormikContext } from 'formik'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { Box } from '@mui/material'
import { FormFileUpload } from 'components/ui-kit/form/fileUpload/FormFileUpload'
import { themeFileAllowedTypes, themeFileUploadContext } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'

export const PreferenceThemeWelcomeTabHeroBannerForm: React.FC = () => {
  const { values } = useFormikContext<ThemeV3DTO>()
  const formVisible = getIn(values, themeUpsertFormKeys.preferenceWelcomeTabHeroBannerVisible)

  return (
    <SidebarFormGroup sx={{ pt: 0, gap: '16px' }}>
      <FormSectionSwitch title={'Hero Banner'} name={themeUpsertFormKeys.preferenceWelcomeTabHeroBannerVisible} />
      <Box px={'18px'}>
        {formVisible && (
          <FormFileUpload
            name={themeUpsertFormKeys.preferenceWelcomeTabHeroBannerImageUrl}
            label={'Hero Banner Image'}
            belowButtonLabel={
              'Recommended size and aspect ratio: 1024x480.\nOptimum image resolution: 72 PPI (pixels per inch).'
            }
            uploadContext={themeFileUploadContext}
            accept={themeFileAllowedTypes}
          />
        )}
      </Box>
    </SidebarFormGroup>
  )
}
