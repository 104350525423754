import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchOrchestrationEvents } from 'api/orchestrationEventsV2/fetchers/fetchOrchestrationEvents'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.events,
  queryFn: fetchOrchestrationEvents,
  select: res => res?.data?.events || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useOrchestrationEvents = (config?: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      start: 0,
      limit: 1000,
      ...config?.params,
    },
  })
}
