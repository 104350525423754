import React, { Dispatch, SetStateAction } from 'react'
import { useFormikContext } from 'formik'

import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { ICanvasStep, ITaskActivityParams } from 'pages/orchestration/workflows/edit/interfaces'
import { ContextVarDTO } from 'pages/orchestration/workflows/edit/interfaces/ITaskActivityParams'
import { ContextVariableCategoryDTO, ContextVariableDTO } from '@ketch-com/figurehead'
import { getIsVariableReferencedInDownstreamDecisionGateway } from '../utils'
import { Button, PopUp, DropListButton, Icon, ListItemText, ActionSheetItem } from '@ketch-com/deck'
import { Checkbox } from 'components/Checkbox/Checkbox'

type Props = {
  remove: <T>(index: number) => T | undefined
  option: ContextVarDTO
  variableOptions: ContextVariableDTO[]
  variableId?: string
  variableIndex: number
  selectedVariableCodeForAddition: string
  formikStateKey: string
  step: ICanvasStep
  steps: Array<ICanvasStep>
  setVariablesWarning: Dispatch<SetStateAction<string>>
}

export const VariableItem: React.FC<Props> = ({
  variableId,
  remove,
  option,
  variableIndex,
  selectedVariableCodeForAddition,
  variableOptions,
  formikStateKey,
  step,
  steps,
  setVariablesWarning,
}) => {
  const [confirmRemoveVariableModalMessage, setConfirmRemoveVariableModalMessage] = React.useState<string>('')

  const {
    setFieldValue,
    setFieldTouched,
    values: { outcomeVariables: formikDataSubjectVariables },
  } = useFormikContext<ITaskActivityParams>()

  const selectedOutcomeVariable = variableOptions.find(cv => cv.code === option?.code)

  const filteredVariableOptions = variableOptions.filter(
    cv => !formikDataSubjectVariables.find(pdv => pdv.code === cv.code) && cv.code !== selectedVariableCodeForAddition,
  )
  return (
    <Box
      key={variableId}
      display="flex"
      flexDirection="column"
      sx={{
        backgroundColor: 'fadedGrey.main',
        borderRadius: 2.75,
        p: 1.5,
        mb: 2,
      }}
    >
      {/* Row 1: Drag'n'Drop Indicator Icon, Option Value Input, Delete Item Button */}

      <Box display="flex" mb={2} alignItems="center">
        <Box display="flex" alignItems="center" flex={1}>
          <Box display="flex" flex={1} alignItems="center">
            <DropListButton
              disableClearable={selectedOutcomeVariable !== null}
              isOptionEqualToValue={(option, value) => option.code === value.code}
              getOptionLabel={option => option?.name || ''}
              renderOption={(props, option, { selected }) => (
                <ActionSheetItem selected={selected} {...props}>
                  <ListItemText selected={selected}>{option.name}</ListItemText>
                </ActionSheetItem>
              )}
              options={filteredVariableOptions}
              onChange={(_, value) =>
                value &&
                setFieldValue(`${formikStateKey}[${variableIndex}]`, {
                  ...{
                    code: value.code,
                    name: value.name,
                  },
                  required: !!option?.required,
                })
              }
              value={selectedOutcomeVariable}
              placeholder="Select"
              size="small"
            />
          </Box>

          {/* Right */}

          <Box ml={1} display="flex" alignItems="center">
            <Button
              variant="iconCustomRounded"
              color="tertiary"
              size="small"
              onClick={() => {
                const { isReferenced, warningMessage } = getIsVariableReferencedInDownstreamDecisionGateway({
                  ctxVariable: selectedOutcomeVariable || {},
                  step,
                  steps,
                })

                if (
                  isReferenced &&
                  selectedOutcomeVariable?.category === ContextVariableCategoryDTO.OutcomeContextVariableCategory
                ) {
                  setVariablesWarning(warningMessage)
                  return
                }

                if (
                  isReferenced &&
                  selectedOutcomeVariable?.category === ContextVariableCategoryDTO.DataSubjectContextVariableCategory
                ) {
                  setConfirmRemoveVariableModalMessage(warningMessage)
                  return
                } else {
                  remove(variableIndex)
                }
              }}
            >
              <Icon name="FBin" />
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Row 2: Required Checkbox */}

      <Checkbox
        title="Required"
        value={!!option?.required}
        onChange={(e, checked) => {
          setFieldValue(`${formikStateKey}[${variableIndex}].required`, checked)
          setFieldTouched(`${formikStateKey}[${variableIndex}].required`, true)
        }}
      />

      <PopUp
        title="Remove Variable"
        isOpen={!!confirmRemoveVariableModalMessage}
        onClose={() => setConfirmRemoveVariableModalMessage('')}
        variant="dialog"
        popUpWidth="550px"
        popUpActionChildren={
          <>
            <Button color="secondary" size="large" onClick={() => setConfirmRemoveVariableModalMessage('')}>
              Cancel
            </Button>
            <Button color="primary" size="large" onClick={() => remove(variableIndex)}>
              Remove
            </Button>
          </>
        }
      >
        <Typography>{confirmRemoveVariableModalMessage}</Typography>
      </PopUp>
    </Box>
  )
}
