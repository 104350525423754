import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
  includeIssues?: boolean
}>

type Response = APIListResponse<{ identitySpaces: IdentitySpaceDTO[] }>

export const fetchIdentitySpaces = ({
  organizationCode,
  includeMetadata,
  includeIssues,
  start,
  limit,
  query,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/identity-spaces`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
          query,
        },
      },
    }),
  )
