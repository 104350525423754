import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { CanonicalRightDTO } from 'interfaces/rights/right'
import { APIRequestParams } from 'api/common/utils'

type Params = APIRequestParams<{
  includeChildCustomEvents?: boolean
  code?: string
  dataRole?: number
}>

export const fetchCanonicalRight = ({ includeChildCustomEvents, code, dataRole }: Params) =>
  API.get<CanonicalRightDTO>(
    formatRequestString({
      entityUrl: `/api/canonical-rights/${code}`,
      params: {
        filters: {
          includeChildCustomEvents,
          dataRole,
        },
      },
    }),
  )
