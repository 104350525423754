import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetCustomRightNameResponseBodyDTO } from '@ketch-com/figurehead'

type Params = {
  id: string
}

export const fetchCustomRightName = ({ id }: Params) =>
  API.get<GetCustomRightNameResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/custom-right-names/${id}`,
    }),
  )
