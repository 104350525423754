import React, { useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { relativePath } from 'utils/routing/relativePath'
import useLocation from 'react-use/lib/useLocation'
import { Button, ListLayout, TabSegmented, TabSegmentedGroup } from '@ketch-com/deck'
import { Box, Tooltip } from '@mui/material'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { FormMode } from 'interfaces/formModes/formMode'
import { useIsExperienceServerAdmin } from '../hooks'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks'

enum FormsTab {
  Templates = 'templates',
  Fields = 'fields',
}

export const Forms: React.FC = () => {
  const location = useLocation()
  const { pathname } = location
  const navigate = useNavigate()
  const [currentTab, setCurrentTab] = useState(0)
  const { isEntitled } = useIsEntitled()

  const { isUserExperienceServerAdmin } = useIsExperienceServerAdmin()
  const isEntitledToExpServerRights = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)
  const isCreateFormDisabled = !isUserExperienceServerAdmin || !isEntitledToExpServerRights
  const isCreateFieldDisabled = !isUserExperienceServerAdmin

  /* Redirect to "templates" tab as it's the default view per product  */
  useEffect(() => {
    if (location.pathname === '/deployment/forms') {
      navigate(RoutesManager.deployment.forms.templates.root.getURL())
    }
  }, [location, navigate])

  const tabs = useMemo(
    () => [
      {
        label: 'Forms',
        icon: 'OFile',
        link: {
          pathname: relativePath(RoutesManager.deployment.forms.templates.root.getURL(), '/deployment/forms/'),
        },
      },
      {
        label: 'Fields',
        icon: 'OStacksView',
        link: {
          pathname: relativePath(RoutesManager.deployment.forms.fields.root.getURL(), '/deployment/forms/'),
        },
      },
    ],
    [],
  )

  const selectedTab = window.location.pathname.endsWith('forms/templates') ? FormsTab.Templates : FormsTab.Fields

  const handleSegmentTabChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    if (newValue === FormsTab.Templates) {
      navigate(RoutesManager.deployment.forms.templates.root.getURL())
    } else {
      navigate(RoutesManager.deployment.forms.fields.root.getURL())
    }
  }

  const handleCreateNewFormTemplateOnClick = () => {
    const targetUrl = RoutesManager.deployment.forms.templates.upsert.root.getURL({
      id: NEW_ITEM_ID,
      formMode: FormMode.CREATE,
    })

    navigate(targetUrl)
  }

  const handleCreateNewFormFieldOnClick = () => {
    navigate(
      RoutesManager.deployment.forms.fields.upsert.root.getURL({
        id: NEW_ITEM_ID,
        formMode: FormMode.CREATE,
      }),
    )
  }

  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (pathname && pathname.includes(tab.link.pathname)) {
        setCurrentTab(index)
      }
    })
  }, [currentTab, pathname, tabs])

  return (
    <ListLayout
      headerTitle={''}
      customHeaderContentComponent={
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: '-24px' }}>
          <TabSegmentedGroup
            canDeselectCurrentValue={false}
            value={selectedTab}
            exclusive
            onChange={handleSegmentTabChange}
            size="small"
          >
            <TabSegmented value={FormsTab.Templates} key="1">
              All Forms
            </TabSegmented>
            <TabSegmented value={FormsTab.Fields} key="2">
              Form Fields
            </TabSegmented>
          </TabSegmentedGroup>
          {selectedTab === FormsTab.Templates ? (
            <Tooltip
              placement="bottom"
              title={isCreateFormDisabled ? 'You do not have permission to create Forms' : ''}
            >
              <span>
                <Button
                  color="primary"
                  size="medium"
                  onClick={handleCreateNewFormTemplateOnClick}
                  disabled={isCreateFormDisabled}
                >
                  Create Form
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Tooltip
              placement="bottom"
              title={isCreateFieldDisabled ? 'You do not have permission to create Fields' : ''}
            >
              <span>
                <Button onClick={handleCreateNewFormFieldOnClick} disabled={isCreateFieldDisabled}>
                  Create Field
                </Button>
              </span>
            </Tooltip>
          )}
        </Box>
      }
    >
      <Outlet />
    </ListLayout>
  )
}
