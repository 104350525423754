import { useLocation, useParams } from 'react-router-dom'

// api
import { useStack } from 'api/stacks/queries/useStack'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'

// components
import { ViewLayout } from 'components/ui-layouts/viewLayout/ViewLayout'
import { ViewLayoutHeader } from 'components/ui-layouts/viewLayout/ViewLayoutHeader'
import { PurposeStackActions } from './components/PurposeStackActions'
import { ViewLayoutContent } from 'components/ui-layouts/viewLayout/ViewLayoutContent'
import { PurposeStackViewRoutes } from './PurposeStackViewRoutes'

// utils
import { RoutesManager } from 'utils/routing/routesManager'
import { relativePath } from 'utils/routing/relativePath'

// types
type Props = {}

export const PurposeStackViewContainer: React.FC<Props> = () => {
  // hooks
  const { code } = useParams<{ code: string }>()
  const { search } = useLocation()

  // services
  const { data: purposeStack, isLoading } = useStack({ params: { code: code!, includeMetadata: true } })
  const { data: languages, isLoading: isLanguagesLoading } = useOrganizationLanguages()
  const filteredLanguages = languages.filter(l => l.enabled)
  // component utils
  const breadcrumbs = [
    { title: 'Consent & Rights' },
    { title: 'Purpose Stacks', link: RoutesManager.deployment.purposeStacks.root.getURL() },
    {
      title: purposeStack?.name || 'Loading...',
      link: RoutesManager.deployment.purposeStacks.view.root.getURL({ code }),
    },
  ]

  const tabs = [
    {
      id: 'overview',
      title: 'Overview',
      link: {
        pathname: relativePath(RoutesManager.deployment.purposeStacks.view.overview.root.pattern),
        search,
      },
    },
  ]

  if (filteredLanguages.length > 0) {
    tabs.push({
      id: 'translations',
      title: 'Translations',
      link: {
        pathname: relativePath(RoutesManager.deployment.purposeStacks.view.translations.root.pattern),
        search,
      },
    })
  }

  return (
    <ViewLayout breadcrumbs={breadcrumbs}>
      <ViewLayoutHeader
        title={purposeStack?.name}
        backTo={RoutesManager.deployment.purposeStacks.root.getURL()}
        actions={<PurposeStackActions purposeStack={purposeStack} />}
      />
      <ViewLayoutContent tabs={tabs}>
        <PurposeStackViewRoutes
          languages={filteredLanguages}
          purposeStack={purposeStack}
          isLoading={isLoading && isLanguagesLoading}
        />
      </ViewLayoutContent>
    </ViewLayout>
  )
}
