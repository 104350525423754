import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { CreateRelationshipRequest, CreateRelationshipResp } from 'pages/assetManager/interfaces'

type Params = APIRequestParams<{
  data: CreateRelationshipRequest
}>

export const createRelationshipMapping = ({ data }: Params) => {
  return API.post<CreateRelationshipResp>(
    formatRequestString({
      entityUrl: `/api/asset-manager/relationships`,
    }),
    data,
  )
}
