import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useCookie } from 'api/cookies/queries/useCookie'
import { useCookieVersion } from 'api/cookies/queries/useCookieVersion'
import { useCookieVersions } from 'api/cookies/queries/useCookieVersions'
import { CookieView } from 'pages/policyCenter/cookies/view/CookieView'

export const CookieViewContainer: React.FC = () => {
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()
  const { queries } = useQueryParams<{ version?: string }>()
  const { version } = queries

  const handleRedirectToCookiesList = () => {
    navigate(RoutesManager.policyCenter.cookies.root.getURL())
  }

  const { data: cookie, isLoading: isCookieLoaded } = useCookie({
    enabled: !version,
    params: {
      cookieCode: code!,
      includeIssues: true,
      includeMetadata: true,
    },
    onError: handleRedirectToCookiesList,
  })

  const { data: cookieVersion, isLoading: isCookieVerionLoaded } = useCookieVersion({
    enabled: !!version,
    params: {
      cookieCode: code!,
      version: version!,
    },
    onError: handleRedirectToCookiesList,
  })

  const { data: versions, isLoading: isVersionsLoading } = useCookieVersions({
    params: {
      cookieCode: code!,
      includeMetadata: true,
      ordering: {
        version: 'desc',
      },
    },
  })

  const isReady = !isVersionsLoading && version ? !isCookieVerionLoaded : !isCookieLoaded

  return <CookieView isReady={isReady} cookie={version ? cookieVersion : cookie} versions={versions} />
}
