import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useClearSearchParamsOnMount = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const debugValue = searchParams.get('debug')
    const newSearchParams = new URLSearchParams()

    if (debugValue) newSearchParams.set('debug', debugValue)

    setSearchParams(newSearchParams.toString())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
