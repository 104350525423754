import React, { useContext, useMemo } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FieldArray, useFormikContext, ErrorMessage, getIn } from 'formik'
import { DropListButton, TextInput, Button, Icon, IconDictionary, theme } from '@ketch-com/deck'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { experienceUpsertFormKeys } from '../../../utils/useExperienceValidationSchema'
import { SubscriptionListItem } from '@ketch-sdk/ketch-types'
import { useSubscriptionTopics } from '../../../utils/useSubscriptionTopics'
import { ExperienceUpsertContext } from '../../../context/ExperienceUpsertContext'

const SelectSubscriptionTopic = styled(Box)(({ theme }) => ({
  borderRadius: 11,
  backgroundColor: theme.palette.sphereFaded.main,
  padding: theme.spacing(1.5),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const SelectedSubscriptionTopic = styled(Box)(({ theme }) => ({
  borderRadius: 11,
  backgroundColor: theme.palette.fadedGrey.main,
  padding: theme.spacing(1.5),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1.5),
}))

export const SubscriptionTopicsList: React.FC = () => {
  const {
    isSubscriptionTopicsLoading,
    subscriptionTopics,
    setSubscriptionsTopicInputValue,
    selectedSubscriptionsTopic,
    setSelectedSubscriptionsTopic,
  } = useSubscriptionTopics()

  const { values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext<ExperienceV2DTO>()
  const { allSubscriptionTopics } = useContext(ExperienceUpsertContext)

  const items: SubscriptionListItem[] = getIn(values, experienceUpsertFormKeys.preferenceSubscriptionsTabListItems)
  const isTouched = getIn(touched, experienceUpsertFormKeys.preferenceSubscriptionsTabListItems)
  const errorMsgs = getIn(errors, experienceUpsertFormKeys.preferenceSubscriptionsTabListItems)

  // Build a mapping of subscription topic code to name
  const codeNameMap = useMemo(() => {
    return allSubscriptionTopics?.topics?.reduce(
      (acc, obj) => {
        acc[obj.code || ''] = obj.name
        return acc
      },
      {} as Record<string, string>,
    )
  }, [allSubscriptionTopics?.topics])

  return (
    <Box>
      <Box>
        <ErrorMessage name={experienceUpsertFormKeys.preferenceSubscriptionsTabListItems}>
          {msg => {
            if (Array.isArray(msg)) {
              const errorMessage = msg.filter(element => element)?.[0]?.name || ''
              if (errorMessage)
                return (
                  <Box mb={2}>
                    <Typography variant="body" color="chileanFire.main">
                      {errorMessage}
                    </Typography>
                  </Box>
                )
            }
          }}
        </ErrorMessage>
        <FieldArray
          name={experienceUpsertFormKeys.preferenceSubscriptionsTabListItems}
          validateOnChange={false}
          render={arrayHelpers => {
            return (
              <Box>
                <DragDropContext
                  onDragEnd={(result: DropResult) => {
                    if (!result.destination) return

                    const startIndex = result.source.index
                    const endIndex = result.destination.index

                    const newOrder = Array.from(items)
                    const [removed] = newOrder.splice(startIndex, 1)
                    newOrder.splice(endIndex, 0, removed)

                    setFieldValue(experienceUpsertFormKeys.preferenceSubscriptionsTabListItems, newOrder, false)
                  }}
                >
                  <Droppable droppableId="droppable">
                    {provided => {
                      const { innerRef, droppableProps } = provided
                      return (
                        <Box {...droppableProps} ref={innerRef}>
                          {items.map((topic, topicIndex) => {
                            const isDragDisabled = items.length === 1

                            return (
                              <Draggable
                                key={topic?.code}
                                draggableId={topic?.code || ''}
                                index={topicIndex}
                                isDragDisabled={isDragDisabled}
                              >
                                {provided => {
                                  return (
                                    <SelectedSubscriptionTopic
                                      sx={{
                                        backgroundColor: ({ palette }) =>
                                          !topic?.code ? palette.Error.PrimaryLight : null,
                                      }}
                                      ref={provided.innerRef}
                                      key={topic?.code}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Box display="flex" alignItems="center" gap={1}>
                                        <Icon
                                          name={IconDictionary.ODragHandle}
                                          iconColor={theme.palette.fadedDarkGrey.main}
                                        />
                                        <Typography variant="label">
                                          {codeNameMap && topic?.code
                                            ? codeNameMap[topic.code]
                                            : `Subscription Deleted (code: ${topic?.code})`}
                                        </Typography>
                                      </Box>
                                      <Button
                                        variant="icon"
                                        color="white"
                                        onClick={() => {
                                          arrayHelpers.remove(topicIndex)
                                        }}
                                      >
                                        <Icon name={IconDictionary.FBin} width={16} height={16} />
                                      </Button>
                                    </SelectedSubscriptionTopic>
                                  )
                                }}
                              </Draggable>
                            )
                          })}

                          {provided.placeholder}
                        </Box>
                      )
                    }}
                  </Droppable>
                </DragDropContext>

                <SelectSubscriptionTopic>
                  <DropListButton
                    value={selectedSubscriptionsTopic}
                    size="small"
                    sx={{ width: 200 }}
                    loading={isSubscriptionTopicsLoading}
                    filterOptions={x => x}
                    clearOnBlur
                    noOptionsText="No Subscriptions"
                    getOptionDisabled={option => items?.some(topic => topic?.code === option?.code)}
                    onChange={(e, topic, reason) => {
                      if (reason === 'clear') {
                        setSelectedSubscriptionsTopic(null)
                      } else {
                        setSelectedSubscriptionsTopic(topic)
                      }
                    }}
                    onInputChange={(e, inputVal) => {
                      setSubscriptionsTopicInputValue(inputVal)
                    }}
                    options={
                      subscriptionTopics?.topics?.filter(topic => !items.some(item => item.code === topic.code)) || []
                    }
                    renderInput={params => (
                      <TextInput
                        {...params}
                        error={isTouched && Boolean(errorMsgs)}
                        errorMessage={typeof errorMsgs === 'string' ? errorMsgs : ''}
                        onBlur={() => {
                          setFieldTouched(experienceUpsertFormKeys.preferenceSubscriptionsTabListItems, true, false)
                        }}
                        InputProps={{
                          ...params.InputProps,
                        }}
                        placeholder="Select Subscriptions"
                        variant="outlined"
                      />
                    )}
                    renderOption={(props, topic) => {
                      return (
                        <Box
                          component="li"
                          {...props}
                          key={topic?.code}
                          display="flex"
                          flexDirection="column"
                          gap={0.5}
                          py={2}
                        >
                          <Typography alignSelf="flex-start" variant="body">
                            {topic?.name}
                          </Typography>
                          <Typography alignSelf="flex-start" variant="footnote" color="darkDuskFaded.main">
                            {topic?.code}
                          </Typography>
                        </Box>
                      )
                    }}
                    getOptionLabel={option => option?.name || ''}
                    isOptionEqualToValue={(option, value) => option?.code === value?.code}
                  />
                  <Button
                    color="secondary"
                    disabled={!selectedSubscriptionsTopic}
                    onClick={() => {
                      arrayHelpers.push({
                        code: selectedSubscriptionsTopic?.code,
                        type: 1,
                      })
                      setSubscriptionsTopicInputValue('')
                      setSelectedSubscriptionsTopic(null)
                    }}
                  >
                    Add
                  </Button>
                </SelectSubscriptionTopic>
              </Box>
            )
          }}
        />
      </Box>
    </Box>
  )
}
