import React, { useEffect, useState } from 'react'

import { useDownloadFile } from 'api/files/mutations/useDownloadFile'
import Box from '@mui/material/Box'

import { showToast } from 'components/modals/AlertComponent'

import { FileInfoDTO } from 'interfaces/files/fileInfo'

import { useFormikContext } from 'formik'
import { convertFileInfoDTOLocalToFileInfoDTO } from 'utils/api/serializeFileInfoDTO'
import { Chip, FileExtensionIcon, Icon, InfoRow } from '@ketch-com/deck'
import { MAX_FILE_UPLOAD_SIZE_4_MB } from 'utils/constants/maxFileUploadSize'
import { FormDropZoneWithUpload } from 'components/form/FormDropZone/FormDropZoneWithUpload'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type Props = {
  attachments?: FileInfoDTO[] | undefined
  canEdit: boolean | undefined
  disableInEditDownload?: boolean
  infoRowTitle: string
  isError?: boolean
  isRequired: boolean | undefined
  name: string
  onDelete?: (file: FileInfoDTO) => void
  onUploadComplete?: (files: FileInfoDTO[]) => void
  showFileData?: boolean
  showOptionalLabel?: boolean
  showRequiredLabel?: boolean
  showUploadDates?: boolean
  showUploadSizes?: boolean
  uploadContext: any
  multiple?: boolean
}

export const InfoRowInlineUpload: React.FC<Props> = ({
  attachments = [],
  canEdit = false,
  disableInEditDownload = false,
  infoRowTitle,
  isError = false,
  isRequired = false,
  name = '',
  onDelete = null,
  onUploadComplete = null,
  showFileData,
  showOptionalLabel,
  showRequiredLabel = false,
  showUploadDates,
  showUploadSizes,
  uploadContext = {},
  multiple = true,
}) => {
  // hooks
  const { isSubmitting, errors, touched, setTouched }: any = useFormikContext()
  const [isEditing, setIsEditing] = useState(false)
  const [showDropMessage, setShowDropMessage] = useState(false)

  useEffect(() => {
    if (isRequired && !!attachments.length) {
      setShowDropMessage(false)
    }
  }, [attachments, isRequired])

  // api
  const { mutateAsync: handleDownloadFile, isLoading: isDownloadingFile } = useDownloadFile()

  // event handlers
  const handleDownloadOnClick = async (attachment: FileInfoDTO) => {
    try {
      const { data } = await handleDownloadFile({
        params: {
          fileUrl: attachment?.download_url || '',
        },
      })
      let a: HTMLAnchorElement | null = document.createElement('a')
      a.href = URL.createObjectURL(data)
      a.download = attachment?.name || ''
      a.click()
      a = null
    } catch (error) {
      showToast({
        content: `Unable to download file ${attachment.name}`,
        type: 'error',
      })
    }
  }

  const handleEditMode = () => {
    if (!canEdit) return
    setIsEditing(!isEditing)
    setTouched({ ...touched, [name]: false })
  }

  // add edit mode listener for abstracted edit button
  useEffect(() => {
    if (!canEdit) {
      setIsEditing(false)
    }
  }, [canEdit])

  const title = `${infoRowTitle} ${showRequiredLabel ? '(Required)' : showOptionalLabel ? '(Optional)' : ''}`

  return (
    <InfoRow
      title={title}
      isEditable={canEdit}
      isEditing={isEditing}
      isLoading={isSubmitting}
      isValidation={isError}
      onEditChange={handleEditMode}
      onAcceptChange={() => {
        setIsEditing(false)
      }}
      onCancelChange={() => {
        setIsEditing(false)
      }}
      isEmpty={!attachments.length}
    >
      <Box display="flex" justifyContent="space-between" width="100%" maxWidth="500px">
        {/* attachment list */}
        {!isEditing ? (
          <Box width="calc(100% - 24px)" display="flex" flexDirection="column" gap={1}>
            {!!attachments.length ? (
              attachments.map(attachment => {
                const file = convertFileInfoDTOLocalToFileInfoDTO(attachment)
                const handleOnClick = () => handleDownloadOnClick(attachment)
                return (
                  <Box display="flex" alignItems="center" key={attachment.ID}>
                    <Chip
                      label={attachment.name}
                      size="medium"
                      icon={<FileExtensionIcon iconVariant="filled" extension={file?.contentType} />}
                      onDelete={handleOnClick}
                      onClick={handleOnClick}
                      deleteIcon={<Icon name="ODownload" />}
                      clickable
                      disabled={isDownloadingFile}
                    />
                  </Box>
                )
              })
            ) : (
              <EmptyValueRenderer />
            )}
          </Box>
        ) : (
          <Box display="flex" justifyContent="space-between" maxWidth="485px">
            <FormDropZoneWithUpload
              disabled={isSubmitting}
              dropMessage={
                showDropMessage || (errors?.[name] === 'Required' && touched?.[name])
                  ? 'Please add attachments'
                  : undefined
              }
              hideUploadedFilesView={false}
              hideErrorText={true}
              maxSize={MAX_FILE_UPLOAD_SIZE_4_MB}
              name={name}
              onDelete={file => {
                onDelete?.(file)
              }}
              onUploadComplete={files => onUploadComplete?.(files)}
              required={isRequired}
              showFileData={showFileData}
              uploadContext={uploadContext}
              showUploadSizes={showUploadSizes}
              showUploadDates={showUploadDates}
              disableDownload={disableInEditDownload}
              multiple={multiple}
            />
          </Box>
        )}
      </Box>
    </InfoRow>
  )
}
