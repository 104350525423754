import React, { useContext } from 'react'
import { BuilderContext } from '../../../Builder'
import { getIn } from 'formik'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { Box, Typography, styled } from '@mui/material'
import { KetchBadge } from '../../KetchBadge'
import { GpcBadge } from '../../GpcBadge'
import { ItemStyle } from '@ketch-sdk/ketch-types'
import { BUILDER_CONSTANTS, ModalExperienceBuilderSection } from '../../../utils/enums'
import { clamp } from 'lodash'
import { SelectableSection } from '../../SelectableSection'

const FooterBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 24px 24px 24px',
})

const RightBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
})

const ButtonBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '86px',
  minHeight: '40px',
  padding: '8px',
})

export const ModalFooter: React.FC = () => {
  const { themeConfig, experienceConfig, isTheme } = useContext(BuilderContext)

  // Theme
  const backgroundColor = getIn(themeConfig, themeUpsertFormKeys.modalFooterBackgroundColor)
  const buttonStyle = getIn(themeConfig, themeUpsertFormKeys.modalFooterActionButtonStyle)
  const buttonColor = getIn(themeConfig, themeUpsertFormKeys.modalFooterActionButtonBackgroundColor)
  const buttonTextColor = getIn(themeConfig, themeUpsertFormKeys.modalFooterActionButtonTextColor)
  const showKetchBadge = getIn(themeConfig, themeUpsertFormKeys.modalFooterKetchBadgeVisible)
  const borderRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.modalContainerCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'

  const modalFooterActionButtonCornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.modalFooterActionButtonCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'

  // Experience
  const showGpcBadge = getIn(experienceConfig, experienceUpsertFormKeys.modalFooterGpcBadgeVisible)
  const buttonText = getIn(experienceConfig, experienceUpsertFormKeys.modalFooterActionButtonText)

  return (
    <FooterBox
      sx={{
        background: backgroundColor,
        borderBottomLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
      }}
    >
      <Box>{showKetchBadge && <KetchBadge contrastBackgroundColor={backgroundColor} />}</Box>
      <SelectableSection section={isTheme ? undefined : ModalExperienceBuilderSection.Footer}>
        <RightBox>
          {showGpcBadge && <GpcBadge contrastBackgroundColor={backgroundColor} />}
          <ButtonBox
            sx={{
              border: `1.5px solid ${buttonColor}`,
              background: buttonStyle === ItemStyle.Filled ? buttonColor : '',
              color: buttonTextColor,
              borderRadius: modalFooterActionButtonCornerRadius,
            }}
          >
            <Typography variant={'label'} sx={{ maxWidth: '200px', wordWrap: 'break-word' }}>
              {buttonText}
            </Typography>
          </ButtonBox>
        </RightBox>
      </SelectableSection>
    </FooterBox>
  )
}
