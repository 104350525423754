import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { getInstalledDataSystemAssets } from '../fetchers/getInstalledDataSystemAssets'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.assets,
  queryFn: getInstalledDataSystemAssets,
  select: res => res?.data,
})

export type InstalledDataSystemAssetsQueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useInstalledDataSystemAssets = (config?: InstalledDataSystemAssetsQueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
