import * as Yup from 'yup'

// utils
import { useSubjectTypeCodeAvailable } from 'api/dataSubjectTypes/queries/useSubjectTypeCodeAvailable'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { useMemo } from 'react'

interface IValidationParams {
  isEditMode?: boolean
}

export const useDataSubjectTypeFormValidation = ({ isEditMode = false }: IValidationParams) => {
  const { mutateAsync: handleSubjectTypeCodeValid } = useSubjectTypeCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleSubjectTypeCodeValid({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(
    () =>
      Yup.object().shape({
        ...(!isEditMode && {
          code: codeValidationSchema,
        }),
        isCustomType: Yup.boolean(),
        name: Yup.string().required('This field is required'),
        requiresUserInput: Yup.boolean(),
        translations: Yup.object({}),
      }),
    [isEditMode, codeValidationSchema],
  )
}
