/** @jsxRuntime classic */
/** @jsx jsx */
import { createElement, useRef, useEffect } from 'react'
import { h, render } from 'preact'
import {
  LanyardProvider,
  LanyardBannerExperience,
  LanyardBannerExperienceProps,
  fullConfigBeta,
  consentsMock,
  vendorConsentMock,
  showPreferenceOptionsMock,
} from '@ketch-com/lanyard'

import { ThemeDTO, ThemeFormDTO } from 'interfaces/themes-v2/theme'

const jsx = createElement

type Props = {
  wrapperClassName?: string
  theme: ThemeDTO | ThemeFormDTO
} & LanyardBannerExperienceProps

export type BannerPreviewProps = Props

export const BannerPreview: React.FC<Props> = ({ wrapperClassName, theme, ...bannerProps }) => {
  const ref = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    const jsx = h

    render(
      <LanyardProvider
        config={{ ...fullConfigBeta, theme } as any}
        consent={consentsMock?.purposes}
        handleUpdateConsent={() => console.info('handleUpdateConsent')}
        setViewMode={() => console.info('setViewMode')}
        vendorConsent={vendorConsentMock}
        viewMode="experiences.consent.banner"
        showPreferenceOptions={showPreferenceOptionsMock}
      >
        <LanyardBannerExperience {...bannerProps} />
      </LanyardProvider>,
      ref.current,
    )
  })

  return <div className={wrapperClassName} ref={ref} />
}
