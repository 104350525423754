import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { DeleteIdentitySpaceModal } from 'components/modals/identitySpaces/DeleteIdentitySpaceModal'
import { Typography, Box } from '@mui/material'
import { TooltipButton } from '@ketch-com/deck'

type Props = {
  isLatestVersion: boolean
  identitySpace: IdentitySpaceDTO
}

export const IdentitySpaceViewActions: React.FC<Props> = ({ isLatestVersion, identitySpace }) => {
  const navigate = useNavigate()

  const { isPermitted } = useIsPermitted()
  const isPermittedToCreateIdentitySpace = isPermitted([PERMISSIONS.IDSP_WRITE])

  const upsertLink = RoutesManager.settings.identitySpaces.upsert.root.getURL({ code: identitySpace?.code })

  const [isDeleteOpen, setIsDeleteOpen] = useState(false)

  const onEditButtonClick = () => {
    navigate(upsertLink)
  }

  if (!isLatestVersion) {
    return null
  }

  return (
    <>
      <Box display="flex" gap={1.5}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="smallBody" color="darkDuskFaded.main">
            Last Updated
          </Typography>

          <Typography color="darkDusk.main" variant="smallBody">
            <FormattedDateRenderer date={identitySpace.metadata?.createdAt} />
          </Typography>
        </Box>

        {isPermittedToCreateIdentitySpace && (
          <TooltipButton
            disabled={identitySpace?.managed}
            title={identitySpace?.managed ? 'Cannot delete Identifiers that are managed by installed apps' : ''}
            size="large"
            color="tertiary"
            onClick={() => setIsDeleteOpen(true)}
          >
            Delete
          </TooltipButton>
        )}

        {isPermittedToCreateIdentitySpace && (
          <TooltipButton
            disabled={identitySpace?.managed}
            title={identitySpace?.managed ? 'Cannot edit Identifiers that are managed by installed apps' : ''}
            onClick={onEditButtonClick}
            color="secondary"
            size="large"
          >
            Edit
          </TooltipButton>
        )}
      </Box>

      {isDeleteOpen && (
        <DeleteIdentitySpaceModal
          identitySpaceCode={identitySpace.code}
          onCancel={() => {
            setIsDeleteOpen(false)
          }}
          onSubmit={() => {
            navigate(RoutesManager.settings.identitySpaces.root.getURL())
          }}
        />
      )}
    </>
  )
}
