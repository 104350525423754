import { useParams } from 'react-router-dom'
import { UrlParams } from '../../../../../policyCenter/processingActivities/view/utils'
import { useProcessingActivity } from 'api/processingActivities/queries/useProcessingActivity'
import { useNavigate } from 'react-router'
import { useState } from 'react'
import { ProcessingActivityDataSystemProcessingStageDTO } from '@ketch-com/figurehead'
import { useDataMapRegions } from 'api/dataMap/queries/useDataMapRegions'

export const useDataMapProcessingActivityUtils = () => {
  const { id } = useParams<UrlParams>()
  const navigate = useNavigate()

  const { data: processingActivity, isLoading: isProcessingActivityLoading } = useProcessingActivity({
    params: { activityId: id || '', includeMetadata: true },
  })

  // Get regions
  const { data: regions, isLoading: isRegionsLoading } = useDataMapRegions({})

  // Data systems filter states
  const [searchString, setSearchString] = useState('')
  const [activityStage, setActivityStage] = useState<ProcessingActivityDataSystemProcessingStageDTO>(
    ProcessingActivityDataSystemProcessingStageDTO.UnspecifiedProcessingActivityDataSystemProcessingStage,
  )

  return {
    id,
    navigate,
    processingActivity,
    isProcessingActivityLoading,
    regions,
    isRegionsLoading,
    searchString,
    setSearchString,
    activityStage,
    setActivityStage,
  }
}

export type DataMapProcessingActivityUtils = ReturnType<typeof useDataMapProcessingActivityUtils>
