import React, { Fragment, useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { DeleteApiKeyModal } from 'components/modals/apiKeys/DeleteApiKeyModal'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

import { WebhookDTO } from '@ketch-com/figurehead'
import { WebhookOverviewModal } from '.'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'

type Props = {
  webhook: WebhookDTO
  setHookToDeleteId: (value: React.SetStateAction<string>) => void
  setHookToEditId: (value: React.SetStateAction<string>) => void
}

export const WebhookActionsCellRenderer: React.FC<Props> = ({ webhook, setHookToDeleteId, setHookToEditId }) => {
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isHookOverviewModalOpen, setIsHookOverviewModalOpen] = useState(false)

  const isPermittedToReadWebhooks = isPermitted(PERMISSIONS.WEBHOOK_READ)
  const isPermittedToEditWebhooks = isPermitted(PERMISSIONS.WEBHOOK_WRITE)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <Button size="medium" variant="iconSubtle" onClick={handleClickListItem}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
        {isPermittedToReadWebhooks && (
          <ActionSheetItem
            onClick={e => {
              setIsHookOverviewModalOpen(true)
              handleClose(e)
            }}
          >
            View
          </ActionSheetItem>
        )}
        {isPermittedToReadWebhooks && isPermittedToEditWebhooks && <ActionSheetItem divider />}
        {isPermittedToEditWebhooks && (
          <ActionSheetItem
            onClick={e => {
              setHookToEditId(webhook?.id || '')
              handleClose(e)
            }}
          >
            Edit
          </ActionSheetItem>
        )}
        {isPermittedToEditWebhooks && <ActionSheetItem divider />}
        {isPermittedToEditWebhooks && (
          <ActionSheetItem
            onClick={e => {
              setHookToDeleteId(webhook?.id || '')
              handleClose(e)
            }}
          >
            Delete
          </ActionSheetItem>
        )}
      </ActionSheetWrapper>

      {isDeleteModalOpen && (
        <DeleteApiKeyModal
          apiKeyId={webhook?.id || ''}
          onCancel={() => setIsDeleteModalOpen(false)}
          onSubmit={async () => {
            await queryClient.refetchQueries(ApiQueryKeys.apiKeysPaginated)
            setIsDeleteModalOpen(false)
          }}
        />
      )}

      {isHookOverviewModalOpen ? (
        <WebhookOverviewModal webhook={webhook} onCancel={() => setIsHookOverviewModalOpen(false)} />
      ) : null}
    </Fragment>
  )
}
