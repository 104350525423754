import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'

export const getFilterLabeledOptions = ({
  options,
  attributeEffects,
}: {
  options: ManageLabelModalLabel[]
  attributeEffects: any
}) => {
  const collectUsedOptions = attributeEffects
    .filter((effect: any) => effect?.criteria === 'labeled')
    .map(
      (effect: any) =>
        `${effect?.labelName?.code || ''}${effect?.labelName?.name || ''}${effect?.labelName?.value || ''}`,
    )
    .filter((el: any) => typeof el === 'string' && el !== '')
  const filteredOptions = options.filter(
    (option: ManageLabelModalLabel) =>
      !collectUsedOptions.includes(`${option?.code || ''}${option?.name || ''}${option?.value || ''}`),
  )
  return filteredOptions
}
