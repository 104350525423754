import React from 'react'
import moment from 'moment'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'

import { NotificationDTO } from '@ketch-com/figurehead'
import { NotificationCardTextWithLinksReplaced } from '.'
import { Typography } from '@mui/material'

const NewNotificationIcon = styled(Box)(({ theme }) => ({
  width: 6,
  height: 6,
  borderRadius: '50%',
  background: theme.palette.heliotrope.main,
  marginRight: theme.spacing(1.75),
}))

type Props = {
  isNew?: boolean
  isLast?: boolean
  isFirst?: boolean
  notification?: NotificationDTO
}

export const NotificationCard: React.FC<Props> = ({ notification, isLast, isNew = false }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderBottom: ({ palette }) => (!isLast ? `1px solid ${palette.iron.main}` : 'none'),
        pl: 0,
        pr: 1,
        pt: 1.5,
        pb: isLast ? 3 : 1.5,
      }}
    >
      {isNew && <NewNotificationIcon />}
      <Box>
        <Box>
          <NotificationCardTextWithLinksReplaced notification={notification} isNew={isNew} />
        </Box>
        <Box>
          <Typography sx={{ fontStyle: 'capitalize' }} color="darkDuskFaded.main">
            {moment((notification?.metadata?.createdAt || 0) * 1000).fromNow()}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
