import { useMemo, useState } from 'react'

// components
import Box from '@mui/material/Box'
import { Button, Chip, FileExtensionIcon, Icon, ListItem, MegaFilterAccordion, TextInput, theme } from '@ketch-com/deck'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { AccordionDetails, AccordionSummary, Tooltip } from '@mui/material'

// utils
import { useAuth } from 'utils/hooks/useAuth'
import { debounce } from 'lodash'

// types
import { DataReviewStepDTO, DataReviewFileDTO } from '@ketch-com/figurehead'
import { FormFile } from 'api/dataReview/fetchers/uploadDataReviewFiles'
import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'

type Props = {
  activeFile?: FormFile
  files: FormFile[]
  isComplete: boolean
  isFileFetching: boolean
  isSaving: boolean
  onFileChange: (file: any) => void
  setShowModal: (showModal: boolean) => void
  steps: DataReviewStepDTO[]
  workflowExecutionStep: WorkflowExecutionStepDTO
}

export const Sidebar = ({
  activeFile,
  files,
  isComplete,
  isFileFetching,
  isSaving,
  onFileChange,
  setShowModal,
  steps,
  workflowExecutionStep,
}: Props) => {
  const { userData } = useAuth()
  const [collapsed, setCollapsed] = useState<Array<string | undefined>>([])
  const [search, setSearch] = useState('')
  const [textValue, setTextValue] = useState<string>()

  const handleCollapse = (appCode: string | undefined) => {
    let newCollapsed
    if (collapsed.includes(appCode)) {
      newCollapsed = collapsed.filter((c: string | undefined) => c !== appCode)
    } else {
      newCollapsed = [...collapsed, appCode]
    }
    setCollapsed(newCollapsed)
  }

  const getRedactionCount = (fileId: string) => {
    if (fileId === activeFile?.id) {
      return activeFile?.redactions?.length || 0
    }
    const file = files?.find((f: FormFile) => f?.id === fileId)
    return file?.redactions?.length || 0
  }

  // If App is present but files do not match the search we don't want to show the app itself
  const getFilteredAppsIfFilesAreEmpty = (steps: DataReviewStepDTO[]) =>
    (steps || []).filter((app: DataReviewStepDTO) => {
      let matchingFilesPresent = false

      app?.files?.forEach?.((file: DataReviewFileDTO) => {
        if (file?.name?.toLowerCase().includes(search.trim().toLowerCase())) {
          matchingFilesPresent = true
          return
        }
      })

      return matchingFilesPresent
    })

  const getAppName = (app: DataReviewStepDTO) =>
    app?.code?.includes('ManualTaskActivity')
      ? 'Manually Uploaded (Tasks)'
      : app?.code?.includes('Review')
      ? 'Manually Uploaded'
      : app?.name

  const getAppsBySearchFilter = (steps: DataReviewStepDTO[]) =>
    (steps || []).filter((app: DataReviewStepDTO) =>
      !!search ? getAppName(app)?.toLowerCase().includes(search.trim().toLowerCase()) : app,
    )

  const getAppFilesBySearchFilter = (files: DataReviewFileDTO[]) => {
    const filteredFiles = files?.filter((file: DataReviewFileDTO) =>
      !!search ? file?.name?.toLowerCase().includes(search.trim().toLowerCase()) : file,
    )

    return getAppsBySearchFilter(steps).length > 0 ? files : filteredFiles
  }

  const filteredApps =
    getAppsBySearchFilter(steps).length > 0 ? getAppsBySearchFilter(steps) : getFilteredAppsIfFilesAreEmpty(steps)
  const isAssignee = workflowExecutionStep?.assignee === userData.userId
  const isApprover = workflowExecutionStep?.approver === userData.userId
  const canUploadFile = (isApprover || isAssignee) && !isComplete

  const debouncedSearch = useMemo(
    () =>
      debounce((v: string) => {
        setSearch(v)
      }, 100),
    [setSearch],
  )

  return (
    <Box display="flex" bgcolor="white.main" flexDirection="column" borderRadius="11px" overflow="scroll" width={328}>
      <Box mb={2} px={3} pt={2}>
        {/* TODO: Enable BE search once API is ready */}
        <TextInput
          name="app_doc_search"
          fullWidth
          placeholder="Search for app or document"
          onChange={e => {
            setTextValue(e.target.value)
            debouncedSearch(e.target.value)
          }}
          value={textValue}
          startIcon={<Icon name="OMag" />}
          endIcon={
            !!search && (
              <Icon
                sx={{ cursor: 'pointer' }}
                name="OCross"
                onClick={() => {
                  setSearch('')
                  setTextValue('')
                }}
              />
            )
          }
        />
      </Box>
      <Box flex={1} pl={3} pr={2}>
        {(filteredApps.length > 0 ? filteredApps : steps).map(app => (
          <Box key={app?.code}>
            <MegaFilterAccordion
              expanded={!!collapsed.includes(app?.code)}
              onChange={() => {
                handleCollapse(app?.code || '')
              }}
            >
              <AccordionSummary
                disableTouchRipple
                className="MegaFilterAccordionSummary"
                expandIcon={<Icon name="OArrowCRight" />}
              >
                <Typography variant={!!collapsed.includes(app?.code) ? 'label' : 'body'}>
                  {getAppName(app)}
                  {!!getAppFilesBySearchFilter(app?.files || []).length && (
                    <>
                      {': '}
                      <Typography variant="label" color="sphere.main">
                        {getAppFilesBySearchFilter(app?.files || []).length}
                      </Typography>
                    </>
                  )}
                </Typography>
              </AccordionSummary>
              {/* child list els */}
              {!!getAppFilesBySearchFilter(app?.files || []).length && (
                <AccordionDetails>
                  {getAppFilesBySearchFilter(app?.files || [])?.map((file: DataReviewFileDTO, _index, self) => {
                    return (
                      <ListItem
                        sx={{
                          maxHeight: 'unset',
                          wordBreak: 'break-all',
                        }}
                        key={file.id}
                        onClick={() => (!isFileFetching ? onFileChange(file) : null)}
                        disabled={isSaving}
                        selected={file?.id === activeFile?.id}
                        startElement={
                          <Box mr={1} display="flex" alignItems="center">
                            <FileExtensionIcon iconVariant="filled" extension={file.displayName?.split('.').pop()} />
                          </Box>
                        }
                        title={
                          file?.isExcluded
                            ? `${file?.displayName?.split?.('.')?.[0]} Excluded`
                            : file?.displayName?.split?.('.')?.[0]
                        }
                        endElement={
                          <Box alignItems="center" display="flex">
                            {/* Excluded Icon */}
                            {file?.isExcluded && (
                              <Tooltip title={file?.isExcluded ? 'File Excluded' : ''}>
                                <Box display="flex" alignItems="center">
                                  <Icon name="ODecline" iconColor={theme.palette.doomedGrey.main} />
                                </Box>
                              </Tooltip>
                            )}
                            {/* Redaction Count */}
                            {!!getRedactionCount(file?.id || '') && (
                              <Chip size="small" label={<Icon name="FEdit2" />} />
                            )}
                            {/* Arrow */}
                            {file?.id !== activeFile?.id && <Icon name="OArrowCRight" />}
                          </Box>
                        }
                      />
                    )
                  })}
                </AccordionDetails>
              )}
            </MegaFilterAccordion>
          </Box>
        ))}
      </Box>

      {canUploadFile && (
        <Box pb={2.625} px={3}>
          <Divider
            sx={{
              borderColor: 'iron.main',
              mb: 2,
            }}
          />
          <Button color="tertiary" onClick={() => setShowModal(true)}>
            Upload New Document
          </Button>
        </Box>
      )}
    </Box>
  )
}
