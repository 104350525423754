import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { ConsentExperienceFormValues } from '../utils/consent/interfaces'
import { ConsentExperienceTab } from '../utils/consent/constants'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { ExperienceDTO } from 'interfaces/experiences/experience'
import { ExperienceUrlTypeParam } from 'interfaces/experiences/experienceUrlTypeParam'
import { FormMode } from 'interfaces/formModes/formMode'
import { getConsentInitialValues, useConsentValidationSchema } from '../utils/consent/utils'
import { getPreferenceInitialValues, usePreferenceValidationSchema } from '../utils/preference/utils'
import { mapConsentValuesToPayload } from '../utils/consent/utils'
import { mapPreferenceValuesToPayload } from '../utils/preference/utils'
import { PreferenceExperienceFormValues } from '../interfaces/PreferenceExperienceFormValues'
import { PreferenceExperienceTab } from '../utils/preference/constants'
import { RoutesManager } from 'utils/routing/routesManager'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useAuth } from 'utils/hooks/useAuth'
import { useCreateExperience } from 'api/experiences/mutations/useCreateExperience'
import { useExperience } from 'api/experiences/queries/useExperience'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { useUpdateExperience } from 'api/experiences/mutations/useUpdateExperience'
import { useFormFields } from 'api/formFields/queries/useFormFields'
import { useExperienceBuilderFormStateUtils } from 'store/experienceBuilderFormStateFilterSlice/hooks'
import { useJurisdictionsCanonicalRights } from 'api/rights/queries/useJurisdictionsCanonicalRights'
import { useJurisdictionsRights } from 'api/rights/queries/useJurisdictionsRights'
import { mergeRights } from 'pages/consentAndRights/experiences/upsert/utils/preference/utils/mergeRights'
import { useFormTemplatesInfinite } from 'api/formTemplates/queries/useFormTemplatesInfinite'
import { MaybeNull } from 'interfaces'
import { PreferenceField } from '../utils/common'
import { useSyncTabs } from '.'
import { useSubscriptionTopicsPaginated } from 'api/subscriptions/queries/useSubscriptionTopicsPaginated'

export const useExperienceUpsertUtils = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isEntitled } = useIsEntitled()
  const { userData } = useAuth()
  const { pathname } = useLocation()
  const [activeSidebarTab, setActiveSidebarTab] = useState<string>('')
  const [sidebarActiveTab, setSidebarActiveTab] = useState<MaybeNull<PreferenceField>>(null)

  // redux state
  const { values: reduxValues, resetPersistedReduxValues } = useExperienceBuilderFormStateUtils()
  const shouldRehydrateInProgressEdit = Object.keys(reduxValues).length > 0

  const { code, experienceType, formMode } = useParams<{
    code?: string
    experienceType: ExperienceUrlTypeParam
    formMode: FormMode
  }>()

  // derive constants from params
  const isConsentExperience = experienceType === ExperienceUrlTypeParam.CONSENT
  const isEntitledToJit = isEntitled(ENTITLEMENTS.EXP_SERVER_JIT)
  const isEntitledToConsentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)
  const isEntitledToRights = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)
  const isEntitledToSubscriptions = isEntitled(ENTITLEMENTS.SUBSCRIPTIONS)

  // Sidebar tab state
  const [activeTab, setActiveTab] = useState(
    isConsentExperience ? ConsentExperienceTab.BANNER : PreferenceExperienceTab.OVERVIEW,
  )

  const handleRedirectToList = () => {
    navigate(RoutesManager.deployment.experiences.root.getURL())
  }

  // fetch data
  const { data: nonSystemOrganizationLanguages, isLoading: isOrganizationLanguagesLoading } = useOrganizationLanguages()

  const { data: experience, isLoading: isExperienceLoading } = useExperience({
    enabled: formMode === FormMode.DUPLICATE || formMode === FormMode.EDIT,
    params: {
      experienceCode: code!,
    },
    onSuccess: ({ data }) => {
      if (isConsentExperience) {
        if (!data.experience.consent) {
          handleRedirectToList()
        }
      } else {
        if (!data.experience.preference) {
          handleRedirectToList()
        }
      }
    },
    onError: () => {
      handleRedirectToList()
    },
  })

  const { data: allFormFields, isLoading: isLoadingAllFormFields } = useFormFields({
    itemsPerPage: 1000,
    params: {
      includeMetadata: true,
    },
  })

  const { data: canonicalRights } = useJurisdictionsCanonicalRights()

  const { data: customRights } = useJurisdictionsRights()

  const { data: subscriptionTopics, isLoading: isSubscriptionTopicsLoading } = useSubscriptionTopicsPaginated({
    enabled: isEntitledToSubscriptions,
    params: {
      includeMetadata: true,
      limit: 200,
    },
  })

  const { data: formTemplates, isLoading: isLoadingFormTemplates } = useFormTemplatesInfinite({
    options: {
      itemsPerPage: 1000,
      enabled: true,
    },
    params: {
      ordering: { created_at: 'desc' },
      includeMetadata: true,
    },
  })

  if (experience && experience.preference) {
    const mergedRights = mergeRights({ experience, canonicalRights, customRights })
    experience.preference.rights = mergedRights
  }

  const onUpsertSuccess = async ({ code }: ExperienceDTO) => {
    await queryClient.refetchQueries(ApiQueryKeys.entitlements)
    await queryClient.refetchQueries(ApiQueryKeys.experiences)
    await queryClient.refetchQueries(ApiQueryKeys.experience)
    showToast({ content: 'Experience created', type: 'success' })
    navigate(RoutesManager.deployment.experiences.view.root.getURL({ code }))
  }

  const { mutateAsync: handleCreateExperience } = useCreateExperience({
    onSuccess: ({ data }) => {
      onUpsertSuccess(data.experience)
      resetPersistedReduxValues()
    },
    onError: () => {
      showToast({ content: 'Failed to create experience', type: 'error' })
    },
  })

  const { mutateAsync: handleUpdateExperience } = useUpdateExperience({
    onSuccess: ({ data }) => {
      showToast({ content: 'Experience updated', type: 'success' })
      onUpsertSuccess(data.experience)
      resetPersistedReduxValues()
    },
    onError: () => {
      showToast({ content: 'Failed to update experience', type: 'error' })
    },
  })

  const onSubmit = async (values: ConsentExperienceFormValues | PreferenceExperienceFormValues) => {
    const formData = isConsentExperience
      ? mapConsentValuesToPayload({ values: values as ConsentExperienceFormValues, isEntitledToJit })
      : mapPreferenceValuesToPayload({
          values: values as PreferenceExperienceFormValues,
          isEntitledToConsentAndDisclosure,
          isEntitledToRights,
          isEntitledToSubscriptions,
        })

    await (formMode === FormMode.CREATE || formMode === FormMode.DUPLICATE
      ? handleCreateExperience({
          params: {
            formData,
          },
        })
      : handleUpdateExperience({
          params: {
            experienceCode: code!,
            formData,
          },
        }))
  }

  useSyncTabs({ sidebarActiveTab, setActiveTab })

  useEffect(() => {
    if (!isEditMode) {
      setSidebarActiveTab(PreferenceField.MAIN_TITLE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // only run on mount

  // upsert utils
  const organizationLanguages = useMemo(
    () => nonSystemOrganizationLanguages.filter(({ system }) => !system),
    [nonSystemOrganizationLanguages],
  )

  const isEditMode = formMode === FormMode.EDIT

  const initialValues = useMemo(
    () =>
      shouldRehydrateInProgressEdit
        ? reduxValues
        : isConsentExperience
        ? getConsentInitialValues({
            organizationLanguages,
            experience,
            formMode: formMode!,
            organizationName: userData.organizationName!,
          })
        : getPreferenceInitialValues({
            organizationLanguages,
            experience,
            formMode: formMode!,
            organizationName: userData.organizationName!,

            canonicalRights,
            subscriptionTopics: subscriptionTopics?.topics || [],
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationLanguages, experience, formMode, isConsentExperience, userData, allFormFields, canonicalRights],
  )

  const consentValidationSchema = useConsentValidationSchema({
    isEditMode,
    isEntitledToJit,
    organizationLanguages,
  })

  const preferenceValidationSchema = usePreferenceValidationSchema({
    isEditMode,
    organizationLanguages,
    isEntitledToRights,
    isEntitledToConsentAndDisclosure,
    isEntitledToSubscriptions,
  })

  const breadcrumbs = isEditMode
    ? [
        { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
        { title: 'Experiences', link: RoutesManager.deployment.experiences.root.getURL() },
        {
          title: experience?.name || experience?.code,
          link: RoutesManager.deployment.experiences.view.root.getURL({ code: experience?.code }),
        },
        { title: 'Edit Experience' },
      ]
    : [
        { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
        { title: 'Experiences', link: RoutesManager.deployment.experiences.root.getURL() },
        { title: 'Create New Experience' },
      ]

  const validationSchema = isConsentExperience ? consentValidationSchema : preferenceValidationSchema

  const isReady =
    !isOrganizationLanguagesLoading &&
    !isExperienceLoading &&
    !isLoadingAllFormFields &&
    !isLoadingFormTemplates &&
    !isSubscriptionTopicsLoading

  const tabs = isConsentExperience
    ? [
        {
          id: ConsentExperienceTab.BANNER,
          name: ConsentExperienceTab.BANNER,
        },
        {
          id: ConsentExperienceTab.MODAL,
          name: ConsentExperienceTab.MODAL,
        },
        {
          id: ConsentExperienceTab.JIT,
          name: ConsentExperienceTab.JIT,
          hidden: !isEntitledToJit,
        },
      ]
    : [
        {
          id: PreferenceExperienceTab.OVERVIEW,
          name: PreferenceExperienceTab.OVERVIEW,
        },
        {
          id: PreferenceExperienceTab.PREFERENCES,
          name: PreferenceExperienceTab.PREFERENCES,
          hidden: !isEntitledToConsentAndDisclosure,
        },
        {
          id: PreferenceExperienceTab.SUBSCRIPTIONS,
          name: PreferenceExperienceTab.SUBSCRIPTIONS,
          hidden: !isEntitledToSubscriptions,
        },
        {
          id: PreferenceExperienceTab.RIGHTS,
          name: PreferenceExperienceTab.RIGHTS,
          hidden: !isEntitledToRights,
        },
      ]

  const getOverlayProps = useCallback(
    (name: PreferenceField) => ({
      active: name === sidebarActiveTab,
      onClick: () => setSidebarActiveTab(name),
    }),
    [sidebarActiveTab],
  )

  const payload = {
    activeSidebarTab,
    activeTab,
    allFormFields,
    breadcrumbs,
    canonicalRights,
    code,
    experience,
    experienceType,
    formMode,
    formTemplates,
    getOverlayProps,
    initialValues,
    isConsentExperience,
    isEditMode,
    isEntitledToConsentAndDisclosure,
    isEntitledToJit,
    isEntitledToRights,
    isEntitledToSubscriptions,
    isReady,
    navigate,
    onSubmit,
    organizationLanguages,
    pathname,
    resetPersistedReduxValues,
    setActiveSidebarTab,
    setActiveTab,
    setSidebarActiveTab,
    sidebarActiveTab,
    tabs,
    validationSchema,
  }

  return payload
}

export type UseExperienceUpsertUtilsReturnType = ReturnType<typeof useExperienceUpsertUtils>
