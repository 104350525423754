export const protocolId: { [key: string]: string } = {
  gpc: 'gpc',
  google: 'google',
  tcfeu: 'tcfeuv2',
  tcfeuLegacy: 'tcfeuv2-legacy',
  tcfca: 'tcfcav1',
  usps: 'uspv1',
  usnat: 'usnat',
  usca: 'usca',
  uscaLegacy: 'usca-legacy',
  usva: 'usva',
  usco: 'usco',
  usut: 'usut',
  usct: 'usct',
}

export const mutallyExclusiveProtocols: string[] = [
  protocolId.tcfeu,
  protocolId.tcfca,
  protocolId.usnat,
  protocolId.usca,
  protocolId.usva,
  protocolId.usco,
  protocolId.usct,
]

export const documentedProtocols: string[] = [protocolId.google]

export const protocolDocumentationLink: { [key: string]: string } = {
  [protocolId.google]: 'https://docs.ketch.com/ketch/docs/configuring-privacy-protocols#consent-mode-considerations',
}

export const mspaProtocols: string[] = [protocolId.usca, protocolId.usco, protocolId.usva]

export const lspaProtocols: string[] = [protocolId.uscaLegacy]

export const enableAdvertiserConsentModeProtocols: string[] = [protocolId.tcfeuLegacy]

export const legacyProtocols: string[] = [protocolId.uscaLegacy, protocolId.tcfeuLegacy]

export const gvlProtocols: string[] = [protocolId.tcfeu, protocolId.tcfca, protocolId.tcfeuLegacy]

export const googleACProtocols: string[] = [protocolId.tcfeuLegacy]

export const gvlRegion: { [key: string]: string } = {
  europe: 'eu',
  canada: 'ca',
}
