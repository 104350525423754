import { useFilters } from 'utils/hooks/useFiltersV2'
import { useMemo } from 'react'
import { searchParamIgnoreArray } from 'utils/constants/searchParamIgnoreArray'

export const MEGA_FILTER_IGNORE_ARRAY = ['search']

export const useMegaFilterUtils = () => {
  const {
    searchParams,
    searchParamArray,
    clearAllEntriesForKey,
    removeQueryParamFromList,
    replaceParams,
    getParamValueByKey,
    clearAllSearchParams,
  } = useFilters()

  /**
   * Check if a value exists for a key
   */
  const hasValue = (key: string, value: string) => {
    return getParamValueByKey({ key }).includes(value)
  }

  /**
   * Set or add a value for a search param key
   */
  const addParamValue = (key: string, value: string) => {
    const current = getParamValueByKey({ key })
    const newValue = current.includes(value) ? current : [...current, value]
    replaceParams({ [key]: newValue.join(','), page: '0' })
  }

  const filteredSearchParamArray = searchParamArray.filter(param => {
    const [key] = param
    return !searchParamIgnoreArray.includes(key) && !MEGA_FILTER_IGNORE_ARRAY.includes(key.toLowerCase())
  })

  const filterSelectedData = filteredSearchParamArray
    .map(([key, value]) => {
      return value.split(',').map(value => ({ key, value }))
    })
    .flat()

  /**
   * Calculates the number of filters applied to the current search query.
   *
   * @returns {number} The number of filters applied to the search query.
   */
  const filterCount = useMemo((): number => {
    return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
      if (!searchParamIgnoreArray.includes(key) && !MEGA_FILTER_IGNORE_ARRAY.includes(key.toLowerCase())) {
        const values = value.split(',')
        return acc + values.length
      }
      return acc
    }, 0)
  }, [searchParams])

  return {
    addParamValue,
    hasValue,
    getParamValueByKey,
    replaceParams,
    clearAllEntriesForKey,
    clearAllSearchParams,
    removeQueryParamFromList,
    filterCount,
    filterSelectedData,
  }
}

export type MegaFilterUtils = ReturnType<typeof useMegaFilterUtils>
