import { Box } from '@mui/material'
import { JurisdictionDTO } from '@ketch-com/figurehead'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { Chip } from 'components/ui-kit/chip/Chip'
import { useFormikContext } from 'formik'
import { StackForm } from '../utils/formValues'
import { Error } from 'components/ui-kit/form/common/error/Error'
import React from 'react'
import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'
import { JurisdictionsInOtherStacks } from '../utils/types'
import { Text } from 'components/ui-kit/typography/Text'

type Props = {
  jurisdictions: JurisdictionDTO[]
  showJurisdictionError: boolean
  jurisdictionsInOtherStacks: JurisdictionsInOtherStacks
}

const errorStateStyles = (palette: any) => ({
  border: `1px solid ${palette.chileanFire.main}`,
  m: -1,
  p: 1,
})

type TooltipProps = {
  code: string
  name: string
}

export const BasicDetailsForm: React.FC<Props> = ({
  jurisdictions,
  showJurisdictionError,
  jurisdictionsInOtherStacks,
}) => {
  const {
    setFieldValue,
    setFieldTouched,
    values: { jurisdictions: jurisdictionFormValue },
  } = useFormikContext<StackForm>()

  const JurisdictionChip: React.FC<TooltipProps> = ({ code, name }) => {
    const otherStack = jurisdictionsInOtherStacks[code]

    const chip = (
      <Chip
        disabled={Boolean(otherStack)}
        onClick={() => {
          const newJurisdictionsValue = jurisdictionFormValue?.includes(code || '')
            ? jurisdictionFormValue.filter?.(j => j !== code)
            : [...jurisdictionFormValue, code]
          setFieldTouched('jurisdictions', true)
          setFieldValue('jurisdictions', newJurisdictionsValue)
        }}
        selected={jurisdictionFormValue?.includes(code || '')}
      >
        {name}
      </Chip>
    )

    if (otherStack) {
      return (
        <Tooltip
          content={
            <Text component="div" maxWidth={200}>
              This jurisdiction is already a member of a stack. Update {otherStack} stack to make this jurisdiction
              available.
            </Text>
          }
        >
          {chip}
        </Tooltip>
      )
    }

    return chip
  }

  return (
    <>
      <FormSection title="Basic Details">
        <Box width={600}>
          <FormInput name="name" label="Name" placeholder="Add recognizable name" required fullWidth />
        </Box>
      </FormSection>
      <FormSection
        title="Jurisdictions"
        subTitle="Specify for which data subject types we should display experiences on this property."
      >
        <Box
          display="inline-flex"
          gap="8px"
          flexWrap="wrap"
          borderRadius={2}
          sx={({ palette }) => (showJurisdictionError ? errorStateStyles(palette) : {})}
        >
          {jurisdictions.map(({ name, code }) => (
            <JurisdictionChip key={code} name={name || ''} code={code || ''} />
          ))}
        </Box>
        {showJurisdictionError ? (
          <Box mt={1.5}>
            <Error>Required</Error>
          </Box>
        ) : null}
      </FormSection>
    </>
  )
}
