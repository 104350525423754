import React from 'react'
import { includes, isUndefined } from 'lodash'

import {
  WorkflowActivityCode,
  WorkflowActivityConnectorCode,
  WorkflowActivityGatewayMode,
} from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep, IWorkflowItems } from 'pages/orchestration/workflows/edit/interfaces'

// Misc:
import { EndFlowForm } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/EndFlowForm'

// Workflows:
import { ChildWorkflowForm } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/workflow/ChildWorkflowForm'

// Gateways:
import { SplitGatewayForm } from './forms/gateway/split/SplitGatewayForm'
import { DecisionGatewayForm } from './forms/gateway/DecisionGatewayForm'
import { JoinGatewayForm } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/gateway/join/JoinGatewayForm'

// Activities:
import { ApprovalForm } from './forms/activity/ApprovalForm'
import { TaskForm } from './forms/activity/TaskForm'
import { EmailForm } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/EmailForm'
import { DataSubjectEmailForm } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/DataSubjectEmailForm'
import { ApiCallForm } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/apiCall/ApiCallForm'
import { AdobeConnectorForm } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/connector/adobe/AdobeConnectorForm'
import { FulfillRightForm } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/FulfillRightForm'
import { DsrTransponderForm } from './forms/activity/DsrTransponderForm'
import { IdentityVerificationForm } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/IdentityVerificationForm'
import { DataReviewForm } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/dataReview/DataReviewForm'
import { ImportContext } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/ImportContext'
// Apps:
import { AppForm } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/app/AppForm'
import { AppLvl2Form } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/app/AppLvl2Form'
import { DecisionBooleanGatewayForm } from './forms/gateway/decisionBoolean/DecisionBooleanGatewayForm'
import { SetPermitsForm } from './forms/activity/SetPermitsForm'
import { InternalSystemDSRForm } from './forms/activity/InternalSystemDSRForm'
import { EndFlowFormV3 } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/EndFlowFormV3'

import { useState, useEffect } from 'react'

function useDelayedRender() {
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    let timeoutId: any

    if (!rendered) {
      timeoutId = setTimeout(() => {
        setRendered(true)
      }, 1000)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [rendered])

  return rendered
}

interface ISidebarConfigsProps {
  workflowCode: string
  workflowItems: IWorkflowItems
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep; pathId?: string }) => void
  connectedNestStartStep?: ICanvasStep
}

export const SidebarConfigs: React.FC<ISidebarConfigsProps> = ({
  workflowCode,
  workflowItems,
  step,
  steps,
  handleClose,
  handleSave,
  handleRemove,
  connectedNestStartStep,
}) => {
  const { activity, workflow, gateway, finish } = step
  const isActivity = !isUndefined(activity)
  const isWorkflow = !isUndefined(workflow)
  const isApp = isActivity && !includes(Object.values(WorkflowActivityCode), step.activity?.code)
  const isAppLvl2 = (isActivity || isWorkflow) && !!workflowItems.appslvl2.find(({ name }) => name === step?.name)
  const isGateway = !isUndefined(gateway)
  const isEndFlow = !isUndefined(finish)

  const formProps = {
    step,
    steps,
    connectedNestStartStep,
    handleClose,
    handleSave,
    handleRemove,
  }

  useDelayedRender()

  if (isAppLvl2) {
    return <AppLvl2Form {...formProps} />
  }

  if (isApp) {
    return <AppForm {...formProps} />
  }

  if (isActivity) {
    switch (activity?.code) {
      case WorkflowActivityCode.APPROVAL:
        return <ApprovalForm {...formProps} />
      case WorkflowActivityCode.TASK:
        return <TaskForm {...formProps} />
      case WorkflowActivityCode.SET_PERMITS:
        return <SetPermitsForm {...formProps} />
      case WorkflowActivityCode.EMAIL:
        return <EmailForm {...formProps} />
      case WorkflowActivityCode.DATA_SUBJECT_EMAIL:
        return <DataSubjectEmailForm {...formProps} />
      case WorkflowActivityCode.IDENTITY_VERIFICATION:
        return <IdentityVerificationForm {...formProps} />
      case WorkflowActivityCode.FULFILL_RIGHT:
        return <FulfillRightForm {...formProps} />
    }
  }

  if (isGateway) {
    switch (gateway?.mode) {
      case WorkflowActivityGatewayMode.SPLIT:
        return <SplitGatewayForm {...formProps} />
      case WorkflowActivityGatewayMode.DECISION_SINGLE:
      case WorkflowActivityGatewayMode.DECISION_MULTI:
        return <DecisionGatewayForm {...formProps} />
      case WorkflowActivityGatewayMode.JOIN:
        return <JoinGatewayForm {...formProps} />
      case WorkflowActivityGatewayMode.DECISION_BOOLEAN:
        return <DecisionBooleanGatewayForm {...formProps} />
    }
  }

  if (isWorkflow) {
    switch (workflow?.code) {
      // connectors that are child workflows
      case WorkflowActivityConnectorCode.ADOBE:
      case WorkflowActivityConnectorCode.SALESFORCE:
      case WorkflowActivityConnectorCode.MICROSOFT_EXCHANGE_SERVER:
      case WorkflowActivityConnectorCode.GOOGLE_MARKETING:
      case WorkflowActivityConnectorCode.HUBSPOT:
      case WorkflowActivityConnectorCode.MARKETO:
      case WorkflowActivityConnectorCode.APPNEXUS:
      case WorkflowActivityConnectorCode.COMSCORE:
      case WorkflowActivityConnectorCode.ORACLE_DATA_CLOUD:
      case WorkflowActivityConnectorCode.DATAXU:
      case WorkflowActivityConnectorCode.DSR_TRANSPONDER:
        return <DsrTransponderForm {...formProps} />
      case WorkflowActivityConnectorCode.INTERNAL_SYSTEM_DSR:
        return <InternalSystemDSRForm {...formProps} />
      case WorkflowActivityConnectorCode.KETCH_TRANSPONDER:
        return <AdobeConnectorForm {...formProps} />
      case WorkflowActivityConnectorCode.END:
        return <EndFlowFormV3 {...formProps} />
      case WorkflowActivityConnectorCode.IMPORT_CONTEXT:
        return <ImportContext {...formProps} />
      default:
        // this is hacky and fragile, but necessary because we're blurring the lines between workflows and activities.
        if (workflow?.temporalFunctionName === WorkflowActivityConnectorCode.API_CALL_V2) {
          return <ApiCallForm {...formProps} />
        }
        if (workflow?.temporalFunctionName === WorkflowActivityConnectorCode.DATA_REVIEW) {
          return <DataReviewForm {...formProps} />
        } else {
          // generic child workflow form
          return <ChildWorkflowForm workflowCode={workflowCode} {...formProps} />
        }
    }
  }

  if (isEndFlow) {
    return <EndFlowForm {...formProps} />
  }

  return null
}
