import { UserDTO, UserProfileFormDTO } from 'interfaces/users/users'
import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  userId: string
  formData: UserProfileFormDTO
}>

type Response = { user: UserDTO }

export const updateUserProfile = ({ userId, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/users/${userId}/profile`,
    }),
    formData,
  )
