import React from 'react'

import { Button } from '@ketch-com/deck'
import Box from '@mui/material/Box'
import { transponderDocumentationLinks } from '../../constants'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  handleClick: () => void
}

export const AppliancesViewActions: React.FC<Props> = ({ handleClick }) => {
  return (
    <Box display="flex">
      <Box mr={1}>
        <Button color="tertiary" onClick={handleClick}>
          Installation Guide
        </Button>
      </Box>

      <Button
        onClick={() => {
          window.open(transponderDocumentationLinks.TRANSPONDER_OVERVIEW, '_blank', 'noopener,noreferrer')
        }}
        color="secondary"
      >
        Documentation
      </Button>
    </Box>
  )
}

export const AppliancesViewActionsV2: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Box display="flex">
      <Box mr={1}>
        <Button
          color="primary"
          onClick={() => {
            navigate(RoutesManager.developers.transponders.create.getURL())
          }}
        >
          Create Transponder
        </Button>
      </Box>

      <Button
        onClick={() => {
          window.open(transponderDocumentationLinks.TRANSPONDER_OVERVIEW, '_blank', 'noopener,noreferrer')
        }}
        color="secondary"
      >
        Documentation
      </Button>
    </Box>
  )
}
