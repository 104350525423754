import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { putNotificationById } from '../fetchers/putNotificationById'

const useCustomMutation = createUseMutation({
  mutationFn: putNotificationById,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateNotificationById = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    ...config,
  })
}
