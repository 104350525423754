import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import {
  CreateSubscriptionBody,
  CreateSubscription200,
} from '@ketch-com/harbormaster/dist/organization/subscriptions.schemas'

type Params = APIRequestParams<{
  reqBody: CreateSubscriptionBody
}>

export const createSubscription = ({ reqBody }: Params) =>
  API.post<CreateSubscription200>(
    formatRequestString({
      entityUrl: `/api/subscriptions`,
    }),
    reqBody,
  )
