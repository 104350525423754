import { Box, Grid } from '@mui/material'
import React from 'react'
import Markdown from 'markdown-to-jsx'
import { AppFormFieldDTO } from 'interfaces/apps'
import { useDynamicDropdownValues } from 'api/apps/queries/useDynamicDropdownValues'
import { ApplianceDTO } from 'interfaces/appliances'
import { FormDroplistButton } from 'components/form/FormDroplistButton'

type Props = {
  field: AppFormFieldDTO
  index: number
}

export const FormDropdownWithDynamicOptions: React.FC<Props> = ({ field, index }) => {
  const { data } = useDynamicDropdownValues({
    params: {
      entityUrl: field?.data?.url || '',
    },
  })

  /* Using type "any" up and down the chain because the dynamically fetched value will vary based on field?.data?.url */
  const dynamicDropdownItems = (Object.entries<any>(data || {})?.[0]?.[1] || [])?.map?.((v: any) => ({
    ...v,
    name: v?.[field?.data?.labelKey || 'foo'] || '',
    id: v?.[field?.data?.valueKey || 'bar'] || '',
  }))

  return (
    <Box mb={2} key={index}>
      {!!field?.description && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Markdown>{field.description}</Markdown>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormDroplistButton
            disableClearable
            hideOptionalLabel
            fullWidth
            valueKey="id"
            placeholder={field?.placeholder || 'Select'}
            formPropertyName={`fields[${index}].value`}
            size="medium"
            items={
              field?.name === 'appliance'
                ? dynamicDropdownItems.filter(
                    // TODO:BAC There was talk of also filtering by connectionStatus. Still being debated.
                    // (appliance: ApplianceDTO) => appliance?.approvalStatus === 1 && appliance?.connectionStatus === 1,
                    (appliance: ApplianceDTO) => appliance?.approvalStatus === 1,
                  )
                : dynamicDropdownItems || []
            }
          />
        </Grid>
      </Grid>
    </Box>
  )
}
