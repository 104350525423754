import React, { useContext } from 'react'
import { AuthenticatorsEditView, AuthenticatorsUpsertView } from './components'
import { AuthenticatorUpsertContext, withFormAuthenticatorUpsertContext } from './context/AuthenticatorUpsertContext'

export const AuthenticatorsUpsertContainerWithContext: React.FC = () => {
  const { isEditMode } = useContext(AuthenticatorUpsertContext)

  return isEditMode ? <AuthenticatorsEditView /> : <AuthenticatorsUpsertView />
}

export const AuthenticatorsUpsertContainer = withFormAuthenticatorUpsertContext(
  AuthenticatorsUpsertContainerWithContext,
)
