import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import clsx from 'clsx'

import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'
import Info from '@mui/icons-material/Info'

const PREFIX = 'DataAssetsImpactedInfo'

const classes = {
  dataAssetsImpactedContainer: `${PREFIX}-dataAssetsImpactedContainer`,
  tileText: `${PREFIX}-tileText`,
  assetStatTileContainer: `${PREFIX}-assetStatTileContainer`,
  assetTileValue: `${PREFIX}-assetTileValue`,
  infoIcon: `${PREFIX}-infoIcon`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing, typography } }) => ({
  [`& .${classes.dataAssetsImpactedContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.tileText}`]: {
    textTransform: 'capitalize',
  },

  [`& .${classes.assetStatTileContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: spacing(1.25),
    padding: spacing(1.75, 2.25),
    marginRight: spacing(2.25),
    backgroundColor: palette.fadedGrey.main,
    transition: 'background-color 0.3s ease-in-out 0.8s',
    height: '100%',
    minHeight: 75,
  },

  [`& .${classes.assetTileValue}`]: {
    textTransform: 'capitalize',
    '&:hover': {
      color: palette.darkDusk.main,
    },
  },

  [`& .${classes.infoIcon}`]: {
    fontSize: typography.pxToRem(18),
    marginLeft: spacing(1),
    fill: palette.darkDuskFaded.main,
    marginRight: spacing(1),
  },
}))

type DataAssetsImpactedInfoProps = {
  policy: AssetPolicyDTO & {
    assetsImpacted: { [key: string]: string }
  }
}

export const DataAssetsImpactedInfo: React.FC<DataAssetsImpactedInfoProps> = ({ policy }) => {
  // TODO:BAC - need new endpoint to fetch data assets impacted, or, need to add new field to policy to supply this info

  const databaseCount = policy?.assetsImpacted?.RESOURCE_TYPE_SCHEMA || 13 // TODO:BAC - should be "|| 0"
  const datasetCount = policy?.assetsImpacted?.RESOURCE_TYPE_DATASET || 42 // TODO:BAC - should be "|| 0"
  const attributeCount = policy?.assetsImpacted?.RESOURCE_TYPE_ATTRIBUTE || 33 // TODO:BAC - should be "|| 0"

  return (
    <StyledBox>
      <Box mb={2} className={classes.dataAssetsImpactedContainer}>
        {/* Databases */}

        {!!databaseCount && (
          <Box className={classes.assetStatTileContainer}>
            {/* Value */}

            <Text color="black" className={clsx(classes.tileText)} size={16} weight={700}>
              {databaseCount}
            </Text>

            {/* Title */}
            <Text color="black" className={clsx(classes.tileText)} size={12} weight={500}>
              Databases
            </Text>
          </Box>
        )}

        {/* Datasets */}
        {!!datasetCount && (
          <Box className={classes.assetStatTileContainer}>
            {/* Value */}

            <Text color="black" className={clsx(classes.tileText)} size={16} weight={700}>
              {datasetCount}
            </Text>

            {/* Title */}
            <Text color="black" className={clsx(classes.tileText)} size={12} weight={500}>
              Datasets
            </Text>
          </Box>
        )}

        {/* Attributes */}
        {!!attributeCount && (
          <Box className={classes.assetStatTileContainer}>
            {/* Value */}

            <Text color="black" className={clsx(classes.tileText)} size={16} weight={700}>
              {attributeCount}
            </Text>

            {/* Title */}
            <Text color="black" className={clsx(classes.tileText)} size={12} weight={500}>
              Attributes
            </Text>
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <Info className={classes.infoIcon} />{' '}
        <Text size={11} weight={500} color="grey">
          These numbers stand for the quantity of data assets this policy affects or can affect.
        </Text>
      </Box>
    </StyledBox>
  )
}
