import React from 'react'

import Box from '@mui/material/Box'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import Typography from '@mui/material/Typography'
import { FormTemplateDTO } from '@ketch-com/figurehead'
import { FormTemplateUsedInExperienceTable } from '.'

type Props = {
  onClose: () => void
  formTemplate?: FormTemplateDTO
}
export const FormTemplateUsedInExperiencesListModal: React.FC<Props> = ({ onClose, formTemplate }) => {
  return (
    <Dialog
      isLoading={false}
      cancelBtnLabel="Close"
      contentWidth={550}
      shouldHideSubmitButton={false}
      title="Experiences"
      onCancelBtnClick={onClose}
    >
      <Typography variant="body" mb={3} component={Box}>
        This form is used in the following deployed Experiences:
      </Typography>

      <FormTemplateUsedInExperienceTable formTemplate={formTemplate} />
    </Dialog>
  )
}
