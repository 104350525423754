export enum PurposeDataUsageDuration {
  UNSPECIFIED = 0,
  DAYS = 1,
  MONTHS = 2,
  YEARS = 3,
}

export const PURPOSE_DATA_USAGE_DURATION_UNIT_NAMES = {
  [PurposeDataUsageDuration.UNSPECIFIED]: 'Unspecified',
  [PurposeDataUsageDuration.DAYS]: 'Days',
  [PurposeDataUsageDuration.MONTHS]: 'Months',
  [PurposeDataUsageDuration.YEARS]: 'Years',
}

export const PURPOSE_DATA_USAGE_DURATION_UNIT_OPTIONS = [
  {
    id: PurposeDataUsageDuration.DAYS,
    name: PURPOSE_DATA_USAGE_DURATION_UNIT_NAMES[PurposeDataUsageDuration.DAYS],
  },
  {
    id: PurposeDataUsageDuration.MONTHS,
    name: PURPOSE_DATA_USAGE_DURATION_UNIT_NAMES[PurposeDataUsageDuration.MONTHS],
  },
  {
    id: PurposeDataUsageDuration.YEARS,
    name: PURPOSE_DATA_USAGE_DURATION_UNIT_NAMES[PurposeDataUsageDuration.YEARS],
  },
]
