import { Checkbox, CheckboxProps } from '@ketch-com/deck'
import { ChangeEvent } from 'react'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'

// TODO:JB - Replace with ui kit form component once ready

type Props = {
  name: string
  afterChange?: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void
} & CheckboxProps

export const FormCheckbox: React.FC<Props> = ({ name, afterChange, ...props }) => {
  const { values, setFieldValue } = useFormikContext<ExperienceV2DTO | ThemeV3DTO>()
  const handleChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFieldValue(name, e.target.checked)
    afterChange?.(e, checked)
  }

  return <Checkbox checked={Boolean(getIn(values, name))} onChange={handleChange} {...props} />
}
