import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'
import { isEmpty } from 'lodash'

import { TabsRoutes, TabsRoutesConfig } from 'components/ui-kit/tabs/TabsRoutes'

const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        padding: '24px 0',

        '&:not($withTabs)': {
          borderTop: `1px solid ${theme.palette.iron.main}`,
        },

        '&:not($hasBorderTop)': {
          borderTop: 0,
          paddingTop: 0,
        },
      },
      content: {
        paddingLeft: 18,
        paddingRight: 18,
      },
      hasBorderTop: {},
      withTabs: {},
      tabsWrapper: {
        position: 'relative',
        borderBottom: `1px solid ${theme.palette.iron.main}`,
        marginBottom: 24,
      },
      tabs: {
        position: 'absolute',
        bottom: -1,
      },
    }),
  { name: 'ViewLayoutContentLeftMenu' },
)

type Props = {
  children?: React.ReactNode
  tabs?: TabsRoutesConfig[]
  className?: string
  contentClassName?: string
  hasBorderTop?: boolean
}

export const ViewLayoutContentLeftMenu: React.FC<Props> = ({
  tabs = [],
  children,
  className,
  contentClassName,
  hasBorderTop = true,
}) => {
  const classes = useStyles()
  const hasTabs = !isEmpty(tabs)

  const classList = clsx(classes.root, className, {
    [classes.withTabs]: hasTabs,
    [classes.hasBorderTop]: hasBorderTop,
  })

  return (
    <div className={classList}>
      {hasTabs && (
        <div className={classes.tabsWrapper}>
          <TabsRoutes tabs={tabs} size="regular" className={classes.tabs} />
        </div>
      )}
      <div className={clsx(classes.content, contentClassName)}>{children}</div>
    </div>
  )
}
