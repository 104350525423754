import React from 'react'
import clsx from 'clsx'
import { Box, Popover } from '@mui/material'

import { makeStyles, createStyles } from '@mui/styles'

import { Badge } from 'components/ui-kit/badge/Badge'
import { BadgeKeyValue } from 'components/ui-kit/badge/BadgeKeyValue'
import { Text } from 'components/ui-kit/typography/Text'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { LabelDTO } from '@ketch-com/figurehead'

const MAX_LABELS_TO_DISPLAY = 15

const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      firstBadge: {
        marginRight: spacing(2),
      },
      withMarginBottom: {
        marginBottom: spacing(1),
      },
      withMarginRight: {
        marginRight: spacing(1),
      },
      capitalCase: {
        textTransform: 'capitalize',
      },
      withPointer: {
        cursor: 'pointer',
      },
      popoverContainer: {
        flexWrap: 'wrap',
        display: 'flex',
        maxWidth: '725px',
        padding: spacing(2),
        paddingBottom: spacing(1),
      },
    }),
  { name: 'ViewListLayoutContent' },
)

const LabelsFlex = ({ labels }: { labels: LabelDTO[] }) => {
  const classes = useStyles()

  return (
    <>
      {labels?.length > 0 &&
        labels.map((l, index) => {
          if (l?.code && l?.value)
            return (
              <BadgeKeyValue
                key={l.value}
                badgeKey={l?.name as string}
                badgeValue={l?.value}
                className={clsx(classes.capitalCase, classes.withMarginBottom, {
                  [classes.firstBadge]: index !== labels.length - 1,
                })}
              />
            )
          if (l?.code)
            return (
              <Badge
                key={l.code}
                className={clsx(classes.capitalCase, classes.withMarginBottom, {
                  [classes.firstBadge]: index !== labels.length - 1,
                })}
              >
                {l.code}
              </Badge>
            )

          if (l?.value)
            return (
              <Badge
                key={l.value}
                className={clsx(classes.capitalCase, classes.withMarginBottom, {
                  [classes.firstBadge]: index !== labels.length - 1,
                })}
              >
                {l.value}
              </Badge>
            )
          return null
        })}
    </>
  )
}

type Props = {
  labels: LabelDTO[]
  shouldShowNone?: boolean
}

export const OtherLabels: React.FC<Props> = ({ labels, shouldShowNone = true }) => {
  const classes = useStyles()
  let overflowLabels: LabelDTO[] = []
  const displayLabels: LabelDTO[] = labels.slice(0, MAX_LABELS_TO_DISPLAY)
  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'other-labels-popover' : undefined

  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (labels.length > MAX_LABELS_TO_DISPLAY) {
    overflowLabels = labels.slice(MAX_LABELS_TO_DISPLAY)
  }

  if (labels.length < 1 && shouldShowNone) return <EmptyValueRenderer />

  if (labels.length < 1 && !shouldShowNone) return null

  return (
    <>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <LabelsFlex labels={displayLabels} />
      </Box>

      {overflowLabels?.length > 0 && (
        <Box display="flex" mt={1}>
          <Text className={classes.withMarginRight} size={14}>
            {overflowLabels.length} more
          </Text>
          <Text className={classes.withPointer} onClick={handleClick} size={14} color="sphere">
            View All
          </Text>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            classes={{
              paper: classes.popoverContainer,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <LabelsFlex labels={labels} />
          </Popover>
        </Box>
      )}
    </>
  )
}
