import React, { useState } from 'react'

import { Box, Typography } from '@mui/material'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { Button, PopUp } from '@ketch-com/deck'
import { RadioGroup, RadioOptions } from 'components/RadioGroup/RadioGroup'

type Props = {
  onSubmit: (pathId?: string) => void
  onCancel: () => void
  step: ICanvasStep
  stepType: WorkflowActivityGatewayMode
}

export const DecisionGatewayConfirmDeleteModal: React.FC<Props> = ({ onSubmit, onCancel, step, stepType }) => {
  const gatewayName =
    stepType === WorkflowActivityGatewayMode.DECISION_SINGLE
      ? 'Single Decision Gateway'
      : stepType === WorkflowActivityGatewayMode.DECISION_MULTI
      ? 'Multi Decision Gateway'
      : 'Split'

  const pathOptions: RadioOptions = step.gateway
    ? step.gateway.transitions.map((i, index) => ({ value: i.ID, title: i.name || `Path ${index + 1}` }))
    : []

  const [isSelectingPaths, setIsSelectingPaths] = useState(false)
  const [selectedPath, setSelectedPath] = useState<string | undefined>(pathOptions?.[0]?.value)

  if (isSelectingPaths) {
    return (
      <PopUp
        title="Delete Decision Gateway"
        variant="dialog"
        onClose={onCancel}
        popUpWidth="550px"
        popUpActionChildren={
          <>
            <Button color="secondary" size="large" onClick={onCancel}>
              Cancel
            </Button>
            <Button color="primary" size="large" onClick={() => onSubmit(selectedPath)}>
              Confirm
            </Button>
          </>
        }
      >
        <Typography variant="body" component="div">
          Please select the path you wish to keep in the workflow.
        </Typography>
        <Box maxHeight="200px" overflow="scroll">
          <RadioGroup
            options={pathOptions}
            value={selectedPath}
            onChange={event => setSelectedPath(event.target.value)}
          />
        </Box>
      </PopUp>
    )
  }

  return (
    <PopUp
      title={`Delete ${gatewayName}`}
      variant="dialog"
      popUpActionChildren={
        <>
          <Button color="tertiary" size="large" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="secondary" size="large" onClick={() => setIsSelectingPaths(true)}>
            Select Path
          </Button>
          <Button color="primary" size="large" onClick={() => onSubmit()}>
            Delete
          </Button>
        </>
      }
      onClose={onCancel}
    >
      <Typography variant="body" component="div">
        Would you like to delete the entire {gatewayName} group or keep one of the paths in the workflow?
      </Typography>
      <Typography variant="body">Please select your preferred option.</Typography>
    </PopUp>
  )
}
