import { APIListRequestParams } from 'api/common/utils'
import { GetWebhookResponseBodyDTO } from '@ketch-com/figurehead'

import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  webhookId?: string
}>

export const fetchWebhook = ({ webhookId }: Params) =>
  API.get<GetWebhookResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/webhooks/${webhookId}`,
    }),
  )
