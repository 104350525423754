import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  deploymentPlanCode: string
  organizationCode: string
}

export const deleteDeploymentPlan = ({ organizationCode, deploymentPlanCode }: Params) =>
  API.delete<null>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/deployments/${deploymentPlanCode}`,
    }),
  )
