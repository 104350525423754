import { WorkflowActivityConnectorCode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'

const DELETE_RIGHT = { id: 'DELETE', name: 'Delete' }

export const getMockPropagationTypes = () => [
  { id: 'permit', name: 'Permit' },
  { id: 'rights', name: 'Rights' },
]

export const getMockConditions = () => [
  { id: 'any', name: 'ANY' },
  { id: 'all', name: 'ALL' },
]

export const getMockIdentityName = (step: ICanvasStep) => {
  switch (step?.workflow?.code) {
    case WorkflowActivityConnectorCode.ADOBE:
      return 'ECID'
    case WorkflowActivityConnectorCode.HUBSPOT:
      return 'Email, Hubspot Cookie'

    case WorkflowActivityConnectorCode.MARKETO:
      return 'Contact ID'

    case WorkflowActivityConnectorCode.APPNEXUS:
    case WorkflowActivityConnectorCode.GOOGLE_MARKETING:
    case WorkflowActivityConnectorCode.COMSCORE:
    case WorkflowActivityConnectorCode.ORACLE_DATA_CLOUD:
    case WorkflowActivityConnectorCode.DATAXU:
      return 'TCF Daisybit'

    case WorkflowActivityConnectorCode.SALESFORCE:
      return 'SubscriberID'

    case WorkflowActivityConnectorCode.MICROSOFT_EXCHANGE_SERVER:
      return 'Email'
    default:
      return ''
  }
}

export const getMockConnections = (step: ICanvasStep) => {
  switch (step?.workflow?.code) {
    case WorkflowActivityConnectorCode.ADOBE:
    case WorkflowActivityConnectorCode.HUBSPOT:
    case WorkflowActivityConnectorCode.MARKETO:
      return [{ id: 'CONNECTION_1', name: 'Connection 1' }]

    case WorkflowActivityConnectorCode.GOOGLE_MARKETING:
      return [
        { id: 'GOOGLE_ADH_1', name: 'Google ADH 1' },
        { id: 'GOOGLE_ADS', name: 'Google Ads 2' },
      ]

    case WorkflowActivityConnectorCode.APPNEXUS:
    case WorkflowActivityConnectorCode.COMSCORE:
    case WorkflowActivityConnectorCode.ORACLE_DATA_CLOUD:
    case WorkflowActivityConnectorCode.DATAXU:
      return [{ id: 'TCF_2.0', name: 'TCF 2.0' }]

    case WorkflowActivityConnectorCode.SALESFORCE:
      return [{ id: 'MARKETING_CLOUD', name: 'Marketing Cloud' }]

    case WorkflowActivityConnectorCode.MICROSOFT_EXCHANGE_SERVER:
      return [
        { id: 'SUPERSET_COM', name: 'Superset.com' },
        { id: 'KETCH_COM', name: 'Ketch.com' },
      ]

    case WorkflowActivityConnectorCode.KETCH_TRANSPONDER:
      return [
        { id: 'AXONIC', name: 'Axonic' },
        { id: 'SB_REDSHIFT', name: 'SB Redshift' },
      ]
  }
}

export const getMockRights = (step: ICanvasStep) => {
  switch (step?.workflow?.code) {
    case WorkflowActivityConnectorCode.ADOBE:
      return [
        { id: 'ACCESS', name: 'Access' },
        { id: 'DELETE', name: 'Delete' },
        { id: 'OPT_OUT_OF_SALE', name: 'Opt Out of Sale' },
      ]

    case WorkflowActivityConnectorCode.MARKETO:
      return [
        { id: 'DELETION', name: 'Deletion' },
        { id: 'PORTABILITY', name: 'Portability' },
      ]

    case WorkflowActivityConnectorCode.SALESFORCE:
      return [
        { id: 'DELETE', name: 'Delete' },
        { id: 'PORTABILITY', name: 'Portability' },
      ]

    case WorkflowActivityConnectorCode.MICROSOFT_EXCHANGE_SERVER:
    case WorkflowActivityConnectorCode.HUBSPOT:
      return [{ id: 'DELETE', name: 'Delete' }]

    case WorkflowActivityConnectorCode.APPNEXUS:
    case WorkflowActivityConnectorCode.COMSCORE:
    case WorkflowActivityConnectorCode.ORACLE_DATA_CLOUD:
    case WorkflowActivityConnectorCode.DATAXU:
    case WorkflowActivityConnectorCode.GOOGLE_MARKETING:
      // NOTE: when there are no rights for connector - show default 'Delete' right
      return [DELETE_RIGHT]

    default:
      return []
  }
}

export const getMockIdSpaces = (step: ICanvasStep) => {
  switch (step?.workflow?.code) {
    case WorkflowActivityConnectorCode.ADOBE:
      return [
        {
          id: 'PERSONLIZATION',
          name: 'Personlization',
        },
        {
          id: 'ADVERTISING',
          name: 'Advertising',
        },
        {
          id: 'ANALYTICS',
          name: 'Analytics',
        },
      ]

    case WorkflowActivityConnectorCode.COMSCORE:
    case WorkflowActivityConnectorCode.DATAXU:
    case WorkflowActivityConnectorCode.ORACLE_DATA_CLOUD:
    case WorkflowActivityConnectorCode.APPNEXUS:
    case WorkflowActivityConnectorCode.GOOGLE_MARKETING:
      return [
        { id: 'STORE_ON_DEVICE', name: 'Store and/or access information on a device' },
        { id: 'BASIC_ADS', name: 'Select basic ads' },
        { id: 'PERSONALISED_PROFILE', name: 'Create a personalised ads profile' },
        { id: 'PERSONALISED_ADS', name: 'Select personalised ads' },
        { id: 'PERSONALISED_CONTENT_PROFILE', name: 'Create a personalised content profile' },
        { id: 'SELECT_PERSONALISED_CONTENT', name: 'Select personalised content' },
        { id: 'MEASURE_AD_PERFORMANCE', name: 'Measure ad performance' },
        { id: 'MEASURE_CONTENT_PERFORMANCE', name: 'Measure content performance' },
        { id: 'MARKET_RESEARCH', name: 'Apply market research to generate audience insights' },
        { id: 'DEVELOP_AND_IMPROVE', name: 'Develop and improve products' },
        { id: 'ENSURE_SECURITY', name: 'Ensure security, prevent fraud, and debug' },
        { id: 'TECHNICALLY_DELIVER', name: 'Technically deliver ads or content' },
        { id: 'MATCH_ offline_data', name: 'Match and combine offline data sources.' },
        { id: 'LINK_DEVICES', name: 'Link different devices' },
        {
          id: 'DEVICE_CHARACTERISTICS_ID',
          name: 'Receive and use automatically-sent device characteristics for identification',
        },
        { id: 'PRECISE_GEOLOCATION', name: 'Use precise geolocation data' },
        { id: 'SCAN_DEVICE_CHARACTERISTICS', name: 'Actively scan device characteristics for identification' },
      ]

    case WorkflowActivityConnectorCode.HUBSPOT:
      return [
        { id: 'EMAIL_OUTREACH', name: 'Email Outreach' },
        { id: 'WEB_BEHAVIOR_ANALYTICS', name: 'Web Behavior Analytics' },
      ]

    case WorkflowActivityConnectorCode.MARKETO:
      return [
        { id: 'SEND_EMAIL', name: 'Send Email' },
        { id: 'USER_PROFILING', name: 'User Profiling' },
        { id: 'WEB_ADVERTISING', name: 'Web Advertising' },
      ]

    case WorkflowActivityConnectorCode.SALESFORCE:
      return [
        { id: 'DATA_COLLECTION', name: 'Data Collection' },
        { id: 'MARKETING_EMAIL', name: 'Marketing Email' },
        { id: 'TARGETING', name: 'Targeting' },
        { id: 'USER_PROFILING', name: 'User Profiling' },
        { id: 'IDENTITY_MANAGEMENT', name: 'Identity Management' },
      ]

    case WorkflowActivityConnectorCode.MICROSOFT_EXCHANGE_SERVER:
      return [{ id: 'SEND_EMAIL', name: 'Send Email' }]

    default:
      return []
  }
}
