enum Environments {
  CI = 'ci',
  LOCAL = 'local',
  PRODUCTION = 'production',
  UAT = 'uat',
}

export const isLocal = () => getEnvFromHostname() === Environments.LOCAL

export const isUat = () => getEnvFromHostname() === Environments.UAT

export const isProd = () => getEnvFromHostname() === Environments.PRODUCTION

export const getEnvFromHostname = () => {
  const hostname = window.location.hostname
  if (hostname === 'localhost') {
    return Environments.LOCAL
  } else if (hostname === 'sendit.ninja') {
    return Environments.UAT
  } else if (hostname === 'app.ketch.com') {
    return Environments.PRODUCTION
  }
}
