import { useParams, useNavigate } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useRights } from 'api/rights/queries/useRights'
import { useRegulations } from 'api/regulations/queries/useRegulations'
import { usePolicyScope } from 'api/policyScopes/queries/usePolicyScope'
import { usePolicyScopeVersion } from 'api/policyScopes/queries/usePolicyScopeVersion'
import { usePolicyScopeVersions } from 'api/policyScopes/queries/usePolicyScopeVersions'
import { useRegionsHierarchy } from 'api/regions/queries/useRegionsHierarchy'

export const usePolicyScopeViewUtils = () => {
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()
  const { queries } = useQueryParams<{ version?: string }>()
  const { version } = queries

  const handleRedirectToPolicyScopesList = () => {
    navigate(RoutesManager.policyCenter.policyScopes.list.root.getURL())
  }

  const { data: policyScope, isLoading: isPolicyScopeLoading } = usePolicyScope({
    enabled: !version,
    params: {
      policyScopeCode: code!,
      includeIssues: true,
      includeMetadata: true,
    },
    onError: handleRedirectToPolicyScopesList,
  })

  const { data: policyScopeVersion, isLoading: isPolicyScopeVersionLoading } = usePolicyScopeVersion({
    enabled: !!version,
    params: {
      policyScopeCode: code!,
      version: version!,
      includeIssues: true,
      includeMetadata: true,
    },
    onError: handleRedirectToPolicyScopesList,
  })

  const { data: versions, isLoading: isVersionsLoading } = usePolicyScopeVersions({
    params: {
      policyScopeCode: code!,
      includeIssues: true,
      includeMetadata: true,
      ordering: {
        version: 'desc',
      },
    },
  })

  const { data: rights, isLoading: isRightsLoading } = useRights({
    params: {
      includeIssues: true,
      includeMetadata: true,
    },
  })

  const { data: regulations, isLoading: isRegulationsLoading } = useRegulations({
    params: {
      includeIssues: true,
      includeMetadata: true,
    },
  })

  const { data: regionGroups, isLoading: isRegionsLoading } = useRegionsHierarchy()

  const isReady =
    (version ? !isPolicyScopeVersionLoading : !isPolicyScopeLoading) &&
    !isVersionsLoading &&
    !isRightsLoading &&
    !isRegulationsLoading &&
    !isRegionsLoading

  const entity = version ? policyScopeVersion : policyScope

  return {
    isReady,
    entity,
    versions,
    rights,
    regulations,
    regionGroups,
    policyScope,
  }
}

export type UsePolicyScopeViewUtils = ReturnType<typeof usePolicyScopeViewUtils>
