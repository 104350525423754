import React from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { useUpdateWorkflowExecution } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecution'
import { useWorkflowExecutionStepDetails } from 'api/workflowExecutions/queries/useWorkflowExecutionStepDetails'
import { useFilesInfo } from 'api/files/queries/useFilesInfo'
import { mapValuesToPayload, WorkflowExecutionCompleteFormValues } from './utils'
import { showToast } from 'components/modals/AlertComponent'
import { WorkflowExecutionCompleteModal } from './WorkflowExecutionCompleteModal'

type Props = {
  rightInvocation?: RightInvocationDTO
  workflowExecutionStep?: WorkflowExecutionStepDTO
  onClose: () => void
}

export const WorkflowExecutionCompleteModalContainer: React.FC<Props> = ({
  rightInvocation,
  workflowExecutionStep,
  onClose,
}) => {
  const queryClient = useQueryClient()

  const { data: workflowExecutionStepDetails, isLoading: isWorkflowExecutionStepDetailsLoading } =
    useWorkflowExecutionStepDetails({
      enabled: !!workflowExecutionStep?.workflowExecutionID && !!workflowExecutionStep?.stepID,
      params: {
        workflowExecutionId: workflowExecutionStep?.workflowExecutionID || '',
        stepId: workflowExecutionStep?.stepID || '',
      },
    })

  const resolutionAttachments =
    workflowExecutionStepDetails?.results?.[workflowExecutionStep?.stepID || '']?.resolutionAttachments || []

  const { data: filesInfo, isLoading: isFilesInfoLoading } = useFilesInfo({
    enabled: !!resolutionAttachments.length,
    params: {
      infoUrls: resolutionAttachments,
    },
  })

  const { mutateAsync: handleCompleteWorkflowExecution } = useUpdateWorkflowExecution({
    onSuccess: async () => {
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      showToast({ content: 'Request completed', type: 'success' })
      onClose()
    },
    onError: () => {
      showToast({ content: 'Failed to complete request', type: 'error' })
    },
  })

  const onSubmit = async (values: WorkflowExecutionCompleteFormValues) => {
    const formData = mapValuesToPayload({
      values,
      workflowExecutionId: workflowExecutionStep?.workflowExecutionID || '',
    })

    await handleCompleteWorkflowExecution({
      params: {
        formData,
      },
    })

    await queryClient.refetchQueries([
      ApiQueryKeys.rightInvocationV2,
      {
        rightInvocationId: rightInvocation?.id || '',
      },
    ])

    await queryClient.refetchQueries([
      ApiQueryKeys.workflowExecution,
      {
        workflowExecutionId: workflowExecutionStep?.workflowExecutionID,
      },
    ])
  }

  const isReady = !isWorkflowExecutionStepDetailsLoading && !isFilesInfoLoading

  return (
    <WorkflowExecutionCompleteModal
      isReady={isReady}
      filesInfo={filesInfo}
      rightInvocation={rightInvocation}
      workflowExecutionStep={workflowExecutionStep}
      workflowExecutionStepDetails={workflowExecutionStepDetails}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  )
}
