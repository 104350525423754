import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { PutSubscriptionControlOrchestrationsRequestBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  data: PutSubscriptionControlOrchestrationsRequestBodyDTO
  code: string
}>

export const updateSubscriptionControlOrchestrations = ({ data, code }: Params) => {
  return API.put<PutSubscriptionControlOrchestrationsRequestBodyDTO>(
    formatRequestString({
      entityUrl: `/api/subscriptions/controls/${code}/orchestrations`,
    }),
    data,
  )
}
