import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetUsage200, GetUsageParams } from '@ketch-com/harbormaster/dist/organization/plans.schemas'

type Params = APIRequestParams<{
  organizationCode: string
}> &
  GetUsageParams

export const fetchUsage = ({ organizationCode, granularity }: Params) => {
  let entityUrl = `/api/organizations/${organizationCode}/usage`
  if (granularity) entityUrl = entityUrl + `?granularity=${granularity}`
  return API.get<GetUsage200>(
    formatRequestString({
      entityUrl,
    }),
  )
}
