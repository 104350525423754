import React from 'react'

import { useCreateLayoutContext } from 'components/ui-layouts/createLayout/CreateLayoutContext'

type Props = {
  children?: React.ReactNode
  step: string
}

export const CreateLayoutStepContent: React.FC<Props> = ({ step, children }) => {
  const { isSectionActive } = useCreateLayoutContext()

  return <>{isSectionActive(step) && children}</>
}
