import { styled } from '@mui/system'
import { ReactComponent as RemoveDropdownItemButtonIcon } from 'assets/icons/delete_dropdown_item_icon.svg'

interface RemoveFormFieldButtonProps {
  disabled?: boolean
}

export const RemoveFormFieldButton = styled(RemoveDropdownItemButtonIcon)<RemoveFormFieldButtonProps>(
  ({ theme, disabled }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    height: 28,
    width: 28,

    '& > path': {
      fill: !disabled ? theme.palette.darkDusk.main : theme.palette.darkDusk.main,
      fillOpacity: !disabled ? 1 : 0.25,
    },

    '&:hover': {
      '& > path': {
        fill: !disabled ? theme.palette.sphere.main : undefined,
      },
    },
  }),
)
