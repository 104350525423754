import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchWorkflowSummary } from 'api/workflows/fetchers/fetchWorkflowSummary'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.workflowSummary,
  queryFn: fetchWorkflowSummary,
  select: res => res?.data?.workflowSummary || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery, 'organizationCode'>

export const useWorkflowSummary = (config: Config) => {
  const { userData } = useAuth()

  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
      organizationCode: userData.organizationCode!,
    },
  })
}
