import React from 'react'
import clsx from 'clsx'
import { ButtonBase, CircularProgress } from '@mui/material'
import { SxProps, Theme } from '@mui/material'

import { makeStyles, createStyles } from '@mui/styles'

import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'
import { FormikErrors } from 'formik'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseButton: {
        fontFamily: typography.fontFamily,
        borderRadius: 5,
        letterSpacing: '0.01em',
      },
      baseButtonDisabled: {
        opacity: 0.3,
      },
      baseButtonStretch: {
        width: '100%',
      },

      // Sizes:
      sizeRegular: {
        padding: '6px 10px',
        lineHeight: typography.pxToRem(18),
        fontSize: typography.pxToRem(14),
        fontWeight: 600,
        minWidth: 80,
        height: 28,
      },
      sizeBig: {
        padding: '14px 18px',
        lineHeight: typography.pxToRem(18),
        fontSize: typography.pxToRem(14),
        fontWeight: 600,
        minWidth: 100,
        height: 44,
      },

      // Types:
      typePrimary: {
        color: palette.white.main,
        background: palette.sphere.main,

        '&:hover': {
          background: palette.royalBlue.main,
          cursor: 'pointer',
        },

        '&:active': {
          background: palette.persianBlue.main,
          cursor: 'pointer',
        },
      },

      typeSecondary: {
        color: palette.sphere.main,
        background: palette.transparent.main,
        border: `1px solid ${palette.sphere.main}`,

        '&:hover': {
          color: palette.royalBlue.main,
          border: `1px solid ${palette.royalBlue.main}`,
          cursor: 'pointer',
        },

        '&:active': {
          color: palette.persianBlue.main,
          border: `1px solid ${palette.persianBlue.main}`,
          background: palette.naviBlue.main,
          cursor: 'pointer',
        },
      },
      typeTertiary: {
        color: palette.sphere.main,
        background: palette.fadedGrey.main,

        '&:hover': {
          color: palette.royalBlue.main,
          background: palette.iron.main,
          cursor: 'pointer',
        },

        '&:active': {
          color: palette.persianBlue.main,
          background: palette.fadedDarkGrey.main,
          cursor: 'pointer',
        },
      },

      // ProgressIcon Types:
      progressIconPrimary: {
        color: palette.white.main,
      },
      progressIconSecondary: {
        color: palette.royalBlue.main,
      },
      progressIconTertiary: {
        color: palette.royalBlue.main,
      },

      // ProgressIcon Sizes:
      progressIconRegular: {
        marginLeft: 10,
      },
      progressIconBig: {
        marginLeft: 18,
      },
      removeMidWidth: {
        minWidth: 0,
      },
      removeBackground: {
        background: 'transparent',
      },
    }),
  { name: 'Button' },
)

export type BUTTON_VARIANTS = 'primary' | 'secondary' | 'tertiary'

export type BUTTON_SIZES = 'regular' | 'big'

interface Props {
  children?: React.ReactNode
  id?: string
  /** Test ID of an element for selectors */
  testId?: string
  /** Checks if the button should be disabled */
  disabled?: boolean
  /** Checks if the button should be in loading state */
  pending?: boolean
  /** Button stretch - should button be stretched to full width */
  stretch?: boolean
  /** shouldRemoveMinWidth - should remove minimum button width */
  shouldRemoveMinWidth?: boolean
  /** Replace standard loading text with custom */
  pendingText?: string
  /** Button variant - reflects the colour of border and background */
  variant?: BUTTON_VARIANTS
  /** Button size - reflects the size of the button */
  size?: BUTTON_SIZES
  /** Button type - reflects the action type of the button */
  type?: 'submit' | 'button' | 'reset'
  /** Button custom className */
  className?: string
  /** Button tooltip */
  tooltip?: React.ReactNode | string | string[] | FormikErrors<ICanvasStep>[] | null
  /** Button onClick handler */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  /** Unique identifier for Intercom product tours */
  intercomTarget?: string
  /** Clipboard text on copy */
  clipboardText?: string
  sx?: SxProps<Theme>
  removeBackground?: boolean
}

/**
 * -
 */
export const Button: React.FC<Props> = ({
  id = undefined,
  testId = undefined,
  disabled = false,
  pending = false,
  pendingText = '',
  tooltip,
  type = 'button',
  variant = 'primary',
  size = 'regular',
  stretch = false,
  className,
  onClick,
  children,
  shouldRemoveMinWidth = false,
  intercomTarget = undefined,
  clipboardText = undefined,
  removeBackground = false,
  sx,
}) => {
  const classes = useStyles({ type, size })

  const buttonClassName = clsx(
    classes.baseButton,
    {
      [classes.baseButtonStretch]: !!stretch,

      [classes.typePrimary]: variant === 'primary',
      [classes.typeSecondary]: variant === 'secondary',
      [classes.typeTertiary]: variant === 'tertiary',

      [classes.sizeRegular]: size === 'regular',
      [classes.sizeBig]: size === 'big',
      [classes.removeMidWidth]: shouldRemoveMinWidth,
      'clipboard-copy-button': !!clipboardText,
      [classes.removeBackground]: !!removeBackground,
    },
    className,
  )

  const progressClassList = clsx({
    [classes.progressIconPrimary]: variant === 'primary',
    [classes.progressIconSecondary]: variant === 'secondary',
    [classes.progressIconTertiary]: variant === 'tertiary',

    [classes.progressIconRegular]: size === 'regular',
    [classes.progressIconBig]: size === 'big',
  })

  return (
    <Tooltip content={tooltip!}>
      <ButtonBase
        id={id}
        data-test-id={testId}
        type={type}
        classes={{
          root: buttonClassName,
          disabled: classes.baseButtonDisabled,
        }}
        sx={sx}
        data-intercom-target={intercomTarget}
        disabled={disabled || pending}
        disableRipple
        disableTouchRipple
        onClick={onClick}
        data-clipboard-text={clipboardText}
      >
        {pending && pendingText ? pendingText : children}

        {pending && (
          <CircularProgress
            classes={{
              root: progressClassList,
            }}
            size={16}
          />
        )}
      </ButtonBase>
    </Tooltip>
  )
}
