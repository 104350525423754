import Box from '@mui/material/Box'
import numeral from 'numeral'

import { ChartTrendPeriod } from 'interfaces/rights/rightStats'
import { Text } from 'components/ui-kit/typography/Text'

type TooltipProps = {
  active?: boolean
  payload: any
  label: string
  trendPeriod?: ChartTrendPeriod
  startEndDifferenceInDays?: number
}

export const UserActivityQueriesGraphTooltip = ({ active, payload }: TooltipProps) => {
  if (active && payload) {
    const [{ payload: subPayload }] = payload
    return (
      <Box
        sx={{
          background: ({ palette }) => palette.darkDusk.main,
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          py: 1,
          pl: 1.25,
          pr: 3,
        }}
      >
        <Text size={12} weight={700} color="white" mb={0.5}>
          {subPayload?.date}
        </Text>

        <Text size={12} weight={500} color="white" mb={0.5}>
          {numeral(subPayload?.queryCount).format('0,0')} Queries
        </Text>
      </Box>
    )
  }

  return null
}
