import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchRelatedAssetsPaginatedV2 } from '../fetchers/fetchRelatedAssetsPaginatedV2'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.relatedResourcesPaginated,
  queryFn: fetchRelatedAssetsPaginatedV2,
  select: res => res?.data?.hierarchies || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useRelatedAssetsPaginatedV2 = (config?: QueryConfig) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )
  return useCustomPaginatedQuery({
    ...config,
    params: {
      isV2FeatureFlagEnabled,
      ...config?.params,
      start: 0,
      limit: 1000,
    },
  })
}
