import React from 'react'
import { useNavigate } from 'react-router-dom'

// dux
import { getEventsListFilterState } from 'store/eventsListFilterSlice/selectors'
import { setDataRole } from 'store/eventsListFilterSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'

// components
import { Box } from '@mui/material'
import { Button, TabSegmented, TabSegmentedGroup } from '@ketch-com/deck'

// utils
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

// types
import { DataRoleType } from 'interfaces/events'
import { EventV2DTO } from '@ketch-com/figurehead'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { EventsListFiltersV2 } from './EventsListFiltersV2'
type Props = {
  events: EventV2DTO[]
}

enum EventsTab {
  Controller = 'controller',
  Processor = 'processor',
}

export const EventsListHeader: React.FC<Props> = ({ events }) => {
  // dux
  const { dataRole } = useAppSelector(getEventsListFilterState)

  // tab
  const selectedTab = dataRole === DataRoleType.DATA_ROLE_CONTROLLER ? EventsTab.Controller : EventsTab.Processor

  // breadcrumbs
  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Events', link: RoutesManager.orchestration.events.root.getURL() },
  ]

  // handlers
  const handleCreateEvent = () => navigate(RoutesManager.orchestration.events.create.root.getURL())

  // hooks
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isPermitted } = useIsPermitted()

  //  utils
  const isPermittedToMapEvents = isPermitted([PERMISSIONS.WORKFLOW_ASSIGN])

  const handleSegmentTabChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    if (newValue === EventsTab.Controller) {
      dispatch(setDataRole(DataRoleType.DATA_ROLE_CONTROLLER))
    } else {
      dispatch(setDataRole(DataRoleType.DATA_ROLE_PROCESSOR))
    }
  }

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <EventsListFiltersV2 />
        <Box display="flex" alignItems="center" gap={4}>
          <TabSegmentedGroup
            canDeselectCurrentValue={false}
            value={selectedTab}
            exclusive
            onChange={handleSegmentTabChange}
            size="small"
          >
            <TabSegmented value={EventsTab.Controller} key="1">
              Controller
            </TabSegmented>
            <TabSegmented value={EventsTab.Processor} key="2">
              Processor
            </TabSegmented>
          </TabSegmentedGroup>
          {isPermittedToMapEvents && <Button onClick={handleCreateEvent}>Create Event</Button>}
        </Box>
      </Box>
    </>
  )
}
