import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateCommon.svg'
import { RoutesManager } from 'utils/routing/routesManager'
import { setNavigationSliceGoBack } from 'store/navigationSlice'
import { DataGrid, EmptyState } from '@ketch-com/deck'
import { FormFieldsListContext } from 'pages/consentAndRights/forms/fields/list/context/FormFieldsListContext'
import { FormMode } from 'interfaces/formModes/formMode'
import { useDebouncedSearchString } from '../hooks'
import { withFormFieldsListContext } from '../utils/withContext'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { FormFieldDTO } from '@ketch-com/figurehead'
import { useFieldsTabCustomFieldsListColumns, useFieldsTabDefaultFieldsListColumns } from '../utils/fieldsTabUtils'
import { GridRowParams } from '@mui/x-data-grid'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

const CustomFieldsSection = () => {
  const { searchString } = useDebouncedSearchString()
  const formFieldsListContext = useContext(FormFieldsListContext)
  const { infiniteCustomFormFieldsResp, isUserExperienceServerAdmin, location, dispatch, navigate } =
    formFieldsListContext

  const {
    data: infiniteCustomFormFieldsData,
    isLoading: isLoadingInfiniteCustomFormFields,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = infiniteCustomFormFieldsResp

  const columns = useFieldsTabCustomFieldsListColumns({ isUserExperienceServerAdmin })

  const isGridLoading = isFetchingNextPage || isLoadingInfiniteCustomFormFields

  return (
    <>
      {/* Custom Fields Table Section */}

      <Typography variant="h3" color="darkDuskFaded.main" mb={2}>
        Custom Fields
      </Typography>

      {/* Custom Fields Table */}
      {!isLoadingInfiniteCustomFormFields && !infiniteCustomFormFieldsData.length && !searchString ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={6}
          borderRadius="11px"
          bgcolor="white.main"
        >
          <EmptyState
            customIcon={<EmptyStateIcon />}
            title="There are no custom form fields for this organization"
            subTitle={isUserExperienceServerAdmin ? 'Create your first one to populate this section' : undefined}
            primaryButtonTitle={isUserExperienceServerAdmin ? 'Create Field' : undefined}
            primaryButtonProps={{
              size: 'large',
              onClick: () =>
                navigate(
                  RoutesManager.deployment.forms.fields.upsert.root.getURL({
                    id: NEW_ITEM_ID,
                    formMode: FormMode.CREATE,
                  }),
                ),
            }}
          />
        </Box>
      ) : (
        <Box mb={6} height="60vh">
          <DataGrid
            sx={{
              border: 'none',
              cursor: 'pointer',
              '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                backgroundImage: 'unset',
                cursor: 'auto',
              },
            }}
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              includeOutliers: false,
              expand: true,
            }}
            getRowHeight={() => 'auto'}
            columns={columns}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowSelectionOnClick
            getRowId={row => row.code}
            rows={infiniteCustomFormFieldsData || []}
            hideFooter
            loading={isGridLoading}
            noRowsOverlayProps={{
              buttonTitle: '',
            }}
            onRowClick={({ row: formField }: GridRowParams<FormFieldDTO>) => {
              dispatch(setNavigationSliceGoBack(location.pathname))
              const targetURL = RoutesManager.deployment.forms.fields.view.root.getURL({
                id: formField?.id || '',
              })
              navigate(targetURL)
            }}
            paginationLoading={isGridLoading}
            onRowsScrollEnd={() => {
              if (!isFetchingNextPage && hasNextPage) fetchNextPage()
            }}
          />
        </Box>
      )}
    </>
  )
}

type Props = {}

export const FieldsTabWithoutContext: React.FC<Props> = () => {
  const { searchString } = useDebouncedSearchString()
  const formFieldsListContext = useContext(FormFieldsListContext)
  const { defaultFormFields, isLoadingDefaultFormFields, isEntitledToExpServerRights } = formFieldsListContext

  const filteredDefaultFields = defaultFormFields.filter(
    formField => formField?.title?.toLowerCase?.().includes(searchString?.toLowerCase?.()),
  )

  const columns = useFieldsTabDefaultFieldsListColumns()

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Experiences', link: RoutesManager.deployment.experiencesV2.root.getURL() },
    { title: 'Forms', link: RoutesManager.deployment.forms.templates.root.getURL() },
    { title: 'Fields', link: RoutesManager.deployment.forms.fields.root.getURL() },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      {/* Default Fields Table Section */}

      <Typography variant="h3" color="darkDuskFaded.main" mb={2}>
        Default Fields
      </Typography>

      {/* Default Fields Table */}
      <Box mb={6}>
        <DataGrid
          sx={{
            border: 'none',
            ...(isLoadingDefaultFormFields && {
              '& .MuiDataGrid-virtualScroller': {
                height: '300px',
              },
            }),
          }}
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: false,
            expand: true,
          }}
          getRowHeight={() => 'auto'}
          columns={columns}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowHoverStates
          disableRowSelectionOnClick
          getRowId={row => row.code}
          rows={filteredDefaultFields || []}
          hideFooter
          loading={isLoadingDefaultFormFields}
          noRowsOverlayProps={{
            buttonTitle: '',
          }}
        />
      </Box>

      {/* Custom Fields Table Section */}
      {isEntitledToExpServerRights && <CustomFieldsSection />}
    </>
  )
}

export const FieldsTab = withFormFieldsListContext(FieldsTabWithoutContext)
