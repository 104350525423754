import { ElementType } from 'react'

import { ReactComponent as CompletedStatusIcon } from 'assets/icons/completed-status-icon.svg'
import { ReactComponent as RejectedStatusIcon } from 'assets/icons/rejected-status-icon.svg'
import { ReactComponent as DueSoonStatusIcon } from 'assets/icons/due-soon-status-icon.svg'
import { ReactComponent as OnTrackStatusIcon } from 'assets/icons/on-track-status-icon.svg'
import { ReactComponent as OverdueStatusIcon } from 'assets/icons/overdue-status-icon.svg'
import { IconMap } from '@ketch-com/deck'
import { RightInvocationDTO, RightInvocationRequestStatusDTO } from '@ketch-com/figurehead'

export const getRightInvocationStatusIcon = (rightInvocation: RightInvocationDTO): ElementType<any> => {
  const { requestStatus } = rightInvocation

  switch (requestStatus) {
    case RightInvocationRequestStatusDTO.FulfilledRightInvocationRequestStatus:
      return CompletedStatusIcon
    case RightInvocationRequestStatusDTO.RejectedRightInvocationRequestStatus:
      return RejectedStatusIcon
    case RightInvocationRequestStatusDTO.DueSoonRightInvocationRequestStatus:
      return DueSoonStatusIcon
    case RightInvocationRequestStatusDTO.DueTodayRightInvocationRequestStatus:
      return DueSoonStatusIcon
    case RightInvocationRequestStatusDTO.OverdueRightInvocationRequestStatus:
      return OverdueStatusIcon
    case RightInvocationRequestStatusDTO.OnTrackRightInvocationRequestStatus:
      return OnTrackStatusIcon
    default:
      return OnTrackStatusIcon
  }
}

export const getRightInvocationStatusIconName = (rightInvocation: RightInvocationDTO): keyof typeof IconMap => {
  const { requestStatus } = rightInvocation

  switch (requestStatus) {
    case RightInvocationRequestStatusDTO.FulfilledRightInvocationRequestStatus:
      return 'FCheckRound'
    case RightInvocationRequestStatusDTO.RejectedRightInvocationRequestStatus:
      return 'FCrossRound'
    case RightInvocationRequestStatusDTO.DueSoonRightInvocationRequestStatus:
      return 'OClock'
    case RightInvocationRequestStatusDTO.DueTodayRightInvocationRequestStatus:
      return 'OClock'
    case RightInvocationRequestStatusDTO.OverdueRightInvocationRequestStatus:
      return 'FClock'
    case RightInvocationRequestStatusDTO.OnTrackRightInvocationRequestStatus:
      return 'FPointer'
    default:
      return 'FPointer'
  }
}
