import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { UpsertLayout, FormRow, Complication, UpsertLayoutMode } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import { Values, formStepConfigs, initialValues } from './utils'
import { InputStepExample } from './components/InputStepExample'

interface Props {}

const getRenderedFormTitle = () => <Complication title="Create layout form example" mb="24px" />

export const EditLayoutFormExample: React.FC<Props> = props => {
  const [currentValidationSchema, setCurrentValidationSchema] = useState({} as Yup.ObjectSchema<any>)
  const [endText, setEndText] = useState('')

  useEffect(() => {
    let current = {} as Yup.ObjectSchema<any>
    formStepConfigs.forEach((elem, i) => {
      if (i === 0) {
        current = elem.validationSchema
      } else {
        current = current.concat(elem.validationSchema)
      }
    })
    setCurrentValidationSchema(current)
  }, [])

  const formikInstance = useFormik<Values>({
    initialValues: initialValues,
    onSubmit: () => {},
    validationSchema: currentValidationSchema,
  })

  const { values, errors, setErrors, validateForm } = formikInstance

  const onSubmit = async () => {
    const errors = await validateForm()
    if (Object.keys(errors).length) {
      return
    }
    setEndText('Done')
  }

  const onCancel = () => {
    setErrors({})
  }

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: theme => theme.palette.bone.main,
      }}
    >
      <FormikProvider value={formikInstance}>
        <UpsertLayout
          mode={UpsertLayoutMode.edit}
          onAccept={onSubmit}
          onCancel={onCancel}
          renderFormTitle={getRenderedFormTitle}
        >
          <FormRow title={formStepConfigs[0].title} subTitle="Subhead">
            <InputStepExample formFieldValue="firstValue" />
          </FormRow>
          <FormRow title={formStepConfigs[1].title} subTitle="Subhead">
            <InputStepExample formFieldValue="secondValue" />
          </FormRow>
          <FormRow title={formStepConfigs[2].title}>
            <InputStepExample formFieldValue="thirdValue" />
          </FormRow>

          <FormRow title={formStepConfigs[3].title} subTitle="Subhead">
            <InputStepExample formFieldValue="fourthValue" />
          </FormRow>
          <FormRow title={formStepConfigs[4].title}>
            <InputStepExample formFieldValue="fifthValue" />
          </FormRow>
        </UpsertLayout>
      </FormikProvider>
      {endText && <pre>{JSON.stringify(endText, null, 2)}</pre>}
      <pre>{JSON.stringify(values, null, 2) || 'nothing to preview'}</pre>
      <pre>{JSON.stringify(errors, null, 2) || 'nothing to preview'}</pre>
    </Box>
  )
}
