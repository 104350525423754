import { useCallback } from 'react'
import { createGlobalState, useEffectOnce } from 'react-use'

import { ObjectLiteral } from 'interfaces'

export function createUseFilters<T extends ObjectLiteral>(initialState: T) {
  const useCreateHookInstance = createGlobalState<T>(initialState)
  let instances = 0

  return function useFilters() {
    const [filters, setFilters] = useCreateHookInstance()

    useEffectOnce(() => {
      instances = instances + 1

      return () => {
        instances = instances - 1

        // reset global state on last instance unmount
        if (!instances) {
          setFilters(initialState)
        }
      }
    })

    const updater = useCallback(
      (nextState: Partial<T>) => {
        setFilters({ ...filters!, ...nextState })
      },
      [filters, setFilters],
    )

    return [filters!, updater] as const
  }
}
