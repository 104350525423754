import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

import { showToast } from 'components/ui-kit/toastr/Toastr'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { validationRegExp } from 'utils/constants/forms/validationRegExp'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export const useEditAutoDiscoveryFormSectionUtils = ({ assetSummary }: Props) => {
  const { values, setFieldValue } = useFormikContext<EditDatabaseFormValues>()
  const shouldShowStartOnInput = values?.schedule?.frequency === '2'
  const shouldShowEveryMonthInfo = values?.schedule?.frequency === '3'

  const handleFrequencyOnChange = (e: { id: string; name: string }) => {
    const selectedValue = e?.id
    if (selectedValue) {
      setFieldValue('schedule.frequency', selectedValue)
      if (selectedValue === '0') setFieldValue('schedule.isCustomizeStartingTimeChecked', false)
    }
  }

  const handleStartTimeInputOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // allow empty value to be set
    if (e.target.value === '') {
      setFieldValue('schedule.time', e.target.value)
      return
    }

    const isNumeric = validationRegExp.NUMERIC_INTEGER_STRING.test(
      e.target.value
        .split('')
        .filter(char => char !== ':')
        .join(''),
    )

    if (!isNumeric) {
      showToast({ content: 'Numbers only please', type: 'error' })
      return
    }

    if (e.target.value.length > 5) {
      showToast({ content: 'Invalid input', type: 'error' })
      return
    }
    setFieldValue('schedule.time', e.target.value)
  }

  /* reset time and period if "Customize Starting Time" checkbox is toggled to false */
  useEffect(() => {
    if (!values?.schedule?.isCustomizeStartingTimeChecked) {
      setFieldValue('schedule.time', '')
      setFieldValue('schedule.period', '1')
    }
  }, [values?.schedule?.isCustomizeStartingTimeChecked, setFieldValue, assetSummary])

  /* reset day if frequency changes to anything other than "weekly" */
  useEffect(() => {
    if (values?.schedule?.frequency !== '2') {
      setFieldValue('schedule.day', '')
    }
  }, [values?.schedule?.frequency, setFieldValue, assetSummary])

  return {
    shouldShowStartOnInput,
    shouldShowEveryMonthInfo,
    handleStartTimeInputOnChange,
    handleFrequencyOnChange,
    values,
  }
}
