import { createContext } from 'react'

export const NavigationUpdatesContext = createContext({
  onClose: () => {},
})

type Props = {
  children?: React.ReactNode
  onClose: () => void
}

export const NavigationUpdatesContextProvider: React.FC<Props> = ({ onClose, children }) => {
  return (
    <NavigationUpdatesContext.Provider
      value={{
        onClose,
      }}
    >
      {children}
    </NavigationUpdatesContext.Provider>
  )
}
