import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Response = {
  csrf_token: string
}

export const prepareFileUpload = () =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/v2/files/prepare-upload`,
    }),
    { withCredentials: true },
  )
