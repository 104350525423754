import React from 'react'
import { Box } from '@mui/material'
import { Chip, Status, StatusState, StatusVariant } from '@ketch-com/deck'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { CookieProvenanceNames } from 'interfaces/cookies/cookieProvenance'

type Props = {
  cookie: CookieDTO
}

export const CookieViewDetails: React.FC<Props> = ({ cookie }) => {
  const { code, status, latest, appCode, version, provenance } = cookie

  return (
    <Box display="flex" gap={1} alignItems="center">
      <Chip size="small" label={CookieProvenanceNames[provenance]} />
      {appCode && <Chip size="small" label={appCode} />}
      {status ? (
        <Status icon="Dot" variant={StatusVariant.outlined} status={StatusState.success} label="Disclosed" />
      ) : (
        <Status icon="Dot" variant={StatusVariant.outlined} status={StatusState.draft} label="Not Disclosed" />
      )}
      {!latest && <Chip size="small" label={`v${version}`} />}
      {!!code && <Chip size="small" label={`Code: ${appCode}`} />}
    </Box>
  )
}
