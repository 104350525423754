import Box from '@mui/material/Box'
import { Button, Icon, Tab, TabPanel, TabGroup, theme } from '@ketch-com/deck'
import React from 'react'
import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormRadioGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormRadioGroup'
import { SubscriptionListStyle } from '@ketch-sdk/ketch-types'
import { SubscriptionListStyleLabels } from '../../../../../../experiences-v2/upsert/utils/labels'
import {
  BuilderTextInputMode,
  FormTextInput,
} from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'
import { getIn, useFormikContext } from 'formik'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { Typography } from '@mui/material'

export const PreferenceThemeSubscriptionsTabListForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ThemeV3DTO>()

  const isFilledStyle =
    getIn(values, themeUpsertFormKeys.preferenceSubscriptionsTabListStyle) === SubscriptionListStyle.Filled

  // Current tab of the button form
  const [tab, setTab] = React.useState(0)

  // Copy the primary button theme to the secondary or tertiary one
  const handleApplyPurposesStyleClick = () => {
    const purposesSwitchButtonTheme = getIn(values, themeUpsertFormKeys.preferencePurposesTabListSwitchButtons)
    const copyTo = themeUpsertFormKeys.preferenceSubscriptionsTabListSwitchButton
    setFieldValue(copyTo, purposesSwitchButtonTheme)
  }

  return (
    <>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
            <Tab size={'medium'} label="Layout" />
            <Tab size={'medium'} label="Switch Button" />
            <Tab size={'medium'} label="Checkbox" />
          </TabGroup>
        </Box>
        {/* List items form */}
        <TabPanel value={tab} index={0} sx={{ px: 0 }}>
          <SidebarFormGroup sx={{ paddingTop: 0 }}>
            <SidebarFormRadioGroup
              name={themeUpsertFormKeys.preferenceSubscriptionsTabListStyle}
              title={'List style'}
              options={Object.values(SubscriptionListStyle).map(style => ({
                label: SubscriptionListStyleLabels[style],
                value: style,
              }))}
              row
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceSubscriptionsTabListBackgroundColor}
              label={isFilledStyle ? 'Subscription Fill Color' : 'Subscription Underline Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceSubscriptionsTabListTextColor}
              label={'Subscription Content Color'}
              fullWidth
              required
            />
            {/* NOTE: Descoped */}
            {/* <FormColorInput
              name={themeUpsertFormKeys.preferenceSubscriptionsTabListLinkColor}
              label={'Subscription Links Color'}
              fullWidth
              required
            /> */}
            {isFilledStyle && (
              <FormTextInput
                name={themeUpsertFormKeys.preferenceSubscriptionsTabListCornerRadius}
                label={'Subscription Corner Radius (px)'}
                mode={BuilderTextInputMode.CornerRadius}
                inputWidth="92px"
              />
            )}
          </SidebarFormGroup>
        </TabPanel>

        {/* Switch buttons form */}
        <TabPanel value={tab} index={1} sx={{ px: 0 }}>
          <SidebarFormGroup sx={{ paddingTop: 0 }}>
            <FormColorInput
              name={themeUpsertFormKeys.preferenceSubscriptionsTabListSwitchButtonOnBackgroundColor}
              label={'Switch Button ON Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceSubscriptionsTabListSwitchButtonOnTextColor}
              label={'Switch Button ON Text Label Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceSubscriptionsTabListSwitchButtonOffBackgroundColor}
              label={'Switch Button OFF Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceSubscriptionsTabListSwitchButtonOffTextColor}
              label={'Switch Button OFF Text Label Color'}
              fullWidth
              required
            />

            {/* Apply style from purposes */}
            <Box>
              <Button
                color={'tertiary'}
                endIcon={<Icon name={'OPaintbrush'} iconColor={theme.palette.sphere.main} />}
                fullWidth={false}
                onClick={handleApplyPurposesStyleClick}
              >
                Apply Style from Purposes
              </Button>
              <Typography component={'p'} variant={'smallBody'} color={theme.palette.darkDuskFaded.main} mt={'6px'}>
                Click here to apply the switch button styles as defined on the Purposes page.
              </Typography>
            </Box>
          </SidebarFormGroup>
        </TabPanel>

        {/* Checkbox form */}
        <TabPanel value={tab} index={2} sx={{ px: 0 }}>
          <SidebarFormGroup sx={{ paddingTop: 0 }}>
            <FormColorInput
              name={themeUpsertFormKeys.preferenceSubscriptionsTabListCheckboxSelectedBackgroundColor}
              label={'Checkbox SELECTED Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceSubscriptionsTabListCheckboxSelectedTextColor}
              label={'Checkbox SELECTED Text Label Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceSubscriptionsTabListCheckboxUnselectedBackgroundColor}
              label={'Checkbox NOT SELECTED Color'}
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferenceSubscriptionsTabListCheckboxUnselectedTextColor}
              label={'Checkbox NOT SELECTED Text Label Color'}
              fullWidth
              required
            />
          </SidebarFormGroup>
        </TabPanel>
      </Box>
    </>
  )
}
