import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { useState } from 'react'

export const ModelBiasWidgetActionSheet = ({
  handleDownloadModelBiasFile,
  handleOnRemove,
  pending,
  canWrite,
}: {
  handleDownloadModelBiasFile: () => void
  handleOnRemove: () => void
  pending: boolean
  canWrite: boolean
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button size="medium" onClick={handleClickListItem} variant="iconSubtle" color="tertiary" pending={pending}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
        <ActionSheetItem
          onClick={() => {
            handleClose()
            handleDownloadModelBiasFile()
          }}
        >
          Download
        </ActionSheetItem>
        {canWrite && (
          <>
            <ActionSheetItem divider />
            <ActionSheetItem
              onClick={() => {
                handleClose()
                handleOnRemove()
              }}
            >
              Remove
            </ActionSheetItem>
          </>
        )}
      </ActionSheetWrapper>
    </>
  )
}
