import { useContext } from 'react'
import { RightInvocationWorkflowStepDetailViewContext } from '../../../../context/RightInvocationWorkflowStepDetailViewContext'
import { useFilesInfoList } from 'api/files/queries/useFilesInfoList'
import { useUserMe } from 'api/users/queries/useUserMe'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { useIsPermitted } from 'utils/hooks'
import { RoutesManager } from 'utils/routing/routesManager'
import { retrieveVersionAndFolder } from '../utils'
import { fromAssignee } from 'utils/helpers/teamStringParser'

export const useEndFlowUtils = () => {
  const { isPermitted } = useIsPermitted()
  const {
    isReady,
    stepId,
    rightInvocation,
    workflowStep,
    stepDetails,
    workflowExecutionId,
    rightInvocationId,
    isWorkflowExecutionTerminated,
    workflowExecution,
  } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const { data: userMe } = useUserMe()

  // form utils
  const subjectCommunicationMessage =
    stepDetails?.manualInterventionData?.subjectCommunicationMessage ||
    stepDetails?.executionData?.end?.subjectCommunicationMessage ||
    ''
  const coverLetterFromExecutionData = stepDetails?.executionData?.end?.coverLetter || []
  let defaultFolder = `${rightInvocation?.id}_${stepId}`
  let defaultVersion = 'cover_letter'

  let folder = defaultFolder
  let version = defaultVersion

  if (coverLetterFromExecutionData?.length && !!coverLetterFromExecutionData[0]) {
    let result = retrieveVersionAndFolder(coverLetterFromExecutionData)
    folder = result.folder
    version = result.version
  }

  const { data: coverLetter } = useFilesInfoList({
    params: {
      version,
      folder,
    },
  })

  // component utils
  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    { title: 'Rights Queue', link: RoutesManager.orchestration.rightsQueue.root.getURL() },
    {
      title: rightInvocation?.policyScopeName,
      link: RoutesManager.orchestration.rightsQueue.view.root.getURL({ id: workflowExecutionId }),
    },
    {
      title: 'Task',
      link: RoutesManager.orchestration.rightsQueue.stepDetails.root.getURL({
        id: workflowExecutionId,
        stepId,
        workflowExecutionId,
      }),
    },
  ]

  const isComplete = workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS
  const [team, teamAssignee] = fromAssignee(stepDetails?.assignee || '')
  const isAssignedToMe = teamAssignee === userMe?.ID
  const isRightsQueueAdmin = isPermitted([PERMISSIONS.RTINVQ_READ, PERMISSIONS.RTINVQ_WRITE], true)
  const canReassign = isRightsQueueAdmin && !isComplete && !isWorkflowExecutionTerminated

  const payload = {
    breadcrumbs,
    canReassign,
    coverLetter,
    isAssignedToMe,
    isComplete,
    isReady,
    isWorkflowExecutionTerminated,
    rightInvocation,
    rightInvocationId,
    stepDetails,
    stepId,
    subjectCommunicationMessage,
    workflowExecutionId,
    workflowStep,
    workflowExecution,
    team,
    teamAssignee,
  }

  return payload
}

export type UseEndFlowUtils = ReturnType<typeof useEndFlowUtils>
