import React from 'react'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces'
import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { COOKIE_KETCH_LIBRARY_KEY } from 'interfaces/cookies/cookie'
import { RoutesManager } from 'utils/routing/routesManager'
import { useCookiesPaginated } from 'api/cookies/queries/useCookiesPaginated'
import { CookiesLibraryView } from 'pages/policyCenter/cookies/library/CookiesLibraryView'

export const CookiesLibraryContainer: React.FC = () => {
  const navigate = useNavigate()

  const {
    data: cookies,
    isLoading,
    isFetching,
    pagination,
  } = useCookiesPaginated({
    params: {
      includeMetadata: true,
      includeIssues: true,
      appCode: COOKIE_KETCH_LIBRARY_KEY,
      used: false,
    },
  })

  const handleSubmit = async (values: { cookieCode: MaybeNull<string> }) => {
    const { cookieCode } = values

    if (cookieCode) {
      navigate(RoutesManager.policyCenter.cookies.upsert.root.getURL({ code: NEW_ITEM_CODE, ketchCode: cookieCode }))
    } else {
      navigate(RoutesManager.policyCenter.cookies.upsert.root.getURL({ code: NEW_ITEM_CODE }))
    }
  }

  return (
    <CookiesLibraryView
      cookies={cookies}
      pagination={pagination}
      isLoading={isLoading}
      isFetching={isFetching}
      onSubmit={handleSubmit}
    />
  )
}
