import React from 'react'
import { TrackerWithNewPurposes } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { Button } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useConfirmTrackerWithNewPurposes } from 'api/trackers/mutations/useConfirmTrackerWithNewPurposes'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'

type Props = {
  tracker: TrackerWithNewPurposes
}

export const ConfirmTrackerWithNewPurposes: React.FC<Props> = ({ tracker }) => {
  const queryClient = useQueryClient()
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])

  const { mutateAsync: confirmTrackerWithNewPurposes, isLoading: isConfirmingTrackerWithNewPurposes } =
    useConfirmTrackerWithNewPurposes({
      onSuccess: async () => {
        await queryClient.invalidateQueries([ApiQueryKeys.trackersWithNewPurposesInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.trackerStatistics])
        showToast({ content: 'Updated tracker purposes', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Unable to update tracker purposes', type: 'error' })
      },
    })

  return (
    <Button
      disabled={isConfirmingTrackerWithNewPurposes || !isPermittedToWriteCookie}
      color="tertiary"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        confirmTrackerWithNewPurposes({
          params: { encodedTrackerKey: tracker?.encodedKey },
        })
      }}
    >
      Confirm
    </Button>
  )
}
