import React from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { Box, Button as MuiButton, Popover } from '@mui/material'
import { addDays, format } from 'date-fns'
import { DayPicker } from 'react-day-picker'
import { Button } from 'components/ui-kit/button/Button'

import { EndIcon } from 'components/ui-kit/dropdown/DropdownV2/components'
import { getTrendDateRangePickerSxProps } from './utils'
import { useAppDispatch } from 'store/hooks'
import { updateDateRange, updateTrendPeriod } from 'store/permitStatisticsFilterStateSlice'
import { ChartTrendPeriod } from 'interfaces/rights/rightStats'
import { getApplyButtonTooltip } from './utils/getApplyButtonTooltip'
import { useDateRangePickerUtils } from './hooks'
import 'react-day-picker/dist/style.css'

const PREFIX = 'DateRangePicker'

const classes = {
  selectedDateRangeButton: `${PREFIX}-selectedDateRangeButton`,
  trendDatePickerButton: `${PREFIX}-trendDatePickerButton`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.selectedDateRangeButton}`]: {
    outline: `1px solid ${palette.sphere.main}`,
  },

  [`& .${classes.trendDatePickerButton}`]: {
    border: 'none',
  },
}))

const css = `
  .my-selected:not([disabled]) {
    background-color: rgba(87, 116, 255, 0.3);
  }
`

type Props = {
  size?: 'medium' | 'large' | 'small' | undefined
}

const currentDate = new Date()

export const DateRangePicker: React.FC<Props> = ({ size = 'small' }) => {
  const dispatch = useAppDispatch()
  const {
    anchorEl,
    handleClick,
    handleCloseWithoutApply,
    isOpen,
    popoverId,
    range,
    setRange,
    setAnchorEl,
    startEndDifferenceInDays,
    differenceInDaysLocalComponentRange,
    disabledDays,
  } = useDateRangePickerUtils()

  return (
    <StyledBox>
      <style>{css}</style>

      <MuiButton
        size={size}
        variant="contained"
        disableRipple
        disableFocusRipple
        disableElevation
        onClick={handleClick}
        endIcon={<EndIcon isPending={false} isDropdownOpen={false} />}
        sx={getTrendDateRangePickerSxProps()}
      >
        {`${range?.from ? format(range?.from || 0, 'MMM dd, yyyy') : 'From date'} - ${
          range?.to ? format(range?.to || 0, 'MMM dd, yyyy') : 'To date'
        }`}
      </MuiButton>

      <Popover
        id={popoverId}
        open={isOpen}
        PaperProps={{
          sx: {
            minWidth: '400px',
            p: 1.25,
            borderRadius: 1.5,
          },
        }}
        anchorEl={anchorEl}
        onClose={handleCloseWithoutApply}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {/* Date Picker Input */}

        <DayPicker
          mode="range"
          disabled={disabledDays}
          selected={range}
          onSelect={setRange}
          numberOfMonths={2}
          modifiersClassNames={{
            selected: 'my-selected',
            today: 'my-today',
          }}
          modifiersStyles={{
            disabled: { fontSize: '75%' },
          }}
        />

        <Box mt={1} display="flex" alignItems="center" justifyContent="flex-end" gap={1.25}>
          <Button
            className={clsx({
              [classes.selectedDateRangeButton]: differenceInDaysLocalComponentRange === -30,
            })}
            variant="tertiary"
            onClick={e => {
              e.preventDefault()
              setRange({ from: addDays(currentDate, -30), to: currentDate })
            }}
          >
            Last 30 Days
          </Button>

          <Button
            className={clsx({
              [classes.selectedDateRangeButton]: differenceInDaysLocalComponentRange === -60,
            })}
            variant="tertiary"
            onClick={e => {
              e.preventDefault()
              setRange({ from: addDays(currentDate, -60), to: currentDate })
            }}
          >
            Last 60 Days
          </Button>

          <Button
            className={clsx({
              [classes.selectedDateRangeButton]: differenceInDaysLocalComponentRange === -90,
            })}
            variant="tertiary"
            onClick={e => {
              e.preventDefault()
              setRange({ from: addDays(currentDate, -90), to: currentDate })
            }}
          >
            Last 90 Days
          </Button>

          <Button
            tooltip={getApplyButtonTooltip(range?.to, range?.from)}
            disabled={!range?.from || !range?.to}
            variant="primary"
            onClick={e => {
              e.preventDefault()
              // set redux store state to local component state to reflect user edits to date range
              const payload = { startDate: range?.from?.toISOString() || '', endDate: range?.to?.toISOString() || '' }
              dispatch(updateDateRange(payload))
              // if the date range is greater than 4 months automatically apply switch view to "monthly"
              if (startEndDifferenceInDays > 120) {
                dispatch(updateTrendPeriod(ChartTrendPeriod.MONTHLY_PAST_TWELVE))
              }
              setAnchorEl(null)
            }}
          >
            Apply
          </Button>
        </Box>
      </Popover>
    </StyledBox>
  )
}
