import { MaybeNull } from 'interfaces'
import { CookieDTO, CookieFormDTO } from 'interfaces/cookies/cookie'
import { CookieDurations } from 'interfaces/cookies/cookieDurations'
import { CookieProvenance } from 'interfaces/cookies/cookieProvenance'
import { CookieCategory } from 'interfaces/cookies/cookieCategory'

export const getCookieInitialValues = (
  cookie: MaybeNull<CookieDTO>,
  ketchCookie?: MaybeNull<CookieDTO>,
): CookieFormDTO => ({
  ID: cookie?.ID,
  code: ketchCookie?.code || cookie?.code || '',
  name: ketchCookie?.name || cookie?.name || '',
  appCode: ketchCookie?.appCode || cookie?.appCode || 'Custom',
  serviceProvider: ketchCookie?.serviceProvider || cookie?.serviceProvider || '',
  host: ketchCookie?.host || cookie?.host || '',
  duration: ketchCookie?.duration || cookie?.duration || CookieDurations.SESSION,
  provenance: ketchCookie?.provenance || cookie?.provenance || CookieProvenance.FIRST_PARTY,
  category: ketchCookie?.category || cookie?.category || CookieCategory.STRICTLY_NECESSARY,
  description: ketchCookie?.description || cookie?.description || '',
  purposes: ketchCookie?.purposes || cookie?.purposes || [],
  propertyCookies: ketchCookie?.propertyCookies || cookie?.propertyCookies || [],
})
