// layout utils
import { ProtectedRoute } from 'components/ui-routing/ProtectedRoute'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'

// pages
import { AssetsHomePageContainer } from 'pages/assetManager/assets/home/AssetsHomePageContainer'
import { AssetsHomePageContainerV2 } from 'pages/assetManager/assets/homeV2/AssetsHomePageContainer'
import { RelationshipsContainer } from 'pages/assetManager/relationships/list/RelationshipsContainer'
import { RelationshipMappingContainer } from 'pages/assetManager/relationshipMapping/RelationshipMappingContainer'
import { AssetDetailViewContainer } from './assets/view/AssetDetailViewContainer'
import { AssetUpsertContainer } from './assets/upsert/AssetUpsertContainer'
import { AssetsListViewContainer } from 'pages/assetManager/assets/list/AssetsListViewContainer'
import { ClassificationsContainer } from 'pages/assetManager/classifications/ClassificationsContainer'
import { DSRConfigUpsertContainer } from 'pages/assetManager/dsrConfig/upsert/DSRConfigUpsertContainer'
import { DSRIdentityLookupContainer } from 'pages/assetManager/dsrIdentityLookup/DSRIdentityLookupContainer'
import { ScenarioDetailsContainer } from 'pages/assetManager/scenario/details/ScenarioDetailsContainer'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'

export const assetManagerRoutesChildren = [
  {
    element: (
      <ProtectedRoute
        component={isRelease(Release.DataSystems) ? AssetsHomePageContainerV2 : AssetsHomePageContainer}
        permissions={PERMISSIONS.ASSET_READ}
      />
    ),
    path: RoutesManager.assetManager.root.pattern,
  },
  {
    element: (
      <ProtectedRoute
        component={isRelease(Release.DataSystems) ? AssetsHomePageContainerV2 : AssetsHomePageContainer}
        permissions={PERMISSIONS.ASSET_READ}
      />
    ),
    path: RoutesManager.assetManager.assets.root.pattern,
  },
  {
    element: <ProtectedRoute component={AssetsListViewContainer} permissions={PERMISSIONS.ASSET_READ} />,
    path: RoutesManager.assetManager.assets.list.pattern,
  },
  {
    element: <ProtectedRoute component={RelationshipsContainer} permissions={PERMISSIONS.ASSET_RELATIONSHIP_READ} />,
    path: `${RoutesManager.assetManager.relationships.list.pattern}/*`,
  },
  {
    element: (
      <ProtectedRoute component={RelationshipMappingContainer} permissions={PERMISSIONS.ASSET_RELATIONSHIP_READ} />
    ),
    path: `${RoutesManager.assetManager.relationshipMapping.list.pattern}/*`,
  },
  {
    element: <ProtectedRoute component={ClassificationsContainer} permissions={PERMISSIONS.LABEL_READ} />,
    path: RoutesManager.assetManager.classifications.root.pattern,
  },

  /* Asset Detail (general) */
  {
    element: <ProtectedRoute component={AssetDetailViewContainer} permissions={PERMISSIONS.ASSET_READ} />,
    path: `${RoutesManager.assetManager.assets.detail.root.pattern}/*`,
  },

  {
    element: <ProtectedRoute component={ScenarioDetailsContainer} permissions={PERMISSIONS.ASSET_READ} />,
    path: `${RoutesManager.assetManager.assets.detail.scenarioDetailsTab.pattern}/*`,
  },

  {
    element: <ProtectedRoute component={DSRConfigUpsertContainer} permissions={PERMISSIONS.ASSET_WRITE} />,
    path: RoutesManager.assetManager.assets.detail.dsrConfigUpsert.pattern,
  },

  {
    element: <ProtectedRoute component={AssetUpsertContainer} permissions={PERMISSIONS.ASSET_WRITE} />,
    path: RoutesManager.assetManager.assets.upsert.pattern,
  },
  {
    element: <ProtectedRoute component={DSRIdentityLookupContainer} permissions={PERMISSIONS.ASSET_WRITE} />,
    path: RoutesManager.assetManager.assets.detail.dsrConfigIdentity.pattern,
  },
]
