import { IBaseActivityParams, ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'

interface IGetAppInitialValuesParams {
  step: ICanvasStep
}

export const getAppInitialValues = (params: IGetAppInitialValuesParams): IBaseActivityParams => {
  const { step } = params
  const description = step.description || ''
  const code = step.code || ''

  return {
    description,
    code,
  }
}
