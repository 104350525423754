import { formatRequestString } from 'utils/helpers/api/queryString'
import { API } from 'utils/api/api'
import { PutAssetRequestBodyDTO } from '@ketch-com/figurehead'

type Params = {
  organizationCode: string
  assetCode: string
  formData: PutAssetRequestBodyDTO
  resourceTypeCode?: string
  isV2FeatureFlagEnabled?: boolean
}

type Response = any

export const updateAssetV2 = ({ assetCode, formData, resourceTypeCode, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/assets/${assetCode}`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/assets/${assetCode}`

  return API.put<Response>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          ...(resourceTypeCode && {
            resourceTypeCode,
          }),
        },
      },
    }),
    formData,
  )
}
