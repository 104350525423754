import { Box, FormControlLabel, RadioGroup, Typography } from '@mui/material'
import { FormFieldDTO } from '@ketch-com/figurehead'
import React from 'react'
import { FormDataInputValue } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'
import { Radio } from '@ketch-com/deck'

type Props = {
  formField?: FormFieldDTO
  formValue?: FormDataInputValue
  setFormValue?: (v: FormDataInputValue) => void
}
export const FormFieldListValueInput: React.FC<Props> = ({ formField, formValue, setFormValue }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography mb={0.5} variant="smallLabel">
        New Value
      </Typography>
      <RadioGroup
        value={formValue}
        onChange={(e, value) => {
          setFormValue?.(value)
        }}
      >
        {formField?.options?.map((v, index) => {
          return <FormControlLabel key={index} value={v.value} control={<Radio />} label={v.label} />
        })}
      </RadioGroup>
    </Box>
  )
}
