import { groupBy, find } from 'lodash'

import { MaybeNull } from 'interfaces/common'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans/deploymentPlan'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { ExperienceType } from 'interfaces/experiences/experienceType'
import {
  DeploymentPlanFormValues,
  DeploymentPlanFormSpecialValues,
} from 'pages/consentAndRights/deploymentPlans/upsert/utils'

export enum DeploymentPlanFormStep {
  BASIC_DETAILS = 'Basic Details',
  EXPERIENCES = 'Experiences',
  DOCUMENTS = 'Documents',
}

const getPolicyScopeExperiencesReducer = ({
  deploymentPlan,
  duplicate,
}: {
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
  duplicate: boolean
}) => {
  const existing = groupBy(deploymentPlan?.policyScopeExperiences, 'policyScopeCode')

  return (acc: {}, { code }: PolicyScopeDTO): DeploymentPlanFormValues['policyScopeExperiences'] => {
    if (duplicate) {
      const consent = find(existing[code], ({ experienceType }) => experienceType === ExperienceType.CONSENT)
      const preference = find(existing[code], ({ experienceType }) => experienceType === ExperienceType.PREFERENCE)

      return {
        ...acc,
        [code]: {
          [ExperienceType.CONSENT]: {
            experienceID: consent ? consent.experienceID || DeploymentPlanFormSpecialValues.NO_EXPERIENCE : '',
            experienceDefault: consent ? consent.experienceDefault : '',
          },
          [ExperienceType.PREFERENCE]: {
            experienceID: preference ? preference.experienceID || DeploymentPlanFormSpecialValues.NO_EXPERIENCE : '',
          },
        },
      }
    } else {
      return {
        ...acc,
        [code]: {
          [ExperienceType.CONSENT]: {
            experienceID: '',
            experienceDefault: '',
          },
          [ExperienceType.PREFERENCE]: {
            experienceID: '',
          },
        },
      }
    }
  }
}

export const getInitialValues = ({
  duplicate,
  policyScopes,
  deploymentPlan,
}: {
  duplicate: boolean
  policyScopes: PolicyScopeDTO[]
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
}): DeploymentPlanFormValues => {
  const policyScopeExperiences = (policyScopes || []).reduce(
    getPolicyScopeExperiencesReducer({ duplicate, deploymentPlan }),
    {},
  )

  if (duplicate) {
    return {
      code: '',
      name: '',
      privacyPolicyCode: deploymentPlan?.privacyPolicyCode || DeploymentPlanFormSpecialValues.NO_DOCUMENT,
      termsOfServiceCode: deploymentPlan?.privacyPolicyCode || DeploymentPlanFormSpecialValues.NO_DOCUMENT,
      themeID: deploymentPlan?.themeID || '',
      policyScopeExperiences,
    }
  }

  return {
    code: '',
    name: '',
    privacyPolicyCode: '',
    termsOfServiceCode: '',
    themeID: '',
    policyScopeExperiences,
  }
}
