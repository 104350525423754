import { StatusState } from '@ketch-com/deck'
import { RightInvocationDTO, RightInvocationRequestStatusDTO } from '@ketch-com/figurehead'

export const getRightInvocationCanonicalStatusVariantV2 = (rightInvocation: RightInvocationDTO): StatusState => {
  const { requestStatus } = rightInvocation

  switch (requestStatus) {
    case RightInvocationRequestStatusDTO.FulfilledRightInvocationRequestStatus:
      return StatusState.success
    case RightInvocationRequestStatusDTO.RejectedRightInvocationRequestStatus:
      return StatusState.finished
    case RightInvocationRequestStatusDTO.DueSoonRightInvocationRequestStatus:
      return StatusState.warning
    case RightInvocationRequestStatusDTO.DueTodayRightInvocationRequestStatus:
      return StatusState.error
    case RightInvocationRequestStatusDTO.OverdueRightInvocationRequestStatus:
      return StatusState.error
    case RightInvocationRequestStatusDTO.OnTrackRightInvocationRequestStatus:
      return StatusState.inProgress
    default:
      return StatusState.inProgress
  }
}
