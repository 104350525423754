import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  organizationCode: string
  inviteeEmail: string
}>

type Response = {}

export const deleteInvite = ({ organizationCode, inviteeEmail }: Params) =>
  API.delete<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/invitations/${inviteeEmail}`,
    }),
  )
