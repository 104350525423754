import React from 'react'
import { Status, StatusState, StatusVariant } from '@ketch-com/deck'

type Props = {
  active: boolean
  activeTitle: string
  inactiveTitle: string
}

export const StatusRenderer: React.FC<Props> = ({ active, activeTitle, inactiveTitle }) => {
  return (
    <Status
      variant={StatusVariant.ghost}
      label={active ? activeTitle : inactiveTitle}
      status={active ? StatusState.success : StatusState.draft}
      icon={active ? 'OCheck' : 'OCross'}
    />
  )
}
