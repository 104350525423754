import React from 'react'

import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { useHandleEditOnClick, useHandleViewAssetOnClick } from '../hooks'
import { Button } from '@ketch-com/deck'
import { Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'

import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  ({ spacing, typography, palette }) =>
    createStyles({
      withMarginRight: {
        marginRight: spacing(3.5),
      },
      closeIcon: {
        fontSize: typography.pxToRem(24),
        color: palette.darkDusk.main,
        cursor: 'pointer',

        '&:hover': {
          opacity: 0.7,
        },
      },

      checkIcon: {
        fontSize: typography.pxToRem(24),
        color: palette.sphere.main,
        cursor: 'pointer',

        '&:hover': {
          opacity: 0.7,
        },
      },
    }),
  { name: 'ReviewIssuesModalActionsCellRenderer' },
)

type Props = {
  asset: AssetSummaryDTO
}
/*
    TODO:BAC - note, this component is a work in progress.  It's not rendered in the UI yet.
    It will be a helper component the the "REVIEW LABELS" workflow.
*/
export const ReviewIssuesModalActionsCellRenderer: React.FC<Props> = ({ asset }) => {
  const classes = useStyles()
  const handleEditOnClick = useHandleEditOnClick(asset)
  const handleViewOnClick = useHandleViewAssetOnClick(asset)
  const isApproved = false

  if (!isApproved) {
    return (
      <Box display="flex" alignItems="center">
        <Button className={classes.withMarginRight} color="tertiary" onClick={() => {}}>
          <CheckIcon className={classes.checkIcon} />
        </Button>

        <Button color="tertiary" onClick={() => {}}>
          <CloseIcon className={classes.closeIcon} />
        </Button>
      </Box>
    )
  } else {
    return (
      <ButtonOptions
        items={[
          {
            content: 'View',
            onClick: () => handleViewOnClick(),
          },
          {
            content: 'Assign',
            onClick: () => {},
          },
          {
            content: 'Edit',
            onClick: () => handleEditOnClick(),
          },
        ]}
      />
    )
  }
}
