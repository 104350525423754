import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useOrchestrationEvents } from 'api/orchestrationEventsV2/queries/useOrchestrationEvents'
import { useWorkflow } from 'api/workflows/queries/useWorkflow'
import { useWorkflowVersion } from 'api/workflows/queries/useWorkflowVersion'
import { useWorkflowVersions } from 'api/workflows/queries/useWorkflowVersions'
import { WorkflowView } from 'pages/orchestration/workflows/view/WorkflowView'
import { useWorkflowSummary } from 'api/workflows/queries/useWorkflowSummary'

export const WorkflowViewContainer: React.FC = () => {
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()
  const { queries } = useQueryParams<{ version?: string }>()
  const { version } = queries

  const handleRedirectToWorkflowsList = () => {
    navigate(RoutesManager.orchestration.workflows.root.getURL())
  }

  const { data: events, isLoading: isEventsListLoading } = useOrchestrationEvents()

  const { data: workflow, isLoading: isWorkflowLoading } = useWorkflow({
    params: {
      workflowCode: code!,
      includeIssues: true,
      includeMetadata: true,
    },
    onError: handleRedirectToWorkflowsList,
  })

  const { data: workflowSummary, isLoading: isWorkflowSummaryLoading } = useWorkflowSummary({
    params: {
      workflowCode: code!,
    },
    onError: handleRedirectToWorkflowsList,
  })

  const { data: workflowVersion, isLoading: isWorkflowVersionLoading } = useWorkflowVersion({
    enabled: !!version && !!workflow,
    params: {
      workflowCode: workflow?.code as string,
      version: version!,
    },
    onError: handleRedirectToWorkflowsList,
  })

  const { data: versions, isLoading: isVersionsLoading } = useWorkflowVersions({
    enabled: !!workflow,
    params: {
      workflowCode: workflow?.code as string,
      includeMetadata: true,
      ordering: {
        version: 'desc',
      },
    },
  })

  const isWorkflowEntityLoading = version ? isWorkflowVersionLoading : isWorkflowLoading
  const isReady = !isWorkflowSummaryLoading && !isVersionsLoading && !isEventsListLoading && !isWorkflowEntityLoading

  return (
    <WorkflowView
      isReady={isReady}
      workflow={version ? workflowVersion : workflow}
      workflowSummary={workflowSummary}
      events={events}
      versions={versions}
    />
  )
}
