import React, { useState } from 'react'
import { Box, Popover } from '@mui/material'
import { Icon, IconDictionary, Button, theme } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { UnapprovedTracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { useUpsertTrackerDisclosure } from 'api/trackers/mutations/useUpsertTrackerDisclosure'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { PropertyDisclosureAndBlocking } from 'pages/policyCenter/trackers/list/unapprovedTrackers/components'
import { useUpsertTrackerBlocking } from 'api/trackers/mutations/useUpsertTrackerBlocking'
import { useFeatureFlag } from 'utils/hooks'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type Props = {
  tracker: UnapprovedTracker
}

export const DisclosureAndBlocking: React.FC<Props> = ({ tracker = {} as UnapprovedTracker }) => {
  const queryClient = useQueryClient()
  const trackerPropertiesItems = tracker?.properties
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const { isFeatureFlagEnabled } = useFeatureFlag()

  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const isTrackerBlockingRelease =
    getIsPermitted([PERMISSIONS.DEMO]) ||
    isFeatureFlagEnabled(
      FEATURE_FLAGS.PROPERTY_TAGS_TRACKER_BLOCKING,
      FEATURE_FLAGS_VALUES.PROPERTY_TAGS_TRACKER_BLOCKING_ENABLED,
    )
  const isTrackerDisclosedInSomeProperties = trackerPropertiesItems?.some(item => item?.isDisclosed)
  const isTrackerDisclosedInAllProperties = trackerPropertiesItems?.every(item => item?.isDisclosed)
  const isTrackerBlockedInSomeProperties = trackerPropertiesItems?.some(item => item?.isBlocked)
  const isTrackerBlockedInAllProperties = trackerPropertiesItems?.every(item => item?.isBlocked)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const { mutateAsync: upsertUnapprovedTrackerDisclosure, isLoading: isUpdatingUnapprovedTrackerDisclosure } =
    useUpsertTrackerDisclosure({
      onSuccess: async () => {
        await queryClient.invalidateQueries([ApiQueryKeys.unapprovedTrackersInfinite])
        showToast({ content: 'Updated disclosure', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to update disclosure', type: 'error' })
      },
    })

  const { mutateAsync: upsertUnapprovedTrackerBlocking, isLoading: isUpdatingUnapprovedTrackerBlocking } =
    useUpsertTrackerBlocking({
      onSuccess: async () => {
        await queryClient.invalidateQueries([ApiQueryKeys.unapprovedTrackersInfinite])
        showToast({ content: 'Updated blocking status', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to update blocking status', type: 'error' })
      },
    })

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1}>
        {/* Disclosure button */}
        <Button
          sx={{
            width: 32,
            height: 32,
            borderRadius: '100%',
            padding: 0.25,
          }}
          disabled={!isPermittedToWriteCookie || isUpdatingUnapprovedTrackerDisclosure || Boolean(anchorEl)}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation()
            upsertUnapprovedTrackerDisclosure({
              params: {
                data: {
                  isDisclosed: isTrackerDisclosedInSomeProperties ? false : true,
                },
                encodedTrackerKey: tracker?.encodedKey || '',
              },
            })
          }}
          variant={isTrackerDisclosedInSomeProperties ? 'contained' : 'iconCustomRounded'}
          color={isTrackerDisclosedInSomeProperties ? 'primary' : 'tertiary'}
          children={
            isTrackerDisclosedInSomeProperties ? (
              <Icon name={IconDictionary.OEyeShow} iconColor={theme.palette.white.main} />
            ) : (
              <Icon name={IconDictionary.OEyeHide} />
            )
          }
        />

        {/* Blocking button */}
        {isTrackerBlockingRelease && (
          <Button
            sx={{
              width: 32,
              height: 32,
              borderRadius: '100%',
              padding: 0.25,
            }}
            disabled={!isPermittedToWriteCookie || isUpdatingUnapprovedTrackerBlocking || Boolean(anchorEl)}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.stopPropagation()
              upsertUnapprovedTrackerBlocking({
                params: {
                  data: {
                    isBlocked: isTrackerBlockedInSomeProperties ? false : true,
                  },
                  encodedTrackerKey: tracker?.encodedKey || '',
                },
              })
            }}
            variant={isTrackerBlockedInSomeProperties ? 'iconCustomRounded' : 'contained'}
            color={isTrackerBlockedInSomeProperties ? 'tertiary' : 'primary'}
            children={
              isTrackerBlockedInSomeProperties ? (
                <Icon
                  name={IconDictionary.ODecline}
                  iconColor={isTrackerBlockedInSomeProperties ? theme.palette.Black.o100 : theme.palette.white.main}
                />
              ) : (
                <Icon name={IconDictionary.OCheckRound2} />
              )
            }
          />
        )}

        {isTrackerDisclosedInSomeProperties &&
        !isTrackerDisclosedInAllProperties &&
        (!isTrackerBlockingRelease || !isTrackerBlockedInAllProperties) ? (
          <Button
            disabled={!isPermittedToWriteCookie || isUpdatingUnapprovedTrackerDisclosure || Boolean(anchorEl)}
            onClick={handleClick}
            children="Custom"
            startIcon={<Icon name={IconDictionary.OMore} />}
            sx={{ borderRadius: 12 }}
          />
        ) : (
          <Button
            disabled={!isPermittedToWriteCookie || isUpdatingUnapprovedTrackerDisclosure || Boolean(anchorEl)}
            onClick={handleClick}
            variant="iconCustomRounded"
            color="tertiary"
            children={<Icon name={IconDictionary.OMore} />}
          />
        )}

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box width={340} display="flex" flexDirection="column" gap={1} p={2}>
            {trackerPropertiesItems?.map((trackerPropertyItem, trackerPropertyItemIndex) => {
              return (
                <PropertyDisclosureAndBlocking
                  key={trackerPropertyItem?.id}
                  tracker={tracker}
                  trackerPropertyItem={trackerPropertyItem}
                  trackerPropertyItemIndex={trackerPropertyItemIndex}
                />
              )
            })}
          </Box>
        </Popover>
      </Box>
    </Box>
  )
}
