import { useState } from 'react'
import { ContentGroup, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { FormikProvider, useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { BusinessFunctionUpsertDetails } from './BusinessFunctionUpsertDetails'
import { BusinessFunctionUpsertPurposes } from './BusinessFunctionUpsertPurposes'
import { FormMode } from 'interfaces/formModes/formMode'
import { FormStep, initialState } from '../../utils/formHelpers'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'
import { useValidationSchema } from 'pages/policyCenter/businessFunctions/utils/formHelpers'

type Props = {
  onSubmit: (data: any) => Promise<any>
}

export const getBusinessFunctionFormSteps = () => [FormStep.DETAILS, FormStep.PURPOSES]

export const BusinessFunctionCreateLayout = ({ onSubmit }: Props) => {
  const navigate = useNavigate()
  const validationSchema = useValidationSchema({ isEditMode: false })
  const [currentFormStep, setCurrentFormStep] = useState(0)

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Business Functions', link: RoutesManager.policyCenter.businessFunctions.root.getURL() },
    {
      title: 'Create',
      link: RoutesManager.policyCenter.businessFunctions.upsert.root.getURL({ formMode: FormMode.CREATE }),
    },
  ]

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialState,
    validationSchema: validationSchema,
    onSubmit: values => onSubmit({ params: { formData: values } }),
  })
  const renderedChildren = [<BusinessFunctionUpsertDetails />, <BusinessFunctionUpsertPurposes />]
  const { submitForm, isSubmitting, validateForm, setFieldTouched } = formikProps
  const isLastStep = getBusinessFunctionFormSteps().length - 1 === currentFormStep
  const onAccept = async () => {
    const errors = await validateForm()
    if (Object.keys(errors).length) {
      Object.keys(errors).forEach(errorKey => {
        setFieldTouched(errorKey, true)
      })
      return
    }
    if (isLastStep) {
      submitForm()
    } else {
      setCurrentFormStep(prev => prev + 1)
    }
  }

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          sx={{
            '&.UpsertLayout-container': {
              '& .UpsertLayout-stepper': {
                // navbar 40px + 8px gap between layout stepper and content
                top: '8px',
              },
            },
          }}
          formSteps={getBusinessFunctionFormSteps()}
          mode={UpsertLayoutMode.create}
          currentFormStep={currentFormStep}
          showStepper
          renderFormTitle={() => <ContentGroup title="Create New Business Function" titleVariant="h2" isDivider />}
          onAccept={onAccept}
          onCancel={() => {
            navigate(RoutesManager.policyCenter.businessFunctions.list.root.getURL())
          }}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">{renderedChildren.filter((_, i) => currentFormStep >= i)}</form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
