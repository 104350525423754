import React from 'react'
import { Link } from 'react-router-dom'

import { ExperienceDTO } from 'interfaces/experiences/experience'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/processing_activities.svg'
import { RoutesManager } from 'utils/routing/routesManager'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { Table } from 'components/ui-layouts/table/Table'
import { Button } from '@ketch-com/deck'
import { SectionTitle } from 'components/ui-kit/typography/compositions/SectionTitle'
import { AssociatedRightsCellRenderer } from 'pages/consentAndRights/forms/templates/view/components'
import Typography from '@mui/material/Typography'

type Props = {
  experience: ExperienceDTO
}

export const ExperienceForms: React.FC<Props> = ({ experience }) => {
  const formTemplates = experience?.formTemplates || []

  return (
    <>
      <SectionTitle>Associated Forms</SectionTitle>

      <Table
        items={formTemplates}
        isSmallHeaderText
        emptyState={
          formTemplates.length === 0 ? (
            <EmptyState
              bgVariant="fadedGrey"
              variant="page"
              icon={<EmptyStateIcon />}
              title="There are no forms associated with this experience."
              description="Assign a form to this experience to populate the table."
            />
          ) : null
        }
        containerSx={{
          mb: 6,
          backgroundColor: ({ palette }) => palette.white.main,
          borderRadius: 2.75,
        }}
        cellSettings={{
          forms: {
            label: 'Forms',
            width: 540,
            cellRenderer: ({ name }) => <Typography variant="label">{name}</Typography>,
            justifyContent: 'flex-start',
          },
          associatedRights: {
            label: 'Associated Rights',
            cellRenderer: experience => <AssociatedRightsCellRenderer experience={experience} />,
            width: 490,
          },
          openInNewTab: {
            cellRenderer: form => {
              const targetUrl = RoutesManager.deployment.forms.templates.view.root.getURL({
                id: form?.ID,
              })

              return (
                <Link to={targetUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                  <Button color="tertiary">Open In New Tab</Button>
                </Link>
              )
            },
          },
        }}
      />
    </>
  )
}
