import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { useNavigate, To } from 'react-router-dom'
import Box from '@mui/material/Box'
import qs from 'query-string'

import { makeStyles, createStyles } from '@mui/styles'

import { Flex } from 'components/ui-layouts/flex/Flex'
import { ButtonBack } from 'components/ui-kit/buttonBack/ButtonBack'
import Typography from '@mui/material/Typography'
import { formatCodeFromName } from 'utils/formatters'

const useStyles = makeStyles(
  createStyles({
    root: {
      marginBottom: 40,
      minHeight: 65,
    },
    buttonBack: {
      marginRight: 16,
      height: 'auto',
    },
    title: {
      alignSelf: 'flex-start',
      wordBreak: 'break-word',
    },
    contentWrapper: {
      flex: 1,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    actions: {},
    details: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 7,
    },
    noMargin: {
      marginBottom: 0,
    },
  }),
  { name: 'ViewLayoutHeader' },
)

type Props = {
  title?: string
  backTo?: To
  backToScrollToTarget?: string
  actions?: ReactNode | ReactNode[]
  details?: ReactNode | ReactNode[]
  topRightAdornment?: ReactNode
  icon?: ReactNode | null | undefined
  noMargin?: boolean
  titleDataIntercomTarget?: string
}

export const ViewLayoutHeader: React.FC<Props> = ({
  backTo,
  title,
  actions,
  details,
  topRightAdornment,
  icon = null,
  backToScrollToTarget,
  noMargin = false,
  titleDataIntercomTarget,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <Flex
      className={clsx(classes.root, {
        [classes.noMargin]: noMargin,
      })}
    >
      <ButtonBack
        className={classes.buttonBack}
        onClick={() => {
          if (backTo && backToScrollToTarget) {
            navigate({
              pathname: backTo as string,
              search: qs.stringify({ targetId: backToScrollToTarget }),
            })
          } else if (backTo) {
            navigate(backTo)
          } else {
            navigate(-1)
          }
        }}
      />

      {!!icon && (
        <Box mr={2} display="flex" alignItems="center" justifyContent="center">
          {icon}
        </Box>
      )}

      <Flex className={classes.contentWrapper} justifyContent="center" flexDirection="column">
        <Flex alignItems="center">
          <Typography
            className={clsx({ [classes.title]: !!details })}
            variant="h2"
            title={title}
            data-intercom-target={
              titleDataIntercomTarget
                ? `${titleDataIntercomTarget}_app_view_title`
                : `${formatCodeFromName(title || '')}_app_view_title}`
            }
          >
            {title}
          </Typography>
          <Box mb="auto" ml="auto">
            {topRightAdornment}
          </Box>
        </Flex>
        {!!details && <div className={classes.details}>{details}</div>}
      </Flex>

      <div className={classes.actions}>{actions}</div>
    </Flex>
  )
}
