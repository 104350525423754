import React from 'react'
import { RadioGroup as MUIRadioGroup, SxProps, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { Radio, Props as BaseRadioProps } from 'components/ui-kit/radio/Radio'
import clsx from 'clsx'

const useStyles = makeStyles(
  createStyles({
    withBorderBottom: {
      '&.MuiFormGroup-root': {
        '&:not(.MuiFormGroup-row)': {
          '& >*': {
            '&:not(:last-child)': {
              marginBottom: 12,
              paddingBottom: 12,
              borderBottom: '1px solid #DADDDE',
            },
          },
        },
      },
    },

    sizeRegular: {
      '&.MuiFormGroup-row': {
        '& >*': {
          '&:not(:last-child)': {
            marginRight: 24,
          },
        },
      },
      '&:not(.MuiFormGroup-row)': {
        '& >*': {
          '&:not(:last-child)': {
            marginBottom: 24,
          },
        },
      },
    },

    sizeSmall: {
      '&.MuiFormGroup-row': {
        '& >*': {
          '&:not(:last-child)': {
            marginRight: 12,
          },
        },
      },
      '&:not(.MuiFormGroup-row)': {
        '& >*': {
          '&:not(:last-child)': {
            marginBottom: 12,
          },
        },
      },
    },
  }),
  { name: 'RadioGroup' },
)

type OmittedProps = Omit<BaseRadioProps, 'title' | 'checked' | 'disabled' | 'onChange'>

export type RadioOption = {
  title: React.ReactNode
  value: any
  disabled?: boolean
}

export interface Props extends OmittedProps {
  /** Component list of options */
  options: RadioOption[]
  /** Component layout */
  layout?: 'row' | 'column'
  /** Component onChange method */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: any) => void
  /** SX Overrides */
  sx?: SxProps<Theme>
  /** Align radio flex-start */
  alignFlexStart?: boolean
  optionsWithBorderBottom?: boolean
}

export const RadioGroup: React.FC<Props> = ({
  name,
  id = name,
  value,
  onChange,
  options,
  layout = 'row',
  size = 'regular',
  sx,
  alignFlexStart = false,
  optionsWithBorderBottom = false,
  ...baseRadioProps
}) => {
  const classes = useStyles()

  const componentClassName = clsx({
    [classes.withBorderBottom]: optionsWithBorderBottom,
    [classes.sizeRegular]: size === 'regular',
    [classes.sizeSmall]: size === 'small',
  })

  return (
    <MUIRadioGroup
      id={id}
      row={layout === 'row'}
      className={componentClassName}
      name={name}
      value={value}
      onChange={onChange}
      sx={sx}
    >
      {options.map((option, index) => (
        <Radio
          id={`${id}-${index}${option?.title ? `-${option.title}` : ''}`}
          key={index}
          name={name}
          title={option.title}
          value={option.value}
          checked={option.value === value}
          disabled={option.disabled}
          alignFlexStart={alignFlexStart}
          {...baseRadioProps}
        />
      ))}
    </MUIRadioGroup>
  )
}
