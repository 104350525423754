import { Button, TableCell } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { IdentitySpaceDTO } from '@ketch-com/figurehead'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { FormInlineEdit } from 'components/form/FormInlineEdit'

export type DataGridRowType = {
  code: string
  value: string
  rowIndex: number
}

const RightsInvocationDroplistCell = ({
  code,
  rowIndex,
  identitySpaces,
}: {
  code: string
  rowIndex: number
  identitySpaces: IdentitySpaceDTO[]
}) => {
  return (
    <TableCell padding={'8px 0'}>
      <FormDroplistButton
        disableClearable
        required
        size="small"
        disabled={code === 'email' && rowIndex === 0}
        valueKey="code"
        formPropertyName={`identities[${rowIndex}].code`}
        placeholder="Select Identifier"
        items={identitySpaces}
      />
    </TableCell>
  )
}
const RightsInvocationInlineEditCell = ({ code, rowIndex }: { code: string; rowIndex: number }) => {
  return (
    <TableCell padding={'8px 0'}>
      <FormInlineEdit
        shouldUpdateDebounced
        disabled={code === 'email' && rowIndex === 0}
        required
        formPropertyName={`identities[${rowIndex}].value`}
        placeholder="Value"
      />
    </TableCell>
  )
}

export const getGridColumns = ({
  identitySpaces,
  identitiesLength,
  remove,
}: {
  identitySpaces: IdentitySpaceDTO[]
  identitiesLength: number
  remove: (index: number) => any
}) => [
  {
    field: 'indentitySpace',
    headerName: 'Identifier',
    flex: 1,
    minWidth: 312,
    renderCell: ({ row }: GridRenderCellParams<DataGridRowType>) => (
      <RightsInvocationDroplistCell code={row.code} rowIndex={row.rowIndex} identitySpaces={identitySpaces} />
    ),
  },
  {
    field: 'value',
    headerName: 'Value',
    flex: 1,
    sortable: false,
    renderCell: ({ row: { code, rowIndex } }: GridRenderCellParams<DataGridRowType>) => (
      <RightsInvocationInlineEditCell code={code} rowIndex={rowIndex} />
    ),
  },
  {
    field: 'removeButton',
    headerName: '',
    flex: 1,
    maxWidth: 120,
    renderCell: ({ row: { code, rowIndex } }: GridRenderCellParams<DataGridRowType>) => (
      <TableCell columnGap={1.5}>
        <Button
          disabled={identitiesLength === 1 || (code === 'email' && rowIndex === 0)}
          color="tertiary"
          onClick={() => {
            remove(rowIndex)
          }}
        >
          Remove
        </Button>
      </TableCell>
    ),
  },
]
