import React from 'react'
import clsx from 'clsx'
import { Radio as MUIRadio, Theme } from '@mui/material'

import { makeStyles, createStyles } from '@mui/styles'

type UseStylesProps = {
  alignFlexStart?: boolean
}

const useStyles = makeStyles<Theme, UseStylesProps>(
  ({ typography, palette }) =>
    createStyles({
      baseRadioWrapper: {
        display: 'inline-flex',
        alignItems: ({ alignFlexStart }) => (alignFlexStart ? 'flex-start' : 'center'),

        '&:not($disabled)': {
          cursor: 'pointer',

          '& $baseRadioText': {
            cursor: 'pointer',
          },

          '&:hover': {
            '& $baseRadio': {
              color: palette.sphere.main,
            },

            '&$checked': {
              '& $baseRadio': {
                color: palette.royalBlue.main,
              },
              '& $baseRadioText': {
                color: palette.royalBlue.main,
              },
            },
          },

          '&:active': {
            '& $baseRadio': {
              color: palette.persianBlue.main,
            },

            '&$checked': {
              '& $baseRadio': {
                color: palette.persianBlue.main,
              },
              '& $baseRadioText': {
                color: palette.persianBlue.main,
              },
            },
          },
        },
      },
      baseRadio: {
        borderRadius: 3,
        padding: 0,

        '&:hover': {
          background: 'none',
        },

        '&.Mui-checked': {
          color: palette.sphere.main,

          '&:hover': {
            background: 'none',
          },
        },

        '&:not(.Mui-checked)': {
          color: palette.fadedDarkGrey.main,
        },
      },
      baseRadioText: {
        fontFamily: typography.fontFamily,
        letterSpacing: '0.01em',
        color: palette.darkDusk.main,
      },

      // Sizes:
      sizeSmall: {
        '& $baseRadioText': {
          fontSize: typography.pxToRem(12),
          lineHeight: typography.pxToRem(16),
          marginLeft: 6,
        },
        '& .MuiSvgIcon-root': {
          width: 16,
          height: 16,
        },
      },
      sizeRegular: {
        '& $baseRadioText': {
          fontSize: typography.pxToRem(14),
          lineHeight: typography.pxToRem(20),
          marginLeft: 10,
        },
        '& .MuiSvgIcon-root': {
          width: 20,
          height: 20,
        },
      },

      // States:
      disabled: {
        '& $baseRadio': {
          color: palette.fadedDarkGrey.main,
        },
        '& $baseRadioText': {
          color: palette.fadedDarkGrey.main,
        },
      },
      checked: {
        '& $baseRadioText': {
          fontWeight: 600,
        },
      },
      focusVisible: {},

      alignFlexStart: {
        alignItems: 'flex-start',
      },
    }),
  { name: 'Radio' },
)

export interface Props {
  /** ID of an element */
  id?: string
  /** Test ID of an element for selectors */
  testId?: string
  /** Name of an element */
  name?: string
  /** Custom element className */
  className?: any
  /** Component title */
  title: React.ReactNode
  /** Component value (controlled) */
  value: any
  /** Component checked state (controlled) */
  checked: boolean
  /** Component size */
  size?: 'regular' | 'small'
  /** Disable state */
  disabled?: boolean
  /** Component value change method */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  /** Wrapper onChange method */
  wrapperOnChange?: () => void
  /** Align flex-start */
  alignFlexStart?: boolean
}

/**
 * -
 */
export const Radio: React.FC<Props> = ({
  name,
  id = name,
  testId,
  className,
  title,
  value,
  checked,
  size = 'regular',
  disabled = false,
  onChange,
  wrapperOnChange,
  alignFlexStart = false,
}) => {
  const classes = useStyles({
    alignFlexStart: !!alignFlexStart,
  })

  const wrapperClassName = clsx(classes.baseRadioWrapper, {
    [classes.sizeSmall]: size === 'small',
    [classes.sizeRegular]: size === 'regular',
    [classes.checked]: checked,
    [classes.disabled]: disabled,
    [classes.alignFlexStart]: alignFlexStart,
  })

  const componentClassName = clsx(
    classes.baseRadio,
    {
      [classes.sizeSmall]: size === 'small',
      [classes.sizeRegular]: size === 'regular',
      [classes.disabled]: disabled,
    },
    className,
  )

  return (
    <div className={wrapperClassName} onClick={() => wrapperOnChange?.()}>
      <MUIRadio
        id={id}
        data-test-id={`Radio-${testId}`}
        disableRipple
        onChange={onChange}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        classes={{
          root: componentClassName,
          checked: classes.checked,
          disabled: classes.disabled,
        }}
      />

      <label htmlFor={id} className={classes.baseRadioText}>
        {title}
      </label>
    </div>
  )
}
