import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

const PREFIX = 'ActivityAndAccessDetails'

export const DetailsClasses = {
  container: `${PREFIX}-container`,
  tableAssetTypeIcon: `${PREFIX}-tableAssetTypeIcon`,
  dataSetIcon: `${PREFIX}-dataSetIcon`,
  percentCell: `${PREFIX}-percentCell`,
  queriesCountCell: `${PREFIX}-queriesCountCell`,
  queryProgressCell: `${PREFIX}-queryProgressCell`,
}

export const StyledBox = styled(Box)(({ theme: { spacing, palette } }) => ({
  [`&.${DetailsClasses.container}`]: {
    flex: 2.54,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: spacing(1.375),
    backgroundColor: palette.white.main,
    padding: spacing(2.5),
  },
  [`& .${DetailsClasses.tableAssetTypeIcon}`]: {
    width: 22,
    height: 22,
    marginRight: spacing(1),
  },

  [`& .${DetailsClasses.dataSetIcon}`]: {
    marginRight: spacing(1),
  },

  [`& .${DetailsClasses.percentCell}`]: {
    alignItems: 'center',
    display: 'block',
    paddingRight: spacing(4),
  },

  [`& .${DetailsClasses.queriesCountCell}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  [`& .${DetailsClasses.queryProgressCell}`]: {
    paddingLeft: spacing(0),
  },
}))
