import React from 'react'

import { MaybeNull } from 'interfaces'
import { WorkflowDTO, WorkflowSummaryDTO } from 'interfaces/workflows'
import { EventV2DTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

import { Box, Typography } from '@mui/material'
import { DataGrid, EmptyState } from '@ketch-com/deck'
import { useWorkflowTabEventsColumns } from './utils/workflowTabEventsUtils'

type Props = {
  workflow: MaybeNull<WorkflowDTO>
  workflowSummary: MaybeNull<WorkflowSummaryDTO>
  events: EventV2DTO[]
}

export const WorkflowTabEvents: React.FC<Props> = ({ workflow, workflowSummary, events }) => {
  const customWorkflowEvents =
    workflowSummary?.customEventIDs?.map(eventID => {
      const eventByID = events?.find(({ id }) => id === eventID)

      return {
        name: eventByID?.rightName,
        description: eventByID?.rightDescription,
        code: eventByID?.rightCode,
        dataSubjectTypeName: eventByID?.dataSubjectTypeName,
        dataRole: eventByID?.dataRole,
      }
    }) || []

  const canonicalWorkflowEvents =
    workflowSummary?.canonicalEventIDs?.map(eventID => {
      const eventByID = events?.find(({ id }) => id === eventID)

      return {
        name: eventByID?.rightName,
        description: eventByID?.rightDescription,
        code: eventByID?.rightCode,
        dataSubjectTypeName: eventByID?.dataSubjectTypeName,
        dataRole: eventByID?.dataRole,
      }
    }) || []

  const allEvents = [...customWorkflowEvents, ...canonicalWorkflowEvents]

  const columns = useWorkflowTabEventsColumns()
  return (
    <>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
          { title: 'Workflows', link: RoutesManager.orchestration.workflows.root.getURL() },
          {
            title: workflow?.name || workflow?.code,
            link: RoutesManager.orchestration.workflows.view.root.getURL({ code: workflow?.code }),
          },
          { title: 'Events' },
        ]}
      />
      <Box display="flex" flexDirection="column" gap={4} mb={4}>
        <Typography variant="h3">Event Assignments</Typography>
        {!allEvents.length ? (
          <Box
            mt={2.5}
            bgcolor="bone.main"
            borderRadius="11px"
            py={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <EmptyState
              iconName="OChatAlert"
              title="No events assigned to this workflow"
              subTitle="Events assigned to this workflow will be displayed here."
            />
          </Box>
        ) : (
          <DataGrid
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              includeOutliers: false,
              columns: columns.map(column => column.field),
              expand: true,
            }}
            getRowHeight={() => 'auto'}
            columns={columns}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowHoverStates
            disableRowSelectionOnClick
            getRowId={row => row.code}
            rows={allEvents}
            hideFooter
          />
        )}
      </Box>
    </>
  )
}
