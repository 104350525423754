import { useState, useEffect } from 'react'
import { debounce } from 'lodash'

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      })
    }
    const handleResizeDebounced = debounce(handleResize, 100)

    window.addEventListener('resize', handleResizeDebounced)

    return () => window.removeEventListener('resize', handleResizeDebounced)
  }, [])

  return windowSize
}
