import React from 'react'
import { Box, Typography } from '@mui/material'
import { DataGrid, theme, TableCell, Button, EmptyState } from '@ketch-com/deck'
import { CustomRightNameDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'

type Props = {
  values: CustomRightNameDTO
  tab: any
}

export const Usage: React.FC<Props> = ({ values }) => {
  const rows =
    values.jurisdictionRights?.map((jurisdiction, index) => ({
      id: index,
      right: jurisdiction.rightName,
      jurisdiction: jurisdiction.jurisdictionName,
      jurisdictionCode: jurisdiction.jurisdictionCode,
    })) || []

  const cols: GridColDef<(typeof rows)[0]>[] = [
    {
      flex: 1,
      field: 'Jurisdiction',
      headerName: 'Jurisdiction',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return <TableCell subTitle={params.row.jurisdictionCode} title={params.row.jurisdiction} />
      },
    },
    {
      flex: 1,
      field: 'right',
      headerName: 'Right',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            <Typography variant="body">{params?.row?.right}</Typography>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      maxWidth: 180,
      flex: 1,
      field: 'Link',
      align: 'right',
      headerName: ' ',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            {params?.row ? (
              <Button
                color="tertiary"
                onClick={() => {
                  window.open(
                    RoutesManager.policyCenter.policyScopes.view.root.getURL({ code: params.row.jurisdictionCode }),
                    '_blank',
                    'noreferrer',
                  )
                }}
              >
                Open In New Tab
              </Button>
            ) : null}
          </TableCell>
        )
      },
      sortable: false,
    },
  ]

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Typography variant="h4" color={theme.palette.Black}>
        Usage
      </Typography>

      {values.jurisdictionRights ? (
        <Box>
          <DataGrid
            sx={{ position: 'relative' }}
            columns={cols}
            rows={rows}
            disableColumnResize
            disableColumnFilter
            disableColumnReorder
            disableColumnPinning
            disableAggregation
            hideFooter
            disableColumnMenu
            disableRowHoverStates
            disableRowSelectionOnClick
          />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: 'bone.main',
            padding: 5,
            borderRadius: '11px',
          }}
        >
          <EmptyState
            title="There are no jurisdictions that use this custom right name"
            subTitle="You can assign this name to the selected rights in any jurisdiction."
            iconName="OGlobe"
          />
        </Box>
      )}
    </Box>
  )
}
