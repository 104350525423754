import { API } from '../../../utils/api/api'
import { FetchProcessingActivitiesSummaryResponseBodyDTO } from '@ketch-com/figurehead'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

export const fetchProcessingActivitiesSummary = () => {
  return API.get<FetchProcessingActivitiesSummaryResponseBodyDTO>(
    formatRequestString({
      entityUrl: '/api/processing-activities/summary',
    }),
  )
}
