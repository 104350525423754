import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Grid } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { FieldArray, useFormikContext } from 'formik'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { Text } from 'components/ui-kit/typography/Text'
import { ReactComponent as AssetsIcon } from 'assets/icons/assets.svg'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { Button } from '@ketch-com/deck'
import { ReactComponent as RemoveBadgeIcon } from 'assets/icons/remove_icon_bold.svg'
import { IconSpacer } from './IconSpacer'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { ASSET_TYPE_OPTIONS, CRITERIA_OPTIONS } from '../constants'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import { getRenderSelectedLabel } from 'pages/assetManager/components/ManageLabelsInput/utils'
import { PolicyFormValues } from 'interfaces/policies/PolicyFormValues'
import { useAutocompleteStyles } from '../hooks'
import { AutocompleteLabeledInput, AutocompleteNameInput } from '.'

const PREFIX = 'AllowancesFormSection'

const classes = {
  container: `${PREFIX}-container`,
  withBorderBottom: `${PREFIX}-withBorderBottom`,
  plusButton: `${PREFIX}-plusButton`,
  withMarginRight: `${PREFIX}-withMarginRight`,
  deleteButton: `${PREFIX}-deleteButton`,
  hidden: `${PREFIX}-hidden`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing, shadows } }) => ({
  [`&.${classes.container}`]: {
    backgroundColor: palette.white.main,
    borderRadius: spacing(1.5),
    boxShadow: shadows[1],
  },

  [`& .${classes.withBorderBottom}`]: {
    borderBottom: `1px solid ${palette.iron.main}`,
  },

  [`& .${classes.plusButton}`]: {
    borderRadius: '50%',
    height: spacing(3.5),
    width: spacing(3.5),
    padding: spacing(2),
    minWidth: 'unset',
  },

  [`& .${classes.withMarginRight}`]: {
    marginRight: spacing(3),
  },

  [`& .${classes.deleteButton}`]: {
    borderRadius: '50%',
    height: spacing(3.5),
    width: spacing(3.5),
    padding: spacing(2),
    fontSize: 16,
    minWidth: 'unset',
    color: 'black',
    marginLeft: spacing(2),
  },

  [`& .${classes.hidden}`]: {
    opacity: 0,
  },
}))

type Props = {
  onAddAccessAllowance: (event: React.MouseEvent) => void
  onRemoveAccessAllowance: (event: React.MouseEvent) => void
}

export const AllowancesFormSection: React.FC<Props> = ({ onAddAccessAllowance, onRemoveAccessAllowance }) => {
  const autocompleteClasses = useAutocompleteStyles()
  const { setFieldValue } = useFormikContext<PolicyFormValues>()

  const handleAutocompleteLabeledOnChange = (
    event: React.ChangeEvent<{}>,
    value: ManageLabelModalLabel[],
    index: number,
  ): void => {
    if (value?.length > 0) {
      setFieldValue(`accessAllowances[${index}].labelName`, { ...value[0] })
    } else {
      setFieldValue(`accessAllowances[${index}].labelName`, {})
    }
  }

  const handleAutocompleteNamedOnChange = (
    event: React.ChangeEvent<{}>,
    value: string[],
    typeEnum: number,
    index: number,
  ): void => {
    if (value?.length > 0) {
      setFieldValue(`accessAllowances[${index}].labelName`, {
        type: typeEnum,
        named: value[0],
        labeled: {}, // optional, leave value empty if a boolean label type
      })
    } else {
      setFieldValue(`accessAllowances[${index}].labelName`, {})
    }
  }

  return (
    <StyledBox display="flex" flexDirection="column" className={classes.container}>
      {/* Row 1 */}

      <Box display="flex" alignItems="center" p={3} className={classes.withBorderBottom}>
        <Box mr={3}>
          <AssetsIcon />
        </Box>
        <Box mr={2}>
          <Text size={20} weight={700}>
            Allow access to the following resources:
          </Text>
        </Box>

        <Button id="addAccessConfig" onClick={onAddAccessAllowance} className={classes.plusButton} color="tertiary">
          <AddIcon />
        </Button>
      </Box>

      {/* Row 2 */}

      <FieldArray name="valuesSerialized">
        {({ form }) => {
          const { values, setFieldValue, errors }: any = form
          const { accessAllowances } = values

          return (
            <Box pt={3} pb={1}>
              {accessAllowances.map((a: any, index: number) => (
                <Box
                  key={`${a}_${index}`}
                  display="flex"
                  alignItems="center"
                  px={3}
                  mb={index !== accessAllowances?.length - 1 ? 3 : 0}
                >
                  <IconSpacer />

                  <Grid key={index} container spacing={2} alignItems="flex-end">
                    <Grid item xs={3}>
                      <FormDropdown
                        hasErrorSpacer
                        className={classes.withMarginRight}
                        label="Asset Type"
                        required
                        fullWidth
                        onChange={() => {
                          setFieldValue(`accessAllowances[${index}].criteria`, '')
                          setFieldValue(`accessAllowances[${index}].labelName`, '')
                        }}
                        name={`accessAllowances[${index}].assetType`}
                        placeholder="Select Asset Type"
                        items={ASSET_TYPE_OPTIONS}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <FormDropdown
                        hasErrorSpacer
                        className={classes.withMarginRight}
                        label="Criteria"
                        required
                        fullWidth
                        name={`accessAllowances[${index}].criteria`}
                        placeholder="Select Criteria"
                        items={CRITERIA_OPTIONS}
                        onChange={() => {
                          setFieldValue(`accessAllowances[${index}].labelName`, '')
                        }}
                      />
                    </Grid>

                    <Grid item xs={5}>
                      {/* Placeholder/Disabled Input, before "labeled" or "named" is selected */}

                      {accessAllowances[index].criteria === '' && (
                        <Autocomplete
                          popupIcon={null}
                          autoComplete={false}
                          multiple
                          classes={{ inputRoot: autocompleteClasses.inputRoot }}
                          disabled={accessAllowances[index].criteria === ''}
                          id={`accessAllowances[${index}].labelName`}
                          options={[]}
                          renderInput={params => (
                            <TextField
                              {...params}
                              autoComplete="off"
                              placeholder=""
                              inputProps={{
                                ...params.inputProps,
                                form: {
                                  autoComplete: 'off',
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                              }}
                            />
                          )}
                        />
                      )}

                      {/* Label Input */}

                      {accessAllowances[index].criteria === 'labeled' &&
                        Object.keys(accessAllowances[index].labelName)?.length === 0 && (
                          <AutocompleteLabeledInput
                            handleAutocompleteLabeledOnChange={handleAutocompleteLabeledOnChange}
                            index={index}
                            valueKey="accessAllowances"
                          />
                        )}

                      {/* Label Input With Value */}

                      {accessAllowances[index].criteria === 'labeled' &&
                        Object.keys(accessAllowances[index].labelName)?.length > 1 && (
                          <>
                            <Label>Labeled</Label>
                            <Box display="flex" alignItems="center" className={autocompleteClasses.proxyLabelsInput}>
                              {getRenderSelectedLabel({
                                value: accessAllowances[index].labelName,
                                onRemove: () => {
                                  setFieldValue(`accessAllowances[${index}].labelName`, {})
                                },
                              })}
                            </Box>
                          </>
                        )}

                      {/* Name Input */}

                      {accessAllowances[index].criteria === 'named' && !accessAllowances[index].labelName?.named && (
                        <AutocompleteNameInput
                          index={index}
                          handleAutocompleteNamedOnChange={handleAutocompleteNamedOnChange}
                          valueKey="accessAllowances"
                        />
                      )}

                      {/* Name Input With Value */}

                      {accessAllowances[index].criteria === 'named' && accessAllowances[index].labelName?.named && (
                        <Box>
                          <Label>Named</Label>
                          <Box display="flex" alignItems="center" className={autocompleteClasses.proxyInput}>
                            <Box display="flex" alignItems="center" className={autocompleteClasses.selectedValueTag}>
                              {accessAllowances[index].labelName?.named}
                              <RemoveBadgeIcon
                                className={autocompleteClasses.removeSelectedValueBadgeIcon}
                                onClick={() => {
                                  setFieldValue(`accessAllowances[${index}].labelName`, {})
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {(!errors?.accessAllowances?.[index]?.labelName ||
                        !values?.accessAllowances?.[index]?.criteria) && (
                        <Error className={classes.hidden}>hidden</Error>
                      )}
                    </Grid>

                    {accessAllowances.length > 1 && (
                      <Grid item xs={1} alignSelf="center">
                        <Button
                          id={index.toString()}
                          onClick={onRemoveAccessAllowance}
                          className={classes.deleteButton}
                          color="tertiary"
                        >
                          <DeleteIcon fontSize="inherit" />
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              ))}
            </Box>
          )
        }}
      </FieldArray>
    </StyledBox>
  )
}
