import { isEmpty } from 'lodash'
import { IRegionFE, ObjectLiteral } from 'interfaces'
import { getRegionsRecursiveCount } from 'utils/helpers/regions'
import { RegionDTO } from 'interfaces/regions/region'

export const PLAIN_LIST = 'PLAIN_LIST'

// NOTE: concatenate regions and regionGroups recursively as they represent the same item type. No need to separate them.
export const transformRegionsForFeTree = (
  { code, name, policyScopeCode, regions, regionGroups }: RegionDTO,
  accumulator: IRegionFE[],
) => {
  const regionTransformed: IRegionFE = {
    code,
    name,
    policyScopeCode,
  }
  const childRegionGroupsTransformed: IRegionFE[] = []
  const childRegionsTransformed: IRegionFE[] = []

  // todo polsco: we won't need this after BE remove regionGroups
  if (regionGroups) {
    regionGroups?.forEach?.(region => transformRegionsForFeTree(region, childRegionGroupsTransformed))
  }

  if (regions) {
    regions?.forEach?.(region => transformRegionsForFeTree(region, childRegionsTransformed))
  }

  if (!isEmpty(childRegionGroupsTransformed) || !isEmpty(childRegionsTransformed)) {
    regionTransformed.regions = [...childRegionGroupsTransformed, ...childRegionsTransformed]
  }

  accumulator.push(regionTransformed)
}

export const addRegionToPlainList = (region: RegionDTO, plainList: ObjectLiteral[]) => {
  const regionToSave: IRegionFE = { name: region.name, code: region.code, policyScopeCode: region.policyScopeCode }

  if (region.regions) {
    regionToSave.childrenCount = getRegionsRecursiveCount(region.regions)
  }

  plainList.push(regionToSave)

  if (region.regions) {
    region.regions?.forEach?.(region => addRegionToPlainList(region, plainList))
  }
}
