import { useFormikContext } from 'formik'
import { useContext, useEffect } from 'react'
import { ThemeUpsertContext } from '../context/ThemeUpsertContext'
import { getErrorSection } from '../utils/utils'

/**
 * Component that watches the formik error state and switches the theme
 * builder section if there is a section mapped to a particular error.
 */
export const ThemeFormErrorSectionSwitcher: React.FC = () => {
  const { setThemeBuilderSection } = useContext(ThemeUpsertContext)
  const { isValid, isValidating, isSubmitting, errors } = useFormikContext()

  useEffect(() => {
    if (!isValid && !isValidating && !isSubmitting) {
      const errorSection = getErrorSection(errors)
      if (errorSection) setThemeBuilderSection(errorSection)
    }
  }, [isValid, isValidating, isSubmitting, errors, setThemeBuilderSection])

  return null
}
