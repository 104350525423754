import { styled } from '@mui/system'

import Box from '@mui/material/Box'

import { Icon } from '@ketch-com/deck'
import { Typography } from '@mui/material'

const StyledPageNavigator = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.white.main,
  borderRadius: 100,
  display: 'flex',
  height: 32,
  justifyContent: 'center',
  marginLeft: 16,
  boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.05)',
}))

type Props = {
  pageNumber: number
  pageCount: number
  handlePageUp: () => void
  handlePageDown: () => void
}

export const PageNavigator = ({ handlePageUp, handlePageDown, pageNumber, pageCount }: Props) => {
  return (
    <StyledPageNavigator>
      <Box sx={{ display: 'flex', cursor: 'pointer', px: 1 }}>
        <Icon name="OArrowCUp" onClick={handlePageUp} cursor="pointer" />
      </Box>
      <Box sx={{ display: 'flex', cursor: 'pointer', px: 1 }}>
        <Icon name="OArrowCDown" onClick={handlePageUp} cursor="pointer" />
      </Box>

      <Box
        sx={{
          border: theme => `1px solid ${theme.palette.darkDuskFaded.main}`,
          borderRadius: '5px',
          height: '28px',
          textAlign: 'center',
          px: 1,
          width: '36px',
        }}
      >
        <Typography variant="smallLabel">{pageNumber}</Typography>
      </Box>

      <Box sx={{ height: '28px', px: 2 }}>
        <Typography variant="smallLabel">{pageCount}</Typography>
      </Box>
    </StyledPageNavigator>
  )
}
