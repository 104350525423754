import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { upsertUnapprovedTrackers } from '../fetchers/upsertUnapprovedTrackers'

const useCustomMutation = createUseMutation({
  mutationFn: upsertUnapprovedTrackers,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpsertUnapprovedTrackers = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
