import React from 'react'
import { TagsListProvider } from './context/TagsContext'
import { WebTagsList } from './WebTagsList'

export const WebTagsListContainer: React.FC = () => {
  return (
    <TagsListProvider>
      <WebTagsList />
    </TagsListProvider>
  )
}
