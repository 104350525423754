import Box from '@mui/material/Box'
import React, { useContext } from 'react'
import { Button, DropListButton, ListItemText, ActionSheetItem } from '@ketch-com/deck'
import { Tooltip } from '@mui/material'

import { useFormikContext } from 'formik'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
import { StepDetailsTileWithEditModeInitialValues } from '../utils'

type Props = {
  push: (obj: any) => void
  setSelectedDataSubjectVariableForAddition: React.Dispatch<React.SetStateAction<string>>
  selectedDataSubjectVariableForAddition: string
  setUnAddedVariableWarning: React.Dispatch<React.SetStateAction<boolean>>
}

export const AddDataSubjectVariableRow: React.FC<Props> = ({
  push,
  setSelectedDataSubjectVariableForAddition,
  selectedDataSubjectVariableForAddition,
  setUnAddedVariableWarning,
}) => {
  const {
    dataSubjectVariables,
    stepDetails: { dataSubjectContext: stepDetailsDataSubjectVariables = [] },
  } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const {
    values: { dataSubjectVariables: formikDataSubjectVariables },
  } = useFormikContext<StepDetailsTileWithEditModeInitialValues>()

  const selectedDataSubjectVariable = dataSubjectVariables?.find?.(
    cv => cv.code === selectedDataSubjectVariableForAddition,
  )

  const formFieldItems =
    dataSubjectVariables
      .filter(
        cv =>
          !formikDataSubjectVariables?.find?.(dsv => dsv.code === cv.code) &&
          !stepDetailsDataSubjectVariables?.find?.(dsv => dsv.code === cv.code),
      )
      ?.map(cv => ({
        active: selectedDataSubjectVariableForAddition === cv.code,
        content: cv.name,
        value: cv.code,
        onClick: () => {
          setSelectedDataSubjectVariableForAddition(cv?.code || '')
        },
      })) || []

  const dropListValue = selectedDataSubjectVariableForAddition
    ? formFieldItems.find(elem => elem.value === selectedDataSubjectVariableForAddition)
    : null
  return (
    <Tooltip title={formFieldItems.length ? '' : 'No more fields to add'}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1.5}
        sx={{
          backgroundColor: 'sphereFaded.main',
          borderRadius: 2.75, // 11px
          columnGap: '16px',
        }}
      >
        {/* Left */}
        <DropListButton
          sx={{
            minWidth: 215,
          }}
          disableClearable={dropListValue !== null}
          fullWidth
          placeholder="Select Subject Variable..."
          value={dropListValue}
          options={formFieldItems}
          size="small"
          onChange={(_, value, reason) => {
            if (reason === 'clear') {
              setUnAddedVariableWarning(false)
              setSelectedDataSubjectVariableForAddition('')
            } else {
              value?.onClick()
            }
          }}
          getOptionLabel={option => option.content || ''}
          renderOption={(props, option) => (
            <ActionSheetItem selected={option?.value === selectedDataSubjectVariableForAddition} {...props}>
              <ListItemText selected={option?.value === selectedDataSubjectVariableForAddition}>
                {option.content}
              </ListItemText>
            </ActionSheetItem>
          )}
        />
        {/* Right */}
        <Button
          disabled={!selectedDataSubjectVariable}
          color="secondary"
          onClick={() => {
            if (selectedDataSubjectVariable) {
              push({
                ...selectedDataSubjectVariable,
                options: selectedDataSubjectVariable?.values || [],
                value: '',
              })
              setSelectedDataSubjectVariableForAddition('')
            }
          }}
        >
          Add
        </Button>
      </Box>
    </Tooltip>
  )
}
