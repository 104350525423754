import React from 'react'
import { Avatar, Box, IconButton } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { Text } from 'components/ui-kit/typography/Text'
import { HOOK_TYPE_NAMES, KETCH_DML } from 'pages/developers/hooks/constants'
import { useFormikContext } from 'formik'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { DmlHookDTO } from '@ketch-com/figurehead'

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      prePostBadge: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
        borderColor: palette.iron.main,
        padding: spacing(0.625, 0.825),
        borderRadius: spacing(1),
      },
      dmlPlaceholder: {
        display: 'flex',
        alignItems: 'center',
        padding: spacing(1.5, 0, 1.5, 2.5),
        backgroundColor: palette.fadedGrey.main,
      },
      indexAvatar: {
        backgroundColor: palette.fadedGrey.main,
        height: 24,
        width: 24,
      },
      hookLineItemContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: spacing(1.5, 0, 1.5, 2.5),
      },
      chipContainer: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: palette.fadedGrey.main,
        marginLeft: spacing(2.5),
        padding: spacing(0, 1.5),
        borderRadius: 100,
      },
    }),
  { name: 'DraggableHookItem' },
)

type Props = {
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  hook: DmlHookDTO
  index: number
  shouldHaveBorderBottom?: boolean
}

export const DraggableHookItem: React.FC<Props> = ({ provided, hook, snapshot, index, shouldHaveBorderBottom }) => {
  const classes = useStyles()
  const { setFieldValue, values } = useFormikContext<EditDatabaseFormValues>()
  const { hooks } = values
  return (
    <Box
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      sx={{
        userSelect: 'none',
        my: 1,
        outline: ({ palette }) => (snapshot.isDragging ? `3px dashed ${palette.fadedGrey.main}` : undefined),
        borderBottom: ({ palette }) =>
          shouldHaveBorderBottom && !snapshot.isDragging ? `1px solid ${palette.iron.main}` : undefined,
      }}
      style={{
        ...provided.draggableProps.style,
      }}
    >
      {hook?.name === KETCH_DML ? (
        <Box className={classes.dmlPlaceholder}>
          {/* Position Indicator  */}

          <Avatar className={classes.indexAvatar}>
            <Text size={14}>{index + 1}</Text>
          </Avatar>

          <Text sx={{ ml: 2.5 }} size={14}>
            {hook?.name}
          </Text>
        </Box>
      ) : (
        <Box className={classes.hookLineItemContainer}>
          {/* Position Indicator  */}

          <Avatar className={classes.indexAvatar}>
            <Text size={14}>{index + 1}</Text>
          </Avatar>

          {/* Chip Container */}

          <Box className={classes.chipContainer}>
            {/* PRE/POST badge */}

            <Box className={classes.prePostBadge}>
              <Text size={14}>{HOOK_TYPE_NAMES[hook?.type || 0]}</Text>
            </Box>

            {/* Hook Name */}

            <Text sx={{ ml: 0.625 }} size={14}>
              {hook?.name}
            </Text>

            <IconButton
              color="primary"
              aria-label="remove hook"
              disableFocusRipple
              disableRipple
              onClick={() => {
                setFieldValue(
                  'hooks',
                  hooks.filter(h => h.id !== hook.id),
                )
              }}
              sx={{
                color: 'darkDusk.main',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  )
}
