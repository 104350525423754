import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { fetchRelatedAssetsPaginatedV2 } from '../fetchers/fetchRelatedAssetsPaginatedV2'
import { APIListRequestParams } from 'api/common/utils'
import { AssetSummaryDTO, GetAssetHierarchiesResponseBodyDTO } from '@ketch-com/figurehead'

type LastPage = GetAssetHierarchiesResponseBodyDTO

type Params = APIListRequestParams<{
  canonicalResourceType?: number | null | undefined
  resourceTypeCode?: string | null | undefined
  query?: string | null | undefined
  assetCode?: string | null | undefined
  identityRelationType?: number
  isPersonal?: boolean
}>

export const useRelatedAssetsInfiniteV2 = ({
  enabled = true,
  onSettled,
  params,
}: {
  enabled?: boolean
  onSettled?: any
  params: Params
}) => {
  const { limit = 20 } = params

  const resp = useInfiniteQuery({
    enabled,
    queryKey: [
      ApiQueryKeys.relatedResourcesInfiniteV2,
      {
        ...params,
      },
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchAssetsResp = await fetchRelatedAssetsPaginatedV2({
        limit,
        start: (pageParam - 1) * limit,
        assetCode: params.assetCode,
        ...params,
      })

      const { data } = fetchAssetsResp

      data.hierarchies = data.hierarchies ?? []
      data.totalResults = data.totalResults ?? 0

      return data
    },
    getNextPageParam: ({ totalResults }: LastPage, allPages: LastPage[]) => {
      let totalAssetsFetchedSoFar = 0
      allPages?.forEach?.(p => {
        totalAssetsFetchedSoFar += p?.hierarchies?.length || 0
      })
      const nextPageParam = Math.ceil(totalAssetsFetchedSoFar / limit) + 1
      const getNextPageParamReturnValue = totalAssetsFetchedSoFar === totalResults ? undefined : nextPageParam
      return getNextPageParamReturnValue
    },
    onSettled,
  })

  let infiniteAssets: AssetSummaryDTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    infiniteAssets = [...infiniteAssets, ...(page?.hierarchySummaries || [])]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: infiniteAssets,
    totalResults,
  }
}
