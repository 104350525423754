import { theme } from '@ketch-com/deck'

export const avatarColorBucket = [
  {
    bg: theme.palette.labelText1.main,
    fg: theme.palette.label1.main,
  },
  {
    bg: theme.palette.labelText2.main,
    fg: theme.palette.label2.main,
  },
  {
    bg: theme.palette.labelText4.main,
    fg: theme.palette.label4.main,
  },
  {
    bg: theme.palette.labelText5.main,
    fg: theme.palette.label5.main,
  },
  {
    bg: theme.palette.labelText6.main,
    fg: theme.palette.label6.main,
  },
]

/**
 * In the event that a user no longer exists the user will not have an entry in the color map.
 * These will be the "no longer with us" colors.
 */
export const userNoLongerExistsColors = {
  bg: theme.palette.labelText6.main,
  fg: theme.palette.label6.main,
}
