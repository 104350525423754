import { Box } from '@mui/material'
import { useEffect } from 'react'
import { FormDataInputValue } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'
import { TextInput } from '@ketch-com/deck'

type Props = {
  formValue: FormDataInputValue
  setFormValue: (v: FormDataInputValue) => void
}
export const NumberValueInput: React.FC<Props> = ({ formValue = 0, setFormValue }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setFormValue(0), [])

  return (
    <Box display="flex" flexDirection="column">
      <TextInput
        type="number"
        label="Enter Value (Number)"
        fullWidth
        value={formValue as number}
        onChange={e => {
          setFormValue(Number(e.target.value))
        }}
        size="small"
      />
    </Box>
  )
}
