import React from 'react'

import Box from '@mui/material/Box'
import { PreferenceExperienceTab } from 'pages/consentAndRights/experiences/upsert/utils/preference/constants'
import { UpsertInlineLayoutPreviewContent } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutPreviewContent'
import { useUpsertPreferenceStyles } from '../../hooks'
import { HeaderPreview, OverviewPreview, PreferencePreview, RightsPreview, SubscriptionsPreview } from './components'

type Props = {
  activeTab: PreferenceExperienceTab
}

export const Preview: React.FC<Props> = ({ activeTab }) => {
  const classes = useUpsertPreferenceStyles()

  const isOverviewTabActive = activeTab === PreferenceExperienceTab.OVERVIEW
  const isPreferencesTabActive = activeTab === PreferenceExperienceTab.PREFERENCES
  const isRightsTabActive = activeTab === PreferenceExperienceTab.RIGHTS
  const isSubscriptionsTabActive = activeTab === PreferenceExperienceTab.SUBSCRIPTIONS

  return (
    <UpsertInlineLayoutPreviewContent className={classes.content}>
      <Box display="flex" className={classes.wrapper} alignItems="center" justifyContent="center">
        <Box display="flex" className={classes.preview} flexDirection="column">
          {/* Header Preview */}

          <HeaderPreview activeTab={activeTab} />

          {/* Overview Preview */}

          {isOverviewTabActive && <OverviewPreview />}

          {/* Preference Preview */}

          {isPreferencesTabActive && <PreferencePreview />}

          {/* Subscriptions Preview */}

          {isSubscriptionsTabActive && <SubscriptionsPreview />}

          {/* Rights Preview */}

          {isRightsTabActive && <RightsPreview />}
        </Box>
      </Box>
    </UpsertInlineLayoutPreviewContent>
  )
}
