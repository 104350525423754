import { Box, BoxProps, styled } from '@mui/material'
import React, { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren, BoxProps {
  selected: boolean
}

const WrapperBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '100%',
  gap: '4px',
  paddingBottom: '1px',
})

const SelectedBar = styled(Box)(({ theme }) => ({
  width: 18,
  height: 2,
  background: theme.palette.Highlight.PrimaryDark,
  borderRadius: 100,
}))

export const HeaderSectionWrapper: React.FC<Props> = ({ selected = false, children, ...rest }) => {
  return (
    <WrapperBox {...rest}>
      {children}
      <SelectedBar visibility={selected ? 'unset' : 'hidden'} />
    </WrapperBox>
  )
}
