import React from 'react'

import { IEndFlowActivityParams } from 'pages/orchestration/workflows/edit/interfaces'
import { formatCodeFromName } from 'utils/formatters'
import { useFormikContext } from 'formik'
import { FormInput } from 'components/form/FormInput'
import { Box } from '@mui/material'

type Props = {}

export const DescriptionAndCode: React.FC<Props> = props => {
  const { setFieldValue } = useFormikContext<IEndFlowActivityParams>()

  return (
    <>
      <Box mb={3}>
        <FormInput
          required
          formPropertyName="description"
          fullWidth
          label="Description Label"
          placeholder="Enter description"
          onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
          shouldUpdateDebounced
        />
      </Box>
      {/* Code */}
      <Box mb={3}>
        <FormInput
          formPropertyName="code"
          fullWidth
          label="Code"
          placeholder="Enter code"
          required
          shouldUpdateDebounced
        />
      </Box>
    </>
  )
}
