import { LIST_DISPLAY_MODES } from '.'
import { CustomIconName, IconMap } from '@ketch-com/deck'

const stringsArray = Object.keys(IconMap) as CustomIconName[]

const iconDictionary = stringsArray.reduce(
  (acc, cur) => {
    acc[cur] = cur
    return acc
  },
  {} as { [key in CustomIconName]: CustomIconName },
)

export const LIST_DISPLAY_MODE_OPTIONS: any = [
  {
    id: LIST_DISPLAY_MODES.TILES,
    icon: iconDictionary.OStacksView,
  },
  {
    id: LIST_DISPLAY_MODES.LIST,
    icon: iconDictionary.OListView,
  },
]
