import { createContext } from 'react'
import { TrackerDetailsUtils } from 'pages/policyCenter/trackers/interfaces'
import { useTrackerDetailsUtils } from 'pages/policyCenter/trackers/hooks'

export const TrackerDetailsContext = createContext({} as TrackerDetailsUtils)

type Props = {
  children?: React.ReactNode
}

export const TrackerDetailsContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useTrackerDetailsUtils()

  return (
    <TrackerDetailsContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </TrackerDetailsContext.Provider>
  )
}

export const withTrackerDetailsContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <TrackerDetailsContextProvider>
        <Component {...props} />
      </TrackerDetailsContextProvider>
    )
  }
}
