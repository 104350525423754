import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

import { LanyardBannerExperienceProps } from '@ketch-com/lanyard'
import { ThemeBannerPosition } from 'interfaces/themes-v2/themeBannerPosition'
import { PreviewDeviceMode } from 'interfaces/preview/previewDeviceMode'
import { BannerPreview } from 'pages/consentAndRights/experiences/preview/components/bannerPreview/BannerPreview'
import { ExperiencePreviewScreen } from 'pages/consentAndRights/experiences/preview/components/ExperiencePreviewScreen'
import { ensureHydratedThemeColors } from 'utils/helpers/ensureHydratedThemeColors'

const useStyles = makeStyles(
  createStyles({
    mobileScreenRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    screen: {
      display: 'flex',
    },
    bottom: {
      alignSelf: 'flex-end',
    },
    top: {
      alignSelf: 'flex-start',
    },
    bottomLeft: {
      width: 360,
      alignSelf: 'flex-end',
    },
    bottomRight: {
      width: 360,
      marginLeft: 'auto',
      alignSelf: 'flex-end',
    },
    mobile: {
      alignSelf: 'flex-end',
    },
  }),
  { name: 'BannerScreenPreview' },
)

type Props = {
  mode?: PreviewDeviceMode
} & Omit<LanyardBannerExperienceProps, 'wrapperClassName' | 'className'>

export const BannerScreenPreview = ({ mode = PreviewDeviceMode.DESKTOP, theme, ...rest }: Props) => {
  const classes = useStyles()
  const { bannerPosition = ThemeBannerPosition.BOTTOM } = theme

  return (
    <ExperiencePreviewScreen
      mode={mode}
      className={clsx({
        [classes.mobileScreenRoot]: mode === PreviewDeviceMode.MOBILE,
      })}
      classes={{
        screen: classes.screen,
      }}
    >
      <BannerPreview
        theme={ensureHydratedThemeColors(theme)}
        wrapperClassName={
          mode === PreviewDeviceMode.DESKTOP
            ? clsx({
                [classes.bottom]: bannerPosition === ThemeBannerPosition.BOTTOM,
                [classes.top]: bannerPosition === ThemeBannerPosition.TOP,
                [classes.bottomLeft]: bannerPosition === ThemeBannerPosition.BOTTOM_LEFT,
                [classes.bottomRight]: bannerPosition === ThemeBannerPosition.BOTTOM_RIGHT,
              })
            : classes.mobile
        }
        {...rest}
      />
    </ExperiencePreviewScreen>
  )
}
