import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Button, Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import { SubscriptionControlDTO } from '@ketch-com/figurehead'
import { useNavigate } from 'react-router-dom'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormMode } from 'interfaces/formModes/formMode'

export const subscriptionControlColumns = (firstControlId: string): GridColDef<SubscriptionControlDTO>[] => [
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    renderCell: (cellParams: GridRenderCellParams<SubscriptionControlDTO>) => {
      const {
        row: { name },
        id,
      } = cellParams

      return (
        <TableCell title={name}>
          {id === firstControlId ? (
            <Status variant={StatusVariant.filled} label="Required" status={StatusState.new} />
          ) : null}
        </TableCell>
      )
    },
  },
  {
    align: 'right',
    field: 'dropDown',
    headerAlign: 'left',
    headerName: '',
    sortable: false,
    maxWidth: 180,
    flex: 1,
    renderCell: ({ row: { code } }: GridRenderCellParams<SubscriptionControlDTO>) => {
      return <SubscriptionControlsButtonCell subscriptionControlCode={code} />
    },
  },
]

const SubscriptionControlsButtonCell = ({ subscriptionControlCode }: { subscriptionControlCode?: string }) => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const isPermittedToManageSubscriptions = isPermitted(PERMISSIONS.SUBSCRIPTIONS_WRITE)

  const onClickHandler = () => {
    navigate(
      RoutesManager.policyCenter.subscriptions.upsert.subscriptionControl.root.getURL({
        code: subscriptionControlCode,
        formMode: FormMode.EDIT,
      }),
    )
  }
  return (
    <TableCell>
      <Button disabled={!isPermittedToManageSubscriptions} color="tertiary" onClick={onClickHandler}>
        Edit
      </Button>
    </TableCell>
  )
}
