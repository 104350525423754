import { Appliance } from '@ketch-com/windlass/dist/appliance/appliances'
import { MaybeNull } from 'interfaces'
import { TransponderFormValues } from 'interfaces/appliances'

type GetTransponderFormValuesArgs = {
  appliance: MaybeNull<Appliance>
}

export const getTransponderFormValues = ({ appliance }: GetTransponderFormValuesArgs): TransponderFormValues => ({
  name: appliance?.name || '',
})
