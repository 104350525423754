import React from 'react'
import { Text } from 'react-konva'

import { ICoordinates } from 'pages/orchestration/workflows/edit/interfaces'
import { getAbsoluteCoordinates } from 'pages/orchestration/workflows/edit/utils/positioning/absoluteCoordinates'
import * as CanvasConstants from 'pages/orchestration/workflows/edit/utils/constants'

interface ICanvasTextProps {
  index: number
  text: string
  gridPosition: ICoordinates
}

export const CanvasTransitionText: React.FC<ICanvasTextProps> = props => {
  const { index, text, gridPosition } = props
  const absoluteCoordinates = getAbsoluteCoordinates(gridPosition)
  const coordinates = {
    x: absoluteCoordinates.x - CanvasConstants.GRID_CELL_WIDTH * 2,
    y: absoluteCoordinates.y + CanvasConstants.GRID_CELL_HEIGHT * 0.37,
  }
  const width = index ? CanvasConstants.GRID_CELL_WIDTH * 1.3 : CanvasConstants.GRID_CELL_WIDTH * 0.9

  if (!text) {
    return null
  }

  if (!index) {
    coordinates.x = absoluteCoordinates.x - CanvasConstants.GRID_CELL_WIDTH * 0.95
    coordinates.y = absoluteCoordinates.y + 10
  }

  return (
    <Text
      text={text}
      fill="#5774FF"
      align={index ? 'right' : 'center'}
      fontStyle="bold"
      fontSize={12}
      lineHeight={1.3}
      wrap="none"
      ellipsis
      width={width}
      x={coordinates.x}
      y={coordinates.y}
    />
  )
}
