import React, { Fragment, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { UserShortDTO } from 'interfaces/users/users'
import { UserStatus } from 'interfaces/users/userStatus'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { DeleteUserModal } from 'components/modals/users/DeleteUserModal'
import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { DeleteInviteModal } from 'components/modals/users/DeleteInviteModal'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'

type Props = {
  user: UserShortDTO
}

export const UsersListDropdown: React.FC<Props> = ({ user }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false)
  const [isDeleteInviteModalOpen, setIsDeleteInviteModalOpen] = useState(false)
  const [isBusy] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  /* Resend API currently not working. Uncomment once resolved by Auth0
  const { mutate: handleReSendInvite } = useResendInvite({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: () => {
      showToast({ content: 'Invite has been sent', type: 'success' })
      setIsBusy(false)
    },
    onError: () => {
      showToast({ content: 'Failed to sent invite', type: 'error' })
      setIsBusy(false)
    },
  })
  */

  const menuItems = useMemo(
    () => [
      {
        id: 'edit',
        content: 'Edit',
        onClick: () => navigate(RoutesManager.settings.users.upsert.root.getURL({ id: user.ID })),
        // In auth0, invited "users" do not actually exist yet so cannot be fetched/edited
        hidden: !isPermitted(PERMISSIONS.USER_ADMIN) || user.status === UserStatus.INVITED,
      },
      {
        id: 'deleteUser',
        content: 'Delete User',
        onClick: () => setIsDeleteUserModalOpen(true),
        // In auth0, invited "users" do not actually exist yet so cannot be fetched/deleted here
        hidden: !isPermitted(PERMISSIONS.USER_ADMIN) || user.status === UserStatus.INVITED,
      },
      {
        id: 'resentInvite',
        content: 'Resend Invite',
        hidden: !isPermitted(PERMISSIONS.USER_ADMIN) || user.status === UserStatus.ACTIVE,
        onClick: () => navigate(RoutesManager.settings.users.upsert.root.getURL({ id: NEW_ITEM_CODE })),
      },
      {
        id: 'Delete Invited User',
        content: 'Delete',
        onClick: () => setIsDeleteInviteModalOpen(true),
        hidden: !isPermitted(PERMISSIONS.USER_ADMIN) || user.status !== UserStatus.INVITED,
      },
    ],
    [isPermitted, navigate, user.ID, user.status],
  )

  return (
    <Fragment>
      <Button size="medium" variant="iconSubtle" onClick={handleClickListItem} pending={isBusy}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
        {menuItems.map(item => {
          if (!item.hidden) {
            return (
              <ActionSheetItem key={item.id} onClick={item.onClick}>
                {item.content}
              </ActionSheetItem>
            )
          } else {
            return null
          }
        })}
      </ActionSheetWrapper>

      {isDeleteUserModalOpen && (
        <DeleteUserModal
          userId={user.ID}
          onCancel={() => setIsDeleteUserModalOpen(false)}
          onSubmit={async () => {
            await queryClient.refetchQueries(ApiQueryKeys.usersPaginated)

            setIsDeleteUserModalOpen(false)
          }}
        />
      )}

      {isDeleteInviteModalOpen && (
        <DeleteInviteModal
          inviteeEmail={user.email}
          onCancel={() => setIsDeleteInviteModalOpen(false)}
          onSubmit={async () => {
            await queryClient.refetchQueries(ApiQueryKeys.usersPaginated)

            setIsDeleteInviteModalOpen(false)
          }}
        />
      )}
    </Fragment>
  )
}
