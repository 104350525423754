import React from 'react'
import { GetAssetsStatisticsResponseBodyDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'
import { Box } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { ReactComponent as DatabaseIcon } from 'assets/icons/ass_list_item_database_icon_small.svg'
import { ReactComponent as TablesIcon } from 'assets/icons/dataset.svg'
import { ReactComponent as SensitiveDataAssetsIcon } from 'assets/icons/person-avatar-small.svg'
import { ReactComponent as DataSystemsIcon } from 'assets/icons/data_systems.svg'
import { formatCount } from 'pages/insights/utils'

type Props = {
  assetStatistics: GetAssetsStatisticsResponseBodyDTO
}

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      assetStatisticsContainer: {
        display: 'flex',
        gap: spacing(2),
      },
      assetStatisticsItem: {
        backgroundColor: palette.superLightGrey.main,
        borderRadius: 16,
        padding: spacing(3),
        flex: 1,
      },
      assetTypeIcon: {
        marginRight: spacing(1),
        minWidth: 32,
        minHeight: 32,
      },
      assetStatisticsItemHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: spacing(1),
      },
    }),
  { name: 'AssetsStatistics' },
)

export const AssetsStatistics: React.FC<Props> = ({ assetStatistics }) => {
  const classes = useStyles()
  const { dataSystems = 0, databases = 0, sensitiveDataAssets = 0, datasets = 0 } = assetStatistics
  return (
    <Box className={classes.assetStatisticsContainer}>
      <Box className={classes.assetStatisticsItem}>
        <Box className={classes.assetStatisticsItemHeader}>
          <Text size={34} weight={800}>
            {String(formatCount(databases))}
          </Text>
          <DatabaseIcon className={classes.assetTypeIcon} />
        </Box>
        <Text size={14} weight={600}>
          {databases === 1 ? 'Database' : 'Databases'}
        </Text>
      </Box>
      <Box className={classes.assetStatisticsItem}>
        <Box className={classes.assetStatisticsItemHeader}>
          <Text size={34} weight={800}>
            {String(formatCount(datasets))}
          </Text>
          <TablesIcon className={classes.assetTypeIcon} />
        </Box>
        <Text size={14} weight={600}>
          {datasets === 1 ? 'Table' : 'Tables'}
        </Text>
      </Box>
      <Box className={classes.assetStatisticsItem}>
        <Box className={classes.assetStatisticsItemHeader}>
          <Text size={34} weight={800}>
            {String(formatCount(sensitiveDataAssets))}
          </Text>
          <SensitiveDataAssetsIcon className={classes.assetTypeIcon} />
        </Box>
        <Text size={14} weight={600}>
          {sensitiveDataAssets === 1 ? 'Sensitive Data Asset' : 'Sensitive Data Assets'}
        </Text>
      </Box>
      <Box className={classes.assetStatisticsItem}>
        <Box className={classes.assetStatisticsItemHeader}>
          <Text size={34} weight={800}>
            {String(formatCount(dataSystems))}
          </Text>
          <DataSystemsIcon className={classes.assetTypeIcon} />
        </Box>
        <Text size={14} weight={600}>
          {dataSystems === 1 ? 'Data System' : 'Data Systems'}
        </Text>
      </Box>
    </Box>
  )
}
