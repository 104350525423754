export enum VendorChoice {
  SOME = 0,
  ALL = 1,
}

export enum JurisdictionChoice {
  SOME,
  ALL,
}

export enum MSPAChoice {
  covered = 'coveredTransaction',
  optOut = 'optOutOption',
  serviceProvider = 'serviceProvider',
}

export const VENDOR_CHOICE_NAME = {
  [VendorChoice.SOME]: 'Selected Vendors',
  [VendorChoice.ALL]: 'All Vendors',
}

export const JURISDICTION_CHOICE_NAME = {
  [JurisdictionChoice.SOME]: 'Selected Jurisdictions',
  [JurisdictionChoice.ALL]: 'All Jurisdictions',
}

export const MSPA_CHOICE_NAME = {
  [MSPAChoice.covered]: 'MSPA Covered Transaction',
  [MSPAChoice.optOut]: 'MSPA Opt-Out Option Mode',
  [MSPAChoice.serviceProvider]: 'MSPA Service Provider Mode',
}

export const VENDOR_CHOICE_DESCRIPTIONS = {
  [VendorChoice.SOME]: '',
  [VendorChoice.ALL]: 'New vendors will be added automatically',
}

export const JURISDICTION_CHOICE_DESCRIPTIONS = {
  [JurisdictionChoice.SOME]: '',
  [JurisdictionChoice.ALL]: 'New jurisdictions will be added automatically',
}

export const MSPA_CHOICE_DESCRIPTIONS = {
  [MSPAChoice.covered]: '',
  [MSPAChoice.optOut]:
    'Opt-Out Option Mode is for First Parties that may engage in Sell or Share Personal Information.',
  [MSPAChoice.serviceProvider]:
    'Service Provider Mode is for signatories that do not “sell,” “share,” or process personal information for “targeted advertising.”',
}

export const VENDOR_CHOICE_OPTIONS = [
  {
    id: false,
    name: VENDOR_CHOICE_NAME[VendorChoice.SOME],
    description: VENDOR_CHOICE_DESCRIPTIONS[VendorChoice.SOME],
  },
  {
    id: true,
    name: VENDOR_CHOICE_NAME[VendorChoice.ALL],
    description: VENDOR_CHOICE_DESCRIPTIONS[VendorChoice.ALL],
  },
]

export const JURISDICTION_CHOICE_OPTIONS = [
  {
    id: false,
    name: JURISDICTION_CHOICE_NAME[JurisdictionChoice.SOME],
    description: JURISDICTION_CHOICE_DESCRIPTIONS[JurisdictionChoice.SOME],
  },
  {
    id: true,
    name: JURISDICTION_CHOICE_NAME[JurisdictionChoice.ALL],
    description: JURISDICTION_CHOICE_DESCRIPTIONS[JurisdictionChoice.ALL],
  },
]

export const MSPA_CHOICE_OPTIONS = [
  {
    id: MSPAChoice.optOut,
    name: MSPA_CHOICE_NAME[MSPAChoice.optOut],
    description: MSPA_CHOICE_DESCRIPTIONS[MSPAChoice.optOut],
  },
  {
    id: MSPAChoice.serviceProvider,
    name: MSPA_CHOICE_NAME[MSPAChoice.serviceProvider],
    description: MSPA_CHOICE_DESCRIPTIONS[MSPAChoice.serviceProvider],
  },
]
