import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import { Tooltip, Typography } from '@mui/material'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { CookieProvenanceNames } from 'interfaces/cookies/cookieProvenance'
import { CookiesListDropdown } from './CookiesListDropdown'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { CookiesListIssuesCell } from './CookiesListIssuesCell'

export const cookieColumns: GridColDef<CookieDTO>[] = [
  {
    align: 'left',
    field: 'issues',
    headerAlign: 'left',
    headerName: '',
    maxWidth: 50,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return <CookiesListIssuesCell hasIssues={row.hasIssues} />
    },
  },
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    renderCell: (params: GridRenderCellParams<CookieDTO>) => {
      return params.row.name ? (
        <TableCell sx={{ margin: '8px 0' }} title={params.row.name} subTitle={params.row.serviceProvider} />
      ) : (
        <TableCell sx={{ margin: '8px 0' }}>
          <Typography color={'lightGrey.main'} variant="body">
            None
          </Typography>
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'provenance',
    flex: 0.7,
    headerAlign: 'left',
    headerName: 'Provenance',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return (
        <TableCell>
          <Chip size="small" label={CookieProvenanceNames[row.provenance]} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'library',
    flex: 0.7,
    headerAlign: 'left',
    headerName: 'Library',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return (
        <TableCell>
          {row.appCode ? (
            <Chip size="small" label={row.appCode} />
          ) : (
            <Typography color={'lightGrey.main'} variant="body">
              None
            </Typography>
          )}
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'applications',
    flex: 0.7,
    headerAlign: 'left',
    headerName: 'Properties',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return (
        <TableCell>
          {!row.propertyCookies || !row.propertyCookies?.length ? (
            <Chip size="small" label="0" />
          ) : (
            <Tooltip
              title={
                <>
                  {row.propertyCookies?.map(propertyCookie => (
                    <div key={propertyCookie.propertyCode}>{propertyCookie.propertyCode}</div>
                  ))}
                </>
              }
            >
              <Chip size="small" label={row.propertyCookies?.length} />
            </Tooltip>
          )}
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'purposes',
    flex: 0.7,
    headerAlign: 'left',
    headerName: 'Purposes',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return (
        <TableCell>
          {!row.purposes || !row.purposes?.length ? (
            <Chip size="small" label="0" />
          ) : (
            <Tooltip title={<>{row.purposes?.map(purpose => <div key={purpose.code}>{purpose.name}</div>)}</>}>
              <Chip size="small" label={row.purposes?.length} />
            </Tooltip>
          )}
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'status',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Status',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return (
        <TableCell>
          {row.status ? (
            <Status icon="Dot" label="Disclosed" status={StatusState.success} variant={StatusVariant.ghost} />
          ) : (
            <Status icon="Dot" label="Not Disclosed" status={StatusState.draft} variant={StatusVariant.ghost} />
          )}
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'updatedAt',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Last Scanned',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return (
        <TableCell>
          <FormattedDateRenderer date={row.metadata?.updatedAt} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'dropDown',
    headerAlign: 'left',
    headerName: '',
    maxWidth: 50,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return (
        <TableCell>
          <CookiesListDropdown cookie={row} />
        </TableCell>
      )
    },
  },
]
