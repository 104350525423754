import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { makeStyles, createStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'

import { MaybeNull } from 'interfaces/common'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { EXPERIENCE_ACTION_BUTTON_DESTINATION_OPTIONS } from 'interfaces/experiences/experienceActionButtonDestination'
import {
  ExperienceBannerPrimaryButtonAction,
  EXPERIENCE_BANNER_PRIMARY_BUTTON_ACTION_OPTIONS,
} from 'interfaces/experiences/experienceBannerPrimaryButtonAction'
import {
  BannerField,
  SIDEBAR_TITLES,
  fieldNames,
  useOnRemove,
  isFieldRequired,
  serialize,
} from 'pages/consentAndRights/experiences/upsert/utils/common'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { Group } from 'components/ui-layouts/group/Group'
import { Button } from 'components/ui-kit/button/Button'
import { Text } from 'components/ui-kit/typography/Text'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRadioGroup } from 'components/ui-kit/form/radioGroup/FormRadioGroup'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { InterpolateLinks } from 'pages/consentAndRights/experiences/components/InterpolateLinks'
import { UpsertInlineLayoutPreview } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutPreview'
import { UpsertInlineLayoutPreviewContent } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutPreviewContent'
import { UpsertExperienceEditableOverlay } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEditableOverlay'
import { UpsertExperiencePreviewText } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewText'
import { UpsertExperiencePreviewButton } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewButton'
import { UpsertExperienceEmptyField } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEmptyField'
import { UpsertExperienceFieldSidebar } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceFieldSidebar'
import { InterpolatedTextArea } from 'pages/consentAndRights/experiences/upsert/components/common/interpolatedTextArea/InterpolatedTextArea'
import {
  BANNER_PRIMARY_BUTTON_TEXT_REQUIRED_VALUE,
  BANNER_SECONDARY_BUTTON_TEXT_REQUIRED_VALUE,
} from '../../../utils/consent/constants'
import { ConsentExperienceFormValues } from '../../../utils/consent/interfaces'
import { onRemoveHandledCases } from '../../../utils/common/hooks/useOnRemove'
import { FormSwitch } from 'components/ui-kit/form/switch/FormSwitch'
import { Typography } from '@mui/material'

const sectionsWithTranslations = [
  BannerField.MAIN_TITLE,
  BannerField.DESCRIPTION,
  BannerField.ACTION_BUTTON,
  BannerField.CONFIRM_BUTTON,
]

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      banner: {
        width: '100%',
        maxWidth: 845,
        padding: '19px 16px 44px 25px',
        boxShadow: '0px 5px 24px rgba(0, 0, 0, 0.65)',
        borderRadius: 3,
        backgroundColor: palette.darkDusk.main,
        color: palette.white.main,
      },
      closeIcon: {
        fontSize: typography.pxToRem(18),
      },
      header: {
        marginBottom: 14,
      },
      description: {
        flex: 1,
      },
      descriptionText: {
        whiteSpace: 'pre-line',
        overflowWrap: 'break-word',
      },
      buttons: {
        flexWrap: 'nowrap',
        marginLeft: 15,
      },
      content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      translationsTitle: {
        display: 'block',
        marginBottom: 20,
      },
    }),
  { name: 'UpsertBanner' },
)

type Props = {
  isEditMode: boolean
  organizationLanguages: LanguageWithStatusDTO[]
}

export const UpsertBanner: React.FC<Props> = ({ isEditMode, organizationLanguages }) => {
  const classes = useStyles()

  const [active, setActive] = useState<MaybeNull<BannerField>>(isEditMode ? null : BannerField.MAIN_TITLE)
  const { values, setFieldValue, setFieldTouched } = useFormikContext<ConsentExperienceFormValues>()

  const { title, footerDescription, buttonText, secondaryButtonText } = values.banner

  const getOverlayProps = (name: BannerField) => ({
    active: name === active,
    onClick: () => {
      setActive(name)
    },
  })

  const { onRemove } = useOnRemove({ organizationLanguages })

  const getSidebarProps = () => {
    const required = isFieldRequired({ field: active!, values })

    return {
      title: SIDEBAR_TITLES[active!],
      required,
      actions: (
        <>
          {required ? (
            <>
              {active === BannerField.ACTION_BUTTON && (
                <Button
                  disabled
                  size="big"
                  variant="secondary"
                  tooltip="Cannot remove when primary button is set to save all"
                >
                  Remove
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                disabled={!onRemoveHandledCases.includes(active!)}
                size="big"
                variant="secondary"
                onClick={() => {
                  onRemove(active!)
                }}
              >
                Remove
              </Button>
            </>
          )}
        </>
      ),
    }
  }

  const shouldShowTranslationsSection = !!organizationLanguages?.length && sectionsWithTranslations.includes(active!)

  return (
    <UpsertInlineLayoutPreview>
      <UpsertInlineLayoutPreviewContent className={classes.content}>
        <div className={classes.banner}>
          <Flex className={classes.header} justifyContent="space-between" alignItems="center">
            <UpsertExperienceEditableOverlay {...getOverlayProps(BannerField.MAIN_TITLE)}>
              {title ? (
                <UpsertExperiencePreviewText bold color="white">
                  {title}
                </UpsertExperiencePreviewText>
              ) : (
                <UpsertExperienceEmptyField>Main Title</UpsertExperienceEmptyField>
              )}
            </UpsertExperienceEditableOverlay>

            {/* Close Icon */}

            {values?.banner?.showCloseIcon ? (
              <UpsertExperienceEditableOverlay
                {...getOverlayProps(BannerField.CLOSE_ICON)}
                hideClickToEdit
                removeMinWidth
              >
                <CloseIcon className={classes.closeIcon} />
              </UpsertExperienceEditableOverlay>
            ) : (
              <UpsertExperienceEditableOverlay
                {...getOverlayProps(BannerField.CLOSE_ICON)}
                removeMinWidth
                hideClickToEdit
              >
                <Box display="flex" alignItems="center" sx={{ opacity: 0.75 }}>
                  <Text size={14} mr={2} color="white">
                    Enable Button
                  </Text>
                  <CloseIcon className={classes.closeIcon} />
                </Box>
              </UpsertExperienceEditableOverlay>
            )}
          </Flex>

          <Flex>
            <UpsertExperienceEditableOverlay
              className={classes.description}
              {...getOverlayProps(BannerField.DESCRIPTION)}
            >
              {(() => {
                const footerDescriptionText = serialize(footerDescription)

                return footerDescriptionText ? (
                  <UpsertExperiencePreviewText className={classes.descriptionText} size="small" color="white">
                    <InterpolateLinks text={footerDescriptionText} />
                  </UpsertExperiencePreviewText>
                ) : (
                  <UpsertExperienceEmptyField required size="small">
                    Description
                  </UpsertExperienceEmptyField>
                )
              })()}
            </UpsertExperienceEditableOverlay>

            <Group className={classes.buttons} inline={false} alignItems="flex-start" gap={15}>
              <UpsertExperienceEditableOverlay {...getOverlayProps(BannerField.ACTION_BUTTON)}>
                {secondaryButtonText ? (
                  <UpsertExperiencePreviewButton inverted>
                    <UpsertExperiencePreviewText bold size="small" color="white">
                      {secondaryButtonText}
                    </UpsertExperiencePreviewText>
                  </UpsertExperiencePreviewButton>
                ) : (
                  <UpsertExperienceEmptyField button size="small">
                    Secondary Button
                  </UpsertExperienceEmptyField>
                )}
              </UpsertExperienceEditableOverlay>

              <UpsertExperienceEditableOverlay {...getOverlayProps(BannerField.CONFIRM_BUTTON)}>
                {buttonText ? (
                  <UpsertExperiencePreviewButton>
                    <UpsertExperiencePreviewText bold size="small">
                      {buttonText}
                    </UpsertExperiencePreviewText>
                  </UpsertExperiencePreviewButton>
                ) : (
                  <UpsertExperienceEmptyField required button size="small">
                    Primary Button
                  </UpsertExperienceEmptyField>
                )}
              </UpsertExperienceEditableOverlay>
            </Group>
          </Flex>
        </div>
      </UpsertInlineLayoutPreviewContent>

      {!!active && (
        <UpsertExperienceFieldSidebar {...getSidebarProps()}>
          <>
            {active === BannerField.MAIN_TITLE && (
              <FormRow marginBottom={24}>
                <FormInput
                  required={isFieldRequired({ field: BannerField.MAIN_TITLE })}
                  fullWidth
                  name={fieldNames.consent.banner.title.root}
                  label="Display Text"
                />
              </FormRow>
            )}

            {active === BannerField.DESCRIPTION && (
              <FormRow marginBottom={24}>
                <InterpolatedTextArea
                  required={isFieldRequired({ field: BannerField.DESCRIPTION })}
                  label="Display Text"
                  name={fieldNames.consent.banner.footerDescription.root}
                />
              </FormRow>
            )}

            {active === BannerField.ACTION_BUTTON && (
              <>
                <FormRow marginBottom={24}>
                  <FormInput
                    required={isFieldRequired({ field: BannerField.ACTION_BUTTON, values })}
                    fullWidth
                    name={fieldNames.consent.banner.secondaryButtonText.root}
                    label="Display Text"
                  />
                </FormRow>

                <FormRow marginBottom={24}>
                  <FormRadioGroup
                    name={fieldNames.consent.banner.secondaryButtonDestination.root}
                    label="Action"
                    layout="column"
                    valueKey="id"
                    renderLabel={({ name }) => name}
                    options={EXPERIENCE_ACTION_BUTTON_DESTINATION_OPTIONS}
                  />
                </FormRow>
              </>
            )}

            {active === BannerField.CONFIRM_BUTTON && (
              <>
                <FormRow marginBottom={24}>
                  <FormInput
                    disabled={values.banner.primaryButtonAction === ExperienceBannerPrimaryButtonAction.ACCEPT_ALL}
                    required={isFieldRequired({ field: BannerField.CONFIRM_BUTTON })}
                    fullWidth
                    name={fieldNames.consent.banner.buttonText.root}
                    label="Display Text"
                  />
                </FormRow>

                <FormRow marginBottom={24}>
                  <FormRadioGroup
                    name={fieldNames.consent.banner.primaryButtonAction.root}
                    label="Action"
                    layout="column"
                    valueKey="id"
                    renderLabel={({ name }) => name}
                    options={EXPERIENCE_BANNER_PRIMARY_BUTTON_ACTION_OPTIONS}
                    onChange={async (e, value) => {
                      if (value === ExperienceBannerPrimaryButtonAction.ACCEPT_ALL) {
                        if (!values.banner.secondaryButtonText) {
                          const name = fieldNames.consent.banner.secondaryButtonText.root

                          setFieldValue(name, BANNER_SECONDARY_BUTTON_TEXT_REQUIRED_VALUE)
                          setFieldTouched(name, true)
                        }

                        setFieldValue(
                          fieldNames.consent.banner.buttonText.root,
                          BANNER_PRIMARY_BUTTON_TEXT_REQUIRED_VALUE,
                        )
                      }
                    }}
                  />
                </FormRow>
              </>
            )}

            {active === BannerField.CLOSE_ICON && (
              <FormRow marginBottom={24}>
                <FormSwitch inlineLabel label="Show X Button" name="banner.showCloseIcon" />
              </FormRow>
            )}
          </>

          {!!shouldShowTranslationsSection && (
            <>
              <Typography mb={2.5} variant="h4" color="fadedDarkGrey.main" component="div">
                Translations
              </Typography>

              {active === BannerField.MAIN_TITLE && (
                <>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <FormRow key={language.code} marginBottom={24}>
                      <FormInput
                        fullWidth
                        required={false}
                        name={fieldNames.consent.banner.title.translation(language.code)}
                        label={`Display Text (${language.englishName})`}
                      />
                    </FormRow>
                  ))}
                </>
              )}

              {active === BannerField.DESCRIPTION && (
                <>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <FormRow key={language.code} marginBottom={24}>
                      <InterpolatedTextArea
                        required={false}
                        name={fieldNames.consent.banner.footerDescription.translation(language.code)}
                        label={`Display Text (${language.englishName})`}
                      />
                    </FormRow>
                  ))}
                </>
              )}

              {active === BannerField.ACTION_BUTTON && (
                <>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <FormRow key={language.code} marginBottom={24}>
                      <FormInput
                        fullWidth
                        required={false}
                        name={fieldNames.consent.banner.secondaryButtonText.translation(language.code)}
                        label={`Display Text (${language.englishName})`}
                      />
                    </FormRow>
                  ))}
                </>
              )}

              {active === BannerField.CONFIRM_BUTTON && (
                <>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <FormRow key={language.code} marginBottom={24}>
                      <FormInput
                        fullWidth
                        required={false}
                        name={fieldNames.consent.banner.buttonText.translation(language.code)}
                        label={`Display Text (${language.englishName})`}
                      />
                    </FormRow>
                  ))}
                </>
              )}
            </>
          )}
        </UpsertExperienceFieldSidebar>
      )}
    </UpsertInlineLayoutPreview>
  )
}
