import { useContext } from 'react'
import { BuilderContext } from '../../../../../../Builder'
import { PreferenceExperienceBuilderSection, PreferenceThemeBuilderSection } from '../../../../../../utils/enums'
import { SelectableSection } from '../../../../../SelectableSection'
import { Box, styled } from '@mui/material'
import { RequestsHomeHeader } from './components/RequestsHomeHeader'
import { RequestsHomeDsrLink } from './components/RequestsHomeDsrLink'
import { RequestsHomeTypes } from './components/RequestsHomeTypes'

const RequestsTabBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

export const RequestsHomeTab: React.FC = () => {
  const { isTheme } = useContext(BuilderContext)

  return (
    <RequestsTabBox>
      <SelectableSection
        section={isTheme ? PreferenceThemeBuilderSection.RequestsHomeHeader : undefined}
        overlayProps={{ marginTop: '12px', marginBottom: '28px' }}
      >
        <RequestsHomeHeader />
      </SelectableSection>
      <SelectableSection
        section={
          isTheme
            ? PreferenceThemeBuilderSection.RequestsHomeDsrLink
            : PreferenceExperienceBuilderSection.RequestsHomeDsrLink
        }
      >
        <RequestsHomeDsrLink />
      </SelectableSection>
      <SelectableSection
        section={
          isTheme
            ? PreferenceThemeBuilderSection.RequestsHomeRightsList
            : PreferenceExperienceBuilderSection.RequestsHomeRightsListTitle
        }
        overlayProps={{ marginTop: '40px' }}
      >
        <RequestsHomeTypes />
      </SelectableSection>
    </RequestsTabBox>
  )
}
