import { SxProps, Theme, Typography, Box } from '@mui/material'
import { uniqueId } from 'lodash'
import { CheckboxProps, Checkbox as DeckCheckbox } from '@ketch-com/deck'

export interface Props extends CheckboxProps {
  /** ID of an element */
  id?: string
  /** Name of an element */
  name?: string
  /** Test ID of an element for selectors */
  testId?: string
  /** Container styles */
  containerSx?: SxProps<Theme>
}

/**
 * -
 */
export const Checkbox: React.FC<Props> = ({
  id = uniqueId(),
  name,
  testId,
  className,
  title,
  value,
  disabled = false,
  onChange,
  containerSx,
  ...rest
}) => {
  return (
    <Box sx={containerSx} display="flex" alignItems="center" gap={0.5}>
      <DeckCheckbox
        {...rest}
        id={id}
        name={name}
        data-test-id={`Checkbox-${testId}`}
        checked={value === true}
        disabled={disabled}
        onChange={onChange}
      />

      {!!title && <Typography variant={value === true ? 'label' : 'body'}>{title}</Typography>}
    </Box>
  )
}
