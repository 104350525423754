import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { MaybeNull } from 'interfaces'
import { CookieDTO, CookieFormDTO } from 'interfaces/cookies/cookie'
import { getCookieInitialValues } from 'pages/policyCenter/cookies/upsert/utils/formValues'
import { useCookieValidationSchema } from 'pages/policyCenter/cookies/upsert/utils/formValidation'
import { getCookieFormSteps } from 'pages/policyCenter/cookies/upsert/utils/formSteps'
import { CookieBasicDetailsFormSection } from 'pages/policyCenter/cookies/upsert/components/CookieBasicDetailsFormSection'
import { CookiePurposesFormSection } from 'pages/policyCenter/cookies/upsert/components/CookiePurposesFormSection'
import { CookieApplicationsFormSection } from 'pages/policyCenter/cookies/upsert/components/CookieApplicationsFormSection'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'

type Props = {
  isReady: boolean
  isKetchCookie: boolean
  cookie: MaybeNull<CookieDTO>
  ketchCookie: MaybeNull<CookieDTO>
  onSubmit: (values: CookieFormDTO) => Promise<void>
}

export const CreateCookieView: React.FC<Props> = ({ isReady, isKetchCookie, cookie, ketchCookie, onSubmit }) => {
  const [currentFormStep, setCurrentFormStep] = useState(0)
  const navigate = useNavigate()
  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Cookies', link: RoutesManager.policyCenter.cookies.root.getURL() },
    { title: 'Create' },
  ]
  const initialValues = useMemo(() => getCookieInitialValues(cookie, ketchCookie), [cookie, ketchCookie])
  const validationSchema = useCookieValidationSchema({ isKetchCookie })
  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })
  const renderedChildren = [
    <CookieBasicDetailsFormSection isKetchCookie={isKetchCookie} />,
    <CookiePurposesFormSection isKetchCookie={isKetchCookie} />,
    <CookieApplicationsFormSection isKetchCookie={isKetchCookie} />,
  ]
  const { submitForm, isSubmitting, validateForm, setFieldTouched } = formikProps
  const isLastStep = getCookieFormSteps().length - 1 === currentFormStep
  const onAccept = async () => {
    const errors = await validateForm()
    if (Object.keys(errors).length) {
      Object.keys(errors).forEach(errorKey => {
        setFieldTouched(errorKey, true)
      })
      return
    }
    if (isLastStep) {
      submitForm()
    } else {
      setCurrentFormStep(prev => prev + 1)
    }
  }

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          sx={{
            '&.UpsertLayout-container': {
              '& .UpsertLayout-stepper': {
                // 8px gap between layout stepper and content
                top: '8px',
              },
            },
          }}
          formSteps={getCookieFormSteps()}
          mode={UpsertLayoutMode.create}
          currentFormStep={currentFormStep}
          showStepper
          renderFormTitle={() => <ContentGroup title="Create New Cookie" titleVariant="h2" isDivider />}
          onAccept={onAccept}
          onCancel={() => {
            navigate(RoutesManager.policyCenter.cookies.view.root.getURL({ code: cookie?.code }))
          }}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
          onThirdActionButtonHandler={() => {
            navigate(RoutesManager.policyCenter.cookies.library.root.getURL())
          }}
          thirdActionButtonText="Back To Library"
          thirdButtonProps={{
            color: 'tertiary',
            size: 'large',
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {isReady ? (
              renderedChildren.filter((_, i) => currentFormStep >= i)
            ) : (
              <Box
                sx={{
                  margin: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spinner size="32px" thickness={2.5} />
              </Box>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
