import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIRequestParams } from 'api/common/utils'
import { AssetSummaryDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  assetCode: string
  resourceTypeCode?: string
  isV2FeatureFlagEnabled?: boolean
}>

type Response = { assetSummary: AssetSummaryDTO }

export const fetchAssetV2 = ({ assetCode, resourceTypeCode, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/assets/${assetCode}`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/assets/${assetCode}`

  return API.get<Response>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          ...(resourceTypeCode && {
            resourceTypeCode,
          }),
        },
      },
    }),
  )
}
