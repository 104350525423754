import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchFormField } from '../fetchers/fetchFormField'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.formField,
  queryFn: fetchFormField,
  select: res => res?.data?.formField || undefined,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useFormField = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
