import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { upsertTrackerPropertyDisclosure } from '../fetchers/upsertTrackerPropertyDisclosure'

const useCustomMutation = createUseMutation({
  mutationFn: upsertTrackerPropertyDisclosure,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpsertTrackerPropertyDisclosure = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
