import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'

type Props = {
  index: number
}

export const GroupNumber: React.FC<Props> = ({ index }) => {
  return (
    <Box
      height={32}
      width={32}
      borderRadius="16px"
      sx={({ palette }) => ({ backgroundColor: palette.fadedGrey.main })}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Text weight={600}>{index + 1}</Text>
    </Box>
  )
}
