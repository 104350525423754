import React from 'react'
import { Box } from '@mui/material'
import { Button, DataGrid, TooltipButton, EmptyState, ListLayout } from '@ketch-com/deck'
import { GridRowParams } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { cookieColumns } from './cookiesListDataGridUtils'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { CookiesListFilters } from './CookiesListFilters'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateCookies.svg'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

type Props = {
  isLoading: boolean
  cookies: CookieDTO[]
}

export const CookiesList: React.FC<Props> = ({ isLoading, cookies }) => {
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()
  const isEntitledToCreateCookies = isEntitled(ENTITLEMENTS.QTY_COOKIE)
  const { isPermitted } = useIsPermitted()
  const isPermittedToCreateCookies = isPermitted([PERMISSIONS.COOKIE_WRITE])
  const canCreateCookies = isEntitledToCreateCookies && isPermittedToCreateCookies

  const handleUpsertCookieClick = () => {
    navigate(RoutesManager.policyCenter.cookies.library.root.getURL())
  }

  const hasCookiesWithIssues = cookies.some(cookie => cookie.hasOwnProperty('hasIssues') && !!cookie.hasIssues)
  const dataGridColumns = !hasCookiesWithIssues ? cookieColumns.slice(1) : cookieColumns

  return (
    <>
      <Box bgcolor="bone.main" minWidth="100%">
        <ListLayout
          headerTitle="Cookies"
          headerProps={{ isDivider: true }}
          actionHeaderBottomBlockComponent={
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                width: '100%',
              }}
            >
              <CookiesListFilters />
              <Box>
                {isPermittedToCreateCookies &&
                  (!isEntitledToCreateCookies ? (
                    <TooltipButton
                      onClick={() => handleUpsertCookieClick()}
                      title="No cookie entitlements remaining, please delete one or contact your admin for more"
                      disabled={!isEntitledToCreateCookies}
                    >
                      Create Cookie
                    </TooltipButton>
                  ) : (
                    <Button onClick={() => handleUpsertCookieClick()}>Create Cookie</Button>
                  ))}
              </Box>
            </Box>
          }
        >
          <Box width="100%">
            {!isLoading && !cookies.length ? (
              <Box
                sx={{
                  padding: '34px 0',
                  borderRadius: '11px',
                  height: '100%',
                  backgroundColor: 'white.main',
                }}
              >
                <EmptyState
                  customIcon={<EmptyStateIcon />}
                  title="There are no cookies for this organization"
                  subTitle={canCreateCookies ? 'Create your first one to populate the table.' : undefined}
                  primaryButtonTitle={canCreateCookies ? 'Create Cookie' : undefined}
                  primaryButtonProps={{
                    onClick: () => handleUpsertCookieClick(),
                  }}
                />
              </Box>
            ) : (
              <Box>
                <DataGrid
                  sx={{
                    height: isLoading ? '500px' : '',
                    '& .MuiDataGrid-row:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  autosizeOnMount
                  columns={dataGridColumns}
                  disableChildrenSorting
                  disableColumnMenu
                  disableColumnPinning
                  disableColumnReorder
                  disableColumnResize
                  getRowId={row => row.ID}
                  getRowHeight={() => 'auto'}
                  hideFooter
                  loading={isLoading}
                  onRowClick={({ row }: GridRowParams<CookieDTO>) => {
                    navigate(RoutesManager.policyCenter.cookies.view.root.getURL({ code: row?.code }))
                  }}
                  rows={cookies}
                />
              </Box>
            )}
          </Box>
        </ListLayout>
      </Box>
    </>
  )
}
