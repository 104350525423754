import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetDSRConfigCountResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

type Params = APIListRequestParams<{
  assetId?: string
  rightType?: number
  dataRole?: number
  canonicalRightCode?: string
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchDsrConfigCount = ({
  assetId,
  rightType,
  dataRole,
  start,
  limit,
  canonicalRightCode,
  isV2FeatureFlagEnabled,
}: Params) => {
  let entityUrl = `/api/v3/assets/${assetId}/dsr-config-count`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/assets/${assetId}/dsr-config-count`

  return API.get<GetDSRConfigCountResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          start,
          limit,
          ...(rightType && {
            rightType,
          }),
          ...(dataRole && {
            dataRole,
          }),
          ...(canonicalRightCode && {
            canonicalRightCode,
          }),
        },
      },
    }),
  )
}
