import { Banner } from '@ketch-com/deck'
import React, { useContext } from 'react'
import { RightsQueueViewContext } from '../../../context/RightsQueueViewContext'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { useUsersCurrentTimezone } from 'utils/hooks'

type Props = {}

export const TurboRejectBanner: React.FC<Props> = props => {
  const timeZoneShortFormat = useUsersCurrentTimezone()
  const { rightInvocation, original, appeal } = useContext(RightsQueueViewContext)

  const finalizedByName = appeal.forcefullyCompleted ? appeal.finalizedByName : original.finalizedByName
  const finalizedAt = appeal.forcefullyCompleted ? appeal.finalizedAt : original.finalizedAt

  const title = `This workflow was finalized by ${finalizedByName} on ${formatDateTimeFromUnix(
    finalizedAt,
  )} ${timeZoneShortFormat}.`

  if (!rightInvocation?.finalization?.forcefullyCompleted) return null

  return (
    <Banner
      title={title}
      severity="info" // purple
      sx={{
        mb: 3,
      }}
    >
      The state displayed below reflects the workflow status at the time of finalization.
    </Banner>
  )
}
