import { Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { FormCheckbox } from '../FormCheckbox'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'

export const PreferenceExperienceWelcomeTabGpcForm: React.FC = () => {
  return (
    <SidebarFormGroup sx={{ p: 0, m: 0, gap: 0 }}>
      <FormControlLabel
        control={<FormCheckbox name={experienceUpsertFormKeys.preferenceGpcBadgeVisible} />}
        label={<Typography variant={'label'}>GPC Signal Indicator</Typography>}
      />
    </SidebarFormGroup>
  )
}
