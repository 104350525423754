import { AutocompleteRenderInputParams, Grid, InputAdornment } from '@mui/material'
import { useUsers } from 'api/users/queries/useUsers'
import { AvatarSize, Button, ListItemText, ActionSheetItem, TextInput } from '@ketch-com/deck'
import { useFormikContext } from 'formik'
import { useAuth } from 'utils/hooks'
import Box from '@mui/material/Box'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { UserAvatar } from 'components/avatar/UserAvatar'
import { useCallback } from 'react'

type Props = { inFormRow?: boolean; label: string; name: string; onItemClick?: () => void }

type ContainerProps = {
  children?: React.ReactNode
}

export const AssigneeSelector: React.FC<Props> = ({ inFormRow = true, label, name, onItemClick = () => {} }) => {
  const { userData } = useAuth()
  const { data: usersList } = useUsers({ params: { active: true } })
  const { setFieldValue } = useFormikContext()

  const usersOptions = usersList?.map(user => ({
    id: user?.ID,
    name: `${user?.firstName || ''} ${user?.lastName || ''}`,
  }))

  const Container: React.FC<ContainerProps> = useCallback(
    ({ children }) =>
      inFormRow ? (
        <Box marginBottom={3}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <>{children}</>
      ),
    [inFormRow],
  )

  const renderInput = useCallback(
    (
      params: AutocompleteRenderInputParams,
      selectedValue: {
        id: string
        name: string
      } | null,
      error?: string | undefined,
    ) => {
      const currentUser = selectedValue ? usersList.find(user => user?.ID === selectedValue.id) : undefined
      return (
        <TextInput
          {...params}
          InputProps={{
            ...params.InputProps,
            placeholder: `Select ${label || 'Assignee'}`,
            startAdornment: params.inputProps.value && (
              <InputAdornment position="start">
                <UserAvatar user={currentUser} size={AvatarSize.small} />
              </InputAdornment>
            ),
          }}
          error={!!error}
          errorMessage={(!!error && error) || ''}
        />
      )
    },
    [label, usersList],
  )

  return (
    <Container>
      <FormDroplistButton
        disableClearable
        required
        fullWidth
        label={label}
        formPropertyName={name}
        items={usersOptions}
        valueKey="id"
        renderInput={renderInput}
        onItemClick={onItemClick}
        renderOption={(props, option, state, isSelected) => {
          const avatarUser = usersList.find(user => option.id === user?.ID)
          return (
            <ActionSheetItem selected={isSelected} {...props}>
              <ListItemText selected={isSelected}>
                <Box display="flex" alignItems="center" gap={1}>
                  <UserAvatar user={avatarUser} size={AvatarSize.small} />
                  {option.name}
                </Box>
              </ListItemText>
            </ActionSheetItem>
          )
        }}
      />
      <Box display="flex" alignItems="center">
        <Button sx={{ mt: 1 }} color="secondary" onClick={() => setFieldValue(name, userData?.userId)}>
          Assign to me
        </Button>
      </Box>
    </Container>
  )
}
