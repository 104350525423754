import React, { useState } from 'react'
import { useDebounce } from 'react-use'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { RightsQueueMegaFilterButtonPanelIds } from '../constants'
import { useFiltersV3 } from 'utils/hooks'
import { Checkbox, Icon, Spinner, TextInput } from '@ketch-com/deck'
import { RightDTO } from 'interfaces/rights/right'

const panelId = RightsQueueMegaFilterButtonPanelIds.rights

type Props = {
  rights: RightDTO[]
  isLoading: boolean
  handlePaginationReset: () => void
}

export const RightFilter: React.FC<Props> = ({ rights, isLoading, handlePaginationReset }) => {
  const [searchString, setSearchString] = useState<string>('')
  const [searchRightValue, setSearchRightValue] = useState<string>('')
  const { clearAllEntriesForKey, removeQueryParamFromList, replaceParams, getPanelParamValuesArrayByPanelId } =
    useFiltersV3()
  const panelParamValuesArray = getPanelParamValuesArrayByPanelId(panelId)

  const isAllRightsSelected = panelParamValuesArray.length === rights?.length

  useDebounce(
    () => {
      if (searchString) {
        setSearchRightValue(searchString)
      } else {
        setSearchRightValue('')
      }
    },
    500,
    [searchString],
  )

  const filteredRights = searchRightValue
    ? rights.filter(right => {
        const isChecked = !!panelParamValuesArray.find(param => param === right.code)
        return (
          right.name.toLowerCase().includes(searchRightValue.toLowerCase()) ||
          right.code.toLowerCase().includes(searchRightValue.toLowerCase()) ||
          isChecked // show checked rights even if they don't match the search string
        )
      })
    : rights

  return (
    <Box display="flex" flexWrap="wrap" flexDirection="column" alignItems="center" width="100%">
      {/* Search */}
      <Box paddingX={1.5} paddingTop={1} paddingBottom={1.5} width="100%">
        <TextInput
          sx={{ mb: 1 }}
          value={searchString}
          startIcon={<Icon name="OMag" />}
          endIcon={
            searchString && <Icon sx={{ cursor: 'pointer' }} name="OCross" onClick={() => setSearchString('')} />
          }
          size="small"
          fullWidth
          placeholder="Search Rights"
          onChange={event => setSearchString(event.target.value)}
        />
      </Box>

      {/* Loading Spinner  */}

      {isLoading ? (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="16px" thickness={2} />
        </Box>
      ) : !rights.length || !filteredRights.length ? (
        <Box height={34} width="100%" pl={1.5}>
          <Typography color="darkDuskFaded.main">No results found</Typography>
        </Box>
      ) : (
        //  All Rights Checkbox
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          sx={{ bgcolor: 'background.paper', maxHeight: 200, overflow: 'auto', width: '100%' }}
        >
          <Box display="flex" alignItems="center" padding="0px 3px">
            <Checkbox
              id="all-rights"
              name="all-rights"
              title="All"
              checked={isAllRightsSelected}
              onClick={() => {
                if (isAllRightsSelected) {
                  clearAllEntriesForKey(panelId)
                } else {
                  replaceParams({ [panelId]: rights.map(r => r.code).join(','), page: '0' })
                }
                handlePaginationReset()
              }}
            />
            <Typography>All</Typography>
          </Box>

          {/* Individual Rights */}
          {filteredRights.map(right => {
            const isChecked = !!panelParamValuesArray.find(param => param === right.code)
            return (
              <Box key={right.code} display="flex" alignItems="center" padding="1px 3px">
                <Checkbox
                  id={right.code}
                  name={right.name}
                  title={right.name}
                  checked={isChecked}
                  onClick={() => {
                    if (isChecked) {
                      removeQueryParamFromList({ key: panelId, value: right.code })
                    } else {
                      replaceParams({ [panelId]: [...panelParamValuesArray, right.code].join(','), page: '0' })
                    }
                    handlePaginationReset()
                  }}
                />

                <Typography
                  sx={{
                    wordBreak: 'break-word',
                  }}
                >
                  {right.name}
                </Typography>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}
