import React from 'react'
import { styled } from '@mui/material/styles'

import { ObjectLiteral } from 'interfaces'

import { Group } from 'components/ui-layouts/group/Group'
import { DropList } from 'components/ui-kit/dropList/DropList'
import { usePoliciesListFilters } from '../hooks'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'

const PREFIX = 'RightsQueueFilters'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledGroup = styled(Group)({
  [`&.${classes.root}`]: {
    marginTop: 24,
  },
})

type Props = {
  policies: ObjectLiteral[]
}

export const PoliciesListFilters: React.FC<Props> = (/* { policies } */) => {
  const [{ status, search }, setFilters] = usePoliciesListFilters()

  const statusItems = [{ id: 'foo', name: 'bar' }].map(({ id, name }) => ({
    content: name,
    active: status === id,
    onClick: () => {
      setFilters({ status: id })
    },
  }))

  const statusAllOption = {
    content: 'All Statuses',
    active: status === '',
    onClick: () => {
      setFilters({ status: '' })
    },
  }

  return (
    <StyledGroup className={classes.root}>
      <SearchInput
        onChange={nextSearchString => setFilters({ search: nextSearchString })}
        // TODO:BAC implement when paginated text search is implemented by BE
        onClear={() => setFilters({ search: '' })}
        value={search}
      />
      <DropList
        value={!!status}
        placeholder={status === '' ? 'All Statuses' : ''}
        items={[statusAllOption, ...statusItems]}
      >
        {/* {!!status && policies.find(({ code }) => code === status)?.name} */}
        {!!status && status}
      </DropList>
    </StyledGroup>
  )
}
