import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import { FormControlLabel, Typography } from '@mui/material'
import { FormCheckbox } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormCheckbox'

export const PreferenceThemeWelcomeTabQuickLinksForm: React.FC = () => {
  return (
    <>
      <SidebarFormGroup sx={{ pt: 0, pb: '16px' }} hasBorderBottom>
        <FormColorInput
          name={themeUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkColor}
          label={'Link Color'}
          fullWidth
          required
        />
        <FormControlLabel
          control={<FormCheckbox name={themeUpsertFormKeys.preferenceWelcomeTabQuickLinksLinkUnderline} />}
          label={<Typography variant={'label'}>Underline Links</Typography>}
          sx={{ mt: '-16px', px: '2px' }}
        />
      </SidebarFormGroup>
      <SidebarFormGroup sx={{ py: '16px' }}>
        <FormControlLabel
          control={<FormCheckbox name={themeUpsertFormKeys.preferenceWelcomeTabQuickLinksShowArrows} />}
          label={<Typography variant={'label'}>Show Arrow Indicators</Typography>}
          sx={{ px: '2px' }}
        />
      </SidebarFormGroup>
    </>
  )
}
