import { useUsers } from 'api/users/queries/useUsers'
import { showToast } from 'components/modals/AlertComponent'
import { useUpdateWorkflowExecution } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecution'
import { useContext } from 'react'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { RoutesManager } from 'utils/routing/routesManager'
import { useParams } from 'react-router-dom'
import { useImportContextInitialValues } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ImportContext/hooks'
import { useUpdateWorkflowExecutionStep } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStep'
import { useRetryExecution } from 'api/executions/mutations/useRetryExecution'
import { useNavigate } from 'react-router-dom'

export const useImportContextUtils = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { id: rightInvocationId } = useParams<{ id?: string }>()
  const {
    workflowExecutionId,
    workflowStep,
    isReady: isRightsQueueWorkflowTaskDetailViewContainerUtilsReady,
    stepId,
    rightInvocation,
    refetchWorkflowStep,
    stepDetails,
    canEdit,
    auth,
  } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const { data: users, isLoading: isUsersLoading } = useUsers({
    params: {
      active: true,
    },
  })

  const { mutateAsync: resolveWorkflowStep, isLoading: isResolving } = useUpdateWorkflowExecution({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.workflowExecutionStepDetailsV2, { workflowExecutionId, stepId }])
    },
  })

  const { mutateAsync: retryImportContext, isLoading: isRetryingImportContext } = useRetryExecution({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.executionResultsInfinite])
      showToast({ content: 'Retry initiated', type: 'success' })
      navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocationId }))
    },
    onError: () => {
      showToast({ content: 'Unable to retry', type: 'error' })
    },
  })

  const resolve = async (importContextValue: string) => {
    await resolveWorkflowStep({
      params: {
        formData: {
          temporalWorkflowID: `${workflowExecutionId}${workflowStep?.ID}`,
          signalName: 'import_context_resolve',
          signalValue: {
            importContextValue,
            selectedBy: 'currentUser',
          },
        },
      },
    })

    await refetchWorkflowStep()
  }

  const isReady = !!isRightsQueueWorkflowTaskDetailViewContainerUtilsReady && !isUsersLoading

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    { title: 'Rights Queue', link: RoutesManager.orchestration.rightsQueue.root.getURL() },
    {
      title: rightInvocation?.policyScopeName,
      link: RoutesManager.orchestration.rightsQueue.view.root.getURL({ id: rightInvocationId }),
    },
    {
      title: 'Import Context',
      link: RoutesManager.orchestration.rightsQueue.stepDetails.root.getURL({
        id: rightInvocationId,
        stepId,
        workflowExecutionId,
      }),
    },
  ]

  const initialValues = useImportContextInitialValues(stepDetails)

  const { mutateAsync: updateWorkflowExecutionStep } = useUpdateWorkflowExecutionStep({
    onSuccess: async () => {
      await queryClient.refetchQueries([
        ApiQueryKeys.workflowExecutionStepDetailsV2,
        { workflowExecutionId, stepId: workflowStep?.ID },
      ])

      await queryClient.refetchQueries([
        ApiQueryKeys.workflowExecutionStepDetails,
        { workflowExecutionId, stepId: workflowStep?.ID, organizationCode: auth?.userData?.organizationCode },
      ])
      showToast({ content: 'Updated workflow step', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update workflow step', type: 'error' })
    },
  })

  return {
    isReady,
    resolve,
    isResolving,
    retryImportContext,
    isRetryingImportContext,
    users,
    breadcrumbs,
    workflowStep,
    workflowExecutionId,
    stepDetails,
    rightInvocationId,
    initialValues,
    canEdit,
    auth,
    updateWorkflowExecutionStep,
  }
}
