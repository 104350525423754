import React from 'react'
import { Text } from 'react-konva'

import * as CanvasConstants from 'pages/orchestration/workflows/edit/utils/constants'

interface ICanvasTextProps {
  text: string
}

export const CanvasNameText: React.FC<ICanvasTextProps> = props => {
  const { text } = props
  const coordinates = {
    x: -CanvasConstants.GRID_CELL_WIDTH * 0.4,
    y: CanvasConstants.GRID_TEXT_GAP,
  }

  if (!text) {
    return null
  }

  return (
    <Text
      text={text}
      align="center"
      fill="#222222"
      fontSize={12}
      fontStyle="bold"
      lineHeight={1.3}
      wrap="none"
      ellipsis
      width={CanvasConstants.GRID_CELL_WIDTH * 1.8}
      x={coordinates.x}
      y={coordinates.y}
    />
  )
}
