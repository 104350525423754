import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { confirmTrackerOnNewProperties } from '../fetchers/confirmTrackerOnNewProperties'

const useCustomMutation = createUseMutation({
  mutationFn: confirmTrackerOnNewProperties,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useConfirmTrackerOnNewProperties = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
