const isString = (v: any): v is string => typeof v === 'string'

enum Colors {
  DEFAULT_DARK = '#071a24',
  DEFAULT_WHITE = '#ffffff',
  DEFAULT_LIGHT = '#f6f6f6',
  DEFAULT_GREY = '#daddde',
  DEFAULT_DARK_GREY = 'rgba(7,26,36,.5);',
  DEFAULT_DISABLED = '#ecedee',
}

type DecomposedColor = {
  type: 'rgb' | 'rgba' | 'hsl' | 'hsla'
  values: number[]
}

/**
 * Function that checks if a string represents a valid hex color code
 * @param hex A string containing a hex color code
 * @returns Boolean indicating if it is or isn't a valid color
 */
export function isValidHexColor(hex: string): boolean {
  const regex = /^#(?:[0-9a-fA-F]{3}){1,2}$|^#(?:[0-9a-fA-F]{4}){1,2}$/
  return regex.test(hex)
}

/**
 * Converts a color from CSS hex format to CSS rgba format.
 *
 * @param {string} color - Hex color, i.e. #nnn or #nnnnnn
 * @param {number} alpha - The opacity level
 * @returns {string} A CSS rgba color string
 */
export function hexToRgba(color: string, alpha = 1): string {
  const omitHash = color.substring(1)
  const re = new RegExp(`.{1,${omitHash.length / 3}}`, 'g')
  const colorsMatch = omitHash.match(re)
  let colors: string[] = []

  if (colorsMatch) {
    if (colorsMatch[0].length === 1) {
      colors = colorsMatch.map(n => n + n)
    } else {
      colors = colorsMatch.map(n => n)
    }
  }

  return colors ? `rgba(${colors.map(n => parseInt(n, 16)).join(', ')}, ${alpha})` : ''
}

/**
 * Converts a color from CSS hex format to CSS rgb format.
 *
 * @param {string} color - Hex color, i.e. #nnn or #nnnnnn
 * @returns {string} A CSS rgb color string
 */
function hexToRgb(color: string): string {
  const omitHash = color.substring(1)
  const re = new RegExp(`.{1,${omitHash.length / 3}}`, 'g')
  const colorsMatch = omitHash.match(re)
  let colors: string[] = []

  if (colorsMatch) {
    if (colorsMatch[0].length === 1) {
      colors = colorsMatch.map(n => n + n)
    } else {
      colors = colorsMatch.map(n => n)
    }
  }

  return colors ? `rgb(${colors.map(n => parseInt(n, 16)).join(', ')})` : ''
}

/**
 * Converts a color from hsl format to rgb format.
 *
 * @param {string} color - HSL color values
 * @returns {string} rgb color values
 */
function hslToRgb(color: string): string {
  const { type, values } = decomposeColor(color)

  const h = values[0]
  const s = values[1] / 100
  const l = values[2] / 100

  const a = s * Math.min(l, 1 - l)
  const f = (n: number, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
  const rgb = [Math.round(f(0) * 255), Math.round(f(8) * 255), Math.round(f(4) * 255)]

  return recomposeColor(
    type === 'hsla'
      ? {
          type: 'rgba',
          values: [...rgb, values[3]],
        }
      : {
          type: 'rgb',
          values: rgb,
        },
  )
}

/**
 * Returns an object with the type and values of a color.
 *
 * Note: Does not support rgb % values.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @returns {object} - A MUI color object: {type: string, values: number[]}
 */
function decomposeColor(color: string | DecomposedColor): DecomposedColor {
  if (isString(color)) {
    if (color.charAt(0) === '#') {
      return decomposeColor(hexToRgb(color))
    }

    const marker = color.indexOf('(')
    const type = color.substring(0, marker) as DecomposedColor['type']

    const values = color
      .substring(marker + 1, color.length - 1)
      .split(',')
      .map(value => parseFloat(value))

    return { type, values }
  }

  // Idempotent
  return color
}

/**
 * Converts a color object with type and values to a string.
 *
 * @param {object} color - Decomposed color
 * @param {string} color.type - One of: 'rgb', 'rgba', 'hsl', 'hsla'
 * @param {array} color.values - [n,n,n] or [n,n,n,n]
 * @returns {string} A CSS color string
 */
function recomposeColor({ type, values }: DecomposedColor): string {
  let vals: (string | number)[] = [...values]

  if (type.indexOf('rgb') !== -1) {
    // Only convert the first 3 values to int (i.e. not alpha)
    vals = vals.map((n, i) => (i < 3 ? parseInt(String(n), 10) : n))
  } else if (type.indexOf('hsl') !== -1) {
    vals[1] = `${vals[1]}%`
    vals[2] = `${vals[2]}%`
  }

  return `${type}(${vals.join(', ')})`
}

/**
 * The relative brightness of any point in a color space,
 * normalized to 0 for darkest black and 1 for lightest white.
 *
 * Formula: https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @returns {number} The relative brightness of the color in the range 0 - 1
 */
function getLuminance(color: string): number {
  const { type, values } = decomposeColor(color)

  const rgbValues = type === 'hsl' || type === 'hsla' ? decomposeColor(hslToRgb(color)).values : values

  const [r, g, b] = rgbValues.map(val => {
    const n = val / 255 // normalized
    return n <= 0.03928 ? n / 12.92 : ((n + 0.055) / 1.055) ** 2.4
  })

  // Truncate at 3 digits
  return Number((0.2126 * r + 0.7152 * g + 0.0722 * b).toFixed(3))
}

/**
 * Calculates the contrast ratio between two colors.
 *
 * Formula: https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
 *
 * @param {string} foreground - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @param {string} background - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @returns {number} A contrast ratio value in the range 0 - 21.
 */
function getContrastRatio(foreground: string, background: string): number {
  const lumA = getLuminance(foreground)
  const lumB = getLuminance(background)
  return (Math.max(lumA, lumB) + 0.05) / (Math.min(lumA, lumB) + 0.05)
}

/**
 * Checks if a color is dark based on its luminance.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @returns {boolean} True if the color is dark, false if the color is light.
 */
export function isDark(color: string): boolean {
  // Get the luminance of the color
  const luminance = getLuminance(color)

  // Return true if the luminance is less than 0.5, false otherwise
  return luminance < 0.85
}

/**
 * This function returns the most appropriate contrast color for the provided background color.
 * It takes into consideration whether the background color is light or dark.
 * It uses a contrast ratio threshold to decide whether to return a dark or light contrast color.
 * If the background color is dark, it prefers to return a light color and vice versa.
 *
 * @param {string} background - The background color in CSS format (hex, rgb, rgba, hsl, hsla).
 * @param {string} [defaultDark=Colors.DEFAULT_DARK] - The default dark color to return if no dark color is provided.
 * @param {string} [defaultLight=Colors.DEFAULT_WHITE] - The default light color to return if no light color is provided.
 *
 * @returns {string} The most appropriate contrast color for the provided background color.
 */
export function getContrastColor(
  background: string,
  defaultDark: string = Colors.DEFAULT_DARK,
  defaultLight: string = Colors.DEFAULT_WHITE,
): string {
  const contrastThreshold = 7 // Contrast ratio threshold

  // Return the default dark color if the background passed in isn't a valid hex color
  if (!isValidHexColor(background)) return defaultDark

  // Determine if the background color is dark
  const isBackgroundDark = isDark(background)

  // Determine the contrast color based on whether the background color is dark or light
  let contrastColor = isBackgroundDark ? defaultLight : defaultDark

  // Calculate the contrast ratio of the background color with the selected contrast color
  const contrastRatio = getContrastRatio(background, contrastColor)

  // If the contrast ratio is below the threshold, swap the contrast color
  if (contrastRatio < contrastThreshold) {
    contrastColor = isBackgroundDark ? defaultDark : defaultLight
  }

  return contrastColor
}

// This function prevents breaks if the default colors are changed - don't want the #abc123 colors directly in HTML data elements
export function getContrastColorName(background: string) {
  return getContrastColor(background) === Colors.DEFAULT_DARK ? 'dark' : 'light'
}
