import { RoutesManager } from 'utils/routing/routesManager'
import { DataRiskDetailMenu } from '../../components/layout/DataRiskDetailMenu'
import { ThirdPartyRisk } from '../../__mocks__/thirdPartyRisk'
import { useStyles } from '../../components/layout/styles'

type Props = {
  rows: ThirdPartyRisk[]
}

export const UnknownTransfersMenu: React.FC<Props> = ({ rows }) => {
  const classes = useStyles()
  return (
    <DataRiskDetailMenu
      route={RoutesManager.insights.dataRisks.thirdPartyRisk.unknownTransfers.root}
      items={rows.map(row => ({
        id: row.id || '',
        text: row.name || '',
        score: row.riskScore || 0,
        icon: <img alt={row.name} src={row.logo} className={classes.icon} />,
      }))}
    />
  )
}
