import { Chip, Status, StatusState, StatusVariant } from '@ketch-com/deck'
import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { ExperienceViewContext } from '../context/ExperienceViewContext'
import { ExperienceTypeLabels } from '../../upsert/utils/labels'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { ExperienceStatus } from 'interfaces/experiences-v2/experienceStatus'

export const ExperienceViewDetails: React.FC = () => {
  const { experience } = useContext(ExperienceViewContext)

  // For demos we always show progressive experiences as "Deployed"
  const isDeployed = experience?.status === ExperienceStatus.Deployed || experience?.type === ExperienceType.Progressive

  const statusState = isDeployed ? StatusState.success : StatusState.draft
  const statusLabel = isDeployed ? 'Deployed' : 'Undeployed'

  return experience?.type ? (
    <Box display="flex" gap="8px" alignItems="center">
      <Chip size="small" label={ExperienceTypeLabels[experience.type as ExperienceType]} />
      <Status label={statusLabel} status={statusState} variant={StatusVariant.outlined} icon="Dot" />
    </Box>
  ) : null
}
