import { Box, Input, Typography } from '@mui/material'
import { Button } from '@ketch-com/deck'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { ReactComponent as DocumentIcon } from 'assets/icons/policy-documents.svg'
import { Formik, FormikHelpers, useFormikContext } from 'formik'
import { Label } from 'components/ui-kit/form/common/label/Label'

type FileUpload = { file: File | undefined }
type Props = {
  onCancel: () => void
}

const EmptyState = () => {
  const { setFieldValue, submitForm } = useFormikContext<FileUpload>()
  return (
    <Box>
      <Label id="file">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          py={12}
          borderRadius={3}
          sx={({ palette }) => ({
            border: `2px dashed ${palette.iron.main}`,
            background: palette.bone.main,
            '&:hover': { background: palette.iron.main },
          })}
        >
          <DocumentIcon />
          <Typography variant="h4" mb={1.25} mt={1.5}>
            Drop Your Configuration File Here
          </Typography>
          <Typography variant="smallBody">
            You can drop a file here for quick upload or use Browse button below to locate it in your file manager.
          </Typography>
          <Typography variant="smallLabel" mb={3}>
            Supported file types: CSV
          </Typography>
          <Button color="secondary">Browse...</Button>
        </Box>
      </Label>
      <Input
        id="file"
        name="file"
        type="file"
        sx={{ width: '0.1px', height: '0.1px', opacity: '0', overflow: 'hidden', position: 'absolute', zIndex: '-1' }}
        onChange={(event: any) => {
          setFieldValue('file', event.currentTarget.files[0])
          submitForm()
        }}
      />
    </Box>
  )
}

const SuccessText = ({ error = false }: { error?: boolean }) => {
  return (
    <Box display="flex" gap={1} ml={0.5} mt={2}>
      <Box>
        <InfoIcon style={{ fill: 'currentColor' }} />
      </Box>
      <Box>
        <Typography variant="label" component="div" mb={2}>
          {error
            ? 'Uploaded file has syntax or table name errors.'
            : 'Importing configurations will override previous configurations (12) for matching tables'}
          <br />
          {error
            ? 'Unable to match these configurations (48) with tables in “{Database_Name}”.'
            : 'in Database_Name database for “Delete Data, Customer” event.'}
        </Typography>
        <Typography variant="body" component="div">
          {error ? 'Please resolve those errors and try again.' : 'Please review this action before confirming.'}
        </Typography>
      </Box>
    </Box>
  )
}

export const ImportConfigurationModal: React.FC<Props> = ({ onCancel }) => {
  const Empty = () => {
    const initialValues: FileUpload = { file: undefined }
    const submit = (values: FileUpload, formikHelpers: FormikHelpers<FileUpload>): void | Promise<any> => {
      // TODO:JA Stopping point for now as DSR P3 has been descoped for now
      console.info(values)
    }
    return (
      <Formik initialValues={initialValues} onSubmit={submit}>
        <EmptyState />
      </Formik>
    )
  }

  return (
    <Dialog
      isLoading={false}
      isSaving={false}
      title="Import Configuration for “Delete Data, Customer” Event"
      subtitle="Import a CSV file with tables configuration."
      contentWidth={734}
      submitBtnLabel="Import"
      cancelBtnLabel="Close"
      onSubmitBtnClick={() => {}}
      onCancelBtnClick={onCancel}
      withTitleBorderBottom
    >
      <Empty />
      <SuccessText />
    </Dialog>
  )
}
