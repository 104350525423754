import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { ApiKeyDTO } from 'interfaces/apiKeys'

type Params = APIRequestParams<{
  organizationCode: string
  apiKeyId: string
}>

export const deactivateApiKey = ({ organizationCode, apiKeyId }: Params) =>
  API.post<ApiKeyDTO>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/api-keys/${apiKeyId}/disable`,
    }),
  )
