import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchAppsInstancesPurposes } from 'api/apps/fetchers/fetchAppsInstancesPurposes'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.appsInstancesPurposes,
  queryFn: fetchAppsInstancesPurposes,
  select: res => res?.data?.appsInstancesPurposes || [],
})

type QueryConfig = CustomQueryConfig<typeof useCustomQuery, 'organizationCode'>

export const useAppsInstancesPurposes = (config?: QueryConfig) => {
  const { userData } = useAuth()

  return useCustomQuery({
    ...config,
    params: {
      ...config?.params,
      organizationCode: userData.organizationCode!,
    },
  })
}
