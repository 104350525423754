import { useMemo } from 'react'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { useCheckIsApplicationCodeAvailable } from 'api/applications/mutations/useCheckIsApplicationCodeAvailable'
import { MaybeNull } from 'interfaces'
import { ApplicationFormValues, ApplicationIdentitySpace } from 'interfaces/applications/ApplicationFormValues'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { ApplicationIdentitySpaceFormat } from 'interfaces/applications/ApplicationIdentitySpaceFormat'
import { ApplicationPlatform } from 'interfaces/applications/applicationPlatform'

export enum ApplicationFormStep {
  BASIC_DETAILS = 'Basic Details',
  DOCUMENTS = 'Documents',
  ACCESSIBILITY = 'Accessibility',
  ENVIRONMENTS = 'Environments',
  DATA_LAYER = 'Data Layer',
}

const testThatFieldHasDataOrIsNonMobilePropertyWithCustomIdentitiesOn = function (
  this: any,
  value?: MaybeNull<string | number>,
) {
  const ctx = this as Yup.TestContext & { from: [any, { value: ApplicationFormValues }] }
  const { value: formValues } = ctx.from[1]

  const hasValue = !!value
  const hasCustomIdentitySpaces = !!formValues.customIdentitySpaces
  const isMobileProperty = formValues.platform === ApplicationPlatform.MOBILE

  return hasValue || !hasCustomIdentitySpaces || isMobileProperty
}

const testThatFieldHasDataOrCustomIdentitiesIsOff = function (this: any, value?: MaybeNull<string | number>) {
  const ctx = this as Yup.TestContext & { from: [any, { value: ApplicationFormValues }] }
  const { value: formValues } = ctx.from[1]

  const hasValue = !!value
  const hasCustomIdentitySpaces = !!formValues.customIdentitySpaces

  return hasValue || !hasCustomIdentitySpaces
}

const getIsKeyRequired = function (this: any, value?: MaybeNull<string | number>) {
  const ctx = this as Yup.TestContext & { from: [any, { value: ApplicationFormValues }] }
  const { value: formValues } = ctx.from[0]
  const identitySpaceLineItemValues = { ...formValues } as ApplicationIdentitySpace
  if (
    Number(identitySpaceLineItemValues.format) &&
    identitySpaceLineItemValues.format === ApplicationIdentitySpaceFormat.STRING
  ) {
    return true
  }

  const isValid = identitySpaceLineItemValues.format !== ApplicationIdentitySpaceFormat.STRING && !!value

  return isValid
}

export const useApplicationValidationSchema = ({ isEditMode = false }: { isEditMode?: boolean } = {}) => {
  const { mutateAsync: handleCheckIsApplicationCodeAvailable } = useCheckIsApplicationCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsApplicationCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(
    () =>
      Yup.object().shape({
        ...(!isEditMode && {
          code: codeValidationSchema,
        }),
        name: Yup.string().max(75, 'Name must not exceed 75 characters').required('Required'),
        platform: Yup.string().required('Required'),
        environments: Yup.array().when('platform', {
          is: '2',
          then: schema =>
            schema.of(
              Yup.object().shape({
                code: Yup.string().max(50, 'Name must not exceed 50 characters').required('Required'),
                domain: Yup.string(),
                pattern: Yup.string(),
                ...(isRelease(Release.PropertyThemeDocuments) && {
                  themeID: Yup.string().required('Required'),
                }),
              }),
            ),
          otherwise: schema =>
            schema.of(
              Yup.object().shape({
                code: Yup.string().max(50, 'Name must not exceed 50 characters').required('Required'),
                domain: Yup.string(),
                pattern: Yup.string().required('Required'),
                ...(isRelease(Release.PropertyThemeDocuments) && {
                  themeID: Yup.string().required('Required'),
                }),
              }),
            ),
        }),
        identitySpaces: Yup.array().of(
          Yup.object().shape({
            // "Identifier" column
            identitySpaceID: Yup.string().test('', 'Required', testThatFieldHasDataOrCustomIdentitiesIsOff),
            // "Location" column
            type: Yup.mixed().test('', 'Required', testThatFieldHasDataOrIsNonMobilePropertyWithCustomIdentitiesOn),
            // "Name" column
            variable: Yup.string().max(75, 'Name must not exceed 75 characters').required('Required'),
            // "Format" column
            format: Yup.string().required('Required'),
            // "Key" column
            key: Yup.string().max(50, 'Variable must not exceed 50 characters').test('', 'Required', getIsKeyRequired),
          }),
        ),
        dataSubjectTypeCodes: Yup.array().of(Yup.string()).min(1, 'At least one Data Subject is required'),
        defaultPolicyScopeVariable: Yup.string().test('', 'Required', function (value?: MaybeNull<string>) {
          const ctx = this as Yup.TestContext & { from: [{ value: ApplicationFormValues }] }
          const { value: formValues } = ctx.from[0]

          return !formValues.customPolicyScope || !!value
        }),
        proxy: Yup.string()
          .url()
          .when('supportProxy', {
            is: true,
            then: Yup.string().required('Required'),
          }),
        ...(isRelease(Release.PropertyThemeDocuments) && {
          privacyPolicyCode: Yup.string().required('Required'),
        }),
        ...(isRelease(Release.PropertyThemeDocuments) && {
          termsOfServiceCode: Yup.string(),
        }),
      }),
    [isEditMode, codeValidationSchema],
  )
}

export const validateApplicationFormStep = (
  step: ApplicationFormStep,
  formikProps: FormikProps<ApplicationFormValues>,
): boolean => {
  const { errors, setFieldTouched } = formikProps
  let stepFields: (keyof ApplicationFormValues)[] = []
  let isValid = true

  const validate = () => {
    stepFields.forEach(stepField => {
      if (!!errors[stepField]) {
        if (Array.isArray(errors[stepField])) {
          const arrayError = errors[stepField] as
            | ApplicationFormValues['environments']
            | ApplicationFormValues['identitySpaces']
          arrayError.forEach((errorField, index) => {
            Object.keys(errorField).forEach(key => {
              setFieldTouched(`${stepField}[${index}].${key}`)
            })
          })
          isValid = false
          return
        }
        setFieldTouched(stepField)
        isValid = false
      }
    })
  }

  switch (step) {
    case ApplicationFormStep.BASIC_DETAILS: {
      stepFields = ['name', 'code', 'dataSubjectTypeCodes']
      break
    }
    case ApplicationFormStep.DOCUMENTS: {
      stepFields = ['privacyPolicyCode']
      break
    }
    case ApplicationFormStep.ENVIRONMENTS: {
      stepFields = ['environments']
      break
    }
    case ApplicationFormStep.DATA_LAYER: {
      stepFields = ['identitySpaces', 'proxy', 'defaultPolicyScopeVariable']
      break
    }
  }

  validate()

  return isValid
}
