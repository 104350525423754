import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { RoutesManager } from 'utils/routing/routesManager'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { DeleteCookieModal } from 'components/modals/cookies/DeleteCookieModal'
import { getBodyByEntityType } from 'components/modals/common/EditConfirmationModal'
import { Button, PopUp, Icon, ActionSheetWrapper, ActionSheetItem } from '@ketch-com/deck'
import { Typography } from '@mui/material'
import { getApplicationEntityName } from 'utils/messages/entityName'

type Props = {
  cookie: CookieDTO
}

export const CookiesListDropdown: React.FC<Props> = ({ cookie }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const { code, purposes } = cookie
  const isInSensitiveStatus = !isEmpty(purposes)
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const reFetchCookies = () => queryClient.refetchQueries(ApiQueryKeys.cookiesPaginated)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const isDeleteMenuItemHidden = isInSensitiveStatus || !isPermitted(PERMISSIONS.PURPOSE_WRITE)
  const isEditMenuItemHidden = !isPermitted(PERMISSIONS.PURPOSE_WRITE)

  return (
    <>
      <Button size="medium" variant="iconSubtle" onClick={handleClickListItem}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
        {!isEditMenuItemHidden && (
          <ActionSheetItem
            onClick={() => {
              isInSensitiveStatus
                ? setIsEditOpen(true)
                : navigate(RoutesManager.policyCenter.cookies.upsert.root.getURL({ code }))
              handleClose()
            }}
          >
            Edit
          </ActionSheetItem>
        )}
        {!isEditMenuItemHidden && !isDeleteMenuItemHidden && <ActionSheetItem divider />}
        {!isDeleteMenuItemHidden && (
          <ActionSheetItem
            onClick={() => {
              setIsDeleteOpen(true)
              handleClose()
            }}
          >
            Delete
          </ActionSheetItem>
        )}
      </ActionSheetWrapper>

      <DeleteCookieModal
        isOpen={isDeleteOpen}
        code={code}
        onSubmit={() => {
          setIsDeleteOpen(false)

          return reFetchCookies()
        }}
        onCancel={() => setIsDeleteOpen(false)}
      />

      <PopUp
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        popUpActionChildren={
          <>
            <Button color="secondary" size="large" onClick={() => setIsEditOpen(false)}>
              Close
            </Button>
            <Button
              color="primary"
              size="large"
              onClick={() => {
                navigate(RoutesManager.policyCenter.cookies.upsert.root.getURL({ code }))
              }}
            >
              Edit
            </Button>
          </>
        }
        variant="dialog"
        title={`Edit ${getApplicationEntityName({ entityType: ApplicationEntity.COOKIE }).singular}`}
      >
        <Typography>{getBodyByEntityType({ entityType: ApplicationEntity.COOKIE })}</Typography>
      </PopUp>
    </>
  )
}
