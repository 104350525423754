import { DeploymentPlanDTO, UpsertDeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  deploymentPlanCode: string
  formData: UpsertDeploymentPlanDTO
}

type Response = { deployment: DeploymentPlanDTO }

export const updateDeploymentPlan = ({ organizationCode, deploymentPlanCode, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/deployments/${deploymentPlanCode}`,
    }),
    formData,
  )
