import { PutAssetV3ConfirmationStatusRequestBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

interface UpdateAssignedDataCategoriesConfirmationStatusParams {
  installedDataSystemId: string
  classificationData: PutAssetV3ConfirmationStatusRequestBodyDTO
}

export const updateAssignedDataCategoriesConfirmationStatus = ({
  installedDataSystemId,
  classificationData,
}: UpdateAssignedDataCategoriesConfirmationStatusParams) => {
  return API.put<PutAssetV3ConfirmationStatusRequestBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v3/assets/${installedDataSystemId}/confirmation-status`,
    }),
    classificationData,
  )
}
