import React from 'react'

import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { AttributeTableActionCellRenderer } from '.'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { Badge } from 'components/ui-kit/badge/Badge'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { Pagination } from 'api/common/paginatedQuery'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { DataCategoryCellRenderer } from 'pages/assetManager/components'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useAppSelector } from 'store/hooks'
import { getAttributeUpdateErrors } from 'store/dsrTransponderFormSlice/selectors'
import { useAppDispatch } from 'store/hooks'
import { clearAttributeUpdateErrors } from 'store/dsrTransponderFormSlice'
import { NameAndCodeAndMaxLengthCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeAndMaxLengthCellRenderer'
import { Box } from '@mui/material'
import { AssetDTO } from '@ketch-com/figurehead'
import { useFormikContext } from 'formik'
import { IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { WORKFLOW_TYPE_ENUM } from '../constants'

interface Props {
  pagination: Pagination
  setConfigureDatasetModalCode: (code: string | undefined) => void
  activeDatabase: string
  relatedAttributes: AssetDTO[]
  selectedDatasetObject: AssetDTO | undefined
  isLoadingRelatedAttributes: boolean
  configureDatasetModalCode: string
  setAttributesSearchString: React.Dispatch<React.SetStateAction<string>>
  attributesSearchString: string
}

export const ConfigureDatasetAttributeActionsModal: React.FC<Props> = ({
  attributesSearchString,
  selectedDatasetObject,
  isLoadingRelatedAttributes,
  setConfigureDatasetModalCode,
  relatedAttributes,
  configureDatasetModalCode,
  activeDatabase,
  pagination,
  setAttributesSearchString,
}) => {
  const dispatch = useAppDispatch()

  const { values: formikValues } = useFormikContext<IWorkflowConfig>()
  const isAccessFlow = WORKFLOW_TYPE_ENUM.GET === formikValues?.canonicalRightCode

  const attributeUpdateErrors = useAppSelector(getAttributeUpdateErrors)
  const isValid = !Object.values(attributeUpdateErrors)
    .map(m => m.isValid)
    .some(m => m === false)

  return (
    <Dialog
      isLoading={isLoadingRelatedAttributes}
      isSaving={false}
      title={selectedDatasetObject?.resource?.name || ''}
      subtitle="Customize attribute actions."
      onCancelBtnClick={() => {
        if (isValid) {
          setConfigureDatasetModalCode(undefined)
          dispatch(clearAttributeUpdateErrors())
          setAttributesSearchString('')
        } else {
          showToast({
            content: 'Please complete required fields or select "No Action"',
            type: 'error',
          })
        }
      }}
      contentWidth={780}
      submitBtnLabel="Close"
      isValid={isValid}
      onSubmitBtnClick={() => {
        setConfigureDatasetModalCode(undefined)
        dispatch(clearAttributeUpdateErrors())
        setAttributesSearchString('')
      }}
      withTitleBorderBottom={true}
      shouldHideCancelButton
    >
      <Box display="flex" alignItems="center" pb={3.5}>
        <SearchInput
          size="small"
          disabled={!isValid}
          hint={!isValid ? 'Please fix errors' : undefined}
          onChange={nextSearchString => setAttributesSearchString(nextSearchString)}
          onClear={() => setAttributesSearchString('')}
          value={attributesSearchString}
        />
      </Box>

      <TablePaginated
        pagination={pagination}
        isSmallHeaderText
        variant="page"
        items={relatedAttributes || []}
        cellSettings={{
          name: {
            width: 260,
            label: 'Attribute Name',
            cellRenderer: asset => (
              <NameAndCodeAndMaxLengthCellRenderer
                name={asset?.resource?.name}
                code={asset?.resource?.dataType?.name || ''}
                maxLength={
                  ((asset?.resource?.dataType?.facets as unknown as { [key: string]: any }[]) || []).find(
                    (facet: any) => facet.name === 'maxLength',
                  )?.value?.value?.integer || null
                }
              />
            ),
          },
          include: {
            width: 150,
            label: isAccessFlow ? 'Include' : 'Actions',
            cellRenderer: asset => (
              <AttributeTableActionCellRenderer
                activeDatabase={activeDatabase}
                attributeCode={asset?.resource?.id || ''}
                attribute={asset}
                datasetCode={configureDatasetModalCode}
              />
            ),
          },
          dataCategory: {
            label: 'Data Category',
            width: 150,
            cellRenderer: asset => <DataCategoryCellRenderer dataCategory={asset?.dataCategory?.[0]?.value} />,
          },
          labels: {
            label: 'Classifications',
            cellRenderer: asset => {
              const assignedLabels = asset?.assignedLabels || []
              const inferredLabels = asset?.inferredLabels || []
              const collectedLabels = [...assignedLabels, ...inferredLabels]
              return (
                <Badge>
                  <TextCellRenderer value={collectedLabels?.length || 0} />
                </Badge>
              )
            },
          },
        }}
      />
    </Dialog>
  )
}
