import { MaybeNull } from 'interfaces/common'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans/deploymentPlan'
import { ApplicationDTO } from 'interfaces/applications/application'
import { ObjectLiteral } from 'interfaces'

export type DeploymentPlanDeployFormValues = {
  [applicationId: string]: {
    opened: boolean
    environments: {
      [environmentId: string]: boolean
    }
  }
}

export const getInitialValues = ({
  deploymentPlan,
  applications,
}: {
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
  applications: ApplicationDTO[]
}) => {
  const deploymentPlanId = deploymentPlan?.ID

  return applications.reduce<DeploymentPlanDeployFormValues>(
    (acc, { ID: applicationId, environments: applicationEvironments }) => {
      const environments = applicationEvironments.reduce<ObjectLiteral<boolean>>(
        (res, { ID: environmentId, deploymentID }) => ({
          ...res,
          [environmentId]: deploymentID?.value === deploymentPlanId,
        }),
        {},
      )

      return {
        ...acc,
        [applicationId]: {
          opened: Object.values(environments).some(Boolean),
          environments,
        },
      }
    },
    {},
  )
}

export const mapValuesToPayload = (values: DeploymentPlanDeployFormValues) => ({
  applicationEnvironmentIDs: Object.values(values).flatMap(({ environments }) =>
    Object.entries(environments)
      .filter(([_, checked]) => checked)
      .map(([environmentId]) => environmentId),
  ),
})

export const isDeploymentOverwritten = ({
  values,
  deploymentPlan,
}: {
  values: DeploymentPlanDeployFormValues
  deploymentPlan: DeploymentPlanDTO
}) => !!deploymentPlan.applicationEnvironments?.some(({ ID, applicationID }) => !values[applicationID].environments[ID])

export const getSelectionMetadata = (values: DeploymentPlanDeployFormValues) => {
  const flatValues = Object.values(values)
    .map(({ environments }) => Object.values(environments))
    .flat(Infinity)

  const selectedCount = flatValues.filter(Boolean).length
  const totalCount = flatValues.length

  const value =
    selectedCount > 0 && selectedCount < totalCount ? ('indeterminate' as const) : selectedCount === totalCount

  return {
    selectedCount,
    totalCount,
    value,
  }
}
