export enum ApplicationEnvironment {
  PRODUCTION = 'production',
  STAGING = 'staging',
}

export const APPLICATION_ENVIRONMENT_NAMES = {
  [ApplicationEnvironment.PRODUCTION]: 'Production',
  [ApplicationEnvironment.STAGING]: 'Staging',
}

export const APPLICATION_ENVIRONMENT_OPTIONS = [
  {
    id: ApplicationEnvironment.PRODUCTION,
    name: APPLICATION_ENVIRONMENT_NAMES[ApplicationEnvironment.PRODUCTION],
  },
  {
    id: ApplicationEnvironment.STAGING,
    name: APPLICATION_ENVIRONMENT_NAMES[ApplicationEnvironment.STAGING],
  },
]
