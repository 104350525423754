import { APIListRequestParams, formatOrdering, Ordering } from 'api/common/utils'
import { FetchNotificationsResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
  ordering?: Ordering<'status' | 'created_at'>
  start?: number
  hasTaskId?: boolean
  hasNoTaskId?: boolean
}>

export const fetchNotifications = ({ includeMetadata, ordering, start, limit, hasNoTaskId, hasTaskId }: Params) => {
  return API.get<FetchNotificationsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/notifications`,
      params: {
        filters: {
          start,
          limit,
          ordering: formatOrdering(ordering),
          ...(hasTaskId && { hasTaskId }),
          ...(hasNoTaskId && { hasNoTaskId }),
        },
      },
    }),
  )
}
