import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { Text } from 'components/ui-kit/typography/Text'
import { Box } from '@mui/material'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { DeletePurposeStacksModal } from 'components/modals/stacks/DeletePurposeStacksModal'

import { formatLastUpdatedDate } from 'utils/helpers/dateTime'
import { RoutesManager } from 'utils/routing/routesManager'

// types
import { StackDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'
type Props = {
  purposeStack?: MaybeNull<StackDTO>
}

export const PurposeStackActions: React.FC<Props> = ({ purposeStack }) => {
  const [isDeletePurposeStacksModalOpen, setIsDeletePurposeStacksModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" height={40} mr={1}>
          <Text weight={500} color="grey">
            Last Updated&nbsp;
          </Text>
          <Text weight={500}>{formatLastUpdatedDate(purposeStack?.metadata?.updatedAt)}</Text>
        </Box>
        <ButtonOptions
          id={`policyTableItemActionButtonId`}
          size="regular"
          items={[
            {
              content: 'Edit',
              onClick: () => {
                navigate(RoutesManager.deployment.purposeStacks.upsert.root.getURL({ code: purposeStack?.id }))
              },
            },
            {
              content: 'Delete',
              onClick: () => setIsDeletePurposeStacksModalOpen(true),
            },
          ]}
        />
      </Box>
      {isDeletePurposeStacksModalOpen && (
        <DeletePurposeStacksModal
          purposeStack={purposeStack}
          onCancel={() => setIsDeletePurposeStacksModalOpen(false)}
          onSubmit={async () => {
            navigate(RoutesManager.deployment.purposeStacks.root.getURL())
            setIsDeletePurposeStacksModalOpen(false)
          }}
        />
      )}
    </>
  )
}
