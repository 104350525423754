import { Box, Typography } from '@mui/material'
import { Free } from 'components/planAndBilling/components/planDetails/Free'
import { Starter } from 'components/planAndBilling/components/planDetails/Starter'
import { Plus } from 'components/planAndBilling/components/planDetails/Plus'
import { Pro } from 'components/planAndBilling/components/planDetails/Pro'
import { theme } from '@ketch-com/deck'

export const PlanDetails = () => {
  return (
    <Box
      sx={{
        borderTop: `1px solid ${theme.palette.iron.main}`,
        paddingTop: 6,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        sx={{ background: 'linear-gradient(to right, #C9B1FB, #6622F4)' }}
      >
        <Box
          width={0}
          height={0}
          borderTop="16px solid transparent"
          borderLeft={`16px solid ${theme.palette.white.main}`}
          borderBottom="16px solid transparent"
        ></Box>
        <Typography variant="label" color={theme.palette.white.main}>
          Early Stage
        </Typography>
        <Typography variant="label" color={theme.palette.white.main}>
          Growth
        </Typography>
        <Typography variant="label" color={theme.palette.white.main}>
          Enterprise
        </Typography>
        <Box
          width={0}
          height={0}
          borderTop={`16px solid ${theme.palette.white.main}`}
          borderLeft="16px solid #6622F4"
          borderBottom={`16px solid ${theme.palette.white.main}`}
        ></Box>
      </Box>
      <Box display="flex" gap={2}>
        <Free />
        <Starter />
        <Plus />
        <Pro />
      </Box>
    </Box>
  )
}
