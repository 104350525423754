import { useNavigate, useParams } from 'react-router-dom'
import { UrlParams } from './interfaces'
import { FormMode } from 'interfaces/formModes/formMode'
import { usePrivacyProtocol } from 'api/privacyProtocols/queries/usePrivacyProtocol'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { usePurposesV2 } from 'api/purposesV2/queries/usePurposesV2'
import { useJurisdictionsV2 } from 'api/jurisdictionsV2/queries/useJurisdictionsV2'
import { useGlobalVendorList } from 'api/globalVendorList/queries/useGlobalVendorList'
import { useGoogleVendorList } from 'api/googleVendorList/queries/useGoogleVendorList'
import { getInitialValues, usePrivacyProtocolsValidationSchema } from './formValues'
import {
  googleACProtocols,
  gvlProtocols,
  gvlRegion,
  legacyProtocols,
  protocolId,
} from 'pages/consentAndRights/consent/privacyProtocols/constants'
import { useUpdatePrivacyProtocolConfig } from 'api/privacyProtocols/mutations/useUpdatePrivacyProtocolConfig'
import { RoutesManager } from 'utils/routing/routesManager'
import {
  PrivacyProtocolConfigurationPutRequestBody,
  PutPrivacyProtocolConfigBody,
} from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'

export const usePrivacyProtocolUpsertUtils = () => {
  const navigate = useNavigate()

  // Page details
  const { id, formMode } = useParams<UrlParams>()
  const isEditMode = formMode === FormMode.EDIT
  const region = gvlProtocols.includes(id || '')
    ? id === protocolId.tcfca
      ? gvlRegion.canada
      : gvlRegion.europe
    : gvlRegion.europe

  // Fetch data
  const {
    data: protocol,
    isLoading: isProtocolLoading,
    isFetching: isProtocolFetching,
  } = usePrivacyProtocol({
    params: {
      protocolId: id,
    },
    onError: () => {
      showToast({ content: `Failed to fetch protocol`, type: 'error' })
    },
  })
  const {
    data: purposes,
    isLoading: isPurposesLoading,
    isFetching: isPurposesFetching,
  } = usePurposesV2({
    onError: () => {
      showToast({ content: `Failed to fetch purposes`, type: 'error' })
    },
  })
  const {
    data: jurisdictions,
    isLoading: isJurisdictionsLoading,
    isFetching: isJurisdictionsFetching,
  } = useJurisdictionsV2({
    params: {
      include: 'protocols',
    },
    onError: () => {
      showToast({ content: `Failed to fetch jurisdictions`, type: 'error' })
    },
  })
  const {
    data: globalVendorList,
    isLoading: isGlobalVendorListLoading,
    isFetching: isGlobalVendorListFetching,
  } = useGlobalVendorList({
    enabled: gvlProtocols.includes(id || ''),
    params: { region },
    onError: () => {
      showToast({ content: `Failed to fetch global vendor list`, type: 'error' })
    },
  })
  const {
    data: googleVendorList,
    isLoading: isGoogleVendorListLoading,
    isFetching: isGoogleVendorListFetching,
  } = useGoogleVendorList({
    enabled: googleACProtocols.includes(id || ''),
    params: { region },
    onError: () => {
      showToast({ content: `Failed to fetch Google additional consents vendor list`, type: 'error' })
    },
  })

  const isReady =
    !(isProtocolLoading || isProtocolFetching) &&
    !(isPurposesLoading || isPurposesFetching) &&
    !(isJurisdictionsLoading || isJurisdictionsFetching) &&
    !(isGlobalVendorListLoading || isGlobalVendorListFetching) &&
    !(isGoogleVendorListLoading || isGoogleVendorListFetching)

  // Form config
  const validationSchema = usePrivacyProtocolsValidationSchema(
    protocol.supportsJurisdictionSelection,
    protocol.supportsPurposeMapping,
    protocol.supportsVendorSelection,
    protocol.supportsOtherOptions,
  )
  const initialValues = getInitialValues(protocol)
  const allowSelectAllJurisdictions = legacyProtocols.includes(protocol.id)

  // Submit
  const { mutateAsync: updatePrivacyProtocolConfig } = useUpdatePrivacyProtocolConfig({
    onError: () => {
      showToast({ content: `Failed to ${isEditMode ? 'update' : 'create'} protocol`, type: 'error' })
    },
    onSuccess: () => {
      showToast({ content: `${isEditMode ? 'Updated' : 'Created'} protocol`, type: 'success' })
    },
  })
  const onSubmit = async (formData: PrivacyProtocolConfigurationPutRequestBody) => {
    const body: PutPrivacyProtocolConfigBody = {
      privacyProtocolConfig: formData,
    }

    await updatePrivacyProtocolConfig({
      params: { id: protocol?.id, formData: body },
    })

    // Navigate to view screen for updated protocol
    navigate(RoutesManager.orchestration.consent.privacyProtocols.view.root.getURL({ id: protocol?.id }))
  }

  return {
    navigate,
    protocol,
    purposes,
    jurisdictions,
    globalVendorList,
    googleVendorList,
    validationSchema,
    initialValues,
    updatePrivacyProtocolConfig,
    onSubmit,
    allowSelectAllJurisdictions,
    isReady,
    isEditMode,
  }
}

export type PrivacyProtocolUpsertUtils = ReturnType<typeof usePrivacyProtocolUpsertUtils>
