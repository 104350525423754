import React from 'react'
import { createTeleporter } from 'react-teleporter'

export const CreateLayoutActionsPortal = createTeleporter()

type Props = {
  children?: React.ReactNode
}
export const CreateLayoutActions: React.FC<Props> = ({ children }) => (
  <CreateLayoutActionsPortal.Source>{children}</CreateLayoutActionsPortal.Source>
)
