import { ViewLayout } from '@ketch-com/deck'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { EditCustomFieldsSection } from './customFieldsSection/components/EditCustomFieldsSection'

export const DataSystemsSettings: React.FC = () => {
  const navigate = useNavigate()

  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.systems.root.getURL() },
    { title: 'Systems', link: RoutesManager.systems.systems.root.getURL() },
    { title: 'Settings' },
  ]

  const handleExitSettings = () => {
    navigate(RoutesManager.systems.systems.root.getURL())
  }

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ViewLayout
        contentGroupProps={{
          title: 'Data Systems Settings',
          showBackButton: true,
          propsBackButton: {
            onClick: handleExitSettings,
          },
        }}
      >
        <EditCustomFieldsSection handleExitSettings={handleExitSettings} />
      </ViewLayout>
    </>
  )
}
