import { Box, styled } from '@mui/material'
import { SelectableSection } from '../SelectableSection'
import { ProgressiveExperienceBuilderSection } from '../../utils/enums'
import { ProgressiveHeader } from './components/ProgressiveHeader'
import { ProgressiveDescription } from './components/ProgressiveDescription'
import { ProgressiveActionButtons } from './components/ProgressiveActionButtons'
import { ProgressiveSwitchButton } from './components/ProgressiveSwitchButton'
import { useContext } from 'react'
import { BuilderContext } from '../../Builder'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { ProgressiveExperienceConsentLayout, ProgressiveExperienceType } from '@ketch-sdk/ketch-types'
import { getIn } from 'formik'
import { ProgressiveSubscriptions } from './components/ProgressiveSubscriptions'

const ProgressiveBuilderBox = styled(Box)({
  width: '484px',
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
})

const ProgressiveBuilderActionButtonsLayoutBox = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
})

const ProgressiveBuilderSwitchButtonLayoutBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

export const ProgressiveBuilder: React.FC = () => {
  const { experienceConfig } = useContext(BuilderContext)

  const type = getIn(experienceConfig, experienceUpsertFormKeys.progressiveExperienceType) as ProgressiveExperienceType
  const consentLayout = getIn(
    experienceConfig,
    experienceUpsertFormKeys.progressiveExperienceConsentLayout,
  ) as ProgressiveExperienceConsentLayout

  const isSwitchButtonLayout =
    type === ProgressiveExperienceType.Consent && consentLayout === ProgressiveExperienceConsentLayout.ToggleSwitch

  return (
    <SelectableSection section={ProgressiveExperienceBuilderSection.Container} overlayProps={{ margin: '18px' }}>
      <ProgressiveBuilderBox>
        {isSwitchButtonLayout ? (
          <ProgressiveBuilderSwitchButtonLayoutBox>
            <Box>
              <SelectableSection
                section={ProgressiveExperienceBuilderSection.Header}
                overlayProps={{ margin: '18px 20px 10px 18px' }}
                sx={{ width: 'fit-content' }}
              >
                <ProgressiveHeader />
              </SelectableSection>
              <SelectableSection
                section={ProgressiveExperienceBuilderSection.Description}
                overlayProps={{ margin: '12px 22px 18px 18px' }}
                sx={{ width: 'fit-content' }}
              >
                <ProgressiveDescription />
              </SelectableSection>
            </Box>
            <SelectableSection
              section={ProgressiveExperienceBuilderSection.SwitchButton}
              overlayProps={{ margin: '4px 18px 4px 4px' }}
            >
              <ProgressiveSwitchButton />
            </SelectableSection>
          </ProgressiveBuilderSwitchButtonLayoutBox>
        ) : (
          <ProgressiveBuilderActionButtonsLayoutBox>
            <SelectableSection
              section={ProgressiveExperienceBuilderSection.Header}
              overlayProps={{ margin: '18px 18px 10px 18px' }}
              sx={{ width: 'fit-content' }}
            >
              <ProgressiveHeader />
            </SelectableSection>
            <SelectableSection
              section={ProgressiveExperienceBuilderSection.Description}
              overlayProps={{ margin: '12px 18px 12px 18px' }}
            >
              <ProgressiveDescription />
            </SelectableSection>
            {type === ProgressiveExperienceType.Subscription && (
              <SelectableSection
                section={ProgressiveExperienceBuilderSection.Subscriptions}
                overlayProps={{ margin: '10px 18px 12px 18px' }}
              >
                <ProgressiveSubscriptions />
              </SelectableSection>
            )}
            <SelectableSection
              section={ProgressiveExperienceBuilderSection.ActionButtons}
              overlayProps={{ margin: '10px 16px 18px 18px' }}
              sx={{ width: 'fit-content' }}
            >
              <ProgressiveActionButtons />
            </SelectableSection>
          </ProgressiveBuilderActionButtonsLayoutBox>
        )}
      </ProgressiveBuilderBox>
    </SelectableSection>
  )
}
