import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { WebTagIssue } from 'interfaces/webtags/WebTags'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
  appCode?: string[]
  appInstanceId?: string
}>

export type TagIssuesResponse = APIListResponse<{
  appInstanceIssues: WebTagIssue[]
}>

export const fetchWebTagIssues = ({
  organizationCode,
  includeMetadata,
  start,
  limit,
  appCode,
  appInstanceId,
}: Params) =>
  API.get<TagIssuesResponse>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/tags/issues`,
      params: {
        filters: {
          includeMetadata,
          start,
          limit,
          ...(appCode && {
            appCode,
          }),
          ...(appInstanceId && {
            appInstanceID: appInstanceId,
          }),
        },
      },
    }),
  )
