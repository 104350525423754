import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchInstalledDataSystem } from '../fetchers/fetchInstalledDataSystem'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.installedDataSystem,
  queryFn: fetchInstalledDataSystem,
  select: res => res?.data.installedDataSystem || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useInstalledDataSystem = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
