import { Banner, Button, ContentGroup, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { Divider } from '@mui/material'
import { TransponderCreateFormSection as FormSection } from './TranspondersCreateFormSection'
import { TransponderCreateFormInstructions as Instructions } from './TranspondersCreateInstructions'
import { Form, FormikProvider, useFormik } from 'formik'
import { useTransponderCreate } from '../../context'
import { useTransponderCreateValidationSchema } from '../../hooks/useValidationSchema'
import { showToast } from 'components/modals/AlertComponent'
import { useCreateTransponder } from 'api/assets/mutations/useCreateTransponder'
import { TransponderDocumentationSlugs } from 'pages/developers/transponders/hooks/useTransponderDocumentation'

export const TransponderCreateForm: React.FC = () => {
  const { code, handleExitFlow, handleVisitDocs, setCode, setStatus, setToken, setApplianceId } = useTransponderCreate()
  const { mutateAsync: handleCreateTransponder } = useCreateTransponder({
    onSuccess: ({ data }) => {
      showToast({ content: 'Transponder created', type: 'success' })
      setToken(data.token)
      setCode(data.name)
      setApplianceId(data.applianceId)
      setStatus('success')
    },
    onError: () => {
      showToast({ content: 'Failed to create transponder', type: 'error' })
      setStatus('error')
    },
  })
  const validateSchema = useTransponderCreateValidationSchema(code)

  const onSubmit = async (values: { name: string }) => {
    await handleCreateTransponder({ params: { name: values.name } })
  }

  const formikProps = useFormik({
    validateOnMount: false,
    enableReinitialize: true,
    initialValues: { name: '' },
    validationSchema: validateSchema,
    onSubmit: onSubmit,
  })

  const { isSubmitting, submitForm } = formikProps

  return (
    <FormikProvider value={formikProps}>
      <UpsertLayout
        mode={UpsertLayoutMode.create}
        renderFormTitle={() => <ContentGroup title="Create Transponder" titleVariant="h1" isDivider />}
        onAccept={submitForm}
        acceptActionButtonText="Done"
        acceptButtonProps={{
          pending: isSubmitting,
        }}
        onThirdActionButtonHandler={() => {
          handleVisitDocs()
        }}
        thirdActionButtonText="Read Documentation"
        thirdButtonProps={{
          pending: isSubmitting,
        }}
        onCancel={handleExitFlow}
        cancelButtonProps={{
          pending: isSubmitting,
        }}
      >
        <Form>
          <FormSection />
          <Divider />
          <Banner
            severity="info"
            sx={{ marginBlock: 4 }}
            action={
              <Button
                variant="link"
                sx={{ whiteSpace: 'nowrap' }}
                onClick={() => handleVisitDocs(TransponderDocumentationSlugs.deployingTransponders)}
              >
                Read Documentation
              </Button>
            }
          >
            If you encounter an error or experience difficulty connecting to a transponder, please consult the
            troubleshooting documentation for assistance.
          </Banner>
          <Instructions />
        </Form>
      </UpsertLayout>
    </FormikProvider>
  )
}
