import { Box, styled } from '@mui/material'
import { getIn } from 'formik'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { useContext } from 'react'
import { BuilderCheckbox, CheckboxVariant } from '../../BuilderCheckbox'

const sampleCheckboxData = ['Email', 'SMS', 'Mail', 'Phone']

const CheckboxesBox = styled(Box)({
  display: 'flex',
  gap: '40px',
  padding: '8px 16px',
})

export const ProgressiveSubscriptions: React.FC = () => {
  const { themeConfig } = useContext(BuilderContext)

  // Theme config - Using the preference theme for demo
  const checkboxesOnBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabListCheckboxSelectedBackgroundColor,
  )
  const checkboxesOnTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabListCheckboxSelectedTextColor,
  )
  const checkboxesOffBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabListCheckboxUnselectedBackgroundColor,
  )
  const checkboxesOffTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabListCheckboxUnselectedTextColor,
  )

  return (
    <CheckboxesBox>
      {sampleCheckboxData.map((label, index) => (
        <BuilderCheckbox
          variant={index === 1 ? CheckboxVariant.off : CheckboxVariant.on}
          onBackgroundColor={checkboxesOnBackgroundColor}
          onTextColor={checkboxesOnTextColor}
          offBackgroundColor={checkboxesOffBackgroundColor}
          offTextColor={checkboxesOffTextColor}
          label={label}
        />
      ))}
    </CheckboxesBox>
  )
}
