import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { getIn } from 'formik'
import { BuilderContext } from '../../../Builder'
import { useContext } from 'react'
import { BuilderSwitch } from '../../BuilderSwitch'
import { Box, styled } from '@mui/material'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'

const SwitchBox = styled(Box)({
  paddingRight: '16px',
})

// Theme config - We are using the modal theme for demo
export const ProgressiveSwitchButton: React.FC = () => {
  const { experienceConfig, themeConfig } = useContext(BuilderContext)

  const switchButtonOnBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.modalPurposeListSwitchButtonsOnBackgroundColor,
  )
  const switchButtonOnTextColor = getIn(themeConfig, themeUpsertFormKeys.modalPurposeListSwitchButtonsOnTextColor)
  const switchButtonOffBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.modalPurposeListSwitchButtonsOffBackgroundColor,
  )
  const switchButtonOffTextColor = getIn(themeConfig, themeUpsertFormKeys.modalPurposeListSwitchButtonsOffTextColor)

  // Experience config
  const showSwitchButtonLabels = getIn(
    experienceConfig,
    experienceUpsertFormKeys.progressiveExperienceSwitchButtonsVisible,
  )
  const switchButtonOnText = getIn(experienceConfig, experienceUpsertFormKeys.progressiveExperienceSwitchButtonsOnText)
  const switchButtonOffText = getIn(
    experienceConfig,
    experienceUpsertFormKeys.progressiveExperienceSwitchButtonsOffText,
  )

  return (
    <SwitchBox minWidth={showSwitchButtonLabels ? '114.5px' : undefined}>
      <BuilderSwitch
        onBackgroundColor={switchButtonOnBackgroundColor}
        onTextColor={switchButtonOnTextColor}
        offBackgroundColor={switchButtonOffBackgroundColor}
        offTextColor={switchButtonOffTextColor}
        onText={switchButtonOnText}
        offText={switchButtonOffText}
        showText={showSwitchButtonLabels}
      />
    </SwitchBox>
  )
}
