import { Chip, Icon, theme } from '@ketch-com/deck'
import Box from '@mui/material/Box'

export const getRenderTags = ({
  value,
  handleRemoveTag,
  emails,
  isDisabled,
}: {
  value: string[]
  handleRemoveTag: (arg: string) => void
  emails: string[]
  isDisabled: boolean
}) =>
  value.map((option: string, index: number) => {
    return (
      <Box
        key={option}
        display="flex"
        flexDirection="row"
        alignItems="center"
        mr={index !== emails.length ? 1 : 0}
        py={0.5}
      >
        <Chip
          label={option}
          deleteIcon={<Icon name="OCross" iconColor={theme.palette.darkDusk.main} />}
          onDelete={
            isDisabled
              ? undefined
              : () => {
                  handleRemoveTag(option)
                }
          }
        />
      </Box>
    )
  })
