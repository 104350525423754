import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Button, TableCell } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { WorkflowDefinitionStepSummaryDTO } from '@ketch-com/figurehead'
import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'

export const variableUsedInWorkflowsColumns: GridColDef<WorkflowDefinitionStepSummaryDTO>[] = [
  {
    align: 'left',
    field: 'activity',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Activity',
    sortable: false,
    renderCell: ({ row: activity }: GridRenderCellParams<WorkflowDefinitionStepSummaryDTO>) => {
      return (
        <TableCell>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px',
                borderRadius: '10px',
                height: 36,
                width: 36,
                minWidth: 36,
                maxHeight: 52,
                backgroundColor:
                  activity.activityCode === WorkflowActivityCode.DECISION_MULTI ? 'darkDusk.main' : 'pink.main',
              }}
            >
              <img
                src={activity?.icon?.url}
                alt=""
                style={{
                  width: '100%',
                }}
              />
            </Box>
            <Typography
              title={activity?.description}
              ml={1.5}
              variant="label"
              sx={{
                wordBreak: 'break-all',
              }}
            >
              {activity?.description}
            </Typography>
          </Box>
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'workflow',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Workflow',
    sortable: false,
    renderCell: ({ row: activity }: GridRenderCellParams<WorkflowDefinitionStepSummaryDTO>) => {
      return <TableCell title={activity.workflowDefinitionName} subTitle={activity.workflowDefinitionCode} />
    },
  },
  {
    align: 'left',
    field: 'action',
    headerAlign: 'left',
    headerName: '',
    width: 180,
    sortable: false,
    renderCell: ({ row: activity }: GridRenderCellParams<WorkflowDefinitionStepSummaryDTO>) => {
      return (
        <TableCell>
          <a
            href={`${window?.location?.origin}/orchestration/workflows/edit/${activity.workflowDefinitionCode}?activityId=${activity.id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Button color="tertiary">Open In New Tab</Button>
          </a>
        </TableCell>
      )
    },
  },
]
