import { WorkflowActivityCode, WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep, ICoordinates } from '../../interfaces'
import { getStepTransitions } from './getStepTransitions'
import * as CanvasConstants from '../constants'

interface ShouldRenderJoinGatewayProps {
  steps: ICanvasStep[]
  joinStep: ICanvasStep
  gridPosition: ICoordinates
  previousStep: ICanvasStep
  connectedNestStartPosition?: number
}

interface StepChain {
  id: string
  chain: string[]
  length: number
}

export interface JoinGatewayInfo {
  shouldDrawTile: boolean
  lineEndPosition: {
    x: number
    y: number
  }
}

const getParentSteps = (steps: ICanvasStep[], stepId: string) => {
  return steps.filter(step => getStepTransitions(step).includes(stepId))
}

const getParentStepChain = (steps: ICanvasStep[], stepId: string, stepChain: string[] = []) => {
  const parentStep = getParentSteps(steps, stepId)[0]

  stepChain.push(parentStep?.ID as string)

  const parentStepIsJoinOrStartTile =
    parentStep?.gateway?.mode === WorkflowActivityGatewayMode.JOIN ||
    parentStep?.activityCode === WorkflowActivityCode.START ||
    !parentStep

  if (!parentStepIsJoinOrStartTile) {
    getParentStepChain(steps, parentStep?.ID as string, stepChain)
  }

  return stepChain
}

export const getJoinTileRenderDetails: (props: ShouldRenderJoinGatewayProps) => JoinGatewayInfo = ({
  joinStep,
  steps,
  gridPosition,
  previousStep,
  connectedNestStartPosition,
}) => {
  // Only the LONGEST path of the connected nest should render the join gateway tile
  // tie broken by ID, ALPHABETICALLY
  // HOWEVER all paths of the nest need to do this sorta math to know where to draw the line to

  const stepsLeadingToJoin = getParentSteps(steps, joinStep.ID as string)

  const stepChainForEachStepLeadingToJoin: StepChain[] = stepsLeadingToJoin.map(step => {
    const chain = getParentStepChain(steps, step.ID as string)
    return {
      id: step.ID as string,
      chain,
      length: chain.length,
    }
  })

  const stepChainLengths = stepChainForEachStepLeadingToJoin.map(({ length }) => length)

  const longestChainLength = Math.max(...stepChainLengths)

  const longestChains = stepChainForEachStepLeadingToJoin.filter(({ length }) => length === longestChainLength)

  const firstAlphabeticalIdOfLongestChains = longestChains.sort((a, b) => (a.id >= b.id ? 1 : -1))[0]

  const previousStepChainLength =
    stepChainForEachStepLeadingToJoin.find(({ id }) => id === previousStep.ID)?.length || 0

  const lineLength = (longestChainLength - previousStepChainLength) * 2
  const lineEndPosition = {
    x: gridPosition.x + lineLength + 2,
    y: connectedNestStartPosition || CanvasConstants.CANVAS_START_TILE_POSITION.y, // This puts the join on the top row of the canvas grid unless it is a nested join with a different start position
  }

  return {
    shouldDrawTile: firstAlphabeticalIdOfLongestChains.id === previousStep.ID,
    lineEndPosition,
  }
}
