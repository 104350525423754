import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIRequestParams } from 'api/common/utils'
import { GetDataPreviewResponseBody } from 'pages/assetManager/interfaces'

type Params = APIRequestParams<{
  tableResourceID?: string
  columnResourceID?: string
  applianceID?: string
}>

export const fetchDataPreview = ({ tableResourceID, columnResourceID, applianceID }: Params) => {
  const entityUrl = `/api/asset-manager/data-preview`

  return API.get<GetDataPreviewResponseBody>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          ...(tableResourceID && {
            tableResourceID,
          }),
          ...(columnResourceID && {
            columnResourceID,
          }),
          ...(applianceID && {
            applianceID,
          }),
        },
      },
    }),
  )
}
