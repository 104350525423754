import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { PutSubscriptionControlRequestBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  data: PutSubscriptionControlRequestBodyDTO
  code: string
}>

export const updateSubscriptionControl = ({ data, code }: Params) => {
  return API.put<PutSubscriptionControlRequestBodyDTO>(
    formatRequestString({
      entityUrl: `/api/subscriptions/controls/${code}`,
    }),
    data,
  )
}
