import React from 'react'
import clsx from 'clsx'
import { DistributiveOmit as Omit } from '@mui/types'

import { makeStyles, createStyles } from '@mui/styles'

import { Text, Props as TextProps } from 'components/ui-kit/typography/Text'

const useStyles = makeStyles(
  createStyles({
    contentTitle: {
      marginBottom: 29,
      display: 'block',
    },
  }),
  { name: 'SectionTitle' },
)

export type Props = Omit<TextProps, 'size' | 'weight'>

export const SectionTitle: React.FC<Props> = ({ children, className, ...props }) => {
  const classes = useStyles()

  return (
    <Text className={clsx(classes.contentTitle, className)} size={20} weight={700} {...props}>
      {children}
    </Text>
  )
}
