import React, { useContext } from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'

import { Flex } from 'components/ui-layouts/flex/Flex'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { PreferenceExperienceTab } from 'pages/consentAndRights/experiences/upsert/utils/preference/constants'
import { PreferenceField } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { UpsertExperienceEditableOverlay } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEditableOverlay'
import { UpsertExperienceEmptyField } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEmptyField'
import { UpsertExperiencePoweredBy } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePoweredBy'
import { UpsertExperiencePreviewText } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewText'
import { useEntitlements, useUpsertPreferenceStyles } from '../../../hooks'
import { useFormikContext } from 'formik'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'

type Props = {
  activeTab: PreferenceExperienceTab
}

export const HeaderPreview: React.FC<Props> = ({ activeTab }) => {
  const classes = useUpsertPreferenceStyles()
  const { getOverlayProps } = useContext(ExperienceUpsertContext)
  const { isEntitledToConsentAndDisclosure, isEntitledToRights, isEntitledToSubscriptions } = useEntitlements()

  const { values } = useFormikContext<PreferenceExperienceFormValues>()
  const { title, overview, rights, consents, subscriptions } = values

  const isOverviewTabActive = activeTab === PreferenceExperienceTab.OVERVIEW
  const isPreferencesTabActive = activeTab === PreferenceExperienceTab.PREFERENCES
  const isRightsTabActive = activeTab === PreferenceExperienceTab.RIGHTS
  const isSubscriptionsTabActive = activeTab === PreferenceExperienceTab.SUBSCRIPTIONS

  return (
    <Box className={classes.header}>
      <Flex className={classes.titleWrapper} justifyContent="space-between" alignItems="center">
        {/* Main Title */}

        <UpsertExperienceEditableOverlay {...getOverlayProps(PreferenceField.MAIN_TITLE)}>
          {title ? (
            <UpsertExperiencePreviewText bold size="large" color="white">
              {title}
            </UpsertExperiencePreviewText>
          ) : (
            <UpsertExperienceEmptyField required size="large">
              Main Title
            </UpsertExperienceEmptyField>
          )}
        </UpsertExperienceEditableOverlay>

        <UpsertExperiencePoweredBy />
      </Flex>

      {/* Tabs Row: Over | Consent | Rights  */}

      {(isEntitledToConsentAndDisclosure || isEntitledToRights) && (
        <ul className={classes.tabs}>
          <li
            className={clsx(classes.tab, {
              [classes.active]: isOverviewTabActive,
            })}
          >
            <UpsertExperienceEditableOverlay {...getOverlayProps(PreferenceField.OVERVIEW_TAB_NAME)}>
              {overview?.tabName ? (
                <UpsertExperiencePreviewText
                  bold={isOverviewTabActive}
                  size="small"
                  color={isOverviewTabActive ? 'blue' : 'white'}
                >
                  {overview.tabName}
                </UpsertExperiencePreviewText>
              ) : (
                <UpsertExperienceEmptyField required size="small">
                  Overview Tab
                </UpsertExperienceEmptyField>
              )}
            </UpsertExperienceEditableOverlay>
          </li>

          {isEntitledToConsentAndDisclosure && (
            <li className={clsx(classes.tab, { [classes.active]: isPreferencesTabActive })}>
              <UpsertExperienceEditableOverlay {...getOverlayProps(PreferenceField.PREFERENCES_TAB_NAME)}>
                {consents?.tabName ? (
                  <UpsertExperiencePreviewText
                    bold={isPreferencesTabActive}
                    size="small"
                    color={isPreferencesTabActive ? 'blue' : 'white'}
                  >
                    {consents.tabName}
                  </UpsertExperiencePreviewText>
                ) : (
                  <UpsertExperienceEmptyField required size="small">
                    Consent Tab
                  </UpsertExperienceEmptyField>
                )}
              </UpsertExperienceEditableOverlay>
            </li>
          )}

          {isEntitledToSubscriptions && (
            <li className={clsx(classes.tab, { [classes.active]: isSubscriptionsTabActive })}>
              <UpsertExperienceEditableOverlay {...getOverlayProps(PreferenceField.SUBSCRIPTIONS_TAB_NAME)}>
                {subscriptions?.tabName ? (
                  <UpsertExperiencePreviewText
                    bold={isSubscriptionsTabActive}
                    size="small"
                    color={isSubscriptionsTabActive ? 'blue' : 'white'}
                  >
                    {subscriptions.tabName}
                  </UpsertExperiencePreviewText>
                ) : (
                  <UpsertExperienceEmptyField required size="small">
                    Subscriptions Tab
                  </UpsertExperienceEmptyField>
                )}
              </UpsertExperienceEditableOverlay>
            </li>
          )}

          {isEntitledToRights && (
            <li className={clsx(classes.tab, { [classes.active]: isRightsTabActive })}>
              <UpsertExperienceEditableOverlay {...getOverlayProps(PreferenceField.RIGHTS_TAB_NAME)}>
                {rights?.tabName ? (
                  <UpsertExperiencePreviewText
                    bold={isRightsTabActive}
                    size="small"
                    color={isRightsTabActive ? 'blue' : 'white'}
                  >
                    {rights.tabName}
                  </UpsertExperiencePreviewText>
                ) : (
                  <UpsertExperienceEmptyField required size="small">
                    Rights Tab
                  </UpsertExperienceEmptyField>
                )}
              </UpsertExperienceEditableOverlay>
            </li>
          )}
        </ul>
      )}
    </Box>
  )
}
