import React, { useContext, useEffect } from 'react'
import { FormSectionSwitch } from '../FormSectionSwitch'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { Box } from '@mui/material'
import { Tab, TabPanel, TabGroup } from '@ketch-com/deck'
import { FormTextInput } from '../FormTextInput'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { FormEditor, renderDocumentLinkerModal } from 'components/ui-kit/form/editor'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { PreferenceExperienceBuilderSection } from '../../../builder/utils/enums'

export const PreferenceExperienceWelcomeTabAboutForm: React.FC = () => {
  const { experienceBuilderSection, languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const { values } = useFormikContext<ExperienceV2DTO>()
  const hasLanguages = !isLanguagesLoading && !!languages.length

  // Current tab of the button form
  const startInDescription = experienceBuilderSection === PreferenceExperienceBuilderSection.WelcomeTabAboutDescription
  const [tab, setTab] = React.useState(startInDescription ? 1 : 0)

  const showTitleForm = getIn(values, experienceUpsertFormKeys.preferenceWelcomeTabAboutVisible)

  // Update starting tab when the experience builder section changes
  useEffect(() => setTab(startInDescription ? 1 : 0), [startInDescription])

  return (
    <>
      <SidebarFormGroup sx={{ pt: 0, mt: '-8px' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
            <Tab size={'medium'} label="Title" />
            <Tab size={'medium'} label="Description" />
          </TabGroup>
        </Box>

        {/* Title form */}
        <TabPanel value={tab} index={0} sx={{ px: 0, pt: 0 }}>
          <FormSectionSwitch name={experienceUpsertFormKeys.preferenceWelcomeTabAboutVisible} title={'Title'} />
          {showTitleForm && (
            <Box px={2.25}>
              <SidebarFormGroup hasBorderBottom={hasLanguages} sx={{ paddingTop: '16px' }}>
                <FormTextInput
                  name={experienceUpsertFormKeys.preferenceWelcomeTabAboutTitle}
                  label={'Title Text'}
                  fullWidth
                  charLimit={200}
                />
              </SidebarFormGroup>
              <SidebarFormTranslations
                title={'Title Text Translations'}
                name={experienceUpsertFormKeys.preferenceWelcomeTabAboutTitle}
                variant={'text'}
                charLimit={200}
              />
            </Box>
          )}
        </TabPanel>

        {/* Description form*/}
        <TabPanel value={tab} index={1} sx={{ px: 0, pt: 0 }}>
          <SidebarFormGroup hasBorderBottom sx={{ pt: 0 }}>
            <FormEditor
              label={'Description Text'}
              required={false}
              id={experienceUpsertFormKeys.preferenceWelcomeTabAboutDescription.replaceAll('.', '_')}
              name={experienceUpsertFormKeys.preferenceWelcomeTabAboutDescription}
              linkChangeModal={renderDocumentLinkerModal}
              charLimit={6000}
            />
          </SidebarFormGroup>
          <SidebarFormTranslations
            title={'Description Text Translations'}
            name={experienceUpsertFormKeys.preferenceWelcomeTabAboutDescription}
            variant={'richText'}
            charLimit={6000}
          />
        </TabPanel>
      </SidebarFormGroup>
    </>
  )
}
