import { MaybeNull } from 'interfaces'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { PreferenceField } from '../utils/common'
import { PreferenceExperienceTab } from '../utils/preference/constants'
import { ConsentExperienceTab } from '../utils/consent/constants'

interface Args {
  sidebarActiveTab: MaybeNull<PreferenceField>
  setActiveTab: Dispatch<SetStateAction<ConsentExperienceTab | PreferenceExperienceTab>>
}

export const useSyncTabs = ({ sidebarActiveTab, setActiveTab }: Args) => {
  useEffect(() => {
    switch (sidebarActiveTab) {
      case PreferenceField.OVERVIEW_TAB_NAME:
        setActiveTab(PreferenceExperienceTab.OVERVIEW)
        break
      case PreferenceField.PREFERENCES_TAB_NAME:
        setActiveTab(PreferenceExperienceTab.PREFERENCES)
        break
      case PreferenceField.SUBSCRIPTIONS_TAB_NAME:
        setActiveTab(PreferenceExperienceTab.SUBSCRIPTIONS)
        break
      case PreferenceField.RIGHTS_TAB_NAME:
        setActiveTab(PreferenceExperienceTab.RIGHTS)
        break
      default:
        // Do nothing
        break
    }
  }, [sidebarActiveTab, setActiveTab])
}
