import { flatMap } from 'lodash'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useQueries } from 'react-query'
import { fetchAppInstancesUsages } from 'api/apps/fetchers/fetchAppInstancesUsages'
import { useAuth } from 'utils/hooks/useAuth'
import { WorkflowDTO } from 'interfaces/workflows'

export const useAppInstancesUsages = (appInstancesIdsArray: string[]) => {
  const { userData } = useAuth()

  const queries = appInstancesIdsArray.map(appInstanceId => ({
    queryKey: [ApiQueryKeys.appsInstanceUsages, { appInstanceId, organizationCode: userData.organizationCode! }],
    queryFn: fetchAppInstancesUsages,
  }))
  /* using "as any" here because there's an open issue about how typing useQueries from react-query is challenging.
  Issue Link: https://github.com/tannerlinsley/react-query/issues/1675
  */
  const results = useQueries(queries as any).map((r: any) => ({
    workflows: r.data?.data?.workflows || [],
    isLoading: r?.isLoading || r?.isFetching || false,
  }))

  const workflows = flatMap(results, result => result.workflows as WorkflowDTO[])

  return { workflows, isLoading: results.some(r => r?.isLoading) }
}
