import { useContext } from 'react'
import { Box } from '@mui/material'
import { options } from './utils'
import { Chip, MegaFilterContext } from '@ketch-com/deck'

export const ChipsSection = ({ sectionKey }: { sectionKey: string }) => {
  const context = useContext(MegaFilterContext)

  const { handleAddSelectedValue, handleDeleteValue, getSelectedValuesArrayByCategory } = context

  const currentValues = getSelectedValuesArrayByCategory(sectionKey)

  const getIsSelected = (option: string) =>
    !!(currentValues && currentValues.find(selectedValue => selectedValue.value === option))
  const handleChipClick = (option: string) => {
    const isSelected = getIsSelected(option)
    if (!isSelected) {
      handleAddSelectedValue({
        value: option,
        category: sectionKey,
      })
    } else {
      handleDeleteValue({
        value: option,
        category: sectionKey,
      })
    }
  }

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" gap={1} sx={{ margin: '0 12px' }}>
      {options.map((option, index) => {
        return (
          <Chip
            key={index}
            label={option}
            clickable
            selectable
            isSelected={getIsSelected(option)}
            onClick={() => handleChipClick(option)}
          />
        )
      })}
    </Box>
  )
}
