import { GroupChannel, SendbirdGroupChat } from '@sendbird/chat/groupChannel'
import { SENDBIRD_CHANNEL_OWNER_ID } from './enums'
import { reconnectAs } from './reconnectAs'

/**
 * Invites a user to a group channel.
 *
 * @param sb A sendbird SDK instance
 * @param channel The channel to invite the user to
 * @param userIdToInvite The ID of the user to invite
 * @param currentUserId The ID of the current user, used to reconnect after channel creation
 */
export const inviteToGroupChannel = async (
  sb: SendbirdGroupChat,
  channel: GroupChannel,
  userIdToInvite: string,
  currentUserId: string,
) => {
  // Invite to channel as channel owner
  await reconnectAs(sb, SENDBIRD_CHANNEL_OWNER_ID)
  const newChannel = await channel.inviteWithUserIds([userIdToInvite])

  // Reconnect as current user
  await reconnectAs(sb, currentUserId)
  return newChannel
}
