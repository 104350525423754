import { TooltipButton } from '@ketch-com/deck'
import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { GetWebhooksContextResponseBodyDTO, WebhookWorkflowEventStatusDTO } from '@ketch-com/figurehead'
import { WebhooksContextTimelineItemStatus } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'
import { useCallback, useContext, useState } from 'react'
import { ApiCallContext } from '../context/ApiCallContext'

type Props = {
  workflowStep: WorkflowExecutionStepDetailsDTO | null
  resolve: () => Promise<void>
  webhookContext: GetWebhooksContextResponseBodyDTO | null
}

export const ApiCallStepDetailsHeaderActions: React.FC<Props> = ({ workflowStep, resolve, webhookContext }) => {
  // TODO:JA this is a short term solution until backend passes allowRetry and allowResolve params
  // If most recent timeline activity is an error, display Retry
  let label = 'Resolve'
  let tooltip = `The step can be resolved once the "Completed" signal has been sent via API and all issues have been fixed.`
  if (webhookContext?.timeline && webhookContext?.timeline?.length > 0) {
    if (webhookContext.timeline[0].status === WebhooksContextTimelineItemStatus.ERROR) {
      label = 'Retry'
      tooltip = `There was an error in the webhook response. Please correct and use "Retry" to resend the request to the webhook.`
    }
  }

  const { timelineIssuesCount } = useContext(ApiCallContext)

  const [hasRetried, setHasRetried] = useState(false)

  const handleResolve = useCallback(async () => {
    setHasRetried(true)
    try {
      await resolve()
    } catch {
      setHasRetried(false)
      return
    }
    window.location.reload()
  }, [resolve])

  const hideResolve =
    workflowStep?.status === WorkflowExecutionStepStatus.PENDING ||
    workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS

  // enableResolve is true if we are in the part of the loop of the event forwarder where we can push resolve to
  // move the workflow forward - e.g., when there are no more issues and we are in "completed" status
  let enableResolve =
    timelineIssuesCount === 0 &&
    webhookContext?.latestEventStatus === WebhookWorkflowEventStatusDTO.CompletedWebhookWorkflowEventStatus

  // enableRetry is true if we are in the part of the loop of the event forwarder where we can push retry to resend
  // the request to the webhook - e.g., if the webhook returned an 3xx+ error code or had a malformed body
  let enableRetry =
    webhookContext?.timeline &&
    webhookContext?.timeline?.length > 0 &&
    webhookContext.timeline[0].status === WebhooksContextTimelineItemStatus.ERROR

  // handle button behavior in the case of a Failed step
  let isStepFailed = workflowStep?.status === WorkflowExecutionStepStatus.FAIL
  if (isStepFailed) {
    label = 'Resolve'
    tooltip = `This step encountered an error causing it to Fail. Please enter any desired notes and Data Subject Variables, then use "Resolve" to have the parent workflow proceed.`
    enableRetry = false
    enableResolve = true
  }

  return hideResolve ? null : (
    <TooltipButton
      size="large"
      color="primary"
      onClick={handleResolve}
      disabled={!(enableResolve || enableRetry) || hasRetried}
      title={tooltip}
    >
      {label}
    </TooltipButton>
  )
}
