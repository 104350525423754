import { Badge } from 'components/ui-kit/badge/Badge'
import { AssetSummaryDTO } from '@ketch-com/figurehead'

type Props = {
  datasetId?: string
  assetsSummary: AssetSummaryDTO[]
}

export const DatasetPersonalDataType: React.FC<Props> = ({ datasetId, assetsSummary }) => {
  const selectedAsset = assetsSummary.filter(assetSummary => assetSummary?.asset?.resource?.id === datasetId)
  return <Badge variant="neutral">{selectedAsset[0]?.asset?.dataCategory?.[0].value || 'Non-Personal'}</Badge>
}
