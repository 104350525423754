import { getURLParams } from './utils'

export const assetManagerDemo = {
  root: {
    pattern: '/asset-manager-data',
    getURL: () => '/asset-manager-data',
  },

  view: {
    root: {
      pattern: '/view/:code',
      getURL: ({ code }: getURLParams) => `/asset-manager-data/view/${code}`,
    },

    overview: {
      root: {
        pattern: '/overview',
        getURL: ({ code }: getURLParams) => `/asset-manager-data/view/${code}/overview`,
      },
    },

    attributes: {
      root: {
        pattern: '/attributes',
        getURL: ({ code }: getURLParams) => `/asset-manager-data/view/${code}/attributes`,
      },
    },
  },
}
