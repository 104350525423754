import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { useUser } from 'api/users/queries/useUser'
import { showToast } from 'components/modals/AlertComponent'
import { UsersViewView } from 'pages/settings/users/view/UsersViewView'

export const UsersViewContainer: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()

  const handleRedirectToUsersList = () => {
    navigate(RoutesManager.settings.users.root.getURL())
  }

  const { data: user, isLoading: isUserLoading } = useUser({
    params: {
      includeMetadata: true,
      userId: id!,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch user', type: 'error' })
      handleRedirectToUsersList()
    },
  })

  return <UsersViewView isReady={!isUserLoading} user={user} />
}
