import { APIListRequestParams } from 'api/common/utils'
import { FindInstalledDataSystemsV2ResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  q?: string
  capabilities?: string[]
  connectionStatuses?: string[]
  start?: number
  limit?: number
  ownerUserIds?: string
}>

export const fetchInstalledDataSystems = ({
  q,
  capabilities,
  connectionStatuses,
  start,
  limit,
  ownerUserIds,
}: Params) => {
  return API.get<FindInstalledDataSystemsV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/installed-data-systems`,
      params: {
        filters: {
          start,
          limit,
          query: q,
          capabilities,
          connectionStatuses,
          ownerUserIds,
        },
      },
    }),
  )
}
