import { useMemo, useState, useContext } from 'react'
import { useTheme } from '@mui/styles'
import { showToast } from 'components/modals/AlertComponent'
import {
  getStepDetailsTileWithEditModeValidationSchema,
  getStepDetailsTileWithEditModeInitialValues,
  mapStepDetailsTileWithEditModeValuesToPayload,
  StepDetailsTileWithEditModeInitialValues,
} from '../utils'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useUpdateWorkflowExecutionStep } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStep'
import { RightInvocationWorkflowStepDetailViewContext } from '../../../context'
import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'

const useThemedStyles = ({ isExpanded }: { isExpanded: boolean }) => {
  const theme = useTheme()

  return {
    containerSx: {
      backgroundColor: 'white.main',
      borderRadius: 1.375,
      p: 2.5,
      mb: 2,
    },
    withPointerSx: {
      cursor: 'pointer',
    },
    chevronSx: {
      mr: 1.875,
      color: 'darkDusk.main',
      fontSize: theme.typography.pxToRem(20),
      transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
      transition: 'transform 0.2s ease-in-out',

      '& > path': {
        fill: theme.palette.darkDusk.main,
      },
    },

    editItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'bone.main',
      borderRadius: 2.75,
      px: 1,
      py: 1.5,
      mb: theme.spacing(2),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  }
}

export const useStepDetailsTileWithEditModeUtils = () => {
  const queryClient = useQueryClient()
  const {
    stepDetails,
    refetchWorkflowStep,
    stepId,
    workflowExecutionId,
    workflowStep,
    isRightsQueueAdmin,
    isTaskComplete,
    isAssignedToMe,
    isWorkflowExecutionTerminated,
    workflowExecution,
  } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const styles = useThemedStyles({ isExpanded })

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [selectedDataSubjectVariableForAddition, setSelectedDataSubjectVariableForAddition] = useState<string>('')
  const [unAddedVariableWarning, setUnAddedVariableWarning] = useState<boolean>(false)

  const validationSchema = useMemo(() => getStepDetailsTileWithEditModeValidationSchema(), [])

  const initialValues = useMemo(() => getStepDetailsTileWithEditModeInitialValues({ stepDetails }), [stepDetails])

  const { mutateAsync: submitStepDetails } = useUpdateWorkflowExecutionStep({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.workflowExecutionStepDetailsV2, { workflowExecutionId, stepId }])
      await queryClient.refetchQueries(ApiQueryKeys.gatewayContext)
      await refetchWorkflowStep()

      showToast({ content: 'Successfully updated Task Data Subject Variables', type: 'success' })
      setIsEditMode(false)
    },
    onError: () => {
      showToast({ content: 'Failed to update Task Data Subject Variables', type: 'error' })
    },
  })

  const handleSubmit = async (values: StepDetailsTileWithEditModeInitialValues) => {
    const formData = mapStepDetailsTileWithEditModeValuesToPayload({ values })

    await submitStepDetails({
      params: {
        formData: {
          dataSubjectContext: formData,
        },
        workflowExecutionId,
        stepId,
      },
    })
  }

  const handleExitEditMode = (resetForm: any) => {
    resetForm()
    setIsEditMode(false)
  }

  const isDecisionGatewayStep =
    workflowStep?.activityCode === WorkflowActivityCode.DECISION_MULTI ||
    workflowStep?.activityCode === WorkflowActivityCode.DECISION_BOOLEAN

  const appealDataSubjectVariables = stepDetails?.dataSubjectContext?.filter(variable => !!variable?.isAppealValue)

  const isInternalSystemDSRStep = workflowStep?.activityCode === WorkflowActivityCode.INTERNAL_SYSTEM_DSR

  const payload = {
    appealDataSubjectVariables,
    handleExitEditMode,
    handleSubmit,
    initialValues,
    isAssignedToMe,
    isDecisionGatewayStep,
    isInternalSystemDSRStep,
    isEditMode,
    isExpanded,
    isRightsQueueAdmin,
    isWorkflowExecutionTerminated,
    isTaskComplete,
    selectedDataSubjectVariableForAddition,
    setIsEditMode,
    setIsExpanded,
    setSelectedDataSubjectVariableForAddition,
    setUnAddedVariableWarning,
    stepDetails,
    styles,
    unAddedVariableWarning,
    validationSchema,
    workflowExecution,
  }

  return payload
}
