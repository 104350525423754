import React from 'react'
import { SxProps, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'
import ClearIcon from '@mui/icons-material/Clear'

type UseStylesProps = {
  hasOnClick?: boolean
}

const useStyles = makeStyles<Theme, UseStylesProps>(
  ({ typography, palette, spacing }) =>
    createStyles({
      baseChip: {
        boxSizing: 'border-box',
        display: 'inline-flex',
        alignItems: 'center',
        fontFamily: typography.fontFamily,
        letterSpacing: '-0.01em',
        borderRadius: 20,
        background: palette.fadedGrey.main,
        userSelect: 'none',

        '&:hover': {
          background: ({ hasOnClick }) => (hasOnClick ? palette.iron.main : undefined),
        },
      },
      borderRadius100: {
        borderRadius: 100,
      },
      baseIcon: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        marginRight: 5,
      },
      baseRemoveIcon: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        marginLeft: 5,
        cursor: 'pointer',

        '&:hover': {
          opacity: 0.75,
        },
      },
      baseContent: {
        flexGrow: 1,
      },
      padding: {
        padding: '6px 12px',
      },
      paddingRegular: {
        padding: spacing(1.25, 2),
      },
      // Sizes:
      sizeRegular: {
        fontSize: typography.pxToRem(14),
        lineHeight: typography.pxToRem(20),

        '& $baseIcon svg': {
          fontSize: typography.pxToRem(18),
        },

        '& $baseRemoveIcon svg': {
          fontSize: typography.pxToRem(18),
        },
      },
      sizeSmall: {
        fontWeight: 500,
        fontSize: typography.pxToRem(12),
        lineHeight: typography.pxToRem(17),

        '& $baseIcon svg': {
          fontSize: typography.pxToRem(16),
        },

        '& $baseRemoveIcon svg': {
          fontSize: typography.pxToRem(16),
        },
      },

      // States:
      canBeSelected: {
        cursor: 'pointer',

        '& $baseContent': {
          color: palette.heliotrope.main,
        },

        '&:hover': {
          background: palette.fadedHeliotrope.main,

          '& $baseContent': {
            color: palette.pink.main,
          },
        },

        '&:active': {
          background: palette.fadedHeliotrope.main,

          '& $baseContent': {
            color: palette.minsk.main,
          },
        },
      },
      selected: {
        background: palette.heliotrope.main,
        color: palette.white.main,

        '& $baseContent': {
          color: palette.white.main,
        },

        '&:hover': {
          background: palette.pink.main,
          color: palette.white.main,

          '& $baseContent': {
            color: palette.white.main,
          },
        },

        '&:active': {
          background: palette.minsk.main,
          color: palette.white.main,

          '& $baseContent': {
            color: palette.white.main,
          },
        },
      },
      disabled: {
        pointerEvents: 'none',

        '& $baseContent': {
          color: palette.fadedDarkGrey.main,
        },

        '& $baseIcon svg': {
          color: palette.fadedDarkGrey.main,
        },

        '& $baseRemoveIcon svg': {
          color: palette.fadedDarkGrey.main,
        },
      },
    }),
  { name: 'Chip' },
)

export interface Props {
  children?: React.ReactNode
  /** Component active state */
  selected?: boolean
  /** Component disabled state */
  disabled?: boolean
  /** Component size - reflects the font size and dimensions */
  size?: 'regular' | 'small'
  /** Component icon */
  icon?: any
  /** Component custom className */
  className?: any
  /** Component onClick trigger */
  onClick?: () => void
  /** Component onRemove trigger */
  onRemove?: () => void
  /** To override padding */
  resetPadding?: boolean
  sx?: SxProps<Theme>
  childrenContainerSx?: SxProps<Theme>
  borderRadius100?: boolean
  withPaddingRegular?: boolean
}

/**
 * -
 */
export const Chip: React.FC<Props> = ({
  selected = false,
  disabled = false,
  size = 'regular',
  className,
  icon,
  onClick,
  onRemove,
  children,
  resetPadding = false,
  sx,
  childrenContainerSx,
  borderRadius100 = false,
  withPaddingRegular = false,
}) => {
  const classes = useStyles({ hasOnClick: !!onClick })

  const componentClassName = clsx(
    classes.baseChip,
    {
      [classes.sizeRegular]: size === 'regular',
      [classes.sizeSmall]: size === 'small',
      [classes.canBeSelected]: !!onClick,
      [classes.selected]: selected,
      [classes.disabled]: disabled,
      [classes.padding]: !resetPadding,
      [classes.borderRadius100]: borderRadius100,
      [classes.paddingRegular]: withPaddingRegular,
    },
    className,
  )

  return (
    <Box className={componentClassName} onClick={onClick} sx={sx}>
      {icon && <Box className={classes.baseIcon}>{icon}</Box>}

      <Box sx={childrenContainerSx} className={classes.baseContent}>
        {children}
      </Box>

      {!!onRemove && (
        <Box
          className={classes.baseRemoveIcon}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            onRemove()
          }}
        >
          <ClearIcon />
        </Box>
      )}
    </Box>
  )
}
