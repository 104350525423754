import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchSubscriptionControlOrchestrations } from 'api/subscriptions/fetchers/fetchSubscriptionControlOrchestrations'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.subscriptionControlOrchestrations,
  queryFn: fetchSubscriptionControlOrchestrations,
  select: res => res?.data || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useSubscriptionControlOrchestrations = (config?: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
