import { BannerField, ModalField, JitField, PreferenceField } from '.'

export const SIDEBAR_TITLES = {
  [BannerField.MAIN_TITLE]: 'Main Title',
  [BannerField.DESCRIPTION]: 'Description',
  [BannerField.ACTION_BUTTON]: 'Secondary Button',
  [BannerField.CONFIRM_BUTTON]: 'Primary Button',
  [BannerField.CLOSE_ICON]: 'X Button',

  [ModalField.MAIN_TITLE]: 'Main Title',
  [ModalField.BODY_TITLE]: 'Body Title',
  [ModalField.BODY_DESCRIPTION]: 'Body Description',
  [ModalField.CONFIRM_BUTTON]: 'Primary Button',
  [ModalField.CLOSE_ICON]: 'X Button',

  [JitField.MAIN_TITLE]: 'Main Title',
  [JitField.BODY_DESCRIPTION]: 'Body Description',
  [JitField.ACTION_BUTTON]: 'Tertiary Button',
  [JitField.DECLINE_BUTTON]: 'Secondary Button',
  [JitField.CONFIRM_BUTTON]: 'Primary Button',
  [JitField.CLOSE_ICON]: 'X Button',

  [PreferenceField.MAIN_TITLE]: 'Main Title',
  [PreferenceField.OVERVIEW_TAB_NAME]: 'Overview Tab Name',
  [PreferenceField.OVERVIEW_BODY_TITLE]: 'Body Title',
  [PreferenceField.OVERVIEW_BODY_DESCRIPTION]: 'Body Description',
  [PreferenceField.RIGHTS_TAB_NAME]: 'Rights Tab Name',
  [PreferenceField.RIGHTS_BODY_TITLE]: 'Body Title',
  [PreferenceField.RIGHTS_BODY_DESCRIPTION]: 'Body Description',
  [PreferenceField.RIGHTS_FOOTER_BUTTONS]: 'Footer Buttons',
  [PreferenceField.PREFERENCES_TAB_NAME]: 'Consent Tab Name',
  [PreferenceField.PREFERENCES_BODY_TITLE]: 'Body Title',
  [PreferenceField.PREFERENCES_BODY_DESCRIPTION]: 'Body Description',
  [PreferenceField.PREFERENCES_SUBMIT_BUTTON]: 'Submit Button',
  [PreferenceField.PREFERENCE_PURPOSES]: 'Purposes',
  [PreferenceField.RIGHTS_FORM_SETTINGS]: 'Form Settings',
  [PreferenceField.RIGHTS_REQUEST]: 'Rights Settings',
  [PreferenceField.SUBSCRIPTIONS_TAB_NAME]: 'Subscriptions Tab Name',
  [PreferenceField.SUBSCRIPTIONS_BODY_TITLE]: 'Body Title',
  [PreferenceField.SUBSCRIPTIONS_BODY_DESCRIPTION]: 'Body Description',
  [PreferenceField.SUBSCRIPTIONS_PRIMARY_BUTTON]: 'Primary Button',
  [PreferenceField.SUBSCRIPTIONS_SECONDARY_BUTTON]: 'Secondary Button',
  [PreferenceField.SUBSCRIPTIONS_TOPICS]: 'Subscriptions List',
  [PreferenceField.SUBSCRIPTIONS_CONTROL]: 'Unsubscribe From All',
}
