import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

// components
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Box, Typography } from '@mui/material'
import { Button } from '@ketch-com/deck'
import { showToast } from 'components/modals/AlertComponent'
import { Reassign } from 'pages/orchestration/rightsQueue/redaction/components/Reassign'
import { RedactionHeaderActionsModal } from './RedactionHeaderActionsModal'
// layouts

// api
import { useUpdateWorkflowExecutionStepReviewers } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStepReviewers'
import { useUpdateReviewStatus } from 'api/dataReview/mutations/useUpdateReviewStatus'
import { ApiQueryKeys } from 'api/common/queryKeys'

// utils
import { RoutesManager } from 'utils/routing/routesManager'
import { useAuth } from 'utils/hooks/useAuth'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

// types
import { DataReviewStatusEnum } from 'interfaces/dataReview'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { DataReviewStepDTO } from '@ketch-com/figurehead'
import { fromAssignee } from 'utils/helpers/teamStringParser'

type Props = {
  dataReviewSteps: DataReviewStepDTO[]
  invalidFormatFiles: string[]
  isSaving: boolean
  rightInvocation: RightInvocationDTO
  saveFiles: () => Promise<void>
  totalRedactionCount: number
  workflowExecutionStep: WorkflowExecutionStepDTO
}

export const RedactionHeaderActions: React.FC<Props> = ({
  dataReviewSteps,
  invalidFormatFiles,
  isSaving,
  rightInvocation,
  saveFiles,
  totalRedactionCount,
  workflowExecutionStep,
}) => {
  // hooks
  const { userData } = useAuth()
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const { stepId } = useParams<{ id: string; stepId: string; executionId: string }>()
  const queryClient = useQueryClient()

  // state
  const [modalSubmitType, setModalSubmitType] = useState('')
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  // api
  const { mutate: handleUpdateStatus } = useUpdateReviewStatus({
    onSuccess: async () => {
      await queryClient.refetchQueries([
        ApiQueryKeys.workflowExecution,
        {
          workflowExecutionId: workflowExecutionStep.workflowExecutionID,
        },
      ])
      await queryClient.refetchQueries(ApiQueryKeys.rightInvocationV2)
      showToast({ content: 'Status updated', type: 'success' })
      handleClose()
    },
    onError: () => {
      showToast({ content: 'Failed to update status', type: 'error' })
    },
  })

  const { mutateAsync: handleUpdateWorkflowExecutionStepReviewer } = useUpdateWorkflowExecutionStepReviewers({
    onSuccess: async () => {
      await queryClient.refetchQueries([
        ApiQueryKeys.workflowExecution,
        {
          workflowExecutionId: workflowExecutionStep.workflowExecutionID,
        },
      ])
      showToast({ content: 'Activity reassigned', type: 'success' })
      setShowDropdown(false)
    },
    onError: () => {
      showToast({ content: 'Failed to reassign activity', type: 'error' })
      setShowDropdown(false)
    },
  })

  // component utils

  const { assignee, approver, dataReviewStatus } = workflowExecutionStep
  const [, assigneeFromTeam] = fromAssignee(assignee || '')
  const isAssignedToMe = assigneeFromTeam === userData.userId
  const canUserApprove = approver === userData.userId
  const canReassign = isPermitted(PERMISSIONS.WORKFLOW_TASK_ASSIGN)
  const { metadata } = rightInvocation
  const isApproved = dataReviewStatus === DataReviewStatusEnum.DATA_REVIEW_STATUS_APPROVED
  const hasApprover = !!approver
  const needsApproval =
    !!approver?.length &&
    dataReviewStatus !== DataReviewStatusEnum.DATA_REVIEW_STATUS_APPROVED &&
    dataReviewStatus !== DataReviewStatusEnum.DATA_REVIEW_STATUS_SUBMITTED
  const isPendingReview = dataReviewStatus === DataReviewStatusEnum.DATA_REVIEW_STATUS_SUBMITTED
  const showSaveButton = (isPendingReview && canUserApprove) || (!isPendingReview && isAssignedToMe)
  const currentExecutionID =
    (rightInvocation.appeal?.isAppealed
      ? rightInvocation.appeal?.results?.appealWorkflowExecutionID
      : rightInvocation.workflowExecutionID) ?? ''

  const submitForApproval = async () => {
    // save files before submitting for approval
    await saveFiles()
    updateStatus(DataReviewStatusEnum.DATA_REVIEW_STATUS_SUBMITTED)
  }

  const rejectApproval = () => {
    updateStatus(DataReviewStatusEnum.DATA_REVIEW_STATUS_PENDING)
  }

  const completeStep = async () => {
    // save files before completing
    await saveFiles()
    updateStatus(DataReviewStatusEnum.DATA_REVIEW_STATUS_APPROVED)
  }

  const updateStatus = (status: number) => {
    handleUpdateStatus({
      params: {
        executionId: currentExecutionID,
        requestId: rightInvocation?.id || '',
        stepId: workflowExecutionStep.stepID,
        formData: {
          status,
        },
      },
    })
  }

  const handleClose = () => {
    navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocation.id }))
  }

  const handleModalSubmitAction = async (action: string) => {
    setModalSubmitType('')
    switch (action) {
      case 'Approval':
        await completeStep()
        break
      case 'Submit':
        await submitForApproval()
        break
      default:
        await saveFiles()
        break
    }
  }

  const handleReassign = async (values: { assigneeId: string }) => {
    const formData = isPendingReview ? { approver: values.assigneeId } : { assignee: values.assigneeId }
    await handleUpdateWorkflowExecutionStepReviewer({
      params: {
        workflowExecutionId: currentExecutionID,
        stepId: stepId!,
        formData,
      },
    })
  }

  return (
    <>
      {!!modalSubmitType && (
        <RedactionHeaderActionsModal
          dataReviewSteps={dataReviewSteps}
          invalidFormatFiles={invalidFormatFiles}
          modalSubmitType={modalSubmitType}
          onCancel={() => setModalSubmitType('')}
          onSubmit={() => handleModalSubmitAction(modalSubmitType)}
          buttonLabel={!hasApprover && isAssignedToMe ? 'Complete' : 'Approve'}
          totalRedactionCount={totalRedactionCount}
        />
      )}

      <Box display="flex" alignItems="center" gap={1.5} height="100%">
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="smallBody" color="darkDuskFaded.main">
            Last Updated
          </Typography>
          <Typography color="darkDusk.main" variant="smallBody">
            <FormattedDateRenderer date={metadata?.updatedAt} />
          </Typography>
        </Box>

        {canReassign && (
          <Reassign
            assignee={isPendingReview ? approver : assignee}
            onSubmit={handleReassign}
            setShowDropdown={setShowDropdown}
            showDropdown={showDropdown}
          />
        )}

        {isAssignedToMe && needsApproval && (
          <Button pending={isSaving} size="large" onClick={() => setModalSubmitType('Submit')} color="secondary">
            Submit For Approval
          </Button>
        )}

        {canUserApprove && isPendingReview && (
          <Button pending={isSaving} size="large" onClick={rejectApproval} color="tertiary">
            Reject
          </Button>
        )}

        {!hasApprover && isAssignedToMe && !isApproved && (
          <Button pending={isSaving} size="large" onClick={() => setModalSubmitType('Approval')} color="secondary">
            Complete
          </Button>
        )}

        {showSaveButton && (
          <Button
            pending={isSaving}
            size="large"
            onClick={() => setModalSubmitType('Save')}
            color={isPendingReview ? 'secondary' : 'primary'}
          >
            Save
          </Button>
        )}

        {canUserApprove && isPendingReview && (
          <Button pending={isSaving} size="large" onClick={() => setModalSubmitType('Approval')}>
            Approve
          </Button>
        )}
      </Box>
    </>
  )
}
