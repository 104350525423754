import { IDParams } from './utils'

export const permissions = {
  root: {
    pattern: '/permissions',
    getURL: () => `/permissions`,
  },

  permissions: {
    root: {
      pattern: '/permissions',
      getURL: () => `/permissions/permissions`,
    },

    view: {
      root: {
        pattern: '/permissions/view/:id',
        getURL: ({ id }: IDParams) => `/permissions/permissions/view/${id}`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ id }: IDParams) => `/permissions/permissions/view/${id}/overview`,
        },
      },

      sets: {
        root: {
          pattern: '/sets',
          getURL: ({ id }: IDParams) => `/permissions/permissions/view/${id}/sets`,
        },
      },
    },

    upsert: {
      root: {
        pattern: '/permissions/edit/:id',
        getURL: ({ id }: IDParams) => `/permissions/permissions/edit/${id}`,
      },
    },
  },

  permissionsSets: {
    root: {
      pattern: '/permissions-sets',
      getURL: () => `/permissions/permissions-sets`,
    },

    view: {
      root: {
        pattern: '/permissions-sets/view/:id',
        getURL: ({ id }: IDParams) => `/permissions/permissions-sets/view/${id}`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ id }: IDParams) => `/permissions/permissions-sets/view/${id}/overview`,
        },
      },

      permissions: {
        root: {
          pattern: '/permissions',
          getURL: ({ id }: IDParams) => `/permissions/permissions-sets/view/${id}/permissions`,
        },
      },

      skus: {
        root: {
          pattern: '/skus',
          getURL: ({ id }: IDParams) => `/permissions/permissions-sets/view/${id}/skus`,
        },
      },
    },

    upsert: {
      root: {
        pattern: '/permissions-sets/edit/:id',
        getURL: ({ id }: IDParams) => `/permissions/permissions-sets/edit/${id}`,
      },
    },
  },
}
