import { JurisdictionDTO, StackDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'
import { Box } from '@mui/material'
import { ViewListLayout } from 'components/ui-layouts/viewListLayout/ViewListLayout'
import { ViewListLayoutHeader } from 'components/ui-layouts/viewListLayout/ViewListLayoutHeader'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { useState } from 'react'
import { Button } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ViewListLayoutContent } from 'components/ui-layouts/viewListLayout/ViewListLayoutContent'
import { Table } from 'components/ui-layouts/table/Table'
import { Tag } from 'components/ui-kit/tag/Tag'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { Chip } from 'components/ui-kit/chip/Chip'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { styled } from '@mui/styles'
import { ReactComponent as EmptyStateExperiencesIcon } from 'assets/icons/emptyState/EmptyStateExperiences.svg'
import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'
import { DeletePurposeStacksModal } from 'components/modals/stacks/DeletePurposeStacksModal'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  stacks: StackDTO[]
  isLoading: boolean
}
type ModalProps = {
  stack: StackDTO
  isOpen: boolean
}

const PREFIX = 'PurposeStacksList'
const classes = { chip: `${PREFIX}-chip` }
const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.chip}`]: {
    color: palette.persianBlue.main,
    backgroundColor: palette.marine.main,
  },
}))

const JurisdictionTooltip = ({ children, jurisdictions }: { children: any; jurisdictions: JurisdictionDTO[] }) => {
  if (jurisdictions && jurisdictions?.length > 1) {
    return (
      <Tooltip
        content={jurisdictions.map(({ name }) => (
          <Text component="div" sx={{ mb: 0.25 }} key={name}>
            {name}
          </Text>
        ))}
      >
        {children}
      </Tooltip>
    )
  }
  return children
}

export const PurposeStacksList: React.FC<Props> = ({ stacks, isLoading }) => {
  const [deletePurposeStacksModal, setDeletePurposeStacksModal] = useState<ModalProps>()
  const [searchText, setSearchText] = useState('')
  const navigate = useNavigate()
  const navigateToUpsert = (code = 'new') =>
    navigate(RoutesManager.deployment.purposeStacks.upsert.root.getURL({ code }))
  const navigateToView = (code?: string) => navigate(RoutesManager.deployment.purposeStacks.view.root.getURL({ code }))

  const visibleRows = searchText ? stacks.filter(stack => stack.name?.toLowerCase()?.includes(searchText)) : stacks
  const anyStacksExist = stacks && stacks?.length > 0

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Experiences', link: RoutesManager.deployment.experiencesV2.root.getURL() },
    { title: 'Purpose Stacks', link: RoutesManager.deployment.purposeStacks.root.getURL() },
  ]

  const EmptyState = () => (
    <Box display="flex" flexDirection="column" alignItems="center" mt={8.5} mb={6.75}>
      <Box mb={3.25}>
        <EmptyStateExperiencesIcon />
      </Box>
      <Text size={17} weight={600} sx={{ mb: 0.75 }}>
        {anyStacksExist
          ? 'There are no stacks matching your search query.'
          : 'There are no stacks for this organization'}
      </Text>
      {anyStacksExist ? null : (
        <>
          <Text size={12} sx={{ mb: 3 }}>
            Create your first purpose stack to populate the table.
          </Text>
          <Button size="large" onClick={() => navigateToUpsert()}>
            Create Stack
          </Button>
        </>
      )}
    </Box>
  )

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <StyledBox>
        <ViewListLayout isReady={!isLoading}>
          <ViewListLayoutHeader
            title={''}
            actions={
              <Box mt={0}>
                <Button onClick={() => navigateToUpsert()}>Create Stack</Button>
              </Box>
            }
            filters={<SearchInput size="small" placeholder="Search" onChange={setSearchText} value={searchText} />}
            borderBottom={'none'}
            paddingBottom={24}
            marginBottom={0}
          />
          <ViewListLayoutContent>
            {visibleRows.length > 0 ? (
              <Table
                items={visibleRows}
                onRowClick={({ id }) => navigateToView(id)}
                cellSettings={{
                  name: {
                    label: 'Name',
                    width: 450,
                    cellRenderer: ({ name }) => <Text weight={600}>{name}</Text>,
                  },
                  jurisdiction: {
                    label: 'Jurisdiction',
                    cellRenderer: ({ jurisdictions }) => {
                      return jurisdictions?.length ? (
                        <JurisdictionTooltip jurisdictions={jurisdictions}>
                          <Tag text={jurisdictions?.length > 1 ? 'Multiple' : jurisdictions[0].name} />
                        </JurisdictionTooltip>
                      ) : (
                        <EmptyValueRenderer />
                      )
                    },
                  },
                  groups: {
                    label: 'Groups',
                    cellRenderer: ({ groups }) =>
                      groups?.length && groups.length > 0 ? (
                        <Chip className={classes.chip}>{groups?.length}</Chip>
                      ) : (
                        <EmptyValueRenderer />
                      ),
                  },
                  lastUpdate: {
                    label: 'Last Update',
                    cellRenderer: ({ metadata }) => <FormattedDateRenderer date={metadata?.updatedAt} />,
                  },
                  more: {
                    width: 70,
                    cellRenderer: stack => (
                      <ButtonOptions
                        items={[
                          { content: 'Edit', onClick: () => navigateToUpsert(stack?.id) },
                          {
                            content: 'Delete',
                            onClick: () =>
                              setDeletePurposeStacksModal({
                                stack,
                                isOpen: true,
                              }),
                          },
                        ]}
                      />
                    ),
                  },
                }}
              />
            ) : (
              <EmptyState />
            )}
          </ViewListLayoutContent>
        </ViewListLayout>
      </StyledBox>
      {deletePurposeStacksModal?.isOpen && (
        <DeletePurposeStacksModal
          purposeStack={deletePurposeStacksModal?.stack}
          onCancel={() =>
            setDeletePurposeStacksModal({
              ...deletePurposeStacksModal,
              isOpen: false,
            })
          }
          onSubmit={async () => {
            setDeletePurposeStacksModal({
              ...deletePurposeStacksModal,
              isOpen: false,
            })
          }}
        />
      )}
    </>
  )
}
