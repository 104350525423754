import React from 'react'

import Box from '@mui/material/Box'

import { PreferenceField } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { UpsertExperienceEditableOverlay } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEditableOverlay'
import { useUpsertPreferenceStyles } from 'pages/consentAndRights/experiences/upsert/components/UpsertPreference/hooks'

import { UpsertExperiencePreviewPlaceholder } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewPlaceholder'

type Props = {
  getOverlayProps: (name: PreferenceField) => {
    active: boolean
    onClick: () => void
  }
}

export const FormTemplates: React.FC<Props> = ({ getOverlayProps }) => {
  const classes = useUpsertPreferenceStyles()

  return (
    <UpsertExperienceEditableOverlay
      className={classes.bodyDescription}
      {...getOverlayProps(PreferenceField.RIGHTS_FORM_SETTINGS)}
    >
      <Box display="flex" flexDirection="column">
        <UpsertExperiencePreviewPlaceholder>Form Template</UpsertExperiencePreviewPlaceholder>
      </Box>
    </UpsertExperienceEditableOverlay>
  )
}
