import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from '../../common/paginatedQuery'
import { ApiQueryKeys } from '../../common/queryKeys'
import { getInstalledDataSystemProcessingActivities } from '../fetchers/getInstalledDataSystemProcessingActivities'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.installedDataSystemProcessingActivities,
  queryFn: getInstalledDataSystemProcessingActivities,
  select: res => res?.data || {},
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useInstalledDataSystemProcessingActivities = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
