import React from 'react'
import { useNavigate } from 'react-router-dom'

// assets
import { ReactComponent as EmptyStateEventsIcon } from 'assets/icons/emptyState/EmptyStateEvents.svg'

// components
import Box from '@mui/material/Box'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ViewListLayoutContent } from 'components/ui-layouts/viewListLayout/ViewListLayoutContent'

// utils
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

// types
import { PERMISSIONS } from 'interfaces/permissions/permissions'

export const EventsListEmpty: React.FC = () => {
  // hooks
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const isPermittedToMapEvents = isPermitted([PERMISSIONS.WORKFLOW_ASSIGN])

  // handlers
  const handleCreateEvent = () => navigate(RoutesManager.orchestration.events.create.root.getURL())

  return (
    <Box my={4}>
      <ViewListLayoutContent>
        <EmptyState
          variant="list"
          icon={<EmptyStateEventsIcon />}
          title="No events assigned to the workflows"
          description={isPermittedToMapEvents ? 'Assign your first one to populate this section!' : null}
          actions={[
            {
              title: 'Manage Workflow Assignments',
              hidden: !isPermittedToMapEvents,
              size: 'big',
              onClick: handleCreateEvent,
            },
          ]}
        />
      </ViewListLayoutContent>
    </Box>
  )
}
