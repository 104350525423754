import React from 'react'

import {
  WorkflowExecutionStepStatus,
  WORKFLOW_EXECUTION_STEP_NAMES,
} from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { IssueDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { hasInvalidCredentialsIssue } from 'pages/orchestration/rightsQueue/utilsV2'
import { DataReviewStatusEnum } from 'interfaces/dataReview'
import { Box, Typography } from '@mui/material'
import { ActivityTimerChip } from 'pages/orchestration/rightsQueue/components/ActivityTimerChip'
import { getStatusTimeRemaining } from 'pages/orchestration/rightsQueue/components/utils/getStatusTimeRemaining'
import { Chip, Status, StatusState, StatusVariant } from '@ketch-com/deck'

type Props = {
  dataReviewStatus?: number
  issues?: IssueDTO[]
  status: WorkflowExecutionStepStatus
  dueAt?: number
  code?: string
}

const variantMap = {
  [WorkflowExecutionStepStatus.PENDING]: StatusState.draft,
  [WorkflowExecutionStepStatus.IN_PROGRESS]: StatusState.inProgress,
  [WorkflowExecutionStepStatus.SUCCESS]: StatusState.success,
  [WorkflowExecutionStepStatus.FAIL]: StatusState.error,
  [WorkflowExecutionStepStatus.INVALID_CREDENTIALS]: StatusState.warning,
  [WorkflowExecutionStepStatus.TERMINATED]: StatusState.error,
  [WorkflowExecutionStepStatus.SKIPPED]: StatusState.draft,
  [WorkflowExecutionStepStatus.NOT_STARTED]: StatusState.draft,
  [WorkflowExecutionStepStatus.MIXED]: StatusState.draft,
} as const

export const WorkflowExecutionStepStatusRenderer: React.FC<Props> = ({
  dataReviewStatus,
  status,
  issues,
  dueAt,
  code,
}) => {
  const isOverdue = dueAt ? getStatusTimeRemaining(dueAt).duration <= 0 : false

  return (
    <Box display="flex" alignItems="center" gap={1.5} rowGap={0.75} flexWrap="wrap">
      {code && (
        <Chip
          size="small"
          label={
            <Typography variant="smallLabel">
              Code: <Typography variant="smallBody">{code}</Typography>
            </Typography>
          }
        />
      )}
      {!dueAt ? (
        <Status
          icon="Dot"
          label={
            hasInvalidCredentialsIssue(status, issues)
              ? WORKFLOW_EXECUTION_STEP_NAMES[WorkflowExecutionStepStatus.INVALID_CREDENTIALS]
              : dataReviewStatus === DataReviewStatusEnum.DATA_REVIEW_STATUS_SUBMITTED
              ? 'Pending Review'
              : isOverdue
              ? 'Overdue'
              : WORKFLOW_EXECUTION_STEP_NAMES[status]
          }
          variant={StatusVariant.outlined}
          status={
            hasInvalidCredentialsIssue(status, issues)
              ? variantMap[WorkflowExecutionStepStatus.INVALID_CREDENTIALS]
              : isOverdue
              ? variantMap[WorkflowExecutionStepStatus.FAIL]
              : variantMap[status]
          }
        />
      ) : null}
      {dueAt ? <ActivityTimerChip includeTimerString dueAt={Number(dueAt)} /> : null}
    </Box>
  )
}
