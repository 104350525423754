import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIRequestParams } from 'api/common/utils'
import { GetPolicyResponseBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  code: string
}>

export const fetchPolicyV2 = ({ code }: Params) =>
  API.get<GetPolicyResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/policies/${code}`,
      params: {
        filters: {},
      },
    }),
  )
