import { Box, styled } from '@mui/material'
import { RequestsSubmittedHeader } from './components/RequestsSubmittedHeader'
import { RequestsSubmittedBanner } from './components/RequestsSubmittedBanner'
import { RequestsSubmittedSummary } from './components/RequestsSubmittedSummary'
import { RequestsSubmittedFooter } from './components/RequestsSubmittedFooter'
import { SelectableSection } from '../../../../../SelectableSection'
import { useContext } from 'react'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import {
  PreferenceExperienceBuilderSection,
  PreferenceThemeBuilderSection,
} from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'

const RequestsSubmittedTabBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '592px',
  justifyContent: 'space-between',
})

export const RequestsSubmittedTab: React.FC = () => {
  const { isTheme } = useContext(BuilderContext)

  return (
    <RequestsSubmittedTabBox>
      <Box>
        <SelectableSection
          section={
            isTheme
              ? PreferenceThemeBuilderSection.RequestsSubmittedHeader
              : PreferenceExperienceBuilderSection.RequestsSubmittedHeader
          }
          overlayProps={{
            margin: '-4px 0px',
          }}
        >
          <RequestsSubmittedHeader />
        </SelectableSection>
        <SelectableSection section={isTheme ? PreferenceThemeBuilderSection.RequestsSubmittedBanner : undefined}>
          <RequestsSubmittedBanner />
        </SelectableSection>
        <SelectableSection
          section={isTheme ? PreferenceThemeBuilderSection.RequestsSubmittedText : undefined}
          overlayProps={{ margin: '8px 0px' }}
        >
          <RequestsSubmittedSummary />
        </SelectableSection>
      </Box>
      <SelectableSection section={isTheme ? PreferenceThemeBuilderSection.RequestsSubmittedFooter : undefined}>
        <RequestsSubmittedFooter />
      </SelectableSection>
    </RequestsSubmittedTabBox>
  )
}
