import React from 'react'
import { useFormikContext } from 'formik'

import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { PreferenceField } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { UpsertExperienceEditableOverlay } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEditableOverlay'

import { useUpsertPreferenceStyles } from 'pages/consentAndRights/experiences/upsert/components/UpsertPreference/hooks'
import { RadioGroup } from 'components/ui-kit/radioGroup/RadioGroup'

import Typography from '@mui/material/Typography'
import { ensureArray } from 'utils/helpers/array'

type Props = {
  getOverlayProps: (name: PreferenceField) => {
    active: boolean
    onClick: () => void
  }
}

type RightType = 'get' | 'delete' | 'restrict' | 'update'

const optionsMap: Record<RightType, string> = {
  get: 'Provide Data',
  delete: 'Delete Data',
  restrict: 'Restrict Processing',
  update: 'Update Data',
}

export const Request: React.FC<Props> = ({ getOverlayProps }) => {
  const classes = useUpsertPreferenceStyles()

  const { values } = useFormikContext<PreferenceExperienceFormValues>()
  const { rights } = values

  const shouldShowDefault = rights.requestTitleType === 'default' && !!rights.showRequestDetailsTitle

  return (
    <UpsertExperienceEditableOverlay
      className={classes.bodyDescription}
      {...getOverlayProps(PreferenceField.RIGHTS_REQUEST)}
    >
      {/* Default Title */}

      {shouldShowDefault && (
        <Typography variant="h3" mb={3}>
          Request Details
        </Typography>
      )}

      {/* Custom Title */}

      {rights.requestTitleType === 'custom' && (
        <Typography variant="h3" mb={3}>
          {rights.requestDetailsTitle}
        </Typography>
      )}

      {/* Radio Buttons */}

      <RadioGroup
        sx={{
          my: 3,
        }}
        value="delete"
        name="dataCategory"
        layout="column"
        options={ensureArray(values?.rights?.canonicalRightsOrder)
          ?.map(right => {
            return {
              value: right,
              title: optionsMap?.[right as RightType] || 'Delete Data',
            }
          })
          .filter(Boolean)}
      />
    </UpsertExperienceEditableOverlay>
  )
}
