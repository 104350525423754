import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchBusinessFunction } from '../fetchers/fetchBusinessFunction'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.businessFunction,
  queryFn: fetchBusinessFunction,
  select: res => res?.data?.businessFunction || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useBusinessFunction = (config: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
