import type { HandleUploadParams, HandleDeleteParams } from './FormDropZone.types'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/ui-kit/toastr/Toastr'

/**
 * Handles the upload of files.
 *
 * @param params - The upload parameters.
 */
export const handleUpload = async ({ files, value, setValue, queryClient, onUploadComplete }: HandleUploadParams) => {
  setValue([...value, ...files]) // Combine current files and new files.
  if (onUploadComplete) onUploadComplete(files)
  await queryClient.refetchQueries(ApiQueryKeys.filesList) // Refetch file list queries after upload.
}

/**
 * Handles the deletion of a file.
 *
 * @param params - The deletion parameters.
 */
export const handleDelete = async ({
  attachment,
  required,
  value,
  maxFiles,
  handleDeleteFile,
  setValue,
  onDelete,
}: HandleDeleteParams) => {
  // If file upload is required, and only one file is currently uploaded, and multiple uploads are allowed, do not delete.
  if (required && value.length === 1 && maxFiles > 1) return

  try {
    await handleDeleteFile({
      params: {
        fileUrl: attachment.download_url,
      },
    })
    showToast({ content: `Removed file ${attachment.name}`, type: 'success' }) // Show success message.
    if (onDelete) onDelete(attachment) // Execute additional deletion logic, if provided.
  } catch (error) {
    showToast({ content: `Unable to remove file ${attachment.name}`, type: 'error' }) // Show error message.
  }
  setValue(value.filter(v => v.ID !== attachment.ID)) // Remove the deleted file from current files.
}
