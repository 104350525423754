import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetPrivacyProtocols200 } from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'

export const fetchPrivacyProtocols = () =>
  API.get<GetPrivacyProtocols200>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/privacy-protocols`,
      params: {
        filters: {},
      },
    }),
  )
