export const transponderAWSElasticBeanstalkInstructions = `

## Step 1 - Contact Support to get the Transponder Binary

Contact your Ketch support manager or email support@ketch.com to receive your transponder binary.

## Step 2 - Create a VPC

> This tutorial assumes you will create a new VPC to host Transponder. If you already have an existing VPC and would like to use that instead, skip to the next step.

This part of the tutorial is largely based on the AWS' [official guide](https://docs.aws.amazon.com/vpc/latest/userguide/create-vpc.html#create-vpc-and-other-resources). Refer to that guide for details and troubleshooting tips.

1. Go to the [VPC page](https://us-west-2.console.aws.amazon.com/vpcconsole/home) in the AWS web console
2. Click on the "Create VPC" button
3. Select the "VPC and more" option
4. Give the VPC a name
5. Use \`/22\` CIDR block, two availability zones, two public subnets, two private subnets, one NAT gateway per AZ, and an S3 gateway endpoint
6. Wait until the VPC is created; this will take a few minutes
7. Once the VPC is created, note down its ID and the public and private subnet IDs

## Step 3 - Create a Security Group

> The Transponder has a web console to help you manage it. This web console should be limited to your company's network. This tutorial assumes you will create a new security group and use inbound rules to limit which IP ranges can connect to the Transponder. Elastic Beanstalk deployment of the Transponder mandates that you specify a security group for the load balancer that fronts the web console. If you already have an existing security group and would like to use that instead, skip to the next step.

1. Go to the [VPC page](https://us-west-2.console.aws.amazon.com/vpcconsole/home) in the AWS web console
2. Click on the "Security groups" from the left-side navigation menu
3. Click on the "Create security group" button
4. Give the security group a name
5. Select the VPC created above
6. Click the "Add rule" button under the "Inbound rules" section
7. Use \`HTTPS\` type and provide appropriate CIDR for the source
8. Add more rules as needed
9. Click on the "Create security group" button
10. Once the security group is created, note down its ID

## Step 4 - Create a TLS certificate

> This tutorial assumes that you will create a brand new TLS certificate managed by AWS Certificate Manager. Elastic Beanstalk deployment of the Transponder mandates that you use AWS Certificate Manager to manage your TLS certificate for the Transponder. If you already have an existing TLS certificate managed by AWS Certificate Manager and would like to use that instead, skip to the next step.

Create a public certificate using AWS Certificate Manager. For details on how to request a certificate or any troubleshooting guides, refer to AWS' [official guide](https://docs.aws.amazon.com/acm/latest/userguide/gs-acm-request-public.html#request-public-console).

\`\`\`shell
$ aws acm request-certificate --domain-name <INSERT YOUR TRANSPONDER HOSTNAME HERE> --key-algorithm EC_prime256v1 --validation-method DNS
{
  "CertificateArn": "arn:aws:acm:us-west-2:<AWS_ACCOUNT_ID>:certificate/22213ab7-6cf3-4a50-8aec-5da2044a0794"
}
# Validate the certificate via DNS entry
\`\`\`

## Step 5 - Create a CloudFormation stack

### Determine Docker Engine Version

Visit the AWS' [official guide](https://docs.aws.amazon.com/elasticbeanstalk/latest/platforms/platform-history-docker.html) and find the latest Docker engine in v3. Then, search for \`SolutionStackName\` in the Cloud Formation Template to check the Docker engine specified in the template. If the Docker engine specified in the template file does not match the latest version in v3, update the file accordingly.

Make sure to use the version that runs Docker, not ECS.

### Create the Stack

1. Go to the [CloudFormation page](https://us-west-2.console.aws.amazon.com/cloudformation/home) in the AWS web console
2. Click on the "Create stack" button and select "With new resources" option
3. Upload the CloudFormation template YAML file
4. Click on the "Next" button
5. Give the stack a name
6. Enter all parameters. Refer to the table below for the details on the parameters
7. Click on the "Next" button
8. Optionally, if you wish to ensure that the stack is not accidentally deleted, you can enable termination protection by expanding the "Stack creation options" section and toggling the "Termination Protection" radio button to "Activated".
9. Click on the "Next" button
10. Review the parameters in the middle of the "Review and create" page
11. Check the acknowledge checkbox for the IAM role creation
12. Click on the "Submit" button
13. Wait until the CloudFormation stack is created; this will take about 15 minutes

## Step 6 - Create an Elastic Beanstalk environment

1. Go to the [Elastic Beanstalk page](https://us-west-2.console.aws.amazon.com/elasticbeanstalk/home) in the AWS web console
2. Click on the "Applications" from the left-side navigation menu
3. Select the application created above
4. Click on the "Saved configurations" from the left-side navigation menu under the application
5. Select the configuration and click on the "Launch environment" button
6. Select "Web server environment"
7. Give the environment a name
8. Select "Upload your code" in the "Application code" section
9. Upload the Elastic Beanstalk asset file and label the version following the version number in the file name (i.e. \`3.0.13\`)
10. Click on the "Next" button
11. On the next page, click on the "Skip to review" button
12. Verify the application code has the correct file and click on the "Submit" button
13. Wait until the CloudFormation stack is created; this will take about 15 minutes

## Step 7 - Verify the deployment

Visit the Transponder UI from your browser using the DNS entry created by Elastic Beanstalk. You will likely run into an HTTPS error because your TLS certificate does not match the hostname. For this tutorial, it is safe to ignore that error. For the production deployment, you want to create a CNAME DNS entry to link the Transponder hostname to the hostname generated by Elastic Beanstalk.

Furthermore, you can see the logs of the Transponder in the CloudWatch Logs. Check for the following log to verify everything is working fine:

\`\`\`json
{"level":"info","msg":"serving","time":"2023-12-25T00:00:00Z"}
\`\`\`

You now have a Transponder using Elastic Beanstalk!
`
