import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { Button } from '@ketch-com/deck'
import { SubscriptionControlDTO } from '@ketch-com/figurehead'
import { FormMode } from 'interfaces/formModes/formMode'
import { Box, Typography } from '@mui/material'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'

type Props = {
  subscriptionControl: SubscriptionControlDTO
}

export const SubscriptionControlViewActions: React.FC<Props> = ({ subscriptionControl }) => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const isPermittedToManageSubscriptions = isPermitted(PERMISSIONS.SUBSCRIPTIONS_WRITE)

  return (
    <Box display="flex" gap="12px" alignItems="center">
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="smallBody" color="darkDuskFaded.main">
          Last Updated
        </Typography>
        {subscriptionControl?.lastUpdated ? (
          <Typography color="darkDusk.main" variant="smallBody">
            {formatDateTimeFromUnix(subscriptionControl.lastUpdated)}
          </Typography>
        ) : (
          <Typography color="lightGrey.main" variant="smallBody">
            None
          </Typography>
        )}
      </Box>

      {isPermittedToManageSubscriptions && (
        <>
          <Button
            onClick={() => {
              if (subscriptionControl) {
                navigate(
                  RoutesManager.policyCenter.subscriptions.upsert.subscriptionControl.root.getURL({
                    code: subscriptionControl.code,
                    formMode: FormMode.EDIT,
                  }),
                )
              }
            }}
            color="secondary"
            size="large"
          >
            Edit
          </Button>
        </>
      )}
    </Box>
  )
}
