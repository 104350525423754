import { AppCapabilities } from './AppCapabilities'

export const AppCapabilitiesNames = {
  [AppCapabilities.UNSPECIFIED_APP_CAPABILITY]: 'Unspecified',
  [AppCapabilities.PERMIT_PROPAGATION_APP_CAPABILITY]: 'Permit Propagation',
  [AppCapabilities.PERMIT_INGESTION_APP_CAPABILITY]: 'Permit Ingestion',
  [AppCapabilities.RIGHTS_PROPAGATION_APP_CAPABILITY]: 'Rights Propagation',
  [AppCapabilities.RIGHTS_INGESTION_APP_CAPABILITY]: 'Rights Ingestion',
  [AppCapabilities.TAG_ORCHESTRATION_APP_CAPABILITY]: 'Tag Orchestration',
}
