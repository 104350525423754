import { Box, Typography, styled } from '@mui/material'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { useContext } from 'react'
import { SelectableSection } from '../../../../../SelectableSection'
import {
  BUILDER_CONSTANTS,
  PreferenceExperienceBuilderSection,
} from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { getIn } from 'formik'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { ItemStyle } from '@ketch-sdk/ketch-types'
import { clamp } from 'lodash'

const FooterBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
})

const ButtonBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '81px',
  minHeight: '44px',
  padding: '12px 24px',
})

export const PurposesFooter: React.FC = () => {
  const { themeConfig, experienceConfig, isTheme } = useContext(BuilderContext)

  // Theme config
  const actionButtonStyle = getIn(themeConfig, themeUpsertFormKeys.preferencePurposesTabFooterActionButtonStyle)
  const actionButtonBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferencePurposesTabFooterActionButtonBackgroundColor,
  )
  const actionButtonTextColor = getIn(themeConfig, themeUpsertFormKeys.preferencePurposesTabFooterActionButtonTextColor)

  const actionButtonCornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferencePurposesTabFooterActionButtonCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'

  // Experience config
  const actionButtonText = getIn(experienceConfig, experienceUpsertFormKeys.preferencePurposesTabActionButtonText)

  return (
    <FooterBox>
      <SelectableSection section={isTheme ? undefined : PreferenceExperienceBuilderSection.PurposesTabActionButton}>
        <ButtonBox
          sx={{
            background: actionButtonStyle === ItemStyle.Filled ? actionButtonBackgroundColor : '',
            border: `1.5px solid ${actionButtonBackgroundColor}`,
            borderRadius: actionButtonCornerRadius,
          }}
        >
          <Typography
            color={actionButtonTextColor}
            fontSize="14px"
            fontWeight={600}
            maxWidth={'200px'}
            sx={{ wordWrap: 'break-word' }}
          >
            {actionButtonText}
          </Typography>
        </ButtonBox>
      </SelectableSection>
    </FooterBox>
  )
}
