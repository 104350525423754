import { useEffect, useState } from 'react'

import { usePermitStatsOverall } from 'api/permitStatistics/queries/usePermitsStatsOverall'
import { useUserMe } from 'api/users/queries/useUserMe'
import { HeapClient } from 'interfaces/heap'
import moment from 'moment'
import { addYears } from 'date-fns'

export const useHeapIdentify = () => {
  const [isMounted, setIsMounted] = useState(false)
  const { data: user, isLoading: isLoadingUserMe } = useUserMe()

  // Get permits data
  const currentDate = new Date()
  const { data: permitStats, isLoading: isLoadingOverallPermitStats } = usePermitStatsOverall({
    enabled: !!currentDate,
    params: {
      startDate: `${moment(addYears(currentDate, -1)).format('YYYY-MM-DD')}`,
      endDate: `${moment(currentDate).format('YYYY-MM-DD')}`,
    },
  })

  const isLoadingAggregate = isLoadingUserMe || isLoadingOverallPermitStats

  useEffect(() => {
    const heapClient = (window as any)?.heap as HeapClient
    const shouldCallHeapIdentify = !isMounted && !!heapClient && !!user && !!permitStats && !isLoadingAggregate

    if (shouldCallHeapIdentify) {
      heapClient.identify(user.ID)

      // Permit has been received if consent count is greater than 0
      const isFirstPermitReceived = (permitStats?.consent || 0) > 0

      const payload = {
        planProductId: user.organization?.planProductID || 'not_specified',
        code: user.organization.code,
        email: user.email,
        id: user.ID,
        name: user.organization.name,
        isFirstPermitReceived,
      }

      heapClient.addUserProperties(payload)
      setIsMounted(true)
    }
  }, [setIsMounted, isMounted, user, permitStats, isLoadingAggregate])
}
