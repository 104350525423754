import React from 'react'

import { PurposesDropList } from './PurposesDropList'
import { WebTagsSourceDropList } from './WebTagsSourceDropList'
import { AppInstancesDropList } from './AppInstancesDropList'
import { useDebounce } from 'react-use'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getWebTagsListFilterState } from 'store/webTagsListFilterSlice/selectors'
import {
  setAppCode,
  setAppInstanceId,
  setEnabled,
  setPurposeId,
  clearSearchQuery,
  setSearchQuery,
  setProperty,
} from 'store/webTagsListFilterSlice'
import { TextInput, Icon } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { AppCode } from '../utils/enums'
import { PropertiesDropList } from './PropertiesDropList'
import { MaybeNull } from 'interfaces'
import { StatusDropList } from './StatusDropList'

type Props = {
  isFetching: boolean
  searchString: string
  setSearchString: (value: string) => void
}

export const WebTagsListFilters: React.FC<Props> = ({ isFetching, searchString, setSearchString }) => {
  const dispatch = useAppDispatch()
  const { appCode, appInstanceId, property, enabled, purposeId } = useAppSelector(getWebTagsListFilterState)
  const isPropertyFilterSelected = appCode === AppCode.Property

  useDebounce(
    () => {
      if (searchString) {
        dispatch(setSearchQuery(searchString))
      } else {
        dispatch(clearSearchQuery())
      }
    },
    500,
    [searchString],
  )

  const handleTagSourceChange = (value: MaybeNull<string>) => {
    dispatch(setAppCode(value || ''))
    // Reset app instance filter when app source is changed
    dispatch(setAppInstanceId(''))
  }

  return (
    <Box display="flex" gap={2}>
      <TextInput
        placeholder="Search"
        startIcon={<Icon name="OMag" />}
        size="small"
        inputWidth="250px"
        fullWidth={false}
        onChange={event => setSearchString?.(event.currentTarget.value)}
        value={searchString}
        endIcon={
          searchString && <Icon sx={{ cursor: 'pointer' }} name="OCross" onClick={() => setSearchString?.('')} />
        }
      />

      <WebTagsSourceDropList
        pending={isFetching}
        placeholder="Source"
        value={appCode}
        onChange={handleTagSourceChange}
      />

      {!!appCode &&
        (isPropertyFilterSelected ? (
          <PropertiesDropList value={property} onChange={value => dispatch(setProperty(value?.value || ''))} />
        ) : (
          <AppInstancesDropList
            pending={isFetching}
            placeholder={appInstanceId === '' ? 'All Source Connections' : ''}
            appCode={appCode}
            value={appInstanceId}
            valueKey="ID"
            onChange={value => dispatch(setAppInstanceId(value?.ID || ''))}
          />
        ))}

      <PurposesDropList
        pending={isFetching}
        value={purposeId}
        valueKey="ID"
        onChange={value => dispatch(setPurposeId(value?.ID || ''))}
      />

      <StatusDropList pending={isFetching} value={enabled} onChange={value => dispatch(setEnabled(value || ''))} />
    </Box>
  )
}
