import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CustomRightNameDTO } from '@ketch-com/figurehead'
import { Button, DataGrid, PopUp, TableCell } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  onClose: () => void
  data: CustomRightNameDTO
}
export const CustomRightNameJurisdictionModal: React.FC<Props> = ({ onClose, data }) => {
  const rows =
    data.jurisdictionRights?.map((jurisdiction, index) => ({
      id: index,
      right: jurisdiction.rightName,
      jurisdiction: jurisdiction.jurisdictionName,
      jurisdictionCode: jurisdiction.jurisdictionCode,
    })) || []

  const cols = [
    {
      width: 165,
      field: 'right',
      headerName: 'Right',
      flex: 1,
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell sx={{ marginTop: '5px', marginBottom: '5px' }}>
            <Typography variant="label">{params?.row?.right}</Typography>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 140,
      field: 'Jurisdiction',
      headerName: 'Jurisdiction',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return <NameAndCodeCellRenderer code={params.row.jurisdictionCode} name={params.row.jurisdiction} />
      },
      sortable: false,
    },
    {
      width: 180,
      field: 'Link',
      headerName: '',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell
            isRightAlign
            sx={{
              left: '12px',
              position: 'relative',
            }}
          >
            {params?.row?.right ? (
              <Button
                color="tertiary"
                onClick={() => {
                  window.open(
                    RoutesManager.policyCenter.policyScopes.view.root.getURL({ code: params.row.jurisdictionCode }),
                    '_blank',
                    'noreferrer',
                  )
                }}
              >
                Open In New Tab
              </Button>
            ) : null}
          </TableCell>
        )
      },
    },
  ]
  return (
    <PopUp
      title="Jurisdictions"
      variant="dialog"
      popUpWidth="550px"
      onClose={onClose}
      popUpActionChildren={
        <Button color="secondary" size="large" onClick={onClose}>
          Close
        </Button>
      }
    >
      <Typography variant="body" component={Box}>
        {`This Custom Right Name is used in the following active Jurisdiction${
          data.jurisdictionRights?.length! > 1 ? 's' : ''
        }:`}
      </Typography>

      <DataGrid
        columns={cols}
        rows={rows}
        hidePaginationRowsInfo={true}
        checkboxSelection={false}
        disableColumnResize
        disableColumnFilter
        disableColumnReorder
        disableColumnPinning
        disableAggregation
        disableRowGrouping
        hideFooter
        disableColumnMenu
        disableRowHoverStates
        loading={false}
        disableRowSelectionOnClick
        columnMenuProps={
          {
            slots: {
              columnMenuFilterItem: null,
              columnMenuAggregationItem: null,
              columnMenuGroupingItem: null,
            },
          } as any
        }
        getRowHeight={() => 'auto'}
      />
    </PopUp>
  )
}
