import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { TransponderFormValues } from 'interfaces/appliances'
import { useAppliance } from 'api/appliances/queries/useAppliance'
import { TranspondersUpsertView } from './TranspondersUpsertView'
import { useUpdateTransponder } from 'api/appliances/mutations/useUpdateTransponder'
import { RoutesManager } from 'utils/routing/routesManager'

export const TranspondersUpsertContainer: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { id } = useParams<{ id: string }>()

  const { data: appliance, isLoading: isLoadingAppliance } = useAppliance({
    params: {
      applianceId: id!,
    },
  })

  const onUpdateTransponderSuccess = async (/* updatedAppliance: ApplianceDTO */) => {
    await queryClient.refetchQueries([ApiQueryKeys.appliance, { applianceId: appliance?.applianceID }])
    navigate(RoutesManager.developers.transponders.detail.getURL({ id: appliance?.applianceID }))
    showToast({ content: 'Transponder updated', type: 'success' })
  }

  const { mutateAsync: handleUpdateTransponder } = useUpdateTransponder({
    onSuccess: () => onUpdateTransponderSuccess(/* data?.appliance */),
    onError: () => {
      showToast({ content: 'Failed to update transponder', type: 'error' })
    },
  })

  const onSubmit = async (values: TransponderFormValues) => {
    await handleUpdateTransponder({
      params: {
        formData: values,
        applianceId: id!,
      },
    })
  }

  return <TranspondersUpsertView isEditMode isReady={!isLoadingAppliance} appliance={appliance} onSubmit={onSubmit} />
}
