import { APIListRequestParams } from 'api/common/utils'
import { GetJurisdictionPurposesResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  code?: string
  languageCode?: string
}>

export const fetchJurisdictionPurposes = ({ code, languageCode }: Params) =>
  API.get<GetJurisdictionPurposesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/jurisdictions/${code}/purposes`,
      params: {
        filters: {
          start: 0,
          limit: 1000,
          ...(languageCode && { languageCode }),
        },
      },
    }),
  )
