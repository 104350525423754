import { ICoordinates } from 'pages/orchestration/workflows/edit/interfaces'
import * as CanvasConstants from 'pages/orchestration/workflows/edit/utils/constants'

export const getAbsoluteCoordinates = (stepRelativePosition: ICoordinates): ICoordinates => ({
  x: stepRelativePosition.x * CanvasConstants.GRID_CELL_WIDTH,
  y: stepRelativePosition.y * CanvasConstants.GRID_CELL_HEIGHT,
})

/**
 * Gets absolute coordinates in the bottom right quadrant (q4) of the grid.
 * @param stepRelativePosition
 */
export const getAbsoluteCoordinatesV2 = (stepRelativePosition: ICoordinates): ICoordinates => ({
  x: -stepRelativePosition.x * CanvasConstants.GRID_CELL_WIDTH,
  y: -stepRelativePosition.y * CanvasConstants.GRID_CELL_HEIGHT,
})
