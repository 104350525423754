export enum SupportedDetailViewWorkflowStepNames {
  ApiCall = 'API Call',
  DecisionSingle = 'Decision Single',
  DecisionMulti = 'Decision Multi',
  Task = 'Task',
  ImportContext = 'Import Context',
  IdentityVerification = 'Identity Verification',
  End = 'End Flow',
  // TODO:BAC - coming soon, will need to add to this list to add a detail view and also
  // add another if statement to  src/pages/orchestration/rightsQueue/stepDetails/WorkflowStepDetailsContainer.tsx

  // DataSubjectEmail = 'Data Subject Email',
  // Task = 'Task',
  // ExecuteInternalDsr = 'Execute Internal DSR',
}

// For some workflow step types, the step name is configurable.
export enum SupportedDetailViewWorkflowStepCodes {
  ApiCall = 'standard.ForwardEventWorkflow',
  DecisionSingle = 'decision_single',
  DecisionMulti = 'decision_multi',
  Task = 'standard.ManualTaskActivity',
  Email = 'standard.SendEmailActivity',
  DataSubjectEmail = 'standard.SendDataSubjectEmailActivity',
  SetPermit = 'standard.SetPermitActivity',
  Idv = 'standard.IdentityVerificationActivity',
  End = 'standard.EndWorkflow',
  InternalSystemDsr = 'internalSystemDSR',
}

// We need to fall back to looking up the step by its activityStepCode
export enum BasicSupportedDetailViewWorkflowStepCodes {
  ApiCall = 'standard.ForwardEventWorkflow',
  DecisionSingle = 'decision_single',
  DecisionMulti = 'decision_multi',
  Task = 'standard.ManualTaskActivity',
  End = 'standard.EndWorkflow',
}
