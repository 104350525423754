import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { Box } from '@mui/material'
import { useField } from 'formik'
import { FileRejection } from 'react-dropzone'
import { DropZone } from '@ketch-com/deck'
import { useUploadFiles } from 'api/files/mutations/useUploadFile'
import { useDeleteFile } from 'api/files/mutations/useDeleteFile'
import { useDownloadFile } from 'api/files/mutations/useDownloadFile'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { showToast } from 'components/modals/AlertComponent'
import { FormDropZoneProps } from './FormDropZoneWithUpload.types'
import {
  handleUpload,
  handleDelete,
  handleDownloadOnClick,
  STANDARD_SUPPORTED_FILETYPES,
} from './FormDropZoneWithUpload.utils'

import { FormInputLabel } from '../FormInputLabel'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { FileDataType, mapFileInfoDTOToDropZoneUploadedFileData } from './utils'
import { FormError } from '../FormError'

type UploadErrorType = {
  error: string
  file: File
}

const isErrorFileInfoDTO = (file: any): file is UploadErrorType => !!file?.error

export function FormDropZoneWithUpload({
  accept = STANDARD_SUPPORTED_FILETYPES,
  allowDuplicates = false,
  disabled,
  disableDelete = false,
  disableDownload = false,
  dropMessage,
  fileInfoDisplayVariant = 'standard',
  hideDropzoneOnUpload = false,
  hideErrorText = false,
  hideUploadedFilesView = false,
  label,
  locked = false,
  maxFiles = Infinity,
  name,
  onClickWhenLocked,
  onDelete,
  onFileDialogCancel,
  onUploadComplete,
  onUploadingStateChange,
  required = true,
  showUploadDates = false,
  showUploadSizes = false,
  truncateAttachmentNameChars = undefined,
  uploadContext,
  validate,
  validateOnTouch = true,
  isUploadOneFile = false,
  multiple = false,
  ...rest
}: FormDropZoneProps) {
  // Initialize hooks and variables

  const [{ value }, { error, touched }, { setValue }] = useField<FileInfoDTO[]>({ name, validate })
  const [uploadedFileDataAtUpload, setUploadedFileDataAtUpload] = useState<FileDataType | undefined>(undefined)
  const [dropzoneValue, setDropZoneValue] = useState<FileInfoDTO[]>([])

  useEffect(() => {
    if (typeof value === 'string') {
      setDropZoneValue([
        {
          public_url: value,
          name: '',
          ID: '',
          content_type: '',
          download_url: '',
          extension: '',
          info_url: '',
          size: '',
        },
      ])
    } else {
      setDropZoneValue(value)
    }
  }, [value])

  const queryClient = useQueryClient()

  const showError = !!error && touched
  const isRequiredAndOnlyFile = required && dropzoneValue.length === 1 && maxFiles !== 1
  const timeout = useRef(0)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadErrors, setUploadErrors] = useState<string[]>([])
  const [validationError, setValidationError] = useState<string>('')
  const [acceptedFilesNames, setAcceptedFilesNames] = useState<string[]>([])
  const [uploadSucces, setUploadSucces] = useState(false)

  useEffect(() => {
    onUploadingStateChange?.(isUploading)
  }, [isUploading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => () => window.clearTimeout(timeout.current), [])

  // Mutation hooks for uploading, deleting, and downloading
  const { mutateAsync: handleUploadFiles } = useUploadFiles()
  const { mutateAsync: handleDeleteFile, isLoading: isDeletingFile } = useDeleteFile({
    onSuccess: async () => await queryClient.refetchQueries(ApiQueryKeys.filesList),
  })
  const { mutateAsync: handleDownloadFile, isLoading: isDownloadingFile } = useDownloadFile()

  const onDropAccepted = async (acceptedFiles: File[]) => {
    const currentFileNames = dropzoneValue?.map(file => file.name) || []
    const acceptedFilesNames = acceptedFiles.map(file => file.name)
    setAcceptedFilesNames(acceptedFilesNames)
    const duplicateFileNames = allowDuplicates ? [] : acceptedFilesNames.filter(name => currentFileNames.includes(name))
    if (duplicateFileNames.length) {
      showToast({
        content: `You have already uploaded a file with name "${duplicateFileNames?.[0]}"`,
        type: 'error',
      })
      return
    }

    setIsUploading(true)

    const uploads: FileInfoDTO[] = []
    const errors: string[] = []

    try {
      const result = (await handleUploadFiles({
        params: {
          files: acceptedFiles,
          ...uploadContext,
        },
      }))!

      if (Array.isArray(result)) {
        result.forEach(elem => {
          if (!isErrorFileInfoDTO(elem)) {
            const { data } = elem
            uploads.push(data)
          } else {
            // if at least one of the upload tries failed we push the error to erros array
            errors.push(elem.error)
          }
        })
      } else {
        // error if csrf token cannot be retrieved
        if (result.error) {
          errors.push(result.error)
        }
      }
    } catch (e) {
      errors.push(e as string)
    }

    if (!errors.length) {
      setUploadSucces(true)
      showToast({ content: `${uploads.length === 1 ? 'File' : 'Files'} uploaded`, type: 'success' })
    } else {
      showToast({ content: `Failed to upload ${errors.length === 1 ? 'file' : 'files'}`, type: 'error' })
    }

    setUploadErrors(errors)

    timeout.current = window.setTimeout(() => {
      setUploadErrors([])
    }, 4000)
    timeout.current = window.setTimeout(() => {
      setUploadSucces(false)
      setAcceptedFilesNames([])
    }, 2000)

    const filesUploadDate = acceptedFiles.map(file => ({ [file.name]: { date: new Date(), size: file.size } }))
    setUploadedFileDataAtUpload(uploadData => Object.assign({ ...uploadData }, ...filesUploadDate))
    if (isUploadOneFile) {
      handleUpload({ files: uploads, value: [], setValue, queryClient, onUploadComplete })
    } else {
      handleUpload({ files: uploads, value: dropzoneValue, setValue, queryClient, onUploadComplete })
    }

    setIsUploading(false)
  }

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    let content = rejectedFiles?.[0]?.errors?.[0]?.message
    setValidationError(content)
    timeout.current = window.setTimeout(() => {
      setValidationError('')
    }, 1500)
    showToast({
      content,
      type: 'error',
    })
  }

  const isDisabled = disabled || isUploading

  const handleDragEnter = useCallback(
    (e: React.DragEvent<HTMLElement>) => {
      if (locked) {
        e.stopPropagation()
        e.preventDefault()
        if (onClickWhenLocked) onClickWhenLocked()
      }
    },
    [locked, onClickWhenLocked],
  )

  const onDeleteHandler = ({ value, attachment }: { attachment: FileInfoDTO; value: FileInfoDTO[] }): Promise<void> => {
    const currentUploadedFileData = { ...uploadedFileDataAtUpload }
    if (currentUploadedFileData[attachment.name]) {
      delete currentUploadedFileData[attachment.name]
    }

    setUploadedFileDataAtUpload(currentUploadedFileData)

    return handleDelete({
      attachment,
      value,
      handleDeleteFile,
      onDelete,
      maxFiles,
      required,
      setValue,
    })
  }

  const uploadedFileData = mapFileInfoDTOToDropZoneUploadedFileData(dropzoneValue)

  return (
    <Box width="100%">
      {!!label && <FormInputLabel label={label} required={required} />}
      <DropZone
        accept={accept}
        acceptedFilesNames={acceptedFilesNames}
        disabled={isDisabled || value.length === maxFiles}
        disableDelete={disableDelete}
        disableDownload={disableDownload}
        handleDownloadOnClick={attachment => handleDownloadOnClick({ attachment: attachment, handleDownloadFile })}
        handleDragEnter={handleDragEnter}
        hideDropzoneOnUpload={hideDropzoneOnUpload}
        hideUploadedFilesView={hideUploadedFilesView}
        isDeletingFile={isDeletingFile}
        isDownloadingFile={isDownloadingFile}
        isUploading={isUploading}
        maxSize={rest.maxSize}
        isOneFileRequired={isRequiredAndOnlyFile}
        maxFiles={maxFiles}
        onDelete={onDeleteHandler}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
        showUploadDates={showUploadDates}
        showUploadSizes={showUploadSizes}
        subtitle="You can drop a file here for quick upload or use the button below to locate it in your file manager."
        title="Upload File"
        uploadErrors={uploadErrors}
        uploadFilesData={uploadedFileData}
        uploadSucces={uploadSucces}
        valid={!!dropMessage ? false : !showError}
        validationError={validationError ? validationError : dropMessage || ''}
        value={dropzoneValue}
        multiple={multiple}
      />
      {!hideErrorText && showError && (
        <Box>
          <FormError msg={error} />
        </Box>
      )}
    </Box>
  )
}
