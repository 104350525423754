import { useAppliancesListFilters } from '../hooks'
import {
  ApplianceConnectionStatusName,
  applianceConnectionsStatusOptions,
} from 'interfaces/appliances/ApplianceConnectionStatus'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon, ListItemText } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { useState } from 'react'

export const AppliancesListFilters = () => {
  const [{ status, search }, setFilters] = useAppliancesListFilters()

  const selectedDroplistValue = applianceConnectionsStatusOptions.find(elem => elem === status)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const open = Boolean(anchorEl)
  const handleMenuItemClick = (_: React.MouseEvent<HTMLElement>, status: ApplianceConnectionStatusName) => {
    setFilters({ status })
    setAnchorEl(null)
  }
  const handleClose = () => setAnchorEl(null)

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <SearchTextInput
        size="small"
        showClearButton
        onChange={nextSearchString => setFilters({ search: nextSearchString })}
        value={search}
      />

      <Button
        size="medium"
        color="tertiary"
        onClick={handleButtonClick}
        endIcon={<Icon name={open ? 'OArrowCUp' : 'OArrowCDown'} />}
      >
        {selectedDroplistValue ? selectedDroplistValue : 'Status'}
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose}>
        {applianceConnectionsStatusOptions.map(option => (
          <ActionSheetItem
            key={option}
            selected={!!selectedDroplistValue && option === selectedDroplistValue}
            onClick={event => handleMenuItemClick(event, option)}
          >
            <ListItemText selected={!!selectedDroplistValue && option === selectedDroplistValue}>{option}</ListItemText>
          </ActionSheetItem>
        ))}
      </ActionSheetWrapper>
    </Box>
  )
}
