import { useAssetV2 } from 'api/assets/queries/useAssetV2'
import { useFormikContext } from 'formik'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import React, { useEffect, useState } from 'react'

export const useManageInferredLabelsInputUtils = ({ assetCode }: { assetCode: string }) => {
  const [labels, setLabels] = useState<ManageLabelModalLabel[]>([])
  const { setFieldValue, values } = useFormikContext<EditDatabaseFormValues>()

  const { data: assetSummary, isLoading: isLoadingAsset } = useAssetV2({
    params: {
      assetCode,
    },
  })

  useEffect(() => {
    const inferredLabels = assetSummary?.asset?.inferredLabels || []

    const currentLabels = [...inferredLabels].map(l => ({
      code: l?.code,
      value: l?.value || '',
      name: l?.name,
      isSystemLabel: l?.isSystemLabel,
    }))
    setLabels([...(currentLabels as ManageLabelModalLabel[])])
  }, [assetSummary, setLabels])

  React.useEffect(() => {
    setFieldValue('inferredLabels', labels)
  }, [labels, setFieldValue])

  const handleRemoveTag = (labelToRemoveUniqueIdentifier: string) => {
    setFieldValue(
      'labels',
      values.labels.filter(l => {
        const uniqueIdentifier = `${l?.code || ''}${l?.value || ''}${l?.name || ''}`
        return uniqueIdentifier !== labelToRemoveUniqueIdentifier
      }),
    )
    setLabels(labels =>
      labels.filter(l => {
        const uniqueIdentifier = `${l?.code || ''}${l?.value || ''}${l?.name || ''}`
        return uniqueIdentifier !== labelToRemoveUniqueIdentifier
      }),
    )
  }

  return {
    handleRemoveTag,
    labels,
    isLoading: isLoadingAsset,
  }
}
