import React, { useContext } from 'react'
import { DeploymentPlanPreviewContext } from '../context/DeploymentPlanPreviewContext'
import { ModalScreenPreview } from 'pages/consentAndRights/experiences/preview/components/modalPreview/ModalScreenPreview'
import { ensureHydratedThemeColors } from 'utils/helpers/ensureHydratedThemeColors'

type Props = {}

export const DeploymentPlanModalPreview: React.FC<Props> = () => {
  const { activeExperienceType, activePolicyScopeCode, data, mapping, mode } = useContext(DeploymentPlanPreviewContext)

  const { configuration } = mapping[activeExperienceType]!

  const config = configuration?.experiences?.consent?.modal!
  const purposes = configuration?.purposes || []
  const rights = configuration?.rights || []

  return (
    <ModalScreenPreview
      key={activePolicyScopeCode}
      mode={mode}
      theme={ensureHydratedThemeColors(configuration?.theme)}
      title={config.title}
      bodyTitle={config.bodyTitle}
      bodyDescription={config.bodyDescription}
      buttonText={config.buttonText}
      data={data}
      rights={rights}
      purposes={purposes}
      consent={{}}
      jitPurposes={[]}
    />
  )
}
