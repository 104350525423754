import React, { useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { TabSegmented, TabSegmentedGroup, theme } from '@ketch-com/deck'
import { dataSystemAssetsColumns } from './utils/DataSystemAssetsDataGridUtil'
import { DataSystemAssetsTable } from './DataSystemAssetsTable'
import { ListAssetItemDTO } from '@ketch-com/figurehead'
import { useAssetsTabFilterContext } from './context/AssetsTabFilterContext'
import { AssetsTabFilters } from './components/AssetsTabFilters'
import { getResourceType } from './utils/getResourceType'
import { DrillDownTracker } from './components/DrillDownTracker'
import { useDataSystemsContext } from '../context/DataSystemContext'
import { useManualClassificationVerification } from './hooks/useManualClassificationVerification'
import { AssetsTabRowEditModal } from './components/AssetsTabRowEditModal'

export const DataSystemAssetsTab: React.FC = () => {
  const {
    assetCategory,
    setAssetCategory,
    drillDownLineage,
    setParentResourceID,
    paginationModel,
    setPaginationModel,
    assetsDataSystems,
    isLoadingAssetsDataSystems,
    snapshotCurrentQueryDetails,
    handleClearDrilldown,
    searchQuery,
    handleSearchInputChange,
    filterData,
    setFilterData,
    sections,
    setCounts,
    filterButtonText,
    resetFilters,
    traverseLineage,
    showEditModal,
    setShowEditModal,
    dedupedDataSystemAssets,
    isLoadingInstalledDatasystemAssets,
    installedDatasystemPagination,
    refetchInstalledDatasystemAssets,
  } = useAssetsTabFilterContext()

  const { appDescriptor } = useDataSystemsContext()

  const megaFilterProps = useMemo(
    () => ({
      filterData,
      isLoadingAssetsDataSystems,
      setFilterData,
      sections,
      setCounts,
      filterButtonText,
      resetFilters,
    }),
    [filterData, isLoadingAssetsDataSystems, setFilterData, sections, setCounts, filterButtonText, resetFilters],
  )

  const { handleManualClassificationVerification } = useManualClassificationVerification(
    appDescriptor?.id!,
    refetchInstalledDatasystemAssets,
  )

  const [currentResourceLevel, setCurrentResourceLevel] = useState<string | undefined>(undefined)

  useEffect(() => {
    return () => {
      handleClearDrilldown()
    }
  }, [handleClearDrilldown])

  useEffect(() => {
    refetchInstalledDatasystemAssets()
  }, [refetchInstalledDatasystemAssets])

  useEffect(() => {
    if (assetsDataSystems.dataSystems?.[0]?.assetCategories?.[0].code) {
      setAssetCategory(assetsDataSystems.dataSystems?.[0]?.assetCategories?.[0].code)
      setCurrentResourceLevel(assetsDataSystems.dataSystems?.[0]?.assetCategories?.[0].code)
    }
  }, [assetsDataSystems.dataSystems, setAssetCategory])

  const columns = useMemo(
    () =>
      dataSystemAssetsColumns({
        resourceType: getResourceType(currentResourceLevel),
        setAssetCategory,
        setCurrentResourceLevel,
        setParentResourceID,
        snapshotCurrentQueryDetails,
        handleManualClassificationVerification,
        setShowEditModal,
      }),
    [
      currentResourceLevel,
      setAssetCategory,
      setCurrentResourceLevel,
      setParentResourceID,
      snapshotCurrentQueryDetails,
      handleManualClassificationVerification,
      setShowEditModal,
    ],
  )

  const isLoading = isLoadingAssetsDataSystems || isLoadingInstalledDatasystemAssets

  return (
    <Box maxWidth="838px" overflow="scroll">
      {drillDownLineage.length > 0 && (
        <AssetsTabFilters
          searchQuery={searchQuery}
          handleSearchInputChange={handleSearchInputChange}
          megaFilterProps={megaFilterProps}
        />
      )}
      <Box
        sx={{
          backgroundColor: drillDownLineage.length > 0 ? theme.palette.Black.o8 : theme.palette.Common.Container,
          paddingTop: drillDownLineage.length > 0 ? 1 : 0,
          paddingBottom: 2,
          paddingX: drillDownLineage.length > 0 ? 2 : 0,
          borderRadius: '16px',
          marginTop: drillDownLineage.length > 0 ? 1 : 0,
        }}
      >
        {drillDownLineage.length > 0 ? (
          <DrillDownTracker
            drillDownLineage={drillDownLineage}
            traverseLineage={traverseLineage}
            handleClearDrilldown={handleClearDrilldown}
            onClose={() => {
              setCurrentResourceLevel(assetsDataSystems.dataSystems?.[0]?.assetCategories?.[0].code)
            }}
          />
        ) : (
          <Box display="flex" justifyContent="space-between" paddingBottom={1}>
            <AssetsTabFilters
              searchQuery={searchQuery}
              handleSearchInputChange={handleSearchInputChange}
              megaFilterProps={megaFilterProps}
            />
            {assetCategory && (
              <Box>
                <TabSegmentedGroup
                  value={currentResourceLevel}
                  exclusive
                  disabled={isLoadingInstalledDatasystemAssets}
                  onChange={(_, nextVal) => {
                    setCurrentResourceLevel(nextVal)
                  }}
                >
                  {assetsDataSystems.dataSystems?.[0]?.assetCategories?.map(category => {
                    return (
                      <TabSegmented
                        key={category.code}
                        value={category.code!}
                        size="small"
                        onClick={() => {
                          setAssetCategory(category.code)
                          setParentResourceID(undefined)
                          setPaginationModel({
                            page: 0,
                            pageSize: 10,
                          })
                          handleSearchInputChange('')
                        }}
                      >
                        <Box display="flex" gap={0.5}>
                          <Typography variant="label">{getResourceType(category.code)}</Typography>
                          <Typography variant="fadedLabel">{category.count}</Typography>
                        </Box>
                      </TabSegmented>
                    )
                  })}
                </TabSegmentedGroup>
              </Box>
            )}
          </Box>
        )}

        <DataSystemAssetsTable
          columns={columns}
          rows={dedupedDataSystemAssets as ListAssetItemDTO[]}
          rowCount={installedDatasystemPagination?.totalCount}
          isLoading={isLoading}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          resourceType={getResourceType(currentResourceLevel)}
        />
      </Box>
      {showEditModal && (
        <AssetsTabRowEditModal
          canonicalResourceType={showEditModal.canonicalResourceType}
          resourceName={showEditModal.resourceName}
          classificationList={showEditModal.classificationList}
          classificationName={showEditModal.classificationName}
          classificationType={showEditModal.classificationType}
          refetchInstalledDatasystemAssets={refetchInstalledDatasystemAssets}
          onClose={() => {
            setShowEditModal(undefined)
          }}
        />
      )}
    </Box>
  )
}
