import React from 'react'

// api
import { getEventsListFilterState } from 'store/eventsListFilterSlice/selectors/getEventsListFilterState'
import { useCanonicalRight } from 'api/rights/queries/useCanonicalRight'

// components
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { Table } from 'components/ui-layouts/table/Table'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { TooltipV2 } from 'components/ui-kit/tooltip/TooltipV2'
import { Box } from '@mui/material'

// types
import { useAppSelector } from 'store/hooks/useAppSelector'
import { DataRoleType } from 'interfaces/events'
import { SubjectTypeBulletedListTooltipContent } from 'components/ui-kit/tooltip/components'
import { ResultingCustomEvent } from 'interfaces/rights/right'

type Props = {
  canonicalRightCode?: string
  onCancel?: () => void
}

export const RightsModal: React.FC<Props> = ({ canonicalRightCode, onCancel }) => {
  const { dataRole } = useAppSelector(getEventsListFilterState)
  const { data: right, isLoading: isRightsLoading } = useCanonicalRight({
    params: {
      dataRole: dataRole,
      code: canonicalRightCode,
      includeChildCustomEvents: true,
    },
  })

  return (
    <Dialog
      isLoading={isRightsLoading}
      title={right?.name || ''}
      subtitle={right?.description || ''}
      contentWidth={880}
      withTitleBorderBottom={true}
      cancelBtnLabel="Close"
      onCancelBtnClick={onCancel}
    >
      <Table
        items={
          right?.resultingCustomEvents?.sort((a: ResultingCustomEvent, b: ResultingCustomEvent) =>
            (a.rightName || '').localeCompare(b.rightName || ''),
          ) || []
        }
        cellSettings={{
          nameAndCode: {
            label: 'Rights',
            width: 250,
            cellRenderer: ({ rightName, rightCode }) => <NameAndCodeCellRenderer name={rightName} code={rightCode} />,
          },

          dataSubject: {
            label: 'Subject Type',
            width: 150,
            showCell: dataRole !== DataRoleType.DATA_ROLE_PROCESSOR,
            cellRenderer: ({ dataSubjectTypes }) =>
              !!dataSubjectTypes?.length && (
                <TooltipV2
                  arrow={true}
                  title={<SubjectTypeBulletedListTooltipContent dataSubjectTypes={dataSubjectTypes} />}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      backgroundColor: 'marine.main',
                      borderRadius: '100%',
                      color: 'royalBlue.main',
                      display: 'flex',
                      fontSize: 11,
                      fontWeight: 500,
                      height: '24px',
                      justifyContent: 'center',
                      width: '24px',
                    }}
                  >
                    {dataSubjectTypes?.length}
                  </Box>
                </TooltipV2>
              ),
          },
          description: {
            label: 'Description',
            cellRenderer: ({ rightDescription }) => <>{rightDescription}</>,
          },
        }}
      />
    </Dialog>
  )
}
