import { ThemeDTO, ThemeFormDTO } from 'interfaces/themes-v2/theme'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  themeCode: string
  organizationCode: string
  formData: ThemeFormDTO
}

type Response = { theme: ThemeDTO }

export const updateTheme = ({ organizationCode, themeCode, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/themes/${themeCode}`,
    }),
    formData,
  )
