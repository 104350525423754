import React from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import { makeStyles, createStyles } from '@mui/styles'
import { SxProps, Theme } from '@mui/material'

type StylesProps = {
  marginBottom: number
}

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        marginBottom: ({ marginBottom }: StylesProps) => marginBottom,

        '&:last-child': {
          marginBottom: 0,
        },
      },
    }),
  { name: 'FormRow' },
)

type Props = {
  children?: React.ReactNode
  className?: string
  marginBottom?: number
  sx?: SxProps<Theme>
}

export const FormRow: React.FC<Props> = ({ children, marginBottom = 32, className, sx, ...rest }) => {
  const classes = useStyles({ marginBottom })

  return (
    <Box {...rest} className={clsx(classes.root, className)} sx={sx}>
      {children}
    </Box>
  )
}
