import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchDataMapRegions } from '../fetchers/fetchDataMapRegions'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.dataMapRegions,
  queryFn: fetchDataMapRegions,
  select: res => res?.data?.regions || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useDataMapRegions = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
