import { ContentGroup, SettingsLayout, SettingsLayoutMenuItem } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useCallback, useEffect, useMemo } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useIsEntitled, useIsPermitted } from 'utils/hooks'
import { RoutesManager } from 'utils/routing/routesManager'

const developersRoutes = [
  {
    title: 'Hooks',
    path: RoutesManager.developers.hooks.list.pattern,
    permissions: [PERMISSIONS.HOOK_READ],
  },
  {
    title: 'Transponders',
    path: RoutesManager.developers.transponders.list.pattern,
    permissions: [PERMISSIONS.TRANSPONDER_READ],
  },
  {
    title: 'API Keys',
    path: RoutesManager.developers.apiKeys.list.pattern,
    permissions: [PERMISSIONS.API_KEY_READ],
  },
  {
    title: 'Webhooks',
    path: RoutesManager.developers.webhooks.list.pattern,
    permissions: [PERMISSIONS.WEBHOOK_READ],
  },
  {
    title: 'Authenticators',
    path: RoutesManager.developers.authenticators.list.pattern,
    permissions: [PERMISSIONS.AUTHENTICATION_READ],
  },
  {
    title: 'Token Verifiers',
    path: RoutesManager.developers.verifiers.list.pattern,
    permissions: [PERMISSIONS.AUTHENTICATION_READ],
  },
]

type RouteItemPath = SettingsLayoutMenuItem & {
  path: string
  permissions: Array<string>
}

export const Developers = () => {
  const { isPermitted } = useIsPermitted()
  const { isEntitled } = useIsEntitled()
  const isEntitledToVerifiedIdentities = isEntitled(ENTITLEMENTS.VERIFIED_IDENTITIES)
  const location = useLocation()
  const navigate = useNavigate()

  const onRouteMenuItemClick = useCallback(
    (r: RouteItemPath) => {
      navigate(r.path)
    },
    [navigate],
  )

  const routes: RouteItemPath[] = useMemo(
    () =>
      developersRoutes
        .filter(r => isPermitted(r.permissions, true))
        .filter(r => {
          if (r.path === RoutesManager.developers.verifiers.list.pattern && !isEntitledToVerifiedIdentities)
            return false
          return true
        })
        .map(r => {
          const isActive = location.pathname.includes(r.path)
          return {
            ...r,
            listItemProps: {
              selected: isActive,
            },
            onClickHandler: () => {
              onRouteMenuItemClick(r)
            },
          }
        }),
    [isEntitledToVerifiedIdentities, isPermitted, location.pathname, onRouteMenuItemClick],
  )

  useEffect(() => {
    if (location.pathname === RoutesManager.developers.root.pattern) {
      navigate(routes[0].path)
    }
  }, [location.pathname, navigate, routes])

  return (
    <Box display="flex" flexDirection="column" gap={3} flex={1} height="calc(100% - 24px - 64px);">
      <ContentGroup title="Developers" isDivider titleVariant="h1" variant="top-header" />
      <SettingsLayout
        menuItems={routes}
        sx={{
          '&.SettingsLayout-container': {
            flex: 1,
            height: 'fit-content',
            marginBottom: '16px',
            borderRadius: '11px !important',
            '& .SettingsLayout-menu': {
              top: '8px',
            },
          },
          '& .SettingsLayout-content': {
            borderRadius: '11px !important',
          },
        }}
      >
        <Outlet />
      </SettingsLayout>
    </Box>
  )
}
