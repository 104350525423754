import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { ContextVariableDTO } from '@ketch-com/figurehead'

type Params = {
  formData: {
    contextVariable: ContextVariableDTO
  }
}

type Response = { contextVariable: ContextVariableDTO }

export const createContextVariable = ({ formData }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: '/api/context-variables',
    }),
    formData,
  )
