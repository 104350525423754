import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { updateProcessingActivityStatus } from '../fetchers/updateProcessingActivityStatus'

const useCustomMutation = createUseMutation({
  mutationFn: updateProcessingActivityStatus,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateProcessingActivityStatus = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation, {})
}
