import React from 'react'
import { useParams } from 'react-router-dom'
import { UrlParams } from 'pages/assetManager/dsrConfig/interfaces'
import { FormMode } from 'interfaces/formModes/formMode'
import { useProcessingActivity } from 'api/processingActivities/queries/useProcessingActivity'
import { CreateProcessingActivities, EditProcessingActivities } from 'pages/policyCenter/processingActivities/upsert'

export const UpsertProcessingActivities: React.FC = () => {
  const { formMode, id } = useParams<UrlParams>()
  const isEditMode = formMode === FormMode.EDIT

  const { data: processingActivity, isLoading: isProcessingActivityLoading } = useProcessingActivity({
    enabled: isEditMode,
    params: { activityId: id || '' },
  })

  const isReady = !isProcessingActivityLoading

  return isEditMode ? (
    <EditProcessingActivities isReady={isReady} processingActivity={processingActivity} />
  ) : (
    <CreateProcessingActivities />
  )
}
