import { AppNavigationContext } from 'components/appLayout/appNavigation/context/AppNavigationContext'
import { useContext } from 'react'
import { SidebarRouteItem } from 'utils/routing/types'
import { SidebarItemWrapper } from '../../SidebarItemWrapper'
import { ListItem } from '@ketch-com/deck'
import { GatedNavigateStatus, useNavigateGated } from 'utils/routing/hooks/useNavigateGated'

type Props = {
  item: SidebarRouteItem
}

export const SidebarNestedItem: React.FC<Props> = ({ item }) => {
  const { setSidebarOpenSection } = useContext(AppNavigationContext)
  const navigateGated = useNavigateGated()

  const isSelected = window.location.pathname.includes(item.path)
  const onClick = () => {
    const { status } = navigateGated(
      item.path,
      item.permissions,
      item.entitlements,
      item.requireAllPermissions,
      item.requireAllEntitlements,
    )
    if (status === GatedNavigateStatus.Success && item.sectionId) setSidebarOpenSection(item.sectionId)
  }

  return (
    <SidebarItemWrapper selected={isSelected}>
      <ListItem
        title={item.title}
        onClick={onClick}
        sx={theme => ({
          backgroundColor: isSelected ? `${theme.palette.Highlight.Disabled} !important` : '',
          '& .MuiTypography-root': {
            fontWeight: isSelected ? '600 !important' : '',
            color: isSelected ? theme.palette.Highlight.PrimaryDark : '',
          },

          // These 3 styles make the text not jump as wraps/unwraps when the sidebar width changes
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          transition: 'width 0.3s ease',
        })}
      />
    </SidebarItemWrapper>
  )
}
