import { LabelChip, LabelChipColor, LabelChipVariant } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

interface Props {}

export const LabelChipExample: React.FC<Props> = props => {
  return (
    <>
      <Typography variant="h3" mb={2}>
        DECK Label Chip component
      </Typography>

      <Box display="flex">
        <Box display="flex" flexDirection="column" gap={2} flex={1}>
          {Object.values(LabelChipVariant).map(variant => {
            return Object.values(LabelChipColor).map(color => {
              return (
                <LabelChip
                  variant={variant}
                  title="Title"
                  color={color}
                  labelDetails="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,"
                  onConfirm={() => alert('confirm')}
                  onDismiss={() => alert('dismiss')}
                />
              )
            })
          })}
        </Box>
      </Box>
    </>
  )
}
