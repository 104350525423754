import {
  PrivacyProtocol,
  PrivacyProtocolConfigurationPatchRequestBody,
  PrivacyProtocolConfigurationPatchRequestBodyStatus,
  PrivacyProtocolConfigurationPutRequestBody,
  PrivacyProtocolConfigurationPutRequestBodyStatus,
  PrivacyProtocolConfigurationResponseBody,
  PrivacyProtocolConfigurationResponseBodyStatus,
} from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'

/***
 * Flip the status of a privacy protocol
 *
 * If status is none, it remains the same
 *
 * @param protocol
 */
export const flipStatus = (protocol: PrivacyProtocol) => {
  let newStatus

  // Decide new status
  switch (protocol.config?.status) {
    case PrivacyProtocolConfigurationResponseBodyStatus.enabled: {
      newStatus = PrivacyProtocolConfigurationResponseBodyStatus.disabled
      break
    }
    case PrivacyProtocolConfigurationResponseBodyStatus.disabled: {
      newStatus = PrivacyProtocolConfigurationResponseBodyStatus.enabled
      break
    }
    default: {
      newStatus = PrivacyProtocolConfigurationResponseBodyStatus.none
    }
  }
  protocol.config!.status = newStatus
  return protocol
}

/**
 * Get a status request body for some protocol status
 * @param status
 */
export const getStatusRequestBody = (status: PrivacyProtocolConfigurationResponseBodyStatus) => {
  let newStatus: PrivacyProtocolConfigurationPatchRequestBodyStatus

  // Decide new status
  switch (status) {
    case PrivacyProtocolConfigurationResponseBodyStatus.enabled: {
      newStatus = PrivacyProtocolConfigurationPatchRequestBodyStatus.enabled
      break
    }
    case PrivacyProtocolConfigurationResponseBodyStatus.disabled: {
      newStatus = PrivacyProtocolConfigurationPatchRequestBodyStatus.disabled
      break
    }
    default: {
      newStatus = PrivacyProtocolConfigurationPatchRequestBodyStatus.none
    }
  }
  return { status: newStatus } as PrivacyProtocolConfigurationPatchRequestBody
}

/**
 * Convert from denormalized to normalized config status
 * @param denormalizedStatus
 */
const normalizeStatus = (
  denormalizedStatus: PrivacyProtocolConfigurationResponseBodyStatus,
): PrivacyProtocolConfigurationPutRequestBodyStatus => {
  switch (denormalizedStatus) {
    case PrivacyProtocolConfigurationResponseBodyStatus.none:
      return PrivacyProtocolConfigurationPutRequestBodyStatus.none
    case PrivacyProtocolConfigurationResponseBodyStatus.disabled:
      return PrivacyProtocolConfigurationPutRequestBodyStatus.disabled
    default:
      return PrivacyProtocolConfigurationPutRequestBodyStatus.enabled
  }
}

/**
 * Convert from denormalized to normalized config type
 * @param config
 */
export const normalizeConfig = (
  config: PrivacyProtocolConfigurationResponseBody,
): PrivacyProtocolConfigurationPutRequestBody => {
  const newPurposeMap: any = {}
  Object.entries(config.purposeMapping).forEach(([protocolPurposeID, customerPurposes]) => {
    if (customerPurposes)
      newPurposeMap[protocolPurposeID] = customerPurposes.map(customerPurpose => customerPurpose.code)
  })

  return {
    ...config,
    status: normalizeStatus(config.status),
    jurisdictions: config.jurisdictions?.map(j => j.code) || [],
    vendors: config.vendors?.map(v => v.id || '') || [],
    googleVendors: config.googleVendors?.map(v => v.id || '') || [],
    purposeMapping: newPurposeMap,
  }
}
