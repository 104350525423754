import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { extendRightInvocation } from '../fetchers/extendRightInvocation'

const useCustomMutation = createUseMutation({
  mutationFn: extendRightInvocation,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useExtendRightInvocation = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation)
}
