import { APIListRequestParams } from 'api/common/utils'
import { GetDataSystemByPurposeResponseBodyDTO } from '@ketch-com/figurehead'

import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  purposeCode?: string
  systemId?: string
}>

export const fetchDataMapDataSystem = ({ purposeCode, systemId }: Params) =>
  API.get<GetDataSystemByPurposeResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/data-map/purposes/${purposeCode}/data-systems/${systemId}`,
      params: {
        filters: {},
      },
    }),
  )
