import React from 'react'
import useImage from 'use-image'
import { Image } from 'react-konva'

import { IAbsoluteCoordinates } from 'pages/orchestration/workflows/edit/interfaces'

interface ICanvasImageProps {
  svgURL: string
  position: IAbsoluteCoordinates
  width: number
  height: number
  useShadow?: boolean
  listening?: boolean
}

export const CanvasImage: React.FC<ICanvasImageProps> = ({ svgURL, position, width, height, useShadow, listening }) => {
  const [image] = useImage(svgURL)
  const shadowProps = useShadow
    ? {
        shadowColor: '#212121',
        shadowBlur: 8,
        shadowOffset: { x: 0, y: 0 },
        shadowOpacity: 0.5,
      }
    : {}

  return (
    <Image
      x={position.x}
      y={position.y}
      image={image}
      width={width}
      height={height}
      {...shadowProps}
      listening={listening}
    />
  )
}
