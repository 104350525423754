import { useNavigate } from 'react-router-dom'
import { ContentGroup, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { RoutesManager } from 'utils/routing/routesManager'
import { CustomRightNameDTO } from '@ketch-com/figurehead'
import { getCustomRightNameFormValues } from '../utils'
import { BasicDetailsFormSection } from '../components/BasicDetailsFormSection'
import { FormikProvider, useFormik } from 'formik'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { getUpsertBreadCrumbs, validationSchema } from './utils'

export const CustomRightNameUpsertCreate = ({
  customRightName,
  onSubmitCreate,
  isReady,
}: {
  customRightName: CustomRightNameDTO
  onSubmitCreate: (values: CustomRightNameDTO) => Promise<void>
  isReady: boolean
}) => {
  const navigate = useNavigate()
  const initialValues = getCustomRightNameFormValues(customRightName)

  const breadcrumbs = getUpsertBreadCrumbs(false)

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmitCreate,
  })

  const { submitForm, isSubmitting, values, validateForm, setFieldTouched } = formikProps

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          renderFormTitle={() => <ContentGroup title="Create Custom Right Name" titleVariant="h2" isDivider />}
          mode={UpsertLayoutMode.create}
          showStepper={false}
          onAccept={async () => {
            const errors = await validateForm()
            if (Object.keys(errors).length) {
              Object.keys(errors).forEach(errorKey => {
                setFieldTouched(errorKey, true)
              })
              return
            }

            submitForm()
          }}
          onCancel={() => navigate(RoutesManager.settings.customRightName.root.getURL())}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
          acceptActionButtonText="Save"
          cancelActionButtonText="Cancel"
        >
          <form autoComplete="off">
            <BasicDetailsFormSection values={values} isLoading={!isReady} />
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
