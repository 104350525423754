import { Button, Icon, theme } from '@ketch-com/deck'
import { Tooltip, Typography } from '@mui/material'
import { AppNavigationContext } from 'components/appLayout/appNavigation/context/AppNavigationContext'
import React, { useContext } from 'react'

export const HeaderCreateButton: React.FC = () => {
  const { setShowCreateOverlay } = useContext(AppNavigationContext)

  return (
    <Tooltip title={<Typography variant="label">Create | CTRL + K</Typography>}>
      <span>
        <Button
          id="create-button"
          variant={'iconCustomRounded'}
          onClick={() => setShowCreateOverlay(true)}
          sx={{ backgroundColor: `${theme.palette.Action.Primary} !important`, width: 28, height: 28, minWidth: 28 }}
        >
          <Icon name="OPlus" iconColor={theme.palette.White.o100} />
        </Button>
      </span>
    </Tooltip>
  )
}
