import { OBLIGATION_ENUM, WORKFLOW_TYPE_ENUM } from '../constants'

export const getObligationEffectByCanonicalRightCode = (canonicalRightCode: string): string => {
  switch (canonicalRightCode) {
    case WORKFLOW_TYPE_ENUM.DELETE:
      return OBLIGATION_ENUM.DELETE
    case WORKFLOW_TYPE_ENUM.GET:
      return OBLIGATION_ENUM.GET
    default:
      return OBLIGATION_ENUM.DELETE
  }
}
