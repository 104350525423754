import React from 'react'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material'

type Props = {
  onClick?: () => void
  sx?: SxProps
  children?: React.ReactNode
}

export const UpsertInlineLayoutPreview: React.FC<Props> = ({ onClick, sx, children }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'stretch',
        width: '100%',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
