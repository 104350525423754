import { GridRenderCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium'
import { AvatarSize, Button, Chip, DataGridProps, TableCell } from '@ketch-com/deck'
import { useContext } from 'react'
import { WorkflowExecutionDTO, WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { UserShortDTO } from 'interfaces/users/users'
import { WorkflowExecutionAssignee } from 'pages/orchestration/rightsQueue/components'
import { WorkflowExecutionStepStatusRendererV3 } from '../WorkflowExecutionStepStatusRendererV3'
import { getIsWorkflowStepDetailSupported } from 'pages/orchestration/rightsQueue/utils'
import {
  SupportedDetailViewWorkflowStepCodes,
  SupportedDetailViewWorkflowStepNames,
} from 'pages/orchestration/rightsQueue/stepDetails/interfaces'
import { useNavigate } from 'react-router-dom'
import {
  RightInvocationDTO,
  WorkflowExecutionPreviewStepDTO,
  WorkflowExecutionPreviewStepStatusDTO,
} from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { WorkflowExecutionStepActions } from '../stepActions/WorkflowExecutionStepActions'
import { WorkflowExecutionTaskStepActions } from '../stepActions/WorkflowExecutionTaskStepActions'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { fromAssignee } from 'utils/helpers/teamStringParser'
import { Box, Typography } from '@mui/material'
import { WorkflowExecutionStepActivityRenderer } from '../WorkflowExecutionStepActivityRenderer'
import { WorkflowActivity } from 'interfaces/workflowActivities/workflowActivity'
import { WorkflowDTO } from 'interfaces/workflows'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { RightsQueueViewContext } from 'pages/orchestration/rightsQueue/viewV2/context/RightsQueueViewContext'

export const filteredStyle = { '& *': { filter: 'opacity(65%)' } }

export interface FlattenedWorkflowExecutionStepPreviewDTO extends WorkflowExecutionPreviewStepDTO {
  rowIndex: number
  id?: string
  path?: string[]
  isGroup?: boolean
  hasMixedStatuses?: boolean
}

export const WorkflowDetailsActionsTableCell = ({
  workflowExecutionStep,
  rightInvocation,
  rightInvocationId,
  workflowExecution,
  stepIndex,
  workflowDefinition,
  isAppeal,
}: {
  workflowExecutionStep: WorkflowExecutionStepDTO
  rightInvocationId: string | undefined
  rightInvocation: RightInvocationDTO | null
  workflowExecution: WorkflowExecutionDTO | null
  stepIndex: number
  workflowDefinition: WorkflowDTO | null
  isAppeal: boolean
}) => {
  const navigate = useNavigate()
  const { dsrExecutionsV2 } = useContext(RightsQueueViewContext)

  const isWorkflowStepDetailSupported = getIsWorkflowStepDetailSupported({
    stepName: workflowExecutionStep?.stepName as unknown as SupportedDetailViewWorkflowStepNames,
    stepCode: workflowExecutionStep?.activityStepCode as unknown as SupportedDetailViewWorkflowStepCodes,
    rightInvocation: rightInvocation || undefined,
    stepIndex,
    dsrExecutionsV2,
    workflowDefinition,
    isAppeal,
  })

  /* new view button links to workflow execution step detail page */
  if (isWorkflowStepDetailSupported) {
    return (
      <Button
        color="tertiary"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()

          navigate(
            RoutesManager.orchestration.rightsQueue.stepDetails.root.getURL({
              id: rightInvocationId,
              stepId: workflowExecutionStep.stepID,
              workflowExecutionId: workflowExecution?.ID || '',
            }),
          )
        }}
      >
        View
      </Button>
    )
    /* Legacy view button that opens modal experience */
  } else {
    return (
      <WorkflowExecutionStepActions
        workflowExecution={workflowExecution}
        workflowExecutionStep={workflowExecutionStep as any}
        rightInvocation={rightInvocation || {}}
      />
    )
  }
}

export const workflowPreviewColumns = (
  users: UserShortDTO[],
  shouldShowAdminAction: boolean,
  rightInvocationId: string | undefined,
  rightInvocation: RightInvocationDTO | null,
  workflowExecution: WorkflowExecutionDTO | null,
  workflowActivities: WorkflowActivity[],
  workflowDefinition: WorkflowDTO | null,
  isAppeal: boolean,
): {
  columns: GridColDef<FlattenedWorkflowExecutionStepPreviewDTO>[]
  firstColumn: DataGridProps['groupingColDef']
} => {
  const steps = workflowExecution?.steps || []
  const getWorkflowExecutionStep = (stepId: string) => {
    return steps.find(({ stepID }) => stepID === stepId)
  }

  const firstColumn: DataGridProps['groupingColDef'] = {
    headerName: 'Activity',
    hideDescendantCount: true,
    flex: 1,
    minWidth: 355,
    valueGetter: (params: GridValueGetterParams<FlattenedWorkflowExecutionStepPreviewDTO>) => {
      const isStepSkippedOrNotStarted =
        params.row.status === WorkflowExecutionPreviewStepStatusDTO.SkippedWorkflowExecutionPreviewStepStatus ||
        params.row.status === WorkflowExecutionPreviewStepStatusDTO.PendingWorkflowExecutionPreviewStepStatus

      if (params.row.stepID) {
        return (
          <TableCell sx={isStepSkippedOrNotStarted ? filteredStyle : undefined}>
            <WorkflowExecutionStepActivityRenderer
              workflowExecutionStep={params.row as unknown as WorkflowExecutionStepDTO}
              workflowActivities={workflowActivities}
              workflowDefinition={workflowDefinition}
            />
          </TableCell>
        )
      } else {
        const text = params.row.stepName

        return text ? (
          <Chip
            label={
              <>
                Path: <Typography variant="label">{text}</Typography>
              </>
            }
          />
        ) : (
          'Path'
        )
      }
    },
  }

  let columns: GridColDef<FlattenedWorkflowExecutionStepPreviewDTO>[] = [
    {
      align: 'left',
      field: 'assignee',
      headerAlign: 'left',
      headerName: 'Assignee',
      minWidth: 300,
      maxWidth: 400,
      sortable: false,
      renderCell: ({ row: previewStep }: GridRenderCellParams<FlattenedWorkflowExecutionStepPreviewDTO>) => {
        const { currentDesignee, stepID } = previewStep
        if (!stepID) return null

        const isStepSkippedOrNotStarted =
          previewStep.status === WorkflowExecutionPreviewStepStatusDTO.SkippedWorkflowExecutionPreviewStepStatus ||
          previewStep.status === WorkflowExecutionPreviewStepStatusDTO.PendingWorkflowExecutionPreviewStepStatus

        const [, teamAssignee] = fromAssignee(currentDesignee || '')
        const stepAssignee = users.find(({ ID }) => ID === teamAssignee)
        return (
          <TableCell sx={isStepSkippedOrNotStarted ? filteredStyle : undefined}>
            {stepAssignee ? (
              <WorkflowExecutionAssignee assignee={stepAssignee} avatarSize={AvatarSize.medium} />
            ) : (
              <Chip size="small" label="Automated" />
            )}
          </TableCell>
        )
      },
    },

    {
      align: 'left',
      field: 'description',
      headerAlign: 'left',
      headerName: 'Description',
      minWidth: 300,
      maxWidth: 400,
      sortable: false,
      renderCell: ({ row: previewStep }: GridRenderCellParams<FlattenedWorkflowExecutionStepPreviewDTO>) => {
        const { stepDescription, stepID } = previewStep

        if (!stepID) return <Box height={63}>&nbsp;</Box>

        const isStepSkippedOrNotStarted =
          previewStep.status === WorkflowExecutionPreviewStepStatusDTO.SkippedWorkflowExecutionPreviewStepStatus ||
          previewStep.status === WorkflowExecutionPreviewStepStatusDTO.PendingWorkflowExecutionPreviewStepStatus

        if (stepDescription)
          return <TableCell sx={isStepSkippedOrNotStarted ? filteredStyle : undefined} title={stepDescription} />

        return (
          <TableCell sx={isStepSkippedOrNotStarted ? filteredStyle : undefined}>
            <EmptyValueRenderer />
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'status',
      headerAlign: 'left',
      headerName: 'Status',
      minWidth: 150,
      maxWidth: 200,
      sortable: false,
      renderCell: ({ row: previewStep }: GridRenderCellParams<FlattenedWorkflowExecutionStepPreviewDTO>) => {
        const { status, dueAt, stepID, hasMixedStatuses } = previewStep

        if (!stepID) return null

        return (
          <TableCell>
            <WorkflowExecutionStepStatusRendererV3
              status={
                hasMixedStatuses
                  ? WorkflowExecutionStepStatus.MIXED
                  : status || WorkflowExecutionPreviewStepStatusDTO.SkippedWorkflowExecutionPreviewStepStatus
              }
              dueAt={dueAt}
            />
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'action',
      width: 100,
      minWidth: 90,
      headerAlign: 'left',
      headerName: '',
      sortable: false,
      renderCell: ({ row: previewStep }: GridRenderCellParams<FlattenedWorkflowExecutionStepPreviewDTO>) => {
        const { stepID, rowIndex, status } = previewStep

        if (!stepID) return null

        const workflowExecutionStep = getWorkflowExecutionStep(stepID)
        if (!workflowExecutionStep) return null

        const isStepSkippedOrNotStarted =
          status === WorkflowExecutionPreviewStepStatusDTO.SkippedWorkflowExecutionPreviewStepStatus ||
          status === WorkflowExecutionPreviewStepStatusDTO.PendingWorkflowExecutionPreviewStepStatus

        if (isStepSkippedOrNotStarted) return null

        return (
          <TableCell>
            <WorkflowDetailsActionsTableCell
              workflowExecutionStep={workflowExecutionStep}
              rightInvocation={rightInvocation}
              rightInvocationId={rightInvocationId}
              workflowExecution={workflowExecution}
              stepIndex={rowIndex}
              workflowDefinition={workflowDefinition}
              isAppeal={isAppeal}
            />
          </TableCell>
        )
      },
    },
  ]

  if (shouldShowAdminAction) {
    const adminAction: GridColDef<FlattenedWorkflowExecutionStepPreviewDTO>[] = [
      {
        align: 'left',
        field: 'adminAction',
        minWidth: 100,
        maxWidth: 160,
        headerAlign: 'left',
        headerName: '(Ketch L1 Intervention)',
        sortable: false,
        renderCell: ({ row: previewStep }: GridRenderCellParams<FlattenedWorkflowExecutionStepPreviewDTO>) => {
          const { stepID } = previewStep
          if (!stepID) return null

          const workflowExecutionStep = getWorkflowExecutionStep(stepID)
          if (!workflowExecutionStep) return null

          return (
            <TableCell>
              <WorkflowExecutionTaskStepActions
                isAssigneeRequired={false}
                rightInvocation={rightInvocation || {}}
                workflowExecutionStep={workflowExecutionStep}
                workflowExecution={workflowExecution}
              />
            </TableCell>
          )
        },
      },
    ]
    columns = [...columns, ...adminAction]
  }

  return { columns, firstColumn }
}
