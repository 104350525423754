import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles, createStyles } from '@mui/styles'

import { Button } from 'components/ui-kit/button/Button'
import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { ReactComponent as QuestionMarkGrayIcon } from 'assets/icons/about_question_mark_gray.svg'
import { RelationshipsViewRoutes } from 'pages/assetManager/relationships/list/RelationshipsViewRoutes'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { TabsRoutes } from 'components/ui-kit/tabs/TabsRoutes'
import { Text } from 'components/ui-kit/typography/Text'
import { theme } from '@ketch-com/deck'
import { ViewLayoutContent } from 'components/ui-layouts/viewLayout/ViewLayoutContent'
import { ViewListLayout } from 'components/ui-layouts/viewListLayout/ViewListLayout'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

import { CreateRelationshipModal } from './components'
import { useRelationshipsViewUtils } from './hooks'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      headerContainer: {
        marginBottom: spacing(2),
        paddingBottom: spacing(3),
        borderBottom: `1px solid ${palette.iron.main}`,
      },
      questionMarkGrayIcon: {
        marginRight: spacing(2),
      },
      aboutTitleText: {
        color: palette.darkDusk.main,
        opacity: 0.5,
      },
      about: {
        width: 354,
        minHeight: 420,
        maxHeight: 420,
        padding: spacing(3),
        background: palette.white.main,
        borderRadius: 11,
        overflow: 'hidden',
      },

      contentContainer: {
        minHeight: '100%',
        padding: 14,
        backgroundColor: theme.palette.white.main,
        borderRadius: 11,
      },
      tabsWrapper: {
        position: 'relative',
        borderBottom: `1px solid ${theme.palette.iron.main}`,
        marginBottom: 24,
      },
    }),
  { name: 'RelationshipsView' },
)

type Props = {
  isReady?: boolean
  issuesMessage?: React.ReactNode
  versionsMessage?: React.ReactNode
}

export const RelationshipsView: React.FC<Props> = ({ isReady }) => {
  const { isPermitted } = useIsPermitted()
  const classes = useStyles()
  const {
    isCreateRelationshipModalOpen,
    setIsCreateRelationshipModalOpen,
    handleSubmit,
    tabs,
    searchString,
    setSearchString,
  } = useRelationshipsViewUtils()

  const isPermittedToCreateRelation = isPermitted(PERMISSIONS.ASSET_RELATIONSHIP_WRITE)

  return (
    <>
      <ViewListLayout>
        <ContentBoundaries>
          {/* Title Area */}

          <Box className={classes.headerContainer}>
            <Box mb={3}>
              <Text size={34} weight={800}>
                Relationship Mapping
              </Text>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {/* Search Input has been de-scoped. Setting opacity to 0 and disabling until BE is ready
              TODO:BAC - connect and un-hide when BE is ready */}
              <Box sx={{ opacity: 0 }}>
                <SearchInput
                  disabled
                  size="regular"
                  onChange={nextSearchString => {
                    setSearchString(nextSearchString)
                  }}
                  onClear={() => setSearchString('')}
                  value={searchString}
                />
              </Box>
              <Button
                disabled={!isPermittedToCreateRelation}
                tooltip={!isPermittedToCreateRelation ? 'You do not have permission to create a relationship' : ''}
                onClick={() => setIsCreateRelationshipModalOpen(true)}
              >
                Create Relation
              </Button>
            </Box>
          </Box>

          {/* Left Column: Relationship Tabs - Discovered, Created */}

          <Flex justifyContent="space-between">
            <Box mr={2} display="flex" flex={1} flexDirection="column" alignItems="stretch">
              {isReady ? (
                <ViewLayoutContent className={classes.contentContainer}>
                  <Box className={classes.tabsWrapper}>
                    <TabsRoutes tabs={tabs} size="regular" />
                  </Box>

                  <RelationshipsViewRoutes />
                </ViewLayoutContent>
              ) : (
                <Spinner />
              )}
            </Box>

            {/* Right Column: About Area */}

            <Box mr={1} className={classes.about}>
              <Box mb={3} display="flex" alignItems="center">
                <QuestionMarkGrayIcon className={classes.questionMarkGrayIcon} />
                <Text size={14} weight={600} className={classes.aboutTitleText}>
                  About
                </Text>
              </Box>

              <Box mb={3}>
                <Text size={14} weight="normal">
                  Here you can find all relations of attributes to each other structured as Foreign to Primary.
                </Text>
              </Box>

              <Box mb={0.5}>
                <Text size={14} weight={600} className={classes.aboutTitleText}>
                  Navigation
                </Text>
              </Box>

              <Box mb={3}>
                <Text size={14} weight="normal">
                  The <strong>Created tab</strong> shows all relations created on the Ketch Platform manually.
                </Text>
              </Box>

              <Box mb={3}>
                <Text size={14} weight="normal">
                  The <strong>Discovered tab</strong> contains relations that were channeled here via a Transponder.
                </Text>
              </Box>

              <Box mb={0.5}>
                <Text size={14} weight={600} className={classes.aboutTitleText}>
                  Data Type
                </Text>
              </Box>

              <Box>
                <Text size={14} weight="normal">
                  Attributes can relate to each other only if they contain the same type of data.
                </Text>
              </Box>
            </Box>
          </Flex>
        </ContentBoundaries>
      </ViewListLayout>

      {/* Create Relation Modal */}

      {isCreateRelationshipModalOpen ? (
        <CreateRelationshipModal
          onSubmit={handleSubmit}
          onCancel={() => {
            setIsCreateRelationshipModalOpen(false)
          }}
        />
      ) : null}
    </>
  )
}
