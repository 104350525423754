import { ContextVariableDTO } from '@ketch-com/figurehead'
import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { Button } from '@ketch-com/deck'
import { FormDataInputValue } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'
import { ApiCallContext } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/context/ApiCallContext'
import { BooleanValueInput, ContextVariableListValueInput, NumberValueInput, TextValueInput } from '.'

type Props = {
  contextVariable?: ContextVariableDTO
  onCancel: () => void
  onSubmit: () => void
  formValue: FormDataInputValue
  setFormValue: (v: FormDataInputValue) => void
}
export const ContextVariableInlineForm: React.FC<Props> = ({
  contextVariable,
  onCancel,
  formValue,
  setFormValue,
  onSubmit,
}) => {
  const { isSubmittingWebhookContext } = useContext(ApiCallContext)
  const form =
    contextVariable?.type === 1 ? (
      <ContextVariableListValueInput
        contextVariable={contextVariable}
        formValue={formValue}
        setFormValue={setFormValue}
      />
    ) : contextVariable?.type === 2 ? (
      <NumberValueInput formValue={formValue} setFormValue={setFormValue} />
    ) : contextVariable?.type === 3 ? (
      <BooleanValueInput formValue={formValue} setFormValue={setFormValue} />
    ) : contextVariable?.type === 4 ? (
      <TextValueInput formValue={formValue} setFormValue={setFormValue} />
    ) : null

  const shouldDisableButton = !form || formValue === undefined

  return (
    <Box display="flex" flexDirection="column" gap={1.5}>
      {form && <Box>{form}</Box>}
      <Box display="flex" alignItems="center" gap={1}>
        <Button pending={isSubmittingWebhookContext} color="secondary" aria-label="close" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          pending={isSubmittingWebhookContext}
          disabled={shouldDisableButton}
          aria-label="update context variable"
          onClick={onSubmit}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}
