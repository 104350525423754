import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { Formik } from 'formik'
import { FormikHelpers } from 'formik'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { useQueryClient } from 'react-query'
import { useUpdateWorkflowExecutionStep } from 'api/workflowExecutions/mutations/useUpdateWorkflowExecutionStep'
import { IdentityVerificationContext } from '../../../context/IdentityVerificationContext'
import { getIdentityVerificationAvailablePathsStringValue } from '../../../utils'
import { showToast } from 'components/modals/AlertComponent'
import { InfoRow, TextInput } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type MyFormValues = {
  resolutionDetails: string
}

type Props = {}

export const ResolutionNotes: React.FC<Props> = () => {
  const queryClient = useQueryClient()
  const [isEditMode, setIsEditMode] = useState(false)
  const { workflowStep, stepDetails, workflowExecutionId, auth, canEdit } = useContext(
    RightInvocationWorkflowStepDetailViewContext,
  )

  const { resolveFormik } = useContext(IdentityVerificationContext)

  const hasResolutionNotes = stepDetails?.executionData?.manualTask?.hasResolutionNotes || false
  const resolutionDetails = stepDetails?.manualInterventionData?.resolutionDetails || ''

  const showNotesRequired = hasResolutionNotes && !stepDetails?.manualInterventionData?.resolutionDetails

  const initialValues = {
    resolutionDetails,
  }

  const { mutateAsync: updateWorkflowExecutionStep } = useUpdateWorkflowExecutionStep({
    onSuccess: async () => {
      await queryClient.refetchQueries([
        ApiQueryKeys.workflowExecutionStepDetailsV2,
        { workflowExecutionId, stepId: workflowStep?.ID },
      ])

      await queryClient.refetchQueries([
        ApiQueryKeys.workflowExecutionStepDetails,
        { workflowExecutionId, stepId: workflowStep?.ID, organizationCode: auth?.userData?.organizationCode },
      ])
      showToast({ content: 'Updated workflow step', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update workflow step', type: 'error' })
    },
  })

  async function handleOnSubmit(values: MyFormValues, formikHelpers: FormikHelpers<MyFormValues>): Promise<any> {
    const availablePaths = getIdentityVerificationAvailablePathsStringValue(stepDetails)

    try {
      await updateWorkflowExecutionStep({
        params: {
          workflowExecutionId,
          stepId: workflowStep?.ID,
          formData: {
            manualInterventionData: {
              resolutionDetails: values.resolutionDetails || '',
              decisionBoolean: availablePaths === 'verified' ? true : false,
            },
          },
        },
      })
      resolveFormik?.setFieldValue('resolutionDetails', values.resolutionDetails)
    } catch (error) {
      console.error('error', error)
    }
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleOnSubmit} validateOnMount>
      {({ values, setFieldValue, submitForm, isSubmitting }) => {
        return (
          <InfoRow
            title="Notes"
            isEditable={canEdit}
            isEditing={isEditMode}
            isLoading={isSubmitting}
            onEditChange={() => {
              setIsEditMode(true)
            }}
            onAcceptChange={async () => {
              setIsEditMode(false)
              await submitForm()
            }}
            onCancelChange={() => {
              setIsEditMode(false)
              setFieldValue('resolutionDetails', resolutionDetails)
            }}
            isEmpty={!resolutionDetails}
          >
            {isEditMode ? (
              <Box display="flex" alignItems="center">
                <TextInput
                  autoFocus
                  placeholder="Notes..."
                  fullWidth
                  value={values.resolutionDetails}
                  defaultValue={resolutionDetails}
                  onChange={e => setFieldValue('resolutionDetails', e.currentTarget.value)}
                  name="resolutionDetails"
                  required={showNotesRequired}
                  variant="ghost"
                />
              </Box>
            ) : resolutionDetails ? (
              resolutionDetails
            ) : (
              <EmptyValueRenderer />
            )}
          </InfoRow>
        )
      }}
    </Formik>
  )
}
