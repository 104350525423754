import React from 'react'
import clsx from 'clsx'
import { Dialog as ModalDialog, DialogTitle, DialogContent, DialogActions, Box } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'

import { ButtonColorsType, Button } from '@ketch-com/deck'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { Text } from 'components/ui-kit/typography/Text'

const useStyles = makeStyles(
  ({ typography, palette, spacing }) =>
    createStyles({
      baseModal: {
        fontFamily: typography.fontFamily,
        borderRadius: 5,
        letterSpacing: '0.01em',
        width: '100%',
        maxWidth: ({ contentWidth }: { contentWidth: number }) => contentWidth,
        padding: '18px 24px',
        background: palette.white.main,
      },
      baseModalTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
      },
      baseModalCloseIcon: {
        fontSize: typography.pxToRem(24),
        color: palette.darkDusk.main,
        cursor: 'pointer',

        '&:hover': {
          opacity: 0.7,
        },
      },
      baseModalContent: {
        padding: '24px 0',
        minHeight: 75,
        boxSizing: 'border-box',
      },
      removeTopContentPadding: {
        paddingTop: 0,
      },
      baseModalContentPending: {
        minHeight: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      baseModalActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 0,
      },
      contentWrapper: {},
      borderBottom: {
        borderBottom: `1px solid ${palette.iron.main}`,
        paddingBottom: spacing(1.375),
      },
      withActionsBorderTop: {
        paddingTop: spacing(2),
        borderTop: `1px solid ${palette.iron.main}`,
      },
    }),
  { name: 'Dialog' },
)

interface Props {
  children?: React.ReactNode
  /** Display loading indicator instead of content and disable buttons when this variable is true */
  isLoading?: boolean
  /** Disable buttons when this variable is true */
  isSaving?: boolean
  /** Disable submit button when this is false */
  isValid?: boolean
  /** Flag for hiding submit button  */
  shouldHideSubmitButton?: boolean
  /** Flag for hiding cancel button but still showing close "X" in upper right corner */
  shouldHideCancelButton?: boolean
  /** Toggle border bottom style under title/subtitle */
  withTitleBorderBottom?: boolean
  /** Toggle border bottom style above action buttons */
  withActionsBorderTop?: boolean
  /** Toggle border bottom style above action buttons */
  removeTopContentPadding?: boolean
  /** Custom Modal className */
  className?: string
  /** Content wrapper className */
  baseModalContentClassName?: string
  /** Modal Dialog title */
  title: React.ReactNode
  /** Modal Dialog title */
  subtitle?: React.ReactNode
  /** Max width of modal window until scroll appears */
  contentWidth?: number
  /** Text to be displayed for submit button */
  submitBtnLabel?: string
  /** Text to be displayed for cancel button */
  cancelBtnLabel?: string
  /** Text to be displayed for submit button */
  declineAllBtnLabel?: string
  /** Submit button display type */
  submitBtnVariant?: ButtonColorsType
  /** Cancel button display type */
  cancelBtnVariant?: ButtonColorsType
  /** Submit button action type */
  onSubmitBtnClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void> | void
  /** Cancel button action type */
  onCancelBtnClick?: (event: React.MouseEvent<HTMLButtonElement | SVGSVGElement, MouseEvent>) => void
  /** Cancel button action type */
  onDeclineAllBtnClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void> | void
  /** Flag for showing modal */
  isOpen?: boolean
}

/**
 * -
 */
export const Dialog: React.FC<Props> = ({
  cancelBtnLabel = 'Cancel',
  cancelBtnVariant = 'secondary',
  children,
  className,
  contentWidth = 550,
  declineAllBtnLabel,
  withTitleBorderBottom = false,
  isLoading = false,
  isSaving = false,
  isValid = true,
  onCancelBtnClick,
  onDeclineAllBtnClick,
  onSubmitBtnClick,
  shouldHideSubmitButton = false,
  shouldHideCancelButton = false,
  submitBtnLabel = 'Save',
  submitBtnVariant = 'primary',
  subtitle = '',
  title = '',
  withActionsBorderTop = false,
  baseModalContentClassName = '',
  removeTopContentPadding = false,
  isOpen = true,
}) => {
  const classes = useStyles({ contentWidth })
  const modalClassName = clsx(classes.baseModal, className)

  return (
    <ModalDialog
      open={isOpen}
      PaperProps={{
        classes: {
          root: modalClassName,
        },
      }}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      {isLoading ? (
        <DialogContent className={classes.baseModalContentPending}>
          <Spinner />
        </DialogContent>
      ) : (
        <>
          <Box
            sx={{
              borderBottom: ({ palette }) => (withTitleBorderBottom ? `1px solid ${palette.iron.main}` : 'none'),
              pb: withTitleBorderBottom ? 1.375 : 0,
            }}
            display="flex"
            flexDirection="column"
          >
            <DialogTitle className={classes.baseModalTitle}>
              <Text size={20} weight={700} color="black">
                {title}
              </Text>

              {onCancelBtnClick && <CloseIcon onClick={onCancelBtnClick} className={classes.baseModalCloseIcon} />}
            </DialogTitle>

            {subtitle && (
              <Box mt={1}>
                <Text size={14} color="grey">
                  {subtitle}
                </Text>
              </Box>
            )}
          </Box>

          <DialogContent
            className={clsx(classes.baseModalContent, baseModalContentClassName, {
              [classes.removeTopContentPadding]: removeTopContentPadding,
            })}
          >
            <div className={classes.contentWrapper}>{children}</div>
          </DialogContent>

          <DialogActions
            className={clsx(classes.baseModalActions, {
              [classes.withActionsBorderTop]: withActionsBorderTop,
            })}
          >
            {!shouldHideCancelButton && onCancelBtnClick && (
              <Button
                id={cancelBtnLabel?.toLowerCase()}
                color={cancelBtnVariant}
                size="large"
                pending={isLoading || isSaving}
                onClick={onCancelBtnClick}
              >
                {cancelBtnLabel}
              </Button>
            )}

            {onDeclineAllBtnClick && (
              <Button
                id={declineAllBtnLabel?.toLowerCase()}
                color="secondary"
                size="large"
                pending={isLoading || isSaving}
                onClick={onDeclineAllBtnClick}
              >
                {declineAllBtnLabel}
              </Button>
            )}

            {!shouldHideSubmitButton && onSubmitBtnClick && (
              <Button
                id={submitBtnLabel?.toLowerCase()}
                color={submitBtnVariant}
                size="large"
                pending={isSaving}
                disabled={!isValid}
                onClick={onSubmitBtnClick}
              >
                {submitBtnLabel}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </ModalDialog>
  )
}
