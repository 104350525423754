import { DataSystemV2DTO, InstalledDataSystemRelationshipTypeV2DTO } from '@ketch-com/figurehead'

export interface DiscoveredSystemsInstallFormValues {
  selectedDataSystems: DataSystemV2DTO[]
  description: string
  type: InstalledDataSystemRelationshipTypeV2DTO
}

export const initialValues: DiscoveredSystemsInstallFormValues = {
  selectedDataSystems: [],
  description: 'Automatically discovered data system.',
  type: InstalledDataSystemRelationshipTypeV2DTO.PartnerInstalledDataSystemRelationshipTypeV2,
}
