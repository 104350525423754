import { useParams, useNavigate } from 'react-router-dom'
import { useApplication } from 'api/applications/queries/useApplication'
import { useApplicationVersion } from 'api/applications/queries/useApplicationVersion'
import { useApplicationVersions } from 'api/applications/queries/useApplicationVersions'
import { useThemes } from 'api/themes-v3/queries/useThemes'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { RoutesManager } from 'utils/routing/routesManager'
import { ApplicationView } from 'pages/consentAndRights/applications/view/ApplicationView'

export const ApplicationViewContainer = () => {
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()
  const { queries } = useQueryParams<{ version?: string }>()
  const { version } = queries

  const handleRedirectToApplicationsList = () => {
    navigate(RoutesManager.deployment.applications.root.getURL())
  }

  const { data: application, isLoading: isApplicationLoading } = useApplication({
    enabled: !version,
    params: {
      applicationCode: code!,
      includeIssues: true,
      includeMetadata: true,
    },
    onError: handleRedirectToApplicationsList,
  })

  const { data: applicationVersion, isLoading: isApplicationVersionLoading } = useApplicationVersion({
    enabled: !!version,
    params: {
      applicationCode: code!,
      version: version!,
    },
    onError: handleRedirectToApplicationsList,
  })

  const { data: versions, isLoading: isVersionsLoading } = useApplicationVersions({
    params: {
      applicationCode: code!,
      includeMetadata: true,
      ordering: {
        version: 'desc',
      },
    },
  })

  const { data: themes, isLoading: isThemesLoading } = useThemes()

  const isReady =
    !isThemesLoading && !isVersionsLoading && version ? !isApplicationVersionLoading : !isApplicationLoading

  return (
    <ApplicationView
      isReady={isReady}
      application={version ? applicationVersion : application}
      versions={versions}
      themes={themes}
    />
  )
}
