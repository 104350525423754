import { DsrConfigFormData, RelatedPathsAndAssets } from 'pages/assetManager/dsrConfig/interfaces'
import {
  CreatePatchDSRConfigsRequestBodyDTO,
  DatasetDSRConfigurationDTO,
  RelationshipDTO,
  AttributeMappingDTO,
  AttributeCommandDTO,
  GetDSRConfigsResponseBodyDTO,
} from '@ketch-com/figurehead'

type Args = {
  values: DsrConfigFormData
  scenarioId: string
  relatedPathsAndAssets: RelatedPathsAndAssets
  primaryDatasetId: string
  isEditMode?: boolean
  connectionCode?: string
  assetsDsrConfig: GetDSRConfigsResponseBodyDTO[]
  canonicalRightCode: string
}

export const mapApplicationValuesToPayload = ({
  canonicalRightCode,
  values,
  scenarioId,
  relatedPathsAndAssets,
  primaryDatasetId,
  isEditMode,
  connectionCode,
  assetsDsrConfig,
}: Args) => {
  const datasetsConfig = {} as CreatePatchDSRConfigsRequestBodyDTO
  datasetsConfig.datasetDSRConfiguration = []
  const { actions, identitySpace, relatedDatasets } = values
  const configuringDatasetIds = [primaryDatasetId, ...Object.keys(relatedDatasets)]

  configuringDatasetIds?.forEach?.(configuringDatasetId => {
    const dataSetDsrConfiguration = {} as DatasetDSRConfigurationDTO
    dataSetDsrConfiguration.scenarioId = scenarioId
    dataSetDsrConfiguration.datasetId = configuringDatasetId
    dataSetDsrConfiguration.connectionCode = connectionCode
    dataSetDsrConfiguration.attributeCommands = []
    dataSetDsrConfiguration.identityLookups = []

    for (const [attributeId, configuration] of Object.entries(actions[configuringDatasetId])) {
      if (configuration.command > 0) {
        const attributeCommand: AttributeCommandDTO = {
          id: attributeId,
          command: configuration.command,
        }
        if (configuration.command === 6) attributeCommand.updateValue = configuration.updateValue
        dataSetDsrConfiguration.attributeCommands.push(attributeCommand)

        if (isEditMode && !dataSetDsrConfiguration.id && configuration.dsrConfigId)
          dataSetDsrConfiguration.id = configuration.dsrConfigId
      }
    }

    for (const [, identitySpaceSelection] of Object.entries(identitySpace)) {
      const relatedPaths = relatedDatasets[configuringDatasetId]
      if (relatedPaths) {
        const relatedPathsHash = Object.keys(relatedPaths).filter(path => {
          if (path !== 'included') return true
          return false
        })
        const relatedPathsRelationships = relatedPathsAndAssets[configuringDatasetId]
        const identityPathMap = {} as { [key: string]: RelationshipDTO[] }

        relatedPathsRelationships?.forEach?.(relatedPath => {
          const { path, hashID } = relatedPath
          path?.forEach?.(pathValue => {
            const { relationships } = pathValue
            const relationship: RelationshipDTO = { attributeMappings: [] as AttributeMappingDTO[] }
            relationships?.forEach?.(relation => {
              relationship.attributeMappings?.push({
                id: relation[0].relationshipId,
                fromAttributeId: relation[0].attributeId,
                toAttributeId: relation[1].attributeId,
              } as AttributeMappingDTO)
            })
            if (!identityPathMap[hashID]) identityPathMap[hashID] = []
            identityPathMap[hashID].push(relationship)
          })
        })

        relatedPathsHash?.forEach?.(relatedPathHashId => {
          dataSetDsrConfiguration?.identityLookups?.push({
            hashId: relatedPathHashId,
            identityAttributeId: identitySpaceSelection.attributeId,
            identitySpaceCode: identitySpaceSelection.identitySpaceCode,
            identityPath: identityPathMap[relatedPathHashId],
            exclude: Boolean(
              !identitySpaceSelection.checked || !relatedPaths.included || !relatedPaths[relatedPathHashId],
            ),
          })
        })
      } else {
        dataSetDsrConfiguration?.identityLookups?.push({
          identityAttributeId: identitySpaceSelection.attributeId,
          identitySpaceCode: identitySpaceSelection.identitySpaceCode,
          exclude: Boolean(!identitySpaceSelection.checked),
          identityPath: [],
        })
      }
    }

    datasetsConfig?.datasetDSRConfiguration?.push(dataSetDsrConfiguration)
  })

  const createDsrConfigs = datasetsConfig.datasetDSRConfiguration.filter(
    config => !config.id && (config.datasetId === primaryDatasetId || relatedDatasets[config.datasetId || ''].included),
  )
  // remove all excluded identityLookups if there is no previous configuration
  createDsrConfigs?.forEach?.(config => {
    const lookups = config.identityLookups?.filter(lookup => !lookup.exclude)
    config.identityLookups = lookups
  })

  const patchDsrConfigs = datasetsConfig.datasetDSRConfiguration.filter(config => config.id)

  const createData = {
    datasetDSRConfiguration: createDsrConfigs,
    canonicalRightCode,
  } as CreatePatchDSRConfigsRequestBodyDTO
  const patchData = {
    datasetDSRConfiguration: patchDsrConfigs,
    canonicalRightCode,
  } as CreatePatchDSRConfigsRequestBodyDTO

  return {
    createData,
    patchData,
  }
}
