import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseHint: {
        display: 'block',
        fontSize: typography.pxToRem(12),
        fontWeight: 500,
        marginTop: 4,
        color: palette.darkDuskFaded.main,
      },
    }),
  { name: 'Hint' },
)

/**
 * -
 */
type Props = {
  children?: React.ReactNode
}
export const Hint: React.FC<Props> = ({ children }) => {
  const classes = useStyles()

  return <span className={classes.baseHint}>{children}</span>
}
