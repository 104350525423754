import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { Button, PopUp } from '@ketch-com/deck'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/modals/AlertComponent'
import { MaybeNull } from 'interfaces'
import { Typography } from '@mui/material'
import { useSoftDeleteRightInvocation } from 'api/rightInvocations/mutations/useSoftDeleteRightInvocation'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { useAppSelector } from 'store/hooks'
import { getNavigationState } from 'store/navigationSlice/selectors'
import { RightInvocationDTO } from '@ketch-com/figurehead'

type Props = {
  rightInvocation: MaybeNull<RightInvocationDTO>
  onClose: () => void
  onDelete?: () => void
}

export const ConfirmDeleteDSRModal: React.FC<Props> = ({ rightInvocation, onClose, onDelete = onClose }) => {
  const [isBusy, setIsBusy] = useState(false)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const reduxNavigationState = useAppSelector(getNavigationState)
  /* check redux store for goBack value to preserve any filters user might have had set */
  const backTo = !!reduxNavigationState?.goBack
    ? reduxNavigationState.goBack
    : RoutesManager.orchestration.rightsQueue.root.getURL()

  const { mutate: handleSoftDeleteRightInvocation } = useSoftDeleteRightInvocation({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(ApiQueryKeys.rightInvocationsPaginated)
      await queryClient.refetchQueries(ApiQueryKeys.rightInvocationsPaginatedV2)
      onDelete?.()
      showToast({ content: 'DSR deleted', type: 'success' })
      navigate(backTo)
    },
    onError: () => {
      showToast({ content: 'Failed to delete DSR', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <PopUp
      title="Warning"
      variant="dialog"
      onClose={onClose}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onClose} pending={isBusy}>
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={() => {
              handleSoftDeleteRightInvocation({
                params: {
                  rightInvocationId: rightInvocation?.id || '',
                },
              })
            }}
            pending={isBusy}
          >
            Confirm
          </Button>
        </>
      }
    >
      <Typography>
        Are you sure you want to delete this request? Doing so will remove it entirely from the platform and is not
        reversible.
      </Typography>
    </PopUp>
  )
}
