import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchFilesInfo } from 'api/files/fetchers/fetchFilesInfo'
import { excludeFalsy } from 'utils/helpers/excludeFalsy'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.filesInfo,
  queryFn: fetchFilesInfo,
  select: res => (res || []).map(({ data }) => data).filter(excludeFalsy),
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useFilesInfo = (config: Config) =>
  useCustomQuery({
    ...config,
    params: config.params!,
  })
