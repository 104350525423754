import React, { useContext } from 'react'
import { EditPolicyScope } from 'pages/policyCenter/policyScopes/upsert/components/EditPolicyScope'
import { CreatePolicyScope } from 'pages/policyCenter/policyScopes/upsert/components/CreatePolicyScope'
import { PolicyScopeUpsertContext, withFormPolicyScopeUpsertContext } from './context/PolicyScopeUpsertContext'

export const PolicyScopeUpsertContainerWithContext: React.FC = () => {
  const {
    isEditMode,
    rights,
    policyScope,
    onSubmit,
    regionsHierarchyTransformed,
    policyScopeTemplate,
    isReady,
    filteredRegulations,
  } = useContext(PolicyScopeUpsertContext)

  return isEditMode ? (
    <EditPolicyScope
      isReady={isReady}
      onSubmit={onSubmit}
      regulations={filteredRegulations}
      regionGroups={regionsHierarchyTransformed}
      rights={rights}
      policyScope={policyScope}
    />
  ) : (
    <CreatePolicyScope
      isReady={isReady}
      policyScopeTemplate={policyScopeTemplate}
      onSubmit={onSubmit}
      regulations={filteredRegulations}
      regionGroups={regionsHierarchyTransformed}
      rights={rights}
    />
  )
}

export const PolicyScopeUpsertContainer = withFormPolicyScopeUpsertContext(PolicyScopeUpsertContainerWithContext)
