import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  fileUrl: string
}

export const deleteFile = async ({ fileUrl }: Params) => {
  return API.delete(
    formatRequestString({
      entityUrl: fileUrl,
    }),
  )
}
