import { MaybeNull, ObjectLiteral } from 'interfaces'
import { ConsentExperienceDTO } from 'interfaces/experiences/experience'
import { FormMode } from 'interfaces/formModes/formMode'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { deserialize } from '../../common'
import { ConsentExperienceFormValues, ConsentExperienceTranslationFields } from '../interfaces'
import { getDefaultValues } from './getDefaultValues'

export const getConsentInitialValues = ({
  experience,
  organizationLanguages,
  formMode,
  organizationName,
}: {
  experience: MaybeNull<ConsentExperienceDTO>
  organizationLanguages: LanguageWithStatusDTO[]
  formMode: FormMode
  organizationName: string
}): ConsentExperienceFormValues => {
  const defaultValues = getDefaultValues({ organizationName })

  if (!experience) {
    return {
      name: '',
      code: '',
      banner: defaultValues.banner,
      modal: defaultValues.modal as any,
      jit: defaultValues.jit,
      translations: organizationLanguages.reduce<ObjectLiteral<ConsentExperienceTranslationFields>>(
        (acc, { language }) => {
          return {
            ...acc,
            [language.code]: {
              banner: {
                title: '',
                footerDescription: deserialize(''),
                buttonText: '',
                secondaryButtonText: '',
              },
              modal: {
                title: '',
                bodyTitle: '',
                bodyDescription: deserialize(''),
                buttonText: '',
                consentTitle: '',
                switchOnText: '',
                switchOffText: '',
              },
              jit: {
                title: '',
                bodyDescription: deserialize(''),
                moreInfoText: '',
                declineButtonText: '',
                acceptButtonText: '',
              },
            },
          } as any
        },
        {},
      ),
    }
  }

  const duplicate = formMode === FormMode.DUPLICATE
  const banner = experience?.consent?.banner
  const modal = experience?.consent?.modal
  const jit = experience?.consent?.jit
  const translations = experience?.consent?.translations

  return {
    name: duplicate ? '' : experience?.name,
    code: duplicate ? '' : experience?.code,
    banner: {
      buttonText: banner?.buttonText || '',
      extensions: {},
      footerDescription: deserialize(banner?.footerDescription || ''),
      primaryButtonAction: banner?.primaryButtonAction || defaultValues.banner.primaryButtonAction,
      secondaryButtonDestination: banner?.secondaryButtonDestination || defaultValues.banner.secondaryButtonDestination,
      secondaryButtonText: banner?.secondaryButtonText || '',
      showCloseIcon: !!banner?.showCloseIcon,
      title: banner?.title || '',
    },
    modal: {
      bodyDescription: deserialize(modal?.bodyDescription || ''),
      bodyTitle: modal?.bodyTitle || '',
      buttonText: modal?.buttonText || '',
      consentTitle: modal?.consentTitle || '',
      extensions: {},
      hideConsentTitle: !!modal?.hideConsentTitle || false,
      hideLegalBases: !!modal?.hideLegalBases || false,
      isCustomSwitchTextSwitchOn: !!modal?.switchOnText || !!modal?.switchOffText,
      isCustomTextSwitchOn: !!modal?.consentTitle,
      showCloseIcon: !!modal?.showCloseIcon,
      switchOffText: modal?.switchOffText || defaultValues.modal.switchOffText,
      switchOnText: modal?.switchOnText || defaultValues.modal.switchOnText,
      switchTextRenderLogic: modal?.switchTextRenderLogic || defaultValues.modal.switchTextRenderLogic,
      title: modal?.title || '',
    } as any,
    jit: {
      acceptButtonText: jit?.acceptButtonText || '',
      bodyDescription: deserialize(jit?.bodyDescription || ''),
      declineButtonText: jit?.declineButtonText || '',
      extensions: {},
      moreInfoDestination: jit?.moreInfoDestination || defaultValues.jit.moreInfoDestination,
      moreInfoText: jit?.moreInfoText || '',
      showCloseIcon: !!jit?.showCloseIcon,
      title: jit?.title || '',
    },
    translations: organizationLanguages.reduce<ObjectLiteral<ConsentExperienceTranslationFields>>(
      (acc, { language }) => {
        const bannerTranslation = translations?.[language.code]?.banner
        const modalTranslation = translations?.[language.code]?.modal
        const jitTranslation = translations?.[language.code]?.jit

        return {
          ...acc,
          [language.code]: {
            banner: {
              buttonText: bannerTranslation?.buttonText || '',
              footerDescription: deserialize(bannerTranslation?.footerDescription || ''),
              secondaryButtonText: bannerTranslation?.secondaryButtonText || '',
              title: bannerTranslation?.title || '',
            },
            modal: {
              bodyDescription: deserialize(modalTranslation?.bodyDescription || ''),
              bodyTitle: modalTranslation?.bodyTitle || '',
              buttonText: modalTranslation?.buttonText || '',
              consentTitle: modalTranslation?.consentTitle || '',
              switchOffText: modalTranslation?.switchOffText || '',
              switchOnText: modalTranslation?.switchOnText || '',
              title: modalTranslation?.title || '',
            },
            jit: {
              acceptButtonText: jitTranslation?.acceptButtonText || '',
              bodyDescription: deserialize(jitTranslation?.bodyDescription || ''),
              declineButtonText: jitTranslation?.declineButtonText || '',
              moreInfoText: jitTranslation?.moreInfoText || '',
              title: jitTranslation?.title || '',
            },
          },
        } as any
      },
      {},
    ),
  }
}
