import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIListRequestParams } from '../../common/utils'
import { GetPrivacyProtocol200 } from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'

type Params = APIListRequestParams<{
  protocolId?: string
}>

export const fetchPrivacyProtocol = ({ protocolId }: Params) =>
  API.get<GetPrivacyProtocol200>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/privacy-protocols/${protocolId}`,
      params: {
        filters: {},
      },
    }),
  )
