import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { JurisdictionResponse } from '@ketch-com/supercargo/dist/jurisdiction_gen.schemas'
import { APIListRequestParams } from '../../common/utils'

type Params = APIListRequestParams<{
  include?: string
}>

export const fetchJurisdictionsV2 = ({ include }: Params) =>
  API.get<JurisdictionResponse>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/jurisdictions`,
      params: {
        filters: {
          include,
        },
      },
    }),
  )
