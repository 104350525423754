import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { AuthenticatorFormSteps, getAuthenticatorInitialValues } from '../utils'
import { CreateAuthenticatorDetailsFormSection, CreateAuthenticatorVariablesMappingFormSection } from '.'
import { AuthenticatorUpsertContext } from '../context/AuthenticatorUpsertContext'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormikProvider, useFormik } from 'formik'
import { useGetCreateAuthenticatorValidationSchema } from '../utils'

type Props = {}

export const AuthenticatorsUpsertView: React.FC<Props> = () => {
  const { authenticatorToEdit: authenticator, handleSubmit } = useContext(AuthenticatorUpsertContext)
  const [currentFormStep, setCurrentFormStep] = useState(0)

  const navigate = useNavigate()
  const initialValues = useMemo(() => getAuthenticatorInitialValues({ authenticator }), [authenticator])
  const validationSchema = useGetCreateAuthenticatorValidationSchema({
    currentFormStep,
  })

  const breadcrumbs = [
    { title: 'Developers', link: RoutesManager.developers.authenticators.list.getURL() },
    { title: 'Authentication', link: RoutesManager.developers.authenticators.list.getURL() },
    { title: 'Create New' },
  ]

  let formSteps = Object.values(AuthenticatorFormSteps)

  const isLastStep = formSteps.length - 1 === currentFormStep

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  const { isSubmitting, submitForm, validateForm, setFieldTouched } = formikProps

  const onAccept = async () => {
    const errors = await validateForm()

    if (Object.keys(errors).length) {
      Object.keys(errors).forEach(errorKey => {
        if (errors.variableClaimMappings && errorKey === 'variableClaimMappings') {
          const variableClaimMappingsErrors: Array<{ value: string }> = errors.variableClaimMappings as any
          variableClaimMappingsErrors.forEach((errorObject, index) => {
            // because values are stored in formik context as
            // { variableClaimMappings : [{{fieldKey}: 'value'}, etc etc] }
            Object.keys(errorObject).forEach(errorObjectKey => {
              setFieldTouched(`variableClaimMappings[${index}][${errorObjectKey}]`, true)
            })
          })
        } else {
          setFieldTouched(errorKey, true)
        }
      })
      return
    }
    if (isLastStep) {
      submitForm()
    } else {
      setCurrentFormStep(prev => prev + 1)
    }
  }

  const renderedChildren = [
    <CreateAuthenticatorDetailsFormSection />,
    <CreateAuthenticatorVariablesMappingFormSection />,
  ]

  useEffect(() => {
    setTimeout(function () {
      const scrollToElement = document.getElementsByClassName('DeckFormRow')[currentFormStep]
      scrollToElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 0)
  }, [currentFormStep])

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          sx={{
            '&.UpsertLayout-container': {
              '& .UpsertLayout-stepper': {
                // navbar 40px + 8px gap between layout stepper and content
                top: '8px',
              },
            },
          }}
          mode={UpsertLayoutMode.create}
          showStepper
          formSteps={formSteps}
          currentFormStep={currentFormStep}
          renderFormTitle={() => <ContentGroup title="Create New Authenticator" titleVariant="h2" isDivider />}
          onAccept={onAccept}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          acceptActionButtonText={isLastStep ? 'Save & Finish' : 'Next'}
          onCancel={() => {
            navigate(RoutesManager.developers.authenticators.root.getURL())
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">{renderedChildren.filter((_, i) => currentFormStep >= i)}</form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
