import React from 'react'
import { Typography } from '@mui/material'
import { Button, PopUp } from '@ketch-com/deck'

type Props = {
  onSubmit: () => void
  onCancel: () => void
}

export const ConfirmMultipleEndTilesModal: React.FC<Props> = ({ onSubmit, onCancel }) => {
  return (
    <PopUp
      title="Multiple End Tiles"
      popUpWidth="550px"
      variant="dialog"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="primary" size="large" onClick={onSubmit}>
            Confirm
          </Button>
        </>
      }
    >
      <Typography>
        Warning! Your workflow has multiple End tiles. Please ensure the logic in your Workflow is such that only ONE
        End tile can ever be reached.
      </Typography>
    </PopUp>
  )
}
