export const getInferredClassifications = () => [
  { name: 'ABA_ROUTING', description: '' },
  { name: 'AGE', description: '' },
  { name: 'AWS_ACCESS_KEY', description: '' },
  { name: 'AWS_SECRET_KEY', description: '' },
  { name: 'BANK_ACCOUNT_NUMBER', description: '' },
  { name: 'BANK_ACCOUNT', description: '' },
  { name: 'BANK_ROUTING', description: '' },
  { name: 'BIRTH_YEAR', description: '' },
  { name: 'BIRTHDATE', description: '' },
  { name: 'CITY_STATE', description: '' },
  { name: 'CITY', description: '' },
  { name: 'COUNTRY_AREA_CODE', description: '' },
  { name: 'COUNTRY_REGION', description: '' },
  { name: 'COUNTRY', description: '' },
  { name: 'COUNTY', description: '' },
  { name: 'CREDIT_DEBIT_CVV', description: '' },
  { name: 'CREDIT_DEBIT_EXPIRY', description: '' },
  { name: 'CREDIT_DEBIT_NUMBER', description: '' },
  { name: 'CREDIT_RATING', description: '' },
  { name: 'DATE_TIME', description: '' },
  { name: 'DATE', description: '' },
  { name: 'DISABILITY', description: '' },
  { name: 'DRIVERS_LICENSE', description: '' },
  { name: 'EDUCATION', description: '' },
  { name: 'EMAIL', description: '' },
  { name: 'EMPLOYMENT_INCOME', description: '' },
  { name: 'EMPLOYMENT', description: '' },
  { name: 'ETHNICITY', description: '' },
  { name: 'FAX', description: '' },
  { name: 'FIRST_NAME', description: '' },
  { name: 'GENDER', description: '' },
  { name: 'GPS_COORD', description: '' },
  { name: 'HEIGHT', description: '' },
  { name: 'IBAN_CODE', description: '' },
  { name: 'IMEI', description: '' },
  { name: 'IMSI', description: '' },
  { name: 'IP_ADDRESS', description: '' },
  { name: 'LAST_NAME', description: '' },
  { name: 'LATITUDE', description: '' },
  { name: 'LICENSE_NUMBER', description: '' },
  { name: 'LINKEDIN_URL', description: '' },
  { name: 'LONGITUDE', description: '' },
  { name: 'MAC_ADDRESS', description: '' },
  { name: 'MARITAL_STATUS', description: '' },
  { name: 'MIDDLE_NAME', description: '' },
  { name: 'MILITARY_STATUS', description: '' },
  { name: 'NATIONALITY', description: '' },
  { name: 'ORGANIZATION_NAME', description: '' },
  { name: 'PASSPORT_NUMBER', description: '' },
  { name: 'PASSWORD', description: '' },
  { name: 'PERSON_NAME', description: '' },
  { name: 'PHONE_AREA_CODE', description: '' },
  { name: 'PHONE_NUMBER', description: '' },
  { name: 'PIN', description: '' },
  { name: 'POSTAL_CODE', description: '' },
  { name: 'PROVINCE', description: '' },
  { name: 'RACE', description: '' },
  { name: 'RELIGION', description: '' },
  { name: 'SSN', description: '' },
  { name: 'STATE', description: '' },
  { name: 'STREET_ADDRESS', description: '' },
  { name: 'SWIFT_CODE', description: '' },
  { name: 'TIME', description: '' },
  { name: 'TIN', description: '' },
  { name: 'URL_ADDRESS', description: '' },
  { name: 'URL', description: '' },
  { name: 'US_STATE', description: '' },
  { name: 'USERNAME', description: '' },
  { name: 'WEIGHT', description: '' },
  { name: 'ZIP_CODE', description: '' },
]
