import { useInfiniteQuery } from 'react-query'
import { fetchAssetsPaginatedV3 } from 'api/assets/fetchers/fetchAssetsPaginatedV3'
import { ApiQueryKeys } from 'api/common/queryKeys'

type Params = {
  canonicalResourceType?: number | null | undefined
  resourceTypeCode?: string | null | undefined
  query?: string | null | undefined
  hookId?: string | null | undefined
  enabled?: boolean
  limit?: number
}

export const useAssetsInfiniteV3 = (params: Params) => {
  const { canonicalResourceType, resourceTypeCode, query, limit, hookId, enabled = true } = params
  const resp = useInfiniteQuery({
    enabled,
    queryKey: [ApiQueryKeys.resourcesInfiniteV3, { ...params }],
    queryFn: async ({ pageParam = {} }) => {
      const fetchResult = await fetchAssetsPaginatedV3({
        limit: limit || 20,
        nextToken: pageParam.nextToken,
        canonicalResourceType,
        resourceTypeCode,
        query: query || '',
        hookId,
      })
      return fetchResult
    },
    getNextPageParam: lastPage => {
      const nextToken = lastPage?.data?.next?.nextToken
      return nextToken ? { nextToken } : undefined
    },
  })

  return {
    ...resp,
    assetsData: resp?.data?.pages?.map(page => page?.data?.assets || []).flat() || [],
    totalResults: resp?.data?.pages?.[0]?.data?.totalResults || 0,
  }
}

export type UseAssetsInfiniteV3Return = ReturnType<typeof useAssetsInfiniteV3>
