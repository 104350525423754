import { ICanvasStep, ITaskActivityParams } from 'pages/orchestration/workflows/edit/interfaces'
import { isTaskActivityParamsTypeGuard } from 'pages/orchestration/workflows/edit/interfaces/typeGuards'
import { WORKFLOW_TYPE_ENUM } from '../../DsrTransponderForm/constants'

interface IGetTaskInitialValuesParams {
  step: ICanvasStep
  canonicalRightCode?: string
}

export const getTaskFormInitialValues = (params: IGetTaskInitialValuesParams): ITaskActivityParams => {
  const { step, canonicalRightCode } = params

  const defaultValues: {
    [key: string]: boolean
  } = {}

  // per https://ketch-com.atlassian.net/browse/KD-8066
  // we test for !step?.code because we only want to set these defaults for the first time a step
  // is dropped onto the canvas.
  if (canonicalRightCode !== WORKFLOW_TYPE_ENUM.GET && !step?.code) {
    // for non-access workflows
    defaultValues.hasResolutionNotes = true
    defaultValues.hasResolutionAttachments = true
  }

  if (canonicalRightCode === WORKFLOW_TYPE_ENUM.GET && !step?.code) {
    // otherwise, for access workflows
    defaultValues.hasResolutionNotes = true
    defaultValues.hasResolutionAttachments = true
    defaultValues.hasDsrResolutionAttachments = true
  }

  if (isTaskActivityParamsTypeGuard(step.activity?.params)) {
    return {
      assignee: step.activity?.params?.assignee || '',
      code: step.code || '',
      description: step.description || '',
      details: step.activity?.params?.details || '',

      // Notes
      hasResolutionNotes: step.activity?.params?.hasResolutionNotes || defaultValues.hasResolutionNotes || false,
      isResolutionNotesRequired: step.activity?.params?.isResolutionNotesRequired || false,

      // Attachments
      hasResolutionAttachments:
        step.activity?.params?.hasResolutionAttachments || defaultValues.hasResolutionAttachments || false,
      isResolutionAttachmentsRequired: step.activity?.params?.isResolutionAttachmentsRequired || false,

      // Attachments for Data Subject
      isDsrResolutionAttachmentsRequired: step.activity?.params?.isDsrResolutionAttachmentsRequired || false,
      hasDsrResolutionAttachments:
        step.activity?.params?.hasDsrResolutionAttachments || defaultValues.hasDsrResolutionAttachments || false,
      dsrResolutionDescription: step.activity?.params?.dsrResolutionDescription || '',

      // Data Subject Variables
      hasDataSubjectVariables: step.activity?.params?.hasDataSubjectVariables || false,
      dataSubjectVariables: step.activity?.params?.dataSubjectVariables || [],

      // Outcome Variables
      hasOutcomeVariables: step.activity?.params?.hasOutcomeVariables || false,
      outcomeVariables: step.activity?.params?.outcomeVariables || [],

      // Identifiers
      hasIdentitySpaces: step.activity?.params?.hasIdentitySpaces || false,
      identitySpaces: step.activity?.params?.identitySpaces || [],

      // Activity Timer
      timeToCompleteDays: step.activity?.params?.timeToCompleteDays || undefined,
    }
  }

  return {
    assignee: '',
    code: '',
    description: '',
    details: '',

    dsrResolutionDescription: '',

    // Notes
    hasResolutionNotes: defaultValues.hasResolutionNotes || false,
    isResolutionNotesRequired: false,

    // Attachments
    hasResolutionAttachments: defaultValues.hasResolutionAttachments || false,
    isResolutionAttachmentsRequired: false,

    // Attachments for Data Subject
    isDsrResolutionAttachmentsRequired: false,
    hasDsrResolutionAttachments: defaultValues.hasDsrResolutionAttachments || false,

    // Data Subject Variables
    hasDataSubjectVariables: false,
    dataSubjectVariables: [],

    // Outcome Variables
    hasOutcomeVariables: false,
    outcomeVariables: [],

    // Identifiers
    hasIdentitySpaces: false,
    identitySpaces: [],
  }
}
