import React from 'react'
import { useQueryClient } from 'react-query'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { Banner, Button, Chip, Icon, theme } from '@ketch-com/deck'
import {
  ProcessingActivityAssessmentRequirementDTO,
  ProcessingActivityDTO,
  ProcessingActivityTypeDTO,
} from '@ketch-com/figurehead'
import {
  NavigationBreadCrumbs,
  NavigationBreadCrumbsProps,
} from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormMode } from 'interfaces/formModes/formMode'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { ProcessingActivityTypeLabel } from 'pages/policyCenter/processingActivities/constants'

type Props = {
  breadcrumbs: NavigationBreadCrumbsProps['items']
  processingActivity: ProcessingActivityDTO
}

export const ReviewProcessingActivity: React.FC<Props> = ({ breadcrumbs, processingActivity }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const NotRequiredBox = (
    <Banner title="Assessment Not Required" variant="standard" isInfoBox severity="info">
      <Typography>
        This processing activity doesn’t require an Impact Assessment or any other type of assessment.
      </Typography>
    </Banner>
  )

  const RecommendedBox = (
    <Banner title="Impact Assessment Recommended" variant="outlined" isInfoBox severity="info">
      <Typography>
        An Impact Assessment or another type of assessment is recommended for this processing activity.
      </Typography>
    </Banner>
  )

  const MaybeRequiredBox = (
    <Banner title="Impact Assessment May Be Required" variant="outlined" isInfoBox severity="info">
      <Typography>
        This processing activity might necessitate an Impact Assessment or another type of assessment.
      </Typography>
    </Banner>
  )

  const RequiredBox = (
    <Banner title="Impact Assessment Required" variant="outlined" isInfoBox severity="warning">
      <Typography>This processing activity requires an Impact Assessment or another type of assessment.</Typography>
    </Banner>
  )

  const NoneBox = (
    <Banner title="None" variant="standard" isInfoBox severity="info">
      No Impact Assessment or any other form of assessment was necessary for the approval of this processing activity.
    </Banner>
  )

  return (
    <Box>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box bgcolor="white.main" width={600} mx="auto" my={4} px={6} py={4} borderRadius="11px">
        <Box display="flex" alignItems="center" gap={2}>
          <Icon name="OutcomeSuccess" width={56} height={56} />
          <Typography variant="h2" color="greenHaze.main">
            Processing Activity Requested
          </Typography>
        </Box>

        <Box bgcolor="fadedGrey.main" p={2} my={3} borderRadius="11px">
          <Typography variant="h4">Application Review Process</Typography>
          {processingActivity?.purposes?.length ? (
            <Box my={2} display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="smallLabel" color="darkDuskFaded.main">
                Purpose
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {processingActivity?.purposes?.map((purpose, index) => <Chip label={purpose.name} key={index} />)}
              </Box>
            </Box>
          ) : null}

          {processingActivity?.type !== ProcessingActivityTypeDTO.UnspecifiedProcessingActivityType ? (
            <Box my={2} display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="smallLabel" color="darkDuskFaded.main">
                Type
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Chip
                  label={
                    processingActivity.type === 'standard'
                      ? ProcessingActivityTypeLabel[ProcessingActivityTypeDTO.StandardProcessingActivityType]
                      : ProcessingActivityTypeLabel[ProcessingActivityTypeDTO.ModelProcessingActivityType]
                  }
                />
              </Box>
            </Box>
          ) : null}

          {Array.isArray(processingActivity?.dataSystems) && processingActivity?.dataSystems.length > 0 && (
            <Box my={2} display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="smallLabel" color="darkDuskFaded.main">
                Data Systems
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <Chip label={processingActivity?.dataSystems.length} />
              </Box>
            </Box>
          )}
        </Box>

        {/* Status */}
        <Box pb={3} mb={3} borderBottom={`1px solid ${theme.palette.iron.main}`}>
          {/* Not required */}
          {processingActivity.assessmentRequirement ===
            ProcessingActivityAssessmentRequirementDTO.NotRequiredProcessingActivityAssessmentRequirement &&
            NotRequiredBox}

          {/* Recommended */}
          {processingActivity.assessmentRequirement ===
            ProcessingActivityAssessmentRequirementDTO.RecommendedProcessingActivityAssessmentRequirement &&
            RecommendedBox}

          {/* Maybe Required */}
          {processingActivity.assessmentRequirement ===
            ProcessingActivityAssessmentRequirementDTO.MaybeRequiredProcessingActivityAssessmentRequirement &&
            MaybeRequiredBox}

          {/* Required */}
          {processingActivity.assessmentRequirement ===
            ProcessingActivityAssessmentRequirementDTO.RequiredProcessingActivityAssessmentRequirement && RequiredBox}

          {/* None */}
          {processingActivity.assessmentRequirement ===
            ProcessingActivityAssessmentRequirementDTO.UnspecifiedProcessingActivityAssessmentRequirement && NoneBox}
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Button
            size="large"
            onClick={async () => {
              await queryClient.invalidateQueries(ApiQueryKeys.processingActivity)
              navigate(
                RoutesManager.policyCenter.processingActivities.view.overview.root.getURL({
                  id: processingActivity.id,
                }),
              )
            }}
          >
            Done
          </Button>

          <Button
            size="large"
            color="tertiary"
            onClick={() => {
              navigate(
                RoutesManager.policyCenter.processingActivities.upsert.getURL({
                  formMode: FormMode.EDIT,
                  id: processingActivity.id,
                }),
              )
            }}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
