import React from 'react'
import Box from '@mui/material/Box'

type Props = {
  children?: React.ReactNode
}
export const AppContent: React.FC<Props> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 3,
      }}
    >
      {children}
    </Box>
  )
}
