import React from 'react'
import { Grid } from '@mui/material'
import SignVerifyEmailImage from 'assets/icons/onboarding/verify-email.jpg'

export const SignUpFormCompletedInsights: React.FC = () => {
  return (
    <Grid container>
      <Grid item>
        <img
          src={SignVerifyEmailImage}
          alt=""
          style={{ width: '100%', height: 'auto', position: 'relative', top: '-5%' }}
        />
      </Grid>
    </Grid>
  )
}
