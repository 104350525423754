import React, { useState } from 'react'
import { Formik } from 'formik'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { Box } from '@mui/material'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { MAX_FILE_UPLOAD_SIZE_4_MB } from 'utils/constants/maxFileUploadSize'
import { Button, PopUp } from '@ketch-com/deck'
import { FormDropZoneWithUpload } from 'components/form/FormDropZone/FormDropZoneWithUpload'

type Props = {
  folder: string
  onClose: () => void
  version: string
}

export const UploadDocumentsModal: React.FC<Props> = ({ folder, onClose, version }) => {
  const queryClient = useQueryClient()

  const [isUploading, setIsUploading] = useState(false)

  const onUploadComplete = async () => {
    await queryClient.refetchQueries(ApiQueryKeys.dataReview)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        dsrResolutionAttachments: [],
      }}
      onSubmit={() => {}}
    >
      {() => {
        return (
          <>
            <PopUp
              variant="modal"
              title="Upload New Document"
              popUpWidth="840px"
              onClose={onClose}
              popUpActionChildren={
                <>
                  <Button color="secondary" size="large" onClick={onClose} disabled={isUploading}>
                    Close
                  </Button>
                </>
              }
            >
              <Box mt={2.25}>
                <FormRow>
                  <FormDropZoneWithUpload
                    multiple
                    allowDuplicates
                    onUploadComplete={onUploadComplete}
                    required={true}
                    name="dsrResolutionAttachments"
                    maxSize={MAX_FILE_UPLOAD_SIZE_4_MB}
                    onUploadingStateChange={setIsUploading}
                    uploadContext={{
                      version,
                      folder,
                      bucket: '',
                      includeInDataSubjectFolder: true,
                    }}
                    disableDownload
                    showUploadSizes
                  />
                </FormRow>
              </Box>
            </PopUp>
          </>
        )
      }}
    </Formik>
  )
}
