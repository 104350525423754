import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { editProcessingActivity } from 'api/processingActivities/fetchers/editProcessingActivity'

const useCustomMutation = createUseMutation({
  mutationFn: editProcessingActivity,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useEditProcessingActivity = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation, {})
}
