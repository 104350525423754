import React from 'react'
import { useParams } from 'react-router-dom'

import { PolicyView } from 'pages/policyCenter/policies/view/PolicyView'
import { usePolicyV2 } from 'api/policies/queries/usePolicyV2'
import { usePurpose } from 'api/purposes/queries/usePurpose'

export const PolicyViewContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const { data: policy, isLoading: isLoadingAsset } = usePolicyV2({
    params: {
      code: id!,
    },
  })

  const { data: purpose, isLoading: isLoadingPurpose } = usePurpose({
    enabled: !!policy?.purpose,
    params: {
      purposeCode: policy?.purpose || '',
    },
  })

  return <PolicyView isReady={!isLoadingAsset && !isLoadingPurpose} policy={policy} purpose={purpose} />
}
