import React, { useState, useMemo } from 'react'
import Grid from '@mui/material/Grid'
import { makeStyles, createStyles } from '@mui/styles'
import { Formik } from 'formik'

import { MaybeNull } from 'interfaces/common'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionStepDTO, WorkflowExecutionDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { joinNonEmpty } from 'utils/helpers/joinNonEmpty'
import { Modal } from 'components/ui-kit/modal/Modal'
import { Group } from 'components/ui-layouts/group/Group'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { Text } from 'components/ui-kit/typography/Text'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { FormDropZone } from 'components/ui-kit/form/dropZone/FormDropZone'
import { RightInvocationIdentitySpaces } from 'pages/orchestration/rightsQueue/viewV2/components/RightInvocationIdentitySpaces'
import { Box } from '@mui/material'
import { WORKFLOW_TYPE_ENUM } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/DsrTransponderForm/constants'
import { FormTextArea } from 'components/ui-kit/form/textArea/FormTextArea'
import { WorkflowExecutionStepStatusRenderer } from '../WorkflowExecutionStepStatusRenderer'
import { WorkflowExecutionStepCompleteFormValues, getValidationSchema } from './utils'
import { MAX_FILE_UPLOAD_SIZE_4_MB } from 'utils/constants/maxFileUploadSize'

const useStyles = makeStyles(
  theme =>
    createStyles({
      sectionTitle: {
        display: 'block',
        marginBottom: 12,
        color: theme.palette.fadedDarkGrey.main,
      },
    }),
  { name: 'WorkflowExecutionStepCompleteModal' },
)

type Props = {
  isReady: boolean
  rightInvocation: RightInvocationDTO
  workflowExecution: MaybeNull<WorkflowExecutionDTO>
  workflowExecutionStep: WorkflowExecutionStepDTO
  workflowExecutionStepDetails: MaybeNull<WorkflowExecutionStepDetailsDTO>
  filesInfo: FileInfoDTO[]
  dsrFilesInfo: FileInfoDTO[]
  onSubmit: (values: WorkflowExecutionStepCompleteFormValues) => Promise<void>
  onClose: () => void
}

export const WorkflowExecutionStepCompleteModal: React.FC<Props> = ({
  isReady,
  filesInfo,
  dsrFilesInfo,
  rightInvocation,
  workflowExecution,
  workflowExecutionStep,
  workflowExecutionStepDetails,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles()
  const [isUploading, setIsUploading] = useState(false)

  const { user, identities } = rightInvocation

  const activityParams = workflowExecutionStepDetails?.activity?.params
  const hasResolutionNotes = activityParams?.hasResolutionNotes || false
  const hasResolutionAttachments = activityParams?.hasResolutionAttachments || false
  const hasDsrResolutionAttachments = activityParams?.hasDsrResolutionAttachments || false
  const dsrResolutionDescription = activityParams?.dsrResolutionDescription || ''
  const stepDetailsText = activityParams?.details
  const resolutionDetails =
    workflowExecutionStepDetails?.results?.[workflowExecutionStep.stepID]?.resolutionDetails || ''
  const isAccessFlow = workflowExecution?.results?.['canonicalRightCode'] === WORKFLOW_TYPE_ENUM.GET

  const validationSchema = useMemo(
    () =>
      getValidationSchema({
        hasResolutionNotes,
        hasResolutionAttachments,
        hasDsrResolutionAttachments,
      }),
    [hasResolutionNotes, hasResolutionAttachments, hasDsrResolutionAttachments],
  )

  const initialValues: WorkflowExecutionStepCompleteFormValues = useMemo(
    () => ({
      resolutionDetails,
      resolutionAttachments: filesInfo,
      dsrResolutionAttachments: dsrFilesInfo,
    }),
    [resolutionDetails, filesInfo, dsrFilesInfo],
  )

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ submitForm, isSubmitting }) => {
        return (
          <Modal
            contentWidth={840}
            title={
              <Group gap={16} alignItems="center">
                Task&nbsp;
                <WorkflowExecutionStepStatusRenderer status={workflowExecutionStep.status} />
              </Group>
            }
            isValid={!isUploading}
            isLoading={!isReady}
            isSaving={isSubmitting}
            cancelBtnLabel="Close"
            onCancelBtnClick={onClose}
            onSubmitBtnClick={submitForm}
            submitBtnLabel="Complete Task"
          >
            <Box mt={2.25}>
              <FormRow>
                <Text className={classes.sectionTitle} size={16} weight={800}>
                  Task
                </Text>

                <Label>Details</Label>
                <TextCellRenderer value={stepDetailsText} />
              </FormRow>

              <FormRow>
                <Text className={classes.sectionTitle} size={16} weight={800}>
                  Data Subject
                </Text>
                <Grid container>
                  <Grid item xs={4}>
                    <FormRow>
                      <Label>Name</Label>
                      <TextCellRenderer value={joinNonEmpty([user?.first, user?.last])} />
                    </FormRow>

                    <FormRow>
                      <Label>Email</Label>
                      <TextCellRenderer value={user?.email} />
                    </FormRow>
                  </Grid>
                  <Grid item xs={4}>
                    <Label>Identifiers</Label>
                    {identities?.length ? (
                      <RightInvocationIdentitySpaces max={6} identities={identities} />
                    ) : (
                      <EmptyValueRenderer />
                    )}
                  </Grid>
                </Grid>
              </FormRow>

              <FormRow>
                <Text className={classes.sectionTitle} size={16} weight={800}>
                  Resolution
                </Text>
              </FormRow>

              <FormRow>
                <FormTextArea
                  label="Notes"
                  name="resolutionDetails"
                  required={hasResolutionNotes}
                  fullWidth
                  placeholder="Type here"
                  rows={5}
                />
              </FormRow>

              <FormRow>
                <FormDropZone
                  required={hasResolutionAttachments}
                  label="Attachments"
                  name="resolutionAttachments"
                  disabled={isSubmitting}
                  onUploadingStateChange={setIsUploading}
                  maxSize={MAX_FILE_UPLOAD_SIZE_4_MB}
                  uploadContext={{
                    version: 'resolution_attachments',
                    folder: `${rightInvocation.id}_${workflowExecutionStep.stepID}`,
                    bucket: '',
                  }}
                />
              </FormRow>

              {isAccessFlow ? (
                <FormRow>
                  <FormDropZone
                    required={hasDsrResolutionAttachments}
                    label="Attachments for Data Subject"
                    name="dsrResolutionAttachments"
                    disabled={isSubmitting}
                    maxSize={MAX_FILE_UPLOAD_SIZE_4_MB}
                    onUploadingStateChange={setIsUploading}
                    dropMessage={
                      dsrResolutionDescription ? (
                        <Text size={12} color="fadedDarkGrey" marginBottom={1}>
                          {dsrResolutionDescription}
                        </Text>
                      ) : null
                    }
                    uploadContext={{
                      version: rightInvocation.id,
                      folder: workflowExecutionStep.stepID,
                      bucket: '',
                    }}
                  />
                </FormRow>
              ) : null}
            </Box>
          </Modal>
        )
      }}
    </Formik>
  )
}
