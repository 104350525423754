import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, TableCell } from '@ketch-com/deck'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { CookieCategoryNames } from 'interfaces/cookies/cookieCategory'
import { CookieDurationsNames } from 'interfaces/cookies/cookieDurations'
import { CookiesLibraryViewCodeTableCell } from './CookiesLibraryViewCodeTableCell'

export const libraryViewCookiesColumns: GridColDef<CookieDTO>[] = [
  {
    align: 'left',
    field: 'code',
    headerAlign: 'left',
    headerName: '',
    sortable: false,
    maxWidth: 50,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return <CookiesLibraryViewCodeTableCell row={row} />
    },
  },
  {
    align: 'left',
    field: 'name',
    flex: 0.6,
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return <TableCell sx={{ padding: '8px 0' }} title={row.name} subTitle={row.serviceProvider} />
    },
  },
  {
    align: 'left',
    field: 'description',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Description',
    width: 400,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return <TableCell sx={{ padding: '8px 0' }} title={row.description}></TableCell>
    },
  },
  {
    align: 'left',
    field: 'category',
    headerAlign: 'left',
    flex: 0.5,
    headerName: 'Category',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return (
        <TableCell>
          <Chip size="small" label={CookieCategoryNames[row.category]} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'duration',
    headerAlign: 'left',
    flex: 0.5,
    headerName: 'Duration',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return (
        <TableCell>
          <Chip size="small" label={CookieDurationsNames[row.duration]} />
        </TableCell>
      )
    },
  },
]
