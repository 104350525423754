import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  deploymentPlanCode: string
  formData: {
    applicationEnvironmentIDs: string[]
  }
}

export const deployDeploymentPlan = ({ organizationCode, deploymentPlanCode, formData }: Params) =>
  API.post<null>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/deployments/${deploymentPlanCode}/deploy`,
    }),
    formData,
  )
