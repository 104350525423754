import { TypographyProps, Typography } from '@mui/material'
import React from 'react'

const getColorConversion = (color?: string) => {
  if (!color) return 'darkDusk'
  switch (color) {
    case 'grey':
      return 'darkDuskFaded'
    case 'secondary':
      return 'textSecondary'
    case 'lightGrey':
      return 'iron'
    default:
      return color
  }
}

export type TextColorOptions =
  | 'black'
  | 'bloomDark'
  | 'chileanFire'
  | 'darkChrome'
  | 'darkDusk'
  | 'darkDuskFaded'
  | 'graph10'
  | 'graph11'
  | 'graph12'
  | 'graph13'
  | 'greenHaze'
  | 'fadedDarkGrey'
  | 'grey'
  | 'heliotrope'
  | 'infraRed'
  | 'lightGrey'
  | 'muir'
  | 'persianBlue'
  | 'pinkCold'
  | 'pink'
  | 'red'
  | 'royalBlue'
  | 'sphere'
  | 'sphereCold'
  | 'teal'
  | 'white'
  | 'whiteFaded'
  | 'fadedDarkGrey'

export type FontWeightOptions = 500 | 600 | 700 | 800 | 'normal' | 'bold'

export type Props = {
  children?: React.ReactNode
  className?: string
  color?: TextColorOptions
  component?: React.ElementType<any>
  decoration?: 'none' | 'underline'
  isLink?: boolean
  letterSpacing?: string
  lineHeight?: number | string | 'unset'
  size?: number
  testId?: string
  transform?: 'none' | 'uppercase' | 'lowercase' | 'capitalize'
  title?: string
  weight?: FontWeightOptions
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line' | 'break-spaces'
  cursor?: 'pointer' | 'auto'
}

const TextV2Inner = (props: TypographyProps & Props, ref?: React.ForwardedRef<HTMLSpanElement>) => {
  const { isLink, color, children, testId, component, ...rest } = props
  return (
    <Typography
      {...rest}
      {...{ component: component || 'span' }}
      title={rest?.title}
      ref={ref ? ref : undefined}
      className={rest?.className}
      sx={{
        '&:hover': {
          cursor: !!rest.onClick ? 'pointer' : rest?.cursor === 'pointer' ? 'pointer' : 'auto',
          textDecoration: !!rest.onClick ? 'underline' : 'none',
        },
        color: color ? `${getColorConversion(color as string)}.main` : 'darkDusk.main',
        fontWeight: rest?.weight || 'normal',
        textTransform: rest?.transform || null,
        textDecoration: rest?.decoration || null,
        fontSize: ({ typography }) => typography.pxToRem(rest?.size || 14),
        lineHeight: ({ typography }) => rest?.lineHeight || typography.pxToRem((rest?.size || 14) + 2),
        whiteSpace: rest?.whiteSpace || 'normal',
        letterSpacing: rest.letterSpacing || '-0.01em',
        ...rest.sx,
      }}
      data-test-id={testId}
      onClick={rest?.onClick}
    >
      {children}
    </Typography>
  )
}

/**
 * @deprecated This component is deprecated and should not be used anymore.
 */
export const Text = React.forwardRef(TextV2Inner)
