import { useNavigate, useParams, useLocation, matchPath } from 'react-router-dom'
import { ExperienceUrlTypeParam } from 'interfaces/experiences/experienceUrlTypeParam'
import { RoutesManager } from 'utils/routing/routesManager'
import { useLanguage } from 'pages/consentAndRights/experiences/preview/hooks/useLanguage'
import { getExperiencePersistedValuesLocationState } from '../../upsert/utils/common'
import { useEffect, useMemo, useState } from 'react'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { FormMode } from 'interfaces/formModes/formMode'
import { PreviewDeviceMode } from 'interfaces/preview/previewDeviceMode'
import { DEFAULT_THEME_CODE } from 'utils/constants/themes'
import { useAuth, useIsEntitled } from 'utils/hooks'
import { ConsentExperienceTab } from '../../upsert/utils/consent/constants'
import { getConsentInitialValues } from '../../upsert/utils/consent/utils'
import { getPreferenceInitialValues } from '../../upsert/utils/preference/utils'
import { usePolicyScope } from './usePolicyScope'
import { filterLanguages } from '../utils'
// api
import { useExperience } from 'api/experiences/queries/useExperience'
import { useThemes } from 'api/themes-v2/queries/useThemes'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { useJurisdictionPurposesPreviews } from 'api/purposes/queries/useJurisdictionPurposesPreviews'
import { useFormTemplatesPreviews } from 'api/formTemplates/queries/useFormTemplatesPreviews'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { useJurisdictionRights } from 'api/rights/queries/useJurisdictionRights'
import { ensureHydratedThemeColors } from 'utils/helpers/ensureHydratedThemeColors'
import { ensureArray } from 'utils/helpers/array'

export const useExperiencePreviewUtils = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { userData } = useAuth()
  const { experienceType, code } = useParams<{
    experienceType: ExperienceUrlTypeParam
    code?: string
  }>()

  const [mode, setMode] = useState(PreviewDeviceMode.DESKTOP)
  const [activeTab, setActiveTab] = useState(ConsentExperienceTab.BANNER)

  const handleRedirectToList = () => {
    navigate(RoutesManager.deployment.experiences.root.getURL())
  }

  const creationContext = location.state?.creationContext
  const isConsentExperience = experienceType === ExperienceUrlTypeParam.CONSENT

  const { data: languages, isLoading: isLanguagesLoading } = useOrganizationLanguages()
  const filteredLanguages = filterLanguages(languages)
  const initialLanguageCode = filteredLanguages?.[0]?.language?.code || ''
  const { activeLanguage, languageItems } = useLanguage({
    languages: ensureArray(filteredLanguages),
    initialLanguageCode,
  })

  const { data: experience, isFetching: isExperienceFetching } = useExperience({
    enabled: !creationContext,
    params: {
      experienceCode: code!,
      languageCode: activeLanguage?.language?.code || '',
    },
    onSuccess: ({ data }) => {
      if (isConsentExperience) {
        if (!data.experience.consent) {
          handleRedirectToList()
        }
      } else {
        if (!data.experience.preference) {
          handleRedirectToList()
        }
      }
    },
    onError: () => {
      handleRedirectToList()
    },
  })

  const { data: themes, isLoading: isThemesLoading } = useThemes()

  const isReady = !isExperienceFetching && !isThemesLoading && !!themes?.length && !isLanguagesLoading

  const [activeThemeCode, setActiveThemeCode] = useState('')

  useEffect(() => {
    if (themes?.length > 0) {
      // check if there is a default theme
      const defaultTheme = themes?.find(({ code }) => code === DEFAULT_THEME_CODE)
      // if there is a default theme, set to default theme's code, otherwise set to the code of the first theme in themes
      const themeCode = !!defaultTheme ? defaultTheme?.code : themes?.[0]?.code
      setActiveThemeCode(themeCode)
    }
  }, [themes])

  const { isEntitled } = useIsEntitled()

  const isEntitledToRights = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)
  const isEntitledToConsentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)
  const isEntitledToSubscriptions = isEntitled(ENTITLEMENTS.SUBSCRIPTIONS)

  const experienceValues = useMemo(
    () =>
      creationContext?.values ||
      (isConsentExperience
        ? getConsentInitialValues({
            experience,
            organizationLanguages: [],
            formMode: FormMode.EDIT,
            organizationName: userData.organizationName!,
          })
        : getPreferenceInitialValues({
            experience,
            organizationLanguages: [],
            formMode: FormMode.EDIT,
            organizationName: userData.organizationName!,
          })),
    [creationContext, experience, isConsentExperience, userData],
  )

  const { data: formTemplates, isLoading: isFormTemplatesLoading } = useFormTemplatesPreviews({
    params: {
      languageCode: activeLanguage?.language?.code || '',
    },
  })
  const { data: policyScopes, isLoading: isPolicyScopesLoading } = usePolicyScopes()
  const initialPolicyScopeCode = policyScopes[0]?.code || ''

  const { activePolicy, policyScopesItems, isLoadingPolicyScopes } = usePolicyScope(initialPolicyScopeCode)

  const { data: jurisdictionRights, isLoading: isJurisdictionRightsLoading } = useJurisdictionRights({
    params: {
      jurisdictionCode: activePolicy?.code || '',
      languageCode: activeLanguage?.language?.code || '',
      order: experienceValues?.rights?.canonicalRightsOrder.toString(',') || '',
    },
    enabled: !!activePolicy?.code,
  })

  const {
    data: { purposes },
    isLoading: isJurisdictionPurposesPreviewsLoading,
  } = useJurisdictionPurposesPreviews({
    params: {
      code: activePolicy?.code,
      languageCode: activeLanguage?.language?.code || '',
    },
    enabled: !!activePolicy?.code,
  })

  const activeTheme = useMemo(
    () => ensureHydratedThemeColors(themes.find(({ code }) => code === activeThemeCode)),
    [activeThemeCode, themes],
  )

  const themesItems = useMemo(
    () =>
      themes.map(({ name, code }) => ({
        active: code === activeThemeCode,
        content: name,
        onClick: () => {
          setActiveThemeCode(code)
        },
      })),
    [activeThemeCode, themes],
  )

  const { name } = experienceValues

  const exitTo = creationContext
    ? {
        pathname: creationContext.referrer,
        state: getExperiencePersistedValuesLocationState(creationContext.values),
      }
    : RoutesManager.deployment.experiences.view.root.getURL({ code })

  const isExistingEntityPreview =
    !creationContext ||
    matchPath(creationContext.referrer, `/deployment${RoutesManager.deployment.experiences.upsert.root.pattern}`)
      ?.params?.formMode === FormMode.EDIT

  const isPreviewReady =
    isReady &&
    !isFormTemplatesLoading &&
    !isJurisdictionRightsLoading &&
    !isPolicyScopesLoading &&
    !isJurisdictionPurposesPreviewsLoading &&
    !isLoadingPolicyScopes

  const payload = {
    activeLanguage,
    activePolicy,
    activeTab,
    activeTheme,
    activeThemeCode,
    creationContext,
    code,
    exitTo,
    experience,
    experienceValues,
    formTemplates,
    isConsentExperience,
    isEntitledToConsentAndDisclosure,
    isEntitledToRights,
    isEntitledToSubscriptions,
    isExistingEntityPreview,
    isPreviewReady,
    isReady,
    jurisdictionPurposes: purposes,
    jurisdictionRights,
    languageItems,
    mode,
    name,
    policyScopesItems,
    setActiveTab,
    setMode,
    themes,
    themesItems,
  }

  return payload
}

export type ExperiencePreviewUtils = ReturnType<typeof useExperiencePreviewUtils>
