import React, { memo } from 'react'
import { times } from 'lodash'
import { Layer, Line } from 'react-konva'

import * as CanvasConstants from 'pages/orchestration/workflows/edit/utils/constants'

export const BackgroundLayer: React.FC = memo(() => {
  const maxWidth = CanvasConstants.GRID_SIZE_X * CanvasConstants.GRID_CELL_WIDTH
  const maxHeight = CanvasConstants.GRID_SIZE_Y * CanvasConstants.GRID_CELL_HEIGHT
  const strokeColor = '#E1E2E6'
  const strokeWidth = 1

  return (
    <Layer>
      {times(CanvasConstants.GRID_SIZE_X + 1, index => (
        <Line
          key={index}
          points={[0, index * CanvasConstants.GRID_CELL_WIDTH, maxWidth, index * CanvasConstants.GRID_CELL_WIDTH]}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        />
      ))}

      {times(CanvasConstants.GRID_SIZE_Y + 1, index => (
        <Line
          key={index}
          points={[index * CanvasConstants.GRID_CELL_HEIGHT, 0, index * CanvasConstants.GRID_CELL_HEIGHT, maxHeight]}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        />
      ))}
    </Layer>
  )
})
