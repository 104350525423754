import React from 'react'
import Box from '@mui/material/Box'

import { Badge } from 'components/ui-kit/badge/Badge'
import { AssetPolicyAssetDTO } from '@ketch-com/figurehead'
import { ASSET_TYPE_NAMES } from 'interfaces/assets'

type Props = {
  resources: AssetPolicyAssetDTO[]
}

export const ResourcesCellRenderer: React.FC<Props> = ({ resources }) => (
  <Box display="flex" flexDirection="column">
    {resources.map((r, index) => (
      <Box
        key={`${r?.type || ''}_${r?.named || ''}_${r.labeled}_${index}`}
        mb={index !== resources.length - 1 ? 2.25 : 1.75}
      >
        <Badge>{ASSET_TYPE_NAMES[r?.type || 0]}</Badge>
      </Box>
    ))}
  </Box>
)
