import { IBaseActivityParams } from './IBaseActivityParams'

export type ContextVarDTO = {
  code: string
  name?: string
  required?: boolean
}

export interface ITaskActivityParams extends IBaseActivityParams {
  details: string
  activity?: string

  // Notes
  hasResolutionNotes: boolean
  isResolutionNotesRequired: boolean

  // Attachments
  hasResolutionAttachments: boolean
  isResolutionAttachmentsRequired: boolean

  // Attachments for Data Subject
  hasDsrResolutionAttachments: boolean
  isDsrResolutionAttachmentsRequired: boolean
  dsrResolutionDescription?: string

  // Data Subject Variables
  hasDataSubjectVariables: boolean
  dataSubjectVariables: ContextVarDTO[]

  // Outcome Variables
  hasOutcomeVariables: boolean
  outcomeVariables: ContextVarDTO[]

  // Identifiers
  hasIdentitySpaces: boolean
  identitySpaces: ContextVarDTO[]
}

export enum TaskActivityParamsKeys {
  assignee = 'assignee',
  details = 'details',
  description = 'description',
  code = 'code',

  // Notes
  hasResolutionNotes = 'hasResolutionNotes',
  isResolutionNotesRequired = 'isResolutionNotesRequired',

  // Attachments
  hasResolutionAttachments = 'hasResolutionAttachments',
  isResolutionAttachmentsRequired = 'isResolutionAttachmentsRequired',

  // Attachments for Data Subject
  hasDsrResolutionAttachments = 'hasDsrResolutionAttachments',
  isDsrResolutionAttachmentsRequired = 'isDsrResolutionAttachmentsRequired',
  dsrResolutionDescription = 'dsrResolutionDescription',

  // Data Subject Variables
  hasDataSubjectVariables = 'hasDataSubjectVariables',
  dataSubjectVariables = 'dataSubjectVariables',

  // Outcome Variables
  hasOutcomeVariables = 'hasOutcomeVariables',
  outcomeVariables = 'outcomeVariables',

  // Outcome Variables
  hasIdentitySpaces = 'hasIdentitySpaces',
  identitySpaces = 'identitySpaces',
}
