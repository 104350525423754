import React, { useState } from 'react'
import clsx from 'clsx'
import { ButtonBase, CircularProgress, Popover } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { MaybeNull } from 'interfaces/common'
import { List, ListItemType as BaseListItemType } from 'components/ui-kit/list/List'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseButton: {
        display: 'inline-flex',
        alignItems: 'center',
        fontFamily: typography.fontFamily,
        borderRadius: 5,
        letterSpacing: '0.01em',
      },
      baseButtonDisabled: {
        opacity: 0.3,
      },
      baseContent: {
        flexGrow: 1,
        display: 'inline-flex',
        alignItems: 'center',
      },
      baseIcon: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      progressIcon: {},
      chevronIcon: {},

      // Popover
      popoverRoot: {
        marginTop: 5,
        borderRadius: 5,
      },
      popover: {
        borderRadius: 5,
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.2)',
        minWidth: 170,
      },

      // Sizes:
      sizeRegular: {
        padding: '2px 10px 3px',
        lineHeight: typography.pxToRem(18),
        fontSize: typography.pxToRem(14),
        fontWeight: 600,
        height: 28,
        minWidth: 80,

        '& $baseContent': {
          marginRight: 8,
        },

        '& $progressIcon': {
          marginRight: 6,
        },

        '& $chevronIcon': {
          marginTop: 3,
          fontSize: typography.pxToRem(22),
        },
      },
      sizeBig: {
        padding: '8px 18px 9px',
        lineHeight: typography.pxToRem(18),
        fontSize: typography.pxToRem(14),
        fontWeight: 600,
        minWidth: 100,

        '& $baseContent': {
          marginRight: 10,
        },

        '& $progressIcon': {
          marginRight: 6,
        },

        '& $chevronIcon': {
          marginTop: 3,
          fontSize: typography.pxToRem(24),
        },
      },

      // Types:
      typePrimary: {
        color: palette.white.main,
        background: palette.sphere.main,

        '& $progressIcon': {
          color: palette.white.main,
        },

        '&:hover': {
          background: palette.royalBlue.main,
          cursor: 'pointer',
        },

        '&:active': {
          background: palette.persianBlue.main,
          cursor: 'pointer',
        },
      },

      typeSecondary: {
        color: palette.sphere.main,
        background: palette.white.main,
        border: `1px solid ${palette.sphere.main}`,

        '& $progressIcon': {
          color: palette.royalBlue.main,
        },

        '&:hover': {
          color: palette.royalBlue.main,
          border: `1px solid ${palette.royalBlue.main}`,
          cursor: 'pointer',
        },

        '&:active': {
          color: palette.persianBlue.main,
          border: `1px solid ${palette.persianBlue.main}`,
          background: palette.naviBlue.main,
          cursor: 'pointer',
        },
      },
      typeTertiary: {
        color: palette.sphere.main,
        background: palette.fadedGrey.main,

        '& $progressIcon': {
          color: palette.royalBlue.main,
        },

        '&:hover': {
          color: palette.royalBlue.main,
          background: palette.iron.main,
          cursor: 'pointer',
        },

        '&:active': {
          color: palette.persianBlue.main,
          background: palette.fadedDarkGrey.main,
          cursor: 'pointer',
        },
      },
    }),
  { name: 'ButtonActions' },
)

type BUTTON_VARIANTS = 'primary' | 'secondary' | 'tertiary'

interface Props {
  children?: React.ReactNode
  /** Checks if the component should be disabled */
  disabled?: boolean
  /** Checks if the component should be in loading state */
  pending?: boolean
  /** Component variant - reflects the colour of border and background */
  variant?: BUTTON_VARIANTS
  /** Component size - reflects the size of the button */
  size?: 'regular' | 'big'
  /** Component custom className */
  className?: any
  /** Component items list to be displayed in popover */
  items: BaseListItemType[]
}

/**
 * -
 */
export const ButtonActions: React.FC<Props> = ({
  disabled = false,
  pending = false,
  variant = 'primary',
  size = 'regular',
  className,
  children,
  items,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<MaybeNull<HTMLButtonElement>>(null)

  const isOpen = Boolean(anchorEl)
  const popoverId = isOpen ? 'button-actions-popover' : undefined

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!disabled && !pending) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const buttonClassName = clsx(
    classes.baseButton,
    {
      [classes.typePrimary]: variant === 'primary',
      [classes.typeSecondary]: variant === 'secondary',
      [classes.typeTertiary]: variant === 'tertiary',

      [classes.sizeRegular]: size === 'regular',
      [classes.sizeBig]: size === 'big',
    },
    className,
  )

  return (
    <>
      <ButtonBase
        classes={{
          root: buttonClassName,
          disabled: classes.baseButtonDisabled,
        }}
        disabled={disabled || pending}
        disableRipple
        disableTouchRipple
        onClick={handleOpen}
      >
        <div className={classes.baseContent}>{children}</div>
        <div className={classes.baseIcon}>
          {pending && (
            <CircularProgress
              size={size === 'big' ? 24 : 16}
              classes={{
                root: classes.progressIcon,
              }}
            />
          )}

          {isOpen ? (
            <KeyboardArrowUpIcon className={classes.chevronIcon} />
          ) : (
            <KeyboardArrowDownIcon className={classes.chevronIcon} />
          )}
        </div>
      </ButtonBase>

      <Popover
        id={popoverId}
        open={isOpen}
        classes={{
          root: classes.popoverRoot,
          paper: classes.popover,
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List
          size="regular"
          items={items.map(item => ({
            ...item,
            onClick: e => {
              if (item.onClick) {
                item.onClick(e)
              }
              handleClose()
            },
          }))}
        />
      </Popover>
    </>
  )
}
