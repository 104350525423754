import pluralize from 'pluralize'
import { LinkChip } from 'components/ui-kit/chip/LinkChip'
import { RoutesManager } from 'utils/routing/routesManager'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import React from 'react'
import { Box, styled } from '@mui/material'
import { useNavigate } from 'react-router'
import { AssetManagerWidgetLayout } from '../../../../components'
import { ProcessingActivityDTO } from '@ketch-com/figurehead'
import { Icon, theme } from '@ketch-com/deck'

const ChipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(0.5),
}))

type Props = {
  processingActivities: ProcessingActivityDTO[]
}

export const ProcessingActivitiesWidget: React.FC<Props> = ({ processingActivities }) => {
  const navigate = useNavigate()

  return (
    <AssetManagerWidgetLayout
      title="Processing Activities"
      subTitle={`${processingActivities.length || 0} Associated Processing ${pluralize('Activity')}`}
    >
      {!!processingActivities && processingActivities.length ? (
        <ChipContainer>
          {processingActivities.map(activity => (
            <LinkChip
              text={activity.name}
              onClick={() =>
                navigate(RoutesManager.policyCenter.processingActivities.view.overview.root.getURL({ id: activity.id }))
              }
            />
          ))}
        </ChipContainer>
      ) : (
        <Box textAlign="center">
          <EmptyState
            title="No Processing Activities Assigned"
            icon={<Icon name={'ORelated'} iconColor={theme.palette.darkDuskFaded.main} />}
            description="This system is not associated with any processing activities."
            variant="page"
          />
        </Box>
      )}
    </AssetManagerWidgetLayout>
  )
}
