import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { updateDataSystemGlobalCustomAttribute } from '../fetchers/updateDataSystemGlobalCustomAttribute'

const useCustomMutation = createUseMutation({
  mutationFn: updateDataSystemGlobalCustomAttribute,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateDataSystemGlobalCustomAttribute = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
