import { PolicyDTO } from '@ketch-com/figurehead'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { FUNCTION_NAME_ENUM, OBLIGATION_ENUM } from '../constants'
import { IDsrTransponderFormInitialValues } from '../interfaces'

type GetObligationUrlByFunctionNameArgs = {
  functionName: string
  canonicalRightCode: string
  obligation: string
}

const getObligationUrlByFunctionName = ({
  functionName,
  obligation,
}: GetObligationUrlByFunctionNameArgs): string | undefined => {
  switch (functionName) {
    case FUNCTION_NAME_ENUM.NULLIFY: // nullify
      return OBLIGATION_ENUM.NULLIFY
    case FUNCTION_NAME_ENUM.HASH: // hashed
      return OBLIGATION_ENUM.UPDATE_AS_HASHED
    case FUNCTION_NAME_ENUM.MASK: // masked
      return OBLIGATION_ENUM.UPDATE_AS_MASKED
    case FUNCTION_NAME_ENUM.SET_VALUE: // update
      return OBLIGATION_ENUM.UPDATE
    /* the default case will be reached for "legacy" policies (those without functionName property).
    In which case the obligation should be set as received with no mapping/translation */
    default:
      return !!obligation ? obligation : OBLIGATION_ENUM.DELETE
  }
}

type Args = {
  policies: PolicyDTO[]
  step: ICanvasStep
  canonicalRightCode: string
}

export const getClientSidePolicyRepresentation = ({
  policies,
  step,
  canonicalRightCode,
}: Args): IDsrTransponderFormInitialValues => {
  const payload: IDsrTransponderFormInitialValues = {
    policyStatements: {},
    selectedDatabases: [],
    isConfigureDatasetAttributeModalValid: true,
    attributeUpdateErrors: {},
    code: step?.code || 'ketch_transponder',
    description: step?.description || 'Transponder',
  }

  policies?.forEach?.((pol: PolicyDTO) => {
    payload.selectedDatabases = [...payload.selectedDatabases, pol?.resource || '']
    const levelOneStatements: any = {}
    const levelTwoStatements: any = {}
    const statementEntries = Object.entries(pol?.statements || {})

    for (const [datasetCode, value] of statementEntries) {
      const { effects, obligation } = value
      /* if "obligation" is truthy, we know there there are ZERO attribute level effects */
      if (obligation) {
        levelOneStatements[datasetCode] = { effect: obligation, assetType: 'dataset' }
        /* reaching else block means there are attribute level effects */
      } else {
        levelOneStatements[datasetCode] = { effect: obligation, assetType: 'dataset' }
        for (const [attributeCode, value] of Object.entries(effects || {})) {
          levelTwoStatements[attributeCode] = {
            effect: getObligationUrlByFunctionName({
              canonicalRightCode,
              functionName: value?.functionName || '',
              obligation: value.obligation || '',
            }),
            assetType: 'attribute',
            parentAssetCode: datasetCode,
            ...(value.value && { value: value.value }),
          }
        }
      }
    }

    payload.policyStatements[pol?.resource || ''] = {
      resource: pol?.resource || '',
      statements: {
        ...levelOneStatements,
        ...levelTwoStatements,
      },
      ...(pol.id && { id: pol.id }),
    }
  })

  return { ...payload }
}
