import { useNavigate } from 'react-router-dom'

import { Box, Grid, Typography } from '@mui/material'
import { BusinessFunctionDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { Avatar, AvatarSize, Card } from '@ketch-com/deck'
import { getTextColorForTile } from '../../utils/colorMappings'
import { useDeleteBusinessFunction } from 'api/businessFunctions/queries/useDeleteBusinessFunction'
import { FormMode } from 'interfaces/formModes/formMode'

export const BusinessFunctionTile = ({
  businessFunction,
  onDelete,
}: {
  businessFunction: BusinessFunctionDTO
  onDelete: () => void
}) => {
  const navigate = useNavigate()

  const { mutateAsync: handleDeleteBusinessFunction } = useDeleteBusinessFunction({
    onSuccess: () => onDelete(),
    onError: () => {},
  })

  const navigateToBusinessFunctionView = (code: string | undefined) =>
    navigate(RoutesManager.policyCenter.businessFunctions.view.root.getURL({ code }))

  const deleteBusinessFunction = () => {
    handleDeleteBusinessFunction({ params: { code: businessFunction.code || '' } })
  }

  const editBusinessFunction = () =>
    navigate(
      RoutesManager.policyCenter.businessFunctions.upsert.root.getURL({
        code: businessFunction.code,
        formMode: FormMode.EDIT,
      }),
    )

  return (
    <>
      <Grid item>
        <Card
          sx={{
            minHeight: 255,
            '&:hover': {
              cursor: 'pointer',
            },
            padding: 2,
            '& .MuiCardContent-root': {
              '& .DeckCardAvatarContent': {
                '& .DeckCardAvatarFooter': {
                  marginTop: 'auto',
                },
              },
            },
          }}
          moreMenuItems={[
            { label: 'Edit', onClick: editBusinessFunction },
            { label: 'Delete', onClick: deleteBusinessFunction },
          ]}
          cardVariant="avatar"
          cardTitle={businessFunction.name}
          avatarComponent={
            <Avatar
              size={AvatarSize.xlarge}
              textColor={getTextColorForTile(businessFunction.labelColor)}
              backgroundColor={businessFunction.labelColor}
            >
              {businessFunction.label}
            </Avatar>
          }
          onClick={() => navigateToBusinessFunctionView(businessFunction.code)}
          cardContent={
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography
                variant="smallBody"
                color="darkDuskFaded.main"
                sx={{
                  display: '-webkit-box',
                  '-webkit-line-clamp': '3',
                  '-webkit-box-orient': 'vertical',
                  overflow: 'hidden',
                }}
              >
                {businessFunction.description}
              </Typography>
            </Box>
          }
          footerChips={[{ label: `Purposes: ${String(businessFunction.purposes?.length || 0)}` }]}
        />
      </Grid>
    </>
  )
}
