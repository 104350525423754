import React from 'react'
import { Grid, Box } from '@mui/material'

import { FormInput } from 'components/form/FormInput'
import { FormRow } from '@ketch-com/deck'

export const UserProfileFormSectionDetails: React.FC = () => (
  <FormRow>
    <Box display="flex" flexDirection="column" gap={3}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormInput required fullWidth label="First Name" formPropertyName="firstName" placeholder="Example: James" />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormInput required fullWidth label="Last Name" formPropertyName="lastName" placeholder="Example: Smith" />
        </Grid>
      </Grid>
    </Box>
  </FormRow>
)
