import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { getInitialValues, getValidationSchema } from 'pages/assetManager/schemas/upsert/utils'
import { Group } from 'components/ui-layouts/group/Group'
import { Button } from '@ketch-com/deck'
import { UpsertLayout } from 'components/ui-layouts/upsertLayout/UpsertLayout'
import { UpsertLayoutFooter } from 'components/ui-layouts/upsertLayout/UpsertLayoutFooter'
import { MaybeNull } from 'interfaces/common'
import { EditSchemaFormValues } from 'interfaces/assets'
import { AssetSummaryDTO } from '@ketch-com/figurehead'

import { LabelDefinitionDTO as LabelDTO } from '@ketch-com/figurehead'
import { EditAssetForm } from 'pages/assetManager/components'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  isReady: boolean
  isUpdatingSchema: boolean
  onSubmit: (values: EditSchemaFormValues) => Promise<void>
  schema: MaybeNull<AssetSummaryDTO>
  labels: MaybeNull<LabelDTO[]>
}

export const SchemaUpsertView: React.FC<Props> = ({ isReady, onSubmit, schema, isUpdatingSchema }) => {
  const navigate = useNavigate()
  const initialValues = useMemo(() => getInitialValues({ schema }), [schema])
  const validationSchema = useMemo(getValidationSchema, [])

  return (
    <UpsertLayout
      isReady={isReady}
      title="Edit Schema"
      breadcrumbs={[
        { title: 'Data Map', link: RoutesManager.assetManager.assets.root.getURL() },
        { title: 'Assets', link: RoutesManager.assetManager.assets.list.getURL() },
        {
          title: schema?.asset?.resource?.name || schema?.asset?.resource?.id,
          link: RoutesManager.assetManager.assets.detail.root.getURL({ code: schema?.asset?.resource?.id || '' }),
        },
        { title: 'Edit Schema' },
      ]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm, isValid }) => (
        <>
          <EditAssetForm assetSummary={schema} />

          <UpsertLayoutFooter>
            <Group>
              <Button
                pending={isSubmitting}
                color="secondary"
                size="large"
                onClick={() => {
                  navigate(
                    RoutesManager.assetManager.assets.detail.root.getURL({
                      code: schema?.asset?.resource?.id || '',
                      resourceTypeCode: schema?.asset?.resource?.resourceType?.code || '',
                    }),
                  )
                }}
              >
                Cancel
              </Button>
              <Button disabled={!isValid} size="large" onClick={submitForm} pending={isSubmitting}>
                Save
              </Button>
            </Group>
          </UpsertLayoutFooter>
        </>
      )}
    </UpsertLayout>
  )
}
