import { usePlan } from 'api/planAndBilling/queries/usePlan'
import { useUsage } from 'api/planAndBilling/queries/useUsage'
import { GetUsageGranularity } from '@ketch-com/harbormaster/dist/organization/plans.schemas'
import { useSubscription } from 'api/planAndBilling/mutations/useSubscription'
import { useBillingPortal } from 'api/planAndBilling/mutations/useBillingPortal'
import { SubscriptionAction } from '@ketch-com/harbormaster/dist/organization/subscriptions.schemas'
import { OrgPlanIDs } from 'components/planAndBilling/common/constants/planAndBilling'
import { PlanStatus, BillingStatus } from '@ketch-com/harbormaster/dist/organization/plans.schemas'

export const usePlanAndBillingUtils = () => {
  const { data: orgPlan, isLoading: isLoadingOrgPlan } = usePlan({})
  const { data: orgUsage, isLoading: isLoadingOrgUsage } = useUsage({
    params: { granularity: GetUsageGranularity.month },
  })

  const { mutate: createSubscription, isLoading: isCreatingSubscription } = useSubscription({
    onSuccess: ({ data }) => {
      if (data.action === SubscriptionAction.Checkout && data.checkoutURL) {
        window.location.href = data.checkoutURL
      }
      if (data.action === SubscriptionAction.Downgraded) {
        window.location.reload()
      }
    },
  })
  const { mutate: createBillingPortal, isLoading: isCreatingBillingPortal } = useBillingPortal({
    onSuccess: ({ data }) => {
      if (data.billingPortalURL) {
        window.location.href = data.billingPortalURL
      }
    },
  })

  const isReady = !isLoadingOrgPlan && !isLoadingOrgUsage

  const isOrgOnFreePlan = orgPlan?.planProductID === OrgPlanIDs?.free
  const isFreePlanInGrace = isOrgOnFreePlan && orgPlan?.planStatus === PlanStatus.in_grace_period
  const isFreePlanSuspended = isOrgOnFreePlan && orgPlan?.planStatus === PlanStatus.suspended
  const isOrgOnStarterPlan = orgPlan?.planProductID === OrgPlanIDs?.starter
  const isStarterPlanInGrace = isOrgOnStarterPlan && orgPlan?.planStatus === PlanStatus.in_grace_period
  const isStarterPlanSuspended = isOrgOnStarterPlan && orgPlan?.planStatus === PlanStatus.suspended
  const isOrgOnPlusPlan = orgPlan?.planProductID === OrgPlanIDs?.plus
  const isOrgOnProPlan = orgPlan?.planProductID === OrgPlanIDs?.pro
  const isBillingInGrace = orgPlan?.billingStatus === BillingStatus.in_grace_period
  const isBillingMissed = orgPlan?.billingStatus === BillingStatus.missed

  return {
    isReady,
    orgPlan,
    orgUsage,
    createSubscription,
    isCreatingSubscription,
    createBillingPortal,
    isCreatingBillingPortal,
    isOrgOnFreePlan,
    isFreePlanInGrace,
    isFreePlanSuspended,
    isOrgOnStarterPlan,
    isStarterPlanInGrace,
    isStarterPlanSuspended,
    isOrgOnPlusPlan,
    isOrgOnProPlan,
    isBillingInGrace,
    isBillingMissed,
  }
}
