import { useInstalledDataSystemProcessingActivities } from 'api/dataSystems/queries/useInstalledDataSystemProcessingActivities'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useAssignedDataCategoriesInfinite } from 'api/dataSystemsCategories/queries/useAssignedDataCategoriesInfinite'
import { useIsEntitled, useIsPermitted } from 'utils/hooks'

export const useDataSystemWidgetsUtils = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const { isEntitled } = useIsEntitled()

  // Processing activities for this data system
  const { data: processingActivities } = useInstalledDataSystemProcessingActivities({ params: { id } })

  // Data categories for this data system
  const { data: dataAssignedCategories } = useAssignedDataCategoriesInfinite({
    installedDataSystemId: id,
    limit: 9000,
  })

  return {
    id,
    navigate,
    isPermitted,
    isEntitled,
    processingActivities,
    dataAssignedCategories,
  }
}

export type DataSystemWidgetsUtils = ReturnType<typeof useDataSystemWidgetsUtils>
