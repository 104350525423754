import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchPolicyV2 } from '../fetchers/fetchPolicyV2'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.policy,
  queryFn: fetchPolicyV2,
  select: res => res?.data?.policy || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const usePolicyV2 = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
