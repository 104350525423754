import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'

import { App } from 'pages/App'
import 'i18n/config'
import 'assets/styles/index.css'

const tagManagerArgs = {
  gtmId: (window as any).figureheadConfig.REACT_APP_FIGUREHEAD_GTM_ID,
}

if (tagManagerArgs.gtmId) {
  TagManager.initialize(tagManagerArgs)
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)
