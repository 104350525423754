import React from 'react'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { ContentGroup, DataGrid, EmptyState } from '@ketch-com/deck'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateVersions.svg'
import { Box } from '@mui/material'
import { cookieVersionsTabGridColumns } from './utils/cookieVersionsTabDataGridUtils'
import { CookieViewTabActionCell } from './utils/CookieViewTabActionCell'

type Props = {
  versions: CookieDTO[]
  cookie: CookieDTO
}

export const CookieVersionsTab: React.FC<Props> = ({ versions, cookie }) => {
  const { queries, setQueryParam, removeQueryParam } = useQueryParams<{ version?: string }>()

  const { name, code, version } = cookie
  const currentVersion = queries.version ? +queries.version : version

  const dataGridCols: GridColDef<CookieDTO>[] = [
    ...cookieVersionsTabGridColumns,
    {
      align: 'left',
      field: 'actions',
      headerAlign: 'left',
      headerName: '',
      sortable: false,
      maxWidth: 100,
      renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
        return (
          <CookieViewTabActionCell
            disabled={row.version === currentVersion}
            row={row}
            onClick={() => {
              if (row.latest) {
                removeQueryParam('version')
              } else {
                setQueryParam('version', row.version!)
              }
            }}
          />
        )
      },
    },
  ]
  return (
    <>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
          { title: 'Cookies', link: RoutesManager.policyCenter.cookies.root.getURL() },
          {
            title: name || code,
            link: RoutesManager.policyCenter.cookies.view.root.getURL({ code }),
          },
          { title: 'Versions' },
        ]}
      />

      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup title="Version History" titleVariant="h3" variant="inner-page-header" />

        {versions.length ? (
          <>
            <Box>
              <DataGrid
                sx={{
                  paddingLeft: 'unset',
                  paddingRight: 'unset',
                  paddingTop: 'unset',
                }}
                autosizeOnMount
                columns={dataGridCols}
                disableChildrenSorting
                disableColumnMenu
                disableColumnPinning
                disableColumnReorder
                disableColumnResize
                getRowId={row => row.ID}
                hideFooter
                rows={versions}
                getRowHeight={() => 'auto'}
              />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              backgroundColor: 'bone.main',
              padding: '34px',
              borderRadius: '11px',
            }}
          >
            <EmptyState
              customIcon={<EmptyStateIcon />}
              title="No versions exist for the selected cookie"
              subTitle="As you make changes to this cookie, you can view that history here"
            />
          </Box>
        )}
      </Box>
    </>
  )
}
