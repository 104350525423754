import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchPolicyScopeTemplates } from 'api/policyScopes/fetchers/fetchPolicyScopeTemplates'
import { ApiQueryKeys } from 'api/common/queryKeys'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.policyScopeTemplates,
  queryFn: fetchPolicyScopeTemplates,
  select: res => res?.data?.policyScopes || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const usePolicyScopeTemplates = (config?: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config?.params,
      start: 0,
      limit: 1000,
    },
  })
