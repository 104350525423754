import { ContentGroup } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'
import { useDataSystemsContext } from '../context/DataSystemContext'
import ChipContentRadioEditInfoRow from 'components/InfoRow/ChipContentRadioEditInfoRow'
import { inherentRiskLevelColors, inherentRiskLevelOptions } from '../types'
import { InherentRiskDTO } from '@ketch-com/figurehead'

export const RiskManagement: React.FC = () => {
  const { appDescriptor, updatedDataSystem, handleSystemFieldUpdates } = useDataSystemsContext()

  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.systems.root.getURL() },
    { title: 'Systems', link: RoutesManager.systems.systems.root.getURL() },
    { title: appDescriptor?.dataSystem?.name, link: RoutesManager.systems.id.root.getURL({ id: appDescriptor?.id }) },
    { title: 'Risk Management' },
  ]

  const selectedValue =
    inherentRiskLevelOptions[updatedDataSystem ? updatedDataSystem.inherentRisk! : appDescriptor?.inherentRisk!].value
  const chipBackgroundColor = inherentRiskLevelColors[selectedValue]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box display="flex" flexDirection="column" gap={3} mb={6}>
        <ContentGroup title="Risk Management" titleVariant="h3" />
        <ChipContentRadioEditInfoRow
          allowDelete={false}
          title="Risk Level"
          options={inherentRiskLevelOptions}
          value={selectedValue}
          chipColorPalette={chipBackgroundColor}
          setValue={value => handleSystemFieldUpdates({ inherentRisk: value as InherentRiskDTO })}
        />
      </Box>
    </>
  )
}
