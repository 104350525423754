import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { joinNonEmpty } from 'utils/helpers/joinNonEmpty'
import { MaybeNull } from 'interfaces/common'
import { UserDTO } from 'interfaces/users/users'
import { settings } from 'utils/routing/routes/settings'
import { Button, Spinner, Status, StatusState, StatusVariant, InfoRow, theme } from '@ketch-com/deck'
import { UserStatus } from '../../../../interfaces/users/userStatus'
import { ChangePasswordModal } from '../edit/changePassword/ChangePasswordModal'
import { UserTeamsInfoRow } from './UserTeamsInfoRow'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  isReady: boolean
  user: MaybeNull<UserDTO>
}

export const UserProfileView: React.FC<Props> = ({ isReady, user }) => {
  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] = useState(false)
  const navigate = useNavigate()
  const userName = joinNonEmpty([user?.firstName, user?.lastName])

  const breadcrumbs = [
    {
      title: 'Settings',
      link: RoutesManager.settings.root.getURL(),
    },
    { title: 'Account', link: RoutesManager.settings.profile.root.getURL() },
  ]

  if (!isReady) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box sx={{ position: 'relative', backgroundColor: theme.palette.white.main, borderRadius: 11 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="h2">{userName ? userName : user?.email}</Typography>
            {user?.status === UserStatus.ACTIVE ? (
              <Status variant={StatusVariant.outlined} status={StatusState.success} label="Active" icon="Dot" />
            ) : (
              <Status variant={StatusVariant.outlined} status={StatusState.draft} label="Invited" icon="Dot" />
            )}
          </Box>

          <Box display="flex" alignItems="center" gap={1}>
            <Button size="medium" color="secondary" onClick={() => navigate(settings.profile.upsert.root.getURL())}>
              Edit
            </Button>
            <Button onClick={() => setIsOpenChangePasswordModal(true)} size="medium">
              Change Password
            </Button>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={4}>
          <InfoRow title="Name">
            <Typography variant="body">{userName || <EmptyValueRenderer />}</Typography>
          </InfoRow>
          <InfoRow title="Email Address">
            <Typography variant="body">{user?.email || <EmptyValueRenderer />}</Typography>
          </InfoRow>
          <InfoRow title="Organizations">
            <Typography variant="body">{user?.organization?.name || <EmptyValueRenderer />}</Typography>
          </InfoRow>
          <UserTeamsInfoRow userId={user?.ID} />
        </Box>

        {isOpenChangePasswordModal && <ChangePasswordModal onClose={() => setIsOpenChangePasswordModal(false)} />}
      </Box>
    </>
  )
}
