import { APIListRequestParams } from 'api/common/utils'
import { IdentitySpacesCatalogDTO } from 'interfaces/identitySpaces/identitySpace'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  includeIssues?: boolean
}>

export const fetchIdentitySpacesCatalog = ({ includeMetadata, includeIssues, start, limit }: Params) =>
  API.get<IdentitySpacesCatalogDTO>(
    formatRequestString({
      entityUrl: `/api/identity-spaces/catalog`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
        },
      },
    }),
  )
