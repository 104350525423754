import {
  FormFieldDTO,
  FormFieldTypeDTO,
  FormFieldVariantDTO,
  FormFieldWidthDTO,
  PreviewConfigurationExperienceFormFieldDTO,
} from '@ketch-com/figurehead'

const fieldCodesThatAreRequired = ['email', 'firstName', 'lastName']

export const getPreviewConfigurationExperienceFormFieldDTOfromFormFieldDTO = (
  formField: FormFieldDTO,
): PreviewConfigurationExperienceFormFieldDTO => {
  if (!formField) return {} as PreviewConfigurationExperienceFormFieldDTO

  const isTextArea =
    formField.type === FormFieldTypeDTO.TextFormFieldType &&
    formField.variant === FormFieldVariantDTO.TextareaFormFieldVariant
  const isCheckbox = formField.type === FormFieldTypeDTO.CheckboxFormFieldType

  const inferredWidth = isTextArea || isCheckbox ? 'full' : 'half'

  const payload: PreviewConfigurationExperienceFormFieldDTO = {
    id: formField?.id,
    required: fieldCodesThatAreRequired.includes(formField?.code || ''),
    label: formField.title,
    hint: formField.hint,
    category: formField.category,
    type: formField.type,
    variant: formField.variant,
    maxLength: 256,
    name: formField.code,
    identitySpaceCode: formField.identitySpace?.code,
    ...(!!formField.options && {
      options: formField.options || [],
    }),
    width: inferredWidth as FormFieldWidthDTO,
  }

  return payload
}
