import React, { useContext, useState } from 'react'
import { useDebounce } from 'react-use'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { Checkbox, Spinner } from '@ketch-com/deck'
import { Filter } from 'components/MegaFilter/interfaces'
import pluralize from 'pluralize'
import { CustomMegaFilterContext } from '../context/MegaFilterContext'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'

type Props = {
  data: Filter
  isLoading: boolean
}

export const MegaFilterCheckboxList: React.FC<Props> = ({ data: filter, isLoading }) => {
  const {
    getParamValueByKey,
    replaceParams,
    clearAllEntriesForKey,
    removeQueryParamFromList,
    addParamValue,
    hasValue,
  } = useContext(CustomMegaFilterContext)

  const [searchString, setSearchString] = useState<string>('')
  const [searchInnerValue, setSearchInnerValue] = useState<string>('')
  const selected = getParamValueByKey({ key: filter.key })
  const selectAll = selected.length === filter.items.length

  useDebounce(
    () => {
      if (searchString) {
        setSearchInnerValue(searchString)
      } else {
        setSearchInnerValue('')
      }
    },
    500,
    [searchString],
  )

  return (
    <Box display="flex" flexWrap="wrap" flexDirection="column" alignItems="center" width="100%">
      <Box padding="0 12px" width="100%">
        <SearchTextInput
          fullWidth
          onChange={value => setSearchString(value)}
          value={searchString}
          placeholder={`Search ${filter.items.length} ${pluralize(filter.label, filter.items.length)}`}
          showClearButton
          size="small"
          sx={{ mb: 1 }}
        />
      </Box>
      {/* loader */}
      {isLoading && (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="16px" thickness={2} />
        </Box>
      )}
      {/* select all checkbox */}

      {filter.includeAll && (
        <Box display="flex" alignItems="center" flexWrap="wrap" padding="0px 3px" width="100%">
          <Checkbox
            id="all-subject-types"
            name="all-subject-types"
            title="All"
            checked={selectAll}
            indeterminate={selected.length > 0}
            onChange={() => {
              if (selectAll) {
                clearAllEntriesForKey(filter.key)
              } else {
                replaceParams({ [filter.key]: filter.items.map(item => item.id).join(','), page: '0' })
              }
            }}
          />
          <Typography>All</Typography>
        </Box>
      )}
      {/* Checklist */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        sx={{ bgcolor: 'background.paper', maxHeight: 200, overflow: 'scroll', width: '100%' }}
      >
        {filter.items
          .filter(
            item =>
              item.label.toLowerCase().includes(searchInnerValue.toLowerCase()) ||
              item.id.toLowerCase().includes(searchInnerValue.toLowerCase()) ||
              hasValue(filter.key, item.id), // Always show checked items
          )
          .map(item => (
            <Box key={item.id} display="flex" alignItems="center" padding="0px 3px">
              <Checkbox
                id={item.id}
                name={item.id}
                title={item.label}
                value={hasValue(filter.key, item.id)}
                checked={hasValue(filter.key, item.id)}
                onChange={() => {
                  if (hasValue(filter.key, item.id)) {
                    removeQueryParamFromList({ key: filter.key, value: item.id })
                  } else {
                    addParamValue(filter.key, item.id)
                  }
                }}
              />
              <Typography>{item.label}</Typography>
            </Box>
          ))}
      </Box>
    </Box>
  )
}
