import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { useLocation, useNavigate, useMatch } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { Tabs } from 'components/ui-kit/tabs/Tabs'
const PREFIX = 'PoliciesControlsTabs'

const classes = {
  tab: `${PREFIX}-tab`,
}

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
  [`& .${classes.tab}`]: {
    marginLeft: spacing(1.5),
  },
}))

type Props = {}

export const PoliciesControlsTabs: React.FC<Props> = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const replaceConditional = (path: string) => path.replace('?', '')

  const policiesRouteDetails = useMatch(
    replaceConditional(RoutesManager.insights.dataRisks.policiesControls.policies.root.path),
  )
  const securityControlsRouteDetails = useMatch(
    replaceConditional(RoutesManager.insights.dataRisks.policiesControls.securityControls.root.path),
  )

  const code = policiesRouteDetails?.params?.code || securityControlsRouteDetails?.params?.code

  return (
    <StyledBox mb={1.5} sx={({ palette }) => ({ borderBottom: `1px solid ${palette.iron.main}` })}>
      <Tabs
        tabClassName={classes.tab}
        config={[
          {
            key: 1,
            title: 'Policies',
            onClick: () => navigate(RoutesManager.insights.dataRisks.policiesControls.policies.root.getURL({})),
            active:
              location.pathname === RoutesManager.insights.dataRisks.policiesControls.policies.root.getURL({ code }) ||
              location.pathname === RoutesManager.insights.dataRisks.policiesControls.root.getURL(),
          },
          {
            key: 2,
            title: 'Security Controls',
            onClick: () => navigate(RoutesManager.insights.dataRisks.policiesControls.securityControls.root.getURL({})),
            active:
              location.pathname ===
              RoutesManager.insights.dataRisks.policiesControls.securityControls.root.getURL({ code }),
          },
        ]}
      />
    </StyledBox>
  )
}
