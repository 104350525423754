import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import React from 'react'
import { RoutesManager } from 'utils/routing/routesManager'
import { Box } from '@mui/material'
import { TransponderCreateSuccess } from './components'
import { TransponderCreateForm } from './components'
import { useTransponderCreate } from './context'
import { AppCrashView } from 'components/ui-routing/AppCrashView'

export const TranspondersCreate: React.FC = () => {
  const { status } = useTransponderCreate()

  const breadcrumbs = [
    { title: 'Developers', link: RoutesManager.developers.transponders.list.getURL() },
    { title: 'Transponders', link: RoutesManager.developers.transponders.list.getURL() },
    { title: 'Create Transponder', link: RoutesManager.developers.transponders.create.getURL() },
  ]

  return (
    <Box paddingBottom={3}>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs} />
      {status === 'inProgress' && <TransponderCreateForm />}
      {status === 'success' && <TransponderCreateSuccess />}
      {status === 'error' && (
        <Box minWidth="460px" width="80vw">
          <AppCrashView />
        </Box>
      )}
    </Box>
  )
}
