import { Box, capitalize } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import clsx from 'clsx'

import { Text } from 'components/ui-kit/typography/Text'

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    tag: {
      padding: spacing(0.75, 1),
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',

      '&.tag-style-grey': {
        backgroundColor: palette.fadedGrey.main,
        borderRadius: 5,

        '&.has-value-text': {
          borderRadius: '5px 0 0 5px',
        },
      },
      '&.tag-style-marine': {
        backgroundColor: palette.marine.main,
        borderRadius: 100,

        '&.has-value-text': {
          borderRadius: '100px 0 100px 0',
        },
      },
      '&.tag-style-paleBlue': {
        backgroundColor: '#CEEDFF',
        borderRadius: 100,

        '&.has-value-text': {
          borderRadius: '100px 0 100px 0',
        },
      },
      '&.tag-style-purple': {
        backgroundColor: palette.bloom.main,
        borderRadius: 100,

        '&.has-value-text': {
          borderRadius: '100px 0 100px 0',
        },
      },
    },
    tagText: {
      '&.tag-style-grey': {
        color: palette.darkChrome.main,
      },
      '&.tag-style-marine': {
        color: palette.persianBlue.main,
      },
      '&.tag-style-paleBlue': {
        color: palette.storm.main,
      },
      '&.tag-style-purple': {
        color: palette.darkChrome.main,
      },
    },
    valueText: {
      background: palette.white.main,
      border: `1px solid ${palette.fadedGrey.main}`,
      borderRadius: '0 5px 5px 0',
      padding: spacing(0, 1),
      display: 'flex',
      alignItems: 'center',
    },
  }),
)

type TagTheme = 'purple' | 'marine' | 'grey' | 'paleBlue'

type Props = {
  text?: string
  theme?: TagTheme
  valueText?: string
}

export const Tag = ({ text = '', theme = 'grey', valueText = '' }: Props) => {
  const classes = useStyles()

  const valueTextClassName = valueText && 'has-value-text'
  const styleClassName = `tag-style-${theme}`
  const textClassName = clsx(classes.tagText, styleClassName)

  return (
    <Box display="flex">
      <Box className={clsx(classes.tag, styleClassName, valueTextClassName)}>
        <Text size={11} weight={500} lineHeight="unset" cursor="auto" className={textClassName}>
          {capitalize(text)}
        </Text>
      </Box>
      {valueText && (
        <Box className={classes.valueText}>
          <Text size={11} weight={500} lineHeight="unset" cursor="auto" className={textClassName}>
            {valueText}
          </Text>
        </Box>
      )}
    </Box>
  )
}
