import { GatewayTransitionDTO } from 'pages/orchestration/workflows/edit/interfaces/ICanvasStep'
import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { TaskRemovalWarningMessageConfig } from '../interfaces'
import { ICanvasStep, ITaskActivityParams } from 'pages/orchestration/workflows/edit/interfaces'

type Args = {
  values: ITaskActivityParams
  steps: Array<ICanvasStep>
}

export const getTaskRemovalWarningMessageConfig = ({ values, steps }: Args) => {
  const taskOutcomeVariables = values?.outcomeVariables || []
  const taskDataSubjectVariables = values?.dataSubjectVariables || []

  const workflowDecisionGateways = steps.filter(s => s.activityCode === WorkflowActivityCode.DECISION_MULTI)

  /**
   * An object of key/value pairs where the key is the workflow step ID
   * and the value is an array of its transition objects.
   */
  const workflowGatewayTransitionsObject: {
    [key: string]: Array<GatewayTransitionDTO>
  } = {}

  workflowDecisionGateways?.forEach?.(dg => {
    dg?.gateway?.transitions?.forEach?.(transition => {
      if (workflowGatewayTransitionsObject[dg.ID!] === undefined) {
        workflowGatewayTransitionsObject[dg.ID!] = [transition]
      } else {
        workflowGatewayTransitionsObject[dg.ID!].push(transition)
      }
    })
  })

  const warningMessages: TaskRemovalWarningMessageConfig[] = []

  Object.entries(workflowGatewayTransitionsObject || {})?.forEach?.(([gatewayStepId, transitionsArray]) => {
    transitionsArray?.forEach?.(transition => {
      if (
        taskOutcomeVariables.find(v => v.code === transition.variable) ||
        taskDataSubjectVariables.find(v => v.code === transition.variable)
      ) {
        warningMessages.push({
          gateway: steps?.find?.(s => s.ID === gatewayStepId) || {},
          variable: [...taskOutcomeVariables, ...taskDataSubjectVariables]?.find?.(v => v.code === transition.variable),
          transition,
          taskDescription: values?.description || '',
        })
      }
    })
  })

  return warningMessages
}
