import React from 'react'
import { Chip } from 'components/ui-kit/chip/Chip'

import { Text } from 'components/ui-kit/typography/Text'
import { AutocompleteRenderOptionState } from '@mui/material'
import { AssetPurposeDTO } from '@ketch-com/figurehead'

export const getRenderTag = (
  props: React.HTMLAttributes<HTMLLIElement>,
  value: AssetPurposeDTO,
  state: AutocompleteRenderOptionState,
): React.ReactNode | null => {
  const uniqueIdentifier = value?.code || ''

  return (
    <li {...props} key={uniqueIdentifier}>
      <Chip>
        <Text size={14}>{value?.name}</Text>
      </Chip>
    </li>
  )
}
