import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetSubscriptionControlOrchestrationsResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

type Params = APIListRequestParams<{
  code?: string
}>

export const fetchSubscriptionControlOrchestrations = ({ code, includeMetadata }: Params) =>
  API.get<GetSubscriptionControlOrchestrationsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/subscriptions/controls/${code}/orchestrations`,
      params: {
        filters: {
          ...(includeMetadata && {
            includeMetadata,
          }),
        },
      },
    }),
  )
