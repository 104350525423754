import { ExperienceCanonicalRightFormTemplate } from 'interfaces/experiences/experience'

/**
 * Updates formTemplateID for a specific right template in the array.
 *
 * @param templates - The existing templates array.
 * @param templateCode - The template code to match in the array.
 * @param newTemplateId - The new templateId to be set.
 * @returns The updated templates array.
 */
export const updateTemplateId = (
  templates: ExperienceCanonicalRightFormTemplate[],
  templateCode: string | undefined,
  newTemplateId: string | undefined,
) => {
  // Clone the existing array values
  const updatedTemplates = [...templates]

  // Find the index of the template to be updated
  const indexToUpdate = updatedTemplates.findIndex(temp => temp.code === templateCode)

  // Update the formTemplateID of the found template
  if (indexToUpdate !== -1) {
    updatedTemplates[indexToUpdate] = {
      ...updatedTemplates[indexToUpdate],
      formTemplateID: newTemplateId,
    }
  }

  return updatedTemplates
}
