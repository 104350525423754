import React, { useContext } from 'react'
import { BuilderContext } from '../../../Builder'
import { Typography } from '@mui/material'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { SectionEmptyState } from '../../SectionEmptyState'

export const ModalDescriptionTitle: React.FC = () => {
  const { themeConfig, experienceConfig } = useContext(BuilderContext)

  // Theme
  const color = getIn(themeConfig, themeUpsertFormKeys.modalDescriptionTitleColor)

  // Experience
  const showTitle = getIn(experienceConfig, experienceUpsertFormKeys.modalDescriptionTitleVisible)
  const text = getIn(experienceConfig, experienceUpsertFormKeys.modalDescriptionTitle)

  return showTitle ? (
    <Typography
      variant="h4"
      color={color}
      mb={0.75}
      sx={{ wordWrap: 'break-word', maxWidth: '635px', minHeight: '20.8px' }}
    >
      {text}
    </Typography>
  ) : (
    <SectionEmptyState section={'Description Title'} mb={0.75} />
  )
}
