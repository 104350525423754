import { APIListRequestParams } from 'api/common/utils'
import { GetAuthenticatorResponseBodyDTO } from '@ketch-com/figurehead'

import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  authenticatorId?: string
}>

export const fetchAuthenticator = ({ authenticatorId }: Params) =>
  API.get<GetAuthenticatorResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/authenticators/${authenticatorId}`,
    }),
  )
