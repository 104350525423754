import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'
import { useFormikContext } from 'formik'

import { ThemePreviewViewType } from 'interfaces/themes-v2/themePreview'
import { ThemeBannerPosition } from 'interfaces/themes-v2/themeBannerPosition'
import { ThemeModalPosition } from 'interfaces/themes-v2/themeModalPosition'

import { bannerDataMock } from 'pages/consentAndRights/experiences/preview/components/bannerPreview/utils'
import { modalDataMock } from 'pages/consentAndRights/experiences/preview/components/modalPreview/utils'
import { preferenceDataMock } from 'pages/consentAndRights/experiences/preview/components/preferencePreview/utils'
import { ThemeFormValues, mapUpsertThemeValuesToPayload } from 'pages/consentAndRights/themes/upsert/utils'
import { BannerPreview } from 'pages/consentAndRights/experiences/preview/components/bannerPreview/BannerPreview'
import { ModalPreview } from 'pages/consentAndRights/experiences/preview/components/modalPreview/ModalPreview'
import { PreferencePreview } from 'pages/consentAndRights/experiences/preview/components/preferencePreview/PreferencePreview'

const useStyles = makeStyles(
  createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bannerBottomSide: {
      width: 360,
    },
    modal: {
      width: 620,
      height: 572,
    },
    modalSide: {
      width: 480,
      height: '100%',
    },
    preference: {
      width: '100%',
      height: '100%',
    },
  }),
  { name: 'ThemeUpsertPreviewContent' },
)

type Props = {
  view: ThemePreviewViewType
}

export const ThemeUpsertPreviewContent: React.FC<Props> = ({ view }) => {
  const classes = useStyles()
  const { values } = useFormikContext<ThemeFormValues>()

  const theme = mapUpsertThemeValuesToPayload({ values })

  return (
    <>
      {view === ThemePreviewViewType.BANNER && (
        <BannerPreview
          wrapperClassName={classes.wrapper}
          className={clsx({
            [classes.bannerBottomSide]:
              values.bannerPosition === ThemeBannerPosition.BOTTOM_LEFT ||
              values.bannerPosition === ThemeBannerPosition.BOTTOM_RIGHT,
          })}
          theme={theme}
          {...bannerDataMock}
        />
      )}

      {view === ThemePreviewViewType.MODAL && (
        <ModalPreview
          wrapperClassName={classes.wrapper}
          className={clsx(classes.modal, {
            [classes.modalSide]:
              values.modalPosition === ThemeModalPosition.LEFT_FULL_HEIGHT ||
              values.modalPosition === ThemeModalPosition.RIGHT_FULL_HEIGHT,
          })}
          theme={theme}
          {...modalDataMock}
        />
      )}

      {view === ThemePreviewViewType.FORM && (
        <PreferencePreview
          wrapperClassName={classes.wrapper}
          className={classes.preference}
          theme={theme}
          {...preferenceDataMock}
        />
      )}
    </>
  )
}
