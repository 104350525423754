import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { useEffect, useMemo, useState } from 'react'

type UseLanguage = {
  languages: LanguageWithStatusDTO[]
  initialLanguageCode: string
}

export type UseLanguageResult = {
  activeLanguage: LanguageWithStatusDTO | null
  languageItems: {
    active: boolean
    content: string | undefined
    onClick: () => void
  }[]
}

/**
 * React Hook to manage the selection of an active language.
 *
 * @param {UseLanguage} input - An object containing the list of languages and the initial language code.
 * @returns {Object} An object containing the currently active language object, and a list of language items with an active flag, their content, and an onClick handler.
 *
 * @hook
 */
export const useLanguage = ({ languages, initialLanguageCode }: UseLanguage) => {
  /**
   * @type {React.Dispatch<React.SetStateAction<LanguageWithStatusDTO | null>>}
   * State and setter for the active language object.
   */
  const [activeLanguage, setActiveLanguage] = useState<LanguageWithStatusDTO | null>(null)

  // Effect to update activeLanguage whenever initialLanguageCode changes.
  useEffect(() => {
    if (!activeLanguage) {
      setActiveLanguage(languages.find(language => language.language?.code === initialLanguageCode) || null)
    }
  }, [initialLanguageCode, languages, activeLanguage])

  // Memoize language items to optimize performance.
  const languageItems = useMemo(
    () =>
      languages?.map(language => ({
        active: language?.language?.code === activeLanguage?.language?.code, // Indicate if the language is active.
        content: language?.language?.englishName, // Content of the language item.
        onClick: () => {
          setActiveLanguage(language) // Handler to set the active language object.
        },
      })) || [],
    [activeLanguage, languages],
  )

  return { activeLanguage, languageItems }
}
