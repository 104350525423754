import { FormRow, InfoRow } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { useTeams } from 'api/teams/queries/useTeams'
import { TeamChip } from 'components/chip/TeamChip'
import { useMemo } from 'react'

type Props = { userId?: string; inForm?: boolean }

export const UserTeamsInfoRow: React.FC<Props> = ({ userId, inForm }) => {
  const { data, isLoading } = useTeams({ params: { memberIds: [userId || ''] } })

  const Component = useMemo(() => (inForm ? FormRow : InfoRow), [inForm])

  if (isLoading || !data?.teams?.length) return null

  return (
    <Component title="Teams">
      <Box display="flex" gap={1} flexWrap="wrap">
        {data.teams?.map(team => <TeamChip team={team} key={team.id} />)}
      </Box>
    </Component>
  )
}
