// components
import Box from '@mui/material/Box'
import { LinkChip } from 'components/ui-kit/chip/LinkChip'
import { Text } from 'components/ui-kit/typography/Text'

// types
import { PurposeDTO } from '@ketch-com/figurehead'
type Props = {
  data: PurposeDTO[]
  onClick: (code: string) => void
}

export const LinkChipsList = ({ data, onClick }: Props) =>
  data?.length ? (
    <Box mb={-1} display="flex" gap={1} flexWrap="wrap">
      {data?.map(({ name, code }) => (
        <LinkChip key={code} onClick={() => onClick(code || '')} sx={{ mb: 1, mr: 1 }} text={name || ''} />
      ))}
    </Box>
  ) : (
    <Text>None</Text>
  )
