import { CustomRightNameDTO } from '@ketch-com/figurehead'

export const getCustomRightNameFormValues = (customRightName: CustomRightNameDTO): CustomRightNameDTO => {
  const { id, jurisdictionRights, metadata, name, translations } = customRightName
  if (!customRightName) {
    return {
      id: '',
      jurisdictionRights: [],
      metadata: {},
      name: '',
      translations: [],
    }
  }

  return {
    id,
    jurisdictionRights,
    metadata,
    name,
    translations,
  }
}

/**
 * Takes in the language and returns the exacted language code
 *
 * @param {string} inputString - French (Canada) (fr-CA) or Spanish (es)
 * @returns {string} The Language Code between the parenthesis - "fr-CA" or "es"
 */
export function extractValueInParentheses(inputString: string) {
  const regex = /\(([^)]+)\)[^()]*$/ // Updated regular expression to match the last set of parentheses
  const matches = inputString.match(regex)

  if (matches && matches.length > 1) {
    return matches[1] // Extracted value inside the last parentheses
  }

  return null // Return null if no match is found
}
