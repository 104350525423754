import { APIRequestParams } from 'api/common/utils'
import { WebTagDTO, WebTagStatusFormDTO } from 'interfaces/webtags/WebTags'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  organizationCode: string
  webTagId: string
  formData: WebTagStatusFormDTO
}>

type Response = { tag: WebTagDTO }

export const updateWebTagStatus = ({ organizationCode, webTagId, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/tags/${webTagId}/enable`,
    }),
    formData,
  )
