type Result = {
  version: string
  folder: string
}

export const retrieveVersionAndFolder = (paths: string[]): Result => {
  const urlParams = new URLSearchParams(paths[0].split('?')[1])
  const version = urlParams.get('version') || ''
  const folder = urlParams.get('folder') || ''

  return { version, folder }
}
