import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { ObjectLiteral } from 'interfaces'
import { ApplicationDTO } from 'interfaces/applications/application'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans/deploymentPlan'
import { ExperienceDTO } from 'interfaces/experiences/experience'
import { APPLICATION_PLATFORM_NAME } from 'interfaces/applications/applicationPlatform'
import { RoutesManager } from 'utils/routing/routesManager'
import { useApplications } from 'api/applications/queries/useApplications'
import { useDeploymentPlans } from 'api/deploymentPlans/queries/useDeploymentPlans'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { Table } from 'components/ui-layouts/table/Table'
import { Badge } from 'components/ui-kit/badge/Badge'
import { Button } from '@ketch-com/deck'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { SectionTitle } from 'components/ui-kit/typography/compositions/SectionTitle'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'

import { ReactComponent as EmptyStateDeploymentPlansIcon } from 'assets/icons/emptyState/EmptyStateDeploymentPlans.svg'

type Props = {
  experience: ExperienceDTO
}

export const ExperienceDeployments: React.FC<Props> = ({ experience }) => {
  const navigate = useNavigate()
  const { applicationEnvironments } = experience

  const { data: applications, isLoading: isApplicationsLoading } = useApplications({
    enabled: !!applicationEnvironments?.length,
  })

  const { data: deploymentPlans, isLoading: isDeploymentPlansLoading } = useDeploymentPlans({
    enabled: !!applicationEnvironments?.length,
  })

  const applicationsMap = useMemo(
    () =>
      applications.reduce<ObjectLiteral<ApplicationDTO>>(
        (acc, application) => ({
          ...acc,
          [application.ID]: application,
        }),
        {},
      ),
    [applications],
  )

  const deploymentPlansMap = useMemo(
    () =>
      deploymentPlans.reduce<ObjectLiteral<DeploymentPlanDTO>>(
        (acc, deploymentPlan) => ({
          ...acc,
          [deploymentPlan.ID]: deploymentPlan,
        }),
        {},
      ),
    [deploymentPlans],
  )

  if (isApplicationsLoading || isDeploymentPlansLoading) {
    return <Spinner padding={50} />
  }

  return (
    <>
      <SectionTitle>Deployment Summary</SectionTitle>

      {applicationEnvironments?.length ? (
        <>
          <Table
            items={applicationEnvironments}
            cellSettings={{
              property: {
                label: 'Property',
                width: 250,
                cellRenderer: ({ applicationName, applicationID }) => (
                  <NameAndCodeCellRenderer name={applicationName} code={applicationsMap[applicationID]?.code} />
                ),
              },
              type: {
                label: 'Type',
                width: 150,
                cellRenderer: ({ applicationPlatform }) => (
                  <Badge>{APPLICATION_PLATFORM_NAME[applicationPlatform]}</Badge>
                ),
              },
              environment: {
                label: 'Environment',
                width: 200,
                cellRenderer: ({ code }) => code,
              },
              pattern: {
                label: 'Pattern',
                width: 220,
                cellRenderer: ({ pattern }) => pattern,
              },
              deploymentPlan: {
                label: 'Deployment Plan',
                cellRenderer: ({ deploymentID }) => {
                  const deploymentPlanId = deploymentID?.value

                  if (deploymentPlanId) {
                    const deploymentPlan = deploymentPlansMap[deploymentPlanId]
                    const name = deploymentPlan?.name
                    const code = deploymentPlan?.code

                    return <NameAndCodeCellRenderer name={name} code={code} />
                  }

                  return <EmptyValueRenderer />
                },
              },
              viewButton: {
                width: 90,
                cellRenderer: ({ deploymentID }) => {
                  const deploymentPlanId = deploymentID?.value
                  const deploymentPlan = deploymentPlanId ? deploymentPlansMap[deploymentPlanId] : null
                  const code = deploymentPlan?.code

                  return (
                    <Button
                      disabled={!deploymentPlan}
                      color="tertiary"
                      onClick={() => {
                        navigate(RoutesManager.deployment.deploymentPlans.view.root.getURL({ code }))
                      }}
                    >
                      View
                    </Button>
                  )
                },
              },
            }}
          />
        </>
      ) : (
        <EmptyState
          icon={<EmptyStateDeploymentPlansIcon />}
          bgVariant="grey"
          variant="page"
          title="Experience not used in any deployments"
          description="Once a relevant deployment plan uses this experience, it will be displayed here."
        />
      )}
    </>
  )
}
