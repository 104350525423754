import { Box, Typography } from '@mui/material'
import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'

import { Chip, Status, StatusState, StatusVariant } from '@ketch-com/deck'

type Props = { workflowStep: WorkflowExecutionStepDetailsDTO | null }
export const AppDetailsHeaderDetails: React.FC<Props> = ({ workflowStep }) => {
  const statusBadge =
    workflowStep?.status === WorkflowExecutionStepStatus.FAIL ||
    workflowStep?.status === WorkflowExecutionStepStatus.INVALID_CREDENTIALS ? (
      <Status label="Failed" icon="Dot" status={StatusState.error} variant={StatusVariant.outlined} />
    ) : workflowStep?.status === WorkflowExecutionStepStatus.IN_PROGRESS ||
      workflowStep?.status === WorkflowExecutionStepStatus.PENDING ? (
      <Status label="In Progress" icon="Dot" status={StatusState.inProgress} variant={StatusVariant.outlined} />
    ) : (
      <Status label="Completed" icon="Dot" status={StatusState.success} variant={StatusVariant.outlined} />
    )

  return (
    <Box display="flex" gap={1}>
      <Chip
        size="small"
        label={
          <Typography variant="smallLabel">
            Code: <Typography variant="smallBody">{workflowStep?.code || 'none'}</Typography>
          </Typography>
        }
      />
      {statusBadge}
    </Box>
  )
}
