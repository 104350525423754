import { RoutesManager } from 'utils/routing/routesManager'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useIsKetchFree } from 'utils/hooks/useIsKetchFree'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'

type UseHeaderRoutesConfigArgs = {
  isEntitledToDemo?: boolean
}

export const useHeaderRoutesConfig = ({ isEntitledToDemo = false }: UseHeaderRoutesConfigArgs) => {
  const { t } = useTranslation(['common'])

  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isTrackersVersion0 = true // TODO: enabling trackers by default cleanup cookies code when trackers is functioning E2E
  const isExpV1 = isFeatureFlagEnabled(
    FEATURE_FLAGS.EXPERIENCE_SERVER_VERSION,
    FEATURE_FLAGS_VALUES.EXPERIENCE_SERVER_VERSION_1,
  )

  // todo: DEMO CHINESE TRANSLATION CODE. REMOVE WHEN LOCALIZATION IS MORE ROBUST
  // Localized routes should route to other localized routes
  const { pathname } = useLocation()
  const isLocalizedUrl = pathname.endsWith('/zh')

  const { isKetchFree } = useIsKetchFree()

  const policyCenterSubRoutes = [
    {
      title: 'Jurisdictions',
      path: RoutesManager.policyCenter.policyScopes.root.getURL(),
      permissions: [PERMISSIONS.JURISDICTION_READ],
    },
    {
      title: 'Purposes',
      path: RoutesManager.policyCenter.purposes.root.getURL(),
      permissions: [PERMISSIONS.PURPOSE_READ],
    },
    {
      title: 'Documents',
      path: RoutesManager.policyCenter.policyDocuments.root.getURL(),
      permissions: [PERMISSIONS.POLDOC_READ],
    },
    {
      title: 'Controllers',
      path: RoutesManager.policyCenter.controllers.root.getURL(),
      permissions: [PERMISSIONS.CONTROLLER_READ],
    },
    {
      title: 'Policies',
      path: RoutesManager.policyCenter.policies.list.getURL(),
      permissions: [PERMISSIONS.ASSET_POLICY_READ],
    },
    {
      title: 'Business Functions',
      path: RoutesManager.policyCenter.businessFunctions.root.getURL(),
      permissions: [PERMISSIONS.BUSINESS_FUNCTIONS_READ],
    },
    {
      title: 'Subscriptions',
      path: RoutesManager.policyCenter.subscriptions.list.subscriptionTopics.root.getURL(),
      permissions: [PERMISSIONS.SUBSCRIPTIONS_READ],
    },
    {
      title: 'Assessments',
      path: RoutesManager.policyCenter.assessments.list.getURL(),
      permissions: [PERMISSIONS.ASSESSMENT_READ],
    },

    {
      title: 'Processing Activities',
      path: RoutesManager.policyCenter.processingActivities.root.getURL(),
      permissions: [PERMISSIONS.PROCESSING_ACTIVITY_READ],
    },
  ]

  if (isTrackersVersion0)
    policyCenterSubRoutes?.push({
      title: 'Trackers',
      path: RoutesManager.policyCenter.trackers.list.approvedTrackers.root.getURL(),
      permissions: [PERMISSIONS.COOKIE_READ],
    })
  else
    policyCenterSubRoutes?.push({
      title: 'Cookies',
      path: RoutesManager.policyCenter.cookies.root.getURL(),
      permissions: [PERMISSIONS.COOKIE_READ],
    })

  const headerRoutes = [
    ...(isKetchFree
      ? [
          {
            title: 'Home',
            path: RoutesManager.home.root.getURL(),
            permissions: [],
          },
        ]
      : []),
    {
      title: t('Insights'),
      path: RoutesManager.insights.root.getURL(),
      subRoutes: [
        {
          title: 'Activity & Access',
          path: RoutesManager.insights.activityAndAccess.root.getURL(),
          permissions: [PERMISSIONS.DEMO],
        },
        {
          title: 'Permit Statistics',
          path: isLocalizedUrl
            ? RoutesManager.insights.permits.chineseTranslation.getURL({ code: 'zh' })
            : RoutesManager.insights.permits.root.getURL(),
          permissions: [PERMISSIONS.PERMIT_INSIGHTS_READ],
        },
        {
          title: 'Right Statistics',
          path: isLocalizedUrl
            ? RoutesManager.insights.rights.chineseTranslation.getURL({ code: 'zh' })
            : RoutesManager.insights.rights.root.getURL(),
          permissions: [PERMISSIONS.RIGHT_INSIGHTS_READ],
        },
        {
          title: 'Audit Logs',
          path: RoutesManager.insights.auditLogs.root.getURL(),
          permissions: [PERMISSIONS.PERMIT_INSIGHTS_READ, PERMISSIONS.RIGHT_INSIGHTS_READ],
          requireAllPermissions: false, // "false" here allows access to the route if any one of the permissions are met.  The default, "true", requires all permissions to be met.
        },
        {
          title: 'Data Map',
          path: RoutesManager.insights.dataMap.purposes.root.getURL(),
          permissions: [PERMISSIONS.DATAMAP_READ],
        },
        {
          title: 'Data Risks',
          path: RoutesManager.insights.dataRisks.root.getURL(),
          permissions: [PERMISSIONS.DEMO], // TODO: Update accordingly on feature complete
        },
      ],
    },
    {
      title: t('Policy Center'),
      path: RoutesManager.policyCenter.root.getURL(),
      subRoutes: policyCenterSubRoutes,
    },
    {
      title: t('Experience Server'),
      path: RoutesManager.deployment.root.getURL(),
      subRoutes: [
        {
          title: 'Forms',
          path: RoutesManager.deployment.forms.root.getURL(),
          permissions: [PERMISSIONS.EXP_WRITE],
        },

        {
          title: 'Properties',
          path: RoutesManager.deployment.applications.root.getURL(),
          permissions: [PERMISSIONS.PROPERTY_READ],
        },
        {
          title: 'Experiences',
          path: isExpV1
            ? RoutesManager.deployment.experiences.root.getURL()
            : RoutesManager.deployment.experiencesV2.root.getURL(),
          permissions: [PERMISSIONS.EXP_READ],
        },
        {
          title: 'Themes',
          path: isExpV1
            ? RoutesManager.deployment.themes.root.getURL()
            : RoutesManager.deployment.themesV3.root.getURL(),
          permissions: [PERMISSIONS.THEME_READ],
        },
        {
          title: 'Deployment Plans',
          path: isExpV1
            ? RoutesManager.deployment.deploymentPlans.root.getURL()
            : RoutesManager.deployment.deploymentPlansV2.root.getURL(),
          permissions: [PERMISSIONS.DEPLOY_READ],
        },
        {
          title: 'Languages',
          path: RoutesManager.deployment.languages.root.getURL(),
          permissions: [PERMISSIONS.TRANSLATIONS_READ],
        },
        {
          title: 'Purpose Stacks',
          path: RoutesManager.deployment.purposeStacks.root.getURL(),
          permissions: [PERMISSIONS.EXP_READ],
        },
      ],
    },
    {
      title: t('Orchestration'),
      path: RoutesManager.orchestration.root.getURL(),
      subRoutes: [
        {
          title: 'Events',
          path: RoutesManager.orchestration.events.root.getURL(),
          permissions: [PERMISSIONS.WORKFLOW_READ],
        },
        {
          title: 'Workflows',
          path: RoutesManager.orchestration.workflows.root.getURL(),
          permissions: [PERMISSIONS.WORKFLOW_READ],
        },
        {
          title: 'Rights Queue',
          path: RoutesManager.orchestration.rightsQueue.root.getURL(),
          permissions: [PERMISSIONS.RTINVQ_READ],
        },
        {
          title: 'Consent',
          path: RoutesManager.orchestration.consent.systems.root.getURL(),
          permissions: [PERMISSIONS.PERMIT_ORCH_READ],
        },
        {
          title: 'Tags',
          path: RoutesManager.orchestration.webTags.root.getURL(),
          permissions: [PERMISSIONS.WEB_TAGS_READ],
        },
      ],
    },

    {
      title: t('Data Map'),
      path: RoutesManager.assetManager.assets.root.getURL(),
      subRoutes: [
        {
          title: t('Systems'),
          path: RoutesManager.systems.root.getURL(),
          permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
        },

        {
          title: 'Assets',
          path: RoutesManager.assetManager.assets.root.getURL(),
          permissions: [PERMISSIONS.ASSET_READ],
        },
        {
          title: 'Relationships',
          path: RoutesManager.assetManager.relationshipMapping.list.getURL(),
          permissions: [PERMISSIONS.ASSET_RELATIONSHIP_READ],
        },
        {
          title: 'Labels',
          path: RoutesManager.systems.labels.classifications.getURL(),
          permissions: [PERMISSIONS.LABEL_READ],
        },
      ],
      permissions: [
        PERMISSIONS.ASSET_READ,
        PERMISSIONS.ASSET_RELATIONSHIP_READ,
        PERMISSIONS.LABEL_READ,
        PERMISSIONS.DATA_SYSTEMS_READ,
      ],
      requireAllPermissions: false,
    },
  ]

  return headerRoutes
}
