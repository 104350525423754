import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  relationshipId: string
  isV2FeatureFlagEnabled?: boolean
}

export const deleteRelationshipV2 = ({ relationshipId, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/relationships/${relationshipId}`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/relationships/${relationshipId}`

  return API.delete<Response>(
    formatRequestString({
      entityUrl,
    }),
  )
}
