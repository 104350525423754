import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { LoadingOptionsSpinner } from 'pages/policyCenter/policies/upsert/CreatePolicyForm/components'
import { useHookSearchOptions } from './utils'
import { ASSET_TYPE_ENUM, EditDatabaseFormValues } from 'interfaces/assets'
import { AssetSummaryDTO, DmlHookDTO } from '@ketch-com/figurehead'
import { HookOptionChip } from './components'
import { KETCH_DML } from 'pages/developers/hooks/constants'
import { FormikErrors } from 'formik'
import { MaybeNull } from 'interfaces'

type Props = {
  values: EditDatabaseFormValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<EditDatabaseFormValues>>
  assetSummary?: MaybeNull<AssetSummaryDTO>
}

export const HooksSelect: React.FC<Props> = ({ values, setFieldValue, assetSummary }) => {
  const { hookOptions, isLoadingHookOptions, setSearchValue } = useHookSearchOptions({
    assetSummary,
  })

  const autocompleteId = `hookSelectAutoComplete`

  const handleAddHookToHooksList = (hookToAdd: DmlHookDTO) => {
    const hookToAddType = hookToAdd.type
    const currentHooksCopy = [...values.hooks]
    const preHookPayload = [...currentHooksCopy.filter(hook => hook.type === 1)]
    const postHookPayload = [...currentHooksCopy.filter(hook => hook.type === 2)]
    const payload = [
      ...preHookPayload,
      ...(hookToAddType === 1 ? [hookToAdd] : []),
      {
        assignmentCount: 9999,
        dmlCode: 'dummy',
        id: 'dummy',
        name: KETCH_DML,
        operation: 99999,
        technology: 'dummy',
        type: 99,
      },
      ...postHookPayload,
      ...(hookToAddType === 2 ? [hookToAdd] : []),
    ]
    setFieldValue('hooks', [...payload])
  }

  return (
    <Autocomplete
      options={
        hookOptions.filter(h => {
          const shouldAllow = !(values?.hooks || [])?.some?.(hook => hook?.id === h?.id)
          return shouldAllow
        }) || []
      }
      id={autocompleteId}
      fullWidth
      size="medium"
      openOnFocus
      clearOnBlur
      popupIcon={null}
      autoComplete={false}
      multiple={false}
      value={null} // Hard coding 'null' here ensures the input will remain clear after selecting an option
      loading={isLoadingHookOptions}
      loadingText={<LoadingOptionsSpinner />}
      onChange={(_, v) => {
        if (v) {
          handleAddHookToHooksList(v as DmlHookDTO)
          const activeDomElement = document.activeElement as HTMLElement
          activeDomElement.blur()
        }
      }}
      getOptionLabel={(option: DmlHookDTO) => option?.name as string}
      renderOption={(props, option) => {
        return (
          <li {...{ ...props, key: option.id }}>
            <HookOptionChip hook={option} />
          </li>
        )
      }}
      isOptionEqualToValue={(option, value) => option?.name === value?.name}
      onInputChange={(_, newInputValue) =>
        setSearchValue(newInputValue, ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATASET)
      }
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          placeholder="Hook name"
          helperText="some helper text"
          inputProps={{
            ...params.inputProps,
            form: {
              autoComplete: 'off',
            },
          }}
          InputProps={{
            ...params.InputProps,
            sx: {
              maxWidth: 300,
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          FormHelperTextProps={{
            sx: {
              display: 'none',
            },
          }}
        />
      )}
    />
  )
}
