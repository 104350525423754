import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchAppInstanceUsages } from 'api/apps/fetchers/fetchAppInstanceUsages'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.appsInstanceUsages,
  queryFn: fetchAppInstanceUsages,
  select: res => ({
    workflows: res?.data?.workflows || [],
  }),
})

type Config = CustomQueryConfig<typeof useCustomQuery, 'organizationCode'>

export const useAppInstanceUsages = (config: Config) => {
  const { userData } = useAuth()

  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
      organizationCode: userData.organizationCode!,
    },
  })
}
