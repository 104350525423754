import { fetchFileInfo } from 'api/files/fetchers/fetchFileInfo'
import { excludeFalsy } from 'utils/helpers/excludeFalsy'

type Params = {
  infoUrls: string[]
}

export const fetchFilesInfo = async ({ infoUrls }: Params) => {
  const filesInfo = await Promise.all(infoUrls.map(infoUrl => fetchFileInfo({ infoUrl }).catch(() => null)))

  return filesInfo.filter(excludeFalsy)
}
