import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import { Button, Status, StatusState, StatusVariant, Tab, TabGroup } from '@ketch-com/deck'
import { AppHeaderNotificationsList } from '../notifications/AppHeaderNotificationsList'
import { TasksList } from '../tasks/TasksList'

import { RoutesManager } from 'utils/routing/routesManager'
import { useUnreadNotificationsCount } from 'api/notifications/queries/useUnreadNotificationsCount'
import Typography from '@mui/material/Typography'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks'

type Props = {}

export const TabsListContainer: React.FC<Props> = () => {
  const navigate = useNavigate()

  const [tabStatus, setTabsStatus] = useState<'tasks' | 'notifications'>('tasks')
  const { isPermitted } = useIsPermitted()
  const canViewNotificationsListView = isPermitted(PERMISSIONS.RTINVQ_READ)

  const { data: unreadNotificationsCount } = useUnreadNotificationsCount({
    params: {
      hasNoTaskId: true,
    },
  })

  const { data: unreadTaskNotificationsCount } = useUnreadNotificationsCount({
    params: {
      hasTaskId: true,
    },
  })

  const [currentTab, setCurrentTab] = useState(0)

  useEffect(() => {
    if (currentTab === 0) {
      setTabsStatus('tasks')
    } else {
      setTabsStatus('notifications')
    }
  }, [currentTab])
  const tabs = useMemo(
    () => [
      {
        id: 'tasks',
        label: (
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="label">Tasks</Typography>
            {unreadTaskNotificationsCount ? (
              <Status
                variant={StatusVariant.filled}
                status={StatusState.new}
                label={`${String(unreadTaskNotificationsCount)}`}
              />
            ) : (
              <></>
            )}
          </Box>
        ),
        newCount: unreadTaskNotificationsCount,
      },
      {
        id: 'notifications',
        label: (
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="label">Notifications</Typography>
            {unreadNotificationsCount ? (
              <Status
                variant={StatusVariant.filled}
                status={StatusState.new}
                label={`${String(unreadNotificationsCount)}`}
              />
            ) : (
              <></>
            )}
          </Box>
        ),

        newCount: unreadNotificationsCount,
      },
    ],
    [unreadNotificationsCount, unreadTaskNotificationsCount],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      minHeight={200}
      sx={{
        px: 0.5,
        pt: 0.5,
      }}
    >
      <Box
        sx={{
          top: 0,
          backgroundColor: ({ palette }) => palette.white.main,
          zIndex: 100,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3">Your Updates</Typography>

          {canViewNotificationsListView && (
            <Button
              color="tertiary"
              onClick={() =>
                navigate(
                  tabStatus === 'tasks'
                    ? RoutesManager.updates.tasks.root.getURL()
                    : RoutesManager.updates.notifications.root.getURL(),
                )
              }
            >
              View More
            </Button>
          )}
        </Box>

        <Box mt={1}>
          <TabGroup
            value={currentTab}
            onChange={(event: React.SyntheticEvent, value: any) => {
              setCurrentTab(value)
            }}
          >
            {tabs.map((tabItem, index) => (
              <Tab {...tabItem} key={index} />
            ))}
          </TabGroup>
        </Box>
      </Box>

      <Box sx={{ pt: 0.5, maxHeight: '500px', overflow: 'scroll' }}>
        {tabStatus === 'tasks' ? <TasksList /> : <AppHeaderNotificationsList />}
      </Box>
    </Box>
  )
}
