function translation(this: { root: string }, languageCode: string) {
  return `translations.${languageCode}.${this.root}`
}

export const fieldNames = {
  consent: {
    banner: {
      title: {
        root: 'banner.title',
        translation,
      },
      footerDescription: {
        root: 'banner.footerDescription',
        translation,
      },
      secondaryButtonText: {
        root: 'banner.secondaryButtonText',
        translation,
      },
      secondaryButtonDestination: {
        root: 'banner.secondaryButtonDestination',
      },
      primaryButtonAction: {
        root: 'banner.primaryButtonAction',
      },
      buttonText: {
        root: 'banner.buttonText',
        translation,
      },
    },

    modal: {
      title: {
        root: 'modal.title',
        translation,
      },
      bodyTitle: {
        root: 'modal.bodyTitle',
        translation,
      },
      bodyDescription: {
        root: 'modal.bodyDescription',
        translation,
      },
      buttonText: {
        root: 'modal.buttonText',
        translation,
      },
      consentTitle: {
        root: 'modal.consentTitle',
        translation,
      },
      consentSwitchText: {
        root: 'modal.consentSwitchText',
        translation,
      },
      switchOnText: {
        root: 'modal.switchOnText',
        translation,
      },
      switchOffText: {
        root: 'modal.switchOffText',
        translation,
      },
    },

    jit: {
      title: {
        root: 'jit.title',
        translation,
      },
      bodyDescription: {
        root: 'jit.bodyDescription',
        translation,
      },
      moreInfoText: {
        root: 'jit.moreInfoText',
        translation,
      },
      moreInfoDestination: {
        root: 'jit.moreInfoDestination',
      },
      declineButtonText: {
        root: 'jit.declineButtonText',
        translation,
      },
      acceptButtonText: {
        root: 'jit.acceptButtonText',
        translation,
      },
    },
  },

  preference: {
    title: {
      root: 'title',
      translation,
    },
    overview: {
      tabName: {
        root: 'overview.tabName',
        translation,
      },
      bodyTitle: {
        root: 'overview.bodyTitle',
        translation,
      },
      bodyDescription: {
        root: 'overview.bodyDescription',
        translation,
      },
    },
    rights: {
      tabName: {
        root: 'rights.tabName',
        translation,
      },
      bodyTitle: {
        root: 'rights.bodyTitle',
        translation,
      },
      bodyDescription: {
        root: 'rights.bodyDescription',
        translation,
      },
      buttonText: {
        root: 'rights.buttonText',
        translation,
      },
      recaptchaEnabled: {
        root: 'rights.recaptchaEnabled',
        translation,
      },
      showRequestDetailsTitle: {
        root: 'rights.showRequestDetailsTitle',
        translation,
      },
      requestTitleType: {
        root: 'rights.requestTitleType',
        translation,
      },
      requestDetailsTitle: {
        root: 'rights.requestDetailsTitle',
        translation,
      },
      showRequestDetailsField: {
        root: 'rights.showRequestDetailsField',
        translation,
      },
      requestDetailsRequired: {
        root: 'rights.requestDetailsRequired',
        translation,
      },

      showPersonalDetailsTitle: {
        root: 'rights.showPersonalDetailsTitle',
        translation,
      },
      personalDetailsTitleType: {
        root: 'rights.personalDetailsTitleType',
        translation,
      },
      personalDetailsTitle: {
        root: 'rights.personalDetailsTitle',
        translation,
      },
      formFields: {
        root: 'rights.formFields',
        translation,
      },
      canonicalRightFormTemplates: {
        root: 'rights.canonicalRightFormTemplates',
        translation,
      },
      customRightFormTemplates: {
        root: 'rights.customRightFormTemplates',
        translation,
      },
      hasSingleFormTemplate: {
        root: 'rights.hasSingleFormTemplate',
        translation,
      },
      singleFormTemplateID: {
        root: 'rights.singleFormTemplateID',
        translation,
      },
    },
    consents: {
      tabName: {
        root: 'consents.tabName',
        translation,
      },
      bodyTitle: {
        root: 'consents.bodyTitle',
        translation,
      },
      bodyDescription: {
        root: 'consents.bodyDescription',
        translation,
      },
      buttonText: {
        root: 'consents.buttonText',
        translation,
      },
      consentTitle: {
        root: 'consents.consentTitle',
        translation,
      },
      consentSwitchText: {
        root: 'consents.consentSwitchText',
        translation,
      },
      switchOnText: {
        root: 'consents.switchOnText',
        translation,
      },
      switchOffText: {
        root: 'consents.switchOffText',
        translation,
      },
    },
    subscriptions: {
      tabName: {
        root: 'subscriptions.tabName',
        translation,
      },
      bodyTitle: {
        root: 'subscriptions.bodyTitle',
        translation,
      },
      bodyDescription: {
        root: 'subscriptions.bodyDescription',
        translation,
      },
      primaryButtonText: {
        root: 'subscriptions.primaryButtonText',
        translation,
      },
      secondaryButtonText: {
        root: 'subscriptions.secondaryButtonText',
        translation,
      },
      title: {
        root: 'subscriptions.title',
        translation,
      },
      subscriptionsSwitchText: {
        root: 'subscriptions.subscriptionsSwitchText',
        translation,
      },
      switchOnText: {
        root: 'subscriptions.switchOnText',
        translation,
      },
      switchOffText: {
        root: 'subscriptions.switchOffText',
        translation,
      },
    },
  },
} as const
