import { Box, Typography } from '@mui/material'
import { Checkbox, DataGrid, FormRow, Spinner } from '@ketch-com/deck'
import { useFormikContext } from 'formik'
import { BusinessFunctionFormValues } from '../../utils/formHelpers'
import { businessFunctionsUpsertColumns } from './businessFunctionUpsertDataGridUtils'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { PurposeDTO } from 'interfaces/purposes/purpose'

export const BusinessFunctionUpsertPurposes = () => {
  const { data: purposesList, isLoading } = usePurposes({})
  const { setFieldValue, values } = useFormikContext<BusinessFunctionFormValues>()

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '48px 0',
        }}
      >
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, purpose: PurposeDTO) => {
    // @ts-ignore TODO change ID field in type to id
    const valuesWithoutCurrentPurpose = (values?.purposes || []).filter((p: PurposeDTO) => p.id !== purpose.ID)
    // @ts-ignore TODO change ID field in type to id
    const newEntry = { id: purpose.ID, code: purpose.code }

    if (e.target.checked) {
      setFieldValue('purposes', [...valuesWithoutCurrentPurpose, newEntry])
    } else {
      setFieldValue('purposes', valuesWithoutCurrentPurpose)
    }
  }

  const onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setFieldValue(
        'purposes',
        purposesList.map(p => ({ id: p.ID, code: p.code })),
      )
    } else {
      setFieldValue('purposes', [])
    }
  }

  const checkIsChecked = (purpose: PurposeDTO) => {
    return Boolean(values.purposes?.find(p => p.id === purpose.ID))
  }

  return (
    <FormRow title="Purposes" subTitle="Select purposes under which this business function operates." hasTopBorder>
      <Box mb={3} ml={2} display="flex" alignItems="center">
        <Checkbox
          value={(values.purposes || []).length === purposesList.length}
          onChange={onCheckAll}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Typography sx={{ ml: 1 }}>
          {(values.purposes || []).length}/{purposesList.length} Selected
        </Typography>
      </Box>
      <DataGrid
        sx={{
          height: 'auto',
          paddingLeft: 'unset',
          paddingRight: 'unset',
          paddingTop: 'unset',
        }}
        autosizeOnMount
        columns={businessFunctionsUpsertColumns(onCheckboxChange, checkIsChecked)}
        disableChildrenSorting
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        getRowHeight={() => 'auto'}
        getRowId={row => row.ID}
        hideFooter
        loading={isLoading}
        rows={purposesList}
      />
    </FormRow>
  )
}
