import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { GatewayTransitionDTO } from 'pages/orchestration/workflows/edit/interfaces/ICanvasStep'
import { getStepsToStartCount } from 'pages/orchestration/workflows/edit/utils/steps'
import pluralize from 'pluralize'

type Args = {
  step: ICanvasStep
  steps: Array<ICanvasStep>
}

export const getIsTaskReferencedInDownstreamDecisionGateway = ({ step, steps }: Args) => {
  const stepIndex = steps.findIndex(s => s.ID === step.ID)

  const sortedSteps = [...steps].sort((a, b) => {
    const aStepCountFromStepToStart = getStepsToStartCount({ step: a, steps })
    const bStepCountFromStepToStart = getStepsToStartCount({ step: b, steps })

    return aStepCountFromStepToStart - bStepCountFromStepToStart
  })

  const downstreamDecisionGatewaysTransitions = sortedSteps
    .filter((s, i) => i > stepIndex && s.activityCode === WorkflowActivityCode.DECISION_MULTI)
    .map(gw => gw?.gateway?.transitions)
    .flat() as GatewayTransitionDTO[]

  const downstreamDecisionGateways = sortedSteps.filter(
    (s, i) => i > stepIndex && s.activityCode === WorkflowActivityCode.DECISION_MULTI,
  )

  const downstreamDecisionGatewaysReferencingStep = downstreamDecisionGateways.filter(
    gatewayStep => gatewayStep?.gateway?.transitions?.find(transition => transition.outcomeVariableStepID === step.ID),
  )

  const isReferenced = !!downstreamDecisionGatewaysTransitions.find(
    transition => step.ID === transition.outcomeVariableStepID,
  )

  const gatewayPluralized = pluralize('gateway', downstreamDecisionGatewaysReferencingStep.length || 0)

  const warningMessage = isReferenced
    ? `This task is referenced in ${gatewayPluralized} ${downstreamDecisionGatewaysReferencingStep
        .map(gw => `"${gw.description}"`)
        .join(
          ', ',
        )}. You must first remove the reference from ${gatewayPluralized} before deselecting Outcome Variables.`
    : ''

  const payload = { isReferenced, warningMessage }

  return payload
}
