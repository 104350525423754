import React from 'react'
import { Formik } from 'formik'
import { compact } from 'lodash'

// components
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'
import Box from '@mui/material/Box'
import { VerificationOption } from 'pages/consentAndRights/experiences/upsert/components/UpsertConsent/components/VerificationOption'

// utils
import { useUsers } from 'api/users/queries/useUsers'
import { formatCodeFromName } from 'utils/formatters'
import { getDataReviewInitialValues, getDataReviewValidationSchema } from './utils'

// types
import { IDataReviewActivityParams, ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { AssigneeSelector } from '../components/AssigneeSelector'
import { FormInput } from 'components/form/FormInput'
import { Typography } from '@mui/material'
import { FormAssigneeOrTeamSelector } from '../../../../../../../../../components/form/FormAssigneeOrTeamSelector'
interface IDataReviewFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const DataReviewForm: React.FC<IDataReviewFormProps> = ({
  step,
  steps,
  handleClose,
  handleRemove,
  handleSave,
}) => {
  const { isLoading } = useUsers({ params: { active: true } })
  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))

  const onSubmit = (values: IDataReviewActivityParams) => {
    handleSave({
      step: {
        ...step,
        valid: true,
        code: values.code,
        description: values.description,
        workflow: {
          ...step.workflow,
          params: {
            assignee: values.assignee,
            approver: values.approver,
            needsApproval: values.needsApproval,
            timeToCompleteDays: values.timeToCompleteDays,
          },
        } as ICanvasStep['workflow'],
      },
    })
  }

  return (
    <SidebarConfigsWrapper isLoading={isLoading} handleClose={handleClose}>
      <Formik
        initialValues={getDataReviewInitialValues({ step })}
        validationSchema={getDataReviewValidationSchema({ usedCodes })}
        initialErrors={{ error: 'error' }}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {form => {
          const { isValid, submitForm, setFieldValue, values } = form

          return (
            <>
              <SidebarConfigsHeader
                isValid={isValid}
                step={step}
                steps={steps}
                handleSave={() => submitForm()}
                handleRemove={() => handleRemove({ step })}
                handleClose={handleClose}
                copyable
              />

              <SidebarConfigsBody sx={{ px: 3 }}>
                <Box mb={3}>
                  <FormInput
                    required
                    formPropertyName="description"
                    fullWidth
                    label="Description Label"
                    placeholder="Enter description"
                    onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
                    shouldUpdateDebounced
                  />
                </Box>

                <Box mb={5}>
                  <FormInput
                    formPropertyName="code"
                    fullWidth
                    label="Code"
                    placeholder="Enter code"
                    required
                    shouldUpdateDebounced
                  />
                </Box>

                <Typography variant="h3" sx={{ mb: 3 }}>
                  Approval Details
                </Typography>

                <FormAssigneeOrTeamSelector label="Assignee" name="assignee" />

                <VerificationOption
                  title="Approval"
                  value={values?.needsApproval || false}
                  onChange={e => {
                    const needsApproval = !!e.target.checked
                    setFieldValue('needsApproval', needsApproval)
                    if (!needsApproval) {
                      setFieldValue('approver', '')
                    }
                  }}
                  requisiteForm={
                    <Box display="flex" flexDirection="column" pt={1.5}>
                      <AssigneeSelector inFormRow={false} label="Approver" name="approver" />
                    </Box>
                  }
                />

                <Box mt={3}>
                  <FormInput
                    type="number"
                    formPropertyName="timeToCompleteDays"
                    label="Time To Complete (Days)"
                    placeholder="0"
                    inputWidth="80px"
                    alwaysShowError
                  />
                </Box>
              </SidebarConfigsBody>
            </>
          )
        }}
      </Formik>
    </SidebarConfigsWrapper>
  )
}
