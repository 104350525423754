import React from 'react'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { Badge } from 'components/ui-kit/badge/Badge'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateCommon.svg'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { ClassificationActions } from './ClassificationActions'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { INSUFFICIENT_PERMISSIONS_TOOLTIP } from 'utils/constants/INSUFFICIENT_PERMISSIONS_TOOLTIP'
import { Box } from '@mui/material'
import { Classification, SetClassificationModal } from 'pages/assetManager/classifications/types'
import { LabelDefinitionDTO as LabelDTO } from '@ketch-com/figurehead'
import { Table } from 'components/ui-layouts/table/Table'
import { Waypoint } from 'react-waypoint'
import { InfiniteScrollLoadingIndicator } from 'components/ui-layouts/table/components/infiniteScrollLoadingIndicator'

type Props = {
  isLoading: boolean
  isFetchingNextPage?: boolean
  hasNextPage?: boolean
  fetchNextPage: () => {}
  labels: LabelDTO[]
  setClassificationModal: SetClassificationModal
}

export const ClassificationsManual: React.FC<Props> = ({
  isLoading,
  isFetchingNextPage,
  labels,
  hasNextPage,
  fetchNextPage,
  setClassificationModal,
}) => {
  const { isPermitted } = useIsPermitted()
  const isPermittedToCreateLabel = isPermitted([PERMISSIONS.LABEL_WRITE])

  if (!isLoading && !labels?.length)
    return (
      <EmptyState
        icon={<EmptyStateIcon />}
        title="There are no classifications for this organization"
        description="Create your first one to populate the table"
        actions={[
          {
            title: 'Create Classification',
            size: 'big',
            onClick: () =>
              setClassificationModal(prevValues => ({
                ...prevValues,
                display: true,
              })),
            disabled: !isPermittedToCreateLabel,
            tooltip: !isPermittedToCreateLabel ? INSUFFICIENT_PERMISSIONS_TOOLTIP : undefined,
          },
        ]}
      />
    )
  return (
    <Box>
      <Table
        pending={isLoading}
        items={labels}
        rowIdKey={labrl => labrl?.code || ''}
        cellSettings={{
          classification: {
            label: 'Classification',
            width: 600,
            cellRenderer: ({ name }) => <Badge>{name}</Badge>,
          },
          description: {
            label: 'Description',
            cellRenderer: ({ description }, index) => (
              <>
                <TextCellRenderer value={description} />
                {(labels?.length || 0) - 5 === index ? (
                  <Waypoint
                    onEnter={() => {
                      if (!isFetchingNextPage && hasNextPage) fetchNextPage()
                    }}
                  />
                ) : null}
              </>
            ),
          },
          dropDown: {
            width: 60,
            cellRenderer: label => {
              const labelData: Classification = {
                name: String(label.name),
                code: label.code,
                description: label.description,
              }
              return <ClassificationActions label={labelData} setClassificationModal={setClassificationModal} />
            },
          },
        }}
      />
      <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
    </Box>
  )
}
