import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Verifiers } from './Verifiers'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useVerifiers } from 'api/verifiers/queries/useVerifiers'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'

export const VerifiersContainer: React.FC = () => {
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()
  const isEntitledToVerifiedIdentities = isEntitled(ENTITLEMENTS.VERIFIED_IDENTITIES)
  const [searchQueryValue, setSearchQueryValue] = useState('')
  const {
    data: verifiersData,
    isLoading: isLoadingVerifiers,
    pagination,
  } = useVerifiers({
    params: {
      query: searchQueryValue,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch verifiers', type: 'error' })
    },
    enabled: isEntitledToVerifiedIdentities,
  })

  useEffect(() => {
    if (!isEntitledToVerifiedIdentities) navigate('/')
  }, [isEntitledToVerifiedIdentities, navigate])

  return (
    <Verifiers
      verifiersData={verifiersData}
      isLoadingVerifiers={isLoadingVerifiers}
      pagination={pagination}
      setSearchQueryValue={setSearchQueryValue}
      searchQueryValue={searchQueryValue}
    />
  )
}
