import { Pagination } from 'api/common/paginatedQuery'
import { useSyncWebTags } from 'api/webtags/mutations/useSyncWebTags'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { WebTagDTO, WebTagIssue } from 'interfaces/webtags/WebTags'
import React, { useCallback, useMemo, useState } from 'react'
import { useAppSelector } from 'store/hooks'
import { getWebTagsListFilterState } from 'store/webTagsListFilterSlice/selectors'
import { createCustomContext } from 'utils/hooks/createCustomContext'
import { TagListState } from '../utils/enums'
import { useWebTagsPaginatedPreservePaginationV2 } from 'api/webtags/queries/useWebTagsPaginatedPreservePaginationV2'
import { TagsSummaryData } from '../utils/types'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useWebTagIssues } from 'api/webtags/queries/useWebTagIssues'
import { isUndefined } from 'lodash'

export interface TagsListContextProps {
  tagListState: TagListState
  setTagListState: React.Dispatch<React.SetStateAction<TagListState>>
  selectedTagIds: string[]
  setSelectedTagIds: React.Dispatch<React.SetStateAction<string[]>>
  bulkUpdateTagIds: string[]
  setBulkUpdateTagIds: React.Dispatch<React.SetStateAction<string[]>>
  purposes: PurposeDTO[]
  webTagIssues: WebTagIssue[]
  isPurposesLoading: boolean
  isLoading: boolean
  isFetching: boolean
  isTagsWriter: boolean
  webTags: WebTagDTO[]
  newTags: WebTagDTO[]
  logoUrls: { [appCode: string]: string }
  newTagsLogoUrls: { [appCode: string]: string }
  tagsSummaryData: TagsSummaryData
  pagination: Pagination
  handleSyncWebTags: () => Promise<void>
  emptyStateMessage: string
  isNewWebTagsListLoading: boolean
  isNewWebTagsListFetching: boolean
  newWebTagsPagination: Pagination
}

const { Provider, useCustomContext: useTagsList } = createCustomContext<TagsListContextProps>({
  displayName: 'TagsListContext',
})

const tagsListStateToPausedFormValue: { [listState: string]: boolean | undefined } = {
  [TagListState.All]: undefined,
  [TagListState.Active]: false,
  [TagListState.Paused]: true,
}

export const TagsListProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    appCode,
    appInstanceId,
    property: propertyID,
    enabled,
    purposeId,
    q: reduxSearchQuery,
  } = useAppSelector(getWebTagsListFilterState)
  const { isPermitted } = useIsPermitted()

  // UI States
  const [tagListState, setTagListState] = useState<TagListState>(TagListState.All)
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([])
  const [bulkUpdateTagIds, setBulkUpdateTagIds] = useState<string[]>([])

  const isTagsWriter = isPermitted(PERMISSIONS.WEB_TAGS_WRITE)

  const {
    data: tagsListResponse,
    isLoading: isWebTagsListLoading,
    isFetching: isWebTagsListFetching,
    pagination,
  } = useWebTagsPaginatedPreservePaginationV2({
    params: {
      includeIssues: true,
      includeMetadata: true,
      q: reduxSearchQuery,
      appCode,
      appInstanceId,
      propertyID,
      purposeId,
      enabled,
      approved: true,
      // If segment button = All, don't include the enabled or paused filters
      paused: tagsListStateToPausedFormValue[tagListState],
    },
  })

  const {
    data: newTagsListResponse,
    isLoading: isNewWebTagsListLoading,
    isFetching: isNewWebTagsListFetching,
    pagination: newWebTagsPagination,
  } = useWebTagsPaginatedPreservePaginationV2({
    params: {
      includeIssues: true,
      includeMetadata: true,
      // When first imported from tag manager, tag.approved is false
      approved: false,
    },
  })

  const newTags = useMemo(() => newTagsListResponse?.tags?.filter(tag => !tag.approved) || [], [newTagsListResponse])
  const newTagsLogoUrls = useMemo(() => newTagsListResponse?.logoURLs || {}, [newTagsListResponse])

  const webTags = useMemo(() => tagsListResponse?.tags || [], [tagsListResponse])
  const logoUrls = useMemo(() => tagsListResponse?.logoURLs || {}, [tagsListResponse])

  const tagsSummaryData: TagsSummaryData = useMemo(
    () => ({
      totalTags: tagsListResponse?.totalResults || 0,
      enabledTags: tagsListResponse?.totalEnabled || 0,
      newTags: newTagsListResponse?.totalResults || 0,
    }),
    [tagsListResponse, newTagsListResponse],
  )

  const hasFilters = appCode || appInstanceId || enabled || purposeId || reduxSearchQuery
  const emptyStateMessage = hasFilters
    ? 'There are no tags for entered search criteria'
    : 'There are no tags for this organization'

  const { data: purposesResponse, isLoading: isPurposesLoading } = usePurposes({
    onError: () => {
      showToast({ content: 'Failed to fetch purposes', type: 'error' })
    },
  })
  const purposes = useMemo(() => purposesResponse.filter(purpose => !isUndefined(purpose.enabled)), [purposesResponse])

  const { data: webTagIssues } = useWebTagIssues({
    onError: () => {
      showToast({ content: 'Failed to fetch web tag issues', type: 'error' })
    },
    params: {},
  })

  const { mutateAsync: syncWebTags } = useSyncWebTags({
    onSuccess: () => {
      showToast({ content: 'Tag sync initiated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to initiate tag sync', type: 'error' })
    },
  })

  const handleSyncWebTags = useCallback(async () => {
    await syncWebTags({
      params: {
        appCode: appCode,
        appInstanceID: appInstanceId,
      },
    })
  }, [appCode, appInstanceId, syncWebTags])

  const isLoading = isWebTagsListLoading

  const isFetching = isWebTagsListFetching

  const value = useMemo(
    () => ({
      tagListState,
      setTagListState,
      selectedTagIds,
      setSelectedTagIds,
      bulkUpdateTagIds,
      setBulkUpdateTagIds,
      purposes,
      webTagIssues,
      isPurposesLoading,
      isLoading,
      isFetching,
      isTagsWriter,
      webTags,
      newTags,
      logoUrls,
      newTagsLogoUrls,
      tagsSummaryData,
      pagination,
      handleSyncWebTags,
      emptyStateMessage,
      isNewWebTagsListLoading,
      isNewWebTagsListFetching,
      newWebTagsPagination,
    }),
    [
      tagListState,
      setTagListState,
      selectedTagIds,
      setSelectedTagIds,
      bulkUpdateTagIds,
      setBulkUpdateTagIds,
      purposes,
      webTagIssues,
      isPurposesLoading,
      isLoading,
      isFetching,
      isTagsWriter,
      webTags,
      newTags,
      logoUrls,
      newTagsLogoUrls,
      tagsSummaryData,
      pagination,
      handleSyncWebTags,
      emptyStateMessage,
      isNewWebTagsListLoading,
      isNewWebTagsListFetching,
      newWebTagsPagination,
    ],
  )

  return <Provider value={value}>{children}</Provider>
}

export { useTagsList }
