import React, { Fragment } from 'react'
import Box from '@mui/material/Box'
import { Formik, useFormikContext } from 'formik'
import { compact } from 'lodash'

import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'

import { getEndInitialValues } from 'pages/orchestration/workflows/edit/utils/forms/activities/end/endInitialValues'
import { getEndValidationSchema } from 'pages/orchestration/workflows/edit/utils/forms/activities/end/endValidation'
import { ICanvasStep, IEndFlowActivityParams } from 'pages/orchestration/workflows/edit/interfaces'
import { ConfigSelectorOptions, configOptions, convertEnumToBool } from './utils'
import { useAppealWorkflows } from './hooks/useAppealWorkflows'
import { AutomatedForm, DescriptionAndCode, ManualForm } from './components'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'
import { WorkflowDTO } from 'interfaces/workflows'
import { ContentGroup } from '@ketch-com/deck'
import { FormRadioGroup } from 'components/form/FormRadioGroup'

interface IEndFlowFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const EndFlowFormV3: React.FC<IEndFlowFormProps> = props => {
  const { step, steps, handleClose, handleRemove, handleSave } = props
  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))

  const { values } = useFormikContext<WorkflowDTO>()

  const { data: appealWorkflows, isLoading: isWorkflowsListLoading } = useAppealWorkflows({
    canonicalRightCode: values?.canonicalRightCode || '',
  })

  /**
   * Handles form submission.
   * Extracts the values, performs some transformations, and then calls handleSave function
   *
   * @param {IEndFlowActivityParams} values - The form values
   */
  const onSubmit = (values: IEndFlowActivityParams) => {
    // Destructure the form values
    const {
      code,
      description,
      assignee,
      configSelector,
      finalizeRequestAs,
      allowAppeal,
      appealWorkflowDefinitionCode,
      timeToCompleteDays,
    } = values

    // Determine form type
    const isAutomated = configSelector === ConfigSelectorOptions.Automated
    const isManual = configSelector === ConfigSelectorOptions.Manual

    // Define the conditional form values
    const coverLetterFile = isAutomated ? values.coverLetterFile : []
    const coverLetter = isAutomated && values.isCoverLetterRequired ? values.coverLetterFile?.[0]?.info_url : ''
    const isCoverLetterRequired = isManual
      ? convertEnumToBool(values?.manualCoverLetter || '')
      : values?.isCoverLetterRequired
    const isMessageRequired = isManual ? convertEnumToBool(values?.manualMessage || '') : values?.isMessageRequired
    const subjectCommunicationMessage =
      isAutomated && values.isMessageRequired ? values.subjectCommunicationMessage : ''

    // Prepare the updatedStep object
    const updatedStep: ICanvasStep = {
      ...step, // spread the existing step properties
      valid: true, // set valid to true
      code, // code from form values
      description, // description from form values
      workflow: {
        ...step.workflow, // spread the existing workflow properties
        params: {
          // Update the params with the form values
          assignee,
          configSelector,
          coverLetterFile,
          coverLetter,
          isCoverLetterRequired,
          isMessageRequired,
          subjectCommunicationMessage,
          finalizeRequestAs,
          allowAppeal,
          appealWorkflowDefinitionCode,
          timeToCompleteDays,
        },
      } as ICanvasStep['workflow'],
      // An empty 'finish' object is required for bosun logic
      finish: {},
    }

    // Call handleSave function with the updatedStep object
    handleSave({ step: updatedStep })
  }

  return (
    <SidebarConfigsWrapper handleClose={handleClose} isLoading={isWorkflowsListLoading}>
      <Formik
        initialValues={getEndInitialValues({ step })}
        validationSchema={getEndValidationSchema({ usedCodes })}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ isValid, submitForm, values, setFieldValue, errors, touched }) => {
          logObjectDetails(
            {
              values,
              errors,
              touched,
            },
            'EndFlowFormV3.tsx',
            'white',
            false, // false ensures will log only in debug mode (i.e. ?debug=log)
          )
          return (
            <Fragment>
              <SidebarConfigsHeader
                isValid={isValid}
                step={step}
                steps={steps}
                handleSave={() => submitForm()}
                handleRemove={() => handleRemove({ step })}
                handleClose={handleClose}
              />

              <SidebarConfigsBody
                sx={{
                  px: 3,
                }}
              >
                <Box>
                  <DescriptionAndCode />

                  <Box mb={3}>
                    <ContentGroup
                      title="Configuration"
                      subTitle="Select how the end of the request should be configured."
                      titleVariant="h3"
                    />
                  </Box>
                  {/* Automated or Manual radio select  */}

                  <Box display="flex" alignItems="center" mb={3}>
                    <FormRadioGroup
                      valueKey="id"
                      hideOptionalLabel
                      row
                      formPropertyName="configSelector"
                      renderLabel={({ name }) => name}
                      options={configOptions} // i.e. automated or manual
                      onChange={e => {
                        const newValue = e.target.value
                        if (newValue === ConfigSelectorOptions.Manual) {
                          setFieldValue('finalizeRequestAs', undefined)
                          setFieldValue('manualMessage', 'OPTIONAL')
                          setFieldValue('manualCoverLetter', 'OPTIONAL')
                          setFieldValue('isCoverLetterRequired', false)
                          setFieldValue('isMessageRequired', false)
                        }

                        if (newValue === ConfigSelectorOptions.Automated) {
                          setFieldValue('finalizeRequestAs', 'completed')
                          setFieldValue('manualMessage', undefined)
                          setFieldValue('manualCoverLetter', undefined)
                        }
                      }}
                    />
                  </Box>
                  {values.configSelector === ConfigSelectorOptions.Automated && (
                    <AutomatedForm appealWorkflows={appealWorkflows} />
                  )}

                  {values.configSelector === ConfigSelectorOptions.Manual && (
                    <ManualForm appealWorkflows={appealWorkflows} />
                  )}
                </Box>
              </SidebarConfigsBody>
            </Fragment>
          )
        }}
      </Formik>
    </SidebarConfigsWrapper>
  )
}
