import React, { createContext, FC } from 'react'
import { DropdownOptionDTO } from '../interfaces'

export const FormFieldContext = createContext(
  {} as {
    getDummyDropdownItem: () => DropdownOptionDTO
  },
)

type Props = {
  children: React.ReactNode
  getDummyDropdownItem: () => DropdownOptionDTO
}
export const FormFieldUpsertContextProvider: FC<Props> = ({ children, getDummyDropdownItem }) => {
  return (
    <FormFieldContext.Provider
      value={{
        getDummyDropdownItem,
      }}
    >
      {children}
    </FormFieldContext.Provider>
  )
}
