import React from 'react'
import { ListItemText, ActionSheetItem as DeckMenuItem } from '@ketch-com/deck'
import { DataRoleType } from '../../../../../interfaces/events'

type TProps = {
  selected: boolean
  option: { value: string | DataRoleType; label: string | DataRoleType }
}
export const MenuItem = ({ selected, option, ...props }: TProps): React.ReactElement => {
  return (
    <DeckMenuItem selected={selected} {...props}>
      <ListItemText selected={selected}>{option?.label}</ListItemText>
    </DeckMenuItem>
  )
}
