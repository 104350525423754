import React from 'react'
import { styled } from '@mui/material/styles'
import { DefaultTFuncReturn } from 'i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export const LegendBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: 22,
  marginTop: 32,
}))

export const LegendItemBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: 32,
}))

export const ChartTooltipContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.darkDusk.main,
  borderRadius: 5,
  color: theme.palette.white.main,
  padding: '8px 10px',
  minWidth: 175,
}))

interface LegendIconBoxProps {
  variant?: 'fulfilled' | 'invoked'
}

export const LegendIconBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'variant',
  name: 'LegendIconBox',
  slot: 'Root',
})<LegendIconBoxProps>(({ theme, variant }) => ({
  backgroundColor: variant === 'fulfilled' ? '#28988B' : variant === 'invoked' ? '#E27965' : 'transparent',
  borderRadius: 2,
  width: 16,
  height: 16,
  marginRight: 10,
}))

interface ChartDescriptionSectionProps {
  mainText?: string
  subText?: DefaultTFuncReturn | string
}

export const ChartDescriptionSection: React.FC<ChartDescriptionSectionProps> = ({ mainText, subText }) => (
  <Box mr={5}>
    <Typography variant="h3" lineHeight={0.7}>
      {mainText || 0}
    </Typography>

    <Typography variant="smallLabel">{subText}</Typography>
  </Box>
)

interface LegendItemProps {
  variant: 'fulfilled' | 'invoked'
  label: string
}

export const LegendItem: React.FC<LegendItemProps> = ({ variant, label }) => (
  <LegendItemBox>
    <LegendIconBox variant={variant} />
    <Typography variant="smallLabel">{label}</Typography>
  </LegendItemBox>
)

export { RightsTrendQueueFlowChartTooltip } from './RightsTrendQueueFlowChartTooltip'
