import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchTeams } from '../fetchers/fetchTeams'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.teams,
  queryFn: fetchTeams,
  select: res => res?.data,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useTeams = (config?: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      start: 0,
      limit: 1000,
      ...config?.params,
    },
  })
}
