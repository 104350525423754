import React, { useMemo } from 'react'
import { MaybeNull } from 'interfaces'
import { IdentitySpaceDTO, IdentitySpacesCatalogDTO } from 'interfaces/identitySpaces/identitySpace'
import { getInitialValues, IdentitySpaceFormValues } from './utils/formValues'
import { useValidationSchema } from './utils/formValidation'
import { Formik } from 'formik'
import { IdentitySpaceBasicDetails } from './components/IdentitySpaceBasicDetails'
import { IdentitySpaceMappings } from './components/IdentitySpaceMappings'
import { AuthenticatedIdentity } from './components/AuthenticatedIdentity'
import { MarketingPreferenceIdentity } from 'pages/settings/identitySpaces/upsert/components/MarketingPreferenceIdentity'
import { FindVerifiersResponseBodyDTO } from '@ketch-com/figurehead'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { Button, PopUp, Spinner } from '@ketch-com/deck'
import { Box } from '@mui/material'

type Props = {
  isReady: boolean
  isEditMode: boolean
  identitySpace: MaybeNull<IdentitySpaceDTO>
  identitySpaces: IdentitySpaceDTO[]
  identitySpacesCatalog: MaybeNull<IdentitySpacesCatalogDTO>
  verifiersData: MaybeNull<FindVerifiersResponseBodyDTO>
  onSubmit: (values: IdentitySpaceFormValues) => Promise<void>
  onClose: () => void
}

export const IdentitySpaceUpsertModal: React.FC<Props> = ({
  isReady,
  isEditMode,
  onSubmit,
  identitySpace,
  identitySpaces = [],
  identitySpacesCatalog,
  verifiersData = {},
  onClose,
}) => {
  const { isEntitled } = useIsEntitled()
  const isEntitledToVerifiedIdentities = isEntitled(ENTITLEMENTS.VERIFIED_IDENTITIES)
  const initialValues = useMemo(
    () => getInitialValues({ identitySpace, identitySpaces }),
    [identitySpace, identitySpaces],
  )

  const validationSchema = useValidationSchema({ isEditMode })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {({ isSubmitting, submitForm }) => (
        <PopUp
          title="Create Identifier"
          variant="modal"
          popUpWidth="800px"
          onClose={onClose}
          popUpActionChildren={
            <>
              <Button color="secondary" size="large" pending={isSubmitting} onClick={onClose}>
                Close
              </Button>
              <Button color="primary" size="large" pending={isSubmitting} onClick={submitForm}>
                Save
              </Button>
            </>
          }
        >
          {!isReady ? (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
              <Spinner size="32px" thickness={2.5} />
            </Box>
          ) : (
            <Box>
              <IdentitySpaceBasicDetails
                isEditMode={isEditMode}
                identitySpacesCatalog={identitySpacesCatalog!}
                isModalForm={true}
              />

              <IdentitySpaceMappings isEditMode={isEditMode} />

              <MarketingPreferenceIdentity />

              {isEntitledToVerifiedIdentities && verifiersData?.verifiers?.length ? (
                <AuthenticatedIdentity isEditMode={isEditMode} verifiersData={verifiersData} />
              ) : null}
            </Box>
          )}
        </PopUp>
      )}
    </Formik>
  )
}
