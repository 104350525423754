import React from 'react'
import { Box } from '@mui/material'
import { AssetSummaryDTO, ConnectionDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'
import { AssetLocationConfig } from 'pages/assetManager/interfaces'

type Props = {
  assetSummary: AssetSummaryDTO
  connections: ConnectionDTO[]
}

export const TooltipContent: React.FC<Props> = ({ assetSummary, connections }) => {
  const assetLocationConfig: AssetLocationConfig = Object.entries(assetSummary?.asset?.resource?.hierarchies || {})
    .map(([, value]) => {
      const humanReadableKey =
        connections?.[0]?.resourceTypes?.find(rt => rt.code === value?.resourceType?.code)?.name ||
        value?.resourceType?.code
      return { tileTitle: humanReadableKey as string, tileValue: value?.name as string }
    })
    .filter((value, index, self) => index === self.findIndex(t => t.tileTitle === value.tileTitle))

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        background: 'black',
        padding: 1,
      }}
    >
      {/* Title */}

      <Text
        variant="subtitle2"
        component="span"
        fontWeight={700}
        sx={{
          fontSize: '10px',
          color: 'rgba(255, 255, 255, 0.6)',
          mb: 1.5,
          textTransform: 'uppercase',
          letterSpacing: '0.13em',
        }}
      >
        Asset Location
      </Text>

      <Text
        variant="subtitle2"
        component="span"
        fontWeight={700}
        sx={{
          fontSize: '10px',
          color: 'rgba(255, 255, 255, 0.6)',
        }}
      >
        Connection
      </Text>

      <Text
        variant="subtitle2"
        component="span"
        fontWeight={700}
        sx={{
          fontSize: '10px',
          color: 'white',
          mb: 1.5,
        }}
      >
        {assetSummary?.asset?.connection?.name || ''}
      </Text>

      {assetLocationConfig.map((config, index) => {
        return (
          <React.Fragment key={config.tileTitle}>
            <Text
              variant="subtitle2"
              component="span"
              fontWeight={700}
              sx={{
                fontSize: '10px',
                color: 'rgba(255, 255, 255, 0.6)',
              }}
            >
              {config.tileTitle}
            </Text>

            <Text
              variant="subtitle2"
              component="span"
              fontWeight={700}
              sx={{
                fontSize: '10px',
                color: 'white',
                mb: 1.5,
              }}
            >
              {config.tileValue}
            </Text>
          </React.Fragment>
        )
      })}
    </Box>
  )
}
