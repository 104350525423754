import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchDataPreview } from '../fetchers/fetchDataPreview'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.dataPreview,
  queryFn: fetchDataPreview,
  select: res => res?.data,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useDataPreview = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
