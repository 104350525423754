import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { API } from 'utils/api/api'
import { AppDTO, AppInstanceDTO } from 'interfaces/apps'

type Params = APIListRequestParams<{
  appCode?: AppDTO['ID']
  organizationCode: string
}>

type Response = APIListResponse<{ instances: AppInstanceDTO[] }>

export const fetchAppInstances = ({ appCode, organizationCode }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/app-instances`,
      params: {
        filters: {
          appCode,
        },
      },
    }),
  )
