import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetDSRConfigsResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

type Params = APIListRequestParams<{
  assetId: string
  scenarioId: string
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchAssetsDsrConfigDynamic = ({ assetId, scenarioId, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/dsr-configs`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/dsr-configs`

  return API.get<GetDSRConfigsResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          assetId,
          scenarioId,
        },
      },
    }),
  )
}
