import React from 'react'

import { Classifications } from 'pages/assetManager/classifications/Classifications'
import { useClassificationsV2OrV3BasedOnFeatureFlag } from 'api/labels/queries/useClassificationsV2OrV3BasedOnFeatureFlag'

export const ClassificationsContainer: React.FC = () => {
  const {
    data: labels,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useClassificationsV2OrV3BasedOnFeatureFlag({
    params: {
      includeSystemLabels: false,
      limit: 10,
    },
  })

  return (
    <Classifications
      isLoading={isLoading}
      labels={labels}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
    />
  )
}
