import { APIListRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetRelationships } from 'pages/assetManager/interfaces'

type params = APIListRequestParams<{
  nextToken?: string
  assetType?: string
  query?: string
  discovered?: boolean
}>

export const fetchRelationshipMappingPaginated = ({ nextToken, limit = 20, discovered, query, assetType }: params) => {
  return API.get<GetRelationships>(
    formatRequestString({
      entityUrl: `/api/asset-manager/relationships`,
      params: {
        filters: {
          discovered,
          ...(limit && {
            limit,
          }),
          ...(query && {
            query,
          }),
          ...(assetType && {
            assetType,
          }),
          ...((nextToken || {}) && {
            nextToken,
          }),
        },
      },
    }),
  )
}
