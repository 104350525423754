import React, { useContext } from 'react'

import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { UpsertInlineLayout } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayout'
import { UpsertInlineLayoutToolbar } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutToolbar'
import { UpsertPreference } from 'pages/consentAndRights/experiences/upsert/components/UpsertPreference'
import { ExperienceUpsertContext, withExperienceUpsertContext } from './context/ExperienceUpsertContext'
import { PersistFormValuesToReduxStore } from './components/PersistFormValuesToReduxStore'
import { ExperienceUpsertHeader } from './components/ExperienceUpsertHeader'
import { UpsertConsent } from './components/UpsertConsent/UpsertConsent'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'
import { PreferenceExperienceFormValues } from './interfaces/PreferenceExperienceFormValues'

type Props = {}

export const ExperienceUpsertWithoutContext: React.FC<Props> = () => {
  const {
    activeTab,
    breadcrumbs,
    initialValues,
    isConsentExperience,
    isReady,
    onSubmit,
    setActiveTab,
    tabs,
    validationSchema,
  } = useContext(ExperienceUpsertContext)

  return (
    <UpsertInlineLayout
      isReady={isReady}
      breadcrumbs={breadcrumbs}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ errors, values, touched }) => {
        logObjectDetails(
          {
            errors,
            rightsValues: (values as PreferenceExperienceFormValues)?.rights,
            touched,
            values,
          },
          'ExperienceUpsert.tsx',
          'yellow',
          false, // false ensures will log only in debug mode (i.e. ?debug=log)
        )
        return (
          <>
            <PersistFormValuesToReduxStore />

            <ExperienceUpsertHeader />

            <UpsertInlineLayoutToolbar>
              <SegmentTabs value={activeTab} onChange={tab => setActiveTab(tab)} tabs={tabs} />
            </UpsertInlineLayoutToolbar>

            {isConsentExperience ? <UpsertConsent /> : <UpsertPreference />}
          </>
        )
      }}
    </UpsertInlineLayout>
  )
}

export const ExperienceUpsert = withExperienceUpsertContext(ExperienceUpsertWithoutContext)
