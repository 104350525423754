export enum ApplicationIdentitySpaceFormat {
  STRING = 1,
  JSON = 2,
  JWT = 3,
  QUERY_STRING = 4,
  SEMICOLON = 5,
}

export const APPLICATION_IDENTITY_SPACE_FORMAT_NAME = {
  [ApplicationIdentitySpaceFormat.STRING]: 'String',
  [ApplicationIdentitySpaceFormat.JSON]: 'JSON',
  [ApplicationIdentitySpaceFormat.JWT]: 'JWT',
  [ApplicationIdentitySpaceFormat.QUERY_STRING]: 'Query String',
  [ApplicationIdentitySpaceFormat.SEMICOLON]: 'Semicolon',
}

export const APPLICATION_IDENTITY_SPACE_FORMAT_OPTIONS = [
  {
    id: ApplicationIdentitySpaceFormat.STRING,
    name: APPLICATION_IDENTITY_SPACE_FORMAT_NAME[ApplicationIdentitySpaceFormat.STRING],
  },
  {
    id: ApplicationIdentitySpaceFormat.JSON,
    name: APPLICATION_IDENTITY_SPACE_FORMAT_NAME[ApplicationIdentitySpaceFormat.JSON],
  },
  {
    id: ApplicationIdentitySpaceFormat.JWT,
    name: APPLICATION_IDENTITY_SPACE_FORMAT_NAME[ApplicationIdentitySpaceFormat.JWT],
  },
  {
    id: ApplicationIdentitySpaceFormat.QUERY_STRING,
    name: APPLICATION_IDENTITY_SPACE_FORMAT_NAME[ApplicationIdentitySpaceFormat.QUERY_STRING],
  },
  {
    id: ApplicationIdentitySpaceFormat.SEMICOLON,
    name: APPLICATION_IDENTITY_SPACE_FORMAT_NAME[ApplicationIdentitySpaceFormat.SEMICOLON],
  },
]
