import React from 'react'
import { Box } from '@mui/material'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { AssetsTabMegaFilter, AssetsTabMegaFilterProps } from './AssetsTabMegaFilter'

export interface AssetsTabFiltersProps {
  searchQuery: string
  handleSearchInputChange: (nextValue: string) => void
  megaFilterProps: AssetsTabMegaFilterProps
}

export const AssetsTabFilters: React.FC<AssetsTabFiltersProps> = ({
  searchQuery,
  handleSearchInputChange,
  megaFilterProps,
}) => {
  return (
    <Box display="flex" gap={2}>
      <SearchTextInput onChange={nextValue => handleSearchInputChange(nextValue)} value={searchQuery} showClearButton />
      <AssetsTabMegaFilter {...megaFilterProps} />
    </Box>
  )
}
