import React from 'react'
import { Box, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { styled } from '@mui/system'
import { ExperienceBaseDTO } from 'interfaces/experiences/experience'

export const StyledUl = styled('ul')(({ theme }: { theme?: Theme }) => ({
  paddingLeft: 20,
  paddingRight: 10,
  marginTop: 10,
  marginBottom: 6,
}))

type Props = {
  experience?: ExperienceBaseDTO
}

export const AssociatedFormTemplatesCountTooltipTitle: React.FC<Props> = ({ experience }) => {
  const timesUsed = experience?.formTemplates?.length || 0
  if (timesUsed === 0) return null

  const forms = experience?.formTemplates || []

  return (
    <StyledUl>
      {forms?.map((form, index) => {
        return (
          <li
            key={form?.code}
            style={{
              marginBottom: index === forms?.length - 1 ? 0 : 8,
            }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="smallLabel" color="white.main">
                {form?.name}
              </Typography>
            </Box>
          </li>
        )
      })}
    </StyledUl>
  )
}
