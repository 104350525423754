export enum PolicyStatusEnum {
  AWAITING_APPROVAL = 0,
  PENDING = 1,
  ENABLED = 2,
  DISABLED = 3,
}

export const POLICY_STATUS_ENUM = {
  AWAITING_APPROVAL: 0,
  PENDING: 1,
  ENABLED: 2,
  DISABLED: 3,
}

export const POLICY_STATUS_VARIANT_MAP: {
  [key: number]: string
} = {
  [PolicyStatusEnum.AWAITING_APPROVAL]: 'in-progress',
  [PolicyStatusEnum.PENDING]: 'new',
  [PolicyStatusEnum.ENABLED]: 'active',
  [PolicyStatusEnum.DISABLED]: 'inactive',
}

export const POLICY_STATUS_TITLE_MAP: {
  [key: number]: string
} = {
  [PolicyStatusEnum.AWAITING_APPROVAL]: 'Awaiting Approval',
  [PolicyStatusEnum.PENDING]: 'Pending',
  [PolicyStatusEnum.ENABLED]: 'Enabled',
  [PolicyStatusEnum.DISABLED]: 'Disabled',
}
