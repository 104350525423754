import { FormTemplateSectionDTO } from '@ketch-com/figurehead'

/**
 *
 * @param section - FormTemplateSectionDTO
 * @description Checks if a form template section is empty
 * @returns {boolean}
 * @example
 * formTemplateSectionIsEmpty({ formFields: [], title: '', description: '' }) // true
 */
export const formTemplateSectionIsEmpty = (section?: FormTemplateSectionDTO) => {
  return section?.formFields?.length === 0 && section?.title === '' && section?.description === ''
}
