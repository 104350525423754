import * as Yup from 'yup'

import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { WorkflowExecutionStepCompleteDTO } from 'interfaces/workflowExecutions/workflowExecution'

export type WorkflowExecutionAutomatedStepCompleteFormValues = {
  resolutionDetails: string
  resolutionAttachments: FileInfoDTO[]
}

export const getValidationSchema = ({
  hasResolutionNotes,
  hasResolutionAttachments,
}: {
  hasResolutionNotes: boolean
  hasResolutionAttachments: boolean
}) =>
  Yup.object().shape({
    ...(hasResolutionAttachments && {
      resolutionAttachments: Yup.array().min(1, 'Required'),
    }),
    ...(hasResolutionNotes && {
      resolutionDetails: Yup.string().required('Required'),
    }),
  })

export const mapValuesToPayload = (
  values: WorkflowExecutionAutomatedStepCompleteFormValues,
): WorkflowExecutionStepCompleteDTO => ({
  results: {
    resolutionDetails: values.resolutionDetails,
    resolutionAttachments: values.resolutionAttachments.map(file => file.info_url),
  },
})
