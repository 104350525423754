import React from 'react'
import { useQueryClient } from 'react-query'
import { Box } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { ProcessingActivityDTO } from '@ketch-com/figurehead'
import { BasicDetails, ProcessingDetails, DataSystems } from 'pages/policyCenter/processingActivities/upsert/components'
import { useEditProcessingActivity } from 'api/processingActivities/mutations/useEditProcessingActivity'
import { showToast } from 'components/modals/AlertComponent'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { UrlParams } from 'pages/assetManager/dsrConfig/interfaces'
import { getProcessingActivitiesFormValidation } from 'pages/policyCenter/processingActivities/upsert/utils'
import { FormikProvider, useFormik } from 'formik'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isReady?: boolean
  processingActivity?: ProcessingActivityDTO
}
export const EditProcessingActivities: React.FC<Props> = ({ isReady, processingActivity }) => {
  const { id = '' } = useParams<UrlParams>()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Processing Activities', link: RoutesManager.policyCenter.processingActivities.root.getURL() },
    { title: 'Edit Processing Activity' },
  ]

  const { mutate: handleEditProcessingActivity } = useEditProcessingActivity({
    onSuccess: async ({ data: ProcessingActivityDTO }) => {
      await queryClient.invalidateQueries(ApiQueryKeys.processingActivity)
      showToast({ content: ' Updated processing activity', type: 'success' })
      navigate(
        RoutesManager.policyCenter.processingActivities.view.overview.root.getURL({
          id: ProcessingActivityDTO?.processingActivity?.id || '',
        }),
      )
    },
    onError: () => {
      showToast({ content: 'Unable to update processing activity', type: 'error' })
    },
  })

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: { ...processingActivity },
    validationSchema: getProcessingActivitiesFormValidation(),
    onSubmit: (processingActivityFormData: ProcessingActivityDTO) => {
      handleEditProcessingActivity({
        params: {
          id,
          formData: { processingActivity: processingActivityFormData },
        },
      })
    },
  })

  const { isSubmitting, submitForm } = formikProps

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={UpsertLayoutMode.edit}
          showStepper
          renderFormTitle={() => <ContentGroup title="Edit Processing Activity" titleVariant="h2" isDivider />}
          onAccept={submitForm}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          acceptActionButtonText="Save"
          onCancel={() => {
            navigate(
              RoutesManager.policyCenter.processingActivities.view.overview.root.getURL({
                id: processingActivity?.id,
              }),
            )
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          {isReady ? (
            <>
              <BasicDetails />
              <ProcessingDetails />
              <DataSystems />
            </>
          ) : (
            <Box
              sx={{
                margin: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner size="32px" thickness={2.5} />
            </Box>
          )}
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
