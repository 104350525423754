import React from 'react'
import { Route } from 'react-router-dom'
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query'

import { AssetSummaryDTO, GetAssetHierarchiesResponseBodyDTO } from '@ketch-com/figurehead'
import { useDeDuplicatedConnections } from 'pages/assetManager/hooks/useDeDuplicatedConnections'
import { SnowflakeAttributeTable, SnowflakeDatasetTable } from './components'
import { ASSET_TYPE_ENUM } from 'interfaces/assets'
import { RoutesManager } from 'utils/routing/routesManager'
import { Spinner } from 'components/ui-kit/spinner/Spinner'

type Props = {
  assets: AssetSummaryDTO[]
  isLoading: boolean
  isFetchingNextPage?: boolean
  resourceTypeCode?: string
  hasNextPage?: boolean
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<GetAssetHierarchiesResponseBodyDTO, unknown>>
}

export const AssetDetailViewAssetListTable: React.FC<Props> = ({
  assets,
  isLoading,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  resourceTypeCode,
}) => {
  const connections = useDeDuplicatedConnections()
  const connectionType = connections.find(connection => connection.code === resourceTypeCode)
  const canonicalResourceType = connectionType?.canonicalResourceType

  if (isLoading) return <Spinner />

  if (
    canonicalResourceType === ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATASET ||
    resourceTypeCode?.includes('.schema')
  ) {
    return (
      <SnowflakeDatasetTable
        {...{ assets, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, resourceTypeCode }}
      />
    )
  }

  if (canonicalResourceType === ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_ATTRIBUTE) {
    return (
      <SnowflakeAttributeTable
        {...{ assets, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, resourceTypeCode }}
      />
    )
  }

  return <Route path={RoutesManager.assetManager.assets.list.getURL()} />
}
