import Box from '@mui/material/Box'

import { Badge } from 'components/ui-kit/badge/Badge'
import { BadgeKeyValue } from 'components/ui-kit/badge/BadgeKeyValue'

import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'

export const getRenderTags = ({
  value,
  handleRemoveTag,
  labels,
}: {
  value: ManageLabelModalLabel[]
  handleRemoveTag?: (arg: string) => void
  labels: ManageLabelModalLabel[]
}) =>
  value.map((option: ManageLabelModalLabel, index: number) => {
    if (option.value)
      return (
        <Box
          key={`${option.code}_${option.value}`}
          display="flex"
          flexDirection="row"
          alignItems="center"
          mr={index !== labels.length ? 1.25 : 0}
          py={1}
        >
          <BadgeKeyValue
            {...(!!handleRemoveTag && {
              onRemoveBadge: () => {
                const uniqueIdentifier = `${option?.code || ''}${option?.value || ''}${option?.name || ''}`
                handleRemoveTag(uniqueIdentifier)
              },
            })}
            badgeKey={option.code}
            badgeValue={option.value}
            variant="neutral"
          />
        </Box>
      )
    if (!option.value)
      return (
        <Box
          key={`${option.code}_${option.value}`}
          display="flex"
          flexDirection="row"
          alignItems="center"
          mr={index !== labels.length ? 2 : 0}
          py={1}
        >
          <Badge
            {...(!!handleRemoveTag && {
              onRemoveBadge: () => {
                const uniqueIdentifier = `${option?.code || ''}${option?.value || ''}${option?.name || ''}`
                handleRemoveTag(uniqueIdentifier)
              },
            })}
            variant="neutral"
          >
            {option.code}
          </Badge>
        </Box>
      )
    return null
  })
