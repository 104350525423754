import { Box, Typography, styled } from '@mui/material'
import { getIn } from 'formik'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { useContext } from 'react'
import { BuilderSwitch, SwitchVariant } from '../../../../../BuilderSwitch'
import { SelectableSection } from '../../../../../SelectableSection'
import {
  BUILDER_CONSTANTS,
  PreferenceExperienceBuilderSection,
  PreferenceThemeBuilderSection,
} from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'
import { SectionEmptyState } from '../../../../../SectionEmptyState'
import { clamp } from 'lodash'

const UnsubscribeFromAllBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 16px 12px 16px',
  margin: '32px 0px 0px 0px',
})

const TextContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '375px',
})

export const SubscriptionsUnsubscribeAll: React.FC = () => {
  const { themeConfig, experienceConfig, isTheme } = useContext(BuilderContext)

  // Theme config
  const backgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllBackgroundColor,
  )
  const textColor = getIn(themeConfig, themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllTextColor)
  const cornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'
  const switchButtonOnBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchButtonOnBackgroundColor,
  )
  const switchButtonOnTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchButtonOnTextColor,
  )
  const switchButtonOffBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchButtonOffBackgroundColor,
  )
  const switchButtonOffTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchButtonOffTextColor,
  )

  // Experience config
  const visible = getIn(experienceConfig, experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllVisible)
  const title = getIn(experienceConfig, experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllTitle)
  const descriptionVisible = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllDescriptionVisible,
  )
  const description = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllDescription,
  )
  const switchButtonLabelVisible = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchButtonLabelsVisible,
  )
  const switchButtonOnLabel = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchLabelsOnText,
  )
  const switchButtonOffLabel = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchLabelsOffText,
  )

  return (
    <SelectableSection
      section={
        isTheme
          ? PreferenceThemeBuilderSection.SubscriptionsTabUnsubscribeAll
          : PreferenceExperienceBuilderSection.SubscriptionsTabUnsubscribeAll
      }
      overlayProps={{
        margin: '2px',
      }}
      hidden={isTheme && !visible}
    >
      {visible ? (
        <UnsubscribeFromAllBox sx={{ background: backgroundColor, borderRadius: cornerRadius }}>
          <TextContentBox maxWidth={375}>
            <Typography fontSize={16} fontWeight={700} color={textColor} sx={{ wordWrap: 'break-word' }}>
              {title}
            </Typography>
            {descriptionVisible && (
              <Typography fontSize={12} fontWeight={400} color={textColor} sx={{ wordWrap: 'break-word' }}>
                {description}
              </Typography>
            )}
          </TextContentBox>
          <BuilderSwitch
            variant={SwitchVariant.off}
            onBackgroundColor={switchButtonOnBackgroundColor}
            onTextColor={switchButtonOnTextColor}
            offBackgroundColor={switchButtonOffBackgroundColor}
            offTextColor={switchButtonOffTextColor}
            onText={switchButtonOnLabel}
            offText={switchButtonOffLabel}
            showText={switchButtonLabelVisible}
          />
        </UnsubscribeFromAllBox>
      ) : (
        <SectionEmptyState section={'Unsubscribe From All'} hidden={isTheme} sx={{ mt: '32px' }} />
      )}
    </SelectableSection>
  )
}
