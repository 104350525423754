export enum PreviewFrom {
  Editing = 'editing',
  Detail = 'detail',
  New = 'new',
}

type PreviewMode = 'desktop' | 'mobile'

export interface FormTemplatePreviewQueryParams {
  /** JSON stringified FormTemplateDTO. Leveraged in preserving the form state when switching between preview and edit mode. */
  formDTO?: string
  /** Toggle desktop vs. mobile preview mode */
  previewMode?: PreviewMode
  /** Encodes where the user arrived at the preview from. Used in determining the "Exit Preview" button behavior. */
  from?: PreviewFrom
}
