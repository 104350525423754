import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchConsent } from '../fetchers/fetchConsent'

const useCustomPaginatedQuery = createUseQuery({
  queryKey: ApiQueryKeys.auditLogsConsent,
  queryFn: fetchConsent,
  select: res => res?.data?.purposes || [],
})

type QueryConfig = CustomQueryConfig<typeof useCustomPaginatedQuery>

export const useConsent = (config?: QueryConfig) =>
  useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
