import { RightInvocationDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  rightInvocationId: string
  workflowCode: string
}

type Response = { rightInvocation: RightInvocationDTO }

export const assignWorkflow = ({ organizationCode, rightInvocationId, workflowCode }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/right-invocations/${rightInvocationId}/workflow/${workflowCode}/start`,
    }),
  )
