import { makeStyles, createStyles } from '@mui/styles'

export const useAssetsListViewStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: spacing(2),
        paddingBottom: spacing(3),
        borderBottom: `1px solid ${palette.iron.main}`,
      },
      filters: {
        width: 250,
        minHeight: 363,
        maxHeight: '100%',
        background: palette.white.main,
        borderRadius: 11,
        overflow: 'hidden',
      },
      segmentTabs: {
        marginRight: spacing(3),
      },
    }),
  { name: 'AssetsListView' },
)
