import { UpdateWebhooksContextRequestBodyDTO, GetWebhooksContextResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  workflowExecutionId?: string | null | undefined
  stepId?: string | null | undefined
  formData: UpdateWebhooksContextRequestBodyDTO
}

export const updateWebhookContext = ({ workflowExecutionId, stepId, formData }: Params) =>
  API.put<GetWebhooksContextResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/executions/${workflowExecutionId}/steps/${stepId}/webhooks-context`,
    }),
    formData,
  )
