import React from 'react'
import { Box, Grid } from '@mui/material'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'

import { AssetSummaryDTO, DmlHookDTO } from '@ketch-com/figurehead'
import { DraggableHookItem, HooksSelect } from '../components'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { KETCH_DML } from 'pages/developers/hooks/constants'
import { MaybeNull } from 'interfaces'

type Props = {
  onDragEnd: (result: DropResult) => void
  hooks: DmlHookDTO[]
  assetSummary?: MaybeNull<AssetSummaryDTO>
}

export const HooksFormSection: React.FC<Props> = ({ onDragEnd, hooks, assetSummary }) => {
  return (
    <FormSection title="Hooks" subTitle="Search and assign hooks. Drag chips to rearrange hooks ordering.">
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <HooksSelect assetSummary={assetSummary} />
        </Grid>

        <Grid item xs={6} />

        <Grid item xs={12}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => {
                const { innerRef, droppableProps } = provided
                return (
                  <Box {...droppableProps} ref={innerRef} p={1}>
                    {(hooks || []).map((hook: DmlHookDTO, index: number) => {
                      const dmlIndex = hooks.findIndex((item: any) => item.name === KETCH_DML)
                      const shouldHaveBorderBottom =
                        hooks.length - 1 !== index && index !== dmlIndex - 1 && hook?.name !== KETCH_DML

                      return (
                        <Draggable
                          key={hook.id}
                          draggableId={hook?.id || ''}
                          index={index}
                          isDragDisabled={hook?.name === KETCH_DML}
                        >
                          {(provided, snapshot) => (
                            <DraggableHookItem
                              provided={provided}
                              snapshot={snapshot}
                              hook={hook}
                              index={index}
                              shouldHaveBorderBottom={shouldHaveBorderBottom}
                            />
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </Box>
                )
              }}
            </Droppable>
          </DragDropContext>
        </Grid>
      </Grid>
    </FormSection>
  )
}
