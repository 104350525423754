import { NotificationStatus } from 'interfaces/notificationsV2'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  notificationId: string
  status: NotificationStatus
}

type Response = any

export const putNotificationById = ({ notificationId, status }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/notifications/${notificationId}`,
    }),
    { status },
  )
