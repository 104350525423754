import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { IdentitySpacesListDropdown } from 'pages/settings/identitySpaces/list/IdentitySpacesListDropdown'
import { IdentitySpacesRenderer } from 'pages/settings/identitySpaces/components/IdentitySpaceRenderer'
import { IssuesCellRenderer } from 'components/renderers/IssuesCellRenderer'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { Pagination } from 'api/common/paginatedQuery'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

import { ReactComponent as IdentitySpacesEmptyIcon } from 'assets/icons/identitySpacesES.svg'
import { Box } from '@mui/material'

import { ListLayout, TooltipButton, EmptyState, DataGrid, TableCell, Chip } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isLoading: boolean
  isFetching: boolean
  identitySpaces: IdentitySpaceDTO[]
  pagination: Pagination
}

export const IdentitySpacesList: React.FC<Props> = ({ isLoading, isFetching, identitySpaces, pagination }) => {
  const navigate = useNavigate()

  const { isEntitled } = useIsEntitled()
  const isEntitledToCreateIdentitySpace = isEntitled(ENTITLEMENTS.QTY_IDENTITY_SPACE)

  const { isPermitted } = useIsPermitted()
  const isPermittedToCreateIdentitySpace = isPermitted([PERMISSIONS.IDSP_WRITE])

  const createIdentityUrl = RoutesManager.settings.identitySpaces.upsert.root.getURL({ code: NEW_ITEM_CODE })

  const breadcrumbs = [
    {
      title: 'Settings',
      link: RoutesManager.settings.root.getURL(),
    },
    { title: 'Identifiers', link: RoutesManager.settings.identitySpaces.root.getURL() },
  ]

  const columns = useMemo(() => {
    const isIssues = identitySpaces.some(item => !!item.hasIssues)

    return [
      ...(isIssues
        ? [
            {
              field: 'issues',
              headerName: 'Issues',
              width: 35,
              renderCell: (params: GridRenderCellParams) => (
                <TableCell>
                  <IssuesCellRenderer hasIssues={params.row.hasIssues} entityType={ApplicationEntity.IDENTITY_SPACE} />
                </TableCell>
              ),
              sortable: false,
              renderHeader: () => null,
            },
          ]
        : []),
      {
        field: 'identitySpace',
        headerName: 'Identifier',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <NameAndCodeCellRenderer code={params?.row?.code} name={params?.row?.name} />
          </TableCell>
        ),
        sortable: false,
        flex: 1,
      },
      {
        field: 'type',
        headerName: 'Type',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {params?.row?.typeCode ? <Chip size="small" label={params?.row?.typeCode} /> : <EmptyValueRenderer />}
          </TableCell>
        ),
        sortable: false,
        flex: 0.8,
      },
      {
        field: 'format',
        headerName: 'Format',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {params?.row?.formatCode ? <Chip size="small" label={params?.row?.formatCode} /> : <EmptyValueRenderer />}
          </TableCell>
        ),
        sortable: false,
        flex: 0.5,
      },
      {
        field: 'pushesTo',
        headerName: 'Pushes To',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <TableCell>
              <IdentitySpacesRenderer identitySpaceMaps={params?.row?.destinationIdentitySpaceMaps || []} />
            </TableCell>
          )
        },
        sortable: false,
        flex: 0.5,
      },
      {
        field: 'created',
        headerName: 'Created',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <FormattedDateRenderer date={params?.row?.metadata?.createdAt} />
          </TableCell>
        ),
        sortable: false,
        flex: 0.8,
      },
      {
        renderCell: (params: GridRenderCellParams) => <IdentitySpacesListDropdown identitySpace={params?.row} />,
        width: 60,
        sortable: false,
        field: 'action',
        renderHeader: () => null,
      },
    ]
  }, [identitySpaces])

  const totalPages = pagination.totalPages

  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ListLayout
        headerTitle="Identifiers"
        customHeaderRightActionComponent={
          <TooltipButton
            disabled={!isEntitledToCreateIdentitySpace || !isPermittedToCreateIdentitySpace}
            onClick={() => navigate(createIdentityUrl)}
            //@ts-expect-error
            title={
              !isPermittedToCreateIdentitySpace || !isEntitledToCreateIdentitySpace ? (
                <>
                  {!isPermittedToCreateIdentitySpace ? (
                    <span>
                      You do not have sufficient permissions to perform this action, please contact your admin.
                    </span>
                  ) : (
                    <span>No Identifier entitlements remaining, please delete one or contact your admin for more.</span>
                  )}
                </>
              ) : null
            }
          >
            Create Identifier
          </TooltipButton>
        }
        headerProps={{
          isDivider: true,
        }}
      >
        {!isFetching && !identitySpaces.length ? (
          <EmptyState
            customIcon={<IdentitySpacesEmptyIcon />}
            title="There are no Identifiers for this organization"
            subTitle="Create your first one to populate the table"
            primaryButtonTitle={isPermittedToCreateIdentitySpace ? 'Create Identifier' : undefined}
            primaryButtonProps={{ onClick: () => navigate(createIdentityUrl) }}
          />
        ) : (
          <Box flexDirection="column" display="flex">
            <DataGrid
              rows={identitySpaces}
              columns={columns}
              disableChildrenSorting
              disableColumnMenu
              disableColumnResize
              disableColumnReorder
              disableRowSelectionOnClick
              disableAggregation
              hideFooterRowCount
              onRowClick={({ row }) =>
                navigate(RoutesManager.settings.identitySpaces.view.root.getURL({ code: row?.code }))
              }
              getRowId={row => row.ID}
              loading={isLoading || pagination.isPending}
              pagination
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={onPaginationModelChange}
              pageSizeOptions={[rowsPerPage]}
              rowCount={pagination.totalResults || rowsPerPage * totalPages}
              hideFooterPagination={totalPages < 2}
              hideFooter={totalPages < 2}
              getRowHeight={() => 'auto'}
              sx={{
                cursor: 'pointer',
                '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                  backgroundImage: 'unset',
                  cursor: 'auto',
                },
                ...((isLoading || pagination.isPending) && {
                  '& .MuiDataGrid-virtualScroller': {
                    height: '300px',
                  },
                }),
              }}
            />
          </Box>
        )}
      </ListLayout>
    </>
  )
}
