import { useMemo } from 'react'
import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { getContrastColor, fullConfigBeta } from '@ketch-com/lanyard'
import { Group } from 'components/ui-layouts/group/Group'

type Args = {
  theme: ThemeDTO
}

export const useThemeItems = ({ theme }: Args) => {
  const bannerItems = useMemo(() => {
    const {
      bannerBackgroundColor = fullConfigBeta?.theme?.bannerBackgroundColor,
      bannerContentColor,
      bannerButtonColor = fullConfigBeta?.theme?.bannerButtonColor,
      bannerSecondaryButtonColor,
    } = theme

    return [
      {
        name: 'Banner Background',
        color: bannerBackgroundColor,
      },
      {
        name: (
          <Group gap={4}>
            Banner Content
            {!bannerContentColor && '(Default Color)'}
          </Group>
        ),
        color: bannerContentColor || getContrastColor(bannerBackgroundColor),
      },
      {
        name: 'Banner Buttons & Links',
        color: bannerButtonColor,
      },
      {
        name: (
          <Group gap={4}>
            Banner Secondary Button Color
            {!bannerSecondaryButtonColor && '(Default Color)'}
          </Group>
        ),
        color: bannerSecondaryButtonColor || bannerBackgroundColor,
      },
    ]
  }, [theme])

  const modalItems = useMemo(() => {
    const {
      modalHeaderBackgroundColor = fullConfigBeta?.theme?.modalHeaderBackgroundColor,
      modalHeaderContentColor,
      modalContentColor = fullConfigBeta?.theme?.modalContentColor,
      modalButtonColor = fullConfigBeta?.theme?.modalButtonColor,
      modalSwitchOffColor,
      modalSwitchOnColor,
      modalBodyBackgroundColor = fullConfigBeta?.theme?.modalBodyBackgroundColor,
    } = theme

    return [
      {
        name: 'Modal Header & Footer Background',
        color: modalHeaderBackgroundColor,
      },
      {
        name: (
          <Group gap={4}>
            Modal Header & Footer Content
            {!modalHeaderContentColor && '(Default Color)'}
          </Group>
        ),
        color: modalHeaderContentColor || getContrastColor(modalHeaderBackgroundColor),
      },
      {
        name: 'Modal Button',
        color: modalButtonColor,
      },
      {
        name: 'Modal Content',
        color: modalContentColor,
      },
      {
        name: (
          <Group gap={4}>
            Modal Switch Off
            {!modalSwitchOffColor && '(Default Color)'}
          </Group>
        ),
        color: modalSwitchOffColor || '#daddde',
      },
      {
        name: (
          <Group gap={4}>
            Modal Switch On
            {!modalSwitchOnColor && '(Default Color)'}
          </Group>
        ),
        color: modalSwitchOnColor || '#071a24',
      },
      {
        name: 'Modal Background',
        color: modalBodyBackgroundColor,
      },
    ]
  }, [theme])

  const formItems = useMemo(() => {
    const {
      formHeaderBackgroundColor = fullConfigBeta?.theme?.formHeaderBackgroundColor,
      formHeaderContentColor,
      formContentColor = fullConfigBeta?.theme?.formContentColor,
      formButtonColor = fullConfigBeta?.theme?.formButtonColor,
      formSwitchOffColor,
      formSwitchOnColor,
      formBodyBackgroundColor = fullConfigBeta?.theme?.formBodyBackgroundColor,
    } = theme

    return [
      {
        name: 'Form Header Background',
        color: formHeaderBackgroundColor,
      },
      {
        name: (
          <Group gap={4}>
            Form Header Content
            {!formHeaderContentColor && '(Default Color)'}
          </Group>
        ),
        color: formHeaderContentColor || getContrastColor(formHeaderBackgroundColor),
      },
      {
        name: 'Form Content',
        color: formContentColor,
      },
      {
        name: 'Form Buttons',
        color: formButtonColor,
      },
      {
        name: (
          <Group gap={4}>
            Form Switch Off
            {!formSwitchOffColor && '(Default Color)'}
          </Group>
        ),
        color: formSwitchOffColor || '#daddde',
      },
      {
        name: (
          <Group gap={4}>
            Form Switch On
            {!formSwitchOnColor && '(Default Color)'}
          </Group>
        ),
        color: formSwitchOnColor || '#071a24',
      },
      {
        name: 'Form Background',
        color: formBodyBackgroundColor,
      },
    ]
  }, [theme])

  return {
    bannerItems,
    modalItems,
    formItems,
  }
}
