import React from 'react'

// components
import { FormSwitch } from 'components/form/FormSwitch'
import { FormRow } from '@ketch-com/deck'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'

export const CollectAdditionalInformation: React.FC = () => (
  <FormRow
    title="Collect Additional Information"
    subTitle="Subjects will see an extra input field in the Rights Request form asking  “Tell us about your relationship with us.”"
  >
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box>
          <FormSwitch
            formPropertyName="requiresUserInput"
            label="Collect Additional Information"
            inlineLabel
            size="medium"
          />
        </Box>
      </Grid>
    </Grid>
  </FormRow>
)
