import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import React from 'react'

const PREFIX = 'DetailsEmptyState'

const classes = {
  contentContainer: `${PREFIX}-contentContainer`,
  emptyState: `${PREFIX}-emptyState`,
  emptyStateIcon: `${PREFIX}-emptyStateIcon`,
}

const StyledBox = styled(Box)(({ theme: { spacing, palette } }) => ({
  [`&.${classes.contentContainer}`]: {
    flex: 2.54,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: spacing(1.375),
  },

  [`& .${classes.emptyState}`]: {
    backgroundColor: palette.whiteFaded.main,
    paddingTop: spacing(24),
  },

  [`& .${classes.emptyStateIcon}`]: {
    opacity: 0.45,
  },
}))

type Props = {
  title: string
  description: string
  icon: JSX.Element
}

export const DetailsEmptyState: React.FC<Props> = ({ title, description, icon }) => {
  return (
    <StyledBox className={classes.contentContainer}>
      <EmptyState
        titleTextVariant="faded"
        descriptionTextVariant="faded"
        className={classes.emptyState}
        iconClassName={classes.emptyStateIcon}
        variant="page"
        icon={icon}
        title={title}
        description={description}
        spacerHeight={1200}
      />
    </StyledBox>
  )
}
