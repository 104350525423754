import React from 'react'

import { showToast } from 'components/ui-kit/toastr/Toastr'
import {
  DsrFormPolicyStatements,
  updateAttributeStatement,
  setAttributeUpdateErrors,
} from 'store/dsrTransponderFormSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getPolicyStatements, getAttributeUpdateErrors } from 'store/dsrTransponderFormSlice/selectors'
import { OBLIGATION_ENUM, WORKFLOW_TYPE_ENUM } from '../../../constants'
import * as utils from '../utils'
import { getObligationOptions, getShouldResetAttributeError } from '../utils'
import { useFormikContext } from 'formik'
import { IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { AssetDTO } from '@ketch-com/figurehead'

type Args = {
  activeDatabase: string
  attributeCode: string
  datasetCode: string
  attribute: AssetDTO
}

export const useAttributeTableActionCellRendererUtils = ({
  activeDatabase,
  attributeCode,
  attribute,
  datasetCode,
}: Args) => {
  const dispatch = useAppDispatch()
  const policyStatements = useAppSelector(getPolicyStatements)
  const attributeUpdateErrors = useAppSelector(getAttributeUpdateErrors)
  const targetEffectValue = policyStatements?.[activeDatabase]?.statements?.[attributeCode]?.effect || ''
  const attributeDataType = attribute?.resource?.dataType?.name || ''
  const errors = attributeUpdateErrors?.[attributeCode]
  const { values: formikValues } = useFormikContext<IWorkflowConfig>()
  const isAccessFlow = WORKFLOW_TYPE_ENUM.GET === formikValues?.canonicalRightCode
  const hasEffectAssigned = targetEffectValue ? targetEffectValue === OBLIGATION_ENUM.INCLUDE : false

  const handleEffectOnToggle = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const updateValue = checked ? OBLIGATION_ENUM.INCLUDE : OBLIGATION_ENUM.NO_ACTION
    const updatePayload: DsrFormPolicyStatements = utils.getUpdateEffectSelectionPayload({
      activeDatabase,
      policyStatements,
      attributeCode,
      datasetCode,
      updateValue,
    })

    dispatch(updateAttributeStatement(updatePayload))
  }

  const handleEffectSelectOnChange = (e: string) => {
    const updateValue = e

    if (updateValue === OBLIGATION_ENUM.UPDATE) {
      dispatch(
        setAttributeUpdateErrors({
          ...attributeUpdateErrors,
          [attributeCode]: { message: 'Required', isValid: false },
        }),
      )
    }

    if (getShouldResetAttributeError({ updateValue, canonicalRightCode: formikValues?.canonicalRightCode || '' })) {
      dispatch(
        setAttributeUpdateErrors({
          ...attributeUpdateErrors,
          [attributeCode]: {},
        }),
      )
    }

    const updatePayload: DsrFormPolicyStatements = utils.getUpdateEffectSelectionPayload({
      activeDatabase,
      policyStatements,
      attributeCode,
      datasetCode,
      updateValue,
    })

    dispatch(updateAttributeStatement(updatePayload))
  }

  const handleCustomValueOnChange = async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const switchCase = (attribute?.dataCategory?.[0]?.value || '').toLowerCase()
    const updateValue = switchCase === 'string' ? (e.target.value as string) : (e.target.value.trim() as string)

    const maxLength =
      Number(
        ((attribute?.resource?.dataType?.facets as unknown as { [key: string]: any }[]) || []).find(
          (facet: any) => facet.name === 'maxLength',
        )?.value?.value?.integer,
      ) || 255

    if (updateValue.length > maxLength) {
      showToast({
        content: `Cannot exceed max length of ${maxLength} characters`,
        type: 'error',
        autoHideDuration: 3000,
      })
      return
    }

    if (updateValue === '') {
      dispatch(
        setAttributeUpdateErrors({
          ...attributeUpdateErrors,
          [attributeCode]: { message: 'Required', isValid: false },
        }),
      )
      const customValueUpdatePayload = utils.getUpdateCustomValuePayload({
        activeDatabase,
        policyStatements,
        attributeCode,
        updateValue,
      })
      dispatch(updateAttributeStatement(customValueUpdatePayload))
      dispatch(
        setAttributeUpdateErrors({
          ...attributeUpdateErrors,
          [attributeCode]: { message: 'Required', isValid: false },
        }),
      )
      return
    }

    try {
      if (updateValue) {
        await utils.validateCustomValueInput({ switchCase, updateValue })
      }

      dispatch(setAttributeUpdateErrors({ ...attributeUpdateErrors, [attributeCode]: { message: '', isValid: true } }))

      const customValueUpdatePayload = utils.getUpdateCustomValuePayload({
        activeDatabase,
        policyStatements,
        attributeCode,
        updateValue,
      })

      dispatch(updateAttributeStatement(customValueUpdatePayload))
    } catch (error) {
      showToast({
        content: `"${updateValue}" not valid for ${attributeDataType}`,
        type: 'error',
        autoHideDuration: 3000,
      })
    }
  }

  const obligationOptions = getObligationOptions({
    attributeDataType,
    canonicalRightCode: formikValues?.canonicalRightCode || '',
  })

  return {
    attributeDataType,
    errors,
    handleCustomValueOnChange,
    handleEffectSelectOnChange,
    obligationOptions,
    policyStatements,
    targetEffectValue,
    isAccessFlow,
    hasEffectAssigned,
    handleEffectOnToggle,
  }
}
