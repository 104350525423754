import { CustomButtonProps, MegaFilterSectionType } from '@ketch-com/deck'
import React from 'react'

export enum MegaFilterType {
  ChipList = 'chipList',
  CheckList = 'checkList',
}

export type MegaFilterProps = {
  filters: Filter[]
  title?: string
  selectedCount?: number
  isLoading?: boolean
  totalItemsCount?: number
  sections: MegaFilterSectionType[]
  buttonTitle: string
  buttonProps?: CustomButtonProps
}

export type BaseFilterProps = {
  filter: Filter
  children: React.ReactNode
  isLoading?: boolean
}

export type FilterProps = {
  filter: Filter
  isLoading?: boolean
}

export type OverflowChipProps = {
  title: string
  filters: Filter[]
}

export type Filter = {
  key: string
  label: string
  type: MegaFilterType
  items: FilterItem[]
  includeAll?: boolean
}

export type FilterItem = {
  id: string
  label: string
}
