import { useMemo } from 'react'
import * as Yup from 'yup'
import { isEmpty, isUndefined } from 'lodash'

import {
  WorkflowActivityConnectorCode,
  WorkflowActivityGatewayMode,
} from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { useCheckIsWorkflowCodeAvailable } from 'api/workflows/mutations/useCheckIsWorkflowCodeAvailable'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { getIdentityVerificationOrApprovalTileWithoutDecisionBooleanErrors } from '../../components/WorkflowCanvas/utils'

export const useValidationSchema = ({ isEditMode = false }: { isEditMode?: boolean } = {}) => {
  const { mutateAsync: handleCheckIsWorkflowCodeAvailable } = useCheckIsWorkflowCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsWorkflowCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(
    () =>
      Yup.object().shape({
        ...(!isEditMode && {
          code: codeValidationSchema,
        }),
        name: Yup.string().max(75, 'Name must not exceed 75 characters').required('Please input workflow name'),
        steps: Yup.array()
          .test('Must have component', 'Workflows must have at least one component', function (steps: ICanvasStep[]) {
            return !isEmpty(steps.filter(({ finish, start }) => !finish && !start))
          } as Yup.TestFunction)
          .test('Invalid configurations', 'Please address invalid component configurations', function (
            steps: ICanvasStep[],
          ) {
            const invalidItems: Array<ICanvasStep> = []
            const placeholders: Array<ICanvasStep> = []

            steps?.forEach?.(workflowStep => {
              const isSplitGateway = workflowStep?.gateway?.mode === WorkflowActivityGatewayMode.SPLIT

              if (!workflowStep.valid) {
                invalidItems.push(workflowStep)
              }

              if (!isUndefined(workflowStep?.placeholder)) {
                placeholders.push(workflowStep)
              }

              // For Split Gateway there should be at least 2 transitions with connections
              if (isSplitGateway) {
                const connectedTransitions = workflowStep.gateway!.transitions.filter(
                  transition => !!transition.ID,
                ).length
                const isValid = connectedTransitions >= 2

                if (!isValid) {
                  invalidItems.push(workflowStep)
                }
              }
            })

            return isEmpty(invalidItems) && isEmpty(placeholders)
          } as Yup.TestFunction)
          .test('Must have End Flow activity', 'Please add End Flow activity to complete', function (
            steps: ICanvasStep[],
          ) {
            return !isEmpty(
              steps.find(
                step => step.finish || step.workflow?.temporalFunctionName === WorkflowActivityConnectorCode.END,
              ),
            )
          } as Yup.TestFunction)
          .test(
            'Must have Decision Boolean tiles following Identity Verification and Approval activities',
            (steps, { createError }) => {
              const tilesWithoutDecisionBooleanError =
                getIdentityVerificationOrApprovalTileWithoutDecisionBooleanErrors(steps as ICanvasStep[])

              if (!tilesWithoutDecisionBooleanError) return true

              return createError({ message: tilesWithoutDecisionBooleanError, path: 'steps' })
            },
          ),
      }),
    [isEditMode, codeValidationSchema],
  )
}
