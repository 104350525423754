import { ContentGroup, SettingsLayout, SettingsLayoutMenuItem } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useCallback, useEffect, useMemo } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useIsPermitted } from 'utils/hooks'
import { RoutesManager } from 'utils/routing/routesManager'

const developersRoutes = [
  {
    title: 'Tasks',
    path: RoutesManager.updates.tasks.list.pattern,
    permissions: [PERMISSIONS.RTINVQ_READ],
  },
  {
    title: 'Notifications',
    path: RoutesManager.updates.notifications.list.pattern,
    permissions: [PERMISSIONS.RTINVQ_READ],
  },
]

type RouteItemPath = SettingsLayoutMenuItem & {
  path: string
  permissions: Array<string>
}

export const Updates = () => {
  const { isPermitted } = useIsPermitted()

  const location = useLocation()
  const navigate = useNavigate()

  const onRouteMenuItemClick = useCallback(
    (r: RouteItemPath) => {
      navigate(r.path)
    },
    [navigate],
  )

  const routes: RouteItemPath[] = useMemo(
    () =>
      developersRoutes
        .filter(r => isPermitted(r.permissions, true))
        .map(r => {
          const isActive = location.pathname.includes(r.path)
          return {
            ...r,
            listItemProps: {
              selected: isActive,
            },
            onClickHandler: () => {
              onRouteMenuItemClick(r)
            },
          }
        }),
    [isPermitted, location.pathname, onRouteMenuItemClick],
  )

  useEffect(() => {
    if (location.pathname === RoutesManager.updates.root.pattern) {
      navigate(routes[0].path)
    }
  }, [location.pathname, navigate, routes])

  return (
    <Box display="flex" flexDirection="column" gap={3} flex={1} height="calc(100% - 88px);">
      <ContentGroup title="My Updates" isDivider titleVariant="h1" variant="top-header" />
      <SettingsLayout
        menuItems={routes}
        sx={{
          '&.SettingsLayout-container': {
            flex: 1,
            height: 'fit-content',
            marginBottom: '16px',
            borderRadius: '11px !important',
            '& .SettingsLayout-menu': {
              top: '8px',
            },
          },
          '& .SettingsLayout-content': {
            borderRadius: '11px !important',
          },
        }}
      >
        <Outlet />
      </SettingsLayout>
    </Box>
  )
}
