import React from 'react'
import { Box } from '@mui/material'

import { Text } from 'components/ui-kit/typography/Text'

type PopoverTitleValuePairProps = {
  title?: string
  value?: string
  marginBottom?: number
  titleLetterSpacing?: string
}

export const PopoverTitleValuePair: React.FC<PopoverTitleValuePairProps> = ({
  title,
  value,
  marginBottom,
  titleLetterSpacing,
}) => {
  if (!value) return null

  return (
    <>
      <Box mb={0.25}>
        <Text weight={700} size={10} color="whiteFaded" letterSpacing={titleLetterSpacing}>
          {title}
        </Text>
      </Box>
      <Box mb={marginBottom}>
        <Text weight={600} size={12} color="white">
          {value}
        </Text>
      </Box>
    </>
  )
}
