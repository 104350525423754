import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchContextVariablesPaginated } from 'api/contextVariables/fetchers/fetchContextVariablesPaginated'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.contextVariablesPaginated,
  queryFn: fetchContextVariablesPaginated,
  select: res => res?.data?.contextVariables || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useContextVariablesPaginated = (config?: QueryConfig) =>
  useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
    itemsPerPage: config?.params?.limit,
  })
