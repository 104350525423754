import {
  GetNotFoundTrackerPropertiesParams,
  GetTrackerPropertiesResponseBody,
} from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchNotFoundTrackerProperties = ({
  nextToken,
  limit,
  searchString,
  encodedTrackerKey,
  isDisclosed,
}: GetNotFoundTrackerPropertiesParams & { encodedTrackerKey: string }) => {
  return API.get<GetTrackerPropertiesResponseBody>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/tracker/${encodedTrackerKey}/not-found/properties`,
      params: {
        filters: {
          ...(nextToken && {
            nextToken,
          }),
          ...(limit && {
            limit,
          }),
          ...(searchString && {
            searchString,
          }),
          ...(isDisclosed && {
            isDisclosed,
          }),
        },
      },
    }),
  )
}
