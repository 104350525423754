import React from 'react'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { RoutesManager } from 'utils/routing/routesManager'
import { CookieCategoryNames } from 'interfaces/cookies/cookieCategory'
import { CookieDurationsNames } from 'interfaces/cookies/cookieDurations'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { ContentGroup, DataGrid, InfoRow } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { cookieOverviewTabGridColumns } from './utils/cookieOverviewTabDataGridUtils'

type Props = {
  cookie: CookieDTO
}

export const CookieOverviewTab: React.FC<Props> = ({ cookie }) => {
  return (
    <>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
          { title: 'Cookies', link: RoutesManager.policyCenter.cookies.root.getURL() },
          {
            title: cookie?.name || cookie?.code,
            link: RoutesManager.policyCenter.cookies.view.root.getURL({ code: cookie?.code }),
          },
          { title: 'Overview' },
        ]}
      />
      <Box display="flex" flexDirection="column" gap={6}>
        <Box display="flex" flexDirection="column" gap={4}>
          <ContentGroup title="Basic Details" titleVariant="h3" variant="inner-page-header" />
          <InfoRow title="Service Provider">{cookie.serviceProvider}</InfoRow>
          <InfoRow title="Domain">{cookie?.host}</InfoRow>
          <InfoRow title="Category">{CookieCategoryNames[cookie.category]}</InfoRow>
          <InfoRow title="Duration">{CookieDurationsNames[cookie.duration]}</InfoRow>
          <InfoRow title="Description">{cookie.description}</InfoRow>
        </Box>
        {cookie?.purposes?.length && (
          <Box display="flex" flexDirection="column" gap={4}>
            <ContentGroup title="Purposes" titleVariant="h3" variant="inner-page-header" />
            <DataGrid
              sx={{
                paddingLeft: 'unset',
                paddingRight: 'unset',
                paddingTop: 'unset',
              }}
              autosizeOnMount
              columns={cookieOverviewTabGridColumns}
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnReorder
              disableColumnResize
              getRowId={row => row.ID}
              hideFooter
              rows={cookie?.purposes}
              getRowHeight={() => 'auto'}
            />
          </Box>
        )}
      </Box>
    </>
  )
}
