import React from 'react'
import { Box } from '@mui/material'

import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { ReactComponent as VerticalConnectorTwoDots } from 'assets/icons/policy-logic-vertical-connector-two-dots.svg'
import { Text } from 'components/ui-kit/typography/Text'

import { AllowAccessSection, ApplyEffectsSection } from './components'
import { ApplyFiltersSection } from './components/ApplyFiltersSection'
import { PurposeChip } from '..'

type Props = {
  policy: AssetPolicyDTO
}

export const LogicTab: React.FC<Props> = ({ policy }) => {
  return (
    <>
      <Box display="flex" alignItems="center" pb={3.5} pt={1.5}>
        <Text size={20} weight={700}>
          Logic
        </Text>
      </Box>

      <Box mb={1.25}>
        <Text size={14}>
          When accessing data for the <strong>purpose</strong> of:
        </Text>
      </Box>

      <Box sx={{ mb: 1.25 }}>
        <PurposeChip purpose={policy?.purpose || ''} />
      </Box>

      <Box>
        <VerticalConnectorTwoDots />
      </Box>

      {/* Allow Access to: */}

      <AllowAccessSection policy={policy} />

      {/* Apply Effects: */}

      <ApplyEffectsSection policy={policy} />

      {/* Apply Filters: */}

      <ApplyFiltersSection policy={policy} />
    </>
  )
}
