import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  webhookId?: string
}

export const deleteWebhook = ({ webhookId }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/webhooks/${webhookId}`,
    }),
  )
