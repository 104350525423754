import React, { Dispatch, SetStateAction } from 'react'
import { useDebounce } from 'react-use'
import { Box } from '@mui/material'
import {
  Button,
  ListLayout,
  TextInput,
  Icon,
  DropListButton,
  ActionSheetItem,
  ListItemText,
  TabSegmentedGroup,
  TabSegmented,
} from '@ketch-com/deck'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import {
  ASSETS_DROPDOWN_OPTIONS,
  RELATIONSHIP_OPTIONS,
  RELATIONSHIP_TYPE,
  ASSET_TYPE,
} from 'pages/assetManager/relationshipMapping/constants'
import {
  CreateRelationshipMappingModal,
  DeleteRelationshipMappingModal,
} from 'pages/assetManager/relationshipMapping/components/CreatedRelationships/components'
import { HowItWorksModal } from 'pages/assetManager/relationshipMapping/components/HowItWorks'
import { CreatedRelationships, DiscoveredRelationships } from './components'
import { Relationships, GetRelationship } from 'pages/assetManager/interfaces'
import { CreateRelationdhipFormData } from 'pages/assetManager/relationshipMapping/interfaces'
import { MaybeNull } from 'interfaces'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  isReady?: boolean
  relationships?: Relationships
  searchString?: string
  setSearchString?: Dispatch<SetStateAction<string>>
  relationshipSearch?: string
  setRelationshipSearch?: Dispatch<SetStateAction<string>>
  relationshipType?: RELATIONSHIP_TYPE
  setRelationshipType?: Dispatch<SetStateAction<RELATIONSHIP_TYPE>>
  assetType?: ASSET_TYPE
  setAssetType?: Dispatch<SetStateAction<ASSET_TYPE>>
  isFetchingNextPage?: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => {}
  isCreateRelationshipMappingModalOpen: boolean
  setIsCreateRelationshipMappingModalOpen: Dispatch<SetStateAction<boolean>>
  deleteRelationship: MaybeNull<GetRelationship>
  setDeleteRelationship: Dispatch<SetStateAction<MaybeNull<GetRelationship>>>
  onCreateRelationshipMappingFormSubmit: (data: CreateRelationdhipFormData) => void
  onDeleteRelationshipMappingSubmit: (relationship: MaybeNull<GetRelationship>) => void
  isHowItWorksModalOpen: boolean
  setIsHowItWorksModalOpen: Dispatch<SetStateAction<boolean>>
}

export const RelationshipMapping: React.FC<Props> = ({
  relationshipType = RELATIONSHIP_TYPE.CREATED,
  setRelationshipType = () => {},
  relationshipSearch = '',
  setRelationshipSearch = () => {},
  assetType = ASSET_TYPE.DATABASE,
  setAssetType = () => {},
  searchString = relationshipSearch,
  setSearchString = () => {},
  onCreateRelationshipMappingFormSubmit,
  onDeleteRelationshipMappingSubmit,
  ...rest
}) => {
  const { isPermitted } = useIsPermitted()

  const dropListValue = ASSETS_DROPDOWN_OPTIONS.find(({ id }) => id === assetType) || null

  const isPermittedToCreateRelationshipMapping = isPermitted(PERMISSIONS.ASSET_RELATIONSHIP_WRITE)

  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.systems.root.getURL() },
    { title: 'Relationships', link: RoutesManager.assetManager.relationshipMapping.list.getURL() },
  ]

  useDebounce(
    () => {
      if (searchString) {
        setRelationshipSearch(searchString)
      } else {
        setRelationshipSearch('')
      }
    },
    500,
    [searchString],
  )
  return (
    <ListLayout
      headerTitle="Relationship Mapping"
      headerRightActionComponentProps={{
        headerRightActionInfoButtonProps: {
          onClick: () => {
            rest?.setIsHowItWorksModalOpen(true)
          },
        },
      }}
      headerProps={{ isDivider: true }}
      actionHeaderBottomBlockComponent={
        <Box display="flex" justifyContent="space-between" width="100%" gap={3}>
          <Box display="flex" gap={3}>
            <TextInput
              placeholder="Search"
              startIcon={<Icon name="OMag" />}
              size="small"
              fullWidth={false}
              onChange={event => {
                if (event?.currentTarget?.value) {
                  setSearchString?.(event.currentTarget.value)
                }
              }}
              value={searchString}
            />
            <DropListButton
              size="small"
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={option => option.title}
              renderOption={(props, option, { selected }) => (
                <ActionSheetItem selected={selected} {...props}>
                  <ListItemText selected={selected}>{option.title}</ListItemText>
                </ActionSheetItem>
              )}
              value={dropListValue}
              options={ASSETS_DROPDOWN_OPTIONS}
              onChange={(_, value) => value && setAssetType?.(value.id)}
            />
            <TabSegmentedGroup
              value={relationshipType}
              onChange={(_, type) => {
                setRelationshipType?.(type)
              }}
              exclusive
            >
              {RELATIONSHIP_OPTIONS.map(item => (
                <TabSegmented value={item.id} key={item.id} size="small">
                  {item.name}
                </TabSegmented>
              ))}
            </TabSegmentedGroup>
          </Box>
          {relationshipType === RELATIONSHIP_TYPE.CREATED ? (
            <Button
              disabled={!isPermittedToCreateRelationshipMapping && !rest.isReady}
              onClick={() => rest?.setIsCreateRelationshipMappingModalOpen(true)}
            >
              Create Relation
            </Button>
          ) : null}
        </Box>
      }
    >
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box
        p={3}
        sx={{
          backgroundColor: ({ palette }) => palette.white.main,
          borderRadius: '11px',
        }}
      >
        {relationshipType === RELATIONSHIP_TYPE.CREATED ? <CreatedRelationships {...rest} /> : null}
        {relationshipType === RELATIONSHIP_TYPE.DISCOVERED ? <DiscoveredRelationships {...rest} /> : null}
      </Box>
      {rest?.isCreateRelationshipMappingModalOpen ? (
        <CreateRelationshipMappingModal
          onSubmit={onCreateRelationshipMappingFormSubmit}
          onCancel={() => {
            rest?.setIsCreateRelationshipMappingModalOpen(false)
          }}
        />
      ) : null}

      {rest?.deleteRelationship ? (
        <DeleteRelationshipMappingModal
          onSubmit={onDeleteRelationshipMappingSubmit}
          onCancel={() => {
            rest?.setDeleteRelationship(null)
          }}
          deleteRelationship={rest?.deleteRelationship}
        />
      ) : null}

      {rest?.isHowItWorksModalOpen ? (
        <HowItWorksModal
          onCancel={() => {
            rest?.setIsHowItWorksModalOpen(false)
          }}
        />
      ) : null}
    </ListLayout>
  )
}
