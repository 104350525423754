export const getPolicyTypeDisplayText = (category?: number) => {
  switch (category) {
    case 1:
      return 'Rights - Deletion'

    case 2:
      return 'Rights - Access'

    case 3:
      return 'Rights - Portability'

    case 4:
      return 'Access Control'

    default:
      return 'Unspecified'
  }
}
