import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  code: string
  isV2FeatureFlagEnabled: boolean
}

export const checkIsLabelCodeAvailableV2 = ({ code, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/labels/${code}/available`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/labels/${code}/available`
  return API.get<{ available?: boolean }>(
    formatRequestString({
      entityUrl,
    }),
  ).then(({ data }) => !!data?.available)
}
// /v2/labels/{labelCode}/available
