import React from 'react'
import { Box } from '@mui/material'

import { Text } from 'components/ui-kit/typography/Text'
import { HOOK_TYPE_NAMES } from 'pages/developers/hooks/constants'
import { DmlHookDTO } from '@ketch-com/figurehead'

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      prePostBadge: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
        borderColor: palette.iron.main,
        padding: spacing(0.625, 0.825),
        borderRadius: spacing(1),
      },
      chipContainer: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: palette.fadedGrey.main,
        marginLeft: spacing(2.5),
        padding: spacing(0.725, 1.5),
        borderRadius: 100,
      },
    }),
  { name: 'HookOptionChip' },
)

type Props = {
  hook: DmlHookDTO
}

export const HookOptionChip: React.FC<Props> = ({ hook }) => {
  const classes = useStyles()

  return (
    <Box className={classes.chipContainer}>
      {/* PRE/POST badge */}

      <Box className={classes.prePostBadge}>
        <Text size={14}>{HOOK_TYPE_NAMES[hook?.type || 0]}</Text>
      </Box>

      {/* Hook Name */}

      <Text sx={{ ml: 0.625 }} size={14}>
        {hook?.name}
      </Text>
    </Box>
  )
}
