import React from 'react'

import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { ContentGroup } from '@ketch-com/deck'

import { useQueryParams } from 'utils/hooks/useQueryParams'
import { COMMON, RIGHTS_STATISTICS } from 'i18n/types'
import { DataRoleType } from 'interfaces/events'
import moment from 'moment'
import { useAuth } from 'utils/hooks'
import { PolicyScopesDropList } from './PolicyScopesDropList'
import { SubjectTypeDropList } from './SubjectTypeDropList'
import { DataRoleDropList } from './DataRoleDropList'

type Props = {}

const RIGHT_STATISTICS_NEW_FILTER_RELEASE_DATE = '2023-04-01'

const RightsFiltersActionPanel = () => {
  const { setQueryParam, removeQueryParam } = useQueryParams<{
    policyScope?: string
    dataSubjectType?: string
    dataRole?: DataRoleType
  }>()

  const { userData } = useAuth()

  // TODO: Remove after 04/01/2023
  const newFiltersReleaseDate = moment(RIGHT_STATISTICS_NEW_FILTER_RELEASE_DATE)
  const isNewFiltersReleaseDate = Math.sign(moment().diff(newFiltersReleaseDate, 'days')) >= 0
  const isDev = ['localhost', 'sendit.ninja'].some(h => h === window.location.hostname)
  const isOrgAllowed = ['prod_test', 'vara_labs'].some(o => o === userData.organizationCode)

  return (
    <Box gap={1} display="flex">
      <PolicyScopesDropList
        onChange={value => (value ? setQueryParam('policyScope', value) : removeQueryParam('policyScope'))}
      />
      {(isNewFiltersReleaseDate || isDev || isOrgAllowed) && (
        <>
          <SubjectTypeDropList
            onChange={value => (value ? setQueryParam('dataSubjectType', value) : removeQueryParam('dataSubjectType'))}
          />
          <DataRoleDropList
            onChange={value => (value ? setQueryParam('dataRole', value) : removeQueryParam('dataRole'))}
          />
        </>
      )}
    </Box>
  )
}

export const RightsFilters: React.FC<Props> = () => {
  const { t } = useTranslation([RIGHTS_STATISTICS, COMMON])

  return (
    <ContentGroup
      variant="top-header"
      title={t('Rights Invocations') as string}
      titleVariant="h1"
      actionBottomBlockComponent={<RightsFiltersActionPanel />}
      isDivider
    />
  )
}
