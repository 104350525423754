import { useState } from 'react'
import { Box } from '@mui/material'

import { Checkbox } from 'components/ui-kit/checkbox/Checkbox'
import { Tabs, TabsConfig } from 'components/ui-kit/tabs/Tabs'
import { useMegaFilterStyles } from '../hooks'
import { FILTER_STATES } from '../constants'
import { FilterConfigItem } from '../utils/getFiltersConfig'
import { LoadingIndicator } from './LoadingIndicator'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'

type Props = {
  rowData: FilterConfigItem
  filterState: FILTER_STATES
}

const dummyTech = [
  {
    id: '1',
    name: 'Tech 1',
  },
  {
    id: '2',
    name: 'Tech 2',
  },
  {
    id: '3',
    name: 'Tech 3',
  },
]

const dummyProviders = [
  {
    id: '1',
    name: 'Provider 1',
  },
  {
    id: '2',
    name: 'Provider 2',
  },
  {
    id: '3',
    name: 'Provider 3',
  },
]
/*
    TODO:BAC - note, this component is a work in progress.  It's not rendered in the UI yet.
    It will be a helper component the the MegaFilter workflow.
*/
export const MegaFilterTechAndProviderExpandableRow: React.FC<Props> = ({ rowData, filterState }) => {
  const classes = useMegaFilterStyles({ filterState })
  const [searchString, setSearchString] = useState<string>('')
  const isLoading = false
  const [activeTab, setActiveTab] = useState<string>('tech')
  const tabsConfig: TabsConfig[] = [
    {
      key: 'tech',
      title: `Technology ${dummyTech.length}`,
      active: activeTab === 'tech',
      onClick: () => setActiveTab('tech'),
    },
    {
      key: 'provider',
      title: `Provider ${dummyProviders.length}`,
      active: activeTab === 'provider',
      onClick: () => setActiveTab('provider'),
    },
  ]

  return (
    <Box className={classes.filterOptionsContainer}>
      <SearchInput
        fullWidth
        isSearchPending={false}
        size="small"
        onChange={nextSearchString => setSearchString(nextSearchString)}
        onClear={() => setSearchString('')}
        value={searchString}
      />
      <Tabs size="small" config={tabsConfig} />

      {/* Initial Loading Indicator, When Expansion Panel First Opens */}

      {isLoading && (
        <Box mt={2}>
          <LoadingIndicator />
        </Box>
      )}

      {activeTab === 'tech' && (
        <Box mt={2} pb={2} display="flex" flexDirection="column" maxHeight={150} overflow="scroll">
          {dummyTech.map(tech => {
            // const isChecked = !!(queries?.purpose || '')?.split(',').includes(tech.id)
            const isChecked = false
            return (
              <Box className={classes.typeCheckboxContainer} key={tech.id}>
                <Checkbox
                  id={tech.id}
                  name={tech.id}
                  title={tech.name}
                  value={isChecked}
                  onChange={() => {
                    // const currentPurposes = (queries?.purpose || '').split(',') || []
                    // const potentiallyDuplicatedTypes = [...currentPurposes, tech.id]
                    // if (isChecked) {
                    //   const payload = [...new Set(potentiallyDuplicatedTypes)]
                    //     .filter(el => !!el)
                    //     .filter(el => el !== tech.id)
                    //     .join(',')
                    //   setQueryParam('purpose', payload)
                    // } else {
                    //   const payload = [...new Set(potentiallyDuplicatedTypes)].filter(el => !!el).join(',')
                    //   setQueryParam('purpose', payload)
                    // }
                  }}
                />
              </Box>
            )
          })}
        </Box>
      )}

      {activeTab === 'provider' && (
        <Box mt={2} pb={2} display="flex" flexDirection="column" maxHeight={150} overflow="scroll">
          {dummyProviders.map(provider => {
            // const isChecked = !!(queries?.purpose || '')?.split(',').includes(provider.id)
            const isChecked = false
            return (
              <Box className={classes.typeCheckboxContainer} key={provider.id}>
                <Checkbox
                  id={provider.id}
                  name={provider.id}
                  title={provider.name}
                  value={isChecked}
                  onChange={() => {
                    // const currentPurposes = (queries?.purpose || '').split(',') || []
                    // const potentiallyDuplicatedTypes = [...currentPurposes, tech.id]
                    // if (isChecked) {
                    //   const payload = [...new Set(potentiallyDuplicatedTypes)]
                    //     .filter(el => !!el)
                    //     .filter(el => el !== tech.id)
                    //     .join(',')
                    //   setQueryParam('purpose', payload)
                    // } else {
                    //   const payload = [...new Set(potentiallyDuplicatedTypes)].filter(el => !!el).join(',')
                    //   setQueryParam('purpose', payload)
                    // }
                  }}
                />
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}
