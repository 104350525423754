import { format } from 'date-fns'
import moment from 'moment'

export const formatDateTimeFromUnix: (unixTimeStamp?: string | number, formatType?: string) => any = (
  unixTimeStamp = '',
  formatType = 'MMM D YYYY, h:mm A',
) => (unixTimeStamp ? moment.unix(+unixTimeStamp).format(formatType) : '')

export const formatLastUpdatedDate = (lastUpdated?: number) =>
  lastUpdated ? format(new Date(lastUpdated * 1000), 'MMM dd yyyy, p') : 'Loading...'
