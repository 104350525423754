import React from 'react'
import Box from '@mui/material/Box'

import { makeStyles, createStyles } from '@mui/styles'

import { MaybeNull } from 'interfaces/common'
import { Text } from 'components/ui-kit/typography/Text'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { AssetSummaryDTO } from '@ketch-com/figurehead'

const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      assetCloudImage: {
        width: 36,
        height: 36,
        marginRight: spacing(2),
      },
      capitalCase: {
        textTransform: 'capitalize',
      },
    }),
  { name: 'SystemLabelWithIcon' },
)

type Props = {
  title: string
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export const SystemLabelWithIcon: React.FC<Props> = ({ assetSummary, title }) => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Box mb={1}>
        <Text weight={600} size={12}>
          {title}
        </Text>
      </Box>
      <Box display="flex" alignItems="center">
        <img
          src={getAssetCloudImgUrl(assetSummary?.asset?.connection?.technology || '')}
          className={classes.assetCloudImage}
          alt={getAssetCloudImgUrl(assetSummary?.asset?.connection?.technology || '')}
        />
        <Text size={14} className={classes.capitalCase}>
          {assetSummary?.asset?.connection?.technology || ''}
        </Text>
      </Box>
    </Box>
  )
}
