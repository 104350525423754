import React, { JSXElementConstructor } from 'react'
import { compact } from 'lodash'
import { TreeItem, TreeItemContentProps } from '@mui/x-tree-view'
import { ObjectLiteral } from 'interfaces'

interface Props<T> {
  item: T
  onItemClick: (item: T) => void
  ContentComponent: JSXElementConstructor<TreeItemContentProps>
  type: string
  searchString?: string
  expandableKey?: string
  expanded: string[]
  nestedTreeLevel?: number
}

export function constructListItem<T extends ObjectLiteral>({
  item,
  onItemClick,

  type,
  searchString,
  expandableKey,
  expanded,
  nestedTreeLevel = 0,
  ContentComponent,
}: Props<T>) {
  return (
    <TreeItem
      ContentComponent={ContentComponent}
      sx={{
        '& .MuiCollapse-root.MuiCollapse-vertical.MuiTreeItem-group': {
          marginLeft: 'unset',
          width: '100%',
        },
      }}
      nodeId={item.code}
      label="Applications"
      ContentProps={
        {
          item: item,
          nestedTreeLevel,
          onItemClick: () => onItemClick(item),
          type,
          searchString,
        } as any
      }
    >
      {expandableKey && item[expandableKey]
        ? item[expandableKey].map((item: T) =>
            constructListItem({
              item,
              onItemClick,
              type,
              searchString,
              expandableKey,
              expanded,
              nestedTreeLevel: nestedTreeLevel + 1,
              ContentComponent,
            }),
          )
        : null}
    </TreeItem>
  )
}

interface FilterTreeItemProps<T> {
  item: T
  search: string
  expandableKey?: string
}

export function filterTreeItem<T extends ObjectLiteral>({
  item,
  search,
  expandableKey = 'regions',
}: FilterTreeItemProps<T>) {
  const isItemMatched = item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
  const currentLevelItemFiltered = isItemMatched ? item : null

  if (item[expandableKey]) {
    const childItemsFiltered = compact(
      item[expandableKey].map((item: T) => filterTreeItem({ item, search, expandableKey })),
    )

    return childItemsFiltered.length
      ? {
          ...item,
          [expandableKey]: childItemsFiltered,
        }
      : currentLevelItemFiltered
  } else {
    return currentLevelItemFiltered
  }
}

export function getAllCodes<T extends ObjectLiteral>(data: Array<T>): string[] {
  const result: string[] = []

  function extractCodes(obj: T) {
    if (obj.code) {
      result.push(obj.code)
    }

    if (obj.regions && Array.isArray(obj.regions)) {
      obj.regions.forEach(region => {
        extractCodes(region)
      })
    }
  }

  data.forEach((entry: T) => {
    extractCodes(entry)
  })

  return result
}
