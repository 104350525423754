import { Checkbox, TextInputProps } from '@ketch-com/deck'

import { useFormikContext } from 'formik'
import { ApplicationDTO } from 'interfaces/applications/application'
import { CookieFormDTO } from 'interfaces/cookies/cookie'

type FormCookieApplicationsCheckboxProps = {
  row: ApplicationDTO
} & TextInputProps

const cookieApplicationsCheckboxFormKeys = {
  propertyCookies: 'propertyCookies',
} as const

export const FormCookieApplicationsCheckbox = ({ row, ...rest }: FormCookieApplicationsCheckboxProps) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext<CookieFormDTO>()

  const isChecked = !!values.propertyCookies?.find(item => item.propertyCode === row.code)

  const onChange = () => {
    setFieldTouched(cookieApplicationsCheckboxFormKeys.propertyCookies)

    if (isChecked) {
      setFieldValue(
        cookieApplicationsCheckboxFormKeys.propertyCookies,
        values.propertyCookies?.filter(item => item.propertyCode !== row.code),
      )
    } else {
      setFieldValue(cookieApplicationsCheckboxFormKeys.propertyCookies, [
        ...(values?.propertyCookies || []),
        {
          cookieID: values.code,
          propertyCode: row.code,
        },
      ])
    }
  }

  return <Checkbox checked={isChecked} onChange={onChange} inputProps={{ 'aria-label': 'controlled' }} />
}
