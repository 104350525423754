import { RightInvocationStatusTextHumanReadable } from 'components/ui-kit/status/interfaces'
import { RightInvocationDTO, RightInvocationRequestStatusDTO } from '@ketch-com/figurehead'

export const getDetailViewStatusText = (
  rightInvocation: RightInvocationDTO,
): RightInvocationStatusTextHumanReadable => {
  const { requestStatus } = rightInvocation

  switch (requestStatus) {
    case RightInvocationRequestStatusDTO.FulfilledRightInvocationRequestStatus:
      return RightInvocationStatusTextHumanReadable.COMPLETED
    case RightInvocationRequestStatusDTO.RejectedRightInvocationRequestStatus:
      return RightInvocationStatusTextHumanReadable.REJECTED
    case RightInvocationRequestStatusDTO.DueSoonRightInvocationRequestStatus:
      return RightInvocationStatusTextHumanReadable.DUE_SOON
    case RightInvocationRequestStatusDTO.DueTodayRightInvocationRequestStatus:
      return RightInvocationStatusTextHumanReadable.DUE_TODAY
    case RightInvocationRequestStatusDTO.OverdueRightInvocationRequestStatus:
      return RightInvocationStatusTextHumanReadable.OVERDUE
    case RightInvocationRequestStatusDTO.OnTrackRightInvocationRequestStatus:
      return RightInvocationStatusTextHumanReadable.ON_TRACK
    default:
      return RightInvocationStatusTextHumanReadable.PENDING
  }
}
