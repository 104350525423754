import { Typography } from '@mui/material'
import { Button, theme } from '@ketch-com/deck'
import React, { useContext } from 'react'
import { styled } from '@mui/styles'
import Box from '@mui/material/Box'
import { ExperienceUpsertContext } from '../../../context/ExperienceUpsertContext'
import { ExperienceBuilderSectionLabels } from '../../builder/utils/labels'
import {
  BannerExperienceBuilderSection,
  ExperienceBuilderSection,
  ModalExperienceBuilderSection,
  PreferenceExperienceBuilderSection,
  ProgressiveExperienceBuilderSection,
} from '../../builder/utils/enums'
import { experienceUpsertFormKeys } from '../../../utils/useExperienceValidationSchema'
import { getIn, useFormikContext } from 'formik'
import { getFormNameForLocale } from '../../../utils/utils'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { defaultExperienceV2DTO, defaultProgressiveExperienceV2DTO } from '../../../utils/defaults'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'

// Mapping of builder sections to the form values which should be reset when "Reset to Defaults" is clicked
const resetLayoutMapping: { [section: string]: string | string[] } = {
  [BannerExperienceBuilderSection.Header]: experienceUpsertFormKeys.resetBannerHeaderLayout,
  [BannerExperienceBuilderSection.Description]: experienceUpsertFormKeys.resetBannerDescriptionLayout,
  [BannerExperienceBuilderSection.ActionButtons]: experienceUpsertFormKeys.resetBannerButtonsLayout,
  [BannerExperienceBuilderSection.Footer]: experienceUpsertFormKeys.resetBannerFooterLayout,
  [ModalExperienceBuilderSection.Header]: experienceUpsertFormKeys.resetModalHeaderLayout,
  [ModalExperienceBuilderSection.Description]: experienceUpsertFormKeys.resetModalDescriptionLayout,
  [ModalExperienceBuilderSection.DescriptionTitle]: experienceUpsertFormKeys.resetModalDescriptionTitleLayout,
  [ModalExperienceBuilderSection.ListHeader]: experienceUpsertFormKeys.resetModalListHeaderLayout,
  [ModalExperienceBuilderSection.PurposeList]: experienceUpsertFormKeys.resetModalPurposeListLayout,
  [ModalExperienceBuilderSection.Footer]: experienceUpsertFormKeys.resetModalFooterLayout,
  [PreferenceExperienceBuilderSection.WelcomeTabHeader]: experienceUpsertFormKeys.resetPreferenceWelcomeTabHeaderLayout,
  [PreferenceExperienceBuilderSection.WelcomeTabFooter]: experienceUpsertFormKeys.resetPreferenceWelcomeTabFooterLayout,
  [PreferenceExperienceBuilderSection.WelcomeTabWelcomeMessage]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabWelcomeMsgLayout,
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinks]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabQuickLinksLayout,
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLinkTwo]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabQuickLinksLayout,
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLinkThree]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabQuickLinksLayout,
  [PreferenceExperienceBuilderSection.WelcomeTabAbout]: experienceUpsertFormKeys.resetPreferenceWelcomeTabAboutLayout,
  [PreferenceExperienceBuilderSection.WelcomeTabGpcBadge]: experienceUpsertFormKeys.preferenceGpcBadgeVisible,
  [PreferenceExperienceBuilderSection.WelcomeTabExitButton]: experienceUpsertFormKeys.preferenceExitButtonPosition,
  [PreferenceExperienceBuilderSection.WelcomeTabAboutDescription]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabAboutLayout,
  [PreferenceExperienceBuilderSection.PurposesTabHeaderTitle]:
    experienceUpsertFormKeys.resetPreferencePurposesTabHeaderLayout,
  [PreferenceExperienceBuilderSection.PurposesTabHeaderDescription]:
    experienceUpsertFormKeys.resetPreferencePurposesTabHeaderLayout,
  [PreferenceExperienceBuilderSection.PurposesTabListHeader]:
    experienceUpsertFormKeys.resetPreferencePurposesTabListHeaderLayout,
  [PreferenceExperienceBuilderSection.PurposesTabList]: experienceUpsertFormKeys.resetPreferencePurposesTabListLayout,
  [PreferenceExperienceBuilderSection.PurposesTabActionButton]:
    experienceUpsertFormKeys.resetPreferencePurposesTabFooterLayout,
  [PreferenceExperienceBuilderSection.SubscriptionsTabHeaderTitle]:
    experienceUpsertFormKeys.resetPreferenceSubscriptionsTabHeaderTitleLayout,
  [PreferenceExperienceBuilderSection.SubscriptionsTabHeaderDescription]:
    experienceUpsertFormKeys.resetPreferenceSubscriptionsTabHeaderDescriptionLayout,
  [PreferenceExperienceBuilderSection.SubscriptionsTabUnsubscribeAll]:
    experienceUpsertFormKeys.resetPreferenceSubscriptionsTabUnsubscribeAllLayout,
  [PreferenceExperienceBuilderSection.SubscriptionsTabList]:
    experienceUpsertFormKeys.resetPreferenceSubscriptionsTabListLayout,
  [PreferenceExperienceBuilderSection.SubscriptionsTabActionButton]:
    experienceUpsertFormKeys.resetPreferenceSubscriptionsTabActionButtonLayout,
  [PreferenceExperienceBuilderSection.RequestsHomeHeaderTitle]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabHomeHeaderTitleLayout,
  [PreferenceExperienceBuilderSection.RequestsHomeHeaderDescription]: '',
  [PreferenceExperienceBuilderSection.RequestsHomeDsrLink]: '',
  [PreferenceExperienceBuilderSection.RequestsHomeRightsListTitle]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabHomeRightsLayout,
  [PreferenceExperienceBuilderSection.RequestsHomeRightsListRequestTypes]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabHomeRightsLayout,
  [PreferenceExperienceBuilderSection.RequestsFormContent]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabFormRecaptchaLayout,
  [PreferenceExperienceBuilderSection.RequestsFormActionButton]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabFormActionButtonLayout,
  [PreferenceExperienceBuilderSection.RequestsSubmittedHeader]: '',
  [PreferenceExperienceBuilderSection.RequestsSubmittedSubtitle]: '',
  [PreferenceExperienceBuilderSection.RequestsSubmittedDescription]: '',
  [PreferenceExperienceBuilderSection.RequestsSubmittedActionButton]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabSubmittedActionButtonLayout,
  [ProgressiveExperienceBuilderSection.Container]: [
    experienceUpsertFormKeys.resetProgressiveExperienceContainerType,
    experienceUpsertFormKeys.resetProgressiveExperienceContainerPosition,
    experienceUpsertFormKeys.resetProgressiveExperienceContainerConsentLayout,
  ],
  [ProgressiveExperienceBuilderSection.Header]: experienceUpsertFormKeys.resetProgressiveExperienceHeaderLayout,
  [ProgressiveExperienceBuilderSection.Description]:
    experienceUpsertFormKeys.resetProgressiveExperienceDescriptionLayout,
}

const resetTranslationsMapping: { [section: string]: string } = {
  [BannerExperienceBuilderSection.Header]: experienceUpsertFormKeys.resetBannerHeaderTranslations,
  [BannerExperienceBuilderSection.Description]: experienceUpsertFormKeys.resetBannerDescriptionTranslations,
  [BannerExperienceBuilderSection.ActionButtons]: experienceUpsertFormKeys.resetBannerButtonsTranslations,
  [BannerExperienceBuilderSection.Footer]: experienceUpsertFormKeys.resetBannerFooterTranslations,
  [ModalExperienceBuilderSection.Header]: experienceUpsertFormKeys.resetModalHeaderTranslations,
  [ModalExperienceBuilderSection.Description]: experienceUpsertFormKeys.resetModalDescriptionTranslations,
  [ModalExperienceBuilderSection.DescriptionTitle]: experienceUpsertFormKeys.resetModalDescriptionTitleTranslations,
  [ModalExperienceBuilderSection.ListHeader]: experienceUpsertFormKeys.resetModalListHeaderTranslations,
  [ModalExperienceBuilderSection.PurposeList]: experienceUpsertFormKeys.resetModalPurposeListTranslations,
  [ModalExperienceBuilderSection.Footer]: experienceUpsertFormKeys.resetModalFooterTranslations,
  [PreferenceExperienceBuilderSection.WelcomeTabHeader]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabHeaderTranslations,
  [PreferenceExperienceBuilderSection.WelcomeTabNavigation]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabNavigationTranslations,
  [PreferenceExperienceBuilderSection.WelcomeTabFooter]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabFooterTranslations,
  [PreferenceExperienceBuilderSection.WelcomeTabWelcomeMessage]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabWelcomeMsgTranslations,
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinks]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabQuickLinksTranslations,
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLinkTwo]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabQuickLinksTranslations,
  [PreferenceExperienceBuilderSection.WelcomeTabQuickLinksLinkThree]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabQuickLinksTranslations,
  [PreferenceExperienceBuilderSection.WelcomeTabAbout]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabAboutTranslations,
  [PreferenceExperienceBuilderSection.WelcomeTabExitButton]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabExitButtonTranslations,
  [PreferenceExperienceBuilderSection.WelcomeTabAboutDescription]:
    experienceUpsertFormKeys.resetPreferenceWelcomeTabAboutTranslations,
  [PreferenceExperienceBuilderSection.WelcomeTabGpcBadge]: '',
  [PreferenceExperienceBuilderSection.PurposesTabHeaderTitle]:
    experienceUpsertFormKeys.resetPreferencePurposesTabHeaderTitleTranslations,
  [PreferenceExperienceBuilderSection.PurposesTabHeaderDescription]:
    experienceUpsertFormKeys.resetPreferencePurposesTabHeaderDescriptionTranslations,
  [PreferenceExperienceBuilderSection.PurposesTabListHeader]:
    experienceUpsertFormKeys.resetPreferencePurposesTabListHeaderTranslations,
  [PreferenceExperienceBuilderSection.PurposesTabList]:
    experienceUpsertFormKeys.resetPreferencePurposesTabListTranslations,
  [PreferenceExperienceBuilderSection.PurposesTabActionButton]:
    experienceUpsertFormKeys.resetPreferencePurposesTabActionButtonTranslations,
  [PreferenceExperienceBuilderSection.SubscriptionsTabHeaderTitle]:
    experienceUpsertFormKeys.resetPreferenceSubscriptionsTabHeaderTitleTranslations,
  [PreferenceExperienceBuilderSection.SubscriptionsTabHeaderDescription]:
    experienceUpsertFormKeys.resetPreferenceSubscriptionsTabHeaderDescriptionTranslations,
  [PreferenceExperienceBuilderSection.SubscriptionsTabUnsubscribeAll]:
    experienceUpsertFormKeys.resetPreferenceSubscriptionsTabUnsubscribeAllTranslations,
  [PreferenceExperienceBuilderSection.SubscriptionsTabList]:
    experienceUpsertFormKeys.resetPreferenceSubscriptionsTabListTranslations,
  [PreferenceExperienceBuilderSection.SubscriptionsTabActionButton]:
    experienceUpsertFormKeys.resetPreferenceSubscriptionsTabActionButtonTranslations,
  [PreferenceExperienceBuilderSection.RequestsHomeHeaderTitle]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabHomeHeaderTitleTranslations,
  [PreferenceExperienceBuilderSection.RequestsHomeHeaderDescription]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabHomeHeaderDescriptionTranslations,
  [PreferenceExperienceBuilderSection.RequestsHomeDsrLink]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabHomeDsrLinkTranslations,
  [PreferenceExperienceBuilderSection.RequestsHomeRightsListTitle]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabHomeRightsTranslations,
  [PreferenceExperienceBuilderSection.RequestsHomeRightsListRequestTypes]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabHomeRightsTranslations,
  [PreferenceExperienceBuilderSection.RequestsFormContent]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabFormContentTranslations,
  [PreferenceExperienceBuilderSection.RequestsFormActionButton]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabFormActionButtonTranslations,
  [PreferenceExperienceBuilderSection.RequestsSubmittedHeader]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabSubmittedHeaderTranslations,
  [PreferenceExperienceBuilderSection.RequestsSubmittedSubtitle]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabSubmittedSubtitleTranslations,
  [PreferenceExperienceBuilderSection.RequestsSubmittedDescription]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabSubmittedDescriptionTranslations,
  [PreferenceExperienceBuilderSection.RequestsSubmittedActionButton]:
    experienceUpsertFormKeys.resetPreferenceRequestsTabSubmittedActionButtonTextTranslations,
}

const hideBorderSections: ExperienceBuilderSection[] = [
  BannerExperienceBuilderSection.ActionButtons,
  PreferenceExperienceBuilderSection.WelcomeTabNavigation,
  PreferenceExperienceBuilderSection.WelcomeTabAbout,
  PreferenceExperienceBuilderSection.WelcomeTabAboutDescription,
  PreferenceExperienceBuilderSection.RequestsHomeRightsListTitle,
  PreferenceExperienceBuilderSection.RequestsHomeRightsListRequestTypes,
]

if (isRelease(Release.FormsWithinExperienceServer)) {
  hideBorderSections.push(PreferenceExperienceBuilderSection.RequestsFormContent)
}

const SidebarHeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

export const SidebarHeader: React.FC = () => {
  const { experienceBuilderSection, languages, isLanguagesLoading, experienceType } =
    useContext(ExperienceUpsertContext)
  const { setFieldValue } = useFormikContext<ExperienceV2DTO>()

  const defaultExperience =
    experienceType === ExperienceType.Progressive ? defaultProgressiveExperienceV2DTO : defaultExperienceV2DTO

  // Reset this section to the default values
  const handleResetClick = () => {
    if (experienceBuilderSection) {
      // Reset layout fields
      const fieldToReset = resetLayoutMapping[experienceBuilderSection]
      if (fieldToReset) {
        if (typeof fieldToReset === 'string') {
          setFieldValue(fieldToReset, getIn(defaultExperience, fieldToReset))
        } else if (Array.isArray(fieldToReset)) {
          fieldToReset.forEach(field => {
            setFieldValue(field, getIn(defaultExperience, field))
          })
        }
      }

      if (!isLanguagesLoading) {
        // Reset fields for english
        const translationFieldName = resetTranslationsMapping[experienceBuilderSection]
        if (translationFieldName) {
          const fieldToReset = getFormNameForLocale(translationFieldName, 'en')
          if (fieldToReset) setFieldValue(fieldToReset, getIn(defaultExperience, fieldToReset))

          // Reset fields for each locale
          languages.forEach(({ language: { code } }) => {
            const fieldToReset = getFormNameForLocale(translationFieldName, code)
            if (fieldToReset) setFieldValue(fieldToReset, getIn(defaultExperience, fieldToReset))
          })
        }
      }
    }
  }

  return (
    <SidebarHeaderBox
      sx={
        experienceBuilderSection && hideBorderSections.includes(experienceBuilderSection)
          ? { marginBottom: '32px' }
          : {
              borderBottom: `1px solid ${theme.palette.iron.main}`,
              paddingBottom: '16px',
              marginBottom: '24px',
            }
      }
    >
      <Typography variant={'h3'}>
        {experienceBuilderSection ? ExperienceBuilderSectionLabels[experienceBuilderSection] : 'Section'}
      </Typography>
      <Button
        onClick={handleResetClick}
        variant={'text'}
        sx={{ marginLeft: 'auto', ':hover': { textDecoration: 'none' } }}
      >
        <Typography variant={'labelLink'} color={theme.palette.sphere.main}>
          Reset to Defaults
        </Typography>
      </Button>
    </SidebarHeaderBox>
  )
}
