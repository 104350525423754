import { FC } from 'react'
import { useFormikContext } from 'formik'
import { Box, Grid, Typography } from '@mui/material'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import {
  ActionSheetItem,
  Chip,
  ChipsAutocomplete,
  ChipsAutocompleteOptionType,
  FormRow,
  Icon,
  ListItemText,
} from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { APPLICATION_PLATFORM_OPTIONS, ApplicationPlatform } from 'interfaces/applications/applicationPlatform'
import { formatCodeFromName } from 'utils/formatters'
import { ApplicationFormValues } from 'interfaces/applications/ApplicationFormValues'
import { ApplicationIdentitySpaceFormat } from 'interfaces/applications/ApplicationIdentitySpaceFormat'
import { ApplicationIdentitySpaceType } from 'interfaces/applications/applicationIdentitySpaceType'
import { getIdentitySpaceEmptyValue } from '../utils'

type Props = {
  isEditMode?: boolean
  dataSubjectTypes: DataSubjectTypeDTO[]
}

type DataSubjectTypeOption = { value: string; label: string } & ChipsAutocompleteOptionType

export const ApplicationBasicDetails: FC<Props> = ({ isEditMode = false, dataSubjectTypes }) => {
  const { errors, touched, values, setFieldValue, setFieldTouched } = useFormikContext<ApplicationFormValues>()
  const { dataSubjectTypeCodes, identitySpaces } = values

  const dataSubjectTypeOptions: DataSubjectTypeOption[] = dataSubjectTypes.map(dataSubjectType => ({
    value: dataSubjectType?.code ?? '',
    label: dataSubjectType?.name ?? '',
  }))
  const dataSubjectTypeValues: DataSubjectTypeOption[] = dataSubjectTypes
    .filter(dataSubjectType => dataSubjectTypeCodes?.includes(dataSubjectType?.code ?? ''))
    .map(dataSubjectType => ({
      value: dataSubjectType?.code ?? '',
      label: dataSubjectType?.name ?? '',
    }))

  const handleInitializeIdentitySpacesIfNeeded = (isMobileProperty: boolean = false) => {
    const isMobileWithNonStringIdentifier =
      isMobileProperty &&
      identitySpaces.some(
        identifier =>
          identifier.format !== ApplicationIdentitySpaceFormat.STRING ||
          identifier.type !== ApplicationIdentitySpaceType.QUERY_STRING,
      )

    if (!identitySpaces.length || isMobileWithNonStringIdentifier) {
      setFieldValue('identitySpaces', [getIdentitySpaceEmptyValue(isMobileProperty)])
    }
  }

  return (
    <FormRow title="Basic Details">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormRadioGroup
            row
            hideOptionalLabel
            size="medium"
            label="Property Type"
            formPropertyName="platform"
            valueKey="id"
            options={APPLICATION_PLATFORM_OPTIONS}
            renderLabel={({ name }) => name}
            onChange={(_, nextValue) => {
              if (Number(nextValue) === ApplicationPlatform.MOBILE) {
                handleInitializeIdentitySpacesIfNeeded(true)
                setFieldValue('customIdentitySpaces', true)
              }
            }}
          />
        </Grid>
        <Grid container item spacing={4}>
          <Grid item xs={6}>
            <FormInput
              fullWidth
              required
              label="Name"
              formPropertyName="name"
              placeholder="Add Property Name"
              onChange={e => {
                if (!isEditMode) {
                  setFieldTouched('code', true)
                  setFieldValue('code', formatCodeFromName(e.target.value))
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              fullWidth
              required
              label="Code"
              formPropertyName="code"
              placeholder="Add Code"
              disabled={isEditMode}
            />
          </Grid>
          <Grid item xs={12} mb={!!dataSubjectTypeValues.length ? -2 : -4}>
            <ChipsAutocomplete
              inRowChips
              disableClearable
              multiple
              fullWidth
              width="380px"
              chipSize="medium"
              label="Data Subject(s)"
              placeholder="Start typing data subject name"
              value={dataSubjectTypeValues}
              options={dataSubjectTypeOptions}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              error={!!errors?.dataSubjectTypeCodes && !!touched?.dataSubjectTypeCodes}
              errorMessage={errors?.dataSubjectTypeCodes}
              onChange={(_, value) => {
                setFieldValue(
                  'dataSubjectTypeCodes',
                  value.map(item => item.value),
                )
              }}
              getOptionLabel={option => option.value}
              renderOption={(props, option, { selected }) => (
                <ActionSheetItem selected={selected} {...props}>
                  <ListItemText selected={selected}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body">{option.label}</Typography>
                      <Typography variant="footnote">{option.value}</Typography>
                    </Box>
                  </ListItemText>
                </ActionSheetItem>
              )}
              renderChipItem={data => (
                <Chip
                  key={data.value}
                  label={data.label}
                  secondaryLabel={data.value}
                  deleteIcon={<Icon name="OCross" />}
                  onDelete={() => {
                    setFieldValue('dataSubjectTypeCodes', dataSubjectTypeCodes?.filter(code => code !== data.value))
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormRow>
  )
}
