import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  controllerCode: string
  organizationCode: string
}

export const deleteController = ({ organizationCode, controllerCode }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/controllers/${controllerCode}`,
    }),
  )
