import { POLICY_EFFECT_URL_ENUM } from '../constants'

export const getMapEffectStringToEffectUrl = (value: string) => {
  switch (value) {
    case 'mask':
      return POLICY_EFFECT_URL_ENUM.MASK
    case 'hash':
      return POLICY_EFFECT_URL_ENUM.HASH
    case 'deny':
      return POLICY_EFFECT_URL_ENUM.DENY
    default:
      return POLICY_EFFECT_URL_ENUM.DENY
  }
}
