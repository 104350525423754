import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetJurisdictionRightsResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

type Params = APIListRequestParams<{
  jurisdictionCode: string
  languageCode?: string
  order?: string
}>

export const fetchJurisdictionRights = ({ jurisdictionCode, languageCode, order }: Params) =>
  API.get<GetJurisdictionRightsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/jurisdictions/${jurisdictionCode}/rights`,
      params: {
        filters: {
          ...(languageCode && { languageCode }),
          ...(order && { order }),
        },
      },
    }),
  )
