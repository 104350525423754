import { formatRequestString } from 'utils/helpers/api/queryString'
import { API } from 'utils/api/api'
import { BusinessFunctionDTO, UpdateBusinessFunctionResponseBodyDTO } from '@ketch-com/figurehead'

type Params = {
  organizationCode: string
  formData: BusinessFunctionDTO
}

export const updateBusinessFunction = ({ organizationCode, formData }: Params) =>
  API.put<UpdateBusinessFunctionResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/business-functions/${formData.code}`,
    }),
    { data: { ...formData, organizationCode } },
  )
