import React, { useMemo } from 'react'
import { Formik } from 'formik'
import { compact } from 'lodash'

import { MaybeNull } from 'interfaces/common'
import { ObjectLiteral } from 'interfaces'
import { UserShortDTO } from 'interfaces/users/users'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useAuth } from 'utils/hooks/useAuth'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { Modal } from 'components/ui-kit/modal/Modal'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { Text } from 'components/ui-kit/typography/Text'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { Group } from 'components/ui-layouts/group/Group'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { WorkflowExecutionAssignee } from 'pages/orchestration/rightsQueue/components/WorkflowExecutionAssignee'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { Box } from '@mui/material'
import { Button } from '@ketch-com/deck'
import { getValidationSchema } from './utils'
import { WorkflowExecutionStepReassignFormValues } from '../reassignStep/utils'
import { WorkflowExecutionStepAttachments } from '../WorkflowExecutionStepAttachments'
import { WorkflowExecutionStepStatusRenderer } from '../WorkflowExecutionStepStatusRenderer'
import { WorkflowExecutionStepApproveFormValues } from './utils'

type Props = {
  isReady: boolean
  users: UserShortDTO[]
  filesInfo: FileInfoDTO[]
  approveStepDetails: MaybeNull<WorkflowExecutionStepDetailsDTO>
  stepToApproveDetails: MaybeNull<WorkflowExecutionStepDetailsDTO>
  onSubmit: (values?: WorkflowExecutionStepApproveFormValues, isReassign?: boolean) => Promise<void>
  onClose: () => void
  stepId: string
}

export const WorkflowExecutionStepApproveModal: React.FC<Props> = ({
  isReady,
  users,
  filesInfo,
  approveStepDetails,
  stepToApproveDetails,
  onSubmit,
  onClose,
  stepId,
}) => {
  const { userData } = useAuth()
  const { isPermitted } = useIsPermitted()

  const approvalStatus = approveStepDetails?.status
  const isCompleted = approvalStatus === WorkflowExecutionStepStatus.SUCCESS
  const isApproved = approveStepDetails?.results?.[stepId]?.decisionBoolean !== false
  const approvalAssigneeId = approveStepDetails?.assignee
  const isAssignedToMe = approvalAssigneeId === userData.userId
  const approvalAssignee = users.find(({ ID }) => ID === approvalAssigneeId)
  const approvalDetails = approveStepDetails?.activity?.params?.details

  const isApproveWorkflow = !!stepToApproveDetails?.workflow
  const taskStepId = stepToApproveDetails?.ID
  const taskDescription = stepToApproveDetails?.description || stepToApproveDetails?.name
  const taskDetails = stepToApproveDetails?.activity?.params?.details
  const taskAssigneeId = stepToApproveDetails?.assignee
  const taskResolutionNotes = stepToApproveDetails?.results?.[taskStepId!]?.resolutionDetails
  const taskAssignee = users.find(({ ID }) => ID === taskAssigneeId)
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const isPermittedToChangeTask = isPermitted(PERMISSIONS.WORKFLOW_TASK_ASSIGN)
  const initialValues: WorkflowExecutionStepReassignFormValues = useMemo(
    () => ({
      assigneeId: approvalAssigneeId || '',
    }),
    [approvalAssigneeId],
  )

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        const isAssigneeChanged = initialValues.assigneeId !== values.assigneeId
        const isAssignedBackToMe = isAssigneeChanged && values.assigneeId === approvalAssigneeId

        return onSubmit(values, isAssigneeChanged && !isAssignedBackToMe)
      }}
    >
      {({ submitForm, isValid, values, isSubmitting, setFieldValue, errors }) => {
        const buttonsProps: ObjectLiteral = {}
        const isAssigneeChanged = initialValues.assigneeId !== values.assigneeId
        const isAssignedBackToMe = isAssigneeChanged && values.assigneeId === approvalAssigneeId
        const selectedAssignee = users.find(({ ID }) => ID === values.assigneeId)

        const submitFormAsApproved = () => {
          setFieldValue('decisionBoolean', true)
          submitForm()
        }

        const submitFormAsDisapproved = () => {
          setFieldValue('decisionBoolean', false)
          submitForm()
        }

        if (isPermittedToChangeTask && !isCompleted) {
          if (!isAssignedToMe || (isAssigneeChanged && !isAssignedBackToMe)) {
            if (selectedAssignee && selectedAssignee.ID !== approvalAssigneeId) {
              buttonsProps.onSubmitBtnClick = submitForm
              buttonsProps.submitBtnLabel = 'Reassign'
            } else {
              buttonsProps.isValid = false
            }
          } else {
            buttonsProps.submitBtnLabel = 'Approve'
            buttonsProps.onSubmitBtnClick = submitFormAsApproved

            buttonsProps.tertiaryBtnLabel = 'Reject'
            buttonsProps.onTertiaryBtnClick = submitFormAsDisapproved
          }
        } else {
          buttonsProps.cancelBtnVariant = 'primary'
        }

        return (
          <Modal
            isLoading={!isReady}
            isValid={isValid}
            isSaving={isSubmitting}
            contentWidth={840}
            title={
              isApproveWorkflow ? (
                'Approve Workflow'
              ) : (
                <Group gap={16} alignItems="center">
                  Task Approval&nbsp;
                  <WorkflowExecutionStepStatusRenderer status={approvalStatus!} dueAt={approveStepDetails?.dueAt} />
                </Group>
              )
            }
            cancelBtnLabel="Close"
            onCancelBtnClick={onClose}
            {...buttonsProps}
          >
            {isApproveWorkflow ? (
              <Text size={14}>Confirm that the workflow has completed as expected.</Text>
            ) : (
              <Box mt={2.25}>
                {isCompleted && (
                  <Text sx={{ mb: 2.25 }} component="div" size={16} weight={700} letterSpacing="-1%">
                    {compact([approvalAssignee?.firstName, approvalAssignee?.lastName]).join(' ')}{' '}
                    {isApproved ? 'approved' : 'disapproved'} the request
                  </Text>
                )}
                <FormRow>
                  <Label>Approval Details</Label>
                  <TextCellRenderer value={approvalDetails} />
                </FormRow>

                <FormRow>
                  <Label>Activity {isCompleted ? (isApproved ? 'Approved' : 'Disapproved') : 'Approving'}</Label>
                  <TextCellRenderer value={taskDescription} />
                </FormRow>

                {taskDetails && (
                  <FormRow>
                    <Label>Task Details</Label>
                    <TextCellRenderer value={taskDetails} />
                  </FormRow>
                )}

                <FormRow>
                  <Group gap={120} alignItems="flex-start">
                    <div>
                      <Label>Task Assignee</Label>
                      {taskAssignee ? <WorkflowExecutionAssignee assignee={taskAssignee} /> : <EmptyValueRenderer />}
                    </div>

                    {isPermittedToChangeTask && !isCompleted ? (
                      <Box display="flex" flexDirection="column">
                        <FormDropdown
                          label="Approval Assignee"
                          placeholder="Select Assignee"
                          name="assigneeId"
                          valueKey="ID"
                          items={users}
                          renderItem={user => <WorkflowExecutionAssignee assignee={user} />}
                        />
                        <Button
                          sx={{
                            marginTop: 1,
                            maxWidth: '120px',
                          }}
                          color="secondary"
                          onClick={() => setFieldValue('assigneeId', userData?.userId)}
                        >
                          Assign to me
                        </Button>
                      </Box>
                    ) : (
                      <div>
                        <Label>Approval Assignee</Label>

                        {approvalAssignee ? (
                          <WorkflowExecutionAssignee assignee={approvalAssignee} />
                        ) : (
                          <EmptyValueRenderer />
                        )}
                      </div>
                    )}
                  </Group>
                </FormRow>

                <FormRow>
                  <Label>Notes</Label>
                  <TextCellRenderer value={taskResolutionNotes} />
                </FormRow>

                <FormRow>
                  <Label>Attachments</Label>

                  {filesInfo.length ? (
                    <WorkflowExecutionStepAttachments attachments={filesInfo} />
                  ) : (
                    <EmptyValueRenderer />
                  )}
                </FormRow>
              </Box>
            )}
          </Modal>
        )
      }}
    </Formik>
  )
}
