import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Button, Chip, TableCell } from '@ketch-com/deck'

import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { ApplicationEnvironmentDTO } from 'interfaces/applications/application'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
export interface RowIndexedWorkflowExecutionStepDTO extends WorkflowExecutionStepDTO {
  rowIndex: number
}

export const experienceDeploymentsColumns = ({
  deploymentPlans,
}: {
  deploymentPlans: DeploymentPlanDTO[]
}): GridColDef<ApplicationEnvironmentDTO>[] => {
  return [
    {
      align: 'left',
      field: 'property',
      headerAlign: 'left',
      headerName: 'Property',
      sortable: false,
      flex: 1,
      renderCell: ({ row: { property } }: GridRenderCellParams<ApplicationEnvironmentDTO>) => (
        <TableCell title={property?.name} subTitle={property?.code} />
      ),
    },
    {
      align: 'left',
      field: 'type',
      headerAlign: 'left',
      headerName: 'Type',
      sortable: false,
      flex: 1,
      renderCell: ({ row: { property } }: GridRenderCellParams<ApplicationEnvironmentDTO>) => (
        <TableCell>
          <Chip size="small" label={property?.type ?? 'N/A'} />
        </TableCell>
      ),
    },
    {
      align: 'left',
      field: 'environment',
      headerAlign: 'left',
      headerName: 'Environment',
      sortable: false,
      flex: 1,
      renderCell: ({ row: { code } }: GridRenderCellParams<ApplicationEnvironmentDTO>) => <TableCell title={code} />,
    },
    {
      align: 'left',
      field: 'pattern',
      headerAlign: 'left',
      headerName: 'Pattern',
      sortable: false,
      flex: 1,
      renderCell: ({ row: { pattern } }: GridRenderCellParams<ApplicationEnvironmentDTO>) => (
        <TableCell title={pattern} />
      ),
    },
    {
      align: 'left',
      field: 'deploymentPlan',
      headerAlign: 'left',
      headerName: 'Deployment Plan',
      sortable: false,
      flex: 1,
      renderCell: ({ row: { deployment } }: GridRenderCellParams<ApplicationEnvironmentDTO>) => {
        if (deployment) {
          return <TableCell title={deployment.name} subTitle={deployment?.code} />
        }

        return <EmptyValueRenderer />
      },
    },
    {
      align: 'left',
      field: 'viewButton',
      headerAlign: 'left',
      headerName: ' ',
      sortable: false,
      flex: 1,
      maxWidth: 100,
      renderCell: ({ row }: GridRenderCellParams<ApplicationEnvironmentDTO>) => {
        return <ActionButton deploymentPlans={deploymentPlans} row={row} />
      },
    },
  ]
}

const ActionButton = ({
  deploymentPlans,
  row,
}: {
  deploymentPlans: DeploymentPlanDTO[]
  row: ApplicationEnvironmentDTO
}) => {
  const { deployment } = row
  const navigate = useNavigate()
  // Get code for this deployment plan
  const code = deploymentPlans.find(deploymentPlan => deploymentPlan.ID === deployment?.ID)?.code

  return (
    <TableCell>
      <Button
        disabled={!deployment || !code}
        color="tertiary"
        onClick={() => {
          navigate(RoutesManager.deployment.deploymentPlansV2.view.root.getURL({ code }))
        }}
      >
        View
      </Button>
    </TableCell>
  )
}
