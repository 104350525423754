import Box from '@mui/material/Box'
import React from 'react'
import { Button } from '@ketch-com/deck'

import { Dropdown } from 'components/ui-kit/dropdown/Dropdown'
import { useFormikContext } from 'formik'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'

import { truncateStringWithEllipse } from 'utils/formatters'
import { TooltipV2 } from 'components/ui-kit/tooltip/TooltipV2'
import { useJurisdictionsRights } from 'api/rights/queries/useJurisdictionsRights'

type Props = {
  push: (obj: any) => void
  setSelectedRightCodeForAddition: React.Dispatch<React.SetStateAction<string>>
  selectedRightCodeForAddition: string
}

export const AddRightRow: React.FC<Props> = ({
  push,
  setSelectedRightCodeForAddition,
  selectedRightCodeForAddition,
}) => {
  const {
    values: {
      rights: { customRightFormTemplates: formikFormRights },
    },
  } = useFormikContext<PreferenceExperienceFormValues>()

  const { data: customRights } = useJurisdictionsRights()

  const selectedRight = customRights.find(cv => cv.code === selectedRightCodeForAddition)

  const formRightItems = customRights
    .filter(cv => !formikFormRights.find(pdv => pdv.code === cv.code))
    .map(cv => ({
      active: selectedRightCodeForAddition === cv.code,
      content: cv.name,
      value: cv.code,
      onClick: () => {
        setSelectedRightCodeForAddition(cv?.code || '')
      },
    }))

  return (
    <TooltipV2 arrow title={formRightItems.length ? '' : 'No more fields to add'}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1.5}
        sx={{
          backgroundColor: 'sphereFaded.main',
          borderRadius: 2.75, // 11px
        }}
      >
        {/* Left */}
        <Box display="flex" alignItems="center">
          <Dropdown
            size="small"
            placeholder="Select Right"
            value={selectedRightCodeForAddition}
            items={formRightItems}
            disabled={!formRightItems.length}
          >
            <span title={selectedRight?.name}>{truncateStringWithEllipse(selectedRight?.name, 40)}</span>
          </Dropdown>
        </Box>

        {/* Right */}
        <Button
          disabled={!selectedRight}
          color="secondary"
          onClick={() => {
            if (selectedRight) {
              push({ ...selectedRight, formTemplateID: '', rightCode: selectedRight?.code })
              setSelectedRightCodeForAddition('')
            }
          }}
        >
          Add Right
        </Button>
      </Box>
    </TooltipV2>
  )
}
