import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { ConnectionV2DTO } from '@ketch-com/figurehead'
import { fetchConnections } from '../fetchers/fetchConnections'

const limit = 25

export const useConnectionsInfinite = ({
  installedDataSystemId,
  q,
  capabilities,
}: {
  installedDataSystemId?: string
  q?: string
  capabilities?: string[]
}) => {
  const resp = useInfiniteQuery({
    queryKey: [ApiQueryKeys.connections, { q, capabilities, installedDataSystemId }],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchConnectionsResult = await fetchConnections({
        limit,
        start: (pageParam - 1) * limit,
        installedDataSystemId,
        q,
        capabilities,
      })

      return {
        connections: fetchConnectionsResult.data.connections ?? [],
        totalResults: fetchConnectionsResult.data.totalResults ?? 0,
      }
    },
    getNextPageParam: ({ totalResults }, allPages) => {
      let totalItemsFetched = 0
      allPages?.forEach?.(pageOfResults => {
        totalItemsFetched += pageOfResults?.connections?.length || 0
      })
      const nextPageParam = Math.ceil(totalItemsFetched / limit) + 1

      return totalItemsFetched === totalResults ? undefined : nextPageParam
    },
  })

  let connectionsInfinite: ConnectionV2DTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    const newConnections = page?.connections
    connectionsInfinite = [...connectionsInfinite, ...newConnections]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: connectionsInfinite,
    totalResults,
  }
}
