import * as Yup from 'yup'

export interface Values {
  firstValue: string
  secondValue: string
  thirdValue: string
  fourthValue: string
  fifthValue: string
}

export const initialValues: Values = {
  firstValue: '',
  secondValue: '',
  thirdValue: '',
  fourthValue: '',
  fifthValue: '',
}

export const formStepConfigs = [
  {
    title: 'Step 1',
    validationSchema: Yup.object().shape({
      firstValue: Yup.string().required(`First step value validation`),
    }),
  },
  {
    title: 'Step 2',
    validationSchema: Yup.object().shape({
      secondValue: Yup.string().required(`Second step validation`),
    }),
  },
  {
    title: 'Step 3',
    validationSchema: Yup.object().shape({
      thirdValue: Yup.string().required(`Third step validation`),
    }),
  },
  {
    title: 'Step 4',
    validationSchema: Yup.object().shape({
      fourthValue: Yup.string().required(`Fourth step validation`),
    }),
  },
  {
    title: 'Step 5',
    validationSchema: Yup.object().shape({
      fifthValue: Yup.string().required(`Fifth step validation`),
    }),
  },
]
