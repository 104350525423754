import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { DatasetScenarioContainer, DatabaseScenarioContainer } from 'pages/assetManager/scenario/list/components'

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export const ScenarioList: React.FC<Props> = ({ assetSummary }) => {
  const resourceTypeCode = assetSummary?.asset?.resource?.resourceType?.code

  if (resourceTypeCode?.includes('.database')) return <DatabaseScenarioContainer />
  return <DatasetScenarioContainer assetSummary={assetSummary} />
}
