import React from 'react'
import { useFormikContext } from 'formik'
import { CookieFormDTO } from 'interfaces/cookies/cookie'
import { useApplications } from 'api/applications/queries/useApplications'
import { DataGrid, FormRow } from '@ketch-com/deck'
import { cookieApplicationsGridColumns } from './utils/cookieApplicationsDataGridUtils'

type Props = {
  isKetchCookie: boolean
}

export const CookieApplicationsFormSection: React.FC<Props> = () => {
  const { values } = useFormikContext<CookieFormDTO>()
  const { data: applications, isFetching, isLoading } = useApplications()

  if (!values.purposes?.length) {
    return null
  }

  return (
    <FormRow title="Properties" hasTopBorder>
      <DataGrid
        sx={{
          padding: 'unset',
        }}
        autosizeOnMount
        columns={cookieApplicationsGridColumns}
        disableChildrenSorting
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        getRowHeight={() => 'auto'}
        getRowId={row => row.ID}
        hideFooter
        loading={isLoading || isFetching}
        rows={applications}
      />
    </FormRow>
  )
}
