import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, TooltipButton } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { CookieDTO } from 'interfaces/cookies/cookie'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'

type Props = {
  isLatestVersion: boolean
  isInSensitiveStatus: boolean
  cookie: CookieDTO
  onRemoveClick: () => void
  onEditClick: () => void
}

export const CookieViewActions: React.FC<Props> = props => {
  const navigate = useNavigate()

  const { isPermitted } = useIsPermitted()
  const isPermittedToCreateCookies = isPermitted([PERMISSIONS.PURPOSE_WRITE])

  const { isLatestVersion, isInSensitiveStatus, cookie, onRemoveClick, onEditClick } = props
  const upsertLink = RoutesManager.policyCenter.cookies.upsert.root.getURL({ code: cookie?.code })

  const onEditButtonClick = () => {
    if (isInSensitiveStatus) {
      onEditClick()
    } else {
      navigate(upsertLink)
    }
  }

  if (!isLatestVersion) {
    return null
  }

  return (
    <Box display="flex" gap="12px" alignItems="center">
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="smallBody" color="darkDuskFaded.main">
          Last Updated
        </Typography>
        {cookie.metadata?.createdAt ? (
          <Typography color="darkDusk.main" variant="smallBody">
            {formatDateTimeFromUnix(cookie.metadata?.createdAt)}
          </Typography>
        ) : (
          <Typography color="lightGrey.main" variant="smallBody">
            None
          </Typography>
        )}
      </Box>

      {isPermittedToCreateCookies && (
        <TooltipButton
          size="large"
          color="tertiary"
          disabled={isInSensitiveStatus}
          title={isInSensitiveStatus ? 'Cannot delete cookies while mapped' : undefined}
          onClick={onRemoveClick}
        >
          Delete
        </TooltipButton>
      )}

      {isPermittedToCreateCookies && (
        <Button onClick={onEditButtonClick} color="secondary" size="large">
          Edit
        </Button>
      )}
    </Box>
  )
}
