import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { deleteFormField } from '../fetchers/deleteFormField'

const useCustomMutation = createUseMutation({
  mutationFn: deleteFormField,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useDeleteFormField = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
