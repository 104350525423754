import { PutAuthenticatorRequestBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  formData: PutAuthenticatorRequestBodyDTO
  authenticatorId?: string
}

type Response = {}

export const updateAuthenticator = ({ formData, authenticatorId }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/authenticators/${authenticatorId}`,
    }),
    formData,
  )
