import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'

import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { ThemeStatus } from 'interfaces/themes-v2/themeStatus'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Group } from 'components/ui-layouts/group/Group'
import { Button } from 'components/ui-kit/button/Button'
import { Text } from 'components/ui-kit/typography/Text'
import { DeleteThemeModal } from 'components/modals/themes/DeleteThemeModal'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'

type Props = {
  theme: ThemeDTO
}

export const ThemeViewActions: React.FC<Props> = ({ theme }) => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()

  const [{ isEditConfirmationOpen, isDeleteOpen }, setState] = useSetState({
    isEditConfirmationOpen: false,
    isDeleteOpen: false,
  })

  const { code, status, metadata } = theme
  const isPublished = status === ThemeStatus.PUBLISHED
  const isPermittedToManageThemes = isPermitted(PERMISSIONS.THEME_WRITE)

  const handleRedirectToUpsertTheme = () => {
    navigate(RoutesManager.deployment.themes.upsert.root.getURL({ code }))
  }

  return (
    <>
      <Group>
        <Group gap={8}>
          <Text size={12} color="grey" weight={500}>
            Last Updated
          </Text>
          <Text size={12} weight={500}>
            <FormattedDateRenderer date={metadata?.updatedAt} />
          </Text>
        </Group>

        <Button
          size="big"
          variant="tertiary"
          onClick={() => {
            navigate(RoutesManager.deployment.themes.preview.root.getURL({ code }))
          }}
        >
          Preview
        </Button>

        {isPermittedToManageThemes && (
          <>
            <Button
              disabled={isPublished}
              tooltip={isPublished ? 'Cannot delete themes while deployed' : null}
              size="big"
              variant="tertiary"
              onClick={() => {
                setState({ isDeleteOpen: true })
              }}
            >
              Delete
            </Button>

            <Button
              onClick={() => {
                if (isPublished) {
                  setState({ isEditConfirmationOpen: true })
                } else {
                  handleRedirectToUpsertTheme()
                }
              }}
              variant="secondary"
              size="big"
            >
              Edit
            </Button>
          </>
        )}
      </Group>

      {isDeleteOpen && (
        <DeleteThemeModal
          themeCode={code}
          onSubmit={() => {
            navigate(RoutesManager.deployment.themes.root.getURL())
          }}
          onCancel={() => {
            setState({ isDeleteOpen: false })
          }}
        />
      )}

      {isEditConfirmationOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.THEME}
          onSubmit={handleRedirectToUpsertTheme}
          onCancel={() => {
            setState({ isEditConfirmationOpen: false })
          }}
        />
      )}
    </>
  )
}
