import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { CreatePatchDSRConfigsRequestBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  data: CreatePatchDSRConfigsRequestBodyDTO
  type: string
  isV2FeatureFlagEnabled?: boolean
}>

export const upsertDsrConfiguration = ({ data, type, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/dsr-configs`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/dsr-configs`

  if (type === 'create')
    return API.post<{}>(
      formatRequestString({
        entityUrl,
      }),
      data,
    )

  return API.put<{}>(
    formatRequestString({
      entityUrl,
    }),
    data,
  )
}
