import { useContext } from 'react'
import { BuilderContext } from '../../Builder'
import { PreferenceExperienceBuilderSection, PreferenceThemeBuilderSection } from '../../utils/enums'
import { Box, styled } from '@mui/material'
import { SelectableSection } from '../SelectableSection'
import { PreferenceHeader } from './components/PreferenceHeader'
import { PreferenceNavigation } from './components/PreferenceNavigation'
import { ExitButtonPosition, PreferenceCenterPage } from '@ketch-sdk/ketch-types'
import { WelcomeTab } from './components/tabs/welcome/WelcomeTab'
import { PrivacyPolicyTab } from './components/tabs/privacyPolicy/PrivacyPolicyTab'
import { SubscriptionsTab } from './components/tabs/subscriptions/SubscriptionsTab'
import { PurposesTab } from './components/tabs/purposes/PurposesTab'
import { PreferenceExitButton } from './components/PreferenceExitButton'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { getIn } from 'formik'
import { KetchBadge } from '../KetchBadge'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { RequestsHomeTab } from './components/tabs/requests/home/RequestsHomeTab'
import { RequestsFormTab } from './components/tabs/requests/form/RequestsFormTab'
import { RequestsSubmittedTab } from './components/tabs/requests/submitted/RequestsSubmittedTab'
import { GpcBadge } from '../GpcBadge'
import { SectionEmptyState } from '../SectionEmptyState'

const preferenceTabs = {
  [PreferenceCenterPage.Welcome]: <WelcomeTab />,
  [PreferenceCenterPage.PrivacyPolicy]: <PrivacyPolicyTab />,
  [PreferenceCenterPage.Purpose]: <PurposesTab />,
  [PreferenceCenterPage.Subscriptions]: <SubscriptionsTab />,
  [PreferenceCenterPage.RequestsHome]: <RequestsHomeTab />,
  [PreferenceCenterPage.RequestsForm]: <RequestsFormTab />,
  [PreferenceCenterPage.RequestsSubmitted]: <RequestsSubmittedTab />,
}

const PreferenceBuilderBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '312px 1fr',
  width: '1024px',
  columnGap: '48px',
  rowGap: '32px',
  marginTop: '12px',
  zIndex: 1,
}))

const PreferenceBuilderHeaderBox = styled(Box)(({ theme }) => ({
  gridColumn: '1/3',
}))

const PreferenceBuilderLeftColumnBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '0px 0px 32px 32px',
}))

const PreferenceBuilderRightColumnBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gridColumn: '2/3',
  minHeight: '927px',
  width: '592px',
  padding: '0px 0px 32px 0px',
}))

export const PreferenceBuilder: React.FC = () => {
  const { themeConfig, experienceConfig, isTheme, preferencePage } = useContext(BuilderContext)

  // Theme config
  const backgroundColor = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabContainerBackgroundColor)
  const showKetchBadge = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabFooterShowKetchBadge)
  const headerLogoUrl = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabHeaderLogoUrl)
  const exitButtonPosition = getIn(themeConfig, themeUpsertFormKeys.preferenceWelcomeTabContainerExitButtonPosition)
  const showExitButton = exitButtonPosition === ExitButtonPosition.bottomLeft

  // Experience config
  const showGpcBadge = getIn(experienceConfig, experienceUpsertFormKeys.preferenceWelcomeTabFooterShowGpcBadge)

  return (
    <SelectableSection
      section={isTheme ? PreferenceThemeBuilderSection.WelcomeTabContainer : undefined}
      overlayProps={{ margin: '30px 18px 18px 18px' }}
    >
      <PreferenceBuilderBox sx={{ background: backgroundColor }}>
        {/* Header */}
        <PreferenceBuilderHeaderBox gridColumn={headerLogoUrl ? '1/2' : '1/3'}>
          <SelectableSection
            section={
              isTheme
                ? PreferenceThemeBuilderSection.WelcomeTabHeader
                : PreferenceExperienceBuilderSection.WelcomeTabHeader
            }
            overlayProps={{ margin: '18px' }}
          >
            <PreferenceHeader />
          </SelectableSection>
        </PreferenceBuilderHeaderBox>

        <PreferenceBuilderLeftColumnBox>
          <SelectableSection
            section={
              isTheme
                ? PreferenceThemeBuilderSection.WelcomeTabNavigation
                : PreferenceExperienceBuilderSection.WelcomeTabNavigation
            }
          >
            <PreferenceNavigation />
          </SelectableSection>

          <Box>
            {/* Exit button bottom left position */}
            {showExitButton && (
              <SelectableSection
                section={
                  isTheme
                    ? PreferenceThemeBuilderSection.WelcomeTabExitButton
                    : PreferenceExperienceBuilderSection.WelcomeTabExitButton
                }
              >
                <PreferenceExitButton variant={ExitButtonPosition.bottomLeft} />
              </SelectableSection>
            )}

            {/* Ketch and GPC badges */}
            <SelectableSection
              section={
                isTheme ? PreferenceThemeBuilderSection.WelcomeTabKetchBadge : undefined // PreferenceExperienceBuilderSection.WelcomeTabGpcBadge - TODO: Descoped
              }
            >
              <Box display={'flex'} alignItems={'center'} height={'30px'} gap={'24px'}>
                {showKetchBadge || showGpcBadge ? (
                  <>
                    {showKetchBadge && <KetchBadge contrastBackgroundColor={backgroundColor} />}
                    {showGpcBadge && <GpcBadge contrastBackgroundColor={backgroundColor} />}
                  </>
                ) : (
                  <SectionEmptyState section="Footer" />
                )}
              </Box>
            </SelectableSection>
          </Box>
        </PreferenceBuilderLeftColumnBox>
        <PreferenceBuilderRightColumnBox>
          {preferenceTabs[preferencePage || PreferenceCenterPage.Welcome]}
        </PreferenceBuilderRightColumnBox>
      </PreferenceBuilderBox>
    </SelectableSection>
  )
}
