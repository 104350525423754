import React from 'react'

import { ExperienceDTO } from 'interfaces/experiences/experience'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { SectionTitle } from 'components/ui-kit/typography/compositions/SectionTitle'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { Table } from 'components/ui-layouts/table/Table'
import { Status } from 'components/ui-kit/status/Status'
import { Badge } from 'components/ui-kit/badge/Badge'
import { Button } from '@ketch-com/deck'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'

type Props = {
  experience: ExperienceDTO
  versions: ExperienceDTO[]
}

export const ExperienceVersions: React.FC<Props> = ({ experience, versions }) => {
  const { queries, setQueryParam, removeQueryParam } = useQueryParams<{ version?: string }>()
  const currentVersion = queries.version ? +queries.version : experience.version

  return (
    <>
      <SectionTitle>Version History</SectionTitle>

      {versions.length ? (
        <Table
          items={versions}
          cellSettings={{
            status: {
              label: 'Status',
              width: 110,
              cellRenderer: ({ latest }) => (
                <>
                  {!!latest && (
                    <Status variant="active" size="regular">
                      Latest
                    </Status>
                  )}
                </>
              ),
            },
            version: {
              label: 'Version',
              width: 200,
              cellRenderer: ({ version }) => <Badge variant="outline">v{version}</Badge>,
            },
            experience: {
              label: 'Experience',
              cellRenderer: ({ name, code }) => <NameAndCodeCellRenderer name={name} code={code} />,
            },
            updatedAt: {
              label: 'Last Updated',
              width: 225,
              cellRenderer: ({ metadata }) => <FormattedDateRenderer date={metadata?.createdAt} />,
            },
            actions: {
              width: 100,
              cellRenderer: ({ latest, version }) => (
                <Button
                  disabled={version === currentVersion}
                  color="tertiary"
                  onClick={() => {
                    if (latest) {
                      removeQueryParam('version')
                    } else {
                      setQueryParam('version', version)
                    }
                  }}
                >
                  View
                </Button>
              ),
            },
          }}
        />
      ) : (
        <EmptyState
          bgVariant="grey"
          variant="page"
          title="No versions exist for the selected experience"
          description="As you make changes to this experience, you can view that history here"
        />
      )}
    </>
  )
}
