import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { useUsers } from 'api/users/queries/useUsers'

export const useEditAttributeFormUtils = () => {
  const { data: users, isLoading: isUsersLoading } = useUsers({
    params: {
      active: true,
    },
  })

  const { data: identitySpaces, isLoading: isLoadingIdentitySpaces } = useIdentitySpaces()

  return {
    users,
    identitySpaceOptions: (identitySpaces || []).map(({ code, name }) => ({
      id: code,
      name,
    })),
    isLoading: isUsersLoading || isLoadingIdentitySpaces,
  }
}
