import { DataReviewFileDTO, UploadDataReviewFilesRequestBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export type FormFile = DataReviewFileDTO & {
  rawData?: string | undefined
  data?: string | Blob
}

type Params = {
  executionId?: string
  files?: FormFile[]
  requestId?: string
}

export const uploadDataReviewFiles = ({ executionId, files = [], requestId }: Params) => {
  const formData = new FormData()
  files?.map(file => {
    // @ts-ignore
    return formData.append('files', file?.data || '', file.name)
  })

  return API.post<UploadDataReviewFilesRequestBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/data-review/${requestId}/${executionId}/files`,
    }),
    formData,
  )
}
