import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { postAllNotificationsAsRead } from '../fetchers/postAllNotificationsAsRead'

const useCustomMutation = createUseMutation({
  mutationFn: postAllNotificationsAsRead,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useMarkAllNotificationsRead = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    ...config,
  })
}
