import { JurisdictionCanonicalRightDTO } from '@ketch-com/figurehead'
import { ExperienceCanonicalRightFormTemplate } from 'interfaces/experiences/experience'

export const transformJurisdictionCanonicalRightsToExperienceCanonicalRightFormTemplates = (
  jurisdictionCanonicalRights: JurisdictionCanonicalRightDTO[],
): ExperienceCanonicalRightFormTemplate[] => {
  return jurisdictionCanonicalRights.map(jur => ({
    code: jur.code || '',
    name: jur.name || '',
    rights: jur.rights || [],
    experienceID: '',
    canonicalRightCode: '',
    formTemplateID: '',
    id: '',
  }))
}
