import React, { Fragment, useState } from 'react'
import { useQueryClient } from 'react-query'
import { DeleteOutline, EditOutlined, VisibilityOutlined } from '@mui/icons-material'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { DeleteApiKeyModal } from 'components/modals/apiKeys/DeleteApiKeyModal'

import { createStyles, makeStyles } from '@mui/styles'
import { DmlHookDTO } from '@ketch-com/figurehead'
import { HookOverviewModal } from '../../components'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      buttonOptions: {
        backgroundColor: palette.white.main,
      },
    }),
  { name: 'HooksActionsCellRenderer' },
)

type Props = {
  hook: DmlHookDTO
  setHookToDeleteId: (value: React.SetStateAction<string>) => void
  setHookToEditId: (value: React.SetStateAction<string>) => void
}

export const HooksActionsCellRenderer: React.FC<Props> = ({ hook, setHookToDeleteId, setHookToEditId }) => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isBusy] = useState(false)
  const canHookBeDeleted = (hook?.assignmentCount || 0) === 0
  const [isHookOverviewModalOpen, setIsHookOverviewModalOpen] = useState(false)

  const menuItems = [
    {
      content: 'Edit',
      onClick: () => setHookToEditId(hook?.id || ''),
      icon: <EditOutlined />,
      hidden: !isPermitted(PERMISSIONS.HOOK_WRITE),
    },
    {
      content: 'Delete',
      onClick: () => setHookToDeleteId(hook?.id || ''),
      icon: <DeleteOutline />,
      hidden: !isPermitted(PERMISSIONS.HOOK_WRITE) || !canHookBeDeleted,
    },
    {
      content: 'View',
      icon: <VisibilityOutlined />,
      onClick: () => setIsHookOverviewModalOpen(true),
    },
  ]

  return (
    <Fragment>
      <ButtonOptions
        pending={isBusy}
        items={menuItems}
        className={classes.buttonOptions}
        disabled={!isPermitted(PERMISSIONS.HOOK_WRITE)}
      />

      {isDeleteModalOpen && (
        <DeleteApiKeyModal
          apiKeyId={hook?.id || ''}
          onCancel={() => setIsDeleteModalOpen(false)}
          onSubmit={async () => {
            await queryClient.refetchQueries(ApiQueryKeys.apiKeysPaginated)

            setIsDeleteModalOpen(false)
          }}
        />
      )}

      {isHookOverviewModalOpen ? (
        <HookOverviewModal hook={hook} onCancel={() => setIsHookOverviewModalOpen(false)} />
      ) : null}
    </Fragment>
  )
}
