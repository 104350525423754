import { ApiQueryKeys } from '../../common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from '../../common/paginatedQuery'
import { fetchProcessingActivities } from '../fetchers/fetchProcessingActivities'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.processingActivitiesPaginated,
  queryFn: fetchProcessingActivities,
  select: res => res?.data?.processingActivities || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useProcessingActivitiesPaginated = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    itemsPerPage: config?.itemsPerPage || 10,
    params: {
      ...config?.params,
    },
  })
}
