import { Text } from 'components/ui-kit/typography/Text'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { RelatedPath, DsrConfigFormData } from 'pages/assetManager/dsrConfig/interfaces'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { getRelatedDataset, getPrimaryDataset } from 'pages/assetManager/dsrConfig/utils'
import { useFormikContext } from 'formik'
import { Chip } from 'components/ui-kit/chip/Chip'
import { ReactComponent as PersonAvatarSmall } from 'assets/icons/person-avatar-small.svg'
import clsx from 'clsx'
import { AssetTypeIcon } from 'pages/assetManager/components'
import { getAssetType } from 'pages/assetManager/utils'

type Props = {
  relatedPaths: RelatedPath[]
  pathHashId: string
  onCancel: () => void
}

const PREFIX = 'ViewMoreModal'

const classes = {
  fkRelationship: `${PREFIX}-fkRelationship`,
  pkRelationship: `${PREFIX}-pkRelationship`,
  lastRelationshipBlock: `${PREFIX}-lastRelationshipBlock`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`& .${classes.fkRelationship}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: palette.darkChrome.main,
    border: `1px solid ${palette.iron.main}`,
    borderRadius: 5,
    width: 32,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.pkRelationship}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: palette.darkViolet.main,
    backgroundColor: 'rgba(156, 100, 255, 0.25)',
    border: `1px solid rgba(156, 100, 255, 0.25)`,
    borderRadius: 5,
    width: 32,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.lastRelationshipBlock}`]: {
    border: `1px solid ${palette.iron.main}`,
    borderRadius: 11,
    padding: 12,
    marginLeft: -12,
  },
}))

export const ViewMoreModal: React.FC<Props> = ({ onCancel, relatedPaths, pathHashId }) => {
  const { values } = useFormikContext<DsrConfigFormData>()
  const { identitySpace = {} } = values
  const { datasetName: relatedDataSetName } = getRelatedDataset(relatedPaths[0])
  const { datasetName: primaryDatasetName, providerCode } = getPrimaryDataset(relatedPaths[0])
  const datasetType = getAssetType(providerCode, 2).toLowerCase()
  const subTitle = `Learn how users will be identified in the "${relatedDataSetName}" ${datasetType}.`
  const [relatedPath] = relatedPaths.filter(relatedPath => relatedPath.hashID === pathHashId)

  const relatedDataPathElement = relatedPath.path.map((pathValue, pathIndex) => {
    let isLastRelationshipAsset
    const relatedAssetsNames: JSX.Element[][] = [[], []]
    pathValue.relationships?.forEach?.((relatedAssetsList, relationshipsIndex) => {
      relatedAssetsList?.forEach?.((relatedAssets, relatedAssetsListIndex) => {
        isLastRelationshipAsset =
          pathIndex === relatedPath.path.length - 1 && relatedAssetsListIndex === relatedAssetsList.length - 1
        let datasetInfo = (
          <Text key={relationshipsIndex}>{`${relatedAssets.datasetName}.${relatedAssets.attributeName}`}</Text>
        )
        if (isLastRelationshipAsset)
          datasetInfo = (
            <Text key={relationshipsIndex}>
              <strong>{relatedAssets.datasetName}</strong>.{relatedAssets.attributeName}
            </Text>
          )

        relatedAssetsNames[relatedAssetsListIndex].push(datasetInfo)
      })
    })

    const relatedAssets = pathValue.relationships[0]
    const pathList = [
      <Box key={relatedAssets[0].relationshipId} display="flex" flexDirection="column" gap={0.5} mb={0.5}>
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            className={clsx({
              [classes.pkRelationship]: relatedAssets[0].key === 'PK',
              [classes.fkRelationship]: relatedAssets[0].key === 'FK',
            })}
          >
            {relatedAssets[0].key}
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            {relatedAssetsNames[0]}
          </Box>
        </Box>
        <KeyboardArrowDownIcon
          sx={{
            width: 16,
            height: 16,
            color: 'rgba(7, 26, 36, 0.5)',
            ml: 1,
          }}
        />
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          className={clsx({
            [classes.lastRelationshipBlock]: isLastRelationshipAsset,
          })}
        >
          <Box
            className={clsx({
              [classes.pkRelationship]: relatedAssets[1].key === 'PK',
              [classes.fkRelationship]: relatedAssets[1].key === 'FK',
            })}
          >
            {relatedAssets[1].key}
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            {relatedAssetsNames[1]}
          </Box>
        </Box>
      </Box>,
    ]

    return <StyledBox key={pathIndex}>{pathList}</StyledBox>
  })

  const identitySpaceElement: JSX.Element[] = []

  for (const [identitySpaceId, identitySpaceValue] of Object.entries(identitySpace)) {
    const element = (
      <Box key={identitySpaceId} display="flex" alignItems="center" gap={1.5}>
        <Text>{`${primaryDatasetName}.${identitySpaceValue.attributeName}`}</Text>
        <Chip size="small">
          <Box display="flex" alignItems="center" gap={1}>
            <PersonAvatarSmall />
            <Box>
              <strong>Identity:</strong> {identitySpaceValue.identitySpaceName}
            </Box>
          </Box>
        </Chip>
      </Box>
    )

    identitySpaceElement.push(element)
  }
  return (
    <Dialog
      title="Relation Details"
      subtitle={subTitle}
      contentWidth={600}
      cancelBtnLabel="Close"
      onCancelBtnClick={onCancel}
      withTitleBorderBottom={true}
    >
      <Box ml={2}>
        {identitySpaceElement.length ? (
          <Box display="flex" alignItems="center" gap={1} mb={1.5}>
            <Box width={32} height={24} display="flex" justifyContent="center" alignItems="center">
              <AssetTypeIcon assetType={3} className="" />
            </Box>

            <Box display="flex" flexDirection="column" gap={1}>
              {identitySpaceElement}
            </Box>
          </Box>
        ) : null}
        {relatedDataPathElement}
      </Box>
    </Dialog>
  )
}
