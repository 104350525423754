import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import { useFormikContext } from 'formik'

import { ThemeFormValues } from 'pages/consentAndRights/themes/upsert/utils'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { Text } from 'components/ui-kit/typography/Text'
import { FormColorInput } from 'components/ui-kit/form/colorInput/FormColorInput'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { Flex } from 'components/ui-layouts/flex/Flex'
import Grid from '@mui/material/Grid'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { FormCheckbox } from 'components/ui-kit/form/checkbox/FormCheckbox'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { Input } from 'components/ui-kit/input/Input'
import { DefaultLabel } from './DefaultLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Switch } from 'components/ui-kit/switch/Switch'
import FormGroup from '@mui/material/FormGroup'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      root: {
        position: 'absolute',
        top: 13,
        right: 24,
        bottom: 13,
        left: 24,
      },
      header: {
        display: 'block',
        marginBottom: 18,
        padding: '13px 0 26px 0',
        borderBottom: `1px solid ${palette.iron.main}`,
      },
      subHeader: {
        display: 'block',
        color: palette.darkDuskFaded.main,
      },
      content: {
        flex: 1,
        overflowY: 'auto',
      },
    }),
  { name: 'FormView' },
)

type Props = {}

export const FormView: React.FC<Props> = () => {
  const classes = useStyles()
  const { isEntitled } = useIsEntitled()
  const isEntitledToUnbranded = isEntitled(ENTITLEMENTS.EXP_SERVER_UNBRANDED)
  const { values, setFieldValue } = useFormikContext<ThemeFormValues>()

  return (
    <>
      <Flex flexDirection="column" className={classes.root}>
        <Text className={classes.header} size={20} weight={700} lineHeight={1.4}>
          Form Customization
        </Text>

        <div className={classes.content}>
          <Text className={classes.subHeader} size={16} weight={800} lineHeight={1.12} mb={2}>
            General
          </Text>

          {/* Typeface and Corner Radius Inputs */}

          <FormRow marginBottom={24}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormDropdown fullWidth label="Typeface" disabled name="font" items={[]} placeholder="Arial" />
              </Grid>
              <Grid item xs={6}>
                <FormInput required label="Buttons Corner Radius" name="buttonBorderRadius" />
              </Grid>
            </Grid>
          </FormRow>

          {/* Show Watermark Toggle Input */}

          <FormRow marginBottom={48}>
            <FormCheckbox
              disabled={!isEntitledToUnbranded}
              name="showWatermark"
              title={'Show "Powered by Ketch" Watermark'}
            />
          </FormRow>

          {/* Header Section */}

          <Text className={classes.subHeader} size={16} weight={800} lineHeight={1.12} mb={3}>
            Header
          </Text>

          {/* Background Color */}

          <FormRow marginBottom={24}>
            <FormColorInput required fullWidth label="Background Color" name="formHeaderBackgroundColor" />
          </FormRow>

          {/* Content Color */}

          <FormRow marginBottom={48}>
            <Box display="flex" flexDirection="column">
              {!!values?.customizeFormHeaderContentColor ? (
                <>
                  <DefaultLabel title="Content Color" />
                  <Input value="Default" disabled sx={{ mb: 1 }} />
                </>
              ) : (
                <FormColorInput required fullWidth label="Content Color" name="formHeaderContentColor" />
              )}

              <FormCheckbox
                name="customizeFormHeaderContentColor"
                title="Use Default Color"
                onChange={(e, val) => {
                  setFieldValue('customizeFormHeaderContentColor', val)
                  if (val) {
                    setFieldValue('formHeaderContentColor', '')
                  } else {
                    setFieldValue('formHeaderContentColor', '#071A24')
                  }
                }}
              />
            </Box>
          </FormRow>

          {/* Body Section  */}

          <Text className={classes.subHeader} size={16} weight={800} lineHeight={1.12} mb={3}>
            Body
          </Text>

          <FormRow marginBottom={24}>
            <Typography variant="label" color="darkDusk.main">
              Accept/Reject All Buttons
            </Typography>
            <FormGroup sx={{ mt: 1 }}>
              <FormControlLabel
                sx={{ ml: 0 }}
                control={
                  <Switch
                    value={values?.purposeFormButtonsLookIdentical || false}
                    onChange={(_, checked) => setFieldValue('purposeFormButtonsLookIdentical', checked)}
                    sx={{ mr: 1 }}
                  />
                }
                label="Look Identical"
              />
            </FormGroup>
          </FormRow>

          {/* Body Color */}

          <FormRow marginBottom={24}>
            <FormColorInput required fullWidth label="Background Color" name="formBodyBackgroundColor" />
          </FormRow>

          {/* Content Color */}

          <FormRow marginBottom={24}>
            <FormColorInput required fullWidth label="Content Color" name="formContentColor" />
          </FormRow>

          {/* Button Color */}

          <FormRow marginBottom={24}>
            <FormColorInput required fullWidth label="Buttons Color" name="formButtonColor" />
          </FormRow>

          {/* Switch On Color */}

          <FormRow marginBottom={24}>
            <Box display="flex" flexDirection="column">
              {!!values?.customizeFormSwitchOnColor ? (
                <>
                  <DefaultLabel title="Switch Positive" />
                  <Input value="Default" disabled sx={{ mb: 1 }} />
                </>
              ) : (
                <FormColorInput required fullWidth label="Switch Positive" name="formSwitchOnColor" />
              )}

              <FormCheckbox
                name="customizeFormSwitchOnColor"
                title="Use Default Color"
                onChange={(e, val) => {
                  setFieldValue('customizeFormSwitchOnColor', val)
                  if (val) {
                    setFieldValue('formSwitchOnColor', '')
                  } else {
                    setFieldValue('formSwitchOnColor', '#071A24')
                  }
                }}
              />
            </Box>
          </FormRow>

          {/* Switch Off Color */}

          <FormRow marginBottom={24}>
            <Box display="flex" flexDirection="column">
              {!!values?.customizeFormSwitchOffColor ? (
                <>
                  <DefaultLabel title="Switch Negative" />
                  <Input value="Default" disabled sx={{ mb: 1 }} />
                </>
              ) : (
                <FormColorInput required fullWidth label="Switch Negative" name="formSwitchOffColor" />
              )}

              <FormCheckbox
                name="customizeFormSwitchOffColor"
                title="Use Default Color"
                onChange={(e, val) => {
                  setFieldValue('customizeFormSwitchOffColor', val)
                  if (val) {
                    setFieldValue('formSwitchOffColor', '')
                  } else {
                    setFieldValue('formSwitchOffColor', '#071A24')
                  }
                }}
              />
            </Box>
          </FormRow>
        </div>
      </Flex>
    </>
  )
}
