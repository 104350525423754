import React from 'react'
import clsx from 'clsx'
import { ButtonBase, CircularProgress } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseButton: {
        fontFamily: typography.fontFamily,
        borderRadius: 5,
        letterSpacing: '0.01em',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      baseContent: {},

      // Sizes:
      sizeSmall: {
        width: 38,
        height: 28,

        '& $baseContent': {
          fontSize: typography.pxToRem(16),
        },

        '&:hover': {
          background: palette.fadedGrey.main,

          '& $baseContent': {
            color: palette.royalBlue.main,
          },
        },

        '&:active': {
          background: palette.fadedDarkGrey.main,

          '& $baseContent': {
            color: palette.persianBlue.main,
          },
        },
      },
      sizeRegular: {
        width: 38,
        height: 28,
        background: palette.fadedGrey.main,

        '& $baseContent': {
          fontSize: typography.pxToRem(20),
        },

        '&:hover': {
          background: palette.iron.main,

          '& $baseContent': {
            color: palette.royalBlue.main,
          },
        },

        '&:active': {
          background: palette.fadedDarkGrey.main,

          '& $baseContent': {
            color: palette.persianBlue.main,
          },
        },
      },
      sizeBig: {
        width: 54,
        height: 44,
        background: palette.fadedGrey.main,

        '& $baseContent': {
          fontSize: typography.pxToRem(24),
        },

        '&:hover': {
          background: palette.iron.main,

          '& $baseContent': {
            color: palette.royalBlue.main,
          },
        },

        '&:active': {
          background: palette.fadedDarkGrey.main,

          '& $baseContent': {
            color: palette.persianBlue.main,
          },
        },
      },

      // States:
      disabled: {
        '&:not($sizeSmall)': {
          background: palette.fadedGrey.main,
        },

        '& $baseContent': {
          color: palette.lightGrey.main,
        },
      },
      pending: {
        '& $baseContent': {
          color: palette.sphere.main,
        },
      },
    }),
  { name: 'ButtonBack' },
)

export interface Props {
  /** Checks if the component should be disabled */
  disabled?: boolean
  /** Checks if the component should be in loading state */
  pending?: boolean
  /** Component size - reflects the size of the button */
  size?: 'small' | 'regular' | 'big'
  /** Component custom className */
  className?: any
  /** Component click listener */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

/**
 * -
 */
export const ButtonBack: React.FC<Props> = ({
  disabled = false,
  pending = false,
  size = 'regular',
  className,
  onClick,
}) => {
  const classes = useStyles()

  const buttonClassName = clsx(
    classes.baseButton,
    {
      [classes.sizeSmall]: size === 'small',
      [classes.sizeRegular]: size === 'regular',
      [classes.sizeBig]: size === 'big',
      [classes.pending]: pending,
      [classes.disabled]: disabled,
    },
    className,
  )

  return (
    <ButtonBase
      classes={{
        root: buttonClassName,
        disabled: classes.disabled,
      }}
      disabled={disabled || pending}
      disableRipple
      disableTouchRipple
      onClick={onClick}
    >
      {!pending ? (
        <KeyboardBackspaceIcon className={classes.baseContent} />
      ) : (
        <CircularProgress
          classes={{
            root: classes.baseContent,
          }}
          size={size === 'small' ? 14 : 18}
        />
      )}
    </ButtonBase>
  )
}
