import { GetInstalledDataSystemV2ResponseBodyDTO } from '@ketch-com/figurehead'
import { APIRequestParams } from 'api/common/utils'

import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  id: string
}>

export const fetchInstalledDataSystem = ({ id }: Params) =>
  API.get<GetInstalledDataSystemV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/installed-data-systems/${id}`,
      params: {
        filters: {
          includeDetails: true,
        },
      },
    }),
  )
