import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import { Typography } from '@mui/material'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { CookieDTO } from 'interfaces/cookies/cookie'

export const cookieVersionsTabGridColumns: GridColDef<CookieDTO>[] = [
  {
    align: 'left',
    headerAlign: 'left',
    field: 'status',
    headerName: 'Status',
    sortable: false,
    flex: 1,
    maxWidth: 110,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return (
        <TableCell>
          {!!row.latest && (
            <Status icon="Dot" label="Latest" status={StatusState.success} variant={StatusVariant.ghost} />
          )}
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    headerAlign: 'left',
    field: 'version',
    headerName: 'Version',
    sortable: false,
    flex: 1,

    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return (
        <TableCell>
          <Chip size="small" label={`v${row.version}`} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    headerAlign: 'left',
    field: 'name',
    headerName: 'Name',
    sortable: false,
    flex: 1,

    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      return <TableCell title={row.name} subTitle={row.code} sx={{ margin: '8px 0' }}></TableCell>
    },
  },
  {
    align: 'left',
    field: 'updatedAt',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Created',

    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<CookieDTO>) => {
      if (row.metadata?.updatedAt) {
        return <TableCell title={formatDateTimeFromUnix(row.metadata?.createdAt)}></TableCell>
      }
      return (
        <TableCell>
          <Typography color={'lightGrey.main'} variant="body">
            None
          </Typography>
        </TableCell>
      )
    },
  },
]
