import React, { useContext } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormFieldDetailViewContext } from '../context/FormFieldDetailViewContext'
import { FormFieldTypeDTO } from '@ketch-com/figurehead'
import { InfoRow } from '@ketch-com/deck'

type Props = {}

export const FormFieldViewItemsWithoutTranslation: React.FC<Props> = () => {
  const { formikProps } = useContext(FormFieldDetailViewContext)
  const { values } = formikProps

  const isText = values?.inputType === FormFieldTypeDTO.TextFormFieldType
  const isCheckbox = values?.inputType === FormFieldTypeDTO.CheckboxFormFieldType
  const isDropdown = values?.inputType === FormFieldTypeDTO.DropdownFormFieldType

  const options = isCheckbox ? values?.checkboxOptions : isDropdown ? values?.dropdownOptions : []

  if (isText) return null

  return (
    <>
      {options?.map((option, optionIndex) => {
        return (
          <InfoRow
            key={`${option.label}_${option.value}`}
            title={isCheckbox ? `Checkbox ${optionIndex + 1}` : `Item ${optionIndex + 1}`}
          >
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="body">{option?.label}</Typography>
              <Typography variant="smallBody" color="darkDuskFaded.main">
                <Typography variant="smallLabel" color="darkDuskFaded.main">
                  Value:
                </Typography>
                &nbsp;
                {option?.value}
              </Typography>
            </Box>
          </InfoRow>
        )
      })}
    </>
  )
}
