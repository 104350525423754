import React, { useContext, useMemo, useState, useEffect } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'

import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { RoutesManager } from 'utils/routing/routesManager'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { getBannerText } from 'components/ui-layouts/issuesMessage/IssuesMessage'
import { PolicyScopeRights } from 'pages/policyCenter/policyScopes/view/tabs/PolicyScopeRights'
import { PolicyScopeVersions } from 'pages/policyCenter/policyScopes/view/tabs/PolicyScopeVersions'
import { PolicyScopePurposesContainer } from 'pages/policyCenter/policyScopes/view/tabs/PolicyScopePurposesContainer'
import { PolicyScopeViewActions } from 'pages/policyCenter/policyScopes/view/components/PolicyScopeViewActions'
import { PolicyScopeBasicDetails } from 'pages/policyCenter/policyScopes/view/components/PolicyScopeBasicDetails'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PolicyScopeViewDetails } from 'pages/policyCenter/policyScopes/view/components/PolicyScopeViewDetails'
import { PolicyScopeViewVersions } from 'pages/policyCenter/policyScopes/view/components/PolicyScopeViewVersions'
import { relativePath } from 'utils/routing/relativePath'
import { PolicyScopeViewContext, withPolicyScopeViewContext } from './context/PolicyScopeViewContext'

import { Banner, Button, Spinner, ViewLayout, Tab, TabGroup } from '@ketch-com/deck'
import { NavigationBreadCrumbs } from '../../../../components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Box } from '@mui/material'
type Props = {}

const PolicyScopeViewWithContext: React.FC<Props> = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const { isReady, policyScope, versions, regulations, rights, regionGroups } = useContext(PolicyScopeViewContext)
  const location = useLocation()
  const { queries } = useQueryParams<{ version?: string }>()
  const { isPermitted } = useIsPermitted()
  const navigate = useNavigate()

  const tabs = useMemo(
    () => [
      {
        title: 'Rights',
        link: {
          pathname: relativePath(RoutesManager.policyCenter.policyScopes.view.rights.root.pattern),
          search: location.search,
        },
      },
      {
        title: 'Purposes',
        link: {
          pathname: relativePath(RoutesManager.policyCenter.policyScopes.view.purposes.root.pattern),
          search: location.search,
        },
        hidden: !isPermitted(PERMISSIONS.PURPOSE_READ),
      },
      {
        title: 'Versions',
        link: {
          pathname: relativePath(RoutesManager.policyCenter.policyScopes.view.versions.root.pattern),
          search: location.search,
        },
      },
    ],
    [isPermitted, location.search],
  )

  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (location.pathname.includes(tab.link.pathname)) {
        setCurrentTab(index)
      }
    })
  }, [location.pathname, tabs])

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Jurisdictions', link: RoutesManager.policyCenter.policyScopes.root.getURL() },
    { title: policyScope?.name },
  ]

  if (!isReady || !policyScope) {
    return (
      <>
        <NavigationBreadCrumbs type="dark" items={breadcrumbs} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '50px 0',
            backgroundColor: 'white.main',
            borderRadius: '11px',
            width: '1280px',
            flex: 1,
          }}
        >
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )
  }

  const handleChangeTab = (event: React.SyntheticEvent, newTab: number) => {
    setCurrentTab(newTab)
    navigate(tabs[newTab].link.pathname)
  }

  const isLatest = !queries.version

  const { code } = policyScope

  const banners = (
    <>
      {policyScope?.hasIssues && (
        <Banner
          sx={{
            minWidth: '100%',
          }}
          title={getBannerText(ApplicationEntity.POLICY_SCOPE)}
          action={
            <Button
              variant="link"
              onClick={() => {
                navigate(RoutesManager.policyCenter.policyScopes.upsert.root.getURL({ code }))
              }}
            >
              Update
            </Button>
          }
          severity="error"
        />
      )}
      {!isLatest && <PolicyScopeViewVersions />}
    </>
  )

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs} />

      <Box display="flex" flexDirection="column" gap="24px" alignItems="center" flex="1">
        {banners}
        <ViewLayout
          contentGroupProps={{
            title: policyScope?.name,
            showBackButton: true,
            propsBackButton: {
              onClick: () => navigate(RoutesManager.policyCenter.policyScopes.root.getURL()),
            },
            infoBlockComponent: <PolicyScopeViewDetails isLatest={isLatest} policyScope={policyScope} />,
            actionRightBlockComponent: <PolicyScopeViewActions policyScope={policyScope} isLatest={isLatest} />,
          }}
          customHeaderContentComponent={
            <PolicyScopeBasicDetails regionGroups={regionGroups} regulations={regulations} policyScope={policyScope} />
          }
        >
          <Box sx={{ paddingBottom: '32px' }}>
            <TabGroup
              value={currentTab}
              onChange={handleChangeTab}
              aria-label="policy scopes tabs"
              sx={{ marginBottom: '24px' }}
            >
              {tabs.map(tab => (
                <Tab label={tab.title} key={tab.title} />
              ))}
            </TabGroup>
            <Routes location={location}>
              <Route
                path="/"
                element={<PolicyScopeRights regulations={regulations} rights={rights} policyScope={policyScope} />}
              />

              <Route
                path={relativePath(RoutesManager.policyCenter.policyScopes.view.rights.root.pattern)}
                element={<PolicyScopeRights regulations={regulations} rights={rights} policyScope={policyScope} />}
              />

              <Route
                path={relativePath(RoutesManager.policyCenter.policyScopes.view.purposes.root.pattern)}
                element={<PolicyScopePurposesContainer />}
              />

              <Route
                path={relativePath(RoutesManager.policyCenter.policyScopes.view.versions.root.pattern)}
                element={
                  <PolicyScopeVersions
                    regulations={regulations}
                    rights={rights}
                    versions={versions}
                    policyScope={policyScope}
                  />
                }
              />
            </Routes>
          </Box>
        </ViewLayout>
      </Box>
    </>
  )
}

export const PolicyScopeView = withPolicyScopeViewContext(PolicyScopeViewWithContext)
