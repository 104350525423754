import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchAppliance } from '../fetchers/fetchAppliance'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.appliance,
  queryFn: fetchAppliance,
  select: res => res?.data || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useAppliance = (config: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
