import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { createStyles, makeStyles } from '@mui/styles'
import pluralize from 'pluralize'

import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { AssetManagerWidgetLayout, HookWidgetHookChip } from 'pages/assetManager/components'
import { useAssetSummaryAssignedHooks } from 'pages/assetManager/hooks'
import { Text } from 'components/ui-kit/typography/Text'
import Avatar from '@mui/material/Avatar'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/hooks-empty-state-icon.svg'
import { HooksWidgetEditModal } from './components'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { useUpdateAssetV2 } from 'api/assets/mutations/useUpdateAssetV2'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { mapValuesToPayload } from './components/HooksWidgetEditModal/utils'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      avatar: {
        backgroundColor: palette.fadedGrey.main,
        height: 24,
        width: 24,
        marginRight: spacing(1),
      },
      emptyState: {
        backgroundColor: palette.whiteFaded.main,
        padding: spacing(4, 0),
        minHeight: 0,
      },
    }),
  { name: 'HooksWidget' },
)

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export const HooksWidget: React.FC<Props> = ({ assetSummary }) => {
  const classes = useStyles()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const { hooks, assignedHooksCount } = useAssetSummaryAssignedHooks({ assetSummary })
  const queryClient = useQueryClient()
  const assetId = assetSummary?.asset?.resource?.id || ''

  const { mutate: handleUpdateAsset, isLoading: isUpdatingAsset } = useUpdateAssetV2({
    onError: () => {
      showToast({ content: 'Failed to update hooks', type: 'error' })
    },
    onSuccess: async () => {
      await queryClient.refetchQueries([
        ApiQueryKeys.resource,
        {
          assetCode: assetSummary?.asset?.resource?.id,
        },
      ])
      showToast({ content: 'Hooks updated', type: 'success' })
      setIsEditMode(false)
    },
  })

  return (
    <AssetManagerWidgetLayout
      isEditDisabled={false}
      isEditModalOpen={isEditMode}
      onEdit={() => setIsEditMode(true)}
      title="Hooks"
      subTitle={`${assignedHooksCount} ${pluralize('hook', assignedHooksCount)} assigned.`}
      editModal={
        <HooksWidgetEditModal
          assetSummary={assetSummary}
          isUpdatingAssetLabels={isUpdatingAsset}
          onSubmit={hooks => {
            const formData = mapValuesToPayload({
              assetSummary,
              hooks,
            })
            handleUpdateAsset({
              params: {
                assetCode: assetId || '',
                formData,
              },
            })
          }}
          onCancel={() => {
            setIsEditMode(false)
          }}
        />
      }
    >
      {assignedHooksCount > 0 ? (
        <Box display="flex" flexDirection="column" gap={2}>
          {hooks.map((hook, index) => {
            return (
              <Box key={hook.id} display="flex" alignItems="center">
                {/* Index */}

                <Avatar className={classes.avatar}>
                  <Text size={14}>{index + 1}</Text>
                </Avatar>

                {/* Hook Details */}

                <HookWidgetHookChip hook={hook} />
              </Box>
            )
          })}
        </Box>
      ) : (
        <EmptyState
          title="No hooks assigned"
          description="This asset has no hooks assigned."
          titleTextVariant="faded"
          descriptionTextVariant="faded"
          className={classes.emptyState}
          variant="page"
          icon={<EmptyStateIcon />}
        />
      )}
    </AssetManagerWidgetLayout>
  )
}
