import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchResourceTypeDetails } from '../fetchers/fetchResourceTypeDetails'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.resourceTypeDetails,
  queryFn: fetchResourceTypeDetails,
  select: res => res?.data || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useResourceTypeDetails = (config?: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
