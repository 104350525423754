import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

import { Flex } from 'components/ui-layouts/flex/Flex'
import { Text } from 'components/ui-kit/typography/Text'
import { UpsertInlineLayoutPreviewSidebar } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutPreviewSidebar'

const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        position: 'absolute',
        top: 13,
        right: 24,
        left: 24,
        bottom: 13,
      },
      header: {
        minHeight: 54, // per design review, should be 80px in height.  13px padding top + 13px padding bottom + 54px height = 80px
        borderBottom: `1px solid ${theme.palette.iron.main}`,
        paddingBottom: 13,
      },
      required: {
        paddingTop: 13,
        paddingBottom: 26,
      },
      content: {
        paddingTop: 19,
        flex: 1,
        overflowY: 'auto',
      },
    }),
  { name: 'UpsertExperienceFieldSidebar' },
)

type Props = {
  children?: React.ReactNode
  required?: boolean
  title: string
  actions?: React.ReactNode
}

export const UpsertExperienceFieldSidebar: React.FC<Props> = ({ title, required = false, children, actions }) => {
  const classes = useStyles()

  return (
    <UpsertInlineLayoutPreviewSidebar>
      <Flex flexDirection="column" className={classes.root}>
        <Flex
          className={clsx(classes.header, { [classes.required]: required })}
          justifyContent="space-between"
          alignItems="center"
        >
          <div>
            <Text size={20} weight={700}>
              {title}
            </Text>
          </div>

          {actions}
        </Flex>

        <div className={classes.content}>{children}</div>
      </Flex>
    </UpsertInlineLayoutPreviewSidebar>
  )
}
