import React from 'react'
import { SxProps, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import { makeStyles, createStyles } from '@mui/styles'
import { useField, FieldValidator } from 'formik'

import { Hint } from 'components/ui-kit/form/common/hint/Hint'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { TextArea, Props as BaseTextAreaProps } from 'components/ui-kit/textArea/TextArea'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { isFunction } from 'lodash'

const useStyles = makeStyles(
  createStyles({
    baseFormTextArea: {
      display: 'inline-block',
      width: '100%',
    },
    fullWidth: {
      display: 'block',
    },
  }),
  { name: 'FormTextArea' },
)

type OmittedProps = Omit<BaseTextAreaProps, 'name' | 'value' | 'valid'>

interface Props extends OmittedProps {
  /** Formik field name */
  name: string
  /** Text to be displayed below TextArea */
  hint?: string
  /** Text to be displayed above TextArea */
  label?: string | React.ReactNode
  /** Color of text to be displayed above TextArea */
  labelColor?: 'black' | 'grey'
  /** Display Asterisk as required field */
  required?: boolean
  /** Custom validation method */
  validate?: FieldValidator
  /** Helper text */
  helperText?: React.ReactNode
  /** Prop to provide sx style overrides */
  sx?: SxProps<Theme>
  /** Add escape hatch for automatic Formik update */
  shouldOverrideOnChange?: boolean
}

export const FormTextArea: React.FC<Props> = ({
  name,
  hint = '',
  label = '',
  labelColor = 'black',
  required = false,
  validate,
  fullWidth,
  helperText,
  shouldOverrideOnChange = false,
  onChange,
  sx,
  ...baseTextAreaProps
}) => {
  const classes = useStyles()
  const [field, meta] = useField({ name, validate })
  const showError = meta.error && meta.touched

  const onChangeInner = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!shouldOverrideOnChange) {
      // Formik onChange
      field.onChange(e)
    }

    // Outer onChange
    if (isFunction(onChange)) {
      onChange(e)
    }
  }

  return (
    <Box id={name} className={classes.baseFormTextArea} sx={sx}>
      {label && (
        <Label id={baseTextAreaProps.id} required={required} color={labelColor}>
          {label}
        </Label>
      )}

      {helperText ?? null}

      <TextArea valid={!showError} {...field} fullWidth={fullWidth} {...baseTextAreaProps} onChange={onChangeInner} />

      {showError && <Error>{meta.error}</Error>}

      {hint && !showError && <Hint>{hint}</Hint>}
    </Box>
  )
}
