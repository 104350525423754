import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchApplianceWorks } from '../fetchers/fetchApplianceWorks'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.applianceWorks,
  queryFn: fetchApplianceWorks,
  select: res => res?.data,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useApplianceWorks = (config: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
