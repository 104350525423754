import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { ExtendRightInvocationRequestBodyDTO } from '@ketch-com/figurehead'

type Params = {
  requestId: string
  formData: ExtendRightInvocationRequestBodyDTO
}

export const extendRightInvocation = ({ requestId, formData }: Params) =>
  API.post<{}>(
    formatRequestString({
      entityUrl: `/api/v2/right-invocations/${requestId}/extend`,
    }),
    formData,
  )
