import { useCallback } from 'react'
import { useUpdateDataSystemGlobalCustomAttributeOption } from 'api/dataSystems/mutations/useUpdateDataSystemGlobalCustomAttributeOption'
import { UpdateDataSystemGlobalCustomAttributeOptionParams } from 'api/dataSystems/fetchers/updateDataSystemGlobalCustomAttributeOption'
import { CustomFieldsState } from '../../types'

export const useOptionUpdates = (customFields: CustomFieldsState[]) => {
  const { mutateAsync: updateDataSystemGlobalCustomAttributeOption } = useUpdateDataSystemGlobalCustomAttributeOption()

  const handleOptionUpdates = useCallback(async () => {
    const optionsToUpdate: UpdateDataSystemGlobalCustomAttributeOptionParams[] = []

    customFields.forEach(field => {
      if (!field.options) return

      field.options.forEach(option => {
        if (option.isEdited) {
          const { isEdited, ...optionData } = option
          optionsToUpdate.push({ attributeId: field.id!, optionId: option.id!, reqBody: { option: optionData } })
        }
      })
    })

    if (!optionsToUpdate.length) return

    const updatePromises = optionsToUpdate.map(optionUpdate =>
      updateDataSystemGlobalCustomAttributeOption({ params: optionUpdate }),
    )

    await Promise.all(updatePromises)
  }, [customFields, updateDataSystemGlobalCustomAttributeOption])

  return handleOptionUpdates
}
