import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Chip, DataGrid, Icon, Button, Checkbox, getRowClassName } from '@ketch-com/deck'
import { useGridApiRef, GridEventListener, GridRowId } from '@mui/x-data-grid-premium'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks'
import { RightsQueueViewContext } from '../../../../context/RightsQueueViewContext'
import { Box, Typography } from '@mui/material'
import { workflowPreviewColumns } from './workflowPreviewColumns'
import { AppCrashView } from 'components/ui-routing/AppCrashView'
import { MetadataCardList } from '../../../RightsQueueOverviewTab/components'
import { parsePreviewRows } from './parsePreviewRows'

type Props = {
  mode?: 'execution' | 'appeal'
}

export const WorkflowPreview: React.FC<Props> = ({ mode = 'execution' }) => {
  const {
    workflowActivities,
    workflowExecution,
    workflowDefinition,
    rightInvocation,
    users,
    rightInvocationId,
    workflowPreview,
    appealWorkflow,
    appealWorkflowPreview,
    appealWorkflowExecution,
  } = useContext(RightsQueueViewContext)

  const apiRef = useGridApiRef()
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const [showSkipped, setShowSkipped] = useState(rightInvocation?.finalization?.forcefullyCompleted || false)
  const [showNotStarted, setShowNotStarted] = useState(false)
  const [openRows, setOpenRows] = useState<GridRowId[]>([])
  const shouldShowAdminAction = isPermitted([PERMISSIONS.ADMIN_KETCH])

  const workflowToUse = mode === 'execution' ? workflowDefinition : appealWorkflow
  const workflowExecutionToUse = mode === 'execution' ? workflowExecution : appealWorkflowExecution
  const workflowPreviewToUse = mode === 'execution' ? workflowPreview : appealWorkflowPreview

  // The whole row of each expandable group should be clickable to open/close the row
  const onRowClick = React.useCallback<GridEventListener<'rowClick'>>(
    params => {
      const rowId = params.id
      const tableRow = apiRef.current.getRowNode(rowId)

      if (tableRow && tableRow.type === 'group') {
        const isRowOpen = openRows.includes(rowId)
        apiRef.current.setRowChildrenExpansion(rowId, !isRowOpen)

        if (!isRowOpen) {
          setOpenRows([...openRows, rowId])
        } else {
          setOpenRows(openRows.filter(row => row !== rowId))
        }
      }
    },
    [apiRef, openRows],
  )

  // First column needs to be passed in separately as `groupingColDef` prop to be grouped on
  const { columns, firstColumn } = useMemo(
    () =>
      workflowPreviewColumns(
        users,
        shouldShowAdminAction,
        rightInvocationId,
        rightInvocation,
        workflowExecutionToUse,
        workflowActivities,
        workflowToUse,
        mode === 'appeal',
      ),
    [
      users,
      shouldShowAdminAction,
      rightInvocationId,
      rightInvocation,
      workflowExecutionToUse,
      workflowActivities,
      workflowToUse,
      mode,
    ],
  )

  const handleChipClick = () => {
    navigate(
      RoutesManager.orchestration.workflows.view.root.getURL({
        code: workflowExecutionToUse?.workflowDefinitionCode || '',
      }),
    )
  }

  const {
    rows: filteredRows,
    hasSkippedSteps,
    hasNotStartedSteps,
  } = useMemo(
    () =>
      parsePreviewRows(
        workflowPreviewToUse?.steps || [],
        showSkipped,
        showNotStarted,
        workflowExecutionToUse,
        rightInvocation,
      ),
    [workflowPreviewToUse?.steps, showNotStarted, showSkipped, workflowExecutionToUse, rightInvocation],
  )

  // On first load and after changing "Not Started" / "Skipped" all group rows should be expanded
  // Known issue - After some steps progress, this may re-expand collapsed columns - TODO:JA Figure out a safe fix
  useEffect(() => {
    setOpenRows(filteredRows.filter(({ isGroup }) => !!isGroup).map(({ id }) => id || ''))
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRows.length, showNotStarted, showSkipped])

  const expandCollapseAll = (expanded: boolean) => {
    const newRows: string[] = []

    filteredRows.forEach(row => {
      if (row.isGroup) {
        apiRef.current.setRowChildrenExpansion(row?.stepID || '', expanded)
        if (expanded) newRows.push(row?.stepID || '')
      }
    })

    setOpenRows(newRows)
  }

  return (
    <Box display="flex" flexDirection="column" mb={6}>
      <MetadataCardList mode={mode === 'execution' ? 'workflow' : 'appeal'} />

      <Box
        bgcolor="fadedGrey.main"
        display="flex"
        py={1}
        px={2}
        justifyContent="space-between"
        borderRadius="11px"
        mb={2}
        mt={1}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Icon name="FWorkflow" />
          <Typography variant="h4">{mode === 'appeal' ? 'Appeal' : 'Request'} Workflow</Typography>
          <Chip
            clickable
            onDelete={handleChipClick}
            deleteIcon={<Icon name="OArrowCRight" />}
            onClick={handleChipClick}
            label={<Box sx={{ maxWidth: 250 }}>{workflowExecutionToUse?.workflowDefinitionName || 'Workflow'}</Box>}
          />

          <Typography ml={2}>Show activities:</Typography>
          <Box display="flex" alignItems="center">
            <Checkbox
              disabled={!hasNotStartedSteps}
              name="skipped"
              checked={showNotStarted}
              onChange={() => setShowNotStarted(!showNotStarted)}
            />
            <Typography
              variant={showNotStarted ? 'label' : 'body'}
              component="label"
              htmlFor="skipped"
              onClick={() => (hasNotStartedSteps ? setShowNotStarted(!showNotStarted) : undefined)}
              color={hasNotStartedSteps ? undefined : 'Text.Secondary'}
            >
              Not Started
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              disabled={!hasSkippedSteps}
              name="skipped"
              checked={showSkipped}
              onChange={() => setShowSkipped(!showSkipped)}
            />
            <Typography
              variant={showSkipped ? 'label' : 'body'}
              component="label"
              htmlFor="skipped"
              onClick={() => (hasSkippedSteps ? setShowSkipped(!showSkipped) : undefined)}
              color={hasSkippedSteps ? undefined : 'Text.Secondary'}
            >
              Skipped
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Button color="secondary" onClick={() => expandCollapseAll(true)}>
            Expand All
          </Button>
          <Button color="secondary" onClick={() => expandCollapseAll(false)}>
            Collapse All
          </Button>
        </Box>
      </Box>

      {workflowExecutionToUse?.steps ? (
        <DataGrid
          treeData
          apiRef={apiRef}
          getRowClassName={getRowClassName}
          getTreeDataPath={row => row.path}
          onRowClick={onRowClick}
          slots={{
            treeDataCollapseIcon: () => <Icon name="OArrowCDown" />,
            treeDataExpandIcon: () => <Icon name="OArrowCRight" />,
          }}
          slotProps={{
            baseIconButton: {
              onClick: () => false, // delegate onClick to the rowClick handler
            },
          }}
          rows={filteredRows}
          sx={{
            '& .MuiDataGrid-virtualScroller': {
              height: workflowExecutionToUse?.steps && workflowExecutionToUse?.steps.length ? 'auto' : '300px',
            },
          }}
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: false,
            columns: columns.map(column => column.field),
            expand: true,
          }}
          getRowHeight={() => 'auto'}
          columns={columns}
          groupingColDef={firstColumn}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnReorder
          disableRowSelectionOnClick
          hideFooter
          isGroupExpandedByDefault={group => openRows.includes(group.id)}
        />
      ) : (
        <AppCrashView hideButton={true} />
      )}
    </Box>
  )
}
