import { styled } from '@mui/system'
import { ReactComponent as RemoveDropdownItemButtonIcon } from 'assets/icons/delete_dropdown_item_icon.svg'

interface RemoveRightButtonProps {
  disabled?: boolean
}

export const RemoveRightButton = styled(RemoveDropdownItemButtonIcon)<RemoveRightButtonProps>(
  ({ theme, disabled }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    height: 28,
    width: 28,
    '&:hover': {
      '& > path': {
        fill: !disabled ? theme.palette.sphere.main : undefined,
      },
    },
  }),
)
