import { Button, Icon, MegaFilter, MegaFilterOptionType } from '@ketch-com/deck'
import { SystemListFilter } from 'pages/dataSystems/components/Filters/SystemListFilterV2/SystemListFilter'
import React, { useEffect, useMemo } from 'react'
import { FilterContextProps } from '../context/AssetsTabFilterContext'
import { useSearchParams } from 'react-router-dom'
import { AssetsFiltersData } from 'pages/dataSystems/DataSystem/types'

export interface AssetsTabMegaFilterProps {
  filterData: FilterContextProps['filterData']
  isLoadingAssetsDataSystems: FilterContextProps['isLoadingAssetsDataSystems']
  setFilterData: FilterContextProps['setFilterData']
  sections: FilterContextProps['sections']
  setCounts: FilterContextProps['setCounts']
  filterButtonText: FilterContextProps['filterButtonText']
  resetFilters: FilterContextProps['resetFilters']
  desyncfromUrlParams?: boolean
}

export const AssetsTabMegaFilter: React.FC<AssetsTabMegaFilterProps> = ({
  filterData,
  isLoadingAssetsDataSystems: isLoading,
  setFilterData,
  sections,
  setCounts,
  filterButtonText,
  resetFilters,
  desyncfromUrlParams,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!desyncfromUrlParams) {
      //   Set things from query-params on the first page load. Determine if it is first by checking if any filter is selected
      if (searchParams.toString().length === 0) {
        return
      }
      const hasFiltersSet = Object.values(filterData).some(values => values.some(option => option.isSelected))
      if (hasFiltersSet) {
        return
      }
      const updatedFilterData: AssetsFiltersData = {}
      for (const key in filterData) {
        const searchValues = searchParams.get(key)?.split(',') || []
        updatedFilterData[key] = filterData[key].map(option => ({
          ...option,
          isSelected: searchValues.includes(option.id),
        }))
      }

      const count = Object.keys(filterData).reduce<Record<string, number>>((acc, key) => {
        const paramValues = searchParams.get(key)
        acc[key] = 0
        if (paramValues) {
          acc[key] = paramValues.split(',').length
        }
        return acc
      }, {})
      setCounts(count)
      setFilterData(updatedFilterData)
    }
  }, [desyncfromUrlParams, filterData, searchParams, setCounts, setFilterData])

  const selectedFilterValues = useMemo(() => {
    const filterValues: Record<string, MegaFilterOptionType[]> = {}
    for (const key in filterData) {
      filterValues[key] = filterData[key]
        .filter(option => option.isSelected)
        .map(selectedFilter => ({
          value: selectedFilter.id!,
          label: selectedFilter.name,
          category: key,
        }))
    }
    return filterValues
  }, [filterData])

  const updateFilters = (categoryName: string, optionId: string, isSelected: boolean) => {
    const updatedFilterData = {
      ...filterData,
      [categoryName]: filterData[categoryName].map(filter => {
        if (filter.id === optionId) {
          return { ...filter, isSelected }
        }
        return filter
      }),
    }
    if (!desyncfromUrlParams) {
      const updatedSearchParams = Object.entries(updatedFilterData).reduce(
        (acc, [key, values]) => {
          const selectedIds = values.reduce(
            (str, item) => (item.isSelected ? (str ? `${str},${item.id}` : item.id) : str),
            '',
          )

          if (selectedIds) {
            acc[key] = selectedIds
          }

          return acc
        },
        {} as Record<string, string>,
      )

      setSearchParams(updatedSearchParams)
    }
    setFilterData(updatedFilterData)
    setCounts(prev => ({
      ...prev,
      [categoryName]: isSelected ? prev[categoryName] + 1 : prev[categoryName] - 1,
    }))
  }

  const onResetFilters = () => {
    if (!desyncfromUrlParams) {
      setSearchParams({})
    }
    resetFilters()
  }

  return (
    <MegaFilter
      sx={{
        '& .MuiButtonBase-root': {
          alignSelf: 'flex-start',
        },
        '& #MegaFilter-popperId': {
          // Datagrid empty state z-index is set to 4 for some reason so we need to higher index for interaction
          zIndex: 1400,
        },
        '& #MegaFilter-popperId .MuiPaper-root': {
          minWidth: '300px',
          width: 'auto',
        },
        '& .MegaFilter-selectedOptions': {
          '& .MuiButtonBase-root': {
            alignSelf: 'center',
          },
        },
      }}
      value={selectedFilterValues}
      sections={sections}
      onDeselectChipHandler={option => updateFilters(option.category, option.value, false)}
      buttonTitle={filterButtonText}
      chipSize="small"
      buttonProps={{
        size: 'medium',
        color: 'secondary',
        endIcon: <Icon name="OArrowCDown" />,
        pending: isLoading,
        sx: {
          '& .DeckBtnWrapChild': {
            whiteSpace: 'nowrap',
          },
        },
      }}
      chipCount={0}
      renderActionItems={
        <Button
          sx={{ marginBlock: 1 }}
          color="tertiary"
          onClick={() => {
            onResetFilters()
          }}
        >
          Clear All
        </Button>
      }
    >
      {sections.map(section => {
        return (
          <SystemListFilter
            key={section.value}
            name={section.value}
            label={section.label}
            options={filterData[section.value]}
            isLoading={isLoading}
            updateFilters={updateFilters}
          />
        )
      })}
    </MegaFilter>
  )
}
