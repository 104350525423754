import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { register } from 'api/auth/fetchers/register'

const useCustomMutation = createUseMutation({
  mutationFn: register,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useRegister = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation)
}
