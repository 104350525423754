import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

import { Text } from 'components/ui-kit/typography/Text'

const useStyles = makeStyles(
  theme =>
    createStyles({
      bold: {
        fontWeight: 'bold',
      },
      blue: {
        color: theme.palette.sphere.main,
      },
    }),
  { name: 'UpsertExperiencePreviewText' },
)

type Props = {
  children?: React.ReactNode
  className?: string
  required?: boolean
  bold?: boolean
  size?: 'small' | 'medium' | 'large'
  color?: 'black' | 'white' | 'grey' | 'blue'
}

export const UpsertExperiencePreviewText: React.FC<Props> = ({
  size = 'medium',
  color = 'black',
  bold = false,
  className,
  children,
  required = false,
  ...rest
}) => {
  const classes = useStyles()

  const textProps = (() => {
    switch (size) {
      case 'small':
        return { size: 13, lineHeight: 1.54 }

      case 'medium':
        return { size: 18, lineHeight: 1.17 }

      case 'large':
        return { size: 22, lineHeight: 1.14 }
    }
  })()

  return (
    <Text
      {...rest}
      {...textProps}
      {...(color !== 'blue' && { color })}
      className={clsx({ [classes.bold]: bold, [classes.blue]: color === 'blue' }, className)}
    >
      {children}
      {required && '*'}
    </Text>
  )
}
