import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { createPlaceholderStep } from '../../steps/createPlaceholder'
import { getNextJoinTile } from '../../steps/getNextJoinTile'

interface IGetSplitGatewayInitialValuesParams {
  step: ICanvasStep
  steps: ICanvasStep[]
}

export interface IGetCommonGatewayInitialValues {
  description: string
  code: string
  transitions: Array<ICanvasStep>
}

export const getTransitionItem = (values: IGetCommonGatewayInitialValues, steps: ICanvasStep[]): ICanvasStep => {
  // By the time a new path is added to a split, it should always have a join somewhere down the chain.
  // Use the first path of the join to go find the associated join and set that as the new path's transition
  const firstPath = values.transitions[0]
  const joinTile = getNextJoinTile({ steps, step: firstPath })

  return createPlaceholderStep(joinTile?.ID, 'New Path')
}

export const getGatewayInitialValues = (
  params: IGetSplitGatewayInitialValuesParams,
): IGetCommonGatewayInitialValues => {
  const { steps } = params
  const step = steps.find(({ ID }) => ID === params.step.ID)

  const description = step?.description || ''
  const code = step?.code || ''
  const transitions = step?.gateway?.transitions || []

  const values = {
    description,
    code,
    transitions: transitions.map(transition => ({
      ...transition,
      name: transition?.name || '',
      variable: transition?.variable || '',
      operator: transition?.operator || '',
      operand: transition?.operand || '',
    })),
  }

  return values
}
