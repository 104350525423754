import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  workflowCode?: string
  stepId?: string
  assignee?: string
  orgCode?: string
}>

type Response = {}

export const reassignWorkflowStep = ({ workflowCode, stepId, assignee, orgCode }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${orgCode}/workflows/${workflowCode}/steps/${stepId}/assignee`,
    }),
    { assignee },
  )
