import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchConnections } from '../fetchers/fetchConnections'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.cookies,
  queryFn: fetchConnections,
  select: res => res?.data?.connections || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useConnections = (config?: Config) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )
  return useCustomQuery({
    ...config,
    params: {
      isV2FeatureFlagEnabled,
      ...config?.params,
    },
    cacheTime: 1000 * 60 * 5, // 5 minutes
  })
}
