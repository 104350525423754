import React, { useEffect, useContext } from 'react'
import { Navigate, Outlet, useNavigate, useNavigation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

// utils
import { useEntitlements } from 'api/entitlements/queries/useEntitlements'
import { usePermissions } from 'api/permissions/queries/usePermissions'
import { useGetFeatureFlags } from 'api/featureFlags/queries/useGetFeatureFlags'

// components
import { AppNavigation } from 'components/appLayout/appNavigation/AppNavigation'
import { AppContent } from 'components/appLayout/AppContent'
import { InitialLoadingScreen } from './auth/InitialLoadingScreen'
import { PlanAndBillingGlobalBanner } from 'components/planAndBilling/PlanAndBillingGlobalBanner'

// hooks
import { useHeapIdentify } from 'utils/hooks/useHeapIdentify'
import { useIntercomIdentify } from 'utils/hooks/useIntercomIdentify'
import { useAuth, useRefetchUnreadNotificationsCountOnNavigation } from 'utils/hooks'
import { useSyncRootStringPath } from 'utils/hooks/useSyncRootStringPath'
import { useLocalization } from 'utils/hooks/useLocalization'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { clear } from 'utils/CacheUtils'

//context
import {
  PlanAndBillingContext,
  withPlanAndBillingContext,
} from 'components/planAndBilling/common/context/PlanAndBillingContext'

export const AppLayoutWithContext: React.FC = () => {
  const navigate = useNavigate()
  const navigation = useNavigation()
  const { logout, isAuthenticated, isLoading: isAuth0Loading } = useAuth0()
  const { loginWithRedirect: ketchLoginWithRedirect } = useAuth()
  const { isReady: isPlanAndBillingReady } = useContext(PlanAndBillingContext)
  const { isLoading: isEntitlementsListLoading, isError: isFetchEntitlementsError } = useEntitlements({
    staleTime: 60 * 1000 * 5, // 5 minutes
    onError: () => {
      navigate('/auth/error')
    },
  })

  const { isLoading: isPermissionsListLoading, isError: isFetchPermissionsError } = usePermissions({
    staleTime: 60 * 1000 * 5, // 5 minutes
    onError: () => {
      navigate('/auth/error')
    },
  })

  const { isLoading: isFeatureFlagsLoading } = useGetFeatureFlags({
    staleTime: 60 * 1000 * 5, // 5 minutes
    onError: () => {
      navigate('/auth/error')
    },
  })

  const isReady =
    !isEntitlementsListLoading && !isPermissionsListLoading && !isFeatureFlagsLoading && isPlanAndBillingReady

  useHeapIdentify()
  useIntercomIdentify()
  useSyncRootStringPath()
  useLocalization()
  useRefetchUnreadNotificationsCountOnNavigation()

  useEffect(() => {
    const loginWithRedirectAsync = async () => {
      if (!isAuthenticated && !isAuth0Loading) {
        await ketchLoginWithRedirect()
      }
    }

    loginWithRedirectAsync()
  }, [isAuthenticated, ketchLoginWithRedirect, isAuth0Loading])

  if (isFetchEntitlementsError || isFetchPermissionsError) {
    clear()
    logout()
    return <Navigate to="/" />
  }

  if (!isReady || isAuth0Loading || !isAuthenticated) return <InitialLoadingScreen />

  return (
    <AppNavigation>
      <AppContent>
        <PlanAndBillingGlobalBanner />
        {navigation.state !== 'idle' ? <Spinner /> : <Outlet />}
      </AppContent>
    </AppNavigation>
  )
}

export const AppLayout = withPlanAndBillingContext(AppLayoutWithContext)
