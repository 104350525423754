import { Box } from '@mui/material'
import { styled } from '@mui/styles'

export const Container = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.minsk.main,
}))

export const InnerContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  padding: theme.spacing(5),
  borderRadius: theme.spacing(1),
  width: '400px',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}))
