import { Box, Typography, styled } from '@mui/material'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import React, { useContext } from 'react'
import { BuilderContext } from '../../../Builder'
import { getIn } from 'formik'
import { Icon } from '@ketch-com/deck'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { BUILDER_CONSTANTS } from '../../../utils/enums'
import { clamp } from 'lodash'

const HeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '8px',
  padding: '24px',
  minHeight: '87px',
})

const CloseButtonBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 30,
  height: 30,
})

export const ModalHeader: React.FC = () => {
  const { themeConfig, experienceConfig } = useContext(BuilderContext)

  // Theme
  const headerBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.modalHeaderBackgroundColor)
  const headerTextColor = getIn(themeConfig, themeUpsertFormKeys.modalHeaderTitleColor)
  const closeButtonBackgroundColor = getIn(themeConfig, themeUpsertFormKeys.modalHeaderReturnButtonFillColor)
  const closeButtonIconColor = getIn(themeConfig, themeUpsertFormKeys.modalHeaderReturnButtonIconColor)
  const closeButtonBorderRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.modalHeaderReturnButtonCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'
  const borderRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.modalContainerCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'

  // Experience
  const headerText = getIn(experienceConfig, experienceUpsertFormKeys.modalHeaderTitle)
  const showCloseButton = getIn(experienceConfig, experienceUpsertFormKeys.modalHeaderCloseButtonVisible)

  return (
    <HeaderBox
      sx={{
        background: headerBackgroundColor,
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
      }}
    >
      <Typography
        fontSize={26}
        fontWeight={700}
        lineHeight={'39px'}
        color={headerTextColor}
        sx={{ wordWrap: 'break-word', maxWidth: '600px' }}
      >
        {headerText}
      </Typography>
      {showCloseButton && (
        <CloseButtonBox
          sx={{
            backgroundColor: closeButtonBackgroundColor,
            borderRadius: closeButtonBorderRadius,
          }}
        >
          <Icon name={'OCross'} iconColor={closeButtonIconColor} sx={{ borderRadius: closeButtonBorderRadius }} />
        </CloseButtonBox>
      )}
    </HeaderBox>
  )
}
