import React from 'react'
import { Box } from '@mui/material'

import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { FormInput } from 'components/ui-kit/form/input/FormInput'

import { useStyles } from '../hooks'
import { RegistrationHeading } from '../components/RegistrationHeading'

const nonCompanyEmailDomains = ['gmail.com', 'yahoo.com', 'mail.ru', 'yandex.mail']

const bannedEmailDomains = [
  'transcend.io',
  'securiti.ai',
  'bigid.com',
  'datagrail.io',
  'didomi.io',
  'onetrust.com',
  'osano.com',
  'truyo.com',
  'wirewheel.io',
  'trustarc.com',
  'cookiebot.com',
  'secuvy.ai',
  'sourcepoint.com',
  'eligrey.com',
]

export const PersonalInfo = ({ props }: any) => {
  const classes = useStyles()

  const validateEmail = (value: string) => {
    // Check if none
    if (!value) return 'Required'

    const valueToTest = value.toLowerCase()

    // Check if valid email
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) return 'Invalid email address'

    // Check if company email
    if (nonCompanyEmailDomains.some(domain => valueToTest.endsWith(domain.toLowerCase())))
      return 'Ketch free only supports accounts created with company email addresses.'

    // Check if disallowed domain
    if (bannedEmailDomains.some(domain => valueToTest.endsWith(domain.toLowerCase())))
      return 'This company is not supported by Ketch Free.'

    return ''
  }

  return (
    <div className={classes.flexContainer}>
      <RegistrationHeading
        formTitle="Create your account"
        formSubtitle="Ketch is quick and easy to set up."
        formSecondSubtitle="Achieve day one privacy compliance with Ketch for free."
      />
      <Box mb={3}>
        <FormRow>
          <FormInput fullWidth required autocomplete="name" label="Full Name" name="fullName" />
        </FormRow>
      </Box>
      <Box mb={4}>
        <FormRow>
          <FormInput fullWidth required autocomplete="email" label="Work Email" name="email" validate={validateEmail} />
        </FormRow>
      </Box>
    </div>
  )
}
