import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { useFormUtils } from './useFormUtils'
import { useResolve } from 'pages/orchestration/rightsQueue/stepDetails/hooks'

export const useSetPermitsViewUtils = () => {
  const [showRequiredFields, setShowRequiredFields] = useState(false)
  const [isResolving, setIsResolving] = useState(false)
  const { id: rightInvocationId } = useParams<{ id?: string }>()

  const {
    isReady: isContextReady,
    userMe,
    rightInvocation,
    stepId,
    workflowStep,
    workflowExecutionId,
  } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const { resolutionAttachments, initialValues, validationSchema, isStepDetailsLoading, stepDetails } = useFormUtils()

  const { resolve } = useResolve({
    setIsResolving,
    stepDetails,
  })

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    { title: 'Rights Queue', link: RoutesManager.orchestration.rightsQueue.root.getURL() },
    {
      title: rightInvocation?.policyScopeName,
      link: RoutesManager.orchestration.rightsQueue.view.root.getURL({ id: rightInvocationId }),
    },
    {
      title: 'Set Permits',
      link: RoutesManager.orchestration.rightsQueue.stepDetails.root.getURL({
        id: rightInvocationId,
        stepId,
        workflowExecutionId,
      }),
    },
  ]
  const isReady = !!isContextReady && !isStepDetailsLoading && !isStepDetailsLoading
  const isAssignedToMe = stepDetails?.assignee === userMe?.ID
  const isCompleted = workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS

  return {
    breadcrumbs,
    resolutionAttachments,
    initialValues,
    isAssignedToMe,
    isCompleted,
    isReady,
    resolve,
    rightInvocationId,
    stepDetails,
    validationSchema,
    workflowStep,
    isResolving,
    setIsResolving,
    showRequiredFields,
    setShowRequiredFields,
  }
}
