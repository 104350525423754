import React from 'react'
import { InputLabel, Typography } from '@mui/material'

type FormInputLabelProps = {
  required?: boolean
  label?: string
}

export const FormInputLabel = ({ required, label }: FormInputLabelProps): React.ReactElement => {
  return (
    <InputLabel>
      {required ? (
        <Typography variant="label" color="darkDusk.main">
          {label}
        </Typography>
      ) : (
        <>
          <Typography variant="label" color="darkDusk.main">
            {label}
          </Typography>{' '}
          <Typography variant="label" color="darkGrey.main">
            {'(Optional)'}
          </Typography>
        </>
      )}
    </InputLabel>
  )
}
