import React, { useState } from 'react'

import { FormFieldDTO } from '@ketch-com/figurehead'
import { useLocation, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { setNavigationSliceGoBack } from 'store/navigationSlice'
import { useDispatch } from 'react-redux'
import { FormMode } from 'interfaces/formModes/formMode'
import { DeleteFormFieldModalV3 } from 'pages/consentAndRights/forms/components'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'

type Props = {
  formField?: FormFieldDTO
  isUserExperienceServerAdmin?: boolean
}

export const CustomFormFieldsDropdown: React.FC<Props> = ({ formField, isUserExperienceServerAdmin }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Button size="medium" variant="iconSubtle" onClick={handleClickListItem} disabled={!isUserExperienceServerAdmin}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transitionDuration={0}
        onClick={e => e.stopPropagation()}
      >
        {isUserExperienceServerAdmin && (
          <ActionSheetItem
            onClick={e => {
              handleClose()
              dispatch(setNavigationSliceGoBack(location.pathname))
              navigate(
                RoutesManager.deployment.forms.fields.upsert.root.getURL({
                  id: formField?.id || '',
                  formMode: FormMode.EDIT,
                }),
              )
            }}
          >
            Edit
          </ActionSheetItem>
        )}
        {isUserExperienceServerAdmin && <ActionSheetItem divider />}
        {isUserExperienceServerAdmin && (
          <ActionSheetItem
            onClick={e => {
              handleClose()
              setIsDeleteModalOpen(true)
            }}
          >
            Delete
          </ActionSheetItem>
        )}
      </ActionSheetWrapper>
      {isDeleteModalOpen && (
        <DeleteFormFieldModalV3 formField={formField} onCancel={() => setIsDeleteModalOpen(false)} />
      )}
    </>
  )
}
