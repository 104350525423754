/** @jsxRuntime classic */
/** @jsx jsx */
import { createElement, useRef, useEffect } from 'react'
import { h, render } from 'preact'
import {
  LanyardProvider,
  LanyardModalExperience,
  LanyardModalExperienceProps,
  fullConfigBeta,
  consentsMock,
  vendorConsentMock,
  showPreferenceOptionsMock,
} from '@ketch-com/lanyard'

import { Theme } from '@ketch-sdk/ketch-types'

import { ThemeDTO, ThemeFormDTO } from 'interfaces/themes-v2/theme'

const jsx = createElement

type Props = {
  wrapperClassName?: string
  theme: Theme | ThemeDTO | ThemeFormDTO
} & LanyardModalExperienceProps

export type ModalPreviewProps = Props

export const ModalPreview: React.FC<Props> = ({ wrapperClassName, theme, purposes, ...modalProps }) => {
  const ref = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    const jsx = h

    render(
      <LanyardProvider
        config={{ ...fullConfigBeta, theme, ...(purposes && { purposes }) } as any}
        consent={consentsMock?.purposes}
        handleUpdateConsent={() => console.info('handleUpdateConsent')}
        setViewMode={() => console.info('setViewMode')}
        vendorConsent={vendorConsentMock}
        viewMode="experiences.consent.modal"
        showPreferenceOptions={showPreferenceOptionsMock}
      >
        <LanyardModalExperience {...modalProps} />
      </LanyardProvider>,
      ref.current,
    )
  })

  return <div className={wrapperClassName} ref={ref} />
}
