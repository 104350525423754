import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'
import { showToast } from 'components/modals/AlertComponent'
import { useInviteMember } from 'api/users/mutations/useInviteMember'
import { UserDTO } from 'interfaces/users/users'
import { UserFormValues, getUserFormPayload } from 'pages/settings/users/upsert/utils'
import { UsersUpsertView } from 'pages/settings/users/upsert/UsersUpsertView'
import { useUpdateUser } from 'api/users/mutations/useUpdateUser'
import { useUser } from 'api/users/queries/useUser'
import { useRoles } from 'api/roles/queries/useRoles'
import { useCurrentOrganization } from 'utils/hooks/useCurrentOrganization'

export const UsersUpsertContainer: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { id } = useParams<{ id: string }>()
  const isEditMode = id !== NEW_ITEM_ID

  const onInviteMemberSuccess = async () => {
    await queryClient.refetchQueries(ApiQueryKeys.entitlements)

    showToast({ content: 'Member Invited', type: 'success' })

    navigate(RoutesManager.settings.users.root.getURL())
  }

  const onUpdateUserSuccess = async (nextUser: UserDTO) => {
    await queryClient.refetchQueries(ApiQueryKeys.entitlements)
    await queryClient.refetchQueries([ApiQueryKeys.user, { userId: nextUser.ID }])

    if (isEditMode) {
      showToast({ content: 'User updated', type: 'success' })
    } else {
      showToast({ content: 'User created', type: 'success' })
    }

    navigate(RoutesManager.settings.users.view.root.getURL({ id: nextUser.ID }))
  }

  const handleRedirectToUsersList = () => {
    navigate(RoutesManager.settings.users.root.getURL())
  }

  const { organization, isLoading: isLoadingOrganization } = useCurrentOrganization({})

  const { mutateAsync: handleInviteMember } = useInviteMember({
    onSuccess: () => onInviteMemberSuccess(),
    onError: () => {
      showToast({ content: 'Failed to invite user', type: 'error' })
    },
  })

  const { mutateAsync: handleUpdateUser } = useUpdateUser({
    onSuccess: ({ data }) => onUpdateUserSuccess(data.user),
    onError: () => {
      showToast({ content: 'Failed to update user', type: 'error' })
    },
  })

  const { data: user, isLoading: isUserLoading } = useUser({
    enabled: isEditMode,
    params: {
      userId: id!,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch user', type: 'error' })
      handleRedirectToUsersList()
    },
  })

  const { data: roles, isLoading: isUsersRolesLoading } = useRoles({
    onError: () => {
      showToast({ content: 'Failed to fetch users roles', type: 'error' })
      handleRedirectToUsersList()
    },
  })

  const onSubmit = async (values: UserFormValues) => {
    const formData = getUserFormPayload({ values })

    await (isEditMode
      ? handleUpdateUser({
          params: {
            userId: id!,
            formData,
          },
        })
      : handleInviteMember({
          params: {
            formData,
          },
        }))
  }

  return (
    <UsersUpsertView
      isReady={!isUserLoading && !isUsersRolesLoading && !isLoadingOrganization}
      isEditMode={isEditMode}
      user={user}
      roles={roles}
      onSubmit={onSubmit}
      organization={organization}
    />
  )
}
