import React from 'react'

import { Button, PopUp } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  onSubmit?: () => void
  onCancel: () => void
  tileName: string
}

export const ConfirmRemoveTileModal: React.FC<Props> = ({ onSubmit, onCancel, tileName }) => {
  return (
    <PopUp
      title="Remove Tile"
      variant="dialog"
      popUpWidth="550px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="primary" size="large" onClick={onSubmit}>
            Remove
          </Button>
        </>
      }
    >
      <Typography>
        Are you sure you want to remove the <Typography variant="label">{tileName}</Typography> tile? Doing so will also
        remove all tiles to the right of it.
      </Typography>
    </PopUp>
  )
}
