import { RIGHTS_INVOCATION_TYPE, WORKFLOW_TYPE_ENUM } from '../constants'

export const getRightsInvocationType = (canonicalRightCode: string): number => {
  switch (canonicalRightCode) {
    case WORKFLOW_TYPE_ENUM.DELETE:
      return RIGHTS_INVOCATION_TYPE.ERASURE
    case WORKFLOW_TYPE_ENUM.GET:
      return RIGHTS_INVOCATION_TYPE.GET
    default:
      return RIGHTS_INVOCATION_TYPE.UNSPECIFIED
  }
}
