import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { UserDTO } from 'interfaces/users/users'

type Params = APIRequestParams<{
  organizationCode: string
  userId: string
  includeMetadata?: boolean
}>

type Response = { user: UserDTO }

export const fetchUser = ({ organizationCode, userId, includeMetadata }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/members/${userId}`,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
  )
