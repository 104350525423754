import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles, createStyles } from '@mui/styles'
import clsx from 'clsx'

import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'
import {
  NavigationBreadCrumbs,
  NavigationBreadCrumbsProps,
} from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { PurposesWidget, HooksWidget, LocationWidget } from 'pages/assetManager/assets/view/components'
import { AssetSummaryDTO, FetchAssetProcessingActivitiesResponseBodyDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'
import {
  getAssetCanonicalResourceType,
  getDoesAssetSupportHooks,
} from 'pages/assetManager/components/EditAssetForm/utils'
import { ASSET_TYPE_ENUM } from 'interfaces/assets'
import { useAssetParents } from 'pages/assetManager/assets/hooks'
import { ProcessingActivitiesWidget } from '../../../pages/assetManager/assets/view/components/ProcessingActivitiesWidget/ProcessingActivitiesWidget'
import { ClassificationsWidget } from 'pages/assetManager/assets/view/components/ClassificationsWidget/ClassificationsWidget'

const useStyles = makeStyles(
  theme =>
    createStyles({
      layoutBounds: {
        width: 1280,
      },
      detailsContainer: {
        flex: 5,
        padding: 14,
        backgroundColor: theme.palette.white.main,
        borderRadius: 11,
        maxWidth: 898,
      },
      loading: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        minHeight: 250,
      },
      versionsMessage: {
        marginBottom: 24,
      },
      issuesMessage: {
        marginBottom: 24,
      },
      ignoreFlex: {
        flex: 0,
      },
    }),
  { name: 'AssetManagerViewLayout' },
)

type Props = {
  children?: React.ReactNode
  isReady?: boolean
  breadcrumbs?: NavigationBreadCrumbsProps['items']
  issuesMessage?: React.ReactNode
  versionsMessage?: React.ReactNode
  assetSummary: MaybeNull<AssetSummaryDTO>
  processingActivities: MaybeNull<FetchAssetProcessingActivitiesResponseBodyDTO>
}

export const AssetManagerViewLayout: React.FC<Props> = ({
  isReady = true,
  breadcrumbs = [],
  children,
  issuesMessage,
  versionsMessage,
  assetSummary,
  processingActivities,
}) => {
  const classes = useStyles()
  const shouldShowHooksWidget = getDoesAssetSupportHooks(assetSummary)
  const shouldShowPurposesWidget =
    getAssetCanonicalResourceType(assetSummary) === ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATABASE
  const resourceParentsDescending = useAssetParents({ assetSummary })
  const shouldShowLocationWidget = resourceParentsDescending.length > 0

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />

      {isReady && !!issuesMessage && (
        <ContentBoundaries className={classes.issuesMessage} width={1280}>
          {issuesMessage}
        </ContentBoundaries>
      )}

      {isReady && !!versionsMessage && (
        <ContentBoundaries className={classes.versionsMessage} width={1280}>
          {versionsMessage}
        </ContentBoundaries>
      )}

      <Box className={clsx(classes.layoutBounds)}>
        {isReady ? (
          <Box display="flex" gap={2}>
            <Box className={classes.detailsContainer} flex={3}>
              {children}
            </Box>
            <Box flex={1} display="flex" flexDirection="column" justifyContent="flex-start" gap={2}>
              <ClassificationsWidget />

              {shouldShowLocationWidget ? (
                <LocationWidget assetSummary={assetSummary} resourceParentsDescending={resourceParentsDescending} />
              ) : null}

              {shouldShowHooksWidget ? <HooksWidget assetSummary={assetSummary} /> : null}

              {shouldShowPurposesWidget ? (
                <PurposesWidget assetSummary={assetSummary} purposes={processingActivities?.purposes || []} />
              ) : null}

              <ProcessingActivitiesWidget processingActivities={processingActivities?.processingActivities || []} />

              {/* TODO:BAC - PoliciesWidget hidden per BE not ready */}
              {/* <PoliciesWidget assetSummary={assetSummary} /> */}
            </Box>
          </Box>
        ) : (
          <Box display="flex" alignItems="center" className={classes.loading}>
            <Spinner />
          </Box>
        )}
      </Box>
    </>
  )
}
