import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { ICanvasStep, ITaskActivityParams } from 'pages/orchestration/workflows/edit/interfaces'
import { GatewayTransitionDTO } from 'pages/orchestration/workflows/edit/interfaces/ICanvasStep'
import { ContextVarDTO } from 'pages/orchestration/workflows/edit/interfaces/ITaskActivityParams'

// Type Definitions
type Args = {
  steps: Array<ICanvasStep>
}

type ContextVarDTOWithTaskName = ContextVarDTO & { taskName?: string; taskStepId?: string }
type GatewayTransition = GatewayTransitionDTO & { stepId?: string }

export type SubjectVariableWarningMessage = {
  variableName?: string
  variableCode?: string
  gatewayName?: string
  taskName?: string
  transition?: GatewayTransitionDTO
}

export function filterAndRemoveDuplicates(messages: SubjectVariableWarningMessage[]): SubjectVariableWarningMessage[] {
  const uniqueMessages: SubjectVariableWarningMessage[] = []
  const messageSet: Set<string> = new Set()

  for (const message of messages) {
    const key = `${message.variableName}_${message.gatewayName}`

    if (!messageSet.has(key)) {
      uniqueMessages.push(message)
      messageSet.add(key)
    }
  }

  return uniqueMessages
}

/**
 * Retrieves a list of warning messages for context variables that are referenced in gateways
 * but not set as required in tasks.
 *
 * @param {Object} args - The arguments object.
 * @param {Array<ICanvasStep>} args.steps - The array of canvas steps to check for warnings.
 *
 * @returns {Array<SubjectVariableWarningMessage>} - The list of warning messages. Each message contains information
 * about the variable, the task and the gateway involved.
 */
export const getIsContextVariableReferencedInGatewayButNotSetToRequired = ({
  steps,
}: Args): SubjectVariableWarningMessage[] => {
  // Initialize array to store the warning messages
  const subjectVariableWarningMessages: SubjectVariableWarningMessage[] = []

  // Initialize array to store variables that are not required
  const unRequiredVariables: ContextVarDTOWithTaskName[] = []

  // Iterate through the steps
  steps.forEach(step => {
    // If the activityCode of step is a task
    if (step?.activityCode === WorkflowActivityCode.TASK) {
      // Check if data subject variables are required or not, if not add to unRequiredVariables array
      ;(step?.activity?.params as ITaskActivityParams)?.dataSubjectVariables?.forEach(dsv => {
        if (!dsv?.required) {
          unRequiredVariables.push({
            ...dsv,
            taskStepId: String(step?.ID),
            taskName: step?.description,
          })
        }
      })
      // Check if outcome variables are required or not, if not add to unRequiredVariables array
      ;(step?.activity?.params as ITaskActivityParams)?.outcomeVariables?.forEach(ov => {
        if (!ov?.required) {
          unRequiredVariables.push({
            ...ov,
            taskStepId: String(step?.ID),
            taskName: step?.description,
          })
        }
      })
    }
  })

  // Initialize array to store transitions that refer to unrequired variables
  const gatewayTransitions: GatewayTransition[] = []

  // Iterate through the steps again
  steps.forEach(step => {
    // If the activityCode of step is a multi-decision
    if (step?.activityCode === WorkflowActivityCode.DECISION_MULTI) {
      // Check each transition in the gateway of the step
      step?.gateway?.transitions?.forEach(transition => {
        // If transition variable is in unRequiredVariables array
        if (unRequiredVariables.some(unRequiredVariable => unRequiredVariable?.code === transition?.variable)) {
          const variable = unRequiredVariables.find(
            unRequiredVariable => unRequiredVariable?.code === transition?.variable,
          )

          // Add transition to gatewayTransitions array
          gatewayTransitions.push({
            ...transition,
            stepId: String(step?.ID),
          })
          // Add a warning message about the unrequired variable being referenced in a gateway
          subjectVariableWarningMessages.push({
            variableName: variable?.name,
            variableCode: variable?.code,
            gatewayName: step?.description,
            taskName: variable?.taskName,
            transition: transition,
          })
        }
      })
    }
  })

  return filterAndRemoveDuplicates(subjectVariableWarningMessages)
}
