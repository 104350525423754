import React from 'react'
import Box from '@mui/material/Box'
import { Button, DataGrid, TableCell } from '@ketch-com/deck'
import { FormTemplateDTO, FormTemplateExperienceDTO } from '@ketch-com/figurehead'
import { Link } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { getFormTemplateDeployedExperiences } from '../templates/upsert/utils'
import { useFeatureFlag } from 'utils/hooks'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

type Props = {
  formTemplate?: FormTemplateDTO
}

export const FormTemplateUsedInExperienceTable: React.FC<Props> = ({ formTemplate }) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isExpV1 = isFeatureFlagEnabled(
    FEATURE_FLAGS.EXPERIENCE_SERVER_VERSION,
    FEATURE_FLAGS_VALUES.EXPERIENCE_SERVER_VERSION_1,
  )

  return (
    <DataGrid
      autosizeOnMount
      autosizeOptions={{
        includeHeaders: true,
        includeOutliers: false,
        expand: true,
      }}
      getRowHeight={() => 'auto'}
      columns={[
        {
          align: 'left',
          field: 'name',
          flex: 1,
          headerAlign: 'left',
          headerName: 'Experience',
          sortable: false,
          renderCell: ({ row }: GridRenderCellParams<FormTemplateExperienceDTO>) => {
            return <TableCell title={row.name} subTitle={row.code} />
          },
        },
        {
          align: 'left',
          field: 'description',
          flex: 1,
          headerAlign: 'left',
          headerName: 'Description',
          sortable: false,
          renderCell: ({ row: experience }: GridRenderCellParams<FormTemplateExperienceDTO>) => {
            return (
              <TableCell>
                <Box display="flex" alignItems="center" pr={2.25}>
                  <Link
                    to={
                      isExpV1
                        ? RoutesManager.deployment.experiences.view.root.getURL({
                            code: experience?.code,
                          })
                        : RoutesManager.deployment.experiencesV2.view.preview.root.getURL({
                            id: experience?.id,
                          })
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <Button color="tertiary">Open In New Tab</Button>
                  </Link>
                </Box>
              </TableCell>
            )
          },
        },
      ]}
      disableChildrenSorting
      disableColumnMenu
      disableColumnPinning
      disableColumnResize
      disableColumnReorder
      disableRowSelectionOnClick
      disableRowHoverStates
      getRowId={row => row.code}
      rows={getFormTemplateDeployedExperiences(formTemplate)}
      noRowsOverlayProps={{
        buttonTitle: '',
        subTitle: '',
      }}
      hideFooter
    />
  )
}
