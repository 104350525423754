import { IDataReviewActivityParams, ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { isDataReviewActivityParamsTypeGuard } from 'pages/orchestration/workflows/edit/interfaces/typeGuards'

interface IGetDataReviewInitialValuesParams {
  step: ICanvasStep
}

export const getDataReviewInitialValues = (params: IGetDataReviewInitialValuesParams): IDataReviewActivityParams => {
  const { step } = params

  if (isDataReviewActivityParamsTypeGuard(step.workflow?.params)) {
    return {
      approver: step.workflow?.params?.approver || '',
      assignee: step.workflow?.params?.assignee || '',
      code: step.code || '',
      description: step.description || '',
      needsApproval: step.workflow?.params?.needsApproval || false,
      timeToCompleteDays: step.workflow?.params?.timeToCompleteDays || undefined,
    }
  }

  return {
    approver: '',
    assignee: '',
    code: '',
    description: '',
    needsApproval: false,
    timeToCompleteDays: undefined,
  }
}
