import { FC } from 'react'
import Box from '@mui/material/Box'
import { EmptyState, theme } from '@ketch-com/deck'

export const RedactionEmptyState: FC = () => (
  <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center" mt={13}>
    <EmptyState
      title="Select the document for redaction"
      subTitle="Check the panel on the left to look up and select the document that you'd like to edit."
      iconName="ODocument"
      size="large"
      colorTheme={theme.palette.darkDuskFaded.main}
    />
  </Box>
)
