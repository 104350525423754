import { createContext } from 'react'
import { UnapprovedTrackersUtils } from 'pages/policyCenter/trackers/interfaces'
import { useUnapprovedTrackersUtils } from 'pages/policyCenter/trackers/hooks'

export const UnapprovedTrackersContext = createContext({} as UnapprovedTrackersUtils)

type Props = {
  children?: React.ReactNode
}

export const UnapprovedTrackersContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useUnapprovedTrackersUtils()

  return (
    <UnapprovedTrackersContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </UnapprovedTrackersContext.Provider>
  )
}

export const withUnapprovedTrackersContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <UnapprovedTrackersContextProvider>
        <Component {...props} />
      </UnapprovedTrackersContextProvider>
    )
  }
}
