import { FILTER_STATES } from '../constants'

export const getPadding = (filterState: FILTER_STATES) => {
  switch (filterState) {
    case FILTER_STATES.COLLAPSED:
      return '14px 20px'
    case FILTER_STATES.OPEN:
      return '14px 20px'
    case FILTER_STATES.SELECTING:
      return '14px 20px'
    default:
      return '14px 20px'
  }
}
