import { useWorkflowsPaginated } from 'api/workflows/queries/useWorkflowsPaginated'

export const useAppealWorkflows = ({ canonicalRightCode }: { canonicalRightCode: string }) => {
  return useWorkflowsPaginated({
    enabled: !!canonicalRightCode,
    itemsPerPage: 1000,
    params: {
      canonicalRightCode: canonicalRightCode,
    },
  })
}
