import { AssetDTO } from '@ketch-com/figurehead'
import { ASSET_TYPE_ENUM } from 'interfaces/assets'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

export const useHandleViewResourceOnClick = (asset: AssetDTO) => {
  const navigate = useNavigate()
  const handleViewOnClick = useCallback(() => {
    switch (asset?.resource?.resourceType?.canonicalResourceType) {
      case ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATABASE:
        navigate(RoutesManager.assetManager.assets.detail.root.getURL({ code: asset?.resource?.id || '' }))
        break
      case ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATASET:
        navigate(RoutesManager.assetManager.assets.detail.root.getURL({ code: asset?.resource?.id || '' }))
        break
      case ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_ATTRIBUTE:
        navigate(RoutesManager.assetManager.assets.detail.root.getURL({ code: asset?.resource?.id || '' }))
        break
      default:
        break
    }
  }, [asset, navigate])

  return handleViewOnClick
}
