import { makeStyles, createStyles } from '@mui/styles'

export const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        marginTop: theme.spacing(3),
      },
    }),
  { name: 'CookiesListFilters' },
)
