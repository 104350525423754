import { FC, MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import { useQueryClient } from 'react-query'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { ApplicationDTO } from 'interfaces/applications/application'
import { SENSITIVE_APPLICATION_STATUSES } from 'interfaces/applications/applicationStatus'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { FormMode } from 'interfaces/formModes/formMode'
import { RoutesManager } from 'utils/routing/routesManager'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'
import { ApplicationDeleteModal } from 'pages/consentAndRights/applications/modals/ApplicationDeleteModal'
import { ApplicationExportCodeModal } from 'pages/consentAndRights/applications/modals/ApplicationExportCodeModal'
import { ApplicationManageDeploymentModal } from 'pages/consentAndRights/applications/modals/manageDeployment/ApplicationManageDeploymentModal'

type Props = {
  application: ApplicationDTO
}

export const ApplicationsListDropdown: FC<Props> = ({ application }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [{ isEditConfirmationOpen, isManageDeploymentOpen, isExportCodeOpen, isDeleteOpen }, setState] = useSetState({
    isEditConfirmationOpen: false,
    isManageDeploymentOpen: false,
    isExportCodeOpen: false,
    isDeleteOpen: false,
  })
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()

  const canManageApp = isPermitted(PERMISSIONS.PROPERTY_WRITE)
  const isInSensitiveStatus = SENSITIVE_APPLICATION_STATUSES.includes(application.status)

  const refetchProperty = () => queryClient.refetchQueries(ApiQueryKeys.applicationsPaginated)

  const handleClickListItem = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    navigate(
      RoutesManager.deployment.applications.upsert.root.getURL({ code: application.code, formMode: FormMode.EDIT }),
    )
  }

  return (
    <div>
      <Button size="medium" variant="iconSubtle" onClick={handleClickListItem}>
        <Icon name="OMore" />
      </Button>

      <ActionSheetWrapper anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} transitionDuration={0}>
        {canManageApp && (
          <div>
            <ActionSheetItem
              onClick={() => {
                if (isInSensitiveStatus) {
                  setState({ isEditConfirmationOpen: true })
                } else {
                  handleEdit()
                }
                handleClose()
              }}
            >
              Edit
            </ActionSheetItem>
            <ActionSheetItem divider />
            <ActionSheetItem
              onClick={() => {
                setState({ isManageDeploymentOpen: true })
                handleClose()
              }}
            >
              Manage Deployment
            </ActionSheetItem>
            <ActionSheetItem divider />
            <ActionSheetItem
              onClick={() => {
                setState({ isExportCodeOpen: true })
                handleClose()
              }}
            >
              Export Code
            </ActionSheetItem>
          </div>
        )}
        {!isInSensitiveStatus && (
          <div>
            <ActionSheetItem divider />
            <ActionSheetItem
              onClick={() => {
                setState({ isDeleteOpen: true })
                handleClose()
              }}
            >
              Delete
            </ActionSheetItem>
          </div>
        )}
      </ActionSheetWrapper>

      {isEditConfirmationOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.APPLICATION}
          onSubmit={handleEdit}
          onCancel={() => {
            setState({ isEditConfirmationOpen: false })
          }}
        />
      )}

      {isManageDeploymentOpen && (
        <ApplicationManageDeploymentModal
          application={application}
          onSuccess={async () => {
            await refetchProperty()
            setState({ isManageDeploymentOpen: false })
          }}
          onClose={() => {
            setState({ isManageDeploymentOpen: false })
          }}
        />
      )}

      {isExportCodeOpen && (
        <ApplicationExportCodeModal
          application={application}
          onClose={() => {
            setState({ isExportCodeOpen: false })
          }}
        />
      )}

      {isDeleteOpen && (
        <ApplicationDeleteModal
          application={application}
          onDelete={refetchProperty}
          onClose={() => {
            setState({ isDeleteOpen: false })
          }}
        />
      )}
    </div>
  )
}
