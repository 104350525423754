import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { AssessmentDocumentDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  data: AssessmentDocumentDTO
}>

export const createAssessment = ({ data }: Params) => {
  return API.post<AssessmentDocumentDTO>(
    formatRequestString({
      entityUrl: `/api/resources/assessments`,
    }),
    data,
  )
}
