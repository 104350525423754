import React from 'react'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useFormikContext } from 'formik'

import { Label } from 'components/ui-kit/form/common/label/Label'
import { ASSET_TYPE_ENUM, ASSET_TYPE_NAMES } from 'interfaces/assets'
import { LoadingOptionsSpinner } from '.'
import { useAutocompleteStyles, useResourceSearchOptions } from '../hooks'
import { getAutocompleteNameError } from '../../utils'
import { FilterOptionsState } from '@mui/material/useAutocomplete'

type Props = {
  //
  index: number
  handleAutocompleteNamedOnChange: any
  valueKey: string
  filterOptions?: (options: string[], state: FilterOptionsState<string>) => string[]
  labelName?: string
  placeholder?: string
}

export const AutocompleteNameInput: React.FC<Props> = ({
  index,
  handleAutocompleteNamedOnChange,
  valueKey,
  filterOptions,
  labelName,
  placeholder,
}) => {
  const autocompleteClasses = useAutocompleteStyles()
  const { resourceNameOptions, setResourceOptions, isLoadingResources, setSearchValue } = useResourceSearchOptions()
  const { values, errors /* touched, setFieldTouched */ }: any = useFormikContext()

  return (
    <>
      {labelName && <Label>{labelName}</Label>}
      {!labelName && <Label>Named</Label>}
      <Autocomplete
        popupIcon={null}
        autoComplete={false}
        multiple
        filterOptions={filterOptions ? filterOptions : undefined}
        classes={{ inputRoot: autocompleteClasses.inputRoot }}
        disabled={values?.[valueKey]?.[index].criteria === ''}
        id={`accessAllowances[${index}].labelName`}
        loading={isLoadingResources}
        loadingText={<LoadingOptionsSpinner />}
        onClose={() => {
          setResourceOptions([])
        }}
        onChange={(e, v) => {
          const typeEnum =
            values?.[valueKey]?.[index]?.assetType ===
            ASSET_TYPE_NAMES[ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATABASE]
              ? ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATABASE
              : ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATASET
          handleAutocompleteNamedOnChange(e, v, typeEnum, index)
          setResourceOptions([])
        }}
        options={resourceNameOptions}
        renderTags={(value: string[]) => value?.[0] || ''}
        getOptionLabel={option => option}
        getOptionDisabled={() => Object.keys(values?.[valueKey]?.[index]?.labelName || {}).length > 0}
        renderOption={(props, option) => (
          <li {...props}>
            <Box display="flex" alignItems="center" className={autocompleteClasses.selectedValueTag}>
              {option}
            </Box>
          </li>
        )}
        onInputChange={(_, newInputValue) => {
          const typeEnum =
            values?.[valueKey]?.[index]?.assetType ===
            ASSET_TYPE_NAMES[ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATABASE]
              ? ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATABASE
              : ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATASET
          if (valueKey === 'attributeEffects' || valueKey === 'filters') {
            setSearchValue(newInputValue, ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_ATTRIBUTE)
          } else {
            setSearchValue(newInputValue, typeEnum)
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={placeholder}
            helperText={errors?.[valueKey]?.[index]?.labelName || errors?.[valueKey]?.[index]?.name || null}
            error={getAutocompleteNameError({ errors, index, valueKey })}
            inputProps={{
              ...params.inputProps,
              form: {
                autoComplete: 'off',
              },
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        )}
      />
    </>
  )
}
