import * as Yup from 'yup'
import { createEditor, Editor, Element, Range, Transforms, Node, BaseEditor } from 'slate'
import { withReact, ReactEditor, useSlate } from 'slate-react'
import { withHistory } from 'slate-history'
import { LinkUrlType } from '../constants'
import { CustomEditor, CustomElement, CustomText, LinkElement, LinkElementFormValues } from '../interfaces'

declare module 'slate' {
  export interface CustomTypes {
    Editor: CustomEditor
    Element: CustomElement
    Text: CustomText
  }
}

function withLinks<T extends BaseEditor>(editor: T) {
  const { isInline } = editor

  editor.isInline = element => {
    return element.type === 'link' || isInline(element)
  }

  return editor
}

export const createEditorInstance = () => withLinks(withHistory(withReact(createEditor()))) as CustomEditor

export const useSlateInstance = () => useSlate() as CustomEditor

export const isLinkActive = (editor: CustomEditor) => {
  const [link] = Editor.nodes(editor, {
    match: node => {
      return !Editor.isEditor(node) && Element.isElement(node) && (node as CustomElement).type === 'link'
    },
  })

  return !!link
}

export const unwrapLink = (editor: CustomEditor) => {
  Transforms.unwrapNodes(editor, {
    match: node => !Editor.isEditor(node) && Element.isElement(node) && (node as CustomElement).type === 'link',
  })
}

export const wrapLink = ({ editor, url, text }: { editor: CustomEditor; url: string; text?: string }) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)

  const link = {
    type: 'link' as const,
    url,
    children: isCollapsed ? [{ text: text! }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })

    if (text) {
      Transforms.insertText(editor, text)
    }

    Transforms.collapse(editor, { edge: 'end' })
  }
}

export const focusEditor = (editor: CustomEditor) => {
  if (!editor.selection) {
    // Set selection to the end editor point
    Transforms.select(editor, Editor.end(editor, []))
  }

  ReactEditor.focus(editor)
}

export const getInitialValues = ({
  isEditMode,
  element,
  editor,
}: {
  isEditMode: boolean
  element?: Element
  editor: CustomEditor
}): LinkElementFormValues => {
  if (isEditMode) {
    const url = (element as LinkElement)!.url as string
    const isCustomLink = url !== LinkUrlType.PRIVACY_POLICY && url !== LinkUrlType.TERMS_OF_SERVICE

    return {
      linkText: Node.string(element!),
      linkUrl: (isCustomLink ? LinkUrlType.CUSTOM : url) as LinkUrlType,
      customUrl: isCustomLink ? url : '',
    }
  }

  return {
    linkText: editor.selection ? Editor.string(editor, editor.selection) : '',
    linkUrl: LinkUrlType.PRIVACY_POLICY,
    customUrl: '',
  }
}

export const validationSchema = Yup.object().shape({
  linkText: Yup.string().trim().required('Required'),
  customUrl: Yup.string()
    .trim()
    .when('linkUrl', {
      is: LinkUrlType.CUSTOM,
      then: Yup.string().url('Invalid url').required('Required'),
    }),
})
