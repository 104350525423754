import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { TableCell } from '@ketch-com/deck'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { Typography } from '@mui/material'
import { FormCookiePurposesCheckbox } from './FormCookiePurposesCheckbox'

export const cookiePurposesGridColumns: GridColDef<PurposeDTO>[] = [
  {
    field: 'used',
    headerName: '',
    sortable: false,
    maxWidth: 50,
    renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) => {
      return <FormCookiePurposesCheckbox row={row} />
    },
  },
  {
    align: 'left',
    field: 'name',
    headerAlign: 'left',
    headerName: 'Name',
    width: 250,
    flex: 0.3,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) => {
      return <TableCell title={row.name} subTitle={row.code} sx={{ margin: '8px 0' }}></TableCell>
    },
  },
  {
    align: 'left',
    field: 'description',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Description',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<PurposeDTO>) => {
      return row.description ? (
        <TableCell sx={{ margin: '8px 0' }} title={row.description} />
      ) : (
        <TableCell>
          <Typography color={'lightGrey.main'} variant="body">
            None
          </Typography>
        </TableCell>
      )
    },
  },
]
