import { Typography } from '@mui/material'
import React from 'react'

import { getContrastColor } from '../utils/getContrastColor'
import { Chip } from '@ketch-com/deck'

type Props = {
  contrastBackgroundColor: string
}

export const GpcBadge: React.FC<Props> = ({ contrastBackgroundColor }) => {
  const textContrastColor = getContrastColor(contrastBackgroundColor, '#000000', '#FFFFFF')
  const backgroundContrastColor = getContrastColor(contrastBackgroundColor, '#071a241a', '#FFFFFF3D')

  return (
    <>
      <Chip
        size="small"
        sx={{ background: backgroundContrastColor }}
        label={
          <Typography variant={'smallLabel'} color={textContrastColor}>
            GPC
          </Typography>
        }
      />
    </>
  )
}
