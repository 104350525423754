import { useMemo } from 'react'
import * as yup from 'yup'
import {
  BannerContainerLayout,
  BannerContainerPosition,
  BannerContainerSize,
  ExitButtonPosition,
  ItemStyle,
  ListLayout,
  ModalContainerPosition,
} from '@ketch-sdk/ketch-types'
import {
  BannerThemeBuilderSection,
  ModalThemeBuilderSection,
  PreferenceThemeBuilderSection,
  ThemeBuilderSection,
} from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'

export const themeUpsertFormKeys = {
  themeName: 'name',
  themeType: 'type',

  bannerContainerLayout: 'content.banner.container.layout',
  bannerContainerSize: 'content.banner.container.size',
  bannerContainerPosition: 'content.banner.container.position',
  bannerContainerBackgroundColor: 'content.banner.container.background.color',
  bannerContainerCornerRadius: 'content.banner.container.cornerRadius',
  bannerContainerBackdropVisible: 'content.banner.container.backdrop.visible',
  bannerContainerBackdropBackgroundColor: 'content.banner.container.backdrop.background.color',
  bannerContainerBackdropBackgroundOpacity: 'content.banner.container.backdrop.background.opacity',
  bannerContainerBackdropDisableInteractions: 'content.banner.container.backdrop.disableContentInteractions',

  bannerHeaderTitleColor: 'content.banner.header.title.color',
  bannerHeaderReturnButtonFillColor: 'content.banner.header.returnButton.background.color',
  bannerHeaderReturnButtonIconColor: 'content.banner.header.returnButton.icon.color',
  bannerHeaderReturnButtonCornerRadius: 'content.banner.header.returnButton.cornerRadius',

  bannerDescriptionTextColor: 'content.banner.description.text.color',
  bannerDescriptionLinkColor: 'content.banner.description.link.color',
  bannerDescriptionUnderlineLinks: 'content.banner.description.link.underline',

  bannerPrimaryButton: 'content.banner.buttons.primary',
  bannerPrimaryButtonStyle: 'content.banner.buttons.primary.style',
  bannerPrimaryButtonBackgroundColor: 'content.banner.buttons.primary.background.color',
  bannerPrimaryButtonOutlineColor: 'content.banner.buttons.primary.outline.color',
  bannerPrimaryButtonTextColor: 'content.banner.buttons.primary.text.color',
  bannerPrimaryButtonCornerRadius: 'content.banner.buttons.primary.cornerRadius',
  bannerSecondaryButton: 'content.banner.buttons.secondary',
  bannerSecondaryButtonStyle: 'content.banner.buttons.secondary.style',
  bannerSecondaryButtonBackgroundColor: 'content.banner.buttons.secondary.background.color',
  bannerSecondaryButtonOutlineColor: 'content.banner.buttons.secondary.outline.color',
  bannerSecondaryButtonTextColor: 'content.banner.buttons.secondary.text.color',
  bannerSecondaryButtonCornerRadius: 'content.banner.buttons.secondary.cornerRadius',
  bannerTertiaryButton: 'content.banner.buttons.tertiary',
  bannerTertiaryButtonStyle: 'content.banner.buttons.tertiary.style',
  bannerTertiaryButtonBackgroundColor: 'content.banner.buttons.tertiary.background.color',
  bannerTertiaryButtonOutlineColor: 'content.banner.buttons.tertiary.outline.color',
  bannerTertiaryButtonTextColor: 'content.banner.buttons.tertiary.text.color',
  bannerTertiaryButtonCornerRadius: 'content.banner.buttons.tertiary.cornerRadius',

  bannerFooterKetchBadgeVisible: 'content.banner.footer.ketchBadgeVisible',

  modalContainerPosition: 'content.modal.container.position',
  modalContainerBackgroundColor: 'content.modal.container.background.color',
  modalContainerCornerRadius: 'content.modal.container.cornerRadius',
  modalContainerBackdropVisible: 'content.modal.container.backdrop.visible',
  modalContainerBackdropBackgroundColor: 'content.modal.container.backdrop.background.color',
  modalContainerBackdropBackgroundOpacity: 'content.modal.container.backdrop.background.opacity',
  modalContainerBackdropDisableInteractions: 'content.modal.container.backdrop.disableContentInteractions',

  modalHeaderTitleColor: 'content.modal.header.title.color',
  modalHeaderBackgroundColor: 'content.modal.header.background.color',
  modalHeaderReturnButtonFillColor: 'content.modal.header.returnButton.background.color',
  modalHeaderReturnButtonOutlineColor: 'content.modal.header.returnButton.outline.color',
  modalHeaderReturnButtonIconColor: 'content.modal.header.returnButton.icon.color',
  modalHeaderReturnButtonCornerRadius: 'content.modal.header.returnButton.cornerRadius',

  modalDescriptionTitleColor: 'content.modal.description.title.color',
  modalDescriptionTextColor: 'content.modal.description.text.color',
  modalDescriptionLinkColor: 'content.modal.description.link.color',
  modalDescriptionUnderlineLinks: 'content.modal.description.link.underline',

  modalListTitleColor: 'content.modal.purposeListHeader.title.color',
  modalAcceptAllButtonStyle: 'content.modal.purposeListHeader.acceptAllButton.style',
  modalAcceptAllButtonBackgroundColor: 'content.modal.purposeListHeader.acceptAllButton.background.color',
  modalAcceptAllButtonOutlineColor: 'content.modal.purposeListHeader.acceptAllButton.outline.color',
  modalAcceptAllButtonTextColor: 'content.modal.purposeListHeader.acceptAllButton.text.color',
  modalAcceptAllButtonCornerRadius: 'content.modal.purposeListHeader.acceptAllButton.cornerRadius',
  modalRejectAllButtonStyle: 'content.modal.purposeListHeader.rejectAllButton.style',
  modalRejectAllButtonBackgroundColor: 'content.modal.purposeListHeader.rejectAllButton.background.color',
  modalRejectAllButtonOutlineColor: 'content.modal.purposeListHeader.rejectAllButton.outline.color',
  modalRejectAllButtonTextColor: 'content.modal.purposeListHeader.rejectAllButton.text.color',
  modalRejectAllButtonCornerRadius: 'content.modal.purposeListHeader.rejectAllButton.cornerRadius',

  modalPurposeListLayout: 'content.modal.purposeList.purposeListItems.layout',
  modalPurposeListStyle: 'content.modal.purposeList.purposeListItems.style',
  modalPurposeListItemFillColor: 'content.modal.purposeList.purposeListItems.purposeFill.color',
  modalPurposeListItemOutlineColor: 'content.modal.purposeList.purposeListItems.purposeOutline.color',
  modalPurposeListItemContentColor: 'content.modal.purposeList.purposeListItems.purposeContent.color',
  modalPurposeListItemLinkColor: 'content.modal.purposeList.purposeListItems.purposeLinks.color',
  modalPurposeListItemArrowIconColor: 'content.modal.purposeList.purposeListItems.arrowIcon.color',
  modalPurposeListItemCornerRadius: 'content.modal.purposeList.purposeListItems.purposeCornerRadius',
  modalPurposeListSwitchButtonsOnBackgroundColor: 'content.modal.purposeList.switchButtons.on.background.color',
  modalPurposeListSwitchButtonsOnTextColor: 'content.modal.purposeList.switchButtons.on.text.color',
  modalPurposeListSwitchButtonsOffBackgroundColor: 'content.modal.purposeList.switchButtons.off.background.color',
  modalPurposeListSwitchButtonsOffTextColor: 'content.modal.purposeList.switchButtons.off.text.color',

  modalFooterBackgroundColor: 'content.modal.footer.background.color',
  modalFooterActionButtonStyle: 'content.modal.footer.actionButton.style',
  modalFooterActionButtonBackgroundColor: 'content.modal.footer.actionButton.background.color',
  modalFooterActionButtonOutlineColor: 'content.modal.footer.actionButton.outline.color',
  modalFooterActionButtonTextColor: 'content.modal.footer.actionButton.text.color',
  modalFooterActionButtonCornerRadius: 'content.modal.footer.actionButton.cornerRadius',
  modalFooterKetchBadgeVisible: 'content.modal.footer.ketchBadgeVisible',

  preferenceWelcomeTabContainerExitButtonPosition: 'content.preference.container.exitPosition',
  preferenceWelcomeTabContainerFont: 'content.preference.container.font',
  preferenceWelcomeTabContainerBackgroundColor: 'content.preference.container.background.color',
  preferenceWelcomeTabHeaderBackgroundColor: 'content.preference.header.background.color',
  preferenceWelcomeTabHeaderTitleColor: 'content.preference.header.title.color',
  preferenceWelcomeTabHeaderLogoUrl: 'content.preference.header.logo.url',
  preferenceWelcomeTabHeaderLogoName: 'content.preference.header.logo.name',
  preferenceWelcomeTabNavigationLayoutBackgroundColor: 'content.preference.navigation.layout.background.color',
  preferenceWelcomeTabNavigationLayoutCornerRadius: 'content.preference.navigation.layout.cornerRadius',
  preferenceWelcomeTabNavigationLayoutItemUnselectedBackgroundColor:
    'content.preference.navigation.layout.item.unselectedBackground.color',
  preferenceWelcomeTabNavigationLayoutItemUnselectedTextColor:
    'content.preference.navigation.layout.item.unselectedText.color',
  preferenceWelcomeTabNavigationLayoutItemSelectedBackgroundColor:
    'content.preference.navigation.layout.item.selectedBackground.color',
  preferenceWelcomeTabNavigationLayoutItemSelectedTextColor:
    'content.preference.navigation.layout.item.selectedText.color',
  preferenceWelcomeTabNavigationLayoutItemCornerRadius: 'content.preference.navigation.layout.item.cornerRadius',
  preferenceWelcomeTabNavigationIconsVisible: 'content.preference.navigation.iconsVisible',
  preferenceWelcomeTabNavigationWelcomeIconUrl: 'content.preference.navigation.icons.welcome.url',
  preferenceWelcomeTabNavigationPrivacyPolicyIconUrl: 'content.preference.navigation.icons.privacyPolicy.url',
  preferenceWelcomeTabNavigationPurposesIconUrl: 'content.preference.navigation.icons.purposes.url',
  preferenceWelcomeTabNavigationSubscriptionsIconUrl: 'content.preference.navigation.icons.subscriptions.url',
  preferenceWelcomeTabNavigationRequestsIconUrl: 'content.preference.navigation.icons.requests.url',
  preferenceWelcomeTabExitButtonBackgroundColor: 'content.preference.exitButton.background.color',
  preferenceWelcomeTabExitButtonTextColor: 'content.preference.exitButton.text.color',
  preferenceWelcomeTabExitButtonCornerRadius: 'content.preference.exitButton.cornerRadius',
  preferenceWelcomeTabExitButtonIconVisible: 'content.preference.exitButton.iconVisible',
  preferenceWelcomeTabExitButtonUseDefaultIcon: 'content.preference.exitButton.useDefaultIcon',
  preferenceWelcomeTabExitButtonIconColor: 'content.preference.exitButton.iconColor.color',
  preferenceWelcomeTabExitButtonIconUrl: 'content.preference.exitButton.iconUrl',
  preferenceWelcomeTabExitButtonIconName: 'content.preference.exitButton.iconName',
  preferenceWelcomeTabHeroBannerVisible: 'content.preference.tabs.welcome.heroBanner.visible',
  preferenceWelcomeTabHeroBannerImageUrl: 'content.preference.tabs.welcome.heroBanner.image.url',
  preferenceWelcomeTabHeroBannerImageVisible: 'content.preference.tabs.welcome.heroBanner.image.name',
  preferenceWelcomeTabWelcomeMsgTitleColor: 'content.preference.tabs.welcome.welcomeMsg.title.color',
  preferenceWelcomeTabWelcomeMsgSubtitleColor: 'content.preference.tabs.welcome.welcomeMsg.subtitle.color',
  preferenceWelcomeTabWelcomeMsgLinkColor: 'content.preference.tabs.welcome.welcomeMsg.link.color',
  preferenceWelcomeTabWelcomeMsgLinkUnderline: 'content.preference.tabs.welcome.welcomeMsg.link.underline',
  preferenceWelcomeTabQuickLinksTitleColor: 'content.preference.tabs.welcome.quickLinks.title.color',
  preferenceWelcomeTabQuickLinksLinkColor: 'content.preference.tabs.welcome.quickLinks.link.color',
  preferenceWelcomeTabQuickLinksLinkUnderline: 'content.preference.tabs.welcome.quickLinks.link.underline',
  preferenceWelcomeTabQuickLinksShowArrows: 'content.preference.tabs.welcome.quickLinks.showArrows',
  preferenceWelcomeTabAboutTitleColor: 'content.preference.tabs.welcome.about.title.color',
  preferenceWelcomeTabAboutTextColor: 'content.preference.tabs.welcome.about.text.color',
  preferenceWelcomeTabAboutLinkColor: 'content.preference.tabs.welcome.about.link.color',
  preferenceWelcomeTabAboutLinkUnderline: 'content.preference.tabs.welcome.about.link.underline',
  preferenceWelcomeTabFooterShowKetchBadge: 'content.preference.ketchBadgeVisible',
  preferencePurposesTabHeaderTitleColor: 'content.preference.tabs.purposes.header.title.color',
  preferencePurposesTabHeaderDescriptionColor: 'content.preference.tabs.purposes.header.description.color',
  preferencePurposesTabHeaderLinkColor: 'content.preference.tabs.purposes.header.link.color',
  preferencePurposesTabHeaderLinkUnderline: 'content.preference.tabs.purposes.header.link.underline',
  preferencePurposesTabListHeaderTitleColor: 'content.preference.tabs.purposes.purposeListHeader.title.color',
  preferencePurposesTabListHeaderAcceptAllButtonStyle:
    'content.preference.tabs.purposes.purposeListHeader.acceptAllButton.style',
  preferencePurposesTabListHeaderAcceptAllButtonBackgroundColor:
    'content.preference.tabs.purposes.purposeListHeader.acceptAllButton.background.color',
  preferencePurposesTabListHeaderAcceptAllButtonOutlineColor:
    'content.preference.tabs.purposes.purposeListHeader.acceptAllButton.outline.color',
  preferencePurposesTabListHeaderAcceptAllButtonTextColor:
    'content.preference.tabs.purposes.purposeListHeader.acceptAllButton.text.color',
  preferencePurposesTabListHeaderAcceptAllButtonCornerRadius:
    'content.preference.tabs.purposes.purposeListHeader.acceptAllButton.cornerRadius',
  preferencePurposesTabListHeaderRejectAllButtonStyle:
    'content.preference.tabs.purposes.purposeListHeader.rejectAllButton.style',
  preferencePurposesTabListHeaderRejectAllButtonBackgroundColor:
    'content.preference.tabs.purposes.purposeListHeader.rejectAllButton.background.color',
  preferencePurposesTabListHeaderRejectAllButtonOutlineColor:
    'content.preference.tabs.purposes.purposeListHeader.rejectAllButton.outline.color',
  preferencePurposesTabListHeaderRejectAllButtonTextColor:
    'content.preference.tabs.purposes.purposeListHeader.rejectAllButton.text.color',
  preferencePurposesTabListHeaderRejectAllButtonCornerRadius:
    'content.preference.tabs.purposes.purposeListHeader.rejectAllButton.cornerRadius',
  preferencePurposesTabListItemsLayout: 'content.preference.tabs.purposes.purposeList.purposeListItems.layout',
  preferencePurposesTabListItemsStyle: 'content.preference.tabs.purposes.purposeList.purposeListItems.style',
  preferencePurposesTabListItemsPurposeFillColor:
    'content.preference.tabs.purposes.purposeList.purposeListItems.purposeFill.color',
  preferencePurposesTabListItemsPurposeOutlineColor:
    'content.preference.tabs.purposes.purposeList.purposeListItems.purposeOutline.color',
  preferencePurposesTabListItemsPurposeContentColor:
    'content.preference.tabs.purposes.purposeList.purposeListItems.purposeContent.color',
  preferencePurposesTabListItemsPurposeLinksColor:
    'content.preference.tabs.purposes.purposeList.purposeListItems.purposeLinks.color',
  preferencePurposesTabListItemsArrowIconColor:
    'content.preference.tabs.purposes.purposeList.purposeListItems.arrowIcon.color',
  preferencePurposesTabListItemsPurposeCornerRadius:
    'content.preference.tabs.purposes.purposeList.purposeListItems.purposeCornerRadius',
  preferencePurposesTabListSwitchButtons: 'content.preference.tabs.purposes.purposeList.switchButtons',
  preferencePurposesTabListSwitchButtonsOnBackgroundColor:
    'content.preference.tabs.purposes.purposeList.switchButtons.on.background.color',
  preferencePurposesTabListSwitchButtonsOnTextColor:
    'content.preference.tabs.purposes.purposeList.switchButtons.on.text.color',
  preferencePurposesTabListSwitchButtonsOffBackgroundColor:
    'content.preference.tabs.purposes.purposeList.switchButtons.off.background.color',
  preferencePurposesTabListSwitchButtonsOffTextColor:
    'content.preference.tabs.purposes.purposeList.switchButtons.off.text.color',
  preferencePurposesTabFooterBackgroundColor: 'content.preference.tabs.purposes.footer.background.color',
  preferencePurposesTabFooterActionButtonStyle: 'content.preference.tabs.purposes.footer.actionButton.style',
  preferencePurposesTabFooterActionButtonBackgroundColor:
    'content.preference.tabs.purposes.footer.actionButton.background.color',
  preferencePurposesTabFooterActionButtonOutlineColor:
    'content.preference.tabs.purposes.footer.actionButton.outline.color',
  preferencePurposesTabFooterActionButtonTextColor: 'content.preference.tabs.purposes.footer.actionButton.text.color',
  preferencePurposesTabFooterActionButtonCornerRadius:
    'content.preference.tabs.purposes.footer.actionButton.cornerRadius',
  preferenceSubscriptionsTabHeaderTitleColor: 'content.preference.tabs.subscriptions.header.title.color',
  preferenceSubscriptionsTabHeaderDescriptionColor: 'content.preference.tabs.subscriptions.header.description.color',
  preferenceSubscriptionsTabHeaderLinkColor: 'content.preference.tabs.subscriptions.header.link.color',
  preferenceSubscriptionsTabHeaderLinkUnderline: 'content.preference.tabs.subscriptions.header.link.underline',
  preferenceSubscriptionsTabUnsubscribeAllPosition: 'content.preference.tabs.subscriptions.unsubscribeAll.position',
  preferenceSubscriptionsTabUnsubscribeAllBackgroundColor:
    'content.preference.tabs.subscriptions.unsubscribeAll.background.color',
  preferenceSubscriptionsTabUnsubscribeAllTextColor: 'content.preference.tabs.subscriptions.unsubscribeAll.text.color',
  preferenceSubscriptionsTabUnsubscribeAllCornerRadius:
    'content.preference.tabs.subscriptions.unsubscribeAll.cornerRadius',
  preferenceSubscriptionsTabUnsubscribeAllSwitchButton:
    'content.preference.tabs.subscriptions.unsubscribeAll.switchButton',
  preferenceSubscriptionsTabUnsubscribeAllSwitchButtonOnTextColor:
    'content.preference.tabs.subscriptions.unsubscribeAll.switchButton.on.text.color',
  preferenceSubscriptionsTabUnsubscribeAllSwitchButtonOnBackgroundColor:
    'content.preference.tabs.subscriptions.unsubscribeAll.switchButton.on.background.color',
  preferenceSubscriptionsTabUnsubscribeAllSwitchButtonOffTextColor:
    'content.preference.tabs.subscriptions.unsubscribeAll.switchButton.off.text.color',
  preferenceSubscriptionsTabUnsubscribeAllSwitchButtonOffBackgroundColor:
    'content.preference.tabs.subscriptions.unsubscribeAll.switchButton.off.background.color',
  preferenceSubscriptionsTabListStyle: 'content.preference.tabs.subscriptions.list.layout.style',
  preferenceSubscriptionsTabListBackgroundColor: 'content.preference.tabs.subscriptions.list.layout.background.color',
  preferenceSubscriptionsTabListTextColor: 'content.preference.tabs.subscriptions.list.layout.text.color',
  preferenceSubscriptionsTabListLinkColor: 'content.preference.tabs.subscriptions.list.layout.link.color',
  preferenceSubscriptionsTabListCornerRadius: 'content.preference.tabs.subscriptions.list.layout.cornerRadius',
  preferenceSubscriptionsTabListSwitchButton: 'content.preference.tabs.subscriptions.list.switchButton',
  preferenceSubscriptionsTabListSwitchButtonOnBackgroundColor:
    'content.preference.tabs.subscriptions.list.switchButton.on.background.color',
  preferenceSubscriptionsTabListSwitchButtonOnTextColor:
    'content.preference.tabs.subscriptions.list.switchButton.on.text.color',
  preferenceSubscriptionsTabListSwitchButtonOffBackgroundColor:
    'content.preference.tabs.subscriptions.list.switchButton.off.background.color',
  preferenceSubscriptionsTabListSwitchButtonOffTextColor:
    'content.preference.tabs.subscriptions.list.switchButton.off.text.color',
  preferenceSubscriptionsTabListCheckbox: 'content.preference.tabs.subscriptions.list.checkbox',
  preferenceSubscriptionsTabListCheckboxSelectedBackgroundColor:
    'content.preference.tabs.subscriptions.list.checkbox.selected.background.color',
  preferenceSubscriptionsTabListCheckboxSelectedTextColor:
    'content.preference.tabs.subscriptions.list.checkbox.selected.label.color',
  preferenceSubscriptionsTabListCheckboxUnselectedBackgroundColor:
    'content.preference.tabs.subscriptions.list.checkbox.unselected.background.color',
  preferenceSubscriptionsTabListCheckboxUnselectedTextColor:
    'content.preference.tabs.subscriptions.list.checkbox.unselected.label.color',
  preferenceSubscriptionsTabFooterBackgroundColor: 'content.preference.tabs.subscriptions.footer.background.color',
  preferenceSubscriptionsTabFooterActionButtonStyle: 'content.preference.tabs.subscriptions.footer.actionButton.style',
  preferenceSubscriptionsTabFooterActionButtonBackgroundColor:
    'content.preference.tabs.subscriptions.footer.actionButton.background.color',
  preferenceSubscriptionsTabFooterActionButtonOutlineColor:
    'content.preference.tabs.subscriptions.footer.actionButton.outline.color',
  preferenceSubscriptionsTabFooterActionButtonTextColor:
    'content.preference.tabs.subscriptions.footer.actionButton.text.color',
  preferenceSubscriptionsTabFooterActionButtonCornerRadius:
    'content.preference.tabs.subscriptions.footer.actionButton.cornerRadius',
  preferenceRequestsTabHomeHeaderTitleColor: 'content.preference.tabs.requests.home.header.title.color',
  preferenceRequestsTabHomeHeaderDescriptionColor: 'content.preference.tabs.requests.home.header.description.color',
  preferenceRequestsTabHomeHeaderLinkColor: 'content.preference.tabs.requests.home.header.link.color',
  preferenceRequestsTabHomeHeaderLinkUnderline: 'content.preference.tabs.requests.home.header.link.underline',
  preferenceRequestsTabHomeReturnButtonBackground:
    'content.preference.tabs.requests.home.header.returnButton.background.color',
  preferenceRequestsTabHomeReturnButtonIcon: 'content.preference.tabs.requests.home.header.returnButton.icon.color',
  preferenceRequestsTabHomeReturnButtonCornerRadius:
    'content.preference.tabs.requests.home.header.returnButton.cornerRadius',
  preferenceRequestsTabHomeDsrPortalLinkTitleColor: 'content.preference.tabs.requests.home.dsrPortalLink.title.color',
  preferenceRequestsTabHomeDsrPortalLinkDescriptionColor:
    'content.preference.tabs.requests.home.dsrPortalLink.description.color',
  preferenceRequestsTabHomeDsrPortalLinkArrowIcon:
    'content.preference.tabs.requests.home.dsrPortalLink.arrowIcon.color',
  preferenceRequestsTabHomeDsrPortalLinkBackground:
    'content.preference.tabs.requests.home.dsrPortalLink.background.color',
  preferenceRequestsTabHomeDsrPortalLinkCornerRadius:
    'content.preference.tabs.requests.home.dsrPortalLink.cornerRadius',
  preferenceRequestsTabHomeRightsListTitleColor: 'content.preference.tabs.requests.home.rightsList.title.color',
  preferenceRequestsTabHomeRightsListItemTitleColor:
    'content.preference.tabs.requests.home.rightsList.item.title.color',
  preferenceRequestsTabHomeRightsListItemSubtitleColor:
    'content.preference.tabs.requests.home.rightsList.item.subtitle.color',
  preferenceRequestsTabHomeRightsListItemArrowIcon:
    'content.preference.tabs.requests.home.rightsList.item.arrowIcon.color',
  preferenceRequestsTabHomeRightsListItemBackground:
    'content.preference.tabs.requests.home.rightsList.item.background.color',
  preferenceRequestsTabHomeRightsListItemCornerRadius:
    'content.preference.tabs.requests.home.rightsList.item.cornerRadius',
  preferenceRequestsTabFormHeaderBackground: 'content.preference.tabs.requests.rightForm.header.background.color',
  preferenceRequestsTabFormHeaderTitleColor: 'content.preference.tabs.requests.rightForm.header.title.color',
  preferenceRequestsTabFormHeaderTitleUnderline: 'content.preference.tabs.requests.rightForm.header.title.underline',
  preferenceRequestsTabFormHeaderReturnButtonBackgroundColor:
    'content.preference.tabs.requests.rightForm.header.returnButton.background.color',
  preferenceRequestsTabFormHeaderReturnButtonIconColor:
    'content.preference.tabs.requests.rightForm.header.returnButton.icon.color',
  preferenceRequestsTabFormHeaderReturnButtonCornerRadius:
    'content.preference.tabs.requests.rightForm.header.returnButton.cornerRadius',
  preferenceRequestsTabFormDividersTitleColor: 'content.preference.tabs.requests.rightForm.form.dividers.title.color',
  preferenceRequestsTabFormDividersSubtitleColor:
    'content.preference.tabs.requests.rightForm.form.dividers.subtitle.color',
  preferenceRequestsTabFormFieldsStyle: 'content.preference.tabs.requests.rightForm.form.fields.style',
  preferenceRequestsTabFormFieldsActiveBackground:
    'content.preference.tabs.requests.rightForm.form.fields.active.background.color',
  preferenceRequestsTabFormFieldsActiveOutline:
    'content.preference.tabs.requests.rightForm.form.fields.active.outline.color',
  preferenceRequestsTabFormFieldsInactiveBackground:
    'content.preference.tabs.requests.rightForm.form.fields.inactive.background.color',
  preferenceRequestsTabFormFieldsInactiveOutline:
    'content.preference.tabs.requests.rightForm.form.fields.inactive.outline.color',
  preferenceRequestsTabFormFieldsErrorBackground:
    'content.preference.tabs.requests.rightForm.form.fields.error.background.color',
  preferenceRequestsTabFormFieldsErrorOutline:
    'content.preference.tabs.requests.rightForm.form.fields.error.outline.color',
  preferenceRequestsTabFormFieldsCornerRadius: 'content.preference.tabs.requests.rightForm.form.fields.cornerRadius',
  preferenceRequestsTabFormFieldsLabelColor: 'content.preference.tabs.requests.rightForm.form.fields.fieldLabel.color',
  preferenceRequestsTabFormFieldsHintTextColor: 'content.preference.tabs.requests.rightForm.form.fields.hintText.color',
  preferenceRequestsTabFormFieldsInputTextColor:
    'content.preference.tabs.requests.rightForm.form.fields.inputText.color',
  preferenceRequestsTabFormCheckboxes: 'content.preference.tabs.requests.rightForm.form.checkboxes',
  preferenceRequestsTabFormCheckboxesSelectedBackgroundColor:
    'content.preference.tabs.requests.rightForm.form.checkboxes.selected.background.color',
  preferenceRequestsTabFormCheckboxesSelectedLabelColor:
    'content.preference.tabs.requests.rightForm.form.checkboxes.selected.label.color',
  preferenceRequestsTabFormCheckboxesUnselectedBackgroundColor:
    'content.preference.tabs.requests.rightForm.form.checkboxes.unselected.background.color',
  preferenceRequestsTabFormCheckboxesUnselectedLabelColor:
    'content.preference.tabs.requests.rightForm.form.checkboxes.unselected.label.color',
  preferenceRequestsTabFormCheckboxesErrorBackgroundColor:
    'content.preference.tabs.requests.rightForm.form.checkboxes.error.background.color',
  preferenceRequestsTabFormCheckboxesErrorLabelColor:
    'content.preference.tabs.requests.rightForm.form.checkboxes.error.label.color',
  preferenceRequestsTabFormActionButtonStyle: 'content.preference.tabs.requests.rightForm.actionButton.style',
  preferenceRequestsTabFormActionButtonBackground:
    'content.preference.tabs.requests.rightForm.actionButton.background.color',
  preferenceRequestsTabFormActionButtonOutline: 'content.preference.tabs.requests.rightForm.actionButton.outline.color',
  preferenceRequestsTabFormActionButtonTextColor: 'content.preference.tabs.requests.rightForm.actionButton.text.color',
  preferenceRequestsTabFormActionButtonIcon: 'content.preference.tabs.requests.rightForm.actionButton.icon.color',
  preferenceRequestsTabFormActionButtonCornerRadius:
    'content.preference.tabs.requests.rightForm.actionButton.cornerRadius',
  preferenceRequestsTabSubmittedHeaderTitleColor: 'content.preference.tabs.requests.submitted.header.title.color',
  preferenceRequestsTabSubmittedHeaderReturnButtonBackgroundColor:
    'content.preference.tabs.requests.submitted.header.returnButton.background.color',
  preferenceRequestsTabSubmittedHeaderReturnButtonIconColor:
    'content.preference.tabs.requests.submitted.header.returnButton.icon.color',
  preferenceRequestsTabSubmittedHeaderReturnButtonCornerRadius:
    'content.preference.tabs.requests.submitted.header.returnButton.cornerRadius',
  preferenceRequestsTabSubmittedBannerVisible: 'content.preference.tabs.requests.submitted.banner.visible',
  preferenceRequestsTabSubmittedBannerImageUrl: 'content.preference.tabs.requests.submitted.banner.image.url',
  preferenceRequestsTabSubmittedTextSubtitleColor: 'content.preference.tabs.requests.submitted.text.title.color',
  preferenceRequestsTabSubmittedTextDescriptionColor:
    'content.preference.tabs.requests.submitted.text.description.color',
  preferenceRequestsTabSubmittedFooterBackgroundColor:
    'content.preference.tabs.requests.submitted.footer.background.color',
  preferenceRequestsTabSubmittedFooterActionButtonStyle:
    'content.preference.tabs.requests.submitted.footer.actionButton.style',
  preferenceRequestsTabSubmittedFooterActionButtonBackgroundColor:
    'content.preference.tabs.requests.submitted.footer.actionButton.background.color',
  preferenceRequestsTabSubmittedFooterActionButtonOutlineColor:
    'content.preference.tabs.requests.submitted.footer.actionButton.outline.color',
  preferenceRequestsTabSubmittedFooterActionButtonTextColor:
    'content.preference.tabs.requests.submitted.footer.actionButton.text.color',
  preferenceRequestsTabSubmittedFooterActionButtonIconColor:
    'content.preference.tabs.requests.submitted.footer.actionButton.icon.color',
  preferenceRequestsTabSubmittedFooterActionButtonCornerRadius:
    'content.preference.tabs.requests.submitted.footer.actionButton.cornerRadius',

  // Reset keys - Banner (used by the "Reset to Default" button in each form)
  resetBannerContainer: 'content.banner.container',
  resetBannerHeader: 'content.banner.header',
  resetBannerButtons: 'content.banner.buttons',
  resetBannerDescription: 'content.banner.description',

  // Reset keys - Modal
  resetModalContainer: 'content.modal.container',
  resetModalHeader: 'content.modal.header',
  resetModalDescription: 'content.modal.description',
  resetModalListHeader: 'content.modal.purposeListHeader',
  resetModalPurposeList: 'content.modal.purposeList',
  resetModalFooter: 'content.modal.footer',

  // Reset keys - Preference
  resetPreferenceWelcomeTabContainer: 'content.preference.container',
  resetPreferenceWelcomeTabHeader: 'content.preference.header',
  resetPreferenceWelcomeTabNavigation: 'content.preference.navigation',
  resetPreferenceWelcomeTabExitButton: 'content.preference.exitButton',
  resetPreferenceWelcomeTabHeroBanner: 'content.preference.tabs.welcome.heroBanner',
  resetPreferenceWelcomeTabWelcomeMsg: 'content.preference.tabs.welcome.welcomeMsg',
  resetPreferenceWelcomeTabQuickLinks: 'content.preference.tabs.welcome.quickLinks',
  resetPreferenceWelcomeTabAbout: 'content.preference.tabs.welcome.about',
  resetPreferencePurposesTabHeader: 'content.preference.tabs.purposes.header',
  resetPreferencePurposesTabListHeader: 'content.preference.tabs.purposes.purposeListHeader',
  resetPreferencePurposesTabList: 'content.preference.tabs.purposes.purposeList',
  resetPreferencePurposesTabFooter: 'content.preference.tabs.purposes.footer',
  resetPreferenceSubscriptionsTabHeader: 'content.preference.tabs.subscriptions.header',
  resetPreferenceSubscriptionsTabUnsubscribeAll: 'content.preference.tabs.subscriptions.unsubscribeAll',
  resetPreferenceSubscriptionsTabList: 'content.preference.tabs.subscriptions.list',
  resetPreferenceSubscriptionsTabFooter: 'content.preference.tabs.subscriptions.footer',
  resetPreferenceRequestsTabHomeHeader: 'content.preference.tabs.requests.home.header',
  resetPreferenceRequestsTabHomeDsrLink: 'content.preference.tabs.requests.home.dsrPortalLink',
  resetPreferenceRequestsTabHomeRightsList: 'content.preference.tabs.requests.home.rightsList',
  resetPreferenceRequestsTabFormHeader: 'content.preference.tabs.requests.rightForm.header',
  resetPreferenceRequestsTabFormContent: 'content.preference.tabs.requests.rightForm.form',
  resetPreferenceRequestsTabFormActionButton: 'content.preference.tabs.requests.rightForm.actionButton',
  resetPreferenceRequestsTabSubmittedHeader: 'content.preference.tabs.requests.submitted.header',
  resetPreferenceRequestsTabSubmittedBanner: 'content.preference.tabs.requests.submitted.banner',
  resetPreferenceRequestsTabSubmittedText: 'content.preference.tabs.requests.submitted.text',
  resetPreferenceRequestsTabSubmittedFooter: 'content.preference.tabs.requests.submitted.footer',
} as const

// Section that the builder should jump to when there is a formik error at that key
export const errorSections: { [errorFormKey: string]: ThemeBuilderSection } = {
  [themeUpsertFormKeys.bannerPrimaryButtonCornerRadius]: BannerThemeBuilderSection.ActionButtons,
  [themeUpsertFormKeys.bannerSecondaryButtonCornerRadius]: BannerThemeBuilderSection.ActionButtonsSecondary,
  [themeUpsertFormKeys.bannerTertiaryButtonCornerRadius]: BannerThemeBuilderSection.ActionButtonsTertiary,
  [themeUpsertFormKeys.bannerContainerCornerRadius]: BannerThemeBuilderSection.Container,
  [themeUpsertFormKeys.bannerHeaderReturnButtonCornerRadius]: BannerThemeBuilderSection.Header,

  [themeUpsertFormKeys.modalHeaderReturnButtonCornerRadius]: ModalThemeBuilderSection.Header,
  [themeUpsertFormKeys.modalAcceptAllButtonCornerRadius]: ModalThemeBuilderSection.ListHeaderAcceptAll,
  [themeUpsertFormKeys.modalRejectAllButtonCornerRadius]: ModalThemeBuilderSection.ListHeaderRejectAll,
  [themeUpsertFormKeys.modalPurposeListItemCornerRadius]: ModalThemeBuilderSection.PurposeList,

  [themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutCornerRadius]:
    PreferenceThemeBuilderSection.WelcomeTabNavigation,
  [themeUpsertFormKeys.preferenceWelcomeTabNavigationLayoutItemCornerRadius]:
    PreferenceThemeBuilderSection.WelcomeTabNavigation,
  [themeUpsertFormKeys.preferencePurposesTabListHeaderAcceptAllButtonCornerRadius]:
    PreferenceThemeBuilderSection.PurposesTabListHeaderAcceptAll,
  [themeUpsertFormKeys.preferencePurposesTabListHeaderRejectAllButtonCornerRadius]:
    PreferenceThemeBuilderSection.PurposesTabListHeaderRejectAll,
  [themeUpsertFormKeys.preferencePurposesTabListItemsPurposeCornerRadius]:
    PreferenceThemeBuilderSection.PurposesTabList,

  [themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllCornerRadius]:
    PreferenceThemeBuilderSection.SubscriptionsTabUnsubscribeAll,
  [themeUpsertFormKeys.preferenceSubscriptionsTabListCornerRadius]: PreferenceThemeBuilderSection.SubscriptionsTabList,

  [themeUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkCornerRadius]:
    PreferenceThemeBuilderSection.RequestsHomeDsrLink,
  [themeUpsertFormKeys.preferenceRequestsTabHomeRightsListItemCornerRadius]:
    PreferenceThemeBuilderSection.RequestsHomeRightsList,
  [themeUpsertFormKeys.preferenceRequestsTabFormHeaderReturnButtonCornerRadius]:
    PreferenceThemeBuilderSection.RequestsFormHeader,
  [themeUpsertFormKeys.preferenceRequestsTabFormFieldsCornerRadius]:
    PreferenceThemeBuilderSection.RequestsFormContentFields,
  [themeUpsertFormKeys.preferenceRequestsTabSubmittedHeaderReturnButtonCornerRadius]:
    PreferenceThemeBuilderSection.RequestsSubmittedHeader,
}

// Helper validation schemas for reusable types
const colorThemeConfigSchema = yup.object({
  color: yup
    .string()
    .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8}|[A-Fa-f0-9]{3})$/, 'Invalid hex color format')
    .nullable(),
  opacity: yup
    .number()
    .min(0, 'Opacity must be a decimal between 0 and 100')
    .max(100, 'Opacity must be a decimal between 0 and 100')
    .nullable(),
})

const textThemeConfigSchema = yup.object({
  color: yup
    .string()
    .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8}|[A-Fa-f0-9]{3})$/, 'Invalid hex color format')
    .nullable(),
  opacity: yup
    .number()
    .min(0, 'Opacity must be a decimal between 0 and 1')
    .max(1, 'Opacity must be a decimal between 0 and 1')
    .nullable(),
  underline: yup.boolean().nullable(),
})

const backdropThemeConfigSchema = yup.object({
  visible: yup.boolean().nullable(),
  background: colorThemeConfigSchema.nullable(),
  disableContentInteractions: yup.boolean().nullable(),
})

const returnButtonThemeConfigSchema = yup.object({
  background: colorThemeConfigSchema.nullable(),
  icon: colorThemeConfigSchema.nullable(),
  cornerRadius: yup
    .number()
    .min(0, 'Corner radius must be greater than 0')
    .max(36, 'Corner radius must be less than or equal to 36')
    .nullable(),
})

const headerThemeConfigSchema = yup.object({
  background: colorThemeConfigSchema.nullable(),
  title: textThemeConfigSchema.nullable(),
  returnButton: returnButtonThemeConfigSchema.nullable(),
})

const checkboxThemeConfigSchema = yup.object({
  background: colorThemeConfigSchema.nullable(),
  label: colorThemeConfigSchema.nullable(),
})

// CheckboxesThemeConfig validation schema
const checkboxesThemeConfigSchema = yup.object({
  selected: checkboxThemeConfigSchema.nullable(),
  unselected: checkboxThemeConfigSchema.nullable(),
})

const actionButtonThemeConfigSchema = yup.object({
  style: yup.mixed().oneOf(Object.values(ItemStyle)).nullable(),
  background: colorThemeConfigSchema.nullable(),
  text: textThemeConfigSchema.nullable(),
  icon: colorThemeConfigSchema.nullable(),
  cornerRadius: yup
    .number()
    .min(0, 'Corner radius must be greater than 0')
    .max(36, 'Corner radius must be less than or equal to 36')
    .nullable(),
})

const bannerButtonsThemeConfigSchema = yup.object({
  primary: actionButtonThemeConfigSchema.nullable(),
  secondary: actionButtonThemeConfigSchema.nullable(),
  tertiary: actionButtonThemeConfigSchema.nullable(),
})

const bannerDescriptionThemeConfigSchema = yup.object({
  link: textThemeConfigSchema.nullable(),
  text: textThemeConfigSchema.nullable(),
})

const bannerContainerThemeConfigSchema = yup.object({
  backdrop: backdropThemeConfigSchema.nullable(),
  background: colorThemeConfigSchema.nullable(),
  cornerRadius: yup
    .number()
    .min(0, 'Corner radius must be greater than 0')
    .max(36, 'Corner radius must be less than or equal to 36')
    .nullable(),
  position: yup.mixed().oneOf(Object.values(BannerContainerPosition)).nullable(),
  layout: yup.mixed().oneOf(Object.values(BannerContainerLayout)).nullable(),
  size: yup.mixed().oneOf(Object.values(BannerContainerSize)).nullable(),
  font: yup.string().nullable(),
})

// BannerThemeConfig validation schema
const bannerThemeConfigSchema = yup.object({
  container: bannerContainerThemeConfigSchema.nullable(),
  header: headerThemeConfigSchema.nullable(),
  description: bannerDescriptionThemeConfigSchema.nullable(),
  buttons: bannerButtonsThemeConfigSchema.nullable(),
})

const modalContainerThemeConfigSchema = yup.object({
  position: yup.mixed().oneOf(Object.values(ModalContainerPosition)).nullable(),
  font: yup.string().nullable(),
  background: colorThemeConfigSchema.nullable(),
  cornerRadius: yup
    .number()
    .min(0, 'Corner radius must be greater than 0')
    .max(36, 'Corner radius must be less than or equal to 36')
    .nullable(),
  backdrop: backdropThemeConfigSchema.nullable(),
})

const modalDescriptionThemeConfigSchema = yup.object({
  title: textThemeConfigSchema.nullable(),
  text: textThemeConfigSchema.nullable(),
  link: textThemeConfigSchema.nullable(),
})

const purposeListHeaderThemeConfigSchema = yup.object({
  title: textThemeConfigSchema.nullable(),
  acceptAllButton: actionButtonThemeConfigSchema.nullable(),
  rejectAllButton: actionButtonThemeConfigSchema.nullable(),
})

// Additional schemas for ModalThemeConfig specific types
const footerThemeConfigSchema = yup.object({
  background: colorThemeConfigSchema.nullable(),
  actionButton: actionButtonThemeConfigSchema.nullable(),
})

const pageTextThemeConfigSchema = yup.object({
  title: textThemeConfigSchema.nullable(),
  description: textThemeConfigSchema.nullable(),
  link: textThemeConfigSchema.nullable(),
})

const listItemsThemeConfigSchema = yup.object({
  layout: yup.mixed().oneOf(Object.values(ListLayout)).nullable(),
  style: yup.mixed().oneOf(Object.values(ItemStyle)).nullable(),
  purposeFill: colorThemeConfigSchema.nullable(),
  purposeContent: colorThemeConfigSchema.nullable(),
  purposeLinks: colorThemeConfigSchema.nullable(),
  arrowIcon: colorThemeConfigSchema.nullable(),
  purposeCornerRadius: yup
    .number()
    .min(0, 'Corner radius must be greater than 0')
    .max(36, 'Corner radius must be less than or equal to 36')
    .nullable(),
})

const switchButtonThemeConfigSchema = yup.object({
  background: colorThemeConfigSchema.nullable(),
  text: textThemeConfigSchema.nullable(),
})

const switchButtonsThemeConfigSchema = yup.object({
  on: switchButtonThemeConfigSchema.nullable(),
  off: switchButtonThemeConfigSchema.nullable(),
})

const purposeListThemeConfigSchema = yup.object({
  purposeListItems: listItemsThemeConfigSchema.nullable(),
  switchButtons: switchButtonsThemeConfigSchema.nullable(),
})

// ModalThemeConfig validation schema
const modalThemeConfigSchema = yup.object({
  container: modalContainerThemeConfigSchema.nullable(),
  header: headerThemeConfigSchema.nullable(),
  description: modalDescriptionThemeConfigSchema.nullable(),
  purposeListHeader: purposeListHeaderThemeConfigSchema.nullable(),
  purposeList: purposeListThemeConfigSchema.nullable(),
  footer: footerThemeConfigSchema.nullable(),
})

const welcomeTabContainerThemeConfigSchema = yup.object({
  exitPosition: yup.mixed().oneOf(Object.values(ExitButtonPosition)).nullable(),
  font: yup.string().nullable(),
  background: colorThemeConfigSchema.nullable(),
})

const imageThemeConfigSchema = yup.object({
  name: yup.string().nullable(),
  url: yup.string().url().nullable(),
})

const welcomeTabHeaderThemeConfigSchema = yup.object({
  background: colorThemeConfigSchema.nullable(),
  title: textThemeConfigSchema.nullable(),
  logo: imageThemeConfigSchema.nullable(),
})

const navigationLayoutItemThemeConfigSchema = yup.object({
  unselectedBackground: colorThemeConfigSchema.nullable(),
  unselectedText: textThemeConfigSchema.nullable(),
  selectedBackground: colorThemeConfigSchema.nullable(),
  selectedText: textThemeConfigSchema.nullable(),
  cornerRadius: yup
    .number()
    .min(0, 'Corner radius must be greater than 0')
    .max(36, 'Corner radius must be less than or equal to 36')
    .nullable(),
})

const navigationLayoutThemeConfigSchema = yup.object({
  background: colorThemeConfigSchema.nullable(),
  cornerRadius: yup
    .number()
    .min(0, 'Corner radius must be greater than 0')
    .max(36, 'Corner radius must be less than or equal to 36')
    .nullable(),
  item: navigationLayoutItemThemeConfigSchema.nullable(),
})

const navigationIconsThemeConfigSchema = yup.object({
  welcome: imageThemeConfigSchema.nullable(),
  privacyPolicy: imageThemeConfigSchema.nullable(),
  purposes: imageThemeConfigSchema.nullable(),
  subscriptions: imageThemeConfigSchema.nullable(),
  requests: imageThemeConfigSchema.nullable(),
})

const welcomeTabNavigationThemeConfigSchema = yup.object({
  layout: navigationLayoutThemeConfigSchema.nullable(),
  iconsVisible: yup.boolean().nullable(),
  icons: navigationIconsThemeConfigSchema.nullable(),
})

const welcomeTabExitButtonThemeConfigSchema = yup.object({
  background: colorThemeConfigSchema.nullable(),
  text: textThemeConfigSchema.nullable(),
  iconVisible: yup.boolean().nullable(),
  useDefaultIcon: yup.boolean().nullable(),
  iconColor: colorThemeConfigSchema.nullable(),
  icon: imageThemeConfigSchema.nullable(),
})

const welcomeTabHeroBannerThemeConfigSchema = yup.object({
  visible: yup.boolean().nullable(),
  image: imageThemeConfigSchema.nullable(),
})

const welcomeTabWelcomeMsgThemeConfigSchema = yup.object({
  title: textThemeConfigSchema.nullable(),
  subtitle: textThemeConfigSchema.nullable(),
  link: textThemeConfigSchema.nullable(),
})

const welcomeTabQuickLinksThemeConfigSchema = yup.object({
  title: textThemeConfigSchema.nullable(),
  link: textThemeConfigSchema.nullable(),
  showArrows: yup.boolean().nullable(),
})

const welcomeTabAboutThemeConfigSchema = yup.object({
  title: textThemeConfigSchema.nullable(),
  text: textThemeConfigSchema.nullable(),
  link: textThemeConfigSchema.nullable(),
})

// PreferenceWelcomeTabThemeConfig validation schema
const preferenceWelcomeTabThemeConfigSchema = yup.object({
  container: welcomeTabContainerThemeConfigSchema.nullable(),
  header: welcomeTabHeaderThemeConfigSchema.nullable(),
  navigation: welcomeTabNavigationThemeConfigSchema.nullable(),
  exitButton: welcomeTabExitButtonThemeConfigSchema.nullable(),
  heroBanner: welcomeTabHeroBannerThemeConfigSchema.nullable(),
  welcomeMsg: welcomeTabWelcomeMsgThemeConfigSchema.nullable(),
  quickLinks: welcomeTabQuickLinksThemeConfigSchema.nullable(),
  about: welcomeTabAboutThemeConfigSchema.nullable(),
})

// PreferencePrivacyPolicyTabThemeConfig validation schema
const preferencePrivacyPolicyTabThemeConfigSchema = yup.object({
  text: textThemeConfigSchema.nullable(),
  link: textThemeConfigSchema.nullable(),
})

// PreferencePurposesTabThemeConfig validation schema
const preferencePurposesTabThemeConfigSchema = yup.object({
  header: pageTextThemeConfigSchema.nullable(),
  purposeListHeader: purposeListHeaderThemeConfigSchema.nullable(),
  purposeList: purposeListThemeConfigSchema.nullable(),
  footer: footerThemeConfigSchema.nullable(),
})

const subscriptionsTabGlobalControlThemeConfigSchema = yup.object({
  background: colorThemeConfigSchema.nullable(),
  text: colorThemeConfigSchema.nullable(),
  cornerRadius: yup
    .number()
    .min(0, 'Corner radius must be greater than 0')
    .max(36, 'Corner radius must be less than or equal to 36')
    .nullable(),
  switchButton: switchButtonsThemeConfigSchema.nullable(),
})

const layoutThemeConfigSchema = yup.object({
  style: yup.string().nullable(), // Change to ItemStyle enum if needed
  background: colorThemeConfigSchema.nullable(),
  text: textThemeConfigSchema.nullable(),
  link: textThemeConfigSchema.nullable(),
  cornerRadius: yup
    .number()
    .min(0, 'Corner radius must be greater than 0')
    .max(36, 'Corner radius must be less than or equal to 36')
    .nullable(),
})

const subscriptionsTabListThemeConfigSchema = yup.object({
  layout: layoutThemeConfigSchema.nullable(),
  switchButton: switchButtonsThemeConfigSchema.nullable(),
  checkbox: checkboxesThemeConfigSchema.nullable(),
})

// PreferenceSubscriptionsTabThemeConfig validation schema
const preferenceSubscriptionsTabThemeConfigSchema = yup.object({
  header: pageTextThemeConfigSchema.nullable(),
  unsubscribeAll: subscriptionsTabGlobalControlThemeConfigSchema.nullable(),
  list: subscriptionsTabListThemeConfigSchema.nullable(),
  footer: footerThemeConfigSchema.nullable(),
})

const requestsTabDsrLinkThemeConfigSchema = yup.object({
  title: textThemeConfigSchema.nullable(),
  description: textThemeConfigSchema.nullable(),
  arrowIcon: colorThemeConfigSchema.nullable(),
  background: colorThemeConfigSchema.nullable(),
  cornerRadius: yup
    .number()
    .min(0, 'Corner radius must be greater than 0')
    .max(36, 'Corner radius must be less than or equal to 36')
    .nullable(),
})

const rightsListItemThemeConfigSchema = yup.object({
  title: textThemeConfigSchema.nullable(),
  subtitle: textThemeConfigSchema.nullable(),
  arrowIcon: colorThemeConfigSchema.nullable(),
  background: colorThemeConfigSchema.nullable(),
  cornerRadius: yup
    .number()
    .min(0, 'Corner radius must be greater than 0')
    .max(36, 'Corner radius must be less than or equal to 36')
    .nullable(),
})

// RequestsTabRightsListThemeConfig validation schema
const requestsTabRightsListThemeConfigSchema = yup.object({
  title: textThemeConfigSchema.nullable(),
  item: rightsListItemThemeConfigSchema.nullable(),
})

const requestsTabHomeThemeConfigSchema = yup.object({
  header: headerThemeConfigSchema.nullable(),
  dsrPortalLink: requestsTabDsrLinkThemeConfigSchema.nullable(),
  rightsList: requestsTabRightsListThemeConfigSchema.nullable(),
})

const formDividersThemeConfigSchema = yup.object({
  title: textThemeConfigSchema.nullable(),
  subtitle: textThemeConfigSchema.nullable(),
})

const fieldThemeConfigSchema = yup.object({
  background: colorThemeConfigSchema.nullable(),
  outline: colorThemeConfigSchema.nullable(),
})

const formFieldsThemeConfigSchema = yup.object({
  style: yup.string().nullable(), // Change to FormFieldStyle enum if needed
  active: fieldThemeConfigSchema.nullable(),
  inactive: fieldThemeConfigSchema.nullable(),
  cornerRadius: yup
    .number()
    .min(0, 'Corner radius must be greater than 0')
    .max(36, 'Corner radius must be less than or equal to 36')
    .nullable(),
  fieldLabel: textThemeConfigSchema.nullable(),
  hintText: textThemeConfigSchema.nullable(),
  inputText: textThemeConfigSchema.nullable(),
})

const rightFormThemeConfigSchema = yup.object({
  dividers: formDividersThemeConfigSchema.nullable(),
  fields: formFieldsThemeConfigSchema.nullable(),
  checkboxes: checkboxesThemeConfigSchema.nullable(),
  actionButton: actionButtonThemeConfigSchema.nullable(),
})

const requestsTabRightFormThemeConfigSchema = yup.object({
  header: headerThemeConfigSchema.nullable(),
  form: rightFormThemeConfigSchema.nullable(),
})

const submittedRequestThemeConfigSchema = yup.object({
  bannerVisible: yup.boolean().nullable(),
  bannerImage: imageThemeConfigSchema.nullable(),
  title: textThemeConfigSchema.nullable(),
  description: textThemeConfigSchema.nullable(),
})

const requestsTabSubmittedThemeConfigSchema = yup.object({
  header: headerThemeConfigSchema.nullable(),
  summary: submittedRequestThemeConfigSchema.nullable(),
})

// PreferenceRequestsTabThemeConfig validation schema
const preferenceRequestsTabThemeConfigSchema = yup.object({
  home: requestsTabHomeThemeConfigSchema.nullable(),
  rightForm: requestsTabRightFormThemeConfigSchema.nullable(),
  submitted: requestsTabSubmittedThemeConfigSchema.nullable(),
})

const preferenceNavigationItemThemeConfigSchema = yup.object({
  cornerRadius: yup
    .number()
    .min(0, 'Corner radius must be greater than 0')
    .max(24, 'Corner radius must be less than or equal to 24')
    .nullable(),
})

const preferenceNavigationLayoutThemeConfigSchema = yup.object({
  item: preferenceNavigationItemThemeConfigSchema,
  cornerRadius: yup
    .number()
    .min(0, 'Corner radius must be greater than 0')
    .max(24, 'Corner radius must be less than or equal to 24')
    .nullable(),
})

const preferenceNavigationThemeConfigSchema = yup.object({
  layout: preferenceNavigationLayoutThemeConfigSchema,
})

const preferenceTabsThemeConfigSchema = yup.object({
  welcome: preferenceWelcomeTabThemeConfigSchema,
  privacyPolicy: preferencePrivacyPolicyTabThemeConfigSchema,
  purposes: preferencePurposesTabThemeConfigSchema,
  subscriptions: preferenceSubscriptionsTabThemeConfigSchema,
  requests: preferenceRequestsTabThemeConfigSchema,
})

const preferenceThemeConfigSchema = yup.object({
  navigation: preferenceNavigationThemeConfigSchema,
  tabs: preferenceTabsThemeConfigSchema,
})

export const useThemeValidationSchema = () => {
  return useMemo(() => {
    return yup.object().shape({
      name: yup.string().required('Please enter a name'),
      content: yup.object().shape({
        banner: bannerThemeConfigSchema,
        modal: modalThemeConfigSchema,
        preference: preferenceThemeConfigSchema,
      }),
    })
  }, [])
}
