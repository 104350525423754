import React, { useEffect } from 'react'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { Navigate } from 'react-router-dom'

type Props = {}

export const RedirectWithToast: React.FC<Props> = props => {
  console.error(`Route does not exist: ${window.location.href}`)
  useEffect(() => {
    showToast({ content: `The page that you were trying to access doesn't exist`, type: 'error' })
  }, [])

  return <Navigate to="/" />
}
