import React, { useState } from 'react'

import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { useIsEntitled, useIsPermitted } from 'utils/hooks'
import { DeleteAppModal } from 'pages/dataSystems/components/Modals/DeleteAppModal'

import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { Avatar, AvatarSize, Card, Chip, Icon, theme } from '@ketch-com/deck'
import {
  inherentRiskLevelColors,
  inherentRiskLevelOptions,
  PersonalDataTypeOptions,
} from 'pages/dataSystems/DataSystem/types'
import { InstalledDataSystemV2ListItemDTO } from '@ketch-com/figurehead'
import { OwnerAvatarColors } from './hooks/useAppListItems'
import { ProgressIndicator } from 'pages/policyCenter/assessments/view/components/ProgressIndicator'
import { dataSystemProgressIndicatorStyles } from 'pages/dataSystems/DataSystem/constants'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

type SystemListItemProps = {
  app: InstalledDataSystemV2ListItemDTO
  ownerAvatarColors: OwnerAvatarColors
}

interface OwnerName {
  name?: string
  initials?: string
}

export const SystemsListItem: React.FC<SystemListItemProps> = ({ app, ownerAvatarColors }) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)

  const navigate = useNavigate()

  const handleAppClick = (id: string) => {
    navigate(RoutesManager.systems.id.overview.root.getURL({ id }))
  }

  const hasApp = app.dataSystem?.code

  // TODO - KC: remove menu item till we get the data
  const { isEntitled } = useIsEntitled()
  const isEntitledToDemo = isEntitled(ENTITLEMENTS.DEMO)
  const isEntitledToDataMap = isEntitled(ENTITLEMENTS.DATA_MAPPING)

  const { isPermitted } = useIsPermitted()

  const isDataSystemsWrite = isPermitted([PERMISSIONS.DATA_SYSTEMS_WRITE, PERMISSIONS.INTEGRATIONS_WRITE])

  // TODO - KC: remove menu item till we get the data
  // const isEntitledToCreateConnection = isEntitled(
  //   app?.dataSystem?.systemType === 'Internal'
  //     ? ENTITLEMENTS.QTY_SYSTEM_INTEGRATIONS_INTERNAL
  //     : ENTITLEMENTS.QTY_SYSTEM_INTEGRATIONS_EXTERNAL,
  // )
  // const filteredCapabilities = app?.dataSystem?.filteredCapabilities ?? []
  // const hasCapabilities = !!filteredCapabilities?.length

  const hasConnections = !!app.connectionCount

  const progressPercentage =
    app.progress?.filled && app.progress.total
      ? Math.round((app.progress.filled / app.progress.total) * 100)
      : undefined

  const displayProgressIndicator = progressPercentage && progressPercentage !== 100

  let items: {
    content: string
    onClick: () => void
    disabled?: boolean
  }[] = [
    {
      content: 'View',
      onClick: () => handleAppClick(app?.id!),
    },
  ]

  // TODO - KC: remove menu item till we get the data
  // if (hasApp && isDataSystemsWrite) {
  //   items.push({
  //     content: 'Add Connection',
  //     onClick: () =>
  //       navigate(RoutesManager.systems.connections.upsert.root.getURL({ id: app?.id, instanceId: NEW_ITEM_ID })),
  //     disabled: !isEntitledToCreateConnection || !hasCapabilities || !hasConnections,
  //   })
  // }

  if (isDataSystemsWrite) {
    items.push({
      content: 'Delete',
      onClick: () => setIsDeleteOpen(true),
      disabled: hasConnections,
    })
  }

  const cardMenuItems: {
    label: string
    onClick: () => void
  }[] = [
    {
      label: 'View',
      onClick: () => handleAppClick(app?.id!),
    },
  ]
  if (isDataSystemsWrite && !hasConnections) {
    cardMenuItems.push({
      label: 'Delete',
      onClick: () => setIsDeleteOpen(true),
    })
  }

  const ownerName: OwnerName = { name: undefined, initials: undefined }
  if (app.ownerUsers?.[0]) {
    ownerName.name = `${app.ownerUsers[0].firstName} ${app.ownerUsers[0].lastName}`
    ownerName.initials = `${app.ownerUsers[0].firstName?.charAt(0)}${app.ownerUsers[0].lastName?.charAt(0)}`
  } else if (app.ownerTeams?.[0]) {
    ownerName.name = `${app.ownerTeams[0].description}`
    ownerName.initials = `${app.ownerTeams[0].description?.charAt(0)}`.toLocaleUpperCase()
  }

  const inherentRiskColors = app.inherentRisk ? inherentRiskLevelColors[app.inherentRisk] : undefined
  const inherentRiskLabel = app.inherentRisk ? inherentRiskLevelOptions[app.inherentRisk].title : ''

  const connectionIconName = app.connectionCount
    ? app.connectionIssueCount
      ? 'OConnectionIssue'
      : 'OConnectionTrue'
    : 'OConnectionFalse'
  const connectionColor = app.connectionCount
    ? app.connectionIssueCount
      ? theme.palette.Warning.Primary
      : theme.palette.Success.Primary
    : theme.palette.Text.Secondary
  const connectionChipColor = app.connectionCount
    ? app.connectionIssueCount
      ? theme.palette.Warning.Disabled
      : theme.palette.Success.Disabled
    : theme.palette.Black.o8

  return (
    <>
      <Card
        sx={{
          cursor: 'pointer',
          '& .DeckCardAvatarContent .DeckCardAvatarFooter': {
            marginTop: 'auto !important',
          },
        }}
        onClick={e => {
          handleAppClick(app?.id!)
          e.stopPropagation()
        }}
        cardVariant="avatar"
        cardTitle={app.dataSystem?.name || 'None'}
        avatarComponent={
          displayProgressIndicator ? (
            <Box display="flex" justifyContent="space-between" flexGrow={2}>
              <Avatar
                src={app.dataSystem?.logoUrl}
                alt={app.dataSystem?.name}
                variant="rounded"
                size={AvatarSize['2xlarge']}
                borderColor={theme.palette.tertiary.main}
                isLogo
              />
              {isEntitledToDataMap && (
                <Box padding={0.25}>
                  <ProgressIndicator
                    percent={progressPercentage}
                    width={24}
                    style={dataSystemProgressIndicatorStyles}
                  />
                </Box>
              )}
            </Box>
          ) : (
            <Avatar
              src={app.dataSystem?.logoUrl}
              alt={app.dataSystem?.name}
              variant="rounded"
              size={AvatarSize['2xlarge']}
              borderColor={theme.palette.tertiary.main}
              isLogo
            />
          )
        }
        moreMenuItems={cardMenuItems}
        cardContent={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              mb: 2.25,
            }}
          >
            {hasApp && (
              <>
                <Box padding={0.5} display="flex" flexDirection="column" paddingY={2} gap={0.5}>
                  <Typography variant="fadedLabel">Connections</Typography>
                  <Box display="flex" alignItems="center" gap={0.25}>
                    <Chip
                      size="small"
                      icon={<Icon name={connectionIconName} iconColor={connectionColor} />}
                      sx={{ backgroundColor: connectionChipColor, width: 'fit-content' }}
                      label={
                        <Typography variant="smallLabel" color={connectionColor}>
                          {app.connectionCount ?? 'None'}
                        </Typography>
                      }
                    />
                    {app.connectionIssueCount && <Icon name="OWarning" iconColor={theme.palette.Warning.Primary} />}
                  </Box>
                </Box>
                <Box padding={0.5} display="flex" flexDirection="column" paddingY={1} gap={0.5}>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography variant="fadedLabel">Owner</Typography>
                    {ownerName.name ? (
                      <Box display="flex" gap={0.5} alignItems="center">
                        <Avatar
                          backgroundColor={ownerAvatarColors.backgroundColor}
                          variant="circular"
                          size={AvatarSize.small}
                        >
                          <Typography variant="smallFootnote" color={ownerAvatarColors.color}>
                            {ownerName.initials}
                          </Typography>
                        </Avatar>
                        <Typography variant="smallLabel">{ownerName.name}</Typography>
                      </Box>
                    ) : (
                      <Typography variant="smallBody" color={theme.palette.Text.Secondary}>
                        Not Assigned
                      </Typography>
                    )}
                  </Box>
                </Box>
                {isEntitledToDataMap && (
                  <Box padding={0.5} display="flex" flexDirection="column" paddingTop={2} gap={0.5}>
                    <Typography variant="fadedLabel">Data Sensitivity</Typography>
                    <Chip
                      size="small"
                      sx={{ backgroundColor: theme.palette.Black.o8, width: 'fit-content' }}
                      label={
                        <Typography variant="smallLabel">
                          {PersonalDataTypeOptions[app.personalDataType || '0'].title}
                        </Typography>
                      }
                    />
                  </Box>
                )}
                {isEntitledToDataMap && isEntitledToDemo && app.inherentRisk && (
                  <Box padding={0.5} display="flex" flexDirection="column" paddingTop={2} gap={0.5}>
                    <Typography variant="fadedLabel">Inherent Risk</Typography>
                    <Chip
                      label={<Typography variant="smallLabel">{inherentRiskLabel}</Typography>}
                      size="small"
                      sx={{
                        color: inherentRiskColors?.color,
                        backgroundColor: inherentRiskColors?.background,
                        width: 'fit-content',
                        '&:hover': {
                          backgroundColor: inherentRiskColors?.background,
                          cursor: 'auto',
                        },
                      }}
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        }
      />
      {isDeleteOpen && (
        <DeleteAppModal
          appId={app?.id || ''}
          onCancel={() => {
            setIsDeleteOpen(false)
          }}
          onSubmit={() => {
            navigate(RoutesManager.systems.systemsHome.root.getURL())
          }}
        />
      )}
    </>
  )
}
