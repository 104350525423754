import { addDays, differenceInCalendarDays, format } from 'date-fns'
import { TranslatorFunction } from 'i18n/types'
import { TrendPermitStatsDTO } from '@ketch-com/figurehead'
import { ChartTrendPeriod } from 'interfaces/rights/rightStats'

export type ReshapedDataForChart = {
  name: string
  monthDateName: string
  noConsent: number
  noConsentRequired: number
  consent: number
  overallLine: number
  consentLine: number
  isFirstInNewMonth: boolean
  year: string
  fullDayName: string
  dateOfMonth: string
  monthName: string
  monthNameYearAbbreviation: string
}

// function to create an array of dates between two dates
const getDaysArray = function (s: string, e: string) {
  const numberOfDaysRequired = Math.abs(differenceInCalendarDays(new Date(s), new Date(e))) + 1
  const startDate = new Date(s)
  const daysArray = []

  for (let i = 0; i < numberOfDaysRequired; i++) {
    const date = addDays(startDate, i)
    daysArray.push(date)
  }

  return daysArray
}

export const getReshapedDataForChart = (
  trends: TrendPermitStatsDTO[],
  trendPeriod: ChartTrendPeriod,
  startDate: string,
  endDate: string,
  translate: TranslatorFunction = input => input,
): ReshapedDataForChart[] => {
  const reshapedData = []
  const myDateRange = getDaysArray(startDate, endDate)

  for (let i = 0; i < trends.length; i++) {
    const dayData = trends[i]
    const localElementDate = myDateRange?.[i] || new Date()
    const nameDayOfWeek = translate(format(localElementDate, 'eee'), { ns: 'common' })
    const nameDayOfWeekFull = format(localElementDate, 'EEEE')
    const nameMonth = translate(format(localElementDate, 'LLL'), { ns: 'common' })
    const isFirstInNewMonth = localElementDate.getDate() === 1
    const yearString = localElementDate.getFullYear().toString()

    const payload = {
      name: trendPeriod === ChartTrendPeriod.DAILY ? nameDayOfWeek : nameMonth,
      monthDateName: `${nameMonth}, ${localElementDate.getDate()}`,
      noConsent: dayData.noConsent || 0,
      noConsentRequired: dayData.consentNotRequired || 0,
      consent: dayData.consent || 0,
      overallLine: (dayData?.consent || 0) + (dayData?.consentNotRequired || 0) + (dayData?.noConsent || 0),
      consentLine: (dayData.consent || 0) + (dayData.consentNotRequired || 0),
      isFirstInNewMonth: trendPeriod === ChartTrendPeriod.DAILY ? false : isFirstInNewMonth,
      year: yearString,
      fullDayName: nameDayOfWeekFull,
      dateOfMonth: localElementDate.getDate().toString(),
      monthName: nameMonth,
      monthNameYearAbbreviation: `${nameMonth} '${yearString.slice(-2)}`,
    }
    reshapedData.push(payload)
  }

  return reshapedData
}
