import ClipboardJS from 'clipboard'
import { useEffect } from 'react'

/* This hook enables buttons to copy text to the clipboard
   - Pass a clipboardText prop to any button to copy it on click */
export const useClipboardButtons = () => {
  useEffect(() => {
    new ClipboardJS('.clipboard-copy-button')
  }, [])
}
