import React, { Fragment } from 'react'
import Box from '@mui/material/Box'
import { Waypoint } from 'react-waypoint'

import { AppHeaderNotificationCard } from './AppHeaderNotificationCard'
import { splitNotificationsIntoReadAndUnread } from 'pages/updates/notifications/list/utils'
import { useInfiniteNotifications } from 'api/notifications/queries/useInfiniteNotifications'
import { EmptyState, Spinner, theme } from '@ketch-com/deck'
import { Typography } from '@mui/material'
import { InfiniteScrollLoadingIndicator } from 'components/InfiniteScrollLoadingIndicator'

type Props = {}

export const AppHeaderNotificationsList: React.FC<Props> = () => {
  const {
    isLoading: isLoadingNotifications,
    data: notifications,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteNotifications({
    hasNoTaskId: true,
  })
  const { readNotifications, unreadNotifications } = splitNotificationsIntoReadAndUnread(notifications || [])

  if (isLoadingNotifications)
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" p={5}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )

  if (unreadNotifications.length === 0 && readNotifications.length === 0)
    return (
      <Box p={1.75}>
        <Box borderRadius="11px" py={4} display="flex" flexDirection="column" alignItems="center">
          <EmptyState
            size="small"
            colorTheme={theme.palette.darkDuskFaded.main}
            iconName="OCheckRound2"
            title="All Caught Up!"
            subTitle="There are no notifications that require your attention"
          />
        </Box>
      </Box>
    )

  return (
    <Box pt={1.25}>
      <Box flexDirection="column" display="flex">
        {unreadNotifications.length > 0 && (
          <Typography variant="label" color="darkDuskFaded.main" ml={1.25}>
            New
          </Typography>
        )}
        {unreadNotifications?.map((notification, index) => {
          return (
            <Fragment key={notification.id}>
              <AppHeaderNotificationCard
                isNew
                isFirst={index === 0}
                isLast={index === unreadNotifications.length - 1}
                notification={notification}
              />
            </Fragment>
          )
        })}
      </Box>
      {/* Read Notifications */}
      <Box flexDirection="column" display="flex">
        {readNotifications.length > 0 && (
          <Typography variant="label" color="darkDuskFaded.main" ml={1.25}>
            Recent
          </Typography>
        )}
        {readNotifications?.map((notification, index) => {
          return (
            <Fragment key={notification.id}>
              <AppHeaderNotificationCard
                isNew={false}
                isFirst={index === 0}
                isLast={index === readNotifications.length - 1}
                notification={notification}
              />
            </Fragment>
          )
        })}
      </Box>

      <Waypoint
        onEnter={() => {
          if (hasNextPage) fetchNextPage()
        }}
      />

      <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
    </Box>
  )
}
