import { useDebounce } from 'react-use'
import { useFormikContext } from 'formik'

import { PreferenceExperienceFormValues } from '../interfaces/PreferenceExperienceFormValues'
import { ConsentExperienceFormValues } from '../utils/consent/interfaces'
import { useExperienceBuilderFormStateUtils } from 'store/experienceBuilderFormStateFilterSlice/hooks'

export const PersistFormValuesToReduxStore: React.FC = () => {
  const { setState, values: persistedExpBuilderFormState } = useExperienceBuilderFormStateUtils()
  const { values } = useFormikContext<ConsentExperienceFormValues | PreferenceExperienceFormValues>()

  useDebounce(
    () => {
      const shouldDispatch = JSON.stringify(persistedExpBuilderFormState) !== JSON.stringify(values)
      if (shouldDispatch) setState({ values })
    },
    500,
    [values],
  )

  return null
}
