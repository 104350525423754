import { ContextVariableCategoryDTO, ContextVariableDTO } from '@ketch-com/figurehead'

type Args = {
  contextVariable: ContextVariableDTO
  isEditMode: boolean
}

export const getContextVariablesFormInitialValues = ({ contextVariable, isEditMode }: Args): ContextVariableDTO => {
  if (!isEditMode) {
    return {
      code: '',
      name: '',
      type: 4,
      values: [],
      category: ContextVariableCategoryDTO.DataSubjectContextVariableCategory,
    }
  }

  return {
    values: contextVariable.values || [''],
    ...contextVariable,
  }
}
