import { ConnectionDTO, ResourceTypeDTO } from '@ketch-com/figurehead'
import { groupBy } from 'lodash'

interface FixedCheckbox {
  value: string
  id: string
}

export type FilterConfigItem = {
  id: string
  name: string
  isSearchable: boolean
  tabs: string[]
  fixedCheckboxes: FixedCheckbox[]
}

export type FiltersConfig = FilterConfigItem[]

export const getFiltersConfig = (connections: ConnectionDTO[]) => {
  const allResourceTypes: ResourceTypeDTO[] = []

  for (const connection of connections) {
    const { resourceTypes } = connection

    if (resourceTypes) {
      allResourceTypes.push(...resourceTypes)
    }
  }

  const deDuplicatedResourceTypes = allResourceTypes
    .map(r => {
      return {
        value: r.name as string,
        id: r.code as string,
        technology: (r?.name?.toLowerCase() || '')?.split(' ')?.[0] || '',
      }
    })
    .filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i)

  const checkboxesGroupedByTechnology = groupBy(deDuplicatedResourceTypes, f => f.technology)
  const groupedCheckboxEntries = Object.entries(checkboxesGroupedByTechnology)
  const sortedGroupedCheckboxEntries = groupedCheckboxEntries.sort((a, b) => a[0].localeCompare(b[0]))

  let fixedCheckboxesPayload: FixedCheckbox[] = []
  for (const [, checkboxes] of sortedGroupedCheckboxEntries) {
    fixedCheckboxesPayload = [...fixedCheckboxesPayload, ...checkboxes]
  }

  const payload: FiltersConfig = [
    {
      id: 'type',
      name: 'Type',
      isSearchable: false,
      tabs: [],
      fixedCheckboxes: fixedCheckboxesPayload,
    },
  ]

  return payload
}
