import { ApiQueryKeys } from 'api/common/queryKeys'
import { NotificationDTO } from '@ketch-com/figurehead'
import { useInfiniteQuery } from 'react-query'
import { fetchNotifications } from '../fetchers/fetchNotifications'

const limit = 100

export const useInfiniteNotifications = ({
  hasNoTaskId,
  hasTaskId,
}: {
  hasNoTaskId?: boolean
  hasTaskId?: boolean
}) => {
  const resp = useInfiniteQuery({
    queryKey: [ApiQueryKeys.notificationsInfinite, { hasNoTaskId, hasTaskId }],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchCookiesResults = await fetchNotifications({
        includeMetadata: true,
        limit,
        start: (pageParam - 1) * limit,
        organizationCode: 'test',
        hasNoTaskId,
        hasTaskId,
      })

      const { data } = fetchCookiesResults

      data.notifications = data.notifications ?? []
      data.totalResults = data.totalResults ?? 0

      return data
    },
    getNextPageParam: ({ totalResults }, allPages) => {
      let totalNotificationsFetchedSoFar = 0
      allPages?.forEach?.(pageOfResults => {
        totalNotificationsFetchedSoFar += pageOfResults?.notifications?.length || 0
      })
      const nextPageParam = Math.ceil(totalNotificationsFetchedSoFar / limit) + 1

      return totalNotificationsFetchedSoFar === totalResults ? undefined : nextPageParam
    },
    refetchInterval: 1000 * 30 * 1, // 30 seconds
  })

  let infiniteNotifications: NotificationDTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    infiniteNotifications = [...infiniteNotifications, ...(page?.notifications || [])]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: infiniteNotifications,
    totalResults,
  }
}
