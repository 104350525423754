import { createContext } from 'react'
import { TrackersWithNewPurposesUtils } from 'pages/policyCenter/trackers/interfaces'
import { useTrackersWithNewPurposesUtils } from 'pages/policyCenter/trackers/hooks'

export const TrackersWithNewPurposesContext = createContext({} as TrackersWithNewPurposesUtils)

type Props = {
  children?: React.ReactNode
}

export const TrackersWithNewPurposesContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useTrackersWithNewPurposesUtils()

  return (
    <TrackersWithNewPurposesContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </TrackersWithNewPurposesContext.Provider>
  )
}

export const withTrackersWithNewPurposesContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <TrackersWithNewPurposesContextProvider>
        <Component {...props} />
      </TrackersWithNewPurposesContextProvider>
    )
  }
}
