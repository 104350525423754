import { createContext } from 'react'
import { ApprovedTrackersUtils } from 'pages/policyCenter/trackers/interfaces'
import { useApprovedTrackersUtils } from 'pages/policyCenter/trackers/hooks'

export const ApprovedTrackersContext = createContext({} as ApprovedTrackersUtils)

type Props = {
  children?: React.ReactNode
}

export const ApprovedTrackersContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useApprovedTrackersUtils()

  return (
    <ApprovedTrackersContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </ApprovedTrackersContext.Provider>
  )
}

export const withApprovedTrackersContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <ApprovedTrackersContextProvider>
        <Component {...props} />
      </ApprovedTrackersContextProvider>
    )
  }
}
