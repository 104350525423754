import { Element, Text, Descendant } from 'slate'
import { LinkElement } from 'pages/consentAndRights/experiences/upsert/components/common/interpolatedTextArea/interfaces'

export const getIsFooterDescriptionValid = (nodes: Descendant[]): boolean => {
  const payload = nodes
    .map(node => {
      const texts: string[] = []
      const inlineNodes: (LinkElement | Text)[] = (node as any)?.children || []

      inlineNodes?.forEach?.(inlineNode => {
        if (Element.isElement(inlineNode)) {
          if (inlineNode.type === 'link') {
            const linkText = inlineNode.children.map(({ text }) => text).join('')
            const linkUrl = inlineNode.url

            texts.push(`[${linkText}](${linkUrl})`)
          }
        } else {
          !!inlineNode.text ? texts.push(inlineNode.text) : texts.push(' ')
        }
      })
      const joinedTexts = texts.join('')
      return joinedTexts
    })
    .join('\n')
    .trim()
  return !!payload
}
