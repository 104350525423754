import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  executionId?: string
  fileName?: string
  requestId?: string
  stepId?: string
}

export const excludeDataReviewFile = ({ executionId, fileName, requestId, stepId }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/data-review/${requestId}/${executionId}/${stepId}/files/${fileName}`,
    }),
  )
