import React, { forwardRef } from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

import { PreviewDeviceMode } from 'interfaces/preview/previewDeviceMode'
import desktopBackground from 'assets/images/previewBackground/bg-desktop.png'
import mobileBackground from 'assets/images/previewBackground/bg-mobile.png'

const useStyles = makeStyles(
  createStyles({
    screen: {
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      overflow: 'hidden',
      minHeight: '60vh',
    },
    desktop: {
      borderRadius: 12,
      padding: 5,
      width: '100%',
      height: '100%',
      backgroundImage: `url('${desktopBackground}')`,
    },
    mobile: {
      width: 400,
      height: 620,
      borderRadius: 50,
      padding: '32px 20px',
      backgroundImage: `url('${mobileBackground}')`,
    },
  }),
  { name: 'ExperiencePreviewScreen' },
)

type Props = {
  mode: PreviewDeviceMode
  className?: string
  classes?: Partial<ReturnType<typeof useStyles>>
  children?: React.ReactNode
}

export const ExperiencePreviewScreen = forwardRef<HTMLDivElement, Props>(
  ({ mode, className, classes: _classes, children }, ref) => {
    const classes = useStyles({ classes: _classes })

    return (
      <div className={className}>
        <div
          ref={ref}
          className={clsx(classes.screen, {
            [classes.mobile]: mode === PreviewDeviceMode.MOBILE,
            [classes.desktop]: mode === PreviewDeviceMode.DESKTOP,
          })}
        >
          {children}
        </div>
      </div>
    )
  },
)
