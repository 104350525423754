import React, { useState, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { useField } from 'formik'
import { Descendant } from 'slate'
import { Slate, Editable } from 'slate-react'
import { Box } from '@mui/material'

import {
  createEditorInstance,
  focusEditor,
} from 'pages/consentAndRights/experiences/upsert/components/common/interpolatedTextArea/utils'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { Button } from '@ketch-com/deck'
import { InterpolatedElement, DocumentLinkerModal, TriggerModalModal } from './components'

const PREFIX = 'InterpolatedTextArea'

const classes = {
  button: `${PREFIX}-button`,
  editable: `${PREFIX}-editable`,
}

const Root = styled(Box)(({ theme: { typography, palette, spacing } }) => ({
  [`& .${classes.editable}`]: {
    padding: '12px 14px',
    minHeight: 224,
    borderRadius: 5,
    border: `1px solid ${palette.iron.main}`,
    fontSize: typography.pxToRem(14),
    lineHeight: 1.43,
    letterSpacing: typography.pxToRem(-0.14),

    '&:hover': {
      border: `1px solid ${palette.darkDuskFaded.main}`,
    },

    '&:focus': {
      border: `1px solid ${palette.sphere.main}`,
    },

    '&$error': {
      border: `1px solid ${palette.chileanFire.main}`,
    },
  },

  /* Manually replaced MUI button styles that the editable wrapper overrides (sometimes) */
  [`.${classes.button}`]: {
    color: palette.sphere.main,
    border: `1px solid ${palette.sphere.main}`,
    borderRadius: 5,
  },
}))

type Props = {
  name: string
  label?: string
  className?: string
  placeholder?: string
  required?: boolean
}

// @refresh reset
export const InterpolatedTextArea: React.FC<Props> = ({ name, label, className, required = false, placeholder }) => {
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false)
  const [isTriggerModalModalOpen, setIsTriggerModalModalOpen] = useState(false)
  const editor = useMemo(createEditorInstance, [])
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<Descendant[]>({ name })

  const showError = touched && !!error

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={nodes => {
        const nodesWithEmptyLinksRemoved = nodes.map((node: any) => {
          const newNode = {
            ...node,
            children: (node?.children || [])?.filter(
              (childNode: any) =>
                !(childNode?.type === 'link' && childNode?.children?.some((child: any) => child?.text === '')),
              [],
            ),
          }
          return newNode
        })
        setValue(nodesWithEmptyLinksRemoved)
      }}
    >
      <Root className={className}>
        {!!label && (
          <Label
            required={required}
            onClick={() => {
              focusEditor(editor)
            }}
          >
            {label}
          </Label>
        )}

        <Editable
          className={classes.editable}
          name={name}
          placeholder={placeholder}
          onBlur={() => {
            setTouched(true)
          }}
          renderElement={({ attributes, children, element }) => (
            <InterpolatedElement element={element} attributes={attributes}>
              {children}
            </InterpolatedElement>
          )}
        />

        {showError && <Error>{error}</Error>}

        <Box mt={2.25}>
          <Button className={classes.button} color="secondary" onClick={() => setIsDocumentModalOpen(true)}>
            Link Document
          </Button>
        </Box>

        <Box mt={2.25}>
          <Button className={classes.button} color="secondary" onClick={() => setIsTriggerModalModalOpen(true)}>
            Trigger Modal
          </Button>
        </Box>
      </Root>
      {isDocumentModalOpen && <DocumentLinkerModal onClose={() => setIsDocumentModalOpen(false)} />}

      {isTriggerModalModalOpen && <TriggerModalModal onClose={() => setIsTriggerModalModalOpen(false)} />}
    </Slate>
  )
}
