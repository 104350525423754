import React, { cloneElement, isValidElement, Children, Fragment, ReactNode, ReactElement } from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

import { Flex, FlexProps } from 'components/ui-layouts/flex/Flex'
import { isString } from 'lodash'

type ReactChildArray = ReturnType<typeof Children.toArray>

export const flattenChildren = (children: ReactNode): ReactChildArray => {
  const childrenArray = Children.toArray(children)

  return childrenArray.reduce((flatChildren: ReactChildArray, child) => {
    if ((child as ReactElement<any>).type === Fragment) {
      return flatChildren.concat(flattenChildren((child as ReactElement<any>).props.children))
    }

    flatChildren.push(child)

    return flatChildren
  }, [])
}

type Props = FlexProps & {
  gap?: number
  /** Custom component item className */
  classes?: {
    item: string
  }
}

const styles = createStyles({
  item: ({ gap }: { gap: number; classes: Props['classes'] }) => ({
    marginRight: gap,
    flexShrink: 0,
  }),
})

const useStyles = makeStyles(styles, { name: 'Group' })

/**
 * @deprecated This component is deprecated and should not be used anymore.
 */
export const Group: React.FC<Props> = ({
  children,
  gap = 12,
  inline = true,
  alignItems = 'center',
  classes: _classes,
  ...rest
}) => {
  const classes = useStyles({ gap, classes: _classes })

  const modifiedChildren = flattenChildren(children).map((child, index, arr) => {
    if (child && isValidElement(child)) {
      const cloneProps = {
        key: index,
        className: clsx(child?.props?.className, { [classes.item]: index !== arr.length - 1 }),
      }
      return cloneElement(child, cloneProps)
    } else {
      if (isString(child)) {
        const trimmed = child.trim()

        return (
          !!trimmed && (
            <span key={index} className={classes.item}>
              {trimmed}
            </span>
          )
        )
      }

      return (
        !!child && (
          <span key={index} className={classes.item}>
            {child}
          </span>
        )
      )
    }
  })

  return (
    <Flex {...rest} inline={inline} alignItems={alignItems}>
      {modifiedChildren}
    </Flex>
  )
}
