import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { CreateBusinessFunctionRequestBodyDTO, CreateBusinessFunctionResponseBodyDTO } from '@ketch-com/figurehead'

type Params = {
  organizationCode: string
  formData: CreateBusinessFunctionRequestBodyDTO
}

export const createBusinessFunction = ({ formData, organizationCode }: Params) =>
  API.post<CreateBusinessFunctionResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/business-functions`,
    }),
    { data: { ...formData, organizationCode } },
  )
