import { APIRequestParams } from 'api/common/utils'
import { AppDescriptorDTO } from 'interfaces/apps'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  organizationCode: string
  appId: string
}>

export const fetchApp = ({ organizationCode, includeMetadata, appId }: Params) =>
  API.get<AppDescriptorDTO>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/apps/${appId}`,
      params: {
        filters: {
          includeMetadata,
        },
      },
    }),
  )
