import { useContext } from 'react'
import { BannerThemeHeaderForm } from './forms/BannerThemeHeaderForm'
import { BannerThemeDescriptionForm } from './forms/BannerThemeDescriptionForm'
import { BannerThemeButtonsForm } from './forms/BannerThemeButtonsForm'
import { BannerThemeContainerForm } from './forms/BannerThemeContainerForm'
import { ThemeUpsertContext } from '../../../context/ThemeUpsertContext'
import {
  BannerThemeBuilderSection,
  ModalThemeBuilderSection,
  PreferenceThemeBuilderSection,
} from '../../../../../experiences-v2/upsert/components/builder/utils/enums'
import { ModalThemeContainerForm } from './forms/ModalThemeContainerForm'
import { ModalThemeHeaderForm } from './forms/ModalThemeHeaderForm'
import { ModalThemeDescriptionForm } from './forms/ModalThemeDescriptionForm'
import { ModalThemeListHeaderForm } from './forms/ModalThemeListHeaderForm'
import { ModalThemePurposeListForm } from './forms/ModalThemePurposeListForm'
import { ModalThemeFooterForm } from './forms/ModalThemeFooterForm'
import { PreferenceThemeWelcomeTabContainerForm } from './forms/PreferenceThemeWelcomeTabContainerForm'
import { PreferenceThemeWelcomeTabHeaderForm } from './forms/PreferenceThemeWelcomeTabHeaderForm'
import { PreferenceThemeWelcomeTabNavigationForm } from './forms/PreferenceThemeWelcomeTabNavigationForm'
import { PreferenceThemeWelcomeTabExitButtonForm } from './forms/PreferenceThemeWelcomeTabExitButtonForm'
import { PreferenceThemeWelcomeTabHeroBannerForm } from './forms/PreferenceThemeWelcomeTabHeroBannerForm'
import { PreferenceThemeWelcomeTabWelcomeMessageForm } from './forms/PreferenceThemeWelcomeTabWelcomeMessageForm'
import { PreferenceThemeWelcomeTabQuickLinksForm } from './forms/PreferenceThemeWelcomeTabQuickLinksForm'
import { PreferenceThemeWelcomeTabAboutForm } from './forms/PreferenceThemeWelcomeTabAboutForm'
import { PreferenceThemePurposesTabHeaderForm } from './forms/PreferenceThemePurposesTabHeaderForm'
import { PreferenceThemePurposesTabListHeaderForm } from './forms/PreferenceThemePurposesTabListHeaderForm'
import { PreferenceThemePurposesTabListForm } from './forms/PreferenceThemePurposesTabListForm'
import { PreferenceThemePurposesTabFooterForm } from './forms/PreferenceThemePurposesTabFooterForm'
import { PreferenceThemeSubscriptionsTabHeaderForm } from './forms/PreferenceThemeSubscriptionsTabHeaderForm'
import { PreferenceThemeSubscriptionsTabUnsubscribeAllForm } from './forms/PreferenceThemeSubscriptionsTabUnsubscribeAllForm'
import { PreferenceThemeSubscriptionsTabListForm } from './forms/PreferenceThemeSubscriptionsTabListForm'
import { PreferenceThemeSubscriptionsTabFooterForm } from './forms/PreferenceThemeSubscriptionsTabFooterForm'
import { PreferenceThemeRequestsTabHomeHeaderForm } from './forms/PreferenceThemeRequestsTabHomeHeaderForm'
import { PreferenceThemeRequestsTabHomeDsrLinkForm } from './forms/PreferenceThemeRequestsTabHomeDsrLinkForm'
import { PreferenceThemeRequestsTabHomeRightsListForm } from './forms/PreferenceThemeRequestsTabHomeRightsListForm'
import { PreferenceThemeRequestsTabFormHeaderForm } from './forms/PreferenceThemeRequestsTabFormHeaderForm'
import { PreferenceThemeRequestsTabFormContentForm } from './forms/PreferenceThemeRequestsTabFormContentForm'
import { PreferenceThemeRequestsTabFormActionButtonForm } from './forms/PreferenceThemeRequestsTabFormActionButtonForm'
import { PreferenceThemeRequestsTabSubmittedHeaderForm } from './forms/PreferenceThemeRequestsTabSubmittedHeaderForm'
import { PreferenceThemeRequestsTabSubmittedBannerForm } from './forms/PreferenceThemeRequestsTabSubmittedBannerForm'
import { PreferenceThemeRequestsTabSubmittedTextForm } from './forms/PreferenceThemeRequestsTabSubmittedTextForm'
import { PreferenceThemeRequestsTabSubmittedFooterForm } from './forms/PreferenceThemeRequestsTabSubmittedFooterForm'
import { BannerThemeFooterForm } from './forms/BannerThemeFooterForm'
import { PreferenceThemeWelcomeTabKetchBadgeForm } from './forms/PreferenceThemeWelcomeTabKetchBadgeForm'

const sectionForm: { [section: string]: React.ReactNode } = {
  [BannerThemeBuilderSection.Container]: <BannerThemeContainerForm />,
  [BannerThemeBuilderSection.Header]: <BannerThemeHeaderForm />,
  [BannerThemeBuilderSection.Description]: <BannerThemeDescriptionForm />,
  [BannerThemeBuilderSection.ActionButtons]: <BannerThemeButtonsForm />,
  [BannerThemeBuilderSection.ActionButtonsSecondary]: <BannerThemeButtonsForm />,
  [BannerThemeBuilderSection.ActionButtonsTertiary]: <BannerThemeButtonsForm />,
  [BannerThemeBuilderSection.Footer]: <BannerThemeFooterForm />,

  [ModalThemeBuilderSection.Container]: <ModalThemeContainerForm />,
  [ModalThemeBuilderSection.Header]: <ModalThemeHeaderForm />,
  [ModalThemeBuilderSection.Description]: <ModalThemeDescriptionForm />,
  [ModalThemeBuilderSection.ListHeader]: <ModalThemeListHeaderForm />,
  [ModalThemeBuilderSection.ListHeaderAcceptAll]: <ModalThemeListHeaderForm />,
  [ModalThemeBuilderSection.ListHeaderRejectAll]: <ModalThemeListHeaderForm />,
  [ModalThemeBuilderSection.PurposeList]: <ModalThemePurposeListForm />,
  [ModalThemeBuilderSection.Footer]: <ModalThemeFooterForm />,

  [PreferenceThemeBuilderSection.WelcomeTabContainer]: <PreferenceThemeWelcomeTabContainerForm />,
  [PreferenceThemeBuilderSection.WelcomeTabHeader]: <PreferenceThemeWelcomeTabHeaderForm />,
  [PreferenceThemeBuilderSection.WelcomeTabNavigation]: <PreferenceThemeWelcomeTabNavigationForm />,
  [PreferenceThemeBuilderSection.WelcomeTabExitButton]: <PreferenceThemeWelcomeTabExitButtonForm />,
  [PreferenceThemeBuilderSection.WelcomeTabHeroBanner]: <PreferenceThemeWelcomeTabHeroBannerForm />,
  [PreferenceThemeBuilderSection.WelcomeTabWelcomeMessage]: <PreferenceThemeWelcomeTabWelcomeMessageForm />,
  [PreferenceThemeBuilderSection.WelcomeTabQuickLinks]: <PreferenceThemeWelcomeTabQuickLinksForm />,
  [PreferenceThemeBuilderSection.WelcomeTabAbout]: <PreferenceThemeWelcomeTabAboutForm />,
  [PreferenceThemeBuilderSection.WelcomeTabKetchBadge]: <PreferenceThemeWelcomeTabKetchBadgeForm />,
  [PreferenceThemeBuilderSection.PurposesTabHeader]: <PreferenceThemePurposesTabHeaderForm />,
  [PreferenceThemeBuilderSection.PurposesTabListHeader]: <PreferenceThemePurposesTabListHeaderForm />,
  [PreferenceThemeBuilderSection.PurposesTabListHeaderAcceptAll]: <PreferenceThemePurposesTabListHeaderForm />,
  [PreferenceThemeBuilderSection.PurposesTabListHeaderRejectAll]: <PreferenceThemePurposesTabListHeaderForm />,
  [PreferenceThemeBuilderSection.PurposesTabList]: <PreferenceThemePurposesTabListForm />,
  [PreferenceThemeBuilderSection.PurposesTabFooter]: <PreferenceThemePurposesTabFooterForm />,
  [PreferenceThemeBuilderSection.SubscriptionsTabHeader]: <PreferenceThemeSubscriptionsTabHeaderForm />,
  [PreferenceThemeBuilderSection.SubscriptionsTabUnsubscribeAll]: <PreferenceThemeSubscriptionsTabUnsubscribeAllForm />,
  [PreferenceThemeBuilderSection.SubscriptionsTabList]: <PreferenceThemeSubscriptionsTabListForm />,
  [PreferenceThemeBuilderSection.SubscriptionsTabFooter]: <PreferenceThemeSubscriptionsTabFooterForm />,
  [PreferenceThemeBuilderSection.RequestsHomeHeader]: <PreferenceThemeRequestsTabHomeHeaderForm />,
  [PreferenceThemeBuilderSection.RequestsHomeDsrLink]: <PreferenceThemeRequestsTabHomeDsrLinkForm />,
  [PreferenceThemeBuilderSection.RequestsHomeRightsList]: <PreferenceThemeRequestsTabHomeRightsListForm />,
  [PreferenceThemeBuilderSection.RequestsFormHeader]: <PreferenceThemeRequestsTabFormHeaderForm />,
  [PreferenceThemeBuilderSection.RequestsFormContent]: <PreferenceThemeRequestsTabFormContentForm />,
  [PreferenceThemeBuilderSection.RequestsFormContentFields]: <PreferenceThemeRequestsTabFormContentForm />,
  [PreferenceThemeBuilderSection.RequestsFormActionButton]: <PreferenceThemeRequestsTabFormActionButtonForm />,
  [PreferenceThemeBuilderSection.RequestsSubmittedHeader]: <PreferenceThemeRequestsTabSubmittedHeaderForm />,
  [PreferenceThemeBuilderSection.RequestsSubmittedBanner]: <PreferenceThemeRequestsTabSubmittedBannerForm />,
  [PreferenceThemeBuilderSection.RequestsSubmittedText]: <PreferenceThemeRequestsTabSubmittedTextForm />,
  [PreferenceThemeBuilderSection.RequestsSubmittedFooter]: <PreferenceThemeRequestsTabSubmittedFooterForm />,
}

export const SidebarForm: React.FC = () => {
  const { themeBuilderSection } = useContext(ThemeUpsertContext)

  return themeBuilderSection ? <>{sectionForm[themeBuilderSection]}</> : null
}
