import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import {
  BuilderTextInputMode,
  FormTextInput,
} from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'

export const PreferenceThemeRequestsTabHomeDsrLinkForm: React.FC = () => {
  return (
    <SidebarFormGroup sx={{ pt: 0, gap: '16px' }}>
      <FormColorInput
        name={themeUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkTitleColor}
        label={'Title Color'}
        fullWidth
        required
      />
      <FormColorInput
        name={themeUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkDescriptionColor}
        label={'Description Color'}
        fullWidth
        required
      />
      <FormColorInput
        name={themeUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkArrowIcon}
        label={'Arrow Icon Color'}
        fullWidth
        required
      />
      <FormColorInput
        name={themeUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkBackground}
        label={'Fill Color'}
        fullWidth
        required
      />
      <FormTextInput
        name={themeUpsertFormKeys.preferenceRequestsTabHomeDsrPortalLinkCornerRadius}
        label={'Corner Radius (px)'}
        mode={BuilderTextInputMode.CornerRadius}
        inputWidth="92px"
      />
    </SidebarFormGroup>
  )
}
