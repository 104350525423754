import { ThemeDTO } from 'interfaces/themes/theme'
import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  themeCode: string
  organizationCode: string
  includeIssues?: boolean
  version?: boolean
}>

type Response = { theme: ThemeDTO }

export const fetchTheme = ({ organizationCode, includeMetadata, includeIssues, themeCode }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/themes/${themeCode}`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
        },
      },
    }),
  )
