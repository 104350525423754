import { InstalledDataSystemDataMapResidencyV2DTO } from '@ketch-com/figurehead'
import pluralize from 'pluralize'
import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { Card, Spinner, theme } from '@ketch-com/deck'
import { DataMapProcessingActivityContext } from '../context/DataMapProcessingActivityContext'
import {
  RetentionPeriodType,
  RetentionPeriodTypeOptions,
  securityMeasureOptions,
  SecurityMeasures,
} from 'pages/dataSystems/DataSystem/types'

type Props = {
  residency: InstalledDataSystemDataMapResidencyV2DTO
}

export const DataResidencyTile: React.FC<Props> = ({ residency }) => {
  const { isRegionsLoading, regions } = useContext(DataMapProcessingActivityContext)

  // Assemble residency info - display spinner until regions load
  const country =
    isRegionsLoading && !!regions ? (
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner size="16px" thickness={2.5} />
        </Box>
      </>
    ) : (
      regions.find(region => region.code === residency.country)?.name
    )
  const retention =
    residency.retentionPeriodType && residency.retentionPeriod
      ? `${residency.retentionPeriod} ${pluralize(
          RetentionPeriodTypeOptions[parseInt(residency.retentionPeriodType) as RetentionPeriodType].title,
          residency.retentionPeriod,
        )}`
      : 'No retention period'

  return (
    <Card
      cardVariant="small"
      sx={{
        width: 230,
        '&.DeckCardSmall': {
          padding: 2,
        },
      }}
      customContentCard={
        <Box display="flex" flexDirection="column" gap={3} width="100%">
          {/* Country */}
          <Typography variant="label">{country || 'No country'}</Typography>

          {/* Securities list */}

          <Box display="flex" flexDirection="column">
            <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
              Security
            </Typography>
            {residency.security && residency.security.length > 0 ? (
              residency.security.map(security => (
                <Typography key={security} variant="body">
                  {securityMeasureOptions[security as SecurityMeasures].title}
                </Typography>
              ))
            ) : (
              <Typography variant="body">No securities</Typography>
            )}
          </Box>

          {/* Retention */}
          <Box display="flex" flexDirection="column">
            <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
              Retention
            </Typography>
            <Typography variant="body">{retention}</Typography>
          </Box>
        </Box>
      }
    />
  )
}
