import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RightsQueueMegaFilterButtonPanelIds } from 'pages/orchestration/rightsQueue/listV2/components/RightsQueueMegaFilterButton/constants'

interface RightsQueueFilterState {
  searchParams: {
    [key: string]: string
  }
}

const initialState = {
  searchParams: {
    page: '0',
    orderBy: 'invoked_at',
    ordering: 'desc',
    splitIndex: '0',
    itemsPerPage: '20',
    query: '',

    [RightsQueueMegaFilterButtonPanelIds.status]: '',
    [RightsQueueMegaFilterButtonPanelIds.assignees]: '',
    [RightsQueueMegaFilterButtonPanelIds.jurisdictions]: '',
    [RightsQueueMegaFilterButtonPanelIds.rights]: '',
    [RightsQueueMegaFilterButtonPanelIds.subjectTypes]: '',
    [RightsQueueMegaFilterButtonPanelIds.workflows]: '',
  },
} as RightsQueueFilterState

export const rightsQueueFilterSlice = createSlice({
  name: 'rightsQueueFilterSlice',
  initialState,
  reducers: {
    setSearchParams: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.searchParams = {
        ...state.searchParams,
        ...action.payload,
      }
    },
    setMultipleSearchParams: (state, action: PayloadAction<{ [key: string]: string }[]>) => {
      const params = action.payload
      params?.forEach?.(param => {
        state.searchParams = {
          ...state.searchParams,
          ...param,
        }
      })
    },
    unsetSearchParams: (state, action: PayloadAction<string>) => {
      const key = action.payload
      state.searchParams = {
        ...state.searchParams,
        [key]: '',
      }
    },
    unsetMultipleSearchParams: (state, action: PayloadAction<string[]>) => {
      const keys = action.payload
      keys?.forEach?.(key => {
        state.searchParams = {
          ...state.searchParams,
          [key]: '',
        }
      })
    },
    removeSearchParamFromList: (state, action: PayloadAction<{ key: string; value: string }>) => {
      const { key, value } = action.payload
      const values = state.searchParams[key].split(',')
      const filteredValues = values.filter(v => v !== value)
      state.searchParams = {
        ...state.searchParams,
        [key]: filteredValues.join(','),
      }
    },
    resetAllSearchParams: () => initialState,
  },
})

export const {
  setSearchParams,
  setMultipleSearchParams,
  unsetSearchParams,
  unsetMultipleSearchParams,
  removeSearchParamFromList,
  resetAllSearchParams,
} = rightsQueueFilterSlice.actions

export default rightsQueueFilterSlice.reducer
