import { FC, useMemo } from 'react'
import { Formik } from 'formik'

// components
import Box from '@mui/material/Box'
import { Button, Icon } from '@ketch-com/deck'

// utils
import {
  WorkflowExecutionStepReassignFormValues,
  validationSchema,
} from 'pages/orchestration/rightsQueue/viewV2/components/RightsQueueWorkflowTab/components/reassignStep/utils'
import { FormAssigneeOrTeamSelector } from 'components/form/FormAssigneeOrTeamSelector'

// types
type Props = {
  assignee: string | undefined
  onSubmit: (values: { assigneeId: string }) => void
  showDropdown: boolean
  setShowDropdown: (showDropdown: boolean) => void
}

export const Reassign: FC<Props> = ({ assignee, onSubmit, showDropdown, setShowDropdown }) => {
  const initialValues: WorkflowExecutionStepReassignFormValues = useMemo(
    () => ({
      assigneeId: assignee || '',
    }),
    [assignee],
  )

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm }) => (
        <Box>
          {showDropdown ? (
            <FormAssigneeOrTeamSelector
              label="Assignee"
              showLabel={false}
              name="assigneeId"
              showAssignToMeButton={false}
              size={'small'}
              inFormRow={false}
              onItemClick={() => {
                submitForm()
                setShowDropdown(false)
              }}
            />
          ) : (
            <Button
              onClick={() => setShowDropdown(!showDropdown)}
              size="large"
              color="tertiary"
              endIcon={<Icon name="FEdit" />}
            >
              Re-assign
            </Button>
          )}
        </Box>
      )}
    </Formik>
  )
}
