import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getInitialValues, getValidationSchema } from 'pages/policyCenter/policies/upsert/utils'
import { RoutesManager } from 'utils/routing/routesManager'
import { Group } from 'components/ui-layouts/group/Group'
import { Button } from '@ketch-com/deck'
import { UpsertLayout } from 'components/ui-layouts/upsertLayout/UpsertLayout'
import { UpsertLayoutFooter } from 'components/ui-layouts/upsertLayout/UpsertLayoutFooter'
import { CreatePolicyForm } from 'pages/policyCenter/policies/upsert/CreatePolicyForm'
import { PolicyFormValues } from 'interfaces/policies/PolicyFormValues'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { MaybeNull } from 'interfaces/common'
import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { NEW_ITEM_ID } from 'utils/constants/misc'

type Props = {
  policy: MaybeNull<AssetPolicyDTO>
  isCreatingPolicy: boolean
  isUpdatingPolicy: boolean
  isReady: boolean
  onSubmit: (values: PolicyFormValues) => Promise<void>
  purposes: MaybeNull<PurposeDTO[]>
}

export const PolicyUpsertView: React.FC<Props> = ({
  isReady,
  onSubmit,
  purposes,
  isUpdatingPolicy = false,
  isCreatingPolicy = false,
  policy = null,
}) => {
  const navigate = useNavigate()
  const initialValues = useMemo(() => getInitialValues({ policy, purposes }), [policy, purposes])
  const validationSchema = useMemo(getValidationSchema, [])
  const { id } = useParams<{ id: string }>()

  return (
    <UpsertLayout
      isReady={isReady}
      title={id === NEW_ITEM_ID ? 'Create Policy' : 'Edit Policy'}
      breadcrumbs={
        id === NEW_ITEM_ID
          ? [
              { title: 'Privacy Program', link: RoutesManager.policyCenter.policies.list.getURL() },
              { title: 'Policies', link: RoutesManager.policyCenter.policies.list.getURL() },
              { title: 'Create New Policy' },
            ]
          : [
              { title: 'Privacy Program', link: RoutesManager.policyCenter.policies.list.getURL() },
              { title: 'Policies', link: RoutesManager.policyCenter.policies.list.getURL() },
              {
                title: policy?.name || policy?.id || '',
                link: RoutesManager.policyCenter.policies.detail.getURL({ id }),
              },
              { title: 'Edit Policy' },
            ]
      }
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, submitForm }) => {
        return (
          <>
            <CreatePolicyForm purposes={purposes} />

            <UpsertLayoutFooter>
              <Group>
                <Button
                  pending={isSubmitting}
                  color="secondary"
                  size="large"
                  onClick={() => {
                    navigate(RoutesManager.policyCenter.policies.list.getURL())
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!isValid}
                  size="large"
                  onClick={submitForm}
                  pending={isSubmitting || isCreatingPolicy || isUpdatingPolicy}
                >
                  Save
                </Button>
              </Group>
            </UpsertLayoutFooter>
          </>
        )
      }}
    </UpsertLayout>
  )
}
