import { createContext } from 'react'
import { CustomSqlUtils } from 'pages/assetManager/scenario/details/components/CustomSql/interfaces'
import { useCustomSqlUtils } from 'pages/assetManager/scenario/details/components/CustomSql/hooks'

export const CustomSqlContext = createContext({} as CustomSqlUtils)

type Props = {
  children?: React.ReactNode
}

export const CustomSqlContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useCustomSqlUtils()

  return (
    <CustomSqlContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </CustomSqlContext.Provider>
  )
}

export const withCustomSqlContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <CustomSqlContextProvider>
        <Component {...props} />
      </CustomSqlContextProvider>
    )
  }
}
