import { Badge } from 'components/ui-kit/badge/Badge'
import { BadgeKeyValue } from 'components/ui-kit/badge/BadgeKeyValue'

import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'

type Args = {
  value: ManageLabelModalLabel
  onRemove: () => void
}

export const getRenderSelectedLabel = ({ value, onRemove }: Args): React.ReactNode | null => {
  if (value.value)
    return (
      <BadgeKeyValue
        onRemoveBadge={() => onRemove()}
        badgeKey={value.code}
        badgeValue={value.value}
        variant="neutral"
      />
    )
  if (!value.value)
    return (
      <Badge onRemoveBadge={() => onRemove()} variant="neutral">
        {value.code}
      </Badge>
    )
  return null
}
