import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { ApiKeyDTO, ApiKeyFormDTO } from 'interfaces/apiKeys'

type Params = APIRequestParams<{
  id: string
  organizationCode: string
  formData: ApiKeyFormDTO
}>

export const updateApiKey = ({ organizationCode, id: apiKeyId, formData }: Params) =>
  API.put<ApiKeyDTO>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/api-keys/${apiKeyId}`,
    }),
    formData,
  )
