import React from 'react'

import { PurposesTable } from '../components/PurposesTable'
import { StackTable } from '../components/StackTable'

// utils
import { useIsEntitled } from 'utils/hooks/useIsEntitled'

// types
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { StackDTO, PurposeDTO } from '@ketch-com/figurehead'
import { LegalBaseDTO } from 'interfaces/legalBases/legalBase'
type Props = {
  isReady: boolean
  legalBases: LegalBaseDTO[]
  purposesByScope: PurposeDTO[]
  policyScopeCode: string
  stack: StackDTO
}

export const PolicyScopePurposes: React.FC<Props> = ({
  isReady,
  legalBases,
  purposesByScope,
  policyScopeCode,
  stack,
}) => {
  const { isEntitled } = useIsEntitled()

  const isConsentAndDisclosureAllowed = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)

  return (
    <>
      {isConsentAndDisclosureAllowed && (
        <StackTable isReady={isReady} legalBases={legalBases} policyScopeCode={policyScopeCode} stack={stack} />
      )}
      {!!purposesByScope?.length && (
        <PurposesTable
          isReady={isReady}
          legalBases={legalBases}
          purposesByScope={purposesByScope}
          policyScopeCode={policyScopeCode}
        />
      )}
    </>
  )
}
