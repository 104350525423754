import React, { useContext, useMemo } from 'react'
import { styled } from '@mui/styles'
import Box from '@mui/material/Box'
import { BuilderType } from './utils/enums'
import { BannerBuilder } from './components/banner/BannerBuilder'
import { ModalBuilder } from './components/modal/ModalBuilder'
import { PreferenceBuilder } from './components/preference/PreferenceBuilder'
import { BuilderContext } from './Builder'
import { ProgressiveBuilder } from './components/progressive/ProgressiveBuilder'

const BuilderBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
})

export const BuilderView: React.FC = () => {
  const { builderType } = useContext(BuilderContext)

  const isPreference = builderType === BuilderType.Preference

  const builder = useMemo(() => {
    switch (builderType) {
      case BuilderType.Banner:
        return <BannerBuilder />
      case BuilderType.Modal:
        return <ModalBuilder />
      case BuilderType.Preference:
        return <PreferenceBuilder />
      case BuilderType.Progressive:
        return <ProgressiveBuilder />
    }
  }, [builderType])

  return <BuilderBox alignItems={isPreference ? 'flex-start' : 'center'}>{builder}</BuilderBox>
}
