import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  () =>
    createStyles({
      preview: {
        flex: 1,
        position: 'relative',
      },
    }),
  { name: 'UpsertInlineLayoutPreviewContent' },
)

type Props = {
  children?: React.ReactNode
  className?: string
}

export const UpsertInlineLayoutPreviewContent: React.FC<Props> = ({ children, className }) => {
  const classes = useStyles()

  return <div className={clsx(classes.preview, className)}>{children}</div>
}
