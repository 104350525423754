import { Button } from '@ketch-com/deck'
import { Box } from '@mui/material'
import React from 'react'

export interface EditCustomFieldsSectionActionsProps {
  pending: boolean
  handleCancel: () => Promise<void>
  handleSave: () => void
}

export const EditCustomFieldsSectionActions: React.FC<EditCustomFieldsSectionActionsProps> = ({
  pending,
  handleCancel,
  handleSave,
}) => {
  return (
    <Box display="flex" gap={1}>
      <Button pending={pending} color="secondary" onClick={handleCancel}>
        Cancel
      </Button>
      <Button pending={pending} color="primary" onClick={handleSave}>
        Save & Exit
      </Button>
    </Box>
  )
}
