export const canonicalResourceTypeMap: Record<string, string> = {
  CANONICAL_RESOURCE_TYPE_UNSPECIFIED: 'Unspecified',
  CANONICAL_RESOURCE_TYPE_DATABASE: 'Database',
  CANONICAL_RESOURCE_TYPE_DATASET: 'Table',
  CANONICAL_RESOURCE_TYPE_ATTRIBUTE: 'Column',
  CANONICAL_RESOURCE_TYPE_OBJECT: 'Object',
  CANONICAL_RESOURCE_TYPE_DRIVE: 'Database',
  CANONICAL_RESOURCE_TYPE_FOLDER: 'Folder',
  CANONICAL_RESOURCE_TYPE_FILE: 'File',
  CANONICAL_RESOURCE_TYPE_SCHEMA: 'Schema',
}

export const columnFiltersByResourceType: Record<string, string[]> = {
  database: [],
  schema: ['dataOwner'],
  dataset: ['dataOwner'],
  object: ['dataOwner'],
  folder: ['dataOwner'],
  file: ['dataOwner'],
  table: ['dataOwner'],
  column: ['dataOwner'],
  attribute: ['dataOwner'],
}

export const filterDisplayNameMap: Record<string, string> = {
  dataSensitivityCodes: 'Data Sensitivity',
  dataCategoryCodes: 'Data Categories',
  dataTypeCodes: 'Data Types',
}

export const personalDataTypeOptions = [
  { name: 'Personal', value: 'personal' },
  { name: 'Sensitive', value: 'sensitive' },
]

export const classificationFilterKeyToTypeMap: Record<string, string> = {
  dataCategoryCodes: 'dataCategories',
  dataTypeCodes: 'dataTypes',
  dataSensitivityCodes: 'dataSensitivities',
}
