import React, { useContext } from 'react'

import { PreferenceField, fieldNames } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { InterpolatedTextArea } from 'pages/consentAndRights/experiences/upsert/components/common/interpolatedTextArea/InterpolatedTextArea'
import Typography from '@mui/material/Typography'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'

const sectionsWithTranslations = [
  PreferenceField.SUBSCRIPTIONS_TAB_NAME,
  PreferenceField.SUBSCRIPTIONS_BODY_TITLE,
  PreferenceField.SUBSCRIPTIONS_BODY_DESCRIPTION,
  PreferenceField.SUBSCRIPTIONS_PRIMARY_BUTTON,
  PreferenceField.SUBSCRIPTIONS_SECONDARY_BUTTON,
]

type Props = {}

export const Subscriptions: React.FC<Props> = () => {
  const { sidebarActiveTab, organizationLanguages } = useContext(ExperienceUpsertContext)
  return (
    <>
      {sectionsWithTranslations.includes(sidebarActiveTab || PreferenceField.MAIN_TITLE) && (
        <Typography variant="h4" color="fadedDarkGrey.main" mb={2.5}>
          Translations
        </Typography>
      )}

      {sidebarActiveTab === PreferenceField.SUBSCRIPTIONS_TAB_NAME && (
        <>
          {organizationLanguages.map(({ language, enabled }) => (
            <FormRow key={language.code} marginBottom={24}>
              <FormInput
                fullWidth
                required={false}
                name={fieldNames.preference.subscriptions.tabName.translation(language.code)}
                label={`Display Text (${language.englishName})`}
              />
            </FormRow>
          ))}
        </>
      )}

      {sidebarActiveTab === PreferenceField.SUBSCRIPTIONS_BODY_TITLE && (
        <>
          {organizationLanguages.map(({ language, enabled }) => (
            <FormRow key={language.code} marginBottom={24}>
              <FormInput
                fullWidth
                required={false}
                name={fieldNames.preference.subscriptions.bodyTitle.translation(language.code)}
                label={`Display Text (${language.englishName})`}
              />
            </FormRow>
          ))}
        </>
      )}

      {sidebarActiveTab === PreferenceField.SUBSCRIPTIONS_BODY_DESCRIPTION && (
        <>
          {organizationLanguages.map(({ language, enabled }) => (
            <FormRow key={language.code} marginBottom={24}>
              <InterpolatedTextArea
                required={false}
                name={fieldNames.preference.subscriptions.bodyDescription.translation(language.code)}
                label={`Display Text (${language.englishName})`}
              />
            </FormRow>
          ))}
        </>
      )}

      {sidebarActiveTab === PreferenceField.SUBSCRIPTIONS_PRIMARY_BUTTON && (
        <>
          {organizationLanguages.map(({ language, enabled }) => (
            <FormRow key={language.code} marginBottom={24}>
              <FormInput
                fullWidth
                required={false}
                name={fieldNames.preference.subscriptions.primaryButtonText.translation(language.code)}
                label={`Display Text (${language.englishName})`}
              />
            </FormRow>
          ))}
        </>
      )}

      {sidebarActiveTab === PreferenceField.SUBSCRIPTIONS_SECONDARY_BUTTON && (
        <>
          {organizationLanguages.map(({ language, enabled }) => (
            <FormRow key={language.code} marginBottom={24}>
              <FormInput
                fullWidth
                required={false}
                name={fieldNames.preference.subscriptions.secondaryButtonText.translation(language.code)}
                label={`Display Text (${language.englishName})`}
              />
            </FormRow>
          ))}
        </>
      )}
    </>
  )
}
