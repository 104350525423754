import { CookieCategoryOptions } from 'interfaces/cookies/cookieCategory'

export const getStatusFilterDisplay = (status: number | null) => {
  if (status === null) return null
  if (status === 0) return 'Not Disclosed'
  if (status === 1) return 'Disclosed'
}

export const getCategoryFilterDisplay = (category: number | null) => {
  if (category === null) return null
  return CookieCategoryOptions.find(({ id }) => id === category)?.name
}

export const COOKIE_FILTER_STATUS_OPTIONS = [
  {
    value: 0,
    name: 'Not Disclosed',
  },
  {
    value: 1,
    name: 'Disclosed',
  },
]
