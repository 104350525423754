import React from 'react'
import { TextInputColor } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

interface Props {}

export const TextInputColorExample: React.FC<Props> = ({ value, onChange, ...args }: any) => {
  const [colorValue, setColorValue] = React.useState('')
  const [colorValue1, setColorValue1] = React.useState('#A3C4C7')
  const [colorValue2, setColorValue2] = React.useState('#222')

  return (
    <Box>
      <Typography variant="h4" mb={2}>
        TextInputColor with label
      </Typography>
      <TextInputColor
        label="Label"
        value={colorValue}
        onChange={setColorValue}
        {...args}
        sx={{ marginBottom: '24px' }}
      />
      <Typography variant="h4" mb={2}>
        TextInputColor small
      </Typography>
      <TextInputColor
        value={colorValue1}
        onChange={setColorValue1}
        {...args}
        sx={{ marginBottom: '24px' }}
        placeholder="placeholder"
        size="small"
      />
      <Typography variant="h4" mb={2}>
        TextInputColor full width
      </Typography>
      <TextInputColor
        value={colorValue2}
        onChange={setColorValue2}
        {...args}
        sx={{ marginBottom: '24px' }}
        placeholder="placeholder"
        fullWidth
      />
    </Box>
  )
}
