import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Avatar, AvatarSize, Button, Chip, theme } from '@ketch-com/deck'
import { ProcessingActivityDataSystemConsentStatusDTO } from '@ketch-com/figurehead'
import { renderConsentStatusIcon, renderConsentStatusLabel } from './ConsentStatusWidget'
import { EmptyValueRendererV2 } from 'components/ui-kit/typography/compositions/EmptyValueRendererV2'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { TableCell } from '@ketch-com/deck'
import { ProcessingActivityDataSystemDTO } from '@ketch-com/figurehead'

export const processingActivityDataSystemColumns = ({
  handleConfirmClick,
  handleUndoClick,
  handleViewSystemClick,
  handleConnectClick,
  isConsentStatusUpdating,
  isPermitted,
}: {
  isConsentStatusUpdating: boolean
  handleConnectClick: (dataSystemId: string) => Promise<void>
  handleConfirmClick: (id: string) => Promise<void>
  handleViewSystemClick: (dataSystemId: string) => void
  handleUndoClick: (id: string) => Promise<void>
  isPermitted: boolean
}): GridColDef<ProcessingActivityDataSystemDTO>[] => [
  {
    align: 'left',
    field: 'dataSystem',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Data System',
    sortable: false,
    renderCell: ({ row: system }: GridRenderCellParams<ProcessingActivityDataSystemDTO>) => {
      return (
        <TableCell>
          <Box display="flex" gap={2} alignItems="center">
            <Avatar
              isLogo
              src={system?.dataSystem?.logoUrl}
              alt={system?.dataSystem?.name}
              variant="rounded"
              size={AvatarSize.xlarge}
              backgroundColor={theme.palette.tertiary.main}
            />
            <Typography variant="label">{system?.dataSystem?.name || 'Name Unknown'}</Typography>
          </Box>
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'connections',
    flex: 0.5,
    headerAlign: 'left',
    headerName: 'Connections',
    sortable: false,
    renderCell: ({ row: system }: GridRenderCellParams<ProcessingActivityDataSystemDTO>) => {
      return (
        <TableCell>
          <Chip
            size="small"
            label={
              system.connectionCount ? (
                <Typography variant="mediumFootnote">{system.connectionCount || 0}</Typography>
              ) : (
                <EmptyValueRendererV2 variant="mediumFootnote" />
              )
            }
          />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'status',
    flex: 0.7,
    headerAlign: 'left',
    headerName: 'Status',
    sortable: false,
    renderCell: ({ row: system }: GridRenderCellParams<ProcessingActivityDataSystemDTO>) => {
      return (
        <TableCell>
          <Box display="flex" alignItems="center" gap={0.25}>
            {renderConsentStatusIcon(system, 24, 24)}
            {renderConsentStatusLabel(system)}
          </Box>
        </TableCell>
      )
    },
  },
  {
    align: 'right',
    field: 'action',
    flex: 0.75,
    headerAlign: 'left',
    headerName: ' ',
    sortable: false,
    renderCell: ({ row: system }: GridRenderCellParams<ProcessingActivityDataSystemDTO>) => {
      switch (system.consentStatus) {
        case ProcessingActivityDataSystemConsentStatusDTO.OrchestratedProcessingActivityDataSystemConsentStatus:
          return (
            <TableCell>
              <Box display="flex" gap={1} alignItems="center">
                {system.consentStatusSetManually && (
                  <Button
                    sx={{
                      '& .DeckBtnWrapChild': {
                        width: 'max-content',
                      },
                    }}
                    color="secondary"
                    onClick={() => handleUndoClick(system.id || '')}
                    pending={isConsentStatusUpdating}
                  >
                    Undo
                  </Button>
                )}
                <Button
                  sx={{
                    '& .DeckBtnWrapChild': {
                      width: 'max-content',
                    },
                  }}
                  color="tertiary"
                  onClick={() => handleViewSystemClick(system.dataSystemId || '')}
                  pending={isConsentStatusUpdating}
                >
                  View System
                </Button>
              </Box>
            </TableCell>
          )
        case ProcessingActivityDataSystemConsentStatusDTO.NeedsConfirmationProcessingActivityDataSystemConsentStatus:
          return (
            <TableCell>
              {isPermitted ? (
                <Button
                  color="secondary"
                  onClick={() => handleConfirmClick(system.id || '')}
                  pending={isConsentStatusUpdating}
                >
                  Confirm
                </Button>
              ) : (
                <></>
              )}
            </TableCell>
          )
        default:
          return (
            <TableCell>
              {isPermitted ? (
                <Button
                  color="secondary"
                  onClick={() => handleConnectClick(system.dataSystemId || '')}
                  pending={isConsentStatusUpdating}
                >
                  Connect
                </Button>
              ) : (
                <></>
              )}
            </TableCell>
          )
      }
    },
  },
]
