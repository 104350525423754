import React from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'
import { IdentitySpaceFormValues, mapValuesToPayload } from 'pages/settings/identitySpaces/upsert/utils/formValues'
import { IdentitySpaceUpsertView } from 'pages/settings/identitySpaces/upsert/IdentitySpaceUpsertView'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { useUpdateIdentitySpace } from 'api/identitySpaces/mutations/useUpdateIdentitySpace'
import { useIdentitySpace } from 'api/identitySpaces/queries/useIdentitySpace'
import { useCreateIdentitySpace } from 'api/identitySpaces/mutations/useCreateIdentitySpace'
import { useIdentitySpacesCatalog } from 'api/identitySpaces/queries/useIdentitySpacesCatalog'
import { showToast } from 'components/modals/AlertComponent'
import { IdentitySpaceUpsertModal } from './IdentitySpaceUpsertModal'
import { useVerifiers } from 'api/verifiers/queries/useVerifiers'

type Props = { isModalForm?: boolean; onClose?: () => void; onSuccess?: (code: string) => void }

export const IdentitySpaceUpsertContainer: React.FC<Props> = ({
  isModalForm = false,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()

  const isEditMode = !isModalForm && code !== NEW_ITEM_CODE

  const onError = () => {
    if (isModalForm) {
      showToast({
        type: 'error',
        content: 'Failed to load Identifier creation form',
      })
    } else {
      navigate(RoutesManager.settings.identitySpaces.root.getURL())
    }
  }

  const { data: identitySpaces } = useIdentitySpaces({
    params: {
      includeMetadata: true,
      includeIssues: true,
    },
    onError,
  })

  const { data: verifiersData, isLoading: isLoadingVerifiers } = useVerifiers({
    params: {},
    itemsPerPage: 100,
    onError,
  })

  const { data: identitySpace, isLoading: isLoadingIdentitySpace } = useIdentitySpace({
    enabled: isEditMode,
    params: {
      identitySpaceCode: code!,
    },
    onError,
  })

  const { data: identitySpacesCatalog, isLoading: isIdentitySpacesCatalogLoading } = useIdentitySpacesCatalog({
    onError,
  })

  const onUpsertSuccess = async ({ code }: IdentitySpaceDTO) => {
    if (isModalForm) {
      showToast({
        type: 'success',
        content: 'Created Identifier',
      })
      onClose()
      await queryClient.refetchQueries(ApiQueryKeys.identitySpaces)
      onSuccess(code)
    } else {
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      navigate(RoutesManager.settings.identitySpaces.view.root.getURL({ code }))
    }
  }

  const { mutateAsync: handleCreateIdentitySpace } = useCreateIdentitySpace({
    onSuccess: ({ data }) => {
      showToast({ content: 'Identifier created', type: 'success' })
      onUpsertSuccess(data.identitySpace)
    },
    onError: () => {
      showToast({ content: 'Failed to create Identifier', type: 'error' })
    },
  })

  const { mutateAsync: handleUpdateIdentitySpace } = useUpdateIdentitySpace({
    onSuccess: ({ data }) => {
      showToast({ content: 'Identifier updated', type: 'success' })
      onUpsertSuccess(data.identitySpace)
    },
    onError: () => {
      showToast({ content: 'Failed to update Identifier', type: 'error' })
    },
  })

  const onSubmit = async (values: IdentitySpaceFormValues) => {
    const formData = mapValuesToPayload({ values })

    await (isEditMode
      ? handleUpdateIdentitySpace({
          params: {
            identitySpaceCode: code!,
            formData,
          },
        })
      : handleCreateIdentitySpace({
          params: {
            formData,
          },
        }))
  }

  const isReady = !isLoadingIdentitySpace && !isIdentitySpacesCatalogLoading && !isLoadingVerifiers

  if (!!identitySpace?.managed)
    return <Navigate to={RoutesManager.settings.identitySpaces.view.root.getURL({ code })} />

  return isModalForm ? (
    <IdentitySpaceUpsertModal
      isEditMode={isEditMode}
      isReady={isReady}
      onSubmit={onSubmit}
      identitySpace={identitySpace}
      identitySpaces={identitySpaces}
      identitySpacesCatalog={identitySpacesCatalog}
      verifiersData={verifiersData}
      onClose={onClose}
    />
  ) : (
    <IdentitySpaceUpsertView
      isEditMode={isEditMode}
      isReady={isReady}
      onSubmit={onSubmit}
      identitySpace={identitySpace}
      identitySpaces={identitySpaces}
      identitySpacesCatalog={identitySpacesCatalog}
      verifiersData={verifiersData}
    />
  )
}
