import { FormikErrors } from 'formik'

import { Box, Typography } from '@mui/material'
import { Button, TooltipButton } from '@ketch-com/deck'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'

// types
import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { WorkflowExecutionStepCompleteFormValues } from 'pages/orchestration/rightsQueue/viewV2/components/RightsQueueWorkflowTab/components/completeStep/utils'
import { Dispatch, SetStateAction, useContext, useState } from 'react'
import { RetryAppModal } from '.'
import { StepDetailsDTO } from '@ketch-com/figurehead'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
type Props = {
  canSubmit: boolean
  isAssignedToMe: boolean
  errors: FormikErrors<WorkflowExecutionStepCompleteFormValues>
  resolve: () => void
  workflowStep: WorkflowExecutionStepDetailsDTO | null
  stepDetails: StepDetailsDTO | null
  setIsResolving: Dispatch<SetStateAction<boolean>>
  isResolving: boolean

  setShowRequiredFields: Dispatch<SetStateAction<boolean>>
}

export const AppDetailsHeaderActions: React.FC<Props> = ({
  isAssignedToMe,
  canSubmit,
  errors,
  resolve,
  workflowStep,
  stepDetails,
  setIsResolving,
  isResolving,
  setShowRequiredFields,
}) => {
  const [isRetryModalOpen, setIsRetryModalOpen] = useState<boolean>(false)
  const requiredFields: string[] = []

  errors?.resolutionDetails && requiredFields.push('Notes')
  errors?.resolutionAttachments && requiredFields.push('Attachments')
  const { hasResolveSucceeded } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const tooltipTitle = isAssignedToMe ? '' : 'Resolve is only available for assignees.'

  const isNotSuccess = workflowStep?.status !== WorkflowExecutionStepStatus.SUCCESS

  const shouldShowRetryButton =
    workflowStep?.status === WorkflowExecutionStepStatus.FAIL && !!stepDetails?.executionData?.app?.supportsAppRetry

  return (
    <Box display="flex" gap={1.5} alignItems="center">
      {workflowStep?.completedAt && (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="smallBody" color="darkDuskFaded.main">
            Last Updated
          </Typography>
          <Typography color="darkDusk.main" variant="smallBody">
            <FormattedDateRenderer date={workflowStep?.completedAt} />
          </Typography>
        </Box>
      )}

      {/* Retry Button */}

      {shouldShowRetryButton && (
        <Button disabled={hasResolveSucceeded} size="large" color="tertiary" onClick={() => setIsRetryModalOpen(true)}>
          Retry
        </Button>
      )}

      {/* Resolve Button */}

      {isNotSuccess && (
        <TooltipButton
          title={tooltipTitle}
          pending={isResolving}
          disabled={!isAssignedToMe || hasResolveSucceeded}
          size="large"
          color="primary"
          onClick={() => {
            if (canSubmit) {
              setShowRequiredFields(false)
              setIsResolving(true)
              resolve()
            } else {
              setShowRequiredFields(true)
            }
          }}
        >
          Resolve
        </TooltipButton>
      )}
      {/* Retry Modal */}
      {isRetryModalOpen && <RetryAppModal onCancel={() => setIsRetryModalOpen(false)} stepDetails={stepDetails} />}
    </Box>
  )
}
