import React, { useState } from 'react'
import { Box, InputAdornment } from '@mui/material'
import { ActionSheetItem, Chip, ChipsAutocomplete, Icon, ListItemText, TextInput } from '@ketch-com/deck'
import { StyledAutoCompleteContainer } from 'pages/dataSystems/DataSystem/components/styledComponents'
import { DocumentOptionType } from 'pages/dataSystems/DataSystem/types'

type Props = {
  documents: DocumentOptionType[]
  selectedDocuments: DocumentOptionType[]
  updateSelectedList: (updatedList: any) => void
}

export const DocumentOptionsSearchAutocomplete: React.FC<Props> = ({
  selectedDocuments,
  documents,
  updateSelectedList = () => {},
}) => {
  const [selected, setSelected] = useState<DocumentOptionType[]>(selectedDocuments)
  const [inputValue, setInputValue] = useState('')

  const onOptionSelect = (e: React.SyntheticEvent<Element, Event>, v: DocumentOptionType[]) => {
    e.preventDefault()
    setSelected(v)
    updateSelectedList(v)
  }

  return (
    <StyledAutoCompleteContainer>
      <ChipsAutocomplete
        inRowChips
        chipSize="medium"
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        multiple
        onChange={(e, newValue) => onOptionSelect(e, newValue)}
        options={documents}
        sx={{ width: '80%' }}
        value={selected}
        renderInput={params => (
          <TextInput
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Icon name="OMag" />
                </InputAdornment>
              ),
              endAdornment: null,
            }}
            onChange={e => setInputValue(e.currentTarget.value)}
            placeholder="Start typing a document name"
            value={inputValue}
            variant="outlined"
          />
        )}
        renderOption={(props, option, { selected }) => {
          return (
            <ActionSheetItem selected={selected} {...props} key={option.ID}>
              <ListItemText selected={selected}>{option.name}</ListItemText>
            </ActionSheetItem>
          )
        }}
        renderChipItem={(data, index): React.ReactNode => {
          return (
            <Box key={`${index}-${data.code}`}>
              <Chip
                size="medium"
                label={data.name}
                deleteIcon={<Icon name="OCross" />}
                onDelete={() => {
                  setSelected(selected.filter(label => label.code !== data.code))
                  updateSelectedList(selected.filter(label => label.code !== data.code))
                }}
              />
            </Box>
          )
        }}
      />
    </StyledAutoCompleteContainer>
  )
}
