import React from 'react'

import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { Banner } from 'components/ui-kit/banner/Banner'

type Props = {
  theme: ThemeDTO
}

export const ThemeVersionsMessage: React.FC<Props> = () => {
  const { removeQueryParam } = useQueryParams<{ version?: string }>()

  return (
    <Banner
      variant="warning"
      actions={[
        {
          title: 'View Current',
          onClick: () => removeQueryParam('version'),
        },
      ]}
    >
      You are viewing an old version of this theme. Return to the current version to manage or activate.
    </Banner>
  )
}
