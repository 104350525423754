import { Status, StatusState, StatusVariant } from '@ketch-com/deck'
import React, { useContext } from 'react'
import { ThemeStatus } from 'interfaces/themes-v3/themeStatus'
import { ThemeViewContext } from '../context/ThemeViewContext'

export const ThemeViewDetails: React.FC = () => {
  const { theme } = useContext(ThemeViewContext)

  const statusState = theme?.status === ThemeStatus.Deployed ? StatusState.success : StatusState.draft
  const statusLabel = theme?.status === ThemeStatus.Deployed ? 'Deployed' : 'Undeployed'

  return <Status label={statusLabel} status={statusState} variant={StatusVariant.outlined} icon="Dot" />
}
