import Box from '@mui/material/Box'
import { ChangeEvent, ReactNode, useState } from 'react'
import { SystemStyleObject } from '@mui/system/styleFunctionSx'
import { Theme } from '@mui/material'
import { SwitchToggle } from '@ketch-com/deck'

type Props = {
  /** Initial value of the switch */
  value?: boolean
  /** Component to render to the right of the switch */
  title: ReactNode
  /** Component to render to the right of the switch, below title component */
  description?: ReactNode
  /** Function to call when the switch is toggled */
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  /** Styles for the container */
  containerSx?: SystemStyleObject<Theme>
}

/**
 * A switch with accompanying text beside it
 */
export const SwitchText: React.FC<Props> = ({ title, description, onChange, value, containerSx }) => {
  const [enabled, setEnabled] = useState<boolean>(value || false) // Default to switched off

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange(event, checked)
    setEnabled(!enabled)
  }

  return (
    <Box display="flex" sx={containerSx}>
      <Box
        sx={{
          display: 'flex',
          padding: '0px 18px 0px 0px',
          alignItems: 'center',
          gap: '8px',
          alignSelf: 'stretch',
        }}
      >
        <SwitchToggle onChange={handleOnChange} checked={enabled} size="medium" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: '4px',
          padding: '0px 18px 0px 18px',
        }}
      >
        {title}
        {description}
      </Box>
    </Box>
  )
}
