import { IDParams } from './utils'

export const developers = {
  root: {
    pattern: '/developers',
    getURL: () => '/developers',
  },

  transponders: {
    list: {
      pattern: '/developers/transponders',
      getURL: () => '/developers/transponders',
    },

    create: {
      pattern: '/developers/transponders/create',
      getURL: () => '/developers/transponders/create',
    },

    detail: {
      pattern: '/developers/transponders/view/:id',
      getURL: ({ id }: IDParams) => `/developers/transponders/view/${id}`,
    },

    upsert: {
      pattern: '/developers/transponders/edit/:id',
      getURL: ({ id }: IDParams) => `/developers/transponders/edit/${id}`,
    },
  },

  apiKeys: {
    root: {
      pattern: '/api-keys',
      getURL: () => '/developers/api-keys',
    },

    list: {
      pattern: '/developers/api-keys',
      getURL: () => '/developers/api-keys',
    },

    detail: {
      pattern: '/developers/api-keys/view/:id',
      getURL: ({ id }: IDParams) => `/developers/api-keys/view/${id}`,
    },

    upsert: {
      pattern: '/developers/api-keys/edit/:id',
      getURL: ({ id }: IDParams) => `/developers/api-keys/edit/${id}`,
    },
  },

  hooks: {
    root: {
      pattern: '/hooks',
      getURL: () => '/developers/hooks',
    },

    list: {
      pattern: '/developers/hooks',
      getURL: () => '/developers/hooks',
    },

    detail: {
      pattern: '/developers/hooks/view/:id',
      getURL: ({ id }: IDParams) => `/developers/hooks/view/${id}`,
    },

    upsert: {
      pattern: '/developers/hooks/edit/:id',
      getURL: ({ id }: IDParams) => `/developers/hooks/edit/${id}`,
    },
  },

  webhooks: {
    root: {
      pattern: '/webhooks',
      getURL: () => '/developers/webhooks',
    },

    list: {
      pattern: '/developers/webhooks',
      getURL: () => '/developers/webhooks',
    },

    detail: {
      pattern: '/developers/webhooks/view/:id',
      getURL: ({ id }: IDParams) => `/developers/webhooks/view/${id}`,
    },

    upsert: {
      pattern: '/developers/webhooks/edit/:id',
      getURL: ({ id }: IDParams) => `/developers/webhooks/edit/${id}`,
    },
  },

  authenticators: {
    root: {
      pattern: '/authenticators',
      getURL: () => '/developers/authenticators',
    },

    list: {
      pattern: '/developers/authenticators',
      getURL: () => '/developers/authenticators',
    },

    detail: {
      pattern: '/developers/authenticators/view/:id',
      getURL: ({ id }: IDParams) => `/developers/authenticators/view/${id}`,
    },

    upsert: {
      pattern: '/developers/authenticators/edit/:id',
      getURL: ({ id }: IDParams) => `/developers/authenticators/edit/${id}`,
    },
  },

  verifiers: {
    list: {
      pattern: '/developers/verifiers',
      getURL: () => '/developers/verifiers',
    },
  },
}
