import React, { useMemo, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import { RGBAColor } from 'deck.gl'
import { RegulationDTO } from 'interfaces/regulations/regulation'
import { RightDTO } from 'interfaces/rights/right'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { GLOBAL_STANDARD_SCOPE_CODE } from 'utils/constants/policyScopes'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { IssuesCellRenderer } from 'components/renderers/IssuesCellRenderer'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { PolicyScopeRegulations } from 'pages/policyCenter/policyScopes/components/PolicyScopeRegulations'
import { PolicyScopeRights } from 'pages/policyCenter/policyScopes/components/PolicyScopeRights'
import { Pagination } from 'api/common/paginatedQuery'
import { PolicyScopesListDropdown } from 'pages/policyCenter/policyScopes/list/PolicyScopesListDropdown'
import { PolicyScopesMapMultiple } from '../components/map/PolicyScopesMapMultiple'
import { DataGrid, TableCell, TooltipButton, EmptyState, Spinner, ListLayout } from '@ketch-com/deck'
import { GridRenderCellParams, GridPaginationModel } from '@mui/x-data-grid-premium'
import { Typography, Box } from '@mui/material'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { FirstNameLastNameRenderer } from 'components/renderers/FirstNameLastNameRenderer'

const PREFIX = 'PolicyScopesList'

const classes = {
  mapWrapper: `${PREFIX}-mapWrapper`,
}

const Root = styled('div')(({ theme: { palette } }) => ({
  [`& .${classes.mapWrapper}`]: {
    height: 600,
    padding: 20,
    background: 'white',
  },
}))

const initialViewState = {
  width: 1212,
  height: 560,
  latitude: 45.58271429367609,
  longitude: 13.692982974033699,
  zoom: 0.7679697961867215,
  bearing: 0,
  pitch: 0,
  altitude: 1.5,
  maxZoom: 20,
  minZoom: 0,
  maxPitch: 60,
  minPitch: 0,
  position: [0, 0, 0],
}

export interface IMapRegion {
  id: string
  value: number
  policyScopeName: string
  itemsInScopeCount: number
  policyScopeCode?: string
  fillColor?: RGBAColor
}
interface PolicyScopesProps {
  isReady: boolean
  policyScopes: PolicyScopeDTO[]
  rights: RightDTO[]
  regulations: RegulationDTO[]
  pagination: Pagination
}

export const PolicyScopesList: React.FC<PolicyScopesProps> = ({
  isReady,
  policyScopes,
  regulations,
  rights,
  pagination,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [currentTab, setCurrentTab] = React.useState(0)

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Jurisdictions', link: RoutesManager.policyCenter.policyScopes.root.getURL() },
  ]

  const handleChangeTab = (event: React.SyntheticEvent, newTab: number) => {
    setCurrentTab(newTab)
    navigate(tabItems[newTab].link)
  }
  // const match = useMatch()
  // NOTE: sort jurisdictions to make the 'default' always to be first. Needed for Map colors
  const policyScopesData = [...policyScopes].sort(scope => (scope.code === GLOBAL_STANDARD_SCOPE_CODE ? -1 : 1))

  const { isEntitled } = useIsEntitled()
  const isEntitledToCreatePolicyScopes = isEntitled(ENTITLEMENTS.QTY_JURISDICTION)

  const { isPermitted } = useIsPermitted()
  const isPermittedToCreatePolicyScopes = isPermitted([PERMISSIONS.JURISDICTION_WRITE])

  const canCreatePolicyScopes = isEntitledToCreatePolicyScopes && isPermittedToCreatePolicyScopes

  const totalPages = pagination.totalPages
  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: GridPaginationModel) => {
    pagination.onPageChange(paginationModel.page)
  }

  const regionsForMap: IMapRegion[] = []
  const tabItems = useMemo(
    () => [
      {
        label: 'List',
        link: RoutesManager.policyCenter.policyScopes.list.table.root.getURL(),
      },
      {
        label: 'Map',
        link: RoutesManager.policyCenter.policyScopes.list.map.root.getURL(),
      },
    ],
    [],
  )

  useEffect(() => {
    tabItems.forEach((tab, index) => {
      if (location.pathname.includes(tab.link)) {
        setCurrentTab(index)
      }
    })
  }, [location.pathname, tabItems])

  policyScopesData?.forEach?.(({ code: policyScopeCode, name, regionCodes = [] }, scopeIndex) => {
    const itemsInScopeCount = regionCodes.length

    regionCodes?.forEach?.(code => {
      regionsForMap.push({
        id: code,
        value: scopeIndex,
        policyScopeName: name,
        policyScopeCode,
        itemsInScopeCount,
      })
    })
  })

  const handleUpsertPolicyScopes = () => {
    navigate(RoutesManager.policyCenter.policyScopes.library.root.getURL())
  }

  type RowType = (typeof policyScopesData)[0]

  const gridColumns = [
    {
      field: 'issues',
      headerName: '',
      width: 35,
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<RowType>) => (
        <TableCell>
          <IssuesCellRenderer hasIssues={!!row.hasIssues} entityType={ApplicationEntity.POLICY_SCOPE} />
        </TableCell>
      ),
    },
    {
      field: 'code',
      headerName: 'Policy Name',
      width: 250,
      sortable: false,
      renderCell: ({ row: { code, name } }: GridRenderCellParams<RowType>) => (
        <TableCell>
          <NameAndCodeCellRenderer code={code} name={name} />
        </TableCell>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      flex: 1,
      renderCell: ({ row: { description } }: GridRenderCellParams<RowType>) => {
        const content = description ?? 'None'
        return (
          <TableCell>
            <Typography>{content}</Typography>
          </TableCell>
        )
      },
    },
    {
      field: 'regulations',
      headerName: 'Regulations',
      sortable: false,
      width: 120,
      renderCell: ({ row: { regulationCodes } }: GridRenderCellParams<RowType>) => (
        <PolicyScopeRegulations regulationCodes={regulationCodes} regulations={regulations} />
      ),
    },
    {
      field: 'rights',
      headerName: 'Rights',
      width: 120,
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<RowType>) => (
        <TableCell>
          <PolicyScopeRights policyScope={row} regulations={regulations} rights={rights} />
        </TableCell>
      ),
    },
    {
      field: 'lastUpdated',
      width: 180,
      headerName: 'Last Updated',
      sortable: false,
      renderCell: ({ row: { audit } }: GridRenderCellParams<RowType>) => {
        return (
          <TableCell>
            <FormattedDateRenderer date={audit?.updatedAt} />
          </TableCell>
        )
      },
    },
    {
      field: 'updatedBy',
      width: 200,
      headerName: 'Updated By',
      sortable: false,
      renderCell: ({ row: { audit } }: GridRenderCellParams<RowType>) => {
        return (
          <TableCell>
            <FirstNameLastNameRenderer firstName={audit?.updatedBy?.firstName} lastName={audit?.updatedBy?.lastName} />
          </TableCell>
        )
      },
    },
    {
      field: 'dropDown',
      headerName: '',
      width: 60,
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<RowType>) => (
        <TableCell>
          <PolicyScopesListDropdown policyScope={row} />
        </TableCell>
      ),
    },
  ]

  const hasPolicyScopesDataWithIssues = policyScopesData.some(
    policyScope => policyScope.hasOwnProperty('hasIssues') && !!policyScope.hasIssues,
  )
  const dataGridColumns = !hasPolicyScopesDataWithIssues ? gridColumns.slice(1) : gridColumns

  const ListTable = (
    <DataGrid
      loading={!isReady}
      rows={policyScopesData}
      columns={dataGridColumns}
      disableColumnMenu
      disableColumnPinning
      disableColumnReorder
      disableChildrenSorting
      disableColumnResize
      disableBorder
      disableRowSelectionOnClick
      getRowId={({ code }) => code}
      onRowClick={({ row: { code } }) => {
        navigate(RoutesManager.policyCenter.policyScopes.view.root.getURL({ code }))
      }}
      getRowHeight={() => 'auto'}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      pageSizeOptions={[rowsPerPage]}
      rowCount={pagination.totalResults || policyScopesData?.length}
      hideFooterPagination={totalPages < 2}
      sx={{
        '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
          cursor: 'pointer',
        },
        '& .MuiDataGrid-columnHeader[role="columnheader"]:hover': {
          backgroundImage: 'unset',
        },
      }}
    />
  )

  return (
    <Box
      sx={{
        width: 1280,
        margin: '0 auto',
        flex: 1,
      }}
    >
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ListLayout
        headerTitle="Jurisdictions"
        tabsComponentProps={{ value: currentTab, onChange: handleChangeTab, tabItems }}
        actionHeaderBottomBlockComponent={
          isPermittedToCreatePolicyScopes && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                width: '100%',
              }}
            >
              <TooltipButton
                onClick={() => handleUpsertPolicyScopes()}
                title={
                  !isEntitledToCreatePolicyScopes
                    ? 'No jurisdiction entitlements remaining, please delete one or contact your admin for more'
                    : undefined
                }
                disabled={!isEntitledToCreatePolicyScopes}
              >
                Create Jurisdiction
              </TooltipButton>
            </Box>
          )
        }
      >
        {isReady && !policyScopes.length ? (
          <EmptyState
            iconName="OScales"
            title="There are no jurisdictions for this organization"
            subTitle={canCreatePolicyScopes ? 'Create your first one to populate the table.' : undefined}
            primaryButtonTitle="Create Jurisdiction"
            primaryButtonProps={{
              hidden: !canCreatePolicyScopes,
              onClick: () => handleUpsertPolicyScopes(),
            }}
          />
        ) : (
          <Root>
            {!isReady ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '50px 0',
                  backgroundColor: 'white.main',
                  borderRadius: '11px',
                  width: '1280px',
                  flex: 1,
                  height: '50vh',
                }}
              >
                <Spinner size="32px" thickness={2.5} />
              </Box>
            ) : (
              <Routes location={location}>
                <Route path="/" element={ListTable} />

                <Route path={RoutesManager.policyCenter.policyScopes.list.table.root.pattern} element={ListTable} />

                <Route
                  path={RoutesManager.policyCenter.policyScopes.list.map.root.pattern}
                  element={
                    <div className={classes.mapWrapper}>
                      <PolicyScopesMapMultiple regions={regionsForMap} initialViewState={initialViewState} />
                    </div>
                  }
                />
              </Routes>
            )}
          </Root>
        )}
      </ListLayout>
    </Box>
  )
}
