import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { FinalizeRequestFormValues } from '../utils'
import { useFormikContext } from 'formik'
import { RightsQueueViewContext } from '../../../context/RightsQueueViewContext'
import pluralize from 'pluralize'
import { finalizeRequestFormKeys } from '../utils/getValidationSchema'
import { FormSwitch } from 'components/form/FormSwitch'
import { FormDroplistButton } from 'components/form/FormDroplistButton'

export const CardContainer = styled(Box)(({ theme }) => ({
  gap: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '11px',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
}))

export const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

export const AllowAppealCard: React.FC = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<FinalizeRequestFormValues>()
  const { rightInvocation, appealWorkflows } = useContext(RightsQueueViewContext)

  const timeToInvokeAppealInDays = rightInvocation?.appeal?.settings?.timeToInvokeAppealInDays || 0
  const timeToFulfillAppealInDays = rightInvocation?.appeal?.settings?.timeToFulfillAppealInDays || 0

  return (
    <CardContainer>
      <FormSwitch
        formPropertyName="requestAllowsAppeal"
        onChange={(e, checked) => {
          if (!checked) {
            setFieldValue(finalizeRequestFormKeys.appealWorkflowDefinitionCode, '')
            setFieldTouched(finalizeRequestFormKeys.appealWorkflowDefinitionCode, false)
          }
        }}
      />

      <TextContainer>
        <Typography variant="label">Allow Appeal</Typography>

        <Typography
          variant="smallBody"
          color={values.requestAllowsAppeal ? 'darkDusk.main' : 'darkDuskFaded.main'}
          mb={values.requestAllowsAppeal ? 2 : undefined}
        >
          Appeal can be initiated by Data Subject through the Privacy Portal within{' '}
          <Typography
            variant={values.requestAllowsAppeal ? 'smallLabel' : 'smallBody'}
            color={values.requestAllowsAppeal ? 'darkDusk.main' : 'darkDuskFaded.main'}
          >
            {timeToInvokeAppealInDays}
          </Typography>{' '}
          {pluralize('day', timeToInvokeAppealInDays)} and must be resolved within{' '}
          <Typography
            variant={values.requestAllowsAppeal ? 'smallLabel' : 'smallBody'}
            color={values.requestAllowsAppeal ? 'darkDusk.main' : 'darkDuskFaded.main'}
          >
            {timeToFulfillAppealInDays}
          </Typography>{' '}
          {pluralize('day', timeToInvokeAppealInDays)}.
        </Typography>

        {values.requestAllowsAppeal && (
          <FormDroplistButton
            formPropertyName={finalizeRequestFormKeys.appealWorkflowDefinitionCode}
            required={!!values.requestAllowsAppeal}
            size="small"
            items={appealWorkflows}
            valueKey="code"
            placeholder="Select Workflow"
            label="Appeal Workflow"
          />
        )}
      </TextContainer>
    </CardContainer>
  )
}
