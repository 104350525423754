import React from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import pluralize from 'pluralize'

import { Text } from 'components/ui-kit/typography/Text'
import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { AssetManagerWidgetLayout, AssetTypeIcon } from 'pages/assetManager/components'
import { Chip } from 'components/ui-kit/chip/Chip'
import { ReactComponent as Branch } from 'assets/icons/locations-widget-spacer.svg'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      avatar: {
        backgroundColor: palette.fadedGrey.main,
        height: 24,
        width: 24,
        marginRight: spacing(1),
      },
      emptyState: {
        backgroundColor: palette.whiteFaded.main,
        padding: spacing(4, 0),
        minHeight: 0,
      },
      branch: {
        position: 'absolute',
        top: -10,
      },
      parentChip: {
        padding: spacing(0.5, 1),
        backgroundColor: palette.white.main,
        border: `1px solid ${palette.iron.main}`,
        '&:hover': {
          background: palette.white.main,
        },
      },
      resourceChip: {
        padding: spacing(0.5, 1),
      },
      assetTypeIcon: {
        marginRight: spacing(1),
        minWidth: 16,
        minHeight: 16,
      },
    }),
  { name: 'LocationWidget' },
)

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
  resourceParentsDescending: { name?: string; resourceTypeCode?: string; canonicalResourceType: number }[]
}

export const LocationWidget: React.FC<Props> = ({ assetSummary, resourceParentsDescending }) => {
  const classes = useStyles()

  return (
    <AssetManagerWidgetLayout
      title="Location"
      subTitle={`This asset has ${resourceParentsDescending?.length} ${pluralize(
        'parent',
        resourceParentsDescending?.length || 0,
      )}.`}
    >
      <Box display="flex" flexDirection="column" gap={1.25}>
        {resourceParentsDescending.map((parent, index) => (
          <Box key={parent.name} display="flex" alignItems="center" position="relative">
            {index !== 0 ? <Branch className={classes.branch} /> : null}
            <Chip className={classes.parentChip}>
              <Box display="flex" alignItems="center">
                <AssetTypeIcon assetType={parent?.canonicalResourceType || 0} className={classes.assetTypeIcon} />
                <Text size={14}>{parent?.name}</Text>
              </Box>
            </Chip>
          </Box>
        ))}

        <Box display="flex" alignItems="center" position="relative">
          <Branch className={classes.branch} />
          <Chip className={classes.resourceChip}>
            <Box display="flex" alignItems="center">
              <AssetTypeIcon
                assetType={assetSummary?.asset?.resource?.resourceType?.canonicalResourceType || 0}
                className={classes.assetTypeIcon}
              />
              <Text size={14}>{assetSummary?.asset?.resource?.name}</Text>
            </Box>
          </Chip>
        </Box>
      </Box>
    </AssetManagerWidgetLayout>
  )
}
