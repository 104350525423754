import { Box } from '@mui/material'
import { RelatedPath, DsrConfigFormData } from 'pages/assetManager/dsrConfig/interfaces'
import { getRelatedDataset } from 'pages/assetManager/dsrConfig/utils'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { FormSwitch } from 'components/ui-kit/form/switch/FormSwitch'
import { Text } from 'components/ui-kit/typography/Text'

type Props = {
  relatedPaths: RelatedPath[]
}

export const RelatedDatasetInclude: React.FC<Props> = ({ relatedPaths }) => {
  const { values, setFieldValue } = useFormikContext<DsrConfigFormData>()
  const { datasetId: relatedDataSetId } = getRelatedDataset(relatedPaths[0])
  const checkboxFieldName = `relatedDatasets.${relatedDataSetId}.included`
  const relatedDatasetsMap = values.relatedDatasets[relatedDataSetId] || {}
  const { included, ...relatedDatasetPaths } = relatedDatasetsMap
  const isPathsSelected = Object.values(relatedDatasetPaths).includes(true)
  const isDatasetIncluded = included && isPathsSelected

  useEffect(() => {
    setFieldValue(checkboxFieldName, isDatasetIncluded)
  }, [checkboxFieldName, setFieldValue, isDatasetIncluded])

  return (
    <Box display="flex" justifyContent="right" alignItems="center" gap={1} width={140}>
      <FormSwitch
        name={checkboxFieldName}
        disabled={!isPathsSelected}
        onChange={(_, checked) => {
          setFieldValue(checkboxFieldName, checked)
        }}
      />
      <Text size={14} weight={isDatasetIncluded ? 'bold' : 'normal'}>
        Include
      </Text>
    </Box>
  )
}
