import React, { createContext } from 'react'
import {
  InternalSystemDsrUtilsType,
  useInternalSystemDsrUtils,
} from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/InternalSystemDsr/hooks'

export const InternalSystemDsrContext = createContext({} as InternalSystemDsrUtilsType)

type Props = {
  children?: React.ReactNode
}

export const InternalSystemDsrContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useInternalSystemDsrUtils()

  return (
    <InternalSystemDsrContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </InternalSystemDsrContext.Provider>
  )
}

export const withInternalSystemDsrContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <InternalSystemDsrContextProvider>
        <Component {...props} />
      </InternalSystemDsrContextProvider>
    )
  }
}
