import { Box } from '@mui/material'
import { Button } from '@ketch-com/deck'
import { NavigationBreadCrumbsProps } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { CreateLayout } from 'components/ui-layouts/createLayout/CreateLayout'
import { CreateLayoutActions } from 'components/ui-layouts/createLayout/CreateLayoutActions'
import { CreateLayoutStepContent } from 'components/ui-layouts/createLayout/CreateLayoutStepContent'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { BasicDetailsForm } from './components/BasicDetailsForm'
import { PurposesForm } from './components/PurposesForm'
import { TranslationsForm } from './components/TranslationsForm'
import { getPurposeStacksInitialValues, StackForm, usePurposeStacksValidationSchema } from './utils/formValues'
import { JurisdictionsInOtherStacks } from './utils/types'

type Props = {
  breadcrumbs: NavigationBreadCrumbsProps['items']
  purposes: PurposeDTO[]
  jurisdictions: any[]
  languages: LanguageWithStatusDTO[]
  onSubmit: (formData: StackForm) => void
  jurisdictionsInOtherStacks: JurisdictionsInOtherStacks
}

enum StackFormStep {
  DETAILS = 'Basic Details',
  PURPOSES = 'Purposes',
  TRANSLATIONS = 'Translations',
}

const formSteps = [StackFormStep.DETAILS, StackFormStep.PURPOSES]

export const PurposeStacksCreateForm: React.FC<Props> = ({
  breadcrumbs,
  purposes,
  jurisdictions,
  languages,
  onSubmit,
  jurisdictionsInOtherStacks,
}) => {
  const validationSchema = usePurposeStacksValidationSchema()
  const initialValues = useMemo(() => getPurposeStacksInitialValues(), [])
  const navigate = useNavigate()
  useEffect(() => {
    // if enabled languages exist add form step
    !!languages.length && !formSteps.includes(StackFormStep.TRANSLATIONS) && formSteps.push(StackFormStep.TRANSLATIONS)
  }, [languages])

  return (
    <CreateLayout
      steps={formSteps}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      title={'Create Stack'}
      breadcrumbs={breadcrumbs}
    >
      {({ isSubmitting, submitForm, errors, touched }, { isLastStep, setNextStep }) => (
        <Box>
          <CreateLayoutStepContent step={StackFormStep.DETAILS}>
            <BasicDetailsForm
              jurisdictions={jurisdictions}
              showJurisdictionError={Boolean(errors.jurisdictions && touched.jurisdictions)}
              jurisdictionsInOtherStacks={jurisdictionsInOtherStacks}
            />
          </CreateLayoutStepContent>

          <CreateLayoutStepContent step={StackFormStep.PURPOSES}>
            <PurposesForm purposes={purposes} />
          </CreateLayoutStepContent>

          {!!languages.length && (
            <CreateLayoutStepContent step={StackFormStep.TRANSLATIONS}>
              <TranslationsForm languages={languages} />
            </CreateLayoutStepContent>
          )}

          <CreateLayoutActions>
            <Box display="flex" gap={1.5}>
              <Button
                color="secondary"
                size="large"
                pending={isSubmitting}
                onClick={() => {
                  navigate(RoutesManager.deployment.purposeStacks.root.getURL())
                }}
              >
                Cancel
              </Button>

              <Button
                size="large"
                pending={isSubmitting}
                onClick={() => {
                  if (isLastStep) {
                    submitForm()
                  } else {
                    setNextStep()
                  }
                }}
              >
                {isLastStep ? 'Save & Finish' : 'Next'}
              </Button>
            </Box>
          </CreateLayoutActions>
        </Box>
      )}
    </CreateLayout>
  )
}
