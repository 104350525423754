import { API } from '../../../utils/api/api'
import { formatRequestString } from '../../../utils/helpers/api/queryString'
import { PutProcessingActivityModelBiasRequestBodyDTO } from '@ketch-com/figurehead'

type Params = {
  formData: PutProcessingActivityModelBiasRequestBodyDTO
  activityId: string
}

type Response = {}

export const updateProcessingActivityModelBias = ({ formData, activityId }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/processing-activities/${activityId}/model-bias`,
    }),
    formData,
  )
