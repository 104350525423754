import React from 'react'
import Grid from '@mui/material/Grid'

import { FormRow } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'

type Props = {}

export const CreateAuthenticatorDetailsFormSection: React.FC<Props> = () => {
  return (
    <FormRow title="Basic Details">
      <Grid container spacing={3} mb={2}>
        <Grid item xs={6}>
          <FormInput
            label="Name"
            fullWidth
            required
            formPropertyName="name"
            placeholder="Example: Login to Service"
            hint="Display Name for use in Ketch systems"
            shouldUpdateDebounced
          />
        </Grid>
        <Grid item xs={6} />

        <Grid item xs={12}>
          <FormInput
            shouldUpdateDebounced
            label="Auth URL"
            fullWidth
            required
            formPropertyName="authUrl"
            hint="The endpoint used to initiate the OAuth2.0 flow. An authorization code is returned from here"
            placeholder="Example: https://example.com/oauth/token"
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            shouldUpdateDebounced
            label="Access Token URL"
            fullWidth
            required
            hint="The endpoint used to exchange the authorization code for an access token"
            formPropertyName="accessTokenUrl"
            placeholder="Example: https://example.com/oauth/token"
          />
        </Grid>

        <Grid item xs={6}>
          <FormInput
            label="Client ID"
            fullWidth
            hint="The unique identifier issued to the client that Ketch will use to perform the authorization flow"
            required
            formPropertyName="clientId"
            placeholder="Example: elmof8d6OoP3ZOqAsNh7r3ue"
            shouldUpdateDebounced
          />
        </Grid>

        <Grid item xs={6}>
          <FormInput
            label="Client Secret"
            hint="The secret issued to the client that Ketch will use to perform the authorization flow"
            fullWidth
            required
            formPropertyName="clientSecret"
            placeholder="Example: 24xjf8d6OoP3ZOqAsNh7r3ue"
            shouldUpdateDebounced
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            shouldUpdateDebounced
            fullWidth
            formPropertyName="scopes"
            hint="Any scopes requested by the client, in space-delimited format"
            label="Scopes"
            placeholder="Example: user:read profile:read account:read"
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            shouldUpdateDebounced
            label="Final Redirect URL"
            fullWidth
            // TODO:BAC - will only be required when `type` === 2, and that's not yet supported by BE
            required={false}
            formPropertyName="redirectUrl"
            placeholder="Redirect URL"
            hint="The final landing page the user should be directed to following the OAuth2.0 exchange. If not specified, will default to a Success page in the Ketch Privacy Portal"
          />
        </Grid>
      </Grid>
    </FormRow>
  )
}
