import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  id: string
  includeIssues?: boolean
  version?: boolean
}>

export const fetchTheme = ({ includeMetadata, includeIssues, id }: Params) =>
  API.get<ThemeV3DTO>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/themes/${id}`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
        },
      },
    }),
  )
