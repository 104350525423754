import { FileInfoDTO } from '@ketch-com/figurehead'
import { FileInfoDTO as FileInfoDTOLocal } from 'interfaces/files/fileInfo'

/**
 * Converts FileInfoDTO to FileInfoDTOLocal.
 * @param {FileInfoDTO} fileDTO - The source FileInfoDTO object.
 * @returns {FileInfoDTOLocal} - The converted FileInfoDTOLocal object.
 */
function convertFileInfoDTOToFileInfoDTOLocal(fileDTO: FileInfoDTO): FileInfoDTOLocal {
  // Extract extension from the name if available
  let extension = ''
  if (fileDTO.name) {
    const parts = fileDTO.name.split('.')
    if (parts.length > 1) {
      extension = parts[parts.length - 1]
    }
  }

  return {
    public_url: fileDTO.publicUrl ?? '',
    ID: fileDTO.id ?? '',
    content_type: fileDTO.contentType ?? '',
    download_url: fileDTO.downloadUrl ?? '',
    extension: extension,
    info_url: fileDTO.infoUrl ?? '',
    name: fileDTO.name ?? '',
    size: fileDTO.size ?? '',
  }
}

/**
 * Converts an array of FileInfoDTO to an array of FileInfoDTOLocal.
 * @param {FileInfoDTO[]} fileDTOArray - The array of FileInfoDTO objects.
 * @returns {FileInfoDTOLocal[]} - The array of converted FileInfoDTOLocal objects.
 */
// accepts an array of FileInfoDTO and returns an array of FileInfoDTOLocal
export function convertFileInfoDTOArrayToFileInfoDTOLocalArray(fileDTOArray: FileInfoDTO[]): FileInfoDTOLocal[] {
  return fileDTOArray.map(fileDTO => convertFileInfoDTOToFileInfoDTOLocal(fileDTO))
}

/**
 * Converts FileInfoDTOLocal to FileInfoDTO.
 * @param {FileInfoDTOLocal} fileDTO - The source FileInfoDTOLocal object.
 * @returns {FileInfoDTO} - The converted FileInfoDTO object.
 */
export function convertFileInfoDTOLocalToFileInfoDTO(fileDTO: FileInfoDTOLocal): FileInfoDTO {
  return {
    contentType: fileDTO.content_type || undefined,
    downloadUrl: fileDTO.download_url || undefined,
    id: fileDTO.ID || undefined,
    infoUrl: fileDTO.info_url || undefined,
    name: fileDTO.name || undefined,
    publicUrl: fileDTO.public_url || undefined,
    size: fileDTO.size || undefined,
  }
}
