// Third-party libraries
import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { useFormikContext } from 'formik'

// Components
import { Flex } from 'components/ui-layouts/flex/Flex'
import { Group } from 'components/ui-layouts/group/Group'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { Text } from 'components/ui-kit/typography/Text'
import { Input } from 'components/ui-kit/input/Input'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormCheckbox } from 'components/ui-kit/form/checkbox/FormCheckbox'
import { FormColorInput } from 'components/ui-kit/form/colorInput/FormColorInput'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { RadioGroup } from 'components/ui-kit/radioGroup/RadioGroup'
import { DefaultLabel } from './DefaultLabel'
import { BannerPositionVisualization } from '../../BannerPositionVisualization'
import { ExperiencePositionRadio } from '../../ExperiencePositionRadio'

// Types
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { ButtonVariant } from '@ketch-sdk/ketch-types'
import { fullConfigBeta } from '@ketch-com/lanyard'
import type { RadioOption } from 'components/ui-kit/radioGroup/RadioGroup'
import { ThemeBannerPosition } from 'interfaces/themes/themeBannerPosition'
import type { ThemeFormValues } from 'pages/consentAndRights/themes/upsert/utils'

// utils
import { capitalizeFirstLetterOfString } from 'utils/text/stringUtils'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'

const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      root: {
        position: 'absolute',
        top: 13,
        right: 24,
        bottom: 13,
        left: 24,
      },
      header: {
        display: 'block',
        marginBottom: 18,
        padding: '13px 0 26px 0',
        borderBottom: `1px solid ${palette.iron.main}`,
      },
      subHeader: {
        display: 'block',
        color: palette.darkDuskFaded.main,
      },
      content: {
        flex: 1,
        overflowY: 'auto',
      },
    }),
  { name: 'BannerView' },
)

type Props = {}

export const BannerView: React.FC<Props> = () => {
  const classes = useStyles()
  const { isEntitled } = useIsEntitled()
  const isEntitledToUnbranded = isEntitled(ENTITLEMENTS.EXP_SERVER_UNBRANDED)
  const { values, setFieldValue } = useFormikContext<ThemeFormValues>()

  // Convert ButtonVariant to RadioOption[]
  const radioOptions: RadioOption[] = Object.values(ButtonVariant).map(variant => ({
    title: variant === ButtonVariant.Contained ? 'Solid' : capitalizeFirstLetterOfString(variant),
    value: variant,
  }))

  return (
    <>
      <Flex flexDirection="column" className={classes.root}>
        <Text className={classes.header} size={20} weight={700} lineHeight={1.4}>
          Banner Customization
        </Text>

        <div className={classes.content}>
          <Text className={classes.subHeader} size={16} weight={800} lineHeight={1.12} mb={2}>
            General
          </Text>

          {/* Banner Position Input */}

          <FormRow marginBottom={24}>
            <Label required>Layout</Label>

            <Group gap={16}>
              {[
                ThemeBannerPosition.BOTTOM,
                ThemeBannerPosition.TOP,
                ThemeBannerPosition.BOTTOM_LEFT,
                ThemeBannerPosition.BOTTOM_RIGHT,
              ].map(position => (
                <ExperiencePositionRadio key={position} name="bannerPosition" value={position}>
                  <BannerPositionVisualization position={position} />
                </ExperiencePositionRadio>
              ))}
            </Group>
          </FormRow>

          {/* Typeface and Button Corner Radius Input */}

          <FormRow marginBottom={24}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormDropdown fullWidth label="Typeface" disabled name="font" items={[]} placeholder="Arial" />
              </Grid>
              <Grid item xs={6}>
                <FormInput required label="Buttons Corner Radius" name="buttonBorderRadius" />
              </Grid>
            </Grid>
          </FormRow>

          {/* Show Watermark Input */}

          <FormRow marginBottom={16}>
            <FormCheckbox
              disabled={!isEntitledToUnbranded}
              name="showWatermark"
              title={'Show "Powered by Ketch" Watermark'}
            />
          </FormRow>

          {/* Force Banner Interaction */}

          <FormRow marginBottom={48}>
            <FormCheckbox
              name="bannerForceInteraction"
              title="Force Banner Interaction"
              tooltip="Forces Data Subjects to select a banner button before being able to interact with the rest of the website."
            />
          </FormRow>

          {/* Color Inputs */}

          <Text size={16} weight={800} lineHeight={1.12} color="darkDuskFaded" mb={3} component="div">
            Body
          </Text>

          {/* Background Color */}

          <FormRow marginBottom={24}>
            <FormColorInput required fullWidth label="Background Color" name="bannerBackgroundColor" />
          </FormRow>

          {/* Content Color */}

          <FormRow marginBottom={24}>
            <Box display="flex" flexDirection="column">
              {!!values?.customizeBannerContentColor ? (
                <>
                  <DefaultLabel title="Content Color" />
                  <Input value="Default" disabled sx={{ mb: 1 }} />
                </>
              ) : (
                <FormColorInput required fullWidth label="Content Color" name="bannerContentColor" />
              )}

              <FormCheckbox
                name="customizeBannerContentColor"
                title="Use Default Color"
                onChange={(e, val) => {
                  setFieldValue('customizeBannerContentColor', val)
                  if (val) {
                    setFieldValue('bannerContentColor', '')
                  } else {
                    setFieldValue('bannerContentColor', fullConfigBeta?.theme?.bannerContentColor || '#071A24')
                  }
                }}
              />
            </Box>
          </FormRow>

          {/* Primary Button and Links Color */}

          <FormRow marginBottom={24}>
            <FormColorInput required fullWidth label="Primary Button & Links Color" name="bannerButtonColor" />
          </FormRow>

          {/* Secondary Button Color */}

          <FormRow marginBottom={24}>
            <Box display="flex" flexDirection="column">
              <FormColorInput required fullWidth label="Secondary Button Color" name="bannerSecondaryButtonColor" />
              <RadioGroup
                value={values?.bannerSecondaryButtonVariant}
                onChange={(_, val) => setFieldValue('bannerSecondaryButtonVariant', val)}
                options={radioOptions}
              />
            </Box>
          </FormRow>
        </div>
      </Flex>
    </>
  )
}
