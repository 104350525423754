import {
  Avatar,
  AvatarSize,
  EmptyState,
  Button,
  LabelChipColor,
  LabelChipVariant,
  PopUp,
  TabSegmented,
  TabSegmentedGroup,
  theme,
  Icon,
  CustomIconName,
} from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { useDataSystemsContext } from '../../context/DataSystemContext'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useSplitClassificationsByStatus, {
  ClassificationList,
  ClassificationListItem,
} from '../hooks/useSplitClassificationsByStatus'
import { AssetsTabTableLabelChip } from './AssetsTabTableLabelChip'
import { AssetClassificationTypeDTO, ConfirmationStatusV3DTO } from '@ketch-com/figurehead'
import { useManualClassificationVerification } from '../hooks/useManualClassificationVerification'
import { canonicalResourceTypeMap } from '../constants'

export interface AssetsTabRowEditModalProps {
  resourceName: string
  canonicalResourceType: string
  classificationName: string
  classificationType: string
  classificationList: ClassificationList
  refetchInstalledDatasystemAssets: () => void
  onClose?: () => void
}

const classificationTypeToFilterPropertyMap: Record<string, string> = {
  dataSensitivities: 'dataSensitivityCodes',
  dataCategories: 'dataCategoryCodes',
  dataTypes: 'dataTypeCodes',
}
const classificationTypeToIdPropertyMap: Record<string, string> = {
  dataSensitivities: 'assetDataSensitivityId',
  dataCategories: 'assetDataCategoryId',
  dataTypes: 'assetDataTypeId',
}

export const AssetsTabRowEditModal: React.FC<AssetsTabRowEditModalProps> = ({
  canonicalResourceType,
  classificationName,
  classificationList,
  onClose,
  classificationType,
  refetchInstalledDatasystemAssets,
  resourceName,
}) => {
  const { appDescriptor } = useDataSystemsContext()
  const [classificationStatusTab, setClassificationStatusTab] = useState<string>('assigned')
  const [assignedClassificationsList, setAssignedClassificationsList] = useState<ClassificationList>([])
  const [unassignedClassificationsList, setUnassignedClassificationsList] = useState<ClassificationList>([])

  const { assigned: assignedClassifications, unassigned: unassignedClassifications } =
    useSplitClassificationsByStatus(classificationList)

  const { handleManualClassificationVerification } = useManualClassificationVerification(appDescriptor?.id!)

  useEffect(() => {
    setAssignedClassificationsList(assignedClassifications)
  }, [assignedClassifications])

  useEffect(() => {
    setUnassignedClassificationsList(unassignedClassifications)
  }, [unassignedClassifications])

  const assetCount = useMemo(() => {
    if (classificationStatusTab === 'assigned') {
      return assignedClassificationsList.length
    } else {
      return unassignedClassificationsList.length
    }
  }, [classificationStatusTab, assignedClassificationsList.length, unassignedClassificationsList.length])

  const chipList = useMemo(() => {
    if (classificationStatusTab === 'assigned') {
      return assignedClassificationsList
    } else {
      return unassignedClassificationsList
    }
  }, [assignedClassificationsList, unassignedClassificationsList, classificationStatusTab])

  const handleRestore = useCallback(
    (classificationId: string) => {
      const item = (unassignedClassificationsList as any).find(
        (classification: ClassificationListItem) => classification.id === classificationId,
      )
      const classification = {
        ...item,
        confirmationStatus: ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
      }

      setAssignedClassificationsList([...assignedClassificationsList, classification])
      setUnassignedClassificationsList(
        (unassignedClassificationsList as any).filter(
          (classification: ClassificationListItem) => classification.id !== classificationId,
        ),
      )
    },
    [assignedClassificationsList, unassignedClassificationsList],
  )

  const restoreAll = useCallback(() => {
    const restoredClassifications = unassignedClassificationsList.map(classification => {
      return {
        ...classification,
        confirmationStatus: ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
      }
    })
    setAssignedClassificationsList([...assignedClassificationsList, ...restoredClassifications])
    setUnassignedClassificationsList([])
  }, [assignedClassificationsList, unassignedClassificationsList])

  const handleDismiss = useCallback(
    (classificationId: string) => {
      const classification = {
        ...((assignedClassificationsList as any).find(
          (classification: ClassificationListItem) => classification.id === classificationId,
        ) || {}),
        confirmationStatus: ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
      }
      setUnassignedClassificationsList([...unassignedClassificationsList, classification])
      setAssignedClassificationsList(
        (assignedClassificationsList as any).filter(
          (classification: ClassificationListItem) => classification.id !== classificationId,
        ),
      )
    },
    [assignedClassificationsList, unassignedClassificationsList],
  )

  const dismissAll = useCallback(() => {
    const dismissedClassifications = assignedClassificationsList.map(classification => {
      return {
        ...classification,
        confirmationStatus: ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
      }
    })
    setUnassignedClassificationsList([...unassignedClassificationsList, ...dismissedClassifications])
    setAssignedClassificationsList([])
  }, [assignedClassificationsList, unassignedClassificationsList])

  const handleConfirm = useCallback(
    (classificationId: string) => {
      const newAssignedClassifications = assignedClassificationsList.map(classification => {
        if (classification.id === classificationId) {
          return {
            ...classification,
            confirmationStatus: ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
          }
        }
        return classification
      })
      setAssignedClassificationsList(newAssignedClassifications)
    },
    [assignedClassificationsList],
  )

  const classificationTypeTpAssetClassificationTypeDTOMap: Record<string, AssetClassificationTypeDTO> = useMemo(
    () => ({
      dataSensitivities: AssetClassificationTypeDTO.AssetClassificationTypeDataSensitivityAssetClassificationType,
      dataCategories: AssetClassificationTypeDTO.AssetClassificationTypeDataCategoryAssetClassificationType,
      dataTypes: AssetClassificationTypeDTO.AssetClassificationTypeDataTypeAssetClassificationType,
    }),
    [],
  )

  const handleSave = useCallback(async () => {
    const initialValues = [...assignedClassifications, ...unassignedClassifications]
    const initialMap = new Map(initialValues.map(item => [item.id, item.confirmationStatus]))

    const updatedValues = [...assignedClassificationsList, ...unassignedClassificationsList]
    // Find all items in updatedArray where confirmationStatus has changed
    const classificationsToUpdate = updatedValues.filter(item => {
      const initialStatus = initialMap.get(item.id)
      return initialStatus !== undefined && initialStatus !== item.confirmationStatus
    })
    try {
      await Promise.all(
        classificationsToUpdate.map(classification =>
          handleManualClassificationVerification(
            classificationTypeTpAssetClassificationTypeDTOMap[classificationType],
            (classification as any)[classificationTypeToIdPropertyMap[classificationType]],
            classification.confirmationStatus!,
          ),
        ),
      )
      refetchInstalledDatasystemAssets()
      if (onClose) onClose()
    } catch (error) {
      console.error(error)
    }
  }, [
    assignedClassifications,
    unassignedClassifications,
    assignedClassificationsList,
    unassignedClassificationsList,
    refetchInstalledDatasystemAssets,
    onClose,
    handleManualClassificationVerification,
    classificationTypeTpAssetClassificationTypeDTOMap,
    classificationType,
  ])

  return (
    <PopUp
      variant="modal"
      onClose={() => {
        if (onClose) onClose()
      }}
      popUpCustomTitle={
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h3">{`${classificationName}`}</Typography>
          <Typography variant="h3" color={theme.palette.Text.Secondary}>
            in
          </Typography>
          <Avatar
            isLogo
            icon={canonicalResourceTypeMap[canonicalResourceType] as CustomIconName}
            variant="rounded"
            size={AvatarSize['medium']}
            backgroundColor={theme.palette.tertiary.main}
          />
          <Typography variant="h3">{resourceName}</Typography>
        </Box>
      }
      popUpActionChildren={
        <>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
        </>
      }
    >
      <Box display="flex" flexDirection="column" gap={3} width="800px">
        <Box>
          <TabSegmentedGroup
            value={classificationStatusTab}
            exclusive
            onChange={(event, newValue) => setClassificationStatusTab(newValue)}
          >
            <TabSegmented value="assigned" size="small">
              <Box display="flex" gap={0.5}>
                <Typography variant="label">Assigned</Typography>
                <Typography variant="label" color={theme.palette.Text.Secondary}>
                  {assignedClassificationsList.length}
                </Typography>
              </Box>
            </TabSegmented>
            <TabSegmented value="unassigned" size="small">
              <Box display="flex" gap={0.5}>
                <Typography variant="label">Unassigned</Typography>
                <Typography variant="label" color={theme.palette.Text.Secondary}>
                  {unassignedClassificationsList.length}
                </Typography>
              </Box>
            </TabSegmented>
          </TabSegmentedGroup>
        </Box>

        <Typography variant="body">Search for a label or remove those already assigned.</Typography>

        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            paddingTop={2}
            sx={{ borderTop: `1px solid ${theme.palette.Text.Disabled}` }}
          >
            <Box display="flex" gap={1}>
              <Typography variant="label">{`${
                classificationStatusTab === 'assigned' ? `Asset's` : 'Dismissed'
              } ${classificationName}`}</Typography>
              <Typography variant="label" color={theme.palette.Text.Secondary}>
                {assetCount}
              </Typography>
            </Box>
            <Button
              variant="link"
              color="secondary"
              onClick={classificationStatusTab === 'assigned' ? dismissAll : restoreAll}
            >{`${classificationStatusTab === 'assigned' ? 'Dismiss' : 'Restore'} All`}</Button>
          </Box>
        </Box>
        {chipList.length ? (
          <Box display="flex" flexWrap="wrap" gap={1} height="172px" alignContent="start" margin={2}>
            {chipList.map(classification => {
              return (
                <AssetsTabTableLabelChip
                  PopperProps={{ disablePortal: true }}
                  key={classification.id}
                  title={classification.name}
                  color={
                    classification.confirmationStatus === 'Dismissed'
                      ? LabelChipColor.fairly_confident
                      : LabelChipColor.confident
                  }
                  variant={
                    classificationName === 'Data Type' ? LabelChipVariant.classification : LabelChipVariant.category
                  }
                  hideConfidence
                  viewInsightsProps={{
                    classificationName: classification.name!,
                    classificationCode: classification.code!,
                    classificationId: (classification as any)[classificationTypeToIdPropertyMap[classificationType]],
                    classificationDescription: 'No Description Available',
                    classificationFilterKey: classificationTypeToFilterPropertyMap[classificationType],
                    onClickCallback: onClose,
                  }}
                  customTooltipActions={
                    classificationStatusTab !== 'assigned' ? (
                      <Button
                        sx={{ width: '192px' }}
                        variant="iconSubtle"
                        onClick={() => handleRestore(classification.id!)}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="start"
                          marginTop="4px"
                          paddingX={0.5}
                          flexGrow={1}
                          gap={0.5}
                          width="192px"
                        >
                          <Icon name="ORestore" iconColor={theme.palette.White.o100} />
                          <Typography variant="label" color={theme.palette.Text.PrimaryLight}>
                            Revert
                          </Typography>
                        </Box>
                      </Button>
                    ) : undefined
                  }
                  onConfirm={() => {
                    handleConfirm(classification.id!)
                  }}
                  onDismiss={() => {
                    handleDismiss(classification.id!)
                  }}
                />
              )
            })}
          </Box>
        ) : (
          <EmptyState
            title={`No ${classificationStatusTab === 'assigned' ? 'Assigned' : 'Dismissed'} Labels`}
            subTitle={`There are no ${
              classificationStatusTab === 'assigned' ? 'assigned' : 'manually dismissed'
            } ${classificationName} for this specific data asset.`}
            iconName="OLabel"
          />
        )}
      </Box>
    </PopUp>
  )
}
