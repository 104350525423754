export enum CookieCategory {
  STRICTLY_NECESSARY = 1,
  FUNCTIONAL = 2,
  PERFORMANCE = 3,
  MARKETING = 4,
}

export const CookieCategoryNames = {
  [CookieCategory.STRICTLY_NECESSARY]: 'Strictly Necessary',
  [CookieCategory.FUNCTIONAL]: 'Functional',
  [CookieCategory.PERFORMANCE]: 'Performance',
  [CookieCategory.MARKETING]: 'Marketing',
}

export const CookieCategoryOptions = [
  { id: CookieCategory.STRICTLY_NECESSARY, name: CookieCategoryNames[CookieCategory.STRICTLY_NECESSARY] },
  { id: CookieCategory.FUNCTIONAL, name: CookieCategoryNames[CookieCategory.FUNCTIONAL] },
  { id: CookieCategory.PERFORMANCE, name: CookieCategoryNames[CookieCategory.PERFORMANCE] },
  { id: CookieCategory.MARKETING, name: CookieCategoryNames[CookieCategory.MARKETING] },
]
