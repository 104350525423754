import { Chip, DropListButton, Spinner, TextInput } from '@ketch-com/deck'
import {
  Operation,
  OperationTypes,
  OperationTypesWithDesc,
  Rule,
  ValidOperations,
} from 'pages/consentAndRights/applications/upsert/components/ApplicationPredicates.types'
import Grid from '@mui/material/Grid'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { Logger } from 'utils/Logger'

type Props = {
  rule: Rule
  index: number
  onChange: (rule: Rule, key: string, value: string, isDelete: boolean) => void
}
export default function EditRule({ rule, index, onChange }: Props) {
  const updateRule = (key: string, value: string) => {
    onChange(rule, key, value, false)
  }
  return (
    <Grid container spacing={1}>
      {index > 0 && (
        <Grid item xs={12} mb={1}>
          <Chip color="secondary" isSelected label="AND"></Chip>
        </Grid>
      )}
      <Grid item xs={6} md={4}>
        <DropListButton
          fullWidth
          getOptionLabel={o => OperationTypesWithDesc[o as OperationTypes]}
          label="Type"
          onChange={(_, value) => value && updateRule('type', value)}
          options={Object.keys(OperationTypesWithDesc)}
          value={rule.type}
        />
      </Grid>
      {rule.type ? (
        <Grid item xs={6} md={4}>
          <DropListButton
            fullWidth
            getOptionLabel={o => Operation[o]?.label || ''}
            label="Operation"
            onChange={(_, value) => {
              value && updateRule('operation', value)
            }}
            options={ValidOperations[rule.type]}
            value={rule.operation || ''}
          />
        </Grid>
      ) : null}
      <DetailsStep rule={rule} onChange={updateRule} />
    </Grid>
  )
}

function PurposeList({ onChange, value }: { value: string; onChange: (value: string) => void }) {
  const purposesResponse = usePurposes()
  if (purposesResponse.isError) {
    Logger.error('Could not fetch purposes')
    return null
  } else if (purposesResponse.isLoading || !purposesResponse.data) {
    return <Spinner size="16px" />
  }
  return (
    <DropListButton
      label="Purpose"
      fullWidth
      onChange={(_, v) => v && onChange(v)}
      options={purposesResponse.data.map(p => p.code)}
      getOptionLabel={p => purposesResponse.data.find(i => i.code === p)?.name || ''}
      value={value}
    />
  )
}

function DetailsStep({ rule, onChange }: { rule: Rule; onChange: (key: string, value: string) => void }) {
  if (!Boolean(rule.operation)) {
    return null
  } else if (rule.type === OperationTypes.CONSENT) {
    return (
      <Grid item xs={6} md={4}>
        <PurposeList
          value={rule.details || ''}
          onChange={v => {
            onChange('details', v)
          }}
        />
      </Grid>
    )
  } else if (rule.operation === Operation.URL.key) {
    return (
      <Grid item xs={6} md={4}>
        <TextInput
          autoFocus
          helperText={'complete or part of the URL'}
          label={Operation.URL.detailLabel}
          onChange={e => {
            onChange('details', e.target.value)
          }}
          required
          value={rule.details || ''}
        />
      </Grid>
    )
  } else if (rule.type === OperationTypes.COHORTS || rule.type === OperationTypes.PAGE_ATTR) {
    return (
      <>
        <Grid item xs={6} md={4}>
          <TextInput
            autoFocus
            helperText={'Key/Variable Name'}
            label={'Key'}
            onChange={e => {
              onChange('variableName', e.target.value)
            }}
            required
            value={rule.variableName || ''}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextInput
            autoFocus
            helperText={'Value It Should Hold'}
            label={'Value'}
            onChange={e => {
              onChange('details', e.target.value)
            }}
            required
            value={rule.details || ''}
          />
        </Grid>
      </>
    )
  } else if (Operation[rule.operation].detailLabel) {
    return (
      <Grid item xs={6} md={4}>
        <TextInput
          autoFocus
          helperText={Operation[rule.operation].detailHelperText}
          label={Operation[rule.operation].detailLabel}
          onChange={e => {
            onChange('details', e.target.value)
          }}
          required
          value={rule.details || ''}
        />
      </Grid>
    )
  }
  return null
}
