import { IdentitySpaceMapDTO } from 'interfaces/identitySpaces/identitySpace'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { Chip } from '@ketch-com/deck'
import { Box, Tooltip, Typography } from '@mui/material'

const IdentitySpacesTooltip = ({ identitySpaceMaps }: { identitySpaceMaps: IdentitySpaceMapDTO[] }) => (
  <Box display="flex" flexDirection="column">
    {identitySpaceMaps.map((identitySpace, index: number) => (
      <Box display="flex" alignItems="center" justifyContent="center" gap="2px" key={index}>
        <Typography variant="smallBody" component={Box}>
          {identitySpace.destinationIdentitySpaceCode} + {identitySpace.sourceIdentitySpaceCode}
        </Typography>
      </Box>
    ))}
  </Box>
)

export const IdentitySpacesRenderer = ({ identitySpaceMaps }: { identitySpaceMaps: IdentitySpaceMapDTO[] }) => {
  const length = identitySpaceMaps.length
  const hasItems = length !== 0

  return hasItems ? (
    <Tooltip placement="bottom" title={<IdentitySpacesTooltip identitySpaceMaps={identitySpaceMaps} />}>
      <div>
        <Chip size="small" label={length} sx={{ minWidth: '24x' }} clickable />
      </div>
    </Tooltip>
  ) : (
    <EmptyValueRenderer />
  )
}
