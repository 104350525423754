import { EmptyState, theme } from '@ketch-com/deck'
import { memo } from 'react'
import { ReactComponent as EmptyWorkQueueIcon } from 'assets/icons/emptyState/EmptyStateApplicationWorkQueue.svg'

export const ApplianceCreationPrompt: React.FC = memo(() => {
  return (
    <EmptyState
      title="Queue is Empty"
      subTitle="There are no requests in the queue to the transponder yet.  Once they are sent they will appear here."
      customIcon={<EmptyWorkQueueIcon />}
      sx={{
        borderRadius: 4,
        border: `1px solid ${theme.palette.Common.Divider}`,
      }}
    />
  )
})
