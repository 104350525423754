import React from 'react'

import { TypographyProps, Typography } from '@mui/material'

export type Props = { children?: React.ReactNode } & TypographyProps

export const EmptyValueRendererV2: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Typography variant="body" color={props.color || 'darkDuskFaded.main'} {...props}>
      {children || 'None'}
    </Typography>
  )
}
