import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  id: string
}

export const deleteExperience = ({ id }: Params) =>
  API.delete<null>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/experiences/${id}`,
    }),
  )
