import { RadioGroup, Typography, styled } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Radio } from '@ketch-com/deck'
import Box from '@mui/material/Box'
import React from 'react'
import { useField } from 'formik'

type Option = {
  value: string
  label: React.ReactNode
  belowLabel?: React.ReactNode
}

type Props = {
  name: string
  title?: string
  options: Option[]
  row?: boolean
  onChange?: (value: any) => void
}

const OptionBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

export const SidebarFormRadioGroup: React.FC<Props> = ({ title, name, options, row, onChange }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ value }, _, { setValue }] = useField(name)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (onChange) onChange(e.target.value)
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap="4px">
      {title && <Typography variant={'label'}>{title}</Typography>}
      <RadioGroup value={value} onChange={handleChange} row={row}>
        {options.map(({ label, belowLabel, value: optionValue }, index) => (
          <OptionBox key={optionValue} mb={index < options.length - 1 ? '8px' : 0}>
            <FormControlLabel
              value={optionValue}
              control={<Radio />}
              label={<Typography variant={value === optionValue ? 'label' : 'body'}>{label}</Typography>}
            />
            {belowLabel}
          </OptionBox>
        ))}
      </RadioGroup>
    </Box>
  )
}
