import React from 'react'
import Box from '@mui/material/Box'

import { ReactComponent as TagIcon } from 'assets/icons/tag.svg'
import { ReactComponent as AbcIcon } from 'assets/icons/abc.svg'
import { AssetPolicyAssetDTO } from '@ketch-com/figurehead'
import { Badge } from 'components/ui-kit/badge/Badge'
import { BadgeKeyValue } from 'components/ui-kit/badge/BadgeKeyValue'

type Props = {
  resources: AssetPolicyAssetDTO[]
}

export const ResourcesDetailsCellRenderer: React.FC<Props> = ({ resources }) => (
  <Box display="flex" flexDirection="column">
    {resources.map((r, index) => {
      const labelKey = Object.keys(r?.labeled || {})?.[0] || ''
      const labelValue = Object.values(r?.labeled || {})?.[0] || ''

      return (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          key={`${r?.type || ''}_${r?.named || ''}_${r.labeled}_${index}`}
          mb={index !== resources.length - 1 ? 3.25 : 1.75}
        >
          <Box mr={1.25} display="flex" alignItems="center" justifyContent="center">
            {r.named ? <AbcIcon /> : <TagIcon />}
          </Box>
          {r.named || null}
          {labelKey && labelValue && <BadgeKeyValue badgeKey={labelKey} badgeValue={labelValue} />}
          {labelKey && !labelValue && <Badge>{labelKey}</Badge>}
        </Box>
      )
    })}
  </Box>
)
