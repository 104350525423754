import { useState, useEffect } from 'react'
import { AssetsFiltersData } from '../../types'

export type InstalledDataSystemAssetsQueryConfig = {
  params: {
    id?: string
    filters?: InstalledDataSystemAssetsQueryConfigFilters
  }
}

export type InstalledDataSystemAssetsQueryConfigFilters = Record<string, string[] | string | undefined>

export type PaginationModel = {
  page: number
  pageSize: number
}

const useAssetQueryPayload = (
  appDescriptorId: string | undefined,
  filtersData: AssetsFiltersData,
  searchString?: string,
  staticFilter?: [string, string],
) => {
  const [assetCategory, setAssetCategory] = useState<string>()
  const [parentResourceID, setParentResourceID] = useState<string>()
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  })

  const [assetQueryPayload, setAssetQueryPayload] = useState<InstalledDataSystemAssetsQueryConfig>({
    params: {
      id: appDescriptorId,
    },
  })

  useEffect(() => {
    const basefilters: Record<string, string | undefined> = {
      offset: `${paginationModel.page * paginationModel.pageSize}`,
      limit: `${paginationModel.pageSize}`,
      resourceType: assetCategory,
      parentResourceID,
      searchString,
    }

    const dynamicFilters = Object.entries(filtersData).reduce(
      (acc, [key, values]) => {
        const accumulator: string[] = []
        if (staticFilter && key === staticFilter[0]) {
          accumulator.push(staticFilter[1])
        }
        const selectedValues: string[] = values.reduce(
          (selectedList, filterOption) => {
            if (filterOption.isSelected) {
              selectedList.push(filterOption.id)
            }
            return selectedList
          },
          [...accumulator],
        )
        acc[key] = [selectedValues.join(',')]
        return acc
      },
      {} as Record<string, string[]>,
    )

    const filters = { ...dynamicFilters, ...basefilters }

    const newQueryPayload = {
      params: {
        id: appDescriptorId,
        filters,
      },
    }

    setAssetQueryPayload(newQueryPayload)
  }, [
    appDescriptorId,
    assetCategory,
    filtersData,
    paginationModel.page,
    paginationModel.pageSize,
    parentResourceID,
    searchString,
    staticFilter,
  ])

  return {
    assetCategory,
    setAssetCategory,
    parentResourceID,
    setParentResourceID,
    assetQueryPayload,
    paginationModel,
    setPaginationModel,
  }
}

export default useAssetQueryPayload
