import { Drawer as MUIDrawer, PaperProps } from '@mui/material'
import React from 'react'
import { isFunction } from 'lodash'

import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon-nocolor.svg'
import Box from '@mui/material/Box'
import { Button, theme } from '@ketch-com/deck'

type Props = {
  /** Component to display inside the drawer */
  children?: React.ReactNode
  /** Where the drawer should originate from */
  anchor?: 'bottom' | 'top' | 'left' | 'right'
  /** Boolean indicating if the drawer is displayed */
  open?: boolean
  /** Function to call when the drawer is closed, passing this will cause a close icon to be displayed in the top right */
  onClose?: () => void
  /** Override for the default paper props */
  PaperProps?: PaperProps
}

// Default styles for each anchor point
const defaultPaperProps = {
  bottom: {
    sx: {
      height: '80%',
      width: '100%',
      maxWidth: '1300px',
      margin: 'auto', // Centered
      padding: '32px',
      borderRadius: '37px 37px 0px 0px',
    },
  },
  top: {
    sx: {
      height: '80%',
      width: '100%',
      maxWidth: '1300px',
      margin: 'auto', // Centered
      padding: '32px',
      borderRadius: '0px 0px 37px 37px',
    },
  },
  left: {
    sx: {
      height: '100%',
      width: '80%',
      maxWidth: '1300px',
      margin: 'auto', // Centered
      padding: '32px',
      borderRadius: '0px 37px 37px 0px',
    },
  },
  right: {
    sx: {
      height: '100%',
      width: '80%',
      maxWidth: '1300px',
      margin: 'auto', // Centered
      padding: '32px',
      borderRadius: '37px 0px 0px 37px',
    },
  },
}

export const Drawer: React.FC<Props> = ({ children, anchor = 'bottom', open = true, onClose, PaperProps, ...rest }) => {
  return (
    <MUIDrawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      PaperProps={{ ...defaultPaperProps[anchor], ...PaperProps }}
      {...rest}
    >
      {/* Show a close button if an onClose function was passed */}
      {isFunction(onClose) && (
        <Box position="absolute" top={32} right={32}>
          <Button onClick={onClose} variant="iconCustomRounded" color="tertiary">
            <CloseIcon width="12px" height="12px" fill={theme.palette.darkDusk.main} />
          </Button>
        </Box>
      )}
      {children}
    </MUIDrawer>
  )
}
