import { PurchasePlansDTO } from 'interfaces/auth/authData'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchPurchasePlans = () =>
  API.get<PurchasePlansDTO>(
    formatRequestString({
      entityUrl: `/api/provision/plans`,
    }),
  )
