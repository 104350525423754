import React, { useContext } from 'react'

import { UpsertInlineLayoutPreview } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutPreview'
import { Preview, Sidebar } from './components'
import { PreferenceExperienceTab } from '../../utils/preference/constants'
import { ExperienceUpsertContext } from '../../context/ExperienceUpsertContext'

type Props = {}

export const UpsertPreference: React.FC<Props> = () => {
  const { activeTab, sidebarActiveTab } = useContext(ExperienceUpsertContext)

  return (
    <UpsertInlineLayoutPreview>
      <Preview activeTab={activeTab as PreferenceExperienceTab} />

      {!!sidebarActiveTab && <Sidebar />}
    </UpsertInlineLayoutPreview>
  )
}
