import React, { useState } from 'react'

import { useWorkflowsPaginated } from 'api/workflows/queries/useWorkflowsPaginated'
import { useOrchestrationEvents } from 'api/orchestrationEventsV2/queries/useOrchestrationEvents'
import { WorkflowsList } from 'pages/orchestration/workflows/list/WorkflowsList'

export const WorkflowsListContainer: React.FC = () => {
  const { data: events, isLoading: isEventsListLoading, isFetching: isEventsListFetching } = useOrchestrationEvents()
  const [searchValue, setSearchValue] = useState('')
  const {
    data: workflows,
    isLoading: isWorkflowsListLoading,
    isFetching: isWorkflowsListFetching,
    pagination,
  } = useWorkflowsPaginated({
    params: {
      includeIssues: true,
      includeMetadata: true,
    },
  })

  const onHandleSearch = (value: string) => {
    setSearchValue(value)
  }

  const filteredWorkflows = !searchValue
    ? workflows
    : workflows.filter(elem => {
        const lowerCaseSearchValue = searchValue.toLowerCase()
        return (
          elem.name.toLowerCase().includes(lowerCaseSearchValue) ||
          elem.code.toLowerCase().includes(lowerCaseSearchValue)
        )
      })

  const isLoading = isEventsListLoading || isWorkflowsListLoading
  const isFetching = isWorkflowsListFetching || isEventsListFetching

  return (
    <WorkflowsList
      isLoading={isLoading}
      isFetching={isFetching}
      workflows={filteredWorkflows}
      events={events}
      pagination={pagination}
      onHandleSearch={onHandleSearch}
      searchValue={searchValue}
    />
  )
}
