import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { SidebarRouteItem } from 'utils/routing/types'

export enum SidebarSection {
  Home = 'home',
  PrivacyProgram = 'privacyProgram',
  ConsentRights = 'consentRights',
  DataMap = 'dataMap',
  Reports = 'reports',
}

export interface SidebarSearchResult {
  parents: string[]
  title: string
  path: string
  permissions?: PERMISSIONS[]
  entitlements?: ENTITLEMENTS[]
  description?: string
}

export const orderSidebarSearchRoutes = (routes: SidebarSearchResult[], search: string) => {
  const searchLowerCase = search.toLocaleLowerCase()

  // Show items starting with the search string first, followed by those that just contain it, each in alphabetical order
  const startingWithSearch = routes
    .filter(item => item.title.toLocaleLowerCase().startsWith(searchLowerCase))
    .sort((a, b) => a.title.localeCompare(b.title))
  const containingSearch = routes
    .filter(item => !item.title.toLocaleLowerCase().startsWith(searchLowerCase))
    .sort((a, b) => a.title.localeCompare(b.title))

  return [...startingWithSearch, ...containingSearch]
}

export const getSidebarSearchRoutes = (routes: SidebarRouteItem[], search: string): SidebarSearchResult[] => {
  const searchLowerCase = search.toLocaleLowerCase()
  const searchRoutes = (routes: SidebarRouteItem[], search: string, parents: string[] = []): SidebarSearchResult[] => {
    let results: SidebarSearchResult[] = []

    for (const route of routes) {
      if (route.title.toLocaleLowerCase().includes(search)) {
        results.push({
          parents,
          title: route.title,
          path: route.path,
          entitlements: route.entitlements,
          permissions: route.permissions,
          description: route.description,
        })
      }

      if (route.subRoutes) {
        if (Array.isArray(route.subRoutes)) {
          results = results.concat(searchRoutes(route.subRoutes, search, [...parents, route.title]))
        } else {
          for (const subRouteSection in route.subRoutes) {
            results = results.concat(
              searchRoutes(route.subRoutes[subRouteSection], search, [...parents, route.title, subRouteSection]),
            )
          }
        }
      }
    }

    return results
  }

  return orderSidebarSearchRoutes(searchRoutes(routes, searchLowerCase), searchLowerCase)
}
