import React, { createContext } from 'react'
import { UseTaskViewUtilsReturnType, useTaskViewUtils } from '../hooks'

export const TaskContext = createContext({} as UseTaskViewUtilsReturnType)

type Props = {
  children?: React.ReactNode
}

export const TaskContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useTaskViewUtils()

  return (
    <TaskContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </TaskContext.Provider>
  )
}

export const withTaskContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <TaskContextProvider>
        <Component {...props} />
      </TaskContextProvider>
    )
  }
}
