import { CreateApplianceRequest } from '@ketch-com/windlass/dist/appliance/validation'
import { useValidateTransponderName } from 'api/assets/mutations/useValidateTransponderName'
import { useMemo } from 'react'
import * as Yup from 'yup'

export const useTransponderCreateValidationSchema = (name = '') => {
  const validateTransponderName = useValidateTransponderName()

  return useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .required('A transponder name is required.')
          .test('is-unique', `Transponder name already exists. Please choose a unique name.`, async value => {
            if (!value) {
              return false
            }
            try {
              const payload: CreateApplianceRequest = { name: value }
              const response = await validateTransponderName.mutateAsync({ params: payload })
              return response.data.valid
            } catch (error) {
              console.error('Error validating transponder name:', error)
              return false
            }
          }),
      }),
    [validateTransponderName],
  )
}
