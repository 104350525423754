import {
  WORKFLOW_TYPE_ENUM,
  DELETE_OBLIGATION_OPTIONS,
  GET_OBLIGATION_OPTIONS,
  DATA_TYPE_DEPENDENT_OBLIGATION_OPTIONS,
} from '../../../constants'

type ObligationOptions = {
  id: string
  name: string
}[]

type Args = {
  canonicalRightCode: string
  attributeDataType: string
}

const conditions = [
  'String',
  'Integer',
  'Int',
  'Int8',
  'Int16',
  'Int32',
  'Int64',
  'Uint',
  'Uint8',
  'Uint16',
  'Uint32',
  'Uint64',
]

const getDataTypeDependentObligationOptions = (attributeDataType: string): ObligationOptions => {
  let payload: ObligationOptions = []
  if (conditions.some(condition => condition.toLowerCase() === attributeDataType.toLowerCase())) {
    payload = [...DATA_TYPE_DEPENDENT_OBLIGATION_OPTIONS]
  }
  return payload
}

export const getObligationOptions = ({ canonicalRightCode, attributeDataType }: Args): ObligationOptions => {
  switch (canonicalRightCode) {
    case WORKFLOW_TYPE_ENUM.DELETE:
      return [...DELETE_OBLIGATION_OPTIONS, ...getDataTypeDependentObligationOptions(attributeDataType)]
    case WORKFLOW_TYPE_ENUM.GET: // GET is also referred to as "Access" in conversation, and is presented to the user as "Provide Data"
      return [...GET_OBLIGATION_OPTIONS]
    default:
      return [...DELETE_OBLIGATION_OPTIONS, ...getDataTypeDependentObligationOptions(attributeDataType)]
  }
}
