import React, { useState } from 'react'

// api
import { Pagination } from 'api/common/paginatedQuery'

// components
import { EventsListContent, EventsListHeader } from './components'
import { RightsModal } from './components/RightsModal'
import { ViewListLayout } from 'components/ui-layouts/viewListLayout/ViewListLayout'

// types
import { EventV2DTO } from '@ketch-com/figurehead'
import { RightsModalType } from 'interfaces/rights/right'
import { WorkflowDTO } from 'interfaces/workflows'

type Props = {
  events: EventV2DTO[]
  isLoading: boolean
  isReady: boolean
  pagination: Pagination
  workflows: WorkflowDTO[]
}

export const EventsList: React.FC<Props> = ({ events, isLoading, isReady, pagination, workflows }) => {
  const [rightsModal, setRightsModal] = useState<RightsModalType>()

  return (
    <ViewListLayout isReady={isReady}>
      {/* Events Header */}
      <EventsListHeader events={events} />

      {/* Events Content */}
      <EventsListContent
        events={events}
        isLoading={isLoading}
        pagination={pagination}
        setRightsModal={setRightsModal}
        workflows={workflows}
      />

      {/* Rights Modal */}
      {rightsModal?.showModal && (
        <RightsModal
          canonicalRightCode={rightsModal?.canonicalRightCode}
          onCancel={() =>
            setRightsModal({
              ...rightsModal,
              showModal: false,
            })
          }
        />
      )}
    </ViewListLayout>
  )
}
