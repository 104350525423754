import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchProperties } from '../fetchers/fetchProperties'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.properties,
  queryFn: fetchProperties,
  select: res => res?.data?.properties || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useProperties = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    itemsPerPage: config?.itemsPerPage || 1000,
    params: {
      ...config?.params,
    },
  })
}
