import { useState } from 'react'

type Args = {
  onOpen?: () => any
  onClose?: () => any
  onChange?: (args: any) => any
}

export const useDropdownV2Utils = ({ onOpen, onClose, onChange }: Args) => {
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(null)
  const isDropdownOpen = Boolean(dropdownAnchorEl)

  const handleDropdownOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isDropdownOpen && onOpen) onOpen()
    setDropdownAnchorEl(event.currentTarget)
  }

  const handleMenuOnClose = () => {
    setDropdownAnchorEl(null)
    if (onClose) onClose()
  }

  const handleMenuItemOnClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (onChange) onChange(event.currentTarget.id as string)
    setDropdownAnchorEl(null)
  }

  return {
    dropdownAnchorEl,
    isDropdownOpen,
    handleDropdownOnClick,
    setDropdownAnchorEl,
    handleMenuOnClose,
    handleMenuItemOnClick,
  }
}
