import { useQueryParams } from 'utils/hooks'

import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { Button, ContentGroup, DataGrid, Spinner } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { CSSProperties } from 'react'
import { FormikProvider, useFormik, useFormikContext } from 'formik'
import { useDataSystemsInfinite } from 'api/dataSystems/queries/useDataSystemsInfinite'
import { Filters, ShowFilters } from 'pages/dataSystems/components/Filters/Filters'
import { getDataSystemV2DTOColumns } from './utils'
import { Drawer } from '../../../../../components/ui-kit/drawer/Drawer'

type Props = {
  setViewAllSystemsOpen?: any
  className?: string
  style?: CSSProperties
  onClose: () => void
  isOpen: boolean
}

export const SystemsSelector: React.FC<Props> = ({ setViewAllSystemsOpen, onClose, isOpen }) => {
  const { queries } = useQueryParams<{
    q?: string
    integrations?: string
  }>()

  // outside formik context, used to update the actual data for the add system flow
  const { setFieldValue, submitForm } = useFormikContext()

  const {
    data: dataSystems,
    isLoading,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useDataSystemsInfinite({
    q: queries.q,
    notInstalled: true,
    hasIntegration: !!queries.integrations,
  })

  const onSubmit = async (values: any) => {}

  const handleSubmit = async (values: any) => {
    const selectedDataSystems = values.selectedDataSystems.map((selectedDataSystem: string) =>
      dataSystems.find(elem => elem.code === selectedDataSystem),
    )

    setFieldValue('selectedDataSystems', selectedDataSystems)

    submitForm()

    setViewAllSystemsOpen(false)
  }

  const isReady = !isLoading || !isRefetching

  const initialValues = { selectedDataSystems: [] as Array<string> }

  // inner formik context, used for the datagrid
  const formikProps = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validateOnMount: true,
    enableReinitialize: true,
  })

  const { setFieldValue: innerSetFieldValue, isSubmitting, values } = formikProps

  const columns = getDataSystemV2DTOColumns({ values, setFieldValue: innerSetFieldValue })

  return (
    <FormikProvider value={formikProps}>
      <Drawer
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: {
            height: '90%',
            width: '100%',
            maxWidth: '1300px',
            margin: 'auto', // Centered
            padding: '32px',
            borderRadius: '37px 37px 0px 0px',
          },
        }}
      >
        <Box mb={4}>
          <ContentGroup
            title="Select System"
            subTitle="Select several systems to add, view available integration capabilities."
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Filters show={[ShowFilters.SEARCH, ShowFilters.INTEGRATIONS]} />
            <Button
              pending={isSubmitting}
              disabled={!values.selectedDataSystems.length}
              size="large"
              onClick={() => handleSubmit(values)}
            >
              {values.selectedDataSystems.length > 1 ? (
                <div>Add {values.selectedDataSystems.length} Systems</div>
              ) : (
                <div>Add System</div>
              )}
            </Button>
          </Box>
          <Box display="flex" alignItems="center" gap={3}>
            <Typography variant="label" color="darkDuskFaded.main">
              {dataSystems.length} systems available
            </Typography>
            <Typography variant="label">{values.selectedDataSystems.length || 0} selected</Typography>
          </Box>

          <FormRow>
            {!isReady ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Spinner size="32px" thickness={2.5} />
              </Box>
            ) : (
              <Box height="60vh">
                <DataGrid
                  autosizeOnMount
                  autosizeOptions={{
                    includeHeaders: true,
                    includeOutliers: false,
                    expand: true,
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  disableChildrenSorting
                  disableColumnMenu
                  disableColumnPinning
                  disableColumnResize
                  disableColumnReorder
                  disableRowHoverStates
                  disableRowSelectionOnClick
                  getRowId={row => row.code}
                  rows={dataSystems}
                  loading={isFetchingNextPage || isLoading}
                  hideFooter
                  noRowsOverlayProps={{
                    title: 'No data systems matching your search criteria',
                    subTitle: '',
                    buttonTitle: '',
                  }}
                  paginationLoading={isFetchingNextPage || isLoading}
                  onRowsScrollEnd={() => {
                    if (!isFetchingNextPage && hasNextPage) fetchNextPage()
                  }}
                />
              </Box>
            )}
          </FormRow>
        </Box>
      </Drawer>
    </FormikProvider>
  )
}
