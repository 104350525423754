import React from 'react'

import { useQueryParams } from 'utils/hooks/useQueryParams'
import { Banner, Button } from '@ketch-com/deck'

export const IdentitySpaceVersionsMessage: React.FC = () => {
  const { removeQueryParam } = useQueryParams<{ version?: string }>()

  return (
    <Banner
      severity="warning"
      title="You are viewing an old version of this Identifier. Return to the current version to manage or activate."
      action={
        <Button
          variant="link"
          onClick={() => {
            removeQueryParam('version')
          }}
        >
          View Current
        </Button>
      }
    />
  )
}
