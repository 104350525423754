import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { uploadDataReviewFiles } from 'api/dataReview/fetchers/uploadDataReviewFiles'

const useCustomMutation = createUseMutation({
  mutationFn: uploadDataReviewFiles,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUploadDataReviewFiles = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation)
}
