import React, { useState } from 'react'
import { Alert } from '@ketch-com/deck'
import { MaybeNull } from 'interfaces/common'
import { AlertColor } from '@mui/material'

type toastTypes = 'info' | 'error' | 'success' | 'alert'

type showToastProps = {
  content: any
  type: toastTypes
  autoHideDuration?: number
}

type Props = {}

export let showToast: (params: showToastProps) => void = () => {}

export const ToastComponent: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [toastType, setToastType] = useState<MaybeNull<toastTypes>>(null)
  const [toastContent, setToastContent] = useState<any>(null)
  const [autoHideDuration, setAutoHideDuration] = useState<number>(1500)

  const handleHide = () => {
    setIsOpen(false)
    setToastType(null)
    setToastContent(null)
  }

  showToast = (params: showToastProps) => {
    setToastType(params.type)
    setToastContent(params.content)
    setAutoHideDuration(params?.autoHideDuration || 1500)
    setIsOpen(true)
  }

  const severity = toastType !== null ? (toastType === 'alert' ? 'warning' : toastType) : undefined

  return (
    <Alert
      severity={severity as AlertColor | undefined}
      autoHideDuration={autoHideDuration}
      open={isOpen}
      onClose={handleHide}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {toastContent}
    </Alert>
  )
}
