import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchDsrConfigCount } from '../fetchers/fetchDsrConfigCount'
import { useQueryClient } from 'react-query'
import { GetDSRConfigCountResponseBodyDTO } from '@ketch-com/figurehead'
import { AxiosResponse } from 'axios'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.assetDsrConfigCount,
  queryFn: fetchDsrConfigCount,
  select: res => res?.data || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useDsrConfigCount = (config?: Config) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )
  const queryClient = useQueryClient()
  return useCustomQuery({
    ...config,
    params: {
      isV2FeatureFlagEnabled,
      ...config?.params,
    },
    initialData: () => {
      const dsrConfigCountRes =
        queryClient.getQueryData<AxiosResponse<GetDSRConfigCountResponseBodyDTO, any>>([
          ApiQueryKeys.assetDsrConfigCount,
          { assetId: config?.params?.assetId, limit: 100, rightType: 0, start: 0, canonicalRightCode: 'get,delete' },
        ]) || ({} as AxiosResponse<GetDSRConfigCountResponseBodyDTO, any>)
      const dsrConfigCountResClone = { ...dsrConfigCountRes }
      const dsrConfigCountData: GetDSRConfigCountResponseBodyDTO = structuredClone(dsrConfigCountRes?.data || {})
      const { dsrScenario = {} } = dsrConfigCountData
      const filteredScenario = {} as GetDSRConfigCountResponseBodyDTO['dsrScenario']

      for (const [scenarioId, scenario] of Object.entries(dsrScenario)) {
        if (filteredScenario && scenario.rightType === config?.params?.rightType)
          filteredScenario[scenarioId as keyof GetDSRConfigCountResponseBodyDTO['dsrScenario']] = scenario
      }

      dsrConfigCountData.dsrScenario = filteredScenario
      dsrConfigCountData.totalScenarios = filteredScenario ? Object.keys(filteredScenario).length : 0

      if (dsrConfigCountResClone?.data) dsrConfigCountResClone.data = dsrConfigCountData

      return dsrConfigCountResClone
    },
  })
}
