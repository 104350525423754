import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, TableCell } from '@ketch-com/deck'
import { FormFieldDTO, WorkflowDefinitionStepSummaryDTO } from '@ketch-com/figurehead'
import { FormFieldTimesUsedInFormTemplatesTooltipTitle } from 'pages/consentAndRights/forms/components'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { Box, Tooltip, Typography } from '@mui/material'
import { getHumanReadableFieldType } from '../../utils'
import { CustomFormFieldsDropdown } from '../components'

const countWorkflows = (activities?: WorkflowDefinitionStepSummaryDTO[]) => {
  if (!activities) return 0
  // Use a set to filter duplicate workflow codes
  return new Set(activities.map(({ workflowDefinitionCode }) => workflowDefinitionCode)).size
}

export const useFieldsTabDefaultFieldsListColumns = (): GridColDef<FormFieldDTO>[] => [
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<FormFieldDTO>) => {
      return <TableCell title={row.name} subTitle={row.code} />
    },
  },
  {
    align: 'left',
    field: 'values',
    flex: 0.5,
    headerAlign: 'left',
    headerName: 'Forms',
    sortable: false,
    renderCell: ({ row: formField }: GridRenderCellParams<FormFieldDTO>) => {
      return (
        <TableCell>
          {formField?.templates?.length ? (
            <Tooltip title={<FormFieldTimesUsedInFormTemplatesTooltipTitle formField={formField} type="template" />}>
              <Box>
                <Chip label={formField?.templates?.length} size="small" clickable />
              </Box>
            </Tooltip>
          ) : (
            <EmptyValueRenderer />
          )}
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'workflows',
    flex: 0.5,
    headerAlign: 'left',
    headerName: 'Workfows',
    sortable: false,
    renderCell: ({ row: formField }: GridRenderCellParams<FormFieldDTO>) => {
      return (
        <TableCell>
          {!!countWorkflows(formField?.activities) ? (
            <Tooltip title={<FormFieldTimesUsedInFormTemplatesTooltipTitle formField={formField} type="workflow" />}>
              <Box>
                <Chip label={countWorkflows(formField?.activities)} size="small" clickable />
              </Box>
            </Tooltip>
          ) : (
            <EmptyValueRenderer />
          )}
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'type',
    flex: 0.5,
    headerAlign: 'left',
    headerName: 'Type',
    sortable: false,
    renderCell: ({ row: formField }: GridRenderCellParams<FormFieldDTO>) => {
      return (
        <TableCell>
          <Typography variant="body" textTransform="capitalize">
            {getHumanReadableFieldType(formField)}
          </Typography>
        </TableCell>
      )
    },
  },
]

export const useFieldsTabCustomFieldsListColumns = ({
  isUserExperienceServerAdmin,
}: {
  isUserExperienceServerAdmin: boolean
}): GridColDef<FormFieldDTO>[] => [
  {
    align: 'left',
    field: 'fieldTitle',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Field Title',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<FormFieldDTO>) => {
      return <TableCell title={row.name} subTitle={row.code} />
    },
  },
  {
    align: 'left',
    field: 'values',
    flex: 0.4,
    headerAlign: 'left',
    headerName: 'Forms',
    sortable: false,
    renderCell: ({ row: formField }: GridRenderCellParams<FormFieldDTO>) => {
      return (
        <TableCell>
          {formField?.templates?.length ? (
            <Tooltip title={<FormFieldTimesUsedInFormTemplatesTooltipTitle formField={formField} type="template" />}>
              <Box>
                <Chip label={formField?.templates?.length} size="small" clickable />
              </Box>
            </Tooltip>
          ) : (
            <EmptyValueRenderer />
          )}
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'workflows',
    flex: 0.4,
    headerAlign: 'left',
    headerName: 'Workfows',
    sortable: false,
    renderCell: ({ row: formField }: GridRenderCellParams<FormFieldDTO>) => {
      return (
        <TableCell>
          {!!countWorkflows(formField?.activities) ? (
            <Tooltip title={<FormFieldTimesUsedInFormTemplatesTooltipTitle formField={formField} type="workflow" />}>
              <Box>
                <Chip label={countWorkflows(formField?.activities)} size="small" clickable />
              </Box>
            </Tooltip>
          ) : (
            <EmptyValueRenderer />
          )}
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'type',
    flex: 0.4,
    headerAlign: 'left',
    headerName: 'Type',
    sortable: false,
    renderCell: ({ row: formField }: GridRenderCellParams<FormFieldDTO>) => {
      return (
        <TableCell>
          <Typography variant="body" textTransform="capitalize">
            {getHumanReadableFieldType(formField)}
          </Typography>
        </TableCell>
      )
    },
  },
  {
    align: 'right',
    field: 'dropDown',
    flex: 1,
    headerAlign: 'left',
    headerName: ' ',
    maxWidth: 80,
    sortable: false,
    renderCell: ({ row: formField }: GridRenderCellParams<FormFieldDTO>) => {
      return (
        <TableCell>
          <CustomFormFieldsDropdown formField={formField} isUserExperienceServerAdmin={isUserExperienceServerAdmin} />
        </TableCell>
      )
    },
  },
]
