import { PutFormFieldRequestBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  formData: PutFormFieldRequestBodyDTO
}

type Response = {}

export const updateFormField = ({ formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/form-fields/${formData?.formField?.id || ''}`,
    }),
    formData,
  )
