import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  includeIssues?: boolean
}>

type Response = APIListResponse<{ experiences: ExperienceV2DTO[] }>

export const fetchExperiences = ({ includeMetadata, includeIssues, start, limit }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/experiences`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
        },
      },
    }),
  )
