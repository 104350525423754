import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { DeleteWorkflowModal } from 'components/modals/workflows/DeleteWorkflowModal'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'
import { ObjectLiteral } from 'interfaces'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useDeletePolicyV2 } from 'api/policies/mutations/useDeletePolicyV2'

type Props = {
  policy: ObjectLiteral
}

export const PoliciesListDropdown: React.FC<Props> = ({ policy }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const { code } = policy
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)

  const handleEditClick = () => {
    navigate(RoutesManager.orchestration.workflows.upsert.root.getURL({ code: policy?.code }))
  }

  const { mutateAsync: handleDeletePolicy } = useDeletePolicyV2({
    onSuccess: async () => {
      showToast({ content: 'Policy deleted', type: 'success' })
      await queryClient.refetchQueries(ApiQueryKeys.policies)
      setIsDeleteOpen(false)
    },
    onError: () => {
      showToast({ content: 'Failed to create policy', type: 'error' })
      setIsDeleteOpen(false)
    },
  })

  const onSubmit = async () => await handleDeletePolicy({ params: { policyCode: policy.code } })

  return (
    <>
      <ButtonOptions
        items={[
          {
            content: 'Edit',
            icon: <EditOutlined />,
            hidden: !isPermitted(PERMISSIONS.WORKFLOW_WRITE),
            onClick: handleEditClick,
          },
          {
            content: 'Delete',
            icon: <DeleteOutline />,
            hidden: !isPermitted(PERMISSIONS.WORKFLOW_WRITE),
            onClick: () => setIsDeleteOpen(true),
          },
        ]}
      />

      {isEditOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.WORKFLOW}
          onSubmit={() => {
            navigate(RoutesManager.orchestration.workflows.upsert.root.getURL({ code: policy?.code }))
          }}
          onCancel={() => setIsEditOpen(false)}
        />
      )}

      {isDeleteOpen && (
        <DeleteWorkflowModal workflowCode={code} onSubmit={onSubmit} onCancel={() => setIsDeleteOpen(false)} />
      )}
    </>
  )
}
