import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

// utils
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useDeleteDataSubjectType } from 'api/dataSubjectTypes/mutations/useDeleteDataSubjectType'

// components
import { showToast } from 'components/modals/AlertComponent'
import Box from '@mui/material/Box'

// types
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { Button, Chip, DataGrid, PopUp, TableCell } from '@ketch-com/deck'
import { Tooltip, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

type Props = {
  dataSubjectType: DataSubjectTypeDTO
  onSubmit?: () => Promise<void>
  onCancel: () => void
}

type GridItem = {
  title: string
  values: (string | undefined)[] | undefined
}

export const DeleteDataSubjectTypeModal: React.FC<Props> = ({ dataSubjectType, onSubmit, onCancel }) => {
  const queryClient = useQueryClient()
  const [isBusy, setIsBusy] = useState(false)

  const { mutate: handleDeleteDataSubjectType } = useDeleteDataSubjectType({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'Subject Type deleted', type: 'success' })

      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to delete Subject Type', type: 'error' })
      setIsBusy(false)
    },
  })

  // component utils
  const purposesWithSingleSubjectType = dataSubjectType.purposes?.filter(p => p.dataSubjectTypes?.length === 1) || []
  const propertiesWithSingleSubjectType =
    dataSubjectType.properties?.filter(p => p.dataSubjectTypes?.length === 1) || []
  const showWarning = purposesWithSingleSubjectType?.length > 0 || propertiesWithSingleSubjectType?.length > 0
  const purposes = showWarning ? purposesWithSingleSubjectType : dataSubjectType?.purposes
  const properties = showWarning ? propertiesWithSingleSubjectType : dataSubjectType?.properties

  // make items array
  const items = []
  purposes?.length && items.push({ title: 'Purposes', values: purposes?.map(purpose => purpose?.name) })
  properties?.length && items.push({ title: 'Properties', values: properties?.map(property => property?.name) })
  if (!showWarning) {
    items.unshift({ title: 'Events', values: dataSubjectType?.events?.map(event => event?.rightName) })
  }

  return (
    <PopUp
      title="Delete Subject Type"
      variant="dialog"
      popUpWidth="550px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isBusy} onClick={onCancel}>
            Close
          </Button>
          {!showWarning && (
            <Button
              color="primary"
              size="large"
              pending={isBusy}
              onClick={() => {
                handleDeleteDataSubjectType({
                  params: {
                    dataSubjectTypeCode: dataSubjectType?.code || '',
                  },
                })
              }}
            >
              Delete
            </Button>
          )}
        </>
      }
    >
      {showWarning ? (
        <Typography>
          To delete Subject Type: <Typography variant="label">{dataSubjectType.name}</Typography>, please delete or add
          an additional Subject Type association to the below {!!purposesWithSingleSubjectType?.length && 'purposes'}
          {!!purposesWithSingleSubjectType?.length && propertiesWithSingleSubjectType?.length > 0 && ' and'}
          {!!propertiesWithSingleSubjectType?.length && ' properties'}.
        </Typography>
      ) : (
        <Typography>
          Deleting this Subject Type will delete all associated events and remove any pre-existing relationships between
          purposes and properties.
        </Typography>
      )}

      <DataGrid
        disableRowSelectionOnClick
        disableMultipleRowSelection
        columns={[
          {
            align: 'left',
            headerAlign: 'left',
            field: 'title',
            headerName: 'Title',
            renderCell: ({ row }: GridRenderCellParams<GridItem>) => <TableCell title={row.title} />,
            flex: 1,
            sortable: false,
          },
          {
            field: 'values',
            headerName: '',
            flex: 1,
            align: 'left',
            renderCell: ({ row: { values } }: GridRenderCellParams<GridItem>) => {
              if (!!values?.length) {
                return (
                  <TableCell>
                    <Tooltip
                      title={
                        <Box>
                          {values.map(value => (
                            <Box key={value}>
                              <Typography color="white.main">{value}</Typography>
                            </Box>
                          ))}
                        </Box>
                      }
                    >
                      <Box>
                        <Chip label={values?.length || 0} size="small" clickable />
                      </Box>
                    </Tooltip>
                  </TableCell>
                )
              }
              return <TableCell></TableCell>
            },
            sortable: false,
          },
        ]}
        rows={items}
        getRowId={row => row.title}
        disableColumnResize
        disableColumnFilter
        disableColumnReorder
        disableColumnPinning
        disableAggregation
        disableRowGrouping
        hideFooter
        disableColumnMenu
        disableRowHoverStates
      />
    </PopUp>
  )
}
