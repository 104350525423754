import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { FormFieldDetailViewContext } from '../context/FormFieldDetailViewContext'
import { useFormFieldsRelationshipData } from 'pages/consentAndRights/forms/hooks'
import { useFeatureFlag } from 'utils/hooks'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { DataGrid, ContentGroup } from '@ketch-com/deck'
import {
  fieldDetailAuthenticatorColumns,
  fieldDetailFormTemplateColumns,
  fieldDetailWorkflowDefinitionStepSummaryColumns,
  getFieldDetailExperienceShortColumns,
} from './formFieldDetailViewUsageTabUtils'

type Props = {}

export const FormFieldDetailViewUsageTab: React.FC<Props> = () => {
  const { formField } = useContext(FormFieldDetailViewContext)
  const { formFieldAuthenticators, formFieldExperiences, formFieldTemplates, formFieldActivities } =
    useFormFieldsRelationshipData(formField)
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isExpV1 = isFeatureFlagEnabled(
    FEATURE_FLAGS.EXPERIENCE_SERVER_VERSION,
    FEATURE_FLAGS_VALUES.EXPERIENCE_SERVER_VERSION_1,
  )
  return (
    <Box display="flex" flexDirection="column" gap={6} pb={6}>
      {formFieldTemplates?.length > 0 ? (
        <Box display="flex" flexDirection="column" gap={4}>
          <ContentGroup variant="inner-page-header" titleVariant="h3" title="Forms" />
          <DataGrid
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              includeOutliers: false,
              expand: true,
            }}
            getRowHeight={() => 'auto'}
            columns={fieldDetailFormTemplateColumns}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowSelectionOnClick
            disableRowHoverStates
            disableMultipleRowSelection
            getRowId={row => row.code}
            rows={formFieldTemplates}
            hideFooter
          />
        </Box>
      ) : null}

      {formFieldExperiences?.length > 0 ? (
        <Box display="flex" flexDirection="column" gap={4}>
          <ContentGroup variant="inner-page-header" titleVariant="h3" title="Experiences" />

          <DataGrid
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              includeOutliers: false,
              expand: true,
            }}
            getRowHeight={() => 'auto'}
            columns={getFieldDetailExperienceShortColumns(isExpV1)}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowSelectionOnClick
            disableRowHoverStates
            disableMultipleRowSelection
            getRowId={row => row.code}
            rows={formFieldExperiences}
            hideFooter
          />
        </Box>
      ) : null}

      {formFieldAuthenticators?.length > 0 ? (
        <Box display="flex" flexDirection="column" gap={4}>
          <ContentGroup variant="inner-page-header" titleVariant="h3" title="Authenticators" />

          <DataGrid
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              includeOutliers: false,
              expand: true,
            }}
            getRowHeight={() => 'auto'}
            columns={fieldDetailAuthenticatorColumns}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowSelectionOnClick
            disableRowHoverStates
            disableMultipleRowSelection
            getRowId={row => row.id}
            rows={formFieldAuthenticators}
            hideFooter
          />
        </Box>
      ) : null}

      {formFieldActivities?.length > 0 ? (
        <Box display="flex" flexDirection="column" gap={4}>
          <ContentGroup variant="inner-page-header" titleVariant="h3" title="Workflows" />

          <DataGrid
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              includeOutliers: false,
              expand: true,
            }}
            getRowHeight={() => 'auto'}
            columns={fieldDetailWorkflowDefinitionStepSummaryColumns}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowSelectionOnClick
            disableRowHoverStates
            disableMultipleRowSelection
            getRowId={row => row.code}
            rows={formFieldActivities}
            hideFooter
          />
        </Box>
      ) : null}
    </Box>
  )
}
