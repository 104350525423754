import React from 'react'
import { TrackerProperty, TrackerOnNewProperty } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { Button } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useConfirmTrackerOnNewProperty } from 'api/trackers/mutations/useConfirmTrackerOnNewProperty'

type Props = {
  trackerProperty: TrackerProperty
  tracker: TrackerOnNewProperty
}

export const TrackerOnNewPropertyConfirm: React.FC<Props> = ({ tracker, trackerProperty = {} as TrackerProperty }) => {
  const queryClient = useQueryClient()
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])

  const { mutateAsync: confirmTrackerOnNewProperty, isLoading: isConfirmingTrackerOnNewProperty } =
    useConfirmTrackerOnNewProperty({
      onSuccess: async () => {
        await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.trackersOnNewPropertiesInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.newPropertyTrackerPropertiesInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.trackerStatistics])
        showToast({ content: 'Updated tracker', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to update tracker', type: 'error' })
      },
    })

  return (
    <Button
      disabled={isConfirmingTrackerOnNewProperty || !isPermittedToWriteCookie}
      color="tertiary"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        confirmTrackerOnNewProperty({
          params: { encodedTrackerKey: tracker?.encodedKey, propertyID: trackerProperty?.id },
        })
      }}
    >
      Confirm
    </Button>
  )
}
