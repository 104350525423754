import React, { useState, useContext } from 'react'

import { OrganizationView } from 'pages/settings/organizations/view/OrganizationView'
import { useCurrentOrganization } from 'utils/hooks/useCurrentOrganization'
import { useOrganizationProducts } from 'api/products/queries/useOrganizationProducts'
import { useCreateOrganizationEditPortalUrl } from 'api/organizations/mutations/useCreateOrganizationEditPortalUrl'
import { showToast } from 'components/modals/AlertComponent'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useIsCurrentUserOrgAdmin } from 'utils/hooks/useIsCurrentUserOrgAdmin'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'

const getReturnUrl = (): string => {
  const isLocalhost = window.location.hostname === 'localhost'
  if (isLocalhost) {
    return 'http://localhost:3000/settings/organization?status=stripe'
  }
  return `https://${window.location.hostname}/settings/organization?status=stripe`
}

export const OrganizationViewContainer: React.FC = () => {
  const { queries, removeQueryParam } = useQueryParams()
  const { isReady: isPlanAndBillingReady } = useContext(PlanAndBillingContext)
  useIsCurrentUserOrgAdmin()
  const { organization, isLoading: isLoadingOrganization } = useCurrentOrganization({})

  const [isShowReturnFromStripeMessage, setIsShowReturnFromStripeMessage] = useState(false)

  React.useEffect(() => {
    if (queries?.status === 'stripe') {
      setIsShowReturnFromStripeMessage(true)
    }
  }, [queries?.status])

  const { data: organizationProducts, isLoading: isLoadingProduct } = useOrganizationProducts({
    params: {
      productID: organization?.planProductID || '',
    },
  })

  const { mutateAsync: getEditOrganizationPortalUrl, isLoading: isLoadingGetEditOrgPortalUrl } =
    useCreateOrganizationEditPortalUrl({
      onSuccess: ({ data }) => {
        if (data?.portalURL) {
          window.location.replace(data?.portalURL)
        } else {
          showToast({ content: 'Oops, something went wrong. Please contact customer support', type: 'error' })
        }
      },
      onError: () => {
        showToast({ content: 'Oops, something went wrong. Please contact customer support', type: 'error' })
      },
    })

  const handleGetEditOrganizationPortalUrl = async (): Promise<void> => {
    await getEditOrganizationPortalUrl({
      params: {
        formData: {
          ReturnURL: getReturnUrl(),
        },
      },
    })
  }

  const handleHideReturnFromStripeMessage = () => {
    setIsShowReturnFromStripeMessage(false)
    removeQueryParam('status')
  }

  const isReady = !isLoadingOrganization && !isLoadingProduct && isPlanAndBillingReady

  return (
    <OrganizationView
      onGetEditOrganizationUrl={handleGetEditOrganizationPortalUrl}
      isReady={isReady}
      organization={organization}
      organizationProducts={organizationProducts}
      isLoadingGetEditOrgPortalUrl={isLoadingGetEditOrgPortalUrl}
      isShowReturnFromStripeMessage={isShowReturnFromStripeMessage}
      onHideReturnFromStripeMessage={handleHideReturnFromStripeMessage}
    />
  )
}
