import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { AssetPolicyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  formData: AssetPolicyDTO
}>

export const createPolicyV2 = ({ formData }: Params) =>
  API.post<AssetPolicyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/policies`,
    }),
    formData,
  )
