export const getAssetType = (providerCode: string, canonicalResourceType: number) => {
  switch (providerCode) {
    case 'azuresqldb':
    case 'mariadb':
    case 'mysql':
    case 'oracle':
    case 'redshift':
    case 'snowflake':
    case 'sqlserver':
    case 'postgresql':
    case 'gbq':
      if (canonicalResourceType === 1) return 'Database'
      if (canonicalResourceType === 2) return 'Table'
      if (canonicalResourceType === 3) return 'Column'
      return 'Schema'
    case 'mongodb':
    case 'documentdb':
      if (canonicalResourceType === 1) return 'Database'
      if (canonicalResourceType === 2) return 'Collection'
      if (canonicalResourceType === 3) return 'Field'
      return ''
    default:
      return ''
  }
}
