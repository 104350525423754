import { RightInvocationStatusDTO } from '@ketch-com/figurehead'
import * as Yup from 'yup'

export type FinalizeRequestFormValues = {
  finalizedStatus: RightInvocationStatusDTO | undefined
  appealWorkflowDefinitionCode?: string
  subjectCommunicationMessage: string | undefined
  coverLetter: File[] | undefined
  reasonForFinalization: string | undefined
  requestAllowsAppeal?: boolean
}

export const finalizeRequestFormKeys = {
  finalizedStatus: 'finalizedStatus',
  appealWorkflowDefinitionCode: 'appealWorkflowDefinitionCode',
  subjectCommunicationMessage: 'subjectCommunicationMessage',
  coverLetter: 'coverLetter',
  reasonForFinalization: 'reasonForFinalization',
  requestAllowsAppeal: 'requestAllowsAppeal',
} as const

export const getValidationSchema = ({
  isCoverLetterRequired,
  isMessageRequired,
}: {
  isCoverLetterRequired: boolean
  isMessageRequired: boolean
}) =>
  Yup.object().shape({
    [finalizeRequestFormKeys.finalizedStatus]: Yup.string().required('Required'),
    [finalizeRequestFormKeys.subjectCommunicationMessage]: isMessageRequired
      ? Yup.string().required('Required')
      : Yup.string(),
    [finalizeRequestFormKeys.coverLetter]: isCoverLetterRequired
      ? Yup.array().min(1, 'Required').required()
      : Yup.array(),
    [finalizeRequestFormKeys.reasonForFinalization]: Yup.string(),
    [finalizeRequestFormKeys.requestAllowsAppeal]: Yup.boolean(),
    [finalizeRequestFormKeys.appealWorkflowDefinitionCode]: Yup.string().when(
      finalizeRequestFormKeys.requestAllowsAppeal,
      {
        is: true,
        then: Yup.string().required('Required'),
      },
    ),
  })
