import { ApiKeyFormValues, ApiKeyFormPayload } from 'interfaces/apiKeys'
import { NONE, NO_PERMISSION } from '../../constants'

type Args = { values: ApiKeyFormValues }

export const getApiKeyFormPayload = ({ values }: Args): ApiKeyFormPayload => ({
  name: values.name,
  description: values.description,
  status: values.status,
  roles: Object.values(values.roles)
    .filter(v => v !== NONE)
    .filter(v => v !== NO_PERMISSION)
    .map(v => ({ code: v })),
})
