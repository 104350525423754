import React from 'react'
import Box from '@mui/material/Box'
import { Message as TwilioMessage } from '@twilio/conversations'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { InternalMessageAuthor, MessageAttachment } from './components'
import { useUsers } from 'api/users/queries/useUsers'
import { AvatarColorMap } from 'pages/orchestration/rightsQueue/viewV2/hooks/useTwilioConversationClient'
import { useAuth } from 'utils/hooks/useAuth'
import { MessageAttributes } from '../../interfaces'
import { Typography } from '@mui/material'
import { MessageDropdownMenu } from './components/MessageDropdownMenu'

type Props = {
  message: TwilioMessage
  isLast?: boolean
  avatarColorMap?: AvatarColorMap
  isThreadClosed?: boolean
}

export const Message: React.FC<Props> = ({ message, isLast, isThreadClosed, avatarColorMap = new Map() }) => {
  const { userData } = useAuth()

  const { data: users } = useUsers({
    params: {
      active: true,
      includeMetadata: true,
    },
  })

  const internalMessageAuthor = users.find(user => user.ID === message.author)
  const isMessageInternal = !(message?.attributes as MessageAttributes)?.isDataSubject
  const messageHasAttachments = !!message.attachedMedia?.length
  const isCurrentUserMessageAuthor = userData?.userId === message?.author

  const messageBody = message?.body?.split?.('\n')?.filter?.(Boolean) || []

  return (
    <Box
      borderRadius="28px"
      display="flex"
      flexDirection="column"
      key={message?.sid}
      mb={isLast ? 0 : 2}
      pb={2}
      pt={1}
      pl={2}
      p="12px 16px"
      sx={{ backgroundColor: isMessageInternal ? 'fadedGrey.main' : 'sphereFaded.main' }}
    >
      <Box display="flex" alignItems="flex-start" flexDirection="column" justifyContent="space-between">
        {/* Author and Sent Row  */}
        <Box display="flex" alignItems="center" paddingBottom="12px" justifyContent="space-between" width="100%">
          <InternalMessageAuthor
            message={message}
            bgColor={avatarColorMap?.get?.(internalMessageAuthor?.ID)?.bg}
            fgColor={avatarColorMap?.get?.(internalMessageAuthor?.ID)?.fg}
          />

          {isCurrentUserMessageAuthor && !isThreadClosed && <MessageDropdownMenu message={message} />}
        </Box>

        {/* Message Body Container */}
        <Box mb="6px">
          {messageBody?.map?.((line, index) => (
            <Box key={`${line}_${index}`}>
              <Typography>{line}</Typography>
            </Box>
          ))}
        </Box>

        {/* Right: createAt Date, ButtonOptions */}
        <Typography variant="smallBody" color="darkDuskFaded.main" mb="6px">
          <FormattedDateRenderer date={(message?.dateCreated?.getTime() || 0) / 1000} />
        </Typography>

        {/* Attachments Row */}
        {messageHasAttachments && (
          <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
            {message.attachedMedia?.map(attachment => (
              <MessageAttachment attachment={attachment} key={attachment.sid} message={message} />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}
