import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { ControllerFormDTO } from 'interfaces/controllers/controller'
import { RoutesManager } from 'utils/routing/routesManager'
import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { showToast } from 'components/modals/AlertComponent'
import { useCreateController } from 'api/controllers/mutations/useCreateController'
import { useUpdateController } from 'api/controllers/mutations/useUpdateController'
import { useController } from 'api/controllers/queries/useController'
import { EditControllerView } from 'pages/policyCenter/controllers/edit/EditControllerView'

export const EditControllerContainer: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { code } = useParams<{ code: string }>()
  const isEditMode = code !== NEW_ITEM_CODE

  const onUpsertSuccess = async () => {
    await queryClient.refetchQueries(ApiQueryKeys.entitlements)
    await queryClient.refetchQueries(ApiQueryKeys.controllersPaginated)

    if (isEditMode) {
      showToast({ content: 'Controller updated', type: 'success' })
    } else {
      showToast({ content: 'Controller created', type: 'success' })
    }

    navigate(RoutesManager.policyCenter.controllers.root.getURL())
  }

  const handleRedirectToControllersList = () => {
    navigate(RoutesManager.policyCenter.controllers.root.getURL())
  }

  const { mutateAsync: handleCreateController } = useCreateController({
    onSuccess: () => onUpsertSuccess(),
    onError: () => {
      showToast({ content: 'Failed to create controller', type: 'error' })
    },
  })

  const { mutateAsync: handleUpdateController } = useUpdateController({
    onSuccess: () => onUpsertSuccess(),
    onError: () => {
      showToast({ content: 'Failed to update controller', type: 'error' })
    },
  })

  const { data: controller, isLoading: isControllerLoading } = useController({
    enabled: isEditMode,
    params: {
      controllerCode: code!,
    },
    onError: handleRedirectToControllersList,
  })

  const onSubmit = async (values: ControllerFormDTO) => {
    await (isEditMode
      ? handleUpdateController({
          params: {
            controllerCode: code!,
            formData: values,
          },
        })
      : handleCreateController({
          params: {
            formData: values,
          },
        }))
  }

  return (
    <EditControllerView
      isReady={!isControllerLoading}
      isEditMode={isEditMode}
      controller={controller}
      onSubmit={onSubmit}
    />
  )
}
