import React from 'react'
import { Box } from '@mui/material'
import { useFormikContext } from 'formik'
import {
  ProcessingActivityDTO,
  ProcessingActivityAssetDTO,
  ProcessingActivityDataSystemProcessingStageDTO,
  ProcessingActivityTypeDTO,
} from '@ketch-com/figurehead'
import { Button, Icon } from '@ketch-com/deck'
import {
  DataGridAssetObjectType,
  DataGridObjectType,
  getStageDefaultState,
  isDataGridAssetObjectType,
  isDataGridAutocompleteObjType,
} from './dataSystemsUtils'

type Props = {
  system?: DataGridObjectType | DataGridAssetObjectType
  systemIndex?: number
  processingActivityAsset?: ProcessingActivityAssetDTO
  processingActivityAssetIndex?: number
}

export const DataSystemGridStageProcessing: React.FC<Props> = ({ system }) => {
  const { isSubmitting, values, setFieldValue } = useFormikContext<ProcessingActivityDTO>()

  const isModelType = values.type === ProcessingActivityTypeDTO.ModelProcessingActivityType

  const handleUpdateStages = (isChecked: boolean, stageValueType: ProcessingActivityDataSystemProcessingStageDTO) => {
    // asset

    if (system) {
      if (isDataGridAutocompleteObjType(system)) {
        return
      } else if (!isDataGridAssetObjectType(system)) {
        // processing activity
        // path ---> dataSystems[${systemIndex}].processingStage
        const dataSystems = [...(values.dataSystems || [])]

        dataSystems.forEach(dataSystem => {
          if (dataSystem.dataSystemId === system.dataSystemId) {
            if (isChecked) {
              dataSystem.processingStages = [...(dataSystem.processingStages || []), stageValueType]
            } else {
              const filtered = [...(dataSystem.processingStages || [])].filter(status => status !== stageValueType)
              dataSystem.processingStages = filtered
            }
          }
        })

        setFieldValue('dataSystems', [...dataSystems])
      } else {
        // asset
        //path -> `dataSystems[${systemIndex}].assets.[${processingActivityAssetIndex}].processingStages`

        const dataSystems = [...(values.dataSystems || [])]
        dataSystems.forEach(dataSystem => {
          if (dataSystem.dataSystemId === system.parentElem?.dataSystemId) {
            const currentAsset = dataSystem.assets?.find(
              // apparently connectionID can be the same for multiple assets in same processing activity
              // elem => elem.asset?.asset?.connection?.connectionID === system.asset?.asset?.connection?.connectionID,
              elem => elem.asset?.asset?.resource?.id === system.asset?.asset?.resource?.id,
            )
            if (currentAsset) {
              if (isChecked) {
                currentAsset.processingStages = [...(currentAsset.processingStages || []), stageValueType]
              } else {
                const filtered = [...(currentAsset.processingStages || [])].filter(status => status !== stageValueType)
                currentAsset.processingStages = filtered
              }
            }
          }
        })
        setFieldValue('dataSystems', [...dataSystems])
      }
    }
  }

  const { isInputStageChecked, isOutputStageChecked, isProcessStageChecked } = getStageDefaultState({
    system,
    processingActivity: values,
  })

  return (
    <Box display="flex" justifyContent="left" alignItems="center" gap={1}>
      <Button
        disabled={isSubmitting}
        variant="icon"
        color={isInputStageChecked ? 'primary' : 'secondary'}
        onClick={() => {
          handleUpdateStages(
            !isInputStageChecked,
            ProcessingActivityDataSystemProcessingStageDTO.InputProcessingActivityDataSystemProcessingStage,
          )
        }}
      >
        <Icon name="OInput" />
      </Button>

      {!isModelType ? (
        <Button
          disabled={isSubmitting}
          variant="icon"
          color={isProcessStageChecked ? 'primary' : 'secondary'}
          onClick={() => {
            handleUpdateStages(
              !isProcessStageChecked,
              ProcessingActivityDataSystemProcessingStageDTO.ProcessProcessingActivityDataSystemProcessingStage,
            )
          }}
        >
          <Icon name="ORefresh" />
        </Button>
      ) : null}

      <Button
        disabled={isSubmitting}
        variant="icon"
        color={isOutputStageChecked ? 'primary' : 'secondary'}
        onClick={() => {
          handleUpdateStages(
            !isOutputStageChecked,
            ProcessingActivityDataSystemProcessingStageDTO.OutputProcessingActivityDataSystemProcessingStage,
          )
        }}
      >
        <Icon name="OOutput" />
      </Button>
    </Box>
  )
}
