import React from 'react'

import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { getApplicationEntityName } from 'utils/messages/entityName'
import { Box, Tooltip } from '@mui/material'
import { Icon, theme } from '@ketch-com/deck'

export const getTooltipContent = (entityType: ApplicationEntity) => {
  switch (entityType) {
    case ApplicationEntity.PERMIT_PROPAGATION:
      return 'Purposes for this property have not been configured yet.'
    case ApplicationEntity.DPIA:
      return 'Ketch recommends you conduct a DPIA given the canonical purpose chosen for this purpose.'

    default: {
      const entityName = getApplicationEntityName({ entityType })

      return `${entityName.singular} configurations need to be updated`
    }
  }
}

type Props = {
  className?: string
  hasIssues?: boolean
  entityType: ApplicationEntity
}

export const IssuesCellRenderer: React.FC<Props> = ({ className, hasIssues = false, entityType }) => {
  if (!hasIssues) {
    return null
  }

  return (
    <Tooltip title={getTooltipContent(entityType)} placement="bottom">
      <Box display="flex" justifyContent="center" alignItems="center">
        <Icon name="FInfo" width={18} height={18} iconColor={theme.palette.warning.main} />
      </Box>
    </Tooltip>
  )
}
