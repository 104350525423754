import React from 'react'
import { useFormikContext, FieldArrayRenderProps } from 'formik'
import { Box } from '@mui/material'
import {
  SubscriptionTopicFormData,
  OrchestrationData,
  ConnectionItem,
} from 'pages/policyCenter/subscriptions/interfaces'
import { shopifySubscriptionGroupItems } from 'pages/policyCenter/subscriptions/constants'
import { Chip, Icon } from '@ketch-com/deck'
import { FormDroplistButton } from 'components/form/FormDroplistButton'

type Props = {
  orchestration: OrchestrationData
  fieldArrayHelpers: FieldArrayRenderProps
  fieldArrayIndex: number
  channelCode: string
  isSystemConnectionsLoading: boolean
  connectionItems: ConnectionItem[]
}

export const Shopify: React.FC<Props> = ({
  orchestration,
  fieldArrayHelpers,
  fieldArrayIndex,
  channelCode,
  isSystemConnectionsLoading,
  connectionItems,
}) => {
  const { isSubmitting, setFieldValue } = useFormikContext<SubscriptionTopicFormData>()
  const { remove } = fieldArrayHelpers

  return (
    <Box display="flex" alignItems="flex-start" gap={3} py={1.5}>
      <Chip
        icon={<img src={orchestration?.appLogo?.url} alt={orchestration?.appLogo?.title} width={24} height={24} />}
        disabled={isSubmitting || isSystemConnectionsLoading}
        size="medium"
        label={orchestration.name}
        onDelete={() => remove(fieldArrayIndex)}
        deleteIcon={<Icon name="OCross" />}
      />
      <FormDroplistButton
        required
        size="small"
        disabled={isSubmitting || isSystemConnectionsLoading}
        sx={{ width: 220 }}
        formPropertyName={`contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appInstanceId`}
        label="Select Connection"
        placeholder="Select Connection"
        items={connectionItems || []}
        onItemClick={option => {
          setFieldValue(`contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appInstanceId`, option.id)
          setFieldValue(`contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appInstanceName`, option.name)
        }}
        valueKey="id"
      />
      <FormDroplistButton
        required
        disabled={isSubmitting || isSystemConnectionsLoading}
        size="small"
        sx={{ width: 220 }}
        formPropertyName={`contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appParams.subscriptionGroup`}
        valueKey="id"
        placeholder="Subscription Type"
        items={shopifySubscriptionGroupItems || []}
        onItemClick={option => {
          setFieldValue(
            `contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appParams.subscriptionGroup`,
            option.id,
          )
        }}
      />
    </Box>
  )
}
