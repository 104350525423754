import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { FetchCustomRightNamesResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

type Params = APIListRequestParams<{
  includeDeleted?: boolean
}>

export const fetchCustomRightNameList = ({ start, limit, query, includeMetadata, includeDeleted, ordering }: Params) =>
  API.get<FetchCustomRightNamesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/custom-right-names`,
      params: {
        filters: {
          start,
          limit,
          query,
          includeMetadata,
          includeDeleted,
          ordering,
        },
      },
    }),
  )
