import { useMemo } from 'react'
import * as Yup from 'yup'

import { validationMessages } from 'utils/constants/forms/validationMessages'
import { useIsAvailableHandler } from 'utils/helpers/validators/useIsAvailableHandler'
import { useCheckIsLabelCodeAvailableV2 } from 'api/labels/queries/useCheckIsLabelCodeAvailableV2'
import { formatCodeFromName } from 'utils/formatters'

export const useValidationSchema = (isEditMode: boolean) => {
  const { mutateAsync: handleCheckOrganizationCodeAvailable } = useCheckIsLabelCodeAvailableV2()

  const handleCheckIsLabelCodeAvailable = useIsAvailableHandler({
    handler: value => {
      if (isEditMode) {
        return Promise.resolve(true)
      }

      return handleCheckOrganizationCodeAvailable({
        params: {
          code: formatCodeFromName(value),
        },
      })
    },
  })

  return useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .test('Is Unique', validationMessages.CLASSIFICATION_LABEL_MUST_BE_UNIQUE, handleCheckIsLabelCodeAvailable)
          .required(validationMessages.REQUIRED),
      }),
    [handleCheckIsLabelCodeAvailable],
  )
}
