import * as Yup from 'yup'
import { useCheckIsExperienceCodeAvailable } from 'api/experiences/mutations/useCheckIsExperienceCodeAvailable'
import { ExperienceBannerPrimaryButtonAction } from 'interfaces/experiences/experienceBannerPrimaryButtonAction'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { useMemo } from 'react'
import { Descendant } from 'slate'
import { validationMessages } from 'utils/constants/forms/validationMessages'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { getIsFooterDescriptionValid } from '../../common'
import { ConsentExperienceFormValues } from '../interfaces'

export const useConsentValidationSchema = ({
  isEditMode,
  isEntitledToJit,
  organizationLanguages,
}: {
  isEditMode: boolean
  isEntitledToJit: boolean
  organizationLanguages: LanguageWithStatusDTO[]
}) => {
  const { mutateAsync: handleCheckIsExperienceCodeAvailable } = useCheckIsExperienceCodeAvailable()

  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsExperienceCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(() => {
    const enabledLanguages = organizationLanguages.filter(({ enabled }) => enabled)

    const consentShapeSchema = {
      banner: Yup.object().shape({
        footerDescription: Yup.array().test('Is required', 'Required', value => {
          const isValid = getIsFooterDescriptionValid(value as Descendant[])
          return isValid
        }),
        buttonText: Yup.string().required(validationMessages.REQUIRED),
        secondaryButtonText: Yup.string().test('Is required', 'Required', (value, ctx) => {
          const { from } = ctx as Yup.TestContext & { from: [{ value: ConsentExperienceFormValues }] }
          const { value: formValues } = from[from.length - 1]

          return formValues.banner.primaryButtonAction !== ExperienceBannerPrimaryButtonAction.ACCEPT_ALL || !!value
        }),
      }),
      modal: Yup.object().shape({
        title: Yup.string().required(validationMessages.REQUIRED),
        buttonText: Yup.string().required(validationMessages.REQUIRED),
        consentTitle: Yup.string().when('isCustomTextSwitchOn', {
          is: true,
          then: Yup.string().required(validationMessages.REQUIRED),
        }),
        switchTextRenderLogic: Yup.number().required('Required'),
        switchOnText: Yup.string().when('isCustomSwitchTextSwitchOn', {
          is: true,
          then: Yup.string().required(validationMessages.REQUIRED),
        }),
        switchOffText: Yup.string().when('isCustomSwitchTextSwitchOn', {
          is: true,
          then: Yup.string().required(validationMessages.REQUIRED),
        }),
      }),
      ...(isEntitledToJit && {
        jit: Yup.object().shape({
          title: Yup.string().required(validationMessages.REQUIRED),
          declineButtonText: Yup.string().required(validationMessages.REQUIRED),
          acceptButtonText: Yup.string().required(validationMessages.REQUIRED),
        }),
      }),
    }

    const translationsEnabledLanguagesConsentShapeSchema = {
      banner: Yup.object().shape({
        footerDescription: Yup.array(),
        buttonText: Yup.string(),
        secondaryButtonText: Yup.string(),
      }),
      modal: Yup.object().shape({
        title: Yup.string(),
        buttonText: Yup.string(),
      }),
      ...(isEntitledToJit && {
        jit: Yup.object().shape({
          title: Yup.string(),
          declineButtonText: Yup.string(),
          acceptButtonText: Yup.string(),
        }),
      }),
    }

    return Yup.object().shape({
      ...(!isEditMode && {
        code: codeValidationSchema,
      }),
      name: Yup.string().max(75, 'Name must not exceed 75 characters').required(validationMessages.REQUIRED),
      ...consentShapeSchema,
      ...(!!enabledLanguages.length && {
        translations: Yup.object().shape(
          enabledLanguages.reduce(
            (acc, { language }) => ({
              ...acc,
              [language.code]: Yup.object().shape(translationsEnabledLanguagesConsentShapeSchema),
            }),
            {},
          ),
        ),
      }),
    })
  }, [isEditMode, isEntitledToJit, organizationLanguages, codeValidationSchema])
}
