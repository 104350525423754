import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react'
import { AuthContextType } from 'interfaces/auth/authContext'
import React, { createContext, useCallback, useEffect } from 'react'
import { InitialLoadingScreen } from 'pages/auth/InitialLoadingScreen'
import { useLocation } from 'react-router-dom'
import { CacheKeys, clear as clearCache, save as saveToCache } from 'utils/CacheUtils'

const checksumai = (window as any)?.checksumai
const posthogPublicKey = (window as any)?.figureheadConfig?.REACT_APP_POSTHOG_PUBLIC_KEY

let auth0: Auth0ContextInterface<any>

export function getAuth0() {
  return auth0
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

type AuthProviderHelperProps = {
  children?: React.ReactNode
}

export const AuthProviderHelper: React.FC<AuthProviderHelperProps> = ({ children }) => {
  auth0 = useAuth0()
  const location = useLocation()

  const { isAuthenticated, user, isLoading, logout, error, loginWithRedirect } = auth0

  useEffect(() => {
    if (user) {
      saveToCache(CacheKeys.orgId, user.org_id)
      saveToCache(CacheKeys.userId, user.sub)
      saveToCache(CacheKeys.userEmail, user.email)
    }
  }, [user])

  const ketchLogout = async () => {
    if (!!posthogPublicKey) {
      try {
        console.info('\n', `resetting posthog `, '\n')
        checksumai?.reset?.()
      } catch (error) {
        console.info('failed to reset posthog')
      }
    }
    clearCache()
    logout()
  }

  const ketchLoginWithRedirect = useCallback(() => {
    /* no sense redirecting to '/' after login, we want the user to go to /callback and have the logic at that route determine where to redirect */
    if (location.pathname === '/') return loginWithRedirect()
    /* we never want to send user back to `/auth/error` after a successful login attempt */
    if (location.pathname === '/auth/error') return loginWithRedirect()
    /* for any other route, we want to send the user back to the route they were trying to access */
    return loginWithRedirect({ appState: { returnTo: location.pathname } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginWithRedirect])

  if (isLoading) {
    return <InitialLoadingScreen />
  }

  const provided = {
    isAuthenticated,
    userData: {
      organizationCode: user?.['https://auth.ketch.com/claims/org/code'],
      organizationId: user?.org_id,
      organizationName: user?.['https://auth.ketch.com/claims/org/name'],
      userId: user?.sub,
      firstName: user?.given_name,
      lastName: user?.family_name,
    },
    logout: ketchLogout,
    isLoading,
    loginWithRedirect: ketchLoginWithRedirect,
    error,
  }

  return <AuthContext.Provider value={provided}>{children}</AuthContext.Provider>
}
