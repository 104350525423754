import { DataMapPurposeDTO } from '@ketch-com/figurehead'
import { PURPOSE_DATA_ROLE_NAMES } from 'interfaces/purposes/purposeDataRole'

export const makeExportCSVData = (purposes: DataMapPurposeDTO[]) => {
  return purposes?.map(purpose => {
    return {
      ...purpose,
      description: purpose.description || 'N/A',
      documents: purpose.documents?.map(document => document.name).join(', ') || 'N/A',
      classifications: purpose.dataCategories?.join(', ') || 'N/A',
      businessFunctions: purpose.businessFunctions?.join(', ') || 'N/A',
      dataSubjectTypes: purpose.dataSubjectTypes?.map(type => type.name).join(', ') || 'N/A',
      dataRole: PURPOSE_DATA_ROLE_NAMES[purpose?.dataRole as unknown as keyof typeof PURPOSE_DATA_ROLE_NAMES] || 'N/A',
      legalBases: purpose.legalBases?.map(lb => `(${lb.jurisdiction}, ${lb.legalBasis})`).join(', ') || 'N/A',
      dataTransfersFrom: purpose.dataTransfersFrom?.join(', ') || 'N/A',
      dataTransfersTo: purpose.dataTransfersTo?.join(', ') || 'N/A',
    }
  })
}
