import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchPermitStatsJurisdiction } from '../fetchers/fetchPermitStatsJurisdiction'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.permitsStatsJurisdiction,
  queryFn: fetchPermitStatsJurisdiction,
  select: res => res?.data?.jurisdictions || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const usePermitsStatsJurisdiction = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    itemsPerPage: config?.itemsPerPage || 5,
    params: {
      ...config?.params,
    },
  })
}
