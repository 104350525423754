import { ResolveExecutionStep200 } from '@ketch-com/bosun/dist/mediation_gen.schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  workflowExecutionId?: string | null | undefined
  stepId?: string | null | undefined
}

export const resolveExecution = ({ workflowExecutionId, stepId }: Params) =>
  API.post<ResolveExecutionStep200>(
    formatRequestString({
      entityUrl: `/api/v0/workflows/executions/${workflowExecutionId}/steps/${stepId}/resolve`,
    }),
  )
