import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { fetchPaginatedDataMapPurposes } from '../fetchers/fetchDataMapPurposes'
import { APIListRequestParams } from 'api/common/utils'
import { showToast } from 'components/ui-kit/toastr/Toastr'

// types
import { DataMapPurposeDTO } from '@ketch-com/figurehead'

type LastPage = { purposes?: DataMapPurposeDTO[]; totalResults?: number }

type Params = APIListRequestParams

export const useDataMapPurposesInfinite = ({ onSettled, params }: { onSettled?: any; params: Params }) => {
  const { limit = 20 } = params

  const resp = useInfiniteQuery({
    onError: () => {
      showToast({ content: 'Failed to fetch data map purposes', type: 'error' })
    },
    queryKey: [ApiQueryKeys.dataMapPurposesInfinite],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchDataMapPurposesResp = await fetchPaginatedDataMapPurposes({
        limit,
        start: (pageParam - 1) * limit,
        ...params,
      })

      const { data } = fetchDataMapPurposesResp
      data.purposes = data.purposes || []
      data.totalResults = data.totalResults ?? 0

      return data
    },

    getNextPageParam: ({ totalResults }: LastPage, allPages: LastPage[]) => {
      let totalPurposesFetchedSoFar = 0
      allPages?.forEach?.(p => {
        totalPurposesFetchedSoFar += p?.purposes?.length || 0
      })
      const nextPageParam = Math.ceil(totalPurposesFetchedSoFar / limit) + 1
      const getNextPageParamReturnValue = totalPurposesFetchedSoFar === totalResults ? undefined : nextPageParam
      return getNextPageParamReturnValue
    },
    onSettled,
    staleTime: 1000 * 60 * 5, // five minutes
  })

  let infinitePurposes: DataMapPurposeDTO[] = []
  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    infinitePurposes = [...infinitePurposes, ...(page?.purposes || [])]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: infinitePurposes,
    totalResults,
  }
}
