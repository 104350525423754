import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { Tab, TabPanel, TabGroup } from '@ketch-com/deck'
import { FormTextInput } from '../FormTextInput'
import { experienceUpsertFormKeys } from '../../../../utils/useExperienceValidationSchema'
import { FormSectionSwitch } from '../FormSectionSwitch'
import { SidebarFormRadioGroup } from '../SidebarFormRadioGroup'
import { BannerButtonAction } from '@ketch-sdk/ketch-types'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { BannerButtonActionLabels } from '../../../../utils/labels'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { styled } from '@mui/styles'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'

const ButtonFormBox = styled(Box)({
  padding: '0px 18px',
})

export const BannerExperienceButtonsForm: React.FC = () => {
  const { values } = useFormikContext<ExperienceV2DTO>()
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length
  const { layout } = values
  // Current tab of the button form
  const [tab, setTab] = React.useState(0)

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
          <Tab size={'medium'} label="Primary" />
          <Tab size={'medium'} label="Secondary" />
          <Tab size={'medium'} label="Tertiary" />
        </TabGroup>
      </Box>

      {/* Primary button form */}
      <TabPanel value={tab} index={0} sx={{ px: 0 }}>
        <SidebarFormGroup hasBorderBottom={hasLanguages} sx={{ paddingTop: '16px' }}>
          <FormTextInput
            name={experienceUpsertFormKeys.bannerPrimaryButtonText}
            label={'Label Text'}
            fullWidth
            charLimit={50}
          />
          <SidebarFormRadioGroup
            name={experienceUpsertFormKeys.bannerPrimaryButtonAction}
            title={'Action'}
            options={Object.values(BannerButtonAction).map(action => ({
              label: BannerButtonActionLabels[action],
              value: action,
            }))}
          />
          {layout?.banner?.buttons?.primary?.action === 'openURL' && (
            <Box mt={-2}>
              <FormTextInput
                name={experienceUpsertFormKeys.bannerPrimaryButtonActionURL}
                placeholder="Paste URL here"
                size="small"
                fullWidth
              />
            </Box>
          )}
        </SidebarFormGroup>
        <SidebarFormTranslations
          title={'Label Text Translations'}
          name={experienceUpsertFormKeys.bannerPrimaryButtonText}
          variant={'text'}
          charLimit={50}
        />
      </TabPanel>

      {/* Secondary button form*/}
      <TabPanel value={tab} index={1} sx={{ px: 0 }}>
        <FormSectionSwitch title={'Secondary Button'} name={experienceUpsertFormKeys.bannerSecondaryButtonVisible} />
        {getIn(values, experienceUpsertFormKeys.bannerSecondaryButtonVisible) && (
          <ButtonFormBox>
            <SidebarFormGroup hasBorderBottom sx={{ paddingTop: '16px' }}>
              <FormTextInput
                name={experienceUpsertFormKeys.bannerSecondaryButtonText}
                label={'Label Text'}
                fullWidth
                charLimit={50}
              />
              <SidebarFormRadioGroup
                name={experienceUpsertFormKeys.bannerSecondaryButtonAction}
                title={'Action'}
                options={Object.values(BannerButtonAction).map(action => ({
                  label: BannerButtonActionLabels[action],
                  value: action,
                }))}
              />
              {layout?.banner?.buttons?.secondary?.action === 'openURL' && (
                <Box mt={-2}>
                  <FormTextInput
                    name={experienceUpsertFormKeys.bannerSecondaryButtonActionURL}
                    placeholder="Paste URL here"
                    size="small"
                    fullWidth
                  />
                </Box>
              )}
            </SidebarFormGroup>
            <SidebarFormTranslations
              title={'Label Text Translations'}
              name={experienceUpsertFormKeys.bannerSecondaryButtonText}
              variant={'text'}
              charLimit={50}
            />
          </ButtonFormBox>
        )}
      </TabPanel>

      {/* Tertiary button form*/}
      <TabPanel value={tab} index={2} sx={{ px: 0 }}>
        <FormSectionSwitch title={'Tertiary Button'} name={experienceUpsertFormKeys.bannerTertiaryButtonVisible} />
        {getIn(values, experienceUpsertFormKeys.bannerTertiaryButtonVisible) && (
          <ButtonFormBox>
            <SidebarFormGroup hasBorderBottom sx={{ paddingTop: '16px' }}>
              <FormTextInput
                name={experienceUpsertFormKeys.bannerTertiaryButtonText}
                label={'Label Text'}
                fullWidth
                charLimit={50}
              />
              <SidebarFormRadioGroup
                name={experienceUpsertFormKeys.bannerTertiaryButtonAction}
                title={'Action'}
                options={Object.values(BannerButtonAction).map(action => ({
                  label: BannerButtonActionLabels[action],
                  value: action,
                }))}
              />
              {layout?.banner?.buttons?.tertiary?.action === 'openURL' && (
                <Box mt={-2}>
                  <FormTextInput
                    name={experienceUpsertFormKeys.bannerTertiaryButtonActionURL}
                    placeholder="Paste URL here"
                    size="small"
                    fullWidth
                  />
                </Box>
              )}
            </SidebarFormGroup>
            <SidebarFormTranslations
              title={'Label Text Translations'}
              name={experienceUpsertFormKeys.bannerTertiaryButtonText}
              variant={'text'}
              charLimit={50}
            />
          </ButtonFormBox>
        )}
      </TabPanel>
    </Box>
  )
}
