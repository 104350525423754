import React, { useState, useMemo } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button, PopUp, theme } from '@ketch-com/deck'

type Props = {
  identities: string[]
  max?: number
}

export const RightInvocationIdentitySpaces: React.FC<Props> = ({ identities, max = 3 }) => {
  const [isOpen, setIsOpen] = useState(false)

  const mappedIdentities = useMemo(
    () =>
      identities.map(identity => {
        const [key, value] = identity.split('/').slice(1)
        // TODO:BAC - not sure if key can/should be mapped to human readable text
        return (
          <>
            <Typography variant="body" mr={0.5}>
              {key}:
            </Typography>
            <Typography variant="body" color="darkDuskFaded.main">
              {value}
            </Typography>
          </>
        )
      }),
    [identities],
  )

  return (
    <Box>
      <Box component="ul" margin={0} paddingLeft="14px">
        {mappedIdentities.slice(0, max).map((identity, index) => (
          <Box
            component="li"
            key={index}
            sx={{
              whiteSpace: 'nowrap',
              marginBottom: theme.spacing(1),
            }}
          >
            <Typography>{identity}</Typography>
          </Box>
        ))}
      </Box>

      {mappedIdentities.length > max && (
        <Button
          variant="link"
          color="secondary"
          onClick={() => {
            setIsOpen(true)
          }}
        >
          View All
        </Button>
      )}

      {isOpen && (
        <PopUp
          title="Identifiers"
          variant="dialog"
          onClose={(_: object) => {
            setIsOpen(false)
          }}
          popUpActionChildren={
            <Button
              color="primary"
              size="large"
              onClick={() => {
                setIsOpen(false)
              }}
            >
              Close
            </Button>
          }
        >
          <Box component="ul" margin={0}>
            {mappedIdentities.map((identity, index) => (
              <Box
                component="li"
                key={index}
                sx={{
                  whiteSpace: 'nowrap',
                  marginBottom: theme.spacing(1),
                }}
              >
                <Typography>{identity}</Typography>
              </Box>
            ))}
          </Box>
        </PopUp>
      )}
    </Box>
  )
}
