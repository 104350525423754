import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces/common'
import { WorkflowDTO, WorkflowSummaryDTO } from 'interfaces/workflows'
import { EventV2DTO } from '@ketch-com/figurehead'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { WorkflowVersionsMessage } from 'pages/orchestration/workflows/view/components/WorkflowVersionsMessage'
import { WorkflowViewActions } from 'pages/orchestration/workflows/view/components/WorkflowViewActions'
import { WorkflowViewDetails } from 'pages/orchestration/workflows/view/components/WorkflowViewDetails'
import { WorkflowViewRoutes } from 'pages/orchestration/workflows/view/WorkflowViewRoutes'
import { Spinner, ViewLayout } from '@ketch-com/deck'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Box } from '@mui/material'
import { IssuesMessage } from 'components/issuesMessage/IssuesMessage'

type Props = {
  isReady: boolean
  workflow: MaybeNull<WorkflowDTO>
  workflowSummary: MaybeNull<WorkflowSummaryDTO>
  events: EventV2DTO[]
  versions: WorkflowDTO[]
}

export const WorkflowView: React.FC<Props> = ({ isReady, workflow, workflowSummary, events, versions }) => {
  const { search, pathname } = useLocation()
  const { queries } = useQueryParams<{ version?: string }>()
  const navigate = useNavigate()
  const [currentTab, setCurrentTab] = useState(0)

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    { title: 'Workflows', link: RoutesManager.orchestration.workflows.root.getURL() },
    { title: workflow?.name || workflow?.code },
  ]

  const tabs = useMemo(
    () => [
      {
        label: 'Events',
        link: RoutesManager.orchestration.workflows.view.events.root.getURL({ code: workflow?.code || '' }),
        search,
      },
      {
        label: 'Versions',
        link: RoutesManager.orchestration.workflows.view.versions.root.getURL({ code: workflow?.code || '' }),
        search,
      },
    ],
    [search, workflow],
  )

  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (pathname.includes(tab.link)) {
        setCurrentTab(index)
      }
    })
  }, [pathname, tabs])

  const isLatest = !queries.version

  const issuesMessageActions = {
    title: 'Update',
    onClick: () => {
      navigate(RoutesManager.orchestration.workflows.upsert.root.getURL({ code }))
    },
  }

  if (!isReady || !workflow) {
    return (
      <>
        <NavigationBreadCrumbs type="dark" items={breadcrumbs} />
        <Box display="flex" justifyContent="center" alignItems="center" borderRadius={1.375} width="1280px" flex={1}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )
  }

  const { hasIssues, name, code } = workflow

  const banners = (
    <>
      {!isLatest && <WorkflowVersionsMessage workflow={workflow} />}
      {!!hasIssues && <IssuesMessage actions={issuesMessageActions} entityType={ApplicationEntity.WORKFLOW} />}
    </>
  )

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      <Box display="flex" flexDirection="column" gap="24px" alignItems="center" flex={1}>
        {banners}
        <ViewLayout
          contentGroupProps={{
            title: name || code,
            showBackButton: true,
            propsBackButton: {
              onClick: () => navigate(RoutesManager.orchestration.workflows.root.getURL()),
            },
            infoBlockComponent: (
              <WorkflowViewDetails isLatest={isLatest} workflow={workflow} workflowSummary={workflowSummary} />
            ),
            actionRightBlockComponent: (
              <WorkflowViewActions isLatest={isLatest} workflow={workflow} workflowSummary={workflowSummary} />
            ),
          }}
          tabsComponentProps={{
            value: currentTab,
            onChange: (_, value) => {
              navigate({ pathname: tabs[value].link, search: tabs[value].search })
            },
            tabItems: tabs,
          }}
        >
          <WorkflowViewRoutes
            workflow={workflow}
            workflowSummary={workflowSummary}
            versions={versions}
            events={events}
          />
        </ViewLayout>
      </Box>
    </>
  )
}
