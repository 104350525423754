import { theme } from '@ketch-com/deck'
import { TextColorOptions } from 'components/ui-kit/typography/Text'

export type RiskScoreInfo = {
  textColor: string
  bgColor: string
  textColorName: TextColorOptions
  bgColorName: string
  category: string
  flavorText: string
  secondaryTextColor: string
  secondaryTextColorName: TextColorOptions
}

export const RISK_SCORE_INFO = {
  low: {
    textColor: theme.palette.riskScoreLowText.main,
    textColorName: 'riskScoreLowText' as TextColorOptions,
    secondaryTextColor: theme.palette.aero.main,
    secondaryTextColorName: 'aero' as TextColorOptions,
    bgColor: theme.palette.riskScoreLowBg.main,
    bgColorName: 'riskScoreLowBg' as TextColorOptions,
    category: 'Acceptable',
    flavorText:
      'In the likelihood of an event, consequences will create an insignificant impact to the organization overall.',
  },
  medium: {
    textColor: theme.palette.riskScoreMediumText.main,
    textColorName: 'riskScoreMediumText' as TextColorOptions,
    secondaryTextColor: theme.palette.ray.main,
    secondaryTextColorName: 'ray' as TextColorOptions,
    bgColor: theme.palette.riskScoreMediumBg.main,
    bgColorName: 'riskScoreMediumBg' as TextColorOptions,
    category: 'Tolerable',
    flavorText:
      'A tolerable risk score is intended to communicate a rising concern that may grow to a more significant threat.',
  },
  high: {
    textColor: theme.palette.riskScoreHighText.main,
    textColorName: 'riskScoreHighText' as TextColorOptions,
    secondaryTextColor: theme.palette.spanishOrange.main,
    secondaryTextColorName: 'spanishOrange' as TextColorOptions,
    bgColor: theme.palette.riskScoreHighBg.main,
    bgColorName: 'riskScoreHighBg' as TextColorOptions,
    category: 'Critical',
    flavorText:
      'A critical risk score represents a severe impact on business operations and is generally unacceptable.',
  },
  extreme: {
    textColor: theme.palette.riskScoreExtremeText.main,
    textColorName: 'riskScoreExtremeText' as TextColorOptions,
    secondaryTextColor: theme.palette.red.main,
    secondaryTextColorName: 'red' as TextColorOptions,
    bgColor: theme.palette.riskScoreExtremeBg.main,
    bgColorName: 'riskScoreExtremeBg' as TextColorOptions,
    category: 'Extreme',
    flavorText: 'An extreme risk score can lead to a significant business impact or disruption of operations.',
  },
}

export const getRiskScoreDetails = (riskScore: number): RiskScoreInfo => {
  if (riskScore < 30) {
    return RISK_SCORE_INFO.low
  }
  if (riskScore < 60) {
    return RISK_SCORE_INFO.medium
  }
  if (riskScore < 80) {
    return RISK_SCORE_INFO.high
  }

  return RISK_SCORE_INFO.extreme
}
