import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { FormTemplateUpsertContext } from 'pages/consentAndRights/forms/templates/upsert/context/FormTemplateUpsertContextProvider'
import { FormTitle } from './components'
import { FormTemplateSection } from './components/FormTemplateSection'
import { styled } from '@mui/material/styles'
import { Theme } from '@mui/material/styles'

const StickyContainer = styled(Box)(({ theme }: { theme?: Theme }) => ({
  borderRadius: '11px',
  padding: theme?.spacing(3),
  width: '100%',
  backgroundColor: theme?.palette.white.main,
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  top: 55,
}))

type Props = {}

export const TemplateSectionConfigSidePanel: React.FC<Props> = props => {
  const { activeSectionIndex } = useContext(FormTemplateUpsertContext)

  if (activeSectionIndex === undefined) return null

  return (
    <Box minWidth={504} minHeight={1056} position="relative">
      <StickyContainer>
        {activeSectionIndex === 'form-title' && <FormTitle />}

        {typeof activeSectionIndex === 'number' && <FormTemplateSection />}
      </StickyContainer>
    </Box>
  )
}
