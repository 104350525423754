import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles, createStyles } from '@mui/styles'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

import { Text } from 'components/ui-kit/typography/Text'
import { getRenderTags, getRenderTag, getFilterOptions } from 'pages/assetManager/components/ManageLabelsInput/utils'
import { Modal } from 'components/ui-kit/modal/Modal'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import { useDataCategoriesBulkAssignModalUtils } from './hooks'
import { Chip } from 'components/ui-kit/chip/Chip'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import SearchIcon from '@mui/icons-material/Search'
import { getUniqueIdentifier } from './utils'

export const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      autocomplete: {
        maxWidth: 300,
      },
      modalClassName: {
        paddingTop: spacing(2),
      },
    }),
  { name: 'DataCategoriesBulkAssignModal' },
)

type Props = {
  isUpdatingAssetLabels?: boolean
  assetCode: string
  onSubmit?: (labels: ManageLabelModalLabel[]) => void
  onCancel: () => void
}

export const DataCategoriesBulkAssignModal: React.FC<Props> = ({
  onSubmit = () => {},
  assetCode,
  onCancel,
  isUpdatingAssetLabels = false,
}) => {
  const classes = useStyles()

  const {
    handleAutocompleteOnChange,
    handleLabelInputValueOnChange,
    handleRemoveTag,
    isBusy,
    isLoading,
    isOptionsOpen,
    labelOptions,
    labels,
    newLabel,
    setIsOptionsOpen,
    setNewLabel,
  } = useDataCategoriesBulkAssignModalUtils({ assetCode })

  return (
    <Modal
      isLoading={isLoading}
      isSaving={isBusy || isUpdatingAssetLabels}
      title="Classifications"
      subtitle="Search for a classification, or remove those already assigned."
      contentWidth={825}
      submitBtnLabel="Save"
      className={classes.modalClassName}
      cancelBtnLabel="Cancel"
      onSubmitBtnClick={() => {
        onSubmit([...labels])
      }}
      onCancelBtnClick={onCancel}
    >
      {/* Autocomplete */}

      <Box
        display="flex"
        alignItems="center"
        sx={{
          my: 2.25,
          pb: 2.25,
          borderBottom: ({ palette }) => `1px solid ${palette.iron.main}`,
        }}
      >
        <Autocomplete
          id="labels"
          multiple
          fullWidth
          className={classes.autocomplete}
          disableClearable
          popupIcon={null}
          onChange={handleAutocompleteOnChange}
          open={isOptionsOpen}
          getOptionLabel={(option: ManageLabelModalLabel) => option.name}
          options={[...labelOptions]}
          /* value hardcoded to empty array so selected options do not display in the input */
          value={[]}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Escape') {
              setIsOptionsOpen(false)
              if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
            }
          }}
          onClose={() => {
            setNewLabel('')
          }}
          filterOptions={options => getFilterOptions({ options, newLabel: newLabel, labels: labels })}
          renderTags={(value: ManageLabelModalLabel[]) =>
            getRenderTags({ value, handleRemoveTag: handleRemoveTag, labels: labels })
          }
          renderOption={getRenderTag}
          renderInput={params => (
            <TextField
              {...params}
              onFocus={() => setIsOptionsOpen(true)}
              onBlur={() => setIsOptionsOpen(false)}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                ...params.inputProps,
                onChange: handleLabelInputValueOnChange,
                value: newLabel,
                placeholder: 'Label name',
              }}
            />
          )}
        />
      </Box>

      <Box display="flex" alignItems="center" my={2}>
        <Text size={14} weight={600} mr={0.75}>
          Assigned Asset Classifications
        </Text>

        <Text size={14} weight={600} color="darkDuskFaded">
          {labels?.length || 0}
        </Text>
      </Box>

      {/* Classifications */}

      <Box display="flex" alignItems="center" gap={2}>
        {labels?.length > 0 ? (
          labels.map((label, index) => {
            if (label?.code && label?.value)
              return (
                <Chip onRemove={() => handleRemoveTag(getUniqueIdentifier(label))}>
                  <Box display="flex" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        borderRight: ({ palette }) => `1px solid ${palette.darkDuskFaded.main}`,
                        pr: 1,
                      }}
                    >
                      <Text size={14} color="darkDuskFaded">
                        {label?.name}
                      </Text>
                    </Box>
                    <Box display="flex" alignItems="center" sx={{ pl: 1 }}>
                      <Text size={14}>{label?.value}</Text>
                    </Box>
                  </Box>
                </Chip>
              )

            if (label?.code)
              return (
                <Chip onRemove={() => handleRemoveTag(getUniqueIdentifier(label))}>
                  <Text size={14}>{label.code}</Text>
                </Chip>
              )

            if (label?.value)
              return (
                <Chip onRemove={() => handleRemoveTag(getUniqueIdentifier(label))}>
                  <Text size={14}>{label.value}</Text>
                </Chip>
              )
            return null
          })
        ) : (
          <EmptyValueRenderer color="darkDuskFaded" />
        )}
      </Box>
    </Modal>
  )
}
