import { InstalledDataSystemDataMapResidencyV2DTO } from '@ketch-com/figurehead'
import pluralize from 'pluralize'
import { ProcessingActivityContext } from '../../context/ProcessingActivityContext'
import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { Spinner, theme } from '@ketch-com/deck'
import {
  RetentionPeriodType,
  RetentionPeriodTypeOptions,
  securityMeasureOptions,
  SecurityMeasures,
} from 'pages/dataSystems/DataSystem/types'

type Props = {
  residency: InstalledDataSystemDataMapResidencyV2DTO
}

export const DataResidencyTile: React.FC<Props> = ({ residency }) => {
  const { isRegionsLoading, regions } = useContext(ProcessingActivityContext)

  // Assemble residency info - display spinner until regions load
  const country = isRegionsLoading ? (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Spinner thickness={1.5} size={20} />
    </Box>
  ) : (
    regions.find(region => region.code === residency.country)?.name
  )
  const retention =
    residency.retentionPeriodType && residency.retentionPeriod
      ? `${residency.retentionPeriod} ${pluralize(
          RetentionPeriodTypeOptions[parseInt(residency.retentionPeriodType) as RetentionPeriodType].title,
          residency.retentionPeriod,
        )}`
      : 'No retention period'

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      p={2.25}
      border={`1px solid ${theme.palette.iron.main}`}
      borderRadius="11px"
    >
      {/* Country */}
      <Typography variant="label">{country || 'No country'}</Typography>

      {/* Securities list */}
      <Box display="flex" flexDirection="column">
        <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
          Security
        </Typography>
        {residency.security && residency.security.length > 0 ? (
          residency.security.map(security => (
            <Typography key={security} variant="body">
              {securityMeasureOptions[security as SecurityMeasures].title}
            </Typography>
          ))
        ) : (
          <Typography variant="body">No securities</Typography>
        )}
      </Box>

      {/* Retention */}
      <Box display="flex" flexDirection="column" marginTop="-4px">
        <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
          Retention
        </Typography>
        <Typography variant="body">{retention}</Typography>
      </Box>
    </Box>
  )
}
