import Box from '@mui/material/Box'
import React from 'react'
import { Button, DropListButton, ListItemText, ActionSheetItem } from '@ketch-com/deck'
import { ContextVariableDTO } from '@ketch-com/figurehead'
import { ContextVarDTO } from 'pages/orchestration/workflows/edit/interfaces/ITaskActivityParams'
import { Tooltip } from '@mui/material'
import { FormError } from 'components/form/FormError'

type Props = {
  push: (obj: any) => void
  setSelectedDataSubjectCodeForAddition: React.Dispatch<React.SetStateAction<string>>
  selectedVariableCodeForAddition: string
  variableOptions: ContextVariableDTO[]
  currentlySelectedOptions: ContextVarDTO[]
  selectPlaceholder: string
  unAddedVariableWarning?: string
  setUnAddedVariableWarning?: React.Dispatch<React.SetStateAction<boolean>>
}

export const AddVariableRow: React.FC<Props> = ({
  setSelectedDataSubjectCodeForAddition,
  selectedVariableCodeForAddition,
  variableOptions,
  push,
  currentlySelectedOptions,
  selectPlaceholder,
  setUnAddedVariableWarning,
  unAddedVariableWarning,
}) => {
  const selectedVariable = variableOptions.find(cv => cv.code === selectedVariableCodeForAddition) || null

  const filteredContextVariableItems = variableOptions.filter(
    cv => !currentlySelectedOptions?.find(pdv => pdv.code === cv.code),
  )
  return (
    <>
      <Tooltip title={filteredContextVariableItems.length ? '' : 'No variables of this type to add'}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1.5}
          sx={{
            backgroundColor: 'sphereFaded.main',
            borderRadius: 2.75, // 11px
          }}
        >
          {/* Left */}
          <Box display="flex" alignItems="center">
            <DropListButton
              disabled={!filteredContextVariableItems.length}
              disableClearable={false}
              isOptionEqualToValue={(option, value) => option.code === value.code}
              getOptionLabel={option => option?.name || ''}
              renderOption={(props, option, { selected }) => (
                <ActionSheetItem selected={selected} {...props}>
                  <ListItemText selected={selected}>{option.name}</ListItemText>
                </ActionSheetItem>
              )}
              options={filteredContextVariableItems}
              onChange={(event, value) => {
                if (value) {
                  setSelectedDataSubjectCodeForAddition(value.code || '')
                } else {
                  setSelectedDataSubjectCodeForAddition('')
                  setUnAddedVariableWarning?.(false)
                }
              }}
              value={selectedVariable}
              placeholder={selectPlaceholder}
              size="small"
            />
          </Box>

          {/* Right */}
          <Button
            disabled={!selectedVariable}
            color="secondary"
            onClick={() => {
              if (selectedVariable) {
                const variableItem = {
                  name: selectedVariable.name,
                  code: selectedVariable.code,
                  required: false,
                }

                push(variableItem)
                setSelectedDataSubjectCodeForAddition('')
              }
            }}
          >
            Add Variable
          </Button>
        </Box>
      </Tooltip>
      {/* Error */}

      {unAddedVariableWarning && (
        <Box display="flex" alignItems="center" pl={1}>
          <FormError msg={unAddedVariableWarning} />
        </Box>
      )}
    </>
  )
}
