import { useFormikContext } from 'formik'
import { Box, Typography } from '@mui/material'
import { TooltipButton } from '@ketch-com/deck'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { useContext } from 'react'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
import { ImportContextData } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ImportContext/context'
import { ImportContextFormData } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ImportContext/interfaces'

type Props = {}

export const ImportContextHeaderActions: React.FC<Props> = () => {
  const { isAssignedToMe } = useContext(RightInvocationWorkflowStepDetailViewContext)
  const { workflowStep, resolve, isResolving, stepDetails, retryImportContext, isRetryingImportContext } =
    useContext(ImportContextData)
  const { isSubmitting, validateForm } = useFormikContext<ImportContextFormData>()
  const { manualInterventionData = {}, manualInterventionStatus } = stepDetails
  const isCompleted = workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS
  const canResolve = isAssignedToMe && !isCompleted && !isResolving && manualInterventionData?.importContextValue
  const isNotSuccess = workflowStep?.status !== WorkflowExecutionStepStatus.SUCCESS
  const shouldShowResolveButton = isNotSuccess && manualInterventionStatus === 1
  const shouldShowRetryButton =
    isNotSuccess && !shouldShowResolveButton && stepDetails.executionData?.importContext?.supportsRetry
  let tooltipTitle = ''

  if (!isAssignedToMe) tooltipTitle = 'Resolve is only available for assignee'
  if (isAssignedToMe && !manualInterventionData?.importContextValue)
    tooltipTitle = `Please complete Manual Intervention fields`

  return (
    <Box display="flex" gap={1.5} alignItems="center">
      {workflowStep?.completedAt && (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="smallBody" color="darkDuskFaded.main">
            Last Updated
          </Typography>
          <Typography color="darkDusk.main" variant="smallBody">
            <FormattedDateRenderer date={workflowStep?.completedAt} />
          </Typography>
        </Box>
      )}

      {/* Retry Button */}

      {shouldShowRetryButton && (
        <TooltipButton
          title={'Resend the request to transponder if no result has been received.'}
          disabled={isRetryingImportContext || isResolving}
          size="large"
          color="primary"
          onClick={async () => {
            retryImportContext({
              params: {
                workflowExecutionId: stepDetails?.workflowExecutionID,
                stepId: stepDetails?.stepID,
                payload: {
                  importContext: {
                    shouldRetry: true,
                  },
                },
              },
            })
          }}
        >
          Retry
        </TooltipButton>
      )}

      {/* Resolve Button */}

      {shouldShowResolveButton && (
        <TooltipButton
          title={tooltipTitle}
          pending={isSubmitting}
          disabled={!canResolve || isRetryingImportContext}
          size="large"
          color="primary"
          onClick={async () => {
            const { importContextValue: noImportContextValueError } = await validateForm()
            if (!noImportContextValueError) resolve(manualInterventionData?.importContextValue || '')
          }}
        >
          Resolve
        </TooltipButton>
      )}
    </Box>
  )
}
