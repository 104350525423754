import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchInteractionsPaginated } from '../fetchers/fetchInteractionsPaginated'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.auditLogsInteractionsPaginated,
  queryFn: fetchInteractionsPaginated,
  select: res => res?.data?.interactions || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useInteractionsPaginated = (config?: QueryConfig) =>
  useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
