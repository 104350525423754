import { Box, FormControlLabel, Typography } from '@mui/material'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { FormTextInput } from '../FormTextInput'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { FormCheckbox } from '../FormCheckbox'
import { useContext } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'

export const ModalExperienceHeaderForm: React.FC = () => {
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length
  return (
    <>
      <Box>
        <SidebarFormGroup hasBorderTop={false} sx={{ paddingTop: 0 }}>
          <FormTextInput
            label={'Title Text'}
            name={experienceUpsertFormKeys.modalHeaderTitle}
            fullWidth
            charLimit={200}
          />
        </SidebarFormGroup>
        <SidebarFormGroup hasBorderTop hasBorderBottom={hasLanguages}>
          <FormControlLabel
            control={<FormCheckbox name={experienceUpsertFormKeys.modalHeaderCloseButtonVisible} />}
            label={<Typography variant={'label'}>Show "X" Button</Typography>}
          />
        </SidebarFormGroup>
        <SidebarFormTranslations
          title={'Title Text Translations'}
          name={experienceUpsertFormKeys.modalHeaderTitle}
          variant={'text'}
          charLimit={200}
        />
      </Box>
    </>
  )
}
