import { API } from 'utils/api/api'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { APIListRequestParams, APIListResponse } from 'api/common/utils'

type Params = APIListRequestParams<{
  policyScopeCode: string
  organizationCode: string
  includeIssues?: boolean
}>

type Response = APIListResponse<{ policyScope?: PolicyScopeDTO }>

export const fetchPolicyScope = ({ policyScopeCode, organizationCode, includeMetadata, includeIssues }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/policy-scopes/${policyScopeCode}`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
        },
      },
    }),
  )
