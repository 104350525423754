import * as Yup from 'yup'

export const getApiKeyFormValidation = () =>
  Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string(),
    roles: Yup.object()
      .shape({
        // This ensures that the object keys can be any string and values must be strings that are not undefined
      })
      .test('values-check', 'All values must be defined strings', value => {
        if (typeof value !== 'object' || value === null) return false
        return Object.values(value).every(v => typeof v === 'string' && v !== undefined)
      }),
  })
