import React from 'react'
import pluralize from 'pluralize'
import { Box, Typography, Tooltip } from '@mui/material'
import { Chip } from '@ketch-com/deck'
import { PurposeSummary } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type Props = {
  purposes: PurposeSummary[]
}

export const Purposes: React.FC<Props> = ({ purposes = [] }, ref?: React.ForwardedRef<HTMLSpanElement>) => {
  const noOfPurposes = purposes?.length || 0

  return (
    <Tooltip
      title={
        <Box>
          {purposes?.map((purpose, index) => {
            return (
              <Box key={index} my={1}>
                <Typography variant="label" color="white.main">
                  {purpose?.name}
                </Typography>
              </Box>
            )
          })}
        </Box>
      }
      placement="bottom"
    >
      <Box>
        <Chip size="small" label={`${noOfPurposes} ${pluralize('Purpose', noOfPurposes)}`} />
      </Box>
    </Tooltip>
  )
}
