import { CreateRelationdhipFormData } from 'pages/assetManager/relationshipMapping/interfaces'

export const getCreateRelationshipMappingInitialValues = (): CreateRelationdhipFormData => {
  return {
    databaseResourceId: '',
    technology: '',
    foriegnTableResourceId: '',
    primaryTableResourceId: '',
    foriegnColumnResourceId: '',
    primaryColumnResourceId: '',
  } as CreateRelationdhipFormData
}
