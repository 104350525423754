import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { DeploymentPlanDeployFormValues, mapValuesToPayload } from 'pages/consentAndRights/deploymentPlans/deploy/utils'
import { useDeploymentPlan } from 'api/deploymentPlans/queries/useDeploymentPlan'
import { useDeployDeploymentPlan } from 'api/deploymentPlans/mutations/useDeployDeploymentPlan'
import { useApplications } from 'api/applications/queries/useApplications'
import { DeploymentPlanDeploy } from 'pages/consentAndRights/deploymentPlans/deploy/DeploymentPlanDeploy'
import { showToast } from 'components/ui-kit/toastr/Toastr'

export const DeploymentPlanDeployContainer: React.FC = () => {
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()

  const { data: deploymentPlan, isFetching: isDeploymentPlanFetching } = useDeploymentPlan({
    params: {
      deploymentPlanCode: code!,
    },
    onError: () => {
      navigate(RoutesManager.deployment.deploymentPlans.root.getURL())
    },
  })

  const { data: applications, isFetching: isApplicationsFetching } = useApplications()

  const { mutateAsync: handleDeployDeploymentPlan } = useDeployDeploymentPlan({
    onSuccess: () => {
      navigate(RoutesManager.deployment.deploymentPlans.view.root.getURL({ code }))
    },
    onError: () => {
      showToast({ content: 'Failed to manage deployment', type: 'error' })
    },
  })

  const onSubmit = async (values: DeploymentPlanDeployFormValues) => {
    const formData = mapValuesToPayload(values)

    await handleDeployDeploymentPlan({
      params: {
        deploymentPlanCode: code!,
        formData,
      },
    })
  }

  const isReady = !isDeploymentPlanFetching && !isApplicationsFetching

  return (
    <DeploymentPlanDeploy
      isReady={isReady}
      deploymentPlan={deploymentPlan}
      applications={applications}
      onSubmit={onSubmit}
    />
  )
}
