import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import React, { useContext } from 'react'
import { RoutesManager } from 'utils/routing/routesManager'
import { JurisdictionsFormSection } from './components/JurisdictionsFormSection'
import { PurposeMappingFormSection } from './components/PurposeMappingFormSection'
import { VendorsFormSection } from './components/VendorsFormSection'
import { GoogleVendorsFormSection } from './components/GoogleVendorsFormSection'
import { OtherOptionsFormSection } from './components/OtherOptionsFormSection'
import { FormMode } from 'interfaces/formModes/formMode'
import { PrivacyProtocolUpsertContext, withPrivacyProtocolUpsertContext } from './context/PrivacyProtocolUpsertContext'
import { normalizeConfig } from './utils'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'

/**
 * View for configuring a privacy protocol
 */
const PrivacyProtocolUpsertWithContext: React.FC = () => {
  const { protocol, navigate, validationSchema, initialValues, onSubmit, isEditMode, isReady } =
    useContext(PrivacyProtocolUpsertContext)

  const breadcrumbs = [
    {
      title: 'Consent & Rights',
      link: RoutesManager.orchestration.root.getURL(),
    },
    { title: 'Consent', link: RoutesManager.orchestration.consent.systems.root.getURL() },
    { title: 'Privacy Protocols', link: RoutesManager.orchestration.consent.privacyProtocols.root.getURL() },
    {
      title: `${isEditMode ? 'Edit' : 'Enable'} ${protocol.name}`,
      link: RoutesManager.orchestration.consent.privacyProtocols.upsert.root.getURL({
        id: protocol.id,
        formMode: isEditMode ? FormMode.EDIT : FormMode.CREATE,
      }),
    },
  ]

  // Redirect to protocols home if creating, or to view screen if editing
  const handleCancel = () => {
    const navigateTo = isEditMode
      ? RoutesManager.orchestration.consent.privacyProtocols.view.root.getURL({ id: protocol.id })
      : RoutesManager.orchestration.consent.privacyProtocols.root.getURL()
    navigate(navigateTo)
  }

  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues: isEditMode ? normalizeConfig(protocol.config!) : initialValues,
    validationSchema,
    onSubmit: onSubmit,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
  })
  const { submitForm, isSubmitting } = formikProps

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={UpsertLayoutMode.edit}
          renderFormTitle={() => (
            <ContentGroup title={`${isEditMode ? 'Edit' : 'Enable'} ${protocol.name}`} titleVariant="h2" isDivider />
          )}
          onAccept={submitForm}
          acceptActionButtonText={isEditMode ? 'Save' : 'Enable'}
          onCancel={handleCancel}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {isReady ? (
              <Box>
                {/* Jurisdiction selection */}
                {protocol.supportsJurisdictionSelection ? <JurisdictionsFormSection /> : <></>}

                {/* Purpose Mapping */}
                {protocol.supportsPurposeMapping ? <PurposeMappingFormSection /> : <></>}

                {/* Vendors Selection */}
                {protocol.supportsVendorSelection ? <VendorsFormSection /> : <></>}

                {/* Google Vendors Selection */}
                {protocol.supportsGoogleVendorSelection ? <GoogleVendorsFormSection /> : <></>}

                {/* Other Options */}
                {protocol.supportsOtherOptions ? <OtherOptionsFormSection /> : <></>}
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%" m={6}>
                <Spinner size="32px" thickness={2.5} />
              </Box>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}

export const PrivacyProtocolUpsert = withPrivacyProtocolUpsertContext(PrivacyProtocolUpsertWithContext)
