import { APIListRequestParams } from 'api/common/utils'
import { useDatabaseDatasetSummariesInfiniteV2 } from './useDatabaseDatasetSummariesInfiniteV2'
import { useDatabaseDatasetSummariesInfiniteV3 } from './useDatabaseDatasetSummariesInfiniteV3'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type Params = APIListRequestParams<{
  query?: string | null | undefined
  assetId?: string
  identityRelationType?: number
  isPersonal?: boolean
  scenarioId?: string
}>

export const useV2OrV3DatabaseDatasetSummariesInfiniteBasedOnFeatureFlag = ({ params }: { params: Params }) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )

  /* V2 */

  const {
    data: infiniteAssetsDataV2,
    isLoading: isLoadingV2,
    hasNextPage: hasNextPageV2,
    fetchNextPage: fetchNextPageV2,
    isFetchingNextPage: isFetchingNextPageV2,
    totalResults: totalResultsV2,
  } = useDatabaseDatasetSummariesInfiniteV2({
    params: {
      ...params,
    },
    options: {
      enabled: isV2FeatureFlagEnabled,
    },
  })

  const payloadV2 = {
    data: infiniteAssetsDataV2 || [],
    isLoading: isLoadingV2,
    hasNextPage: hasNextPageV2,
    fetchNextPage: fetchNextPageV2,
    isFetchingNextPage: isFetchingNextPageV2,
    totalResults: totalResultsV2,
  }

  /* V3 */

  const {
    assetsData: assetsDataV3,
    isLoading: isLoadingV3,
    hasNextPage: hasNextPageV3,
    fetchNextPage: fetchNextPageV3,
    isFetchingNextPage: isFetchingNextPageV3,
    totalResults: totalResultsV3,
  } = useDatabaseDatasetSummariesInfiniteV3({
    params: {
      ...params,
    },
    options: {
      enabled: !isV2FeatureFlagEnabled,
    },
  })

  const payloadV3 = {
    data: assetsDataV3 || [],
    isLoading: isLoadingV3,
    hasNextPage: hasNextPageV3,
    fetchNextPage: fetchNextPageV3,
    isFetchingNextPage: isFetchingNextPageV3,
    totalResults: totalResultsV3,
  }

  return isV2FeatureFlagEnabled ? payloadV2 : payloadV3
}
