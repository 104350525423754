import { ICanvasStep } from '../../interfaces'
import { getUuidWithoutHyphensAndStartingWithALetter } from '../getUuidWithoutHyphensAndStartingWithALetter'
import { cloneDeep } from 'lodash'
import { WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'
import { getStepType } from './getStepType'
import { CanvasStepType } from '../constants'

/**
 * Regenerate the IDs for a list of steps, include any transitions between these steps.
 * @param steps Steps for which to regenerate IDs
 */
export const regenerateIds = (steps: ICanvasStep[]) => {
  // Build new IDs dict
  const oldNewIdMap = steps.reduce(
    (obj, key) => ({ ...obj, [key.ID!]: getUuidWithoutHyphensAndStartingWithALetter() }),
    {} as { [oldId: string]: string },
  )

  const newSteps: ICanvasStep[] = []

  steps.forEach(step => {
    const newStep: ICanvasStep = cloneDeep(step)

    // Determine what type of step this is
    const stepType = getStepType(step)
    const isStepGatewayJoin =
      stepType === CanvasStepType.Gateway && step.gateway?.mode === WorkflowActivityGatewayMode.JOIN

    // Replace old IDs with new IDs in each step
    newStep.ID = oldNewIdMap[step.ID!]
    switch (stepType) {
      case CanvasStepType.Activity:
        newStep.activity!.transition = oldNewIdMap[newStep.activity?.transition || '']
        break
      case CanvasStepType.Gateway:
        if (isStepGatewayJoin) {
          newStep.code = getUuidWithoutHyphensAndStartingWithALetter()
        }
        newStep.gateway!.transitions.forEach(transition => {
          transition.ID = oldNewIdMap[transition.ID || '']
        })
        break
      case CanvasStepType.Placeholder:
        newStep.placeholder!.transition = oldNewIdMap[newStep.placeholder?.transition || '']
        break
      case CanvasStepType.Workflow:
        newStep.workflow!.transition = oldNewIdMap[newStep.workflow?.transition || '']
        break
    }

    // Add new step with updated IDs
    newSteps.push(newStep)
  })

  return newSteps
}
