import React, { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { CreateAuthenticatorDetailsFormSection } from './CreateAuthenticatorDetailsFormSection'
import { CreateAuthenticatorVariablesMappingFormSection } from './CreateAuthenticatorVariablesMappingFormSection'
import { getAuthenticatorInitialValues, useGetEditAuthenticatorValidationSchema } from '../utils'
import { AuthenticatorUpsertContext } from '../context/AuthenticatorUpsertContext'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'

interface Props {}

export const AuthenticatorsEditView: React.FC<Props> = () => {
  const { authenticatorToEdit: authenticator, handleSubmit, isReady } = useContext(AuthenticatorUpsertContext)
  const navigate = useNavigate()
  const initialValues = useMemo(() => getAuthenticatorInitialValues({ authenticator }), [authenticator])
  const validationSchema = useGetEditAuthenticatorValidationSchema()

  const { name } = authenticator

  const breadcrumbs = [
    { title: 'Developers', link: RoutesManager.developers.root.getURL() },
    { title: 'Authentication', link: RoutesManager.developers.authenticators.root.getURL() },
    {
      title: name,
      link: RoutesManager.developers.root.getURL(),
    },
    { title: 'Edit Authentication' },
  ]

  const formikProps = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
  })

  const { isSubmitting, submitForm } = formikProps

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={UpsertLayoutMode.edit}
          renderFormTitle={() => <ContentGroup title="Edit Authentication" titleVariant="h2" isDivider />}
          onAccept={submitForm}
          onCancel={() => {
            navigate(RoutesManager.developers.authenticators.root.getURL())
          }}
          acceptActionButtonText="Save"
          cancelActionButtonText="Cancel"
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {isReady ? (
              <>
                <CreateAuthenticatorDetailsFormSection />
                <CreateAuthenticatorVariablesMappingFormSection />
              </>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%" m={6}>
                <Spinner size="32px" thickness={2.5} />
              </Box>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
