import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ControllerDTO } from 'interfaces/controllers/controller'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { DeleteControllerModal } from 'components/modals/controllers/DeleteControllerModal'
import { Button, Icon, ActionSheetWrapper, ActionSheetItem, ListItemText } from '@ketch-com/deck'
import { Box } from '@mui/material'

type Props = {
  controller: ControllerDTO
}

export const ControllersListDropdown: React.FC<Props> = ({ controller }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const { code } = controller

  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const reFetchControllers = () => queryClient.refetchQueries(ApiQueryKeys.controllersPaginated)

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const open = Boolean(anchorEl)

  const handleClose = () => setAnchorEl(null)

  const items = [
    {
      content: 'Edit',
      hidden: !isPermitted(PERMISSIONS.CONTROLLER_WRITE),
      onClick: () => {
        navigate(RoutesManager.policyCenter.controllers.upsert.root.getURL({ code }))
        handleClose()
      },
    },
    {
      content: 'divider',
    },
    {
      content: 'Delete',
      hidden: !isPermitted(PERMISSIONS.CONTROLLER_WRITE),
      onClick: () => {
        setIsDeleteOpen(true)
        handleClose()
      },
    },
  ]

  return (
    <>
      <Button size="medium" variant="icon" color="tertiary" onClick={handleButtonClick}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map(option => {
          if (option.content === 'divider') {
            return <ActionSheetItem key={option.content} divider />
          }

          return (
            <ActionSheetItem hidden={option.hidden} key={option.content} onClick={option.onClick}>
              <ListItemText>
                <Box display="flex" alignItems="center" gap="15px">
                  {option.content}
                </Box>
              </ListItemText>
            </ActionSheetItem>
          )
        })}
      </ActionSheetWrapper>

      {isDeleteOpen && (
        <DeleteControllerModal
          code={code}
          onSubmit={() => {
            setIsDeleteOpen(false)

            return reFetchControllers()
          }}
          onCancel={() => setIsDeleteOpen(false)}
        />
      )}
    </>
  )
}
