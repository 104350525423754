import { AssetTypeIcon } from 'pages/assetManager/components'
import { UnutilizedDataAssets } from 'pages/insights/dataRisks/__mocks__/unutilizedDataAssets'
import { RoutesManager } from 'utils/routing/routesManager'
import { DataRiskDetailMenu } from '../../components/layout/DataRiskDetailMenu'
import { useStyles } from '../../components/layout/styles'

type Props = {
  unutilizedDataAssets: UnutilizedDataAssets[]
}

export const UnutilizedDataMenu: React.FC<Props> = ({ unutilizedDataAssets }) => {
  const classes = useStyles()

  return (
    <DataRiskDetailMenu
      route={RoutesManager.insights.dataRisks.unutilizedData.view.root}
      items={unutilizedDataAssets.map(asset => ({
        id: asset.id || '',
        text: asset.name || '',
        score: asset.dataRiskScore || 0,
        icon: <AssetTypeIcon assetType={asset?.canonicalResourceType || 0} className={classes.icon} />,
      }))}
    />
  )
}
