/**
 * Convert an array of arrays of strings to an object where each key-value pair is formed from a subarray.
 *
 * @param arr An array of arrays of strings where the first element of each subarray is used as a key,
 *           and the second element is used as the value.
 * @returns An object where the keys are the first elements of the subarrays and the values are the second elements
 *          split by ','.
 */
export function arrayToObject(arr: string[][]): { [key: string]: string[] } {
  // Initialize an empty object to store the key-value pairs
  const result: { [key: string]: string[] } = {}
  // Iterate over each sub-array in the input array
  for (const subarray of arr) {
    // The first element in the sub-array is used as the key for the object
    // The second element in the sub-array is split into an array of strings using ',' as the separator
    // This array of strings is then used as the value for the key
    const key = subarray[0]
    let value = subarray[1] ? subarray[1].split(',') : []
    // If the key is 'query', convert all values to lowercase
    if (key.toLowerCase() === 'query') {
      value = value.map(v => v.toLowerCase())
    }
    result[key] = value
  }
  // Return the resulting object
  return result
}
