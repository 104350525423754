import React from 'react'
import { GetAssetsDataSystemsResponseBodyDTO } from '@ketch-com/figurehead'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { ReactComponent as ConnectIcon } from 'assets/icons/dataSystemActiveConnection.svg'
import { ReactComponent as CheckRoundIcon } from 'assets/icons/check_round.svg'
import { ReactComponent as ExclamationOrangeIcon } from 'assets/icons/exclamation_orange.svg'
import { List } from 'components/ui-kit/list/List'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button } from '@ketch-com/deck'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { useAppDispatch } from 'store/hooks'
import { setFilterState, clearSearchQuery, addTypeFilter } from 'store/megaFilterStateSlice'
import { FILTER_STATES } from 'pages/assetManager/assets/list/components/MegaFilter/constants'
import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import pluralize from 'pluralize'

type Props = {
  assetsDataSystems: GetAssetsDataSystemsResponseBodyDTO
}

const AssetsDataSystemsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.iron.main}`,
}))

const AssetsDataSystemsItemsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  gap: theme.spacing(2),
}))

const AssetsDataSystemsItem = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.superLightGrey.main,
  borderRadius: 12,
  padding: theme.spacing(2.5),
  width: 236,
}))

const AssetsDataSystemsItemHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

const AssetsDataSystemsLogoContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: 8,
  width: 44,
  height: 44,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    height: 32,
    width: 32,
  },
}))

const AssetName = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  display: 'inline-block',
  fontSize: 16,
  fontWeight: 700,
  margin: theme.spacing(3, 0, 4),
}))

const AssetsList = styled(List)(({ theme }) => ({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  borderBottom: `1px solid ${theme.palette.iron.main}`,
}))

const AssetsListContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& span': {
    marginLeft: -8,
  },
  '& svg': {
    position: 'absolute',
    height: 20,
    top: 1,
    left: 150,
  },
}))

const AssetListName = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  cursor: 'pointer',
}))

const CapabilitiesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: 5,
  margin: theme.spacing(1, 0),
}))

const Capability = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5),
}))

export const AssetsDataSystems: React.FC<Props> = ({ assetsDataSystems }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { dataSystems = [] } = assetsDataSystems

  return (
    <AssetsDataSystemsContainer>
      <Box display="flex" justifyContent="space-between" mt={3} mb={4}>
        <Typography variant="h3" color="darkDuskFaded.main">
          Systems
        </Typography>
        <Button disabled={false} onClick={() => navigate(RoutesManager.systems.create.root.getURL())}>
          Add System
        </Button>
      </Box>

      <AssetsDataSystemsItemsContainer>
        {dataSystems.map(dataSystem => {
          const { assetCategories = [], connectionsCount, technology = '' } = dataSystem

          return (
            <AssetsDataSystemsItem key={technology}>
              <AssetsDataSystemsItemHeader>
                <AssetsDataSystemsLogoContainer>
                  <img src={getAssetCloudImgUrl(technology)} alt={technology} />
                </AssetsDataSystemsLogoContainer>
                <ButtonOptions
                  items={[
                    {
                      content: 'Assets',
                      onClick: () => {},
                    },
                    {
                      content: 'Systems',
                      onClick: () => {},
                    },
                    {
                      content: 'Relationships',
                      onClick: () => {},
                    },
                    {
                      content: 'Classifications',
                      onClick: () => {},
                    },
                  ]}
                />
              </AssetsDataSystemsItemHeader>
              <AssetName>{technology}</AssetName>

              <AssetsList
                items={[
                  {
                    active: false,
                    content: (
                      <AssetsListContent key={assetCategories[0].code}>
                        <AssetListName>
                          {assetCategories[0].count}{' '}
                          {pluralize(`${assetCategories[0].code?.split('.')[1]}`, assetCategories[0]?.count || 0)}
                        </AssetListName>
                        <ChevronRightIcon />
                      </AssetsListContent>
                    ),
                    disabled: false,
                    icon: false,
                    onClick: () => {
                      if (assetCategories[0].code) {
                        dispatch(clearSearchQuery())
                        dispatch(setFilterState(FILTER_STATES.OPEN))
                        dispatch(addTypeFilter(assetCategories[0].code))
                        navigate(RoutesManager.assetManager.assets.list.getURL())
                      }
                    },
                  },
                ]}
                size="small"
              />

              <CapabilitiesContainer>
                <Capability>
                  <ConnectIcon />
                  <Typography variant="smallLabel" color="greenHaze.main" pl={0.5}>
                    {connectionsCount} {pluralize('Connection', connectionsCount || 0)}
                  </Typography>
                </Capability>
                <Capability>
                  {connectionsCount === 0 ? <ExclamationOrangeIcon /> : <CheckRoundIcon />}
                  <Typography
                    variant="smallLabel"
                    color={connectionsCount === 0 ? 'chileanFire.main' : 'greenHaze.main'}
                    pl={0.5}
                  >
                    Data Discovery
                  </Typography>
                </Capability>
              </CapabilitiesContainer>
            </AssetsDataSystemsItem>
          )
        })}
      </AssetsDataSystemsItemsContainer>
    </AssetsDataSystemsContainer>
  )
}
