import { Button, Card, CircularProgress, CircularProgressSize, Icon, theme } from '@ketch-com/deck'
import { Box, Typography, styled } from '@mui/material'
import { useRightsStatsReport } from 'api/rights/queries/useRightsStatsReport'
import { RightsOverviewCard } from './RightsOverviewCard'
import { RoutesManager } from 'utils/routing/routesManager'
import { useSidebarRoutesConfig } from 'utils/routing/useSidebarRoutesConfig'
import { GatedNavigateStatus, useNavigateGated } from 'utils/routing/hooks/useNavigateGated'
import { SidebarRouteItem } from 'utils/routing/types'
import { useContext } from 'react'
import { AppNavigationContext } from 'components/appLayout/appNavigation/context/AppNavigationContext'
import { EntitledBlurWrapper } from 'components/entitledBlurWrapper/EntitledBlurWrapper'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

const HomeOverviewCard = styled(Card)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '16px 20px',
  '& .MuiCardContent-root': {
    height: '100%',
  },
})

const CardContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '100%',
  height: '100%',
})

const CardHeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '16px',
})

const CardBodyBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '24px 28px',
})

const RightsCardBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr);',
  width: '100%',
  gap: '16px',
})

export const RightsCard: React.FC = () => {
  const { setSidebarOpenSection } = useContext(AppNavigationContext)
  // Rights data
  const { data: rightsReport, isLoading } = useRightsStatsReport()
  const navigateGated = useNavigateGated()
  const reportsItems = useSidebarRoutesConfig().find(item => item.path === RoutesManager.insights.root.getURL())
    ?.subRoutes as SidebarRouteItem[]
  const rightsRouteItem = reportsItems.find(item => item.path === RoutesManager.insights.rights.root.getURL())

  const handleClick = (item: SidebarRouteItem) => {
    const { status } = navigateGated(
      item.path,
      item.permissions,
      item.entitlements,
      item.requireAllPermissions,
      item.requireAllEntitlements,
    )
    if (status === GatedNavigateStatus.Success && item.sectionId) setSidebarOpenSection(item.sectionId)
  }

  return (
    <HomeOverviewCard
      customContentCard={
        isLoading ? (
          <CircularProgress size={CircularProgressSize.xLarge} />
        ) : (
          <CardContentBox>
            {/* Header */}
            <CardHeaderBox>
              <Box>
                <Typography variant="h3" mb="2px">
                  Rights
                </Typography>
                <Typography color={theme.palette.Text.Secondary}>The current status of your DSARs requests.</Typography>
              </Box>
              <Button variant="icon" color="tertiary" onClick={() => rightsRouteItem && handleClick(rightsRouteItem)}>
                <Icon name="OArrowCRight" />
              </Button>
            </CardHeaderBox>

            {/* Body */}
            <EntitledBlurWrapper entitlements={rightsRouteItem?.entitlements || ENTITLEMENTS.STATS_RIGHTS}>
              <CardBodyBox id="card-body">
                <Box minWidth={128}>
                  <Typography variant="h1">{(rightsReport?.pending || 0).toString()}</Typography>
                  <Typography variant="label" color={theme.palette.Text.Secondary}>
                    Requests
                  </Typography>
                </Box>
                <RightsCardBox>
                  <RightsOverviewCard
                    title={rightsReport?.overdue || 0}
                    subtitle="Overdue"
                    icon="FClock"
                    textColor={theme.palette.Error.PrimaryDark}
                    backgroundColor={theme.palette.Error.Disabled}
                  />
                  <RightsOverviewCard
                    title={rightsReport?.dueOneDay || 0}
                    subtitle="Due Today"
                    icon="OClock"
                    textColor={theme.palette.Error.PrimaryDark}
                    backgroundColor={theme.palette.Error.Disabled}
                  />
                  <RightsOverviewCard
                    title={rightsReport?.dueFourteenDays || 0}
                    subtitle="Due Soon"
                    icon="OClock"
                    backgroundColor={theme.palette.Warning.Disabled}
                    textColor={theme.palette.Warning.PrimaryDark}
                  />
                  <RightsOverviewCard
                    title={rightsReport?.onTrack || 0}
                    subtitle="On Track"
                    icon="FPointer"
                    backgroundColor={theme.palette.Action.Disabled}
                    textColor={theme.palette.Action.PrimaryDark}
                  />
                </RightsCardBox>
              </CardBodyBox>
            </EntitledBlurWrapper>
          </CardContentBox>
        )
      }
    />
  )
}
