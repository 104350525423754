import { DropResult } from 'react-beautiful-dnd'
import { useFormikContext } from 'formik'

import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { KETCH_DML } from 'pages/developers/hooks/constants'
import { MaybeNull } from 'interfaces'
import { reorderHooks } from '../utils'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useUsers } from 'api/users/queries/useUsers'

type Args = {
  assetSummary?: MaybeNull<AssetSummaryDTO>
}

export const useEditAssetFormUtils = ({ assetSummary }: Args) => {
  const { setFieldValue, values } = useFormikContext<EditDatabaseFormValues>()
  const { hooks } = values
  const { data: users, isLoading: isUsersLoading } = useUsers({
    params: {
      active: true,
    },
  })

  const onDragEnd = (result: DropResult) => {
    // handle item dropped outside the list area
    if (!result.destination) {
      return
    }

    const startIndex = result.source.index
    const endIndex = result.destination.index
    const hookBeingRelocated = hooks[startIndex]
    const hookBeingRelocatedType = hookBeingRelocated.type
    const dmlIndex = hooks.findIndex((item: any) => item.name === KETCH_DML)

    if (hookBeingRelocatedType === 1 && endIndex >= dmlIndex) {
      showToast({ content: 'PRE hook must not be after Ketch DML', type: 'error' })
      return
    }

    if (hookBeingRelocatedType === 2 && endIndex <= dmlIndex) {
      showToast({ content: 'POST hook must not be before Ketch DML', type: 'error' })
      return
    }

    const newItems = reorderHooks(hooks, startIndex, endIndex)

    setFieldValue('hooks', newItems)
  }

  return {
    users,
    isUsersLoading,
    onDragEnd,
    hooks,
  }
}
