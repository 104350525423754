import { ActionSheetItem, DropListButton, ListItemText } from '@ketch-com/deck'
import { SxProps } from '@mui/material'
import { useApplications } from 'api/applications/queries/useApplications'
import { MaybeNull } from 'interfaces'
import React, { useMemo } from 'react'

type Props = {
  onChange: (value: MaybeNull<OptionType>) => void
  value: string | null
  sx?: SxProps
}

type OptionType = { label: string; value: string }

export const PropertiesDropList: React.FC<Props> = ({ onChange, value, sx }) => {
  // Get app instances
  const { data: applications, isLoading } = useApplications()

  // Build options
  const options: OptionType[] = useMemo(
    () => [
      {
        label: 'All Properties',
        value: 'all',
      },
      ...applications
        .filter(property => property.ID && property.name)
        .map(property => ({ label: property.name!, value: property.ID! })),
    ],
    [applications],
  )

  const currentValue = useMemo(() => {
    const _value = value || 'all'

    return options.find(elem => elem.value === _value)
  }, [value, options])

  return (
    <DropListButton
      value={currentValue}
      placeholder="Select Property"
      loading={isLoading}
      options={options}
      size="small"
      onChange={(event, newValue) => {
        onChange(newValue?.value === 'all' ? null : newValue)
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <ActionSheetItem key={option.value} selected={selected} {...props}>
            <ListItemText selected={selected}>{option.label}</ListItemText>
          </ActionSheetItem>
        )
      }}
      sx={sx}
    />
  )
}
