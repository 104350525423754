export enum ExperienceActionButtonDestination {
  MODAL = 1,
  PREFERENCES = 2,
  REJECT_ALL = 3,
}

export const EXPERIENCE_ACTION_BUTTON_DESTINATION_NAMES = {
  [ExperienceActionButtonDestination.MODAL]: 'Modal',
  [ExperienceActionButtonDestination.PREFERENCES]: 'Preferences',
  [ExperienceActionButtonDestination.REJECT_ALL]: 'Reject All',
}

export const EXPERIENCE_ACTION_BUTTON_DESTINATION_OPTIONS = [
  { id: ExperienceActionButtonDestination.MODAL, name: 'Open Modal' },
  { id: ExperienceActionButtonDestination.PREFERENCES, name: 'Open Preferences' },
  { id: ExperienceActionButtonDestination.REJECT_ALL, name: 'Reject All / No Consent' },
]
