import React from 'react'
import { EditWorkflowContextProvider } from './contexts/EditWorkflowContext'
import { EditWorkflow } from './EditWorkflow'

type Props = {}

export const EditWorkflowContainer: React.FC<Props> = () => {
  return (
    <EditWorkflowContextProvider>
      <EditWorkflow />
    </EditWorkflowContextProvider>
  )
}
