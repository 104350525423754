import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { SetReviewersForWorkflowExecutionStepRequestBodyDTO } from '@ketch-com/figurehead'

type Params = {
  workflowExecutionId: string
  stepId: string
  formData: SetReviewersForWorkflowExecutionStepRequestBodyDTO
}

export const updateWorkflowExecutionStepReviewers = ({ workflowExecutionId, stepId, formData }: Params) =>
  API.put<{}>(
    formatRequestString({
      entityUrl: `/api/executions/${workflowExecutionId}/steps/${stepId}/reviewers`,
    }),
    formData,
  )
