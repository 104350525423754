import React, { useMemo } from 'react'
import { Box } from '@mui/material'

import { FILTER_STATES } from './constants'
import { Text } from 'components/ui-kit/typography/Text'
import { Table } from 'components/ui-layouts/table/Table'
import { ReactComponent as ToggleChevron } from 'assets/icons/mega_filter_toggle_chevron.svg'
import * as Mega from './components'
import { useMegaFilterStyles } from './hooks'
import { Button } from '@ketch-com/deck'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { clearFilterState, setFilterState } from 'store/megaFilterStateSlice'
import { getMegaFilterState } from 'store/megaFilterStateSlice/selectors'
import { getNumberWithCommas } from 'utils/formatters/getNumberWithCommas'
import { ConnectionDTO } from '@ketch-com/figurehead'
import { getFiltersConfig } from './utils'

type Props = {
  selectedCount?: number
  onSelectAll: (event: React.MouseEvent) => void
  onClear: (event: React.MouseEvent) => void
  totalResults: number
  totalAssetCount: number
  connections: ConnectionDTO[]
}

export const MegaFilter: React.FC<Props> = ({
  selectedCount,
  onSelectAll,
  onClear,
  totalResults = 0,
  totalAssetCount = 0,
  connections,
}) => {
  const { filterState, typeState } = useAppSelector(getMegaFilterState)
  const dispatch = useAppDispatch()
  const classes = useMegaFilterStyles({ filterState })
  const filtersConfig = getFiltersConfig(connections)
  const expandRowsOnMount: number[] = useMemo(() => [], [])

  filtersConfig?.forEach?.((row, rowIndex) => {
    if (row.name && row.id === 'type' && typeState?.length) {
      expandRowsOnMount.push(rowIndex)
    }
  })

  return (
    <Box className={classes.container}>
      {/* First Row */}

      {filterState !== FILTER_STATES.SELECTING && (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" flexDirection="column">
              <Text size={16} weight={700}>
                Filter
              </Text>
              {filterState === FILTER_STATES.COLLAPSED && typeState.length > 0 && (
                // TODO:BAC - this will need to be dynamic once other filters become available.
                <Box mt={0.5} display="flex" alignItems="center">
                  <Text size={14} color="sphere">
                    1
                  </Text>
                </Box>
              )}
            </Box>
            <Box
              className={classes.openButton}
              onClick={() => {
                if (filterState === FILTER_STATES.COLLAPSED) {
                  dispatch(setFilterState(FILTER_STATES.OPEN))
                } else {
                  dispatch(setFilterState(FILTER_STATES.COLLAPSED))
                }
              }}
            >
              <ToggleChevron />
            </Box>
          </Box>

          {filterState === FILTER_STATES.OPEN && (
            <Box mt={1} mb={1.5} display="flex" alignItems="center">
              <Text size={14} color="grey">
                {getNumberWithCommas(totalAssetCount)} {totalAssetCount === 1 ? 'asset' : 'assets'} |{' '}
                {getNumberWithCommas(totalResults)} found
              </Text>
              {typeState.length !== 0 && (
                <Box
                  ml={2}
                  onClick={() => dispatch(clearFilterState())}
                  display="flex"
                  alignItems="center"
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  <Text size={14} color="sphere">
                    Clear All
                  </Text>
                </Box>
              )}
            </Box>
          )}

          <Box className={classes.filterTableContainer}>
            <Table
              hideHeader
              hasRowExpandContentBorderTop
              items={filtersConfig}
              pending={false}
              shouldRemoveTableRowExpandContentPadding
              isRowExpandable={rowData => !!rowData.name}
              expandRowsOnMount={expandRowsOnMount}
              renderExpandableRow={rowData => {
                switch (rowData.id) {
                  case 'type':
                    return <Mega.MegaFilterTypeExpandableRow rowData={rowData} filterState={filterState} />
                  case 'purpose':
                    return <Mega.MegaFilterPurposesExpandableRow rowData={rowData} filterState={filterState} />
                  case 'label':
                    return <Mega.MegaFilterLabelsExpandableRow rowData={rowData} filterState={filterState} />
                  case 'technology-and-providers':
                    return <Mega.MegaFilterTechAndProviderExpandableRow rowData={rowData} filterState={filterState} />
                  default:
                    return null
                }
              }}
              cellSettings={{
                id: {
                  width: 300,
                  cellRenderer: row => (
                    <Box display="flex" alignItems="center">
                      <Box mr={typeState.length > 0 ? 1 : 0}>
                        <Text size={14}>{row.name}</Text>
                      </Box>
                      {!!typeState.length && (
                        <Text size={14} color="sphere">
                          {typeState.length}
                        </Text>
                      )}
                    </Box>
                  ),
                  className: classes.filterOptionNameCell,
                },
              }}
            />
          </Box>
        </>
      )}

      {/*

      Selecting State UI

      Note, This cannot be rendered to the UI currently because the "selecting" state has been disabled/is not organically accessible.

      This is part of the bulk update workflow, BE is not ready.

      */}

      {filterState === FILTER_STATES.SELECTING && (
        <>
          {/* Row 1 */}

          <Box display="flex" alignItems="center">
            <Text size={16} weight={700}>
              {selectedCount} selected
            </Text>
          </Box>

          {/* Row 2 */}

          <Box mb={4} pt={1} display="flex" alignItems="center">
            <Box mr={2} onClick={onSelectAll}>
              <Text size={14} color="sphere" className={classes.bulkSelectActionText}>
                Select All
              </Text>
            </Box>
            <Box onClick={onClear} className={classes.bulkSelectActionText}>
              <Text size={14} color="sphere">
                Clear
              </Text>
            </Box>
          </Box>

          {/* Actions */}
          <Box mb={2}>
            <Text size={14} weight={600} color="grey">
              Actions:
            </Text>
          </Box>

          <Button className={classes.bulkActionButtons} color="tertiary" onClick={() => {}}>
            Assign Label
          </Button>

          <Button className={classes.bulkActionButtons} color="tertiary" onClick={() => {}}>
            Assign Purpose
          </Button>

          <Button className={classes.bulkActionButtons} color="tertiary" onClick={() => {}}>
            Assign Policy
          </Button>

          <Button className={classes.bulkActionButtons} color="tertiary" onClick={() => {}}>
            Edit Data Sensitivity
          </Button>

          <Button color="tertiary" onClick={() => {}}>
            Edit Data Owner
          </Button>
        </>
      )}
    </Box>
  )
}
