import React from 'react'

import { MaybeNull } from 'interfaces'
import {
  WorkflowExecutionDTO,
  WorkflowExecutionStepDTO,
  WorkflowExecutionStepCode,
  WorkflowExecutionStepName,
} from 'interfaces/workflowExecutions/workflowExecution'
import { WorkflowExecutionApprovalStepActions } from './WorkflowExecutionApprovalStepActions'
import { WorkflowExecutionAutomatedStepActions } from './WorkflowExecutionAutomatedStepActions'
import { WorkflowExecutionRedactionStepActions } from './WorkflowExecutionRedactionStepActions'
import { WorkflowExecutionTaskStepActions } from './WorkflowExecutionTaskStepActions'
import { WorkflowExecutionPageViewActions } from './WorkflowExecutionPageViewActions'
import { RightInvocationDTO } from '@ketch-com/figurehead'

type Props = {
  rightInvocation: RightInvocationDTO
  workflowExecution: MaybeNull<WorkflowExecutionDTO>
  workflowExecutionStep: WorkflowExecutionStepDTO
}

export const WorkflowExecutionStepActions: React.FC<Props> = ({
  workflowExecutionStep,
  workflowExecution,
  rightInvocation,
}) => {
  const { activityStepCode, stepName } = workflowExecutionStep
  const isTask = stepName === WorkflowExecutionStepName.TASK
  const isApproval = stepName === WorkflowExecutionStepName.APPROVAL
  const isApiCall = stepName === WorkflowExecutionStepName.API_CALL
  const isDecision =
    stepName === WorkflowExecutionStepName.DECISION_MULTI ||
    stepName === WorkflowExecutionStepName.DECISION_SINGLE ||
    stepName === WorkflowExecutionStepName.DECISION_GATEWAY
  const isRedaction = activityStepCode === WorkflowExecutionStepCode.DATA_REVIEW

  if (isTask) {
    return (
      <WorkflowExecutionTaskStepActions
        rightInvocation={rightInvocation}
        workflowExecutionStep={workflowExecutionStep}
        workflowExecution={workflowExecution}
        isAssigneeRequired
      />
    )
  }

  if (isApproval) {
    return <WorkflowExecutionApprovalStepActions workflowExecutionStep={workflowExecutionStep} />
  }

  if (isApiCall || isDecision) {
    return (
      <WorkflowExecutionPageViewActions
        id={rightInvocation.id}
        stepId={workflowExecutionStep.stepID}
        workflowExecutionId={workflowExecution?.ID || ''}
      />
    )
  }

  if (isRedaction)
    return (
      <WorkflowExecutionRedactionStepActions
        workflowExecution={workflowExecution}
        workflowExecutionId={workflowExecutionStep.workflowExecutionID || ''}
        workflowExecutionStepId={workflowExecutionStep.stepID}
      />
    )

  return (
    <WorkflowExecutionAutomatedStepActions
      rightInvocation={rightInvocation}
      workflowExecution={workflowExecution}
      workflowExecutionStep={workflowExecutionStep}
    />
  )
}
