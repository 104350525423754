import React from 'react'
import { useSetState } from 'react-use'
import { ButtonBase } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { Editor, Transforms } from 'slate'
import { RenderElementProps, ReactEditor } from 'slate-react'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import {
  useSlateInstance,
  unwrapLink,
} from 'pages/consentAndRights/experiences/upsert/components/common/interpolatedTextArea/utils'
import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { DocumentLinkerModal } from '.'
import { LinkElement } from '../interfaces'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      root: {
        color: palette.sphere.main,
      },
      actions: {
        margin: '-4px auto',
      },
      action: {
        flexDirection: 'column',
        width: 51,
        height: 36,
        color: palette.white.main,

        '&:hover': {
          backgroundColor: palette.whiteDisabled.main,

          '& $actionText': {
            display: 'inline',
          },
        },
      },
      actionIcon: {
        fontSize: typography.pxToRem(14),
      },
      actionText: {
        display: 'none',
        fontFamily: typography.fontFamily,
        fontSize: typography.pxToRem(11),
        fontWeight: 500,
        lineHeight: 1.36,
        letterSpacing: typography.pxToRem(0.11),
      },
    }),
  { name: 'LinkElementRenderer' },
)

export const LinkElementRenderer: React.FC<RenderElementProps> = ({ attributes, element, children }) => {
  const classes = useStyles()
  const editor = useSlateInstance()

  const [{ isModalOpen, isTooltipOpen }, setState] = useSetState({
    isModalOpen: false,
    isTooltipOpen: false,
  })

  const handleSelectLink = () => {
    // Find element path
    const path = ReactEditor.findPath(editor, element)

    // Expand selection for whole link node
    Transforms.select(editor, {
      anchor: Editor.start(editor, path),
      focus: Editor.end(editor, path),
    })
  }

  return (
    <>
      <Tooltip
        interactive
        arrow={false}
        tag="span"
        trigger="click"
        open={isTooltipOpen}
        onRequestClose={() => {
          setState({ isTooltipOpen: false })
        }}
        content={
          <Flex className={classes.actions}>
            <ButtonBase
              disableRipple
              disableTouchRipple
              className={classes.action}
              onClick={() => {
                handleSelectLink()
                setState({ isModalOpen: true, isTooltipOpen: false })
              }}
            >
              <EditIcon className={classes.actionIcon} />
              <span className={classes.actionText}>Edit</span>
            </ButtonBase>

            <ButtonBase
              className={classes.action}
              onClick={() => {
                handleSelectLink()
                unwrapLink(editor)
              }}
            >
              <DeleteIcon className={classes.actionIcon} />
              <span className={classes.actionText}>Delete</span>
            </ButtonBase>
          </Flex>
        }
      >
        <a
          {...attributes}
          className={classes.root}
          onClick={() => {
            setState({ isTooltipOpen: true })
          }}
          href={(element as LinkElement).url}
        >
          {children}
        </a>
      </Tooltip>

      {isModalOpen && (
        <DocumentLinkerModal
          isEditMode
          element={element}
          onClose={() => {
            setState({ isModalOpen: false })
          }}
        />
      )}
    </>
  )
}
