import queryString from 'query-string'

import { ObjectLiteral } from 'interfaces'

export const formatRequestString = ({ entityUrl, params = {} }: { entityUrl: string; params?: ObjectLiteral }) => {
  const urlKeys = Object.keys(params)
  const { filters } = params
  const queryStr = queryString.stringify(filters)

  urlKeys?.forEach?.(key => {
    entityUrl = entityUrl.replace(`$${key}$`, params[key])
  })

  if (queryStr) {
    entityUrl += `?${queryStr}`
  }

  return entityUrl.replace(/%26/g, '&').replace(/%3D/g, '=')
}
