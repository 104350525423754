import React from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'

import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { useUpdateFormField } from 'api/formFields/mutations/useUpdateFormField'
import { useFormField } from 'api/formFields/queries/useFormField'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { FormFieldFormDTO } from 'pages/consentAndRights/forms/fields/upsert/interfaces'
import {
  mapFormFieldValuesToPayload,
  getFormFieldInitialValues,
  useFormFieldValidationSchema,
} from 'pages/consentAndRights/forms/fields/upsert/utils'
import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useEnabledOrganizationLanguages } from 'pages/consentAndRights/forms/fields/upsert/hooks'
import { useFormFieldsRelationshipData } from 'pages/consentAndRights/forms/hooks'

export const useFormFieldDetailViewUtils = () => {
  const isEditMode = true
  const { id: formFieldId } = useParams<{ id?: string }>()
  const queryClient = useQueryClient()

  // fetch data
  const { enabledLanguages, isLoadingEnabledLanguages, organizationLanguages } = useEnabledOrganizationLanguages()
  const { data: identitySpaces, isLoading: isLoadingIdentitySpaces } = useIdentitySpaces()
  const { data: formField, isLoading: isLoadingFormField } = useFormField({
    enabled: isEditMode,
    params: {
      formFieldId: formFieldId || '',
      includeMetadata: true,
      includeAuthenticators: true,
    },
  })

  const { hasAnyRelationships } = useFormFieldsRelationshipData(formField)

  // mutation utils
  const { mutateAsync: handleUpdateFormField } = useUpdateFormField({
    onSuccess: () => {
      showToast({ content: 'Form field updated', type: 'success' })
      queryClient.invalidateQueries(ApiQueryKeys.formField)
    },
  })

  const handleSubmit = async (values: FormFieldFormDTO) => {
    const formData = mapFormFieldValuesToPayload({ values, identitySpaces, formField })

    await handleUpdateFormField({
      params: {
        formData,
      },
    })
  }

  // component utils
  const initialValues = React.useMemo(
    () => getFormFieldInitialValues({ formField, languages: enabledLanguages }),
    [enabledLanguages, formField],
  )
  const isReady = !isLoadingIdentitySpaces && !isLoadingEnabledLanguages && !isLoadingFormField

  const validationSchema = useFormFieldValidationSchema({ isEditMode: true, isDetailViewMode: true })

  const translations = Object.entries(formField?.translations || {}) || []

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.applications.root.getURL() },
    { title: 'Form Fields', link: RoutesManager.deployment.forms.fields.root.getURL() },
    { title: formField?.name || formField?.title || 'Custom Form Field' },
  ]

  // inline edit utils
  const formikProps = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnMount: true,
    enableReinitialize: true,
  })

  const tabs = [
    {
      label: 'Overview',
      link: {
        pathname: RoutesManager.deployment.forms.fields.view.overview.root.getURL({ id: formFieldId }),
      },
    },
    {
      label: 'Translations',
      link: {
        pathname: RoutesManager.deployment.forms.fields.view.translations.root.getURL({ id: formFieldId }),
      },
    },

    ...(hasAnyRelationships
      ? [
          {
            label: 'Usage',
            link: {
              pathname: RoutesManager.deployment.forms.fields.view.usage.root.getURL({ id: formFieldId }),
            },
          },
        ]
      : []),
  ]

  const payload = {
    enabledLanguages,
    organizationLanguages,
    formField,
    translations,
    breadcrumbs,
    formikProps,
    isReady,
    tabs,
  }

  return payload
}

export type UseFormFieldDetailViewUtils = ReturnType<typeof useFormFieldDetailViewUtils>
