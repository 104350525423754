import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import {
  SubscriptionContactMethodDTO,
  SubscriptionTopicDTO,
  SubscriptionTopicTranslationDTO,
} from '@ketch-com/figurehead'
import { Box, Tooltip, Typography } from '@mui/material'
import { LegalBaseDTO } from 'interfaces/legalBases/legalBase'
import { SubscriptionTopicsListDropdown } from './components'

export const subscriptionTopicsColumns = (
  legalBases: LegalBaseDTO[],
  subscriptionContactMethods: SubscriptionContactMethodDTO[],
): GridColDef<SubscriptionTopicDTO>[] => [
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    renderCell: ({ row: { code, name } }: GridRenderCellParams<SubscriptionTopicDTO>) => {
      return <TableCell sx={{ padding: '8px 0' }} title={name} subTitle={code} />
    },
  },
  {
    align: 'left',
    field: 'description',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Description',
    sortable: false,
    renderCell: ({ row: { description } }: GridRenderCellParams<SubscriptionTopicDTO>) => {
      if (description) return <TableCell sx={{ padding: '8px 0' }} title={description} />
      return (
        <TableCell sx={{ padding: '8px 0' }}>
          <Typography color="darkDuskFaded.main">None</Typography>
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'legalBasis',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Legal Basis',
    sortable: false,
    renderCell: ({ row: { legalBasisCode = '' } }: GridRenderCellParams<SubscriptionTopicDTO>) => {
      const selectedLegalBasis = legalBases.filter(({ code = '' }) => code === legalBasisCode)?.[0] || {}
      const { name: legalBasisName = '' } = selectedLegalBasis
      return (
        <TableCell>
          <Chip label={legalBasisName} size="small" />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'contactMethod',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Contact Method',
    sortable: false,
    renderCell: ({ row: { contactMethods = [] } }: GridRenderCellParams<SubscriptionTopicDTO>) => {
      if (!contactMethods.length)
        return (
          <TableCell sx={{ padding: '8px 0' }}>
            <Chip label={<Typography color="darkDuskFaded.main">None</Typography>} />
          </TableCell>
        )
      else {
        if (contactMethods.length === 1) {
          return (
            <TableCell sx={{ padding: '8px 0' }}>
              <Status
                variant={StatusVariant.filled}
                status={StatusState.new}
                label={subscriptionContactMethods.filter(method => contactMethods.includes(method.code || ''))[0].name}
              />
            </TableCell>
          )
        }
        return (
          <TableCell sx={{ padding: '8px 0' }}>
            <Tooltip
              placement="right"
              title={
                <Box display="flex" flexDirection="column" p={1}>
                  {subscriptionContactMethods
                    .filter(method => contactMethods.includes(method.code || ''))
                    .map(method => (
                      <Typography key={method.code} fontSize={11} fontWeight={500}>
                        {method.name || ''}
                      </Typography>
                    ))}
                </Box>
              }
            >
              <Status
                variant={StatusVariant.filled}
                status={StatusState.inProgress}
                label={contactMethods?.length.toString()}
              />
            </Tooltip>
          </TableCell>
        )
      }
    },
  },
  {
    align: 'left',
    field: 'translations',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Translations',
    sortable: false,
    renderCell: ({ row: { translations = {} } }: GridRenderCellParams<SubscriptionTopicDTO>) => {
      const langCode: string[] = Object.keys(translations)
      const filteredTranslations = langCode.filter(code => {
        const translationData: SubscriptionTopicTranslationDTO = translations[code]
        if (translationData && (translationData.name || translationData.description)) return true
        return false
      })
      const totalTranslations = Object.keys(filteredTranslations).length
      return (
        <TableCell sx={{ padding: '8px 0' }}>
          <Status variant={StatusVariant.filled} status={StatusState.inProgress} label={totalTranslations.toString()} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'dropDown',
    headerAlign: 'left',
    headerName: '',
    sortable: false,
    maxWidth: 60,
    flex: 1,
    renderCell: ({ row: topic }: GridRenderCellParams<SubscriptionTopicDTO>) => {
      return <SubscriptionTopicsListDropdown subscriptionTopic={topic} />
    },
  },
]
