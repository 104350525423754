import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createTeam } from '../mutations/createTeam'

const useCustomMutation = createUseMutation({
  mutationFn: createTeam,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateTeam = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
