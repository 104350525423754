import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { fetchRightInvocationExport } from '../fetchers/fetchRightInvocationExport'

const useCustomMutation = createUseMutation({
  mutationFn: fetchRightInvocationExport,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useFetchRightInvocationExport = (config?: Config) => {
  const mutation = useCustomMutation(config)
  return useBindMutationStaticParams(mutation)
}
