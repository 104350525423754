import React from 'react'
import { Modal } from 'components/ui-kit/modal/Modal'
import { Box, Typography } from '@mui/material'

type Props = {
  assetType: string
  onCancel: () => void
}

export const AssetDeletedModal: React.FC<Props> = ({ onCancel, assetType }) => {
  return (
    <Modal
      isRemoveMaxHeight
      cancelBtnLabel="Close"
      contentWidth={750}
      onCancelBtnClick={onCancel}
      title={`${assetType} not detected`}
    >
      <Box mt={3}>
        <Typography variant="body">
          This {assetType.toLowerCase()} was no longer detected during our scan. This means that this{' '}
          {assetType.toLowerCase()} could have been deleted or ketch has lost permission.
        </Typography>
        <br />
        <br />
        <Typography variant="body">
          Please reach out to your database administrator if you believe this was an error.
        </Typography>
      </Box>
    </Modal>
  )
}
