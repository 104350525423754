import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createSubscription } from '../fetchers/createSubscription'

const useCustomMutation = createUseMutation({
  mutationFn: createSubscription,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useSubscription = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
