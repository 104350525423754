import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

import { createRelationshipV2 } from '../fetchers/createRelationshipV2'

const useCustomMutation = createUseMutation({
  mutationFn: createRelationshipV2,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateRelationshipV2 = (config?: Config) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, { isV2FeatureFlagEnabled })
}
