import Box from '@mui/material/Box'
import React from 'react'
import { isFunction } from 'lodash'
import { Chip } from '@ketch-com/deck'
import { SystemStyleObject } from '@mui/system/styleFunctionSx'
import { Theme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Variant } from '@mui/material/styles/createTypography'

export type ChipData = {
  /** Unique key for the chip */
  key: string
  /** Display string for the chip */
  label: string
}

type ChipListProps = {
  /** List of data to include in the list */
  data: ChipData[]
  /** Function called when a chip is deleted */
  onDelete?: (deletedChip: ChipData) => any
  /** Function called when a chip is clicked */
  onClick?: (clickedChip: ChipData) => any
  /** Left icon */
  icon?: React.ReactElement
  /** Right icon */
  deleteIcon?: React.ReactElement
  /** Display chips vertically instead of horizontally */
  verticalChips?: boolean
  /** Clickable */
  clickable?: boolean
  /** Typography variant for chip label */
  chipLabelVariant?: Variant
  /** Size property for chip */
  chipSize?: 'small' | 'medium'
  /** Styles for the container */
  containerSx?: SystemStyleObject<Theme>
}

/**
 * A list of chips which can optionally be selected or deleted
 */
export const ChipList: React.FC<ChipListProps> = ({
  data,
  onDelete,
  onClick,
  icon,
  deleteIcon,
  verticalChips,
  clickable,
  chipLabelVariant,
  chipSize = 'small',
  containerSx,
}) => {
  return (
    <Box
      display="inline-flex"
      flexDirection={verticalChips ? 'column' : 'row'}
      alignItems="flex-start"
      gap={1}
      flexWrap="wrap"
      borderRadius={2}
      sx={containerSx}
    >
      {data.map(d => (
        <Chip
          size={chipSize}
          key={d.key}
          label={<Typography variant={chipLabelVariant || 'body'}>{d.label}</Typography>}
          onDelete={isFunction(onDelete) ? onDelete(d) : undefined}
          onClick={isFunction(onClick) ? onClick(d) : undefined}
          clickable={clickable}
          icon={icon}
          deleteIcon={deleteIcon}
        />
      ))}
    </Box>
  )
}
