import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

export const useHandleViewAssetOnClick = (assetSummary: AssetSummaryDTO) => {
  const navigate = useNavigate()

  return useCallback(() => {
    navigate(
      RoutesManager.assetManager.assets.detail.root.getURL({
        code: assetSummary?.asset?.resource?.id || '',
        resourceTypeCode: assetSummary?.asset?.resource?.resourceType?.code || '',
      }),
    )
  }, [assetSummary, navigate])
}
