import { ReactComponent as StartIcon } from 'assets/icons/api-status-start.svg'
import { FormFieldDTO, FormFieldTypeDTO, FormFieldVariantDTO } from '@ketch-com/figurehead'
import { WebhooksContextTimelineItemStatus } from '../../../../../interfaces'
import { Icon, theme } from '@ketch-com/deck'

export const getStatusIcon = (status?: WebhooksContextTimelineItemStatus) =>
  status === WebhooksContextTimelineItemStatus.SUCCESS ? (
    <Icon name="OCheckRound2" iconColor={theme.palette.greenHaze.main} />
  ) : status === WebhooksContextTimelineItemStatus.ERROR ? (
    <Icon name="ODecline" iconColor={theme.palette.error.main} />
  ) : status === WebhooksContextTimelineItemStatus.UNKNOWN || status === WebhooksContextTimelineItemStatus.INVALID ? (
    <Icon name="OImportant" iconColor={theme.palette.chileanFire.main} />
  ) : status === WebhooksContextTimelineItemStatus.START ? (
    <StartIcon />
  ) : null

export const getTitleColorAlt = (status?: WebhooksContextTimelineItemStatus) =>
  status === WebhooksContextTimelineItemStatus.UNKNOWN || status === WebhooksContextTimelineItemStatus.INVALID
    ? 'chileanFire.main'
    : 'darkDusk.main'

export const getHumanReadableTimelineItemFieldType = (formField?: FormFieldDTO) => {
  if (!formField) return ''

  const isTextArea =
    formField.type === FormFieldTypeDTO.TextFormFieldType &&
    formField.variant === FormFieldVariantDTO.TextareaFormFieldVariant
  const isInput =
    formField.type === FormFieldTypeDTO.TextFormFieldType &&
    formField.variant === FormFieldVariantDTO.InputFormFieldVariant

  if (isTextArea || isInput) return 'Text Field'

  if (formField.type === FormFieldTypeDTO.DropdownFormFieldType) return 'Dropdown'

  if (formField.type === FormFieldTypeDTO.CheckboxFormFieldType) return 'Array of Strings'

  return 'Input'
}
