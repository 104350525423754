import * as Yup from 'yup'

export const getCreateRelationshipMappingValidation = () => {
  return Yup.object().shape({
    databaseResourceId: Yup.string().required('Required'),
    foriegnTableResourceId: Yup.string().required('Required'),
    primaryTableResourceId: Yup.string().required('Required'),
    foriegnColumnResourceId: Yup.string().required('Required'),
    primaryColumnResourceId: Yup.string().required('Required'),
  })
}
