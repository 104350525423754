import { GetOnboardingProgressResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchOnboardingProgress = () =>
  API.get<GetOnboardingProgressResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/onboarding-progress`,
    }),
  )
