import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { APIListRequestParams } from 'api/common/utils'
import { fetchClassificationsPaginatedV2 } from '../fetchers/fetchClassificationsPaginatedV2'
import { FetchLabelDefinitionsResponseBodyDTO, LabelDefinitionDTO } from '@ketch-com/figurehead'

type LastPage = FetchLabelDefinitionsResponseBodyDTO

type Params = APIListRequestParams<{
  includeIssues?: boolean
  includeSystemLabels?: boolean
}>

type Options = {
  enabled?: boolean
}

export const useClassificationsInfiniteV2 = ({ options, params }: { options?: Options; params: Params }) => {
  const { limit = 20, includeIssues } = params
  const resp = useInfiniteQuery({
    enabled: !!options?.enabled,
    queryKey: [ApiQueryKeys.classificationsPaginatedV2],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchClassificationsResults = await fetchClassificationsPaginatedV2({
        includeIssues,
        limit,
        start: (pageParam - 1) * limit,
        ...params,
      })

      const { data } = fetchClassificationsResults
      data.labels = data.labels ?? []
      data.totalResults = data.totalResults ?? 0

      return data
    },
    getNextPageParam: ({ totalResults }: LastPage, allPages: LastPage[]) => {
      let totalPurposesFetchedSoFar = 0
      allPages?.forEach?.(p => {
        totalPurposesFetchedSoFar += p?.labels?.length || 0
      })
      const nextPageParam = Math.ceil(totalPurposesFetchedSoFar / limit) + 1
      const getNextPageParamReturnValue = totalPurposesFetchedSoFar === totalResults ? undefined : nextPageParam
      return getNextPageParamReturnValue
    },
  })

  let infiniteClassifications: LabelDefinitionDTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    infiniteClassifications = [...infiniteClassifications, ...(page?.labels || [])]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: infiniteClassifications,
    totalResults,
  }
}
