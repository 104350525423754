import { useCallback, useMemo, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import { isEmpty } from 'lodash'

import { ExperienceType } from 'interfaces/experiences/experienceType'
import { PreviewDeviceMode } from 'interfaces/preview/previewDeviceMode'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useFormTemplatesPreviews } from 'api/formTemplates/queries/useFormTemplatesPreviews'
import { ExperienceActionButtonDestination } from 'interfaces/experiences/experienceActionButtonDestination'
import {
  ConsentConfigurationMapping,
  ConfigurationMappings,
  getConfigurationsMappings,
  getConfigurationsParams,
  DeploymentPlanView,
  getConsentExperienceView,
} from 'pages/consentAndRights/deploymentPlans/preview/utils'
import { RoutesManager } from 'utils/routing/routesManager'
import { useDeploymentPlan } from 'api/deploymentPlans/queries/useDeploymentPlan'
import { useDeploymentPlanPreviewConfigurations } from 'api/deploymentPlans/queries/useDeploymentPlanPreviewConfigurations'
import { useThemes } from 'api/themes-v2/queries/useThemes'
import { useExperiences } from 'api/experiences/queries/useExperiences'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'

export const useDeploymentPlanPreviewUtils = () => {
  const navigate = useNavigate()
  const [mode, setMode] = useState(PreviewDeviceMode.DESKTOP)
  const { isEntitled } = useIsEntitled()
  const { code: deploymentPlanCode } = useParams<{ code: string }>()

  const [{ activeView, activeExperienceType, activePolicyScopeCode, mappings }, setState] = useSetState({
    activeView: DeploymentPlanView.BANNER,
    activeExperienceType: ExperienceType.CONSENT,
    activePolicyScopeCode: '',
    mappings: {} as ConfigurationMappings,
  })

  const redirectToDeploymentPlansList = () => {
    navigate(RoutesManager.deployment.deploymentPlans.root.getURL())
  }

  const { data: deploymentPlan, isFetching: isDeploymentPlanFetching } = useDeploymentPlan({
    enabled: !!deploymentPlanCode,
    params: {
      deploymentPlanCode: deploymentPlanCode!,
    },
    onError: redirectToDeploymentPlansList,
  })

  const { data: themes, isFetching: isThemesFetching } = useThemes()
  const { data: experiences, isFetching: isExperiencesFetching } = useExperiences()
  const { data: policyScopes, isFetching: isPolicyScopesFetching } = usePolicyScopes()

  const isDependenciesReady =
    !isDeploymentPlanFetching && !isThemesFetching && !isExperiencesFetching && !isPolicyScopesFetching

  useDeploymentPlanPreviewConfigurations({
    enabled: isDependenciesReady,
    params: {
      configurations: getConfigurationsParams({
        deploymentPlan,
        experiences,
        themes,
      }),
    },
    onSuccess: response => {
      const mappings = getConfigurationsMappings({
        deploymentPlan: deploymentPlan!,
        experiences,
        deploymentPlanPreviewConfigurations: response.map(({ data }) => data),
      })

      if (isEmpty(mappings)) {
        return navigate(RoutesManager.deployment.deploymentPlans.view.root.getURL({ code: deploymentPlanCode }))
      }

      const [initialPolicyScopeCode] = Object.keys(mappings)
      const consent = mappings[initialPolicyScopeCode][ExperienceType.CONSENT]

      setState({
        mappings,
        activePolicyScopeCode: initialPolicyScopeCode,
        ...(consent
          ? {
              activeExperienceType: ExperienceType.CONSENT,
              activeView: getConsentExperienceView(consent!),
            }
          : {
              activeExperienceType: ExperienceType.PREFERENCE,
              activeView: DeploymentPlanView.PREFERENCE,
            }),
      })
    },
    onError: redirectToDeploymentPlansList,
  })

  const onPolicyScopeCodeChange = useCallback(
    (nextPolicyScopeCode: string) => {
      const nextMapping = mappings[nextPolicyScopeCode]

      const nextExperienceType = nextMapping[activeExperienceType]
        ? activeExperienceType
        : activeExperienceType === ExperienceType.CONSENT
        ? ExperienceType.PREFERENCE
        : ExperienceType.CONSENT

      setState({
        activePolicyScopeCode: nextPolicyScopeCode,
        activeExperienceType: nextExperienceType,
        activeView:
          nextExperienceType === ExperienceType.CONSENT
            ? getConsentExperienceView(nextMapping[nextExperienceType]! as ConsentConfigurationMapping)
            : DeploymentPlanView.PREFERENCE,
      })
    },
    [activeExperienceType, mappings, setState],
  )

  const onExperienceTypeChange = useCallback(
    (nextExperienceType: ExperienceType) => {
      setState({
        activeExperienceType: nextExperienceType,
        activeView:
          nextExperienceType === ExperienceType.CONSENT
            ? getConsentExperienceView(mappings[activePolicyScopeCode][nextExperienceType]!)
            : DeploymentPlanView.PREFERENCE,
      })
    },
    [activePolicyScopeCode, mappings, setState],
  )

  const onBannerSecondaryButtonClick = useCallback(
    (destination: ExperienceActionButtonDestination) => {
      const isPreference = destination === ExperienceActionButtonDestination.PREFERENCES

      if (isPreference) {
        if (mappings[activePolicyScopeCode][ExperienceType.PREFERENCE]) {
          setState({
            activeView: DeploymentPlanView.PREFERENCE,
            activeExperienceType: ExperienceType.PREFERENCE,
          })
        }
      } else {
        setState({ activeView: DeploymentPlanView.MODAL })
      }
    },
    [activePolicyScopeCode, mappings, setState],
  )

  const { data: formTemplates } = useFormTemplatesPreviews()

  const deploymentPlanName = deploymentPlan?.name

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Deployment Plans', link: RoutesManager.deployment.deploymentPlans.root.getURL() },
    { title: deploymentPlanName || deploymentPlanCode },
    { title: 'Preview' },
  ]

  const policyScopesItems = useMemo(() => {
    const supported = Object.keys(mappings)

    return policyScopes
      .filter(({ code }) => supported.includes(code))
      .map(ps => ({
        ...ps,
        active: ps?.code === activePolicyScopeCode,
        content: ps?.name || ps?.code,
        onClick: () => onPolicyScopeCodeChange(ps?.code),
      }))
  }, [mappings, policyScopes, activePolicyScopeCode, onPolicyScopeCodeChange])

  const mapping = mappings[activePolicyScopeCode]
  const hasBothTypes = !!mapping?.[ExperienceType.CONSENT] && !!mapping?.[ExperienceType.PREFERENCE]

  const data = {
    privacyPolicy: deploymentPlan?.privacyPolicy?.URL || '',
    termsOfService: deploymentPlan?.termsOfService?.URL || '',
  }

  const isReady = !isEmpty(mappings)

  const payload = {
    activeExperienceType,
    activePolicyScopeCode,
    activeView,
    breadcrumbs,
    deploymentPlanCode,
    data,
    formTemplates,
    hasBothTypes,
    isEntitled,
    mapping,
    mappings,
    isReady,
    mode,
    deploymentPlanName,
    onBannerSecondaryButtonClick,
    onExperienceTypeChange,
    policyScopesItems,
    setMode,
  }

  return payload
}

export type UseDeploymentPlanPreviewUtils = ReturnType<typeof useDeploymentPlanPreviewUtils>
