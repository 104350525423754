import { Banner, Button, PopUp } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { matchSearch } from 'utils/helpers/matchSearch'
import { useState } from 'react'
import { WebTagDTO, WebTagPurposeBulkUpdateFormDTO } from 'interfaces/webtags/WebTags'
import { TransferList } from 'components/transferList/TransferList'
import { TransferListItemContentComponent } from 'components/modals/purposes/MapPurposesToWebTagModal'
import { find, isEmpty } from 'lodash'
import { useBulkUpdateWebTagPurposes } from 'api/webtags/mutations/useBulkUpdateWebTagPurposes'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'

type Props = {
  tagIds: string[]
  webTags: WebTagDTO[]
  onClose: () => void
}

/**
 * Modal to handle updating the mapped purposes for multiple web tags at once
 */
export const BulkUpdateTagPurposesModal: React.FC<Props> = ({ tagIds, webTags, onClose }) => {
  const queryClient = useQueryClient()
  const [mappedPurposes, setMappedPurposes] = useState<WebTagDTO['purposes']>([])
  const [mappedPurposesSearchString, setMappedPurposesSearchString] = useState('')
  const [availablePurposesSearchString, setAvailablePurposesSearchString] = useState('')

  const { mutateAsync: bulkUpdatePurposes, isLoading: isBulkUpdating } = useBulkUpdateWebTagPurposes({
    onSuccess: () => {
      showToast({ content: 'Tag purposes update', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Tag purposes update failed', type: 'error' })
    },
  })

  const {
    data: purposesList,
    isLoading: isPurposesListLoading,
    isFetching: isPurposesListFetching,
  } = usePurposes({
    onError: () => {
      showToast({ content: 'Failed to fetch purposes', type: 'error' })
      onClose()
    },
  })

  const isLoading = isBulkUpdating || isPurposesListLoading || isPurposesListFetching

  // Already mapped purposes for this tag
  const mappedPurposesList =
    mappedPurposes?.filter(purpose => matchSearch(purpose.name, mappedPurposesSearchString)) || []

  // Purposes which are not currently mapped to this tag
  const availablePurposesList =
    purposesList?.filter(
      purpose =>
        purpose.enabled &&
        isEmpty(find(mappedPurposes, { code: purpose.code })) &&
        matchSearch(purpose.name, availablePurposesSearchString),
    ) || []

  // Format request body and make update status call, then exit
  const handleSave = async () => {
    const purposeIDs = mappedPurposesList.map(purpose => purpose.ID)
    const tagPurposeMappings: WebTagPurposeBulkUpdateFormDTO['tagPurposeMappings'] = webTags
      .filter(({ ID }) => tagIds.includes(ID))
      .map(({ ID: tagID, appCode, appInstanceID }) => ({ tagID, appCode, appInstanceID, purposeIDs }))
    await bulkUpdatePurposes({ params: { formData: { tagPurposeMappings } } })

    // Refresh tags list and close
    await queryClient.invalidateQueries(ApiQueryKeys.webTagsPaginated)
    onClose()
  }

  return (
    <PopUp
      title="Update Purposes"
      popUpWidth="960px"
      variant="modal"
      onClose={onClose}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" size="large" pending={isLoading} onClick={handleSave}>
            Apply
          </Button>
        </>
      }
    >
      <Box>
        <Banner
          severity="info"
          title="All currently assigned purposes will be replaced with the selected ones from the list."
          children="Built-in consent checks will be turned off if applicable."
          sx={{ mb: '24px', width: '912px !important' }}
        />
        <TransferList
          excludedTitle="All Purposes"
          excludedItems={availablePurposesList}
          excludedItemsSearchString={availablePurposesSearchString}
          includedTitle="Selected Purposes"
          includedItems={mappedPurposesList}
          includedItemsSearchString={mappedPurposesSearchString}
          onChange={nextIncludedItems => setMappedPurposes(nextIncludedItems)}
          onSearchIncludedItems={nextSearchString => setMappedPurposesSearchString(nextSearchString)}
          onSearchExcludedItems={nextSearchString => setAvailablePurposesSearchString(nextSearchString)}
          ContentComponent={TransferListItemContentComponent}
        />
      </Box>
    </PopUp>
  )
}
