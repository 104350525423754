import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { updateInstalledDataSystem } from '../fetchers/updateInstalledDataSystem'

const useCustomMutation = createUseMutation({
  mutationFn: updateInstalledDataSystem,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateInstalledDataSystem = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
