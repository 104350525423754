import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from '../../common/mutation'
import { useAuth } from '../../../utils/hooks'
import { updatePrivacyProtocolConfigStatus } from '../fetchers/updatePrivacyProtocolConfigStatus'

const useCustomMutation = createUseMutation({
  mutationFn: updatePrivacyProtocolConfigStatus,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdatePrivacyProtocolConfigStatus = (config?: Config) => {
  const { userData } = useAuth()
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    organizationCode: userData.organizationCode,
  })
}
