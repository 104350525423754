import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import { CustomURLBuilder } from './CustomURLBuilder'

type Props = {}

export const KetchTagDebug: React.FC<Props> = () => {
  return (
    <Container maxWidth="xl">
      <Box display="flex" flexDirection="column" p={3} sx={{ backgroundColor: 'white.main', borderRadius: 2 }}>
        <Typography mb={4} variant="h3">
          Ketch Tag Debug Builder
        </Typography>

        <CustomURLBuilder onSubmit={() => {}} />
      </Box>
    </Container>
  )
}
