import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { PreviewConfigurationDTO } from '@ketch-com/figurehead'

type Params = {
  experienceCode: string
  policyScopeCode: string
  themeCode: string
}

export type FetchDeploymentPlanPreviewConfigurationParams = Params

export const fetchDeploymentPlanPreviewConfiguration = ({ experienceCode, policyScopeCode, themeCode }: Params) =>
  API.get<PreviewConfigurationDTO>(
    formatRequestString({
      entityUrl: `/api/configurations/${experienceCode}/${policyScopeCode}/${themeCode}`,
    }),
  )
