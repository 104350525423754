import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { GridColDef, GridRowParams } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { Chip, DataGrid, EmptyState, ListLayout, TooltipButton, TableCell, theme } from '@ketch-com/deck'
import { Pagination } from 'api/common/paginatedQuery'
import { ApplicationDTO } from 'interfaces/applications/application'
import { FormMode } from 'interfaces/formModes/formMode'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { APPLICATION_PLATFORM_NAME } from 'interfaces/applications/applicationPlatform'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { ReactComponent as EmptyStateApplicationsIcon } from 'assets/icons/emptyState/EmptyStateApplications.svg'
import { DataDeploymentStatus, DataSubjectTypeBadge } from 'pages/consentAndRights/applications/list/components'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { ApplicationsListDropdown } from './ApplicationsListDropdown'

type Props = {
  isLoading: boolean
  isFetching: boolean
  applications: ApplicationDTO[]
  pagination: Pagination
  totalResults?: number
}

const columns: GridColDef<ApplicationDTO>[] = [
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Property',
    sortable: false,
    renderCell: ({ row: { name, code } }) => <TableCell title={name} subTitle={code} />,
  },
  {
    align: 'left',
    field: 'platform',
    flex: 1,
    maxWidth: 170,
    headerAlign: 'left',
    headerName: 'Type',
    sortable: false,
    renderCell: ({ row: { platform } }) => (
      <TableCell>
        <Chip size="small" label={APPLICATION_PLATFORM_NAME[platform]} />
      </TableCell>
    ),
  },
  {
    align: 'left',
    field: 'status',
    flex: 1,
    maxWidth: 170,
    headerAlign: 'left',
    headerName: 'Deployment Status',
    sortable: false,
    renderCell: ({ row: { status, environments } }) => {
      return (
        <TableCell>
          <DataDeploymentStatus status={status} environments={environments} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'dataSubjectTypes',
    flex: 1,
    maxWidth: 170,
    headerAlign: 'left',
    headerName: 'Subject Type',
    sortable: false,
    renderCell: ({ row: { dataSubjectTypes } }) => (
      <TableCell sx={{ '& > div': { maxWidth: '100%' } }}>
        <DataSubjectTypeBadge dataSubjectTypes={dataSubjectTypes} />
      </TableCell>
    ),
  },
  {
    align: 'left',
    field: 'lastUpdated',
    flex: 1,
    maxWidth: 240,
    headerAlign: 'left',
    headerName: 'Last Updated',
    sortable: false,
    renderCell: ({ row }) => (
      <TableCell>
        <FormattedDateRenderer date={row.metadata?.updatedAt} />
      </TableCell>
    ),
  },
  {
    align: 'left',
    field: 'dropDown',
    flex: 1,
    maxWidth: 64,
    headerAlign: 'left',
    headerName: ' ',
    sortable: false,
    renderCell: ({ row }) => (
      <TableCell>
        <ApplicationsListDropdown application={row} />
      </TableCell>
    ),
  },
]

export const ApplicationsList: FC<Props> = ({ isLoading, isFetching, applications, pagination, totalResults }) => {
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Properties', link: RoutesManager.deployment.propertiesHome.root.getURL() },
  ]

  const isEntitledToCreateApplications = isEntitled(ENTITLEMENTS.QTY_PROPERTY)
  const isPermittedToCreateApplications = isPermitted([PERMISSIONS.PROPERTY_WRITE])
  const canCreateApplications = isEntitledToCreateApplications && isPermittedToCreateApplications

  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / pagination.totalPages) : 20),
    [pagination.totalResults, pagination.totalPages],
  )

  // Assemble pagination object expected by data grid
  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const handleCreate = () => {
    navigate(RoutesManager.deployment.applications.upsert.root.getURL({ formMode: FormMode.CREATE }))
  }

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ListLayout
        customHeaderRightActionComponent={
          isPermittedToCreateApplications && (
            <TooltipButton
              disabled={!isEntitledToCreateApplications}
              title={
                !isEntitledToCreateApplications
                  ? 'No property entitlements remaining, please delete one or contact your admin for more'
                  : undefined
              }
              onClick={handleCreate}
            >
              Create Property
            </TooltipButton>
          )
        }
      >
        <Box>
          {!isFetching && !applications.length ? (
            <Box
              sx={{
                padding: '24px 0',
                borderRadius: '11px',
                backgroundColor: theme.palette.white.main,
              }}
            >
              <EmptyState
                title="There are no properties for this organization"
                subTitle={canCreateApplications ? 'Create your first one to populate the table.' : undefined}
                customIcon={<EmptyStateApplicationsIcon />}
                primaryButtonTitle={canCreateApplications ? 'Create Property' : undefined}
                primaryButtonProps={{
                  size: 'large',
                  onClick: handleCreate,
                }}
              />
            </Box>
          ) : (
            <DataGrid
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              disableRowSelectionOnClick
              autosizeOnMount
              autosizeOptions={{
                expand: true,
                includeHeaders: true,
                includeOutliers: false,
              }}
              noRowsOverlayProps={{
                buttonTitle: '',
              }}
              loading={isLoading}
              getRowHeight={() => 'auto'}
              getRowId={row => row.ID}
              rows={applications}
              columns={columns}
              pagination
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={onPaginationModelChange}
              pageSizeOptions={[rowsPerPage]}
              rowCount={totalResults ?? 0}
              hideFooter={pagination.totalPages < 2}
              onRowClick={({ row: { code } }: GridRowParams<ApplicationDTO>) => {
                navigate(RoutesManager.deployment.applications.view.root.getURL({ code }))
              }}
              sx={{
                '& .MuiDataGrid-virtualScroller': {
                  overflowX: 'hidden',
                },
                ...(isLoading && {
                  '& .MuiDataGrid-virtualScroller': {
                    height: '300px',
                  },
                }),
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                  backgroundImage: 'unset',
                },
                border: 'unset',
              }}
            />
          )}
        </Box>
      </ListLayout>
    </>
  )
}
