import { useIsEntitled } from 'utils/hooks'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
/**
 * @description
 * This hook is used to check if the user is entitled to the experience server rights
 * If not, the user is redirected to the profile page as per https://ketch-com.atlassian.net/browse/KD-6950
 * form fields are to be gated on `exp_server_rights`
 */
export const useRedirectIfNotEntitledToExperienceServerRights = () => {
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()

  useEffect(() => {
    const isEntitledToRoute = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)
    if (!isEntitledToRoute) {
      navigate(RoutesManager.settings.profile.root.getURL())
    }
  }, [navigate, isEntitled])
}
