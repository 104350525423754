import React from 'react'
import { FileInfoDTO } from '@ketch-com/figurehead'
import { Icon, Chip, ChipProps, IconDictionary, CustomIconName, FileExtensionIcon } from '@ketch-com/deck'

type AttachmentChipProps = Omit<ChipProps, 'deleteIcon'> & {
  file: FileInfoDTO
  deleteIconName?: CustomIconName
  iconColor?: string
}

export const AttachmentChip: React.FC<AttachmentChipProps> = ({ ...rest }) => {
  const { deleteIconName, size, iconColor, ...restProps } = rest

  return (
    <Chip
      size={size || 'small'}
      icon={<FileExtensionIcon iconVariant="filled" extension={rest?.file?.contentType} />}
      onDelete={rest?.onDelete || rest?.onClick}
      deleteIcon={<Icon name={deleteIconName || IconDictionary.ODownload} />}
      {...restProps}
    />
  )
}
