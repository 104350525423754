import { Timeline } from '@mui/lab'
import { Box, Typography } from '@mui/material'

import { GetWebhooksContextResponseBodyDTO } from '@ketch-com/figurehead'
import { useState } from 'react'
import { APICallDetailsModal } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/components'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { TimelineItem } from './components'
import { Button } from '@ketch-com/deck'

export type TimelineModalProps = { title?: string; subtitle?: string; headers?: string; body?: string } | undefined

type Props = {
  webhookContext: GetWebhooksContextResponseBodyDTO
}
export const ApiCallStepDetailsTimeline: React.FC<Props> = ({ webhookContext }) => {
  const [modalDetails, setModalDetails] = useState<TimelineModalProps>()

  const { data: identitySpaces } = useIdentitySpaces()

  const timelineLength = webhookContext?.timeline?.length

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box>
        <Typography variant="h3">Timeline</Typography>
        <Typography color="darkDuskFaded.main">
          Questions? Contact your customer support representative at&nbsp;
          <Button
            variant="link"
            color="secondary"
            href="#"
            sx={{
              fontSize: '14px',
              marginTop: '-2px',
            }}
            onClick={e => {
              window.location.href = 'mailto:support@ketch.com'
              e.preventDefault()
            }}
          >
            support@ketch.com
          </Button>
        </Typography>
      </Box>

      {timelineLength ? (
        <Timeline sx={{ alignItems: 'flex-start' }}>
          {webhookContext.timeline?.map((timelineItem, index) => {
            return (
              <TimelineItem
                timelineItem={timelineItem}
                isLastItem={index >= (webhookContext.timeline || []).length - 1}
                setModalDetails={setModalDetails}
                key={JSON.stringify(timelineItem)}
                identitySpaces={identitySpaces}
              />
            )
          })}
        </Timeline>
      ) : (
        <Box>
          <Typography>No timeline is available for this activity.</Typography>
        </Box>
      )}

      {modalDetails ? <APICallDetailsModal {...modalDetails} onCancel={() => setModalDetails(undefined)} /> : null}
    </Box>
  )
}
