import React, { useMemo } from 'react'
import { sortBy } from 'lodash'

import { useGenericCanonicalPurposes } from 'api/purposes/queries/useGenericCanonicalPurposes'
import { useCanonicalRights } from 'api/rights/queries/useCanonicalRights'
import { DataSystemIdentitySpaceV2DTO, InstalledDataSystemV2DTO, PurposeDTO, RightDTO } from '@ketch-com/figurehead'
import { Box, Typography } from '@mui/material'
import { Capabilities, CapabilitiesList } from 'interfaces/dataSystems/Capabilities'
import {
  Chip,
  ContentGroup,
  Icon,
  InfoRow,
  Status,
  TableCell,
  StatusState,
  StatusVariant,
  DataGrid,
} from '@ketch-com/deck'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  appDescriptor: InstalledDataSystemV2DTO
}

type CapabilityType = {
  enabled?: boolean
  name: string
  code: Capabilities
}

type PurposeType = {
  enabled?: boolean
  code: string
  name: string
  description?: string
  purposes?: PurposeDTO[]
}

type RightType = {
  enabled?: boolean
  code: string
  name: string
  description: string
  supportsAppeal?: boolean
  rights: RightDTO[]
  translations?: any
}

export const Details: React.FC<Props> = ({ appDescriptor }) => {
  const { data: purposesList, isLoading: isPurposesListLoading } = useGenericCanonicalPurposes()
  const { data: rightsList, isLoading: isRightsListLoading } = useCanonicalRights()

  const { dataSystem } = appDescriptor

  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.systems.root.getURL() },
    { title: 'Systems', link: RoutesManager.systems.systems.root.getURL() },
    { title: appDescriptor?.dataSystem?.name, link: RoutesManager.systems.id.root.getURL({ id: appDescriptor?.id }) },
    { title: 'Integration Details' },
  ]

  const capabilitiesList: CapabilityType[] = CapabilitiesList.map(capability => {
    return {
      ...capability,
      enabled: dataSystem?.supportedCapabilities?.includes(capability.code),
    }
  })

  const purposesListSorted: PurposeType[] = useMemo(
    () =>
      sortBy(
        purposesList.map(purpose => ({
          ...purpose,
          enabled: dataSystem?.supportedPurposes?.includes(purpose.code),
        })),
        [({ enabled }) => (enabled ? 0 : 1), 'code'],
      ),
    [purposesList, dataSystem],
  )

  const rightsListSorted: RightType[] = useMemo(
    () =>
      sortBy(
        rightsList.map(right => ({
          ...right,
          enabled: dataSystem?.supportedRights?.includes(right.code),
        })),
        [({ enabled }) => (enabled ? 0 : 1), 'code'],
      ),
    [rightsList, dataSystem],
  )

  const docLinks = [
    { link: dataSystem?.documentURLs?.privacyPolicyURL, name: 'Privacy Policy' },
    { link: dataSystem?.documentURLs?.tosURL, name: 'Terms of Use' },
    { link: dataSystem?.documentURLs?.customerSupportURL, name: 'Customer Support' },
    { link: dataSystem?.documentURLs?.statusURL, name: 'Service Status' },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box display="flex" flexDirection="column" gap={6} mb={6}>
        <ContentGroup
          titleVariant="h3"
          title="About"
          subTitle="Technical details and support links about an integration."
        />

        {!!capabilitiesList.length && (
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="h4">Capabilities</Typography>
            <DataGrid
              autosizeOnMount
              autosizeOptions={{
                includeHeaders: true,
                includeOutliers: false,
                columns: capabilityTypeColumns.map(column => column.field),
                expand: true,
              }}
              getRowHeight={() => 'auto'}
              columns={capabilityTypeColumns}
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              disableRowHoverStates
              disableRowSelectionOnClick
              getRowId={row => row.code}
              rows={capabilitiesList}
              hideFooter
              noRowsOverlayProps={{
                buttonTitle: '',
              }}
            />
          </Box>
        )}

        {(isPurposesListLoading || !!purposesListSorted.length) && !!dataSystem?.supportedPurposes?.length && (
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="h4">Supported Purposes</Typography>
            <DataGrid
              autosizeOnMount
              autosizeOptions={{
                includeHeaders: true,
                includeOutliers: false,
                columns: purposeTypeColumns.map(column => column.field),
                expand: true,
              }}
              getRowHeight={() => 'auto'}
              columns={purposeTypeColumns}
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              disableRowHoverStates
              disableRowSelectionOnClick
              getRowId={row => row.code}
              rows={purposesListSorted}
              loading={isPurposesListLoading}
              hideFooter
              noRowsOverlayProps={{
                buttonTitle: '',
              }}
            />
          </Box>
        )}

        {(isRightsListLoading || !!rightsListSorted.length) && !!dataSystem?.supportedRights?.length && (
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="h4">Supported Rights</Typography>
            <DataGrid
              autosizeOnMount
              autosizeOptions={{
                includeHeaders: true,
                includeOutliers: false,
                columns: rightTypeColumns.map(column => column.field),
                expand: true,
              }}
              getRowHeight={() => 'auto'}
              columns={rightTypeColumns}
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              disableRowHoverStates
              disableRowSelectionOnClick
              getRowId={row => row.code}
              rows={rightsListSorted}
              loading={isRightsListLoading}
              hideFooter
              noRowsOverlayProps={{
                buttonTitle: '',
              }}
            />
          </Box>
        )}

        {!!dataSystem?.identitySpaces?.length && (
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="h4">Identifiers</Typography>
            <DataGrid
              autosizeOnMount
              autosizeOptions={{
                includeHeaders: true,
                includeOutliers: false,
                columns: dataSystemIdentitySpaceColumns.map(column => column.field),
                expand: true,
              }}
              getRowHeight={() => 'auto'}
              columns={dataSystemIdentitySpaceColumns}
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              disableRowHoverStates
              disableRowSelectionOnClick
              getRowId={row => row.code}
              rows={dataSystem.identitySpaces}
              hideFooter
              noRowsOverlayProps={{
                buttonTitle: '',
              }}
            />
          </Box>
        )}

        {dataSystem?.documentURLs && (
          <InfoRow title="Support and Documentation Links">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              {docLinks.map(({ name, link }) => {
                if (link) {
                  return (
                    <Box>
                      <Chip
                        label={name}
                        onClick={() => {
                          window.open(link, '_blank', 'noopener,noreferrer')
                        }}
                        deleteIcon={<Icon name="OArrowCRight" />}
                        onDelete={() => window.open(link, '_blank', 'noopener,noreferrer')}
                        clickable
                      />
                    </Box>
                  )
                } else {
                  return null
                }
              })}
            </Box>
          </InfoRow>
        )}
      </Box>
    </>
  )
}

const capabilityTypeColumns: GridColDef<CapabilityType>[] = [
  {
    align: 'left',
    field: 'capability',
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { name } }: GridRenderCellParams<CapabilityType>) => {
      return <TableCell title={name}></TableCell>
    },
  },
  {
    align: 'left',
    field: 'status',
    headerAlign: 'left',
    headerName: 'Status',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { enabled } }: GridRenderCellParams<CapabilityType>) => {
      return (
        <TableCell>
          <Status
            icon="Dot"
            label={enabled ? 'Available' : 'Unsupported'}
            status={enabled ? StatusState.success : StatusState.draft}
            variant={StatusVariant.ghost}
          />
        </TableCell>
      )
    },
  },
]

const purposeTypeColumns: GridColDef<PurposeType>[] = [
  {
    align: 'left',
    field: 'capability',
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { name, code } }: GridRenderCellParams<PurposeType>) => {
      return <TableCell title={name} subTitle={code}></TableCell>
    },
  },
  {
    align: 'left',
    field: 'status',
    headerAlign: 'left',
    headerName: 'Status',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { enabled } }: GridRenderCellParams<PurposeType>) => {
      return (
        <TableCell>
          <Status
            icon="Dot"
            label={enabled ? 'Supported' : 'Unsupported'}
            status={enabled ? StatusState.success : StatusState.draft}
            variant={StatusVariant.ghost}
          />
        </TableCell>
      )
    },
  },
]

const rightTypeColumns: GridColDef<RightType>[] = [
  {
    align: 'left',
    field: 'capability',
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { name, code } }: GridRenderCellParams<RightType>) => {
      return <TableCell title={name} subTitle={code}></TableCell>
    },
  },
  {
    align: 'left',
    field: 'status',
    headerAlign: 'left',
    headerName: 'Status',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { enabled } }: GridRenderCellParams<RightType>) => {
      return (
        <TableCell>
          <Status
            icon="Dot"
            label={enabled ? 'Supported' : 'Unsupported'}
            status={enabled ? StatusState.success : StatusState.draft}
            variant={StatusVariant.ghost}
          />
        </TableCell>
      )
    },
  },
]
const dataSystemIdentitySpaceColumns: GridColDef<DataSystemIdentitySpaceV2DTO>[] = [
  {
    align: 'left',
    field: 'name',
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { name, code } }: GridRenderCellParams<DataSystemIdentitySpaceV2DTO>) => {
      return <TableCell title={name} />
    },
  },
  {
    align: 'left',
    field: 'description',
    headerAlign: 'left',
    headerName: 'Description',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { description } }: GridRenderCellParams<DataSystemIdentitySpaceV2DTO>) => {
      return <TableCell title={description} />
    },
  },
]
