import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'

import { fetchConnections } from '../fetchers/fetchConnections'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.connections,
  queryFn: fetchConnections,
  select: res => res?.data.connections,
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useConnections = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
