import { theme } from '@ketch-com/deck'
import { StatusVariantV3Enum } from '../../interfaces'

const { palette } = theme

export const getSmallBackgroundColor = (variant: StatusVariantV3Enum): string => {
  switch (variant) {
    case StatusVariantV3Enum.draft:
      return palette.bone.main
    case StatusVariantV3Enum.new:
      return palette.fadedHeliotrope.main
    case StatusVariantV3Enum.inProgress:
      return palette.bleachWhite.main
    case StatusVariantV3Enum.activeEnabled:
      return palette.tara.main
    case StatusVariantV3Enum.errorCanceled:
      return palette.lightRed.main
    case StatusVariantV3Enum.finishedDone:
      return palette.sphereFaded.main
    case StatusVariantV3Enum.inactiveArchive:
      return palette.bone.main
    default:
      return palette.bone.main
  }
}
