import { getRelease } from '.'
import { Release } from './RELEASES'

/**
 * Compares current release with param.
 *
 * @param release name of current release.
 * @return bool
 */
export const isRelease = (release: Release): boolean => {
  const curRelease = getRelease()
  return release === curRelease
}
