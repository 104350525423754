import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'

import { RightDTO } from 'interfaces/rights/right'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { RoutesManager } from 'utils/routing/routesManager'
import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { CreateRightInvocationForm } from 'pages/orchestration/rightsQueue/create/CreateRightInvocationForm'
import { getRightInvocationInitialValues, getRightInvocationValidationSchema } from './utils'
import { RightInvocationFormValues } from 'interfaces/rightInvocations'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { DevQuickCreate } from './DevQuickCreate'
import { FormikProvider, useFormik } from 'formik'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RecaptchaDisclosure } from 'components/recaptcha/RecaptchaDisclosure'

type Props = {
  isReady: boolean
  rights: RightDTO[]
  identitySpaces: IdentitySpaceDTO[]
  policyScopes: PolicyScopeDTO[]
  onSubmit: (values: RightInvocationFormValues) => Promise<void>
  dataSubjectTypes: DataSubjectTypeDTO[]
}

export const CreateRightInvocation: React.FC<Props> = ({
  isReady,
  rights,
  policyScopes,
  identitySpaces,
  onSubmit,
  dataSubjectTypes,
}) => {
  const navigate = useNavigate()

  const initialValues = useMemo(getRightInvocationInitialValues, [])
  const validationSchema = useMemo(() => getRightInvocationValidationSchema(dataSubjectTypes), [dataSubjectTypes])

  const formikProps = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
  })

  const { isSubmitting, submitForm } = formikProps

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    { title: 'Rights Queue', link: RoutesManager.orchestration.rightsQueue.root.getURL() },
    { title: 'Create New Rights Request' },
  ]
  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          showStepper={false}
          mode={UpsertLayoutMode.create}
          renderFormTitle={() => <ContentGroup title="Create New Rights Request" titleVariant="h2" isDivider />}
          onAccept={submitForm}
          onCancel={() => {
            navigate(RoutesManager.orchestration.rightsQueue.root.getURL())
          }}
          acceptActionButtonText="Save"
          cancelActionButtonText="Cancel"
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {isReady ? (
              <>
                <DevQuickCreate />

                <CreateRightInvocationForm
                  rights={rights}
                  policyScopes={policyScopes}
                  identitySpaces={identitySpaces}
                  dataSubjectTypes={dataSubjectTypes}
                />
                <Box>
                  <RecaptchaDisclosure />
                </Box>
              </>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%" m={6}>
                <Spinner size="32px" thickness={2.5} />
              </Box>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
