import { DsrIdentityLookupFormData } from 'pages/assetManager/dsrIdentityLookup/interfaces'

type Args = {
  datasetId: string
}

export const getDsrIdentityLookupInitialValues = ({ datasetId }: Args): DsrIdentityLookupFormData => ({
  isPrimaryDataset: true,
  datasetId: datasetId,
  primaryAttributeIdentitySpaceCode: '',
  primaryAttributeId: '',
  relatedDatasetHasIdentitySpace: false,
})
