import { FormikProps } from 'formik'
import { AuthenticatorFormSteps } from './AuthenticatorFormSteps'
import { CreateAuthenticatorFormValues } from './getAuthenticatorInitialValues'

export const getIsCreateAuthenticatorStepValid = (
  step: AuthenticatorFormSteps,
  formikProps: FormikProps<CreateAuthenticatorFormValues>,
): boolean => {
  const { errors, isValid } = formikProps

  switch (step) {
    case AuthenticatorFormSteps.DETAILS:
      return (
        !errors.accessTokenUrl &&
        !errors.authUrl &&
        !errors.clientId &&
        !errors.clientSecret &&
        !errors.name &&
        !errors.redirectUrl
      )

    case AuthenticatorFormSteps.VARIABLES_MAPPING:
      return isValid

    default:
      return true
  }
}
