import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

import { Tabs, Props as TabsProps } from 'components/ui-kit/tabs/Tabs'

const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        position: 'relative',
        padding: '0 18px',

        '&::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          height: 2,
          bottom: 0,
          backgroundColor: theme.palette.iron.main,
        },
      },
      tabs: {
        position: 'relative',
        zIndex: 1,
      },
    }),
  { name: 'ViewLayoutTabsLeftMenu' },
)

export const ViewLayoutTabsLeftMenu: React.FC<TabsProps> = ({ className, ...rest }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Tabs {...rest} className={clsx(classes.tabs, className)} />
    </div>
  )
}
