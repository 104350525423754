import React from 'react'
import { useFormikContext, FieldArrayRenderProps } from 'formik'
import { Box } from '@mui/material'
import { SubscriptionTopicFormData, OrchestrationData } from 'pages/policyCenter/subscriptions/interfaces'
import { Chip, Icon } from '@ketch-com/deck'

type Props = {
  orchestration: OrchestrationData
  fieldArrayHelpers: FieldArrayRenderProps
  fieldArrayIndex: number
}

export const WebhookOrchestration: React.FC<Props> = ({ fieldArrayHelpers, fieldArrayIndex, orchestration }) => {
  const { isSubmitting } = useFormikContext<SubscriptionTopicFormData>()
  const { remove } = fieldArrayHelpers

  return (
    <Box display="flex" alignItems="center" py={1.5}>
      <Chip
        icon={<Icon name="WApi" />}
        disabled={isSubmitting}
        size="medium"
        label={orchestration.name}
        onDelete={() => remove(fieldArrayIndex)}
        deleteIcon={<Icon name="OCross" />}
      />
    </Box>
  )
}
