import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useLocation, useNavigate } from 'react-router-dom'
import { useProcessingActivitiesSummary } from 'api/processingActivities/queries/useProcessingActivitiesSummary'
import { useInfiniteProcessingActivities } from 'api/processingActivities/queries/useInfiniteProcessingActivities'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useDeleteProcessingActivity } from 'api/processingActivities/mutations/useDeleteProcessingActivity'
import { useEffect, useMemo, useState } from 'react'
import { useProcessingActivityFilters } from 'api/processingActivities/queries/useProcessingActivityFilters'
import { useFilters } from 'utils/hooks/useFiltersV2'

export const useProcessingActivitiesUtils = () => {
  const { isPermitted } = useIsPermitted()
  const navigate = useNavigate()
  const { getParamValueByKey, clearAllSearchParams } = useFilters()
  const { search: searchString } = useLocation()
  const [search, setSearch] = useState('')

  // Get applied filters from query params
  const businessFunctionFilters = getParamValueByKey({ key: 'businessFunction' })
  const teamFilters = getParamValueByKey({ key: 'team' })
  const dataSystemFilters = getParamValueByKey({ key: 'dataSystem' })
  const purposeFilters = getParamValueByKey({ key: 'purpose' })
  const statusFilters = getParamValueByKey({ key: 'status' })
  const typeFilters = getParamValueByKey({ key: 'type' })
  const searchFilter = getParamValueByKey({ key: 'search' })

  const hasFilters = useMemo(() => {
    return !!(
      businessFunctionFilters.length ||
      teamFilters.length ||
      dataSystemFilters.length ||
      purposeFilters.length ||
      statusFilters.length ||
      typeFilters.length ||
      searchFilter.length
    )
  }, [
    businessFunctionFilters,
    dataSystemFilters,
    purposeFilters,
    statusFilters,
    typeFilters,
    searchFilter,
    teamFilters,
  ])

  // Fetch possible filter values
  const { data: filters, isLoading: isFiltersLoading, isFetching: isFiltersFetching } = useProcessingActivityFilters()

  // Fetch data
  const {
    data: summary,
    isLoading: isSummaryLoading,
    refetch: refetchProcessingActivitiesSummary,
  } = useProcessingActivitiesSummary()

  const {
    data: processingActivities,
    isLoading: isProcessingActivitiesLoading,
    isRefetching: isProcessingActivitiesRefetching,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch: refetchProcessingActivities,
  } = useInfiniteProcessingActivities({
    includeMetadata: true,
    customLimit: 15,
    businessFunctionCodes: businessFunctionFilters,
    dataSystemIds: dataSystemFilters,
    purposeCodes: purposeFilters,
    statuses: statusFilters,
    types: typeFilters,
    query: searchFilter.length ? searchFilter[0] : undefined,
  })
  const isLoading = isSummaryLoading || isProcessingActivitiesLoading

  // Delete mutation function
  const { mutate: handleDeleteProcessingActivity } = useDeleteProcessingActivity({
    onSuccess: async () => {
      try {
        await Promise.all([refetchProcessingActivitiesSummary(), refetchProcessingActivities()])
        showToast({ content: 'Processing activity deleted', type: 'success' })
      } catch {
        showToast({ content: 'Failed to reload processing activity', type: 'error' })
      }
    },
    onError: () => {
      showToast({ content: 'Failed to deleted processing activity', type: 'error' })
    },
  })

  // Flags
  const hasProcessingActivities = processingActivities.length > 0
  const canWrite = isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_WRITE)

  // Refetch processing activities whenever one of the filters changes
  useEffect(() => {
    refetchProcessingActivities()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString])

  return {
    navigate,
    summary,
    search,
    setSearch,
    processingActivities,
    refetchProcessingActivities,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    hasFilters,
    hasProcessingActivities,
    handleDeleteProcessingActivity,
    isLoading,
    canWrite,
    filters,
    clearAllSearchParams,
    isFiltersLoading,
    isFiltersFetching,
    isProcessingActivitiesRefetching,
  }
}

export type ProcessingActivitiesUtils = ReturnType<typeof useProcessingActivitiesUtils>
