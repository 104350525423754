import React, { createContext } from 'react'
import { UseDeploymentPlanPreviewUtils, useDeploymentPlanPreviewUtils } from '../hooks'

export const DeploymentPlanPreviewContext = createContext({} as UseDeploymentPlanPreviewUtils)

type Props = {
  children?: React.ReactNode
}

export const DeploymentPlanPreviewContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useDeploymentPlanPreviewUtils()

  return (
    <DeploymentPlanPreviewContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </DeploymentPlanPreviewContext.Provider>
  )
}

export const withDeploymentPlanPreviewContextContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <DeploymentPlanPreviewContextProvider>
        <Component {...props} />
      </DeploymentPlanPreviewContextProvider>
    )
  }
}
