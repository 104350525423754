import React, { useState, useMemo } from 'react'
import { Formik } from 'formik'

import { MaybeNull } from 'interfaces/common'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { WorkflowExecutionStepDetailsDTO } from 'interfaces/workflowExecutions/workflowExecutionStepDetails'
import { Box, Typography } from '@mui/material'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionStepStatusRenderer } from '../WorkflowExecutionStepStatusRenderer'
import { WorkflowExecutionAutomatedStepCompleteFormValues, getValidationSchema } from './utils'
import { Button, PopUp, InfoRow, Spinner } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { FormInput } from 'components/form/FormInput'
import { FormDropZoneWithUpload } from 'components/form/FormDropZone/FormDropZoneWithUpload'

type Props = {
  isReady: boolean
  rightInvocation: RightInvocationDTO
  workflowExecutionStep: WorkflowExecutionStepDTO
  workflowExecutionStepDetails: MaybeNull<WorkflowExecutionStepDetailsDTO>
  filesInfo: FileInfoDTO[]
  onSubmit: (values: WorkflowExecutionAutomatedStepCompleteFormValues) => Promise<void>
  onClose: () => void
}

export const WorkflowExecutionAutomatedStepCompleteModal: React.FC<Props> = ({
  isReady,
  filesInfo,
  rightInvocation,
  workflowExecutionStep,
  workflowExecutionStepDetails,
  onSubmit,
  onClose,
}) => {
  const [isUploading, setIsUploading] = useState(false)

  const activityParams = workflowExecutionStepDetails?.activity?.params
  const hasResolutionNotes = activityParams?.hasResolutionNotes || false
  const hasResolutionAttachments = activityParams?.hasResolutionAttachments || false
  const resolutionDetails =
    workflowExecutionStepDetails?.results?.[workflowExecutionStep.stepID]?.resolutionDetails || ''
  const stepDetailsText = activityParams?.details

  const initialValues: WorkflowExecutionAutomatedStepCompleteFormValues = useMemo(
    () => ({
      resolutionDetails,
      resolutionAttachments: filesInfo,
    }),
    [resolutionDetails, filesInfo],
  )

  const validationSchema = useMemo(
    () =>
      getValidationSchema({
        hasResolutionNotes,
        hasResolutionAttachments,
      }),
    [hasResolutionNotes, hasResolutionAttachments],
  )

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting, isValid }) => (
        <PopUp
          title="Automated Activity"
          variant="modal"
          onClose={onClose}
          popUpWidth="840px"
          popUpTitleStatusComponent={<WorkflowExecutionStepStatusRenderer status={workflowExecutionStep.status} />}
          popUpActionChildren={
            <>
              <Button
                color="secondary"
                size="large"
                pending={isSubmitting}
                disabled={!isValid || isUploading}
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                color="primary"
                size="large"
                onClick={submitForm}
                disabled={!isValid || isUploading}
                pending={isSubmitting}
              >
                Complete Task
              </Button>
            </>
          }
        >
          {!isReady ? (
            <Box display="flex" justifyContent="center">
              <Spinner size="32px" thickness={2.5} />
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" gap={6}>
              <Box display="flex" flexDirection="column" gap={4}>
                <Typography variant="h4">Activity</Typography>
                <InfoRow title="Details">{stepDetailsText || <EmptyValueRenderer />}</InfoRow>
              </Box>
              <Box display="flex" flexDirection="column" gap={4}>
                <Typography variant="h4">Manual Override</Typography>
                <Box>
                  <FormInput
                    required={hasResolutionNotes}
                    fullWidth
                    formPropertyName="resolutionDetails"
                    placeholder="Type here"
                    label="Notes"
                  />
                </Box>
                <Box>
                  <FormDropZoneWithUpload
                    multiple
                    required={hasResolutionAttachments}
                    label="Attachments"
                    name="resolutionAttachments"
                    disabled={isSubmitting}
                    onUploadingStateChange={setIsUploading}
                    uploadContext={{
                      version: 'intervention_attachments',
                      folder: `${rightInvocation.id}_${workflowExecutionStep.stepID}`,
                      bucket: '',
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </PopUp>
      )}
    </Formik>
  )
}
