import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles, createStyles } from '@mui/styles'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { getFilterOptions, getRenderTag } from './utils'
import { Modal } from 'components/ui-kit/modal/Modal'
import { Text } from 'components/ui-kit/typography/Text'
import { usePurposesWidgetEditModalUtils } from './hooks'
import SearchIcon from '@mui/icons-material/Search'
import { AssetPurposeDTO, AssetSummaryDTO, PurposeDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'
import { Chip } from 'components/ui-kit/chip/Chip'

export const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      autocomplete: {
        maxWidth: 300,
      },
      modalClassName: {
        paddingTop: spacing(2),
      },
    }),
  { name: 'PurposesWidgetEditModal' },
)

type Props = {
  isUpdatingAssetLabels?: boolean
  onSubmit?: (purposes: PurposeDTO[]) => void
  onCancel: () => void
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export const PurposesWidgetEditModal: React.FC<Props> = ({
  onSubmit = () => {},
  onCancel,
  isUpdatingAssetLabels = false,
  assetSummary,
}) => {
  const classes = useStyles()

  const {
    handleAutocompleteOnChange,
    handleRemoveTag,
    isLoading,
    isOptionsOpen,
    newPurpose,
    purposeOptions,
    handleLabelInputValueOnChange,
    purposes,
    setIsOptionsOpen,
    setNewPurpose,
  } = usePurposesWidgetEditModalUtils({ assetSummary: assetSummary || undefined })

  return (
    <Modal
      isLoading={isLoading}
      isSaving={isUpdatingAssetLabels}
      title="Purposes"
      subtitle="Search for a purpose, or remove those already assigned."
      contentWidth={825}
      submitBtnLabel="Save"
      className={classes.modalClassName}
      cancelBtnLabel="Cancel"
      onSubmitBtnClick={() => onSubmit([...purposes])}
      onCancelBtnClick={onCancel}
    >
      {/* Autocomplete */}

      <Box
        display="flex"
        alignItems="center"
        sx={{
          my: 2.25,
          pb: 2.25,
          borderBottom: ({ palette }) => `1px solid ${palette.iron.main}`,
        }}
      >
        <Autocomplete
          id="labels"
          multiple
          fullWidth
          className={classes.autocomplete}
          disableClearable
          popupIcon={null}
          onChange={handleAutocompleteOnChange}
          open={isOptionsOpen}
          getOptionLabel={(option: AssetPurposeDTO) => option?.name || ''}
          options={[...purposeOptions]}
          value={[]} // value hardcoded to empty array so selected options do not display in the input
          onClose={() => setNewPurpose('')}
          filterOptions={options => getFilterOptions({ options, newPurpose, purposes })}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Escape') {
              setIsOptionsOpen(false)
              if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
            }
          }}
          renderOption={getRenderTag}
          renderInput={params => (
            <TextField
              {...params}
              onFocus={() => setIsOptionsOpen(true)}
              onBlur={() => setIsOptionsOpen(false)}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                ...params.inputProps,
                onChange: handleLabelInputValueOnChange,
                value: newPurpose,
                placeholder: 'Purpose name',
              }}
            />
          )}
        />
      </Box>

      <Box display="flex" alignItems="center" my={2}>
        <Text size={14} weight={600} mr={0.75}>
          Assigned Purposes
        </Text>

        <Text size={14} weight={600} color="darkDuskFaded">
          {purposes?.length || 0}
        </Text>
      </Box>

      {/* Purposes */}

      <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
        {purposes?.length > 0 ? (
          purposes.map(purpose => (
            <Chip key={purpose?.code} onRemove={() => handleRemoveTag(purpose?.code || '')}>
              <Text size={14}>{purpose?.name}</Text>
            </Chip>
          ))
        ) : (
          <EmptyValueRenderer color="darkDuskFaded" />
        )}
      </Box>
    </Modal>
  )
}
