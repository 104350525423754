import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchUsers } from 'api/users/fetchers/fetchUsers'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.users,
  queryFn: fetchUsers,
  select: res =>
    res?.data?.members
      ?.filter(user => user?.email?.toLowerCase() !== 'support@ketch.com')
      // sort alphabetically per https://ketch-com.atlassian.net/browse/KD-4407
      .sort((a, b) => {
        const nameA = `${a?.firstName}${a?.lastName}${a.email}`.toLowerCase()
        const nameB = `${b?.firstName}${b?.lastName}${b.email}`.toLowerCase()
        return nameA.localeCompare(nameB)
      }) || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery, 'organizationCode'>

export const useUsers = (config?: Config) => {
  const { userData } = useAuth()

  return useCustomQuery({
    ...config,
    params: {
      start: 0,
      limit: 1000,
      ...config?.params,
      organizationCode: userData.organizationCode!,
    },
  })
}
