import React from 'react'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { FormFieldFormDTO } from '../interfaces'
import { getFormFieldInitialValues, mapFormFieldValuesToPayload, useFormFieldValidationSchema } from '../utils'
import { RoutesManager } from 'utils/routing/routesManager'
import { useCreateFormField } from 'api/formFields/mutations/useCreateFormField'
import { useEnabledOrganizationLanguages, useGetEmptyDropdownItem } from '.'
import { useIsEditMode } from 'utils/hooks'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { useUpdateFormField } from 'api/formFields/mutations/useUpdateFormField'
import { useFormField } from 'api/formFields/queries/useFormField'
import { useAppSelector } from 'store/hooks'
import { getNavigationState } from 'store/navigationSlice/selectors'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useCustomFormFieldConfig } from './useCustomFormFieldConfig'
import { createFormFieldSteps } from '../constants/getFormFieldFromSteps'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useQueryClient } from 'react-query'

export const useFormFieldUpsertContainerUtils = () => {
  const navigate = useNavigate()
  const isEditMode = useIsEditMode()
  const queryClient = useQueryClient()
  const { goBack: reduxStoreGoBack } = useAppSelector(getNavigationState)
  const { id: formFieldId } = useParams<{ id?: string }>()
  const customFormConfig = useCustomFormFieldConfig({ isEditMode, createFormFieldSteps })

  // fetch data
  const { enabledLanguages, isLoadingEnabledLanguages } = useEnabledOrganizationLanguages()
  const { data: identitySpaces, isLoading: isLoadingIdentitySpaces } = useIdentitySpaces()
  const { data: formField, isLoading: isLoadingFormField } = useFormField({
    enabled: isEditMode,
    params: {
      formFieldId: formFieldId || '',
      includeMetadata: true,
    },
  })

  // mutations
  const { mutateAsync: handleCreateFormField } = useCreateFormField({
    onSuccess: async () => {
      showToast({ content: 'Form field created', type: 'success' })
      await queryClient.refetchQueries(ApiQueryKeys.formFieldsPaginated)
      navigate(RoutesManager.deployment.forms.fields.root.getURL())
    },
  })

  const { mutateAsync: handleUpdateFormField } = useUpdateFormField({
    onSuccess: async () => {
      showToast({ content: 'Form field updated', type: 'success' })
      await queryClient.refetchQueries(ApiQueryKeys.formFieldsPaginated)
      navigate(RoutesManager.deployment.forms.fields.root.getURL())
    },
  })

  const handleSubmit = async (values: FormFieldFormDTO) => {
    const formData = mapFormFieldValuesToPayload({ values, identitySpaces, formField })

    isEditMode
      ? await handleUpdateFormField({
          params: {
            formData,
          },
        })
      : await handleCreateFormField({
          params: {
            formData,
          },
        })
  }

  // component utils
  const initialValues = React.useMemo(
    () => getFormFieldInitialValues({ formField, languages: enabledLanguages }),
    [enabledLanguages, formField],
  )

  // component utils

  const breadcrumbs = isEditMode
    ? [
        { title: 'Consent & Rights', link: RoutesManager.deployment.applications.root.getURL() },
        { title: 'Form Fields', link: RoutesManager.deployment.forms.fields.root.getURL() },
        {
          title: formField?.name || formField?.title,
          link: RoutesManager.deployment.forms.fields.view.root.getURL({
            id: formField?.id,
          }),
        },
        { title: 'Edit Custom Form Field' },
      ]
    : [
        { title: 'Consent & Rights', link: RoutesManager.deployment.applications.root.getURL() },
        { title: 'Form Fields', link: RoutesManager.deployment.forms.fields.root.getURL() },
        { title: 'Create Custom Form Field' },
      ]
  const isReady = !isLoadingIdentitySpaces && !isLoadingEnabledLanguages && !isLoadingFormField
  const validationSchema = useFormFieldValidationSchema({ isEditMode })
  const targetBackToUrl = reduxStoreGoBack || RoutesManager.deployment.forms.fields.root.getURL()
  const { getDummyDropdownItem } = useGetEmptyDropdownItem()

  const formikProps = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  })

  return {
    breadcrumbs,
    customFormConfig,
    formField,
    formikProps,
    getDummyDropdownItem,
    handleSubmit,
    initialValues,
    isEditMode,
    isReady,
    navigate,
    targetBackToUrl,
    validationSchema,
  }
}
