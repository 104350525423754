import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { SubscriptionControlDTO, GetSubscriptionControlOrchestrationsResponseBodyDTO } from '@ketch-com/figurehead'
import { LegalBaseDTO } from 'interfaces/legalBases/legalBase'
import { RoutesManager } from 'utils/routing/routesManager'
import { SubscriptionControlViewActions } from 'pages/policyCenter/subscriptions/subscriptionControl/view/components'
import { getSortedControlTranslations } from 'pages/policyCenter/subscriptions/utils'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Chip, ContentGroup, InfoRow, Spinner, ViewLayout, EmptyState } from '@ketch-com/deck'
import {
  SubscriptionControlOrchestrationRowType,
  SubscriptionControlOrchestrationsInfoRowContent,
} from './viewSubscriptionControlUtils'
import { FormMode } from 'interfaces/formModes/formMode'

type Props = {
  legalBases: LegalBaseDTO[]
  isReady: boolean
  organizationLanguages: LanguageWithStatusDTO[]
  subscriptionControl: SubscriptionControlDTO
  subscriptionControlOrchestrations: GetSubscriptionControlOrchestrationsResponseBodyDTO
}

export const ViewSubscriptionControl: React.FC<Props> = ({
  subscriptionControl,
  isReady,
  organizationLanguages,
  subscriptionControlOrchestrations,
}) => {
  const navigate = useNavigate()
  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Subscriptions', link: RoutesManager.policyCenter.subscriptions.list.subscriptionControls.root.getURL() },
    { title: subscriptionControl.name },
  ]
  // BE has translations map arranged by langCode instead of langName
  subscriptionControl.translations = getSortedControlTranslations(
    subscriptionControl.translations,
    organizationLanguages,
  )

  const orchestrationsArray: SubscriptionControlOrchestrationRowType[] =
    subscriptionControlOrchestrations && subscriptionControlOrchestrations.orchestrations
      ? Object.values(subscriptionControlOrchestrations.orchestrations).map((elem, index) => {
          return {
            id: (index + 1).toString(),
            subscriptionControlOrchestration: elem,
          }
        })
      : []

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs} />
      <ViewLayout
        contentGroupProps={{
          title: subscriptionControl.name,
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.policyCenter.subscriptions.list.subscriptionControls.root.getURL()),
          },
          infoBlockComponent: (
            <Chip
              size="small"
              label={
                <Typography variant="smallLabel">
                  Code: <Typography variant="smallBody">{subscriptionControl.code}</Typography>
                </Typography>
              }
            />
          ),
          actionRightBlockComponent: <SubscriptionControlViewActions subscriptionControl={subscriptionControl} />,
        }}
      >
        <Box display="flex" flexDirection="column" gap={6}>
          {isReady ? (
            <Box display="flex" flexDirection="column" gap={4}>
              <ContentGroup title="Orchestration" titleVariant="h3" variant="inner-page-header" />
              {orchestrationsArray?.length ? (
                <Box display="flex" flexDirection="column" gap={4}>
                  {orchestrationsArray.map(subscriptionTopic => {
                    return (
                      <InfoRow title="Orchestrated via" titleAddendum={subscriptionTopic.key}>
                        <SubscriptionControlOrchestrationsInfoRowContent
                          controlOrchestration={subscriptionTopic.subscriptionControlOrchestration}
                        />
                      </InfoRow>
                    )
                  })}
                </Box>
              ) : (
                <Box
                  sx={{
                    backgroundColor: ({ palette }) => palette.bone.main,
                    borderRadius: '11px',
                    padding: ({ spacing }) => spacing(8, 0),
                    marginTop: ({ spacing }) => spacing(2),
                  }}
                >
                  <EmptyState
                    iconName="FConnector"
                    title="No configured orchestration"
                    subTitle="Please configure orchestration"
                    primaryButtonTitle="Configure Orchestration"
                    primaryButtonProps={{
                      onClick: () =>
                        navigate(
                          RoutesManager.policyCenter.subscriptions.upsert.subscriptionControl.root.getURL({
                            code: subscriptionControl.code,
                            formMode: FormMode.EDIT,
                          }),
                        ),
                    }}
                  />
                </Box>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '50px 0',
              }}
            >
              <Spinner size="32px" thickness={2.5} />
            </Box>
          )}
        </Box>
      </ViewLayout>
    </>
  )
}
