import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchCustomRightNameList } from '../fetchers/fetchCustomRightNameList'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.customRightNameList,
  queryFn: fetchCustomRightNameList,
  select: res => res?.data?.customRightNames || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useCustomRightNameList = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      start: 0,
      limit: 1000,
      ...config?.params!,
    },
  })
}
