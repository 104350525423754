import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createTheme } from 'api/themes-v3/fetchers/createTheme'

const useCustomMutation = createUseMutation({
  mutationFn: createTheme,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateTheme = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
