import React from 'react'

import { TaskRemovalWarningMessageConfig } from '../interfaces'
import { Banner, Button, DataGrid, PopUp, TableCell } from '@ketch-com/deck'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'

type Props = {
  taskRemovalWarningMessageConfig?: TaskRemovalWarningMessageConfig[]
  onClose: () => void
  confirmRemove: () => void
}

export const TaskRemovalWarningModal: React.FC<Props> = ({
  taskRemovalWarningMessageConfig,
  onClose,
  confirmRemove,
}) => {
  return (
    <PopUp
      popUpWidth="800px"
      isOpen={!!taskRemovalWarningMessageConfig?.length}
      onClose={onClose}
      title="Remove Task"
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onClose}>
            Close
          </Button>
          <Button color="primary" size="large" onClick={() => confirmRemove()}>
            Confirm
          </Button>
        </>
      }
    >
      <Banner
        className="ModalBanner"
        severity="warning"
        sx={{
          // it is affected by the parent dialog, since they share some classnames
          '&.MuiPaper-root': {
            width: 'auto',
          },
        }}
      >
        To remove the Task tile, please remove all corresponding Gateway dependencies.
      </Banner>

      <DataGrid
        sx={{
          '& .MuiDataGrid-virtualScroller': {
            height: !!taskRemovalWarningMessageConfig?.length ? 'auto' : '300px',
          },
        }}
        autosizeOnMount
        columns={dataGridColumns}
        disableChildrenSorting
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        getRowId={row => row.variable.code}
        disableRowHoverStates
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        hideFooter
        rows={taskRemovalWarningMessageConfig || []}
        noRowsOverlayProps={{
          buttonTitle: '',
        }}
      />
    </PopUp>
  )
}

const dataGridColumns: GridColDef<TaskRemovalWarningMessageConfig>[] = [
  {
    align: 'left',
    field: 'variable',
    headerAlign: 'left',
    headerName: 'Variable',
    flex: 1,
    sortable: false,
    renderCell: ({ row: { variable } }: GridRenderCellParams<TaskRemovalWarningMessageConfig>) => {
      return <TableCell title={variable?.name} subTitle={variable?.code} />
    },
  },
  {
    align: 'left',
    field: 'task',
    headerAlign: 'left',
    headerName: 'Task',
    flex: 1,
    sortable: false,
    renderCell: ({ row: { taskDescription } }: GridRenderCellParams<TaskRemovalWarningMessageConfig>) => {
      return <TableCell title={taskDescription} />
    },
  },
  {
    align: 'left',
    field: 'gateway',
    headerAlign: 'left',
    headerName: 'Gateway',
    flex: 1,
    sortable: false,
    renderCell: ({ row: { gateway } }: GridRenderCellParams<TaskRemovalWarningMessageConfig>) => {
      return <TableCell title={gateway.description} />
    },
  },
]
