import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { UserProfileFormDTO } from 'interfaces/users/users'
import { RoutesManager } from 'utils/routing/routesManager'
import { useUserMe } from 'api/users/queries/useUserMe'
import { showToast } from 'components/modals/AlertComponent'
import { useUpdateUserProfile } from 'api/users/mutations/useUpdateUserProfile'
import { getUserProfileFormPayload } from 'pages/settings/userProfile/edit/userDetails/utils/formPayload'
import { EditUserProfileDetailsView } from 'pages/settings/userProfile/edit/userDetails/EditUserProfileDetailsView'

export const EditUserProfileDetailsContainer: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { data: userMe, isLoading: isUserLoading } = useUserMe({
    onError: () => {
      showToast({ content: 'Failed to fetch user', type: 'error' })
      navigate('/')
    },
  })

  const { mutateAsync: handleUpdateUserProfile } = useUpdateUserProfile()

  const onSubmit = async (values: UserProfileFormDTO) => {
    try {
      const formData = getUserProfileFormPayload({ values })

      await handleUpdateUserProfile({
        params: {
          formData,
        },
      })

      await queryClient.refetchQueries(ApiQueryKeys.userMe)
      showToast({ content: 'User profile updated', type: 'success' })
      navigate(RoutesManager.settings.profile.root.getURL())
    } catch (e) {
      showToast({ content: 'Failed to update user profile', type: 'error' })
    }
  }

  return <EditUserProfileDetailsView isReady={!isUserLoading} user={userMe} onSubmit={onSubmit} />
}
