export type NestedErrorObject = {
  [key: string]: NestedErrorObject | string
}

export function concatenateAttributes(obj: NestedErrorObject, parentKey: string = ''): string[] {
  let result: string[] = []

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let currentKey = parentKey ? `${parentKey}.${key}` : key

      if (typeof obj[key] === 'object' && obj[key] !== null) {
        let nestedAttributes = concatenateAttributes(obj[key] as NestedErrorObject, currentKey)
        result = result.concat(nestedAttributes)
      } else {
        result.push(currentKey)
      }
    }
  }

  return result
}
