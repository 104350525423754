import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { SecurityRisksHighlight, SecurityRisksPolicyControl } from './components'

const PREFIX = 'SecurityRisks'

const classes = {
  securityRisksContainer: `${PREFIX}-securityRisksContainer`,
  securityRisksLabel: `${PREFIX}-securityRisksLabel`,
  securityRisksItemsContainer: `${PREFIX}-securityRisksItemsContainer`,
  securityRisksPolicyControlContainer: `${PREFIX}-securityRisksPolicyControlContainer`,
  securityRisksHighlightContainer: `${PREFIX}-securityRisksHighlightContainer`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.securityRisksContainer}`]: {
    margin: spacing(5, 0),
  },

  [`& .${classes.securityRisksLabel}`]: {
    fontSize: 28,
    fontWeight: 800,
    color: 'rgba(7, 26, 36, 0.5)',
  },

  [`& .${classes.securityRisksItemsContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    height: 510,
    marginTop: spacing(2),
  },

  [`& .${classes.securityRisksPolicyControlContainer}`]: {
    flexBasis: 848,
    flexGrow: 0,
    backgroundColor: `${palette.white.main}`,
    borderRadius: spacing(1.5),
  },

  [`& .${classes.securityRisksHighlightContainer}`]: {
    flexBasis: 416,
    flexGrow: 0,
    backgroundColor: `${palette.iron.main}`,
    borderRadius: spacing(1.5),
  },
}))

type Props = {}

//TODO: Complete UI
export const SecurityRisks: React.FC<Props> = () => {
  return (
    <StyledBox className={classes.securityRisksContainer}>
      <Text className={classes.securityRisksLabel}>Security Risks</Text>
      <Box className={classes.securityRisksItemsContainer}>
        <SecurityRisksPolicyControl />
        <SecurityRisksHighlight />
      </Box>
    </StyledBox>
  )
}
