import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, TableCell } from '@ketch-com/deck'
import { AuthenticatorDTO, ContextVariableDTO } from '@ketch-com/figurehead'
import { getVariableTypesString } from './getVariableTypesString'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { getTypesString } from './getTypesString'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { ContextVariableListDropdown } from '../list/ContextVariableListDropdown'

export const useContextVariablesListColumns = (): GridColDef<ContextVariableDTO>[] => [
  {
    align: 'left',
    field: 'contextVariable',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ContextVariableDTO>) => {
      return <TableCell title={row.name} subTitle={row.code} />
    },
  },
  {
    align: 'left',
    field: 'variableType',
    flex: 0.4,
    headerAlign: 'left',
    headerName: 'Variable Type',
    sortable: false,
    renderCell: ({ row: { category } }: GridRenderCellParams<ContextVariableDTO>) => {
      return (
        <TableCell>
          <Chip label={getVariableTypesString(category)} size="small" />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'dataType',
    flex: 0.25,
    headerAlign: 'left',
    headerName: 'Data Type',
    sortable: false,
    renderCell: ({ row: { type } }: GridRenderCellParams<ContextVariableDTO>) => {
      if (type) return <TableCell title={getTypesString(type)} />
      return (
        <TableCell>
          <EmptyValueRenderer />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'created',
    flex: 0.5,
    headerAlign: 'left',
    headerName: 'Created',
    sortable: false,
    renderCell: ({ row: { metadata } }: GridRenderCellParams<ContextVariableDTO>) => {
      return (
        <TableCell>
          {metadata?.createdAt ? <FormattedDateRenderer date={metadata?.createdAt} /> : <EmptyValueRenderer />}
        </TableCell>
      )
    },
  },
  {
    align: 'right',
    field: 'dropDown',
    headerAlign: 'left',
    headerName: '',
    sortable: false,
    maxWidth: 80,
    flex: 1,
    renderCell: ({ row: cellData }: GridRenderCellParams<AuthenticatorDTO>) => {
      return (
        <TableCell>
          <ContextVariableListDropdown contextVariable={cellData} />
        </TableCell>
      )
    },
  },
]
