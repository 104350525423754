import { useState } from 'react'
import { useTrackerCategories } from 'api/trackers/queries/useTrackerCategories'
import { useTrackerConfiguration } from 'api/trackers/queries/useTrackerConfiguration'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { useUpsertTrackerConfiguration } from 'api/trackers/mutations/useUpsertTrackerConfiguration'
import { showToast } from 'components/modals/AlertComponent'

export const useTrackersSettingsUtils = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const { data: trackerCategories, isLoading: isTrackersCategoriesLoading } = useTrackerCategories({})
  const { data: trackerConfiguration, isLoading: isLoadingTrackerConfiguration } = useTrackerConfiguration({
    enabled: currentTab === 1,
  })

  const { mutateAsync: updateTrackerConfiguration, isLoading: isUpdatingTrackerConfiguration } =
    useUpsertTrackerConfiguration({
      onError: () => showToast({ content: 'Failed to update tracker configuration', type: 'error' }),
    })

  const { data: allPurposeList, isLoading: isLoadingAllPurposeList } = usePurposes({})
  const isReady = !isTrackersCategoriesLoading && !isLoadingAllPurposeList

  return {
    isReady,
    trackerCategories,
    isTrackersCategoriesLoading,
    allPurposeList,
    currentTab,
    setCurrentTab,
    trackerConfiguration,
    isLoadingTrackerConfiguration,
    updateTrackerConfiguration,
    isUpdatingTrackerConfiguration,
  }
}
