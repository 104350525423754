import React from 'react'
import clsx from 'clsx'
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'

import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { Text } from 'components/ui-kit/typography/Text'
import { formatCodeFromName } from 'utils/formatters'
import { ButtonColorsType, Button } from '@ketch-com/deck'

type UseStylesProps = {
  removeDialogTitleBorderBottom?: boolean
  contentWidth?: number
}

const useStyles = makeStyles<Theme, UseStylesProps>(
  ({ typography, palette }) =>
    createStyles({
      baseModal: {
        fontFamily: typography.fontFamily,
        borderRadius: 5,
        letterSpacing: '0.01em',
        width: '100%',
        maxWidth: ({ contentWidth }) => contentWidth,
        padding: '18px 24px',
        background: palette.white.main,
      },
      baseModalTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        lineHeight: '28px',
        padding: ({ removeDialogTitleBorderBottom }) => (removeDialogTitleBorderBottom ? '0 0 8px 0' : '0 0 16px 0'),
        borderBottom: ({ removeDialogTitleBorderBottom }) =>
          removeDialogTitleBorderBottom ? undefined : `1px solid ${palette.iron.main}`,
      },
      baseModalCloseIcon: {
        fontSize: '24px',
        color: palette.darkDusk.main,
        cursor: 'pointer',

        '&:hover': {
          opacity: 0.7,
        },
      },
      baseModalContent: {
        padding: '18px 0',
        overflowY: 'auto',
        boxSizing: 'border-box',
      },
      maximumHeight: {
        maxHeight: 400,
      },
      minimumHeight: {
        minHeight: 150,
      },
      noMaximumHeight: {
        maxHeight: 'none',
      },
      noMinimumHeight: {
        minHeight: 'none',
      },
      baseModalContentPending: {
        minHeight: 250,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      baseModalActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderTop: `1px solid ${palette.iron.main}`,
        padding: '18px 0 0 0',
      },

      contentWrapper: {},
    }),
  { name: 'Modal' },
)

export interface Props {
  children?: React.ReactNode
  /** Display loading indicator instead of content and disable buttons when this variable is true */
  isLoading?: boolean
  /** Disable buttons when this variable is true */
  isSaving?: boolean
  /** Disable submit button when this is false */
  isValid?: boolean
  /** Option to disable max height */
  isRemoveMaxHeight?: boolean
  /** Option to disable min height */
  shouldRemoveMinHeight?: boolean
  /** Custom Modal className */
  className?: string
  /** Modal Dialog title */
  title: React.ReactNode
  /** Modal Dialog title */
  subtitle?: React.ReactNode
  /** Max width of modal window until scroll appears */
  contentWidth?: number
  /** Text to be displayed for submit button */
  submitBtnLabel?: string
  /** Text to be displayed for cancel button */
  cancelBtnLabel?: string
  /** Text to be displayed for tertiary button */
  tertiaryBtnLabel?: string
  /** Submit button display type */
  submitBtnVariant?: ButtonColorsType
  /** Cancel button display type */
  cancelBtnVariant?: ButtonColorsType
  /** Tertiary button display type */
  tertiaryBtnVariant?: ButtonColorsType
  /** Submit button action type */
  onSubmitBtnClick?: () => void
  /** Cancel button action type */
  onCancelBtnClick?: () => void
  /** Tertiary button action type */
  onTertiaryBtnClick?: () => void
  /** Close button action type */
  onCloseButtonClick?: () => void
  /** conditionally open modal */
  isOpen?: boolean
  /** data-intercom-target for title */
  titleDataIntercomTarget?: string
  /** Remove dialog title Border Bottom */
  removeDialogTitleBorderBottom?: boolean
}

export const Modal: React.FC<Props> = ({
  isLoading = false,
  isSaving = false,
  isValid = true,
  isRemoveMaxHeight = false,
  shouldRemoveMinHeight = false,
  className,
  children,
  title = '',
  subtitle = '',
  contentWidth = 550,
  submitBtnLabel = 'Save',
  cancelBtnLabel = 'Cancel',
  titleDataIntercomTarget = '',
  tertiaryBtnLabel = '',
  submitBtnVariant = 'primary',
  cancelBtnVariant = 'secondary',
  tertiaryBtnVariant = 'tertiary',
  onCancelBtnClick,
  onSubmitBtnClick,
  onTertiaryBtnClick,
  onCloseButtonClick = onCancelBtnClick,
  isOpen = true,
  removeDialogTitleBorderBottom = false,
}) => {
  const classes = useStyles({ contentWidth, removeDialogTitleBorderBottom })
  const modalClassName = clsx(classes.baseModal, className)

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        classes: {
          root: modalClassName,
        },
      }}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      {isLoading ? (
        <DialogContent className={classes.baseModalContentPending}>
          <Spinner />
        </DialogContent>
      ) : (
        <>
          {subtitle ? (
            <DialogTitle className={classes.baseModalTitle}>
              <Box display="flex" flexDirection="column">
                <Box mb={0.75}>
                  <Typography
                    variant="h3"
                    data-intercom-target={
                      titleDataIntercomTarget || (typeof title === 'string' ? formatCodeFromName(title) : undefined)
                    }
                    component="span"
                  >
                    {title}
                  </Typography>
                </Box>

                <Text size={14} color="grey">
                  {subtitle}
                </Text>
              </Box>

              {onCloseButtonClick && (
                <Box>
                  <CloseIcon onClick={onCloseButtonClick} className={classes.baseModalCloseIcon} />
                </Box>
              )}
            </DialogTitle>
          ) : (
            <DialogTitle className={classes.baseModalTitle}>
              <Typography
                variant="h3"
                data-intercom-target={
                  titleDataIntercomTarget || (typeof title === 'string' ? formatCodeFromName(title) : undefined)
                }
                component="span"
              >
                {title}
              </Typography>

              {onCloseButtonClick && <CloseIcon onClick={onCloseButtonClick} className={classes.baseModalCloseIcon} />}
            </DialogTitle>
          )}

          <DialogContent
            className={clsx(classes.baseModalContent, {
              [classes.noMaximumHeight]: isRemoveMaxHeight,
              [classes.maximumHeight]: !isRemoveMaxHeight,
              [classes.noMinimumHeight]: shouldRemoveMinHeight,
              [classes.minimumHeight]: !shouldRemoveMinHeight,
            })}
          >
            <div className={classes.contentWrapper}>{children}</div>
          </DialogContent>

          <DialogActions className={classes.baseModalActions}>
            {onCancelBtnClick && (
              <Button color={cancelBtnVariant} size="large" pending={isLoading || isSaving} onClick={onCancelBtnClick}>
                {cancelBtnLabel}
              </Button>
            )}

            {onTertiaryBtnClick && (
              <Button
                color={tertiaryBtnVariant}
                size="large"
                pending={isSaving || isLoading}
                disabled={!isValid}
                onClick={onTertiaryBtnClick}
              >
                {tertiaryBtnLabel}
              </Button>
            )}

            {onSubmitBtnClick && (
              <Button
                color={submitBtnVariant}
                size="large"
                pending={isSaving || isLoading}
                disabled={!isValid}
                onClick={onSubmitBtnClick}
              >
                {submitBtnLabel}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
