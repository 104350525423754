import { useRef, useCallback } from 'react'

import { MaybeNull, Unpacked } from 'interfaces/common'

const useGetLatest = <T>(value: T) => {
  const ref = useRef<T>(null!)
  ref.current = value

  return useCallback(() => ref.current, [])
}

export const useAsyncDebounce = <Fn extends (...args: any[]) => any>(fn: Fn, wait = 500) => {
  type Args = Parameters<Fn>
  type Result = Unpacked<ReturnType<Fn>>

  const getLatestFn = useGetLatest(fn)
  const getLatestWait = useGetLatest(wait)

  const debounceRef = useRef<{
    promise: MaybeNull<Promise<Result>>
    resolve: MaybeNull<(value: Result) => void>
    reject: MaybeNull<(reason: any) => void>
    timeout: number
  }>({
    promise: null,
    resolve: null,
    reject: null,
    timeout: 0,
  })

  return useCallback(
    async (...args: Args) => {
      if (!debounceRef.current.promise) {
        debounceRef.current.promise = new Promise<Result>((resolve, reject) => {
          debounceRef.current.resolve = resolve
          debounceRef.current.reject = reject
        })
      }

      if (debounceRef.current.timeout) {
        clearTimeout(debounceRef.current.timeout)
      }

      debounceRef.current.timeout = window.setTimeout(async () => {
        debounceRef.current.timeout = 0

        try {
          debounceRef.current.resolve?.(await getLatestFn()(...args))
        } catch (e) {
          debounceRef.current.reject?.(e)
        } finally {
          debounceRef.current.promise = null
        }
      }, getLatestWait())

      return debounceRef.current.promise
    },
    [getLatestFn, getLatestWait],
  )
}
