import React, { Fragment, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { FieldArray, Formik } from 'formik'
import { compact } from 'lodash'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'

import { formatCodeFromName } from 'utils/formatters'
import { getDecisionBooleanGatewayFormValidationSchema, getDecisionBooleanInitialValues } from './utils'
import { ConfirmRemoveTileModal } from 'pages/orchestration/workflows/edit/components/ConfirmRemoveTileModal'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { DecisionPathTile } from '../DecisionGatewayForm/components'
import { FormInput } from 'components/form/FormInput'
import { Typography } from '@mui/material'

interface IDecisionBooleanGatewayFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const DecisionBooleanGatewayForm: React.FC<IDecisionBooleanGatewayFormProps> = ({
  step,
  steps,
  handleClose,
  handleRemove,
  handleSave,
}) => {
  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))
  const [isReady] = useState(true)
  const [isConfirmRemoveModalOpen, setIsConfirmRemoveModalOpen] = useState<boolean>(false)

  const onSubmit = (values: any) => {
    handleSave({
      step: {
        ...step,
        description: values.description,
        code: values.code,
        valid: true,
        gateway: {
          ...step.gateway,
          transitions: values.transitions,
        } as ICanvasStep['gateway'],
      },
    })
  }

  const stepIndex = useMemo(() => steps.findIndex(({ code }) => code === step.code), [steps, step])

  return (
    <SidebarConfigsWrapper isLoading={!isReady} handleClose={handleClose}>
      <Formik
        initialValues={getDecisionBooleanInitialValues({ step, steps })}
        validationSchema={getDecisionBooleanGatewayFormValidationSchema({ usedCodes })}
        validateOnMount
        onSubmit={onSubmit}
      >
        {form => {
          const { isValid, values, submitForm, setFieldValue } = form

          return (
            <Fragment>
              <SidebarConfigsHeader
                isValid={isValid}
                step={step}
                steps={steps}
                handleSave={() => submitForm()}
                handleRemove={() => {
                  setIsConfirmRemoveModalOpen(true)
                }}
                handleClose={handleClose}
                copyable
              />

              <SidebarConfigsBody
                sx={{
                  px: 3,
                }}
              >
                <Box mb={5}>
                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormInput
                          required
                          formPropertyName="description"
                          fullWidth
                          label="Description Label"
                          placeholder="Enter description"
                          onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
                          shouldUpdateDebounced
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormInput
                        formPropertyName="code"
                        fullWidth
                        label="Code"
                        placeholder="Enter code"
                        required
                        shouldUpdateDebounced
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" alignItems="center" mb={3}>
                  <Typography variant="h3">Decision Paths</Typography>
                </Box>

                <FieldArray
                  name="transitions"
                  render={({ push, remove, insert }) => (
                    <Box>
                      <DragDropContext
                        onDragEnd={(dragData: DropResult) => {
                          const sourceIndex = dragData?.source?.index
                          const sourceObject = values?.transitions?.[sourceIndex]
                          const destinationIndex = dragData?.destination?.index

                          if (
                            sourceIndex === undefined ||
                            destinationIndex === undefined ||
                            sourceObject === undefined
                          ) {
                            return
                          }

                          remove(sourceIndex)
                          insert(destinationIndex, sourceObject)
                        }}
                      >
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <Box {...provided.droppableProps} ref={provided.innerRef}>
                              {values.transitions.map((_: any, index: number) => (
                                <DecisionPathTile
                                  step={step}
                                  steps={steps}
                                  stepIndex={stepIndex}
                                  key={index}
                                  index={index}
                                  remove={remove}
                                  expandable={false}
                                  copyable
                                />
                              ))}
                              {provided.placeholder}
                            </Box>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Box>
                  )}
                />
              </SidebarConfigsBody>
            </Fragment>
          )
        }}
      </Formik>
      {isConfirmRemoveModalOpen && (
        <ConfirmRemoveTileModal
          tileName="Decision Boolean Gateway"
          onSubmit={() => {
            handleRemove({ step })
            setIsConfirmRemoveModalOpen(false)
          }}
          onCancel={() => setIsConfirmRemoveModalOpen(false)}
        />
      )}
    </SidebarConfigsWrapper>
  )
}
