import { useMemo, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useRegister } from 'api/auth/mutations/useRegister'
import { usePurchasePlansOptions } from 'api/auth/queries/usePurchasePlansOptions'
import { useAuthRedirect } from 'utils/hooks/useAuthRedirect'
import { showToast } from 'components/ui-kit/toastr/Toastr'

import { useValidationSchema } from './useValidationSchema'
import { defaultInitialValues } from '../constants'
import { RegistrationFormValues } from 'interfaces/register/register'

import { useCookies } from 'react-cookie'

const getErrorMessageText = (errorMessage: string | undefined): string => {
  if (errorMessage && errorMessage.includes('user already exists'))
    return 'There is already an account associated with this email.  Please login or click "Forgot Password".'
  return 'Oops, something went wrong. Please refresh and try again. If the error persists, please contact customer service at support@ketch.com'
}

export const useRegisterUtils = () => {
  useAuthRedirect()

  const { queries } = useQueryParams()
  const { data: purchasePlanOptions } = usePurchasePlansOptions({})
  const validationSchema = useValidationSchema()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [submissionErrorText, setSubmissionErrorText] = useState('')
  const [cookies] = useCookies(['hubspotutk'])

  const initialValues: RegistrationFormValues = useMemo(
    () => ({
      ...defaultInitialValues,
      plan: queries?.planId || '',
    }),
    [queries?.planId],
  )

  const { mutateAsync: registerNewUser } = useRegister()

  const trimWebsite = (website: string) => {
    if (website.startsWith('http://')) {
      website = website.substring(7)
    } else if (website.startsWith('https://')) {
      website = website.substring(8)
    }
    return website
  }

  const onSubmit = async (values: RegistrationFormValues, setIsSuccessful: any) => {
    /* make sure executeRecaptcha is initiated before calling */
    if (!executeRecaptcha) {
      return
    }
    let recaptchaToken = null
    try {
      recaptchaToken = await executeRecaptcha('signin')
    } catch (error) {
      recaptchaToken = ''
    }

    await registerNewUser({
      params: {
        formData: {
          email: values.email || '',
          orgCode: values.orgCode || '',
          orgName: values.orgCode || '', //values.orgName || '',
          plan: values.plan || 'plan_ketch_free',
          fullName: values.fullName || '',
          website: trimWebsite(values.website) || '',
          privacyPolicy: values.privacyPolicy || '',
          hubspotutk: cookies['hubspotutk'] || '',
          recaptchaToken,
          marketingConsent: values.marketingConsent || false,
        },
      },
      onSuccess: () => {
        setIsSuccessful(true)
      },
      onError: ({ response }) => {
        setSubmissionErrorText(getErrorMessageText(response?.data?.error?.message))
        showToast({ content: submissionErrorText, type: 'error' })
      },
    })
  }

  return {
    executeRecaptcha,

    initialValues,
    onSubmit,
    planName: purchasePlanOptions?.plan?.find(p => p.code === queries?.planId)?.name,
    queries,
    submissionErrorText,
    validationSchema,
  }
}
