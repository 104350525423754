export enum ASSET_TYPE {
  DATABASE = 'Database',
  SCHEMA = 'Schema',
  TABLE = 'Table',
  COLUMN = 'Column',
}

export enum RELATIONSHIP_TYPE {
  CREATED = 'Created',
  DISCOVERED = 'Discovered',
}

export const ASSETS_DROPDOWN_OPTIONS = [
  {
    id: ASSET_TYPE.DATABASE,
    title: ASSET_TYPE.DATABASE,
  },
  {
    id: ASSET_TYPE.SCHEMA,
    title: ASSET_TYPE.SCHEMA,
  },
  {
    id: ASSET_TYPE.TABLE,
    title: ASSET_TYPE.TABLE,
  },
  {
    id: ASSET_TYPE.COLUMN,
    title: ASSET_TYPE.COLUMN,
  },
]

export const RELATIONSHIP_OPTIONS = [
  {
    id: RELATIONSHIP_TYPE.CREATED,
    name: RELATIONSHIP_TYPE.CREATED,
  },
  {
    id: RELATIONSHIP_TYPE.DISCOVERED,
    name: RELATIONSHIP_TYPE.DISCOVERED,
  },
]
