import { ThemeFormDTO } from 'interfaces/themes-v2/theme'
import { ThemeFormValues } from '.'

export const mapUpsertThemeValuesToPayload = ({ values }: { values: ThemeFormValues }): ThemeFormDTO => {
  const {
    name,
    code,

    bannerBackgroundColor,
    bannerButtonColor,
    bannerContentColor,
    bannerPosition,
    bannerSecondaryButtonColor,
    bannerForceInteraction,

    buttonBorderRadius,

    formButtonColor,
    formContentColor,
    formHeaderBackgroundColor,
    formHeaderContentColor,
    formSwitchOffColor,
    formSwitchOnColor,

    modalButtonColor,
    modalContentColor,
    modalHeaderBackgroundColor,
    modalHeaderContentColor,
    modalPosition,
    modalSwitchOffColor,
    modalSwitchOnColor,

    /* TODO:BAC - QR colors not yet implemented, but coming soon */
    // qrBackgroundColor,
    // qrForegroundColor,

    showWatermark,

    bannerSecondaryButtonVariant,
    purposeButtonsLookIdentical,
    purposeFormButtonsLookIdentical,
    formBodyBackgroundColor,
    modalBodyBackgroundColor,
  } = values

  const payload = {
    name,
    code,

    bannerBackgroundColor,
    bannerButtonColor,
    bannerPosition,
    ...(bannerContentColor && { bannerContentColor }),
    ...(bannerSecondaryButtonColor && { bannerSecondaryButtonColor }),
    bannerForceInteraction: !!bannerForceInteraction,

    buttonBorderRadius: Number(buttonBorderRadius),

    formButtonColor,
    formContentColor,
    formHeaderBackgroundColor,
    ...(formHeaderContentColor && { formHeaderContentColor }),
    ...(formSwitchOffColor && { formSwitchOffColor }),
    ...(formSwitchOnColor && { formSwitchOnColor }),

    modalButtonColor,
    modalContentColor,
    modalHeaderBackgroundColor,
    modalPosition,
    ...(modalHeaderContentColor && { modalHeaderContentColor }),
    ...(modalSwitchOnColor && { modalSwitchOnColor }),
    ...(modalSwitchOffColor && { modalSwitchOffColor }),

    /* TODO:BAC - QR colors not yet implemented, but coming soon */
    // qrBackgroundColor,
    // qrForegroundColor,

    showWatermark: !!showWatermark,
    bannerSecondaryButtonVariant,
    purposeButtonsLookIdentical,
    purposeFormButtonsLookIdentical,
    formBodyBackgroundColor,
    modalBodyBackgroundColor,
  }

  return payload
}
