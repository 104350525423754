import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useCookie } from 'api/cookies/queries/useCookie'
import { useDeleteCookie } from 'api/cookies/mutations/useDeleteCookie'

import { showToast } from 'components/ui-kit/toastr/Toastr'
import { Button, PopUp } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  code: string
  onSubmit?: () => void
  onCancel: () => void
  isOpen: boolean
}

export const DeleteCookieModal: React.FC<Props> = props => {
  const queryClient = useQueryClient()
  const { code, onSubmit, onCancel, isOpen } = props
  const [isBusy, setIsBusy] = useState(false)

  const { data: cookie, isLoading } = useCookie({
    enabled: !!code,
    params: {
      cookieCode: code!,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch cookie', type: 'error' })
      onCancel()
    },
  })

  const { mutate: handleDeleteCookie } = useDeleteCookie({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'Cookie deleted', type: 'success' })

      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to delete cookie', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <PopUp
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isBusy} onClick={onCancel}>
            Close
          </Button>
          <Button
            color="primary"
            size="large"
            pending={isBusy}
            onClick={() => {
              handleDeleteCookie({
                params: {
                  cookieCode: code!,
                },
              })
            }}
          >
            Delete
          </Button>
        </>
      }
      variant="dialog"
      title="Delete Cookie"
    >
      <Typography>
        Are you sure you want to delete <strong>{cookie?.name || cookie?.code}</strong> cookie?
      </Typography>
    </PopUp>
  )
}
