import { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Chip } from '@mui/material'
import clsx from 'clsx'
import { useNavigate, useMatch } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Text } from 'components/ui-kit/typography/Text'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { SegmentTabSortOption } from '../../components'
import { getURLParams } from 'utils/routing/routes/utils'

const PREFIX = 'DataRiskDetailMenu'

const classes = {
  menuContainer: `${PREFIX}-menuContainer`,
  container: `${PREFIX}-container`,
  searchInput: `${PREFIX}-searchInput`,
  chevronRightIcon: `${PREFIX}-chevronRightIcon`,
  unutilizedDataListItem: `${PREFIX}-unutilizedDataListItem`,
  tabClassName: `${PREFIX}-tabClassName`,
  lowDataRiskScoreChip: `${PREFIX}-lowDataRiskScoreChip`,
  mediumDataRiskScoreChip: `${PREFIX}-mediumDataRiskScoreChip`,
  highDataRiskScoreChip: `${PREFIX}-highDataRiskScoreChip`,
  extremeDataRiskScoreChip: `${PREFIX}-extremeDataRiskScoreChip`,
}

const StyledBox = styled(Box)(({ theme: { spacing, palette } }) => ({
  [`&.${classes.menuContainer}`]: {
    flexBasis: '28.25%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: spacing(1.375),
  },

  [`& .${classes.container}`]: {
    flex: 1,
    maxWidth: 358,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: spacing(1.375),
    backgroundColor: palette.white.main,
    maxHeight: 732,
    padding: spacing(2.5),
    overflowY: 'auto',
  },

  [`& .${classes.searchInput}`]: {
    border: `1px solid ${palette.white.main}`,
    borderBottom: `1px solid ${palette.iron.main}`,
    borderRadius: 0,
  },

  [`& .${classes.chevronRightIcon}`]: {
    color: palette.fadedDarkGrey.main,
    height: 16,
    '&[data-active="active"]': {
      color: palette.darkDusk.main,
    },
  },

  [`& .${classes.unutilizedDataListItem}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(1.25, 0.75, 1.25, 1.25),
    borderRadius: spacing(1.375),
    '&:hover': {
      backgroundColor: palette.sphereFaded.main,
      cursor: 'pointer',
    },
    '&[data-active="active"]': {
      backgroundColor: palette.sphereFaded.main,
      cursor: 'unset',
    },
  },

  [`& .${classes.tabClassName}`]: {
    padding: spacing(0.75, 1.5),
    minHeight: 27,
  },

  [`& .${classes.lowDataRiskScoreChip}`]: {
    color: palette.riskScoreLowText.main,
    backgroundColor: palette.riskScoreLowBg.main,
  },

  [`& .${classes.mediumDataRiskScoreChip}`]: {
    color: palette.riskScoreMediumText.main,
    backgroundColor: palette.riskScoreMediumBg.main,
  },

  [`& .${classes.highDataRiskScoreChip}`]: {
    color: palette.riskScoreHighText.main,
    backgroundColor: palette.riskScoreHighBg.main,
  },

  [`& .${classes.extremeDataRiskScoreChip}`]: {
    color: palette.riskScoreExtremeText.main,
    backgroundColor: palette.riskScoreExtremeBg.main,
  },
}))

export enum TabSortType {
  ASC = 'asc',
  DEC = 'dec',
}

type MenuItem = {
  icon: JSX.Element
  text: string
  score: number
  id: string
}

type Props = {
  items: MenuItem[]
  route: { getURL: (routeInfo: getURLParams) => string; path: string }
}

export const DataRiskDetailMenu: React.FC<Props> = ({ items, route }) => {
  const [sort, setSort] = useState<TabSortType>(TabSortType.ASC)
  const [searchString, setSearchString] = useState('')
  const navigate = useNavigate()
  const match = useMatch(route.path)
  const assetId = match?.params?.code || ''

  const visibleRows = items
    .filter(item => item.text.toLowerCase().includes(searchString))
    .sort((itemA, itemB) => {
      if (sort === TabSortType.DEC) {
        return itemA.score > itemB.score ? 1 : -1
      }

      return itemA.score < itemB.score ? 1 : -1
    })

  const tabFilterOptions = [
    {
      id: TabSortType.ASC,
      name: <SegmentTabSortOption type={TabSortType.ASC} isActive={TabSortType.ASC === sort} />,
    },
    {
      id: TabSortType.DEC,
      name: <SegmentTabSortOption type={TabSortType.DEC} isActive={TabSortType.DEC === sort} />,
    },
  ]

  return (
    <StyledBox className={classes.menuContainer}>
      <Box className={classes.container}>
        <Box display="flex" alignItems="center" mb={3}>
          <Box display="flex" alignItems="center" mr={1.5}>
            <SearchInput
              size="small"
              fullWidth
              className={classes.searchInput}
              onChange={nextSearchString => setSearchString(nextSearchString)}
              onClear={() => setSearchString('')}
              value={searchString}
            />
          </Box>

          <SegmentTabs
            tabClassName={classes.tabClassName}
            value={sort}
            tabs={tabFilterOptions}
            onChange={sort => setSort(sort)}
          />
        </Box>

        {visibleRows.map((item: MenuItem, index) => {
          const isActive = item?.id === assetId
          const dataActive = isActive ? 'active' : ''
          return (
            <Box
              key={item.id}
              data-active={dataActive}
              data-testid={`data-risks-row-${index}`}
              onClick={() => {
                const targetPath = route.getURL({
                  code: item?.id,
                })
                navigate(targetPath)
              }}
              className={classes.unutilizedDataListItem}
            >
              {/* Name */}
              <Box display="flex" alignItems="center">
                {item.icon}
                <Text
                  size={14}
                  lineHeight={2.5}
                  component="div"
                  sx={{ width: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {item?.text}
                </Text>
              </Box>
              {/* Asset Count */}
              <Box display="flex" alignItems="center" justifyContent="flex-end" width={64}>
                <Chip
                  className={clsx(classes.lowDataRiskScoreChip, {
                    [classes.mediumDataRiskScoreChip]: (item.score || 0) > 30,
                    [classes.highDataRiskScoreChip]: (item.score || 0) > 60,
                    [classes.extremeDataRiskScoreChip]: (item.score || 0) > 80,
                  })}
                  label={item.score}
                  size="small"
                  clickable={false}
                />
                <ChevronRightIcon className={classes.chevronRightIcon} data-active={dataActive} />
              </Box>
            </Box>
          )
        })}
      </Box>
    </StyledBox>
  )
}
