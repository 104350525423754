import React from 'react'

import { DropListButton, DropListButtonProps } from '@ketch-com/deck'
import { useTranslation } from 'react-i18next'
import { COMMON } from 'i18n/types'
import { DATA_ROLE_OPTIONS, DataRoleType } from 'interfaces/events'
import { MenuItem } from './MenuItem'

interface Props
  extends Omit<
    DropListButtonProps<{ label: string; value: string }, boolean, boolean, boolean>,
    'onChange' | 'options'
  > {
  onChange: (value?: DataRoleType) => void
}

export const DataRoleDropList: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation([COMMON])
  const options =
    DATA_ROLE_OPTIONS.map(item => ({
      label: item.name || item.id,
      value: item.id,
    })) || []

  return (
    <DropListButton
      renderOption={(props, option, { selected }) => <MenuItem {...props} option={option} selected={selected} />}
      onChange={(_, item) => onChange(item?.value)}
      options={options}
      placeholder={t('All Data Roles')!}
      size="small"
    />
  )
}
