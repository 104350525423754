import React from 'react'
import useImage from 'use-image'
import { Group, Image, Rect } from 'react-konva'
import { includes, isUndefined } from 'lodash'

import { ObjectLiteral } from 'interfaces'
import { WorkflowActivityCode, WorkflowActivityConnectorCode } from 'interfaces/workflowActivities/workflowActivity'
import { IAbsoluteCoordinates, ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'

interface ICanvasImageProps {
  step: ICanvasStep
  position: IAbsoluteCoordinates
  useShadow?: boolean
}

export const CanvasStepImage: React.FC<ICanvasImageProps> = props => {
  const { step, position, useShadow } = props
  const shadowProps = useShadow
    ? {
        shadowColor: '#212121',
        shadowBlur: 8,
        shadowOffset: { x: 0, y: 0 },
        shadowOpacity: 0.5,
      }
    : {}

  const [image] = useImage(step.iconURL!)
  const isGateway = !isUndefined(step?.gateway)
  const isActivity = !isUndefined(step?.activity) || !isUndefined(step?.workflow)
  const isApp = isActivity && !includes(Object.values(WorkflowActivityCode), step.activity?.code)
  const isDsrTransponder =
    step.activityCode === WorkflowActivityCode.DSR_TRANSPONDER || step.iconCode === WorkflowActivityCode.DSR_TRANSPONDER
  const isInternalSystemDSR =
    step.activityCode === WorkflowActivityCode.INTERNAL_SYSTEM_DSR ||
    step.iconCode === WorkflowActivityCode.INTERNAL_SYSTEM_DSR
  const isImportContext =
    step.activityCode === WorkflowActivityCode.IMPORT_CONTEXT || step.iconCode === WorkflowActivityCode.IMPORT_CONTEXT

  const stylesProps: ObjectLiteral = {
    cornerRadius: 14,
  }

  if (isGateway) {
    stylesProps.fill = '#222222'
  }

  if (isActivity && !isApp) {
    stylesProps.fill = '#9C64FF'
  }

  if (isApp) {
    stylesProps.fill = '#FFF'
  }

  if (isDsrTransponder) {
    stylesProps.fill = '#9C64FF'
  }

  if (isInternalSystemDSR) {
    stylesProps.fill = '#9C64FF'
  }

  if (
    step.workflow?.temporalFunctionName === WorkflowActivityConnectorCode.API_CALL_V2 ||
    step.workflow?.temporalFunctionName === WorkflowActivityConnectorCode.DATA_REVIEW
  ) {
    stylesProps.fill = '#9C64FF'
  }

  if (isImportContext) {
    stylesProps.fill = '#9C64FF'
  }

  return (
    <Group x={position.x} y={position.y}>
      <Rect width={64} height={64} {...stylesProps} {...shadowProps} />
      <Image x={10} y={10} image={image} width={44} height={44} />
    </Group>
  )
}
