import React, { useState } from 'react'
import clsx from 'clsx'
import { SxProps, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import { CircularProgress } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { isEmpty, debounce } from 'lodash'
import EastIcon from '@mui/icons-material/East'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

import { Hint } from 'components/ui-kit/form/common/hint/Hint'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { Input, Props as BaseInputProps } from 'components/ui-kit/input/Input'
import { theme } from '@ketch-com/deck'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseSearchInput: {
        display: 'inline-block',
      },
      baseSearchInputStretch: {
        display: 'block',
        width: '100%',
      },
      baseInputWrapper: {
        position: 'relative',
      },

      // Icon:
      withSmallLeftIcon: {
        paddingLeft: 38,
      },
      withRegularLeftIcon: {
        paddingLeft: '42px !important',
      },
      withSmallRightIcon: {
        paddingRight: 38,
      },
      withRegularRightIcon: {
        paddingRight: 38,
      },

      // Icon Sizes:
      iconSmall: {
        fontSize: typography.pxToRem(18),
      },
      iconRegular: {
        fontSize: typography.pxToRem(24),
      },

      icon: {
        position: 'absolute',
        zIndex: 1,
        color: palette.darkDuskFaded.main,
      },

      iconLeft: {
        '&$iconSmall': {
          top: 6,
          left: 12,
        },

        '&$iconRegular': {
          top: 10,
          left: 14,
        },
      },

      iconRight: {
        cursor: 'pointer',

        '&:hover': {
          opacity: 0.8,
        },

        '&$iconSmall': {
          top: 6,
          right: 10,
        },

        '&$iconRegular': {
          top: 10,
          right: 14,
        },
      },

      iconFocused: {
        color: palette.sphere.main,
      },

      withBottomBorderOnly: {
        '&.MuiInputBase-root': {
          border: 'none',
          borderBottom: `1px solid ${theme.palette.iron.main}`,
          borderRadius: 0,
        },

        '&:not(.Mui-disabled)': {
          '&:hover': {
            border: `none !important`,
            borderBottom: `1px solid ${theme.palette.iron.main} !important`,
          },

          '&.Mui-focused': {
            border: `none !important`,
            borderBottom: `1px solid ${theme.palette.sphere.main} !important`,
          },
        },
      },
    }),
  { name: 'SearchInput' },
)

export interface Props extends Omit<BaseInputProps, 'onChange'> {
  /** Stretch input to fullWidth */
  fullWidth?: boolean
  /** Display loading indicator for input */
  isSearchPending?: boolean
  /** Text to be displayed below input */
  hint?: string
  /** Text to be displayed above input */
  label?: string
  /** Input placeholder */
  placeholder?: string
  /** Amount of symbols to be typed before search starts */
  startSearchFromChar?: number
  /** Amount of milliseconds passed after last call to onSearch */
  debounceTimeout?: number
  /** Method triggered when user types */
  onChange: (value: string) => void
  /** Method triggered when search happens */
  onSearch?: (value: string) => Promise<void> | void
  /** Method triggered when cross is pressed */
  onClear?: () => void
  /** Icon to be displayed on the left side of input */
  isFocusedClassName?: string
  /** Icon */
  iconType?: string
  /** Sx */
  sx?: SxProps<Theme>
  /** Only include a bottom border */
  onlyBottomBorder?: boolean
}

export const SearchInput: React.FC<Props> = ({
  isSearchPending = false,
  fullWidth = false,
  name,
  hint = '',
  label = '',
  placeholder = 'Search...',
  startSearchFromChar = 3,
  debounceTimeout = 500,
  isFocusedClassName,
  iconType = 'search',
  sx,
  onlyBottomBorder = false,
  ...baseInputProps
}) => {
  const classes = useStyles()
  const searchDebounced = baseInputProps.onSearch
    ? debounce(baseInputProps.onSearch, debounceTimeout)
    : debounce(() => {}, debounceTimeout)
  const hasValue = !isEmpty(baseInputProps.value)
  const showClearIcon = hasValue && !!baseInputProps.onClear && !isSearchPending
  const [isFocused, setIsFocused] = useState(baseInputProps.autofocus)
  const inputClassName = clsx(baseInputProps.className, isFocused ? isFocusedClassName : undefined, {
    [classes.withSmallLeftIcon]: baseInputProps.size === 'small',
    [classes.withRegularLeftIcon]: !baseInputProps.size || baseInputProps.size === 'regular',
    [classes.withSmallRightIcon]: baseInputProps.size === 'small',
    [classes.withRegularRightIcon]: !baseInputProps.size || baseInputProps.size === 'regular',
    [classes.baseSearchInputStretch]: fullWidth,
    [classes.withBottomBorderOnly]: onlyBottomBorder,
  })
  const iconLeftClassName = clsx(classes.icon, classes.iconLeft, {
    [classes.iconFocused]: isFocused,
    [classes.iconSmall]: baseInputProps.size === 'small',
    [classes.iconRegular]: !baseInputProps.size || baseInputProps.size === 'regular',
  })
  const iconRightClassName = clsx(classes.icon, classes.iconRight, {
    [classes.iconFocused]: isFocused,
    [classes.iconSmall]: baseInputProps.size === 'small',
    [classes.iconRegular]: !baseInputProps.size || baseInputProps.size === 'regular',
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = e.target.value

    searchDebounced.cancel()

    if (inputValue.length >= startSearchFromChar) {
      searchDebounced(inputValue)
    }

    baseInputProps.onChange(inputValue)
  }

  const handleClear = () => {
    baseInputProps.onChange('')
    baseInputProps.onClear && baseInputProps.onClear()
  }

  return (
    <Box
      className={clsx(classes.baseSearchInput, {
        [classes.baseSearchInputStretch]: fullWidth,
      })}
      sx={sx}
    >
      {label && (
        <Label size={baseInputProps.size} id={baseInputProps.id}>
          {label}
        </Label>
      )}

      <div className={classes.baseInputWrapper}>
        {iconType === 'search' ? (
          <SearchIcon className={iconLeftClassName} />
        ) : (
          <EastIcon className={iconLeftClassName} />
        )}

        <Input
          {...baseInputProps}
          name={name}
          className={inputClassName}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />

        {isSearchPending && (
          <CircularProgress
            classes={{
              root: iconRightClassName,
            }}
            size={16}
          />
        )}

        {showClearIcon && <ClearIcon className={iconRightClassName} onClick={handleClear} />}
      </div>

      {hint && <Hint>{hint}</Hint>}
    </Box>
  )
}
