import { Chip, Icon } from '@ketch-com/deck'
import { useContextVariable } from 'api/contextVariables/queries/useContextVariable'
import { ViewContextVariablesModal } from 'pages/settings/contextVariables/components'
import React, { useState } from 'react'

type Props = { code: string }

export const ContextVariableChip: React.FC<Props> = ({ code }) => {
  const { data: contextVariable, isLoading } = useContextVariable({ params: { code } })
  const [showModal, setShowModal] = useState(false)

  if (isLoading) return null

  return (
    <>
      <Chip
        clickable
        label={contextVariable.name}
        onClick={() => setShowModal(true)}
        deleteIcon={<Icon name="OArrowCRight" />}
        onDelete={() => setShowModal(true)}
      />
      {showModal ? (
        <ViewContextVariablesModal contextVariable={contextVariable} onCancel={() => setShowModal(false)} />
      ) : null}
    </>
  )
}
