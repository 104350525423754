import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { useTheme } from 'api/themes/queries/useTheme'
import { useDeleteTheme } from 'api/themes/mutations/useDeleteTheme'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { Text } from 'components/ui-kit/typography/Text'
import { showToast } from 'components/ui-kit/toastr/Toastr'

type Props = {
  themeCode: string
  onSubmit?: () => void
  onCancel: () => void
}

export const DeleteThemeModal: React.FC<Props> = ({ themeCode, onSubmit, onCancel }) => {
  const queryClient = useQueryClient()
  const [isBusy, setIsBusy] = useState(false)

  const { data: theme, isLoading } = useTheme({
    enabled: !!themeCode,
    params: {
      themeCode,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch theme', type: 'error' })
      onCancel()
    },
  })

  const { mutate: handleDeleteTheme } = useDeleteTheme({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'Theme deleted', type: 'success' })
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to delete theme', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <Dialog
      isLoading={isLoading}
      isSaving={isBusy}
      title="Delete Theme"
      contentWidth={550}
      submitBtnLabel="Delete"
      cancelBtnLabel="Close"
      onSubmitBtnClick={() => {
        handleDeleteTheme({
          params: {
            themeCode,
          },
        })
      }}
      onCancelBtnClick={onCancel}
    >
      <Text size={14}>
        Are you sure you want to delete <strong>{theme?.name || theme?.code}</strong> theme?
      </Text>
    </Dialog>
  )
}
