import Grid from '@mui/material/Grid'
import { Formik } from 'formik'
import { compact } from 'lodash'
import Box from '@mui/material/Box'

import { ICanvasStep, ISetPermitsActivityParams } from 'pages/orchestration/workflows/edit/interfaces'

import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'

import { formatCodeFromName } from 'utils/formatters'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { getSetPermitsInitialValues } from 'pages/orchestration/workflows/edit/utils/forms/activities/setPermits/getSetPermitsInitialValues'
import { getSetPermitsValidationSchema } from 'pages/orchestration/workflows/edit/utils/forms/activities/setPermits/getSetPermitsValidationSchema'
import { useMemo } from 'react'
import { FormInput } from 'components/form/FormInput'
import { DataGrid, FormRow, TableCell, theme } from '@ketch-com/deck'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { FormError } from 'components/form/FormError'

interface ISetPermitsFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const SetPermitsForm: React.FC<ISetPermitsFormProps> = ({
  step,
  steps,
  handleClose,
  handleRemove,
  handleSave,
}) => {
  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))
  const { data: purposes, isLoading: isPurposesListLoading } = usePurposes({})

  const onSubmit = (values: ISetPermitsActivityParams) => {
    const stepPayload = {
      ...step,
      valid: true,
      code: values.code,
      description: values.description,
      activity: {
        ...step.activity,
        params: {
          permitDetails: (values?.permitDetails || [])?.map(permit => {
            const [entry] = Object.entries(permit)
            const targetPurposeId = entry?.[0]
            const targetPurpose = purposes?.find(purpose => purpose.ID === targetPurposeId)
            return Object.fromEntries([[targetPurpose?.code || '', Number(entry?.[1])]])
          }),
        },
      } as ICanvasStep['activity'],
    }

    handleSave({
      step: stepPayload,
    })
  }

  const memoizedInitialValues = useMemo(() => getSetPermitsInitialValues({ step, purposes }), [step, purposes])

  const purposesWithIndex = purposes.map((purpose, index) => ({ ...purpose, index }))

  return (
    <SidebarConfigsWrapper isLoading={isPurposesListLoading} handleClose={handleClose}>
      <Formik
        validateOnMount
        initialValues={memoizedInitialValues}
        validationSchema={getSetPermitsValidationSchema({ usedCodes })}
        onSubmit={onSubmit}
      >
        {({ isValid, submitForm, setFieldValue, errors, touched }) => {
          return (
            <>
              <SidebarConfigsHeader
                isValid={isValid}
                steps={steps}
                step={step}
                handleSave={() => submitForm()}
                handleRemove={() => handleRemove({ step })}
                handleClose={handleClose}
                copyable
              />

              <SidebarConfigsBody sx={{ px: 3 }}>
                <Box pb={3}>
                  <Box marginBottom={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormInput
                          formPropertyName="description"
                          fullWidth
                          required
                          label="Description Label"
                          placeholder="Enter description"
                          onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
                          shouldUpdateDebounced
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <FormInput
                        formPropertyName="code"
                        fullWidth
                        label="Code"
                        placeholder="Enter code"
                        required
                        shouldUpdateDebounced
                      />
                    </Grid>
                  </Grid>
                </Box>

                <FormRow
                  title="Permit Details"
                  subTitle="Select how the permits should be set for each of the below purposes."
                >
                  {errors.permitDetails && touched.permitDetails && <FormError msg={errors.permitDetails} />}
                  <DataGrid
                    sx={{
                      borderColor:
                        errors.permitDetails && touched.permitDetails ? theme.palette.chileanFire.main : undefined,
                      marginBlockStart:
                        errors.permitDetails && touched.permitDetails ? theme.spacing(1 / 2) : undefined,
                      '& .MuiDataGrid-virtualScroller': {
                        height: !!purposesWithIndex?.length ? 'auto' : '300px',
                      },
                    }}
                    autosizeOnMount
                    columns={dataGridColumns}
                    disableChildrenSorting
                    disableColumnMenu
                    disableColumnPinning
                    disableColumnReorder
                    disableColumnResize
                    getRowId={row => row.ID}
                    disableRowHoverStates
                    disableRowSelectionOnClick
                    getRowHeight={() => 'auto'}
                    hideFooter
                    rows={purposesWithIndex}
                    noRowsOverlayProps={{
                      buttonTitle: '',
                    }}
                  />
                </FormRow>
              </SidebarConfigsBody>
            </>
          )
        }}
      </Formik>
    </SidebarConfigsWrapper>
  )
}

type PurposeDTOWithIndex = { index: number } & PurposeDTO
const dataGridColumns: GridColDef<PurposeDTOWithIndex>[] = [
  {
    align: 'left',
    field: 'productArea',
    headerAlign: 'left',
    headerName: 'Product Area',
    flex: 1,
    sortable: false,
    renderCell: ({ row: { name } }: GridRenderCellParams<PurposeDTOWithIndex>) => {
      return <TableCell title={name} />
    },
  },
  {
    align: 'left',
    field: 'itemName',
    headerAlign: 'left',
    headerName: 'Used By',
    flex: 1,
    sortable: false,
    renderCell: ({ row: { ID, index } }: GridRenderCellParams<PurposeDTOWithIndex>) => {
      return (
        <TableCell>
          <FormDroplistButton
            disableClearable
            required
            fullWidth
            size="small"
            formPropertyName={`permitDetails.${index}.${ID}`}
            placeholder="Type"
            valueKey="id"
            checkSelectedValueAsString
            items={[
              {
                id: '1',
                name: 'Do Nothing',
              },
              {
                id: '2',
                name: 'Opt Out',
              },
              {
                id: '3',
                name: 'Opt In',
              },
            ]}
          />
        </TableCell>
      )
    },
  },
]
