import { Box, Typography, styled } from '@mui/material'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { useContext } from 'react'
import {
  BUILDER_CONSTANTS,
  PreferenceExperienceBuilderSection,
} from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { getIn } from 'formik'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { ItemStyle } from '@ketch-sdk/ketch-types'
import { SelectableSection } from '../../../../../../SelectableSection'
import { SectionEmptyState } from '../../../../../../SectionEmptyState'
import { clamp } from 'lodash'

const FooterBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
})

const ButtonBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '81px',
  minHeight: '44px',
  maxWidth: '530px',
  padding: '12px 24px',
})

export const RequestsSubmittedFooter: React.FC = () => {
  const { themeConfig, experienceConfig, isTheme } = useContext(BuilderContext)

  // Theme config
  const actionButtonStyle = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabSubmittedFooterActionButtonStyle,
  )
  const actionButtonBackgroundColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabSubmittedFooterActionButtonBackgroundColor,
  )
  const actionButtonOutlineColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabSubmittedFooterActionButtonOutlineColor,
  )
  const actionButtonTextColor = getIn(
    themeConfig,
    themeUpsertFormKeys.preferenceRequestsTabSubmittedFooterActionButtonTextColor,
  )
  const isActionButtonFilledStyle = actionButtonStyle === ItemStyle.Filled

  const actionButtonCornerRadius =
    clamp(
      getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabSubmittedFooterActionButtonCornerRadius),
      BUILDER_CONSTANTS.MIN_BORER_RADIUS,
      BUILDER_CONSTANTS.MAX_BORDER_RADIUS,
    ) + 'px'

  // Experience config
  const actionButtonVisible = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceRequestsTabSubmittedActionButtonVisible,
  )
  const actionButtonText = getIn(
    experienceConfig,
    experienceUpsertFormKeys.preferenceRequestsTabSubmittedActionButtonText,
  )

  return (
    <FooterBox>
      <SelectableSection
        section={isTheme ? undefined : PreferenceExperienceBuilderSection.RequestsSubmittedActionButton}
        overlayProps={{ margin: '5px' }}
      >
        {actionButtonVisible ? (
          <ButtonBox
            sx={{
              ...(isActionButtonFilledStyle
                ? { background: actionButtonBackgroundColor }
                : { border: `1.5px solid ${actionButtonOutlineColor}` }),
              borderRadius: actionButtonCornerRadius,
            }}
          >
            <Typography color={actionButtonTextColor} fontSize="14px" fontWeight={600} sx={{ wordWrap: 'break-word' }}>
              {actionButtonText}
            </Typography>
          </ButtonBox>
        ) : (
          <SectionEmptyState section="Button" compact />
        )}
      </SelectableSection>
    </FooterBox>
  )
}
