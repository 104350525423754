import React from 'react'

// api
import { useDataSubjectTypes } from 'api/dataSubjectTypes/queries/useDataSubjectTypes'
import { Pagination } from 'api/common/paginatedQuery'

// dux
import { getEventsListFilterState } from 'store/eventsListFilterSlice/selectors'
import { useAppSelector } from 'store/hooks'

// components
import Box from '@mui/material/Box'
import { EventsListEmpty } from './EventsListEmpty'
import { EventsListTable } from './EventsListTable'
import { EventsByRightCode } from './EventsByRightCode'

// types
import { EventV2DTO } from '@ketch-com/figurehead'
import { DataRoleType, RightType } from 'interfaces/events'
import { WorkflowDTO } from 'interfaces/workflows'
import { RightsModalType } from 'interfaces/rights/right'

type Props = {
  events: EventV2DTO[]
  isLoading: boolean
  pagination: Pagination
  setRightsModal: (rightsModal: RightsModalType) => void
  workflows: WorkflowDTO[]
}
export const EventsListContent: React.FC<Props> = ({ events, isLoading, pagination, setRightsModal, workflows }) => {
  // dux
  const { dataSubjectTypeCode, dataRole } = useAppSelector(getEventsListFilterState)
  const { data: dataSubjectTypes } = useDataSubjectTypes()

  // utils
  const eventsByRightType = [
    {
      events: events.filter(event => event.rightType === RightType.RIGHT_TYPE_CANONICAL) || [],
      title: 'Canonical Events',
      type: RightType.RIGHT_TYPE_CANONICAL,
    },
    {
      events:
        events
          .filter(event => event.rightType === RightType.RIGHT_TYPE_CUSTOM)
          .sort((a, b) => (a.rightName || '').localeCompare(b.rightName || '')) || [],
      title: 'Custom Events',
      type: RightType.RIGHT_TYPE_CUSTOM,
    },
  ]

  const showRightsByCode = dataRole !== DataRoleType.DATA_ROLE_PROCESSOR && !dataSubjectTypeCode

  return (
    <Box my={4}>
      {!events.length ? (
        // empty state
        <EventsListEmpty />
      ) : showRightsByCode && dataSubjectTypes?.length > 1 ? (
        // list w/o data subject filters
        <EventsByRightCode
          eventsByRightType={eventsByRightType}
          hasDataSubjectFilter={!!dataSubjectTypeCode}
          isLoading={isLoading}
          pagination={pagination}
          setRightsModal={setRightsModal}
          workflows={workflows}
        />
      ) : (
        // list with data subject filters
        eventsByRightType.map(
          ({ events, title, type }) =>
            !!events.length && (
              <EventsListTable
                events={events}
                hasDataSubjectFilter={!!dataSubjectTypeCode}
                key={title}
                isLoading={isLoading}
                pagination={pagination}
                setRightsModal={setRightsModal}
                title={title}
                type={type}
                workflows={workflows}
              />
            ),
        )
      )}
    </Box>
  )
}
