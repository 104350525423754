import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useFormikContext } from 'formik'
import { FilterOptionsState } from '@mui/material/useAutocomplete'

import { Label } from 'components/ui-kit/form/common/label/Label'
import { LoadingOptionsSpinner } from '.'
import { useAutocompleteStyles, useLabelsSearchOptions } from '../hooks'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'
import { getRenderTag, getRenderTags } from 'pages/assetManager/components/ManageLabelsInput/utils'

type Props = {
  index: number
  handleAutocompleteLabeledOnChange: any
  valueKey: string
  filterOptions?: (
    options: ManageLabelModalLabel[],
    state: FilterOptionsState<ManageLabelModalLabel>,
  ) => ManageLabelModalLabel[]
}

export const AutocompleteLabeledInput: React.FC<Props> = ({
  index,
  handleAutocompleteLabeledOnChange,
  valueKey,
  filterOptions,
}) => {
  const autocompleteClasses = useAutocompleteStyles()
  const { setSearchLabelValue, labelOptions, isLoadingLabels, setLabelOptions } = useLabelsSearchOptions()
  const { values, errors }: any = useFormikContext()

  return (
    <>
      <Label>Labeled</Label>
      <Autocomplete
        id={`accessAllowances[${index}].labelName`}
        popupIcon={null}
        autoComplete={false}
        multiple
        filterOptions={filterOptions}
        classes={{ inputRoot: autocompleteClasses.inputRoot }}
        disabled={values?.[valueKey]?.[index].criteria === ''}
        options={labelOptions}
        getOptionLabel={option => option.name}
        loading={isLoadingLabels}
        onChange={(e, v) => {
          handleAutocompleteLabeledOnChange(e, v, index)
          setLabelOptions([])
        }}
        onClose={() => {
          setLabelOptions([])
        }}
        onInputChange={(_, newInputValue) => {
          setSearchLabelValue(newInputValue)
        }}
        loadingText={<LoadingOptionsSpinner />}
        getOptionDisabled={() => Object.keys(values?.[valueKey]?.[index]?.labelName || {}).length > 0}
        renderOption={getRenderTag}
        renderTags={(value: ManageLabelModalLabel[]) => getRenderTags({ value, handleRemoveTag: () => {}, labels: [] })}
        renderInput={params => (
          <TextField
            {...params}
            helperText={errors?.[valueKey]?.[index]?.labelName || null}
            error={!!errors?.[valueKey]?.[index]?.labelName || undefined}
            inputProps={{
              ...params.inputProps,
              form: {
                autoComplete: 'off',
              },
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        )}
      />
    </>
  )
}
