import { createUseQuery, CustomQueryConfig } from '../../common/query'
import { ApiQueryKeys } from '../../common/queryKeys'
import { fetchProcessingActivity } from '../fetchers/fetchProcessingActivity'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.processingActivity,
  queryFn: fetchProcessingActivity,
  select: res => res?.data.processingActivity || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useProcessingActivity = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
