import { showToast } from 'components/ui-kit/toastr/Toastr'
import { FormikErrors } from 'formik'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { KETCH_DML } from 'pages/developers/hooks/constants'
import { DropResult } from 'react-beautiful-dnd'
import { reorderHooks } from '../utils'

type Args = {
  values: EditDatabaseFormValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<EditDatabaseFormValues>>
}

export const useEditAssetFormUtils = ({ setFieldValue, values }: Args) => {
  const { hooks = [] } = values

  const onDragEnd = (result: DropResult) => {
    // handle item dropped outside the list area
    if (!result.destination) {
      return
    }

    const startIndex = result.source.index
    const endIndex = result.destination.index
    const hookBeingRelocated = hooks?.[startIndex]
    const hookBeingRelocatedType = hookBeingRelocated.type
    const dmlIndex = hooks?.findIndex?.((item: any) => item?.name === KETCH_DML)

    if (hookBeingRelocatedType === 1 && endIndex >= dmlIndex) {
      showToast({ content: 'PRE hook must not be after Ketch DML', type: 'error' })
      return
    }

    if (hookBeingRelocatedType === 2 && endIndex <= dmlIndex) {
      showToast({ content: 'POST hook must not be before Ketch DML', type: 'error' })
      return
    }

    const newItems = reorderHooks(hooks, startIndex, endIndex)

    setFieldValue('hooks', newItems)
  }

  return {
    onDragEnd,
    hooks,
  }
}
