import React, { createContext } from 'react'
import { useAppNavigationUtils, UseAppNavigationUtilsReturnType } from '../utils/useAppNavigationUtils'

export const AppNavigationContext = createContext({} as UseAppNavigationUtilsReturnType)

type Props = {
  children: React.ReactNode
}

export const AppNavigationContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useAppNavigationUtils()

  return <AppNavigationContext.Provider value={{ ...hookValues }}>{children}</AppNavigationContext.Provider>
}

export const withAppNavigationContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <AppNavigationContextProvider>
        <Component {...props} />
      </AppNavigationContextProvider>
    )
  }
}
