import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { EventV2DTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  dataRole?: number
  rightType?: number
  rightCode?: string
  dataSubjectTypeCode?: string
  query?: string
}>

type Response = APIListResponse<{ events: EventV2DTO[] }>

export const fetchOrchestrationEvents = ({ dataRole, rightType, rightCode, dataSubjectTypeCode, query }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/v2/events`,
      params: {
        filters: {
          dataRole,
          rightType,
          rightCode,
          dataSubjectTypeCode,
          query,
        },
      },
    }),
  )
