import { CreateWebhookRequestBodyDTO } from '@ketch-com/figurehead'
import { CreateWebhookFormValues } from './getInitialValues'

export const mapFormValuesToFormData = (values: CreateWebhookFormValues): CreateWebhookRequestBodyDTO => {
  const hasEvents = Boolean(Object.keys(values?.events || {}).length)
  const events = []
  if (hasEvents) {
    for (const [key, value] of Object.entries(values?.events || {})) {
      if (value) events.push(key)
    }
  }

  return {
    webhook: {
      name: values.name,
      url: values.url,
      testMode: values.testMode,
      headers: values.headers,
      events,
    },
  }
}
