import { FC } from 'react'
import { useSetState } from 'react-use'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { Button, TooltipButton } from '@ketch-com/deck'
import { ApplicationDTO } from 'interfaces/applications/application'
import { FormMode } from 'interfaces/formModes/formMode'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { SENSITIVE_APPLICATION_STATUSES } from 'interfaces/applications/applicationStatus'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'
import { ApplicationExportCodeModal } from 'pages/consentAndRights/applications/modals/ApplicationExportCodeModal'
import { ApplicationDeleteModal } from 'pages/consentAndRights/applications/modals/ApplicationDeleteModal'

type Props = {
  application: ApplicationDTO
}

export const ApplicationViewActions: FC<Props> = ({ application }) => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()

  const { code, metadata, status } = application
  const canManageApp = isPermitted(PERMISSIONS.PROPERTY_WRITE)
  const isInSensitiveStatus = SENSITIVE_APPLICATION_STATUSES.includes(status)

  const [{ isExportCodeOpen, isDeleteOpen, isEditConfirmationOpen }, setState] = useSetState({
    isExportCodeOpen: false,
    isDeleteOpen: false,
    isEditConfirmationOpen: false,
  })

  const handleEdit = () => {
    navigate(RoutesManager.deployment.applications.upsert.root.getURL({ code, formMode: FormMode.EDIT }))
  }

  return (
    <>
      <Box display="flex" alignItems="center" gap="12px" sx={{ paddingLeft: 3 }}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography noWrap variant="smallBody" color="darkDuskFaded.main">
            Last Updated
          </Typography>
          <Typography noWrap color="darkDusk.main" variant="smallBody">
            <FormattedDateRenderer date={metadata?.updatedAt} />
          </Typography>
        </Box>

        <Button
          color="tertiary"
          size="large"
          sx={{ textWrap: 'nowrap' }}
          onClick={() => {
            setState({ isExportCodeOpen: true })
          }}
        >
          Export Code
        </Button>

        {canManageApp && (
          <>
            <TooltipButton
              title={isInSensitiveStatus ? 'You can’t delete Properties with active deployments.' : undefined}
              disabled={isInSensitiveStatus}
              color="tertiary"
              size="large"
              onClick={() => {
                setState({ isDeleteOpen: true })
              }}
            >
              Delete
            </TooltipButton>

            <Button
              color="secondary"
              size="large"
              onClick={() => {
                if (isInSensitiveStatus) {
                  setState({ isEditConfirmationOpen: true })
                } else {
                  handleEdit()
                }
              }}
            >
              Edit
            </Button>
          </>
        )}
      </Box>

      {isExportCodeOpen && (
        <ApplicationExportCodeModal
          application={application}
          onClose={() => {
            setState({ isExportCodeOpen: false })
          }}
        />
      )}

      {isDeleteOpen && (
        <ApplicationDeleteModal
          application={application}
          onClose={() => {
            setState({ isDeleteOpen: false })
          }}
          onDelete={() => {
            navigate(RoutesManager.deployment.applications.root.getURL())
          }}
        />
      )}

      {isEditConfirmationOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.APPLICATION}
          onSubmit={handleEdit}
          onCancel={() => {
            setState({ isEditConfirmationOpen: false })
          }}
        />
      )}
    </>
  )
}
