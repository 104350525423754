import { APIListRequestParams, APIListResponse, formatOrdering, Ordering } from 'api/common/utils'
import { WorkflowDTO } from 'interfaces/workflows'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
  workflowCode: string
  ordering?: Ordering<'version'>
}>

type Response = APIListResponse<{ workflows: WorkflowDTO[] }>

export const fetchWorkflowVersions = ({
  organizationCode,
  workflowCode,
  includeMetadata,
  start,
  limit,
  ordering,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/workflows/${workflowCode}/versions`,
      params: {
        filters: {
          includeMetadata,
          start,
          limit,
          ordering: formatOrdering(ordering),
        },
      },
    }),
  )
