import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  formData: ExperienceV2DTO
}

export const createExperience = ({ formData }: Params) =>
  API.post<ExperienceV2DTO>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/experiences`,
    }),
    { experience: formData },
  )
