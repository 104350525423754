import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchCookies } from 'api/cookies/fetchers/fetchCookies'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.cookiesPaginated,
  queryFn: fetchCookies,
  select: res => res?.data?.cookies || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery, 'organizationCode'>

export const useCookiesPaginated = (config: QueryConfig) => {
  const { userData } = useAuth()

  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config!.params,
      organizationCode: userData.organizationCode!,
    },
  })
}
