import React, { CSSProperties, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces'
import { AppDescriptorDTO, AppFormDTO, AppInstanceDTO, AppInstanceFieldDTO } from 'interfaces/apps'

import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { RoutesManager } from 'utils/routing/routesManager'
import { Button, ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import {
  AppFormSectionBasicDetails,
  AppFormSectionFields,
  AppFormSectionIdentitySpaces,
} from 'pages/dataSystems/ConnectionNew/AddConnectionForm/components'

import { getAppInstanceFormValues } from './utils'
import { GoogleSignInButton } from 'components/ui-kit/button/GoogleSignInButton'
import { Link } from 'components/ui-kit/link/Link'
import { Box, Typography, styled } from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import { useGetCreateAddConnectionFormValidationSchema } from './hooks/useValidationSchema'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

const DisclaimerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: '0 0 1rem 0',
}))

type Props = {
  isReady: boolean
  isEditMode: boolean
  appDescriptor: MaybeNull<AppDescriptorDTO>
  appInstance: MaybeNull<AppInstanceDTO>
  appInstanceFields: AppInstanceFieldDTO[]
  identitySpaces: IdentitySpaceDTO[]
  onSubmit: (values: AppFormDTO) => Promise<void>
  className?: string
  style?: CSSProperties
}

export const AddConnectionFormCreate: React.FC<Props> = ({
  isReady,
  isEditMode,
  appDescriptor,
  appInstance,
  appInstanceFields,
  identitySpaces,
  onSubmit,
  style,
  className,
}) => {
  const navigate = useNavigate()

  const app = appDescriptor?.app
  const marketplaceEntity = appDescriptor?.marketplaceEntity
  const appName = app?.name || ''
  const requiresGoogleOAuth = ['Google Analytics', 'Google Marketing'].includes(appName)
  const [currentFormStep, setCurrentFormStep] = useState(0)

  const initialValues = useMemo(
    () => getAppInstanceFormValues({ app, appInstance, appInstanceFields }),
    [app, appInstance, appInstanceFields],
  )

  const breadcrumbs = [
    { title: 'Systems', link: RoutesManager.systems.root.getURL() },
    { title: appName || '', link: RoutesManager.systems.id.root.getURL({ id: app?.ID }) },
    { title: 'New Connection' },
  ]

  const doesNotRequireIdentityInput = !!(app && !app.identitySpaces?.length)

  const validationSchema = useGetCreateAddConnectionFormValidationSchema({
    currentFormStep,
    isEditMode,
    appDescriptor,
    doesNotRequireIdentityInput,
  })

  enum NewConnectionFormStep {
    BASIC_DETAILS = 'Basic Details',
    IDENTITY_SPACES = 'Identifier',
    CONFIGURATION = 'Configuration',
  }

  let formSteps = [
    NewConnectionFormStep.BASIC_DETAILS,
    NewConnectionFormStep.IDENTITY_SPACES,
    NewConnectionFormStep.CONFIGURATION,
  ]

  let renderedChildren = []
  if (doesNotRequireIdentityInput) {
    renderedChildren = [
      <AppFormSectionBasicDetails isEditMode={isEditMode} marketplaceEntity={marketplaceEntity} />,
      <AppFormSectionFields isEditMode={isEditMode} app={app} />,
    ]
  } else {
    renderedChildren = [
      <AppFormSectionBasicDetails isEditMode={isEditMode} marketplaceEntity={marketplaceEntity} />,
      <AppFormSectionIdentitySpaces app={app} identitySpaces={identitySpaces} />,
      <AppFormSectionFields isEditMode={isEditMode} app={app} />,
    ]
  }

  if (doesNotRequireIdentityInput) {
    formSteps = [NewConnectionFormStep.BASIC_DETAILS, NewConnectionFormStep.CONFIGURATION]
  }

  const isLastStep = formSteps.length - 1 === currentFormStep

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })

  const { isSubmitting, values, isValid, submitForm, validateForm, setFieldTouched } = formikProps

  const hasManagedItems = !!values?.identitySpaces?.filter(({ managed }) => !!managed)?.length
  const hasUserInput = !!values?.identitySpaces?.filter(({ userIdentitySpaceCode }) => !!userIdentitySpaceCode)?.length
  const isIdentitySpacesListValid = hasManagedItems || hasUserInput || doesNotRequireIdentityInput
  const isSaveDisabled = isSubmitting || !isValid || !isIdentitySpacesListValid

  const onAccept = async () => {
    const errors = await validateForm()
    if (Object.keys(errors).length) {
      Object.keys(errors).forEach(errorKey => {
        if (errors.fields && errorKey === 'fields') {
          const fieldErros: Array<{ value: string }> = errors.fields as any
          fieldErros.forEach((fieldKey, index) => {
            // because values are stored in formik context as
            // { fields : [{value: 'value'}, etc etc] }
            setFieldTouched(`fields[${index}].value`, true)
          })
        } else {
          setFieldTouched(errorKey, true)
        }
      })
      return
    }
    if (isLastStep) {
      submitForm()
    } else {
      setCurrentFormStep(prev => prev + 1)
    }
  }

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          sx={{
            '&.UpsertLayout-container': {
              '& .UpsertLayout-stepper': {
                top: '8px',
              },
            },
          }}
          mode={UpsertLayoutMode.create}
          showStepper
          formSteps={formSteps}
          currentFormStep={currentFormStep}
          renderFormTitle={() => <ContentGroup title={`New Connection ${appName}`} titleVariant="h2" isDivider />}
          onAccept={onAccept}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          acceptActionButtonText="Continue"
          onCancel={() => {
            navigate(RoutesManager.systems.id.overview.root.getURL({ id: app?.ID }))
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
          // if this condition is true, this will render instead the normal accept button
          customAcceptButton={
            isLastStep &&
            requiresGoogleOAuth && <GoogleSignInButton onClick={() => submitForm()} disabled={isSaveDisabled} />
          }
        >
          <form autoComplete="off">
            {isReady ? (
              <>
                {renderedChildren.filter((_, i) => currentFormStep >= i)}
                {marketplaceEntity?.docUrl && (
                  <DisclaimerContainer>
                    <Typography>
                      By completing the installation, you acknowledge and accept that it operates as described in the{' '}
                      <Link outer to={marketplaceEntity?.docUrl || ''} target="_blank" variant="blue">
                        <Button variant="link" color="secondary">
                          <Typography>Documentation</Typography>
                        </Button>
                      </Link>
                      .<br />
                      You may uninstall this app at any time as described in the{' '}
                      <Link outer to={marketplaceEntity?.docUrl || ''} target="_blank" variant="blue">
                        <Button variant="link" color="secondary">
                          <Typography>Documentation</Typography>
                        </Button>
                      </Link>
                      .
                    </Typography>
                  </DisclaimerContainer>
                )}
              </>
            ) : (
              <Box
                sx={{
                  margin: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spinner size="32px" thickness={2.5} />
              </Box>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
