import { ObjectLiteral } from 'interfaces'
import { UpsertPreferenceExperienceDTO, PreferenceExperienceConfigDTO } from 'interfaces/experiences/experience'
import { isEmpty, omit } from 'lodash'
import { PreferenceExperienceFormValues } from '../../../interfaces/PreferenceExperienceFormValues'
import { serialize } from '../../common'
import { SubscriptionsDTO } from 'interfaces/experiences/experience'

export const mapPreferenceValuesToPayload = ({
  values,
  isEntitledToRights,
  isEntitledToConsentAndDisclosure,
  isEntitledToSubscriptions,
}: {
  values: PreferenceExperienceFormValues
  isEntitledToRights: boolean
  isEntitledToConsentAndDisclosure: boolean
  isEntitledToSubscriptions: boolean
}): UpsertPreferenceExperienceDTO => {
  const { consents, subscriptions } = values

  const { isCustomSwitchTextSwitchOn, isCustomTextSwitchOn, ...restConsents } = consents
  const { hideSubscriptionDetailTitle, ...restSubscriptions } = subscriptions
  restSubscriptions.topicItems = restSubscriptions?.topicItems?.map(({ name, ...rest }) => rest)
  restSubscriptions.controlItems = restSubscriptions?.controlItems?.map(({ name, ...rest }) => rest)

  return {
    name: values.name,
    code: values.code,

    preference: {
      title: values.title,
      overview: {
        ...values.overview,
        bodyDescription: serialize(values.overview.bodyDescription),
      },
      ...(isEntitledToConsentAndDisclosure && {
        consents: {
          ...restConsents,
          bodyDescription: serialize(values.consents.bodyDescription),
        },
      }),
      ...(isEntitledToSubscriptions && {
        subscriptions: {
          ...restSubscriptions,
          bodyDescription: serialize(subscriptions.bodyDescription),
        } as SubscriptionsDTO,
      }),
      ...(isEntitledToRights && {
        rights: {
          // 'personalDetailsTitleType' and 'requestTitleType' are local only for app form state and should not be sent to the server
          ...omit(values.rights, ['personalDetailsTitleType', 'requestTitleType']),
          canonicalRightFormTemplates: values.rights.canonicalRightFormTemplates.map(template => {
            return {
              ...omit(template, ['code', 'name', 'rights', 'id', 'experienceID']),
              canonicalRightCode: template.code,
            }
          }),
          customRightFormTemplates: values.rights.customRightFormTemplates.map(template =>
            omit(template, ['canonicalRightCode', 'canonicalRightName', 'code', 'name']),
          ),
          bodyDescription: serialize(values.rights.bodyDescription),
        },
      }),
      ...(!isEmpty(values.translations) && {
        translations: Object.entries(values.translations!).reduce<ObjectLiteral<PreferenceExperienceConfigDTO>>(
          (acc, [languageCode, translation]) => ({
            ...acc,
            [languageCode]: {
              title: translation.title,
              overview: {
                ...translation.overview,
                bodyDescription: serialize(translation.overview.bodyDescription),
              },
              ...(isEntitledToConsentAndDisclosure && {
                consents: {
                  ...translation.consents,
                  bodyDescription: serialize(translation.consents.bodyDescription),
                },
              }),
              ...(isEntitledToSubscriptions && {
                subscriptions: {
                  ...translation.subscriptions,
                  bodyDescription: serialize(translation.subscriptions.bodyDescription),
                } as SubscriptionsDTO,
              }),
              ...(isEntitledToRights && {
                rights: {
                  ...translation.rights,
                  bodyDescription: serialize(translation.rights.bodyDescription),
                },
              }),
            },
          }),
          {},
        ),
      }),
    },
  }
}
