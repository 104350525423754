import React from 'react'
import { Box, Typography } from '@mui/material'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'

type Props = {
  dataSubjectTypes: DataSubjectTypeDTO[]
}

export const SubjectTypeBulletedListTooltipContent: React.FC<Props> = ({ dataSubjectTypes }) => {
  return (
    <ul
      style={{
        paddingLeft: 20,
        paddingRight: 10,
        marginTop: 3,
        marginBottom: 6,
      }}
    >
      {dataSubjectTypes?.map((dst, index) => {
        return (
          <li
            key={dst?.code}
            style={{
              marginBottom: index === dataSubjectTypes?.length - 1 ? 0 : 10,
            }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="smallLabel" color="white.main">
                {dst?.name}
              </Typography>
              <Typography variant="footnote" color="white.main">
                {dst?.code}
              </Typography>
            </Box>
          </li>
        )
      })}
    </ul>
  )
}
