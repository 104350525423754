import { Formik } from 'formik'
import { Box } from '@mui/material'

import { useParams } from 'react-router-dom'
import { showToast } from 'components/modals/AlertComponent'
import { useUpdateAssignedCategories } from 'api/dataSystemsCategories/mutations/useUpdateAssignedCategories'
import { AssignedCategoryV3DTO, ConfirmationStatusV3DTO } from '@ketch-com/figurehead'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useQueryClient } from 'react-query'
import { useAssignedDataCategoriesInfinite } from 'api/dataSystemsCategories/queries/useAssignedDataCategoriesInfinite'
import { Button, Chip, PopUp, Icon } from '@ketch-com/deck'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const AssignedCategoriesRestoreModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { id } = useParams<{ id: string }>()

  const { data: dataAssignedCategories } = useAssignedDataCategoriesInfinite({
    installedDataSystemId: id,
    limit: 9000,
  })

  const queryClient = useQueryClient()

  const { mutateAsync: handleUpdateAssignedCategories } = useUpdateAssignedCategories({
    onSuccess: async () => {
      await queryClient.refetchQueries(ApiQueryKeys.assignedDataCategories)

      showToast({ content: 'Assigned data categories updated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update assigned data categories', type: 'error' })
    },
  })

  const onSubmit = (values: { assignedCategories: AssignedCategoryV3DTO[] }) => {
    handleUpdateAssignedCategories({
      params: {
        installedDataSystemId: id,
        formData: { assignedCategories: values.assignedCategories },
      },
    })
  }

  const handleConfirmation = (assignedCategoryId: string | undefined, confirmationStatus: ConfirmationStatusV3DTO) => {
    const assignedCategoriesRemap = dataAssignedCategories?.assignedCategories?.map(assignedCategory => {
      if (assignedCategory.id === assignedCategoryId) {
        return {
          ...assignedCategory,
          confirmationStatus,
        }
      } else {
        return assignedCategory
      }
    })

    handleUpdateAssignedCategories({
      params: {
        installedDataSystemId: id,
        formData: { assignedCategories: assignedCategoriesRemap },
      },
    })
  }

  const assignedCategoriesDismissed = dataAssignedCategories?.assignedCategories.filter(
    assignedCategory => assignedCategory.confirmationStatus === ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
  )

  const initialValues = {
    assignedCategories: assignedCategoriesDismissed ?? [],
  }

  return (
    <Formik enableReinitialize validateOnMount initialValues={initialValues} onSubmit={onSubmit}>
      {({ values }) => {
        return (
          <PopUp
            title="Data Categories"
            subTitle={`Data Categories Dismissed ${assignedCategoriesDismissed.length ?? 0}`}
            onClose={onClose}
            isOpen={isOpen}
            popUpWidth="1000px"
            variant="dialog"
            popUpActionChildren={
              <Button
                color="secondary"
                size="large"
                onClick={e => {
                  onClose()
                }}
              >
                Close
              </Button>
            }
          >
            <Box display="flex" gap={2} flexWrap="wrap">
              {values.assignedCategories.map(assignedCategory => {
                return (
                  <Box key={assignedCategory.id}>
                    <Chip
                      clickable
                      label={assignedCategory?.category?.name ?? ''}
                      onClick={() =>
                        handleConfirmation(assignedCategory?.id, ConfirmationStatusV3DTO.NoneConfirmationStatusV3)
                      }
                      deleteIcon={<Icon name="ORestore" />}
                      onDelete={() =>
                        handleConfirmation(assignedCategory?.id, ConfirmationStatusV3DTO.NoneConfirmationStatusV3)
                      }
                    />
                  </Box>
                )
              })}
            </Box>
          </PopUp>
        )
      }}
    </Formik>
  )
}
