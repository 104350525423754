export const numberTypes = [
  'Integer',
  'Int',
  'Int8',
  'Int16',
  'Int32',
  'Int64',
  'UInt',
  'UInt8',
  'UInt16',
  'UInt32',
  'UInt64',
  'Decimal',
  'Float',
  'Float32',
  'Float64',
  'bitSize',
  'Bit',
  'scale',
  'Money',
]

export const DSR_COMMAND_OPTIONS = [
  { id: 1, name: 'None' },
  { id: 2, name: 'Get' },
  { id: 3, name: 'Mask' },
  { id: 4, name: 'Hash' },
  { id: 5, name: 'Nullify' },
  { id: 6, name: 'Update' },
]
