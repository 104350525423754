import React, { useContext } from 'react'

import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { PreferenceField, isFieldRequired, fieldNames } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { SubscriptionTopics, SubscriptionControl } from './components'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import { InterpolatedTextArea } from 'pages/consentAndRights/experiences/upsert/components/common/interpolatedTextArea/InterpolatedTextArea'

type Props = {}

export const SubscriptionFields: React.FC<Props> = () => {
  const { sidebarActiveTab } = useContext(ExperienceUpsertContext)
  switch (sidebarActiveTab) {
    case PreferenceField.SUBSCRIPTIONS_TAB_NAME:
      return (
        <FormRow marginBottom={24}>
          <FormInput
            required={isFieldRequired({ field: PreferenceField.RIGHTS_TAB_NAME })}
            fullWidth
            name={fieldNames.preference.subscriptions.tabName.root}
            label="Display Text"
          />
        </FormRow>
      )
    case PreferenceField.SUBSCRIPTIONS_TOPICS:
      return <SubscriptionTopics />
    case PreferenceField.SUBSCRIPTIONS_CONTROL:
      return <SubscriptionControl />
    case PreferenceField.SUBSCRIPTIONS_BODY_TITLE:
      return (
        <FormRow marginBottom={24}>
          <FormInput
            required={isFieldRequired({ field: PreferenceField.RIGHTS_BODY_TITLE })}
            fullWidth
            name={fieldNames.preference.subscriptions.bodyTitle.root}
            label="Display Text"
          />
        </FormRow>
      )
    case PreferenceField.SUBSCRIPTIONS_BODY_DESCRIPTION:
      return (
        <FormRow marginBottom={24}>
          <InterpolatedTextArea
            required={isFieldRequired({ field: PreferenceField.RIGHTS_BODY_DESCRIPTION })}
            label="Display Text"
            name={fieldNames.preference.subscriptions.bodyDescription.root}
          />
        </FormRow>
      )
    case PreferenceField.SUBSCRIPTIONS_PRIMARY_BUTTON:
      return (
        <FormRow marginBottom={24}>
          <FormInput
            required={isFieldRequired({ field: PreferenceField.SUBSCRIPTIONS_PRIMARY_BUTTON })}
            fullWidth
            name={fieldNames.preference.subscriptions.primaryButtonText.root}
            label="Display Text"
          />
        </FormRow>
      )
    case PreferenceField.SUBSCRIPTIONS_SECONDARY_BUTTON:
      return (
        <FormRow marginBottom={24}>
          <FormInput
            required={isFieldRequired({ field: PreferenceField.SUBSCRIPTIONS_SECONDARY_BUTTON })}
            fullWidth
            name={fieldNames.preference.subscriptions.secondaryButtonText.root}
            label="Display Text"
          />
        </FormRow>
      )
    default:
      return null
  }
}
