import React from 'react'

import { OrganizationDTO } from 'interfaces/organizations/organization'
import { OrganizationDetailsCard } from './components/OrganizationDetailsCard'
import { MaybeNull } from 'interfaces'
import { OrganizationProductsDTO } from 'interfaces/products/product'
import { ReturnFromStripeCard } from './components/ReturnFromStripeCard'
import { Box } from '@mui/material'
import { Spinner } from '@ketch-com/deck'
import { createStyles, makeStyles } from '@mui/styles'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
// TODO: removed temporarily per https://ketch-com.atlassian.net/browse/KD-6950 #4
// import { SubscriptionPlanDetailsCard } from './components/SubscriptionPlanDetailsCard'

type Props = {
  isReady: boolean
  organization: MaybeNull<OrganizationDTO>
  organizationProducts: MaybeNull<OrganizationProductsDTO>
  isLoadingGetEditOrgPortalUrl: boolean
  isShowReturnFromStripeMessage: boolean
  onGetEditOrganizationUrl: () => void
  onHideReturnFromStripeMessage: () => void
}

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      layout: {
        position: 'relative',
        backgroundColor: palette.white.main,
        borderRadius: 11,
      },
    }),
  { name: 'OrganizationView' },
)

export const OrganizationView: React.FC<Props> = ({
  isReady,
  organization,
  organizationProducts,
  onGetEditOrganizationUrl,
  isLoadingGetEditOrgPortalUrl,
  isShowReturnFromStripeMessage,
  onHideReturnFromStripeMessage,
}) => {
  const classes = useStyles()

  const breadcrumbs = [
    {
      title: 'Settings',
      link: RoutesManager.settings.root.getURL(),
    },
    { title: 'Organization', link: RoutesManager.settings.organization.root.getURL() },
  ]

  if (!isReady || !organization || !organizationProducts) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box className={classes.layout}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          {isShowReturnFromStripeMessage && (
            <ReturnFromStripeCard onHideReturnFromStripeMessage={onHideReturnFromStripeMessage} />
          )}
          <OrganizationDetailsCard organization={organization} />

          {/* TODO: removed temporarily per https://ketch-com.atlassian.net/browse/KD-6950 #4 */}
          {/* <SubscriptionPlanDetailsCard
        organizationProducts={organizationProducts}
        onGetEditOrganizationUrl={onGetEditOrganizationUrl}
        isLoadingGetEditOrgPortalUrl={isLoadingGetEditOrgPortalUrl}
      /> */}
        </Box>
      </Box>
    </>
  )
}
