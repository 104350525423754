import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

import { AppFormDTO } from 'interfaces/apps'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { Chip } from '@ketch-com/deck'
import { FormDroplistButton } from 'components/form/FormDroplistButton'

type Props = {
  managed?: boolean
  identitySpaces: IdentitySpaceDTO[]
  filters: any
  rowIndex: number
}

export const UserIdentitySpacesCellRenderer: React.FC<Props> = ({ managed, filters, rowIndex, identitySpaces }) => {
  const { setFieldValue } = useFormikContext<AppFormDTO>()

  const filteredSpaces = identitySpaces.filter(
    item => !filters || (item.formatCode === filters?.format && item.typeCode === filters.type),
  )
  const fieldName = `identitySpaces[${rowIndex}].userIdentitySpaceCode`

  /* set code to only allowable value if only one value remains after filtering */
  useEffect(() => {
    if ((filteredSpaces || [])?.length === 1) {
      setFieldValue(fieldName, filteredSpaces?.[0]?.code || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return managed ? (
    <Chip label="Managed" />
  ) : (
    <FormDroplistButton
      size="small"
      hideOptionalLabel
      disableClearable
      valueKey="code"
      formPropertyName={fieldName}
      placeholder="Select Identifier"
      items={filteredSpaces}
    />
  )
}
