import { FormFieldDTO } from '@ketch-com/figurehead'
import { ensureArray } from 'utils/helpers/array'

export type FormFieldRelationship = 'Forms' | 'Authenticators' | 'Experiences' | 'Workflows'

export const useFormFieldsRelationshipData = (formField?: FormFieldDTO) => {
  // templates derived data
  const formFieldTemplates = ensureArray(formField?.templates)
  const hasTemplates = formFieldTemplates.length > 0

  const formFieldTemplatesActive = formFieldTemplates.filter(template => !!template?.active)
  const hasActiveTemplates = formFieldTemplatesActive.length > 0

  // authenticators derived data
  const formFieldAuthenticators = ensureArray(formField?.authenticators)
  const hasAuthenticators = formFieldAuthenticators.length > 0

  // experiences derived data
  const formFieldExperiences = ensureArray(formField?.experiences)
  const hasExperiences = formFieldExperiences.length > 0

  const formFieldExperiencesActive = formFieldExperiences.filter(experience => !!experience?.active)
  const hasActiveExperiences = formFieldExperiencesActive.length > 0

  // DSR Workflow activities derived data
  const formFieldActivities = ensureArray(formField?.activities)
  const hasActivities = formFieldActivities.length > 0

  const formFieldActivitiesActive = formFieldActivities.filter(activity => !!activity?.isActive)
  const hasActiveActivities = formFieldActivitiesActive.length > 0

  // only allow delete if form field is not used in any templates, authenticators, workflows, or experiences
  const isAllowedToDelete =
    formFieldTemplatesActive.length === 0 &&
    formFieldAuthenticators.length === 0 &&
    formFieldExperiencesActive.length === 0 &&
    formFieldActivities.length === 0

  const hasAnyRelationships = hasTemplates || hasExperiences || hasAuthenticators || hasActivities

  const defaultTab: FormFieldRelationship = hasTemplates
    ? 'Forms'
    : hasAuthenticators
    ? 'Authenticators'
    : hasExperiences
    ? 'Experiences'
    : 'Workflows'

  return {
    formFieldAuthenticators,
    formFieldExperiences,
    formFieldExperiencesActive,
    formFieldTemplatesActive,
    formFieldTemplates,
    hasActiveExperiences,
    hasActiveTemplates,
    hasAuthenticators,
    isAllowedToDelete,
    hasAnyRelationships,
    hasActivities,
    formFieldActivities,
    formFieldActivitiesActive,
    hasActiveActivities,
    defaultTab,
  }
}
