import React, { forwardRef } from 'react'
import { find, isEmpty } from 'lodash'
import { useSetState } from 'react-use'

import { LanguageDTO } from 'interfaces/languages/language'
import { matchSearch } from 'utils/helpers/matchSearch'
import { useLanguages } from 'api/languages/queries/useLanguages'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { useUpdateLanguagesConfiguration } from 'api/languages/mutations/useUpdateLanguagesConfiguration'
import { showToast } from 'components/modals/AlertComponent'
import { TransferList } from 'components/transferList/TransferList'
import { Button, ListItem, PopUp } from '@ketch-com/deck'
import { Typography, Box } from '@mui/material'
import { TreeItemContentProps } from '@mui/x-tree-view'

type Props = {
  onSubmit?: () => void
  onCancel: () => void
}

type State = {
  isBusy: boolean
  mappedItems: LanguageDTO[]
  mappedItemsSearchString: string
  availableItemsSearchString: string
}

const TransferListItemContentComponent = forwardRef(function CustomContent(
  { item, onItemClick, type, searchString }: TreeItemContentProps,
  ref: React.Ref<HTMLLIElement & HTMLDivElement>,
) {
  return (
    <Box onClick={onItemClick}>
      <ListItem
        sx={{
          maxHeight: 'unset',
        }}
        ref={ref}
        tabIndex={0}
        onExpandableActionIconClick={onItemClick}
        isExpandableAction
        expandableActionLevelItem="one"
        expandableActionIcon={type === 'INCLUDE' ? 'FMinusRound' : 'FPlusRound'}
        startElement={
          <Box display="flex" flexDirection="column">
            <Typography variant="body">{item.englishName}</Typography>
          </Box>
        }
      />
    </Box>
  )
})

export const ChangeLanguagesListModal: React.FC<Props> = props => {
  const { onSubmit, onCancel } = props

  const [state, setState] = useSetState<State>({
    isBusy: false,
    mappedItems: [],
    mappedItemsSearchString: '',
    availableItemsSearchString: '',
  })

  const { data: availableLanguages, isLoading: isAvailableLanguagesLoading } = useLanguages({
    onError: () => {
      showToast({ content: 'Failed to fetch available languages', type: 'error' })
      onCancel()
    },
  })

  const { isLoading: isUsedLanguagesLoading } = useOrganizationLanguages({
    onSuccess: ({ data }) => {
      const itemsList = data.languages || []

      setState({
        mappedItems: itemsList.map(item => item.language),
      })
    },
    onError: () => {
      showToast({ content: 'Failed to fetch organization languages', type: 'error' })
      onCancel()
    },
  })

  const { mutateAsync: handleUpdateLanguagesConfiguration } = useUpdateLanguagesConfiguration({
    onMutate: () => {
      setState({ isBusy: true })
    },
    onSuccess: () => {
      showToast({ content: 'Languages configuration updated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update languages configuration', type: 'error' })
    },
  })

  const handleSubmit = async () => {
    const codes = state.mappedItems.map(({ code }) => code)
    const formData = {
      languageCodes: codes,
    }

    await handleUpdateLanguagesConfiguration({ params: { formData } })
    await onSubmit?.()
  }

  const mappedItemsList = state.mappedItems.filter(item => matchSearch(item.englishName, state.mappedItemsSearchString))
  const availableItemsList = availableLanguages.filter(
    item =>
      isEmpty(find(state.mappedItems, { code: item.code })) &&
      matchSearch(item.englishName, state.availableItemsSearchString),
  )

  return (
    <PopUp
      isLoading={isAvailableLanguagesLoading || isUsedLanguagesLoading}
      title="Manage Languages"
      popUpWidth="950px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="primary" size="large" type="submit" pending={state.isBusy} onClick={() => handleSubmit()}>
            Confirm
          </Button>
        </>
      }
    >
      <TransferList
        excludedTitle="All Languages"
        excludedItems={availableItemsList}
        excludedItemsSearchString={state.availableItemsSearchString}
        includedTitle="Selected Languages"
        includedItems={mappedItemsList}
        includedItemsSearchString={state.mappedItemsSearchString}
        onChange={nextIncludedItems => setState({ mappedItems: nextIncludedItems })}
        onSearchIncludedItems={nextSearchString => setState({ mappedItemsSearchString: nextSearchString })}
        onSearchExcludedItems={nextSearchString => setState({ availableItemsSearchString: nextSearchString })}
        ContentComponent={TransferListItemContentComponent}
      />
    </PopUp>
  )
}
