import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  code: string
}

export const checkIsFormTemplateCodeAvailable = ({ code }: Params) =>
  API.get<{ available?: boolean }>(
    formatRequestString({
      entityUrl: `/api/form-templates/${code}/available`,
    }),
  ).then(({ data }) => !!data?.available)
