import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchLabelsV3 } from 'api/labels/fetchers/fetchLabelsV3'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.labels,
  queryFn: fetchLabelsV3,
  select: res => res?.data?.labels || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useLabelsV3 = (config?: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
