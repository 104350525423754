import { TabFilterType } from '../constants'

export const getStatusFilterEnum = (string: string) => {
  switch (string) {
    case TabFilterType.ALL:
      return ''
    case TabFilterType.DONE:
      return String(1)
    case TabFilterType.SKIP:
      return String(3)
    case TabFilterType.FAIL:
      return String(2)
    default:
      return ''
  }
}
