import Box from '@mui/material/Box'
import { BuilderContext } from '../../../Builder'
import { useContext } from 'react'
import { SectionEmptyState } from '../../SectionEmptyState'
import { ThemeBuilderSectionLabels } from '../../../utils/labels'
import { BannerThemeBuilderSection } from '../../../utils/enums'
import { KetchBadge } from '../../KetchBadge'
import { GpcBadge } from '../../GpcBadge'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'

export const BannerFooter: React.FC = () => {
  const { experienceConfig, themeConfig, isTheme } = useContext(BuilderContext)

  // Theme config
  const backgroundColor = getIn(themeConfig, themeUpsertFormKeys.bannerContainerBackgroundColor)
  const showKetchBadge = getIn(themeConfig, themeUpsertFormKeys.bannerFooterKetchBadgeVisible)

  // Experience confige
  const showGpcBadge = getIn(experienceConfig, experienceUpsertFormKeys.bannerFooterGpcBadgeVisible)

  return showKetchBadge || showGpcBadge ? (
    <Box display="flex" gap="24px" alignItems={'center'} height={24}>
      {showKetchBadge && <KetchBadge contrastBackgroundColor={backgroundColor} />}
      {showGpcBadge && <GpcBadge contrastBackgroundColor={backgroundColor} />}
    </Box>
  ) : isTheme ? (
    <SectionEmptyState section={ThemeBuilderSectionLabels[BannerThemeBuilderSection.Footer]} />
  ) : null
}
