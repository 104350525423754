import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchUsers } from 'api/users/fetchers/fetchUsers'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.usersPaginated,
  queryFn: fetchUsers,
  select: res => res?.data?.members || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery, 'organizationCode'>

export const useUsersPaginated = (config?: QueryConfig) => {
  const { userData } = useAuth()

  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
      organizationCode: userData.organizationCode!,
    },
  })
}
