import Box from '@mui/material/Box'
import { Button, Icon } from '@ketch-com/deck'
import React, { useState } from 'react'
import { HowProcessingActivitiesWorksModal } from './HowProcessingActivitiesWorksModal'

export const ProcessingActivitiesHeader: React.FC = () => {
  const [howItWorksOpen, setHowItWorksOpen] = useState(false)

  return (
    <>
      {/* Title and how-to chip  */}
      <Box display="flex" alignItems="center">
        <Button
          sx={{
            ml: 'auto',
          }}
          size="medium"
          color="tertiary"
          startIcon={<Icon name="OInfo" />}
          onClick={() => setHowItWorksOpen(true)}
        >
          How Does it Work
        </Button>
        {howItWorksOpen && <HowProcessingActivitiesWorksModal onCancel={() => setHowItWorksOpen(false)} />}
      </Box>
    </>
  )
}
