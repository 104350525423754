import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import {
  BuilderTextInputMode,
  FormTextInput,
} from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'

export const PreferenceThemeRequestsTabHomeRightsListForm: React.FC = () => {
  return (
    <>
      <SidebarFormGroup sx={{ pt: 0 }} hasBorderBottom>
        <FormColorInput
          name={themeUpsertFormKeys.preferenceRequestsTabHomeRightsListTitleColor}
          label={'List Title Color'}
          fullWidth
          required
        />
      </SidebarFormGroup>
      <SidebarFormGroup title={'List Items'} sx={{ gap: '16px' }}>
        <FormColorInput
          name={themeUpsertFormKeys.preferenceRequestsTabHomeRightsListItemTitleColor}
          label={'Request Title Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferenceRequestsTabHomeRightsListItemArrowIcon}
          label={'Arrow Icon Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferenceRequestsTabHomeRightsListItemBackground}
          label={'Fill Color'}
          fullWidth
          required
        />
        <FormTextInput
          name={themeUpsertFormKeys.preferenceRequestsTabHomeRightsListItemCornerRadius}
          label={'Corner Radius (px)'}
          mode={BuilderTextInputMode.CornerRadius}
          inputWidth="92px"
        />
      </SidebarFormGroup>
    </>
  )
}
