import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { Navigate } from 'react-router-dom'
import { useAuth, useIsPermitted } from 'utils/hooks'
import { RoutesManager } from '../routesManager'
import { InitialLoadingScreen } from 'pages/auth/InitialLoadingScreen'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useIsKetchFree } from 'utils/hooks/useIsKetchFree'

/**
 * This component will call the loginWithRedirect function from the useAuth hook if the user is not authenticated, but was invited.
 * If a user arrives here by accident, then they will be redirected to the home page.
 */
export const Invite = ({ myText }: { myText?: string }) => {
  const { isPermitted, isPermissionsLoading } = useIsPermitted()
  const { isAuthenticated, isLoading: isAuth0Loading } = useAuth0()
  const { loginWithRedirect: ketchLoginWithRedirect } = useAuth()
  const { isKetchFree, isLoading: isKetchFreeLoading } = useIsKetchFree()

  const isPermittedToInsightsHome = isPermitted(PERMISSIONS.RIGHT_INSIGHTS_READ)

  useEffect(() => {
    const loginWithRedirectAsync = async () => {
      if (!isAuthenticated && !isAuth0Loading) {
        await ketchLoginWithRedirect()
      }
    }

    loginWithRedirectAsync()
  }, [isAuthenticated, ketchLoginWithRedirect, isAuth0Loading])

  if (isPermissionsLoading || isAuth0Loading || !isAuthenticated || isKetchFreeLoading) return <InitialLoadingScreen />

  if (isKetchFree) return <Navigate to="/home" />

  if (!isPermittedToInsightsHome) return <Navigate to={RoutesManager.settings.profile.root.getURL()} />

  if (isPermittedToInsightsHome) return <Navigate to="/insights" />

  return <Spinner />
}
