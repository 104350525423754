import { makeStyles, createStyles } from '@mui/styles'

export const useStyles = makeStyles(
  ({ typography, palette, spacing }) =>
    createStyles({
      container: {
        flex: 1,
        maxWidth: '50vw',
        height: '100vh',
        padding: '0 80px',
        background: palette.white.main,
        overflowY: 'scroll',
      },
      loginLink: {
        fontSize: typography.pxToRem(14),
        color: palette.sphere.main,
        textDecoration: 'underline',
      },
      backButton: {
        width: '44px',
      },
      leftArrow: {
        marginRight: spacing(1.5),
      },
      flexContainer: {
        width: '428px',
      },
      formTitle: {
        width: '416px',
        height: '42px',
        fontFamily: "'Inter'",
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '42px',
        lineHeight: '42px',
        letterSpacing: '-0.01em',
        color: '#000000',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        align: 'center',
        textAlign: 'center',
        display: 'block',
      },
      formSubtitle: {
        width: '428px',
        fontFamily: "'Inter'",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '28px',
        align: 'center',
        letterSpacing: '-0.02em',
        color: '#000000',
        flex: 'none',
        order: 1,
        flexGrow: 0,
        textAlign: 'center',
        display: 'block',
      },
      signInOption: {
        fontFamily: "'Inter'",
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '-0.01em',
        color: '#071A24',
        display: 'block',
        textAlign: 'center',
      },
      termsOfService: {
        fontFamily: "'Inter'",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '-0.01em',
        color: '#071A24',
      },
      sideContainer: {
        width: '104px',
        flex: 0,
      },
      link: {
        color: palette.sphere.main,
        textDecoration: 'none',
      },
      columnContainer: {
        alignItems: 'center',
        flexDirection: 'column',
      },
    }),
  { name: 'Register' },
)
