import Box from '@mui/material/Box'
import { useContext } from 'react'
import { ProcessingActivitiesContext } from '../context/ProcessingActivitiesContext'
import { ProcessingActivityTile } from './ProcessingActivityTile'
import { ProcessingActivityDTO } from '@ketch-com/figurehead'
import { NoSearchResultsBox } from './NoSearchResults'
import { Waypoint } from 'react-waypoint'
import { InfiniteScrollLoadingIndicator } from 'components/InfiniteScrollLoadingIndicator'
import { Spinner } from '@ketch-com/deck'

// List of processing activities
export const ProcessingActivitiesList: React.FC = () => {
  const {
    processingActivities,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isProcessingActivitiesRefetching,
    isLoading,
  } = useContext(ProcessingActivitiesContext)

  if (isLoading || (isProcessingActivitiesRefetching && !isFetchingNextPage)) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" flex={1} height="100%">
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }
  return processingActivities.length ? (
    <Box>
      <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" gap={2.5} rowGap={3} mt={3} mb={3}>
        {processingActivities.map((activity: ProcessingActivityDTO, index) => (
          <Box key={activity.id}>
            <ProcessingActivityTile key={activity.id} {...activity} />
            {(processingActivities?.length || 0) - 2 === index ? (
              <Waypoint
                onEnter={() => {
                  if (!isFetchingNextPage && hasNextPage) fetchNextPage()
                }}
              />
            ) : null}
          </Box>
        ))}
      </Box>
      <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
    </Box>
  ) : (
    <Box mt={3}>
      <NoSearchResultsBox />
    </Box>
  )
}
