import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type DateRange = {
  startDate: string
  endDate: string
}

interface ActivityAndAccessFilterState {
  teamActivity: DateRange
  dataUsage: DateRange
  accessUtilization: DateRange
}

const initialState = {
  teamActivity: {
    startDate: '',
    endDate: '',
  },
  dataUsage: {
    startDate: '',
    endDate: '',
  },
  accessUtilization: {
    startDate: '',
    endDate: '',
  },
} as ActivityAndAccessFilterState

export const activityAndAccessSlice = createSlice({
  name: 'ActivityAndAccessFilterStateSlice',
  initialState,
  reducers: {
    setTeamActivityDateRange: (state, action: PayloadAction<DateRange>) => {
      state.teamActivity = action.payload
    },
    setDataUsageDateRange: (state, action: PayloadAction<DateRange>) => {
      state.dataUsage = action.payload
    },
    setAccessUtilizationDateRange: (state, action: PayloadAction<DateRange>) => {
      state.accessUtilization = action.payload
    },
  },
})

export const { setTeamActivityDateRange, setDataUsageDateRange, setAccessUtilizationDateRange } =
  activityAndAccessSlice.actions

export default activityAndAccessSlice.reducer
