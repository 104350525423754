import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Button, Icon } from '@ketch-com/deck'
import { DeleteApplianceModal } from 'pages/developers/transponders/list/components/DeleteApplianceModal'
import { RoutesManager } from 'utils/routing/routesManager'
import { Box, Typography } from '@mui/material'
import { TransponderKeyModal } from './TransponderKeyModal'
import { Appliance } from '@ketch-com/windlass/dist/appliance/appliances'

type Props = {
  appliance: Appliance
}

export const TransponderViewActions: React.FC<Props> = ({ appliance }) => {
  const [isDeleteApplianceModalOpen, setIsDeleteApplianceModalOpen] = useState(false)
  const [transponderKeyModalOpen, setTransponderKeyModalOpen] = useState(false)

  const navigate = useNavigate()

  return (
    <>
      <Box display="flex" gap={1.5}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="smallBody" color="darkDuskFaded.main">
            Last Connected
          </Typography>

          <Typography color="darkDusk.main" variant="smallBody">
            <FormattedDateRenderer
              date={appliance?.Instances && appliance.Instances?.[0] && appliance.Instances[0].ts}
            />
          </Typography>
        </Box>

        <Button
          onClick={() => {
            setIsDeleteApplianceModalOpen(true)
          }}
          color="tertiary"
          size="large"
        >
          Delete
        </Button>

        <Button
          color="secondary"
          size="large"
          startIcon={<Icon name="FKey" />}
          onClick={() => {
            setTransponderKeyModalOpen(true)
          }}
        >
          Transponder Key
        </Button>

        <Button
          onClick={() => {
            navigate(RoutesManager.developers.transponders.upsert.getURL({ id: appliance.applianceID }))
          }}
          color="primary"
          size="large"
        >
          Edit
        </Button>
      </Box>

      {isDeleteApplianceModalOpen && (
        <DeleteApplianceModal
          applianceId={appliance.applianceID}
          onCancel={() => setIsDeleteApplianceModalOpen(false)}
          onSubmit={async () => {
            setIsDeleteApplianceModalOpen(false)
          }}
        />
      )}
      {transponderKeyModalOpen && (
        <TransponderKeyModal
          applianceId={appliance.applianceID}
          handleClose={() => setTransponderKeyModalOpen(false)}
        />
      )}
    </>
  )
}
