import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { fetchAssetsPaginatedV2 } from '../fetchers/fetchAssetsPaginatedV2'
import { APIListRequestParams } from 'api/common/utils'
import { AssetSummaryDTO } from '@ketch-com/figurehead'

type LastPage = { assets: AssetSummaryDTO[]; totalResults?: number }

type Params = APIListRequestParams<{
  canonicalResourceType?: number | null | undefined
  resourceTypeCode?: string | null | undefined
  query?: string | null | undefined
  enabled?: boolean
}>

export const useAssetsInfiniteV2 = ({ onSettled, params }: { onSettled?: any; params: Params }) => {
  const { limit = 20, enabled = true } = params

  const resp = useInfiniteQuery({
    enabled,
    queryKey: [
      ApiQueryKeys.resourcesInfinite,
      {
        includeIssues: true,
        includeMetadata: true,
        ...params,
      },
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchAssetsResp = await fetchAssetsPaginatedV2({
        limit,
        start: (pageParam - 1) * limit,
        ...params,
      })

      const { data } = fetchAssetsResp

      data.assets = data.assets ?? []
      data.totalResults = data.totalResults ?? 0

      return data
    },
    getNextPageParam: ({ totalResults }: LastPage, allPages: LastPage[]) => {
      let totalAssetsFetchedSoFar = 0
      allPages?.forEach?.(p => {
        totalAssetsFetchedSoFar += p?.assets?.length || 0
      })
      const nextPageParam = Math.ceil(totalAssetsFetchedSoFar / limit) + 1
      const getNextPageParamReturnValue = totalAssetsFetchedSoFar === totalResults ? undefined : nextPageParam
      return getNextPageParamReturnValue
    },
    onSettled,
    staleTime: 1000 * 60 * 5, // five minutes
  })

  let infiniteAssets: AssetSummaryDTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    infiniteAssets = [...infiniteAssets, ...(page?.assets || [])]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: infiniteAssets,
    totalResults,
  }
}
