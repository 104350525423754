import React from 'react'
import { Tooltip as TippyTooltip, TooltipProps } from 'react-tippy'
import { FormikErrors } from 'formik'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'

import 'react-tippy/dist/tippy.css'
import './Tooltip.scss'

declare module 'react-tippy' {
  export interface TooltipProps {
    children?: React.ReactNode
    tag?: React.ElementType
  }
}

export interface Props extends Omit<TooltipProps, 'html'> {
  /** Should appear with animation */
  animation?: TooltipProps['animation']
  /** Should have arrow icon */
  arrow?: TooltipProps['arrow']
  /** className of container */
  className?: TooltipProps['className']
  /** Component inner content */
  content?: React.ReactNode | string[] | FormikErrors<ICanvasStep>[]
  /** Component placement */
  position?: TooltipProps['position']
  /** Component sticky position */
  sticky?: TooltipProps['sticky']
  /** Component keeps open when mouse enter over tooltip */
  interactive?: TooltipProps['interactive']
  /** Component trigger listeners */
  trigger?: TooltipProps['trigger']
  /** Component custom options to be passed to popper.js */
  popperOptions?: any
  /** Component container tag */
  tag?: React.ElementType
}

/**
 * -
 */
export const Tooltip: React.FC<Props> = ({
  animation = 'shift',
  arrow = true,
  children,
  className = 'tippy-wrapper-classname',
  content,
  tag = 'div',
  position = 'top',
  sticky = true,
  interactive = false,
  trigger = 'mouseenter',
  popperOptions,
  ...rest
}) => {
  if (!content) {
    return <>{children}</>
  }

  return (
    <TippyTooltip
      {...rest}
      tag={tag}
      animation={animation}
      arrow={arrow}
      html={<>{content}</>}
      position={position}
      className={className}
      sticky={sticky}
      interactive={interactive}
      trigger={trigger}
      popperOptions={popperOptions}
    >
      {children}
    </TippyTooltip>
  )
}
