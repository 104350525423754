import { useLocation, useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { useIsEntitled, useQueryParams } from 'utils/hooks'
import { useIsExperienceServerAdmin } from 'pages/consentAndRights/forms/hooks'
import { useFormTemplatesInfinite } from 'api/formTemplates/queries/useFormTemplatesInfinite'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

export const useTemplatesListUtils = () => {
  const { queries } = useQueryParams<{ search?: string }>()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  // fetch data

  const infiniteCustomFormTemplatesResp = useFormTemplatesInfinite({
    options: {
      itemsPerPage: 20,
      enabled: true,
    },
    params: {
      ordering: { created_at: 'desc' },
      query: queries.search || undefined,
      includeMetadata: true,
    },
  })

  const { isUserExperienceServerAdmin, isUserLoading } = useIsExperienceServerAdmin()

  const isLoadingAggregate = infiniteCustomFormTemplatesResp.isLoading || isUserLoading

  const { isEntitled } = useIsEntitled()

  const isEntitledToExpServerRights = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)

  const payload = {
    infiniteCustomFormTemplatesResp,
    isEntitledToExpServerRights,
    navigate,
    isLoadingAggregate,
    isUserExperienceServerAdmin,
    location,
    dispatch,
  }

  return payload
}

export type UseTemplatesListUtilsReturn = ReturnType<typeof useTemplatesListUtils>
