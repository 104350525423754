import { PoliciesList } from 'pages/policyCenter/policies/list/PoliciesList'
import { usePoliciesPaginatedV2 } from 'api/policies/queries/usePoliciesPaginatedV2'

export const PoliciesListContainer: React.FC = () => {
  const {
    data: policies,
    isLoading,
    pagination,
  } = usePoliciesPaginatedV2({
    itemsPerPage: 10,
    params: {},
  })

  return <PoliciesList isLoading={isLoading} policies={policies} pagination={pagination} />
}
