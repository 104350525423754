import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'
import { showToast } from 'components/modals/AlertComponent'

import { useUpdateApiKey } from 'api/apiKeys/mutations/useUpdateApiKey'
import { useCreateApiKey } from 'api/apiKeys/mutations/useCreateApiKey'
import { ApiKeyUpsertView } from './ApiKeyUpsertView'

import { useApiKeyRoles } from 'api/apiKeys/queries/useApiKeyRoles'
import { useApiKey } from 'api/apiKeys/queries/useApiKey'
import { ApiKeyDTO, ApiKeyFormValues } from 'interfaces/apiKeys'
import { getApiKeyFormPayload } from './utils'
import { useCurrentOrganization } from 'utils/hooks/useCurrentOrganization'

export const ApiKeyUpsertContainer: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { id } = useParams<{ id: string }>()

  const isEditMode = id !== NEW_ITEM_ID
  const { organization, isLoading: isLoadingOrganization } = useCurrentOrganization({})

  const onUpsertSuccess = async (apiKey: ApiKeyDTO) => {
    if (isEditMode) {
      await queryClient.refetchQueries([ApiQueryKeys.apiKey, { apiKeyId: apiKey?.id, includeMetadata: true }])
      showToast({ content: 'API Key updated', type: 'success' })
      navigate(RoutesManager.developers.apiKeys.detail.getURL({ id }))
    } else {
      showToast({ content: 'API Key created', type: 'success' })
      navigate(RoutesManager.developers.apiKeys.detail.getURL({ id: apiKey.id }))
    }
  }

  const handleRedirectToApiKeyList = () => {
    navigate(RoutesManager.developers.apiKeys.root.getURL())
  }

  const { mutateAsync: handleCreateApiKey } = useCreateApiKey({
    onSuccess: ({ data }) => {
      onUpsertSuccess(data)
    },
    onError: () => {
      showToast({ content: 'Failed to create apiKey', type: 'error' })
    },
  })

  const { mutateAsync: handleUpdateApiKey } = useUpdateApiKey({
    onSuccess: ({ data }) => {
      onUpsertSuccess(data)
    },
    onError: () => {
      showToast({ content: 'Failed to update apiKey', type: 'error' })
    },
  })

  const { data: roles, isLoading: isApiKeyRolesLoading } = useApiKeyRoles({
    onError: () => {
      showToast({ content: 'Failed to fetch users roles', type: 'error' })
      handleRedirectToApiKeyList()
    },
  })

  const { data: apiKey, isLoading: isApiKeyLoading } = useApiKey({
    enabled: isEditMode,
    params: {
      apiKeyId: id!,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch API Key', type: 'error' })
      handleRedirectToApiKeyList()
    },
  })

  const handleOnSubmit = async (values: ApiKeyFormValues) => {
    if (!isEditMode) {
      const formData = getApiKeyFormPayload({ values })
      await handleCreateApiKey({
        params: {
          formData,
        },
      })
    }
    if (isEditMode) {
      const formData = getApiKeyFormPayload({ values })

      await handleUpdateApiKey({
        params: {
          id: id!,
          formData,
        },
      })
    }
  }

  const isReady = !isApiKeyRolesLoading && !isApiKeyLoading && !isLoadingOrganization

  return (
    <ApiKeyUpsertView
      isReady={isReady}
      isEditMode={isEditMode}
      apiKey={apiKey}
      onSubmit={handleOnSubmit}
      roles={roles}
      organization={organization}
    />
  )
}
