import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import zh from '../i18n/translations/zh/common.json'
import zhPermitsStatistics from '../i18n/translations/zh/permitsStatistics.json'
import zhRightsStatistics from '../i18n/translations/zh/rightsStatistics.json'
import en from '../i18n/translations/en/common.json'
import enPermitsStatistics from '../i18n/translations/en/permitsStatistics.json'
import enRightsStatistics from '../i18n/translations/en/rightsStatistics.json'
import { COMMON, PERMITS_STATISTICS, RIGHTS_STATISTICS } from './types'

const resources = {
  en: {
    [COMMON]: en,
    [PERMITS_STATISTICS]: enPermitsStatistics,
    [RIGHTS_STATISTICS]: enRightsStatistics,
  },
  zh: {
    [COMMON]: zh,
    [PERMITS_STATISTICS]: zhPermitsStatistics,
    [RIGHTS_STATISTICS]: zhRightsStatistics,
  },
}

i18n.use(initReactI18next).init({
  lng: 'en',
  resources,
})

export default i18n
