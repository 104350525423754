import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  verifierId?: string
}

export const deleteVerifier = ({ verifierId }: Params) =>
  API.delete<{}>(
    formatRequestString({
      entityUrl: `/api/verifiers/${verifierId}`,
    }),
  )
