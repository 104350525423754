import React, { useContext } from 'react'

import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { InterpolatedTextArea } from 'pages/consentAndRights/experiences/upsert/components/common/interpolatedTextArea/InterpolatedTextArea'
import { PreferenceField, isFieldRequired, fieldNames } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { RightsRequest, FormTemplates } from './components'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import { FormSwitch } from 'components/ui-kit/form/switch/FormSwitch'

type Props = {}

export const RightsFields: React.FC<Props> = () => {
  const { sidebarActiveTab, organizationLanguages } = useContext(ExperienceUpsertContext)
  switch (sidebarActiveTab) {
    case PreferenceField.RIGHTS_TAB_NAME:
      return (
        <FormRow marginBottom={24}>
          <FormInput
            required={isFieldRequired({ field: PreferenceField.RIGHTS_TAB_NAME })}
            fullWidth
            name={fieldNames.preference.rights.tabName.root}
            label="Display Text"
          />
        </FormRow>
      )
    case PreferenceField.RIGHTS_BODY_TITLE:
      return (
        <FormRow marginBottom={24}>
          <FormInput
            required={isFieldRequired({ field: PreferenceField.RIGHTS_BODY_TITLE })}
            fullWidth
            name={fieldNames.preference.rights.bodyTitle.root}
            label="Display Text"
          />
        </FormRow>
      )
    case PreferenceField.RIGHTS_BODY_DESCRIPTION:
      return (
        <FormRow marginBottom={24}>
          <InterpolatedTextArea
            required={isFieldRequired({ field: PreferenceField.RIGHTS_BODY_DESCRIPTION })}
            label="Display Text"
            name={fieldNames.preference.rights.bodyDescription.root}
          />
        </FormRow>
      )
    case PreferenceField.RIGHTS_FOOTER_BUTTONS:
      return (
        <>
          <FormRow marginBottom={24}>
            <FormSwitch
              inlineLabel
              label="Enable reCAPTCHA Validation"
              name={fieldNames.preference.rights.recaptchaEnabled.root}
            />
          </FormRow>

          <FormRow marginBottom={24}>
            <FormInput
              required={isFieldRequired({ field: PreferenceField.RIGHTS_FOOTER_BUTTONS })}
              fullWidth
              name={fieldNames.preference.rights.buttonText.root}
              label="Submit Button Label"
            />
          </FormRow>
        </>
      )
    case PreferenceField.RIGHTS_REQUEST:
      return <RightsRequest organizationLanguages={organizationLanguages} />
    case PreferenceField.RIGHTS_FORM_SETTINGS:
      return <FormTemplates />
    default:
      return null
  }
}
