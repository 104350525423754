import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { useDeleteExperience } from 'api/experiences-v2/mutations/useDeleteExperience'
import { showToast } from 'components/modals/AlertComponent'
import { Button, PopUp } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  experience: ExperienceV2DTO
  onClose: () => void
  onDelete?: () => any
}

export const ExperienceDeleteModal: React.FC<Props> = ({ experience, onClose, onDelete = onClose }) => {
  const queryClient = useQueryClient()

  const { name, id } = experience
  const [isBusy, setIsBusy] = useState(false)

  const { mutate: handleDeleteExperience } = useDeleteExperience({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onDelete?.()
      showToast({ content: 'Experience deleted', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to delete experience', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <PopUp
      title="Delete Experience"
      popUpWidth="550px"
      variant="dialog"
      onClose={onClose}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onClose} pending={isBusy}>
            Close
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={() => {
              handleDeleteExperience({
                params: {
                  id: id || '',
                },
              })
            }}
            pending={isBusy}
          >
            Delete
          </Button>
        </>
      }
    >
      <Typography>
        Are you sure you want to delete <Typography variant="label">{name}</Typography> experience?
      </Typography>
    </PopUp>
  )
}
