import { DataSystemV2DTO } from '@ketch-com/figurehead'
import { Avatar, AvatarSize, Checkbox, TableCell, theme } from '@ketch-com/deck'

import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { FormikErrors } from 'formik'
import { Box, Typography } from '@mui/material'
import { Capabilites } from 'pages/dataSystems/components/Capabilities'

export const getDataSystemV2DTOColumns = ({
  values,
  setFieldValue,
}: {
  values: {
    selectedDataSystems: string[]
  }
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<{}>>
}): GridColDef<DataSystemV2DTO>[] => [
  {
    align: 'left',
    field: 'radio',
    headerAlign: 'left',
    headerName: '',
    sortable: false,
    flex: 1,
    maxWidth: 50,

    renderCell: ({ row: dataSystem }: GridRenderCellParams<DataSystemV2DTO>) => {
      const isChecked = dataSystem?.code ? values?.selectedDataSystems.some(elem => dataSystem.code === elem) : false

      const handleOnChange = () => {
        if (isChecked) {
          const newValues = values?.selectedDataSystems.filter(elem => dataSystem.code !== elem)
          setFieldValue(`selectedDataSystems`, newValues)
        } else {
          const newValues = [...values?.selectedDataSystems]
          newValues.push(dataSystem.code || '')
          setFieldValue(`selectedDataSystems`, newValues)
        }
      }

      return (
        <TableCell>
          <Checkbox checked={isChecked} value={isChecked} onChange={handleOnChange} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'name',
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    flex: 1,

    renderCell: ({ row: dataSystem }: GridRenderCellParams<DataSystemV2DTO>) => {
      return (
        <TableCell>
          <Box display="flex" alignItems="center" gap={1}>
            {dataSystem?.logoUrl && (
              <Box>
                <Avatar
                  isLogo
                  src={dataSystem?.logoUrl}
                  alt={dataSystem?.name}
                  size={AvatarSize.xlarge}
                  variant="rounded"
                  backgroundColor={theme.palette.tertiary.main}
                />
              </Box>
            )}
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography component="div">{dataSystem?.name!}</Typography>
              <Typography component="div" variant="footnote">
                {dataSystem?.appCode}
              </Typography>
            </Box>
          </Box>
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'consent',
    headerAlign: 'left',
    headerName: 'Capabilities',
    sortable: false,
    flex: 1,

    renderCell: ({ row: dataSystem }: GridRenderCellParams<DataSystemV2DTO>) => {
      return (
        <TableCell>
          <Capabilites supportedCapabilites={dataSystem.supportedCapabilities} />
        </TableCell>
      )
    },
  },
]
