import React from 'react'
import { showToast } from 'components/modals/AlertComponent'
import { useParams } from 'react-router-dom'
import { useLegalBases } from 'api/legalBases/queries/useLegalBases'
import { ViewSubscriptionControl } from 'pages/policyCenter/subscriptions/subscriptionControl/view/ViewSubscriptionControl'
import { UrlParams } from 'pages/assetManager/dsrConfig/interfaces'
import { useSubscriptionControl } from 'api/subscriptions/queries/useSubscriptionControl'
import { SubscriptionControlDTO } from '@ketch-com/figurehead'
import { useOrganizationLanguagesPaginated } from 'api/languages/queries/useOrganizationLanguagesPaginated'
import { useSubscriptionControlOrchestrations } from 'api/subscriptions/queries/useSubscriptionControlOrchestrations'

export const ViewSubscriptionControlContainer: React.FC = () => {
  const { code } = useParams<UrlParams>()
  const { data: legalBases, isLoading: isLegalBasesLoading } = useLegalBases({
    onError: () => {
      showToast({ content: 'Failed to fetch legal basis', type: 'error' })
    },
  })

  const { data: subscriptionControl, isLoading: isSubscriptionControlLoading } = useSubscriptionControl({
    params: { code, includeMetadata: true },
    onError: () => {
      showToast({ content: 'Failed to fetch subscriptions', type: 'error' })
    },
  })

  const { data: organizationLanguages, isLoading: isOrganizationLanguagesLoading } = useOrganizationLanguagesPaginated({
    params: {
      includeMetadata: true,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch organization languages', type: 'error' })
    },
  })

  const { data: subscriptionControlOrchestrations, isLoading: isSubscriptionControlOrchestrationsLoading } =
    useSubscriptionControlOrchestrations({
      params: { code },
      onError: () => {
        showToast({ content: 'Failed to fetch subscription orchestrations', type: 'error' })
      },
    })

  const isReady =
    !isLegalBasesLoading &&
    !isSubscriptionControlLoading &&
    !isOrganizationLanguagesLoading &&
    !isSubscriptionControlOrchestrationsLoading

  return (
    <ViewSubscriptionControl
      isReady={isReady}
      legalBases={legalBases}
      organizationLanguages={organizationLanguages}
      subscriptionControl={subscriptionControl?.control || ({} as SubscriptionControlDTO)}
      subscriptionControlOrchestrations={subscriptionControlOrchestrations || {}}
    />
  )
}
