import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  formData: { ReturnURL: string }
}>

export const createOrganizationEditPortalUrl = ({ formData }: Params) =>
  API.post<any>(
    formatRequestString({
      entityUrl: `/api/provision/portal`,
    }),
    formData,
  )
