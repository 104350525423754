import React, { useState } from 'react'
import { styled } from '@mui/material/styles'

import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { DeletePolicyModal } from './DeletePolicyModal'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

const PREFIX = 'ActionsCellRenderer'

const classes = {
  buttonOptions: `${PREFIX}-buttonOptions`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.buttonOptions}`]: {
    background: 'transparent',
  },
}))

type Props = {
  policyId: string
}

export const ActionsCellRenderer: React.FC<Props> = ({ policyId }) => {
  const [isDeletePolicyModalOpen, setIsDeletePolicyModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()

  return (
    <Root>
      <ButtonOptions
        id={`${policyId}-policyTableItemActionButtonId`}
        className={classes.buttonOptions}
        size="regular"
        items={[
          {
            testId: `edit-policy-action-${policyId}`,
            content: 'Edit',
            icon: <EditOutlined />,
            onClick: () => {
              navigate(RoutesManager.policyCenter.policies.upsert.getURL({ id: policyId }))
            },
          },
          {
            testId: `${policyId}-deletePolicyAction`,
            content: 'Delete',
            icon: <DeleteOutline />,
            onClick: () => setIsDeletePolicyModalOpen(true),
          },
        ]}
      />
      {isDeletePolicyModalOpen && (
        <DeletePolicyModal
          policyId={policyId}
          onCancel={() => setIsDeletePolicyModalOpen(false)}
          onSubmit={async () => {
            setIsDeletePolicyModalOpen(false)
          }}
        />
      )}
    </Root>
  )
}
