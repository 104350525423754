import { AppCode, TagListState } from './enums'

export const appCodeLabels: { [TagListState: string]: string } = {
  [AppCode.GoogleTagManager]: 'Google Tag Manager',
  [AppCode.AdobeExperiencePlatform]: 'Adobe Experience Platform',
}

export const tagListStateLabels: { [TagListState: string]: string } = {
  [TagListState.All]: 'All',
  [TagListState.Active]: 'Active',
  [TagListState.Paused]: 'Paused',
}
