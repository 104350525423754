import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'

import { IdentitySpaceCellRenderer } from './components'
import { AuthenticatorDTO, FormFieldDTO, VariableClaimMappingDTO } from '@ketch-com/figurehead'
import { Button, DataGrid, InfoRow, PopUp, Spinner, TableCell } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import {
  VariableClaimMappingDestination,
  VariableClaimMappingDestinationNames,
} from 'pages/developers/authentication/upsert/constants'
import { useFormFields } from 'api/formFields/queries/useFormFields'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'

type Props = {
  authenticator?: AuthenticatorDTO
  onCancel?: () => void
  shouldHideAssetsAssignmentTab?: boolean
}

export const AuthenticatorOverviewModal: React.FC<Props> = ({ onCancel, authenticator }) => {
  const [isShowClientSecret, setIsShowClientSecret] = useState<boolean>(false)
  const { data: allFormFields, isLoading: isLoadingAllFormFields } = useFormFields({
    itemsPerPage: 1000,
    params: {
      includeMetadata: true,
    },
  })

  return (
    <PopUp
      variant="modal"
      title={authenticator?.name}
      popUpWidth="774px"
      popUpActionChildren={
        <Button color="secondary" size="large" onClick={onCancel}>
          Close
        </Button>
      }
      onClose={onCancel}
    >
      {isLoadingAllFormFields ? (
        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gap={6}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="h4">Details</Typography>
            <InfoRow title="Name">
              <Typography>{authenticator?.name}</Typography>
            </InfoRow>
            <InfoRow title="Type">
              <Typography variant="label">{authenticator?.type === 1 ? 'OAuth 2.0' : 'Form Post'}</Typography>
            </InfoRow>
            <InfoRow title="Access Token URL">
              <Typography>{authenticator?.accessTokenUrl}</Typography>
            </InfoRow>
            <InfoRow title="Auth URL">
              <Typography>{authenticator?.authUrl}</Typography>
            </InfoRow>
            <InfoRow title="Client ID">
              <Typography>{authenticator?.clientId}</Typography>
            </InfoRow>
            <InfoRow title="Client Secret">
              <Box display="flex" alignItems="center" gap={1}>
                <Typography>{isShowClientSecret ? authenticator?.clientSecret : '•••••••••'}</Typography>
                <Button color="tertiary" onClick={() => setIsShowClientSecret(b => !b)}>
                  {isShowClientSecret ? 'Hide' : 'View'}
                </Button>
              </Box>
            </InfoRow>
            <InfoRow title="Redirect URL">
              {authenticator?.redirectUrl ? (
                <Typography>{authenticator?.redirectUrl}</Typography>
              ) : (
                <EmptyValueRenderer />
              )}
            </InfoRow>
            <InfoRow title="Scopes">
              {authenticator?.scopes ? <Typography>{authenticator?.scopes}</Typography> : <EmptyValueRenderer />}
            </InfoRow>
          </Box>
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="h4">Variable Mapping</Typography>
            <DataGrid
              sx={{
                cursor: 'pointer',
                '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                  backgroundImage: 'unset',
                  cursor: 'auto',
                },
              }}
              autosizeOnMount
              autosizeOptions={{
                expand: true,
                includeHeaders: true,
                includeOutliers: false,
              }}
              getRowHeight={() => 'auto'}
              columns={transpondersListColumns({ allFormFields })}
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              disableRowSelectionOnClick
              disableRowHoverStates
              getRowId={row => row.sourceKey}
              rows={authenticator?.variableClaimMappings || []}
              hideFooter
              noRowsOverlayProps={{
                buttonTitle: '',
              }}
            />
          </Box>
        </Box>
      )}
    </PopUp>
  )
}

export const transpondersListColumns = ({
  allFormFields,
}: {
  allFormFields: FormFieldDTO[]
}): GridColDef<VariableClaimMappingDTO>[] => [
  {
    align: 'left',
    field: 'variables',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Variables',
    sortable: false,
    renderCell: ({ row: { sourceKey } }: GridRenderCellParams<VariableClaimMappingDTO>) => {
      return <TableCell title={sourceKey} />
    },
  },
  {
    align: 'left',
    field: 'type',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Type',
    sortable: false,
    renderCell: ({ row: { destination } }: GridRenderCellParams<VariableClaimMappingDTO>) => {
      return (
        <TableCell
          title={VariableClaimMappingDestinationNames[destination as keyof typeof VariableClaimMappingDestinationNames]}
        />
      )
    },
  },
  {
    align: 'left',
    field: 'options',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Options',
    sortable: false,
    renderCell: ({ row: { destination, destinationKey } }: GridRenderCellParams<VariableClaimMappingDTO>) => {
      const cellRenderItem =
        destination === VariableClaimMappingDestination.IDENTITY_SPACE ? (
          <IdentitySpaceCellRenderer identitySpaceCode={String(destinationKey) || ''} />
        ) : destination === VariableClaimMappingDestination.DATA_SUBJECT ? (
          <Typography>{allFormFields?.find(formField => formField.code === destinationKey)?.name}</Typography>
        ) : (
          <Typography>{destinationKey}</Typography>
        )

      return <TableCell>{cellRenderItem}</TableCell>
    },
  },
]
