import { Box, Typography } from '@mui/material'
import React from 'react'

type Props = {
  /** HTML rich text string */
  text?: string
  /** Text color */
  textColor?: string
  /** Font size */
  fontSize?: number | string
  /** Font weight */
  fontWeight?: number
  /** Link text color */
  linkColor?: string
  /** Line height */
  lineHeight?: string
  /** Should links be underlined */
  underlineLinks?: boolean
  /** Should text be centered */
  center?: boolean
}

export const RichTextRenderer: React.FC<Props> = ({
  text,
  textColor = 'darkDusk.main',
  fontSize = 14,
  fontWeight = 400,
  lineHeight = '18.85px !important',
  linkColor = 'darkDusk.main',
  underlineLinks = false,
  center = false,
}) => {
  return (
    <Typography
      component={Box}
      variant="body"
      sx={{
        fontSize: fontSize,
        fontWeight: fontWeight,
        lineHeight: lineHeight,
        letterSpacing: '-0.13px',
        margin: '0 !important',
        padding: 0,
        textAlign: center ? 'center' : '',

        '& span': {
          color: `${textColor} !important`,
        },

        '& p': {
          margin: 0,
          padding: 0,
          color: `${textColor} !important`,
        },
        '& ul': {
          margin: 0,
          color: `${textColor} !important`,
        },
        '& a': {
          color: `${linkColor} !important`,
          textDecoration: underlineLinks ? 'underline' : 'none',
        },
        mb: 3,
      }}
      dangerouslySetInnerHTML={{
        __html: text || '',
      }}
    />
  )
}
