export const getResourceType = (
  resourceTypeString: string = '',
  toSentenceCase: boolean = false,
  canonical: boolean = false,
) => {
  const characterToSplitOn = canonical ? '_' : '.'
  const resourceType = resourceTypeString.split(characterToSplitOn).pop() || ''

  if (toSentenceCase && resourceType) {
    return resourceType.charAt(0).toUpperCase() + resourceType.slice(1).toLowerCase()
  }

  return resourceType
}
