import React from 'react'

import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { DropListButton, DropListButtonProps } from '@ketch-com/deck'
import { useTranslation } from 'react-i18next'
import { COMMON, RIGHTS_STATISTICS } from 'i18n/types'
import { MenuItem } from './MenuItem'

interface Props
  extends Omit<
    DropListButtonProps<{ label?: string; value?: string }, boolean, boolean, boolean>,
    'onChange' | 'options'
  > {
  onChange: (value?: string) => void
}

export const PolicyScopesDropList: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation([RIGHTS_STATISTICS, COMMON])
  const { data, isLoading } = usePolicyScopes()
  const options =
    data?.map(item => ({
      label: item?.name || item?.code,
      value: item?.code,
    })) || []

  return (
    <DropListButton
      renderOption={(props, option, { selected }) => <MenuItem {...props} option={option} selected={selected} />}
      onChange={(_, item) => onChange(item?.value)}
      options={options}
      loading={isLoading}
      size="small"
      placeholder={isLoading ? t('Loading..', { ns: 'common' })! : t('All Jurisdictions')!}
    />
  )
}
