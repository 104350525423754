import React from 'react'
import { groupBy } from 'lodash'

// api
import { Pagination } from 'api/common/paginatedQuery'

// components
import Box from '@mui/material/Box'
import { EventsListTable } from './EventsListTable'
import { Text } from 'components/ui-kit/typography/Text'

// types
import { EventsByType } from 'interfaces/events'
import { RightsModalType } from 'interfaces/rights/right'
import { WorkflowDTO } from 'interfaces/workflows'

type Props = {
  setRightsModal: (rightsModal: RightsModalType) => void
  eventsByRightType: EventsByType[]
  hasDataSubjectFilter: boolean
  isLoading: boolean
  pagination: Pagination
  workflows: WorkflowDTO[]
}

export const EventsByRightCode: React.FC<Props> = ({
  setRightsModal,
  eventsByRightType,
  hasDataSubjectFilter,
  isLoading,
  pagination,
  workflows,
}) => (
  <Box my={4}>
    {!!eventsByRightType.length &&
      eventsByRightType.map(({ events, title, type }) => {
        const eventsByRightName = Object.entries(groupBy(events, event => event?.rightName))
        return (
          !!eventsByRightName.length && (
            <Box key={title}>
              <Box>
                <Text color={'darkDuskFaded'} lineHeight={2} size={28} weight={800}>
                  {title}
                </Text>
              </Box>
              {eventsByRightName.map(([name, evts]) => {
                return (
                  <EventsListTable
                    events={evts}
                    hasDataSubjectFilter={hasDataSubjectFilter}
                    isLoading={isLoading}
                    key={name}
                    pagination={pagination}
                    setRightsModal={setRightsModal}
                    title={name}
                    type={type}
                    workflows={workflows}
                  />
                )
              })}
            </Box>
          )
        )
      })}
  </Box>
)
