import { FC } from 'react'
import { Button, PopUp } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { OrgPlanNames } from 'components/planAndBilling/common/constants/planAndBilling'

type FreeDowngradeResolveBillingModalProps = {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onSubmit: () => void
}

export const FreeDowngradeResolveBillingModal: FC<FreeDowngradeResolveBillingModalProps> = ({
  isOpen,
  isLoading,
  onClose,
  onSubmit,
}) => {
  return (
    <PopUp
      title="Resolve Billing Issues"
      variant="modal"
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      popUpWidth="560px"
      popUpActionChildren={
        <>
          <Button color="tertiary" size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button color="error" size="large" onClick={onSubmit} disabled={isLoading}>
            Update Billing Details
          </Button>
        </>
      }
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="body">
          Before you are able to downgrade to "<Typography variant="label">{OrgPlanNames.free}</Typography>" plan you
          must resolve your current outstanding billing issues.
        </Typography>
        <Typography variant="body">Click below to update your billing information.</Typography>
      </Box>
    </PopUp>
  )
}
