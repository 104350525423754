import { FC, useState, useContext } from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { FieldArray, useFormikContext } from 'formik'

import { fieldNames } from 'pages/consentAndRights/experiences/upsert/utils/common'
import { PreferenceExperienceFormValues } from 'pages/consentAndRights/experiences/upsert/interfaces/PreferenceExperienceFormValues'
import { VerificationOption } from 'pages/consentAndRights/experiences/upsert/components/UpsertPreference/components'
import { AddRightRow, FormTemplate, FormTemplatesDropdown } from './components'
import Typography from '@mui/material/Typography'
import { updateTemplateId } from 'pages/consentAndRights/experiences/upsert/components/UpsertPreference/components/Sidebar/components/RightsFields/components/FormTemplates/utils/updateTemplateId'
import { Link } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ensureArray } from 'utils/helpers/array'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import { transformJurisdictionCanonicalRightsToExperienceCanonicalRightFormTemplates } from 'pages/consentAndRights/experiences/upsert/utils/preference/utils'

export const reorderDropdownOptions = <T,>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const PREFIX = 'PreferenceRightsPersonalDetails'

const classes = {
  tabs: `${PREFIX}-tabs`,
  addIcon: `${PREFIX}-addIcon`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.tabs}`]: {
    borderBottom: `1px solid ${palette.iron.main}`,
    width: '100%',
  },

  [`& .${classes.addIcon}`]: {
    fill: palette.royalBlue.main,
    fontSize: 20,
  },
}))

export const FormTemplates: FC = () => {
  const { setFieldValue, setFieldTouched, values, setValues } = useFormikContext<PreferenceExperienceFormValues>()
  const [selectedRightCodeForAddition, setSelectedRightCodeForAddition] = useState<string>('')
  const { rights: rightsFormikValues } = values
  const { canonicalRights, formTemplates } = useContext(ExperienceUpsertContext)

  const defaultFormTemplateId = formTemplates?.find(template => template?.code === 'default')?.id

  return (
    <StyledBox display="flex" flexDirection="column" mb={3}>
      <Box mb={4}>
        <Typography variant="body">
          You can create and manage Forms in Experience Server &#8594;{' '}
          <Link style={{ all: 'unset' }} to={RoutesManager.deployment.forms.root.getURL()}>
            <Typography
              variant="body"
              sx={{
                cursor: 'pointer',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
            >
              Forms
            </Typography>
          </Link>
        </Typography>
      </Box>

      <VerificationOption
        shouldHide={false}
        title="Single form for all rights"
        value={rightsFormikValues.hasSingleFormTemplate}
        onChange={e => {
          setFieldValue(fieldNames.preference.rights.hasSingleFormTemplate.root, e.target.checked)
          if (!e.target.checked) {
            setFieldValue(fieldNames.preference.rights.singleFormTemplateID.root, '')
          } else {
            setFieldValue(fieldNames.preference.rights.singleFormTemplateID.root, defaultFormTemplateId)
          }
          setFieldValue(
            fieldNames.preference.rights.canonicalRightFormTemplates.root,
            transformJurisdictionCanonicalRightsToExperienceCanonicalRightFormTemplates(ensureArray(canonicalRights)),
          )
          setFieldTouched(fieldNames.preference.rights.hasSingleFormTemplate.root, true)
        }}
        requisiteForm={
          <Box mt={2}>
            <Typography variant="label">Form Template</Typography>
            <FormTemplatesDropdown
              errorMessageName={fieldNames.preference.rights.singleFormTemplateID.root}
              onClick={selectedTemplate => {
                if (values.rights.hasSingleFormTemplate) {
                  const updatedTemplates = ensureArray(values?.rights?.canonicalRightFormTemplates).map(template => ({
                    ...template,
                    formTemplateID: selectedTemplate?.id,
                  }))

                  // Set the new template id for singleFormTemplateID and all canonical rights
                  setValues({
                    ...values,
                    rights: {
                      ...values.rights,
                      singleFormTemplateID: selectedTemplate?.id || '',
                      canonicalRightFormTemplates: updatedTemplates,
                    },
                  })
                }
              }}
              templateId={values?.rights?.singleFormTemplateID}
            />
          </Box>
        }
      />

      {!rightsFormikValues.hasSingleFormTemplate && (
        <Box sx={{ borderTop: theme => `1px solid ${theme.palette.iron.main}` }} mt={2}>
          <Box mt={2}>
            <Typography variant="body">Please specify which form to present for each right.</Typography>
          </Box>

          <Box mt={4}>
            <Box mb={2}>
              <Typography variant="h4">Canonical Rights</Typography>
            </Box>
            <FieldArray
              name={fieldNames.preference.rights.canonicalRightFormTemplates.root}
              render={() => (
                <Box>
                  {ensureArray(rightsFormikValues?.canonicalRightFormTemplates).map((template, templateIndex) => {
                    return (
                      <FormTemplate
                        index={templateIndex}
                        key={template?.code}
                        onClick={selectedTemplate => {
                          setFieldValue(
                            `${fieldNames.preference.rights.canonicalRightFormTemplates.root}[${templateIndex}].formTemplateID`,
                            selectedTemplate?.id,
                          )
                        }}
                        template={template}
                      />
                    )
                  })}
                </Box>
              )}
            />
          </Box>

          <Box>
            <Box mb={2}>
              <Typography variant="h4">Custom Rights</Typography>
            </Box>

            <Box mb={2}>
              <Typography variant="body">
                To override a canonical right mapping, create a custom right association below.
              </Typography>
            </Box>

            <FieldArray
              name={fieldNames.preference.rights.customRightFormTemplates.root}
              render={({ remove, push }) => (
                <Box>
                  {ensureArray(rightsFormikValues?.customRightFormTemplates).map((template, index) => {
                    return (
                      <FormTemplate
                        key={template?.code}
                        index={index}
                        onClick={selectedTemplate => {
                          const updatedTemplates = updateTemplateId(
                            values.rights.customRightFormTemplates,
                            template?.code,
                            selectedTemplate?.id,
                          )

                          // Set the new array values
                          setFieldValue(fieldNames.preference.rights.customRightFormTemplates.root, updatedTemplates)
                        }}
                        remove={remove}
                        template={template}
                      />
                    )
                  })}

                  <AddRightRow
                    key="addRight"
                    push={push}
                    selectedRightCodeForAddition={selectedRightCodeForAddition}
                    setSelectedRightCodeForAddition={setSelectedRightCodeForAddition}
                  />
                </Box>
              )}
            />
          </Box>
        </Box>
      )}
    </StyledBox>
  )
}
