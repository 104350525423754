import * as yup from 'yup'

export const UPDATE_VALUE_VALIDATION_SCHEMAS = {
  STRING: yup.string().max(255, 'Max 255 chars'),
  INTEGER: yup.number().integer().typeError('Must match type'),
  BOOLEAN: yup.boolean().typeError('Must match type'),
  DECIMAL: yup.number().typeError('Must match type'),
  INT: yup.number().typeError('Must match type'),
  INT_8: yup.number().typeError('Must match type'),
  INT_16: yup.number().typeError('Must match type'),
  INT_32: yup.number().typeError('Must match type'),
  INT_64: yup.number().typeError('Must match type'),
  U_INT: yup.number().typeError('Must match type'),
  U_INT_8: yup.number().typeError('Must match type'),
  U_INT_16: yup.number().typeError('Must match type'),
  U_INT_32: yup.number().typeError('Must match type'),
  U_INT_64: yup.number().typeError('Must match type'),
}
