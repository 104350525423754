import React from 'react'
import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { SubscriptionTopicFormData, UrlParams } from 'pages/policyCenter/subscriptions/interfaces'
import { formatCodeFromName } from 'utils/formatters'
import { FormMode } from 'interfaces/formModes/formMode'
import { Translations } from 'pages/policyCenter/subscriptions/subscriptionTopic/upsert/components'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { FormInput } from 'components/form/FormInput'
import { FormRow } from '@ketch-com/deck'

type Props = {
  organizationLanguages: LanguageWithStatusDTO[]
}

export const BasicDetails: React.FC<Props> = ({ organizationLanguages }) => {
  const { setFieldTouched, setFieldValue, isSubmitting } = useFormikContext<SubscriptionTopicFormData>()
  const { formMode } = useParams<UrlParams>()
  const isEditMode = formMode === FormMode.EDIT
  return (
    <FormRow title="Basic Details" subTitle="Add a title and description that will be shown to end-users.">
      <Box width={340}>
        <FormInput
          shouldUpdateDebounced
          fullWidth
          required
          formPropertyName="basicDetails.name"
          label="Display Name"
          disabled={isSubmitting}
          onChange={e => {
            if (!isEditMode) {
              setFieldTouched('basicDetails.code', true)
              setFieldValue('basicDetails.code', formatCodeFromName(e.target.value))
            }
          }}
        />
      </Box>
      <Box width={340}>
        <FormInput
          fullWidth
          required
          formPropertyName="basicDetails.code"
          label="Code"
          disabled={isEditMode || isSubmitting}
        />
      </Box>
      <Box width={830}>
        <FormInput
          multiline
          fullWidth
          formPropertyName="basicDetails.description"
          label="Display Description"
          disabled={isSubmitting}
          rows={4}
        />
      </Box>
      {organizationLanguages?.length ? (
        <Box>
          <Translations organizationLanguages={organizationLanguages} />
        </Box>
      ) : null}
    </FormRow>
  )
}
