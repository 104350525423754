export enum LookUpTab {
  DIRECT = 'direct_lookup',
  NO_DIRECT = 'no_direct_lookup',
  CUSTOM_SQL = 'custom_sql',
}

export const SCRIPT_TYPE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
} as const
