import { Descendant } from 'slate'
import { LinkElement, ParagraphElement } from '../../../components/common/interpolatedTextArea/interfaces'

export const deserialize = (text: string): Descendant[] => {
  const payload = text
    .split('\n')
    .map(str => {
      const inlineNodes: Descendant[] = []
      const links = str.match(/\[(.*?)\)/gm) || []

      if (links.length) {
        let cursorPosition = 0

        links?.forEach?.(link => {
          const linkStart = str.indexOf(link)
          const textBefore = str.substring(cursorPosition, linkStart)

          const [linkTextWrapped] = link.match(/\[(.*?)\]/gm)!
          const [linkUrlWrapped] = link.match(/\((.*?)\)/gm)!

          const linkText = linkTextWrapped.replace(/\[|\]/gm, '')
          const linkUrl = linkUrlWrapped.replace(/\(|\)/gm, '')

          inlineNodes.push({ text: textBefore })

          inlineNodes.push({
            type: 'link',
            url: linkUrl,
            children: [{ text: linkText }],
          } as LinkElement)

          cursorPosition = linkStart + link.length
        })

        if (cursorPosition < str.length) {
          const textAfter = str.substr(cursorPosition)
          inlineNodes.push({ text: textAfter })
        }
      } else {
        inlineNodes.push({ text: str })
      }

      return inlineNodes
    })
    .map(
      children =>
        ({
          type: 'paragraph' as const,
          children,
        }) as ParagraphElement,
    )

  return payload
}
