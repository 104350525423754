import React from 'react'

import Typography from '@mui/material/Typography'
import { FormFieldDTO } from '@ketch-com/figurehead'
import { FormFieldModalUsedInSection } from '.'
import { Button, PopUp } from '@ketch-com/deck'

type Props = {
  onCancel: () => void
  formField?: FormFieldDTO
  message: string
}
export const FormFieldUsedInTemplatesListModal: React.FC<Props> = ({ onCancel, formField, message }) => {
  return (
    <PopUp
      title="Usage"
      variant="modal"
      onClose={onCancel}
      popUpWidth="684px"
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Close
          </Button>
        </>
      }
    >
      <Typography variant="body" component="div">
        {message}
      </Typography>

      <FormFieldModalUsedInSection formField={formField} />
    </PopUp>
  )
}
