import { APIListRequestParams } from 'api/common/utils'
import { GetScenarioBasedExecutionResultsResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  start?: number
  limit?: number
  query?: string | null | undefined
  executionId?: string | null | undefined
  stepId?: string | null | undefined
  status?: string | null | undefined
  activityStepCode?: string
}>

export const fetchExecutionsPaginated = ({ start, limit, executionId, stepId, query, activityStepCode }: Params) =>
  API.get<GetScenarioBasedExecutionResultsResponseBodyDTO>(
    formatRequestString({
      entityUrl:
        activityStepCode === 'dsrTransponder'
          ? `/api/executions/${executionId}/steps/${stepId}/transponder-results`
          : `/api/executions/${executionId}/steps/${stepId}/scenario-results`,
      params: {
        filters: {
          start,
          limit,
          ...(query && {
            query,
          }),
        },
      },
    }),
  )
