import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { UpdateRightInvocationBody } from '@ketch-com/wheelhouse/dist/rights/rightInvocations.schemas'

type Params = {
  requestId: string
  formData: UpdateRightInvocationBody
}

export const updateRightInvocation = ({ requestId, formData }: Params) =>
  API.put<{}>(
    formatRequestString({
      entityUrl: `/api/v2/right-invocations/${requestId}`,
    }),
    formData,
  )
