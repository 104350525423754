import { SxProps, Theme } from '@mui/material'
import { MaybeNull } from 'interfaces'

export type GetSXPropsArgs = {
  value: MaybeNull<any>
  isValid?: boolean
  isTouched?: boolean
  isHeliotrope?: boolean
}

export const getAutocompleteInputCellRendererSxProps = ({
  value,
  isTouched,
  isValid,
  isHeliotrope = false,
}: GetSXPropsArgs): SxProps<Theme> => {
  return {
    letterSpacing: '-0.01em',
    textTransform: 'none',
    fontWeight: 600,
    fontFamily: ({ typography }) => typography.fontFamily,
    fontSize: ({ typography }) => typography.pxToRem(14),
    borderRadius: 1.25,
    py: 0.75,
    color: ({ palette }) =>
      value ? palette?.[`${isHeliotrope ? 'heliotrope' : 'darkDusk'}`].main : palette.darkDuskFaded.main,
    backgroundColor: ({ palette }) => palette.fadedGrey.main,
    border: ({ palette }) =>
      !isValid && isTouched ? `1px solid ${palette.chileanFire.main}` : `1px solid ${palette.iron.main}`,
    '&:hover': {
      backgroundColor: ({ palette }) => palette.fadedGrey.main,
      color: ({ palette }) => palette.royalBlue.main,
    },
    '&.MuiButton-sizeSmall': {
      minWidth: '60px',
      py: 0.125,
    },
  }
}
