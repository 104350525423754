import { faker } from '@faker-js/faker'

export type OverAccessData = {
  id?: string
  name?: string
  riskScore?: number
  riskCategory?: string
  riskTrend?: RiskTrend[]
}

export type OverAccess = {
  overAccess?: OverAccessData[]
  totalResults?: number
}

type RiskTrend = {
  month?: string
  riskScore?: number
}

const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const generateOverAccess = (number: number): OverAccess => {
  const overAccess: OverAccessData[] = []

  for (let i = 0; i < number; i++) {
    const riskScore = faker.datatype.number({ min: 10, max: 100 })
    let riskCategory: string = 'Extreme'
    if (riskScore < 30) riskCategory = 'Acceptable'
    if (riskScore < 60) riskCategory = 'Tolerable'
    if (riskScore < 80) riskCategory = 'Critical'

    const now = faker.datatype.number({ min: 0, max: 11 })
    const riskTrend: RiskTrend[] = []
    for (let j = 0; j <= now; j++) {
      riskTrend.push({
        month: month[j],
        riskScore: j === now ? riskScore : faker.datatype.number({ min: 10, max: riskScore }),
      })
    }

    overAccess.push({
      id: faker.datatype.uuid(),
      name: `${faker.name.firstName()} ${faker.name.lastName()}`,
      riskScore,
      riskCategory,
      riskTrend,
    })
  }
  return {
    overAccess,
    totalResults: number,
  }
}

// Only generate once so that refreshing the page works
export const overAccessMockData = {
  overAccess: [
    {
      id: '703d62de-2c6d-490b-9e83-d5717bc8721f',
      name: 'Abe Windler',
      riskScore: 55,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 55,
        },
      ],
    },
    {
      id: '9707e560-1826-462d-838f-ca8e31095245',
      name: 'Clair Hilll',
      riskScore: 47,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 22,
        },
        {
          month: 'Feb',
          riskScore: 40,
        },
        {
          month: 'Mar',
          riskScore: 15,
        },
        {
          month: 'Apr',
          riskScore: 27,
        },
        {
          month: 'May',
          riskScore: 47,
        },
      ],
    },
    {
      id: '6d085b0d-9837-4048-a0ea-da7153575431',
      name: 'Alessia Hyatt',
      riskScore: 65,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 47,
        },
        {
          month: 'Feb',
          riskScore: 61,
        },
        {
          month: 'Mar',
          riskScore: 11,
        },
        {
          month: 'Apr',
          riskScore: 34,
        },
        {
          month: 'May',
          riskScore: 43,
        },
        {
          month: 'Jun',
          riskScore: 41,
        },
        {
          month: 'Jul',
          riskScore: 45,
        },
        {
          month: 'Aug',
          riskScore: 48,
        },
        {
          month: 'Sep',
          riskScore: 56,
        },
        {
          month: 'Oct',
          riskScore: 26,
        },
        {
          month: 'Nov',
          riskScore: 28,
        },
        {
          month: 'Dec',
          riskScore: 65,
        },
      ],
    },
    {
      id: 'a6f54ac8-3bec-453e-82ad-85d99b17dc22',
      name: 'Dorthy Doyle',
      riskScore: 65,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 10,
        },
        {
          month: 'Feb',
          riskScore: 34,
        },
        {
          month: 'Mar',
          riskScore: 13,
        },
        {
          month: 'Apr',
          riskScore: 63,
        },
        {
          month: 'May',
          riskScore: 17,
        },
        {
          month: 'Jun',
          riskScore: 57,
        },
        {
          month: 'Jul',
          riskScore: 64,
        },
        {
          month: 'Aug',
          riskScore: 15,
        },
        {
          month: 'Sep',
          riskScore: 43,
        },
        {
          month: 'Oct',
          riskScore: 27,
        },
        {
          month: 'Nov',
          riskScore: 65,
        },
      ],
    },
    {
      id: '7376949d-740f-4972-9cde-e718e651e543',
      name: 'Annabelle Keeling',
      riskScore: 14,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 11,
        },
        {
          month: 'Feb',
          riskScore: 14,
        },
        {
          month: 'Mar',
          riskScore: 13,
        },
        {
          month: 'Apr',
          riskScore: 13,
        },
        {
          month: 'May',
          riskScore: 10,
        },
        {
          month: 'Jun',
          riskScore: 14,
        },
        {
          month: 'Jul',
          riskScore: 14,
        },
        {
          month: 'Aug',
          riskScore: 14,
        },
        {
          month: 'Sep',
          riskScore: 14,
        },
      ],
    },
    {
      id: '6ee09e75-53cc-4967-89bc-54922aae7430',
      name: 'Kristina Hodkiewicz',
      riskScore: 42,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 19,
        },
        {
          month: 'Feb',
          riskScore: 19,
        },
        {
          month: 'Mar',
          riskScore: 17,
        },
        {
          month: 'Apr',
          riskScore: 42,
        },
        {
          month: 'May',
          riskScore: 10,
        },
        {
          month: 'Jun',
          riskScore: 31,
        },
        {
          month: 'Jul',
          riskScore: 40,
        },
        {
          month: 'Aug',
          riskScore: 42,
        },
      ],
    },
    {
      id: '598ed4f3-e3bd-44d5-8549-4ad0b683e7df',
      name: 'Marian Johnson',
      riskScore: 45,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 15,
        },
        {
          month: 'Feb',
          riskScore: 17,
        },
        {
          month: 'Mar',
          riskScore: 29,
        },
        {
          month: 'Apr',
          riskScore: 35,
        },
        {
          month: 'May',
          riskScore: 34,
        },
        {
          month: 'Jun',
          riskScore: 33,
        },
        {
          month: 'Jul',
          riskScore: 44,
        },
        {
          month: 'Aug',
          riskScore: 42,
        },
        {
          month: 'Sep',
          riskScore: 25,
        },
        {
          month: 'Oct',
          riskScore: 45,
        },
      ],
    },
    {
      id: 'ba00dafe-f2cd-452a-a9c5-691db22dc08b',
      name: 'Liam Lakin',
      riskScore: 55,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 46,
        },
        {
          month: 'Feb',
          riskScore: 19,
        },
        {
          month: 'Mar',
          riskScore: 54,
        },
        {
          month: 'Apr',
          riskScore: 30,
        },
        {
          month: 'May',
          riskScore: 32,
        },
        {
          month: 'Jun',
          riskScore: 17,
        },
        {
          month: 'Jul',
          riskScore: 44,
        },
        {
          month: 'Aug',
          riskScore: 54,
        },
        {
          month: 'Sep',
          riskScore: 51,
        },
        {
          month: 'Oct',
          riskScore: 29,
        },
        {
          month: 'Nov',
          riskScore: 55,
        },
      ],
    },
    {
      id: '65c30c56-1ea5-4052-a79f-53a728e54f4c',
      name: 'Eldridge Towne',
      riskScore: 61,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 31,
        },
        {
          month: 'Feb',
          riskScore: 11,
        },
        {
          month: 'Mar',
          riskScore: 61,
        },
      ],
    },
    {
      id: '2f520f5f-1198-4ebe-917f-b336937c5f32',
      name: 'Theresia Feil',
      riskScore: 94,
      riskCategory: 'Extreme',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 90,
        },
        {
          month: 'Feb',
          riskScore: 27,
        },
        {
          month: 'Mar',
          riskScore: 45,
        },
        {
          month: 'Apr',
          riskScore: 43,
        },
        {
          month: 'May',
          riskScore: 61,
        },
        {
          month: 'Jun',
          riskScore: 94,
        },
      ],
    },
    {
      id: '6ee31a2c-4524-4d6a-903a-e77e90bc1eee',
      name: 'Tom Hammes',
      riskScore: 50,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 19,
        },
        {
          month: 'Feb',
          riskScore: 28,
        },
        {
          month: 'Mar',
          riskScore: 22,
        },
        {
          month: 'Apr',
          riskScore: 37,
        },
        {
          month: 'May',
          riskScore: 29,
        },
        {
          month: 'Jun',
          riskScore: 50,
        },
      ],
    },
    {
      id: 'dda80e0c-f89b-4070-804f-fdbc8d4f07fd',
      name: 'Jed Beer',
      riskScore: 89,
      riskCategory: 'Extreme',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 89,
        },
        {
          month: 'Feb',
          riskScore: 27,
        },
        {
          month: 'Mar',
          riskScore: 84,
        },
        {
          month: 'Apr',
          riskScore: 36,
        },
        {
          month: 'May',
          riskScore: 89,
        },
        {
          month: 'Jun',
          riskScore: 57,
        },
        {
          month: 'Jul',
          riskScore: 78,
        },
        {
          month: 'Aug',
          riskScore: 26,
        },
        {
          month: 'Sep',
          riskScore: 44,
        },
        {
          month: 'Oct',
          riskScore: 63,
        },
        {
          month: 'Nov',
          riskScore: 89,
        },
      ],
    },
    {
      id: '8e3b15c1-7d13-442f-bbd9-78678a67fb7d',
      name: 'Ciara Stehr',
      riskScore: 88,
      riskCategory: 'Extreme',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 30,
        },
        {
          month: 'Feb',
          riskScore: 88,
        },
      ],
    },
    {
      id: '1454664a-6c83-473c-97dc-dd3dfe627114',
      name: 'Wallace Aufderhar',
      riskScore: 10,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 10,
        },
        {
          month: 'Feb',
          riskScore: 10,
        },
        {
          month: 'Mar',
          riskScore: 10,
        },
        {
          month: 'Apr',
          riskScore: 10,
        },
        {
          month: 'May',
          riskScore: 10,
        },
        {
          month: 'Jun',
          riskScore: 10,
        },
        {
          month: 'Jul',
          riskScore: 10,
        },
      ],
    },
    {
      id: '2652d21f-a108-454c-9d5e-dcde4e0c589c',
      name: 'Graciela Treutel',
      riskScore: 72,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 31,
        },
        {
          month: 'Feb',
          riskScore: 19,
        },
        {
          month: 'Mar',
          riskScore: 14,
        },
        {
          month: 'Apr',
          riskScore: 56,
        },
        {
          month: 'May',
          riskScore: 24,
        },
        {
          month: 'Jun',
          riskScore: 55,
        },
        {
          month: 'Jul',
          riskScore: 72,
        },
      ],
    },
    {
      id: '62515eb5-b5c8-4cfc-b792-e705804e24f3',
      name: 'Emery Walsh',
      riskScore: 81,
      riskCategory: 'Extreme',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 53,
        },
        {
          month: 'Feb',
          riskScore: 53,
        },
        {
          month: 'Mar',
          riskScore: 45,
        },
        {
          month: 'Apr',
          riskScore: 32,
        },
        {
          month: 'May',
          riskScore: 77,
        },
        {
          month: 'Jun',
          riskScore: 27,
        },
        {
          month: 'Jul',
          riskScore: 81,
        },
      ],
    },
    {
      id: 'bb01dcf7-d8d3-4505-b5aa-ab437ea78433',
      name: 'Armani Herzog',
      riskScore: 90,
      riskCategory: 'Extreme',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 15,
        },
        {
          month: 'Feb',
          riskScore: 86,
        },
        {
          month: 'Mar',
          riskScore: 33,
        },
        {
          month: 'Apr',
          riskScore: 19,
        },
        {
          month: 'May',
          riskScore: 90,
        },
      ],
    },
    {
      id: '17eb2309-6a8c-4e12-b627-c5ae04b352f7',
      name: 'Pearlie Block',
      riskScore: 87,
      riskCategory: 'Extreme',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 84,
        },
        {
          month: 'Feb',
          riskScore: 35,
        },
        {
          month: 'Mar',
          riskScore: 87,
        },
      ],
    },
    {
      id: 'ced7555e-6973-4ac1-b0fa-993441a1ca5d',
      name: 'Brandy Wilkinson',
      riskScore: 93,
      riskCategory: 'Extreme',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 69,
        },
        {
          month: 'Feb',
          riskScore: 25,
        },
        {
          month: 'Mar',
          riskScore: 30,
        },
        {
          month: 'Apr',
          riskScore: 21,
        },
        {
          month: 'May',
          riskScore: 61,
        },
        {
          month: 'Jun',
          riskScore: 48,
        },
        {
          month: 'Jul',
          riskScore: 10,
        },
        {
          month: 'Aug',
          riskScore: 19,
        },
        {
          month: 'Sep',
          riskScore: 13,
        },
        {
          month: 'Oct',
          riskScore: 73,
        },
        {
          month: 'Nov',
          riskScore: 11,
        },
        {
          month: 'Dec',
          riskScore: 93,
        },
      ],
    },
    {
      id: 'cfdd5730-206f-4770-8ea2-035bb2bc870f',
      name: 'Evelyn Hackett',
      riskScore: 20,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 15,
        },
        {
          month: 'Feb',
          riskScore: 16,
        },
        {
          month: 'Mar',
          riskScore: 20,
        },
      ],
    },
    {
      id: '1f8cfc97-a346-4732-9bf2-cbdac4b95de9',
      name: 'Lucienne Hoeger',
      riskScore: 35,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 21,
        },
        {
          month: 'Feb',
          riskScore: 19,
        },
        {
          month: 'Mar',
          riskScore: 10,
        },
        {
          month: 'Apr',
          riskScore: 13,
        },
        {
          month: 'May',
          riskScore: 34,
        },
        {
          month: 'Jun',
          riskScore: 30,
        },
        {
          month: 'Jul',
          riskScore: 15,
        },
        {
          month: 'Aug',
          riskScore: 27,
        },
        {
          month: 'Sep',
          riskScore: 27,
        },
        {
          month: 'Oct',
          riskScore: 29,
        },
        {
          month: 'Nov',
          riskScore: 35,
        },
      ],
    },
    {
      id: '27ab88a4-5e10-43c3-8108-262d22bae414',
      name: 'Sherman Schiller',
      riskScore: 25,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 24,
        },
        {
          month: 'Feb',
          riskScore: 22,
        },
        {
          month: 'Mar',
          riskScore: 17,
        },
        {
          month: 'Apr',
          riskScore: 10,
        },
        {
          month: 'May',
          riskScore: 16,
        },
        {
          month: 'Jun',
          riskScore: 11,
        },
        {
          month: 'Jul',
          riskScore: 14,
        },
        {
          month: 'Aug',
          riskScore: 15,
        },
        {
          month: 'Sep',
          riskScore: 10,
        },
        {
          month: 'Oct',
          riskScore: 25,
        },
      ],
    },
    {
      id: '563f8115-53c7-4c83-99ea-a69fd3c29dd9',
      name: 'Perry Kovacek',
      riskScore: 67,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 49,
        },
        {
          month: 'Feb',
          riskScore: 67,
        },
      ],
    },
    {
      id: '5b414c3d-14a9-4146-8d22-6d0abc7e7c81',
      name: 'Floyd Pagac',
      riskScore: 79,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 49,
        },
        {
          month: 'Feb',
          riskScore: 38,
        },
        {
          month: 'Mar',
          riskScore: 63,
        },
        {
          month: 'Apr',
          riskScore: 61,
        },
        {
          month: 'May',
          riskScore: 74,
        },
        {
          month: 'Jun',
          riskScore: 57,
        },
        {
          month: 'Jul',
          riskScore: 17,
        },
        {
          month: 'Aug',
          riskScore: 79,
        },
      ],
    },
    {
      id: '6d12ef8b-ef7c-427b-bce1-fa9f9c608457',
      name: 'Lisandro Emmerich',
      riskScore: 71,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 13,
        },
        {
          month: 'Feb',
          riskScore: 71,
        },
      ],
    },
    {
      id: '529be37e-d4c4-46d8-b116-e98f41b1fffc',
      name: 'Kyleigh Buckridge',
      riskScore: 38,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 38,
        },
      ],
    },
    {
      id: '80b4e201-28b4-4451-ab14-2c2bd14ad7cc',
      name: 'Amelia Brown',
      riskScore: 63,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 42,
        },
        {
          month: 'Feb',
          riskScore: 16,
        },
        {
          month: 'Mar',
          riskScore: 21,
        },
        {
          month: 'Apr',
          riskScore: 63,
        },
        {
          month: 'May',
          riskScore: 63,
        },
      ],
    },
    {
      id: 'add05d5f-6701-4b9b-837c-11727e8b3424',
      name: 'Leone Lemke',
      riskScore: 19,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 18,
        },
        {
          month: 'Feb',
          riskScore: 11,
        },
        {
          month: 'Mar',
          riskScore: 10,
        },
        {
          month: 'Apr',
          riskScore: 16,
        },
        {
          month: 'May',
          riskScore: 17,
        },
        {
          month: 'Jun',
          riskScore: 18,
        },
        {
          month: 'Jul',
          riskScore: 12,
        },
        {
          month: 'Aug',
          riskScore: 18,
        },
        {
          month: 'Sep',
          riskScore: 12,
        },
        {
          month: 'Oct',
          riskScore: 11,
        },
        {
          month: 'Nov',
          riskScore: 19,
        },
      ],
    },
    {
      id: '782b234a-ee5b-41da-99db-80a630656d0b',
      name: 'Sierra McCullough',
      riskScore: 35,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 22,
        },
        {
          month: 'Feb',
          riskScore: 17,
        },
        {
          month: 'Mar',
          riskScore: 12,
        },
        {
          month: 'Apr',
          riskScore: 32,
        },
        {
          month: 'May',
          riskScore: 17,
        },
        {
          month: 'Jun',
          riskScore: 24,
        },
        {
          month: 'Jul',
          riskScore: 32,
        },
        {
          month: 'Aug',
          riskScore: 10,
        },
        {
          month: 'Sep',
          riskScore: 25,
        },
        {
          month: 'Oct',
          riskScore: 22,
        },
        {
          month: 'Nov',
          riskScore: 34,
        },
        {
          month: 'Dec',
          riskScore: 35,
        },
      ],
    },
    {
      id: '590ec237-ef5e-4a4d-a2fa-dd49d8f45fc7',
      name: 'Ernestina Bruen',
      riskScore: 34,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 30,
        },
        {
          month: 'Feb',
          riskScore: 15,
        },
        {
          month: 'Mar',
          riskScore: 32,
        },
        {
          month: 'Apr',
          riskScore: 17,
        },
        {
          month: 'May',
          riskScore: 34,
        },
      ],
    },
    {
      id: '5b1a750e-da36-46c4-80f5-4953c89c789a',
      name: 'Vida Stamm',
      riskScore: 46,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 15,
        },
        {
          month: 'Feb',
          riskScore: 40,
        },
        {
          month: 'Mar',
          riskScore: 27,
        },
        {
          month: 'Apr',
          riskScore: 46,
        },
      ],
    },
    {
      id: '62b8cf4d-2b64-44ca-a31f-530b92c50276',
      name: 'Ayden Lubowitz',
      riskScore: 24,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 22,
        },
        {
          month: 'Feb',
          riskScore: 21,
        },
        {
          month: 'Mar',
          riskScore: 15,
        },
        {
          month: 'Apr',
          riskScore: 12,
        },
        {
          month: 'May',
          riskScore: 24,
        },
      ],
    },
    {
      id: '8b89b081-488a-454a-81a2-d2e1ca44976e',
      name: 'Evan Stokes',
      riskScore: 68,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 55,
        },
        {
          month: 'Feb',
          riskScore: 68,
        },
      ],
    },
    {
      id: 'd1f51681-594b-4797-9727-1da601d18e55',
      name: 'Serena Runolfsdottir',
      riskScore: 68,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 47,
        },
        {
          month: 'Feb',
          riskScore: 12,
        },
        {
          month: 'Mar',
          riskScore: 42,
        },
        {
          month: 'Apr',
          riskScore: 28,
        },
        {
          month: 'May',
          riskScore: 17,
        },
        {
          month: 'Jun',
          riskScore: 15,
        },
        {
          month: 'Jul',
          riskScore: 60,
        },
        {
          month: 'Aug',
          riskScore: 32,
        },
        {
          month: 'Sep',
          riskScore: 51,
        },
        {
          month: 'Oct',
          riskScore: 60,
        },
        {
          month: 'Nov',
          riskScore: 11,
        },
        {
          month: 'Dec',
          riskScore: 68,
        },
      ],
    },
    {
      id: '9f394281-95f7-43e2-9b70-46166a6e468a',
      name: 'Paige Stiedemann',
      riskScore: 72,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 18,
        },
        {
          month: 'Feb',
          riskScore: 72,
        },
      ],
    },
    {
      id: '4816ec7e-b8f4-4389-9127-f19a6fb73a45',
      name: 'Amya Miller',
      riskScore: 37,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 18,
        },
        {
          month: 'Feb',
          riskScore: 23,
        },
        {
          month: 'Mar',
          riskScore: 37,
        },
      ],
    },
    {
      id: '1fb57a91-42ca-4f67-982b-3f903f6f8ed5',
      name: 'Alexzander Schmeler',
      riskScore: 32,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 11,
        },
        {
          month: 'Feb',
          riskScore: 28,
        },
        {
          month: 'Mar',
          riskScore: 11,
        },
        {
          month: 'Apr',
          riskScore: 20,
        },
        {
          month: 'May',
          riskScore: 17,
        },
        {
          month: 'Jun',
          riskScore: 31,
        },
        {
          month: 'Jul',
          riskScore: 12,
        },
        {
          month: 'Aug',
          riskScore: 13,
        },
        {
          month: 'Sep',
          riskScore: 20,
        },
        {
          month: 'Oct',
          riskScore: 15,
        },
        {
          month: 'Nov',
          riskScore: 32,
        },
      ],
    },
    {
      id: '62dde5d1-7f36-4d41-b5cf-a0027f978e0e',
      name: 'Antonette Glover',
      riskScore: 48,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 35,
        },
        {
          month: 'Feb',
          riskScore: 27,
        },
        {
          month: 'Mar',
          riskScore: 22,
        },
        {
          month: 'Apr',
          riskScore: 39,
        },
        {
          month: 'May',
          riskScore: 48,
        },
      ],
    },
    {
      id: '41adae89-0e5a-456e-aab7-3a9aea314290',
      name: 'Delilah Runolfsdottir',
      riskScore: 48,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 48,
        },
      ],
    },
    {
      id: '6959b72f-9613-4b6c-b9d3-46328991b7d2',
      name: 'Nickolas Cruickshank',
      riskScore: 74,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 18,
        },
        {
          month: 'Feb',
          riskScore: 42,
        },
        {
          month: 'Mar',
          riskScore: 60,
        },
        {
          month: 'Apr',
          riskScore: 70,
        },
        {
          month: 'May',
          riskScore: 29,
        },
        {
          month: 'Jun',
          riskScore: 60,
        },
        {
          month: 'Jul',
          riskScore: 41,
        },
        {
          month: 'Aug',
          riskScore: 25,
        },
        {
          month: 'Sep',
          riskScore: 13,
        },
        {
          month: 'Oct',
          riskScore: 64,
        },
        {
          month: 'Nov',
          riskScore: 41,
        },
        {
          month: 'Dec',
          riskScore: 74,
        },
      ],
    },
    {
      id: 'ad8f7416-7eda-4e98-ab27-cedaac0494ea',
      name: 'Oma Heidenreich',
      riskScore: 41,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 34,
        },
        {
          month: 'Feb',
          riskScore: 41,
        },
      ],
    },
    {
      id: 'c7cc1c21-2fba-410f-b69e-1a2209002c96',
      name: 'Wyatt Jaskolski',
      riskScore: 61,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 58,
        },
        {
          month: 'Feb',
          riskScore: 57,
        },
        {
          month: 'Mar',
          riskScore: 35,
        },
        {
          month: 'Apr',
          riskScore: 50,
        },
        {
          month: 'May',
          riskScore: 48,
        },
        {
          month: 'Jun',
          riskScore: 21,
        },
        {
          month: 'Jul',
          riskScore: 33,
        },
        {
          month: 'Aug',
          riskScore: 28,
        },
        {
          month: 'Sep',
          riskScore: 28,
        },
        {
          month: 'Oct',
          riskScore: 35,
        },
        {
          month: 'Nov',
          riskScore: 61,
        },
      ],
    },
    {
      id: 'e1746cfe-d926-42d1-8106-179168e1e11c',
      name: 'Christophe Weissnat',
      riskScore: 97,
      riskCategory: 'Extreme',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 30,
        },
        {
          month: 'Feb',
          riskScore: 26,
        },
        {
          month: 'Mar',
          riskScore: 97,
        },
      ],
    },
    {
      id: '87bad58d-5d7c-4658-9375-4ea35ee0b378',
      name: 'Alexane Carroll',
      riskScore: 30,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 21,
        },
        {
          month: 'Feb',
          riskScore: 24,
        },
        {
          month: 'Mar',
          riskScore: 21,
        },
        {
          month: 'Apr',
          riskScore: 20,
        },
        {
          month: 'May',
          riskScore: 10,
        },
        {
          month: 'Jun',
          riskScore: 22,
        },
        {
          month: 'Jul',
          riskScore: 12,
        },
        {
          month: 'Aug',
          riskScore: 29,
        },
        {
          month: 'Sep',
          riskScore: 20,
        },
        {
          month: 'Oct',
          riskScore: 11,
        },
        {
          month: 'Nov',
          riskScore: 30,
        },
      ],
    },
    {
      id: 'e91276e8-dd63-416a-b73d-5832a1f82927',
      name: 'Ricardo Dickens',
      riskScore: 97,
      riskCategory: 'Extreme',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 74,
        },
        {
          month: 'Feb',
          riskScore: 97,
        },
        {
          month: 'Mar',
          riskScore: 61,
        },
        {
          month: 'Apr',
          riskScore: 89,
        },
        {
          month: 'May',
          riskScore: 86,
        },
        {
          month: 'Jun',
          riskScore: 68,
        },
        {
          month: 'Jul',
          riskScore: 19,
        },
        {
          month: 'Aug',
          riskScore: 66,
        },
        {
          month: 'Sep',
          riskScore: 24,
        },
        {
          month: 'Oct',
          riskScore: 83,
        },
        {
          month: 'Nov',
          riskScore: 97,
        },
      ],
    },
    {
      id: 'ee538883-79ab-4eed-8bc1-52980bda02e1',
      name: 'Hal Dietrich',
      riskScore: 99,
      riskCategory: 'Extreme',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 99,
        },
      ],
    },
    {
      id: '756aada1-b119-43dd-b2d0-97ec6ecad391',
      name: 'Braulio Aufderhar',
      riskScore: 51,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 21,
        },
        {
          month: 'Feb',
          riskScore: 12,
        },
        {
          month: 'Mar',
          riskScore: 11,
        },
        {
          month: 'Apr',
          riskScore: 51,
        },
      ],
    },
    {
      id: '25eb829b-2f71-4092-bcfb-ddf0b65556c5',
      name: 'Javon Rowe',
      riskScore: 65,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 58,
        },
        {
          month: 'Feb',
          riskScore: 65,
        },
      ],
    },
    {
      id: 'fbb6f5ed-41d6-4df5-8a2f-97735d0afc92',
      name: 'Eric Schuppe',
      riskScore: 46,
      riskCategory: 'Critical',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 44,
        },
        {
          month: 'Feb',
          riskScore: 23,
        },
        {
          month: 'Mar',
          riskScore: 30,
        },
        {
          month: 'Apr',
          riskScore: 15,
        },
        {
          month: 'May',
          riskScore: 36,
        },
        {
          month: 'Jun',
          riskScore: 12,
        },
        {
          month: 'Jul',
          riskScore: 46,
        },
      ],
    },
    {
      id: '95684bd5-313d-45a7-8d09-287888703da3',
      name: 'Nico Toy',
      riskScore: 82,
      riskCategory: 'Extreme',
      riskTrend: [
        {
          month: 'Jan',
          riskScore: 59,
        },
        {
          month: 'Feb',
          riskScore: 29,
        },
        {
          month: 'Mar',
          riskScore: 16,
        },
        {
          month: 'Apr',
          riskScore: 54,
        },
        {
          month: 'May',
          riskScore: 14,
        },
        {
          month: 'Jun',
          riskScore: 30,
        },
        {
          month: 'Jul',
          riskScore: 54,
        },
        {
          month: 'Aug',
          riskScore: 34,
        },
        {
          month: 'Sep',
          riskScore: 51,
        },
        {
          month: 'Oct',
          riskScore: 82,
        },
      ],
    },
  ],
  totalResults: 50,
}
