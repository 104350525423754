import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'
import {
  NavigationBreadCrumbs,
  NavigationBreadCrumbsProps,
} from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

// layout props
type LayoutStyleProps = {
  backgroundColor?: string
  borderRadius?: string
  position?: string
  width?: string
  fullHeight?: boolean
}

const useStyles = makeStyles<Theme, LayoutStyleProps>(
  theme =>
    createStyles({
      layout: ({ backgroundColor, borderRadius, position, width, fullHeight }: LayoutStyleProps) => ({
        position: (position as any) || 'relative',
        flex: 1,
        padding: 14,
        backgroundColor: backgroundColor || theme.palette.white.main,
        borderRadius: borderRadius || 11,
        width: width || 1280,
        height: fullHeight ? '100%' : undefined,
      }),
      loading: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        minHeight: 250,
      },
      versionsMessage: {
        marginBottom: 24,
      },
      issuesMessage: {
        marginBottom: 24,
      },
      ignoreFlex: {
        flex: 0,
      },
    }),
  { name: 'ViewLayout' },
)

type Props = {
  children?: React.ReactNode
  breadCrumbsType?: 'light' | 'dark'
  backgroundColor?: string | undefined
  borderRadius?: string | undefined
  isReady?: boolean
  breadcrumbs?: NavigationBreadCrumbsProps['items']
  issuesMessage?: React.ReactNode
  versionsMessage?: React.ReactNode
  ignoreFlex?: boolean
  position?: string
  width?: string | undefined
  fullHeight?: boolean
}

export const ViewLayout: React.FC<Props> = ({
  breadCrumbsType = 'dark',
  backgroundColor,
  borderRadius,
  isReady = true,
  breadcrumbs = [],
  children,
  issuesMessage,
  versionsMessage,
  ignoreFlex = false,
  position,
  width,
  fullHeight = true,
}) => {
  const classes = useStyles({
    backgroundColor,
    borderRadius,
    position,
    width,
    fullHeight,
  })

  return (
    <>
      <NavigationBreadCrumbs type={breadCrumbsType} items={breadcrumbs.filter(b => !!b?.title)} />

      {isReady && !!issuesMessage && (
        <ContentBoundaries className={classes.issuesMessage} width={1280}>
          {issuesMessage}
        </ContentBoundaries>
      )}

      {isReady && !!versionsMessage && (
        <ContentBoundaries className={classes.versionsMessage} width={1280}>
          {versionsMessage}
        </ContentBoundaries>
      )}

      <div
        className={clsx(classes.layout, {
          [classes.ignoreFlex]: ignoreFlex,
        })}
      >
        {isReady ? (
          children
        ) : (
          <Flex className={classes.loading} alignItems="center">
            <Spinner />
          </Flex>
        )}
      </div>
    </>
  )
}
