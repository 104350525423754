import { ICoordinates } from 'pages/orchestration/workflows/edit/interfaces'

interface IGetGridPositionParams {
  parentGridPosition: ICoordinates
}

export const getGridPositionForStep = ({ parentGridPosition }: IGetGridPositionParams): ICoordinates => {
  return {
    x: parentGridPosition.x + 2,
    y: parentGridPosition.y,
  }
}
