import { Box, Chip, SxProps, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import clsx from 'clsx'

import { Text } from 'components/ui-kit/typography/Text'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    chip: {
      textTransform: 'capitalize',
      color: palette.royalBlue.main,
      cursor: 'pointer',
      backgroundColor: palette.sphereFaded.main,
      '&:hover, &:focus': {
        backgroundColor: palette.sphereSemiFaded.main,
      },
      '&:active': {
        backgroundColor: palette.sphere.main,
      },
      '&.link-chip-large': {
        padding: spacing(3, 0),
        borderRadius: 100,

        '& img': {
          height: 36,
          width: 36,
        },
      },
      '&.link-chip-medium': {
        padding: spacing(2.5, 0.5),
        borderRadius: 100,

        '& img': {
          height: 36,
          width: 36,
        },
      },
      '&.link-chip-grey': {
        background: palette.fadedGrey.main,
        '& span': {
          color: palette.darkDusk.main,
        },
        '&:active, &:focus, &:hover': {
          background: palette.iron.main,
        },
      },
    },
    chevron: {
      fill: palette.royalBlue.main,
      fontSize: 20,
    },
  }),
)

type LinkChipProps = {
  text?: string
  className?: string
  onClick?: () => void
  icon?: React.ReactElement
  deleteIcon?: React.ReactElement
  size?: 'small' | 'medium' | 'large'
  variant?: 'grey' | 'blue'
  clickable?: 'closeIcon' | 'tile'
  sx?: SxProps<Theme>
}

export const LinkChip: React.FC<LinkChipProps> = ({
  text = '',
  className,
  onClick = () => {},
  icon = <></>,
  deleteIcon,
  size = 'small',
  variant = 'blue',
  clickable = 'tile',
  sx,
}) => {
  const classes = useStyles()

  const sizeClass = `link-chip-${size}`
  const colorClass = `link-chip-${variant}`

  return (
    <Chip
      className={clsx(classes.chip, className, sizeClass, colorClass)}
      clickable={clickable && clickable === 'tile'}
      onClick={clickable && clickable === 'tile' ? onClick : undefined}
      label={
        <Box display="flex" alignItems="center">
          <Text cursor="pointer" size={14} color="royalBlue">
            {text}
          </Text>
        </Box>
      }
      // Avatar = Start-of-chip Icon
      avatar={icon}
      // Delete Icon = End-of-chip Icon
      deleteIcon={deleteIcon ? deleteIcon : <ChevronRightIcon className={classes.chevron} />}
      // Required for DeleteIcon
      onDelete={onClick}
      sx={sx}
    />
  )
}
