import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CustomRightNameDTO } from '@ketch-com/figurehead'
import { Button, DataGrid, PopUp, TableCell } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  onClose: () => void
  data: CustomRightNameDTO
  action: any
  isSubmitting?: boolean
}
export const CustomRightNameSaveAlertModal: React.FC<Props> = ({ onClose, data, action, isSubmitting = false }) => {
  const rows =
    data.jurisdictionRights?.map((jurisdiction, index) => ({
      id: index,
      right: jurisdiction.rightName,
      jurisdiction: jurisdiction.jurisdictionName,
      jurisdictionCode: jurisdiction.jurisdictionCode,
    })) || []

  const cols = [
    {
      width: 165,
      field: 'right',
      headerName: 'Right',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            <Typography variant="label">{params?.row?.right}</Typography>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 140,
      field: 'Jurisdiction',
      headerName: 'Jurisdiction',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return <TableCell title={params.row.jurisdiction} subTitle={params.row.jurisdictionCode} />
      },
      sortable: false,
    },
    {
      width: 180,
      field: 'Link',
      headerName: '',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell
            sx={{
              left: '12px',
              position: 'relative',
            }}
          >
            {params?.row?.right ? (
              <Button
                color="tertiary"
                onClick={() => {
                  window.open(
                    RoutesManager.policyCenter.policyScopes.view.root.getURL({ code: params.row.jurisdictionCode }),
                    '_blank',
                    'noreferrer',
                  )
                }}
              >
                Open In New Tab
              </Button>
            ) : null}
          </TableCell>
        )
      },
      sortable: false,
    },
  ]
  return (
    <PopUp
      title="Save Changes"
      variant="dialog"
      popUpWidth="550px"
      onClose={onClose}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onClose} pending={isSubmitting}>
            Cancel
          </Button>
          <Button color="primary" size="large" onClick={action} pending={isSubmitting}>
            Save
          </Button>
        </>
      }
    >
      <Typography variant="body" component={Box}>
        {`This Custom Right Name is currently in use in the below Jurisdiction${
          data?.jurisdictionRights?.length! > 1 ? 's' : ''
        }. All changes will take effect immediately, are you sure you want to save?`}
      </Typography>

      <DataGrid
        columns={cols}
        rows={rows}
        hidePaginationRowsInfo={true}
        hideFooter
        disableColumnResize
        disableColumnFilter
        disableColumnReorder
        disableColumnPinning
        disableAggregation
        disableRowGrouping
        hideFooterRowCount
        disableColumnMenu
        disableRowHoverStates
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
      />
    </PopUp>
  )
}
