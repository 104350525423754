import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { capitalize, values } from 'lodash'
import { Box, Tooltip } from '@mui/material'

import { IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { RoutesManager } from 'utils/routing/routesManager'
import { formatCodeFromName } from 'utils/formatters'
import { getAreTransitionsValid } from '../utils/getAreTransitionsValid'
import { Button, Icon, InlineEditSize, Status, StatusState, StatusVariant, TooltipButton, theme } from '@ketch-com/deck'
import { FormInlineEdit } from 'components/form/FormInlineEdit'
import { FormInput } from 'components/form/FormInput'

type Props = {
  isEditMode: boolean
}

export const EditWorkflowForm: React.FC<Props> = ({ isEditMode }) => {
  const navigate = useNavigate()

  const {
    isSubmitting,
    submitForm,
    isValid,
    errors,
    setFieldValue,
    setFieldTouched,
    values: formikValues,
  } = useFormikContext<IWorkflowConfig>()

  const { steps } = formikValues

  const areTransitionsValid = getAreTransitionsValid(steps)

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="flex-start">
        <Box display="flex" gap={2} alignItems="center">
          <FormInlineEdit
            shouldUpdateDebounced
            required
            size={InlineEditSize.large}
            placeholder="Workflow Name"
            formPropertyName="name"
            onChange={e => {
              if (!isEditMode) {
                setFieldTouched('code', true)
                setFieldValue('code', formatCodeFromName(e.target.value))
              }
            }}
          />

          <FormInput required size="small" disabled={isEditMode} placeholder="Workflow Code" formPropertyName="code" />

          <Box display="flex" alignItems="center" justifyContent="center">
            {formikValues?.canonicalRightCode && (
              <Status
                variant={StatusVariant.filled}
                label={capitalize(formikValues.canonicalRightCode)}
                status={StatusState.new}
              />
            )}

            <Tooltip title="The type of the workflow can't be changed at this stage.">
              <Box display="flex" alignItems="center" ml={0.5}>
                <Icon name="FUnknown" iconColor={theme.palette.darkDuskFaded.main} height={16} width={16} />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <Box display="flex" gap={2} alignItems="center">
        <Button onClick={() => navigate(RoutesManager.orchestration.workflows.root.getURL())} color={'secondary'}>
          Cancel
        </Button>

        <TooltipButton
          title={!isValid ? values(errors)[0].toString() : undefined}
          pending={isSubmitting}
          disabled={!isValid || !areTransitionsValid}
          color="primary"
          onClick={submitForm}
        >
          Save & Finish
        </TooltipButton>
      </Box>
    </>
  )
}
