import { useContext } from 'react'

import { showToast } from 'components/modals/AlertComponent'
import { getValidationSchema, getInitialValues } from '../utils'
import { RightsQueueViewContext } from '../../../context/RightsQueueViewContext'
import { useExtendRightInvocation } from 'api/rightInvocationsV2/mutations/useExtendRightInvocation'
import { ExtendRightInvocationRequestBodyDTO } from '@ketch-com/figurehead'

type Args = {
  onClose: () => void
}

export const useExtendRequestModalUtils = ({ onClose }: Args) => {
  const { rightInvocation, refetchRightInvocation } = useContext(RightsQueueViewContext)

  const initialValues = getInitialValues()
  const validationSchema = getValidationSchema()

  const { mutateAsync: handleExtendRightInvocation, isLoading: isExtendLoading } = useExtendRightInvocation({
    onSuccess: async () => {
      showToast({
        content: 'Request extended successfully',
        type: 'success',
      })
      await refetchRightInvocation()
      onClose()
    },
    onError: () => {
      showToast({
        content: 'Failed to extend request',
        type: 'error',
      })
    },
  })

  const resolve = async (values: ExtendRightInvocationRequestBodyDTO) => {
    try {
      await handleExtendRightInvocation({
        params: {
          requestId: rightInvocation?.id || '',
          formData: values,
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  return {
    isExtendLoading,
    initialValues,
    validationSchema,
    resolve,
    rightInvocation,
  }
}
