import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchAuthConfig } from 'api/auth/fetchers/fetchAuthConfig'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.authConfig,
  queryFn: fetchAuthConfig,
  select: res => res?.data || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useAuthConfig = (config: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config.params,
    },
  })
