import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces/common'
import { IdentitySpaceDTO, IdentitySpacesCatalogDTO } from 'interfaces/identitySpaces/identitySpace'
import { RoutesManager } from 'utils/routing/routesManager'
import { IdentitySpaceFormValues } from 'pages/settings/identitySpaces/upsert/utils/formValues'
import { getInitialValues } from 'pages/settings/identitySpaces/upsert/utils/formValues'
import { useValidationSchema } from 'pages/settings/identitySpaces/upsert/utils/formValidation'
import { IdentitySpaceMappings } from 'pages/settings/identitySpaces/upsert/components/IdentitySpaceMappings'
import { AuthenticatedIdentity } from 'pages/settings/identitySpaces/upsert/components/AuthenticatedIdentity'
import { MarketingPreferenceIdentity } from 'pages/settings/identitySpaces/upsert/components/MarketingPreferenceIdentity'
import { IdentitySpaceBasicDetails } from 'pages/settings/identitySpaces/upsert/components/IdentitySpaceBasicDetails'
import { ContentGroup, UpsertLayoutMode, UpsertLayout, Spinner } from '@ketch-com/deck'
import { useFormik, FormikProvider } from 'formik'
import { FindVerifiersResponseBodyDTO } from '@ketch-com/figurehead'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { Box } from '@mui/material'
import { NavigationBreadCrumbs } from '../../../../components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isReady: boolean
  isEditMode: boolean
  identitySpace: MaybeNull<IdentitySpaceDTO>
  identitySpaces: IdentitySpaceDTO[]
  identitySpacesCatalog: MaybeNull<IdentitySpacesCatalogDTO>
  verifiersData: MaybeNull<FindVerifiersResponseBodyDTO>
  onSubmit: (values: IdentitySpaceFormValues) => Promise<void>
}

export const IdentitySpaceUpsertView: React.FC<Props> = ({
  isReady,
  isEditMode,
  onSubmit,
  identitySpace,
  identitySpaces = [],
  identitySpacesCatalog,
  verifiersData = {},
}) => {
  const { isEntitled } = useIsEntitled()
  const isEntitledToVerifiedIdentities = isEntitled(ENTITLEMENTS.VERIFIED_IDENTITIES)
  const navigate = useNavigate()

  const breadcrumbs = isEditMode
    ? [
        { title: 'Settings', link: RoutesManager.settings.root.getURL() },
        { title: 'Identifiers', link: RoutesManager.settings.identitySpaces.root.getURL() },
        {
          title: identitySpace?.name || identitySpace?.code,
          link: RoutesManager.settings.identitySpaces.view.root.getURL({ code: identitySpace?.code }),
        },
      ]
    : [
        { title: 'Settings', link: RoutesManager.settings.root.getURL() },
        { title: 'Identifiers', link: RoutesManager.settings.identitySpaces.root.getURL() },
        { title: 'Create New Identifier' },
      ]

  const initialValues = useMemo(
    () => getInitialValues({ identitySpace, identitySpaces }),
    [identitySpace, identitySpaces],
  )

  const validationSchema = useValidationSchema({ isEditMode })

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })

  const { isSubmitting, submitForm } = formikProps

  return (
    <FormikProvider value={formikProps}>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <UpsertLayout
        mode={UpsertLayoutMode.edit}
        renderFormTitle={() => (
          <ContentGroup title={isEditMode ? 'Edit Identifier' : 'Create Identifier'} titleVariant="h2" isDivider />
        )}
        onAccept={submitForm}
        onCancel={() => {
          navigate(RoutesManager.settings.identitySpaces.root.getURL())
        }}
        acceptButtonProps={{
          pending: isSubmitting,
        }}
      >
        {isReady ? (
          <>
            <IdentitySpaceBasicDetails isEditMode={isEditMode} identitySpacesCatalog={identitySpacesCatalog!} />

            <IdentitySpaceMappings isEditMode={isEditMode} shouldSelectFirstByDefault />

            <MarketingPreferenceIdentity />

            {isEntitledToVerifiedIdentities && verifiersData?.verifiers?.length ? (
              <AuthenticatedIdentity isEditMode={isEditMode} verifiersData={verifiersData} />
            ) : null}
          </>
        ) : (
          <Box
            sx={{
              margin: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner size="32px" thickness={2.5} />
          </Box>
        )}
      </UpsertLayout>
    </FormikProvider>
  )
}
