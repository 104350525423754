import React from 'react'
import clsx from 'clsx'
import { Avatar } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { AvatarGroup } from '@mui/material'
import { AvatarGroupProps } from '@mui/lab'

const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      root: {},
      item: {
        width: 28,
        height: 28,
        border: `1px solid ${palette.iron.main}`,
      },
    }),
  { name: 'ColorScheme' },
)

type Props = {
  /** List of Hex colors which will be displayed as circle with a bg of this color */
  colors: string[]
  /** Custom component className */
  className?: string
  /** Custom component item className */
  classes?: Partial<ReturnType<typeof useStyles>>
} & AvatarGroupProps

/**
 * Render list of hex colors into list of circles with corresponding backgrounds
 */
export const ColorScheme: React.FC<Props> = ({ colors = [], className, classes: _classes, max = colors.length }) => {
  const classes = useStyles({ classes: _classes })

  return (
    <AvatarGroup max={max} className={clsx(classes.root, className)}>
      {colors.map((color, index) => (
        <Avatar className={classes.item} key={index} style={{ backgroundColor: color }}>
          {' '}
        </Avatar>
      ))}
    </AvatarGroup>
  )
}
