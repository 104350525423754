import React from 'react'

import { Cell, Pie, PieChart, Tooltip } from 'recharts'
import { Box, Typography } from '@mui/material'
import { getTransformedPerJurisdictionData } from '../../utils'
import { PerJurisdictionPermitStatsDTO } from '@ketch-com/figurehead'
import { CircularProgress, CircularProgressSize, EmptyState } from '@ketch-com/deck'
import {
  DistributionPerJurisdictionTooltip,
  DistributionPerScopeTopThree,
  DistributionPerScopeTwoOrLess,
} from './components'
import { useIsLoadingAggregate } from 'pages/insights/permitsStatistics/utils'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'

type Props = {
  jurisdictions: PerJurisdictionPermitStatsDTO[]
  isLoading: boolean
  jurisdictionTotalRequests: number
}

export const DistributionPerJurisdiction: React.FC<Props> = ({
  jurisdictions,
  isLoading,
  jurisdictionTotalRequests,
}) => {
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])
  const reshapedJurisdictionData = getTransformedPerJurisdictionData(jurisdictions, jurisdictionTotalRequests, t)
  const isLoadingAggregate = useIsLoadingAggregate()

  if (isLoadingAggregate || isLoading) {
    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: ({ palette }) => palette.white.main,
          borderRadius: 2,
          padding: 4,
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h3">{t('Per Jurisdiction')}</Typography>
          <Typography variant="body" color="darkDuskFaded.main" sx={{ mb: 0.5 }}>
            {t('Which jurisdictions receive the most requests?')}
          </Typography>
        </Box>

        <Box flex={1} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={CircularProgressSize.xLarge} />
        </Box>
      </Box>
    )
  }

  if (!isLoadingAggregate && !isLoading && jurisdictions.length === 0) {
    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: ({ palette }) => palette.white.main,
          borderRadius: 2,
          padding: 4,
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h3">{t('Per Jurisdiction')}</Typography>
          <Typography variant="body" color="darkDuskFaded.main">
            {t('Which jurisdictions receive the most requests?')}
          </Typography>
        </Box>

        <Box flex={1} display="flex" alignItems="center" justifyContent="center">
          <EmptyState colorTheme="darkDuskFaded" iconName="OFilter" title="No data for selected filter criteria" />
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        background: ({ palette }) => palette.white.main,
        borderRadius: 2,
        padding: 4,
      }}
    >
      {/* Column 1 */}

      <Box sx={{ flex: 10.25, display: 'flex', flexDirection: 'column' }}>
        {/* Row 1  */}

        <Box display="flex" flexDirection="column">
          <Typography variant="h3">{t('Per Jurisdiction')}</Typography>
          <Typography variant="body" color="darkDuskFaded.main" sx={{ mb: 0.5 }}>
            {t('Which jurisdictions receive the most requests?')}
          </Typography>
        </Box>

        {/* Row 2 */}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            mb: 1,
          }}
        >
          <PieChart width={260} height={260} data={reshapedJurisdictionData}>
            <Pie
              data={reshapedJurisdictionData}
              animationDuration={500}
              startAngle={-120}
              endAngle={240}
              innerRadius={70}
              outerRadius={70 + 56}
              fill="#8884d8"
              paddingAngle={reshapedJurisdictionData.length === 1 ? 0 : 4}
              dataKey="percent"
            >
              {reshapedJurisdictionData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip
              content={props => {
                return (
                  <DistributionPerJurisdictionTooltip
                    active={props.active}
                    label={props.label}
                    payload={props.payload}
                  />
                )
              }}
            />
          </PieChart>
        </Box>

        {/* Row 3 */}

        <Box display="flex" flexDirection="column">
          <Typography variant="body" color="darkDusk.main">
            *{t('Each user can belong to only one Jurisdiction.')}
          </Typography>
        </Box>
      </Box>

      {/* Column 2 */}

      {reshapedJurisdictionData.length > 2 ? (
        <DistributionPerScopeTopThree data={reshapedJurisdictionData} />
      ) : (
        <DistributionPerScopeTwoOrLess data={reshapedJurisdictionData} />
      )}
    </Box>
  )
}
