import { UseLabelsConfig, useLabelsV2 } from './useLabelsV2'
import { useLabelsV3 } from './useLabelsV3'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

export const useLabelsV2OrV3BasedOnFeatureFlag = ({ params }: UseLabelsConfig = {}) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )

  /* V2 */

  const respV2 = useLabelsV2({
    enabled: isV2FeatureFlagEnabled,
    params: {
      ...params,
    },
  })

  /* V3 */

  const respV3 = useLabelsV3({
    enabled: !isV2FeatureFlagEnabled,
    params: {
      ...params,
    },
  })

  return isV2FeatureFlagEnabled ? respV2 : respV3
}
