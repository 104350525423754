import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchAssetsUniqueNamesPaginatedV2 } from 'api/assets/fetchers/fetchAssetsUniqueNamesPaginatedV2'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.resourcesPaginated,
  queryFn: fetchAssetsUniqueNamesPaginatedV2,
  select: res => res?.data?.names || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useAssetsUniqueNamesPaginatedV2 = (config?: QueryConfig) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )

  return useCustomPaginatedQuery({
    ...config,
    params: {
      isV2FeatureFlagEnabled,
      ...config?.params,
    },
  })
}
