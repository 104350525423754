import { useState, useCallback, useMemo, useEffect } from 'react'
import { useDebounce } from 'react-use'
import { Box } from '@mui/material'
import { TableCell, DataGrid, CustomFilterPanel } from '@ketch-com/deck'
import { useFiltersV3 } from 'utils/hooks'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { useRightsQueueListTableUtils } from '../../orchestration/rightsQueue/listV2/components/RightsQueueListTable/hooks/useRightsQueueListTableUtils'

export const statusFilters: { label: string; value: string }[] = [
  {
    label: 'On Track',
    value: 'on_track',
  },
  {
    label: 'Due Soon',
    value: 'due_soon',
  },
  {
    label: 'Due Today',
    value: 'due_today',
  },
  {
    label: 'Overdue',
    value: 'overdue',
  },
  {
    label: 'Completed',
    value: 'fulfilled',
  },
]

export const useRightsQueueSearchInputUtils = () => {
  const { removeSingleSearchParam, replaceParams, reduxSearchParams } = useFiltersV3()
  const [searchString, setSearchString] = useState<string>(() => {
    return reduxSearchParams?.query || ''
  })

  useDebounce(
    () => {
      if (searchString) {
        replaceParams({ query: searchString })
      }
      if (!searchString) {
        removeSingleSearchParam('query')
      }
    },
    500,
    [searchString],
  )

  useEffect(() => {
    if (!reduxSearchParams?.query) {
      setSearchString(reduxSearchParams?.query || '')
    }
  }, [reduxSearchParams?.query])

  return {
    setSearchString,
    searchString,
  }
}

const useDataGridData = () => {
  const rightsQueueListTableUtils = useRightsQueueListTableUtils()
  const { rightsQueueListTableColumnConfig, rightInvocations } = rightsQueueListTableUtils

  const columns = rightsQueueListTableColumnConfig.map(column => ({
    field: column.id,
    headerName: column.label,
    renderCell: (params: any) => <TableCell>{column?.cellRenderer?.(params.row.originalRowData)}</TableCell>,
    width: column.id === 'subject-type' ? 250 : column.minWidth,
    sortable: false,
  }))

  const rows = rightInvocations.map(row => ({
    id: row.id,
    request_status: row.requestStatus,
    'subject-name': row.rightName,
    'request-code': row.rightCode,
    'current-activity': row.requestStatusText,
    progress: row.status,
    workflow: row.workflowExecutionID,
    invoked_at: formatDateTimeFromUnix(row.invokedAt),
    'subject-type': row.rightName,
    originalRowData: row,
  }))

  return {
    columns,
    rows,
  }
}

type Props = {}

export const DataGridFiltersExample: React.FC<Props> = props => {
  const predefinedUniqValues = {
    request_status: statusFilters,
  }

  const [selectedColumn, setSelectedColumn] = useState('')
  const { rows, columns } = useDataGridData()
  const { replaceParams, getPanelParamValuesArrayByPanelId } = useFiltersV3()
  const panelId = 'requestStatuses'
  const panelParamValuesArray = getPanelParamValuesArrayByPanelId(panelId)

  console.info('panelParamValuesArray', panelParamValuesArray)

  const onFilterChange = useCallback(
    (value: any) => {
      replaceParams({
        [panelId]: value,
        page: '0',
      })
    },
    [replaceParams],
  )

  const columnNames = useMemo(
    () =>
      columns?.map(column => ({
        label: column.headerName,
        value: column.field,
      })),
    [columns],
  )

  return (
    <Box style={{ width: 'auto' }}>
      <DataGrid
        filterMode="server"
        columns={columns}
        rows={rows}
        onFilterModelChange={model => {
          setSelectedColumn(model.items[0].field)
        }}
        slots={{
          filterPanel: CustomFilterPanel,
        }}
        slotProps={{
          filterPanel: {
            onFilterChange,
            rows,
            columnNames,
            predefinedUniqValues,
            multiple: true,
            predefined: true,
            selectedColumn,
          } as any,
        }}
      />
    </Box>
  )
}
