import { CustomAttributeTypeDTO } from '@ketch-com/figurehead'
import { useDataSystemsContext } from '../../context/DataSystemContext'
import TextAreaInfoRow from 'components/InfoRow/TextAreaInfoRow'
import ChipContentRadioEditInfoRow, {
  ChipContentRadioEditOptions,
} from 'components/InfoRow/ChipContentRadioEditInfoRow'
import ChipContentCheckboxEditInfoRow from 'components/InfoRow/ChipContentCheckboxEditInfoRow'

export const CustomFields: React.FC = () => {
  const { appDescriptor, handleSystemCustomFieldValueUpdates } = useDataSystemsContext()

  return (
    <>
      {appDescriptor?.customAttributeValues
        ?.filter(
          field =>
            !!field.configuration && field.configuration.type !== CustomAttributeTypeDTO.UnspecifiedCustomAttributeType,
        )
        .sort((a, b) => a.configuration!.index - b.configuration!.index)
        .map(field => {
          const { configuration, values } = field
          if (
            configuration?.type === CustomAttributeTypeDTO.SingleSelectCustomAttributeType ||
            configuration?.type === CustomAttributeTypeDTO.MultiSelectCustomAttributeType
          ) {
            const options: ChipContentRadioEditOptions = configuration.options!.reduce((acc, option) => {
              acc[option.value] = {
                title: option.value,
                value: option.value,
                disabled: false,
              }
              return acc
            }, {} as ChipContentRadioEditOptions)

            if (configuration?.type === CustomAttributeTypeDTO.SingleSelectCustomAttributeType) {
              return (
                <ChipContentRadioEditInfoRow
                  allowDelete={false}
                  isCustomField
                  isEditable
                  title={configuration!.label}
                  options={options!}
                  value={values?.[0] || ''}
                  setValue={(values?: string[] | string) =>
                    handleSystemCustomFieldValueUpdates(configuration.id!, configuration.label, values)
                  }
                />
              )
            }
            return (
              <ChipContentCheckboxEditInfoRow
                allowDelete
                isCustomField
                isEditable
                title={configuration!.label}
                options={options!}
                values={values || []}
                setValue={(values?: string[] | string) =>
                  handleSystemCustomFieldValueUpdates(configuration.id!, configuration.label, values)
                }
              />
            )
          }
          return (
            <TextAreaInfoRow
              isCustomField
              title={configuration!.label}
              value={values?.[0]}
              setValue={(values?: string[] | string) =>
                handleSystemCustomFieldValueUpdates(configuration!.id!, configuration!.label, values)
              }
            />
          )
        })}
    </>
  )
}
