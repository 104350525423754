import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import { SidebarFormRadioGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormRadioGroup'
import { ItemStyle } from '@ketch-sdk/ketch-types'
import { ItemStyleLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { handleButtonStyleChange } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'
import { useFormikContext } from 'formik'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import {
  BuilderTextInputMode,
  FormTextInput,
} from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'

export const PreferenceThemeSubscriptionsTabFooterForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ThemeV3DTO>()
  return (
    <>
      {/* TODO:JB - Descoped */}
      {/* <SidebarFormGroup sx={{ pt: 0, gap: '16px' }} hasBorderBottom>
        <FormColorInput
          name={themeUpsertFormKeys.preferenceSubscriptionsTabFooterBackgroundColor}
          label={'Footer Background Color'}
          fullWidth
          required
        />
      </SidebarFormGroup> */}
      <SidebarFormGroup title={'Action Button'} sx={{ pt: 0, gap: '16px' }}>
        <SidebarFormRadioGroup
          name={themeUpsertFormKeys.preferenceSubscriptionsTabFooterActionButtonStyle}
          title={'Style'}
          options={Object.values(ItemStyle).map(style => ({
            label: ItemStyleLabels[style],
            value: style,
          }))}
          row
          onChange={newStyle =>
            handleButtonStyleChange(
              values,
              setFieldValue,
              newStyle,
              themeUpsertFormKeys.preferenceSubscriptionsTabFooterActionButtonTextColor,
            )
          }
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferenceSubscriptionsTabFooterActionButtonBackgroundColor}
          label={'Action Button Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferenceSubscriptionsTabFooterActionButtonTextColor}
          label={'Action Button Text Color'}
          fullWidth
          required
        />
        <FormTextInput
          name={themeUpsertFormKeys.preferenceSubscriptionsTabFooterActionButtonCornerRadius}
          label="Action Button Corner Radius"
          inputWidth="92px"
          mode={BuilderTextInputMode.CornerRadius}
        />
      </SidebarFormGroup>
    </>
  )
}
