import { ContextVariableCategoryEnum } from 'interfaces/workflows/ContextVariableCategoryEnum'
import { GatewayTransitionDTO } from 'pages/orchestration/workflows/edit/interfaces/ICanvasStep'
import { getUuidWithoutHyphensAndStartingWithALetter } from 'pages/orchestration/workflows/edit/utils/getUuidWithoutHyphensAndStartingWithALetter'

export const getDecisionGatewayTransitionItem = (): GatewayTransitionDTO => ({
  ID: getUuidWithoutHyphensAndStartingWithALetter(),
  name: '',
  description: '',
  variable: '',
  operator: '',
  operand: '',
  variableCategory: ContextVariableCategoryEnum.DATA_SUBJECT_VARIABLE,
  outcomeVariableStepID: '',
})
