import { ListItem } from '@ketch-com/deck'
import { Box, Divider, styled } from '@mui/material'
import React, { Fragment, useContext } from 'react'
import { SidebarRouteItem } from 'utils/routing/types'
import { isArray } from 'lodash'
import { AppNavigationContext } from 'components/appLayout/appNavigation/context/AppNavigationContext'
import { GatedNavigateStatus, useNavigateGated } from 'utils/routing/hooks/useNavigateGated'

const HomeOverviewSectionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  borderRadius: 8,
  background: theme.palette.Common.Container,
  height: 'fit-content',
}))

const ItemsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px',
})

type Props = {
  items?: SidebarRouteItem[] | { [subRouteSection: string]: SidebarRouteItem[] }
}

export const HomeOverviewSection: React.FC<Props> = ({ items }) => {
  const { setSidebarOpenSection } = useContext(AppNavigationContext)
  const navigateGated = useNavigateGated()
  const hasSections = typeof items === 'object' && Object.entries(items).length && !isArray(items)

  if (!(hasSections || !!items?.length)) return null

  const sectionsLength = Object.entries(items as { [subRouteSection: string]: SidebarRouteItem[] }).length

  const handleItemClick = (item: SidebarRouteItem) => {
    const { status } = navigateGated(
      item.path,
      item.permissions,
      item.entitlements,
      item.requireAllPermissions,
      item.requireAllEntitlements,
    )
    if (status === GatedNavigateStatus.Success && item.sectionId) setSidebarOpenSection(item.sectionId)
  }

  return (
    <HomeOverviewSectionBox>
      {hasSections ? (
        Object.entries(items as { [subRouteSection: string]: SidebarRouteItem[] }).map(
          ([section, sectionItems], sectionIndex) => (
            <ItemsBox key={sectionIndex}>
              <ListItem
                title={section}
                endIcon={<></>}
                sx={theme => ({
                  cursor: 'default',
                  minHeight: '24px',
                  ':hover': {
                    background: 'none',
                    cursor: 'default',
                  },
                  '& .MuiTypography-root': {
                    fontWeight: '600 !important',
                    fontSize: '12px !important',
                    color: `${theme.palette.Text.Secondary} !important`,
                  },
                })}
              />
              {sectionItems.map((item, index) => (
                <Fragment key={index}>
                  <ListItem title={item.title} onClick={() => handleItemClick(item)} key={index} />
                  {(index !== sectionItems.length - 1 || sectionIndex !== sectionsLength - 1) && (
                    <Divider sx={{ my: '4px' }} />
                  )}
                </Fragment>
              ))}
            </ItemsBox>
          ),
        )
      ) : (
        <ItemsBox>
          {(items as SidebarRouteItem[]).map((item, index) => (
            <Fragment key={index}>
              <ListItem title={item.title} onClick={() => handleItemClick(item)} key={index} />
              {index !== (items as SidebarRouteItem[]).length - 1 && <Divider sx={{ my: '4px' }} />}
            </Fragment>
          ))}
        </ItemsBox>
      )}
    </HomeOverviewSectionBox>
  )
}
