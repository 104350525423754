import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchRegionsHierarchy } from 'api/regions/fetchers/fetchRegionsHierarchy'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.regions,
  queryFn: fetchRegionsHierarchy,
  select: res => res?.data?.regionGroups || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useRegionsHierarchy = (config?: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config?.params,
      start: 0,
      limit: 1000,
    },
  })
