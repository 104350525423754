import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { FormTemplateExperienceRightDTO, FormTemplateExperienceRightsDTO } from '@ketch-com/figurehead'
import { Button, DataGrid, PopUp, Tab, TabGroup, TabProps, TableCell } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

type Props = {
  onCancel: () => void
  formTemplateExperienceRights?: FormTemplateExperienceRightsDTO
}

export const FormTemplateAssociatedRightsModal: React.FC<Props> = ({ onCancel, formTemplateExperienceRights }) => {
  const [activeRightTab, setActiveRightTab] = React.useState<keyof FormTemplateExperienceRightsDTO>(() => {
    const firstRightTab = Object.keys(formTemplateExperienceRights || {})[0] as keyof FormTemplateExperienceRightsDTO
    return firstRightTab
  })

  const [currentTab, setCurrentTab] = useState(0)

  const tabDetails: {
    key: number
    rightTab: keyof FormTemplateExperienceRightsDTO
    title: string
    index: number
  }[] = [
    { index: 0, key: 1, rightTab: 'delete', title: 'Delete Data' },
    { index: 1, key: 2, rightTab: 'get', title: 'Access Data' },
    { index: 2, key: 3, rightTab: 'restrict', title: 'Restrict Processing' },
    { index: 3, key: 4, rightTab: 'update', title: 'Update Data' },
    { index: 4, key: 5, rightTab: 'custom', title: 'Custom' },
  ]

  const config = tabDetails.map(detail => ({
    key: detail.key,
    label: `${detail.title} ${formTemplateExperienceRights?.[detail.rightTab]?.length || 0}`,
    onClick: () => {
      setActiveRightTab(detail.rightTab)
      const newIndex = tabDetails.find(elem => elem.rightTab === detail.rightTab)?.index || 0
      setCurrentTab(newIndex)
    },
    active: activeRightTab === detail.rightTab,
    disabled: (formTemplateExperienceRights?.[detail.rightTab]?.length || 0) === 0,
  })) as TabProps[]

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }
  return (
    <PopUp
      title="Associated Rights"
      subTitle="Associated Rights as defined by your Experience Server canonical right designation and deployment plan (if available)."
      onClose={onCancel}
      variant="modal"
      popUpWidth="880px"
      popUpActionChildren={
        <Button color="secondary" size="large" onClick={onCancel}>
          Close
        </Button>
      }
    >
      <TabGroup value={currentTab} onChange={handleChange}>
        {config.map(elem => {
          return <Tab {...elem} />
        })}
      </TabGroup>

      <Box height="400px">
        <DataGrid
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: false,
            expand: true,
          }}
          getRowHeight={() => 'auto'}
          columns={[
            {
              align: 'left',
              field: 'name',
              flex: 1,
              headerAlign: 'left',
              headerName: 'Experience',
              sortable: false,
              renderCell: ({ row }: GridRenderCellParams<FormTemplateExperienceRightDTO>) => {
                return <TableCell title={row.name} subTitle={row.code} />
              },
            },
            {
              align: 'left',
              field: 'description',
              flex: 1,
              headerAlign: 'left',
              headerName: 'Description',
              sortable: false,
              renderCell: ({ row }: GridRenderCellParams<FormTemplateExperienceRightDTO>) => {
                return <TableCell title={row.description} />
              },
            },
          ]}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowSelectionOnClick
          disableRowHoverStates
          getRowId={row => row.code}
          rows={formTemplateExperienceRights?.[activeRightTab] || []}
          hideFooter
        />
      </Box>
    </PopUp>
  )
}
