import React from 'react'

import { FormTemplateAssociatedRightsModal } from '.'
import { FormTemplateExperienceDTO, FormTemplateExperienceRightsDTO } from '@ketch-com/figurehead'
import { Chip, Icon } from '@ketch-com/deck'

type Props = {
  experience?: FormTemplateExperienceDTO
}

export const AssociatedRightsCellRenderer: React.FC<Props> = ({ experience }) => {
  const [showAssociatedRightsModal, setShowAssociatedRightsModal] = React.useState<
    FormTemplateExperienceRightsDTO | undefined
  >()

  const associatedRightsCount = Object.values?.(experience?.rights || {})?.reduce?.(
    (acc, curr) => acc + curr?.length,
    0,
  )
  return (
    <>
      <Chip
        clickable
        label={String(associatedRightsCount)}
        onClick={() => setShowAssociatedRightsModal(experience?.rights)}
        onDelete={() => setShowAssociatedRightsModal(experience?.rights)}
        deleteIcon={<Icon name="OArrowCRight" />}
      />
      {!!showAssociatedRightsModal && (
        <FormTemplateAssociatedRightsModal
          formTemplateExperienceRights={experience?.rights}
          onCancel={() => setShowAssociatedRightsModal(undefined)}
        />
      )}
    </>
  )
}
