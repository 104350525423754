import { Button, CustomIconName, Icon } from '@ketch-com/deck'
import { Typography } from '@mui/material'
import React from 'react'
import { To, useNavigate } from 'react-router-dom'

export interface CrumbProps {
  title?: string
  link?: To
  icon?: CustomIconName
  clickable?: boolean
}

export const Crumb: React.FC<CrumbProps> = ({ title, link, icon, clickable = true }) => {
  const navigate = useNavigate()

  return (
    <Button
      key={title}
      variant="link"
      size="large"
      onClick={() => link && clickable && navigate(link)}
      sx={{
        // Disable pointer for non-clickable crumbs
        ...(!clickable ? { cursor: 'default' } : {}),
        '& span': {
          gap: '2px',
          // Hide underline for non-clickable crumbs (we do it this way so the text isn't shifted down)
          ...(!clickable ? { borderBottomColor: 'white !important' } : {}),
        },
        marginX: '8px',
      }}
    >
      {icon && <Icon name={'Database'} width={20} height={20} />}
      <Typography fontSize={16} fontWeight={600} lineHeight="20.8px" letterSpacing="-0.16px" whiteSpace="nowrap">
        {title}
      </Typography>
    </Button>
  )
}
