import { ContentGroup, ListLayout } from '@ketch-com/deck'
import { Box, Divider, styled } from '@mui/material'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'
import { SidebarRouteItem } from 'utils/routing/types'
import { useSidebarRoutesConfig } from 'utils/routing/useSidebarRoutesConfig'
import { DatamapOverviewVisualization } from './components/DatamapOverviewVisualization'
import { OverviewCard } from 'components/OverviewCard/OverviewCard'
import { useNavigateGated } from 'utils/routing/hooks/useNavigateGated'

const CardsBox = styled(Box)({
  display: 'flex',
  gap: '24px',
})

export const DataMapOverview: React.FC = () => {
  const navigateGated = useNavigateGated()
  const breadcrumbs = [{ title: 'Data Map' }]

  const handleCardClick = (item: SidebarRouteItem) => {
    navigateGated(
      item.path,
      item.permissions,
      item.entitlements,
      item.requireAllPermissions,
      item.requireAllEntitlements,
    )
  }

  // Get datamap routes
  const datamapRoutes = useSidebarRoutesConfig().find(route => route.path.includes(RoutesManager.systems.root.getURL()))
    ?.subRoutes as SidebarRouteItem[]

  const cards = datamapRoutes.map((item, index) => (
    <OverviewCard key={index} cardTitle={item.title} onClick={() => handleCardClick(item)} />
  ))

  return (
    <ListLayout headerTitle="Data Map" headerProps={{ isDivider: true }} sx={{ px: '154px', maxWidth: '500px' }}>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />

      {/* Visualization */}
      <DatamapOverviewVisualization />

      <Divider sx={{ mt: 6, mb: 2 }} />
      {/* Cards */}
      {Boolean(cards.length) && (
        <ContentGroup
          title="Connect & Discover"
          titleVariant="h3"
          subTitle="Connect and configure vendor and partner Systems for an end-to-end automation. Discover and label your data."
        >
          <CardsBox>{cards}</CardsBox>
        </ContentGroup>
      )}
    </ListLayout>
  )
}
