import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { WorkflowActivity } from 'interfaces/workflowActivities/workflowActivity'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
  includeIssues?: boolean
  canonicalRightCode?: string
}>

type Response = APIListResponse<{ activities: WorkflowActivity[] }>

export const fetchWorkflowActivities = ({
  organizationCode,
  includeMetadata,
  includeIssues,
  start,
  limit,
  canonicalRightCode,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/activities`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
          canonicalRightCode,
        },
      },
    }),
  )
