import type {
  HandleUploadParams,
  HandleDeleteParams,
  HandleDownloadOnClickParams,
  SetValue,
} from './FormDropZoneWithUpload.types'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/modals/AlertComponent'

/**
 * Handles the upload of files.
 *
 * @param params - The upload parameters.
 */
export const handleUpload = async ({ files, value, setValue, queryClient, onUploadComplete }: HandleUploadParams) => {
  setValue([...value, ...files]) // Combine current files and new files.
  if (onUploadComplete) onUploadComplete(files)
  await queryClient.refetchQueries(ApiQueryKeys.filesList) // Refetch file list queries after upload.
}

/**
 * Handles the deletion of a file.
 *
 * @param params - The deletion parameters.
 */
export const handleDelete = async ({
  attachment,
  value,
  handleDeleteFile,
  onDelete,
  maxFiles,
  required,
  setValue,
}: HandleDeleteParams) => {
  try {
    if (required && value.length === 1 && maxFiles > 1) return
    await handleDeleteFile({
      params: {
        fileUrl: attachment.download_url,
      },
    })
    showToast({ content: `Removed file ${attachment.name}`, type: 'success' }) // Show success message.
    if (onDelete) onDelete(attachment) // Execute additional deletion logic, if provided.
  } catch (error) {
    showToast({ content: `Unable to remove file ${attachment.name}`, type: 'error' }) // Show error message.
  }
  setValue?.(value.filter(v => v.ID !== attachment.ID)) // Remove the deleted file from current files.
}

/**
 * Handles the download of a file when clicked.
 *
 * @param params - The download parameters.
 */
export const handleDownloadOnClick = async ({ attachment, handleDownloadFile }: HandleDownloadOnClickParams) => {
  try {
    const { data } = await handleDownloadFile({
      params: {
        fileUrl: attachment.download_url, // Pass the file URL for download.
      },
    })
    let a: HTMLAnchorElement | null = document.createElement('a')
    a.href = URL.createObjectURL(data)
    a.download = attachment.name
    a.click()
    a = null
    showToast({ content: `Downloaded file ${attachment.name}`, type: 'success' }) // Show success message.
  } catch (error) {
    showToast({ content: `Unable to download file ${attachment.name}`, type: 'error' }) // Show error message.
  }
}

/**
 * Sets the value of the form field. If the field has not yet been touched, it will also mark it as touched.
 *
 * @param nextValue - The new value of the field.
 * @param setValue - Function to set the value of the field.
 * @param touched - Whether the field has been touched already.
 * @param setTouched - Function to set the touched state of the field.
 */
export function onSetValue(
  nextValue: FileInfoDTO[],
  setValue: SetValue,
  touched: boolean,
  setTouched: (touched: boolean) => void,
): void {
  setValue(nextValue)
  if (!touched) setTouched(true)
}

export const STANDARD_SUPPORTED_FILETYPES = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'application/pdf': ['.pdf'],
  // TODO:GP replace when we have an updated PDFTron license
  // 'application/msword': ['.doc'],
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  // 'application/vnd.ms-excel': ['.xls'],
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'text/csv': ['.csv'],
  'application/json': ['.json'],
}
