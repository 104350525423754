import { useState } from 'react'
import { useTrackersOnNewPropertiesInfinite } from 'api/trackers/queries/useTrackersOnNewPropertiesInfinite'
import { GetTrackersOnNewPropertiesParams } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

export const useTrackersOnNewPropertiesUtils = () => {
  const [trackersOnNewPropertiesParams, setTrackersOnNewPropertiesParams] = useState<GetTrackersOnNewPropertiesParams>({
    searchString: '',
  })

  const {
    trackers: trackersOnNewProperties,
    isLoading: isLoadingTrackersOnNewProperties,
    isFetching: isFetchingTrackersOnNewProperties,
    isFetchingNextPage: isFetchingNextTrackersOnNewProperties,
    hasNextPage: hasNextTrackersOnNewProperties,
    fetchNextPage: fetchNextTrackersOnNewProperties,
    totalResults: totalResultsForTrackersOnNewProperties,
  } = useTrackersOnNewPropertiesInfinite(trackersOnNewPropertiesParams)

  const isReady = !isLoadingTrackersOnNewProperties

  return {
    isReady,
    trackersOnNewProperties,
    isLoadingTrackersOnNewProperties,
    isFetchingTrackersOnNewProperties,
    isFetchingNextTrackersOnNewProperties,
    hasNextTrackersOnNewProperties,
    fetchNextTrackersOnNewProperties,
    totalResultsForTrackersOnNewProperties,
    trackersOnNewPropertiesParams,
    setTrackersOnNewPropertiesParams,
  }
}
