import React, { useState } from 'react'
import { GetAssetsStatisticsResponseBodyDTO, GetAssetsDataSystemsResponseBodyDTO } from '@ketch-com/figurehead'
import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { AssetsStatistics, AssetsDataSystems } from './components'
import { useAppDispatch } from 'store/hooks'
import { setSearchQuery, setFilterState, clearFilterState } from 'store/megaFilterStateSlice'
import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'
import { FILTER_STATES } from 'pages/assetManager/assets/list/components/MegaFilter/constants'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ReactComponent as EmptyDataSystemsIcon } from 'assets/icons/empty_data_systems.svg'
import { Spinner } from 'components/ui-kit/spinner/Spinner'

type Props = {
  isLoading: boolean
  assetStatistics: GetAssetsStatisticsResponseBodyDTO
  assetsDataSystems: GetAssetsDataSystemsResponseBodyDTO
}

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.iron.main}`,
}))

const BodyContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: 12,
  padding: theme.spacing(3, 2),
}))

export const AssetsHomePage: React.FC<Props> = ({ isLoading, assetStatistics, assetsDataSystems }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchString, setSearchString] = useState<string>('')
  const { dataSystems = 0, databases = 0, sensitiveDataAssets = 0, datasets = 0 } = assetStatistics
  const isEmptyData =
    !isLoading && !dataSystems && !databases && !sensitiveDataAssets && !datasets && !assetsDataSystems.totalResults

  return (
    <ContentBoundaries>
      <HeaderContainer>
        <Typography variant="h2">Assets</Typography>

        <SearchInput
          size="small"
          startSearchFromChar={0}
          debounceTimeout={0}
          value={searchString}
          placeholder="Search"
          onKeyPress={e => {
            if (e.key === 'Enter') {
              dispatch(setFilterState(FILTER_STATES.COLLAPSED))
              dispatch(clearFilterState())
              dispatch(setSearchQuery(searchString))
              navigate(RoutesManager.assetManager.assets.list.getURL())
            }
          }}
          onChange={() => {}}
          onSearch={nextValue => {
            setSearchString(nextValue)
          }}
          onClear={() => setSearchString('')}
        />
      </HeaderContainer>

      <BodyContainer>
        {isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" py={3}>
            <Box ml={4}>
              <Spinner />
            </Box>
          </Box>
        )}

        {!isLoading && !isEmptyData ? (
          <>
            <AssetsStatistics assetStatistics={assetStatistics} />
            <AssetsDataSystems assetsDataSystems={assetsDataSystems} />
          </>
        ) : null}

        {!isLoading && isEmptyData ? (
          <EmptyState
            icon={<EmptyDataSystemsIcon />}
            variant="list"
            bgVariant="white"
            title="No Data Systems yet"
            description="Try adding data systems and you'll see them populated here."
            actions={[
              {
                title: 'Add System',
                variant: 'primary',
                size: 'big',
                onClick: () => {
                  navigate(RoutesManager.systems.create.root.getURL())
                },
              },
            ]}
          />
        ) : null}
      </BodyContainer>
    </ContentBoundaries>
  )
}
