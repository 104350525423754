import { getUniqueDataSubjectTypes } from 'pages/consentAndRights/consent/systems/edit/utils'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

import React from 'react'
import { CanonicalPurposeDTO } from 'interfaces/purposes/canonicalPurpose'
import { Box, Tooltip } from '@mui/material'
import { Chip } from '@ketch-com/deck'

type Props = {
  canonicalPurpose: CanonicalPurposeDTO
}

export const PermitPropagationDataSubjectTypeBadge: React.FC<Props> = ({ canonicalPurpose }) => {
  const dataSubjectTypes = getUniqueDataSubjectTypes(canonicalPurpose)

  const tooltipContent = (
    <div>
      {dataSubjectTypes.map(dataSubjectType => (
        <div key={dataSubjectType.code}>{dataSubjectType.name}</div>
      ))}
    </div>
  )

  if (!dataSubjectTypes.length) {
    return <EmptyValueRenderer />
  }

  return (
    <Tooltip title={dataSubjectTypes.length !== 1 ? tooltipContent : undefined}>
      <Box>
        <Chip
          label={dataSubjectTypes.length === 1 ? dataSubjectTypes[0].name : 'Multiple'}
          size="small"
          clickable={dataSubjectTypes.length !== 1}
        />
      </Box>
    </Tooltip>
  )
}
