import { ProtectedRoute } from 'components/ui-routing/ProtectedRoute'
import { RoutesManager } from 'utils/routing/routesManager'

// pages
import { NotificationsListContainer } from './notifications/list/NotificationsListContainer'
import { TasksList } from './tasks/list/TasksList'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { Updates } from './Updates'

export const updatesRoutesChildren = [
  {
    element: <ProtectedRoute component={Updates} permissions={PERMISSIONS.RTINVQ_READ} />,
    path: RoutesManager.updates.root.pattern,
    children: [
      {
        element: <ProtectedRoute component={TasksList} permissions={PERMISSIONS.RTINVQ_READ} />,
        path: RoutesManager.updates.tasks.list.pattern,
      },
      {
        element: <ProtectedRoute component={NotificationsListContainer} permissions={PERMISSIONS.RTINVQ_READ} />,
        path: RoutesManager.updates.notifications.list.pattern,
      },
    ],
  },
]
