import { ListLayout } from '@ketch-com/deck'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { SectionTab } from 'interfaces/sectionTabs/sectionTabs'
import { max } from 'lodash'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useNavigateGated } from 'utils/routing/hooks/useNavigateGated'
import { RoutesManager } from 'utils/routing/routesManager'

const tabs: SectionTab[] = [
  {
    tabProps: {
      label: 'Requests',
    },
    path: RoutesManager.orchestration.rightsQueue.root.getURL(),
    permissions: [PERMISSIONS.RTINVQ_READ],
    entitlements: [ENTITLEMENTS.RIGHTS_MANAGEMENT, ENTITLEMENTS.WORKFLOWS],
  },
  {
    tabProps: {
      label: 'Workflows',
    },
    path: RoutesManager.orchestration.workflows.root.getURL(),
    permissions: [PERMISSIONS.WORKFLOW_READ],
    entitlements: [ENTITLEMENTS.RIGHTS_MANAGEMENT, ENTITLEMENTS.WORKFLOWS],
  },
  {
    tabProps: {
      label: 'Events',
    },
    path: RoutesManager.orchestration.events.root.getURL(),
    permissions: [PERMISSIONS.WORKFLOW_READ],
    entitlements: [ENTITLEMENTS.RIGHTS_MANAGEMENT, ENTITLEMENTS.WORKFLOWS],
  },
]

export const RightsSection: React.FC = () => {
  const navigate = useNavigate()
  const navigateGated = useNavigateGated()
  const { pathname } = useLocation()

  // Determine what tab is currently selected based on URL
  const tab = tabs.findIndex(t => pathname.includes(t.path))
  const tabItems = Object.values(tabs).map(t => t.tabProps)

  /* Redirect if base route is navigated to  */
  useEffect(() => {
    if (pathname === RoutesManager.deployment.rightsHome.root.getURL()) {
      navigate(RoutesManager.orchestration.rightsQueue.root.getURL())
    }
  }, [pathname, navigate])

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    const { path, entitlements, permissions } = tabs[newTab]
    navigateGated(path, permissions, entitlements)
  }

  return (
    <ListLayout headerTitle="Rights" tabsComponentProps={{ value: max([tab, 0]), onChange: handleTabChange, tabItems }}>
      <Outlet />
    </ListLayout>
  )
}
