import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import { ApiKeyDTO, ApiKeyStatus } from 'interfaces/apiKeys'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { ApiKeyListDropdown } from '../ApiKeyListDropdown'
import { SensitiveTextCellRenderer } from 'components/cellRenderers/SensitiveTextCellRenderer'

export const getApiKeyListColumns = (): GridColDef<ApiKeyDTO>[] => [
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'API Key',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ApiKeyDTO>) => {
      return <TableCell title={row.name} />
    },
  },
  {
    align: 'left',
    field: 'view',
    flex: 1,
    headerAlign: 'left',
    headerName: 'View',
    sortable: false,
    renderCell: ({ row: apiKey }: GridRenderCellParams<ApiKeyDTO>) => {
      return (
        <TableCell>
          <SensitiveTextCellRenderer value={apiKey?.id} />
        </TableCell>
      )
    },
  },

  {
    align: 'left',
    field: 'status',
    flex: 0.6,
    headerAlign: 'left',
    headerName: 'Status',
    sortable: false,
    renderCell: ({ row: { status } }: GridRenderCellParams<ApiKeyDTO>) => {
      return (
        <TableCell>
          <Status
            icon="Dot"
            label={status === ApiKeyStatus.ENABLED ? 'Active' : 'Deactivated'}
            variant={StatusVariant.ghost}
            status={status === ApiKeyStatus.ENABLED ? StatusState.success : StatusState.draft}
          />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'createdDate',
    flex: 0.7,
    headerAlign: 'left',
    headerName: 'Created',
    sortable: false,
    renderCell: ({ row: { createdAt } }: GridRenderCellParams<ApiKeyDTO>) => {
      return (
        <TableCell>
          <FormattedDateRenderer date={createdAt} />
        </TableCell>
      )
    },
  },
  {
    align: 'right',
    field: 'dropDown',
    headerAlign: 'left',
    headerName: '',
    sortable: false,
    flex: 1,
    maxWidth: 60,
    renderCell: ({ row: apiKey }: GridRenderCellParams<ApiKeyDTO>) => {
      return (
        <TableCell>
          <ApiKeyListDropdown apiKey={apiKey} />
        </TableCell>
      )
    },
  },
]
