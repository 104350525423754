import { faker } from '@faker-js/faker'

type Summary = {
  dataAssets?: number
  piiRecords?: number
  extreme?: number
  critical?: number
  tolerable?: number
  acceptable?: number
}

type DataRisksIntelligenceSummary = {
  data: Summary
  systems: Summary
}

export const generateDataRisksIntelligenceSummary = (): DataRisksIntelligenceSummary => {
  return {
    data: {
      dataAssets: faker.datatype.number({ min: 0, max: 1000000 }),
      piiRecords: faker.datatype.number({ min: 0, max: 1000000 }),
      extreme: faker.datatype.number({ min: 100000, max: 400000 }),
      critical: faker.datatype.number({ min: 100000, max: 1000000 }),
      tolerable: faker.datatype.number({ min: 100000, max: 1000000 }),
      acceptable: faker.datatype.number({ min: 100000, max: 1000000 }),
    },
    systems: {
      dataAssets: faker.datatype.number({ min: 0, max: 1000000 }),
      piiRecords: faker.datatype.number({ min: 0, max: 1000000 }),
      extreme: faker.datatype.number({ min: 100000, max: 400000 }),
      critical: faker.datatype.number({ min: 100000, max: 1000000 }),
      tolerable: faker.datatype.number({ min: 100000, max: 1000000 }),
      acceptable: faker.datatype.number({ min: 100000, max: 1000000 }),
    },
  }
}

type DataRisksIntelligenceAverageRiskScore = {
  data?: number
  systems?: number
  security?: number
}

export const generateDataRisksIntelligenceAverageRiskScore = (): DataRisksIntelligenceAverageRiskScore => {
  return {
    data: faker.datatype.number({ min: 0, max: 100 }),
    systems: faker.datatype.number({ min: 0, max: 100 }),
    security: faker.datatype.number({ min: 0, max: 100 }),
  }
}

type AccessAndUsageRisks = {
  percentage?: number
}

export const generateAccessAndUsageRisks = (): AccessAndUsageRisks => {
  return {
    percentage: faker.datatype.number({ min: 0, max: 100 }),
  }
}

// Only generate once so that refreshing the page works
export const summaryMockData = {
  data: {
    dataAssets: 755690,
    piiRecords: 856599,
    extreme: 217635,
    critical: 608495,
    tolerable: 696568,
    acceptable: 994929,
  },
  systems: {
    dataAssets: 895733,
    piiRecords: 923476,
    extreme: 323896,
    critical: 923299,
    tolerable: 615859,
    acceptable: 662164,
  },
}

export const averageRiskScoreMockData = {
  data: 32,
  systems: 58,
  security: 54,
}

export const accessAndUsageRisksMockData = {
  percentage: 75,
}
