import { CreateTeam200, PutTeamBody } from 'interfaces/services/harbormaster/teams_gen.schemas'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  formData: PutTeamBody
}

type Response = { team: CreateTeam200 }

export const createTeam = ({ formData }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/teams`,
    }),
    formData,
  )
