import { Button, DropListButton } from '@ketch-com/deck'
import { Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

type OptionType = { label: string; value: string }
export const MOCK_OPTIONS: Array<OptionType> = [
  {
    label: 'Orange',
    value: 'orange',
  },
  {
    label: 'Apple',
    value: 'apple',
  },
  {
    label: 'Pineapple',
    value: 'pineapple',
  },
  {
    label: 'Grapegrapegrapegrapegrapegrapegrapegrapegrapegrape',
    value: 'grape',
  },
]

type Props = {}

export const getValidationSchema = () =>
  Yup.object().shape({
    regularDropListButton: Yup.string(),
    multipleDropListButton: Yup.array().of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }),
    ),
  })

export const DropListButtonExample: React.FC<Props> = props => {
  const initialValues: {
    regularDropListButton: OptionType | null
    multipleDropListButton: OptionType[]
  } = { regularDropListButton: null, multipleDropListButton: [] }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => console.info('Form submitted with values:', values)}
      validationSchema={getValidationSchema()}
    >
      {formik => {
        const { values, setFieldValue } = formik
        return (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Typography variant="h3" mb={2}>
              DropListButton
            </Typography>

            <Typography variant="body" mt={1}>
              Regular DropListButton
            </Typography>

            <DropListButton
              options={MOCK_OPTIONS}
              onChange={(event, newValue) => {
                setFieldValue('regularDropListButton', newValue?.value)
              }}
              value={values.regularDropListButton}
            />
            <Typography variant="body" mt={1}>
              Multiple DropListButton
            </Typography>
            <DropListButton
              multiple
              options={MOCK_OPTIONS}
              onChange={(event, newValue) => {
                setFieldValue('multipleDropListButton', newValue)
              }}
              value={values.multipleDropListButton}
            />
            <Button sx={{ width: 100 }} type="submit">
              Submit
            </Button>

            <Button color="secondary" sx={{ width: 100 }} onClick={() => formik.resetForm()}>
              Clear
            </Button>
            <pre>{JSON.stringify(formik, null, 2) || 'nothing to preview'}</pre>
          </Form>
        )
      }}
    </Formik>
  )
}
