import React from 'react'
import { Box, Typography } from '@mui/material'
import { Spinner } from '@ketch-com/deck'

type Props = {
  isFetchingNextPage?: boolean
}

export const InfiniteScrollLoadingIndicator: React.FC<Props> = ({ isFetchingNextPage }) => {
  if (!isFetchingNextPage) return null
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      mb={2}
      py={1}
      sx={{
        backgroundColor: 'fadedGrey.main',
        borderRadius: '8px',
      }}
    >
      <Box marginRight={3}>
        <Spinner size="20px" thickness={2} />
      </Box>
      <Typography variant="smallLabel" color="darkGrey.main">
        Loading...
      </Typography>
    </Box>
  )
}
