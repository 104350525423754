import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createDataSystem } from '../fetchers/createDataSystem'

const useCustomMutation = createUseMutation({
  mutationFn: createDataSystem,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateDataSystem = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
