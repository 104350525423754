import React, { useMemo, useRef } from 'react'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ChipsAutocomplete } from '@ketch-com/deck'
import { ActionSheetItem, ListItemText, ChipsAutocompleteOptionType } from '@ketch-com/deck'
import { useSubscriptionTopicsPaginated } from 'api/subscriptions/queries/useSubscriptionTopicsPaginated'

export const ProgressiveExperienceSubscriptionsForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ExperienceV2DTO>()
  const ref = useRef()

  // Get subscription topic options
  const { data: subscriptionTopics } = useSubscriptionTopicsPaginated({
    params: {
      includeMetadata: true,
      limit: 1000,
    },
  })
  const subscriptionTopicOptions = useMemo(
    () =>
      subscriptionTopics?.topics
        ?.filter(topic => !!topic.code)
        .map(topic => ({
          label: topic.name,
          value: topic.code!,
        })) || [],
    [subscriptionTopics],
  )

  // Get the current subscription topics value
  const topicCodes = getIn(values, experienceUpsertFormKeys.progressiveExperienceSubscriptionTopicCodes) as string[]
  const topicCodesValue: ChipsAutocompleteOptionType[] = useMemo(() => {
    return topicCodes.map(code => ({
      label: subscriptionTopics?.topics?.find(topic => topic.code === code)?.name,
      value: code,
    }))
  }, [topicCodes, subscriptionTopics])

  const renderAutocompleteOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: ChipsAutocompleteOptionType,
  ) => (
    <ActionSheetItem {...props} key={option.value}>
      <ListItemText>{option.label}</ListItemText>
    </ActionSheetItem>
  )

  return (
    <SidebarFormGroup sx={{ pt: 0, gap: '8px' }}>
      <ChipsAutocomplete
        ref={ref.current}
        fullWidth
        renderOption={renderAutocompleteOption}
        isOptionEqualToValue={(elem, value) => elem.value === value.value}
        disableClearable
        label="Subscriptions"
        placeholder="Select a value"
        chipSize="medium"
        size="medium"
        multiple
        options={subscriptionTopicOptions}
        onChange={(_, newValue) => {
          setFieldValue(
            experienceUpsertFormKeys.progressiveExperienceSubscriptionTopicCodes,
            newValue.map(value => value.value),
          )
        }}
        handleChipClick={v => {
          const currentValueWithoutSubscriptionTopic = topicCodes.filter(code => code !== v.value)
          setFieldValue(
            experienceUpsertFormKeys.progressiveExperienceSubscriptionTopicCodes,
            currentValueWithoutSubscriptionTopic,
          )
        }}
        value={topicCodesValue}
      />
    </SidebarFormGroup>
  )
}
