import { InstalledDataSystemV2DTO } from '@ketch-com/figurehead'
import { Avatar, AvatarSize, Radio, TableCell, theme } from '@ketch-com/deck'

import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { FormikErrors } from 'formik'
import { Box, Typography } from '@mui/material'
import { Capabilites } from 'pages/dataSystems/components/Capabilities'

export const getInstalledDataSystemV2DTOColumns = ({
  setFieldValue,
  values,
}: {
  values: {
    id: string
  }
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<{}>>
}): GridColDef<InstalledDataSystemV2DTO>[] => [
  {
    align: 'left',
    field: 'radio',
    headerAlign: 'left',
    headerName: '',
    sortable: false,
    flex: 1,
    maxWidth: 50,

    renderCell: ({ row: { id } }: GridRenderCellParams<InstalledDataSystemV2DTO>) => {
      const isChecked = values.id === id

      const handleOnChange = () => {
        if (isChecked) {
          setFieldValue(`id`, null)
        } else {
          setFieldValue(`id`, id)
        }
      }

      return (
        <TableCell>
          <Radio checked={isChecked} value={isChecked} onChange={handleOnChange} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'name',
    headerAlign: 'left',
    headerName: 'Name',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { dataSystem } }: GridRenderCellParams<InstalledDataSystemV2DTO>) => {
      return (
        <TableCell>
          <Box display="flex" alignItems="center" gap={1}>
            {dataSystem?.logoUrl && (
              <Box>
                <Avatar
                  isLogo
                  src={dataSystem?.logoUrl}
                  alt={dataSystem?.name}
                  size={AvatarSize.xlarge}
                  variant="rounded"
                  backgroundColor={theme.palette.tertiary.main}
                />
              </Box>
            )}
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography component="div">{dataSystem?.name!}</Typography>
              <Typography component="div" variant="footnote">
                {dataSystem?.appCode}
              </Typography>
            </Box>
          </Box>
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'consent',
    headerAlign: 'left',
    headerName: 'Capabilities',
    sortable: false,
    flex: 1,

    renderCell: ({ row: { dataSystem } }: GridRenderCellParams<InstalledDataSystemV2DTO>) => {
      return (
        <TableCell>
          <Capabilites supportedCapabilites={dataSystem?.supportedCapabilities} />
        </TableCell>
      )
    },
  },
]
