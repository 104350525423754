import { ASSET_TYPE_ENUM } from 'interfaces/assets'
import { AssetSummaryDTO } from '@ketch-com/figurehead'

export const getAssetTypeHumanReadable = (assetSummary?: AssetSummaryDTO) => {
  const assetType = assetSummary?.asset?.resource?.resourceType?.canonicalResourceType || 0
  if (ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATABASE === assetType) return 'Database'
  if (ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATASET === assetType) return 'Dataset'
  if (ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_ATTRIBUTE === assetType) return 'Attribute'
  // TODO:BAC - not sure about this conditional.  Feels a bit hacky.
  if (assetSummary?.asset?.resource?.resourceType?.code?.includes?.('.schema')) return 'Schema'
  return 'Unspecified'
}
