import { AccessibilityButtonConfiguration } from '@ketch-sdk/ketch-types'
import { ApplicationIdentitySpaceFormat } from './ApplicationIdentitySpaceFormat'
import { ApplicationIdentitySpaceType } from './applicationIdentitySpaceType'
import { ApplicationPlatform } from './applicationPlatform'

export enum ApplicationFormSpecialValues {
  NO_DOCUMENT = 'No Document',
}

export type ApplicationIdentitySpace = {
  identitySpaceID: string // "Identifier" column
  type?: ApplicationIdentitySpaceType | '' // "Location" column
  variable?: string // "Name" column
  format?: ApplicationIdentitySpaceFormat | '' // "Format" column
  key?: string // "Key" column; required for all formats except ApplicationIdentitySpaceFormat.STRING
  applicationCode?: string
  applicationName?: string
}

export interface ApplicationAccessibilityButtonFormValue extends AccessibilityButtonConfiguration {
  enabled: boolean
}

export type ApplicationFormValues = {
  ID?: string
  code: string
  name: string
  platform: ApplicationPlatform
  defaultPolicyScopeVariable: string
  managedIdentity: boolean
  customIdentitySpaces: boolean
  customPolicyScope: boolean
  proxy: string
  supportProxy: boolean
  dataSubjectTypeCodes?: string[]
  environments: {
    code: string
    domain: string
    pattern: string
    themeID: string
  }[]
  identitySpaces: ApplicationIdentitySpace[]
  propertyCookies?: {
    cookieID: string
    propertyCode: string
    lastScanned?: number
    disclose?: boolean
  }[]
  accessibilityButton: ApplicationAccessibilityButtonFormValue
  progressiveConsentRules?: { list: string }
  privacyPolicyCode: string | ApplicationFormSpecialValues.NO_DOCUMENT
  termsOfServiceCode: string | ApplicationFormSpecialValues.NO_DOCUMENT
}
