import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createPolicyScope } from 'api/policyScopes/fetchers/createPolicyScope'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomMutation = createUseMutation({
  mutationFn: createPolicyScope,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreatePolicyScope = (config?: Config) => {
  const { userData } = useAuth()
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    organizationCode: userData.organizationCode,
  })
}
