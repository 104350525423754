import { useNavigate, useParams } from 'react-router-dom'

import { NEW_ITEM_ID } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'
import { showToast } from 'components/modals/AlertComponent'

import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
// import { mapValuesToPayload } from './utils'

import { AddConnectionFormEdit } from './AddConnectionFormEdit'
import { AddConnectionFormCreate } from './AddConnectionFormCreate'
import React, { CSSProperties } from 'react'
import { Box, styled } from '@mui/material'
import { useCreateConnection } from 'api/dataSystems/mutations/useCreateConnection'
import { useUpdateConnection } from 'api/dataSystems/mutations/useUpdateConnection'
import {
  AppDescriptorDTO,
  AppFormDTO,
  AppFormFieldDTO,
  AppInstanceDTO,
  AppInstanceFieldDTO,
  AppMarketPlaceEntityDTO,
} from 'interfaces/apps'
import { useInstalledDataSystem } from 'api/dataSystems/queries/useInstalledDataSystem'
import { useConnection } from 'api/dataSystems/queries/useConnection'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { ConnectionV2DTO, CreateConnectionV2RequestBodyConnectionDTO } from '@ketch-com/figurehead'
import { fetchDataSystemSetupURL } from 'api/dataSystems/fetchers/fetchDataSystemSetupURL'
import { getHostURL } from 'utils/routing/hostURL'
import { useAuth } from 'utils/hooks'

const Root = styled(Box)(({ theme }) => ({}))

type Props = {
  className?: string
  style?: CSSProperties
}

export const AddConnectionForm: React.FC<Props> = ({ className, style }) => {
  const { id, instanceId } = useParams<{ id: string; instanceId: string }>()

  const navigate = useNavigate()

  const { userData } = useAuth()

  const isEditMode = !!instanceId && instanceId !== NEW_ITEM_ID

  const { data: installedDataSystem, isLoading: isLoadingInstalledDataSystem } = useInstalledDataSystem({
    params: {
      id: id!,
    },
    onError: () => navigate(RoutesManager.systems.id.overview.root.getURL({ id })),
  })

  const { data: connection, isLoading: isLoadingConnection } = useConnection({
    enabled: isEditMode && !!id && !!instanceId,
    params: {
      id: instanceId!,
    },
  })

  const { data: identitySpacesList, isLoading: isLoadingIdentitySpaces } = useIdentitySpaces()

  const queryClient = useQueryClient()

  const handleSuccess = async ({ connection }: { connection: ConnectionV2DTO | undefined }) => {
    if (!connection?.id) {
      return
    }
    const appCode = connection.dataSystem?.appCode || ''
    const appInstanceId = connection.appInstanceId || ''
    const dataSystemId = connection.installedDataSystemId || ''

    await queryClient.refetchQueries(ApiQueryKeys.entitlements)

    const viewAppURL = RoutesManager.systems.id.connections.root.getURL({ id })

    const installURL = await fetchDataSystemSetupURL({ code: connection.dataSystem?.appCode })
    let setupUrl = decodeURI(installURL.data.setupUrl)
    if (setupUrl.includes('authorize')) {
      //it is oauth app new authorize url /offshore/authorize/{authConfigID}/{appInstanceID}
      setupUrl = setupUrl.replace('{authConfigID}', appCode)
      setupUrl = setupUrl.replace('{appInstanceID}', appInstanceId)
      setupUrl = setupUrl + `?dataSystemID=${dataSystemId}&tenant=${userData.organizationCode}`
      window.location.href = `${setupUrl}`
    } else if (setupUrl.includes('setup')) {
      window.location.href = `${
        installURL.data.setupUrl
      }?redirectURL=${getHostURL()}${viewAppURL}&appInstanceID=${connection?.appInstanceId}&orgCode=${
        userData.organizationCode
      }`
    } else {
      navigate(viewAppURL)
    }
  }

  const { mutateAsync: handleCreateConnection } = useCreateConnection({
    onSuccess: async ({ data }) => {
      showToast({ content: 'Connection created', type: 'success' })

      handleSuccess({ connection: data.connection })
    },
    onError: () => {
      showToast({ content: 'Failed to create connection', type: 'error' })
    },
  })

  const { mutateAsync: handleUpdateConnection } = useUpdateConnection({
    onSuccess: async ({ data }) => {
      showToast({ content: 'Connection updated', type: 'success' })

      handleSuccess({ connection: data.connection })
    },
    onError: () => {
      showToast({ content: 'Failed to update connection', type: 'error' })
    },
  })

  const isReady = !isLoadingInstalledDataSystem && !isLoadingConnection && !isLoadingIdentitySpaces

  const handleSubmit = async (values: AppFormDTO) => {
    const valuesRemap: CreateConnectionV2RequestBodyConnectionDTO = {
      ...values,
      appId: installedDataSystem?.dataSystem?.appId,
      installedDataSystemId: id,
      formFields: values.fields,
      identitySpaceMappings: values.identitySpaces.map((identitySpace: any) => {
        return {
          destinationIdentitySpace: identitySpace.userIdentitySpaceCode,
          sourceIdentitySpace: identitySpace.code,
        }
      }),
    }

    if (isEditMode) {
      handleUpdateConnection({
        params: {
          id: instanceId,
          formData: {
            connection: valuesRemap,
          },
        },
      })
    } else {
      handleCreateConnection({
        params: {
          formData: {
            connection: valuesRemap,
          },
        },
      })
    }
  }

  const appDescriptor: AppDescriptorDTO = {
    app: {
      ID: installedDataSystem?.id!,
      appInstanceIDs: installedDataSystem?.connections?.map(connection => connection.id!)!,
      shortDescription: installedDataSystem?.description!,
      supportedLanguages: [],
      logo: {
        title: installedDataSystem?.dataSystem?.name!,
        URL: installedDataSystem?.dataSystem?.logoUrl!,
      },
      code: installedDataSystem?.dataSystemCode,
      capabilities: installedDataSystem?.dataSystem?.supportedCapabilities!,
      orgCode: '',
      name: installedDataSystem?.dataSystem?.name ?? '',
      version: '',
      homepageUrl: '',
      userAuthCallbackUrl: '',
      hasCredentialsIssue: false,
      setupUrl: '',
      infoUrl: '',
      permissions: [],
      primaryCategory: 0,
      supportedPurposes: installedDataSystem?.dataSystem?.supportedPurposes!,
      formTitle: '',
      formSubtitle: '',
      form: installedDataSystem?.dataSystem?.form as AppFormFieldDTO[],
      identitySpaces: installedDataSystem?.dataSystem?.identitySpaces as any,
      supportedRights: installedDataSystem?.dataSystem?.supportedRights!,
      rules: {
        install: '',
        view: '',
      },
      refreshInterval: 0,
    },
    capabilities: [false, false, false, false, false, false, false, false, false],
    marketplaceEntity: {
      ...(installedDataSystem?.dataSystem! as AppMarketPlaceEntityDTO),
      docUrl: installedDataSystem?.dataSystem?.documentURLs?.docURL || '',
    },
  }

  const appInstance: AppInstanceDTO = {
    ID: connection?.appInstanceId!,
    code: connection?.code!,
    name: connection?.name!,
    status: connection?.status,
    description: connection?.description!,
    appID: connection?.dataSystem?.appId!,
    orgCode: '',
    version: '',
    createdAt: undefined,
    updatedAt: undefined,
    hasCredentialsIssue: false,
    fields: connection?.formFields as AppInstanceFieldDTO[],
    purposes: connection?.enabledPurposes,
    identitySpaceMappings: connection?.identitySpaceMappings as any,
  }

  const appInstanceFields: AppInstanceFieldDTO[] = connection?.formFields as AppInstanceFieldDTO[]

  return (
    <Root className={className} style={style}>
      {isEditMode ? (
        <AddConnectionFormEdit
          appDescriptor={appDescriptor}
          appInstance={appInstance!}
          appInstanceFields={appInstanceFields}
          identitySpaces={identitySpacesList}
          isEditMode={isEditMode}
          isReady={isReady}
          onSubmit={handleSubmit}
        />
      ) : (
        <AddConnectionFormCreate
          appDescriptor={appDescriptor}
          appInstance={appInstance!}
          appInstanceFields={appInstanceFields}
          identitySpaces={identitySpacesList}
          isEditMode={isEditMode}
          isReady={isReady}
          onSubmit={handleSubmit}
        />
      )}
    </Root>
  )
}
