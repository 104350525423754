import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { createTheme } from 'api/themes-v2/fetchers/createTheme'
import { useAuth } from 'utils/hooks/useAuth'

const useCustomMutation = createUseMutation({
  mutationFn: createTheme,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateTheme = (config?: Config) => {
  const { userData } = useAuth()
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    organizationCode: userData.organizationCode,
  })
}
