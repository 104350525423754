import { FC } from 'react'
import { Formik } from 'formik'
import { PopUp, Button } from '@ketch-com/deck'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'
import { useExtendRequestModalUtils } from './hooks'
import { extendRequestFormKeys } from './utils/getValidationSchema'
import { FormInput } from 'components/form/FormInput'

type Props = {
  onClose: () => void
}

export const ExtendRequestModal: FC<Props> = ({ onClose }) => {
  const { initialValues, validationSchema, resolve, rightInvocation } = useExtendRequestModalUtils({ onClose })

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={resolve}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ values, isSubmitting, handleSubmit, errors, touched }) => {
        logObjectDetails(
          {
            isSubmitting,
            values,
            errors,
            touched,
            rightInvocation,
          },
          'ExtendRequestModal.tsx',
          'white',
          false, // false ensures will log only in debug mode (i.e. ?debug=log)
        )
        return (
          <>
            <PopUp
              isOpen
              isLoading={isSubmitting}
              onClose={onClose}
              variant="modal"
              showCloseButton
              title="Extend Fulfillment Time"
              popUpActionChildren={
                <>
                  <Button color="secondary" onClick={() => onClose()} size="large">
                    Cancel
                  </Button>
                  <Button size="large" onClick={() => handleSubmit()}>
                    Confirm
                  </Button>
                </>
              }
            >
              <Box display="flex" flexDirection="column">
                <Typography variant="body" color="darkDusk.main" mb={4}>
                  Specify how many days you would like to extend this request's fulfillment time.
                </Typography>

                <Box display="flex" flexDirection="column" gap={4}>
                  <FormInput
                    label="Extension Period (days)"
                    type="number"
                    formPropertyName={extendRequestFormKeys.extensionDays}
                    inputWidth="70px"
                    required
                  />

                  <FormInput
                    label="Reason For Extension"
                    formPropertyName={extendRequestFormKeys.extensionReason}
                    fullWidth
                    rows={5}
                    multiline
                    shouldUpdateDebounced
                  />
                </Box>
              </Box>
            </PopUp>
          </>
        )
      }}
    </Formik>
  )
}
