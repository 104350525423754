import { configureStore } from '@reduxjs/toolkit'
import selectedDatabasesReducer from './dsrTransponderFormSlice'
import megaFilterReducer from './megaFilterStateSlice'
import eventsListFilterReducer from './eventsListFilterSlice'
import policiesListFilterReducer from './policiesListFilterSlice'
import permitStatisticsFilterReducer from './permitStatisticsFilterStateSlice'
import paginationReducer from './paginationPreservationSlice'
import webTagsListFiltersReducer from './webTagsListFilterSlice'
import rightsQueueFilterReducer from './rightsQueueFilterSlice'
import cookiesListFilterReducer from './cookiesListFilterSlice'
import activityAndAccessReducer from './activityAndAccessFilterStateSlice'
import navigationReducer from './navigationSlice'
import assetDetailViewReducer from './assetDetailViewSlice'
import experienceBuilderFormStateReducer from './experienceBuilderFormStateFilterSlice'
import workflowCanvasCopiedStepsStateReducer from './workflowCanvasCopiedStepsSlice'

export const store = configureStore({
  reducer: {
    dsrForm: selectedDatabasesReducer,
    megaFilter: megaFilterReducer,
    eventsListFilter: eventsListFilterReducer,
    policiesListFilter: policiesListFilterReducer,
    permitStatisticsFilter: permitStatisticsFilterReducer,
    paginationState: paginationReducer,
    webTagsListFilters: webTagsListFiltersReducer,
    rightsQueueFilters: rightsQueueFilterReducer,
    cookiesListFilters: cookiesListFilterReducer,
    activityAndAccessFilter: activityAndAccessReducer,
    navigation: navigationReducer,
    assetDetailView: assetDetailViewReducer,
    experienceBuilderFormState: experienceBuilderFormStateReducer,
    workflowCanvasCopiedStepsState: workflowCanvasCopiedStepsStateReducer,
  },
})
