import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  userId: string
}>

type Response = {}

export const updateUserPassword = ({ userId }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/users/${userId}/password`,
    }),
  )
