import React from 'react'
import { useSetState } from 'react-use'
import { useNavigate } from 'react-router-dom'

import { FormMode } from 'interfaces/formModes/formMode'
import { ExperienceDTO } from 'interfaces/experiences/experience'
import { ExperienceUrlTypeParam } from 'interfaces/experiences/experienceUrlTypeParam'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { SENSITIVE_EXPERIENCE_STATUSES } from 'interfaces/experiences/experienceStatus'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { Group } from 'components/ui-layouts/group/Group'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Text } from 'components/ui-kit/typography/Text'
import { Button } from 'components/ui-kit/button/Button'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'
import { ExperienceDeleteModal } from 'pages/consentAndRights/experiences/modals/ExperienceDeleteModal'

type Props = {
  experience: ExperienceDTO
}

export const ExperienceViewActions: React.FC<Props> = ({ experience }) => {
  const navigate = useNavigate()

  const { isPermitted } = useIsPermitted()
  const isPermittedToManageExperiences = isPermitted(PERMISSIONS.EXP_WRITE)

  const { consent, code, metadata, status } = experience
  const isInSensitiveStatus = SENSITIVE_EXPERIENCE_STATUSES.includes(status)

  const [{ isDeleteOpen, isEditConfirmationOpen }, setState] = useSetState({
    isDeleteOpen: false,
    isEditConfirmationOpen: false,
  })

  const handleRedirectToEdit = () => {
    navigate(
      RoutesManager.deployment.experiences.upsert.root.getURL({
        code,
        formMode: FormMode.EDIT,
        experienceType: consent ? ExperienceUrlTypeParam.CONSENT : ExperienceUrlTypeParam.PREFERENCE,
      }),
    )
  }

  return (
    <>
      <Group>
        <Group gap={3}>
          <Text size={12} color="grey" weight={500}>
            Last Updated
          </Text>
          <Text size={12} weight={500}>
            <FormattedDateRenderer date={metadata?.createdAt} />
          </Text>
        </Group>

        {isPermittedToManageExperiences && (
          <>
            <Button
              disabled={isInSensitiveStatus}
              tooltip={isInSensitiveStatus ? 'Cannot delete experiences while deployed' : null}
              variant="tertiary"
              size="big"
              onClick={() => {
                setState({ isDeleteOpen: true })
              }}
            >
              Delete
            </Button>

            <Button
              variant="secondary"
              size="big"
              onClick={() => {
                if (isInSensitiveStatus) {
                  setState({ isEditConfirmationOpen: true })
                } else {
                  handleRedirectToEdit()
                }
              }}
            >
              Edit
            </Button>
          </>
        )}

        <Button
          size="big"
          onClick={() => {
            navigate(
              RoutesManager.deployment.experiences.preview.root.getURL({
                code,
                experienceType: consent ? ExperienceUrlTypeParam.CONSENT : ExperienceUrlTypeParam.PREFERENCE,
              }),
            )
          }}
        >
          Preview
        </Button>
      </Group>

      {isDeleteOpen && (
        <ExperienceDeleteModal
          experience={experience}
          onClose={() => {
            setState({ isDeleteOpen: false })
          }}
          onDelete={() => {
            navigate(RoutesManager.deployment.experiences.root.getURL())
          }}
        />
      )}

      {isEditConfirmationOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.EXPERIENCE}
          onSubmit={handleRedirectToEdit}
          onCancel={() => {
            setState({ isEditConfirmationOpen: false })
          }}
        />
      )}
    </>
  )
}
