import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  id: string
  formData: ExperienceV2DTO
}

export const updateExperience = ({ id, formData }: Params) =>
  API.put<ExperienceV2DTO>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/experiences/${id}`,
    }),
    { experience: formData },
  )
