import { ICanvasStep } from '../../interfaces'
import { getStepTransitions } from './getStepTransitions'
import {
  WorkflowActivityConnectorCode,
  WorkflowActivityGatewayMode,
} from 'interfaces/workflowActivities/workflowActivity'
import { isNewStepConnectedNestStart } from './isNewStepConnectedNestStart'

type Props = {
  steps: ICanvasStep[]
  step: ICanvasStep
  onlyUntilNextNestStart?: boolean
}

export const getNextJoinTile = ({ steps, step, onlyUntilNextNestStart = false }: Props): ICanvasStep | undefined => {
  const realStep = steps.find(({ ID }) => ID === step.ID)

  if (!realStep || realStep.workflow?.code === WorkflowActivityConnectorCode.END) return undefined

  const transitionStepId = getStepTransitions(realStep)[0]
  const transitionStep = steps.find(({ ID }) => ID === transitionStepId)

  if (!transitionStep || transitionStep.workflow?.code === WorkflowActivityConnectorCode.END) return undefined

  if (onlyUntilNextNestStart && isNewStepConnectedNestStart({ step: transitionStep, steps })) {
    return undefined
  }

  if (transitionStep?.gateway?.mode !== WorkflowActivityGatewayMode.JOIN) {
    return getNextJoinTile({ steps, step: transitionStep })
  }

  return transitionStep
}

export const getTileBeforeNextJoinTile = ({ steps, step }: Props): ICanvasStep | undefined => {
  const realStep = steps.find(({ ID }) => ID === step.ID)

  if (!realStep) return undefined

  const transitionStepId = getStepTransitions(realStep)[0]
  const transitionStep = steps.find(({ ID }) => ID === transitionStepId)

  if (!transitionStep) return undefined

  if (transitionStep?.gateway?.mode !== WorkflowActivityGatewayMode.JOIN) {
    return getTileBeforeNextJoinTile({ steps, step: transitionStep })
  }

  return realStep
}
