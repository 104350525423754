import { PoliciesList } from 'pages/policyCenter/policies/listV2/PoliciesList'
import { usePoliciesPaginatedV2 } from 'api/policies/queries/usePoliciesPaginatedV2'
import { getSerializedPolicy } from './__mocks__/'

export const PoliciesListContainer: React.FC = () => {
  const {
    data: policies,
    isLoading,
    pagination,
  } = usePoliciesPaginatedV2({
    itemsPerPage: 10,
    params: {},
  })

  const serializedPolicies = policies?.map(policy => getSerializedPolicy(policy))

  return <PoliciesList isLoading={isLoading} policies={serializedPolicies} pagination={pagination} />
}
