import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { CreateHookFormValues } from '../components/CreateHookModal/utils/getInitialValues'
import { HookTypeFilterSelectOptions } from '../constants'
import { mapFormValuesToFormData } from '../components/CreateHookModal/utils'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { useDmlHooks } from 'api/dmlHooks/queries/useDmlHooks'
import { useDebounce } from 'react-use'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { HookTypeEnum } from '../../constants'
import { useCreateDmlHook } from 'api/dmlHooks/mutations/useCreateDmlHook'
import { useUpdateDmlHook } from 'api/dmlHooks/mutations/useUpdateDmlHook'
import { useDeleteDmlHook } from 'api/dmlHooks/mutations/useDeleteDmlHook'

export const useHooksListUtils = () => {
  const { isEntitled } = useIsEntitled()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const [searchString, setSearchString] = useState('')
  const [searchQueryValue, setSearchQueryValue] = useState('')
  const [isCreateHookModalOpen, setIsCreateHookModalOpen] = useState<boolean>(false)
  const [hookToDeleteId, setHookToDeleteId] = useState<string>('')
  const [hookToEditId, setHookToEditId] = useState<string>('')
  const [hookTypeFilterSelect, setHookTypeFilterSelect] = useState<HookTypeFilterSelectOptions>(
    HookTypeFilterSelectOptions.ALL,
  )
  const isPermittedToCreateHooks = isPermitted([PERMISSIONS.API_KEY_WRITE])
  const isEntitledToCreateHooks = isEntitled(ENTITLEMENTS.HOOKS)

  const {
    data: hooks,
    isLoading: isLoadingHooks,
    pagination,
  } = useDmlHooks({
    params: {
      query: searchQueryValue,
      type: hookTypeFilterSelect === 'All' ? undefined : String(HookTypeEnum[hookTypeFilterSelect]),
    },
    onError: () => {
      showToast({ content: 'Failed to fetch Hooks', type: 'error' })
    },
  })

  const { mutateAsync: handleCreateDmlHook } = useCreateDmlHook({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.dmlHooks])
    },
  })

  const { mutateAsync: handleUpdateDmlHook } = useUpdateDmlHook({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.dmlHooks])
      showToast({ content: 'Hook updated', type: 'success' })
      setHookToEditId('')
    },
  })

  const { mutateAsync: handleDeleteDmlHook } = useDeleteDmlHook({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.dmlHooks])
      setHookToDeleteId('')
      showToast({ content: 'Hook deleted', type: 'success' })
    },
  })

  const handleCreateHookOnClick = () => setIsCreateHookModalOpen(true)

  const handleSubmit = async (values: CreateHookFormValues) => {
    const formData = mapFormValuesToFormData(values)

    hookToEditId
      ? await handleUpdateDmlHook({
          onError: () => showToast({ content: 'Failed to update hook', type: 'error' }),
          onSuccess: () => showToast({ content: 'Hook updated', type: 'success' }),
          params: {
            formData,
            hookId: hookToEditId,
          },
        })
      : await handleCreateDmlHook({
          onError: () => showToast({ content: 'Failed to create hook', type: 'error' }),
          onSuccess: () => {
            setIsCreateHookModalOpen(false)
            showToast({ content: 'Hook created', type: 'success' })
          },
          params: {
            formData,
          },
        })
  }

  const handleSubmitDeleteHook = async () => {
    await handleDeleteDmlHook({
      onError: () => showToast({ content: 'Failed to delete hook', type: 'error' }),
      onSuccess: () => {
        showToast({ content: 'Hook deleted', type: 'success' })
      },
      params: {
        hookId: hookToEditId,
      },
    })
  }

  useDebounce(
    () => {
      if (searchString) {
        setSearchQueryValue(searchString)
      } else {
        setSearchQueryValue('')
      }
    },
    500,
    [searchString],
  )

  return {
    handleCreateHookOnClick,
    handleSubmit,
    handleSubmitDeleteHook,
    hooks,
    hookToDeleteId,
    hookToEditId,
    hookTypeFilterSelect,
    isCreateHookModalOpen,
    isLoadingHooks,
    isPermittedToCreateHooks,
    isEntitledToCreateHooks,
    pagination,
    searchString,
    setHookToDeleteId,
    setHookToEditId,
    setHookTypeFilterSelect,
    setIsCreateHookModalOpen,
    setSearchString,
  }
}
