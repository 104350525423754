import { WorkflowDTO, WorkflowFormDTO } from 'interfaces/workflows'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  workflowCode: string
  formData: WorkflowFormDTO
}

type Response = { workflow: WorkflowDTO }

export const updateWorkflow = ({ organizationCode, workflowCode, formData }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/workflows/${workflowCode}`,
    }),
    formData,
  )
