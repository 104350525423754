import React from 'react'
import { Box } from '@mui/material'
import clsx from 'clsx'

import { ASSET_TYPE_NAMES } from 'interfaces/assets'
import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'

import { AllowAccessChip, AssetChip } from '../components'
import { getAllowAccessChipText, getNamedOrLabeled } from '../utils'
import { useTreeStyles } from '../hooks'

type Props = {
  policy: AssetPolicyDTO
}

export const AllowAccessSection: React.FC<Props> = ({ policy }) => {
  const classes = useTreeStyles()
  const { assets } = policy

  /* Allow Access to: */
  return (
    <>
      <Box display="flex" alignItems="center" mt={1}>
        <Box>
          <AllowAccessChip />
        </Box>
        <Box ml={1.5}>
          <Text size={14}>to:</Text>
        </Box>
      </Box>

      <Box className={classes.treeContainer}>
        {(assets || []).map(ass => (
          <Box key={JSON.stringify(ass)} className={classes.treeNodeSVG}>
            <Box className={classes.treeChild}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Text className={clsx(classes.withMarginRight, classes.capitalize)} size={14}>
                    {ASSET_TYPE_NAMES[ass?.type || 0]}s
                  </Text>

                  <Text className={classes.withMarginRight} size={14} weight={600}>
                    {getNamedOrLabeled(ass)}
                  </Text>

                  <AssetChip name={getAllowAccessChipText({ type: getNamedOrLabeled(ass), ass })} />
                </Box>
                <Box display="flex" alignItems="center">
                  <Text size={11} weight={500} color="darkDuskFaded">
                    {/* TODO:BAC - Make dynamic.  This will need to be returned on the fetched policy */}
                    42 assets affected
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  )
}
