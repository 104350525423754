import { forwardRef, HTMLAttributes } from 'react'
import Box from '@mui/material/Box'
import { makeStyles, createStyles } from '@mui/styles'
import clsx from 'clsx'

import { ReactComponent as RemoveBadgeIcon } from 'assets/icons/remove_icon_bold.svg'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseBadgeContainer: {
        display: 'inline-flex',
        overflow: 'hidden',
        borderRadius: 5,
        fontFamily: typography.fontFamily,
        fontSize: typography.pxToRem(11),
        lineHeight: typography.pxToRem(13),
        fontWeight: 500,
        letterSpacing: '0.01em',
        border: `1px solid ${palette.fadedGrey.main}`,
      },
      badgeValue: {
        textAlign: 'center',
        padding: '5px 8px',
        background: 'none',
      },
      badgeKey: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 8px',
        textAlign: 'center',
      },

      // badgeKey Types:
      typeKeyLightPink: {
        background: palette.bloom.main,
        color: palette.darkViolet.main,
      },
      typeKeyDarkBlue: {
        background: palette.marine.main,
        color: palette.persianBlue.main,
      },
      typeKeyNeutral: {
        background: palette.fadedGrey.main,
        color: palette.darkChrome.main,
      },

      // badgeContainer Types:
      typeContainerLightPink: {
        color: palette.darkViolet.main,
      },
      typeContainerDarkBlue: {
        color: palette.persianBlue.main,
      },
      typeContainerNeutral: {
        color: palette.darkChrome.main,
      },
      removeBadgeContainer: {
        cursor: 'pointer',
      },
    }),
  { name: 'BadgeKeyValue' },
)

interface Props extends HTMLAttributes<HTMLDivElement> {
  /** Status variant - reflects the colour of border and background */
  variant?: 'light-pink' | 'dark-blue' | 'neutral'
  /** Status custom className */
  className?: string
  /** Left side display value */
  badgeKey: string
  /** Right side display value */
  badgeValue: string
  /** Optional function param when Badge is intended for use in form where badges are removable */
  onRemoveBadge?: () => void
}

/**
 * -
 */
export const BadgeKeyValue = forwardRef<HTMLDivElement, Props>(function BadgeKeyValue(
  { variant = 'neutral', className, badgeKey, badgeValue, onRemoveBadge },
  ref,
) {
  const classes = useStyles()

  const badgeContainerClassName = clsx(
    classes.baseBadgeContainer,
    {
      [classes.typeContainerLightPink]: variant === 'light-pink',
      [classes.typeContainerDarkBlue]: variant === 'dark-blue',
      [classes.typeContainerNeutral]: variant === 'neutral',
    },
    className,
  )

  const badgeKeyClassName = clsx(classes.badgeKey, {
    [classes.typeKeyLightPink]: variant === 'light-pink',
    [classes.typeKeyDarkBlue]: variant === 'dark-blue',
    [classes.typeKeyNeutral]: variant === 'neutral',
  })

  return (
    <div ref={ref} className={badgeContainerClassName}>
      <div className={badgeKeyClassName}>{badgeKey}</div>
      <div className={classes.badgeValue}>
        <Box display="flex" alignItems="center">
          {badgeValue}
          {!!onRemoveBadge && (
            <Box
              display="flex"
              alignItems="center"
              className={classes.removeBadgeContainer}
              ml={2}
              onClick={onRemoveBadge}
            >
              <RemoveBadgeIcon />
            </Box>
          )}
        </Box>
      </div>
    </div>
  )
})
