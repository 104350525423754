import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'

import { useDebounce } from 'react-use'
import { useFilters } from 'utils/hooks/useFiltersV2'
import { useInfiniteTasks } from 'api/tasks/queries/useInfiniteTasks'
import Typography from '@mui/material/Typography'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { TabSegmented, TabSegmentedGroup } from '@ketch-com/deck'

type Props = {
  count: number
}

export const TasksListFilters: React.FC<Props> = ({ count }) => {
  const { replaceParams, removeSingleSearchParam, searchParams } = useFilters()
  const [searchString, setSearchString] = useState<string>(() => {
    return searchParams.get('search') || ''
  })

  const { totalResults: tasksTodoTotalResults } = useInfiniteTasks({
    status: '1',
  })

  const { totalResults: tasksCompletedTotalResults } = useInfiniteTasks({
    status: '2',
  })

  useEffect(() => {
    if (!searchParams.get('status')) {
      replaceParams({ status: '1' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useDebounce(
    () => {
      if (searchString && searchString !== searchParams.get('search')) {
        replaceParams({ search: searchString })
      }
      if (!searchString) {
        removeSingleSearchParam('search')
      }
    },
    500,
    [searchString],
  )

  return (
    <Box display="flex" alignItems="center" gap={1.5} width="100%">
      <Box display="flex" alignItems="center" flex={1.5}>
        <SearchTextInput
          onChange={nextSearchString => setSearchString(nextSearchString)}
          value={searchString}
          showClearButton
          size="small"
          fullWidth
          placeholder="Search Activity Name or Request ID"
        />
      </Box>

      <Box display="flex" alignItems="center" flex={3}>
        <TabSegmentedGroup
          size="small"
          exclusive
          value={searchParams.get('status') || '1'}
          onChange={(_, nextStatus) => replaceParams({ status: nextStatus })}
        >
          <TabSegmented
            value={'1'}
            key={1}
            sx={{
              backgroundColor: 'white.main',
            }}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="label" mr={0.5}>
                To Do
              </Typography>
              <Typography variant="label" color="darkDuskFaded.main">
                {tasksTodoTotalResults}
              </Typography>
            </Box>
          </TabSegmented>
          <TabSegmented value={'2'} key={2}>
            <Box display="flex" alignItems="center">
              <Typography variant="label" mr={0.5}>
                Completed
              </Typography>
              <Typography variant="label" color="darkDuskFaded.main">
                {tasksCompletedTotalResults}
              </Typography>
            </Box>
          </TabSegmented>
        </TabSegmentedGroup>
      </Box>
    </Box>
  )
}
