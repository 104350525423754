import { TextInputColor, TextInputColorProps } from '@ketch-com/deck'
import { ChangeEvent, ClipboardEventHandler, useRef } from 'react'
import { getIn, useFormikContext } from 'formik'
import { isUndefined } from 'lodash'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { isValidHexColor } from '../../../utils/utils'
import { Box, Typography } from '@mui/material'

type Props = { name: string; label: string | React.ReactElement } & Omit<TextInputColorProps, 'value' | 'ref' | 'label'>

// TODO - Integrate this through the figurehead `FormInputColor` component
export const FormColorInput: React.FC<Props> = ({ name, label, ...props }) => {
  const { values, setFieldValue } = useFormikContext<ThemeV3DTO>()
  const value = getIn(values, name)

  const lastValidColor = useRef(isValidHexColor(value) ? value : '#000000')

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const color = e.target?.value

    if (isValidHexColor(color)) {
      lastValidColor.current = color
    }

    if (name) setFieldValue(name, color)
  }

  // Reset to the last valid if the current color is invalid when the field is blurred
  const handleBlur = () => {
    if (!isValidHexColor(value) && name) {
      setFieldValue(name, lastValidColor.current)
    }
  }

  // Handle pastes into this input, overrides the default behaviour by replacing text
  // even if pasted content doesn't fit, meaning user doesn't need to delete the entire
  // hex string in the input before they can paste.
  const handlePaste: ClipboardEventHandler = e => {
    const pasteContent = e.clipboardData.getData('text')
    if (isValidHexColor(pasteContent) && name) setFieldValue(name, pasteContent)
  }

  return (
    <Box>
      <Typography variant="label" component={'div'} mb={'4px'}>
        {label}
      </Typography>
      <TextInputColor
        value={isUndefined(value) ? '' : value}
        onChange={handleChange}
        onBlur={handleBlur}
        onPaste={handlePaste}
        {...props}
      />
    </Box>
  )
}
