import React from 'react'

import { AssetsListView } from 'pages/assetManager/assets/list/AssetsListView'
import { useAppSelector } from 'store/hooks'
import { getMegaFilterState } from 'store/megaFilterStateSlice/selectors'
import { useConnections } from 'api/assets/queries/useConnections'
import { UseAssetsInfiniteV3Return } from 'api/assets/queries/useAssetsInfiniteV3'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { useV2OrV3AssetsInfiniteBasedOnFeatureFlag } from 'api/assets/queries/useV2OrV3AssetsInfiniteBasedOnFeatureFlag'

export const AssetsListViewContainer: React.FC = () => {
  const { searchQuery, typeState } = useAppSelector(getMegaFilterState)

  const { data: connections, isLoading: isLoadingConnections } = useConnections()

  const {
    infiniteAssetsData: assetsData,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    totalResults,
    totalAssetCount,
  } = useV2OrV3AssetsInfiniteBasedOnFeatureFlag({
    params: {
      query: searchQuery,
      resourceTypeCode: typeState?.length > 0 ? typeState?.[0] : undefined,
      limit: 20,
    },
  })

  return (
    <AssetsListView
      isLoading={isLoading || isLoadingConnections}
      assets={assetsData as AssetSummaryDTO[]}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage as UseAssetsInfiniteV3Return['fetchNextPage']}
      isFetchingNextPage={isFetchingNextPage}
      totalResults={totalResults}
      totalAssetCount={totalAssetCount}
      connections={connections}
    />
  )
}
