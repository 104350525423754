import { AssetScheduleDTO } from '@ketch-com/figurehead'
import { EditDatabaseFormValues } from 'interfaces/assets'

const getTimePayload = (scheduleValues: EditDatabaseFormValues['schedule']) => {
  const hours = parseInt(scheduleValues?.time?.split?.(':')?.[0] || '0')
  const setMinsPayload = parseInt(scheduleValues?.time?.split?.(':')?.[1] || '0')

  let setHoursPayload = scheduleValues?.period === '1' ? hours : hours + 12
  if (setHoursPayload === 12 && scheduleValues?.period === '1') {
    setHoursPayload = 0
  }
  if (setHoursPayload === 24 && scheduleValues?.period === '2') {
    setHoursPayload = 12
  }

  const timeWithSetHours = new Date().setHours(setHoursPayload, setMinsPayload, 0, 0)
  const timePayload = new Date(timeWithSetHours).toISOString()

  return timePayload
}

export const getSchedulePayload = (scheduleValues: EditDatabaseFormValues['schedule']) => {
  const payload: AssetScheduleDTO = {}

  if (scheduleValues?.frequency) {
    payload['frequency'] = parseInt(scheduleValues.frequency)
  } else {
    payload['frequency'] = 1
  }

  if (scheduleValues?.day) {
    payload['day'] = parseInt(scheduleValues.day)
  } else {
    payload['day'] = 0
  }

  if (scheduleValues?.time) {
    payload['time'] = getTimePayload(scheduleValues)
  } else {
    payload['time'] = ''
  }

  return payload
}
