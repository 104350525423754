import { APIListRequestParams } from 'api/common/utils'
import { AssessmentTemplateStatusDTO, AssessmentTemplateListDocumentDTO, ArchiveStatusDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  name?: string
  approvalStatus?: AssessmentTemplateStatusDTO
  archiveStatus?: ArchiveStatusDTO
}>

export const fetchAssessmentTemplates = ({ start, limit, name, approvalStatus, archiveStatus }: Params) => {
  return API.get<AssessmentTemplateListDocumentDTO>(
    formatRequestString({
      entityUrl: `/api/resources/assessment-templates`,
      params: {
        filters: {
          'page[start]': start,
          'page[limit]': limit,
          ...(name && { 'filter[name]': name }),
          ...(approvalStatus && { 'filter[status]': approvalStatus }),
          ...(archiveStatus && { 'filter[archive]': archiveStatus }),
        },
      },
    }),
  )
}
