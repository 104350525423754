import React, { useContext } from 'react'
import { Button } from '@ketch-com/deck'
import Box from '@mui/material/Box'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { formatCodeFromName } from 'utils/formatters'
import { FormInlineEdit } from 'components/ui-kit/form/inlineEdit/FormInlineEdit'
import { FormMode } from 'interfaces/formModes/formMode'
import {
  PreferenceField,
  getExperienceCreationLocationState,
} from 'pages/consentAndRights/experiences/upsert/utils/common'
import { Group } from 'components/ui-layouts/group/Group'
import { RoutesManager } from 'utils/routing/routesManager'
import { UpsertInlineLayoutHeader } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutHeader'
import { useFormikContext } from 'formik'
import { isValid } from 'date-fns'
import { PreferenceExperienceFormValues } from '../interfaces/PreferenceExperienceFormValues'
import { ConsentExperienceFormValues } from '../utils/consent/interfaces'
import { ExperienceUpsertContext } from '../context/ExperienceUpsertContext'
import { PreferenceExperienceTab } from '../utils/preference/constants'

type Props = {}

export const ExperienceUpsertHeader: React.FC<Props> = () => {
  const { values, setFieldValue, setFieldTouched, isSubmitting, errors, submitForm } = useFormikContext<
    ConsentExperienceFormValues | PreferenceExperienceFormValues
  >()
  const {
    code,
    experience,
    experienceType,
    formMode,
    isEditMode,
    navigate,
    pathname,
    resetPersistedReduxValues,
    setActiveTab,
    setSidebarActiveTab,
  } = useContext(ExperienceUpsertContext)

  const handleNavigateToPreview = () => {
    navigate(
      RoutesManager.deployment.experiences.preview.root.getURL({
        code,
        experienceType: experienceType!,
      }),
      {
        state: getExperienceCreationLocationState({
          referrer: pathname,
          values,
        }),
      },
    )
  }

  const handleCancel = () => {
    if (isEditMode || formMode === FormMode.DUPLICATE) {
      resetPersistedReduxValues()
      navigate(RoutesManager.deployment.experiences.view.root.getURL({ code: experience?.code }))
    } else {
      resetPersistedReduxValues()
      navigate(RoutesManager.deployment.experiences.root.getURL())
    }
  }

  const singleFormTemplateError = (errors as PreferenceExperienceFormValues)?.rights?.singleFormTemplateID
  const formTemplateError = (errors as PreferenceExperienceFormValues)?.rights
  const subscriptionTopicError = (errors as PreferenceExperienceFormValues)?.subscriptions?.topicItems
  const subscriptionControlError = (errors as PreferenceExperienceFormValues)?.subscriptions?.controlItems

  return (
    <UpsertInlineLayoutHeader>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column">
          <FormInlineEdit
            sx={{
              mb: 2,
            }}
            name="name"
            placeholder="Name"
            size="x-large"
            maxWidth={700}
            onChange={e => {
              if (!isEditMode) {
                setFieldTouched('code', true)
                setFieldValue('code', formatCodeFromName(e.target.value))
              }
            }}
          />

          <FormInlineEdit name="code" placeholder="Code" maxWidth={500} disabled={isEditMode} />
        </Flex>

        <Group>
          <Button
            color="tertiary"
            size="large"
            disabled={!isValid}
            pending={isSubmitting}
            onClick={handleNavigateToPreview}
          >
            Preview
          </Button>

          <Button color="secondary" size="large" pending={isSubmitting} onClick={handleCancel}>
            Cancel
          </Button>

          <Box>
            <Button
              size="large"
              disabled={!isValid}
              pending={isSubmitting}
              onClick={() => {
                if (!!(singleFormTemplateError || formTemplateError)) {
                  setActiveTab(PreferenceExperienceTab.RIGHTS)
                  setSidebarActiveTab(PreferenceField.RIGHTS_FORM_SETTINGS)
                }

                if (subscriptionTopicError) {
                  setActiveTab(PreferenceExperienceTab.SUBSCRIPTIONS)
                  setSidebarActiveTab(PreferenceField.SUBSCRIPTIONS_TOPICS)
                }

                if (subscriptionControlError) {
                  setActiveTab(PreferenceExperienceTab.SUBSCRIPTIONS)
                  setSidebarActiveTab(PreferenceField.SUBSCRIPTIONS_CONTROL)
                }
                submitForm()
              }}
            >
              Save
            </Button>
          </Box>
        </Group>
      </Flex>
    </UpsertInlineLayoutHeader>
  )
}
