import React from 'react'

import Box from '@mui/material/Box'

type Props = {
  children?: React.ReactNode
  isTransparent?: boolean
  removePadding?: boolean
}

export const ViewListLayoutContent: React.FC<Props> = ({ children, isTransparent, removePadding = false }) => {
  return (
    <Box
      sx={{
        minHeight: '100%',
        padding: removePadding ? '' : '14px',
        borderRadius: '11px',
        backgroundColor: isTransparent ? 'transparent' : 'white.main',
      }}
    >
      {children}
    </Box>
  )
}
