import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { getMappings } from 'pages/settings/identitySpaces/view/utils/helpers'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { IdentitySpaceVersionsMessage } from 'pages/settings/identitySpaces/view/components/IdentitySpaceVersionsMessage'
import { IdentitySpaceViewActions } from 'pages/settings/identitySpaces/view/components/IdentitySpaceViewActions'
import { IdentitySpaceViewDetails } from 'pages/settings/identitySpaces/view/components/IdentitySpaceViewDetails'
import { IdentitySpaceViewRoutes } from 'pages/settings/identitySpaces/view/IdentitySpaceViewRoutes'
import { MaybeNull } from 'interfaces/common'
import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { relativePath } from 'utils/routing/relativePath'
import { FindVerifiersResponseBodyDTO } from '@ketch-com/figurehead'
import { NavigationBreadCrumbs } from '../../../../components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Box } from '@mui/material'
import { Spinner, ViewLayout } from '@ketch-com/deck'
import { IssuesMessage } from 'components/issuesMessage/IssuesMessage'

type Props = {
  isReady: boolean
  identitySpace: MaybeNull<IdentitySpaceDTO>
  identitySpaces: IdentitySpaceDTO[]
  versions: IdentitySpaceDTO[]
  verifiersData: MaybeNull<FindVerifiersResponseBodyDTO>
}

export const IdentitySpaceView: React.FC<Props> = ({
  isReady,
  identitySpace,
  identitySpaces,
  versions,
  verifiersData,
}) => {
  const { queries } = useQueryParams<{ version?: string }>()
  const { version } = queries
  const isLatest = !version || !!identitySpace?.latest
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const breadcrumbs = [
    { title: 'Settings', link: RoutesManager.settings.root.getURL() },
    { title: 'Identifiers', link: RoutesManager.settings.identitySpaces.root.getURL() },
    {
      title: identitySpace?.name || identitySpace?.code,
      link: RoutesManager.settings.identitySpaces.view.root.getURL({ code: identitySpace?.ID }),
    },
  ]

  const tabs = useMemo(
    () => [
      {
        value: 'overview',
        label: 'Overview',
        link: relativePath(RoutesManager.settings.identitySpaces.view.overview.root.pattern),
      },
      {
        value: 'version',
        label: 'Versions',
        link: relativePath(RoutesManager.settings.identitySpaces.view.versions.root.pattern),
      },
    ],
    [],
  )

  const currentTab = useMemo(() => {
    const currTab = tabs.find(tab => pathname.includes(tab.link)) || tabs[0]

    return currTab.value
  }, [pathname, tabs])

  const onHandleTabChange = (event: React.SyntheticEvent, value: string) => {
    const activeTab = tabs.find(tabElem => tabElem.value === value)

    if (activeTab) {
      navigate(activeTab.link)
    }
  }

  const { pushesTo } = getMappings({ identitySpaces, identitySpace })

  const issuesMessageActions = {
    title: 'Update',
    onClick: () => {
      navigate(RoutesManager.settings.identitySpaces.upsert.root.getURL({ code: identitySpace?.code }))
    },
  }

  if (!isReady || !identitySpace) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      {isReady && !!identitySpace?.hasIssues && (
        <Box width="100%" mb={3}>
          <IssuesMessage actions={issuesMessageActions} entityType={ApplicationEntity.IDENTITY_SPACE} />
        </Box>
      )}

      {!isLatest && (
        <Box width={1280} mb={2}>
          <IdentitySpaceVersionsMessage />
        </Box>
      )}

      <ViewLayout
        contentGroupProps={{
          title: identitySpace?.name || identitySpace?.code || '',
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.settings.identitySpaces.root.getURL()),
          },
          actionRightBlockComponent: (
            <IdentitySpaceViewActions isLatestVersion={isLatest} identitySpace={identitySpace} />
          ),
          infoBlockComponent: <IdentitySpaceViewDetails identitySpace={identitySpace} />,
        }}
        tabsComponentProps={{
          value: currentTab,
          tabItems: tabs,
          onChange: onHandleTabChange,
        }}
      >
        <IdentitySpaceViewRoutes
          identitySpace={identitySpace}
          pushesTo={pushesTo}
          versions={versions}
          verifiersData={verifiersData}
        />
      </ViewLayout>
    </>
  )
}
