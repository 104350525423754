import React, { useState } from 'react'
import { DataGrid, Tab, TabGroup } from '@ketch-com/deck'
import { FormFieldDTO } from '@ketch-com/figurehead'
import { useFormFieldsRelationshipData } from '../hooks'
import { FormFieldRelationship } from '../hooks/useFormFieldsRelationshipData'
import { useFeatureFlag } from 'utils/hooks'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import {
  authenticatorGridColumns,
  formTemplateGridColumns,
  getExperienceShortGridColumns,
  workflowDefinitionStepSummaryGridColumns,
} from './FormFieldModalUsedInSectionUtils'

type Props = {
  formField?: FormFieldDTO
  showOnlyActiveRelationships?: boolean
}

export const FormFieldModalUsedInSection: React.FC<Props> = ({ formField, showOnlyActiveRelationships = false }) => {
  const {
    formFieldTemplatesActive,
    formFieldAuthenticators,
    formFieldExperiencesActive,
    formFieldExperiences,
    formFieldTemplates,
    formFieldActivities,
    defaultTab,
  } = useFormFieldsRelationshipData(formField)

  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isExpV1 = isFeatureFlagEnabled(
    FEATURE_FLAGS.EXPERIENCE_SERVER_VERSION,
    FEATURE_FLAGS_VALUES.EXPERIENCE_SERVER_VERSION_1,
  )

  const [relationship, setRelationship] = useState<FormFieldRelationship>(defaultTab)
  const [currentTab, setCurrentTab] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }
  const tabsConfig = ['Forms', 'Authenticators', 'Experiences', 'Workflows']
    .filter(key => {
      if (key === 'Forms') {
        if (showOnlyActiveRelationships) return formFieldTemplatesActive.length > 0
        return formFieldTemplates.length > 0
      }

      if (key === 'Authenticators') {
        return formFieldAuthenticators.length > 0
      }

      if (key === 'Experiences') {
        if (showOnlyActiveRelationships) return formFieldExperiencesActive.length > 0
        return formFieldExperiences.length > 0
      }

      if (key === 'Workflows') {
        return formFieldActivities.length > 0
      }

      return false
    })
    .map((key, index) => {
      return {
        key,
        active: relationship === key,
        title: key,
        onClick: () => {
          setRelationship(key as FormFieldRelationship)
          setCurrentTab(index)
        },
        link: undefined,
      }
    })

  return (
    <>
      <TabGroup value={currentTab} onChange={handleChange}>
        {tabsConfig.map(elem => {
          return <Tab label={elem.title} {...elem} />
        })}
      </TabGroup>

      {relationship === 'Forms' && (
        <DataGrid
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: false,
            expand: true,
          }}
          getRowHeight={() => 'auto'}
          columns={formTemplateGridColumns}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowSelectionOnClick
          disableRowHoverStates
          getRowId={row => row.code}
          rows={showOnlyActiveRelationships ? formFieldTemplatesActive : formFieldTemplates}
          hideFooter
          noRowsOverlayProps={{
            buttonTitle: '',
          }}
        />
      )}

      {relationship === 'Experiences' && (
        <DataGrid
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: false,
            expand: true,
          }}
          getRowHeight={() => 'auto'}
          columns={getExperienceShortGridColumns(isExpV1)}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowSelectionOnClick
          disableRowHoverStates
          getRowId={row => row.code}
          rows={showOnlyActiveRelationships ? formFieldExperiencesActive : formFieldExperiences}
          hideFooter
          noRowsOverlayProps={{
            buttonTitle: '',
          }}
        />
      )}

      {relationship === 'Workflows' && (
        <DataGrid
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: false,
            expand: true,
          }}
          getRowHeight={() => 'auto'}
          columns={workflowDefinitionStepSummaryGridColumns}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowSelectionOnClick
          disableRowHoverStates
          getRowId={row => row.code}
          rows={formFieldActivities}
          hideFooter
          noRowsOverlayProps={{
            buttonTitle: '',
          }}
        />
      )}

      {relationship === 'Authenticators' && (
        <DataGrid
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: false,
            expand: true,
          }}
          getRowHeight={() => 'auto'}
          columns={authenticatorGridColumns}
          disableChildrenSorting
          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          disableRowSelectionOnClick
          disableRowHoverStates
          getRowId={row => row.id}
          rows={formFieldAuthenticators}
          hideFooter
          noRowsOverlayProps={{
            buttonTitle: '',
          }}
        />
      )}
    </>
  )
}
