import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'

import { Modal } from 'components/ui-kit/modal/Modal'
import { AssetSummaryDTO, DmlHookDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'
import { useEditAssetFormUtils } from './hooks/useEditAssetFormUtils'
import { HooksFormSection } from './components'
import { useInlineEditUtils } from 'pages/assetManager/assets/view/hooks/useAssetDetailContentRows/hooks'

export const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      autocomplete: {
        maxWidth: 300,
      },
      modalClassName: {
        paddingTop: spacing(2),
      },
    }),
  { name: 'HooksWidgetEditModal' },
)

type Props = {
  isUpdatingAssetLabels?: boolean
  onSubmit?: (hooks: DmlHookDTO[]) => void
  onCancel: () => void
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export const HooksWidgetEditModal: React.FC<Props> = ({
  onSubmit = () => {},
  onCancel,
  isUpdatingAssetLabels = false,
  assetSummary,
}) => {
  const classes = useStyles()
  const { setFieldValue, isUpdatingDatabase, values } = useInlineEditUtils({ assetSummary: assetSummary || undefined })

  const { onDragEnd, hooks } = useEditAssetFormUtils({ setFieldValue, values })

  return (
    <Modal
      isLoading={isUpdatingDatabase}
      isSaving={isUpdatingAssetLabels}
      title="Purposes"
      isRemoveMaxHeight
      subtitle="Search for a purpose, or remove those already assigned."
      contentWidth={825}
      submitBtnLabel="Save"
      className={classes.modalClassName}
      cancelBtnLabel="Cancel"
      onSubmitBtnClick={() => onSubmit([...hooks])}
      onCancelBtnClick={onCancel}
    >
      <HooksFormSection
        onDragEnd={onDragEnd}
        hooks={hooks}
        values={values}
        setFieldValue={setFieldValue}
        assetSummary={assetSummary}
      />
    </Modal>
  )
}
