import React, { useState } from 'react'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ViewListLayoutHeader } from 'components/ui-layouts/viewListLayout/ViewListLayoutHeader'
import { ViewListLayout } from 'components/ui-layouts/viewListLayout/ViewListLayout'
import { ViewListLayoutContent } from 'components/ui-layouts/viewListLayout/ViewListLayoutContent'
import {
  ClassificationsManual,
  ClassificationsInferred,
  ClassificationModal,
} from 'pages/assetManager/classifications/components'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { INSUFFICIENT_PERMISSIONS_TOOLTIP } from 'utils/constants/INSUFFICIENT_PERMISSIONS_TOOLTIP'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { Box } from '@mui/material'
import { Button } from 'components/ui-kit/button/Button'
import { LabelDefinitionDTO as LabelDTO } from '@ketch-com/figurehead'

type Props = {
  isLoading: boolean
  isFetchingNextPage?: boolean
  hasNextPage?: boolean
  fetchNextPage: () => {}
  labels: LabelDTO[]
}

enum ClassificationMode {
  MANUAL = 'Manual',
  AUTOMATED = 'Automated',
}

const CLASSIFICATION_MODE_OPTIONS = [
  {
    id: ClassificationMode.MANUAL,
    name: ClassificationMode.MANUAL,
  },
  {
    id: ClassificationMode.AUTOMATED,
    name: ClassificationMode.AUTOMATED,
  },
]

const classificationModalInitialState = { display: false, data: { name: '' } }

export const Classifications: React.FC<Props> = (props: Props) => {
  const [classificationMode, setClassificationMode] = useState(ClassificationMode.MANUAL)
  const [classificationModal, setClassificationModal] = useState(classificationModalInitialState)
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteLabel = getIsPermitted([PERMISSIONS.LABEL_WRITE])
  return (
    <>
      <ViewListLayout>
        <ViewListLayoutHeader title="Classifications" />
        <Box mb={3} display="flex" justifyContent="space-between">
          <SegmentTabs tabs={CLASSIFICATION_MODE_OPTIONS} value={classificationMode} onChange={setClassificationMode} />
          {classificationMode === ClassificationMode.MANUAL && props?.labels?.length ? (
            <Button
              disabled={!isPermittedToWriteLabel}
              tooltip={!isPermittedToWriteLabel ? INSUFFICIENT_PERMISSIONS_TOOLTIP : undefined}
              onClick={() =>
                setClassificationModal(prevValues => ({
                  ...prevValues,
                  display: true,
                }))
              }
            >
              Create Classification
            </Button>
          ) : null}
        </Box>
        <ViewListLayoutContent>
          {classificationMode === ClassificationMode.MANUAL ? (
            <ClassificationsManual setClassificationModal={setClassificationModal} {...props} />
          ) : null}
          {classificationMode === ClassificationMode.AUTOMATED ? <ClassificationsInferred /> : null}
        </ViewListLayoutContent>
      </ViewListLayout>

      {classificationModal.display ? (
        <ClassificationModal
          onCancel={() => setClassificationModal(classificationModalInitialState)}
          data={classificationModal.data}
        />
      ) : null}
    </>
  )
}
