import { Box, Typography, Autocomplete, InputAdornment } from '@mui/material'
import { useFormikContext, FieldArray } from 'formik'
import { PurposeDTO, ProcessingActivityDTO, TeamDTO } from '@ketch-com/figurehead'
import { processingFrequencyType } from 'pages/policyCenter/processingActivities/constants'
import { usePurposesPaginated } from 'api/purposes/queries/usePurposesPaginated'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useProcessingActivitiesUpsertSearch } from 'pages/policyCenter/processingActivities/hooks'
import { ActionSheetItem, Chip, FormRow, Icon, ListItemText, TextInput } from '@ketch-com/deck'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { StyledOrchestrationAutocompletePaper } from 'pages/policyCenter/subscriptions/subscriptionTopic/upsert/components/Orchestration/utils'
import { useTeams } from 'api/teams/queries/useTeams'
import { UserAvatar } from 'components/avatar/UserAvatar'
import { UserShortDTO } from 'interfaces/users/users'

type Props = {}

export const ProcessingDetails: React.FC<Props> = () => {
  const { isSubmitting, values } = useFormikContext<ProcessingActivityDTO>()

  const purposeSearchState = useProcessingActivitiesUpsertSearch<PurposeDTO>()
  const teamsSearchState = useProcessingActivitiesUpsertSearch<TeamDTO>()

  const { isLoading: isLoadingPurposes } = usePurposesPaginated({
    itemsPerPage: 300,
    params: {
      query: purposeSearchState.searchQueryValue,
    },
    onSuccess: ({ data = {} }) => {
      const { purposes = [] } = data
      purposeSearchState.setOptions(purposes)
    },
    onError: () => {
      showToast({ content: 'Failed to fetch purposes', type: 'error' })
    },
  })

  const { isLoading: isLoadingTeams } = useTeams({
    params: {
      query: teamsSearchState.searchQueryValue,
    },
    onSuccess: ({ data = {} }) => {
      const { teams = [] } = data
      teamsSearchState.setOptions(teams)
    },
    onError: () => {
      showToast({ content: 'Failed to fetch business functions list', type: 'error' })
    },
  })

  return (
    <FormRow title="Processing Details" subTitle="Provide a bit more information on the nature of the data processing.">
      <Box display="flex" flexDirection="column" gap={3}>
        <FormRadioGroup
          hideOptionalLabel
          label="Processing Frequency"
          formPropertyName="processingFrequency"
          row
          valueKey="frequency"
          renderLabel={({ label }, isSelected) => (
            <Typography variant={isSelected ? 'label' : 'body'}>{label}</Typography>
          )}
          options={processingFrequencyType.map(el => ({
            ...el,
            ...(isSubmitting && {
              disabled: true,
            }),
          }))}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="label">Purposes</Typography>
          <FieldArray name="purposes">
            {({ push, remove }) => {
              return (
                <Box>
                  <Autocomplete
                    key={values?.purposes?.length}
                    loading={isLoadingPurposes}
                    disabled={isSubmitting}
                    sx={{ width: 420 }}
                    filterOptions={x => x}
                    clearOnBlur
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    noOptionsText="No Purposes"
                    getOptionDisabled={option =>
                      Boolean(values?.purposes?.some(purpose => purpose?.code === option?.code))
                    }
                    onChange={(e, purpose) => {
                      push(purpose)
                    }}
                    onInputChange={(e, inputVal) => purposeSearchState.setInputValue(inputVal)}
                    onClose={() => {
                      purposeSearchState.setInputValue('')
                      purposeSearchState.setSearchQueryValue('')
                    }}
                    options={purposeSearchState?.options || []}
                    renderInput={params => (
                      <TextInput
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon name="OMag" />
                            </InputAdornment>
                          ),
                          endAdornment: <></>,
                        }}
                        placeholder="Start typing a purpose name"
                        value={purposeSearchState.inputValue}
                        variant="outlined"
                      />
                    )}
                    renderOption={(props, purpose, { selected }) => (
                      <ActionSheetItem {...props} key={purpose?.code} selected={selected}>
                        <ListItemText selected={selected}>{purpose?.name}</ListItemText>
                      </ActionSheetItem>
                    )}
                    PaperComponent={StyledOrchestrationAutocompletePaper}
                    getOptionLabel={purpose => purpose?.code || ''}
                  />

                  <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
                    {values?.purposes?.map((purpose, index) => {
                      return (
                        <Chip
                          key={index}
                          disabled={isSubmitting}
                          label={purpose?.name || ''}
                          deleteIcon={<Icon name="OCross" />}
                          onDelete={() => {
                            remove(index)
                          }}
                        />
                      )
                    })}
                  </Box>
                </Box>
              )
            }}
          </FieldArray>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="label">Teams</Typography>
          <FieldArray name="teams">
            {({ push, remove }) => {
              return (
                <Box>
                  <Autocomplete
                    key={values?.teams?.length}
                    loading={isLoadingTeams}
                    disabled={isSubmitting}
                    sx={{ width: 420 }}
                    filterOptions={x => x}
                    clearOnBlur
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    noOptionsText="No Teams"
                    getOptionDisabled={option => Boolean(values?.teams?.some(team => team?.id === option?.id))}
                    onChange={(_, team) => {
                      push(team)
                    }}
                    onInputChange={(_, inputVal) => teamsSearchState.setInputValue(inputVal)}
                    onClose={() => {
                      teamsSearchState.setInputValue('')
                      teamsSearchState.setSearchQueryValue('')
                    }}
                    options={teamsSearchState?.options || []}
                    renderInput={params => (
                      <TextInput
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon name="OMag" />
                            </InputAdornment>
                          ),
                          endAdornment: <></>,
                        }}
                        placeholder="Start typing a team name"
                        value={teamsSearchState.inputValue}
                        variant="outlined"
                      />
                    )}
                    renderOption={(props, team, { selected }) => (
                      <ActionSheetItem {...props} key={team?.id} selected={selected}>
                        <Box display="flex" alignItems="center" gap={1}>
                          <UserAvatar user={{ ID: team.id } as UserShortDTO} alternativeText={team.label} />
                          <ListItemText selected={selected}>{team?.name}</ListItemText>
                        </Box>
                      </ActionSheetItem>
                    )}
                    PaperComponent={StyledOrchestrationAutocompletePaper}
                    getOptionLabel={team => team?.name || ''}
                  />
                  <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
                    {values?.teams?.map((team, index) => {
                      return (
                        <Chip
                          key={index}
                          disabled={isSubmitting}
                          label={team?.name || ''}
                          deleteIcon={<Icon name="OCross" />}
                          onDelete={() => {
                            remove(index)
                          }}
                        />
                      )
                    })}
                  </Box>
                </Box>
              )
            }}
          </FieldArray>
        </Box>
      </Box>
    </FormRow>
  )
}
