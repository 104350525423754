import { ICanvasStep, IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { omitDisconnectedSteps } from 'pages/orchestration/workflows/edit/utils/steps/omitDisconnectedSteps'
import { createPlaceholderStep } from './createPlaceholder'
import { getNextJoinTile } from './getNextJoinTile'

export interface IUpdateStepParams {
  workflow?: IWorkflowConfig
  step: ICanvasStep
}

export interface IUpdateStep {
  newStep: ICanvasStep
  newSteps: Array<ICanvasStep>
}

export const updateStep = ({ step, workflow }: IUpdateStepParams): IUpdateStep => {
  const steps = workflow?.steps || []

  const stepsToReturn = [...steps]
  const stepToUpdateIndex = steps.findIndex(({ ID }) => ID === step.ID)

  stepsToReturn[stepToUpdateIndex] = step

  // Gateways may add paths that need to be tracked here. If a placeholder is added, a new path was added that needs connecting.
  step.gateway?.transitions?.forEach(transition => {
    if (!stepsToReturn.find(({ ID }) => ID === transition.ID)) {
      const joinTile = getNextJoinTile({ step, steps })
      const placeholderForPath = createPlaceholderStep(joinTile?.ID, transition.name, transition.ID as string)

      stepsToReturn.push(placeholderForPath)
    }
  })

  return {
    newStep: step,
    newSteps: omitDisconnectedSteps({ steps: stepsToReturn }),
  }
}
