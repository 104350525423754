import { APIRequestParams } from 'api/common/utils'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  id: string
  includeIssues?: boolean
}>

export const fetchExperience = ({ includeMetadata, includeIssues, id }: Params) =>
  API.get<ExperienceV2DTO>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/experiences/${id}`,
      params: {
        filters: {
          ...(includeMetadata && { includeMetadata }),
          ...(includeIssues && { includeIssues }),
        },
      },
    }),
  )
