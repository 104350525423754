import { useCallback } from 'react'
import { every, some, isArray } from 'lodash'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useEntitlements } from 'api/entitlements/queries/useEntitlements'
import { useNavigate } from 'react-router-dom'

export const useIsEntitled = () => {
  const navigate = useNavigate()
  const {
    data: entitlements,
    isLoading: isLoadingEntitlements,
    isFetching: isFetchingEntitlements,
  } = useEntitlements({
    staleTime: 60 * 1000 * 5, // 5 minutes
    onError: () => {
      navigate('/auth/error')
    },
  })

  // For an individual entitlement, check if the entitlement is present and if it has quantity remaining
  const checkEntitlement = useCallback(
    (entitlement: ENTITLEMENTS) => {
      const entitlementAllowed = Boolean(entitlements?.[entitlement]?.allowed)
      const entitlementRemaining = !!entitlements?.[entitlement]?.numberRemaining // numberRemaining can be negative if entitled to unlimited amount (qty -1)
      return entitlementAllowed && entitlementRemaining
    },
    [entitlements],
  )

  const isEntitled = useCallback(
    (requiredEntitlements: ENTITLEMENTS | ENTITLEMENTS[], requireAll?: boolean) => {
      const entitlementsToCheck = isArray(requiredEntitlements) ? requiredEntitlements : [requiredEntitlements]

      // If entitlement data is null, return false
      if (!entitlements) return false

      // If no required entitlements are passed, return true
      if (!requiredEntitlements.length) return true

      // Checking user entitlements based on requiredEntitlements and requireAll parameters
      const entitled = requireAll
        ? every(entitlementsToCheck, entitlement => checkEntitlement(entitlement))
        : some(entitlementsToCheck, entitlement => checkEntitlement(entitlement))

      return entitled
    },
    [entitlements, checkEntitlement],
  )

  const isEntitledWithDetails = useCallback(
    (requiredEntitlements: ENTITLEMENTS | ENTITLEMENTS[], requireAll?: boolean) => {
      const entitlementsToCheck = isArray(requiredEntitlements) ? requiredEntitlements : [requiredEntitlements]

      // Call above function to check entitled status
      const entitled = isEntitled(requiredEntitlements, requireAll)

      // Filter list for the missing entitlements
      const missingEntitlements = entitlementsToCheck.filter(entitlement => !checkEntitlement(entitlement))

      return { entitled, missingEntitlements }
    },
    [isEntitled, checkEntitlement],
  )

  return {
    isEntitled,
    isEntitledWithDetails,
    isLoadingEntitlements,
    isFetchingEntitlements,
  }
}
