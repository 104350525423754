import { Button, ContentGroup } from '@ketch-com/deck'
import {
  ExperienceAB,
  LOGICAL_OP,
  Rule,
  RuleSet,
} from 'pages/consentAndRights/applications/upsert/components/ApplicationPredicates.types'
import Grid from '@mui/material/Grid'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { Logger } from 'utils/Logger'
import InfoRowRuleSet from 'pages/consentAndRights/applications/upsert/components/InfoRowRuleSet'
import { ApplicationFormValues } from 'interfaces/applications/ApplicationFormValues'
import { ApplicationDTO } from 'interfaces/applications/application'

export const getEmptyRule = (): Rule => ({ id: v4(), logicOp: LOGICAL_OP.AND }) as Rule
export const getEmptyExp = (): ExperienceAB => ({ id: v4() }) as ExperienceAB

const getEmptyRuleGroup = (): RuleSet => ({ id: v4(), rules: [getEmptyRule()], experiences: [getEmptyExp()] })

type Props = {
  application: ApplicationFormValues | ApplicationDTO
  onSubmit: (values: ApplicationFormValues) => void
}
export default function ApplicationPredicates({ application, onSubmit }: Props) {
  const [ruleSet, setRuleSet] = useState<RuleSet[]>([])

  function updateRuleGroup(rg: RuleSet, isDelete: boolean) {
    const index = ruleSet.findIndex(i => i.id === rg.id)
    if (index === -1) {
      Logger.debug(`Internal error: Rule ${rg.id} not found`)
      return
    }
    const updatedRuleSet = isDelete
      ? [...ruleSet.slice(0, index), ...ruleSet.slice(index + 1)]
      : [...ruleSet.slice(0, index), rg, ...ruleSet.slice(index + 1)]

    onSubmit({
      ...(application as ApplicationFormValues),
      progressiveConsentRules: { list: JSON.stringify(updatedRuleSet) },
    })

    setRuleSet(updatedRuleSet)
  }

  useEffect(() => {
    if (!application || !application.progressiveConsentRules?.list) {
      return
    }
    const r: RuleSet[] = JSON.parse(application.progressiveConsentRules.list)
    setRuleSet(r)
  }, [application])

  return (
    <>
      <ContentGroup
        title="Progressive Consent"
        titleVariant="h3"
        variant="inner-page-header"
        subTitle="Specify which customers see which consent experiences."
        actionRightBlockComponent={
          <Button color="secondary" onClick={() => setRuleSet([...ruleSet, getEmptyRuleGroup()])}>
            Add Rule Set
          </Button>
        }
      />
      <Grid container spacing={2} mb={5}>
        {ruleSet.map((rg, index) => (
          <Grid item xs={12}>
            <InfoRowRuleSet ruleSet={rg} onSubmit={updateRuleGroup} ruleSetIndex={index} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
