import React, { createContext } from 'react'
import { useThemeUpsertUtils, UseThemeUpsertUtilsReturnType } from '../utils/useThemeUpsertUtils'

export const ThemeUpsertContext = createContext({} as UseThemeUpsertUtilsReturnType)

type Props = {
  children: React.ReactNode
}

export const ThemeUpsertContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useThemeUpsertUtils()

  return <ThemeUpsertContext.Provider value={{ ...hookValues }}>{children}</ThemeUpsertContext.Provider>
}

export const withThemeUpsertContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <ThemeUpsertContextProvider>
        <Component {...props} />
      </ThemeUpsertContextProvider>
    )
  }
}
