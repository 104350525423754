import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { useAuth } from 'utils/hooks/useAuth'
import { createBusinessFunction } from '../fetchers/createBusinessFunction'

const useCustomMutation = createUseMutation({
  mutationFn: createBusinessFunction,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateBusinessFunction = (config?: Config) => {
  const { userData } = useAuth()
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {
    organizationCode: userData.organizationCode,
  })
}
