import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import { FormFileUpload } from 'components/ui-kit/form/fileUpload/FormFileUpload'
import { themeFileAllowedTypes, themeFileUploadContext } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'

export const PreferenceThemeWelcomeTabHeaderForm: React.FC = () => {
  return (
    <>
      <SidebarFormGroup sx={{ pt: 0 }} hasBorderBottom>
        <FormColorInput
          name={themeUpsertFormKeys.preferenceWelcomeTabHeaderBackgroundColor}
          label={'Header Background Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferenceWelcomeTabHeaderTitleColor}
          label={'Header Title Color'}
          fullWidth
          required
        />
      </SidebarFormGroup>
      <SidebarFormGroup>
        <FormFileUpload
          name={themeUpsertFormKeys.preferenceWelcomeTabHeaderLogoUrl}
          label={'Header Logo'}
          belowButtonLabel={'Container size: 268x64'}
          uploadContext={themeFileUploadContext}
          accept={themeFileAllowedTypes}
        />
      </SidebarFormGroup>
    </>
  )
}
