import React from 'react'

import { ExperienceDTO } from 'interfaces/experiences/experience'
import { SectionTitle } from 'components/ui-kit/typography/compositions/SectionTitle'
import { FormGroup } from 'components/ui-layouts/formLayout/FormGroup'
import { BannerDetails } from 'pages/consentAndRights/experiences/view/components/BannerDetails'
import { ModalDetails } from 'pages/consentAndRights/experiences/view/components/ModalDetails'
import { JitDetails } from 'pages/consentAndRights/experiences/view/components/JitDetails'
import { OverviewDetails } from 'pages/consentAndRights/experiences/view/components/OverviewDetails'
import { RightsDetails } from 'pages/consentAndRights/experiences/view/components/RightsDetails'
import { ConsentsDetails } from 'pages/consentAndRights/experiences/view/components/ConsentsDetails'
import { SubscriptionsDetails } from 'pages/consentAndRights/experiences/view/components/SubscriptionsDetails'

type Props = {
  experience: ExperienceDTO
}

export const ExperienceOverview: React.FC<Props> = ({ experience }) => {
  const { consent, preference } = experience

  return (
    <>
      {!!consent && (
        <>
          <FormGroup>
            <SectionTitle>Banner Details</SectionTitle>
            <BannerDetails config={consent.banner} />
          </FormGroup>

          <FormGroup>
            <SectionTitle>Modal Details</SectionTitle>
            <ModalDetails config={consent.modal} />
          </FormGroup>

          {consent?.jit && (
            <FormGroup>
              <SectionTitle>Just in Time Details</SectionTitle>
              <JitDetails config={consent.jit} />
            </FormGroup>
          )}
        </>
      )}

      {!!preference && (
        <>
          <FormGroup>
            <SectionTitle>Overview Details</SectionTitle>
            <OverviewDetails title={preference.title} config={preference.overview} />
          </FormGroup>

          <FormGroup>
            <SectionTitle>Rights Details</SectionTitle>
            <RightsDetails title={preference.title} config={preference.rights} />
          </FormGroup>

          <FormGroup>
            <SectionTitle>Preferences Details</SectionTitle>
            <ConsentsDetails title={preference.title} config={preference.consents} />
          </FormGroup>

          <FormGroup>
            <SectionTitle>Subscriptions</SectionTitle>
            <SubscriptionsDetails title={preference.title} config={preference.subscriptions} />
          </FormGroup>
        </>
      )}
    </>
  )
}
