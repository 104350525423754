import { useMemo } from 'react'
import { BuilderProps } from '../Builder'
import { BuilderTarget } from './enums'
import { useIsEntitled } from 'utils/hooks'
import { PreferenceCenterPage } from '@ketch-sdk/ketch-types'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

export const useBuilderUtils = (props: BuilderProps) => {
  const { builderTarget, preferencePage, experienceConfig } = props

  const { isEntitled } = useIsEntitled()
  const hasPurposesEntitlement = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)
  const hasSubscriptionsEntitlement = isEntitled(ENTITLEMENTS.SUBSCRIPTIONS)
  const hasRightsEntitlement = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)
  const entitledPreferencePages: PreferenceCenterPage[] = useMemo(() => {
    return [
      PreferenceCenterPage.Welcome,
      ...(hasPurposesEntitlement ? [PreferenceCenterPage.Purpose] : []),
      ...(hasSubscriptionsEntitlement ? [PreferenceCenterPage.Subscriptions] : []),
      ...(hasRightsEntitlement ? [PreferenceCenterPage.RequestsHome] : []),
      ...(hasRightsEntitlement ? [PreferenceCenterPage.RequestsForm] : []),
      ...(hasRightsEntitlement ? [PreferenceCenterPage.RequestsSubmitted] : []),
    ]
  }, [hasPurposesEntitlement, hasSubscriptionsEntitlement, hasRightsEntitlement])

  // Flag indicating if this is a theme builder
  const isTheme = builderTarget === BuilderTarget.Theme

  // Get the experience config for the current locale
  const experienceLayoutConfig = experienceConfig.layout!
  const experienceEnglishConfig = experienceConfig.translations!['en']
  const experienceTranslationConfig = experienceConfig.translations!

  return {
    ...props,
    isTheme,
    experienceLayoutConfig,
    experienceEnglishConfig,
    experienceTranslationConfig,
    preferencePage,
    entitledPreferencePages,
  }
}

export type UseBuilderUtilsReturnType = ReturnType<typeof useBuilderUtils>
