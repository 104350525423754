import React from 'react'
import { SubscriptionTopics } from 'pages/policyCenter/subscriptions/subscriptionTopic/list/SubscriptionTopics'
import { useLegalBases } from 'api/legalBases/queries/useLegalBases'
import { useSubscriptionTopicsPaginated } from 'api/subscriptions/queries/useSubscriptionTopicsPaginated'
import { showToast } from 'components/modals/AlertComponent'
import { useSubscriptionContactMethodsPaginated } from 'api/subscriptions/queries/useSubscriptionContactMethodsPaginated'
import { Box } from '@mui/material'
import { Spinner } from '@ketch-com/deck'

export const SubscriptionTopicsContainer: React.FC = () => {
  const {
    data: subscriptionTopics,
    isLoading: subscriptionTopicsLoading,
    pagination: subscriptionTopicsPagination,
  } = useSubscriptionTopicsPaginated({
    params: {
      includeMetadata: true,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch subscriptions', type: 'error' })
    },
  })
  const { data: subscriptionContactMethods, isLoading: isSubscriptionContactMethodsLoading } =
    useSubscriptionContactMethodsPaginated({
      params: {},
      onError: () => {
        showToast({ content: 'Failed to fetch contact methods', type: 'error' })
      },
    })
  const { data: legalBases, isLoading: isLegalBasesLoading } = useLegalBases()
  const isReady = !isLegalBasesLoading && !subscriptionTopicsLoading && !isSubscriptionContactMethodsLoading
  if (isReady)
    return (
      <SubscriptionTopics
        subscriptionTopics={subscriptionTopics}
        isLoading={subscriptionTopicsLoading}
        pagination={subscriptionTopicsPagination}
        legalBases={legalBases}
        subscriptionContactMethods={subscriptionContactMethods?.contactMethods || []}
      />
    )

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" m={6}>
      <Spinner size="32px" thickness={2.5} />
    </Box>
  )
}
