import { ICanvasStep } from '../../interfaces'
import { cloneDeep, get } from 'lodash'
import { getTransitionsFromStep } from './transitionsFromStep'
import { omitDisconnectedSteps } from './omitDisconnectedSteps'
import { codeExists } from '../codeExists'
import { regenerateIds } from './regenerateIds'
import { getNextJoinTile } from './getNextJoinTile'
import { getLastStepOfPath } from './getLastStepOfPath'
import { connectSteps } from './connectSteps'
import { getCopyPasteType } from './getCopyPasteType'
import { CanvasStepType, CopyPasteType } from '../constants'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { getStepType } from './getStepType'
import { WorkflowActivityGatewayMode } from '../../../../../../interfaces/workflowActivities/workflowActivity'
import { updateAllTransitions } from './updateTransition'
import { isTileInConnectedNest } from './isTileInConnectedNest'
import { getLastStepOfAllPaths } from './getLastStepOfAllPaths'

export type IPasteStepsParams = {
  stepToReplace: ICanvasStep
  copiedSteps: ICanvasStep[]
  allSteps: ICanvasStep[]
  transitionIndex?: number
}

export interface IPasteSteps {
  pastedSteps?: ICanvasStep[]
  newSteps?: ICanvasStep[]
}

export const pasteSteps = ({ stepToReplace, copiedSteps, allSteps }: IPasteStepsParams): IPasteSteps => {
  // Assumption: The step at index 0 of copiedSteps is the first of the copied steps in the workflow.
  // This lets us determine what type of copy it is based on this step (activity, gateway, or path).

  if (copiedSteps.length < 1) {
    showToast({
      content: 'Paste error',
      type: 'success',
      autoHideDuration: 3000,
    })
    return {}
  }

  // Get new IDs for all copied steps
  const stepsToPaste = regenerateIds(copiedSteps)

  // Determine what type of paste we are doing
  const copyPasteType = getCopyPasteType(stepsToPaste)

  // Ensure the code of each pasted step is unique
  stepsToPaste.forEach(stepToPaste => {
    const otherStepsToPaste = stepsToPaste.filter(step => step.ID !== stepToPaste.ID)
    // Append _copy to code until it becomes unused
    while (codeExists(stepToPaste.code || '', [...allSteps, ...otherStepsToPaste])) {
      stepToPaste.code = stepToPaste.code + '_copy'
    }
  })

  const currentStepTransitions = getTransitionsFromStep({ step: stepToReplace, steps: allSteps })
  const currentStepTransitionId = get(currentStepTransitions, `[0].ID`) || undefined
  const currentStepTransition = allSteps.find(step => step.ID === currentStepTransitionId)
  const updatedSteps: ICanvasStep[] = []

  allSteps.forEach(workflowStep => {
    const { ID } = workflowStep

    if (ID === stepToReplace.ID) {
      switch (copyPasteType) {
        // Single activity step case
        case CopyPasteType.single:
          let newActivityStep: ICanvasStep = cloneDeep(stepsToPaste[0])
          newActivityStep.ID = ID
          connectSteps(newActivityStep, currentStepTransitionId || '')

          updatedSteps.push(newActivityStep)
          break

        // Split or multi decision gateway case
        case CopyPasteType.splitOrMultiGateway:
          const gatewayStepWithJoin = stepsToPaste[0]
          const joinStep = getNextJoinTile({ step: gatewayStepWithJoin, steps: stepsToPaste })!
          const isInNest = isTileInConnectedNest({ step: stepToReplace, steps: allSteps })

          // Connect placeholders parent to gateway (by replacement)
          gatewayStepWithJoin.ID = ID

          if (isInNest) {
            // Paste nest within nest case

            // Get join of the outer nest and last step in each path
            const nestJoin = getNextJoinTile({ step: stepToReplace, steps: allSteps })
            const lastSteps = getLastStepOfAllPaths({ gatewayStep: gatewayStepWithJoin, steps: stepsToPaste })

            // Connect the last step of each path
            lastSteps.forEach((lastStep, pathIndex) => {
              if (pathIndex === 0) {
                // Connect the last step of the first path to the step after placeholder
                connectSteps(lastStep, currentStepTransitionId)
              } else {
                // Connect last step in all other paths to the next join
                connectSteps(lastStep, nestJoin)
              }
            })
          } else {
            // Update transitions to first pasted step and out of the last
            if (joinStep) {
              joinStep.gateway!.transitions[0].ID = currentStepTransitionId

              if (currentStepTransition) {
                const currentStepTransitionType = getStepType(currentStepTransition)

                // Don't let a join follow a join
                if (
                  currentStepTransitionType === CanvasStepType.Gateway &&
                  currentStepTransition.gateway?.mode === WorkflowActivityGatewayMode.JOIN
                ) {
                  // Update any steps which point to the other joins
                  updateAllTransitions(allSteps, currentStepTransition.ID!.toString(), joinStep.ID!.toString())
                  joinStep.gateway!.transitions = currentStepTransition.gateway!.transitions
                }
              }
            } else {
              gatewayStepWithJoin.gateway!.transitions[0].ID = currentStepTransitionId
            }
          }

          updatedSteps.push(...stepsToPaste)
          break

        // Decision boolean or single decision gateway case
        case CopyPasteType.booleanOrSingleGateway:
          const gatewayStepNoJoin = stepsToPaste[0]
          const lastStepOfFirstPath = getLastStepOfPath({
            pathStartStepId: gatewayStepNoJoin.gateway?.transitions[0].ID?.toString() || '',
            steps: stepsToPaste,
          })

          // Update transitions to first pasted step and out of the last
          gatewayStepNoJoin.ID = ID
          connectSteps(lastStepOfFirstPath || gatewayStepNoJoin, currentStepTransitionId || '')

          updatedSteps.push(...stepsToPaste)
          break

        // Multistep path case
        case CopyPasteType.path:
          const firstStep = stepsToPaste[0]
          const pathJoinStep = getNextJoinTile({ step: stepsToPaste[0], steps: stepsToPaste })
          const lastStep = stepsToPaste.at(-1)

          // Update transitions to first pasted step and out of the last
          firstStep.ID = ID
          if (pathJoinStep) {
            pathJoinStep.gateway!.transitions = [{ ID: currentStepTransitionId }]
          } else if (lastStep) {
            connectSteps(lastStep, currentStepTransitionId)
          }

          updatedSteps.push(...stepsToPaste)
          break
        default:
          showToast({
            content: 'Paste error',
            type: 'error',
            autoHideDuration: 3000,
          })
      }
    } else {
      // Push the other workflow steps before or after pasted ones
      updatedSteps.push(workflowStep)
    }
  })

  // Cleanup new steps
  const newSteps = omitDisconnectedSteps({ steps: updatedSteps })

  return {
    pastedSteps: stepsToPaste,
    newSteps,
  }
}
