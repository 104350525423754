import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'

export const PreferenceThemeRequestsTabSubmittedTextForm: React.FC = () => {
  return (
    <SidebarFormGroup sx={{ pt: 0, gap: '16px' }}>
      <FormColorInput
        name={themeUpsertFormKeys.preferenceRequestsTabSubmittedTextSubtitleColor}
        label={'Subtitle Color'}
        fullWidth
        required
      />
      <FormColorInput
        name={themeUpsertFormKeys.preferenceRequestsTabSubmittedTextDescriptionColor}
        label={'Description Color'}
        fullWidth
        required
      />
    </SidebarFormGroup>
  )
}
