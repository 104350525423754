import React from 'react'
import { Button } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Container, InnerContainer } from './components'
import Typography from '@mui/material/Typography'

type Props = {
  message: string
  buttonText?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}
/**
 * This component is rendered if the /auth/error route catches.  Since this component renders within the auth0 provider,
 * logout() can be called before redirecting the user to the home page where the init logic will rerun and the user will
 * be navigated to the appropriate page or sent to auth0 to sign in.
 */
export const SignInErrorWithinAuth0Provider: React.FC<Props> = ({ message, buttonText, onClick }) => {
  const navigate = useNavigate()
  const { logout } = useAuth0()

  return (
    <Container>
      <InnerContainer>
        <Typography fontSize={20} color="darkDuskFaded.main">
          Sign-in Error
        </Typography>

        <Typography fontSize={16} color="pinkCold.main" my={2}>
          {message}
        </Typography>

        {buttonText && (
          <Button
            onClick={
              onClick
                ? onClick
                : () => {
                    try {
                      logout()
                      navigate('/')
                    } catch (error) {
                      console.error(error)
                      navigate('/')
                    }
                  }
            }
          >
            {buttonText}
          </Button>
        )}

        <Typography fontSize={12} color="darkDuskFaded.main" mt={2}>
          If the error persists, please contact support@ketch.com.
        </Typography>
      </InnerContainer>
    </Container>
  )
}
