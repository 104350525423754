import React from 'react'
import { AssessmentDTO, AssessmentStatusDTO } from '@ketch-com/figurehead'
import { IconButton, IconType } from './IconButton'

export const AssessmentStatusIcon: React.FC<{ assessment?: AssessmentDTO; width?: number; height?: number }> = ({
  assessment,
  width,
  height,
}) => {
  const percentComplete = Math.floor(
    ((assessment?.attributes?.completedQuestions || 0) / (assessment?.attributes?.totalQuestions || 1)) * 100,
  )

  if (assessment?.attributes?.status === AssessmentStatusDTO.InProgressAssessmentStatus && percentComplete === 100)
    return <IconButton width={width} height={height} type={IconType.COMPLETE} />
  if (assessment?.attributes?.status === AssessmentStatusDTO.InProgressAssessmentStatus)
    return <IconButton width={width} height={height} type={IconType.PROGRESS} percent={percentComplete} />
  if (assessment?.attributes?.status === AssessmentStatusDTO.ExpiredAssessmentStatus)
    return <IconButton width={width} height={height} type={IconType.EXPIRED} />
  if (assessment?.attributes?.status === AssessmentStatusDTO.InReviewAssessmentStatus)
    return <IconButton width={width} height={height} type={IconType.REVIEW} />

  return <IconButton width={width} height={height} type={IconType.APPROVED} />
}
