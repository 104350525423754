import { withDataSystemWidgetsContext } from './context/DataSystemWidgetsContext'
import { DataCategoriesWidget } from './components/DataCategoriesWidget/DataCategoriesWidget'
import React from 'react'
import { Box, Divider, styled } from '@mui/material'
import { PurposesWidget } from './components/PurposesWidget'
import { ProcessingActivitiesWidget } from './components/ProcessingActivitiesWidget'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useDataSystemsContext } from '../../context/DataSystemContext'
import { SystemProfileStatusWidget } from './components/SystemProfileStatusWidget'
import { DemoAssessmentInDataSystemWidget } from 'pages/policyCenter/processingActivities/view/components/widgets/DemoAssesmentInDataSystemWidget'
import { useIsEntitled } from 'utils/hooks'
import { WidgetPreview } from './components/WidgetPreview'

const WidgetsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginBottom: 32,
}))

const DataSystemWidgetsWithContext: React.FC = () => {
  const { isEntitled } = useIsEntitled()
  const entitledToDemo = isEntitled(ENTITLEMENTS.DEMO)
  const { appDescriptor, isEntitledToDataMap } = useDataSystemsContext()
  const displayProgressWidget =
    appDescriptor?.progress?.filled !== undefined &&
    appDescriptor.progress.total &&
    appDescriptor.progress.filled !== appDescriptor.progress.total

  if (isEntitledToDataMap) {
    return (
      <WidgetsContainer>
        {displayProgressWidget && (
          <>
            <SystemProfileStatusWidget />
            <Divider />
          </>
        )}
        <DataCategoriesWidget />
        {entitledToDemo && <DemoAssessmentInDataSystemWidget />}
        <PurposesWidget />
        <ProcessingActivitiesWidget />
      </WidgetsContainer>
    )
  }

  // If User does not have datamap entitlements, display Widget previews
  // TODO: add assessments widget once completed
  const unEntitledWidgetPreviews = [
    { label: 'Data Categories' },
    { label: 'Purposes' },
    { label: 'Processing Activities' },
  ]
  return (
    <WidgetsContainer>
      {unEntitledWidgetPreviews.map(widgetPreview => (
        <WidgetPreview key={widgetPreview.label} label={widgetPreview.label} />
      ))}
    </WidgetsContainer>
  )
}

export const DataSystemWidgets = withDataSystemWidgetsContext(DataSystemWidgetsWithContext)
