import { useMemo } from 'react'
import * as yup from 'yup'
import { mapValues } from 'lodash'

import { useIsEditMode } from 'utils/hooks'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { FormFieldCategoryDTO, FormFieldTypeDTO, FormFieldVariantDTO, FormFieldWidthDTO } from '@ketch-com/figurehead'
import { useCheckIsFormTemplateCodeAvailable } from 'api/formTemplates/mutations/useCheckIsFormTemplateCodeAvailable'
import { validationMessages } from 'utils/constants/forms/validationMessages'

const { object, lazy, string } = yup

// Define schemas for form field values validation
const formFieldWidthDTOSchema = yup
  .string()
  .oneOf(Object.values(FormFieldWidthDTO))
  .required(validationMessages.REQUIRED)

const formFieldVariantDTOSchema = yup
  .string()
  .oneOf(Object.values(FormFieldVariantDTO))
  .required(validationMessages.REQUIRED)

const formFieldTypeDTOSchema = yup.string().oneOf(Object.values(FormFieldTypeDTO)).required(validationMessages.REQUIRED)

const formFieldCategoryDTOSchema = yup
  .string()
  .oneOf(Object.values(FormFieldCategoryDTO))
  .required(validationMessages.REQUIRED)

// Define schema for form template translation
const formTemplateTranslationDTOSchema = lazy(obj =>
  object(
    mapValues(obj, () =>
      object({
        title: string(),
      }),
    ),
  ),
)

// Define schema for form template section translation
const formTemplateSectionTranslationDTOSchema = lazy(obj =>
  object(
    mapValues(obj, () =>
      object({
        description: string(),
        title: string(),
      }),
    ),
  ),
)

// Define schema for preview configuration experience form field
const previewConfigurationExperienceFormFieldDTOSchema = yup
  .object()
  .shape({
    category: formFieldCategoryDTOSchema,
    id: yup.string().required(validationMessages.REQUIRED),
    label: yup.string(),
    name: yup.string().required(validationMessages.REQUIRED),
    required: yup.boolean(),
    type: formFieldTypeDTOSchema,
    variant: formFieldVariantDTOSchema,
    width: formFieldWidthDTOSchema,
  })
  .required(validationMessages.REQUIRED)

// Define schema for form template section
const formTemplateSectionDTOSchema = yup
  .object()
  .shape({
    description: yup.string(),
    formFields: yup.array().of(previewConfigurationExperienceFormFieldDTOSchema),
    title: yup.string(),
    translations: formTemplateSectionTranslationDTOSchema,
  })
  .required(validationMessages.REQUIRED)

/**
 * This hook generates a validation schema for form template.
 * It uses the mutation function from useCheckIsFormTemplateCodeAvailable
 * to check whether the code value entered is available or not.
 * It generates a different schema based on whether the form is in edit mode or not.
 *
 * @return {Object} - The validation schema for form template.
 */
export const useFormTemplateValidationSchema = () => {
  const { mutateAsync: handleCheckIsCodeAvailable } = useCheckIsFormTemplateCodeAvailable()
  const isEditMode = useIsEditMode()

  // Creating schema for code validation
  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(() => {
    return yup
      .object()
      .shape({
        ...(isEditMode ? {} : { code: codeValidationSchema }),
        id: yup.string(),
        name: yup
          .string()
          .required(validationMessages.REQUIRED)
          .max(45, validationMessages.getMaxCharsErrorMessage(45)),
        sections: yup
          .array()
          .of(formTemplateSectionDTOSchema)
          .required(validationMessages.REQUIRED)
          .min(1, 'At least one section is required.'),
        title: yup.string(),
        translations: formTemplateTranslationDTOSchema,
      })
      .required(validationMessages.REQUIRED)
  }, [codeValidationSchema, isEditMode])
}
