import React from 'react'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseLink: {
        width: '100%',
        boxSizing: 'border-box',
        display: 'inline',
        alignItems: 'center',
        fontFamily: typography.fontFamily,
        letterSpacing: '0.01em',
        textDecoration: 'none',
        cursor: 'pointer',

        '&:hover': {
          textDecoration: 'underline',
        },
      },
      baseContainer: {
        display: 'inline-flex',
      },
      baseIcon: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        marginRight: 5,
      },
      baseContent: {
        flexGrow: 1,
      },

      // Sizes:
      sizeRegular: {
        fontWeight: 600,
        fontSize: typography.pxToRem(14),
        lineHeight: typography.pxToRem(20),

        '& $baseIcon svg': {
          fontSize: typography.pxToRem(18),
        },
      },
      sizeSmall: {
        fontWeight: 600,
        fontSize: typography.pxToRem(12),
        lineHeight: typography.pxToRem(18),

        '& $baseIcon svg': {
          fontSize: typography.pxToRem(16),
        },
      },

      // Types:
      typeButtonWrap: {
        textDecoration: 'none',

        '&:hover': {
          textDecoration: 'none',
        },
      },
      typeBlack: {
        color: palette.darkDusk.main,
      },
      typeFaded: {
        color: palette.darkDuskFaded.main,
        textDecoration: 'underline',
      },
      typeBlue: {
        color: palette.sphere.main,
        fontWeight: 400,

        '&:hover': {
          color: palette.royalBlue.main,
        },

        '&:active': {
          color: palette.persianBlue.main,
        },
      },

      // States:
      disabled: {
        pointerEvents: 'none',
        opacity: 0.25,
      },
    }),
  { name: 'Link' },
)

export interface Props {
  children?: React.ReactNode
  /** Component Link URL */
  to: string
  /** Component variant - reflects the colour of border and background */
  variant?: 'black' | 'blue' | 'buttonWrap' | 'faded'
  /** Component size - reflects the font size and dimensions */
  size?: 'regular' | 'small'
  /** Component icon */
  icon?: any
  /** Component custom className */
  className?: any
  /** Considers how to open link */
  target?: '_self' | '_blank'
  /** Is link used outside of app */
  outer?: boolean
  /** Component disabled state */
  disabled?: boolean
  /** Download link attribute */
  download?: boolean
}

/**
 * -
 */
export const Link: React.FC<Props> = ({
  to,
  variant = 'black',
  size = 'regular',
  className,
  icon,
  target = '_self',
  outer = false,
  disabled = false,
  download = false,
  children,
}) => {
  const classes = useStyles()

  const componentClassName = clsx(
    classes.baseLink,
    {
      [classes.typeBlack]: variant === 'black',
      [classes.typeFaded]: variant === 'faded',
      [classes.typeBlue]: variant === 'blue',
      [classes.typeButtonWrap]: variant === 'buttonWrap',
      [classes.sizeRegular]: size === 'regular',
      [classes.sizeSmall]: size === 'small',
      [classes.disabled]: disabled,
    },
    className,
  )

  const extraProps = target === '_blank' ? { rel: 'noopener noreferrer' } : {}

  if (outer) {
    return (
      <a {...extraProps} href={to} download={download} className={componentClassName} target={target}>
        <div className={classes.baseContainer}>
          {icon && <div className={classes.baseIcon}>{icon}</div>}
          {children && <div className={classes.baseContent}>{children}</div>}
        </div>
      </a>
    )
  }

  return (
    <RouterLink {...extraProps} download={download} to={to} target={target} className={componentClassName}>
      <div className={classes.baseContainer}>
        {icon && <div className={classes.baseIcon}>{icon}</div>}
        {children && <div className={classes.baseContent}>{children}</div>}
      </div>
    </RouterLink>
  )
}
