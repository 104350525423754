import { Radio } from '@ketch-com/deck'
import { FormControlLabel, FormHelperText, RadioGroup } from '@mui/material'
import { useFormikContext } from 'formik'
import { Values } from '../utils'

export const RadioStepExample = ({ formFieldValue }: { formFieldValue: keyof Values }) => {
  const { errors, setFieldValue } = useFormikContext<Values>()
  return (
    <>
      <RadioGroup name="group-radio" row onChange={e => setFieldValue(formFieldValue, e.target.value)}>
        <FormControlLabel value="one" control={<Radio />} label="one" />
        <FormControlLabel value="two" control={<Radio />} label="two" />
      </RadioGroup>
      {!!errors[formFieldValue] && (
        <FormHelperText sx={{ color: 'chileanFire.main' }}>{errors[formFieldValue]}</FormHelperText>
      )}
    </>
  )
}
