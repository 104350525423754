import React from 'react'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'

import { ControllerFormDTO } from 'interfaces/controllers/controller'
import { formatCodeFromName } from 'utils/formatters'
import { FormRow } from '@ketch-com/deck'
import { FormInput } from '../../../../../components/form/FormInput'

type Props = {
  isEditMode?: boolean
}

export const ControllerFormSectionBasicDetails: React.FC<Props> = ({ isEditMode = false }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext<ControllerFormDTO>()

  return (
    <FormRow title="Basic Details">
      <Grid container>
        <Grid item xs={6}>
          <FormInput
            required
            fullWidth
            label="Name"
            formPropertyName="name"
            placeholder="Example: My New Controller"
            onChange={e => {
              if (!isEditMode) {
                setFieldTouched('code', true)
                setFieldValue('code', formatCodeFromName(e.target.value))
              }
            }}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={6}>
          <FormInput
            required
            fullWidth
            disabled={isEditMode}
            label="Code"
            formPropertyName="code"
            placeholder="Example: CT957384"
          />
        </Grid>
      </Grid>
    </FormRow>
  )
}
