import React from 'react'

import { Button, PopUp, Spinner } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

type Props = {
  onSubmit?: () => void
  onCancel: () => void
  isBusy?: boolean
  isLoading?: boolean
  title: string
  body: React.ReactNode
  cancelBtnLabel?: string
}

export const AreYouSureYouWantToDeleteModal: React.FC<Props> = ({
  onCancel,
  onSubmit,
  isBusy,
  isLoading,
  body,
  title,
  cancelBtnLabel,
}) => {
  return (
    <PopUp
      title={title}
      variant="dialog"
      popUpWidth="550px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isBusy} onClick={onCancel}>
            {cancelBtnLabel}
          </Button>
          {onSubmit && (
            <Button color="primary" size="large" pending={isBusy} onClick={onSubmit}>
              Delete
            </Button>
          )}
        </>
      }
    >
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : (
        <Typography>{body}</Typography>
      )}
    </PopUp>
  )
}
