import React from 'react'
import { Box } from '@mui/material'
import { SystemListMegaFilter } from './SystemListFilterV2/SystemListMegaFilter'
import { useSystemListFilterContext } from './SystemListFilterV2/SystemListFilterV2Context'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'

export const SystemListFilters: React.FC = () => {
  const { searchQuery, handleSearchInputChange } = useSystemListFilterContext()
  return (
    <Box display="flex" gap={2}>
      <SearchTextInput onChange={nextValue => handleSearchInputChange(nextValue)} value={searchQuery} showClearButton />
      <SystemListMegaFilter />
    </Box>
  )
}
