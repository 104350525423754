import {
  UpdateInstalledDataSystemAssignedCategoriesV3RequestBodyDTO,
  UpdateInstalledDataSystemAssignedCategoriesV3ResponseBodyDTO,
} from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  installedDataSystemId?: string
  formData: UpdateInstalledDataSystemAssignedCategoriesV3RequestBodyDTO
}

export const updateAssignedCategories = ({ installedDataSystemId, formData }: Params) => {
  return API.put<UpdateInstalledDataSystemAssignedCategoriesV3ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v3/installed-data-systems/${installedDataSystemId}/categories`,
    }),
    formData,
  )
}
