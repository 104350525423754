import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchRights } from 'api/rights/fetchers/fetchRights'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.rights,
  queryFn: fetchRights,
  select: res => res?.data?.rights || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useRights = (config?: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config?.params,
      start: 0,
      limit: 1000,
    },
  })
