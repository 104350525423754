import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { useState } from 'react'

export const AssessmentWidgetActionSheet = ({
  handleRemoveAssessmentClick,
  handleUploadFile,
  isProcessingActivityApproved,
  handleSetAssessmentModalState,
  handleSetShowEditApprovedDialog,
  pending,
}: {
  handleRemoveAssessmentClick: () => void
  handleUploadFile: () => void
  isProcessingActivityApproved: boolean
  handleSetAssessmentModalState: () => void
  handleSetShowEditApprovedDialog: () => void
  pending: boolean
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button size="medium" onClick={handleClickListItem} variant="iconSubtle" color="tertiary" pending={pending}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
        <ActionSheetItem
          onClick={() => {
            handleClose()
            if (isProcessingActivityApproved) {
              handleSetShowEditApprovedDialog()
            } else {
              handleSetAssessmentModalState()
            }
          }}
        >
          Change Assessment
        </ActionSheetItem>
        <ActionSheetItem divider />
        <ActionSheetItem
          onClick={() => {
            handleClose()
            if (isProcessingActivityApproved) {
              handleSetShowEditApprovedDialog()
            } else {
              handleUploadFile()
            }
          }}
        >
          Upload File
        </ActionSheetItem>
        <ActionSheetItem divider />
        <ActionSheetItem
          onClick={() => {
            handleClose()
            if (isProcessingActivityApproved) {
              handleSetShowEditApprovedDialog()
            } else {
              handleRemoveAssessmentClick()
            }
          }}
        >
          Remove Assessment
        </ActionSheetItem>
      </ActionSheetWrapper>
    </>
  )
}
