import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  dataSubjectTypeCode: string
}

export const deleteDataSubjectType = ({ dataSubjectTypeCode }: Params) =>
  API.delete<Response>(
    formatRequestString({
      entityUrl: `/api/data-subject-types/${dataSubjectTypeCode}`,
    }),
  )
