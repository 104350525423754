import {
  ExperienceCanonicalRightFormTemplate,
  ExperienceCustomRightFormTemplate,
  ExperienceDTO,
  RightsConfigDTO,
} from 'interfaces/experiences/experience'
import { JurisdictionRightDTO, JurisdictionCanonicalRightDTO } from '@ketch-com/figurehead'

type MergeRightsParams = {
  experience: ExperienceDTO | null
  canonicalRights: JurisdictionCanonicalRightDTO[]
  customRights: JurisdictionRightDTO[]
}

/**
 * Merges the rights from the experience object with the canonical rights.
 *
 * For each right in the experience's canonical and custom rights, if a matching right is found in the canonical rights (based on code),
 * the properties of the matching right are merged into the right from the experience.
 *
 * @param {MergeRightsParams} params - The parameters for the function.
 * @returns {Rights} - The merged rights object.
 */
export const mergeRights = ({ experience, canonicalRights, customRights }: MergeRightsParams): RightsConfigDTO => {
  let mergedCanonicalRights: ExperienceCanonicalRightFormTemplate[] = []

  let mergedCustomRights: ExperienceCustomRightFormTemplate[] = []

  if (experience && experience.preference && experience.preference.rights) {
    const { canonicalRightFormTemplates, customRightFormTemplates } = experience.preference.rights

    // Update canonicalRightFormTemplates
    if (canonicalRightFormTemplates) {
      mergedCanonicalRights = canonicalRightFormTemplates.map(right => {
        const { canonicalRightCode } = right
        const canonicalRight = canonicalRights.find(elem => elem.code === canonicalRightCode) || {}
        return { ...right, ...canonicalRight }
      })
    }

    // Update customRightFormTemplates
    if (customRightFormTemplates) {
      mergedCustomRights = customRightFormTemplates.map(right => {
        const { rightCode } = right
        const customRight = customRights.find(elem => elem.code === rightCode) || {}
        return { ...right, ...customRight }
      })
    }
  }

  // Return the merged rights object
  return {
    ...experience?.preference?.rights,
    canonicalRightFormTemplates: mergedCanonicalRights,
    customRightFormTemplates: mergedCustomRights,
  }
}
