import React from 'react'
import { SxProps, Theme } from '@mui/material'
import { UserShortDTO } from 'interfaces/users/users'

import { useUsers } from 'api/users/queries/useUsers'
import { AvatarStack, Spinner } from '@ketch-com/deck'
import { AvatarSize } from '@ketch-com/deck'
import { UserAvatar } from 'components/avatar/UserAvatar'
import { fromAssignee } from 'utils/helpers/teamStringParser'

type Props = {
  userIds: Array<string>
  maxCount?: number
  sx?: SxProps<Theme>
  avatarBorderColor?: string
  size?: AvatarSize
}

export const AvatarGroup: React.FC<Props> = ({
  userIds,
  maxCount,
  size = AvatarSize.small,
  sx,
  avatarBorderColor = 'white',
}) => {
  const { data: usersList, isLoading: isUsersListLoading } = useUsers({ params: { active: true } })

  if (isUsersListLoading) return <Spinner size="32px" thickness={2.5} />
  const allUsersbyId = Object.fromEntries(usersList.map((user): [string, UserShortDTO] => [user.ID, user]))
  const hasOverflowIds = userIds.length > (maxCount ?? 0)
  const displayedUserIds = hasOverflowIds ? userIds.slice(0, maxCount) : userIds

  return (
    <AvatarStack max={maxCount} sx={sx}>
      {displayedUserIds.map(userId => {
        const [, teamUser] = fromAssignee(userId)
        const user = allUsersbyId[teamUser]

        return <UserAvatar key={userId} user={user} size={size} borderColor={avatarBorderColor} />
      })}
    </AvatarStack>
  )
}
