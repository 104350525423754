import { getRelease } from '.'

/**
 * Clears the 'release' session storage variable.
 * @return null refreshes page
 */
export const clearRelease = () => {
  const release = getRelease()
  if (!release) return
  window.sessionStorage.removeItem('release')
  window.location.reload()
}
