import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { createTransponder } from '../fetchers/createTransponder'

const useCustomMutation = createUseMutation({
  mutationFn: createTransponder,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCreateTransponder = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
