export const auth = {
  root: {
    pattern: '/auth',
    getURL: () => '/auth',
  },

  // invite is used by auth0 for email invitation links
  invite: {
    root: {
      pattern: '/invite',
      getURL: () => '/auth/invite',
    },
  },

  error: {
    root: {
      pattern: '/auth/error',
      getURL: () => '/auth/error',
    },
  },
}
