import React, { useContext } from 'react'

import { DeploymentPlanView } from 'pages/consentAndRights/deploymentPlans/preview/utils'
import { RoutesManager } from 'utils/routing/routesManager'
import { PreviewLayout } from 'components/ui-layouts/previewLayout/PreviewLayout'
import {
  DeploymentPlanPreviewContext,
  withDeploymentPlanPreviewContextContext,
} from './context/DeploymentPlanPreviewContext'
import {
  DeploymentPlanBannerPreview,
  DeploymentPlanModalPreview,
  DeploymentPlanPreferencePreview,
  DeploymentPlanPreviewToolbar,
} from './components'

type Props = {}

const DeploymentPlanPreviewWithoutContext: React.FC<Props> = () => {
  const { activeView, breadcrumbs, deploymentPlanCode, isReady, deploymentPlanName } =
    useContext(DeploymentPlanPreviewContext)

  return (
    <PreviewLayout
      isReady={isReady}
      breadcrumbs={breadcrumbs}
      name={deploymentPlanName}
      code={deploymentPlanCode}
      exitTo={RoutesManager.deployment.deploymentPlans.view.root.getURL({ code: deploymentPlanCode })}
      toolbar={<DeploymentPlanPreviewToolbar />}
    >
      {activeView === DeploymentPlanView.BANNER && <DeploymentPlanBannerPreview />}

      {activeView === DeploymentPlanView.MODAL && <DeploymentPlanModalPreview />}

      {activeView === DeploymentPlanView.PREFERENCE && <DeploymentPlanPreferencePreview />}
    </PreviewLayout>
  )
}

export const DeploymentPlanPreview = withDeploymentPlanPreviewContextContext(DeploymentPlanPreviewWithoutContext)
