import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import {
  BuilderTextInputMode,
  FormTextInput,
} from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'

export const PreferenceThemeRequestsTabFormActionButtonForm: React.FC = () => {
  return (
    <SidebarFormGroup sx={{ pt: 0, gap: '16px' }}>
      <FormColorInput
        name={themeUpsertFormKeys.preferenceRequestsTabFormActionButtonBackground}
        label={'Background Color'}
        fullWidth
        required
      />
      <FormColorInput
        name={themeUpsertFormKeys.preferenceRequestsTabFormActionButtonTextColor}
        label={'Text Color'}
        fullWidth
        required
      />
      <FormTextInput
        name={themeUpsertFormKeys.preferenceRequestsTabFormActionButtonCornerRadius}
        label="Action Button Corner Radius"
        inputWidth="92px"
        mode={BuilderTextInputMode.CornerRadius}
      />
    </SidebarFormGroup>
  )
}
