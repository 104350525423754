import React from 'react'
import numeral from 'numeral'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import { AreaChart, XAxis, YAxis, Area, ResponsiveContainer, Tooltip, Label } from 'recharts'

import { Text } from 'components/ui-kit/typography/Text'
import { formatCountWithoutDecimals } from 'pages/insights/permitsStatistics/components/PermitsSummary/components/PermitStatisticsOverall/utils'
import { UserActivityQueriesGraphTooltip } from '.'
import { getSumOfPropertyInAnArrayOfObjects } from 'utils/helpers/array/getSumOfPropertyInAnArrayOfObjects'
import { CustomAxisTickMark, CustomRechartLabel } from 'pages/insights/activityAndAccess/components'
import { teamMembers } from 'pages/insights/activityAndAccess/__mocks__/generateDummyTeamMembers'
import { theme } from '@ketch-com/deck'

type Props = {}

export const UserActivityQueriesGraph: React.FC<Props> = props => {
  const location = useLocation()
  const userId = location?.pathname?.split('/')?.[4] || ''
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const data = useMemo(() => generateTeamMemberQueriesGraphData(25), [userId])
  const teamMember = teamMembers.find(member => member.id === userId)
  const data = teamMember?.userActivityGraphStaticData
  const totalQueries = getSumOfPropertyInAnArrayOfObjects(data, 'queryCount')

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" alignItems="center" flex={3.2}>
        <ResponsiveContainer height={420}>
          <AreaChart height={420} data={data} margin={{ top: 10, right: 30, left: -10, bottom: 0 }}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={theme.palette.graph14.main} stopOpacity={0.8} />
                <stop offset="95%" stopColor={theme.palette.graph14.main} stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="name"
              tickLine={false}
              stroke={theme.palette.iron.main}
              interval="preserveStartEnd"
              tick={<CustomAxisTickMark customXOffset={10} customYOffset={10} />}
            />
            <YAxis
              dataKey="queryCount"
              tick={
                <CustomAxisTickMark
                  customXOffset={25}
                  customYOffset={10}
                  customTickFormatter={(value: any, index: number) => {
                    const isFirstIndex = index === 0
                    return !isFirstIndex ? `${formatCountWithoutDecimals(value)}` : ''
                  }}
                />
              }
              tickLine={false}
              stroke={theme.palette.iron.main}
              domain={[
                0,
                (dataMax: any) => {
                  const roundedDataMax = Math.round(dataMax / 10) * 10
                  const roundedYAxisBuffer = Math.round(Math.floor(dataMax * 0.3) / 10) * 10
                  return roundedDataMax + roundedYAxisBuffer
                },
              ]}
            >
              <Label
                content={props => {
                  return <CustomRechartLabel title="Queries Graph" xOffset={60} yOffset={2} />
                }}
              />
            </YAxis>
            <Tooltip
              cursor={false}
              content={props => {
                return (
                  <UserActivityQueriesGraphTooltip active={props.active} label={props.label} payload={props.payload} />
                )
              }}
            />
            <Area
              type="monotone"
              dataKey="queryCount"
              stroke="#9C64FF"
              fillOpacity={1}
              fill="url(#colorUv)"
              strokeWidth={1}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
      <Box display="flex" flex={1} flexDirection="column" justifyContent="flex-start" height="100%">
        <Text mb={1} size={14}>
          Queries for selected period
        </Text>
        <Text mb={8} size={20} weight={700} color="heliotrope">
          {numeral(totalQueries).format('0,0')}
        </Text>

        <Text mb={1} size={14}>
          Total queries made
        </Text>
        <Text size={20} weight={700}>
          {numeral(totalQueries * 3).format('0,0')}
        </Text>
      </Box>
    </Box>
  )
}
