import React, { useState } from 'react'
import clsx from 'clsx'
import { Box, Grid, Paper } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'

import { Text } from 'components/ui-kit/typography/Text'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { ReactComponent as RightArrowIcon } from 'assets/icons/arrow_right_relationships.svg'
import { DeleteRelationshipModal } from '../DeleteRelationshipModal'
import { MappingDTO } from '@ketch-com/figurehead'
import { useForceRerenderToAdjustRowHeight, useRelationshipsTableUtils } from './hooks'
import { getReferencesListContainerMaxHeight } from './utils'
import { ForeignKeyPill, PrimaryKeyPill, RelationshipsInfiniteScrollLoadingIndicator } from './components'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      sectionTitle: {
        marginBottom: spacing(0.5),
      },
      subSectionTitle: {
        color: palette.lightGrey.main,
        marginBottom: spacing(3),
      },
      paper: {
        position: 'relative',
        borderBottom: `1px solid ${palette.iron.main}`,
      },
      paperLeftColumn: {
        position: 'relative',
        borderRight: `1px solid ${palette.iron.main}`,
      },
      paperRightColumn: {
        paddingLeft: spacing(4.75),
      },
      gridRoot: {
        position: 'relative',
        '& .MuiGrid-item': {
          padding: spacing(0, 2),
        },
      },
      rightArrowIcon: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        right: -15,
        top: '45%',
        padding: spacing(0.25),
        borderRadius: '50%',
      },
      overlayDiv: {
        zIndex: 10,
        backgroundColor: palette.fadedGrey.main,
        position: 'absolute',
        border: '1px solid rgba(7, 26, 36, 0.5)',
        borderRadius: '100px',
      },
      pillContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      },
    }),
  { name: 'RelationshipsCreatedTab' },
)

type Props = {
  type: 'discovered' | 'created'
  relationships: MappingDTO[]
  isLoadingReferences?: boolean
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
  fetchNextPage: () => void
}

export const RelationshipsTable: React.FC<Props> = ({
  relationships,
  isLoadingReferences,
  hasNextPage,
  fetchNextPage,
  type,
  isFetchingNextPage,
}) => {
  const classes = useStyles()
  const [relationshipToDelete, setRelationshipToDelete] = useState<MappingDTO | undefined>(undefined)
  const { handlePointerOnEnterEvent, handlePointerOnLeave, handleReferencesListContainerOnScroll } =
    useRelationshipsTableUtils({
      hasNextPage,
      fetchNextPage,
      isLoadingReferences,
    })
  useForceRerenderToAdjustRowHeight({ isLoadingReferences })

  if (!relationships?.length) {
    return null
  }

  return (
    <Box
      id="referencesListContainer"
      onScroll={handleReferencesListContainerOnScroll}
      maxHeight={isLoadingReferences ? 500 : getReferencesListContainerMaxHeight()}
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Grid id="myGrid" container spacing={2} className={classes.gridRoot}>
        {/* First Row */}

        <Grid item xs={6} className={classes.paperLeftColumn}>
          <Paper sx={{ pt: 1 }} elevation={0}>
            <Text size={12} weight={600}>
              Foreign Keys
            </Text>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ pt: 1 }} elevation={0}>
            <Box pl={4.75}>
              <Text size={12} weight={600} color="heliotrope">
                Primary Keys
              </Text>
            </Box>
          </Paper>
        </Grid>

        {/* Map Subsequent Rows */}

        {relationships.map((relationship, index) => {
          const {
            id: relationshipId,
            foreignKey: foreignKeyArray,
            primaryKey: primaryKeyArray,
            foreignKeyParent,
            primaryKeyParent,
          } = relationship
          const foreignKeyArrayWithParent = [foreignKeyParent, ...(foreignKeyArray || [])]
          const primaryKeyArrayWithParent = [primaryKeyParent, ...(primaryKeyArray || [])]

          const leftPaperId = `leftPaperId-${relationshipId}`
          const rightPaperId = `rightPaperId-${relationshipId}`

          const leftPaperElement = document.getElementById(leftPaperId) as HTMLDivElement
          const rightPaperElement = document.getElementById(rightPaperId) as HTMLDivElement

          if (leftPaperElement && rightPaperElement) {
            const leftHeight = leftPaperElement.offsetHeight
            const rightHeight = rightPaperElement.offsetHeight

            if (leftHeight > rightHeight) {
              rightPaperElement.style.height = `${leftHeight}px`
            }
            if (leftHeight < rightHeight) {
              leftPaperElement.style.height = `${rightHeight}px`
            }
          }

          return (
            <React.Fragment key={relationshipId}>
              {/* Left Column */}

              <Grid item xs={6} className={classes.paperLeftColumn}>
                <Box className={classes.rightArrowIcon}>
                  <RightArrowIcon />
                </Box>
                <Paper id={leftPaperId} elevation={0} className={classes.paper}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    sx={{
                      height: '100%',
                      pt: index === 0 ? 0 : 2,
                      pl: 0.25,
                    }}
                  >
                    <Box mb={1}>
                      <Text size={12} weight={500}>
                        {foreignKeyParent?.resource?.name}
                      </Text>
                    </Box>
                    {(foreignKeyArray || []).map((foreignKey, index) => (
                      <ForeignKeyPill
                        foreignKey={foreignKey!}
                        foreignKeyLength={foreignKeyArrayWithParent!.length}
                        handlePointerOnEnterEvent={handlePointerOnEnterEvent}
                        handlePointerOnLeave={handlePointerOnLeave}
                        index={index}
                        key={foreignKey?.resource!.id}
                        relationshipId={relationshipId!}
                      />
                    ))}
                  </Box>
                </Paper>
              </Grid>

              {/* Right Column */}

              <Grid item xs={6}>
                <Paper id={rightPaperId} elevation={0} className={clsx(classes.paper, classes.paperRightColumn)}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flex={1}
                    sx={{
                      height: '100%',
                      pt: index === 0 ? 0 : 2,
                    }}
                  >
                    <Box display="flex" flexDirection="column" className={classes.pillContainer}>
                      <Box mb={1}>
                        <Text size={12} weight={500}>
                          {primaryKeyParent?.resource?.name}
                        </Text>
                      </Box>
                      {(primaryKeyArray || []).map((primaryKey, index) => (
                        <PrimaryKeyPill
                          pillContainerId={`${relationshipId}-primary-key-${index}`}
                          key={primaryKey?.resource!.id}
                          primaryKey={primaryKey!}
                          primaryKeyLength={primaryKeyArrayWithParent!.length}
                          handlePointerOnEnterEvent={handlePointerOnEnterEvent}
                          handlePointerOnLeave={handlePointerOnLeave}
                          index={index}
                        />
                      ))}
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      sx={{
                        opacity: type === 'discovered' ? 0 : 1,
                      }}
                    >
                      <ButtonOptions
                        disabled={type === 'discovered'}
                        size="small"
                        items={[
                          {
                            content: 'Remove',
                            onClick: () => setRelationshipToDelete(relationship),
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </React.Fragment>
          )
        })}
        <Grid item xs={12}>
          <RelationshipsInfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
        </Grid>
      </Grid>

      {/* Delete Relationship Modal */}

      {!!relationshipToDelete ? (
        <DeleteRelationshipModal
          relationship={relationshipToDelete}
          onCancel={() => {
            setRelationshipToDelete(undefined)
          }}
          onSubmit={() => {
            setRelationshipToDelete(undefined)
          }}
        />
      ) : null}
    </Box>
  )
}
