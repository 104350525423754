import { AssetPurposeDTO } from '@ketch-com/figurehead'

type GetFilterOptionsArgs = { options: AssetPurposeDTO[]; newPurpose: string; purposes: AssetPurposeDTO[] }

type SearchFilterArgs = { element: AssetPurposeDTO; newPurpose: string }

type FilterFunctionArgs = {
  element: AssetPurposeDTO
  purposes: AssetPurposeDTO[]
}

const searchFilter = ({ element, newPurpose }: SearchFilterArgs) => {
  const tempString = Object.values(element).join('').replace(' ', '').toLowerCase()
  return tempString.includes(newPurpose)
}

const alreadySelectedFilter = ({ element, purposes }: FilterFunctionArgs) => {
  const purposeCodesArray = purposes.map(l => l?.code || '')
  const isValid = purposeCodesArray.indexOf(element?.code || '') === -1
  return isValid
}

export const getFilterOptions = ({ options, newPurpose, purposes }: GetFilterOptionsArgs): AssetPurposeDTO[] =>
  options.filter(o => searchFilter({ element: o, newPurpose }) && alreadySelectedFilter({ element: o, purposes }))
