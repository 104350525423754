export const GlobalControl = {
  Code: 'global_control',
} as const

export const SubscriptionTopicsTab = {
  SubscriptionTopicsList: 'List of Subscriptions',
  SubscriptionTopicsSwitchButton: 'Switch Buttons',
} as const

export const UnsubscribeAllTabs = {
  Title: 'Title',
  Description: 'Description',
  SwitchButton: 'Switch Button',
  Position: 'Position',
  Impact: 'Impact',
} as const

export const CONTROL_DEFAULTS = {
  TITLE: 'Unsubscribe from all',
} as const

export const SWITCH_MODES = {
  ON: 'on',
  OFF: 'off',
} as const

export const SWITCH_DEFAULT_TEXT = {
  OPT_IN: 'Opted In',
  OPT_OUT: 'Opted Out',
} as const
