import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import { Avatar, AvatarSize, Button, Icon, IconDictionary, Widget, theme } from '@ketch-com/deck'
import { ProcessingActivityContext } from '../../context/ProcessingActivityContext'
import Typography from '@mui/material/Typography'
import { ProcessingActivityDataSystemConsentStatusDTO, ProcessingActivityDataSystemDTO } from '@ketch-com/figurehead'
import { ProcessingActivityConsentStatusLabel } from '../../../constants'
import { ConsentStatusModal } from './ConsentStatusModal'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

export const ProcessingActivityConsentStatusIcon = {
  [ProcessingActivityDataSystemConsentStatusDTO.OrchestratedProcessingActivityDataSystemConsentStatus]:
    IconDictionary.OShapesCircular,
  [ProcessingActivityDataSystemConsentStatusDTO.NeedsConfirmationProcessingActivityDataSystemConsentStatus]:
    IconDictionary.FUnknown,
  [ProcessingActivityDataSystemConsentStatusDTO.NoConnectionsProcessingActivityDataSystemConsentStatus]:
    IconDictionary.FUnknown,
  [ProcessingActivityDataSystemConsentStatusDTO.UnspecifiedProcessingActivityDataSystemConsentStatus]:
    IconDictionary.FUnknown,
}

export const ProcessingActivityConsentStatusColor = {
  [ProcessingActivityDataSystemConsentStatusDTO.OrchestratedProcessingActivityDataSystemConsentStatus]:
    theme.palette.greenHaze.main,
  [ProcessingActivityDataSystemConsentStatusDTO.NeedsConfirmationProcessingActivityDataSystemConsentStatus]:
    theme.palette.chileanFire.main,
  [ProcessingActivityDataSystemConsentStatusDTO.NoConnectionsProcessingActivityDataSystemConsentStatus]:
    theme.palette.chileanFire.main,
  [ProcessingActivityDataSystemConsentStatusDTO.UnspecifiedProcessingActivityDataSystemConsentStatus]:
    theme.palette.darkDuskFaded.main,
}

export const renderConsentStatusIcon = (
  system: ProcessingActivityDataSystemDTO,
  width: number = 18,
  height: number = 18,
) => {
  const iconName =
    system.consentStatusSetManually &&
    system.consentStatus ===
      ProcessingActivityDataSystemConsentStatusDTO.OrchestratedProcessingActivityDataSystemConsentStatus
      ? IconDictionary.FCheckRound
      : ProcessingActivityConsentStatusIcon[
          system.consentStatus ||
            ProcessingActivityDataSystemConsentStatusDTO.UnspecifiedProcessingActivityDataSystemConsentStatus
        ]
  const iconColor =
    ProcessingActivityConsentStatusColor[
      system.consentStatus ||
        ProcessingActivityDataSystemConsentStatusDTO.UnspecifiedProcessingActivityDataSystemConsentStatus
    ]

  return <Icon name={iconName} iconColor={iconColor} width={width} height={height} />
}

export const renderConsentStatusLabel = (system: ProcessingActivityDataSystemDTO) => {
  const labelText =
    system.consentStatusSetManually &&
    system.consentStatus ===
      ProcessingActivityDataSystemConsentStatusDTO.OrchestratedProcessingActivityDataSystemConsentStatus
      ? 'Confirmed Manually'
      : ProcessingActivityConsentStatusLabel[
          system.consentStatus ||
            ProcessingActivityDataSystemConsentStatusDTO.UnspecifiedProcessingActivityDataSystemConsentStatus
        ]

  const labelColor =
    ProcessingActivityConsentStatusColor[
      system.consentStatus ||
        ProcessingActivityDataSystemConsentStatusDTO.UnspecifiedProcessingActivityDataSystemConsentStatus
    ]

  return (
    <Typography variant="smallLabel" color={labelColor}>
      {labelText}
    </Typography>
  )
}

export const ConsentStatusWidget: React.FC = () => {
  const {
    processingActivity,
    handleConsentStatusUpdate,
    setShowEditApprovedDialog,
    isProcessingActivityApproved,
    isConsentStatusUpdating,
  } = useContext(ProcessingActivityContext)
  const { isPermitted } = useIsPermitted()
  const [showViewAllModal, setShowViewAllModal] = useState(false)

  const totalSystemsCount = processingActivity.dataSystems ? processingActivity.dataSystems.length : 0
  const orchestratedCount = processingActivity.dataSystems
    ? processingActivity.dataSystems.filter(
        system =>
          system.consentStatus ===
          ProcessingActivityDataSystemConsentStatusDTO.OrchestratedProcessingActivityDataSystemConsentStatus,
      ).length
    : 0

  const handleUndoClick = async (id: string) => {
    if (isProcessingActivityApproved) {
      setShowEditApprovedDialog(true)
    } else {
      await handleConsentStatusUpdate({
        params: {
          activityId: processingActivity.id || '',
          dataSystemId: id,
          formData: {
            consentStatus:
              ProcessingActivityDataSystemConsentStatusDTO.NeedsConfirmationProcessingActivityDataSystemConsentStatus,
          },
        },
      })
    }
  }

  const handleConfirmClick = async (id: string) => {
    if (isProcessingActivityApproved) {
      setShowEditApprovedDialog(true)
    } else {
      await handleConsentStatusUpdate({
        params: {
          activityId: processingActivity.id || '',
          dataSystemId: id,
          formData: {
            consentStatus:
              ProcessingActivityDataSystemConsentStatusDTO.OrchestratedProcessingActivityDataSystemConsentStatus,
          },
        },
      })
    }
  }

  return (
    <>
      <Widget
        expandable
        title="Consent Status"
        subTitle={`${totalSystemsCount} data sources.`}
        actionButton={
          totalSystemsCount > 7 ? (
            <Button color="tertiary" onClick={() => setShowViewAllModal(!showViewAllModal)}>
              View All
            </Button>
          ) : (
            <></>
          )
        }
        content={
          <Box width="100%">
            {/* Summary Percentage */}
            <Box display="flex" alignItems="center" gap={2} mb={3.5}>
              <Avatar
                icon="ODatabaseLoad"
                variant="circular"
                size={AvatarSize.xlarge}
                backgroundColor={theme.palette.tertiary.main}
              />
              <Box display="flex" flexDirection="column">
                <Typography variant="h2">
                  {Math.round((orchestratedCount / (totalSystemsCount !== 0 ? totalSystemsCount : 1)) * 100)}%
                </Typography>
                <Typography variant="label">of data is Consented</Typography>
              </Box>
            </Box>

            {/* List of systems */}
            <Box display="flex" flexDirection="column" gap={2} mb={1.5}>
              {processingActivity.dataSystems &&
                processingActivity.dataSystems.slice(0, 7).map(system => (
                  // Only show first 7 systems, rest in modal
                  <Box key={system.id} display="flex" gap={2} alignItems="center">
                    <Avatar
                      isLogo
                      src={system?.dataSystem?.logoUrl}
                      alt={system?.dataSystem?.name}
                      variant="rounded"
                      size={AvatarSize.xlarge}
                      backgroundColor={theme.palette.tertiary.main}
                    />
                    <Box display="flex" flexDirection="column">
                      <Typography variant="label">{system?.dataSystem?.name || 'Unknown'}</Typography>
                      <Box display="flex" alignItems="center" gap={0.25}>
                        {renderConsentStatusIcon(system)}
                        {renderConsentStatusLabel(system)}
                      </Box>
                    </Box>

                    {system.consentStatusSetManually &&
                      system.consentStatus ===
                        ProcessingActivityDataSystemConsentStatusDTO.OrchestratedProcessingActivityDataSystemConsentStatus &&
                      isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_WRITE) && (
                        <Button
                          variant="icon"
                          color="white"
                          sx={{ ml: 'auto', p: 0.25 }}
                          pending={isConsentStatusUpdating}
                          onClick={() => {
                            handleUndoClick(system.id || '')
                          }}
                        >
                          <Icon name={'OUndo'} width={24} height={24} />
                        </Button>
                      )}

                    {/* Confirm system */}
                    {(system.consentStatus ===
                      ProcessingActivityDataSystemConsentStatusDTO.NeedsConfirmationProcessingActivityDataSystemConsentStatus ||
                      system.consentStatus ===
                        ProcessingActivityDataSystemConsentStatusDTO.NoConnectionsProcessingActivityDataSystemConsentStatus) &&
                      isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_WRITE) && (
                        <Button
                          color="secondary"
                          sx={{ ml: 'auto' }}
                          onClick={() => {
                            handleConfirmClick(system.id || '')
                          }}
                          pending={isConsentStatusUpdating}
                        >
                          Confirm
                        </Button>
                      )}
                  </Box>
                ))}
            </Box>

            {totalSystemsCount > 7 && (
              <Button
                onClick={() => setShowViewAllModal(!showViewAllModal)}
                variant="link"
                color="secondary"
                sx={{
                  pl: 0,
                  '&.MuiButtonBase-root': {
                    '&:hover': {
                      '& .DeckBtnWrapChild': {
                        borderColor: 'darkDuskFaded.main',
                      },
                    },
                  },
                }}
              >
                <Typography color="darkDuskFaded.main">And {totalSystemsCount - 7} more</Typography>
              </Button>
            )}
          </Box>
        }
      />
      {/* Modal to view all systems if > 7 */}
      {showViewAllModal && <ConsentStatusModal onClose={() => setShowViewAllModal(false)} />}
    </>
  )
}
