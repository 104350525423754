import { UpsertPolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { PolicyScopeFormValues } from '../interfaces'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'

export const mapValuesToPayload = ({ ...values }: PolicyScopeFormValues): UpsertPolicyScopeDTO => {
  const { rightsFulfillment, ...rest } = values
  const hasAtLeastOneRightWithAllowAppeal = rightsFulfillment.some(({ allowAppeal }) => allowAppeal)

  const payload = {
    ...rest,
    rightsFulfillment: rightsFulfillment
      .filter(({ checked }) => checked)
      .map(({ rightCode, fulfillment, allowAppeal, customRightNameID }) => ({
        rightCode,
        fulfillment: +fulfillment,
        allowAppeal: !!allowAppeal,
        customRightNameID: customRightNameID || '',
      })),
    timeToFulfillAppealInDays: hasAtLeastOneRightWithAllowAppeal
      ? +(rest?.timeToFulfillAppealInDays || '45')
      : undefined,
    timeToInvokeAppealInDays: hasAtLeastOneRightWithAllowAppeal ? +(rest?.timeToInvokeAppealInDays || '30') : undefined,
  }

  logObjectDetails(
    {
      values,
      payload,
    },
    'mapValuesToPayload.ts',
    'white',
    false, // ensures will only log in debug mode, ?debug=log
  )

  return payload
}
