import { Box, Grid, Typography } from '@mui/material'
import { Chip, Icon, InfoRow } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'
import { ViewModeLayout } from 'components/ui-layouts/viewLayout/ViewModeLayout'
import { RoutesManager } from 'utils/routing/routesManager'
import { useDataMapPurpose } from 'api/dataMap/queries/useDataMapPurpose'
import { PurposeDataRole, PURPOSE_DATA_ROLE_NAMES } from 'interfaces/purposes/purposeDataRole'
import { showToast } from 'components/modals/AlertComponent'
import { TeamChip } from 'components/chip/TeamChip'
import { useTeams } from 'api/teams/queries/useTeams'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type Props = {
  purposeCode: string
}

const ChipsList = ({ data }: { data: string[] }) =>
  data && data.length ? (
    <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
      {data.map(string => !!string.length && <Chip label={string} key={string} />)}
    </Box>
  ) : (
    <EmptyValueRenderer />
  )

const unique = (array: any[]) => [...new Set(array)]

export const DataMapPurposeView: React.FC<Props> = ({ purposeCode }) => {
  const navigate = useNavigate()
  const { data: purpose, isLoading: isPurposeLoading } = useDataMapPurpose({
    params: { purposeCode },
    onError: () => {
      showToast({ content: 'Failed to fetch data map purpose', type: 'error' })
    },
  })

  const onChipNavigate = (code?: string) => {
    if (code) {
      navigate(RoutesManager.policyCenter.policyDocuments.view.root.getURL({ code }))
    }
  }

  const DocumentChipsList = purpose.documents ? (
    <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
      {purpose.documents?.map(({ name, code }) => (
        <Chip
          clickable
          key={name}
          label={name || 'File'}
          deleteIcon={<Icon name="OArrowCRight" />}
          onClick={() => onChipNavigate(code)}
          onDelete={() => onChipNavigate(code)}
        />
      ))}
    </Box>
  ) : (
    <EmptyValueRenderer />
  )

  const legalBases = purpose.legalBases ? (
    <Box width="100%">
      <Grid container mb={2}>
        <Grid item xs={3}>
          <Typography color="darkGrey.main" variant="label">
            Jurisdiction
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography color="darkGrey.main" variant="label">
            Legal Bases
          </Typography>
        </Grid>
      </Grid>
      {purpose.legalBases?.map(({ jurisdiction, legalBasis }) => (
        <InfoRow
          key={jurisdiction}
          title={jurisdiction ?? ''}
          sx={{
            marginBottom: '16px',
            '&.InfoRow-container': {
              '&.InfoRow-medium': {
                '& .InfoRow-helpTextTitle': {
                  color: theme => theme.palette.darkDusk.main,
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '16px',
                },
              },
            },
          }}
        >
          {legalBasis}
        </InfoRow>
      ))}
    </Box>
  ) : (
    <EmptyValueRenderer />
  )

  const { data: teamList, isLoading: isTeamsLoading } = useTeams({})

  const teams = teamList?.teams?.filter(team => purpose.teamIDs?.includes(team.id || ''))

  return (
    <ViewModeLayout
      title="Overview"
      subtitle={`Basic information about ${purpose.name || 'Purpose'}`}
      isLoading={isPurposeLoading}
      shouldPadRows={true}
      contentRows={[
        {
          title: 'Description',
          info: purpose.description ? <Typography>{purpose.description}</Typography> : <EmptyValueRenderer />,
        },
        {
          title: 'Duration of Data Usage',
          info: purpose.duration ? <Typography variant="label">{purpose.duration}</Typography> : <EmptyValueRenderer />,
        },
        { title: 'Documents', info: DocumentChipsList },
        { title: 'Classifications', info: <ChipsList data={purpose.dataCategories || []} /> },
        {
          title: 'Teams',
          info: (
            <Box display="flex" gap={1}>
              {isTeamsLoading ? (
                'Loading...'
              ) : (teams || []).length > 0 ? (
                teams?.map(team => <TeamChip team={team} showSecondaryLabel={false} key={team.id} />)
              ) : (
                <EmptyValueRenderer />
              )}
            </Box>
          ),
        },
        {
          title: 'Subject Type',
          info: purpose.dataSubjectTypes ? (
            <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
              {purpose.dataSubjectTypes.map(dst => (
                <Chip key={dst?.code} label={dst.name} secondaryLabel={dst.code} />
              ))}
            </Box>
          ) : (
            <EmptyValueRenderer />
          ),
        },

        {
          title: 'Data Role',
          info: purpose?.dataRole ? (
            <Chip label={PURPOSE_DATA_ROLE_NAMES?.[purpose.dataRole as PurposeDataRole]} />
          ) : (
            <EmptyValueRenderer />
          ),
        },

        { title: 'Legal Bases', info: legalBases },
        {
          title: 'Data Transfers From',
          info: <ChipsList data={unique(purpose.dataTransfersFrom || [])} />,
        },
        {
          title: 'Data Transfers To',
          info: <ChipsList data={unique(purpose.dataTransfersTo || [])} />,
        },
      ]}
    />
  )
}
