export enum HookTypeFilterSelectOptions {
  ALL = 'All',
  PRE = 'PRE',
  POST = 'POST',
}

export enum HookTypeEnum {
  PRE = 1,
  POST = 2,
}

export const HOOK_TYPE_FILTER_SELECT_OPTIONS = [
  {
    id: HookTypeFilterSelectOptions.ALL,
    name: HookTypeFilterSelectOptions.ALL,
  },
  {
    id: HookTypeFilterSelectOptions.PRE,
    name: HookTypeFilterSelectOptions.PRE,
  },
  {
    id: HookTypeFilterSelectOptions.POST,
    name: HookTypeFilterSelectOptions.POST,
  },
]
