import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchWorkflowPreview } from '../fetchers/fetchWorkflowPreview'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.workflowPreview,
  queryFn: fetchWorkflowPreview,
  select: res => res?.data?.workflowExecution || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useWorkflowPreview = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params,
    },
    staleTime: 30 * 1000, // 30 seconds
  })
}
