import { CreateStackResponseBodyDTO, PutStackResponseBodyDTO, StackDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  code?: string
  formData: StackDTO
}

export const upsertStack = ({ code, formData }: Params) => {
  const url = formatRequestString({
    entityUrl: `/api/stacks${code ? `/${code}` : ''}`,
  })

  const body = { stack: formData }

  if (code) {
    return API.put<PutStackResponseBodyDTO>(url, body)
  }

  return API.post<CreateStackResponseBodyDTO>(url, body)
}
