import { createUseQuery } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchDataSystemGlobalCustomAttributes } from '../fetchers/fetchDataSystemGlobalCustomAttributes'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.dataSystemGlobalCustomAttributes,
  queryFn: fetchDataSystemGlobalCustomAttributes,
  select: res => res?.data || null,
})

export const useDataSystemGlobalCustomAttributes = () => {
  return useCustomQuery({ params: {} })
}
