import React, { useCallback } from 'react'

import { useFormikContext } from 'formik'
import { PolicyFormValues } from 'interfaces/policies/PolicyFormValues'

export const useCreatePolicyFormUtils = () => {
  const { values, isSubmitting, setFieldValue } = useFormikContext<PolicyFormValues>()

  const handleAddPolicyModifier = useCallback(
    (e: React.MouseEvent) => {
      switch (e.currentTarget.id) {
        case 'Effects':
          setFieldValue('attributeEffects', [{ effect: '', criteria: '', labelName: '' }])
          break
        case 'Filters':
          setFieldValue('filters', [{ name: '', condition: '', value: '' }])
          break
        default:
          return
      }
    },
    [setFieldValue],
  )

  /* Access */

  const handleAddAccessAllowance = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      setFieldValue('accessAllowances', [...values.accessAllowances, { assetType: '', criteria: '', labelName: {} }])
    },
    [setFieldValue, values.accessAllowances],
  )

  const handleRemoveAccessAllowance = useCallback(
    (e: React.MouseEvent) => {
      const indexToRemove = parseInt(e.currentTarget.id)
      setFieldValue(
        'accessAllowances',
        values.accessAllowances.filter((_, i) => i !== indexToRemove),
      )
    },
    [setFieldValue, values.accessAllowances],
  )

  /* Filters */

  const handleAddFilter = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      setFieldValue('filters', [...values.filters, { name: '', condition: '', value: '' }])
    },
    [setFieldValue, values.filters],
  )

  const handleRemoveFilter = useCallback(
    (e: React.MouseEvent) => {
      const indexToRemove = parseInt(e.currentTarget.id)
      setFieldValue(
        'filters',
        values.filters.filter((_, i) => i !== indexToRemove),
      )
    },
    [setFieldValue, values.filters],
  )

  const handleRemoveFiltersFormSection = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      setFieldValue('filters', [])
    },
    [setFieldValue],
  )

  /* Effects */

  const handleAddAttributeEffect = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      setFieldValue('attributeEffects', [...values.attributeEffects, { effect: '', criteria: '', labelName: '' }])
    },
    [setFieldValue, values.attributeEffects],
  )

  const handleRemoveAttributeEffect = useCallback(
    (e: React.MouseEvent) => {
      const indexToRemove = parseInt(e.currentTarget.id)
      setFieldValue(
        'attributeEffects',
        values.attributeEffects.filter((_, i) => i !== indexToRemove),
      )
    },
    [setFieldValue, values.attributeEffects],
  )

  const handleRemoveEffectsFormSection = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      setFieldValue('attributeEffects', [])
    },
    [setFieldValue],
  )

  return {
    handleAddPolicyModifier,
    handleAddAccessAllowance,
    handleRemoveAccessAllowance,
    handleAddAttributeEffect,
    handleRemoveAttributeEffect,
    handleRemoveEffectsFormSection,
    handleRemoveFiltersFormSection,
    handleAddFilter,
    handleRemoveFilter,
    isSubmitting,
    values,
  }
}
