import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { getURLParams, CodeParams } from './utils'

export const settings = {
  root: {
    pattern: '/settings',
    getURL: () => '/settings',
  },

  organization: {
    root: {
      pattern: '/organization',
      getURL: () => '/settings/organization',
    },

    edit: {
      root: {
        pattern: '/organization/edit',
        getURL: () => `/settings/organization/edit`,
      },
    },

    changeSubscription: {
      root: {
        pattern: '/organization/change-subscription',
        getURL: () => `/settings/organization/change-subscription`,
      },
    },
  },

  planBilling: {
    root: {
      pattern: '/plan-and-billing',
      getURL: () => '/settings/plan-and-billing',
    },
  },

  users: {
    root: {
      pattern: '/users',
      getURL: () => '/settings/users',
    },

    view: {
      root: {
        pattern: '/users/view/:id',
        getURL: ({ id }: getURLParams) => `/settings/users/view/${id}`,
      },
    },

    upsert: {
      root: {
        pattern: '/users/edit/:id',
        getURL: ({ id }: getURLParams) => `/settings/users/edit/${id}`,
      },
    },
  },

  teams: {
    root: {
      pattern: '/teams',
      getURL: () => '/settings/teams',
    },

    view: {
      root: {
        pattern: '/teams/:id',
        getURL: ({ id }: getURLParams) => `/settings/teams/${id}`,
      },
    },

    upsert: {
      root: {
        pattern: '/teams/edit/:id',
        getURL: ({ id = NEW_ITEM_CODE }: getURLParams) => `/settings/teams/edit/${id}`,
      },
    },
  },

  identitySpaces: {
    root: {
      pattern: '/identity-spaces',
      getURL: () => '/settings/identity-spaces',
    },

    view: {
      root: {
        pattern: '/identity-spaces/view/:code',
        getURL: ({ code }: CodeParams) => `/settings/identity-spaces/view/${code}`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ code }: CodeParams) => `/settings/identity-spaces/view/${code}/overview`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ code }: CodeParams) => `/settings/identity-spaces/view/${code}/versions`,
        },
      },
    },

    upsert: {
      root: {
        pattern: '/identity-spaces/edit/:code',
        getURL: ({ code }: CodeParams) => `/settings/identity-spaces/edit/${code}`,
      },
    },
  },

  profile: {
    root: {
      pattern: '/profile',
      getURL: () => '/settings/profile',
    },

    upsert: {
      root: {
        pattern: '/profile/edit',
        getURL: () => '/settings/profile/edit',
      },
    },

    changePassword: {
      root: {
        pattern: '/profile/change-password',
        getURL: () => '/settings/profile/change-password',
      },
    },
  },

  controllers: {
    root: {
      pattern: '/controllers',
      getURL: () => '/settings/controllers',
    },

    upsert: {
      root: {
        pattern: '/controllers/edit/:code',
        getURL: ({ code }: CodeParams) => `/settings/controllers/edit/${code}`,
      },
    },
  },

  dataSubjectTypes: {
    root: {
      pattern: '/data-subject-types',
      getURL: () => '/settings/data-subject-types',
    },

    upsert: {
      root: {
        pattern: '/data-subject-types/edit/:id',
        getURL: ({ id }: getURLParams) => `/settings/data-subject-types/edit/${id}`,
      },
    },
  },
  customRightName: {
    root: {
      pattern: '/custom-right-name',
      getURL: () => '/settings/custom-right-name',
    },
    upsert: {
      root: {
        pattern: '/custom-right-name/edit/:id',
        getURL: ({ id }: getURLParams) => `/settings/custom-right-name/edit/${id}`,
      },
    },
  },
  contextVariables: {
    root: {
      pattern: '/context-variables',
      getURL: () => '/settings/context-variables',
    },
  },

  formFields: {
    root: {
      pattern: '/form-fields',
      getURL: () => '/settings/form-fields',
    },

    view: {
      root: {
        pattern: '/form-fields/view/:id',
        getURL: ({ id }: getURLParams) => `/settings/form-fields/view/${id}`,
      },
    },

    upsert: {
      root: {
        pattern: '/form-fields/edit/:id',
        getURL: ({ id }: getURLParams) => `/settings/form-fields/edit/${id}`,
      },
    },
  },
}
