import React from 'react'
import Box from '@mui/material/Box'

import { Button, ContentGroup, EmptyState, Spinner } from '@ketch-com/deck'
import { NotificationCard } from './components'
import { splitNotificationsIntoReadAndUnread } from './utils'
import { useMarkAllNotificationsRead } from 'api/notifications/mutations/useMarkAllNotificationsRead'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { Waypoint } from 'react-waypoint'
import { useInfiniteNotifications } from 'api/notifications/queries/useInfiniteNotifications'
import { Typography } from '@mui/material'
import { InfiniteScrollLoadingIndicator } from 'components/InfiniteScrollLoadingIndicator'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {}

export const NotificationsList: React.FC<Props> = () => {
  const {
    data: notifications,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteNotifications({
    hasNoTaskId: true,
  })
  const { readNotifications, unreadNotifications } = splitNotificationsIntoReadAndUnread(notifications || [])
  const queryClient = useQueryClient()

  const breadcrumbs = [
    {
      title: 'My Updates',
      link: RoutesManager.updates.root.getURL(),
    },
    { title: 'Notifications' },
  ]

  const { mutate: markAllNotificationsRead } = useMarkAllNotificationsRead({
    onSuccess: () => {
      queryClient.refetchQueries([ApiQueryKeys.notificationsInfinite])
      queryClient.refetchQueries([ApiQueryKeys.notificationsUnreadCount])
    },
  })

  const shouldShowEmptyState = unreadNotifications.length === 0 && readNotifications.length === 0 && !isLoading

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box display="flex" flexDirection="column" gap={3}>
        {isLoading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Spinner size="32px" thickness={2.5} />
          </Box>
        ) : (
          <>
            <ContentGroup
              isDivider
              title="Notifications"
              actionRightBlockComponent={
                <Button
                  color="tertiary"
                  onClick={() =>
                    markAllNotificationsRead({
                      params: {
                        hasNoTaskId: true,
                      },
                    })
                  }
                >
                  Mark All Read
                </Button>
              }
            />

            <Box>
              {isLoading && (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Spinner size="32px" thickness={2.5} />
                </Box>
              )}

              {shouldShowEmptyState && (
                <Box
                  mt={2.5}
                  bgcolor="bone.main"
                  borderRadius="11px"
                  py={4}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <EmptyState
                    iconName="OChatAlert"
                    title="All Caught Up!"
                    subTitle="There are no active notifications that require your attention"
                  />
                </Box>
              )}
              <Box display="flex" flexDirection="column" ml={2}>
                {unreadNotifications.length > 0 && (
                  <Typography variant="label" color="darkDuskFaded.main">
                    NEW
                  </Typography>
                )}

                {unreadNotifications?.map((notification, index) => (
                  <NotificationCard
                    isNew={true}
                    isFirst={index === 0}
                    isLast={index === unreadNotifications.length - 1}
                    notification={notification}
                    key={notification.id}
                  />
                ))}

                {readNotifications.length > 0 && (
                  <Typography variant="label" color="darkDuskFaded.main">
                    RECENT
                  </Typography>
                )}

                {readNotifications?.map((notification, index) => (
                  <NotificationCard
                    isNew={false}
                    isFirst={index === 0}
                    isLast={index === readNotifications.length - 1}
                    notification={notification}
                    key={notification.id}
                  />
                ))}

                <Waypoint
                  onEnter={() => {
                    if (hasNextPage) fetchNextPage()
                  }}
                />

                <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  )
}
