import {
  SaveOrganizationDataSystemGlobalCustomAttributesResponseBodyDTO,
  UpdateDataSystemGlobalCustomAttributeConfigurationRequestBodyDTO,
} from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export interface UpdateDataSystemGlobalCustomAttributeParams {
  id: string
  reqBody: UpdateDataSystemGlobalCustomAttributeConfigurationRequestBodyDTO
}

export const updateDataSystemGlobalCustomAttribute = ({ id, reqBody }: UpdateDataSystemGlobalCustomAttributeParams) => {
  return API.put<SaveOrganizationDataSystemGlobalCustomAttributesResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/data-systems/global-custom-attributes/${id}`,
    }),
    reqBody,
  )
}
