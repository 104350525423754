import React from 'react'

import { getElementPairs } from '../utils'

type UseRelationshipsTableUtilsArgs = {
  isLoadingReferences?: boolean
  hasNextPage?: boolean
  fetchNextPage: () => void
}

export const useRelationshipsTableUtils = ({ hasNextPage, fetchNextPage }: UseRelationshipsTableUtilsArgs) => {
  const handlePointerOnEnterEvent = (e: React.PointerEvent<HTMLDivElement>) => {
    const [element, sisterElement] = getElementPairs(e)
    element.setAttribute('should-highlight', 'true')
    sisterElement.setAttribute('should-highlight', 'true')
  }

  const handlePointerOnLeave = (e: React.PointerEvent<HTMLDivElement>) => {
    const [element, sisterElement] = getElementPairs(e)
    element.removeAttribute('should-highlight')
    sisterElement.removeAttribute('should-highlight')
  }

  const handleReferencesListContainerOnScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    const scrollHeight = e.currentTarget.scrollHeight
    const scrollTop = e.currentTarget.scrollTop
    const scrollDiff = scrollHeight - scrollTop
    const clientHeight = e.currentTarget.clientHeight
    const shouldFetchNextPage = scrollDiff - 10 <= clientHeight

    if (shouldFetchNextPage && hasNextPage) {
      fetchNextPage()
    }
  }

  return {
    handlePointerOnEnterEvent,
    handlePointerOnLeave,
    handleReferencesListContainerOnScroll,
  }
}
