import { GetWorkflowExecutionPreviewResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  workflowExecutionId?: string
}

export const fetchWorkflowPreview = ({ workflowExecutionId }: Params) =>
  API.get<GetWorkflowExecutionPreviewResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/executions/${workflowExecutionId}/preview`,
    }),
  )
