import React from 'react'
import { Box, Typography } from '@mui/material'

import { ReactComponent as CreateRequestIcon } from 'assets/icons/create_request.svg'
import { ReactComponent as TrackProcessingIcon } from 'assets/icons/track_processing.svg'
import { ReactComponent as ConductAssessmentsIcon } from 'assets/icons/conduct_assessments.svg'
import { ReactComponent as CollaborateApproveIcon } from 'assets/icons/collaborate_approve.svg'
import { Button, PopUp } from '@ketch-com/deck'

type Props = {
  onCancel: () => void
}

export const HowProcessingActivitiesWorksModal: React.FC<Props> = ({ onCancel }) => {
  return (
    <PopUp
      variant="modal"
      popUpWidth="750px"
      title="How Does It Work"
      popUpActionChildren={
        <Button color="secondary" size="large" onClick={onCancel}>
          Close
        </Button>
      }
      onClose={onCancel}
    >
      <Box>
        <Box display="flex" gap={3} alignItems="center" justifyContent="center" my={5}>
          <CreateRequestIcon />
          <Box display="flex" flexDirection="column" gap={0.5} width="480px">
            <Typography variant="label">Create a Request</Typography>
            <Typography variant="body">
              Begin by creating a processing activity request. Within this request, clearly outline which teams are
              involved, the purpose for, and the data systems involved in the processing.
            </Typography>
          </Box>
        </Box>

        <Box display="flex" gap={3} alignItems="center" justifyContent="center" my={5}>
          <TrackProcessingIcon />
          <Box display="flex" flexDirection="column" gap={0.5} width="480px">
            <Typography variant="label">Track Standard or AI Processing</Typography>
            <Typography variant="body">
              You can choose between two types of processing: standard and AI/model. The latter provides the option to
              include additional context, such as a model bias report.
            </Typography>
          </Box>
        </Box>

        <Box display="flex" gap={3} alignItems="center" justifyContent="center" my={5}>
          <ConductAssessmentsIcon />
          <Box display="flex" flexDirection="column" gap={0.5} width="480px">
            <Typography variant="label">Conduct Assessments</Typography>
            <Typography variant="body">
              If the processing activity necessitates a privacy assessment, you can either link to Ketch assessments, or
              you can upload a file generated from a third-party tool.
            </Typography>
          </Box>
        </Box>

        <Box display="flex" gap={3} alignItems="center" justifyContent="center" my={5}>
          <CollaborateApproveIcon />
          <Box display="flex" flexDirection="column" gap={0.5} width="480px">
            <Typography variant="label">Collaborate & Approve!</Typography>
            <Typography variant="body">
              Ask questions, highlight risk mitigants, and resolve issues with your colleagues. Once you are satisfied,
              approve the activity! While you will retain the ability to make edits after approval, modifications will
              necessitate another version of the activity and subsequent approval.
            </Typography>
          </Box>
        </Box>
      </Box>
    </PopUp>
  )
}
