import { useInfiniteQuery } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchNewPropertyTrackerProperties } from '../fetchers/fetchNewPropertyTrackerProperties'
import { GetNewPropertyTrackerPropertiesParams } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type Params = GetNewPropertyTrackerPropertiesParams & { enabled?: boolean; encodedTrackerKey: string; limit?: number }

export const useNewPropertyTrackerPropertiesInfinite = (params: Params) => {
  const { enabled = true, limit = 20 } = params
  const resp = useInfiniteQuery({
    enabled,
    queryKey: [ApiQueryKeys.newPropertyTrackerPropertiesInfinite, { ...params }],
    queryFn: async ({ pageParam = {} }) => {
      const categories = await fetchNewPropertyTrackerProperties({
        limit,
        nextToken: pageParam.nextToken,
        ...params,
      })
      return categories
    },
    getNextPageParam: lastPage => {
      const nextToken = lastPage?.data?.nextToken
      return nextToken ? { nextToken } : null
    },
  })

  return {
    ...resp,
    trackerProperties: resp?.data?.pages?.map(page => page?.data?.properties || []).flat() || [],
  }
}
