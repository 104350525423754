import { FC, useContext } from 'react'
import { Box, Typography, hexToRgb } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { Button, AvatarPalette, AvatarSize, InfoRow } from '@ketch-com/deck'
import { OrganizationDTO } from 'interfaces/organizations/organization'
import { MaybeNull } from 'interfaces'
import { settings } from '../../../../../utils/routing/routes/settings'
import { useNavigate } from 'react-router-dom'
import { EmptyValueRenderer } from '../../../../../components/EmptyValueRenderer'
import { UniqueUsers } from 'components/planAndBilling/components/uniqueUsers/UniqueUsers'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import { useIsEntitled } from 'utils/hooks'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      container: {
        background: palette.white.main,
        borderRadius: 11,
        width: '100%',
      },
      orgCardHeaderRow: {
        borderBottom: `1px solid ${palette.iron.main}`,
        paddingBottom: spacing(2),
        paddingLeft: spacing(2),
      },
      img: {
        maxWidth: '100px',
      },
    }),
  { name: 'Organization' },
)

type Props = {
  organization: MaybeNull<OrganizationDTO>
}

const ColorInfo = ({ color, title }: { color?: string; title?: string }) => {
  return (
    <Box display="flex" gap={1} alignItems="flex-start">
      {color && <AvatarPalette size={AvatarSize.small} backgroundColor={color} />}
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="label">{title}</Typography>
        <Typography variant="body">{color ? hexToRgb(color) : '-'}</Typography>
        <Typography variant="body">{color ? color : '-'}</Typography>
      </Box>
    </Box>
  )
}

export const OrganizationDetailsCard: FC<Props> = ({ organization }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()
  const { isOrgOnFreePlan, isOrgOnStarterPlan } = useContext(PlanAndBillingContext)

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h2">Organization</Typography>
        <Button size="medium" color="secondary" onClick={() => navigate(settings.organization.edit.root.getURL())}>
          Edit
        </Button>
      </Box>

      <Box display="flex" flexDirection="column" gap={4}>
        <InfoRow title="Name">
          <Typography variant="body">{organization?.name || <EmptyValueRenderer />}</Typography>
        </InfoRow>
        <InfoRow title="Code">
          <Typography variant="body">{organization?.code || <EmptyValueRenderer />}</Typography>
        </InfoRow>
        <InfoRow title="Logo">
          {organization?.logoURL !== '' ? (
            <img className={classes.img} alt="complex" src={organization?.logoURL || ''} />
          ) : (
            <Typography variant="body" color="fadedDarkGrey.main">
              No Logo Uploaded
            </Typography>
          )}
        </InfoRow>
        <InfoRow title="Colors">
          <Box display="flex" gap="70px" alignItems="flex-start">
            <ColorInfo color={organization?.primaryBrandColor} title="Primary" />
            <ColorInfo color={organization?.secondaryBrandColor} title="Secondary" />
          </Box>
        </InfoRow>
        {!isOrgOnFreePlan && !isOrgOnStarterPlan && isEntitled([ENTITLEMENTS.CONSENT_MANAGEMENT]) ? (
          <UniqueUsers />
        ) : null}
      </Box>
    </Box>
  )
}
