import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles, createStyles } from '@mui/styles'
import { useFormikContext } from 'formik'

import { ThemeFormValues } from 'pages/consentAndRights/themes/upsert/utils'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { Text } from 'components/ui-kit/typography/Text'
import { FormColorInput } from 'components/ui-kit/form/colorInput/FormColorInput'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { Flex } from 'components/ui-layouts/flex/Flex'
import Grid from '@mui/material/Grid'
import { Label } from 'components/ui-kit/form/common/label/Label'
import { Group } from 'components/ui-layouts/group/Group'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { FormCheckbox } from 'components/ui-kit/form/checkbox/FormCheckbox'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { ExperiencePositionRadio } from '../../ExperiencePositionRadio'
import { ThemeModalPosition } from 'interfaces/themes-v2/themeModalPosition'
import { ModalPositionVisualization } from '../../ModalPositionVisualization'
import { Input } from 'components/ui-kit/input/Input'
import { fullConfigBeta } from '@ketch-com/lanyard'
import { DefaultLabel } from './DefaultLabel'
import { Switch } from 'components/ui-kit/switch/Switch'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      root: {
        position: 'absolute',
        top: 13,
        right: 24,
        bottom: 13,
        left: 24,
      },
      header: {
        display: 'block',
        marginBottom: 18,
        padding: '13px 0 26px 0',
        borderBottom: `1px solid ${palette.iron.main}`,
      },
      subHeader: {
        display: 'block',
        color: palette.darkDuskFaded.main,
      },
      content: {
        flex: 1,
        overflowY: 'auto',
      },
    }),
  { name: 'ModalView' },
)

type Props = {}

export const ModalView: React.FC<Props> = () => {
  const classes = useStyles()
  const { isEntitled } = useIsEntitled()
  const isEntitledToUnbranded = isEntitled(ENTITLEMENTS.EXP_SERVER_UNBRANDED)
  const { values, setFieldValue } = useFormikContext<ThemeFormValues>()

  return (
    <Flex flexDirection="column" className={classes.root}>
      <Text className={classes.header} size={20} weight={700} lineHeight={1.4}>
        Modal Customization
      </Text>

      <div className={classes.content}>
        <Text className={classes.subHeader} size={16} weight={800} lineHeight={1.12} mb={2}>
          General
        </Text>

        <FormRow marginBottom={24}>
          <Label required>Layout</Label>

          {/* Modal Position Input */}

          <Group gap={16}>
            {[ThemeModalPosition.CENTER, ThemeModalPosition.LEFT_FULL_HEIGHT, ThemeModalPosition.RIGHT_FULL_HEIGHT].map(
              position => (
                <ExperiencePositionRadio key={position} name="modalPosition" value={position}>
                  <ModalPositionVisualization position={position} />
                </ExperiencePositionRadio>
              ),
            )}
          </Group>
        </FormRow>

        {/* Typeface and Button Radius Inputs */}

        <FormRow marginBottom={24}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormDropdown fullWidth label="Typeface" disabled name="font" items={[]} placeholder="Arial" />
            </Grid>
            <Grid item xs={6}>
              <FormInput required label="Buttons Corner Radius" name="buttonBorderRadius" />
            </Grid>
          </Grid>
        </FormRow>

        {/* Show Watermark Input */}

        <FormRow marginBottom={48}>
          <FormCheckbox
            disabled={!isEntitledToUnbranded}
            name="showWatermark"
            title={'Show "Powered by Ketch" Watermark'}
          />
        </FormRow>

        {/* Header and Footer Section */}

        <Text className={classes.subHeader} size={16} weight={800} lineHeight={1.12} mb={3}>
          Header & Footer
        </Text>

        {/* Background Color */}

        <FormRow marginBottom={24}>
          <FormColorInput required fullWidth label="Background Color" name="modalHeaderBackgroundColor" />
        </FormRow>

        {/* Content Color */}

        <FormRow marginBottom={24}>
          <Box display="flex" flexDirection="column">
            {!!values?.customizeModalHeaderContentColor ? (
              <>
                <DefaultLabel title="Content Color" />
                <Input value="Default" disabled sx={{ mb: 1 }} />
              </>
            ) : (
              <FormColorInput fullWidth label="Content Color" name="modalHeaderContentColor" />
            )}

            <FormCheckbox
              name="customizeModalHeaderContentColor"
              title="Use Default Color"
              onChange={(e, val) => {
                setFieldValue('customizeModalHeaderContentColor', val)
                if (val) {
                  setFieldValue('modalHeaderContentColor', '')
                } else {
                  setFieldValue('modalHeaderContentColor', fullConfigBeta?.theme?.modalHeaderContentColor || '#071A24')
                }
              }}
            />
          </Box>
        </FormRow>

        {/* Button Color */}

        <FormRow marginBottom={48}>
          <FormColorInput required fullWidth label="Footer Button Color" name="modalButtonColor" />
        </FormRow>

        {/* Body Section */}

        <Text className={classes.subHeader} mb={3} size={16} weight={800} lineHeight={1.12}>
          Body
        </Text>

        <FormRow marginBottom={24}>
          <Typography variant="label" color="darkDusk.main">
            Accept/Reject All Buttons
          </Typography>
          <FormGroup sx={{ mt: 1 }}>
            <FormControlLabel
              sx={{ ml: 0 }}
              control={
                <Switch
                  value={values?.purposeButtonsLookIdentical || false}
                  onChange={(_, checked) => setFieldValue('purposeButtonsLookIdentical', checked)}
                  sx={{ mr: 1 }}
                />
              }
              label="Look Identical"
            />
          </FormGroup>
        </FormRow>

        {/* Body Color */}

        <FormRow marginBottom={24}>
          <FormColorInput required fullWidth label="Background Color" name="modalBodyBackgroundColor" />
        </FormRow>

        {/* Content Color */}

        <FormRow marginBottom={24}>
          <FormColorInput required fullWidth label="Content Color" name="modalContentColor" />
        </FormRow>

        {/* Switch Positive Color */}

        <FormRow marginBottom={24}>
          <Box display="flex" flexDirection="column">
            {!!values?.customizeModalSwitchOnColor ? (
              <>
                <DefaultLabel title="Switch Positive" />
                <Input value="Default" disabled sx={{ mb: 1 }} />
              </>
            ) : (
              <FormColorInput fullWidth label="Switch Positive" name="modalSwitchOnColor" />
            )}

            <FormCheckbox
              name="customizeModalSwitchOnColor"
              title="Use Default Color"
              onChange={(e, val) => {
                setFieldValue('customizeModalSwitchOnColor', val)
                if (val) {
                  setFieldValue('modalSwitchOnColor', '')
                } else {
                  setFieldValue('modalSwitchOnColor', '#071A24')
                }
              }}
            />
          </Box>
        </FormRow>

        {/* Switch Negative Color */}

        <FormRow marginBottom={24}>
          <Box display="flex" flexDirection="column">
            {!!values?.customizeModalSwitchOffColor ? (
              <>
                <DefaultLabel title="Switch Negative" />
                <Input value="Default" disabled sx={{ mb: 1 }} />
              </>
            ) : (
              <FormColorInput fullWidth label="Switch Negative" name="modalSwitchOffColor" />
            )}

            <FormCheckbox
              name="customizeModalSwitchOffColor"
              title="Use Default Color"
              onChange={(e, val) => {
                setFieldValue('customizeModalSwitchOffColor', val)
                if (val) {
                  setFieldValue('modalSwitchOffColor', '')
                } else {
                  setFieldValue('modalSwitchOffColor', '#071A24')
                }
              }}
            />
          </Box>
        </FormRow>
      </div>
    </Flex>
  )
}
