import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Button, Chip, Radio, TableCell } from '@ketch-com/deck'
import { PurposeTemplateDTO } from 'interfaces/purposes/purposeTemplate'
import { useFormikContext } from 'formik'
import { MaybeNull } from 'interfaces'
import { AssessmentDTO, ProcessingActivityDTO } from '@ketch-com/figurehead'

export const PurposeLibraryViewCodeTableCell = ({ row }: { row: PurposeTemplateDTO }) => {
  const { values, setFieldValue } = useFormikContext<{ templateCode: null; collectionCode: MaybeNull<string> }>()
  const isChecked = values.templateCode === row.code

  return (
    <TableCell>
      <Radio
        checked={isChecked}
        value={isChecked}
        onChange={() => {
          if (isChecked) {
            setFieldValue(`templateCode`, null)
            setFieldValue(`collectionCode`, null)
          } else {
            setFieldValue(`templateCode`, row.code)
            setFieldValue(`collectionCode`, values.collectionCode)
          }
        }}
      />
    </TableCell>
  )
}

export const useAssessmentsModalColumns = ({
  processingActivity,
  isAssessmentUpdating,
  handleSelectClick,
}: {
  processingActivity: ProcessingActivityDTO
  isAssessmentUpdating: boolean
  handleSelectClick: (activityId: string, assessmentId: string) => Promise<void>
}): GridColDef<AssessmentDTO>[] => [
  {
    align: 'left',
    field: 'name',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Assessment Name',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<AssessmentDTO>) => {
      return <TableCell title={row.attributes?.name} />
    },
  },
  {
    align: 'left',
    field: 'questions',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Questions',
    sortable: false,
    renderCell: ({ row: assessment }: GridRenderCellParams<AssessmentDTO>) => {
      return (
        <TableCell>
          <Chip size="small" label={assessment.attributes?.totalQuestions || 'None'} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'template',
    headerAlign: 'left',
    flex: 1,
    headerName: 'Template',
    sortable: false,
    renderCell: ({ row: assessment }: GridRenderCellParams<AssessmentDTO>) => {
      return (
        <TableCell>
          <Chip label={assessment.relationships?.template?.data?.attributes?.name || 'None'} size="small" />
        </TableCell>
      )
    },
  },

  {
    align: 'left',
    field: 'more',
    headerAlign: 'left',
    flex: 1,
    maxWidth: 100,
    headerName: '',
    sortable: false,
    renderCell: ({ row: assessment }: GridRenderCellParams<AssessmentDTO>) => {
      return (
        <TableCell>
          <Button
            pending={isAssessmentUpdating}
            onClick={() => handleSelectClick(processingActivity.id || '', assessment.id || '')}
          >
            Select
          </Button>
        </TableCell>
      )
    },
  },
]
