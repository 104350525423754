import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInfiniteQuery } from 'react-query'
import { InstalledDataSystemV2DTO } from '@ketch-com/figurehead'
import { fetchInstalledDataSystems } from '../fetchers/fetchInstalledDataSystems'

const limit = 50

export const useInstalledDataSystemsInfinite = ({
  q,
  capabilities,
  connectionStatuses,
}: {
  q?: string
  capabilities?: string[]
  connectionStatuses?: string[]
}) => {
  const resp = useInfiniteQuery({
    queryKey: [ApiQueryKeys.dataSystems, { q, capabilities }],
    queryFn: async ({ pageParam = 1 }) => {
      const fetchInstalledDataSystemsResult = await fetchInstalledDataSystems({
        limit,
        start: (pageParam - 1) * limit,
        q,
        capabilities,
        connectionStatuses,
      })

      return {
        dataSystems: fetchInstalledDataSystemsResult.data.installedDataSystems ?? [],
        totalResults: fetchInstalledDataSystemsResult.data.totalResults ?? 0,
      }
    },
    getNextPageParam: ({ totalResults }, allPages) => {
      let totalItemsFetched = 0
      allPages?.forEach?.(pageOfResults => {
        totalItemsFetched += pageOfResults?.dataSystems?.length || 0
      })
      const nextPageParam = Math.ceil(totalItemsFetched / limit) + 1

      return totalItemsFetched === totalResults ? undefined : nextPageParam
    },
  })

  let dataSystemsInfinite: InstalledDataSystemV2DTO[] = []

  let totalResults = 0

  resp.data?.pages?.forEach?.(page => {
    const newInstalledDataSystems = page?.dataSystems
    dataSystemsInfinite = [...dataSystemsInfinite, ...newInstalledDataSystems]
    totalResults = page?.totalResults || 0
  })

  return {
    ...resp,
    data: dataSystemsInfinite,
    totalResults,
  }
}
