import React, { useContext } from 'react'
import { serialize } from '../../upsert/utils/common'
import { ConsentExperienceTab } from '../../upsert/utils/consent/constants'
import { ExperiencePreviewContext } from '../context/ExperiencePreviewContext'
import { BannerScreenPreview } from './bannerPreview/BannerScreenPreview'
import { ModalScreenPreview } from './modalPreview/ModalScreenPreview'
import { ConsentExperienceFormValues } from '../../upsert/utils/consent/interfaces'

type Props = {}

export const ConsentExperiencePreview: React.FC<Props> = () => {
  const { activeTab, activeTheme, mode, experienceValues } = useContext(ExperiencePreviewContext)

  const { banner, modal } = experienceValues as ConsentExperienceFormValues

  return (
    <>
      {activeTab === ConsentExperienceTab.BANNER && (
        <BannerScreenPreview
          mode={mode}
          theme={activeTheme!}
          title={banner.title}
          footerDescription={serialize(banner.footerDescription)}
          buttonText={banner.buttonText}
          secondaryButtonText={banner.secondaryButtonText}
        />
      )}

      {activeTab === ConsentExperienceTab.MODAL && (
        <ModalScreenPreview
          mode={mode}
          theme={activeTheme!}
          title={modal.title}
          bodyTitle={modal.bodyTitle}
          bodyDescription={serialize(modal.bodyDescription)}
          buttonText={modal.buttonText}
        />
      )}
    </>
  )
}
