import React from 'react'
import { Box, Typography } from '@mui/material'
import { AssessmentDTO, AssessmentStatusDTO } from '@ketch-com/figurehead'
import { AssessmentStatusIcon } from './AssessmentStatusIcon'

export const AssessmentProgressDetails: React.FC<{ assessment?: AssessmentDTO; shorten?: boolean }> = ({
  assessment,
  shorten = false,
}) => {
  const { completedQuestions, totalQuestions, status } = assessment?.attributes || {}

  const percentComplete = Math.round(((completedQuestions || 0) / (totalQuestions || 1)) * 100)

  const statusColor =
    status === AssessmentStatusDTO.InReviewAssessmentStatus
      ? 'sphere.main'
      : status === AssessmentStatusDTO.ExpiredAssessmentStatus
      ? 'heliotrope.main'
      : 'darkDusk.main'

  const statusText =
    status === AssessmentStatusDTO.InReviewAssessmentStatus
      ? 'In Review'
      : status === AssessmentStatusDTO.ApprovedAssessmentStatus
      ? 'Approved'
      : status === AssessmentStatusDTO.ExpiredAssessmentStatus
      ? 'Expired'
      : `${percentComplete || 0}% (${completedQuestions || 0}/${totalQuestions || 0}${
          shorten ? '' : ' Questions Complete'
        })`

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <AssessmentStatusIcon assessment={assessment} />
      <Typography variant="label" color={statusColor}>
        {statusText}
      </Typography>
    </Box>
  )
}
