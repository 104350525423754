import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChartTrendPeriod } from 'interfaces/rights/rightStats'

interface PermitStatisticsState {
  jurisdiction: string
  identitySpace: string
  purpose: string
  property: string
  environment: string
  startDate: string
  endDate: string
  trendPeriod: ChartTrendPeriod
}

const initialState = {
  jurisdiction: '',
  identitySpace: '',
  purpose: '',
  property: '',
  environment: '',
  startDate: '',
  endDate: '',
  trendPeriod: ChartTrendPeriod.DAILY,
} as PermitStatisticsState

export const permitStatisticsSlice = createSlice({
  name: 'PermitStatisticsSlice',
  initialState,
  reducers: {
    updateJurisdictionFilter: (state, action: PayloadAction<string>) => {
      state.jurisdiction = action.payload
    },

    updateIdentitySpaceFilter: (state, action: PayloadAction<string>) => {
      state.identitySpace = action.payload
    },

    updatePurposeFilter: (state, action: PayloadAction<string>) => {
      state.purpose = action.payload
    },

    updatePropertyFilter: (state, action: PayloadAction<string>) => {
      state.property = action.payload
    },

    updateEnvironmentFilter: (state, action: PayloadAction<string>) => {
      state.environment = action.payload
    },

    updateDateRange: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
      state.startDate = action.payload.startDate
      state.endDate = action.payload.endDate
    },

    updateTrendPeriod: (state, action: PayloadAction<ChartTrendPeriod>) => {
      state.trendPeriod = action.payload
    },

    clearDropdownFilters: state => {
      state.jurisdiction = ''
      state.purpose = ''
      state.identitySpace = ''
      state.property = ''
      state.environment = ''
    },
  },
})

export const {
  updateJurisdictionFilter,
  updateIdentitySpaceFilter,
  updatePurposeFilter,
  updatePropertyFilter,
  updateEnvironmentFilter,
  updateDateRange,
  updateTrendPeriod,
  clearDropdownFilters,
} = permitStatisticsSlice.actions

export default permitStatisticsSlice.reducer
