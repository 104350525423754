import { useContext, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Button } from '@ketch-com/deck'

import { FormFieldCategoryDTO, WebhooksContextTimelineDTO } from '@ketch-com/figurehead'
import { useState } from 'react'
import { useFormFields } from 'api/formFields/queries/useFormFields'
import { ApiCallContext } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/context/ApiCallContext'
import { FormDataInputValue } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'
import { FormDataChip } from '../../../../../../FormDataChip'
import { FormFieldInlineForm } from './FormFieldInlineForm'
import { getHumanReadableTimelineItemFieldType } from '../../../utils'

type Props = {
  timelineItem: WebhooksContextTimelineDTO
}

export const InvalidFormData: React.FC<Props> = ({ timelineItem }) => {
  const { submitTimelineChange } = useContext(ApiCallContext)

  const { formData = {} } = timelineItem
  const { isActionEnabled } = formData
  const formDataCode = formData?.data?.[0]?.code
  const formDataValueOriginal = formData?.data?.[0]?.value

  const { data: formFields } = useFormFields({
    itemsPerPage: 1000,
    params: {
      ordering: { title: 'asc' },
      includeMetadata: true,
      category: FormFieldCategoryDTO.CustomFormFieldCategory,
    },
  })

  const [showForm, setShowForm] = useState(false)
  const [formValue, setFormValue] = useState<FormDataInputValue>()
  const formField = formFields?.find(f => f.code === formDataCode)

  const submit = async () => {
    const isFormValueAnArray = Array.isArray(formValue)

    const payload = {
      timelineItem,
      timelineItemToReplace: {
        ...timelineItem,
        formData: {
          data: [
            {
              code: formDataCode,
              value: String(formDataValueOriginal),
              name: formData?.data?.[0]?.name,
            },
          ],
          isActionEnabled: false,
        },
      },
      newTimelineItem: {
        ...timelineItem,
        formData: {
          data: [
            {
              code: formDataCode,
              ...(!isFormValueAnArray ? { value: String(formValue) } : { values: formValue }),
              name: formField?.name || formField?.title,
            },
          ],
          isActionEnabled: false,
        },
      },
    }

    await submitTimelineChange(payload)
  }

  useEffect(() => {
    if (!isActionEnabled) {
      setShowForm(false)
    }
  }, [isActionEnabled])

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" mb={0.75}>
        <Box>
          <Typography>Form Field received:&nbsp;</Typography>
        </Box>
        <FormDataChip formDataItem={formData?.data?.[0]} />
      </Box>

      <Box mb={0.75}>
        <Typography>
          Value Received:&nbsp;
          <Typography color="chileanFire.main">
            "{formData?.data?.[0]?.value || formData?.data?.[0]?.values?.join(', ')}"
          </Typography>
        </Typography>
      </Box>
      <Box mb={1.5}>
        <Box>
          <Typography variant="label">
            Issue:<Typography>&nbsp;received value doesn’t match Form Field type.</Typography>
          </Typography>
        </Box>
        <Box>
          <Typography>
            <Typography variant="label">Acceptable Type:</Typography>&nbsp;
            {getHumanReadableTimelineItemFieldType(formField)}
          </Typography>
        </Box>
      </Box>

      {/* Action Enabled View */}

      {isActionEnabled && !showForm && (
        <Box>
          <Button color="tertiary" onClick={() => setShowForm(true)}>
            Change Value
          </Button>
        </Box>
      )}

      {/* Form */}

      {showForm && (
        <Box width={500}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12} display="flex" justifyContent="flex-start">
              <FormFieldInlineForm
                formField={formField}
                onCancel={() => {
                  setShowForm(false)
                  setFormValue(undefined)
                }}
                formValue={formValue}
                setFormValue={setFormValue}
                onSubmit={submit}
                isUnknown={false}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  )
}
