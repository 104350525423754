export const formatLastUsedDate = (lastUsedDate: Date): string => {
  const now = new Date()

  const timeDifference = now.getTime() - lastUsedDate.getTime()
  // 1000 ms -> seconds, 3600 seconds -> hours, 24 hours -> day
  const dayDifference = timeDifference / (1000 * 3600 * 24)
  const yearDifference = Math.floor(dayDifference / 365)

  if (yearDifference > 0) {
    return `${yearDifference} year${yearDifference > 1 ? 's' : ''} ago`
  }

  // Using a simple 30 days/month should be close enough for this view.
  const monthDifference = Math.floor(dayDifference / 30)
  if (monthDifference > 0) {
    return `${monthDifference} month${monthDifference > 1 ? 's' : ''} ago`
  }

  return `${dayDifference} day${dayDifference > 1 ? 's' : ''} ago`
}
