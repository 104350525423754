import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { duotoneLight } from 'react-syntax-highlighter/dist/cjs/styles/prism'

import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { HookExecutedDmlModalInfoRow } from './HookExecutedDmlModalInfoRow'
import { ExecutionResultHookDTO } from '@ketch-com/figurehead'

type Props = {
  hook?: ExecutionResultHookDTO
  onCancel?: () => void
}

export const HookExecutedDmlModal: React.FC<Props> = ({ onCancel, hook }) => {
  return (
    <Dialog
      title={hook?.hook?.name}
      contentWidth={968}
      submitBtnLabel="Delete"
      cancelBtnLabel="Close"
      onCancelBtnClick={onCancel}
      withTitleBorderBottom={true}
    >
      <HookExecutedDmlModalInfoRow
        title="Populated Arguments"
        hasBorderBottom
        info={
          <SyntaxHighlighter language="sql" style={duotoneLight} wrapLongLines={true}>
            {hook?.executedDml || ''}
          </SyntaxHighlighter>
        }
      />
    </Dialog>
  )
}
