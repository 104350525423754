import { useContext } from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

import { LanyardModalExperienceProps } from '@ketch-com/lanyard'
import { ThemeModalPosition } from 'interfaces/themes-v2/themeModalPosition'
import { PreviewDeviceMode } from 'interfaces/preview/previewDeviceMode'
import { ModalPreview } from 'pages/consentAndRights/experiences/preview/components/modalPreview/ModalPreview'
import { ExperiencePreviewScreen } from 'pages/consentAndRights/experiences/preview/components/ExperiencePreviewScreen'

import { ExperiencePreviewContext } from '../../context/ExperiencePreviewContext'
import { ensureHydratedThemeColors } from 'utils/helpers/ensureHydratedThemeColors'

const useStyles = makeStyles(
  createStyles({
    mobileScreenRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    screen: {
      display: 'flex',
    },
    modal: {
      height: '100%',
    },
    mobile: {
      width: '100%',
    },
    center: {
      width: 900,
      height: '95%',
      alignSelf: 'center',
      margin: '0 auto',
    },
    leftFullHeight: {
      width: 480,
      height: '100%',
    },
    rightFullHeight: {
      width: 480,
      height: '100%',
      marginLeft: 'auto',
    },
  }),
  { name: 'ModalScreenPreview' },
)

type Props = {
  mode?: PreviewDeviceMode
} & Omit<LanyardModalExperienceProps, 'wrapperClassName' | 'className'>

export const ModalScreenPreview = ({ mode = PreviewDeviceMode.DESKTOP, theme, ...rest }: Props) => {
  const { jurisdictionPurposes: purposes } = useContext(ExperiencePreviewContext)
  const classes = useStyles()
  const { modalPosition = ThemeModalPosition.CENTER } = theme

  return (
    <ExperiencePreviewScreen
      mode={mode}
      className={clsx({
        [classes.mobileScreenRoot]: mode === PreviewDeviceMode.MOBILE,
      })}
      classes={{
        screen: classes.screen,
      }}
    >
      <ModalPreview
        wrapperClassName={clsx(
          mode === PreviewDeviceMode.DESKTOP
            ? {
                [classes.center]: modalPosition === ThemeModalPosition.CENTER,
                [classes.leftFullHeight]: modalPosition === ThemeModalPosition.LEFT_FULL_HEIGHT,
                [classes.rightFullHeight]: modalPosition === ThemeModalPosition.RIGHT_FULL_HEIGHT,
              }
            : classes.mobile,
        )}
        className={classes.modal}
        theme={ensureHydratedThemeColors(theme)}
        purposes={purposes}
        {...rest}
      />
    </ExperiencePreviewScreen>
  )
}
