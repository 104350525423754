import { APIListRequestParams, APIListResponse, formatOrdering, Ordering } from 'api/common/utils'
import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
  themeCode: string
  ordering?: Ordering<'version'>
}>

type Response = APIListResponse<{ themes: ThemeDTO[] }>

export const fetchThemeVersions = ({ organizationCode, themeCode, includeMetadata, start, limit, ordering }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/themes/${themeCode}/versions`,
      params: {
        filters: {
          includeMetadata,
          start,
          limit,
          ordering: formatOrdering(ordering),
        },
      },
    }),
  )
