import { Box } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'

import { getAssetCloudImgUrl } from 'pages/assetManager/utils'

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    tag: {
      backgroundColor: palette.lighterGrey.main,
      border: `1px solid ${palette.white.main}`,
    },
    image: {
      width: 16,
      height: 16,
    },
  }),
)

type Props = {
  technology?: string
  directSrc?: string
}

export const TechnologyTag = ({ technology = '', directSrc }: Props) => {
  const classes = useStyles()

  return (
    <Box
      className={classes.tag}
      borderRadius={100}
      width={24}
      height={24}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <img src={directSrc || getAssetCloudImgUrl(technology)} className={classes.image} alt="" />
    </Box>
  )
}
