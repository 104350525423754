import { hexToRgb } from '@mui/material'
import { AccessControlType } from 'components/ui-kit/form/imageUpload/hooks'
import { FormikErrors, getIn } from 'formik'
import { ThemeBuilderSection } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'
import { errorSections } from './useThemeValidationSchema'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { ItemStyle } from '@ketch-sdk/ketch-types'
import { ThemeSummary } from 'interfaces/themes-v3/themeSummary'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'

export const getRgba = (hex: string, opacity: string): string => {
  const rgb = hexToRgb(hex)
  const opacityDecimal = parseFloat(opacity) / 100
  return rgb.replace(')', `, ${opacityDecimal})`)
}

export function isValidHexColor(color: string): boolean {
  if (!color) return false

  // Regular expression to match 3 or 6 digit hexadecimal colors
  const hexColorPattern = /^#(?:[0-9a-fA-F]{3}){1,2}$/
  return hexColorPattern.test(color)
}

export const themeFileUploadContext = {
  version: `${new Date().getTime()}`,
  folder: 'test',
  bucket: '',
  acl: `${AccessControlType.public}`,
}

export const themeFileAllowedTypes = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/svg+xml': ['.svg'],
  // TODO: Support .webp, cargo backend doesn't allow currently
  // 'image/webp': ['.webp'],
}

/**
 * Extracts unique color values from a nested object.
 * It recursively searches through all properties of the object and any nested objects.
 * If a property named 'color' is found, and its value is a non-empty string,
 * it is added to the set of unique colors.
 *
 * @param {any} obj - The object to be searched for color properties.
 * @returns {string[]} An array of unique color values found in the object.
 */
export function extractUniqueColors(obj: any): string[] {
  const colors = new Set<string>()

  function extract(obj: any) {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        extract(obj[key])
      } else if (key === 'color' && obj[key] !== '') {
        colors.add(obj[key])
      }
    }
  }

  extract(obj)
  return Array.from(colors)
}

/**
 * Extracts unique color values from a nested object.
 * It recursively searches through all properties of the object and any nested objects.
 * If a property named 'color' is found, and its value is a non-empty string,
 * it is added to the set of unique colors.
 *
 * @param {any} obj - The object to be searched for color properties.
 * @returns {string[]} An array of unique color values found in the object.
 */
export async function extractUniqueColorsAsync(obj: any): Promise<string[]> {
  const colors = new Set<string>()

  function extract(obj: any) {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        extract(obj[key])
      } else if (key === 'color' && obj[key] !== '') {
        colors.add(obj[key])
      }
    }
  }

  extract(obj)
  return Array.from(colors)
}

function hexToRgb2(hex: string): { r: number; g: number; b: number } | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

function colorDistance(hex1: string, hex2: string): number {
  const rgb1 = hexToRgb2(hex1)
  const rgb2 = hexToRgb2(hex2)

  if (!rgb1 || !rgb2) {
    return NaN
  }

  const rDiff = rgb1.r - rgb2.r
  const gDiff = rgb1.g - rgb2.g
  const bDiff = rgb1.b - rgb2.b

  return Math.sqrt(rDiff * rDiff + gDiff * gDiff + bDiff * bDiff)
}

export function areColorsSimilar(hex1: string, hex2: string, threshold = 30): boolean {
  return colorDistance(hex1, hex2) < threshold
}

/**
 * Return the form section corresponding to the first key from errorSections that is defined
 * in the errors array.
 */
export const getErrorSection = (errors: any): ThemeBuilderSection => {
  const firstErrorFormKey = Object.keys(errorSections).find(errorFormKey => !!getIn(errors, errorFormKey))
  return errorSections[firstErrorFormKey || '']
}

// Swap the button text color from white to black when outline changes
export const handleButtonStyleChange = (
  themeValues: ThemeV3DTO,
  setFieldFunction: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<ThemeV3DTO>>,
  newStyle: ItemStyle,
  updateColorFieldName: string,
) => {
  // Get current color
  const currentColor = getIn(themeValues, updateColorFieldName)
  // Decide if we should swap to black
  const swapToBlack = newStyle === ItemStyle.Outlined && (currentColor === '#FFFFFF' || currentColor === '#ffffff')
  const swapToWhite = newStyle === ItemStyle.Filled && currentColor === '#000000'
  // Do swap
  if (swapToBlack) setFieldFunction(updateColorFieldName, '#000000')
  else if (swapToWhite) setFieldFunction(updateColorFieldName, '#FFFFFF')
}

export const getThemeSummary = (theme: ThemeV3DTO): ThemeSummary => ({
  colors: extractUniqueColors(theme.content),
})

// When configuring a theme we want to show all fields even if the default experience hides them
export const getDefaultExperienceConfigForThemeUpsert = (experienceConfig: ExperienceV2DTO) => {
  const defaultConfig = { ...experienceConfig }

  // Show banner close button
  if (defaultConfig.layout?.banner?.header?.closeButtonVisible === false) {
    // Explicit false compare above because we don't want to set in the undefined case
    defaultConfig.layout.banner.header.closeButtonVisible = true
  }

  // Show modal close button
  if (defaultConfig.layout?.modal?.header?.closeButtonVisible === false) {
    // Explicit false compare above because we don't want to set in the undefined case
    defaultConfig.layout.modal.header.closeButtonVisible = true
  }

  // Show quick links
  if (defaultConfig.layout?.preference?.tabs?.welcome?.quickLinks?.visible === false) {
    // Explicit false compare above because we don't want to set in the undefined case
    defaultConfig.layout.preference.tabs.welcome.quickLinks.visible = true
  }

  return defaultConfig
}
