import React, { useContext } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { FormTemplateDTO } from '@ketch-com/figurehead'
import { useFormikContext } from 'formik'
import { FormTemplateUpsertContext } from 'pages/consentAndRights/forms/templates/upsert/context/FormTemplateUpsertContextProvider'
import { Header } from '.'
import { FormInput } from 'components/form/FormInput'

type Props = {}

export const FormTitle: React.FC<Props> = props => {
  const { values } = useFormikContext<FormTemplateDTO>()
  const { languages } = useContext(FormTemplateUpsertContext)

  return (
    <Box display="flex" flexDirection="column">
      <Header title="Form Title" isRequired={false} />

      <Box
        sx={{
          mb: 6,
        }}
      >
        <FormInput
          id="form-title"
          fullWidth
          formPropertyName="title"
          label="Title"
          placeholder="Add form title"
          shouldUpdateDebounced
        />
      </Box>

      <Typography variant="h4" mb={3} component={Box}>
        Translations
      </Typography>

      {Object.keys(values?.translations || {}).map((languageCode, index) => {
        const language = languages?.find(enabledLanguage => enabledLanguage.code === languageCode)
        const label = `Title (${language?.englishName})`

        return (
          <Box
            sx={{
              mb: 3,
            }}
          >
            <FormInput
              shouldUpdateDebounced
              key={index}
              fullWidth
              formPropertyName={`translations.${languageCode}.title`}
              label={label}
              placeholder="Add translation"
            />
          </Box>
        )
      })}
    </Box>
  )
}
