import {
  IActivityParams,
  IApiCallActivityParams,
  IApprovalActivityParams,
  ITaskActivityParams,
  IEmailActivityParams,
  IAdobeConnectActivityParams,
  IWorkflowActivityParams,
  IGenericChildWorkflowParams,
  IAppLevel2Params,
  IDataReviewActivityParams,
  IIdentityVerificationActivityParams,
  ISetPermitsActivityParams,
  IEndFlowActivityParams,
} from 'pages/orchestration/workflows/edit/interfaces'

export const isApiCallActivityParamsTypeGuard = (params?: IActivityParams): params is IApiCallActivityParams =>
  (params as IApiCallActivityParams).httpMethod !== undefined

export const isTaskActivityParamsTypeGuard = (params?: IActivityParams): params is ITaskActivityParams =>
  (params as ITaskActivityParams).assignee !== undefined

export const isEmailActivityParamsTypeGuard = (params?: IActivityParams): params is IEmailActivityParams =>
  (params as IEmailActivityParams).cc !== undefined

export const isSetPermitsActivityParamsTypeGuard = (params?: IActivityParams): params is ISetPermitsActivityParams =>
  (params as ISetPermitsActivityParams).permitDetails !== undefined

export const isIdentityVerificationParamsTypeGuard = (
  params?: IActivityParams,
): params is IIdentityVerificationActivityParams => (params as IIdentityVerificationActivityParams).to !== undefined

export const isApprovalActivityParamsTypeGuard = (params?: IActivityParams): params is IApprovalActivityParams =>
  (params as IApprovalActivityParams).approver !== undefined

export const isGenericChildWorkflowParamsTypeGuard = (
  params?: IWorkflowActivityParams,
): params is IGenericChildWorkflowParams => (params as IGenericChildWorkflowParams).workflow !== undefined

export const isAdobeConnectActivityParamsTypeGuard = (
  params?: IWorkflowActivityParams,
): params is IAdobeConnectActivityParams => (params as IAdobeConnectActivityParams).type !== undefined

export const isAppLevel2ActivityParamsTypeGuard = (params?: IWorkflowActivityParams): params is IAppLevel2Params =>
  (params as IAppLevel2Params).appInstanceID !== undefined || (params as IAppLevel2Params).appID !== undefined

export const isDataReviewActivityParamsTypeGuard = (params?: IActivityParams): params is IDataReviewActivityParams =>
  (params as IDataReviewActivityParams)?.approver !== undefined ||
  (params as IDataReviewActivityParams)?.assignee !== undefined ||
  (params as IDataReviewActivityParams)?.needsApproval !== undefined

export const isEndFlowActivityParamsTypeGuard = (params?: IWorkflowActivityParams): params is IEndFlowActivityParams =>
  (params as IEndFlowActivityParams)?.configSelector !== undefined
