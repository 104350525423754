import { Button, Icon, ActionSheetWrapper, theme } from '@ketch-com/deck'
import { useState } from 'react'
import { Box, SxProps, Typography, styled } from '@mui/material'

type Props<T> = {
  items: T[]
  value: T
  borderBottomItems?: T[]
  onChange?: (item: T) => void
  renderItem?: (item: T, index?: number) => React.ReactNode
  sx?: SxProps
}

const ItemBox = styled(Box)({
  paddingLeft: '18px',
  paddingRight: '18px',
  maxWidth: '170px',
  display: 'flex',
  alignItems: 'center',
  ':hover': {
    backgroundColor: theme.palette.fadedGrey.main,
  },
  '&:first-child': {
    paddingTop: '4px',
    minHeight: '42px',
  },
  '&:last-child': {
    paddingBottom: '4px',
    minHeight: '42px',
  },
})

export const ExperienceServerDropdownButton: <T extends {}>(props: Props<T>) => React.ReactElement = ({
  items,
  borderBottomItems,
  value,
  onChange,
  renderItem,
  sx,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (item: any) => {
    onChange?.(item)
    handleClose()
  }

  return (
    <>
      <Button
        id="prefernce-center-page-dropdown-button"
        variant={'text'}
        endIcon={
          open ? (
            <Icon name={'OArrowCUp'} iconColor={theme.palette.darkDusk.main} color={'warning'} />
          ) : (
            <Icon name={'OArrowCDown'} iconColor={theme.palette.darkDusk.main} color={'warning'} />
          )
        }
        onClick={handleClick}
        sx={{
          backgroundColor: theme.palette.fadedGrey.main,
          minWidth: '133px',
          maxWidth: '146px',
          width: 'fit-content',
          justifyContent: 'space-between',
          ...sx,
        }}
      >
        {!!renderItem ? (
          <Typography
            variant="label"
            noWrap
            sx={{
              maxWidth: '110px',
              width: 'fit-content',
            }}
          >
            {renderItem(value)}
          </Typography>
        ) : (
          <Typography variant="label" color={theme.palette.darkDusk.main} maxWidth={'100px'} width="fit-content" noWrap>
            {value.toString()}
          </Typography>
        )}
      </Button>
      <ActionSheetWrapper
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        sx={{
          '& .MuiMenu-list': {
            padding: '0px',
          },
        }}
      >
        {items.map((item, index) => {
          const hasBorderBottom = borderBottomItems && borderBottomItems.includes(item)
          const hasPaddingTop = index > 0 && borderBottomItems?.includes(items[index - 1])
          return (
            <ItemBox
              onClick={() => handleChange(item)}
              key={index}
              borderBottom={hasBorderBottom ? `1px solid ${theme.palette.iron.main}` : ''}
              paddingBottom={hasBorderBottom ? '4px' : ''}
              paddingTop={hasPaddingTop ? '4px' : ''}
              sx={{ minHeight: hasBorderBottom || hasPaddingTop ? '42px' : '38px' }}
            >
              {!!renderItem ? (
                <Typography variant="body" noWrap>
                  {renderItem(item, index)}
                </Typography>
              ) : (
                <Typography variant="label" color={theme.palette.darkDusk.main} noWrap>
                  {item.toString()}
                </Typography>
              )}
            </ItemBox>
          )
        })}
      </ActionSheetWrapper>
    </>
  )
}
