import { APIRequestParams } from 'api/common/utils'
import { WorkflowDTO } from 'interfaces/workflows'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  organizationCode: string
  appInstanceId: string
}>

type Response = { workflows: WorkflowDTO[] }

export const fetchAppInstanceUsages = ({ organizationCode, appInstanceId }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/workflows/app-instances/${appInstanceId}`,
    }),
  )
