import React from 'react'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useParams, useNavigate } from 'react-router-dom'
import { EditSubscriptionControl } from 'pages/policyCenter/subscriptions/subscriptionControl/upsert/edit/EditSubscriptionControl'
import { UrlParams } from 'pages/assetManager/dsrConfig/interfaces'
import { SubscriptionControlFormData } from 'pages/policyCenter/subscriptions/interfaces'
import {
  SubscriptionControlDTO,
  PutSubscriptionControlRequestBodyDTO,
  SubscriptionControlOrchestrationDTO,
  SubscriptionOrchestrationAppDTO,
  SubscriptionOrchestrationWebhookDTO,
  PutSubscriptionControlOrchestrationsRequestBodyDTO,
} from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { useSubscriptionControl } from 'api/subscriptions/queries/useSubscriptionControl'
import { useUpdateSubscriptionControl } from 'api/subscriptions/mutations/useUpdateSubscriptionControl'
import { useOrganizationLanguagesPaginated } from 'api/languages/queries/useOrganizationLanguagesPaginated'
import { useWebhooks } from 'api/webhooks/queries/useWebhooks'
import { useSystems } from 'api/apps/queries/useSystems'
import { useUpdateSubscriptionControlOrchestrations } from 'api/subscriptions/mutations/useUpdateSubscriptionControlOrchestrations'
import { useSubscriptionControlOrchestrations } from 'api/subscriptions/queries/useSubscriptionControlOrchestrations'

export const UpsertSubscriptionControlContainer: React.FC = () => {
  const subscriptionControlOrchestration = { orchestrations: {} } as PutSubscriptionControlOrchestrationsRequestBodyDTO
  const navigate = useNavigate()
  const { code } = useParams<UrlParams>()

  const { data: organizationLanguages, isLoading: isOrganizationLanguagesLoading } = useOrganizationLanguagesPaginated({
    params: {
      includeMetadata: true,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch organization languages', type: 'error' })
    },
  })

  const { data: subscriptionControl, isLoading: isSubscriptionControlLoading } = useSubscriptionControl({
    params: { code },
    onError: () => {
      showToast({ content: 'Failed to fetch subscriptions', type: 'error' })
    },
  })

  const { data: subscriptionControlOrchestrations, isLoading: isSubscriptionControlOrchestrationsLoading } =
    useSubscriptionControlOrchestrations({
      params: { code },
      onError: () => {
        showToast({ content: 'Failed to fetch subscription orchestrations', type: 'error' })
      },
    })

  const { data: webhooksData = [], isLoading: isLoadingWebhooksData } = useWebhooks({
    itemsPerPage: 100,
    params: { event: 'subscriptions' },
    onError: () => {
      showToast({ content: 'Failed to fetch subscription webhooks', type: 'error' })
    },
  })

  const { data: systemsData, isLoading: isLoadingSystemsData } = useSystems({
    itemsPerPage: 100,
    params: { event: 'subscriptions' },
    onError: () => {
      showToast({ content: 'Failed to fetch subscription systems', type: 'error' })
    },
  })

  const { mutate: updateSubscriptionControlOrchestrations } = useUpdateSubscriptionControlOrchestrations({
    onSuccess: () => {
      showToast({ content: 'Saved subscription orchestrations', type: 'success' })
      navigate(RoutesManager.policyCenter.subscriptions.list.subscriptionControls.root.getURL())
    },
    onError: () => {
      showToast({ content: 'Unable to save subscription orchestrations', type: 'success' })
    },
  })

  const { mutate: updateSubscriptionControl } = useUpdateSubscriptionControl({
    onSuccess: () => {
      showToast({ content: 'Saved subscription', type: 'success' })
      updateSubscriptionControlOrchestrations({
        params: { data: subscriptionControlOrchestration, code: code || '' },
      })
    },
    onError: () => {
      showToast({ content: 'Unable to save subscription', type: 'error' })
    },
  })

  const onSubmit = (formikValues: SubscriptionControlFormData) => {
    const { basicDetails, orchestrations } = formikValues

    const data: PutSubscriptionControlRequestBodyDTO = {
      control: {
        code: basicDetails.code || '',
        name: basicDetails.name,
        description: basicDetails.description,
        translations: basicDetails.translations,
      } as SubscriptionControlDTO,
    }

    subscriptionControlOrchestration.orchestrations = orchestrations.map(orchestration => {
      if (orchestration?.appInstanceId) {
        return {
          app: {
            code: orchestration?.appCode,
            instanceId: orchestration?.appInstanceId,
            appName: orchestration?.name,
            logoUrl: orchestration?.appLogo?.url,
            connectionName: orchestration?.appInstanceName,
          } as SubscriptionOrchestrationAppDTO,
        } as SubscriptionControlOrchestrationDTO
      }

      return {
        webhook: {
          id: orchestration?.webhookId,
          name: orchestration?.name,
        } as SubscriptionOrchestrationWebhookDTO,
      } as SubscriptionControlOrchestrationDTO
    })
    updateSubscriptionControl({ params: { data, code: basicDetails.code } })
  }

  const isReady =
    !isOrganizationLanguagesLoading &&
    !isSubscriptionControlLoading &&
    !isSubscriptionControlOrchestrationsLoading &&
    !isLoadingWebhooksData &&
    !isLoadingSystemsData

  return (
    <EditSubscriptionControl
      isReady={isReady}
      onSubmit={onSubmit}
      subscriptionControl={subscriptionControl?.control || ({} as SubscriptionControlDTO)}
      organizationLanguages={organizationLanguages}
      webhooksData={webhooksData}
      systemsData={systemsData?.apps || []}
      subscriptionControlOrchestrations={subscriptionControlOrchestrations || {}}
    />
  )
}
