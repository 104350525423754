import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUsePaginatedQuery, CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchRightsInvocationsPaginated } from '../fetchers/fetchRightsInvocationsPaginated'

const useCustomPaginatedQuery = createUsePaginatedQuery({
  queryKey: ApiQueryKeys.auditLogsRightInvocationsPaginated,
  queryFn: fetchRightsInvocationsPaginated,
  select: res => res?.data?.rightInvocations || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useRightsInvocationsPaginated = (config?: QueryConfig) =>
  useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
