import React from 'react'
import { Box, Typography } from '@mui/material'
import { TimelineSeparator, TimelineItem as MuiTimelineItem, TimelineContent, TimelineConnector } from '@mui/lab'
import { WebhooksContextTimelineDTO } from '@ketch-com/figurehead'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { getContextDetailsString } from '../../../../utils/getContextDetailsString'
import { TimelineModalProps } from '../../ApiCallStepDetailsTimeline'
import { TimelineItemContent } from './components/TimelineItemContent/TimelineItemContent'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { getStatusIcon, getTitleColorAlt } from './utils'

type Props = {
  timelineItem: WebhooksContextTimelineDTO
  isLastItem: boolean
  setModalDetails: (props: TimelineModalProps) => void
  identitySpaces: IdentitySpaceDTO[]
}

export const TimelineItem: React.FC<Props> = ({ timelineItem, isLastItem, setModalDetails, identitySpaces }) => {
  const { status, time } = timelineItem
  return (
    <MuiTimelineItem sx={{ ml: -1, width: '100%', '&:before': { display: 'none' } }}>
      <TimelineSeparator>
        {getStatusIcon(status)}
        {isLastItem ? null : <TimelineConnector sx={{ width: '1px' }} />}
      </TimelineSeparator>
      <TimelineContent sx={{ mb: 6, pl: 1.5 }}>
        <Box mt={-1}>
          <Typography variant="label" color={getTitleColorAlt(status)}>
            {getContextDetailsString(timelineItem)}
          </Typography>

          <Typography fontSize={12} component="div" variant="smallBody" color="darkDuskFaded.main" mb={1}>
            <FormattedDateRenderer date={time} />
          </Typography>
        </Box>
        <TimelineItemContent
          timelineItem={timelineItem}
          setModalDetails={setModalDetails}
          identitySpaces={identitySpaces}
        />
      </TimelineContent>
    </MuiTimelineItem>
  )
}
