import { ApiQueryKeys } from 'api/common/queryKeys'
import { CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchExperiences } from 'api/experiences-v2/fetchers/fetchExperiences'

import { createUsePaginatedQueryPreservedPage } from 'api/common/createUsePaginatedQueryPreservedPage'

const useCustomPaginatedQuery = createUsePaginatedQueryPreservedPage({
  queryKey: ApiQueryKeys.experiencesPaginated,
  queryFn: fetchExperiences,
  select: res => res?.data?.experiences || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery>

export const useExperiencesPaginated = (config?: QueryConfig) => {
  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
