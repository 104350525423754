import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchRegulations } from 'api/regulations/fetchers/fetchRegulations'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.regulations,
  queryFn: fetchRegulations,
  select: res => res?.data?.regulations || [],
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useRegulations = (config?: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config?.params,
      start: 0,
      limit: 1000,
    },
  })
