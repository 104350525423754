import { Button, PopUp } from '@ketch-com/deck'
import { Box, Typography, styled } from '@mui/material'
import { useContext } from 'react'
import { AppNavigationContext } from '../context/AppNavigationContext'
import { ReactComponent as NotPermittedArt } from 'assets/icons/navigation/art_restricted_feature.svg'

const NotPermittedBox = styled(Box)({
  display: 'flex',
  gap: '32px',
  padding: '16px 0px',
})

const TextBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '93px',
})

export const NotPermittedModal: React.FC = () => {
  const { setShowNotPermittedModal } = useContext(AppNavigationContext)

  const onCloseClick = () => setShowNotPermittedModal(false)

  return (
    <PopUp
      variant="modal"
      title="This Feature Is Restricted"
      onClose={onCloseClick}
      popUpActionChildren={
        <Button size="large" color="secondary" onClick={onCloseClick}>
          Close
        </Button>
      }
      PaperProps={{ style: { width: '560px' } }}
    >
      <NotPermittedBox>
        <NotPermittedArt />
        <TextBox>
          <Typography variant="label" fontSize="16px" letterSpacing="-0.14px">
            You need additional permissions to access this feature.
          </Typography>
          <Typography>Ask one of your teammates to provide you with proper access and try again.</Typography>
        </TextBox>
      </NotPermittedBox>
    </PopUp>
  )
}
