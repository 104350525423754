import { MaybeNull } from 'interfaces'
import { AssetPurposeDTO, AssetSummaryDTO, PutAssetRequestBodyDTO } from '@ketch-com/figurehead'

type Args = { assetSummary: MaybeNull<AssetSummaryDTO>; purposes: AssetPurposeDTO[] }

export const mapValuesToPayload = ({ assetSummary, purposes }: Args): PutAssetRequestBodyDTO => {
  return {
    assetSummary: {
      ...assetSummary,
      asset: {
        ...assetSummary?.asset,
        purposes: [...purposes],
      },
    },
  }
}
