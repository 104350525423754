import { createUseMutation, CustomMutationConfig, useBindMutationStaticParams } from 'api/common/mutation'
import { checkIsFormTemplateCodeAvailable } from '../fetchers/checkIsFormTemplateCodeAvailable'

const useCustomMutation = createUseMutation({
  mutationFn: checkIsFormTemplateCodeAvailable,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useCheckIsFormTemplateCodeAvailable = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation, {})
}
