import { createUseMutation, useBindMutationStaticParams, CustomMutationConfig } from 'api/common/mutation'
import { includeDataReviewFile } from 'api/dataReview/fetchers/includeDataReviewFile'

const useCustomMutation = createUseMutation({
  mutationFn: includeDataReviewFile,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useIncludeDataReviewFile = (config?: Config) => {
  const mutation = useCustomMutation(config)

  return useBindMutationStaticParams(mutation)
}
