import { GetRightInvocationExportResponseBodyDTO } from '@ketch-com/figurehead'

import { API } from 'utils/api/api'
import { formatRequestStringV2 } from 'utils/helpers/api/formatRequestStringV2'

export const fetchRightInvocationExport = () => {
  return API.get<GetRightInvocationExportResponseBodyDTO>(
    formatRequestStringV2({
      entityUrl: `/api/v2/right-invocations/export`,
    }),
  )
}
