import React, { createContext } from 'react'
import { UseExperienceUpsertUtilsReturnType, useExperienceUpsertUtils } from '../hooks'

export const ExperienceUpsertContext = createContext({} as UseExperienceUpsertUtilsReturnType)

type Props = {
  children: React.ReactNode
}

export const ExperienceUpsertContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useExperienceUpsertUtils()

  return <ExperienceUpsertContext.Provider value={{ ...hookValues }}>{children}</ExperienceUpsertContext.Provider>
}

export const withExperienceUpsertContext = (Component: React.FC) => {
  return (props: any) => {
    return (
      <ExperienceUpsertContextProvider>
        <Component {...props} />
      </ExperienceUpsertContextProvider>
    )
  }
}
