import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface WebTagsListFilterState {
  q: string
  appCode: string
  appInstanceId: string
  property: string
  purposeId: string
  enabled: string
}

const initialState = {
  q: '',
  appCode: '',
  appInstanceId: '',
  property: '',
  purposeId: '',
  enabled: '',
} as WebTagsListFilterState

export const webTagsListFilterSlice = createSlice({
  name: 'webTagsListFilterSlice',
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.q = action.payload
    },
    clearSearchQuery: state => ({
      ...initialState,
    }),
    setAppCode: (state, action: PayloadAction<string>) => {
      state.appCode = action.payload
    },
    setAppInstanceId: (state, action: PayloadAction<string>) => {
      state.appInstanceId = action.payload
    },
    setProperty: (state, action: PayloadAction<string>) => {
      state.property = action.payload
    },
    setEnabled: (state, action: PayloadAction<string>) => {
      state.enabled = action.payload
    },

    setPurposeId: (state, action: PayloadAction<string>) => {
      state.purposeId = action.payload
    },
  },
})

export const { setSearchQuery, clearSearchQuery, setAppCode, setAppInstanceId, setProperty, setEnabled, setPurposeId } =
  webTagsListFilterSlice.actions

export default webTagsListFilterSlice.reducer
