import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { TableCell } from '@ketch-com/deck'
import { CanonicalPurposeDTO } from 'interfaces/purposes/canonicalPurpose'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { joinNonEmpty } from 'utils/helpers/joinNonEmpty'
import { PermitPropagationDataSubjectTypeBadge } from '../view/PermitPropagationDataSubjectTypeBadge'
import { FormCheckbox } from 'components/form/FormCheckbox'

export const getPermitPropagationEditListColumns = ({ ID }: { ID: string }): GridColDef<CanonicalPurposeDTO>[] => [
  {
    align: 'left',
    field: 'ketchPurpose',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Ketch Purpose',
    sortable: false,
    renderCell: ({ row: { name, code } }: GridRenderCellParams<CanonicalPurposeDTO>) => {
      return (
        <TableCell>
          <FormCheckbox
            name={`${ID}.${code}`}
            label={<NameAndCodeCellRenderer name={name} code={code} />}
            inlineLabel
          />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'customPurposes',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Associated Custom Purpose(s)',
    sortable: false,
    renderCell: ({ row: { purposes } }: GridRenderCellParams<CanonicalPurposeDTO>) => {
      const names = joinNonEmpty(purposes?.map(({ name }) => name), ', ')
      return <TableCell title={names} />
    },
  },
  {
    align: 'left',
    field: 'dataSubjectTypes',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Subject Type',
    sortable: false,
    renderCell: ({ row: canonicalPurpose }: GridRenderCellParams<CanonicalPurposeDTO>) => {
      return (
        <TableCell>
          <PermitPropagationDataSubjectTypeBadge canonicalPurpose={canonicalPurpose} />
        </TableCell>
      )
    },
  },
]
