import { useContext, useMemo, useRef } from 'react'
import { useFormikContext } from 'formik'

import { createStep, ICreateStepParams } from 'pages/orchestration/workflows/edit/utils/steps/createStep'
import {
  createPlaceholder,
  createPlaceholderStep,
  ICreatePlaceholder,
} from 'pages/orchestration/workflows/edit/utils/steps/createPlaceholder'
import { IUpdateStepParams, updateStep } from 'pages/orchestration/workflows/edit/utils/steps/updateStep'
import { IRemoveStepParams, removeStep } from 'pages/orchestration/workflows/edit/utils/steps/removeStep'
import { IReplaceStepParams, replaceStep } from 'pages/orchestration/workflows/edit/utils/steps/replaceStep'
import { EditWorkflowContext } from '../../../contexts/EditWorkflowContext'
import { ICanvasStep, IWorkflowConfig } from '../../../interfaces'
import { useDispatch } from 'react-redux'
import { copySteps, setCopiedStepsCanonicalRightCode } from 'store/workflowCanvasCopiedStepsSlice'
import { getWorkflowCanvasCopiedStepsState } from 'store/workflowCanvasCopiedStepsSlice/selectors'
import { useAppSelector } from 'store/hooks'
import { showToast } from 'components/modals/AlertComponent'
import { IPasteStepsParams, pasteSteps } from '../../../utils/steps/pasteSteps'
import { getStepsInPath } from '../../../utils/steps/getStepsInPath'
import { CanvasStepType, GatewayModeName, UNDO_REDO_BUFFER_SIZE } from '../../../utils/constants'
import { cloneDeep } from 'lodash'
import { connectSteps } from '../../../utils/steps/connectSteps'
import { WorkflowActivityGatewayMode } from '../../../../../../../interfaces/workflowActivities/workflowActivity'
import { Stack } from 'utils/dataStructures/stack/Stack'
import { getStepType } from '../../../utils/steps/getStepType'
import { getUuidWithoutHyphensAndStartingWithALetter } from '../../../utils/getUuidWithoutHyphensAndStartingWithALetter'
import { getStepsInGateway } from '../../../utils/steps/getStepsInGateway'

export const useEditWorkflowFormContainerUtils = () => {
  const workflowConfigFormikContext = useFormikContext<IWorkflowConfig>()
  const { values, setFieldValue, handleSubmit, setSubmitting } = workflowConfigFormikContext
  const dispatch = useDispatch()
  const reduxCopiedStepsState = useAppSelector(getWorkflowCanvasCopiedStepsState)

  // Stacks to store undo/redo state
  const undoStack = useRef(new Stack<ICanvasStep[]>(UNDO_REDO_BUFFER_SIZE))
  const redoStack = useRef(new Stack<ICanvasStep[]>(UNDO_REDO_BUFFER_SIZE))

  const {
    canonicalRightCode,
    handleConfirmMultipleEndTilesModal,
    handleConfirmSubmitWithUnRequiredDataSubjectVariablesReferencedInTasks,
    isActivitiesListLoading,
    isDuplicateMode,
    isEditMode,
    isReady,
    onSubmit,
    setCanonicalRightCode,
    setShowMultipleEndTilesModal,
    setUnRequiredDataSubjectVariablesReferencedInTasksWarning,
    standardJoinActivity,
    showMultipleEndTilesModal,
    unRequiredDataSubjectVariablesReferencedInTasksWarning,
    workflow,
    workflowActivities,
  } = useContext(EditWorkflowContext)

  /**
   * Sets the steps state while managing the undo/redo buffers
   * @param newSteps New steps state to set
   * @param oldSteps Previous steps state
   * @param clearRedo Flag indicating if the redo buffer should be cleared after state is set
   */
  const setStepsWithUndo = (newSteps: ICanvasStep[], oldSteps: ICanvasStep[], clearRedo: boolean = true) => {
    // Update the undo buffer
    undoStack.current.push(oldSteps)

    // Update steps value
    setFieldValue('steps', newSteps)

    // Conditionally clear redo buffer
    if (clearRedo) redoStack.current.clear()
  }

  const handleCreateStep = (params: ICreateStepParams) => {
    const { parentStep, transitionIndex, stepToCreate } = params
    const { newStep, newSteps } = createStep({
      workflow: values,
      parentStep,
      stepToCreate,
      transitionIndex,
      standardJoinActivity,
    })

    setStepsWithUndo(newSteps, values.steps)

    return newStep
  }

  const handleUpdateStep = (params: IUpdateStepParams) => {
    const { newStep, newSteps } = updateStep({ ...params, workflow: values })

    // We don't allow undo of details within a step
    setFieldValue('steps', newSteps)
    return newStep
  }

  const handleRemoveStep = (params: IRemoveStepParams) => {
    const { newSteps } = removeStep({ ...params, workflow: values })

    setStepsWithUndo(newSteps, values.steps)
  }

  const handleReplaceStep = (params: IReplaceStepParams) => {
    const { newStep, newSteps } = replaceStep({ ...params, workflow: values })

    setStepsWithUndo(newSteps, values.steps)
    return newStep
  }

  /**
   * Paste steps into a placeholder. When pasting into a non-step placeholder we first add a step placeholder and then
   * paste onto it.
   */
  const handlePasteSteps = ({ stepToReplace, copiedSteps, allSteps, transitionIndex }: IPasteStepsParams) => {
    const stepToReplaceType = getStepType(stepToReplace)
    const isJoin =
      stepToReplaceType === CanvasStepType.Gateway && stepToReplace.gateway!.mode === WorkflowActivityGatewayMode.JOIN
    const isDecisionBoolean =
      stepToReplaceType === CanvasStepType.Gateway &&
      stepToReplace.gateway!.mode === WorkflowActivityGatewayMode.DECISION_BOOLEAN
    const isGatewaySingle =
      stepToReplaceType === CanvasStepType.Gateway &&
      stepToReplace.gateway!.mode === WorkflowActivityGatewayMode.DECISION_SINGLE

    if (stepToReplaceType !== CanvasStepType.Placeholder) {
      // Non-step placeholder case, we insert a step placeholder and paste on it

      // Determine the ID of the new placeholder
      let placeholderId
      if (isJoin) {
        placeholderId = stepToReplace.gateway?.transitions.length
          ? stepToReplace.gateway?.transitions[0].ID?.toString()
          : getUuidWithoutHyphensAndStartingWithALetter()
      } else if (isGatewaySingle || (isDecisionBoolean && stepToReplace.gateway?.transitions[transitionIndex!].ID)) {
        placeholderId = stepToReplace.gateway?.transitions[transitionIndex!].ID!.toString()
      } else if (isDecisionBoolean) {
        stepToReplace.gateway!.transitions[transitionIndex!].ID = getUuidWithoutHyphensAndStartingWithALetter()
        placeholderId = stepToReplace.gateway!.transitions[transitionIndex!].ID!.toString()
      }

      // Create new placeholder and connect previous step to it
      const placeholder = createPlaceholderStep(undefined, undefined, placeholderId)
      stepToReplace = cloneDeep(stepToReplace)
      connectSteps(stepToReplace, placeholder)

      // Ensure allSteps has the proper steps now
      const stepToReplaceIndex = allSteps.findIndex(step => step.ID === stepToReplace.ID)
      allSteps[stepToReplaceIndex] = stepToReplace
      allSteps.push(placeholder)

      // Do paste with updated steps
      const { pastedSteps, newSteps } = pasteSteps({ stepToReplace: placeholder, copiedSteps, allSteps })
      setStepsWithUndo(newSteps || [], values.steps)
      return pastedSteps
    } else {
      // Step placeholder case
      const { pastedSteps, newSteps } = pasteSteps({ stepToReplace, copiedSteps, allSteps })
      setStepsWithUndo(newSteps || [], values.steps)
      return pastedSteps
    }
  }

  const handleCreatePlaceholder = (params: ICreatePlaceholder) => {
    const { newSteps, newStep } = createPlaceholder({ ...params, workflow: values })
    setStepsWithUndo(newSteps, values.steps)
    return newStep
  }

  const isWorkflowWithType = !!values.canonicalRightCode

  /** Copy/paste */
  const handleCopyActivity = (activity: ICanvasStep) => {
    dispatch(copySteps([activity]))
    dispatch(setCopiedStepsCanonicalRightCode(values.canonicalRightCode || ''))
    showToast({
      content: `
        ${activity.name} copied!\n\n
        Create or select an empty tile on the canvas to paste.
      `,
      type: 'success',
      autoHideDuration: 3000,
    })
  }

  /**
   * Copies all steps from a gateway and the next join
   * @param gateway Gateway to copy
   * @param steps All steps in the workflow
   */
  const handleCopyGateway = (gateway: ICanvasStep, steps: ICanvasStep[]) => {
    const gatewayName = gateway.gateway?.mode ? GatewayModeName[gateway.gateway?.mode] : 'Gateway'
    const stepsToCopy = getStepsInGateway({ gateway, steps })
    dispatch(copySteps(stepsToCopy))
    dispatch(setCopiedStepsCanonicalRightCode(values.canonicalRightCode || ''))
    showToast({
      content: `
        ${gatewayName} copied!\n\n
        Create or select an empty tile on the canvas to paste.
      `,
      type: 'success',
      autoHideDuration: 3000,
    })
  }

  /**
   * Copies all steps in a path
   * @param pathStartStepId
   * @param steps
   * @param isDecisionBoolean
   */
  const handleCopyPath = (pathStartStepId: string, steps: ICanvasStep[], isDecisionBoolean: boolean = false) => {
    const stepsToCopy = getStepsInPath({ pathStartStepId, steps, untilEnd: isDecisionBoolean })
    dispatch(copySteps(stepsToCopy))
    dispatch(setCopiedStepsCanonicalRightCode(values.canonicalRightCode || ''))
    showToast({
      content: `
        Path copied!\n\n
        Create or select an empty tile on the canvas to paste.
      `,
      type: 'success',
      autoHideDuration: 3000,
    })
  }

  const hasCopiedSteps = !!reduxCopiedStepsState.steps.length

  /** Undo/Redo */
  const handleUndo = () => {
    const previousSteps = undoStack.current.pop()
    if (previousSteps) {
      redoStack.current.push(values.steps)
      setFieldValue('steps', previousSteps)
    }
  }

  const handleRedo = () => {
    const previousSteps = redoStack.current.pop()
    if (previousSteps) {
      // Set steps to the previously undone value but don't clear the redo buffer
      setStepsWithUndo(previousSteps, values.steps, false)
    }
  }

  const canUndo = useMemo(() => {
    return !undoStack.current.isEmpty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [undoStack.current._items.length])

  const canRedo = useMemo(() => {
    return !redoStack.current.isEmpty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redoStack.current._items.length])

  const payload = {
    canonicalRightCode: workflow?.canonicalRightCode || values?.canonicalRightCode || canonicalRightCode,
    handleConfirmMultipleEndTilesModal,
    handleCreatePlaceholder,
    handleCreateStep,
    handleRemoveStep,
    handlePasteSteps,
    handleReplaceStep,
    handleSubmit,
    handleUpdateStep,
    handleCopyActivity,
    handleCopyGateway,
    handleCopyPath,
    handleUndo,
    handleRedo,
    canUndo,
    canRedo,
    copiedSteps: reduxCopiedStepsState.steps,
    copiedStepsCanonicalRightCode: reduxCopiedStepsState.copiedStepsCanonicalRightCode,
    hasCopiedSteps,
    isActivitiesListLoading,
    isDuplicateMode,
    isEditMode,
    isReady,
    isWorkflowWithType,
    onSubmit,
    setCanonicalRightCode,
    setShowMultipleEndTilesModal,
    setSubmitting,
    showMultipleEndTilesModal,
    values,
    workflow,
    workflowActivities,
    workflowConfigFormikContext,
    unRequiredDataSubjectVariablesReferencedInTasksWarning,
    setUnRequiredDataSubjectVariablesReferencedInTasksWarning,
    handleConfirmSubmitWithUnRequiredDataSubjectVariablesReferencedInTasks,
  }

  return payload
}

export type UseEditWorkflowFormContainerUtils = ReturnType<typeof useEditWorkflowFormContainerUtils>
