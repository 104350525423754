import React from 'react'
import Box from '@mui/material/Box'
import { useField, FieldValidator } from 'formik'
import { FormControl, FormControlLabel, FormLabel, RadioGroup, SxProps, Theme, Typography } from '@mui/material'
import { isNumber } from 'lodash'

import { ObjectLiteral } from 'interfaces'
import { Radio, RadioProps } from '@ketch-com/deck'
import { FormError } from './FormError'

function isText(data: unknown): data is string {
  return typeof data === 'string'
}

interface Props<T extends ObjectLiteral & { disabled?: boolean } = ObjectLiteral> extends Omit<RadioProps, 'onChange'> {
  /** Formik field name */
  formPropertyName: string
  /** Text to be displayed above Component */
  label?: React.ReactNode
  required?: boolean
  /** Custom validation method */
  validate?: FieldValidator
  /** Value key refers to item value */
  valueKey?: string
  /** Array of items to render in menu */
  options: T[]
  /** Label renderer for each radio button */
  renderLabel: (item: T, isSelected?: boolean) => React.ReactNode
  /** set values to number */
  isInt?: boolean
  /** show radio buttons horizontally */
  row?: boolean
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, nextValue: string) => void
  hideOptionalLabel?: boolean
  labelSx?: SxProps<Theme> | undefined
}

/**
 * -
 */
export function FormRadioGroup<T extends ObjectLiteral & { disabled?: boolean } = ObjectLiteral>({
  formPropertyName,
  isInt = false,
  label = '',
  onChange,
  options,
  renderLabel,
  required,
  row,
  validate,
  valueKey = 'value',
  hideOptionalLabel = false,
  sx,
  labelSx,
}: Props<T>) {
  const [field, meta, { setValue }] = useField({ name: formPropertyName, validate })
  const showError = meta.error && meta.touched

  const onChangeInner = (e: React.ChangeEvent<HTMLInputElement>, nextValue: string) => {
    const val = isNumber(field.value) ? +nextValue : nextValue
    if (isInt) {
      setValue(parseInt(val as string))
    } else {
      setValue(val)
    }
    if (onChange) {
      onChange(e, nextValue)
    }
  }

  return (
    <Box>
      <FormControl>
        <FormLabel
          sx={{
            ...labelSx,
          }}
        >
          {isText(label) ? (
            required ? (
              <Typography variant="label" color="darkDusk.main">
                {label}
              </Typography>
            ) : (
              <>
                <Typography variant="label" color="darkDusk.main">
                  {label}
                </Typography>{' '}
                {!hideOptionalLabel && (
                  <Typography variant="label" color="darkGrey.main">
                    {'(Optional)'}
                  </Typography>
                )}
              </>
            )
          ) : (
            label
          )}
        </FormLabel>
        <RadioGroup
          {...field}
          value={(typeof field.value === 'boolean' ? String(field.value) : field.value) || null}
          row={row}
          onChange={onChangeInner}
          sx={{
            gap: 2,
            ...sx,
          }}
        >
          {options.map(option => (
            <FormControlLabel
              key={option[valueKey]}
              value={isInt ? parseInt(option[valueKey]) : option[valueKey]}
              control={<Radio sx={{ mr: '1px' }} />}
              label={renderLabel(option, field.value === option[valueKey])}
              disabled={option.disabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {showError && <FormError msg={meta.error} />}
    </Box>
  )
}
