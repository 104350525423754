import React from 'react'
import { Grid, Box, Typography, Tooltip } from '@mui/material'
import { useFormikContext } from 'formik'
import { SwitchToggle, Icon, IconDictionary, theme } from '@ketch-com/deck'
import { formatCodeFromName } from 'utils/formatters'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'

type Props = {
  isEditMode?: boolean
  themes: ThemeV3DTO[]
}

export const DeploymentPlanBasicDetails: React.FC<Props> = ({ isEditMode = false, themes }) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext()

  return (
    <FormSection title="Basic Details">
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <FormInput
            required
            fullWidth
            label="Name"
            name="name"
            placeholder="Example: My New Deployment Plan"
            onChange={e => {
              if (!isEditMode) {
                setFieldTouched('code', true)
                setFieldValue('code', formatCodeFromName(e.target.value))
              }
            }}
          />
        </Grid>

        {!isRelease(Release.PropertyThemeDocuments) && (
          <Grid item xs={3}>
            <FormDropdown
              fullWidth
              valueKey="id"
              name="themeID"
              label="Theme"
              items={themes}
              placeholder="Select Theme"
              renderItem={({ name }) => <NameAndCodeCellRenderer name={name} />}
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={4} mt={0}>
        <Grid item xs={6}>
          <FormInput
            required
            fullWidth
            disabled={isEditMode}
            label="Code"
            name="code"
            placeholder="Example: AP957384"
          />
        </Grid>

        <Grid item xs={3}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" gap={0.5}>
              <Typography variant="label">Quiet Mode</Typography>

              <Tooltip title="Quiet mode deployments will not serve experiences or generate consent.">
                <Box>
                  <Icon
                    name={IconDictionary.FImportant}
                    iconColor={theme.palette.darkDuskFaded.main}
                    width={20}
                    height={20}
                  />
                </Box>
              </Tooltip>
            </Box>
            <SwitchToggle
              onChange={(e, switchSelection) => {
                setFieldValue('isOrchestrationOnly', switchSelection)
              }}
              checked={(values as DeploymentPlanDTO)?.isOrchestrationOnly}
            />
          </Box>
        </Grid>
      </Grid>
    </FormSection>
  )
}
