import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchWebhookContext } from 'api/executions/fetchers/fetchWebhookContext'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.webhookContext,
  queryFn: fetchWebhookContext,
  select: res => res?.data || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useWebhookContext = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params,
    },
  })
}
