import { LookUpTypes } from 'pages/assetManager/scenario/details/components'
import { GetDSRConfigCountResponseBodyDTO } from '@ketch-com/figurehead'

type Props = {
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
}

export const ScenarioDetailsRoutes: React.FC<Props> = ({ dsrConfigCount }) => {
  return <LookUpTypes dsrConfigCount={dsrConfigCount} />
}
