import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'
import { showToast } from 'components/modals/AlertComponent'
import { useCreateDataSubjectType } from 'api/dataSubjectTypes/mutations/useCreateDataSubjectType'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'

import { DataSubjectTypesUpsertView } from 'pages/settings/dataSubjectTypes/upsert/DataSubjectTypesUpsertView'
import { useUpdateDataSubjectType } from 'api/dataSubjectTypes/mutations/useUpdateDataSubjectType'
import { useDataSubjectType } from 'api/dataSubjectTypes/queries/useDataSubjectType'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { useDataSubjectTypeTemplates } from 'api/dataSubjectTypes/queries/useDataSubjectTypeTemplates'

export const DataSubjectTypesUpsertContainer: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { id } = useParams<{ id: string }>()
  const isEditMode = id !== NEW_ITEM_ID

  const onCreateDataSubjectTypeSuccess = async () => {
    await queryClient.refetchQueries(ApiQueryKeys.entitlements)

    showToast({ content: 'Subject Type Created', type: 'success' })

    navigate(RoutesManager.settings.dataSubjectTypes.root.getURL())
  }

  const onUpdateDataSubjectTypeSuccess = async (nextDataSubjectType: DataSubjectTypeDTO) => {
    await queryClient.refetchQueries([ApiQueryKeys.dataSubjectType, { code: nextDataSubjectType.code }])

    if (isEditMode) {
      showToast({ content: 'Subject Type updated', type: 'success' })
    } else {
      showToast({ content: 'Subject Type created', type: 'success' })
    }

    navigate(RoutesManager.settings.dataSubjectTypes.root.getURL())
  }

  const { mutateAsync: handleCreateDataSubjectType } = useCreateDataSubjectType({
    onSuccess: () => onCreateDataSubjectTypeSuccess(),
    onError: () => {
      showToast({ content: 'Failed to create Subject Type', type: 'error' })
    },
  })

  const { mutateAsync: handleUpdateDataSubjectType } = useUpdateDataSubjectType({
    onSuccess: ({ data }) => onUpdateDataSubjectTypeSuccess(data.dataSubjectType),
    onError: () => {
      showToast({ content: 'Failed to update Subject Type', type: 'error' })
    },
  })

  const { data: dataSubjectType, isLoading: isDataSubjectTypeLoading } = useDataSubjectType({
    enabled: isEditMode,
    params: {
      dataSubjectTypeCode: id!,
    },
  })

  const { data: languages, isLoading: isLanguagesLoading } = useOrganizationLanguages()
  const { data: templates, isLoading: isTemplatesLoading } = useDataSubjectTypeTemplates()

  const onSubmit = async (formData: DataSubjectTypeDTO) => {
    await (isEditMode
      ? handleUpdateDataSubjectType({
          params: {
            code: id!,
            formData,
          },
        })
      : handleCreateDataSubjectType({
          params: {
            formData,
          },
        }))
  }

  return (
    <DataSubjectTypesUpsertView
      isReady={!isDataSubjectTypeLoading && !isLanguagesLoading && !isTemplatesLoading}
      isEditMode={isEditMode}
      dataSubjectType={dataSubjectType}
      languages={languages}
      onSubmit={onSubmit}
      templates={templates}
    />
  )
}
