import { Icon, ListLayout } from '@ketch-com/deck'
import { useSyncWebTags } from 'api/webtags/mutations/useSyncWebTags'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { SectionTab } from 'interfaces/sectionTabs/sectionTabs'
import { max } from 'lodash'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useIsPermitted } from 'utils/hooks'
import { useNavigateGated } from 'utils/routing/hooks/useNavigateGated'
import { RoutesManager } from 'utils/routing/routesManager'

const tabs: SectionTab[] = [
  {
    tabProps: {
      label: 'Systems',
    },
    path: RoutesManager.orchestration.consent.systems.root.getURL(),
    permissions: [PERMISSIONS.PERMIT_ORCH_READ],
    entitlements: [ENTITLEMENTS.ORCHESTRATION_CONSENT],
  },
  {
    tabProps: {
      label: 'Privacy Protocols',
    },
    path: RoutesManager.orchestration.consent.privacyProtocols.root.getURL(),
    permissions: [PERMISSIONS.PERMIT_ORCH_READ],
    entitlements: [ENTITLEMENTS.ORCHESTRATION_PROTOCOLS],
  },
  {
    tabProps: {
      label: 'Tags',
    },
    path: RoutesManager.orchestration.webTags.root.getURL(),
    permissions: [PERMISSIONS.WEB_TAGS_READ],
    entitlements: [ENTITLEMENTS.CONSENT_MANAGEMENT],
  },
]

export const ConsentSection: React.FC = () => {
  const navigate = useNavigate()
  const navigateGated = useNavigateGated()
  const { pathname } = useLocation()
  const { isPermitted } = useIsPermitted()
  const isTagsWriter = isPermitted(PERMISSIONS.WEB_TAGS_WRITE)

  // Only show the "Get Tags" button when on the tags list page
  const showGetTags = isTagsWriter && window.location.pathname.endsWith('/tags')

  // Determine what tab is currently selected based on URL
  const tab = tabs.findIndex(t => pathname.includes(t.path))
  const tabItems = Object.values(tabs).map(t => t.tabProps)

  // Sync tags hook
  const { mutateAsync: syncWebTags } = useSyncWebTags({
    onSuccess: () => {
      showToast({ content: 'Tag sync initiated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to initiate tag sync', type: 'error' })
    },
  })

  const handleGetTagsClick = async () => {
    await syncWebTags()
  }

  /* Redirect if base route is navigated to  */
  useEffect(() => {
    if (pathname === RoutesManager.deployment.consentHome.root.getURL()) {
      navigate(RoutesManager.orchestration.consent.systems.root.getURL())
    }
  }, [pathname, navigate])

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    const { path, entitlements, permissions } = tabs[newTab]
    navigateGated(path, permissions, entitlements)
  }

  return (
    <ListLayout
      headerTitle="Consent"
      tabsComponentProps={{ value: max([tab, 0]), onChange: handleTabChange, tabItems }}
      headerRightActionComponentProps={
        showGetTags
          ? {
              headerRightActionInfoButtonProps: {
                children: 'Get Tags',
                startIcon: <Icon name={'OTransformDown'} sx={{ mr: '4px' }} />,
                onClick: handleGetTagsClick,
              },
            }
          : undefined
      }
    >
      <Outlet />
    </ListLayout>
  )
}
