import { useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useCustomRightNameList } from 'api/customRightNames/queries/useCustomRightNameList'
import { useUpdateCustomRightName } from 'api/customRightNames/mutations/useUpdateCustomRightName'
import { showToast } from 'components/modals/AlertComponent'
import { PutCustomRightNameRequestBodyDTO } from '@ketch-com/figurehead'
import { NEW_ITEM_ID } from 'utils/constants/misc'

export const useContextCustomRightNameUtils = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const [saveWarningModal, setSaveWarningModal] = useState(false)
  const navigate = useNavigate()

  const onCrnUpdateSuccess = async (nextCrnUpdateType: PutCustomRightNameRequestBodyDTO) => {
    await queryClient.refetchQueries([ApiQueryKeys.customRightName, { id: nextCrnUpdateType.customRightName?.id }])

    showToast({ content: 'Custom Right Name updated', type: 'success' })

    navigate(RoutesManager.settings.customRightName.root.getURL())
  }

  const {
    data: customRightNamesList,
    isFetching,
    isLoading,
    refetch: refetchCustomRightNameList,
  } = useCustomRightNameList({
    params: {
      includeMetadata: true,
    },
  })

  const [searchString, setSearchString] = useState('')
  const [isUpsertModalOpen, setIsUpsertModalOpen] = useState(false)
  const [showDetailedView, setShowDetailedView] = useState(false)
  const queryClient = useQueryClient()

  const onSubmit = async () => {
    await queryClient.refetchQueries(ApiQueryKeys.contextVariablesPaginated)
    setIsUpsertModalOpen(false)
  }

  const handleCreateName = () => {
    navigate(RoutesManager.settings.customRightName.upsert.root.getURL({ id: NEW_ITEM_ID }))
  }

  const isEmptyState = !isFetching && !customRightNamesList.length

  const tabs = useMemo(
    () => [
      {
        label: 'Details',
      },
      {
        label: 'Usage',
      },
    ],
    [],
  )

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const { mutateAsync: handleCrnUpdate } = useUpdateCustomRightName({
    onSuccess: ({ data }) => onCrnUpdateSuccess(data),
    onError: () => {
      showToast({ content: 'Failed to update Custom Right Name', type: 'error' })
    },
  })
  const onSubmitUpdate = async (formData: any) => {
    if (formData.jurisdictionRights && saveWarningModal === false) {
      setSaveWarningModal(true)
    } else {
      await handleCrnUpdate({
        params: {
          id: formData.id!,
          formData,
        },
      })
    }
  }

  return {
    isEmptyState,
    isUpsertModalOpen,
    onSubmit,
    searchString,
    setIsUpsertModalOpen,
    setSearchString,
    handleCreateName,
    showDetailedView,
    setShowDetailedView,
    tabs,
    handleTabChange,
    currentTab,
    customRightNamesList,
    isLoading,
    onSubmitUpdate,
    saveWarningModal,
    setSaveWarningModal,
    refetchCustomRightNameList,
  }
}
