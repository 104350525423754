import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchTeamDefaultAssignee } from '../fetchers/fetchTeamDefaultAssignee'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.teamDefaultAssignee,
  queryFn: fetchTeamDefaultAssignee,
  select: res => res?.data.assigneeId,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useTeamDefaultAssignee = (config?: Config) =>
  useCustomQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
