import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Box, Typography } from '@mui/material'
import { CreateRelationdhipFormData } from 'pages/assetManager/relationshipMapping/interfaces'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { useSelectAssetsState } from 'pages/assetManager/relationshipMapping/components/CreatedRelationships/components/CreateRelationshipMappingModal/hooks/useSelectAssetsState'
import { TableResources } from 'pages/assetManager/interfaces'
import { useTableResources } from 'api/assets/queries/useTableResources'
import { DropListButton, ActionSheetItem } from '@ketch-com/deck'

export const TableAssetsForiegn = () => {
  const { setFieldValue, setFieldTouched, isSubmitting, values, errors, touched } =
    useFormikContext<CreateRelationdhipFormData>()
  const { setInputValue, searchQueryValue, setSearchQueryValue } = useSelectAssetsState<TableResources>()

  useEffect(() => {
    setInputValue('')
    setSearchQueryValue('')
  }, [values?.databaseResourceId, setInputValue, setSearchQueryValue])

  const { data: tableResourcesData, isLoading: isTableResourcesDataLoading } = useTableResources({
    params: {
      query: searchQueryValue,
      limit: 200,
      parentDbId: values?.databaseResourceId,
    },
  })

  const isError = !!errors?.foriegnTableResourceId && !!touched?.foriegnTableResourceId
  const errorMessage = errors?.foriegnTableResourceId || 'no-error'

  return (
    <Box>
      <DropListButton
        key={values.databaseResourceId}
        noOptionsText="No Tables"
        placeholder="Select Table"
        loading={isTableResourcesDataLoading}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={isSubmitting}
        options={tableResourcesData?.tableResources || []}
        filterOptions={x => x}
        getOptionLabel={option => option?.name || ''}
        renderOption={(props, option, { selected }) => {
          return (
            <ActionSheetItem key={option.id} selected={selected} {...props}>
              <Box display="flex" gap={0.5} flexDirection="column">
                <Box alignSelf="flex-start">
                  <Typography variant="body">{option?.name}</Typography>
                </Box>
                <Box alignSelf="flex-start" display="flex" alignItems="center" gap={0.5}>
                  <img
                    width={16}
                    height={16}
                    src={getAssetCloudImgUrl(values?.technology || '')}
                    alt={values?.technology || 'technology image'}
                  />
                  <Typography variant="footnote">
                    {option?.schemaName && option?.name ? `${option?.schemaName}.${option?.name}` : 'Table'}
                  </Typography>
                </Box>
              </Box>
            </ActionSheetItem>
          )
        }}
        onBlur={() => {
          setFieldTouched('foriegnTableResourceId', true)
        }}
        onChange={(e, tableResource) => {
          setFieldValue('foriegnColumnResourceId', '')
          setFieldTouched('foriegnColumnResourceId', false)
          setFieldValue('foriegnTableResourceId', tableResource?.id)
        }}
        onInputChange={(event, value) => {
          setInputValue(value)
        }}
        error={isError}
        errorMessage={errorMessage}
      />
    </Box>
  )
}
