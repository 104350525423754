import { useEffect, useState } from 'react'
import { Button, InfoRow, Spinner } from '@ketch-com/deck'

import ViewRule from 'pages/consentAndRights/applications/upsert/components/ViewRule'
import {
  ExperienceAB,
  Rule,
  RuleSet,
} from 'pages/consentAndRights/applications/upsert/components/ApplicationPredicates.types'
import EditRule from 'pages/consentAndRights/applications/upsert/components/EditRule'
import { Divider, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { EditExperienceAB, ViewExp } from 'pages/consentAndRights/applications/upsert/components/EditExperienceAB'
import { useExperiences } from 'api/experiences-v2/queries/useExperiences'
import { Logger } from 'utils/Logger'
import { getEmptyExp, getEmptyRule } from 'pages/consentAndRights/applications/upsert/components/ApplicationPredicates'

export interface InfoRuleGroupType {
  onSubmit: (value: RuleSet, isDelete: boolean) => void
  ruleSet: RuleSet
  ruleSetIndex: number
}

export default function InfoRowRuleSet({ ruleSetIndex, onSubmit, ruleSet }: InfoRuleGroupType) {
  const lastExp = ruleSet.experiences[ruleSet.experiences.length - 1]
  const [isEditing, setIsEditing] = useState(!Boolean(lastExp.chance))
  const [innerValue, setInnerValue] = useState(ruleSet)
  const { data, isLoading, error } = useExperiences({ params: { limit: 0 } })

  useEffect(() => {
    setInnerValue(ruleSet)
  }, [ruleSet])

  const handleSubmit = (isDelete?: boolean) => {
    onSubmit(innerValue, Boolean(isDelete))
    setIsEditing(false)
  }

  const handleOnCancel = () => {
    setIsEditing(false)
    setInnerValue(ruleSet)
  }

  const onRuleChange = (rule: Rule, key: string, value: string, isDelete = false) => {
    const index = innerValue.rules.findIndex(i => rule.id === i.id)
    if (index === -1) {
      Logger.debug(`Internal error: Rule ${rule.id} not found`)
      return
    }
    const updatedRules = isDelete
      ? [...innerValue.rules.splice(0, index), ...innerValue.rules.splice(index + 1)]
      : [...innerValue.rules.splice(0, index), { ...rule, [key]: value }, ...innerValue.rules.splice(index + 1)]
    setInnerValue({ ...innerValue, rules: updatedRules })
  }
  const onExperienceChange = (exp: ExperienceAB, key: string, value: string, isDelete = false) => {
    const index = innerValue.experiences.findIndex(i => exp.id === i.id)
    if (index === -1) {
      Logger.debug(`Internal error: Rule ${exp.id} not found`)
      return
    }
    const updatedExp = isDelete
      ? [...innerValue.experiences.splice(0, index), ...innerValue.experiences.splice(index + 1)]
      : [
          ...innerValue.experiences.splice(0, index),
          { ...exp, [key]: value },
          ...innerValue.experiences.splice(index + 1),
        ]
    setInnerValue({ ...innerValue, experiences: updatedExp })
  }

  const handleEditMode = () => {
    setIsEditing(!isEditing)
  }

  if (isLoading) {
    return <Spinner size="32px" thickness={2.5} />
  } else if (error) {
    Logger.error(`Failed to fetch experience list v2 ${error}`)
    return null
  }

  return (
    <InfoRow
      title={`${ruleSetIndex + 1}`}
      isEditable={true}
      isEditing={isEditing}
      onEditChange={handleEditMode}
      onAcceptChange={handleSubmit}
      onCancelChange={handleOnCancel}
      onDeleteChange={() => handleSubmit(true)}
      isEmpty={!ruleSet}
    >
      <Grid container spacing={1}>
        {isEditing && (
          <>
            <Grid item xs={6} md={3}>
              <Button
                onClick={() => setInnerValue({ ...innerValue, rules: [...innerValue.rules, getEmptyRule() as Rule] })}
                variant="outlined"
              >
                Add Rule
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                onClick={() =>
                  setInnerValue({
                    ...innerValue,
                    experiences: [...innerValue.experiences, getEmptyExp() as ExperienceAB],
                  })
                }
                variant="outlined"
              >
                Add Experience
              </Button>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Typography color={isEditing ? 'primary' : 'textSecondary'}>When..</Typography>
        </Grid>
        {innerValue.rules.map((rule, index) => (
          <Grid item xs={12} md={10}>
            {isEditing ? (
              <EditRule rule={rule} onChange={onRuleChange} index={index} />
            ) : (
              <ViewRule rule={rule} index={index} />
            )}
          </Grid>
        ))}
        <Grid item xs={12}>
          <Divider light />
          <Typography color={isEditing ? 'primary' : 'textSecondary'}>Trigger Experience..</Typography>
        </Grid>
        {innerValue.experiences.map((exp, index) => (
          <Grid item xs={12} md={10}>
            {isEditing ? (
              <EditExperienceAB experience={exp} onChange={onExperienceChange} allExperiences={data} />
            ) : (
              <ViewExp experience={exp} allExperiences={data} />
            )}
          </Grid>
        ))}
      </Grid>
    </InfoRow>
  )
}
