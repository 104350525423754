import { IconDefinitionDTO } from '@ketch-com/figurehead'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { renderIcon } from 'pages/orchestration/rightsQueue/listV2/components/RightsQueueListTable/components/CurrentActivityCellRenderer/utils/renderIcon'

export const GatewayCellRenderer = ({ name, icon }: { name?: string; icon?: IconDefinitionDTO }) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Box flexShrink={0}>{renderIcon(undefined, '#000', icon?.url)}</Box>
      <Typography variant="label">{name}</Typography>
    </Box>
  )
}
