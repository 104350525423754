import React, { useEffect, useMemo, useState } from 'react'
import { DataGrid, Spinner } from '@ketch-com/deck'
import {
  GridColDef,
  GridSortModel,
  GridPaginationModel,
  GridRowParams,
  useGridApiRef,
  GridEventListener,
} from '@mui/x-data-grid-premium'
import Box from '@mui/material/Box'

import { EmptyState } from '@ketch-com/deck'
import { useFiltersV3 } from 'utils/hooks'
import { RightsQueueListTableUtils } from './hooks/useRightsQueueListTableUtils'

import { useRightsQueueListDataGridColumns } from './hooks/useRightsQueueListTableDataGridColumns'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setNavigationSliceGoBack } from 'store/navigationSlice'
import { RoutesManager } from 'utils/routing/routesManager'

import { RightsQueueListTableColumnId } from './interfaces'
import {
  FetchRightInvocationsFilterKeys,
  FetchRightInvocationsOrderByKeys,
} from 'interfaces/rightInvocationsV2/FetchRightInvocationsFilterKeys'
import { useAppSelector } from 'store/hooks'
import { getPaginationState } from 'store/paginationPreservationSlice/selectors'
import { RIGHTS_QUEUE_PAGINATION_REDUX_KEY, setPage } from 'store/paginationPreservationSlice'

type Props = {
  rightsQueueListTableUtils: RightsQueueListTableUtils
}
const MENU_ITEMS = [5, 10, 15, 20, 25, 30, 40, 50, 75]
export const RightsQueueListTable: React.FC<Props> = ({ rightsQueueListTableUtils }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const tableContainerRef = React.useRef<HTMLDivElement>(null)
  const { filterCount, removeSingleSearchParam, reduxSearchParams } = useFiltersV3()
  const dispatch = useDispatch()
  const { pagination: reduxPaginationState } = useAppSelector(getPaginationState)
  const rightInvocationsColumns: GridColDef<any>[] = useRightsQueueListDataGridColumns()
  const { isLoading, isFetching, paginationHelpers, rightInvocations, handleRequestSort, order, orderBy } =
    rightsQueueListTableUtils

  const { isNextPending, isPrevPending, page, isPending } = paginationHelpers

  const filters = useFiltersV3()

  const gridTotalPages = paginationHelpers.totalPages

  const rowsPerPage = useMemo(
    () => (paginationHelpers.totalResults ? Math.ceil(paginationHelpers.totalResults / gridTotalPages) : 20),
    [paginationHelpers.totalResults, gridTotalPages],
  )

  const [sortModel, setSortModel] = useState<GridSortModel>([])

  useEffect(() => {
    setSortModel([
      {
        field: orderBy,
        sort: order,
      },
    ])
  }, [order, orderBy])

  const apiRef = useGridApiRef()

  const paginationModel = {
    page:
      typeof reduxPaginationState[RIGHTS_QUEUE_PAGINATION_REDUX_KEY] !== 'undefined'
        ? reduxPaginationState[RIGHTS_QUEUE_PAGINATION_REDUX_KEY]
        : paginationHelpers.page,
    pageSize: rowsPerPage,
  }

  React.useEffect(() => {
    const handleRowClick: GridEventListener<'columnHeaderClick'> = params => {
      if (
        params.field === FetchRightInvocationsFilterKeys.statuses ||
        params.field === FetchRightInvocationsOrderByKeys.invoked_at
      ) {
        handleRequestSort({} as React.MouseEvent<unknown>, params.field as RightsQueueListTableColumnId)
        // also reset page
        dispatch(
          setPage({
            key: RIGHTS_QUEUE_PAGINATION_REDUX_KEY,
            value: 0,
          }),
        )
      }
    }

    // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`.
    if (apiRef.current && apiRef.current.subscribeEvent) {
      return apiRef.current.subscribeEvent('columnHeaderClick', handleRowClick)
    }
  }, [apiRef, dispatch, handleRequestSort])

  const onPaginationModelChange = (model: GridPaginationModel) => {
    if (model.page === paginationModel.page) {
      // change how many items per page
      filters.replaceParams({ itemsPerPage: String(model.pageSize) })
    } else {
      // change page
      dispatch(
        setPage({
          key: RIGHTS_QUEUE_PAGINATION_REDUX_KEY,
          value: model.page,
        }),
      )
      paginationHelpers.onPageChange(model.page)
    }

    if (model.pageSize !== paginationModel.pageSize) {
      dispatch(
        setPage({
          key: RIGHTS_QUEUE_PAGINATION_REDUX_KEY,
          value: 0,
        }),
      )
    }
  }

  // scroll smoothly to the first result in table when page changes
  useEffect(() => {
    if (tableContainerRef.current) {
      // do not scroll if incoming page is pending
      if (!isNextPending && !isPrevPending && !isFetching && !isLoading) {
        tableContainerRef.current.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    }
  }, [page, isNextPending, isPrevPending, isFetching, isLoading])

  if (rightInvocations.length < 1 && isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  if (rightInvocations.length < 1 && !isLoading && !filterCount && !isNextPending && !isPrevPending) {
    if (!reduxSearchParams.query) {
      return (
        <Box
          sx={{
            padding: '50px 0',
            borderRadius: '11px',
            backgroundColor: 'white.main',
          }}
        >
          <EmptyState
            iconName="OClock"
            title="There are no requests in the queue"
            subTitle="The queue will populate as new requests are made."
          />
        </Box>
      )
    } else
      return (
        <Box
          sx={{
            padding: '50px 0',
            borderRadius: '11px',
            backgroundColor: 'white.main',
          }}
        >
          <EmptyState
            iconName="OFilter"
            title="No data matches the filter criteria"
            subTitle="Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try again."
            primaryButtonTitle="Reset to Defaults"
            primaryButtonProps={{
              onClick: () => {
                removeSingleSearchParam('query')
              },
              color: 'tertiary',
            }}
          />
        </Box>
      )
  }

  if (rightInvocations.length < 1 && !isLoading && !!filterCount && !isFetching && !isNextPending && !isPrevPending) {
    return (
      <Box
        sx={{
          padding: '50px 0',
          borderRadius: '11px',
          backgroundColor: 'white.main',
        }}
      >
        <EmptyState iconName="OFilter" title="No data matches the filter criteria" />
      </Box>
    )
  }

  if (rightInvocations.length < 1 && !isLoading && !!filterCount && isFetching) {
    return (
      <Box display="flex" alignItems="center">
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  return (
    <Box>
      <DataGrid
        sx={{
          border: 'unset',
          cursor: 'pointer',
          '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
            backgroundImage: 'unset',
            cursor: 'auto',
          },
          '& .MuiDataGrid-footerContainer': {
            minHeight: 'min-content',
            ' .MuiTablePagination-toolbar': {
              paddingLeft: 1,
              paddingRight: 1,
            },
          },
        }}
        autosizeOnMount
        autosizeOptions={{
          includeHeaders: true,
          includeOutliers: false,
          columns: rightInvocationsColumns.map(column => column.field),
          expand: true,
        }}
        getRowHeight={() => 'auto'}
        columns={rightInvocationsColumns}
        disableChildrenSorting
        disableAggregation
        disableRowGrouping
        disableColumnFilter
        disableMultipleColumnsFiltering
        getRowId={row => row.id}
        loading={isPending || isNextPending || isPrevPending}
        rows={rightInvocations}
        pagination
        apiRef={apiRef}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={MENU_ITEMS}
        rowCount={paginationHelpers.totalResults}
        ref={tableContainerRef}
        onRowClick={({ row }: { row: GridRowParams<RightInvocationDTO> }) => {
          if (row.id) {
            dispatch(setNavigationSliceGoBack(`${location.pathname}${location.search}`))
            navigate(RoutesManager.orchestration.rightsQueue.view.root.getURL({ id: String(row.id) }))
          }
        }}
        sortingMode="server"
        sortModel={sortModel}
        slotProps={{
          pagination: {
            showLastButton: true,
            showFirstButton: true,
            size: 'medium',
          },
        }}
      />
    </Box>
  )
}
