import React from 'react'
import { Box, Typography } from '@mui/material'
import Ketch from 'assets/icons/onboarding/ketch.png'

type SignUpCompletedProps = {
  isMobileLayout?: boolean
}

export const SignUpCompleted: React.FC<SignUpCompletedProps> = ({ isMobileLayout }) => {
  return (
    <Box display="flex" justifyContent="center" px={2}>
      <Box flexBasis={420} display="flex" flexDirection="column" gap={6}>
        <Box width={194} height={66}>
          <img src={Ketch} alt="KetchLogo" style={{ width: '100%', height: 'auto' }} />
        </Box>
        <Box display="flex" flexDirection="column" gap={4}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="h1">Account Created!</Typography>
            <Typography variant="body">Please check your email to sign in and start exploring!</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
