import React, { useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Text } from 'components/ui-kit/typography/Text'
import { Link } from 'components/ui-kit/link/Link'
import { fieldNames, ModalField } from '../../../utils/common'
import { Tabs } from 'components/ui-kit/tabs/Tabs'
import { useFormikContext } from 'formik'
import { VerificationOption } from './VerificationOption'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import {
  ExperienceSwitchRenderLogic,
  EXPERIENCE_SWITCH_RENDER_LOGIC_OPTIONS,
} from 'interfaces/experiences/experienceSwitchRenderLogic'
import { FormRadioGroup } from 'components/ui-kit/form/radioGroup/FormRadioGroup'
import { ConsentExperienceFormValues } from '../../../utils/consent/interfaces'
import { Checkbox } from 'components/ui-kit/checkbox/Checkbox'
import { Input } from 'components/ui-kit/input/Input'

const PREFIX = 'ConsentPurposesFields'

const classes = {
  tabs: `${PREFIX}-tabs`,
  addIcon: `${PREFIX}-addIcon`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`& .${classes.tabs}`]: {
    borderBottom: `1px solid ${palette.iron.main}`,
    marginBottom: spacing(1.5),
    width: '100%',
  },

  [`& .${classes.addIcon}`]: {
    fill: palette.royalBlue.main,
    fontSize: 20,
  },
}))

type Props = {
  active?: ModalField
}
export const ConsentPurposesFields: React.FC<Props> = ({ active }) => {
  const [activeTab, setActiveTab] = useState<'title' | 'purpose-details'>('title')
  const [activeCustomSwitchTextTab, setActiveCustomSwitchTextTab] = useState<'positive' | 'negative'>('positive')
  const { values, setFieldValue, validateField } = useFormikContext<ConsentExperienceFormValues>()

  const { data: orgLangs } = useOrganizationLanguages()

  const organizationLanguages = useMemo(() => orgLangs.filter(({ system }) => !system), [orgLangs])

  if (active !== ModalField.PREFERENCE_PURPOSES) return null

  return (
    <StyledBox>
      <Box display="flex" flexDirection="column" mb={3}>
        <Text size={14} mb={2} mt={1}>
          Define how Purposes should be displayed in this Experience.
        </Text>

        <Text size={14} mb={3}>
          If you would like to configure how purposes are grouped together, you can configure this through Stacks{' '}
          <Link variant="blue" to="/deployment/purpose-stacks">
            here
          </Link>
          .
        </Text>

        <Text size={20} weight={700}>
          Details
        </Text>
      </Box>

      <Tabs
        className={classes.tabs}
        tabSpacingOverride={2}
        config={[
          {
            key: 1,
            title: 'Title',
            onClick: () => setActiveTab('title'),
            active: activeTab === 'title',
          },
          {
            key: 2,
            title: 'Purpose Details',
            onClick: () => setActiveTab('purpose-details'),
            active: activeTab === 'purpose-details',
          },
        ]}
      />

      {/* Title Tab */}

      {activeTab === 'title' ? (
        <>
          <VerificationOption
            title="Title"
            value={!values?.modal?.hideConsentTitle}
            // onChange={e => setFieldValue('modal.hideConsentTitle', !e.target.checked)}
            onChange={e => {
              setFieldValue('modal.hideConsentTitle', !e.target.checked)
              /* If switch is toggled "off" then wipe inputs that are no longer required */
              if (!e.target.checked) {
                setFieldValue('modal.isCustomTextSwitchOn', false)
                setFieldValue('modal.consentTitle', '')
                organizationLanguages?.forEach?.(({ language }) => {
                  setFieldValue(fieldNames.consent.modal.consentTitle.translation(language.code), '')
                })
                setTimeout(() => {
                  validateField('modal')
                }, 500)
              }
            }}
            sx={{
              mb: 2,
              mt: 1.5,
              borderBottom: ({ palette }) =>
                !values?.modal?.hideConsentTitle ? 'none' : `1px solid ${palette.iron.main}`,
              borderRadius: ({ spacing }) => (!values?.modal?.hideConsentTitle ? spacing(1.375) : 0),
            }}
            requisiteForm={
              <Box display="flex" flexDirection="column" px={2} mb={2.5}>
                <Box display="flex" alignItems="center" mb={1}>
                  <Text size={14} weight={600} mr={0.5}>
                    Display Text
                  </Text>

                  <Text size={14} weight={600} color="darkDuskFaded">
                    (Default)
                  </Text>
                </Box>
                <Input fullWidth disabled={true} value="Purposes" />
                <Text size={14} color="darkDuskFaded" mt={1}>
                  The default text has translations for all available languages.
                </Text>
              </Box>
            }
          />

          <VerificationOption
            shouldHide={!!values?.modal?.hideConsentTitle}
            title="Custom Text"
            value={!!values?.modal?.isCustomTextSwitchOn}
            onChange={e => {
              setFieldValue('modal.isCustomTextSwitchOn', e.target.checked)
              /* If switch is toggled "off", then wipe required inputs */
              if (!e.target.checked) {
                setFieldValue('modal.consentTitle', '')
                organizationLanguages?.forEach?.(({ language }) => {
                  setFieldValue(fieldNames.consent.modal.consentTitle.translation(language.code), '')
                })
                setTimeout(() => {
                  validateField('modal')
                }, 500)
              }
            }}
            sx={{
              mb: 2,
              borderBottom: ({ palette }) =>
                !!values?.modal?.isCustomTextSwitchOn ? 'none' : `1px solid ${palette.iron.main}`,
              borderRadius: ({ spacing }) => (!!values?.modal?.isCustomTextSwitchOn ? spacing(1.375) : 0),
            }}
            requisiteForm={
              <Box display="flex" flexDirection="column" px={2}>
                <FormInput
                  label="Display Text"
                  shouldShowOptional
                  optionalOverrideText="Custom"
                  fullWidth
                  placeholder="Example: Purposes"
                  name="modal.consentTitle"
                  hint="Add your custom text and provide translations."
                  sx={{
                    mb: 5,
                  }}
                />

                <Text size={16} weight={800} color="lightGrey" mb={3}>
                  Translations
                </Text>

                {organizationLanguages.map(({ language, enabled }) => (
                  <FormRow key={language.code} marginBottom={24}>
                    <FormInput
                      fullWidth
                      required={false}
                      name={fieldNames.consent.modal.consentTitle.translation(language.code)}
                      label={`Display Text (${language.englishName})`}
                    />
                  </FormRow>
                ))}
              </Box>
            }
          />
        </>
      ) : null}

      {/* Purpose Details Tab */}

      {activeTab === 'purpose-details' ? (
        <>
          <Box display="flex" flexDirection="column" mb={3}>
            <Text size={16} weight={700} mb={1} mt={1}>
              Legal Basis
            </Text>

            <Text size={14} color="darkDuskFaded" mb={2}>
              A legal basis is your justification for lawfully processing personal data.
            </Text>

            <Checkbox
              title="Show Legal Bases"
              value={!values?.modal?.hideLegalBases}
              onChange={(e, checked) => {
                setFieldValue('modal.hideLegalBases', !e.target.checked)
              }}
            />
          </Box>

          <Box display="flex" flexDirection="column" mb={3}>
            <Text size={16} weight={700} mb={1} mt={1}>
              Switch Text Rendering
            </Text>

            <Text size={14} color="darkDuskFaded" mb={2}>
              Switcher text that appears to the right of consent toggles
            </Text>

            <FormRadioGroup
              name="modal.switchTextRenderLogic"
              isInt
              layout="column"
              valueKey="id"
              size="small"
              renderLabel={({ name }) => name}
              options={EXPERIENCE_SWITCH_RENDER_LOGIC_OPTIONS}
              onChange={(e, v) => {
                /* Per Product direction when "never render" is selected the "Custom Switch Text" should be disabled, so, 
                we reset all the values that no longer apply after "never render" is selected */
                if (v === ExperienceSwitchRenderLogic.RENDER_NEVER) {
                  setFieldValue('modal.isCustomSwitchTextSwitchOn', false)
                  setFieldValue('modal.switchOnText', '')
                  setFieldValue('modal.switchOffText', '')
                  organizationLanguages?.forEach?.(({ language }) => {
                    setFieldValue(fieldNames.consent.modal.switchOffText.translation(language.code), '')
                  })
                  organizationLanguages?.forEach?.(({ language }) => {
                    setFieldValue(fieldNames.consent.modal.switchOnText.translation(language.code), '')
                  })
                  setTimeout(() => {
                    validateField('modal')
                  }, 500)
                }
              }}
            />
          </Box>

          {/* Custom Switch Text Inputs */}

          <VerificationOption
            title="Custom Switch Text"
            disabled={values?.modal?.switchTextRenderLogic === ExperienceSwitchRenderLogic.RENDER_NEVER}
            value={!!values?.modal?.isCustomSwitchTextSwitchOn}
            tooltipContent={
              values?.modal?.switchTextRenderLogic === ExperienceSwitchRenderLogic.RENDER_NEVER ? (
                <Box display="flex" flexDirection="column" gap={2} px={0.5} py={1} sx={{ maxWidth: 180 }}>
                  <Text size={12} lineHeight="17px">
                    To enable "Custom Text", select in the <strong>Switch Text Rendering</strong> section either "Always
                    Renders" or "Renders only if ..."
                  </Text>
                </Box>
              ) : null
            }
            onChange={e => {
              setFieldValue('modal.isCustomSwitchTextSwitchOn', e.target.checked)
              /* If switch is toggled "off", then wipe required inputs */
              if (!e.target.checked) {
                setFieldValue('modal.switchOnText', '')
                setFieldValue('modal.switchOffText', '')
                organizationLanguages?.forEach?.(({ language }) => {
                  setFieldValue(fieldNames.consent.modal.switchOffText.translation(language.code), '')
                })
                organizationLanguages?.forEach?.(({ language }) => {
                  setFieldValue(fieldNames.consent.modal.switchOnText.translation(language.code), '')
                })
                setTimeout(() => {
                  validateField('modal')
                }, 500)
              }
            }}
            sx={{
              mb: 2,
              borderBottom: ({ palette }) =>
                !!values?.modal?.isCustomSwitchTextSwitchOn ? 'none' : `1px solid ${palette.iron.main}`,
              borderRadius: ({ spacing }) => (!!values?.modal?.isCustomSwitchTextSwitchOn ? spacing(1.375) : 0),
            }}
            requisiteForm={
              <Box display="flex" flexDirection="column" px={2}>
                <Tabs
                  className={classes.tabs}
                  tabSpacingOverride={2}
                  config={[
                    {
                      key: 1,
                      title: 'Positive',
                      onClick: () => setActiveCustomSwitchTextTab('positive'),
                      active: activeCustomSwitchTextTab === 'positive',
                    },
                    {
                      key: 2,
                      title: 'Negative',
                      onClick: () => setActiveCustomSwitchTextTab('negative'),
                      active: activeCustomSwitchTextTab === 'negative',
                    },
                  ]}
                />

                {/* Positive Text Input and Translations */}

                {activeCustomSwitchTextTab === 'positive' ? (
                  <>
                    <FormInput
                      label="Display Text"
                      required
                      fullWidth
                      placeholder="Example: Opt-in"
                      name="modal.switchOnText"
                      hint="Add your custom text and provide translations."
                      sx={{
                        mb: 5,
                      }}
                    />

                    <Text size={16} weight={800} color="lightGrey" mb={3}>
                      Translations
                    </Text>

                    {organizationLanguages.map(({ language, enabled }) => (
                      <FormRow key={language.code} marginBottom={24}>
                        <FormInput
                          fullWidth
                          required={false}
                          name={fieldNames.consent.modal.switchOnText.translation(language.code)}
                          label={`Display Text (${language.englishName})`}
                        />
                      </FormRow>
                    ))}
                  </>
                ) : null}

                {/* Negative Text Input and Translations */}

                {activeCustomSwitchTextTab === 'negative' ? (
                  <>
                    <FormInput
                      label="Display Text"
                      required
                      fullWidth
                      placeholder="Example: Opt-out"
                      name="modal.switchOffText"
                      hint="Add your custom text and provide translations."
                      sx={{
                        mb: 5,
                      }}
                    />

                    <Text size={16} weight={800} color="lightGrey" mb={3}>
                      Translations
                    </Text>

                    {organizationLanguages.map(({ language, enabled }) => (
                      <FormRow key={language.code} marginBottom={24}>
                        <FormInput
                          fullWidth
                          required={false}
                          name={fieldNames.consent.modal.switchOffText.translation(language.code)}
                          label={`Display Text (${language.englishName})`}
                        />
                      </FormRow>
                    ))}
                  </>
                ) : null}
              </Box>
            }
          />
        </>
      ) : null}
    </StyledBox>
  )
}
