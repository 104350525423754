import { StepDetailsDTO } from '@ketch-com/figurehead'
import { WorkflowActivityCategory } from 'interfaces/workflowActivities/workflowActivity'

function parseVariableValue(ctxVarValue?: string | number | boolean): any {
  if (typeof ctxVarValue === 'string') {
    return ctxVarValue
  } else if (typeof ctxVarValue === 'boolean') {
    return ctxVarValue ? 'true' : 'false'
  } else if (typeof ctxVarValue === 'number') {
    return ctxVarValue.toString()
  } else {
    return ''
  }
}

export const getStepDetailsTileWithEditModeInitialValues = ({ stepDetails }: { stepDetails?: StepDetailsDTO }) => {
  const { dataSubjectContext = [] } = stepDetails || {}

  const dataSubjectContextInitialValues = dataSubjectContext
    ?.filter(
      variable =>
        (stepDetails?.activityCategory === WorkflowActivityCategory.GATEWAY ? true : !variable?.isConfiguredForStep) &&
        !variable?.isAppealValue,
    )
    ?.map(variable => {
      return {
        ...variable,
        value: parseVariableValue(variable.value),
      }
    })

  const payload = {
    dataSubjectVariables: dataSubjectContextInitialValues,
  }

  return payload
}

export type StepDetailsTileWithEditModeInitialValues = ReturnType<typeof getStepDetailsTileWithEditModeInitialValues>
