import { theme } from '@ketch-com/deck'

export type ColorMapping = { textColor: string; backgroundColor: string }

export const colorMapping: ColorMapping[] = [
  { textColor: theme.palette.label1.main, backgroundColor: theme.palette.labelText1.main },
  { textColor: theme.palette.label2.main, backgroundColor: theme.palette.labelText2.main },
  { textColor: theme.palette.label3.main, backgroundColor: theme.palette.labelText3.main },
  { textColor: theme.palette.label4.main, backgroundColor: theme.palette.labelText4.main },
  { textColor: theme.palette.label5.main, backgroundColor: theme.palette.labelText5.main },
  { textColor: theme.palette.label6.main, backgroundColor: theme.palette.labelText6.main },
  { textColor: theme.palette.label7.main, backgroundColor: theme.palette.labelText7.main },
  { textColor: theme.palette.label8.main, backgroundColor: theme.palette.labelText8.main },
  { textColor: theme.palette.label9.main, backgroundColor: theme.palette.labelText9.main },
  { textColor: theme.palette.label10.main, backgroundColor: theme.palette.labelText10.main },
  { textColor: theme.palette.label11.main, backgroundColor: theme.palette.labelText11.main },
  { textColor: theme.palette.label12.main, backgroundColor: theme.palette.labelText12.main },
  { textColor: theme.palette.label13.main, backgroundColor: theme.palette.labelText13.main },
  { textColor: theme.palette.label14.main, backgroundColor: theme.palette.labelText14.main },
  { textColor: theme.palette.label15.main, backgroundColor: theme.palette.labelText15.main },
]

export const getTextColorForTile = (backgroundColor?: string): string | undefined =>
  colorMapping.find(color => color.backgroundColor === backgroundColor)?.textColor
