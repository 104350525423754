import { createContext } from 'react'

import { MaybeNull } from 'interfaces'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { ICreateStepParams } from 'pages/orchestration/workflows/edit/utils/steps/createStep'
import { ICreatePlaceholder } from 'pages/orchestration/workflows/edit/utils/steps/createPlaceholder'
import { IUpdateStepParams } from 'pages/orchestration/workflows/edit/utils/steps/updateStep'
import { IRemoveStepParams } from 'pages/orchestration/workflows/edit/utils/steps/removeStep'
import { IReplaceStepParams } from 'pages/orchestration/workflows/edit/utils/steps/replaceStep'
import { IPasteStepsParams } from '../utils/steps/pasteSteps'

export interface IChangeActiveStep {
  step: ICanvasStep
  connectedNestStartStep?: ICanvasStep
}

interface IContextProps {
  activeStep: MaybeNull<ICanvasStep>
  activeDragStep: MaybeNull<ICanvasStep>
  dropCoordinates: {
    x?: number
    y?: number
  }
  handleCreateStep: (params: ICreateStepParams) => void
  handleUpdateStep: (params: IUpdateStepParams) => void
  handleReplaceStep: (params: IReplaceStepParams) => void
  handlePasteSteps: (params: IPasteStepsParams) => void
  handleRemoveStep: (params: IRemoveStepParams) => void
  handleCreatePlaceholder: (params: ICreatePlaceholder) => void
  handleChangeActiveStep: (params: IChangeActiveStep) => void
  handleUndo: () => void
  handleRedo: () => void
  canUndo: boolean
  canRedo: boolean
}

export const CanvasContext = createContext<Partial<IContextProps>>({})
