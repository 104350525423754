import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces/common'
import { ContentGroup, Spinner, UpsertLayout, UpsertLayoutMode } from '@ketch-com/deck'
import { getTransponderFormValidation, getTransponderFormValues } from './utils'
import { TransponderFormDetails } from './components'
import { TransponderFormValues } from 'interfaces/appliances'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'
import { Appliance } from '@ketch-com/windlass/dist/appliance/appliances'

type Props = {
  isReady: boolean
  isEditMode: boolean
  appliance: MaybeNull<Appliance>
  onSubmit: (values: TransponderFormValues) => Promise<void>
}

export const TranspondersUpsertView: React.FC<Props> = ({ isReady, isEditMode = true, appliance, onSubmit }) => {
  const navigate = useNavigate()

  const initialValues = useMemo(() => getTransponderFormValues({ appliance }), [appliance])
  const validationSchema = useMemo(getTransponderFormValidation, [])

  const breadcrumbs = [
    { title: 'Developers', link: RoutesManager.developers.transponders.list.getURL() },
    { title: 'Transponders', link: RoutesManager.developers.transponders.list.getURL() },
    {
      title: appliance?.name || '',
      link: RoutesManager.developers.transponders.detail.getURL({ id: appliance?.applianceID }),
    },
    { title: 'Edit' },
  ]

  const formikProps = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
  })

  const { isSubmitting, submitForm } = formikProps

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={isEditMode ? UpsertLayoutMode.edit : UpsertLayoutMode.create}
          renderFormTitle={() => (
            <ContentGroup title={isEditMode ? 'Edit Transponder' : 'Create Transponder'} titleVariant="h2" isDivider />
          )}
          onAccept={submitForm}
          onCancel={() => {
            navigate(RoutesManager.developers.transponders.detail.getURL({ id: appliance?.applianceID }))
          }}
          acceptActionButtonText="Save"
          cancelActionButtonText="Cancel"
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <form autoComplete="off">
            {isReady ? (
              <>
                <TransponderFormDetails />
              </>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%" m={6}>
                <Spinner size="32px" thickness={2.5} />
              </Box>
            )}
          </form>
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
