import { APIRequestParams } from 'api/common/utils'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans/deploymentPlan'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  deploymentPlanCode: string
  organizationCode: string
  includeIssues?: boolean
}>

type Response = { deployment: DeploymentPlanDTO }

export const fetchDeploymentPlan = ({ organizationCode, includeMetadata, includeIssues, deploymentPlanCode }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/deployments/${deploymentPlanCode}`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
        },
      },
    }),
  )
