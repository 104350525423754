import { PreferenceCenterPage } from '@ketch-sdk/ketch-types'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { useThemes } from 'api/themes-v3/queries/useThemes'
import { ConsentExperienceType } from 'interfaces/experiences-v2/consentExperienceType'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIsPermitted } from 'utils/hooks'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { PolicyScopeDTO } from 'interfaces/policyScopes-v2/policyScope'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { PreviewDisplayMode } from '../../../utils/enums'
import { withDefaultFirst } from '.'
import { ThemeType } from 'pages/consentAndRights/themes-v3/upsert/utils/enums'

export const useExperiencePreviewUtils = () => {
  // Utils
  const location = useLocation()
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()

  // Dropdown options - Experience
  const [languageOptions, setLanguageOptions] = useState<LanguageWithStatusDTO[]>()
  const [jurisdictionOptions, setJurisdictionOptions] = useState<PolicyScopeDTO[]>()
  const [themeOptions, setThemeOptions] = useState<ThemeV3DTO[]>()

  // Dropdown options - Theme
  const [themeType, setThemeType] = useState<ThemeType>(ThemeType.Banner)

  // Preview data
  const { isLoading: isLanguagesLoading } = useOrganizationLanguages({
    onSuccess: ({ data: { languages } }) => {
      setLanguageOptions([
        { language: { englishName: 'English' }, enabled: true } as LanguageWithStatusDTO,
        ...(languages?.length ? languages : []),
      ])
    },
  })
  const { isLoading: isThemesLoading } = useThemes({
    onSuccess: ({ data: { themes } }) => {
      setThemeOptions(withDefaultFirst(themes, 'id'))
    },
  })
  const { isLoading: isJurisdictionsLoading } = usePolicyScopes({
    onSuccess: ({ data: { policyScopes } }) => {
      setJurisdictionOptions(withDefaultFirst(policyScopes ?? [], 'code'))
    },
  })

  // Preview state
  const [consentExperienceType, setConsentExperienceType] = useState<ConsentExperienceType>(
    ConsentExperienceType.Banner,
  )
  const [displayMode, setDisplayMode] = useState<PreviewDisplayMode>(PreviewDisplayMode.Desktop)
  const [preferenceCenterPage, setPreferenceCenterPage] = useState<PreferenceCenterPage>(PreferenceCenterPage.Welcome)
  const [language, setLanguage] = useState<LanguageWithStatusDTO>()
  const [jurisdiction, setJurisdiction] = useState<PolicyScopeDTO>()
  const [theme, setTheme] = useState<ThemeV3DTO>()
  const [isPreviewReady, setIsPreviewReady] = useState(false)
  const isReady = !isLanguagesLoading && !isJurisdictionsLoading && !isThemesLoading

  // TODO:JB - Versions
  // const { data: experienceVersion, isLoading: isExperienceVersionLoading } = useExperienceVersion({
  //   enabled: !!version,
  //   params: {
  //     experienceCode: code!,
  //     version: version!,
  //   },
  //   onError: handleRedirectToExperiencesList,
  // })

  // TODO:JB - Versions
  // const { data: versions, isLoading: isVersionsLoading } = useExperienceVersions({
  //   params: {
  //     experienceCode: code!,
  //     includeMetadata: true,
  //     ordering: {
  //       version: 'desc',
  //     },
  //   },
  // })

  return {
    location,
    navigate,
    isPermitted,
    isReady,
    consentExperienceType,
    setConsentExperienceType,
    displayMode,
    setDisplayMode,
    preferenceCenterPage,
    setPreferenceCenterPage,
    isLanguagesLoading,
    languageOptions,
    language,
    setLanguage,
    isJurisdictionsLoading,
    jurisdictionOptions,
    jurisdiction,
    setJurisdiction,
    isThemesLoading,
    themeOptions,
    theme,
    setTheme,
    themeType,
    setThemeType,
    isPreviewReady,
    setIsPreviewReady,
  }
}

export type ExperiencePreviewUtils = ReturnType<typeof useExperiencePreviewUtils>
