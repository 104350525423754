import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from './useIsEntitled'
import { useEffect, useState } from 'react'

/**
 * Hook to determine if the user is on a demo account
 */
export const useIsDemoOrg = () => {
  const [isDemoOrg, setIsDemoOrg] = useState(false)
  const { isEntitled, isLoadingEntitlements } = useIsEntitled()

  useEffect(() => {
    if (!isLoadingEntitlements) {
      setIsDemoOrg(isEntitled(ENTITLEMENTS.DEMO))
    }
  }, [isEntitled, isLoadingEntitlements])

  return { isDemoOrg }
}
