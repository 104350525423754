import React from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Box } from '@mui/material'

import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import {
  OptionalFormSectionAddTile,
  PurposesFormSection,
  AllowancesFormSection,
  EffectsFormSection,
  FiltersFormSection,
} from './components'
import { useCreatePolicyFormUtils } from './hooks'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { MaybeNull } from 'interfaces/common'

const PREFIX = 'CreatePolicyForm'

const classes = {
  greyContainer: `${PREFIX}-greyContainer`,
}

const StyledFormSection = styled(FormSection)(({ theme: { palette, spacing } }) => ({
  [`& .${classes.greyContainer}`]: {
    padding: spacing(3),
    backgroundColor: palette.bone.main,
    borderRadius: spacing(1.5),
    display: 'flex',
    flexDirection: 'row',
  },
}))

type Props = {
  policy?: any
  purposes: MaybeNull<PurposeDTO[]>
}

export const CreatePolicyForm: React.FC<Props> = ({ purposes }) => {
  const _ = useCreatePolicyFormUtils()

  return (
    <StyledFormSection title="Policy Builder" subTitle="Use the builder below to create a new policy.">
      <FormRow>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <FormInput required fullWidth name="name" label="Policy Name" placeholder="e.g. Customer Data Policy" />
          </Grid>
          <Grid item xs={9}>
            <FormInput
              required
              fullWidth
              name="description"
              label="Description"
              placeholder="e.g. Overarching policy applicable to all customer data."
            />
          </Grid>
        </Grid>
      </FormRow>

      {/* Grey Box Input Section */}

      <Box className={classes.greyContainer}>
        {/* Column 1  */}

        <Box pr={2} display="flex" flexDirection="column" flex={10}>
          <PurposesFormSection purposes={purposes} />

          <AllowancesFormSection
            onAddAccessAllowance={_.handleAddAccessAllowance}
            onRemoveAccessAllowance={_.handleRemoveAccessAllowance}
          />

          {_.values.attributeEffects.length > 0 && (
            <EffectsFormSection
              onAddAttributeEffect={_.handleAddAttributeEffect}
              onRemoveAttributeEffect={_.handleRemoveAttributeEffect}
              onRemoveEffectsFormSection={_.handleRemoveEffectsFormSection}
            />
          )}

          {_.values.filters.length > 0 && (
            <FiltersFormSection
              onAddFilter={_.handleAddFilter}
              onRemoveFilter={_.handleRemoveFilter}
              onRemoveFiltersFormSection={_.handleRemoveFiltersFormSection}
            />
          )}
        </Box>

        {/* Column 2 */}

        <Box pl={1} display="flex" flexDirection="column" flex={2}>
          {_.values.attributeEffects.length === 0 && (
            <OptionalFormSectionAddTile
              onAdd={_.handleAddPolicyModifier}
              title="Effects"
              description="With the following attribute effects"
              marginBottom
            />
          )}
          {_.values.filters.length === 0 && (
            <OptionalFormSectionAddTile
              onAdd={_.handleAddPolicyModifier}
              title="Filters"
              description="Add the following row filters"
            />
          )}
        </Box>
      </Box>
    </StyledFormSection>
  )
}
