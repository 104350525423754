import React from 'react'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import {
  BuilderTextInputMode,
  FormTextInput,
} from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'
import { Box, Typography } from '@mui/material'
import { Button, Icon, theme } from '@ketch-com/deck'
import { getIn, useFormikContext } from 'formik'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'

export const PreferenceThemeSubscriptionsTabUnsubscribeAllForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ThemeV3DTO>()

  // Copy the primary button theme to the secondary or tertiary one
  const handleApplyPurposesStyleClick = () => {
    const purposesSwitchButtonTheme = getIn(values, themeUpsertFormKeys.preferencePurposesTabListSwitchButtons)
    const copyTo = themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchButton
    setFieldValue(copyTo, purposesSwitchButtonTheme)
  }

  return (
    <>
      {/* Whole element form */}
      <SidebarFormGroup sx={{ paddingTop: 0 }} hasBorderBottom>
        <FormColorInput
          name={themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllBackgroundColor}
          label={'Fill Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllTextColor}
          label={'Text Color'}
          fullWidth
          required
        />
        <FormTextInput
          name={themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllCornerRadius}
          label={'Corner Radius (px)'}
          mode={BuilderTextInputMode.CornerRadius}
          inputWidth="92px"
        />
      </SidebarFormGroup>

      {/* Switch button form */}
      <SidebarFormGroup title="Switch Button">
        <FormColorInput
          name={themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchButtonOnBackgroundColor}
          label={'Switch Button ON Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchButtonOnTextColor}
          label={'Switch Button ON Text Label Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchButtonOffBackgroundColor}
          label={'Switch Button OFF Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchButtonOffTextColor}
          label={'Switch Button OFF Text Label Color'}
          fullWidth
          required
        />

        {/* Apply style from purposes */}
        <Box>
          <Button
            color={'tertiary'}
            endIcon={<Icon name={'OPaintbrush'} iconColor={theme.palette.sphere.main} />}
            fullWidth={false}
            onClick={handleApplyPurposesStyleClick}
          >
            Apply Style from Purposes
          </Button>
          <Typography component={'p'} variant={'smallBody'} color={theme.palette.darkDuskFaded.main} mt={'6px'}>
            Click here to apply the switch button styles as defined on the Purposes page.
          </Typography>
        </Box>
      </SidebarFormGroup>
    </>
  )
}
