import { APIListRequestParams } from 'api/common/utils'
import { FindRelationshipsResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  start?: number
  limit?: number
  type?: number
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchRelationshipsPaginatedV2 = ({ start, limit, type, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/relationships`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/relationships`

  return API.get<FindRelationshipsResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          start,
          limit,
          type,
        },
      },
    }),
  )
}
