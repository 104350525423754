import { ApplianceConnectionStatusName } from 'interfaces/appliances/ApplianceConnectionStatus'
import { createUseFilters } from 'utils/hooks/createUseFilters'

export const useAppliancesListFilters = createUseFilters<{
  search: string
  status: ApplianceConnectionStatusName
}>({
  search: '',
  status: 'Status',
})
