import React from 'react'
import { useSetState } from 'react-use'
import { useNavigate } from 'react-router-dom'
import { Button, TooltipButton } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { MaybeNull } from 'interfaces'
import { WorkflowDTO, WorkflowSummaryDTO } from 'interfaces/workflows'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { RoutesManager } from 'utils/routing/routesManager'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'
import { DeleteWorkflowModal } from 'components/modals/workflows/DeleteWorkflowModal'

type Props = {
  isLatest: boolean
  workflow: WorkflowDTO
  workflowSummary: MaybeNull<WorkflowSummaryDTO>
}

export const WorkflowViewActions: React.FC<Props> = ({ isLatest, workflow, workflowSummary }) => {
  const navigate = useNavigate()

  const { isPermitted } = useIsPermitted()
  const isPermittedToCreateWorkflow = isPermitted([PERMISSIONS.WORKFLOW_WRITE])
  const isPermittedToMapWorkflowToEvent = isPermitted([PERMISSIONS.WORKFLOW_ASSIGN])

  const [{ isDeleteOpen, isEditOpen }, setState] = useSetState({
    isDeleteOpen: false,
    isEditOpen: false,
  })

  if (!isLatest) {
    return null
  }

  const { code } = workflow
  const isInSensitiveStatus = !!workflowSummary?.isActive
  const updatedAt = workflowSummary?.updatedAt

  const handleEditClick = () => {
    if (isInSensitiveStatus) {
      setState({ isEditOpen: true })
    } else {
      navigate(RoutesManager.orchestration.workflows.upsert.root.getURL({ code }))
    }
  }

  return (
    <>
      <Box display="flex" gap={1.5} alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography color="darkDuskFaded.main" variant="smallBody">
            Last Updated
          </Typography>
          <Typography color="darkDusk.main" variant="smallBody">
            <FormattedDateRenderer date={updatedAt} />
          </Typography>
        </Box>

        {isPermittedToCreateWorkflow && (
          <TooltipButton
            title={isInSensitiveStatus ? 'Cannot delete workflows while enabled' : undefined}
            disabled={isInSensitiveStatus}
            size="large"
            color="tertiary"
            onClick={() => setState({ isDeleteOpen: true })}
          >
            Delete
          </TooltipButton>
        )}

        {isPermittedToCreateWorkflow && (
          <Button onClick={handleEditClick} color="secondary" size="large">
            Edit
          </Button>
        )}

        <TooltipButton
          disabled={!isPermittedToMapWorkflowToEvent}
          title={
            !isPermittedToMapWorkflowToEvent
              ? 'You do not have sufficient permissions to perform this action, please contact your admin'
              : undefined
          }
          onClick={() => navigate(RoutesManager.orchestration.events.root.getURL())}
          color="primary"
          size="large"
        >
          Manage Assignments
        </TooltipButton>
      </Box>

      {isEditOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.WORKFLOW}
          onSubmit={() => {
            navigate(RoutesManager.orchestration.workflows.upsert.root.getURL({ code }))
          }}
          onCancel={() => setState({ isEditOpen: false })}
        />
      )}

      {isDeleteOpen && (
        <DeleteWorkflowModal
          workflowCode={code}
          onSubmit={() => navigate(RoutesManager.orchestration.workflows.root.getURL())}
          onCancel={() => setState({ isDeleteOpen: false })}
        />
      )}
    </>
  )
}
