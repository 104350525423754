import React from 'react'
import { Box } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import { Text } from 'components/ui-kit/typography/Text'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { InfoRow } from './InfoRow'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { Chip } from 'components/ui-kit/chip/Chip'
import { usePurpose } from 'api/purposes/queries/usePurpose'
import { PURPOSE_DATA_USAGE_DURATION_UNIT_NAMES } from 'interfaces/purposes/dataUsageDuration'
import { useLegalBases } from 'api/legalBases/queries/useLegalBases'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { useLegalBasesInfoRowData } from '../hooks'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Badge } from 'components/ui-kit/badge/Badge'
import { PURPOSE_DATA_ROLE_NAMES } from 'interfaces/purposes/purposeDataRole'
import { Status } from 'components/ui-kit/status/Status'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      status: {
        padding: spacing(0),
      },
      baseModalContent: {
        paddingTop: spacing(0),
      },
    }),
  { name: 'PurposeOverviewModal' },
)

type Props = {
  purposeCode?: string
  onCancel?: () => void
}

export const PurposeOverviewModal: React.FC<Props> = ({ onCancel, purposeCode }) => {
  const classes = useStyles()

  const { data: purpose, isLoading: isLoadingPurpose } = usePurpose({
    enabled: !!purposeCode,
    params: {
      purposeCode: purposeCode || '',
    },
    onError: () => {
      showToast({ content: 'Failed to fetch purpose', type: 'error' })
      onCancel?.()
    },
  })
  const { data: legalBases, isLoading: isLegalBasesLoading } = useLegalBases()
  const { data: jurisdictions, isLoading: isLoadingJurisdictions } = usePolicyScopes()

  const aggregateIsLoading = isLoadingPurpose || isLegalBasesLoading || isLoadingJurisdictions

  const legalBasesInfoRowData = useLegalBasesInfoRowData({
    purposeLegalBases: purpose?.legalBases,
    jurisdictions,
    legalBases,
  })

  return (
    <Dialog
      baseModalContentClassName={classes.baseModalContent}
      title={purpose?.name}
      isLoading={aggregateIsLoading}
      subtitle={
        <Box display="flex" alignItems="center" gap={1} pb={5}>
          {purpose?.dataRole ? <Badge variant="neutral">{PURPOSE_DATA_ROLE_NAMES?.[purpose.dataRole]}</Badge> : null}

          {purpose?.enabled ? (
            <Status size="big" variant="active">
              Enabled
            </Status>
          ) : (
            <Status variant="inactive">Disabled</Status>
          )}

          <Box display="flex" alignItems="center">
            <Text size={10} color="darkChrome" weight={700} transform="uppercase" letterSpacing="0.15em" mr={0.5}>
              Code
            </Text>

            <Text size={11} weight={500} color="grey">
              {purpose?.code}
            </Text>
          </Box>
        </Box>
      }
      contentWidth={968}
      submitBtnLabel="Delete"
      cancelBtnLabel="Close"
      onCancelBtnClick={onCancel}
      withTitleBorderBottom={true}
    >
      <Box>
        <InfoRow
          title="Description"
          hasBorderBottom
          info={
            purpose?.description ? (
              <Text size={14}>{purpose?.description}</Text>
            ) : (
              <EmptyValueRenderer color="darkDuskFaded" />
            )
          }
        />

        <InfoRow
          title="Description"
          hasBorderBottom
          info={
            purpose?.dataUsageDuration ? (
              <Box display="flex" alignItems="center">
                <Text size={14} weight={600} mr={0.5}>
                  {`${purpose?.dataUsageDuration} ${
                    PURPOSE_DATA_USAGE_DURATION_UNIT_NAMES[purpose?.dataUsageDurationUnit]
                  }`}
                </Text>
              </Box>
            ) : (
              <EmptyValueRenderer color="darkDuskFaded" />
            )
          }
        />

        <InfoRow
          title="Classifications"
          titleAddendum={
            <Text ml={0.5} size={14} weight={600} color="grey">
              {purpose?.dataCategories?.length || 0}
            </Text>
          }
          hasBorderBottom
          info={
            purpose?.dataCategories?.length ? (
              <Box display="flex" alignItems="center" gap={1}>
                {purpose?.dataCategories.map(dataCategory => {
                  return <Chip key={dataCategory?.code}>{dataCategory?.name}</Chip>
                })}
              </Box>
            ) : (
              <EmptyValueRenderer color="darkDuskFaded" />
            )
          }
        />

        <InfoRow
          title="Business Functions"
          titleAddendum={
            <Text ml={0.5} size={14} weight={600} color="grey">
              {purpose?.businessFunctions?.length || 0}
            </Text>
          }
          hasBorderBottom
          info={
            purpose?.businessFunctions?.length ? (
              <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
                {purpose?.businessFunctions.map(businessFunction => {
                  return <Chip key={businessFunction?.code}>{businessFunction?.name}</Chip>
                })}
              </Box>
            ) : (
              <EmptyValueRenderer color="darkDuskFaded" />
            )
          }
        />

        <InfoRow
          title="Legal Bases"
          hasBorderBottom
          info={
            !!legalBasesInfoRowData?.length ? (
              <Box display="flex" flexDirection="column" flex={1}>
                {/* Header Row */}

                <Box display="flex" alignItems="center" flex={1} mb={2}>
                  {/* Jurisdiction Column */}

                  <Box display="flex" alignItems="center" flex={1}>
                    <Text size={12} weight={600} color="darkDuskFaded">
                      Jurisdictions
                    </Text>
                  </Box>

                  {/* Legal Base Column */}

                  <Box display="flex" alignItems="center" flex={1}>
                    <Text size={12} weight={600} color="darkDuskFaded" flex={1}>
                      Legal Basis
                    </Text>
                  </Box>
                </Box>

                {legalBasesInfoRowData.map((lb, index) => {
                  const isLastRow = index === legalBasesInfoRowData.length - 1
                  return (
                    <Box
                      py={2}
                      display="flex"
                      alignItems="center"
                      flex={1}
                      key={`${lb?.jurisdictionName}-${lb?.legalBaseName}`}
                      sx={{ borderBottom: ({ palette }) => (isLastRow ? undefined : `1px solid ${palette.iron.main}`) }}
                    >
                      {/* Jurisdiction Column */}

                      <Box display="flex" alignItems="center" flex={1}>
                        <Text size={14} weight={600}>
                          {lb?.jurisdictionName}
                        </Text>
                      </Box>

                      {/* Legal Base Column */}

                      <Box display="flex" alignItems="center" flex={1}>
                        <Text size={14}>{lb?.legalBaseName}</Text>
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            ) : (
              <EmptyValueRenderer color="darkDuskFaded" />
            )
          }
        />

        <InfoRow title="Last Updated" info={<FormattedDateRenderer date={purpose?.metadata?.updatedAt} />} />
      </Box>
    </Dialog>
  )
}
