import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseStatus: {
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 5,
      },
      baseDot: {
        borderRadius: '50%',
      },
      baseText: {
        fontFamily: typography.fontFamily,
        letterSpacing: '0.01em',
      },

      // Sizes:
      sizeSmall: {
        fontSize: typography.pxToRem(11),
        fontWeight: 500,
        lineHeight: typography.pxToRem(13),
        padding: '2px 6px',

        '& > $baseDot': {
          width: 4,
          height: 4,
          marginRight: 6,
        },
      },
      sizeRegular: {
        height: 24,
        fontSize: typography.pxToRem(12),
        fontWeight: 600,
        lineHeight: typography.pxToRem(16),
        padding: '3px 8px',

        '& > $baseDot': {
          width: 6,
          height: 6,
          marginRight: 8,
        },
      },
      sizeBig: {
        fontSize: typography.pxToRem(12),
        fontWeight: 600,
        lineHeight: typography.pxToRem(16),
        height: 24,
        padding: '3px 8px',

        '& > $baseDot': {
          width: 6,
          height: 6,
          marginRight: 8,
        },

        '&$typeDraft': {
          border: `1px solid ${palette.darkDusk.main}`,
        },
        '&$typeNew': {
          border: `1px solid ${palette.heliotrope.main}`,
        },
        '&$typeInProgress': {
          border: `1px solid ${palette.chileanFire.main}`,
        },
        '&$typeActive': {
          border: `1px solid ${palette.apple.main}`,
        },
        '&$typeError': {
          border: `1px solid ${palette.red.main}`,
        },
        '&$typeFinished': {
          border: `1px solid ${palette.sphere.main}`,
        },
        '&$typeInactive': {
          border: `1px solid ${palette.darkDuskFaded.main}`,
        },
      },

      // Types:
      typeDraft: {
        '& > $baseText': {
          color: palette.darkDusk.main,
        },
        '& > $baseDot': {
          background: palette.darkDusk.main,
        },
      },
      typeNew: {
        '& > $baseText': {
          color: palette.heliotrope.main,
        },
        '& > $baseDot': {
          background: palette.heliotrope.main,
        },
      },
      typeInProgress: {
        '& > $baseText': {
          color: palette.chileanFire.main,
        },
        '& > $baseDot': {
          background: palette.chileanFire.main,
        },
      },
      typeActive: {
        '& > $baseText': {
          color: palette.apple.main,
        },
        '& > $baseDot': {
          background: palette.apple.main,
        },
      },
      typeError: {
        '& > $baseText': {
          color: palette.red.main,
        },
        '& > $baseDot': {
          background: palette.red.main,
        },
      },
      typeFinished: {
        '& > $baseText': {
          color: palette.sphere.main,
        },
        '& > $baseDot': {
          background: palette.sphere.main,
        },
      },
      typeInactive: {
        '& > $baseText': {
          color: palette.darkDuskFaded.main,
        },
        '& > $baseDot': {
          background: palette.darkDuskFaded.main,
        },
      },
    }),
  { name: 'Status' },
)

export type StatusVariant =
  | 'draft'
  | 'new'
  | 'inProgress'
  | 'active'
  | 'error'
  | 'finished'
  | 'inactive'
  | 'in-progress'

interface Props {
  children?: React.ReactNode
  /** Status variant - reflects the colour of border and background */
  variant?: StatusVariant
  /** Status size - reflects the size of the button */
  size?: 'small' | 'regular' | 'big'
  /** Status custom className */
  className?: any
}

export type StatusProps = Props

/**
 * -
 */
export const Status: React.FC<Props> = ({ variant = 'primary', size = 'regular', className, children }) => {
  const classes = useStyles()

  const statusClassName = clsx(
    classes.baseStatus,
    {
      [classes.typeDraft]: variant === 'draft',
      [classes.typeNew]: variant === 'new',
      [classes.typeInProgress]: variant === 'in-progress' || variant === 'inProgress',
      [classes.typeActive]: variant === 'active',
      [classes.typeError]: variant === 'error',
      [classes.typeFinished]: variant === 'finished',
      [classes.typeInactive]: variant === 'inactive',
      [classes.sizeSmall]: size === 'small',
      [classes.sizeRegular]: size === 'regular',
      [classes.sizeBig]: size === 'big',
    },
    className,
  )

  return (
    <div className={statusClassName}>
      <div className={classes.baseDot} />
      <div className={classes.baseText}>
        {children ? (
          children
        ) : (
          <>
            {variant === 'draft' && 'Draft'}
            {variant === 'new' && 'New'}
            {variant === 'in-progress' && 'In Progress'}
            {variant === 'active' && 'Active'}
            {variant === 'error' && 'Error/Cancelled'}
            {variant === 'finished' && 'Finished/Done'}
            {variant === 'inactive' && 'Inactive'}
          </>
        )}
      </div>
    </div>
  )
}
