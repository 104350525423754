import { createUseQuery, CustomQueryConfig } from '../../common/query'
import { ApiQueryKeys } from '../../common/queryKeys'
import { fetchProcessingActivityVersion } from '../fetchers/fetchProcessingActivityVersion'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.processingActivityVersion,
  queryFn: fetchProcessingActivityVersion,
  select: res => res?.data.processingActivity || {},
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useProcessingActivityVersion = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
