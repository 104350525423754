import { useState } from 'react'
import { useTrackerDetails } from 'api/trackers/queries/useTrackerDetails'
import { useParams } from 'react-router-dom'
import { trackerURLParams } from 'utils/routing/routes/utils'
import { useInstalledDataSystems } from 'api/dataSystems/queries/useInstalledDataSystems'
import { useDebounce } from 'react-use'

export const useTrackerDetailsUtils = () => {
  const { encodedTrackerKey } = useParams<trackerURLParams>()
  const { isLoading: isLoadingTrackerDetails, data: trackerDetails } = useTrackerDetails({
    params: {
      encodedTrackerKey,
    },
  })

  const [dataSystemInputValue, setDataSystemInputValue] = useState('')
  const [dataSystemSearchQueryValue, setDataSystemSearchQueryValue] = useState('')
  useDebounce(
    () => {
      if (dataSystemInputValue) {
        setDataSystemSearchQueryValue(dataSystemInputValue)
      } else {
        setDataSystemSearchQueryValue('')
      }
    },
    500,
    [dataSystemInputValue],
  )
  const { data: dataSystems, isLoading: isLoadingDataSystems } = useInstalledDataSystems({
    itemsPerPage: 300,
    params: {
      q: dataSystemSearchQueryValue,
    },
  })

  const [currentTab, setCurrentTab] = useState(0)

  const isReady = !isLoadingTrackerDetails && !isLoadingDataSystems

  return {
    isReady,
    trackerDetails,
    currentTab,
    setCurrentTab,
    encodedTrackerKey,
    dataSystems,
    isLoadingDataSystems,
    setDataSystemInputValue,
    dataSystemInputValue,
  }
}
