import { useContext, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useContextVariablesPaginated } from 'api/contextVariables/queries/useContextVariablesPaginated'
import { Button, DropListButton, ListItemText, ActionSheetItem } from '@ketch-com/deck'
import { ContextVariableCategoryDTO, WebhooksContextTimelineDTO } from '@ketch-com/figurehead'
import { UpsertContextVariablesModal } from 'pages/settings/contextVariables/components'
import { useState } from 'react'
import { ContextVariableInlineForm } from './ContextVariableInlineForm'
import { ApiCallContext } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/context/ApiCallContext'
import { FormDataInputValue } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/interfaces'

type Props = {
  timelineItem: WebhooksContextTimelineDTO
}

export const UnknownContextVariable: React.FC<Props> = ({ timelineItem }) => {
  const { submitTimelineChange } = useContext(ApiCallContext)

  const { contextVariables = {} } = timelineItem
  const { isActionEnabled } = contextVariables
  const [contextVariableKey] = Object.entries(contextVariables?.data || { '': '' })?.[0]

  const { data: allContextVariables, refetch: refetchContextVariableList } = useContextVariablesPaginated({
    params: { limit: 2000, category: ContextVariableCategoryDTO.DataSubjectContextVariableCategory },
  })
  const [showModal, setShowModal] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [selectedCode, setSelectedCode] = useState<string>('')
  const [formValue, setFormValue] = useState<FormDataInputValue>()
  const contextVariable = allContextVariables?.find(cv => cv.code === selectedCode)

  const submit = () => {
    submitTimelineChange({
      timelineItem,
      timelineItemToReplace: {
        ...timelineItem,
        ...{ contextVariables: { ...timelineItem.contextVariables, isActionEnabled: false } },
      },
      newTimelineItem: {
        ...timelineItem,
        ...{ contextVariables: { data: { [selectedCode]: formValue }, isActionEnabled: false } },
      },
    })
  }

  useEffect(() => {
    if (!isActionEnabled) setShowForm(false)
  }, [isActionEnabled])

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography>
          Data subject variable received:&nbsp;<Typography variant="label">{contextVariableKey}</Typography>
        </Typography>
      </Box>
      <Box mt={0.75} mb={1.5}>
        <Typography variant="label">
          Issue:<Typography>&nbsp;Data Subject Variable is not found in your organization.</Typography>
        </Typography>
      </Box>
      {isActionEnabled && !showForm ? (
        <Box display="flex" gap={1.5}>
          <Button color="tertiary" onClick={() => setShowForm(true)}>
            Change Context
          </Button>
          <Button color="secondary" onClick={() => setShowModal(true)}>
            Create New Context
          </Button>
        </Box>
      ) : null}
      {showModal ? (
        <UpsertContextVariablesModal
          onSubmit={async (value = '') => {
            refetchContextVariableList()
            setShowModal(false)
            setSelectedCode(value)
            setShowForm(true)
          }}
          onCancel={() => setShowModal(false)}
        />
      ) : null}
      {showForm ? (
        <Box mt={1.5} width={600}>
          <Grid container alignItems="flex-end" gap={1.5}>
            <Grid item xs={10}>
              <DropListButton
                // see https://github.com/mui/material-ui/issues/33661 for more details
                disableClearable={!!(selectedCode !== null)}
                fullWidth
                label="Change To"
                isOptionEqualToValue={(option, value) => option.code === value.code}
                getOptionLabel={option => option.name || ''}
                renderOption={(props, option, { selected }) => (
                  <ActionSheetItem {...props} key={option.code} selected={selected}>
                    <ListItemText selected={selected}>{option.name}</ListItemText>
                  </ActionSheetItem>
                )}
                options={allContextVariables}
                onChange={(_, value) => value && value.code && setSelectedCode(value.code)}
                value={allContextVariables.find(elem => selectedCode === elem.code) || null}
                placeholder="Select"
                size="small"
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-start">
              <ContextVariableInlineForm
                contextVariable={contextVariable}
                onCancel={() => setShowForm(false)}
                formValue={formValue}
                setFormValue={setFormValue}
                onSubmit={submit}
              />
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </Box>
  )
}
