import { APIListRequestParams } from 'api/common/utils'
import { useRelationshipsInfiniteV2 } from './useRelationshipsInfiniteV2'
import { useRelationshipsInfiniteV3 } from './useRelationshipsInfiniteV3'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type Params = APIListRequestParams<{
  type?: number
}>

type UseV2OrV3RelatedRelationshipsInfiniteBasedOnFeatureFlagArgs = {
  params: Params
}

export const useV2OrV3RelatedRelationshipsInfiniteBasedOnFeatureFlag = ({
  params,
}: UseV2OrV3RelatedRelationshipsInfiniteBasedOnFeatureFlagArgs) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )

  /* V2 */

  const {
    data: infiniteRelationshipsDataV2,
    isLoading: isLoadingV2,
    hasNextPage: hasNextPageV2,
    fetchNextPage: fetchNextPageV2,
    isFetchingNextPage: isFetchingNextPageV2,
    totalResults: totalResultsV2,
  } = useRelationshipsInfiniteV2({
    params: {
      ...params,
    },
    options: {
      enabled: isV2FeatureFlagEnabled,
    },
  })

  const payloadV2 = {
    infiniteRelationshipsData: infiniteRelationshipsDataV2 || [],
    isLoading: isLoadingV2,
    hasNextPage: hasNextPageV2,
    fetchNextPage: fetchNextPageV2,
    isFetchingNextPage: isFetchingNextPageV2,
    totalResults: totalResultsV2,
  }

  /* V3 */

  const {
    relationshipsData: relationshipsDataV3,
    isLoading: isLoadingV3,
    hasNextPage: hasNextPageV3,
    fetchNextPage: fetchNextPageV3,
    isFetchingNextPage: isFetchingNextPageV3,
    totalResults: totalResultsV3,
  } = useRelationshipsInfiniteV3({
    params: {
      ...params,
    },
    options: {
      enabled: !isV2FeatureFlagEnabled,
    },
  })

  const payloadV3 = {
    infiniteRelationshipsData: relationshipsDataV3 || [],
    isLoading: isLoadingV3,
    hasNextPage: hasNextPageV3,
    fetchNextPage: fetchNextPageV3,
    isFetchingNextPage: isFetchingNextPageV3,
    totalResults: totalResultsV3,
  }

  return isV2FeatureFlagEnabled ? payloadV2 : payloadV3
}
