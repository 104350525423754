import { API } from 'utils/api/api'
import { APIRequestParams } from 'api/common/utils'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { CreateApplianceResponse } from '@ketch-com/windlass/dist/appliance/appliances'

type CreateApplianceTokenParams = APIRequestParams<{
  applianceId: string
}>

export const createApplianceToken = ({ applianceId }: CreateApplianceTokenParams) =>
  API.post<CreateApplianceResponse>(
    formatRequestString({
      entityUrl: `/api/asset-manager/appliances/${applianceId}/token`,
    }),
  )
