import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { makeStyles, createStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'

import { MaybeNull } from 'interfaces/common'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { EXPERIENCE_ACTION_BUTTON_DESTINATION_OPTIONS } from 'interfaces/experiences/experienceActionButtonDestination'
import {
  JitField,
  SIDEBAR_TITLES,
  fieldNames,
  useOnRemove,
  isFieldRequired,
  serialize,
} from 'pages/consentAndRights/experiences/upsert/utils/common'
import { InterpolateLinks } from 'pages/consentAndRights/experiences/components/InterpolateLinks'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { Button } from '@ketch-com/deck'
import { Group } from 'components/ui-layouts/group/Group'
import { Text } from 'components/ui-kit/typography/Text'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRadioGroup } from 'components/ui-kit/form/radioGroup/FormRadioGroup'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { UpsertInlineLayoutPreview } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutPreview'
import { UpsertInlineLayoutPreviewContent } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutPreviewContent'
import { UpsertExperienceEditableOverlay } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEditableOverlay'
import { UpsertExperiencePoweredBy } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePoweredBy'
import { UpsertExperiencePreviewText } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewText'
import { UpsertExperiencePreviewButton } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewButton'
import { UpsertExperiencePreviewPlaceholder } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperiencePreviewPlaceholder'
import { UpsertExperienceEmptyField } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceEmptyField'
import { UpsertExperienceFieldSidebar } from 'pages/consentAndRights/experiences/upsert/components/common/UpsertExperienceFieldSidebar'
import { InterpolatedTextArea } from 'pages/consentAndRights/experiences/upsert/components/common/interpolatedTextArea/InterpolatedTextArea'
import { ConsentExperienceFormValues } from '../../../utils/consent/interfaces'
import { onRemoveHandledCases } from '../../../utils/common/hooks/useOnRemove'
import { FormSwitch } from 'components/ui-kit/form/switch/FormSwitch'
import Typography from '@mui/material/Typography'

const sectionsWithTranslations = [
  JitField.MAIN_TITLE,
  JitField.BODY_DESCRIPTION,
  JitField.ACTION_BUTTON,
  JitField.DECLINE_BUTTON,
  JitField.CONFIRM_BUTTON,
]

const useStyles = makeStyles(
  theme =>
    createStyles({
      wrapper: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      modal: {
        width: '100%',
        height: '100%',
        maxWidth: 846,
        maxHeight: 650,
        borderRadius: 3,
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 5px 24px rgba(0, 0, 0, 0.65)',
      },
      header: {
        padding: '17px 24px 21px 24px',
        backgroundColor: theme.palette.darkDusk.main,
        color: theme.palette.white.main,
      },
      closeIcon: {
        fontSize: theme.typography.pxToRem(18),
      },
      body: {
        flex: 1,
        overflowY: 'auto',
        padding: 24,
      },
      bodyDescription: {
        marginBottom: 24,
        whiteSpace: 'pre-line',
        overflowWrap: 'break-word',
      },
      footer: {
        padding: '12px 24px',
        backgroundColor: theme.palette.darkDusk.main,
      },
      translationsTitle: {
        display: 'block',
        marginBottom: 20,
      },
      readMore: {
        display: 'block',
        textDecoration: 'underline',
        padding: '11px 15px',
      },
    }),
  { name: 'UpsertJit' },
)

type Props = {
  isEditMode: boolean
  organizationLanguages: LanguageWithStatusDTO[]
}

export const UpsertJit: React.FC<Props> = ({ isEditMode, organizationLanguages }) => {
  const classes = useStyles()

  const [active, setActive] = useState<MaybeNull<JitField>>(isEditMode ? null : JitField.MAIN_TITLE)
  const { values } = useFormikContext<ConsentExperienceFormValues>()
  const { title, bodyDescription, moreInfoText, declineButtonText, acceptButtonText } = values.jit

  const getOverlayProps = (name: JitField) => ({
    active: active === name,
    onClick: () => {
      setActive(name)
    },
  })

  const { onRemove } = useOnRemove({ organizationLanguages })

  const shouldShowTranslationsSection = !!organizationLanguages?.length && sectionsWithTranslations.includes(active!)

  return (
    <UpsertInlineLayoutPreview>
      <UpsertInlineLayoutPreviewContent className={classes.content}>
        <Flex className={classes.wrapper} alignItems="center" justifyContent="center">
          <Flex className={classes.modal} flexDirection="column">
            <Flex className={classes.header} justifyContent="space-between" alignItems="center">
              <UpsertExperienceEditableOverlay {...getOverlayProps(JitField.MAIN_TITLE)}>
                {title ? (
                  <UpsertExperiencePreviewText bold size="large" color="white">
                    {title}
                  </UpsertExperiencePreviewText>
                ) : (
                  <UpsertExperienceEmptyField required size="large">
                    Main Title
                  </UpsertExperienceEmptyField>
                )}
              </UpsertExperienceEditableOverlay>

              {/* Close Icon */}

              {values?.jit?.showCloseIcon ? (
                <UpsertExperienceEditableOverlay
                  {...getOverlayProps(JitField.CLOSE_ICON)}
                  hideClickToEdit
                  removeMinWidth
                >
                  <CloseIcon className={classes.closeIcon} />
                </UpsertExperienceEditableOverlay>
              ) : (
                <UpsertExperienceEditableOverlay
                  {...getOverlayProps(JitField.CLOSE_ICON)}
                  removeMinWidth
                  hideClickToEdit
                >
                  <Box display="flex" alignItems="center" sx={{ opacity: 0.75 }}>
                    <Text size={14} mr={2} color="white">
                      Enable Button
                    </Text>
                    <CloseIcon className={classes.closeIcon} />
                  </Box>
                </UpsertExperienceEditableOverlay>
              )}
            </Flex>
            <div className={classes.body}>
              <UpsertExperienceEditableOverlay
                className={classes.bodyDescription}
                {...getOverlayProps(JitField.BODY_DESCRIPTION)}
              >
                {(() => {
                  const bodyDescriptionText = serialize(bodyDescription)

                  return bodyDescriptionText ? (
                    <UpsertExperiencePreviewText size="small">
                      <InterpolateLinks text={bodyDescriptionText} />
                    </UpsertExperiencePreviewText>
                  ) : (
                    <UpsertExperienceEmptyField size="small">Body Description</UpsertExperienceEmptyField>
                  )
                })()}
              </UpsertExperienceEditableOverlay>

              <UpsertExperiencePreviewPlaceholder>Purposes List</UpsertExperiencePreviewPlaceholder>
            </div>

            <Flex className={classes.footer} justifyContent="space-between" alignItems="center">
              <UpsertExperiencePoweredBy />

              <Group gap={20}>
                <UpsertExperienceEditableOverlay {...getOverlayProps(JitField.ACTION_BUTTON)}>
                  {moreInfoText ? (
                    <UpsertExperiencePreviewText className={classes.readMore} size="small" color="white">
                      {moreInfoText}
                    </UpsertExperiencePreviewText>
                  ) : (
                    <UpsertExperienceEmptyField button size="small">
                      Tertiary Button
                    </UpsertExperienceEmptyField>
                  )}
                </UpsertExperienceEditableOverlay>

                <UpsertExperienceEditableOverlay {...getOverlayProps(JitField.DECLINE_BUTTON)}>
                  {declineButtonText ? (
                    <UpsertExperiencePreviewButton inverted>
                      <UpsertExperiencePreviewText bold size="small" color="white">
                        {declineButtonText}
                      </UpsertExperiencePreviewText>
                    </UpsertExperiencePreviewButton>
                  ) : (
                    <UpsertExperienceEmptyField required button size="small">
                      Secondary Button
                    </UpsertExperienceEmptyField>
                  )}
                </UpsertExperienceEditableOverlay>

                <UpsertExperienceEditableOverlay {...getOverlayProps(JitField.CONFIRM_BUTTON)}>
                  {acceptButtonText ? (
                    <UpsertExperiencePreviewButton>
                      <UpsertExperiencePreviewText bold size="small">
                        {acceptButtonText}
                      </UpsertExperiencePreviewText>
                    </UpsertExperiencePreviewButton>
                  ) : (
                    <UpsertExperienceEmptyField required button size="small">
                      Primary Button
                    </UpsertExperienceEmptyField>
                  )}
                </UpsertExperienceEditableOverlay>
              </Group>
            </Flex>
          </Flex>
        </Flex>
      </UpsertInlineLayoutPreviewContent>

      {!!active && (
        <UpsertExperienceFieldSidebar
          title={SIDEBAR_TITLES[active]}
          required={isFieldRequired({ field: active })}
          actions={
            <Button
              disabled={!onRemoveHandledCases.includes(active!)}
              size="large"
              color="secondary"
              onClick={() => {
                onRemove(active!)
              }}
            >
              Remove
            </Button>
          }
        >
          <>
            {active === JitField.MAIN_TITLE && (
              <FormRow marginBottom={24}>
                <FormInput
                  required={isFieldRequired({ field: JitField.MAIN_TITLE })}
                  fullWidth
                  name={fieldNames.consent.jit.title.root}
                  label="Display Text"
                />
              </FormRow>
            )}

            {active === JitField.BODY_DESCRIPTION && (
              <FormRow marginBottom={24}>
                <InterpolatedTextArea
                  required={isFieldRequired({ field: JitField.BODY_DESCRIPTION })}
                  label="Display Text"
                  name={fieldNames.consent.jit.bodyDescription.root}
                />
              </FormRow>
            )}

            {active === JitField.ACTION_BUTTON && (
              <>
                <FormRow marginBottom={24}>
                  <FormInput
                    required={isFieldRequired({ field: JitField.ACTION_BUTTON })}
                    fullWidth
                    name={fieldNames.consent.jit.moreInfoText.root}
                    label="Display Text"
                  />
                </FormRow>

                <FormRow marginBottom={24}>
                  <FormRadioGroup
                    id="secondaryButtonDestination"
                    name={fieldNames.consent.jit.moreInfoDestination.root}
                    label="Action"
                    layout="column"
                    valueKey="id"
                    renderLabel={({ name }) => name}
                    options={EXPERIENCE_ACTION_BUTTON_DESTINATION_OPTIONS}
                  />
                </FormRow>
              </>
            )}

            {active === JitField.DECLINE_BUTTON && (
              <FormRow marginBottom={24}>
                <FormInput
                  required={isFieldRequired({ field: JitField.DECLINE_BUTTON })}
                  fullWidth
                  name={fieldNames.consent.jit.declineButtonText.root}
                  label="Display Text"
                />
              </FormRow>
            )}

            {active === JitField.CONFIRM_BUTTON && (
              <FormInput
                required={isFieldRequired({ field: JitField.CONFIRM_BUTTON })}
                fullWidth
                name={fieldNames.consent.jit.acceptButtonText.root}
                label="Display Text"
              />
            )}
          </>

          {!!shouldShowTranslationsSection && (
            <>
              <Typography mb={2.5} variant="h4" color="fadedDarkGrey.main" component="div">
                Translations
              </Typography>

              {active === JitField.MAIN_TITLE && (
                <>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <FormRow key={language.code} marginBottom={24}>
                      <FormInput
                        fullWidth
                        required={false}
                        name={fieldNames.consent.jit.title.translation(language.code)}
                        label={`Display Text (${language.englishName})`}
                      />
                    </FormRow>
                  ))}
                </>
              )}

              {active === JitField.BODY_DESCRIPTION && (
                <>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <FormRow key={language.code} marginBottom={24}>
                      <InterpolatedTextArea
                        required={false}
                        name={fieldNames.consent.jit.bodyDescription.translation(language.code)}
                        label={`Display Text (${language.englishName})`}
                      />
                    </FormRow>
                  ))}
                </>
              )}

              {active === JitField.ACTION_BUTTON && (
                <>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <FormRow key={language.code} marginBottom={24}>
                      <FormInput
                        fullWidth
                        required={false}
                        name={fieldNames.consent.jit.moreInfoText.translation(language.code)}
                        label={`Display Text (${language.englishName})`}
                      />
                    </FormRow>
                  ))}
                </>
              )}

              {active === JitField.DECLINE_BUTTON && (
                <>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <FormRow key={language.code} marginBottom={24}>
                      <FormInput
                        fullWidth
                        required={false}
                        name={fieldNames.consent.jit.declineButtonText.translation(language.code)}
                        label={`Display Text (${language.englishName})`}
                      />
                    </FormRow>
                  ))}
                </>
              )}

              {active === JitField.CONFIRM_BUTTON && (
                <>
                  {organizationLanguages.map(({ language, enabled }) => (
                    <FormRow key={language.code} marginBottom={24}>
                      <FormInput
                        fullWidth
                        required={false}
                        name={fieldNames.consent.jit.acceptButtonText.translation(language.code)}
                        label={`Display Text (${language.englishName})`}
                      />
                    </FormRow>
                  ))}
                </>
              )}
            </>
          )}

          {active === JitField.CLOSE_ICON && (
            <FormRow marginBottom={24}>
              <FormSwitch inlineLabel label="Show X Button" name="jit.showCloseIcon" />
            </FormRow>
          )}
        </UpsertExperienceFieldSidebar>
      )}
    </UpsertInlineLayoutPreview>
  )
}
