import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { Text } from 'components/ui-kit/typography/Text'
import { ConfigurationProgress } from 'pages/assetManager/scenario/components'
import { ReactComponent as ScenarioDetailsIcon } from 'assets/icons/square_button_next.svg'
import { UrlParams } from 'pages/assetManager/scenario/interfaces'
import { GetDSRConfigCountResponseBodyDTO, ScenarioDTO, AssetSummaryDTO } from '@ketch-com/figurehead'
import { ReactComponent as ConfiguredIcon } from 'assets/icons/green_checked_circle.svg'
import { MaybeNull } from 'interfaces'
import { getAssetType } from 'pages/assetManager/utils'
import pluralize from 'pluralize'
import { LookUpTab } from 'pages/assetManager/scenario/details/constants'

type Props = {
  scenario: ScenarioDTO
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export const ScenarioStats: React.FC<Props> = ({ scenario, dsrConfigCount, assetSummary = {} }) => {
  const providerCode = assetSummary?.asset?.connection?.providerCode || ''
  const datasetType = getAssetType(providerCode, 2)
  const { code: databaseId, resourceTypeCode = '' } = useParams<UrlParams>()
  const navigate = useNavigate()
  const { dsrConfigurationCount = {}, personalAssets = 0 } = dsrConfigCount
  const { customizedAssets = 0, skippedAssets = 0, totalActions = 0 } = dsrConfigurationCount[scenario.id || '']
  const allDatasetsConfigured = totalActions && personalAssets && totalActions === personalAssets
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" flexBasis={292}>
      <Box display="flex" alignItems="center" justifyContent="flex-start" gap={1.5}>
        {allDatasetsConfigured ? (
          <Box width={34} height={34} display="flex" alignItems="center" justifyContent="center">
            <ConfiguredIcon />
          </Box>
        ) : (
          <ConfigurationProgress progress={Math.round((totalActions / personalAssets) * 100)} />
        )}
        <Box>
          {allDatasetsConfigured ? (
            <Text size={14} weight={600} display="block" color="greenHaze">
              Configured
            </Text>
          ) : (
            <Text size={14} weight={600} display="block">
              {totalActions}/<Text color="grey">{personalAssets}</Text> {pluralize(datasetType, personalAssets)}{' '}
              Configured
            </Text>
          )}
          <Text size={12} color="grey">
            {skippedAssets} skipped, {customizedAssets} customized
          </Text>
        </Box>
      </Box>

      <IconButton
        sx={{ padding: 0 }}
        color="secondary"
        aria-label="Configure Dataset"
        disableFocusRipple
        disableRipple
        onClick={() => {
          navigate(
            RoutesManager.assetManager.assets.detail.scenarioDetailsTab.getURL({
              scenarioId: scenario.id,
              id: databaseId,
              resourceTypeCode,
              tab: LookUpTab.DIRECT,
            }),
          )
        }}
      >
        <ScenarioDetailsIcon />
      </IconButton>
    </Box>
  )
}
