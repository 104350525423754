import { PurposeDTO, AssetPurposeDTO } from '@ketch-com/figurehead'

type Args = { purposeOptions: PurposeDTO[] }

export const getReducedPurposeOptions = ({ purposeOptions }: Args): any[] =>
  purposeOptions.reduce((accumulator: AssetPurposeDTO[], purpose: PurposeDTO) => {
    return [
      ...accumulator,
      {
        code: purpose?.code,
        name: purpose?.name,
      },
    ] as AssetPurposeDTO[]
  }, [])
