import React, { useState } from 'react'
import {
  ContentGroup,
  Button,
  TabGroup,
  Tab,
  TextInput,
  TabSegmented,
  TabSegmentedGroup,
  Icon,
  Chip,
  Status,
  StatusState,
  StatusVariant,
} from '@ketch-com/deck'
import { Box, Typography, Divider } from '@mui/material'

const TopHeaderBottomActionPanel = () => {
  const [alignment, setAlignment] = React.useState('left')

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment)
    }
  }

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <TextInput placeholder="Search..." startIcon={<Icon name="OMag" />} size="medium" />
      <TabSegmentedGroup value={alignment} exclusive onChange={handleChange} size="small">
        <TabSegmented value="left" key="1">
          All
        </TabSegmented>
        <TabSegmented value="center" key="2">
          Manual
        </TabSegmented>
        <TabSegmented value="right" key="3">
          Automated
        </TabSegmented>
      </TabSegmentedGroup>
    </Box>
  )
}

const InnerHeaderActionRightPanel = () => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="body" sx={{ color: theme => theme.palette.iron.main }}>
        Last Updated
      </Typography>
      <Typography variant="body" sx={{ color: theme => theme.palette.darkDusk.main }}>
        Mar 21 2023, 10:13 AM
      </Typography>
      <Button color="tertiary" size="large">
        Delete
      </Button>
      <Button color="secondary" size="large">
        Edit
      </Button>
    </Box>
  )
}

const InnerHeaderInfoPanel = () => {
  return (
    <>
      <Chip label="Privacy Policy" size="small" />
      <Status label="Undeployed" status={StatusState.draft} variant={StatusVariant.outlined} />
      <Chip label="v3" size="small" />
    </>
  )
}

export const ContentGroupPartOfLayoutExample = () => {
  const [topHeaderTabsValue, setTopHeaderTabsValue] = useState(0)
  const [innerHeaderTabsValue, setInnerHeaderValue] = useState(0)

  return (
    <Box display="flex" flexDirection="column" gap={10}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Typography variant="label">Top header example</Typography>
        <ContentGroup
          variant="top-header"
          title="Labels"
          titleVariant="h1"
          actionRightBlockComponent={<Chip size="medium" icon={<Icon name="OInfo" />} label="How does it work" />}
          actionBottomBlockComponent={<TopHeaderBottomActionPanel />}
        >
          {
            <TabGroup
              value={topHeaderTabsValue}
              onChange={(event: React.SyntheticEvent, newValue: number) => {
                setTopHeaderTabsValue(newValue)
              }}
            >
              <Tab label="Classifications" icon="OBookmark" iconPosition="start" />
              <Tab label="Data Categories" icon="OLabel" iconPosition="start" />
            </TabGroup>
          }
        </ContentGroup>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" gap={4}>
        <Typography variant="label">Inner page header</Typography>
        <ContentGroup
          variant="inner-page-header"
          title="Automation privacy policy"
          titleVariant="h2"
          showBackButton
          actionRightBlockComponent={<InnerHeaderActionRightPanel />}
          infoBlockComponent={<InnerHeaderInfoPanel />}
        >
          {
            <TabGroup
              value={innerHeaderTabsValue}
              onChange={(event: React.SyntheticEvent, newValue: number) => {
                setInnerHeaderValue(newValue)
              }}
            >
              <Tab label="Overview" />
              <Tab label="Deployments" />
              <Tab label="Versions" />
            </TabGroup>
          }
        </ContentGroup>
      </Box>
    </Box>
  )
}
