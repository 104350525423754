import { GetStepDetailsResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  workflowExecutionId: string | undefined
  stepId: string
}

export const fetchWorkflowExecutionStepDetailsV2 = ({ workflowExecutionId, stepId }: Params) =>
  API.get<GetStepDetailsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/executions/${workflowExecutionId}/steps/${stepId}/details`,
    }),
  )
