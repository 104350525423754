import React, { useContext } from 'react'

import { UserShortDTO } from 'interfaces/users/users'
import { RightInvocationDTO } from '@ketch-com/figurehead'
import { RightsQueueViewContext } from '../../context/RightsQueueViewContext'
import { NoWorkflowAssigned } from './components/NoWorkflowAssigned'
import { TurboRejectBanner } from './components/TurboRejectBanner'
import { WorkflowPreview } from './components/WorkflowPreview/WorkflowPreview'

type Props = {
  users: UserShortDTO[]
  rightInvocation: RightInvocationDTO
}

export const RightsQueueWorkflowTab: React.FC<Props> = () => {
  const { workflowExecution, workflowPreview, rightInvocation } = useContext(RightsQueueViewContext)

  const hasAppeal = !!rightInvocation?.appeal?.results?.appealWorkflowExecutionID

  if (!workflowExecution && !workflowPreview) return <NoWorkflowAssigned />

  return (
    <>
      <TurboRejectBanner />
      <WorkflowPreview />
      {hasAppeal ? <WorkflowPreview mode="appeal" /> : null}
    </>
  )
}
