import React, { useMemo } from 'react'
import numeral from 'numeral'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import { ScatterChart, CartesianGrid, XAxis, YAxis, Scatter, Label, ZAxis, Tooltip } from 'recharts'
import { Text } from 'components/ui-kit/typography/Text'
import { generateRatioWidgetDummyDataTheta } from 'pages/insights/activityAndAccess/__mocks__/ratioWidgetDummyData'
import { generatedTeamMemberQueriesGraphData } from 'pages/insights/activityAndAccess/__mocks__/teamMemberQueriesGraphData'
import { AccessUtilizationGraphTooltip } from './AccessUtilizationGraphTooltip'
import { TeamMemberDot } from './TeamMemberDot'
import { teamMembersAlt } from 'pages/insights/activityAndAccess/__mocks__/generateDummyTeamMembers'
import { CustomAxisTickMark, CustomRechartLabel } from 'pages/insights/activityAndAccess/components'
import { theme } from '@ketch-com/deck'

type Props = {
  teamMemberName?: string
}

export const AccessUtilizationQueriesGraph: React.FC<Props> = ({ teamMemberName }) => {
  const location = useLocation()
  const userId = location?.pathname?.split('/')?.[4] || ''
  const teamAverageData = useMemo(() => generateRatioWidgetDummyDataTheta(1), [])
  const member = teamMembersAlt.find(member => member.id === userId)
  const teamMemberSpecificData = member?.accessUtilizationGraphStaticData || []

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" alignItems="center" flex={1.75}>
        <ScatterChart
          height={360}
          width={360}
          data={generatedTeamMemberQueriesGraphData}
          margin={{ top: 20, right: 30, left: -10, bottom: 0 }}
        >
          <CartesianGrid
            strokeDasharray="0 0"
            horizontalPoints={[104.375, 179.75, 250.125]}
            verticalPoints={[/* 50, */ 120, 190, 260 /* 330 */]}
          />

          <XAxis
            stroke={theme.palette.iron.main}
            tick={<CustomAxisTickMark customXOffset={28} customYOffset={10} unitAdornment="%" />}
            dataKey="x"
            type="number"
            ticks={[100]}
            domain={[0, 100]}
            unit="%"
            tickLine={false}
          >
            <Label
              content={props => {
                return <CustomRechartLabel textSize={12} title="Usage" xOffset={297} yOffset={303} />
              }}
            />
          </XAxis>
          <YAxis
            stroke={theme.palette.iron.main}
            tick={<CustomAxisTickMark customXOffset={28} customYOffset={10} unitAdornment="%" />}
            dataKey="y"
            type="number"
            ticks={[0, 100]}
            domain={[0, 100]}
            unit="%"
            tickLine={false}
          >
            <Label
              content={props => {
                return <CustomRechartLabel textSize={12} title="Access" xOffset={57} yOffset={9} />
              }}
            />
          </YAxis>
          <ZAxis dataKey="z" range={[340, 340]} unit="%" />

          <Tooltip
            cursor={false}
            content={props => {
              return (
                <AccessUtilizationGraphTooltip
                  active={props.active}
                  payload={props.payload}
                  teamMemberName={member?.name}
                />
              )
            }}
          />

          <Scatter name="beta" data={teamAverageData} fill="rgba(7, 26, 36, 0.25)" isAnimationActive={false} />

          <Scatter
            name="alpha"
            data={teamMemberSpecificData}
            fill="black"
            isAnimationActive={false}
            shape={<TeamMemberDot />}
          />
        </ScatterChart>
      </Box>

      {/* // TODO:BAC - make statistic dynamic */}

      <Box display="flex" flex={1.5} flexDirection="column" justifyContent="flex-start" height="100%">
        <Box display="flex" alignItems="center" mb={1}>
          <Text size={14} weight={600} mr={1}>
            Access utilization
          </Text>
          <Text size={14}>for select period</Text>
        </Box>

        {/* Utilized (black text) */}

        <Box display="flex" alignItems="center" mb={8}>
          <Text
            size={20}
            weight={700}
            color="black"
            pr={1}
            mr={1}
            sx={{
              borderRight: '1px solid',
              borderColor: 'darkDusk.main',
            }}
          >
            {teamMemberSpecificData?.[0]?.x}%
          </Text>

          <Text size={20} weight={700} color="black">
            {numeral((teamMemberSpecificData?.[0]?.x / 100) * 8000).format('0,0')} data assets
          </Text>
        </Box>

        <Box display="flex" alignItems="center" mb={1}>
          <Text size={14} weight={600} mr={1}>
            Access not utilized
          </Text>
          <Text size={14}>for select period</Text>
        </Box>

        {/* Not Utilized (orange text) */}

        <Box display="flex" alignItems="center" mb={8}>
          <Text
            size={20}
            weight={700}
            color="chileanFire"
            pr={1}
            mr={1}
            sx={{
              borderRight: '1px solid',
              borderColor: 'chileanFire.main',
            }}
          >
            {100 - teamMemberSpecificData?.[0]?.x}%
          </Text>

          <Text size={20} weight={700} color="chileanFire">
            {numeral(((100 - teamMemberSpecificData?.[0]?.x) / 100) * 8000).format('0,0')} data assets
          </Text>
        </Box>

        <Box display="flex" alignItems="center" mb={2}>
          <Box
            sx={{
              borderRadius: '50%',
              width: 10,
              height: 10,
              backgroundColor: 'darkDusk.main',
              mr: 1,
            }}
          />
          <Text size={11} weight={500}>
            {teamMemberName}
          </Text>
        </Box>

        <Box display="flex" alignItems="center" mb={2}>
          <Box
            sx={{
              borderRadius: '50%',
              width: 10,
              height: 10,
              backgroundColor: 'fadedDarkGrey.main',
              mr: 1,
            }}
          />
          <Text size={11} weight={500}>
            Team Average
          </Text>
        </Box>
      </Box>
    </Box>
  )
}
