import { useParams } from 'react-router-dom'
import { useMemo } from 'react'

/**
 * Custom hook to determine whether the current page is in edit mode.
 * Assumes the route parameters 'code' and 'id'.
 * The page is considered to be in 'edit mode' if the value of either 'code' or 'id' is not 'new'.
 *
 * @returns {boolean} Returns 'true' if the current page is in edit mode, 'false' otherwise.
 *
 * @example
 * const isEditMode = useIsEditMode()
 */
export const useIsEditMode = () => {
  const { code, id } = useParams<{ code?: string; id?: string }>()

  const isEditMode = useMemo(() => {
    return code ? code !== 'new' : id ? id !== 'new' : false
  }, [code, id])

  return isEditMode
}
