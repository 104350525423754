import React, { ReactNode } from 'react'
import { Box, Typography } from '@mui/material'

type PermitPurposeRowProps = {
  title: string
  info: ReactNode
  hasBorderBottom?: boolean
  hasBorderTop?: boolean
}

export const PermitPurposeRow: React.FC<PermitPurposeRowProps> = ({
  title,
  info,
  hasBorderBottom = true,
  hasBorderTop = false,
}) => {
  return (
    <Box
      sx={{
        borderBottom: ({ palette }) => (hasBorderBottom ? `1px solid ${palette.iron.main}` : undefined),
        borderTop: ({ palette }) => (hasBorderTop ? `1px solid ${palette.iron.main}` : undefined),
      }}
      display="flex"
    >
      <Box display="flex" flex={2.15} justifyContent="flex-start" flexDirection="column">
        <Box display="flex" alignItems="center" sx={{ pt: 2, pb: 2, wordBreak: 'break-word' }}>
          <Typography variant="label">{title}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap" flex={4.15} gap={1} sx={{ pt: 2, pb: 2, wordBreak: 'break-word' }}>
        <Typography>{info}</Typography>
      </Box>
    </Box>
  )
}
