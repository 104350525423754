import React from 'react'

import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { SectionTitle } from 'components/ui-kit/typography/compositions/SectionTitle'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { Table } from 'components/ui-layouts/table/Table'
import { Status } from 'components/ui-kit/status/Status'
import { Badge } from 'components/ui-kit/badge/Badge'
import { Button } from '@ketch-com/deck'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { FirstNameLastNameRenderer } from 'components/renderers/FirstNameLastNameRenderer'

type Props = {
  deploymentPlan: DeploymentPlanDTO
  versions: DeploymentPlanDTO[]
}

export const DeploymentPlanVersions: React.FC<Props> = ({ deploymentPlan, versions }) => {
  const { queries, setQueryParam, removeQueryParam } = useQueryParams<{ version?: string }>()
  const currentVersion = queries.version ? +queries.version : deploymentPlan.version

  return (
    <>
      <SectionTitle>Version History</SectionTitle>

      {versions.length ? (
        <Table
          items={versions}
          cellSettings={{
            status: {
              label: 'Status',
              width: 110,
              cellRenderer: ({ latest }) => (
                <>
                  {latest && (
                    <Status variant="active" size="regular">
                      Latest
                    </Status>
                  )}
                </>
              ),
            },
            version: {
              label: 'Version',
              width: 200,
              cellRenderer: ({ version }) => <Badge variant="outline">v{version}</Badge>,
            },
            deploymentPlan: {
              label: 'Deployment Plan',
              cellRenderer: ({ name, code }) => <NameAndCodeCellRenderer name={name} code={code} />,
            },
            updatedAt: {
              label: 'Created',
              width: 250,
              cellRenderer: ({ version }) => <FormattedDateRenderer date={version} />,
            },
            createdBy: {
              label: 'Created By',
              width: 200,
              cellRenderer: ({ audit }) => (
                <FirstNameLastNameRenderer
                  firstName={audit?.updatedBy?.firstName}
                  lastName={audit?.updatedBy?.lastName}
                />
              ),
            },
            actions: {
              width: 110,
              cellRenderer: ({ latest, version }) => (
                <Button
                  disabled={!version || version === currentVersion}
                  color="tertiary"
                  onClick={() => {
                    if (latest) {
                      if (queries.version) {
                        removeQueryParam('version')
                      }
                    } else {
                      if (version) {
                        setQueryParam('version', version)
                      }
                    }
                  }}
                >
                  View
                </Button>
              ),
            },
          }}
        />
      ) : (
        <EmptyState
          bgVariant="grey"
          variant="page"
          title="No versions exist for the selected deployment plan"
          description="As you make changes to this deployment plan, you can view that history here"
        />
      )}
    </>
  )
}
