import { APIListRequestParams } from '../../common/utils'
import { API } from '../../../utils/api/api'
import { FetchProcessingActivityVersionsResponseBodyDTO } from '@ketch-com/figurehead'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  activityId?: string
}>

export const fetchProcessingActivityVersions = ({ activityId, query, includeMetadata, limit, start }: Params) =>
  API.get<FetchProcessingActivityVersionsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/processing-activities/${activityId}/versions`,
      params: {
        filters: {
          start,
          limit,
          includeMetadata,
          ...(query && {
            query,
          }),
        },
      },
    }),
  )
