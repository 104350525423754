import { useContext } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { RoutesManager } from 'utils/routing/routesManager'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useUsers } from 'api/users/queries/useUsers'
import { useExecutionsInfinite } from 'api/executions/queries/useExecutionsInfinite'
import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useRetryExecution } from 'api/executions/mutations/useRetryExecution'
import { useResolveExecution } from 'api/executions/mutations/useResolveExecution'
import { useNavigate } from 'react-router-dom'

export const useInternalSystemDsrUtils = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { id: rightInvocationId } = useParams<{ id?: string }>()

  const {
    isReady: isRightsQueueWorkflowTaskDetailViewContainerUtilsReady,
    rightInvocation,
    stepId,
    workflowExecutionId,
    workflowStep,
    stepDetails,
    workflowExecution,
    isRightsQueueAdmin,
    isWorkflowExecutionTerminated,
    isTaskComplete,
  } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const {
    data: dsrExecutionsV1,
    dataV2: dsrExecutionsV2,
    isLoading: isLoadingDsrExecutions,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useExecutionsInfinite({
    params: {
      itemsPerPage: 20,
      stepId: stepId || '',
      executionId: workflowExecutionId || '',
      enabled: stepDetails?.activityStepCode === WorkflowActivityCode.INTERNAL_SYSTEM_DSR,
    },
  })

  const canReassign = isRightsQueueAdmin && !isTaskComplete && !isWorkflowExecutionTerminated

  const { data: users, isLoading: isUsersLoading } = useUsers({
    params: {
      active: true,
    },
  })

  const { mutateAsync: resolve, isLoading: isResolving } = useResolveExecution({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.executionResultsInfinite])
      showToast({ content: 'Resolve initiated', type: 'success' })
      navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocationId }))
    },
    onError: () => {
      showToast({ content: 'Unable to resolve', type: 'error' })
    },
  })

  const { mutateAsync: retrySql, isLoading: isRetryingSql } = useRetryExecution({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.executionResultsInfinite])
      showToast({ content: 'Retry initiated', type: 'success' })
      navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocationId }))
    },
    onError: () => {
      showToast({ content: 'Unable to retry', type: 'error' })
    },
  })

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    { title: 'Rights Queue', link: RoutesManager.orchestration.rightsQueue.root.getURL() },
    {
      title: rightInvocation?.policyScopeName,
      link: RoutesManager.orchestration.rightsQueue.view.root.getURL({ id: rightInvocationId }),
    },
    {
      title: 'Execute Internal DSR',
      link: RoutesManager.orchestration.rightsQueue.stepDetails.root.getURL({
        id: rightInvocationId,
        stepId,
        workflowExecutionId,
      }),
    },
  ]

  const isReady =
    isRightsQueueWorkflowTaskDetailViewContainerUtilsReady && !isUsersLoading && !isResolving && !isLoadingDsrExecutions

  return {
    isReady,
    rightInvocationId,
    stepDetails,
    workflowStep,
    workflowExecution,
    rightInvocation,
    stepId,
    workflowExecutionId,
    breadcrumbs,
    resolve,
    isTaskComplete,
    isResolving,
    users,
    canReassign,
    dsrExecutionsV1,
    dsrExecutionsV2,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    retrySql,
    isRetryingSql,
  }
}

export type InternalSystemDsrUtilsType = ReturnType<typeof useInternalSystemDsrUtils>
