import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { RoutesManager } from 'utils/routing/routesManager'
import { getCanDeletePolicyScope } from 'utils/constants/policyScopes'
import { Button } from '@ketch-com/deck'
import Typography from '@mui/material/Typography'
import { DeletePolicyScopeModal } from 'components/modals/policyScopes/DeletePolicyScopeModal'
import Box from '@mui/material/Box'
import { formatLastUpdatedDate } from '../../../../../utils/helpers/dateTime'
import { FirstNameLastNameRenderer } from 'components/renderers/FirstNameLastNameRenderer'

type Props = {
  policyScope: PolicyScopeDTO
  isLatest?: boolean
}

export const PolicyScopeViewActions: React.FC<Props> = ({ policyScope, isLatest }) => {
  const navigate = useNavigate()
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const { isPermitted } = useIsPermitted()

  if (!isLatest) {
    return null
  }

  const { code, audit } = policyScope
  const isPermittedToManagePolicyScopes = isPermitted([PERMISSIONS.JURISDICTION_WRITE])
  const isGlobalCode = getCanDeletePolicyScope(code)

  return (
    <>
      <Box display="flex" gap="12px" alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography color="darkDuskFaded.main" variant="smallBody">
            Last Updated&nbsp;
          </Typography>
          <Typography color="darkDusk.main" variant="smallBody">
            {formatLastUpdatedDate(audit?.updatedAt)}
            <FirstNameLastNameRenderer
              firstName={audit?.updatedBy?.firstName}
              lastName={audit?.updatedBy?.lastName}
              renderEmpty={false}
              prefix="by"
              textVariant="smallBody"
            />
          </Typography>
        </Box>

        {isPermittedToManagePolicyScopes && (
          <>
            <Button
              disabled={!isGlobalCode}
              color="tertiary"
              size="large"
              onClick={() => {
                setIsDeleteOpen(true)
              }}
            >
              Delete
            </Button>

            <Button
              size="large"
              color="secondary"
              onClick={() => {
                navigate(RoutesManager.policyCenter.policyScopes.upsert.root.getURL({ code }))
              }}
            >
              Edit
            </Button>
          </>
        )}
      </Box>

      {isDeleteOpen && (
        <DeletePolicyScopeModal
          code={code}
          onSubmit={() => {
            navigate(RoutesManager.policyCenter.policyScopes.root.getURL())
          }}
          onCancel={() => {
            setIsDeleteOpen(false)
          }}
        />
      )}
    </>
  )
}
