import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { ExperienceCanonicalRightFormTemplate } from 'interfaces/experiences/experience'
import React, { useContext } from 'react'
import { PreferenceExperienceFormValues } from '../../upsert/interfaces/PreferenceExperienceFormValues'
import { serialize } from '../../upsert/utils/common'
import { PreferenceScreenPreview } from './preferencePreview/PreferenceScreenPreview'
import { ExperiencePreviewContext } from '../context/ExperiencePreviewContext'

type Props = {}

export const PreferenceExperiencePreview: React.FC<Props> = () => {
  const {
    activeTheme,
    experienceValues,
    formTemplates,
    isEntitledToConsentAndDisclosure,
    isEntitledToRights,
    isEntitledToSubscriptions,
    isPreviewReady,
    jurisdictionPurposes: purposes,
    jurisdictionRights,
    mode,
  } = useContext(ExperiencePreviewContext)

  if (!isPreviewReady) return <Spinner />

  const { overview, consents, rights, subscriptions } = experienceValues as PreferenceExperienceFormValues

  // This is added via Shoreline so we need to add for lanyard render logic
  const extendedCanonicalRightFormTemplates = rights?.canonicalRightFormTemplates?.map(
    (template: ExperienceCanonicalRightFormTemplate) => ({
      ...template,
      canonicalRightCode: template?.canonicalRightCode || template?.code,
    }),
  )

  const rightsTabPayload = {
    rightsTab: {
      tabName: rights.tabName,
      bodyTitle: rights.bodyTitle,
      bodyDescription: serialize(rights.bodyDescription),
      buttonText: rights.buttonText,
    },
    rights: jurisdictionRights,
    formTemplates,
    canonicalRightFormTemplates: extendedCanonicalRightFormTemplates,
    customRightFormTemplates: rights?.customRightFormTemplates,
  }

  const consentAndDisclosureTabPayload = {
    consentsTab: {
      tabName: consents.tabName,
      bodyTitle: consents.bodyTitle,
      bodyDescription: serialize(consents.bodyDescription),
      buttonText: consents.buttonText,
    },
  }

  const subscriptionsTabPayload = {
    subscriptionsTab: {
      tabName: subscriptions.tabName,
      bodyTitle: subscriptions.bodyTitle,
      bodyDescription: serialize(subscriptions.bodyDescription),
      primaryButtonText: subscriptions.primaryButtonText,
      secondaryButtonText: subscriptions.secondaryButtonText,
      switchOnText: subscriptions.switchOnText,
      switchOffText: subscriptions.switchOffText,
    },
  }

  const overviewTabPayload = {
    tabName: overview.tabName,
    bodyTitle: overview.bodyTitle,
    bodyDescription: serialize(overview.bodyDescription),
  }

  return (
    <PreferenceScreenPreview
      preference={experienceValues}
      mode={mode}
      theme={activeTheme!}
      purposes={purposes || []}
      overviewTab={overviewTabPayload}
      {...(isEntitledToConsentAndDisclosure && consentAndDisclosureTabPayload)}
      {...(isEntitledToRights && rightsTabPayload)}
      {...(isEntitledToSubscriptions && subscriptionsTabPayload)}
    />
  )
}
