import { Box } from '@mui/material'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { FieldArray, useFormikContext } from 'formik'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { StackForm } from '../utils/formValues'
import { TranslationTile } from './TranslationTile'

type Props = { languages: LanguageWithStatusDTO[] }

export const TranslationsForm: React.FC<Props> = ({ languages }) => {
  const {
    values: { groups },
  } = useFormikContext<StackForm>()

  return (
    <FormSection
      title="Translations"
      subTitle="Translations define how these group names will be shown in the experience server, and can be added later."
    >
      <FieldArray
        name="groups"
        render={helpers => (
          <Box>
            {groups.map((group, index) => (
              <TranslationTile key={group.id} group={group} index={index} helpers={helpers} languages={languages} />
            ))}
          </Box>
        )}
      />
    </FormSection>
  )
}
