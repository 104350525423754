export enum DeploymentPlanStatus {
  DEPLOYED = 'Deployed',
  ACTIVE = 'Active',
  PUBLISHED = 'Published',
  INACTIVE = 'Inactive',
  TESTING = 'Testing',
  FULFILLED = 'Fulfilled',
  OVERDUE = 'Overdue',
}

export const SENSITIVE_DEPLOYMENT_PLANS_STATUSES = [
  DeploymentPlanStatus.DEPLOYED,
  DeploymentPlanStatus.ACTIVE,
  DeploymentPlanStatus.PUBLISHED,
  DeploymentPlanStatus.TESTING,
]
