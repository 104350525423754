import React from 'react'

import { Checkbox } from 'components/ui-kit/checkbox/Checkbox'
import { ObjectLiteral } from 'interfaces'
import { isEqual } from 'lodash'

interface Props<T> {
  items: T[]
  selectedDatabases: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  isLoading: boolean
}

export const CheckAllDatabasesCellRenderer = <T extends ObjectLiteral>({
  items,
  setSelected,
  selectedDatabases,
  isLoading,
}: Props<T>) => {
  const getCheckAllValue = () => {
    const currentPageOfResultsAssetCodes = items.map(item => item.code).sort()
    const databaseEntries = [...selectedDatabases].filter(id => currentPageOfResultsAssetCodes.includes(id)).sort()

    if (databaseEntries.length > 0 && isEqual(currentPageOfResultsAssetCodes, databaseEntries)) return true
    if (
      databaseEntries.length > 0 &&
      !isEqual(currentPageOfResultsAssetCodes, databaseEntries) &&
      databaseEntries.some(id => currentPageOfResultsAssetCodes.includes(id))
    )
      return 'indeterminate'
    return false
  }
  const checkAllValue = getCheckAllValue()

  const handleToggleSelectAll = () => {
    if (checkAllValue) {
      setSelected([])
    } else {
      setSelected(items.map(({ code }) => code))
    }
  }

  return isLoading ? null : <Checkbox value={checkAllValue} onChange={() => handleToggleSelectAll()} />
}
