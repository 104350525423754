import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchFormTemplate } from '../fetchers/fetchFormTemplate'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.formTemplate,
  queryFn: fetchFormTemplate,
  select: res => res?.data?.formTemplate || undefined,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useFormTemplate = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
