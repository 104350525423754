import { isBoolean, isUndefined } from 'lodash'

export type APIRequestParams<T> = {
  includeMetadata?: boolean
} & T

export type APIListRequestParams<T = {}> = {
  includeMetadata?: boolean
  limit?: number
  nextToken?: string
  query?: string
  start?: number
  ordering?: Ordering
} & T

export type APIListResponse<T> = {
  totalResults: number
  cursor?: string
} & T

export type Ordering<T extends string | number | symbol = any> = Record<T, 'asc' | 'desc' | true>

export enum OrderBy {
  ASC = 'asc',
  DESC = 'desc',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
}

export const formatOrdering = (ordering?: Ordering) =>
  Object.entries(ordering || {}).map(([key, value]) => (isBoolean(value) ? key : `${key} ${value}`))

export const isQueryEnabled = (enabled?: boolean) => isUndefined(enabled) || enabled
