import React, { useState } from 'react'
import { useQueryClient, QueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/modals/AlertComponent'
import { ApplianceApprovalStatusTypes, ApplianceDTO } from 'interfaces/appliances'
import { DeleteApplianceModal } from './DeleteApplianceModal'
import Box from '@mui/material/Box'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { useApproveAppliance } from 'api/appliances/mutations/useApproveAppliance'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  appliance: ApplianceDTO
  shouldShowButtonOptions?: boolean
}

type HandleRefetchQueriesArgs = {
  queryClient: QueryClient
  appliance: ApplianceDTO
  shouldShowButtonOptions: boolean
}

const handleRefetchQueries = async ({ queryClient, appliance, shouldShowButtonOptions }: HandleRefetchQueriesArgs) => {
  if (shouldShowButtonOptions) {
    /* button options are shown on the list view, thus refetch list */
    await queryClient.invalidateQueries(ApiQueryKeys.appliances)
  } else {
    /* button options are hidden on the detail view, thus refetch individual appliance */
    await queryClient.refetchQueries([ApiQueryKeys.appliance, { applianceId: appliance?.applianceID }])
  }
}

export const AppliancesListDropdown: React.FC<Props> = ({ appliance, shouldShowButtonOptions = true }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [isDeleteApplianceModalOpen, setIsDeleteApplianceModalOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { mutateAsync: handleApproveAppliance, isLoading: isApproveApplianceLoading } = useApproveAppliance({
    onSuccess: async () => {
      showToast({ content: 'Transponder approved.', type: 'success' })
      await handleRefetchQueries({ queryClient, appliance, shouldShowButtonOptions })
    },
    onError: async () => {
      showToast({ content: 'Failed to approve transponder', type: 'error' })
      await handleRefetchQueries({ queryClient, appliance, shouldShowButtonOptions })
    },
  })

  return (
    <>
      <Box display="flex" alignItems="center" gap={1}>
        {(appliance?.approvalStatus || 0) === ApplianceApprovalStatusTypes.UNAUTHORIZED && (
          <Box display="flex" gap={1} alignItems="center">
            <Button
              sx={{
                width: 'max-content',
              }}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation()
                event.preventDefault()
                setIsDeleteApplianceModalOpen(true)
              }}
              color="tertiary"
            >
              Revoke
            </Button>

            <Button
              sx={{
                width: 'max-content',
              }}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation()
                event.preventDefault()
                handleApproveAppliance({
                  params: {
                    applianceId: appliance.applianceID,
                  },
                })
              }}
              pending={isApproveApplianceLoading}
              color="secondary"
            >
              Approve
            </Button>
          </Box>
        )}
        {shouldShowButtonOptions && (
          <>
            <Button size="medium" variant="iconSubtle" onClick={handleClickListItem}>
              <Icon name="OMore" />
            </Button>
            <ActionSheetWrapper
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transitionDuration={0}
              // need this so the accordion does not toggle open state on menu/action sheet close
              onClick={e => e.stopPropagation()}
            >
              <ActionSheetItem
                onClick={e => {
                  handleClose()
                  navigate(RoutesManager.developers.transponders.upsert.getURL({ id: appliance?.applianceID }))
                }}
              >
                Edit
              </ActionSheetItem>

              <ActionSheetItem divider />
              <ActionSheetItem
                onClick={e => {
                  handleClose()
                  setIsDeleteApplianceModalOpen(true)
                }}
              >
                Delete
              </ActionSheetItem>
            </ActionSheetWrapper>
          </>
        )}
      </Box>

      {isDeleteApplianceModalOpen && (
        <DeleteApplianceModal
          applianceId={appliance.applianceID}
          onCancel={() => setIsDeleteApplianceModalOpen(false)}
          onSubmit={async () => {
            setIsDeleteApplianceModalOpen(false)
          }}
        />
      )}
    </>
  )
}
