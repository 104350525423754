/** The following rights should be unchecked by default:
 * When a user does attempt to check one of them,they should be met with an "are you sure" modal per:
 * https://ketch-com.atlassian.net/browse/KD-6448
 * https://ketch-com.atlassian.net/browse/KD-8004
 */
export const optOutRights = [
  'cpra_dnsmpi',
  'ctdpa_dnsmpi',
  'npl_dnsmpi',
  'cpra_lspi',
  'colopaco_dnsmpi',
  'ucpa_dnsmpi',
  'vcdpava_dnsmpi',
  'tdpsa_dnsmpi',
  'ocpa_dnsmpi',
  'mtcdpa_dnsmpi',
]

/** The following regulations should be hidden unless the user has the "demo" permission
 * "Law 25" (code: q25). Note: this regulation is only available in the PROD environment
 */
export const demoOnlyRegulations = ['']
