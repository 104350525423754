import React from 'react'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { DataGrid, FormRow } from '@ketch-com/deck'
import { cookiePurposesGridColumns } from './utils/cookiePurposesDataGridUtils'

type Props = {
  isKetchCookie: boolean
}

export const CookiePurposesFormSection: React.FC<Props> = () => {
  const { data: purposes, isFetching, isLoading } = usePurposes()

  return (
    <FormRow title="Purposes" hasTopBorder>
      <DataGrid
        sx={{
          padding: 'unset',
        }}
        autosizeOnMount
        columns={cookiePurposesGridColumns}
        disableChildrenSorting
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        getRowHeight={() => 'auto'}
        getRowId={row => row.ID}
        hideFooter
        loading={isLoading || isFetching}
        rows={purposes}
      />
    </FormRow>
  )
}
