import { Button } from '@ketch-com/deck'
import { Box, SxProps, Typography, styled } from '@mui/material'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { ReactComponent as FeatureNotAvailableIcon } from 'assets/icons/navigation/feature_not_available.svg'
import { PropsWithChildren, useCallback } from 'react'
import { useIsEntitled } from 'utils/hooks'
import { getDemoFormLink } from 'utils/helpers/getDemoFormLink'
import { useUserMe } from 'api/users/queries/useUserMe'

interface Props extends PropsWithChildren {
  entitlements: ENTITLEMENTS | ENTITLEMENTS[]
  title?: string
  description?: string
  buttonText?: string
  overlaySx?: SxProps
}

const BlurredContainerBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.Common.Divider}`,
  borderRadius: '11px',
  position: 'relative',
  height: '100%',
}))

const BlurredContentBox = styled(Box)(({ theme }) => ({
  background: `${theme.palette.White.o24}`,
  filter: 'blur(15px)',
  pointerEvents: 'none',
}))

const OverlayBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  width: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
})

const TextBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '50px',
  justifyContent: 'space-between',
})

export const EntitledBlurWrapper: React.FC<Props> = ({
  children,
  entitlements,
  title = 'This feature isn’t available with your current subscription.',
  description = 'Please contact our team and get an upgrade!',
  buttonText = 'Contact Sales',
  overlaySx,
}) => {
  const { isEntitledWithDetails } = useIsEntitled()
  const { entitled, missingEntitlements } = isEntitledWithDetails(entitlements)
  const { data: user } = useUserMe()

  const onContactSalesClick = useCallback(() => {
    const salesLink = getDemoFormLink(missingEntitlements, user || undefined)
    window.open(salesLink, '_blank', 'noreferrer')
  }, [missingEntitlements, user])

  // Show children if entitled
  if (entitled) return <>{children}</>

  return (
    <BlurredContainerBox>
      <BlurredContentBox>{children}</BlurredContentBox>
      <OverlayBox sx={overlaySx}>
        <FeatureNotAvailableIcon />
        <TextBox>
          <Typography variant="label" fontSize={16} component="p">
            {title}
          </Typography>
          <Typography variant="body" component="p">
            {description}
          </Typography>
        </TextBox>
        <Button onClick={onContactSalesClick}>{buttonText}</Button>
      </OverlayBox>
    </BlurredContainerBox>
  )
}
