import Box from '@mui/material/Box'
import { styled } from '@mui/styles'
import {
  Avatar,
  AvatarSize,
  Button,
  CustomIconName,
  Icon,
  AvatarPalette,
  ActionSheetWrapper,
  theme,
} from '@ketch-com/deck'
import { TooltipV2 } from 'components/ui-kit/tooltip/TooltipV2'
import { useState } from 'react'
import { Typography } from '@mui/material'
import {
  getContrastColor,
  isValidHexColor,
} from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/getContrastColor'
import { areColorsSimilar } from '../utils/utils'

export type ColorPickerProps = {
  /** Colors to show */
  colors: string[]
  /** How many colors to show before sending the rest to the dropdown */
  listBreakpoint?: number
  /** Should hover to copy be disabled */
  disableHover?: boolean
  /** Should the overflow colors dropdown be disabled */
  noDropdown?: boolean
  /** Disable margins */
  noMargin?: boolean
  /** Only show the dropdown list */
  listOnly?: boolean
  /** List of background colors, so borders are applied when palette color is similar */
  backgroundColors?: string[]
}

const ColorOptionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '11px 0px',
  '&:first-child': {
    paddingTop: '7px',
  },
  '&:last-child': {
    paddingBottom: '7px',
  },
}))

const PickerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const PaletteBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 2,
  marginRight: 6,
}))

const ColorPickerAvatar: React.FC<{ color: string; disableHover?: boolean; backgroundColors?: string[] }> = ({
  color,
  disableHover = false,
  backgroundColors = [],
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [hoverText, setHoverText] = useState(color.toUpperCase())
  const [hoverIconName, setHoverIconName] = useState<CustomIconName>('OCopy')
  const showBorder = backgroundColors.some(bgColor => areColorsSimilar(bgColor, color))

  // Show copied checkmark and text for 2 seconds on click
  const handleClick = () => {
    // Copy the color's hex code to clipboard
    navigator.clipboard.writeText(color.toUpperCase())

    // Show copy icon and text for 2 seconds
    setHoverText('Copied!')
    setHoverIconName('OCheck')
    setTimeout(() => {
      setHoverText(color.toUpperCase())
      setHoverIconName('OCopy')
    }, 2000)
  }

  const iconColor = getContrastColor(color, '#000000', '#FFFFFF')

  return (
    <TooltipV2 title={disableHover ? '' : hoverText} placement={'bottom'} arrow={false}>
      <span>
        <Avatar
          icon={isHovered ? hoverIconName : undefined}
          textColor={iconColor}
          backgroundColor={color}
          key={color}
          onClick={handleClick}
          onMouseEnter={() => setIsHovered(!disableHover)}
          onMouseLeave={() => setIsHovered(false)}
          borderColor={showBorder ? 'lightGrey' : undefined}
        >
          {/* This removes the default fallback icon so an empty circle is shown */}
          <></>
        </Avatar>
      </span>
    </TooltipV2>
  )
}

const ColorPickerListCopyButton: React.FC<{ value: string }> = ({ value }) => {
  const [copyHoverText, setCopyHoverText] = useState('')

  const handleClick = () => {
    navigator.clipboard.writeText(value)
    setCopyHoverText('Copied!')
    setTimeout(() => {
      setCopyHoverText('')
    }, 2000)
  }

  return (
    <TooltipV2 title={copyHoverText} placement="right" arrow={false}>
      <Button variant={'iconSubtle'} onClick={handleClick} sx={{ ml: 'auto', width: 20, height: 20 }}>
        <Icon name={'OCopy'} width={20} height={20} />
      </Button>
    </TooltipV2>
  )
}

export const ColorPickerMenu: React.FC<{ colors: string[]; listOnly?: boolean }> = ({ colors, listOnly = false }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        id="more-colors-menu-button"
        variant={'text'}
        endIcon={<Icon name={'OArrowCDown'} iconColor={theme.palette.darkDusk.main} color={'warning'} />}
        onClick={handleClick}
        sx={{ backgroundColor: theme.palette.fadedGrey.main, minWidth: '80px' }}
      >
        <Typography variant="smallLabel" color={theme.palette.darkDusk.main}>
          {listOnly ? 'Colors' : `${colors.length} more`}
        </Typography>
      </Button>
      <ActionSheetWrapper open={open} onClose={handleClose} anchorEl={anchorEl}>
        {colors.map((color, index) => (
          <ColorOptionBox
            sx={{
              ...(index !== 0 ? { borderTop: `1px solid ${theme.palette.iron.main}` } : {}),
            }}
            key={color}
          >
            <AvatarPalette
              backgroundColor={color}
              size={AvatarSize.small}
              borderColor={areColorsSimilar(color, '#FFFFFF') ? 'lightGrey' : undefined}
            />
            <Typography variant={'body'} ml="6px">
              {color.toUpperCase()}
            </Typography>
            <ColorPickerListCopyButton value={color.toUpperCase()} />
          </ColorOptionBox>
        ))}
      </ActionSheetWrapper>
    </>
  )
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  colors,
  listBreakpoint = 7,
  disableHover = false,
  noDropdown = false,
  noMargin = false,
  listOnly = false,
  backgroundColors = [],
}) => {
  const validColors = colors.filter(color => isValidHexColor(color))

  return (
    <PickerBox sx={{ mx: noMargin ? 0 : '32px' }}>
      {!listOnly && (
        <PaletteBox>
          {validColors.slice(0, listBreakpoint).map(color => (
            <ColorPickerAvatar
              color={color}
              key={color}
              disableHover={disableHover}
              backgroundColors={backgroundColors}
            />
          ))}
        </PaletteBox>
      )}

      {/* Show dropdown with remaining colors if there are more than the listBreakpoint value */}
      {validColors.length > listBreakpoint && !noDropdown && (
        <ColorPickerMenu colors={listOnly ? colors : validColors.slice(listBreakpoint)} listOnly={listOnly} />
      )}
    </PickerBox>
  )
}
