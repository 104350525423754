import React, { memo } from 'react'
import { Layer } from 'react-konva'
import { isUndefined } from 'lodash'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { CANVAS_START_TILE_POSITION } from 'pages/orchestration/workflows/edit/utils/constants'
import { StartFlow } from 'pages/orchestration/workflows/edit/konvaEntities/StartFlow'

interface IItemsLayerProps {
  steps: Array<ICanvasStep>
}

export const ItemsLayer: React.FC<IItemsLayerProps> = memo(props => {
  const { steps = [] } = props
  const startStep = steps.filter(({ start }) => !isUndefined(start))[0]

  return (
    <Layer>
      <StartFlow gridPosition={CANVAS_START_TILE_POSITION} step={startStep} steps={steps} />
    </Layer>
  )
})
