import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { KETCH_DML } from 'pages/developers/hooks/constants'

type Args = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export const useAssetSummaryAssignedHooks = ({ assetSummary }: Args) => {
  const hooks = [
    ...(assetSummary?.asset?.dmlPreHooks || []),
    {
      assignmentCount: 9999,
      dmlCode: 'dummy',
      id: 'dummy',
      name: KETCH_DML,
      operation: 99999,
      technology: 'dummy',
      type: 99,
    },
    ...(assetSummary?.asset?.dmlPostHooks || []),
  ]
  const assignedHooksCount = hooks?.length > 1 ? hooks?.length - 1 : 0

  return {
    hooks,
    assignedHooksCount,
  }
}
