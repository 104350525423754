import Grid from '@mui/material/Grid'

import { ThemeDTO } from 'interfaces/themes-v2/theme'
import { SectionTitle } from 'components/ui-kit/typography/compositions/SectionTitle'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { SubSectionTitle } from 'components/ui-kit/typography/compositions/SubSectionTitle'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { ThemeExperienceDetails } from 'pages/consentAndRights/themes/view/components/ThemeExperienceDetails'
import { useThemeItems } from '../hooks'

type Props = {
  theme: ThemeDTO
}

export const ThemeDetailsTab: React.FC<Props> = ({ theme }) => {
  const { buttonBorderRadius = 0 } = theme
  const { bannerItems, modalItems, formItems } = useThemeItems({ theme })

  return (
    <>
      <SectionTitle>General</SectionTitle>

      <FormRow>
        <Grid container>
          <Grid item xs={3}>
            <SubSectionTitle>Typeface</SubSectionTitle>
            <EmptyValueRenderer />
          </Grid>
          <Grid item xs={3}>
            <SubSectionTitle>Buttons Corner Radius</SubSectionTitle>
            <TextCellRenderer value={buttonBorderRadius} />
          </Grid>
        </Grid>
      </FormRow>

      <ThemeExperienceDetails title="Banner" items={bannerItems} />
      <ThemeExperienceDetails title="Modal" items={modalItems} />
      <ThemeExperienceDetails title="Form" items={formItems} />
    </>
  )
}
