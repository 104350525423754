import { API } from '../../../utils/api/api'
import { FetchProcessingActivityFiltersResponseBodyDTO } from '@ketch-com/figurehead'
import { formatRequestString } from '../../../utils/helpers/api/queryString'

export const fetchProcessingActivityFilters = () =>
  API.get<FetchProcessingActivityFiltersResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/processing-activities/filters`,
    }),
  )
