import * as Yup from 'yup'

export const getValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    technology: Yup.string().required('Required'),
    operation: Yup.string().required('Required'),
    code: Yup.string().required('Required'),
  })
