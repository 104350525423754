import React, { useState } from 'react'
import { AlertColor } from '@mui/material'
import { Alert } from '@ketch-com/deck'

type showToastProps = {
  content: any
  type: AlertColor
  autoHideDuration?: number
}

type Props = {}

export let showToast: (params: showToastProps) => void = () => {}

export const AlertComponent: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [toastType, setToastType] = useState<AlertColor | undefined>(undefined)
  const [toastContent, setToastContent] = useState<any>(null)
  const [autoHideDuration, setAutoHideDuration] = useState<number>(1500)

  const handleHide = () => {
    setIsOpen(false)
  }

  showToast = (params: showToastProps) => {
    setToastType(params.type)
    setToastContent(params.content)
    setAutoHideDuration(params?.autoHideDuration || 1500)
    setIsOpen(true)
  }

  return (
    <Alert
      severity={toastType}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={autoHideDuration}
      open={isOpen}
      onClose={handleHide}
    >
      {toastContent}
    </Alert>
  )
}
