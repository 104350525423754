import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { UpdateTrackerDescriptionBody } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type Params = APIRequestParams<{
  requestBody: UpdateTrackerDescriptionBody
  encodedTrackerKey: string
}>

export const upsertTrackerDescription = ({ encodedTrackerKey, requestBody }: Params) => {
  return API.put(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/tracker/${encodedTrackerKey}/description`,
      params: {
        filters: {},
      },
    }),
    requestBody,
  )
}
