import { ChartTrendPeriod } from 'interfaces/rights/rightStats'

type Args = {
  trendPeriod: ChartTrendPeriod
  startEndDifferenceInDays?: number
}

export const getXAxisDataKey = ({ trendPeriod, startEndDifferenceInDays = 7 }: Args) => {
  if (trendPeriod === ChartTrendPeriod.MONTHLY_PAST_TWELVE) return 'monthNameYearAbbreviation'
  if (trendPeriod === ChartTrendPeriod.DAILY && startEndDifferenceInDays < 10) return 'name'
  if (startEndDifferenceInDays > 9) return 'monthDateName'
  return 'name'
}
