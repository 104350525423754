import { ApplianceWork } from '@ketch-com/windlass/dist/appliance/appliances'
import { getInputSubjectName } from './getInputSubjectName'

export const createInputSubjectList = (data?: ApplianceWork[]) => {
  const memo: Record<string, true> = {}
  const list = [{ name: 'All Input Subjects' }]

  if (!data) return list

  data.forEach(item => {
    if (!item.inputSubject) return
    const inputSubject = { name: getInputSubjectName(item.inputSubject), id: item.inputSubject }
    if (!memo[inputSubject.name]) {
      memo[inputSubject.name] = true
      list.push(inputSubject)
    }
  })
  return list
}
