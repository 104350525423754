type DataItem = { id: string; name: string; code: string }
export type DataAssetsDedupedInputItem = { [key: string]: DataItem[] }

export function dedupeWithinItemsByKeys(items: { [key: string]: any }[], keys: string[]): DataAssetsDedupedInputItem[] {
  return items.map(item => {
    const dedupedItem: DataAssetsDedupedInputItem = {}

    // Deduplicate each specified key within this item
    keys.forEach(key => {
      if (item[key]) {
        const uniqueMap = (item[key] as DataItem[]).reduce(
          (acc, val) => {
            if (!acc[val.id]) {
              acc[val.id] = val
            }
            return acc
          },
          {} as Record<string, DataItem>,
        )

        // Set the deduplicated array to the key in the new item
        dedupedItem[key] = Object.values(uniqueMap)
      }
    })

    // Copy any other properties that aren't in the specified keys array
    Object.keys(item).forEach(key => {
      if (!keys.includes(key)) {
        dedupedItem[key] = item[key]
      }
    })

    return dedupedItem
  })
}
