import Box from '@mui/material/Box'
import { Tab, TabPanel, TabGroup } from '@ketch-com/deck'
import React, { useContext, useEffect } from 'react'
import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { SidebarFormRadioGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormRadioGroup'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { ItemStyle } from '@ketch-sdk/ketch-types'
import { ItemStyleLabels } from '../../../../../../experiences-v2/upsert/utils/labels'
import { FormColorInput } from '../FormColorInput'
import { getIn, useFormikContext } from 'formik'
import {
  BuilderTextInputMode,
  FormTextInput,
} from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { handleButtonStyleChange } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'
import { ThemeUpsertContext } from 'pages/consentAndRights/themes-v3/upsert/context/ThemeUpsertContext'
import { PreferenceThemeBuilderSection } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'

const themeBuilderSectionTabMap: { [section: string]: number } = {
  [PreferenceThemeBuilderSection.PurposesTabListHeader]: 0,
  [PreferenceThemeBuilderSection.PurposesTabListHeaderAcceptAll]: 1,
  [PreferenceThemeBuilderSection.PurposesTabListHeaderRejectAll]: 2,
}

export const PreferenceThemePurposesTabListHeaderForm: React.FC = () => {
  const { values, setFieldValue, isSubmitting } = useFormikContext<ThemeV3DTO>()
  const { themeBuilderSection } = useContext(ThemeUpsertContext)

  // Current tab of the button form
  const [tab, setTab] = React.useState(0)

  // Update the current tab of this form when the theme builder section changes
  useEffect(() => {
    if (themeBuilderSection) {
      setTab(themeBuilderSectionTabMap[themeBuilderSection])
    } else {
      setTab(0)
    }
  }, [themeBuilderSection, isSubmitting])

  return (
    <>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
            <Tab size={'medium'} label="List Title" />
            <Tab size={'medium'} label='"Accept All" Button' />
            <Tab size={'medium'} label='"Reject All" Button' />
          </TabGroup>
        </Box>
        {/* List title form */}
        <TabPanel value={tab} index={0} sx={{ px: 0 }}>
          <SidebarFormGroup sx={{ paddingTop: 0 }}>
            <FormColorInput
              name={themeUpsertFormKeys.preferencePurposesTabListHeaderTitleColor}
              label={'List title Color'}
              fullWidth
              required
            />
          </SidebarFormGroup>
        </TabPanel>

        {/* Accept all button form */}
        <TabPanel value={tab} index={1} sx={{ px: 0 }}>
          <SidebarFormGroup sx={{ paddingTop: 0 }}>
            <SidebarFormRadioGroup
              name={themeUpsertFormKeys.preferencePurposesTabListHeaderAcceptAllButtonStyle}
              title={'Style'}
              options={Object.values(ItemStyle).map(style => ({
                label: ItemStyleLabels[style],
                value: style,
              }))}
              row
              onChange={newStyle =>
                handleButtonStyleChange(
                  values,
                  setFieldValue,
                  newStyle,
                  themeUpsertFormKeys.preferencePurposesTabListHeaderAcceptAllButtonTextColor,
                )
              }
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferencePurposesTabListHeaderAcceptAllButtonBackgroundColor}
              label={
                getIn(values, themeUpsertFormKeys.preferencePurposesTabListHeaderAcceptAllButtonStyle) ===
                ItemStyle.Filled
                  ? 'Background Color'
                  : 'Border Color'
              }
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferencePurposesTabListHeaderAcceptAllButtonTextColor}
              label={'Text Color'}
              fullWidth
              required
            />
            <FormTextInput
              name={themeUpsertFormKeys.preferencePurposesTabListHeaderAcceptAllButtonCornerRadius}
              label={'Corner Radius (px)'}
              mode={BuilderTextInputMode.CornerRadius}
              inputWidth="92px"
            />
          </SidebarFormGroup>
        </TabPanel>

        {/* Reject all button form */}
        <TabPanel value={tab} index={2} sx={{ px: 0 }}>
          <SidebarFormGroup sx={{ paddingTop: 0 }}>
            <SidebarFormRadioGroup
              name={themeUpsertFormKeys.preferencePurposesTabListHeaderRejectAllButtonStyle}
              title={'Style'}
              options={Object.values(ItemStyle).map(style => ({
                label: ItemStyleLabels[style],
                value: style,
              }))}
              row
              onChange={newStyle =>
                handleButtonStyleChange(
                  values,
                  setFieldValue,
                  newStyle,
                  themeUpsertFormKeys.preferencePurposesTabListHeaderRejectAllButtonTextColor,
                )
              }
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferencePurposesTabListHeaderRejectAllButtonBackgroundColor}
              label={
                getIn(values, themeUpsertFormKeys.preferencePurposesTabListHeaderRejectAllButtonStyle) ===
                ItemStyle.Filled
                  ? 'Background Color'
                  : 'Border Color'
              }
              fullWidth
              required
            />
            <FormColorInput
              name={themeUpsertFormKeys.preferencePurposesTabListHeaderRejectAllButtonTextColor}
              label={'Text Color'}
              fullWidth
              required
            />
            <FormTextInput
              name={themeUpsertFormKeys.preferencePurposesTabListHeaderRejectAllButtonCornerRadius}
              label={'Corner Radius (px)'}
              inputWidth="92px"
              mode={BuilderTextInputMode.CornerRadius}
              sx={{ width: '90px' }}
            />
          </SidebarFormGroup>
        </TabPanel>
      </Box>
    </>
  )
}
