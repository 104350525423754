import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { IsValidApplianceResponse, CreateApplianceRequest } from '@ketch-com/windlass/dist/appliance/validation'

export const validateTransponderName = (data: CreateApplianceRequest) => {
  let entityUrl = `/api/asset-manager/validation/appliances`
  return API.post<IsValidApplianceResponse>(
    formatRequestString({
      entityUrl,
    }),
    data,
  )
}
