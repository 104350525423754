import React, { useContext } from 'react'
import { Typography } from '@mui/material'
import { PopUp, Button } from '@ketch-com/deck'
import { CustomSqlContext } from 'pages/assetManager/scenario/details/components/CustomSql/context'

type Props = {}

export const CustomSqlDeleteModal: React.FC<Props> = () => {
  const { customSqls = [], customSqlModalData, setCustomSqlModalData, deleteCustomSql } = useContext(CustomSqlContext)

  const selectedCustomSql = customSqls.filter(sql => sql.id === customSqlModalData?.customSqlId)?.[0]
  return (
    <PopUp
      variant="dialog"
      title="Delete Custom SQL"
      popUpWidth="778px"
      onClose={() => setCustomSqlModalData(null)}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={() => setCustomSqlModalData(null)}>
            No
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={() => {
              deleteCustomSql({ params: { customSqlId: customSqlModalData?.customSqlId || '' } })
            }}
          >
            Yes
          </Button>
        </>
      }
    >
      <Typography variant="body">
        Are you sure you want to delete <Typography variant="label">{selectedCustomSql?.name}?</Typography>
      </Typography>
    </PopUp>
  )
}
