import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { PutSubscriptionTopicRequestBodyDTO } from '@ketch-com/figurehead'

type Params = APIRequestParams<{
  data: PutSubscriptionTopicRequestBodyDTO
  code: string
}>

export const updateSubscriptionTopic = ({ data, code }: Params) => {
  return API.put<PutSubscriptionTopicRequestBodyDTO>(
    formatRequestString({
      entityUrl: `/api/subscriptions/topics/${code}`,
    }),
    data,
  )
}
