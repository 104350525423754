import { useNavigate } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import { useBusinessFunctions } from 'api/businessFunctions/queries/useBusinessFunctions'
import { Button, ListLayout, Icon, Spinner, TextInput } from '@ketch-com/deck'
import { FormMode } from 'interfaces/formModes/formMode'
import { RoutesManager } from 'utils/routing/routesManager'
import { BusinessFunctionTile } from './components/BusinessFunctionTile'
import { showToast } from 'components/modals/AlertComponent'
import { useState } from 'react'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

export const BusinessFunctionList = () => {
  const navigate = useNavigate()
  const { data, isLoading, refetch, isRefetching } = useBusinessFunctions({
    params: {},
    onError: () => {
      showToast({ content: 'Failed to fetch business functions list', type: 'error' })
    },
  })
  const [searchText, setSearchText] = useState('')
  const businessFunctions = data.filter(
    businessFunction => businessFunction?.name?.toLowerCase().includes(searchText.toLowerCase()),
  )

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Business Functions', link: RoutesManager.policyCenter.businessFunctions.list.root.getURL() },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ListLayout
        headerTitle="Business Functions"
        headerProps={{
          isDivider: true,
        }}
        actionHeaderBottomBlockComponent={
          <Box display="flex" justifyContent="space-between" width="100%">
            <TextInput
              value={searchText}
              startIcon={<Icon name="OMag" />}
              size="small"
              placeholder="Search..."
              onChange={event => setSearchText(event.target.value)}
            />
            <Button
              onClick={() =>
                navigate(
                  RoutesManager.policyCenter.businessFunctions.upsert.root.getURL({
                    formMode: FormMode.CREATE,
                    code: 'new',
                  }),
                )
              }
            >
              Create Business Function
            </Button>
          </Box>
        }
      >
        {isLoading || isRefetching ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%" m={6}>
            <Spinner size="32px" thickness={2.5} />
          </Box>
        ) : (
          <Grid container rowSpacing={4} columnSpacing={2.5}>
            {businessFunctions.map(bfn => (
              <BusinessFunctionTile onDelete={refetch} businessFunction={bfn} key={bfn.code} />
            ))}
          </Grid>
        )}
      </ListLayout>
    </>
  )
}
