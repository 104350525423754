export enum ExperienceStatus {
  DEPLOYED = 'Deployed',
  ACTIVE = 'Active',
  PUBLISHED = 'Published',
  TESTING = 'Testing',
  FULFILLED = 'Fulfilled',
  OVERDUE = 'Overdue',
  INACTIVE = 'Inactive',
}

export const SENSITIVE_EXPERIENCE_STATUSES = [
  ExperienceStatus.DEPLOYED,
  ExperienceStatus.ACTIVE,
  ExperienceStatus.PUBLISHED,
  ExperienceStatus.TESTING,
]
