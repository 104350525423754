export enum CookieProvenance {
  FIRST_PARTY = 1,
  THIRD_PARTY = 2,
}

export const CookieProvenanceNames = {
  [CookieProvenance.FIRST_PARTY]: '1st-party',
  [CookieProvenance.THIRD_PARTY]: '3rd-party',
}

export const CookieProvenanceOptions = [
  { id: CookieProvenance.FIRST_PARTY, name: CookieProvenanceNames[CookieProvenance.FIRST_PARTY] },
  { id: CookieProvenance.THIRD_PARTY, name: CookieProvenanceNames[CookieProvenance.THIRD_PARTY] },
]
