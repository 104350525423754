import React, { useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { mapValues } from 'lodash'

import { MaybeNull } from 'interfaces/common'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { ApplicationDTO } from 'interfaces/applications/application'
import { RoutesManager } from 'utils/routing/routesManager'
import {
  getInitialValues,
  DeploymentPlanDeployFormValues,
  isDeploymentOverwritten,
  getSelectionMetadata,
} from 'pages/consentAndRights/deploymentPlans-v2/deploy/utils'
import { SectionTitle } from 'components/ui-kit/typography/compositions/SectionTitle'
import { Checkbox } from 'components/ui-kit/checkbox/Checkbox'
import { Group } from 'components/ui-layouts/group/Group'
import { Button } from '@ketch-com/deck'
import { UpsertLayout } from 'components/ui-layouts/upsertLayout/UpsertLayout'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { UpsertLayoutFooter } from 'components/ui-layouts/upsertLayout/UpsertLayoutFooter'
import { DeploymentPlanApplicationSection } from 'pages/consentAndRights/deploymentPlans-v2/deploy/DeploymentPlanApplicationSection'

const PREFIX = 'DeploymentPlanDeploy'

const classes = {
  totalControls: `${PREFIX}-totalControls`,
  subTitle: `${PREFIX}-subTitle`,
}

const StyledUpsertLayout = styled(UpsertLayout)({
  [`& .${classes.totalControls}`]: {
    paddingLeft: 18,
  },
  [`& .${classes.subTitle}`]: {
    display: 'block',
    marginTop: 24,
    marginBottom: 12,
  },
})

type Props = {
  isReady: boolean
  deploymentPlan: MaybeNull<DeploymentPlanDTO>
  applications: ApplicationDTO[]
  onSubmit: (values: DeploymentPlanDeployFormValues) => Promise<void>
}

export const DeploymentPlanDeploy: React.FC<Props> = ({ isReady, deploymentPlan, applications, onSubmit }) => {
  const navigate = useNavigate()
  const [isOverwriteModalOpen, setIsOverwriteModalOpen] = useState(false)

  const name = deploymentPlan?.name
  const code = deploymentPlan?.code

  const deploymentPlanViewUrl = RoutesManager.deployment.deploymentPlansV2.view.root.getURL({ code })

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Deployment Plans', link: RoutesManager.deployment.deploymentPlansV2.root.getURL() },
    {
      title: name || code,
      link: deploymentPlanViewUrl,
    },
    { title: 'Deploy Plan' },
  ]

  const initialValues = useMemo(
    () => getInitialValues({ deploymentPlan, applications }),
    [deploymentPlan, applications],
  )

  return (
    <StyledUpsertLayout
      breadcrumbs={breadcrumbs}
      title="Deploy Plan"
      isReady={isReady}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm, values, setValues }) => {
        const { selectedCount, totalCount, value } = getSelectionMetadata(values)

        return (
          <Box px={2.25}>
            <div className={classes.totalControls}>
              <SectionTitle>Select Properties</SectionTitle>

              <Checkbox
                id="selected-total"
                name="selected-total"
                value={value}
                title={`${selectedCount}/${totalCount} Selected`}
                onChange={() => {
                  const nextValue = value === 'indeterminate' || !value

                  const nextValues = mapValues(values, ({ opened, environments }) => ({
                    opened: nextValue || opened,
                    environments: mapValues(environments, () => nextValue),
                  }))

                  setValues(nextValues)
                }}
              />
            </div>

            <div>
              {applications.map(application => (
                <DeploymentPlanApplicationSection key={application.ID} application={application} />
              ))}
            </div>

            <UpsertLayoutFooter>
              <Group>
                <Button
                  pending={isSubmitting}
                  color="secondary"
                  size="large"
                  onClick={() => {
                    navigate(deploymentPlanViewUrl)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  onClick={() => {
                    if (isDeploymentOverwritten({ values, deploymentPlan: deploymentPlan! })) {
                      setIsOverwriteModalOpen(true)
                    } else {
                      submitForm()
                    }
                  }}
                  pending={isSubmitting}
                >
                  Save
                </Button>
              </Group>
            </UpsertLayoutFooter>

            {isOverwriteModalOpen && (
              <Dialog
                title="Update Deployment"
                submitBtnLabel="Continue"
                onSubmitBtnClick={() => {
                  setIsOverwriteModalOpen(false)
                  submitForm()
                }}
                onCancelBtnClick={() => {
                  setIsOverwriteModalOpen(false)
                }}
              >
                This action will overwrite current deployment plan associations for selected environments, are you sure
                you want to continue?
              </Dialog>
            )}
          </Box>
        )
      }}
    </StyledUpsertLayout>
  )
}
