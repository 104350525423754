import { ContextVariableDTO } from '@ketch-com/figurehead'
import { getTypesString, getVariableTypesString } from '../utils'
import { Button, PopUp, InfoRow } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

type Props = {
  contextVariable?: ContextVariableDTO
  onEdit?: () => void
  onCancel: () => void
}

export const ViewContextVariablesModal: React.FC<Props> = ({ onCancel, contextVariable = {}, onEdit }) => {
  const { name, code, type, values, category } = contextVariable
  const items = [
    {
      label: 'Code',
      value: <Typography variant="label">{code}</Typography>,
    },
    {
      label: 'Variable Type',
      value: <Typography variant="label">{getVariableTypesString(category)}</Typography>,
    },
    {
      label: 'Data Type',
      value: <Typography variant="label">{getTypesString(type || -1)}</Typography>,
    },
  ]

  if (values?.length) {
    values?.forEach?.((typeValue, index) =>
      items.push({
        label: `Item ${index + 1}`,
        value: <Typography>{typeValue}</Typography>,
      }),
    )
  }

  return (
    <PopUp
      sx={{
        '& .MuiPaper-root': {
          '& .MuiDialogTitle-root': {
            wordBreak: 'break-word',
          },
        },
      }}
      variant="modal"
      title={name}
      onClose={onCancel}
      popUpWidth="774px"
      popUpActionChildren={
        <>
          {!!onEdit && (
            <Button color="tertiary" size="large" onClick={onEdit}>
              Edit
            </Button>
          )}
          <Button color="secondary" size="large" onClick={onCancel}>
            Close
          </Button>
        </>
      }
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography variant="h3">Overview</Typography>
        {items.map((elem, index) => {
          return (
            <InfoRow key={index} title={elem.label}>
              <Box sx={{ wordBreak: 'break-word' }}>{elem.value}</Box>
            </InfoRow>
          )
        })}
      </Box>
    </PopUp>
  )
}
