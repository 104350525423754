import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'

interface IGetTransitionsToStep {
  step?: ICanvasStep
  steps: Array<ICanvasStep>
}

export const getTransitionToStep = (params: IGetTransitionsToStep): Array<ICanvasStep> => {
  const { step, steps } = params

  if (!step) {
    return []
  }

  return (
    steps?.filter(
      currentStep =>
        currentStep?.activity?.transition === step?.ID ||
        currentStep?.workflow?.transition === step?.ID ||
        currentStep?.placeholder?.transition === step?.ID ||
        currentStep?.gateway?.transitions.some(transition => transition.ID === step?.ID),
    ) || []
  )
}
