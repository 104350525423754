import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchRightInvocationV2 } from 'api/rightInvocationsV2/fetchers/fetchRightInvocationV2'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.rightInvocationV2,
  queryFn: fetchRightInvocationV2,
  select: res => res?.data?.rightInvocation || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useRightInvocationV2 = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
