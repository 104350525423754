import React from 'react'
import { SxProps, Theme, Box, Typography } from '@mui/material'
import { useField, FieldValidator } from 'formik'

import { Checkbox, CheckboxProps } from '@ketch-com/deck'
import { uniqueId } from 'lodash'
import { FormError } from './FormError'

type OmittedProps = Omit<CheckboxProps, 'name' | 'value'>

interface Props extends OmittedProps {
  /** Formik field name */
  name: string
  /** Text to be displayed below Component */
  hint?: string
  /** Text to be displayed above Component */
  label?: React.ReactNode
  /** Display Asterisk as required field */
  required?: boolean
  /** Custom validation method */
  validate?: FieldValidator
  /** Should label be displayed inline? (Default: false) */
  inlineLabel?: boolean
  /** Custom onChange method (should change formValues on top level) */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  /** Prop to provide sx style overrides */
  sx?: SxProps<Theme>
}

/**
 * -
 */
export const FormCheckbox: React.FC<Props> = ({
  name,
  hint,
  label,
  validate,
  onChange,
  className,
  inlineLabel = false,
  sx,
  id = uniqueId(),
  ...checkboxProps
}) => {
  const [field, { error, touched }] = useField({ name, validate, type: 'checkbox' })
  const showError = error && touched

  return (
    <Box display="flex" flexDirection="column" className={className} sx={sx}>
      {!inlineLabel && label && <Typography mb="2px">{label}</Typography>}

      <Box display="inline-flex" alignItems="center">
        <Checkbox {...checkboxProps} {...field} onChange={onChange || field.onChange} id={id} />
        {inlineLabel && label && (
          <Typography component="label" htmlFor={id} ml={1} variant={field.value ? 'label' : 'body'}>
            {label}
          </Typography>
        )}
      </Box>

      {showError && <FormError msg={error} sx={{ fontSize: 12 }} />}

      {!!hint && !showError && <Typography variant="smallLabel">{hint}</Typography>}
    </Box>
  )
}
