import React, { useContext } from 'react'
import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormColorInput } from '../FormColorInput'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { SidebarFormRadioGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormRadioGroup'
import { ItemStyle } from '@ketch-sdk/ketch-types'
import { ItemStyleLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { handleButtonStyleChange } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'
import { useFormikContext } from 'formik'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { FormCheckbox } from 'components/form/FormCheckbox'
import { FormControlLabel, Typography } from '@mui/material'
import {
  BuilderTextInputMode,
  FormTextInput,
} from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import { AppNavigationContext } from 'components/appLayout/appNavigation/context/AppNavigationContext'

export const ModalThemeFooterForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ThemeV3DTO>()
  const { isOrgOnFreePlan, isOrgOnStarterPlan } = useContext(PlanAndBillingContext)
  const { setShowNotEntitledModal } = useContext(AppNavigationContext)

  return (
    <>
      <SidebarFormGroup sx={{ pt: 0, gap: '16px' }} hasBorderBottom>
        <FormColorInput
          name={themeUpsertFormKeys.modalFooterBackgroundColor}
          label={'Footer Background Color'}
          fullWidth
          required
        />
      </SidebarFormGroup>
      <SidebarFormGroup hasBorderBottom>
        <FormControlLabel
          control={
            <FormCheckbox
              name={themeUpsertFormKeys.modalFooterKetchBadgeVisible}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if ((isOrgOnFreePlan || isOrgOnStarterPlan) && values?.content?.modal?.footer?.ketchBadgeVisible) {
                  setShowNotEntitledModal(true)
                  return
                }
                setFieldValue(
                  'content.modal.footer.ketchBadgeVisible',
                  !values?.content?.modal?.footer?.ketchBadgeVisible,
                )
              }}
            />
          }
          label={<Typography variant={'label'}>Show "Powered by Ketch" Badge</Typography>}
        />
      </SidebarFormGroup>
      <SidebarFormGroup title={'Action Button'}>
        <SidebarFormRadioGroup
          name={themeUpsertFormKeys.modalFooterActionButtonStyle}
          title={'Style'}
          options={Object.values(ItemStyle).map(style => ({
            label: ItemStyleLabels[style],
            value: style,
          }))}
          row
          onChange={newStyle =>
            handleButtonStyleChange(
              values,
              setFieldValue,
              newStyle,
              themeUpsertFormKeys.modalFooterActionButtonTextColor,
            )
          }
        />
        <FormColorInput
          name={themeUpsertFormKeys.modalFooterActionButtonBackgroundColor}
          label={'Action Button Color'}
          fullWidth
          required
        />
        <FormColorInput
          name={themeUpsertFormKeys.modalFooterActionButtonTextColor}
          label={'Action Button Text Color'}
          fullWidth
          required
        />
        <FormTextInput
          name={themeUpsertFormKeys.modalFooterActionButtonCornerRadius}
          label="Action Button Corner Radius"
          inputWidth="92px"
          mode={BuilderTextInputMode.CornerRadius}
        />
      </SidebarFormGroup>
    </>
  )
}
