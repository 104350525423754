import { Icon, TableCell } from '@ketch-com/deck'
import { Box, Tooltip } from '@mui/material'
import { getTooltipContent } from 'components/ui-layouts/table/cellRenderers/IssuesCellRenderer'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { useTheme } from '@mui/styles'

export const CookiesListIssuesCell = ({ hasIssues }: { hasIssues?: boolean }) => {
  const theme = useTheme()
  if (hasIssues) {
    return (
      <TableCell>
        <Tooltip title={getTooltipContent(ApplicationEntity.COOKIES)}>
          <Box>
            <Icon iconColor={theme.palette.chileanFire.main} name="FImportant" fontSize="small" />
          </Box>
        </Tooltip>
      </TableCell>
    )
  }
  return <TableCell></TableCell>
}
