import React, { useState } from 'react'
import { useFormikContext } from 'formik'

import { RightDTO } from 'interfaces/rights/right'
import { FormRow, EmptyState } from '@ketch-com/deck'
import { ConfirmCheckRightModal } from '../ConfirmCheckRightModal'
import { PolicyScopeFormValues } from '../../interfaces'
import { RightsTableWithAppeals, SelectAllRightsCheckbox } from './components'
import { PolicyScopeAppeals } from '../PolicyScopeAppeals'

type Props = {
  rights: RightDTO[]
}

export const PolicyScopeRights: React.FC<Props> = ({ rights }) => {
  const [rightToConfirmCode, setRightToConfirmCode] = useState('')
  const {
    values: { rightsFulfillment, ...restValues },
    setValues,
  } = useFormikContext<PolicyScopeFormValues>()
  const shouldShowAppeals = rightsFulfillment.some(({ allowAppeal }) => allowAppeal)

  return (
    <>
      <FormRow
        title="Rights"
        subTitle="The rights selected below are based on regulations currently selected, but can be updated as you choose."
      >
        {rightsFulfillment.length ? (
          <>
            <SelectAllRightsCheckbox />

            <RightsTableWithAppeals rights={rights} setRightToConfirmCode={setRightToConfirmCode} />
          </>
        ) : (
          <EmptyState iconName="OProfile" title="No rights are currently associated with selected regulations" />
        )}

        {!!rightToConfirmCode && (
          <ConfirmCheckRightModal
            onCancel={() => setRightToConfirmCode('')}
            onSubmit={() => {
              setValues({
                ...restValues,
                rightsFulfillment: rightsFulfillment.map(fulfillment => {
                  if (fulfillment.rightCode !== rightToConfirmCode) {
                    return fulfillment
                  }
                  return {
                    ...fulfillment,
                    checked: true,
                  }
                }),
              })
              setRightToConfirmCode('')
            }}
          />
        )}
      </FormRow>
      {shouldShowAppeals && <PolicyScopeAppeals rights={rights} />}
    </>
  )
}
