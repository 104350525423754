import { Box, Typography } from '@mui/material'
import { TooltipButton } from '@ketch-com/deck'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { useContext } from 'react'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context/RightInvocationWorkflowStepDetailViewContext'
import { InternalSystemDsrContext } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/InternalSystemDsr/context'

type Props = {}

export const InternalSystemDsrHeaderActions: React.FC<Props> = () => {
  const { isAssignedToMe } = useContext(RightInvocationWorkflowStepDetailViewContext)
  const { workflowStep, resolve, isResolving, isRetryingSql, workflowExecutionId, stepId } =
    useContext(InternalSystemDsrContext)
  const isCompleted = workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS
  const canResolve = isAssignedToMe && !isCompleted && !isResolving
  const isNotSuccess = workflowStep?.status !== WorkflowExecutionStepStatus.SUCCESS
  let tooltipTitle = ''

  if (!isAssignedToMe) tooltipTitle = 'Resolve is only available for assignee'

  return (
    <Box display="flex" gap={1.5} alignItems="center">
      {workflowStep?.completedAt && (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="smallBody" color="darkDuskFaded.main">
            Last Updated
          </Typography>
          <Typography color="darkDusk.main" variant="smallBody">
            <FormattedDateRenderer date={workflowStep?.completedAt} />
          </Typography>
        </Box>
      )}

      {/* Resolve Button */}

      {isNotSuccess && (
        <TooltipButton
          title={tooltipTitle}
          disabled={!canResolve || isRetryingSql}
          size="large"
          color="primary"
          onClick={async () => {
            resolve({
              params: {
                workflowExecutionId,
                stepId,
              },
            })
          }}
        >
          Resolve
        </TooltipButton>
      )}
    </Box>
  )
}
