import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { useState } from 'react'

export const AssessmentWidgetFileActionSheet = ({
  isProcessingActivityApproved,
  downloadAssessmentFile,
  handleRemoveAssessmentFileClick,
  setShowEditApprovedDialog,
  canWrite,
}: {
  isProcessingActivityApproved: boolean
  downloadAssessmentFile: () => void
  handleRemoveAssessmentFileClick: () => void
  setShowEditApprovedDialog: () => void
  canWrite: boolean
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button size="medium" onClick={handleClickListItem} variant="iconSubtle" color="tertiary">
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
        <ActionSheetItem
          onClick={() => {
            handleClose()
            downloadAssessmentFile()
          }}
        >
          Download
        </ActionSheetItem>

        {canWrite && <ActionSheetItem divider />}

        {canWrite && (
          <ActionSheetItem
            onClick={() => {
              handleClose()
              if (isProcessingActivityApproved) {
                setShowEditApprovedDialog()
              } else {
                handleRemoveAssessmentFileClick()
              }
            }}
          >
            Remove
          </ActionSheetItem>
        )}
      </ActionSheetWrapper>
    </>
  )
}
