import { CreateLabelDefinitionRequestBodyDTO, CreateLabelDefinitionResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  formData: CreateLabelDefinitionRequestBodyDTO
  isV2FeatureFlagEnabled?: boolean
}

export const createLabelV2 = ({ formData, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/labels`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/labels`
  return API.post<CreateLabelDefinitionResponseBodyDTO>(
    formatRequestString({
      entityUrl,
    }),
    formData,
  )
}
