import React, { useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ContentGroup, UpsertLayout, CircularProgress, CircularProgressSize } from '@ketch-com/deck'
import { useFormik, FormikProvider, Form } from 'formik'
import { MaybeNull } from 'interfaces/common'
import { ControllerDTO, ControllerFormDTO } from 'interfaces/controllers/controller'
import { RoutesManager } from 'utils/routing/routesManager'
import { useValidationSchema } from 'pages/policyCenter/controllers/edit/utils/formValidation'
import { getControllerFormValues } from 'pages/policyCenter/controllers/edit/utils/formValues'
import { ControllerFormSectionBasicDetails } from 'pages/policyCenter/controllers/edit/components/ControllerFormSectionBasicDetails'
import { NavigationBreadCrumbs } from '../../../../components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isReady: boolean
  isEditMode: boolean
  controller: MaybeNull<ControllerDTO>
  onSubmit: (values: ControllerFormDTO) => Promise<void>
}

export const EditControllerView: React.FC<Props> = ({ isReady, isEditMode, controller, onSubmit }) => {
  const navigate = useNavigate()

  const getRenderedFormTitle = useCallback(() => {
    return <ContentGroup title={isEditMode ? 'Edit Controller' : 'Create Controller'} titleVariant="h2" isDivider />
  }, [isEditMode])

  const validationSchema = useValidationSchema({ isEditMode })
  const initialValues = useMemo(() => getControllerFormValues({ controller }), [controller])

  const formikInstance = useFormik<any>({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  })
  const { submitForm, isSubmitting } = formikInstance

  const breadcrumbs = isEditMode
    ? [
        { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
        { title: 'Controllers', link: RoutesManager.policyCenter.controllers.root.getURL() },
        {
          title: controller?.name || controller?.code,
        },
        { title: 'Edit' },
      ]
    : [
        { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
        { title: 'Controllers', link: RoutesManager.policyCenter.controllers.root.getURL() },
        { title: 'Create' },
      ]

  if (!isReady) {
    return <CircularProgress size={CircularProgressSize.large} />
  }

  return (
    <FormikProvider value={formikInstance}>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Form>
        <UpsertLayout
          acceptActionButtonText="Save"
          showStepper={false}
          onCancel={() => {
            navigate(RoutesManager.policyCenter.controllers.root.getURL())
          }}
          onAccept={submitForm}
          renderFormTitle={getRenderedFormTitle}
          acceptButtonProps={{ pending: isSubmitting }}
          cancelButtonProps={{ pending: isSubmitting }}
        >
          <ControllerFormSectionBasicDetails isEditMode={isEditMode} />
        </UpsertLayout>
      </Form>
    </FormikProvider>
  )
}
