import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchDataSensitivities } from '../fetchers/fetchDataSensitivities'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.fetchDataSensitivities,
  queryFn: fetchDataSensitivities,
  select: res => res?.data || null,
})

type QueryConfig = CustomQueryConfig<typeof useCustomQuery>

export const useFetchDataSensitivities = (config: QueryConfig) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params,
    },
  })
}
