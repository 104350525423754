import { CreateVerifierResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  formData: CreateVerifierResponseBodyDTO
}

type Response = {}

export const createVerifier = ({ formData }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/verifiers`,
    }),
    formData,
  )
