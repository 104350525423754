import { useState } from 'react'

import { SIDEBAR_WIDTH_COLLAPSED, SIDEBAR_WIDTH_EXPANDED } from 'pages/orchestration/workflows/edit/utils/constants'

export const useSidebar = () => {
  const [sidebarWidth, setSidebarWidth] = useState(SIDEBAR_WIDTH_EXPANDED)
  const [isExpanded, setIsExpanded] = useState(true)
  const handleToggleSidebar = () => {
    setIsExpanded(prevState => !prevState)
    setSidebarWidth(!isExpanded ? SIDEBAR_WIDTH_EXPANDED : SIDEBAR_WIDTH_COLLAPSED)
  }

  return {
    isExpanded,
    sidebarWidth,
    handleToggleSidebar,
  }
}
