import { FormTemplateDTO, FormTemplateExperienceStatusDTO } from '@ketch-com/figurehead'

export const getFormTemplateDeployedExperiences = (formTemplate?: FormTemplateDTO) => {
  const formTemplateExperiences = formTemplate?.experiences || []

  if (formTemplateExperiences?.length > 0) {
    return formTemplateExperiences.filter(
      experience => experience.status === FormTemplateExperienceStatusDTO.DeployedFormTemplateExperienceStatus,
    )
  } else {
    return []
  }
}
