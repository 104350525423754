import { GetRightInvocationQueueV2ResponseBodyDTO } from '@ketch-com/figurehead'
import { FetchRightInvocationsFilters } from 'interfaces/rightInvocationsV2'

import { API } from 'utils/api/api'
import { formatRequestStringV2 } from 'utils/helpers/api/formatRequestStringV2'

type Params = FetchRightInvocationsFilters

export const fetchRightInvocationsV2 = ({ ...filters }: Params) => {
  return API.get<GetRightInvocationQueueV2ResponseBodyDTO>(
    formatRequestStringV2({
      entityUrl: `/api/v2/right-invocations`,
      params: { filters },
    }),
  )
}
