import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button, Card, Icon, theme } from '@ketch-com/deck'

type TileDetail = {
  title: string
  body: string
}

type Props = {
  title: string
  subtitle: string
  details: TileDetail[]
  onClick: () => void
}

export const DataMapListTile: React.FC<Props> = ({ title, subtitle, details, onClick }) => {
  return (
    <>
      <Card
        sx={{
          flex: 1,
          '&.DeckCardStretchWidth': {
            flex: 1,
            marginRight: 'unset',
          },
        }}
        cardVariant="fullwidth"
        customContentCard={
          <Box width="100%">
            <Box display="flex" mb={1}>
              <Typography
                onClick={onClick}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  },
                }}
                variant="h3"
              >
                {title}
              </Typography>
              <Button
                variant="icon"
                color="tertiary"
                sx={{
                  marginLeft: 'auto',
                }}
                onClick={onClick}
              >
                <Icon name="OArrowCRight" />
              </Button>
            </Box>
            <Typography display="block" variant="body" color={theme.palette.darkDuskFaded.main} mb={3}>
              {subtitle}
            </Typography>
            <Box display="flex" gap={2}>
              {details.map((detail, index) => (
                <Card
                  key={`${detail.title}${index}`}
                  cardVariant="small"
                  cardTitle={detail.title}
                  subTitle={detail.body}
                />
              ))}
            </Box>
          </Box>
        }
      />
    </>
  )
}
