import React, { useState } from 'react'

import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { useUpdateLanguage } from 'api/languages/mutations/useUpdateLanguage'
import { showToast } from 'components/modals/AlertComponent'
import { Button, PopUp } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  item: LanguageWithStatusDTO
  onSubmit?: () => void
  onCancel: () => void
}

export const ChangeLanguageStatusModal: React.FC<Props> = props => {
  const { item, onSubmit, onCancel } = props
  const { enabled, language } = item
  const [isBusy, setIsBusy] = useState(false)

  const { mutateAsync: handleUpdateLanguage } = useUpdateLanguage({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: () => {
      showToast({ content: 'Language updated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update language', type: 'error' })
    },
  })

  const handleSubmit = async () => {
    await handleUpdateLanguage({
      params: {
        languageCode: language.code,
        formData: {
          enabled: !enabled,
        },
      },
    })
    await onSubmit?.()
  }

  return (
    <PopUp
      title="Edit Language"
      variant="dialog"
      popUpWidth="550px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Close
          </Button>
          <Button color="primary" size="large" type="submit" pending={isBusy} onClick={() => handleSubmit()}>
            {enabled ? 'Disable' : 'Enable'}
          </Button>
        </>
      }
    >
      <Typography variant="body">
        Are you sure you want to {enabled ? 'disable' : 'enable'} <strong>{language.englishName}</strong> language?
      </Typography>
    </PopUp>
  )
}
