import React from 'react'
import Box from '@mui/material/Box'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { CheckboxOptionTranslationDTO } from 'pages/consentAndRights/forms/fields/upsert/interfaces'
import { Status, StatusState, StatusVariant } from '@ketch-com/deck'

type Props = {
  translationEntry: [string, CheckboxOptionTranslationDTO]
  organizationLanguages?: LanguageWithStatusDTO[]
}

export const ItemsLanguageCellRenderer: React.FC<Props> = ({ translationEntry, organizationLanguages }) => {
  const englishName = organizationLanguages?.find(lang => lang?.language?.code === translationEntry?.[1]?.code)
    ?.language?.englishName
  const isEnabled = organizationLanguages?.find(lang => lang?.language?.code === translationEntry?.[1]?.code)?.enabled

  return (
    <Box display="flex" alignItems="center" gap={2.5}>
      <Status
        icon="Dot"
        label={englishName || ''}
        variant={StatusVariant.ghost}
        status={isEnabled ? StatusState.success : StatusState.draft}
      />
    </Box>
  )
}
