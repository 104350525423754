import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

// api
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useDeleteStack } from 'api/stacks/mutations/useDeleteStack'
// components
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { Text } from 'components/ui-kit/typography/Text'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
// types
import { StackDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'
type Props = {
  purposeStack?: MaybeNull<StackDTO>
  onSubmit?: () => void
  onCancel: () => void
}

export const DeletePurposeStacksModal: React.FC<Props> = ({ purposeStack, onSubmit = () => {}, onCancel }) => {
  const queryClient = useQueryClient()
  const [isBusy, setIsBusy] = useState(false)

  const { mutate: handleDeleteStack } = useDeleteStack({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'Stack deleted', type: 'success' })
      await queryClient.refetchQueries(ApiQueryKeys.stacks)
      onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to delete transponder', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <Dialog
      isSaving={isBusy}
      title="Confirm Deletion"
      contentWidth={550}
      submitBtnLabel="Confirm"
      cancelBtnLabel="Cancel"
      onSubmitBtnClick={() => {
        handleDeleteStack({
          params: {
            code: purposeStack?.id || '',
          },
        })
      }}
      onCancelBtnClick={onCancel}
    >
      <Text size={14}>
        Are you sure you want to delete <strong>{purposeStack?.name}</strong>? You cannot reverse this action.
      </Text>
    </Dialog>
  )
}
