import { ApplicationDTO, UpsertApplicationDTO } from 'interfaces/applications/application'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = {
  organizationCode: string
  formData: UpsertApplicationDTO
}

type Response = { application: ApplicationDTO }

export const createApplication = ({ organizationCode, formData }: Params) =>
  API.post<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/applications`,
    }),
    formData,
  )
