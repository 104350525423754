import Box from '@mui/material/Box'
import { Routes, Route, useLocation } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ViewLayoutHeaderLeftMenu, ViewLayoutLeftMenu } from 'components/ui-layouts/viewLayoutLeftMenu'
import { ReactComponent as EmptyStateDatabaseIcon } from 'assets/icons/databases.svg'
import { ReactComponent as PoliciesControlsIcon } from 'assets/icons/policiesAndControls.svg'
import { PoliciesMenu } from './components/PoliciesMenu'
import { PoliciesDetails } from './components/PoliciesDetails'
import { Button } from '@ketch-com/deck'
import { policiesMockData, securityControlsMockData } from '../__mocks__/policiesControls' //TODO:JA use real data
import { DetailsEmptyState } from '../components/layout/DetailsEmptyState'
import { SecurityControlsMenu } from './components/SecurityControlsMenu'
import { SecurityControlsDetails } from './components/SecurityControlsDetails'
import { PoliciesControlsTabs } from './components/PoliciesControlsTabs'
import { useStyles } from '../components/layout/styles'
import { DataRiskHeaderNav } from '../components/layout/DataRiskHeaderNav'
import { useScrollToTopOnLoad } from 'utils/hooks/useScrollToTopOnLoad'
import { showClipboardToast } from 'pages/insights/utils'
import { relativePath } from 'utils/routing/relativePath'

type Props = {}

export const PoliciesControlsContainer: React.FC<Props> = () => {
  const location = useLocation()
  const classes = useStyles()

  useScrollToTopOnLoad()

  const breadcrumbs = [
    { title: 'Reports', link: RoutesManager.insights.root.getURL() },
    { title: 'Data Risks', link: RoutesManager.insights.dataRisks.root.getURL() },
    { title: 'Policies & Controls', link: RoutesManager.insights.dataRisks.policiesControls.root.getURL() },
  ]

  const emptyState = (
    <DetailsEmptyState
      title="Select a data asset"
      description="Check the panel on the left to look up and select the data set to see the insights."
      icon={<EmptyStateDatabaseIcon />}
    />
  )

  return (
    <ViewLayoutLeftMenu breadcrumbs={breadcrumbs}>
      <ViewLayoutHeaderLeftMenu
        title="Policies & Controls"
        titleComponent={<DataRiskHeaderNav />}
        details="Identify data assets missing from DSR processes or with insufficient security controls."
        detailsClassName={classes.subtitle}
        backTo={RoutesManager.insights.dataRisks.root.getURL()}
        icon={<PoliciesControlsIcon />}
        actions={
          <Button color="tertiary" size="large" clipboardText={window.location.href} onClick={showClipboardToast}>
            Share
          </Button>
        }
      />
      <PoliciesControlsTabs />
      <Box display="flex" gap={1.5}>
        <Routes location={location}>
          <Route
            path={relativePath(
              RoutesManager.insights.dataRisks.policiesControls.policies.root.path,
              RoutesManager.insights.dataRisks.policiesControls.root.path,
            )}
            element={
              <>
                <PoliciesMenu rows={policiesMockData.policies} />
                <PoliciesDetails policiesRecords={policiesMockData.policies} emptyState={emptyState} />
              </>
            }
          />
          <Route
            path={relativePath(
              RoutesManager.insights.dataRisks.policiesControls.securityControls.root.path,
              RoutesManager.insights.dataRisks.policiesControls.root.path,
            )}
            element={
              <>
                <SecurityControlsMenu rows={securityControlsMockData.securityControls} />
                <SecurityControlsDetails
                  securityControlsRecords={securityControlsMockData.securityControls}
                  emptyState={emptyState}
                />
              </>
            }
          />

          <Route
            path={relativePath(
              RoutesManager.insights.dataRisks.policiesControls.root.path,
              RoutesManager.insights.dataRisks.policiesControls.root.path,
            )}
            element={
              <>
                <PoliciesMenu rows={policiesMockData.policies} />
                {emptyState}
              </>
            }
          />
        </Routes>
      </Box>
    </ViewLayoutLeftMenu>
  )
}
