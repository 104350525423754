import { ApiQueryKeys } from 'api/common/queryKeys'
import { isQueryEnabled } from 'api/common/utils'
import { CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { createUsePaginatedQueryPreservedPage } from 'api/common/createUsePaginatedQueryPreservedPage'
import { fetchOrganizationLanguages } from 'api/languages/fetchers/fetchOrganizationLanguages'
import { useAuth } from 'utils/hooks/useAuth'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

const useCustomPaginatedQuery = createUsePaginatedQueryPreservedPage({
  queryKey: ApiQueryKeys.organizationsLanguagesPaginated,
  queryFn: fetchOrganizationLanguages,
  select: res => res?.data?.languages || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery, 'organizationCode'>

export const useOrganizationLanguagesPaginated = (config?: QueryConfig) => {
  const { userData } = useAuth()
  const { isPermitted } = useIsPermitted()

  return useCustomPaginatedQuery({
    ...config,
    enabled: isPermitted(PERMISSIONS.TRANSLATIONS_READ) && isQueryEnabled(config?.enabled),
    params: {
      ...config?.params,
      organizationCode: userData.organizationCode!,
    },
  })
}
