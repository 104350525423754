import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'

export interface Props extends TooltipProps {
  className?: string
}

const StyledTooltip = styled(({ arrow, className, ...rest }: Props) => (
  <Tooltip {...rest} arrow={arrow} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.darkDusk.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    backgroundColor: theme.palette.darkDusk.main,
    borderRadius: 5,
    boxShadow: `2px 2px 4px rgba(0, 0, 0, 0.3)`,
  },
}))

export const TooltipV2: React.FC<TooltipProps> = ({ arrow = true, children, title, ...rest }) => (
  <StyledTooltip {...rest} arrow={arrow} title={title}>
    {children}
  </StyledTooltip>
)
