import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { MaybeNull } from 'interfaces/common'
import { FileInfoDTO } from 'interfaces/files/fileInfo'

type Params = {
  version: string
  folder: string
}

export const fetchFilesInfoList = async ({ version, folder }: Params) => {
  const filesListInfo = await API.get<MaybeNull<FileInfoDTO[]>>(
    formatRequestString({
      entityUrl: `/api/v2/files/list?bucket=''&version=${version}&folder=${folder}`,
    }),
  )

  return filesListInfo.data
}
