import React from 'react'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import Box from '@mui/material/Box'
import { getWorkflowExecutionStepStatusIcon, getWorkflowExecutionStepStatusText } from './utils'
import { IconDictionary, Status, StatusState, StatusVariant } from '@ketch-com/deck'
import {
  StatusTimeRemaining,
  getStatusTimeRemaining,
} from 'pages/orchestration/rightsQueue/components/utils/getStatusTimeRemaining'
import pluralize from 'pluralize'
import { WorkflowExecutionPreviewStepStatusDTO } from '@ketch-com/figurehead'

const statusMap = {
  [WorkflowExecutionStepStatus.PENDING]: StatusState.inProgress,
  [WorkflowExecutionStepStatus.IN_PROGRESS]: StatusState.inProgress,
  [WorkflowExecutionStepStatus.SUCCESS]: StatusState.success,
  [WorkflowExecutionStepStatus.FAIL]: StatusState.error,
  [WorkflowExecutionStepStatus.INVALID_CREDENTIALS]: StatusState.error,
  [WorkflowExecutionStepStatus.TERMINATED]: StatusState.error,
  [WorkflowExecutionStepStatus.SKIPPED]: StatusState.draft,
  [WorkflowExecutionStepStatus.NOT_STARTED]: StatusState.draft,
  [WorkflowExecutionStepStatus.MIXED]: StatusState.finished,
}

type Props = {
  status: WorkflowExecutionStepStatus | WorkflowExecutionPreviewStepStatusDTO
  dueAt?: number
}

type StatusProps = {
  label: string
  icon: keyof typeof IconDictionary
  secondaryLabel?: string
  variant: StatusVariant
  status: StatusState
}

// Produces lovely strings such as "9 days left" or "1 hour ago"
const formatTimeLeft = (timeRemaining: StatusTimeRemaining, isOverdue: boolean) => {
  return `${Math.abs(timeRemaining.duration || 0)} ${
    timeRemaining.unit === 'Days' ? pluralize('day', timeRemaining.duration) : pluralize('hour', timeRemaining.duration)
  } ${isOverdue ? 'ago' : 'left'}`
}

const getStatusContent = (
  timeRemaining: StatusTimeRemaining | undefined,
  status: WorkflowExecutionStepStatus,
): StatusProps => {
  // Use legacy logic if no due date
  if (!timeRemaining) {
    return {
      label: getWorkflowExecutionStepStatusText({ status }),
      icon: getWorkflowExecutionStepStatusIcon(status, false),
      variant: StatusVariant.ghost,
      status: statusMap[status],
    }
  }

  if (status === WorkflowExecutionStepStatus.SUCCESS) {
    return {
      label: 'Completed',
      icon: IconDictionary.FCheckRound,
      variant: StatusVariant.ghost,
      status: StatusState.success,
    }
  }

  if (timeRemaining.duration <= 0) {
    return {
      label: 'Overdue',
      icon: IconDictionary.FClock,
      variant: StatusVariant.ghost,
      secondaryLabel: formatTimeLeft(timeRemaining, true),
      status: StatusState.error,
    }
  }

  if (timeRemaining.unit === 'Hours' && timeRemaining.duration <= 24) {
    return {
      label: 'Due today',
      icon: IconDictionary.OClock,
      variant: StatusVariant.ghost,
      secondaryLabel: formatTimeLeft(timeRemaining, false),
      status: StatusState.error,
    }
  }

  if (timeRemaining.unit === 'Hours') {
    return {
      label: 'Due soon',
      icon: IconDictionary.OClock,
      variant: StatusVariant.ghost,
      secondaryLabel: formatTimeLeft(timeRemaining, false),
      status: StatusState.warning,
    }
  }

  if (status === WorkflowExecutionStepStatus.IN_PROGRESS) {
    return {
      label: 'In Progress',
      icon: IconDictionary.FPointer,
      variant: StatusVariant.ghost,
      status: StatusState.inProgress,
      secondaryLabel: formatTimeLeft(timeRemaining, false),
    }
  }

  if (status === WorkflowExecutionStepStatus.PENDING) {
    return {
      label: 'Pending Review',
      icon: IconDictionary.FEyeShow,
      variant: StatusVariant.ghost,
      status: StatusState.inProgress,
      secondaryLabel: formatTimeLeft(timeRemaining, false),
    }
  }

  if (status === WorkflowExecutionStepStatus.SKIPPED) {
    return {
      label: 'Skipped',
      variant: StatusVariant.ghost,
      icon: IconDictionary.OCross,
      status: StatusState.draft,
    }
  }

  if (status === WorkflowExecutionStepStatus.NOT_STARTED) {
    return {
      label: 'Not Started',
      variant: StatusVariant.ghost,
      icon: IconDictionary.OArrowWall,
      status: StatusState.draft,
    }
  }

  return {
    label: 'Unknown',
    variant: StatusVariant.ghost,
    icon: IconDictionary.FPointer,
    status: StatusState.draft,
  }
}

// Workflow preview endpoint uses a totally different data type to express step status, for some reason ¯\_(ツ)_/¯
// This method smooths that over to ensure that the types are compatible
export const convertPreviewStatusToLegacyStatus = (
  status: WorkflowExecutionPreviewStepStatusDTO | WorkflowExecutionStepStatus,
): WorkflowExecutionStepStatus => {
  if (status === WorkflowExecutionPreviewStepStatusDTO.CompletedWorkflowExecutionPreviewStepStatus) {
    return WorkflowExecutionStepStatus.SUCCESS
  }

  if (status === WorkflowExecutionPreviewStepStatusDTO.FailedWorkflowExecutionPreviewStepStatus) {
    return WorkflowExecutionStepStatus.FAIL
  }

  if (status === WorkflowExecutionPreviewStepStatusDTO.InvalidWorkflowExecutionPreviewStepStatus) {
    return WorkflowExecutionStepStatus.INVALID_CREDENTIALS
  }

  if (status === WorkflowExecutionPreviewStepStatusDTO.PendingWorkflowExecutionPreviewStepStatus) {
    return WorkflowExecutionStepStatus.NOT_STARTED
  }

  if (status === WorkflowExecutionPreviewStepStatusDTO.RunningWorkflowExecutionPreviewStepStatus) {
    return WorkflowExecutionStepStatus.IN_PROGRESS
  }

  if (status === WorkflowExecutionPreviewStepStatusDTO.SkippedWorkflowExecutionPreviewStepStatus) {
    return WorkflowExecutionStepStatus.SKIPPED
  }

  if (status === WorkflowExecutionPreviewStepStatusDTO.TerminatedWorkflowExecutionPreviewStepStatus) {
    return WorkflowExecutionStepStatus.TERMINATED
  }

  return status as WorkflowExecutionStepStatus
}

export const WorkflowExecutionStepStatusRendererV3: React.FC<Props> = ({ status, dueAt }) => {
  const timeRemaining = dueAt ? getStatusTimeRemaining(dueAt) : undefined

  const convertedStatus = convertPreviewStatusToLegacyStatus(status)
  const content = getStatusContent(timeRemaining, convertedStatus)

  return (
    <Box display="flex" alignItems="center">
      <Status {...content} />
    </Box>
  )
}
