import { ICoordinates, IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'
import { getUuidWithoutHyphensAndStartingWithALetter } from './getUuidWithoutHyphensAndStartingWithALetter'
import { WorkflowActivityGatewayMode } from '../../../../../interfaces/workflowActivities/workflowActivity'

/**
 * Important definitions/notes:
 * - Grid coordinates: x and y values given in units of squares. e.g. x = 10, y = 5 is 10 squares right and 5 squares down
 * - Absolute coordinates: x and y values given in pixel units. e.g. x = 10, y = 5 in grid coordinates is equivalent to
 *   x = 700 (10 * GRID_CELL_WIDTH), y = 350 (5 * 70) in absolute coordinates
 * - The grid is in the bottom right quadrant of a 2D graph, so x and y values provided to the canvas are negative. Be
 *   wary of this when calculating absolute positions.
 */

// Number of grid cells in the x and y direction
export const GRID_SIZE_X = 500
export const GRID_SIZE_Y = 500

// Pixel width and height of a single grid cell
export const GRID_CELL_WIDTH = 70
export const GRID_CELL_HEIGHT = 70

// Placement of the start tile in grid coordinates
export const CANVAS_START_TILE_POSITION: ICoordinates = {
  x: 250,
  y: 250,
}

// Top left corner of the starting canvas view in grid coordinates
export const CANVAS_START_VIEW_POSITION: ICoordinates = {
  x: 248,
  y: 248,
}

// These define the canvas boundaries within the form
export const CANVAS_TOP_OFFSET = 70
export const CANVAS_SIDEBAR_EXPANDED_LEFT_OFFSET = 280
export const CANVAS_SIDEBAR_COLLAPSED_LEFT_OFFSET = 156

export const CANVAS_LINE_COLOR = '#5673ff'

export const CANVAS_COPY_HOVERED_STROKE_WIDTH = 4
export const CANVAS_COPY_NOT_HOVERED_OPACITY = 0.2

export const SCALE_STEP = 0.1
export const MOUSE_ZOOM_SCALE_STEP = 0.1
export const SCALE_STEP_LIMIT_MAX = 0.2
export const SCALE_STEP_LIMIT_MIN = 2

export const PAN_STEP_MAX = 50

export const SIDEBAR_WIDTH_COLLAPSED = 156
export const SIDEBAR_WIDTH_EXPANDED = 280

export const GRID_IMG_SIZE = 64
export const GRID_IMG_GAP = 3

export const GRID_TEXT_GAP = GRID_CELL_HEIGHT + 5
export const GRID_DESCRIPTION_GAP = GRID_TEXT_GAP + 18

// How far back a user can undo and redo
export const UNDO_REDO_BUFFER_SIZE = 20

export const WORKFLOW_INITIAL_STATE: IWorkflowConfig = {
  canonicalRightCode: '',
  code: '',
  name: '',
  steps: [
    {
      ID: getUuidWithoutHyphensAndStartingWithALetter(),
      name: 'Start Flow',
      code: 'system.startFlow',
      activityCode: 'system.startFlow',
      valid: true,
      start: {
        transition: undefined,
      },
    },
  ],
}

export const GatewayModeName = {
  [WorkflowActivityGatewayMode.JOIN]: 'Join',
  [WorkflowActivityGatewayMode.SPLIT]: 'Split',
  [WorkflowActivityGatewayMode.DECISION_SINGLE]: 'Decision Single',
  [WorkflowActivityGatewayMode.DECISION_MULTI]: 'Decision Multi',
  [WorkflowActivityGatewayMode.DECISION_BOOLEAN]: 'Decision Boolean',
}

export enum CanvasStepType {
  Undefined,
  Placeholder,
  Activity,
  Gateway,
  Workflow,
  Start,
  End,
}

export enum CopyPasteType {
  single,
  splitOrMultiGateway,
  booleanOrSingleGateway,
  path,
}
