import { FILTER_STATES } from '../constants'

export const getDimensions = (filterState: FILTER_STATES) => {
  switch (filterState) {
    case FILTER_STATES.COLLAPSED:
      return {
        width: 135,
        height: 60,
      }
    case FILTER_STATES.OPEN:
      return {
        width: 358,
        height: 363,
      }
    case FILTER_STATES.SELECTING:
      return {
        width: 244,
        height: 363,
      }
    default:
      return {
        width: 100,
        height: 100,
      }
  }
}
