import { FC, useContext } from 'react'

import { Dropdown } from 'components/ui-kit/dropdown/Dropdown'
import { truncateStringWithEllipse } from 'utils/formatters'
import { ExperienceCanonicalRightFormTemplate } from 'interfaces/experiences/experience'
import { Error } from 'components/ui-kit/form/common/error/Error'
import Box from '@mui/material/Box'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences/upsert/context/ExperienceUpsertContext'
import { ErrorMessage } from 'formik'

type Props = {
  onClick: (selectedTemplate: ExperienceCanonicalRightFormTemplate) => void
  templateId: string
  errorMessageName: string
}

export const FormTemplatesDropdown: FC<Props> = ({ onClick, templateId, errorMessageName }) => {
  const { formTemplates } = useContext(ExperienceUpsertContext)

  const templateItems = formTemplates?.map(template => ({
    active: templateId === template?.id,
    content: template?.name,
    value: template?.id,
    onClick: () => onClick(template),
  }))

  const activeTemplate = formTemplates?.find(t => templateId === t?.id)

  return (
    <Box display="flex" flexDirection="column">
      <Dropdown
        containerSx={{ width: '100%' }}
        placeholder="Select Form Template"
        value={templateId}
        items={templateItems}
      >
        <span title={activeTemplate?.name}>{truncateStringWithEllipse(activeTemplate?.name, 40)}</span>
      </Dropdown>

      <ErrorMessage name={errorMessageName}>
        {msg => {
          return <Error>{msg}</Error>
        }}
      </ErrorMessage>
    </Box>
  )
}
