import React from 'react'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'

import { ThemeDTO } from 'interfaces/themes/theme'
import { formatCodeFromName } from 'utils/formatters'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'

type Props = {
  isEditMode?: boolean
  themes: ThemeDTO[]
}

export const DeploymentPlanBasicDetails: React.FC<Props> = ({ isEditMode = false, themes }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext()

  return (
    <FormSection title="Basic Details">
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <FormInput
            required
            fullWidth
            label="Name"
            name="name"
            placeholder="Example: My New Deployment Plan"
            onChange={e => {
              if (!isEditMode) {
                setFieldTouched('code', true)
                setFieldValue('code', formatCodeFromName(e.target.value))
              }
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <FormDropdown
            fullWidth
            valueKey="ID"
            name="themeID"
            label="Theme"
            items={themes}
            placeholder="Select Theme"
            renderItem={({ name, code }) => <NameAndCodeCellRenderer name={name} code={code} />}
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} mt={0}>
        <Grid item xs={6}>
          <FormInput
            required
            fullWidth
            disabled={isEditMode}
            label="Code"
            name="code"
            placeholder="Example: AP957384"
          />
        </Grid>
      </Grid>
    </FormSection>
  )
}
