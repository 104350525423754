import { useLocation, useParams } from 'react-router-dom'
import { UrlParams } from './index'
import { useProcessingActivity } from 'api/processingActivities/queries/useProcessingActivity'
import { useProcessingActivityChat } from 'api/processingActivities/queries/useProcessingActivityChat'
import { useDataMapRegions } from 'api/dataMap/queries/useDataMapRegions'
import { useState } from 'react'
import { ProcessingActivityDataSystemProcessingStageDTO, ProcessingActivityStatusDTO } from '@ketch-com/figurehead'
import { useNavigate } from 'react-router'
import { useQueryClient } from 'react-query'
import { showToast } from 'components/modals/AlertComponent'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useDeleteProcessingActivity } from 'api/processingActivities/mutations/useDeleteProcessingActivity'
import { useUploadFile } from 'api/files/mutations/useUploadFile'
import { useDownloadFile } from 'api/files/mutations/useDownloadFile'
import { useUpdateProcessingActivityAssessment } from 'api/processingActivities/mutations/useUpdateProcessingActivityAssessment'
import { useProcessingActivityVersions } from 'api/processingActivities/queries/useProcessingActivityVersions'
import { useProcessingActivityVersion } from 'api/processingActivities/queries/useProcessingActivityVersion'
import { useQueryParams } from 'utils/hooks'
import { useUpdateProcessingActivityDataFlow } from 'api/processingActivities/mutations/useUpdateProcessingActivityDataFlow'
import { useUpdateProcessingActivityDataSystemConsentStatus } from 'api/processingActivities/mutations/useUpdateProcessingActivityDataSystemConsentStatus'
import { useUpdateProcessingActivityStatus } from 'api/processingActivities/mutations/useUpdateProcessingActivityStatus'
import { useDeleteProcessingActivityAssessment } from 'api/processingActivities/mutations/useDeleteProcessingActivityAssessment'
import { useDeleteProcessingActivityDataFlowFile } from 'api/processingActivities/mutations/useDeleteProcessingActivityDataFlowFile'
import { useDeleteProcessingActivityModelBiasFile } from 'api/processingActivities/mutations/useDeleteProcessingActivityModelBiasFile'
import { useUpdateProcessingActivityAssessmentRequirement } from 'api/processingActivities/mutations/useUpdateProcessingActivityAssessmentRequirement'
import { useUsers } from 'api/users/queries/useUsers'
import { useUpdateProcessingActivityModelBiasFile } from 'api/processingActivities/mutations/useUpdateProcessingActivityModelBiasFile'
import { isTwilioConversationsEnabled } from 'utils/helpers/isTwilioConversationsEnabled'

export const useProcessingActivityUtils = () => {
  // Misc. Hooks
  const { id } = useParams<UrlParams>()
  const location = useLocation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { queries, setQueryParam, removeQueryParam } = useQueryParams<{ versionId?: string }>()

  // Shared state across all view screens which controls the display of a modal warning the user about making changes to an approved PA
  const [showEditApprovedDialog, setShowEditApprovedDialog] = useState(false)

  // Get this processing activity (latest version)
  const {
    data: processingActivityLatest,
    isLoading: isProcessingActivityLatestLoading,
    isFetching: isProcessingActivityLatestFetching,
    refetch: refetchProcessingActivityLatest,
  } = useProcessingActivity({
    params: {
      activityId: id || '',
      includeMetadata: true,
    },
  })

  // Determine chat enabled status
  const isProcessingActivityChatEnabled = isTwilioConversationsEnabled()

  // Get processing activity chat
  const { data: processingActivityChat, isLoading: isProcessingActivityChatLoading } = useProcessingActivityChat({
    params: {
      activityId: id || '',
    },
    onError: () => {
      showToast({ content: 'Unable to get comments', type: 'error' })
    },
    enabled: isProcessingActivityChatEnabled,
  })

  // Get processing activity chat users
  const { data: processingActivityChatUsers, isLoading: isProcessingActivityChatUsersLoading } = useUsers({
    params: {
      active: true,
      includeMetadata: true,
    },
    onError: () => {
      showToast({ content: 'Unable to get users', type: 'error' })
    },
  })

  // Get specific versions of this processing activity
  const {
    data: versions,
    isLoading: isVersionsLoading,
    refetch: refetchVersions,
  } = useProcessingActivityVersions({
    params: {
      activityId: id || '',
      includeMetadata: true,
    },
  })
  const {
    data: processingActivityVersioned,
    isLoading: isProcessingActivityVersionedLoading,
    isFetching: isProcessingActivityVersionedFetching,
    refetch: refetchProcessingActivityVersioned,
  } = useProcessingActivityVersion({
    params: {
      activityId: id || '',
      versionId: queries.versionId || '',
      includeMetadata: true,
    },
  })

  // Determine if we should show the latest processing activity or a previous version
  const isPreviousVersion = !!queries.versionId
  const processingActivity = isPreviousVersion ? processingActivityVersioned : processingActivityLatest
  const isProcessingActivityLoading =
    isProcessingActivityLatestLoading ||
    isProcessingActivityVersionedLoading ||
    isProcessingActivityChatLoading ||
    isProcessingActivityChatUsersLoading
  const isProcessingActivityFetching = isProcessingActivityLatestFetching || isProcessingActivityVersionedFetching

  // Get regions
  const { data: regions, isLoading: isRegionsLoading } = useDataMapRegions({})

  // Delete processing activity
  const { mutate: handleDeleteProcessingActivity } = useDeleteProcessingActivity({
    onSuccess: async () => {
      await queryClient.refetchQueries([
        ApiQueryKeys.processingActivitiesInfinite,
        ApiQueryKeys.processingActivitiesSummary,
      ])
      showToast({ content: 'Processing activity deleted', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to delete processing activity', type: 'error' })
    },
  })

  // Delete assessment file or object from processing activity (if file, backend will also delete in S3)
  const { mutateAsync: handleDeleteProcessingActivityAssessment, isLoading: isDeletingAssessment } =
    useDeleteProcessingActivityAssessment({
      onError: () => {
        showToast({ content: 'Failed to delete assessment', type: 'error' })
      },
    })

  // Delete data flow file
  const { mutateAsync: handleDeleteProcessingActivityDataFlowFile, isLoading: isDataFlowFileDeleting } =
    useDeleteProcessingActivityDataFlowFile({
      onSuccess: async () => {
        await refetchProcessingActivityLatest()
        showToast({ content: 'File deleted', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to delete file', type: 'error' })
      },
    })

  // Delete model bias file
  const { mutateAsync: handleDeleteProcessingActivityModelBiasFile, isLoading: isModelBiasFileDeleting } =
    useDeleteProcessingActivityModelBiasFile({
      onSuccess: async () => {
        showToast({ content: 'File deleted', type: 'success' })
        await queryClient.refetchQueries([ApiQueryKeys.processingActivity])
      },
      onError: () => {
        showToast({ content: 'Failed to delete file', type: 'error' })
      },
    })

  // Download data flow file
  const downloadDataFlowFile = async () => {
    if (processingActivity?.dataFlowFile?.downloadUrl) {
      const { data } = await handleDownloadFile({
        params: {
          fileUrl: processingActivity.dataFlowFile.downloadUrl, // Pass the file URL for download.
        },
      })

      let a: HTMLAnchorElement | null = document.createElement('a')
      a.href = URL.createObjectURL(data)
      a.download = processingActivity.dataFlowFile.name || 'Untitled'
      a.click()
      a = null
      showToast({ content: `Downloaded file ${processingActivity.dataFlowFile.name}`, type: 'success' }) // Show success message.

      return data
    }
  }

  // Download model bias file
  const downloadModelBiasFile = async () => {
    if (processingActivity?.modelBiasFile?.downloadUrl) {
      const { data } = await handleDownloadFile({
        params: {
          fileUrl: processingActivity.modelBiasFile.downloadUrl, // Pass the file URL for download.
        },
      })

      let a: HTMLAnchorElement | null = document.createElement('a')
      a.href = URL.createObjectURL(data)
      a.download = processingActivity.modelBiasFile.name || 'Untitled'
      a.click()
      a = null
      showToast({ content: `Downloaded file ${processingActivity.modelBiasFile.name}`, type: 'success' }) // Show success message.
    }
  }

  // Update processing activity status
  const { mutateAsync: handleStatusUpdate, isLoading: isStatusUpdating } = useUpdateProcessingActivityStatus({
    onSuccess: async () => {
      // Refetch so the updated filename displays
      await queryClient.refetchQueries([ApiQueryKeys.processingActivity])
      showToast({ content: 'Status Updated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update status', type: 'error' })
    },
  })

  // Update assessment
  const { mutateAsync: handleAssessmentUpdate, isLoading: isAssessmentUpdateLoading } =
    useUpdateProcessingActivityAssessment({
      onError: () => {
        showToast({ content: 'Failed to update assessment', type: 'error' })
      },
    })

  // Update data flow
  const { mutateAsync: handleDataFlowUpdate } = useUpdateProcessingActivityDataFlow({
    onSuccess: async () => {
      // Refetch so the updated filename displays
      await queryClient.refetchQueries([ApiQueryKeys.processingActivity])
    },
    onError: () => {
      showToast({ content: 'Failed to update data flow', type: 'error' })
    },
  })

  // Update model bias
  const { mutateAsync: handleModelBiasFileUpdate, isLoading: isModelBiasFileUpdating } =
    useUpdateProcessingActivityModelBiasFile({
      onError: () => {
        showToast({ content: 'Failed to update assessment', type: 'error' })
      },
    })

  // Update consent status
  const { mutateAsync: handleConsentStatusUpdate, isLoading: isConsentStatusUpdating } =
    useUpdateProcessingActivityDataSystemConsentStatus({
      onSuccess: async () => {
        // Refetch so the updated filename displays
        await queryClient.refetchQueries([ApiQueryKeys.processingActivity])
        showToast({ content: 'Consent status updated', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to update consent status', type: 'error' })
      },
    })

  // Upload, download, and delete files in S3
  const { mutateAsync: handleUploadFile } = useUploadFile({
    onError: () => {
      showToast({ content: 'Upload failed', type: 'error' })
    },
  })
  const { mutateAsync: handleDownloadFile } = useDownloadFile({
    onError: () => {
      showToast({ content: 'Download failed', type: 'error' })
    },
  })

  // Model bias file upload function
  const uploadModelBiasFile = async (activityId: string, file: File) => {
    try {
      // Add to S3 and update processing activity
      await Promise.all([
        handleUploadFile({
          params: {
            file,
            version: 'processing-activity-model-bias-file',
            folder: activityId,
            bucket: '',
          },
        }),
        handleModelBiasFileUpdate({
          params: {
            activityId,
            formData: {
              file: {
                name: file.name,
              },
            },
          },
        }),
      ])
      // Refetch
      await refetchProcessingActivityLatest()
      showToast({ content: 'Model bias file uploaded', type: 'success' })
    } catch {
      showToast({ content: 'Model bias file upload failed', type: 'error' })
    }
  }

  // Model bias delete function
  const removeModelBiasFile = async (activityId: string) => {
    try {
      await handleDeleteProcessingActivityModelBiasFile({ params: { activityId } })
      await refetchProcessingActivityLatest()
      showToast({ content: 'Model bias file deleted', type: 'success' })
    } catch {
      showToast({ content: 'Model bias file delete failed', type: 'error' })
    }
  }

  // Assessment file upload function
  const uploadAssessmentFile = async (activityId: string, file: File) => {
    try {
      // Add to S3 and update processing activity
      await Promise.all([
        handleUploadFile({
          params: {
            file,
            version: 'processing-activity-assessment-file',
            folder: activityId,
            bucket: '',
          },
        }),
        handleAssessmentUpdate({
          params: {
            activityId,
            formData: {
              file: {
                name: file.name,
              },
            },
          },
        }),
      ])
      // Refetch
      await refetchProcessingActivityLatest()
      showToast({ content: 'Assessment uploaded', type: 'success' })
    } catch {
      showToast({ content: 'Assessment upload failed', type: 'error' })
    }
  }

  // Assessment delete function, for assessment object or file
  const removeAssessment = async (activityId: string) => {
    try {
      await handleDeleteProcessingActivityAssessment({ params: { activityId } })
      await refetchProcessingActivityLatest()
      showToast({ content: 'Assessment file deleted', type: 'success' })
    } catch {
      showToast({ content: 'Assessment delete failed', type: 'error' })
    }
  }

  // Update assessment requirement
  const { mutateAsync: handleAssessmentRequirementUpdate, isLoading: isRequirementUpdating } =
    useUpdateProcessingActivityAssessmentRequirement({
      onSuccess: async () => {
        // Refetch so the updated filename displays
        await queryClient.refetchQueries([ApiQueryKeys.processingActivity])
        showToast({ content: 'Assessment requirement updated', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to update assessment requirement', type: 'error' })
      },
    })

  const downloadAssessmentFile = async () => {
    if (processingActivity?.assessmentFile?.downloadUrl) {
      const { data } = await handleDownloadFile({
        params: {
          fileUrl: processingActivity.assessmentFile.downloadUrl, // Pass the file URL for download.
        },
      })

      let a: HTMLAnchorElement | null = document.createElement('a')
      a.href = URL.createObjectURL(data)
      a.download = processingActivity.assessmentFile.name || 'Untitled'
      a.click()
      a = null
      showToast({ content: `Downloaded file ${processingActivity.assessmentFile.name}`, type: 'success' }) // Show success message.

      return data
    }
  }

  const isProcessingActivityApproved =
    processingActivity.status === ProcessingActivityStatusDTO.ApprovedProcessingActivityStatus

  // Data sources filter states
  const [searchString, setSearchString] = useState('')
  const [activityStage, setActivityStage] = useState<ProcessingActivityDataSystemProcessingStageDTO>(
    ProcessingActivityDataSystemProcessingStageDTO.UnspecifiedProcessingActivityDataSystemProcessingStage,
  )

  // Determine loading states
  const isAssessmentUpdating = isAssessmentUpdateLoading || isDeletingAssessment || isRequirementUpdating

  return {
    location,
    navigate,
    isProcessingActivityLoading,
    isProcessingActivityFetching,
    isRegionsLoading,
    regions,
    id,
    processingActivity,
    isProcessingActivityChatEnabled,
    isProcessingActivityChatLoading,
    processingActivityChat,
    isProcessingActivityChatUsersLoading,
    processingActivityChatUsers,
    isVersionsLoading,
    versions,
    refetchVersions,
    processingActivityVersioned,
    searchString,
    setSearchString,
    activityStage,
    setActivityStage,
    handleStatusUpdate,
    handleAssessmentUpdate,
    uploadAssessmentFile,
    uploadModelBiasFile,
    downloadAssessmentFile,
    downloadDataFlowFile,
    downloadModelBiasFile,
    handleDataFlowUpdate,
    handleConsentStatusUpdate,
    handleAssessmentRequirementUpdate,
    removeAssessment,
    removeModelBiasFile,
    refetchProcessingActivityLatest,
    refetchProcessingActivityVersioned,
    handleDeleteProcessingActivity,
    handleDeleteProcessingActivityAssessment,
    handleDeleteProcessingActivityDataFlowFile,
    isProcessingActivityApproved,
    isAssessmentUpdating,
    isConsentStatusUpdating,
    isModelBiasFileUpdating,
    isModelBiasFileDeleting,
    isDataFlowFileDeleting,
    isStatusUpdating,
    isPreviousVersion,
    setQueryParam,
    removeQueryParam,
    showEditApprovedDialog,
    setShowEditApprovedDialog,
  }
}

export type ProcessingActivityUtils = ReturnType<typeof useProcessingActivityUtils>
