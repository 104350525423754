import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { FindScenariosResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

type Params = APIListRequestParams<{
  dataRole?: number
  rightType?: number
  canonicalRightCode?: string
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchScenarios = ({
  start,
  limit,
  query,
  dataRole,
  rightType,
  canonicalRightCode,
  isV2FeatureFlagEnabled,
}: Params) => {
  let entityUrl = `/api/v3/scenarios`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/scenarios`

  return API.get<FindScenariosResponseBodyDTO>(
    formatRequestString({
      entityUrl,
      params: {
        filters: {
          start,
          limit,
          ...(rightType && {
            rightType,
          }),
          ...(dataRole && {
            dataRole,
          }),
          ...(canonicalRightCode && {
            canonicalRightCode,
          }),
        },
      },
    }),
  )
}
