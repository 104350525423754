import { Box, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { getIn } from 'formik'
import { BuilderContext } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/Builder'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { useContext } from 'react'

const RequestsFormHeaderBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  marginBottom: '44px',
})

export const RequestsSubmittedHeader: React.FC = () => {
  const { themeConfig, experienceConfig } = useContext(BuilderContext)

  // Theme config
  const titleColor = getIn(themeConfig, themeUpsertFormKeys.preferenceRequestsTabSubmittedHeaderTitleColor)

  // Experience config
  const title = getIn(experienceConfig, experienceUpsertFormKeys.preferenceRequestsTabSubmittedTitle)

  return (
    <RequestsFormHeaderBox>
      <Typography fontSize={28} fontWeight={600} color={titleColor}>
        {title}
      </Typography>
    </RequestsFormHeaderBox>
  )
}
