import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { getRootPathString } from 'store/paginationPreservationSlice/selectors'
import { Order, RightsQueueListTableColumnId } from '../interfaces'
import { useFiltersV3 } from 'utils/hooks'
import { useRightsQueueListTableColumnConfigV2 } from '.'
import { useRightInvocationsPaginatedV2 } from 'api/rightInvocationsV2/queries/useRightInvocationsPaginatedV2'
import { arrayToObject } from 'utils/helpers/arrayToObject'
import { usePaginationHelpers } from 'utils/hooks/usePaginationHelpers'

export const useRightsQueueListTableUtils = () => {
  const { searchParamArray, replaceParams, getParamValueByKey } = useFiltersV3()
  const order = getParamValueByKey('ordering') as Order
  const orderBy = getParamValueByKey('orderBy') as RightsQueueListTableColumnId
  const rightsQueueListTableColumnConfig = useRightsQueueListTableColumnConfigV2()

  const filterPayload = {
    ...arrayToObject(searchParamArray),
  }
  const location = useLocation()
  const reduxRootPathString = useAppSelector(getRootPathString)
  const locationRootPathString = [location.pathname.split('/')?.[1], location.pathname.split('/')?.[2]].join('/')
  const isRootPathHasChanged = reduxRootPathString !== locationRootPathString

  const {
    data: rightInvocations,
    isLoading,
    pagination,
    isFetching,
    ...rest
  } = useRightInvocationsPaginatedV2({
    itemsPerPage: !!getParamValueByKey('itemsPerPage') ? Number(getParamValueByKey('itemsPerPage')) : 20,
    params: filterPayload,
  })

  const paginationHelpers = usePaginationHelpers({ pagination })

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: RightsQueueListTableColumnId) => {
    const orderBy = getParamValueByKey('orderBy')

    if (order === 'desc' && orderBy === property) {
      replaceParams({
        orderBy: property,
        ordering: 'asc',
      })
      return
    }
    // TODO:BAC - uncomment if we want to allow sorting to be turned off for a column instead of toggling between asc and desc
    // if (order === 'asc' && orderBy === property) {
    //   removeMultipleSearchParams(['orderBy', 'ordering'])
    //   return
    // }

    replaceParams({
      orderBy: property,
      ordering: 'desc',
    })
  }

  /* default sort of table should be  by invoked_at, desc */
  useEffect(() => {
    if (isRootPathHasChanged) {
      replaceParams({ orderBy: 'invoked_at', ordering: 'desc' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    handleRequestSort,
    order,
    orderBy,
    isLoading,
    isFetching,
    paginationHelpers,
    rightInvocations,
    rightsQueueListTableColumnConfig,
    searchParamArray,
    getParamValueByKey,
    ...rest,
  }
}

export type RightsQueueListTableUtils = ReturnType<typeof useRightsQueueListTableUtils>
