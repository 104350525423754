import { makeStyles, createStyles } from '@mui/styles'

export const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      // States:
      invalid: {
        borderColor: `${palette.chileanFire.main} !important`,
      },

      baseError: {
        fontSize: typography.pxToRem(12),
        fontWeight: 500,
        color: palette.chileanFire.main,
      },
    }),
  { name: 'Input' },
)
