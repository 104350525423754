import React from 'react'
import clsx from 'clsx'

import { makeStyles, createStyles } from '@mui/styles'
import { NavLink, useLocation } from 'react-router-dom'
import { Box, Theme } from '@mui/material'

type UseStylesProps = {
  removePaddingLeft?: boolean
}

const useStyles = makeStyles<Theme, UseStylesProps>(
  ({ typography, palette, spacing }) =>
    createStyles({
      baseTabs: {
        display: 'inline-flex',
        alignItems: 'center',
        width: '100%',
        paddingLeft: ({ removePaddingLeft }) => (removePaddingLeft ? 0 : 18),
        paddingRight: 18,
        borderRadius: 0,
        boxSizing: 'border-box',
      },
      baseTab: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        fontFamily: typography.fontFamily,
        letterSpacing: '0.01em',
        textTransform: 'none',
        textDecoration: 'none',
        color: palette.darkDuskFaded.main,
        marginRight: 24,

        '& .count': {
          color: palette.fadedDarkGrey.main,
          marginLeft: spacing(0.5),
        },

        '&:not($active)': {
          '&:hover': {
            color: palette.royalBlue.main,
          },

          '&:active': {
            color: palette.persianBlue.main,
          },

          '& .icon': {
            color: palette.darkDuskFaded.main,
          },
        },

        '& svg': {
          marginRight: 12,
          marginBottom: '0 !important',
        },
      },

      // States:
      active: {
        '& .count': {
          color: palette.darkDuskFaded.main,
          marginLeft: spacing(0.5),
        },

        '& .icon': {
          '& path': {
            fill: palette.darkDusk.main,
            fillOpacity: 1,
          },
        },

        color: palette.darkDusk.main,
        borderBottom: `1px solid ${palette.darkDusk.main}`,
      },
      disabled: {
        color: palette.fadedDarkGrey.main,
        cursor: 'no-drop',
      },

      // Sizes:
      sizeRegular: {
        '& $baseTab': {
          padding: '0 0 14px 0',
          fontSize: typography.pxToRem(14),
          fontWeight: 600,
          lineHeight: typography.pxToRem(20),
        },

        '& $baseTab  svg': {
          fontSize: typography.pxToRem(20),
        },
      },
      sizeSmall: {
        '& $baseTab': {
          padding: '0 0 12px 0',
          fontSize: typography.pxToRem(12),
          fontWeight: 600,
          lineHeight: typography.pxToRem(18),
        },

        '& $baseTab svg': {
          fontSize: typography.pxToRem(18),
        },
      },
    }),
  { name: 'TabsRoutes' },
)

export interface TabsRoutesConfig {
  id?: string
  title: string
  icon?: React.ReactNode
  disabled?: boolean
  link: any
  hidden?: boolean
  count?: number
  resourceTypeCode?: string
  shouldShowCount?: boolean
  countComponent?: React.ReactNode
}

export interface Props {
  /** Tabs size - reflects the size of the tab */
  size?: 'small' | 'regular'
  /** Tabs custom className */
  className?: string
  /** Tabs list */
  tabs: TabsRoutesConfig[]
  /** Remove padding left */
  removePaddingLeft?: boolean
}

/**
 * -
 */
export const TabsRoutes: React.FC<Props> = ({ tabs, removePaddingLeft = false, size = 'regular', className }) => {
  const classes = useStyles({ removePaddingLeft })
  const location = useLocation()

  const visibleTabs = tabs.filter(({ hidden }) => !hidden)

  if (!visibleTabs.length) {
    return null
  }

  const tabsClassName = clsx(
    classes.baseTabs,
    {
      [classes.sizeRegular]: size === 'regular',
      [classes.sizeSmall]: size === 'small',
    },
    className,
  )

  return (
    <div className={tabsClassName}>
      {visibleTabs.map((tab, index) => {
        let link = tab?.link?.pathname || tab?.link
        const tabClassName = clsx(classes.baseTab, {
          [classes.disabled]: tab?.disabled,
          [classes.active]: location.pathname.includes(String(link)),
        })
        return (
          <NavLink
            key={index}
            to={tab.link}
            className={tabClassName}
            id={`${tab?.id || ''}-tab`}
            onClick={e => {
              if (tab?.disabled) e.preventDefault()
            }}
          >
            {tab.icon ? (
              <Box display="flex" alignItems="center">
                <Box className="icon" display="flex" alignItems="center" justifyContent="center">
                  {tab.icon}
                </Box>
                {tab.title}
              </Box>
            ) : (
              tab.title
            )}{' '}
            {tab?.shouldShowCount ? tab.countComponent || <span className="count">{tab.count}</span> : null}
          </NavLink>
        )
      })}
    </div>
  )
}
