import React from 'react'
import { useQueryClient } from 'react-query'
import { useSetState } from 'react-use'
import { useNavigate } from 'react-router-dom'
import { ControlPointDuplicate, DeleteOutline, EditOutlined } from '@mui/icons-material'

import { FormMode } from 'interfaces/formModes/formMode'
import { ExperienceDTO } from 'interfaces/experiences/experience'
import { ExperienceUrlTypeParam } from 'interfaces/experiences/experienceUrlTypeParam'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { SENSITIVE_EXPERIENCE_STATUSES } from 'interfaces/experiences/experienceStatus'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'
import { ExperienceDeleteModal } from 'pages/consentAndRights/experiences/modals/ExperienceDeleteModal'

type Props = {
  experience: ExperienceDTO
}

export const ExperiencesListDropdown: React.FC<Props> = ({ experience }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { isPermitted } = useIsPermitted()
  const isPermittedToManageExperiences = isPermitted(PERMISSIONS.EXP_WRITE)

  const { isEntitled } = useIsEntitled()
  const isEntitledToCreateExperience = isEntitled(ENTITLEMENTS.EXPERIENCES)
  const isEntitledToConsentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)
  const isEntitledToRights = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)

  const { consent, code, status } = experience
  const isInSensitiveStatus = SENSITIVE_EXPERIENCE_STATUSES.includes(status)

  const [{ isEditConfirmationOpen, isDeleteOpen }, setState] = useSetState({
    isEditConfirmationOpen: false,
    isDeleteOpen: false,
  })

  const handleRedirectToUpsert = ({ formMode }: { formMode: FormMode }) => {
    navigate(
      RoutesManager.deployment.experiences.upsert.root.getURL({
        code,
        formMode,
        experienceType: consent ? ExperienceUrlTypeParam.CONSENT : ExperienceUrlTypeParam.PREFERENCE,
      }),
    )
  }

  let showDuplicate = isEntitledToCreateExperience

  if (showDuplicate) {
    if (consent) {
      showDuplicate = isEntitledToConsentAndDisclosure
    } else {
      showDuplicate = isEntitledToConsentAndDisclosure || isEntitledToRights
    }
  }

  return (
    <>
      <ButtonOptions
        items={[
          {
            content: 'Edit',
            icon: <EditOutlined />,
            hidden: !isPermittedToManageExperiences,
            onClick: () => {
              if (isInSensitiveStatus) {
                setState({ isEditConfirmationOpen: true })
              } else {
                handleRedirectToUpsert({ formMode: FormMode.EDIT })
              }
            },
          },
          {
            content: 'Duplicate',
            icon: <ControlPointDuplicate />,
            hidden: !isPermittedToManageExperiences || !showDuplicate,
            onClick: () => {
              handleRedirectToUpsert({ formMode: FormMode.DUPLICATE })
            },
          },
          {
            content: 'Delete',
            icon: <DeleteOutline />,
            hidden: !isPermittedToManageExperiences || isInSensitiveStatus,
            onClick: () => {
              setState({ isDeleteOpen: true })
            },
          },
        ]}
      />

      {isEditConfirmationOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.EXPERIENCE}
          onSubmit={() => {
            handleRedirectToUpsert({ formMode: FormMode.EDIT })
          }}
          onCancel={() => {
            setState({ isEditConfirmationOpen: false })
          }}
        />
      )}

      {isDeleteOpen && (
        <ExperienceDeleteModal
          experience={experience}
          onDelete={() => queryClient.refetchQueries(ApiQueryKeys.experiencesPaginated)}
          onClose={() => {
            setState({ isDeleteOpen: false })
          }}
        />
      )}
    </>
  )
}
