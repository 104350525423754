import React from 'react'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { useFormikContext } from 'formik'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { CheckboxOptionTranslationDTO, DropdownOptionDTO, FormFieldFormDTO } from '../../../interfaces'
import { formatCodeFromName } from 'utils/formatters'
import { Button, DataGrid, Icon, TableCell, theme } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { FormInlineEdit } from 'components/form/FormInlineEdit'

type Props = {
  isTranslationExpanded: boolean
  checkboxOptionIndex: number
  remove: <T>(index: number) => T | undefined
  setExpandedTranslations: React.Dispatch<React.SetStateAction<string[]>>
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  option: DropdownOptionDTO
}

type DataGridTranslations = CheckboxOptionTranslationDTO & {
  rowIndex: number
}

export const DraggableCheckboxItem: React.FC<Props> = ({
  isTranslationExpanded,
  checkboxOptionIndex,
  remove,
  setExpandedTranslations,
  provided,
  snapshot,
  option,
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<FormFieldFormDTO>()
  const isRemoveItemDisabled = (values?.checkboxOptions?.length || 0) <= 1

  const dataGridRows: DataGridTranslations[] =
    values?.checkboxOptions?.[checkboxOptionIndex]?.translations.map((row, index) => ({ ...row, rowIndex: index })) ||
    []
  return (
    <Box
      ref={provided.innerRef}
      key={checkboxOptionIndex}
      display="flex"
      flexDirection="column"
      sx={{
        backgroundColor: 'white.main',
        borderRadius: 2.75,
        p: 1.5,
        mb: 2,
        outline: ({ palette }) => (snapshot.isDragging ? `3px dashed ${palette.fadedGrey.main}` : undefined),
      }}
      style={{
        ...provided.draggableProps.style,
      }}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {/* <AlphaTest /> */}

      <Box display="flex">
        {/* Drag'n'Drop Indicator Icon, Option Value Input, Delete Item Button */}

        <Box
          display="flex"
          flex={1}
          sx={{
            pb: 2,
            ...(!!values?.translations.length && {
              borderBottom: '1px solid',
              borderColor: isTranslationExpanded ? 'darkDusk.main' : 'iron.main',
            }),
          }}
        >
          {/* Left */}

          <Box display="flex" mr={1} pt={0.5}>
            <Icon name="ODragHandle" iconColor={theme.palette.fadedDarkGrey.main} />
          </Box>

          {/* Center */}

          <Box display="flex" gap={3} flex={1} flexWrap="wrap" flexDirection="column">
            <FormInput
              shouldUpdateDebounced
              required
              label={`Checkbox ${checkboxOptionIndex + 1}`}
              formPropertyName={`checkboxOptions.[${checkboxOptionIndex}].label`}
              fullWidth
              placeholder="Add checkbox name"
              onChange={e => {
                setFieldTouched(`checkboxOptions.[${checkboxOptionIndex}].value`, true)
                setFieldTouched(`checkboxOptions.[${checkboxOptionIndex}].label`, true)
                setFieldValue(`checkboxOptions.[${checkboxOptionIndex}].value`, formatCodeFromName(e.target.value))
              }}
            />

            <FormInput
              label="Value"
              formPropertyName={`checkboxOptions.[${checkboxOptionIndex}].value`}
              fullWidth
              required
              placeholder="Add checkbox value"
              shouldUpdateDebounced
            />
          </Box>

          {/* Right */}

          <Box ml={1} display="flex">
            <Button
              variant="iconCustomRounded"
              color="tertiary"
              disabled={isRemoveItemDisabled}
              onClick={() => {
                if (isRemoveItemDisabled) return
                remove(checkboxOptionIndex)
                setExpandedTranslations(e => e.filter(id => id !== option.id))
              }}
            >
              <Icon name="FBin" />
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Translations Input Container */}

      <Box flexDirection="column" pt={1} display={!!values?.translations.length ? 'flex' : 'none'}>
        {/* Translations Collapse Row */}

        <Box
          display="flex"
          alignItems="center"
          mb={isTranslationExpanded ? 2 : 0}
          onClick={() => {
            if (isTranslationExpanded) {
              // remove checkboxOptionIndex from expandedTranslations
              setExpandedTranslations(e => e.filter(i => i !== option.id))
            } else {
              // add checkboxOptionIndex to expandedTranslations
              setExpandedTranslations(e => [...e, option.id])
            }
          }}
        >
          <Icon name={isTranslationExpanded ? 'OArrowCDown' : 'OArrowCRight'} />
          <Typography variant="label">Translations</Typography>
        </Box>

        {/* Dropdown Item Translations Inputs Table */}

        {isTranslationExpanded && (
          <DataGrid
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              includeOutliers: false,
              expand: true,
            }}
            getRowHeight={() => 'auto'}
            columns={[
              {
                align: 'left',
                field: 'language',
                flex: 1,
                headerAlign: 'left',
                headerName: 'Language',
                sortable: false,
                renderCell: ({ row }: GridRenderCellParams<DataGridTranslations>) => {
                  return <TableCell title={row.title} />
                },
              },
              {
                align: 'left',
                field: 'fieldLabelTranslation',
                flex: 1,
                headerAlign: 'left',
                headerName: 'Checkbox Translation',
                sortable: false,
                renderCell: ({ row: { rowIndex } }: GridRenderCellParams<DataGridTranslations>) => {
                  return (
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormInlineEdit
                        sx={{
                          input: {
                            maxWidth: '350px !important',
                          },
                          '& .InlineEdit-content': {
                            '&.InlineEdit-medium': {
                              maxWidth: '350px',
                              height: 'auto',
                              minHeight: '32px',
                            },
                          },
                        }}
                        formPropertyName={`checkboxOptions.[${checkboxOptionIndex}].translations.[${rowIndex}].fieldLabelTranslation`}
                        placeholder="Add Translation"
                        shouldUpdateDebounced
                        // necessary so it doesn't interfere with the data grid accesibility keyboard actions
                        onKeyDown={e => e.stopPropagation()}
                      />
                    </TableCell>
                  )
                },
              },
            ]}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowSelectionOnClick
            disableRowHoverStates
            getRowId={row => row.code}
            rows={dataGridRows}
            hideFooter
          />
        )}
      </Box>
    </Box>
  )
}
