import React, { useState } from 'react'
import { ViewFormDataModal } from './ViewFormDataModal'
import { RightInvocationFormDataDTO } from '@ketch-com/figurehead'
import { Chip, Icon } from '@ketch-com/deck'

type Props = {
  formDataItem?: RightInvocationFormDataDTO
}

export const FormDataChip: React.FC<Props> = ({ formDataItem }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Chip
        clickable
        label={formDataItem?.name}
        onClick={() => setShowModal(true)}
        deleteIcon={<Icon name="OArrowCRight" />}
        onDelete={() => setShowModal(true)}
      />

      {showModal ? <ViewFormDataModal formDataItem={formDataItem} onCancel={() => setShowModal(false)} /> : null}
    </>
  )
}
