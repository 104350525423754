import React, { useContext, useState, useEffect } from 'react'
import { Box, Typography, Tooltip } from '@mui/material'
import { withTrackersWithNewPurposesContext, TrackersWithNewPurposesContext } from 'pages/policyCenter/trackers/context'
import {
  Spinner,
  EmptyState,
  DataGrid,
  TableCell,
  Icon,
  IconDictionary,
  Button,
  theme,
  TextInput,
  PopUp,
} from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import {
  GetTrackerStatisticsResponseBody,
  TrackerWithNewPurposes,
} from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useDebounce } from 'react-use'
import pluralize from 'pluralize'
import { ProvenanceType } from 'pages/policyCenter/trackers/interfaces'
import { ProvenanceVariant } from 'pages/policyCenter/trackers/components'
import { Categories } from 'pages/policyCenter/trackers/list/needsReviewTrackers/trackersWithNewPurposes/components/Categories'
import { Purposes } from 'pages/policyCenter/trackers/list/needsReviewTrackers/trackersWithNewPurposes/components/Purposes'
import { ConfirmTrackerWithNewPurposes } from 'pages/policyCenter/trackers/list/needsReviewTrackers/trackersWithNewPurposes/components/ConfirmTrackerWithNewPurposes'

type Props = {
  trackerStatistics: GetTrackerStatisticsResponseBody
}

const TrackersWithNewPurposesWithContext: React.FC<Props> = ({ trackerStatistics }) => {
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const {
    isReady = false,
    trackersWithNewPurposes,
    numTrackersWithNewPurposes,
    isFetchingTrackersWithNewPurposes,
    isFetchingNextTrackersWithNewPurposes,
    hasNextTrackersWithNewPurposes,
    fetchNextTrackersWithNewPurposes,
    confirmTrackersWithNewPurposes,
    isConfirmingTrackersWithNewPurposes,
    searchString,
    setSearchString,
    shouldConfirmTrackerPurposes,
    setShouldConfirmTrackerPurposes,
  } = useContext(TrackersWithNewPurposesContext)

  const [inputValue, setInputValue] = useState(searchString || '')

  const rows = trackersWithNewPurposes?.map(tracker => tracker) || []
  const cols = [
    {
      width: 380,
      field: 'key',
      headerName: 'Tracker',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        const provenance = params?.row?.provenance
        return (
          <TableCell>
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={36}
                height={36}
                borderRadius={1}
                sx={{ backgroundColor: ({ palette }) => palette.fadedGrey.main }}
              >
                <ProvenanceVariant provenance={provenance} type={ProvenanceType.Icon} />
              </Box>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography variant="label">{params?.row?.key}</Typography>

                {params?.row?.trackerInstances?.length ? (
                  <Box>
                    <Tooltip
                      title={
                        <Box>
                          {params?.row?.trackerInstances?.map((instances, index) => {
                            return (
                              <Box key={index} my={1}>
                                <Typography variant="label" color="white.main">
                                  {instances}
                                </Typography>
                              </Box>
                            )
                          })}
                        </Box>
                      }
                      placement="bottom"
                    >
                      <Typography variant="smallBody" color="darkDuskFaded.main">
                        {params?.row?.trackerInstances?.length || 0}{' '}
                        {pluralize('instance', params?.row?.trackerInstances?.length)}
                      </Typography>
                    </Tooltip>
                  </Box>
                ) : null}

                {params?.row?.dataSystem?.name || params?.row?.dataSystem?.logoURL ? (
                  <Box display="flex" alignItems="center" gap={0.5}>
                    {params?.row?.dataSystem?.logoURL ? (
                      <img
                        src={params?.row?.dataSystem?.logoURL}
                        alt={params?.row?.dataSystem?.name}
                        width={12}
                        height={12}
                      />
                    ) : null}
                    {params?.row?.dataSystem?.name ? (
                      <Typography variant="footnote" color="darkDusk.main">
                        {params?.row?.dataSystem?.name}
                      </Typography>
                    ) : null}
                  </Box>
                ) : (
                  <Typography variant="footnote" color="darkDuskFaded.main">
                    Unknown System
                  </Typography>
                )}
              </Box>
            </Box>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 220,
      field: 'categories',
      headerName: 'Category',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            {params?.row?.categories?.length ? (
              <Categories tracker={params?.row} />
            ) : (
              <Typography variant="body" color="darkDuskFaded.main">
                Unknown
              </Typography>
            )}
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 220,
      field: 'purposes',
      headerName: 'Purposes',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            {params?.row?.oldPurposes?.length ? (
              <Purposes purposes={params?.row?.oldPurposes} />
            ) : (
              <Typography variant="body" color="darkDuskFaded.main">
                None
              </Typography>
            )}
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 220,
      field: 'newPurposes',
      headerName: 'Updated Purposes',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            {params?.row?.purposes?.length ? (
              <Purposes purposes={params?.row?.purposes} />
            ) : (
              <Typography variant="body" color="darkDuskFaded.main">
                None
              </Typography>
            )}
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 180,
      field: 'type',
      headerName: '',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <Box display="flex" justifyContent="right" flexBasis="100%">
            <ConfirmTrackerWithNewPurposes tracker={params.row} />
          </Box>
        )
      },
      sortable: false,
    },
  ]

  useDebounce(
    () => {
      setSearchString(inputValue)
    },
    500,
    [inputValue],
  )

  useEffect(() => {
    if (!searchString) setInputValue('')
  }, [searchString])

  if (!isReady && searchString === '')
    return (
      <Box display="flex" justifyContent="center" alignItems="center" my={4}>
        <Spinner size={35} thickness={3} />
      </Box>
    )

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={0.5} mb={2}>
        <Icon name={IconDictionary.OInfo} iconColor={theme.palette.fadedDarkGrey.main} width={16} height={16} />
        <Typography variant="smallBody" color="darkDuskFaded.main">
          The categories for the trackers below have recently changed, resulting in changes to their purpose mappings.
          Please review the list below, adjust purpose mappings if desired, and confirm the changes.
        </Typography>
      </Box>

      <Box>
        <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
          <TextInput
            inputWidth="180px"
            size="small"
            placeholder="Search"
            startIcon={<Icon name={IconDictionary.OMag} iconColor={theme.palette.darkDuskFaded.main} />}
            rows={2}
            value={inputValue}
            onChange={e => {
              setInputValue(e?.currentTarget?.value)
            }}
          />
          {trackersWithNewPurposes.length > 1 && isPermittedToWriteCookie ? (
            <Button
              disabled={isConfirmingTrackersWithNewPurposes}
              color="primary"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                setShouldConfirmTrackerPurposes(true)
              }}
            >
              Confirm {numTrackersWithNewPurposes}
            </Button>
          ) : null}
        </Box>

        {!trackersWithNewPurposes?.length && searchString && isReady ? (
          <Box
            sx={{
              padding: '50px 0',
              borderRadius: '11px',
              backgroundColor: 'white.main',
            }}
          >
            <EmptyState
              iconName={IconDictionary.OFilter}
              title="No data matches the search criteria"
              subTitle="Sorry, we couldn't find any results that match your search criteria. Please adjust your search and try again."
              primaryButtonTitle="Reset to Defaults"
              primaryButtonProps={{
                onClick: () => {
                  setSearchString('')
                },
                color: 'tertiary',
              }}
            />
          </Box>
        ) : null}

        {trackersWithNewPurposes?.length ? (
          <Box height="58vh">
            <DataGrid
              getRowHeight={() => 'auto'}
              getRowId={(row: TrackerWithNewPurposes) => row?.key}
              sx={{
                '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                  backgroundImage: 'unset',
                  cursor: 'auto',
                },
              }}
              rows={rows}
              columns={cols}
              hideFooter={false}
              hideFooterPagination={false}
              hideFooterRowCount
              disableBorder
              disableColumnMenu
              disableColumnPinning
              disableColumnReorder
              disableColumnResize
              disableChildrenSorting
              disableRowSelectionOnClick
              disableRowHoverStates
              loading={false}
              columnMenuProps={
                {
                  slots: {
                    columnMenuFilterItem: null,
                    columnMenuAggregationItem: null,
                    columnMenuGroupingItem: null,
                  },
                } as any
              }
              onRowsScrollEnd={() => {
                if (
                  hasNextTrackersWithNewPurposes &&
                  !isFetchingTrackersWithNewPurposes &&
                  !isFetchingNextTrackersWithNewPurposes
                )
                  fetchNextTrackersWithNewPurposes()
              }}
              paginationLoading={isFetchingNextTrackersWithNewPurposes}
              isPaginationLoading={isFetchingNextTrackersWithNewPurposes}
            />
          </Box>
        ) : null}
      </Box>

      {shouldConfirmTrackerPurposes ? (
        <PopUp
          title="Confirm Tracker Purposes"
          popUpWidth="550px"
          variant="dialog"
          onClose={() => setShouldConfirmTrackerPurposes(false)}
          popUpActionChildren={
            <>
              <Button
                disabled={isConfirmingTrackersWithNewPurposes}
                color="secondary"
                size="large"
                onClick={() => setShouldConfirmTrackerPurposes(false)}
              >
                Cancel
              </Button>
              <Button
                disabled={isConfirmingTrackersWithNewPurposes}
                color="primary"
                size="large"
                onClick={() => confirmTrackersWithNewPurposes({ params: { searchString } })}
              >
                Confirm {numTrackersWithNewPurposes}
              </Button>
            </>
          }
        >
          <Box>
            <Typography variant="body">
              Are you sure you want to confirm {numTrackersWithNewPurposes}
              {pluralize(' tracker', numTrackersWithNewPurposes)}
              {pluralize(' purpose', numTrackersWithNewPurposes)}?
            </Typography>
          </Box>
        </PopUp>
      ) : null}
    </Box>
  )
}

export const TrackersWithNewPurposes = withTrackersWithNewPurposesContext(TrackersWithNewPurposesWithContext)
