import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchFeatureFlags } from 'api/featureFlags/fetchers/fetchFeatureFlags'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.featureFlags,
  queryFn: fetchFeatureFlags,
  select: res => res?.data?.treatments || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useGetFeatureFlags = (config?: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config?.params,
    },
  })
}
