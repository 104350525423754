import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { InputLabel, SxProps, Theme, Typography, Autocomplete, Box } from '@mui/material'
import { useField } from 'formik'
import { uniqueId } from 'lodash'
import { FormError } from '../FormError'
import { TextInput, TextInputProps } from '@ketch-com/deck'
import { getRenderTags } from './utils/utils'

interface Props extends TextInputProps {
  /** Formik field name */
  formPropertyName: string
  /** Text to be displayed below input */
  hint?: string
  /** Text to be displayed in the tooltip w/ info icon */
  info?: string
  /** Text to be displayed above input */
  label?: string
  /** Display Asterisk as required field */
  required?: boolean
  /** Component value change method */
  onChange?: () => void
  sx?: SxProps<Theme>
  labelColor?: string
  hideOptionalLabel?: boolean
}

const yupObject = yup.string().email()

export const FormEmailsInput: React.FC<Props> = ({
  id = uniqueId(),
  formPropertyName,
  placeholder = '',
  disabled = false,
  fullWidth = false,
  label = '',
  info = '',
  required = false,
  labelColor,
  onChange,
  hideOptionalLabel,
  sx,
}) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(formPropertyName)
  const [pendingEmail, setPendingEmailValue] = useState<string>('')
  const [emails, setEmails] = useState<string[]>(() => [...value])
  const [isError, setIsError] = useState(false)
  const [isDuplicateError, setDuplicateIsError] = useState(false)

  const handleValidateEmail = async (email: string) => {
    if (!email) {
      setIsError(true)
      return
    }
    if (emails.includes(email)) {
      setDuplicateIsError(true)
      return
    }
    try {
      await yupObject.validate(email)
      setEmails(e => [...e, pendingEmail])
      setPendingEmailValue('')
    } catch (error) {
      setIsError(true)
    }
  }

  const handleResetErrors = () => {
    setIsError(false)
    setDuplicateIsError(false)
  }

  const handleLabelInputValueOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    handleResetErrors()
    setPendingEmailValue(e.target.value)
  }

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleValidateEmail(pendingEmail)
    }

    if (e.key === 'Backspace' && pendingEmail.length === 0 && emails.length > 0) {
      setEmails(e => [...e.slice(0, -1)])
    }
  }

  const handleOnBlur = () => {
    setTouched(true)
    pendingEmail && handleValidateEmail(pendingEmail)
  }

  useEffect(() => {
    if (value.length !== emails.length) {
      setValue(emails)
    }
    if (onChange) onChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails, value])

  return (
    <Box display={fullWidth ? 'block' : 'inline-block'} sx={sx}>
      <InputLabel>
        {required ? (
          <Typography variant="label" color={labelColor || 'darkDusk.main'}>
            {label}
          </Typography>
        ) : (
          <>
            <Typography variant="label" color={labelColor || 'darkDusk.main'}>
              {label}
            </Typography>{' '}
            {!hideOptionalLabel && (
              <Typography variant="label" color="darkGrey.main">
                {'(Optional)'}
              </Typography>
            )}
          </>
        )}
      </InputLabel>
      <Autocomplete
        id="labels"
        sx={{
          '& .MuiTextField-root .MuiInputBase-root': {
            height: 'auto',
          },
        }}
        multiple
        disabled={disabled}
        disableClearable
        open={false}
        onKeyDown={handleOnKeyDown}
        options={[]}
        onBlur={handleOnBlur}
        value={emails}
        renderTags={(value: string[]) =>
          getRenderTags({
            value,
            handleRemoveTag: (emailToRemove: string) => {
              setEmails(e => e.filter(email => email !== emailToRemove))
            },
            emails,
            isDisabled: disabled,
          })
        }
        renderInput={params => (
          <TextInput
            {...params}
            InputProps={{
              ...params.InputProps,
              placeholder,
              endAdornment: null,
            }}
            inputProps={{
              ...params.inputProps,
              onChange: handleLabelInputValueOnChange,
              value: pendingEmail,
            }}
            error={touched && (!!error || isError || isDuplicateError)}
          />
        )}
      />

      {touched && error && (
        <Box>
          <FormError msg={error} />
        </Box>
      )}
      {touched && isError && (
        <Box>
          <FormError msg="Please enter a valid email" />
        </Box>
      )}

      {touched && isDuplicateError && (
        <Box>
          <FormError msg="No duplicate entries" />
        </Box>
      )}
    </Box>
  )
}
