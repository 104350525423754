import { useInfiniteQuery } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchUnapprovedTrackers } from '../fetchers/fetchUnapprovedTrackers'
import { GetUnapprovedTrackersParams } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type Params = GetUnapprovedTrackersParams & { enabled?: boolean; limit?: number }

export const useUnapprovedTrackersInfinite = (params: Params) => {
  const { enabled = true, limit = 20 } = params
  const resp = useInfiniteQuery({
    enabled,
    queryKey: [ApiQueryKeys.unapprovedTrackersInfinite, { ...params }],
    queryFn: async ({ pageParam = {} }) => {
      const trackers = await fetchUnapprovedTrackers({
        limit,
        nextToken: pageParam.nextToken,
        ...params,
      })
      return trackers
    },
    getNextPageParam: lastPage => {
      const nextToken = lastPage?.data?.nextToken
      return nextToken ? { nextToken } : null
    },
  })

  return {
    ...resp,
    trackers: resp?.data?.pages?.map(page => page?.data?.trackers || []).flat() || [],
    totalResults: resp?.data?.pages?.[0]?.data?.totalResults,
  }
}
