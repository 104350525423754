import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetScenarioResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

type Params = APIListRequestParams<{
  scenarioId?: string
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchScenario = ({ scenarioId, isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/scenarios/${scenarioId}`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/scenarios/${scenarioId}`

  return API.get<GetScenarioResponseBodyDTO>(
    formatRequestString({
      entityUrl,
    }),
  )
}
