import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { WorkflowDTO } from 'interfaces/workflows'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
  includeIssues?: boolean
  canonicalRightCode?: string
}>

type Response = APIListResponse<{ workflows: WorkflowDTO[] }>

export const fetchWorkflows = ({
  organizationCode,
  includeMetadata,
  includeIssues,
  start,
  limit,
  canonicalRightCode,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/workflows`,
      params: {
        filters: {
          canonicalRightCode,
          includeMetadata,
          includeIssues,
          start,
          limit,
        },
      },
    }),
  )
