import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchIdentitySpacesCatalog } from 'api/identitySpaces/fetchers/fetchIdentitySpacesCatalog'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.identitySpacesCatalog,
  queryFn: fetchIdentitySpacesCatalog,
  select: res => res?.data || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useIdentitySpacesCatalog = (config?: Config) =>
  useCustomQuery({
    ...config,
    params: {
      start: 0,
      limit: 1000,
      ...config?.params,
    },
  })
