import React from 'react'
import { useFormikContext } from 'formik'
import { PolicyScopeDTO } from 'interfaces/policyScopes-v2/policyScope'
import { EXPERIENCE_DEFAULT_DISPLAY_OPTIONS } from 'interfaces/experiences-v2/experienceDefaultDisplay'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { Table } from 'components/ui-layouts/table/Table'
import { FormDropList } from 'components/ui-kit/form/dropList/FormDropList'
import { DeploymentPlanExperienceAssignment } from 'pages/consentAndRights/deploymentPlans-v2/upsert/components/deploymentPlanExperiences/DeploymentPlanExperienceAssignment'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import { Banner } from '@ketch-com/deck'
import { Box, AlertColor } from '@mui/material'

type Props = {
  policyScopes: PolicyScopeDTO[]
  experiences: ExperienceV2DTO[]
}

export const DeploymentPlanExperiences: React.FC<Props> = ({ policyScopes, experiences }) => {
  const { isEntitled } = useIsEntitled()
  const isEntitledToContentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)
  const { values } = useFormikContext()

  return (
    <FormSection title="Experiences">
      {(values as DeploymentPlanDTO)?.isOrchestrationOnly ? (
        <Box mb={2}>
          <Banner isCompact severity={'info' as AlertColor} variant={'filled'} title="Quiet mode is enabled">
            These configurations are not applicable. Quiet mode deployments will not serve experiences or generate
            consent.
          </Banner>
        </Box>
      ) : null}
      <Table
        variant="page"
        items={policyScopes}
        cellSettings={{
          policyScope: {
            label: 'Jurisdiction',
            cellRenderer: ({ code, name }) => <NameAndCodeCellRenderer name={name} code={code} />,
          },
          ...(isEntitledToContentAndDisclosure && {
            consentAndDisclosure: {
              label: 'Consent & Disclosure Experience',
              width: 300,
              cellRenderer: ({ code }) => (
                <DeploymentPlanExperienceAssignment
                  experienceType={ExperienceType.Consent}
                  policyScopeCode={code}
                  experiences={experiences}
                />
              ),
            },
            defaultConsentDisplay: {
              label: 'Default Consent Display',
              width: 300,
              cellRenderer: ({ code }) => {
                // TODO:JB - This check
                // const isNoExperienceSelected =
                //   values.policyScopeExperiences[code]?.[ExperienceType.Consent]?.experienceID ===
                //   DeploymentPlanFormSpecialValues.NO_EXPERIENCE

                return (
                  <>
                    {!false && (
                      <FormDropList
                        name={`policyScopeExperiences.${code}.${ExperienceType.Consent}.experienceDefault`}
                        items={EXPERIENCE_DEFAULT_DISPLAY_OPTIONS}
                        placeholder="Select Default Display"
                      />
                    )}
                  </>
                )
              },
            },
          }),
          preference: {
            label: 'Preference Experience',
            width: 300,
            cellRenderer: ({ code }) => (
              <DeploymentPlanExperienceAssignment
                experienceType={ExperienceType.Preference}
                policyScopeCode={code}
                experiences={experiences}
              />
            ),
          },
        }}
      />
    </FormSection>
  )
}
