import { useQueries } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { ListDsrAttributeActionsSummaryBodyDTO } from '@ketch-com/figurehead'
import { fetchPermittedAttrActionsSummary } from '../fetchers/fetchPermittedAttrActionsSummary'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type Params = {
  assetIds: (string | undefined)[]
  enabled?: boolean
  canonicalRightCode?: string
  limit?: number
}

export const useAssetsHierarchiesDynamic = ({ assetIds, enabled, canonicalRightCode = '', limit }: Params) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )

  const queries = assetIds.map((assetId = '') => ({
    queryKey: [ApiQueryKeys.assetHierarchies, assetId, canonicalRightCode],
    queryFn: () => fetchPermittedAttrActionsSummary({ assetId, canonicalRightCode, limit, isV2FeatureFlagEnabled }),
    enabled,
  }))

  const results = useQueries(queries as any).map((r: any) => {
    return {
      data: (r?.data?.data || []) as ListDsrAttributeActionsSummaryBodyDTO[],
      isLoading: r?.isLoading || r?.isFetching || false,
    }
  })

  const assetsHierarchies = results.flatMap(result => result.data)

  const payload = { assetsHierarchies, isLoading: results.some(r => r?.isLoading) }

  return payload
}
