// layout utils
import { ProtectedRoute } from 'components/ui-routing/ProtectedRoute'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { relativePath } from 'utils/routing/relativePath'
import { RoutesManager } from 'utils/routing/routesManager'

// pages
import { OrganizationViewContainer } from 'pages/settings/organizations/view/OrganizationViewContainer'
import { OrganizationUpsertContainer } from 'pages/settings/organizations/upsert/OrganizationUpsertContainer'
import { OrganizationChangeSubscriptionContainer } from 'pages/settings/organizations/changeSubscription/OrganizationChangeSubscriptionContainer'

import { IdentitySpacesListContainer } from 'pages/settings/identitySpaces/list/IdentitySpacesListContainer'
import { IdentitySpaceUpsertContainer } from 'pages/settings/identitySpaces/upsert/IdentitySpaceUpsertContainer'
import { IdentitySpaceViewContainer } from 'pages/settings/identitySpaces/view/IdentitySpaceViewContainer'

import { UsersListContainer } from 'pages/settings/users/list/UsersListContainer'
import { UsersViewContainer } from 'pages/settings/users/view/UsersViewContainer'
import { UsersUpsertContainer } from 'pages/settings/users/upsert/UsersUpsertContainer'

import { UserProfileContainer } from 'pages/settings/userProfile/view/UserProfileContainer'
import { EditUserProfileDetailsContainer } from 'pages/settings/userProfile/edit/userDetails/EditUserProfileDetailsContainer'

import { DataSubjectTypesListContainer } from 'pages/settings/dataSubjectTypes/list/DataSubjectTypesListContainer'
import { DataSubjectTypesUpsertContainer } from 'pages/settings/dataSubjectTypes/upsert/DataSubjectTypesUpsertContainer'

import { PlanAndBilling } from 'pages/settings/planAndBilling/PlanAndBilling'

import { ContextVariablesList } from 'pages/settings/contextVariables/list/ContextVariablesList'

import { CustomRightNameList } from 'pages/settings/customRightName/list/CustomRightNameList'
import { CustomRightNameUpsertContainer } from 'pages/settings/customRightName/upsert/CustomRightNameUpsertContainer'
import { TeamsList } from './teams/list/TeamsList'
import { TeamsUpsertContainer } from './teams/upsert/TeamsUpsertContainer'
import { Settings } from './Settings'
import { LanguagesListContainer } from 'pages/consentAndRights/languages/list/LanguagesListContainer'

export const settingsRoutesChildren = [
  {
    element: <ProtectedRoute component={Settings} />,
    path: RoutesManager.settings.root.pattern,
    children: [
      {
        element: <ProtectedRoute component={UserProfileContainer} />,
        path: '/settings/',
      },
      {
        element: <ProtectedRoute component={UserProfileContainer} />,
        path: relativePath(RoutesManager.settings.profile.root.pattern),
      },
      {
        element: <ProtectedRoute component={OrganizationViewContainer} permissions={PERMISSIONS.ORG_ADMIN} />,
        path: relativePath(RoutesManager.settings.organization.root.pattern),
      },
      {
        element: <ProtectedRoute component={PlanAndBilling} permissions={PERMISSIONS.ORG_ADMIN} />,
        path: relativePath(RoutesManager.settings.planAndBilling.root.pattern),
      },
      {
        element: <ProtectedRoute component={UsersListContainer} permissions={PERMISSIONS.USER_ADMIN} />,
        path: relativePath(RoutesManager.settings.users.root.pattern),
      },
      {
        element: <ProtectedRoute component={IdentitySpacesListContainer} permissions={PERMISSIONS.IDSP_READ} />,
        path: relativePath(RoutesManager.settings.identitySpaces.root.pattern),
      },
      {
        element: <ProtectedRoute component={LanguagesListContainer} permissions={PERMISSIONS.TRANSLATIONS_READ} />,
        path: relativePath(RoutesManager.deployment.languages.root.pattern),
      },
      {
        element: <ProtectedRoute component={CustomRightNameList} permissions={PERMISSIONS.CUSTOM_NAME_RIGHTS_READ} />,
        path: relativePath(RoutesManager.settings.customRightName.root.pattern),
      },
      {
        element: <ProtectedRoute component={ContextVariablesList} permissions={PERMISSIONS.CONTEXT_VARIABLE_READ} />,
        path: relativePath(RoutesManager.settings.contextVariables.root.pattern),
      },
      {
        element: (
          <ProtectedRoute component={DataSubjectTypesListContainer} permissions={PERMISSIONS.SUBJECTTYPE_READ} />
        ),
        path: relativePath(RoutesManager.settings.dataSubjectTypes.root.pattern),
      },
      {
        element: <ProtectedRoute component={TeamsList} permissions={PERMISSIONS.TEAM_READ} />,
        path: relativePath(RoutesManager.settings.teams.root.pattern),
      },
      {
        element: <ProtectedRoute component={TeamsList} permissions={PERMISSIONS.TEAM_READ} />,
        path: relativePath(RoutesManager.settings.teams.view.root.pattern),
      },
    ],
  },
  {
    element: (
      <ProtectedRoute component={CustomRightNameUpsertContainer} permissions={PERMISSIONS.CUSTOM_NAME_RIGHTS_WRITE} />
    ),
    path: relativePath(RoutesManager.settings.customRightName.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={OrganizationChangeSubscriptionContainer} permissions={PERMISSIONS.ORG_ADMIN} />,
    path: relativePath(RoutesManager.settings.organization.changeSubscription.root.pattern),
  },
  {
    element: <ProtectedRoute component={OrganizationUpsertContainer} permissions={PERMISSIONS.ORG_ADMIN} />,
    path: relativePath(RoutesManager.settings.organization.edit.root.pattern),
  },
  {
    element: <ProtectedRoute component={IdentitySpaceUpsertContainer} permissions={PERMISSIONS.IDSP_WRITE} />,
    path: relativePath(RoutesManager.settings.identitySpaces.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={UsersViewContainer} permissions={PERMISSIONS.USER_ADMIN} />,
    path: relativePath(RoutesManager.settings.users.view.root.pattern),
  },
  {
    element: <ProtectedRoute component={UsersUpsertContainer} permissions={PERMISSIONS.USER_ADMIN} />,
    path: relativePath(RoutesManager.settings.users.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={TeamsUpsertContainer} permissions={PERMISSIONS.USER_ADMIN} />,
    path: relativePath(RoutesManager.settings.teams.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={EditUserProfileDetailsContainer} />,
    path: relativePath(RoutesManager.settings.profile.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={DataSubjectTypesUpsertContainer} permissions={PERMISSIONS.SUBJECTTYPE_WRITE} />,
    path: relativePath(RoutesManager.settings.dataSubjectTypes.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={IdentitySpaceUpsertContainer} permissions={PERMISSIONS.IDSP_WRITE} />,
    path: relativePath(RoutesManager.settings.identitySpaces.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={IdentitySpaceViewContainer} permissions={PERMISSIONS.IDSP_READ} />,
    path: `${relativePath(RoutesManager.settings.identitySpaces.view.root.pattern)}/*`,
  },
]
