import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchExperience } from 'api/experiences-v2/fetchers/fetchExperience'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.experience,
  queryFn: fetchExperience,
  select: res => res?.data || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useExperience = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params!,
    },
  })
}
