import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'

import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'

interface IFulfillRightFormParams {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const FulfillRightForm: React.FC<IFulfillRightFormParams> = props => {
  const { step, handleSave, handleClose, handleRemove, steps } = props
  const [isReady] = useState(true)

  const onSubmit = () => {
    handleSave({
      step: {
        ...step,
        valid: true,
      },
    })
  }

  return (
    <SidebarConfigsWrapper isLoading={!isReady} handleClose={handleClose}>
      <Formik initialValues={{}} validationSchema={Yup.object()} validateOnMount onSubmit={onSubmit}>
        {form => {
          const { isValid } = form

          return (
            <>
              <SidebarConfigsHeader
                isValid={isValid}
                step={step}
                steps={steps}
                handleSave={() => form.submitForm()}
                handleRemove={() => handleRemove({ step })}
                handleClose={handleClose}
                copyable
              />
              <SidebarConfigsBody>&nbsp;</SidebarConfigsBody>
            </>
          )
        }}
      </Formik>
    </SidebarConfigsWrapper>
  )
}
