import React from 'react'
import Box from '@mui/material/Box'

import { Text } from 'components/ui-kit/typography/Text'
import { Label } from 'components/ui-kit/form/common/label/Label'

type Props = {
  title: string
}

export const DefaultLabel: React.FC<Props> = ({ title }) => {
  return (
    <Box display="flex" alignItems="center">
      <Label>{title}</Label>
      <Text size={14} color="fadedDarkGrey" mb={0.675} ml={0.5}>
        (Optional)
      </Text>
    </Box>
  )
}
