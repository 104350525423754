import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import {
  PatchTrackersOnNewPropertiesResponseBody,
  ConfirmAllTrackersOnNewPropertiesParams,
} from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type params = APIRequestParams<ConfirmAllTrackersOnNewPropertiesParams>

export const confirmAllTrackersOnNewProperties = ({ searchString }: params) =>
  API.put<PatchTrackersOnNewPropertiesResponseBody>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/trackers/new-properties?searchString=${searchString}`,
    }),
  )
