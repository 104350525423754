import React from 'react'

import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { Text } from 'components/ui-kit/typography/Text'
import { StackGroupWithId } from 'pages/consentAndRights/purposeStacks/upsert/utils/formValues'

type Props = {
  stackGroup: StackGroupWithId
  onSubmit?: () => void
  onCancel: () => void
}

export const DeleteStackGroupModal: React.FC<Props> = ({ stackGroup, onSubmit, onCancel }) => {
  return (
    <Dialog
      title="Delete Stack Group"
      contentWidth={550}
      submitBtnLabel="Delete"
      cancelBtnLabel="Close"
      onSubmitBtnClick={onSubmit}
      onCancelBtnClick={onCancel}
    >
      <Text size={14}>
        Are you sure you want to delete&nbsp;
        {stackGroup.name ? (
          <>
            Purpose Stack Group <strong>{stackGroup.name}</strong>
          </>
        ) : (
          'this purpose stack group'
        )}
        ?
      </Text>
    </Dialog>
  )
}
