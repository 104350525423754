import React from 'react'
import { SxProps, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import { isEmpty, debounce } from 'lodash'
import { TextInput, Icon, TextInputProps } from '@ketch-com/deck'
import { FormInputLabel } from './FormInputLabel'

export interface Props extends Omit<TextInputProps, 'onChange'> {
  /** Stretch input to fullWidth */
  fullWidth?: boolean
  /** Display loading indicator for input */
  isSearchPending?: boolean
  /** Text to be displayed below input */
  hint?: string
  /** Text to be displayed above input */
  label?: string
  /** Input placeholder */
  placeholder?: string
  /** Amount of symbols to be typed before search starts */
  startSearchFromChar?: number
  /** Amount of milliseconds passed after last call to onSearch */
  debounceTimeout?: number
  /** Method triggered when user types */
  onChange: (value: string) => void
  /** Method triggered when search happens */
  onSearch?: (value: string) => Promise<void> | void
  /** Method triggered when cross is pressed */
  onClear?: () => void
  /** Sx */
  sx?: SxProps<Theme>
  /** Is field required */
  required?: boolean
}

export const SearchInput: React.FC<Props> = ({
  isSearchPending = false,
  fullWidth = false,
  name,
  hint = '',
  label = '',
  placeholder = 'Search...',
  startSearchFromChar = 3,
  debounceTimeout = 500,
  sx,
  required,
  ...baseInputProps
}) => {
  const searchDebounced = baseInputProps.onSearch
    ? debounce(baseInputProps.onSearch, debounceTimeout)
    : debounce(() => {}, debounceTimeout)
  const hasValue = !isEmpty(baseInputProps.value)
  const showClearIcon = hasValue && !!baseInputProps.onClear && !isSearchPending

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = e.target.value

    searchDebounced.cancel()

    if (inputValue.length >= startSearchFromChar) {
      searchDebounced(inputValue)
    }

    baseInputProps.onChange(inputValue)
  }

  const handleClear = () => {
    baseInputProps.onChange('')
    baseInputProps.onClear && baseInputProps.onClear()
  }

  return (
    <Box sx={sx}>
      {!!label && <FormInputLabel label={label} required={required} />}

      <TextInput
        {...baseInputProps}
        name={name}
        fullWidth={fullWidth}
        placeholder={placeholder}
        onChange={handleChange}
        startIcon={<Icon name="OMag" />}
        errorMessage={hint}
        error={!!hint}
        endIcon={showClearIcon && <Icon sx={{ cursor: 'pointer' }} name="OCross" onClick={handleClear} />}
      />
    </Box>
  )
}
